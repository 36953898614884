<template>
  <div>
    <div>
      <span
        ref="trigger"
        v-tippy="vtippyOptions"
        role="button"
      >
        <slot name="trigger">
          <div
            :class="{
              'u-spacing-ph-l u-spacing-pv-m': size === 'medium',
              'u-spacing-ph-m u-spacing-pv-s': size === 'small',
              'previous-days-active': active
            }"
            class="u-display-flex token u-border-width-s u-border-radius-xl u-border-all u-border-color-grey-xxx-light u-spacing-mr-s u-spacing-mb-m u-cursor-pointer u-font-size-5"
          >
            <span> {{ title }} </span>
          </div>
        </slot>
      </span>
      <div
        id="last_days_token"
        ref="previous_days_popover"
        class="popover"
      >
        <div class="popover-header">
          <span class="u-display-flex u-flex-align-items-center">
            <span class="u-color-grey-white">Custom last X days</span>
          </span>
          <span>
            <div @click="toggle">
              <rb-icon
                icon="cross"
                class="rb-icon--small u-color-grey-white u-cursor-pointer"
              />
            </div>
          </span>
        </div>
        <div
          class="u-display-flex u-display-flex u-flex-direction-column u-spacing-mt-m"
        >
          <font class="u-spacing-ml-l u-spacing-mb-s"> Last </font>
          <div class="input_row">
            <input
              v-model="last_x_value"
              min="1"
              class="previous-days-selector-input"
              type="number"
            />
            <rb-select
              v-if="isActive"
              :send-details="true"
              :on-close="onLastMetricChange"
              class="previous_days_select-select-parent"
              :options="[
                { title: 'Days' },
                { title: 'Months' },
                { title: 'Years' }
              ]"
            >
              <div
                slot="trigger"
                class="previous-days-selector-rb-select"
              >
                <span class="u-text-overflow-ellipsis u-font-size-6">{{
                  last_x_metric
                }}</span>
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  icon="arrow-down"
                />
              </div>
            </rb-select>
          </div>
        </div>
        <div class="u-display-flex u-display-flex u-flex-direction-column">
          <font class="u-spacing-ml-l u-spacing-mb-s"> Exclude last </font>
          <div class="input_row">
            <input
              v-model="exclude_x_value"
              min="1"
              class="previous-days-selector-input"
              type="number"
              :rounded="true"
            />
            <rb-select
              v-if="isActive"
              class="previous_days_select-select-parent"
              :send-details="true"
              :on-close="onExcludeMetricChange"
              :options="[
                { title: 'Days' },
                { title: 'Months' },
                { title: 'Years' }
              ]"
            >
              <div
                slot="trigger"
                class="previous-days-selector-rb-select"
              >
                <span class="u-text-overflow-ellipsis u-font-size-6">{{
                  exclude_x_metric
                }}</span>
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  icon="arrow-down"
                />
              </div>
            </rb-select>
          </div>
        </div>
        <div
          class="previous_days_selector_error u-display-flex u-flex-align-items-center u-color-red-base"
        >
          <div
            v-show="!enableApply"
            class="u-position-absolute"
          >
            <rb-icon icon="info-circle-fill u-color-red-base rb-icon--small" />
            Excluded days cannot be greater than last days.
          </div>
        </div>

        <div
          class="u-display-flex u-flex-justify-content-flex-end u-spacing-pb-m u-spacing-pr-m row-top-border u-spacing-pt-m"
        >
          <rb-button
            size="s"
            :click-fn="onApply"
            type="filled"
            :disabled="!enableApply"
            text="Apply"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/require-default-prop */
export default {
  name: 'RbPreviousDaysSelector',
  props: {
    numberOfDays: {
      type: Number
    },
    numberOfDaysExcluded: {
      type: Number
    },
    size: {
      type: String,
      default: 'medium'
    },
    active: {
      type: Boolean,
      default: false
    },
    onCreate: {
      type: Function
    }
  },
  data: function () {
    return {
      title: '',
      isActive: false,
      tippyInstance: null,
      last_x_metric: 'Days',
      exclude_x_metric: 'Days',
      last_x_value: this.numberOfDays,
      exclude_x_value: this.numberOfDaysExcluded,
      isNew:
        typeof this.numberOfDays !== 'number' &&
        typeof this.numberOfDaysExcluded !== 'number',
      modifiedNumberOfDays: this.numberOfDays,
      modifiedNumberOfDaysExcluded: this.numberOfDaysExcluded,
      numberOfDaysmutiplier: {
        Days: 1,
        Months: 30,
        Years: 365
      }
    };
  },
  computed: {
    formattedNumberOfDaysMetric() {
      if (this.last_x_value > 1) {
        return this.last_x_metric;
      } else {
        // display Months as Month if value greater than 1.
        return this.last_x_metric.slice(0, -1);
      }
    },
    formattedExcludedNumberOfDaysMetric() {
      if (this.exclude_x_value > 1) {
        return this.exclude_x_metric;
      } else {
        // display Months as Month if value greater than 1.
        return this.exclude_x_metric.slice(0, -1);
      }
    },
    vtippyOptions() {
      return {
        html: '#last_days_token',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        distance: -35,
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide,
        popperOptions: {
          Defaults: {
            onCreate: this.onTippyCreate
          }
        }
      };
    },
    enableApply() {
      return this.modifiedNumberOfDaysExcluded < this.modifiedNumberOfDays;
    }
  },
  watch: {
    last_x_metric() {
      this.reCalculatePreviousNumberOfDays();
    },
    last_x_value() {
      this.reCalculatePreviousNumberOfDays();
    },
    exclude_x_metric() {
      this.reCalculateExcludedNumberOfDays();
    },
    exclude_x_value() {
      this.reCalculateExcludedNumberOfDays();
    },
    numberOfDays(value) {
      // numberOfDays metric always stored as days in backend. hence metric hardcoded.
      this.last_x_value = value;
      this.last_x_metric = 'Days';
      this.reCalculatePreviousNumberOfDays();
      this.updateTitle();
    },
    numberOfDaysExcluded(value) {
      this.exclude_x_value = value;
      this.exclude_x_metric = 'Days';
      this.reCalculateExcludedNumberOfDays();
      this.updateTitle();
    }
  },
  created() {
    this.onCreate(this);
    this.reCalculatePreviousNumberOfDays();
    this.reCalculateExcludedNumberOfDays();
    this.updateTitle();
  },
  methods: {
    getCurrentState() {
      return {
        name: 'Choose custom last X days',
        numberOfDays:
          this.last_x_value * this.numberOfDaysmutiplier[this.last_x_metric],
        numberOfDaysExcluded:
          this.exclude_x_value *
          this.numberOfDaysmutiplier[this.exclude_x_metric]
      };
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.isActive = true;
    },
    onHide() {
      this.$emit('apply', this.getCurrentState());
      this.isActive = false;
    },
    onTippyCreate(instance) {
      this.tippyInstance = instance;
    },
    onLastMetricChange(context, selectedMetric) {
      this.last_x_metric = selectedMetric[0].title;
    },
    onExcludeMetricChange(context, selectedMetric) {
      this.exclude_x_metric = selectedMetric[0].title;
    },
    reCalculatePreviousNumberOfDays() {
      this.modifiedNumberOfDays =
        this.last_x_value * this.numberOfDaysmutiplier[this.last_x_metric];
      if (this.modifiedNumberOfDays > 0) {
        this.last_x_value = this.findOptimalMetric(
          this.modifiedNumberOfDays
        ).optimalValue;
        this.last_x_metric = this.findOptimalMetric(
          this.modifiedNumberOfDays
        ).optimalMetric;
      }
    },
    reCalculateExcludedNumberOfDays() {
      this.modifiedNumberOfDaysExcluded =
        this.exclude_x_value *
        this.numberOfDaysmutiplier[this.exclude_x_metric];
      if (this.modifiedNumberOfDaysExcluded > 0) {
        this.exclude_x_value = this.findOptimalMetric(
          this.modifiedNumberOfDaysExcluded
        ).optimalValue;
        this.exclude_x_metric = this.findOptimalMetric(
          this.modifiedNumberOfDaysExcluded
        ).optimalMetric;
      }
    },
    findOptimalMetric(days) {
      let optimalMetric, optimalValue;
      if (days % this.numberOfDaysmutiplier.Years === 0) {
        optimalMetric = 'Years';
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Years);
      } else if (days % this.numberOfDaysmutiplier.Months === 0) {
        optimalMetric = 'Months';
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Months);
      } else {
        optimalMetric = 'Days';
        optimalValue = days;
      }
      return {
        optimalMetric,
        optimalValue
      };
    },
    toggle($event) {
      if (this.tippyInstance) {
        if (this.tippyInstance.state.visible) {
          this.$emit('apply', this.getCurrentState());
          this.tippyInstance.hide();
        } else {
          this.tippyInstance.show();
        }
      }
    },
    updateTitle() {
      let title = this.last_x_value + ' ' + this.formattedNumberOfDaysMetric;
      if (this.exclude_x_value) {
        title +=
          ' except last ' +
          this.exclude_x_value +
          ' ' +
          this.formattedExcludedNumberOfDaysMetric;
      }
      title = this.isNew
        ? 'Custom Previous Days'
        : 'Last ' + title.toLowerCase();
      this.title = title;
    },
    onApply() {
      this.tippyInstance.hide();
      this.$emit('apply', this.getCurrentState());
      this.updateTitle();
    }
  }
};
</script>

<style lang="css" scoped>
.input_row {
  padding-left: 2.4rem;
  display: flex;
  margin-bottom: 0.8rem;
  line-height: 1.5;
}

.previous-days-selector-rb-select {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border-color: #e9eaeb;
  border-width: 1px;
  border: solid;
  width: 100%;
}
.previous_days_select-select-parent {
  margin-right: 3.2rem;
  max-width: 100px;
  width: 50%;
}
.previous-days-selector-input {
  max-width: 50px;
  outline: none;
  width: 20%;
  text-align: center;
  border-color: #e9eaeb;
  border-radius: 4px;
  border-width: 1px;
  border: solid;
  margin-right: 0.8rem;
  font-size: 1.3rem;
}
.previous_days_selector_hr {
  border-color: #e9eaeb;
  border-radius: 4px;
  border-width: 1px;
  border: solid;
}
.popover {
  min-width: 250px;
  min-height: 284px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  justify-content: space-between;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b333b;
  display: flex;
  flex-direction: column;
}
.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.token {
  border-radius: 25px;
}
.previous-days-active {
  border-color: #007cf6;
}
.row-top-border {
  border-top: solid 1px #e9eaeb;
}
.previous_days_selector_error {
  max-height: 1px;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
}
</style>
