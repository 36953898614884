import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import { formatter } from '@/utils/helpers/formatter.js';
import transformer from '@/utils/services/data-transformer';
import {
  Entities,
  EntitiesForPostProcessingStatus
} from '@/pages/toolsAndSettings/const.js';
// LOGIC FOR STANDARD DIGITAL SHELVES
const CONSTANTS = {
  catalog: {
    sheetName: 'Standard catalog',
    templateUrl:
      'https://taxonomy-and-tags.s3-us-west-2.amazonaws.com/templates/Standard+Catalog+Template.xlsx'
  },
  [Entities.campaign]: {
    sheetName: 'Campaign Taxonomy',
    templateUrl:
      'https://taxonomy-and-tags.s3-us-west-2.amazonaws.com/templates/Standard+Catalog+Template.xlsx'
  },
  content: {
    sheetName: 'Content Change',
    templateUrl:
      'https://taxonomy-and-tags.s3.us-west-2.amazonaws.com/contentChange/ContentSotChangeTemplate.xlsx'
  },
  variants: {
    sheetName: 'Variant Change',
    templateUrl:
      'https://taxonomy-and-tags.s3.us-west-2.amazonaws.com/changeInVariants/VariationAuthorityTemplate.xlsx'
  },
  'digital-shelves': {
    sheetName: 'Digital shelves',
    templateUrl:
      'https://taxonomy-and-tags.s3-us-west-2.amazonaws.com/templates/Standard+Digital+Shelves_+Template.xlsx'
  }
};

const getReadAPIPayload = (context, entityData) => {
  const {
    entity,
    searchText,
    searchKey = 'asin',
    requestBody = {}
  } = entityData;
  context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
  const {
    appliedFilters,
    page,
    limit,
    persistedFilters = []
  } = context.state[entity];
  const where = transformer.generateWhereClause(
    { dimensionNameValueList: [] },
    { values: appliedFilters }
  );
  context.commit('SET_ENTITY_SEARCH_TERM', { entity, searchText });
  if (Array.isArray(searchText)) {
    if (searchText.length) {
      const dimensionName = searchKey;
      if (dimensionName) {
        searchText.forEach((searchInput) => {
          where.dimensionNameValueList.push({
            dimensionName,
            dimensionValue: searchInput
          });
        });
      }
    }
  }
  if (typeof searchText === 'string' && searchText) {
    where.dimensionNameValueList.push({
      dimensionName: 'search',
      dimensionValue: searchText
    });
  }
  where.dimensionNameValueList = [
    ...where.dimensionNameValueList,
    ...persistedFilters
  ];
  return { entity, page, limit, where, requestBody };
};

const customWhereClauseTransformation = (where) =>
  where?.dimensionNameValueList?.length
    ? where.dimensionNameValueList.map((filter) => ({
        dimensionNameValueList: [filter]
      }))
    : [{ dimensionNameValueList: [] }];

const entityStateInit = (entityName) => {
  let taxonomyPersistedFilters = [];
  try {
    taxonomyPersistedFilters = JSON.parse(
      localStorage.getItem('taxonomyPersistedFilters')
    )?.[entityName];
  } catch (error) {
    console.log('No such key found in localstorage');
  }
  return {
    bExists: false,
    bErrorState: false,
    isLoading: false,
    errors: [],
    data: {},
    page: 1,
    limit: 10,
    previewData: {},
    templateUrl: CONSTANTS[entityName].templateUrl,
    appliedFilters:
      JSON.parse(
        localStorage.getItem(
          `${EntitiesForPostProcessingStatus[entityName]}-filter`
        )
      ) || {},
    searchText: '',
    savedPreviewStep: null,
    downloadLink: '',
    fileDetails: {
      fileBuffer: null,
      name: '',
      bValidationSuccess: false,
      lastModified: ''
    },
    persistedFilters: taxonomyPersistedFilters,
    transformedPreviewData: {}
  };
};
const filterWhereClauseTransformation = (where) =>
  where?.length
    ? where.map((filter) => ({
        dimensionNameValueList: filter.dimensionNameValueList
      }))
    : [{ dimensionNameValueList: [] }];

const initState = () => {
  const entities = [
    'digital-shelves',
    'catalog',
    'content',
    'variants',
    Entities.campaign
  ];
  const state = {};
  entities.forEach((entityName) => {
    state[entityName] = entityStateInit(entityName);
  });
  return state;
};

const getters = {
  getSavedPreviewStep: (state) => (entityName) => {
    return state[entityName].savedPreviewStep;
  },
  getEntityStore: (state) => (entityName) => {
    return state[entityName];
  },
  getEntityExists: (state) => (entityName) => {
    return state[entityName].bExists;
  },
  getEntityPreviewData: (state) => (entityName) => {
    return state[entityName].previewData;
  },
  getTransformedEntityPreviewData: (state) => (entityName) => {
    return state[entityName].transformedPreviewData;
  },
  getEntityFileDetails: (state) => (entityName) => {
    return state[entityName].fileDetails;
  },
  getEntityLoadingState: (state) => (entityName) => {
    return state[entityName].isLoading;
  },
  getEntityTemplateFile: (state) => (entityName) => {
    return state[entityName].templateUrl;
  },
  getEntityErrorList: (state) => (entityName) => {
    return state[entityName].errors;
  },
  getEntityDownloadLink: (state) => (entityName) => {
    return state[entityName].downloadLink;
  },
  getPersistedFilters: (state) => (entityName) => {
    return state[entityName].persistedFilters;
  }
};

const actions = {
  downloadEntity(context, entityData) {
    const { entity } = entityData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    let data = {
      entity: entity
    };
    return HttpService.post('ENTITY_DOWNLOAD', data)
      .then((status) => {
        context.commit('SET_ENTITY_DOWNLOAD_FILE', {
          entity,
          downloadLink: status.data.data.entityDownloadedPayload.s3FilePath
        });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  updateEntityFilters(context, data) {
    const entity = data.entity;
    const filters = data.filters;
    context.commit('SET_ENTITY_FILTERS', { entity, filters });
  },
  savedPreviewStep: async (context, entity) => {
    const response = await HttpService.get('GET_DIGITAL_SHELF_HEIRARCHY', {
      append: `?entityType=${entity}`
    });
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    try {
      const result = response?.data?.response;
      context.commit('SAVED_PREVIEW_STEP', { result, entity });
    } catch (error) {
      console.log(error);
    } finally {
      context.commit('SET_ENTITY_LOADING_STATE', { isLoading: false, entity });
    }
  },
  reactToPagination(context, data) {
    const entity = data.entity;
    const searchKey = data.body?.searchKey;
    const requestBody = data.body?.requestBody;
    const page = data.body.APIConfig.page;
    const limit = data.body.APIConfig.limit;
    const APIData = { entity, searchText, searchKey, requestBody };
    if (data.body.APIConfig.apiConfig) {
      APIData.apiConfig = data.body.APIConfig.apiConfig;
    }
    context.commit('SET_ENTITY_PAGE_AND_LIMIT', { entity, page, limit });
    const searchText = context.state[entity].searchText;
    const actionMap = {
      [Entities.catalog]: 'fetchEntityRead',
      [Entities.campaign]: 'fetchEntityReadV2'
    };
    return context.dispatch(actionMap?.[entity] || 'fetchSOTRead', {
      ...APIData
    });
  },
  resetEntity(context, data) {
    const entity = data.entity;
    context.commit('RESET_ENTITY_STATE', entity);
  },
  triggerIngestion(context, entityData) {
    const {
      file,
      sheetName,
      entity,
      replaceAll,
      skipPostTrigger,
      skipFormatting,
      fixedColumns = null
    } = entityData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('sheetName', sheetName);
    data.append('entity', entity);
    data.append('replaceAll', replaceAll);
    data.append('skipFormatting', skipFormatting);
    data.append('skipPostTrigger', skipPostTrigger);
    let triggerResponse = {
      errors: [],
      data: [],
      bValidationSuccess: false,
      fileDetails: {
        fileBuffer: file,
        name: file.name,
        lastModified: file.lastModified,
        lastModifiedDate: formatter(file.lastModifiedDate, 'datetime')
      },
      success: true
    };

    if (fixedColumns) {
      data.append('fixedColumns', JSON.stringify(fixedColumns));
      triggerResponse = {
        ...triggerResponse,
        fixedColumns
      };
    }
    context.commit('SET_ENTITY_VALIDATION_ERRORS', { entity, errors: [] });

    return HttpService.post('ENTITY_VALIDATE', data)
      .then((status) => {
        if (status.data.success) {
          triggerResponse.success = true;
          triggerResponse.data = status.data;
          triggerResponse.bValidationSuccess = true;
          context.commit('SET_ENTITY_PREVIEW_DATA', {
            entity,
            previewData: status.data
          });
        } else {
          let errors = [];
          status.data.forEach((checkData) => {
            errors = errors.concat(checkData.errors);
          });
          errors = errors.map((errorInfo) => {
            return {
              errorEntity: errorInfo.errorEntity,
              errorDescription: errorInfo.errorReason
            };
          });
          triggerResponse.success = false;
          triggerResponse.errors = errors;
          context.commit('SET_ENTITY_VALIDATION_ERRORS', { entity, errors });
        }
        context.commit('SET_ENTITY_EXISTING_FILE_DETAILS', {
          entity,
          fileDetails: triggerResponse.fileDetails
        });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return triggerResponse;
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  triggerSOTIngestion(context, entityData) {
    const {
      file,
      sheetName,
      entity,
      replaceAll,
      skipPostTrigger,
      skipFormatting
    } = entityData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('sheetName', sheetName);
    data.append('entity', entity);
    data.append('replaceAll', replaceAll);
    data.append('skipFormatting', skipFormatting);
    data.append('skipPostTrigger', skipPostTrigger);
    const triggerResponse = {
      errors: [],
      data: [],
      bValidationSuccess: false,
      fileDetails: {
        fileBuffer: file,
        name: file.name,
        lastModified: file.lastModified,
        lastModifiedDate: formatter(file.lastModifiedDate, 'datetime')
      },
      success: true
    };
    context.commit('SET_ENTITY_VALIDATION_ERRORS', { entity, errors: [] });

    return HttpService.post('ENTITY_VALIDATE', data)
      .then((status) => {
        if (status.data.success) {
          triggerResponse.success = true;
          triggerResponse.bValidationSuccess = true;
        } else {
          let errors = [];
          status.data.forEach((checkData) => {
            errors = errors.concat(checkData.errors);
          });
          errors = errors.map((errorInfo) => {
            return {
              errorEntity: errorInfo.errorEntity,
              errorDescription: errorInfo.errorReason
            };
          });
          triggerResponse.success = false;
          triggerResponse.errors = errors;
          context.commit('SET_ENTITY_VALIDATION_ERRORS', { entity, errors });
        }
        context.commit('SET_ENTITY_EXISTING_FILE_DETAILS', {
          entity,
          fileDetails: triggerResponse.fileDetails
        });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return triggerResponse;
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  fetchEntityExistsStatus(context, entityData) {
    const { entity } = entityData;
    if (context.getters.getEntityExists(entity)) {
      return true;
    }
    const entityName = [Entities.catalog, Entities.campaign].includes(entity)
      ? entity + 'Taxonomy'
      : entity;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    let data = {
      entityType: entityName,
      ...([Entities.catalog, Entities.campaign].includes(entity)
        ? {
            componentType: 'Taxonomy'
          }
        : {})
    };

    return HttpService.post('ENTITY_EXISTS_V2', data)
      .then((status) => {
        const bExists = status.data.entityExists;
        if (bExists) {
          context.commit('SET_ENTITY_EXISTING_FILE_DETAILS', {
            entity,
            fileDetails: status.data.fileDetails || {
              fileBuffer: null,
              name: '',
              bValidationSuccess: false,
              lastModified: ''
            }
          });
        }
        context.commit('SET_ENTITY_EXISTS_STATUS', { entity, bExists });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return bExists;
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  fetchEntityRead(context, entityData) {
    const { entity, page, limit, where, requestBody } = getReadAPIPayload(
      context,
      entityData
    );
    let data = {
      entity,
      page,
      limit,
      where,
      ...requestBody
    };

    return HttpService.post('ENTITY_READ', data)
      .then((response) => {
        context.commit('SET_ENTITY_PREVIEW_DATA', {
          entity,
          previewData: response.data
        });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  fetchEntityReadV2(context, entityData) {
    const { entity, page, limit, where, requestBody } = getReadAPIPayload(
      context,
      entityData
    );
    const transformedWhere = transformer.transformWhereStructure([
      { dimensionNameValueList: where.dimensionNameValueList }
    ]);
    let data = {
      entityType: entity,
      pagination: {
        page,
        limit
      },
      where: filterWhereClauseTransformation(transformedWhere.where),
      ...requestBody
    };

    return HttpService.post('ENTITY_READ_V2', data)
      .then((response) => {
        if (response?.data?.success) {
          context.commit('SET_ENTITY_PREVIEW_DATA', {
            entity,
            previewData: { data: { entityData: { ...response.data } } }
          });
        }
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  fetchSOTRead(context, entityData) {
    const { entity, apiConfig } = entityData;
    const { page, limit } = context.state[entity];
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    return HttpLayer.post({
      cube: 'EXECUTE_CUBE_API',
      APIData: { ...apiConfig, limit, page },
      header: { headers: { cache: 'false' } }
    })
      .then((response) => {
        const rows = transformer.mergeResultDimension(response.data);
        const totalRows = rows?.[0]?.auto_cubesdk_count;
        context.commit('SET_ENTITY_PREVIEW_DATA', {
          entity,
          previewData: { rows, totalRows }
        });
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  mergeSelectedEntity(context, mergeData) {
    const { entity } = mergeData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });

    return HttpService.post('ENTITY_MERGE', mergeData)
      .then(() => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return {
          success: true
        };
      })
      .catch((err) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        console.error(err);
        return {
          success: false
        };
      });
  },
  deleteEntityColumn(context, deleteData) {
    const { entity, deletePayload } = deleteData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    return HttpService.post('ENTITY_DELETE', deletePayload)
      .then((response) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return {
          success: true
        };
      })
      .catch((err) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        console.error(err);
        return {
          success: false
        };
      });
  },
  AddOrRenameEntityColumn(context, renameData) {
    const { entity, renamePayload } = renameData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    return HttpService.post('ENTITY_RENAME', renamePayload)
      .then((response) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return {
          success: true
        };
      })
      .catch((err) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        console.error(err);
        return {
          success: false
        };
      });
  },
  saveEntityDataUpdates(context, dataUpdates) {
    const { entity, dataUpdatePayload } = dataUpdates;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    return HttpService.post('ENTITY_UPDATE_METADATA', dataUpdatePayload)
      .then((response) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return {
          success: true
        };
      })
      .catch((err) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        console.error(err);
        return {
          success: false
        };
      });
  },
  updateEntitiesForMetadata(context, dataUpdates) {
    const { entity, dataUpdatePayload } = dataUpdates;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    return HttpService.post('ENTITY_UPDATE_ENTITY', dataUpdatePayload)
      .then((response) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        return {
          success: true
        };
      })
      .catch((err) => {
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
        console.error(err);
        return {
          success: false
        };
      });
  },
  setEntitySearchValue(context, searchPayload) {
    const { entity, searchText } = searchPayload;
    context.commit('SET_ENTITY_SEARCH_TERM', { entity, searchText });
  },
  setEntityLoading(context, { isLoading, entity }) {
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading, entity });
  },
  downloadExcel(context, entityData) {
    const { entity, downloadNewFile = false, ...requestBody } = entityData;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });
    let data = {
      entity,
      where: customWhereClauseTransformation({
        dimensionNameValueList: []
      }),
      downloadNewFile,
      page: 1,
      limit: 50000,
      ...requestBody
    };
    return HttpService.post('ENTITY_DOWNLOAD_EXCEL', data, {
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const disposition = response?.headers?.['content-disposition'];
        let fileName = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches?.[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        } else {
          fileName = 'CampaignTaxonomyTemplate.xlsx';
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  setPersistedFilters(context, data) {
    const { entity, filters } = data;
    context.commit('SET_ENTITY_PERSISTED_FILTERS', {
      entity,
      filters
    });
  },
  fetchUnmappedCampaigns(context, data) {
    const { entity, retailer, whereClause, ...restData } = data;
    context.commit('SET_ENTITY_LOADING_STATE', { isLoading: true, entity });

    let body = {
      entityType: entity,
      pagination: {
        page: 1,
        limit: 50
      },
      where: customWhereClauseTransformation(whereClause),
      ...restData
    };

    return HttpService.post('ENTITY_READ_V2', body)
      .then((response) => {
        if (response?.data?.success) {
          context.commit('SET_ENTITY_PREVIEW_DATA', {
            entity,
            previewData: { data: { entityData: { ...response.data } } }
          });
          context.commit('TRANSFORM_PREVIEW_DATA', {
            entity,
            previewData: { data: { entityData: { ...response.data } } }
          });
        }
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_ENTITY_LOADING_STATE', {
          isLoading: false,
          entity
        });
      });
  },
  clearPersistedFilters(context, entity) {
    context.commit('SET_ENTITY_PERSISTED_FILTERS', {
      entity,
      filters: []
    });
    localStorage.setItem(
      'taxonomyPersistedFilters',
      JSON.stringify({ [entity]: [] })
    );
  }
};

const mutations = {
  RESET_ENTITY_STATE: (state, entityName) => {
    Vue.set(state, entityName, entityStateInit(entityName));
  },
  SET_ENTITY_SEARCH_TERM: (state, data) => {
    const { entity, searchText } = data;
    Vue.set(state[entity], 'searchText', searchText);
  },
  SET_ENTITY_FILTERS: (state, data) => {
    const { entity, filters: appliedFilters } = data;
    Vue.set(state[entity], 'appliedFilters', appliedFilters);
  },
  SAVED_PREVIEW_STEP: (state, data) => {
    const { entity, result } = data;
    Vue.set(state[entity], 'savedPreviewStep', result);
  },
  SET_ENTITY_VALIDATION_ERRORS: (state, data) => {
    const { errors, entity } = data;
    Vue.set(state[entity], 'errors', errors);
  },
  SET_ENTITY_LOADING_STATE: (state, data) => {
    const { isLoading, entity } = data;
    Vue.set(state[entity], 'isLoading', isLoading);
  },
  SET_ENTITY_PAGE_AND_LIMIT: (state, data) => {
    const { page, limit, entity } = data;
    Vue.set(state[entity], 'page', page);
    Vue.set(state[entity], 'limit', limit);
  },
  SET_ENTITY_EXISTING_FILE_DETAILS: (state, data) => {
    const { fileDetails, entity } = data;
    Vue.set(state[entity], 'fileDetails', fileDetails);
  },
  SET_ENTITY_DOWNLOAD_FILE: (state, data) => {
    const { downloadLink, entity } = data;
    Vue.set(state[entity], 'downloadLink', downloadLink);
  },
  SET_ENTITY_EXISTS_STATUS: (state, data) => {
    const { entity, bExists } = data;
    Vue.set(state[entity], 'bExists', bExists);
  },
  SET_ENTITY_PREVIEW_DATA: (state, data) => {
    const { entity, previewData } = data;
    Vue.set(state[entity], 'previewData', previewData);
  },
  SET_ENTITY_PERSISTED_FILTERS: (state, data) => {
    const { entity, filters } = data;
    Vue.set(state[entity], 'persistedFilters', filters);
    localStorage.setItem(
      'taxonomyPersistedFilters',
      JSON.stringify({ [entity]: filters })
    );
  },
  TRANSFORM_PREVIEW_DATA: (state, data) => {
    const { entity, previewData } = data;
    const preview = previewData?.data?.entityData;

    Vue.set(state[entity], 'transformedPreviewData', {
      count: preview?.pagination?.final_auto_cubesdk_count,
      data: preview?.response?.[0]?.entityDataAndValuesList?.map((entity) => ({
        ...entity,
        ...(entity?.data?.[0] || [])
      }))
    });
  },
  INIT_SETUP: (state, data) => {
    const initObject = initState();
    Object.keys(initObject).forEach((key) => {
      Vue.set(state, key, initObject[key]);
    });
  }
};
export default {
  state: initState(),
  getters,
  mutations,
  actions
};
