<template>
  <div class="create-budget-plan widget--full-screen">
    <!-- Header Start -->
    <loader
      v-if="!latestStrategyFetched"
      class="fill--parent loader-z-index u-bg-color-grey-white"
      :color="'#007cf6'"
    />
    <section
      class="createBudgetPlan--header u-display-flex u-flex-align-items-center u-spacing-p-l"
    >
      <div
        class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-width-100"
      >
        <div class="u-display-flex u-flex-align-items-center u-width-100">
          <span @click="closePlan()">
            <rb-icon
              :icon="'cross'"
              class="u-color-grey-lighter rb-icon--x-medium u-cursor-pointer u-spacing-mr-s"
            />
          </span>
          <input
            v-model="budgetPlanTitle"
            type="text"
            maxlength="56"
            :class="[
              budgetPlanTitle !== 'Untitled Budget Plan' || isTitleInputClicked
                ? 'active-state'
                : ' u-color-grey-lighter'
            ]"
            class="heading--input u-text-overflow-ellipsis u-font-size-1 u-font-weight-600"
            @click="titleInputClicked()"
            @input="updateBudgetPlanTitle"
          />
        </div>
        <input
          v-model="budgetPlanDescription"
          type="text"
          maxlength="160"
          :placeholder="placeholder"
          class="heading--input budget-plan-desc u-font-size-4 u-spacing-pt-s u-spacing-ml-xl"
          @input="updateBudgetPlanDescription"
        />
      </div>
    </section>
    <!-- Header ends -->
    <StepComponent
      v-if="latestStrategyFetched"
      :enabled-bread-crumbs="enabledBreadCrumbs"
      :disable-bread-crumb-click-action="true"
      :set-active-selection="activeSelection"
      :show-step-bullet-description="false"
      :module-class="'budgetStep'"
      :config="stepsConfig"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import loader from '@/components/basic/loader';
import { steps } from '@/pages/budget-planner-v2/create-plan/stepConfig.js';
import {
  updateClonedPlan,
  convertExecutionStrategyToTempRow,
  ignoreAGValues
} from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/helpers.js';
import { cloneDeep } from 'lodash';
import utils from '@/utils/helpers';

export default {
  components: {
    StepComponent,
    loader
  },
  data() {
    return {
      budgetPlanTitle: '',
      budgetPlanDescription: '',
      enabledBreadCrumbs: [0],
      isTitleInputClicked: false,
      placeholder: 'Give this budget plan a description.',
      stepsConfig: [],
      closeBudgetPlan: false,
      tempRowData: {}
    };
  },
  computed: {
    getBudgetPlanTitle() {
      return this.$store.getters['budgetPlannerOmni/getBudgetPlanTitle'];
    },
    getBudgetPlanDescription() {
      return this.$store.getters['budgetPlannerOmni/getBudgetPlanDescription'];
    },
    activeSelection() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getCreatePlanActiveSelection'
      ];
    },
    latestStrategyFetched() {
      return this.$store.getters['budgetPlannerOmni/getLatestStrategyFetched'];
    },
    strategyLevels() {
      return this.$store.getters[
        'budgetPlannerOmni/getSelectedLevelsFromStrategy'
      ];
    },
    budgetPlanInputTable() {
      return this.$store.getters[
        'budgetPlannerOmni/getBudgetPlanInputTableRows'
      ];
    },
    dropdownSelection() {
      return this.$store.getters['budgetPlannerOmni/getDropdownSelectionMap'];
    },
    isAutomationsEnabled() {
      const automationsConfigCheck = Vue.options.filters.config_check(
        'feature.pages.boV2_automations'
      )?.enable;
      const internalUserCheck = utils.internalUserCheck(window.user);
      return automationsConfigCheck && internalUserCheck;
    }
  },
  watch: {
    getBudgetPlanTitle: {
      handler(newVal) {
        this.budgetPlanTitle = newVal;
      },
      immediate: true
    },
    getBudgetPlanDescription: {
      handler(newVal) {
        this.budgetPlanDescription = newVal;
      },
      immediate: true
    }
  },
  async created() {
    this.$store.commit('budgetPlannerOmni/RESET_COMPLETE_BUDGET_PLAN_STATE');
    const year = this.$route.query.nextYearPlan
      ? new Date().getFullYear() + 1
      : null;
    await this.$store.dispatch('budgetPlannerOmni/fetchLatestStrategy', year);
    if (this.$route.query.page === 'seasonality') {
      await this.seasonalitySetup(year);
    }
    const myVueInstance = new Vue();
    const automationsConfigEnabled =
      myVueInstance.$options.filters.config_check(
        'feature.pages.boV2_automations'
      )?.enable;
    this.stepsConfig = steps(
      this,
      automationsConfigEnabled && utils.internalUserCheck(window.user)
    );
    this.refreshWeights();
  },
  methods: {
    refreshWeights() {
      this.$store.dispatch('budgetPlannerOmni/refreshWeights', 1);
    },
    async seasonalitySetup(year) {
      // fetch latest budget plan
      await this.$store.dispatch('budgetPlannerOmni/fetchLatestBudgetPlan', {
        isNextYearPlan: !!year,
        isAutomationsEnabled: this.isAutomationsEnabled
      });
      // update budget plan input table
      convertExecutionStrategyToTempRow(
        this.budgetPlanInputTable.rows,
        this.tempRowData
      );
      // save input budget plan table data
      this.saveInputBudgetPlanTable();
      // form pacing payload with new input table data
      this.$store.dispatch('budgetPlannerOmni/formPacingPayload', {
        isNextYearPlan: !!year,
        isAutomationsEnabled: this.isAutomationsEnabled
      });
      // create seasonality event map
      this.$store.commit('budgetPlannerOmni/RESTORE_SEASONALITY_EVENT_MAP');
      // remove seasonality from route
      this.$router.push({ query: {} });
    },
    saveInputBudgetPlanTable() {
      const clonedPlan = cloneDeep(this.budgetPlanInputTable.rows);
      updateClonedPlan(
        clonedPlan,
        this.tempRowData,
        ignoreAGValues,
        this.dropdownSelection
      );
      this.tempRowData = {};
      this.$store.dispatch(
        'budgetPlannerOmni/updateBudgetPlanInputTableRows',
        clonedPlan
      );
    },
    updateBudgetPlanTitle() {
      this.$store.commit(
        'budgetPlannerOmni/MUTATE_BUDGET_PLAN_TITLE',
        this.budgetPlanTitle
      );
    },
    updateBudgetPlanDescription() {
      this.$store.commit(
        'budgetPlannerOmni/MUTATE_BUDGET_PLAN_DESCRIPTION',
        this.budgetPlanDescription
      );
    },
    closeModal(val) {
      if (val) {
        this.showModal = false;
      }
    },
    closePlan() {
      this.$store.commit('budgetPlannerOmni/MUTATE_RESET_BUDGET_INPUT');
      this.$store.commit('budgetPlannerOmni/RESET_COMPLETE_BUDGET_PLAN_STATE');
      this.$router.push({
        name: 'budget-planner-dashboard-v2'
      });
    },
    titleInputClicked() {
      this.isTitleInputClicked = true;
    }
  }
};
</script>

<style lang="css" scoped>
.loader-z-index {
  z-index: 103;
}
.heading--input {
  width: 640px;
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}

.budget-plan-desc {
  font-style: italic;
}

.create-budget-plan {
  height: 100%;
  width: 100%;
  top: 0;
  overflow: hidden;
  z-index: 103;
}
.createBudgetPlan--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: fixed;
  width: 100%;
  z-index: 101;
}
</style>
