import {
  baseConfig,
  addProductToLineItemsConfig
} from '@/components/widgets/collaboration/eventConfigUtils.js';

export default {
  config: (retailer) => {
    let events = {};
    /**
     doing this, because we've updated the actionTypes overtime, and we want to have
     backwards compatibility for actions performed before the change.
     ex meijerMonthlyAndDailyPacingChange was updated to criteoCommonMonthlyAndDailyPacingChange
     **/
    [
      'samsClub',
      'criteoCommon',
      [`${retailer}CriteoCommon`],
      [retailer]
    ].forEach((configRetailerKey) => {
      events = {
        ...events,
        [`${configRetailerKey}CampaignEditBudget`]: {
          props: {
            old_budget: 'old_budget',
            new_budget: 'budget',
            ...baseConfig()
          },
          message: 'adjusted the campaign budget',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'old_budget',
              newValue: 'new_budget',
              component: 'arrowChange',
              'sub-heading': 'New Budget'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: true
          }
        },
        [`${configRetailerKey}CampaignEditAttribution`]: {
          props: {
            oldClickWindow: 'old_clickAttributionWindow',
            newClickWindow: 'clickAttributionWindow',
            oldViewWindow: 'old_viewAttributionWindow',
            newViewWindow: 'viewAttributionWindow',
            oldClickProduct: 'old_clickAttributionScope',
            newClickProduct: 'clickAttributionScope',
            oldViewProduct: 'old_viewAttributionScope',
            newViewProduct: 'viewAttributionScope',

            ...baseConfig()
          },
          message: 'updated the attribution settings',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'oldClickWindow',
              newValue: 'newClickWindow',
              component: 'arrowChange',
              'sub-heading': 'Post Click Lookback window',
              hideIfNull: true
            },
            {
              oldValue: 'oldViewWindow',
              newValue: 'newViewWindow',
              component: 'arrowChange',
              'sub-heading': 'Post View Lookback window',
              hideIfNull: true
            },
            {
              oldValue: 'oldClickProduct',
              newValue: 'newClickProduct',
              component: 'arrowChange',
              'sub-heading': 'Post Click Product Match',
              hideIfNull: true
            },
            {
              oldValue: 'oldViewProduct',
              newValue: 'newViewProduct',
              component: 'arrowChange',
              'sub-heading': 'Post View Product Match',
              hideIfNull: true
            }
          ],
          customArrowComponent: {
            multiple: true
            // hideIfNull: true
          }
        },
        [`${configRetailerKey}UpdateLineItemState`]: {
          props: {
            oldValue: 'status',
            newValue: 'current_status',
            ...baseConfig()
          },
          message: 'updated the line item status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Line Item status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}LineItemNameChange`]: {
          props: {
            oldValue: 'current_name',
            newValue: 'new_name',
            ...baseConfig()
          },
          message: 'updated the line item name',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Line Item name',
              caseSensitive: true
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}LineItemDateChange`]: {
          props: {
            previousStartDate: 'current_start_date',
            newStartDate: 'new_start_date',
            previousEndDate: 'current_end_date',
            newEndDate: 'new_end_date',
            ...baseConfig()
          },
          message: 'updated the line item date.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'previousStartDate',
              newValue: 'newStartDate',
              component: 'arrowChange',
              'sub-heading': 'Start date',
              hideIfNull: true
            },
            {
              oldValue: 'previousEndDate',
              newValue: 'newEndDate',
              component: 'arrowChange',
              'sub-heading': 'End date',
              hideIfNull: true
            }
          ],
          displayType: 'string',
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AddProductToLineItem`]:
          addProductToLineItemsConfig,
        [`${configRetailerKey}BulkAddProductToLineItem`]:
          addProductToLineItemsConfig,
        [`${configRetailerKey}AddRemoveKeywordsToLineItem`]: {
          props: {
            keyword: 'keyword',
            keywordMatchType: 'keywordMatchType',
            campaign_name: 'campaign_name',
            line_item_name: 'line_item_name',
            is_deleted: 'is_deleted',
            bid: 'bid',
            ...baseConfig()
          },
          message: (data) => {
            let deletedOrNot = 'added';
            let negativeOrNot = '';
            if (data?.keywordMatchType?.toLowerCase()?.includes('negative')) {
              negativeOrNot = 'negative ';
            }
            if (data?.is_deleted === 'true' || data?.is_deleted === true) {
              deletedOrNot = 'removed';
            }
            return `${deletedOrNot} a ${negativeOrNot}keyword.`;
          },
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Line Item Name',
              textMessage: 'campaign_name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Line Item Name',
              textMessage: 'line_item_name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match type',
              textMessage: 'keywordMatchType',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'keyword'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Bid',
              textMessage: 'bid',
              hideIfNull: true
            }
          ]
        },
        [`${configRetailerKey}LineItemAddNegativeKeyword`]: {
          props: {
            keyword: 'keyword',
            keywordMatchType: 'keywordMatchType',
            campaign_name: 'campaign_name',
            ...baseConfig()
          },
          message: 'added a negative keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Line Item Name',
              textMessage: 'campaign_name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match type',
              textMessage: 'keywordMatchType'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'keyword'
            }
          ]
        },
        [`${configRetailerKey}LineItemKeywordsUpdateBids`]: {
          props: {
            oldValue: 'oldBid',
            newValue: 'newBid',
            ...baseConfig()
          },
          message: 'adjusted the keyword bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Keyword bid',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}AddKeywordToCampaigns`]: {
          props: {
            textMessage: 'new_keyword_text',
            matchType: 'new_match_type',
            newValue: 'new_bid',
            adgroupName: 'adgroupName',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added a new keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Adgroup Name',
              textMessage: 'adgroupName'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match Type',
              textMessage: 'matchType'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Keyword bid',
              textMessage: 'newValue',
              displayType: 'currency'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ]
        },
        [`${configRetailerKey}AddNegativeKeywordToCampaigns`]: {
          props: {
            textMessage: 'new_keyword_text',
            matchType: 'new_match_type',
            adgroupName: 'adgroupName',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added a new negative keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Adgroup Name',
              textMessage: 'adgroupName'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match Type',
              textMessage: 'matchType'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ]
        },
        [`${configRetailerKey}ProductAdBidChange`]: {
          props: {
            oldValue: 'current_bid',
            newValue: 'new_bid',
            ...baseConfig()
          },
          message: 'adjusted the sku bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU bid',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}ProductAdStatusChange`]: {
          props: {
            oldValue: 'current_status',
            newValue: 'status',
            ...baseConfig()
          },
          message: 'updated the skus status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}UnpauseProductStatus`]: {
          props: {
            oldValue: 'previousStatus',
            newValue: 'newStatus',
            ...baseConfig()
          },
          message: 'updated the skus status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}PauseProductStatus`]: {
          props: {
            oldValue: 'previousStatus',
            newValue: 'newStatus',
            ...baseConfig()
          },
          message: 'updated the skus status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}UpdateKeywordState`]: {
          props: {
            oldValue: 'current_state',
            newValue: 'state',
            ...baseConfig()
          },
          message: 'updated the keyword status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Keyword status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}KeywordBidChange`]: {
          props: {
            oldValue: 'current_bid',
            newValue: 'new_bid',
            ...baseConfig()
          },
          message: 'adjusted the keyword bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Keyword bid',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignBudgetChange`]: {
          props: {
            oldValue: 'daily_budget',
            newValue: 'new_daily_budget',
            ...baseConfig()
          },
          message: 'adjusted the campaign daily budget.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign daily budget',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}BulkCampaignMetadataChange`]: {
          props: {
            oldValue: 'previousBudget',
            newValue: 'newBudget',
            ...baseConfig()
          },
          message: 'adjusted the campaign daily budget.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign daily budget',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignTotalBudgetChange`]: {
          props: {
            oldValue: 'total_budget',
            newValue: 'new_total_budget',
            ...baseConfig()
          },
          message: 'adjusted the campaign total budget.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign total budget',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignStatusChange`]: {
          props: {
            oldValue: 'current_status',
            newValue: 'status',
            ...baseConfig()
          },
          message: 'updated the campaign status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign status'
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}BidChange`]: {
          props: {
            oldValue: 'previousBid',
            newValue: 'newBid',
            ...baseConfig()
          },
          message: 'adjusted the keyword bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Keyword bid'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog',
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignDateChange`]: {
          props: {
            start_date: 'start_date',
            new_start_date: 'new_start_date',
            end_date: 'end_date',
            new_end_date: 'new_end_date',
            ...baseConfig()
          },
          message: 'updated the campaign date.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'start_date',
              newValue: 'new_start_date',
              component: 'arrowChange',
              'sub-heading': 'Start date'
            },
            {
              oldValue: 'end_date',
              newValue: 'new_end_date',
              component: 'arrowChange',
              'sub-heading': 'End date'
            }
          ],
          displayType: 'string',
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AddSkuToCampaigns`]: {
          props: {
            sku: 'sku',
            image_url: 'image_url',
            product_title: 'product_title',
            product_url: 'product_url',
            adgroupName: 'adgroupName',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added the SKU for the selected campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'Campaign budget'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Adgroup Name',
              textMessage: 'adgroupName'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignMetadataChange`]: {
          props: {
            newName: 'name',
            oldName: 'view_old_name',
            newState: 'state',
            oldState: 'view_old_state',
            newEndDate: 'view_end_date',
            oldEndDate: 'view_old_end_date',
            newStartDate: 'view_start_date',
            oldStartDate: 'view_old_start_date',
            previousDailyBudget: 'previousDailyBudget',
            previousTotalBudget: 'previousTotalBudget',
            newDailyBudget: 'newDailyBudget',
            newTotalBudget: 'newTotalBudget',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated the campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'oldName',
              newValue: 'newName',
              component: 'arrowChange',
              'sub-heading': 'Name',
              hideIfNull: true
            },
            {
              oldValue: 'oldState',
              newValue: 'newState',
              component: 'arrowChange',
              'sub-heading': 'State',
              hideIfNull: true
            },
            {
              oldValue: 'oldEndDate',
              newValue: 'newEndDate',
              component: 'arrowChange',
              'sub-heading': 'End date',
              hideIfNull: true
            },
            {
              oldValue: 'oldStartDate',
              newValue: 'newStartDate',
              component: 'arrowChange',
              'sub-heading': 'Start date',
              hideIfNull: true
            },
            {
              oldValue: 'previousDailyBudget',
              newValue: 'newDailyBudget',
              component: 'arrowChange',
              'sub-heading': 'Daily Budget',
              displayType: 'currency',
              hideIfNull: true
            },
            {
              oldValue: 'previousTotalBudget',
              newValue: 'newTotalBudget',
              component: 'arrowChange',
              'sub-heading': 'Total Budget',
              displayType: 'currency',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}ProductadMetadataChange`]: {
          props: {
            oldStatus: 'previousStatus',
            newStatus: 'newStatus',
            itemId: 'item_id',
            itemName: 'itemName',
            newBid: 'new_bid',
            oldBid: 'old_bid',
            adgroupName: 'adgroupName',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated the sku.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Ad group name',
              textMessage: 'adgroupName',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'SKU name',
              textMessage: 'itemName',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'SKU',
              textMessage: 'itemId',
              hideIfNull: true
            },
            {
              oldValue: 'oldStatus',
              newValue: 'newStatus',
              component: 'arrowChange',
              'sub-heading': 'Ad SKU status',
              hideIfNull: true
            },
            {
              oldValue: 'oldBid',
              newValue: 'newBid',
              component: 'arrowChange',
              'sub-heading': 'SKU bid',
              displayType: 'currency',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          },
          displayType: 'string'
        },
        [`${configRetailerKey}KeywordMetadataChange`]: {
          props: {
            previousStatus: 'previousStatus',
            newStatus: 'newStatus',
            oldBid: 'view_old_bid',
            newBid: 'new_bid',
            adgroupName: 'adgroup_name',
            keyword: 'keyword_text',
            matchType: 'match_type',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated the keyword',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Ad group name',
              textMessage: 'adgroupName',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'keyword',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match type',
              textMessage: 'matchType',
              hideIfNull: true
            },
            {
              oldValue: 'previousStatus',
              newValue: 'newStatus',
              component: 'arrowChange',
              'sub-heading': 'Keyword Status',
              hideIfNull: true
            },
            {
              oldValue: 'oldBid',
              newValue: 'newBid',
              component: 'arrowChange',
              'sub-heading': 'Keyword Bid',
              displayType: 'currency',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AdgroupMetadataChange`]: {
          props: {
            oldName: 'view_old_adgroup_name',
            newName: 'view_adgroup_name',
            oldStatus: 'view_old_state',
            newStatus: 'status',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated the ad group.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'oldStatus',
              newValue: 'newStatus',
              component: 'arrowChange',
              'sub-heading': 'Status',
              hideIfNull: true
            },
            {
              oldValue: 'oldName',
              newValue: 'newName',
              component: 'arrowChange',
              'sub-heading': 'Adgroup Name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}ProfileMetadataChange`]: {
          props: {
            oldStatus: 'oldStatus',
            newStatus: 'newStatus',
            search_amp_name: 'search_amp_name',
            old_search_amp_name: 'old_search_amp_name',
            headline_text: 'headline_text',
            old_headline_text: 'old_headline_text',
            click_url: 'click_url',
            old_click_url: 'old_click_url',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated the profile.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'oldStatus',
              newValue: 'newStatus',
              component: 'arrowChange',
              'sub-heading': 'Status',
              hideIfNull: true
            },
            {
              oldValue: 'old_search_amp_name',
              newValue: 'search_amp_name',
              component: 'arrowChange',
              'sub-heading': 'Brand name',
              hideIfNull: true
            },
            {
              oldValue: 'old_headline_text',
              newValue: 'headline_text',
              component: 'arrowChange',
              'sub-heading': 'Headline',
              hideIfNull: true
            },
            {
              oldValue: 'old_click_url',
              newValue: 'click_url',
              component: 'arrowChange',
              'sub-heading': 'URL',
              caseSensitive: true,
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AddProfileToCampaigns`]: {
          props: {
            newStatus: 'newStatus',
            search_amp_name: 'search_amp_name',
            headline_text: 'headline_text',
            click_url: 'click_url',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added a profile.',
          component: 'name-message-template',
          midComponents: [
            {
              textMessage: 'newStatus',
              component: 'textMessage',
              'sub-heading': 'Status',
              hideIfNull: true
            },
            {
              textMessage: 'search_amp_name',
              component: 'textMessage',
              'sub-heading': 'Brand name',
              hideIfNull: true
            },
            {
              textMessage: 'headline_text',
              component: 'textMessage',
              'sub-heading': 'Headline',
              hideIfNull: true
            },
            {
              textMessage: 'click_url',
              component: 'textMessage',
              'sub-heading': 'URL',
              caseSensitive: true,
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignCancelReview`]: {
          props: {
            newName: 'name',
            oldName: 'view_old_name',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'cancelled the campaign review',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'newName',
              newValue: 'oldName',
              component: 'arrowChange',
              'sub-heading': 'Campaign Name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignSubmitReview`]: {
          props: {
            newName: 'name',
            oldName: 'view_old_name',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'submitted the campaign for review',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'newName',
              newValue: 'oldName',
              component: 'arrowChange',
              'sub-heading': 'Campaign Name',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}PlacementBidMetadataChange`]: {
          props: {
            campaign_name: 'name',
            campaign_id: 'campaign_id',
            placement_type: 'placement_type',
            old_multiplier: 'old_multiplier',
            new_multiplier: 'new_multiplier',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated placement bids',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Campaign',
              textMessage: 'campaign_name'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Placement Type',
              textMessage: 'placement_type'
            },
            {
              oldValue: 'old_multiplier',
              newValue: 'new_multiplier',
              component: 'arrowChange',
              'sub-heading': 'Multiplier',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}PlatformBidMetadataChange`]: {
          props: {
            campaign_name: 'name',
            campaign_id: 'campaign_id',
            platform_type: 'platform_type',
            old_multiplier: 'old_multiplier',
            new_multiplier: 'new_multiplier',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated platform bids',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Campaign',
              textMessage: 'campaign_name'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Platform Type',
              textMessage: 'platform_type'
            },
            {
              oldValue: 'old_multiplier',
              newValue: 'new_multiplier',
              component: 'arrowChange',
              'sub-heading': 'Multiplier',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AddPlacementBidMetadata`]: {
          props: {
            campaign_name: 'name',
            campaign_id: 'campaign_id',
            placement_type: 'placement_type',
            old_multiplier: 'old_multiplier',
            new_multiplier: 'new_multiplier',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added placement bids',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Campaign',
              textMessage: 'campaign_name'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Placement Type',
              textMessage: 'placement_type'
            },
            {
              oldValue: 'old_multiplier',
              newValue: 'new_multiplier',
              component: 'arrowChange',
              'sub-heading': 'Multiplier',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}AddPlatformBidMetadata`]: {
          props: {
            campaign_name: 'name',
            campaign_id: 'campaign_id',
            platform_type: 'platform_type',
            old_multiplier: 'old_multiplier',
            new_multiplier: 'new_multiplier',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'added platform bids',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Campaign',
              textMessage: 'campaign_name'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Platform Type',
              textMessage: 'platform_type'
            },
            {
              oldValue: 'old_multiplier',
              newValue: 'new_multiplier',
              component: 'arrowChange',
              'sub-heading': 'Multiplier',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignPlacementMetadataChange`]: {
          props: {
            campaign_name: 'name',
            campaign_id: 'campaign_id',
            placement_type: 'placement_type',
            old_status: 'old_status',
            new_status: 'new_status',
            errorMessage: 'errorMessage',
            ...baseConfig()
          },
          message: 'updated placement status',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Campaign',
              textMessage: 'campaign_name'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Placement Type',
              textMessage: 'placement_type'
            },
            {
              oldValue: 'old_status',
              newValue: 'new_status',
              component: 'arrowChange',
              'sub-heading': 'Status',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Failure reason',
              textMessage: 'errorMessage',
              hideIfNull: true,
              caseSensitive: true,
              textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        // Citrus retailers action types start here
        [`${configRetailerKey}CampaignEditMaxBid`]: {
          props: {
            oldValue: 'old_max_bid',
            newValue: 'new_max_bid',
            ...baseConfig()
          },
          message: 'adjusted the max CPC.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign max cpc',
              displayType: 'currency'
            }
          ]
        },
        [`${configRetailerKey}CampaignAddBulkKeywords`]: {
          props: {
            textMessage: 'new_keyword',
            matchType: 'match_type',
            ...baseConfig()
          },
          message: 'added a new keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match Type',
              textMessage: 'matchType'
            }
          ]
        },
        [`${configRetailerKey}CampaignNameChange`]: {
          props: {
            oldValue: 'old_campaign_name',
            newValue: 'new_campaign_name',
            ...baseConfig()
          },
          message: 'updated the campaign name',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Campaign name',
              caseSensitive: true
            }
          ],
          displayType: 'string'
        },
        [`${configRetailerKey}CampaignStartEndDateChange`]: {
          props: {
            previousEndDate: 'current_end_date',
            newEndDate: 'new_end_date',
            ...baseConfig()
          },
          message: 'updated the campaign end date.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'previousEndDate',
              newValue: 'newEndDate',
              component: 'arrowChange',
              'sub-heading': 'End date',
              hideIfNull: true
            }
          ],
          displayType: 'string',
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignDailyBudgetChange`]: {
          props: {
            previousDailyBudget: 'previousDailyBudget',
            newDailyBudget: 'newDailyBudget',
            ...baseConfig()
          },
          message: 'adjusted the campaign budget.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'previousDailyBudget',
              newValue: 'newDailyBudget',
              component: 'arrowChange',
              'sub-heading': 'Daily budget',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignAlwaysOnBudgetChange`]: {
          props: {
            alwaysOnBudget: 'alwaysOnBudget',
            ...baseConfig()
          },
          message: 'adjusted the campaign budget.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Budget',
              textMessage: 'alwaysOnBudget',
              hideIfNull: true
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignAddBulkProducts`]: {
          props: {
            sku: 'product_code',
            image_url: 'sku_image_url',
            product_title: 'product_name',
            product_url: 'product_url',
            ...baseConfig()
          },
          message: 'added product to a campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'SKU'
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignRemoveBulkProducts`]: {
          props: {
            sku: 'product_code',
            image_url: 'sku_image_url',
            product_title: 'product_name',
            product_url: 'product_url',
            ...baseConfig()
          },
          message: 'deleted sku from a campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'SKU'
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignRemoveProduct`]: {
          props: {
            sku: 'product_code',
            image_url: 'sku_image_url',
            product_title: 'product_name',
            product_url: 'product_url',
            ...baseConfig()
          },
          message: 'deleted sku from a campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'SKU'
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignRemoveBulkKeywords`]: {
          props: {
            textMessage: 'remove_keyword',
            ...baseConfig()
          },
          message: 'deleted a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            }
          ]
        },
        [`${configRetailerKey}CampaignRemoveKeyword`]: {
          props: {
            textMessage: 'remove_keyword',
            ...baseConfig()
          },
          message: 'deleted a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            }
          ]
        },
        // Citrus retailers action types ends here
        [`${configRetailerKey}ProductBidChange`]: {
          props: {
            oldValue: 'old_sku_bid',
            newValue: 'new_sku_bid',
            ...baseConfig()
          },
          message: 'adjusted the product bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU bid',
              oldValue: 'oldValue',
              newValue: 'newValue',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignEndDateChange`]: {
          props: {
            previousEndDate: 'current_end_date',
            newEndDate: 'new_end_date',
            ...baseConfig()
          },
          message: 'updated the campaign end date.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'previousEndDate',
              newValue: 'newEndDate',
              component: 'arrowChange',
              'sub-heading': 'End date',
              hideIfNull: true
            }
          ],
          displayType: 'string',
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}CampaignAddProduct`]: {
          props: {
            sku: 'sku',
            image_url: 'sku_latest_image_url',
            product_title: 'sku_title',
            product_url: 'product_url',
            sku_bid: 'sku_bid',
            ...baseConfig()
          },
          message: 'added product to a campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'SKU'
            },
            {
              component: 'textMessage',
              'sub-heading': 'SKU Bid',
              textMessage: 'sku_bid',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignBulkAddKeywords`]: {
          props: {
            textMessage: 'new_text',
            matchType: 'match_type',
            bidModifier: 'new_keyword_bidMultiplier',
            ...baseConfig()
          },
          message: 'added a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Bid Modifier',
              textMessage: 'bidModifier'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Match Type',
              textMessage: 'matchType'
            }
          ]
        },
        [`${configRetailerKey}CampaignBulkRemoveKeywords`]: {
          props: {
            textMessage: 'new_text',
            ...baseConfig()
          },
          message: 'removed a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            }
          ]
        },
        [`${configRetailerKey}CampaignBulkBlocklistKeywords`]: {
          props: {
            textMessage: 'new_text',
            ...baseConfig()
          },
          message: 'blocked a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            }
          ]
        },
        [`${configRetailerKey}CampaignBulkUnblocklistKeywords`]: {
          props: {
            textMessage: 'new_text',
            bidModifier: 'new_keyword_bidMultiplier',
            ...baseConfig()
          },
          message: 'unblocked a keyword.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Keyword',
              textMessage: 'textMessage'
            },
            {
              component: 'textMessage',
              'sub-heading': 'Bid Modifier',
              textMessage: 'bidModifier'
            }
          ]
        },
        [`${configRetailerKey}KeywordBulkChangeBidMultiplier`]: {
          props: {
            oldValue: 'oldBidMultiplier',
            newValue: 'newBidMultiplier',
            ...baseConfig()
          },
          message: 'adjusted the bid modifier',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Bid modifier',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}OptimizationStrategyChange`]: {
          props: {
            prev_bid_strategy: 'prev_bid_strategy',
            new_bid_strategy: 'new_bid_strategy',
            prev_max_bid: 'prev_max_bid',
            new_max_bid: 'new_max_bid',
            ...baseConfig()
          },
          message: 'updated Optimization Strategy',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'prev_bid_strategy',
              newValue: 'new_bid_strategy',
              component: 'arrowChange',
              'sub-heading': 'Optimize'
            },
            {
              oldValue: 'prev_max_bid',
              newValue: 'new_max_bid',
              component: 'arrowChange',
              'sub-heading': 'Max Bid'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        },
        [`${configRetailerKey}MonthlyAndDailyPacingChange`]: {
          props: {
            old_budget_type: 'old_budget_type',
            new_budget_type: 'new_budget_type',
            old_budget: 'old_budget',
            new_budget: 'new_budget',
            old_monthly_pacing: 'old_monthly_pacing',
            new_monthly_pacing: 'new_monthly_pacing',
            old_daily_pacing: 'old_daily_pacing',
            new_daily_pacing: 'new_daily_pacing',
            ...baseConfig()
          },
          message: 'updated Budget and Pacing',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'old_budget_type',
              newValue: 'new_budget_type',
              component: 'arrowChange',
              'sub-heading': 'Line Item Budget'
            },
            {
              oldValue: 'old_budget',
              newValue: 'new_budget',
              component: 'arrowChange',
              'sub-heading': 'New Budget'
            },
            {
              oldValue: 'old_monthly_pacing',
              newValue: 'new_monthly_pacing',
              component: 'arrowChange',
              'sub-heading': 'Monthly Cap'
            },
            {
              oldValue: 'old_daily_pacing',
              newValue: 'new_daily_pacing',
              component: 'arrowChange',
              'sub-heading': 'Daily Cap'
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: true
          }
        },
        [`${configRetailerKey}LineItemTargetBidChange`]: {
          props: {
            oldValue: 'previousBid',
            newValue: 'new_target_bid',
            ...baseConfig()
          },
          message: 'adjusted the line item bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Line item bid',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}SkUBidChange`]: {
          props: {
            oldValue: 'bid_override',
            newValue: 'set_bid_override',
            ...baseConfig()
          },
          message: 'adjusted the product bid.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'arrowChange',
              'sub-heading': 'Ad SKU bid',
              displayType: 'currency'
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignBudgetChange`]: {
          props: {
            new_budget: 'new_budget',
            new_budget_type: 'new_budget_type',
            new_pacing: 'new_pacing',
            new_end_date: 'new_end_date',
            oldValue: 'daily_budget',
            newValue: 'new_daily_budget',
            ...baseConfig()
          },
          message: 'updated the budget and pacing.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'textMessage',
              'sub-heading': 'Budget',
              textMessage: 'new_budget',
              displayType: 'currency',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Budget Type',
              textMessage: 'new_budget_type',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'Pacing',
              textMessage: 'new_pacing',
              hideIfNull: true
            },
            {
              component: 'textMessage',
              'sub-heading': 'End Date',
              textMessage: 'new_end_date',
              hideIfNull: true
            },
            {
              component: 'arrowChange',
              'sub-heading': 'Daily Budget',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          closeActvity: 'closeKeywordsActivityLog'
        },
        [`${configRetailerKey}CampaignAddProduct`]: {
          props: {
            sku: 'sku',
            image_url: 'image_url',
            product_title: 'sku_title',
            product_url: 'product_url',
            sku_bid: 'sku_bid',
            ...baseConfig()
          },
          message: 'added product to a campaign.',
          component: 'name-message-template',
          midComponents: [
            {
              component: 'skuUpdated',
              'sub-heading': 'SKU'
            },
            {
              component: 'textMessage',
              'sub-heading': 'SKU Bid',
              textMessage: 'sku_bid',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          displayType: 'currency'
        },
        [`${configRetailerKey}CampaignTotalBudgetChange`]: {
          props: {
            previousTotalBudget: 'previousTotalBudget',
            newTotalBudget: 'newTotalBudget',
            ...baseConfig()
          },
          message: 'adjusted the campaign budget.',
          component: 'name-message-template',
          midComponents: [
            {
              oldValue: 'previousTotalBudget',
              newValue: 'newTotalBudget',
              component: 'arrowChange',
              'sub-heading': 'Total budget',
              displayType: 'currency',
              hideIfNull: true
            }
          ],
          customArrowComponent: {
            multiple: true,
            hideIfNull: false
          }
        }
      };
    });
    events = {
      ...events,
      samsClubUpdateLineItemState: {
        props: {
          oldValue: 'status',
          newValue: 'current_status',
          ...baseConfig()
        },
        message: 'updated the line item status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item status'
          }
        ],
        displayType: 'string'
      },
      targetUpdateLineItemState: {
        props: {
          oldValue: 'status',
          newValue: 'current_status',
          ...baseConfig()
        },
        message: 'updated the line item status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item status'
          }
        ],
        displayType: 'string'
      },
      meijerUpdateLineItemState: {
        props: {
          oldValue: 'status',
          newValue: 'current_status',
          ...baseConfig()
        },
        message: 'updated the line item status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item status'
          }
        ],
        displayType: 'string'
      },
      criteoLineItemNameChange: {
        props: {
          oldValue: 'current_name',
          newValue: 'new_name',
          ...baseConfig()
        },
        message: 'updated the line item name',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item name',
            caseSensitive: true
          }
        ],
        displayType: 'string'
      },
      criteoLineItemAddNegativeKeyword: {
        props: {
          keyword: 'keyword',
          keywordMatchType: 'keywordMatchType',
          campaign_name: 'campaign_name',
          ...baseConfig()
        },
        message: 'added a negative keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Line Item Name',
            textMessage: 'campaign_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'keywordMatchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'keyword'
          }
        ]
      },
      meijerLineItemNameChange: {
        props: {
          oldValue: 'current_name',
          newValue: 'new_name',
          ...baseConfig()
        },
        message: 'updated the line item name',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item name',
            caseSensitive: true
          }
        ],
        displayType: 'string'
      },
      criteoLineItemDateChange: {
        props: {
          previousStartDate: 'current_start_date',
          newStartDate: 'new_start_date',
          previousEndDate: 'current_end_date',
          newEndDate: 'new_end_date',
          ...baseConfig()
        },
        message: 'updated the line item date.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'previousStartDate',
            newValue: 'newStartDate',
            component: 'arrowChange',
            'sub-heading': 'Start date',
            hideIfNull: true
          },
          {
            oldValue: 'previousEndDate',
            newValue: 'newEndDate',
            component: 'arrowChange',
            'sub-heading': 'End date',
            hideIfNull: true
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      meijerLineItemDateChange: {
        props: {
          previousStartDate: 'current_start_date',
          newStartDate: 'new_start_date',
          previousEndDate: 'current_end_date',
          newEndDate: 'new_end_date',
          ...baseConfig()
        },
        message: 'updated the line item date.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'previousStartDate',
            newValue: 'newStartDate',
            component: 'arrowChange',
            'sub-heading': 'Start date',
            hideIfNull: true
          },
          {
            oldValue: 'previousEndDate',
            newValue: 'newEndDate',
            component: 'arrowChange',
            'sub-heading': 'End date',
            hideIfNull: true
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      meijerLineItemAddNegativeKeyword: {
        props: {
          keyword: 'keyword',
          keywordMatchType: 'keywordMatchType',
          campaign_name: 'campaign_name',
          ...baseConfig()
        },
        message: 'added a negative keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Line Item Name',
            textMessage: 'campaign_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'keywordMatchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'keyword'
          }
        ]
      },
      meijerUnpauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      meijerPauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      targetUnpauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      targetPauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      samsclubUnpauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      samsclubPauseProductStatus: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the skus status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ],
        displayType: 'string'
      },
      keywordBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'newBid',
          ...baseConfig()
        },
        message: 'adjusted the keyword bid.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Keyword bid'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog'
      },
      instacartAdGroupDefaultBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'newBid',
          ...baseConfig()
        },
        message: 'adjusted the ad group default bid.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Ad group default bid'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog'
      },
      instacartCampaignEditBidStatus: {
        props: {
          newOptimizedBiddingEnabled: 'newOptimizedBiddingEnabled',
          oldOptimizedBiddingEnabled: 'oldOptimizedBiddingEnabled',
          daily_budget: 'daily_budget',
          lifetime_budget: 'lifetime_budget',
          old_lifetime_budget: 'old_lifetime_budget',
          campaign_name: 'campaign_name',
          newBudgetType: 'newBudgetType',
          oldBudgetType: 'oldBudgetType',
          roas_constraint: 'roas_constraint',
          ...baseConfig()
        },
        message: 'adjusted the budget settings.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Daily Budget',
            textMessage: 'daily_budget',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            oldValue: 'old_lifetime_budget',
            newValue: 'lifetime_budget',
            component: 'arrowChange',
            'sub-heading': 'Lifetime Budget',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            oldValue: 'oldOptimizedBiddingEnabled',
            newValue: 'newOptimizedBiddingEnabled',
            component: 'arrowChange',
            'sub-heading': 'Bid Type',
            hideIfNull: true
          },
          {
            oldValue: 'oldBudgetType',
            newValue: 'newBudgetType',
            component: 'arrowChange',
            'sub-heading': 'Budget Type',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Minimum ROAS Goal',
            textMessage: 'roas_constraint',
            displayType: 'currency',
            hideIfNull: true
          }
        ],
        closeActvity: 'closeKeywordsActivityLog',
        displayType: 'currency',
        customArrowComponent: {
          multiple: true,
          hideIfNull: true
        }
      },
      instacartCampaignAdgroupBidChange: {
        props: {
          campaign_name: 'campaign_name',
          newBudgetType: 'newBudgetType',
          adgroupName: 'adgroupName',
          newBid: 'newBid',
          previousBid: 'previousBid',
          ...baseConfig()
        },
        message: 'adjusted the bid settings for adgroups.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name ',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            oldValue: 'previousBid',
            newValue: 'newBid',
            component: 'arrowChange',
            'sub-heading': 'Bid Value',
            displayType: 'currency'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog',
        displayType: 'currency',
        customArrowComponent: {
          multiple: true
          // hideIfNull: true
        }
      },
      instacartKeywordCpcBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'newBid',
          ...baseConfig()
        },
        message: 'adjusted the keyword cpc bid.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Keyword cpc bid'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog',
        displayType: 'currency'
      },
      targetBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'newBid',
          ...baseConfig()
        },
        message: 'adjusted the keyword bid.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Keyword bid'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog',
        displayType: 'currency'
      },
      meijerBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'newBid',
          ...baseConfig()
        },
        message: 'adjusted the keyword bid.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Keyword bid'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog',
        displayType: 'currency'
      },
      campaignBudgetChange: {
        props: {
          oldValue: 'previousBudget',
          newValue: 'newBudget',
          ...baseConfig()
        },
        message: 'adjusted the campaign budget.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Campaign budget',
            displayType: 'currency'
          }
        ]
      },
      instacartCampaignTotalBudgetChange: {
        props: {
          previousLifetimeBudget: 'previousLifetimeBudget',
          newLifetimeBudget: 'newLifetimeBudget',
          previousBudget: 'previousBudget',
          newBudget: 'newBudget',
          newBudgetType: 'newBudgetType',
          previousBudgetType: 'previousBudgetType',
          ...baseConfig()
        },
        message: 'adjusted the campaign budget.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'previousBudget',
            newValue: 'newBudget',
            component: 'arrowChange',
            'sub-heading': 'Daily budget',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            oldValue: 'previousLifetimeBudget',
            newValue: 'newLifetimeBudget',
            component: 'arrowChange',
            'sub-heading': 'Lifetime budget',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            oldValue: 'previousBudgetType',
            newValue: 'newBudgetType',
            component: 'arrowChange',
            'sub-heading': 'Budget type',
            hideIfNull: true
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartCampaignMetadataChange: {
        props: {
          old_objective: 'old_objective',
          new_objective: 'new_objective',
          old_objective_custom: 'old_objective_custom',
          new_objective_custom: 'new_objective_custom',
          newName: 'name',
          view_old_name: 'view_old_name',
          state: 'state',
          view_old_state: 'view_old_state',
          view_old_start_date: 'view_old_start_date',
          view_start_date: 'view_start_date',
          view_end_date: 'view_end_date',
          view_old_end_date: 'view_old_end_date',
          new_budget_type: 'new_budget_type',
          old_budget_type: 'old_budget_type',
          new_daily_budget: 'new_daily_budget',
          old_daily_budget: 'old_daily_budget',
          new_lifetime_budget: 'new_lifetime_budget',
          old_lifetime_budget: 'old_lifetime_budget',
          roas_constraint: 'roas_constraint',
          old_roas_constraint: 'old_roas_constraint',
          view_new_optimized_bidding_enabled:
            'view_new_optimized_bidding_enabled',
          view_old_optimized_bidding_enabled:
            'view_old_optimized_bidding_enabled',
          adgroupName: 'adgroupName',
          newBid: 'newBid',
          previousBid: 'previousBid',
          ...baseConfig()
        },
        message: 'updated the campaign.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'view_old_name',
            newValue: 'newName',
            component: 'arrowChange',
            'sub-heading': 'Campaign Name',
            hideIfNull: true
          },
          {
            oldValue: 'view_old_state',
            newValue: 'state',
            component: 'arrowChange',
            'sub-heading': 'Status',
            hideIfNull: true
          },
          {
            oldValue: 'view_old_start_date',
            newValue: 'view_start_date',
            component: 'arrowChange',
            'sub-heading': 'Start Date',
            hideIfNull: true
          },
          {
            oldValue: 'view_old_end_date',
            newValue: 'view_end_date',
            component: 'arrowChange',
            'sub-heading': 'End Date',
            hideIfNull: true
          },
          {
            oldValue: 'old_objective',
            newValue: 'new_objective',
            component: 'arrowChange',
            'sub-heading': 'Objective',
            hideIfNull: true
          },
          {
            oldValue: 'old_objective_custom',
            newValue: 'new_objective_custom',
            component: 'arrowChange',
            'sub-heading': 'Custom Objective',
            hideIfNull: true
          },
          {
            oldValue: 'old_budget_type',
            newValue: 'new_budget_type',
            component: 'arrowChange',
            'sub-heading': 'Budget Type',
            hideIfNull: true
          },
          {
            oldValue: 'old_daily_budget',
            newValue: 'new_daily_budget',
            component: 'arrowChange',
            'sub-heading': 'Daily Budget',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            oldValue: 'old_lifetime_budget',
            newValue: 'new_lifetime_budget',
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Lifetime Budget',
            hideIfNull: true
          },
          {
            oldValue: 'old_roas_constraint',
            newValue: 'roas_constraint',
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'ROAS',
            hideIfNull: true
          },
          {
            oldValue: 'view_old_optimized_bidding_enabled',
            newValue: 'view_new_optimized_bidding_enabled',
            component: 'arrowChange',
            'sub-heading': 'Bid Type',
            hideIfNull: true
          },
          {
            oldValue: 'previousBid',
            newValue: 'newBid',
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'CPC Bid',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName',
            hideIfNull: true
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      campaignMetadataChange: {
        props: {
          newName: 'name',
          oldName: 'view_old_name',
          newState: 'state',
          oldState: 'view_old_state',
          newEndDate: 'view_end_date',
          oldEndDate: 'view_old_end_date',
          newStartDate: 'view_start_date',
          oldStartDate: 'view_old_start_date',
          newPortfolio: 'view_portfolio',
          oldPortfolio: 'view_old_portfolio',
          oldBidStrategy: 'view_old_bidding_strategy',
          newBidStrategy: 'bidding_strategy',
          placementType: 'placementType',
          newPlacementBid: 'newPlacementBid',
          oldPlacementBid: 'oldPlacementBid',
          previousBudget: 'previousBudget',
          newBudget: 'newBudget',
          oldBidOptimization: 'View_old_bid_optimization',
          newBidOptimization: 'view_bid_optimization',
          ...baseConfig()
        },
        message: 'updated the campaign.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'oldName',
            newValue: 'newName',
            component: 'arrowChange',
            'sub-heading': 'Name',
            hideIfNull: true
          },
          {
            oldValue: 'oldState',
            newValue: 'newState',
            component: 'arrowChange',
            'sub-heading': 'State',
            hideIfNull: true
          },
          {
            oldValue: 'oldEndDate',
            newValue: 'newEndDate',
            component: 'arrowChange',
            'sub-heading': 'End date',
            hideIfNull: true
          },
          {
            oldValue: 'oldStartDate',
            newValue: 'newStartDate',
            component: 'arrowChange',
            'sub-heading': 'Start date',
            hideIfNull: true
          },
          {
            oldValue: 'oldPortfolio',
            newValue: 'newPortfolio',
            component: 'arrowChange',
            'sub-heading': 'Portfolio',
            hideIfNull: true
          },
          {
            oldValue: 'oldBidStrategy',
            newValue: 'newBidStrategy',
            component: 'arrowChange',
            'sub-heading': 'Bidding Strategy',
            hideIfNull: true
          },
          {
            oldValue: 'oldBidOptimization',
            newValue: 'newBidOptimization',
            component: 'arrowChange',
            'sub-heading': 'Bid Optimization',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Placement',
            textMessage: 'placementType',
            hideIfNull: true
          },
          {
            oldValue: 'oldPlacementBid',
            newValue: 'newPlacementBid',
            component: 'arrowChange',
            'sub-heading': 'Placement Bid',
            hideIfNull: true
          },
          {
            oldValue: 'previousBudget',
            newValue: 'newBudget',
            component: 'arrowChange',
            'sub-heading': 'Budget',
            displayType: 'currency',
            hideIfNull: true
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      adgroupMetadataChange: {
        props: {
          adgroupName: 'view_adgroup_name',
          oldState: 'view_old_state',
          state: 'state',
          bid: 'new_bid',
          oldBid: 'view_old_bid',
          ...baseConfig()
        },
        message: 'updated the ad group.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            oldValue: 'oldState',
            newValue: 'state',
            component: 'arrowChange',
            'sub-heading': 'State',
            hideIfNull: true
          },
          {
            oldValue: 'oldBid',
            newValue: 'bid',
            component: 'arrowChange',
            'sub-heading': 'Bid',
            hideIfNull: true,
            displayType: 'currency'
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartAdgroupMetadataChange: {
        props: {
          adgroupName: 'view_adgroup_name',
          oldState: 'view_old_state',
          state: 'state',
          bid: 'new_bid',
          oldBid: 'view_old_bid',
          ...baseConfig()
        },
        message: 'updated the ad group.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            oldValue: 'oldState',
            newValue: 'state',
            component: 'arrowChange',
            'sub-heading': 'Status',
            hideIfNull: true
          },
          {
            oldValue: 'oldBid',
            newValue: 'bid',
            component: 'arrowChange',
            'sub-heading': 'Bid',
            hideIfNull: true,
            displayType: 'currency'
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      campaignPlacementChange: {
        props: {
          placementType: 'placementType',
          newPlacementBid: 'newPlacementBid',
          oldPlacementBid: 'oldPlacementBid',
          bid: 'new_bid',
          oldBid: 'view_old_bid',
          ...baseConfig()
        },
        message: 'updated the campaign placement.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Placement',
            textMessage: 'placementType',
            hideIfNull: true
          },
          {
            oldValue: 'oldPlacementBid',
            newValue: 'newPlacementBid',
            component: 'arrowChange',
            'sub-heading': 'Placement Bid',
            hideIfNull: true
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      updateKeywordState: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the keyword status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Keyword status'
          }
        ],
        displayType: 'string'
      },
      updateTargetState: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the keyword status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Keyword status'
          }
        ],
        displayType: 'string'
      },
      keywordMetadataChange: {
        props: {
          oldStatus: 'previousStatus',
          newStatus: 'newStatus',
          oldBid: 'view_old_bid',
          newBid: 'new_bid',
          adgroupName: 'adgroup_name',
          keyword: 'keyword_text',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'updated the keyword',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'keyword',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            oldValue: 'oldStatus',
            newValue: 'newStatus',
            component: 'arrowChange',
            'sub-heading': 'Keyword status',
            hideIfNull: true
          },
          {
            oldValue: 'oldBid',
            newValue: 'newBid',
            component: 'arrowChange',
            'sub-heading': 'Keyword Bid',
            hideIfNull: true,
            displayType: 'currency'
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartKeywordMetadataChange: {
        props: {
          oldBid: 'view_old_bid',
          newBid: 'new_bid',
          adgroupName: 'adgroup_name',
          keyword_text: 'keyword_text',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'updated the keyword',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'keyword_text',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            oldValue: 'oldBid',
            newValue: 'newBid',
            component: 'arrowChange',
            'sub-heading': 'Keyword Bid',
            hideIfNull: true,
            displayType: 'currency'
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      targetMetadataChange: {
        props: {
          oldStatus: 'previousStatus',
          newStatus: 'newStatus',
          oldBid: 'view_old_bid',
          newBid: 'new_bid',
          adgroupName: 'adgroup_name',
          keyword: 'keyword_text',
          asinId: 'asin_id',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'updated the target',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Target',
            textMessage: 'keyword',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Asin',
            textMessage: 'asinId',
            hideIfNull: true
          },
          {
            oldValue: 'oldStatus',
            newValue: 'newStatus',
            component: 'arrowChange',
            'sub-heading': 'Keyword status',
            hideIfNull: true
          },
          {
            oldValue: 'oldBid',
            newValue: 'newBid',
            component: 'arrowChange',
            'sub-heading': 'Keyword Bid',
            hideIfNull: true,
            displayType: 'currency'
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartProductMetadataChange: {
        props: {
          oldStatus: 'previousStatus',
          newStatus: 'newStatus',
          adgroupName: 'adgroup_name',
          asinId: 'asin_id',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'updated the status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            oldValue: 'oldStatus',
            newValue: 'newStatus',
            component: 'arrowChange',
            'sub-heading': 'Status',
            hideIfNull: true
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartProductStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the product status.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'product status'
          }
        ],
        displayType: 'string'
      },
      instacartCampaignStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the campaign status.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'campaign status'
          }
        ],
        displayType: 'string'
      },
      instacartAdGroupStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the ad group status.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'ad group status'
          }
        ],
        displayType: 'string'
      },
      campaignStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          ...baseConfig()
        },
        message: 'updated the campaign status.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'oldValue',
            newValue: 'newValue',
            component: 'arrowChange',
            'sub-heading': 'Campaign status'
          }
        ],
        displayType: 'string'
      },
      productAdStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          viewMessage: 'sku',
          adgroup_name: 'view_adgroup_name',
          asin_id: 'asin_id',
          ...baseConfig()
        },
        message: 'updated the SKU status.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Asin',
            textMessage: 'viewMessage',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Asin',
            textMessage: 'asin_id',
            hideIfNull: true
          },
          {
            component: 'arrowChange',
            'sub-heading': 'SKU status',
            oldValue: 'oldValue',
            newValue: 'newValue'
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true
        }
      },
      updateNegativeKeywordState: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          keyword_text: 'keyword_text',
          matchtype: 'match_type',
          ...baseConfig()
        },
        message: 'updated the Negative targeting.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Keyword Text',
            textMessage: 'keyword_text'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match Type',
            textMessage: 'matchtype'
          },
          {
            oldValue: 'oldValue',
            newValue: 'newValue',
            component: 'arrowChange',
            'sub-heading': 'Negative targeting status'
          }
        ],
        displayType: 'string'
      },
      updateNegativeTargetState: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'newStatus',
          keyword_text: 'keyword_text',
          matchtype: 'match_type',
          ...baseConfig()
        },
        message: 'updated the Negative targeting.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Product',
            textMessage: 'keyword_text'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match Type',
            textMessage: 'matchtype'
          },
          {
            oldValue: 'oldValue',
            newValue: 'newValue',
            component: 'arrowChange',
            'sub-heading': 'Negative targeting status'
          }
        ],
        displayType: 'string'
      },
      instacartCampaignStartEndDateChange: {
        props: {
          previousStartDate: 'previousStartDate',
          newStartDate: 'newStartDate',
          previousEndDate: 'previousEndDate',
          newEndDate: 'newEndDate',
          ...baseConfig()
        },
        message: 'updated the campaign date.',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'previousStartDate',
            newValue: 'newStartDate',
            component: 'arrowChange',
            'sub-heading': 'Start date'
          },
          {
            oldValue: 'previousEndDate',
            newValue: 'newEndDate',
            component: 'arrowChange',
            'sub-heading': 'End date'
          }
        ],
        displayType: 'string',
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      instacartCampaignsAdGroupProductsAdd: {
        props: {
          sku: 'asin',
          image_url: 'image_url',
          product_title: 'product_title',
          product_url: 'product_url',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added the UPC.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'skuUpdated',
            'sub-heading': 'entity budget'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          }
        ],
        displayType: 'currency'
      },
      instacartAdGroupProductsAdd: {
        props: {
          sku: 'asin',
          image_url: 'image_url',
          product_title: 'product_title',
          product_url: 'product_url',
          adgroupName: 'adgroupName',
          product_size: 'product_size',
          upc: 'upc',
          ...baseConfig()
        },
        message: 'added the UPC.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'skuUpdated',
            'sub-heading': 'entity budget'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Product Title',
            textMessage: 'product_title'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Product Size',
            textMessage: 'product_size'
          },
          {
            component: 'textMessage',
            'sub-heading': 'UPC',
            textMessage: 'upc'
          }
        ],
        displayType: 'currency'
      },
      instacartCampaignsAdGroupKeywordsAdd: {
        props: {
          textMessage: 'keywordText',
          matchType: 'matchType',
          newValue: 'newBid',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added a new keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'textMessage'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword type',
            textMessage: 'matchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword bid',
            textMessage: 'newValue',
            displayType: 'currency'
          }
        ]
      },
      instacartAdGroupKeywordsAdd: {
        props: {
          textMessage: 'keywordText',
          new_keyword_text: 'new_keyword_text',
          matchType: 'matchType',
          newValue: 'newBid',
          new_bid: 'new_bid',
          adgroupName: 'adgroupName',
          new_match_type: 'new_match_type',
          ...baseConfig()
        },
        message: 'added a new keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'textMessage',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'new_keyword_text',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword type',
            textMessage: 'new_match_type',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword bid',
            textMessage: 'newValue',
            displayType: 'currency',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword bid',
            textMessage: 'new_bid',
            displayType: 'currency',
            hideIfNull: true
          }
        ]
      },
      addKeywordToCampaigns: {
        props: {
          textMessage: 'keywordText',
          matchType: 'matchType',
          newValue: 'newBid',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added a new keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'textMessage'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword type',
            textMessage: 'matchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword bid',
            textMessage: 'newValue',
            displayType: 'currency'
          }
        ]
      },
      addAsinCampaign: {
        props: {
          sku: 'asin',
          image_url: 'image_url',
          product_title: 'product_title',
          product_url: 'product_url',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added the ASIN for the selected campaign.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'skuUpdated',
            'sub-heading': 'Campaign budget'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          }
        ],
        displayType: 'currency'
      },
      addNegativeKeyword: {
        props: {
          textMessage: 'keywordText',
          matchType: 'matchType',
          campaign_name: 'campaign_name',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added a negative keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Campaing Name',
            textMessage: 'campaign_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'textMessage'
          }
        ]
      },
      addNegativeTarget: {
        props: {
          textMessage: 'keywordText',
          matchType: 'matchType',
          campaign_name: 'campaign_name',
          adgroupName: 'adgroupName',
          ...baseConfig()
        },
        message: 'added a negative target.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Campaing Name',
            textMessage: 'campaign_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Target',
            textMessage: 'textMessage'
          }
        ]
      },
      addNegativeKeywordToCampaign: {
        props: {
          textMessage: 'keywordText',
          matchType: 'matchType',
          campaign_name: 'campaign_name',
          ...baseConfig()
        },
        message: 'added a negative keyword.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Campaing Name',
            textMessage: 'campaign_name'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match type',
            textMessage: 'matchType'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Keyword',
            textMessage: 'textMessage'
          }
        ]
      },
      addTargetToCampaigns: {
        props: {
          adgroupName: 'adgroupName',
          type: 'type',
          entityName: 'entityName',
          entityId: 'entityId',
          bid: 'new_bid',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'added a Product/Category.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Type',
            textMessage: 'type'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Name',
            textMessage: 'entityName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Entity ID',
            textMessage: 'entityId',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match Type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Product/category Bid',
            textMessage: 'bid',
            displayType: 'currency',
            hideIfNull: true
          }
        ]
      },
      addAudienceTargetToCampaigns: {
        props: {
          adgroupName: 'adgroupName',
          type: 'type',
          entityName: 'entityName',
          entityId: 'entityId',
          bid: 'new_bid',
          matchType: 'match_type',
          ...baseConfig()
        },
        message: 'added a Product/Category.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Type',
            textMessage: 'type'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Name',
            textMessage: 'entityName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Entity ID',
            textMessage: 'entityId',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Match Type',
            textMessage: 'matchType',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Target Bid',
            textMessage: 'bid',
            displayType: 'currency',
            hideIfNull: true
          }
        ]
      },
      VALUE_REMOVED: {
        message: 'removed a seasonality event',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Event Name',
            textMessage: 'label'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Scope',
            textMessage: 'entityName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Time Period',
            textMessage: 'timeperiod'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Budget',
            textMessage: 'value',
            displayTypeKey: 'displayTypeValueKey',
            mode: 'DATA'
          }
        ]
      },
      ENTITY_REMOVED: {
        message: 'entity and budget was removed due to taxonomy change',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Entity Name',
            textMessage: 'label'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Level',
            textMessage: 'entityType'
          }
        ]
      },
      VALUE_ADDED: {
        message: 'added a seasonality event',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Event Name',
            textMessage: 'label'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Scope',
            textMessage: 'entityName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Time Period',
            textMessage: 'timeperiod'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Budget',
            textMessage: 'value',
            displayTypeKey: 'displayTypeValueKey',
            mode: 'DATA'
          }
        ]
      },
      NEW_BUDGET_PLAN: {
        message: 'created a budget plan.',
        component: 'name-message-template',
        midComponents: []
      },
      VALUE_CHANGED: {
        message: 'changed the plan',
        component: 'budgetPlanner',
        midComponents: []
      },
      NEW_FILE_UPLOAD: {
        message: 'updated the budget plan.',
        component: 'name-message-template',
        midComponents: []
      },
      dspLineItemFrequencyCapChange: {
        props: {
          prev_frequency_cap_max_impressions:
            'previousFrequencyCapMaxImpressions',
          new_frequency_cap_max_impressions: 'frequencyCapMaxImpressions',
          prev_frequency_cap_time_unit_count:
            'previousFrequencyCapTimeUnitCount',
          new_frequency_cap_time_unit_count: 'frequencyCapTimeUnitCount',
          prev_frequency_cap_time_unit: 'previousFrequencyCapTimeUnit',
          new_frequency_cap_time_unit: 'frequencyCapTimeUnit',
          ...baseConfig()
        },
        message: 'updated Frequency Cap',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'prev_frequency_cap_max_impressions',
            newValue: 'new_frequency_cap_max_impressions',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Count'
          },
          {
            oldValue: 'prev_frequency_cap_time_unit_count',
            newValue: 'new_frequency_cap_time_unit_count',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Time Unit Count'
          },
          {
            oldValue: 'prev_frequency_cap_time_unit',
            newValue: 'new_frequency_cap_time_unit',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Time Count'
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      dspOrderFrequencyCapChange: {
        props: {
          prev_frequency_cap_max_impressions:
            'previousFrequencyCapMaxImpressions',
          new_frequency_cap_max_impressions: 'frequencyCapMaxImpressions',
          prev_frequency_cap_time_unit_count:
            'previousFrequencyCapTimeUnitCount',
          new_frequency_cap_time_unit_count: 'frequencyCapTimeUnitCount',
          prev_frequency_cap_time_unit: 'previousFrequencyCapTimeUnit',
          new_frequency_cap_time_unit: 'frequencyCapTimeUnit',
          ...baseConfig()
        },
        message: 'updated Frequency Cap',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'prev_frequency_cap_max_impressions',
            newValue: 'new_frequency_cap_max_impressions',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Count'
          },
          {
            oldValue: 'prev_frequency_cap_time_unit_count',
            newValue: 'new_frequency_cap_time_unit_count',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Time Unit Count'
          },
          {
            oldValue: 'prev_frequency_cap_time_unit',
            newValue: 'new_frequency_cap_time_unit',
            component: 'arrowChange',
            'sub-heading': 'Frequency Cap Time Count'
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: false
        }
      },
      dspLineItemMaxSupplyBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'maxSupplyBid',
          ...baseConfig()
        },
        message: 'updated Max Supply Bid',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Max Supply Bid',
            displayType: 'currency'
          }
        ]
      },
      dspLineItemBaseSupplyBidChange: {
        props: {
          oldValue: 'previousBid',
          newValue: 'baseSupplyBid',
          ...baseConfig()
        },
        message: 'updated Base Supply Bid',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Base Supply Bid',
            displayType: 'currency'
          }
        ]
      },
      dspLineItemRemoveInventorySources: {
        props: {
          sourceName: 'sourceName',
          sourceType: 'sourceType',
          ...baseConfig()
        },
        message: 'removed an inventory source.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Source Name',
            textMessage: 'sourceName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Source Type',
            textMessage: 'sourceType'
          }
        ]
      },
      dspLineItemAddInventorySources: {
        props: {
          sourceName: 'sourceName',
          sourceType: 'sourceType',
          ...baseConfig()
        },
        message: 'added an inventory source.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Source Name',
            textMessage: 'sourceName'
          },
          {
            component: 'textMessage',
            'sub-heading': 'Source Type',
            textMessage: 'sourceType'
          }
        ]
      },
      dspOrderStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'deliveryActivationStatus',
          ...baseConfig()
        },
        message: 'updated the order delivery activation status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Order delivery activation status'
          }
        ],
        displayType: 'string'
      },
      dspLineItemStatusChange: {
        props: {
          oldValue: 'previousStatus',
          newValue: 'deliveryActivationStatus',
          ...baseConfig()
        },
        message: 'updated the line item delivery activation status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Line Item delivery activation status'
          }
        ],
        displayType: 'string'
      },
      dspLineItemAddAudiences: {
        props: {
          audienceName: 'audienceName',
          ...baseConfig()
        },
        message: 'added an audience.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Audience',
            textMessage: 'audienceName'
          }
        ]
      },
      dspLineItemRemoveAudiences: {
        props: {
          audienceName: 'audienceName',
          ...baseConfig()
        },
        message: 'removed an audience.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Audience',
            textMessage: 'audienceName'
          }
        ]
      },
      createAdgroup: {
        props: {
          adgroupName: 'view_adgroup_name',
          adgroup_name: 'adgroupName',
          errorMessage: 'errorMessage',
          ...baseConfig()
        },
        message: 'created ad group',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroupName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Ad group name',
            textMessage: 'adgroup_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Failure reason',
            textMessage: 'errorMessage',
            hideIfNull: true,
            caseSensitive: true,
            textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
          }
        ]
      },
      createCampaign: {
        props: {
          campaign_name: 'name',
          campaignName: 'campaign_name',
          errorMessage: 'errorMessage',
          ...baseConfig()
        },
        message: 'created campaign',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'textMessage',
            'sub-heading': 'Campaign name',
            textMessage: 'campaign_name',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Campaign name',
            textMessage: 'campaignName',
            hideIfNull: true
          },
          {
            component: 'textMessage',
            'sub-heading': 'Failure reason',
            textMessage: 'errorMessage',
            hideIfNull: true,
            caseSensitive: true,
            textClass: 'u-font-size-7 u-color-red-base u-font-weight-400'
          }
        ]
      },
      krogerCampaignBudgetPropertiesChange: {
        props: {
          old_budget_type: 'old_budget_type',
          new_budget_type: 'new_budget_type',
          old_budget: 'old_budget',
          new_budget: 'new_budget',
          old_pacing: 'old_pacing',
          new_pacing: 'new_pacing',
          new_end_date: 'new_end_date',
          ...baseConfig()
        },
        message: 'updated Budget properties',
        component: 'name-message-template',
        midComponents: [
          {
            oldValue: 'old_budget_type',
            newValue: 'new_budget_type',
            component: 'arrowChange',
            'sub-heading': 'Interval'
          },
          {
            oldValue: 'old_budget',
            newValue: 'new_budget',
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Budget'
          },
          {
            oldValue: 'old_pacing',
            newValue: 'new_pacing',
            component: 'arrowChange',
            'sub-heading': 'Pacing'
          },
          {
            newValue: 'new_end_date',
            component: 'arrowChange',
            'sub-heading': 'End Date'
          }
        ],
        customArrowComponent: {
          multiple: true,
          hideIfNull: true
        }
      },
      krogerCampaignAddProduct: {
        props: {
          sku: 'sku',
          image_url: 'sku_latest_image_url',
          product_title: 'sku_title',
          adgroupName: 'adgroup_name',
          ...baseConfig()
        },
        message: 'added product to an Ad Group.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'skuUpdated',
            enableSDP: false
          },
          {
            component: 'textMessage',
            'sub-heading': 'Adgroup Name',
            textMessage: 'adgroupName'
          }
        ]
      },
      krogerAdSkuStatusChange: {
        props: {
          oldValue: 'current_status',
          newValue: 'status',
          ...baseConfig()
        },
        message: 'updated the Ad SKU status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Ad SKU status'
          }
        ]
      },
      krogerAdSkuBidChange: {
        props: {
          oldValue: 'old_bid',
          newValue: 'new_bid',
          ...baseConfig()
        },
        message: 'adjusted the Ad Sku bid.',
        component: 'name-message-template',
        midComponents: [
          {
            'sub-heading': 'Ad SKU bid',
            component: 'arrowChange',
            displayType: 'currency'
          }
        ],
        closeActvity: 'closeKeywordsActivityLog'
      },
      krogerAdGroupStatusChange: {
        props: {
          oldValue: 'current_status',
          newValue: 'status',
          ...baseConfig()
        },
        message: 'updated the Ad Group status',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'Campaign status'
          }
        ]
      },
      krogerAdGroupBudgetChange: {
        props: {
          oldValue: 'old_budget',
          newValue: 'new_budget',
          ...baseConfig()
        },
        message: 'adjusted the Ad Group budget',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Budget'
          }
        ]
      },
      krogerAdGroupBaseBidChange: {
        props: {
          newValue: 'new_base_bid',
          oldValue: 'old_base_bid',
          ...baseConfig()
        },
        message: 'adjusted the Ad Group Base Bid',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            displayType: 'currency',
            'sub-heading': 'Base Bid'
          }
        ]
      },
      krogerAdGroupAddProduct: {
        props: {
          sku: 'sku',
          image_url: 'sku_latest_image_url',
          product_title: 'sku_title',
          ...baseConfig()
        },
        message: 'added product to an Ad Group.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'skuUpdated',
            enableSDP: false
          }
        ]
      },
      krogerAdGroupEndDateChange: {
        props: {
          oldValue: 'current_end_date',
          newValue: 'new_end_date',
          ...baseConfig()
        },
        message: 'updated the Ad Group end date.',
        component: 'name-message-template',
        midComponents: [
          {
            component: 'arrowChange',
            'sub-heading': 'End date'
          }
        ]
      }
    };
    return { events };
  }
};
