<template>
  <div
    class="rightPanel u-position-fixed u-display-flex u-flex-direction-column u-bg-color-grey-white"
    :class="showSidePanel ? 'open' : 'close'"
  >
    <div
      class="header box-shadows u-width-100 u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-ph-m u-spacing-pt-l u-spacing-pb-m u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        <rb-icon
          :icon="titleIcon"
          class="u-color-grey-base rb-icon--medium u-cursor-pointer"
        />
        <div
          class="u-spacing-pl-xs u-color-grey-base u-font-size-5 u-font-weight-600"
        >
          {{ title }}
        </div>
      </div>
      <div
        class="u-spacing-p-s u-display-flex"
        @click="closeRightPanel"
      >
        <rb-icon
          :icon="'cross'"
          class="u-color-grey-lighter rb-icon--x-small u-cursor-pointer"
        />
      </div>
    </div>
    <div class="u-spacing-ph-m u-spacing-pv-s u-overflow-auto">
      <div
        v-for="(item, index) in sidePanelContent"
        :key="index"
      >
        <div v-if="item.title && item.value">
          <titleTextComponent
            v-if="item.title !== 'Status'"
            :title="item.title"
            :value="item.value"
          />
          <statusChip
            v-else
            :title="item.title"
            :value="item.value"
            :color-mixin="
              item.value === 'Success'
                ? 'u-bg-color-green-base'
                : 'u-bg-color-red-base'
            "
          />
        </div>
      </div>
    </div>
    <div class="footer" />
  </div>
</template>
<script>
import { eventBus } from '@/utils/services/eventBus';
import titleTextComponent from './titleTextComponent';
import statusChip from './statusChip';
export default {
  components: {
    titleTextComponent,
    statusChip
  },
  props: {
    showSidePanel: {
      type: Boolean,
      default: false
    },
    sidePanelContent: {
      type: Array,
      default: () => {
        return [];
      }
    },
    titleIcon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {
    eventBus.$on('rightPanelOpen', () => {
      this.className = 'open';
    });

    eventBus.$on('rightPanelClose', () => {
      this.className = 'close';
    });
  },
  methods: {
    closeRightPanel() {
      this.$emit('hideSidePanel');
    }
  }
};
</script>

<style lang="css" scoped>
.box-shadows {
  box-shadow: 0 0 4px 0 rgba(43, 51, 59, 0.2);
}
.rightPanel {
  z-index: 105;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
}
.rightPanel .header {
  height: 56px;
}
.rightPanel.close {
  width: 0%;
}
.rightPanel.close .body {
  display: none;
}

.rightPanel.open {
  width: 25%;
}
</style>
