export default {
  getFinalURL(url) {
    return this[url];
  },
  EMAIL: '/api/mail',
  SESSION_DATA: '/api/config',
  RECOMMENDATIONS: '/api/rest/recommendation',
  RECOMMENDATIONS_OF_TYPE:
    '/api/proxy/aramusplatform/rest/recommendation/recommendationMetaData/:recommendationType/:recommendationName',
  RECOMMENDATION_FILTERS:
    '/api/proxy/aramusplatform/rest/recommendation/dimension',
  RECOMMENDATION_COLUMNS:
    '/api/proxy/aramusplatform/rest/recommendation/recommendationaLableMapping',
  UPDATE_RECOMMENDATION_COLUMNS:
    '/api/proxy/aramusplatform/rest/recommendation/uiLabelMapping',
  SEARCH_RECOMMENDATIONS:
    '/api/proxy/aramusplatform/rest/recommendation/searchRecommendation/:searchString',
  REMOVE_RECOMMENDATIONS:
    '/api/proxy/aramusplatform/rest/recommendation/removeSKU',
  RESTORE_RECOMMENDATIONS:
    '/api/proxy/aramusplatform/rest/recommendation/restoreSKU',
  GET_REMOVED_RECOMMENDATIONS:
    '/api/proxy/aramusplatform/rest/recommendation/getRemovedSKU',
  GET_STRATEGIES: '/api/proxy/strategy-service/v1/strategy',
  SIMULTAED_ALERTS:
    '/api/proxy/aramusplatform/rest/recommendation/simulatedAlert/list',
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  REGISTER: '/api/user/register',
  PASSWORD_RESET_LINK: '/api/user/forgotPassword',
  PASSWORD_CHANGE: '/api/user/setPassword',
  INVITE_USERS: '/api/user/inviteUsers',
  RETAIL_LINK_CONNECT: '/api/retail_connect/setup',
  INVITE_USERS_V2: '/api/user/inviteUsers/v2',
  CHECK_USER_STATUS: '/api/user/welcome',
  TABLEAU_GET_TRUST_URL: '/api/tableau/getTrustedviewUrl',
  TABLEAU_GET_TRUST_URL_USER_CLIENT: '/api/tableau/getTrustedDashboardUrl',
  TABLEAU_GET_TRUST_TOKEN: '/api/tableau/getTrustedToken',
  REVENUE_CHART: {
    demo: {
      table: 'Table 1',
      view: 'sample'
    },
    prod: '/api/revenueChart'
  },
  GET_CUBE_LIST: '/api/proxy/brands-service/cube/list',
  GET_CUBE_DETAILS: '/api/proxy/brands-service/cube/getDetails',
  GET_CUBE_QUERY: '/api/proxy/brands-service/cube/view',
  EXECUTE_CUBE_API: '/api/proxy/brands-service/cube/execute',
  EXECUTE_CUBE_API_EMAIL: '/api/proxy/brands-service/cube/execute/download',
  EXECUTE_CUBE_CHART_API: '/api/proxy/brands-service/cube/execute/charts',
  EXECUTE_CUSTOM_CUBE_API: '/api/proxy/brands-service/cube/execute/charts/sku',
  EXECUTE_CUSTOM_CUBE_SKU_API: '/api/proxy/brands-service/cube/execute/custom',
  EXECUTE_CUBE_API_V2: '/api/proxy/brands-service/cube/execute/custom/v2',
  EXECUTE_CUBE_METRIC_API: '/api/proxy/brands-service/cube/execute/metrics',
  EXECUTE_CUBE_REALTIME_METADATA_API:
    '/api/proxy/brands-service/cube/execute/realtime/metadata',
  SOV_BRANDS_CUSTOM: '/api/proxy/brands-service/cube/execute/v2/sov/brands',
  getOTP: '/api/proxy/aramus/rest/twoFactor/getOtp',
  SEND_FEEDBACK: '/api/feedback/post',
  GET_FRESHDESK_TICKETS: '/api/feedback/tickets',
  GET_TICKET_TYPES: '/api/feedback/ticketTypes',
  GET_TICKET_STATUS: '/api/feedback/ticketStatus',
  HARDFETCHSKUDETAILS:
    '/api/proxy/aramusplatform/rest/recommendation/recommendationDetails',
  KEEPALIVE: '/api/keepalive',
  RECOMMENDATION_ACTION_CHANGE:
    '/api/proxy/aramusplatform/rest/recommendation/saveTaskAction',
  GET_SELF_STRATEGIES:
    '/api/proxy/aramusplatform/rest/strategies/getSelfServeStrategyDetails',
  FETCH_FILTERS: '/api/proxy/brands-service/internal/dimension/filters',
  FETCH_FILTERS_V2: '/api/proxy/brands-service/filter/filters',
  FILTER_DATA_V2: '/api/proxy/filter-service/filter/v2/data/read',
  FILTER_METADATA_V2: '/api/proxy/brands-service/filter/v2/metadata/read',
  SAVE_FILTER: '/api/proxy/brands-service/filter/create',
  DELETE_FILTER: '/api/proxy/brands-service/filter/delete',
  HOT_REFRESH_METRICS_DATES:
    '/api/proxy/brands-service/dataRefresh/getMetricDates',
  UPDATE_SELF_SERVE_STATUS:
    '/api/proxy/aramusplatform/rest/strategies/updateStrategyStatus',
  USERS: '/api/user/users',
  SUBMIT_ACTIONS: '/api/proxy/aramusplatform/rest/action/submitActions',
  AMS_ACTIONS: '/api/proxy/brands-service/worklog',
  AMS_ACTIONS_ACTION_LOG: '/api/proxy/brands-service/actionlog',
  AMS_ACTION_SERVICE:
    '/api/proxy/ams-action-service/ams/action/display/extended',
  ENTITY_VALIDATION:
    '/api/proxy/strategy-service/v1/strategy/entity/validation',
  CREATE_STRATEGY: '/api/proxy/strategy-service/v1/strategy',
  GET_STRATEGY_DATA: '/api/proxy/strategy-service/v1/strategy/data',
  TEMPLATELIST: '/api/proxy/aramusplatform/rest/template/list/alert',
  CREATETEMPLATE: '/api/proxy/aramusplatform/rest/template/create',
  READPARAMSFORALERT: '/api/proxy/aramusplatform/rest/alert/read',
  SIMULATETEMPLATE:
    '/api/proxy/aramusplatform/rest/selfserve/simulation/simulate',
  FETCHSIMULATIONLIST:
    '/api/proxy/aramusplatform/rest/selfserve/simulation/list/:templateID',
  PUBLISHTEMPLATE: '/api/proxy/aramusplatform/rest/template/publish',
  READALLPARAMETERS: '/api/proxy/aramusplatform/rest/alert/params/all',
  DELETETEMPLATE: '/api/proxy/aramusplatform/rest/template/delete',
  READTEMPLATE: '/api/proxy/aramusplatform/rest/template/read',
  READSUBSCRIPTION: '/api/proxy/subscription-service/subscription/read',
  UPDATESUBSCRIPTION: '/api/proxy/subscription-service/subscription/update',
  CREATESUBSCRIPTION: '/api/proxy/subscription-service/subscription/create',
  UNSUBSCRIPTION:
    '/api/proxy/subscription-service/subscription/unsubscribe/internal',
  LOGS: '/api/logs/create',
  EVENT_LOGS: '/api/logs/logEvent',
  STRATEGYBUILDERMETADATA:
    '/api/proxy/strategy-service/v1/strategy/utils/builderMetadata',
  GET_STRATEGIES_WITH_DATA:
    '/api/proxy/strategy-service/v1/strategy/all?strategyType=STRATEGY_BUILDER',
  GET_STRATEGIES_WITH_DATA_WOC:
    '/api/proxy/strategy-service/v1/strategy/all/v2?strategyType=STRATEGY_BUILDER&strategyStatus=LIVE',
  PRIORITIZESTRATEGY: '/api/proxy/strategy-service/v1/strategy/prioritize',
  CUBE_METADATA: '/api/proxy/brands-service/cube/fetch',
  STRATEGY_SIMULATE: '/api/proxy/strategy-service/strategybuilder/simulate',
  STRATEGY_SIMULATION_STATUS:
    '/api/proxy/strategy-service/strategybuilder/simulate/status',
  STRATEGY_SIMULATION_OUTPUT:
    '/api/proxy/strategy-service/strategybuilder/simulate/output',
  ACTION_SCHDELUER_TICKET_MESSAGE:
    '/api/proxy/actions-scheduler/actions/followup/caseId/list/latest/responses',
  ACTIONS_SCHEDULER_SELF_SERVE: '/api/proxy/actions-scheduler/api/v1/metadata',
  CIQ_INTERNAL_TOOL_FETCH_TEMPLATE:
    '/api/proxy/ciq-internal-tools/v1/template/read',
  CIQ_INTERNAL_TOOL_FETCH_TEMPLATE_DATA:
    '/api/proxy/ciq-internal-tools/v1/crud/read',
  CIQ_INTERNAL_TOOL_UPDATE_TEMPLATE_DATA:
    '/api/proxy/ciq-internal-tools/v1/custom/update',
  CIQ_INTERNAL_TOOL_INSERT_TEMPLATE_DATA:
    '/api/proxy/ciq-internal-tools/v1/custom/insert',
  DASHBOARD_SERVICE: '/api/proxy/dashboard-service',
  GET_EXISTING_INTERNAL_CATALOG:
    '/api/proxy/brands-service/automation/internal/catalog/dimension/count/existing',
  PUSH_INTERNAL_CATALOG:
    '/api/proxy/brands-service/automation/internal/catalog/upload/new',
  MERGE_INTERNAL_CATALOG:
    '/api/proxy/brands-service/automation/internal/catalog/dimension/merge',
  REPLACE_INTERNAL_CATALOG:
    '/api/proxy/brands-service/automation/internal/catalog/dimension/replace',
  GET_LAST_UPLOADED_CATALOG_INFO:
    '/api/proxy/brands-service/automation/internal/catalog/upload/list',
  REMOVE_LAST_UPLOADED_CATALOG:
    '/api/proxy/brands-service/automation/internal/catalog/dimension/remove/new',
  CIQ_INTERNAL_TOOL_EXCEL_VALIDATION:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/validate-excel',
  CIQ_INTERNAL_TOOL_EXCEL_DOWNLOAD:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/download-excel',
  CIQ_INTERNAL_TOOL_SUBMIT_MAIL:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/send-mail',
  CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_GET:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/users/get',
  CIQ_INTERNAL_TOOL_EXCEL_MAIL_HISTORY_GET:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/mail-history/get',
  CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_INSERT:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/users/insert',
  CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_UPDATE:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/users/update',
  CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_DELETE:
    '/api/proxy/ciq-internal-tools/v1/mail-validator/users/delete',
  GET_BUDGET_PLANNER_STRATEGIES:
    '/api/proxy/strategy-service/v1/strategy/all?strategyStatus=LIVE&strategyType=BUDGET_OPTIMIZER',
  GET_BUDGET_PLANNER_OMNI_STRATEGIES:
    '/api/proxy/strategy-service/v1/strategy/all?strategyStatus=LIVE&strategyType=BUDGET_OPTIMIZER_OMNI',
  GET_BUDGET_PLANNER_ARCHIVED_STRATEGIES:
    '/api/proxy/strategy-service/v1/strategy/all?strategyStatus=ARCHIVED&strategyType=BUDGET_OPTIMIZER',
  ORCHESTRATOR_DOWNLOAD_EXCEL:
    '/api/proxy/orchestrator-service/v1/orchestrator/download-excel',
  ORCHESTRATOR_DOWNLOAD_FILE:
    '/api/proxy/orchestrator-service/v1/orchestrator/download-file',
  ORCHESTRATOR_UPLOAD_EXCEL:
    '/api/proxy/orchestrator-service/v1/orchestrator/upload-excel',
  BUDGET_PLAN_DS_CURVE:
    '/api/proxy/strategy-executor/v1/budgetpacing/pacing-overview',
  BUDGET_OPTIMIZER_HISTORY: '/api/proxy/orchestrator-service/v1/history/get',
  BUDGET_OPTIMIZER_ACTIONS:
    '/api/proxy/brands-service/approval/workflow/action/submit',
  BUDGET_OPTIMIZER_BULK_ACTION:
    '/api/proxy/brands-service/approval/workflow/action/bulk/submit',
  BUDGET_OPTIMIZER_ACTIONS_TABLE:
    '/api/proxy/brands-service/approval/workflow/cube',
  BUDGET_OPTIMIZER_NO_CACHE_CUBE:
    '/api/proxy/brands-service/approval/workflow/cube/data',
  ENTITY_VALIDATE: '/api/proxy/orchestrator-service/v1/ingestion/validate',
  ENTITY_EXISTS: '/api/proxy/orchestrator-service/v1/ingestion/entity/status',
  ENTITY_MERGE: '/api/proxy/orchestrator-service/v1/ingestion/entity/merge',
  ENTITY_READ: '/api/proxy/orchestrator-service/v1/ingestion/entity/read',
  ENTITY_DELETE:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/meta/delete',
  ENTITY_RENAME:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/meta/rename',
  ENTITY_UPDATE_METADATA:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/update/metadata',
  ENTITY_UPDATE_ENTITY:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/update/entity',
  ENTITY_POST_PROCESS_STATUS:
    '/api/proxy/entity-metadata-service/post-processing/status',
  ENTITY_DOWNLOAD:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/download',
  ENTITY_DOWNLOAD_EXCEL:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/download-excel',
  ENTITY_VALUE_RENAME:
    '/api/proxy/orchestrator-service/v1/ingestion/entity/value/rename',
  ORCHESTRATOR_SAVE_STRATEGY:
    '/api/proxy/orchestrator-service/v1/orchestrator/save-budget',
  ORCHESTRATOR_NEXT_YEAR_PLAN:
    '/api/proxy/orchestrator-service/v1/orchestrator/next-year-plan',
  ORCHESTRATOR_FETCH_BUDGET_PLAN:
    '/api/proxy/orchestrator-service/v1/budget-optimiser/fetch',
  ORCHESTRATOR_SAVE_BUDGET_PLAN:
    '/api/proxy/orchestrator-service/v1/budget-optimiser/save',
  S3_DOWNLOAD: '/api/proxy/orchestrator-service/v1/utils/s3/get/presign',
  EXCEL_FROM_S3_CSV_DOWNLOAD:
    '/api/proxy/orchestrator-service/v1/utils/s3/get/excel',
  JSON_FROM_S3_DOWNLOAD: '/api/proxy/orchestrator-service/v1/utils/s3/get/json',
  CIQ_INTERNAL_SQL_TEST_LIST_READ:
    '/api/proxy/ciq-internal-tools/v1/sql-test/read',
  CIQ_INTERNAL_SQL_TEST_LIST_RUNTEST:
    '/api/proxy/ciq-internal-tools/v1/sql-test/runtest',
  S3_FILE_DOWNLOAD: '/api/proxy/ciq-internal-tools/v1/s3/download',
  BRANDS_SERVICE: '/api/proxy/brands-service',
  PRODUCT_DIAGNOSTICS_MIN_MAX_DATE:
    '/api/proxy/ciq-internal-tools/v1/cypress/minMaxDate',
  RECOMMENDATION_SERVICE: '/api/proxy/aramusplatform/rest',
  CUSTOMERMETADATA_SERVICE: '/api/proxy/customer-metadata-service/v1/bookMark',
  FETCH_USER_SELECTIONS:
    '/api/proxy/customer-metadata-service/v1/userPageMeta/fetchAllSelections',
  SET_USER_SELECTIONS:
    '/api/proxy/customer-metadata-service/v1/userPageMeta/setSelections',
  RECOMMENDATIONS_DASHBOARD:
    '/api/proxy/aramusplatform/rest/recommendation/dashboard',
  GET_DIGITAL_SHELF_HEIRARCHY:
    '/api/proxy/entity-metadata-service/entity-tagging/digital-shelves/hierarchy',
  ADD_ENTITY_TAG: '/api/proxy/entity-metadata-service/entity-tagging/add',
  FETCH_SUGGESTED_ENTITY_TAG:
    '/api/proxy/entity-metadata-service/entity-tagging/tag',
  ALL_ENTITIES_FOR_A_TAG:
    '/api/proxy/entity-metadata-service/entity-tagging/entity',
  BULK_ADD_TAG: '/api/proxy/entity-metadata-service/entity-tagging/add/all',
  ALL_ENTITIES_WITH_ALL_TAGS:
    '/api/proxy/entity-metadata-service/entity-tagging/all',
  DELETE_TAG: '/api/proxy/entity-metadata-service/entity-tagging/delete',
  REMOVE_ENTITY_FROM_A_TAG:
    '/api/proxy/entity-metadata-service/entity-tagging/delete/entity',
  GET_DIGITAL_SHELF_TAGGING:
    '/api/proxy/entity-metadata-service/entity-tagging/digital-shelves/hierarchy',
  ENTITY_METADATA_SERVICE: '/api/proxy/entity-metadata-service',
  FETCH_AMAZON_RETAILER_DETAILS:
    '/api/proxy/platform-client-details-service/details/v1/amazon/getClient',
  FETCH_INSTACART_RETAILER_DETAILS:
    '/api/proxy/platform-client-details-service/details/v1/instacart/getClient',
  FETCH_TARGET_RETAILER_DETAILS:
    '/api/proxy/platform-client-details-service/details/v1/target/getClient',
  FETCH_WALMART_RETAILER_DETAILS:
    '/api/proxy/platform-client-details-service/details/v1/walmart/getClient',
  FETCH_TOTP: '/api/user/getTOTP',
  SEARCH_LOGS: '/api/search/log/add',
  GETALL_SEARCH: '/api/search/log/getAll',
  MARKETSHARE_FILTER: '/api/proxy/dashboard-service/marketshare/filters',
  MARKETSHARELITE_FILTER:
    '/api/proxy/marketsharelite-service/marketshare-lite/filters',
  MARKETSHARE_DIFF_DATE:
    '/api/proxy/dashboard-service/marketshare/filters/date',
  MARKETSHARELITE_DIFF_DATE:
    '/api/proxy/marketsharelite-service/marketshare-lite/filters/date',
  MARKETSHARE_CATEGORY_DISABLE:
    '/api/proxy/dashboard-service/marketshare/category/status',
  FETCH_AMS_ACTIONS_MANAGER: '/api/proxy/ams-action-manager/actionrouter/fetch',
  UPDATE_AMS_ACTIONS_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/update',
  UPDATE_BULK_AMS_ACTIONS_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/update-bulk',
  CREATE_AMS_ACTIONS_MANAGER: '/api/proxy/ams-action-manager/v1/create/bulk',
  GET_RECOMMENDATIONS_AMS_ACTION_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/resource/recommendation',
  GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/resource/recommendationbids',
  GET_SD_AUDIENCES_TAXANOMY:
    '/api/proxy/ams-action-manager/actionrouter/sd/audiences/taxonomy/list',
  GET_SD_AUDIENCES_LIST:
    '/api/proxy/ams-action-manager/actionrouter/sd/audiences/list',
  GET_SD_BID_RECOMMENDATIONS:
    '/api/proxy/ams-action-manager/actionrouter/sd/targets/bid/recommendations',
  GET_SD_TARGET_RECOMMENDATIONS:
    '/api/proxy/ams-action-manager/actionrouter/sd/targets/recommendations',
  GET_SD_CONTEXTUAL_TARGET_BIDS_RECOMMANDATIONS:
    '/api/proxy/ams-action-manager/actionrouter/sd/contextualtargets/bid/recommendations',
  GET_RECOMMENDATIONS_SP_TARGETS_AMS_ACTION_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/sp/resource/targets/recommendationbids',
  CREATE_AD_GROUP_AMS_ACTIONS_MANAGER:
    '/api/proxy/ams-action-manager/v1/create/adGroup',
  GET_AMS_SB_ASSETS:
    '/api/proxy/ams-action-manager/actionrouter/fetch/store/assets',
  GET_AMS_SB_STORE_INFO:
    '/api/proxy/ams-action-manager/actionrouter/fetch/stores',
  GET_AMS_PAGE_ASINS:
    '/api/proxy/ams-action-manager/actionrouter/fetch/pageAsins',
  UPLOAD_AMS_SB_ASSETS:
    '/api/proxy/ams-action-manager/actionrouter/upload/store/assets',
  CREATE_CAMPAIGN_AMS_ACTION_MANAGER:
    '/api/proxy/ams-action-manager/v1/create/campaign',
  UPLOAD_PROFILE_AMS_ACTION_MANAGER:
    '/api/proxy/ams-action-manager/actionrouter/upload/profile',
  SEND_MAIL_NOTIFICATION: '/api/sendMailNotification',
  PIPELINE: '/api/proxy/pipeline',
  isUserExists: '/api/partnership/userExists',
  createSessionUrl: '/api/partnership/createSessionUrl',
  revokeSession: '/api/partnership/revokeSession',
  ADD_PROFILE_IMAGE:
    '/api/proxy/ams-action-manager/realtimeKpi/config/updateTable',
  MARKETSHARE: '/api/proxy/marketshare-service',
  MARKETSHARELITE_SERVICE: '/api/proxy/marketsharelite-service',
  GET_SUGGESTED_BIDS_AUTO_CAMPAIGN:
    '/api/proxy/ams-action-manager/actionrouter/bid/recommendations/themebased/asins',
  MEDIA_PLANNER_LIST:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/input',
  MEDIA_PLANNER_RAW_OUTPUT:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/output/raw',
  MEDIA_PLANNER_OUTPUT:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/output',
  MEDIA_PLANNER_STATUS:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/status/get',
  MEDIA_PLANNER_RETRY:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/retry',
  MEDIA_PLANNER_PROFILE_PORTFOLIO_MAPPING:
    '/api/proxy/media-planner-orchestrator/v1/media-plan/profile-portfolio',
  ORCHESTRATOR_GET_DOWNLOADS:
    '/api/proxy/orchestrator-service/v1/download-center/get-all',
  ORCHESTRATOR_GET_DOWNLOAD_STATUS:
    '/api/proxy/orchestrator-service/v1/download-center/get-status',
  ORCHESTRATOR_TRIGGER_DOWNLOAD:
    '/api/proxy/orchestrator-service/v1/download-center/create',
  BUDGET_OPTIMIZER_SERVICE_PREVIEW_SEASONALITY:
    '/api/proxy/budget-optimizer-service/budget_optimizer/preview',
  BUDGET_OPTIMIZER_SERVICE_SET_WEIGHTS:
    '/api/proxy/budget-optimizer-service/budget_optimizer/refresh-weights',
  ORCHESTRATOR_SET_DOWNLOAD_STATUS:
    '/api/proxy/orchestrator-service/v1/download-center/set-status',
  REM: '/api/proxy/rem-service',
  PROXY_LINK: '/api/proxyLink',
  GET_DATA_HEALTH_STATUS: '/api/proxy/brands-service/dataHealthCheck',
  GET_E2E_DETAILS: '/api/proxy/dashboard-service/status/e2e',
  E2E_TRIGGER_ENDPOINT: '/api/trigger/onDemandE2ETrigger',
  GET_SLA_BREACH_STATUS:
    '/api/proxy/transparency-dashboard-service/health_check_status/get_health_history',
  SYSTEM_TRANSPARENT_HEALTH_STATUS:
    '/api/proxy/transparency-dashboard-service/health_check_status/get_overall_health',
  SYSTEM_TRANSPARENT_HEALTH_GROUPS:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/health_groups',
  SYSTEM_TRANSPARENT_HEALTH_CHECKS:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/health_checks',
  SYSTEM_TRANSPARENT_INCIDENTS:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/incidents',
  SYSTEM_TRANSPARENT_PRODUCT_AREA:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/product_area',
  SYSTEM_TRANSPARENT_UPDATE_INCIDENT:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/update_incidents',
  SYSTEM_TRANSPARENT_CREATE_INCIDENT:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/create_incidents',
  CREATE_HEALTH_GROUP:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/health_groups',
  CREATE_HEALTH_CHECK:
    '/api/proxy/transparency-dashboard-service/transparency_dashboard/health_checks',
  ENTITY_EXISTS_V2: '/api/proxy/entity-metadata-service/entity/upload/status',
  ENTITY_READ_V2: '/api/proxy/entity-metadata-service/internal/entity/read/v2',
  UPDATE_BULK_BATCH_ACTIONS_MANAGER:
    '/api/proxy/ams-action-manager/v1/batch/trigger/bulk',
  BULK_SELECT_ALL:
    '/api/proxy/ams-action-manager/v1/batch/trigger/bulk/allEntities',
  GET_CURRENCY_RATES: '/api/proxy/dashboard-service/entity/getCurrencyRates',
  DASHBOARD_TOOLING_SERVICE: '/api/proxy/dashboard-tooling-service',
  FETCH_MIGRATING_CLIENT_DATA: '/api/dms/getMigratingClientData',
  FETCH_CLIENT_MIGRATE_STATUS: '/api/dms/getClientMigratedStatus',
  AUTOMATIONS_UPDATE: '/api/proxy/ams-action-manager/v1/config/update/flag',
  AUTOMATIONS_FETCH: `/api/proxy/ams-action-manager/v1/config/fetch/flag`
};
