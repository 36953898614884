import KeywordTargetingWalmart from '@/components/ams/campaign_creation/walmart_steps/campaignTargeting/keywordTargeting.vue';
import { getRetailer } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import { DataService } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupDataService.js';
import {
  fetchSavedState,
  generateMatchTypeRows,
  updateSavedState,
  keywordLeftTable,
  keywordRightTable,
  negativeKeywordLeftTable,
  negativeKeywordRightTable
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/keywordTargeting.js';
import HttpService from '@/utils/services/http-service';

import {
  keywordAdditionSuccessObject,
  fewKeywordAdditionFailureObject,
  keywordAdditionFailureObject,
  getSnackbarMessageObjectForKeywordAddition
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';

export { keywordLeftTable, keywordRightTable };

export const callNegativeApplyButtonDisabled = (vueRef, context) => {
  const arrayToTraverse = vueRef.computedRightTableRows;
  const addedKeywords = vueRef.computedRightTableRows.map(
    (item) => item?.keyword
  );
  if (addedKeywords.length > 1000) {
    context.showErrorMessage =
      'An Ad Group can have a maximum of 1000 keywords with 1-3 match types.';
    return true;
  }
  context.showErrorMessage = null;
  const selections = arrayToTraverse.filter(
    (row) =>
      vueRef.fetchedRightTableRows.rows.findIndex(
        (fetchedRow) => fetchedRow.key === row.key
      ) === -1
  );
  return !vueRef.rightTableRows.length || !selections.length;
};

export const callNegativeApplyRows = (rows, context, vueRef, retailer) => {
  rows = rows.filter(
    (row) =>
      vueRef.fetchedRightTableRows.rows.findIndex(
        (fetchedRow) => fetchedRow.key === row.key
      ) === -1
  );
  const adgroupId = context?.entityId;
  const campaignId = context.parentEntityId;
  const campaignType = context.entityConfig.entityType;
  return !rows.length
    ? vueRef.openSnackbar('No keywords were added/modified')
    : new Promise((resolve, reject) => {
        const payload = rows.map((row) => ({
          actionType: `${retailer}AddNegativeKeywordToCampaigns`,
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: campaignId,
          actionPayload: {
            campaign_id: campaignId,
            campaignType,
            ad_group_id: adgroupId,
            new_keyword_text: row.keyword,
            new_match_type: 'negativeExact',
            state: 'enabled',
            advertiser_id: context?.$route?.query?.advertiser_id
          },
          viewPayload: {
            campaignId,
            campaignType,
            adgroupId,
            adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
            new_keyword_text: row.keyword,
            new_match_type: row.match_type
          }
        }));
        HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
          .then((response) => {
            if (response?.data?.success) {
              const arrayToTraverse = response?.data?.response;
              let messageObject = getSnackbarMessageObjectForKeywordAddition(
                arrayToTraverse,
                {
                  allSuccessObject: keywordAdditionSuccessObject,
                  someFailedObject: fewKeywordAdditionFailureObject,
                  allFailedObject: keywordAdditionFailureObject
                }
              );
              context.$snackbar.open(messageObject);
            } else {
              context.$snackbar.open(keywordAdditionFailureObject);
            }
            vueRef.$emit('afterAPIActionToCall');
            resolve(true);
          })
          .catch((err) => {
            context.$snackbar.open(keywordAdditionFailureObject);
            reject(err);
          });
      });
};

const retailerToDefaultBidMapping = {
  walmart: 1,
  samsclub_api: 0.3
};

const retailerToMaxBidMapping = {
  walmart: 100,
  samsclub_api: 20
};

export const defaultBid = (retailer) => {
  return retailerToDefaultBidMapping?.[retailer];
};

const maxBidValue = (retailer) => {
  return retailerToMaxBidMapping?.[retailer];
};

export const bidValues = (retailer) => {
  return {
    default: defaultBid(retailer),
    min: 0.3,
    max: maxBidValue(retailer)
  };
};

export const keywordTargetingState =
  (type = 'edit') =>
  (context, adGroupId, campaign_type, LeftTableDefaultTab = null) => {
    const retailer = getRetailer(context);
    return {
      component: 'customActionPanel',
      ui_component: 'customActionPanel',
      bidValues: bidValues(retailer),
      props: {
        btnText: 'Custom',
        widgetsConfig: {
          config: (vueRef) => {
            const widgetConfigs = {
              primaryKey: 'key',
              isMultipleAllowed: true,
              default: {
                ...(type === 'edit'
                  ? {}
                  : {
                      hideHeader: true,
                      hideFooter: true
                    }),
                noRowsRightTable: 'Add Keywords to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Multiple keywords are not allowed'
              },
              left: {
                altStore: `${retailer}CampaignCreation/getKeywords`,
                tabs: {
                  Suggested: {
                    search: {
                      enableSearch: true,
                      placeholder: 'Search',
                      searchKey: 'keyword',
                      isClientSide: true
                    },
                    localFilter: [],
                    columnDefs: keywordLeftTable(vueRef),
                    showFilterCheckBox: true,
                    filterCheckBoxSource: {
                      header: 'Select Match Type:',
                      defaultSelected: ['Broad', 'Phrase', 'Exact'],
                      items: ['Broad', 'Phrase', 'Exact']
                    }
                  },
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'keyword',
                        buttonLabel: 'Add keywords',
                        allowSpecialCharacters: false,
                        emitTextInput: true,
                        entityCount: 1000,
                        emitAtEnd: true
                      },
                      events: {
                        updateTextInput(data) {
                          const keywords = data?.keywordsArray?.filter(
                            (item) => item.keyword.length <= 80
                          );
                          const customBid = data?.ref?.suggestedCustomBidInput;

                          let minBid = 0.3;

                          if (customBid < minBid) {
                            data?.ref?.$snackbar.open({
                              message: `Please add a bid greater than ${minBid}`,
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                            return;
                          }
                          const addToRightKeywordsTable = [];
                          for (const item of keywords) {
                            addToRightKeywordsTable.push({
                              suggestedBid: '-',
                              newBid: customBid,
                              keyword: item.keyword,
                              keywordToShow: item.keyword,
                              key: item.key,
                              match_type: item.match_type
                            });
                          }
                          const invalidKeywords = new Set();
                          const filteredInvalidKeywords = data?.keywordsArray
                            .filter(({ keyword }) => keyword.length > 80)
                            ?.map?.((item) => item.keyword);
                          filteredInvalidKeywords.forEach((keyword) => {
                            invalidKeywords.add(keyword);
                          });
                          if (invalidKeywords.size > 0) {
                            data?.ref?.$snackbar.open({
                              message:
                                'The length of the keyword(s) should be within 80 characters.',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                          data.ref.addEntityFromRawObjectArray(
                            addToRightKeywordsTable
                          );
                          data.enterListRef.keywordTextInput =
                            invalidKeywords.size
                              ? Array.from(invalidKeywords)?.join?.('\n')
                              : '';
                        }
                      }
                    },
                    leftCustomBidValues: [
                      {
                        title: 'Custom Bid',
                        value: 'custom_bid',
                        input: 'currency'
                      }
                    ],
                    showLeftCustomBid: true,
                    showFilterCheckBox: true,
                    filterCheckBoxSource: {
                      header: 'Select Match Type:',
                      defaultSelected: ['Broad', 'Phrase', 'Exact'],
                      items: ['Broad', 'Phrase', 'Exact']
                    }
                  }
                },
                isColumnDefAutoConfigure: true,
                isClientSidePagination: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: `${retailer}CampaignCreation/fetchKeywords`,
                  adGroupId: adGroupId,
                  updateRows: (vueRef, searchKey, searchText) => {
                    const newRows = [];
                    let fliterBroad = false;
                    let filterPhrase = false;
                    let filterExact = false;
                    const checkBoxValues = vueRef.leftFilterCheckBoxArray
                      .toString()
                      .toLowerCase();
                    fliterBroad = checkBoxValues.includes('broad');
                    filterPhrase = checkBoxValues.includes('phrase');
                    filterExact = checkBoxValues.includes('exact');
                    if (
                      (!filterPhrase && !filterExact && !fliterBroad) ||
                      (filterPhrase && filterExact && fliterBroad)
                    ) {
                      filterPhrase = true;
                      filterExact = true;
                      fliterBroad = true;
                    }
                    vueRef.leftTableData.rows.forEach((row) => {
                      if (fliterBroad) {
                        const broad = generateMatchTypeRows(
                          row,
                          'broad',
                          fliterBroad
                        );
                        newRows.push(broad);
                      }
                      if (filterPhrase) {
                        const phrase = generateMatchTypeRows(
                          row,
                          'phrase',
                          !fliterBroad
                        );
                        newRows.push(phrase);
                      }
                      if (filterExact) {
                        const exact = generateMatchTypeRows(
                          row,
                          'exact',
                          !fliterBroad && !filterPhrase && !fliterBroad
                        );
                        newRows.push(exact);
                      }
                    });
                    newRows.forEach((row) => {
                      row.newBid =
                        !Number.isNaN(parseFloat(row.suggestedBid)) &&
                        parseFloat(row.suggestedBid) < 0.3
                          ? 0.3
                          : row.suggestedBid;
                    });
                    vueRef.leftTableRows = newRows;
                    if (searchKey && searchText) {
                      vueRef.leftTableRows = vueRef.leftTableRows.filter(
                        (row) =>
                          row[searchKey]
                            .toLowerCase()
                            .includes(searchText?.toLowerCase())
                      );
                    }
                  },
                  localFilters: [],
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    page: 1,
                    limit: 10
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  rowClassRules: {
                    ' hideBorderAddKeywordsToCampaigns': function (params) {
                      return params.data.keywordToShow === ' ';
                    }
                  }
                },
                headerText: 'Add Keywords'
              },
              right: {
                ...(type === 'edit'
                  ? {
                      routeData: context.$route,
                      type: 'KEYWORDS',
                      fetchAll: true,
                      altStore: 'entityDetails/getReviewStatusData',
                      meta: {
                        type: 'table',
                        action: 'entityDetails/fetchReviewStatusData',
                        localFilters: [
                          {
                            dimensionName: 'ad_group_id',
                            dimensionValue: context.entityId,
                            operator: 'EQUAL_TO'
                          }
                        ],
                        updateRows: (vueRef, rows = []) => {
                          const newRows = [];
                          for (let item of rows) {
                            if (
                              item.status === 'rejected' ||
                              item.adGroupId !== parseInt(context.entityId)
                            ) {
                              continue;
                            }
                            item.keywordToShow = item.keywordText;
                            item.keyword = item.keywordText;
                            item.match_type = item.matchType;
                            item.key =
                              item.keywordToShow + '&' + item.match_type;
                            item.newBid = item.bid;
                            item.suggestedBid = '-';
                            item.disabled = true;
                            item.toolTipText =
                              'Update the bids for existing keywords from the Adjust bids action';
                            newRows.push(item);
                          }
                          vueRef.fetchedRightTableRows = {
                            rows: newRows,
                            totalRows: vueRef.rightTableData.totalRows
                          };
                          vueRef.rightTableRows = newRows;
                          vueRef.updateEntityInformation();
                        }
                      },
                      keepFetchedRightTableRows: true
                    }
                  : {}),
                isColumnDefAutoConfigure: true,
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                body: {
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: keywordRightTable(vueRef)
                },
                updateRightTableRows(vueRef) {
                  // Logic to update right rows will come here now
                  const allStepsData = fetchSavedState(
                    context,
                    `${retailer}CampaignCreation/getAllStepsData`
                  );
                  const newRightTableData = [];
                  const { fetchedKeywordsResponse } = allStepsData;
                  if (
                    fetchedKeywordsResponse &&
                    fetchedKeywordsResponse.length > 0
                  ) {
                    fetchedKeywordsResponse.forEach((item) => {
                      const newKeyword = {
                        suggestedBid: '-',
                        newBid: item?.bid,
                        keyword: item?.keywordText,
                        keywordToShow: item?.keywordText,
                        key: item?.keywordText + '&' + item?.matchType,
                        match_type: item?.matchType,
                        keywordId: item?.keywordId
                      };
                      newRightTableData.push(newKeyword);
                    });
                  }
                  vueRef.rightTableRows = newRightTableData;
                  vueRef.refreshLeftTableIsRowSelected();
                },
                headerText: () =>
                  `Added Keywords (${vueRef.rightTableRows.length})`
              },
              apply(rows = []) {
                rows = rows.filter(
                  (row) =>
                    vueRef.fetchedRightTableRows.rows.findIndex(
                      (fetchedRow) =>
                        fetchedRow.key === row.key &&
                        row.newBid === fetchedRow.newBid
                    ) === -1
                );
                const adgroupId = context?.entityId;
                const campaignId = context.parentEntityId;
                const campaignType = context.entityConfig.entityType;
                return !rows.length
                  ? vueRef.openSnackbar('No keywords were added/modified')
                  : new Promise((resolve, reject) => {
                      const payload = rows.map((row) => ({
                        actionType: `${retailer}AddKeywordToCampaigns`,
                        actionSource: {
                          pageUrl: window.location.href
                        },
                        widget: 'campaign',
                        primaryKey: campaignId,
                        actionPayload: {
                          campaign_id: campaignId,
                          campaignType,
                          ad_group_id: adgroupId,
                          new_keyword_text: row.keyword,
                          new_match_type: row.match_type,
                          new_bid: row.newBid,
                          state: 'enabled',
                          advertiser_id: context?.$route?.query?.advertiser_id
                        },
                        viewPayload: {
                          campaignId,
                          campaignType,
                          adgroupId,
                          adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
                          new_keyword_text: row.keyword,
                          new_match_type: row.match_type,
                          new_bid: row.newBid
                        }
                      }));
                      HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                        .then((response) => {
                          if (response?.data?.success) {
                            context.$snackbar.open(
                              keywordAdditionSuccessObject
                            );
                          } else {
                            context.$snackbar.open(
                              keywordAdditionFailureObject
                            );
                          }
                          vueRef.$emit('afterAPIActionToCall');
                          resolve(true);
                        })
                        .catch((err) => {
                          context.$snackbar.open(keywordAdditionFailureObject);
                          reject(err);
                        });
                    });
              },
              applyButtonDisabled() {
                const arrayToTraverse = vueRef.computedRightTableRows;
                for (const i in arrayToTraverse) {
                  const newBid = arrayToTraverse[i]?.newBid;
                  if (!newBid || parseFloat(newBid) < 0.3) {
                    context.showErrorMessage =
                      'Bid should be greater than $0.30';
                    return true;
                  }
                }
                const addedKeywords = vueRef.computedRightTableRows.map(
                  (item) => item?.keyword
                );
                if (addedKeywords.length > 1000) {
                  context.showErrorMessage =
                    'An Ad Group can have a maximum of 1000 keywords with 1-3 match types.';
                  return true;
                }
                context.showErrorMessage = null;
                const selections = arrayToTraverse.filter(
                  (row) =>
                    vueRef.fetchedRightTableRows.rows.findIndex(
                      (fetchedRow) => fetchedRow.key === row.key
                    ) === -1
                );
                return !vueRef.rightTableRows.length || !selections.length;
              }
            };
            if (LeftTableDefaultTab) {
              widgetConfigs.left.defaultTab = LeftTableDefaultTab;
            }
            return widgetConfigs;
          }
        }
      },
      ...(type === 'edit'
        ? {
            events: {
              afterAPIActionToCall(payload) {
                context.showAddCustomKeywordPanel = false;
                context.reloadComponent(true);
              },
              closePanelMethod() {
                context.showAddCustomKeywordPanel = false;
              }
            }
          }
        : {
            events: {
              rightTableRows(rows) {
                let allStepsData =
                  fetchSavedState(
                    context,
                    `${retailer}CampaignCreation/getAllStepsData`
                  ) || {};
                allStepsData.targets = rows;
                allStepsData = { ...allStepsData };
                updateSavedState(
                  context,
                  `${retailer}CampaignCreation/updateAllStepsData`,
                  allStepsData
                );
              },
              afterAPIActionToCall(payload) {
                this.$emit('openSnackbar', 'Action has been submitted');
              },
              closePanelMethod() {}
            }
          })
    };
  };

export const showHeaderFooter = (type) => {
  return type === 'edit'
    ? {}
    : {
        hideHeader: true,
        hideFooter: true
      };
};

export const callupdatedRightTableRowsForNegative = (context, vueRef) => {
  const allStepsData = fetchSavedState(
    context,
    context?.selectedValues?.getter
  );
  const newRightTableData = [];
  const { fetchedNegativeKeywordsResponse } = allStepsData;
  if (
    fetchedNegativeKeywordsResponse &&
    fetchedNegativeKeywordsResponse.length > 0
  ) {
    fetchedNegativeKeywordsResponse.forEach((item) => {
      const newKeyword = {
        keyword: item?.keywordText,
        keywordToShow: item?.keywordText,
        key: item?.keywordText + '&' + item?.matchType,
        match_type: item?.matchType,
        keywordId: item?.keywordId
      };
      newRightTableData.push(newKeyword);
    });
  }
  vueRef.rightTableRows = newRightTableData;
  vueRef.refreshLeftTableIsRowSelected();
};

export const showRightForNegativeEdit = (type, context) => {
  return type === 'edit'
    ? {
        routeData: context.$route,
        type: 'NEGATIVE_KEYWORDS',
        fetchAll: true,
        altStore: 'entityDetails/getReviewStatusData',
        meta: {
          type: 'table',
          action: 'entityDetails/fetchReviewStatusData',
          localFilters: [
            {
              dimensionName: 'ad_group_id',
              dimensionValue: context.entityId,
              operator: 'EQUAL_TO'
            }
          ],
          updateRows: (vueRef, rows = []) => {
            const newRows = [];
            for (let item of rows) {
              if (
                item.status === 'rejected' ||
                item.adGroupId !== parseInt(context.entityId)
              ) {
                continue;
              }
              item.keywordToShow = item.keywordText;
              item.keyword = item.keywordText;
              item.match_type = 'negativeExact';
              item.key = item.keywordToShow + '&' + item.match_type;
              item.disabled = true;
              item.toolTipText =
                'Update the bids for existing keywords from the Adjust bids action';
              newRows.push(item);
            }
            vueRef.fetchedRightTableRows = {
              rows: newRows,
              totalRows: vueRef.rightTableData.totalRows
            };
            vueRef.rightTableRows = newRows;
            vueRef.updateEntityInformation();
          }
        },
        keepFetchedRightTableRows: true
      }
    : {};
};

export const getUpdatedTextInput = (data) => {
  const keywords = data?.keywordsArray?.filter(
    (item) => item.keyword.length <= 80
  );
  const addToRightKeywordsTable = [];
  for (const item of keywords) {
    addToRightKeywordsTable.push({
      keyword: item.keyword,
      keywordToShow: item.keyword,
      key: item.key,
      match_type: item.match_type
    });
  }
  const invalidKeywords = new Set();
  const filteredInvalidKeywords = data?.keywordsArray
    .filter(({ keyword }) => keyword.length > 80)
    ?.map?.((item) => item.keyword);
  filteredInvalidKeywords.forEach((keyword) => {
    invalidKeywords.add(keyword);
  });
  if (invalidKeywords.size > 0) {
    data?.ref?.$snackbar.open({
      message: 'The length of the keyword(s) should be within 80 characters.',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
  }
  data.ref.addEntityFromRawObjectArray(addToRightKeywordsTable);
  data.enterListRef.keywordTextInput = invalidKeywords.size
    ? Array.from(invalidKeywords)?.join?.('\n')
    : '';
};

export const negativeKeywordTargetingState =
  (type = 'edit') =>
  (context, adGroupId) => {
    const retailer = getRetailer(context);
    return {
      component: 'customActionPanel',
      ui_component: 'customActionPanel',
      bidValues: bidValues(retailer),
      props: {
        btnText: 'Negative',
        widgetsConfig: {
          config: (vueRef) => {
            return {
              primaryKey: 'key',
              isMultipleAllowed: true,
              default: {
                ...showHeaderFooter(type),
                noRowsRightTable: 'Add Negative Keywords to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Multiple keywords are not allowed'
              },
              left: {
                tabs: {
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'keyword',
                        buttonLabel: 'Add keywords',
                        emitTextInput: true,
                        entityCount: 1000,
                        emitAtEnd: true
                      },
                      events: {
                        updateTextInput(data) {
                          getUpdatedTextInput(data);
                        }
                      }
                    },
                    showFilterCheckBox: true,
                    filterCheckBoxSource: {
                      defaultSelected: ['Exact'],
                      header: 'Select Match Type:',
                      items: ['Exact']
                    }
                  }
                },
                isColumnDefAutoConfigure: true,
                isClientSidePagination: true,
                table: 'left',
                meta: {
                  type: 'table',
                  adGroupId: adGroupId,
                  localFilters: []
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    page: 1,
                    limit: 10
                  },
                  gridOptions: {
                    context: {
                      componentParent: vueRef
                    },
                    domLayout: 'normal'
                  },
                  columnDefs: negativeKeywordLeftTable(vueRef),
                  rowClassRules: {
                    ' hideBorderAddKeywordsToCampaigns': function (params) {
                      return params.data.keywordToShow === ' ';
                    }
                  }
                },
                headerText: 'Add Negative Keywords'
              },
              right: {
                ...showRightForNegativeEdit(type, context),
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: vueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: negativeKeywordRightTable(vueRef)
                },
                updateRightTableRows(vueRef) {
                  callupdatedRightTableRowsForNegative(context, vueRef);
                },
                headerText: () =>
                  `Added Negative Keywords (${vueRef.rightTableRows.length})`
              },
              apply(rows = []) {
                callNegativeApplyRows(rows, context, vueRef, retailer);
              },
              applyButtonDisabled() {
                callNegativeApplyButtonDisabled(vueRef, context);
              }
            };
          }
        }
      },
      ...(type === 'edit'
        ? {
            events: {
              afterAPIActionToCall(payload) {
                context.showAddCustomKeywordPanel = false;
                context.reloadComponent(true);
              },
              closePanelMethod() {
                context.showAddCustomKeywordPanel = false;
              }
            }
          }
        : {
            events: {
              rightTableRows(rows) {
                let allStepsData =
                  fetchSavedState(context, context?.selectedValues?.getter) ||
                  {};
                allStepsData.negativeTargets = rows;
                allStepsData = { ...allStepsData };
                updateSavedState(
                  context,
                  `${retailer}CampaignCreation/updateAllStepsData`,
                  allStepsData
                );
              },
              afterAPIActionToCall() {
                this.$emit('openSnackbar', 'Action has been submitted');
              }
            }
          })
    };
  };

export function getRedirectToFunctionDefinition(
  destinationRouteName = 'walmartEntityDetails'
) {
  return (vueRef) => {
    const { advertiserId, campaignType, campaignId, campaignName } =
      vueRef.$route.query;
    vueRef.$snackbar.open({
      message: 'Ad Group created successfully',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    vueRef.$router.replace({
      name: destinationRouteName,
      params: {
        entityType: campaignType,
        entityId: campaignId
      },
      query: {
        advertiser_id: advertiserId,
        parent_entity_name: campaignName
      }
    });
  };
}

const baseTargetingStep = {
  name: 'Targeting',
  description: '',
  targetingType: ['keyword'],
  header: {
    title: 'What is targeting ?',
    desc: [
      'Targeting uses keywords and to help your ads appear in search and detail pages.'
    ]
  },
  key: 'keywordTarget',
  customActionPanelCustomState: keywordTargetingState('create'),
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the keywords you would like to target?',
  textMapping: 'targeting',
  component: KeywordTargetingWalmart,
  isCreateAdGroup: true,
  dataService: DataService,
  btnRightText: 'Create Ad Group',
  keywordBidValidationObject: {
    min: 0.3
  },
  redirectTo: getRedirectToFunctionDefinition('walmartEntityDetails')
};

const samsClubApiAdGroupKeywordTargetingStepManual = {
  ...baseTargetingStep,
  customActionPanelNegativeKeywordTargetingCustomState:
    negativeKeywordTargetingState('create'),
  redirectTo: getRedirectToFunctionDefinition('samsclub_apiEntityDetails'),
  negativeStep: {
    ...baseTargetingStep,
    header: {
      title: 'What are the negative keyword you would like to target?',
      desc: [
        "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
      ]
    },
    customActionPanelNegativeKeywordTargetingCustomState:
      negativeKeywordTargetingState('create'),
    breadCrumbTitle: 'What are the negative keywords you would like to target?',
    redirectTo: getRedirectToFunctionDefinition('samsclub_apiEntityDetails')
  }
};

const samsClubApiAdGroupKeywordTargetingStepAuto = {
  ...baseTargetingStep,
  name: 'Negative Targets (Optional)',
  header: {
    title: 'What are the negative keyword you would like to target?',
    desc: [
      "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
    ]
  },
  customActionPanelNegativeKeywordTargetingCustomState:
    negativeKeywordTargetingState('create'),
  breadCrumbTitle: 'What are the negative keywords you would like to target?',
  redirectTo: getRedirectToFunctionDefinition('samsclub_apiEntityDetails')
};

export default baseTargetingStep;

export const samsClubApiAdGroupKeywordTargetingStep = {
  manual: samsClubApiAdGroupKeywordTargetingStepManual,
  auto: samsClubApiAdGroupKeywordTargetingStepAuto
};
