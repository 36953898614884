import HomeV2 from '@/components/homeV2.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import {
  digitalShelfRoute,
  reportsRoute,
  homeRoute,
  advertisingRoute,
  campaignOptimizerChildrenRoutes,
  getCampaignManagementChildrenRoutesWithTabConfig
} from '@/router/commonRoutePaths.js';
import urlHelper from '@/utils/helpers/url';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const commonCitrusRoutes = (retailer) => ({
  path: `/us/${retailer}_citrus/retail`,
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          `feature.pages.${retailer}_citrus.landingPage`,
          'advertising/campaign_management/campaigns'
        );
      }
    },
    reportsRoute,
    digitalShelfRoute,
    {
      ...advertisingRoute,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutesWithTabConfig(retailer)
        }
      ]
    },

    ...commonInternalRoutesArray
  ]
});

export default commonCitrusRoutes;
