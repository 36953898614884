<template>
  <div class="u-display-flex">
    <rb-select
      :width="'240px'"
      :search-enable-threshold="10000"
      :class="'is-bottom-left'"
      :on-close="onSearchOptionsClose"
      :send-details="true"
      :options="getFilteredSearchListOptions"
    >
      <template
        slot="item"
        slot-scope="option"
      >
        <div>
          <div class="u-display-flex u-flex-align-items-center">
            <div
              style="height: 48px; width: 48px; margin: 0px"
              class="media__img u-flex-none"
            >
              <img
                style="height: 48px; width: 48px; margin: 0px"
                :src="option.image_url"
              />
            </div>
            <div class="u-spacing-ml-m u-overflow-hidden">
              <p
                class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
              >
                {{ option.asin_name }}
              </p>
              <p
                class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
              >
                {{ option.asin }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <form
        slot="trigger"
        class="u-position-relative"
        @submit.prevent="onSearchSubmit"
      >
        <rb-input
          v-model="searchTerm"
          :placeholder="'Search ASIN or ASIN Name'"
          class="search__input u-width-240px"
          :rounded="false"
        />
        <span
          class="u-position-absolute u-display-flex"
          style="top: 0px; left: 8px; bottom: 0; margin: auto"
          @click.stop.prevent="onSearchSubmit"
        >
          <rb-icon
            icon="search"
            class="search__input-icon rb-icon--medium"
          />
        </span>
      </form>
    </rb-select>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  props: {
    config: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      empty: false,
      submitted: false,
      searchTerm: '',
      selectedItem: null
    };
  },
  computed: {
    getFilteredSearchListOptions() {
      var options =
        this.$store.getters.getGlobalFilterValues.catalogAsins || [];
      if (!options) {
        return null;
      }
      var _aReturn = options.filter(
        function (item) {
          if (item.title === 'All' && this.showAll === false) {
            return false;
          }

          for (var i in item) {
            if (
              (item[i] || '')
                .toString()
                .toLowerCase()
                .indexOf((this.searchTerm || '').toLowerCase()) !== -1
            ) {
              return true;
            }
          }

          return false;
        }.bind(this)
      );
      _aReturn = _aReturn.splice(0, 20);
      return _aReturn;
    }
  },
  methods: {
    onSearchOptionsClose(context, selection) {
      if (
        this.empty &&
        selection &&
        selection.length > 0 &&
        this.selectedItem === selection[0].asin &&
        this.searchTerm.length === 0
      ) {
        return;
      }
      if (
        selection &&
        selection.length > 0 &&
        this.selectedItem === selection[0].asin
      ) {
        if (this.searchTerm === '') {
          eventBus.$emit(this.config.emit, null);
          this.empty = true;
        }
        return;
      }

      var selected = null;
      if (
        selection &&
        selection.length > 0 &&
        this.selectedItem !== selection[0].asin
      ) {
        this.submitted = false;
        this.empty = false;
        this.selectedItem = selection[0].asin;
        this.searchTerm = selection[0].asin;
        selected = [
          {
            dimensionName: 'asin',
            dimensionValue: selection[0].asin
          }
        ];
        eventBus.$emit(this.config.emit, selected);
      }
    },
    onSearchSubmit() {
      var selected = null;
      if (this.searchTerm && this.searchTerm.length > 0) {
        this.empty = false;
        selected = [
          {
            dimensionName: 'search',
            dimensionValue: this.searchTerm,
            operator: 'ILIKE'
          }
        ];
      } else {
        this.empty = true;
      }
      this.submitted = true;
      this.selectedItem = null;
      eventBus.$emit(this.config.emit, selected);
    }
  }
};
</script>

<style scoped lang="css">
.list-wrap {
  font-size: 1.6rem;
}
.rb-input {
  padding-left: 30px;
}
</style>
