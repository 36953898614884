<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('predicted_crap') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('current_offer_price_is_')
      }}{{ formatter(alert.attributes.current_offer_price, 'currency') }} ({{
        formatter(alert.attributes.perc_off_list_price, 'percent')
      }}{{ $t('_of_list_price') }}).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pv-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('cogs')"
          :item-value="formatter(alert.attributes.cogs, 'currency')"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('gross_margin')"
          :item-value="formatter(alert.attributes.gross_margin, 'currency')"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('list_price')"
          :item-value="formatter(alert.attributes.list_price, 'currency')"
        />
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('revenue')"
          :item-value="
            formatter(alert.attributes.last_4weeks_revenue, 'currency')
          "
        />
        <span class="u-font-size-7 u-font-weight-300">
          ({{ $t('last_4_weeks') }})</span
        >
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p class="u-spacing-pt-xl u-font-size-6 u-font-weight-600">
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max) per week:
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('price_compression_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
      {{ $t('price_compression_rec_text') }}
    </p>
    <div class="detail-view--card">
      <el-table :data="[{}]">
        <el-table-column
          :label="$t('SKU_details')"
          :width="300"
        >
          <div slot-scope="scope">
            <div>
              <p
                :title="alert.attributes.rec_title_variation_asin"
                class="u-text-overflow-ellipsis"
              >
                {{ alert.attributes.rec_title_variation_asin }}
              </p>
              <p>{{ alert.attributes.recommended_asin_to_variate }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('list_price')">
          <div slot-scope="scope">
            <div>
              <p>
                {{ formatter(alert.attributes.rec_list_price, 'currency') }}
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('offer_price')">
          <div slot-scope="scope">
            <div>
              <p>
                {{ formatter(alert.attributes.rec_offer_price, 'currency') }}
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('cogs')">
          <div slot-scope="scope">
            <div>
              <p>{{ formatter(alert.attributes.rec_cogs, 'currency') }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('revenue')">
          <div slot-scope="scope">
            <div>
              <p>
                {{
                  formatter(
                    alert.attributes.rec_last_4weeks_revenue,
                    'currency'
                  )
                }}
              </p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import keyValuePair from '@/components/basic/keyValuePair';
export default {
  components: {
    chart,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          tooltip_format: {
            All: {
              roundoff: 2,
              pre: 'currency'
            },
            'Gross Margin': {
              suff: '%'
            },
            'Unit Sales': {
              roundoff: 0
            },
            'Net PPM': {
              suff: '%'
            }
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    var xs = {
      COGS: 'COGS_x',
      'List Price': 'List Price_x',
      'Gross Margin': 'Gross Margin_x',
      'Unit Sales': 'Unit Sales_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var cogsTrend = [];
    if (this.alert.attributes.cogs_trend) {
      cogsTrend = JSON.parse(this.alert.attributes.cogs_trend);
      if (cogsTrend.length) {
        dataArray = ['COGS'];
        dateArray = ['COGS_x'];
        for (entry of cogsTrend) {
          if (entry.COGS === undefined) continue;
          dataArray.push(entry.COGS);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var listPriceTrend = [];
    if (this.alert.attributes.list_price_trend) {
      listPriceTrend = JSON.parse(this.alert.attributes.list_price_trend);
      if (listPriceTrend.length) {
        dataArray = ['List Price'];
        dateArray = ['List Price_x'];
        for (entry of listPriceTrend) {
          if (entry.list_price === undefined) continue;
          dataArray.push(entry.list_price);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var offerPriceTrend = [];
    if (this.alert.attributes.offer_price_trend) {
      offerPriceTrend = JSON.parse(this.alert.attributes.offer_price_trend);
      if (offerPriceTrend.length) {
        offerPriceTrend = offerPriceTrend.filter((item) => {
          return item.retailer_site !== undefined;
        });
        dataArray = ['Price on ' + offerPriceTrend[0].retailer_site];
        dateArray = ['Price on ' + offerPriceTrend[0].retailer_site + '_x'];
        for (entry of offerPriceTrend) {
          if (entry.offer_price === undefined) continue;
          dataArray.push(entry.offer_price);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var compOfferPriceTrend = [];
    if (this.alert.attributes.comp_offer_price_trend) {
      compOfferPriceTrend = JSON.parse(
        this.alert.attributes.comp_offer_price_trend
      );
      if (compOfferPriceTrend && compOfferPriceTrend.length) {
        var comps = Array.from(
          new Set(
            compOfferPriceTrend.map((item) => {
              return item.retailer_site;
            })
          )
        );

        if (comps && comps.length) {
          for (var comp of comps) {
            dataArray = ['Price on ' + comp];
            dateArray = ['Price on ' + comp + '_x'];
            for (entry of compOfferPriceTrend) {
              if (entry.retailer_site === comp) {
                if (entry.comp_offer_price === undefined) continue;
                dataArray.push(entry.comp_offer_price);
                dateArray.push(entry.Date);
              }
            }
            chartData.push(dataArray, dateArray);
            xs[dataArray[0]] = dateArray[0];
          }
        }
      }
    }

    var grossMarginTrend = [];
    if (this.alert.attributes.gross_margin_perc_trend) {
      grossMarginTrend = JSON.parse(
        this.alert.attributes.gross_margin_perc_trend
      );
      if (grossMarginTrend.length) {
        dataArray = ['Gross Margin'];
        dateArray = ['Gross Margin_x'];
        for (entry of grossMarginTrend) {
          if (entry.gross_margin_perc === undefined) continue;
          dataArray.push(entry.gross_margin_perc);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var unitSalesTrend = [];
    if (this.alert.attributes.change_in_unit_sales_trend) {
      unitSalesTrend = JSON.parse(
        this.alert.attributes.change_in_unit_sales_trend
      );
      if (unitSalesTrend.length) {
        dataArray = ['Unit Sales'];
        dateArray = ['Unit Sales_x'];
        for (entry of unitSalesTrend) {
          if (entry.unit_sales === undefined) continue;
          dataArray.push(entry.unit_sales);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var netPPMWeeklyTrend = [];
    if (this.alert.attributes.net_ppm_weekly_trend) {
      netPPMWeeklyTrend = JSON.parse(
        this.alert.attributes.net_ppm_weekly_trend
      );
      netPPMWeeklyTrend = netPPMWeeklyTrend.sort((a, b) => {
        if (a.Date < b.Date) return -1;
        else if (a.Date > b.Date) return 1;
        return 0;
      });
      if (netPPMWeeklyTrend.length) {
        dataArray = ['Net PPM'];
        dateArray = ['Net PPM_x'];
        for (entry of netPPMWeeklyTrend) {
          if (entry.net_ppm === undefined) continue;
          dataArray.push(entry.net_ppm);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var thirdPartyTrend = [];
    if (this.alert.attributes.thirdparty_price_trend) {
      thirdPartyTrend = JSON.parse(
        this.alert.attributes.thirdparty_price_trend
      );
      thirdPartyTrend = thirdPartyTrend.sort((a, b) => {
        if (a.Date < b.Date) return -1;
        else if (a.Date > b.Date) return 1;
        return 0;
      });
      thirdPartyTrend = thirdPartyTrend.filter((item) => {
        return item.retailer_site !== undefined;
      });
      if (thirdPartyTrend.length) {
        dataArray = ['3P Price'];
        dateArray = ['3P Price_x'];
        for (entry of thirdPartyTrend) {
          if (entry['3p_offer_price'] === undefined) continue;
          dataArray.push(entry['3p_offer_price']);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
