import metricsDictionary, {
  retailerSpecificMetrics
} from '@/pages/campaign-management/retailers/dictionary.js';
import {
  commonTableActionIcons,
  baseChartConfig,
  commonChartActionIcons,
  additionalDateRanges,
  gridOptions
} from '@/pages/campaign-management/constants.js';
import {
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common.js';
import HttpService from '@/utils/services/http-service';
import { systemTransparencyBannerConfig } from '@/pages/campaign-management/retailers/walmart/utils.js';

const WIDGET = 'searchTerm';
const PRIMARY_KEY = 'search_term_id';

const getRetailerSpecificMetrics = (retailer) => {
  return retailer === 'walmart'
    ? retailerSpecificMetrics[retailer]
    : [
        'attributed_direct_click_sales',
        'attributed_related_click_sales',
        'attributed_brand_click_sales',
        ...retailerSpecificMetrics[retailer]
      ];
};

const getChartRequest = (retailer) => {
  const objectToReturn = {
    cubeName: `${retailer}_campaigns_search_term_workbench`,
    measuresList: [],
    groupByDimensionsList: [],
    timeseriesDimension: 'report_date',
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    getLatestAvailableInsteadOfRollup: false,
    getSharePercentage: false,
    enableNewPVPFormulaForSOV: false,
    disableShard: false,
    filterWhereClause: {
      dimensionNameValueList: []
    },
    commonFilterEnabled: false,
    outerWhereClause: {
      dimensionNameValueList: []
    },
    tagEnabled: false,
    whereClauseCombination: [],
    timeseriesEnabled: true,
    pvpenabled: true,
    yoyenabled: false,
    splyenabled: false,
    digitalShelfEnabled: false,
    sharePercentageV2: false,
    bundleCubeExecutionRequest: {
      adv_metrics: {
        cubeName: `${retailer}_campaigns_search_term_workbench`,
        measuresList: [
          'ad_spend',
          'roas_14d',
          'impressions',
          'clicks',
          'conversion_rate',
          'units_sold',
          'ctr',
          'acos_14d',
          'cpc',
          'total_attributed_sales',
          ...getRetailerSpecificMetrics(retailer)
        ],
        groupByDimensionsList: [
          'keyword_text',
          'ad_group_id',
          'advertiser_id',
          'campaign_id',
          'search_term',
          'match_type'
        ],
        timeseriesDimension: 'report_date',
        orderByList: [],
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        enableNewPVPFormulaForSOV: false,
        disableShard: false,
        filterWhereClause: {
          dimensionNameValueList: []
        },
        commonFilterEnabled: false,
        outerWhereClause: {
          dimensionNameValueList: []
        },
        tagEnabled: false,
        whereClauseCombination: [],
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        splyenabled: false,
        digitalShelfEnabled: false,
        sharePercentageV2: false
      },
      sov_metrics: {
        cubeName: `${retailer}_campaigns_search_term_workbench`,
        measuresList: [
          'total_organic_page_1_count',
          'organic_page_1_count',
          'organic_sov_page_1',
          'total_sp_page_1_count',
          'sp_page_1_count',
          'sp_sov_page_1',
          'total_sb_page_1_count',
          'sb_page_1_count',
          'sb_sov_page_1'
        ],
        groupByDimensionsList: ['search_term'],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        timeseriesDimension: 'report_date',
        orderByList: [],
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        enableNewPVPFormulaForSOV: false,
        disableShard: false,
        filterWhereClause: {
          dimensionNameValueList: []
        },
        commonFilterEnabled: false,
        outerWhereClause: {
          dimensionNameValueList: []
        },
        tagEnabled: false,
        whereClauseCombination: [],
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        splyenabled: false,
        digitalShelfEnabled: false,
        sharePercentageV2: false
      }
    }
  };
  return objectToReturn;
};

const getTableRequest = (retailer) => {
  const objectToReturn = {
    cubeName: `${retailer}_campaigns_search_term_workbench`,
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    timeseriesDimension: 'report_date',
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [],
    groupByDimensionsList: [],
    dimensionList: [],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    where: {
      date: {
        from: ':date',
        to: ':date'
      },
      pvpDate: {
        from: ':date',
        to: ':date'
      }
    },
    limit: 30,
    page: 1,
    customAPIDecisionVars: {
      type: null
    },
    entityType: 'search_term',
    primaryDataGroup: 'adv_metrics',
    bundleCubeExecutionRequest: {
      adv_metrics: {
        cubeName: `${retailer}_campaigns_search_term_workbench`,
        entityType: 'search_term',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        measuresList: [
          'search_term_id',
          'bid',
          'units_sold',
          'roas_14d',
          'acos_14d',
          'cpc',
          'ad_spend',
          'impressions',
          'clicks',
          'ctr',
          'conversion_rate',
          'total_attributed_sales',
          'advertiser_name',
          'campaign_name',
          'campaign_type',
          'targeting_type',
          'status',
          'campaign_status',
          'adgroup_status',
          'adgroup_name',
          'search_term_type',
          ...getRetailerSpecificMetrics(retailer)
        ],
        groupByDimensionsList: [
          'ad_group_id',
          'advertiser_id',
          'campaign_id',
          'search_term',
          'match_type',
          'keyword_text'
        ],
        dimensionList: [],
        orderByList: [],
        customAPIDecisionVars: {
          type: null
        },
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      },
      sov_metrics: {
        cubeName: `${retailer}_campaigns_search_term_workbench`,
        entityType: 'search_term',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        measuresList: [
          'total_organic_page_1_count',
          'organic_page_1_count',
          'organic_sov_page_1',
          'sp_page_1_count',
          'total_sp_page_1_count',
          'sp_sov_page_1',
          'total_sb_page_1_count',
          'sb_page_1_count',
          'sb_sov_page_1'
        ],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        groupByDimensionsList: ['search_term'],
        dimensionList: [],
        orderByList: [],
        customAPIDecisionVars: {
          type: null
        },
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      }
    }
  };

  return objectToReturn;
};

function getActionPanelType({ campaign_type, retailer }) {
  return ['sponsoredProducts', 'SP'].includes(campaign_type)
    ? `${retailer}searchTermsSP`
    : `${retailer}searchTermsSBA`;
}

function addAdditionalFields(
  { match_type, ad_group_id, advertiser_id, search_term_id },
  type
) {
  return type === 'actionPayload'
    ? {
        matchType: match_type,
        adgroupId: ad_group_id,
        advertiser_id,
        search_term_id
      }
    : {};
}

function getNewBid(selection, campaignType) {
  if (Number.isNaN(parseFloat(selection.actionPayload.newBid))) {
    if (campaignType === 'SP') {
      return 0.3;
    }
    return 1;
  }
  return selection.actionPayload.newBid;
}

function triggerCallApiFromCampaignEditEvent(
  context,
  { selections, vueRef },
  isNegativeKeyword = false
) {
  const retailer = context.$store.getters.getRetailer;
  selections = selections.map((selection) => {
    const campaignType = selection.actionPayload.campaignType?.toUpperCase?.();
    const actionPayload = {
      campaignType: campaignType,
      new_keyword_text: selection.actionPayload.keywordText,
      new_match_type: selection.actionPayload.matchType,
      ad_group_id: selection.actionPayload.adgroupId,
      campaign_id: selection.actionPayload.campaignId,
      state: 'enabled',
      advertiser_id: selection.actionPayload.advertiser_id,
      new_bid: getNewBid(selection, campaignType)
    };
    const viewPayload = {
      adgroupName: selection.viewPayload.adgroupName,
      new_keyword_text: selection.viewPayload.keywordText,
      new_match_type: selection.viewPayload.matchType,
      new_bid: getNewBid(selection, campaignType)
    };

    if (isNegativeKeyword) {
      delete actionPayload.state;
      delete actionPayload.new_bid;
      actionPayload.new_match_type = 'negativeExact';
      selection.actionType = `${retailer}AddNegativeKeywordToCampaigns`;
    }
    return {
      ...selection,
      actionSource: {
        pageUrl: selection.actionSource.pageUrl
      },
      widget: WIDGET,
      primaryKey: selection.actionPayload[PRIMARY_KEY],
      actionPayload,
      viewPayload
    };
  });
  let message;
  HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', selections)
    .then(async ({ data }) => {
      message = `${
        selections.length > 1 ? 'Keywords were' : 'Keyword was'
      } added succesfully`;
    })
    .catch(() => {
      message = `Error while adding keyword${selections.length > 1 ? 's' : ''}`;
    })
    .finally(() => {
      context.$snackbar.open({
        message,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      vueRef.$emit('afterAPIActionToCall');
    });
}

const getCommonObjectForAddKeywordsAndNegativeKeywords = (
  context,
  retailer
) => {
  return {
    icon: 'add-circle-outline',
    title: 'Add Keyword',
    id: 'add-keyword',
    customState: {
      component: 'AddSearchTermAsKeywordPanel',
      props: {
        isRetailerIndependent: true,
        retailerIndependentConfigs: {
          APIConfig: {
            cubeName: `${retailer}_campaigns_drop_down_list_per_advertiser`,
            measuresList: ['advertiser_name'],
            groupByDimensionsList: [
              'campaign_type',
              'campaign_id',
              'adgroup_id',
              'campaign_name',
              'adgroup_name',
              'advertiser_id',
              'targeting_type'
            ],
            where: {
              date: {},
              dimensionNameValueList: []
            },
            orderByList: [
              {
                dimension: 'campaign_name',
                direction: 'ASC'
              }
            ],
            timeseriesEnabled: false,
            pvpenabled: false,
            yoyenabled: false
          },
          getWhereClause: ({ selections }) => {
            return [
              {
                dimensionName: 'targeting_type',
                dimensionValue: 'manual'
              }
            ];
          },
          getActionPanelType: (val) =>
            getActionPanelType({ ...(val || {}), retailer }),
          addAdditionalFields: addAdditionalFields
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        },
        callApiFromCampaignEdit: ({ selections, vueRef }) => {
          triggerCallApiFromCampaignEditEvent(context, {
            selections,
            vueRef
          });
        }
      }
    },
    customRenderHandler() {}
  };
};

const getAddKeywordsActionObjectArray = (context, retailer) => {
  let arrayToReturn = [];
  if (retailer === 'walmart') {
    arrayToReturn = [
      getCommonObjectForAddKeywordsAndNegativeKeywords(context, retailer)
    ];
  }
  return arrayToReturn;
};

const getAddNegativeKeywordsActionObjectArray = (context, retailer) => {
  let arrayToReturn = [];
  if (retailer === 'samsclub_api') {
    const commonObject = getCommonObjectForAddKeywordsAndNegativeKeywords(
      context,
      retailer
    );
    commonObject.title = 'Add Negative Keyword';
    commonObject.id = 'add-negative-keyword';
    commonObject.customState.events.callApiFromCampaignEdit = ({
      selections,
      vueRef
    }) => {
      triggerCallApiFromCampaignEditEvent(
        context,
        {
          selections,
          vueRef
        },
        true
      );
    };
    commonObject.customState.props = {
      ...commonObject.customState.props,
      retailerIndependentConfigs: {
        ...commonObject.customState.props.retailerIndependentConfigs,
        getWhereClause: ({ selections }) => {
          return [];
        }
      },
      matchTypeOptions: [{ title: 'negative exact' }],
      hideKeywordsBidSection: true,
      isUsingForAddNegativeKeyword: true
    };
    arrayToReturn = [commonObject];
  }
  return arrayToReturn;
};

export default (context, tab, retailer = 'walmart') => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Search Terms'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'campaigns_search_term.max_feed_date',
        min: 'campaigns_search_term.min_feed_date'
      },
      api: {
        cube: `${retailer}_campaigns_search_term_workbench`,
        endPoint: 'FETCH_FILTERS_V2',
        page: `${retailer}_search_terms`
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'search-terms-filter',
      page: `${retailer}_search_terms`,
      listenerEvent: `${retailer}-search-terms-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-search-terms',
          placeholder: 'Search for Search Term',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-search-terms-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: getChartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          enableWatefall: true,
          chartConfig: baseChartConfig(),
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      activityBar: {
        pageType: 'searchTerm'
      },
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          ...getAddKeywordsActionObjectArray(context, retailer),
          ...getAddNegativeKeywordsActionObjectArray(context, retailer)
        ]
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: getTableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'search-terms'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        }
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    },
    ...systemTransparencyBannerConfig
  };
};
