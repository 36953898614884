import Vue from 'vue';
import walmartKeywordsConfig from '@/pages/campaign-management/retailers/walmart/keywords/index.js';
import walmartCampaignsConfig from '@/pages/campaign-management/retailers/walmart/campaigns/index.js';
import walmartAdSkusConfig from '@/pages/campaign-management/retailers/walmart/skus/index.js';
import walmartSearchTermConfig from '@/pages/campaign-management/retailers/walmart/search_terms/index.js';
import criteoCampaignsConfig from '@/pages/campaign-management/retailers/criteo/campaigns';
import criteoLineItemsConfig from '@/pages/campaign-management/retailers/criteo/line_items';
import criteoSearchTermsConfig from '@/pages/campaign-management/retailers/criteo/search_terms';
import criteoAdSkusConfig from '@/pages/campaign-management/retailers/criteo/ad_skus';
import criteoKeywordsConfig from '@/pages/campaign-management/retailers/criteo/keywords/index.js';

const multiSearchConfigsByEntity = {
  campaigns: {
    searchDimension: 'campaign_name',
    subTitle: 'campaign names',
    findEntityInputPlaceholder: 'Find a campaign name'
  },
  keywords: {
    searchDimension: 'keyword_text',
    subTitle: 'keywords',
    findEntityInputPlaceholder: 'Find a keyword'
  },
  criteo_keywords: {
    searchDimension: 'keyword',
    subTitle: 'keywords',
    findEntityInputPlaceholder: 'Find a keyword'
  },
  ad_skus: {
    searchDimension: 'product_id',
    subTitle: 'SKUs',
    findEntityInputPlaceholder: 'Find a SKU'
  },
  search_terms: {
    searchDimension: 'search_term',
    subTitle: 'search terms',
    findEntityInputPlaceholder: 'Find a search term'
  },
  line_items: {
    searchDimension: 'line_item_name',
    subTitle: 'line item names',
    findEntityInputPlaceholder: 'Find a line item name'
  }
};

const enableFilterV2Config = (retailer) =>
  retailer
    ? Vue.options.filters.config_check(
        `feature.pages.${retailer}_campaign_taxonomy.enable`
      )
    : false;

const getCriteoRetailerCMRoutes = (retailer, context) => ({
  campaigns: {
    ...criteoCampaignsConfig(context, `${retailer}_campaigns`, `${retailer}`),
    enableFilterV2: enableFilterV2Config(retailer),
    multiSearch: multiSearchConfigsByEntity.campaigns
  },
  line_items: {
    ...criteoLineItemsConfig(context, `${retailer}_line_items`, `${retailer}`),
    enableFilterV2: enableFilterV2Config(retailer),
    multiSearch: multiSearchConfigsByEntity.line_items
  },
  ad_skus: {
    ...criteoAdSkusConfig(context, `${retailer}_ad_skus`, `${retailer}`),
    enableFilterV2: enableFilterV2Config(retailer),
    multiSearch: multiSearchConfigsByEntity.ad_skus
  },
  search_terms: {
    ...criteoSearchTermsConfig(
      context,
      `${retailer}_search_terms`,
      `${retailer}`
    ),
    enableFilterV2: enableFilterV2Config(retailer),
    multiSearch: multiSearchConfigsByEntity.search_terms
  },
  keywords: {
    ...criteoKeywordsConfig(context, `${retailer}_keywords`, `${retailer}`),
    enableFilterV2: enableFilterV2Config(retailer),
    multiSearch: multiSearchConfigsByEntity.criteo_keywords
  }
});

export default (context) => {
  const retailer = context?.$store?.getters?.getRetailer;
  return {
    walmart: {
      campaigns: {
        ...walmartCampaignsConfig(context, 'campaigns', 'walmart'),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.campaigns
      },
      keywords: {
        ...walmartKeywordsConfig(context, 'keywords', 'walmart'),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.keywords
      },
      ad_skus: {
        ...walmartAdSkusConfig(context, 'ad_skus', 'walmart'),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: {
          ...multiSearchConfigsByEntity.ad_skus,
          searchDimension: 'sku'
        }
      },
      search_terms: {
        ...walmartSearchTermConfig(context, 'search_terms', 'walmart'),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.search_terms
      }
    },
    samsclub_api: {
      campaigns: {
        ...walmartCampaignsConfig(
          context,
          'samsclub_api_campaigns',
          'samsclub_api'
        ),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.campaigns
      },
      keywords: {
        ...walmartKeywordsConfig(
          context,
          'samsclub_api_keywords',
          'samsclub_api'
        ),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.keywords
      },
      ad_skus: {
        ...walmartAdSkusConfig(context, 'samsclub_api_ad_skus', 'samsclub_api'),
        multiSearch: {
          ...multiSearchConfigsByEntity.ad_skus,
          searchDimension: 'sku'
        }
      },
      search_terms: {
        ...walmartSearchTermConfig(
          context,
          'samsclub_api_search_terms',
          'samsclub_api'
        ),
        enableFilterV2: enableFilterV2Config(retailer),
        multiSearch: multiSearchConfigsByEntity.search_terms
      }
    },
    costco: getCriteoRetailerCMRoutes('costco', context),
    target: getCriteoRetailerCMRoutes('target', context),
    meijer: getCriteoRetailerCMRoutes('meijer', context),
    shipt_criteo: getCriteoRetailerCMRoutes('shipt_criteo', context),
    walmart_criteo: getCriteoRetailerCMRoutes('walmart_criteo', context),
    freshdirect_criteo: getCriteoRetailerCMRoutes(
      'freshdirect_criteo',
      context
    ),
    walgreen_criteo: getCriteoRetailerCMRoutes('walgreen_criteo', context),
    cvs_criteo: getCriteoRetailerCMRoutes('cvs_criteo', context),
    asda_criteo: getCriteoRetailerCMRoutes('asda_criteo', context),
    ulta_criteo: getCriteoRetailerCMRoutes('ulta_criteo', context),
    albertsons_criteo: getCriteoRetailerCMRoutes('albertsons_criteo', context),
    ubereats_criteo: getCriteoRetailerCMRoutes('ubereats_criteo', context),
    target_rms: getCriteoRetailerCMRoutes('target_rms', context)
  };
};
