export const criteoRetailers = [
  'target',
  'meijer',
  'costco',
  'samsclub',
  'shipt_criteo',
  'walmart_criteo',
  'walgreen_criteo',
  'cvs_criteo',
  'asda_criteo',
  'ulta_criteo',
  'albertsons_criteo',
  'ubereats_criteo',
  'target_rms',
  'freshdirect_criteo'
];

export const criteoCommonRetailers = [
  'ulta_criteo',
  'albertsons_criteo',
  'ubereats_criteo',
  'target_rms',
  'freshdirect_criteo'
];
export const citrusRetailers = [
  'albertsons_citrus',
  'hyvee_citrus',
  'shipt_citrus',
  'shoprite_citrus',
  'target_citrus',
  'ahold_citrus',
  'sainsburys_citrus',
  'waitrose_citrus',
  'tesco_citrus',
  'gopuff_citrus'
];

export function isCriteoRetailer(retailer) {
  return criteoRetailers.includes(retailer);
}

export function isCriteoCommonRetailer(retailer) {
  return criteoCommonRetailers.includes(retailer);
}

export function isCitrusRetailer(retailer) {
  return retailer.includes('_citrus');
}

export function isPromoteIQRetailer(retailer) {
  return retailer.includes('_promoteiq');
}
