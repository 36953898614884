import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import HttpService from '@/utils/services/http-service';
import requestTemplate, {
  sbConfigRequest
} from '@/components/ams/campaign_creation/steps/setup/campaign/requestTemplate.js';
import {
  formProductTargetRequestSB,
  formKeywordRequestSB,
  keyValuePairForSP,
  keyValuePairForSB,
  formProductRequest,
  formKeywordRequest,
  formProductTargetsRequest,
  formNegativeKeywordsRequest,
  formPlacementAdjustmentRequest,
  formTargetsRequestSpAuto
} from '@/components/ams/campaign_creation/steps/setup/campaign/formRequestPayloadUtility.js';

export const createCampaign = (
  allStepsData,
  selectedIds,
  ref,
  ignoreSelectedIds
) => {
  const suggestedCampaigns = allStepsData?.suggestedCampaigns;
  const finalRequestArray = [];
  for (const item of suggestedCampaigns) {
    if (
      !selectedIds.includes(formSuggestedCampaignId(item)) &&
      !ignoreSelectedIds
    ) {
      continue;
    }
    const campaignType = campaignTypeMap[item?.campaignType];
    const requestObject = formRequestObject(campaignType, item);
    const keyValuePairs = formKeyValuePairs(campaignType, item);
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    formFinalRequestForCampaignType(requestObject, item, campaignType);
    finalRequestArray.push(requestObject);
  }
  callCamapignCreateApi(finalRequestArray, ref);
};

const callCamapignCreateApi = (requestPayload, ref) => {
  const message = {};
  ref.campaignCreationLoader = true;
  HttpService.post('CREATE_AMS_ACTIONS_MANAGER', requestPayload)
    .then((res) => {
      ref.$store.dispatch(ref.selectedValues.action, {
        ...ref.allStepsData,
        campaignsCreated: res?.data?.response,
        enabledBreadCrumbsMap: {},
        enabledBreadCrumbs: [0]
      });
      let showFailureToastText = false;
      if (res?.data?.success > 0 && res?.data?.failure === 0) {
        message.text = 'Campaign(s) created successfully';
        message.value = 'success';
      } else if (res?.data?.failure > 0 && res?.data?.success === 0) {
        showFailureToastText = true;
        message.value = 'error';
      } else {
        showFailureToastText = true;
        message.value = 'success';
      }
      if (showFailureToastText) {
        const result =
          res?.data?.response?.map((item) => item.description) ||
          'Error while creating one or more campaigns';
        message.text = result?.toString()?.replaceAll(',', '<br>');
      }
    })
    .catch(() => {
      message.text = 'Error while creating camapign(s)';
      message.value = 'error';
    })
    .finally(() => {
      ref.campaignCreationLoader = false;
      ref.$snackbar.open({
        message: message.text,
        duration: 10000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      if (message.value === 'success') {
        ref.$router.push({
          name: 'campaign_created',
          query: {
            flow: 'amazon'
          }
        });
      }
    });
};

const formSuggestedCampaignId = (item) => {
  return `${item?.suggestedCampaignId}_${item?.suggestedCampaignName}`;
};

const formRequstForSB = (requestObject, item) => {
  if (item?.targetingType === 'keyword') {
    requestObject.request.sbKeywordRequest = formKeywordRequestSB(
      sbConfigRequest.sbKeywordRequest,
      item.targets
    );
    requestObject.request.sbNegativeKeywordRequest =
      formNegativeKeywordsRequest(
        sbConfigRequest.sbNegativeKeywordRequest,
        item?.negativeTargets || []
      );
  }
  if (item.targetingType === 'product') {
    requestObject.request.sbTargetRequest = formProductTargetRequestSB(
      sbConfigRequest.sbTargetRequest,
      item.targets
    );
  }
};

const formRequestForSP = (requestObject, item) => {
  requestObject.request.productRequest = formProductRequest(
    requestTemplate.productRequest,
    item.skus
  );
  if (item?.spTargetingType === 'auto') {
    requestObject.request.targetRequest = formTargetsRequestSpAuto(
      requestTemplate.autoTargetRequest,
      item
    );
  } else {
    if (item?.targetingType === 'keyword') {
      requestObject.request.keywordRequest = formKeywordRequest(
        requestTemplate.keywordRequest,
        item.targets
      );
    } else if (item?.targetingType === 'product') {
      requestObject.request.targetRequest = formProductTargetsRequest(
        requestTemplate.targetsRequest,
        item.targets
      );
    }
  }
  requestObject.request.negativeKeywordRequest = formNegativeKeywordsRequest(
    requestTemplate.negativeKeywordRequest,
    item?.negativeTargets || []
  );
  const adjustments = formPlacementAdjustmentRequest(
    requestTemplate.adjustments,
    item?.productPages,
    item?.topOfSearch
  );
  if (adjustments?.length) {
    requestObject.request.campaignRequest.biddingSP.adjustments = adjustments;
  }
};

const formFinalRequestForCampaignType = (requestObject, item, campaignType) => {
  if (campaignType === 'sponsoredProducts') {
    formRequestForSP(requestObject, item);
  } else if (campaignType === 'sponsoredBrands') {
    formRequstForSB(requestObject, item);
  }
};

const formKeyValuePairs = (campaignType, item) => {
  if (campaignType === 'sponsoredProducts') {
    return keyValuePairForSP(campaignType, item);
  } else if (campaignType === 'sponsoredBrands') {
    return keyValuePairForSB(campaignType, item);
  }
};

const campaignTypeMap = {
  sponsoredProducts: 'sponsoredProducts',
  createOwnCampaignSP: 'sponsoredProducts',
  sponsoredBrands: 'sponsoredBrands',
  createOwnCampaignSB: 'sponsoredBrands'
};

const formRequestObject = (campaignType, item) => {
  let requestObject = null;
  if (campaignType === 'sponsoredProducts') {
    requestObject = cloneDeep(requestTemplate.requestObject);
  } else if (campaignType === 'sponsoredBrands') {
    requestObject = cloneDeep(sbConfigRequest.requestObject);
    if (item?.adFormat === 'video') {
      if (requestObject?.request?.campaignRequest?.landingPage) {
        delete requestObject.request.campaignRequest.landingPage;
      }
    }
  }
  return requestObject;
};
