<template>
  <div
    class="search__block--wrapper u-position-relative"
    @click.stop
  >
    <div class="search_input u-position-relative">
      <rb-input
        v-model="searchText"
        :placeholder="$t('search_placeholder')"
        :class="className"
        :rounded="false"
        @input="getSearchResults"
        @focus="openSearchResults"
      />
      <rb-icon
        :icon="'search'"
        class="u-color-grey-x-light search__input__icon rb-icon--small u-position-absolute"
      />
    </div>
    <div
      v-if="isSearchResultsOpen"
      class="search__results"
    >
      <ul
        class="search__results--list u-spacing-pb-m"
        style="min-height: 60px"
      >
        <li
          v-for="(item, index) in searchResults"
          :key="index"
          class="search__results--list-item u-spacing-ph-m u-spacing-pt-s"
        >
          <div v-if="item.asin">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.title }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
          <div v-else-if="item.search">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.search }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
          <div v-else-if="item.bc_brand_alert_type">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.bc_brand_alert_type }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
          <div v-else-if="item.keyword_text">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.keyword_text }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
          <div v-if="item.search_term">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.search_term }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
          <div v-if="item.campaign_name">
            <div
              class="result--details u-spacing-pb-s u-text-overflow-ellipsis"
            >
              {{ item.campaign_name }}
            </div>
            <div
              v-for="(type, innerIndex) in item.recommendationNameType"
              :key="innerIndex"
              class="result--types u-spacing-pb-s"
              @click="openAlertForASIN(item, type)"
            >
              {{ type.recommendationLabel }}
            </div>
          </div>
        </li>
        <li v-if="isFetchingResults">
          <Loader
            class="loader--expand"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
import detailViewConfig from '@/components/pages/recommendations/detailViewConfig.js';

export default {
  name: 'SearchBox',
  components: {
    Loader
  },
  props: {
    className: {
      type: String,
      default: 'search__input'
    },
    searchString: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchText: ''
    };
  },
  computed: {
    isFetchingResults() {
      return this.$store.getters.isFetchingResults;
    },
    isSearchResultsOpen() {
      return (
        this.$store.getters.isSearchResultsOpen &&
        this.$store.getters.getCurrentSubGroup === 'All'
      );
    },
    searchResults() {
      return this.$store.getters.getSearchResults;
    },
    primaryKeyHashMap() {
      return this.$store.getters.getPrimaryKeyHashMap;
    }
  },
  watch: {
    searchString: function (value) {
      this.searchText = value;
    }
  },
  beforeDestroy() {
    this.closeSearchResults();
  },
  methods: {
    openSearchResults() {
      this.$store.commit('OPEN_SEARCH_RESULTS');
      document.addEventListener('click', this.closeSearchResults);
      this.getSearchResults(this.searchText);
    },
    closeSearchResults() {
      document.removeEventListener('click', this.closeSearchResults);
      this.$store.commit('CLOSE_SEARCH_RESULTS');
    },
    getSearchResults(searchText) {
      // if (this.$store.getters.getCurrentSubGroup === 'All') {
      //   if (searchText.length < 3) {
      //     this.$store.commit('CLEAR_SEARCH_RESULTS')
      //   } else {
      //     this.$store.dispatch('fetchSearchResults', searchText)
      //   }
      // } else {
      this.$emit('localSearch', searchText);
      // }
    },
    openAlertForASIN(params, type) {
      var templates = detailViewConfig.detailViewTemplates;
      if (templates[type.recommendationName] === undefined) {
        this.$snackbar.open({
          message:
            '<div class="u-spacing-pv-xs">Comprehensive detail view of this recommendation is not available at the present.</div><div class="u-spacing-pv-xs">If any important metric is missing in the tabular list view, please let us know via product feedback.</div>',
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        this.$router.push({
          name: 'recommendations',
          query: {
            name: type.recommendationName,
            type: type.recommendationType,
            bc_recommendation_type: type.bc_recommendation_type
          }
        });
      } else {
        const queryParams = {
          name: type.recommendationName,
          type: type.recommendationType,
          bc_recommendation_type: type.bc_recommendation_type
        };
        const key = this.primaryKeyHashMap[type.bc_recommendation_type];
        queryParams[key] = params[key];
        this.$router.push({
          name: 'recommendations',
          query: queryParams
        });
      }
      this.closeSearchResults();
    }
  }
};
</script>

<style lang="css">
.search_input > .search__input {
  width: 280px;
  border-radius: 2px;
}
.search_input > .search__input input {
  height: 29px !important;
  border-radius: 2px !important;
  font-size: 11px !important;
  padding-right: 8px !important;
  background: #f7f7f7 !important;
  border: none;
}

.search_input .search__input__icon {
  top: 0px;
  bottom: 0px;
  margin: auto;
  left: 8px;
}

.search__results--list {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  background: white;
  border-radius: 4px;
  z-index: 1000;
}

.search__results--list-item {
  border-bottom: 1px solid #e9eaeb;
}
.search__results--list-item .result--details {
  font-size: 14px;
}
.search__results--list-item .result--types {
  font-size: 11px;
  cursor: pointer;
}
.search__results--list-item .result--types:hover {
  text-decoration: underline;
  color: #007cf6;
}
</style>
