import HttpService from '@/utils/services/http-service';

const state = {
  bookmarks: [],
  isLoading: false
};

const getters = {
  getAllBookmarks: (state) => {
    return state.bookmarks || [];
  },
  isLoading: (state) => {
    return state.isLoading;
  }
};

const mutations = {
  SET_BOOKMARKS: (state, data) => {
    state.bookmarks = [...data];
  },
  SET_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
  UPDATE_ID_BOOKMARK: (state, payload) => {
    const index = state.bookmarks.findIndex(
      (item) => item.path || item.PATH === payload.path
    );
    if (index !== -1) {
      state.bookmarks[index] = { ...payload };
      state.bookmarks = [...state.bookmarks];
    }
    // Vue.set(state.bookmarks[index], 'id', payload.id);
  }
};

const actions = {
  fetchAllBookmarks: (context, options) => {
    context.commit('SET_LOADING', true);
    HttpService.post(
      'CUSTOMERMETADATA_SERVICE',
      {},
      { append: '/getAllBookmarks' }
    )
      .then(
        (response) => {
          const transformResponse = response?.data?.data.map((item) =>
            Object.keys(item).reduce(
              (prev, current) => ({
                ...prev,
                [current.toLowerCase()]: item[current]
              }),
              {}
            )
          );
          context.commit('SET_BOOKMARKS', transformResponse || []);
        },
        () => {
          context.commit('SET_BOOKMARKS', []);
        }
      )
      .finally(() => {
        context.commit('SET_LOADING', false);
      });
  },
  quickAdd: (context, payload) => {
    const allBookmarks = context.getters.getAllBookmarks;
    allBookmarks.unshift({ ...payload, id: '' });
    context.commit('SET_BOOKMARKS', [...allBookmarks]);
  },
  quickRemove: (context, payload) => {
    let allBookmarks = context.getters.getAllBookmarks;
    if (payload.id) {
      allBookmarks = allBookmarks.filter((item) => item.id !== payload.id);
    } else {
      allBookmarks = allBookmarks.filter((item) => item.path !== payload.path);
    }
    context.commit('SET_BOOKMARKS', allBookmarks);
  },
  updateID: (context, payload) => {
    context.commit('UPDATE_ID_BOOKMARK', payload);
  },
  addBookmark: (context, payload) => {
    if (!context.getters.isLoading) {
      context.commit('SET_LOADING', true);
      context.dispatch('quickAdd', payload);
      return HttpService.post('CUSTOMERMETADATA_SERVICE', payload)
        .then(
          (response) => {
            context.dispatch('updateID', response.data?.data);
          },
          (error) => {
            context.dispatch('quickRemove', payload);
            throw new Error('Error message', error);
          }
        )
        .finally(() => {
          context.commit('SET_LOADING', false);
        });
    }
  },
  removeBookmark: (context, bookmarkPath) => {
    if (!context.getters.isLoading) {
      context.commit('SET_LOADING', true);
      const elem = context.getters.getAllBookmarks.filter(
        (item) => item.path === bookmarkPath
      );
      if (elem.length > 0) {
        const payload = {
          id: elem[0].id
        };
        context.dispatch('quickRemove', payload);
        return HttpService.delete('CUSTOMERMETADATA_SERVICE', payload)
          .then(
            () => {},
            (error) => {
              context.dispatch('quickAdd', elem[0]);
              throw new Error('Error message', error);
            }
          )
          .finally(() => {
            context.commit('SET_LOADING', false);
          });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
