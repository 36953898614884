var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"box-shadows u-bg-color-grey-white u-border-radius-xs"},[_c('section',{staticClass:"u-spacing-p-m"},[_c('div',{staticClass:"u-font-size-3 u-font-weight-600 u-spacing-mb-m"},[_vm._v(" "+_vm._s((_vm.popupData || {}).text)+" ")]),_c('BubbleChartPopupRow',{attrs:{"axis-heading":'Horizontal Axis',"axis-label":_vm.popupData.xAxisLabel,"coordinate":_vm.getCoordinate(
          ((_vm.popupData || {}).originalValues || {}).x,
          _vm.popupData.xAxisUnit,
          _vm.popupData.coordinatePrecision
        )}}),_c('BubbleChartPopupRow',{staticClass:"u-spacing-mt-l",attrs:{"axis-heading":'Vertical Axis',"axis-label":_vm.popupData.yAxisLabel,"coordinate":_vm.getCoordinate(
          ((_vm.popupData || {}).originalValues || {}).y,
          _vm.popupData.yAxisUnit,
          _vm.popupData.coordinatePrecision
        )}}),(((_vm.popupData || {}).originalValues || {}).z)?_c('BubbleChartPopupRow',{staticClass:"u-spacing-mt-l",attrs:{"axis-heading":'Bubble Radius',"axis-label":_vm.popupData.zAxisLabel,"coordinate":_vm.getCoordinate(
          ((_vm.popupData || {}).originalValues || {}).z,
          _vm.popupData.zAxisUnit,
          _vm.popupData.coordinatePrecision
        )}}):_vm._e()],1),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }