let environment = 'dev';
const domainArray = location.hostname.split('.');
if (domainArray.length === 3) {
  environment = 'prod';
} else if (domainArray.length === 4) {
  environment = domainArray[1];
} else {
  environment = 'dev';
}
window.environment = environment;
window.clientName = domainArray[0];
