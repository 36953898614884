import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import { cloneDeep } from 'lodash';

const state = {
  instacartAdGroupsData: {},
  instacartSKUsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  subTypes: {},
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  subGroupRows: [],
  showAdgroupActivityLog: false,
  AdgroupsPrimaryKey: null,
  instacartSubGroups: {},
  subGroupLoader: true,
  page_wise_min_max_key: 'campaigns_adgroup'
};

const getters = {
  getInstacartAdGroupsSelectedFilters: (state) => {
    return state.instacartSKUsSelectedFilters;
  },
  getInstacartAdGroupsData: (state) => {
    return state.instacartAdGroupsData;
  },
  getInstacartAdGroupsActivityLog: (state) => {
    return state.showAdgroupActivityLog;
  },
  getInstacartAdGroupsPrimaryKey: (state) => {
    return state.AdgroupsPrimaryKey;
  },
  getInstacartSubGroupTypes: (state) => {
    return state.instacartSubGroups;
  },
  getSubgroupLoader: (state) => {
    return state.subGroupLoader;
  },
  getInstaCartAdGroupsPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  UPDATE_SUBGROUP_LOADER: (state, data) => {
    state.subGroupLoader = data;
  },
  INSTACART_SUBGROUPS: (state, data) => {
    const levelName = 'instacartSubGroups';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, {});
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  },
  SUBGROUP_ROWS: (state, data) => {
    state.subGroupRows = [...data];
  },
  INSTA_ADGROUPS_SUCCESS: (state, data) => {
    Vue.set(state.instacartAdGroupsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.instacartAdGroupsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.instacartAdGroupsData[data.key], 'data', data.columns);
    }
    Vue.set(state.instacartAdGroupsData[data.key], 'rows', data.rows);
    Vue.set(state.instacartAdGroupsData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.instacartAdGroupsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.instacartAdGroupsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.instacartAdGroupsData[data.key], 'page', data.page);
    Vue.set(state.instacartAdGroupsData[data.key], 'load', false);
    Vue.set(state.instacartAdGroupsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.instacartAdGroupsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.instacartAdGroupsData[data.key], 'noData', true);
    }
  },
  IWKMSKUs_RESET: (state, data) => {
    Vue.set(state.instacartAdGroupsData, data.key, {});
    Vue.set(state.instacartAdGroupsData[data.key], 'columns', []);
    Vue.set(state.instacartAdGroupsData[data.key], 'rows', []);
    Vue.set(state.instacartAdGroupsData[data.key], 'load', true);
    Vue.set(state.instacartAdGroupsData[data.key], 'error', false);
    Vue.set(state.instacartAdGroupsData[data.key], 'noData', false);
    Vue.set(state.instacartAdGroupsData[data.key], 'metrics', {});
  },
  IWKMSKUs_ERROR: (state, data) => {
    Vue.set(state.instacartAdGroupsData, data.key, {});
    Vue.set(state.instacartAdGroupsData[data.key], 'columns', []);
    Vue.set(state.instacartAdGroupsData[data.key], 'rows', []);
    Vue.set(state.instacartAdGroupsData[data.key], 'load', false);
    Vue.set(state.instacartAdGroupsData[data.key], 'error', true);
    Vue.set(state.instacartAdGroupsData[data.key], 'noData', false);
    Vue.set(state.instacartAdGroupsData[data.key], 'metrics', {});
  },
  IWKMSKUs_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.instacartSKUsSelectedFilters.date_range;
    Vue.set(state, 'instacartSKUsSelectedFilters', data.values);
    if (!state.instacartSKUsSelectedFilters.date_range) {
      Vue.set(state.instacartSKUsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKMSKUs(data, state);
  },
  OPEN_AD_GROUPS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showAdgroupActivityLog', true);
  },
  CLOSE_AMSSKUS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showAdgroupActivityLog', false);
  },
  CHANGE_ADGROUPS_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'AdgroupsPrimaryKey', key);
  }
};

function addDefaultSubTypeToAPIConfig(APIConfig) {
  const newAPIConfig = { ...APIConfig };
  newAPIConfig.where.dimensionNameValueList =
    newAPIConfig?.where?.dimensionNameValueList?.filter(
      (item) => item.dimensionName !== 'sub_type'
    ) || [];
  newAPIConfig.where.dimensionNameValueList.push({
    dimensionName: 'sub_type',
    dimensionValue: '-'
  });
  return newAPIConfig;
}

function getFiltersInFormatWKMSKUs(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  downloadSKUTableData: (context, data) => {
    return HttpLayer.post({
      cube: 'EXECUTE_CUSTOM_CUBE_SKU_API',
      APIData: data
    }).then((response) => {
      response.data = response.data.map((item) => {
        if (
          item.RESULT.total_sales === null ||
          item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU < 0
        ) {
          item.RESULT.organic_sales = null;
        } else {
          item.RESULT.organic_sales =
            item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU;
        }
        return item;
      });
      console.log(transformer.mergeResultDimension(response.data, true));
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  setInstacartAdGroupsSelectedFilters: (context, data) => {
    context.commit('IWKMSKUs_SET_SELECTED_FILTER', data);
  },
  getInstacartAdGroupsTblData: (context, data) => {
    context.commit('UPDATE_SUBGROUP_LOADER', true);
    context.commit('IWKMSKUs_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    const defaultApiConfig = cloneDeep(data.body.APIConfig);
    const newAPIConfig = addDefaultSubTypeToAPIConfig(data.body.APIConfig);
    HttpLayer.post({
      cube: 'EXECUTE_CUBE_API',
      APIData: newAPIConfig
    })
      .then((response) => {
        if (!response.success) {
          context.commit('IWKMSKUs_ERROR', {
            key: data.meta.key
          });
        } else {
          var _aArray = [];
          _aArray = transformer.mergeResultDimension(response.data, true);
          const dimensionList = _aArray.map((item) => {
            return {
              dimensionName: 'ad_group_id',
              dimensionValue: item.ad_group_id
            };
          });
          callSubTypeRequest(
            defaultApiConfig,
            dimensionList,
            _aArray,
            context,
            data
          );
        }
      })
      .catch(() => {
        context.commit('INSTA_ADGROUPS_SUCCESS', {
          key: data.meta.key,
          rows: [],
          page: data.body.APIConfig.page
        });
      });
  },
  instacartSubGroupTypes: (context, data) => {
    const table = context.state.subGroupRows;
    // sorting in asc order of name
    data.rows = table.filter(
      (row) => row.ad_group_id === data.row.ad_group_id && row.sub_type !== '-'
    );
    data.rows.sort((a, b) => (a.ad_group_name > b.ad_group_name ? 1 : -1));
    context.commit('INSTACART_SUBGROUPS', { ...data });
  },
  getInstacartAdGroupsChartData: (context, data) => {
    context.commit('IWKMSKUs_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.body.APIConfig.pvpenabled =
      data.body.APIConfig.where.pvpDate !== undefined;
    const newAPIConfig = addDefaultSubTypeToAPIConfig(data.body.APIConfig);
    HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: newAPIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKMSKUs_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          if (response.data[0].TIMESERIES) {
            response.data[0].TIMESERIES.sort((a, b) => {
              return new Date(a.report_date) - new Date(b.report_date);
            });
          }
          var xs = {};
          for (var i = 0; i < response.data.length; i++) {
            var aTemp = [];
            aTemp = transformer.getChartDataInFormat(
              data,
              response.data[0].TIMESERIES
            );
          }

          for (i = 0; i < aTemp.length; i++) {
            if (aTemp[i][0] !== 'report_date') {
              xs[aTemp[i][0]] = 'report_date';
            }
          }
          context.commit('INSTA_ADGROUPS_SUCCESS', {
            key: data.meta.key,
            columns: aTemp,
            xs,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            metricsUnits: data.body.getTagUnitData
              ? data.body.getTagUnitData(response.data)
              : {}
          });
        } else {
          context.commit('INSTA_ADGROUPS_SUCCESS', {
            key: data.meta.key,
            columns: [],
            metrics: {}
          });
        }
      }
    });
  },
  getAdGroupsProductAd: (context, data) => {
    context.commit('ACTIVITY_LOG_RESET', {
      key: 'AdGroupsActivityLog'
    });
    context.commit('CHANGE_ADGROUPS_PRIMARYKEY', data.primaryKey);
    HttpService.get('AMS_ACTIONS', {
      append: '?widget=adGroup&primaryKey=' + data.primaryKey
    }).then((response) => {
      if (response.data && !response.data.success) {
        context.commit('ACTIVITY_LOG_ERROR', {
          key: 'AdGroupsActivityLog',
          rows: []
        });
      } else {
        context.commit('ACTIVITY_LOG_SUCCESS', {
          key: 'AdGroupsActivityLog',
          rows: activitytransformer.getActivityData(response.data.response)
        });
      }
    });
  },

  openAmsAdGroupsActivityLog: (context) => {
    context.commit('OPEN_AD_GROUPS_ACTIVITY_LOG');
  },
  closeAdgroupsActivityLog: (context) => {
    context.commit('CLOSE_AMSSKUS_ACTIVITY_LOG');
    context.commit('CHANGE_ADGROUPS_PRIMARYKEY', null);
  }
};

function callSubTypeRequest(
  apiConfig,
  dimensionList,
  outerData,
  context,
  data
) {
  context.commit('UPDATE_SUBGROUP_LOADER', true);
  const newApiConfig = { ...apiConfig };
  newApiConfig.where.dimensionNameValueList = [
    ...dimensionList,
    ...newApiConfig.where.dimensionNameValueList
  ];
  delete newApiConfig.limit;
  delete newApiConfig.page;
  HttpLayer.post({
    cube: 'EXECUTE_CUBE_API',
    APIData: newApiConfig
  })
    .then((response) => {
      const _aArray = transformer.mergeResultDimension(response.data, true);
      const flteredValue = _aArray.filter((item) => item.sub_type !== '-');
      let combinedData = [...outerData, ...flteredValue];
      combinedData = combinedData.map((row, index) => {
        row._key = `key_${index}`;
        return row;
      });
      combinedData = addChildRowCount(combinedData);
      context.commit('SUBGROUP_ROWS', combinedData);
      const mainGroupData = combinedData.filter(
        (item) => item.sub_type === '-'
      );
      for (const item of combinedData) {
        if (item.sub_type !== '-') {
          item.ad_group_name = item.sub_type;
        }
      }
      if (outerData.length > 0) {
        context.commit('INSTA_ADGROUPS_SUCCESS', {
          key: data.meta.key,
          rows: mainGroupData,
          totalRows: outerData[0].auto_cubesdk_count || outerData[0].count,
          page: data.body.APIConfig.page
        });
      } else {
        context.commit('INSTA_ADGROUPS_SUCCESS', {
          key: data.meta.key,
          rows: [],
          page: data.body.APIConfig.page
        });
      }
      return flteredValue;
    })
    .catch(() => {
      context.commit('INSTA_ADGROUPS_SUCCESS', {
        key: data.meta.key,
        rows: [],
        page: data.body.APIConfig.page
      });
    })
    .finally(() => {
      context.commit('UPDATE_SUBGROUP_LOADER', false);
    });
}

const addChildRowCount = (tableData) => {
  const relatedMap = {};
  tableData.map((row, index) => {
    if (row.sub_type !== '-') {
      row['ag-grid-has-expand'] = false;
      if (!relatedMap[row.ad_group_id]) {
        relatedMap[row.ad_group_id] = {
          count: 0
        };
      }
      relatedMap[row.ad_group_id].count++;
    }
  });
  for (const relatedEntity in relatedMap) {
    const index = tableData.findIndex((item) => {
      if (item.ad_group_id === relatedEntity && item.sub_type === '-') {
        return true;
      }
      return false;
    });
    if (index > -1) {
      tableData[index].childCount = relatedMap[relatedEntity].count;
      tableData[index]['ag-grid-has-expand'] =
        relatedMap[relatedEntity].count > 0;
    }
  }
  return tableData;
};

export default {
  state,
  getters,
  mutations,
  actions
};
