<template>
  <section class="width u-color-grey-light u-font-size-5 u-font-weight-normal">
    <div
      v-for="(item, index) in computedOptions"
      :key="index"
      class="u-spacing-ph-m u-spacing-pv-s u-cursor-pointer"
      :class="index === hoverIndex && 'hover-effect'"
      @mouseenter="handleMouseEnter(item, index)"
      @mouseleave="handleMouseLeave"
      @click.stop="handleClick(item)"
    >
      {{ item.label }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      hoverIndex: null,
      prefix: {}
    };
  },
  computed: {
    computedOptions() {
      return this.options.filter((item) => !item.hidden);
    }
  },
  methods: {
    createPayload(data) {
      const infix = { text: data.label };
      const payload = { ...data, prefix: this.prefix, infix };
      return payload;
    },
    handleMouseLeave() {
      this.hoverIndex = null;
    },
    handleMouseEnter(item, index) {
      this.hoverIndex = index;
    },
    handleClick(data) {
      const payload = this.createPayload(data);
      this.$emit('selection', payload);
      const nextStep = { ...data.nextStep };
      nextStep.stepTitle = data.label;
      this.$emit('incStep', nextStep);
    }
  }
};
</script>

<style lang="css" scoped>
.width {
  width: 200px;
}
.hover-effect {
  background: #007cf6 !important;
  color: #fff !important;
}
</style>
