<template>
  <div class="donut-details-container">
    <div class="detail-item">
      <div class="left-label">
        <rb-icon
          v-if="listItem.label === 'Needs your Attention'"
          class="u-spacing-mr-s"
          icon="warning-triangle u-color-orange-base rb-icon--x-medium"
        />
        <rb-icon
          v-else
          class="u-spacing-mr-s"
          icon="amazon u-color-cyan-base rb-icon--x-medium"
        />
        <span class="u-font-size-4">{{ listItem.label }}</span>
      </div>
      <div class="right-label">
        <metric
          :size="'md'"
          class="u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-center u-font-weight-600 u-font-size-4"
          :split="false"
          :config="tagFooterConfig"
          :data="cardMetricData(listItem.keyName)"
          :hide-zero="true"
        />
        <div v-if="isMetricZero()">
          <div
            v-tippy="{
              placement: 'bottom-start',
              arrow: false,
              interactive: true,
              size: 'regular',
              distance: 5,
              maxWidth: '300px'
            }"
            :title="`There are no cases ${
              listItem.label === 'Needs your Attention'
                ? 'that needs your attention'
                : 'that have pending amazon action'
            }`"
            :class="{ 'cursor-not-allowed': isMetricZero(listItem.keyName) }"
          >
            <span
              class="view-details u-display-flex u-flex-align-items-center u-spacing-ml-m u-cursor-pointer"
              :class="{ disabled: isMetricZero(listItem.keyName) }"
              @click="handleItemClick(listItem.keyName)"
            >
              <rb-icon
                class="icon-view-details u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-xs"
                :icon="'table-chart'"
              />
              <span
                class="view-details-text u-font-weight-600 u-font-size-7 u-color-grey-lighter"
                >View Details</span
              >
            </span>
          </div>
        </div>
        <div v-else>
          <span
            class="view-details u-display-flex u-flex-align-items-center u-spacing-ml-m u-cursor-pointer"
            :class="{ disabled: isMetricZero(listItem.keyName) }"
            @click="handleItemClick(listItem.keyName)"
          >
            <rb-icon
              class="icon-view-details u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-xs"
              :icon="'table-chart'"
            />
            <span
              class="view-details-text u-font-weight-600 u-font-size-7 u-color-grey-lighter"
              >View Details</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';

export default {
  components: {
    metric
  },
  props: {
    listItem: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tagFooterConfig: {
      type: Object,
      default: function () {
        return {
          tag1Unit: {
            pre: '',
            roundoff: '',
            suff: ''
          },
          tag2Unit: {
            suff: '%'
          },
          invertTag2: true
        };
      }
    },
    widgetRequestParams: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    cardMetricData(selCard) {
      this.finalObj = {};
      this.finalObj = this.getMetricData(selCard);
      return this.finalObj;
    },
    getMetricData(selCard, seprator = '') {
      let tempObj = {};
      this.metricData.forEach((item) => {
        if (
          item.name === selCard &&
          (!seprator.length || item.name.includes(seprator))
        ) {
          tempObj = {
            tag1: item.RESULT[selCard]
          };
        }
      });
      return tempObj;
    },
    isMetricZero() {
      return this.finalObj.tag1 === 0;
    },
    handleItemClick(keyName) {
      this.$emit('handleViewDetails', keyName);
    }
  }
};
</script>
