<template>
  <div>
    <div
      v-if="show"
      class="messageBar u-display-flex u-spacing-ph-l u-color-grey-white u-spacing-pv-m u-font-size-5"
      :style="{ width: messageBarWidth + 'px', backgroundColor: bgColor }"
      :class="[
        { 'u-position-fixed': positionFixed },
        `u-flex-justify-content-${showCloseButton ? 'space-between' : 'center'}`
      ]"
    >
      <slot name="message-content">
        <div class="u-display-flex">
          <rb-icon
            class="rb-icon--small"
            icon="info-circle-fill"
          />
          <span class="u-spacing-ml-s">
            Updates on your actions are here. Please click on
            <rb-icon
              class="rb-icon--small"
              icon="timeline"
            />
            icon to view the activity log of the respective {{ pageType }}.
          </span>
        </div>
      </slot>
      <span
        v-if="showCloseButton"
        class="u-cursor-pointer"
        @click="hideBar()"
      >
        <rb-icon
          class="rb-icon--small"
          icon="cross"
        />
      </span>
    </div>
  </div>
</template>

<style lang="css" scoped>
.messageBar {
  width: calc(100% - 216px);
  z-index: 1000;
}
</style>

<script>
import utils from '@/utils/helpers/';

export default {
  name: 'MessageBar',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: '--'
    },
    positionFixed: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: '#23b5d3'
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      messageBarWidth: 10
    };
  },
  mounted() {
    this.handleResizeEvent();
    const transitionEndEventName = utils.getTransitionEndEventName();
    window.addEventListener(transitionEndEventName, this.handleResizeEvent);
  },
  beforeDestroy() {
    const transitionEndEventName = utils.getTransitionEndEventName();
    window.removeEventListener(transitionEndEventName, this.handleResizeEvent);
  },
  created() {},
  methods: {
    hideBar() {
      this.$emit('hideAction');
    },
    handleResizeEvent() {
      this.messageBarWidth =
        window.innerWidth - document.querySelector('.u-sidebar')?.offsetWidth;
    }
  }
};
</script>
