import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';

const POComponent = Vue.extend({
  computed: {
    data() {
      return {
        link: this.params.data.po_hyperlink,
        name: this.params.data.po_name
      };
    }
  },
  template: `<div class="u-display-flex u-flex-justify-flex-start">
    <a target="_blank" :href="data.link">
      <span class="link--asin u-text-overflow-ellipsis u-text-case-title  u-color-grey-lighter u-font-size-5"> {{ data.name }}</span>
    </a>
  </div>`
});

const skuLevelSummaryColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    showToolTip: true,
    toolTipText: 'Title and ASIN of the SKU',
    hoverText: 'More Details',
    type: 'asin',
    keyOrder: 2,
    pinned: '',
    keys: {
      imageUrl: 'image_url',
      productUrl: 'product_url',
      title: 'title',
      asin: 'asin'
    }
  },
  {
    title: 'No. of POs',
    key: 'total_no_of_po',
    showToolTip: true,
    toolTipText: 'No. of POs in selected date range',
    type: 'number',
    formatReq: true,
    keyOrder: 3
  },
  {
    title: 'Units Ordered',
    key: 'ordered_units',
    showToolTip: true,
    toolTipText: 'Total units ordered for this SKU',
    type: 'number',
    formatReq: true,
    keyOrder: 4
  },
  {
    title: 'Cases Ordered',
    key: 'ordered_cases',
    showToolTip: true,
    toolTipText: 'No. of cases based on case size data from purchase orders',
    type: 'number',
    formatReq: true,
    keyOrder: 5
  },
  {
    title: 'Units Fulfilled',
    key: 'fulfilled_units',
    showToolTip: true,
    toolTipText: 'Count of fulfilled units',
    type: 'number',
    formatReq: true,
    keyOrder: 6
  },
  {
    title: 'Cases Fulfilled',
    key: 'fulfilled_cases',
    showToolTip: true,
    toolTipText: 'Count of fulfilled cases',
    type: 'number',
    formatReq: true,
    keyOrder: 7
  },
  {
    title: 'Unfulfilled revenue',
    key: 'unfulfilled_revenue',
    showToolTip: true,
    toolTipText: 'Sum of COGS of unfulfilled units for the SKU.',
    type: 'numeric',
    unit: 'CURRENCY',
    keyOrder: 8
  }
];

const poDetailsColumns = [
  {
    title: 'Purchase Order',
    key: 'po_name',
    showToolTip: true,
    toolTipText: 'Purchase Order no.',
    type: 'POComponent',
    keyOrder: 1
  },
  {
    title: 'Ordered On',
    key: 'ordered_on',
    showToolTip: true,
    toolTipText: 'Ordered On date as per the PO report on Vendor Central',
    type: 'date',
    keyOrder: 2
  },
  {
    title: 'FC Code',
    key: 'ship_to_location',
    showToolTip: true,
    toolTipText: 'Fulfillment Center Code',
    type: 'String',
    keyOrder: 3
  },
  {
    title: 'Ordered Units',
    key: 'ordered_units_value',
    showToolTip: true,
    toolTipText: 'Units ordered for the SKU',
    type: 'number',
    formatReq: true,
    keyOrder: 4
  },
  {
    title: 'Ordered Cases',
    key: 'ordered_case_value',
    showToolTip: true,
    toolTipText: 'Cases ordered for the SKU',
    type: 'number',
    formatReq: true,
    keyOrder: 5
  },
  {
    title: 'Fulfilled Units',
    key: 'fulfilled_units_value',
    showToolTip: true,
    toolTipText: 'Units confirmed for the SKU',
    type: 'number',
    formatReq: true,
    keyOrder: 6
  },
  {
    title: 'Fulfilled Cases',
    key: 'fulfilled_cases_value',
    showToolTip: true,
    toolTipText: 'Cases confirmed for the SKU',
    type: 'number',
    formatReq: true,
    keyOrder: 7
  },
  {
    title: 'Unfulfilled revenue',
    key: 'unfulfilled_revenue_value',
    showToolTip: true,
    toolTipText: 'Unfulfilled Revenue for the SKU',
    type: 'numeric',
    unit: 'CURRENCY',
    keyOrder: 8
  }
];

var columnDefs = [];
function getColumnDefinition(vueRef, columns) {
  var _columnDefs = [];
  for (const i in columns) {
    const obj = {};
    obj.field = i;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (columns[i].type === 'numeric') {
      keyType = columns[i].unit ? columns[i].unit : 'numeric';
    }
    if (columns[i].type === 'number') {
      keyType = 'number';
    }
    if (columns[i].type === 'date') {
      keyType = columns[i].type;
    }
    obj.field = columns[i].key;
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: columns[i].formatReq === false ? '' : formatter
    };
    obj.cellRendererFramework = 'genericTableCell';
    obj.headerName = columns[i].title;
    obj.title = columns[i].title;
    obj.headerComponentParams = {
      enableSorting: true
    };

    if (columns[i].type === 'asin') {
      obj.cellRendererFramework = 'skuTableComponentWithButton';
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = columns[i];
      obj.cellRendererParams.hoverTextClickEvent = vueRef.showPODetails;
      obj.minWidth = 300;
    }
    if (columns[i].type === 'POComponent') {
      obj.cellRendererFramework = POComponent;
      // obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = columns[i];
      obj.minWidth = 200;
    }
    obj.keyOrder = columns[i].keyOrder;
    columns[i].pinned && (obj.pinned = columns[i].pinned);
    if (columns[i].showToolTip) {
      obj.headerComponentParams.toolTipText = columns[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      filters: {
        // search: {
        //   enable: false,
        //   emit: 'pofillrateSearchTriggered',
        //   placeholder: 'Search for a ASIN'
        // },
        emit: 'pofillrateFilterApplied',
        listen: {
          pofillrateFilterApplied: {
            action: 'updatePOFillRateSelectedFilter'
          }
        },
        left: [
          {
            title: 'Product Group',
            filterValueKey: 'po_product_group',
            smart: true
          },
          {
            title: 'Category',
            subFilterValueKey: 'po_subcategory',
            filterValueKey: 'po_category',
            twoLevel: true,
            smart: true
          },
          {
            title: 'Brand',
            filterValueKey: 'po_brand',
            smart: true
          }
        ],
        right: [
          {
            title: 'Date Range',
            filterValueKey: 'date_range'
          }
        ]
      },
      widgets: {
        widget1: {
          meta: {
            type: 'chart',
            hideOnNoData: false,
            width: 1,
            action: 'getPOFillRateData',
            key: 'POFillRate',
            localFilters: [],
            listen: {
              pofillrateFilterApplied: {
                action: 'getPOFillRateData'
              },
              pofillrateSearchTriggered: {
                action: 'getPOFillRateData',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    if (!config.meta.localFilters) {
                      config.meta.localFilters = [];
                    }
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    } else {
                      config.meta.localFilters = [];
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'PO FILL RATE',
            tooltip:
              'The ratio of units confirmed to the units ordered across all POs in the selected date range.'
          },
          footer: {
            date: 'po_fill_rate'
          },
          body: {
            APIConfig: {
              cubeName: 'po_fill_rate_v2',
              measuresList: ['po_fill_rate'],
              groupByDimensionsList: [],
              timeseriesDimension: 'ordered_on',
              getLatestAvailableInsteadOfRollup: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin', 'po', 'ordered_on']
              },
              timeseriesEnabled: false,
              pvpenabled: true,
              yoyenabled: false
            },
            getTagData(data, mapping) {
              return {
                tag1: data[0].RESULT.po_fill_rate || 0,
                tag2: (data[0].PVP || {}).PVP_po_fill_rate
              };
            },
            tagConfig: {
              tag1Unit: {
                suff: '%'
              },
              tag2Unit: {
                suff: '%'
              }
            },
            chart: {
              chartOptions: {
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  SRI: {}
                }
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'chart',
            hideOnNoData: false,
            width: 1,
            action: 'getUnfulfilledRevenueData',
            key: 'unfulfilledRevenue',
            localFilters: [],
            listen: {
              pofillrateFilterApplied: {
                action: 'getUnfulfilledRevenueData'
              },
              pofillrateSearchTriggered: {
                action: 'getUnfulfilledRevenueData',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    if (!config.meta.localFilters) {
                      config.meta.localFilters = [];
                    }
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    } else {
                      config.meta.localFilters = [];
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'UNFULFILLED REVENUE',
            tooltip:
              'Sum of COGS of all the units which were unfulfilled for the categories and brands selected in the date range.'
          },
          footer: {
            date: 'po_fill_rate'
          },
          body: {
            APIConfig: {
              cubeName: 'po_fill_rate_v2',
              measuresList: ['unfulfilled_revenue'],
              groupByDimensionsList: [],
              timeseriesDimension: 'ordered_on',
              getLatestAvailableInsteadOfRollup: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin', 'po', 'ordered_on']
              },
              timeseriesEnabled: false,
              pvpenabled: true,
              yoyenabled: false
            },
            getTagData(data, mapping) {
              return {
                tag1: data[0].RESULT.unfulfilled_revenue || 0,
                tag2: (data[0].PVP || {}).PVP_unfulfilled_revenue
              };
            },
            tagConfig: {
              invertTag2: true,
              tag1Unit: {
                pre: 'currency'
              },
              tag2Unit: {
                suff: '%'
              }
            },
            chart: {
              chartOptions: {
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  SRI: {}
                }
              }
            }
          }
        },
        widget3: {
          meta: {
            type: 'chart',
            hideOnNoData: false,
            width: 1,
            action: 'getSKUsData',
            localFilters: [],
            key: 'orderedVsFulfilledSKUsData',
            listen: {
              pofillrateFilterApplied: {
                action: 'getSKUsData'
              },
              pofillrateSearchTriggered: {
                action: 'getSKUsData',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    if (!config.meta.localFilters) {
                      config.meta.localFilters = [];
                    }
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    } else {
                      config.meta.localFilters = [];
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'SKUs ORDERED VS SKUs FULFILLED',
            tooltip: 'Sum of units across all SKUs ordered vs units fulfilled.',
            tooltip_direction: 'top'
          },
          footer: {
            date: 'po_fill_rate'
          },
          body: {
            APIConfig: {
              cubeName: 'po_fill_rate_v2',
              measuresList: ['ordered_units', 'fulfilled_units'],
              groupByDimensionsList: [],
              timeseriesDimension: 'ordered_on',
              getLatestAvailableInsteadOfRollup: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin', 'po', 'ordered_on']
              },
              timeseriesEnabled: false,
              pvpenabled: true,
              yoyenabled: false
            },
            getTagData(data, mapping) {
              return {
                tag1: data[0].RESULT.unfulfilled_revenue || 0,
                tag2: (data[0].PVP || {}).PVP_unfulfilled_revenue
              };
            },
            tagConfig: {
              tag1Unit: {
                pre: 'currency'
              },
              tag2Unit: {
                suff: '%'
              }
            },
            chart: {
              chartOptions: {
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  SRI: {}
                }
              }
            }
          }
        },
        widget4: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getSKULevelSummaryData',
            localFilters: [],
            key: 'SKULevelSummaryData',
            listen: {
              pofillrateFilterApplied: {
                action: 'getSKULevelSummaryData',
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              },
              pofillrateSearchTriggered: {
                action: 'getSKULevelSummaryData',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    if (!config.meta.localFilters) {
                      config.meta.localFilters = [];
                    }
                    if (data.length > 0) {
                      config.body.APIConfig.page = 1;
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    } else {
                      config.meta.localFilters = [];
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            search: true,
            searchPlaceholder: 'Search for a SKU',
            searchParams: ['search'],
            show: true,
            title: 'SKU LEVEL SUMMARY',
            tooltip:
              'Order and fulfillment history of each SKU within the selected date range sorted by unfulfilled revenue by default.',
            actions: {
              download: {
                fileName: 'PO Fill Rate - Sku level Summary',
                colMap: [
                  {
                    displayName: 'Asin',
                    dbKey: 'asin'
                  },
                  {
                    displayName: 'Title',
                    dbKey: 'title'
                  },
                  // {
                  //   displayName: 'SKU Image',
                  //   dbKey: 'image_url'
                  // },
                  {
                    displayName: 'Product URL',
                    dbKey: 'product_url'
                  },
                  {
                    displayName: 'Total Number of PO',
                    dbKey: 'total_no_of_po'
                  },
                  {
                    displayName: 'Ordered Units',
                    dbKey: 'ordered_units'
                  },
                  {
                    displayName: 'Ordered Cases',
                    dbKey: 'ordered_cases'
                  },
                  {
                    displayName: 'Fulfilled Units',
                    dbKey: 'fulfilled_units'
                  },
                  {
                    displayName: 'Fulfilled Cases',
                    dbKey: 'fulfilled_cases'
                  },
                  {
                    displayName: 'Unfulfilled Revenue',
                    dbKey: 'unfulfilled_revenue'
                  }
                ],
                action: 'downloadSKULevelSummaryData',
                transform: function (config, data, that) {
                  var _config = Vue.util.extend({}, config);
                  _config = JSON.parse(JSON.stringify(_config));
                  _config.body.APIConfig.page = undefined;
                  _config.body.APIConfig.limit = undefined;
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'po_fill_rate'
          },
          body: {
            tooltipTexts: [
              '',
              'SKU Details: Title and ASIN of the SKU',
              'No. of POs: No. of POs in selected date range',
              'Units Ordered: Total units ordered for this SKU',
              'Cases Ordered: No. of cases based on case size data from purchase orders',
              'Units Fulfilled: Count of fulfilled units',
              'Cases Fulfilled: Count of fulfilled cases',
              'Unfulfilled Revenue: Sum of COGS of unfulfilled units for the SKU.'
            ],
            defaultOrderBy: [
              {
                dimension: 'asin',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              limit: 5,
              page: 1,
              cubeName: 'po_fill_rate_v2',
              measuresList: [
                'ordered_units',
                'fulfilled_units',
                'total_no_of_po',
                'unfulfilled_revenue',
                'title',
                'count_total',
                'image_url',
                'product_url',
                'ordered_cases',
                'fulfilled_cases'
              ],
              groupByDimensionsList: ['asin'],
              timeseriesDimension: 'ordered_on',
              getLatestAvailableInsteadOfRollup: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin', 'po', 'ordered_on']
              },
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              orderByList: [
                {
                  dimension: 'unfulfilled_revenue',
                  direction: 'DESC'
                },
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ]
            },
            columnDefs: getColumnDefinition(that, skuLevelSummaryColumns)
          }
        },
        widget5: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getAsins',
            localFilters: [],
            alternateAction: 'getPODetailsTableData',
            key: 'poDetailsTableData',
            listen: {
              pofillrateFilterApplied: {
                action: 'getAsins',
                transform: function (config, data, that) {
                  var _config = config;
                  delete _config.meta.from;
                  _config.body.APIConfig.page = 1;
                  for (var i = 0; i < _config.meta.localFilters.length; i++) {
                    if (
                      _config.meta.localFilters[i].dimensionName === 'search'
                    ) {
                      _config.meta.localFilters.splice(i, 1);
                    }
                  }
                  return _config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'PO DETAILS',
            tooltip:
              'Details of all POs ordered for the selected SKU. PO history in selected date range can be seen for any SKU.',
            actions: {
              download: {
                fileName: 'PO Fill Rate - PO details',
                colMap: [
                  {
                    displayName: 'PO',
                    dbKey: 'po_name'
                  },
                  {
                    displayName: 'Ordered On',
                    dbKey: 'ordered_on'
                  },
                  {
                    displayName: 'FC Code',
                    dbKey: 'ship_to_location'
                  },
                  {
                    displayName: 'Ordered Units',
                    dbKey: 'ordered_units_value'
                  },
                  {
                    displayName: 'Ordered Cases',
                    dbKey: 'ordered_case_value'
                  },
                  {
                    displayName: 'Fulfilled Units',
                    dbKey: 'fulfilled_units_value'
                  },
                  {
                    displayName: 'Fulfilled Cases',
                    dbKey: 'fulfilled_cases_value'
                  },
                  {
                    displayName: 'Unfulfilled Revenue',
                    dbKey: 'unfulfilled_revenue_value'
                  },
                  {
                    displayName: 'Purchase Order on vendor central',
                    dbKey: 'po_hyperlink'
                  }
                ],
                action: 'downloadPODetailsTableData',
                transform: function (config, data, that) {
                  var _config = Vue.util.extend({}, config);
                  _config = JSON.parse(JSON.stringify(_config));
                  _config.body.APIConfig.page = undefined;
                  _config.body.APIConfig.limit = undefined;
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'po_fill_rate'
          },
          body: {
            tooltipTexts: [
              'Purchase Order: Purchase Order no.',
              'Ordered On: Date of PO',
              'FC Code: Fulfillment Center Code',
              'Ordered Units: Units ordered for the SKU',
              'Ordered Cases: Cases ordered for the SKU',
              'Fulfilled Units: Units confirmed for the SKU',
              'Fulfilled Cases: Cases confirmed for the SKU',
              'Unfulfilled Revenue: Unfulfilled Revenue for the SKU'
            ],
            defaultOrderBy: [
              {
                dimension: 'po_name',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              limit: 10,
              page: 1,
              cubeName: 'po_fill_rate_v2',
              measuresList: [
                'ordered_units_value',
                'fulfilled_units_value',
                'unfulfilled_revenue_value',
                'title',
                'po_name',
                'ship_to_location',
                'count_total',
                'po_hyperlink',
                'ordered_on',
                'ordered_case_value',
                'fulfilled_cases_value'
              ],
              groupByDimensionsList: ['po'],
              timeseriesDimension: 'ordered_on',
              getLatestAvailableInsteadOfRollup: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin', 'po', 'ordered_on']
              },
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              orderByList: [
                {
                  dimension: 'unfulfilled_revenue_value',
                  direction: 'DESC'
                },
                {
                  dimension: 'po_name',
                  direction: 'DESC'
                }
              ]
            },
            columnDefs: getColumnDefinition(that, poDetailsColumns)
          }
        }
      }
    };
  }
};
