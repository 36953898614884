<template>
  <div
    class="u-display-flex campaign-management-styling u-flex-direction-column u-font-size-1 ams-keywords"
  >
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div v-if="openActivity">
      <activity-log
        v-show="getShowKeywordsActivityLog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'keywords'"
        :close-activity-log="closeActivityLog"
      />
    </div>
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div
      class="u-display-flex u-bg-color-grey-white u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow filter-panel-class"
    >
      <rb-filter-panel
        id="wk-keywords-filters"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'keywords-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :ingestion-tooltip-text="ingestionToolTipText"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :has-search="true"
        :new-date="true"
        :show-data-missing-msg="showDataMissingMsg"
        :data-missing-date-range="'August 11th, 2023, to October 9th, 2023,'"
        :allow-date-compare="true"
        :is-real-time-toggle-present="true"
        :real-time-toggle-value="realTimeToggleValue"
        :default-date-post-range="'last30Days'"
        :max-date="getKeywordsPageWiseMinMaxKey"
        :on-create="onCreate"
        :enable-save="true"
        :page="'keywords'"
        :common-date-key="'common-date-cm'"
        :loader-status="loaderStatus"
        :filter-v2="filterV2"
        :additional-ranges="additionalRanges"
        :show-ingestion-progress="filterV2"
        @onRealTimeToggleClick="onRealTimeToggleClick"
        @savedFilterApplied="filterKey++"
        @clearBulkBanner="isBulkSelectedCleared"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Keywords'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            :bulk-search-ref="selectedBulkSearchType"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          >
            <rbRadioGroup
              slot="custom-bulk-type"
              class="keywords-radio-group u-spacing-mb-m u-spacing-mt-s"
              :radio-options="bulkSearchOptions"
              :value="selectedBulkSearchType"
              group-label="Search for:"
              @onRadioChanged="handleRadioSelection"
            />
          </BulkSearch>
        </span>
        <div
          v-if="!disableStreamChart"
          slot="before-date-picker"
        >
          <div
            class="realTimeToggleWrapper u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-mr-s"
            :class="{ realTimeActiveBorder: realTimeToggleValue }"
            @click="onStreamToggleBtnClick"
          >
            <rb-switch
              :id="pvpId"
              :key="realTimeToggleValue"
              :true-value="true"
              :false-value="false"
              :value="realTimeToggleValue"
              :native-value="realTimeToggleValue"
              :disabled="false"
              class="u-width-auto"
              size="small"
            />
            <div
              v-if="realTimeToggleValue"
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                icon="dot"
                class="rb-icon--medium realtime_active_icon"
              />
              <span class="streamBtnFontActive streamBtnFont">
                Real-Time Data</span
              >
            </div>
            <div
              v-else
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                icon="dot"
                class="rb-icon rb-icon--small icon-task-status u-color-green-base"
              />
              <span class="streamBtnFont"> Real-Time Data</span>
            </div>
          </div>
        </div>
      </rb-filter-panel>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      :dates="filterInstance.selectedDates"
    />

    <card-widget
      v-show="showBidChangePanel === false"
      class="u-flex-1 u-spacing-mh-m u-spacing-mv-m"
      :config="config.widgets['widget1']"
      :data="streamMode ? getStreamChartData : getChartData"
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    class="metric-card-hover"
                    data-cy="metricCardHover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        data-cy="metricList"
                        :class-name="'campaigns-select'"
                        :search-enabled="true"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span class="u-font-size-5">{{ val }}</span>
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getWorkbenchKeywordsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="This metric is enabled only when 1 row is plotted"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <metric
                        v-if="val && (getChartData.metrics || {})[val]"
                        :size="'l'"
                        class="u-display-inline-flex u-spacing-mt-s"
                        :config="(getChartData.metricsUnits || {})[val]"
                        :data="(getChartData.metrics || {})[val]"
                      />
                      <streamMetricLabel
                        v-if="!disableStreamChart && realTimeToggleValue"
                        :metric-label="getStreamChartMetricValue(val)"
                        :stream-metric-tooltip="
                          config.widgets.widget1.meta.streamMetricTooltip
                        "
                      />
                      <span
                        v-if="!val && !(getChartData.metrics || {})[val]"
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <div class="u-display-flex">
              <section
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <span
                  v-if="numberOfPlottedSelections !== 0"
                  class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                  :class="{ isHighlighted: isHighlighted }"
                  style="min-height: 20px"
                >
                  <span class="u-spacing-pr-s u-line-height-1_2"
                    >Graphs plotted for
                    {{ numberOfPlottedSelections }} rows</span
                  >
                  <span @click="plotRows('delete')">
                    <rb-icon
                      :icon="'cross'"
                      class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                      :class="{
                        'isHighlighted u-color-grey-white': isHighlighted
                      }"
                    />
                  </span>
                </span>
              </section>
              <span>
                <icon-text-actions
                  v-if="isExpand"
                  class="u-cursor-pointer padding--right u-width-100"
                  mode="default"
                  :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                  @clicked="isExpand = !isExpand"
                />
                <icon-text-actions
                  v-if="!isExpand"
                  class="u-cursor-pointer u-width-100 padding--right"
                  mode="default"
                  :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                  @clicked="isExpand = !isExpand"
                />
              </span>
            </div>
          </div>
          <div
            :class="streamMode ? 'chart-wrapper-realtime' : ''"
            class="u-border-radius-m"
          >
            <StreamChartActions
              entity="keywords"
              :roll-ups="computedRollUps"
              :real-time-toggle-value="realTimeToggleValue"
              :selected-roll-up="selectedRollUpValue"
              @onYesterdayModeChange="
                (val) => onYesterdayModeChange(val, pageTitle)
              "
              @onStreamModeChange="(val) => onStreamModeChange(val, pageTitle)"
              @onRollUpChange="
                (selection) => getKeywordsChartDataWithRollUp(selection)
              "
            />
            <chart
              id="workbenchKeywordsChart"
              :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
              class="c3-large u-flex-1 u-spacing-mt-l"
              :config="chartConfig"
              :data="widget1Data"
            />
          </div>
        </div>
      </div>
    </card-widget>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        :custom-selection-length="getCustomSelectionLength"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div class="u-position-relative">
      <bidChangePanel
        v-if="showBidChangePanel"
        primary-key="keyword_id"
        widget="keyword"
        api-version="metadataChange"
        :selected-values="selections"
        :title="bidChangePanelTitle"
        :parent-ref="parentRef"
        :config="config"
        :transform-payload="transformForRealtimePayload"
        :custom-selection-length="getBidChangePanelSelectionLength"
        @closePanelMethod="
          () => {
            bidChangeAfterAction(true);
          }
        "
        @afterAPIActionToCall="bidChangeAfterAction"
      />
      <div
        v-if="showaddTagsPanel"
        class="u-display-flex u-flex-direction-column u-width-100"
      >
        <addTagsPanel
          class="keywords-tags-input"
          :title="'ADD TAGS'"
          tags-suggestions-container-classes="keywords-tags-input"
          :sub-text="selectionCountText"
          :custom-selection-length="computedSelectionCount"
          :selected-values="selections"
          :parent-ref="parentRef"
          :save-api-status="tagsLoader"
          :get-add-tag-panel-instance="getAddTagPanelInstance"
          :suggestions-data="getAllTags"
          :tags-icons="[{ icon: 'cross', emit: 'removeActiveTag' }]"
          @closePanelMethod="
            () => {
              closeActionsWorkflow(false);
            }
          "
          @saveKeywords="handleAddTagsForKeywords"
          @textInput="handleTextInputTags"
        />
        <div
          class="u-font-size-6 u-bg-color-white-base u-spacing-p-m u-color-green-lighter u-width-100 u-text-align-center"
        >
          <div v-if="!isBulkSelection">
            {{
              selections.length > 1
                ? `${selections.length} keywords`
                : '1 keyword'
            }}
            selected.
            <span
              class="u-color-blue-base u-cursor-pointer"
              @click="onBulkClick(true)"
              >Select all {{ totalRows }} keywords.</span
            >
          </div>
          <div v-else>
            {{ totalRows }} keywords selected.
            <span
              class="u-color-blue-base u-cursor-pointer"
              @click="onBulkClick(false)"
              >Deselect all keywords.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!showActionsBar && !showBidChangePanel"
      class="u-display-flex u-spacing-p-m u-bg-color-grey-white u-flex-justify-content-space-between"
    >
      <div
        class="u-display-flex u-width-100 u-flex-direction-row-reverse actionspace-dummy u-position-sticky top-0 z-index-2"
      >
        <div class="u-display-flex u-flex-align-items-center">
          <splitTabs
            v-if="modeToggleEnable"
            :tab1="entityModeNames && entityModeNames.realTimeData"
            :tab2="entityModeNames && entityModeNames.comparePvP"
            class="split-tabs-container"
            @tabClicked="onModeSelect"
          >
            <span slot="tag1-pre">
              <rb-icon
                icon="dot"
                class="rb-icon--medium realtime_active_icon u-spacing-mr-s"
              />
            </span>
          </splitTabs>
          <div class="u-display-flex u-flex-align-items-baseline">
            <icon-text-actions
              mode="default"
              :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
              @clicked="openManageColumns"
            />
            <rb-download-button
              :download-email="true"
              :config="config.widgets['widget2']"
              :column-map="config.widgets['widget2'].body.download.columnMap"
              :on-email-click="openEmailPopup"
              :class-name="'enlargeEmailIcon'"
              :disabled="resultLength < 1"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showBulkBanner"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
      :class="{ 'change-bg-color': isSelectAllClicked }"
    >
      <selectDeselectAll
        page-key="Keywords"
        :is-bulk-select-clicked="isBulkSelectClicked"
        :total-entities="
          (getWorkbenchKeywordsData[config.widgets['widget2'].meta.key] || {})
            .totalRows
        "
        :current-selected-entities="selections.length"
        :page-limit="config.widgets.widget2.body.APIConfig.limit"
        @bulkSelectClicked="isBulkSelectedClicked"
        @isBulkSelectedCleared="isBulkSelectedCleared"
      >
      </selectDeselectAll>
    </div>

    <card-widget
      :apply-card-class="false"
      class="u-flex-1 u-spacing-mb-m u-bg-color-grey-white"
      :config="config.widgets['widget2']"
      :data="getWorkbenchKeywordsData[config.widgets['widget2'].meta.key] || {}"
    >
      <div slot="body">
        <div
          :style="{ 'pointer-events': isBulkSelection ? 'none' : 'auto' }"
          class="u-display-flex u-flex-wrap-yes"
        >
          <rb-insights-table
            :config="config.widgets['widget2']"
            :dynamic-column-width="dynamicColumnWidth"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getWorkbenchKeywordsData[config.widgets['widget2'].meta.key] ||
                {}
              ).rows
            "
            :table-column="tableColumns"
            :real-time-toggle-value="realTimeToggleValue"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :scroll-to-node-id="scrollToNodeId"
            :primary-key="'keyword_id'"
            :pagination="true"
            :pagination-total-key="totalRows"
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :table-header-sticky-obj="sticky"
            :show-pagination-options="true"
          />
        </div>
      </div>
      <realtimeFooter
        v-if="getRealtimeMetadata.realtime"
        slot="footer"
        :last-updated="getRealtimeMetadata.lastUpdated"
        @onRefresh="triggerRefresh(false)"
      />
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="amsKeywords-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import realtimeFooter from '@/components/ams/campaigns/components/realtimeFooter.vue';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import addTagsPanel from '@/components/ams/actionPanels/addTagsPanel.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import transformer from '@/utils/services/data-transformer';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import rbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import utilConfig, {
  enableBulkOnlyActions
} from '@/components/ams/campaigns/utils.js';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import {
  metricsShownForAmazonChart,
  entityModeNames,
  defaultKeywordsCols
} from '@/components/ams/campaigns/constants.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';
import StreamChartActions from '@/components/ams/campaigns/components/streamChartActions.vue';
import streamMetricLabel from '@/components/ams/campaigns/components/streamMetricLabel.vue';
import { commonStreamChartMethods } from '@/components/ams/campaigns/streamChartUtils.js';
import { cloneDeep } from 'lodash';
import selectDeselectAll from '@/components/ams/campaigns/SelectAll/selectDeselectAll.vue';
import splitTabs from '@/components/widgets/splitTabs';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {
    splitTabs,
    StreamChartActions,
    streamMetricLabel,
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    bidChangePanel,
    messageBar,
    activityLog,
    modal,
    addTagsPanel,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    rbRadioGroup,
    amsAddMetricTile,
    SystemTransparencyBanner,
    realtimeFooter,
    RollUpBy,
    selectDeselectAll
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      pvpId: 'realTimeToggle',
      realTimeToggleValue: false,
      entityModeNames: {},
      isBulkSelectClicked: false,
      streamData: {},
      dynamicColumnWidth: {
        enable: false
      },
      pageTitle: 'Keywords',
      filterInstance: null,
      chartConfig: {},
      additionalRanges: commons.additionalDateRanges(),
      ingestionDependentEntities: ['campaign'],
      modeToggleEnable:
        Vue.options.filters.config_check('feature.pages.enableRMMAmazonPVP') ||
        false,
      ingestionToolTipText:
        'Custom list updation or creation is in progress. It will take upto 45 mins for the changes to reflect.',
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      tagsLoader: { load: false },
      isBulkSelection: false,
      unavailableMessageSD:
        'This action is currently unavailable for SD campaigns',
      showEmailPopUp: false,
      isSDCampaign: false,
      modalConfig: {},
      parentRef: {},
      resultLength: 0,
      bidChangePanelTitle: 'Adjust Bid',
      showBidChangePanel: false,
      bidLoader: false,
      allowedActions: [],
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'adjust-bids': this.adjustBidsPanel,
        'edit-status': this.updateStatus,
        'add-tag': this.tagKeywords
      },
      showActionsBar: false,
      selections: [],
      filterKey: 0,
      chartWkbenchAxes: {},
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getWorkbenchKeywordsSelectedFilters',
        setter: 'setWorkbenchKeywordsSelectedFilters'
      },
      downloadFileSuffix: 'campaign-management_keywords',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForAmazonChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      showBlueBar: false,
      timer: 30000,
      disableMetric: [
        'Total sales',
        'PCOGS',
        'Inventory',
        'Organic sales',
        'Promo Value',
        'Amazon Frequency Rank'
      ],
      isExpand: false,
      reDrawRows: true,
      rbTableInstance: null,
      showActionsLog: false,
      openActivity: true,
      keywordId: null,
      minBidValue: 1000,
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      isHighlighted: false,
      showPaginationOptions: utils.internalUserCheck(window.user),
      showaddTagsPanel: false,
      selectedBulkSearchType: 'amsKeywords',
      bulkSearchOptions: commons.getBulkSearchOptions('keywords'),
      enableManageColumns: true,
      manageColumnsPanelListener: 'keywordsManageColumnsPanel',
      workBenchDataKey: 'getWorkbenchKeywordsData',
      showBulkBanner: false,
      ...commons.commonDataProperties
    };
  },
  computed: {
    showDataMissingMsg() {
      return Vue.options.filters.config_check(
        'feature.pages.data_missing_banner_needed'
      ).enable;
    },
    isSelectAllClicked() {
      if (this.$store.getters.getIsAllSelectedStateKeywords) {
        return true;
      }
    },
    getCustomSelectionLength() {
      if (this.$store.getters.getIsAllSelectedStateKeywords) {
        const selectionLength = (
          this?.getWorkbenchKeywordsData?.[
            this?.config?.widgets?.widget2?.meta?.key
          ] || {}
        )?.totalRows;
        return parseInt(selectionLength);
      } else {
        return this.selections.length;
      }
    },
    getBidChangePanelSelectionLength() {
      const selectionLength = {
        visibility: true,
        value: this.getCustomSelectionLength
      };
      return selectionLength;
    },
    selectedRollUpValue() {
      return this.selectedRollUp?.key || 'DAY';
    },
    computedRollUps() {
      return this.getComputedRollUpOptions(
        this.$store.getters.getCampaignsKeywordsWhereClause,
        this.rollUps
      );
    },
    getRealtimeMetadata() {
      return this.$store.getters.getKeywordsRealTimeMetadata;
    },
    bulkSearchProps() {
      if (this.selectedBulkSearchType === 'amsKeywordsCampaigns') {
        const { bulkSearchProps } = utilConfig('amazon', 'campaign');
        return bulkSearchProps();
      }
      return this.config.bulkSearchProps();
    },
    keywordFilters() {
      return this.$store.getters.getCampaignsKeywordsWhereClause;
    },
    totalRows() {
      return (
        (
          this.getWorkbenchKeywordsData[this.config.widgets.widget2.meta.key] ||
          {}
        ).totalRows || 0
      );
    },
    selectionCountText() {
      return `${this.computedSelectionCount} keywords(s) selected`;
    },
    computedSelectionCount() {
      if (this.isBulkSelection) {
        return this.totalRows;
      }
      return this.selections.length;
    },
    getAllTags() {
      return this.$store.getters.getAllTags;
    },
    emailStatus() {
      return this.$store.getters.getEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          (
            this.getWorkbenchKeywordsData[
              this.config.widgets.widget1.meta.key
            ] || {}
          ).load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    getShowKeywordsActivityLog() {
      return this.$store.getters.getShowKeywordsActivityLog;
    },
    getWorkbenchKeywordsData() {
      this.showActionsBar = false;
      this.resultLength = (
        (
          this.$store.getters.getWorkbenchKeywordsData[
            this.config.widgets.widget2.meta.key
          ] || {}
        ).rows || []
      ).length;
      return this.$store.getters.getWorkbenchKeywordsData;
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.keyWordsAcitivityLog;
    },
    getChartData() {
      return (
        this.getWorkbenchKeywordsData[
          this.config?.widgets?.widget1?.meta?.key
        ] || {}
      );
    },
    getStreamChartData() {
      return this.$store.getters.getKeywordsStreamChartData;
    },
    streamMode() {
      return this.$store.getters.getKeywordsStreamMode;
    },
    YesterdayMode() {
      return this.$store.getters.getKeywordsYTDMode;
    },
    widget1Data() {
      let data = (
        this.getWorkbenchKeywordsData[
          this.config?.widgets?.widget1?.meta?.key
        ] || {}
      )?.data;
      if (this.streamMode) {
        data = this.getStreamChartData || {};
      }
      if (!this.YesterdayMode) {
        this.streamData = this.getStreamChartData || {};
      }
      let clonedData = (data || {})?.data;
      let pvpData = data?.pvp_data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let ignoreList = [
        'organic_search_count',
        'total_organic_search_count',
        'total_sponsored_search_count',
        'sponsored_search_count',
        'customer_orders',
        'report_date',
        'hour',
        'count',
        'header_search_count',
        'total_header_search_count',
        'incrementality_spend',
        'incrementality_paid_sales_14d'
      ];
      this.metricsList = [];
      for (const element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }

      data = this.createSelectedMetricsList({ data, clonedData });
      data = this.handleStreamDataTransformer(data, pvpData);
      return data;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.keyword_id) {
            return false;
          }
          return (
            params.data.keyword_id === this.$store.getters.getKeywordPrimaryKey
          );
        }.bind(this)

        // 'ag-row-disabled': function (params) {
        //   return params.data.status !== 'enabled'
        // }
      };
    },
    primaryKey() {
      return this.$store.getters.getKeywordPrimaryKey[
        this.config.widgets.widget2.meta.primaryKey
      ];
    },
    getKeywordsPageWiseMinMaxKey() {
      return this.$store.getters.getKeywordsPageWiseMinMaxKey;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(`set${this.pageTitle}PVPRealtime`, false);
    next();
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeCreate() {
    this.config = widgetsConfig.config(this);
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchKeywordsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    let that = this;
    eventBus.$on('metadataUpdate', () => {
      that.triggerRefresh(true);
    });
    eventBus.$on('workbenchKeywordsFilterApplied', () => {
      that.isExpand = true;
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('workbenchKeywordsSearchTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('workbenchKeywordPaginationTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
      that.showBulkBanner = false;
    });
    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('amsKeywordsBulkSearchApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('amsKeywordsCampaignsBulkSearchApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.amsKeywords?.openBulkSearchPopup();
    });
  },
  created() {
    this.parentRef = this;
    this.entityModeNames = entityModeNames;
    this.onModeSelect(entityModeNames?.realTimeData);
    this.realTimeToggleValue = this.$store.getters.getStreamKeywordsToggle;
    this.disableStreamChart = Vue.options.filters.config_check(
      'feature.pages.disableStreamChart'
    );
    if (this.allowedActions.length === 0) {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
    }
    this.chartConfig = cloneDeep(this.config.widgets.widget1.body.chart);
    commons.appendTableDefinitionToConfig.call(this);
    this.config.widgets.widget1.meta.plotSelections = [];
    this.fetchFilters(
      'ams_keyword_campaign_workbench',
      'FETCH_FILTERS_V2',
      'keywords'
    );
  },
  beforeDestroy() {
    this.destoryRealtimeRefreshInterval();
    eventBus.$off('workbenchKeywordsFilterApplied');
    eventBus.$off('workbenchKeywordsSearchTriggered');
    eventBus.$off('workbenchKeywordPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('amsKeywordsBulkSearchApplied');
    eventBus.$off('amsKeywordsCampaignsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
    eventBus.$off('metadataUpdate');
  },
  methods: {
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    async onRealTimeToggleClick(val) {
      this.enableManageColumns = false;
      this.realTimeToggleValue = val;
      this.$store.dispatch('setStreamKeywordsToggle', this.realTimeToggleValue);
      this.onStreamModeChange(this.realTimeToggleValue, 'Keywords');
      await this.$store.dispatch('getWorkbenchKeywordsTableStreamData');
      commons.appendTableDefinitionToConfig.call(this);
      this.enableManageColumns = true;
    },
    onStreamToggleBtnClick() {
      this.onRealTimeToggleClick(!this.realTimeToggleValue);
    },
    onModeSelect(value) {
      this.$store.commit('SET_AMAZON_KEYWORD_MODE', value);
    },
    ...commonMethods,
    ...commonStreamChartMethods,
    getSelectedEntities(value) {
      return formatter(value, 'number', 'NA');
    },
    isBulkSelectedClicked() {
      this.isBulkSelectClicked = true;
      this.selectAllClicked(true);
      this.$store.dispatch('setIsAllSelectedStateKeywords', true);
      this.enableOnlyBulkActions(true);
    },
    isBulkSelectedCleared() {
      this.resetAllowedActionsAfterBulk();
      this.$store.dispatch('setIsAllSelectedStateKeywords', false);
      this.toggleActionBar();
      if (this.bidPanelVisible) {
        this.bidChangeAfterAction();
      }
      this.isBulkSelectClicked = false;
    },
    checkBulkBannerVisibility(data) {
      const pageLimit = this.config.widgets.widget2.body.APIConfig.limit;
      const resultLen =
        this.getWorkbenchKeywordsData[this.config?.widgets?.widget2?.meta?.key]
          ?.rows?.length ?? 0;
      const bulkSelectAllConfigCheck = Vue.options.filters.config_check(
        'feature.ams_bulk_select_all'
      )?.enable;
      const isActionOpen = this.showaddTagsPanel;
      if (
        data.length === 0 &&
        this.$store.getters.getIsAllSelectedStateKeywords
      ) {
        this.$store.dispatch('setIsAllSelectedStateKeywords', false);
        this.showBulkBanner = false;
        this.isBulkSelectClicked = false;
        return;
      }
      const totalEntities = (
        this.getWorkbenchKeywordsData?.[
          this.config?.widgets?.widget2?.meta?.key
        ] || {}
      )?.totalRows;
      if (
        data.length < pageLimit &&
        data.length !== resultLen &&
        data.length > 0 &&
        pageLimit < totalEntities &&
        bulkSelectAllConfigCheck &&
        !isActionOpen
      ) {
        this.isBulkSelectClicked = false;
        this.$store.dispatch('setIsAllSelectedStateKeywords', false);
        this.resetAllowedActionsAfterBulk();
        this.showBulkBanner = true;
        return;
      }
      const isVisible =
        totalEntities > pageLimit &&
        (data.length === pageLimit || resultLen === data.length) &&
        bulkSelectAllConfigCheck &&
        !isActionOpen;
      this.showBulkBanner = isVisible;
    },
    selectAllClicked(data) {
      data
        ? this.config.widgets.widget2.body.gridOptions.api.selectAll()
        : this.config.widgets.widget2.body.gridOptions.api.deselectAll();
    },
    enableOnlyBulkActions(areAllSelected = false) {
      this.allowedActions = enableBulkOnlyActions(
        this.allowedActions,
        areAllSelected,
        'keywords'
      );
      return this.allowedActions;
    },
    resetAllowedActionsAfterBulk() {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
      commons.editAllowedActionsKeys(this);
    },
    toggleActionBar() {
      this.closeActionsWorkflow(false);
      this.resetAllowedActionsAfterBulk();
    },
    getKeywordsChartDataWithRollUp(selection) {
      this.getChartDataWithRollUp(
        selection,
        'Keywords',
        this.$store,
        this.selectedRollUp,
        this.config
      );
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    allRowSelection(value) {
      this.rbTableInstance.gridOptions.api.forEachNode((node) => {
        node.setSelected(value);
      });
    },
    onBulkClick(value) {
      this.allRowSelection(value);
      this.isBulkSelection = value;
    },
    handleTextInputTags(data) {
      const payload = {
        entityType: 'keyword',
        tagType: 'custom',
        customType: 'Custom',
        prefix: data.textInput
      };
      this.fetchAllTags(payload);
    },
    fetchAllTags: commons.debounce(function (payload) {
      this.$store.dispatch('fetchAllTags', payload);
    }, 500),
    getAddTagPanelInstance(that) {
      this.addTagsPanelInstance = that;
    },
    tagKeywords() {
      this.hideActionBar();
      this.showBulkBanner = true;
      this.showaddTagsPanel = true;
    },
    createTagsRequest(activeTags, isBulkSelection) {
      let create = true;
      const allTags = this.getAllTags?.rows?.map((item) => item.title);
      const entities = this.selections.map((item) => {
        return {
          value: item?.data?.keyword_text,
          type: 'keyword',
          description: '',
          image: ''
        };
      });
      const tags = activeTags.map((item) => {
        if (allTags.includes(item?.title)) {
          create = false;
        }
        return {
          level1: item?.title?.trim(),
          level2: '',
          level3: '',
          level4: '',
          level5: '',
          type: 'custom',
          desc: '',
          customType: 'Custom'
        };
      });
      if (isBulkSelection) {
        const brandsRequest = { ...this.config.widgets.widget2.body.APIConfig };
        delete brandsRequest.limit;
        delete brandsRequest.page;
        brandsRequest.orderByList = [];
        brandsRequest.measuresList = ['count'];
        brandsRequest.groupByDimensionsList = ['keyword_text'];
        transformer.getCompleteWhereClause(
          (this.config.widgets.widget2.meta.localFilters || []).concat(
            this.config.widgets.widget2.meta.plotSelections || []
          ),
          this.keywordFilters
        );
        brandsRequest.where.date = this.keywordFilters?.date;
        return {
          brandsRequest,
          tags,
          entityType: 'keyword'
        };
      }

      return {
        entityTaggingAddRequest: {
          tags,
          entities,
          create
        }
      };
    },
    handleAddTagsForKeywords(activeTags) {
      const tagsToValidate = activeTags?.map((item) => {
        return { value: item.title, maxLength: 15 };
      });
      const validationCheck = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      const charCheck = utils.textValidation(validationCheck, tagsToValidate);
      if (charCheck) {
        const commonMessage = 'Tag name cannot ';
        const reasonMessage =
          charCheck.reason === 'character'
            ? 'have special characters.'
            : `exceed ${charCheck.maxLength} characters.`;
        const snackbarObject = {
          message: commonMessage + reasonMessage,
          duration: 5000,
          actionText: ''
        };
        this.$snackbar.open(snackbarObject);
        return;
      }
      let error = false;
      const requestBody = this.createTagsRequest(
        activeTags,
        this.isBulkSelection
      );
      this.tagsLoader.load = true;
      const actionToCall = this.isBulkSelection
        ? 'bulkAddTag'
        : 'addTagToEntity';
      this.$store
        .dispatch(actionToCall, requestBody)
        .then((result) => {
          this.showaddTagsPanel = false;
          this.addTagsPanelInstance.tagsAndSuggestionsInstance.clearAllActiveTags();
          this.closeActionsWorkflow();
          this.deselectAllRows();
        })
        .catch((err) => {
          console.log(err);
          error = true;
        })
        .finally(() => {
          this.tagsLoader.load = false;
          const snackbarObject = {
            message: 'Something went wrong!',
            duration: 5000,
            buttonColor: '#f5d908'
          };
          if (!error) {
            snackbarObject.message =
              'Keywords were added to Custom Digital Shelves successfully!';
            snackbarObject.actionText = 'Visit & Manage Digital Shelves';
            snackbarObject.onAction = () => {
              this.$router.push({
                path: '/tools_and_settings/digital-shelves',
                query: { type: 'custom' }
              });
            };
          }
          this.$snackbar.open(snackbarObject);
        });
    },
    getInitialPayloadObject(data) {
      const realtimeMetadata = this.getRealtimeMetadata;
      const oldStatus =
        (realtimeMetadata?.realtime &&
          realtimeMetadata?.data[data?.keyword_id]?.status?.toLowerCase()) ||
        data?.status?.toLowerCase();
      let payload = {
        keyword_id: data.keyword_id,
        campaignId: data.campaign_id,
        adgroup_id: data.adgroup_id,
        campaignType: data.campaign_type,
        profileId: data.profile_id
      };
      let obj = {
        widget: 'keyword',
        primaryKey: data.keyword_id,
        actionPayload: Object.assign({}, payload),
        viewPayload: Object.assign({}, payload),
        actionType: 'keywordMetadataChange',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      const targetMatchTypeKeyword = [
        'TARGETING_EXPRESSION',
        'TARGETING_EXPRESSION_PREDEFINED'
      ];
      if (
        targetMatchTypeKeyword.includes(
          (data?.match_type_keyword || data?.match_type)?.toUpperCase()
        ) &&
        data?.keyword_id
      ) {
        obj.actionType = 'targetMetadataChange';
        delete obj.actionPayload.keyword_id;
        obj.actionPayload.target_id = data.keyword_id;
      }
      obj.viewPayload.campaignName = data.campaign_name;
      obj.viewPayload.match_type = data.match_type || data.match_type_keyword;
      obj.viewPayload.keyword_text = data.keyword_text;
      obj.viewPayload.adgroup_name = data.adgroup_name;
      obj.actionPayload.state = '';
      obj.actionPayload.previousStatus = oldStatus;
      obj.viewPayload.newStatus = '';
      obj.viewPayload.previousStatus = oldStatus;
      return obj;
    },

    scrollToNodeId() {
      return null;
    },
    processPayloadForStatusChange(params, payloadObjRef, rowData) {
      payloadObjRef.actionPayload.state = params;
      payloadObjRef.viewPayload.newStatus = params;
    },

    getPayload(params, actionType) {
      let paramsToReturn = [];
      for (const element of this.selections) {
        let data = element.data;
        let obj = this.getInitialPayloadObject(data);
        this.processPayloadForStatusChange(params, obj, data);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    adjustBidsPanel() {
      this.hideActionBar();
      this.showBidChangePanel = !this.showBidChangePanel;
      let elm = document.getElementsByClassName('detail-panel');
      if (this.showBidChangePanel === true) {
        elm[0].scrollTo(0, 0);
      } else {
        elm[0].scrollTo(0, 500);
      }
    },

    bidChangeAfterAction(isCloseMethod = false) {
      this.bidLoader = false;
      this.closeActionsWorkflow(false);
      this.adjustBidsPanel();
      this.onSelectionChanged([]);
      this.plotRows('delete', isCloseMethod);
    },

    hideActionBar() {
      this.showActionsBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    updateStatus(selectedOption) {
      if (!(selectedOption && selectedOption[0])) {
        return;
      }
      let that = this;
      let selectedStatus = selectedOption[0].value.toLowerCase();
      let payload = this.getPayload(selectedStatus, 'updateKeywordState');
      let othersObj = {
        successMessage:
          'Status change request has been submitted for the selected keyword(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closeActionsWorkflow(false);
          that.onSelectionChanged([]);
          that.plotRows('delete');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        selectedOption,
        payload,
        othersObj,
        'metadataChange',
        false
      );
    },

    closeActionsWorkflow(fetchChart = true) {
      this.showaddTagsPanel = false;
      this.isBulkSelection = false;
      if (this.$store.getters.getIsAllSelectedStateKeywords) {
        this.isBulkSelectClicked = !this.isBulkSelectClicked;
        this.$store.dispatch('setIsAllSelectedStateKeywords', false);
        this.resetAllowedActionsAfterBulk();
      }
      if (fetchChart) {
        this.onSelectionChanged([]);
        this.plotRows('delete');
      }
      commons.closeActionsWorkflow.call(this);
    },

    removeAllActionPanels(fetchChart = true) {
      this.closeActionsWorkflow(fetchChart);
      this.showBidChangePanel = false;
    },

    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection
    ) {
      const selectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      const allowedActions = ['adjust-bids'];
      commons.checkIfActionIsValid(
        actionId,
        allowedActions,
        selectedNodes,
        this.$snackbar,
        this.isBulkSelectClicked
      );
      const newSelectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      if (newSelectedNodes.length <= 0) {
        return;
      }
      if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        this.actionIdFunctionMap[actionId]();
      }
    },
    plotRows(type, isCloseMethod = false) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      if (!isCloseMethod) {
        eventBus.$emit(
          'workbenchKeywordsPlotSelectedRows',
          this.plotRowsSelections
        );
      }
      document.getElementById('wk-keywords-filters').scrollIntoView();
    },

    onCreate(instance) {
      this.filterInstance = instance;
    },

    checkForActionBar(data) {
      if (
        !this.showBidChangePanel &&
        !this.showaddTagsPanel &&
        !this.showActionsBar
      ) {
        this.showActionsBar = data.length > 0;
      }
      if (data.length === 0) {
        this.showaddTagsPanel = false;
        this.showActionsBar = false;
      }
    },

    onSelectionChanged(data) {
      this.checkBulkBannerVisibility(data);
      if (data.length === 0) {
        this.showBidChangePanel = false;
        this.resetAllowedActionsAfterBulk();
      }
      this.plotRowsSelections = data.map((selection) => {
        return selection.data;
      });
      this.selections = data;
      this.checkForActionBar(data);
      commons.validateForArchivedActions(this.allowedActions, this.selections);
      this.isSDCampaign = commons.invalidateSDActions(
        data,
        this.allowedActions
      );
    },

    onSDCampaignsSelection() {
      const actionTippys = {
        'adjust-bids': this.unavailableMessageSD,
        'edit-status': this.unavailableMessageSD
      };
      this.allowedActions = commons.modifyTippyBasedOnActionState(
        this.allowedActions,
        actionTippys,
        this.isSDCampaign
      );
      if (this.isSDCampaign) {
        this.$snackbar.open({
          message: 'Action set limited for SD campaigns',
          duration: 3000,
          actionText: ''
        });
      }
    },

    kpiMetricsCheck() {
      let kpiMetrics = ['Inventory', 'Organic sales', 'PCOGS', 'Total sales'];
      let resultLen = (
        this.getWorkbenchKeywordsData[
          this.config?.widgets?.widget2?.meta?.key
        ] || {}
      )?.rows?.length;
      if (
        resultLen === undefined ||
        resultLen === 1 ||
        this.plottedSelections >= 1
      ) {
        for (let index in kpiMetrics) {
          if (this.metricsShown.indexOf(kpiMetrics[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    },
    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openKeywordsActivityLog');
      this.$store.dispatch('getKeywordsActivityLog', {
        primaryKey: that.params.node.data.keyword_id
      });
      if (this.keywordId === that.params.node.data.keyword_id) {
        return;
      }
      this.keywordId = that.params.node.data.keyword_id;
      this.rbTableInstance.redrawTableRows();
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.keywordId = null;
      this.$store.dispatch('closeKeywordsActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    getAllTableColumns() {
      return commons.addDefaultColCheck(
        this.config.widgets.widget2.body.columnDefs || [],
        defaultKeywordsCols
      );
    }
  }
};
</script>

<style scoped>
.padding--right {
  padding-right: 0% !important;
}
.ams-keywords .realTimeActiveBorder {
  border: solid 2px #73b8fb !important;
}
.ams-keywords .realTimeToggleWrapper {
  height: 36px;
  padding: 6px 8px;
  border-radius: 4px;
  border: solid 2px transparent;
  background-color: #e6f3ff;
}
.ams-keywords .realTimeToggleWrapper:hover {
  cursor: pointer;
}
.ams-keywords .realTimeToggleWrapper:hover,
.ams-keywords .realTimeToggleWrapper:focus {
  background-color: #bfdffd;
}
.ams-keywords .realTimeToggleWrapper .streamBtnFontActive {
  color: #007cf6;
}
.ams-keywords .realTimeToggleWrapper .streamBtnFont {
  margin-left: 5px;
}
.ams-keywords .filter-panel-class {
  z-index: 99;
  margin-bottom: 0;
}
.ams-keywords .change-bg-color {
  background-color: #e6f3ff !important;
}
.ams-keywords .set-roll-up-width {
  min-width: 210px;
  margin-top: 16px;
}
.ams-keywords .keywords-tags-input .new-tags-selection:hover {
  background-color: rgba(189, 16, 224, 0.1);
}
.ams-keywords .keywords-tags-input .tag_token_container {
  background-color: rgba(189, 16, 224, 0.1);
}
.ams-keywords .keywords-tags-input .suggestion-item-tags-suggestion:hover {
  background-color: rgba(189, 16, 224, 0.1);
}
.keywords-radio-group > span {
  font-size: 13px !important;
  color: #6a7075;
}
.keywords-radio-group .rb-control-label {
  padding: 4px !important;
  font-size: 13px !important;
}
</style>

<style lang="css">
.ams-keywords .filter__wrapper > div {
  align-items: flex-start;
}
.ams-keywords .filter__wrapper > div > div > div {
  align-items: flex-start;
}
.ams-keywords
  .realTimeToggleWrapper
  .rb-switch
  input[type='checkbox']
  + .small {
  width: 15px;
  height: 4px;
  padding: 3px;
}
.ams-keywords
  .realTimeToggleWrapper
  .rb-switch
  input[type='checkbox']
  + .small:before {
  width: 8px;
  height: 8px;
}

.ams-keywords .migration-banner {
  top: 0rem !important;
}
</style>
