<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      Share of search -
      {{
        sampleSize === 'top3'
          ? 'Top 3'
          : sampleSize === 'top10'
          ? 'Top 10'
          : sampleSize === 'page1'
          ? 'Page 1'
          : ''
      }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      <span v-if="direction === 'drop'">Decreased to </span
      ><span v-if="direction === 'increase'">Increased to </span
      >{{
        formatter(
          alert.attributes[sampleSize + '_share_organic_current'],
          'percent'
        )
      }}
      (Average of last 7 days) from
      {{
        formatter(
          alert.attributes[sampleSize + '_share_organic_previous'],
          'percent'
        )
      }}
      (Average of 3 weeks prior to last 7 days).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <!-- <p class="u-font-size-5 u-spacing-pv-l u-font-weight-600">
      Share of search: {{formatter(alert.attributes[sampleSize + '_share_organic_yesterday'], 'percent')}} <span class="u-font-size-7 u-color-grey-base u-font-weight-300">(Yesterday)</span>, {{formatter(alert.attributes[sampleSize + '_share_organic_today'], 'percent')}} <span class="u-font-size-7 u-color-grey-base u-font-weight-300">(Today)</span>
    </p> -->
    <p class="u-spacing-pv-l u-font-size-5 u-font-weight-600">
      Search category:
      <span class="u-font-size-6 u-font-weight-400">{{
        alert.attributes.category
      }}</span
      ><span
        class="u-border-left u-border-color-grey-lighter u-border-width-s u-spacing-pl-s u-spacing-ml-s"
        >Keyword priority:</span
      >
      <span class="u-font-size-6 u-font-weight-400 u-text-case-lower">{{
        formatter(alert.attributes.search_priority_rank, 'number')
      }}</span>
    </p>
    <div class="detail-view--card">
      <div
        class="componentWrapper u-flex-grow-1"
        style="border-bottom: 1px solid rgb(233, 234, 235)"
      >
        <p class="u-spacing-pv-m u-color-grey-lighter">
          DISTRIBUTION OF BRANDS
        </p>
        <div
          v-if="distribution.length"
          class=""
        >
          <data-bars
            :key-to-check="'brand'"
            client-name="My Brand"
            :bar-tooltip-key="'title'"
            :bars-value="distribution"
          />
        </div>
      </div>
      <div class="u-spacing-pv-s u-spacing-pl-m">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <div class="u-display-flex u-flex-justify-content-space-between">
      <p
        class="u-spacing-pt-xl u-spacing-pb-m u-font-size-5 u-font-weight-600 u-color-grey-lighter"
      >
        BIG MOVERS AND ROOT CAUSE
      </p>
      <div class="u-spacing-mt-m">
        <rb-tabs
          v-model="moverstab"
          class="rb-tab--underlined"
          :type="'horizontal'"
          :tab-list="moverstabs"
          @input="setMoversTab"
        />
      </div>
    </div>
    <div class="detail-view--card u-spacing-mb-l">
      <el-table
        v-if="productMovementTrendComputed"
        ref="SRTable"
        :border="true"
        empty-text="No Data"
        class="u-width-100 recommendation-table"
        :data="productMovementTrendPaginatedData"
      >
        <el-table-column
          :label="''"
          :width="70"
        >
          <div slot-scope="scope">
            <div
              class="icon--task u-cursor-pointer u-text-align-right"
              @click="showSKUdetails(scope.row)"
            >
              <rb-icon :icon="'full-screen'" />
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="'SKU Details'"
          :width="300"
          :render-header="appendTip"
        >
          <div slot-scope="scope">
            <div
              class="u-display-flex u-flex-direction-row u-flex-align-items-top"
            >
              <div class="media__img u-flex-none">
                <img
                  :src="scope.row.image_url"
                  alt="image"
                />
              </div>
              <div class="media__content u-flex-1 u-flex-shrink-past-min-size">
                <div
                  :title="scope.row.title"
                  class="media__title u-line-height-1 u-spacing-pb-xs"
                >
                  {{ scope.row.title }}
                </div>
                <div class="media__subtitle">
                  <a
                    class="link--asin u-line-height-1"
                    title="Open product page"
                    :href="scope.row.product_page_url"
                    target="_blank"
                    @click.stop
                    >{{ scope.row.comp_sku }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="'SKU Priority'"
          :width="130"
          :render-header="appendTip"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{ formatter(scope.row.sku_priority_rank, 'number') }}
          </div>
        </el-table-column>
        <el-table-column label="Search Rank">
          <el-table-column
            :label="'Current'"
            :width="100"
            :render-header="appendTip"
          >
            <div
              slot-scope="scope"
              class=""
            >
              {{ formatter(scope.row.search_rank_current, 'number') }}
            </div>
          </el-table-column>
          <el-table-column
            :label="'Previous'"
            :width="110"
            :render-header="appendTip"
          >
            <div
              slot-scope="scope"
              class=""
            >
              {{
                formatter(scope.row.avg_search_rank_historical_period, 'number')
              }}
            </div>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="'Content Changes'"
          :width="160"
          :render-header="appendTip"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{ scope.row.content_change_status ? 'Yes' : 'No' }}
          </div>
        </el-table-column>
        <el-table-column
          :label="'Price Changes'"
          :width="150"
          :render-header="appendTip"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{ formatter(scope.row.total_price_changes_4weeks, 'number') }}
          </div>
        </el-table-column>
        <el-table-column
          :label="'OOS'"
          :width="100"
          :render-header="appendTip"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{
              formatter(scope.row.total_days_on_oos_historical_period, 'number')
            }}
          </div>
        </el-table-column>
        <!-- <el-table-column
          :label="'Promo'"
          :render-header="appendTip"
          :width="100">
          <div class="" slot-scope="scope">
            {{formatter(scope.row.days_on_promo_historical_period, 'number')}}
          </div>
        </el-table-column> -->
        <el-table-column
          :label="'Rollback'"
          :render-header="appendTip"
          :width="100"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{
              formatter(scope.row.days_on_rollback_historical_period, 'number')
            }}
          </div>
        </el-table-column>
        <el-table-column
          :label="'Clearance'"
          :render-header="appendTip"
          :width="100"
        >
          <div
            slot-scope="scope"
            class=""
          >
            {{
              formatter(scope.row.days_on_clearance_historical_period, 'number')
            }}
          </div>
        </el-table-column>
      </el-table>
      <div
        class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-s u-spacing-pv-s"
      >
        <div
          class="u-flex-1 u-display-flex u-flex-align-items-center date--block"
        >
          {{ $t('last_updated') }}:
          {{ formatter(alert.attributes.feed_date, 'date') }}
        </div>
        <div class="">
          <b-pagination
            :total="totalCount"
            :current.sync="currentPage['pageNo']"
            :size="'is-small'"
            :simple="false"
            :rounded="true"
            :per-page="pageLimit"
            @change="pageChanged('pageNo')"
          />
        </div>
      </div>
    </div>
    <!--     <p class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter">
      SHARE OF TOP 3 BRANDS
    </p> -->
    <div
      sty
      class="u-max-width-100 detail-view--card chart--card"
    >
      <div class="detail--chart">
        <chart
          v-if="showChart"
          style="height: 270px"
          class="u-spacing-mh-l u-spacing-pv-l"
          :config="barChartConfig"
          :data="barChartData"
        />
      </div>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <p
      v-if="
        (alert.attributes.mean_estimate_value || direction === 'drop') &&
        showPerformanceOverview
      "
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max):
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('price_compression_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p
      v-if="direction === 'drop'"
      class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m"
    >
      Increase investment in paid media campaigns to improve your share of
      search.
    </p>
    <!-- SKU Details View -->
    <div
      v-if="SKUdetails"
      class="u-position-fixed u-overflow-auto"
      style="
        background: #fff;
        z-index: 1000;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      "
    >
      <div
        class="u-display-flex"
        style="box-shadow: 2px 1px 4px 0 #e9eaeb"
      >
        <div
          class="u-cursor-pointer u-spacing-mh-m u-spacing-mv-l"
          @click="exitSKUdetails()"
        >
          <rb-icon :icon="'cross'" />
        </div>
        <div
          class="u-spacing-m-l u-font-size-1 u-font-weight-bold"
          style="margin-left: 200px"
        >
          SKU Details
        </div>
      </div>
      <div style="margin-left: 252px">
        <div
          class="u-display-flex u-flex-align-items-center u-font-size-5 u-spacing-pt-l"
        >
          <div
            style="height: 56px; width: 56px; margin-right: 8px"
            class="media__img u-flex-none"
          >
            <img
              style="height: 56px; width: 56px"
              :src="SKUdetails.image_url"
            />
          </div>
          <div class="sku-text long">
            <div class="media__title u-spacing-pb-xs u-font-size-6">
              {{ SKUdetails.title }}
            </div>
            <div class="media__subtitle">
              <a
                target="_blank"
                class="link--asin u-line-height-1 u-font-size-7"
                :href="SKUdetails.product_page_url"
                title="Open product page"
                >{{ SKUdetails.comp_sku }}</a
              >
            </div>
          </div>
        </div>

        <div class="u-display-flex u-spacing-pt-l">
          <div
            class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
          >
            <key-value-pair
              :item-key="'Category'"
              :item-value="alert.attributes.category"
            />
          </div>
          <div
            class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
          >
            <key-value-pair
              :item-key="'Priority'"
              :item-value="
                formatter(alert.attributes.search_priority_rank, 'number')
              "
            />
          </div>
          <div
            class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
          >
            <key-value-pair
              :item-key="'Offer price'"
              :item-value="
                formatter(SKUdetails.offer_price_current, 'currency')
              "
            />
          </div>
          <div
            class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
          >
            <key-value-pair
              :item-key="'Keyword'"
              :item-value="SKUdetails.search"
            />
          </div>
          <!-- <div class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light">
            <key-value-pair :item-key="'Keyword frequency rank'" :item-value="formatter(SKUdetails.frequency, 'number')"></key-value-pair>
          </div> -->
          <div class="u-spacing-ph-s">
            <key-value-pair
              :item-key="'Current search rank'"
              :item-value="formatter(SKUdetails.search_rank_current, 'number')"
            />
          </div>
        </div>

        <div class="u-display-flex u-spacing-pt-m u-spacing-pb-xl">
          <div
            class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
          >
            <span class="u-font-weight-600">{{ $t('rating') }}: </span>
            <star-rating
              :active-color="'#f3b000'"
              :inactive-color="'#f3b000'"
              :max="5"
              :value="Math.round(SKUdetails.avg_product_rating || 0)"
              :readonly="true"
            >
              <span
                slot="activeLabel"
                slot-scope="props"
                ><rb-icon :icon="'star'"
              /></span>
              <span
                slot="inactiveLabel"
                slot-scope="props"
                ><rb-icon :icon="'star-empty'"
              /></span>
            </star-rating>
            <span
              >({{ SKUdetails.avg_product_rating || 'NA' }}
              {{ $t('out_of_5') }})</span
            >
          </div>
          <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
            <key-value-pair
              :item-key="$t('reviews')"
              :item-value="SKUdetails.customer_reviews_count"
            />
          </div>
        </div>

        <div
          style="max-width: 722px"
          class="detail-view--card chart--card"
        >
          <div class="detail--chart">
            <chart
              style="height: 270px"
              class="u-spacing-mh-l u-spacing-pv-l"
              :config="innerBarChartConfig"
              :data="innerBarChartData"
            />
          </div>
          <div class="date--block u-spacing-pv-s u-spacing-ph-l">
            {{ $t('last_updated') }}:
            {{ formatter(SKUdetails.feed_date, 'date') }}
          </div>
        </div>

        <p
          class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
        >
          POSSIBLE CAUSES
        </p>
        <ol style="list-style: decimal inside">
          <li
            v-for="(value, $index) of reasons"
            :key="$index"
            class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m"
          >
            {{ value }}
          </li>
          <li class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
            Content changes in “Title” and “About this item”
          </li>
        </ol>
        <div
          class="u-spacing-mt-s u-spacing-mb-xl"
          style="box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15); width: 670px"
        >
          <div style="border-bottom: 1px solid #e9eaeb">
            <rb-tabs
              v-model="tab"
              class="rb-tab--underlined"
              :type="'horizontal'"
              :tab-list="tabs"
              @input="setTab"
            />
          </div>
          <div
            class="u-spacing-p-l"
            style="border-bottom: 1px solid rgb(233, 234, 235)"
          >
            <div v-if="tab === 0">
              <div
                v-for="title in SKUdetails.title_trend"
                v-if="title.last_title !== ''"
                :key="title"
              >
                <p
                  class="u-spacing-mt-m u-spacing-mb-s u-font-weight-600 u-font-size-7 u-color-grey-lighter"
                >
                  {{ title.date }}
                </p>
                <!-- eslint-disable -->
                <div
                  class="u-font-size-4"
                  v-html="title.last_title"
                />
                <!-- eslint-enable -->
              </div>
            </div>
            <div v-if="tab === 1">
              <div
                v-for="desc in SKUdetails.description_trend"
                v-if="desc.last_description !== ''"
                :key="desc"
              >
                <p
                  class="u-spacing-mt-m u-spacing-mb-s u-font-weight-600 u-font-size-7 u-color-grey-lighter"
                >
                  {{ desc.date }}
                </p>
                <!-- eslint-disable -->
                <div
                  class="u-font-size-4"
                  v-html="desc.last_description"
                />
                <!-- eslint-enable -->
              </div>
            </div>
          </div>
          <div class="date--block u-spacing-pv-s u-spacing-ph-l">
            {{ $t('last_updated') }}:
            {{ formatter(SKUdetails.feed_date, 'date') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';
import dataBars from '@/components/basic/dataBars';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
import tooltips from './tooltipConfig.js';

export default {
  components: {
    chart,
    starRating,
    keyValuePair,
    dataBars
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      currentPage: {
        pageNo: 1
      },
      showChart: false,
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          tooltip_format: {
            All: {
              roundoff: 0
            }
          }
        }
      },
      barChartData: [],
      innerBarChartConfig: null,
      innerBarChartData: [],
      sampleSize: '',
      direction: '',
      distribution: [],
      productMovementTrend: null,
      SKUdetails: null,
      tabs: ['Title', 'About this item'],
      tab: 0,
      moverstabs: ['Increase', 'Decrease'],
      moverstab: 0,
      innerContentDesc: [],
      innerDescription: '',
      reasons: null,
      productMovementTrendFilteredData: [],
      productMovementTrendPaginatedData: [],
      pageLimit: 5,
      totalCount: 0,
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  computed: {
    productMovementTrendComputed() {
      if (this.productMovementTrend === null) {
        return null;
      }
      this.productMovementTrendFilteredData = [];
      this.productMovementTrend.filter((a) => {
        if (this.moverstab === 0) {
          if (a[this.sampleSize + '_sku_movement_flag'] === 'added') {
            this.productMovementTrendFilteredData.push(a);
          }
        } else {
          if (a[this.sampleSize + '_sku_movement_flag'] === 'dropped') {
            this.productMovementTrendFilteredData.push(a);
          }
        }
      });
      this.totalCount = this.productMovementTrendFilteredData.length;
      this.pageChanged('pageNo');
      return this.productMovementTrendFilteredData.length > 0 ? true : null;
    }
  },
  mounted() {
    this.sampleSize = this.alert.alertType.split(' ')[2];
    this.direction = this.alert.alertType.split(' ')[3];
    var xs = {};
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var length =
      this.sampleSize === 'top3' ? 3 : this.sampleSize === 'top10' ? 10 : 100;

    var shareOfSearchTrend = JSON.parse(
      this.alert.attributes[this.sampleSize + '_search_brand_share_trend']
    );
    var brandsHavingShare = [];
    for (entry of shareOfSearchTrend) {
      if (
        entry.search_share > 0 &&
        entry.brand &&
        brandsHavingShare.indexOf(entry.brand) === -1
      ) {
        brandsHavingShare.push(entry.brand);
      }
    }
    for (var brand of brandsHavingShare) {
      dataArray = [brand];
      dateArray = [brand + '_x'];
      for (entry of shareOfSearchTrend) {
        if (entry.brand === brand) {
          dataArray.push(entry.search_share);
          dateArray.push(entry.date);
        }
      }
      chartData.push(dataArray, dateArray);
      xs[dataArray[0]] = dateArray[0];
    }

    this.productMovementTrend = JSON.parse(
      this.alert.attributes.product_movement_trend
    );
    for (entry of this.productMovementTrend) {
      this.distribution.push({
        brand: entry.product_by,
        title: entry.title,
        rank: entry.search_rank_current || 100
      });
    }
    this.distribution = this.distribution.sort((a, b) => {
      return a.rank - b.rank;
    });
    this.distribution.splice(length, 100);

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;

    console.log(this.productMovementTrend);
  },
  methods: {
    showSKUdetails(SKU) {
      this.tab = 0;
      this.innerContentDesc = [];
      this.innerBarChartConfig = {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            'Search rank': 'y2'
          },
          axis_format: {
            y2: {
              inverted: true
            }
          },
          tooltip_format: {
            Price: {
              pre: 'currency',
              roundoff: 2
            },
            'Search rank': {
              roundoff: 0
            },
            'Content change': function (value, ratio, id, index) {
              return this.innerContentDesc[index];
            }.bind(this)
          },
          point_format: {
            'Content change': function (data) {
              if (this.innerContentDesc[data.index] !== '') {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      };
      this.innerBarChartData = [];
      var xs = {};
      var dataArray = [];
      var dateArray = [];
      var chartData = [];
      var entry;

      dateArray = ['Price_x'];
      dataArray = ['Price'];
      for (entry of SKU.offer_price_trend) {
        if (!entry.offer_price) continue;
        dateArray.push(entry.date);
        dataArray.push(entry.offer_price);
      }
      chartData.push(dataArray, dateArray);
      xs[dataArray[0]] = dateArray[0];

      dateArray = ['Search rank_x'];
      dataArray = ['Search rank'];
      for (entry of SKU.search_product_rank_trend) {
        if (!entry.search_rank) continue;
        dateArray.push(entry.date);
        dataArray.push(entry.search_rank);
      }
      chartData.push(dataArray, dateArray);
      xs[dataArray[0]] = dateArray[0];

      dateArray = ['Content_x'];
      dataArray = ['Content'];
      if (SKU.title_trend) {
        for (entry of SKU.title_trend) {
          if (!entry.title) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.innerContentDesc.push(': Title');
        }
      }

      this.innerDescription = '';
      if (SKU.description_trend) {
        SKU.description_trend = SKU.description_trend.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        for (entry of SKU.description_trend) {
          if (!entry.description) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.innerContentDesc.push(': Description');
          this.innerDescription = SKU.description_trend[0].description;
        }
      }
      chartData.push(dataArray, dateArray);
      xs[dataArray[0]] = dateArray[0];

      if (SKU.reasons && SKU.reasons.length) {
        this.reasons = SKU.reasons.split(' | ').filter((a) => {
          return a !== '';
        });
      }

      this.innerBarChartConfig.chartOptions.timeseries = xs;
      this.innerBarChartData = chartData;
      this.SKUdetails = SKU;
    },
    exitSKUdetails() {
      this.SKUdetails = null;
    },
    setTab(tab) {
      this.tab = tab;
    },
    setMoversTab(tab) {
      this.moverstab = tab;
    },
    appendTip(h, { column }) {
      var tooltipObj = tooltips.shareOfSearchType;
      var toolTipText = tooltipObj.tooltipText[column.label];
      toolTipText = toolTipText === undefined ? '---' : toolTipText;
      toolTipText = column.label + ': \n' + toolTipText;
      return renderColumnHeaders.renderHeader(h, column, true, toolTipText);
    },
    pageChanged(pageNo) {
      setTimeout(
        function () {
          var pageNum = this.currentPage[pageNo];
          this.productMovementTrendPaginatedData =
            this.productMovementTrendFilteredData.slice(
              pageNum * this.pageLimit - this.pageLimit,
              pageNum * this.pageLimit
            );
        }.bind(this),
        500
      );
    }
  }
};
</script>

<style lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}

.componentWrapper {
  padding: 1.2rem;
  border-color: #e9eaeb;
}
</style>
