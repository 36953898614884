import { provide, readonly, watch, ref } from '@vue/composition-api';
import dashboardInflator from '@/components/widgets/custom_widgets/dashboard_inflator.vue';
import { isEqual, cloneDeep } from 'lodash';
import Vue from 'vue';
export default {
  setup(args) {
    const requestParams = ref({
      global: {}
    });
    watch(
      () => cloneDeep(requestParams.value),
      (nv, ov) => {
        console.log('request Param changed');
      }
    );
    provide('requestParams', readonly(requestParams));
    const updateEntireRequestParams = (rp) => {
      if (!isEqual(rp, requestParams.value)) {
        requestParams.value = rp;
      }
    };
    const updateWidgetRequestParams = (widgetParamsData, widgetKey) => {
      if (!isEqual(widgetParamsData, requestParams.value[widgetKey])) {
        // state change is not triggered, restting the value
        if (requestParams?.value[widgetKey]) {
          delete requestParams.value[widgetKey];
        }
        Vue.set(requestParams.value, widgetKey, widgetParamsData);
      }
    };
    const updateGlobalRequestParams = (globalRequestParam) => {
      if (!isEqual(globalRequestParam, requestParams.value.global)) {
        Vue.set(requestParams.value, 'global', globalRequestParam);
      }
    };
    const getGlobalRequestParams = () => cloneDeep(requestParams.value.global);
    const getWidgetRequestParams = (widgetKey) => {
      return cloneDeep(requestParams.value[widgetKey]);
    };
    const getWidgetMetaForKey = (key) => {
      return cloneDeep(requestParams.value[key]);
    };

    provide('updateEntireRequestParams', updateEntireRequestParams);
    provide('updateWidgetRequestParams', updateWidgetRequestParams);
    provide('updateGlobalRequestParams', updateGlobalRequestParams);
    provide('getGlobalRequestParams', getGlobalRequestParams);
    provide('getWidgetRequestParams', getWidgetRequestParams);
    provide('getWidgetMetaForKey', getWidgetMetaForKey);

    return {
      requestParams,
      updateEntireRequestParams,
      updateWidgetRequestParams,
      updateGlobalRequestParams,
      getGlobalRequestParams,
      getWidgetRequestParams,
      getWidgetMetaForKey
    };
  },
  components: {
    dashboardInflator
  },
  methods: {
    requestParamsChange(changesRequest) {
      this.updateEntireRequestParams(changesRequest);
    }
  }
};
