<template>
  <div
    ref="container"
    class="u-display-flex chip-style u-flex-align-items-center u-cursor-pointer u-color-grey-lighter u-display-flex u-flex-align-items-center u-spacing-ph-xs u-cursor-pointer"
    :class="{
      'rule-builder-chip': chipSize === 'xs'
    }"
  >
    <div class="u-display-flex u-flex-align-items-center u-spacing-pv-xxs">
      <span class="u-spacing-pl-xs">
        <rb-icon
          :icon="preTextIcon"
          class="rb-icon rb-icon--small u-cursor-pointer"
        />
      </span>
      <span class="u-spacing-pl-xs u-spacing-mr-xs u-color-grey-lighter">{{
        preText
      }}</span>
      <loader
        v-if="load"
        class="u-bg-color-grey-white u-spacing-ph-xs"
        size="12px"
        :loading="true"
        :color="'#007cf6'"
      />
      <span
        v-else-if="noData"
        class="u-spacing-pl-xs u-spacing-mr-xs u-color-grey-lighter"
      >
        No Data
      </span>
      <div
        v-else
        ref="selections-container"
        class="max-width overflow-styles"
      >
        <span
          v-for="(selection, index) in selections"
          ref="selection"
          :key="index"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <rb-icon
              v-if="selection.icon"
              class="u-spacing-mr-xs"
              :icon="selection.icon.name"
              :class="selection.icon.class"
            />
            <span class="u-spacing-mr-xxs u-color-grey-base">
              {{ selection.title
              }}{{ index !== selections.length - 1 ? ',' : '' }}
            </span>
          </div>
        </span>
      </div>
      <div class="u-color-grey-base u-font-weight-600">
        {{ overflowText }}
      </div>
      <span class="u-spacing-pr-xxs">
        <rb-icon
          class="u-flex-0 rb-icon--small"
          icon="caret-down"
        />
      </span>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';

export default {
  components: {
    loader
  },
  props: {
    load: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    chipSize: {
      type: String,
      default: 'xs'
    },
    preText: {
      type: String,
      default: null
    },
    selections: {
      type: Array,
      default: () => []
    },
    preTextIcon: {
      type: String,
      default: 'filter'
    }
  },
  data() {
    return {
      overflowCount: null
    };
  },
  computed: {
    overflowText() {
      if (this.overflowCount) {
        return `& ${this.overflowCount} more.`;
      }
      return '';
    }
  },
  watch: {
    selections: {
      immediate: true,
      handler(newSelections) {
        this.hideOverflowingSelections();
      }
    }
  },
  methods: {
    hideOverflowingSelections() {
      setTimeout(() => {
        const selectionContainer =
          this.$refs['selections-container']?.getBoundingClientRect() || {};
        const selection = this.$refs.selection || [];
        let count = 0;
        if (selection.length > 1) {
          for (let i = 0; i < selection.length; i++) {
            const element = selection[i];
            const elementCoordinates = element?.getBoundingClientRect() || {};
            if (
              elementCoordinates.x >
              selectionContainer.x + selectionContainer.width
            ) {
              element.style.visibility = 'hidden';
              count++;
            } else {
              element.style.visibility = '';
            }
          }
        }
        this.overflowCount = count;
      });
    }
  }
};
</script>

<style lang="css" scoped>
.max-width {
  max-width: 250px;
}

.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
