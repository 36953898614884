<template>
  <span class="editable-tags-cell-container">
    <div
      v-for="(item, key) in currentEntities"
      :key="key"
      class="edit-tag-cell-list-item"
      :style="editableCellsStyle"
    >
      <rb-select-v2
        v-if="interactiveStateData === 'edit' && showDropDown"
        :search-enabled="true"
        :client-search="false"
        :search-listener="skuListener"
        :send-details="true"
        :on-close="onEditOptionSelect"
        :options="options"
        class="u-spacing-mr-s u-max-width-100"
        @active-change="handleDropdownActiveChange"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
          :class="{ 'active-dropdown': isDropdownActive }"
        >
          <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
            {{ selectedOption.title }}
          </div>
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
            :icon="'caret-down'"
          />
        </div>
      </rb-select-v2>
      <addTagsPanel
        v-else-if="interactiveStateData === 'edit' || isTagEditable(item)"
        tags-suggestions-container-classes="editable-tags-suggestion-container"
        :enable-style-api="false"
        input-placeholder=""
        :class="{
          'edit-tag-cell-list-item-edit':
            interactiveStateData === 'edit' || isTagEditable(item)
        }"
        class="edit-cell-tag editable-tags-suggestion-container"
        :show-footer="false"
        :show-header="false"
        :header-text="'YOUR CATEGORIES'"
        :suggestions-data="getSearchSuggestions"
        :tags-icons="[
          { icon: 'cross', emit: 'removeActiveTag', color: 'grey-mid-light' }
        ]"
        :initial-tags="getItemTags(item)[0].title ? getItemTags(item) : []"
        :allow-multiple-tags="false"
        @textInput="handleTextInput"
        @tagsChanged="tagsChanged($event, item)"
      />
      <edited-tag-cell
        v-else-if="interactiveStateData === 'edited'"
        :enable-style-api="false"
        class="tags_view_cell_container u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto"
        :edited-entity="item"
        :enable-text-shrink="true"
      />
      <tags-view-cell
        v-else-if="interactiveStateData === 'view'"
        :prefer-emitter="preferEmitter"
        :enable-style-api="false"
        :class="{
          'empty-tags-cell':
            !currentEntitiesData.length ||
            getItemTags(item)[0].type == 'newTagTrigger',
          tags_view_cell_container: currentEntitiesData.length || assignable
        }"
        class="u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto"
        :dropdown-options="dropdownOptions"
        :tags-list="getItemTags(item)"
        @onDropdownOptionSelect="onDropdownOptionSelect($event, item)"
        @tagClicked="tagClicked"
      />
    </div>
    <span v-if="!currentEntities.length">NA</span>
  </span>
</template>

<script>
import tagsViewCell from '@/components/widgets/tableComponents/cells/tagsViewCell.vue';
import addTagsPanel from '@/components/ams/actionPanels/addTagsPanel.vue';
import editedTagCell from '@/components/widgets/tableComponents/cells/editedTagCell.vue';
import rbSelectV2 from '@/components/pages/businessInsights/rbSelectV2';

import { isEmpty } from 'lodash';

export default {
  name: 'EditableTagsCell',
  components: {
    tagsViewCell,
    addTagsPanel,
    editedTagCell,
    rbSelectV2
  },
  props: {
    assignable: {
      type: Boolean,
      default: true
    },
    adhocTooltipFn: {
      type: Function,
      default: (metadata, tagChipItem) => {
        return undefined;
      }
    },
    preferEmitter: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default() {
        return {};
      }
    },
    currentEntities: {
      type: Array,
      default() {
        return [
          { rowId: 123, values: 'Available' },
          { rowId: 123, values: 'UnAvailable' }
        ];
      }
    },
    allSuggestionEntities: {
      type: Array,
      default() {
        return [];
      }
    },
    interactiveState: {
      type: String,
      // eg's - 'edit' and 'view'
      default: 'edit'
    },
    dropdownOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editableCells: {
      type: Object,
      default: function () {
        return {};
      }
    },
    editableCellsStyle: {
      type: String,
      default: ''
    },
    showDropDown: {
      type: Boolean,
      default: false
    },
    searchAction: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      interactiveStateData: this.interactiveState,
      textInput: '',
      currentEntitiesData: this.currentEntities,
      selectedOption: this.currentEntities[0],
      options: [],
      isDropdownActive: false
    };
  },
  computed: {
    getCurrentScopedSlots() {
      return Object.keys(this.$scopedSlots);
    },
    existingTagsComp() {
      const metadata = this.metadata;
      const adhocTooltipFn = this.adhocTooltipFn;
      this.currentEntitiesData = this.currentEntitiesData.map((item, index) => {
        return {
          id: item.recordId,
          tooltip: adhocTooltipFn(metadata, item),
          ...item
        };
      });
      if (
        this.currentEntitiesData.length > 0 ||
        this.interactiveStateData === 'edit'
      ) {
        console.log(this.currentEntitiesData);
        return this.currentEntitiesData;
      } else {
        return [
          {
            id: 999,
            title: 'Assign a value',
            type: 'newTagTrigger'
          }
        ];
      }
    },
    allSuggestionTags() {
      return this.allSuggestionEntities.map((value) => {
        return {
          title: value
        };
      });
    },
    getSearchSuggestions() {
      return {
        load: false,
        rows: this.allSuggestionTags.filter((item) => {
          return item.title.includes(this.textInput);
        }),
        noData: false,
        error: false
      };
    }
  },
  watch: {
    interactiveState(newVal) {
      this.interactiveStateData = newVal;
    },
    currentEntities(newVal) {
      this.currentEntitiesData = newVal;
    }
  },
  created() {
    this.options = this.allSuggestionTags;
  },
  methods: {
    getItemTags(item) {
      if (typeof item.title === 'number') {
        item.title = parseFloat(item?.title).toFixed(2);
      } else {
        item.title = item?.title?.replace('%27', "'");
      }
      const metadata = this.metadata;
      const adhocTooltipFn = this.adhocTooltipFn;
      if (
        !isEmpty(item.title) ||
        this.interactiveStateData === 'edit' ||
        !isNaN(item.title)
      ) {
        return [
          {
            id: item.recordId,
            tooltip: adhocTooltipFn(metadata, item),
            ...item
          }
        ];
      } else {
        return [
          {
            id: item.recordId,
            title: 'Assign a value',
            type: 'newTagTrigger',
            recordId: item.recordId
          }
        ];
      }
    },
    isTagEditable(item) {
      const { asin, column } = this.metadata;
      return this.editableCells?.[asin]?.[column]?.[item.recordId];
    },
    tagClicked(tagPayload) {
      const recordId = tagPayload?.tagItem?.recordId;
      this.$emit('tagClick', {
        tagPayload,
        metadata: this.metadata,
        instance: this,
        recordId
      });
    },
    handleTextInput({ textInput }) {
      this.textInput = textInput;
    },
    onDropdownOptionSelect(params, item) {
      if (!params[0]) {
        return;
      }
      const selectedOptionKey = params[0].key;
      if (selectedOptionKey === 'change_brand') {
        this.interactiveStateData = 'edit';
      } else if (selectedOptionKey === 'remove_brand') {
        this.currentEntitiesData = this.currentEntitiesData.filter(
          (currentEntry) => {
            return currentEntry.title !== params.title;
          }
        );
        this.$emit('tagsChanged', {
          currentTags: this.currentEntitiesData || [],
          metadata: this.metadata,
          force: true,
          recordId: item.recordId
        });
      }
      // sending 'this' as we can use it for row context when used in ag grid table
      this.$emit('onDropdownOptionSelect', {
        params,
        metadata: this.metadata,
        instance: this
      });
    },
    tagsChanged(event, item) {
      if (event?.[0]?.type === 'newTagTrigger') {
        event[0].title = event[1].title;
        delete event[0].type;
        event.pop();
      }
      this.$emit('tagsChanged', {
        currentTags: event,
        metadata: this.metadata,
        recordId: item.recordId
      });
    },
    onEditOptionSelect(context, selectedItem) {
      if (selectedItem.length > 0) {
        this.selectedOption = selectedItem[0];
        this.$emit('tagsChanged', {
          metadata: this.metadata,
          currentValue: this.currentEntities[0].title,
          updatedValue: selectedItem[0]
        });
      }
    },
    async skuListener(searchTerm, context) {
      this.options = await this.$store.dispatch(this.searchAction, {
        searchText: searchTerm,
        metadata: this.metadata
      });
    },
    handleDropdownActiveChange(data) {
      this.isDropdownActive = data;
    }
  }
};
</script>

<style lang="css">
.suggestion-item-tags-suggestion:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
.suggestion-item-tags-suggestion:focus {
  background-color: rgba(255, 213, 0, 0.1);
}
.edit-cell-tag .add-tags-panel-tags-suggestion {
  width: 'fit-content';
  min-width: '10px';
  margin: 0px;
}
.edit-cell-tag .add-tags-panel {
  margin-left: 0px;
}
.editable-tags-suggestion-container .new-tags-selection:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container {
  background-color: rgba(255, 168, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container .tag-icon {
  margin-top: 1px;
  margin-left: 9px;
}
.editable-tags-suggestion-container .tag_token_container .tag-text {
  color: #2b333b;
  font-size: 11px;
  font-weight: normal;
}

.tags_view_cell_container {
  overflow: hidden;
}
.tags_view_cell_container .new-tags-selection:hover {
  background-color: #ffa800;
}
.tags_view_cell_container .tag_token_container {
  background-color: rgba(255, 168, 0, 0.1);
}

.tags_view_cell_container.empty-tags-cell .new-tags-selection:hover {
  background-color: #ffffff;
  border: solid 1px #e9eaeb;
  color: #ffa800;
}
.tags_view_cell_container.empty-tags-cell .tag_token_container {
  padding: 4px 8px;
  background-color: #ffffff;
  border: solid 1px #e9eaeb;
  color: #ffa800;
}

.editable-tags-cell-container {
  width: 100%;
}
.editable-tags-cell-container .edit-tag-cell-list-item {
  height: 70px;
  padding: 19px 24px;
  border-color: blue;
}
.editable-tags-cell-container .edit-tag-cell-list-item:not(:last-child) {
  border-bottom: solid 1px #e9eaeb;
}
.editable-tags-cell-container .edit-tag-cell-list-item-edit {
  border: solid 1px #e9eaeb;
  height: 36px;
  border-radius: 4px;
}
.editable-tags-cell-container .edit-tag-cell-list-item-edit input {
  width: 100%;
}
.editable-tags-cell-container .edit-tag-cell-list-item-edit .searchWrapper {
  cursor: pointer;
}

.edit-cell-tag:focus-within {
  border: solid 1px #007cf6;
}
</style>

<style lang="css" scoped>
.active-dropdown {
  border: 1px solid #f5d908;
  border-radius: 4px;
}
</style>
