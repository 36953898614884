<template>
  <div
    v-if="isExtensionIconVisible"
    class="u-display-flex u-flex-align-items-center u-spacing-ph-m u-menuItem u-cursor-pointer"
    :class="isToolsAndSettingsEnabled ? 'u-spacing-pt-s' : ''"
    @click="onExtensionIconClick"
  >
    <img
      alt="chrome"
      width="16px"
      height="16px"
      src="/images/chrome_extension.svg"
    />
    <p
      v-tippy="{ placement: 'right', arrow: false }"
      class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs"
      :title="extLaunchTooltip"
    >
      Chrome Plugin
    </p>
  </div>
</template>

<script>
import utils from '@/utils/helpers/';
import { filters } from '@ciq-dev/ciq-ui';
import Vue from 'vue';
import logger from '@/components/pages/skuDetails/logger.js';

export default {
  name: 'ChromePlugin',
  author: 'Rajan V',
  data() {
    return {
      isInternalUser: false,
      isChrome: false,
      isExtensionInstalled: false
    };
  },
  computed: {
    extLaunchTooltip() {
      if (this.isExtensionInstalled) {
        const hostname = window.location.host;
        const hostComponents = hostname.split('.');
        const client = hostComponents[0];
        // prod env fall back
        const environment =
          hostComponents.length === 4 ? hostComponents[1] : '';
        return 'Set Chrome extension to ' + client + ' ' + environment;
      } else {
        return 'Install Chrome Extension';
      }
    },
    isExtensionIconVisible() {
      let bExtensionVisible = false;
      bExtensionVisible = this.isChrome;
      bExtensionVisible =
        bExtensionVisible &&
        (this.isInternalUser ||
          (!this.isInternalUser && !this.isExtensionInstalled));
      return bExtensionVisible;
    }
  },
  created() {
    if (window.chrome) {
      this.initiateExtensionSetup();
    }
    this.isToolsAndSettingsEnabled = Vue.options.filters.config_check(
      'feature.pages.tools_and_settings.enable'
    );
  },

  methods: {
    initiateExtensionSetup() {
      this.isChrome = utils.getBrowserName() === 'chrome';
      this.isInternalUser = utils.internalUserCheck(window.user);
      this.isCIQExtensionInstalled();
    },
    isCIQExtensionInstalled() {
      const browser = utils.getBrowserName();
      const extensionId =
        Vue.options.filters.config_check('extension.' + browser + '.id') ||
        'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      if (window.chrome && window.chrome.runtime) {
        try {
          window.chrome.runtime.sendMessage(
            extensionId,
            {
              methodType: 'IsExtensionInstallatedCheck'
            },
            (response) => {
              if (response && response.success) {
                this.isExtensionInstalled = true;
              }
            }
          );
        } catch (error) {
          console.info('extension not installed');
        }
      }
    },
    onExtensionIconClick() {
      if (this.isInternalUser) {
        this.syncExtension();
      }
      if (!this.isExtensionInstalled) {
        this.downloadExtension();
      }
    },
    downloadExtension() {
      window.open(this.getExtensionDownloadUrl(), '_blank');
      logger.log({
        eventType: 'extension_install_triggered',
        eventContext: 'header_launch_icon'
      });
    },
    getExtensionDownloadUrl() {
      const browser = utils.getBrowserName();
      const redirectUrl =
        'https://chrome.google.com/webstore/detail/commerceiq/';
      // getting extension id specific for browser e.g. 'extension.chrome.id'
      const extensionId =
        filters.config_check('extension.' + browser + '.id') ||
        'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      // let extensionId = 'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      if (browser === 'chrome') {
        return redirectUrl + extensionId;
      }
    },
    syncExtension() {
      // The ID of the extensio + extensionId;n we want to talk to.
      const browser = utils.getBrowserName();
      // getting extension id specific for browser e.g. 'extension.chrome.id'
      // to be changed
      const extensionId =
        filters.config_check('extension.' + browser + '.id') ||
        'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      // Make a simple request:
      // let extensionId = 'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      const hostname = window.location.host;
      const hostComponents = hostname.split('.');
      const client = hostComponents[0];
      const environment =
        hostComponents.length === 4 ? hostComponents[1] : 'prod';
      const configs = this.$store.getters.getConfigs;
      if (window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(
          extensionId,
          {
            methodType: 'clientSetup',
            clientDetails: {
              name: client,
              env: environment,
              isInternalUser: true
            },
            configs
          },
          (response) => {
            if (response && response.success) {
              this.$snackbar.open({
                message: 'Extension synced to ' + hostname,
                duration: 10000,
                actionText: ''
              });
            }
          }
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-font-size-6:hover {
  font-weight: 600;
}
</style>
