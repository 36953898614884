<template>
  <div class="u-spacing-ph-m u-width-100">
    <div
      v-if="groupingLabel"
      class="u-display-flex u-line-height-1 u-color-grey-x-light u-font-weight-600 u-font-size-11 u-spacing-mb-xs"
    >
      {{ groupingLabel }}
    </div>
    <div
      class="entity-holder u-border-width-s u-border-left u-border-bottom u-border-top u-border-right u-border-color-grey-xxx-light u-height-100 u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <div
        v-tippy="tippy"
        class="u-width-100 u-spacing-p-m u-height-100 custom-table-header asin-holder-medium"
        :class="{ 'asin-available': asinAvailable }"
        :custom-table-header-column-id="selectedAsin && selectedAsin.entityId"
        :title="selectedAsin && selectedAsin.entityId"
      >
        <div class="u-display-flex">
          <div
            v-tippy="tippy"
            class="u-display-flex"
            :title="'Drag to change the placement'"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small u-spacing-mr-s u-color-grey-light u-cursor-pointer u-user-select-none"
              :icon="'draggable-reorder'"
            />
          </div>
          <rb-select-v2
            class="u-spacing-mr-s u-overflow-auto u-width-100"
            :options="options"
            :search-enabled="true"
            :client-search="false"
            :group-by-key="getGroupByKey"
            :search-listener="searchListener"
            :send-details="true"
            :on-close="entityChanged"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer u-width-100"
            >
              <div
                class="u-flex-1 u-font-size-3 u-font-weight-600 u-text-overflow-ellipsis u-line-height-1"
              >
                <span>{{ selectedAsin && selectedAsin.entityId }}</span>
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                class="u-display-flex u-flex-align-items-center"
                :title="option.entityId"
              >
                {{ option.entityId }}
              </div>
            </template>
          </rb-select-v2>
          <span
            class="u-display-flex"
            @click="removeEntity()"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small u-color-grey-xx-light u-cursor-pointer"
              :icon="'cross'"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
export default {
  name: 'EntityTableHeader',
  components: {
    RbSelectV2
  },
  data() {
    return {
      displayIcon: '',
      alternateIcon: '',
      selectedAsin: null,
      asinAvailable: true,
      tippy: {
        placement: 'top',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      searchText: null,
      options: []
    };
  },
  computed: {
    groupingLabel() {
      if (this.params.groupByKey && this.selectedAsin) {
        return this.selectedAsin[this.params.groupByKey];
      }
      return null;
    },
    getGroupByKey() {
      return this.params.groupByKey;
    }
  },
  created() {
    this.options =
      this.$store.getters[this.params.dropdownGetter][this.params.storeKey]
        ?.data || [];
    const index = this.options.findIndex((item) => {
      return this.params.selectedAsin === item.entityId;
    });
    if (index > -1) {
      this.selectedAsin = this.options[index];
    } else {
      const warehouse =
        this.$store.getters[this.params.dataWarehouseGetter][
          this.params.storeKey
        ];
      this.selectedAsin = warehouse[this.params.selectedAsin];
    }
  },
  methods: {
    async searchListener(searchTerm, context) {
      this.searchText = searchTerm;
      this.options = await this.$store.dispatch(this.params.searchAction, {
        searchText: searchTerm,
        storeKey: this.params.storeKey,
        mutationDisable: true
      });
      console.log(this.options);
    },
    entityChanged(context, selectedOption) {
      if (selectedOption.length > 0) {
        this.selectedAsin.order = this.params.keyOrder;
        const orders = this.sortOrderColumns();
        const index = orders.findIndex((item) => {
          return this.selectedAsin.entityId === item;
        });
        this.$store.dispatch(this.params.deleteColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
        this.selectedAsin = selectedOption[0];
        this.selectedAsin.main_order = index + 1;
        this.selectedAsin.replace = true;
        this.$store.dispatch(this.params.addNewColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
      }
    },
    removeEntity() {
      this.asinAvailable = false;
      setTimeout(() => {
        this.$store.dispatch(this.params.deleteColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
      });
    },
    sortOrderColumns() {
      const domList = document.querySelectorAll('.custom-table-header');
      const list = [];
      for (let i = 0; i < domList.length; i++) {
        list.push(domList[i].getAttribute('custom-table-header-column-id'));
      }
      return list;
    }
  }
};
</script>

<style lang="css" scoped>
.asin-holder-medium .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 142px;
  width: 208px;
  border-radius: 4px;
}
.asin-holder-medium .asin-image {
  height: 140px;
  max-width: 206px;
}
.asin-holder-medium .asin-title {
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-size: 1.4rem;
  color: #2b333b;
}
.entity-holder {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>
