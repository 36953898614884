<template>
  <div
    class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-width-100"
  >
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <div class="u-display-flex u-flex-direction-column">
        <div
          class="u-display-flex u-flex-align-items-center u-flex-wrap-yes u-spacing-mb-xl"
        >
          <div
            v-for="(item, i) in allGoals"
            :key="i"
            class="u-spacing-pr-m"
          >
            <rb-button
              :click-fn="() => selectGoal(i)"
              :text="item.label"
              :size="'l'"
              :class="{ 'rb-button--hollow-blue': item.isSelected }"
              class="u-cursor-pointer selected-outlined-btn"
            />
          </div>
        </div>
        <div>
          <CollapsibleTitle
            class="u-spacing-pr-s"
            title="Adjust your Strategy to achieve your goal"
            :is-expanded="strategyAdjustExpand"
            title-font-class="u-font-size-5 u-font-weight-600"
            :icon-class="!strategyAdjustExpand ? 'rotate-270' : ''"
            @click="() => (strategyAdjustExpand = !strategyAdjustExpand)"
          />
          <p
            class="u-font-size-5 u-color-grey-lighter u-font-weight-400 u-spacing-mt-s u-spacing-mb-xl u-spacing-ml-l u-line-height-1-5"
          >
            Using below parameters, you can tweak what priority needs to be
            given to different metrics while allocating budget. <br />
            For e.g.: Allocating more chips to ROAS means that distribution will
            consider ROAS improvement to be the highest priority.
          </p>
          <div
            v-if="strategyAdjustExpand"
            class="u-spacing-mt-xl u-spacing-mb-s u-spacing-ml-l"
          >
            <div class="u-display-flex">
              <div
                class="u-display-flex u-flex-direction-column u-spacing-mr-xl u-spacing-pr-xl"
              >
                <p
                  class="u-font-size-5 u-font-weight-600 u-spacing-pb-s u-spacing-ml-auto"
                >
                  <span
                    :class="
                      numberOfChipsSelected === 5
                        ? 'u-color-blue-base'
                        : 'u-color-orange-base'
                    "
                    >{{ numberOfChipsSelected }}</span
                  >/5 Chips Allocated
                </p>
                <div
                  class="u-flex-1 u-border-radius-s u-border-width-s u-border-all u-border-color-grey-xxx-light u-spacing-p-m"
                >
                  <p class="u-spacing-pb-s u-font-size-6 u-font-weight-600">
                    Select a strategy:
                  </p>
                  <RbSelectV2
                    class="u-width-160px u-spacing-mb-l u-border-radius-s u-border-width-s u-border-all u-border-color-grey-xxx-light"
                    :on-close="onStrategyChange"
                    :options="strategyOptions"
                    :search-enabled="false"
                    :send-details="true"
                    :search-enable-threshold="100"
                  >
                    <div
                      slot="trigger"
                      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                      >
                        <div class="u-display-flex u-flex-align-items-center">
                          <span class="u-font-size-7">{{
                            selectedStrategyTitle
                          }}</span>
                        </div>
                      </div>
                      <rb-icon
                        class="rb-icon--medium u-spacing-mr-s u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div>
                        <p
                          class="u-font-size-6 u-font-weight-600 u-spacing-pb-xs"
                        >
                          {{ option.title }}
                        </p>
                        <span class="u-font-size-7 u-color-grey-mid-light">{{
                          option.tooltip
                        }}</span>
                      </div>
                    </template>
                  </RbSelectV2>
                  <div
                    v-for="item in Object.keys(priority)"
                    :key="item"
                    class="u-display-flex u-flex-align-items-center u-spacing-mb-l"
                  >
                    <p class="u-width-200px u-font-size-5 u-font-weight-600">
                      <span class="u-color-grey-lighter">{{
                        categoriesMap[item][0]
                      }}</span
                      >:
                      {{ categoriesMap[item][1] }}
                    </p>
                    <div
                      v-for="(__, j) in new Array(5).fill(0)"
                      :key="j"
                      class="priority-circle u-spacing-mr-l"
                      :class="{
                        'priority-circle-selected': j + 1 <= priority[item],
                        'priority-circle-available': isChipAvailable(j, item)
                      }"
                      :draggable="j + 1 === priority[item]"
                      @dragstart="startDrag($event, item)"
                      @dragend="() => (draggedItem = '')"
                      @drop="
                        isChipAvailable(j, item) ? onDrop($event, item) : null
                      "
                      @dragover.prevent
                      @dragenter.prevent
                      @click="
                        () => togglePriority(item, j + 1 <= priority[item], j)
                      "
                    />
                  </div>
                </div>

                <div
                  class="u-spacing-mt-s u-display-flex u-flex-align-items-center u-font-size-5 u-color-grey-lighter"
                >
                  <rb-icon
                    class="rb-icon--medium u-cursor-pointer u-spacing-mr-xs"
                    icon="info-circle-fill"
                  />
                  <div class="u-line-height-1-3">
                    Click on empty field to allocate a chip and click back to
                    remove.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import {
  strategyMap,
  categoriesMap,
  goalOptions
} from '@/components/ams/media-planner/config.js';
import { cloneDeep } from 'lodash';
export default {
  components: {
    CollapsibleTitle,
    RbSelectV2
  },
  props: {
    storeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateKeyInStore: {
      type: Function,
      default: () => {
        return () => null;
      }
    }
  },
  data() {
    return {
      strategyAdjustExpand: true,
      allGoals: [],
      priority: {},
      strategyOptions: [],
      categoriesMap: {},
      strategy: '',
      selectedStrategyTitle: '',
      strategyDescription: '',
      draggedItem: ''
    };
  },
  computed: {
    numberOfChipsSelected() {
      return Object.values(this.priority).reduce((sum, num) => sum + num, 0);
    }
  },
  watch: {
    priority: {
      handler(value) {
        this.updateKeyInStore('priority', value);
      },
      deep: true
    },
    strategy(value) {
      this.updateKeyInStore('strategy', value);
    }
  },
  created() {
    const { goal, strategy, priority } = this.storeData;
    this.categoriesMap = categoriesMap;
    let firstStrategy = strategy;
    this.strategyOptions = Object.keys(strategyMap).map((id) => {
      if (!firstStrategy) {
        firstStrategy = id;
      }
      return {
        title: strategyMap[id].title,
        value: id,
        tooltip: strategyMap[id].tooltip
      };
    });
    this.allGoals = cloneDeep(Object.values(goalOptions));
    if (goal) {
      const selectedIndex = goalOptions[goal].index;
      this.selectGoal(selectedIndex, false);
    } else {
      this.updateKeyInStore('goal', this.allGoals[0].value);
    }
    this.strategy = strategy || firstStrategy;
    this.selectedStrategyTitle = strategyMap[strategy || firstStrategy].title;
    this.strategyDescription =
      strategyMap[strategy || firstStrategy].description;
    this.priority = strategy
      ? priority
      : { ...strategyMap[firstStrategy].chipsAllocations };
  },
  methods: {
    isChipAvailable(index, item) {
      return (
        (index + 1 > this.priority[item] &&
          this.priority[item] + (5 - this.numberOfChipsSelected) >=
            index + 1) ||
        (this.draggedItem &&
          this.draggedItem !== item &&
          index + 1 === this.priority[item] + 1)
      );
    },
    onStrategyChange(__, strategy) {
      const updatedData = { ...this.storeData };
      const { value, title } = strategy[0];
      if (title !== this.strategy) {
        this.strategy = value;
        this.selectedStrategyTitle = title;
        this.strategyDescription = strategyMap[value].description;
        this.priority = { ...strategyMap[value].chipsAllocations };
        updatedData.strategy = value;
      }
    },
    selectGoal(selectedGoal, updateStore = true) {
      this.allGoals.forEach((item) => {
        item.isSelected = false;
      });
      const { isSelected } = this.allGoals?.[selectedGoal] || {};
      this.allGoals[selectedGoal].isSelected = !isSelected;
      if (updateStore)
        this.updateKeyInStore('goal', this.allGoals[selectedGoal].value);
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', item);
      this.draggedItem = item;
    },
    onDrop(evt, item) {
      if (this.strategy !== 'custom') {
        this.strategy = 'custom';
        this.selectedStrategyTitle = 'Custom';
      }
      const itemID = evt.dataTransfer.getData('itemID');
      this.priority[itemID] = this.priority[itemID] - 1;
      this.priority[item] = this.priority[item] + 1;
    },
    togglePriority(item, isSelected, index) {
      if (this.strategy !== 'custom') {
        this.strategy = 'custom';
        this.selectedStrategyTitle = 'Custom';
      }
      if (
        !isSelected &&
        5 - this.numberOfChipsSelected >= index + 1 - this.priority[item]
      ) {
        this.priority[item] = index + 1;
      }
      if (isSelected) {
        const chipsToRemove = this.priority[item] - index;
        this.priority[item] = this.priority[item] - chipsToRemove;
      }
    }
  }
};
</script>

<style scoped lang="css">
.selected-outlined-btn,
.selected-outlined-btn:hover {
  border-radius: 50px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 2.4rem;
  border: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  font-weight: 400;
  color: #2b333b;
}
.rb-button--hollow-blue {
  border-color: #007cf6;
  color: #007cf6;
}
.rb-button--hollow-blue:hover {
  border-color: #007cf6;
  color: #007cf6;
  background: none !important;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.roas-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
  height: 24px;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.roas-input {
  border-left: 0;
  padding-left: 4px;
  height: 24px;
  width: 40px;
}

.priority-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e9eaeb;
}

.priority-circle:active {
  cursor: grabbing;
}

.priority-circle-selected {
  background-color: #007cf6;
  border: 1px solid #e9eaeb;
  box-shadow: 0px 0px 4px #caccce;
}

.priority-circle-available {
  background-color: white;
  border: 1px solid #e9eaeb;
  box-shadow: 0px 0px 4px #caccce;
}

.u-width-160px {
  width: 160px;
}

.u-width-200px {
  width: 200px;
}

.u-spacing-ml-auto {
  margin-left: auto;
}
</style>
