<template>
  <div class="tree_container_digital_shelves">
    <div
      v-if="show"
      class="u-width-100 u-border-color-grey-xxx-light u-border-all u-border-width-s u-flex-justify-content-flex-end u-display-flex"
    >
      <div class="u-spacing-pb-s u-width-120px">
        <div
          class="u-display-flex u-width-100 u-flex-justify-content-space-between"
        >
          <div
            class="u-cursor-pointer u-display-flex u-flex-direction-column u-spacing-p-s u-flex-align-items-center u-flex-justify-content-center"
            @click="handleZoom('out')"
          >
            <rb-icon
              icon="remove-fill"
              class="u-color-grey-lighter rb-icon--medium"
            />
            <div class="u-font-size-8 u-spacing-mt-xs">ZOOM OUT</div>
          </div>
          <div
            class="u-cursor-pointer u-spacing-p-s u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
            @click="handleZoom('in')"
          >
            <rb-icon
              icon="add-circle-fill"
              class="u-color-grey-lighter rb-icon--medium"
            />
            <div class="u-font-size-8 u-spacing-mt-xs">ZOOM IN</div>
          </div>
        </div>
        <div class="u-display-flex">
          <input
            v-model="sliderValue"
            type="range"
            :min="minZoom"
            :max="maxZoom"
            step="0.1"
            value="50"
            class="u-width-100 slider"
          />
        </div>
      </div>
      <div>
        <div
          class="u-cursor-pointer u-spacing-p-s u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
          @click="handleZoom('reset')"
        >
          <rb-icon
            icon="revert"
            class="u-color-grey-lighter rb-icon--medium"
          />
          <div class="u-font-size-8 u-spacing-mt-xs">RESET TO DEFAULT</div>
        </div>
      </div>
    </div>
    <vue-tree
      v-if="show"
      ref="tree"
      class="u-width-100 u-height-100 pageBackground"
      :dataset="localData"
      :config="treeConfig"
      link-style="straight"
    >
      <template v-slot:node="{ node, collapsed }">
        <div
          class="tree_node_style_digital_shelves u-display-flex u-bg-color-white-base u-flex-align-items-center u-max-width-320px u-spacing-p-s"
        >
          <div
            v-if="node.level"
            :class="[
              collapsed ? 'u-bg-color-grey-mid-light' : 'u-bg-color-blue-base'
            ]"
            class="u-display-block level"
          >
            <div
              class="u-color-white-base u-flex-align-items-center u-height-100 u-width-100 u-flex-justify-content-center u-display-flex"
            >
              L{{ node.level }}
            </div>
          </div>

          <div class="u-spacing-pv-s u-spacing-pl-s u-spacing-pr-s">
            <div
              class="u-font-size-5 u-text-overflow-ellipsis u-line-height-1-4"
            >
              {{ node.name }}
            </div>
            <div
              v-if="node.keywords"
              class="u-color-grey-lighter u-font-size-8"
            >
              {{ node.keywords }} keywords
            </div>
          </div>
          <div
            v-if="node.children || node._children"
            class="u-display-flex u-spacing-pr-s u-flex-align-items-center"
          >
            <rb-icon
              :class="node.level ? 'u-spacing-ml-s' : ''"
              class="u-flex-0 u-color-grey-lighter rb-icon--medium"
              :icon="collapsed ? 'arrow1-down' : 'arrow1-up'"
            />
          </div>
        </div>
      </template>
    </vue-tree>
  </div>
</template>

<script>
import VueTree from '@ssthouse/vue-tree-chart';
import loader from '@/components/basic/loader.vue';

export default {
  components: {
    VueTree,
    loader
  },
  props: {
    previewData: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      sliderValue: 0.2,
      minZoom: 0.2,
      maxZoom: 1.2,
      show: false,
      localData: {
        name: 'Standard Digital Shelves',
        children: []
      },
      treeConfig: { nodeWidth: 330, nodeHeight: 80, levelHeight: 200 }
    };
  },
  watch: {
    sliderValue(newVal) {
      if (newVal) {
        this.onSliderChange();
      }
    },
    previewData: {
      handler(newVal) {
        this.show = false;
        this.localData.children = this.traverse(newVal, 1);
        this.$nextTick(() => {
          this.show = true;
        });
      },
      deep: true,
      immediate: true
    },
    show(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.attachScroll();
        });
      }
    }
  },
  methods: {
    onSliderChange() {
      const tree = this.$refs.tree;
      tree.setScale(parseFloat(this.sliderValue));
    },
    handleZoom(val) {
      const floatSliderValue = parseFloat(this.sliderValue);
      if (val === 'in' && floatSliderValue < this.maxZoom) {
        this.sliderValue = floatSliderValue + 0.1;
      } else if (val === 'out' && floatSliderValue > this.minZoom) {
        this.sliderValue = floatSliderValue - 0.1;
      }
      if (val === 'reset') {
        this.sliderValue = 0.8;
      }
    },
    attachScroll() {
      const tree = this.$refs.tree;
      this.handleZoom('reset');
      tree.$refs.container.onmousewheel = (event) => {
        if (event.deltaY < 0 && tree.currentScale < this.maxZoom) {
          this.handleZoom('in');
        } else if (event.deltaY > 0 && tree.currentScale > this.minZoom) {
          this.handleZoom('out');
        }
      };
    },
    nodeClicked(node, collapsed) {
      console.log(node, collapsed);
    },
    traverse(root, level) {
      const nodes = [];
      for (const name in root) {
        let children = null;
        const keywords = root[name]?.keywords?.length
          ? root[name].keywords.length
          : 0;
        if (root[name]?.children && !!Object.keys(root[name].children).length) {
          children = this.traverse(root[name].children, level + 1);
        }
        nodes.push({
          name,
          children,
          level,
          keywords
        });
      }
      return nodes;
    }
  }
};
</script>

<style lang="css">
.tree_container_digital_shelves {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tree_container_digital_shelves .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
  height: 8px;
  background: #007cf6;
  cursor: pointer;
}
.tree_container_digital_shelves .slider::-moz-range-thumb {
  width: 2px;
  height: 6px;
  background: #007cf6;
  cursor: pointer;
}
.tree_container_digital_shelves .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #f5f7fa;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.tree_container_digital_shelves .tree_node_style_digital_shelves {
  height: 55px;
  border-radius: 100px;
  box-shadow: 0 0 4px 0 #caccce;
}
.tree_container_digital_shelves .tree_node_style_digital_shelves .level {
  border-radius: 100px;
  width: 30px;
  height: 30px;
}
.tree_container_digital_shelves .tree-container .link {
  stroke-width: 0.5px;
  stroke: #007cf6 !important;
}
</style>
