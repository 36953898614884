<template>
  <div
    class="u-border-radius-l u-spacing-mr-m"
    :class="
      !isFormVisible &&
      'u-bg-color-grey-white u-spacing-mt-m u-spacing-mb-s u-display-flex u-flex-justify-content-center u-flex-align-items-center'
    "
  >
    <div v-if="showSentScreen && !isFormVisible">
      <mailSentScreen @mounted="handleMounted" />
    </div>
    <div v-else>
      <modal
        v-if="showPopUp"
        :modal-config="modalConfig"
        @clickedOutside="clickedOutsideModal"
      />
      <div
        v-if="isFormVisible || hasActiveUsers"
        class="round-corners-bottom u-spacing-pb-m u-flex-4 u-bg-color-grey-white u-spacing-mt-m u-color-grey-light"
      >
        <div
          class="round-corners-top u-display-flex u-flex-justify-content-space-between u-bg-color-grey-base u-spacing-pv-s u-spacing-ph-xl u-color-grey-white u-font-size-5"
        >
          <div class="u-spacing-mh-s u-font-size-4 u-font-weight-600">
            New Report
          </div>
          <span
            class="u-spacing-mh-s u-spacing-p-s rb-icon rb-icon--small u-cursor-pointer icon-cross u-spacing-ml-s u-display-flex u-flex-align-items-center u-flex-justify-content-center"
            @click="openWindowClosePopUp"
          />
        </div>
        <div class="u-spacing-mh-xl">
          <form @submit.prevent="sendEmail()">
            <div
              class="u-spacing-mt-0 u-display-flex u-flex-wrap-yes u-cursor-text u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pt-m"
              :class="!activeUsers.length && 'u-spacing-pb-xs'"
              @click="focusReceipientsText"
            >
              <!-- u-spacing-pt-m  u-spacing-pl-s u-spacing-pb-xs-->
              <span
                v-if="activeUsers.length === 0 && to === ''"
                class="u-font-size-4 u-font-weight-600 u-spacing-pt-s u-color-grey-lighter u-spacing-pl-s"
              >
                Recipients</span
              >
              <div
                v-for="(item, index) in activeUsers"
                :key="index"
                class="u-font-size-4 u-font-weight-600 u-color-grey-lighter"
              >
                <div
                  v-show="item.isActive"
                  class="u-font-size-7 filter--token u-color-grey-mid-light u u-spacing-pt-s u-spacing-ph-m u-spacing-mh-xs u-spacing-mb-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center u-flex-justify-content-space-between"
                >
                  <span>{{ item.mail }}</span>
                  <span
                    class="rb-icon rb-icon--small u-cursor-pointer icon-cross u-spacing-ml-s"
                    @click="removeActiveUser(item.mail)"
                  />
                </div>
              </div>
              <div :style="activeUsers.length && 'min-height:35px'">
                <input
                  ref="receipientTextField"
                  v-model="to"
                  :size="inputBoxSize + 1"
                  class="u-color-grey-mid-light u-font-size-5 u-font-size-4 u-spacing-pl-s u-spacing-pt-s"
                  placeholder=""
                  type="to"
                  name="To"
                  @focus="showSuggestions = true"
                  @blur="showSuggestions = false"
                />
                <div
                  v-if="showSuggestions && suggestions.length"
                  class="u-position-relative"
                >
                  <div
                    class="u-position-absolute u-spacing-ph-m u-spacing-pv-s suggestion-container u-bg-color-grey-white u-overflow-y-auto u-max-height-300"
                  >
                    <div
                      v-for="(suggestion, index) in suggestions"
                      :key="index"
                      class="u-font-size-5"
                    >
                      <receipientComponent
                        :mail="suggestion.mail"
                        :name="suggestion.name"
                        :type="suggestion.type"
                        :show-tippy="false"
                        @toggleReceipient="handleToggleReceipient"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <label
                v-if="formTouched && receipient === ''"
                class="u-color-red-base u-font-size-7"
                >Please enter an email ID.</label
              >
            </div>
            <div
              class="u-spacing-pl-s u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pt-l u-spacing-pb-xs u-width-100"
            >
              <input
                v-model="subject"
                class="u-font-weight-600 u-width-100 u-font-size-4"
                :class="{ error: formTouched && subject === '' }"
                placeholder="Subject"
                type="subject"
                name="subject"
              />
            </div>
            <div>
              <div
                class="u-spacing-pt-xs u-border-width-s u-border-bottom u-border-color-grey-xxx-light"
              >
                <textarea
                  v-model="body"
                  class="u-spacing-mv-xs u-font-size-5 u-color-grey-mid-light u-spacing-p-s u-bg-color-grey-white"
                  :class="{ error: formTouched && body === '' }"
                  rows="14"
                  cols=""
                  type="body"
                  name="body"
                />
              </div>
              <div
                class="u-font-size-5 u-spacing-ph-s u-spacing-pv-s u-font-weight-600 u-color-grey-lighter"
              >
                REPORTS
              </div>
              <div
                class="u-display-flex files-container u-flex-wrap-yes u-spacing-pb-s"
              >
                <div
                  v-for="(file, index) in attachedFiles"
                  :key="index"
                  class="u-font-size-4 u-display-flex u-spacing-ph-s"
                >
                  <fileComponent
                    :filename="file.filename"
                    :file-path="file.filepath"
                    :index="index"
                    :file-upload-status="file.fileUploadStatus"
                    :message="file.message"
                    @deleteFile="handleDelete"
                  />
                </div>
              </div>
              <div class="u-display-flex">
                <div
                  class="u-cursor-pointer u-spacing-ph-s u-spacing-pv-s baseLine rb-button--filled rb-button__size--m rb-button"
                >
                  <rb-button
                    :click-fn="checkMailForIssues"
                    :loading="isSendingMail"
                    :disabled="!(isSendActive && isTextFilled) || isSendingMail"
                    :text="'Send'"
                    :type="'filled'"
                  />
                </div>
                <div
                  class="u-cursor-pointer u-display-flex u-flex-align-items-center"
                >
                  <rb-button
                    :click-fn="openFileViewer"
                    class="u-border-all u-color-blue-base u-color-blue-base u-border-width-s"
                    :type="'hollow'"
                    :text="'Attach File'"
                    :icon-left="'attachment'"
                  />
                  <input
                    id="fileElm"
                    ref="fileElm"
                    type="file"
                    style="display: none"
                    accept=".xlsx"
                    :multiple="false"
                    @change="processFile"
                  />
                </div>
              </div>
              <div
                v-if="
                  isTextFilled &&
                  !isSendActive &&
                  !isUploading &&
                  !isSendingMail &&
                  isCheckedAllFiles
                "
                class="u-cursor-pointer u-spacing-ml-s error-circumvent u-color-blue-base u-font-size-7"
                @click="checkMailForIssues"
              >
                Send Anyway
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-else>
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-center u-flex-align-items-center"
        >
          <img
            style="width: 235px; height: 270px"
            src="/images/mail-validator-start-screen.png"
          />
          <div
            class="u-spacing-mv-m rb-button--filled rb-button__size--l rb-button"
          >
            <rb-button
              :click-fn="showMailForm"
              :text="'Create Report'"
              :type="'filled'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dropArea from './components/dropArea'
import fileComponent from './components/file-component';
import loader from '@/components/basic/loader';
import receipientComponent from './components/receipient-component';
import modal from '@/components/widgets/modal.vue';
import mailSentScreen from './components/mail-sent-screen';
export default {
  components: {
    fileComponent,
    loader,
    receipientComponent,
    modal,
    mailSentScreen
  },
  data() {
    return {
      to: '',
      showSuggestions: false,
      suggestions: [],
      subject: '',
      body: '',
      formTouched: false,
      showPopUp: false,
      modalConfig: {},
      showSentScreen: false,
      inputBoxSize: 1
    };
  },
  computed: {
    selectedReceipients() {
      return this.$store.getters.selectedReceipients;
    },
    sfUsers() {
      return this.$store.getters.sfUsers.rows;
    },
    ldapUsers() {
      return this.$store.getters.ldapUsers.rows;
    },
    attachedFiles() {
      return this.$store.getters.attachedFiles;
    },
    isSendActive() {
      let isActive = true;
      this.$store.getters.attachedFiles.forEach((item) => {
        isActive = item.success && isActive;
      });
      return isActive;
    },
    isTextFilled() {
      return (
        this.subject !== '' && this.body !== '' && this.activeUsers.length !== 0
      );
    },
    isSendingMail() {
      return this.$store.getters.mailStatus.load;
    },
    activeUsers() {
      return [...this.ldapUsers, ...this.sfUsers].filter(
        (item) => item.isActive
      );
    },
    hasActiveUsers() {
      if (this.activeUsers.length) {
        this.showForm();
      }
    },
    isUploading() {
      let isUploading = false;
      this.$store.getters.attachedFiles.forEach((item) => {
        isUploading = item.isUploading || isUploading;
      });
      return isUploading;
    },
    isFormVisible() {
      return this.$store.getters.isEmailFormOpen;
    },
    isCheckedAllFiles() {
      let isActive = true;
      this.$store.getters.attachedFiles.forEach((item) => {
        isActive = item.filepath && isActive;
      });
      return isActive;
    }
  },
  watch: {
    to: function () {
      if (this.to.length >= 1) {
        const activeLdapUsers = this.getSuggestions(
          this.to,
          'mail',
          this.ldapUsers.filter((item) => !item.isActive),
          'ldapUsers'
        );
        const activeSfUsers = this.getSuggestions(
          this.to,
          'mail',
          this.sfUsers.filter((item) => !item.isActive),
          'sfUsers'
        );
        this.suggestions = [...activeLdapUsers, ...activeSfUsers];
      } else {
        this.suggestions = [];
      }
      this.inputBoxSize = this.to.length;
    }
  },
  methods: {
    showMailForm() {
      this.$store.dispatch('showEmailForm');
    },
    hideMailForm() {
      this.$store.dispatch('hideEmailForm');
      this.resetForm();
      this.showPopUp = false;
    },
    handleMounted() {
      setTimeout(() => {
        this.showSentScreen = false;
      }, 3000);
    },
    handleDelete(fileId) {
      this.$store.dispatch('removeFile', fileId);
      this.showPopUp = false;
    },
    openWindowClosePopUp(fileId) {
      this.modalConfig = {
        bodyTitle: {
          text: 'Are you sure?',
          highlightText: '',
          icon: 'email-subscription'
        },
        body: {
          text: 'Closing the report would lose the progress so far.'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showPopUp = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Continue',
            onClick: () => {
              this.hideMailForm();
            },
            icon: '',
            show: true
          }
        }
      };
      this.showPopUp = true;
    },
    openNoIssuesPopUp() {
      this.modalConfig = {
        bodyTitle: {
          text: 'The email has no issues',
          highlightText: '',
          icon: 'email-subscription'
        },
        body: {
          text: 'No issues were found'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showPopUp = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Send!',
            onClick: () => {
              this.sendEmail();
            },
            icon: '',
            show: true
          }
        }
      };
      this.showPopUp = true;
    },
    openIssuesPopUp(issuesSet) {
      const issuesArray = [...issuesSet];
      let issues = '';
      issuesArray.forEach((item, index) => {
        if (index === issuesArray.length - 1) {
          issues += item;
        } else {
          issues += item + ', ';
        }
      });
      this.modalConfig = {
        bodyTitle: {
          text: 'Send Mail?',
          highlightText: '',
          icon: 'email-subscription'
        },
        body: {
          text: `${issues}. Are you sure you want to continue?`
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showPopUp = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Send Anyway',
            onClick: () => {
              this.sendEmail();
            },
            icon: '',
            show: true
          }
        }
      };
      this.showPopUp = true;
    },
    clickedOutsideModal(val) {
      if (val) {
        this.showPopUp = false;
      }
    },
    processFile(e) {
      const file = e.target.files && e.target.files[0];
      if (file) {
        this.handleFiles(file);
        this.$refs.fileElm.value = '';
      }
    },
    handleFiles(file) {
      if (file.name) {
        // validating for xlsx files
        const tempNames = file.name.split('.');
        if (tempNames[tempNames.length - 1] !== 'xlsx') {
          this.$snackbar.open({
            message: 'Please upload a file with XLSX format',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: '',
            customStyles: 'background: #d7263d'
          });
          return;
        }
      } else {
        // no file name
        return;
      }
      const index = this.attachedFiles.findIndex(
        (item) => item.filename === file.name
      );
      if (index >= 0) {
        this.$snackbar.open({
          message: 'File already attached',
          duration: 5000,
          actionText: ' '
        });
      } else {
        this.onAttach(file);
      }
    },
    openFileViewer() {
      this.$refs.fileElm.click();
    },
    handleToggleReceipient(data) {
      this.$store.dispatch('handleReceipientsToggle', data);
      this.to = '';
    },
    getSuggestions(substring, property, usersList, type) {
      return usersList
        .filter((item) =>
          item[property].toLowerCase().startsWith(substring.toLowerCase())
        )
        .map((item) => ({ mail: item.mail, name: item.name, type }));
    },
    focusReceipientsText() {
      this.$refs.receipientTextField.focus();
    },
    removeActiveUser(mail) {
      this.$store.dispatch('removeActiveUser', { mail });
    },
    onAttach(file) {
      const formData = new FormData();
      formData.append(file.name, file);
      this.$store.dispatch('attachFile', { formData, filename: file.name });
    },
    resetForm() {
      this.$store.dispatch('resetMail', {});
      this.subject = '';
      this.body = '';
      this.to = '';
    },
    checkMailForIssues() {
      let issues = 0;
      const set = new Set();
      if (!this.attachedFiles.length) {
        issues++;
        set.add('No files have been attached');
      } else {
        this.attachedFiles.forEach((item) => {
          if (!item.success) {
            set.add(`${item.message} in file ${item.filename}`);
            issues++;
          }
        });
      }
      if (!issues) {
        this.sendEmail();
      } else {
        this.openIssuesPopUp(set);
      }
    },
    sendEmail() {
      const to = this.activeUsers.map((item) => item.mail);
      const filepaths = this.attachedFiles.map((item) => item.filepath);
      const currentUser = window.user.email;
      const payload = {
        to,
        subject: this.subject,
        body: this.body,
        filepaths,
        fromUser: currentUser
      };
      this.to = '';
      this.showPopUp = false;
      this.$store
        .dispatch('sendMailInternalTool', payload)
        .then(() => {
          this.resetForm();
          this.showSentScreen = true;
          this.$snackbar.open({
            message: 'Report sent successfully',
            duration: 5000,
            actionText: ' '
          });
        })
        .catch((err) => {
          console.log('error', err);
          this.$snackbar.open({
            message: "Couldn't send report",
            duration: 5000,
            actionText: ' '
          });
        });
    }
  }
};
</script>

<style lang="css" scoped>
.error-circumvent:hover {
  text-decoration: underline;
}
input {
  border: 0;
  outline: 0;
  width: 100%;
  border-radius: 2px;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}

.suggestion-container {
  z-index: 0;
  box-shadow: 0px 0px 5px 1px #8b8f93;
}

textarea ::placeholder {
  color: #caccce;
}

.link:hover {
  color: #007cf6;
  text-decoration: none;
}

textarea {
  resize: none;
  border: 0 none;
  width: 100%;
  outline: none;
  height: 100%;
}

.files-container {
  min-height: 160px;
}
::placeholder {
  font-weight: 600;
  color: #8b8f93;
  opacity: 1 !important;
}
.round-corners-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.round-corners-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
