<template>
  <div>
    <div class="u-display-flex u-spacing-mt-xs">
      <rb-button
        v-if="showLeftBtn"
        style="min-width: 93px"
        :text="btnLeftText"
        type="hollow"
        :class="customClassLeft"
        :click-fn="btnLeftClick"
        :disabled="disableLeftBtn"
        class="u-spacing-mr-m"
      />
      <slot name="center" />
      <rb-button
        v-if="showRightBtn"
        style="min-width: 93px"
        :text="btnRightText"
        :class="customClassRight"
        :disabled="disableRightBtn"
        type="filled"
        :click-fn="btnRightClick"
      />
    </div>
    <div
      v-if="showSkipStep"
      class="u-spacing-mt-m u-font-size-5 u-cursor-pointer"
      style="color: #4a90e2"
      @click="skipStep"
    >
      {{ skipStepText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customClassLeft: {
      type: String,
      default: ''
    },
    customClassRight: {
      type: String,
      default: ''
    },
    showLeftBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    },
    btnRightText: {
      type: String,
      default: 'Next'
    },
    btnLeftText: {
      type: String,
      default: 'Back'
    },
    skipStepText: {
      type: String,
      default: 'Skip step'
    },
    disableLeftBtn: {
      type: Boolean,
      default: false
    },
    disableRightBtn: {
      type: Boolean,
      default: false
    },
    showSkipStep: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    btnLeftClick() {
      this.$emit('onClickBtnLeft');
    },
    btnRightClick() {
      this.$emit('onClickBtnRight');
    },
    skipStep() {
      this.$emit('onClickSkipStep');
    }
  }
};
</script>

<style lang="css" scoped>
.rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
</style>
