<template>
  <div class="u-height-100 u-width-220 textWithButton u-color-grey-base">
    <p
      v-if="params.tooltip"
      v-tippy="tippy"
      :title="
        params.customTooltip ? params.customTooltip(params) : tooltip(params)
      "
      class="u-color-grey-base"
    >
      {{ entityHeaderGenerator }}
    </p>
    <p
      v-else
      v-tippy="tippy"
      class="u-color-grey-base"
      :class="renderParams.textClass"
      :title="entityHeaderGenerator"
    >
      {{ entityHeaderGenerator }}
    </p>
    <div
      v-if="renderParams.hoverRequired === true && !renderParams.isDisable"
      class="cellHover u-font-weight-600 u-spacing-ml-m u-font-size-7 u-color-blue-base"
      @click.stop="renderParams.hoverClickFunction(params)"
    >
      <span class="u-spacing-ml-xs hover-span">{{
        renderParams.hoverText
      }}</span>
    </div>
    <div
      v-if="renderParams.hoverRequired === true && renderParams.isDisable"
      class="disabled-btn u-pointer-events-none cellHover u-font-weight-600 u-spacing-ml-m u-font-size-7 u-color-blue-base"
    >
      <span
        class="u-spacing-ml-xs u-spacing-ml-xs disabled-hover-span u-border-radius-xs u-spacing-p-xs u-cursor-default"
        >{{ renderParams.hoverText }}</span
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TextWithButton',
  components: {},
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      renderParams: {
        hoverText: 'hoverText'
      }
    };
  },
  computed: {
    entityHeaderGenerator() {
      if (
        this.params.data.entityType === this.params.colDef.field &&
        !this.params.data[this.params.colDef.field]
      ) {
        return 'Grouping attribute unavailable';
      }
      return this.params.data[this.params.colDef.field];
    }
  },
  created() {
    if (
      this.params?.renderParams !== undefined &&
      !this.params?.renderParams?.asin
    ) {
      this.renderParams = this.params?.renderParams;
    }
  },
  methods: {
    tooltip(params) {
      return params.tooltip.tooltipMessage;
    }
  }
});
</script>

<style lang="css" scoped>
.ag-cell:hover .textWithButton .cellHover {
  display: inline-flex;
}

.textWithButton:hover .cellHover {
  display: inline-flex;
}

.cellHover .hover-span {
  padding: 3px 5px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid #007cf6;
}

.cellHover:hover .hover-span {
  color: white;
  background-color: #007cf6;
}

.u-width-220 {
  width: 220px;
}

.ag-row-selected .ag-column-hover .cellHover {
  background-color: #e6f3ff;
}
.disabled-hover-span {
  background-color: #8b8f93;
  color: #fff;
}
</style>
