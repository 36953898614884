<template>
  <div
    ref="sot_container_ref"
    class="sot_ingestion_container u-flex-1 u-display-flex u-width-100"
  >
    <ingestion-welcome
      v-if="journeyStage === 'ingestion-success'"
      image-path="/images/ingestion-success.png"
      description-icon="check-fill-circle"
      :description="textConfigs.success.text"
      :sub-text="successSubText"
    >
      <div slot="primaryButton">
        <rb-button
          :click-fn="() => redirectToSpecificStage()"
          class="u-width-100 u-spacing-mt-m"
          :text="'Redirect Now'"
          :type="'filled'"
        />
      </div>
    </ingestion-welcome>
    <ingestion-welcome
      v-if="journeyStage === 'welcome'"
      :button-text="textConfigs.welcome.ButtonText"
      :description="textConfigs.welcome.Description"
      :loading="isEntityLoading"
      @launchTriggered="launchTriggered"
    />
    <collapsible-page
      v-if="['bulk_upload', 'select-upload-type'].includes(journeyStage)"
      close-icon="right-arrow"
      close-icon-classes="flipIcon rb-icon--large"
      :page-title="textConfigs.page.Title"
      :page-description="textConfigs.page.Description"
      :close-icon-styles="{ padding: '0.5rem' }"
      @closePage="catalogViewClose"
    >
      <bulk-change
        v-if="
          journeyStage === 'bulk_upload' ||
          journeyStage === 'select-upload-type'
        "
        class="u-spacing-ph-l"
        :loading="isEntityLoading"
        :accepted-file-format="acceptedFileFormat"
        :current-stage="uploadStatus"
        :text-configs="bulkUploadText"
        :inner-components="upload.innerComponents[journeyStage]"
        :file-details="getEntityFileDetails"
        :error-list="errorList"
        :hide-footer="isReuploadScreen"
        :show-status="!isReuploadScreen"
        :warning-text="bulkChangeWarningText"
        :journey-stage="journeyStage"
        :info-section="textConfigs.bulkUploadInfoSection"
        :right-icon="isReuploadScreen ? '' : 'check-fill-circle'"
        @footerClicked="footerClicked"
        @tooltipClicked="tooltipClicked"
        @onUpload="onUpload"
        @onNext="onNext"
        @onBack="onBack"
        @removeFile="onFileRemove"
        @downloadTemplate="downloadTemplate"
        @onCancel="onBack"
      />
    </collapsible-page>
    <preview
      :show-component="journeyStage === 'preview'"
      :all-rows="previewTable.rows"
      :column-defs="previewTable.columns"
      :loading="isEntityLoading"
      :table-config="tableConfig"
    >
      <span
        slot="action_header"
        class="u-display-flex u-width-100 u-spacing-pv-m u-flex-align-items-center"
      >
        <span class="u-flex-1" />
        <!-- <div
          slot="preDatePicker"
          class="u-border-right u-border-color-grey-xxx-light u-border-width-s"
        >
          <div
            v-tippy="{ placement : 'top', arrow: false }"
            class="u-border-radius-xs u-spacing-p-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-m u-bg-color-grey-white u-display-flex u-height-36px"
            title="Know more about this dashboard"
            expoter="display-none"
            @click="openHelpDialog"
          >
            <rb-icon
              class="u-color-grey-lighter"
              :icon="'info-circle-fill'"
            />
          </div>
        </div> -->
        <span
          class="u-border-width-s u-border-color-grey-xxx-light u-spacing-ph-m"
        >
          <rb-button
            slot="trigger"
            text="Overwrite"
            class="u-spacing-p-m edit-catalog-button"
            size="s"
            icon-left="pencil"
            type="filled"
            :click-fn="enableReUploadFlow"
          />
        </span>
        <span
          class="u-display-flex u-border-width-s u-border-left u-border-color-grey-xxx-light u-spacing-ph-m"
        >
          <div
            v-for="(iconInfo, key) in manageEntityOptions.actions"
            :key="key"
            class="u-display-flex u-flex-direction-column u-spacing-m-xs taxonomy-icon-button u-color-grey-lighter"
            style="marginright: -1.5rem"
            @click="actionIconClick(iconInfo)"
          >
            <rb-icon
              class="rb-icon--medium u-cursor-pointer"
              :class="iconInfo.IconClassName"
              :icon="iconInfo.icon"
            />
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              class="u-spacing-pt-xs"
              :title="iconInfo.icon === 'download' ? iconInfo.toolTipText : ''"
            >
              <span
                class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-cursor-pointer"
                :class="iconInfo.textClassName"
                >{{ iconInfo.text }}</span
              >
            </div>
          </div>
        </span>
      </span>

      <div
        v-if="getEntityFileDetails.lastUpdatedAt"
        slot="table_footer"
        class="u-font-size-6 u-spacing-pv-s u-width-100 u-spacing-ph-l u-display-flex u-flex-align-items-center u-color-grey-base u-border-width-s u-border-top u-border-color-grey-xxx-light u-flex-justify-self-flex-end"
      >
        <div class="u-width-100 u-display-flex u-flex-align-items-center">
          <div class="no-wrap u-color-grey-x-light u-font-size-7">
            Last Updated:
            {{ formatter(getEntityFileDetails.lastUpdatedAt, 'datetime') }}
          </div>
          <slot name="footer-right" />
        </div>
      </div>
    </preview>
    <sot-option-selection
      v-if="isJourneyStage('sot-option-selection')"
      :is-sot-loading="isSotLoading"
      :sot-type="sotType"
      @handleSotOptionSelection="navigateToJourneyStage"
    />
    <sot-data-preview
      v-if="isJourneyStage('sot-data-preview')"
      :sot-type="sotType"
      :loading="isButtonLoading"
      @handleManageDataSource="getJourneyStage"
      @handleDownload="downloadExistingFile"
    />
    <sot-generation-status
      v-if="isJourneyStage('sot-generation-status')"
      :sot-type="sotType"
      :does-sot-exists="doesSotExists"
      :sot-generation-initial-status="sotGenerationInitialStatus"
      @handleBackToSourceSelections="navigateToJourneyStage"
      @handleBackToExistingSotTable="navigateToJourneyStage"
      @proceedToCreateOrReplaceSot="startDataSwitchPolling"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import ingestionWelcome from '@/components/basic/welcomeImageBannerButton.vue';
import bulkChange from '@/components/widgets/upload/bulkChange';
import preview from './preview.vue';
import { formatter } from '@/utils/helpers/formatter.js';
// import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';
import HttpService from '@/utils/services/http-service';
import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import loader from '@/components/basic/loader';
import { cloneDeep, capitalize } from 'lodash';
import reUploadSelect from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/reUploadSelect.vue';
import {
  downloadLinkAsFile,
  downloadExcelFromS3Csv
} from '@/utils/helpers/downloader';
import sotDataPreview from '@/pages/toolsAndSettings/entityTagging/common/sourceOfTruth/sotDataPreview/sotDataPreview.vue';
import sotGenerationStatus from '@/pages/toolsAndSettings/entityTagging/common/sourceOfTruth/sotGenerationStatus/sotGenerationStatus.vue';
import sotOptionSelection from '@/pages/toolsAndSettings/entityTagging/common/sourceOfTruth/sotOptionSelection/sotOptionSelection.vue';

export default {
  name: 'SourceOfTruth',
  components: {
    ingestionWelcome,
    bulkChange,
    collapsiblePage,
    // confirmDialog,
    // HelpDocumentDialog,
    preview,
    sotDataPreview,
    sotGenerationStatus,
    sotOptionSelection
  },
  props: {
    sotType: {
      type: String,
      default: ''
    },
    entityType: {
      type: Object,
      default() {
        return {};
      }
    },
    bulkChangeWarningText: {
      type: String,
      default:
        'Uploading a new excel sheet will overwrite all the existing catalog filters.'
    },
    path: {
      type: String,
      default: 'catalog/standard/tag'
    },
    textConfigs: {
      type: Object,
      default() {
        return {};
      }
    },
    columnDefs: {
      type: Array,
      default() {
        return [];
      }
    },
    apiConfig: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    // const widgetConfigInstance = widgetConfig.config();
    // const manageTaxonomyTableColumnDefs = transformer.getColumnDefinition(dynamicTableWidget.config().widgets.selectTaxonomy.columnDefs);
    return {
      sessionIdentifier: null,
      timeKeeper: {
        loadStart: null,
        ingestionCompleteTime: null
      },
      startUploaderTime: null,
      redirectCountdownTimer: 10,
      // showHelpDialog: false,
      // showConfirmPopup: true,
      CONSTANTS: {
        SUCCESS_BANNER_TIME: 10000
      },
      journeyStage: 'welcome',
      acceptedFileFormat: ['.xlsx', '.xls'],
      entityData: cloneDeep(this.entityType),
      upload: {
        currentType: this.doesEntityExist ? 'overwrite' : 'create',
        innerComponents: {
          'select-upload-type': [
            {
              name: 'reUploadSelect',
              props: {
                text: `Overwrite ${
                  this.entityType?.entity === 'content' ? 'Content' : 'Variant'
                } Source Of truth`
              },
              events: {
                onReUploadOptionSelect: this.onReUploadOptionSelect,
                onDownloadPress: this.downloadExistingFile
              }
            }
          ],
          bulk_upload: [
            {
              name: 'rb-button',
              props: {
                text: 'Remove',
                clickFn: this.onFileRemove,
                class: 'u-bg-color-blue-base u-spacing-ph-l u-color-grey-white'
              },
              events: {
                click: this.onFileRemove
              }
            }
          ]
        }
      },
      pageJourney: [],
      uploadStatusData: 'upload',
      innerComponents: [
        {
          name: 'reUploadSelect',
          events: {
            onReUploadOptionSelect: this.onReUploadOptionSelect,
            onDownloadPress: this.downloadTemplate
          }
        }
      ],
      errorListData: [],
      validationResponse: {
        errors: [],
        data: [],
        bValidationSuccess: false,
        fileDetails: {
          fileBuffer: null,
          name: null,
          lastModified: null,
          lastModifiedDate: null
        },
        success: true
      },
      errorContainerClass:
        'u-width-100 u-display-flex u-flex-align-items-center u-spacing-p-xl u-flex-justify-content-space-between u-border-radius-s errorBorder',
      openActivity: false,
      manageTaxonomyState: 0,
      enableColumnCreate: false,
      manageEntityOptions: {
        actions: [
          {
            icon: 'download',
            text: 'DOWNLOAD',
            toolTipText: `Downloads an Excel with the existing Source of Truth for ${capitalize(
              this.entityType.entity
            )} values.`,
            IconClassName: '',
            internalFnTrigger: this.downloadExistingFile
          }
        ]
      },
      isSotLoading: true,
      sotGenerationInitialStatus: 'PENDING',
      doesSotExists: false,
      isButtonLoading: false,
      timeout: null
    };
  },
  computed: {
    isReuploadScreen() {
      return this.journeyStage === 'select-upload-type';
    },
    successSubText() {
      return `<span class="u-font-style-italic">Redirecting to Source of Truth for ${capitalize(
        this.entityType.entity
      )} Overview in <span class="u-color-blue-base"">${
        this.redirectCountdownTimer
      }...</span></span>`;
    },
    tableConfig() {
      return {
        entity: this.entityData.entity,
        meta: { action: 'reactToPagination' },
        totalRows: this.totalRows,
        body: {
          APIConfig: {
            apiConfig: this.apiConfig,
            page: this.entityStore.page,
            limit: this.entityStore.limit
          }
        }
      };
    },
    isEntityLoading() {
      return this.$store.getters.getEntityLoadingState(this.entityData.entity);
    },
    entityStore() {
      return this.$store.getters.getEntityStore(this.entityData.entity);
    },
    doesEntityExist() {
      return this.$store.getters.getEntityExists(this.entityData.entity);
    },
    previewData() {
      const preview = this.$store.getters.getEntityPreviewData(
        this.entityData.entity
      )?.rows;
      return Array.isArray(preview) ? preview : [];
    },
    totalRows() {
      const totalRows = this.$store.getters.getEntityPreviewData(
        this.entityData.entity
      )?.totalRows;
      return totalRows || 0;
    },
    getEntityFileDetails() {
      const fileInfo = this.$store.getters.getEntityFileDetails(
        this.entityData.entity
      );
      if (
        (fileInfo && !fileInfo.name) ||
        this.journeyStage === 'select-upload-type'
      ) {
        fileInfo.name = this.entityType.sheetName;
        fileInfo.lastModifiedDate = '';
      }
      if (this.uploadStatus === 'loading' && this.entityData?.file) {
        fileInfo.name = this.entityData.file.name;
      }
      return fileInfo || {};
    },
    uploadStatus() {
      if (this.journeyStage === 'select-upload-type') {
        return 'success';
      }
      return this.uploadStatusData;
    },
    errorList() {
      return this.$store.getters.getEntityErrorList(this.entityData.entity);
    },
    previewTable() {
      return {
        rows: this.previewData,
        columns: this.columnDefs
      };
    },
    bulkUploadText() {
      return {
        ...this.textConfigs.bulkUpload,
        nextButtonText: !this.doesEntityExist ? 'Create' : 'Overwrite'
      };
    }
  },
  watch: {
    journeyStage(newVal) {
      this.pageJourney.push(newVal);
      if (newVal === 'ingestion-success') {
        this.redirectCountdownTimer = 10;
        this.redirectCountdownTimerLoop();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  created() {
    Vue.component('reUploadSelect', reUploadSelect);
    Vue.component('loader', loader);
    this.sessionIdentifier = new Date().getTime();
    if (this.sotType === 'Content') {
      this.journeyStage = 'sot-option-selection';
      this.getJourneyStage();
    } else {
      this.$store
        .dispatch('fetchEntityExistsStatus', this.entityData)
        .then(() => {
          if (this.doesEntityExist) {
            this.journeyStage = 'preview';
            this.readExistingEntity();
          } else {
            this.journeyStage = 'welcome';
          }
        })
        .catch(() => {
          this.$snackbar.open({
            message: 'Something Went Wrong!',
            duration: 5000,
            actionText: '',
            customStyles: 'background-color: #d7263d !important'
          });
        });
    }
  },
  methods: {
    formatter: formatter,
    footerClicked(event) {
      if (event.srcElement.id === 'sot-info-download-trigger') {
        this.downloadTemplate();
      }
    },
    // openHelpDialog () {
    //   this.showHelpDialog = true;
    // },
    // closeCallback () {
    //   this.showHelpDialog = false;
    // },
    tooltipClicked(event) {
      if (event.srcElement.id === 'open-catalog-page') {
        window.open(window.location.href, '_blank');
      }
    },
    redirectToSpecificStage() {
      if (this.sotType === 'Content') {
        this.journeyStage = 'sot-data-preview';
      } else {
        this.journeyStage = 'preview';
      }
    },
    // Timer to redirect the success page to preview page after 10 seconds
    redirectCountdownTimerLoop() {
      if (this.redirectCountdownTimer > 0) {
        setTimeout(() => {
          this.redirectCountdownTimer--;
          this.redirectCountdownTimerLoop();
        }, 1000);
      } else if (this.redirectCountdownTimer === 0) {
        this.redirectToSpecificStage('preview');
      }
    },
    downloadExistingFile() {
      this.$store
        .dispatch('downloadEntity', { entity: this.entityData.entity })
        .then(() => {
          const downloadLink = this.$store.getters.getEntityDownloadLink(
            this.entityData.entity
          );
          // downloadLink = 's3://taxonomy-and-tags/development/digital-shelves/product1Standard Digital Shelf - User Test Upload.csv';
          if (!downloadLink) {
            this.$snackbar.open({
              message: 'Error. Download Link Empty. Please try again later',
              duration: 5000,
              actionText: ''
            });
            return;
          }
          this.$store.dispatch('setEntityLoading', {
            entity: this.entityData.entity,
            isLoading: true
          });
          const fileName = downloadLink.match(/.*\/(.*)\..*/)[1];
          downloadExcelFromS3Csv(downloadLink, fileName).then(() => {
            this.$logger.logEvent({
              eventType: 'download_existing_entity',
              entityType: this.entityData.entity
            });
            this.$store.dispatch('setEntityLoading', {
              entity: this.entityData.entity,
              isLoading: false
            });
          });
        });
    },
    actionIconClick(iconInfo) {
      if (iconInfo.internalFnTrigger) {
        iconInfo.internalFnTrigger();
      }
    },
    onReUploadOptionSelect(selectedOption) {
      if (!selectedOption.key) {
        return;
      }
      this.upload.currentType = selectedOption.key;
      this.journeyStage = 'bulk_upload';
      this.uploadStatusData = 'upload';
    },
    readExistingEntity() {
      this.$store
        .dispatch('fetchSOTRead', {
          entity: this.entityData.entity,
          apiConfig: this.apiConfig
        })
        .catch((err) => {
          console.error(err);
          this.$snackbar.open({
            message: 'Something Went Wrong with upload. Please try again later',
            duration: 5000,
            actionText: ''
          });
        });
    },
    launchTriggered() {
      this.journeyStage = 'bulk_upload';
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'ingestion_init',
        entityType: this.entityData.entity,
        type: 'fresh'
      });
    },
    onUpload(droppedFile) {
      const entityData = cloneDeep(this.entityData);
      entityData.file = droppedFile;
      this.entityData = entityData;
      this.uploadStatusData = 'loading';
      entityData.replaceAll = true;
      // debugger;
      let timeTakenToReachStep =
        window.performance.now() - this.timeKeeper.loadStart;
      this.$logger.logEvent({
        eventType: 'ingestion_upload_init',
        entityType: this.entityData.entity,
        sessionIdentifier: this.sessionIdentifier,
        timeTakenToReachStep
      });
      this.$store
        .dispatch('triggerSOTIngestion', entityData)
        .then((response) => {
          this.validationResponse = response;
          if (this.entityData.file) {
            if (response.success) {
              this.uploadStatusData = 'success';
              // show success snackbar with droppedFile name
              timeTakenToReachStep =
                window.performance.now() - this.timeKeeper.loadStart;
              this.$logger.logEvent({
                eventType: 'ingestion_validation_success',
                entityType: this.entityData.entity,
                sessionIdentifier: this.sessionIdentifier,
                timeTakenToReachStep
              });
              const msg = `<div class="u-display-flex u-flex-align-items-center"><div class="rb-icon icon-check-fill-circle rb-icon--small u-spacing-mr-xs"></div><span class="u-font-weight-bold">${droppedFile.name}</span><div>, uploaded successfully!</div></div>`;
              this.$snackbar.open({
                message: msg,
                duration: 5000,
                actionText: '',
                customStyles: 'background-color: #3ea95e !important'
              });
            } else {
              this.uploadStatusData = 'error';
              timeTakenToReachStep =
                window.performance.now() - this.timeKeeper.loadStart;
              this.$logger.logEvent({
                eventType: 'ingestion_upload_error',
                entityType: this.entityData.entity,
                sessionIdentifier: this.sessionIdentifier,
                errorList: this.errorList,
                timeTakenToReachStep
              });
              this.errorList.forEach((errorItem) => {
                this.$logger.logEvent({
                  eventType: 'ingestion_upload_error_item',
                  entityType: this.entityData.entity,
                  sessionIdentifier: this.sessionIdentifier,
                  errorDescription: errorItem.errorDescription,
                  errorEntity: errorItem.errorEntity
                });
              });
            }
          }
        })
        .catch(() => {
          this.uploadStatusData = 'error';
          this.$snackbar.open({
            message: 'Something Went Wrong. Please try again!',
            duration: 5000,
            actionText: ''
          });
        });
    },
    onFileRemove(event) {
      this.uploadStatusData = 'upload';
      this.entityData = cloneDeep(this.entityType);
    },
    downloadTemplate(event) {
      const url = this.$store.getters.getEntityTemplateFile(
        this.entityData.entity
      );
      downloadLinkAsFile(url);
    },
    onBack() {
      const prevPageIndex = this.pageJourney.indexOf(this.journeyStage) - 1;
      if (prevPageIndex >= 0) {
        this.journeyStage = this.pageJourney[prevPageIndex];
      } else {
        window.history.back();
      }
    },
    catalogViewClose() {
      if (this.sotType === 'Content') {
        this.journeyStage = 'sot-option-selection';
      } else {
        this.readExistingEntity();
        if (this.doesEntityExist) {
          this.journeyStage = 'preview';
        } else {
          this.journeyStage = 'welcome';
        }
      }
    },
    onNext() {
      const entityData = cloneDeep(this.entityData);
      entityData.replaceAll = true;
      entityData.skipPostTrigger = false;
      this.$store
        .dispatch('triggerSOTIngestion', entityData)
        .then(() => {
          this.$store.dispatch('resetEntity', {
            ...cloneDeep(this.entityData),
            page: 1,
            limit: 10
          });
          if (this.sotType === 'Content') {
            this.journeyStage = 'ingestion-success';
            setTimeout(function () {
              this.journeyStage = 'sot-data-preview';
            }, this.CONSTANTS.SUCCESS_BANNER_TIME);
          } else {
            this.$store
              .dispatch('fetchEntityExistsStatus', this.entityData)
              .then(() => {
                this.journeyStage = 'ingestion-success';
                this.readExistingEntity();
                const timeToIngest =
                  window.performance.now() - this.timeKeeper.loadStart;
                this.$logger.logEvent({
                  eventType: 'ingestion_success',
                  entityType: this.entityData.entity,
                  sessionIdentifier: this.sessionIdentifier,
                  timeToIngest
                });
                setTimeout(() => {
                  this.journeyStage = 'preview';
                }, this.CONSTANTS.SUCCESS_BANNER_TIME);
              });
          }
        })
        .catch((err) => {
          this.$store
            .dispatch('fetchEntityExistsStatus', this.entityData)
            .then(() => {
              // temp to be removed
              this.journeyStage = 'preview';
            });
          console.error(err);
          this.$snackbar.open({
            message: 'Something Went Wrong with upload. Please try again later',
            duration: 5000,
            actionText: ''
          });
        });
    },
    enableReUploadFlow() {
      this.journeyStage = 'select-upload-type';
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'ingestion_init',
        entityType: this.entityData.entity,
        type: 'overwrite'
      });
    },
    isJourneyStage(journeyStage) {
      return this.journeyStage === journeyStage;
    },
    getJourneyStage(entityExistCheck = true) {
      if (this.sotType) {
        const statusApiPayload = {
          sotType: `${this.sotType.toUpperCase()}_AUTHORITY_SOT_GEN`,
          entityExistCheck: entityExistCheck
        };
        this.isButtonLoading = true;
        HttpService.post('ENTITY_METADATA_SERVICE', statusApiPayload, {
          append: '/sot/status'
        })
          .then((response) => {
            this.isSotLoading = false;
            this.isButtonLoading = false;
            if (response.data.status === 'DATA_PRESENT') {
              this.journeyStage = 'sot-data-preview';
              this.doesSotExists = true;
            } else if (
              ['NOT_PRESENT', 'COMPLETED'].includes(response.data.status) ||
              response.data.status === null
            ) {
              this.journeyStage = 'sot-option-selection';
            } else {
              this.sotGenerationInitialStatus = response.data.status;
              this.journeyStage = 'sot-generation-status';
            }
          })
          .catch(() => {
            this.isButtonLoading = false;
            this.isSotLoading = false;
            this.$snackbar.open({
              message: 'Something Went Wrong!',
              duration: 5000,
              actionText: ''
            });
          });
      }
    },
    navigateToJourneyStage(selectedOption) {
      if (selectedOption === 'autogenerate') {
        this.startGeneration();
      } else if (selectedOption === 'upload') {
        this.journeyStage = 'bulk_upload';
      } else if (selectedOption === 'existing-sot') {
        this.journeyStage = 'sot-data-preview';
      } else {
        this.journeyStage = 'sot-option-selection';
      }
    },
    startGeneration() {
      const generateApiPayload = {
        sotType: `${this.sotType.toUpperCase()}_AUTHORITY_SOT_GEN`
      };
      this.isSotLoading = true;
      const that = this;
      HttpService.post('ENTITY_METADATA_SERVICE', generateApiPayload, {
        append: '/sot/generate'
      })
        .then((response) => {
          this.isSotLoading = false;
          if (
            ['INITIATED', 'QUEUED', 'RUNNING', 'FINISHED'].indexOf(
              response.data.status
            ) !== -1
          ) {
            that.sotGenerationInitialStatus = 'PENDING';
          } else {
            that.sotGenerationInitialStatus = 'FAILED';
          }
          that.journeyStage = 'sot-generation-status';
        })
        .catch(() => {
          that.isSotLoading = false;
          that.$snackbar.open({
            message: 'Something Went Wrong!',
            duration: 5000,
            actionText: ''
          });
        });
    },
    getDataSwitch() {
      return new Promise((resolve, reject) => {
        const dataSwitchApiPayload = {
          sotType: `${this.sotType.toUpperCase()}_AUTHORITY_SOT_GEN`,
          entityExistCheck: false
        };
        HttpService.post('ENTITY_METADATA_SERVICE', dataSwitchApiPayload, {
          append: '/sot/dataSwitch'
        })
          .then((response) => {
            return resolve(response.data.status);
          })
          .catch(() => {
            this.$snackbar.open({
              message: 'Something Went Wrong!',
              duration: 5000,
              actionText: '',
              customStyles: 'background-color: #d7263d !important'
            });
          });
      });
    },
    poll: async function (fn, fnCondition, ms) {
      let result = await fn();
      while (fnCondition(result)) {
        await this.wait(ms);
        result = await fn();
      }
      return result;
    },
    wait: function (ms = 1000) {
      const that = this;
      return new Promise((resolve) => {
        that.timeout = setTimeout(resolve, ms);
      });
    },
    startDataSwitchPolling: async function () {
      this.sotGenerationInitialStatus = 'PENDING';
      let response = await this.poll(
        this.getDataSwitch,
        this.pollingCondition,
        5000
      );
      if (response === 'COMPLETED') {
        this.sotGenerationInitialStatus = 'FINISHED';
        this.journeyStage = 'sot-data-preview';
      } else {
        this.sotGenerationInitialStatus = 'FAILED';
      }
    },
    pollingCondition(result) {
      return result === 'PREPARING';
    }
  }
};
</script>

<style lang="css">
.sot_ingestion_container {
  .upload_file_drop_area {
    max-width: 680px;
  }
  .iq_tip_container {
    max-width: 450px;
  }
  .ingestion_bulk_edit_section {
    padding-top: 6.4rem;
    margin-top: 1.6rem;
  }
  .ingestion_work_panel {
    max-width: 750px;
  }
}
.select_taxonomy_section {
  height: 80%;
  width: 80%;
}
.entity_loader {
  z-index: 1002;
  opacity: 0.7;
}
.sot_ingestion_container {
  .tag_item_style_class {
    background-color: rgba(255, 168, 0, 0.1);
  }
  .tag_item_style_class:hover {
    background-color: rgba(255, 168, 0, 1);
  }
}
</style>

<style lang="css">
.edit-catalog-button {
  .rb-icon::before {
    font-size: 12px;
  }
}
.taxonomy-icon-button:hover {
  color: #333;
}
</style>
