export default {
  map: {
    status: {
      title: 'Status',
      toolTipText: 'Status',
      type: 'status',
      page: 'common',
      campaign_keyOrder: 1,
      pinned: 'left',
      showOnUi: true,
      showOnRightPanel: true,
      downloadable: true
      // add tooltip
      // moreDetailsConfig: ['stratBuilder', 'budgetPlanner']
    },
    details_of_action: {
      title: 'Action details',
      toolTipText: 'Action details',
      type: 'icon',
      page: 'common',
      pinned: 'left',
      campaign_keyOrder: 2,
      showOnUi: true,
      downloadable: false
    },
    entity: {
      title: 'Entity',
      toolTipText: 'Entity',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 3,
      pinned: 'left',
      showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    },
    entity_type: {
      title: 'Entity Type',
      toolTipText: 'Entity Type',
      type: 'String',
      page: 'common',
      pinned: 'left',
      campaign_keyOrder: 4,
      showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    },
    campaign_name: {
      title: 'Campaign Name',
      toolTipText: 'Campaign Name',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 5,
      pinned: 'left',
      showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    },
    user_name: {
      title: 'User',
      toolTipText: 'User Name',
      type: 'user',
      page: 'common',
      campaign_keyOrder: 6,
      showOnUi: true,
      downloadable: true
    },
    run_date: {
      title: 'Date',
      toolTipText: 'Date of the action',
      type: 'date',
      page: 'common',
      campaign_keyOrder: 7,
      showOnUi: true,
      downloadable: true
    },
    campaign_type: {
      title: 'Campaign Type',
      toolTipText: 'Campaign Type',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 8,
      showOnUi: true,
      downloadable: true
    },
    source: {
      title: 'Source',
      toolTipText: 'Source',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      campaign_keyOrder: 9,
      showOnUi: true,
      downloadable: true
    },
    // 'source_of_action': {
    //   title: 'Source of action',
    //   toolTipText: 'Source of action',
    //   type: 'String',
    //   page: 'common',
    //   campaign_keyOrder: 9,
    //   showOnUi: true
    // },
    action: {
      title: 'Action',
      toolTipText: 'Action',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 10,
      showOnUi: true,
      downloadable: true
    },
    action_type: {
      title: 'Action Type',
      toolTipText: 'Action Type',
      type: 'String',
      page: 'common',
      // campaign_keyOrder: 11,
      showOnUi: false,
      downloadable: true
    },
    scope: {
      title: 'Scope',
      toolTipText: 'scope',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      showOnUi: false,
      downloadable: true
    },
    previous_value: {
      title: 'Previous',
      toolTipText: 'Previous',
      type: 'String',
      page: 'common',
      showOnUi: false,
      downloadable: true
    },
    current_value: {
      title: 'Current Value',
      toolTipText: 'Current Value',
      type: 'String',
      page: 'common',
      showOnUi: false,
      downloadable: true
    },
    strategy: {
      title: 'Strategy',
      toolTipText: 'Strategy',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      showOnUi: false,
      downloadable: true
    },
    objective: {
      title: 'Objective',
      toolTipText: 'Objective',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      showOnUi: false,
      downloadable: true
    },
    condition: {
      title: 'Condition',
      toolTipText: 'Condition',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      showOnUi: false,
      downloadable: true
    },
    suggested_action: {
      title: 'Suggested Action',
      toolTipText: 'Suggested Action',
      type: 'String',
      page: 'common',
      showOnRightPanel: true
      // showOnUi: true,
      // campaign_keyOrder: 11
    },
    match_type: {
      title: 'Match Type',
      toolTipText: 'Match Type',
      type: 'String',
      page: 'common',
      showOnRightPanel: true,
      downloadable: true
    }
  }
};
