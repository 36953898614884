<template>
  <div>
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start u-spacing-p-s"
    >
      <div>
        <rb-icon
          v-if="
            [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(
              productDetails.state
            )
          "
          icon="warning"
          :class="{
            'u-color-orange-base': SKU_STATE.IMPROVED === productDetails.state,
            'u-color-red-base': SKU_STATE.NEGATIVE === productDetails.state
          }"
        />
      </div>
      <div class="name-description-container">
        <p class="u-spacing-p-s">
          {{ productDetails.currentContent }}
        </p>
        <span
          class="u-spacing-p-xs u-spacing-ml-s u-font-size-7 u-font-weight-600"
          :class="{
            'u-bg-color-yellow-base u-color-grey-base u-border-radius-m':
              productDetails.status === 'pending',
            'u-bg-color-blue-base u-color-white-base u-border-radius-m':
              productDetails.status === 'ticketRaised'
          }"
        >
          {{ SKU_STATUS[productDetails.status] }}
        </span>
      </div>
      <div @click="onProductNameDescriptionEdit(productDetails)">
        <rb-icon
          v-if="
            [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(
              productDetails.state
            )
          "
          icon="pencil"
          class="u-color-blue-base rb-icon--medium"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SKU_STATE, SKU_STATUS } from '@/pages/all_retailers/dso/constant';
import modal from '@/components/widgets/modal.vue';

export default {
  name: 'ProductNameDescription',
  components: {
    modal
  },
  props: {
    productDetails: {
      type: Object,
      default() {
        return {
          id: 1,
          contentType: 'name',
          status: 'improved',
          state: 'ticketRaised',
          contentScore: 65,
          currentContent:
            '3 Musketeers and this is a long name wrapping into 2 lines and it would show the whole content.',
          suggestedContent:
            '3 Musketeers and this is a long name wrapping into 2 lines and it would show the whole content.'
        };
      }
    },
    onProductNameDescriptionEdit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      SKU_STATE,
      SKU_STATUS,
      showEditModal: false,
      renderParams: null
    };
  },
  methods: {
    toggleEdit() {
      this.showEditModal = !this.showEditModal;
    }
  }
};
</script>

<style scoped lang="css">
.name-description-container {
  white-space: normal;
  width: 80%;
  line-height: 1.5rem;
}
</style>
