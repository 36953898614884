<template>
  <div
    v-if="openPopup"
    v-show="openPopup"
  >
    <slot name="header">
      <div class="popup--header">
        <span
          class="u-display-flex u-flex-align-items-center u-color-grey-lighter u-font-size-7"
        >
          {{ headerTitle }}
        </span>
      </div>
    </slot>
    <slot name="body" />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'IconPopupComponent',
  components: {},
  props: {
    showPopup: {
      type: Boolean,
      default: true
    },
    headerTitle: {
      type: String,
      default: 'Report Configuration'
    }
  },
  data() {
    return {
      openPopup: false
    };
  },
  watch: {
    showPopup(newVal) {
      this.openPopup = newVal;
    }
  },
  created() {
    this.openPopup = this.showPopup;
  }
};
</script>

<style lang="css" scoped>
.popup--header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  line-height: 0;
}
</style>
