<template>
  <section>
    <p
      class="u-font-size-5 u-spacing-pb-s u-color-grey-lighter u-font-weight-600"
    >
      {{ title }}
    </p>
    <div class="u-display-flex">
      <div
        v-for="(val, index) in metricsShown"
        :key="index"
        :val="val"
        class="metric-card-hover-trigger"
      >
        <div
          data-cy="metricCards"
          class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
        >
          <div class="u-spacing-p-m">
            <rb-select
              v-if="val"
              :width="'240px'"
              class="u-spacing-mr-m"
              :context="[index]"
              :send-details="true"
              :on-close="metricSelected"
              :options="metricsList"
              :class-name="'campaigns-select '"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                >
                  <span class="u-font-size-5">{{ val }}</span>
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <p
                  class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m"
                >
                  {{ option.title }}
                </p>
              </template>
            </rb-select>
            <metric
              :size="'l'"
              class="u-display-inline-flex u-spacing-mt-s"
              :config="metricsConfig[val]"
              :data="metricData[val]"
            />
            <span
              v-if="false"
              class="u-color-grey-light u-font-size-5"
              >No Data</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import metric from '@/components/basic/metric';
export default {
  components: {
    metric
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    initialMetricsShown: {
      type: Array,
      default: () => {
        return [];
      }
    },
    metricsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    metricsConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
    metricData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      metricsShown: this.initialMetricsShown
    };
  },
  methods: {
    metricSelected(context, val) {
      if (val.length < 1) return;
      if (this.metricsShown.indexOf(val[0].title) === -1) {
        this.metricsShown.splice(context[0], 1, val[0].title);
      }
    }
  }
};
</script>
