import Vue from 'vue';
export const actionGroupConfig = [
  {
    action_group: 'Total Actions',
    action_count: 0
  },
  {
    action_group: 'Budget Change',
    action_count: 0,
    modifier: (action, previousValue, currentValue) => {
      return `${action} from ${Vue.options.filters.num_format(
        previousValue,
        'currency'
      )} to ${Vue.options.filters.num_format(currentValue, 'currency')}`;
    }
  },
  {
    action_group: 'Bid Change',
    action_count: 0,
    modifier: (action, previousValue, currentValue) => {
      return `${action} from ${Vue.options.filters.num_format(
        previousValue,
        'currency'
      )} to ${Vue.options.filters.num_format(currentValue, 'currency')}`;
    }
  },
  {
    action_group: 'Keyword Status Change',
    action_count: 0
  },
  {
    action_group: 'SKU Status Change',
    action_count: 0
  },
  {
    action_group: 'Campaign Status Change',
    action_count: 0
  },
  {
    action_group: 'Keywords Added',
    action_count: 0,
    modifier: (action, previousValue, currentValue) => {
      const commaSeperatedString = currentValue.split('+').join(',');
      return `Keywords Added: ${commaSeperatedString}`;
    }
  },
  {
    action_group: 'AMS SKU Replaced',
    action_count: 0
    // modifier: (action, previousValue, currentValue) => {
    //   const commaSeperatedString = currentValue.split('+').join(',')
    //   return `Keywords Added: ${commaSeperatedString}`
    // }
  }
];

export const convertToTemplateString = (apiData) => {
  const map = {};
  actionGroupConfig.forEach((item, index) => {
    map[item.action_group] = index;
  });
  const convertedApiData = apiData.map((item) => {
    // item.user_name = Math.random().toString(36).substring(7);
    const actionGroupConfigIndex = map[item.action_group] || -1;
    if (
      actionGroupConfigIndex === -1 ||
      !actionGroupConfig[actionGroupConfigIndex].modifier
    ) {
      return item;
    } else {
      item.action = actionGroupConfig[actionGroupConfigIndex].modifier(
        item.action,
        item.previous_value,
        item.current_value
      );
    }
    return item;
  });
  return convertedApiData;
};
