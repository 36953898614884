<template>
  <div
    v-if="(config.events[widgetData['actionType']] || {}).component"
    class="u-spacing-mb-l"
  >
    <component
      :is="config.events[widgetData['actionType']].component"
      :data="eventInfo"
      :config="config.events[widgetData['actionType']]"
    />
    <div class="u-spacing-pv-s">
      <div
        v-for="(component, $index) in (
          config.events[widgetData['actionType']] || {}
        ).midComponents"
        :key="$index"
      >
        <component
          :is="component.component"
          :data="eventInfo"
          :config="component"
        />
      </div>
    </div>
    <!-- <component :data="eventInfo" :is="config.events[widgetData['actionType']].midComponent"></component> -->
    <div class="u-display-flex">
      <span
        class="u-spacing-p-xs u-spacing-mt-s u-font-size-7 u-font-weight-bold u-border-radius-xs u-text-case-title"
        data-cy="currentStaus"
        :class="statusColors[widgetData.status]"
        >{{ widgetData.status }}</span
      >
    </div>
  </div>
</template>

<script>
import nameMessageTemplate from '@/components/widgets/collaboration/templates/name-message.vue';
import arrowChange from '@/components/widgets/collaboration/templates/arrowChange.vue';
import eventConfig from '@/components/widgets/collaboration/eventConfig';
import textMessage from '@/components/widgets/collaboration/templates/textMessage.vue';
import skuUpdated from '@/components/widgets/collaboration/templates/sku-updated.vue';
import budgetPlanner from '@/components/widgets/collaboration/budget-planner/budget-planner.vue';
import { getBaseActionType } from '@/pages/campaign-management/retailers/criteo/utils';

export default {
  components: {
    'name-message-template': nameMessageTemplate,
    arrowChange,
    textMessage,
    skuUpdated,
    budgetPlanner
  },
  props: {
    widgetData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      statusColors: {
        Open: 'u-bg-color-grey-white u-color-grey-x-light u-border-all u-border-color-xx-light u-border-width-s',
        'in-progress': 'u-color-grey-white u-bg-color-orange-base',
        success: 'u-color-grey-white u-bg-color-green-base',
        failed: 'u-color-grey-white u-bg-color-red-base',
        'duplicate entry': 'u-color-grey-white u-bg-color-green-base'
      }
    };
  },
  computed: {
    eventInfo() {
      let returnObj = {};
      const props = this.config.events[this.widgetData.actionType].props;
      if (!props) {
        returnObj = this.widgetData.viewPayload;
      } else {
        for (const key of Object.keys(props)) {
          returnObj[key] = this.widgetData.viewPayload[props[key]];
        }
      }
      returnObj.customArrowComponent =
        this.config.events[this.widgetData.actionType]?.customArrowComponent ||
        false;
      returnObj.message =
        this.config.events[this.widgetData.actionType].message;
      returnObj.displayType =
        this.config.events[this.widgetData.actionType].displayType ||
        this.widgetData.displayType;
      return returnObj;
    },
    config() {
      const actionType = getBaseActionType(this.$store.getters.getRetailer);
      return eventConfig.config(actionType);
    }
  },
  created() {}
};
</script>
