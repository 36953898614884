<template>
  <div
    class="u-width-180px u-spacing-pv-m u-border-radius-s u-spacing-mr-m"
    :class="{ 'u-bg-color-grey-extra-light': !isEditable }"
  >
    <div
      class="u-font-weight-600 u-font-size-4 u-spacing-ph-m u-fill-color-grey-light u-text-case-title"
    >
      {{ metricName }}
    </div>
    <div
      v-if="simulationComplete"
      class="u-color-blue-base u-spacing-ph-m u-font-size-6 u-font-weight-600 u-spacing-mt-s"
    >
      {{ `Simulated ${plannedVal ? 'vs Planned' : ''}` }}
    </div>
    <div
      v-if="simulationComplete"
      class="u-spacing-ph-m u-spacing-mb-m"
    >
      <div class="u-display-flex u-flex-align-items-center u-spacing-mt-s">
        <plan-icon class="u-bg-color-blue-lighter" />
        <div
          class="u-font-size-2 u-font-weight-600"
          :class="{
            'u-color-green-base': simulated >= plannedVal || !plannedVal,
            'u-color-red-base': simulated < plannedVal
          }"
        >
          {{ formatByCurrency(simulated, unit) }}
        </div>
      </div>
      <div
        v-if="plannedVal && simulated"
        class="u-spacing-mb-xs u-spacing-mt-xs progress-bar-wrapper u-position-relative"
      >
        <div
          class="current-bar u-bg-color-blue-lighter"
          :style="{ width: `${findPercentValue(simulated, plannedVal)}%` }"
        ></div>
        <div
          class="planned-bar u-position-absolute u-fill-color-blue-base"
          :style="{ width: `${findPercentValue(plannedVal, simulated)}%` }"
        ></div>
      </div>
    </div>
    <div
      v-if="currentVal && plannedVal"
      :class="{
        'u-border-top u-border-color-grey-xxx-light u-border-width-s':
          simulationComplete
      }"
    >
      <div class="u-spacing-ph-m">
        <div
          v-if="simulationComplete"
          class="u-color-grey-lighter u-font-size-6 u-font-weight-600 u-spacing-mt-s"
        >
          2022 vs Planned
        </div>
        <div
          class="u-spacing-mb-s u-spacing-mt-s progress-bar-wrapper u-position-relative"
        >
          <div
            class="current-bar"
            :style="{ width: `${findPercentValue(currentVal, plannedVal)}%` }"
            :class="isGreaterVal(currentVal, plannedVal)"
          ></div>
          <div
            class="planned-bar u-position-absolute u-fill-color-blue-base"
            :style="{ width: `${findPercentValue(plannedVal, currentVal)}%` }"
          ></div>
        </div>
        <div class="u-display-flex u-font-size-6 u-font-weight-600">
          <div
            class="u-display-flex u-spacing-mr-m u-flex-align-items-center"
            :class="isGreaterVal(currentVal, plannedVal)"
          >
            <plan-icon class="opacity-0_5" />
            {{ formatByCurrency(currentVal, unit) }}
          </div>
          <div class="u-display-flex u-spacing-mr-m u-flex-align-items-center">
            <plan-icon class="u-border-dashed u-border-color-blue-base" />
            {{ formatByCurrency(plannedVal, unit) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEditable && !simulationComplete"
      class="u-spacing-ph-m"
    >
      <div
        class="u-spacing-mt-m u-font-size-7 u-color-grey-lighter u-font-weight-600"
      >
        Enter Simulation Value
      </div>
      <input
        v-model="inputKey"
        :name="metricName"
        class="rb-input u-width-100 u-spacing-p-s u-spacing-mt-xs u-font-size-5 u-font-weight-600"
        @change="handleInputChange"
      />
    </div>
  </div>
</template>

<script>
import PlanIcon from '../atoms/plan-icon.vue';
import Vue from 'vue';
export default Vue.extend({
  components: {
    PlanIcon
  },
  props: {
    metricName: {
      type: String,
      default: '',
      required: true
    },
    unit: {
      type: String,
      default: '',
      required: true
    },
    currentVal: {
      type: Number,
      required: false,
      default: 0
    },
    plannedVal: {
      type: Number,
      required: false,
      default: 0
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false
    },
    simulated: {
      type: Number,
      required: false,
      default: 0
    },
    simulationComplete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputKey: ''
    };
  },
  computed: {},
  watch: {
    simulationComplete: {
      handler(newVal) {
        if (!newVal) {
          this.inputKey = '';
        }
      },
      immediate: true
    }
  },
  methods: {
    handleInputChange(e) {
      this.$emit('onInputChange', {
        [this.metricName]: parseInt(this.inputKey)
      });
    },
    formatByCurrency(value, unit) {
      if (unit === 'percent') {
        return `${value}%`;
      } else return `$${Vue.options.filters.num_format(value)}`;
    },
    isGreaterVal(current, planned) {
      if (Math.max(current, planned) === current) {
        return 'rising-plan';
      } else return 'declining-plan';
    },
    findPercentValue(value1, value2) {
      if (Math.max(value1, value2) === value1) {
        return 100;
      } else {
        return ((value1 / value2) * 100).toFixed(2);
      }
    }
  }
});
</script>
<style lang="css" scoped>
.u-box-shadow-light-grey {
  box-shadow: 0 0 4px 0 #caccce;
}
.u-width-180px {
  width: 180px;
}
.u-bg-color-grey-extra-light {
  background-color: #f5f7fa;
}
.progress-bar-wrapper {
  height: 10px;
  width: 100%;
}
.progress-bar-wrapper .current-bar {
  height: 9px;
  opacity: 0.4;
}
.rising-plan {
  color: #3ea95e;
}
.rising-plan.current-bar,
.rising-plan div {
  background-color: #3ea95e;
}
.declining-plan {
  color: #d7263d;
}
.declining-plan.current-bar,
.declining-plan div {
  background-color: #ff6072;
}
.planned-bar {
  border: 1px dashed;
  height: 10px;
  top: 0%;
}
</style>
