import Vue from 'vue';
import utils from '@/utils/helpers/';
const moment = require('moment');

// Store Actions
const moduleName = 'systemTransparencyDashboard';
export const FETCH_HEALTH_STATUS = `${moduleName}/fetchHealthStatus`;
export const GET_SUMMARIZED_HEALTH_STATUS = `${moduleName}/healthStatusSummarized`;
export const GET_DETAILED_HEALTH_STATUS = `${moduleName}/healthStatusDetailed`;
export const GET_IS_DRAWER_OPEN = `${moduleName}/isDrawerOpen`;
export const UPDATE_IS_DRAWER_OPEN = `${moduleName}/updateIsDrawerOpen`;
export const GET_EXPANDED_HEALTH_GROUP_IDS = `${moduleName}/expandedHealthGroupIds`;
export const UPDATE_EXPANDED_HEALTH_GROUP_IDS = `${moduleName}/updateExpandedHealthGroupIds`;
export const GET_DATES = `${moduleName}/dates`;
export const UPDATE_DATES = `${moduleName}/updateDates`;

// Constants and Maps
export const INCIDENT_PRIORITY = {
  MAJOR: 'MAJOR',
  MINOR: 'MINOR'
};

export const STATUS_MAP = {
  OUTAGE: {
    color: 'orange',
    icon: 'info-circle-fill',
    text: 'Outage'
  },
  OPERATIONAL: {
    color: 'green',
    icon: 'check-fill-circle',
    text: 'Operational'
  },
  FAILED: {
    color: 'orange',
    icon: 'info-circle-fill',
    text: 'Failed'
  },
  SUCCESS: {
    color: 'green',
    icon: 'check-fill-circle',
    text: 'Success'
  }
};

// Util Methods
export const isTransparencyDashboardEnabled = (retailer) => {
  const retailerConfig = `feature.${retailer}.transparency_dashboard`;
  const internalAndExternalConfig =
    'feature.is_transparency_dashboard_for_internal_and_external_users';
  const configCheck = Vue.options.filters.config_check;
  const isEnabledForRetailer = configCheck(retailerConfig);
  const isEnabledForInternalAndExternal = configCheck(
    internalAndExternalConfig
  );
  const currentURL = window.location.href;

  if (isEnabledForInternalAndExternal) {
    return isEnabledForRetailer && !currentURL.includes('amazon/fresh');
  }
  // If not enabled for both internal and external users, check only for internal users
  const isInternalUser = utils.internalUserCheck(window.user);
  return (
    isEnabledForRetailer &&
    isInternalUser &&
    !currentURL.includes('amazon/fresh')
  );
};

export const formatTimeDifference = (inputDateString) => {
  const inputDate = moment.utc(inputDateString);
  const currentDate = moment.utc();

  const duration = moment.duration(currentDate.diff(inputDate));

  if (duration.asSeconds() < 1) {
    return 'just now';
  }

  if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())}d ago`;
  }

  if (duration.asHours() >= 1) {
    return `${Math.floor(duration.asHours())}h ago`;
  }

  if (duration.asMinutes() >= 1) {
    return `${Math.floor(duration.asMinutes())}m ago`;
  }

  return `${Math.floor(duration.asSeconds())}s ago`;
};

export const formatDateRanges = (dateRanges) => {
  const formattedRanges = dateRanges.map((range) => {
    const startDate = moment(range.start_date).format('MMM D');
    const endDate = moment(range.end_date).format('MMM D');
    return `${startDate} - ${endDate}`;
  });

  return formattedRanges.join(', ');
};
