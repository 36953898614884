const targetConfigs = {
  lineItemName: {
    type: 'input',
    value: 'line_item_name',
    id: 'line_item_id',
    reqBody: {
      cubeName: 'target_campaign_line_items_workbench',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: ['line_item_name'],
      groupByDimensionsList: ['campaign_id', 'line_item_id'],
      orderByList: [],
      where: {
        dimensionNameValueList: [],
        date: null,
        pvpDate: null
      }
    }
  }
};

const costcoConfigs = {
  lineItemName: {
    type: 'input',
    value: 'line_item_name',
    id: 'line_item_id',
    reqBody: {
      cubeName: 'costco_campaign_line_items_workbench',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: ['line_item_name'],
      groupByDimensionsList: ['campaign_id', 'line_item_id'],
      orderByList: [],
      where: {
        dimensionNameValueList: [],
        date: null,
        pvpDate: null
      }
    }
  }
};
const meijerConfigs = {
  lineItemName: {
    type: 'input',
    value: 'line_item_name',
    id: 'line_item_id',
    reqBody: {
      cubeName: 'meijer_campaign_line_items_workbench',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: ['line_item_name'],
      groupByDimensionsList: ['campaign_id', 'line_item_id'],
      orderByList: [],
      where: {
        dimensionNameValueList: [],
        date: null,
        pvpDate: null
      }
    }
  }
};

export default {
  target: targetConfigs,
  costco: costcoConfigs,
  meijer: meijerConfigs
};
