<template>
  <div
    v-if="errorMessage"
    class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-spacing-mt-s"
    style="width: 300px"
  >
    <rb-icon
      class="u-flex-0 rb-icon--small"
      :icon="'error-fill'"
    />
    <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="css" scoped>
.rb-icon {
  align-self: start;
}
</style>
