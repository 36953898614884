<template>
  <section>
    <sticky-filter-panel
      ref="sticky-filter-panel"
      :filter-key="stickyFilterKey"
      :view-selection-enabled="false"
      :hide-export="true"
      :hide-add-filter="true"
      class="sticky-panel u-bg-color-grey-white"
      :filter-data="filterComputedData"
      :primary-filter-data="primaryFilterData"
      :secondary-filter-data="{}"
      :disable-date-range-selection="disableDateRangeSelection"
      :min-max-date="minMaxDate"
      :default-date-post-range="defaultDateRange"
      :allow-date-compare="false"
      :enable-date="enableDate"
    >
      <div
        slot="before-filter"
        class="u-display-flex u-flex-align-items-center u-height-36px"
      >
        <div class="u-font-size-2 u-font-weight-700 u-display-flex capitalize">
          Category Leaderboard
        </div>
      </div>
      <div
        v-if="rem1V2Config"
        slot="footer"
        class="u-bg-color-grey-white u-spacing-p-m"
      >
        <rb-filter
          v-if="retailerListFetched"
          ref="retailer-filter"
          :anchor="retailerFilterConfig.filterKey"
          :config="retailerFilterConfig"
          :show-notification="false"
          :multi="false"
          :use-local-storage="false"
          :hide-clear-all-btn="true"
          :is-always-on-filter="true"
          :secondary-data="retailerOptions"
          :apply="updateRetailer"
          :default-filters="selectedRem1RetailerFilterValue"
          :hide-remove-btn="true"
        />
      </div>
    </sticky-filter-panel>
    <section
      v-if="!pageLoading || !rem1V2Config"
      class="u-envelop-width"
    >
      <section class="u-display-flex u-flex-direction-column u-spacing-m-m">
        <div
          class="u-display-flex u-width-100 u-height-100 u-flex-direction-row u-position-relative overview-card"
        >
          <div
            class="u-width-50 u-bg-color-grey-white u-spacing-mr-m u-display-flex u-flex-direction-column u-border-radius-s boxShadow u-spacing-p-m"
            :class="{ 'is-expanded': categoryLandscape }"
          >
            <div
              class="u-display-flex u-flex-justify-content-space-between u-spacing-mb-s"
            >
              <span
                class="u-font-size-3 u-text-case-upper u-color-grey-lighter u-font-weight-600"
                >Category Landscape</span
              >
              <div
                v-if="!categoryLandscapeLoading && !categoryLandscapeError"
                class="u-display-flex u-flex-align-items-center u-color-blue-base u-cursor-pointer"
                :data-heap="
                  categoryLandscape ? 'Category Collapse' : 'Category Expand'
                "
                @click.stop="handleCategoryCollapse"
              >
                <span class="u-font-size-6 u-spacing-mr-s">{{
                  categoryCollapsable
                }}</span>
                <rb-icon
                  :icon="categoryLandscape ? 'collapse' : 'enlarge'"
                  class="rb-icon--small"
                />
              </div>
            </div>
            <div
              v-if="categoryLandscapeError"
              class="u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
            >
              <p>Error Occurred. Please try again later</p>
            </div>
            <div
              v-else-if="noValidCategoriesPresent"
              class="u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
            >
              <p>{{ noValidCategoriesPresentText }}</p>
            </div>
            <div
              v-else-if="categoryLandscapeLoading"
              class="u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
            >
              <loader
                class="u-bg-color-grey-white"
                :loading="true"
                :color="'#007cf6'"
              />
            </div>
            <div
              v-else-if="!categoryLandscapeLoading && !categoryLandscapeError"
              class="u-display-flex u-flex-direction-row u-width-100 u-height-100"
            >
              <div
                class="u-display-flex"
                :class="
                  categoryObservations.length ? 'u-width-60' : 'u-width-100'
                "
              >
                <categoryBars :top-performers="topCategoriesData" />
              </div>
              <div
                v-if="categoryObservations.length"
                class="u-width-40 u-height-100"
              >
                <OverviewObservations :observations="categoryObservations" />
              </div>
            </div>
          </div>
          <div
            class="u-width-50 u-bg-color-grey-white u-border-raidus-s u-display-flex u-flex-direction-column boxShadow u-spacing-p-m"
            :class="{ 'is-expanded': competitorLandscape }"
          >
            <div
              class="u-display-flex u-flex-justify-content-space-between u-spacing-mb-s"
            >
              <span
                class="u-font-size-3 u-text-case-upper u-color-grey-lighter u-font-weight-600"
                >Competitor Landscape
              </span>
              <div
                v-if="!competitorLandscapeLoading"
                class="u-display-flex u-flex-align-items-center u-color-blue-base u-cursor-pointer"
                :data-heap="
                  competitorLandscape
                    ? 'Competitor Collapse'
                    : 'Competitor Expand'
                "
                @click.stop="handleCompetitorCollapse"
              >
                <span class="u-font-size-6 u-spacing-mr-s">{{
                  competitorCollapsable
                }}</span>
                <rb-icon
                  :icon="competitorLandscape ? 'collapse' : 'enlarge'"
                  class="rb-icon--small"
                />
              </div>
            </div>
            <div
              v-if="noValidCategoriesPresent"
              class="u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
            >
              <p>{{ noValidCategoriesPresentText }}</p>
            </div>
            <div
              v-else
              class="u-display-flex u-flex-direction-row u-height-100 u-width-100"
            >
              <div
                class="u-display-flex u-flex-direction-column u-flex-justify-content-space-between u-width-100"
              >
                <div v-if="!rem1V2Config && filterDataFetched">
                  <rb-filter-panel
                    v-if="!competitorLandscapeLoading"
                    id="cva-filters"
                    :key="filterChange"
                    class="u-text-case-title"
                    chip-padding-top="0px"
                    chip-padding-bottom="0px"
                    :enable-save="false"
                    :add-filter-text="addFilterText"
                    :show-notification="false"
                    :data="[]"
                    :primary="primaryFilterData"
                    :secondary-data="secondaryFilterComputedData"
                    :listener-event="filterLocalStorageKey"
                    :has-search="false"
                    :enable-remove-all="false"
                    :emit="filterEmit"
                    :use-local-storage="true"
                    :hide-clear-all-btn="true"
                    :hide-remove-btn="true"
                    :filter-list-tooltip-fn="generateTooltip"
                    :show-dynamic-tooltip="false"
                    :show-next-line-ellipsis="true"
                    :is-cva="true"
                    :get-title="getTitle"
                  />
                </div>
                <category-filter
                  v-if="rem1V2Config && filterDataFetched"
                  :key="categoryFilterKey"
                  :category-filter-options="categoryFilterOptions"
                  :selected-category="selectedCategory"
                  :selected-sub-category-value="selectedSubCategory"
                  :get-title="getFilterOptionTitleV2"
                  @apply="updateCategory"
                />
                <div
                  v-if="competitorLandscapeLoading"
                  class="u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
                >
                  <loader
                    class="u-bg-color-grey-white"
                    :loading="true"
                    :color="'#007cf6'"
                  />
                </div>
                <div
                  v-if="!competitorLandscapeLoading && competitorLandscapeError"
                  class="u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
                >
                  <p>Error Occurred. Please try again later</p>
                </div>
                <podium
                  v-if="
                    !competitorLandscapeLoading &&
                    !competitorLandscapeError &&
                    topBrandsData
                  "
                  class="u-width-100 u-height-100"
                  :top-performers="topBrandsData"
                />
              </div>
            </div>
          </div>
          <OverlayContainer
            v-show="competitorLandscape || categoryLandscape"
            :category-landscape="categoryLandscape"
            :competitor-landscape="competitorLandscape"
            :key-observations="computedAllInsights"
            :last-update-date="lastUpdateDate"
            @closeCompetativeLandscape="handleCompetitorCollapse"
            @closeCategoryLandscape="categoryLandscape = false"
          >
            <div
              slot="categoryLandscapeExpandedCard"
              ref="widgetsContainer"
            >
              <category-performance-widget
                v-if="categoryPerformanceService && !isLoading"
                :service="categoryPerformanceService"
                @onCategoryClick="handleCategoryClick"
                @onSubCategoryClick="handleSubCategoryClick"
              />
            </div>
            <div
              ref="widgetsContainer"
              slot="competitorLandscapeExpandedCard"
              class="u-display-flex u-flex-direction-column u-spacing-ph-m u-spacing-pb-l u-border-bottom u-border-color-grey-xxx-light"
            >
              <div
                v-if="!noValidCategoriesPresent"
                class="share-header"
              >
                <rb-filter-panel
                  v-if="!rem1V2Config && filterDataFetched"
                  id="cva-filters"
                  :key="filterChange"
                  class="u-text-case-title u-width-50"
                  :enable-save="false"
                  :add-filter-text="addFilterText"
                  :show-notification="false"
                  :data="[]"
                  :primary="primaryFilterData"
                  :secondary-data="secondaryFilterComputedData"
                  :listener-event="filterLocalStorageKey"
                  :has-search="false"
                  :enable-remove-all="false"
                  :emit="filterEmit"
                  :use-local-storage="true"
                  :hide-clear-all-btn="true"
                  :filter-list-tooltip-fn="generateTooltip"
                  :show-dynamic-tooltip="true"
                  :hide-remove-btn="true"
                  :show-next-line-ellipsis="true"
                  :is-cva="true"
                  :get-title="getTitle"
                />
                <category-filter
                  v-if="rem1V2Config && filterDataFetched"
                  :key="categoryFilterKey"
                  class="rem-overlay-category-filter u-spacing-pv-m"
                  :category-filter-options="categoryFilterOptions"
                  :selected-category="selectedCategory"
                  :selected-sub-category-value="selectedSubCategory"
                  :get-title="getFilterOptionTitleV2"
                  @apply="updateCategory"
                />

                <spotlight-widget
                  :spotlights-data="getSpotlight"
                  :spotlight-loading="spotlightLoading"
                  :competitor-spotlights-error="competitorSpotlightsError"
                />
                <div
                  class="u-spacing-ml-s u-display-flex u-flex-justify-content-space-between"
                >
                  <div class="u-font-size-4 u-font-weight-bold">
                    Competitor Scorecard
                  </div>
                  <div
                    slot="download"
                    expoter="display-none"
                  >
                    <rb-button
                      v-tippy="{ placement: 'top', arrow: false }"
                      :title="exportScorecardTippy"
                      text=""
                      icon-left="share"
                      :size="'s'"
                      :click-fn="exportWidgets"
                      class="hollow-outlined-btn leaders-export-btn"
                      expoter="display-none"
                    />
                  </div>
                </div>
              </div>
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-mb-m"
              >
                <rb-switch
                  :value="isDetailedView"
                  class="u-spacing-mr-s"
                  @input="tabChanged"
                />
                <span class="u-font-size-4">Detailed View</span>
              </div>
              <div
                v-if="initialClick"
                v-show="clickedDimension"
                ref="dbContainer"
              >
                <container
                  :key="selectedBrowseNode"
                  :title="(clickedDimension || {}).label"
                  :widgets="widgetsList[(clickedDimension || {}).name] || []"
                  :info-text="linerText"
                  @onWidgetChange="handleWidgetChange"
                  @onClose="onContainerClose"
                >
                  <component
                    :is="wrappersMap[(clickedDimension || {}).name]"
                    :selected-widget="computedSelectedWidget"
                    :page-settings="pageSettings"
                    :default-tab="defaultTab"
                    :service="tableDataService"
                    :clicked-dimension="clickedDimension"
                    @fetchLiner="formLinerDataMap"
                  />
                </container>
              </div>

              <div>
                <section
                  v-if="isLoading"
                  style="min-height: 400px"
                  class="u-display-flex u-flex-direction-column u-flex-justify-content-center"
                >
                  <loader
                    class="u-bg-color-grey-white"
                    :loading="true"
                    :color="'#007cf6'"
                  />
                </section>
                <leaders-widget
                  v-if="tableDataService"
                  v-show="!isLoading"
                  :service="tableDataService"
                  :page-settings="pageSettings"
                />
              </div>
            </div>
          </OverlayContainer>
        </div>
      </section>
      <feature-switch feature-switch-local-storage-key="rem1UISwitch" />
    </section>
    <div
      v-if="pageLoading && rem1V2Config"
      class="loading-icon u-position-relative"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
  </section>
</template>

<script>
import rbInsightsTable from '@/components/globals/dataTable/rbInsightsTable.vue';
import ImageWithTextHeader from '@/components/pages/insights/amazon/cva/atoms/ImageWithTextHeader.vue';
import OverviewObservations from '@/components/pages/insights/amazon/cva/atoms/overview-observation.vue';
import OverlayContainer from '@/components/pages/insights/amazon/cva/molecules/overlay-container.vue';
import splitTabs from '@/components/widgets/splitTabs';
import categoryPerformanceWidget from '@/components/pages/insights/amazon/cva/templates/categoryPerformanceWidget.vue';
import leadersWidget from '@/components/pages/insights/amazon/cva/templates/leadersWidget.vue';
import TableService from '@/components/pages/insights/amazon/cva/services/leaders-table/LeadersTableService.js';
import CategoryPerformanceService from '@/components/pages/insights/amazon/cva/services/categoryPerformance/CategoryPerformanceService.js';
import CompetitorLandscapeService from '@/components/pages/insights/amazon/cva/services/CompetitorLanscape';
import CategoryLandscapeService from '@/components/pages/insights/amazon/cva/services/CategoryLandscape';
import loader from '@/components/basic/loader';
import podium from '@/components/pages/insights/amazon/cva/atoms/podium.vue';
import categoryBars from '@/components/pages/insights/amazon/cva/atoms/categoryBars.vue';
import brandStrength from '@/components/pages/insights/amazon/cva/wrappers/brandStrength.vue';
import combinedSovAssortment from '@/components/pages/insights/amazon/cva/wrappers/combinedSovAssortment.vue';
import contentScore from '@/components/pages/insights/amazon/cva/wrappers/contentScore.vue';
import pricingPower from '@/components/pages/insights/amazon/cva/wrappers/pricingPower.vue';
import inStockPerf from '@/components/pages/insights/amazon/cva/wrappers/inStockPerf.vue';
import assortment from '@/components/pages/insights/amazon/cva/wrappers/assortment.vue';
import reviewsAndRatings from '@/components/pages/insights/amazon/cva/wrappers/reviewsAndRatings.vue';
import adIncrementality from '@/components/pages/insights/amazon/cva/wrappers/adIncrementality.vue';
import container from '@/components/pages/insights/amazon/cva/molecules/container.vue';
import { eventBus } from '@/utils/services/eventBus';
import Vue from 'vue';
import BrandIconCell from '@/components/pages/insights/amazon/cva/atoms/brand-icon-cell.vue';
import BrandRankCell from '@/components/pages/insights/amazon/cva/atoms/brand-rank-cell.vue';
import DimensionCell from '@/components/pages/insights/amazon/cva/atoms/dimension-cell.vue';
import rbFilterPanel from '@/components/widgets/filter-panel';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import widgetMixin from '@/components/widgetsMixin.js';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import {
  getNodePayload,
  rem1V2ConfigEnabled
} from '@/components/pages/insights/amazon/cva/utils';
import moment from 'moment';
import filter from '@/components/widgets/filter/filter.vue';
import CategoryFilter from '@/components/pages/insights/amazon/cva/molecules/category-filter.vue';
import SpotlightWidget from '@/components/pages/insights/amazon/cva/components/spotlight-widget.vue';
import featureSwitch from '@/components/basic/featureSwitch.vue';

export default {
  components: {
    stickyFilterPanel,
    loader,
    rbInsightsTable,
    ImageWithTextHeader,
    OverviewObservations,
    OverlayContainer,
    splitTabs,
    leadersWidget,
    brandStrength,
    adIncrementality,
    combinedSovAssortment,
    pricingPower,
    contentScore,
    inStockPerf,
    reviewsAndRatings,
    container,
    categoryBars,
    podium,
    BrandIconCell,
    BrandRankCell,
    categoryPerformanceWidget,
    DimensionCell,
    rbFilterPanel,
    assortment,
    rbFilter: filter,
    CategoryFilter,
    SpotlightWidget,
    featureSwitch
  },
  mixins: [widgetMixin],
  data() {
    return {
      exportScorecardTippy:
        'Click here to export the scorecard as a PDF. The current view and data present on the scorecard will be exported.',
      defaultTab: null,
      minMaxDate: {},
      defaultDateRange: 'last30Days',
      disableDateRangeSelection: true,
      filterLocalStorageKey: 'cva-category-filters',
      addFilterText: 'Modify Filter',
      overviewCardHeaders: {
        collapse: 'Collapse',
        competitor: 'View Scorecard',
        category: 'View All Categories'
      },
      count: 0,
      competitorLandscape: false,
      categoryLandscape: true,
      isDetailedView: true,
      tableDataService: null,
      categoryPerformanceService: null,
      clickedDimension: null,
      initialClick: false,
      selectedWidget: null,
      competitorLandscapeService: null,
      categoryLandscapeService: null,
      wrappersMap: {
        'Brand Strength': 'brandStrength',
        'Ad inefficiency': 'adIncrementality',
        SoV: 'combinedSovAssortment',
        'Pricing Leadership': 'pricingPower',
        'Content Score': 'contentScore',
        RnR: 'reviewsAndRatings',
        'In Stock': 'inStockPerf',
        Assortment: 'combinedSovAssortment'
      },
      widgetsList: {
        'Brand Strength': ['Search Terms'],
        'Ad inefficiency': ['Search Terms'],
        'Pricing Leadership': ['SKUs'],
        SoV: ['Search Terms'],
        'Content Score': ['SKU Details'],
        RnR: ['SKUs'],
        'In Stock': ['SKUs'],
        Assortment: ['Search Terms']
      },
      filterInstance: {},
      filterChange: 0,
      dimensionMapping: [],
      categoryList: [],
      filterEmit: 'conditionFilterChangeEvent',
      selectedBrowseNode: null,
      browseNodeMap: {},
      browseNodeMapV2: {},
      lastUpdateDate: '',
      secondaryFilter: {},
      linerList: {},
      initialLoad: true,
      enableDate: false,
      filterDataFetched: false,
      filterDataApiFailed: false,
      retailerFilterLocalStorageKey: 'cva-retailer-filter',
      selectedRem1Retailer: null,
      selectedRem1RetailerFilterValue: null,
      retailerOptions: null,
      retailerListFetched: false,
      categoryFilterData: null,
      categoryFilterOptions: {},
      selectedCategory: {},
      selectedSubCategory: {},
      categoryFilterKey: 0,
      rem1EnabledRetailers: null,
      pageLoading: false,
      rem1V2Config: rem1V2ConfigEnabled(),
      categoryToolTipMapping: {},
      noValidCategoriesPresent: false,
      noValidCategoriesPresentText: 'No valid categories available',
      stickyFilterKey: 0
    };
  },
  computed: {
    linerText() {
      return this.linerList?.[this.clickedDimension?.name] || '';
    },
    filterComputedData() {
      return [];
    },
    secondaryFilterComputedData() {
      let secondaryFilter = {};
      for (let item of this.dimensionMapping) {
        if (item?.dimensionLabel && item?.dimensionType === 'AMAZON_CATEGORY') {
          secondaryFilter[item.dimensionName] = {
            multi: false,
            smart: false,
            key: item.dimensionName,
            label: item.dimensionLabel,
            values: this.findUnique(this.categoryList, item.dimensionName)
          };
        }
      }
      return secondaryFilter;
    },
    competitorCollapsable() {
      return this.competitorLandscape
        ? this.overviewCardHeaders.collapse
        : this.overviewCardHeaders.competitor;
    },
    competitorLandscapeLoading() {
      return (
        this.competitorLandscapeService?.loading ||
        this.categoryLandscapeLoading
      );
    },
    competitorSpotlightsError() {
      return this.tableDataService?.spotlightError;
    },
    competitorLandscapeError() {
      return this.competitorLandscapeService?.error || this.filterDataApiFailed;
    },
    categoryLandscapeLoading() {
      return this.categoryLandscapeService?.loading;
    },
    categoryLandscapeError() {
      return this.categoryLandscapeService?.error || this.filterDataApiFailed;
    },
    categoryObservations() {
      return this.categoryLandscapeService?.insightsData || [];
    },
    computedSelectedWidget() {
      return (
        this.selectedWidget ||
        this.widgetsList[this.clickedDimension?.name]?.[0] ||
        'Search Terms'
      );
    },
    pageSettings() {
      const whereClause = {
        dimensionNameValueList: [
          {
            dimensionName: 'BROWSE_NODE',
            dimensionValue: this.selectedBrowseNode,
            operator: 'EQUAL_TO'
          }
        ]
      };
      return {
        where: whereClause
      };
    },
    allCompetitorInsights() {
      return this.tableDataService?.allInsights;
    },
    getSpotlight() {
      return this.tableDataService?.spotlights;
    },
    spotlightLoading() {
      return this.tableDataService?.allInsightsLoading;
    },
    computedAllInsights() {
      if (this.categoryLandscape) {
        return this.categoryLandscapeService?.allInsights;
      } else {
        if (this.tableDataService?.allInsightsLoading) {
          return [];
        }
        return this.allCompetitorInsights;
      }
    },
    topBrandsData() {
      return this.competitorLandscapeService?.topBrandsData
        ?.map((data) => {
          const formattedBrandName = data.brand
            .replaceAll(' ', '_')
            .toLowerCase();
          const imageUrl = `https://cdn.rboomerang.com/images/brands/${formattedBrandName}.jpeg`;
          return {
            name: data.brand,
            image: imageUrl,
            leadingIn: data.attributes?.split('Leading in')?.[1],
            client_flag: data.client_flag
          };
        })
        .slice(0, 3);
    },
    categoryCollapsable() {
      return this.categoryLandscape
        ? this.overviewCardHeaders.collapse
        : this.overviewCardHeaders.category;
    },
    isLoading() {
      return (
        this.tableDataService?.loading ||
        this.categoryPerformanceService?.loading
      );
    },
    topCategoriesData() {
      return this.categoryLandscapeService?.topCategoriesData?.map((data) => {
        return {
          categoryStrength: data.sov_fill_percentage,
          title: data.category_name,
          volume: data.search_vol_post_value
        };
      });
    },
    overlayContainer() {
      return this.competitorLandscape || this.categoryLandscape;
    },
    retailerFilterConfig() {
      return {
        filterKey: 'retailer',
        title: 'Retailer'
      };
    }
  },
  watch: {
    pageSettings: {
      handler(newVal) {
        if (this.selectedBrowseNode && !this.categoryLandscapeLoading) {
          this.competitorLandscapeService?.fetchData(newVal);
        }
      },
      deep: true,
      immediate: true
    },
    overlayContainer: {
      handler(newVal) {
        if (!this.initialLoad) {
          if (this.competitorLandscape || this.categoryLandscape) {
            this.$nextTick(() => {
              this.$refs?.widgetsContainer?.scrollIntoView({
                behavior: 'smooth'
              });
            });
          }
        }
        this.initialLoad = false;
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initializePage();
    eventBus.$on('onClose', () => {
      this.onContainerClose();
    });
    eventBus.$on('toggleExpandable', (dimension) => {
      if (!this.initialClick) {
        this.initialClick = true;
      }
      this.onContainerClose();
      this.$nextTick(() => {
        this.defaultTab = dimension?.defaultTab;
        this.clickedDimension = dimension;
        this.selectedWidget = null;
        this.$nextTick(() => {
          this.$refs?.dbContainer?.scrollIntoView({ behavior: 'smooth' });
        });
      });
    });
    eventBus.$on('conditionFilterChangeEvent', (params) => {
      if (Object.keys(params)?.length > 1) {
        delete params[Object.keys(this.filterInstance.values)[0]];
        this.filterInstance.values = params;
        this.filterInstance.order = Object.keys(params);
        this.saveFilter();
        this.filterChange++;
      } else if (
        this.filterInstance?.order?.[0] === Object.keys(params)?.[0] &&
        Object.values(this.filterInstance.values)?.[0][0] !==
          Object.values(params)?.[0][0]
      ) {
        this.filterInstance.values = params;
        this.filterInstance.order = Object.keys(params);
        this.saveFilter();
        this.filterChange++;
      }
      this.selectedBrowseNode = Object.values(params)?.[0]?.[0];
    });
  },
  created() {
    Vue.component('BrandIconCell', BrandIconCell);
    Vue.component('DimensionCell', DimensionCell);
    Vue.component('BrandRankCell', BrandRankCell);
  },
  beforeDestroy() {
    eventBus.$off(this.filterEmit);
  },
  methods: {
    getTitle(item) {
      return this.browseNodeMap?.[item?.title];
    },
    getFilterOptionTitleV2(item) {
      return this.browseNodeMapV2?.[item?.title];
    },
    formLinerDataMap(data) {
      Vue.set(this.linerList, this.clickedDimension.name, data);
    },
    findUnique(val, anchor) {
      let selections;
      if (localStorage.getItem(this.filterLocalStorageKey)) {
        selections = Object.values(
          JSON.parse(localStorage.getItem(this.filterLocalStorageKey))
        )[0];
      } else {
        selections = [
          this.categoryLandscapeService?.topCategoriesData?.[0]?.category_name
        ];
      }
      const _data = [];
      const enableState = {};
      for (const category in val) {
        if (
          val[category][anchor] &&
          val[category][anchor].length > 0 &&
          val[category][anchor] !== 'null'
        ) {
          let found = true;
          if (found) {
            enableState[val[category].request_param] = true;
          } else {
            if (enableState[val[category][anchor]] !== true) {
              enableState[val[category][anchor]] = false;
            }
          }
        }
      }
      for (const category in enableState) {
        if (index !== -1 && !enableState[category]) {
          enableState[category] = true;
        }
        const index = selections.indexOf(category);
        _data.push({
          title: category,
          enable: enableState[category]
        });
      }
      return _data;
    },
    generateTooltip(title) {
      return this.mapTooltip()?.[title]?.tooltip;
    },
    mapTooltip() {
      let mapping = {};
      for (let category of this.categoryList) {
        for (let dimension of this.dimensionMapping) {
          if (dimension?.dimensionLabel) {
            if (category[dimension.dimensionName] !== null) {
              mapping[category.request_param] = {
                label: category?.[dimension.dimensionName],
                node: category?.request_param,
                tooltip: this.constructTooltip(
                  JSON.parse(category.category_info)
                )
              };
            }
          }
        }
      }
      return mapping;
    },
    constructTooltip(list) {
      let tooltip = '<span>';
      tooltip = tooltip + list.join(',<br/>');
      tooltip = tooltip + '</span>';
      return tooltip;
    },
    handleCategoryClick(clickedCategory) {
      if (this.rem1V2Config) {
        const foundCategory = this.categoryList.find(
          (category) => category.requestParam === clickedCategory
        );
        if (foundCategory) {
          this.selectedCategory = foundCategory;
          this.categoryLandscape = !this.categoryLandscape;
          this.selectedBrowseNode = this.selectedCategory.requestParam;
          this.categoryFilterKey++;
          this.handleCompetitorCollapse();
        }
      } else {
        for (let dimension of this.categoryDimensionList) {
          const foundCategory = this.categoryList.find(
            (category) => category.request_param === clickedCategory
          );
          if (foundCategory) {
            this.filterInstance.values = {};
            this.filterInstance.values[dimension] = [
              foundCategory?.request_param
            ];
            this.filterInstance.order = [dimension];
            this.saveFilter();
            this.filterChange++;
            this.categoryLandscape = !this.categoryLandscape;
            this.handleCompetitorCollapse();
            break;
          }
        }
      }
    },
    handleSubCategoryClick(clickedSubCategory, parentCategory) {
      const foundCategory = this.categoryList.find(
        (category) => category.requestParam === parentCategory
      );
      if (foundCategory) {
        this.selectedCategory = foundCategory;
        this.selectedSubCategory = (foundCategory.subCategories || []).reduce(
          (previousVal, currentVal) => {
            if (currentVal.requestParam === clickedSubCategory) {
              previousVal.values = {
                sub_category: [currentVal.name]
              };
            }
            return previousVal;
          },
          {}
        );
        this.categoryLandscape = !this.categoryLandscape;
        this.selectedBrowseNode = clickedSubCategory;
        this.categoryFilterKey++;
        this.handleCompetitorCollapse();
      }
    },
    async initializePage() {
      this.pageLoading = true;
      this.tableDataService = new TableService(
        'cva',
        'leadersWidget',
        476,
        8,
        this.isDetailedView
      );
      this.categoryPerformanceService = new CategoryPerformanceService(
        'cva',
        'categoryPerformanceWidget',
        59,
        1001
      );
      this.categoryLandscapeService = new CategoryLandscapeService(
        'cva',
        'categoryPerformanceWidget',
        59,
        1001
      );
      this.competitorLandscapeService = new CompetitorLandscapeService(
        'customer_value_assesment',
        'cva_comp_landscape',
        481,
        9001
      );
      if (this.rem1V2Config) {
        await this.fetchRetailerList();
        await this.fetchCategoryDataV2();
      } else {
        await this.fetchCategoryDataV1();
      }
      this.pageLoading = false;
      this.fetchLastUpdatedDate();
    },
    async fetchLastUpdatedDate() {
      try {
        const body = {
          entityType: 'last_updated_date',
          operations: {
            system: 'cva'
          }
        };
        if (this.rem1V2Config) {
          body.where = {
            dimensionNameValueList: [
              {
                dimensionName: 'retailer_id',
                dimensionValue: this.selectedRem1Retailer.ID,
                operator: 'EQUAL_TO'
              }
            ]
          };
        }
        const lastUpdated = await HttpService.post('DASHBOARD_SERVICE', body, {
          append: '/cat_analytics/dms/data'
        });
        this.lastUpdateDate = lastUpdated?.data?.response[0]?.LAST_UPDATED_DATE;
        if (this.lastUpdateDate) {
          this.minMaxDate.maxDate = moment(
            lastUpdated?.data?.response[0]?.LAST_UPDATED_DATE,
            'YYYY-MM-DD'
          ).format('MM/DD/YYYY');
          this.enableDate = true;
        } else {
          this.enableDate = false;
        }
      } catch (e) {
        this.enableDate = false;
      }
    },
    async fetchCategoryDataV1() {
      const body = {
        cubeName: 'category_analytics_category_list',
        system: 'cva'
      };
      try {
        const filterData = await HttpService.post('FETCH_FILTERS_V2', body);
        let categoryApiData = filterData.data;
        let payloadCategories = getNodePayload(
          categoryApiData?.response?.data,
          'browse_node_label'
        );
        await this.categoryLandscapeService.fetchData(payloadCategories);
        this.browseNodeMap = categoryApiData.response.data.reduce(
          (prevVal, curVal) => {
            const label =
              curVal.DIMENSION.browse_node_label ||
              curVal.DIMENSION.custom_tag_label;
            prevVal[curVal.DIMENSION.request_param] = label;
            return prevVal;
          },
          {}
        );
        this.filterDataFetched = true;
        this.dimensionMapping =
          categoryApiData.response.metadata.dimensionMappingData || [];
        this.categoryList = transformer.mergeResultDimension(
          categoryApiData.response.data
        );
        this.defaultCategoryV1();
      } catch (e) {
        this.filterDataFetched = false;
        this.filterDataApiFailed = true;
        this.openSnackbar();
      }
    },
    async fetchCategoryDataV2() {
      const body = {
        entityType: 'category',
        operations: {
          system: 'cva'
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'retailer_id',
              dimensionValue: this.selectedRem1Retailer.ID,
              operator: 'EQUAL_TO'
            }
          ]
        }
      };
      try {
        const filterData = await HttpService.post('DASHBOARD_SERVICE', body, {
          append: '/cat_analytics/dms/data'
        });
        this.categoryFilterData = filterData.data;
        if (!this.categoryFilterData.response?.length) {
          this.noValidCategoriesPresent = true;
          return;
        }
        this.categoryList = this.categoryFilterData.response;

        const categoryValues = [];
        this.categoryList.forEach((category) => {
          categoryValues.push({
            title: category.requestParam,
            enable: true
          });
        });
        this.browseNodeMapV2 = this.categoryList.reduce((prevVal, curVal) => {
          prevVal[curVal.requestParam] = curVal.name;
          return prevVal;
        }, {});
        this.categoryFilterOptions = {
          category: {
            values: categoryValues
          }
        };
        const payloadCategories = getNodePayload(this.categoryList);
        await this.categoryLandscapeService.fetchData(payloadCategories);
        this.defaultCategoryV2();
      } catch (e) {
        this.filterDataFetched = false;
        this.filterDataApiFailed = true;
        this.openSnackbar();
      }
    },
    tabChanged(updatedVal) {
      this.tableDataService.isDetailedView = updatedVal;
    },
    onContainerClose() {
      this.clickedDimension = null;
    },
    handleWidgetChange(widget) {
      this.selectedWidget = widget;
    },
    handleCompetitorCollapse() {
      this.competitorLandscape = !this.competitorLandscape;
      if (this.competitorLandscape) {
        this.onContainerClose();
        this.tabChanged(true);
      }
      if (!this.tableDataService) {
        this.tableDataService = new TableService(
          'cva',
          'leadersWidget',
          476,
          8,
          this.isDetailedView
        );
      }
      if (this.categoryLandscape) {
        this.categoryLandscape = false;
      }
    },
    handleCategoryCollapse() {
      if (this.competitorLandscape) {
        this.competitorLandscape = false;
      }
      return (this.categoryLandscape = !this.categoryLandscape);
    },
    exportWidgets() {
      const month = moment().format('MMM');
      const day = moment().format('DD');
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'PDF is being generated and will be downloaded shortly.',
        template: {
          orientation: 'portrait',
          header: true,
          elementSelectors: [
            {
              selector: '.share-header'
            }
          ]
        },
        elementSelector: '.cva-leaders-table',
        fileName: `Competitor_scorecard_${month}/${day}.pdf`
      };
      eventBus.$emit('triggerExport', config);
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    async updateRetailer(data) {
      if (data?.selections?.length > 0) {
        this.pageLoading = true;
        this.noValidCategoriesPresent = false;
        const selectedRetailer = this.rem1EnabledRetailers.find(
          (retailer) => retailer.RETAILER === data.selections[0]
        );
        this.$store.dispatch(
          'cvaRem/setSelectedRem1Retailer',
          selectedRetailer
        );
        this.selectedRem1Retailer = selectedRetailer;
        this.selectedCategory = {};
        this.selectedSubCategory = {};
        this.categoryLandscapeService.loading = true;
        this.categoryLandscapeService.allInsights = [];
        this.categoryPerformanceService.loading = true;
        this.categoryPerformanceService.fetchData('categories');
        await this.fetchCategoryDataV2();
        await this.fetchLastUpdatedDate();
        this.stickyFilterKey++;
        this.pageLoading = false;
      }
    },
    getSelectedRem1Retailer() {
      const selectedGlobalRetailerKey =
        this.$store.getters.getRetailer + this.$store.getters.getProgram;
      let globalRetailer = this.rem1EnabledRetailers.find(
        (retailer) => retailer.ALIAS === selectedGlobalRetailerKey
      );
      if (globalRetailer !== undefined) {
        this.selectedRem1Retailer = globalRetailer;
      } else {
        // if selected retailer from left navbar wasn't found in the list
        this.selectedRem1Retailer = this.rem1EnabledRetailers[0];
      }
      const values = {
        retailer: [this.selectedRem1Retailer.RETAILER]
      };

      this.selectedRem1RetailerFilterValue = { values };
      this.$store.dispatch(
        'cvaRem/setSelectedRem1Retailer',
        this.selectedRem1Retailer
      );

      this.retailerOptions = {
        retailer: {
          values: this.rem1EnabledRetailers.map((retailer) => {
            return {
              title: retailer.RETAILER,
              enable: true
            };
          })
        }
      };
      this.retailerListFetched = true;
    },
    async fetchRetailerList() {
      this.rem1EnabledRetailers = await this.$store.dispatch(
        'cvaRem/fetchRetailerList'
      );
      if (this.rem1EnabledRetailers.length > 0) {
        this.getSelectedRem1Retailer();
      }
    },
    updateCategory(data, selectionType) {
      if (selectionType === 'category') {
        if (data.selections[0] === this.selectedCategory.requestParam) {
          return;
        }
        this.selectedCategory = this.categoryList.find(
          (category) => category.requestParam === data.selections[0]
        );
        this.selectedSubCategory = {};
        this.selectedBrowseNode = this.selectedCategory.requestParam;
        this.categoryFilterKey++;
      } else if (selectionType === 'sub_category') {
        if (data.selections.length === 0) {
          this.selectedSubCategory = {};
          this.selectedBrowseNode = this.selectedCategory.requestParam;
        } else if (data.selections[0] === this.selectedSubCategory.name) {
          return;
        } else {
          let browseNode;
          this.selectedSubCategory = (
            this.selectedCategory.subCategories || []
          ).reduce((previousVal, currentVal) => {
            if (currentVal.name === data.selections[0]) {
              previousVal.values = {
                sub_category: [currentVal.name]
              };
              browseNode = currentVal.requestParam;
            }
            return previousVal;
          }, {});
          this.selectedBrowseNode = browseNode;
        }
        this.categoryFilterKey++;
      }
    },
    saveFilter() {
      localStorage.removeItem(this.filterLocalStorageKey);
      localStorage.removeItem(`${this.filterLocalStorageKey}-order`);
      let storage = JSON.parse(
        localStorage.getItem(this.filterLocalStorageKey)
      );
      const storageValues = this.filterInstance;
      if (!storage) {
        storage = {};
        storage = storageValues.values;
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify(storageValues.values)
        );
        localStorage.setItem(
          `${this.filterLocalStorageKey}-order`,
          JSON.stringify(storageValues.order)
        );
      } else {
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify(storageValues.values)
        );
        localStorage.setItem(
          `${this.filterLocalStorageKey}-order`,
          JSON.stringify(storageValues.order)
        );
      }
    },
    defaultCategoryV1() {
      let dimensionList = [];
      this.dimensionMapping.map((item) => {
        if (item?.dimensionLabel) {
          dimensionList.push(item.dimensionName);
        }
      });
      this.categoryDimensionList = dimensionList;
      let breakFlag = false;
      for (const topCategory of this.categoryLandscapeService
        ?.topCategoriesData || []) {
        const categoryName = topCategory[topCategory?.entityType];
        for (let dimension of dimensionList) {
          const found = this.categoryList.find(
            (category) => category.request_param === categoryName
          );
          if (found) {
            this.filterInstance.values = {};
            this.filterInstance.values[dimension] = [found?.request_param];
            this.filterInstance.order = [dimension];
            breakFlag = true;
            break;
          }
        }
        if (breakFlag) {
          break;
        }
      }
      if (!breakFlag) {
        const dimension = dimensionList[0];
        const defaultCategory = this.categoryList.find(
          (category) => category[dimension] !== null
        );
        if (defaultCategory) {
          this.filterInstance.values = {};
          this.filterInstance.values[dimension] = [
            defaultCategory?.request_param
          ];
          this.filterInstance.order = [dimension];
        }
      }
      this.saveFilter();
    },
    defaultCategoryV2() {
      let breakFlag = false;
      for (const topCategory of this.categoryLandscapeService
        ?.topCategoriesData || []) {
        const categoryName = topCategory.category_name;
        const found = this.categoryList.find(
          (category) => category.name === categoryName
        );
        if (found) {
          this.selectedCategory = found;
          breakFlag = true;
          break;
        }
      }
      if (!breakFlag) {
        this.selectedCategory = this.categoryList[0];
      }
      this.filterDataFetched = true;
      this.selectedBrowseNode = this.selectedCategory.requestParam;
    }
  }
};
</script>

<style lang="css" scoped>
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
.overview-card {
  min-height: 300px;
  max-height: 400px;
}
.boxShadow {
  box-shadow: 0 0 4px 0 #caccce;
}
.is-expanded {
  position: relative;
}
.is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 3.2em;
  width: 100%;
  position: absolute;
  bottom: -2.4em;
  left: 0em;
  box-shadow: -1px 0px 11px -2px #caccce;
  clip-path: inset(0px -2px 0px -2px);
  z-index: 1;
}
.u-z-index-highest {
  z-index: 9999;
}
.leaders-export-btn {
  padding: 4px 5px 0 5px;
}
.loading-icon {
  height: calc(100vh - 150px);
}
</style>

<style lang="css">
.rem-overlay-category-filter .filter--token {
  font-size: 1.3rem;
  padding: 8px 12px;
}
</style>
