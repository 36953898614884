<template>
  <div class="u-background-white u-spacing-p-m u-text-align-center">
    <img
      :src="imageUrl"
      alt="retailer image"
      class="retailer-img"
    />
  </div>
</template>

<script>
export default {
  name: 'RetailerImage',
  props: {
    retailerDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageUrl() {
      return `/images/${this.retailerDetails.name}.png`;
    }
  }
};
</script>

<style scoped lang="css">
.retailer-img {
  width: 200px;
  width: 80px;
}
</style>
