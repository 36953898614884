import entityConfigBuilder from '@/pages/toolsAndSettings/entityConfigBuilder.js';
import { Entities } from '@/pages/toolsAndSettings/const.js';

const readOnlyColumns = {
  amazon: [
    { key: 'campaign_name', altKey: 'campaign_name', value: 'Campaign Name' },
    { key: 'profile_name', altKey: 'profile_name', value: 'Profile' },
    { key: 'portfolio_name', altKey: 'portfolio_name', value: 'Portfolio' }
  ],
  walmart: [
    { key: 'campaign_name', altKey: 'campaign_name', value: 'Campaign Name' },
    { key: 'advertiser_name', altKey: 'advertiser_name', value: 'Advertiser' }
  ],
  instacart: [
    { key: 'campaign_name', altKey: 'campaign_name', value: 'Campaign Name' }
  ],
  kroger_promoteiq: [
    { key: 'campaign_name', altKey: 'campaign_name', value: 'Campaign Name' },
    { key: 'piq_vendor_name', altKey: 'brand', value: 'Brand' } // added 'altKey' to create columns for add new filter page. 'key' is used to create columns for editable dynamic table
  ]
};

const commonCubeDetailsConfig = {
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  orderByList: [],
  limit: 20000,
  page: 1
};

const retailerSpecificEntityCubeDetails = (retailer) => {
  return {
    metrics: readOnlyColumns[retailer].map(({ key }) => key),
    entityColumn: 'campaign_id'
  };
};

const editableTableConfig = {
  amazon: {
    entityName: Entities.campaign,
    filterCubeName: 'ams_campaigns_taxonomy',
    filterConfig: 'feature.pages.amazon_campaign_taxonomy.enable'
  },
  walmart: {
    entityName: Entities.campaign,
    filterCubeName: 'walmart_campaigns_taxonomy',
    filterConfig: 'feature.pages.walmart_campaign_taxonomy.enable'
  },
  kroger_promoteiq: {
    entityName: Entities.campaign,
    filterCubeName: 'promoteiq_campaigns_taxonomy',
    filterConfig: 'feature.pages.kroger_promoteiq_campaign_taxonomy.enable'
  },
  instacart: {
    entityName: Entities.campaign,
    filterCubeName: 'instacart_campaigns_taxonomy',
    filterConfig: 'feature.pages.instacart_campaign_taxonomy.enable'
  }
};

function getColumnConfigs(columns) {
  return columns.reduce((acc, col, index) => {
    acc[col.key] = (columnContext) => {
      return {
        widget: 'dynamicCellComponent',
        isFixed: 'left',
        dynamicCellComponentParams: {
          componentName: 'dataCell',
          paramsToProps: (params) => {
            return {
              params,
              type: 'string',
              value: params.data[col.key],
              tooltipText: params.data[col.key]
            };
          }
        },
        headerComponent: {
          bDynamicHeaderComponent: true,
          componentName: 'dataHeader',
          props: {},
          columnDefsToProps: (params) => {
            return {
              displayName: col.value,
              params: params,
              checkboxSelection: !index,
              alignHeader: 'left',
              enableSorting: true
            };
          }
        },
        showOnUi: true,
        isDownloadable: true,
        sortOnColumn: false,
        isDefaultSortColumn: false,
        checkboxSelection: !index,
        width: !index ? 350 : 150
      };
    };
    return acc;
  }, {});
}

const config = {
  label: 'campaign taxonomy',
  entity: Entities.campaign,
  sheetName: 'Campaign taxonomy',
  path: `${Entities.campaign}/tag`,
  altLabel: 'Campaign Taxonomy',
  entityName: 'campaign',
  primaryKey: 'campaign_id',
  secondaryKey: 'entity_id',
  sectionDesc:
    'Download the campaign taxonomy template, make your changes and upload the template.',
  columnConfigs: (retailer) => getColumnConfigs(readOnlyColumns[retailer]),
  actionsBar: (context) => {
    const columnDropdownOptions =
      context.previewData?.dimensionValueAndCountList?.map(
        ({ dimensionLabel, dimensionColumn, availableValues }) => ({
          value: dimensionColumn,
          title: dimensionLabel,
          suggestions: availableValues
        })
      );
    return {
      props: {
        actionBarActions: [
          {
            icon: 'pencil',
            title: 'Edit Value',
            id: 'edit-status',
            customState: {
              component: 'ColumnValueEdit',
              props: {
                columnDropdownOptions,
                getHeader: (count) =>
                  `Edit Values (${count} campaign${
                    count > 1 ? 's' : ''
                  } selected)`
              },
              events: {
                afterAPIActionToCall(payload) {
                  this.removeCustomCustomComponents();
                  this.closeActionsWorkflow();
                  context.editColumns(payload);
                },
                closePanelMethod() {
                  this.closeActionsWorkflow();
                  context.handleSelectionChange([], true);
                }
              }
            }
          }
        ],
        isCustomActionStateVisible: context.isCustomActionStateVisible,
        selections: context.rowSelections
      },
      events: {
        deselectSelections: (selections) => {
          // this is on x click in actions bar;
          context.handleSelectionChange(selections, true);
        },
        openSnackbar: context.openSnackbar,
        toggleCustomActionState: context.toggleCustomActionState
      }
    };
  },
  readOnlyColumns,
  download: 'downloadTemplate',
  editableTableConfig: (retailer) => editableTableConfig[retailer],
  esDataSetName: 'campaigns_filter', // used in <addEntity />
  infoText: 'are present in Campaign Taxonomy', // used in selectTaxonomy.vue
  selectTaxonomyLabel: 'Taxonomy',
  defaultColumnWidth: 150
};

const getDataAndComputedProps = (context, retailer) => {
  const baseConfig = entityConfigBuilder({ config, context, retailer });
  return {
    ...baseConfig,
    computedProps: {
      ...baseConfig.computedProps,
      actionsBar: config.actionsBar
    }
  };
};

export default {
  config,
  getDataAndComputedProps,
  retailerSpecificEntityCubeDetails,
  editableTableConfig
};
