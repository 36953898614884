<template>
  <!-- use toolTipData for content that resides inside the tool tip and body for content in your component -->
  <div>
    <div
      :id="id"
      v-tippy-html
    >
      <slot name="toolTipData">
        <div
          v-if="toolTipData"
          :class="toolTipData.class || []"
        >
          {{ toolTipData.title || 'NA' }}
        </div>
      </slot>
    </div>
    <slot
      name="body"
      :tippyConfig="localTippyObject"
    >
      <!-- Use the v-tippy directive for a custom component in your file with the help of slot and slot-scopes
            use slot="body" & slot-scop="{tippyConfig}" -->
      <div
        v-if="body"
        v-tippy="localTippyObject"
        :class="body.class || []"
      >
        {{ body.title || 'NA' }}
      </div>
    </slot>
  </div>
</template>

<script>
import { merge } from 'lodash';
export default {
  name: 'CustomToolTip',
  props: {
    toolTipData: {
      type: Object,
      default: () => {
        return {
          title: 'Default title tippy title',
          class: []
        };
      }
    },
    body: {
      type: Object,
      default: () => {
        return {
          title: 'Default body',
          class: []
        };
      }
    },
    tippy: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    id: null
  }),
  computed: {
    localTippyObject() {
      // default config.
      let tippyObj = {
        theme: 'light',
        duration: [0, 0],
        placement: 'bottom-start',
        offset: '60, 0',
        delay: [500, 0],
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: true
            }
          }
        }
      };
      // need not re-write all the properties in the config with the help of merge.
      tippyObj = merge(tippyObj, this.tippy);
      // ensuring html, reactive and interactive are always present. (necessary for multiple custom tippys on the same page.)
      tippyObj.html = `#${this.id}`;
      tippyObj.reactive = true;
      tippyObj.interactive = true;
      return tippyObj;
    }
  },
  created() {
    this.id = 'customToolTip-' + this._uid;
  }
};
</script>
