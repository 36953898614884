import { render, staticRenderFns } from "./double-headed-title.vue?vue&type=template&id=2817c0e0&scoped=true&"
import script from "./double-headed-title.vue?vue&type=script&lang=js&"
export * from "./double-headed-title.vue?vue&type=script&lang=js&"
import style0 from "./double-headed-title.vue?vue&type=style&index=0&id=2817c0e0&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2817c0e0",
  null
  
)

export default component.exports