<template>
  <div class="retailer-breakdown-container">
    <span class="u-font-size-5 u-font-weight-600">Retailer Breakdown</span>
    <div
      :id="id"
      class="retailer-chart u-spacing-mt-m"
    />
  </div>
</template>

<script>
import chart from '@/components/basic/chart.vue';
import c3 from 'c3';

export default {
  name: 'RetailerBreakDown',
  components: {
    chart
  },
  props: {
    id: {
      type: String,
      default: 'retailer'
    },
    barData: {
      type: Array,
      required: true
    },
    groupColors: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartConfig() {
      return {
        bindto: `#${this.id}`,
        data: {
          columns: this.barData,
          type: 'bar',
          groups: this.groups,
          colors: this.groupColors
        },
        bar: {
          width: 30
        },
        axis: {
          x: {
            type: 'category',
            categories: this.categories
          },
          y: {
            show: false
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          show: true
        },
        grid: {
          y: {
            lines: [{ value: 0 }]
          }
        }
      };
    }
  },
  mounted() {
    c3.generate(this.chartConfig);
  }
};
</script>

<style scoped lang="css">
.retailer-breakdown-container {
  background: #fafcff;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  margin: auto;
  padding: 10px;
  margin-top: 10px;
}
.retailer-chart {
  height: 100px;
}
</style>
