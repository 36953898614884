export const STANDARD_PREFIX = 'standard~';
export const CUSTOM_PREFIX = 'custom~';
export const STANDARD_PATH = ['STANDARD'];
export const CUSTOM_PATH = ['CUSTOM'];
export const KEYWORDS_PATH = ['KEYWORDS'];
export const BRANDS_EXCLUSION_LIST = ['UNKNOWN'];
export const SNAPSHOT_WIDGET_WRAPPER_CLASS = 'snapshot-widget';
export const HIERARCHY_RAW_DELIMITER = '----';
export const HIERARCHY_FILTER_DELIMITER = '~';
export const MY_BRANDS = 'My Brands';
export const OVERALL_BUSINESS = 'Overall Business';
export const RULE_BUILDER_SOV_ANCHOR = 'SOV';
export const SOV_FILTER_APPLY = 'sov-filter-apply';
export const SOV_DRIVERS_FILTER_APPLY = 'sov-widget5-filter-apply';
export const BRAND_BY_CLIENT_FLAG = 'brand_by_client_flag';
export const BRAND = 'brand';
export const OTHERS = 'Others';
