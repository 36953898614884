import Vue from 'vue';
import utils from '@/utils/helpers/';
import detailViewConfig from '@/components/pages/recommendations/detailViewConfig.js';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  data() {
    return {
      showRemovedRecommendations: false,
      showRemoveAlertForm: false,
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      ),
      utils: utils,
      detailViewConfig: detailViewConfig
    };
  },
  computed: {
    internalUserCheck() {
      return this.utils.internalUserCheck(window.user);
    },
    showAllSpecificColumn() {
      return (
        this.fetchedRecommendationType === 'search' ||
        this.fetchedRecommendationType === 'asin' ||
        this.fetchedRecommendationType === 'brand'
      );
    },
    fetchedRecommendationIndex() {
      const recommendationTypes = this.$store.getters.getRecommendationTypes;
      return recommendationTypes.findIndex(
        (typeObject) =>
          typeObject.recommendationType === this.fetchedRecommendationType
      );
    },
    fetchedRecommendationDetails() {
      const recommendationTypes = this.$store.getters.getRecommendationTypes;
      const detailObject =
        recommendationTypes.filter(
          (typeObject) =>
            typeObject.recommendationType === this.fetchedRecommendationType
        )[0] || {};
      return detailObject;
    },
    fetchedRecommendationDescription() {
      return this.$store.getters.getFetchedRecommendationDescription;
    },
    fetchedRecommendationName() {
      return this.$store.getters.getFetchedRecommendationName;
    },
    fetchedRecommendationType() {
      return this.$store.getters.getFetchedRecommendationType;
    },
    fetchedBcRecommendationType() {
      return this.$store.getters.getFetchedBcRecommendationType;
    },
    fetchedRecommendationPrimaryKey() {
      return this.$store.getters.getPrimaryKeyType;
    },
    fetchedInternalKey() {
      return this.$store.getters.getInternalKey;
    },
    selectedAlert() {
      return this.$store.getters.getSelectedAlert;
    },
    jumpedFromAll() {
      return this.$store.getters.isRedirectedFromAllSection;
    }
  },
  methods: {
    selectAlert(params) {
      let templates = this.detailViewConfig.detailViewTemplates;
      // Converting all template keys to lowercase.
      templates = Object.keys(templates).reduce(function (result, key) {
        result[key.toLowerCase()] = templates[key];
        return result;
      }, {});
      this.$store.commit('CLEAR_REDIRECTION_FROM_ALL');
      if (
        templates[this.fetchedRecommendationName.toLowerCase()] === undefined
      ) {
        this.$snackbar.open({
          message:
            '<div class="u-spacing-pv-xs">Comprehensive detail view of this recommendation is not available at the present.</div><div class="u-spacing-pv-xs">If any important metric is missing in the tabular list view, please let us know via product feedback.</div>',
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      } else {
        const queryParams = {
          name: this.fetchedRecommendationName,
          type: this.fetchedRecommendationType,
          bc_recommendation_type: this.fetchedBcRecommendationType,
          internal_key: params.internal_key
        };
        this.$router.push({
          query: queryParams
        });
      }
    },
    selectAlertFromAll(params, type) {
      this.$store.commit('SET_REDIRECTION_FROM_ALL');
      const highlightData = {
        keyToSearch: this.fetchedRecommendationPrimaryKey,
        valueToMap: params[this.fetchedRecommendationPrimaryKey]
      };
      this.$store.dispatch('setHighlightRow', highlightData);
      const templates = this.detailViewConfig.detailViewTemplates;
      const routeParams = {
        name: type.recommendationName,
        type: type.recommendationType,
        bc_recommendation_type: type.bc_recommendation_type
      };
      /**
       * To highlight in the new tab
       */
      if (Vue.prototype.$eventKey === true) {
        routeParams.selectedAsin = params.asin;
      }
      if (templates[type.recommendationName]) {
        routeParams[this.fetchedRecommendationPrimaryKey] =
          params[this.fetchedRecommendationPrimaryKey];
      }
      this.$router.push({
        name: type.recommendationGroup,
        query: routeParams
      });
    },
    getObjectFromAsin(asin) {
      const recommendations = this.recommendations;
      const results = recommendations.filter((item) => {
        return item.attributes.asin === asin;
      });
      return results[0];
    },
    getColumnHeaderFromAlertType() {
      if (this.fetchedBcRecommendationType === 'brand') {
        return 'Brand';
      } else if (
        this.fetchedBcRecommendationType === 'search' ||
        this.fetchedBcRecommendationType === 'keyword_text'
      ) {
        return 'Keyword';
      } else if (this.fetchedBcRecommendationType === 'campaign') {
        return 'Campaign';
      } else if (this.fetchedBcRecommendationType === 'search_term') {
        return 'Search Term';
      } else {
        return 'SKU Details';
      }
    },
    download() {
      var fileRows = [];
      var columns;
      var dateKeys = [];
      if (this.fetchedBcRecommendationType === 'brand') {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            uiKey: 'bc_brand_alert_type',
            uiLabel: this.getColumnHeaderFromAlertType()
          }
        ];
      } else if (this.fetchedBcRecommendationType === 'search_term') {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            uiKey: 'search_term',
            uiLabel: this.getColumnHeaderFromAlertType()
          }
        ];
      } else if (this.fetchedBcRecommendationType === 'keyword_text') {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            uiKey: 'keyword_text',
            uiLabel: this.getColumnHeaderFromAlertType()
          }
        ];
      } else if (this.fetchedBcRecommendationType === 'campaign') {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            uiKey: 'campaign_name',
            uiLabel: this.getColumnHeaderFromAlertType()
          }
        ];
      } else if (this.fetchedBcRecommendationType === 'search') {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            tableField: 'SEARCH',
            uiKey: 'search',
            uiLabel: 'Keyword'
          }
        ];
      } else {
        columns = [
          {
            keyOrder: 7,
            keyType: 'string',
            tableField: 'ASIN',
            uiKey: 'asin',
            uiLabel: 'ASIN'
          },
          {
            keyOrder: 10,
            keyType: 'string',
            tableField: 'TITLE',
            uiKey: 'title',
            uiLabel: 'Title'
          },
          {
            keyOrder: 9,
            keyType: 'string',
            tableField: 'PRODUCT_URL',
            uiKey: 'product_url',
            uiLabel: 'Product URL'
          }
        ];
      }
      if (this.currentSelectedGroup !== 'All') {
        columns = columns.concat({
          keyOrder: 11,
          keyType: 'string',
          tableField: 'ACTION_STATE',
          uiKey: 'action_state',
          uiLabel: 'Action status'
        });
      }
      // Download only Enabled Columns from Manage Columns Section.
      columns = columns.concat(
        this.$store.getters.getColumns
          .map((a) => {
            // we use map here to copy the array.
            // the original array's sort function is vuex's version which can cause side effects.
            // we want the default Array.sort for the next step
            return a;
          })
          .sort((a, b) => {
            return a.keyOrder - b.keyOrder;
          })
      );

      if (
        this.$store.getters.getCurrentSelectedRecommendation &&
        this.$store.getters.getCurrentSelectedRecommendation
          .recommendationType === 'SNS drop and onAddon and onPrimeEx'
      ) {
        const linkIndex = columns.findIndex((a) => {
          return a.uiKey === 'additional_details';
        });
        columns.splice(linkIndex + 1, 0, {
          keyOrder: 11,
          keyType: 'string',
          tableField: 'keyword_url',
          uiKey: 'keyword_url',
          uiLabel: 'Additional Details Link'
        });
      }

      if (
        this.$store.getters.getCurrentSelectedRecommendation &&
        this.$store.getters.getCurrentSelectedRecommendation
          .recommendationType === 'Non Product Negative Reviews'
      ) {
        const linkIndex = columns.findIndex((a) => {
          return a.uiKey === 'review_title';
        });
        columns.splice(linkIndex + 1, 0, {
          keyOrder: 11,
          keyType: 'string',
          tableField: 'review_title_url',
          uiKey: 'review_title_url',
          uiLabel: 'Review Title Link'
        });
        const reviewerNameIndex = columns.findIndex((a) => {
          return a.uiKey === 'reviewer_name';
        });
        columns.splice(reviewerNameIndex + 1, 0, {
          keyOrder: 11,
          keyType: 'string',
          tableField: 'reviewer_name_url',
          uiKey: 'reviewer_name_url',
          uiLabel: 'Reviewer Name Link'
        });
      }

      var columnLabels = columns.map((column) => {
        return column.uiLabel;
      });
      var columnKeys = columns.map((column) => {
        if (column.keyType === 'date') {
          dateKeys.push(column.uiKey);
        }
        return column.uiKey;
      });
      fileRows.push(
        columnLabels
          .map((header) => {
            return '"' + header.replace(/"/g, '""') + '"';
          })
          .join(',')
      );

      var recommendations = this.$store.getters.getRecommendations;
      for (var recommendation of recommendations) {
        fileRows.push(
          columnKeys
            .map((key) => {
              if (
                recommendation.attributes[key] === undefined ||
                recommendation.attributes[key] === null
              ) {
                return '"NA"';
              } else if (dateKeys.indexOf(key) !== -1) {
                return (
                  '"' +
                  this.format(recommendation.attributes[key], 'date').replace(
                    /"/g,
                    '""'
                  ) +
                  '"'
                );
              } else if (key === 'recommended_actions') {
                const actionsObject = JSON.parse(
                  recommendation.attributes[key]
                );
                const arrToRet = [];
                Object.keys(actionsObject).forEach((actionKey) => {
                  var toSplitArray = actionsObject[actionKey].split(',');
                  arrToRet.push(toSplitArray.join('. '));
                });
                return arrToRet.join();
              } else if (
                recommendation.attributes.alert_type === 'Content change all' &&
                key === 'new_text' &&
                recommendation.attributes.content_change_type !==
                  'Change in number of images'
              ) {
                // var regex1 = /(<del>(.*?)<\/del>)/g;
                // var regex = /(<ins[^>]+>|<ins>|[^a-z]*<\/ins>)/g;
                // var text = recommendation.attributes['new_text'];
                // text = text.replace(regex1, '');
                // text = text.replace(regex, '');
                // return '"' + ('' + recommendation.attributes[key]).replace(/"/g, '""') + '"';
                // Hacky Way to Resolve it. Not sure why regex not replacing all the occurance. - PROD-66528 Fix
                return (
                  '"' +
                  (
                    '' + recommendation.attributes.new_text_withOutChange
                  ).replace(/"/g, '""') +
                  '"'
                );
              } else if (
                recommendation.attributes.alert_type ===
                  'SNS drop and onAddon and onPrimeEx' &&
                key === 'additional_details' &&
                (recommendation.attributes.event_type ===
                  'Dropped Amazon Choice badge' ||
                  recommendation.attributes.event_type ===
                    'Dropped Best Seller badge')
              ) {
                if (
                  recommendation.attributes.event_type ===
                  'Dropped Best Seller badge'
                ) {
                  return (
                    '"' +
                    (
                      '' +
                      'Category : ' +
                      recommendation.attributes[key]
                    ).replace(/"/g, '""') +
                    '"'
                  );
                }
                return (
                  '"' +
                  ('' + 'Keyword : ' + recommendation.attributes[key]).replace(
                    /"/g,
                    '""'
                  ) +
                  '"'
                );
              } else if (
                recommendation.attributes.alert_type ===
                  'Non Product Negative Reviews' &&
                key === 'link'
              ) {
                return (
                  '"' +
                  ('' + recommendation.attributes.link_url).replace(
                    /"/g,
                    '""'
                  ) +
                  '"'
                );
              } else if (
                recommendation.attributes.alert_type === 'Change in Variants' &&
                key === 'new_variant_list'
              ) {
                return (
                  '"' +
                  (
                    '' +
                    recommendation.attributes.new_variant_list_withOutChange
                  ).replace(/"/g, '""') +
                  '"'
                );
              } else {
                return (
                  '"' +
                  ('' + recommendation.attributes[key]).replace(/"/g, '""') +
                  '"'
                );
              }
            })
            .join(',')
        );
      }

      var file = fileRows.join('\n');
      var blob = new Blob([file], { type: 'text/csv;charset=utf-8;' });
      // var blob = new Blob([file], {type: 'application/vnd.ms-excel'});
      var tempelement = document.createElement('a');
      tempelement.href = URL.createObjectURL(blob);
      tempelement.setAttribute(
        'download',
        this.fetchedRecommendationType + '.csv'
      );
      // tempelement.setAttribute('download', this.fetchedRecommendationType + '.xlsx')
      tempelement.style.display = 'none';
      document.body.appendChild(tempelement);
      tempelement.click();
      document.body.removeChild(tempelement);
    },
    format(value, format, roundOff = 2) {
      if (value === undefined || value === null) {
        return 'NA';
      }
      if (format === 'string') {
        return value;
      } else if (format === 'currency') {
        return formatter(value, 'currency', undefined, roundOff);
      } else if (format === 'percent') {
        return formatter(value, 'percent');
      } else if (format === 'date') {
        // return new Date(value).toLocaleString('en-US', { timeZone: 'UTC' }).split(',')[0]
        // Format Change for KC-UK
        if (value) {
          return formatter(value, 'date');
        } else {
          return '--';
        }
      } else if (format === 'percentFraction') {
        return formatter(value, 'percentFraction');
      } else if (utils.isNumeric(value) && format === 'number') {
        return formatter(value, 'number');
      } else if (format === 'datetime') {
        return formatter(value, 'datetime');
      } else {
        return value.toString();
      }
    }
  }
};
