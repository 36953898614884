<template>
  <div
    class="u-display-flex u-flex-align-items-center u-spacing-mt-l"
    :class="{
      'u-spacing-pt-m u-spacing-pl-m': realTimeToggleValue,
      'u-spacing-pt-s': !realTimeToggleValue
    }"
  >
    <div
      v-if="realTimeToggleValue"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-start"
    >
      <span>
        <rb-icon
          icon="in-progress-dotted"
          class="rb-icon rb-icon--medium u-color-blue-base u-margin-bottom-10"
        />
      </span>
      <span class="u-font-size-5 realTimeText">Real-time Data: Today</span>
      <PvpTimeseriesSwitch
        :class="
          realTimeToggleValue
            ? 'compare_to_Yesterday_switch u-spacing-ml-m'
            : ''
        "
        :value="YesterdayMode"
        :label="{ text: 'Compare to Yesterday' }"
        @onSwitchChange="onYesterdayModeChange"
      />
    </div>
    <div
      v-else
      class="u-display-flex u-flex-align-items-center"
    >
      <roll-up-by
        label="Roll up by:"
        :value="selectedRollUp"
        :options="rollUps"
        @onRollUpChange="onRollUpChange"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    realTimeToggleValue: {
      type: Boolean,
      default: false
    },
    rollUps: {
      type: Array,
      default: () => []
    },
    selectedRollUp: {
      type: String,
      default: null
    },
    entity: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      YesterdayMode: false,
      disableStreamToggle: false
    };
  },
  created() {
    this.disableStreamToggle = Vue.options.filters.config_check(
      'feature.pages.disableStreamChart'
    );
  },
  methods: {
    onYesterdayModeChange(val) {
      this.YesterdayMode = val;
      this.$emit('onYesterdayModeChange', val);
    },
    onRollUpChange(selections) {
      this.$emit('onRollUpChange', selections);
    }
  }
};
</script>

<style scoped>
.realTimeText {
  margin-left: 5px;
}
</style>
