<template>
  <b-pagination
    v-if="total > perPage"
    :config="config"
    :total="total"
    :current="current"
    :rounded="true"
    :per-page="perPage"
    @change="pageChanged"
  />
</template>

<script>
var oObject = {
  props: {
    handleAPI: {
      default: true,
      type: Boolean
    },
    config: Object,
    change: Function,
    total: Number,
    size: {
      type: String,
      default: 'is-small'
    },
    simple: {
      type: Boolean,
      default: false
    },
    rounded: Boolean,
    perPage: {
      default: 5,
      type: Number
    },
    current: Number
  },
  methods: {
    pageChanged(page) {
      if (
        this.handleAPI &&
        this.config &&
        this.config.body &&
        this.config.body.APIConfig
      ) {
        this.config.body.APIConfig.page = page;
        var action = this.config.meta.action;
        if (this.config.meta.alternateAction) {
          action = this.config.meta.alternateAction;
        }
        this.$store.dispatch(action, this.config);
      } else if (this.change) {
        this.change(page, this.config);
      }
    }
  }
};
export default oObject;
</script>

<style></style>
