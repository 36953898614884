<template>
  <div
    class="toolbox-card u-cursor-pointer u-border-radius-l u-spacing-p-l u-spacing-m-s u-border-width-s u-border-all u-border-color-grey-xxx-light"
    style="
      max-width: 336px;
      min-width: 336px;
      max-height: 223px;
      min-height: 223px;
    "
    @click="handleClick"
  >
    <div>
      <div
        class="u-display-flex u-font-size-1 u-flex-justify-content-center u-flex-align-items-center u-spacing-p-xs"
      >
        <rb-icon
          class="rb-icon--bp-custom u-color-blue-base u-color-blue-base"
          :icon="asset"
        />
      </div>
      <div
        class="u-text-align-center u-font-size-2 u-font-weight-600 u-color-grey-base u-display-flex u-flex-justify-content-center u-spacing-p-s"
      >
        {{ title }}
      </div>
      <div
        class="u-text-align-center u-font-size-5 u-line-height-1-3 u-display-flex u-color-grey-mid-light u-flex-justify-content-center u-spacing-p-s"
      >
        {{ subtext }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    asset: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit('click', this.redirect);
    }
  }
};
</script>

<style lang="css" scoped>
.toolbox-card:hover {
  border-color: #007cf6;
}
.rb-icon--bp-custom {
  height: 56px;
  width: 56px;
}
.rb-icon--bp-custom:before {
  font-size: 56px;
}
</style>
