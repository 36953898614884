<template>
  <section
    class="campaign-creation-edit u-position-fixed widget--full-screen"
    @click.stop="removeFocus"
  >
    <loader
      v-show="loading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
      style="z-index: 300"
    />
    <div class="u-position-fixed model u-width-100 campaign-creation-edit">
      <!-- Campaign Name Edit Start -->
      <section
        v-if="setActiveSelection !== 0"
        class="campaign-creation-edit--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
        >
          <div
            class="u-display-flex u-flex-align-items-center"
            style="height: 2.4rem"
          >
            <span @click="closeWithoutSave()">
              <rb-icon
                :icon="'cross'"
                data-cy="crossIcon"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <div
              class="u-display-flex"
              :style="{
                width: showEdit
                  ? campaignName.length === 0
                    ? '23ch'
                    : campaignName.length * 11.5 < 800
                    ? `${campaignName.length * 11}px`
                    : '60%'
                  : '100%'
              }"
            >
              <input
                ref="campaign-creation-input"
                v-model="campaignName"
                type="text"
                placeholder="Untitled"
                data-cy="campaignNameInput"
                class="heading--input u-width-100 u-font-size-2 u-font-weight-bold text-underline"
                :class="{
                  'u-pointer-events-none u-text-overflow-ellipsis text-underline-color-white':
                    showEdit || campaignName.length === 0,
                  'light-grey-placeholder': campaignName.length === 0
                }"
                style="padding-left: 2px"
                @click.self.stop="() => {}"
              />
            </div>
            <div
              v-if="showEdit"
              class=""
              @click.stop="onEditCampaignName"
            >
              <rb-icon
                data-cy="pencilIcon"
                class="rb-icon--x-medium u-cursor-pointer u-spacing-ml-s u-color-grey-lighter"
                :icon="'pencil'"
              />
            </div>
          </div>

          <FormErrorMessage
            class="u-spacing-ml-l"
            :show-error="Boolean(campaignNameErrorMessage)"
            :error-text="campaignNameErrorMessage"
          />
        </div>
      </section>
      <!-- Campaign Name Edit ends -->
      <!-- Header Start -->
      <section
        v-else
        class="campaign-creation-setup--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span @click="closeWithoutSave()">
              <rb-icon
                :icon="'cross'"
                data-cy="crossIcon"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <span
              data-cy="campaignCreateHeader"
              class="u-font-size-1 u-font-weight-600"
              >{{ header && header.title }}
            </span>
          </div>
          <span
            class="u-font-size-4 u-color-grey-mid-light u-spacing-pt-s u-spacing-ml-l"
            >{{ header && header.desc }}
          </span>
        </div>
      </section>
      <!-- Header ends -->
      <!-- Scroll section -->
      <StepComponent
        :config="stepsConfig"
        :panel-width="10"
        :all-steps-data="pageData"
        :enabled-bread-crumbs="[]"
        :show-step-bullet-description="true"
        bullet-point-class="u-spacing-pt-m"
        all-bullet-points-class="marign-top-campaign-creation-bullets"
        section-right-class="u-display-flex u-flex-direction-column u-height-100"
        class="u-width-100"
        :set-active-selection="setActiveSelection"
        @activeSelectionUpdated="activeSelectionUpdated"
      />
      <!-- Scroll section -->
      <loader
        v-show="false"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import loader from '@/components/basic/loader.vue';
import modal from '@/components/widgets/modal.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
import { cloneDeep } from 'lodash';

export default {
  components: {
    StepComponent,
    loader,
    modal,
    FormErrorMessage
  },
  mixins: [stepsMixin],
  data() {
    return {
      stepsConfig: [],
      initialClonedConfigFromFile: {},
      header: {},
      pageData: null,
      setActiveSelection: 0,
      showEdit: true,
      campaignName: '',
      selectedCampaignType: 'sponsoredProducts',
      campaignTargetingType: 'manual',
      loading: false,
      campaignId: null,
      adGroupId: null,
      advertiserId: null,
      walmartCampaignCreateDataService: {},
      campaignNameFilledFirstTimeOnFetch: false,
      campaignNameErrorMessage: '',
      campaignNameAlreadyExists: false
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters?.[this.pageData?.getter] || {};
    },
    isCampaignAlreadyCreated() {
      const { campaignId } = this.$route.query;
      if (campaignId) {
        return true;
      }
      return false;
    }
  },
  watch: {
    allStepsData: {
      handler(newVal) {
        const { campaignType, targetingType, fetchedCampaignResponse } = newVal;
        if (this.selectedCampaignType !== campaignType && campaignType) {
          this.selectedCampaignType = campaignType;
          this.setConfigs();
        }
        if (this.campaignTargetingType !== targetingType && targetingType) {
          this.campaignTargetingType = targetingType;
          this.setConfigs();
        }
        if (
          fetchedCampaignResponse &&
          fetchedCampaignResponse?.name &&
          !this.campaignNameFilledFirstTimeOnFetch
        ) {
          // This condition will only hit once. When the response is filled in allStepsData,
          // we update the campaignName and change the boolean of campaignNameFilledFirstTimeOnFetch to true
          // So that we do not keep updating the campaign name on every allStepsDataChange
          this.campaignName = fetchedCampaignResponse?.name;
          this.campaignNameFilledFirstTimeOnFetch = true;
          this.loading = false;
        }
      },
      deep: true,
      immediate: true
    },
    '$route.query': {
      handler: function () {
        this.initializeDataService();
      },
      deep: true,
      immediate: true
    },
    selectedCampaignType(newVal) {
      this.appendToQueryParams({
        campaignType: newVal
      });
    },
    campaignTargetingType(newVal) {
      this.appendToQueryParams({
        targetingType: newVal
      });
    },
    campaignName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateCampaignName();
        this.checkCampaignNameValidity();
      }
    },
    campaignNameAlreadyExists() {
      this.updateCampaignNameAlreadyExists();
    }
  },
  created() {
    this.initialClonedConfigFromFile = cloneDeep(config);
    this.loading = true;
    this.setConfigs();
    if (
      (this.$route.query?.campaignType === 'sponsoredBrands' ||
        this.$route.query?.campaignType === 'sponsoredProducts') &&
      (this.$route.query?.targetingType === 'auto' ||
        this.$route.query?.targetingType === 'manual')
    ) {
      const allStepsData = { ...this.allStepsData };
      allStepsData.campaignType = this.$route.query?.campaignType;
      allStepsData.targetingType = this.$route.query?.targetingType;
      this.$store.dispatch(this.pageData.action, { ...allStepsData });
    } else {
      this.appendToQueryParams({
        campaignType: this.selectedCampaignType,
        targetingType: this.campaignTargetingType
      });
    }
    this.updateCampaignName();
    this.initializeDataService();
    this.validateCampaignAvailability();
    this.updateCampaignNameAlreadyExists();
    this.checkCampaignNameValidity();
    this.loading = false;

    // If campaign is already created, wait for campaign details to be fetched, watch for fetchedCampaignResponse
    // in the watch property and then update the campaign name accordingly.
    if (this.isCampaignAlreadyCreated) {
      this.loading = true;
    }
  },
  methods: {
    checkCampaignNameValidity() {
      // This campaign name already exists.
      let errorMessage = '';
      if (this.campaignNameAlreadyExists) {
        errorMessage = 'This campaign name already exists.';
      }
      if (!this.campaignName) {
        errorMessage = 'Please enter a campaign name';
      } else if (this.campaignName.length > 255) {
        errorMessage = 'The name should be less than 255 characters.';
      }
      this.campaignNameErrorMessage = errorMessage;
    },
    appendToQueryParams(keyValPairs) {
      const query = { ...this.$route.query, ...keyValPairs };
      this.$router.replace({ query: query });
    },
    validateCampaignAvailability() {
      if (this.isCampaignAlreadyCreated && this.setActiveSelection === 0) {
        this.setActiveSelection = this.setActiveSelection + 1;
      }
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      const retailer = this.$store.getters.getRetailer;
      this.campaignId = parseInt(campaignId) || undefined;
      this.adGroupId = parseInt(adGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      this.walmartCampaignCreateDataService =
        new WalmartCampaignCreateDataService(
          campaignId,
          adGroupId,
          advertiserId,
          campaignType,
          retailer
        );
    },
    setConfigs() {
      let selectedCampaignType = this.selectedCampaignType;
      if (this.selectedCampaignType === 'sponsoredProducts') {
        if (this.campaignTargetingType === 'manual') {
          selectedCampaignType = 'sponsoredProductsManual';
        } else if (this.campaignTargetingType === 'auto') {
          selectedCampaignType = 'sponsoredProductsAuto';
        }
      }
      const clonedConfig = this.initialClonedConfigFromFile;
      this.stepsConfig =
        clonedConfig?.[this.retailer]?.[selectedCampaignType]?.stepsConfig;
      this.header =
        clonedConfig?.[this.retailer]?.[selectedCampaignType]?.header;
      this.pageData = clonedConfig?.[this.retailer]?.[selectedCampaignType];
    },
    activeSelectionUpdated(selectionIndex) {
      this.setActiveSelection = selectionIndex;
    },
    async removeFocus() {
      this.$refs?.['campaign-creation-input']?.blur();
      this.showEdit = true;
      if (
        this.isCampaignAlreadyCreated &&
        (this.campaignNameErrorMessage === '' ||
          this.campaignNameAlreadyExists === true)
      ) {
        // If campaign is already created, then check if campaign name was updated,
        // If yes, then make an API call to update the campaign name
        const allStepsData = { ...this.allStepsData };
        const currentCampaignName = allStepsData?.campaignName;
        const fetchedCampaignName = allStepsData?.fetchedCampaignResponse?.name;
        if (
          currentCampaignName &&
          fetchedCampaignName &&
          currentCampaignName !== fetchedCampaignName &&
          this.loading !== true
        ) {
          this.loading = true;
          this.campaignNameAlreadyExists = false;
          const updateCampaignDetailsResponse =
            await this.updateCampaignDetails({
              campaignName: currentCampaignName
            });
          if (updateCampaignDetailsResponse.data?.success === 1) {
            // Adding this here because in line 327, allStepsData will change
            const allStepsData = { ...this.allStepsData };
            // If name updation was successful
            // Update the name in the fetchedResponse also
            allStepsData.fetchedCampaignResponse.name = currentCampaignName;
            this.$store.dispatch(this.pageData.action, { ...allStepsData });
          }
          if (updateCampaignDetailsResponse.data?.failure > 0) {
            // This means there was an error with the campaign name
            // Probably campaign name already exists
            this.campaignNameAlreadyExists = true;
          }
          this.checkCampaignNameValidity();
          this.loading = false;
        }
      }
    },
    onEditCampaignName() {
      this.showEdit = false;
      this.$refs['campaign-creation-input'].focus();
    },
    updateCampaignName() {
      const allStepsData = { ...this.allStepsData };
      allStepsData.campaignName = this.campaignName;
      this.$store.dispatch(this.pageData.action, { ...allStepsData });
    },
    async updateCampaignNameAlreadyExists(newVal) {
      const allStepsData = { ...this.allStepsData };
      const campaignNameAlreadyExists =
        newVal || this.campaignNameAlreadyExists;
      allStepsData.campaignNameAlreadyExists = campaignNameAlreadyExists;
      await this.$store.dispatch(this.pageData.action, { ...allStepsData });
    },
    setCampaignName() {
      this.campaignName = this.allStepsData?.campaignName;
    },
    closeWithoutSave() {
      this.$store.dispatch(this.pageData.action, {});
      // This will take the user back to campaign management page
      this.$router.push({
        name: 'Campaigns'
      });
    },
    async updateCampaignDetails(campaignUpdateObject) {
      try {
        const updateCampaignDetailsResponse =
          await this.walmartCampaignCreateDataService.updateCampaignDetails(
            campaignUpdateObject
          );
        return updateCampaignDetailsResponse;
      } catch (err) {
        console.log(err);
        throw new Error({
          err,
          reason: 'campaign_update_failed'
        });
      }
    }
  }
};
</script>

<style lang="css">
.text-underline-color-white {
  text-decoration-color: #fff !important;
}

.light-grey-placeholder::-webkit-input-placeholder {
  color: #8b8f93;
}
.light-grey-placeholder::-moz-placeholder {
  color: #8b8f93;
}
.light-grey-placeholder:-moz-placeholder {
  color: #8b8f93;
}
.light-grey-placeholder:-ms-input-placeholder {
  color: #8b8f93;
}
.light-grey-placeholder::placeholder {
  color: #8b8f93;
}
</style>
