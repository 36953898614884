import dataTransformer from '@/utils/services/data-transformer.js';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
export default {
  instacart: {
    dailyBudgetConfig: [
      {
        title: 'Set new daily budget',
        type: 'input',
        value: 'set_new_db'
      },
      {
        title: 'Increase daily budget',
        value: 'increase_db',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: 'Decrease daily budget',
        value: 'decrease_db',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: "Don't change daily budget",
        value: 'dont_change_db'
      }
    ],
    lifetimeBudgetConfig: [
      {
        title: 'Set new lifetime budget',
        type: 'input',
        value: 'set_new_lb'
      },
      {
        title: 'Increase lifetime budget',
        value: 'increase_lb',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: 'Decrease lifetime budget',
        value: 'decrease_lb',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: "Don't change lifetime budget",
        value: 'dont_change_lb'
      }
    ],
    lifetimeSpendingLimitConfig: [
      {
        title: 'Set new lifetime spending limit',
        type: 'input',
        value: 'set_new_lsl'
      },
      {
        title: 'Increase lifetime spending limit',
        value: 'increase_lsl',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: 'Decrease lifetime spending limit',
        value: 'decrease_lsl',
        type: 'dropdown',
        dropdown: [
          {
            label: 'Change by percentage',
            value: 'percentage'
          },
          {
            label: 'Change absolute value',
            value: 'absolute_value'
          }
        ]
      },
      {
        title: 'Remove lifetime spending limit',
        value: 'remove_lsl'
      }
    ],
    overrideBudgetTypeConfig: [
      {
        title: 'Daily budget',
        description:
          'Set a target daily budget. Actual daily spend will generally fluctuate based on many factors but will average out as your campaign runs',
        type: 'input',
        value: 'override_daily_budget'
      }
    ],
    budgetTypeSelections: [
      {
        title: 'retain budget type',
        value: 'retain'
      },
      {
        title: 'override budget type',
        value: 'override'
      }
    ],
    reqBody: {
      cubeName: 'instacart_adgroup_campaign_latest',
      measuresList: [
        'ad_group_name',
        'ad_group_status',
        'default_bid',
        'suggested_bid',
        'min_bid'
      ],
      groupByDimensionsList: ['campaign_id', 'ad_group_id'],
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'campaign_id',
            operator: 'LIKE',
            dimensionValue: null
          }
        ]
      }
    },
    adGroupColumnMetadata: function (vueRef) {
      return dictionaryRightCreateAdGroup(vueRef);
    }
  }
};

const dictionaryRightCreateAdGroup = (vueRef) => [
  {
    name: 'ad_group_name',
    type: 'STRING',
    uiField: {
      uiLabel: 'Ad group name',
      customStyles: 'font-size:14px !important',
      uiType: 'string',
      uiOrder: '1',
      metadata: {
        toolTipText: '',
        tableColumnName: 'ad_group_name',
        showOnUi: true,
        isFixed: false,
        width: 104
      }
    }
  },
  {
    name: 'suggeted bid',
    type: 'custom',
    uiField: {
      widget: 'icon',
      uiLabel: 'Suggested Bid',
      clickHeader: vueRef.addAllSuggestedBids,
      customStyles: 'cursor:pointer; font-size:14px !important',
      headerIcon: {
        icon: 'trending-neutral',
        toolTip: 'Set Suggested Bid',
        class: 'rb-icon--medium u-spacing-pl-m'
      },
      uiType: 'custom',
      uiOrder: '2',
      metadata: {
        toolTipText: 'Set Suggested Bid',
        widget: 'icon',
        type: 'iconText',
        tableColumnName: 'suggested_bid',
        defaultValueColumnName: 'suggestedBid',
        formatType: 'currency',
        displayIcon: 'trending-neutral',
        showOnUi: true,
        isFixed: false,
        width: 62,
        iconClickEvent: vueRef.addSuggestedBid
      }
    }
  },
  {
    name: 'Bid',
    type: 'custom',
    uiField: {
      uiLabel: 'Default CPC Bid',
      customStyles: 'font-size:14px !important',
      uiOrder: '3',
      uiType: 'custom',
      metadata: {
        toolTipText: '',
        widget: 'input',
        type: 'number',
        tableColumnName: 'default_bid',
        defaultValueColumnName: 'default_bid',
        showOnUi: true,
        isFixed: false,
        formatType: 'currency',
        width: 62,
        keyupEvent: '',
        onchangeEvent: (context, event) => {
          // Validatino should happen here
          context.params.data.default_bid = context.bidValue;
          let id = context.params.data.ad_group_id;
          vueRef.adGroupsData.forEach((item) => {
            if (item.ad_group_id === id) {
              item.default_bid = context.bidValue;
            }
            return item;
          });
        }
      }
    }
  }
];

const taggedCell = Vue.extend({
  props: {},
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class="u-display-flex u-width-100">
      <div v-if="paramsData.competitor_asin_title || paramsData.competitor_asin" class="u-width-100 u-flex-direction-column">
        <div class="u-text-overflow-ellipsis u-color-grey-base u-flex-1" v-tippy :title="paramsData.competitor_asin_title || paramsData.competitor_asin">
          <span class=" u-font-weight-600">Product:</span> {{paramsData.competitor_asin_title || paramsData.competitor_asin}}</span>
        </div>
        <div>
          <rb-asin
            v-if="paramsData.competitor_asin && paramsData.competitor_asin_title"
            :asin="paramsData.competitor_asin"
            :product-page-url="paramsData.competitor_asin_product_url"
            tippy-text="Amazon Product Page"
          />
        </div>
      </div>
      <div v-if="paramsData.category">
        <div class="u-text-overflow-ellipsis u-color-grey-base u-flex-1" v-tippy :title="paramsData.category">
          <span class="u-font-weight-600">Category:</span> {{paramsData.category}}</span>
        </div>
      </div>
      </div>    
    `
});

const currency = Vue.extend({
  props: {},
  data() {
    return {
      currency: Vue.prototype.$currency
    };
  },
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class=" u-color-black-base">
        {{currency}} {{paramsData.suggested_bid}}
      </div>    
    `
});
const dictionaryRight = {};
export function getColumnDefinition(vueRef, dictionary = dictionaryRight) {
  var _columnDefs = [];
  const selectedDictionary = dictionary;
  for (const i in selectedDictionary) {
    let obj = {};
    if (Array.isArray(selectedDictionary[i])) {
      const tempColDefs = dataTransformer.getColumnDefinition(
        selectedDictionary[i]
      );
      obj = tempColDefs.displayColConfigs[0];
      _columnDefs.push(obj);
      continue;
    }
    obj.headerComponentParams = {};
    obj.field = i;
    obj.title = selectedDictionary[i].title;
    obj.suppressSizeToFit = false;
    obj.resizable = false;
    obj.headerComponentFramework = 'genericTableHeader';
    const keyType = 'string';
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: formatter
    };
    obj.enableSorting = true;
    obj.cellRenderer = cellRenderer;
    obj.headerComponentParams.enableSorting = true;
    obj.width = selectedDictionary[i].width ?? 450;
    if (selectedDictionary[i].type === 'icon') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererFramework = 'iconTableCell';
      obj.cellRendererParams.displayIcon = selectedDictionary[i].icon;
      obj.cellRendererParams.alternateIcon =
        selectedDictionary[i]?.alternateIcon;
      obj.cellRendererParams.alternateIconClass =
        selectedDictionary[i]?.alternateIconClass;
      obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
      obj.cellRendererParams.iconSize = 'medium';
      obj.cellRendererParams.iconClickEvent =
        vueRef[selectedDictionary[i].iconClickEvent];
      obj.headerComponentParams.clickHeader =
        vueRef[selectedDictionary[i].clickHeader];
      obj.headerComponentParams.customStyles = {
        color: '#007cf6',
        display: 'flex',
        width: '100%',
        'justify-content': 'center'
      };
      if (selectedDictionary[i]?.toolTipText) {
        obj.headerComponentParams.headerIcon = {
          icon: 'info-circle-fill',
          toolTip: selectedDictionary[i]?.toolTipText
        };
      }
      obj.width = 120;
    }
    if (selectedDictionary[i].type === 'append') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      obj.cellRendererFramework = taggedCell;
      obj.cellRendererParams.textKey = i;
      obj.cellRendererParams.keys = selectedDictionary[i].keys;
    }
    if (selectedDictionary[i].type === 'currency') {
      delete obj.cellRenderer;
      obj.cellRendererFramework = currency;
    }
    obj.headerName = selectedDictionary[i].title;
    obj.keyOrder = selectedDictionary[i].keyOrder;
    selectedDictionary[i].pinned && (obj.pinned = selectedDictionary[i].pinned);
    // selectedDictionary[i].pinned && (delete obj.cellRendererFramework);
    if (selectedDictionary[i].toolTipText) {
      obj.headerComponentParams.toolTipText = selectedDictionary[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    if (selectedDictionary[i].showOnUi) {
      _columnDefs.push(obj);
    }
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}
