<template>
  <section>
    <span
      ref="trigger"
      v-tippy="tippyOptions"
      role="button"
    >
      <slot name="trigger">
        <div v-if="selectedHierarchy && selectedHierarchy.length === 0">
          <span
            :class="{ 'subcagetory-dropdown': selectedHierarchy.length === 0 }"
            class="u-border-radius-xxxl u-spacing-pv-s u-spacing-ph-s u-cursor-pointer u-font-size-5 u-display-inline-flex u-flex-align-items-center action--text"
          >
            <span
              class="rb-icon rb-icon--small u-spacing-mr-xs icon-add-circle-fill"
            />
            <span>Add a sub category filter</span>
          </span>
        </div>

        <div
          v-else
          v-tippy="{
            placement: 'bottom-start',
            arrow: false,
            interactive: false,
            size: 'regular',
            distance: 2,
            hideOnClick: 'toggle'
          }"
          :title="tippyTitle"
          class="u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
          :class="{
            'filter--token': chipSize === 'm',
            'filter--token-s': chipSize === 's'
          }"
          style="min-height: 20px; max-height: 300px"
        >
          <rb-icon
            :icon="'filter'"
            class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
          />
          <!-- eslint-disable -->
          <span class="u-spacing-pl-xs u-spacing-pr-s u-line-height-1-1">
            <span v-html="showSelectedFilters"></span>
          </span>
          <rb-icon
            :icon="'caret-down'"
            class="u-color-grey-lighter u-cursor-pointer u-spacing-pr-m"
            :class="{
              'rb-icon--small': chipSize === 'm',
              'rb-icon--x-small': chipSize === 's'
            }"
          />
          <!-- eslint-enable -->
          <span @click.stop="removeSelections">
            <rb-icon
              :icon="'cross'"
              class="u-cursor-pointer u-color-grey-lighter"
              :class="{
                'rb-icon--x-small': chipSize === 'm',
                'rb-icon--xx-small': chipSize === 's'
              }"
            />
          </span>
        </div>
      </slot>
    </span>
    <div
      id="templateContainer"
      class="filter-dropdown-menu u-overflow-auto u-display-flex u-flex-direction-column"
    >
      <div
        class="filter--header u-width-100 u-bg-color-grey-light u-spacing-p-m u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
      >
        <div class="u-display-flex u-flex-align-items-center">
          <div
            class="u-color-grey-white u-font-size-5 u-display-flex"
            style="max-width: 186px"
          >
            <span class="u-spacing-pv-s u-text-overflow-ellipsis">
              Sub Category
            </span>
          </div>
        </div>
        <span @click="toggle">
          <rb-icon
            :icon="'cross'"
            class="rb-icon--small u-color-grey-white u-cursor-pointer"
          />
        </span>
      </div>
      <div
        v-if="isActive"
        class="filter--body u-display-flex u-flex-direction-column u-width-100 u-position-relative filter-dropdown-content-custom u-overflow-auto u-bg-color-grey-white"
      >
        <multi-level-selector-dropdown
          :parent-tippy-id="parentTippyId"
          :selector-disabled-hook="selectorDisabledHook"
          :init-selections="selections"
          :filter-data="filterData"
          @selectionsChanged="selectionsChanged"
        />
        <div
          v-if="filterData && filterDataCount === 0"
          class="u-spacing-m-m u-font-size-7 u-text-align-center u-color-grey-light"
        >
          No Results
        </div>
        <loader
          v-if="!filterData"
          class="fill--parent"
          :loading="!filterData"
          :color="'#007cf6'"
        />
      </div>
      <div
        class="u-display-flex u-flex-justify-content-space-between u-bg-color-grey-white u-spacing-p-m u-flex-align-items-center u-position-relative"
        style="box-shadow: 0px -1px 2px 0px #e9eaeb"
      >
        <span class="u-display-flex u-flex-align-items-center">
          <rb-button
            :text="'Apply'"
            :type="'filled'"
            :size="'s'"
            :disabled="disableApplyButton"
            :click-fn="applyFilters"
          />
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import multiLevelSelectorDropdown from '@/components/widgets/filter/multi-level-selector-dropdown';
import {
  getTransformedSubcategory,
  getGlobalSettingsForMS
} from '@/components/pages/insights/amazon/market-share/utils';

export default {
  name: 'StandardShelf',
  components: {
    loader,
    multiLevelSelectorDropdown
  },
  props: {
    categoryStatusList: {
      type: Object,
      default: () => {}
    },
    selections: {
      type: Array,
      default: () => []
    },
    selectedCategory: {
      type: Array,
      default: () => []
    },
    selectedHierarchy: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      parentTippyId: '',
      isActive: false,
      name: '',
      tippyOptions: {
        html: '#templateContainer',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        placement: 'top-start',
        offset: '0, -40',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      },
      config: {
        title: 'Sub Category'
      },
      chipSize: 'm',
      selectionTracker: {}
    };
  },
  computed: {
    filterData() {
      let selectedCategory = this.selectedCategory[0]?.dimensionValue || '';
      if (selectedCategory) {
        selectedCategory = selectedCategory
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
      let obj = {};
      obj = this.$store.getters.getHeirarchyFilterData || {};
      return { ...obj };
      // return this.$store.getters.getHeirarchyFilterData;
    },
    filterDataCount() {
      return Object.keys(this.filterData || {}).length;
    },
    localFilterData() {
      console.log(this.selectedCategory, this.filterData);
      const selectedCategory = this.selectedCategory[0]?.dimensionValue;
      const obj = {};
      obj[selectedCategory] = this.filterData[selectedCategory];
      return { ...obj };
    },
    disableApplyButton() {
      return (
        this.selectionTracker && Object.keys(this.selectionTracker).length === 0
      );
    },
    tippyTitle() {
      const LIMIT = 20;
      const chipTitleSplit = (this.chipTitle || '').split(',');
      if (chipTitleSplit.length <= 20) {
        return this.trimText(chipTitleSplit).join(', ');
      } else {
        const shownTextArray = chipTitleSplit.slice(0, LIMIT);
        return (
          this.trimText(shownTextArray).join(', ') +
          `... & ${chipTitleSplit.length - LIMIT} more`
        );
      }
    },
    chipTitle() {
      return this.selectedHierarchy
        .map((item) => item?.replaceAll('----', ' > '))
        .join(' , ');
    },
    showSelectedFilters() {
      if (this.selectedHierarchy.length > 0) {
        const maxStrLen = 24;
        let concatStr = '';
        for (let idx = 0; idx <= this.selectedHierarchy.length; idx++) {
          const curSelected = this.selectedHierarchy[idx]?.replaceAll(
            '----',
            ' > '
          );
          if (concatStr.length >= maxStrLen - 3) {
            concatStr = concatStr.slice(0, maxStrLen - 3);
            // add '...' if all the selections is not fit into concatStr
            for (let k = 0; k < idx; k++) {
              if (!concatStr.includes(this.selectedHierarchy[k])) {
                concatStr = concatStr.concat('...');
              }
            }
            // Handling edge case ',' in the end for unclipped selections.
            if (concatStr.charAt(concatStr.length - 1).includes(',')) {
              concatStr = concatStr.slice(0, concatStr.length - 1);
            }
            const moreCount = this.selectedHierarchy.length - idx;
            concatStr = concatStr.concat(
              idx < this.selectedHierarchy.length
                ? ' & ' + moreCount + ' more'
                : ''
            );
            break;
          } else if (curSelected && curSelected.length > 0) {
            concatStr = concatStr.concat(
              curSelected,
              idx < this.selectedHierarchy.length - 1 ? ', ' : ''
            );
          }
        }
        return (
          '<span>' +
          this.config.title +
          ' : ' +
          '</span> <span class="u-color-grey-base">' +
          concatStr +
          '</span>'
        );
      }
    }
  },
  watch: {
    // selectedHierarchy (newVal) {
    //   this.selectedHierarchy = newVal;
    // },
  },
  created() {
    if (!this.filterData) {
      this.$store.dispatch('getFilterDataV2');
    }
    this.$store.dispatch(
      'getFilterDataV2',
      this.selectedCategory[0]?.dimensionValue
    );
  },
  methods: {
    selectorDisabledHook(data) {
      const globalFilter = getGlobalSettingsForMS();
      const category =
        globalFilter?.category || globalFilter?.category_hierarchy?.[0];
      const statusContainsCategory =
        this.categoryStatusList?.[(category + '----' + data).toLowerCase()];
      if (!Object.keys(this.categoryStatusList)?.length) {
        return undefined;
      } else if (!statusContainsCategory) {
        return {
          tooltip: !statusContainsCategory
            ? 'There is no data available for the combination of this filter and the other selected filters.'
            : ''
        };
      }
    },
    selectionsChanged(selections) {
      this.selectionTracker = {};
      for (let selection in selections) {
        if (selections[selection] === true) {
          this.selectionTracker[selection] = true;
        }
      }
      this.$emit('selection', Object.keys(this.selectionTracker));
    },
    trimText(arrayOfStrings = []) {
      return arrayOfStrings.map((item) => (item || '').trim());
    },
    removeSelections() {
      this.$store.dispatch('setInitialSelections', {});
      this.$emit('applySubCategory', {});
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.parentTippyId = instance.popper.id;
      this.$emit('onShow');
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
      const selections = {};
      getTransformedSubcategory()?.map((item) => {
        selections[item] = true;
      });
      this.$store.dispatch('setInitialSelections', selections || {});
    },
    onTippyCreate(instance) {
      this.tippyInstance = instance;
    },
    toggle($event) {
      if (this.tippyInstance) {
        if (this.tippyInstance.state.visible) {
          this.tippyInstance.hide();
        } else {
          this.tippyInstance.show();
        }
      }
    },
    applyFilters() {
      const requestData = {};
      for (var key in this.selectionTracker) {
        const parentCategory = `${this.selectedCategory[0].dimensionValue}----${key}`;
        requestData[parentCategory] = this.selectionTracker[key];
      }
      this.selectionTracker = requestData;
      this.$emit('applySubCategory', this.selectionTracker);
      this.toggle();
    }
  }
};
</script>
<style lang="css" scoped>
.filter-dropdown-menu {
  width: 256px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  overflow: auto;
}

.filter--header {
  line-height: 0;
}

.filter-dropdown-content-custom {
  max-height: 304px;
  min-height: 136px;
}
.subcagetory-dropdown {
  border: 1px dashed #ebeef6;
}
</style>
