<template>
  <div
    class="u-display-flex u-flex-justify-content-space-around ingestion_bulk_edit_section"
  >
    <section class="ingestion_work_panel">
      <upload-header
        :title="textConfigs.sectionTitle"
        :description="textConfigs.sectionDesc"
        class="header_container"
      />
      <transition name="slide-fade">
        <slot
          v-if="currentStage === 'upload'"
          name="upload"
        >
          <div>
            <icon-text
              class="u-spacing-mb-xs u-font-weight-600 u-cursor-pointer bulk-change-download"
              icon="download"
              icon-classes="u-color-blue-base rb-icon--medium"
              :text="textConfigs.downloadTemplateText"
              @onClick="downloadTemplate"
            />
            <upload-file
              class="upload_file_drop_area"
              :accept-format="acceptedFileFormat"
              @onFileUpload="handleFileDrop"
            />
          </div>
        </slot>
        <slot
          v-if="currentStage === 'loading'"
          name="loading"
        >
          <upload-box
            :loading="true"
            :loading-title="`Uploading file <b class='u-spacing-ml-xs u-font-weight-600'> ${
              ' ' + fileDetails.name
            } </b> `"
            loading-text="Trying to pick the speed up..."
            container-class="u-bg-grey-white u-display-flex u-flex-align-items-center u-flex-justify-content-space-around u-border-radius-s"
            @onCancel="removeHandler"
          />
        </slot>
        <slot
          v-if="currentStage === 'success'"
          name="success"
        >
          <loader
            v-if="loading"
            class="u-color-blue-base fill--screen"
            :color="'#3684bb'"
            :loading="loading"
          />
          <upload-box
            status-icon="check-fill-circle"
            :right-icon="rightIcon"
            :right-icon-style-class="'u-color-grey-white'"
            :show-status="showStatus"
            status-icon-classes="rb-icon--small u-spacing-mr-xs u-color-green-base"
            :status-text="successStatusText"
            :title="fileDetails.name"
            :status-text-classes="'u-spacing-mt-s u-font-size-6'"
            title-style-classes="u-color-grey-white"
            :inner-components="innerComponents"
            :sub-title="fileDetails.lastModifiedDate"
          />
        </slot>
        <slot
          v-if="currentStage === 'error'"
          name="error"
        >
          <div>
            <upload-box
              right-icon-style-class="u-color-red-base"
              right-icon="info-circle-fill"
              status-icon="info-circle-fill"
              :show-status="true"
              status-icon-classes="rb-icon--small u-spacing-mr-xs u-color-red-base"
              :container-class="errorContainerClass"
              :status-text-classes="'u-spacing-mt-s u-font-size-6'"
              :title="fileDetails.name"
              :inner-components="errorInnerComponents"
              :sub-title="fileDetails.lastModifiedDate"
              :status-text="textConfigs.statusText"
            />
            <div class="bulk_error_section">
              <error-list :error-list="errorList" />
            </div>
          </div>
        </slot>
      </transition>
      <div
        v-if="!hideFooter"
        class="footer_container"
      >
        <div
          v-if="warningText && warningText.trim().length"
          class="u-spacing-pb-s"
        >
          <iconText
            :icon="'warning-triangle'"
            :icon-classes="'u-flex-0 rb-icon--small u-color-orange-base u-spacing-mr-xs u-flex-align-self-flex-start'"
            :text="warningText"
            :text-classes="'u-spacing-ph-xxs u-font-size-6'"
          />
        </div>
        <upload-footer
          :btn-right-text="textConfigs.nextButtonText"
          :btn-left-text="textConfigs.backButtonText"
          :disable-right-btn="!(currentStage === 'success')"
          class="u-spacing-pb-xl"
          @onClickBtnRight="goToNextStep"
          @onClickBtnLeft="goBack"
        />
      </div>
    </section>
    <slot name="info_section">
      <div
        class="u-font-size-5 iq_tip_container"
        :class="infoSection.containerClass"
      >
        <div
          style="font-size: 24px"
          class="u-font-weight-600"
        >
          <rb-icon
            class="iqIconSize u-color-blue-base"
            icon="light-bulb"
          /><span class="u-color-blue-base">IQ </span>Tip
        </div>
        <span
          class="u-font-size-7 u-spacing-mb-xxs u-spacing-mt-xs"
          v-html="infoSection.header"
        />
        <ul
          style="list-style-type: circle"
          class="u-color-grey-mid-light u-font-size-7 u-spacing-mb-xs"
        >
          <li
            v-for="(tip, index) in infoSection.tips"
            :key="index"
            class="u-spacing-mt-s"
            @click="$emit('tooltipClicked', $event)"
            v-html="tip"
          />
        </ul>
        <span
          class="u-font-size-7 u-spacing-mt-s"
          @click="$emit('footerClicked', $event)"
          v-html="infoSection.footer"
        />
      </div>
    </slot>
  </div>
</template>

<script>
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import uploadBox from '@/components/widgets/upload/uploadBox.vue';
import uploadFooter from '@/components/widgets/upload/footer.vue';
import uploadHeader from '@/components/widgets/upload/header.vue';
import errorList from '@/components/widgets/upload/errorList.vue';

import uploadFile from '@/components/basic/uploadFile.vue';
import uploadLoading from '@/components/widgets/internalCatalogue/progressBar.vue';
import iconText from '@/components/basic/iconText.vue';

export default {
  components: {
    StepComponent,
    uploadBox,
    uploadFooter,
    uploadHeader,
    uploadFile,
    uploadLoading,
    errorList,
    iconText
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    journeyStage: {
      type: String,
      default: 'select-upload-type'
    },
    innerComponents: {
      type: Array,
      default() {
        return [
          {
            name: 'rb-button',
            props: {
              text: 'Remove',
              clickFn: this.removeHandler,
              class: 'u-bg-color-blue-base u-spacing-ph-l u-color-grey-white'
            },
            events: {
              click: this.removeHandler
            }
          }
        ];
      }
    },
    acceptedFileFormat: {
      type: Array,
      default: () => {
        return ['.xlsx'];
      }
    },
    textConfigs: {
      type: Object,
      default() {
        return {
          sectionTitle: 'Upload your Standard Internal Catalog',
          sectionDesc:
            'Use an existing template or update the available template to populate your Standard Internal Catalog.',
          nextButtonText: 'Review and Create',
          backButtonText: 'Back',
          entityName: 'Standard Internal Catalog'
        };
      }
    },
    currentStage: {
      // enum - accept_file, uploading, success,error,
      type: String,
      default: 'accept_file'
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: String,
      default: 'check-fill-circle'
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    downloadTemplateText: {
      type: String,
      default: 'Download Standard Internal Catalog Template'
    },
    fileDetails: {
      type: Object,
      default: () => {
        return {
          fileBuffer: null,
          name: '',
          bValidationSuccess: false,
          lastModified: ''
        };
      }
    },
    errorList: {
      type: Array,
      default: []
    },
    infoSection: {
      type: Object,
      default() {
        return {
          containerClass:
            'u-width-30 u-border-radius-s u-border-color-grey-xxx-light',
          header:
            '<span class="u-font-weight-600">Are you using your own template to upload SKU’s Internal Categorization? Do ensure the below conditions are met:</span>',
          tips: [
            'There should be a column named ASINs containing the list of ASINs.',
            'All columns should have header names. The header names except ASIN will be considered as the Catalog names.',
            'To update catalog values of few ASINs you can either download the existing standard catalog excel sheet and edit the catalog values and re-upload to overwrite or use <span id="open-catalog-page" class="u-color-blue-base u-cursor-pointer" style="text-decoration: underline"> in-product edit </span> to modifiy the values of the impacted ASINs.',
            'To add a few new ASINs to the existing standard catalog, you can download the existing standard catalog excel sheet and add the new ASINs to the excel sheet and re-upload to overwrite.'
          ],
          footer:
            'You may download a new <span id="catalog-info-download-trigger" class="u-color-blue-base u-cursor-pointer" style="text-decoration: underline"> SKUs Internal Categorization Template </span> if you do not have an existing template.'
        };
      }
    },
    warningText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      successStatusText: `All set to <span  class="  u-font-weight-600">Create ${this.textConfigs.entityName} </span> with the above file!`,
      errorContainerClass:
        'u-width-100 u-display-flex u-flex-align-items-center u-spacing-p-xl u-flex-justify-content-space-between u-border-radius-s errorBorder',
      openActivity: false,
      errorInnerComponents: [
        {
          name: 'rb-button',
          props: {
            text: 'Remove',
            clickFn: this.removeHandler,
            class:
              'u-color-blue-base u-spacing-ph-l u-border-none remove-hollow-button'
          },
          events: {
            click: this.removeHandler
          }
        },
        {
          name: 'rb-button',
          props: {
            text: 'Fix the errors',
            clickFn: this.removeHandler,
            class:
              'u-spacing-ml-s u-color-blue-base u-spacing-ph-l u-border-color-blue-base u-display-inline-flex fix-errors-hollow-button'
          },
          events: {
            click: this.removeHandler
          }
        }
      ]
    };
  },
  methods: {
    handleFileDrop(file) {
      this.$emit('onUpload', file);
    },
    goToNextStep(event) {
      this.$emit('onNext', event);
    },
    goBack() {
      this.$emit('onBack', event);
    },
    navigateBack() {
      this.goBack();
    },
    removeHandler(event) {
      this.$emit('removeFile', event);
    },
    downloadTemplate(event) {
      this.$emit('downloadTemplate', event);
    }
  }
};
</script>

<style lang="css" scoped>
.iq_tip_container {
  flex-grow: 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 24px;
  border-radius: 4px;
  border: solid 1px #e9eaeb;
  background-color: #ffffff;
}
.iq_tip_container li {
  list-style: none;
  list-style-position: inside;
}
.iq_tip_container li:before {
  content: '\2022';
  font-size: 24px;
  margin: 2px 8px 18px 0;
  color: #007cf6;
}
.iqIconSize {
  flex-grow: 0;
}
.iqIconSize:before {
  font-size: 22px;
  margin-right: 8px;
}
.pagination-overlay {
  width: 100%;
  height: calc(100vh - 182px);
}
.text-italics {
  font-style: italic;
}
.page--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  width: 100%;
}
.container {
  min-height: 500px;
}
.footer_container {
  margin-top: 40px;
}
.warning_container {
  margin-top: 60px;
}
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.1s ease-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  /* .slide-fade-leave-active below version 2.1.8 */
  transform: translateY(180px);
  opacity: 0;
}
.bulk_error_section {
  max-height: 200px;
  width: 100%;
  overflow: scroll;
  margin-top: 1.6rem;
}
</style>
<style lang="css">
.progress-bar {
  box-shadow: 0 0 6px 0 rgba(43, 51, 59, 0.15);
}
.ingestion_work_panel {
  width: 50%;
}
.ingestion_work_panel h3 {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
