import Vue from 'vue';
import HttpService from '@/utils/services/http-service';

const initialState = () => {
  return {
    selectedRem1Retailer: null,
    doubleClickDynamicColumnMetadata: {},
    rem1EnabledRetailers: null
  };
};

const retailerListApiPayload = {
  entityType: 'retailer',
  operations: {
    system: 'cva'
  }
};

const strategyAndPlanningMenuTitle = 'Strategy & Planning';

function findCLAddIndex(menu) {
  let posIndex = 0;
  const fixedMenuItem = [
    'Search for SKUs'.toLowerCase(),
    'Business Overview'.toLowerCase(),
    'Recommendations'.toLowerCase(),
    'Reports'.toLowerCase()
  ];
  for (const item of menu) {
    if (
      fixedMenuItem?.includes(item?.title?.toLowerCase()) ||
      (item?.children &&
        item?.children[0]?.title?.toLowerCase() === 'Reports'.toLowerCase())
    ) {
      posIndex++;
    }
  }
  return posIndex;
}

function appendCLDashboard(menu) {
  const CLDashboard = {
    title: 'Category Leaderboard',
    icon: 'category_landscape',
    path: 'strategy_and_planning/category_leaderboard',
    redirect: '',
    config: 'cva',
    productRoles: ['SalesIQ', 'MarketingIQ'],
    configCheckRequired: true,
    page: 'insights'
  };
  const index = menu.findIndex(
    (item) => item.title === strategyAndPlanningMenuTitle
  );
  if (index !== -1) {
    const findCLDashboard = menu[index].children.find(
      (snpChild) => snpChild.title?.toLowerCase() === 'category leaderboard'
    );
    // below is check to avoid duplicate Category Leaderboard
    if (!findCLDashboard) {
      menu[index].children.push(CLDashboard);
    }
  } else {
    const strategyAndPlanning = {
      title: strategyAndPlanningMenuTitle,
      icon: '',
      redirect: '',
      defaultRoute: 'strategy_and_planning/category_leaderboard',
      isCollapsed: false,
      configCheckRequired: true,
      children: [CLDashboard]
    };
    menu.splice(findCLAddIndex(menu), 0, strategyAndPlanning);
  }
}

const state = initialState();

const getters = {
  getDoubleClickDynamicColumnMetadata: (state) =>
    state.doubleClickDynamicColumnMetadata,
  getSelectedRem1Retailer: (state) => state.selectedRem1Retailer,
  getRem1EnabledRetailers: (state) => state.rem1EnabledRetailers
};

const mutations = {
  RESET_STORE: (state, data) => {
    Object.assign(state, data);
  },
  SET_SELECTED_REM1_RETAILER: (state, data) => {
    Vue.set(state, 'selectedRem1Retailer', data);
  },
  SET_DOUBLE_CLICK_DYNAMIC_COLUMN_METADATA: (state, data) => {
    Vue.set(state, 'doubleClickDynamicColumnMetadata', data);
  },
  SET_REM1_ENABLED_RETAILERS: (state, data) => {
    Vue.set(state, 'rem1EnabledRetailers', data);
  }
};

const actions = {
  resetStore: (context, data) => {
    const initialStateCopy = initialState();
    context.commit('RESET_STORE', initialStateCopy);
  },
  async fetchDoubleClickDynamicColumnsMetadata(context, payload) {
    const { data } = await HttpService.get('DASHBOARD_SERVICE', {
      append: `/cat_analytics/table/metadata?retailerId=${payload.retailerId}`,
      headers: { products: 'cva' }
    });
    const doubleClickMapping = (data.response || []).reduce(
      (previousVal, currentVal) => {
        previousVal[currentVal.table] = currentVal.activeColumns;
        return previousVal;
      },
      {}
    );
    context.commit(
      'SET_DOUBLE_CLICK_DYNAMIC_COLUMN_METADATA',
      doubleClickMapping
    );
  },
  setSelectedRem1Retailer(context, retailer) {
    context.commit('SET_SELECTED_REM1_RETAILER', retailer);
  },
  async fetchRetailerList(context) {
    try {
      const { data } = await HttpService.post(
        'DASHBOARD_SERVICE',
        retailerListApiPayload,
        {
          append: '/cat_analytics/dms/data'
        }
      );
      context.commit('SET_REM1_ENABLED_RETAILERS', data.response);
      return data.response;
    } catch (error) {
      return [];
    }
  },
  fetchRem1EnabledRetailerList(context, retailerParams) {
    return new Promise((resolve, reject) => {
      HttpService.post('DASHBOARD_SERVICE', retailerListApiPayload, {
        append: '/cat_analytics/dms/data'
      })
        .then((response) => {
          const menu = retailerParams?.menu || {};
          const retailerKey = retailerParams?.selectedRetailer?.key;
          if (response?.data?.response) {
            const CLEnabledRetailers = response?.data?.response.map(
              (retailer) => retailer.ALIAS
            );
            if (CLEnabledRetailers.includes(retailerKey)) {
              appendCLDashboard(menu);
            }
          }
          resolve(menu);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
