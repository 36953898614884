<template>
  <div class="sot-options-wrapper">
    <loader
      v-if="isSotLoading"
      class="entity_loader u-color-blue-base fill--parent"
      :color="'#3684bb'"
      :loading="isSotLoading"
    />
    <div>
      <div
        class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-mb-l"
      >
        <div class="u-font-size-2 u-font-weight-600 u-spacing-mb-s">
          {{ sotOptionTextConfigs.title }}
        </div>
        <div class="u-font-size-5 u-color-grey-mid-light">
          {{ sotOptionTextConfigs.subTitle }}
        </div>
      </div>
      <div
        v-for="(option, index) in sotOptionTextConfigs.availableOptions"
        :key="index"
      >
        <div class="sot-options-card">
          <rb-radio
            v-model="selectedRadio"
            :native-value="option.key"
            :disabled="option.disabled"
          >
            <div class="card-wrapper u-spacing-pl-s">
              <div>
                <div
                  v-if="option.isRecommended"
                  class="recommended-badge u-font-size-6"
                >
                  RECOMMENDED
                </div>
                <div
                  class="u-display-flex u-flex-align-items-center u-spacing-pb-xs"
                >
                  <rb-icon
                    :icon="option.icon"
                    class="rb-icon--medium"
                  />
                  <div class="u-font-size-3 u-font-weight-600 u-spacing-pl-s">
                    {{ option.name }}
                  </div>
                </div>
                <div class="u-font-size-6">
                  <div class="option-about">
                    {{ option.about }}
                    <span
                      v-if="option.showLearnMore"
                      v-tippy
                      :title="option.learnMoreTooltipText || null"
                      class="u-color-blue-base u-text-decoration-underline"
                    >
                      Learn more
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="option.showUpcomingBadge"
                class="u-color-green-base u-font-size-5 u-font-weight-600 u-display-flex u-flex-justify-content-flex-end"
              >
                Upcoming
              </div>
            </div>
          </rb-radio>
        </div>
      </div>
      <div
        class="u-display-inline-flex u-flex-align-items-center u-bg-color-blue-base u-spacing-ph-l u-spacing-pv-s u-color-white-base u-cursor-pointer u-border-radius-s u-font-weight-600 u-spacing-mt-xl proceed-btn"
        @click="handleSotOptionSelection"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-width-100"
        >
          <span class="button_size u-spacing-mr-s u-font-size-6">
            {{ proceedBtnLabel }}
          </span>
          <rb-icon
            icon="arrow-right"
            class="rb-icon--medium"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSotOptionsConfigs } from '@/pages/toolsAndSettings/entityTagging/common/sourceOfTruth/sourceOfTruthConfig';
export default {
  props: {
    sotType: {
      type: String,
      default: ''
    },
    isSotLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sotOptionTextConfigs: {},
      selectedRadio: 'autogenerate'
    };
  },
  computed: {
    proceedBtnLabel() {
      return this.selectedRadio === 'upload'
        ? 'Upload content data'
        : 'Generate Content data';
    }
  },
  created() {
    this.sotOptionTextConfigs = getSotOptionsConfigs(this.sotType);
  },
  methods: {
    handleSotOptionSelection() {
      this.$emit('handleSotOptionSelection', this.selectedRadio);
    }
  }
};
</script>

<style lang="css">
.sot-options-wrapper {
  margin: 60px 0px auto 100px;
}
.sot-options-wrapper .sot-options-card {
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 20px;
  width: 750px;
  min-height: 100px;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(43, 51, 59, 0.3);
  border-radius: 4px;
}
.sot-options-wrapper .sot-options-card.disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}
.sot-options-wrapper .sot-options-card .card-wrapper {
  display: grid;
  grid-template-columns: 80% 20%;
}
.sot-options-wrapper .sot-options-card .card-wrapper .option-about {
  white-space: normal;
}
.sot-options-wrapper .sot-options-card .recommended-badge {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(0, 124, 246, 0.1);
  border-radius: 100px;
  margin-bottom: 5px;
  max-width: 115px;
}
.sot-options-wrapper .sot-options-card .rb-radio {
  width: 100%;
}
.sot-options-wrapper .sot-options-card .rb-radio .rb-control-label {
  width: 100%;
}
</style>

<style lang="css" scoped>
.sot-options-wrapper .proceed-btn {
  min-width: 210px;
}
</style>
