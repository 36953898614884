import moment from 'moment-timezone';

export default {
  data() {
    return {
      currency: this.$currency
    };
  },
  watch: {
    budgetPlanTitle(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.commit('SET_MODIFIED_BUDGET_PLAN_FLAG', true);
      }
    },
    budgetPlanDesc(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.commit('SET_MODIFIED_BUDGET_PLAN_FLAG', true);
      }
    }
  },
  computed: {
    budgetPlanTitle: {
      get() {
        return this.$store.getters.getBudgetPlanTitle;
      },
      set(newValue) {
        this.$store.commit('SET_BUDGET_PLAN_TITLE', newValue);
      }
    },
    budgetPlanDesc: {
      get() {
        return this.$store.getters.getBudgetPlanDesc;
      },
      set(newValue) {
        this.$store.commit('SET_BUDGET_PLAN_DESCRIPTION', newValue);
      }
    },
    budgetPlannerEntities() {
      return this.$store.getters.getBudgetPlannerStrategyEntities;
    },
    fileDetails() {
      return this.$store.getters.getBudgetPlanFileDetails;
    },
    budgetPlanProfileEntities() {
      return this.$store.getters.getBudgetPlanProfiles;
    },
    budgetPlanPortfolioEntities() {
      return this.$store.getters.getBudgetPlanPortfolios;
    },
    budgetPlanEntireBusiness() {
      return this.$store.getters.getPreviewTableEntireBusiness;
    },
    metaDataConfig() {
      return this.$store.getters.getStrategyStaticMetaData;
    },
    getUploadStepErrors() {
      return this.$store.getters.getUploadStepErrors;
    },
    budgetPlannerSavePayload() {
      return this.$store.getters.getBudgetPlannerSavePayload;
    },
    profileDropdownOptions() {
      const profileEntityArr = this.budgetPlanProfileEntities;
      return this.transformEntities(profileEntityArr).filter(
        (entity) => entity.finalBudgetPlan > 0
      );
    },
    portfolioDropdownOptions() {
      const portfolioEntityArr = this.budgetPlanPortfolioEntities;
      return this.transformEntities(portfolioEntityArr).filter(
        (entity) =>
          entity.finalBudgetPlan > 0 &&
          entity.dimensionValue.toLowerCase() !== 'rest'
      );
    },
    entireBusinessObj() {
      const entireBusinessObj = this.budgetPlanEntireBusiness;
      return this.transformEntities(entireBusinessObj);
    },
    isBudgetPlanModified() {
      return this.$store.getters.getIsBudgetPlanModifiedFlag;
    },
    validateBudgetPlan() {
      if (
        this.budgetPlanTitle.trim() === '' ||
        this.budgetPlanTitle === 'Untitled Budget Plan'
      ) {
        this.snackbar('#2b333b', 'Please enter a valid Budget Plan title');
        return false;
      }
      return true;
    },
    visitedAllSteps() {
      return this.$store.getters.getVisitedBudgetPlanSteps.length === 4;
    }
  },
  methods: {
    snackbar(color, message) {
      this.$snackbar.open({
        message,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: '',
        customStyles: 'background:' + color
      });
    },
    transformEntities(entityArr) {
      return entityArr.map((entity) => {
        const entityExecScope = entity.executionScope;
        return {
          dimensionName: entityExecScope.businessScope,
          dimensionValue:
            entityExecScope.businessScope === 'ENTIRE_BUSINESS'
              ? 'ENTIRE_BUSINESS'
              : entityExecScope.businessScopeId,
          title: entityExecScope.businessScopeName,
          relatedEntityId:
            entityExecScope.businessScope === 'PROFILE'
              ? 'ENTIRE_BUSINESS'
              : entityExecScope.relatedBusinessScopeId,
          relatedEntityScope: entityExecScope.relatedBusinessScope,
          finalBudgetPlan: entityExecScope.finalBudgetPlan
        };
      });
    },
    saveBudgetPlannerStrategy() {
      const today = moment();
      const nextYearPlan = this.$route?.query?.nextYearPlan;
      const from = nextYearPlan
        ? moment().add(1, 'years').startOf('year')
        : moment().startOf('year');
      const to = nextYearPlan
        ? moment().add(1, 'years').endOf('year')
        : moment().endOf('year');
      if (!this.validateBudgetPlan) {
        return;
      }
      const params = {
        whereClause: {
          where: {
            date: {
              from: from.format('YYYY-MM-DD'),
              to: to.format('YYYY-MM-DD')
            }
          }
        },
        budgetPlan: {
          strategyName: this.budgetPlanTitle,
          description: this.budgetPlanDesc,
          strategyActive: true,
          strategyStartDate: from.toISOString(),
          strategyEndDate: to.toISOString(),
          timezone: 'GMT'
        }
      };

      this.$store.dispatch('setBudgetPlanSavePayload', params);
      this.budgetPlannerSavePayload.budgetPlan.actions[0].executionTemplate.userFilename =
        this.fileDetails.name || 'BudgetPlan';
      this.budgetPlannerSavePayload.budgetPlan.actions[0].executionTemplate.year =
        nextYearPlan
          ? today.add(1, 'years').format('YYYY')
          : today.format('YYYY');
      if (nextYearPlan) {
        this.budgetPlannerSavePayload.nextYearPlan = true;
      }
      this.$store.dispatch(
        'saveBudgetPlanStrategy',
        this.budgetPlannerSavePayload
      );
      this.$router.push({ name: 'budget-planner-dashboard' });
    },
    formatAllDisplayText() {
      if (this.stepsConfig) {
        this.stepsConfig.forEach((config) => {
          if (config.textMapping) {
            this.formatDisplayText(config.textMapping);
          }
        });
      }
    },
    dimensionMetaDataMapping(params) {
      const temp = this.$store.getters.getPrimaryFilterData.filter((item) => {
        if (item.dimensionName === params) {
          return item;
        }
      })[0];
      return temp || {};
    }
  },
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  }
};
