<!--
Table Switch component wrapper just for dsp
-->
<template>
  <div class="u-spacing-ml-s">
    <div
      v-if="showToolTip"
      v-tippy="tippy"
      :title="tooltipText"
    >
      <rb-icon
        class="rb-icon--medium"
        :class="iconClass"
        icon="task-status"
      />
    </div>
    <div v-else>
      <rb-icon
        class="rb-icon--medium"
        :class="iconClass"
        icon="task-status"
      />
    </div>
  </div>
</template>

<script>
import tableSwitch from '@/components/globals/dataTable/tableComponentsWrapper/tableSwitch';
import { capitalize } from '@/utils/helpers/formatter';

export default {
  components: {
    tableSwitch
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    metric: {
      type: String,
      default: 'ACTIVE'
    },
    showToolTip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enableValues: ['active', 'enabled'],
      pauseValues: ['paused'],
      inReviewValues: ['draft'],
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    iconClass() {
      if (this.metric) {
        const metricValue = this.metric.toString().toLowerCase();
        if (this.enableValues.includes(metricValue)) {
          return 'u-color-green-base';
        } else if (this.pauseValues.includes(metricValue)) {
          return 'u-color-orange-base';
        } else if (this.inReviewValues.includes(metricValue)) {
          return 'u-color-yellow-base';
        }
      }
      return 'u-color-grey-lighter';
    },
    tooltipText() {
      if (this.metric) {
        return capitalize(this.metric);
      }
      return null;
    }
  }
};
</script>
