import Vue from 'vue';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
// moment.tz.add('America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0');

let templateId = null;

const tooltipComponent = Vue.extend({
  props: {
    ticketID: {
      type: Number,
      required: true
    }
  },
  computed: {
    ticketMessage() {
      const ticketMessage = this.$store.getters.getTicketMessages;
      return (
        ticketMessage
          ?.find((el) => parseInt(el.caseId) === this.ticketID)
          ?.latestResponseText.split('||') ?? [
          'No entry found for provided case ID'
        ]
      );
    },
    ticketTime() {
      const timezone = moment.tz.guess();
      const ticketTime = this.$store.getters.getTicketMessages?.find(
        (el) => parseInt(el.caseId) === this.ticketID
      )?.latestResponseTime;
      return ticketTime
        ? moment
            .tz(moment.utc(ticketTime).local(), timezone)
            .format('YYYY-MM-DD hh:mm:ss A z')
        : '';
    }
  },
  template: `
  <div>
  <div v-tippy="{
    html: '#ticket-message',
    theme: 'light',
    placement: 'auto',
    reactive: true,
    arrow: false
  }"> 
    {{ticketID}}
  </div>
  <div id="ticket-message" class="internal-tools-ticket-tooltip-message u-display-flex u-flex-direction-column"> 
          <span v-if="ticketTime" class="u-flex-align-self-flex-end u-spacing-mb-s u-font-weight-600">{{ticketTime}}</span>
          <div class="u-display-flex u-flex-direction-column"> 
            <span class="u-spacing-mb-xs" v-for="message in ticketMessage">
              {{message}}
            </span>
          </div>
        </div>
</div>
  `
});

const textEditableComponent = Vue.extend({
  components: { tooltipComponent },
  data() {
    return {
      actived: false,
      oldValue: null,
      displayData: null,
      hasError: false
    };
  },
  computed: {
    canEdit() {
      const row = this.params.data;
      const index = (this.$store.getters.getEditModeIds.ids || []).findIndex(
        (item) => {
          return item === (row.ID || 'new');
        }
      );
      if (index === -1) {
        return false;
      } else {
        return true;
      }
    },
    dataType() {
      const type = this.params.colDef.dataType;
      if (type === 'NUMBER') {
        return 'number';
      } else {
        return 'text';
      }
    },
    clientTimezone() {
      return this.$store.getters.getClientTimezone.shortForm;
    },
    componentForTicketNumber() {
      return !this.canEdit && this.params.colDef.field === 'TICKET_NUMBER'
        ? 'tooltipComponent'
        : 'div';
    }
  },
  created() {
    this.oldValue = cloneDeep(this.params.value);
    if (!this.params.data.edited || this.params.data.clone) {
      this.displayData = this.formatData();
      const row = this.params.data;
      this.params.node.setData(row);
    } else {
      const type = this.params.colDef.dataType;
      if (
        type === 'DATE' ||
        type === 'DATETIME_PST' ||
        (type === 'DATE' && this.params.value)
      ) {
        if (!moment(this.params.value).isValid()) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      }
      this.displayData = this.params.value;
    }
  },
  mounted() {},
  methods: {
    onKeyDown(event) {
      event.stopPropagation();
    },
    formatData() {
      // let type = this.params.colDef.dataType;
      return this.params.value;
    },
    triggerSaveRow(event) {
      this.actived = false;
      const type = this.params.colDef.dataType;
      this.params.data.clone = false;
      if (
        type === 'DATE' ||
        type === 'DATETIME_PST' ||
        (type === 'DATE' && !!event.target.value)
      ) {
        event.target.value = event.target.value.replace(
          this.clientTimezone,
          ''
        );
        if (this.displayData) {
          this.displayData = this.displayData.replace(this.clientTimezone, '');
        }
        if (!moment(event.target.value).isValid()) {
          alert('DATE NOT VALID');
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      }
      if (this.oldValue !== event.target.value) {
        const row = this.params.data;
        row[this.params.colDef.field] = event.target.value;
        if (row[this.params.colDef.field] === '') {
          row[this.params.colDef.field] = null;
        }
        row.edited = true;
        this.actived = false;
        this.params.node.setData(row);
      }
    },
    triggerFocus(event) {
      this.actived = true;
    }
  },
  template: `<component :is="componentForTicketNumber" :ticketID="params.data.TICKET_NUMBER">
              <input :class="{'internal-tool-text-decoration': actived, 'internal-tool-input-cells-error': hasError}"
              class="internal-tool-input-cells" :type="dataType"
              :value="displayData" @blur="triggerSaveRow($event)" @keydown="onKeyDown" :disabled="!canEdit" @focus="triggerFocus($event)"/>
            </component>`
});

const actionComponent = Vue.extend({
  components: {
    loader
  },
  data() {
    return {
      loading: false,
      error: false,
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    editButtonDistable() {
      if ((this.$store.getters.getEditModeIds.ids || []).length > 0) {
        return true;
      }
      return false;
    },
    canEdit() {
      const row = this.params.data;
      const index = (this.$store.getters.getEditModeIds.ids || []).findIndex(
        (item) => {
          return item === (row.ID || 'new');
        }
      );
      if (index === -1) {
        return false;
      } else {
        return true;
      }
    },
    clientTimezone() {
      return this.$store.getters.getClientTimezone.shortForm;
    },
    clientBrowserTimezoneInGmt() {
      return this.$store.getters.getClientBrowserTimezone?.gmtValue;
    }
  },
  created() {
    this.error = this.params.data.error;
  },
  methods: {
    editMode() {
      const row = this.params.data;
      const ids = this.$store.getters.getEditModeIds.ids;
      if (this.editButtonDistable) {
        return;
      }
      this.$store.dispatch('setEditModeIds', []);
      if ((ids || []).length === 0) {
        this.$store.dispatch('setEditModeIds', [row.ID || 'new']);
      } else {
        row.editeMode = true;
        // this.params.api.setRowData([row])
        this.params.node.setData(row);
        this.params.api.redrawRows({
          rowNodes: [this.params.node]
        });
      }
    },
    formatFullRowData(columns, row) {
      const _row = {};
      const clonedRow = cloneDeep(row);
      for (const key in clonedRow) {
        const index = columns.findIndex((item) => {
          return item.key === key;
        });
        if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].type === 'DATETIME_PST'
        ) {
          _row[key] = moment(
            clonedRow[key] + ` ${this.clientBrowserTimezoneInGmt}`
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        } else if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].type === 'DATETIME'
        ) {
          _row[key] = moment(clonedRow[key]).format('YYYY-MM-DD HH:mm:ss');
        } else if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].type === 'DATE'
        ) {
          _row[key] = moment(clonedRow[key]).format('YYYY-MM-DD');
        } else {
          _row[key] = clonedRow[key];
        }
      }
      return cloneDeep(_row);
    },
    saveRow() {
      const row = this.params.data;
      // let rowCopy = cloneDeep(row);
      const insert = row.inserted;
      delete row.TOTAL_ROWS;
      delete row.edited;
      delete row.inserted;
      delete row.ISDELETED;
      delete row.editeMode;
      delete row.error;
      delete row.clone;
      const prvtRowCopy = cloneDeep(row);
      this.loading = true;
      this.error = false;
      let promise = null;
      let idToRemove = null;
      const formatedNewRow = this.formatFullRowData(
        this.params.colDef.fullConfig,
        prvtRowCopy
      );
      if (insert) {
        const newRow = cloneDeep(row);
        idToRemove = newRow.ID;
        newRow.ID;
        const data = {
          body: {
            templateId: templateId,
            insert: [
              this.formatFullRowData(this.params.colDef.fullConfig, newRow)
            ]
          }
        };
        promise = this.$store.dispatch('insertInternalToolsData', data);
      } else {
        idToRemove = row.ID;
        const data = {
          body: {
            templateId: templateId,
            update: [formatedNewRow]
          }
        };
        promise = this.$store.dispatch('updateInternalToolsData', data);
      }
      promise
        .then((data) => {
          this.loading = false;
          const row = this.params.data;
          row.edited = false;
          this.params.node.setData(row);
          setTimeout(() => {
            const ids = this.$store.getters.getEditModeIds.ids;
            const index = ids.findIndex((item) => {
              return item === idToRemove;
            });
            ids.splice(index, 1);
            this.$store.dispatch('setEditModeIds', ids);
            this.params.api.redrawRows({
              rowNodes: [this.params.node]
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = true;
          row.error = true;
          row.inserted = insert;
          // this.params.node.setData(formatedNewRow);
          this.params.api.redrawRows({
            rowNodes: [this.params.node]
          });
        });
    }
  },
  template: `<div>
              <div v-if="!loading || !params.data.loading" class="u-display-flex u-flex-align-items-center">
                <div @click="editMode" v-if="!canEdit">
                  <rb-icon  class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter" :class="{'disabled': editButtonDistable}" :icon="'pencil'"></rb-icon>
                </div>
                <rb-button  v-if="canEdit" :clickFn="saveRow" :text="'Save'" :size="'s'" :type="'filled'" class="u-spacing-mr-s"></rb-button>
                <span v-if="params.data.error || error" class="u-color-red-base u-display-flex u-flex-align-items-center">
                  <rb-icon v-tippy="tippy" title="Check for the data types and missing value" class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-red-base" :icon="'error-fill'"></rb-icon>
                  Error
                </span>
              </div>
              <div v-if="loading || params.data.loading">
                <loader class="fill--parent u-bg-color-grey-white" :loading="loading || params.data.loading" :color="'#007cf6'"></loader>
              </div>
            </div>`
});

const selectTableCellComponent = Vue.extend({
  components: {},
  data() {
    return {
      selectedValue: null
    };
  },
  computed: {
    options() {
      return this.params.colDef.dropdownList;
    },
    canEdit() {
      const row = this.params.data;
      const index = (this.$store.getters.getEditModeIds.ids || []).findIndex(
        (item) => {
          return item === (row.ID || 'new');
        }
      );
      if (index === -1) {
        return false;
      } else {
        return true;
      }
    }
  },
  created() {
    this.selectedValue = {
      title: this.params.title,
      value: this.params.value
    };
  },
  methods: {
    selectionChange(context, data) {
      if (data.length <= 0) {
        return;
      }
      this.selectedValue = data[0];
      const row = this.params.data;
      row[this.params.colDef.field] = this.selectedValue.value;
      row.edited = true;
      // this.params.api.setRowData([row])
      this.params.node.setData(row);
      // this.params.api.redrawRows({
      //   rowNodes: [this.params.node]
      // });
      // this.params.api.updateRowData({update: [row]});
      // setTimeout(() => {
      //   let params = { force: true };
      //   this.params.api.refreshCells(params);
      // })
    }
  },
  template: `<div>
              <rb-select class="u-spacing-mr-s valueDropdown u-max-width-100" :sendDetails="true" :onClose="selectionChange" :options="options"  :class="{'internal-tool-disabled disabled': !canEdit}">
                  <div class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer" slot="trigger">
                      <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">{{selectedValue.value ? selectedValue.value : 'Select a value'}}</div>
                      <rb-icon class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base" :icon="'caret-down'"></rb-icon>
                  </div>
                  <template slot-scope="option" slot="item">
                    <div class="u-display-flex u-flex-align-items-center" :title="option.title">
                      {{option.title}}
                    </div>
                  </template>
                </rb-select>
              </div>`
});

function getColumnDefinition(vueRef, columns, type) {
  templateId = vueRef.selectedTemplate.ID;
  const _columnDefs = [];
  let columnDefs = [];
  // Pushing edit column always
  if (type !== 'download') {
    const obj = {};
    obj.headerComponentFramework = 'genericTableHeader';
    obj.field = 'edit';
    obj.headerName = 'Actions';
    obj.title = 'Edit';
    obj.order = -1;
    obj.pinned = 'left';
    obj.cellRendererFramework = actionComponent;
    obj.width = 150;
    obj.fullConfig = columns;
    obj.suppressSizeToFit = true;
    _columnDefs.push(obj);
  }
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i];
    if (!col.showOnUI) {
      continue;
    }
    const obj = {};
    obj.headerComponentFramework = 'genericTableHeader';
    obj.field = col.key;
    if (col.hasDropdown) {
      obj.cellRendererFramework = selectTableCellComponent;
      obj.dropdownList = col.dropdownList;
    } else {
      obj.cellRendererFramework = textEditableComponent;
    }
    obj.headerName = col.name;
    obj.title = col.name;
    obj.headerComponentParams = {
      enableSorting: true
    };
    if (col.defaultSortOrder) {
      obj.headerComponentParams.sort = col.defaultSortOrder;
    }
    obj.order = col.order;
    obj.pinned = col.pinned;
    obj.dataType = col.type;
    obj.width = 200;
    obj.suppressSizeToFit = true;
    _columnDefs.push(obj);
  }
  // /**
  //  * Sort based on key order
  //  */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.order - b.order;
  });
  return columnDefs;
}

export default {
  config: (that, columns) => {
    return {
      getColumnDefinition: getColumnDefinition,
      filters: {
        emit: 'workbenchCampaignsFilterApplied',
        hasSearch: false
      },
      widgets: {
        widget2: {
          meta: {
            type: 'table',
            key: 'campaignsTable',
            primaryKey: 'campaign_id',
            action: 'fetchInternalToolsData',
            listen: {},
            paginationAction: 'fetchInternalToolsData'
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_campaign'
          },
          body: {
            APIConfig: {
              templateId: that.selectedTemplate.ID,
              limit: 30,
              page: 1,
              orderByList: [],
              where: {
                dimensionNameValueList: []
              }
            },
            download: {
              fileName: 'temp',
              columnMap: getColumnDefinition(that, columns, 'download'),
              action: 'downloadDvtTemplateData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              }
            },
            columnDefs: getColumnDefinition(that, columns)
          }
        }
      }
    };
  }
};
