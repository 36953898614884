/* eslint-disable @typescript-eslint/camelcase */
import {
  BenchmarkMarketShareExecutivePageService,
  MetaDataReadApi
} from '../meta-data';
import { BenchmarkWidget, DataApiResponse, EntityData } from '../data-api';
import { AxiosResponse } from 'axios';
import {
  ThreePSelection,
  Where
} from '@/components/pages/insights/amazon/market-share/types/filters';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { BenchmarkingColumn, BenchmarkingColumns } from './column';
import { BenchmarkTableData } from './types';
import { Metric, MetricGroup } from './metric';
import {
  getGlobalSettingsForMS,
  customDimensionValueListForMetric,
  constants,
  asinLevelV2ConfigEnabled,
  isMarketShareLite
} from '@/components/pages/insights/amazon/market-share/utils';
// @ts-ignore
import HttpService from '@/utils/services/http-service';
// @ts-ignore
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
export class MarketShareBenchmarkTable {
  v2 = asinLevelV2ConfigEnabled();
  load = true;
  noData = false;
  metadataLoad = true;
  error = false;
  private _columns: BenchmarkingColumns | undefined = undefined;
  private _allColumns: BenchmarkingColumns =
    undefined as unknown as BenchmarkingColumns;
  metaDataService = new BenchmarkMarketShareExecutivePageService();
  dataApi: BenchmarkWidget = new BenchmarkWidget();
  metadataResponse: AxiosResponse<MetaDataReadApi> | null = null;

  generateColumn(data: EntityData, index: number, estimateType: string) {
    return new BenchmarkingColumn(
      data.entityValue,
      data,
      index,
      estimateType,
      this.metrics,
      this.threepSelection,
      this.metadataResponse
    );
  }

  get dataAsOf() {
    return moment(this.minMaxDate.rawMaxDate).format('LL');
  }

  metrics: MetricGroup | null = null;
  private whereClause: unknown = null;
  private threepSelection: ThreePSelection | null = null;
  constructor(
    private globalViewId: number,
    private pageId: number,
    private minMaxDate: {
      rawMaxDate: Date;
      rawMinDate: Date;
    }
  ) {}

  updateData(
    whereClause: Where,
    selectedCategories: string[],
    displayShareIn: 'UNIT_ESTIMATES' | 'SALES_ESTIMATES',
    switchMetricCallBack: (x: any, y: any) => void,
    threepSelection: ThreePSelection
  ) {
    this._columns?.hideAllColumns();
    // if (JSON.stringify(whereClause) === JSON.stringify(this.whereClause) && this._columns?.estimateType === displayShareIn) {
    //   const selectedCategoriesCleaned = selectedCategories.map(y => y.replace('~', '').toUpperCase());
    //   this._columns = this.generateColumns(
    //     this._allColumns.columns.filter(
    //       x => selectedCategoriesCleaned.includes(x.id.toUpperCase())
    //     ), displayShareIn, selectedCategories, switchMetricCallBack
    //   );
    //   return;
    // }
    this.whereClause = cloneDeep(whereClause);
    this.threepSelection = cloneDeep(threepSelection);
    this.initColumns(displayShareIn, selectedCategories, switchMetricCallBack);
  }

  async initColumns(
    estimateType: 'UNIT_ESTIMATES' | 'SALES_ESTIMATES',
    selectedCategories: string[],
    switchMetricCallBack: (x: any, y: any) => void
  ) {
    try {
      this.error = false;
      if (!this.metadataResponse) {
        this.metadataLoad = true;
        this.metadataResponse = await this.metaDataService.getData(
          this.pageId,
          this.globalViewId
        );
        this.metadataLoad = false;
      }

      this.initMetrics(estimateType);
      /**
       * Algorithm
       * 1. Rotate across all the metrics
       * 2. Get the metric list seggregated based on the data group
       * 3. Make the call for all the metric list based on the data group seggregation
       * 4. Merge all the requests with the metrics data
       */
      if (this.metrics) {
        this.load = true;
        const dataResponse = await this.metrics.makeBundledRequest(
          this.whereClause,
          estimateType,
          this.metadataResponse,
          this.threepSelection as ThreePSelection
        );
        this.load = false;
        if (dataResponse.data.entityData.length === 0) {
          this.noData = true;
        }
        this.initializeData(dataResponse.data);
        const columns: EntityData[] = dataResponse.data.entityData;
        this._columns = this.generateColumns(
          columns.map((x, idx) => {
            return this.generateColumn(x, Infinity, estimateType);
          }),
          estimateType,
          selectedCategories,
          switchMetricCallBack
        ) as BenchmarkingColumns;
        this._allColumns = cloneDeep(this._columns);
      }
    } catch (err) {
      console.error(err);
      this.error = true;
    }
  }

  generateColumns(
    columns: BenchmarkingColumn[],
    estimateType: 'UNIT_ESTIMATES' | 'SALES_ESTIMATES',
    selectedCategories: string[],
    switchMetricCallBack: (x: any, y: any) => void
  ): BenchmarkingColumns | undefined {
    if (this.metadataResponse && this.metrics) {
      const selectedCategoriesCleaned = selectedCategories.map((y) =>
        y.replace('~', '').toUpperCase()
      );
      const benchmarkingColumns = new BenchmarkingColumns(
        columns.filter((column) => {
          return selectedCategoriesCleaned.includes(column.id.toUpperCase());
        }),
        this.metadataResponse,
        estimateType,
        [this.metrics],
        switchMetricCallBack
      );
      const savedColumnNames =
        (getGlobalSettingsForMS('showedBenchmarkingColumns') as string[]) || [];
      // If the columns selection and order is present in the local storage, Use that
      if (savedColumnNames.length) {
        savedColumnNames
          .filter((col) =>
            benchmarkingColumns.sortedColumns.some((x) => x.id === col)
          )
          .forEach((x) => {
            benchmarkingColumns?.addColumn(x);
          });
      }
      // If none of the saved columns are present in the category selection list or there is no saved columns in the local storage
      // show the columns/categories with top 5 sales
      if (benchmarkingColumns?.showedColumns?.length === 0) {
        benchmarkingColumns.columns
          .sort((a, b) => {
            return (
              (b.selectedEntity.part?.value as number) -
              (a.selectedEntity.part?.value as number)
            );
          })
          .slice(0, 5)
          .forEach((x) => {
            benchmarkingColumns?.addColumn(x.id);
          });
      }
      return benchmarkingColumns;
    }
  }

  getASPMetric() {
    if (isMarketShareLite()) {
      return 'msl_asp';
    } else {
      return 'asp';
    }
  }

  initMetrics(estimateType: 'UNIT_ESTIMATES' | 'SALES_ESTIMATES') {
    if (this.metadataResponse) {
      const allMetrics = Object.entries(this.metadataResponse.data.metrics)
        .map(([key, value]) => {
          if (
            this.v2 &&
            value.label === 'Average Selling Price' &&
            this.threepSelection
          ) {
            const asp = this.metadataResponse?.data.metadata.asp;
            if (asp?.metricList?.[this.threepSelection] !== value.name) {
              return;
            }
            key = this.getASPMetric();
          }
          let dimensionNameValueList: {
            dimensionName: string;
            dimensionValue: boolean;
          }[] = [];
          if (!asinLevelV2ConfigEnabled()) {
            dimensionNameValueList = customDimensionValueListForMetric(
              value,
              this.metadataResponse as AxiosResponse<MetaDataReadApi>,
              this.threepSelection as ThreePSelection
            );
          }
          return new Metric(key, value.label, value, dimensionNameValueList);
        })
        .filter((x) => x !== undefined) as Metric[];
      const uiMetadata = this.metadataResponse.data.metadata;
      const {
        // Metrics that needs to be selected by default in the dropdown
        defaultMetrics = [],
        // Metrics that needs to be hidden by default in the dropdown
        defaultHiddenMetrics = []
      } = uiMetadata;
      /** Metrics list that user can choose from in the dropdown */
      const metricsForDropdown = [...defaultMetrics, ...defaultHiddenMetrics];
      const showedMetrics: Metric[] = [];
      // Sorting the metrics based on the order they need to be displayed on UI
      metricsForDropdown.forEach((metricName) => {
        const foundMetric = allMetrics.find(
          (metric) => metric.metricData?.name === metricName
        );
        if (foundMetric) {
          showedMetrics.push(foundMetric);
        }
      });
      this.metrics = new MetricGroup(
        allMetrics,
        this.dataApi,
        this.globalViewId,
        this.pageId,
        showedMetrics
      );
    }
  }

  get columns(): unknown {
    return [
      // {
      //   headerComponentFramework: "genericTableHeader",
      //   field: "ag-grid-expand",
      //   headerName: "",
      //   title: "Expand",
      //   pinned: "left",
      //   cellRendererFramework: "",
      //   cellRendererParams: {
      //     hideToolTipIfAgGridHasExpand: true,
      //     showToolTip: true,
      //     toolTipText: "",
      //   },
      //   headerComponentParams: {
      //     enableSorting: false,
      //   },
      //   minWidth: 32,
      //   maxWidth: 32,
      //   width: 32,
      //   sort: false,
      //   lockPosition: true,
      //   suppressSizeToFit: false,
      // },
      ...(this._columns ? this._columns?.columnDef : [])
    ];
  }

  initializeData(data: DataApiResponse) {
    if (this.metrics) {
      this.metrics.dataApiResponse = data;
    }
  }

  get data(): BenchmarkTableData | undefined {
    return {
      rows: this.metrics?.data
    };
  }

  async download(
    whereClause: Where,
    threepSelection: string,
    estimateType: string
  ) {
    try {
      const metadataFromService = this.metadataResponse?.data;
      if (metadataFromService !== undefined) {
        const { endPoint, request, service } =
          metadataFromService?.downloadApiTemplate;
        const payLoad = cloneDeep(request);
        payLoad.entityType = cloneDeep(request?.entityType);
        payLoad.operations.max_date = metadataFromService?.calendar?.max_date;
        payLoad.operations.min_date = metadataFromService?.calendar?.min_date;
        payLoad.where = cloneDeep(whereClause);
        if (request?.widget === 'ms_benchMarking') {
          threepSelection === 'exclude3p' &&
            payLoad.where.dimensionNameValueList.push({
              dimensionName: constants.THREE_P_GROUP_FLAG,
              dimensionValue: false
            });
        }
        if (this.v2) {
          payLoad.metricsList =
            metadataFromService.metadata.downloadMetricsList[estimateType][
              threepSelection
            ];
        } else {
          payLoad.metricsList = this.metrics?.totalMetrics;
        }
        const { data } = await HttpService.post(service, payLoad, {
          append: endPoint
        });
        downloadFile(data.url);
      }
    } catch (e: any) {
      console.error('e', e);
      throw new Error(e);
    }
  }
}
