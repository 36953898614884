<template>
  <div
    ref="dropdown-trigger"
    v-tippy="tippyOptions"
    class="dropdown-trigger big-tooltip-group-trigger u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    :class="'big-tooltip-group-trigger-' + infoTippyId"
  >
    <div
      v-tippy="{ placement: 'top' }"
      :title="metricTitleTooltip"
      class="u-spacing-pr-m overflow-styles u-display-block"
      data-cy="metricLabel"
      :data-cypress="`metricTypeName-${option.label}`"
      @mouseenter="metricLabelHover"
    >
      {{ option.label || option.name }}
      <!-- {{ option.waterfall }} -->
    </div>
    <span
      v-if="option.children"
      class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
      :data-cy="`metriceName-${option.children}`"
      style="transform: rotate(90deg)"
    />
    <BigToolTip
      v-if="!option.children && showBigTooltip"
      :id="infoTippyId"
      ref="bigTooltip"
      data-cy="bigToolTip"
      class="big-tooltip-group"
      :title="option.label || option.name"
      :body="option.tooltip"
    />
  </div>
</template>

<script>
import BigToolTip from './big-tooltip.vue';
export default {
  components: {
    BigToolTip
  },
  props: {
    showBigTooltip: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      metricTitleTooltip: null,
      infoTippyId: 'tooltip-' + this._uid
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#' + this.infoTippyId,
        reactive: true,
        distance: 4,
        placement: 'bottom-start',
        interactive: true,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        offset: '0px, 0px',
        onShow: this.handleTippyShow
      };
    }
  },
  methods: {
    metricLabelHover(e) {
      const metricEl = e?.target;
      if (metricEl?.offsetWidth < metricEl?.scrollWidth) {
        this.metricTitleTooltip = metricEl.innerText;
      } else {
        this.metricTitleTooltip = null;
      }
    },
    hideAllOtherBigTooltips() {
      (document?.querySelectorAll('.big-tooltip-group-trigger') || []).forEach(
        (item) => {
          if (
            !item.classList.contains(
              `big-tooltip-group-trigger-${this.infoTippyId}`
            )
          ) {
            item._tippy.hide();
          }
        }
      );
    },
    getHorizontalTranslateOffset(
      nodeToBeMoved,
      anchoredNode,
      maxBoundaryForRightTranslation,
      additionalPlacementPadding = 0
    ) {
      const nodeToBeMovedDimensions = nodeToBeMoved.getBoundingClientRect();
      const anchoredNodeDimensions = anchoredNode.getBoundingClientRect();
      const triggerToPopupDisplacementOffset =
        anchoredNodeDimensions.x - nodeToBeMovedDimensions.x;
      const rightDisplacementOffset =
        triggerToPopupDisplacementOffset +
        anchoredNodeDimensions.width +
        additionalPlacementPadding;
      if (
        rightDisplacementOffset + nodeToBeMovedDimensions.right <=
        maxBoundaryForRightTranslation
      ) {
        return rightDisplacementOffset;
      }
      return (
        triggerToPopupDisplacementOffset -
        nodeToBeMovedDimensions.width -
        additionalPlacementPadding
      );
    },
    getTippyNode(tippyId) {
      return document.querySelector('#' + tippyId).closest('.tippy-tooltip');
    },
    handleTippyShow() {
      this.hideAllOtherBigTooltips();
      const DROPDOWN_SELECT_BORDER_TO_DROPDOWN_OFFSET = 32;
      const viewPortWidth = document.documentElement.clientWidth;
      this.$nextTick(() => {
        const bigTooltipNode = this.getTippyNode(this.infoTippyId);
        bigTooltipNode.style.visibility = 'hidden';
        bigTooltipNode.style.transform = '';
        this.$nextTick(() => {
          const trigger = this.$refs['dropdown-trigger'];
          const horizontalOffset = this.getHorizontalTranslateOffset(
            bigTooltipNode,
            trigger,
            viewPortWidth,
            DROPDOWN_SELECT_BORDER_TO_DROPDOWN_OFFSET
          );
          bigTooltipNode.style.transform = `translate(${horizontalOffset}px)`;
          bigTooltipNode.style.visibility = '';
        });
      });
    }
  }
};
</script>

<style lang="css" scoped>
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
