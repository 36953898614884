<template>
  <div class="u-spacing-pt-xl u-spacing-pb-m">
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-color-grey-lighter u-font-size-4"
    >
      <rb-icon
        :style="{
          color: config.color
        }"
        class="rb-icon"
        icon="dot"
      />
      <span
        v-if="config.entityValue"
        data-cy="metricEntityPVP"
        class="u-spacing-mr-xs"
      >
        {{ config.entityValue }}'s
      </span>
      <span
        class="u-color-grey-base u-font-weight-400 u-spacing-mr-xs"
        data-cy="metricLabelPVP"
      >
        {{ config.metricLabel }}
      </span>

      <span
        data-cy="metricDatePVP"
        class="u-spacing-mr-s"
      >
        for {{ config.dateText }} :
      </span>
      <metric
        class="u-display-inline-flex"
        :config="config.metricConfig"
        :data="{ tag1: config.metricData.tag1 }"
      />
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';

export default {
  components: {
    metric
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        color: 'blue'
      })
    }
  }
};
</script>
