import HttpService from '@/utils/services/http-service';

export const actions = {
  updateSummarizedHealthStatus({ commit }, data) {
    commit('updateSummarizedHealthStatus', data);
  },
  updateDetailedHealthStatus({ commit }, data) {
    commit('updateDetailedHealthStatus', data);
  },
  updateIsDrawerOpen({ commit }, data) {
    commit('updateIsDrawerOpen', data);
  },
  updateExpandedHealthGroupIds({ commit }, data) {
    commit('updateExpandedHealthGroupIds', data);
  },
  updateDates({ commit }, data) {
    commit('updateDates', data);
  },
  async fetchHealthStatus({ commit, state }, { isSummarized, pageKey, dates }) {
    const action = isSummarized
      ? 'updateSummarizedHealthStatus'
      : 'updateDetailedHealthStatus';
    commit(action, {
      ...state.healthStatusSummarized,
      loading: true
    });
    const error = 'Something went wrong, while fetching the health status.';
    try {
      const res = await HttpService.post('SYSTEM_TRANSPARENT_HEALTH_STATUS', {
        summary: isSummarized,
        filter:
          dates?.from && dates?.to && pageKey
            ? {
                pageKey,
                start_date: dates.from,
                end_date: dates.to
              }
            : {}
      });
      const { status, data } = res;
      if (status === 200) {
        commit(action, {
          data,
          loading: false,
          error: null
        });
      } else {
        commit(action, {
          ...state.healthStatusSummarized,
          loading: false,
          error
        });
      }
    } catch (err) {
      commit(action, {
        ...state.healthStatusSummarized,
        loading: false,
        error
      });
    }
  }
};
