<template>
  <div
    class="automations-dashboard"
    :style="automationsStyleVariables"
  >
    <dashboard-inflator
      :key="keyValue"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      :selected-display-in="selectedDisplayIn || {}"
      :show-header-options="showHeaderOptions"
      :dashboard-help-overview="dashboardHelpOverview"
      :show-active-filter-banner="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @widgetParamsChanged="widgetParamsChanged"
      @clearOverviewFilters="clearOverviewFilters"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import utils from '@/utils/helpers/';
import moment from 'moment';
import HttpService from '@/utils/services/http-service';
import { isEqual, set, cloneDeep } from 'lodash';
import iconText from '@/components/basic/iconText.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import roi_automation_open_cases from '@/pages/automations/AutomationsOpenCases.vue';
import roi_charting_wb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import roi_automation_table from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import roi_automation_types from '@/components/widgets/custom_widgets/custom_widget_components/cards/GenericCardWidgetWrapper.vue';
import AppliedFilterOverview from '@/components/widgets/custom_widgets/custom_widget_components/utilities/AppliedFilterOverview.vue';
import ToggleChartVisibility from '@/components/widgets/custom_widgets/custom_widget_components/charts/ToggleChartVisibility.vue';
import { eventBus } from '@/utils/services/eventBus';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    roi_charting_wb,
    roi_automation_types,
    roi_automation_open_cases,
    roi_automation_table,
    AppliedFilterOverview,
    ToggleChartVisibility,
    iconText
  },
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      globalViewId: 0,
      name: 'AutomationROIReport',
      title: 'Automations and Impact'
    };
    const context = this;
    return {
      context,
      pageInfo,
      keyValue: 0,
      selectedDisplayIn: {},
      showHeaderOptions: true,
      openCasesFilterApplied: null,
      activeAutomationDetailsTable: null,
      activeAutomationDetailsTableKey: null,
      isCardCollapsed: false,
      barOverviewShowChart: false,
      columnKeyMapping: {},
      widgetMetadata: [],
      automationsStyleVariables: {},
      headerOptions: () => {
        return {
          roi_bar_overview: {
            props: {
              'data-cy': 'roi_bar_overview',
              enableRollup: false,
              enableDownload: true,
              showChart: this.barOverviewShowChart,
              enableFooter: true,
              enableWaterfall: false,
              modifyTooltipMessage: true,
              showLegendsAsChips: true,
              selectedMetricLimit: 3,
              minimumMetric: 4,
              isMetricToolTipEnabled: true,
              tooltipText: 'Download',
              removeDropdown: true,
              disableAddNewMetricButton: true,
              plotOnYAxisOnly: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto',
                'grid-column-gap': '1.5rem'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 200 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: false
              },
              headerOptions: [
                {
                  ui_component: 'ToggleChartVisibility',
                  props: {
                    showChart: this.barOverviewShowChart,
                    iconShow: 'show-chart',
                    iconHide: 'collapse'
                  },
                  order: 150,
                  events: {
                    toggleChartVisibilty: this.toggleChartVisibilty
                  }
                }
              ]
            }
          },
          roi_automation_types: {
            props: {
              widgetRequestParams: this.getWidgetRequestParams(
                'roi_automation_types'
              ),
              widgetsArrayToReload: [
                'roi_performance_overview',
                'roi_open_cases_graph'
              ],
              'data-cy': 'roi_automation_types',
              selectedDisplayIn: this.selectedDisplayIn,
              globalParams: this.getGlobalRequestParams(),
              cardClickFunction: this.onAutomationTypeChange,
              cardWidgetTitle: 'Automations Types',
              initialViewCardCount: 4,
              isHorizontallyScrollable: true,
              isTooltipEnabled: false,
              handleMultiTableViews: true,
              constantFooterBg: 'bg-green',
              isCardCollapsed: this.isCardCollapsed,
              cardTooltipText: 'This automation is currently not activated.'
            },
            events: {
              handleMultiTableViews: this.handleMultiTableViews
            }
          },
          roi_performance_overview: {
            props: {
              'data-cy': 'roi_performance_overview',
              headerOptions: [],
              enableRollup: true,
              enableDownload: true,
              enableDisplayIn: false,
              selectedMetricLimit: 3,
              minimumMetric: 4,
              removeDropdown: false,
              disableAddNewMetricButton: true,
              isMetricToolTipEnabled: true,
              modifyTooltipMessage: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto',
                'grid-column-gap': '1.5rem'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 300 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: false
              },
              colorObject: {
                'Total Cases': '#ffa800',
                'Successful Resolutions': '#bd10e0',
                Impact: '#97cc04',
                'Closed Cases': '#ff6072',
                'Open Cases': '#23b5d3',
                'Success Rate': '#f5d908',
                'Vendor Action Pending': '#6886C5'
              }
            }
          },
          roi_open_cases_graph: {
            props: {
              chartConfig: {
                chartOptions: {
                  legend: false,
                  type: 'donut',
                  data: {
                    colors: {
                      'Needs your Attention': '#FFA800',
                      'Pending Amazon Action': 'rgb(35, 181, 211, 0.5)'
                    }
                  },
                  tooltip: {
                    show: false
                  },
                  size: {
                    donutDimensions: {
                      height: 200,
                      width: 200
                    }
                  }
                },
                donut: {
                  expand: false,
                  label: {
                    show: false,
                    position: 'right'
                  },
                  width: 27
                }
              },
              automationDetailsMap: {
                'UNAVAILABLE SKUs': 'roi_automation_details_unavailable',
                '3P Variants': 'roi_automation_details_3p_variant',
                'PO Discrepancy': 'roi_automation_details_po_discrepancy',
                'Variation Authority':
                  'roi_automation_details_variation_authority',
                'Content Authority': 'roi_automation_details_content_authority',
                'Duplicate Listing': 'roi_automation_details_duplicate_listing'
              }
            },
            events: {
              handleViewDetails: this.handleViewDetails
            }
          },
          roi_automation_details_unavailable: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          },
          roi_automation_details_3p_variant: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          },
          roi_automation_details_po_discrepancy: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          },
          roi_automation_details_variation_authority: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          },
          roi_automation_details_content_authority: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          },
          roi_automation_details_duplicate_listing: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.automationDetailsDownload,
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true,
                placeholder: 'Search by Status, Case ID, ASIN',
                searchDimension: 'local_search'
              },
              headerOptions: [
                {
                  order: 50,
                  ui_component: 'iconText',
                  props: {
                    icon: 'table-chart',
                    iconClasses: 'u-color-grey-lighter u-spacing-mt-xs'
                  }
                },
                {
                  ui_component: 'AppliedFilterOverview',
                  props: {
                    filterData: this.openCasesFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 2fr auto auto auto',
                'grid-column-gap': '1rem'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name,
          ':dimensionNameValueList': []
        },
        roi_bar_overview: {
          ':widget': 'roi_bar_overview',
          ':timeseriesRollupBy': 'DAYS',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':dimensionNameValueList': []
        },
        roi_automation_types: {
          ':widget': 'roi_automation_types',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        roi_open_cases_graph: {
          ':widget': 'roi_open_cases_graph',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':dimensionNameValueList': [
            {
              dimensionName: 'filter_issue_type',
              dimensionValue: 'UNAVAILABLE SKUs',
              operator: 'ILIKE'
            }
          ]
        },
        roi_performance_overview: {
          ':widget': 'roi_performance_overview',
          ':timeseriesRollupBy': 'DAYS',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':dimensionNameValueList': [
            {
              dimensionName: 'filter_issue_type',
              dimensionValue: 'UNAVAILABLE SKUs',
              operator: 'ILIKE'
            }
          ]
        },
        roi_automation_details_unavailable: {
          ':widget': 'roi_automation_details_unavailable',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        roi_automation_details_3p_variant: {
          ':widget': 'roi_automation_details_3p_variant',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        roi_automation_details_po_discrepancy: {
          ':widget': 'roi_automation_details_po_discrepancy',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        roi_automation_details_variation_authority: {
          ':widget': 'roi_automation_details_variation_authority',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        roi_automation_details_content_authority: {
          ':widget': 'roi_automation_details_content_authority',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        roi_automation_details_duplicate_listing: {
          ':widget': 'roi_automation_details_duplicate_listing',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        }
      },
      filterSettings: {
        emitEvent: 'automationsFilterApply',
        uiKey: 0,
        filterBarLSKey: 'automations-filter-bar',
        show: true,
        backendCube: 'automations_dashboard',
        defaultDatePostRange: 'last30Days',
        enableSave: false,
        listenerEvent: 'automations-filter',
        allowCompare: false,
        externalFilters: {},
        filterV2: true
      },
      dashboardHelpOverview: {
        isDashboardHelpOverviewPresent: true,
        documentPath:
          'https://cdn.rboomerang.com/helpDoc/Automations%20Impact%20Logic.pdf'
      }
    };
  },
  computed: {
    clientTimezone() {
      return this.$store.getters.getClientTimezone.value;
    }
  },
  watch: {
    openCasesFilterApplied(nv, ov) {
      if (!isEqual(ov, nv)) {
        this.updateColumnMapping();
      }
    }
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('roi_bar_overview', roi_charting_wb);
    Vue.component('roi_automation_types', roi_automation_types);
    Vue.component('roi_performance_overview', roi_charting_wb);
    Vue.component('roi_open_cases_graph', roi_automation_open_cases);
    Vue.component('roi_automation_details_unavailable', roi_automation_table);
    Vue.component('roi_automation_details_3p_variant', roi_automation_table);
    Vue.component(
      'roi_automation_details_po_discrepancy',
      roi_automation_table
    );
    Vue.component(
      'roi_automation_details_variation_authority',
      roi_automation_table
    );
    Vue.component(
      'roi_automation_details_content_authority',
      roi_automation_table
    );
    Vue.component(
      'roi_automation_details_duplicate_listing',
      roi_automation_table
    );
    Vue.component('AppliedFilterOverview', AppliedFilterOverview);
    Vue.component('ToggleChartVisibility', ToggleChartVisibility);
  },
  mounted() {
    // setTimeout(() => {
    //   const stickyElm = document.querySelector('.automations-dashboard > div > span:nth-child(3)');
    //   const observer = new IntersectionObserver(
    //     ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
    //     { rootMargin: '0px 0px 0px 0px' }
    //   );
    //   if (stickyElm) observer.observe(stickyElm);
    // }, 10000);
    // let stickyElm = '';
    // setTimeout(() => {
    //   stickyElm = document.querySelector('.automations-dashboard > div');
    // }, 10000);
    // Getting page-header height dynamically
    let pageHeaderHeight = document.querySelector('.page-header')?.offsetHeight;
    pageHeaderHeight = pageHeaderHeight ? `${pageHeaderHeight}px` : '88px'; // keeping fallback value hardcoded
    this.automationsStyleVariables = {
      '--pageHeaderHeight': pageHeaderHeight,
      '--activeFilterBannerHeight': '0px'
    };
    const scrollDemo = document.querySelector('#pageBody');
    scrollDemo.addEventListener(
      'scroll',
      () => {
        this.handleScroll(scrollDemo.scrollTop);
      },
      { passive: true }
    );
    eventBus.$on(
      'activeFilterBannerVisibility',
      this.addBannerHeightToVariable
    );
  },
  beforeDestroy() {
    eventBus.$off('activeFilterBannerVisibility');
    document.getElementById('pageBody').removeEventListener('scroll');
  },
  methods: {
    addBannerHeightToVariable(bannerVisibility) {
      if (bannerVisibility) {
        this.automationsStyleVariables['--activeFilterBannerHeight'] = '30px';
      } else {
        this.automationsStyleVariables['--activeFilterBannerHeight'] = '0px';
      }
    },
    handleScroll(currentScrollTop) {
      const breakpointOffsetToMiniseWidget = this.barOverviewShowChart
        ? 398
        : 153;
      if (currentScrollTop > breakpointOffsetToMiniseWidget) {
        const stickyElm = document.querySelector(
          '.automations-dashboard > div > span:nth-child(3)'
        );
        stickyElm?.classList.add('is-sticky');
        this.isCardCollapsed = true;
      } else {
        const stickyElm = document.querySelector(
          '.automations-dashboard > div > span:nth-child(3)'
        );
        stickyElm?.classList.remove('is-sticky');
        this.isCardCollapsed = false;
      }
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
      this.updateColumnMapping();
    },
    clearOverviewFilters() {
      var tempWidgetParams = this.getWidgetRequestParams(
        this.activeAutomationDetailsTableKey
      );
      delete tempWidgetParams[':from'];
      delete tempWidgetParams[':to'];
      tempWidgetParams[':dimensionNameValueList'] = [];
      this.updateWidgetRequestParams(
        tempWidgetParams,
        this.activeAutomationDetailsTableKey
      );
      this.openCasesFilterApplied = null;
    },
    widgetParamsChanged(data) {
      this.handleTableVisibilityLogic(data);
      this.handleOpenCasesFilter();
    },
    handleTableVisibilityLogic(data) {
      const availableAutomationDetails = [
        'roi_automation_details_unavailable',
        'roi_automation_details_variation_authority',
        'roi_automation_details_po_discrepancy',
        'roi_automation_details_duplicate_listing',
        'roi_automation_details_content_authority',
        'roi_automation_details_3p_variant'
      ];
      // Updating active table logic for supporting clearOverviewFilters method.
      // activeAutomationDetailsTableKey && activeAutomationDetailsTable needed for clearing overview filters
      for (const key of availableAutomationDetails) {
        if (
          data[key]?.metadata?.hasOwnProperty('isWidgetVisibile') &&
          data[key].metadata.isWidgetVisibile
        ) {
          this.activeAutomationDetailsTableKey = key;
          this.activeAutomationDetailsTable = data[key];
        }
      }
    },
    handleOpenCasesFilter() {
      const dimensionValueCasingMap = {
        'pending on amazon': 'Pending on Amazon',
        'pending on you': 'Pending on You'
      };
      if (this.activeAutomationDetailsTable) {
        const dimensionNameValueList =
          this.activeAutomationDetailsTable[':dimensionNameValueList'];
        if (
          !dimensionNameValueList[0]?.dimensionValue ||
          dimensionNameValueList[0]?.dimensionName !== 'filter_issue_type'
        )
          return;
        const filter = {
          prefix: 'Cases with status',
          title: `${
            dimensionValueCasingMap[dimensionNameValueList[0]?.dimensionValue]
          }`,
          suffix: 'filtered in the table'
        };
        this.openCasesFilterApplied = filter;
      }
    },
    toggleChartVisibilty(val) {
      this.barOverviewShowChart = val;
    },
    updateColumnMapping() {
      const automationDetailsWidget = this.widgetMetadata.find(
        (widget) => widget?.name === this.activeAutomationDetailsTableKey
      );
      if (!automationDetailsWidget) return;
      const splitKey = this.activeAutomationDetailsTableKey.split(
        'roi_automation_details_'
      );
      const dynamicStatusColumnKey = `status_${splitKey[1]}`;
      const metricMetadata = cloneDeep(
        automationDetailsWidget.metrics[`${dynamicStatusColumnKey}`].metadata
      );
      const { alias: metricAlias } =
        automationDetailsWidget.metrics[`${dynamicStatusColumnKey}`];
      const columnKeyMapping = {};
      if (this.openCasesFilterApplied) {
        columnKeyMapping[metricAlias] = metricMetadata;
        set(metricMetadata, ['tableConfig', 'columnHeader'], {
          ui_component: 'dataHeader',
          props: {
            alignHeader: 'left',
            enableSorting: true
          }
        });
        metricMetadata.tableConfig.columnHeader.props.headerIcon = {
          icon: 'filter',
          align: 'left',
          class: 'u-color-blue-base rb-icon--medium'
        };
        set(columnKeyMapping, metricAlias, metricMetadata);
      } else {
        if (metricMetadata.tableConfig?.columnHeader) {
          metricMetadata.tableConfig.columnHeader = null;
        }
        set(columnKeyMapping, metricAlias, metricMetadata);
      }
      this.columnKeyMapping = columnKeyMapping;
    },
    handleMultiTableViews(selectedMetric) {
      const hashMap = {
        unavailable: 'roi_automation_details_unavailable',
        three_p_variants: 'roi_automation_details_3p_variant',
        po_discrepancy: 'roi_automation_details_po_discrepancy',
        variation_authority: 'roi_automation_details_variation_authority',
        content_authority: 'roi_automation_details_content_authority',
        duplicate_listing: 'roi_automation_details_duplicate_listing'
      };
      for (let i = 0; i < this.widgetMetadata.length; i++) {
        if (
          !this.widgetMetadata[i].metadata?.hasOwnProperty('isWidgetVisibile')
        ) {
          continue;
        } else {
          this.widgetMetadata[i].metadata.isWidgetVisibile =
            this.widgetMetadata[i].name === hashMap[selectedMetric];
        }
      }
      var tempWidgetParams = this.getWidgetRequestParams(
        hashMap[selectedMetric]
      );
      tempWidgetParams[':dimensionNameValueList'] = [];
      this.updateWidgetRequestParams(tempWidgetParams, hashMap[selectedMetric]);
      this.clearOverviewFilters();
    },
    handleViewDetails(objData) {
      const statusType = objData.statusType.split('_').join(' ');
      const tempWidgetParams = this.getWidgetRequestParams(
        this.activeAutomationDetailsTableKey
      );
      if (objData.minMaxDate.minDate.includes('/')) {
        tempWidgetParams[':from'] = moment(
          objData.minMaxDate.minDate,
          'MM/DD/YYYY'
        ).format('YYYY-MM-DD');
        tempWidgetParams[':to'] = moment().format('YYYY-MM-DD');
      } else if (objData.minMaxDate.minDate.includes('-')) {
        tempWidgetParams[':from'] = objData.minMaxDate.minDate;
        tempWidgetParams[':to'] = moment().format('YYYY-MM-DD');
      }
      tempWidgetParams[':dimensionNameValueList'] = [
        {
          dimensionName: 'filter_issue_type',
          dimensionValue: statusType,
          operator: 'ILIKE'
        }
      ];
      this.updateWidgetRequestParams(
        tempWidgetParams,
        this.activeAutomationDetailsTableKey
      );
      document
        .querySelector('.dashboard-service-table-wrapper')
        .scrollIntoView({ behavior: 'smooth' });
    },
    convertToClientTimezone(date, time) {
      const utcTime = `${date} ${time}`;
      const convertedDateTimeStamp = moment
        .utc(utcTime, 'MM/DD/YYYY hh:mm:ss a')
        .tz(this.clientTimezone)
        .format('MM/DD/YYYY hh:mm:ss a');
      if (!moment(convertedDateTimeStamp).isValid()) {
        return null;
      }
      const dateTimeArray = convertedDateTimeStamp.split(' ');
      return dateTimeArray;
    },
    async automationDetailsDownload(widgetMeta, requestParams) {
      try {
        const api = widgetMeta.downloadApiTemplate;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          requestParams
        );
        request.where.dimensionNameValueList =
          requestParams[':dimensionNameValueList'];
        request.operations.orderByList = requestParams[':orderByList'] || [];
        request.metricsList = requestParams[':metricsList'];
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        const dimensionColumn = [];
        Object.keys(widgetMeta.metrics).forEach((metric) => {
          if (
            widgetMeta.metrics[metric]?.metadata?.downloadable ||
            widgetMeta.metrics[metric]?.metadata?.visible
          ) {
            dimensionColumn.push({
              field: metric,
              title: widgetMeta.metrics[metric].label,
              priority: widgetMeta.metrics[metric].priority,
              type: widgetMeta?.metrics[metric]?.metadata?.type || ''
            });
          }
        });
        dimensionColumn.sort((item1, item2) => item1.priority > item2.priority);
        const entityData = dashUtils.mergeEntityDataResult(
          response.data.entityData
        );
        const automationDetailsMap = {
          roi_automation_details_unavailable: 'unavailable',
          roi_automation_details_3p_variant: 'three_p_variant',
          roi_automation_details_po_discrepancy: 'po_discrepancy',
          roi_automation_details_variation_authority: 'variation_authority',
          roi_automation_details_content_authority: 'content_authority',
          roi_automation_details_duplicate_listing: 'duplicate_listing'
        };
        const widgetKey = automationDetailsMap[widgetMeta.name];
        const tableColumnstoModify = [
          [`initiation_date_${widgetKey}`, `initiation_time_${widgetKey}`],
          [`resolution_date_${widgetKey}`, `resolution_time_${widgetKey}`]
        ];
        entityData.forEach((row) => {
          tableColumnstoModify.forEach((column) => {
            const timezoneConverted = this.modifyDateAndTimeBasedOnTimezones(
              row,
              column
            );
            row[column[0]] =
              timezoneConverted && timezoneConverted[0]
                ? moment(timezoneConverted[0]).format('MMM DD, YYYY')
                : 'NA';
            row[column[1]] =
              timezoneConverted && timezoneConverted[1]
                ? `${timezoneConverted[1] ? timezoneConverted[1] : ''} ${
                    timezoneConverted[2] ? timezoneConverted[2] : ''
                  }`
                : 'NA';
          });
        });
        utils.performDownload(
          entityData,
          dimensionColumn,
          widgetMeta.label || widgetMeta.name
        );
      } catch (error) {
        console.log(error);
        throw Error(error);
      }
    },
    modifyDateAndTimeBasedOnTimezones(row, column) {
      const defaultDate = row[column[0]];
      const defaultTime = row[column[1]];
      const convertedTimeStamp = this.convertToClientTimezone(
        defaultDate,
        defaultTime
      );
      return convertedTimeStamp;
    }
  }
};
</script>

<style lang="css">
.automations-dashboard {
  max-width: 1400px;
  margin: auto;
}
.automations-dashboard .card__title {
  text-transform: uppercase;
}
.automations-dashboard .filter-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--pageHeaderHeight);
  padding-right: 0;
  padding-left: 0;
}
.automations-dashboard .roi_performance_overview,
.automations-dashboard .roi_open_cases_graph,
.automations-dashboard .roi_automation_details_unavailable,
.automations-dashboard .roi_automation_details_3p_variant,
.automations-dashboard .roi_automation_details_variation_authority,
.automations-dashboard .roi_automation_details_content_authority,
.automations-dashboard .roi_automation_details_po_discrepancy,
.automations-dashboard .roi_automation_details_duplicate_listing {
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}
.automations-dashboard .roi_bar_overview {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.automations-dashboard .roi_bar_overview .header-container-cwc {
  padding: 1.5rem 24px 0px 24px;
}
.automations-dashboard .roi_bar_overview .card__title {
  font-size: 1.4rem;
  margin-top: 0 !important;
}
.automations-dashboard .roi_bar_overview .metric-card-hover-trigger {
  margin: 1rem 0rem 0rem 0rem !important;
}
.automations-dashboard .roi_bar_overview .chip-wrapper {
  margin: 0rem 1.5rem 1.5rem 0rem !important;
  cursor: default;
}
.automations-dashboard .roi_bar_overview .impact-metric .chip-wrapper,
.automations-dashboard .roi_bar_overview .impact-metric .custom-chart-legend {
  background-color: rgba(62, 169, 94, 0.1) !important;
}
.automations-dashboard .roi_bar_overview .chip-value .rb-metric--m {
  align-items: center;
}
.automations-dashboard
  .roi_bar_overview
  .chip-value
  .rb-metric--m
  .secondary_metric {
  font-size: 14px;
}
.automations-dashboard
  .roi_bar_overview
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon {
  margin: 0px 1px 0px 4px;
}
.automations-dashboard
  .roi_bar_overview
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon:before {
  font-size: 10px;
}
.automations-dashboard
  .roi_bar_overview.charting_wb_wrapper
  .custom_widget_body {
  margin-bottom: 0;
}
.automations-dashboard .roi_automation_types {
  position: relative;
  margin-bottom: 0rem !important;
  margin-right: 0rem;
  overflow: hidden;
}
.automations-dashboard .roi_automation_types .card {
  margin-bottom: 1.6rem !important;
}
.automations-dashboard .roi_automation_types .card-wrapper,
.automations-dashboard .roi_automation_types .card-header,
.automations-dashboard .roi_automation_types .card-header-label {
  transition: 0.1s all ease;
}
.automations-dashboard .roi_automation_types .card-header .card__title {
  text-transform: capitalize;
}
.automations-dashboard .roi_automation_types .metric-component .primary_metric {
  font-weight: 700;
}
.automations-dashboard .roi_automation_types .card-footer .rb-metric {
  align-items: center;
}
.automations-dashboard .roi_performance_overview {
  margin-top: 0rem !important;
}
.automations-dashboard .roi_performance_overview .header-container-cwc {
  padding-bottom: 0.75rem;
}
.automations-dashboard
  .roi_performance_overview
  .impact-metric
  .custom-chart-legend {
  background-color: rgba(62, 169, 94, 0.1) !important;
}
.automations-dashboard .roi_open_cases_graph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
}
.automations-dashboard .roi_open_cases_graph .header-container-cwc {
  padding: 2.4rem 2.4rem;
}
.automations-dashboard
  .roi_open_cases_graph
  .c3-chart-arcs
  .c3-chart-arcs-title {
  fill: #8b8f93;
  font-size: 40px;
  font-weight: 600;
}
.automations-dashboard .roi_open_cases_graph .c3-chart-arcs {
  pointer-events: none;
}
.automations-dashboard .roi_automation_details_unavailable .link--asin,
.automations-dashboard .roi_automation_details_3p_variant .link--asin,
.automations-dashboard .roi_automation_details_variation_authority .link--asin,
.automations-dashboard .roi_automation_details_content_authority .link--asin,
.automations-dashboard .roi_automation_details_po_discrepancy .link--asin,
.automations-dashboard .roi_automation_details_duplicate_listing .link--asin {
  color: #2b333b;
  border-bottom: 1px dotted;
}
.automations-dashboard .roi_automation_details_unavailable .link--asin:hover,
.automations-dashboard .roi_automation_details_3p_variant .link--asin:hover,
.automations-dashboard
  .roi_automation_details_variation_authority
  .link--asin:hover,
.automations-dashboard
  .roi_automation_details_content_authority
  .link--asin:hover,
.automations-dashboard .roi_automation_details_po_discrepancy .link--asin:hover,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .link--asin:hover {
  color: #007cf6;
  border-bottom: 1px dotted;
  text-decoration: none;
}
.automations-dashboard .roi_automation_details_unavailable .search__input,
.automations-dashboard .roi_automation_details_3p_variant .search__input,
.automations-dashboard
  .roi_automation_details_variation_authority
  .search__input,
.automations-dashboard .roi_automation_details_content_authority .search__input,
.automations-dashboard .roi_automation_details_po_discrepancy .search__input,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .search__input {
  margin-right: 1rem;
}
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_unavailable'].ag-cell,
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_three_p_variant'].ag-cell,
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_variation_authority'].ag-cell,
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_content_authorit'].ag-cell,
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_po_discrepancy'].ag-cell,
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_duplicate_listing'].ag-cell,
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_duplicate_listing'].ag-cell,
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_duplicate_listing'].ag-cell,
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_duplicate_listing'].ag-cell,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_duplicate_listing'].ag-cell,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_duplicate_listing'].ag-cell {
  border-right: 1px solid #e9eaeb !important;
}
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_unavailable'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_three_p_variant'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_variation_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_content_authority'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_po_discrepancy'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_unavailable
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[col-id^='status_duplicate_listing'][class='ag-header-cell ag-header-cell-sortable'] {
  border-right: 1px solid #e9eaeb !important;
}
.automations-dashboard
  .roi_automation_details_unavailable
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.automations-dashboard
  .roi_automation_details_3p_variant
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.automations-dashboard
  .roi_automation_details_variation_authority
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.automations-dashboard
  .roi_automation_details_content_authority
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.automations-dashboard
  .roi_automation_details_po_discrepancy
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.automations-dashboard
  .roi_automation_details_duplicate_listing
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'] {
  border-right: 1px solid #e9eaeb !important;
}
.automations-dashboard
  .roi_automation_details_unavailable
  .badge.needs-your-attention,
.automations-dashboard
  .roi_automation_details_3p_variant
  .badge.needs-your-attention,
.automations-dashboard
  .roi_automation_details_variation_authority
  .badge.needs-your-attention,
.automations-dashboard
  .roi_automation_details_content_authority
  .badge.needs-your-attention,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  .badge.needs-your-attention,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .badge.needs-your-attention {
  background-color: #ffa800;
}
.automations-dashboard
  .roi_automation_details_unavailable
  .badge.pending-amazon-action,
.automations-dashboard
  .roi_automation_details_3p_variant
  .badge.pending-amazon-action,
.automations-dashboard
  .roi_automation_details_variation_authority
  .badge.pending-amazon-action,
.automations-dashboard
  .roi_automation_details_content_authority
  .badge.pending-amazon-action,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  .badge.pending-amazon-action,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .badge.pending-amazon-action {
  background-color: #23b5d3;
}
.automations-dashboard
  .roi_automation_details_unavailable
  .badge.unsuccessful-resolution,
.automations-dashboard
  .roi_automation_details_3p_variant
  .badge.unsuccessful-resolution,
.automations-dashboard
  .roi_automation_details_variation_authority
  .badge.unsuccessful-resolution,
.automations-dashboard
  .roi_automation_details_content_authority
  .badge.unsuccessful-resolution,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  .badge.unsuccessful-resolution,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .badge.unsuccessful-resolution {
  background-color: #2b333b;
}
.automations-dashboard
  .roi_automation_details_unavailable
  .badge.successful-resolution,
.automations-dashboard
  .roi_automation_details_3p_variant
  .badge.successful-resolution,
.automations-dashboard
  .roi_automation_details_variation_authority
  .badge.successful-resolution,
.automations-dashboard
  .roi_automation_details_content_authority
  .badge.successful-resolution,
.automations-dashboard
  .roi_automation_details_po_discrepancy
  .badge.successful-resolution,
.automations-dashboard
  .roi_automation_details_duplicate_listing
  .badge.successful-resolution {
  background-color: #3ea95e;
}
.automations-dashboard > div {
  margin-top: calc(100px + var(--activeFilterBannerHeight));
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
.automations-dashboard > div .filter-wrapper {
  width: 100%;
}
.automations-dashboard > div > span {
  width: 100%;
}
.automations-dashboard > div > span:nth-child(3) {
  position: sticky;
  top: calc(90px + var(--activeFilterBannerHeight));
  align-self: center;
  display: block;
  z-index: 11;
  background-color: #f4f7fa;
}
.automations-dashboard > div > span:nth-child(4) {
  width: 65%;
}
.automations-dashboard > div > span:nth-child(5) {
  width: 35%;
}
.automations-dashboard > div > span:nth-child(5) .u-envelop-width {
  display: flex;
  height: 100%;
}
.automations-dashboard .is-sticky .roi_automation_types .rb-metric {
  align-items: center;
}
.automations-dashboard
  .is-sticky
  .roi_automation_types
  .rb-metric
  .primary_metric {
  font-weight: 600;
}
.automations-dashboard
  .is-sticky
  .roi_automation_types
  .rb-metric
  .secondary_metric {
  font-size: 14px;
}
.automations-dashboard
  .is-sticky
  .roi_automation_types
  .rb-metric
  .secondary_metric
  .rb-icon {
  margin: 0px 1px 0px 4px;
}
.automations-dashboard
  .is-sticky
  .roi_automation_types
  .rb-metric
  .secondary_metric
  .rb-icon:before {
  font-size: 10px;
}
.automations-dashboard .is-sticky .card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.automations-dashboard .is-sticky .card-header {
  white-space: nowrap;
  padding: 0.9rem 0rem 0.9rem 1rem !important;
}
.automations-dashboard .is-sticky .card-header .card__title {
  font-size: 1.6rem;
  font-weight: normal !important;
}
.automations-dashboard .is-sticky .card-header .card-header-label {
  padding-bottom: 0rem;
}
.automations-dashboard .is-sticky .card-footer {
  display: none;
}
.automations-dashboard .is-sticky .rb-metric {
  min-height: auto !important;
  flex-direction: row;
}
.automations-dashboard .is-sticky .rb-metric .primary_metric {
  font-size: 1.6rem;
}
.automations-dashboard .is-sticky .rb-metric .secondary_metric {
  margin-left: 0.5rem;
}
.automations-dashboard .is-sticky .metric-component {
  margin-bottom: 0 !important;
}
</style>
