import { targetRoutes } from '@/router/target.js';
import { filters } from '@ciq-dev/ciq-ui';

const getCriteoRetailerRoutes = (retailer) => {
  const criteoRetailerRoutes = {
    ...targetRoutes,
    path: `/us/${retailer}/retail`,
    children: [...targetRoutes.children]
  };

  criteoRetailerRoutes.children.shift();
  criteoRetailerRoutes.children = [
    {
      path: '',
      name: 'Home',
      redirect() {
        const criteoRetailerMarketInsightsConfig = filters.config_check(
          `feature.insights.${retailer}_super_sov_v2`
        );
        if (criteoRetailerMarketInsightsConfig.enable) {
          return 'digital_shelf/market_insights';
        }
        return 'advertising/campaign_management';
      }
    },
    ...criteoRetailerRoutes.children
  ];

  return criteoRetailerRoutes;
};

export { getCriteoRetailerRoutes };
