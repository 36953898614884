<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl u-spacing-pb-xxl">
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <campaignTypes
        :campaign-types="campaignTypes"
        :default-selected-index="selectedCampaignTypeIndex"
        :step-configs="step"
        @selectedIndex="campaignSelected"
        @radioSelctionChanged="radioSelectionChanged"
      />
      <createCampaignFooter
        :disable-right-btn="false"
        class="u-margin-top-40"
        :show-left-btn="false"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import campaignTypes from './campaignTypes';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    campaignTypes
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    suggestedCampaignIndex: 0,
    selectedCampaignTypeIndex: 0,
    campaignTypes: [
      {
        url: '',
        title: 'Sponsored Products',
        key: 'createOwnCampaignSP',
        desc: 'Promote products to shoppers actively searching with related keywords or viewing similar products on Amazon.',
        image: '/images/SPTile.svg'
      },
      {
        url: '',
        disabled: true,
        title: 'Sponsored Brands Product',
        key: 'createOwnCampaignSB',
        desc: 'Showcase a collection of products to shoppers actively searching with related keywords on Amazon.',
        image: '/images/HSATile.svg'
      }
      // {
      //   url: '',
      //   disabled: true,
      //   title: 'Sponsored Display',
      //   desc: 'Grow your business by reaching relevant audiences on and off Amazon. Access campaigns and targeting features from Product Display Ads within Sponsored Display.',
      //   image: '/images/SDTile.svg'
      // }
    ]
  }),
  computed: {
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const { campaignType, spTargetingType } =
      this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
    this.selectedCampaignTypeIndex = this.campaignTypes.findIndex(
      (item) => item.key === campaignType
    );
    this.step.spTargetingType = spTargetingType; // auto / manual
    this.$parent.$emit('onStepNextBtnDisabled', {
      disabled: false,
      index: this.step.index
    }); // The next button is always enabled
  },
  methods: {
    campaignSelected(index) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: true,
        index: this.step.index + 1
      }); // Resetting the enabled steps
      this.step.description = this.campaignTypes[index].title;
      this.updateSelectedStore({
        campaignType: this.campaignTypes[index].key,
        spTargetingType: 'manual',
        targets: []
      });
    },
    // For SP only to change between auto and manual targeting type
    radioSelectionChanged(selection) {
      let autoTargetingMetbod =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.autoTargetingMetbod || null;
      let targets = [];
      if (selection === 'auto') {
        autoTargetingMetbod = 'default_bid'; // default_bid / bids_by_targeting_group
        targets = this.step.rows;
      }
      this.updateSelectedStore({
        spTargetingType: selection,
        autoTargetingMetbod,
        targets
      }); // auto / manual
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style scoped></style>
