<template>
  <div class="u-display-flex u-width-100">
    <div class="u-width-50 u-spacing-mr-m">
      <div class="custom--table">
        <div
          v-if="stage.load || stage.noData || stage.error"
          class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-height-100"
        >
          <loader
            v-if="stage.load"
            class="fill--parent custom-bg"
            :loading="stage.load"
            :color="'#007cf6'"
          />
          <div
            v-if="stage.noData || !stage"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter line-height-1_5"
          />
          <div
            v-if="stage.error"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
          >
            <p>Error Occurred. Please try again later</p>
          </div>
        </div>
        <div
          v-else
          class="u-spacing-pb-l"
        >
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center custom--table--header u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span class="u-font-size-6 u-font-weight-600"
                >{{ mainTableTitle }} ({{ mainTableCount }})</span
              >
              <div
                class="u-flex-1 search__block--wrapper u-position-relative u-spacing-ml-l"
                @click.stop
              >
                <div
                  class="u-display-flex search_input u-position-relative u-flex-align-items-center"
                >
                  <rb-input
                    v-model="searchText"
                    placeholder="Search"
                    :class-list="['shortlistable_search_input_field']"
                    :rounded="false"
                    @input="getSearchResults"
                  />
                  <div
                    :style="{ right: '18px' }"
                    class="u-position-absolute"
                  >
                    <rb-icon
                      :icon="'search'"
                      class="u-color-grey-x-light search__input__icon rb-icon--small"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-tippy="tippyLeft"
              class="u-cursor-pointer"
              :title="tooltipTitle"
              @click="addAllToShortList()"
            >
              <rb-icon
                class="rb-icon--medium hover-state u-spacing-mh-s u-color-grey-base"
                :icon="'plus'"
              />
            </div>
          </div>
          <div class="u-spacing-ph-l u-spacing-pb-l">
            <div class="u-overflow-auto custom--table--body">
              <div
                v-if="mainTable.length === 0"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100"
              >
                <span class="u-font-size-6 u-color-grey-mid-light"
                  >No {{ mainTableTitle }} found for the selected rules.</span
                >
              </div>
              <div
                v-if="mainTableEmpty"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100"
              >
                <span class="u-font-size-6 u-color-grey-mid-light"
                  >No {{ mainTableTitle }} found.</span
                >
              </div>

              <div
                v-for="(row, index) in mainTable"
                :key="`mainTable-${row[primary_key]}`"
                class="u-spacing-mt-m"
                :class="{ disable: row.shortListed }"
              >
                <div
                  v-if="row.searched"
                  class="u-display-flex u-flex-justify-content-space-between"
                >
                  <span class="u-font-size-6 u-color-grey-light">{{
                    row[display_key_main]
                  }}</span>
                  <div
                    v-if="row.allow"
                    class="u-cursor-pointer"
                    @click="addToShortList(index)"
                  >
                    <rb-icon
                      class="rb-icon--medium hover-state u-spacing-mh-s u-color-grey-light"
                      :icon="'plus'"
                    />
                  </div>
                  <div
                    v-else
                    v-tippy="tippy"
                    class="u-cursor-pointer"
                    :title="row.infoTooltip"
                  >
                    <rb-icon
                      class="rb-icon--medium hover-state u-spacing-mh-s u-color-grey-light"
                      :icon="'info-circle-outline'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-width-50">
      <div class="custom--table">
        <div
          v-if="stage.load || stage.noData || stage.error"
          class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-height-100"
        >
          <loader
            v-if="stage.load"
            class="fill--parent custom-bg"
            :loading="stage.load"
            :color="'#007cf6'"
          />
          <div
            v-if="stage.noData || !stage"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter line-height-1_5"
          />
          <div
            v-if="stage.error"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
          >
            <p>Error Occurred. Please try again later</p>
          </div>
        </div>
        <div
          v-else
          class="u-spacing-pb-l"
        >
          <div
            class="u-display-flex u-flex-justify-content-space-between custom--table--header u-spacing-ph-l u-spacing-pt-l u-spacing-pb-s"
          >
            <span class="u-font-size-6 u-font-weight-600"
              >Target List ({{ filterTable.length }})</span
            >
            <div
              v-if="filterTable.length > 0"
              v-tippy="tippyLeft"
              class="u-cursor-pointer"
              :title="'Remove all campaigns'"
              @click="removeAllFromShortList()"
            >
              <rb-icon
                class="rb-icon--medium hover-state u-spacing-mh-s u-color-grey-base"
                :icon="'minus'"
              />
            </div>
          </div>
          <div class="u-spacing-ph-l">
            <div class="u-overflow-auto custom--table--body">
              <div
                v-if="filterTable.length === 0"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100"
              >
                <span
                  class="u-font-size-6 u-color-grey-mid-light u-text-align-center u-line-height-1-3"
                  >No {{ mainTableTitle }} selected. <br />Please choose atleast
                  one to proceed forward.</span
                >
              </div>
              <div
                v-for="(row, index) in filterTable"
                :key="`filterTable-${row[primary_key]}`"
                class="u-display-flex u-flex-justify-content-space-between u-spacing-mt-m"
              >
                <span class="u-font-size-6 u-color-grey-light">{{
                  row[display_key_filter]
                }}</span>
                <div @click="removeFromShortList(index)">
                  <rb-icon
                    class="rb-icon--medium hover-state u-spacing-mh-s u-color-grey-light u-cursor-pointer"
                    :icon="'minus'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import { isLineItemRetailer } from '@/components/pages/strategies/create/utils.js';
/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/prop-name-casing */
export default {
  components: {
    loader
  },
  props: {
    display_key_main: {
      type: String
    },
    display_key_filter: {
      type: String
    },
    mainTable: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterTable: {
      type: Array,
      default: () => {
        return [];
      }
    },
    emit: {
      type: String
    },
    primary_key: {
      type: String
    },
    stage: {
      type: Object
    }
  },
  data() {
    return {
      tippy: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      tippyLeft: {
        placement: 'left',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      searchText: '',
      mainTableEmpty: false,
      mainTableCount: 0
    };
  },
  computed: {
    mainTableTitle() {
      // Use the utility method to check if the retailer is a Line Item retailer
      return isLineItemRetailer(this.$store.getters.getRetailer)
        ? 'Line Items'
        : 'Campaigns';
    }
  },
  watch: {
    mainTable(val) {
      this.searchText = '';
      this.mainTableEmpty = false;
      this.setDefaultTable();
      this.addPropetiesToMainTable();
      this.mainTableCount = val.length;
    }
  },
  created() {
    this.addPropetiesToMainTable();
    this.setDefaultTable();
    this.mainTableCount = this.mainTable.length;
    this.tooltipTitle = isLineItemRetailer(this.$store.getters.getRetailer)
      ? 'Add all line Items'
      : 'Add all campaigns';
  },
  methods: {
    addPropetiesToMainTable() {
      for (let i = 0; i < this.mainTable.length; i++) {
        this.mainTable[i].searched = true;
      }
    },
    getSearchResults() {
      let falseCount = 0;
      let searchCount = 0;
      for (let i = 0; i < this.mainTable.length; i++) {
        const element = this.mainTable[i];
        const searchElement = isLineItemRetailer(
          this.$store.getters.getRetailer
        )
          ? element.line_item_name.toUpperCase()
          : element.campaign_name.toUpperCase();
        const searchKey = this.searchText.toUpperCase();
        if (searchElement.indexOf(searchKey) > -1) {
          this.mainTable[i].searched = true;
          searchCount++;
        } else {
          this.mainTable[i].searched = false;
          falseCount++;
        }
      }
      if (falseCount === this.mainTable.length) {
        this.mainTableEmpty = true;
      } else {
        this.mainTableEmpty = false;
      }
      this.mainTableCount = searchCount;
      this.$forceUpdate();
    },
    setDefaultTable() {
      if (this.stage.load) {
        return;
      }
      const mainTable = this.mainTable;
      const filterTable = this.filterTable;
      const removeIndex = [];
      for (let i = 0; i < filterTable.length; i++) {
        const rowIndex = mainTable.findIndex((element) => {
          const filterTableRow = filterTable[i];
          const returnValue =
            element[this.primary_key].toString() ===
            filterTableRow[this.primary_key]?.toString();
          return returnValue;
        });
        if (rowIndex > -1) {
          if (mainTable[rowIndex].allow === false) {
            removeIndex.push(i);
          } else {
            this.mainTable[rowIndex].shortListed = true;
            this.filterTable[i].mainTableIndex = rowIndex;
          }
        }
      }
      const removeLength = removeIndex.length;
      let minusBy = 0;
      for (let i = 0; i < removeLength; i++) {
        this.filterTable.splice(removeIndex[i] - minusBy, 1);
        minusBy++;
      }
      this.shortListUpdated();
    },
    addToShortList(index) {
      this.mainTable[index].mainTableIndex = index;
      this.mainTable[index].shortListed = true;
      const filterTableIndex = this.filterTable.findIndex((element) => {
        return element.mainTableIndex === index;
      });
      if (filterTableIndex > -1) {
        return;
      }
      this.filterTable.push(this.mainTable[index]);
      this.shortListUpdated();
    },
    removeFromShortList(index) {
      // handled empty mainTable edge case
      if (
        this.mainTable?.[this.filterTable[index].mainTableIndex]?.shortListed
      ) {
        this.mainTable[
          this.filterTable[index].mainTableIndex
        ].shortListed = false;
      }
      this.filterTable.splice(index, 1);
      this.shortListUpdated();
    },
    addAllToShortList() {
      for (let i = 0; this.mainTable.length > i; i++) {
        if (
          this.mainTable[i].allow === false ||
          this.mainTable[i].searched === false ||
          this.mainTable[i].shortListed === true
        ) {
          continue;
        }
        this.mainTable[i].shortListed = true;
        this.mainTable[i].mainTableIndex = i;
        this.filterTable.push(this.mainTable[i]);
      }
      this.shortListUpdated();
    },
    removeAllFromShortList() {
      this.filterTable.length = 0;
      for (let i = 0; this.mainTable.length > i; i++) {
        this.mainTable[i].shortListed = false;
      }
      this.shortListUpdated();
    },
    shortListUpdated() {
      this.$forceUpdate();
      if (!this.emit) {
        return;
      }
      this.$emit(this.emit, {
        mainTable: this.mainTable,
        filterTable: this.filterTable
      });
    }
  }
};
</script>

<style scoped lang="css">
.custom--table {
  background-color: #f5f7fa;
  height: 422px;
}
.custom--table--body {
  height: 370px;
  padding-bottom: 2.4rem;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.disable {
  opacity: 0.25;
}
.infoTableMessage {
  margin-top: -13px;
}
.custom-bg {
  background-color: transparent !important;
}
.custom--table--header {
  box-shadow: 0 4px 4px -4px #caccce;
}
.hover-state {
  color: #8b8f93;
}
.hover-state:hover {
  color: #4b5158 !important;
}

.shortlistable_search_input_field {
  height: 27px !important;
  font-size: 11px !important;
  padding-right: 32px !important;
  background: #f7f7f7 !important;
  border: 0 !important;
}
.shortlistable_search_input_field:focus {
  border: none !important;
  background: white !important;
  box-shadow: 0px 0px 4px #caccce !important;
}

.search_input > .search__input {
  width: 280px;
  border-radius: 2px;
}

.search_input .search__input__icon {
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: 8px;
}
</style>
