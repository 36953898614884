<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('low_inventory') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      <span>{{ $t('only_') }}</span
      >{{ formatter(alert.attributes.inventory, 'number')
      }}{{ $t('_units_left_in_stock') }}.
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('appeared_on_')
      }}{{ formatter(alert.attributes.recency_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('category')"
          :item-value="alert.attributes.categoryl3"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('priority')"
          :item-value="formatter(alert.attributes.sku_priority_rank, 'number')"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('offer_price')"
          :item-value="
            formatter(alert.attributes.offer_price_current, 'currency')
          "
        />
      </div>
      <div class="u-spacing-ph-s">
        <key-value-pair
          :item-key="$t('on_paid_placement')"
          :item-value="alert.attributes.is_advertised"
        />
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-l u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.avg_product_rating)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.avg_product_rating || 'NA' }}
          {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.customer_reviews_count || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card chart--card">
      <div class="detail--chart">
        <chart
          v-if="showChart"
          style="height: 270px"
          class="u-spacing-mh-l u-spacing-pv-l"
          :config="barChartConfig"
          :data="barChartData"
        />
      </div>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <p
      class="u-spacing-pt-xl u-spacing-pb-m u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      ACTIVE AD PLACEMENTS
    </p>
    <div class="detail-view--card">
      <el-table :data="JSON.parse(alert.attributes.sku_sponsored_search_array)">
        <el-table-column
          v-for="(column, $index) in Table1Columns"
          :key="$index"
          :label="$t(column.label)"
        >
          <div slot-scope="scope">
            <div>{{ scope.row[column.key] || 'NA' }}</div>
          </div>
        </el-table-column>
      </el-table>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <p
      class="u-spacing-pt-xl u-spacing-pb-m u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      TOP 5 SEARCH KEYWORDS
    </p>
    <div class="detail-view--card">
      <el-table :data="JSON.parse(alert.attributes.top_searc_terms_trend)">
        <el-table-column
          v-for="(column, $index) in Table2Columns"
          :key="$index"
          :label="$t(column.label)"
        >
          <div slot-scope="scope">
            <div>{{ scope.row[column.key] || 'NA' }}</div>
          </div>
        </el-table-column>
      </el-table>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <p
      v-if="reasons"
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      POSSIBLE CAUSES
    </p>
    <ol style="list-style: decimal inside">
      <li
        v-for="(value, $index) of reasons"
        :key="$index"
        class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s"
      >
        {{ value }}
      </li>
    </ol>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max):
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('price_compression_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{ $t('low_inventory_rec_text1') }}
    </p>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{ $t('low_inventory_rec_text2') }}
    </p>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
      {{ $t('low_inventory_rec_text3') }}
    </p>
    <div class="detail-view--card">
      <el-table :data="[{}]">
        <el-table-column
          :label="$t('SKU_details')"
          :width="400"
        >
          <div slot-scope="scope">
            <div>
              <p
                :title="alert.attributes.recommended_sku_title"
                class="u-text-overflow-ellipsis"
              >
                {{ alert.attributes.recommended_sku_title }}
              </p>
              <p>{{ alert.attributes.recommended_client_sku }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('sri')">
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.recommended_sku_sri || 'NA' }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('rating') + ' (' + $t('reviews') + ')'">
          <div slot-scope="scope">
            <div>
              <p>
                {{ alert.attributes.recommended_sku_ratings }} ({{
                  alert.attributes.recommended_sku_reviews
                }})
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('offer_price')">
          <div slot-scope="scope">
            <div>
              <p>
                {{
                  formatter(
                    alert.attributes.recommended_sku_offer_price_current,
                    'currency'
                  )
                }}
              </p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      rollbackArr: [],
      clearanceArr: [],
      paidMediaArr: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            Rollback: 'y2',
            Clearance: 'y2',
            'Paid Media Ads': 'y2',
            'Search Rank Index': 'y2'
          },
          tooltip_format: {
            Rollback: function (value, ratio, id, index) {
              return this.rollbackArr[index] ? 'Yes' : 'No';
            }.bind(this),
            Clearance: function (value, ratio, id, index) {
              return this.clearanceArr[index] ? 'Yes' : 'No';
            }.bind(this),
            'Paid Media Ads': function (value, ratio, id, index) {
              var currObj = this.paidMediaArr[index];
              return (
                'Sponsored on ' +
                currObj.sponsored +
                ' keywords, \n Catapult on ' +
                currObj.catapult +
                ' keywords'
              );
            }.bind(this)
          },
          point_format: {
            Rollback: function (data) {
              if (this.rollbackArr[data.index]) {
                return 5;
              } else {
                return 2;
              }
            }.bind(this),
            Clearance: function (data) {
              if (this.clearanceArr[data.index]) {
                return 5;
              } else {
                return 2;
              }
            }.bind(this),
            'Paid Media Ads': function (data) {
              return 5;
            }
          }
        }
      },
      barChartData: [],
      reasons: null,
      Table1Columns: [
        {
          key: 'search',
          label: 'Keyword'
        },
        {
          key: 'catapult_advt_status',
          label: 'Catapult'
        },
        {
          key: 'sponsored_advt',
          label: 'Sponsored'
        }
      ],
      Table2Columns: [
        {
          key: 'search',
          label: 'Keyword'
        },
        {
          key: 'current_search_rank',
          label: 'Search Rank'
        },
        {
          key: 'avg_search_rank_7_days',
          label: 'Avg. Search Rank (Last Week)'
        },
        {
          key: 'rank1_search.avg_search_rank_30_days',
          label: 'Avg. search rank (A week prior to previous week)'
        }
      ],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    var xs = {};
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var sriTrend = [];
    if (this.alert.attributes.sri_trend) {
      sriTrend = JSON.parse(this.alert.attributes.sri_trend);
      if (sriTrend.length) {
        dataArray = ['Search Rank Index'];
        dateArray = ['Search Rank Index_x'];
        for (entry of sriTrend) {
          if (entry.sri_value === undefined) continue;
          dataArray.push(entry.sri_value);
          dateArray.push(entry.date);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
        this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
          roundoff: 2
        };
      }
    }

    var offerPriceTrend = [];
    if (this.alert.attributes.offer_price_trend) {
      offerPriceTrend = JSON.parse(this.alert.attributes.offer_price_trend);
      if (offerPriceTrend.length) {
        dataArray = ['Price on ' + offerPriceTrend[0].retailer_site];
        dateArray = ['Price on ' + offerPriceTrend[0].retailer_site + '_x'];
        for (entry of offerPriceTrend) {
          if (entry.offer_price === undefined) continue;
          dataArray.push(entry.offer_price);
          dateArray.push(entry.date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
        this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
          roundoff: 2,
          pre: 'currency'
        };
      }
    }

    var rollbackTrend = [];
    if (this.alert.attributes.rollback_trend) {
      rollbackTrend = JSON.parse(this.alert.attributes.rollback_trend);
      if (rollbackTrend.length) {
        rollbackTrend = rollbackTrend.sort((a, b) => {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          return 0;
        });
        dataArray = ['Rollback'];
        dateArray = ['Rollback_x'];
        for (entry of rollbackTrend) {
          if (entry.is_on_rollback === undefined) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.rollbackArr.push(entry.is_on_rollback);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
      }
    }

    var clearanceTrend = [];
    if (this.alert.attributes.clearance_trend) {
      clearanceTrend = JSON.parse(this.alert.attributes.clearance_trend);
      if (clearanceTrend.length) {
        clearanceTrend = clearanceTrend.sort((a, b) => {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          return 0;
        });
        dataArray = ['Clearance'];
        dateArray = ['Clearance_x'];
        for (entry of clearanceTrend) {
          if (entry.is_on_clearance === undefined) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.clearanceArr.push(entry.is_on_clearance);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
      }
    }

    var paidMediaAdsTrend = [];
    if (this.alert.attributes.advt_placement_trend) {
      paidMediaAdsTrend = JSON.parse(
        this.alert.attributes.advt_placement_trend
      );
      if (paidMediaAdsTrend.length) {
        paidMediaAdsTrend = paidMediaAdsTrend.sort((a, b) => {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          return 0;
        });
        dataArray = ['Paid Media Ads'];
        dateArray = ['Paid Media Ads_x'];
        for (entry of paidMediaAdsTrend) {
          if (
            entry.sponsored_placement_count === undefined ||
            entry.catapult_placement_count
          )
            continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.paidMediaArr.push({
            sponsored: entry.sponsored_placement_count || 0,
            catapult: entry.catapult_placement_count || 0
          });
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
      }
    }

    if (this.alert.attributes.reasons && this.alert.attributes.reasons.length) {
      this.reasons = this.alert.attributes.reasons.split(' | ').filter((a) => {
        return a !== '';
      });
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
