import HttpService from '@/utils/services/http-service';

export default {
  all: HttpService.all,
  post: (config) => {
    if (!config.APIData) {
      config.APIData = {};
    }

    if (!config.header) {
      config.header = {};
    }
    var oPromise = HttpService.post(
      config.cube || config.urlId || 'EXECUTE_CUBE_API',
      config.APIData,
      config.header
    )
      .then((response) => {
        var _response = {};
        if (response.data.success) {
          _response.success = true;
          if (
            response.data &&
            response.data.response &&
            response.data.response.data
          ) {
            _response.data = response.data.response.data;
          }
          if (
            response.data &&
            response.data.response &&
            response.data.response.dataSize
          ) {
            _response.dataSize = response.data.response.dataSize;
          }
        } else {
          _response.success = false;
        }
        _response.fullResponse = response.data.response;
        return _response;
      })
      .catch(() => {
        return {
          success: false
        };
      });

    return oPromise;
  }
};
