<template>
  <div>
    <div
      v-for="row in Object.keys(scoreBands)"
      :key="row"
      class="u-spacing-mv-m u-display-flex u-flex-direction-column"
    >
      <div class="u-display-flex">
        <rb-icon
          v-if="scoreBands[row].icon"
          :icon="scoreBands[row].icon"
          class="rb-icon--xx-small u-spacing-mh-xs"
          :class="scoreBands[row].class"
        />
        <label class="u-font-size-6 u-color-grey-lighter u-font-weight-600">{{
          scoreBands[row].title
        }}</label>
      </div>
      <div
        class="input-container u-spacing-mv-s u-width-100 u-display-flex u-flex-justify-content-space-between"
      >
        <input
          v-model="scoreBands[row].minValue"
          type="number"
          :min="0"
          :max="100"
          :class="[
            row === 'bad' ? 'u-color-grey-x-light' : 'u-color-grey-base'
          ]"
          :disabled="row === 'bad'"
          class="u-font-weight-400 u-spacing-p-s u-spacing-pl-m u-width-100 u-border-width-s u-border-color-grey-xxx-light u-border-all"
          @change="(data) => updatePercentageValues(data, row)"
        />
        <span
          class="u-color-grey-base u-spacing-pl-s u-spacing-pt-s u-font-size-3"
          >-</span
        >
        <input
          v-model="scoreBands[row].maxValue"
          type="number"
          :disabled="row === 'good'"
          :min="0"
          :max="100"
          :class="[
            row === 'good' ? 'u-color-grey-x-light' : 'u-color-grey-base'
          ]"
          class="u-spacing-ml-s u-spacing-p-s u-font-weight-400 u-width-100 u-spacing-pl-m u-border-width-s u-border-color-grey-xxx-light u-border-all"
          @change="(data) => updatePercentageValues(data, row)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    rows: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scoreBands: []
    };
  },
  created() {
    this.scoreBands = cloneDeep(this.rows);
  },
  methods: {
    updatePercentageValues(data, key) {
      const bands = this.scoreBands;
      if (bands.good.minValue <= +bands.avg.maxValue) {
        bands.avg.maxValue = +bands.good.minValue - 1;
        bands.avg.minValue =
          +bands.avg.minValue >= bands.good.minValue
            ? bands.good.minValue - 1
            : bands.avg.minValue;
      }
      if (+bands.avg.minValue >= +bands.avg.maxValue) {
        bands.avg.maxValue = +bands.good.minValue - 1;
      }
      if (bands.bad.maxValue >= +bands.avg.minValue) {
        bands.bad.maxValue = bands.avg.minValue - 1;
      }
      if (bands.avg.maxValue <= 0) {
        bands.avg.maxValue = 0;
        bands.avg.minValue = 0;
        bands.bad.maxValue = 0;
      }
      this.scoreBands = bands;
      Object.keys(this.scoreBands).forEach((band) => {
        this.scoreBands[band].minValue =
          this.scoreBands[band].minValue.toString();
        this.scoreBands[band].maxValue =
          this.scoreBands[band].maxValue.toString();
      });
      this.$emit('update', this.scoreBands);
    }
  }
};
</script>

<style></style>
