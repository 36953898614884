import HttpService from '@/utils/services/http-service';
export default class DataService {
  widget;
  page;
  filters;
  pageId;
  globalViewId;
  load;
  error;
  noData;
  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
    this.load = true;
    this.error = false;
    this.noData = false;
    this.metadataLoad = true;
    this.metadataError = false;
  }

  async getMetadata() {
    try {
      this.metadataLoad = true;
      this.metadataError = false;
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadataLoad = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadataLoad = false;
      this.metadataError = true;
    }
  }

  async getData(body, service, apiEndpoint = '/entity/metrics/data') {
    try {
      this.load = true;
      this.error = false;
      this.noData = false;
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post(
        service || 'DASHBOARD_SERVICE',
        body,
        {
          append: apiEndpoint
        }
      );
      if (data.entityData.length === 0) {
        this.noData = true;
      }
      this.load = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.load = false;
      this.error = true;
      this.noData = false;
    }
  }

  async getList(body) {
    body.widget = this.widget;
    body.page = this.page;
    const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/entity/metrics/data/list',
      headers: { retailer: this.retailer }
    });
    return data;
  }

  bodyBuilder(metricsArr) {
    const defaultBody = getDefaultBody();
    for (let ithMetric = 0; ithMetric < metricsArr.length; ithMetric++) {
      defaultBody.metricsList.push(metricsArr[ithMetric].name);
    }
    defaultBody.where = this.filters;
    return defaultBody;
  }

  getDataForMetrics(metrics, widgetInstance) {
    const dataGroups = {};
    const bodies = [];
    for (const key in metrics) {
      if (!dataGroups[metrics[key]?.dataGroup]) {
        dataGroups[metrics[key].dataGroup] = [];
      }
      dataGroups[metrics[key].dataGroup].push(metrics[key]);
    }
    for (const key in dataGroups) {
      bodies.push(this.bodyBuilder(dataGroups[key]));
    }
    return this.getMultiData(bodies, widgetInstance);
  }

  async getMultiData(bodies, widgetInstance) {
    const promiseArray = [];
    for (let i = 0; i < bodies.length; i++) {
      const promise = this.getData(bodies[i]);
      promiseArray.push(promise);
      promise.then((data) => {
        widgetInstance.appendData(data);
      });
    }
    return Promise.all(promiseArray);
  }
}
