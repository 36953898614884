<template>
  <div
    :class="[
      {
        'u-display-inline-flex u-flex-align-items-center':
          alignDirection === 'horizontal',
        'u-display-flex u-flex-direction-column u-flex-justify-content-center':
          alignDirection === 'vertical'
      },
      containerClass
    ]"
    @click="onClick"
  >
    <div>
      <loader
        style="z-index: 100"
        :class="loaderClass"
        :loading="isLoading"
        :color="'#3684bb'"
      />
    </div>
    <rb-tooltip-header
      v-if="tooltipText && !isMetricToolTipEnabled"
      :id="'tooltipTemplate'"
      slot="tooltip-message"
      :title="tooltipTitle"
    >
      <!-- eslint-disable -->
      <div
        slot="tooltip-message"
        class="u-spacing-mt-m tooltip-message"
        v-html="tooltipText"
      />
    </rb-tooltip-header>
    <span
      v-if="tooltipText && isMetricToolTipEnabled && !isLoading"
      v-tippy="tippy"
      class="icon-text u-font-size-3 card__title card__title u-color-grey-lighter u-font-weight-600 u-display-flex u-flex-direction-column"
      :title="tooltipText"
      ><rb-icon
        :icon="icon"
        :class="iconClasses"
      />
      <span
        v-if="text"
        class="u-spacing-ph-s"
        :class="textClasses"
      >
        {{ text }}
      </span>
    </span>
    <div
      v-if="!isLoading && !isMetricToolTipEnabled"
      v-tippy="tooltipText ? setTooltip.tippyOptions : ''"
    >
      <rb-icon
        :icon="icon"
        :class="iconClasses"
      />
      <span
        v-if="text"
        class="u-spacing-ph-s"
        :class="textClasses"
      >
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader';

export default {
  components: {
    Loader
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    tooltipTitle: {
      type: String,
      default: null
    },
    tooltipText: {
      type: String,
      default: null
    },
    containerClass: {
      type: String,
      default: ''
    },
    iconClasses: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: null
    },
    textClasses: {
      type: String,
      default: 'u-spacing-ph-s u-font-size-6 u-color-blue-base'
    },
    alignDirection: {
      type: String,
      // or vertical
      default: 'horizontal'
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loaderClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      tippy: {
        placement: 'top',
        arrow: true,
        distance: 8,
        boundary: 'viewport'
      }
    };
  },
  computed: {
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom-start',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    }
  },
  methods: {
    onClick(event) {
      this.$emit('onClick', event);
    }
    // setTooltip (id) {
    //   return {
    //     id: id,
    //     tippyOptions: {
    //       html: '#' + id,
    //       theme: 'rb-tooltip-header',
    //       placement: 'bottom',
    //       interactive: true,
    //       reactive: true,
    //       arrow: false
    //     }
    //   };
    // }
  }
};
</script>

<style lang="css" scoped>
.icon-text:hover {
  color: #2b333b;
}
.icon-text:hover .rb-icon:before {
  color: #2b333b;
}
</style>
