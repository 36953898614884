








































































// @ts-ignore
import chart from '@/components/basic/chart';
import digitalShelfDropdownRow from '../atoms/digital-shelf-dropdown-row.vue';
import PieChartWidget from '@/components/pages/insights/amazon/market-share/molecules/pie-chart-widget.vue';
import Vue, { PropType } from 'vue';
import { computedStyles } from '@/components/pages/insights/amazon/market-share/utils';

export default Vue.extend({
  components: {
    chart,
    digitalShelfDropdownRow,
    PieChartWidget
  },
  props: {
    customTitleTooltip: {
      type: String,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: Function,
      required: false
    },
    isTitleClickable: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: Number,
      default: 4
    },
    pvpFontSize: {
      type: Number,
      default: 5
    },
    chartData: {
      type: Array as PropType<unknown>,
      default: () => []
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'm'
    },
    icon: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    popupTitle: {
      type: String,
      default: null
    },
    popupMetadata: {
      type: Object,
      default: () => ({})
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedChartData: []
    };
  },
  computed: {
    computedStyles(): {
      legendsMinHeight?: string;
      legendSize?: string;
      legendDonutSpacing?: string;
      donutDimensions: {
        height?: number;
        width?: number;
      };
    } {
      return computedStyles(this.size);
    }
  },
  methods: {
    handleTitleClick() {
      if (this.isTitleClickable) {
        this.$emit('titleClick', this);
      }
    }
  }
});
