export default [
  {
    title: 'Attribute Standards',
    key: 'attribute',
    tooltipText:
      'Attribute Standards define the minimum value for each parameter which would make up to a good content score. For example: A minimum title length of 40 characters will help you in surfacing all the SKUs whose title length is less than 40 characters',
    rows: [
      {
        type: 'numeric',
        value: ':title_len',
        key: 'title_len',
        required: true,
        title: 'Minimum Title Length (Number of Characters)'
      },
      {
        type: 'numeric',
        value: ':desc_len',
        key: 'desc_len',
        required: true,
        title: 'Minimum Description Length (Number of Words)'
      },
      {
        type: 'numeric',
        value: ':bullet_points',
        key: 'bullet_points',
        required: true,
        title: 'Minimum Number of Bullet Points'
      },
      {
        type: 'numeric',
        value: ':reviews',
        key: 'reviews',
        required: true,
        title: 'Minimum Number of Reviews'
      },

      {
        type: 'numeric',
        value: ':images',
        key: 'images',
        required: true,
        title: 'Minimum Number of Images'
      },
      {
        type: 'numeric',
        value: ':avg_rating',
        key: 'avg_rating',
        required: true,
        title: 'Minimum Average Rating'
      }
      // {
      //   type: 'switch',
      //   value: ':title_contains_brand',
      //   key: 'title_contains_brand',
      //   title: 'Title Contains Brand'
      // },
      // {
      //   type: 'switch',
      //   value: ':desc_contains_product',
      //   key: 'desc_contains_product',
      //   title: 'Description Contains Product Title'
      // },
      // {
      //   type: 'switch',
      //   value: ':product_desc_contains_keyword',
      //   key: 'product_desc_contains_keyword',
      //   title: 'Description Contains Keywords'
      // },
      // {
      //   type: 'switch',
      //   value: ':product_title_contains_keyword',
      //   key: 'product_title_contains_keyword',
      //   title: 'Title Contains Keywords'
      // }
    ]
  },
  {
    title: 'Content Score Bands',
    type: 'score_bands',
    key: 'content_score_bands',
    tooltipText:
      'Content Score is an indicator of the quality of content on the Product display pages. The score is computed based on a predefined set of parameters and thresholds which can be modified in the field available above. Ability to define score bands helps you understand which SKUs falls under the Good, Average and Bad category. This will help in prioritizing the SKUs which needs attention.',
    rows: {
      good: {
        title: 'Good',
        type: 'percentage',
        key: 'good',
        class: 'u-bg-color-green-base',
        icon: 'legends-box',
        minValue: ':good_greater_then',
        maxValue: ':good_less_then',
        cellBgColor: 'rgba(62, 169, 94, 0.1)'
      },
      avg: {
        title: 'Average',
        type: 'percentage',
        key: 'avg',
        icon: 'legends-box',
        class: 'u-bg-color-orange-base',
        minValue: ':avg_greater_then',
        maxValue: ':avg_less_then',
        cellBgColor: 'rgba(255, 168, 0, 0.1)'
      },
      bad: {
        title: 'Bad',
        type: 'percentage',
        key: 'bad',
        icon: 'legends-box',
        class: 'u-bg-color-red-base',
        minValue: ':bad_greater_then',
        maxValue: ':bad_less_then',
        cellBgColor: 'rgba(215, 38, 61, 0.1)'
      }
    }
  }
];
