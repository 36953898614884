import { render, staticRenderFns } from "./organicGrowthOthersDetail.vue?vue&type=template&id=e71b8668&scoped=true&"
import script from "./organicGrowthOthersDetail.vue?vue&type=script&lang=js&"
export * from "./organicGrowthOthersDetail.vue?vue&type=script&lang=js&"
import style0 from "./organicGrowthOthersDetail.vue?vue&type=style&index=0&id=e71b8668&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e71b8668",
  null
  
)

export default component.exports