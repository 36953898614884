import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { formatter } from '@/utils/helpers/formatter.js';
import {
  actionGroupConfig,
  convertToTemplateString
} from './actionGroupConfig';
import { isEqual } from 'lodash';

let requestNumber = 0;
const header = {
  headers: {
    cache: false
  }
};

const initialState = () => {
  return {
    workbenchCampaignsData: {},
    workbenchCampaignsSelectedFilters: {
      date_range: {
        value: 'Last 30 days'
      }
    },
    whereClause: {
      dimensionNameValueList: [],
      date: {}
    },
    oldFilters: '{}',
    activeCardFilter: actionGroupConfig[0].action_group,
    isSidePanelActive: false,
    actionGroupData: {
      rows: actionGroupConfig,
      loading: false,
      error: false
    },
    page_wise_min_max_key: 'currentDate'
  };
};

const state = initialState();

const getters = {
  getWorkbenchActionLogsSelectedFilters: (state) => {
    return state.workbenchCampaignsSelectedFilters;
  },
  getWorkbenchActionLogsData: (state) => {
    return state.workbenchCampaignsData;
  },
  getActiveCardFilter: (state) => {
    return state.activeCardFilter;
  },
  getActionGroupData: (state) => {
    return state.actionGroupData;
  },
  getIsSidePanelActive: (state) => {
    return state.isSidePanelActive;
  }
};

const mutations = {
  SET_NEW_CARD_FILTER: (state, data) => {
    Vue.set(state, 'activeCardFilter', data);
  },
  SET_OLD_FILTERS: (state, data) => {
    Vue.set(state, 'oldFilters', data);
  },
  SET_IS_SIDE_PANEL_ACTIVE: (state, data) => {
    Vue.set(state, 'isSidePanelActive', data);
  },
  ACTION_FILTERS_RESET: (state, data) => {
    Vue.set(state.actionGroupData, 'rows', actionGroupConfig);
    Vue.set(state.actionGroupData, 'loading', true);
    Vue.set(state.actionGroupData, 'error', false);
  },
  ACTION_FILTERS_SUCCESS: (state, data) => {
    Vue.set(state.actionGroupData, 'rows', data);
    Vue.set(state.actionGroupData, 'loading', false);
    Vue.set(state.actionGroupData, 'error', false);
  },
  ACTION_FILTERS_FAILURE: (state, data) => {
    Vue.set(state.actionGroupData, 'rows', actionGroupConfig);
    Vue.set(state.actionGroupData, 'loading', false);
    Vue.set(state.actionGroupData, 'error', true);
  },
  IWKCAMPAIGNS_SUCCESS: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', data.columns);
    }
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', data.rows);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'page', data.page);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }
    if (data.rows && data.rows.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }
  },
  IWKCAMPAIGNS_RESET: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  IWKCAMPAIGNS_ERROR: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  IWKCAMPAIGNS_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.workbenchCampaignsSelectedFilters.date_range;
    Vue.set(state, 'workbenchCampaignsSelectedFilters', data.values);
    if (!state.workbenchCampaignsSelectedFilters.date_range) {
      Vue.set(state.workbenchCampaignsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKCampaigns(data, state);
  },
  ACTION_LOGS_RESET_STORE: (state, data) => {
    Object.assign(state, data);
  }
};

function generateUniqueIds(apiData) {
  const convertedApiData = apiData.map((item) => {
    // item.user_name = Math.random().toString(36).substring(7);
    item.uniqueId = Math.random().toString(36);
    return item;
  });
  return convertedApiData;
}

function getFiltersInFormatWKCampaigns(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  resetActionLogsState(context) {
    const initialStateCopy = initialState();
    context.commit('ACTION_LOGS_RESET_STORE', initialStateCopy);
  },
  showSidePanel: (context, data) => {
    context.commit('SET_IS_SIDE_PANEL_ACTIVE', true);
  },
  hideSidePanel: (context, data) => {
    context.commit('SET_IS_SIDE_PANEL_ACTIVE', false);
  },
  fetchCardDetails: async (context, data) => {
    let updateActiveCard = false;
    try {
      data.body.APIConfigFilterCards.where = transformer.getCompleteWhereClause(
        data.meta.localCardFilters || [],
        context.state.whereClause
      );
      data.body.APIConfigFilterCards.where.date =
        context.state.whereClause.date;
      // data.body.APIConfigFilterCards.where.pvpDate = context.state.whereClause.pvpDate;
      context.commit('ACTION_FILTERS_RESET');
      context.commit('SET_IS_SIDE_PANEL_ACTIVE', false);
      const response = await HttpLayer.post({
        APIData: data.body.APIConfigFilterCards,
        header
      });
      const map = {};
      response.data.forEach((item) => {
        map[item.DIMENSION.action_group] = item.RESULT;
      });
      const currRows = context.state.actionGroupData.rows;
      const length = currRows.length;
      let total = 0;
      for (let i = 0; i < length; i++) {
        if (map[currRows[i].action_group]) {
          currRows[i].action_count = map[currRows[i].action_group].action_count;
          total += map[currRows[i].action_group].action_count;
        } else {
          currRows[i].action_count = 0;
        }
        if (
          currRows[i].action_group === context.state.activeCardFilter &&
          currRows[i].action_count === 0 &&
          i !== 0
        ) {
          updateActiveCard = true;
        }
      }
      currRows[0].action_count = total;
      context.commit('ACTION_FILTERS_SUCCESS', currRows);
      if (updateActiveCard) {
        context.commit(
          'SET_NEW_CARD_FILTER',
          actionGroupConfig[0].action_group
        );
        context.dispatch('getWorkbenchActionLogTableData', data);
      }
    } catch (e) {
      context.commit('SET_NEW_CARD_FILTER', actionGroupConfig[0].action_group);
      if (!updateActiveCard) {
        context.commit('ACTION_FILTERS_FAILURE', e);
      }
    }
  },
  updateCardFilter: (context, data) => {
    context.commit('SET_NEW_CARD_FILTER', data);
  },
  downloadActionLogsTableData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.whereClause);
    // data.body.APIConfig.where.date = context.state.whereClause.date;
    // data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;

    // delete data.body.APIConfig.customOrderAndSort;
    return HttpLayer.post({
      APIData: data,
      header
    }).then((response) => {
      let _mergeObj =
        transformer.mergeResultDimension(response.data, true) || [];
      _mergeObj = _mergeObj.map((element) => {
        element.run_date = formatter(element.run_date, 'date');
        return element;
      });
      return _mergeObj;
    });
  },
  setWorkbenchActionLogsSelectedFilters: (context, data) => {
    context.commit('IWKCAMPAIGNS_SET_SELECTED_FILTER', data);
  },
  getWorkbenchActionLogTableData: async (context, data) => {
    try {
      requestNumber++;
      const currentId = requestNumber;
      context.commit('IWKCAMPAIGNS_RESET', {
        key: data.meta.key
      });
      context.commit('SET_IS_SIDE_PANEL_ACTIVE', false);
      const oldFilters = JSON.parse(context.state.oldFilters);
      data.body.APIConfig.where = transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
      data.body.APIConfig.where.date = context.state.whereClause.date;
      data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
      const newFilters = JSON.stringify(data.body.APIConfig.where || {});
      const isOldFilterEqualToNewFilter = isEqual(
        oldFilters,
        JSON.parse(newFilters)
      );
      context.commit('SET_OLD_FILTERS', newFilters);
      if (!isOldFilterEqualToNewFilter) {
        context.dispatch('fetchCardDetails', data);
      }
      if (
        context.state.activeCardFilter !== actionGroupConfig[0].action_group
      ) {
        if (!data.body.APIConfig.where.dimensionNameValueList) {
          data.body.APIConfig.where.dimensionNameValueList = [];
        }
        const existingFilterIndex =
          data.body.APIConfig.where.dimensionNameValueList.findIndex(
            (item) => item.dimensionName === 'action_group'
          );
        if (existingFilterIndex >= 0) {
          data.body.APIConfig.where.dimensionNameValueList[
            existingFilterIndex
          ].dimensionValue = context.state.activeCardFilter;
        } else {
          data.body.APIConfig.where.dimensionNameValueList.push({
            dimensionName: 'action_group',
            dimensionValue: context.state.activeCardFilter
          });
        }
      }
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig,
        header
      });
      if (currentId === requestNumber) {
        if (!response.success) {
          context.commit('IWKCAMPAIGNS_ERROR', {
            key: data.meta.key
          });
        } else {
          var _aArray = [];
          _aArray = transformer.mergeResultDimension(response.data, true);
          _aArray = convertToTemplateString(_aArray);
          _aArray = generateUniqueIds(_aArray);
          // hardcoded data
          // _aArray = transformer.mergeResultDimension(tableData.response.data);
          if (_aArray.length > 0) {
            context.commit('IWKCAMPAIGNS_SUCCESS', {
              key: data.meta.key,
              rows: _aArray,
              totalRows: _aArray[0].auto_cubesdk_count || _aArray[0].count,
              page: data.body.APIConfig.page
            });
          } else {
            context.commit('IWKCAMPAIGNS_SUCCESS', {
              key: data.meta.key,
              rows: [],
              page: data.body.APIConfig.page
            });
          }
        }
      }
    } catch (e) {
      context.commit('IWKCAMPAIGNS_ERROR', {
        key: data.meta.key
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
