export const getParsedList = (str) => {
  let tempList = parsePastedText(str);
  return constructEntityObj(tempList);
};

const parsePastedText = (inputStr) => {
  // split pasted text on new line and remove empty strings
  let currEntityList = inputStr.split(/\r?\n/);
  currEntityList = currEntityList.filter((item) => item !== '');
  currEntityList = currEntityList.map((item) => item?.trim());
  return [...currEntityList];
};

const constructEntityObj = (arr) => {
  // maintain an entity set to track duplicates
  let index = 0;
  let entitySet = new Set();
  arr = arr.map((str) => {
    const constructedEntityObj = {
      id: index,
      entityValue: str,
      isDuplicate: entitySet.has(str.toLowerCase())
    };
    index = index + 1;
    entitySet.add(str.toLowerCase());
    return constructedEntityObj;
  });
  return arr;
};

export const getAllowedMaxEntityListArr = (arr, limit) => {
  // get the max allowed enitities from the total valid entities. Takes first N entities
  return arr?.slice(0, limit);
};
