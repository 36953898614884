<template>
  <div class="u-display-flex u-spacing-mr-m">
    <div class="u-spacing-mt-l u-width-35">
      <span class="u-font-size-5 u-font-weight-600 u-color-grey-light">{{
        step.tableHeader
      }}</span>
      <div class="u-spacing-mt-m u-font-size-5">
        <loader
          v-show="loading"
          slot="loader"
          class="fill--parent"
          :color="'#3fadf7'"
          :size="'4rem'"
          :thickness="'.2rem'"
          style="z-index: 300"
        />
        <table class="bids_group_table">
          <thead>
            <tr class="u-font-weight-600 u-color-grey-lighter">
              <th class="u-width-30">
                <div
                  data-cy="taregtingGroupText"
                  :class="headerContainerClass"
                >
                  Targeting Group
                </div>
              </th>
              <th
                v-if="!hideSuggestedBidColumn"
                class="u-width-30"
              >
                <div
                  data-cy="suggestedBidText"
                  :class="headerContainerClass"
                >
                  Suggested Bid
                </div>
              </th>
              <th class="u-width-30">
                <div
                  data-cy="bidText"
                  :class="headerContainerClass"
                >
                  Bid
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="u-color-grey-light">
            <tr
              v-for="(item, index) in selectedBids"
              :key="index"
            >
              <td>
                <div
                  class="u-color-grey-light"
                  :class="containerClass"
                >
                  <rb-checkbox
                    v-model="item.checked"
                    @input="checkboxSelection()"
                  >
                    {{ item.name }}
                  </rb-checkbox>
                </div>
              </td>
              <td v-if="!hideSuggestedBidColumn">
                <div
                  class="u-display-flex u-flex-justify-content-center"
                  :class="containerClass"
                >
                  <div
                    v-if="item.suggestedBids && item.suggestedBids.length"
                    class="u-font-size-6 u-color-grey-lighter"
                  >
                    <div class="u-spacing-pv-xs u-color-blue-base">
                      {{ item.prefix }}{{ item.suggestedBids[1] }}
                    </div>
                    <div class="u-spacing-pv-xs">
                      {{ item.prefix }}{{ item.suggestedBids[0] }} -
                      {{ item.prefix }}{{ item.suggestedBids[2] }}
                    </div>
                  </div>
                  <span
                    v-else
                    class="u-spacing-pv-s"
                    >-
                  </span>
                </div>
              </td>
              <td>
                <div
                  :class="containerClass"
                  class="u-display-flex u-flex-justify-content-center"
                >
                  <rbNumberInput
                    :key="index"
                    :custom-class="'u-width-70'"
                    :options="item"
                    @handleInput="setBidGroup"
                    @handleValue="setBidGroup"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import rbNumberInput from '@/components/widgets/rbNumberInput';
import loader from '@/components/basic/loader.vue';
import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';

export default {
  components: {
    rbNumberInput,
    loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    containerClass: {
      type: String,
      default: 'u-spacing-p-s'
    },
    headerContainerClass: {
      type: String,
      default: 'u-spacing-p-m'
    }
  },
  data() {
    return {
      selectValue: { title: 'Daily', key: 'daily' },
      suggestedCampaignIndex: 0,
      selectedBids: this.step.rows,
      loading: true,
      errorMessage: '',
      hideSuggestedBidColumn: true,
      autoTargetingGroupsMap: {
        close_match: 0,
        loose_match: 1,
        substitutes: 2,
        complements: 3
      }
    };
  },
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  watch: {},
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const suggestedCampaign =
      this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
    this.selectedBids = suggestedCampaign?.targets?.length
      ? suggestedCampaign?.targets
      : this.step.rows;
    this.getSuggestedBids();
    this.checkboxSelection();
    this.checkValidations();
  },
  methods: {
    setBidGroup(val) {
      this.errorMessage = this.step.handleValidation(val);
      this.updateAllStepsData();
    },
    stepDesc() {
      const suggestedCampaign =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      this.step.description = this.step.getDescription(suggestedCampaign);
    },
    checkboxSelection() {
      this.updateAllStepsData();
      this.checkValidations();
    },
    updateAllStepsData() {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].targets =
        this.selectedBids;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].message =
        this.errorMessage;
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
      this.stepDesc();
    },
    async getSuggestedBids() {
      let payload = cloneDeep(this.step.bidApiConfigs);
      const asins =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].skus;
      const allAsins = asins.map((item) => {
        return item.asin;
      });
      const reqValues = {
        ':profileId':
          this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
            ?.profile?.profile_id,
        ':asins': allAsins
      };
      let res = null;
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }

      // this api to be tested with pointing to prod
      return new Promise(async (resolve, reject) => {
        return await HttpService.post(
          'GET_SUGGESTED_BIDS_AUTO_CAMPAIGN',
          payload
        )
          .then((res) => {
            const failedStatuses = [
              'FAILED',
              'FAILURE',
              404,
              '404',
              503,
              '503'
            ];
            this.hideSuggestedBidColumn = false;
            if (
              !res ||
              !res?.data ||
              failedStatuses.indexOf(res?.data?.status) >= 0 ||
              !res?.data?.bidRecommendations
            ) {
              this.hideSuggestedBidColumn = true;
              this.updateSuggestedBid();
            } else {
              this.updateSuggestedBid(res);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    async updateSuggestedBid(res = null) {
      if (!res) {
        // mocked suggested bids
        res = this.step.res;
      } else {
        res = res.data;
      }
      if (this.hideSuggestedBidColumn) {
        return;
      }
      const response =
        res?.bidRecommendations[0]?.bidRecommendationsForTargetingExpressions ||
        [];
      response.map((item) => {
        let expression = item?.targetingExpression?.type?.toLowerCase();
        const index = this.autoTargetingGroupsMap[expression];
        let bidvalues = item?.bidValues.map((bidObj) => {
          return bidObj?.suggestedBid;
        });
        this.selectedBids[index].suggestedBids = bidvalues;
      });
      this.selectedBids.map((item, index) => {
        if (item.value === 0) {
          this.selectedBids[index].value = item.suggestedBids[1] || 1;
        }
      });
    },
    checkValidations() {
      const campaignToEdit =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      let checkedItems = campaignToEdit?.targets.filter((item) => {
        return item.checked;
      });
      if (checkedItems?.length) {
        checkedItems.map((item) => {
          const message = this.step.handleValidation(item.value);
          if (message.length) {
            this.errorMessage = message;
            this.updateAllStepsData();
          }
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.bids_group_table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e9eaeb;
}
</style>
