import { formatter } from '@/utils/helpers/formatter.js';
import utils from '@/utils/helpers/index.js';
import filterUtils from '@/utils/helpers/filter.js';
import { operator as operators } from '@/utils/helpers/operator.js';
import { cloneDeep, set, get, omit } from 'lodash';

const convertBetweenOperatorToGreaterThanLessThan = (
  joinedValue,
  dimensionName
) => {
  const splitValue = joinedValue.split(', ');
  const obj1 = {
    operator: 'GREATER_THAN_OR_EQUAL_TO',
    dimensionName: dimensionName,
    dimensionValue: Number(splitValue[0])
  };
  const obj2 = {
    operator: 'LESS_THAN_OR_EQUAL_TO',
    dimensionName: dimensionName,
    dimensionValue: Number(splitValue[1])
  };
  return {
    obj1,
    obj2
  };
};

export default {
  getUniqueFilters: (data, prefix, filterMappings) => {
    if (!prefix) {
      prefix = '';
    }

    if (!filterMappings) {
      filterMappings = JSON.parse(
        localStorage.getItem(prefix.replace('_', '') + '_filters_mapping')
      );
    }

    var categoryText = 'category';
    var subCategoryText = 'subcategory';
    if (data.length > 0 && data[0].DIMENSION.category === undefined) {
      // categoryText = 'l1';
    }
    if (
      data.length > 0 &&
      data[0].DIMENSION.category === undefined &&
      data[0].DIMENSION.l1 === undefined
    ) {
      categoryText = 'client_category';
    }

    if (data.length > 0 && data[0].DIMENSION.subcategory === undefined) {
      // subCategoryText = 'l2';
    }
    if (
      data.length > 0 &&
      data[0].DIMENSION.subcategory === undefined &&
      data[0].DIMENSION.l2 === undefined
    ) {
      subCategoryText = 'client_subcategory';
    }

    var category = {};
    var subcategory = {};
    var oObj = {};
    var _oReturn = {};
    for (var i = 0; i < data.length; i++) {
      if (
        data[i].DIMENSION[categoryText] !== undefined &&
        data[i].DIMENSION[subCategoryText] !== undefined
      ) {
        if (!category[data[i].DIMENSION[categoryText]]) {
          category[data[i].DIMENSION[categoryText]] = {
            title: data[i].DIMENSION[categoryText],
            values: []
          };
          subcategory[data[i].DIMENSION[categoryText]] = {};
        }
        if (
          !subcategory[data[i].DIMENSION[categoryText]][
            data[i].DIMENSION[subCategoryText]
          ]
        ) {
          subcategory[data[i].DIMENSION[categoryText]][
            data[i].DIMENSION[subCategoryText]
          ] = true;
          if (data[i].DIMENSION[subCategoryText]) {
            category[data[i].DIMENSION[categoryText]].values.push({
              title: data[i].DIMENSION[subCategoryText]
            });
          }
        }
      }

      for (var j in data[i].DIMENSION) {
        if (!oObj[prefix + j]) {
          oObj[prefix + j] = {};
        }

        var found = true;

        if (filterMappings && filterMappings[prefix + j]) {
          for (var y in filterMappings[prefix + j]) {
            if (
              filterMappings[prefix + j][y] &&
              filterMappings[prefix + j][y].length === 0
            ) {
              found = true;
              break;
            }
            if (
              filterMappings[prefix + j][y].indexOf(
                data[i].DIMENSION[y.replace(prefix, '')]
              ) === -1
            ) {
              found = false;
              break;
            }
          }
        }

        if (found) {
          oObj[prefix + j][data[i].DIMENSION[j]] = true;
        } else {
          if (oObj[prefix + j][data[i].DIMENSION[j]] !== true) {
            delete oObj[prefix + j][data[i].DIMENSION[j]];
          }
        }
      }
    }

    for (var k in oObj) {
      if (!_oReturn[k]) {
        _oReturn[k] = [];
      }
      for (var l in oObj[k]) {
        if (l && l !== 'null' && l.length > 0) {
          _oReturn[k].push({
            title: l
          });
        }
      }
    }

    if (_oReturn[prefix + categoryText]) {
      var oReturnCategory = [];
      for (var t in category) {
        if (oObj[prefix + categoryText][t]) {
          if (category[t].values && category[t].values.length > 0) {
            for (var r = 0; r < category[t].values.length; r++) {
              if (
                !oObj[prefix + subCategoryText][category[t].values[r].title] ||
                t.length === 0
              ) {
                category[t].values.splice(r, 1);
              }
            }
          }
          if (t !== 'null' && t.length > 0) {
            oReturnCategory.push(category[t]);
          }
        }
      }
      _oReturn[prefix + categoryText] = oReturnCategory;
      delete _oReturn[prefix + subCategoryText];
    }
    return _oReturn;
  },
  getChartDataInFormat: (data, response) => {
    var _aArray = [];
    var _oObj = {};
    if (response === null) {
      return [];
    }
    for (var i = 0; i < response.length; i++) {
      for (var j in response[i]) {
        var _j = ((data || {}).map || {})[j] || j;
        if (!_oObj[_j]) {
          _oObj[_j] = [_j];
        }
        _oObj[_j].push(response[i][j]);
      }
    }

    for (var k in _oObj) {
      _aArray.push(_oObj[k]);
    }

    return _aArray;
  },
  getChartRollUpMapping: (data, keyValue) => {
    if (data === null) {
      return [];
    }

    return data.map((value) => {
      if (!value.report_date) {
        value.report_date = value[keyValue];
        delete value[keyValue];
      }
      return value;
    });
  },
  getChartTicksValues: (data, key) => {
    var newArr = [];
    if (data && data.length) {
      for (var i = 0; i < data.length; i++) {
        newArr.push(data[i][key]);
      }
    }
    return newArr;
  },
  getFilterPanelData: (data) => {
    const esDataSetName = data.esDataSetName;
    data = data.filterMetadata;
    var labels = {};
    var panelData = [];
    for (var i = 0; i < data.length; i++) {
      labels[data[i].dimensionType] = data[i].filterLabel;
      if (data[i].dimensionType === 'TAG_KEYWORD') {
        continue;
      }
      // handleFilterDimensionType copies DimensionType from parent level to dropdown item level
      const categoriesToHandleDimensionType = ['SMART_FILTERS'];
      if (categoriesToHandleDimensionType.includes(data[i]?.dimensionType)) {
        data[i] = filterUtils?.handleFilterDimensionType(data[i]);
      }
      var _data = [];
      if (data[i].clickOnlyMetadata) {
        _data = data[i].clickOnlyMetadata;
        _data.tagType = data[i].tagType;
        _data.esDataSetName = data[i].esDataSetName || esDataSetName; // each filter group to have its own esDataSetName
        _data.enableClickOnly = data[i].enableClickOnly;
        _data.dimensionName =
          _data.entityType && _data.tagType
            ? _data.entityType +
              '__' +
              _data.tagType +
              '__' +
              _data.dimensionName
            : _data.dimensionName;
        panelData.push(data[i].clickOnlyMetadata);
      } else if (data[i].dropDownOptions) {
        _data = data[i].dropDownOptions;
        _data.map((item) => {
          item.tagType = data[i].tagType;
          item.esDataSetName = data[i].esDataSetName || esDataSetName; // each filter group to have its own esDataSetName
          item.enableClickOnly = data[i].enableClickOnly;
          item.dimensionName =
            item.entityType && item.tagType
              ? item.entityType +
                '__' +
                item.tagType +
                '__' +
                item.dimensionName
              : item.dimensionName;
          return item;
        });
        panelData = panelData.concat(_data);
      }
    }
    return {
      panelData,
      labels
    };
  },
  mergeResultDimension: (data, addPVP, removeNullProp) => {
    var _aArray = [];
    if (data && data.length) {
      for (var i = 0; i < data.length; i++) {
        var oObj = {};
        for (var j in data[i].RESULT) {
          oObj[j] = data[i].RESULT[j];
        }

        for (j in data[i].DIMENSION) {
          oObj[j] = data[i].DIMENSION[j];
        }

        if (addPVP) {
          for (j in data[i].PVP) {
            oObj['PVP_' + j] = data[i].PVP[j];
          }
        }
        if (removeNullProp) {
          if (oObj[removeNullProp] !== undefined) {
            if (oObj[removeNullProp] !== null && oObj[removeNullProp] !== '') {
              _aArray.push(oObj);
            }
          } else {
            _aArray.push(oObj);
          }
        } else {
          _aArray.push(oObj);
        }
      }
    }
    return _aArray;
  },
  getConversionToBetweenFilter(val) {
    if (!val[0].operator) {
      return val;
    }
    const a =
      val[0].operator.operator === 'GREATER_THAN_OR_EQUAL_TO' &&
      val[1].operator.operator === 'LESS_THAN_OR_EQUAL_TO';
    const b =
      val[0].operator.operator === 'LESS_THAN_OR_EQUAL_TO' &&
      val[1].operator.operator === 'GREATER_THAN_OR_EQUAL_TO';
    let value1 = '';
    let value2 = '';
    if (a) {
      value1 = val[0].value;
      value2 = val[1].value;
    } else {
      value1 = val[1].value;
      value2 = val[0].value;
    }
    if (a || b) {
      return {
        unit: val[0].unit,
        value1,
        value2,
        value: value1.toString() + ', ' + value2.toString(),
        operator: {
          operator: 'BETWEEN',
          title: '> & <'
        }
      };
    }
    return val;
  },
  /**
   *
   * @param {string} dimensionName
   * @param {*} dimensionValue
   * @returns {Object} an object with an optional esDataSetName key
   */
  addEsDataSetName(dimensionName, dimensionValue) {
    const filter = {};
    filter.dimensionName = dimensionName?.split('---')?.[0] || dimensionName;
    if (dimensionValue?.includes('---')) {
      const [value, esDataSetName] = dimensionValue.split('---');
      dimensionValue = value;
      if (esDataSetName) {
        filter.esDataSetName = esDataSetName;
      }
    }
    filter.dimensionValue = dimensionValue;
    return filter;
  },
  createWhereClause({ item, where }) {
    // convert 'BETWEEN' operator to 'GREATER_THAN_OR_EQUAL' and 'LESS_THAN_OR_EQUAL' operators
    if (item.operator && item.operator === 'BETWEEN') {
      const { dimensionValue, dimensionName } = item;
      const { obj1, obj2 } = convertBetweenOperatorToGreaterThanLessThan(
        dimensionValue,
        dimensionName
      );
      where.dimensionNameValueList.push(obj1, obj2);
    } else {
      // creates the final filter object with esDataSetName in case of V2 filters
      const filter = {
        ...item,
        ...this.addEsDataSetName(item.dimensionName, item.dimensionValue)
      };

      where.dimensionNameValueList.push(filter);
    }
  },
  getCompleteWhereClause(where, stateSelectedFilters) {
    let oReturn = {
      dimensionNameValueList: []
    };
    if (where) {
      for (const element of where) {
        oReturn.dimensionNameValueList.push(element);
      }
    }

    if (stateSelectedFilters?.dimensionNameValueList) {
      for (const element of stateSelectedFilters.dimensionNameValueList) {
        if (oReturn.dimensionNameValueList.indexOf(element) === -1) {
          const item = element;
          this.createWhereClause({ item, where: oReturn });
        }
      }
    }
    return oReturn;
  },
  transformWhereStructure(whereArray) {
    const filters = {
      searchFilters: [],
      addFilters: [],
      mappedFilters: [],
      bulkSearchFilters: []
    };

    whereArray.forEach(({ dimensionNameValueList }) => {
      dimensionNameValueList.forEach((filter) => {
        const { dimensionName, dimensionValue, ...rest } = filter;
        const keyCount = Object.keys(rest).length + 2; // +2 for dimensionName and dimensionValue
        if (keyCount === 2) {
          if (filter.dimensionName === 'search') {
            filters.searchFilters.push(filter);
          } else {
            filters.bulkSearchFilters.push(filter);
          }
        } else if (keyCount === 3) {
          filters.addFilters.push(filter);
        } else if (keyCount === 4) {
          filters.mappedFilters.push(filter);
        }
      });
    });

    const transformedWhere = [];

    if (filters.searchFilters.length) {
      transformedWhere.push({
        dimensionNameValueList: filters.searchFilters
      });
    }

    if (filters.mappedFilters.length) {
      filters.mappedFilters.forEach((filter) => {
        transformedWhere.push({
          dimensionNameValueList: [
            ...filters.addFilters,
            ...filters.bulkSearchFilters,
            filter
          ]
        });
      });
    } else if (filters.addFilters.length || filters.bulkSearchFilters.length) {
      transformedWhere.push({
        dimensionNameValueList: [
          ...filters.addFilters,
          ...filters.bulkSearchFilters
        ]
      });
    }
    return { where: transformedWhere };
  },
  convertBetweenOperatorToGreaterThanLessThan,
  transformWhereClauseWithTag(filterWhereClause, pageType = null) {
    const whereClause = cloneDeep(filterWhereClause);
    const transformeredWhereClause = cloneDeep(filterWhereClause);
    // For recommendation page where the request format is different
    if (pageType === 'recommendation') {
      const tagWhereClause = [];
      const updatedFilters = [];

      // Remove the filters which are not of custom catalog, and add them to normal filters array
      transformeredWhereClause.map((elm) => {
        const obj = {};
        for (const [key] of Object.entries(elm)) {
          if (key.indexOf('__') === -1) {
            obj[key] = elm[key];
            delete elm[key];
          }
        }
        updatedFilters.push(obj);
      });
      // Create the tag where clause object in desired way
      const key = Object.keys(transformeredWhereClause[0]);
      if (key.length > 0) {
        transformeredWhereClause.map((item) => {
          const keyName = Object.keys(item)[0];

          const name = keyName.split('__');

          item[keyName]?.map((i) => {
            // Assign the types
            const clauseObj = {
              entityType: name[0],
              tagType: name[1],
              where: {
                dimensionNameValueList: [
                  {
                    dimensionName: name[2],
                    dimensionValue: i,
                    operator: 'EQUAL_TO'
                  }
                ]
              }
            };
            tagWhereClause.push(clauseObj);
          });
        });
      }
      return { tagWhereClause, updatedFilters };
    }
    if (Object.keys(whereClause).length > 0) {
      // non tag where clause
      transformeredWhereClause.dimensionNameValueList =
        whereClause.dimensionNameValueList.filter((item) => {
          if (item.dimensionName.indexOf('__') === -1) {
            return item;
          }
        });

      // tag where clause logic
      const tagDimensionValueMap = {};
      whereClause.dimensionNameValueList.forEach((item) => {
        if (item.dimensionName.indexOf('__') !== -1) {
          if (!tagDimensionValueMap[item.dimensionName]) {
            tagDimensionValueMap[item.dimensionName] = [item.dimensionValue];
          } else {
            tagDimensionValueMap[item.dimensionName].push(item.dimensionValue);
          }
        }
      });
      transformeredWhereClause.tagWhereClause = [];
      for (const dimensionName in tagDimensionValueMap) {
        const name = dimensionName.split('__');
        const tagObject = {
          entityType: name[0],
          tagType: name[1],
          where: {
            dimensionNameValueList: []
          }
        };
        for (const dimensionValue of tagDimensionValueMap[dimensionName]) {
          tagObject.where.dimensionNameValueList.push({
            dimensionName: name[2],
            dimensionValue: dimensionValue,
            operator: 'EQUAL_TO'
          });
        }
        transformeredWhereClause.tagWhereClause.push(tagObject);
      }
    }
    return transformeredWhereClause;
  },
  getCellRenderParams(data) {
    var type = data.uiField.uiType.toLowerCase();
    var objToReturn = {
      formatterFn: formatter
    };
    switch (type) {
      case 'percentage':
      case 'percent':
        objToReturn.keyType = 'PERCENTAGE';
        break;
      case 'currency':
        objToReturn.keyType = 'CURRENCY';
        break;
      case 'number':
        objToReturn.keyType = 'number';
        break;
      case 'numeric':
        // If isFormattingRequired is not passed by the backend we assumne isFormattingRequired to be true
        const formattingRequired =
          data.uiField.metadata.isFormattingRequired === undefined
            ? true
            : data.uiField.metadata.isFormattingRequired;
        // If formatting is not required we are removing the formatterFn Field from the object
        if (formattingRequired === false) {
          delete objToReturn.formatterFn;
        }
        objToReturn.keyType = 'numeric';
        break;
      case 'string':
        objToReturn = {
          keyType: 'string'
        };
        break;
      default:
    }
    return objToReturn;
  },
  getCustomCellRender(data) {
    var colMetaData = data.uiField.metadata;
    var type = colMetaData.widget.toLowerCase();
    var objToReturn = {};
    switch (type) {
      case 'tag':
        objToReturn.cellRendererFramework = 'tagsCell';
        objToReturn.minWidth = 350;
        // objToReturn.maxWidth = 354;
        objToReturn.cellRendererParams = {
          hasDropdown: colMetaData.hasDropdown,
          dropdownOptions: colMetaData.dropdownOptions,
          toolTipText: colMetaData.toolTipText,
          isCrossShown: colMetaData.isCrossShown,
          hasHoverEffect: colMetaData.hasHoverEffect,
          dropdownOnClose: colMetaData.dropdownOnClose,
          dropdownIsMultiSelect: colMetaData.isMultiSelect
        };
        break;
      case 'hyperlink':
        objToReturn.cellRendererFramework = 'linkDisplay';
        objToReturn.cellRendererParams = {
          url: colMetaData.urlTableColumnName
        };
        break;
      case 'progress':
        objToReturn.cellRendererFramework = 'progressDisplay';
        objToReturn.cellRendererParams = {
          fill: colMetaData.percentTableColumnName,
          decimalRoundOff: colMetaData.decimalRoundOff
        };
        break;
      case 'metric':
        objToReturn.cellRendererFramework = 'metricDisplay';
        objToReturn.minWidth = 150;
        objToReturn.cellRendererParams = {
          tag1Key: colMetaData.primaryTableColumnName,
          tag2Key: colMetaData.secondaryTableColumnName,
          tag1Unit: this.getTagUnitData(
            colMetaData.primaryIsPrefix,
            colMetaData.primaryUnit
          ),
          tag2Unit: this.getTagUnitData(
            colMetaData.secondaryIsPrefix,
            colMetaData.secondaryUnit
          )
        };
        break;
      case 'icon':
        objToReturn.cellRendererFramework = 'iconTableCell';
        objToReturn.headerComponentFramework = 'iconTableHeader';
        objToReturn.headerComponentParams = {};
        objToReturn.headerComponentParams.displayIcon = colMetaData.displayIcon;
        objToReturn.cellRendererParams = {
          iconSize: !colMetaData.iconSize ? 'medium' : colMetaData.iconSize,
          iconClickEvent: colMetaData.iconClickEvent,
          alternateIcon: colMetaData.alternateIcon,
          alternateIconClass: colMetaData.alternateIconClass,
          displayIcon: colMetaData.displayIcon,
          toolTipText: colMetaData.toolTipText,
          toolTipPosition: colMetaData.toolTipPosition,
          contextReturnEvent: colMetaData.contextReturnEvent
        };
        objToReturn.cellRendererParams.type = !colMetaData.type
          ? 'icon'
          : colMetaData.type;
        if (colMetaData.type === 'iconText') {
          objToReturn.cellRendererParams.formatType = colMetaData.formatType;
        }
        break;

      case 'sku':
        objToReturn.cellRendererFramework = 'skuTableComponentWithButton';
        objToReturn.minWidth = 300;
        objToReturn.cellRendererParams = {
          keys: {
            title: colMetaData.columnTitleKey,
            asin: colMetaData.columnAsinKey,
            imageUrl: colMetaData.columnImageUrlKey,
            productUrl: colMetaData.coloumnProductUrlKey
          },
          asinHoverText: colMetaData?.columnAsinHoverText,
          disableProductLink: colMetaData?.columnDisableProductLink,
          copyClipboardText: colMetaData?.copyClipboardText,
          hideCell: colMetaData?.hideCell,
          showButton: false
        };
        break;
      case 'input':
        objToReturn.cellRendererFramework = 'inputTypeCell';
        objToReturn.cellRendererParams = {
          type: !colMetaData.type ? 'text' : colMetaData.type,
          blurEvent: colMetaData.blurEvent,
          onchangeEvent: colMetaData.onchangeEvent,
          keyupEvent: colMetaData.keyupEvent,
          defaultValueColumnName: colMetaData.defaultValueColumnName,
          formatType: colMetaData.formatType,
          contextReturnEvent: colMetaData.contextReturnEvent
        };
        break;
      case 'columnlistcell':
        objToReturn.cellRendererFramework = 'columnListCell';
        objToReturn.cellRendererParams = {
          subComponent: colMetaData.subComponent,
          type: !colMetaData.type ? 'text' : colMetaData.type,
          blurEvent: colMetaData.blurEvent,
          onchangeEvent: colMetaData.onchangeEvent,
          keyupEvent: colMetaData.keyupEvent,
          defaultValueColumnName: colMetaData.defaultValueColumnName,
          formatType: colMetaData.formatType,
          contextReturnEvent: colMetaData.contextReturnEvent,
          props: colMetaData.props
        };
        break;
      case 'dynamiccellcomponent':
        objToReturn.cellRendererFramework = 'DynamicCellComponent';
        objToReturn.cellRendererParams = {
          paramsToProps: colMetaData.dynamicCellComponentParams.paramsToProps,
          component: colMetaData.dynamicCellComponentParams.componentName,
          eventMap: colMetaData.dynamicCellComponentParams.eventMap
        };
        break;
      default:
    }
    return objToReturn;
  },
  getTagUnitData(isPrefix, unit) {
    if (isPrefix) {
      return {
        pre: unit
      };
    } else {
      return {
        suff: unit
      };
    }
  },
  getColumnDefinition(
    columns,
    customData,
    customObject,
    internalTableLiseners
  ) {
    var colDefinitionToReturn = [];
    var columnArray = columns;
    for (var i = 0; i < columnArray.length; i++) {
      var currDefinition = columnArray[i];
      var obj = {};
      obj.showOnUi = currDefinition.uiField.metadata.showOnUi;
      obj.cellClass = currDefinition.uiField.metadata.cellClass;
      obj.isDownloadable = currDefinition.uiField.metadata.isDownloadable;
      if (
        currDefinition.uiField.metadata.headerComponent?.bDynamicHeaderComponent
      ) {
        obj.headerComponentFramework = 'DynamicHeaderComponent';
        obj.headerComponentParams = {
          columnDefsToProps:
            currDefinition.uiField.metadata.headerComponent.columnDefsToProps,
          props: currDefinition.uiField.metadata.headerComponent.props,
          component:
            currDefinition.uiField.metadata.headerComponent.componentName,
          eventMap: currDefinition.uiField.metadata.headerComponent.eventMap
        };
      } else {
        obj.headerComponentFramework = 'genericTableHeader';
      }
      obj.title = currDefinition.uiField.uiLabel;
      obj.headerName = currDefinition.uiField.uiLabel;
      obj.checkboxSelection = currDefinition.uiField.metadata.checkboxSelection;

      obj.field =
        currDefinition.uiField.metadata.tableColumnName === undefined
          ? currDefinition.name
          : currDefinition.uiField.metadata.tableColumnName;
      obj.cellRendererFramework = 'genericTableCell';
      obj.cellRendererParams = this.getCellRenderParams(currDefinition);
      if (currDefinition.uiField.uiType.toLowerCase() === 'string') {
        if (currDefinition.uiField.metadata.width !== undefined) {
          obj.minWidth = currDefinition.uiField.metadata.width;
          obj.width = currDefinition.uiField.metadata.width;
        } else {
          obj.minWidth = 180;
        }
      } else {
        obj.minWidth = 120;
      }
      obj.minWidth = currDefinition.uiField?.metadata?.minWidth || obj.minWidth;
      obj.width = currDefinition.uiField?.metadata?.width || obj.width;
      // obj.minWidth = currDefinition.uiField.uiType.toLowerCase() === 'string' ? 180 : 120;
      if (currDefinition.uiField.uiType === 'custom') {
        obj.width = currDefinition.uiField.metadata.width;
        obj.minWidth = currDefinition.uiField.metadata.minWidth
          ? currDefinition.uiField.metadata.minWidth
          : currDefinition.uiField.metadata.width;
        if (
          customData !== undefined &&
          typeof customData === 'object' &&
          Object.keys(customData).length > 0 &&
          customData[currDefinition.name]
        ) {
          // console.log('table customData !== undefined && typeof (customData)', customData !== undefined && typeof (customData) === 'object' && Object.keys(customData).length > 0 && customData[currDefinition.name])
          // console.log('table customData', customData)
          obj.cellRendererFramework = customData[currDefinition.name].component;
          obj.cellRendererParams =
            (customData[currDefinition.name] || {}).params || null;
          obj.minWidth = 200;
        } else {
          if (currDefinition.uiField.metadata.widget === 'progress') {
            obj.field = currDefinition.uiField.metadata.percentTableColumnName;
          }
          const cellObj = this.getCustomCellRender(currDefinition);
          obj.cellRendererFramework = cellObj.cellRendererFramework;
          obj.cellRendererParams = cellObj.cellRendererParams;
          if (cellObj.minWidth) {
            obj.minWidth = cellObj.minWidth;
          }
          if (
            customObject !== undefined &&
            customObject.hasOwnProperty(
              currDefinition.uiField.metadata.tableColumnName
            )
          ) {
            var columnToRead = currDefinition.uiField.metadata.tableColumnName;
            for (const k in customObject[columnToRead]) {
              obj.cellRendererParams[k] = customObject[columnToRead][k];
            }
          }
        }
      }

      obj.keyOrder = currDefinition.uiField.uiOrder;
      obj.pinned = currDefinition.uiField.metadata.isFixed;
      obj.suppressSizeToFit = currDefinition.uiField.metadata.suppressSizeToFit;
      obj.getQuickFilterText =
        currDefinition.uiField.metadata.getQuickFilterText;
      colDefinitionToReturn[obj.keyOrder] = obj;
      obj.headerComponentParams = {
        ...obj.headerComponentParams,
        enableHeaderIconInterAction:
          !!currDefinition.uiField.metadata.enableHeaderIconInterAction,
        alignHeader: currDefinition.uiField.metadata.alignHeader,
        enableSorting:
          currDefinition.uiField.metadata.sortOnColumn === undefined
            ? false
            : currDefinition.uiField.metadata.sortOnColumn,
        unselected: !!currDefinition.uiField.metadata.unselected,
        headerIcon: currDefinition.uiField.headerIcon,
        keyType: obj.cellRendererParams.keyType,
        toolTipText: currDefinition.uiField.uiTooltip,
        clickHeader:
          currDefinition.uiField.clickHeader ||
          (internalTableLiseners &&
            internalTableLiseners.defaultHeaderClickHandler),
        customStyles: currDefinition.uiField.customStyles
      };
      if (currDefinition.uiField.metadata.isDefaultSortColumn) {
        obj.headerComponentParams.sort =
          currDefinition.uiField.metadata.sortDirection; // 'asc'
      }
    }

    var displayColConfigs = colDefinitionToReturn.filter((elm) => {
      return elm.showOnUi === true;
    });

    var downloadColConfigs = colDefinitionToReturn.filter((elm) => {
      return elm.isDownloadable === true;
    });
    var objToReturn = {
      displayColConfigs: displayColConfigs,
      downloadColConfigs: downloadColConfigs
    };
    return objToReturn;
  },
  getTableDataFromFullResponse: function (apiResponse) {
    var response = apiResponse.fullResponse;
    var columns = response.metadata;
    var measureList = columns.measureList;
    var groupByDimensionList = columns.groupByDimensionList;
    measureList = measureList.concat(groupByDimensionList);
    var rows = this.mergeResultDimension(response.data);
    var obj = {
      rows: rows,
      columns: measureList
    };
    return obj;
  },
  generateWhereClause(
    where,
    filters,
    includePvPDate,
    pageWiseMinMaxKeyValue = ''
  ) {
    const values = filters && filters.values;
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (
          values[key] &&
          values[key].constructor === Array &&
          key !== 'date_range'
        ) {
          // for filters
          this.handleFilters({ values, where, key });
        } else if (key === 'date_range') {
          // for date range
          this.handleDateRange({
            includePvPDate,
            where,
            values,
            key,
            pageWiseMinMaxKeyValue
          });
        }
      }
    }
    return where;
  },
  handleFilters({ values, where, key }) {
    for (let i = 0; i < values[key].length; i++) {
      const temp = values[key];
      if (!where.dimensionNameValueList) {
        where.dimensionNameValueList = [];
      }
      if (temp[i].operator) {
        if (temp[i].operator.operator === 'BETWEEN') {
          const obj1 = {
            operator: 'GREATER_THAN_OR_EQUAL_TO',
            dimensionName: key,
            dimensionValue: Number(temp[i].value.split(', ')[0])
          };
          const obj2 = {
            operator: 'LESS_THAN_OR_EQUAL_TO',
            dimensionName: key,
            dimensionValue: Number(temp[i].value.split(', ')[1])
          };
          where.dimensionNameValueList.push(obj1, obj2);
        } else {
          where.dimensionNameValueList.push({
            dimensionName: key,
            dimensionValue: temp[i].value,
            operator: temp[i].operator.operator
          });
        }
      } else {
        where.dimensionNameValueList.push(this.addEsDataSetName(key, temp[i]));
      }
    }
  },
  handleDateRange({
    includePvPDate,
    where,
    values,
    key,
    pageWiseMinMaxKeyValue
  }) {
    if (includePvPDate) {
      // adding this condition for backward compatability. previously this function was not adding pvp filters
      if (
        values[key].compare &&
        values[key].compare_from &&
        values[key].compare_to
      ) {
        where.pvpDate = {
          from: values[key].compare_from,
          to: values[key].compare_to,
          compare_name: values[key].compare_name
            ? values[key].compare_name
            : null
        };
      }
    }
    where.date = {
      from: values[key].from,
      to: values[key].to,
      name: values[key].name ? values[key].name : null,
      page_wise_min_max_key: pageWiseMinMaxKeyValue
    };
  },
  convertDimensionsToFilterFormat(dimensions) {
    const filter = {
      order: [],
      values: {}
    };
    if (!dimensions) {
      return filter;
    }
    for (let i = 0; i < dimensions.length; i++) {
      const { dimensionName } = dimensions[i];
      const orderIndex = filter.order.findIndex((element) => {
        return element === dimensionName;
      });
      if (orderIndex === -1) {
        filter.order.push(dimensionName);
      }
      if (!filter.values[dimensionName]) {
        filter.values[dimensionName] = [];
        filter.values[dimensionName].push(
          this.formatToFilterFormat(dimensions[i])
        );
      } else {
        filter.values[dimensionName].push(
          this.formatToFilterFormat(dimensions[i])
        );
      }
    }
    return filter;
  },
  formatToFilterFormat(dimension) {
    if (dimension.type === 'EXPRESSION') {
      const obj = {
        operator: {
          operator: dimension.operator,
          title: operators[dimension.operator]
        },
        unit: null,
        value: dimension.dimensionValue
      };
      return obj;
    } else {
      return dimension.esDataSetName
        ? `${dimension.dimensionValue}---${dimension.esDataSetName}`
        : dimension.dimensionValue;
    }
  },
  extractMetadataForMetric(metricName, metadata) {
    const aFlattenedMeta = metadata.measureList;
    const metricMeta = aFlattenedMeta.find((metaObj) => {
      if (metaObj.name === metricName) {
        return true;
      } else {
        return false;
      }
    });
    return metricMeta;
  },
  // accepts the payload directly emitted by filter emitter
  // and coverts into where clause section payload compatible with brands service etc.
  filterPayloadToWhereClause(oFilterData) {
    const whereClause = {
      dimensionNameValueList: []
    };
    Object.keys(oFilterData).forEach((dimensionName) => {
      const dimensionFilters = oFilterData[dimensionName].map((value) => {
        return this.addEsDataSetName(dimensionName, value);
      });
      whereClause.dimensionNameValueList = dimensionFilters.concat(
        whereClause.dimensionNameValueList
      );
    });
    return whereClause;
  },
  unCurlArrayArrayToObjectArray: (arr) => {
    return arr.map((obj) => {
      const arrObj = {};
      let arrMaxLength = 0;
      const keysToConvertToArray = [];
      const rowKeys = Object.keys(obj);
      for (let index = 0; index < rowKeys.length; index++) {
        const key_name = rowKeys[index];
        if (utils.isJsonString(obj[key_name])) {
          arrObj[key_name] = JSON.parse(obj[key_name]);
          if (arrObj[key_name].length > arrMaxLength) {
            arrMaxLength = arrObj[key_name].length;
          }
        } else {
          keysToConvertToArray.push(key_name);
        }
      }
      keysToConvertToArray.forEach((key_name) => {
        arrObj[key_name] = Array(arrMaxLength).fill(obj[key_name]);
      });
      return arrObj;
    });
  },
  convertObjectToArray: (arr, anchorKey) => {
    return arr.reduce((prev, curr) => {
      const allKeys = Object.keys(curr);
      const objItemsCount = curr[anchorKey || allKeys[0]].length;
      for (let index = 0; index < objItemsCount; index++) {
        const rowItem = {};
        for (let i = 0; i < allKeys.length; i++) {
          const key_name = allKeys[i];
          rowItem[key_name] = curr[key_name][index];
        }
        prev.push(rowItem);
      }
      return prev;
    }, []);
  },
  // the order of the first level pivot is the order of the array ( lesser index takes precedence)
  groupDataToPivotLevels: (arr, pivotOrderKeys) => {
    return arr.reduce((prev, curr) => {
      const pivotOrderValues = pivotOrderKeys.map((pivot_key) => {
        return curr[pivot_key];
      });
      const groupedFinal = get(prev, pivotOrderValues);
      if (!groupedFinal) {
        set(prev, pivotOrderValues, [curr]);
      } else {
        groupedFinal.push(curr);
        set(prev, pivotOrderValues, groupedFinal);
      }
      return prev;
    }, {});
  },
  generateBlob(rawData, columnsToDownload) {
    console.log('process Downloading');
    const data = [];
    if (rawData && rawData.length) {
      for (var i = 0; i < rawData.length; i++) {
        var oObj = {};
        for (var j in rawData[i].RESULT) {
          oObj[j] = rawData[i].RESULT[j];
        }

        for (j in rawData[i].DIMENSION) {
          oObj[j] = rawData[i].DIMENSION[j];
        }

        data.push(oObj);
      }
    }
    console.log('process data');
    const fileRows = [];
    const columnLabels = [];
    const columnKeys = [];

    for (const idx in columnsToDownload) {
      if (columnsToDownload[idx].disabled || columnsToDownload[idx].download) {
        continue;
      }
      columnLabels.push(columnsToDownload[idx].displayName);
      columnKeys.push(columnsToDownload[idx].dbKey);
    }

    fileRows.push(
      columnLabels
        .map((header) => {
          return '"' + header.replace(/"/g, '""') + '"';
        })
        .slice()
        .join(',')
    );

    console.log('generating data');
    for (let index = 0; index < data.length; index++) {
      const obj = data[index];
      fileRows.push(
        columnKeys
          .map((key) => {
            if (
              key === 'asin' ||
              key === 'asin_hyperlink' ||
              key === 'cache_link'
            ) {
              if (
                obj[key] === undefined ||
                obj[key] === null ||
                obj[key] === ''
              ) {
                return '"' + ('' + 'NA') + '"';
              }
              return '"' + ('' + obj[key]).replace(/"/g, '""') + '"';
            } else {
              if (
                obj[key] === undefined ||
                obj[key] === null ||
                obj[key] === ''
              ) {
                return '"' + ('' + 'NA') + '"';
              }
              if (key === 'released' || key === 'scrape_date') {
                try {
                  const date = new Date(obj[key]);
                  let occurance = 0;
                  const formattedDate =
                    date
                      .toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      })
                      .replace(/ /g, (match) =>
                        ++occurance === 3 ? ',' : match
                      ) || 'NA';
                  return '"' + ('' + formattedDate.replace(/"/g, '""')) + '"';
                } catch (error) {
                  return '"' + ('' + obj[key].replace(/"/g, '""')) + '"';
                }
              }
              return '"' + ('' + obj[key]).replace(/"/g, '""') + '"';
            }
          })
          .slice()
          .join(',')
      );
    }
    const csvContent = fileRows.slice().join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    return blob;
  },
  modifyPayloadForBundleCubeExecutionRequest(
    apiConfig,
    transformedWhereClause,
    context,
    isChart = false
  ) {
    Object.keys(apiConfig.bundleCubeExecutionRequest).forEach((dataGroup) => {
      apiConfig.bundleCubeExecutionRequest[dataGroup].where = omit(
        transformedWhereClause,
        'tagWhereClause'
      );
      apiConfig.bundleCubeExecutionRequest[dataGroup].tagWhereClause =
        transformedWhereClause.tagWhereClause || [];
      apiConfig.bundleCubeExecutionRequest[dataGroup].where.date =
        context.state.whereClause.date;
      apiConfig.bundleCubeExecutionRequest[dataGroup].where.pvpDate =
        context.state.whereClause.pvpDate;
      if (isChart) {
        apiConfig.bundleCubeExecutionRequest[dataGroup].pvpenabled =
          apiConfig.bundleCubeExecutionRequest[dataGroup].where.pvpDate !==
          undefined;
      }
    });
  },
  addEsDataSetNameAsIdentifier(appendEsDataSetName, anchor, esDataSetName) {
    return anchor?.startsWith('dimension') &&
      appendEsDataSetName &&
      esDataSetName
      ? `${anchor}---${esDataSetName}`
      : anchor;
  },
  transformFilterFromAPI(filter, appendEsDataSetName = false) {
    const obj = {};
    Object.keys(filter?.[0] || []).forEach((item) => {
      const esDataSetNameAppendedKey = this.addEsDataSetNameAsIdentifier(
        appendEsDataSetName,
        item,
        filter?.[0]?.[item]?.esDataSetName || 'common_filter'
      );
      if (!filter[0][item].isInvalidated) {
        obj[esDataSetNameAppendedKey] = filter[0][item].values;
      } else if (filter[0][item].filterInvalidationDetails.valueInvalidated) {
        const filterValues = filter[0][item].values.filter((value) => {
          if (
            filter[0][
              item
            ].filterInvalidationDetails.invalidFilterValues.indexOf(value) ===
            -1
          ) {
            return value;
          }
          return false;
        });
        if (filterValues.length > 0) {
          obj[esDataSetNameAppendedKey] = filterValues;
        }
      }
    });

    return {
      order: Object.keys(obj || []) || [],
      values: obj
    };
  },
  mutateTableDataStateWithMetadataRows(rowData, metaData, primaryKey) {
    rowData.forEach((row) => {
      const primaryKey_id = row[primaryKey];
      const campaign = metaData[primaryKey_id];
      for (const key in campaign) {
        if (row.hasOwnProperty(key)) {
          row[key] = campaign[key];
        }
      }
    });
  }
};
