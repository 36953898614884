import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep } from 'lodash';
import transformer from '@/utils/services/data-transformer';
const APIConfig = {
  cubeName: 'asin_campaign_relationship_catalogue_asin',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [
    'product_title',
    'product_url',
    'image_url',
    'brand',
    'sku_price',
    'weeks_of_coverage',
    'availability_status'
  ],
  groupByDimensionsList: ['asin'],
  orderByList: [
    {
      dimension: 'product_title',
      direction: 'ASC'
    }
  ],
  limit: 10,
  page: 1
};

const formSKUList = (skus, rightTableRows) => {
  const rightTableSKUs = rightTableRows.map(
    (rightTableSKU) => rightTableSKU.asin
  );
  const validSKUs = skus.filter((sku) => !rightTableSKUs?.includes(sku.trim()));
  let skuList = '';
  for (const sku of validSKUs) {
    skuList += `'${sku.trim()}',`;
  }
  skuList = skuList.slice(0, -1);
  return skuList;
};

const addSkuOnInput = (data) => {
  let allSKUs = data.keywords || [];
  const entityCount = data?.enterListRef?.entityCount || 1000;
  if (allSKUs.length >= entityCount) {
    const remainingSKUs = allSKUs.length - entityCount;
    allSKUs = allSKUs.slice(0, entityCount);
    data.ref.$snackbar.open({
      message: `Unable to add the last ${remainingSKUs} SKU(s) (You may add upto ${entityCount} SKU(s))`,
      duration: 3000,
      buttonColor: '#f5d908',
      actionText: ''
    });
  }
  const skuList = formSKUList(allSKUs, data?.ref?.rightTableRows);
  const APIConfigDeepClone = cloneDeep(APIConfig);
  let result = [];
  if (skuList === '') {
    data.enterListRef.keywordTextInput = '';
    data.ref.$snackbar.open({
      message: 'SKU(s) in the list are already added',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  delete APIConfigDeepClone.limit;
  delete APIConfigDeepClone.page;
  APIConfigDeepClone.where = {
    dimensionNameValueList: [
      {
        dimensionName: 'asin',
        dimensionValue: skuList,
        operator: 'IN'
      }
    ]
  };
  data.ref.rightTableLoad = true;
  HttpLayer.post({
    APIData: APIConfigDeepClone
  })
    .then((response) => {
      result = transformer.mergeResultDimension(response.data, true);
      data.enterListRef.keywordTextInput = '';
    })
    .finally(() => {
      data.ref.$snackbar.open({
        message: result?.length
          ? 'Added SKU(s) from the list'
          : 'Ineligible/Invalid SKU(s) were not added',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      data.ref.addEntityFromRawObjectArray(result);
      data.ref.rightTableLoad = false;
    });
};

export { APIConfig, addSkuOnInput };
