<template>
  <div>
    <section
      ref="trigger"
      v-tippy="tippyOptions"
    >
      <multiDropdownChip
        :multi-dropdown-config="multiDropdownConfig"
        @click="handleClick"
      />
    </section>
    <section
      :id="id"
      class="u-border-radius-s"
    >
      <RuleBuilderV2
        v-show="activeDropdownIndex === 0"
        ref="rule-builder-axis"
        :root="axisConfig.root"
        @reset="handleCancel"
        @ruleApply="handleAxisRuleApply"
      />
      <RuleBuilderV2
        v-show="activeDropdownIndex === 1"
        ref="rule-builder-midpoint"
        :root="midPointConfig.root"
        @reset="handleCancel"
        @ruleApply="handleMidPointRuleApply"
      />
    </section>
  </div>
</template>

<script>
import ruleBuilderChip from '../atoms/rule-builder-chip';
import RuleBuilderV2 from '../molecules/rule-builder-v2';
import multiDropdownChip from '../atoms/multi-dropdown-chip';

export default {
  components: {
    ruleBuilderChip,
    RuleBuilderV2,
    multiDropdownChip
  },
  props: {
    selected: {
      type: Object,
      default: () => ({})
    },
    multiDropdownConfig: {
      type: Array,
      default: () => []
    },
    axisConfig: {
      type: Object,
      default: () => ({})
    },
    midPointConfig: {
      type: Object,
      default: () => ({})
    },
    dimensionType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activeDropdownIndex: 0,
      id: null,
      isTippyActive: false,
      tippyInstance: null,
      tippyOptions: {}
    };
  },
  created() {
    this.id = 'dropdown-' + this._uid;
    this.tippyOptions = {
      html: '#' + this.id,
      reactive: true,
      distance: 4,
      placement: 'bottom',
      interactive: true,
      theme: 'dropdown',
      trigger: 'click',
      duration: [0, 0],
      onShow: this.onShow,
      onHide: this.onHide
    };
  },
  methods: {
    handleCancel() {
      this.handleRuleBuilderReset();
      this.hideTippy();
    },
    hideTippy() {
      const instance = document.querySelector('.tippy-popper')?._tippy;
      instance?.hide();
    },
    handleClick(payload) {
      const { index } = payload;
      this.activeDropdownIndex = index;
    },
    handleRuleBuilderReset() {
      this.$refs?.['rule-builder-axis']?.resetToInitialPopupState();
      this.$refs?.['rule-builder-midpoint']?.resetToInitialPopupState();
    },
    handleAxisRuleApply(data) {
      const payload = { data, id: this.dimensionType };
      this.$emit('axisRuleApply', payload);
      this.hideTippy();
    },
    handleMidPointRuleApply(data) {
      const payload = { data, id: this.dimensionType };
      this.$emit('midPointRuleApply', payload);
      this.hideTippy();
    },
    onShow() {
      this.isTippyActive = true;
    },
    onHide() {
      this.isTippyActive = false;
      this.handleRuleBuilderReset();
    }
  }
};
</script>
