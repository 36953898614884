<template>
  <section>
    <div v-if="enableV2">
      <OverviewPageV2 />
    </div>
    <section v-else>
      <sticky-filter-panel
        v-if="showFilter"
        ref="sticky-filter-panel"
        :key="stickyFilterKey"
        :hide-export="true"
        :hide-clear-all-btn="true"
        :disable-view="true"
        :show-filter="showFilter"
        class="sticky-panel sticky-filter-panel category_page"
        :filter-data="filterComputedData"
        :primary-filter-data="primaryFilterData"
        :secondary-filter-data="secondaryFilterComputedData"
        :filter-local-storage-key="filterLocalStorageKey"
        :filter-emit="filterEmit"
        :options-list="viewList || []"
        :selected-option="selectedView"
        :filter-state="filterState"
        :default-date-post-range="defaultDateRange"
        :min-max-date="minMaxDate"
        :hide-view-btn="hideViewBtn"
        :hide-remove-btn="true"
        :hide-update-view="hideUpdateView"
        :enable-open-filter="enableOpenFilter"
        :filter-key="filterKey"
        :additional-ranges="additionalRanges"
        :date-mode="dateMode"
        :selection-mode="selectionMode"
        :enable-minimum-selection="true"
        :view-selection-enabled="false"
        @savedFilterApplied="handleSaveFilterApplied"
        @resetView="handleResetView"
        @onCreate="onCreate"
        @filterMounted="handleFilterMount"
        @filterBeforeDestroy="handleFilterBeforeDestroy"
      >
        <div
          slot="before-filter"
          class="u-display-flex u-flex-align-items-center u-height-36px"
        >
          <div
            class="u-font-size-2 u-font-weight-700 u-display-flex capitalize"
          >
            Overview
          </div>
        </div>
        <div
          v-if="!isMarketShareLite"
          slot="preDatePicker"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-button
            text="View SKUs"
            :type="'filled'"
            class="u-spacing-mr-s"
            :click-fn="navigateTo.bind(this)"
          />
        </div>
        <div slot="footer">
          <Settings
            v-if="!isMarketShareLite"
            :key="key"
            :types="settingsValue.viewBy"
            :placements="settingsValue.include3p"
            :keyword-type="settingsValue.displayShareIn"
            :selected-type="transformedComputedSelectedSettingsValue.viewBy"
            :selected-placement="
              transformedComputedSelectedSettingsValue.include3p
            "
            :selected-keyword-type="
              transformedComputedSelectedSettingsValue.displayShareIn
            "
            :customer-level-filters="customerLevelFilters"
            widget-name="settings"
            :hide-view-by="true"
            @apply="handleApply"
            @cancel="handleCancel"
          />
        </div>
      </sticky-filter-panel>

      <section
        v-if="pageFetched"
        class="widgetWrapper u-envelop-width"
      >
        <versionToggle
          v-if="isMsproEnabled && !isMarketShareLite"
          class="u-spacing-mv-m"
          :selected-tab="selectedTab"
        />
        <section
          class="u-spacing-mh-m u-spacing-mv-s u-display-flex slide-card"
        />
        <section class="u-spacing-mh-m u-display-flex slide-card">
          <benchmarking-table
            v-if="benchmarkWidgetService"
            style="width: 100%"
            :page="page"
            :global-settings="getGlobalSettings"
            :global-view-id="selectedView.globalViewId"
            :page-id="selectedView.pageId"
            :service="benchmarkWidgetService"
            :reversed-object="reversedObject"
            :action-icons="benchmarkingWidgetActionIcons"
          />
        </section>

        <table-widget
          v-if="tableDataService !== undefined"
          ref="table-widget"
          class="widget-3"
          :page="page"
          :page-wise-min-max-key="pageWiseMinMaxKey"
          :global-settings="getGlobalSettings"
          :data-service="tableDataService"
          :min-max-date="minMaxDate"
          :action-icons="shareSummaryWidgetActionIcons"
          :share-summary-widget="shareSummaryWidget"
        />
      </section>

      <section v-else>
        <section
          v-if="!pageFetched"
          class="view-port u-display-flex u-flex-direction-column"
        >
          <loader
            class="u-height-100 u-bg-color-grey-white"
            :loading="true"
            :color="'#007cf6'"
          />
        </section>
        <section
          v-else-if="pageFetchedError"
          class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
        >
          <div class="u-display-flex noskuImage_container">
            <img
              alt="error"
              src="/images/no_sku_found.png"
              class="u-width-100"
            />
          </div>
          <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
            <p class="u-color-grey-base u-spacing-mr-s">
              Something went wrong!
            </p>
          </div>
        </section>
      </section>
      <section
        class="u-spacing-mh-m u-spacing-mv-s u-display-flex slide-card"
      />
    </section>
  </section>
</template>

<script>
import versionToggle from '@/components/pages/insights/amazon/market_share_pro/molecules/versionToggle.vue';

import OverviewPageV2 from '@/components/pages/insights/amazon/market_share_pro/pages/overview/index.vue';
import chart from '@/components/basic/chart';
import HttpService from '@/utils/services/http-service';
import cardWidget from '@/components/widgets/cardWidget';
import loader from '@/components/basic/loader';
import widgetMixin from '@/components/widgetsMixin.js';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import Settings from '@/components/pages/insights/amazon/market-share/molecules/settings-popup.vue';
import { cloneDeep } from 'lodash';
import DataService from '@/components/pages/insights/amazon/share-of-voice/service/data';
import TableWidgetService from '@/components/pages/insights/amazon/market-share/service/table-widget-service.js';
import TableWidget from '@/components/pages/insights/amazon/market-share/templates/tableWidget.vue';
import benchmarkingTable from '@/components/pages/insights/amazon/market-share/molecules/benchmarking-table.vue';
import { MarketShareBenchmarkTable } from '@/components/pages/insights/amazon/market-share/service/benchmarking-table';
import SubCategoryFilter from '@/components/pages/insights/amazon/market-share/molecules/hierarchy.vue';
import {
  transformNestedSettingsInUI,
  transformNestedSettingsInDropDown,
  transformNestedSettingsValueForGlobalSettings,
  getAdditionalDateRanges,
  getHighestSelectableRange,
  setGlobalSettingsForMS,
  getGlobalSettingsForMS,
  configurationSetting,
  computeFinalWhereClause,
  getFormattedCustomerLevelFilters,
  getSavedCustomerFilters,
  constants,
  transform3pInNestedSettings,
  get3pOptions,
  asinLevelV2ConfigEnabled,
  DisplayByOptions,
  DefaultDisplayByOptions,
  checkForDisplayShareInOptions,
  getDisplayByShareType,
  getGlobalToLocalInclude3p,
  isMarketShareLite,
  overviewConstants,
  getMetric,
  getShareSummaryWidget,
  NodeIdMap,
  getActionIcons,
  updatePayloadWithNodeId,
  localStorageFallback,
  toggleVersion,
  isMsproEnabled,
  msVersion,
  msProConstants
} from '@/components/pages/insights/amazon/market-share/utils';
import transformer from '@/utils/services/data-transformer.js';
import Vue from 'vue';

export default {
  components: {
    chart,
    loader,
    stickyFilterPanel,
    Settings,
    cardWidget,
    TableWidget,
    SubCategoryFilter,
    benchmarkingTable,
    OverviewPageV2,
    versionToggle
  },
  mixins: [widgetMixin],
  data() {
    let namespace, page, filterLocalStorageKey, diffDate, pageName;
    if (isMarketShareLite()) {
      ({
        mslNamespace: namespace,
        mslFilterLocalStorageKey: filterLocalStorageKey,
        mslPage: page,
        mslDiffDate: diffDate,
        mslPageName: pageName
      } = overviewConstants);
    } else {
      ({ namespace, page, filterLocalStorageKey, diffDate, pageName } =
        overviewConstants);
    }
    return {
      isMsproEnabled: isMsproEnabled(),
      selectedTab: msProConstants.oldVersion,
      enableV2: false,
      reversedObject: {},
      categoryNodeMap: {},
      diffDate,
      dateMode: constants.dateMode,
      shareSummaryWidget: getShareSummaryWidget('overviewPage'),
      pageName,
      isMarketShareLite: isMarketShareLite(),
      namespace,
      page,
      filterLocalStorageKey,
      benchmarkWidgetService: null,
      selectedSubCategory: [],
      selections: [],
      selectionMode: 'multi-week',
      viewList: [],
      selectedView: {},
      pageFetchedError: '',
      secondaryFilterData: {},
      additionalRanges: {
        add: ['lastWeek', 'last4Week', 'last13Week'],
        overrideRange: ['lastWeek', 'last4Week', 'last13Week'],
        order: {
          lastWeek: 3,
          last4Week: 4,
          last13Week: 5
        }
      },
      filterMounted: false,
      renderKey: 0,
      pageFetched: false,
      enableOpenFilter: true,
      filterInstanceCreated: false,
      stickyFilterKey: 0,
      filterKey: 0,
      defaultDateRange: 'last30Days',
      filterInstance: null,
      hideViewBtn: true,
      hideUpdateView: true,
      pageId: null,
      tableDataService: undefined,
      showFilter: false,
      filterEmit: 'marketshare-overview-filter-apply',
      minMaxDate: {},
      pageWiseMinMaxKey: 'marketshare-overview',
      selectedCustomerFilters: {},
      computedSelectedSettingsValue: {
        viewBy: { entityType: 'category', label: 'Category' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedSettingsValue: {
        viewBy: { entityType: 'category', label: 'Category' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      globalWhereClauseValue: {},
      getGlobalSettings: {},
      key: 0,
      benchmarkingWidgetActionIcons: getActionIcons(
        constants.benchmarkingDownloadTippyTitle
      ),
      shareSummaryWidgetActionIcons: getActionIcons(
        constants.shareSummaryDownloadTippyTitle
      )
    };
  },
  computed: {
    actionIcons() {
      return isMarketShareLite()
        ? []
        : [
            {
              emit: 'download',
              icon: 'download',
              loading: false,
              error: false,
              tippyTitle:
                'Download Excel with week level Market Share for the selected Manufacturer and time period.'
            }
          ];
    },
    // updates the settings component selections
    enableHeirarchy() {
      let selectedCategory = cloneDeep(
        this.currentCategory[0]?.dimensionValue || ''
      );
      if (selectedCategory) {
        selectedCategory = selectedCategory
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
      const obj = this.filterData?.category?.[selectedCategory] || {};
      const length = Object.keys(obj?.children || {}).length;
      if (length === 0) {
        return false;
      }
      return true;
      // return this.$store.getters.getHeirarchyFilterData;
    },
    currentCategory() {
      const globalWhereClause = cloneDeep(this.globalWhereClauseValue) || {};
      let dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      dimensionNameValueList = dimensionNameValueList.filter((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.replace('~', '');
          return element;
        } else {
          return false;
        }
      });
      return dimensionNameValueList;
    },
    filterComputedData() {
      return [];
    },
    customerLevelFilters() {
      return getFormattedCustomerLevelFilters(
        this.filterData,
        this.selectedCustomerFilters
      );
    },
    transformedComputedSelectedSettingsValue() {
      const nestedSettings = cloneDeep(this.computedSelectedSettingsValue);
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInUI(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    settingsValue() {
      const nestedSettings = {
        viewBy: [{ entityType: 'category', label: 'Category' }],
        displayShareIn: cloneDeep(DisplayByOptions),
        include3p: get3pOptions(getDisplayByShareType())
      };
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInDropDown(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    secondaryFilterComputedData() {
      const category_hierarchy = {
        multi: true,
        v1: true,
        key: 'category_hierarchy',
        label: 'Category',
        values: []
      };
      Object.keys(this.filterData.category || {}).forEach((element) => {
        category_hierarchy.values.push({ title: element, enable: true });
      });
      return { category_hierarchy };
    },
    filterState() {
      return {
        getter: this.namespace + 'getGlobalFilters',
        setter: this.namespace + 'setGlobalFilters'
      };
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    },
    globalToLocal() {
      return {
        viewBy: {
          label: {
            Manufacturers: 'Category',
            Brands: 'Category'
          },
          entityType: {
            manufacturer: 'category',
            brand: 'category',
            asin: 'category'
          }
        },
        displayShareIn: {
          metricsList: getMetric('overview')
        },
        include3p: getGlobalToLocalInclude3p()
      };
    }
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (msVersion() && !this.isMarketShareLite) {
      this.enableV2 = true;
    } else {
      transform3pInNestedSettings();
      this.initializePage();
    }
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  beforeRouteLeave(to, from, next) {
    setGlobalSettingsForMS(constants.NAVIGATE_FROM, this.pageName);
    next();
  },
  mounted() {
    console.log('MS local storage > overview page >', getGlobalSettingsForMS());
  },
  methods: {
    updateToggleVersion(updateVal) {
      toggleVersion(updateVal);
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    /**
     * @description to reset any active settings changes on clicking on cancel button
     */
    handleCancel() {
      this.key++;
    },
    navigateTo() {
      this.$router.push({ name: 'marketshareSkus' });
    },
    applySubCategory(data) {
      this.selectedSubCategory = Object.keys(data);
      this.refreshWidgets();
    },
    handleFilterMount(instance) {
      this.filterMounted = true;
      eventBus.$on(this.filterEmit, async (data) => {
        // date range is changed we catch the event here
        let storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        for (const key in storage) {
          if (key !== constants.CATEGORY_HIERARCHY) {
            setGlobalSettingsForMS(key, storage[key]);
          }
        }
        if (data?.category_hierarchy) {
          setGlobalSettingsForMS(
            constants.OVERVIEW_CATEGORY_HIERARCHY,
            data?.category_hierarchy
          );
          // Only if category has changed then make a request for fetching differential dates for category
          await this.handleDateChangeForCategoryUpdate(data.category_hierarchy);
        }

        if (!data.name) {
          this.$store.dispatch('setInitialSelections', {});
          this.selectedSubCategory = [];
        }
        this.refreshWidgets();
      });
    },
    handleFilterBeforeDestroy() {
      // this.filterMounted = false;
      // eventBus.$off(this.filterEmit);
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.$router.push({
            name: this.pageName,
            params: { id: selectedView.globalViewId }
          });
          return;
        }

        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList(selectedView);
      } else {
        if (this.viewList && this.viewList.length > 0) {
          selectedView = this.viewList[0];
          this.$router.push({
            name: this.pageName,
            params: { id: selectedView.globalViewId }
          });
          const initialState = {
            filters: selectedView?.pageUserFilters,
            ...selectedView?.widgetUserSelectionMetadata
          };
          this.$store.dispatch(
            this.namespace + 'setSelectedView',
            selectedView
          );
          this.$store.dispatch(
            this.namespace + 'setInitialWidgetState',
            initialState
          );
          this.fetchPageList(selectedView);
        }
      }
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(
        this.$store.getters[this.namespace + 'getGlobalFilters']
      );
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: []
      };
      where.date.from = globalWhereClause?.date_range?.from;
      where.date.to = globalWhereClause?.date_range?.to;
      where.date.name = globalWhereClause?.date_range?.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause?.date_range?.compare_from;
      where.pvpDate.to = globalWhereClause?.date_range?.compare_to;
      where.pvpDate.compare_name = globalWhereClause?.date_range?.compare_name;
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      where.dimensionNameValueList.forEach((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.toLowerCase() + '~';
          element.operator = 'STARTSWITH';
        }
      });
      return where;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      setTimeout(() => {
        this.showFilter = true;
      });
    },
    handleResetView() {
      this.reinitView();
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    async getGlobalFilters(cube, urlFactoryId, page, where) {
      this.resetFilters();
      await this.fetchFilters(
        cube,
        urlFactoryId,
        page,
        where,
        undefined,
        undefined,
        true
      );
    },
    handleApply(payload) {
      // when settings is changed we catch the event here
      // Payload has dynamic currency value, that is transformed so that local storage has static currency value i.e Dollars
      const nestedSettings =
        transformNestedSettingsValueForGlobalSettings(payload);
      this.selectedCustomerFilters = nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(
        'customerLevelFilters',
        nestedSettings.customerLevelFilters
      );
      delete nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
      this.$nextTick(() => {
        this.computedSelectedSettingsValue = payload;
        this.refreshWidgets();
      });
    },
    async refreshWidgets() {
      // const filters = this.$store.getters[this.namespace + "getGlobalFilters"];
      this.globalWhereClauseValue = cloneDeep(this.globalWhereClause());
      this.tableDataService.dateApiMaxDate = this.minMaxDate?.maxDate;
      const whereClause = computeFinalWhereClause(
        this.globalWhereClauseValue,
        [],
        this.selectedCustomerFilters
      );
      this.getGlobalSettings = {
        where: whereClause,
        settingValue: configurationSetting(
          getGlobalSettingsForMS('nestedSettings', undefined, this.v2),
          this.globalToLocal
        )
      };
      if (isMarketShareLite()) {
        this.categoryNodeMap = NodeIdMap(this.filterData?.category, {});
        this.getGlobalSettings.where.dimensionNameValueList =
          updatePayloadWithNodeId(
            this.getGlobalSettings.where.dimensionNameValueList,
            this.categoryNodeMap
          );

        for (const categoryName in this.categoryNodeMap) {
          const value = this.categoryNodeMap[categoryName];
          this.reversedObject[value] = categoryName;
        }
      }
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      this.viewList.unshift(...defaultViewTemp);
    },
    initializePage() {
      this.showFilter = false;
      const requestBody = { page: this.page };
      HttpService.post('DASHBOARD_SERVICE', requestBody, {
        append: '/global/view/list'
      })
        .then((response) => {
          if (response.status === 200) {
            this.viewList = response.data.globalViews.map((item) => {
              item.currentUser = false;
              if (item.createdBy === window.user.email) {
                item.currentUser = true;
              }
              item.formattedAt = moment(item.createdAt).format('LLL');
              if (item.groupName !== 'Default Views') {
                item.edit = false;
              }
              return item;
            });

            this.sortViewList();

            let globalViewId = '';
            if (this.$route?.params?.id) {
              globalViewId = this.$route?.params?.id;
              this.handleViewBasedonParams(globalViewId);
            } else {
              globalViewId = response.data?.userUnsavedGlobalView?.globalViewId;
              if (globalViewId) {
                this.$router.push({
                  name: this.pageName,
                  params: { id: globalViewId }
                });
              } else {
                this.$router.push({
                  name: this.pageName,
                  params: { id: this.viewList[0]?.globalViewId }
                });
              }
            }
          }
        })
        .catch((error) => {
          console.error('e', error);
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        });
    },
    async fetchPageList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.pageId = response.data.id;
        if (response.status === 200) {
          // const { cubeName, pageName, where } =
          //   response.data.API.filterAPI.request;
          // this.getGlobalFilters(cubeName, 'FETCH_FILTERS_V2', pageName, where);
          this.fetchFilterV3(
            {
              ...response.data.API.filterAPI,
              endPoint: response.data.API.filterAPI.endPoint + '?v2=' + this.v2
            },
            (data) => data
          ).finally(async () => {
            localStorageFallback(this.filterData?.category, 'overviewPage');
            let storage = JSON.parse(
              localStorage.getItem(this.filterLocalStorageKey)
            );
            this.categoryNodeMap = NodeIdMap(this.filterData?.category, {});
            const storageValues = this.transformFilterFromAPI(
              response.data.filters?.selectedFilter?.filter || []
            );

            let globalFilter = getGlobalSettingsForMS();
            const defaultCategorytList =
              this.secondaryFilterComputedData.category_hierarchy?.values.map(
                (x) => x.title
              );
            let category =
              globalFilter?.overview_category_hierarchy?.filter((item) =>
                defaultCategorytList.includes(item)
              ) || defaultCategorytList;

            let dateRange = await this.updatedDateRange(
              category.map((cat) => cat.toLowerCase() + '~')
            );
            const { updateFilter, highestSelectableRange } =
              this.checkForCategoryUpdateDateRange(dateRange);

            this.previousPage = globalFilter?.[constants.NAVIGATE_FROM];
            setGlobalSettingsForMS(constants.NAVIGATE_FROM, {}, true);
            this.selectedCustomerFilters =
              getSavedCustomerFilters(this.filterData) || {};
            let dateRangeHighestSelectable = {
              name: highestSelectableRange,
              compare: true,
              compare_name: null
            };
            checkForDisplayShareInOptions();
            if (Object.keys(globalFilter).length) {
              // If global settings date range stored is a disabled date range, select highest selctable date range
              storageValues.values.date_range = updateFilter
                ? dateRangeHighestSelectable
                : globalFilter?.date_range;
              let nestedSettings = globalFilter.nestedSettings;
              this.selectedSettingsValue = configurationSetting(
                nestedSettings,
                this.globalToLocal
              );
              this.computedSelectedSettingsValue = configurationSetting(
                getGlobalSettingsForMS('nestedSettings', undefined, false),
                this.globalToLocal
              );
            } else {
              storageValues.values.date_range = dateRangeHighestSelectable;
              let nestedSettings = { ...this.computedSelectedSettingsValue };
              setGlobalSettingsForMS(null, {
                ...storageValues.values,
                nestedSettings
              });
            }
            storageValues.values.category_hierarchy = category;
            storageValues.order = ['category_hierarchy'];
            if (!storage) {
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              localStorage.setItem(
                `${this.filterLocalStorageKey}-order`,
                JSON.stringify(storageValues.order)
              );
            } else {
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              localStorage.setItem(
                `${this.filterLocalStorageKey}-order`,
                JSON.stringify(storageValues.order)
              );
            }
            this.showFilter = true;
            this.$nextTick(() => {});
            setTimeout(() => {
              // this.showFilter = true;
              document.querySelector('.filter-list__item').click();
            }, 0);
            setTimeout(() => {
              document.querySelector('body').click();
              document.querySelector('.rb-filter .icon-cross').style.display =
                'none';
              this.refreshWidgets();
            }, 0);
            this.benchmarkWidgetService = new MarketShareBenchmarkTable(
              this.selectedView.globalViewId,
              this.selectedView.pageId,
              this.minMaxDate
            );

            const widgetName =
              (isMarketShareLite()
                ? overviewConstants.mslShareSummaryWidget
                : overviewConstants.shareSummaryWidget) +
              (this.v2 ? '_v2' : '');
            this.tableDataService = new TableWidgetService(
              this.page,
              widgetName,
              this.selectedView.pageId,
              this.selectedView.globalViewId
            );
            this.tableDataService.savedSortState =
              globalFilter?.[
                constants.SORTING_OBJECT
              ]?.categoryShareSummary_executive;
            (this.widget2DataService = new DataService(
              this.page,
              this.widget2Name,
              this.selectedView.pageId,
              this.selectedView.globalViewId
            )),
              (this.pageFetched = true);
          });
        }
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    isJsonParsable(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return string;
      }
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    /**
     * @description utility function to fetch updated dates when categroies change
     * @param {array} categoryList list of categories to fetch dates for
     */
    async handleDateChangeForCategoryUpdate(categoryList) {
      this.pageFetched = false;
      this.showFilter = false;
      let dateRange = await this.updatedDateRange(
        categoryList.map((cat) => cat.toLowerCase() + '~')
      );
      const { updateFilter, highestSelectableRange } =
        this.checkForCategoryUpdateDateRange(dateRange);
      let date_range = {
        name: highestSelectableRange,
        compare: true,
        compare_name: null
      };
      /**
       * if updateFilter is true that means there's mismatch between selected dates and available dates
       */
      if (updateFilter) {
        const storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify({
            ...storage,
            date_range
          })
        );
      }
      await this.updateFilters();
      this.$nextTick(() => {
        this.pageFetched = true;
        this.showFilter = true;
      });
    },
    /**
     * @description utility function to check currently selected dates are correct and data is available in backend
     * @param {object} dateRange dateRange received from backend
     */
    checkForCategoryUpdateDateRange(dateRange) {
      const backendStartDate = moment(dateRange.data.response.minDate);
      const backendEndDate = moment(dateRange.data.response.maxDate);

      this.minMaxDate.minDate = backendStartDate.format('MM/DD/YYYY');
      this.minMaxDate.maxDate = backendEndDate.format('MM/DD/YYYY');
      this.minMaxDate.rawMinDate = backendStartDate;
      this.minMaxDate.rawMaxDate = backendEndDate;
      this.additionalRanges = getAdditionalDateRanges(
        backendStartDate,
        backendEndDate
      );
      const availableRanges = this.additionalRanges.add.filter(
        (range) => this.additionalRanges.disabledRange.indexOf(range) === -1
      );
      const highestSelectableRange = getHighestSelectableRange(
        this.additionalRanges.order,
        availableRanges
      );

      let globalFilter = getGlobalSettingsForMS();
      if (!globalFilter?.date_range) {
        /**
         * if localstorage is cleared or for some reason we don't have dateRanges stored in localStorage
         * we will update the dateRanges to highestSelectableRange
         */
        return { updateFilter: true, highestSelectableRange };
      }
      const currentSelectedStartDate = moment(globalFilter?.date_range.from);
      const currentSelectedEndDate = moment(globalFilter?.date_range.to);

      let updateFilter = false;
      if (
        !currentSelectedStartDate ||
        !currentSelectedStartDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        /**
         * if currently selected start date in filter is not between available date ranges
         * we will update the dateRanges to highestSelectableRange
         */
        updateFilter = true;
      }
      if (
        !currentSelectedEndDate ||
        !currentSelectedEndDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        /**
         * if currently selected end date in filter is not between available date ranges
         * we will update the dateRanges to highestSelectableRange
         */
        updateFilter = true;
      }

      return { updateFilter, highestSelectableRange };
    },
    /**
     * @description utility function to fetch updated dates for selected categories
     * @param {array} categorySelection list of selected categories on overview page
     */
    async updatedDateRange(categorySelection = []) {
      if (categorySelection.length === 0) {
        return;
      }
      if (isMarketShareLite()) {
        categorySelection = categorySelection.map(
          (dimension) => this.categoryNodeMap[dimension]
        );
      }
      try {
        /**
         * here overview=true is needed to let backend know this request is for overview page
         */
        return await HttpService.post(this.diffDate, categorySelection, {
          append: '?overview=true',
          params: {
            v2: this.v2
          }
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    showErrorPageLevel(err) {
      this.pageFetched = false;
      this.pageFetchedError = false;
      console.error('err', err);
      this.openSnackbar();
    },
    async updateFilters() {
      return new Promise((resolve) => {
        this.reinitView();

        // Hack to show the filters
        setTimeout(() => {
          document.querySelector('.filter-list__item').click();
        }, 0);
        setTimeout(() => {
          document.querySelector('body').click();
          document.querySelector('.rb-filter .icon-cross').style.display =
            'none';
        }, 0);

        // Once the filter component is reinitialized, resolve the method
        const interval = setInterval(() => {
          if (this.filterInstanceCreated) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }
  }
};
</script>
<style scoped lang="css" scoped>
.intermediate-height-w3 {
  height: 566px;
}
.intermediate-height-w5 {
  height: 332px;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.view-port {
  height: calc(100vh - 72px);
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: scroll;
}
.widget-2-width {
  width: calc(60% - 16px);
}
.u-height-36px {
  height: 36px;
}
.corners-rounded {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.overlay-snapshot {
  position: sticky;
  bottom: -23px;
  z-index: 99;
}
.box-shadows {
  box-shadow: 0 1px 16px 0 rgb(43 51 59 / 25%);
}
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>

<style lang="css">
div[col-id^='brands'] {
  padding-right: 2.4rem;
  border: left;
  border-color: #e9eaeb;
  border-width: 0.8rem;
}
div[col-id^='brands'].ag-cell {
  margin: 0rem;
  padding: 0rem;
}
</style>
