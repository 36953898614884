var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spacing-pv-xxxl u-spacing-ph-xxl"},[_c('section',{staticClass:"u-spacing-pv-m"},[_c('Header',{attrs:{"step":_vm.step}}),_c('div',[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between"},[_c('RbSplitTabs',{attrs:{"tabs-list":_vm.tabsList,"active-tab":_vm.selectedTab,"is-tabs-list":true},on:{"tabClicked":_vm.handleTabClicked}}),_c('rb-button',{attrs:{"text":"Create Ad Group","type":"hollow","click-fn":_vm.handleAddAdGroup}})],1),_c('div',{staticClass:"u-width-100 u-listed-groups-container u-spacing-mt-xl"},[(_vm.listedAdGroups.length === 0)?_c('div',{staticClass:"u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-6 u-color-grey-x-light"},[_vm._v(" No ad groups found ")]):_vm._e(),_vm._l((_vm.listedAdGroups),function(group,i){return _c('div',{key:i,staticClass:"u-border-radius-s u-border-left u-border-color-green-base u-box-shadow u-display-flex u-flex-justify-content-space-between u-border-width-xl u-spacing-p-l",class:[
            !_vm.selectedTab || _vm.showReadyToLaunchGroups
              ? 'u-border-color-green-base'
              : 'u-border-color-orange-base',
            i + 1 < _vm.listedAdGroups.length ? 'u-spacing-mb-xl' : ''
          ]},[_c('div',{staticClass:"u-font-size-4 u-font-weight-400 u-color-grey-base u-display-flex"},[_c('span',{staticClass:"u-spacing-pr-m",attrs:{"data-cy":"numberOfCards"}},[_vm._v(_vm._s(i > 9 ? i + 1 : ("0" + (i + 1))))]),_c('div',{attrs:{"data-cy":"adGroupName"}},[_vm._v(" "+_vm._s(group.adGroupName)+" "),_c('div',{staticClass:"u-display-flex u-font-size-5 u-spacing-mt-s"},[(group.defaultBid && !_vm.isOptimizedCampaign)?_c('div',{staticClass:"u-spacing-mr-l"},[_c('span',{staticClass:"u-color-grey-lighter",attrs:{"data-cy":"defaultCPCBid"}},[_vm._v(" Default CPC Bid: ")]),_vm._v(" $"+_vm._s(group.defaultBid)+" ")]):_vm._e(),(group.products.length)?_c('div',{staticClass:"u-spacing-mr-l"},[_c('span',{staticClass:"u-color-grey-lighter",attrs:{"data-cy":"adSKu"}},[_vm._v(" Ad Skus: ")]),_vm._v(" "+_vm._s(group.products.length)+" ")]):_vm._e(),(group.defaultBid && !_vm.isOptimizedCampaign)?_c('div',[_c('span',{staticClass:"u-color-grey-lighter",attrs:{"data-cy":"targets"}},[_vm._v(" Targets: ")]),_vm._v(" "+_vm._s(group.keywords ? group.keywords.length : 0)+" ")]):_vm._e()])])]),_c('div',{staticClass:"u-spacing-pr-xl u-display-flex u-flex-direction-column u-flex-justify-content-center"},[_c('div',{staticClass:"u-display-flex u-font-size-6 u-font-weight-600"},[_c('div',{staticClass:"u-spacing-mr-xl u-cursor-pointer u-display-flex u-flex-align-items-center",attrs:{"data-cy":"removeText"},on:{"click":function($event){return _vm.handleRemoveAdGroup(i)}}},[_c('rb-icon',{staticClass:"rb-icon--medium u-cursor-pointer u-spacing-mr-s u-color-grey-lighter",attrs:{"icon":'delete'}}),_vm._v(" Remove ")],1),_c('div',{staticClass:"u-cursor-pointer u-display-flex u-flex-align-items-center",on:{"click":function($event){return _vm.handleEditAdGroup(
                    group,
                    _vm.selectedTab && !_vm.showReadyToLaunchGroups ? 1 : 0
                  )}}},[_c('rb-icon',{staticClass:"rb-icon--medium u-cursor-pointer u-spacing-mr-s u-color-grey-lighter",attrs:{"data-cy":"editIcon","icon":'pencil'}}),_c('div',{attrs:{"data-cy":"editText"}},[_vm._v("Edit")])],1)]),(_vm.selectedTab && !_vm.showReadyToLaunchGroups)?_c('div',{staticClass:"u-color-orange-base u-font-size-6 u-font-weight-600 u-spacing-pt-s"},[_vm._v(" 1 Action Pending ")]):_vm._e()])])})],2)]),_c('Footer',{staticClass:"u-spacing-pt-l",attrs:{"show-right-btn":false},on:{"onClickBtnLeft":_vm.prevStep}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }