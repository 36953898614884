<template>
  <div>
    <div
      class="u-display-flex u-border-radius-s u-width-40 u-flex-direction-column u-border-all u-border-color-grey-xxx-light u-spacing-p-l"
    >
      <span
        class="u-font-size-6 u-font-weigth-600 u-color-grey-mid-light"
        data-cy="adGroupNamelabel"
        >Ad Group Name</span
      >
      <div class="u-spacing-mt-s">
        <rb-input
          v-model="adgroupName"
          @input="updatedAdgroupName"
        />
        <FormErrorMessage
          :show-error="showError"
          :error-text="errorText"
        />
      </div>
    </div>
    <section class="u-display-flex u-spacing-mt-s" />
  </div>
</template>

<script>
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';

export default {
  components: {
    FormErrorMessage
  },
  props: {
    defaultValue: {
      type: String,
      default: 'Adgroup 1'
    },
    showError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: 'Error'
    }
  },
  data: () => ({
    adgroupName: 'Adgroup 1'
  }),
  watch: {
    defaultValue(newVal) {
      this.adgroupName = newVal;
    }
  },
  created() {
    this.adgroupName = this.defaultValue;
  },
  methods: {
    updatedAdgroupName() {
      this.$emit('adgroupUpdated', this.adgroupName);
    }
  }
};
</script>

<style lang="css" scoped>
.fit-content {
  width: fit-content !important;
}
</style>
