const commonConfigRetailers = ['amazon', 'amazon_3p'];

const getCubeName = (retailer) => {
  const baseName = 'audit_logger';
  const retailerSuffix = !commonConfigRetailers.includes(retailer)
    ? `${retailer}_custom`
    : 'custom';
  return `${baseName}_${retailerSuffix}_strategy_builder`;
};

const generateConfig = (retailer, configType) => {
  const baseConfig = {
    cubeName: getCubeName(retailer),
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false
  };

  const commonMeasuresList = ['action_count', 'row_num'];
  const commonGroupByDimensionsList = [
    'run_date',
    'entity',
    'entity_type',
    'campaign_name',
    'action',
    'condition',
    'strategy',
    'user_name',
    'action_type',
    'previous_value',
    'current_value',
    'scope',
    'source',
    'suggested_action',
    'campaign_type',
    'action_group',
    'match_type',
    'run_id',
    'status',
    ...(commonConfigRetailers.includes(retailer) ? ['objective'] : [])
  ];
  const commonOrderByList = [
    {
      dimension: 'run_date',
      direction: 'DESC'
    }
  ];

  const filterCardMeasuresList = ['action_count'];
  const filterCardGroupByDimensionsList = ['action_group'];

  if (configType === 'APIConfig') {
    return {
      ...baseConfig,
      measuresList: commonMeasuresList,
      groupByDimensionsList: commonGroupByDimensionsList,
      orderByList: commonOrderByList,
      limit: 20,
      page: 1
    };
  }

  if (configType === 'APIConfigFilterCards') {
    return {
      ...baseConfig,
      measuresList: filterCardMeasuresList,
      groupByDimensionsList: filterCardGroupByDimensionsList,
      orderByList: []
    };
  }
};

export default {
  APIConfig: {
    amazon: generateConfig('amazon', 'APIConfig'),
    instacart: generateConfig('instacart', 'APIConfig'),
    amazon_3p: generateConfig('amazon_3p', 'APIConfig')
  },
  APIConfigFilterCards: {
    amazon: generateConfig('amazon', 'APIConfigFilterCards'),
    instacart: generateConfig('instacart', 'APIConfigFilterCards'),
    amazon_3p: generateConfig('amazon_3p', 'APIConfigFilterCards')
  },
  cubeName: {
    amazon: getCubeName('amazon'),
    instacart: getCubeName('instacart'),
    amazon_3p: getCubeName('amazon_3p')
  }
};
