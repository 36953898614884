<template>
  <div>
    <div :class="containerClass">
      <div
        v-if="!loading"
        class="u-display-flex u-flex-justify-content-space-between u-flex-1"
      >
        <div class="u-display-flex u-flex-1 u-flex-align-items-center">
          <div
            v-if="rightIcon"
            class="u-font-size-4"
            :class="rightIconStyleClass"
          >
            <div>
              <rb-icon
                class="rb-icon--bp-custom u-spacing-mb-m"
                :icon="rightIcon"
                :style="rightIconStyle"
              />
            </div>
          </div>
          <div
            class="u-spacing-ph-s u-flex-1 u-display-flex u-flex-direction-column upload_box_title_container overflow_container"
            :class="titleStyleClasses"
          >
            <div
              class="u-font-weight-600 u-font-size-2"
              :title="title"
            >
              {{ title }}
            </div>
            <div class="u-spacing-pt-xs u-font-size-5">
              {{ subTitle }}
            </div>
          </div>
        </div>
        <div class="u-display-flex u-flex-1" />
        <div class="u-display-flex">
          <div
            v-for="(component, index) in innerComponents"
            :key="index"
          >
            <component
              :is="component.name"
              v-bind="component.props"
              v-on="component.events"
            />
          </div>
        </div>
      </div>
      <progress-bar
        v-if="loading"
        class="uploadbox"
        :file-name="loadingTitle"
        container-class=""
        :cancel-fn="cancelFn"
        :loading-text="loadingText"
      />
    </div>
    <div
      v-if="showStatus"
      :class="statusTextClasses"
    >
      <rb-icon
        :class="statusIconClasses"
        :icon="statusIcon"
      />
      <span v-html="statusText" />
    </div>
  </div>
</template>

<script>
import progressBar from '@/components/widgets/internalCatalogue/progressBar.vue';

export default {
  components: {
    progressBar
  },
  props: {
    containerClass: {
      type: String,
      default:
        'u-display-flex u-flex-align-items-center u-spacing-p-xl u-flex-justify-content-space-between u-bg-color-blue-base u-border-radius-s'
    },
    titleStyleClasses: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingTitle: {
      type: String,
      default: () => {
        return 'dummy upload text';
      }
    },
    loadingText: {
      type: String,
      default: ''
    },
    rightIconStyle: {
      type: String,
      default: ''
    },
    rightIconStyleClass: {
      type: String,
      default: 'u-color-grey-white'
    },
    title: {
      type: String,
      default: 'Default File Name'
    },
    subTitle: {
      type: String,
      default: ''
    },
    innerComponents: {
      type: Array,
      default() {
        return [];
      }
    },
    statusText: {
      type: String,
      default:
        'Please fix these errors to succesfully <span class="u-font-weight-600"> Overwrite Standard Internal Catalog </span> with the above file.'
    },
    statusTextClasses: {
      type: String,
      default: 'u-font-size-7 u-spacing-ml-s u-spacing-pt-m'
    },
    statusIcon: {
      type: String,
      default: 'cross'
    },
    statusIconClasses: {
      type: String,
      default: ''
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancelFn() {
      this.$emit('onCancel', event);
    }
  }
};
</script>

<style lang="css" scoped>
.hollow-button-download .rb-button--hollow {
  padding: 0px 24px;
  color: #007cf6 !important;
  border-color: #007cf6 !important;
}
.hollow-button-download .rb-button--hollow:hover {
  color: #0062c3 !important;
  border-color: #0062c3 !important;
}

.remove-hollow-button:hover {
  color: #007cf6;
}
.fix-errors-hollow-button:hover {
  border-color: #007cf6;
  color: #007cf6;
}
.overflow_container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style lang="css">
.upload_box_title_container {
  max-width: 320px;
}
.uploadbox .progress-bar {
  padding: 3.2rem;
}
.uploadbox .progress-bar .loader {
  height: 8px;
}
</style>
