<template>
  <div
    class="footer u-width-100 u-display-flex u-flex-align-items-center u-font-size-7"
  >
    <div class="u-display-flex u-spacing-mh-m breadCrumbs">
      <div
        v-for="(val, index) in urlEntities"
        class="u-display-flex u-text-case-title"
      >
        <!-- <span v-if="val.url !== undefined" v-bind:class="{ 'lastIndex': index === (urlEntities.length -1) }" :href="val.url">{{val.display}}</span> -->
        <span
          v-if="index === urlEntities.length - 1"
          class="lastIndex"
          >{{ val.display }}</span
        >
        <span
          v-if="index !== urlEntities.length - 1"
          @click="navigateToSection(val.url)"
          >{{ val.display }}</span
        >
        <span
          v-if="index < urlEntities.length - 1"
          class="u-spacing-mh-xs"
        >
          >
        </span>
      </div>
    </div>

    <!-- <div class="u-display-flex rightBlock u-spacing-pr-s">
      <div class="u-display-flex u-spacing-mh-s u-flex-align-items-center">
        <rb-icon class="rb-icon--small " :icon="'sync'"></rb-icon>
      </div>

      <div class="u-display-flex u-spacing-mh-s u-flex-align-items-center">
        <rb-icon class="u-color-green-base rb-icon--small" :icon="'check-fill-circle'"></rb-icon>
        <span class="u-spacing-ml-xs u-font-size-7">BMI: 6:00 AM PST, 10/25/2018</span>
      </div>

      <div class="u-display-flex u-spacing-mh-s u-flex-align-items-center">
        <rb-icon class="u-color-orange-base rb-icon--small" :icon="'warning-triangle'"></rb-icon>
        <span class="u-spacing-ml-xs">AVC: 5:43 AM PST, 10/21/2018</span>
      </div>

      <div class="u-display-flex u-spacing-mh-s u-flex-align-items-center">
        <rb-icon class="u-color-green-base rb-icon--small" :icon="'check-fill-circle'"></rb-icon>
        <span class="u-spacing-ml-xs u-font-size-7">AMS: 3:34 AM PST, 10/25/2018</span>
      </div>
    </div> -->
  </div>
</template>

<script>
// import Vue from 'vue'
export default {
  components: {},
  data() {
    return {
      urlEntities: []
    };
  },
  computed: {
    recommendationLabel() {
      const recommendationTypes = this.$store.getters.getRecommendationTypes;
      const recommendationType = this.$route.query.type;
      const detailObject =
        recommendationTypes.filter(
          (typeObject) => typeObject.recommendationType === recommendationType
        )[0] || {};
      return detailObject.recommendationLabel || '';
    }
  },
  watch: {
    $route(to, from) {
      this.getURLComponets();
    },
    recommendationLabel(to, from) {
      this.getURLComponets();
    }
  },
  created() {
    this.getURLComponets();
  },
  methods: {
    navigateToSection(value) {
      // this.$router.push(value);
    },
    getURLComponets() {
      this.urlEntities = [];
      // var recomUrl = '/recommendations?name=asin&type=asin&bc_recommendation_type=asin';
      var url = this.$route.fullPath.split('/');
      url.splice(0, 1);
      var prevUrlPath = '';
      for (var i = 0; i < url.length; i++) {
        var display = decodeURIComponent(url[i]).replace(/[-]/gi, ' ');
        var queryIndex = url[i].indexOf('?');

        if (
          this.urlEntities.length > 0 &&
          this.urlEntities[i - 1] !== undefined
        ) {
          prevUrlPath =
            this.urlEntities[i - 1].url === undefined
              ? ''
              : this.urlEntities[i - 1].url;
        }

        if (queryIndex > -1) {
          url[i] = url[i].split('?')[0];
          display = display.split('?')[0];
        }
        var urlPath = prevUrlPath + '/' + url[i];
        this.urlEntities.push({
          display: display,
          url: urlPath
        });
      }

      if (this.$route.path.indexOf('recommendations') > -1) {
        let displayName = this.recommendationLabel;
        if (this.$route.query.type === 'asin') displayName = 'SKUs';
        this.urlEntities.push({
          display: displayName,
          url: ''
        });
      }
    }
  }
};
</script>

<style lang="css">
.footer {
  background: #f5f7fa;
  height: 29px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-shadow: 0 -1px 4px 0 #e9eaeb;
}
.footer .breadCrumbs {
  color: #8b8f93;
}
.footer .breadCrumbs .lastIndex {
  color: #4b5158;
  font-weight: 600;
}
.footer .rightBlock {
  margin-left: auto;
  color: #6a7075;
}
</style>
