import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import { formatter, cubeDateFormat } from '@/utils/helpers/formatter.js';
import moment from 'moment';
import { isEqual } from 'lodash';

const initialState = () => {
  return {
    productDiagnosticsGraphData: {
      rows: [],
      load: false,
      error: false,
      noData: false
    },
    productDiagnosticsTableData: {
      rows: [],
      load: false,
      error: false,
      noData: false,
      totalRows: 0
    },
    productDiagnosticsSourceCardData: {
      rows: [],
      load: false,
      error: false,
      noData: false
    },
    productDiagnosticsActiveCardFilter: undefined,
    productDiagnosticsActiveReportType: [
      { title: 'Data Testing', key: 'DATA_TESTING' },
      { title: 'Cypress', key: 'CYPRESS' }
    ],
    productDiagnosticsCumulativeTestScores: {},
    productDiagnosticsHistoricalCumulativeTestScores: {},
    productDiagnosticsOldWhereClause: {},
    whereClause: {
      dimensionNameValueList: [],
      date: {}
    },
    productDiagnosticsMinMaxDate: { rows: {}, load: false, error: false }
  };
};

const state = initialState();

const getters = {
  getProductDiagnosticsFiltersSelected: (state) =>
    state.productDiagnosticsFiltersSelected,
  getProductDiagnosticsGraphData: (state) => state.productDiagnosticsGraphData,
  getProductDiagnosticsTableData: (state) => state.productDiagnosticsTableData,
  getProductDiagnosticsSourceCardData: (state) =>
    state.productDiagnosticsSourceCardData,
  getProductDiagnosticsActiveCardFilter: (state) =>
    state.productDiagnosticsActiveCardFilter,
  getProductDiagnosticsWhereClause: (state) => state.whereClause,
  getProductDiagnosticsCumulativeTestScores: (state) =>
    state.productDiagnosticsCumulativeTestScores,
  getProductDiagnosticsHistoricalCumulativeTestScores: (state) =>
    state.productDiagnosticsHistoricalCumulativeTestScores,
  getProductDiagnosticsActiveReportType: (state) =>
    state.productDiagnosticsActiveReportType,
  getProductDiagnosticsMinMaxDate: (state) => state.productDiagnosticsMinMaxDate
};

const mutations = {
  PRODUCT_DIAGNOSTIC_SET_CUMULATIVE_TEST_SCORES: (state, data) => {
    Vue.set(state, 'productDiagnosticsCumulativeTestScores', data);
  },
  PRODUCT_DIAGNOSTIC_SET_HISTORICAL_CUMULATIVE_TEST_SCORES: (state, data) => {
    Vue.set(state, 'productDiagnosticsHistoricalCumulativeTestScores', data);
  },
  PRODUCT_DIAGNOSTIC_SET_NEW_FILTER_DATE: (state, data) => {
    Vue.set(state, 'whereClause', data);
  },
  PRODUCT_DIAGNOSTICS_SET_NEW_CARD_FILTER: (state, data) => {
    Vue.set(state, 'productDiagnosticsActiveCardFilter', data);
  },
  PRODUCT_DIAGNOSTICS_SET_NEW_REPORT_TYPE_FILTER: (state, data) => {
    Vue.set(state, 'productDiagnosticsActiveReportType', data);
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_RESET: (state, data) => {
    Vue.set(state.productDiagnosticsSourceCardData, 'rows', []);
    Vue.set(state.productDiagnosticsSourceCardData, 'load', true);
    Vue.set(state.productDiagnosticsSourceCardData, 'error', false);
    Vue.set(state.productDiagnosticsSourceCardData, 'noData', false);
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_SUCCESS: (state, data) => {
    Vue.set(state.productDiagnosticsSourceCardData, 'rows', data);
    Vue.set(state.productDiagnosticsSourceCardData, 'load', false);
    Vue.set(state.productDiagnosticsSourceCardData, 'error', false);
    Vue.set(state.productDiagnosticsSourceCardData, 'noData', false);
    if (data.length === 0) {
      Vue.set(state.productDiagnosticsSourceCardData, 'noData', true);
    }
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_FAILURE: (state, data) => {
    Vue.set(state.productDiagnosticsSourceCardData, 'rows', data);
    Vue.set(state.productDiagnosticsSourceCardData, 'load', false);
    Vue.set(state.productDiagnosticsSourceCardData, 'error', true);
    Vue.set(state.productDiagnosticsSourceCardData, 'noData', false);
  },
  PRODUCT_DIAGNOSTICS_REPORT_SUCCESS: (state, data) => {
    Vue.set(state.productDiagnosticsTableData, 'rows', data);

    Vue.set(state.productDiagnosticsTableData, 'load', false);
    Vue.set(state.productDiagnosticsTableData, 'error', false);
    Vue.set(state.productDiagnosticsTableData, 'noData', false);
    Vue.set(state.productDiagnosticsTableData, 'totalRows', 0);
    if (data.length === 0) {
      Vue.set(state.productDiagnosticsTableData, 'noData', true);
    } else {
      Vue.set(
        state.productDiagnosticsTableData,
        'totalRows',
        data[0].auto_cubesdk_count || data[0].count
      );
    }
  },
  PRODUCT_DIAGNOSTICS_REPORT_RESET: (state, data) => {
    Vue.set(state.productDiagnosticsTableData, 'rows', []);
    Vue.set(state.productDiagnosticsTableData, 'load', true);
    Vue.set(state.productDiagnosticsTableData, 'error', false);
    Vue.set(state.productDiagnosticsTableData, 'noData', false);
    Vue.set(state.productDiagnosticsTableData, 'totalRows', 0);
  },
  PRODUCT_DIAGNOSTICS_REPORT_FAILURE: (state, data) => {
    Vue.set(state.productDiagnosticsTableData, 'rows', data);
    Vue.set(state.productDiagnosticsTableData, 'load', false);
    Vue.set(state.productDiagnosticsTableData, 'error', true);
    Vue.set(state.productDiagnosticsTableData, 'noData', false);
    Vue.set(state.productDiagnosticsTableData, 'totalRows', 0);
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_RESET: (state, data) => {
    Vue.set(state.productDiagnosticsGraphData, 'rows', []);
    Vue.set(state.productDiagnosticsGraphData, 'load', true);
    Vue.set(state.productDiagnosticsGraphData, 'error', false);
    Vue.set(state.productDiagnosticsGraphData, 'noData', false);
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_SUCCESS: (state, data) => {
    Vue.set(state.productDiagnosticsGraphData, 'rows', data);
    Vue.set(state.productDiagnosticsGraphData, 'load', false);
    Vue.set(state.productDiagnosticsGraphData, 'error', false);
    if (data.length === 0) {
      Vue.set(state.productDiagnosticsGraphData, 'noData', true);
    }
  },
  PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_FAILURE: (state, data) => {
    Vue.set(state.productDiagnosticsGraphData, 'rows', data);
    Vue.set(state.productDiagnosticsGraphData, 'load', false);
    Vue.set(state.productDiagnosticsGraphData, 'error', true);
    Vue.set(state.productDiagnosticsGraphData, 'noData', false);
  },
  PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_RESET: (state, data) => {
    Vue.set(state.productDiagnosticsMinMaxDate, 'rows', {});
    Vue.set(state.productDiagnosticsMinMaxDate, 'load', true);
    Vue.set(state.productDiagnosticsMinMaxDate, 'error', false);
  },
  PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_SUCCESS: (state, data) => {
    Vue.set(state.productDiagnosticsMinMaxDate, 'rows', data);
    Vue.set(state.productDiagnosticsMinMaxDate, 'load', false);
    Vue.set(state.productDiagnosticsMinMaxDate, 'error', false);
  },
  PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_FAILURE: (state, data) => {
    Vue.set(state.productDiagnosticsMinMaxDate, 'rows', data);
    Vue.set(state.productDiagnosticsMinMaxDate, 'load', false);
    Vue.set(state.productDiagnosticsMinMaxDate, 'error', true);
  },
  PRODUCT_DIAGNOSTIC_RESET_STATE: (state, data) => {
    Object.assign(state, data);
  }
};

function generateUniqueIds(apiData) {
  const convertedApiData = apiData.map((item) => {
    item.uniqueId = Math.random().toString(36);
    return item;
  });
  return convertedApiData;
}

const getTodaysCumulativeTestScores = (data) => {
  let totalSuccess = 0;
  let totalTested = 0;
  let totalFailed = 0;
  data.forEach((item) => {
    totalSuccess += item.total_success;
    totalFailed += item.total_failed;
    totalTested += item.total_success + item.total_failed;
  });
  const resArray = [];
  if (totalTested) {
    resArray.push({
      title: 'Successfull Test Runs',
      number: formatter(totalSuccess, 'numeric'),
      percentage: formatter((totalSuccess / totalTested) * 100, 'percent'),
      color: 'green-base'
    });
    resArray.push({
      title: 'Failed Test Runs',
      number: formatter(totalFailed, 'numeric'),
      percentage: formatter((totalFailed / totalTested) * 100, 'percent'),
      color: 'red-base'
    });
  }
  return resArray;
};

const addReportTypeFilters = (context) => {
  return context.state.productDiagnosticsActiveReportType.map(
    (reportTypeFIlter) => {
      return {
        dimensionName: 'test_category',
        dimensionValue: reportTypeFIlter.key
      };
    }
  );
};

const createSourceCardObjectArray = (data) => {
  const sourceCardArray = data.map((item) => {
    const obj = {};
    obj.sourceName = item.DIMENSION.source_name;
    obj.totalSuccess = item.RESULT.total_success;
    obj.totalFailed = item.RESULT.total_failed;
    obj.totalTested = obj.totalSuccess + obj.totalFailed;
    return obj;
  });
  return sourceCardArray;
};

const actions = {
  async productDiagnosticsMinMax(context) {
    try {
      context.commit('PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_RESET');
      const response = await HttpService.get(
        'PRODUCT_DIAGNOSTICS_MIN_MAX_DATE'
      );
      const { data } = response;
      const currentDate = cubeDateFormat(data.maxRunDate);
      const payload = { date: { to: currentDate, from: currentDate } };
      context.commit('PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_SUCCESS', data);
      context.commit('PRODUCT_DIAGNOSTIC_SET_NEW_FILTER_DATE', payload);
    } catch (e) {
      const currentDate = cubeDateFormat();
      const payloadMinMax = { maxRunDate: currentDate };
      context.commit('PRODUCT_DIAGNOSTIC_MIN_MAX_DATE_FAILURE', payloadMinMax);
      const payloadFilter = { date: { to: currentDate, from: currentDate } };
      context.commit('PRODUCT_DIAGNOSTIC_SET_NEW_FILTER_DATE', payloadFilter);
    }
  },
  setProductDiagnosticsDate(context, data) {
    context.commit('PRODUCT_DIAGNOSTIC_SET_NEW_FILTER_DATE', data);
  },
  resetProductDiagnosticsDashboardState(context) {
    const initialStateCopy = initialState();
    context.commit('PRODUCT_DIAGNOSTIC_RESET_STATE', initialStateCopy);
  },
  productDiagnosticsDownloadFile: async (context, data) => {
    const { filepath, type } = data;
    HttpService.post(
      'S3_FILE_DOWNLOAD',
      { filepath, type },
      { responseType: 'blob' }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const disposition =
          response.headers && response.headers['content-disposition'];
        let fileName = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        } else {
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  productDiagnosticsGraphDetails: async (context, data) => {
    try {
      const to = moment(
        state.productDiagnosticsMinMaxDate.rows.maxRunDate
      ).format('YYYY-MM-DD');
      const from = moment().subtract(7, 'd').format('YYYY-MM-DD');
      data.body.APIConfig.where = transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
      data.body.APIConfig.where.date = { to, from };
      delete data.body.APIConfig.page;
      const storeWhereClause = addReportTypeFilters(context);
      data.body.APIConfig.where.dimensionNameValueList = [
        ...data.body.APIConfig.where.dimensionNameValueList,
        ...storeWhereClause
      ];
      context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_RESET');
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig,
        header: data.body.header
      });
      if (!response.success) {
        context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_FAILURE', []);
      } else {
        const _aArray = transformer.mergeResultDimension(response.data, true);
        const historicalScore = getTodaysCumulativeTestScores(_aArray);
        context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_SUCCESS', _aArray);
        context.commit(
          'PRODUCT_DIAGNOSTIC_SET_HISTORICAL_CUMULATIVE_TEST_SCORES',
          historicalScore
        );
      }
    } catch (e) {
      context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_GRAPH_FAILURE', []);
    }
  },
  productDiagnosticsFetchCardDetails: async (context, data) => {
    try {
      data.body.APIConfig.where = transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
      data.body.APIConfig.where.date = context.state.whereClause.date;
      const storeWhereClause = addReportTypeFilters(context);
      data.body.APIConfig.where.dimensionNameValueList = [
        ...data.body.APIConfig.where.dimensionNameValueList,
        ...storeWhereClause
      ];
      delete data.body.APIConfig.page;
      context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_RESET');
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig,
        header: data.body.header
      });
      const newSourceCardData = createSourceCardObjectArray(response.data);
      const _aArray = transformer.mergeResultDimension(response.data, true);
      const cumulativeTestData = getTodaysCumulativeTestScores(_aArray);
      context.commit(
        'PRODUCT_DIAGNOSTIC_SET_CUMULATIVE_TEST_SCORES',
        cumulativeTestData
      );
      context.commit(
        'PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_SUCCESS',
        newSourceCardData
      );
    } catch (e) {
      context.commit('PRODUCT_DIAGNOSTICS_DASHBOARD_SOURCE_CARDS_FAILURE', []);
    }
  },
  updateProductDiagnosticsCardFilter: (context, data) => {
    context.commit('PRODUCT_DIAGNOSTICS_SET_NEW_CARD_FILTER', data);
  },
  updateProductDiagnosticsActiveReportFilter: (context, data) => {
    context.commit('PRODUCT_DIAGNOSTICS_SET_NEW_REPORT_TYPE_FILTER', data);
  },
  getProductDiagonosticsTableData: async (context, data) => {
    try {
      context.commit('PRODUCT_DIAGNOSTICS_REPORT_RESET', []);
      const storeWhereClause = addReportTypeFilters(context);
      if (context.state.productDiagnosticsActiveCardFilter) {
        storeWhereClause.push({
          dimensionName: 'source_name',
          dimensionValue: context.state.productDiagnosticsActiveCardFilter
        });
      }
      data.body.APIConfig.where = transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
      data.body.APIConfig.where.dimensionNameValueList = [
        ...data.body.APIConfig.where.dimensionNameValueList,
        ...storeWhereClause
      ];
      data.body.APIConfig.where.date = context.state.whereClause.date;
      const oldFilters = context.state.productDiagnosticsOldWhereClause;
      const newFilters = data.body.APIConfig.where;
      const isNewFilterEqualOldFilter = isEqual(oldFilters, newFilters);
      if (!isNewFilterEqualOldFilter) {
        data.body.APIConfig.page = 1;
      }
      context.state.productDiagnosticsOldWhereClause = newFilters;
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig,
        header: data.body.header
      });
      if (!response.success) {
        context.commit('PRODUCT_DIAGNOSTICS_REPORT_FAILURE', []);
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        _aArray = generateUniqueIds(_aArray);
        // hardcoded data
        // _aArray = transformer.mergeResultDimension(tableData.response.data);
        context.commit('PRODUCT_DIAGNOSTICS_REPORT_SUCCESS', _aArray);
      }
    } catch (e) {
      context.commit('PRODUCT_DIAGNOSTICS_REPORT_FAILURE');
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
