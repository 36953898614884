export const keywordRequest = {
  campaignId: ':campaignId',
  adGroupId: ':adGroupId',
  keywordText: ':keyword',
  matchType: ':matchType',
  bid: ':bid',
  state: ':keywordState',
  keywordId: ':keywordId'
};

let tempNegativeKeywordRequest = keywordRequest;
const { bid, ...anotherTempNegativeKeywordRequest } =
  tempNegativeKeywordRequest;

export const negativeKeywordRequest = anotherTempNegativeKeywordRequest;
export default {
  requestObject: {
    campaignId: ':campaignId',
    campaignType: ':campaignType',
    state: ':state',
    advertiserId: ':advertiserId',
    request: {
      campaignRequest: {
        name: ':campaignName',
        targetingType: ':campaignTargetingType',
        state: ':campaignState',
        startDate: ':campaignStartDate', // 2022-06-12
        endDate: ':campaignEndDate',
        totalBudget: ':totalBudget',
        dailyBudget: ':dailyBudget',
        budgetType: ':campaignBudgetType',
        advertiserId: ':advertiserId',
        campaignId: ':campaignId'
      },
      adGroupRequest: {
        campaignId: ':campaignId',
        name: ':adGroupName',
        state: 'enabled',
        adGroupId: ':adGroupId'
      },
      productRequest: [],
      keywordRequest: [],
      negativeKeywordRequest: [],
      creativeRequest: [],
      reviewRequest: {
        campaignId: ':campaignId',
        adGroupId: ':adGroupId',
        reviewId: null
      },
      campaignPlacementRequest: [],
      placementBidMultiplierRequest: [],
      platformBidMultiplierRequest: []
    }
  },
  keywordRequest: keywordRequest,
  negativeKeywordRequest: negativeKeywordRequest,
  productRequest: {
    sku: ':sku',
    state: ':productState',
    imageUrl: ':imageUrl',
    productUrl: ':productUrl',
    productTitle: ':productTitle',
    campaignId: ':campaignId',
    adGroupId: ':adGroupId',
    bid: ':productBid'
  },
  creativeRequest: {
    campaignId: ':campaignId',
    adGroupId: ':adGroupId',
    brandName: ':brandName',
    headline: ':headline',
    clickUrl: ':clickUrl',
    creativeId: ':creativeId',
    state: 'enabled'
  },
  placementInclusionInnerRequest: {
    campaignId: ':campaignId',
    placement: ':placementInclusionType', // Search Carousel, Item Buybox, Item Carousel
    status: ':placementInclusionTypeStatus' // excluded -> disabled, included - > enabled
  },
  placementBidMultiplierRequest: {
    campaignId: ':campaignId',
    placementType: ':bidMultiplierPlacementType', // Buy-Box, Search Ingrid
    multiplier: ':bidMultiplierPlacementValue'
  },
  platformBidMultiplierRequest: {
    campaignId: ':campaignId',
    platformType: ':bidMultiplierPlatformType', // Desktop, Mobile, App
    multiplier: ':bidMultiplierPlatformValue'
  }
};
