<template>
  <div
    v-if="shouldShowBanner"
    class="u-width-100 u-display-flex u-spacing-mt-m u-spacing-ph-l u-flex-align-items-center system-transparency-banner"
    :class="[getWrapperClass(), paddingcss]"
  >
    <div
      class="u-display-flex u-cursor-pointer u-position-relative u-spacing-mr-m"
    >
      <rb-icon
        class="rb-icon--small system-transparency-status-icon"
        :class="`u-color-${statusMap[overallStatus].color}-base`"
        :icon="statusMap[overallStatus].icon"
      />
      <system-transparency-icon
        width="15"
        height="15"
      />
    </div>
    <div
      v-if="incidentsGroupId.length"
      class="u-font-size-5 u-color-grey-base u-spacing-mr-s u-display-flex u-overflow-hidden u-max-width-70"
    >
      <span
        v-if="incidentsGroupId.length > 1"
        class="u-white-space-no-wrap u-overflow-hidden u-text-overflow-ellipsis"
      >
        We have identified
        <span
          v-if="incidentsPriorityCount.major > 0"
          class="u-font-weight-600"
          >{{ incidentsPriorityCount.major }} Key Issue<span
            v-if="incidentsPriorityCount.major > 1"
            >s</span
          ></span
        >
        <span
          v-if="
            incidentsPriorityCount.minor > 0 && incidentsPriorityCount.major > 0
          "
        >
          and
        </span>
        <span
          v-if="incidentsPriorityCount.minor > 0"
          class="u-color-grey-mid-light"
          >{{ incidentsPriorityCount.minor }} minor issue<span
            v-if="incidentsPriorityCount.minor > 1"
            >s</span
          ></span
        >, and we are working towards fixing them.</span
      >
      <span
        v-else
        class="u-overflow-hidden u-display-flex"
      >
        <span
          class="u-white-space-no-wrap u-overflow-hidden u-text-overflow-ellipsis"
        >
          {{ incidentDescription }}
        </span>

        <span
          class="u-color-grey-mid-light u-spacing-ph-s u-white-space-no-wrap"
        >
          ETA: {{ incidentEta }}</span
        ></span
      >
    </div>
    <div
      v-if="incidentsGroupId.length"
      class="u-display-flex u-flex-align-items-center u-color-blue-base u-cursor-pointer u-font-weight-600"
      @click="openDrawer"
    >
      <span class="u-font-size-5 u-spacing-mr-xs u-white-space-no-wrap"
        >View details</span
      >
      <rb-icon
        class="rb-icon--medium"
        :icon="'right-arrow'"
      />
    </div>
    <span
      class="u-spacing-ml-auto"
      @click="closeBanner"
    >
      <rb-icon
        :icon="'cross'"
        class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
      />
    </span>
  </div>
</template>

<script>
import {
  GET_SUMMARIZED_HEALTH_STATUS,
  UPDATE_IS_DRAWER_OPEN,
  STATUS_MAP,
  UPDATE_EXPANDED_HEALTH_GROUP_IDS,
  UPDATE_DATES,
  INCIDENT_PRIORITY,
  isTransparencyDashboardEnabled
} from '@/components/widgets/system-transparency/system-transparency-utils.js';
import SystemTransparencyIcon from '@/components/widgets/system-transparency/system-transparency-icon.vue';

export default {
  name: 'SystemTransparencyBanner',
  components: { SystemTransparencyIcon },
  props: {
    dates: {
      default: () => {
        return {};
      },
      type: Object
    },
    paddingcss: {
      type: [String],
      default: 'u-spacing-pv-xs'
    }
  },
  data() {
    return {
      statusMap: STATUS_MAP,
      showBanner: true,
      disableBanner: this.isBannerDisable()
    };
  },
  computed: {
    healthStatus() {
      return this.$store.getters[GET_SUMMARIZED_HEALTH_STATUS];
    },
    isEnabled() {
      const retailer = this.$store.getters.getRetailer;
      return isTransparencyDashboardEnabled(retailer);
    },
    incidentsGroupId() {
      const { data } = this.healthStatus;
      const incidents = data.active_page_incidents || [];
      return incidents.map((incident) => incident.health_group_id);
    },
    overallStatus() {
      const { data } = this.healthStatus;
      return data.overall_status;
    },
    hasMajorIncident() {
      const incidents = this.healthStatus.data.active_page_incidents || [];
      return incidents.some(
        (incident) => incident.priority === INCIDENT_PRIORITY.MAJOR
      );
    },
    incidentsPriorityCount() {
      const incidents = this.healthStatus.data.active_page_incidents || [];
      return {
        major: incidents.filter(
          (incident) => incident.priority === INCIDENT_PRIORITY.MAJOR
        ).length,
        minor: incidents.filter(
          (incident) => incident.priority !== INCIDENT_PRIORITY.MAJOR
        ).length
      };
    },
    incidentDescription() {
      const { data } = this.healthStatus;
      const incidents = data.active_page_incidents || [];
      if (incidents.length === 1) {
        return incidents[0].description;
      } else {
        return '';
      }
    },
    incidentEta() {
      const { data } = this.healthStatus;
      const incidents = data.active_page_incidents || [];
      if (incidents.length === 1) {
        return incidents[0].eta;
      } else {
        return '';
      }
    },
    shouldShowBanner() {
      return (
        this.incidentsGroupId.length > 0 &&
        this.isEnabled &&
        !this.disableBanner
      );
    },
    bannerClass() {
      return this.hasMajorIncident
        ? 'system-transparency-banner-with-major-issues'
        : 'u-bg-color-grey-xxx-light';
    }
  },
  //
  watch: {
    dates: {
      handler(newValue, oldValue) {
        const { from, to } = newValue;
        if (
          from !== oldValue?.from ||
          (to !== oldValue?.to && from && to && this.isEnabled)
        ) {
          this.$store.dispatch(UPDATE_DATES, { from, to });
        }
      },
      immediate: true
    }
  },

  methods: {
    openDrawer() {
      this.$store.dispatch(
        UPDATE_EXPANDED_HEALTH_GROUP_IDS,
        this.incidentsGroupId
      );
      this.$store.dispatch(UPDATE_IS_DRAWER_OPEN, true);
    },
    getWrapperClass() {
      return this.hasMajorIncident
        ? 'system-transparency-banner-with-major-issues'
        : 'u-bg-color-grey-xxx-light';
    },
    getIncidentPriorityText(isMajor) {
      return isMajor > 0 ? 'Key' : 'Minor';
    },
    closeBanner() {
      const pageKey = this.$route.meta.key;
      let td_banner_hide_config = {};
      const temp_config = localStorage.getItem('td_banner_hide_config');
      if (temp_config !== null) {
        td_banner_hide_config = JSON.parse(window.atob(temp_config));
      }
      td_banner_hide_config[pageKey] = true;
      localStorage.setItem(
        'td_banner_hide_config',
        window.btoa(JSON.stringify(td_banner_hide_config))
      );
      this.disableBanner = true;
    },
    isBannerDisable() {
      const td_banner_hide_config = localStorage.getItem(
        'td_banner_hide_config'
      );
      if (td_banner_hide_config === null) {
        return false;
      }
      return JSON.parse(window.atob(td_banner_hide_config))[
        this.$route.meta.key
      ];
    }
  }
};
</script>
<style lang="css" scoped>
.system-transparency-banner-with-major-issues {
  background: #ffefcf;
}
.system-transparency-banner {
  position: relative;
  top: -1.6rem;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.system-transparency-banner .system-transparency-status-icon {
  position: absolute;
  right: -7px;
  top: -6px;
}
</style>
