<template>
  <div>
    <div
      class="u-spacing-pt-l u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <div class="u-font-weight-600 u-font-size-3 u-color-grey-lighter">
        BUDGET PLAN OVERVIEW
      </div>
      <div>
        <div
          :style="'pointer-events:' + chartData.error ? 'none' : ''"
          class="u-display-flex u-width-100 u-flex-direction-row-reverse u-flex-wrap-yes"
        />
      </div>
    </div>
    <div
      class="u-spacing-pt-l u-font-weight-600 u-font-size-5 u-color-grey-lighter"
    >
      METRICS
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false
    }
  }
};
</script>
