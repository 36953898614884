<template>
  <div
    v-if="showBanner"
    class="banner execution-banner u-font-size-6 u-spacing-pv-s u-spacing-ph-l u-display-flex u-flex-justify-content-space-between"
  >
    <div v-if="isProspect">
      <div
        v-if="!isE2ERunning"
        class="u-display-flex u-flex-align-items-center"
      >
        <span>
          This client is determined as a Prospect and refreshed only once a
          month. Data was last refreshed on
          {{ executionDetails.lastExecutionTime }}, and the next refresh is
          scheduled for {{ executionDetails.nextExecutionTime }}. For more
          details, reach out to
          <a
            href="mailto:support@commerceiq.ai"
            class="u-color-blue-base"
            >support@commerceiq.ai</a
          >
        </span>
        <span>
          <rb-button
            :click-fn="refreshE2E"
            :text="buttonLabel"
            size="s"
            type="filled"
            class="u-spacing-mr-s u-color-grey-white"
          />
        </span>
      </div>
      <div v-else>
        This client is determined as a Prospect and refreshed only once a month.
        Data refresh is currently in progress. For more details, reach out to
        <a
          href="mailto:support@commerceiq.ai"
          class="u-color-blue-base"
          >support@commerceiq.ai</a
        >
      </div>
    </div>
    <div v-if="isInactive">
      This client is determined as Inactive and is no longer refreshed. For more
      details, reach out to
      <a
        href="mailto:support@commerceiq.ai"
        class="u-color-blue-base"
        >support@commerceiq.ai</a
      >
    </div>

    <div @click="hideBanner">
      <rb-icon
        icon="'cross'"
        class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
      />
    </div>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'E2EDetailsBanner',

  data() {
    return {
      showBanner: false,
      buttonLabel: 'Refresh Now',
      executionDetails: {
        clientId: null,
        clientName: null,
        region: null,
        lastExecutionTime: null,
        nextExecutionTime: null,
        subscriptionStatus: null,
        running: false
      }
    };
  },
  computed: {
    isE2ERunning() {
      return this.executionDetails.running;
    },
    isProspect() {
      return this.executionDetails.subscriptionStatus === 'PROSPECT';
    },
    isInactive() {
      return this.executionDetails.subscriptionStatus === 'INACTIVE';
    }
  },

  created() {
    let shouldShow = localStorage.getItem('showE2EBanner');
    if (shouldShow !== null && shouldShow === 'false') {
      shouldShow = false;
    } else {
      shouldShow = true;
    }

    if (!shouldShow) {
      this.showBanner = false;
      this.emitE2eBannerStatus();
    } else {
      this.getE2EExecutionDetails();
    }
  },
  methods: {
    emitE2eBannerStatus() {
      eventBus.$emit('e2eBannerStatus', this.showBanner);
    },
    hideBanner() {
      this.showBanner = false;
      localStorage.setItem('showE2EBanner', false);
    },
    refreshE2E() {
      this.buttonLabel = 'Refreshing...';
      this.triggerE2E();
    },

    async getE2EExecutionDetails() {
      try {
        const response = await HttpService.get('GET_E2E_DETAILS');
        this.executionDetails = response.data;
        if (
          (this.executionDetails.lastExecutionTime &&
            this.executionDetails.nextExecutionTime &&
            this.executionDetails.subscriptionStatus === 'PROSPECT') ||
          this.executionDetails.subscriptionStatus === 'INACTIVE'
        ) {
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
        this.emitE2eBannerStatus();
      } catch (error) {
        console.error(error);
      }
    },
    async triggerE2E() {
      try {
        const response = await HttpService.post('E2E_TRIGGER_ENDPOINT', {
          clientdetailsid: this.executionDetails.clientId,
          client: this.executionDetails.clientName,
          region: this.executionDetails.region
        });
        if (response.status === 200) {
          this.executionDetails.running = true;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.execution-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
.banner-with-negative-margin {
  margin: 0 -2.4rem;
}
</style>
