import common from './common';

const directives = {
  common
};

directives.install = (Vue, options = {}) => {
  for (const module in directives) {
    for (var directive in directives[module]) {
      if (directive && directive !== 'install') {
        Vue.directive(directive, directives[module][directive]);
      }
    }
  }
};

export default directives;
