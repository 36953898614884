import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import {
  keywordAdditionSuccessObject,
  fewKeywordAdditionFailureObject,
  keywordAdditionFailureObject,
  getSnackbarMessageObjectForKeywordAddition
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';

const APIConfig = {
  cubeName: 'instacart_campaigns_keyword_workbench',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['keyword', 'latest_cpc_bid', 'matching_type'],
  groupByDimensionsList: [
    'keyword',
    'keyword_id',
    'ad_group_id',
    'campaign_id'
  ],
  // orderByList: [{ dimension: 'keyword', direction: 'ASC' }],
  limit: 500,
  page: 1
};

export const spAddKeywords = (
  context,
  adGroupId,
  campaignType = 'searchBrandsAmplifier',
  LeftTableDefaultTab = null
) => {
  return {
    ui_component: 'customActionPanel',
    props: {
      staticHeaderText: 'Add Keywords',
      btnText: 'Custom',
      widgetsConfig: {
        config: (vueRef) => {
          const widgetConfigs = {
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              noRowsRightTable: 'Add Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
              // actionInfoText: 'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
            },
            left: {
              // altStore: 'instacartCampaignCreation/getKeywords',
              showTabs: false,
              tabs: {
                'Enter List': {
                  reqTemplate: {
                    key: ':key',
                    broad_bid: null,
                    exact_bid: null,
                    keyword_type: 'GENERIC',
                    phrase_bid: null,
                    recommended_keyword: ':uniqueWord',
                    keyword: ':uniqueWord',
                    match_type: 'Exact',
                    matchTypeToShow: null,
                    bid: '-',
                    newBid: null
                  },
                  onSkipFilter(
                    vueRef,
                    uniqueKeywords,
                    newDataArray,
                    keywordsExist
                  ) {
                    const reqTemplate =
                      vueRef.parentRef.config.left.tabs['Enter List']
                        .reqTemplate;
                    for (const uniqueWord of uniqueKeywords) {
                      const keywordRequestObject = cloneDeep(reqTemplate);
                      const key = uniqueWord;
                      if (!vueRef.rowWithSameKeyAlreadyPresent(key)) {
                        const data = {
                          ':key': key,
                          ':uniqueWord': uniqueWord
                        };

                        for (const key in data) {
                          deepReplaceObjectValues(
                            keywordRequestObject,
                            key,
                            data[key]
                          );
                        }
                        if (vueRef.parentRef?.leftCustomBid?.show) {
                          keywordRequestObject.newBid =
                            vueRef.parentRef?.suggestedCustomBidInput;
                        }
                        if (vueRef.isTarget) {
                          keywordRequestObject.recommended_target = `<b>Product:</b> ${uniqueWord}`;
                        }
                        newDataArray.push(keywordRequestObject);
                      } else {
                        keywordsExist = true;
                      }
                    }
                    return { newDataArray, keywordsExist };
                  },
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords',
                      emitTextInput: false,
                      entityCount: 200
                    },
                    events: {
                      updateTextInput(data) {
                        const keywords = data?.keywordsArray?.filter(
                          (item) => item.keyword.length <= 80
                        );
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        let minBid = 0.3;
                        let maxBid = 85;
                        if (customBid < minBid || customBid > maxBid) {
                          data?.ref?.$snackbar.open({
                            message: `Please add a bid between ${minBid} and ${maxBid}.`,
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          addToRightKeywordsTable.push({
                            suggestedBid: '-',
                            newBid: customBid,
                            keyword: item.keyword,
                            keywordToShow: item.keyword,
                            key: item.key,
                            match_type: item.match_type
                          });
                        }
                        const invalidKeywords = new Set();
                        const filteredInvalidKeywords = data?.keywordsArray
                          .filter(({ keyword }) => keyword.length > 80)
                          ?.map?.((item) => item.keyword);
                        filteredInvalidKeywords.forEach((keyword) => {
                          invalidKeywords.add(keyword);
                        });
                        if (invalidKeywords.size > 0) {
                          data?.ref?.$snackbar.open({
                            message:
                              'The length of the keyword(s) should be within 80 characters.',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                        data.enterListRef.keywordTextInput =
                          invalidKeywords.size
                            ? Array.from(invalidKeywords)?.join?.('\n')
                            : '';
                      }
                    }
                  },
                  hideFilterDropdown: true,
                  singleMatchTypeHeading: 'Exact',
                  bidValues: {
                    default: 0.3,
                    min: 0.3,
                    max: 85
                  },
                  subHeading: 'Default CPC Bid',
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              isClientSidePagination: true,
              table: 'left',
              meta: {
                type: 'table',
                adGroupId: adGroupId,
                localFilters: [
                  {
                    dimensionName: 'adgroup_id',
                    dimensionValue: context?.campaignInfo?.data?.adGroupId
                  }
                ]
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: {
                  page: 1,
                  limit: 200
                },
                columnDefs: []
              },
              headerText: 'Add Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              keepFetchedRightTableRows: true,
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                localFilters: [
                  {
                    dimensionName: 'ad_group_id',
                    dimensionValue: context.entityId,
                    operator: 'EQUAL_TO'
                  }
                ],
                updateRows: (vueRef, rows = []) => {
                  const newRows = [];
                  for (let item of rows) {
                    item.keywordToShow = item.keyword;
                    item.keyword = item.keyword;
                    item.match_type = 'Exact';
                    item.key = item.keyword;
                    item.newBid = item.latest_cpc_bid;
                    item.disabled = true;
                    item.toolTipText =
                      'Update the bids for existing keywords from the Adjust bids action';
                    newRows.push(item);
                  }
                  vueRef.fetchedRightTableRows = {
                    rows: newRows,
                    totalRows: vueRef.rightTableData.totalRows
                  };
                  vueRef.rightTableRows = newRows;
                  vueRef.updateEntityInformation();
                },
                paginationAction: 'fetchTableDataExecuteApi'
              },
              isColumnDefAutoConfigure: true,
              body: {
                // useExistingWhereClause: true,
                APIConfig: {
                  ...cloneDeep(APIConfig),
                  customAPIDecisionVars: {
                    system: 'instacart_campaign_management'
                  }
                },
                columnDefs: keywordRightTable(vueRef)
              },
              headerText: () =>
                `Added Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              rows = rows.filter(
                (row) =>
                  vueRef.fetchedRightTableRows.rows.findIndex(
                    (fetchedRow) => fetchedRow.key === row.key
                  ) === -1
              );
              const adgroupId = context?.entityId;
              const campaignId = context.parentEntityId;
              const campaignType = context.entityConfig.entityType;
              return !rows.length
                ? vueRef.openSnackbar('No keywords were added/modified')
                : new Promise((resolve, reject) => {
                    const payload = rows.map((row) => ({
                      actionType: 'instacartAdGroupKeywordsAdd',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: campaignId,
                      actionPayload: {
                        campaignId: campaignId,
                        campaignType,
                        adgroup_id: adgroupId,
                        new_keyword_text: row.keyword,
                        new_match_type: row.match_type.toLowerCase(),
                        new_bid: row.newBid,
                        state: 'active',
                        advertiser_id: context?.$route?.query?.advertiser_id
                      },
                      viewPayload: {
                        campaignId,
                        campaignType,
                        adgroupId,
                        adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
                        new_keyword_text: row.keyword,
                        new_match_type: row.match_type,
                        new_bid: row.newBid
                      }
                    }));
                    HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                      .then((response) => {
                        if (response?.data?.success) {
                          const arrayToTraverse = response?.data?.response;
                          let messageObject =
                            getSnackbarMessageObjectForKeywordAddition(
                              arrayToTraverse,
                              {
                                allSuccessObject: keywordAdditionSuccessObject,
                                someFailedObject:
                                  fewKeywordAdditionFailureObject,
                                allFailedObject: keywordAdditionFailureObject
                              }
                            );
                          context.$snackbar.open(messageObject);
                        } else {
                          context.$snackbar.open(keywordAdditionFailureObject);
                        }
                        vueRef.$emit('afterAPIActionToCall');
                        resolve(true);
                      })
                      .catch((err) => {
                        context.$snackbar.open(keywordAdditionFailureObject);
                        reject(err);
                      });
                  });
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              // We filter out the already existing added/disabled keywords as we don't need to check their bids
              const filteredArrayToTraverseForBidCheck = arrayToTraverse.filter(
                (item) => !item?.disabled
              );
              for (const i in filteredArrayToTraverseForBidCheck) {
                const newBid = filteredArrayToTraverseForBidCheck[i]?.newBid;
                if (
                  !newBid ||
                  parseFloat(newBid) < 0.3 ||
                  parseFloat(newBid) > 85
                ) {
                  context.showErrorMessage =
                    'Bid should be between $0.3 and $85, both inclusive.';
                  return true;
                }
              }
              const addedKeywords = vueRef.computedRightTableRows.map(
                (item) => item?.keyword
              );
              const distinctKeyords = new Set();
              addedKeywords.forEach((keyword) => distinctKeyords.add(keyword));
              if (distinctKeyords.size > 200) {
                context.showErrorMessage =
                  'An Ad Group can have a maximum of 200 distinct keywords with 1-3 match types.';
                return true;
              }
              context.showErrorMessage = null;
              const selections = arrayToTraverse.filter(
                (row) =>
                  vueRef.fetchedRightTableRows.rows.findIndex(
                    (fetchedRow) => fetchedRow.key === row.key
                  ) === -1
              );
              return !vueRef.rightTableRows.length || !selections.length;
            }
          };
          if (LeftTableDefaultTab) {
            widgetConfigs.left.defaultTab = LeftTableDefaultTab;
          }
          return widgetConfigs;
        }
      }
    },
    events: {
      afterAPIActionToCall(payload) {
        context.showAddCustomKeywordPanel = false;
        context.reloadComponent(true);
      },
      closePanelMethod() {
        context.showAddCustomKeywordPanel = false;
      }
    }
  };
};

const keywordRightTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'newBid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '4',
        uiType: 'custom',
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'newBid',
          defaultValueColumnName: 'newBid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 100,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validation should happen here
            context.params.data.newBid = context.bidValue;
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '5',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};
