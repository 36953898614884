<template>
  <div>
    <div
      v-if="filterData"
      class="u-display-flex u-spacing-pv-xs u-border-right u-spacing-pl-s u-border-color-grey-xxx-light u-border-width-s u-spacing-mr-m u-spacing-mt-xs u-spacing-pr-s"
    >
      <div class="u-display-flex">
        <div class="u-spacing-pb-0 u-spacing-pr-xs">
          <rb-icon
            icon="filter"
            class="rb-icon--medium u-color-blue-base"
          />
        </div>
        <span
          class="u-color-grey-base u-display-inline-block u-spacing-mt-xxs u-font-size-6"
        >
          {{ filterData.prefix }}
        </span>
        <span
          class="u-color-grey-base u-display-inline-block u-spacing-mt-xxs u-font-weight-600 u-font-size-6 u-spacing-ml-xs"
        >
          {{ filterData.title }}</span
        >
        <span
          class="u-display-inline-block u-spacing-mt-xxs u-spacing-ml-xs u-font-size-6"
          >{{ filterData.suffix }}</span
        >
      </div>
      <div>
        <rb-button
          :click-fn="clearFilters"
          :type="'link'"
          class="clear-button u-width-100"
          :text="'Clear Filter'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterData: {
      type: Object,
      default: null
    }
  },
  methods: {
    clearFilters() {
      this.$emit('clearFilters', this.filterData);
    }
  }
};
</script>

<style lang="css" scoped>
.clear-button {
  height: 0;
  margin-top: 0.8rem;
}
</style>
