<template>
  <div
    class="u-font-size-5 u-display-flex u-flex-direction-column u-flex-align-items-center operations_excellence"
  >
    <div class="u-width-100 u-flex-1 u-spacing-p-l u-spacing-pb-0">
      <page-title
        :show-bookmark="false"
        :title="'Operation Excellence'"
      ></page-title>
    </div>
    <div class="u-spacing-mt-m">
      <div
        class="u-spacing-pv-m u-font-size-1 u-text-align-center u-font-weight-600 u-spacing-pt-0"
      >
        {{ clientName }}
      </div>
    </div>
    <div
      class="u-spacing-mb-m u-spacing-mt-m u-display-flex u-flex-align-items-center"
    >
      <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m">
        <span class="u-spacing-mr-s">Start Date:</span>
        <datepicker
          v-model="startDate"
          :disabled-dates="disabledDates"
          placeholder="Select Date"
        ></datepicker>
      </div>
      <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m">
        <span class="u-spacing-mr-s">End Date:</span>
        <datepicker
          v-model="endDate"
          :disabled-dates="disabledDates"
          placeholder="Select Date"
        ></datepicker>
      </div>
      <div>
        <rb-button
          :click-fn="submit"
          :text="'Submit'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s"
        />
      </div>
    </div>
    <section v-if="!isLoading">
      <!-- Chart gauge chart -->
      <div id="gaugeChart"></div>
      <table
        class="proxyLinksTable u-spacing-mb-xxxxl"
        aria-label="A table which shows the service and it's corresponding url"
      >
        <thead>
          <tr>
            <th>Metric</th>
            <th>Weightage</th>
            <th>Value</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody class="u-spacing-pb-xl">
          <tr>
            <td>Performance Acheived vs Goal</td>
            <td>{{ performanceGoal.weightage }}%</td>
            <td>{{ performanceGoal.value }}</td>
            <td>
              <span
                :class="
                  performanceGoal.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-red-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ performanceGoal.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Budget Pacing/Utilisation</td>
            <td>{{ budgetPacing.weightage }}%</td>
            <td>{{ budgetPacing.value }}</td>
            <td>
              <span
                :class="
                  budgetPacing.score == '0'
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-green-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ budgetPacing.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Percent time In Budget</td>
            <td>{{ percentageTimeInBudget.weightage }}%</td>
            <td>{{ percentageTimeInBudget.value }}</td>
            <td>
              <span
                :class="
                  percentageTimeInBudget.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ percentageTimeInBudget.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Wasted Ad spend %</td>
            <td>{{ wastedAdSpend.weightage }}%</td>
            <td>{{ wastedAdSpend.value }}</td>
            <td>
              <span
                :class="
                  wastedAdSpend.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-green-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ wastedAdSpend.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>
              Campaign Taxonomy, SKU Taxonomy and Standard report creation
            </td>
            <td>{{ standardReporting.weightage }}%</td>
            <td>{{ standardReporting.value }}</td>
            <td>
              <span
                :class="
                  standardReporting.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-red-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ standardReporting.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Generic Ad Spend %</td>
            <td>{{ genericAdSpend.weightage }}%</td>
            <td>{{ genericAdSpend.value }}</td>
            <td>
              <span
                :class="
                  genericAdSpend.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ genericAdSpend.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Exact match type Ad spend %</td>
            <td>{{ exactAdSpend.weightage }}%</td>
            <td>{{ exactAdSpend.value }}</td>
            <td>
              <span
                :class="
                  exactAdSpend.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ exactAdSpend.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Added new keywords (last 2 weeks)</td>
            <td>{{ addedTwoKeywords.weightage }}%</td>
            <td>{{ addedTwoKeywords.value }}</td>
            <td>
              <span
                :class="
                  addedTwoKeywords.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-red-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ addedTwoKeywords.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Negated search terms (last 2 weeks)</td>
            <td>{{ negatedTwoKeywords.weightage }}%</td>
            <td>{{ negatedTwoKeywords.value }}</td>
            <td>
              <span
                :class="
                  negatedTwoKeywords.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-red-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ negatedTwoKeywords.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Campaign Optimiser</td>
            <td>{{ campaignOptimizer.weightage }}%</td>
            <td>{{ campaignOptimizer.value }}</td>
            <td>
              <span
                :class="
                  campaignOptimizer.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-green-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ campaignOptimizer.score }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Hourly bidder</td>
            <td>{{ hourlyBidder.weightage }}%</td>
            <td>{{ hourlyBidder.value }}</td>
            <td>
              <span
                :class="
                  hourlyBidder.score == 0
                    ? 'u-bg-color-red-base'
                    : 'u-bg-color-green-base'
                "
                class="u-bg-color-green-base u-color-grey-white u-spacing-p-s u-display-block u-width-40 u-text-align-center"
                >{{ hourlyBidder.score }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div
      v-if="isLoading"
      class="u-spacing-mt-xxxxl"
    >
      <loader
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>

    <br />
    <br />
    <br />
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import loader from '@/components/basic/loader.vue';
import c3 from 'c3';
import Vue from 'vue';
import {
  BudgetPacingAPI,
  WastedAdSpendAPI,
  GenericAdSpendAPI,
  ExactAdSpendAPI,
  PercentTimeInBudgetAPI,
  PerformanceAPI,
  TaxomonyAPI
} from '@/components/pages/ciq-internal-tools/operation_excellence/metricsPayload.js';
import moment from 'moment-timezone';

export default {
  name: 'OperationExcellence',
  components: { datepicker, loader },
  data() {
    return {
      isLoading: false,
      clientName: window.clientName,
      startDate: moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
      disabledDates: {
        from: this.getEndOfLastWeek()
      },
      chart: '',
      performanceGoal: {
        goal: 25,
        weightage: 25,
        value: 0,
        score: 0
      },
      budgetPacing: {
        weightage: 15,
        value: 0,
        score: 0
      },
      wastedAdSpend: {
        weightage: 10,
        value: 0,
        score: 0
      },
      genericAdSpend: {
        weightage: 5,
        value: 0,
        score: 0
      },
      exactAdSpend: {
        weightage: 5,
        value: 0,
        score: 0
      },
      addedTwoKeywords: {
        weightage: 5,
        value: 5,
        score: 5
      },
      negatedTwoKeywords: {
        weightage: 5,
        value: 5,
        score: 5
      },
      campaignOptimizer: {
        weightage: 5,
        value: 5,
        score: 5
      },
      hourlyBidder: {
        weightage: 5,
        value: 5,
        score: 5
      },
      standardReporting: {
        weightage: 10,
        value: 0,
        score: 0
      },
      percentageTimeInBudget: {
        weightage: 10,
        value: 0,
        score: 0
      }
    };
  },
  mounted() {
    this.submit();
  },
  methods: {
    getEndOfLastWeek() {
      const currentDate = new Date();
      const oneWeekAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );
      const endOfLastWeek = new Date(
        oneWeekAgo.getFullYear(),
        oneWeekAgo.getMonth(),
        oneWeekAgo.getDate() + (7 - oneWeekAgo.getDay())
      );
      return endOfLastWeek;
    },
    async submit() {
      this.isLoading = true;
      console.log('submit');
      await PerformanceAPI({ from: this.startDate, to: this.endDate }).then(
        (res) => {
          let totalValue =
            res.data.entityData[0].data[0].PVP.PVP_ordered_product_sales;
          this.performanceGoal.value = totalValue;
          this.performanceGoal.score = 0;
          const threshold80 = 0.8 * this.performanceGoal.goal;
          const threshold60 = 0.6 * this.performanceGoal.goal;
          if (totalValue >= threshold80) {
            this.performanceGoal.score = 25;
          } else if (totalValue >= threshold60 && totalValue < threshold80) {
            this.performanceGoal.score = 15;
          } else {
            this.performanceGoal.score = 0;
          }
        }
      );
      await BudgetPacingAPI({ from: this.startDate, to: this.endDate }).then(
        (res) => {
          console.log(res);
          let budget = res.data?.response?.data?.[0]?.RESULT?.spend_vs_budget;
          this.budgetPacing.value = 0;
          this.budgetPacing.score = 0;
          if (budget) {
            this.budgetPacing.value = Number(budget).toFixed(2);
            budget = Number(budget).toFixed(2);
            if (budget >= 90 && budget <= 105) {
              this.budgetPacing.score = 15;
            } else if (budget >= 80 && budget <= 90) {
              this.budgetPacing.score = 10;
            } else {
              this.budgetPacing.score = 0;
            }
          }
        }
      );
      await WastedAdSpendAPI({ from: this.startDate, to: this.endDate }).then(
        (res) => {
          console.log(res, 'WastedAdSpendAPI');
          let totalAdSpendTotal =
            res[0].data?.response?.data?.[0]?.RESULT?.spend;
          let wastedAdSpendTotal =
            res[1].data?.response?.data?.[0]?.RESULT?.spend;
          let wastedAdSpend = (wastedAdSpendTotal / totalAdSpendTotal) * 100;
          this.wastedAdSpend.value = 0;
          this.wastedAdSpend.score = 0;
          if (wastedAdSpend) {
            this.wastedAdSpend.value = Number(wastedAdSpend).toFixed(2);
            wastedAdSpend = Number(wastedAdSpend).toFixed(0);
            if (wastedAdSpend <= 15) {
              this.wastedAdSpend.score = 10;
            } else if (wastedAdSpend >= 15 && wastedAdSpend <= 20) {
              this.wastedAdSpend.score = 5;
            } else {
              this.wastedAdSpend.score = 0;
            }
          }
        }
      );
      await GenericAdSpendAPI({ from: this.startDate, to: this.endDate }).then(
        (res) => {
          console.log(res, 'GenericAdSpendAPI GenericAdSpendAPI');
          let totalValue = res[0].data?.response?.data?.[0]?.RESULT?.spend;
          let zeroValue = res[1].data?.response?.data?.[0]?.RESULT?.spend;
          let wastedAdSpend = (zeroValue / totalValue) * 100;
          this.genericAdSpend.value = 0;
          this.genericAdSpend.score = 0;
          if (wastedAdSpend) {
            this.genericAdSpend.value = Number(wastedAdSpend).toFixed(2);
            wastedAdSpend = Number(wastedAdSpend).toFixed(0);
            if (wastedAdSpend >= 60) {
              this.genericAdSpend.score = 5;
            } else if (wastedAdSpend >= 30 && wastedAdSpend <= 60) {
              this.genericAdSpend.score = 2.5;
            } else {
              this.genericAdSpend.score = 0;
            }
          }
        }
      );
      await ExactAdSpendAPI({ from: this.startDate, to: this.endDate }).then(
        (res) => {
          console.log(res, 'WastedAdSpendAPI');
          let totalValue = res[0].data?.response?.data?.[0]?.RESULT?.spend;
          let zeroValue = res[1].data?.response?.data?.[0]?.RESULT?.spend;
          let wastedAdSpend = (zeroValue / totalValue) * 100;
          this.exactAdSpend.value = 0;
          this.exactAdSpend.score = 0;
          if (wastedAdSpend) {
            this.exactAdSpend.value = Number(wastedAdSpend).toFixed(2);
            wastedAdSpend = Number(wastedAdSpend).toFixed(0);
            if (wastedAdSpend >= 60) {
              this.exactAdSpend.score = 5;
            } else if (wastedAdSpend >= 30 && wastedAdSpend <= 60) {
              this.exactAdSpend.score = 2.5;
            } else {
              this.exactAdSpend.score = 0;
            }
          }
        }
      );
      await PercentTimeInBudgetAPI({
        from: this.startDate,
        to: this.endDate
      }).then((res) => {
        console.log(res, 'PercentTimeInBudgetAPI');
        let totalValue =
          res.data?.response?.data?.[0]?.RESULT?.percent_time_in_budget;

        this.percentageTimeInBudget.value = 0;
        this.percentageTimeInBudget.score = 0;
        if (totalValue) {
          this.percentageTimeInBudget.value = Number(totalValue).toFixed(2);
          totalValue = Number(totalValue).toFixed(2);
          if (totalValue >= 75) {
            this.percentageTimeInBudget.score = 10;
          } else if (totalValue >= 65 && totalValue <= 75) {
            this.percentageTimeInBudget.score = 5;
          } else {
            this.percentageTimeInBudget.score = 0;
          }
        }
      });
      await TaxomonyAPI().then((response) => {
        this.standardReporting.value = 0;
        this.standardReporting.score = 0;
        if (response?.[0].data.entityExists) {
          this.standardReporting.value += 5;
          this.standardReporting.score += 5;
        }
        if (response?.[1].data.entityExists) {
          this.standardReporting.value += 2.5;
          this.standardReporting.score += 2.5;
        }
        if (response?.[2].data?.tagList[0]?.tags) {
          let oneClickOnboardingDone = false;
          response?.[2].data?.tagList[0]?.tags.forEach((item) => {
            item.pageList.forEach((page) => {
              if (page.createdBy === 'onboarding@commerceiq.ai') {
                oneClickOnboardingDone = true;
              }
            });
          });
          if (oneClickOnboardingDone) {
            this.standardReporting.value += 2.5;
            this.standardReporting.score += 2.5;
          }
        }
      });
      this.isLoading = false;
      Vue.nextTick(() => {
        const total =
          this.performanceGoal.score +
          this.budgetPacing.score +
          this.wastedAdSpend.score +
          this.genericAdSpend.score +
          this.exactAdSpend.score +
          this.percentageTimeInBudget.score +
          this.negatedTwoKeywords.score +
          this.addedTwoKeywords.score +
          this.campaignOptimizer.score +
          this.hourlyBidder.score +
          this.standardReporting.score;
        const totalArray = ['data', total];
        c3.generate({
          bindto: '#gaugeChart',
          legend: {
            show: false
          },
          data: {
            columns: [totalArray],
            type: 'gauge'
          },
          color: {
            pattern: ['#FF0000', '#F97600', '#F6C600', '#60B044'], // the three color levels for the percentage values.
            threshold: {
              values: [30, 60, 90, 100]
            }
          },
          size: {
            height: 180
          }
        });
      });
    }
  }
};
</script>

<style lang="css">
.operations_excellence .vdp-datepicker input {
  display: block;
}
.appNameInputDiv {
  width: 800px;
}
.proxyLinksTable {
  width: 800px;
  text-align: left;
  border-spacing: 0;
}
.proxyLinksTable th,
.proxyLinksTable td {
  padding: 8px 8px;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}
.proxyLinksTable th:nth-child(1) {
  width: 500px;
  font-size: 18px;
}
.proxyLinksTable td:last-child {
  padding: 0px 0px;
  font-size: 16px;
}
</style>
