export const retailerMap = {
  amazon: 'budget_planner_dashboard_v2'
};

export const retailerPlaceHolder = {
  amazon: 'Search for profiles and portfolios'
};

export const retailerFiltersMap = {
  amazon: 'budgetPlanner-filters-v2'
};

export const pageWiseMinMaxKey = {
  amazon: 'budget_optimizer_dashboard'
};

export const cumulativeStepretailerMap = {
  amazon: 'profile'
};

export const previewStepretailerMap = {
  amazon: 'Profile:'
};

export const scopeItemsRetailerMap = {
  amazon: {
    scopeItems: [
      { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      { name: 'Profiles', key: 'PROFILE' },
      { name: 'Portfolios', key: 'PORTFOLIO' }
    ]
  }
};

export const tempCurrentScopeRetailerMap = {
  amazon: {
    ENTIRE_BUSINESS: 'Entire Business',
    PROFILE: 'Profiles',
    PORTFOLIO: 'Portfolios'
  }
};

export const retailerLevelSubStep = {
  amazon: {
    header: {
      title:
        'Would you like to include any specific events to adjust your budget plan?',
      desc: [
        'Add seasonality events such as Black Friday, Prime Day etc. at entire business, profile or portfolio level for specific time period.'
      ]
    }
  }
};

export const enableSpendPcogs = {
  amazon: true
};

export const budgetMetricsShown = {
  amazon: [
    { key: 'Planned Budget' },
    { key: 'Ad Spend' },
    { key: 'Spend / Budget' },
    { key: 'Spend / PCOGS' }
  ]
};

export const retailerMessage = {
  amazon: 'Amazon data is typically delayed by upto 24 hours.'
};

export const editHistoryRetailerConfig = {
  amazon: 'Profile'
};

export const multiRetailerConfig = {
  hideRestForSinglePortfolio: ['instacart'],
  disableCollapseForUncategorized: ['instacart']
};
