import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  getActiveColumns,
  rem1V2ConfigEnabled,
  CONSTANTS,
  getKeywordColumn,
  isAssortment,
  sovDcPayload
} from '@/components/pages/insights/amazon/cva/utils';
import { metricCellDisplay } from '@/components/pages/insights/amazon/market-share/molecules/customCell.js';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  searchTermsChild = {
    Organic: 'organic',
    Sponsored: 'sponsored'
  };

  columns = [];
  selectedTab = 'Organic';

  async fetchData(
    pageSettings,
    selectedTab,
    selectedFilter = CONSTANTS.defaultTrendSelection,
    dcName = 'combinedSovAssortment'
  ) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = sovDcPayload(this.clickedDimension, dcName, selectedFilter);
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  groupingColumn(columns) {
    let columnsGroupsCopy = JSON.parse(JSON.stringify(CONSTANTS.columnsGroup));
    columns.map((col) => {
      columnsGroupsCopy.map((group) => {
        if (group.key === CONSTANTS.columnGroupMapping[col.field]) {
          group.children.push(col);
        }
      });
    });
    return columnsGroupsCopy;
  }

  computeColumnDefs() {
    let columns = [
      {
        headerName: 'Search Term',
        key: 'search_term',
        field: 'search_term',
        toolTipText: 'Search term used by customers in the search bar'
      },
      getKeywordColumn('sov_search_frequency_rank_percentage_change'),
      {
        headerName: 'Your Share of Voice',
        field: 'your_organic_share_of_voice',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: `Your ${
            this.searchTermsChild[this.selectedTab]
          } share of voice for the search term`
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'your_organic_share_of_voice',
            data2: 'your_organic_share_of_voice_pvp',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data1_type: 'PERCENTAGE',
          data2_type: 'PERCENTAGE'
        }
      },
      {
        headerName: 'Top Competitor',
        field: 'ORGANIC_COMP_BRAND',
        toolTipText: 'The top competitor brand for the search term',
        cellClass: 'u-text-transform-capitalize'
      },
      {
        headerName: 'Competitor Share of Voice',
        field: 'your_organic_comp_share_of_voice',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: `Top Competitor's ${
            this.searchTermsChild[this.selectedTab]
          } share of voice for the search term`
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'your_organic_comp_share_of_voice',
            data2: 'your_organic_comp_share_of_voice_pvp',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data1_type: 'PERCENTAGE',
          data2_type: 'PERCENTAGE'
        }
      },
      {
        headerName: 'Your Sponsored Share of Voice',
        field: 'your_sponsored_share_of_voice',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: `Your ${
            this.searchTermsChild[this.selectedTab]
          } share of voice for the search term`
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'your_sponsored_share_of_voice',
            data2: 'your_sponsored_share_of_voice_pvp',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data1_type: 'PERCENTAGE',
          data2_type: 'PERCENTAGE'
        }
      },
      {
        headerName: 'Top Sponsored Competitor',
        field: 'SPONSORED_COMP_BRAND',
        toolTipText: 'The top competitor brand for the search term',
        cellClass: 'u-text-transform-capitalize'
      },
      {
        headerName: 'Competitor Sponsored Share of Voice',
        field: 'your_sponsored_comp_share_of_voice',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: `Top Competitor's ${
            this.searchTermsChild[this.selectedTab]
          } share of voice for the search term`
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'your_sponsored_comp_share_of_voice',
            data2: 'your_sponsored_comp_share_of_voice_pvp',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data1_type: 'PERCENTAGE',
          data2_type: 'PERCENTAGE'
        }
      }
    ];

    columns = getActiveColumns(
      columns,
      isAssortment(this.clickedDimension.name)
        ? 'assortment_strength_v2'
        : 'sov_v2'
    );
    columns = columns.map(getColumnDefinition);

    if (!isAssortment(this.clickedDimension.name)) {
      this.columns = this.groupingColumn(columns);
    } else {
      this.columns = columns;
    }
  }

  getTableData() {
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        previousVal.sov_dc_search_frequency_rank_pvp_invertTag = true;
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
