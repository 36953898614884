<template>
  <div class="u-height-100">
    <div
      class="u-font-size-5 u-spacing-ph-m u-spacing-pt-m u-display-flex u-flex-align-items-center"
    >
      <span
        >Data last updated at :
        <span class="u-font-weight-600">{{ last_updated }}</span></span
      >
      <span
        class="u-spacing-pl-m u-cursor-pointer"
        @click="refreshData"
      >
        <rb-icon
          class="rb-icon--medium"
          :icon="'sync'"
        />
      </span>
    </div>
    <div class="u-display-flex">
      <div
        class="card u-font-size-5 u-spacing-m-m"
        style="min-height: 75px"
      >
        <div class="u-font-weight-600 u-spacing-p-m">
          Summary of Recommendations run
        </div>
        <div v-if="recsSummary.total === undefined">
          <loader
            class="fill--parent u-bg-color-grey-white"
            :color="'#007cf6'"
          />
        </div>
        <div
          v-else-if="recsSummary.succeeded === recsSummary.total"
          class="u-spacing-p-m u-bg-color-green-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"
        >
          <rb-icon
            class="rb-icon--x-large"
            :icon="'check'"
          />
          <span
            >{{ recsSummary.succeeded }} /
            {{ recsSummary.total }} completed</span
          >
        </div>
        <div
          v-else-if="recsSummary.failed === recsSummary.total"
          class="u-spacing-p-m u-bg-color-red-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"
        >
          <rb-icon
            class="rb-icon--x-large"
            :icon="'warning'"
          />
          <span>{{ recsSummary.failed }} / {{ recsSummary.total }} failed</span>
        </div>
        <div
          v-else
          class="u-spacing-ph-m u-spacing-pb-m"
        >
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-green-base u-font-weight-600':
                  recsSummary.succeeded > 0
              }"
              >{{ recsSummary.succeeded }}</span
            >
            / {{ recsSummary.total }} have succeeded
          </div>
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-orange-base u-font-weight-600': recsSummary.running > 0
              }"
              >{{ recsSummary.running }}</span
            >
            / {{ recsSummary.total }} are Running
          </div>
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-red-base u-font-weight-600': recsSummary.failed > 0
              }"
              >{{ recsSummary.failed }}</span
            >
            / {{ recsSummary.total }} have failed
          </div>
        </div>
      </div>
      <div
        class="card u-font-size-5 u-spacing-m-m"
        style="min-height: 75px"
      >
        <div class="u-font-weight-600 u-spacing-p-m">
          Summary of Insights run
        </div>
        <div v-if="insightsSummary.total === undefined">
          <loader
            class="fill--parent u-bg-color-grey-white"
            :color="'#007cf6'"
          />
        </div>
        <div
          v-else-if="insightsSummary.succeeded === insightsSummary.total"
          class="u-spacing-p-m u-bg-color-green-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"
        >
          <rb-icon
            class="rb-icon--x-large"
            :icon="'check'"
          />
          <span
            >{{ insightsSummary.succeeded }} /
            {{ insightsSummary.total }} completed</span
          >
        </div>
        <div
          v-else-if="insightsSummary.failed === insightsSummary.total"
          class="u-spacing-p-m u-bg-color-red-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"
        >
          <rb-icon
            class="rb-icon--x-large"
            :icon="'warning'"
          />
          <span
            >{{ insightsSummary.failed }} /
            {{ insightsSummary.total }} failed</span
          >
        </div>
        <div
          v-else
          class="u-spacing-ph-m u-spacing-pb-m"
        >
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-green-base u-font-weight-600':
                  insightsSummary.succeeded > 0
              }"
              >{{ insightsSummary.succeeded }}</span
            >
            / {{ insightsSummary.total }} have succeeded
          </div>
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-orange-base u-font-weight-600':
                  insightsSummary.running > 0
              }"
              >{{ insightsSummary.running }}</span
            >
            / {{ insightsSummary.total }} are Running
          </div>
          <div class="u-spacing-pt-s">
            <span
              :class="{
                'u-color-red-base u-font-weight-600': insightsSummary.failed > 0
              }"
              >{{ insightsSummary.failed }}</span
            >
            / {{ insightsSummary.total }} have failed
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selections.length > 0"
      class="u-font-size-5 u-spacing-p-m"
    >
      <span>{{ retryConfirmationText }}</span>
      <span>
        <span
          class="u-spacing-p-s u-bg-color-blue-base u-color-grey-white u-border-radius-s u-spacing-mh-s u-cursor-pointer"
          @click="retryFailures"
          >Go Ahead</span
        >
        <span
          class="u-spacing-p-s u-bg-color-grey-white u-border-radius-s u-cursor-pointer"
          @click="cancelRetry"
          >Cancel</span
        >
      </span>
    </div>
    <rb-insights-table
      :table-row="rows"
      :table-column="columns"
      :row-selection="rowSelection"
      :row-height="45"
      :primary-key="'pipeline'"
      :enable-client-side-sorting="false"
      :enable-server-side-sorting="true"
      :pagination="true"
      :show-pagination-options="true"
      :row-class-rules="rowClassRules"
      :grid-options="gridOptions"
    />
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import linkDisplay from '@/components/globals/dataTable/tableComponentsWrapper/linkDisplay';
import moment from 'moment';

export default {
  components: {
    loader,
    linkDisplay
  },
  data() {
    var client = location.hostname.split('.')[0];
    client = client === 'data-pipeline' ? null : client;
    const filters = {
      clients: client === null ? undefined : [client]
    };
    return {
      client,
      filters,
      selections: [],
      retryConfirmationText: '',
      today: moment().format('YYYY-MM-DD'),
      last_updated: null,
      recsSummary: {},
      insightsSummary: {},
      rows: [],
      columns: [
        {
          headerName: 'Flow',
          field: 'flow',
          width: 350,
          pinned: 'left'
        },
        {
          headerName: 'Client name',
          field: 'client'
        },
        {
          headerName: 'Latest status',
          field: 'status',
          sortable: true
        },
        {
          headerName: 'Retry counts',
          field: 'retry_count',
          sortable: true
        },
        {
          headerName: 'Latest exec ID',
          field: 'latestExecID',
          cellRendererFramework: 'linkDisplay',
          cellRendererParams: {
            url: 'execution_link'
          }
        },
        {
          headerName: 'Elapsed time',
          field: 'elapsed_time',
          sortable: true
        }
      ],
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      gridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        },
        domLayout: 'normal'
      },
      rowClassRules: {}
    };
  },
  watch: {
    '$store.getters.getMigrateStatus': {
      handler: function (val) {
        if (!val.loading && val.dataFetched) {
          this.getExecutions('alert_email_workflow');
          this.getExecutions('insights_trigger_workflow');
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      if (!this.client) {
        document.querySelector('.u-sidebar').style.display = 'none';
      }
    }, 2000);
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.selections = [];
      this.rows = [];
      this.recsSummary = {};
      this.insightsSummary = {};

      this.getSummary('alert_email_workflow', 'recsSummary');
      this.getSummary('insights_trigger_workflow', 'insightsSummary');
      this.getExecutions('alert_email_workflow');
      this.getExecutions('insights_trigger_workflow');
    },
    cancelRetry() {
      this.retryConfirmationText = '';
      this.selections = [];
      if (this.gridOptions.api !== null) {
        this.gridOptions.api.deselectAll();
      }
    },
    retryFailures() {
      const data = {
        executions: this.selections
      };
      HttpService.post('PIPELINE', data, {
        append: '/retryExecutions'
      }).then((data) => {
        this.refreshData();
        if (this.gridOptions.api !== null) {
          this.gridOptions.api.deselectAll();
        }
      });
      console.log(data);
      this.retryConfirmationText = '';
    },
    onSelectionChanged(data) {
      var Ids = data.map((item) => {
        return item.data.latestExecID;
      });
      this.retryConfirmationText =
        'Do you want to retry : ' + Ids.join(', ') + ' ?';
      this.selections = data.map((item) => {
        var data = item.data;
        return {
          flow: data.flow,
          client: data.client,
          retailer: data.retailer,
          run_date: data.run_date,
          status: data.status,
          execution_links: [
            {
              id: data.execution_links[0]?.id,
              status: data.execution_links[0]?.status
            }
          ]
        };
      });
    },
    getSummary(flow, variable) {
      HttpService.post(
        'PIPELINE',
        {
          flow,
          run_date: this.today,
          refresh: true,
          filters: this.filters
        },
        {
          append: '/getSummary'
        }
      ).then((data) => {
        this.last_updated = data.data.last_update_time;
        this[variable] = data.data.summary;
      });
    },
    getExecutions(flow) {
      if (this.$store.getters.getMigrateStatus?.dataFetched === false) {
        return;
      }

      var filters = JSON.parse(JSON.stringify(this.filters));
      filters.status = ['FAILED'];

      const payload = {
        flow,
        run_date: this.today,
        refresh: true,
        filters
      };

      if (this.$store.getters.getMigrateStatus?.data) {
        payload.project = 'aramus-workflow-dbx';
      }

      HttpService.post('PIPELINE', payload, {
        append: '/getExecutions'
      }).then((data) => {
        this.last_updated = data.data.last_update_time;
        this.rows = this.rows.concat(data.data.executions);
        this.rows.map((item) => {
          item.latestExecID = item.execution_links[0].id;
          item.execution_link = item.execution_links[0].link;
        });
      });
    }
  }
};
</script>
