var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100 u-line-height-1"},[_c('rb-sku',{attrs:{"show-image":_vm.rbSkuData.showImage,"title":_vm.rbSkuData.title,"image-url":_vm.rbSkuData.imageUrl,"asin":_vm.rbSkuData.asin,"product-page-url":_vm.rbSkuData.productUrl,"enable-s-d-p":_vm.enableSDP},on:{"navigateToSDP":_vm.navigateToSDP}},[(!_vm.parentComponentInstance.showAllSpecificColumn)?_c('div',{staticClass:"u-display-flex u-font-size-7 u-text-overflow-ellipsis",attrs:{"slot":"sku-context"},slot:"sku-context"},[(_vm.params.data.recency == 0)?_c('span',[_vm._v(_vm._s(_vm.parentComponentInstance.$t('new')))]):_vm._e(),(
          _vm.params.data.recency !== 0 &&
          !(_vm.params && _vm.params.product && _vm.params.product !== 'MarketingIQ')
        )?_c('span',[_vm._v(_vm._s(_vm.params.data.recency)+" times")]):_vm._e(),(
          _vm.params.data.mean_estimate_value &&
          _vm.parentComponentInstance.showPerformanceOverview
        )?_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"u-color-blue-base",attrs:{"title":_vm.parentComponentInstance.$t('mean_estimate_value')}},[(
            _vm.params.data.recency == 0 ||
            !(_vm.params && _vm.params.product && _vm.params.product !== 'MarketingIQ')
          )?_c('span',[_vm._v(" · ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.params.data.mean_estimate_value,'currency'))+" ")]):_vm._e(),(_vm.params.data.cached_html_link)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions),expression:"tippyOptions"}],staticClass:"u-display-flex",attrs:{"title":_vm.$t('page_snapshot_tooltip')}},[(
            _vm.params.data.recency == 0 ||
            (_vm.params.data.mean_estimate_value &&
              _vm.parentComponentInstance.showPerformanceOverview) ||
            !(_vm.params && _vm.params.product && _vm.params.product !== 'MarketingIQ')
          )?_c('span',[_vm._v(" · ")]):_vm._e(),_c('a',{staticClass:"link--asin u-color-grey-lighter",attrs:{"href":_vm.params.data.cached_html_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Cached Page")])]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }