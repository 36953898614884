<template>
  <div>
    <div
      ref="trigger"
      v-tippy="tooltipConfig"
      class="expand-button button button--hollow u-display-flex u-flex-align-items-center"
      role="button"
      @click="toggle"
    >
      <rb-icon :icon="'clock'" />
      <span
        class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-ph-s"
      >
        <span class="u-color-grey-mid-light">
          {{ staticTimeText }}
        </span>
        <span
          v-if="allowCompare"
          class="u-font-size-8 u-spacing-pt-xxs"
        >
          <span class="u-color-grey-x-light">Compare to : </span>
          <span>
            {{ staticCompareToTimeText }}
          </span>
        </span>
      </span>
      <rb-icon
        :icon="'arrow-down'"
        class="rb-icon--x-small"
      />
    </div>
    <div
      :id="id"
      ref="dropdownMenu"
      class="u-display-flex u-flex-direction-column"
    >
      <div class="expanded-card u-display-flex">
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-center u-spacing-p-l u-border-width-s u-border-right u-border-color-grey-xxx-light"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-align-items-center u-spacing-mb-l"
          >
            <div class="u-font-weight-600 u-font-size-6 u-spacing-mb-m">
              {{ staticDateText }}
            </div>
            <time-range-picker-slider
              :start-time="startTime"
              :end-time="endTime"
              :time-range-options="timeRangeOptions"
              @endTimeUpdated="updateEndTime"
              @startTimeUpdated="updateStartTime"
            />
          </div>
          <div
            v-if="allowCompare"
            class="u-spacing-mt-l u-display-flex u-flex-direction-column u-flex-align-items-center u-spacing-mb-l"
          >
            <div class="u-font-weight-600 u-font-size-6 u-spacing-mb-m">
              {{ staticCompareToDateText }}
            </div>
            <time-range-picker-slider
              :start-time="compareToStartTime"
              :end-time="compareToEndTime"
              selected-range-color="#ffa800"
              :time-range-options="timeRangeOptions"
              @endTimeUpdated="updateCompareToEndTime"
              @startTimeUpdated="updateCompareToStartTime"
            />
          </div>
        </div>
        <div
          class="u-display-flex u-flex-direction-column u-spacing-p-l u-font-weight-normal u-flex-justify-content-space-between"
          style="min-width: 248px"
        >
          <div>
            <div
              class="u-color-blue-base u-font-weight-600 u-font-size-6 u-spacing-pb-s"
            >
              Time range
            </div>
            <TimeRangePickerDropdown
              :start-time="startTime"
              :end-time="endTime"
              :time-range-options="timeRangeOptions"
              :is-active="isActive"
              @endTimeUpdated="updateEndTime"
              @startTimeUpdated="updateStartTime"
            />
          </div>
          <div
            v-if="allowCompare"
            class=""
          >
            <div
              class="u-color-orange-base u-font-weight-600 u-font-size-6 u-spacing-pb-s"
            >
              Compare To
            </div>
            <TimeRangePickerDropdown
              :start-time="compareToStartTime"
              :end-time="compareToEndTime"
              :time-range-options="timeRangeOptions"
              :is-active="isActive"
              @endTimeUpdated="updateCompareToEndTime"
              @startTimeUpdated="updateCompareToStartTime"
            />
          </div>
          <div
            class="u-display-flex u-flex-justify-content-flex-end u-flex-align-items-center"
          >
            <span class="u-display-flex u-flex-align-items-center">
              <rb-button
                v-if="showReset"
                :click-fn="reset"
                :size="'s'"
                :text="'Reset'"
                :type="'hollow'"
                class="u-spacing-mr-s"
              />
              <rb-button
                :click-fn="submit"
                :size="'s'"
                :text="'Apply'"
                :type="'filled'"
              />
            </span>
          </div>
        </div>
      </div>
      <div
        class="u-font-size-7 u-color-blue-base u-spacing-pv-s u-spacing-ph-l u-border-width-s u-border-top u-border-color-grey-xxx-light u-display-flex u-flex-align-items-center u-font-weight-normal"
      >
        <rb-icon
          :icon="'info-circle-outline'"
          class="rb-icon--small u-spacing-pr-m"
        />
        Data available from
        <span
          class="u-font-weight-bold u-spacing-pl-xs"
          data-cy="date_text_at_bottom_of_calendar"
          >{{ formatTime(minTime) }} - {{ formatTime(maxTime) }}</span
        >. Hence the time selection can be done only within this range.
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import CONSTANTS from '@/utils/constants';
import TimeRangePickerSlider from '@/components/widgets/time/TimeRangePickerSlider.vue';
import TimeRangePickerDropdown from '@/components/widgets/time/TimeRangePickerDropdown.vue';
import {
  parseTime,
  parseDate,
  formatTime,
  formatDate
} from '@/utils/helpers/formatter';

export default {
  components: {
    TimeRangePickerSlider,
    TimeRangePickerDropdown
  },
  props: {
    allowCompare: {
      type: Boolean,
      default: true
    },
    showReset: {
      type: Boolean,
      default: true
    },
    localStorageKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isActive: false,
      useCompareTo: true,
      startTime: null,
      endTime: null,
      compareToStartTime: null,
      compareToEndTime: null,
      staticTimeText: '',
      staticCompareToTimeText: '',
      staticDateText: '',
      staticCompareToDateText: ''
    };
  },
  created() {
    this.initializeConstants();
    this.initialize();
  },
  methods: {
    initializeConstants() {
      this.id = 'timeRangePicker';
      this.tooltipConfig = {
        html: '#' + this.id,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      };
      this.timeRangeOptions = CONSTANTS.intradayTimeRangeOptions;
      this.minTime = this.parseTime(this.timeRangeOptions[0]);
      this.maxTime = this.parseTime(
        this.timeRangeOptions[this.timeRangeOptions.length - 1]
      );
    },
    initialize() {
      let localStoredTime = JSON.parse(
        localStorage.getItem(this.localStorageKey)
      )?.time_range;
      if (localStoredTime && !isEmpty(localStoredTime)) {
        this.startTime = this.parseTime(localStoredTime.startTime);
        this.endTime = this.parseTime(localStoredTime.endTime);
        this.compareToStartTime = this.parseTime(
          localStoredTime.compareToStartTime
        );
        this.compareToEndTime = this.parseTime(
          localStoredTime.compareToEndTime
        );
      } else {
        this.startTime = this.minTime;
        this.endTime = this.maxTime;
        this.compareToStartTime = this.minTime;
        this.compareToEndTime = this.maxTime;
      }
      this.staticTimeText = `${this.formatTime(
        this.startTime
      )} - ${this.formatTime(this.endTime)}`;
      this.staticCompareToTimeText = `${this.formatTime(
        this.compareToStartTime
      )} - ${this.formatTime(this.compareToEndTime)}`;
      let localStoredDate = JSON.parse(
        localStorage.getItem(this.localStorageKey)
      )?.date_range;
      if (localStoredDate && !isEmpty(localStoredDate)) {
        this.staticDateText = `${this.parseAndFormatDateString(
          localStoredDate.from
        )} - ${this.parseAndFormatDateString(localStoredDate.to)}`;
        this.staticCompareToDateText = `${this.parseAndFormatDateString(
          localStoredDate.compare_from
        )} - ${this.parseAndFormatDateString(localStoredDate.compare_to)}`;
      }
    },
    parseTime(timeString) {
      return parseTime(timeString, 'HH:mm:ss');
    },
    formatTime(time) {
      return formatTime(time, 'LT');
    },
    parseAndFormatDateString(dateString) {
      return formatDate(parseDate(dateString), 'MMM Do YYYY');
    },
    formatTimeForSubmit(time) {
      return formatTime(time.set({ seconds: 0 }), 'HH:mm:ss');
    },
    onShow() {
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
    },
    toggle() {
      this.isActive = !this.isActive;
      setTimeout(
        function () {
          this.initialize();
        }.bind(this),
        100
      );
    },
    updateStartTime(time) {
      this.startTime = time;
    },
    updateEndTime(time) {
      this.endTime = time;
    },
    updateCompareToStartTime(time) {
      this.compareToStartTime = time;
    },
    updateCompareToEndTime(time) {
      this.compareToEndTime = time;
    },
    reset: function () {
      this.initialize();
      this.$emit('reset');
    },
    submit: function () {
      const time = {
        startTime: this.formatTimeForSubmit(this.startTime),
        endTime: this.formatTimeForSubmit(this.endTime),
        compare: this.useCompareTo,
        compareToStartTime: this.formatTimeForSubmit(this.compareToStartTime),
        compareToEndTime: this.formatTimeForSubmit(this.compareToEndTime)
      };
      this.$emit('submit', time);
      this.$refs.trigger.click();
    }
  }
};
</script>

<style lang="css" scoped>
.expand-button {
  font-size: 1.1rem;
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  line-height: 1;
  vertical-align: top;
  font-family: inherit;
  border-radius: 4px;
  box-sizing: border-box;
  color: #8b8f93;
  border: 1px solid #e9eaeb;
  padding: 0 12px;
  background: #fff;
  height: 36px;
}
.expand-button:hover,
.expand-button:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}

.expanded-card {
  height: 240px;
}
</style>
