<template>
  <div
    class="u-display-flex u-flex-align-items-flex-start u-flex-justify-content-space-between u-spacing-pb-s"
  >
    <div>
      <div class="u-display-flex u-flex-align-items-center u-spacing-pb-xs">
        <div class="u-spacing-pr-xs u-font-size-5">
          <rb-icon
            icon="layer"
            class="rb-icon--medium u-cursor-pointer u-color-grey-lighter"
          />
        </div>
        <div class="u-color-grey-lighter u-font-size-5 u-font-weight-600">
          {{ bulkSearchPopupProps.title }}
        </div>
        <div
          v-tippy="tippyConfig"
          data-cy="bulkSearchInfoPanel"
          class="u-cursor-pointer u-font-size-5"
          title="Know more about bulk search"
          @click="openBulkSearchHelperPopup"
        >
          <rb-icon
            icon="help-fill"
            class="rb-icon--small u-color-blue-base u-spacing-pl-xs"
          />
        </div>
      </div>
      <div
        v-if="bulkSearchPopupProps.subTitle"
        class="u-color-grey-mid-light u-font-size-7"
      >
        Type / copy & paste {{ bulkSearchPopupProps.subTitle }} separated using
        <span class="u-font-weight-600">a new line</span>
      </div>
    </div>
    <div
      class="u-font-size-5"
      @click="$emit('closeSearchOverviewPopup')"
    >
      <rb-icon
        :icon="'cross'"
        class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  props: {
    bulkSearchPopupProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tippyConfig: {
        placement: 'bottom',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      }
    };
  },
  methods: {
    openBulkSearchHelperPopup() {
      eventBus.$emit('openBulkSearchHelperPopup');
    }
  }
};
</script>
