<template>
  <div
    class="u-width-100 u-spacing-mt-0"
    :class="chartId"
  >
    <chart-legends
      v-if="showMetrics"
      :fill-metric="enablePVPTimeseries"
      :metric-display-list="localMetricsShown"
      :metrics-list="computedMetricsList"
      :default-select-metric="defaultSelectMetric"
      :metric-config="metricConfig"
      :metric-data="metricData"
      :disable-add-new-metric="disableAddNewMetric"
      :remove-dropdown="removeDropdown"
      :minimum-metric="minimumMetric"
      :enable-legend-search="enableLegendSearch"
      :color-object="colorObject"
      :selected-metric-limit="enablePVPTimeseries ? 1 : selectedMetricLimit"
      :note-generator-metric-tooltip="noteGeneratorMetricTooltip"
      :emit-on-metric-default-change="emitOnMetricDefaultChange"
      :plot-on-y-axis-only="plotOnYAxisOnly"
      :default-legends="defaultLegends"
      :enable-watefall="enableWatefall"
      :additional-data="additionalData"
      :has-post-custom-metric="hasPostCustomMetric"
      :metric-font-size="metricFontSize"
      :hide-zero="hideZero"
      :show-big-tooltip="showBigTooltip"
      :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
      :modify-tooltip-message="modifyTooltipMessage"
      :show-legends-as-chips="showLegendsAsChips"
      :show-chart="showChart"
      :margin-top-large="marginTopLarge"
      :margin-top-small="marginTopSmall"
      :metric-tooltip-one-liner="metricTooltipOneLiner"
      :metric-tooltip-theme="metricTooltipTheme"
      :show-custom-tooltip="showCustomTooltip"
      :restrict-single-metric-deselection="restrictSingleMetricDeselection"
      :metric-top-padding-type="metricTopPaddingType"
      :metric-bottom-padding-type="metricBottomPaddingType"
      :chart-legend-post-tag-slot-names="chartLegendPostTagSlotNames"
      :show-metric-val-in-multiple-line="showMetricValInMultipleLine"
      :metric-size="metricSize"
      :show-icons-before-metric="showIconsBeforeMetric"
      :metric-icons="metricIcons"
      :total-entity-count="totalEntityCount"
      :metric-type="metricType"
      :show-metric-count="showMetricCount"
      @selectedList="metricChanged"
      @removedMetric="removedMetric"
      @addMetric="addMetric"
    >
      <div
        slot="post-legends"
        class="u-flex-1"
      >
        <slot name="post-legends" />
      </div>
      <div
        slot="customLegend"
        slot-scope="{ val }"
      >
        <slot
          name="customLegendForChart"
          :val="val"
        />
      </div>
      <div
        slot="customMetric"
        slot-scope="{ value }"
      >
        <slot
          name="postCustomMetric"
          :value="value"
        />
      </div>
      <template
        v-for="name in chartLegendPostTagSlotNames"
        v-slot:[name]
      >
        <slot :name="name"></slot>
      </template>
    </chart-legends>
    <chart-event-legends
      v-if="hasChartEvents"
      :disable-add-new-event="disableAddNewEvent"
      :metric-display-list="eventsShown"
      :metrics-list="eventsList"
      :default-select-metric="defaultSelectEvents"
      :selected-metric-limit="3"
      class=""
      @selectedList="eventsChanged"
    />
    <slot name="space-between-legend-and-chart" />
    <div
      v-show="showChart"
      class=""
      style="
        justify-content: center;
        display: flex;
        align-self: stretch;
        height: auto;
      "
    >
      <div
        v-show="!isChartLoading"
        data-cy="workbenchCampaignsChart"
        :class="[
          { 'u-width-100': true },
          { 'u-height-auto': true },
          { 'u-spacing-mt-l': showPaddingBetween },
          { 'u-position-relative': true }
        ]"
      >
        <div
          v-if="showPoweredByCIQText"
          class="u-color-grey-x-light u-font-size-7 u-position-absolute u-ciq-style u-font-weight-600"
          :style="{ right: selectedMetric.length === 2 ? '70px' : '12px' }"
        >
          Powered by <span class="u-color-grey-base">CommerceIQ</span>
        </div>
        <chart
          :id="chartId"
          :class="customChartClass"
          class="c3-large u-flex-1 chart_space_class"
          :style="{ height: chartHeight }"
          :config="chartConfig"
          :data="formattedChartData"
          :chart-change="chartChange"
          :is-p-v-p-timeseries-chart="enablePVPTimeseries"
          @pointClicked="pointClicked"
          @updateStroke="$emit('updateStroke')"
        />
      </div>
      <loader
        class="u-bg-color-grey-white u-spacing-mt-0"
        :loading="isChartLoading"
        :color="'#007cf6'"
        style="height: 450px"
      />
    </div>
    <legend-with-pvp
      v-if="showLegendWithPVP"
      :config="pvpLegendConfig"
    />
  </div>
</template>
<script>
// import Vue from 'vue';
import chart from './chart.vue';
import chartLegends from './chart-legends.vue';
import chartEventLegends from './chart-events-legends';
import widgetsUtil from '@/components/widgetMixin';
import loader from '@/components/basic/loader';
import legendWithPvp from './legend-with-pvp.vue';
import moment from 'moment';
import { isEmpty } from 'lodash';
export default {
  components: {
    chart,
    chartLegends,
    chartEventLegends,
    loader,
    legendWithPvp
  },
  mixins: [widgetsUtil],
  props: {
    showMetricCount: {
      type: Boolean,
      default: false
    },
    totalEntityCount: {
      type: Number,
      default: 0
    },
    metricType: {
      type: String,
      default: ''
    },
    showSeparatedRangeOnTooltip: {
      type: Boolean,
      default: true
    },
    pvpChartData: {
      type: Object,
      default: () => {}
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    },
    metricTooltipOneLiner: {
      type: Boolean,
      default: true
    },
    metricTooltipTheme: {
      type: String,
      default: 'dark'
    },
    showBigTooltip: {
      type: Boolean,
      default: false
    },
    showChart: {
      type: Boolean,
      default: true
    },
    chartHeight: {
      type: String,
      default: '100%'
    },
    defaultLegends: {
      type: Boolean,
      default: true
    },
    customChartClass: {
      type: Array,
      default: () => ['u-spacing-mt-l']
    },
    noteGeneratorMetricTooltip: {
      type: Function,
      default: () => {}
    },
    ignoreList: {
      type: Array,
      default: () => []
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    chartConfig: {
      type: Object,
      default: () => {}
    },
    defaultSelectMetric: {
      type: Array,
      default: () => []
    },
    metricsShown: {
      type: Array,
      default: () => []
    },
    eventsShown: {
      type: Array,
      default: () => []
    },
    eventsList: {
      type: Array,
      default: () => []
    },
    defaultSelectEvents: {
      type: Array,
      default: () => []
    },
    hasChartEvents: {
      type: Boolean,
      default: false
    },
    metricsList: {
      type: Array,
      default: () => []
    },
    metricColors: {
      type: Object,
      default: () => {}
    },
    disableAddNewMetric: {
      type: Boolean,
      default: false
    },
    removeDropdown: {
      type: Boolean,
      default: false
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    enableLegendSearch: {
      type: Boolean,
      default: true
    },
    colorObject: {
      type: Object,
      default: null
    },
    selectedMetricLimit: {
      type: Number,
      default: 2
    },
    heightRatio: {
      type: Number,
      default: 2.8
    },
    disableAddNewEvent: {
      type: Boolean,
      default: false
    },
    isChartLoading: {
      type: Boolean,
      default: false
    },
    emitOnMetricDefaultChange: {
      type: Boolean,
      default: false
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: false
    },
    showPoweredByCIQText: {
      type: Boolean,
      default: false
    },
    enableWatefall: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    metricFontSize: {
      type: String,
      default: 'u-font-size-5'
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    showLegendsAsChips: {
      type: Boolean,
      default: false
    },
    showMetrics: {
      type: Boolean,
      default: true
    },
    showPaddingBetween: {
      type: Boolean,
      default: true
    },
    marginTopLarge: {
      type: Boolean,
      default: true
    },
    marginTopSmall: {
      type: Boolean,
      default: false
    },
    enablePVPTimeseries: {
      type: Boolean,
      default: false
    },
    pvpMetricData: {
      type: Object,
      default: () => {}
    },
    pvpDateText: {
      type: String,
      default: ''
    },
    restrictSingleMetricDeselection: {
      type: Boolean,
      default: false
    },
    metricTopPaddingType: {
      type: String,
      default: 'l'
    },
    metricBottomPaddingType: {
      type: String,
      default: 'm'
    },
    showIconsBeforeMetric: {
      type: Boolean,
      default: false
    },
    metricIcons: {
      type: Object,
      default: () => {}
    },
    showMetricValInMultipleLine: {
      type: Boolean,
      default: false
    },
    chartLegendPostTagSlotNames: {
      type: Array,
      default: () => []
    },
    metricSize: {
      type: String,
      default: 'l'
    },
    chartCustomDataOrderFormatter: {
      // Can be used to change the order of chart data if required
      type: Function,
      default: () => {}
    },
    chartCustomDataHandler: {
      // Can be used to make any adjustments to the chart data using custom logic if required
      type: Function,
      default: (metricIndex, chartData, allChartMetrics) => {
        return chartData[metricIndex];
      }
    },
    useCustomChartMetricNames: {
      type: Boolean,
      default: false
    },
    hiddenMetrics: {
      // Metrics that are not part of chart-legends but are present in the chart
      type: Array,
      default: () => []
    },
    showHiddenMetricsOnlyWhenVisibleExist: {
      type: Boolean,
      default: true
    },
    metricsTooltipFormat: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      metricsSelectedIndex: [0, 1],
      chartWkbenchAxes: {},
      metricsSelected: [],
      formattedChartData: {},
      selectedMetric: [],
      selectedEvents: [],
      localMetricsShown: [...this.metricsShown],
      chartChange: 0,
      masterMetricList: [],
      masterColorObj: {},
      pvpLegendConfig: {}
    };
  },
  computed: {
    hasPostCustomMetric() {
      return !!this.$scopedSlots.postCustomMetric;
    },
    computedMetricsList() {
      if (this.metricsList.length > 0) {
        return this.metricsList;
      }
      var data = this.chartData.data;
      if (!data) {
        return [];
      }
      const returnArr = [];
      for (let i = 0; i < data.length; i++) {
        returnArr.push({
          title: data[i][0],
          key: data[i][0]
        });
      }
      // console.log('returnArr', returnArr);
      return returnArr.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    showLegendWithPVP() {
      return (
        this.enablePVPTimeseries &&
        !this.isChartLoading &&
        this.pvpLegendConfig.hasOwnProperty('metricConfig') &&
        this.pvpLegendConfig.hasOwnProperty('metricData')
      );
    }
  },
  watch: {
    metricsShown(newValue) {
      this.localMetricsShown = newValue;
    },
    chartData() {
      this.formatChartData(this.masterColorObj, this.masterMetricList);
    }
  },
  updated() {
    this.updateStrokedLines();
  },
  mounted() {
    // this.calculateChartSize();
    window.addEventListener('resize', this.handleResizeEvent);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeEvent);
  },
  created() {
    this.chartId = 'chartingWorkbenchId' + Math.round(Math.random() * 10000000);
  },
  methods: {
    handleResizeEvent() {
      // var chart = document.getElementById(this.chartId);
      // if (chart) {
      //   chart.style.height = ((chart.clientWidth || 320) / this.heightRatio) + 'px';
      //   // console.log(chart.style.height);
      //   this.chartChange++;
      // }
      const metricCardElement = document
        .querySelector(
          `.${this.chartId} .metric-card-hover-trigger .active-metric-card`
        )
        .closest('.metric-card-hover-trigger');
      metricCardElement.click();
      setTimeout(() => {
        metricCardElement.click();
      }, 2);
    },
    selectNewMetric(context, val) {
      // console.log(context, val)
      if (this.metricsSelectedIndex.indexOf(context[0]) === -1) {
        this.metricsSelectedIndex.shift();
        this.metricsSelectedIndex.push(context[0]);
        // Logic for showing 2 y axis. Controlled by passing prop plotOnYAxisOnly as true/false from root widget.
        if (this.plotOnYAxisOnly) {
          this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y';
        } else {
          this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y2';
        }
        // console.log(this.chartWkbenchAxes)
        this.isExpand = false;
      }
    },
    metricSelected(context, val) {
      if (this.localMetricsShown.indexOf(val[0].title) === -1) {
        this.localMetricsShown.splice(context[0], 1, val[0].title);
        this.selectNewMetric(context, [val[0].title]);
        this.isExpand = false;
      }
    },
    metricChanged(data) {
      this.$emit('selectedMetricList', data);
      this.selectedMetric = data.selectedMetric;
      const metircList = this.selectedMetric.concat(this.selectedEvents);
      this.formatChartData(data.metricColors, metircList);
    },
    removedMetric(data) {
      this.$emit('removedMetric', data);
    },
    addMetric(data) {
      this.$emit('addMetric', data);
    },
    eventsChanged(data) {
      this.selectedEvents = data.selectedMetric;
      const metircList = this.selectedMetric.concat(this.selectedEvents);
      this.formatChartData(data.metricColors, metircList);
      this.$emit('selectedList', data);
    },
    pointClicked(data) {
      this.$emit('pointClicked', data);
    },
    tooltipContentForPVPChart(value, currentColor, prevColor) {
      let [currentDate, prevDate] = value?.split('-') || ['', ''];
      if (this.selectedRollUp === 'WEEK') {
        [currentDate, prevDate] = [
          this.currentDateForWeek(currentDate),
          this.prevDateForWeek(prevDate)
        ];
      } else if (this.selectedRollUp === 'MONTH') {
        [currentDate, prevDate] = [
          this.currentDateForMonth(currentDate),
          this.prevDateForMonth(prevDate)
        ];
      } else {
        [currentDate, prevDate] = [
          this.defaultCurrentDate(currentDate),
          this.defaultPrevDate(prevDate)
        ];
      }

      let htmlText = '';

      if (currentDate && prevDate) {
        htmlText = `<span style="color:${currentColor}; display:block">${currentDate}</span>
          <span class="u-font-size-7 u-color-grey-lighter">Compared to <span style="color:${prevColor};">${prevDate}</span></span>`;
      } else if (currentDate) {
        htmlText = `<span style="color:${currentColor}; display:block">${currentDate}</span>`;
      } else if (prevDate) {
        htmlText = `<span style="color:${prevColor}; display:block">${prevDate}</span>`;
      }

      return htmlText;
    },
    formatChartData(metricColors, selectedMetric) {
      const axes = {};
      const data = [];
      this.masterMetricList = [...selectedMetric];
      this.masterColorObj = {
        ...this.masterColorObj,
        ...metricColors
      };
      // Fix for Color are not matching, https://boomerang.atlassian.net/browse/SDP-95
      const _metricColor = {};
      if (this.enablePVPTimeseries) {
        const indexOfMetric = this.chartData?.data?.findIndex((metric) => {
          return metric[0] === this.selectedMetric?.[0]?.key;
        });
        if (indexOfMetric === -1) return;
        const currentData = this.chartData?.data?.[indexOfMetric];
        const previousData = this.pvpChartData?.data?.[indexOfMetric];

        const currentTimeseries = [currentData[0] + ' - Current'];
        if (!previousData) return;
        currentTimeseries.push(...(currentData?.slice(1) || []));
        const prevTimeseries = [previousData[0] + ' - Previous'];
        prevTimeseries.push(...(previousData?.slice(1) || []));
        const data = [currentTimeseries, prevTimeseries];
        const currentLabel = currentData[0] + ' - Current';
        const prevLabel = previousData[0] + ' - Previous';

        _metricColor[currentLabel] = this.masterColorObj[currentData[0]];
        _metricColor[prevLabel] =
          this.getColorPattern.find(
            (el) => el !== _metricColor[currentLabel]
          ) || '#23b5d3';

        this.updatePVPLegendConfig(
          currentData[0],
          _metricColor[prevLabel],
          this.pvpMetricData[selectedMetric[0].key]
        );

        this.updatePVPTimeseriesTooltipForChart(
          currentData[0],
          currentLabel,
          prevLabel
        );

        if (this.showSeparatedRangeOnTooltip) {
          this.$set(this.chartConfig, 'tooltip_format_title', (val) => {
            return this.tooltipContentForPVPChart(
              categories[val],
              _metricColor[currentLabel],
              _metricColor[prevLabel]
            );
          });
        }

        // To add stroke classes to the second trendline
        this.updateStrokedLines();

        const categories = this.chartData?.data?.[0]
          ?.slice(1)
          ?.map((element, index) => {
            return (
              this.chartData?.data?.[0]?.[index + 1] +
              '-' +
              this.pvpChartData?.data?.[0]?.[index + 1]
            );
          });

        this.$set(this.chartConfig, 'xAxisCategories', categories);
        const obj = {
          data: data,
          colors: _metricColor,
          onclick: function (e) {
            alert(e.value);
          }
        };
        this.formattedChartData = {
          ...this.pvpChartData,
          ...obj
        };
      } else {
        const allChartMetrics = [...selectedMetric];
        if (
          !this.showHiddenMetricsOnlyWhenVisibleExist ||
          selectedMetric.length > 0
        ) {
          allChartMetrics.push(...this.hiddenMetrics);
        }
        this.chartCustomDataOrderFormatter(allChartMetrics);
        for (let i = 0; i < allChartMetrics.length; i++) {
          const metric = allChartMetrics[i];
          if (!this.useCustomChartMetricNames) {
            _metricColor[metric.key] = this.masterColorObj[metric.key];
          } else {
            /**
             * Chart Legends uses the resolved names. When using custom name for chart, chart uses the alias.
             * Hence getting the color from masterColorObj with key which contains the resolved name and assigning it to _metricColor using the alias
             */
            _metricColor[metric.alias] = this.masterColorObj[metric.key];
          }
          const chartAxesConfig = this.chartConfig?.chartOptions?.axes;
          if (this.plotOnYAxisOnly) {
            axes[metric.key] = i % 2 === 0 ? 'y' : 'y';
          } else if (!isEmpty(chartAxesConfig)) {
            if (this.useCustomChartMetricNames) {
              axes[metric.alias] = chartAxesConfig[metric.alias] || 'y';
            } else {
              axes[metric.key] = chartAxesConfig[metric.key] || 'y';
            }
          } else {
            axes[metric.key] = i % 2 === 0 ? 'y' : 'y2';
          }
          const indexOfMetric = !this.useCustomChartMetricNames
            ? this.chartData.data.findIndex((item) => {
                return item && item[0] === metric.key;
              })
            : this.chartData.data.findIndex((item) => {
                return item && item[0] === metric.alias;
              });
          if (indexOfMetric !== -1) {
            data.push(
              this.chartCustomDataHandler(
                indexOfMetric,
                this.chartData.data,
                allChartMetrics
              )
            );
          }
        }
        const timeseriesIndex = this.chartData.data.findIndex((item) => {
          return item[0] === this.chartConfig.chartOptions.timeseries;
        });
        if (timeseriesIndex !== -1) {
          data.push(this.chartData.data[timeseriesIndex]);
        }
        if (this.chartData.xs) {
          const xs = this.chartData.xs;
          for (const key in this.chartData.xs) {
            const xsSeries = this.chartData.data.findIndex((item) => {
              return item[0] === xs[key];
            });
            if (xsSeries !== -1) {
              data.push(this.chartData.data[xsSeries]);
            }
          }
        }
        const obj = {
          data: data,
          axes: this.chartData.axes ? this.chartData.axes : axes,
          colors: _metricColor,
          onclick: function (e) {
            alert(e.value);
          }
        };
        if (this.chartData?.types) {
          obj.types = this.chartData.types;
        }
        if (this.chartConfig?.groups) {
          obj.groups = this.chartConfig.groups;
        }
        if (this.chartConfig?.chartOptions?.names) {
          obj.names = this.chartConfig.chartOptions.names;
        }
        this.formattedChartData = {
          ...this.chartData,
          ...obj
        };
      }
    },
    updateStrokedLines() {
      // To make the PVP Timeseries trendline as stroked
      if (this.enablePVPTimeseries) {
        document.querySelectorAll('.c3-lines').forEach((el) => {
          if (el.classList.value.includes('Previous')) {
            el.classList.add('stroked-chart-lines');
          }
        });
      }
    },
    updatePVPLegendConfig(label, color, metricData, entityValue) {
      this.pvpLegendConfig = {
        color,
        entityValue,
        metricLabel: label,
        dateText: this.pvpDateText,
        metricConfig: this.metricConfig[label],
        metricData
      };
    },
    updatePVPTimeseriesTooltipForChart(metricLabel, currentLabel, prevLabel) {
      this.$set(
        this.chartConfig.chartOptions,
        'tooltip_label_text',
        metricLabel
      );

      const metricTooltipFormat = this.metricsTooltipFormat[metricLabel];

      this.$set(this.chartConfig.chartOptions, 'tooltip_format', {
        [currentLabel]: metricTooltipFormat,
        [prevLabel]: metricTooltipFormat
      });
    },
    currentDateForWeekGenerator(currentDate) {
      return (
        moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(currentDate, 'MM/DD/YYYY')
          .add(6, 'd')
          .isAfter(this.dateSelected.to)
          ? moment(this.dateSelected.to).format('MMM DD, YYYY')
          : moment(currentDate, 'MM/DD/YYYY')
              .add(6, 'd')
              .format('MMM DD, YYYY'))
      );
    },
    currentDateForWeek(currentDate) {
      return currentDate ? this.currentDateForWeekGenerator(currentDate) : '';
    },
    prevDateForWeekGenerator(prevDate) {
      return (
        moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(prevDate, 'MM/DD/YYYY')
          .add(6, 'd')
          .isAfter(this.dateSelected.pvpTo)
          ? moment(this.dateSelected.pvpTo).format('MMM DD, YYYY')
          : moment(prevDate, 'MM/DD/YYYY').add(6, 'd').format('MMM DD, YYYY'))
      );
    },
    prevDateForWeek(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? this.prevDateForWeekGenerator(prevDate)
        : '';
    },
    currentDateForMonthGenerator(currentDate) {
      return (
        moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(currentDate, 'MM/DD/YYYY')
          .endOf('month')
          .isAfter(this.dateSelected.to)
          ? moment(this.dateSelected.to).format('MMM DD, YYYY')
          : moment(currentDate, 'MM/DD/YYYY')
              .endOf('month')
              .format('MMM DD, YYYY'))
      );
    },
    currentDateForMonth(currentDate) {
      return currentDate ? this.currentDateForMonthGenerator(currentDate) : '';
    },
    prevDateForMonthGenerator(prevDate) {
      return (
        moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(prevDate, 'MM/DD/YYYY')
          .endOf('month')
          .isAfter(this.dateSelected.pvpTo)
          ? moment(this.dateSelected.pvpTo).format('MMM DD, YYYY')
          : moment(prevDate, 'MM/DD/YYYY')
              .endOf('month')
              .format('MMM DD, YYYY'))
      );
    },
    prevDateForMonth(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? this.prevDateForMonthGenerator(prevDate)
        : '';
    },
    defaultCurrentDate(currentDate) {
      return currentDate
        ? moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
        : '';
    },
    defaultPrevDate(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
        : '';
    }
  }
};
</script>

<style lang="css">
.u-ciq-style {
  z-index: 1;
  top: 12px;
}
.stroked-chart-lines {
  stroke-dasharray: 2;
}
</style>
