/* eslint-disable @typescript-eslint/ban-ts-ignore */
import moment from 'moment';
import { convertArraysToMaps } from '@/utils/helpers/utils.js';
import {
  CategoryPageMarketShareBreakdown,
  CategoryShareAggregateInputParams,
  CategoryShareDownloadInputParams,
  CategorySizeCategoryPageDataApi,
  DataApiResponse,
  ApiData,
  Daum,
  EntityDaum,
  PieChartCategoryPageDataApi,
  PieChartWidgetInputParams
} from './data-api';
import {
  MetaDataReadApi,
  MetaDataService,
  PieChartMarketShareCategoryPageMetaDataService
} from './meta-data';
// @ts-ignore
import { formatter } from '@/utils/helpers/formatter.js';
import { DonutChartData, InputParams } from '../types/pie-chart-widget';
import { MetadataChart } from './backend-types';
// @ts-ignore
import { getNumberSign } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils';
import { AxiosResponse } from 'axios';
import {
  DownloadDataSerivce,
  PieChartMarketShareCategoryPageDownloadDataService
} from '../molecules/download-data';
import {
  asinLevelV2ConfigEnabled,
  getDisplayByLabel,
  getDisplayByShareType
} from '@/components/pages/insights/amazon/market-share/utils';

export class DonutDataService {
  inputParamsCurrent: InputParams | undefined = undefined;
  _load = true;
  error: boolean;
  noData: boolean;
  metaData: MetaDataReadApi | null;
  metadataLoad: boolean;
  metadataError: boolean;
  donutChartData: DonutChartData[];
  donutPopupData: MetadataChart = {};

  metaDataApi: MetaDataService =
    new PieChartMarketShareCategoryPageMetaDataService();

  downloadDataApi: DownloadDataSerivce =
    new PieChartMarketShareCategoryPageDownloadDataService();

  dataApi = new PieChartCategoryPageDataApi();
  categorySizeApi = new CategorySizeCategoryPageDataApi();
  data: InputParams | null = null;

  constructor() {
    this.load = true;
    this.error = false;
    this.noData = true;
    this.metadataLoad = false;
    this.metadataError = false;
    this.metaData = null;
    this.donutChartData = [];
  }

  async download(inputParams: CategoryShareDownloadInputParams) {
    if (this.metaData != null) {
      if (this.metaData?.metrics[inputParams.template]) {
        let inputParamsV2 = inputParams;
        inputParamsV2 = {
          ...inputParams,
          substitute: {
            ...inputParams.substitute,
            'operations.orderByList': [
              {
                dimension: this.metaData?.metrics[inputParams.template]?.name,
                direction: 'DESC'
              }
            ],
            metricsList:
              inputParams.template === 'SALES_ESTIMATES_SHARE_PERCENT'
                ? [
                    this.metaData?.metrics[inputParams.template]?.name,
                    this.metaData?.metrics['SALES_ESTIMATES']?.name
                  ]
                : [
                    this.metaData?.metrics[inputParams.template]?.name,
                    ...(asinLevelV2ConfigEnabled()
                      ? []
                      : [
                          this.metaData?.metrics[
                            // regex to remove _SHARE_PERCENT from UNIT_ESTIMATE_SHARE_PERCENT or UNIT_ESTIMATE_SHARE_PERCENT_ONE_P
                            inputParams.template.replace('_SHARE_PERCENT', '')
                          ]?.name
                        ])
                  ]
          }
        };
        return await (
          await this.downloadDataApi.getData(
            this.metaData.downloadApiTemplate.request,
            inputParamsV2
          )
        ).data.url;
      }
    }
  }

  update(
    pageId: number,
    globalViewId: number,
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ) {
    this.fetchData(pageId, globalViewId, inputParams, categoryShareParams);
  }

  async getMetadata(
    pageId: number,
    globalViewId: number
  ): Promise<MetaDataReadApi> {
    try {
      this.metadataLoad = true;
      this.metadataError = false;
      const data = await this.metaDataApi.getData(pageId, globalViewId);
      this.metadataLoad = false;
      return data.data;
    } catch (e) {
      console.error('e', e);
      this.metadataLoad = false;
      this.metadataError = true;
      throw Error('metadata fetch failed');
    }
  }

  get load(): boolean {
    return this._load;
  }

  set load(_load: boolean) {
    this._load = _load;
  }

  private async fetchData(
    pageId: number,
    globalViewId: number,
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ) {
    try {
      this.donutChartData = [];
      this.donutPopupData = {};
      this.load = true;
      this.error = false;
      this.noData = false;
      if (this.metaData == null) {
        this.metaData = await this.getMetadata(pageId, globalViewId);
      }

      let pieChartDataResponse: AxiosResponse<CategoryPageMarketShareBreakdown>;
      const apiEndpoint =
        this.metaData?.metrics[inputParams.template]?.api?.endPoint;
      try {
        if (this.metaData?.metrics[inputParams.template]) {
          let inputParamsV2 = inputParams;
          inputParamsV2 = {
            ...inputParams,
            substitute: {
              ...inputParams.substitute,
              'operations.orderByList': [
                {
                  dimension: this.metaData?.metrics[inputParams.template]?.name,
                  direction: 'DESC'
                }
              ],
              metricsList: [this.metaData?.metrics[inputParams.template]?.name]
            }
          };
          pieChartDataResponse = await this.dataApi.getData(
            this.metaData?.metrics[inputParams.template]?.api?.request,
            inputParamsV2,
            apiEndpoint,
            this.metaData?.metrics[inputParams.template]?.api?.service
          );
        } else {
          throw Error('Metric not available');
        }
      } catch (err) {
        console.error(err);
        this.error = true;
        this.load = false;
        return null;
      }
      const { data } = pieChartDataResponse;
      let categorySizeData: AxiosResponse<DataApiResponse>;
      try {
        if (this.metaData?.metrics[categoryShareParams.template]) {
          let categoryShareParamsV2 = categoryShareParams;
          categoryShareParamsV2 = {
            ...categoryShareParams,
            substitute: {
              ...categoryShareParams.substitute,
              metricsList: [
                this.metaData?.metrics?.[categoryShareParams.template]?.name
              ]
            }
          };
          categorySizeData = await this.categorySizeApi.getData(
            this.metaData?.metrics[categoryShareParams.template]?.api?.request,
            categoryShareParamsV2,
            apiEndpoint,
            this.metaData?.metrics[inputParams.template]?.api?.service
          );
        } else {
          throw Error('Metric not available');
        }
      } catch (err) {
        console.error(err);
        this.error = true;
        return null;
      } finally {
        this.load = false;
      }
      if (data.entityData.length === 0) {
        this.noData = true;
        return null;
      }

      const pvpMarketShareCategorySize = this.estimateSizePvp(
        categorySizeData.data.entityData[0].data[0],
        inputParams,
        categoryShareParams
      );
      const marketShareCategorySize = this.estimateSize(
        categorySizeData.data.entityData[0].data[0],
        inputParams,
        categoryShareParams
      );

      const totalValue =
        getDisplayByShareType() === 'Sales' ? marketShareCategorySize : 100;
      const totalPvp = 100;
      this.donutChartData = this.transformApiData(
        data.entityData,
        inputParams,
        categoryShareParams,
        totalValue,
        totalPvp
      );
      let downloadTooltipText = '';

      if (inputParams.variableReplace[':entityType'] === 'brand') {
        downloadTooltipText =
          'Download Excel with total Market Share by Brands for the selected category.';
      } else if (
        inputParams.variableReplace[':entityType'] === 'manufacturer'
      ) {
        downloadTooltipText =
          'Download Excel with total Market Share by Manufacturer for the selected category.';
      }
      const chartData: InputParams = {
        chartBottom: {
          icon: 'star',
          text: 'Your Brand',
          lastUpdatedDate: moment(this.metaData?.calendar?.max_date).format(
            'LL'
          )
        },
        chartData: this.donutChartData,
        chartTop: {
          precision: 2,
          mainHeading: 'Category Share',
          heading: 'Category Size:',
          prefixPvpValue: inputParams.displayShareIn.startsWith(
            'UNIT_ESTIMATES'
          )
            ? ''
            : 'currency',
          pvp: pvpMarketShareCategorySize,
          pvpDirection: getNumberSign(pvpMarketShareCategorySize),
          subText: marketShareCategorySize,
          downloadTooltipText
        },
        tooltip: {
          tooltipTitle: 'Market Share'
        }
      };
      this.inputParamsCurrent = chartData;
      this.load = false;
      this.data = chartData;
      // this.data.complete();
    } catch (e) {
      console.error('e', e);
      this.load = false;
      this.error = true;
      this.noData = false;
      this.data = null;
      // this.data.complete();
    }
  }

  sharePercentage(
    data: {
      [key: string]: Daum;
    },
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ): number {
    const key = getDisplayByShareType() === 'Sales' ? '' : '_share_percentage';
    if (asinLevelV2ConfigEnabled()) {
      const returnVal = data[inputParams.template].RESULT[inputParams.template];
      return returnVal;
    } else {
      return data[inputParams.template + key].RESULT[
        inputParams.template + key
      ];
    }
  }

  pvpSharePercentage(
    data: {
      [key: string]: Daum;
    },
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ): number {
    const prefix = getDisplayByShareType() === 'Sales' ? 'PVP_' : 'PVP_DIFF_';
    const key = getDisplayByShareType() === 'Sales' ? '' : '_share_percentage';
    if (asinLevelV2ConfigEnabled()) {
      const returnVal =
        data[inputParams.template].PVP[prefix + inputParams.template];
      return returnVal;
    } else {
      return data[inputParams.template + key].PVP[
        prefix + inputParams.template + key
      ];
    }
  }

  estimateSize(
    data: ApiData,
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ): number {
    return data.RESULT[
      categoryShareParams.template as
        | 'UNIT_ESTIMATES_SIZE'
        | 'SALES_ESTIMATES_SIZE'
    ] as number;
  }

  estimateSizePvp(
    data: ApiData,
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ): number {
    const prefix = 'PVP_';
    return data.PVP[
      (prefix + categoryShareParams.template) as
        | 'PVP_SALES_ESTIMATES_SIZE'
        | 'PVP_SALES_ESTIMATES_SIZE'
    ];
  }

  private entityKey(
    inputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams
  ) {
    return inputParams.variableReplace[':entityType'];
  }

  transformApiData(
    entityData: EntityDaum[],
    pieChartInputParams: PieChartWidgetInputParams,
    categoryShareParams: CategoryShareAggregateInputParams,
    totalValue: number,
    totalPvp: number
  ) {
    const apiArrayLookupMap: { [key: string]: Daum }[] = convertArraysToMaps(
      entityData,
      'name'
    );
    const donutChartData: DonutChartData[] = [];
    let firstFourPercent = 0;
    let firstFourPvp = 0;
    for (let j = 0; j < entityData.slice(0, 4).length; j++) {
      const previousPeriodSharePercent = this.pvpSharePercentage(
        apiArrayLookupMap[j],
        pieChartInputParams,
        categoryShareParams
      );
      firstFourPercent += this.sharePercentage(
        apiArrayLookupMap[j],
        pieChartInputParams,
        categoryShareParams
      );
      firstFourPvp += previousPeriodSharePercent;
      let brand = apiArrayLookupMap[j][
        this.entityKey(pieChartInputParams, categoryShareParams)
      ].RESULT[
        this.entityKey(pieChartInputParams, categoryShareParams)
      ] as string;
      // check for duplicate data
      if (donutChartData.some((data) => data.brand === brand)) {
        brand = ' ' + brand;
      }
      this.donutPopupData[brand] = {
        uncolored: [],
        colored: []
      };
      // const icon = apiArrayLookupMap[j].client_flag.RESULT.client_flag === 'client' ? 'star' : '';
      const icon = '';
      const client = apiArrayLookupMap[j].client_flag.RESULT
        .client_flag as string;
      const preText = pieChartInputParams.variableReplace[':entityType'] + ':';
      // const preText = client === 'client' ? `Your ${pieChartInputParams.variableReplace[':entityType']}:` : `Competitor ${pieChartInputParams.variableReplace[':entityType']}:`
      const obj = this.formatRawData({
        brand: brand,
        legendTooltip: { icon, preText, title: brand },
        pvp: previousPeriodSharePercent,
        pvpDirection: getNumberSign(
          this.pvpSharePercentage(
            apiArrayLookupMap[j],
            pieChartInputParams,
            categoryShareParams
          )
        ),
        percentage: this.sharePercentage(
          apiArrayLookupMap[j],
          pieChartInputParams,
          categoryShareParams
        ),
        value: {
          data: this.sharePercentage(
            apiArrayLookupMap[j],
            pieChartInputParams,
            categoryShareParams
          ),
          type: valueType()
        },
        clientFlag: client,
        legendIcon: icon,
        colored: true,
        barBackground: ''
      });
      donutChartData.push(obj);
      if (obj.brand) {
        this.donutPopupData[obj.brand].colored.push(obj);
      }
    }
    let othersFirstFourPercentage = 0;
    let othersFirstFourPvp = 0;
    const offset = 4;
    entityData.slice(offset).forEach((data, j) => {
      const idx = j + offset;
      othersFirstFourPvp += this.pvpSharePercentage(
        apiArrayLookupMap[idx],
        pieChartInputParams,
        categoryShareParams
      );
      othersFirstFourPercentage += this.sharePercentage(
        apiArrayLookupMap[idx],
        pieChartInputParams,
        categoryShareParams
      );
    });
    const otherBrand = 'Others';
    this.donutPopupData[otherBrand] = {
      uncolored: [],
      colored: []
    };
    entityData.slice(offset, offset + 4).forEach((data, j) => {
      const idx = j + offset;
      const previousPeriodSharePercent = this.pvpSharePercentage(
        apiArrayLookupMap[idx],
        pieChartInputParams,
        categoryShareParams
      );
      this.donutPopupData[otherBrand].uncolored.push(
        this.formatRawData({
          brand: apiArrayLookupMap[idx][
            this.entityKey(pieChartInputParams, categoryShareParams)
          ].RESULT[
            this.entityKey(pieChartInputParams, categoryShareParams)
          ] as string,
          pvp: previousPeriodSharePercent,
          pvpDirection: getNumberSign(
            this.pvpSharePercentage(
              apiArrayLookupMap[idx],
              pieChartInputParams,
              categoryShareParams
            )
          ),
          percentage: this.sharePercentage(
            apiArrayLookupMap[idx],
            pieChartInputParams,
            categoryShareParams
          ),
          value: {
            data: this.sharePercentage(
              apiArrayLookupMap[idx],
              pieChartInputParams,
              categoryShareParams
            ),
            type: valueType()
          },
          clientFlag: apiArrayLookupMap[idx].client_flag.RESULT
            .client_flag as string,
          // legendIcon: apiArrayLookupMap[idx].client_flag.RESULT.client_flag === 'client' ? 'star' : '',
          legendIcon: '',
          colored: false,
          barBackground: ''
        })
      );
    });
    const noPvpDataAvailable = donutChartData.every(
      (data) => data.pvp === null
    );
    const pvpCalc = -firstFourPvp;
    const percentCalc = totalValue - firstFourPercent;
    const otherData = {
      brand: otherBrand,
      legendTooltip: { icon: '', preText: '', title: otherBrand },
      pvp: noPvpDataAvailable ? null : pvpCalc,
      pvpDirection: getNumberSign(pvpCalc),
      percentage: percentCalc,
      value: {
        data: percentCalc,
        type: valueType()
      },
      clientFlag: 'comp',
      legendIcon: '',
      barBackground: '',
      colored: true
    };
    donutChartData.push(otherData);
    this.donutPopupData[otherBrand].colored.push(otherData);

    const restPvp = pvpCalc - othersFirstFourPvp;
    const restData: DonutChartData = this.formatRawData({
      brand: 'Rest',
      pvp: noPvpDataAvailable ? null : restPvp,
      pvpDirection: getNumberSign(restPvp),
      percentage: percentCalc - othersFirstFourPercentage,
      value: {
        data: percentCalc - othersFirstFourPercentage,
        type: valueType()
      },
      clientFlag: 'comp',
      legendIcon: '',
      barBackground: '',
      colored: false
    });

    this.donutPopupData[otherBrand].uncolored.push(restData);
    this.donutChartData = donutChartData;

    this.donutChartData = this.donutChartData.map(this.formatRawData);
    const donutPopupDataCleanedUp: MetadataChart = {};
    for (const key of Object.keys(this.donutPopupData)) {
      donutPopupDataCleanedUp[key] = {
        colored: this.donutPopupData[key].colored.map(this.formatRawData),
        uncolored: this.donutPopupData[key].uncolored.map(this.formatRawData)
      };
    }
    this.donutPopupData = donutPopupDataCleanedUp;
    return this.donutChartData;
  }

  formatRawData(data: DonutChartData): DonutChartData {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false
    });
    return {
      ...data,
      pvp:
        data.pvp === null ? null : Math.abs(Number(formatter.format(data.pvp))),
      percentage: Math.abs(
        Number(formatter.format(data.percentage ? data.percentage : 0))
      ),
      value: {
        data: Math.abs(
          Number(formatter.format(data.value?.data ? data.value?.data : 0))
        ),
        type: valueType()
      }
    };
  }
}

function valueType() {
  return getDisplayByShareType() === 'Sales'
    ? getDisplayByLabel() === 'Dollars'
      ? 'currency'
      : 'numeric'
    : 'percentage';
}

export class DonutDataServiceBenchmarkBrands {
  inputParamsCurrent: InputParams | undefined = undefined;
  _load = true;
  error: boolean;
  noData: boolean;
  metaData: MetaDataReadApi | null;
  metadataLoad: boolean;
  metadataError: boolean;
  donutChartData: DonutChartData[];
  donutPopupData: MetadataChart = {};

  metaDataApi: MetaDataService =
    new PieChartMarketShareCategoryPageMetaDataService();

  downloadDataApi: DownloadDataSerivce =
    new PieChartMarketShareCategoryPageDownloadDataService();

  dataApi = new PieChartCategoryPageDataApi();
  categorySizeApi = new CategorySizeCategoryPageDataApi();
  data: InputParams | null = null;

  constructor() {
    this.load = true;
    this.error = false;
    this.noData = true;
    this.metadataLoad = false;
    this.metadataError = false;
    this.metaData = null;
    this.donutChartData = [];
  }

  update(myShare: number | null, restShare: number | null) {
    this.fetchData(myShare, restShare);
  }

  get load(): boolean {
    return this._load;
  }

  set load(_load: boolean) {
    this._load = _load;
  }

  private async fetchData(myShare: number | null, restShare: number | null) {
    try {
      this.donutChartData = [
        {
          barBackground: '#fff',
          brand: 'myShare',
          clientFlag: 'client',
          colored: true,
          // legendIcon: 'star',
          legendIcon: '',
          percentage: myShare === null ? 0 : myShare,
          pvp: 3232,
          pvpDirection: '+'
        },
        {
          barBackground: '#fff',
          brand: 'restShare',
          clientFlag: 'client',
          colored: true,
          // legendIcon: 'star',
          legendIcon: '',
          percentage:
            myShare === null || (myShare === 0 && restShare === 0)
              ? 100
              : restShare,
          pvp: 3232,
          pvpDirection: '+'
        }
      ];
      this.load = false;
    } catch (e) {
      console.error('e', e);
      this.load = false;
      this.error = true;
      this.noData = false;
      this.data = null;
    }
  }
}
