import HomeV2 from '@/components/homeV2.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import { digitalShelfRoute, homeRoute } from '@/router/commonRoutePaths.js';

const overstockRoutes = {
  path: '/us/overstock/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect: 'digital_shelf/market_insights'
    },
    digitalShelfRoute,
    ...commonInternalRoutesArray
  ]
};

export { overstockRoutes };
