<template>
  <div class="u-spacing-pt-xxxl u-spacing-ph-xxl u-position-relative">
    <loader
      v-show="rightButtonLoading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <section class="u-spacing-pt-m">
      <createCampaignHeader
        :step="step"
        :default-margin-bottom="false"
      />
      <div class="u-width-100 u-display-flex u-spacing-mt-xl">
        <SettingsBody
          :class="{
            'u-width-50': step.rightInfoAlert && !isCampaignAlreadyCreated
          }"
          :settings-advertisers-options="settingsAdvertisersOptions"
          :selected-advertiser="selectedAdvertiser"
          :advertiser-loader="advertiserLoader"
          :default-total-budget="totalBudget"
          :default-daily-budget="dailyBudget"
          :default-calender-dates="calenderDates"
          :disable-advertiser-dropdown="disableAdvertiserDropdown"
          :form-error-object="formErrorObject"
          @handleSelectedAdvertiser="handleAdvertiserSelect"
          @handleTotalBudgetChange="handleTotalBudgetChange"
          @handleDailyBudgetChange="handleDailyBudgetChange"
          @datesValuesUpdated="handleCalenderDatesChange"
        />

        <right-alert-info
          v-if="step.rightInfoAlert && !isCampaignAlreadyCreated"
          :class="{
            'u-width-50': step.rightInfoAlert && !isCampaignAlreadyCreated
          }"
          :title="step.rightInfoAlert.title"
          :description="step.rightInfoAlert.desc"
        />
      </div>

      <createCampaignFooter
        :disable-right-btn="disableRightBtn"
        :disable-left-btn="disableLeftBtn"
        class="u-spacing-pt-l"
        :right-button-loading="rightButtonLoading"
        :error-message="errorMessage"
        @onClickBtnRight="onClickBtnRight"
        @onClickBtnLeft="onClickBtnLeft"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import SettingsBody from './SettingsBody.vue';
import loader from '@/components/basic/loader.vue';
import RightAlertInfo from '@/components/ams/campaign_creation/walmart_steps/settings/RightAlertInfo.vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import moment from 'moment-timezone';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import {
  ErrorCodes,
  WalmartCreateCustomException
} from '@/components/ams/campaign_creation/walmart_steps/errorUtility';

export default {
  name: 'Settings',
  components: {
    createCampaignHeader,
    createCampaignFooter,
    SettingsBody,
    RightAlertInfo,
    loader
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      settingsAdvertisersOptions: [],
      selectedAdvertiser: {},
      advertiserLoader: false,
      totalBudget: 100,
      dailyBudget: 50,
      calenderDates: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: null
      },
      formErrorObject: {
        totalBudget: '',
        dailyBudget: ''
      },
      rightButtonLoading: false,
      campaignId: null,
      adGroupId: null,
      advertiserId: null,
      walmartCampaignCreateDataService: {},
      errorMessage: ''
    };
  },
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    },
    areValidationsErrorPresent() {
      const formErrorObject = this.formErrorObject;
      let areValidationsErrorPresent = false;
      for (const key of Object.keys(formErrorObject)) {
        if (formErrorObject[key] !== '') {
          areValidationsErrorPresent = true;
          return areValidationsErrorPresent;
        }
      }
      return areValidationsErrorPresent;
    },
    disableRightBtn() {
      let flag = false;
      if (this.areValidationsErrorPresent) {
        flag = true;
        return flag;
      }
      if (this.advertiserLoader === true || this.rightButtonLoading === true) {
        flag = true;
        return flag;
      }
      if (this.allStepsData?.campaignNameAlreadyExists === true) {
        flag = true;
        return flag;
      }
      return flag;
    },
    disableLeftBtn() {
      let flag = false;
      if (this.isCampaignAlreadyCreated) {
        flag = true;
      }
      return flag;
    },
    isCampaignAlreadyCreated() {
      const { campaignId } = this.$route.query;
      if (campaignId) {
        return true;
      }
      return false;
    },
    disableAdvertiserDropdown() {
      if (this.isCampaignAlreadyCreated) {
        return true;
      }
      return false;
    },
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  watch: {
    allStepsData: {
      handler(newVal) {
        const startDate = newVal?.campaignDates?.start;
        const endDate = newVal?.campaignDates?.end;
        const totalBudget = newVal?.totalBudget;
        const dailyBudget = newVal?.dailyBudget;
        const startDateString = moment(startDate).format('MMM DD, YYYY');
        const endDateString = endDate
          ? moment(endDate).format('MMM DD, YYYY')
          : 'No End Date';
        const dateDescription = `<span>${startDateString} to ${endDateString}</span>`;
        const totalBudgetDescription = `<br/><span>${
          totalBudget ? `$${totalBudget}` : 'No'
        } Total Budget</span>`;
        const dailyBudgetDescription = `<br/><span>${
          dailyBudget ? `$${dailyBudget}` : 'No'
        } Daily Budget</span>`;
        this.step.description =
          dateDescription + totalBudgetDescription + dailyBudgetDescription;
      },
      deep: true,
      immediate: true
    },
    dailyBudget() {
      this.checkFormValidity();
    },
    totalBudget() {
      this.checkFormValidity();
    }
  },
  async created() {
    this.initializeDataService();
    this.$store.dispatch(this.selectedValues.action, {
      ...this.allStepsData
    });
    this.handleTotalBudgetChange(
      this.allStepsData?.totalBudget || this.step?.totalBudget || null
    );
    this.handleDailyBudgetChange(
      this.allStepsData?.dailyBudget || this.step?.dailyBudget || null
    );
    this.handleCalenderDatesChange(
      this.allStepsData?.campaignDates || this.calenderDates
    );
    if (this.isCampaignAlreadyCreated) {
      try {
        await this.fetchSaveAndPreFillValues();
      } catch (err) {
        console.log(err);
      }
    } else {
      this.advertiserLoader = true;
      try {
        const response = await HttpLayer.post({
          APIData: this.step.getAdvertisers
        });
        this.settingsAdvertisersOptions = transformer.mergeResultDimension(
          response.data,
          true
        );
        this.handleAdvertiserSelect(
          this.settingsAdvertisersOptions?.[0] || {},
          true
        );
      } finally {
        this.advertiserLoader = false;
      }
    }
  },
  methods: {
    checkFormValidity() {
      const formErrorObject = { ...this.formErrorObject };
      const { dailyBudget, totalBudget } = this;

      let totalBudgetError = '';
      let dailyBudgetError = '';

      if (dailyBudget) {
        if (dailyBudget < 50) {
          dailyBudgetError = 'Daily budget must be $50.00 or above.';
        }
      }

      if (totalBudget) {
        if (totalBudget < 100) {
          totalBudgetError = 'Total budget must be $100.00 or above.';
        }
      }

      if (dailyBudget && totalBudget) {
        if (dailyBudget > totalBudget) {
          dailyBudgetError =
            'Daily budget must be equal to or lesser than total budget.';
        }
      }

      if (this.retailer === 'walmart') {
        if (!dailyBudget) {
          dailyBudgetError = 'Please enter daily budget.';
        }
      }
      if (this.retailer === 'samsclub_api') {
        if (!(totalBudget || dailyBudget)) {
          dailyBudgetError = totalBudgetError =
            'Please enter either total budget, daily budget, or both.';
        }
      }

      formErrorObject.totalBudget = totalBudgetError;
      formErrorObject.dailyBudget = dailyBudgetError;

      this.formErrorObject = formErrorObject;
    },
    async fetchSaveAndPreFillValues() {
      try {
        const response = await this.fetchCampaignDetails();
        await this.$store.dispatch(this.selectedValues.action, {
          ...this.allStepsData,
          ...{ fetchedCampaignResponse: response.data?.response[0] }
        });
        this.preFillFieldsFromFetchedData();
      } catch (err) {
        if (err.message === ErrorCodes.FETCHING_CAMPAIGN_DETAILS_FAILED) {
          this.$snackbar.open({
            message:
              'Something went wrong while fetching campaign details. Try again after some time',
            duration: 6000
          });
          this.$router.push({
            name: 'Campaigns'
          });
        }
        console.log(err);
      }
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      const retailer = this.$store.getters.getRetailer;
      this.campaignId = parseInt(campaignId) || undefined;
      this.adGroupId = parseInt(adGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      this.walmartCampaignCreateDataService =
        new WalmartCampaignCreateDataService(
          campaignId,
          adGroupId,
          advertiserId,
          campaignType,
          retailer
        );
    },
    preFillFieldsFromFetchedData() {
      const responseData = this.allStepsData?.fetchedCampaignResponse;
      this.handleTotalBudgetChange(
        responseData?.totalBudget || this.step?.totalBudget || null
      );
      this.handleDailyBudgetChange(
        responseData?.dailyBudget || this.step?.dailyBudget || null
      );
      const calenderDates = {
        start: moment(responseData?.startDate).toDate(),
        end:
          responseData?.endDate === '9999-12-30'
            ? null
            : moment(responseData?.endDate).toDate()
      };
      this.handleCalenderDatesChange(calenderDates || this.calenderDates);
      this.settingsAdvertisersOptions = [
        { advertiser_name: null, advertiser_id: responseData?.advertiserId }
      ];
      this.handleAdvertiserSelect(
        this.settingsAdvertisersOptions?.[0] || {},
        true
      );
    },
    async fetchCampaignDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchCampaignDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_CAMPAIGN_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_CAMPAIGN_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    handleTotalBudgetChange(value) {
      this.totalBudget = value;
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ totalBudget: this.totalBudget }
      });
    },
    handleDailyBudgetChange(value) {
      this.dailyBudget = value;
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ dailyBudget: this.dailyBudget }
      });
    },
    handleAdvertiserSelect(selection, fromCreatedHook) {
      if (fromCreatedHook && this.allStepsData?.advertiser) {
        this.selectedAdvertiser = this.allStepsData.advertiser;
      } else {
        this.selectedAdvertiser = selection;
      }
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ advertiser: this.selectedAdvertiser }
      });
    },
    handleCalenderDatesChange(value) {
      this.calenderDates = value;
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ campaignDates: this.calenderDates }
      });
    },
    async createCampaign() {
      try {
        const allStepsData = this.allStepsData;
        const createCampaignResponse =
          await this.walmartCampaignCreateDataService.createCampaign(
            allStepsData
          );
        if (createCampaignResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.CAMPAIGN_CREATE_UPDATE_FAILED,
            createCampaignResponse
          );
        }
        return createCampaignResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.CAMPAIGN_CREATE_UPDATE_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      }
    },
    async updateCampaignDetails(campaignUpdateObject) {
      this.rightButtonLoading = true;
      try {
        const updateCampaignDetailsResponse =
          await this.walmartCampaignCreateDataService.updateCampaignDetails(
            campaignUpdateObject
          );
        if (updateCampaignDetailsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.CAMPAIGN_CREATE_UPDATE_FAILED,
            updateCampaignDetailsResponse
          );
        }
        if (updateCampaignDetailsResponse.data?.success === 1) {
          this.goToNextStep();
        }
      } catch (err) {
        if (err.message === ErrorCodes.CAMPAIGN_CREATE_UPDATE_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async createAdGroup(campaignId) {
      try {
        const allStepsData = this.allStepsData;
        const createAdGroupResponse =
          await this.walmartCampaignCreateDataService.createAdGroup(
            allStepsData,
            campaignId
          );
        if (createAdGroupResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.CREATE_AD_GROUP_FAILED,
            createAdGroupResponse
          );
        }
        return createAdGroupResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.CREATE_AD_GROUP_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      }
    },
    async createCampaignAndAdGroup() {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        // Create campaign here
        const createCampaignResponse = await this.createCampaign();
        if (createCampaignResponse.data?.success === 1) {
          this.appendToQueryParams({
            campaignId: createCampaignResponse.data?.response[0]?.campaignId,
            advertiserId: allStepsData?.advertiser?.advertiser_id
          });
          const campaignId =
            createCampaignResponse.data?.response[0]?.campaignId;
          const createAdGroupResponse = await this.createAdGroup(campaignId);
          // this means both campaign creation and ad group creation is successful
          if (createAdGroupResponse.data?.success === 1) {
            this.appendToQueryParams({
              adGroupId: createAdGroupResponse.data?.response[0]?.adGroupId
            });
            // Update the dataService to have advertiserId in it, because the api to fetch campaign details will fail
            // If no advertiser id is present
            this.initializeDataService();
            await this.fetchSaveAndPreFillValues();
            this.goToNextStep();
          }
        }
      } catch (err) {
        console.log(err);
        this.handleCaughtErrors(err);
      }
      this.rightButtonLoading = false;
    },
    appendToQueryParams(keyValPairs) {
      const query = { ...this.$route.query, ...keyValPairs };
      this.$router.replace({ query: query });
    },
    checkIfCampaignWasUpdated() {
      const allStepsData = this.allStepsData;
      const fetchedCampaignResponse = allStepsData?.fetchedCampaignResponse;
      let wasCampaignUpdated = false;
      const campaignUpdateObject = {};
      if (fetchedCampaignResponse.totalBudget !== allStepsData?.totalBudget) {
        campaignUpdateObject.totalBudget = allStepsData?.totalBudget || null;
        wasCampaignUpdated = true;
      }
      if (fetchedCampaignResponse.dailyBudget !== allStepsData?.dailyBudget) {
        campaignUpdateObject.dailyBudget = allStepsData?.dailyBudget || null;
        wasCampaignUpdated = true;
      }
      // Logic to send the budgetType
      // If there was daily budget previously, and total is added now, budgetType is both.
      // If there was both, and total budget was removed, then budgetType is daily.
      // If there was both, and daily budget was removed, then budgetType is daily.

      // This if conditions means that there is a budget update.
      if (
        campaignUpdateObject?.totalBudget !== undefined ||
        campaignUpdateObject?.dailyBudget !== undefined
      ) {
        const budgetStateToWhichItWillGetUpdated = {
          totalBudget:
            campaignUpdateObject?.totalBudget !== undefined
              ? campaignUpdateObject?.totalBudget
              : fetchedCampaignResponse.totalBudget,
          dailyBudget:
            campaignUpdateObject?.dailyBudget !== undefined
              ? campaignUpdateObject?.dailyBudget
              : fetchedCampaignResponse.dailyBudget
        };
        if (
          budgetStateToWhichItWillGetUpdated.totalBudget &&
          budgetStateToWhichItWillGetUpdated.dailyBudget
        ) {
          campaignUpdateObject.budgetType = 'both';
        }
        if (
          !budgetStateToWhichItWillGetUpdated.totalBudget &&
          budgetStateToWhichItWillGetUpdated.dailyBudget
        ) {
          campaignUpdateObject.budgetType = 'daily';
        }
        if (
          budgetStateToWhichItWillGetUpdated.totalBudget &&
          !budgetStateToWhichItWillGetUpdated.dailyBudget
        ) {
          campaignUpdateObject.budgetType = 'total';
        }
      }

      const currentUpdatedStartDate = moment(
        allStepsData?.campaignDates.start
      ).format('YYYY-MM-DD');
      const currentUpdatedEndDate = allStepsData?.campaignDates?.end
        ? moment(allStepsData?.campaignDates.end).format('YYYY-MM-DD')
        : '9999-12-30';
      if (fetchedCampaignResponse.startDate !== currentUpdatedStartDate) {
        campaignUpdateObject.startDate = currentUpdatedStartDate;
        wasCampaignUpdated = true;
      }
      if (fetchedCampaignResponse.endDate !== currentUpdatedEndDate) {
        campaignUpdateObject.endDate = currentUpdatedEndDate;
        wasCampaignUpdated = true;
      }
      return [wasCampaignUpdated, campaignUpdateObject];
    },
    goToNextStep() {
      this.$emit('nextStep', {});
    },
    async onClickBtnRight() {
      try {
        if (this.isCampaignAlreadyCreated) {
          const [wasCampaignUpdated, campaignUpdateObject] =
            this.checkIfCampaignWasUpdated();
          if (wasCampaignUpdated) {
            await this.updateCampaignDetails(campaignUpdateObject);
          } else {
            this.goToNextStep();
          }
          // Check if anything was updated and make the update call accordingly
        } else {
          await this.createCampaignAndAdGroup();
        }
      } catch (err) {
        this.handleCaughtErrors(err);
      }
    },
    onClickBtnLeft() {
      this.$emit('prevStep', {});
    },
    handleCaughtErrors(err) {
      if (err.message === ErrorCodes.SOMETHING_WENT_WRONG) {
        this.errorMessage =
          'Something went wrong. Please try again after some time.';
      }
      if (err.message === ErrorCodes.CAMPAIGN_CREATE_UPDATE_FAILED) {
        const failureReason = err?.metadata?.data?.response[0]?.details;

        if (failureReason) {
          const errorReasonString = failureReason.split('|').join('\n');
          this.errorMessage = errorReasonString;
        } else {
          this.errorMessage = this.errorMessage =
            'Incorrect values were passed while updating/creating campaign. Please fix them and try again.';
        }
      }
      if (this.message === ErrorCodes.CREATE_AD_GROUP_FAILED) {
        this.errorMessage =
          'Incorrect values were passed while creating ad group. Please fix them and try again';
      }
    }
  }
};
</script>
