<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      style="height: 48px; width: 48px; margin: 0px"
      class="media__img u-flex-none"
    >
      <img
        style="height: 48px; width: 48px; margin: 0px"
        :src="imageUrl"
      />
    </div>
    <div class="u-spacing-ml-m u-overflow-hidden">
      <p
        class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
      >
        {{ asinName }}
      </p>
      <p
        class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
      >
        {{ asin }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    asinName: {
      type: String,
      default: null
    },
    asin: {
      type: String,
      default: null
    }
  },
  methods: {
    searchFromOption(context, selection) {
      const payload = { context, selection };
      this.$emit('select', payload);
    }
  }
};
</script>

<style></style>
