import adgroupRequestTemplate from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupRequestTemplate.js';
import { addSkuSpAuto } from '@/components/ams/ad_group_creation/setup_configs/samsclub_api/adgroupAddSkuConfig.js';
import { DataService } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupDataService.js';
import { samsClubApiAdGroupKeywordTargetingStep } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';

const formStepConfig = () => {
  const stepsConfig = [
    addSkuSpAuto,
    samsClubApiAdGroupKeywordTargetingStep.auto
  ];
  return stepsConfig;
};

export default {
  adgroupRequestTemplate,
  stepsConfig: formStepConfig(),
  getter: 'samsclub_apiCampaignCreation/getAllStepsData',
  action: 'samsclub_apiCampaignCreation/updateAllStepsData',
  dataService: DataService,
  redirectTo: (vueRef) => {
    const { advertiserId, campaignType, campaignId, campaignName } =
      vueRef.$route.query;
    vueRef.$router.replace({
      name: 'samsclub_apiEntityDetails',
      params: {
        entityType: campaignType,
        entityId: campaignId
      },
      query: {
        advertiser_id: advertiserId,
        parent_entity_name: campaignName
      }
    });
  }
};
