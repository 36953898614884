import MetadataService from './MetadataService';
import HttpService from '@/utils/services/http-service';
import {
  metricToDimensionMapping,
  dimensionToMetricLabelMapping
} from '@/components/pages/insights/amazon/rem-cc/utils/constants.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default class MetricSpotlightService {
  page = null;
  widget = null;
  metadata = null;
  pageId = null;
  globalViewId = null;
  data = null;
  loading = false;
  error = false;
  readApiConfig = null;
  spotlightMetricKey = null;
  selectedMetric = null;
  metricLabel = null;
  formattedSpotlightData = null;
  constructor(widgetConfig) {
    this.globalViewId = widgetConfig.globalViewId;
    this.pageId = widgetConfig.pageId;
    this.readApiConfig = widgetConfig.api;
    this.metadata = new MetadataService({
      globalViewId: this.globalViewId,
      pageId: this.pageId,
      api: this.readApiConfig
    });
  }

  async fetchData() {
    try {
      this.loading = true;
      if (!this.spotlightMetricKey) {
        return;
      }
      const metricDetails = this.metadata.data.metrics.spotlight_metric_type;
      const { api } = metricDetails;
      const { request } = api;
      request.metricsList = ['remcc_spotlights_metric'];
      request.where.dimensionNameValueList = [
        {
          dimensionName: 'spotlight_metric_type',
          dimensionValue: this.spotlightMetricKey,
          operator: 'EQUAL_TO'
        }
      ];
      request.where.date = {
        from: '2022-11-06',
        to: '2022-12-03',
        name: 'lastWeek'
      };
      request.where.pvpDate = {
        from: '2022-11-20',
        to: '2022-11-26',
        compare_name: null
      };
      this.data = await HttpService.post(
        api.service,
        { ...request },
        { append: api.endPoint }
      );
      this.formatMetricSpotlightData();
    } catch (err) {
      this.error = false;
    } finally {
      this.loading = false;
    }
  }

  generateSpotlightCauseString(cause, spotlight) {
    cause = cause.toLowerCase();
    const causeStatus =
      spotlight[`status_${cause}`] === 'increased' ? 'increase' : 'drop';
    const causePvp = Math.abs(parseInt(spotlight[`pvp_${cause}`] || '0'));
    const causePvpColor =
      spotlight[`status_${cause}`] === 'increased' ? 'green' : 'red';
    const causeLabel = dimensionToMetricLabelMapping[cause];
    return `${causeStatus} in <span class="u-font-weight-700">${causeLabel}</span> by <span class="u-color-${causePvpColor}-base u-font-weight-700">${causePvp}%</span>`;
  }

  generateSpotlightCardContentString(spotlight) {
    const dimensionName = metricToDimensionMapping[this.selectedMetric];
    const causeString = JSON.parse(spotlight.cause_list || [])
      .slice(0, 2)
      .map((cause) => this.generateSpotlightCauseString(cause, spotlight))
      .join(' or ');

    const metricStatus =
      spotlight[`status_${dimensionName}`] === 'increased'
        ? 'increase'
        : 'drop';
    const metricPvpColor =
      spotlight[`status_${dimensionName}`] === 'increased' ? 'green' : 'red';
    const metricPvp = Math.abs(
      parseInt(spotlight[`pvp_${dimensionName}`] || '0')
    );

    const content = `One of your <span class="u-font-weight-700">top SKUs</span> experienced a ${metricStatus} in ${this.metricLabel} by <span class="u-font-weight-700 u-color-${metricPvpColor}-base">${metricPvp}%</span> possibly due to ${causeString} in the specified period.`;
    return content;
  }

  formatMetricSpotlightData() {
    // converting list to map for easy access of data
    const entityDataMap = (this.data.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        return previousVal;
      }, {});
    });

    const metricSpotlightData = entityDataMap.map((spotlight) => {
      const obj = {
        content: this.generateSpotlightCardContentString(spotlight),
        skuDetails: {
          title: spotlight.title,
          asin: spotlight.asin,
          pdpUrl: spotlight.asin_hyperlink,
          imageUrl: spotlight.image_url
        },
        actions: [
          {
            text: 'Review SKU',
            handler: () => {
              navigate_to_sdp(spotlight.asin);
            }
          }
        ]
      };
      return obj;
    });
    this.formattedSpotlightData = metricSpotlightData;
  }
}
