import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '@/router';

Vue.use(VueAnalytics, {
  id: 'UA-69812042-5',
  checkDuplicatedScript: true,
  router
});

// this.$ga.set({ fieldName, fieldValue })
