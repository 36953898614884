<template>
  <section class="u-position-relative u-spacing-mt-xl u-spacing-pr-s">
    <section>
      <div
        v-if="activeTab === readyToLaunchTab"
        class="u-spacing-mb-l"
      >
        <div
          class="u-cursor-pointer"
          data-cy="recommendations"
          @click="updateRecommendedCheckBox"
        >
          <rb-checkbox
            v-model="recommended"
            class="u-pointer-events-none"
          >
            <span
              class="u-spacing-pl-xs u-font-size-3 u-font-weight-600"
              data-cy="campaignStatusOfRecommendedCampaigns"
              >{{
                $route.query.manual
                  ? 'Campaigns ready to be launched'
                  : 'Recommended campaigns ready to be launched'
              }}
            </span>
          </rb-checkbox>
        </div>
      </div>
      <div
        v-else
        class="u-spacing-mb-l"
      >
        <span
          class="u-spacing-pl-xs u-font-size-3 u-font-weight-600"
          data-cy="campaignStatusOfRecommendedCampaigns"
          >{{
            $route.query.manual
              ? 'Campaigns with pending actions'
              : 'Recommended campaigns with pending actions'
          }}</span
        >
      </div>
      <div class="u-overflow-auto u-spacing-pr-xs">
        <div
          v-for="(item, index) in filteredCampaignList"
          :key="index"
          class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-p-m u-width-100 card u-spacing-mb-l u-spacing-mt-xs u-border-left u-border-width-l"
          :class="
            item.isPending ? classMapping.PENDING : classMapping.READY_TO_LAUNCH
          "
        >
          <div
            class="u-display-flex u-flex-justify-content-space-betweeen u-flex-1 u-flex-align-items-center"
          >
            <div class="u-flex-1">
              <div class="u-display-flex">
                <p class="u-font-size-4 u-color-grey-base">
                  <span v-if="activeTab === readyToLaunchTab">
                    <div
                      class="u-cursor-pointer"
                      @click.stop="updateRecommendedItem(item, index)"
                    >
                      <rb-checkbox
                        v-model="item.selected"
                        class="u-pointer-events-none"
                      />
                    </div>
                  </span>
                </p>
                <p
                  class="u-font-size-4 u-color-grey-base"
                  :class="{ 'u-spacing-ml-m': activeTab === readyToLaunchTab }"
                >
                  {{ (index + 1) | campaignListFilter }}
                </p>
                <div
                  class="u-display-flex u-flex-direction-column u-spacing-ml-m"
                >
                  <span
                    class="u-font-size-4 u-color-grey-base"
                    data-cy="suggestedCampaignName"
                    >{{ item.suggestedCampaignName }}</span
                  >
                  <p class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s">
                    <span data-cy="startandEndTimetxt">
                      Start and End Time:
                      <span
                        class="u-color-grey-base"
                        data-cy="startDate"
                        >{{ formatDate(item.startDate) }}</span
                      >
                      to
                      <span
                        class="u-color-grey-base"
                        data-cy="endDate"
                      >
                        {{ formatDate(item.endDate) }}</span
                      >
                    </span>
                  </p>
                  <p
                    class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s u-width-100"
                  >
                    <span data-cy="budgetTxt">
                      Budget:
                      <span
                        class="u-color-grey-base"
                        data-cy="budgetValue"
                      >
                        {{ formatterFn(item.budget, 'currency', undefined, 0) }}
                        {{ budgetType(item) }}</span
                      >
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="right_side u-display-flex u-flex-direction-column">
              <div class="u-display-flex u-spacing-pb-xs">
                <icon-text-actions
                  class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                  mode="default"
                  :class="{
                    disabled: campaignList && campaignList.length === 1
                  }"
                  :button-text-size="'u-font-size-6'"
                  :action="{ title: 'Remove', icon: 'trash' }"
                  :apply-padding="false"
                  :split-new-line-text="false"
                  data-cy="removeIcon"
                  @clicked="modifyCampaign('remove', item, index)"
                />
                <icon-text-actions
                  class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                  mode="default"
                  :button-text-size="'u-font-size-6'"
                  :action="{ title: 'Edit', icon: 'pencil' }"
                  :apply-padding="false"
                  :split-new-line-text="false"
                  data-cy="editIcon"
                  @clicked="modifyCampaign('edit', item, index)"
                />
              </div>
              <div
                v-if="item.isPending && item.messages.length"
                v-tippy
                :title="showIssues(item.messages)"
                class="u-font-size-6 u-font-weight-600 cursor-help u-width-100 u-text-align-center u-spacing-pt-s"
                data-cy="campaignStatus"
              >
                {{ item.messages.length }} Action{{
                  item.messages.length === 1 ? '' : 's'
                }}
                Pending
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="campaignList && campaignList.length === 0"
        class="u-font-size-4 u-text-align-center"
        data-cy="noDataAvailable"
      >
        No data available.
      </div>
    </section>
  </section>
</template>
<script>
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { cloneDeep } from 'lodash';
import moment from 'moment';
export default {
  filters: {
    campaignListFilter: function (value) {
      if (!value) return '0';
      return ('0' + value).slice(-2);
    }
  },
  components: {
    iconTextActions
  },
  props: {
    campaignList: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: 'Campaigns ready to launch'
    },
    tabsListWithCount: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    recommended: false,
    formatterFn: formatter,
    filteredCampaignList: [],
    classMapping: {
      READY_TO_LAUNCH: 'ready_to_launch',
      PENDING: 'pending'
    }
  }),
  computed: {
    readyToLaunchTab() {
      return `${this.tabsListWithCount.READY} Campaign${
        this.tabsListWithCount.READY === 1 ? '' : 's'
      } ready to launch`;
    }
  },
  watch: {
    activeTab: {
      handler(newVal) {
        this.updateCampaignList();
      },
      deep: true,
      immediate: true
    },
    campaignList: {
      handler(newVal) {
        this.updateCampaignList();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.updateRecommendedItem(null, 'fromCreated');
  },
  methods: {
    budgetType(item) {
      return item?.budgetType?.title || 'Daily';
    },
    showIssues(messages) {
      const messageValues = [];
      for (const key in messages) {
        if (key !== 'length') {
          messageValues.push(messages[key]);
        }
      }
      return messageValues?.toString()?.replaceAll(',', '<br>');
    },
    updateCampaignList() {
      if (this.activeTab === this.readyToLaunchTab) {
        this.filteredCampaignList = this.campaignList.filter(
          (item) => !item.isPending
        );
      } else {
        this.filteredCampaignList = this.campaignList.filter(
          (item) => item.isPending
        );
      }
    },
    formatDate(value) {
      if (!value) return 'No end date';
      return moment(value, 'YYYYMMDD').format('ll');
    },
    updateRecommendedItem(item, index) {
      let selectAll = true;
      if (index !== 'fromCreated') {
        this.filteredCampaignList[index].selected =
          !this.filteredCampaignList[index]?.selected;
        this.filteredCampaignList = cloneDeep(this.filteredCampaignList);
      }
      this.filteredCampaignList.forEach((item) => {
        if (!item?.selected) {
          selectAll = false;
        }
      });
      this.updateRecommendedCheckBox(selectAll);
    },
    emitSelectedItems() {
      this.$emit(
        'selectedItems',
        this.filteredCampaignList.filter((item) => item.selected)
      );
    },
    updateRecommendedCheckBox(selectAll) {
      if (typeof selectAll === 'boolean') {
        this.recommended = selectAll;
      } else {
        this.recommended = !this.recommended;
        this.filteredCampaignList = this.filteredCampaignList.map((item) => {
          item.selected = this.recommended;
          return item;
        });
      }
      this.emitSelectedItems();
    },
    computeClass(item) {
      return this.classMapping[item.status];
    },
    modifyCampaign(operation, item) {
      let index = -1;
      for (let i = 0; i < this.campaignList.length; i++) {
        if (
          this.campaignList[i]?.suggestedCampaignId ===
            item?.suggestedCampaignId &&
          this.campaignList[i].suggestedCampaignName ===
            item?.suggestedCampaignName
        ) {
          index = i;
          break;
        }
      }
      const value = { ...item, index };
      operation === 'remove'
        ? this.$emit('removeCampaign', value)
        : this.$emit('editCampaign', value);
    }
  }
};
</script>

<style lang="css" scoped>
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.ready_to_launch {
  color: #3ea95e;
  border-color: #3ea95e;
}
.pending {
  color: #ffa800;
  border-color: #ffa800;
}
.cursor-help {
  cursor: help;
}
.card {
  box-shadow: 0px 1px 4px 2px rgb(43 51 59 / 15%);
}
</style>
