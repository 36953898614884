import HttpService from '@/utils/services/http-service';
import { parseDropdownData } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';

export default class DataService {
  widgetId;
  widget;
  page;
  pageId;
  globalViewId;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    rows: [],
    noData: false
  };

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getMetadata() {
    try {
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false
      };
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
    }
  }

  async getData(requestCopy) {
    try {
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.post(
        'DASHBOARD_SERVICE',
        requestCopy,
        { append: '/entity/metrics/data' },
        { widgetId: this.widgetId, hashingKeysToIgnore: ['where'] }
      );
      const { entityData } = data;
      if (entityData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        const parsedData = parseDropdownData(entityData);
        this.data = {
          rows: parsedData,
          error: false,
          load: false,
          noData: false
        };
      }
    } catch (e) {
      if (HttpService.isCancelRequestError(e)) {
        return e;
      }
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
      return e;
    }
  }
}
