import Vue from 'vue';
import starRating from '@/components/widgets/starRatings';
import widget3Data from './widget3.json';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

const skuComponent = Vue.extend({
  components: {
    starRating
  },
  computed: {
    rbSkuData() {
      return {
        title: this.params.data.title,
        imageUrl: this.params.data.image_url,
        asin: this.params.data.asin,
        productUrl: this.params.data.latest_product_page_url,
        showImage: true,
        latestTotalReviews:
          this.params.data.latest_total_reviews |
          this.$options.filters.num_format('', '', '', 0),
        latestAvgReviewRating: Math.round(
          this.params.data.latest_avg_review_rating
        )
      };
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  },
  template: `<div class="u-display-flex u-text-overflow-ellipsis">
  <rb-sku :show-image="rbSkuData.showImage" :title="rbSkuData.title" :image-url="rbSkuData.imageUrl" :asin="rbSkuData.asin" :product-page-url="rbSkuData.productUrl" @navigateToSDP="navigateToSDP" :enableSDP="true">
    <div slot="sku-context" class="u-display-flex u-flex-align-items-center">
      <star-rating :active-color="'#f3b000'" :inactive-color="'#f3b000'" :max="5" :name="rbSkuData.asin" :value="rbSkuData.latestAvgReviewRating" :readonly="true">
        <span slot-scope="props" slot="activeLabel"><rb-icon :icon="'star'"></rb-icon></span>
        <span slot-scope="props" slot="inactiveLabel"><rb-icon :icon="'star-empty'"></rb-icon></span>
      </star-rating>
      <span>({{rbSkuData.latestTotalReviews}})</span>
    </div>
  </rb-sku>
  </div>`
});

const skuLevelBreakdownColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    showToolTip: true,
    toolTipText: 'SKU title and ID as per the catalogue.',
    type: 'asin',
    keyOrder: 1
  },
  {
    title: 'Reviews Received',
    key: 'total_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of reviews received during selected date range',
    type: 'numeric',
    keyOrder: 2
  },
  {
    title: '1 star',
    key: 'star1_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of 1 star reviews in the selected date range',
    type: 'numeric',
    keyOrder: 3
  },
  {
    title: '2 star',
    key: 'star2_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of 2 star reviews in the selected date range',
    type: 'numeric',
    keyOrder: 4
  },
  {
    title: '3 star',
    key: 'star3_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of 3 star reviews in the selected date range',
    type: 'numeric',
    keyOrder: 5
  },
  {
    title: '4 star',
    key: 'star4_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of 4 star reviews in the selected date range',
    type: 'numeric',
    keyOrder: 6
  },
  {
    title: '5 star',
    key: 'star5_new_reviews',
    showToolTip: true,
    toolTipText: 'No. of 5 star reviews in the selected date range',
    type: 'numeric',
    keyOrder: 7
  }
];

var columnDefs = [];
function getColumnDefinition(columns) {
  var _columnDefs = [];
  for (const i in columns) {
    const obj = {};
    obj.field = i;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (columns[i].type === 'numeric') {
      keyType = columns[i].unit ? columns[i].unit : 'numeric';
    }
    if (columns[i].type === 'date') {
      keyType = columns[i].type;
    }
    obj.field = columns[i].key;
    obj.cellRendererParams = {
      keyType: keyType
    };
    obj.cellRendererFramework = 'genericTableCell';
    obj.headerName = columns[i].title;
    obj.title = columns[i].title;
    obj.headerComponentParams = {
      enableSorting: true
    };
    if (columns[i].type === 'asin') {
      obj.cellRendererFramework = skuComponent;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = columns[i];
      obj.minWidth = 300;
    }
    obj.keyOrder = columns[i].keyOrder;
    columns[i].pinned && (obj.pinned = columns[i].pinned);
    if (columns[i].showToolTip) {
      obj.headerComponentParams.toolTipText = columns[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  filters: {
    search: {
      enable: false,
      emit: 'reviewsSearchTriggered',
      placeholder: 'Search for a ASIN'
    },
    emit: 'reviewsFilterApplied',
    listen: {
      reviewsFilterApplied: {
        action: 'updateReviewsSelectedFilter'
      }
    },
    left: [
      {
        title: 'Product Group',
        filterValueKey: 'reviews_product_group',
        smart: true
      },
      {
        title: 'Category',
        subFilterValueKey: 'reviews_subcategory',
        filterValueKey: 'reviews_category',
        twoLevel: true,
        smart: true
      },
      {
        title: 'Brand',
        filterValueKey: 'reviews_brand',
        smart: true
      }
    ]
  },
  widgets: {
    widget1: {
      meta: {
        type: 'chart',
        hideOnNoData: false,
        width: 1,
        action: 'getReviewCount',
        key: 'ReviewCountData',
        listen: {
          reviewsFilterApplied: {
            action: 'getReviewCount'
          }
        },
        localFilters: []
      },
      header: {
        show: true,
        title: 'REVIEW COUNT',
        tooltip: '',
        actions: {
          filter: {
            optionsGetter: 'getReviewsLocalFilters',
            selectionGetter: 'getSelectedReviewsLocalFilters',
            items: [
              {
                isMulti: false,
                dimensionName: 'product_group',
                key: 'starRatings',
                action: 'getReviewCount',
                value: [],
                selectTagEdit: false
              }
            ]
          },
          date: {
            action: {
              setReviewsAndRatingsCustomDate: {},
              getReviewCount: {
                transform: function (config, data, that) {
                  return config;
                }
              }
            },
            maxDate: 'review_ratings',
            showComparedToText: true,
            defaultRangeSelect: 'last7Days',
            dateMode: {
              mode: 'week',
              count: 0
            },
            additionalRanges: {
              add: ['last7Days', 'last4Week', 'last13Week', 'last52Week'],
              order: {
                last7Days: 0,
                last4Week: 2,
                last13Week: 3,
                last52Week: 4
              }
            }
          }
        }
      },
      footer: {
        date: 'review_ratings'
      },
      body: {
        APIConfig: {
          cubeName: 'review_ratings_widget',
          measuresList: [
            'total_new_reviews',
            'star1_new_reviews',
            'star2_new_reviews',
            'star3_new_reviews',
            'star4_new_reviews',
            'star5_new_reviews'
          ],
          groupByDimensionsList: ['brand', 'client_flag'],
          limit: 1000,
          page: 1,
          orderByList: [
            {
              dimension: 'client_flag',
              direction: 'ASC'
            },
            {
              dimension: 'total_new_reviews',
              direction: 'DESC'
            }
          ],
          timeseriesDimension: 'feed_date',
          timeseriesEnabled: true,
          getLatestAvailableInsteadOfRollup: false,
          pvpenabled: true,
          yoyenabled: false,
          getSharePercentage: false,
          where: {
            date: { from: '2018-03-18', to: '2018-03-20' },
            dimensionNameValueList: [
              {
                dimensionName: 'product_group',
                dimensionValue: 'Pantry'
              }
            ]
          },
          dedupBeforeRollup: {
            enableDedupBeforeRollup: true,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
              'asin'
            ]
          }
        },
        getTagData(data, mapping) {
          var oReturn = {};
          for (var i = 0; i < data.length; i++) {
            oReturn[data[i].DIMENSION.brand] = {
              tag1: (data[i].RESULT || {})[mapping] || 0,
              tag2: (data[i].PVP || {})['PVP_' + mapping] || undefined
            };
          }
          return oReturn;
        },
        tagConfig: {
          tag1Unit: {
            suff: '',
            roundoff: 0
          },
          tag2Unit: {
            suff: '%'
          }
        },
        chart: {
          chartOptions: {
            type: 'line',
            xFormat: '%m/%d/%Y',
            timeseries: 'feed_date',
            tooltip_format: {
              All: {
                roundoff: 0
              },
              Revenue: {
                pre: 'currency'
              }
            },
            xDataKey: 'x',
            grid: 'xy'
          }
        }
      }
    },
    widget2: {
      meta: {
        type: 'chart',
        hideOnNoData: false,
        width: 1,
        action: 'getAssortmentAverageData',
        key: 'AssortmentAverageData',
        listen: {
          reviewsFilterApplied: {
            action: 'getAssortmentAverageData'
          }
        },
        localFilters: []
      },
      header: {
        show: true,
        title: 'ASSORTMENT ANALYSIS BY AVERAGE RATING',
        tooltip: '',
        actions: {
          // 'filter': {
          //   optionsGetter: 'getReviewsLocalFilters',
          //   selectionGetter: 'getSelectedReviewsLocalFilters',
          //   items: [
          //     {
          //       isMulti: false,
          //       dimensionName: 'search',
          //       key: 'reviewsLocalFilter',
          //       action: 'getAssortmentAverageData',
          //       value: [],
          //       selectTagEdit: true
          //     }
          //   ]
          // },
          date: {
            mode: 'Single',
            action: {
              setAssortmentAverageDate: {},
              getAssortmentAverageData: {
                transform: function (config, data, that) {
                  return config;
                }
              }
            },
            maxDate: 'review_ratings'
          }
        }
      },
      footer: {
        date: 'review_ratings'
      },
      body: {
        APIConfig: {
          cubeName: 'review_ratings_widget',
          measuresList: ['avg_review_rating', 'count'],
          groupByDimensionsList: ['brand', 'client_flag'],
          orderByList: [
            {
              dimension: 'client_flag',
              direction: 'ASC'
            },
            {
              dimension: 'count',
              direction: 'DESC'
            },
            {
              dimension: 'avg_review_rating',
              direction: 'DESC'
            }
          ],
          timeseriesDimension: 'feed_date',
          timeseriesEnabled: false,
          getLatestAvailableInsteadOfRollup: false,
          pvpenabled: false,
          yoyenabled: false,
          getSharePercentage: false,
          where: {
            date: { from: '2018-04-13', to: '2018-04-13' },
            dimensionNameValueList: [
              {
                dimensionName: 'product_group',
                dimensionValue: 'Pantry'
              }
            ]
          },
          dedupBeforeRollup: {
            enableDedupBeforeRollup: true,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: []
          }
        },
        getTagData(data, mapping) {
          var oReturn = {};
          for (var i = 0; i < data.length; i++) {
            var key =
              data[i].DIMENSION.brand === 'client_brands'
                ? 'My Brands'
                : data[i].DIMENSION.brand;
            var regEx = /^-?\d+\.?\d*$/;
            if (regEx.test(key) === true) {
              key = key + ' ';
            }
            oReturn[key] = {
              tag1: (data[i].RESULT || {}).avg_review_rating || 0
              // tag2: (data[i]['PVP'] || {})['avg_review_rating'] || {}
            };
          }
          return oReturn;
        },
        tagConfig: {
          tag1Unit: {
            suff: ''
          },
          tag2Unit: {
            suff: '%'
          }
        },
        chart: {
          chartOptions: {
            type: 'bar',
            // xFormat: '%m/%d/%Y',
            //  timeseries: 'x',
            tooltip_format: {
              All: {
                roundoff: 0
              }
            },
            grid: 'xy'
          },
          axis: {
            x: 'x',
            rotated: true
          },
          type: 'bar',
          bar: {
            width: {
              ratio: 0.25
            }
          }
        }
      }
    },

    widget3: {
      meta: {
        hideOnNoData: false,
        width: 1,
        action: 'getSkuLevelBreakdownData',
        key: 'SkuLevelBreakdownData',
        listen: {
          reviewsFilterApplied: {
            action: 'getSkuLevelBreakdownData',
            transform: function (config, data, that) {
              var _config = config;
              _config.body.APIConfig.page = 1;
              return _config;
            }
          }
        }
      },
      header: {
        search: true,
        searchPlaceholder: 'Search for a SKU or ASIN',
        searchParams: ['search'],
        show: true,
        title: 'SKU LEVEL BREAKDOWN',
        tooltip:
          'Latest organic search performance data for keywords being tracked.',
        actions: {
          download: {
            fileName: 'ReviewsSkuLevelBreakdownTableData',
            colMap: [
              {
                displayName: 'ASIN',
                dbKey: 'asin'
              },
              {
                displayName: 'ASIN Name',
                dbKey: 'title'
              },
              // {
              //   displayName: 'Image URL',
              //   dbKey: 'image_url'
              // },
              {
                displayName: 'Reviews Received',
                dbKey: 'total_new_reviews'
              },
              // {
              //   title: 'Revenue Change',
              //   field: 'asin_revenue'
              // },
              {
                displayName: '1 Star',
                dbKey: 'star1_new_reviews'
              },
              {
                displayName: '2 Star',
                dbKey: 'star2_new_reviews'
              },
              {
                displayName: '3 Star',
                dbKey: 'star3_new_reviews'
              },
              {
                displayName: '4 Star',
                dbKey: 'star4_new_reviews'
              },
              {
                displayName: '5 Star',
                dbKey: 'star5_new_reviews'
              }
            ],
            action: 'downloadSkuLevelBreakdownTableData',
            transform: function (config, data, that) {
              var _config = Vue.util.extend({}, config);
              _config = JSON.parse(JSON.stringify(config));
              _config.body.APIConfig.page = undefined;
              _config.body.APIConfig.limit = undefined;
              return _config;
            }
          },
          date: {
            action: {
              setReviewsAndRatingsCustomDate: {},
              getSkuLevelBreakdownData: {
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            },
            maxDate: 'review_ratings',
            defaultRangeSelect: 'last7Days',
            dateMode: {
              mode: 'week',
              count: 0
            },
            additionalRanges: {
              add: ['last7Days', 'last4Week', 'last13Week', 'last52Week'],
              order: {
                last7Days: 0,
                last4Week: 2,
                last13Week: 3,
                last52Week: 4
              }
            }
          }
        }
      },
      footer: {
        date: 'review_ratings'
      },
      body: {
        APIConfig: widget3Data,
        columnDefs: getColumnDefinition(skuLevelBreakdownColumns)
      }
    }
  }
};
