<template>
  <div
    v-tippy="{
      placement: tooltipPlacement,
      distance: tooltipDistance,
      maxWidth: tooltipMaxWidth,
      theme: tooltipTheme
    }"
    class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left u-text-overflow-ellipsis u-display-flex u-flex-align-items-center"
    :title="tooltipText"
    @click="iconClickEvent($event)"
  >
    <div
      class="u-display-flex u-flex-justify-content-flex-start u-spacing-pr-xs"
    >
      <div v-if="showCiqBot">
        <rb-icon
          class="rb-icon--medium u-color-blue-base"
          :icon="'bot'"
        />
      </div>
      <div v-else>
        <userAvatar
          :text="cellValue"
          diameter="16px"
          avatar-font-size="u-font-size-7"
          :has-text="false"
        />
        <!-- <div class="u-font-size-7 u-color-grey-white u-text-case-title u-display-flex u-flex-align-items-center u-flex-justify-content-center" :style="{ 'background-color': '#bd10e0', 'border-radius': '50%', 'max-height': '16px', 'min-height': '16px', 'min-width': '16px', 'max-width': '16px' }">{{cellValue[0]}}</div> -->
      </div>
    </div>
    <span class="u-text-overflow-ellipsis">{{ cellValue }}</span>
  </div>
</template>
<script>
// import userAvatar from '@/components/globals/pages/ciq-internal-tools/containers/components/userAvatar'
import userAvatar from '@/components/widgets/userAvatar';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'userCell',
  components: {
    userAvatar
  },
  computed: {
    cellValue() {
      return this.params.value;
    },
    showCiqBot() {
      if (
        this.params.ciqBotCompareValue &&
        this.params.value === this.params.ciqBotCompareValue
      ) {
        return true;
      }
      return this.params.value === 'CIQ Bot';
    },
    tooltipPlacement() {
      if (this.params.tooltipPlacement) {
        return this.params.tooltipPlacement;
      }
      return 'top';
    },
    tooltipDistance() {
      if (this.params.tooltipDistance) {
        return this.params.tooltipDistance;
      }
      return 0;
    },
    tooltipMaxWidth() {
      if (this.params.tooltipMaxWidth) {
        return this.params.tooltipMaxWidth;
      }
      return '500px';
    },
    tooltipTheme() {
      if (this.params.tooltipTheme) {
        return this.params.tooltipTheme;
      }
      return 'click-to-expand';
    },
    tooltipText() {
      if (this.params.tooltipText) {
        return this.params.tooltipText;
      }
      // return 'Actions with this icon are supported by the CommerceIQ platform.'
      // return ''
      return this.params.value;
    }
  },
  methods: {
    iconClickEvent(event) {
      if (this.params.iconClickEvent) {
        this.params.iconClickEvent(this, event);
      }
    }
  }
};
</script>
