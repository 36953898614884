<template>
  <valueVsPvpVsLYCell
    :value="dataObj.metricValue"
    :type="dataObj.metricType"
    :unit="dataObj.metricUnit"
    :first-pvp-value="dataObj.currentPeriod_data1"
    :second-pvp-value="dataObj.previousPeriod_data1"
    :first-pvp-diff-value="dataObj.currentPeriod_data2"
    :second-pvp-diff-value="dataObj.previousPeriod_data2"
    :first-pvp-value-inverted="dataObj.currentPeriod_invertTag"
    :second-pvp-value-inverted="dataObj.previousPeriod_invertTag"
  />
</template>

<script>
import Vue from 'vue';
import valueVsPvpVsLYCell from '@/components/basic/valueVsPvpVsLYCell.vue';

export default {
  components: {
    valueVsPvpVsLYCell
  },
  data() {
    return {
      columnKey: '',
      dataObj: {}
    };
  },
  created() {
    Vue.component('valueVsPvpVsLYCell', valueVsPvpVsLYCell);
    this.columnKey = this.params?.colDef?.field;
    this.dataObj = this.params?.data?.[this.columnKey];
    if (!this.dataObj.metricType || !this.dataObj.metricUnit) {
      const metricAlias = this.columnKey?.includes('__3p')
        ? this.columnKey?.split('__3p')?.[0]
        : this.columnKey?.includes('__retail')
        ? this.columnKey?.split('__retail')?.[0]
        : this.columnKey;
      this.dataObj.metricType = this.params?.data?.[metricAlias]?.metricType;
      this.dataObj.metricUnit = this.params?.data?.[metricAlias]?.metricUnit;
    }
  }
};
</script>
