<template>
  <div class="u-display-flex u-width-100">
    <div
      v-if="isCampaignListEmpty"
      class="cell--content u-display-flex u-flex-align-items-center"
      :class="{ disabled: !isActionApplicable }"
      @click.stop="selectRow()"
    >
      <rb-icon
        class="u-flex-0 rb-icon--small u-color-blue-base"
        :icon="'add-circle-fill'"
      />
      <span class="u-flex-0 u-color-blue-base u-spacing-pl-xs"
        >Add Campaign</span
      >
    </div>
    <div
      v-else
      class="u-flex-0"
    >
      <div
        v-tippy="tippyOptions"
        class="cell--content u-color-grey-base u-text-overflow-ellipsis"
        :title="params.data['campaign_list']"
      >
        {{ params.data['campaign_list'] }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import actionsMixin from '@/utils/mixins/actionsMixin';

export default Vue.extend({
  name: 'AddCampaign',
  mixins: [actionsMixin],
  data() {
    return {
      tippyOptions: {
        placement: 'top',
        offset: '-10, -50',
        delay: [500, 0],
        animation: 'scale',
        interactive: true,
        maxWidth: '200px',
        theme: 'click-to-expand',
        duration: [150, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    isCampaignListEmpty() {
      return !this.params.data.campaign_list;
    }
  },
  mounted() {
    var clamp = Math.ceil((this.params.eGridCell.clientHeight - 32) / 16);
    clamp = clamp > 0 ? clamp : 1;
    this.params.eGridCell.classList.add('u-clamp-' + clamp);
    // remove the click to expand feature if no ellipsis is present
    this.$nextTick(() => {
      let toExpand = false;
      const el = this.$el.querySelector('.cell--content');
      if (
        el.clientHeight < el.scrollHeight - 1 ||
        el.clientWidth < el.scrollWidth
      ) {
        toExpand = true;
      } else if (el.children && el.children.length > 0) {
        const children = el.querySelectorAll('*');
        for (const child of children) {
          if (
            child.clientWidth < el.scrollWidth ||
            child.clientWidth < child.scrollWidth
          ) {
            toExpand = true;
            break;
          }
        }
      }
      if (el && el._tippy && !toExpand) {
        el._tippy.destroy();
        el.removeAttribute('title');
        el.removeAttribute('data-original-title');
        el.classList.add('u-cursor-text');
      } else {
        el.classList.add('u-cursor-pointer');
      }
    });
  },
  methods: {
    selectRow() {
      this.params.node.setSelected(true);
      this.params.context.componentParent.openActionsWorkspace(
        { id: 'edit', successMsg: 'approved.' },
        false,
        1
      );
    }
  }
});
</script>
<style lang="css" scoped>
.cell--content.disabled {
  opacity: 0.5;
  pointer-events: none;
}
@supports not (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content,
  .u-clamp-2 .cell--content,
  .u-clamp-3 .cell--content,
  .u-clamp-4 .cell--content,
  .u-clamp-5 .cell--content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    word-break: break-all;
  }
}
@supports (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-2 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-3 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-4 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-5 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
}
</style>
