<template>
  <div class="u-width-100 u-display-flex u-flex-direction-column">
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="
        (val) => {
          if (val) showModal = !val;
        }
      "
    />
    <div class="u-width-100 u-display-flex u-flex-align-items-flex-start">
      <div
        class="u-spacing-pr-m u-spacing-pb-xl u-font-size-5 u-font-weight-600"
        :style="{ 'padding-top': currentIndex == 0 ? '40px' : '10px' }"
      >
        {{ currentIndex + 1 }}.
      </div>

      <!-- Event label -->
      <div class="u-spacing-pr-xl">
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Event name:
        </div>
        <rb-input
          v-model="localEventData.label"
          class="u-display-flex u-flex-align-items-center seasonality-event-form-element"
          :class-list="[
            'u-width-240px',
            localEventData.titleInvalid ? 'has-error' : ''
          ]"
          @input="eventLabelChanged"
        />
      </div>

      <!-- Event scope -->
      <div class="u-spacing-pr-xl">
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Scope:
        </div>
        <rb-select
          v-tippy="{ placement: 'top', arrow: true }"
          :title="scopeDropDownDisplayText"
          class="valueDropdown seasonality-event-form-element"
          style="width: 140px"
          :send-details="true"
          :on-close="selectEventScope"
          :options="scopeItems"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
            style="height: 34px"
          >
            <div
              class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-mid-light"
            >
              {{ scopeDropDownDisplayText }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-mid-light"
              icon="caret-down"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-width-100 u-flex-justify-content-space-between u-flex-align-items-center"
              :title="option.name"
            >
              <div>
                {{ option.name }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-mid-light"
                icon="arrow1-right"
              />
            </div>
          </template>
        </rb-select>
        <rb-select
          v-click-outside="detectUnselectedScopeItems"
          :class-name="'scope-option-selector seasonality-event-form-element'"
          position="is-bottom-left"
          :place-holder="'Search ' + tempCurrentScope"
          :is-multi-select="false"
          :on-close="selectScopeItems"
          :send-details="true"
          :options="selectedScopeOptions"
        >
          <div
            :ref="eventObj.eventId + '-scope'"
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
            style="display: none"
          />
        </rb-select>
      </div>

      <!-- Event period -->
      <div class="u-spacing-pr-xl u-display-flex u-flex-direction-column">
        <div class="timePeriod_wrp u-display-flex">
          <!-- Event start date -->
          <div class="u-spacing-pr-xl">
            <div
              v-if="showHeader"
              class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
            >
              Start date:
            </div>
            <div
              v-click-outside="hideStartCalendar"
              class="seasonality-event-form-element"
            >
              <span
                class="filter--token u-font-size-6 u-border-radius-xs u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('startDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{ localEventData.start | dateFormatFilter }}</span
                >
              </span>
              <datepicker
                ref="startDate"
                v-model="localEventData.start"
                name="startDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>

          <!-- Event end date -->
          <div>
            <div
              v-if="showHeader"
              class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
            >
              End date:
            </div>
            <div
              v-click-outside="hideEndCalendar"
              class="seasonality-event-form-element"
            >
              <span
                class="filter--token u-font-size-6 u-border-radius-xs u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('endDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{ localEventData.end | dateFormatFilter }}</span
                >
              </span>
              <datepicker
                ref="endDate"
                v-model="localEventData.end"
                name="endDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>
        </div>

        <div
          v-if="warningMessage"
          class="u-display-flex u-spacing-pt-s"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small u-color-orange-base u-spacing-mr-xs u-flex-align-self-flex-start"
            icon="warning-triangle"
          />
          <div class="u-font-size-5 u-color-orange-base">
            {{ warningMessage }}
          </div>
        </div>
        <div
          v-if="dateErrorMsgs.length"
          class="u-display-flex u-spacing-pt-s"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small u-color-red-base u-spacing-mr-xs u-flex-align-self-flex-start"
            icon="error-fill"
          />
          <div class="u-display-flex u-flex-direction-column">
            <div
              v-for="(error, index) in dateErrorMsgs"
              :key="error"
              class="u-font-size-5 u-color-red-base"
            >
              {{ error
              }}<span
                v-if="
                  dateErrorMsgs.length > 1 && index < dateErrorMsgs.length - 1
                "
                >,</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- event budget value -->
      <div
        class="event-info-wrapper u-spacing-pr-xl u-flex-1 u-flex-shrink-past-min-size"
      >
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Budget:
        </div>
        <div
          class="u-display-flex u-flex-direction-column u-width-100 seasonality-event-form-element"
        >
          <div class="u-display-flex u-flex-direction-row u-width-100">
            <div class="u-display-flex u-flex-0">
              <div
                class="u-display-flex u-width-100"
                style="position: relative"
              >
                <rb-input
                  v-model="localEventData.value"
                  type="number"
                  class="u-display-flex u-flex-align-items-center"
                  :class-list="[
                    'u-width-80px',
                    localEventData.budgetValueInvalid ||
                    localEventData.remainingBudgetInvalid
                      ? 'has-error'
                      : ''
                  ]"
                  :min="0"
                  :max="getMaxValue"
                  @input="budgetValueChanged"
                />
                <div
                  v-if="localEventData.valueType === 'PERCENTAGE'"
                  style="position: absolute; right: 8px; top: 12px"
                  class="u-font-size-5"
                >
                  %
                </div>
                <div
                  v-if="localEventData.valueType === 'ABSOLUTE'"
                  style="position: absolute; left: 8px; top: 12px"
                  class="u-font-size-5"
                >
                  $
                </div>
              </div>
            </div>
            <div
              class="valueDropdown u-display-flex u-flex-1 u-flex-shrink-past-min-size u-flex-align-items-center u-spacing-ml-s u-spacing-p-s"
            >
              <span
                v-tippy="{ placement: 'top', arrow: true, offset: '0, 10' }"
                :title="eventBudgetInfo"
                class="u-flex-1 u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
                >{{ eventBudgetInfo }}</span
              >
            </div>
          </div>
          <div
            class="u-display-flex u-flex-1 u-flex-justify-content-space-between"
          >
            <div class="u-font-size-5 u-spacing-mt-s u-color-grey-mid-light">
              <span
                class="u-cursor-pointer"
                :class="{
                  'u-color-blue-base': localEventData.valueType === 'PERCENTAGE'
                }"
                @click="valueTypeChanged('PERCENTAGE')"
                >Percent</span
              >
              <span> | </span>
              <span
                class="u-cursor-pointer"
                :class="{
                  'u-color-blue-base': localEventData.valueType === 'ABSOLUTE'
                }"
                @click="valueTypeChanged('ABSOLUTE')"
                >Absolute</span
              >
            </div>
            <div
              v-if="
                !isNaN(remainingBudgetAmount) &&
                monthlyBudgetAmount &&
                isSeasonalityBudgetValid
              "
              class="u-text-align-right u-spacing-mt-s u-font-size-5"
              :class="{ 'u-color-red-base': remainingBudgetAmount < 0 }"
            >
              <span>${{ remainingBudgetAmount }}</span
              ><span
                :class="[
                  remainingBudgetAmount < 0
                    ? 'u-color-red-base'
                    : 'u-color-grey-mid-light'
                ]"
              >
                left</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Event actions -->
      <div class="event-actions u-display-flex seasonality-event-form-element">
        <div
          v-show="!localEventData.isSaved"
          class="u-cursor-pointer u-display-flex u-spacing-mr-m"
          :class="{ 'disabled u-pointer-events-none': isChartLoading }"
          @click="saveEvent()"
        >
          <span
            class="u-bg-color-blue-base u-color-grey-white u-font-size-7 u-spacing-pv-xs u-spacing-ph-m u-border-radius-s u-font-weight-600"
            :style="{
              'margin-top': currentIndex == 0 ? '40px' : '10px',
              height: '19px'
            }"
            >Save</span
          >
        </div>
        <div
          v-show="!localEventData.isSaved"
          class="u-cursor-pointer u-spacing-mr-s"
          :class="{ 'disabled u-pointer-events-none': isChartLoading }"
          @click="localEventData.newEvent ? deleteEvent() : undoChanges()"
        >
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-spacing-pb-xl"
            :style="{ 'padding-top': currentIndex == 0 ? '42px' : '12px' }"
            icon="cross"
          />
        </div>
        <div
          v-show="localEventData.isSaved"
          class="u-cursor-pointer"
          :class="{ 'disabled u-pointer-events-none': isChartLoading }"
          @click="showDeleteEventModal()"
        >
          <rb-icon
            class="rb-icon--x-medium u-color-grey-lighter u-spacing-pb-xl"
            :style="{ 'padding-top': currentIndex == 0 ? '38px' : '8px' }"
            icon="trash"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import stepsMixin from '@/components/ams/budgetPlanner/steps/stepMixin.js';
import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import modal from '@/components/widgets/modal.vue';
import {
  scopeItemsRetailerMap,
  tempCurrentScopeRetailerMap
} from '@/components/ams/budgetPlanner/retailerConfig';

export default {
  components: {
    Datepicker,
    modal
  },
  mixins: [stepsMixin],
  props: {
    isChartLoading: {
      type: Boolean,
      default: false
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    eventObj: {
      default: '',
      type: Object
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    remainingBudgetHashMap: {
      type: Object,
      default: () => {}
    },
    effectByParentMap: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showModal: false,
      modalConfig: {
        bodyTitle: {
          text: 'Delete event',
          highlightText: '',
          icon: 'trash'
        },
        body: {
          text: 'This event will be deleted.'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showModal = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Delete',
            onClick: () => {
              this.deleteEvent();
            },
            icon: '',
            show: true
          }
        }
      },
      localEventData: {},
      localHashMap: {},
      tempCurrentScope: { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      scopeItems: [
        { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
        { name: 'Profiles', key: 'PROFILE' },
        { name: 'Portfolios', key: 'PORTFOLIO' }
      ],
      selectedScopeOptions: [],
      budgetValueNull: false
    };
  },
  computed: {
    disabledDates() {
      const nextYearPlan = this.$route.query.nextYearPlan;
      const from = this.$route.query.nextYearPlan
        ? new Date(new Date().getFullYear() + 1, 11, 31)
        : new Date(new Date().getFullYear() + 1, 0, 1);
      const to = nextYearPlan
        ? new Date(new Date().getFullYear() + 1, 0, 1)
        : new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );
      return {
        from,
        to
      };
    },
    scopeDropDownDisplayText() {
      let displayText = 'Entire Business';
      const retailer = this.$store.getters.getRetailer;
      if (
        this.localEventData.selectedScope &&
        !Object.keys(this.localEventData.selectedScope).length
      ) {
        displayText = this.tempCurrentScope.name;
      } else if (
        this.localEventData.selectedScope &&
        (this.localEventData.selectedScope.dimensionName === 'PROFILE' ||
          this.localEventData.selectedScope.dimensionName === 'PORTFOLIO')
      ) {
        const tempText =
          tempCurrentScopeRetailerMap[retailer][
            this.localEventData.selectedScope.dimensionName
          ];
        displayText = `${this.capitalize(tempText)}: ${
          this.localEventData.selectedScope.title
        }`;
      }
      return displayText;
    },
    isSeasonalityBudgetValid() {
      const isSeasonalityInFuture =
        moment(this.localEventData.start).diff(moment(), 'days') >= 0;
      return isSeasonalityInFuture;
    },
    monthlyBudgetAmount() {
      if (
        this.localEventData.start &&
        this.localEventData.end &&
        Object.keys(this.localEventData.selectedScope).length &&
        (this.budgetPlannerEntities || []).length > 0
      ) {
        const scopeItem = this.localEventData.selectedScope;
        const month = moment(this.localEventData.start).format('MMM');
        const entityObj = this.budgetPlannerEntities.filter((entity) => {
          return scopeItem.dimensionName === 'ENTIRE_BUSINESS'
            ? entity.executionScope.businessScope === scopeItem.dimensionValue
            : entity.executionScope.businessScopeId ===
                scopeItem.dimensionValue;
        })[0];
        let keyToSelect = '';
        if (
          scopeItem.dimensionName === 'ENTIRE_BUSINESS' ||
          scopeItem.dimensionName === 'PROFILE'
        ) {
          keyToSelect = 'value';
        } else {
          keyToSelect = 'absoluteMonthlyBudget';
        }
        return entityObj.executionStrategies.find(
          (item) =>
            item.type === 'PACING' &&
            item.label.toUpperCase() === month.toUpperCase()
        )[keyToSelect];
      }
    },
    eventBudgetInfo() {
      let infoText;
      if (this.monthlyBudgetAmount) {
        infoText = `of ${moment(this.localEventData.start).format(
          'MMMM'
        )}'s budget plan of $${this.monthlyBudgetAmount}`;
        this.localEventData.eventInfo.description = infoText;
      }
      return infoText;
    },
    remainingBudgetAmount() {
      let remainingBudget = null;
      if (
        this.localHashMap &&
        this.localEventData &&
        this.localEventData.selectedScope &&
        this.localEventData.selectedScope.dimensionValue &&
        this.localEventData.value >= 0 &&
        this.localEventData.valueType &&
        this.localEventData.start &&
        this.localEventData.end
      ) {
        const currScopeValue = this.localEventData.selectedScope.dimensionValue;
        const parentScope =
          this.localEventData.selectedScope.relatedEntityScope;
        const parentScopeValue =
          this.localEventData.selectedScope.relatedEntityId;
        const currMonth = moment(this.localEventData.start)
          .format('MMM')
          .toUpperCase();
        let currentMonthHash = this.localHashMap[currMonth];

        if (!currentMonthHash) {
          currentMonthHash = {};
          let initialBudgetAmount = this.budgetPlannerEntities
            .filter(
              (entity) =>
                entity.executionScope.businessScope === 'ENTIRE_BUSINESS'
            )[0]
            .executionStrategies.filter(
              (item) =>
                item.type === 'PACING' && item.label.toUpperCase() === currMonth
            )[0].value;
          if (
            currMonth === moment().format('MMM').toUpperCase() &&
            this.$parent.currentMonthAmsSpend.ENTIRE_BUSINESS >= 0
          ) {
            initialBudgetAmount =
              initialBudgetAmount -
              this.$parent.currentMonthAmsSpend.ENTIRE_BUSINESS;
          }
          currentMonthHash.ENTIRE_BUSINESS = {
            remainingBudget: initialBudgetAmount,
            type: 'ENTIRE_BUSINESS'
          };
        }

        if (
          parentScope === 'ENTIRE_BUSINESS' &&
          !currentMonthHash.ENTIRE_BUSINESS[currScopeValue]
        ) {
          let initialBudgetAmount = this.budgetPlannerEntities
            .filter(
              (entity) =>
                entity.executionScope.businessScopeId === currScopeValue
            )[0]
            .executionStrategies.filter(
              (item) =>
                item.type === 'PACING' && item.label.toUpperCase() === currMonth
            )[0].value;
          if (
            currMonth === moment().format('MMM').toUpperCase() &&
            this.$parent.currentMonthAmsSpend[currScopeValue] >= 0
          ) {
            initialBudgetAmount =
              initialBudgetAmount -
              this.$parent.currentMonthAmsSpend[currScopeValue];
          }
          currentMonthHash.ENTIRE_BUSINESS[currScopeValue] = {
            remainingBudget: initialBudgetAmount,
            type: 'PROFILE'
          };
        }

        if (parentScope === 'PROFILE') {
          if (!currentMonthHash.ENTIRE_BUSINESS[parentScopeValue]) {
            let initialBudgetAmount = this.budgetPlannerEntities
              .filter(
                (entity) =>
                  entity.executionScope.businessScopeId === parentScopeValue
              )[0]
              .executionStrategies.filter(
                (item) =>
                  item.type === 'PACING' &&
                  item.label.toUpperCase() === currMonth
              )[0].value;
            if (
              currMonth === moment().format('MMM').toUpperCase() &&
              this.$parent.currentMonthAmsSpend[parentScopeValue] >= 0
            ) {
              initialBudgetAmount =
                initialBudgetAmount -
                this.$parent.currentMonthAmsSpend[parentScopeValue];
            }
            currentMonthHash.ENTIRE_BUSINESS[parentScopeValue] = {
              remainingBudget: initialBudgetAmount,
              type: 'PROFILE'
            };
          }

          if (
            !currentMonthHash.ENTIRE_BUSINESS[parentScopeValue][currScopeValue]
          ) {
            let initialBudgetAmount = this.budgetPlannerEntities
              .filter(
                (entity) =>
                  entity.executionScope.businessScopeId === currScopeValue
              )[0]
              .executionStrategies.filter(
                (item) =>
                  item.type === 'PACING' &&
                  item.label.toUpperCase() === currMonth
              )[0].absoluteMonthlyBudget;
            if (
              currMonth === moment().format('MMM').toUpperCase() &&
              this.$parent.currentMonthAmsSpend[currScopeValue] >= 0
            ) {
              initialBudgetAmount =
                initialBudgetAmount -
                this.$parent.currentMonthAmsSpend[currScopeValue];
            }
            currentMonthHash.ENTIRE_BUSINESS[parentScopeValue][currScopeValue] =
              {
                remainingBudget: initialBudgetAmount,
                type: 'PORTFOLIO'
              };
          }
        }

        if (
          this.localEventData.selectedScope.dimensionName === 'ENTIRE_BUSINESS'
        ) {
          remainingBudget = currentMonthHash.ENTIRE_BUSINESS.remainingBudget;
        } else if (
          this.localEventData.selectedScope.dimensionName === 'PROFILE'
        ) {
          remainingBudget =
            currentMonthHash.ENTIRE_BUSINESS[currScopeValue].remainingBudget;
        } else if (
          this.localEventData.selectedScope.dimensionName === 'PORTFOLIO'
        ) {
          remainingBudget =
            currentMonthHash.ENTIRE_BUSINESS[parentScopeValue][currScopeValue]
              .remainingBudget;
        }

        remainingBudget =
          remainingBudget - this.subtractValue(currScopeValue, currMonth);
        remainingBudget =
          Math.round((remainingBudget + Number.EPSILON) * 100) / 100;
      }
      return remainingBudget;
    },
    getMaxValue() {
      if (this.localEventData.start && this.localEventData.end) {
        return this.localEventData.valueType === 'PERCENTAGE'
          ? 100
          : this.monthlyBudgetAmount;
      } else {
        return 0;
      }
    },
    dateRangeOutsideOneMonth() {
      // making sure date range is in same month
      const start = moment(this.localEventData.start).format('M');
      const end = moment(this.localEventData.end).format('M');
      return start !== end;
    },
    endDateLessThanStartDate() {
      const start = moment(this.localEventData.start);
      const end = moment(this.localEventData.end);
      return end < start;
    },
    warningMessage() {
      if (
        this.monthlyBudgetAmount === null ||
        this.monthlyBudgetAmount === undefined
      ) {
        return 'Budget plan is not defined for the selected month';
      }
    },
    dateErrorMsgs() {
      const dateErrorMsgs = [];
      if (this.dateRangeOutsideOneMonth) {
        dateErrorMsgs.push('Date range is not within a month');
      }
      if (this.endDateLessThanStartDate) {
        dateErrorMsgs.push('End date is less than start date');
      }
      return dateErrorMsgs;
    },
    isBudgetValueValid() {
      return this.localEventData.valueType === 'PERCENTAGE'
        ? !this.budgetValueNull &&
            this.localEventData.value >= 0 &&
            this.localEventData.value <= 100
        : !this.budgetValueNull &&
            this.localEventData.value >= 0 &&
            this.localEventData.value <= this.monthlyBudgetAmount;
    },
    isEventDataValid() {
      let isValid = false;
      if (
        this.localEventData.label &&
        this.localEventData.label.trim() &&
        !this.dateErrorMsgs.length &&
        this.isBudgetValueValid &&
        this.remainingBudgetAmount >= 0
      ) {
        isValid = true;
      }
      return isValid;
    }
  },
  watch: {
    'localEventData.value': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (oldVal >= 0) {
          this.updateLocalHashMap();
        }
      }
    },
    'localEventData.valueType': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updateLocalHashMap();
      }
    },
    'localEventData.selectedScope.dimensionValue': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updateLocalHashMap();
      }
    },
    'localEventData.start': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updateLocalHashMap();
      }
    },
    'localEventData.end': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updateLocalHashMap();
      }
    }
  },
  created() {
    const retailer = this.$store.getters.getRetailer;
    this.scopeItems = scopeItemsRetailerMap[retailer].scopeItems;
  },
  mounted() {
    console.log('ADD EVENT');
    this.resetEventData();
    eventBus.$on('remainingBudgetHashMapUpdated', (updatedHashMap) => {
      this.localHashMap = cloneDeep(updatedHashMap);
    });
  },
  methods: {
    subtractValue(scopeId, month) {
      return (
        (((this.effectByParentMap || {})[scopeId] || {})[month] || {})
          .subtractValue || 0
      );
    },
    capitalize(text) {
      if (typeof text !== 'string') return '';
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    hideStartCalendar() {
      this.$refs.startDate.close();
      this.$nextTick(() => {
        this.localEventData.start =
          this.$refs.startDate &&
          moment(this.$refs.startDate.value).format('YYYY-MM-DD');
        this.updateEventData();
      });
    },
    hideEndCalendar() {
      this.$refs.endDate.close();
      this.$nextTick(() => {
        this.localEventData.end =
          this.$refs.endDate &&
          moment(this.$refs.endDate.value).format('YYYY-MM-DD');
        this.updateEventData();
      });
    },
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    eventLabelChanged(value) {
      this.localEventData.label = value;
      this.updateEventData();
    },
    valueTypeChanged(valueType) {
      this.localEventData.valueType = valueType;
      this.$nextTick(() => {
        this.updateEventData();
      });
    },
    budgetValueChanged(value) {
      this.budgetValueNull = false;
      if (value === null || value === '') {
        this.budgetValueNull = true;
      }
      this.localEventData.value = Number(value);
      this.$nextTick(() => {
        this.updateEventData();
      });
    },
    updateAbsoluteAmount() {
      if (this.monthlyBudgetAmount && this.localEventData.value >= 0) {
        const num =
          this.localEventData.valueType === 'ABSOLUTE'
            ? this.localEventData.value
            : (this.localEventData.value / 100) * this.monthlyBudgetAmount;
        this.localEventData.calculatedAbsAmount =
          Math.round((num + Number.EPSILON) * 100) / 100;
      }
    },
    detectUnselectedScopeItems() {
      const elem = document.querySelector(
        '.scope-option-selector.rb-select-menu'
      );
      this.$nextTick(() => {
        if (elem) {
          const isDropDownOpen =
            elem.getBoundingClientRect().width &&
            elem.getBoundingClientRect().height;
          if (
            isDropDownOpen &&
            (this.tempCurrentScope.key === 'PROFILE' ||
              this.tempCurrentScope.name === 'Portfolios') &&
            !Object.keys(this.localEventData.selectedScope).length
          ) {
            this.tempCurrentScope = {
              name: 'Entire Business',
              key: 'ENTIRE_BUSINESS'
            };
            this.localEventData.selectedScope = {
              dimensionName: 'ENTIRE_BUSINESS',
              dimensionValue: 'ENTIRE_BUSINESS',
              title: 'Entire Business'
            };
          }
        }
      });
    },
    selectScopeItems(content, selections) {
      this.localEventData.selectedScope = selections[0];
      if (
        this.localEventData.selectedScope.dimensionName === 'ENTIRE_BUSINESS'
      ) {
        this.$nextTick(() => {
          this.updateEventData();
        });
      }
    },
    selectEventScope(context, selectedScope) {
      if (selectedScope.length > 0) {
        this.tempCurrentScope = selectedScope[0];
        if (this.tempCurrentScope.key === 'PROFILE') {
          this.localEventData.selectedScope = {};
          this.selectedScopeOptions = this.profileDropdownOptions;
          this.$refs[this.eventObj.eventId + '-scope'].click();
        } else if (this.tempCurrentScope.name === 'Portfolios') {
          this.localEventData.selectedScope = {};
          this.selectedScopeOptions = this.portfolioDropdownOptions;
          this.$refs[this.eventObj.eventId + '-scope'].click();
        } else {
          this.localEventData.selectedScope = {
            dimensionName: 'ENTIRE_BUSINESS',
            dimensionValue: 'ENTIRE_BUSINESS'
          };
        }
      }
      this.$nextTick(() => {
        this.updateEventData();
      });
    },
    updateLocalHashMap() {
      this.localHashMap = this.$parent.clearRemainingBudgetHashMap(
        this.localHashMap,
        this.localEventData
      );
      this.updateAbsoluteAmount();
      this.localHashMap = this.$parent.updateRemainingBudgetHashMap(
        this.localHashMap,
        this.localEventData
      );
    },
    updateEventData(comingFromSave) {
      this.localEventData.isValid = this.isEventDataValid;
      this.localEventData.remainingBudgetAmount = this.remainingBudgetAmount;
      if (!comingFromSave) {
        this.localEventData.isSaved =
          this.localEventData.label === this.eventObj.label &&
          this.localEventData.start === this.eventObj.start &&
          this.localEventData.end === this.eventObj.end &&
          this.localEventData.value === this.eventObj.value &&
          this.localEventData.valueType === this.eventObj.valueType &&
          this.localEventData.selectedScope.dimensionName ===
            this.eventObj.selectedScope.dimensionName &&
          this.localEventData.selectedScope.dimensionValue ===
            this.eventObj.selectedScope.dimensionValue;
        this.$parent.setSaveStatus(this.localEventData);
      }
      this.$emit('onUpdateEvent', this.localEventData, comingFromSave);
    },
    undoChanges() {
      this.resetEventData();
    },
    resetEventData() {
      this.localEventData = cloneDeep(this.eventObj);
      this.localHashMap = cloneDeep(this.remainingBudgetHashMap);
      this.$parent.setSaveStatus(this.localEventData);
      this.tempCurrentScope = this.scopeItems.find(
        (item) => item.key === this.localEventData.selectedScope.dimensionName
      );
    },
    showDeleteEventModal() {
      if (this.localEventData && this.localEventData.label) {
        this.modalConfig.body.text = `Are you sure you want to delete "${this.localEventData.label}"?`;
      }
      this.showModal = true;
    },
    deleteEvent() {
      this.$parent.deleteEvent(this.eventObj.eventId);
    },
    saveEvent() {
      this.$set(
        this.localEventData,
        'titleInvalid',
        !(this.localEventData.label && this.localEventData.label.trim())
      );
      this.$set(
        this.localEventData,
        'budgetValueInvalid',
        !this.isBudgetValueValid
      );
      this.$set(
        this.localEventData,
        'remainingBudgetInvalid',
        !this.$parent.validateRemainingBudgetHash(this.localEventData)
      );
      if (
        !this.isEventDataValid ||
        this.localEventData.remainingBudgetInvalid
      ) {
        this.localEventData.isSaved = false;
        this.updateEventData(true);
        return;
      }
      this.updateEventData(true);
      if (!this.$parent.validateCurrentEvent(this.localEventData.eventId)) {
        return;
      }
      this.$set(this.localEventData, 'newEvent', false);
      this.localEventData.isSaved = true;
      const eventObj = {
        type: this.localEventData.type,
        label: this.localEventData.label,
        start: moment(this.localEventData.start).format('YYYY-MM-DD'),
        end: moment(this.localEventData.end).format('YYYY-MM-DD'),
        value: this.localEventData.value,
        valueType: this.localEventData.valueType,
        eventId: this.localEventData.eventId,
        id: this.localEventData.id,
        eventInfo: this.localEventData.eventInfo,
        selectedScope: this.localEventData.selectedScope,
        calculatedAbsAmount: this.localEventData.calculatedAbsAmount,
        isSaved: this.localEventData.isSaved
      };
      this.$emit('onSaveEvent', eventObj);
    }
  }
};
</script>

<style lang="css" scoped>
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}

.event-info-wrapper {
  max-width: 370px;
}

.filter--token {
  width: 124px;
  height: 36px;
  font-weight: normal;
}
</style>
