var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center u-position-relative u-width-100 real-time-header-header-rmm",class:[_vm.params.enableSorting ? 'u-cursor-pointer' : ''],on:{"click":function($event){return _vm.customSort()}}},[_c('customToolTip',{staticClass:"u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center",class:_vm.cellAlignment,attrs:{"tippy":{
      placement: 'bottom',
      offset: null
    }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var tippyConfig = ref.tippyConfig;
return _c('div',{},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(tippyConfig),expression:"tippyConfig"}],staticClass:"header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600 u-color-grey-lighter"},[_c('span',{staticClass:"text-label u-flex-align-items-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])}}])},[_c('div',{attrs:{"slot":"toolTipData"},slot:"toolTipData"},[_c('div',{staticClass:"u-spacing-p-m"},[_c('div',{staticClass:"u-spacing-pb-m"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"u-word-break u-font-size-6 u-font-weight-400",class:_vm.metadata.intervalId || !_vm.metadata.dataFetched
              ? 'u-spacing-pb-m'
              : ''},[_vm._v(" "+_vm._s(_vm.toolTipText)+" ")]),(_vm.metadata.intervalId || !_vm.metadata.dataFetched)?_c('div',{staticClass:"u-color-blue-base u-flex-align-items-center u-display-flex"},[_c('rb-icon',{staticClass:"rb-icon--xx-medium u-color-blue-base u-spacing-pr-s",attrs:{"icon":"info-circle-fill"}}),_vm._v(" "+_vm._s(_vm.realtimeMessage)+" ")],1):_vm._e()])])]),(_vm.metadata.intervalId || !_vm.metadata.dataFetched)?_c('div',{staticClass:"real-time-border-laoder",on:{"click":_vm.clickHeader}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }