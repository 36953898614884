<template>
  <div class="u-display-flex u-flex-align-items-center u-cursor-pointer">
    <div class="sku-wrapper">
      <div
        style="height: 48px; width: 48px; margin: 0px"
        class="media__img u-flex-none"
      >
        <img
          style="height: 48px; width: 48px; margin: 0px"
          :src="imageSrc"
        />
      </div>
      <DistributionTypeBadge :asin-id="asinId" />
    </div>
    <span class="u-spacing-ml-m u-overflow-hidden">
      <p
        class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
      >
        {{ asinTitle }}
      </p>
      <p
        class="u-text-overflow-ellipsis u-text-case-title u-color-grey-lighter u-font-size-5 u-text-align-left"
      >
        {{ asinId }}
      </p>
    </span>
    <rb-icon
      class="u-spacing-ml-s u-color-grey-lighter"
      :icon="'caret-down'"
    />
  </div>
</template>

<script>
import DistributionTypeBadge from '@/components/basic/DistributionTypeBadge.vue';
export default {
  components: {
    DistributionTypeBadge
  },
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    asinTitle: {
      type: String,
      default: ''
    },
    asinId: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="css">
.sku-wrapper {
  position: relative;
}
.sku-wrapper .sku--badge .rb-badge {
  position: absolute;
}
.sku-wrapper .sku--badge:first-child .rb-badge {
  bottom: -5px;
  right: -5px;
}
.sku-wrapper .sku--badge:nth-child(2) .rb-badge {
  bottom: -5px;
  right: 12px;
}
</style>
