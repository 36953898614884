<template>
  <div
    class="u-height-100 u-font-size-5 u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <Loader
      :loading="loading"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      :loading="!loading"
      class="u-spacing-mt-xl appNameInputDiv"
    >
      <div
        class="u-spacing-pv-m u-font-size-1 u-text-align-center u-font-weight-600"
      >
        Proxy Links
      </div>
      <div class="u-spacing-mt-m u-display-flex u-flex-justify-content-center">
        <input
          v-model="appName"
          class="u-spacing-pv-s u-spacing-mr-m u-width-80"
          type="text"
          placeholder="Enter the AppName/Service here"
        />
        <rb-button
          :type="'filled'"
          :text="'Get URL'"
          :disabled="!appName"
          :click-fn="getUrlForAppName"
        />
      </div>
    </div>
    <div class="u-spacing-pt-m u-spacing-mb-l u-font-size-5">
      {{ appNameProxyLink }}
    </div>
    <div class="u-spacing-pv-m u-font-size-2">List of Services</div>
    <div class="u-spacing-mb-xxl">
      <table
        class="proxyLinksTable"
        aria-label="A table which shows the service and it's corresponding url"
      >
        <thead>
          <tr>
            <th>AppName</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody class="u-spacing-pb-xl">
          <tr
            v-for="(appDetails, appIndex) in appNameProxyLinkList"
            :key="appIndex"
          >
            <td>{{ appDetails.appName }}</td>
            <td>{{ appDetails.url }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
import confirmDialog from '@/components/basic/confirmDialog.vue';
import HttpService from '@/utils/services/http-service';

export default {
  components: {
    Loader,
    confirmDialog
  },
  data() {
    return {
      appNameProxyLinkList: [],
      appName: '',
      appNameProxyLink: '',
      loading: false
    };
  },
  async created() {
    this.loading = true;
    try {
      const response = await HttpService.get('PROXY_LINK');
      this.appNameProxyLinkList = Array.isArray(response?.data)
        ? response?.data
        : [];
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getUrlForAppName() {
      this.loading = true;
      try {
        const response = await HttpService.post('PROXY_LINK', [this.appName]);
        this.appNameProxyLink = response.data?.[0]?.url;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.appNameInputDiv {
  width: 800px;
}
.proxyLinksTable {
  width: 800px;
  text-align: left;
  border-spacing: 0;
}
.proxyLinksTable th,
.proxyLinksTable td {
  padding: 8px 8px;
  border-bottom: 1px solid #ccc;
}
.proxyLinksTable th:nth-child(1) {
  width: 400px;
}
</style>
