import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled
} from '@/components/pages/insights/amazon/cva/utils';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  async fetchData(pageSettings, selectedTab) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 1,
        widget: 'cva_rnr_sku',
        entityType: 'SKU',
        metricsList: rem1V2ConfigEnabled()
          ? [
              'cva_RnR_client_id_v2',
              'cva_RnR_positive_string_v2',
              'cva_RnR_negative_string_v2'
            ]
          : [
              'cva_RnR_client_id',
              'cva_RnR_positive_string',
              'cva_RnR_negative_string'
            ],
        dimensionsList: [
          'brand',
          'rating',
          'review',
          'positive_review_per',
          'negative_review_per',
          'client_id'
        ],
        eventsList: [],
        enablePaginationCount: true,
        commonFilterEnabled: false,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: [
            {
              dimension: 'rating',
              direction: 'DESC'
            }
          ],
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        limit: 20,
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'RnR_sku',
              operator: 'EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      if (selectedTab === 'Your SKUs') {
        body.where.dimensionNameValueList.push({
          dimensionName: 'client_flag',
          dimensionValue: 'client',
          operator: 'EQUAL_TO'
        });
      } else {
        body.where.dimensionNameValueList.push({
          dimensionName: 'client_flag',
          dimensionValue: 'comp',
          operator: 'EQUAL_TO'
        });
      }
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'SKU Details',
        field: 'SKU',
        headerComponentParams: {
          enableSorting: false
        },
        minWidth: 300,
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        }
      },
      {
        headerName: 'Brand',
        field: 'brand',
        toolTipText: 'Brand of the SKU',
        cellClass: 'u-text-transform-capitalize'
      },
      {
        headerName: 'Average Rating',
        field: 'rating',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Average rating of the SKU'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Percent of Positive Ratings',
        field: 'positive_review_per',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Percentage of 4 and 5 star ratings'
        },
        cellRendererParams: {
          keyType: 'percent',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Percent of Negative Ratings',
        field: 'negative_review_per',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Percentage of 1,2 and 3 star ratings'
        },
        cellRendererParams: {
          keyType: 'percent',
          cellAlignment: 'u-text-align-right'
        }
      }
    ];
    columns = getActiveColumns(columns, 'customer_sentiment');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = ['SKU'];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
