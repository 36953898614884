<template>
  <div
    class="content-dashboard"
    :style="{
      '--contentDashboardRightPanelMargin': contentDashboardRightPanelMargin
    }"
  >
    <dashboard-inflator
      :key="currentState"
      class="inventory-dashboard"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :enable-date="false"
      :widget-level-settings="widgetLevelSettings()"
      :filter="filterSettings"
      :show-active-filter-banner="false"
      @metadataUpdated="metadataUpdated"
      @widgetParamsChanged="widgetParamsChanged"
    >
      <div slot="right-panel">
        <rb-button
          :click-fn="openPanel"
          :disabled="editButtonDisabled"
          text="Content Score Configuration"
          size="m"
          icon-left="pencil"
          type="filled"
          class="u-color-grey-white content-score-config-button"
          style="border-left: none"
        />
      </div>
    </dashboard-inflator>
    <right-panel
      :listener="panelListener"
      :title="'Content Score Configuration'"
      @panelClose="closePanel"
    >
      <div slot="body">
        <edit-container
          :data="editSections"
          @apply="handleEditConfigApply"
          @cancel="closePanel"
        />
        <div />
      </div>
    </right-panel>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import utils from '@/utils/helpers/';
import contentOverview from './overview.vue';
import rightPanel from '@/components/basic/customRightPanel.vue';
import { eventBus } from '@/utils/services/eventBus';
import editContainer from '@/components/basic/editContainer.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import contentBreakdownTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import Vue from 'vue';
import editContainerConfig from './editContainerConfig';
import statusTextWithIconAndBg from '@/components/globals/dataTable/tableComponentsWrapper/statusTextWithIconAndBg.vue';
import iconText from '@/components/basic/iconText.vue';
import contentTableCell from '@/pages/digital_shelf/content/content_table_cell.vue';
import clearContentFilters from '@/pages/digital_shelf/content/clearContentFilters.vue';
import overviewConfig from '@/pages/digital_shelf/content/overviewConfig.json';
import contentScorecardRetailerConfig from '@/pages/digital_shelf/content/contentScorecardRetailerConfig.json';
import { cloneDeep, set, isEqual } from 'lodash';
import textWithColor from '@/components/textWithColor.vue';
import { isValidJsonString } from '@/utils/helpers/utils.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    rightPanel,
    editContainer,
    textWithColor
  },
  mixins: [dashboardMixin],
  data() {
    const context = this;
    return {
      context,
      dataService: {},
      columnKeyMapping: {},
      currentState: 0,
      overviewConfig,
      contentScorecardRetailerConfig,
      widgetLevelSettings: () => {
        return {
          content_scorecard_dashboard_overview: {
            props: {
              headerOptions: [],
              enableDownload: false,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto'
              }
            }
          },
          content_scorecard_dashboard_breakdown: {
            props: {
              customDownloadEnable: true,
              customDownloadFunction: this.contentTableDownload,
              headerOptions: [
                {
                  ui_component: 'clearContentFilters',
                  props: {
                    filterData: this.overviewFilterApplied
                  },
                  events: {
                    clearFilters: this.clearOverviewFilters
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': '2fr auto auto auto'
              },
              enableDownload: true,
              columnKeyMapping: this.columnKeyMapping,
              searchParams: {
                show: true
              },
              footerOptions: [
                {
                  ui_component: 'iconText',
                  props: {
                    text: this.getHighlightsLabel('Good', 'good'),
                    icon: 'legends-box',
                    iconClasses:
                      'rb-icon--xx-small u-opacity-05 u-bg-color-green-base',
                    containerClass:
                      'u-border-width-s u-border-left  u-border-color-grey-xxx-light u-spacing-pl-s',
                    textClasses:
                      'u-font-size-6 u-color-grey-base u-spacing-p-0 u-spacing-mr-m'
                  }
                },
                {
                  ui_component: 'iconText',
                  props: {
                    text: this.getHighlightsLabel('Average', 'avg'),
                    icon: 'legends-box',
                    iconClasses:
                      'rb-icon--xx-small u-opacity-05  u-bg-color-orange-base',
                    textClasses:
                      'u-font-size-6 u-color-grey-base u-spacing-p-0  u-spacing-mr-m'
                  }
                },
                {
                  ui_component: 'iconText',
                  props: {
                    text: this.getHighlightsLabel('Bad', 'bad'),
                    icon: 'legends-box',
                    iconClasses:
                      'rb-icon--xx-small u-opacity-05 u-bg-color-red-base',
                    textClasses:
                      'u-font-size-6 u-color-grey-base u-spacing-p-0  u-spacing-mr-s'
                  }
                },
                {
                  ui_component: 'iconText',
                  props: {
                    text: 'Does Not Meet Requirement',
                    icon: 'legends-box',
                    iconClasses:
                      'rb-icon--xx-small u-opacity-05 u-bg-color-purple-base',
                    textClasses:
                      'u-font-size-6 u-color-grey-base u-spacing-p-0',
                    containerClass:
                      'u-border-width-s u-border-left u-spacing-ph-s u-spacing-mr-m u-border-color-grey-xxx-light'
                  }
                }
              ],
              footerContainerGridStyles: {
                'grid-template-columns': 'auto auto'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ':entityType': '#ALLOVER_AGGREGATE',
          ':title_len': '40',
          ':title_contains_brand': false,
          ':desc_contains_product': false,
          ':desc_len': '60',
          ':bullet_points': '4',
          ':reviews': '20',
          ':images': '4',
          ':avg_rating': '4',
          ':product_desc_contains_keyword': false,
          ':product_title_contains_keyword': false,
          ':good_greater_then': '75',
          ':good_less_then': '100',
          ':avg_greater_then': '50',
          ':avg_less_then': '74',
          ':bad_greater_then': '0',
          ':bad_less_then': '49'
        },
        content_scorecard_dashboard_overview: {
          ':widget': 'content_scorecard_dashboard_overview',
          ':dimensionNameValueList': []
        },
        content_scorecard_dashboard_breakdown: {
          ':widget': 'content_scorecard_dashboard_breakdown',
          ':orderByList': [],
          ':dimensionNameValueList': []
        }
      },
      filterSettings: {
        emitEvent: 'contentFilterApply',
        uiKey: 0,
        filterBarLSKey: 'content-filter-bar',
        show: true,
        backendCube: 'content_scorecard_dashboard',
        defaultDatePostRange: 'last30Days',
        enableSave: false,
        listenerEvent: 'content-filter',
        allowCompare: true,
        externalFilters: {},
        filterV2: true
      },
      metricGlobalParamMapping: {
        overall_content_score_per: '',
        title_len: ':title_len',
        title_contains_brand_metric: ':title_conatins_brand',
        desc_contains_productname_metric: ':desc_contains_product',
        desc_len: ':desc_len',
        no_of_key_features: ':bullet_points',
        no_of_reviews: ':reviews',
        no_of_images: ':images',
        avg_rating: ':avg_rating',
        desc_contains_keyword_metric: ':product_desc_contains_keyword',
        product_title_contains_keyword_metric: ':product_title_contains_keyword'
      },
      editButtonDisabled: false,
      panelListener: 'contentPanel',
      editSections: editContainerConfig,
      widgetMetadata: [],
      overviewFilterApplied: null,
      contentDashboardRightPanelMargin: '144px'
    };
  },
  computed: {
    activeRetailer() {
      return this.$store.getters.getRetailer;
    },
    getOverviewConfig() {
      this.overviewConfig.cards = this.overviewConfig.cards.filter((e) =>
        e?.retailer?.includes(this.activeRetailer)
      );
      return this.overviewConfig;
    },
    pageInfo() {
      return {
        id: this.contentScorecardRetailerConfig[this.activeRetailer]?.pageId,
        name: 'content_scorecard_dashboard',
        title: 'Content Scorecard'
      };
    },
    contentSideFiltersLSKey() {
      // dynamic LS key based on active retailer for storing right filter values as the component is shared by walmart and amazon.
      return this.activeRetailer
        ? `content-${this.activeRetailer}-right-panel-filters`
        : 'content-right-panel-filters';
    }
  },
  watch: {
    overviewFilterApplied(nv, ov) {
      if (!isEqual(ov, nv)) {
        this.updateColumnMapping();
      }
    }
  },
  created() {
    Vue.component('percentageWithCell', contentTableCell);
    Vue.component('statusTextWithIconAndBg', statusTextWithIconAndBg);
    Vue.component('content_scorecard_dashboard_overview', contentOverview);
    Vue.component(
      'content_scorecard_dashboard_breakdown',
      contentBreakdownTable
    );
    Vue.component('iconText', iconText);
    Vue.component('clearContentFilters', clearContentFilters);
    Vue.component('descriptionWithColor', textWithColor);
    // eventBus.$emit(this.panelListener, 'open');
    this.initRequestParams.global = {
      ':page': this.pageInfo.name,
      ...this.pageInfo,
      ...this.initRequestParams.global
    };
    if (this.contentScorecardRetailerConfig && this.activeRetailer) {
      this.filterSettings.filterV2 =
        this.contentScorecardRetailerConfig[this.activeRetailer]?.filterV2;
    }
    this.updateEntireRequestParams(this.initRequestParams);
    const global = this.getGlobalRequestParams();
    let savedFilters = this.$ciqlocalStorage.getItem(
      this.contentSideFiltersLSKey
    );
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
      Object.keys(savedFilters).forEach((e) => {
        global[':' + e] = savedFilters[e];
      });
      this.updateGlobalRequestParams(global);
    }
    this.editSections = dashUtils.replacePlaceHolderWithData(
      this.editSections,
      global
    );
  },
  mounted() {
    eventBus.$on('activeFilterBannerVisibility', this.addMarginToRightPanel);
  },
  methods: {
    addMarginToRightPanel(bannerVisibility) {
      if (bannerVisibility) {
        this.contentDashboardRightPanelMargin = '176px';
      } else {
        this.contentDashboardRightPanelMargin = '144px';
      }
    },
    clearOverviewFilters(data) {
      const breakdownTableParams = this.getWidgetRequestParams(
        'content_scorecard_dashboard_breakdown'
      );
      let dimensionNameValueList =
        breakdownTableParams[':dimensionNameValueList'];
      // If we recieve a filter object we filter that out from the dimensionNameValueList array, else we remove all the filters here.
      dimensionNameValueList = data
        ? dimensionNameValueList.filter(
            (e) => e.dimensionName !== data.dimensionName
          )
        : [];
      breakdownTableParams[':dimensionNameValueList'] = dimensionNameValueList;
      this.updateWidgetRequestParams(
        breakdownTableParams,
        'content_scorecard_dashboard_breakdown'
      );
      this.overviewFilterApplied = null;
    },
    widgetParamsChanged(data) {
      const breakdownTable = data?.content_scorecard_dashboard_breakdown;
      if (breakdownTable) {
        const global = this.getGlobalRequestParams();
        let cards = dashUtils.replacePlaceHolderWithData(
          this.getOverviewConfig?.cards,
          global
        );
        cards = cards.map((e) => {
          e.label = `${e.title} ${e.operator ?? ''} ${
            e.numeric ? e.value : ''
          } ${e.suffix ?? ''}`;
          return e;
        });
        const dimensionKeys = this.getOverviewConfig?.cards?.map((e) => e.key);
        const dimensionNameValueList =
          breakdownTable[':dimensionNameValueList'];
        const filter = cloneDeep(
          dimensionNameValueList.find((e) =>
            dimensionKeys.includes(e.dimensionName)
          )
        );
        if (filter) {
          filter.title = cards.find(
            (e) => e.key === filter.dimensionName
          )?.label;
          this.overviewFilterApplied = filter;
        }
      }
    },
    getHighlightsLabel(label, key) {
      const global = this.getGlobalRequestParams();
      return `${label} (${global[`:${key}_greater_then`]}%-${
        global[`:${key}_less_then`]
      }%)`;
    },
    handleEditConfigApply(data) {
      const global = this.getGlobalRequestParams();
      Object.keys(data).forEach((e) => (global[':' + e] = data[e]));
      this.updateGlobalRequestParams(global);
      this.editSections = dashUtils.replacePlaceHolderWithData(
        editContainerConfig,
        global
      );
      this.$ciqlocalStorage.setItem(
        this.contentSideFiltersLSKey,
        JSON.stringify(data)
      );
      this.updateColumnMapping();
      this.closePanel();
      this.currentState += 1;
      this.clearOverviewFilters();
    },
    async contentTableDownload(widgetMeta, requestParams) {
      try {
        const global = this.getGlobalRequestParams();
        const api = widgetMeta.downloadApiTemplate;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          requestParams
        );
        request.where.dimensionNameValueList =
          requestParams[':dimensionNameValueList'];
        request.operations.orderByList = requestParams[':orderByList'] || [];
        request.metricsList = requestParams[':metricsList'];

        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        const dimensionColumn = [];
        Object.keys(widgetMeta.metrics).forEach((metric) => {
          if (
            widgetMeta.metrics[metric]?.metadata?.visible ||
            widgetMeta.metrics[metric]?.keyName === 'title'
          ) {
            dimensionColumn.push({
              field: metric,
              title: widgetMeta.metrics[metric].label,
              priority: widgetMeta.metrics[metric].priority
            });
            // hideMetricMeetsRequirementColumn - key to hide 'requirement meets' column in the downloaded csv.
            if (
              metric !== 'overall_content_score_per' &&
              metric !== 'sku' &&
              widgetMeta.metrics[metric]?.metadata?.visible &&
              !widgetMeta.metrics[metric]?.metadata
                ?.hideMetricMeetsRequirementColumn
            ) {
              dimensionColumn.push({
                field: metric.concat('_requirement'),
                title:
                  widgetMeta.metrics[metric].label + ' Meets Requirement ?',
                priority: widgetMeta.metrics[metric].priority
              });
            }
          }
        });
        dimensionColumn.sort((item1, item2) => item1.priority > item2.priority);
        const entityData = dashUtils.mergeEntityDataResult(
          response.data.entityData
        );
        entityData.forEach((e) => {
          Object.keys(e).forEach((row) => {
            if (
              e[row]?.toString()?.startsWith('Yes (') ||
              e[row]?.toString()?.startsWith('No (')
            ) {
              e[row.concat('_requirement')] = e[row]
                ?.toString()
                .match(/(Yes|No)/g)?.[0]
                .trim();
              e[row] = e[row]?.toString().match(/(Yes|No)/g)
                ? e[row]?.toString()?.split('(')[1]?.split(')')[0].trim()
                : e[row];
            } else if (
              isValidJsonString(e[row]) &&
              Array.isArray(JSON.parse(e[row]))
            ) {
              const formattedData = this.generateDownloadFriendlyKeywordsData(
                e[row]
              );
              e[row.concat('_requirement')] = formattedData.isMeetsRequirement
                ? 'Yes'
                : 'No';
              e[row] = formattedData.keywordsString?.trim();
            } else {
              e[row.concat('_requirement')] =
                e[row] && +global[this.metricGlobalParamMapping[row]] <= e[row]
                  ? 'Yes'
                  : 'No';
            }
          });
        });
        utils.performDownload(
          entityData,
          dimensionColumn,
          widgetMeta.label || widgetMeta.name
        );
      } catch (error) {
        console.log(error);
        throw Error(error);
      }
    },
    updateColumnMapping() {
      const global = this.getGlobalRequestParams();
      const breakdownTable = this.widgetMetadata.find(
        (e) => e?.name === 'content_scorecard_dashboard_breakdown'
      );
      const breakdownTableReqParams = this.getWidgetRequestParams(
        'content_scorecard_dashboard_breakdown'
      );
      if (breakdownTable) {
        const metrics = Object.keys(breakdownTable.metrics);
        const columnKeyMapping = {};
        for (const i of metrics) {
          if (breakdownTable.metrics[i].metadata.tableConfig?.columnHeader) {
            breakdownTable.metrics[i].metadata.tableConfig.columnHeader = null;
          }
          const { name: metricName, alias: metricAlias } =
            breakdownTable.metrics[i];
          const metricMetadata = cloneDeep(breakdownTable.metrics[i].metadata);
          const cardConfig = this.getOverviewConfig?.cards?.find((e) => {
            return e.numeric
              ? e.key === metricAlias
              : e.key.concat('_metric') === metricAlias;
          });
          metricMetadata.type = cardConfig?.numeric ? 'number' : 'string';
          if (metricMetadata?.visible && metricMetadata?.tableConfig) {
            const maxValue =
              global[`${this.metricGlobalParamMapping[metricAlias]}`] - 1;
            const rangeColorMap = [
              {
                min: 0,
                max: maxValue > 0 ? maxValue : 0,
                color: 'rgba(189, 16, 224, 0.1)'
              }
            ];
            metricMetadata.tableConfig.props = {
              ...metricMetadata.tableConfig.props,
              ...{ rangeColorMap, value: 100 }
            };
            metricMetadata.tableConfig.props.type = metricMetadata.type;
            // Here, checking by concatenating _metric because the metric used in first widget is used by a diff name in second widget as there are some modifcations done to that metric.
            const sort = breakdownTableReqParams[':orderByList'].find(
              (e) =>
                e.dimension === metricAlias ||
                e.dimension.concat('_metric') === metricAlias
            );
            set(metricMetadata, ['tableConfig', 'columnHeader'], {
              ui_component: 'dataHeader',
              props: {
                alignHeader: cardConfig?.numeric ? 'right' : 'left',
                enableSorting: true
              },
              columnDefsToProps: (params) => {
                params.column.sort = sort?.direction?.toLowerCase();
              }
            });
            columnKeyMapping[metricAlias] = metricMetadata;
            set(columnKeyMapping, metricAlias, metricMetadata);
            if (metricName.includes('content_overview_overall_content_score')) {
              const scoreBandObj = this.editSections[1]?.rows; // get percentage Obj
              const scoreBandColorMap = Object.keys(scoreBandObj).map((e) => {
                const scoreBand = scoreBandObj[e];
                return {
                  min: scoreBand.minValue,
                  max: scoreBand.maxValue,
                  color: scoreBand.cellBgColor
                };
              });
              metricMetadata.tableConfig.props.value = metricName;
              metricMetadata.tableConfig.props.rangeColorMap =
                scoreBandColorMap;
              set(columnKeyMapping, metricAlias, metricMetadata);
            }
          }
          if (
            this.overviewFilterApplied?.dimensionName === metricAlias ||
            this.overviewFilterApplied?.dimensionName.concat('_metric') ===
              metricAlias
          ) {
            metricMetadata.tableConfig.columnHeader.props.headerIcon = {
              icon: 'filter',
              align: 'left',
              class: 'u-color-blue-base rb-icon--medium'
            };
            columnKeyMapping[metricAlias] = metricMetadata;
            set(columnKeyMapping, metricAlias, metricMetadata);
          }
        }
        this.columnKeyMapping = columnKeyMapping;
      }
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
      this.updateColumnMapping();
    },
    openPanel() {
      this.editButtonDisabled = true;
      eventBus.$emit(this.panelListener, 'open');
    },
    closePanel() {
      this.editButtonDisabled = false;
      eventBus.$emit(this.panelListener, 'close');
    },
    generateDownloadFriendlyKeywordsData(keywordData) {
      const parsedData = JSON.parse(keywordData);

      let keywordsString = '';
      let isMeetsRequirement = false;

      parsedData?.map((labelItem, index) => {
        const labelItemObj = labelItem[Object.keys(labelItem)[0]];
        if (labelItemObj.is_present === true) {
          isMeetsRequirement = true;
        }

        keywordsString += Object.keys(labelItem)[0];

        if (index < parsedData.length - 1) {
          keywordsString += ' | ';
        }
      });

      return {
        keywordsString,
        isMeetsRequirement
      };
    }
  }
};
</script>

<style lang="css">
.content-dashboard .rightPanel {
  margin-top: var(--contentDashboardRightPanelMargin);
  z-index: 1;
  height: calc(100% - var(--contentDashboardRightPanelMargin));
}
.content-dashboard .u-opacity-05 {
  opacity: 0.5;
}
.content-dashboard .content-score-config-button .rb-icon:before {
  font-size: 14px;
  padding-right: 0.4rem;
}
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='overall_content_score_per'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='title_len'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='desc_len'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='no_of_key_features'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='no_of_reviews'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='avg_rating'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='desc_contains_productname_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='product_title_contains_keyword_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='title_contains_brand_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='desc_contains_keyword_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='no_of_images'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='apluscontent_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='videopresent_metric'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='bullet_points_contains_keyword_metric'].ag-cell {
  margin: 0;
  padding: 0;
}
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='ordered_revenue_last_30days'],
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='overall_content_score_per'] {
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='ordered_revenue_last_30days'].ag-cell,
.content-dashboard
  .content_scorecard_dashboard_breakdown
  div[col-id^='overall_content_score_per'].ag-cell {
  border-left: 1px solid #e9eaeb !important;
}
.content-dashboard
  .content_scorecard_dashboard_breakdown
  .footer-container-cwc {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
</style>
