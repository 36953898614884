const getScreenshotByImageCapture = async (stream) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const track = stream.getVideoTracks()[0];
  const image = new ImageCapture(track);
  const bitmap = await image.grabFrame();
  stream.getVideoTracks().forEach((track) => track.stop());
  canvas.height = bitmap.height;
  canvas.width = bitmap.width;

  context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
  const screenshot = canvas.toDataURL();
  return screenshot;
};

const getScreenshotFromVideoFrame = async (stream) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.srcObject = stream;
  const screenshot = await new Promise((resolve, reject) => {
    video.addEventListener('loadeddata', async () => {
      const { videoWidth, videoHeight } = video;
      canvas.width = videoWidth;
      canvas.height = videoHeight;
      try {
        await video.play();
        context.drawImage(video, 0, 0, videoWidth, videoHeight);
        const img = canvas.toDataURL();
        video.srcObject.getVideoTracks().forEach((track) => track.stop());
        resolve(img);
      } catch (error) {
        reject(error);
      }
    });
  });
  video.removeEventListener('loadeddata');
  return screenshot;
};

const getScreenshot = async () => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      preferCurrentTab: true,
      video: {
        mediaSource: 'screen',
        cursor: 'never'
      }
    });
    if ('ImageCapture' in window) {
      return getScreenshotByImageCapture(stream);
    } else {
      return getScreenshotFromVideoFrame(stream);
    }
  } catch (err) {
    console.error('Can not capture the screenshot' + err);
    throw err;
  }
};

export default getScreenshot;
