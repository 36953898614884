export const updateComputedFilterData = (
  categorizationType,
  selectedLevels,
  primaryFilterData
) => {
  const filterData = [];

  const blacklistFilter = {
    retailer: ['profile_name', 'portfolio_name'],
    internal: []
  };

  const categorizationTypeMap = {
    retailer: 'AMAZON_CATEGORY',
    internal: 'CAMPAIGN_INTERNAL_CATALOGUE'
  };

  const renameLabelMap = {};
  for (const key in selectedLevels) {
    if (selectedLevels[key] === null) {
      blacklistFilter[categorizationType].push(key);
    } else {
      renameLabelMap[key] = selectedLevels[key]?.dimensionLabel;
    }
  }

  // core logic starts here
  for (let item of primaryFilterData) {
    // remove blackliseted filters
    if (blacklistFilter?.[categorizationType]?.includes(item.dimensionName)) {
      continue;
    }
    // rename label and dimension type
    if (renameLabelMap[item.dimensionName]) {
      item.dimensionLabel = renameLabelMap[item.dimensionName];
      item.dimensionType = categorizationTypeMap[categorizationType];
    }
    filterData.push(item);
  }
  return filterData;
};
