export const errorBlackList = [
  {
    message: "Cannot set properties of undefined (setting 'rxSize')"
  },
  {
    message: 'ResizeObserver loop limit exceeded'
  },
  {
    message: 'Illegal invocation'
  }
];
