import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled,
  getOrderByList,
  getRetailerName
} from '@/components/pages/insights/amazon/cva/utils';

export default class SkusService extends BaseWidgetService {
  async fetchData(pageSettings) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_assortment_sku',
        entityType: 'CHILD_BROWSE_NODE',
        metricsList: rem1V2ConfigEnabled()
          ? ['cva_assortment_client_id_v2']
          : ['cva_assortment_client_id'],
        dimensionsList: [
          'CHILD_BROWSE_NODE_SEARCH_VOL',
          'SKU_1',
          'SKU_2',
          'SKU_3',
          'client_id'
        ],
        eventsList: [],
        enablePaginationCount: true,
        commonFilterEnabled: false,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: getOrderByList('CHILD_BROWSE_NODE_SEARCH_VOL', 'ASC'),
          //   limit: 20,
          //   page: 1,
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'BROWSE_NODE',
              dimensionValue:
                'beauty & personal care › skin care › face › cleansers › bars',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Assortment_sku',
              operator: 'EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
        body.operations.orderByList = getOrderByList(
          'CHILD_BROWSE_NODE',
          'ASC'
        );
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'Sub Category',
        field: 'CHILD_BROWSE_NODE',
        toolTipText:
          'Sub category within the selected category, based on ' +
          getRetailerName() +
          ' browse nodes'
      },
      {
        headerName: 'Avg. Daily Search Volume',
        field: 'child_browse_node_search_vol_per_day',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Day level search volume for the sub category'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: '#1 PRODUCT',
        field: 'SKU_1',
        headerComponentParams: {
          enableSorting: false,
          toolTipText:
            '#1 product in the sub category based on browse node page rank'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        maxWidth: 300
      },
      {
        headerName: '#2 PRODUCT',
        field: 'SKU_2',
        headerComponentParams: {
          enableSorting: false,
          toolTipText:
            '#2 product in the sub category based on browse node page rank'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        maxWidth: 300
      },
      {
        headerName: '#3 PRODUCT',
        field: 'SKU_3',
        headerComponentParams: {
          enableSorting: false,
          toolTipText:
            '#3 product in the sub category based on browse node page rank'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        maxWidth: 300
      }
    ];
    columns = getActiveColumns(columns, 'assortment_strength');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = ['SKU_1', 'SKU_2', 'SKU_3'];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
