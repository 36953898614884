import Vue from 'vue';

export function areRowsSelected(rowSelections: any[]) {
  return rowSelections.length > 0;
}

export function getSegmentIdArray(id: string) {
  try {
    return [...JSON.parse(id)];
  } catch (error) {
    return [id];
  }
}

export const isAdvancedReportingAvailable = (retailer: string) => {
  // @ts-ignore
  return Vue.options.filters.config_check(
    `feature.advanced_reporting.${retailer}.enable`
  );
};
