<template>
  <div class="u-height-100 u-spacing-pv-xxxl u-position-relative">
    <loader
      v-show="rightButtonLoading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
      :style="{ 'z-index': '105' }"
    />
    <section
      class="u-height-100 u-spacing-pt-m u-spacing-pr-l u-spacing-ph-xxl"
    >
      <createCampaignHeader :step="step" />
      <AdGroupName
        v-if="!hideAdgroup"
        :default-value="adGroupName"
        :show-error="Boolean(formErrorObject.adGroupName)"
        :error-text="formErrorObject.adGroupName"
        @adgroupUpdated="handleAdgroup"
      />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        ref="skusTable"
        class="add-sku-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <div
          v-if="skuMessages"
          slot="right-table-footer"
          class="edit_suggested_campaign_adgroup u-font-size-5 u-color-grey-lighter u-font-weight-600 u-position-absolute"
        >
          <rb-icon
            class="u-color-grey-lighter rb-icon--small"
            icon="info-circle-fill"
          />
          <span
            data-cy="errorMessage"
            class="u-spacing-ml-xs"
          >
            {{ skuMessages }}
          </span>
        </div>
      </component>
      <createCampaignFooter
        :show-left-btn="!(step || {}).isCreateAdGroup"
        :disable-right-btn="disableRightBtn"
        :right-button-loading="rightButtonLoading"
        class="u-spacing-pt-l"
        :error-message="errorMessage"
        :btn-right-text="step.btnRightText || 'Next'"
        @onClickBtnRight="onClickBtnRight"
        @onClickBtnLeft="prevStep"
      >
        <div
          v-if="Boolean(errorMessage) && showSkusFailedReasonText"
          slot="slot-below-error-message"
        >
          <tippy
            to="infoTooltip"
            trigger="mouseenter"
            :distance="0"
            interactive="true"
            reactive="true"
            hide-on-click="true"
            :duration="0"
            placement="top-start"
          >
            <TippyTableWithHeader
              header-text="Failed Items"
              :column-definition="failedItemsColumnDefinition"
              :data-rows="failedSkusDataRows"
              :show-download-button="true"
              class="failedItemsTippy"
              @downloadButtonClicked="downloadSkusFailedReasonCsv"
            />
          </tippy>
          <div
            class="u-font-size-7 u-font-weight-bold u-spacing-mt-xs"
            style="text-decoration: underline; text-decoration-style: dotted"
          >
            <span
              name="infoTooltip"
              class="u-cursor-pointer"
            >
              View reason for failure
            </span>
          </div>
        </div>
      </createCampaignFooter>
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import AdGroupName from '@/components/ams/campaign_creation/steps/edit/campaignAdGroup/adGroup.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import loader from '@/components/basic/loader.vue';
import TippyTableWithHeader from '@/components/ams/campaign_creation/walmart_steps/adGroup/TippyTableWithHeader.vue';
import FailedErrorTextTableCell from '@/components/ams/campaign_creation/walmart_steps/adGroup/FailedErrorTextTableCell.vue';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
import {
  ErrorCodes,
  WalmartCreateCustomException
} from '@/components/ams/campaign_creation/walmart_steps/errorUtility';
import {
  getSkusToAdd,
  getSkusToDelete,
  getSkusToUpdate
} from '@/components/ams/campaign_creation/walmart_steps/adGroup/util.js';
import { getFailedSkuColumnDefinition } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import utilityHelpers from '@/utils/helpers';
import Vue from 'vue';

export default {
  name: 'AdGroupAndSkusIndex',
  components: {
    createCampaignHeader,
    createCampaignFooter,
    AdGroupName,
    CustomActionPanel,
    loader,
    FormErrorMessage,
    TippyTableWithHeader
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      customActionPanelCustomState: {},
      adGroupName: 'Test',
      formErrorObject: {
        adGroupName: ''
      },
      suggestedCampaignIndex: 0,
      walmartCampaignCreateDataService: {},
      campaignId: null,
      adGroupId: null,
      createAdGroupId: null,
      advertiserId: null,
      rightButtonLoading: false,
      initialFetchLoading: false,
      skusToAddPayloadForMapping: [],
      errorMessage: '',
      failedItemsColumnDefinition: [],
      failedSkusDataRows: [],
      showSkusFailedReasonText: false
    };
  },
  computed: {
    areValidationsErrorPresent() {
      const formErrorObject = this.formErrorObject;
      let areValidationsErrorPresent = false;
      for (const key of Object.keys(formErrorObject)) {
        if (formErrorObject[key]) {
          areValidationsErrorPresent = true;
          return areValidationsErrorPresent;
        }
      }
      return areValidationsErrorPresent;
    },
    disableRightBtn() {
      let flag = false;
      if (this.areValidationsErrorPresent) {
        flag = true;
        return flag;
      }
      if (Boolean(this.skuMessages) || this.rightButtonLoading) {
        flag = true;
        return flag;
      }
      if (this.allStepsData?.campaignNameAlreadyExists === true) {
        flag = true;
        return flag;
      }
      return flag;
    },
    hideAdgroup() {
      return this.step?.hideAdgroup;
    },
    skuMessages() {
      const minSkuItems = this.step?.skusCountValidationObject?.min || 2;
      const maxSkuItems = this.step?.skusCountValidationObject?.max || 10;
      if (
        this.step?.skusCountValidationObject &&
        (this.skusLength < minSkuItems || this.skusLength > maxSkuItems)
      ) {
        return `You can only enter ${minSkuItems}-${maxSkuItems} items in the ad group.`;
      } else if (this.step?.skuBidValidationObject) {
        const minBid = this.step?.skuBidValidationObject.min;
        for (const item of this.allStepsData?.skus) {
          if (!item.newBid || parseFloat(item.newBid) < minBid) {
            return this.step?.skuBidValidationObject?.message;
          }
        }
      } else return false;
    },
    skusLength() {
      const { skus } = this.allStepsData;
      if (skus) {
        return skus.length;
      } else return 0;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  watch: {
    skusLength: {
      handler(newVal) {
        this.step.description = `${newVal} SKU${
          newVal !== 1 ? "'s" : ''
        } selected`;
      },
      deep: true,
      immediate: true
    },
    adGroupName(nv) {
      if (!this.step?.isCreateAdGroup) {
        this.checkFormValidity(nv);
      }
    },
    allStepsData(nv, ov) {
      if (this.step?.isCreateAdGroup) {
        this.checkFormValidity(nv.adGroupName);
      }
    },
    createAdGroupId(newVal) {
      this.appendToQueryParams({
        adGroupId: newVal
      });
    }
  },
  async created() {
    Vue.component('FailedErrorTextTableCell', FailedErrorTextTableCell);
    this.initializeDataService();
    this.failedItemsColumnDefinition = getFailedSkuColumnDefinition(this);
    this.customActionPanelCustomState =
      this.step?.customActionPanelCustomState(this);
    await this.fetchSaveAndPreFillValues();
  },
  methods: {
    downloadSkusFailedReasonCsv() {
      const downloadDataArray = this.failedSkusDataRows.map((item) => ({
        sku: item?.sku,
        failure_reason: item?.failure_reason
      }));
      const downloadDataColumnMaps = [
        {
          field: 'sku',
          title: 'Item ID'
        },
        {
          field: 'failure_reason',
          title: 'Failure Reason'
        }
      ];
      const { campaignName, adGroupName } = this.allStepsData;
      const fileName = `${campaignName}_${adGroupName}_SKU_errorfile`;
      utilityHelpers.performDownload(
        downloadDataArray,
        downloadDataColumnMaps,
        fileName
      );
    },
    handleCaughtError(err) {
      this.showSkusFailedReasonText = false;
      if (err.message === ErrorCodes.SOMETHING_WENT_WRONG) {
        this.errorMessage =
          'Something went wrong. Please try again after some time.';
      }
      if (err.message === ErrorCodes.FETCHING_AD_GROUP_DETAILS_FAILED) {
        this.errorMessage =
          'Something went wrong while fetching ad group details. Please close the page and try again after some time.';
      }
      if (err.message === ErrorCodes.FETCHING_SKUS_DETAILS_FAILED) {
        this.errorMessage =
          'Something went wrong while fetching SKUS details. Please close the page and try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_AD_GROUP_FAILED) {
        this.errorMessage = this.errorMessage =
          err?.metadata?.data?.response?.[0]?.details ||
          'Something went wrong while updating the ad group. Please try again after some time.';
      }
      if (err.message === ErrorCodes.CREATE_AD_GROUP_FAILED) {
        this.errorMessage =
          err?.metadata?.data?.response?.[0]?.details ||
          'Something went wrong while creating the ad group. Please try again after some time.';
      }
      if (err.message === ErrorCodes.ADD_SKUS_FAILED) {
        this.showSkusFailedReasonText = true;
        const numberOfFailures = err?.metadata?.data?.failure;
        if (numberOfFailures) {
          this.errorMessage = `Addition of ${numberOfFailures} SKU's failed`;
        } else {
          this.errorMessage = "Addition of some SKU's Failed";
        }

        // Logic to fill up the failed reasons table with reason comes here
        const apiResponseArray = err?.metadata?.data?.response;
        const payloadAlreadySentBefore = this.skusToAddPayloadForMapping;
        // The length of both the items will be the same
        const skuToResponseObjectMapping = {};
        for (let i = 0; i < apiResponseArray.length; i++) {
          skuToResponseObjectMapping[payloadAlreadySentBefore[i].sku] =
            apiResponseArray[i];
        }
        const filteredFailureSkus = payloadAlreadySentBefore.filter(
          (payloadItem) =>
            skuToResponseObjectMapping[payloadItem.sku].code === 'failure'
        );
        const finalFailureSkusForTable = filteredFailureSkus.map((item) => {
          item.failure_reason = skuToResponseObjectMapping[item.sku].details;
          return item;
        });
        this.failedSkusDataRows = finalFailureSkusForTable;
      }
      if (err.message === ErrorCodes.UPDATE_SKUS_FAILED) {
        this.errorMessage =
          'Something went wrong while updating a SKU item. Please try again after some time.';
      }
      if (err.message === ErrorCodes.DELETE_SKUS_FAILED) {
        this.errorMessage =
          'Something went wrong while deleting a SKU item. Please try again after some time.';
      }
    },
    checkFormValidity(adGroupName) {
      const formErrorObject = { ...this.formErrorObject };
      // Ad Group name validations
      let adGroupNameError = '';
      if (!adGroupName) {
        adGroupNameError = 'Please enter an Ad Group name.';
      }
      if (adGroupName?.length > 255) {
        adGroupNameError = 'Ad Group Name should be within 255 characters.';
      }
      // Ad Group name validations end here

      formErrorObject.adGroupName = adGroupNameError;

      this.formErrorObject = formErrorObject;
    },
    async fetchSaveAndPreFillValues() {
      try {
        let fetchedSkusFilteredArray = [];
        const fetchedAdGroupResponse = await this.fetchAdGroupDetails();
        let filteredAdGroup = fetchedAdGroupResponse?.data?.response?.filter(
          (item) => item?.adGroupId === parseInt(this.adGroupId)
        );
        if (filteredAdGroup.length > 0) {
          filteredAdGroup = filteredAdGroup[0];
        }
        if (this.adGroupId) {
          const fetchedSkusResponse = await this.fetchSkusDetails();
          fetchedSkusFilteredArray =
            fetchedSkusResponse?.data?.response?.filter(
              (item) => item?.adGroupId === parseInt(this.adGroupId)
            );
        }
        const payload = {
          ...this.allStepsData,
          ...{
            fetchedAdGroupResponse: filteredAdGroup,
            fetchedSkusResponse: fetchedSkusFilteredArray || []
          }
        };
        this.$store.dispatch(this.selectedValues.action, payload);
        const updatedStore =
          this.$store?.getters?.[this.selectedValues?.getter];
        if (this.adGroupId) {
          this.preFillFieldsFromFetchedData(updatedStore);
        }
      } catch (err) {
        console.log(err);
        this.handleCaughtError(err);
      }
    },
    preFillFieldsFromFetchedData(allStepsData = this.allStepsData) {
      const fetchedAdGroupResponse = allStepsData?.fetchedAdGroupResponse;
      this.setAdGroupName(fetchedAdGroupResponse?.name);
      // This triggers the updateRows() method in customActionPanel which has the
      // logic for updating the right table from the fetched data
      this?.$refs?.skusTable?.triggerUpdateRightTableRows?.();
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      const retailer = this.$store.getters.getRetailer;
      this.campaignId = parseInt(campaignId) || undefined;
      this.adGroupId = parseInt(adGroupId || this.createAdGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      const dataService =
        this.step?.dataService || WalmartCampaignCreateDataService;
      this.walmartCampaignCreateDataService = new dataService(
        campaignId,
        adGroupId || this.adGroupId,
        advertiserId,
        campaignType,
        retailer
      );
    },
    async fetchAdGroupDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchAdGroupDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_AD_GROUP_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_AD_GROUP_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async fetchSkusDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchSkusDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_SKUS_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_SKUS_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    handleAdgroup(value) {
      this.setAdGroupName(value);
    },
    setAdGroupName(newVal) {
      let allStepsData = null;
      if (this.allStepsData?.adGroupName === undefined) {
        // If adGroupName is not present in all Steps data
        allStepsData = { ...this.allStepsData };
        this.adGroupName = newVal;
        allStepsData.adGroupName = this.adGroupName;
      } else if (this.allStepsData?.adGroupName && newVal === undefined) {
        this.adGroupName = this.allStepsData?.adGroupName;
        allStepsData = { ...this.allStepsData };
      } else {
        this.adGroupName = newVal;
        allStepsData = { ...this.allStepsData };
        allStepsData.adGroupName = this.adGroupName;
      }
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    goToNextStep() {
      if (
        this.$store.getters.getRetailer === 'walmart' &&
        this.step?.isCreateAdGroup &&
        this.allStepsData?.campaignType === 'sp_auto'
      ) {
        return this.step?.redirectTo?.(this);
      } else {
        this.$emit('nextStep', {});
      }
    },
    checkIfAdGroupWasUpdated() {
      const allStepsData = this.allStepsData;
      const fetchedAdGroupResponse = allStepsData?.fetchedAdGroupResponse;
      let wasAdGroupUpdated = false;
      const adGroupUpdateObject = {};
      if (fetchedAdGroupResponse.name !== allStepsData?.adGroupName) {
        adGroupUpdateObject.adGroupName = allStepsData?.adGroupName;
        wasAdGroupUpdated = true;
      }
      return [wasAdGroupUpdated, adGroupUpdateObject];
    },
    checkIfSkusWasUpdated() {
      try {
        const allStepsData = this.allStepsData;
        const tableSkus = allStepsData?.skus;
        const fetchedSkus = allStepsData?.fetchedSkusResponse;
        const skusToAdd = getSkusToAdd(tableSkus, fetchedSkus);
        const skusToUpdate = getSkusToUpdate(tableSkus, fetchedSkus);
        const skusToDelete = getSkusToDelete(tableSkus, fetchedSkus);
        const wasSkusUpdated =
          skusToAdd.length > 0 ||
          skusToUpdate.length > 0 ||
          skusToDelete.length > 0 ||
          false;
        return [wasSkusUpdated, skusToAdd, skusToUpdate, skusToDelete];
      } catch (error) {
        console.error({ error });
      }
    },
    async onClickBtnRight() {
      try {
        // create ad group
        if (this.step?.isCreateAdGroup && !this.adGroupId) {
          await this.createAdGroup();
          this.initializeDataService();
        } else {
          // update ad group
          const [wasAdGroupUpdated, adGroupUpdateObject] =
            this.checkIfAdGroupWasUpdated();
          if (wasAdGroupUpdated) {
            await this.updateAdGroupDetails(adGroupUpdateObject);
          }
        }
        const [wasSkusUpdated, skusToAdd, skusToUpdate, skusToDelete] =
          this.checkIfSkusWasUpdated();
        if (wasSkusUpdated) {
          if (skusToAdd.length > 0) {
            this.skusToAddPayloadForMapping = skusToAdd;
            await this.addSkus(skusToAdd);
          }
          if (skusToUpdate.length > 0) {
            await this.updateSkus(skusToUpdate);
          }
          if (skusToDelete.length > 0) {
            await this.deleteSkus(skusToDelete);
          }
        }
        // debugger;
        this.goToNextStep();
        return;
      } catch (err) {
        this.handleCaughtError(err);
        await this.fetchSaveAndPreFillValues();
      }
    },
    async updateAdGroupDetails(adGroupUpdateObject) {
      this.rightButtonLoading = true;
      const changeToExistingAdGroupName = () => {
        this.setAdGroupName(
          this.allStepsData?.fetchedAdGroupResponse?.name || null
        );
        // Logic todo
      };
      try {
        const updateAdGroupDetailsResponse =
          await this.walmartCampaignCreateDataService.updateAdGroupDetails(
            adGroupUpdateObject
          );
        if (updateAdGroupDetailsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_AD_GROUP_FAILED,
            updateAdGroupDetailsResponse
          );
        }
      } catch (err) {
        changeToExistingAdGroupName();
        if (err.message === ErrorCodes.UPDATE_AD_GROUP_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async createAdGroup() {
      this.rightButtonLoading = true;
      try {
        const createAdGroupResponse =
          await this.walmartCampaignCreateDataService.createAdGroup(
            this.allStepsData
          );
        if (createAdGroupResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.CREATE_AD_GROUP_FAILED,
            createAdGroupResponse
          );
        }
        if (createAdGroupResponse.data?.success === 1) {
          this.createAdGroupId =
            createAdGroupResponse.data?.response[0]?.adGroupId;
        }
      } catch (err) {
        if (err.message === ErrorCodes.CREATE_AD_GROUP_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async addSkus(skus) {
      this.rightButtonLoading = true;
      try {
        const addSkusResponse =
          await this.walmartCampaignCreateDataService.addSkus(
            skus,
            this.allStepsData?.adGroupName
          );
        if (addSkusResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.ADD_SKUS_FAILED,
            addSkusResponse
          );
        }
        return addSkusResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.ADD_SKUS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async updateSkus(skus) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const updateSkusResponse =
          await this.walmartCampaignCreateDataService.updateOrDeleteSkus(
            skus,
            allStepsData?.adGroupName
          );
        if (updateSkusResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_SKUS_FAILED,
            updateSkusResponse
          );
        }
        return updateSkusResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.UPDATE_SKUS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async deleteSkus(skusToDelete) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const deleteSkusResponse =
          await this.walmartCampaignCreateDataService.updateOrDeleteSkus(
            skusToDelete,
            allStepsData?.adGroupName,
            true
          );
        if (deleteSkusResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.DELETE_SKUS_FAILED,
            deleteSkusResponse
          );
        }
        return deleteSkusResponse;
      } catch (err) {
        if (err.message === ErrorCodes.DELETE_SKUS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    appendToQueryParams(keyValPairs) {
      const query = { ...this.$route.query, ...keyValPairs };
      this.$router.replace({ query: query });
    }
  }
};
</script>

<style lang="css" scoped>
.failedItemsTippy {
  margin: -8px;
}
.edit_suggested_campaign_adgroup {
  bottom: -20px;
}

.rb-icon--small:before {
  font-size: 14px;
}
</style>
