<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl">
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <campaignTypes
        :campaign-types="campaignTypesArray"
        :default-selected-index="selectedCampaignTypeIndex"
        @selectedIndex="campaignSelected"
      />
      <div
        v-if="selectedCampaignType === 'sponsoredProducts'"
        class="u-spacing-mt-l u-width-40"
      >
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="selectTargetingType"
          >Select Targeting Type:</span
        >
        <rbRadioGroup
          style="margin-top: -15px"
          :radio-options="targetingTypeRadioOptions"
          :value="selectedTargetingType"
          :is-column="true"
          @onRadioChanged="handleTargetingTypeRadioChanged"
        />
      </div>
      <createCampaignFooter
        :disable-right-btn="false"
        class="u-spacing-pt-l"
        :show-left-btn="false"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import campaignTypes from '@/components/ams/campaign_creation/steps/edit/campaignTypes/campaignTypes.vue';
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    campaignTypes,
    rbRadioGroup
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    let campaignTypesArray = [
      {
        url: '',
        title: 'Sponsored Brands',
        key: 'sponsoredBrands',
        desc: 'Showcase a collection of products to shoppers actively searching with related keywords on Walmart.',
        image: '/images/SPTile.svg'
      }
    ];
    if (this.step.campaignTypesArray) {
      campaignTypesArray = this.step.campaignTypesArray;
    }
    let targetingTypeRadioOptions = [
      {
        type: 'manual',
        label: 'Manual Campaign',
        desc: "With a manual campaign, advertisers handpick their keywords they want to bid on and match types. Advertisers can also consult Walmart's Keyword Analytics tool to weed out low-performing keywords from their campaigns."
      },
      {
        type: 'auto',
        label: 'Automatic Campaign',
        desc: 'Automatic campaigns are the easiest way to bid on Walmart.com. Easy setup, high-traffic placements, no keyword management and higher impression volume.'
      }
    ];
    if (this.step.targetingTypeRadioOptions) {
      targetingTypeRadioOptions = this.step.targetingTypeRadioOptions;
    }
    return {
      selectedCampaignTypeIndex: 0,
      campaignTypesArray,
      targetingTypeRadioOptions: targetingTypeRadioOptions,
      selectedTargetingType: 'manual'
    };
  },
  computed: {
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    },
    selectedCampaignType() {
      return this.campaignTypesArray[this.selectedCampaignTypeIndex].key;
    }
  },
  watch: {
    allStepsData: {
      handler(newVal) {
        const campaignType = newVal?.campaignType;
        const targetingType = newVal?.targetingType;
        const campaignTypeKeyValuePair = {
          sponsoredBrands: 'Sponsored Brands',
          sponsoredProducts: 'Sponsored Products'
        };
        const targetingTypeKeyValuePair = {
          manual: 'Manual',
          auto: 'Auto'
        };
        let stepDescriptionString = `${campaignTypeKeyValuePair[campaignType]} (${targetingTypeKeyValuePair[targetingType]})`;
        this.step.description = stepDescriptionString;
      },
      deep: true,
      immediate: true
    },
    selectedCampaignTypeIndex() {
      this.handleTargetingTypeRadioChanged('manual');
    }
  },
  created() {
    // Check if store already has campaignType. If yes, we update the campaign type
    // to that.
    const allStepsData = this.allStepsData;
    if (allStepsData?.campaignType) {
      let tempSelectedCampaignTypeIndex = 0;
      tempSelectedCampaignTypeIndex = this.campaignTypesArray.findIndex(
        (item) => item.key === allStepsData.campaignType
      );
      if (tempSelectedCampaignTypeIndex === -1) {
        // If it was not found in the array, change it to 0
        tempSelectedCampaignTypeIndex = 0;
      }
      this.selectedCampaignTypeIndex = tempSelectedCampaignTypeIndex;
    } else {
      this.selectedCampaignTypeIndex = 0;
    }
    if (allStepsData?.targetingType) {
      let tempSelectedTargetingTypeIndex = 0;
      tempSelectedTargetingTypeIndex = this.targetingTypeRadioOptions.findIndex(
        (item) => item.type === allStepsData.targetingType
      );
      if (tempSelectedTargetingTypeIndex === -1) {
        // If it was not found in the array, change it to 0
        tempSelectedTargetingTypeIndex = 0;
      }
      const selectedTargetingType =
        this.targetingTypeRadioOptions[tempSelectedTargetingTypeIndex].type;
      this.handleTargetingTypeRadioChanged(selectedTargetingType);
    } else {
      const selectedTargetingType = this.targetingTypeRadioOptions[0].type;
      this.handleTargetingTypeRadioChanged(selectedTargetingType);
    }
    this.updateSelectedStore({
      campaignType: this.campaignTypesArray[this.selectedCampaignTypeIndex].key,
      targetingType: this.selectedTargetingType
    });
  },
  methods: {
    campaignSelected(index) {
      this.selectedCampaignTypeIndex = index;
      this.updateSelectedStore({
        campaignType: this.campaignTypesArray[index].key
      });
    },
    updateSelectedStore(data) {
      let allStepsData = this.allStepsData;
      allStepsData = { ...allStepsData, ...data };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    handleTargetingTypeRadioChanged(value) {
      this.selectedTargetingType = value;
      this.updateSelectedStore({
        targetingType: this.selectedTargetingType
      });
    }
  }
};
</script>

<style scoped></style>
