<template>
  <div>
    <div class="u-width-100 u-display-flex u-spacing-mb-m">
      <span class="u-spacing-mr-xs">
        <LocalDateFilter
          v-if="customDate"
          class="sov-snapshot-intraday-datepicker u-spacing-mr-s"
          mode="Single"
          :custom-date="customDate"
          @selected-value="handleDateSelect"
        />
      </span>
      <span
        v-if="multiCrawl"
        class="u-spacing-mr-m"
      >
        <rb-select-v2
          :options="crawlTimesService.data.rows"
          :on-close="handleCrawlTimeSelect"
          :send-details="true"
        >
          <MultiSelectChip
            slot="trigger"
            ref="crawl-time-select-tigger"
            :load="dropdownService.data.load || crawlTimesService.data.load"
            :no-data="
              dropdownService.data.noData || crawlTimesService.data.noData
            "
            :pre-text="'Crawl Time'"
            :pre-text-icon="'clock'"
            :selections="selectedCrawlTime"
          />
        </rb-select-v2>
      </span>
      <rb-select-v2
        :options="dropdownService.data.rows"
        :on-close="handleKeywordsSelect"
        :send-details="true"
      >
        <MultiSelectChip
          slot="trigger"
          ref="keywords-select-tigger"
          :load="dropdownService.data.load"
          :no-data="dropdownService.data.noData"
          :pre-text="filterPretext"
          :selections="selectedKeyword"
        />
      </rb-select-v2>
    </div>
    <div
      v-if="
        imageDataService.data.load ||
        snapshotSharesService.data.load ||
        dropdownService.data.load ||
        crawlTimesService.data.load
      "
      :style="{ height: 80 + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-else-if="
        imageDataService.data.error ||
        snapshotSharesService.data.error ||
        dropdownService.data.error ||
        crawlTimesService.data.error
      "
      :style="{ height: 80 + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="
        imageDataService.data.noData ||
        snapshotSharesService.data.noData ||
        dropdownService.data.noData ||
        crawlTimesService.data.noData
      "
      :style="{ height: 80 + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>No Data</p>
    </div>

    <DataBars
      v-else
      :data-bars-limit="null"
      :custom-color-obj="colorsMap"
      :retailer-brand-name="retailerBrandName"
      :image-url-key="keys.imageUrlKey"
      :asin-key="keys.asinKey"
      :asin-hyper-link-key="keys.asinHyperLinkKey"
      :copy-clipboard-text="copyToClipboardText"
      :rank-key="keys.rankKey"
      :client-name="clientName"
      :bar-tooltip-key="keys.barTootlipKey"
      :bars-value="imageDataService.data.rows"
      :key-to-check="keyToCheck"
      :legends-value="snapshotSharesService.data.legendValues"
      :sorted-legends="snapshotSharesService.data.sortedLegends"
      v-bind="dataBarProps"
    />
  </div>
</template>

<script>
import DataBars from '@/components/basic/dataBars.vue';
import { cloneDeep, get } from 'lodash';
import {
  requestDateReplacement,
  requestTimeReplacement,
  hasPlaceholder
} from './dashboard-service-utils';
import MultiSelectChip from '../atoms/multi-select-chip';
import LocalDateFilter from '@/components/basic/localDateFilter';
import loader from '@/components/basic/loader';
import moment from 'moment';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import CONSTANTS from '@/utils/constants';

export default {
  components: {
    RbSelectV2,
    DataBars,
    MultiSelectChip,
    LocalDateFilter,
    loader
  },
  props: {
    externalClientBrand: {
      type: String,
      default: null
    },
    colorsMap: {
      type: Object,
      default: null
    },
    imageDataService: {
      type: Object,
      default: null
    },
    snapshotSharesService: {
      type: Object,
      default: null
    },
    dropdownService: {
      type: Object,
      default: null
    },
    crawlTimesService: {
      type: Object,
      default: null
    },
    filterPretext: {
      type: String,
      default: 'Keywords'
    },
    globalWhereClause: {
      type: Object,
      default: () => ({})
    },
    externalKeywordSelected: {
      type: String,
      default: null
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    retailerBrandName: {
      type: String,
      default: null
    },
    isMarketingClient: {
      type: Boolean,
      default: true
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    namespace: {
      type: String,
      default: null
    },
    multiCrawl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDate: null,
      customDate: null,
      dataBarProps: {},
      metadata: {},
      keys: {},
      accessKeyMetadata: {},
      accessKeys: [],
      selectedKeyword: [],
      selectedCrawlTime: []
    };
  },
  computed: {
    copyToClipboardText() {
      // doing this because Sonarlint is not able to detect the type of this.retailer
      if (this.retailer.toString() !== 'amazon') {
        return 'SKU copied to clipboard';
      }
      return 'ASIN copied to clipboard';
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    clientName() {
      return this.externalClientBrand || this.keys.clientName;
    },
    keyToCheck() {
      return this.externalClientBrand
        ? this.keys.externalKeyToCheck
        : this.keys.staticKeyToCheck;
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    metricsList() {
      return [
        this.multiCrawl ? 'total_listing_count_intraday' : 'total_listing_count'
      ];
    }
  },
  watch: {
    colorsMap: {
      immediate: true,
      handler(newSnapshotColor) {
        if (!newSnapshotColor) {
          this.$emit(
            'newLegendData',
            this.snapshotSharesService.data.legendValues
          );
        }
      }
    },
    externalKeywordSelected: {
      immediate: true,
      handler(newExternalKeywordSelected, oldExternalKeywordSelected) {
        this.externalKeywordSelected = newExternalKeywordSelected;
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    generateAccessKeys(metricsList, accessKeysConfig, metadata) {
      const { client, comp, others, anchored } = accessKeysConfig;
      return [
        ...this.getAccessKey(metricsList, anchored, metadata),
        ...this.getAccessKey(metricsList, client, metadata),
        ...this.getAccessKey(metricsList, comp, metadata),
        ...this.getAccessKey(metricsList, others, metadata)
      ];
    },
    getAccessKey(metricsList, config, metadata) {
      const { prefix, count: metaCount } = config;
      let count = null;
      if (metaCount?.search) {
        count = get(metadata, metaCount.search);
      }
      return this.getAccessKeyFromMetric(
        metricsList,
        metadata.metrics,
        prefix,
        count
      );
    },
    getAccessKeyFromMetric(metrics, metricsMetadata, prefix, count) {
      const accessKeys = [];
      metrics.forEach((metric) => {
        const { keyName } = metricsMetadata[metric];
        if (count) {
          for (let i = 0; i < count; i++) {
            accessKeys.push({
              brandKey: prefix + '_' + (i + 1),
              brandShareKey: prefix + '_' + (i + 1) + '_' + keyName
            });
          }
        } else {
          accessKeys.push({
            brandKey: prefix,
            brandShareKey: prefix + '_' + keyName
          });
        }
      });
      return accessKeys;
    },
    async handleDateSelect(newDate) {
      this.selectedDate = this.getDateFormatted(newDate.to);
      if (this.multiCrawl) {
        await this.getCrawlTimeData();
      }
      await this.fetchWidgetVisualization();
    },
    async handleKeywordsSelect(context, selections) {
      if (selections?.[0]) {
        this.selectedKeyword = [selections[0]];
        if (this.multiCrawl) {
          await this.getCrawlTimeData();
        }
        await this.fetchWidgetVisualization();
      }
    },
    async handleCrawlTimeSelect(context, selections) {
      if (selections?.[0]) {
        this.selectedCrawlTime = [selections[0]];
        await this.fetchWidgetVisualization();
      }
    },
    async fetchWidgetVisualization() {
      await Promise.all([
        this.getShareOfVoiceImages(),
        this.getShareOfVoiceData()
      ]);
    },
    async init() {
      try {
        this.metadata = await this.dropdownService?.getMetadata();
        const { accessKeyMetadata, keys, dataBarProps } =
          this.metadata.customMetadata;
        this.accessKeyMetadata = accessKeyMetadata;
        this.keys = keys;
        this.accessKeys = this.generateAccessKeys(
          this.metricsList,
          this.accessKeyMetadata,
          this.metadata
        );
        this.dataBarProps = dataBarProps;
        this.isIntradayKeywordKey = 'is_intraday';
        this.imageDataService.widgetId = this._uid;
        this.snapshotSharesService.widgetId = this._uid;
        this.dropdownService.widgetId = this._uid;
        this.crawlTimesService.widgetId = this._uid;
        this.initialiseDate();
      } catch (e) {
        console.error('e', e);
      }
    },
    initialiseDate() {
      this.customDate = {
        defaultDate: new Date(this.globalWhereClause.date.to),
        toDate: new Date(this.globalWhereClause.date.to),
        fromDate: new Date(this.globalWhereClause.date.from)
      };
      this.selectedDate = this.getDateFormatted(this.globalWhereClause.date.to);
    },
    getDateFormatted(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    async getData() {
      try {
        this.selectedKeyword = [];
        await this.getKeywordsData();
        if (this.multiCrawl) {
          await this.getCrawlTimeData();
        }
        await this.fetchWidgetVisualization();
      } catch (e) {
        console.error('e', e);
      }
    },
    setLocalFilters(requestCopy, replaceTime = true) {
      requestCopy.where.dimensionNameValueList = [
        {
          dimensionName: this.selectedKeyword[0].entityType,
          dimensionValue: this.selectedKeyword[0].title
        }
      ];
      if (hasPlaceholder(requestCopy.where.date)) {
        requestCopy.where.date.from = this.selectedDate;
        requestCopy.where.date.to = this.selectedDate;
        requestCopy.where.date.name = this.getGlobalWhereClause.date_range.name;
        requestCopy.where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      }
      if (hasPlaceholder(requestCopy.where.pvpDate)) {
        requestCopy.where.pvpDate.from = this.selectedDate;
        requestCopy.where.pvpDate.to = this.selectedDate;
        requestCopy.where.pvpDate.compare_name =
          this.getGlobalWhereClause.date_range.compare_name;
      }
      if (this.multiCrawl && replaceTime) {
        const selectedCrawlTime = this.selectedCrawlTime[0].entityValue;
        if (hasPlaceholder(requestCopy.where.time)) {
          requestCopy.where.time.from = selectedCrawlTime;
          requestCopy.where.time.to = selectedCrawlTime;
        }
        if (hasPlaceholder(requestCopy.where.pvpTime)) {
          requestCopy.where.pvpTime.from = selectedCrawlTime;
          requestCopy.where.pvpTime.to = selectedCrawlTime;
        }
      }
    },
    async getCrawlTimeData() {
      const { request: requestCopy } = cloneDeep(
        this.metadata.metadata.crawlTimeAPI
      );
      this.setLocalFilters(requestCopy, false);
      requestTimeReplacement(requestCopy.where, this.globalWhereClause);
      await this.crawlTimesService.getData(requestCopy);
      this.selectedCrawlTime = [this.crawlTimesService.data.rows[0]];
    },
    async getShareOfVoiceImages() {
      const { request: requestCopy } = cloneDeep(
        this.metadata.metrics[this.keys.sovImageMetricKey].api
      );
      this.setLocalFilters(requestCopy);
      const accessKey = this.keys.marketingSearchRankKey;
      await this.imageDataService.getData(
        requestCopy,
        accessKey,
        this.keys.listingTypeKey
      );
    },
    async getShareOfVoiceData() {
      const { request: requestCopy } = cloneDeep(
        this.metadata.metrics[this.keys.shareOfVoiceKey].api
      );
      this.setLocalFilters(requestCopy);
      this.$emit('sovDataRequest', requestCopy);
      requestCopy.metricsList = this.metricsList;
      await this.snapshotSharesService.getData(
        requestCopy,
        this.accessKeys,
        this.keyToCheck,
        this.clientName
      );
      this.$emit('newLegendData', this.snapshotSharesService.data.legendValues);
    },
    setSelectedKeyword(dropdownData) {
      // find external keyword in the data set, if it exists then, set the current selected keyword to the found item, otherwise select the first element from the dropdown data as the selected keyword
      if (this.externalKeywordSelected) {
        const selectedKeywordIndex = dropdownData.findIndex(
          (item) => item.title === this.externalKeywordSelected
        );
        if (selectedKeywordIndex >= 0) {
          this.selectedKeyword = [dropdownData[selectedKeywordIndex]];
        } else {
          this.selectedKeyword = [dropdownData[0]];
        }
      } else {
        this.selectedKeyword = [dropdownData[0]];
      }
    },
    async getKeywordsData() {
      const { request: keywordsApi } = cloneDeep(
        this.metadata.metadata.keywordAPI
      );
      requestDateReplacement(keywordsApi.where, this.globalWhereClause);
      if (this.multiCrawl) {
        requestTimeReplacement(keywordsApi.where, this.globalWhereClause);
      }
      keywordsApi.where.dimensionNameValueList = [
        ...this.globalWhereClause.dimensionNameValueList
      ];
      this.$emit('keywordsDataRequest', keywordsApi);
      await this.dropdownService.getData(keywordsApi);
      this.dropdownService.data = {
        ...this.dropdownService.data,
        rows: this.formatKeywordsRows(this.dropdownService.data.rows)
      };
      this.setSelectedKeyword(this.dropdownService.data.rows);
    },
    formatKeywordsRows(data) {
      return data.map((item) => {
        const isIntradayKeyword = this.multiCrawl
          ? true
          : item.data.find((i) => i.name === this.isIntradayKeywordKey)?.RESULT[
              this.isIntradayKeywordKey
            ];
        return {
          ...item,
          ...(isIntradayKeyword
            ? { icon: CONSTANTS.crawlTypes.multiCrawl.icon }
            : {})
        };
      });
    }
  }
};
</script>

<style lang="css">
.sov-snapshot-intraday-datepicker .vdp-datepicker__calendar {
  top: -320px;
  left: -110px;
}
</style>
