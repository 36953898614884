<template>
  <div
    class="u-display-flex u-flex-direction-column"
    data-cy="content-metric-card"
  >
    <rb-select
      :on-close="dropdownClose"
      :options="dropdownOptions"
      :send-details="true"
      class="u-spacing-mt-m u-width-100"
    >
      <div
        slot="trigger"
        class="u-display-flex"
      >
        <div
          v-tippy="{ placement: 'top' }"
          :title="metricTitleTooltip"
          class="u-font-size-4 u-text-overflow-ellipsis u-color-grey-mid-light u-font-weight-600 u-cursor-pointer"
          @mouseenter="metricLabelHover"
        >
          {{ cardData.label }}
        </div>
        <rb-icon
          class="u-display-flex u-flex-justify-content-flex-end u-color-grey-lighter rb-icon--small u-spacing-ml-xs u-color-grey-base"
          :icon="'caret-down'"
        />
      </div>
      <div
        slot="item"
        slot-scope="option"
      >
        <span>{{ option.label }}</span>
      </div>
    </rb-select>
    <div class="u-spacing-mt-s u-display-flex" />
    <bar-chart
      class="u-spacing-mt-s"
      :data="barChartData"
      :sub-label="subLabel"
      :label-class="'u-font-weight-600 u-font-size-3 u-color-purple-base'"
      :show-legends="false"
    />
    <slot name="footer" />
  </div>
</template>

<script>
import barChart from '@/components/basic/barChart.vue';
export default {
  components: {
    barChart
  },
  props: {
    cardData: {
      type: Object,
      default: {
        title: 'Title',
        percentage: '0',
        count: '0',
        totalCount: '0'
      }
    },
    dropdownOptions: {
      type: Array,
      default: [
        { title: 'HI', value: 'HI' },
        { title: 'HI', value: 'HI' },
        { title: 'HI', value: 'HI' }
      ]
    },
    subLabel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: 78,
      metricTitleTooltip: null
      // dropdownOptions: [{ title: 'HI', value: 'HI' }, { title: 'HI', value: 'HI' }, { title: 'HI', value: 'HI' }]
    };
  },
  computed: {
    barChartData() {
      return this.cardData;
    }
  },
  methods: {
    dropdownClose(ctx, data) {
      this.$emit('dropdownClose', data, this.cardData.key);
    },
    metricLabelHover(e) {
      const metricEl = e?.target;
      if (metricEl?.offsetWidth < metricEl?.scrollWidth) {
        this.metricTitleTooltip = metricEl.innerText;
      } else {
        this.metricTitleTooltip = null;
      }
    }
  }
};
</script>

<style></style>
