<template>
  <section
    id="businessOverview"
    :class="{ 'u-height-100': isLoading || showError }"
  >
    <section
      v-if="!isLoading && !showError"
      class="u-flex-1 bi-filter u-header-box-shadow"
    >
      <div
        class="u-display-flex filter-panel-with-banner u-spacing-mb-m"
        style="z-index: 99"
      >
        <rb-filter-panel
          id="business-overview"
          :key="filterKey"
          :data="filterData"
          :primary="primaryFilterData"
          :secondary-data="secondaryFilterData"
          :listener-event="filterLocalStorageKey"
          :config="config.filters"
          :loader-status="loaderStatus"
          :emit="config.filters.emit"
          :has-search="true"
          :new-date="enableDate"
          :allow-date-compare="true"
          :default-date-post-range="defaultDatePostRange"
          :on-create="onCreate"
          :enable-save="false"
          :page="'businessInsights'"
          :hide-filter="true"
          :last-week="lastWeekSelection"
          :min-max-date="minMaxDate"
          :enable-remove-all="false"
          :show-notification="false"
          :additional-ranges="additionalRanges"
          :enable-open-filter="enableOpenFilter"
          @savedFilterApplied="filterKey++"
        >
          <div
            v-if="!isLoading && !showError && !isLoadingPageAPI"
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-1 u-flex-align-items-center"
          >
            <vr-select
              v-model="selected"
              :options="[]"
              :group-by-key="'groupName'"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-grey-lighter u-cursor-pointer optionsDropdown u-flex-justify-content-flex-end u-height-36px"
              >
                <Bookmark :title="(selectedView || {}).label" />
                <!-- <rb-icon
                  class="rb-icon--large u-spacing-mr-s u-color-blue-base"
                  :icon="'bi-view'"
                /> -->
                <div
                  class="u-display-flex u-flex-direction-column u-color-grey-base"
                >
                  <span
                    class="u-font-size-2 u-font-weight-bold u-max-width-260px u-text-overflow-ellipsis u-overflow-hidden"
                    :title="(selectedView || {}).label"
                    >{{ (selectedView || {}).label }}</span
                  >
                </div>
                <!-- <rb-icon
                  class="rb-icon--x-medium u-color-grey-lighter"
                  :icon="'caret-down'"
                  expoter="display-none"
                /> -->
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                  :title="option.label"
                >
                  <p v-if="!option.edit">
                    {{ option.label }}
                  </p>
                  <input
                    v-if="option.edit"
                    :id="'input' + option.globalViewId"
                    v-model="option.label"
                    class="input_value"
                    :disabled="!option.edit"
                    @click.stop="option.edit ? '' : true"
                  />
                  <section
                    v-if="option.groupName !== 'Default Views' && option.edit"
                  >
                    <span @click.stop="toggleEdit(option)">
                      <rb-icon
                        class="rb-icon--medium u-color-grey-lighter"
                        :icon="'cross-fill-circle'"
                      />
                    </span>
                    <span @click.stop="updateViewName(option)">
                      <rb-icon
                        class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                        :icon="'check-fill-circle'"
                      />
                    </span>
                  </section>
                  <section
                    v-if="option.groupName !== 'Default Views' && !option.edit"
                    class="u-display-flex"
                  >
                    <div
                      v-if="option.currentUser"
                      class="viewActionIcons u-flex-align-items-center"
                    >
                      <span @click.stop="toggleEdit(option)">
                        <rb-icon
                          class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                          :icon="'pencil'"
                        />
                      </span>
                      <span
                        @click.stop="onShowConfirmDialogFn('delete', option)"
                      >
                        <rb-icon
                          class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                          :icon="'delete'"
                        />
                      </span>
                    </div>
                    <div :name="'infoTooltip' + option.globalViewId">
                      <rb-icon
                        v-if="option.currentUser"
                        class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                        :icon="'admin'"
                      />
                      <rb-icon
                        v-if="!option.currentUser"
                        class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                        :icon="'person'"
                      />
                    </div>
                    <tippy
                      v-if="!hideInsideTippy"
                      :to="'infoTooltip' + option.globalViewId"
                      interactive="true"
                      reactive="true"
                      trigger="mouseenter"
                      distant="0"
                      :duration="0"
                      theme="dropdown"
                      placement="bottom-end"
                    >
                      <div style="background: #333333; padding: 8px">
                        <p style="color: white; padding-bottom: 8px">
                          Created by: {{ option.createdBy }}
                        </p>
                        <p style="color: white; padding-bottom: 4px">
                          Created at: {{ option.formattedAt }}
                        </p>
                      </div>
                    </tippy>
                  </section>
                </div>
              </template>
            </vr-select>

            <!-- <div
              v-show="!hideViewBtn"
              class="u-display-flex"
            >
              <rb-button
                v-show="!hideUpdateView"
                id="updateView"
                text="Update view"
                :loading="isUpdateViewLoading"
                :type="'filled'"
                :click-fn="onShowConfirmDialogFn.bind(this, 'update')"
                class="u-spacing-ml-m"
                expoter="display-none"
              />
              <GenericInputTippy
                :title="'Save as new view'"
                :is-loading="isSaveViewLoading"
                expoter="display-none"
                @onUpdateView="onUpdateView"
              />
              <rb-button
                v-tippy="{ placement : 'top', arrow: false}"
                :title="'Reset the filters and dates to the last saved view.'"
                icon-left="revert"
                :type="'hollow'"
                :click-fn="onResetView"
                class="u-spacing-ml-s hollow-outlined-btn u-bg-color-transparent-base"
                expoter="visibility-hidden"
              />
            </div> -->
          </div>
          <div slot="preDatePicker">
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              class="u-border-radius-xs u-spacing-p-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-s u-bg-color-grey-white u-display-flex u-height-36px"
              title="Know more about this dashboard"
              expoter="display-none"
              @click="openHelpDialog"
            >
              <rb-icon
                class="u-color-grey-lighter"
                :icon="'info-circle-fill'"
              />
            </div>
          </div>
          <div
            slot="download"
            expoter="display-none"
          >
            <rb-button
              v-tippy="{ placement: 'top', arrow: false }"
              :title="'Click here to export the dashboard as a PDF. The current selections and data present on the dashboard will be exported.'"
              text=""
              icon-left="share"
              :type="'hollow'"
              :click-fn="exportWidgets"
              class="u-spacing-ml-s hollow-outlined-btn u-bg-color-transparent-base export-pdf"
              expoter="display-none"
            />
          </div>
          <div
            v-if="invalidFilters.length > 0"
            slot="before-filter"
            class="rb-filter"
            expoter="display-none"
          >
            <div
              class="filter--token u-spacing-mr-s u-display-inline-flex u-flex-align-items-center"
            >
              <p
                class="u-display-flex u-flex-align-items-center u-color-grey-base"
              >
                <rb-icon
                  class="rb-icon--small u-color-orange-base u-spacing-mr-xs"
                  :icon="'warning'"
                />
                One or more saved filters are invalidated.
                <span
                  class="u-spacing-ml-xs u-color-grey-lighter u-cursor-pointer u-text-decoration-underline"
                  @click="onShowConfirmDialogFn('invalidFilter')"
                  >view details</span
                >
              </p>
            </div>
          </div>
          <template
            slot="banner"
            slot-scope="option"
          >
            <sb-banner :dates="option" />
          </template>
        </rb-filter-panel>
      </div>
    </section>
    <div
      class="u-display-flex u-flex-direction-column u-spacing-pt-0 u-envelop-width"
    >
      <div
        v-for="(widget, $index) in widgetsData"
        v-if="!isLoadingPageAPI && !showError && !isLoading"
        :key="$index"
        :class="widget.widgetName"
        class="u-display-flex u-spacing-mb-m slide-card"
      >
        <!-- :is="widget.widgetName === 'chartingWb' ? 'performaceOverview' : (widget.widgetName === 'topMovers' ? 'topMovers' : '')" -->
        <component
          :is="
            widget.widgetName === 'chartingWb'
              ? 'performaceOverview'
              : widget.widgetName
          "
          :config="widget"
          :page="page"
        />
      </div>
    </div>

    <div
      v-if="isLoading || isLoadingPageAPI"
      class="u-height-100 u-position-relative"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="!isLoading && showError"
      class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
    >
      <div class="u-display-flex noskuImage_container">
        <img
          src="/images/no_sku_found.png"
          class="u-width-100"
        />
      </div>
      <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
        <p class="u-color-grey-base u-spacing-mr-s">Something went wrong!</p>
      </div>
    </div>
    <confirmDialog
      v-if="showConfirmPopup"
      width="u-width-60"
      @click.stop
    >
      <div slot="header">
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-weight-600 u-spacing-pt-xl u-spacing-mt-m"
        >
          <div
            class="u-flex-0 u-font-size-1 u-text-align-center u-flex-align-items-center u-display-flex"
          >
            <rb-icon
              v-if="showConfirmPopupType === 'invalidFilter'"
              class="rb-icon--x-large u-color-orange-base u-spacing-mr-xs"
              :icon="'warning'"
            />
            {{
              confirmPopupTemplate[showConfirmPopupType].title || 'Update View'
            }}
          </div>
        </div>
      </div>
      <div slot="content">
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pt-xl u-spacing-pb-l u-flex-direction-column u-flex-align-items-center"
        >
          <div
            class="u-font-size-4 u-text-align-center"
            style="line-height: 23px"
          >
            {{
              confirmPopupTemplate[showConfirmPopupType].subTitle ||
              'Update View'
            }}
          </div>
          <div
            v-if="showConfirmPopupType === 'invalidFilter'"
            class="u-spacing-mt-l"
          >
            <div
              v-for="filter in invalidFilters"
              :key="filter.dimensionLabel"
              class="filter--token u-spacing-mr-s u-display-inline-flex u-flex-align-items-center u-spacing-ph-m u-spacing-pv-s u-border-style-dashed"
            >
              <span
                v-if="filter.filterInvalidationDetails.nameInvalidated"
                class="u-display-flex u-flex-align-items-center u-color-orange-base u-font-size-4 u-font-weight-600"
              >
                <rb-icon
                  class="rb-icon--medium u-color-orange-base u-spacing-mr-xs"
                  :icon="'filter'"
                />
                {{ filter.dimensionLabel }}</span
              >
              <span
                v-else
                class="u-display-flex u-color-grey-lighter u-flex-align-items-center u-font-size-4 u-font-weight-600"
              >
                <rb-icon
                  class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
                  :icon="'filter'"
                />
                {{ filter.dimensionLabel }}&nbsp;:&nbsp;</span
              >
              <span
                v-if="filter.filterInvalidationDetails.valueInvalidated"
                class="u-color-orange-base u-font-size-4 u-spacing-ml-xs u-color-grey-lighter"
              >
                {{
                  filter.filterInvalidationDetails.invalidFilterValues.toString()
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div
          class="u-display-flex u-flex-justify-content-center u-spacing-pb-xxl"
        >
          <div class="u-display-flex u-flex-justify-content-space-between">
            <rb-button
              class="hollow-outlined-btn u-spacing-mr-m"
              :text="'Cancel'"
              :type="'hollow'"
              :click-fn="onCancelDialog"
            />
            <rb-button
              v-show="
                showConfirmPopupType === 'invalidFilter'
                  ? selectedView && selectedView.currentUser
                  : true
              "
              :class="confirmPopupTemplate[showConfirmPopupType].okButtonClass"
              :text="
                confirmPopupTemplate[showConfirmPopupType].okButtonText ||
                'Update view'
              "
              :type="'filled'"
              :click-fn="onOkayBtn"
            />
          </div>
        </div>
      </div>
    </confirmDialog>
    <HelpDocumentDialog
      v-if="showHelpDialog"
      :full-path="`https://cdn.rboomerang.com/helpDoc/Business_Overview_${$store.getters.getProgram}.pdf`"
      @closeCallback="closeCallback"
    />
  </section>
</template>

<script>
import moment from 'moment-timezone';
import sbBanner from '@/components/basic/sb-banner';
import { cloneDeep } from 'lodash';
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsMixin from '@/components/widgetsMixin';
import { eventBus } from '@/utils/services/eventBus';
import { widgetsConfig } from '@/components/pages/businessInsights/widgets';
import performaceOverview from '@/components/pages/businessInsights/BusinessInsightsPerformanceWb';
import topMovers from '@/components/pages/businessInsights/BusinessInsightsTopMovers';
import benchMarking from '@/components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarkingWidget';
import confirmDialog from '@/components/pages/emailSubscription/confirmDialog.vue';
import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';
import GenericInputTippy from '@/components/basic/GenericInputTippy';
var hash = require('object-hash');

export default {
  name: 'BusinessInsights',
  owner: 'Rajan V',
  components: {
    rbFilterPanel,
    loader,
    performaceOverview,
    topMovers,
    benchMarking,
    GenericInputTippy,
    confirmDialog,
    HelpDocumentDialog,
    sbBanner
  },
  mixins: [widgetsMixin],
  data() {
    return {
      demoViewID: 1320,
      selected: {},
      filterKey: 0,
      widgetsData: null,
      isLoading: true,
      isLoadingPageAPI: false,
      page: 'BusinessInsights',
      showError: false,
      viewList: [],
      selectedView: {},
      minMaxDate: {},
      filterLocalStorageKey: 'businessInsights-filters',
      additionalRanges: {
        add: ['last60Days', 'lastWeek', 'last12Months', 'lastYear'],
        order: {
          lastWeek: 1,
          last60Days: 2,
          last12Months: 7,
          lastYear: 8
        }
      },
      isUpdateViewLoading: false,
      isSaveViewLoading: false,
      subscribeForMutations: [
        'SETROLLUP',
        'SETMETRICVALUE',
        'SETWHERECLAUSEVALUE',
        'SET_TOPMOVERS_SELECTEDMEASURE',
        'SET_TOPMOVERS_SELECTEDMEASUREFROMPERFORMANCEOVERVIEW',
        'SET_TOPMOVERS_SELECTEDENTITY',
        'BENCHMARKING_PLOTTED_METRIC',
        'BENCHMARKING_TABLE_METRIC_LIST',
        'BENCHMARKING_MAIN_DROPDOWN_SELECTED',
        'BENCHMARKING_SET_ROLLUP_BY',
        'BENCHMARKING_CLEAR_SELECTED_LIST',
        'BENCHMARKING_DROPDOWN_LIST',
        'BENCHMARKING_DELETE_SELECTED_COLUMN_ENTITY',
        'BENCHMARKING_SELECTED_COLUMN_SHIFT_AND_ADD_NEW',
        'BENCHMARKING_OVERALL_STATE_RESET',
        'BENCHMARKING_TABLE_METRIC_CHANGE',
        'BENCHMARKING_SELECTED_TABLE_METRIC'
      ],
      hideViewBtn: true,
      hideUpdateView: true,
      defaultDatePostRange: 'last30Days',
      enableDate: false,
      showConfirmPopup: false,
      enableOpenFilter: false,
      hideInsideTippy: false,
      widgetCreatedTime: null,
      showHelpDialog: false,
      showConfirmPopupType: '',
      selectedDeleteView: {},
      confirmPopupTemplate: {
        update: {
          title: 'Update View',
          subTitle:
            'All your current selections: date range, dashboard filters, widget filters, metrics and benchmarked entities will be updated in this view.',
          okButtonText: 'Update view',
          okButtonClass: 'u-bg-color-blue-base'
        },
        delete: {
          title: 'Are you sure you want to delete this view?',
          subTitle:
            'A saved view contains selected date range, dashboard and widget filters, metrics and benchmarked entities. Deleting a view will permanently remove it from the account.',
          okButtonText: 'Delete',
          okButtonClass: 'u-bg-color-red-base deleteBtn'
        },
        invalidFilter: {
          title: 'Filters Invalidated',
          subTitle:
            'Below filters are no longer available due to a recent change to your internal taxonomy:',
          okButtonText: 'Update relevant filters and save view',
          okButtonClass: 'u-bg-color-blue-base'
        }
      },
      invalidFilters: []
    };
  },
  computed: {
    lastWeekSelection() {
      return this.metadata?.week;
    }
  },
  beforeRouteLeave(to, from, next) {
    const timeSpentOnTheWidget = new Date().getTime() - this.widgetCreatedTime;
    this.$logger.logEvent({
      eventType: 'page_visit_time',
      page: this.page,
      time_spent_on_the_widget_in_ms: timeSpentOnTheWidget
    });
    this.beforeNavigation(next);
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  beforeCreate() {},
  mounted() {
    // document.querySelector('.page-body')?.addEventListener('scroll', this.handlePageScroll);
  },
  created() {
    this.initialize();
    this.config = widgetsConfig.config;
    eventBus.$on(this.config.filters.emit, this.filterListener);
    eventBus.$on(this.config.filters.addToFilter, this.setFilterValues);
    this.widgetCreatedTime = new Date().getTime();
    this.$logger.logEvent({ eventType: 'page_visit_counter', page: this.page });

    // var _that = this;
    // window.onbeforeunload = function () {
    //   console.log(this, _that, _that.hideViewBtn, this.hideViewBtn);
    //   _that.beforeNavigation();
    //   if (!_that.hideViewBtn) {
    //     // Calling without next, because it's a page close event.
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };

    // https://stackoverflow.com/questions/50705759/beforeunload-event-does-not-fire-after-page-refresh-chrome
    // https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup
    // if (window.performance) {
    //   console.info('window.performance works fine on this browser');
    // }
    // console.info(performance.navigation.type);
    // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
    //   console.info('This page is reloaded');
    // } else {
    //   console.info('This page is not reloaded');
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
    document
      .querySelector('.page-body')
      ?.removeEventListener('scroll', this.handlePageScroll);
    eventBus.$off(this.config.filters.emit, this.filterListener);
    eventBus.$off(this.config.filters.addToFilter, this.setFilterValues);
    // window.removeEventListener('load', this.handler);
  },
  methods: {
    closeCallback() {
      this.showHelpDialog = false;
    },
    openHelpDialog() {
      this.showHelpDialog = true;
    },
    initialize() {
      this.fetchGlobalView();
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (this.subscribeForMutations.indexOf(mutation.type) !== -1) {
          // Run View Save Validation
          this.validateView(mutation.type);
        }
      });
    },
    beforeNavigation(next) {
      if (!this.hideViewBtn) {
        // this.saveDraftView().finally(() => {
        //   if (next) {
        //     next();
        //   }
        // });
        this.saveDraftView();
        if (next) {
          next();
        }
      } else {
        next();
      }
    },
    handleViewBasedonParams(params) {
      if (params && this.viewList.length > 0) {
        this.selectedView =
          this.viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;

        if (!this.selectedView) {
          this.selectedView = this.viewList[0];
          // this.$router.push({ name: 'businessInsights', params: { id: this.selectedView.globalViewId } });
          this.$router.push({
            name: 'cross-retailer-dashboard',
            params: { id: this.demoViewID }
          });
          return;
        }
        this.fetchPageList(this.selectedView.globalViewId);
      } else {
        if (this.viewList && this.viewList.length > 0) {
          this.selectedView = this.viewList[0];
          this.$router.push({
            name: 'cross-retailer-dashboard',
            params: { id: this.demoViewID }
          });
          // this.$router.push({ name: 'businessInsights', params: { id: this.selectedView.globalViewId } });
        }
        // this.fetchGlobalView();
      }
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      this.viewList.unshift(...defaultViewTemp);
    },
    /**
     * Func to fetch global view data.
     */
    fetchGlobalView() {
      this.isLoading = true;
      const requestBody = { page: this.page };
      HttpService.post('DASHBOARD_SERVICE', requestBody, {
        append: '/global/view/list'
      })
        .then((response) => {
          if (response.status === 200) {
            this.metadata = response?.data?.metadata;
            this.viewList = response.data.globalViews.map((item) => {
              item.currentUser = false;
              if (item.createdBy === window.user.email) {
                item.currentUser = true;
              }
              item.formattedAt = moment(item.createdAt).format('LLL');
              if (item.groupName !== 'Default Views') {
                item.edit = false;
              }
              return item;
            });

            this.sortViewList();
            let globalViewId = '';
            if (this.$route?.params?.id) {
              globalViewId = this.$route?.params?.id;
              this.handleViewBasedonParams(globalViewId);
            } else {
              // globalViewId = '' || response.data?.userUnsavedGlobalView?.globalViewId;
              // if (globalViewId) {
              //   this.$router.push({ name: 'businessInsights', params: { id: globalViewId } });
              // } else {
              //   this.$router.push({ name: 'businessInsights', params: { id: this.viewList[0].globalViewId } });
              // }
              this.$router.push({
                name: 'cross-retailer-dashboard',
                params: { id: this.demoViewID }
              });
              return;
            }
            // this.fetchPageList(this.selectedView.globalViewId);

            this.isLoadingPageAPI = true;
          }
        })
        .catch((error) => {
          this.showError = true;
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        });
    },
    /**
     * Fetch page/Widget info based on viewID
     */
    fetchPageList(viewID) {
      this.isLoading = true;
      this.isLoadingPageAPI = true;
      this.enableOpenFilter = false;
      this.widgetsData = [];
      this.listAPIResponse = {};
      localStorage.removeItem(this.filterLocalStorageKey);
      localStorage.removeItem(`${this.filterLocalStorageKey}-order`);
      HttpService.post(
        'DASHBOARD_SERVICE',
        { page: this.page, globalViewId: viewID },
        { append: '/page/widgets/list' }
      )
        .then((response) => {
          if (response.status === 200) {
            this.metadata = response?.data?.metadata;
            this.$store.dispatch('initBenchmarkingStore', this.page);
            this.fetchFilterV2(response.data.API.filterAPI).finally(() => {
              // Calendar MinMaxDate Setup
              this.minMaxDate.minDate = moment(
                response.data.calendar.min_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
              this.minMaxDate.maxDate = moment(
                response.data.calendar.max_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
              this.$store.dispatch('setGlobalMaxDate', this.minMaxDate.maxDate);
              this.listAPIResponse = { ...response.data };

              // Filter Setup - default and saved filter values.
              let storage = JSON.parse(
                localStorage.getItem(this.filterLocalStorageKey)
              );
              if (response.data.filters) {
                const storageValues = this.transformFilterFromAPI(
                  response.data.filters?.selectedFilter?.filter || []
                );
                storageValues.values.date_range =
                  response.data.filters?.date_range;
                // storageValues.values.date_range = { name: 'last60Days', compare: true, compare_name: null };
                if (!storage) {
                  storage = {};
                  storage = storageValues.values;
                  // storage.dimension4 = [
                  //   'ajax',
                  //   'fabuloso',
                  //   'hum'
                  // ];
                  localStorage.setItem(
                    this.filterLocalStorageKey,
                    JSON.stringify(storageValues.values)
                  );
                  localStorage.setItem(
                    `${this.filterLocalStorageKey}-order`,
                    JSON.stringify(storageValues.order)
                  );
                } else {
                  localStorage.setItem(
                    this.filterLocalStorageKey,
                    JSON.stringify(storageValues.values)
                  );
                  localStorage.setItem(
                    `${this.filterLocalStorageKey}-order`,
                    JSON.stringify(storageValues.order)
                  );
                }
              }

              this.isLoading = false;
              this.enableDate = true;
              this.invalidFilters = [];
              response.data.widgets.splice(1, 1);
              this.widgetsData = response.data.widgets.map((config) => {
                config.globalViewId = viewID;
                config.page = this.page;
                return config;
              });

              setTimeout(() => {
                // console.log(this.filterInstance.getSelectedFilters(), 'this.filterInstance.getSelectedFilters()');
                this.$store.dispatch(
                  'setWhereClause',
                  this.filterInstance?.getSelectedFilters()
                );
                if (response.data?.filters?.selectedFilter) {
                  // Filter invalidation.
                  const savedFilter = cloneDeep(response.data.filters);
                  for (const i in savedFilter.selectedFilter.filter[0]) {
                    if (savedFilter.selectedFilter.filter[0][i].isInvalidated) {
                      this.invalidFilters.push(
                        cloneDeep(savedFilter.selectedFilter.filter[0][i])
                      );
                    }
                    delete savedFilter.selectedFilter.filter[0][i]
                      .filterInvalidationDetails;
                  }
                  this.$store.dispatch('setGlobalViewAction', {
                    type: 'filters',
                    filters: cloneDeep(savedFilter)
                  });
                } else {
                  this.$store.dispatch('setGlobalViewAction', {
                    type: 'filters',
                    filters: this.getSelectedFiltersValues()
                  });
                }
                // this.$store.dispatch('setGlobalViewAction', { type: 'filters', 'filters': this.getSelectedFiltersValues() });
                this.validateView();
                // Solving the filter opening issue in a hacky way.
                setTimeout(() => {
                  this.enableOpenFilter = true;
                }, 1000);
              }, 0);
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.showError = true;
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isLoadingPageAPI = false;
        });
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    exportWidgets() {
      const month = moment().format('MM');
      const day = moment().format('DD');
      this.$logger.logEvent({
        eventType: 'expoter',
        type: 'Click',
        page: `${this.page}`,
        widget: 'benchmarking'
      });
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'PDF is being generated and will be downloaded shortly.',
        template: {
          header: true,
          elementSelectors: [
            {
              selector: '.bi-filter'
            }
          ]
        },
        elementSelector: '.slide-card',
        fileName: `Business Overview_${month}/${day}.pdf`
      };
      eventBus.$emit('triggerExport', config);
      // this.exporter();
    },
    /**
     * On GlobalList change
     */
    onViewChange(val) {
      if (val && val.label) {
        this.$logger.logEvent({
          eventType: 'view_change',
          type: 'Click',
          page: `${this.page}`,
          pageIdentifier: `${this._skuID}`,
          eventContext: 'Business Insights Global View Change',
          from: this.selectedView.label,
          to: val.label
        });
        this.selectedView = val;
        this.saveDraftView();
        this.$router.push(`${this.selectedView.globalViewId}`);
        // this.fetchPageList(this.selectedView.globalViewId);
      }
    },
    /**
     * On button click on update view - Save the filter/calendar
     * mode -> Save new/ Update existing
     */
    onUpdateView(mode, data, type) {
      const requestBody = {
        name: data,
        page: this.page,
        pageId: this.listAPIResponse.id
      };
      Object.assign(requestBody, { ...this.getCurrentViewInfo() });
      // Handling the custom date selection - fallback while on save and update
      if (
        requestBody?.filters?.date_range?.name === 'custom' ||
        requestBody?.filters?.date_range?.compare_name === 'custom'
      ) {
        requestBody.filters.date_range.name = this.defaultDatePostRange;
        requestBody.filters.date_range.compare_name = null;
        requestBody.filters.date_range.compare = true;
      }
      if (mode === 'update') {
        this.showConfirmPopup = false;
        requestBody.globalViewId = this.selectedView.globalViewId;
        requestBody.name = this.selectedView.label;
      }
      if (type === 'name') {
        requestBody.name = data.label;
        requestBody.globalViewId = data.globalViewId;
      }
      this.$logger.logEvent({
        eventType: 'view_update',
        type: 'Click',
        page: `${this.page}`,
        mode,
        eventContext: 'Business Insights Global View Updated'
      });

      // Name check for views.
      const isNameExists = this.viewList.find((item) => {
        return item.label.toLowerCase() === requestBody.name.toLowerCase();
      });

      if (
        isNameExists &&
        requestBody.globalViewId !== isNameExists.globalViewId
      ) {
        this.$snackbar.open({
          message: `View with ${requestBody.name} name already exists.`,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        return;
      }

      const loadingKey =
        mode === 'update' ? 'isUpdateViewLoading' : 'isSaveViewLoading';
      this[loadingKey] = true;
      this.viewNrLogger(requestBody);
      this.$store
        .dispatch('saveViewAction', requestBody)
        .then(
          (response) => {
            if (response.data?.success) {
              this.$snackbar.open({
                message: `View ${
                  mode === 'update' ? 'Updated' : 'Saved'
                } Successfully`,
                duration: 5000,
                buttonColor: '#f5d908',
                actionText: ' '
              });
              if (response.data.globalView) {
                response.data.globalView.currentUser = true;
                response.data.globalView.edit = false;
                response.data.globalView.formattedAt = moment(
                  response.data.globalView.createdAt
                ).format('LLL');
                if (mode !== 'update') {
                  this.viewList.push(response.data.globalView);
                } else {
                  this.viewList = this.viewList.map((item) => {
                    if (
                      item.globalViewId ===
                      response.data.globalView?.globalViewId
                    ) {
                      return response.data.globalView;
                    } else {
                      return item;
                    }
                  });
                }
              }
              this.onViewChange(response.data.globalView);
              this.validateView();
              // this.fetchGlobalView();
            } else {
              this.$snackbar.open({
                message: 'Something went wrong !!. Try again',
                duration: 5000,
                buttonColor: '#f5d908',
                actionText: ' '
              });
            }
          },
          (error) => {
            this.$snackbar.open({
              message:
                'Something went wrong !!. Try again' || JSON.stringify(error),
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
          }
        )
        .finally(() => {
          this[loadingKey] = false;
          this.sortViewList();
        });
    },
    viewNrLogger(requestBody) {
      const {
        benchMarking = {},
        chartingWb,
        topMovers
      } = requestBody.widgetMetadata;
      const nrLogBody = {};
      for (
        let ithOfSelectedMetric = 0;
        ithOfSelectedMetric < chartingWb.selectedMetric.length;
        ithOfSelectedMetric++
      ) {
        nrLogBody[`chartingWb_selected_metric_${ithOfSelectedMetric}`] =
          chartingWb.selectedMetric[ithOfSelectedMetric];
      }
      nrLogBody.topMovers_selectedEntity = topMovers.selectedEntity;
      nrLogBody.topMovers_selectedMeasure = topMovers.selectedMeasure;
      let benchmarkingSelectedMetricCounter = 0;
      for (const categoryKey in benchMarking[this.page]
        ?.benchMarkingTableSelectedMetricList) {
        for (const keyOfBenchMarkingTableSelectedMetricList in benchMarking[
          this.page
        ].benchMarkingTableSelectedMetricList[categoryKey]) {
          nrLogBody[
            `benchmarking_selected_metric_${benchmarkingSelectedMetricCounter}`
          ] =
            benchMarking[this.page].benchMarkingTableSelectedMetricList[
              categoryKey
            ][keyOfBenchMarkingTableSelectedMetricList].key;
          benchmarkingSelectedMetricCounter++;
        }
      }
      nrLogBody.benchmarking_selected_entity =
        benchMarking[this.page]?.benchmarkingMainDropdownSelectionKey;
      nrLogBody.eventType = 'save_view_context';
      nrLogBody.page = this.page;
      this.$logger.logEvent(nrLogBody);
    },
    getCurrentViewInfo() {
      const widgets = this.widgetsData.map((e) => e.widgetName);
      const widgetMetadata = widgets.reduce((acc, index) => {
        acc[index] = cloneDeep(this.$store.getters[`get${index}SaveViewData`]);
        return acc;
      }, {});

      const currentView = {
        filters: this.getSelectedFiltersValues(),
        widgetMetadata: {}
      };
      currentView.widgetMetadata = { ...widgetMetadata };
      return currentView;
    },
    validateView(type) {
      const globalFilter = JSON.parse(
        JSON.stringify(this.$store.getters.getGlobalViewInfo)
      );
      const globalFilterHash = hash(globalFilter);
      const currentFilter = JSON.parse(
        JSON.stringify(this.getCurrentViewInfo())
      );
      const currentFilterHash = hash(currentFilter);
      // To see the logs in fs
      if (globalFilterHash === currentFilterHash) {
        this.hideViewBtn = true;
      } else {
        this.hideViewBtn = false;
        // Show the button decide the current view type and hide the update button
        if (!this.selectedView?.currentUser) {
          this.hideUpdateView = true;
        } else {
          this.hideUpdateView = false;
        }
      }
    },
    getSelectedFiltersValues() {
      const getFilterValues = this.filterInstance?.getSelectedFilters() || {};
      if (getFilterValues.values) {
        const filterValues = {
          date_range: {
            name: getFilterValues?.values.date_range?.name,
            compare: getFilterValues?.values.date_range?.compare,
            compare_name: getFilterValues?.values.date_range?.compare_name
          },
          selectedFilter: {
            filter: [...this.filterInstance?.getFiltersFullMetadata()],
            order: [...getFilterValues.order]
          }
        };
        return filterValues;
      }
      return getFilterValues;
    },
    deleteView(view) {
      const instance = document.querySelector('.tippy-popper')._tippy;
      instance?.hide();
      const request = {
        name: view.name,
        page: view.page,
        globalViewId: view.globalViewId
      };
      this.$store.dispatch('deleteView', request).then(
        (response) => {
          if (response.data?.success) {
            this.$snackbar.open({
              message: 'View deleted Successfully',
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
            if (this.selectedView.globalViewId === view.globalViewId) {
              this.fetchGlobalView();
            }
            this.viewList = this.viewList.filter((item) => {
              if (item.globalViewId !== view.globalViewId) {
                return true;
              }
              return false;
            });
          } else {
            this.$snackbar.open({
              message: 'Something went wrong !!. Try again',
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
          }
        },
        (error) => {
          this.$snackbar.open({
            message:
              'Something went wrong !!. Try again' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
      );
    },
    toggleEdit(option) {
      // [].forEach.call(document.querySelectorAll('.select__dropdown-list-item'), function (el) {
      //   el.classList.remove('overrideHover');
      // });
      this.viewList = this.viewList.map((item) => {
        if (item.globalViewId === option.globalViewId) {
          if (!option.edit) {
            setTimeout(() => {
              document
                .querySelector(`div[title="${item.label}"]`)
                .closest('.select__dropdown-list-item')
                ?.classList?.add('overrideHover');
              const input = document.querySelector(
                `#input${option.globalViewId}`
              );
              input?.focus();
              input?.select();
            });
          } else {
            document
              .querySelector(`div[title="${item.label}"]`)
              .closest('.select__dropdown-list-item')
              ?.classList?.remove('overrideHover');
          }
          item.edit = !item.edit;
        } else {
          document
            .querySelector(`div[title="${item.label}"]`)
            .closest('.select__dropdown-list-item')
            ?.classList?.remove('overrideHover');
          item.edit = false;
        }
        return item;
      });
    },
    addHoverClass(option) {
      document
        .querySelector(`div[title="${option.label}"]`)
        .closest('.select__dropdown-list-item')
        ?.classList.add('my-class');
    },
    updateViewName(view) {
      const instance = document.querySelector('.tippy-popper')._tippy;
      instance?.hide();
      this.onUpdateView('update', view, 'name');
    },
    transformFilterFromAPI(filter) {
      const obj = {};
      Object.keys(filter?.[0] || []).forEach((item) => {
        if (!filter[0][item].isInvalidated) {
          obj[item] = filter[0][item].values;
        } else if (filter[0][item].filterInvalidationDetails.valueInvalidated) {
          const filterValues = filter[0][item].values.filter((value) => {
            if (
              filter[0][
                item
              ].filterInvalidationDetails.invalidFilterValues.indexOf(value) ===
              -1
            ) {
              return value;
            }
            return false;
          });
          if (filterValues.length > 0) {
            obj[item] = filterValues;
          }
        }
      });

      return {
        order: Object.keys(obj || []) || [],
        values: obj
      };
    },
    filterListener(data) {
      // do something on event
      this.$store.dispatch('setWhereClause', data);
    },
    onResetView() {
      // this.onViewChange(this.selectedView);
      this.fetchPageList(this.selectedView.globalViewId);
    },
    onShowConfirmDialogFn(type, view) {
      this.showConfirmPopupType = type;
      if (type === 'delete') {
        this.selectedDeleteView = cloneDeep(view);
      }
      this.showConfirmPopup = !this.showConfirmPopup;
    },
    onCancelDialog() {
      this.showConfirmPopup = !this.showConfirmPopup;
    },
    saveDraftView() {
      const requestBody = {
        name: this.selectedView.label,
        page: this.page,
        pageId: this.listAPIResponse.id,
        globalViewId: this.selectedView.globalViewId
      };
      Object.assign(requestBody, { ...this.getCurrentViewInfo() });
      return this.$store
        .dispatch('draftView', requestBody)
        .finally((response) => {
          return response || 'success';
        });
    },
    onHide(tippyInstance) {
      this.viewList = this.viewList.map((item) => {
        document
          .querySelector(`div[title="${item.label}"]`)
          ?.closest('.select__dropdown-list-item')
          ?.classList?.remove('overrideHover');
        item.edit = false;
        return item;
      });
      this.hideInsideTippy = true;
    },
    onShow(tippyInstance) {
      this.hideInsideTippy = false;
    },
    handlePageScroll(event) {
      const elemTop =
        document.querySelector('#businessOverview')?.getBoundingClientRect()
          ?.top || 100;
      const filterElem = document.querySelector('.bi-filter');
      if (elemTop < 50) {
        filterElem?.classList?.add('nav-transparent');
      } else {
        filterElem?.classList?.remove('nav-transparent');
      }
    },
    setFilterValues(oFilter) {
      let isNewFilter = false;
      const storage =
        JSON.parse(localStorage.getItem(this.filterLocalStorageKey)) || {};
      const storageOrder =
        JSON.parse(
          localStorage.getItem(`${this.filterLocalStorageKey}-order`)
        ) || [];
      if (storage[oFilter.key]) {
        const findIndex = storage[oFilter.key].findIndex(
          (item) => item === oFilter.value
        );
        if (findIndex === -1) {
          storage[oFilter.key].push(oFilter.value);
          isNewFilter = true;
        }
      } else {
        isNewFilter = true;
        storage[oFilter.key] = [oFilter.value];
        storageOrder.push(oFilter.key);
      }
      const oSetFilterFormat = {};
      oSetFilterFormat.order = [...storageOrder];
      oSetFilterFormat.values = cloneDeep(storage);
      if (isNewFilter) {
        this.$snackbar.open({
          message: 'Filter added',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        this.enableOpenFilter = false;
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify(storage)
        );
        localStorage.setItem(
          `${this.filterLocalStorageKey}-order`,
          JSON.stringify(storageOrder)
        );
        this.$nextTick(() => {
          this.filterInstance.setFilters(oSetFilterFormat);
        });
        setTimeout(() => {
          this.enableOpenFilter = true;
        }, 10);
      } else {
        this.$snackbar.open({
          message: 'Filter already exists',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    },
    onOkayBtn() {
      this.showConfirmPopup = false;
      if (this.showConfirmPopupType === 'update') {
        this.onUpdateView('update');
      } else if (this.showConfirmPopupType === 'delete') {
        this.deleteView(this.selectedDeleteView);
      } else if (this.showConfirmPopupType === 'invalidFilter') {
        this.onUpdateView('update');
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-sticky-on-scroll {
  position: sticky;
  z-index: 2;
}

.u-maxWidth {
  max-width: 1440px;
  margin: auto;
}

.u-border-none {
  border: none;
}

.input_value {
  border: none !important;
  outline: none;
  background: none;
  padding-left: 0px;
  color: #2b333b !important;
}

.input_value:hover {
  color: white !important;
}

.viewActionIcons {
  visibility: hidden;
}

.overrideHover {
  background: #f5f7fa;
}
.overrideHover .input_value {
  color: #2b333b !important;
  width: 100%;
}
.overrideHover .input_value::selection {
  background-color: #bfdffd;
}
.overrideHover .icon-cross-fill-circle {
  color: #8b8f93 !important;
  fill: #8b8f93 !important;
}
.overrideHover .icon-save {
  color: #007cf6 !important;
  fill: #007cf6 !important;
}

.select__dropdown-list-item:hover .viewActionIcons {
  visibility: visible;
  display: inline-flex;
}

.bi-filter {
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}

.deleteBtn:hover {
  background-color: #d7263d;
}

.rb-filter {
  padding-bottom: 5px;
}
.rb-filter .filter--token {
  border-color: #ffa800;
}

.u-border-style-dashed {
  border-style: dashed;
}

.u-text-decoration-underline {
  text-decoration: underline;
}

.u-max-width-260px {
  max-width: 260px;
}

.u-height-36px {
  height: 36px;
}
</style>
