<template>
  <div>
    <rb-select
      class="u-spacing-ml-xxs valueDropdown u-border-none"
      :send-details="true"
      :width="width"
      :on-close="optionSelectedFn"
      :options="dropdownOptions"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-spacing-ph-xxs u-cursor-pointer u-text-transform-uppercase"
      >
        <div
          v-if="typeOfDropdown === 'text'"
          class="u-display-flex"
        >
          <text-select-component
            class="u-display-flex text-select"
            :selected-zip-code="selectedOptionsData"
          />
          <rb-icon
            class="u-flex-1 rb-icon--large"
            :icon="'caret-down'"
          />
        </div>
        <div
          v-if="typeOfDropdown === 'asin'"
          class="asin-container u-border-color-grey-xxx-light u-border-width-s u-border-all u-spacing-p-s"
        >
          <div class="u-display-flex">
            <rb-sku
              class="u-text-overflow-ellipsis asin-table-holder u-width-400px"
              :show-image="true"
              :title="selectedOptionsData.title"
              :image-url="selectedOptionsData.image_url"
              :asin="selectedOptionsData.sku"
              :rb-asin-tippy-text="skuTooltip"
              :product-page-url="selectedOptionsData.product_url"
              :enable-s-d-p="false"
            />
            <rb-icon
              class="u-flex-1 rb-icon--large u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
        </div>
      </div>
      <div
        v-if="typeOfDropdown === 'asin'"
        slot="item"
        slot-scope="option"
      >
        <skuSelect
          class="dropdown-max-width"
          :image-url="option.image_url"
          :asin-name="option.title"
          :asin="option.sku"
        />
      </div>
    </rb-select>
  </div>
</template>
<script>
import textSelectComponent from './textSelectComponent';
import skuSelect from '@/components/pages/insights/amazon/share-of-voice/atoms/sku-select.vue';

export default {
  name: 'SelectWrapper',
  components: {
    textSelectComponent,
    skuSelect
  },
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    skuTooltip: {
      type: String,
      default: 'View Amazon detail page'
    },
    dropdownOptions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedOption: {
      type: Object,
      default: function () {
        return {};
      }
    },
    typeOfDropdown: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      selectedOptionsData: {}
    };
  },
  watch: {
    selectedOption(newVal) {
      this.selectedOptionsData = newVal || {};
    }
  },
  created() {
    // this.selectedOptionsData = this.selectedOption;
    this.selectedOptionsData = this.dropdownOptions[0] || {};
  },
  methods: {
    optionSelectedFn(context, selection) {
      if (selection.length > 0) {
        this.selectedOptionsData = selection[0];
        this.$emit('onClick', selection[0]);
      }
    }
  }
};
</script>
<style lang="css">
.u-text-transform-uppercase {
  text-transform: uppercase;
}
.asin-container {
  min-width: 480px;
  max-width: 480px;
}
.asin-container:hover {
  border-color: #caccce;
}
.asin-container:hover .rb-icon {
  color: #6a7075;
}
.text-select {
  margin-top: 6px;
}
</style>
