<!--
Values expected in props for rendering

icon:         icon to be displayed
iconSize:     size of icon, small/medium/large etc
iconClasses:  A string denoting the css classes to apply to the icon
text:         text to be displayed alongside icon
textClasses:  A string denoting the css classes to apply to the text
-->
<template>
  <div
    class="u-width-100 iconText"
    :class="wrapperClass"
  >
    <span
      v-tippy="tippyOptions"
      class="u-display-flex u-text-overflow-ellipsis"
      :title="tooltip || ''"
    >
      <rb-icon
        :class="[iconSizeClass, iconClasses]"
        :icon="getMetadata().icon"
      />
      <span
        v-if="text"
        class="textClass"
        :class="textClasses"
      >
        {{ getMetadata().text }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapKeys } from 'lodash';
export default {
  name: 'IconWithText',
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: 'xx-medium'
    },
    iconClasses: {
      type: String,
      default: 'u-color-grey-lighter'
    },
    textClasses: {
      type: String,
      default: 'u-spacing-mb-xxs u-color-grey-base u-font-size-6'
    },
    keyTransformMap: {
      type: Object,
      default: null
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    tippyOptions: {
      type: Object,
      default: () => {
        return { placement: 'top', arrow: true };
      }
    }
  },
  computed: {
    iconSizeClass() {
      return 'rb-icon--' + this.iconSize;
    }
  },
  methods: {
    getMetadata() {
      const caseInsensitiveKeyTransform = mapKeys(
        this.keyTransformMap,
        (val, key) => {
          return (key || '').toUpperCase();
        }
      );
      const caseInsensitiveTextValue = (this.text || '').toUpperCase();
      return {
        icon:
          caseInsensitiveKeyTransform[caseInsensitiveTextValue]?.icon ||
          this.icon,
        text:
          caseInsensitiveKeyTransform[caseInsensitiveTextValue]?.text ||
          this.text
      };
    }
  }
};
</script>

<style lang="css" scoped>
.textClass {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}
</style>
