<template>
  <pvp-timeseries-switch
    :value="advancedReporting"
    class="u-height-35"
    @onSwitchChange="toggleAdvancedReporting"
  >
    <div slot="label">
      <custom-tool-tip :tippy="tippy">
        <div slot="toolTipData">
          <div
            class="u-display-flex u-flex-direction-column u-width-400px u-spacing-p-s"
          >
            <span class="u-font-size-5 u-font-weight-600">
              Advanced Reporting Metrics
            </span>
            <div class="u-font-size-6 u-spacing-mt-m u-font-weight-400">
              Attribution model based on 14 days click and 1 day view. It
              consists brand halo metrics as well as instore Sales metrics
            </div>
            <span class="u-font-size-5 u-font-weight-600 u-spacing-mt-m">
              Standard Reporting Metrics
            </span>
            <div class="u-font-size-6 u-spacing-mt-m u-font-weight-400">
              Attribution model based on 14 days same SKU and direct click only
            </div>
          </div>
        </div>
        <div
          slot="body"
          slot-scope="{ tippyConfig }"
          v-tippy="tippyConfig"
        >
          <span
            class="u-color-grey-lighter u-spacing-mr-xs u-cursor-pointer u-font-size-7 u-font-weight-600"
            >Advanced Reporting Metrics
          </span>
        </div>
      </custom-tool-tip>
    </div>
  </pvp-timeseries-switch>
</template>

<script>
import CustomToolTip from '@/components/widgets/customToolTip.vue';
import PvpTimeseriesSwitch from '@/components/globals/pvpTimeseriesSwitch.vue';

export default {
  name: 'AdvancedReportingSwitch',
  components: {
    CustomToolTip,
    PvpTimeseriesSwitch
  },
  props: {
    advancedReporting: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.tippy = {
      html: '#advancedReportingTooltip',
      interactive: false,
      offset: '0,0'
    };
  },
  methods: {
    toggleAdvancedReporting(val) {
      this.$emit('toggleAdvancedReporting', val);
    }
  }
};
</script>
