<template>
  <div class="cardListContainerClass">
    <widgetContainer
      :key="myKey"
      :context="context"
      :header-options="headerOptionsComp"
      :header-container-grid-styles="headerContainerGridStyles"
      :is-loading="isLoading"
      :enable-footer="false"
      :style="customContainerStyle"
    >
      <div
        slot="body"
        class="u-display-flex u-flex-wrap-yes"
      >
        <div
          v-for="(metric, index) in metricsComputed"
          :key="index"
          class="u-flex-1 u-spacing-mr-m u-spacing-mb-m"
          :class="{ 'u-max-width-25': metricsComputed.length < 4 }"
        >
          <div class="card u-height-100 u-text-align-center u-cursor-pointer">
            <rb-tooltip-header
              :id="'tooltipTemplate'"
              :title="metric.label"
              :message="metric.tooltip"
            />
            <span
              v-tippy="setTooltip.tippyOptions"
              class="card__title u-font-size-4 u-color-grey-base u-spacing-p-l u-spacing-pb-0"
            >
              {{ metric.label }}
            </span>
            <div>
              <metric
                :size="'xxl'"
                class="u-display-inline-flex u-spacing-m-l"
                :config="metricConfig"
                :data="metricDataComputed(metric)"
              />
            </div>

            <span
              class="u-spacing-pv-s u-spacing-ph-l u-display-flex u-border-width-s u-border-top u-border-color-grey-xxx-light"
            >
              <div
                class="u-width-100 no-wrap u-color-grey-lighter u-font-size-7"
              >
                Last Updated: {{ lastUpdatedDate }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </widgetContainer>
  </div>
</template>

<script>
import Vue from 'vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import widgetsUtil from '@/components/widgetMixin';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cardWidget from '@/components/widgets/cardWidget';
import metric from '@/components/basic/metric';
import loader from '@/components/basic/loader';
import { isEqual } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    widgetContainer,
    cardWidget,
    metric,
    loader
  },
  mixins: [widgetsUtil, cwMixin],
  props: {
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customContainerStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    metricConfig: {
      type: Object,
      default: () => {
        return {
          tag1Unit: {
            roundoff: 0
          }
        };
      }
    }
  },
  data() {
    return {
      config: {},
      entityData: [],
      metricsComputed: [],
      myKey: 0,
      isLoading: false
    };
  },
  computed: {
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    }
  },
  watch: {
    metadata() {},
    widgetRequestParams(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) {
        console.log('refresh skipped since widgetRequestParams are equal ');
        return;
      }
      // Re-fetch the data if widget params are updated
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      try {
        this.isLoading = true;

        // Fetch data from list API
        const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);
        let request, api;
        const dataGroupsList = Object.keys(dataGroups);
        for (let i = 0; i < dataGroupsList.length; i++) {
          const dgKey = dataGroupsList[i];
          const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
          const metricsListName = Object.values(dataGroups[dgKey]).map(
            ({ name }) => name
          );
          api = this.metadata.metrics[metricsPerDataGroup[0]].api;
          request = api.request;
          request = dashUtils.replacePlaceHolderWithData(
            request,
            this.widgetRequestParams
          );
          request.where.dimensionNameValueList =
            this.widgetRequestParams[':dimensionNameValueList'];
          request = dashUtils.addMetricListToRequest(request, metricsListName);
          dashUtils.fetchDataAPI(request, api).then((args) => {
            // Set the data received and call the function to compute metrics in desired form
            Vue.set(this.entityData, i, args.entityData[0].data);
            this.computeMetricData();
          });
        }
      } catch (error) {
        console.log(error);
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    // Compute the metrics in required format for the cards
    computeMetricData() {
      if (this.entityData.length > 0) {
        this.isLoading = true;

        const metrics = [];
        const entityDataArr = this.entityData[0];

        // For each metric, check for the corresponding value returen from list API and combine the RESULT data
        Object.values(this.metadata?.metrics).forEach((item) => {
          const specificMetricData = entityDataArr.find(
            (itm) => itm.name === item.keyName
          );

          item.value = specificMetricData;
          metrics.push(item);
        });

        // Set the data and update key to re render
        Vue.set(this, 'metricsComputed', metrics);
        this.myKey = this.myKey + 1;
      }

      this.isLoading = false;
    },
    // The object to be passed to metric component in required format
    metricDataComputed(metricObj) {
      return {
        tag1: metricObj?.value?.RESULT[metricObj?.keyName]
      };
    }
  }
};
</script>
