import adgroupRequestTemplate from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupRequestTemplate.js';
import addSkuConfig from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupAddSkuConfig.js';
import keywordTargeting from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';
import { DataService } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupDataService.js';

const formStepConfig = () => {
  const stepsConfig = [addSkuConfig, keywordTargeting];
  return stepsConfig;
};

export default {
  adgroupRequestTemplate,
  stepsConfig: formStepConfig(),
  getter: 'walmartCampaignCreation/getAllStepsData',
  action: 'walmartCampaignCreation/updateAllStepsData',
  dataService: DataService,
  redirectTo: (vueRef) => {
    const { advertiserId, campaignType, campaignId, campaignName } =
      vueRef.$route.query;
    vueRef.$router.replace({
      name: 'walmartEntityDetails',
      params: {
        entityType: campaignType,
        entityId: campaignId
      },
      query: {
        advertiser_id: advertiserId,
        parent_entity_name: campaignName
      }
    });
  }
};
