import transformer from '@/utils/services/data-transformer.js';

/** Utitlity function to set local storage for default filters
  * @params: filterValueObj: An array of objects of the type:
      [
        {
          'brand': {
            'dimensionLabel': 'brand',
            'dimensionType': 'BRAND',
            'isInvalidated': false,
            'values': [
              'ADORNMENTS'
            ]
          }
        }
      ];
  * @params: filterInstance: The complete filter instance (required for key and emitter)
*/

export const filterType = {
  digitalShelf: 'DIGITAL_SHELF',
  marketShareCategorisation: 'MARKET_SHARE_CATEGORISATION',
  customLists: 'CUSTOM_LISTS',
  expression: 'EXPRESSION'
};

export const filterTitle = {
  standardShelf: 'Standard Shelf',
  marketShareCategory: 'Market Share Category',
  customShelf: 'Custom Shelf'
};

export const setDefaultFilters = (
  filterValuesObject,
  filterInstance,
  appendEsDataSetName
) => {
  const storageValues = transformer.transformFilterFromAPI(
    filterValuesObject || [],
    appendEsDataSetName
  );

  const key = filterInstance.listenerEvent;
  setFiltersLocalStorage(key, storageValues);
};

const setFiltersLocalStorage = (filterLocalStorageKey, storageValues) => {
  if (storageValues?.values) {
    if (Object.keys(storageValues?.values).length > 0) {
      Object.keys(storageValues?.values).forEach((key) => {
        if (storageValues.order.indexOf(key) !== -1) {
          storageValues.values[key] = storageValues.values[key].map((item) => {
            return isJsonParsable(item);
          });
        }
      });
    }
    setLocalStorage(filterLocalStorageKey, storageValues.values);
    setLocalStorage(`${filterLocalStorageKey}-order`, storageValues.order);
  }
};

const isJsonParsable = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export default {
  handleFilterDimensionType: (data) => {
    const parentLevelDimensionType = data?.dimensionType;
    if (parentLevelDimensionType) {
      data.dropDownOptions = data?.dropDownOptions?.map((option) => {
        option.dimensionType = parentLevelDimensionType;
        return option;
      });
    }
    return data;
  }
};
