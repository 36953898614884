<template>
  <div class="u-spacing-pv-xxxl u-spacing-ph-xxl">
    <section class="u-spacing-pv-m">
      <Header :step="step" />
      <div>
        <div class="u-display-flex u-flex-justify-content-space-between">
          <RbSplitTabs
            :tabs-list="tabsList"
            :active-tab="selectedTab"
            :is-tabs-list="true"
            @tabClicked="handleTabClicked"
          />
          <rb-button
            text="Create Ad Group"
            type="hollow"
            :click-fn="handleAddAdGroup"
          />
        </div>
        <div class="u-width-100 u-listed-groups-container u-spacing-mt-xl">
          <div
            v-if="listedAdGroups.length === 0"
            class="u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-6 u-color-grey-x-light"
          >
            No ad groups found
          </div>
          <div
            v-for="(group, i) in listedAdGroups"
            :key="i"
            class="u-border-radius-s u-border-left u-border-color-green-base u-box-shadow u-display-flex u-flex-justify-content-space-between u-border-width-xl u-spacing-p-l"
            :class="[
              !selectedTab || showReadyToLaunchGroups
                ? 'u-border-color-green-base'
                : 'u-border-color-orange-base',
              i + 1 < listedAdGroups.length ? 'u-spacing-mb-xl' : ''
            ]"
          >
            <div
              class="u-font-size-4 u-font-weight-400 u-color-grey-base u-display-flex"
            >
              <span
                data-cy="numberOfCards"
                class="u-spacing-pr-m"
                >{{ i > 9 ? i + 1 : `0${i + 1}` }}</span
              >
              <div data-cy="adGroupName">
                {{ group.adGroupName }}
                <div class="u-display-flex u-font-size-5 u-spacing-mt-s">
                  <div
                    v-if="group.defaultBid && !isOptimizedCampaign"
                    class="u-spacing-mr-l"
                  >
                    <span
                      data-cy="defaultCPCBid"
                      class="u-color-grey-lighter"
                    >
                      Default CPC Bid:
                    </span>
                    ${{ group.defaultBid }}
                  </div>
                  <div
                    v-if="group.products.length"
                    class="u-spacing-mr-l"
                  >
                    <span
                      data-cy="adSKu"
                      class="u-color-grey-lighter"
                    >
                      Ad Skus:
                    </span>
                    {{ group.products.length }}
                  </div>
                  <div v-if="group.defaultBid && !isOptimizedCampaign">
                    <span
                      data-cy="targets"
                      class="u-color-grey-lighter"
                    >
                      Targets:
                    </span>
                    {{ group.keywords ? group.keywords.length : 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="u-spacing-pr-xl u-display-flex u-flex-direction-column u-flex-justify-content-center"
            >
              <div class="u-display-flex u-font-size-6 u-font-weight-600">
                <div
                  data-cy="removeText"
                  class="u-spacing-mr-xl u-cursor-pointer u-display-flex u-flex-align-items-center"
                  @click="handleRemoveAdGroup(i)"
                >
                  <rb-icon
                    class="rb-icon--medium u-cursor-pointer u-spacing-mr-s u-color-grey-lighter"
                    :icon="'delete'"
                  />
                  Remove
                </div>
                <div
                  class="u-cursor-pointer u-display-flex u-flex-align-items-center"
                  @click="
                    handleEditAdGroup(
                      group,
                      selectedTab && !showReadyToLaunchGroups ? 1 : 0
                    )
                  "
                >
                  <rb-icon
                    data-cy="editIcon"
                    class="rb-icon--medium u-cursor-pointer u-spacing-mr-s u-color-grey-lighter"
                    :icon="'pencil'"
                  />
                  <div data-cy="editText">Edit</div>
                </div>
              </div>
              <div
                v-if="selectedTab && !showReadyToLaunchGroups"
                class="u-color-orange-base u-font-size-6 u-font-weight-600 u-spacing-pt-s"
              >
                1 Action Pending
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        class="u-spacing-pt-l"
        :show-right-btn="false"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import Header from '@/components/ams/campaign_creation/steps/common/header.vue';
import Footer from '@/components/ams/campaign_creation/steps/common/footer.vue';
import RbSplitTabs from '@/components/widgets/splitTabs.vue';
import {
  BID_TYPES,
  BUDGET_TYPES,
  STEPS_DATA_GETTER,
  STEPS_DATA_SETTER
} from '../../config';
export default {
  name: 'AdGroup',
  components: {
    Header,
    Footer,
    RbSplitTabs
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      adGroups: [],
      tabsList: [],
      selectedTab: ''
    };
  },
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    listedAdGroups() {
      const { settings } = this.stepsData.steps;
      if (this.selectedTab && settings.bidType === BID_TYPES.manual.type) {
        const invalidDefaultBid = (defaultBid) =>
          settings.budgetType === BUDGET_TYPES.dailyBudget.type
            ? defaultBid >= settings.dailyBudget
            : defaultBid >= settings.lifetimeBudget;
        if (this.showReadyToLaunchGroups) {
          return this.adGroups.filter(
            ({ defaultBid }) => defaultBid && !invalidDefaultBid(defaultBid)
          );
        }

        return this.adGroups.filter(
          ({ defaultBid }) => !defaultBid || invalidDefaultBid(defaultBid)
        );
      }

      return this.adGroups;
    },
    showReadyToLaunchGroups() {
      return this.selectedTab === this.tabsList[0];
    },
    isOptimizedCampaign() {
      return [BID_TYPES.max_sales.type, BID_TYPES.acquire_ntb.type].includes(
        this.stepsData?.steps?.settings?.bidType
      );
    }
  },
  created() {
    const { adGroups, settings } = this.$store.getters[STEPS_DATA_GETTER].steps;
    this.adGroups = adGroups;
    this.checkForPendingGroups(adGroups, settings);
  },
  methods: {
    prevStep() {
      this.$emit('prevStep', {});
    },
    handleAddAdGroup() {
      const stepsData = { ...this.stepsData };
      stepsData.tempAdGroup.id = new Date().getTime();
      this.$store
        .dispatch(STEPS_DATA_SETTER, stepsData)
        .then(() => this.$parent.$emit('createAdGroup', 'creating', 0));
    },
    handleRemoveAdGroup(index) {
      const stepsData = { ...this.stepsData };
      const updatedAdGroups = [...this.adGroups];
      updatedAdGroups.splice(index, 1);
      this.adGroups = updatedAdGroups;
      stepsData.steps.adGroups = this.adGroups;
      this.checkForPendingGroups(this.adGroups, stepsData.steps.settings);
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    handleEditAdGroup(adGroup, jumpToStep) {
      const stepsData = { ...this.stepsData };
      stepsData.tempAdGroup = { ...adGroup };
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
      this.$parent.$emit('createAdGroup', 'editing', jumpToStep);
    },
    handleTabClicked(newValue) {
      this.selectedTab = newValue;
    },
    checkForPendingGroups(adGroups, settings) {
      if (adGroups.length > 0) {
        const pendingAdGroups = adGroups.filter(
          ({ defaultBid }) =>
            settings.bidType === BID_TYPES.manual.type &&
            (!defaultBid ||
              (settings.budgetType === BUDGET_TYPES.dailyBudget.type
                ? defaultBid >= settings.dailyBudget
                : defaultBid >= settings.lifetimeBudget))
        );
        if (pendingAdGroups.length > 0) {
          this.tabsList = [
            `${
              adGroups.length - pendingAdGroups.length
            } Ad group(s) ready to launch`,
            `${pendingAdGroups.length} Ad groups with pending actions`
          ];
          this.selectedTab = `${pendingAdGroups.length} Ad groups with pending actions`;
        } else {
          this.selectedTab = '';
        }
      } else {
        this.tabsList = [];
        this.selectedTab = '';
      }
    }
  }
};
</script>

<style lang="css" scoped>
.rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}

.u-box-shadow {
  box-shadow: 0 1px 4px 2px rgb(43 51 59 / 15%);
}

.u-border-width-xl {
  border-width: 8px;
}

.u-listed-groups-container {
  height: 400px;
  overflow: auto;
  padding: 4px 4px 4px 0;
}
</style>
