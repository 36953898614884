import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import { entityModeMap } from '@/components/ams/campaigns/constants.js';
import {
  realtimeTableData,
  mutateRealtimeState,
  populateCampaignsChartData,
  getCampaignChartAPITemplates,
  populateStreamChartData,
  fetchStreamData
} from '@/components/ams/campaigns/storeHelper.js';

const state = {
  campaignMode: 'realtime',
  workbenchCampaignsData: {},
  addGroupLoader: false,
  workbenchCampaignsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  streamCampaignsTableData: {
    arr: [],
    tableData: {},
    mutations: {}
  },
  streamCampaignsToggle: false,
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  showCampaignsActivityLog: false,
  campaignPrimaryKey: null,
  campaignsKeywords: {},
  campaignsAsins: {},
  campaignsRealTimeMetadata: {},
  campaignsWorkspaceTableLoader: false,
  dayPartingState: {},
  emailSent: {},
  streamMap: {},
  adGroupsFromCampaignId: [],
  page_wise_min_max_key: 'ams_campaign',
  timeSeriesRollUpBy: 'DAY',
  streamMode: false,
  enablePVPStream: false,
  streamChartData: {},
  campaignsAdGroups: { load: false, rows: [], error: false, totalRows: 0 },
  campaignsIsAllSelected: false
};

const getters = {
  getAmazonCampaignMode: (state) => state.campaignMode,
  getIsAllSelectedStateCampaigns: (state) => {
    return state.campaignsIsAllSelected;
  },
  getStreamChartData: (state) => state.streamChartData,
  getCampaignsWhereClause: (state) => state.whereClause,
  getWorkbenchCampaignsSelectedFilters: (state) => {
    return state.workbenchCampaignsSelectedFilters;
  },
  getStreamCampaignsTableData: (state) => {
    return state.streamCampaignsTableData;
  },
  getStreamCampaignsToggle: (state) => {
    return state.streamCampaignsToggle;
  },
  getAddGroupLoader: (state) => {
    return state.addGroupLoader;
  },
  getWorkbenchCampaignsData: (state) => {
    return state.workbenchCampaignsData;
  },
  getShowCampaignsActivityLog: (state) => {
    return state.showCampaignsActivityLog;
  },
  getCampaignPrimaryKey: (state) => {
    return state.campaignPrimaryKey;
  },
  getCampaignsKeywords: (state) => {
    return state.campaignsKeywords;
  },
  getCampaignsAsins: (state) => {
    return state.campaignsAsins;
  },
  getCampaignWorkspaceTableLoader: (state) => {
    return state.campaignsWorkspaceTableLoader;
  },
  getdayPartingState: (state) => {
    return state.dayPartingState;
  },
  getEmailSentStatus: (state) => {
    return state.emailSent;
  },
  getAdGroupsFromCampaignId: (state) => {
    return state.adGroupsFromCampaignId;
  },
  getCampaignsPageWiseMinMaxKey() {
    return state.page_wise_min_max_key;
  },
  getCampaignsAdGroups: (state) => state.campaignsAdGroups,
  getCampaignsRealTimeMetadata: (state) => state.campaignsRealTimeMetadata,
  getCampaignsRollUpByValue(state) {
    return state.timeSeriesRollUpBy;
  },
  getCampaignsStreamMap: (state) => state.streamMap,
  getCampaignsStreamMode: (state) => state.streamMode,
  getCampaignsYTDMode: (state) => state.enablePVPStream
};

const mutations = {
  SET_AMAZON_CAMPAIGN_MODE: (state, data) => {
    state.campaignMode = entityModeMap[data];
  },
  SET_IS_ALL_SELECTED_STATE_CAMPAIGNS: (state, data) => {
    state.campaignsIsAllSelected = data;
  },
  SET_STREAM_CHART_MODE: (state, data) => {
    state.streamMode = data;
  },
  SET_PVP_STREAM: (state, data) => {
    state.enablePVPStream = data;
  },
  SET_STREAM_CAMPAIGNS_TABLE_DATA: (state, data) => {
    state.streamCampaignsTableData = data;
  },
  SET_STREAM_CAMPAIGNS_TOGGLE: (state, data) => {
    state.streamCampaignsToggle = data;
  },
  EMAIL_SENT: (state, data) => {
    state.emailSent = data;
  },
  AD_GROUPS_FROM_CAMPAIGN_ID: (state, data) => {
    state.adGroupsFromCampaignId = data;
  },
  RESET_CAMPAIGN_ASINS: (state) => {
    state.campaignsAsins = {};
  },
  IWKCAMPAIGNS_REALTIME_UPDATE: (state, data) => {
    mutateRealtimeState(state, data);
  },
  IWKCAMPAIGNS_STREAM_DATA: (state, data) => {
    state.streamMap = data;
  },
  IWKCAMPAIGNS_SUCCESS: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', data.columns);
    }
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', data.rows);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'page', data.page);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }
  },
  IWKCAMPAIGNS_RESET: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  IWKCAMPAIGNS_ERROR: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  STREAM_DATA_RESET: (state, data) => {
    state.streamChartData = {
      data: state.enablePVPStream ? state.streamChartData.data : [],
      rows: [],
      load: true,
      error: false,
      noData: false,
      metrics: {}
    };
  },
  STREAM_DATA_SUCCESS: (state, data) => {
    state.streamChartData = {
      data: data.columns,
      pvp_data: data.pvp_columns,
      xs: null,
      load: false,
      error: false,
      noData: false,
      legends: data.legends,
      metrics: data.metrics,
      metricsUnits: data.metricsUnits
    };
  },
  STREAM_DATA_ERROR: (state, data) => {
    state.streamChartData = {
      columns: [],
      rows: [],
      load: false,
      error: true,
      noData: false,
      metrics: {}
    };
  },
  IWKCAMPAIGNS_SET_SELECTED_FILTER: (state, data) => {
    const dateRange = state.workbenchCampaignsSelectedFilters.date_range;
    Vue.set(state, 'workbenchCampaignsSelectedFilters', data.values);
    if (!state.workbenchCampaignsSelectedFilters.date_range) {
      Vue.set(state.workbenchCampaignsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKCampaigns(data, state);
  },
  OPEN_CAMPAIGNS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showCampaignsActivityLog', true);
  },
  CLOSE_CAMPAIGNS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showCampaignsActivityLog', false);
  },
  CHANGE_CAMPAIGNS_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'campaignPrimaryKey', key);
  },
  CAMPAIGNS_KEYWORD_SUCCESS: (state, data) => {
    const campaignID = data.campaignId;
    const responseData = data.APIData;
    Vue.set(state.campaignsKeywords, campaignID, responseData);
  },
  CAMPAIGNS_ASIN_SUCCESS: (state, data) => {
    const campaignID = data.campaignId;
    const responseData = data.APIData;
    Vue.set(state.campaignsAsins, campaignID, responseData);
  },
  CREATING_DAY_PARTING_WITH_DATA: (state, data) => {
    Vue.set(state, 'dayPartingState', data);
  },
  CAMPAIGNS_AD_GROUPS(state, data) {
    Vue.set(state, 'campaignsAdGroups', data);
  },
  CAMPAIGNS_ROLL_UP_BY_CHANGE: (state, key) => {
    Vue.set(state, 'timeSeriesRollUpBy', key);
  }
};

function getFiltersInFormatWKCampaigns(oFilter, state) {
  const where = state.whereClause;
  where.dimensionNameValueList = [];
  for (const i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (let j of oFilter.values[i]) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: j
          });
        }
      }
    } else {
      const date = oFilter.values[i];
      const _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      let _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  setIsAllSelectedStateCampaigns: (context, data) => {
    context.commit('SET_IS_ALL_SELECTED_STATE_CAMPAIGNS', data);
  },
  setCampaignsStreamMode: (context, data) => {
    context.commit('SET_STREAM_CHART_MODE', data);
  },
  setCampaignsPVPRealtime: (context, data) => {
    context.commit('SET_PVP_STREAM', data);
  },
  setStreamCampaignsToggle: (context, data) => {
    context.commit('SET_STREAM_CAMPAIGNS_TOGGLE', data);
  },
  setCampaignsRollUpByChanges: (context, data) => {
    context.commit('CAMPAIGNS_ROLL_UP_BY_CHANGE', data);
  },
  downloadCampaignsTableData: (context, data) => {
    return HttpLayer.post({
      APIData: data
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  setWorkbenchCampaignsSelectedFilters: (context, data) => {
    context.commit('IWKCAMPAIGNS_SET_SELECTED_FILTER', data);
  },

  fetchKeywordsForTheCampaign: (context, data) => {
    const currentCampaignId =
      data.body.APIConfig.where.dimensionNameValueList[0].dimensionValue;
    context.state.campaignsWorkspaceTableLoader = true;
    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          // response.data = tableDummydata;
          const data = transformer.mergeResultDimension(response.data);
          context.commit('CAMPAIGNS_KEYWORD_SUCCESS', {
            APIData: data,
            campaignId: currentCampaignId
          });
        }
        context.state.campaignsWorkspaceTableLoader = false;
      })
      .catch((error) => {
        context.state.campaignsWorkspaceTableLoader = false;
        console.log(error);
      });
  },

  fetchAdGroupsFromCampaignId: (context, data) => {
    const currentCampaignId =
      data.body.APIConfig.where.dimensionNameValueList[0].dimensionValue;
    context.state.addGroupLoader = true;
    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          const data = transformer.mergeResultDimension(response.data);
          context.commit('AD_GROUPS_FROM_CAMPAIGN_ID', {
            APIData: data,
            campaignId: currentCampaignId
          });
        }
        context.state.addGroupLoader = false;
      })
      .catch((error) => {
        context.state.addGroupLoader = false;
        console.log(error);
      });
  },

  resetCampaignAsins: (context) => {
    context.commit('RESET_CAMPAIGN_ASINS');
  },

  fetchAsinsForTheCampaign: (context, data) => {
    const currentCampaignId =
      data?.currentCampaignId ||
      data.body.APIConfig.where.dimensionNameValueList[0]?.dimensionValue;
    context.state.campaignsWorkspaceTableLoader = true;
    const transformAllSkusResponse = data?.transformAllSkusResponse;
    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          const data = transformer.mergeResultDimension(response.data);
          if (transformAllSkusResponse) {
            transformAllSkusResponse(data);
          }
          context.commit('CAMPAIGNS_ASIN_SUCCESS', {
            APIData: data,
            campaignId: currentCampaignId
          });
        }
        context.state.campaignsWorkspaceTableLoader = false;
      })
      .catch((error) => {
        context.state.campaignsWorkspaceTableLoader = false;
        console.log(error);
      });
  },

  sendDownloadDataAsEmail: (context, data) => {
    HttpService.post('EXECUTE_CUBE_API_EMAIL', data)
      .then((response) => {
        context.commit('EMAIL_SENT', response);
      })
      .catch((error) => {
        context.commit('EMAIL_SENT', error);
      });
  },

  getWorkbenchCampaignsTableData: (context, data) => {
    const mutations = {
      reset: 'IWKCAMPAIGNS_RESET',
      error: 'IWKCAMPAIGNS_ERROR',
      success: 'IWKCAMPAIGNS_SUCCESS',
      realtimeMetadata: 'IWKCAMPAIGNS_REALTIME_UPDATE',
      streamData: 'IWKCAMPAIGNS_STREAM_DATA'
    };
    realtimeTableData(context, data, mutations);
  },
  getWorkbenchCampaignsChartData: async (context, data) => {
    const streamCommitMessage = {
      SUCCESS: 'STREAM_DATA_SUCCESS',
      RESET: 'STREAM_DATA_RESET',
      ERROR: 'STREAM_DATA_ERROR'
    };
    const chartDataCommitMessage = {
      SUCCESS: 'IWKCAMPAIGNS_SUCCESS',
      RESET: 'IWKCAMPAIGNS_RESET',
      ERROR: 'IWKCAMPAIGNS_ERROR'
    };

    context.commit(chartDataCommitMessage.RESET, {
      key: data.meta.key
    });
    context.commit(streamCommitMessage.RESET, {
      key: data.meta.key
    });

    const { chartAPIPayload, streamChartAPIPayload } =
      getCampaignChartAPITemplates(context, data);
    try {
      await populateCampaignsChartData(
        data,
        context,
        chartAPIPayload,
        chartDataCommitMessage
      );
      if (context.getters.getStreamCampaignsToggle) {
        await populateStreamChartData(
          data,
          context,
          streamChartAPIPayload,
          streamCommitMessage,
          state.enablePVPStream,
          'Campaigns'
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  getWorkbenchCampaignsTableStreamData: async (context) => {
    if (
      context.getters.getStreamCampaignsTableData &&
      context.getters.getStreamCampaignsToggle
    ) {
      const { arr, tableData, mutations } =
        context.getters.getStreamCampaignsTableData;
      try {
        await fetchStreamData(context, tableData, arr, mutations);
      } catch (error) {
        console.error(error);
      }
    }
  },

  updateCampaignAction: (context, data) => {
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
      } else {
      }
    });
  },
  getCampaignsActivityLog: (context, data) => {
    context.commit('OPEN_CAMPAIGNS_ACTIVITY_LOG');
    context.commit('ACTIVITY_LOG_RESET', {
      key: 'CampaignsActivityLog'
    });
    context.commit('CHANGE_CAMPAIGNS_PRIMARYKEY', data.primaryKey);
    HttpService.get('AMS_ACTIONS', {
      append: '?widget=campaign&primaryKey=' + data.primaryKey
    }).then((response) => {
      if (response.data && !response.data.success) {
        context.commit('ACTIVITY_LOG_ERROR', {
          key: 'CampaignsActivityLog',
          rows: []
        });
      } else {
        context.commit('ACTIVITY_LOG_SUCCESS', {
          key: 'CampaignsActivityLog',
          rows: activitytransformer.getActivityData(response.data.response)
        });
      }
    });
  },

  openCampaignsActivityLog: (context) => {
    context.commit('OPEN_CAMPAIGNS_ACTIVITY_LOG');
  },

  closeCampaignsActivityLog: (context) => {
    context.commit('CLOSE_CAMPAIGNS_ACTIVITY_LOG');
    context.commit('CHANGE_CAMPAIGNS_PRIMARYKEY', '');
  },
  createDayPartingWithData: (context, data) => {
    context.commit('CREATING_DAY_PARTING_WITH_DATA', data);
  },
  fetchCampaignsAdGroups(context, data) {
    context.commit('CAMPAIGNS_AD_GROUPS', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    HttpLayer.post({ APIData: data })
      .then((response) => {
        const adGroups = transformer.mergeResultDimension(response.data, true);
        context.commit('CAMPAIGNS_AD_GROUPS', {
          rows: adGroups,
          load: false,
          error: false,
          totalRows: adGroups?.length
        });
      })
      .catch(() => {
        context.commit('CAMPAIGNS_AD_GROUPS', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
