<template>
  <div class="u-display-inline-flex">
    <span
      class="item--key"
      :style="keyStyles"
      >{{ itemKey }}:</span
    ><span
      class="item--value"
      :style="valueStyles"
      >&nbsp;{{ itemValue }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'RbKeyValuePair',
  props: {
    itemKey: { type: [Number, String], required: false, default: '' },
    itemValue: { type: [Number, String], required: false, default: 'NA' },
    keyFontSize: { type: String, required: false, default: '14px' },
    valueFontSize: { type: String, required: false, default: '14px' }
  },
  computed: {
    keyStyles() {
      return { fontSize: this.keyFontSize };
    },
    valueStyles() {
      return { fontSize: this.valueFontSize };
    }
  }
};
</script>

<style scoped lang="css">
.item--key {
  font-weight: 600;
}
</style>
