import metricsDictionary, {
  retailerSpecificMetrics
} from '@/pages/campaign-management/retailers/dictionary.js';
import {
  commonTableActionIcons,
  baseChartConfig,
  commonChartActionIcons,
  additionalDateRanges,
  skusEditStatusActionPayloadKeys,
  gridOptions
} from '@/pages/campaign-management/constants.js';
import {
  getDataFromRowNode,
  payloadGenerator,
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common.js';
import HttpService from '@/utils/services/http-service';
import { systemTransparencyBannerConfig } from '@/pages/campaign-management/retailers/walmart/utils.js';
import { fetchRealtimeTableData } from '@/pages/campaign-management/store';

const WIDGET = 'skus';
const PRIMARY_KEY = 'ad_group_content_id';
const SB_CAMPAIGN_TYPES = ['sba', 'sb'];

const openSnackbarIfHasSB = (hasSB, context) => {
  if (hasSB) {
    context.openSnackbar(
      'Removed SB campaign type SKU(s) from status change request'
    );
  }
};

const getRetailerSpecificMetrics = (retailer) => {
  return retailer === 'walmart'
    ? retailerSpecificMetrics[retailer]
    : [
        'total_attributed_direct_click_sales',
        'total_attributed_related_click_sales',
        'total_attributed_brand_click_sales',
        ...retailerSpecificMetrics[retailer]
      ];
};

const getChartRequest = (retailer = 'walmart') => {
  return {
    cubeName: `${retailer}_campaigns_skus_workbench`,
    measuresList: [
      'ad_spend',
      'roas_14d',
      'impressions',
      'clicks',
      'conversion_rate',
      'units_sold',
      'ctr',
      'acos_14d',
      'cpc',
      'availability_pct',
      'buy_box_pct',
      'page_views',
      'total_attributed_sales',
      'total_attributed_sales_shipping',
      ...getRetailerSpecificMetrics(retailer)
    ],
    groupByDimensionsList: [
      'sku',
      'sku_name',
      'ad_group_id',
      'advertiser_id',
      'campaign_id'
    ],
    timeseriesDimension: 'report_date',
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    getLatestAvailableInsteadOfRollup: false,
    getSharePercentage: false,
    enableNewPVPFormulaForSOV: false,
    disableShard: false,
    filterWhereClause: {
      dimensionNameValueList: []
    },
    commonFilterEnabled: false,
    outerWhereClause: {
      dimensionNameValueList: []
    },
    tagEnabled: false,
    whereClauseCombination: [],
    timeseriesEnabled: true,
    pvpenabled: true,
    yoyenabled: false,
    splyenabled: false,
    digitalShelfEnabled: false,
    sharePercentageV2: false
  };
};

const getTableRequest = (retailer = 'walmart') => {
  if (retailer === 'walmart') {
    return {
      cubeName: 'walmart_campaigns_skus_workbench',
      measuresList: [],
      groupByDimensionsList: [],
      primaryDataGroup: 'walmart_cm_skus_adv_data',
      bundleCubeExecutionRequest: {
        walmart_cm_skus_adv_data: {
          cubeName: 'walmart_campaigns_skus_workbench',
          measuresList: [
            'availability_pct',
            'buy_box_pct',
            'page_views',
            'units_sold',
            'roas_14d',
            'acos_14d',
            'cpc',
            'ad_spend',
            'impressions',
            'clicks',
            'ctr',
            'conversion_rate',
            'total_attributed_sales',
            'total_attributed_sales_shipping',
            'latest_image_url',
            'advertiser_name',
            'brand',
            'category',
            'campaign_name',
            'campaign_type',
            'targeting_type',
            'campaign_status',
            'adgroup_status',
            'adgroup_name',
            'attributed_orders',
            'advertised_sku_units',
            'other_sku_units',
            'advertised_sku_sales',
            'other_sku_sales',
            'conversion_rate_14d_orders_based',
            'ntb_orders',
            'percentageordersnewtobrand14d',
            'ntb_revenue',
            'percentagesalesnewtobrand14d',
            'ntb_units',
            'percentageunitsnewtobrand14d',
            'conversion_rate_ntb'
          ],
          groupByDimensionsList: [
            'sku',
            'sku_name',
            'ad_group_id',
            'advertiser_id',
            'campaign_id',
            'ad_group_content_id'
          ],
          orderByList: [],
          timeseriesRollupBy: ':timeseriesRollupBy',
          getLatestAvailableInsteadOfRollup: false,
          getSharePercentage: false,
          disableShard: false,
          getPointInTimeMetrics: false,
          dedupBeforeRollup: {
            enableDedupBeforeRollup: true,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
              'report_date'
            ],
            excludeDedupAxes: []
          },
          commonFilterEnabled: false,
          customAPIDecisionVars: {
            system: 'walmart_campaign_management'
          },
          enableNewPVPFormulaForSOV: false,
          flowResidueEntity: false,
          entityType: 'ad_group_content_id',
          sharePercentageV2: false,
          pvptimeSeriesEnabled: false,
          timeseriesEnabled: false,
          timeseriesDimension: 'report_date',
          yoyenabled: false,
          pvpenabled: false,
          pointInTimeMetrics: false,
          pvptimeseriesEnabled: false,
          splyenabled: false,
          where: {
            date: {
              from: ':date',
              to: ':date'
            },
            pvpDate: {
              from: ':date',
              to: ':date',
              compare_name: null
            },
            dimensionNameValueList: []
          },
          tagWhereClause: []
        },
        adv_metadata: {
          cubeName: 'walmart_campaigns_skus_workbench_metadata',
          measuresList: ['status', 'bid'],
          groupByDimensionsList: [
            'ad_group_id',
            'advertiser_id',
            'campaign_id',
            'ad_group_content_id'
          ],
          orderByList: [],
          timeseriesRollupBy: ':timeseriesRollupBy',
          getLatestAvailableInsteadOfRollup: false,
          getSharePercentage: false,
          disableShard: false,
          getPointInTimeMetrics: false,
          dedupBeforeRollup: {
            enableDedupBeforeRollup: false,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
              [],
            excludeDedupAxes: []
          },
          commonFilterEnabled: false,
          customAPIDecisionVars: {
            system: 'walmart_campaign_management'
          },
          enableNewPVPFormulaForSOV: false,
          flowResidueEntity: false,
          entityType: 'ad_group_content_id',
          sharePercentageV2: false,
          pvptimeSeriesEnabled: false,
          timeseriesEnabled: false,
          yoyenabled: false,
          pvpenabled: false,
          pointInTimeMetrics: false,
          pvptimeseriesEnabled: false,
          splyenabled: false,
          where: {
            dimensionNameValueList: [],
            tagWhereClause: [],
            date: {},
            pvpDate: {}
          },
          tagWhereClause: []
        }
      },
      where: {
        date: {
          from: ':date',
          to: ':date'
        },
        pvpDate: {
          from: ':date',
          to: ':date',
          compare_name: null
        },
        dimensionNameValueList: []
      },
      customAPIDecisionVars: {},
      entityType: 'ad_group_content_id',
      tagWhereClause: [],
      orderByList: [],
      limit: 30,
      page: 1,
      yoyenabled: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      getLatestAvailableInsteadOfRollup: false
    };
  }
  return {
    cubeName: `${retailer}_campaigns_skus_workbench`,
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    timeseriesDimension: 'report_date',
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [
      'bid',
      'availability_pct',
      'buy_box_pct',
      'page_views',
      'units_sold',
      'roas_14d',
      'acos_14d',
      'cpc',
      'ad_spend',
      'impressions',
      'clicks',
      'ctr',
      'conversion_rate',
      'total_attributed_sales',
      'total_attributed_sales_shipping',
      'latest_image_url',
      'advertiser_name',
      'brand',
      'category',
      'campaign_name',
      'campaign_type',
      'targeting_type',
      'status',
      'campaign_status',
      'adgroup_status',
      'adgroup_name',
      ...getRetailerSpecificMetrics(retailer)
    ],
    groupByDimensionsList: [
      'sku',
      'sku_name',
      'ad_group_id',
      'advertiser_id',
      'campaign_id',
      'ad_group_content_id'
    ],
    dimensionList: [],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    where: {
      date: {
        from: ':date',
        to: ':date'
      },
      pvpDate: {
        from: ':date',
        to: ':date'
      }
    },
    limit: 30,
    page: 1
  };
};

export const tippyForRetailer = (retailer) => {
  return retailer === 'samsclub_api'
    ? ''
    : 'Note: Edit status is not available for SB campaigns';
};

const dropDownValueForRetailer = (retailer) => {
  return retailer === 'samsclub_api' ? 'disabled' : 'paused';
};

const isAdjustBidDisabled = (currentAction, selections, context) => {
  const dataArray = selections.map((item) => getDataFromRowNode(item));
  const isRetailerSamsclub =
    context?.$store?.getters?.getRetailer === 'samsclub_api';
  const isSBCampaign = selections.some((selection) =>
    SB_CAMPAIGN_TYPES.includes(selection?.data?.campaign_type?.toLowerCase())
  );
  const isDisabled =
    (isRetailerSamsclub && isSBCampaign) ||
    dataArray.some((item) => item.targeting_type === 'manual');

  let tippyMsg = '';

  if (isDisabled) {
    if (isRetailerSamsclub && isSBCampaign) {
      tippyMsg = 'Adjust bid not available for SB campaigns';
    } else {
      tippyMsg = 'Adjust bid not available for manual campaigns';
    }
  }

  currentAction.tippy = tippyMsg;
  currentAction.disabled = isDisabled;
  return currentAction;
};

export default (context, tab, retailer = 'walmart') => {
  const namespace = 'campaignManagement/';
  const getActionUpdater = (currentAction, selections) => {
    const retailer = context.$store?.getters?.getRetailer;

    if (retailer === 'samsclub_api') {
      // Enable the action for all campaign types if the retailer is 'samsclub_api'
      currentAction.disabled = false;
    } else {
      // Enable the action for campaign types other than 'sb' or 'sba'
      const hasSB = selections.some((selection) =>
        SB_CAMPAIGN_TYPES.includes(
          selection?.data?.campaign_type?.toLowerCase?.()
        )
      );

      currentAction.disabled = hasSB;
    }

    return currentAction;
  };

  const handleEditStatusAction = async (
    dropDownSelection,
    retailer,
    selections
  ) => {
    const hasSB = selections.some((item) =>
      SB_CAMPAIGN_TYPES.includes(item.data?.campaign_type?.toLowerCase())
    );

    const dataArray = selections
      .map((item) => getDataFromRowNode(item))
      .filter((data) => {
        const campaignType = data?.campaign_type?.toLowerCase();
        const isSamsclubApi = retailer === 'samsclub_api';
        const allowAllCampaignTypes = isSamsclubApi;
        const allowOtherCampaignTypes =
          !isSamsclubApi && !SB_CAMPAIGN_TYPES.includes(campaignType);

        return allowAllCampaignTypes || allowOtherCampaignTypes;
      });

    const dynamicKeys = {
      primaryKey: PRIMARY_KEY,
      previousEntityValue: 'status',
      actionPayloadKeys: skusEditStatusActionPayloadKeys(),
      viewPayloadKeys: skusEditStatusActionPayloadKeys()
    };

    const staticValues = {
      widget: WIDGET,
      actionType: `${retailer}ProductAdStatusChange`,
      actionSource: {
        pageUrl: window.location.href
      }
    };

    const newValuesObject = {
      newEntityValue: dropDownSelection[0].value,
      actionPayload: {
        status: dropDownSelection[0].value
      },
      viewPayload: {
        status: dropDownSelection[0].value
      }
    };

    const payloadArray = dataArray.map((item) =>
      payloadGenerator(dynamicKeys, staticValues, newValuesObject, item)
    );

    openSnackbarIfHasSB(hasSB, context);

    context.openSnackbar(
      'Status change request has been submitted for the selected Ad SKU(s)'
    );

    const response = await HttpService.post(
      'AMS_ACTIONS_ACTION_LOG',
      payloadArray
    );

    fetchRealtimeTableData(
      'blueBarAction',
      'walmart',
      {
        rows: {
          tableRow: context.config.widget2.table.props.gridOptions.rowData
        }
      },
      'campaignManagement/',
      'skus',
      context
    );
    context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
    return response;
  };

  return {
    namespace,
    bookmark: {
      title: 'Ad SKUs'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'campaigns_sku.max_feed_date',
        min: 'campaigns_sku.min_feed_date'
      },
      api: {
        cube: `${retailer}_campaigns_skus_workbench`,
        endPoint: 'FETCH_FILTERS_V2',
        page: `${retailer}_skus`
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'skus-filter',
      page: `${retailer}_skus`,
      listenerEvent:
        retailer === 'walmart' ? 'skus-filters' : `${retailer}-skus-filter`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          placeholder: 'Search for a SKU',
          emit: 'search-submit-skus',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-skus-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },

      // change name to chart widget and table widget
      api: {
        request: getChartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          enableWatefall: true,
          chartConfig: baseChartConfig(),
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      messageBar: {
        pageType: 'Ad SKUs'
      },
      activityBar: {
        pageType: 'Ad SKU'
      },
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          {
            icon: 'pencil',
            title: 'Edit Status',
            id: 'edit-status',
            dropDown: true,
            tippy: tippyForRetailer(retailer),
            dropDownOptions: [
              { title: 'Enable', value: 'enabled' },
              {
                title: 'Disable',
                value: dropDownValueForRetailer(retailer)
              }
            ],
            actionUpdater: getActionUpdater,
            handler(selectionLength, dropDownSelection, selections) {
              return handleEditStatusAction(
                dropDownSelection,
                retailer,
                selections
              );
            }
          },
          {
            icon: 'dollar',
            title: 'Adjust Bid',
            id: 'adjust-bids',
            actionUpdater: (currentAction, selections) =>
              isAdjustBidDisabled(currentAction, selections, context),
            customState: {
              component: 'BidChangePanel',
              props: {
                title: 'Adjust Bid',
                actionPanelType: 'adskus',
                apiVersion: 'actionlog',
                retailer: context.$store.getters.getRetailer,
                widget: WIDGET,
                primaryKey: PRIMARY_KEY
              },
              events: {
                afterAPIActionToCall(payload) {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.removeCustomCustomComponents();
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.closeActionsWorkflow();
                  fetchRealtimeTableData(
                    'blueBarAction',
                    'walmart',
                    {
                      rows: {
                        tableRow:
                          context.config.widget2.table.props.gridOptions.rowData
                      }
                    },
                    'campaignManagement/',
                    'skus',
                    context
                  );
                },
                closePanelMethod: () => {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.removeCustomCustomComponents();
                }
              }
            }
          }
        ]
      },
      headerOptions: commonTableActionIcons(),
      api: {
        request: getTableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'ad_skus'
      },
      metrics: metricsDictionary(tab, 'table', 'walmart').allMetrics,
      table: {
        props: {
          gridOptions: {
            ...gridOptions(),
            isRowSelectable: (rowNode) => {
              const isSelectable = rowNode?.data?.sku !== '1001';
              return isSelectable;
            },
            getRowStyle: (rowNode) => {
              const isSelectable = rowNode.data?.sku !== '1001';
              if (!isSelectable) {
                return { cursor: 'not-allowed' };
              }
              return {};
            }
          },
          rowHeight: 80,
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        }
      }
    },
    containerClass: 'walmart-skus',
    ...systemTransparencyBannerConfig
  };
};
