<template>
  <section
    class="addAsinsPanel actionsPanel u-bg-color-grey-white u-position-relative"
  >
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="tableLoader || asinsLoading"
      :color="'#007cf6'"
    />
    <section
      :class="{
        visibilityHidden: tableLoader || asinsLoading
      }"
      class="containerVisibilty"
    >
      <header
        class="title u-font-size-5 u-font-weight-600 u-spacing-pt-l u-spacing-ph-l u-text-case-upper"
      >
        Add SKUs ({{ selections.length }} Line Item{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </header>

      <div class="actionsPanelBody u-display-flex u-spacing-p-l">
        <div class="u-width-60 tableContainer">
          <div
            class="tableHeading u-spacing-ph-l u-spacing-pv-m u-font-weight-600 u-font-size-5"
          >
            Add SKUs
          </div>
          <div
            class="u-spacing-p-l u-width-100"
            style="box-shadow: none"
          >
            <div
              class="u-position-relative u-display-flex u-bg-color-grey-white"
            >
              <rb-input
                v-model="asinSearchText"
                :placeholder="$t('search_placeholder_skus')"
                class="search__input u-width-100 u-border-left-none u-bg-color-grey-white"
                @input="localSearch"
              />
              <span class="icon-right u-position-absolute u-display-flex">
                <rb-icon
                  icon="search"
                  class="search__input-icon rb-icon--medium"
                />
              </span>
            </div>
          </div>
          <div class="table-left u-width-100 u-position-relative">
            <div
              v-if="campaignAsinsData.rows.length === 0"
              class="fill--parent message"
            >
              No asin(s) found for selected Campaign
            </div>
            <div class="u-height-100 u-spacing-mb-m">
              <rb-insights-table
                v-if="campaignAsinsData.rows.length > 0"
                :config="tableConfigsLeft"
                :grid-options="gridOptions"
                :auto-configure="campaignAsinsData"
                style="width: 100%"
                :row-height="66"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="false"
                :pagination="true"
                :client-side-pagination="true"
                :pagination-total-key="campaignAsinsData.rows.length"
                :pagination-per-page-limit="25"
                :download-this-table="false"
                :get-current-instance="getCurrentSourceInstance"
                :row-class-rules="rowClassRules"
                :row-style="rowStyle"
                :show-pagination-options="false"
                :row-no-hover-state="true"
              />
            </div>
          </div>
        </div>
        <div class="u-width-60 tableContainer u-spacing-ml-l">
          <div
            class="tableHeading u-spacing-ph-l u-spacing-pv-m u-font-weight-600 u-font-size-5"
          >
            Added SKUs ({{ getAddedAsinsTableData.rows.length }})
          </div>

          <div class="table-right u-width-100 u-position-relative">
            <div
              v-if="getAddedAsinsTableData.rows.length === 0"
              class="fill--parent message"
            >
              No asin(s) added
            </div>
            <rb-insights-table
              v-else
              :config="tableConfigsRight"
              :grid-options="gridOptions2"
              :auto-configure="getAddedAsinsTableData"
              style="width: 100%"
              :row-height="66"
              :client-side-pagination="true"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :pagination-total-key="getAddedAsinsTableData.rows.length"
              :pagination-per-page-limit="25"
              :download-this-table="false"
              :row-style="rowStyle"
              :get-current-instance="getCurrentResultInstance"
              :row-no-hover-state="true"
            />
          </div>
        </div>
      </div>

      <footer
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
      >
        <rb-button
          :disabled="!tableValid"
          :click-fn="addAsinsToCampaign"
          :text="'Apply'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s"
        />
        <rb-button
          :click-fn="closePanel"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
          class="u-spacing-mr-s"
        />
        <div
          v-if="showValueError"
          class="u-spacing-ph-xs u-spacing-pt-xs u-flex-0 u-font-size-7 u-color-red-base"
        >
          {{ errorMessage }}
        </div>
      </footer>
    </section>
  </section>
</template>

<style lang="css">
.containerVisibilty {
  visibility: unset;
}
.visibilityHidden {
  visibility: hidden;
}

.tableContainer {
  box-shadow: 0 0 4px 0 #caccce;
}
.tableContainer .table-left {
  height: 520px !important;
}
.tableContainer .table-right {
  height: 612px !important;
}
.tableContainer .ag-pivot-on .ag-header-group-cell {
  font-size: 25px;
  color: green;
}
.tableContainer .tableHeading {
  border-bottom: 1px solid #e9eaeb;
}
.tableContainer .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.tableContainer .search__input__icon {
  right: 35px !important;
  top: 35px;
}
.tableContainer .ag-theme-material .ag-row {
  border-style: none;
}
.tableContainer .ag-theme-material .ag-row:last-child {
  border-style: solid;
  border-top: none;
}
.tableContainer .message {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #8b8f93;
}

.disabledTableRow .ag-cell:first-child div {
  opacity: 0.8;
}
.removePointerEvents {
  pointer-events: none;
}

.addAsinsPanel .ag-row.ag-row-level-0 div:nth-child(2) .cell--content,
.addAsinsPanel
  .ag-row.ag-row-first.ag-row-last
  div:nth-child(3)
  .cell--content {
  text-transform: capitalize;
}
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
.fillMessage {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import loader from '@/components/basic/loader';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import transformer from '@/utils/services/data-transformer';
import { commons } from '@/components/ams/campaigns/commons.js';
import config from '@/pages/campaign-management/retailers/criteo/line_items/AddProducts/AddProducts.js';

import HttpService from '@/utils/services/http-service';
import { getBaseCubeName } from '@/pages/campaign-management/retailers/criteo/utils';
export default {
  name: 'AddProducts',
  components: {
    loader,
    messageBar
  },
  props: {
    actionToCall: {
      type: Function,
      default: function () {}
    },
    widgetsConfigRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      config: null,
      applyClicked: false,
      gridOptions2: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      showValueError: false,
      errorMessage: '',
      asinsLoading: false,
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      tableConfigsRight: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      responseTableData: [],
      responseTableMetadata: [],
      resultTableData: [],
      currentArrowContext: '',
      resultTableMetadata: [],
      rbTableSourceInstance: null,
      rbTableResultInstance: null,
      tableValid: false,
      asinSearchText: '',
      rows: []
    };
  },
  computed: {
    campaignAsinsData() {
      let rows = [];
      rows = this.rows.filter((data) => {
        if (
          Object.values(data)
            .toString()
            .toLowerCase()
            .includes(this.asinSearchText.toLowerCase())
        ) {
          return data;
        }
      });
      return {
        rows: rows,
        columns: this.responseTableMetadata
      };
    },
    campaignAsinRows() {
      if (this.selections.length === 0) {
        return [];
      }
      const data = this.$store.getters.getCampaignsAsins;
      const selection = this.selections[0].data.campaign_id;
      let rows = [];
      if (data && data[selection]) {
        rows = data[selection];
      }
      return rows;
    },

    rowStyle() {
      return { borderBottom: '1px solid lightgray' };
    },

    rowClassRules() {
      const that = this;
      return {
        'disabledTableRow  ag-cell:first-child removePointerEvents': function (
          params
        ) {
          let val = false;
          that.resultTableData.forEach((row) => {
            if (row.product_id === params.data.product_id) {
              val = true;
            }
          });
          return val;
        }
      };
    },

    tableLoader() {
      return this.$store.getters.getCampaignWorkspaceTableLoader;
    },

    getAddedAsinsTableData() {
      return {
        columns: this.resultTableMetadata,
        rows: this.resultTableData
      };
    }
  },
  watch: {
    rbTableSourceInstance() {
      this.isRowSelected();
    },
    resultTableData(newVal) {
      if (this.resultTableData.length === 0) {
        this.tableValid = false;
      } else {
        this.tableValid = true;
      }
      this.isRowSelected();
      if (this.rbTableResultInstance !== null) {
        this.rbTableResultInstance.redrawTableRows();
      }
    },
    campaignAsinRows(newVal) {
      this.rows = newVal;
    },
    selections: {
      handler: function (newValue, oldValue) {
        if (newValue.length === 0) {
          this.closePanel();
          return;
        }
        this.getSKUsForCampaign();
      }
    }
  },
  created() {
    this.config = config[this.retailer](this);
    this.responseTableMetadata = this.config.tableConfigs.metadata.sourceTable;
    this.resultTableMetadata = this.config.tableConfigs.metadata.resultTable;
    this.getSKUsForCampaign();
  },
  beforeDestroy() {
    this.closePanel();
  },
  methods: {
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    localSearch(input) {
      this.asinSearchText = input;
    },
    addAllAsinsToTable() {
      const currentItems = this.rbTableSourceInstance.rowData;
      const newRows = [];
      currentItems.forEach((res) => {
        if (
          !this.checkRowExistsInCollection(
            res,
            'product_id',
            this.resultTableData
          )
        ) {
          newRows.push(res);
        }
      });
      this.resultTableData = [...this.resultTableData, ...newRows];
    },
    deleteAllAsinsFromTable() {
      this.resultTableData = [];
    },

    getSKUsForCampaign() {
      let payload = {};
      const that = this;
      payload = this.getPayload();
      this.asinsLoading = true;
      HttpService.post('EXECUTE_CUBE_API', payload)
        .then((response) => {
          this.asinsLoading = false;
          that.rows = transformer.mergeResultDimension(
            response.data.response.data,
            true
          );
        })
        .catch((error) => console.log(error));
    },
    getPayload() {
      const dimensionNameValueList = [];
      const retailer = this.retailer;
      this.selections.forEach((item) => {
        const dimensionNamesValue = {
          dimensionName: 'line_item_id',
          operator: 'NOT_LIKE',
          dimensionValue: item?.data?.line_item_id
        };
        dimensionNameValueList.push(dimensionNamesValue);
      });
      dimensionNameValueList.push({
        dimensionName: 'line_item_id',
        operator: 'IS_NULL',
        dimensionValue: ''
      });
      let cubeName = `${getBaseCubeName(retailer)}_latest_line_item_product`;
      const payload = {
        cubeName,
        measuresList: [
          'product_id',
          'name',
          'gtin',
          'category',
          'brand_name',
          'retailer_name',
          'price',
          'image_url',
          'type',
          'bid_override',
          'line_item_id'
        ],
        groupByDimensionsList: ['product_id', 'line_item_id'],
        where: {
          dimensionNameValueList: dimensionNameValueList
        }
      };
      return payload;
    },

    // To get the context of the two tables
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },

    getCurrentResultInstance(context) {
      this.rbTableResultInstance = context;
    },

    // Adding a asin
    addToSelection(context, event) {
      this.resultTableData.push(context.params.data);
    },

    // removing a keywod from the table
    removeFromSelection(context, event) {
      this.resultTableData = this.resultTableData.filter((result) => {
        if (context.params.data.product_id !== result.product_id) {
          return result;
        }
      });
    },

    createPayload() {
      let payload = [];
      for (const selection of this.selections) {
        const tempPayload = {
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          widget: this.widget,
          primaryKey: selection?.data?.[this.primaryKey]
        };
        tempPayload.actionPayload = {
          line_item_id: selection?.data?.line_item_id,
          actionType: this.actionType,
          products: this.resultTableData.map((skuItem) => {
            return {
              promoted_product_id: skuItem?.product_id
            };
          })
        };
        tempPayload.viewPayload = {
          spreadViewPayload: true,
          viewPayloadArray: this.resultTableData.map((skuItem) => {
            return {
              promoted_product_id: skuItem?.product_id,
              image_url: skuItem?.image_url,
              name: skuItem?.name,
              line_item_id: selection?.data?.line_item_id,
              actionType: this.actionType
            };
          })
        };
        payload.push(tempPayload);
      }
      return payload;
    },

    addAsinsToCampaign() {
      let that = this;
      let payload = this.createPayload();
      let othersObj = {
        loader: 'asinsLoading',
        successMessage:
          'Request to add asin(s) for the selected campaign has been submitted ',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closePanel();
        }
      };
      commons.bidChangeApiAction.call(
        this,
        this.selections,
        payload,
        othersObj,
        this.apiVersion
      );
    },

    closePanel() {
      this.parentRef.showAddAsinsPanel = false;
      this.parentRef.closeActionsWorkflow();
      this.resultTableData = [];
      this.$store.dispatch('resetCampaignAsins');
    },
    checkRowExistsInCollection(row, key, table) {
      let val = false;
      table.forEach((item) => {
        if (item[key] === row[key]) {
          val = true;
          return val;
        }
      });
      return val;
    },
    isRowSelected() {
      const rows = [];
      this.rows.forEach((row) => {
        if (this.resultTableData.length > 0) {
          if (
            this.checkRowExistsInCollection(
              row,
              'product_id',
              this.resultTableData
            )
          ) {
            row.isRowSelected = true;
            rows.push(row);
          } else {
            row.isRowSelected = false;
            rows.push(row);
          }
        } else {
          row.isRowSelected = false;
          rows.push(row);
        }
      });
      this.rows = rows;
    }
  }
};
</script>
