<template>
  <div class="chargeback-dashboard">
    <dashboard-inflator
      :key="keyValue"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      :selected-display-in="selectedDisplayIn || {}"
      :show-header-options="showHeaderOptions"
      :show-active-filter-banner="false"
      :show-back-button="showBackButton"
      :back-event-listener="'backButtonEvent'"
      @requestParamsChange="requestParamsChange"
      @updateUIOnly="updateUIOnlyHandler"
      @backButtonEvent="backButtonEvent"
    />
  </div>
</template>
<script>
import utils from '@/utils/helpers/';
import shortage_chargeback_overview from '@/components/widgets/custom_widgets/custom_widget_components/charts/dual-charting-wb.vue';
import shortage_chargeback_summary from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import shortage_bar_chargeback_overview from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import top_contributors_by_claims from '../shortage-invoices/TopContributors.vue';
import Vue from 'vue';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import shortage_total_overview_chargeback from '@/components/widgets/custom_widgets/custom_widget_components/cards/GenericCardWidgetWrapper.vue';
import {
  getSecondaryType,
  getMetricData
} from '@/components/widgets/custom_widgets/custom_widget_components/cards/utility.js';
import RbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import RbCheckboxGroup from '@/components/basic/rbCheckboxGroup.vue';
import RbTabs from '@/components/basic/rbTabs.vue';
import RbSplitTabs from '@/components/widgets/splitTabs.vue';
import iconText from '@/components/basic/iconText.vue';
import ToggleChartVisibility from '@/components/widgets/custom_widgets/custom_widget_components/charts/ToggleChartVisibility.vue';
import { isEqual } from 'lodash';
export default {
  components: {
    shortage_chargeback_summary,
    shortage_chargeback_overview,
    top_contributors_by_claims,
    shortage_bar_chargeback_overview,
    ToggleChartVisibility
  },
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      // id: 40,
      name: 'ChargebackCasesReport',
      title: 'Chargebacks'
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      isCardCollapsed: false,
      columnCellMapping: {},
      keyValue: 0,
      selectedFilters: [],
      selectedDisplayIn: {},
      showHeaderOptions: true,
      barOverviewShowChart: false,
      headerOptions: () => {
        return {
          shortage_chargeback_overview: {
            props: {
              'data-cy': 'shortage_chargeback_overview',
              headerOptions: [],
              enableRollup: true,
              hideZero: false,
              enableDownload: true,
              iconForDownload: 'share',
              enableDisplayIn: false,
              selectedMetricLimit: 2,
              minimumMetric: 5,
              isTooltipEnabled: true,
              removeDropdown: false,
              tooltipText: 'Export',
              disableAddNewMetricButton: true,
              isMetricToolTipEnabled: true,
              modifyTooltipMessage: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto auto'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 380 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: null
              }
            }
          },
          shortage_chargeback_cases_overview: {
            props: {
              headerOptions: [],
              enableRollup: true,
              enableDownload: true,
              iconForDownload: 'share',
              enableDisplayIn: false,
              selectedMetricLimit: 2,
              hideZero: false,
              minimumMetric: 5,
              tooltipText: 'Export',
              removeDropdown: false,
              isTooltipEnabled: true,
              disableAddNewMetricButton: true,
              isMetricToolTipEnabled: true,
              modifyTooltipMessage: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto auto'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 380 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: null
              }
            }
          },
          shortage_chargeback_summary: {
            props: {
              enableDownload: true,
              iconForDownload: 'share',
              tooltipText: 'Export',
              isMetricToolTipEnabled: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              searchParams: {
                show: true,
                placeholder: 'Search By SKU, PO, TransactionID',
                searchDimension: 'local_search'
              }
            }
          },
          shortage_chargeback_summary_cases: {
            props: {
              enableDownload: true,
              iconForDownload: 'share',
              tooltipText: 'Export',
              isMetricToolTipEnabled: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              searchParams: {
                show: true,
                placeholder: 'Search By SKU, PO, TransactionID',
                searchDimension: 'local_search'
              }
            }
          },
          top_contributors_by_claims: {
            props: {
              'data-cy': 'top_contributors_by_claims',
              enableDownload: false,
              iconForDownload: 'share',
              headerContainerGridStyles: {
                'grid-template-columns': '1fr',
                'grid-template-rows': '1fr ',
                'grid-gap': '1 rem'
              },
              searchParams: {
                show: false
              }
            }
          },
          top_contributors_by_claims_cases: {
            props: {
              enableDownload: false,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr',
                'grid-template-rows': '1fr ',
                'grid-gap': '1 rem'
              },
              searchParams: {
                show: false
              }
            }
          },
          shortage_total_overview_chargeback: {
            props: {
              'data-cy': 'shortage_total_overview_chargeback',
              widgetRequestParams: this.getWidgetRequestParams(
                'shortage_total_overview_chargeback'
              ),
              globalParams: this.getGlobalRequestParams(),
              isHorizontallyScrollable: true,
              defaultClass: 'bg-red',
              isCardCollapsed: this.isCardCollapsed,
              cardWidgetTitle: 'Chargeback Types',
              shouldSortMetric: true,
              widgetsArrayToReload: [
                'shortage_chargeback_overview',
                'shortage_chargeback_cases_overview',
                'shortage_chargeback_summary',
                'shortage_chargeback_summary_cases',
                'top_contributors_by_claims',
                'top_contributors_by_claims_cases'
              ],
              initialViewCardCount: 4,
              isMultiDashboard: true,
              isTooltipEnabled: true,
              isMetricToolTipEnabled: true,
              tooltipText: 'Download',
              cardMetricFooterData: this.cardMetricFooterData,
              tagFooterConfig: {
                tag1Unit: {
                  pre: '',
                  roundoff: '',
                  suff:
                    this.selectedDisplayIn.po.value === 'No of POs'
                      ? 'currency'
                      : ''
                },
                tag2Unit: {
                  suff: '%'
                },
                invertTag2: true
              }
            }
          },
          shortage_total_overview_cases_chargeback: {
            props: {
              widgetRequestParams: this.getWidgetRequestParams(
                'shortage_total_overview_cases_chargeback'
              ),
              globalParams: this.getGlobalRequestParams(),
              defaultClass: 'bg-red',
              isCardCollapsed: this.isCardCollapsed,
              isHorizontallyScrollable: true,
              tooltipText: 'Download',
              widgetsArrayToReload: [
                'shortage_chargeback_overview',
                'shortage_chargeback_cases_overview',
                'shortage_chargeback_summary',
                'shortage_chargeback_summary_cases',
                'top_contributors_by_claims',
                'top_contributors_by_claims_cases'
              ],
              cardWidgetTitle: 'Chargeback Types',
              shouldSortMetric: true,
              initialViewCardCount: 4,
              isMultiDashboard: true,
              isMetricToolTipEnabled: true,
              isTooltipEnabled: true,
              cardMetricFooterData: this.cardMetricFooterData,
              tagFooterConfig: {
                tag1Unit: {
                  pre: '',
                  roundoff: '',
                  suff:
                    this.selectedDisplayIn.po.value === 'No of POs'
                      ? 'currency'
                      : ''
                },
                tag2Unit: {
                  suff: '%'
                },
                invertTag2: true
              }
            }
          },
          shortage_bar_overview_cases_chargeback: {
            props: {
              'data-cy': 'shortage_bar_overview_cases_chargeback',
              headerOptions: [
                {
                  ui_component: 'ToggleChartVisibility',
                  props: {
                    showChart: this.barOverviewShowChart,
                    iconShow: 'show-chart',
                    iconHide: 'collapse'
                  },
                  order: 150,
                  events: {
                    toggleChartVisibilty: this.toggleChartVisibilty
                  }
                }
              ],
              showChart: this.barOverviewShowChart,
              enableRollup: false,
              tooltipText: 'Export',
              enableDownload: true,
              iconForDownload: 'share',
              modifyTooltipMessage: true,
              isMetricToolTipEnabled: true,
              showLegendsAsChips: true,
              selectedMetricLimit: 2,
              metricFontSize: 'u-font-size-6',
              minimumMetric: 4,
              enableWaterfall: false,
              removeDropdown: true,
              disableAddNewMetricButton: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto',
                'grid-column-gap': '1.5rem'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 200 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: false
              },
              colorObject: {
                Charged: '#FFA800',
                'Dispute Approved': '#97CC04',
                Disputed: '#BD10E0',
                Impact: 'red'
              }
            }
          },
          shortage_bar_overview_chargeback: {
            props: {
              'data-cy': 'shortage_bar_overview_chargeback',
              headerOptions: [
                {
                  ui_component: 'ToggleChartVisibility',
                  props: {
                    showChart: this.barOverviewShowChart,
                    iconShow: 'show-chart',
                    iconHide: 'collapse'
                  },
                  order: 150,
                  events: {
                    toggleChartVisibilty: this.toggleChartVisibilty
                  }
                }
              ],
              enableRollup: false,
              showChart: this.barOverviewShowChart,
              enableDownload: true,
              iconForDownload: 'share',
              enableWaterfall: false,
              tooltipText: 'Export',
              isMetricToolTipEnabled: true,
              metricFontSize: 'u-font-size-6',
              showLegendsAsChips: true,
              selectedMetricLimit: 2,
              minimumMetric: 4,
              modifyTooltipMessage: true,
              removeDropdown: true,
              disableAddNewMetricButton: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto',
                'grid-column-gap': '1.5rem'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 200 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: false
              },
              colorObject: {
                Charged: '#FFA800',
                'Dispute Approved': '#97CC04',
                Disputed: '#BD10E0',
                Impact: 'red'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name,
          ':dimensionNameValueList': []
        },
        shortage_chargeback_overview: {
          ':widget': 'shortage_chargeback_overview',
          ':timeseriesRollupBy': 'WEEK',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        shortage_chargeback_cases_overview: {
          ':widget': 'shortage_chargeback_cases_overview',
          ':timeseriesRollupBy': 'WEEK',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        shortage_chargeback_summary: {
          ':widget': 'shortage_chargeback_summary',
          ':entityType': 'issue_id_sort'
        },
        shortage_chargeback_summary_cases: {
          ':widget': 'shortage_chargeback_summary_cases',
          ':entityType': 'issue_id_sort'
        },
        top_contributors_by_claims: {
          ':widget': 'top_contributors_by_claims',
          ':entityType': 'asin',
          ':orderByList': [
            {
              dimension: 'charged',
              direction: 'DESC'
            }
          ]
        },
        top_contributors_by_claims_cases: {
          ':widget': 'top_contributors_by_claims_cases',
          ':entityType': 'asin',
          ':orderByList': [
            {
              dimension: 'charged_cases',
              direction: 'DESC'
            }
          ]
        },
        shortage_total_overview_chargeback: {
          ':widget': 'shortage_total_overview_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        shortage_total_overview_cases_chargeback: {
          ':widget': 'shortage_total_overview_cases_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        shortage_bar_overview_cases_chargeback: {
          ':widget': 'shortage_bar_overview_cases_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':timeseriesRollupBy': 'WEEK'
        },
        shortage_bar_overview_chargeback: {
          ':widget': 'shortage_bar_overview_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':timeseriesRollupBy': 'WEEK'
        }
      },
      filterSettings: {
        emitEvent: 'shortageInvoiceFilterApply',
        uiKey: 0,
        show: true,
        showFilterBar: true,
        backendCube: 'shortage_invoice_dashboard',
        defaultDatePostRange: 'last90Days',
        isUpdateKey: 'ChargeBackUiUpdateOnly',
        enableSave: false,
        listenerEvent: 'shortage-invoice-filter',
        filterBar: [
          {
            title: 'Display in',
            key: 'po',
            isUiUpdateOnly: true,
            addInFilter: false,
            selectType: 'radio',
            init: false,
            multiSelect: false,
            options: [
              {
                value: this.currencyString(),
                label: this.currencyString()
              },
              {
                value: 'No of POs',
                label: 'No of POs'
              }
            ],
            originalSelections: [
              {
                value: this.currencyString(),
                label: this.currencyString()
              }
            ]
          }
        ],
        search: {
          enable: true,
          placeholder: 'Search for a SKU or PO',
          width: '240px',
          searchUniqKey: 'searchSubmit'
        },
        enableExtraHeaderPanel: true,
        filterV2: true
      },
      metricGlobalParamMapping: {
        overall_content_score_per: '',
        title_len: ':title_len',
        title_contains_brand_metric: ':title_conatins_brand',
        desc_contains_productname_metric: ':desc_contains_product',
        desc_len: ':desc_len',
        no_of_key_features: ':bullet_points',
        no_of_reviews: ':reviews',
        no_of_images: ':images',
        avg_rating: ':avg_rating',
        desc_contains_keyword_metric: ':product_desc_contains_keyword',
        product_title_contains_keyword_metric: ':product_title_contains_keyword'
      },
      widgetMetadata: []
    };
  },
  computed: {
    showBackButton() {
      if (utils.internalUserCheck(window.user))
        return (
          Vue.options.filters.config_check(
            'feature.insights.chargeback_analytics.internalRelease'
          ) ||
          Vue.options.filters.config_check(
            'feature.insights.chargeback_analytics'
          )
        );
      return Vue.options.filters.config_check(
        'feature.insights.chargeback_analytics'
      );
    }
  },
  watch: {
    requestParams(newVal) {},
    selectedDisplayIn(newVal) {
      this.selectedDisplayIn = newVal;
    }
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component(
      'shortage_bar_overview_chargeback',
      shortage_bar_chargeback_overview
    );
    Vue.component(
      'shortage_bar_overview_cases_chargeback',
      shortage_bar_chargeback_overview
    );
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('shortage_chargeback_overview', shortage_chargeback_overview);
    Vue.component(
      'shortage_chargeback_cases_overview',
      shortage_chargeback_overview
    );
    Vue.component('shortage_chargeback_summary', shortage_chargeback_summary);
    Vue.component(
      'shortage_chargeback_summary_cases',
      shortage_chargeback_summary
    );
    Vue.component('top_contributors_by_claims', top_contributors_by_claims);
    Vue.component(
      'top_contributors_by_claims_cases',
      top_contributors_by_claims
    );
    Vue.component(
      'shortage_total_overview_chargeback',
      shortage_total_overview_chargeback
    );
    Vue.component(
      'shortage_total_overview_cases_chargeback',
      shortage_total_overview_chargeback
    );
    Vue.component('RbRadioGroup', RbRadioGroup);
    Vue.component('RbCheckboxGroup', RbCheckboxGroup);
    Vue.component('rbTabsRounded', RbTabs);
    Vue.component('rbSplitTabs', RbSplitTabs);
    Vue.component('icon-text', iconText);
    Vue.component('ToggleChartVisibility', ToggleChartVisibility);

    const selected = JSON.parse(
      this.$ciqlocalStorage.getItem('ChargeBackUiUpdateOnly')
    );
    if (selected === null) {
      this.$ciqlocalStorage.setItem(
        'ChargeBackUiUpdateOnly',
        JSON.stringify({
          po: {
            value: this.currencyString(),
            label: this.currencyString()
          }
        })
      );
    }
    this.selectedDisplayIn = JSON.parse(
      this.$ciqlocalStorage.getItem('ChargeBackUiUpdateOnly')
    );
    const tempObj = this.mofidiedPageInfo(this.selectedDisplayIn.po);
    this.pageInfo = { ...tempObj };
    const global = this.getGlobalRequestParams();

    const globalParams = { ...global, ...tempObj };
    if (!isEqual(global, globalParams)) {
      this.updateGlobalRequestParams(globalParams);
    }

    setTimeout(() => {
      const scrollDemo = document.getElementById('pageBody');
      scrollDemo.addEventListener(
        'scroll',
        () => {
          this.handleScroll(scrollDemo.scrollTop);
        },
        { passive: true }
      );
    }, 1000);
  },
  beforeDestroy() {
    document.getElementById('pageBody').removeEventListener('scroll');
  },
  methods: {
    getPageFiltersMetadata() {},
    handleScroll(value) {
      const scrollLimit = this.barOverviewShowChart ? 350 : 135;
      if (value > scrollLimit) {
        const stickyElm = document.querySelector(
          '.chargeback-dashboard > div > span:nth-child(3)'
        );
        stickyElm?.classList.add('is-sticky');
        this.isCardCollapsed = true;
      } else {
        const stickyElm = document.querySelector(
          '.chargeback-dashboard > div > span:nth-child(3)'
        );
        stickyElm?.classList.remove('is-sticky');
        this.isCardCollapsed = false;
      }
    },
    cardMetricFooterData(selCard, metricData) {
      let tempObj = {};
      const secondaryType = getSecondaryType(selCard);
      if (!(this.selectedDisplayIn.po.value === 'No of POs')) {
        tempObj = getMetricData(secondaryType, metricData, '_case');
      } else {
        tempObj = getMetricData(secondaryType, metricData, '_dollar');
      }
      return tempObj;
    },
    PerformanceOverviewWidgetDownloadTriggered() {
      try {
        this.dataService.downloadChartingData(
          this.requestParams?.value?.PerformanceOverviewWidget,
          this.requestParams?.value
        );
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    updateColumnMapping() {
      this.columnCellMapping = {};
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
      this.updateColumnMapping();
    },
    getselectedDisplay(selectedDisplay) {
      return selectedDisplay;
    },
    mofidiedPageInfo(selectedDisplay) {
      return selectedDisplay.value === this.currencyString()
        ? {
            name: 'ChargebackReport',
            title: 'Chargebacks',
            ':page': 'ChargebackReport',
            ':from': '2021-10-21',
            ':to': '2021-10-27'
          }
        : {
            name: 'ChargebackCasesReport',
            title: 'Chargebacks',
            ':page': 'ChargebackCasesReport',
            ':from': '2021-10-21',
            ':to': '2021-10-27'
          };
    },
    updateUIOnlyHandler(selectedFilter) {
      const [{ dimensionValue }] = selectedFilter;
      if (this.selectedDisplayIn.value !== dimensionValue) {
        this.$ciqlocalStorage.setItem(
          'ChargeBackUiUpdateOnly',
          JSON.stringify({
            po: { value: dimensionValue, label: dimensionValue }
          })
        );
        this.keyValue = this.keyValue + 1;
        this.selectedDisplayIn = {
          po: { value: dimensionValue, label: dimensionValue }
        };
        const tempObj = this.mofidiedPageInfo(this.selectedDisplayIn.po);
        this.pageInfo = { ...tempObj };
        const global = this.getGlobalRequestParams();
        const globalParams = { ...global, ...tempObj };
        if (!isEqual(global, globalParams)) {
          this.updateGlobalRequestParams(globalParams);
        }
      }
    },
    toggleChartVisibilty(val) {
      this.barOverviewShowChart = val;
    },
    currencyString() {
      const currencyLabel = Vue.options.filters.config_check(
        'feature.i18n.currencyLabel.singular'
      );
      return `${this.$currency} ${currencyLabel} Amount`;
    },
    backButtonEvent() {
      this.$router.push({
        name: 'chargeback_analytics'
      });
    }
  }
};
</script>
<style lang="css">
.chargeback-dashboard #tooltipTemplatecard {
  margin-top: 50px;
}
.chargeback-dashboard .inputClassForEntityTaggingSearch input {
  width: 240px !important;
}
.chargeback-dashboard .dashboard-service-table-wrapper {
  margin-top: 0px !important;
}
.chargeback-dashboard .rb-sku {
  width: 100% !important;
}
.chargeback-dashboard
  .shortage_chargeback_summary
  div[col-id^='purchase_order'].ag-cell,
.chargeback-dashboard
  .shortage_chargeback_summary_cases
  div[col-id^='purchase_order'].ag-cell {
  border-right: 1px solid #e9eaeb !important;
}
.chargeback-dashboard
  .shortage_chargeback_summary
  div[col-id^='purchase_order'][class='ag-header-cell ag-header-cell-sortable'],
.chargeback-dashboard
  .shortage_chargeback_summary_cases
  div[col-id^='purchase_order'][class='ag-header-cell ag-header-cell-sortable'] {
  border-right: 1px solid #e9eaeb !important;
}
.chargeback-dashboard .shortage_chargeback_summary .link--asin,
.chargeback-dashboard .shortage_chargeback_summary_cases .link--asin {
  color: #2b333b;
  border-bottom: 1px dotted;
}
.chargeback-dashboard .shortage_chargeback_summary .link--asin:hover,
.chargeback-dashboard .shortage_chargeback_summary_cases .link--asin:hover {
  color: #007cf6;
  text-decoration: none;
}
.chargeback-dashboard .table-header-tooltip .tooltip-message {
  white-space: pre-line !important;
}
.chargeback-dashboard
  .shortage_chargeback_cases_overview
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.chargeback-dashboard
  .shortage_chargeback_overview
  div[class='ag-header-cell ag-header-cell-sortable ag-column-hover'] {
  border-right: 1px solid #e9eaeb !important;
}
.chargeback-dashboard .shortage_chargeback_cases_overview .link--asin,
.chargeback-dashboard .shortage_chargeback_overview .link--asin {
  color: #2b333b;
  border-bottom: 1px dotted;
}
.chargeback-dashboard .shortage_chargeback_cases_overview .link--asin:hover,
.chargeback-dashboard .shortage_chargeback_overview .link--asin:hover {
  color: #007cf6;
  border-bottom: 1px dotted;
  text-decoration: none;
}
.chargeback-dashboard .shortage_chargeback_cases_overview .header-container-cwc,
.chargeback-dashboard .shortage_chargeback_overview .header-container-cwc {
  padding-bottom: 0;
}
.chargeback-dashboard .shortage_chargeback_cases_overview .rollUpWrapper,
.chargeback-dashboard .shortage_chargeback_overview .rollUpWrapper {
  margin-right: 1.6rem;
}
.chargeback-dashboard .shortage_bar_overview_cases_chargeback,
.chargeback-dashboard .shortage_bar_overview_chargeback {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .header-container-cwc,
.chargeback-dashboard .shortage_bar_overview_chargeback .header-container-cwc {
  padding: 1.5rem 24px 0px 24px;
}
.chargeback-dashboard .shortage_bar_overview_cases_chargeback .card__title,
.chargeback-dashboard .shortage_bar_overview_chargeback .card__title {
  font-size: 1.4rem;
  margin-top: 0 !important;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .metric-card-hover-trigger,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .metric-card-hover-trigger {
  margin: 1rem 0rem 0rem 0rem !important;
}
.chargeback-dashboard .shortage_bar_overview_cases_chargeback .chip-wrapper,
.chargeback-dashboard .shortage_bar_overview_chargeback .chip-wrapper {
  margin: 0rem 1.5rem 1.5rem 0rem !important;
  cursor: default;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .total_impact_dollar-metric
  .chip-wrapper,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .total_impact_dollar-metric
  .chip-wrapper,
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .total_impact_case-metric
  .chip-wrapper,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .total_impact_case-metric
  .chip-wrapper,
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .total_impact_dollar-metric
  .custom-chart-legend,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .total_impact_dollar-metric
  .custom-chart-legend,
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .total_impact_case-metric
  .custom-chart-legend,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .total_impact_case-metric
  .custom-chart-legend {
  background-color: #fbe9ec !important;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .chip-value
  .rb-metric--m,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .chip-value
  .rb-metric--m {
  align-items: center;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric {
  font-size: 14px;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon {
  margin: 0px 1px 0px 4px;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon:before,
.chargeback-dashboard
  .shortage_bar_overview_chargeback
  .chip-value
  .rb-metric--m
  .secondary_metric
  .rb-icon:before {
  font-size: 10px;
}
.chargeback-dashboard
  .shortage_bar_overview_cases_chargeback.charting_wb_wrapper
  .custom_widget_body,
.chargeback-dashboard
  .shortage_bar_overview_chargeback.charting_wb_wrapper
  .custom_widget_body {
  margin-bottom: 0;
}

.chargeback-dashboard .shortage_total_overview_cases_chargeback,
.chargeback-dashboard .shortage_total_overview_chargeback {
  position: relative !important;
  margin-bottom: 0rem !important;
  margin-right: 0rem;
  overflow: hidden;
}
.chargeback-dashboard .shortage_total_overview_cases_chargeback .card,
.chargeback-dashboard .shortage_total_overview_chargeback .card {
  margin-bottom: 0.4rem !important;
}
.chargeback-dashboard .shortage_total_overview_cases_chargeback .card-wrapper,
.chargeback-dashboard .shortage_total_overview_chargeback .card-wrapper,
.chargeback-dashboard .shortage_total_overview_cases_chargeback .card-header,
.chargeback-dashboard .shortage_total_overview_chargeback .card-header,
.chargeback-dashboard
  .shortage_total_overview_cases_chargeback
  .card-header-label,
.chargeback-dashboard .shortage_total_overview_chargeback .card-header-label {
  transition: 0.1s all ease;
}
.chargeback-dashboard
  .shortage_total_overview_cases_chargeback
  .card-header
  .card__title,
.chargeback-dashboard
  .shortage_total_overview_chargeback
  .card-header
  .card__title {
  text-transform: capitalize !important;
  max-width: 170px;
  display: inline-block;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.chargeback-dashboard
  .shortage_total_overview_cases_chargeback
  .metric-component
  .primary_metric,
.chargeback-dashboard
  .shortage_total_overview_chargeback
  .metric-component
  .primary_metric {
  font-weight: 700;
}
.chargeback-dashboard
  .shortage_total_overview_cases_chargeback
  .card-footer
  .rb-metric,
.chargeback-dashboard
  .shortage_total_overview_chargeback
  .card-footer
  .rb-metric {
  align-items: center;
}
.chargeback-dashboard > div {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
.chargeback-dashboard > div .filter-wrapper {
  width: 100%;
}
.chargeback-dashboard > div > span {
  width: 100%;
}
.chargeback-dashboard > div > span:nth-child(3) {
  position: sticky;
  top: 80px;
  align-self: center;
  display: block;
  z-index: 11;
  background-color: #f4f7fa;
}
.chargeback-dashboard .is-sticky .shortage_total_overview_cases_chargeback,
.chargeback-dashboard .is-sticky .shortage_total_overview_chargeback {
  margin-top: 62px;
}
.chargeback-dashboard .is-sticky .card-wrapper {
  display: flex;
  justify-content: center;
  padding: 0.9rem 1rem 0.9rem 1rem;
}
.chargeback-dashboard .is-sticky .card {
  min-height: 20px;
  margin-bottom: 1.5rem !important;
}
.chargeback-dashboard .is-sticky .card .card-wrapper {
  flex-grow: 0;
}
.chargeback-dashboard .is-sticky .u-spacing-mh-xs {
  margin-left: 0px;
}
.chargeback-dashboard .is-sticky .card-header {
  white-space: nowrap;
  padding: 0rem !important;
}
.chargeback-dashboard .is-sticky .card-header .card__title {
  font-size: 1.6rem;
  font-weight: 400 !important;
  max-width: 140px;
}
.chargeback-dashboard .is-sticky .card-header .card-header-label {
  padding-bottom: 0rem;
}
.chargeback-dashboard .is-sticky .card-footer {
  display: none;
}
.chargeback-dashboard .is-sticky .rb-metric {
  min-height: auto !important;
  flex-direction: row;
}
.chargeback-dashboard .is-sticky .rb-metric .primary_metric {
  font-size: 1.6rem;
  font-weight: 700;
}
</style>
