<template>
  <section
    class="u-display-flex u-flex-direction-column u-overflow-x-hidden u-height-100"
  >
    <div
      class="u-display-flex u-spacing-ph-l u-bg-color-grey-white u-spacing-pb-m u-header-box-shadow sticky u-z-index-1"
    >
      <PageTitle :title="'Campaign Optimizer'" />
    </div>
    <!-- header content -->

    <section class="u-envelop-width u-width-100">
      <!-- header content ends -->
      <!-- Objectives Section -->
      <section class="u-spacing-mb-xl">
        <section
          class="u-spacing-mh-m u-spacing-mv-l u-display-flex u-flex-justify-content-space-between u-spacing-mt-0"
        >
          <div class="">
            <h3 class="u-font-size-2 u-text-align-left u-padding-0">
              Objectives
            </h3>
            <p
              class="step-desc u-font-size-5 u-spacing-mt-s u-color-grey-lighter"
            >
              Lists of all objectives
            </p>
          </div>
          <div class="">
            <rb-button
              id="confirmButton"
              :icon-left="'draft'"
              :text="'View saved drafts'"
              :type="'hollow'"
              :size="'m'"
              :click-fn="savedDraft"
            />
          </div>
        </section>
        <Stategy-Carousel :list-of-card="listOfCard" />
      </section>
      <!-- Objectives Section ends -->
      <!-- Strategies Section Start -->
      <section class="strategies u-spacing-mh-m u-spacing-mb-m">
        <section
          class="header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-mb-l"
        >
          <div class="u-display-flex u-flex-direction-column u-flex-1">
            <h3
              class="u-font-size-2 u-text-align-left u-padding-0 u-spacing-mb-s"
            >
              Strategies
            </h3>
            <p class="step-desc u-font-size-5 u-color-grey-lighter">
              Lists of all strategies.
            </p>
          </div>
          <div class="u-font-size-5 u-text-align-right">
            <rb-button
              v-show="showSavePrioritizationBtn"
              id="confirmButton"
              :icon-left="'save'"
              :text="'Save prioritization'"
              :type="'filled'"
              class="u-spacing-mr-m"
              :click-fn="savePrioritization"
            />
            <rb-button
              id="confirmButton"
              :icon-left="'segment'"
              :text="
                !isGroupByActionType ? 'Group by action type' : 'Universal list'
              "
              :type="'hollow'"
              class="u-spacing-mr-m"
              :click-fn="groupByActionType"
            />
            <rb-button
              :icon-left="'plus'"
              :click-fn="gotoCreateStrategy"
              :text="'Create strategy'"
              :type="'filled'"
              class="u-cursor-pointer baseLine"
            />
            <!-- <rb-button
          :text="'Create strategy'"
          :type="'filled'"
          id="confirmButton"
          :clickFn="gotoCreateStrategy"
        ></rb-button> -->
          </div>
        </section>
        <section class="u-position-relative">
          <section v-show="!isGroupByActionType">
            <draggable
              v-model="strategyList"
              @start="drag = true"
              @end="
                drag = false;
                onMoveCallback();
              "
            >
              <div
                v-for="item in strategyList"
                :id="item.strategyUniqueId"
                :key="item.strategyName"
                class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-p-m u-width-100 card strategy_list u-spacing-mb-s u-border-left-width-m u-border-left"
                :class="computeClass(item)"
              >
                <div
                  class="u-display-flex u-flex-justify-content-space-betweeen u-flex-1 u-flex-align-items-center"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-row">
                      <div class="u-display-flex u-flex-direction-column">
                        <p
                          class="u-font-size-4 u-display-flex u-color-grey-base"
                        >
                          <span
                            v-tippy
                            class="u-spacing-mr-s"
                            title="Reorder"
                          >
                            <rb-icon
                              :icon="'draggable-reorder'"
                              class="rb-icon--medium u-color-grey-lighter u-cursor-move"
                            />
                          </span>
                          <span class="u-spacing-mr-s">{{
                            item.priorityRank | priorityFilter
                          }}</span>
                        </p>
                      </div>
                      <div class="u-display-flex u-flex-direction-column">
                        <p
                          class="u-font-size-4 u-display-flex u-flex-align-items-center u-color-grey-base"
                        >
                          <span>{{ item.strategyName }}</span>
                        </p>
                        <p
                          class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s u-width-100"
                        >
                          <span
                            class="u-color-grey-base"
                            :class="computeClass(item)"
                            >{{ item.selected.actions.actionName }}</span
                          >
                          <span v-if="item.description">
                            • {{ item.description }}</span
                          >
                        </p>
                        <p
                          class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
                        >
                          <span
                            v-if="item.creationTime === item.lastModifiedTime"
                          >
                            Created by
                            <span class="u-color-grey-base">{{
                              item.userName
                            }}</span>
                            on
                            <span class="u-color-grey-base">
                              {{ item.creationTime | dateFormatFilter }}</span
                            >
                          </span>
                          <span v-else>
                            Modified by
                            <span class="u-color-grey-base">{{
                              item.userName
                            }}</span>
                            on
                            <span class="u-color-grey-base">
                              {{
                                item.lastModifiedTime | dateFormatFilter
                              }}</span
                            >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="right_side u-display-flex">
                    <icon-text-actions
                      v-if="!item.showDetails"
                      class="u-width-100 show_on_hover u-spacing-mt-s icon_maximize u-spacing-mr-12"
                      mode="default"
                      :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                      :apply-padding="false"
                      @clicked="item.showDetails = !item.showDetails"
                    />
                    <icon-text-actions
                      v-if="item.showDetails"
                      class="u-width-100 show_on_hover u-spacing-mt-s icon_maximize u-spacing-mr-12"
                      mode="default"
                      :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                      :apply-padding="false"
                      @clicked="item.showDetails = !item.showDetails"
                    />
                    <icon-text-actions
                      class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                      mode="default"
                      :action="{ title: 'EDIT', icon: 'pencil' }"
                      :apply-padding="false"
                      @clicked="navigateToScreen('edit', item)"
                    />
                    <icon-text-actions
                      class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                      mode="default"
                      :action="{ title: 'COPY', icon: 'copy-to-clipboard' }"
                      :apply-padding="false"
                      @clicked="navigateToScreen('copy', item)"
                    />
                    <icon-text-actions
                      class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                      mode="default"
                      :action="{ title: 'CIQ', icon: 'bot' }"
                      :class-name="'u-color-blue-base'"
                      :apply-padding="false"
                    />
                    <rb-action-select
                      :current-state="item.strategyStatus"
                      @stateChange="stateChange($event, item)"
                    />
                  </div>
                </div>
                <!-- Strategy Details info  -->
                <transition name="fade">
                  <section
                    v-if="item.showDetails"
                    class="stategy_details_info u-spacing-mt-m u-position-relative"
                  >
                    <div
                      v-if="!filterMetaDataLoading"
                      class="u-display-flex"
                    >
                      <Strategy-Description :details="item.selected" />
                    </div>
                    <Loader
                      v-show="filterMetaDataLoading"
                      class="fill--parent"
                      :color="'#3fadf7'"
                      :size="'4rem'"
                      :thickness="'.2rem'"
                    />
                  </section>
                </transition>
              </div>
              <div
                v-if="strategyList && strategyList.length === 0"
                class="u-font-size-4 u-text-align-center"
              >
                No data available.
              </div>
            </draggable>
          </section>

          <!-- section for - group by action type -->
          <section
            v-if="isGroupByActionType"
            class="action_type"
          >
            <div
              id="action_type_wrp"
              class="container"
            >
              <div
                v-for="(list, key, $index) in strategyGroupByAction"
                :key="key"
                class="item card u-spacing-mb-m u-border-top-width-m u-border-top"
                :class="computeClass(list[0])"
              >
                <section
                  class="heading u-display-flex u-flex-justify-content-space-between u-spacing-m-l"
                >
                  <div
                    class="u-font-size-4 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center"
                  >
                    <span class="u-flex-1">{{ key }}</span>
                  </div>

                  <div class="u-display-flex">
                    <!-- <span class="u-spacing-mr-s u-cursor-pointer u-display-flex u-flex-align-items-center">
                    <rb-icon class="rb-icon" :icon="'play'"></rb-icon>
                    <span class="u-font-size-6">Simulate</span>
                  </span> -->

                    <span
                      v-if="!list[0].showActionType"
                      class="u-spacing-mr-s u-cursor-pointer u-display-flex u-flex-align-items-center"
                      @click="list[0].showActionType = !list[0].showActionType"
                    >
                      <rb-icon
                        icon="enlarge"
                        class="rb-icon icon_maximize_action u-spacing-mr-xs"
                      />
                      <span class="u-font-size-6">Maximize</span>
                    </span>

                    <!-- <span class="u-spacing-mr-s u-cursor-pointer u-display-flex u-flex-align-items-center" @click="list[0].showActionType = !list[0].showActionType;" v-if="list[0].showActionType">
                    <rb-icon icon="collapse" class="rb-icon icon_maximize_action u-spacing-mr-xs"></rb-icon>
                    <span class="u-font-size-6">Minimize</span>
                  </span> -->
                  </div>
                </section>
                <section class="">
                  <draggable
                    v-model="strategyGroupByAction[key]"
                    @start="drag = true"
                    @end="
                      drag = true;
                      onMoveCallback(key);
                    "
                  >
                    <div
                      v-for="item in strategyGroupByAction[key]"
                      :key="item.priority"
                      class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-p-m u-width-100 card strategy_list"
                    >
                      <div
                        class="u-display-flex u-flex-justify-content-space-betweeen u-flex-1 u-flex-align-items-center"
                      >
                        <div class="u-flex-1">
                          <div class="u-display-flex u-flex-direction-column">
                            <p
                              class="u-font-size-4 u-display-flex u-flex-align-items-center u-color-grey-base"
                            >
                              <span
                                v-tippy
                                class="u-spacing-mr-s"
                                title="Reorder"
                              >
                                <rb-icon
                                  :icon="'draggable-reorder'"
                                  class="rb-icon--medium u-color-grey-lighter u-cursor-move"
                                />
                              </span>
                              <span class="u-spacing-mr-s">{{
                                item.priorityRank | priorityFilter
                              }}</span>
                              <span>{{ item.strategyName }}</span>
                            </p>
                            <p
                              class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s u-width-100 u-spacing-pl-xxl"
                            >
                              <span
                                class="u-color-grey-base"
                                :class="computeClass(item)"
                                >{{ item.selected.actions.actionName }}</span
                              >
                              <span
                                v-if="item.description"
                                class="u-line-height-1-3"
                              >
                                • {{ item.description }}</span
                              >
                            </p>
                            <p
                              class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s u-spacing-ml-xxl"
                            >
                              <span
                                v-if="
                                  item.creationTime === item.lastModifiedTime
                                "
                              >
                                Created by
                                <span class="u-color-grey-base">{{
                                  item.userName
                                }}</span>
                                on
                                <span class="u-color-grey-base">
                                  {{
                                    item.creationTime | dateFormatFilter
                                  }}</span
                                >
                              </span>
                              <span v-else>
                                Modified by
                                <span class="u-color-grey-base">{{
                                  item.userName
                                }}</span>
                                on
                                <span class="u-color-grey-base">
                                  {{
                                    item.lastModifiedTime | dateFormatFilter
                                  }}</span
                                >
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="right_side u-display-flex">
                          <icon-text-actions
                            v-if="!item.showDetails"
                            class="u-width-100 show_on_hover u-spacing-mt-s icon_maximize u-spacing-mr-12"
                            mode="default"
                            :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                            :apply-padding="false"
                            @clicked="item.showDetails = !item.showDetails"
                          />
                          <icon-text-actions
                            v-if="item.showDetails"
                            class="u-width-100 show_on_hover u-spacing-mt-s icon_maximize u-spacing-mr-12"
                            mode="default"
                            :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                            :apply-padding="false"
                            @clicked="item.showDetails = !item.showDetails"
                          />
                          <icon-text-actions
                            class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                            mode="default"
                            :action="{ title: 'EDIT', icon: 'pencil' }"
                            :apply-padding="false"
                            @clicked="navigateToScreen('edit', item)"
                          />
                          <icon-text-actions
                            class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                            mode="default"
                            :action="{
                              title: 'COPY',
                              icon: 'copy-to-clipboard'
                            }"
                            :apply-padding="false"
                            @clicked="navigateToScreen('copy', item)"
                          />
                          <icon-text-actions
                            class="u-width-100 show_on_hover u-spacing-mt-s u-spacing-mr-12"
                            mode="default"
                            :action="{ title: 'CIQ', icon: 'bot' }"
                            :class-name="'u-color-blue-base'"
                            :apply-padding="false"
                          />
                          <rb-action-select
                            :current-state="item.strategyStatus"
                            @stateChange="stateChange($event, item)"
                          />
                        </div>
                      </div>
                      <!-- Strategy Details info  -->
                      <transition name="fade">
                        <section
                          v-if="item.showDetails"
                          class="stategy_details_info u-spacing-mv-m u-spacing-pt-m u-position-relative"
                        >
                          <div
                            v-if="!filterMetaDataLoading"
                            class="u-display-flex"
                          >
                            <Strategy-Description :details="item.selected" />
                          </div>
                          <Loader
                            v-show="filterMetaDataLoading"
                            class="fill--parent"
                            :color="'#3fadf7'"
                            :size="'4rem'"
                            :thickness="'.2rem'"
                          />
                        </section>
                      </transition>
                    </div>
                  </draggable>
                </section>
              </div>
            </div>
          </section>

          <Loader
            v-show="isLoading"
            class="fill--parent"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </section>
      </section>
      <!-- Strategies Section end-->
    </section>
    <!-- Right Panel for View Drafts Start -->
    <ViewSavedDraftPanel />
    <!-- Right Panel for View Drafts End -->
  </section>
</template>
<script>
// import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import Loader from '@/components/basic/loader';
import widgetsUtil from '@/components/widgetsMixin';
import asinSearch from '@/components/widgets/asinSearch';
import draggable from 'vuedraggable';
import StategyCarousel from './strategyCarousel';
import ViewSavedDraftPanel from './viewSavedDraft';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
import StrategyDescription from './strategyDesciption';
import rbActionSelect from './strategy_action';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
var hash = require('object-hash');

export default {
  owner: 'Rajan V',
  filters: {
    priorityFilter: function (value) {
      const isValueSingleDigit = value?.toString().length === 1;
      if (!value) return '0';
      if (value && !isValueSingleDigit) return value;
      return ('0' + value).slice(-2);
    },
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  },
  components: {
    Loader,
    rbFilterPanel,
    asinSearch,
    draggable,
    StategyCarousel,
    ViewSavedDraftPanel,
    StrategyDescription,
    rbActionSelect,
    iconTextActions
  },
  mixins: [widgetsUtil],
  data() {
    return {
      filterState: {
        getter: 'getInsightsOverviewSelectedFilter',
        setter: 'setInsightsOverviewSelectedFilter'
      },
      detailsListMapping: [
        { displayName: 'Objective', keyToRead: 'objective' },
        { displayName: 'Scope', keyToRead: 'scope' },
        { displayName: 'Condition', keyToRead: 'rules' },
        { displayName: 'Action', keyToRead: 'actions' },
        { displayName: 'Time period', keyToRead: 'lookBackWindow' }
      ],
      strategyList: [],
      strategyGroupByAction: {},
      showSavePrioritizationBtn: false,
      isGroupByActionType: false,
      showViewDraftPanel: false,
      classMapping: {
        ASIN_REMOVAL: 'asin_removal',
        CAMPAIGN_BUDGET_CHANGE: 'campaign_budget_change',
        ARCHIVE_CAMPAIGN: 'archive_campaign',
        ENABLE_CAMPAIGN: 'enable_campaign',
        PAUSE_CAMPAIGN: 'pause_campaign',
        KEYWORD_BID_CHANGE: 'keyword_bid_change',
        ENABLE_KEYWORD: 'enable_keyword',
        PAUSE_KEYWORD: 'pause_keyword',
        REMOVE_KEYWORD: 'remove_keyword',
        ADD_KEYWORD_TO_CAMPAIGNS: 'add_keyword_to_campaigns',
        ADD_NEGATIVE_KEYWORD: 'add_negative_keyword',
        WALMART_ASIN_REMOVAL: 'asin_removal',
        WALMART_CAMPAIGN_BUDGET_CHANGE: 'campaign_budget_change',
        WALMART_ARCHIVE_CAMPAIGN: 'archive_campaign',
        WALMART_ENABLE_CAMPAIGN: 'enable_campaign',
        WALMART_PAUSE_CAMPAIGN: 'pause_campaign',
        WALMART_KEYWORD_BID_CHANGE: 'keyword_bid_change',
        CHEWY_PROMOTEIQ_KEYWORD_BID_MODIFIER_CHANGE: 'keyword_bid_change',
        WALMART_ENABLE_KEYWORD: 'enable_keyword',
        WALMART_PRODUCTAD_BID_CHANGE: 'keyword_bid_change', // this only maps to a style class
        WALMART_PAUSE_KEYWORD: 'pause_keyword',
        WALMART_REMOVE_KEYWORD: 'remove_keyword',
        WALMART_ADD_KEYWORD_TO_CAMPAIGNS: 'add_keyword_to_campaigns',
        WALMART_ADD_NEGATIVE_KEYWORD: 'add_negative_keyword'
      },
      listOfCard: {},
      strategyListBackup: [],
      hash: {
        list: null,
        groupBy: null
      },
      isLoading: false
    };
  },
  computed: {
    filterMetaDataLoading() {
      return this.$store.getters.getFilterMetaDataLoading;
    },
    filterMetaDataError() {
      return this.$store.getters.getFilterMetaDataError;
    }
  },
  beforeCreate() {},
  mounted() {
    this.init(null, true);
  },
  created() {},
  methods: {
    navigateToScreen(mode, item) {
      this.$router.push({
        name: 'StrategyBuilderEdit',
        query: {
          mode: mode,
          id: item.strategyUniqueId
        }
      });
    },
    stateChange(status, params) {
      const data = {
        strategyUniqueId: params.strategyUniqueId,
        status: status
      };
      this.isLoading = true;
      this.$store.dispatch('strategyStatusUpdate', data).then(
        (data) => {
          this.isLoading = false;
          this.init('decideView');
          this.$snackbar.open({
            message: 'Strategy Status Updated Successfully',
            duration: 5000,
            actionText: ''
          });
        },
        (error) => {
          this.isLoading = false;
          this.$snackbar.open({
            message:
              (error.data && error.data.message) || 'Something Went Wrong !!!',
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    init(params, calledFromMounted) {
      this.isLoading = true;
      this.$store.dispatch('fetchStartegyList').then(
        (response) => {
          if (response && response.data) {
            if (calledFromMounted) {
              // call filter fetch only after builder metadata is fetched, only once.
              this.$store.dispatch('fetchCubeInformation');
            }
            this.strategyList = [];
            this.strategyListBackup = [];
            this.strategyList = JSON.parse(JSON.stringify(response.data));
            this.strategyListBackup = JSON.parse(JSON.stringify(response.data));
            this.hash.list = hash(this.strategyList);
            this.listOfCard = response.data.reduce((initial, item) => {
              if (initial[item.selected.objective.name]) {
                initial[item.selected.objective.name].push(item);
              } else {
                initial[item.selected.objective.name] = [];
                initial[item.selected.objective.name].push(item);
              }
              return initial;
            }, {});
            if (this.$route.query && this.$route.query.id) {
              setTimeout(() => {
                const dom = document.getElementById(
                  decodeURIComponent(this.$route.query.id)
                );
                if (dom) {
                  dom.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                  });
                  dom.classList.add('transision__border');
                  setTimeout(() => {
                    dom.classList.remove('transision__border');
                  }, 3000);
                }
              }, 0);
            }
            this.isLoading = false;
            if (params && this.isGroupByActionType === true) {
              this.isGroupByActionType = false;
              this.groupByActionType();
            }
          }
        },
        (error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || error,
            duration: 5000,
            actionText: ''
          });
          this.isLoading = false;
        }
      );
    },
    computeClass(item, groupBy) {
      return this.classMapping[item.selected.actions.actionType];
    },
    gotoCreateStrategy() {
      this.$router.push({ name: 'StrategyBuilder' });
      // this.$router.push('strategy-builder');
    },
    onMoveCallback(params) {
      if (params) {
        const order = this.strategyGroupByAction[params].map((item) => {
          return item.priorityRank;
        });
        order.sort((a, b) => {
          return a > b ? 1 : b > a ? -1 : 0;
        });
        for (let i = 0; i < order.length; i++) {
          // Vue.set(this.strategyList[i].priorityRank, order[i]);
          this.strategyGroupByAction[params][i].priorityRank = order[i];
        }
        const newHash = hash(this.strategyGroupByAction);
        if (this.hash.groupBy === newHash) {
          this.showSavePrioritizationBtn = false;
        } else {
          this.showSavePrioritizationBtn = true;
        }
      } else {
        const order = this.strategyList.map((item) => {
          return item.priorityRank;
        });
        order.sort((a, b) => {
          return a > b ? 1 : b > a ? -1 : 0;
        });
        for (let i = 0; i < order.length; i++) {
          this.strategyList[i].priorityRank = order[i];
        }
        const newHash = hash(this.strategyList);
        if (this.hash.list === newHash) {
          this.showSavePrioritizationBtn = false;
        } else {
          this.showSavePrioritizationBtn = true;
        }
      }
    },
    groupByActionType() {
      this.showSavePrioritizationBtn = false;
      if (!this.isGroupByActionType) {
        this.isLoading = true;
        setTimeout(
          function () {
            this.isGroupByActionType = !this.isGroupByActionType;
            // isGroupByActionType - true to group it based on action type
            const tempObj = JSON.parse(JSON.stringify(this.strategyListBackup));
            this.strategyGroupByAction = {};
            let tempGroupByAction = {};
            tempGroupByAction = tempObj.reduce((initial, item) => {
              if (initial[item.selected.actions.actionName]) {
                initial[item.selected.actions.actionName].push(item);
              } else {
                initial[item.selected.actions.actionName] = [];
                item.showActionType = true;
                initial[item.selected.actions.actionName].push(item);
              }
              return initial;
            }, {});

            const tempOrderKeys = Object.keys(tempGroupByAction).sort(
              (a, b) => {
                const firstKeyLength = tempGroupByAction[a].length;
                const secondKeyLength = tempGroupByAction[b].length;
                return firstKeyLength < secondKeyLength
                  ? 1
                  : secondKeyLength < firstKeyLength
                  ? -1
                  : 0;
              }
            );

            const obj = {};
            tempOrderKeys.forEach((key) => {
              obj[key] = [];
              obj[key].push(...tempGroupByAction[key]);
              // this.strategyGroupByAction[key] = tempGroupByAction[key];
            });
            this.strategyGroupByAction = JSON.parse(JSON.stringify(obj));
            this.hash.groupBy = hash(this.strategyGroupByAction);
            // item.showDetails = !item.showDetails;
            this.calculateHeight();
            this.isLoading = false;
          }.bind(this),
          1000
        );
      } else {
        this.isGroupByActionType = !this.isGroupByActionType;
        this.strategyList = [];
        this.strategyList = [...this.strategyListBackup];
      }
    },
    savedDraft() {
      this.showViewDraftPanel = true;
      eventBus.$emit('viewSavedDraftPanelOpen');
    },
    calculateHeight() {
      setTimeout(() => {
        const allHeight = [];
        document.querySelectorAll('.item.card').forEach((item) => {
          allHeight.push(parseInt(window.getComputedStyle(item).height) + 16);
        });
        allHeight.sort((a, b) => {
          return a > b ? 1 : b > a ? -1 : 0;
        });
        // corner case
        if (allHeight.length === 2) {
          document.getElementById('action_type_wrp').style.height =
            allHeight[0] >= allHeight[1]
              ? allHeight[0] + 5 + 'px'
              : allHeight[1] + 5 + 'px';
        } else if (allHeight.length >= 3) {
          const lastHeight = allHeight[allHeight.length - 1];
          const otherHeight = allHeight.slice(0, allHeight.length - 1);
          const otherHeightSum = otherHeight.reduce((initial, item) => {
            return initial + item;
          }, 0);
          if (otherHeightSum >= lastHeight) {
            document.getElementById('action_type_wrp').style.height =
              otherHeightSum + 5 + 'px';
          } else {
            document.getElementById('action_type_wrp').style.height =
              lastHeight + otherHeight[1] + 5 + 'px';
          }
        }
      }, 100);
    },
    savePrioritization() {
      this.isLoading = true;
      const objToSend = {
        strategyType: 'STRATEGY_BUILDER',
        strategyPriority: {}
      };
      if (this.isGroupByActionType) {
        Object.keys(this.strategyGroupByAction).forEach((item) => {
          this.strategyGroupByAction[item].forEach((action) => {
            objToSend.strategyPriority[action.strategyUniqueId] =
              action.priorityRank;
          });
        });
      } else {
        this.strategyList.forEach((item) => {
          objToSend.strategyPriority[item.strategyUniqueId] = item.priorityRank;
        });
      }
      HttpService.put('PRIORITIZESTRATEGY', objToSend).then(
        (response) => {
          this.$snackbar.open({
            message: 'Prioritization saved successfully',
            duration: 5000,
            actionText: ''
          });
          this.showSavePrioritizationBtn = false;
          this.isLoading = false;
          setTimeout(() => {
            this.init();
          }, 100);
        },
        (error) => {
          this.isLoading = false;
          this.$snackbar.open({
            message:
              'Something Went Wrong !!!' || (error.data && error.data.message),
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    cancelCallback() {
      this.showViewDraftPanel = false;
    }
  }
};
</script>
<style lang="css" scoped>
.strategy_list {
  transition: border 0.5s linear;
}
.strategy_list .icon_maximize {
  visibility: hidden;
}
.strategy_list .icon_text {
  visibility: hidden;
}

.strategy_list:hover {
  box-shadow: 0px 1px 8px 0px rgba(43, 51, 59, 0.15) !important;
}
.strategy_list:hover .icon_maximize {
  visibility: visible;
}
.strategy_list:hover .icon_text {
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.icon_maximize_action:before {
  font-size: 12px;
}

.asin_removal {
  border-color: #bd10e0;
  color: #bd10e0;
}
.campaign_budget_change {
  border-color: #d7263d;
  color: #d7263d;
}
.archive_campaign {
  border-color: #007cf6;
  color: #007cf6;
}
.enable_campaign {
  border-color: #ffa800;
  color: #ffa800;
}
.pause_campaign {
  border-color: #3ea95e;
  color: #3ea95e;
}
.keyword_bid_change {
  border-color: #bd10e0;
  color: #bd10e0;
}
.enable_keyword {
  border-color: #23b5d3;
  color: #23b5d3;
}
.pause_keyword {
  border-color: #ff6072;
  color: #ff6072;
}
.remove_keyword {
  border-color: #2b333b;
  color: #2b333b;
}
.add_keyword_to_campaigns {
  border-color: #8b8f93;
  color: #8b8f93;
}
.add_negative_keyword {
  border-color: #d7263d;
  color: #d7263d;
}

.transision__border {
  border-right: 1px solid #007cf6;
  border-top: 1px solid #007cf6;
  border-bottom: 1px solid #007cf6;
}

.stategy_details_info {
  color: initial;
}

.action_type .container {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  height: 100px;
  background-color: #f7f7f7;
  border-radius: 3px;
  width: 100%;
  margin: 0px auto;
  counter-reset: items;
}
.action_type .item {
  width: 49%;
  position: relative;
  border-radius: 3px;
}
.action_type div.item::before {
  counter-increment: items;
}
.action_type .item:nth-of-type(2n + 1) {
  order: 1;
}
.action_type .item:nth-of-type(2n) {
  order: 2;
}
.action_type .break {
  flex-basis: 100%;
  width: 0;
  margin: 0;
  content: '';
  padding: 0;
}
</style>
