<template>
  <div
    v-tippy
    title="Manage downloads"
    class="u-display-flex u-flex-direction-row u-flex-align-items-center u-cursor-pointer"
    :class="[enableAnimation ? 'download-slide-anim' : 'download-center-init']"
    data-cy="ManageDownloads"
    @click="openDownloadCenter"
  >
    <rb-icon
      class="rb-icon--x-medium u-spacing-pr-xxs"
      :class="[showNewDownloads ? 'u-color-blue-base' : 'u-color-grey-lighter']"
      :icon="'archive'"
    />
    <span
      class="u-font-size-6 u-font-weight-600 u-fill-color-blue-base u-spacing-pl-xs u-width-100"
      data-cy="DownloadingFile"
      >Downloading file...</span
    >
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'DownloadCenterIcon',
  props: {
    enableAnimation: {
      type: Boolean,
      default: false
    },
    showNewDownloads: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openDownloadCenter() {
      eventBus.$emit('downloadCenterOpen', 'open');
    }
  }
};
</script>

<style lang="css" scoped>
.download-slide-anim {
  z-index: 1;
  overflow: hidden;
  max-width: 200px;
  animation: right_to_left, left_to_right;
  animation-delay: 0s, 3.5s;
  animation-duration: 1.5s, 1.5s;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-out, ease-in;
  transform: translateX(82%);
}
@keyframes right_to_left {
  to {
    transform: translateX(0%);
  }
}
@keyframes left_to_right {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(82%);
  }
}
.download-center-init {
  overflow: hidden;
  max-width: 24px;
}
</style>
