var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !(
        _vm.hideIfNull &&
        _vm.displayValue.oldValue === 'NA' &&
        _vm.displayValue.newValue === 'NA'
      )
    )?_c('div',{staticClass:"u-spacing-mt-s u-display-flex u-flex-direction-column"},[_c('span',{staticClass:"u-font-size-7 u-line-height-1 u-color-grey-lighter u-font-weight-600"},[_vm._v(_vm._s(_vm.config['sub-heading']))]),_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-mt-xs"},[(!(_vm.hideIfNull && _vm.displayValue.oldValue === 'NA'))?_c('div',{style:({ 'min-width': _vm.config.leftMinWidth })},[_c('span',{staticClass:"u-font-size-6 u-color-grey-light u-line-height-1.31",class:{ 'u-text-case-title': !_vm.config.caseSensitive },attrs:{"data-cy":"oldValue"}},[_vm._v(_vm._s(_vm.displayValue.oldValue)+" ")])]):_vm._e(),(
          !(
            _vm.hideIfNull &&
            (_vm.displayValue.oldValue === 'NA' || _vm.displayValue.newValue === 'NA')
          )
        )?_c('div',[_c('rb-icon',{staticClass:"rb-icon--medium u-spacing-mh-s u-color-grey-base",attrs:{"icon":'trending-neutral'}})],1):_vm._e(),(!(_vm.hideIfNull && _vm.displayValue.newValue === 'NA'))?_c('div',[_c('span',{staticClass:"u-font-size-6 u-color-grey-light u-line-height-1.31 u-font-weight-600",class:{ 'u-text-case-title': !_vm.config.caseSensitive },attrs:{"data-cy":"newValue"}},[_vm._v(_vm._s(_vm.displayValue.newValue))])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }