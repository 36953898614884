<template>
  <div class="u-spacing-pv-xxxl">
    <section class="u-spacing-pt-m u-spacing-ph-xxl">
      <adFormatHeader :step="step" />
      <campaignAdFormat
        :store-info="storeInfoForProfile"
        :all-steps-data="allStepsData"
        :selected-values="selectedValues"
        :suggested-campaign-index="suggestedCampaignIndex"
        :step="step"
      />
      <adFormatFooter
        :disable-right-btn="disableNextButton || pageAsinLoader"
        class="u-margin-top-40"
        :show-left-btn="true"
        :error-message="localErrorMessage || apiErrorMessage"
        :right-button-loading="pageAsinLoader"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import adFormatHeader from '@/components/ams/campaign_creation/steps/common/header';
import adFormatFooter from '@/components/ams/campaign_creation/steps/common/footer';
import campaignAdFormat from './campaignAdFormat.vue';
import {
  fetchPageAsins,
  getAsinDetails
} from '@/components/ams/campaign_creation/steps/edit/campaignCreative/helper.js';
import transformer from '@/utils/services/data-transformer';
export default {
  components: {
    adFormatHeader,
    adFormatFooter,
    campaignAdFormat
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    suggestedCampaignIndex: 0,
    localErrorMessage: '',
    apiErrorMessage: '',
    pageAsinLoader: false,
    retriesLeft: 3,
    responseCodes: {
      success: 'SUCCESS',
      invalid: 'INVALID_ARGUMENT',
      too_many_requests: '429 Too Many Requests'
    }
  }),
  computed: {
    disableNextButton() {
      let disable = false;
      const { adFormat, adFormatSubType, customURL } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (adFormat === 'productCollection') {
        if (adFormatSubType === 'custom_url') {
          if (!customURL?.length) {
            disable = true;
          }
        }
        if (adFormatSubType === 'store_on_amazon') {
          disable = this.validateStoreOnAmazonSelection();
        }
      }
      if (adFormat === 'storeSpotlight') {
        disable = this.validateStoreSpotlight();
        this.apiErrorMessage = '';
      }
      if (!disable) {
        this.localErrorMessage = '';
      }
      return disable;
    },
    storeInfoForProfile() {
      return this.$store?.getters?.['campaignCreation/getStoreInfo'];
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    this.fetchStoreInfo();
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    fetchStoreInfo() {
      this.$store.dispatch('campaignCreation/fetchStoreInfo', {
        // 'profileId': '614698750655456'
        profileId:
          this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
            ?.profile?.profile_id
      });
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    getAsinInfo(skus) {
      getAsinDetails(skus)
        .then((response) => {
          const creativeSkus = transformer.mergeResultDimension(
            response?.data,
            true
          );
          this.updateSelectedStore({ creativeSkus });
          this.apiErrorMessage =
            creativeSkus.length < 3
              ? 'The selected store contains less than 3 products. A Sponsored Brands campaign allows you to feature a minimum of 3 products from your store. Please select a different store to proceed.'
              : '';
          this.pageAsinLoader = false;
          if (!this.apiErrorMessage) {
            this.$emit('nextStep', {});
          }
        })
        .catch(() => {
          this.pageAsinLoader = false;
        });
    },
    implementRetry() {
      if (this.retriesLeft > 0) {
        this.retriesLeft = this.retriesLeft - 1;
        setTimeout(() => {
          this.getPageAsins();
        }, [500]);
      } else {
        this.apiErrorMessage =
          'We are experiencing frequent request failures from Amazon, please try again in 5 minutes.';
        this.pageAsinLoader = false;
      }
    },
    validatePageAsinsResponse(response) {
      if (response.code === this.responseCodes.success) {
        this.retriesLeft = 2;
        this.apiErrorMessage = '';
        const asinList = response?.asinList || [];
        this.getAsinInfo(asinList);
      } else if (response.code === this.responseCodes.invalid) {
        this.pageAsinLoader = false;
        this.apiErrorMessage =
          'The entered link appears to be invalid. Please enter a valid store link.';
      } else if (response === this.responseCodes.too_many_requests) {
        this.implementRetry();
      } else {
        this.pageAsinLoader = false;
        this.apiErrorMessage =
          'Something went wrong! Pleaser try after sometime.';
      }
    },
    getPageAsins() {
      const {
        storeOnAmazonPage,
        profile,
        adFormatSubType,
        customURL,
        adFormat
      } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex] ||
        {};
      if (adFormatSubType === 'new_landing_page' || adFormat === 'video') {
        this.$emit('nextStep', {});
        return;
      }
      const pageUrl =
        adFormatSubType === 'custom_url'
          ? customURL
          : storeOnAmazonPage?.storePageUrl;
      this.pageAsinLoader = true;
      fetchPageAsins(pageUrl, profile, this.$store)
        .then((res) => {
          const response = res?.data?.response;
          this.validatePageAsinsResponse(response);
        })
        .catch(() => {
          this.pageAsinLoader = false;
          this.apiErrorMessage =
            'Something went wrong! Pleaser try after sometime.';
        });
    },
    validateCurrentStep() {
      const { adFormat } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex] ||
        {};
      if (adFormat === 'productCollection' || adFormat === 'video') {
        this.retriesLeft = 2;
        this.getPageAsins();
      } else {
        this.$emit('nextStep', {});
      }
    },
    nextStep() {
      this.validateCurrentStep();
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    validateStoreOnAmazonSelection() {
      const selectedStoreOnAmazonStore =
        this.allStepsData.suggestedCampaigns[0]?.storeOnAmazonStore ||
        this.storeInfoForProfile?.rows[0];
      const stores =
        !this.storeInfoForProfile ||
        !this.storeInfoForProfile?.rows?.length > 0;
      const storeOnAmazonPageOptions =
        selectedStoreOnAmazonStore?.storePageInfo;
      const isDisabled = stores || !storeOnAmazonPageOptions?.length > 0;
      if (isDisabled && !this.storeInfoForProfile.load) {
        this.localErrorMessage = `No ${
          stores ? 'stores' : 'pages'
        } found for the given ${
          stores ? 'profile / portfolio' : 'store'
        }. Please choose to proceed with a New Landing Page or enter a custom page URL to continue.`;
      } else {
        this.localErrorMessage = '';
      }
      return isDisabled;
    },
    validateStoreSpotlight() {
      const isDisabled =
        !this.storeInfoForProfile ||
        !this.storeInfoForProfile?.rows?.length > 0;
      this.localErrorMessage =
        isDisabled && !this.storeInfoForProfile.load
          ? 'No storefront found for the given profile / portfolio. Please choose a different ad format.'
          : '';
      return isDisabled;
    }
  }
};
</script>

<style lang="css" scoped>
.fit-content {
  width: fit-content !important;
}
</style>
