<template>
  <div class="u-height-100 u-width-100 u-display-flex u-flex-direction-column">
    <Loader
      :loading="generatedMediaPlans.loading"
      class="loader--expand"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div class="u-spacing-p-l u-spacing-pt-s u-bg-color-grey-white">
      <PageTitle :title="'Media Planner'" />
    </div>
    <div
      v-if="!generatedMediaPlans.loading"
      class="u-display-flex u-width-100 u-flex-justify-content-center u-flex-1"
    >
      <div
        v-if="
          generatedMediaPlans.length === 0 &&
          (draftedMediaPlans.list || []).length === 0
        "
        class="u-display-flex u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"
      >
        <img
          style="max-width: 18%"
          src="/images/no_budget.png"
          alt="no media plan image"
        />
        <div class="u-spacing-pv-l u-font-weight-600 u-font-size-3">
          Looks like you haven’t created your Media plan yet!
        </div>
        <rb-button
          type="filled"
          :click-fn="generatePlan"
          text="Generate Plan"
          icon-left="add-circle-fill"
        />
      </div>
      <div
        v-else
        class="u-width-100 u-spacing-pv-xl u-spacing-ph-l"
      >
        <section
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-mb-l"
        >
          <div class="u-display-flex u-flex-direction-column u-flex-1">
            <h3
              class="u-font-size-2 u-text-align-left u-padding-0 u-spacing-mb-xs"
            >
              Plans
            </h3>
            <p class="step-desc u-font-size-6 u-color-grey-lighter">
              Lists of all plans.
            </p>
          </div>
          <div class="u-text-align-right">
            <rb-button
              :icon-left="'segment'"
              :text="!isGroupByGoal ? 'Group by goal type' : 'Universal list'"
              :type="'hollow'"
              class="u-spacing-mr-m"
              :click-fn="groupByGoal"
            />
            <rb-button
              :icon-left="'draft'"
              text="View Draft Plans"
              :type="'hollow'"
              class="u-spacing-mr-m"
              :click-fn="viewDraftedPlans"
            />
            <div
              v-if="onGoingPlanIds > 0"
              v-tippy="{ placement: 'top', arrow: false }"
              :title="'Currently, a plan is being generated. Please wait for that to complete to generate a new plan.'"
              class="u-display-inline-flex"
            >
              <rb-button
                :icon-left="'plus'"
                :click-fn="generatePlan"
                :text="'Generate Plan'"
                :type="'filled'"
                class="baseLine"
                :disabled="onGoingPlanIds > 0"
              />
            </div>
            <rb-button
              v-else
              :icon-left="'plus'"
              :click-fn="generatePlan"
              :text="'Generate Plan'"
              :type="'filled'"
              class="baseLine"
              :disabled="onGoingPlanIds === -1"
            />
          </div>
        </section>
        <div>
          <DraftedPlans
            :is-open="showDraftedPlans"
            :list="draftedMediaPlans.list"
            :loading="draftedMediaPlans.loading"
            @closeDraftedPlans="() => (showDraftedPlans = false)"
            @resetState="resetState"
          />
          <div
            v-for="(list, listKey, groupIndex) in generatedMediaPlans.list"
            :key="listKey"
          >
            <div
              v-for="(item, index) in list"
              :id="item.id"
              :key="item.id"
              class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-spacing-p-l u-width-100 card"
              :class="{
                'u-spacing-mt-m':
                  isGroupByGoal && groupIndex > 0 && index === 0,
                'u-spacing-mb-m': !isGroupByGoal,
                'u-border-bottom u-border-width-s u-border-color-grey-xxx-light':
                  isGroupByGoal && index + 1 < list.length
              }"
            >
              <div>
                <div
                  v-if="isGroupByGoal && index === 0"
                  class="u-spacing-p-l u-font-size-2 u-font-weight-600 group-heading u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
                >
                  {{ listKey }}
                </div>
                <div
                  class="u-display-flex u-flex-justify-content-space-between u-flex-1 u-flex-align-items-center"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-column">
                      <p
                        class="u-font-size-4 u-display-flex u-flex-align-items-center u-color-grey-base u-font-weight-600"
                      >
                        <span>{{ item.NAME }}</span>
                      </p>
                      <div
                        class="description u-font-size-4 u-color-grey-base u-spacing-mr-xxl u-font-weight-400 u-spacing-mt-m"
                      >
                        <PlanDescription
                          :get-plan-description="() => getPlanDescription(item)"
                        />
                      </div>

                      <p
                        class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
                      >
                        <span
                          v-if="
                            item.CREATED_ON === item.MODIFIED_ON ||
                            !item.MODIFIED_ON ||
                            item.MODIFIED_ON === 'NULL'
                          "
                        >
                          Created by
                          <span class="u-color-grey-base">{{
                            item.CREATED_BY
                          }}</span>
                          on
                          <span class="u-color-grey-base">
                            {{ item.CREATED_ON | dateFormatFilter }}</span
                          >
                        </span>
                        <span v-else>
                          Modified by
                          <span class="u-color-grey-base">{{
                            item.CREATED_BY
                          }}</span>
                          on
                          <span class="u-color-grey-base">
                            {{ item.MODIFIED_ON | dateFormatFilter }}</span
                          >
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="u-width-250px">
                    <div
                      v-if="
                        statuses[item.MEDIA_PLAN_ID] &&
                        statuses[item.MEDIA_PLAN_ID] !==
                          mediaPlanStatusMap.MEDIA_PLAN_CREATED.id &&
                        statuses[item.MEDIA_PLAN_ID] !== 'FAILED' &&
                        timers[item.MEDIA_PLAN_ID] &&
                        timers[item.MEDIA_PLAN_ID].time
                      "
                      class="u-width-100"
                    >
                      <div
                        class="u-spacing-mb-xs u-font-size-7 u-color-grey-base"
                      >
                        <span v-if="timers[item.MEDIA_PLAN_ID].maxTimeReached">
                          "It is taking longer than expected, please check back
                          after some time"
                        </span>
                        <span v-else>
                          Estimated time
                          <span class="u-color-blue-base u-font-weight-600">{{
                            timers[item.MEDIA_PLAN_ID].time
                          }}</span>
                          min
                        </span>
                      </div>

                      <div
                        class="loader"
                        :style="{
                          '--percent': `${timers[item.MEDIA_PLAN_ID].percent}%`
                        }"
                      />
                    </div>
                    <div
                      v-else-if="
                        isPlanInProgress(item) &&
                        !retryingPlan[item.MEDIA_PLAN_ID]
                      "
                      class="u-spacing-mb-xs u-font-size-5 u-color-grey-base u-text-align-center"
                    >
                      This plan is currently being generated
                    </div>
                    <div
                      v-else
                      class="u-display-flex u-flex-align-items-center"
                    >
                      <div
                        v-for="{ title, icon, onClick } in mediaPlanActions"
                        :key="title"
                        :class="`u-position-relative u-spacing-mr-l u-flex-1 ${getClassName(
                          item,
                          title
                        )}`"
                      >
                        <Loader
                          v-if="retryingPlan[item.MEDIA_PLAN_ID]"
                          :loading="true"
                          class="fill--parent"
                          :color="'#3fadf7'"
                        />
                        <IconTextActions
                          v-else
                          button-text-size="u-font-size-6 u-font-weight-600 u-color-grey-base u-white-space-no-wrap"
                          mode="default"
                          :action="getAction(item, title, icon)"
                          :apply-padding="false"
                          @clicked="
                            statuses[item.MEDIA_PLAN_ID] === 'FAILED' &&
                            title === 'View Plan'
                              ? retryPlan(item)
                              : onClick(item)
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="statuses[item.MEDIA_PLAN_ID] === 'FAILED'"
                  class="u-display-flex u-flex-align-items-center u-color-red-base u-font-size-6 u-spacing-pb-s u-spacing-ph-l u-spacing-ph-nl"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--small"
                    icon="error-fill"
                  />
                  <div class="u-flex-0 u-spacing-ml-xxs">
                    Generation of media plan failed. click on retry to generate
                    the plan again.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!generatedMediaPlans.length"
            class="u-font-size-4 u-text-align-center"
          >
            No data available.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
import IconTextActions from '@/components/widgets/iconTextActions.vue';
import PlanDescription from '@/components/ams/media-planner/components/plan-description.vue';
import DraftedPlans from '@/components/ams/media-planner/list/drafted-plans.vue';
import moment from 'moment';
import {
  createPlanDescription,
  goalOptions,
  defaultMediaPlannerConfig,
  getConfigFromPlan,
  FETCH_LIST_OF_MEDIA_PLANS_ACTION,
  DELETE_MEDIA_PLAN_ACTION,
  GENERATED_MEDIA_PLANS_GETTER,
  DRAFTED_MEDIA_PLANS_GETTER,
  MEDIA_PLANNER_CREATION_DATA_SETTER,
  MEDIA_PLANNER_CREATION_DATA_GETTER,
  FILTERED_HISTORICAL_DATA_SETTER,
  OVERALL_HISTORICAL_DATA_SETTER,
  INTERNAL_CATEGORIZATIONS_DATA_SETTER,
  RETAILER_CATEGORIZATIONS_DATA_SETTER,
  FETCH_STATUS_ACTION,
  OUTPUT_GETTER,
  MEDIA_PLAN_STATUS,
  OUTPUT_SETTER,
  RETRY_GENERATING_PLAN_ACTION,
  EXPECTED_GROWTH_SETTER,
  GET_OVERALL_ERRORS
} from '@/components/ams/media-planner/config.js';
import {
  MEDIA_PLANNER_TIMERS_LC_KEY,
  defaultState
} from '@/components/ams/media-planner/constants.js';
import { orderBy } from 'lodash';
export default {
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment.utc(value).local().format('MMM DD, YYYY');
    }
  },
  components: {
    Loader,
    IconTextActions,
    PlanDescription,
    DraftedPlans
  },
  data() {
    const MAX_TIME = 420;
    return {
      isGroupByGoal: false,
      showDraftedPlans: false,
      mediaPlanActions: [
        {
          title: 'Edit Plan',
          icon: 'pencil',
          onClick: (item) => this.editPlan(item)
        },
        {
          title: 'View Plan',
          icon: 'table-chart',
          onClick: (item) => this.viewPlan(item)
        },
        {
          title: 'Delete',
          icon: 'delete',
          onClick: (item) => this.deletePlan(item.MEDIA_PLAN_ID)
        }
      ],
      mediaPlanStatusMap: MEDIA_PLAN_STATUS,
      interval: null,
      timerInterval: null,
      timers: {},
      maxTime: MAX_TIME,
      retryingPlan: {},
      addPlanId: '',
      onGoingPlanIds: -1
    };
  },
  computed: {
    storeData() {
      return this.$store.getters[MEDIA_PLANNER_CREATION_DATA_GETTER];
    },
    errors() {
      return this.$store.getters[GET_OVERALL_ERRORS];
    },
    generatedMediaPlans() {
      const { list, loading, error } =
        this.$store.getters[GENERATED_MEDIA_PLANS_GETTER];
      if (error) {
        this.snackbar(error);
      }
      const groupedList = {};
      const planIds = list.map(({ MEDIA_PLAN_ID }) => MEDIA_PLAN_ID);
      const sortedList = orderBy(
        list,
        [
          (o) => {
            let date = o.CREATED_ON;
            if (
              o.MODIFIED_ON &&
              o.MODIFIED_ON !== 'NULL' &&
              o.MODIFIED_ON > o.CREATED_ON
            ) {
              date = o.MODIFIED_ON;
            }
            return date;
          }
        ],
        ['desc']
      );
      if (this.isGroupByGoal) {
        sortedList.forEach((item) => {
          groupedList[goalOptions[item.SALES_GOAL].label] = [
            ...(groupedList[goalOptions[item.SALES_GOAL].label] || []),
            item
          ];
        });
      } else {
        groupedList[''] = [...sortedList];
      }
      if (planIds.length) {
        this.$store.dispatch(FETCH_STATUS_ACTION, { planIds });
      }
      if (sortedList.length === 0) {
        this.onGoingPlanIds = 0;
      }
      return {
        list: groupedList,
        loading,
        length: sortedList.length
      };
    },
    draftedMediaPlans() {
      const { list, loading, error } =
        this.$store.getters[DRAFTED_MEDIA_PLANS_GETTER];
      if (error) {
        this.snackbar(error);
      }
      const sortedList = orderBy(
        list,
        ['CREATED_ON', 'MODIFIED_ON'],
        ['desc', 'desc']
      );
      return {
        list: sortedList,
        loading
      };
    },
    output() {
      return this.$store.getters[OUTPUT_GETTER];
    },
    statuses() {
      const statuses = this.output.statuses || {};
      const statusKeys = Object.keys(statuses);
      const planIds = statusKeys.filter((planId) => {
        const isCreatedOfFailed =
          statuses[planId] === MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id ||
          statuses[planId] === 'FAILED' ||
          statuses[planId] === null;
        if (isCreatedOfFailed) {
          this.deleteTimerForPlan(planId);
        }

        return !isCreatedOfFailed;
      });
      this.onGoingPlanIds = planIds.length;
      if (!planIds.length && statusKeys.length) {
        localStorage.removeItem(MEDIA_PLANNER_TIMERS_LC_KEY);
      }
      if (this.interval && this.addPlanId) {
        clearInterval(this.interval);
        this.addPlanId = '';
        this.interval = setInterval(() => {
          this.$store.dispatch(FETCH_STATUS_ACTION, { planIds });
        }, 10000);
      }
      if (planIds.length && !this.interval) {
        this.interval = setInterval(() => {
          this.$store.dispatch(FETCH_STATUS_ACTION, { planIds });
        }, 10000);
      }

      return statuses;
    }
  },
  watch: {
    timers: {
      handler(newValue) {
        const planIds = Object.keys(newValue);
        if (planIds.length) {
          planIds.forEach((planId) => {
            if (newValue[planId].percent >= 99) {
              const isCreatedOfFailed =
                this.statuses[planId] ===
                  MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id ||
                this.statuses[planId] === 'FAILED';
              if (isCreatedOfFailed) {
                this.deleteTimerForPlan(planId);
              }
            }
          });
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
        }
      },
      deep: true
    },
    errors(newValue) {
      if (newValue.retry) {
        this.snackbar(newValue.retry);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.timerInterval);
    this.timerInterval = null;
  },
  created() {
    this.$store.dispatch(FETCH_LIST_OF_MEDIA_PLANS_ACTION, {
      isDrafted: false
    });
    this.$store.dispatch(FETCH_LIST_OF_MEDIA_PLANS_ACTION, {
      isDrafted: true
    });
    const timersFromLocalStorage = JSON.parse(
      localStorage.getItem(MEDIA_PLANNER_TIMERS_LC_KEY) || '{}'
    );
    const outputTimeKeys = Object.keys(this.output?.time);
    const outputTime = outputTimeKeys.length
      ? this.output?.time
      : timersFromLocalStorage;
    Object.keys(outputTime).forEach((planId) => {
      const updatedTimers = { ...this.timers };
      updatedTimers[planId] = {
        planTimer: Math.round(
          (new Date().getTime() - outputTime[planId]) / 1000
        ),
        estimatedTimeInSecs: this.maxTime
      };
      this.timers = updatedTimers;
    });
    if (Object.keys(this.timers).length) {
      this.timerInterval = setInterval(() => {
        this.triggerTimers();
      }, 1000);
    }
  },
  methods: {
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    getAction(item, title, icon) {
      return this.statuses[item.MEDIA_PLAN_ID] === 'FAILED' &&
        title === 'View Plan'
        ? {
            title: 'Retry Plan',
            icon: 'reset_to_default'
          }
        : { title, icon };
    },
    isPlanInProgress(item) {
      return (
        this.statuses[item.MEDIA_PLAN_ID] &&
        this.statuses[item.MEDIA_PLAN_ID] !==
          this.mediaPlanStatusMap.MEDIA_PLAN_CREATED.id &&
        this.statuses[item.MEDIA_PLAN_ID] !== 'FAILED'
      );
    },
    getClassName(item, title) {
      return this.isPlanInProgress(item) ||
        (this.onGoingPlanIds > 0 &&
          this.statuses[item.MEDIA_PLAN_ID] === 'FAILED' &&
          title === 'View Plan') ||
        (this.onGoingPlanIds > 0 && title === 'Edit Plan') ||
        !this.statuses[item.MEDIA_PLAN_ID]
        ? 'disabled'
        : '';
    },
    setOutputTimers(updatedOutput) {
      this.$store.dispatch(OUTPUT_SETTER, updatedOutput);
      localStorage.setItem(
        MEDIA_PLANNER_TIMERS_LC_KEY,
        JSON.stringify(updatedOutput.time)
      );
    },
    deleteTimerForPlan(planId) {
      if (this.timers[planId]) {
        const updatedTimer = { ...this.timers };
        delete updatedTimer[planId];
        this.timers = updatedTimer;
      }

      if (this.output.time[planId]) {
        const updatedOutput = { ...this.output };
        delete updatedOutput.time[planId];
        this.setOutputTimers(updatedOutput);
      }
    },
    async resetState() {
      try {
        const mediaPlannerConfig = { ...defaultMediaPlannerConfig };
        mediaPlannerConfig.name = `Media Plan (${moment().format(
          'DD MMM YY HH:mm:ss'
        )})`;
        await this.$store.dispatch(
          MEDIA_PLANNER_CREATION_DATA_SETTER,
          mediaPlannerConfig
        );
        await this.$store.dispatch(FILTERED_HISTORICAL_DATA_SETTER, {
          loading: false,
          data: {}
        });
        await this.$store.dispatch(OVERALL_HISTORICAL_DATA_SETTER, {
          loading: false,
          data: {}
        });
        await this.$store.dispatch(INTERNAL_CATEGORIZATIONS_DATA_SETTER, []);
        await this.$store.dispatch(RETAILER_CATEGORIZATIONS_DATA_SETTER, []);
        await this.$store.dispatch(
          EXPECTED_GROWTH_SETTER,
          defaultState.expectedGrowth
        );
      } catch (err) {
        throw Error(err);
      }
    },
    generatePlan() {
      this.resetState().then(() => {
        this.$router.push({ name: 'Generate Media Plan' });
      });
    },
    groupByGoal() {
      this.isGroupByGoal = !this.isGroupByGoal;
    },
    deletePlan(planId) {
      this.$store.dispatch(DELETE_MEDIA_PLAN_ACTION, {
        planId
      });
    },
    async updateStoreData(planData) {
      const planConfig = getConfigFromPlan(planData);
      const storeData = { ...this.storeData, ...planConfig };
      storeData.mediaPlanId = planData.MEDIA_PLAN_ID;
      await this.$store.dispatch(MEDIA_PLANNER_CREATION_DATA_SETTER, storeData);
    },
    async editPlan(planData) {
      await this.resetState();
      await this.updateStoreData(planData);
      this.$router.push({
        name: 'Edit Media Plan',
        params: { id: planData.MEDIA_PLAN_ID },
        query: { step: 1 }
      });
    },
    async viewPlan(planData) {
      await this.resetState();
      await this.updateStoreData(planData);
      this.$router.push({
        name: 'Edit Media Plan',
        params: { id: planData.MEDIA_PLAN_ID },
        query: { step: 5 }
      });
    },
    viewDraftedPlans() {
      this.showDraftedPlans = true;
    },
    getPlanDescription(planData) {
      const planConfig = getConfigFromPlan(planData);
      return createPlanDescription(planConfig);
    },
    updateState(stateName, key, value) {
      const updatedState = { ...this[stateName] };
      updatedState[key] = value;
      this[stateName] = updatedState;
    },
    triggerTimers() {
      const updatedTimers = { ...this.timers };
      Object.keys(this.timers).forEach((planId) => {
        const { planTimer, estimatedTimeInSecs } = this.timers[planId];
        let timer = this.maxTime - planTimer;
        if (planTimer > this.maxTime) {
          updatedTimers[planId].maxTimeReached = true;
        }
        let minutes = parseInt(timer / 60, 10);
        let seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        updatedTimers[planId] = {
          ...updatedTimers[planId],
          percent: ((planTimer / estimatedTimeInSecs) * 100).toFixed(2),
          time: minutes + ':' + seconds,
          planTimer: planTimer + 1,
          estimatedTimeInSecs
        };
      });
      this.timers = updatedTimers;
    },
    retryPlan(planData) {
      const mediaPlanId = planData.MEDIA_PLAN_ID;
      this.updateState('retryingPlan', mediaPlanId, true);
      this.$store
        .dispatch(RETRY_GENERATING_PLAN_ACTION, {
          mediaPlanId
        })
        .then(() => {
          this.$store
            .dispatch(FETCH_STATUS_ACTION, { planIds: [mediaPlanId] })
            .then(() => {
              this.addPlanId = mediaPlanId;
              const updatedOutput = { ...this.$store.getters[OUTPUT_GETTER] };
              updatedOutput.time = {
                ...updatedOutput.time,
                [mediaPlanId]: new Date().getTime()
              };
              updatedOutput.statuses = {
                ...updatedOutput.statuses,
                [mediaPlanId]: 'POPULATING_FILTERS'
              };
              this.setOutputTimers(updatedOutput);
              this.updateState('timers', mediaPlanId, {
                planTimer: 0,
                estimatedTimeInSecs: this.maxTime
              });
              this.updateState('retryingPlan', mediaPlanId, false);
              if (!this.timerInterval) {
                this.timerInterval = setInterval(() => {
                  this.triggerTimers();
                }, 1000);
              }
            });
        });
    }
  }
};
</script>

<style lang="css" scoped>
.description {
  line-height: 32px;
}

.group-heading {
  margin: -16px -16px 16px -16px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.loader {
  height: 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: var(--percent);
  height: 15px;
  background-color: #007cf6;
}

.u-width-250px {
  width: 250px;
}

.u-spacing-ph-nl {
  margin: 24px -24px -24px -24px;
}
</style>
