import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import { newToBrandMetricsMeasuresList } from '@/components/pages/instacart/campaigns/constants.js';

function getFilters({ entityLabelMap, entity }) {
  return {
    emit: `instacart${entityLabelMap[entity]}sFilterApplied`,
    hasSearch: true,
    search: {
      width: '240px',
      enable: true,
      emit: `instacart${entityLabelMap[entity]}sSearchTriggered`,
      placeholder: `Search for ${entity === 'sku' ? 'a SKU' : 'an Ad Group'}`
    }
  };
}
function getSearchListener({ entityLabelMap, entity }) {
  return {
    [`instacart${entityLabelMap[entity]}sSearchTriggered`]: {
      action: `getInstacart${entityLabelMap[entity]}sChartData`,
      transform: function (config, data, that) {
        config.meta.plotSelections = [];
        if (data !== undefined) {
          config.meta.localFilters = [];
          if (data.length > 0) {
            config.meta.localFilters.push({
              dimensionName: 'search',
              operator: 'ILIKE',
              dimensionValue: data
            });
          }
        }
        return config;
      }
    }
  };
}
function getPlotRowsListener({ entityLabelMap, entity }) {
  return {
    [`instacart${entityLabelMap[entity]}sPlotSelectedRows`]: {
      action: `getInstacart${entityLabelMap[entity]}sChartData`,
      transform: function (config, data, that) {
        config.meta.plotSelections = [];
        if (data && data.length > 0) {
          for (const element of data) {
            config.meta.plotSelections.push({
              dimensionName: entity === 'sku' ? 'product_id' : 'ad_group_id',
              operator: 'ILIKE',
              dimensionValue:
                element[entity === 'sku' ? 'product_id' : 'ad_group_id']
            });
          }
        }
        return config;
      }
    }
  };
}
function getFiltersListener({ entityLabelMap, entity }) {
  return {
    [`instacart${entityLabelMap[entity]}sFilterApplied`]: {
      action: `getInstacart${entityLabelMap[entity]}sChartData`,
      transform: function (config, data, that) {
        config.meta.plotSelections = [];
        return config;
      }
    }
  };
}

function getPaginationListener({ entityLabelMap, entity }) {
  return {
    [`instacart${entityLabelMap[entity]}sPaginationTriggered`]: {
      action: `getInstacart${entityLabelMap[entity]}sChartData`,
      transform: function (config) {
        config.meta.plotSelections = [];
        return config;
      }
    }
  };
}
function chartWidgetData({
  map,
  entityLabelMap,
  entity,
  units,
  createBulkSearchObjectForWidget,
  getTagData,
  axes
}) {
  return {
    map: map,
    meta: {
      type: 'chart',
      key: 'mskusChart',
      axisFormatMap: units,
      action: `getInstacart${entityLabelMap[entity]}sChartData`,
      listen: {
        ...getFiltersListener({ entityLabelMap, entity }),
        ...getPlotRowsListener({ entityLabelMap, entity }),
        ...getSearchListener({ entityLabelMap, entity }),
        ...createBulkSearchObjectForWidget(
          `getInstacart${entityLabelMap[entity]}sChartData`,
          'chart',
          entity
        ),
        ...getPaginationListener({ entityLabelMap, entity })
      }
    },
    header: {
      show: false
    },
    footer: {
      date: entity === 'sku' ? 'campaigns_sku' : 'campaigns_adgroup'
    },
    body: {
      APIConfig: {
        cubeName: `instacart_campaigns_${
          entity === 'sku' ? 'sku' : 'ad_group'
        }_workbench`,
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: true,
        timeseriesDimension: 'report_date',
        pvpenabled: true,
        yoyenabled: false,
        measuresList: [
          'attributed_sales',
          'attributed_quantities',
          'acos',
          'spend',
          'roi',
          'clicks',
          'impressions',
          'ctr',
          'cpc',
          'avg_selling_price',
          'linear_attributed_sales',
          'linear_roas',
          'linear_attributed_quantities',
          'cvr',
          'linear_cvr',
          ...newToBrandMetricsMeasuresList
        ],
        groupByDimensionsList: [
          'campaign_id',
          'ad_group_id',
          ...(entity === 'sku' ? ['upc'] : [])
        ],
        orderByList: [
          {
            dimension: 'spend',
            direction: 'DESC'
          }
        ],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            entity === 'sku' ? 'upc' : 'ad_group_id'
          ]
        }
      },
      getTagUnitData(data) {
        let oReturn = {};
        let result = (data[0] || {}).RESULT;
        for (let i in result) {
          let translatedI = (map || {})[i] ? (map || {})[i] : i;
          oReturn[translatedI] = {
            invertTag2: commons.isInverted(i),
            tag1Unit: units[translatedI],
            tag2Unit: {
              suff: '%'
            }
          };
        }
        return oReturn;
      },
      getTagData,
      chart: {
        chartOptions: {
          legend: false,
          type: 'line',
          types: {
            Promotions: 'scatter'
          },
          xFormat: '%m/%d/%Y',
          timeseries: 'report_date',
          tooltip_format: units,
          axes: axes,
          show_axis_colors: true,
          axis_format: {
            y: {},
            y2: {}
          },
          xDataKey: 'x',
          grid: 'xy'
        }
      }
    }
  };
}

function tableWidgetData({
  entityLabelMap,
  entity,
  that,
  createBulkSearchObjectForWidget,
  getColumnDefinition
}) {
  return {
    meta: {
      type: 'table',
      key: 'mskusTable',
      action: `getInstacart${entityLabelMap[entity]}sTblData`,
      listen: {
        [`instacart${entityLabelMap[entity]}sFilterApplied`]: {
          action: `getInstacart${entityLabelMap[entity]}sTblData`,
          transform: function (config) {
            config.body.APIConfig.page = 1;
            return config;
          }
        },
        [`instacart${entityLabelMap[entity]}sSearchTriggered`]: {
          action: `getInstacart${entityLabelMap[entity]}sTblData`,
          transform: function (config, data, that) {
            config.body.APIConfig.page = 1;
            if (data !== undefined) {
              config.meta.localFilters = [];
              if (data.length > 0) {
                config.meta.localFilters.push({
                  dimensionName: 'search',
                  operator: 'ILIKE',
                  dimensionValue: data
                });
              }
            }
            return config;
          }
        },
        ...createBulkSearchObjectForWidget(
          `getInstacart${entityLabelMap[entity]}sTblData`,
          'table',
          entity
        )
      },
      paginationAction: `instacart${entityLabelMap[entity]}sPaginationTriggered`
    },
    header: {
      show: false
    },
    footer: {
      date: entity === 'sku' ? 'campaigns_sku' : 'campaigns_adgroup'
    },
    body: {
      APIConfig: {
        cubeName: `instacart_campaigns_${
          entity === 'sku' ? 'sku' : 'ad_group'
        }_workbench`,
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        measuresList: [
          'count',
          'attributed_sales',
          'attributed_quantities',
          'acos',
          'spend',
          'roi',
          'clicks',
          'impressions',
          'ctr',
          'cpc',
          'avg_selling_price',
          ...(entity === 'sku'
            ? [
                'latest_image_url',
                'latest_product_page_url',
                'sku_title',
                'brand',
                'status',
                'campaign_type',
                'search_text_id',
                'campaign_name',
                'product_size'
              ]
            : [
                'attributed_sales_percentage',
                'attributed_quantities_percentage',
                'spend_percentage',
                'bid_strength',
                'suggested_bid',
                'default_bid',
                'campaign_type',
                'search_text_id',
                'campaign_status',
                'status',
                'campaign_name'
              ]),
          'ad_group_name',
          'linear_attributed_sales',
          'linear_roas',
          'linear_attributed_quantities',
          'cvr',
          'linear_cvr',
          ...newToBrandMetricsMeasuresList
        ],
        groupByDimensionsList:
          entity === 'sku'
            ? ['upc', 'campaign_id', 'ad_group_id', 'product_id']
            : ['campaign_id', 'ad_group_id', 'sub_type'],
        dimensionList: [],
        orderByList: [
          {
            dimension: 'spend',
            direction: 'DESC'
          }
        ],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
            entity === 'sku' ? ['upc'] : ['ad_group_id']
        },
        limit: entity === 'sku' ? 15 : 10,
        page: 1
      },
      download: {
        fileName: 'SKUTableData',
        columnMap: getColumnDefinition(that, entity),
        action: 'downloadSKUTableData'
      },
      gridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        },
        domLayout: 'normal'
      },
      columnDefs: getColumnDefinition(that, entity),
      rowHeight: entity === 'sku' ? 80 : 70
    }
  };
}

export default {
  config: (entity) => (that) => {
    const entityLabelMap = {
      sku: 'SKU',
      adGroups: 'AdGroup'
    };
    const {
      map,
      units,
      axes,
      getTagData,
      createBulkSearchObjectForWidget,
      getColumnDefinition,
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb
    } = utilConfig('instacart', entity);
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) => getColumnDefinition(...args, entity),
      filters: { ...getFilters({ entityLabelMap, entity }) },
      widgets: {
        widget1: {
          ...chartWidgetData({
            map,
            entityLabelMap,
            entity,
            units,
            axes,
            getTagData,
            createBulkSearchObjectForWidget
          })
        },
        widget2: {
          ...tableWidgetData({
            entityLabelMap,
            entity,
            that,
            createBulkSearchObjectForWidget,
            getColumnDefinition
          })
        }
      }
    };
  }
};
