import moment from 'moment-timezone';
import { getYearsToAdd } from '@/pages/budget-planner-v2/create-plan/store/helpers/utils.js';

export const savePayload = ({
  strategyName,
  description,
  retailer,
  selectedLevels,
  entities,
  categorizationType,
  isNextYearPlan = false,
  isAutomationsEnabled = false
}) => {
  return {
    strategyName,
    isAutomationsEnabled,
    description,
    strategyType: 'BUDGET_OPTIMIZER_OMNI',
    rules: null,
    userName: window.user.email,
    targetType: 'STATIC',
    strategyActive: true,
    strategyStatus: 'LIVE',
    strategyStartDate: moment()
      .subtract(1, 'years')
      .add(getYearsToAdd(isNextYearPlan), 'years')
      .endOf('year')
      .toISOString(),
    strategyEndDate: moment()
      .add(getYearsToAdd(isNextYearPlan, 2), 'years')
      .startOf('year')
      .toISOString(),
    timezone: 'GMT',
    actions: [
      {
        actionId: '',
        actionIndex: 0,
        deleted: false,
        executionTemplate: {
          entities,
          selectedLevels,
          categorizationType,
          lastUpdated: moment(),
          lastUpdatedBy: window.user.email,
          type: 'budgetOptimizerOmni',
          storageLink: ''
        }
      }
    ],
    entities: [],
    scope: {
      subFilter: null,
      scopeRules: null
    },
    strategyEntityType: null,
    objective: null,
    lookBackWindow: null,
    strategyInfoText: null,
    simulationData: null,
    system: 'advertising',
    retailer
  };
};
