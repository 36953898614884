import { commons } from '../commons';
import { filters } from '@ciq-dev/ciq-ui';

import utilConfig from '@/components/ams/campaigns/utils.js';
const {
  map,
  units,
  axes,
  baseMeasuresList,
  getTagData,
  createBulkSearchObjectForWidget,
  getColumnDefinition,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('amazon', 'sku');

export default {
  config: (that) => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) => getColumnDefinition(...args, 'sku'),
      filters: {
        emit: 'workbenchMSKUsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'workbenchMSKUsSearchTriggered',
          placeholder: 'Search for a SKU'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'mskusChart',
            axisFormatMap: units,
            action: 'getWorkbenchMSKUsChartData',
            listen: {
              workbenchMSKUsFilterApplied: {
                action: 'getWorkbenchMSKUsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              workbenchMSKUsPlotSelectedRows: {
                action: 'getWorkbenchMSKUsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data && data.length > 0) {
                    for (const element of data) {
                      config.meta.plotSelections.push({
                        dimensionName: 'search_text_id',
                        operator: 'ILIKE',
                        dimensionValue: element.search_text_id
                      });
                    }
                  }
                  return config;
                }
              },
              workbenchMSKUsSearchTriggered: {
                action: 'getWorkbenchMSKUsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchMSKUsChartData',
                'chart',
                'sku'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchMSKUsChartData',
                'chart',
                'campaign',
                'Skus'
              ),
              workbenchMSKUsPaginationTriggered: {
                action: 'getWorkbenchMSKUsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_asin'
          },
          body: {
            APIConfig: {
              cubeName: 'ams_campaigns_asin_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'report_date',
              timeseriesRollupBy: 'WEEK',
              daterangeDimension: 'feed_date',
              pvpenabled: true,
              yoyenabled: false,
              customAPIDecisionVars: { system: 'amazon_campaign_management' },
              measuresList: [
                ...baseMeasuresList,
                'total_sales',
                'pcogs',
                'inventory_latest',
                'avg_selling_price',
                'lbb_rate',
                'rep_oos',
                'change_in_traffic_percentage',
                'change_in_conversion_percentage',
                'promotion_messages_daily',
                'active_promo',
                'ordered_units',
                'sub_category_rank',
                'attributedSales14dSameSKU',
                'iacos',
                'attributedSalesNewToBrand14d',
                'PercentageSalesNewToBrand14d',
                'attributedOrdersNewToBrand14d',
                'attributedUnitsOrderedNewToBrand14d',
                'acogs'
              ],
              groupByDimensionsList: ['asin', 'campaign_name'],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              dedupBeforeRollup: {
                enableDedupBeforeRollup: false,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin']
              }
            },
            getTagUnitData(data) {
              let oReturn = {};
              let result = (data[0] || {}).RESULT;
              for (let i in result) {
                let translatedI = (map || {})[i] ? (map || {})[i] : i;
                oReturn[translatedI] = {
                  invertTag2: commons.isInverted(i),
                  tag1Unit: units[translatedI],
                  tag2Unit: {
                    suff: '%'
                  }
                };
              }
              return oReturn;
            },
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                types: {
                  Promotions: 'scatter'
                },
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                axes: axes,
                show_axis_colors: true,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy'
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'mskusTable',
            action: 'getWorkbenchMSKUsTableData',
            listen: {
              workbenchMSKUsFilterApplied: {
                action: 'getWorkbenchMSKUsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              workbenchMSKUsSearchTriggered: {
                action: 'getWorkbenchMSKUsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchMSKUsTableData',
                'table',
                'sku'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchMSKUsTableData',
                'table',
                'campaign',
                'Skus'
              )
            },
            paginationAction: 'workbenchMSKUsPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_asin'
          },
          body: {
            APIConfig: {
              cubeName: 'ams_campaigns_asin_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              timeseriesDimension: 'report_date',
              customAPIDecisionVars: { system: 'amazon_campaign_management' },
              pvpenabled:
                filters.config_check('feature.pages.enableRMMAmazonPVP') ||
                false,
              yoyenabled: false,
              measuresList: [
                ...baseMeasuresList,
                'ad_id',
                'adgroup_id',
                'tactic',
                'count',
                'total_sales',
                'pcogs',
                'avg_selling_price',
                'inventory',
                'inventory_latest',
                'latest_offer_price',
                'latest_image_url',
                'latest_product_page_url',
                'subscribe_and_save_price',
                'promotion_types',
                'promotion_messages',
                'category_1',
                'category_1_best_seller_rank',
                'category_2',
                'category_2_best_seller_rank',
                'category_3',
                'category_3_best_seller_rank',
                'category_4',
                'category_4_best_seller_rank',
                'avg_product_rating',
                'total_review_count',
                'new_review_count',
                'length_of_title',
                'length_of_description',
                'amazon_revenue',
                'asin_name',
                'category',
                'subcategory',
                'brand',
                'status',
                'campaign_type',
                'ordered_units',
                'campaign_id',
                'search_text_id',
                'rep_oos',
                'lbb_rate',
                'ad_serving_status',
                'sub_category_rank',
                'attributedSales14dSameSKU',
                'iacos',
                'attributedSalesNewToBrand14d',
                'PercentageSalesNewToBrand14d',
                'attributedOrdersNewToBrand14d',
                'attributedUnitsOrderedNewToBrand14d',
                'acogs',
                'organic_sales'
              ],
              groupByDimensionsList: [
                'asin',
                'campaign_name',
                'profile_id',
                'portfolio_id',
                'portfolio_name',
                'profile_name',
                'adgroup_name'
              ],
              dimensionList: [],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              dedupBeforeRollup: {
                enableDedupBeforeRollup: false,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin']
              },
              limit: 15,
              page: 1
            },
            download: {
              fileName: 'SKUTableData',
              columnMap: getColumnDefinition(that, 'sku'),
              action: 'downloadSKUTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            metadata: {
              primaryKey: 'ad_id',
              stateKey: 'skusRealTimeMetadata',
              apiConfig: {
                cubeName: 'ams_campaigns_asins_workbench_metadata',
                measuresList: ['status'],
                groupByDimensionsList: ['ad_id'],
                orderByList: [
                  {
                    dimension: 'status',
                    direction: 'DESC'
                  }
                ],
                where: {},
                tagWhereClause: []
              }
            },
            columnDefs: getColumnDefinition(that, 'sku'),
            rowHeight: 80
          }
        }
      }
    };
  }
};
