<template>
  <rb-select
    :send-details="true"
    :on-close="onStateChange"
    class="valueDropdown is-mobile-modal u-spacing-mt-s u-border-none"
    :options="dropDownOptions"
  >
    <div
      slot="trigger"
      :class="{ 'u-cursor-pointer': !isLoading, 'no-click': isLoading }"
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <div
        class="u-display-flex u-flex-direction-column u-flex-align-items-center show_on_hover"
      >
        <rb-icon
          v-if="isLoading"
          class="rb-button__icon rotate"
          :icon="'spinner'"
        />
        <div v-else>
          <!-- eslint-disable -->
          <icon-text-actions
            v-if="this.currentState === 'LIVE'"
            class="u-cursor-pointer u-width-100"
            mode="default"
            :action="{ title: currentState, icon: 'task-status' }"
            :class-name="'u-color-green-base'"
            :apply-padding="false"
          />

          <icon-text-actions
            v-if="this.currentState === 'ARCHIVED'"
            class="u-cursor-pointer u-width-100"
            mode="default"
            :action="{ title: currentState, icon: 'task-status' }"
            :class-name="'u-color-red-base'"
            :apply-padding="false"
          />
          <!-- eslint-enable -->
        </div>
      </div>
    </div>
  </rb-select>
</template>

<script>
import iconTextActions from '@/components/widgets/iconTextActions.vue';

export default {
  name: 'RbActionSelect',
  components: {
    iconTextActions
  },
  props: {
    state: {
      type: String,
      default: 'LIVE'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentState: this.state,
      // availableOptions: ['LIVE', 'PAUSED', 'ARCHIVED']
      availableOptions: ['LIVE', 'ARCHIVED']
    };
  },
  computed: {
    dropDownOptions() {
      const itemsToShow = this.availableOptions.filter((item) => {
        return item !== this.currentState;
      });
      const dropDownOptions = itemsToShow.map((item) => {
        return { title: item };
      });
      return dropDownOptions;
    }
  },
  methods: {
    onStateChange(context, selectedItem) {
      this.currentState = selectedItem[0].title;
      this.$emit('stateChange', this.currentState);
    }
  }
};
</script>

<style scoped lang="css">
.valueDropdown {
  border: none !important;
}

.no-click {
  cursor: not-allowed;
  pointer-events: none;
}

.dropDownContainer .caretIcon {
  visibility: hidden;
}
.dropDownContainer:hover .caretIcon {
  visibility: visible;
}
</style>
