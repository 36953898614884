import TableDataService from '@/pages/campaign-management/services/table-data-service.js';

export default class CriteoKeywordsTableDataService extends TableDataService {
  getRowsData(entityData) {
    const data = entityData.map((item) => ({
      ...item.DIMENSION,
      ...item.RESULT,
      ...item.PVP
    }));
    //  temporary fix until BE can flow the status metric
    data.status = data.keyword_status;
    return data;
  }
}
