import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import { CubeConfig } from '@/components/ams/campaigns/keywords/cubeConfig.js';
import utils from '@/utils/helpers/';

const {
  map,
  units,
  axes,
  columnDefs,
  baseMeasuresList,
  getTagData,
  createBulkSearchObjectForWidget,
  getColumnDefinition,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('amazon', 'keyword');

const workbenchKeywordsPlotSelectedRows = {
  action: 'getWorkbenchKeywordsChartData',
  transform: function (config, data, that) {
    config.meta.plotSelections = [];
    if (data && data.length > 0) {
      for (const element of data) {
        config.meta.plotSelections.push({
          dimensionName: 'keyword_id',
          operator: 'ILIKE',
          dimensionValue: element.keyword_id
        });
      }
    }
    return config;
  }
};

const workbenchKeywordsSearchTriggered = {
  action: 'getWorkbenchKeywordsChartData',
  transform: function (config, data, that) {
    config.meta.plotSelections = [];
    if (data !== undefined) {
      config.meta.localFilters = [];
      if (data.length > 0) {
        config.meta.localFilters.push({
          dimensionName: 'search',
          operator: 'ILIKE',
          dimensionValue: data
        });
      }
    }
    return config;
  }
};

const getTagUnitData = (data) => {
  let oReturn = {};
  let result = data?.[0]?.RESULT || {};
  for (let i in result) {
    let translatedI = map?.[i] ? map[i] : i;
    oReturn[translatedI] = {
      invertTag2: commons.isInverted(i),
      tag1Unit: units[translatedI],
      tag2Unit: {
        suff: '%'
      }
    };
  }
  return oReturn;
};

export default {
  config: () => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) => getColumnDefinition(...args, 'keyword'),
      filters: {
        emit: 'workbenchKeywordsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'workbenchKeywordsSearchTriggered',
          placeholder: 'Search for a Keyword'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'keywordChart',
            axisFormatMap: units,
            action: 'getWorkbenchKeywordsChartData',
            streamMetricTooltip: `<span class="u-font-weight-600">Real-time Data</span> sourced from Amazon Marketing Stream (Experimental),may be altered by Amazon within 72 hours. Only data from the previous hours, updated hourly, is available.`,
            listen: {
              workbenchKeywordsFilterApplied: {
                action: 'getWorkbenchKeywordsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              workbenchKeywordsPlotSelectedRows,
              workbenchKeywordsSearchTriggered,
              ...createBulkSearchObjectForWidget(
                'getWorkbenchKeywordsChartData',
                'chart',
                'keyword'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchKeywordsChartData',
                'chart',
                'campaign',
                'Keywords'
              ),
              workbenchKeywordPaginationTriggered: {
                action: 'getWorkbenchKeywordsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_keyword'
          },
          body: {
            APIConfig: {
              getPointInTimeMetrics: false,
              yoyenabled: false,
              measuresList: [],
              cubeName: 'ams_keyword_campaign_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesDimension: 'report_date',
              daterangeDimension: 'report_date',
              disableShard: false,
              timeseriesEnabled: true,
              pvpenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              getSharePercentage: false,
              adjustPvpDateByMaxDate: false,
              commonFilterEnabled: false,
              bundleCubeExecutionRequest: {
                adv_metrics: {
                  timeseriesDimension: 'report_date',
                  unmodifiedTimeseriesDimension: 'feed_date',
                  cubeName: 'ams_keyword_campaign_workbench',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: true,
                  pvpenabled: true,
                  pvptimeseriesEnabled: false,
                  sharePercentageV2: false,
                  splyenabled: false,
                  pointInTimeMetrics: false,
                  daterangeDimension: 'report_date',
                  disableShard: false,
                  enableNewPVPFormulaForSOV: false,
                  flowResidueEntity: false,
                  getPointInTimeMetrics: false,
                  getSharePercentage: false,
                  adjustPvpDateByMaxDate: false,
                  commonFilterEnabled: false,
                  customAPIDecisionVars: {
                    enableForecastSkuLevelWidget: false,
                    enableShareOfVoice: false,
                    ignoreMeasuresWhileDeduping: {},
                    jspEnabled: false,
                    preQueriesMap: {},
                    system: 'amazon_campaign_management',
                    additionalWhereClause: false,
                    anchoredBrand: null,
                    chainingJoinEnabled: false,
                    sovDataGroup: false,
                    timeSeriesDimensionAggregateRequest: null,
                    timeSeriesDimensionEnabled: false,
                    totalClientBrandsForDigitalShelf: 0,
                    totalCompBrandsForDigitalShelf: 0,
                    downloadRequest: null,
                    enableChartsSku: false,
                    enableDigitalShelf: false,
                    enableFetchFromShards: false,
                    queryReplacementRequest: null,
                    skipExternalCount: false,
                    skipLimitAndOffset: false,
                    skipTimeSeriesMetric: false,
                    type: null,
                    totalOthersBrandsForDigitalShelf: 0,
                    contentScorecardRequest: null
                  },
                  yoyenabled: false,
                  measuresList: [
                    ...baseMeasuresList,
                    'total_sales',
                    'organic_sales',
                    'pcogs',
                    'base_bid',
                    'inventory',
                    'avg_selling_price',
                    'customer_orders',
                    'attributedSalesNewToBrand14d',
                    'PercentageSalesNewToBrand14d',
                    'attributedOrdersNewToBrand14d',
                    'attributedUnitsOrderedNewToBrand14d',
                    'search_frequency_rank',
                    'incremental_fraction',
                    'incremental_fraction_band',
                    'iroas',
                    'incremental_sales',
                    'incrementality_spend',
                    'INCREMENTALITY_PAID_SALES_14D'
                  ],
                  groupByDimensionsList: [
                    'keyword_text',
                    'campaign_name',
                    'match_type',
                    'keyword_id'
                  ],
                  orderByList: [],
                  tagWhereClause: [],
                  where: {}
                },
                sov_metrics: {
                  timeseriesDimension: 'report_date',
                  unmodifiedTimeseriesDimension: 'feed_date',
                  cubeName: 'ams_keyword_campaign_workbench',
                  getLatestAvailableInsteadOfRollup: false,
                  pointInTimeMetrics: false,
                  daterangeDimension: 'report_date',
                  disableShard: false,
                  enableNewPVPFormulaForSOV: false,
                  flowResidueEntity: false,
                  commonFilterEnabled: false,
                  customAPIDecisionVars: {
                    enableChartsSku: false,
                    enableDigitalShelf: false,
                    enableFetchFromShards: false,
                    enableForecastSkuLevelWidget: false,
                    enableShareOfVoice: false,
                    ignoreMeasuresWhileDeduping: {},
                    jspEnabled: false,
                    preQueriesMap: {},
                    timeSeriesDimensionAggregateRequest: null,
                    timeSeriesDimensionEnabled: false,
                    totalClientBrandsForDigitalShelf: 0,
                    skipExternalCount: false,
                    skipLimitAndOffset: false,
                    skipTimeSeriesMetric: false,
                    sovDataGroup: false,
                    totalCompBrandsForDigitalShelf: 0,
                    totalOthersBrandsForDigitalShelf: 0,
                    type: null,
                    system: 'amazon_campaign_management',
                    additionalWhereClause: false,
                    anchoredBrand: null,
                    chainingJoinEnabled: false,
                    contentScorecardRequest: null,
                    downloadRequest: null,
                    queryReplacementRequest: null
                  },
                  getPointInTimeMetrics: false,
                  getSharePercentage: false,
                  adjustPvpDateByMaxDate: false,
                  yoyenabled: false,
                  timeseriesEnabled: true,
                  pvpenabled: false,
                  pvptimeseriesEnabled: false,
                  sharePercentageV2: false,
                  splyenabled: false,
                  measuresList: [
                    'share_of_organic_search',
                    'share_of_sponsered_search',
                    'organic_search_count',
                    'total_organic_search_count',
                    'total_sponsored_search_count',
                    'sponsored_search_count',
                    'share_of_sponsered_brand_search',
                    'header_search_count',
                    'total_header_search_count'
                  ],
                  groupByDimensionsList: ['keyword_text'],
                  orderByList: [],
                  dedupBeforeRollup: {
                    enableDedupBeforeRollup: true,
                    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                      ['report_date']
                  },
                  tagWhereClause: [],
                  where: {
                    dimensionNameValueList: [],
                    date: {
                      from: '2023-01-01',
                      to: '2023-01-01',
                      name: 'last30Days',
                      page_wise_min_max_key: 'ams_keyword'
                    },
                    pvpDate: {
                      from: '2023-01-01',
                      to: '2023-01-01',
                      compare_name: null
                    }
                  }
                }
              },
              customAPIDecisionVars: {
                ignoreMeasuresWhileDeduping: {},
                jspEnabled: false,
                preQueriesMap: {},
                system: 'amazon_campaign_management',
                contentScorecardRequest: null,
                timeSeriesDimensionEnabled: false,
                totalClientBrandsForDigitalShelf: 0,
                totalCompBrandsForDigitalShelf: 0,
                totalOthersBrandsForDigitalShelf: 0,
                additionalWhereClause: false,
                anchoredBrand: null,
                chainingJoinEnabled: false,
                downloadRequest: null,
                enableChartsSku: false,
                enableDigitalShelf: false,
                queryReplacementRequest: null,
                skipExternalCount: false,
                skipLimitAndOffset: false,
                skipTimeSeriesMetric: false,
                sovDataGroup: false,
                timeSeriesDimensionAggregateRequest: null,
                enableFetchFromShards: false,
                enableForecastSkuLevelWidget: false,
                enableShareOfVoice: false,
                type: null
              },
              splyenabled: false,
              pointInTimeMetrics: false,
              enableNewPVPFormulaForSOV: false,
              flowResidueEntity: false,
              groupByDimensionsList: [],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              tagWhereClause: []
            },
            SreamAPIConfig: {
              cubeName: 'amazon_hourly_keywords_stream_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'hour',
              timeseriesRollupBy: 'DAY',
              pvpenabled: false,
              yoyenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              splyenabled: false,
              getPointInTimeMetrics: false,
              getSharePercentage: false,
              pointInTimeMetrics: false,
              daterangeDimension: 'report_date',
              measuresList: [
                'spend',
                'impressions',
                'clicks',
                'units_1d',
                'units_7d',
                'units_14d',
                'orders_1d',
                'orders_7d',
                'orders_14d',
                'paid_sales_1d',
                'paid_sales_7d',
                'paid_sales_14d',
                'ctr',
                'conversions_1d',
                'conversions_7d',
                'conversions_14d',
                'acos_1d',
                'acos_7d',
                'acos_14d',
                'cpc',
                'halo_sales_1_day',
                'halo_sales_7_day',
                'halo_sales_14_day',
                'average_order_value'
              ],
              groupByDimensionsList: [
                'keyword_text',
                'campaign_name',
                'match_type',
                'keyword_id'
              ],
              customAPIDecisionVars: {
                system: 'amazon_campaign_management'
              },
              where: {},
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ]
            },
            getTagUnitData,
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                axes: axes,
                line: {
                  connectNull: true
                },
                show_axis_colors: true,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy',
                realtime: false
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'keywordTable',
            primaryKey: 'keyword_id',
            action: 'getWorkbenchKeywordsTableData',
            listen: {
              workbenchKeywordsFilterApplied: {
                action: 'getWorkbenchKeywordsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              workbenchKeywordsSearchTriggered: {
                action: 'getWorkbenchKeywordsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchKeywordsTableData',
                'table',
                'keyword'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchKeywordsTableData',
                'table',
                'campaign',
                'Keywords'
              )
            },
            paginationAction: 'workbenchKeywordPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_keyword'
          },
          body: {
            metadata: utils.internalUserCheck(window.user) && {
              primaryKey: 'keyword_id',
              stateKey: 'keywrodsRealTimeMetadata',
              apiConfig: {
                cubeName: 'ams_keyword_campaign_workbench_metadata',
                measuresList: ['base_bid', 'status'],
                groupByDimensionsList: ['keyword_id'],
                orderByList: [
                  {
                    dimension: 'base_bid',
                    direction: 'DESC'
                  }
                ],
                where: {},
                tagWhereClause: []
              }
            },
            stream: {
              cubeName: 'amazon_hourly_keywords_stream_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'hour',
              timeseriesRollupBy: 'DAY',
              pvpenabled: false,
              yoyenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              splyenabled: false,
              getPointInTimeMetrics: false,
              getSharePercentage: false,
              pointInTimeMetrics: false,
              daterangeDimension: 'report_date',
              measuresList: [
                'spend',
                'impressions',
                'clicks',
                'units_1d',
                'units_7d',
                'units_14d',
                'orders_1d',
                'orders_7d',
                'orders_14d',
                'paid_sales_1d',
                'paid_sales_7d',
                'paid_sales_14d',
                'ctr',
                'conversions_1d',
                'conversions_7d',
                'conversions_14d',
                'acos_1d',
                'acos_7d',
                'acos_14d',
                'cpc',
                'halo_sales_1_day',
                'halo_sales_7_day',
                'halo_sales_14_day',
                'average_order_value'
              ],
              groupByDimensionsList: [
                'keyword_text',
                'campaign_name',
                'match_type',
                'keyword_id'
              ],
              customAPIDecisionVars: {
                system: 'amazon_campaign_management'
              },
              where: {},
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              tagWhereClause: []
            },
            APIConfig: CubeConfig.request,
            download: {
              fileName: 'KeywordTableData',
              columnMap: columnDefs, // columnDefs is computed again in method "appendTableDefinitionToConfig" of index.vue and appended here
              action: 'downloadCampaignsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: columnDefs, // columnDefs is computed again in method "appendTableDefinitionToConfig" of index.vue and appended here
            rowHeight: 70
          }
        }
      }
    };
  }
};
