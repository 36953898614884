<template>
  <div
    class="rb-data-table u-width-100 u-height-100 rb-insights-table-container"
    data-cy="tableContainer"
    :class="{ 'ag-row-no-hover': rowNoHoverState }"
  >
    <ag-grid-vue
      :id="tableId"
      style="width: 100%"
      :data-cy="'tableID' + getDataCYAttribute"
      :style="[
        customHeight &&
          (pagination && paginationTotalKey / paginationPerPageLimit > 0
            ? { height: '90%' }
            : { height: '100%' })
      ]"
      class="ag-theme-material u-display-flex u-flex-direction-column u-flex-1"
      :class="{ overflow: hasOverflow }"
      :grid-options="gridOptions"
      :default-col-def="defaultColDef"
      :row-data="rowData"
      :stop-editing-when-grid-loses-focus="stopEditingWhenGridLosesFocus"
      :pinned-bottom-row-data="pinnedBottomRowData"
      :column-defs="columnDefs"
      :enable-sorting="enableClientSideSorting"
      :enable-server-side-sorting="enableServerSideSorting"
      :row-height="rowHeight"
      :header-height="headerHeight"
      :suppress-column-virtualisation="suppressColumnVirtualisation"
      :suppress-movable-columns="suppressMovableColumns"
      :sorting-order="['asc', 'desc']"
      :enable-col-resize="enableColResize"
      :overlay-no-rows-template="noRowsTemplate"
      :overlay-loading-template="overlayLoadingTemplate"
      :grid-auto-height="gridAutoHeight"
      :row-selection="(rowSelection || {}).mode || 'multiple'"
      :selection-changed="onSelectionChanged"
      :suppress-row-click-selection="true"
      :single-click-edit="singleClickEdit"
      :enable-cell-text-selection="enableCellTextSelection"
      :icons="icons"
      :pagination-change-event="paginationChangeEvent"
      :is-full-width-cell="isFullWidthCell"
      :full-width-cell-renderer="fullWidthCellRenderer"
      :framework-components="frameworkComponents"
      :get-row-height="getRowHeight"
      :is-row-selectable="isRowSelectable"
      :row-drag-managed="true"
      :column-everything-changed="debouncedAutoResizeGrid"
      :displayed-columns-changed="debouncedAutoResizeGrid"
      @row-drag-enter="onRowDragLeave"
      @row-drag-end="onRowDragLeave"
      @row-drag-move="onRowDragLeave"
    />
    <!-- Round (paginationTotalKey/paginationPerPageLimit) to next integer, fix for page 1 not being displayed -->
    <div
      v-if="
        pagination &&
        Math.ceil(paginationTotalKey / paginationPerPageLimit) >= 1
      "
      class="u-border-top u-border-color-grey-xxx-light u-border-width-s u-spacing-ph-l u-spacing-pv-m paginationFooter u-overflow-auto u-display-flex u-flex-justify-content-flex-end u-flex-align-items-center"
      :data-cy="'pagination' + getDataCYAttribute"
    >
      <span
        v-if="showPaginationOptions"
        class="u-font-size-5 u-spacing-ph-s"
        data-cy="rowsPerPagetxt"
        >Rows per page:</span
      >
      <rb-select
        v-if="showPaginationOptions"
        :width="'70px'"
        :send-details="true"
        :on-close="paginationSelected"
        :options="selectedPaginationValues"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <span
            class="u-font-size-5"
            data-cy="paginationPerpageLimit"
            >{{ paginationPerPageLimit }}</span
          >
          <rb-icon
            class="rb-icon--small u-spacing-ml-xs"
            :icon="'caret-down'"
          />
        </div>
      </rb-select>
      <span
        class="u-font-size-5 u-spacing-pl-l u-spacing-pr-m"
        data-cy="totalNoOfPages"
        >{{ paginationObj.pageStart }} - {{ paginationObj.pageTotal }} /
        {{ paginationObj.total }}</span
      >
      <b-pagination
        :total="paginationTotalKey"
        :current.sync="config.body.APIConfig.page"
        :size="'is-small'"
        :simple="false"
        :rounded="true"
        :per-page="paginationPerPageLimit"
        @change="pageChangeEvent"
      />
    </div>
    <rb-download-button
      v-if="downloadThisTable"
      :ref="'downloadReference'"
      class="u-display-none"
      :config="config"
      :column-map="downloadColumnMap"
      :file-name="config.header && config.header.actions.download.fileName"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import rbPagination from '@/components/basic/pagination';
import { eventBus } from '@/utils/services/eventBus';
import { formatter } from '@/utils/helpers/formatter.js';
import helper from '@/utils/helpers/index.js';
import progressDisplay from '@/components/globals/dataTable/tableComponentsWrapper/progressDisplay';
import columnListCell from '@/components/globals/dataTable/tableComponentsWrapper/columnListCell';
import linkDisplay from '@/components/globals/dataTable/tableComponentsWrapper/linkDisplay';
import iconTableCell from '@/components/globals/dataTable/tableComponentsWrapper/iconTableCell';
import inputTypeCell from '@/components/globals/dataTable/tableComponentsWrapper/inputTypeCell';
import iconTableHeader from '@/components/globals/dataTable/tableComponentsWrapper/iconTableHeader';
import metricDisplay from '@/components/globals/dataTable/tableComponentsWrapper/metricDisplay';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import transformer from '@/utils/services/data-transformer';
import tableExpandCell from '@/components/globals/dataTable/tableComponentsWrapper/tableExpandCell';
import addColumnHeader from '@/components/globals/dataTable/tableComponentsWrapper/addColumnHeader';
import cardCell from '@/components/globals/dataTable/tableComponentsWrapper/cardCell';
import asinTableHeader from '@/components/globals/dataTable/tableComponentsWrapper/asinTableHeader';
import pieChartTableHeader from '@/components/globals/dataTable/tableComponentsWrapper/pie-chart-header';
import entityTableHeader from '@/components/globals/dataTable/tableComponentsWrapper/entityTableHeader';
import { cloneDeep, debounce, isEmpty } from 'lodash';
// import alertLastUpdatedAtVue from '../alertLastUpdatedAt.vue';
/* eslint-disable vue/require-default-prop */

export default {
  name: 'RbInsightsTable',
  components: {
    helper,
    AgGridVue,
    rbPagination,
    eventBus,
    progressDisplay,
    linkDisplay,
    rbDownloadButton,
    metricDisplay,
    iconTableCell,
    inputTypeCell,
    iconTableHeader,
    tableExpandCell,
    addColumnHeader,
    cardCell,
    asinTableHeader,
    entityTableHeader,
    columnListCell,
    pieChartTableHeader
  },
  props: {
    dynamicColumnWidth: {
      default: () => {},
      type: Object
    },
    dynamicColumnWidthLimits: {
      default: () => ({
        pinned: {
          min: 86,
          max: 240,
          multiplier: 9
        },
        unpinned: {
          min: 86,
          max: 210,
          multiplier: 9
        }
      }),
      type: Object
    },
    stopEditingWhenGridLosesFocus: {
      type: Boolean,
      default: false
    },
    singleClickEdit: {
      type: Boolean,
      default: false
    },
    enableCellTextSelection: {
      type: Boolean,
      default: false
    },
    dataCYID: {
      type: String,
      default: ''
    },
    enableColResize: {
      type: Boolean,
      default: true
    },
    hasOverflow: {
      type: Boolean,
      default: false
    },
    suppressMovableColumns: {
      type: Boolean,
      default: true
    },
    suppressColumnVirtualisation: {
      type: Boolean,
      default: false
    },
    filterInstance: {
      type: Object,
      default: null
    },
    primaryFiltersData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    customHeight: {
      type: Boolean,
      default: true
    },
    rowNoHoverState: {
      type: Boolean,
      default: false
    },
    rowSelection: {
      type: Object,
      default: () => {}
    },
    gridOptions: {
      type: Object,
      default: () => {}
    },
    // eslint-disable-next-line vue/require-default-prop
    tableRow: Array,
    autoConfigure: Object,
    tableColumn: Array,
    tableColumnObject: Object,
    config: {
      type: Object,
      default: () => {
        return {
          body: {
            APIConfig: {
              page: 1
            }
          }
        };
      }
    },
    gridAutoHeight: {
      type: Boolean,
      default: false
    },
    rowHeight: {
      type: Number,
      default: 80
    },
    enableClientSideSorting: {
      type: Boolean,
      default: true
    },
    enableServerSideSorting: {
      type: Boolean,
      default: false
    },
    enableClientSidePagination: {
      type: Boolean,
      default: false
    },
    sortingChangeEvent: {
      type: String,
      default: ''
    },
    headerHeight: {
      type: Number,
      default: 52
    },
    noRowsMessage: {
      type: String,
      default: 'No Data'
    },
    overlayLoadingTemplate: {
      type: String,
      default: 'Loading...'
    },
    pagination: {
      type: Boolean,
      default: false
    },
    showPaginationOptions: {
      type: Boolean,
      default: false
    },
    paginationTotalKey: {
      type: Number,
      default: 0
    },
    paginationPerPageLimit: {
      type: Number,
      default: 5
    },
    clientSidePagination: {
      type: Boolean,
      default: false
    },
    paginationChangeEvent: {
      type: String,
      default: null
    },
    downloadThisTable: {
      type: Boolean,
      default: false
    },
    customTableObject: {
      type: Object,
      default: () => {}
    },
    rowClassRules: {
      type: Object,
      default: () => {}
    },
    rowClass: {
      type: String,
      default: null
    },
    rowStyle: {
      type: Object,
      default: () => {}
    },
    getCurrentInstance: {
      type: Function
    },
    primaryKey: {
      type: String
    },
    scrollToNodeId: {
      type: Function
    },
    tableHeaderStickyObj: {
      type: Object,
      default: () => {}
    },
    isFullWidthCell: {
      type: Function
    },
    fullWidthCellRenderer: {
      type: String
    },
    frameworkComponents: {
      type: Object,
      default: () => {}
    },
    getRowHeight: {
      type: Function
    },
    hasExpand: {
      type: Boolean,
      default: false
    },
    toolTipTextForExpandIcon: {
      type: String,
      default: ''
    },
    expandCellWidth: {
      type: Number,
      default: 80
    },
    levels: {
      type: Array,
      default: () => []
    },
    hasDefaultRowExpand: {
      type: Boolean,
      default: false
    },
    watchResizeColumn: {
      type: Boolean,
      default: false
    },
    isRowSelectable: {
      type: Function
    },
    rowPerPageList: {
      type: Array,
      default: () => []
    },
    customHasExpandColumnOrder: {
      type: Number,
      default: 0
    }
  },
  data() {
    let defaultColDef = {};
    if (this.rowSelection && this.rowSelection.mode) {
      defaultColDef = {
        headerCheckboxSelection:
          this.rowSelection && this.rowSelection.mode === 'single'
            ? null
            : this.isFirstColumn,
        checkboxSelection: this.isFirstColumn
      };
    }
    let paginationValues = [
      {
        title: 10
      },
      {
        title: 20
      },
      {
        title: 30
      },
      {
        title: 50
      },
      {
        title: 100
      },
      {
        title: 200
      },
      {
        title: 300
      }
    ];
    const selectedObj = {};
    let status = false;
    if (Number.isInteger(this.paginationPerPageLimit)) {
      for (const obj of paginationValues) {
        if (obj.title === this.paginationPerPageLimit) {
          status = true;
          break;
        }
      }
      selectedObj.title = this.paginationPerPageLimit;
      if (!status) {
        paginationValues.push(selectedObj);
        paginationValues = paginationValues.sort(function (a, b) {
          return a.title - b.title;
        });
      }
    }
    const selectedPaginationValues = Vue.options.filters.array_differentiator(
      paginationValues,
      [selectedObj]
    );
    return {
      debouncedAutoResizeGrid: null,
      boundAutoResizeGrid: null,
      icons: {
        checkboxChecked:
          '<span data-cy = "checkedCheckbox" style="margin:0;margin-right: 16px;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-selected u-color-blue-base u-display-flex u-flex-align-items-center"></span>',
        checkboxUnchecked:
          '<span data-cy = "uncheckCheckbox" style="margin:0;margin-right: 16px;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-empty u-color-grey-lighter u-display-flex u-flex-align-items-center"></span>',
        checkboxIndeterminate:
          '<span style="margin:0;margin-right: 16px;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-intermediate u-color-blue-base u-display-flex u-flex-align-items-center"></span>'
      },
      filterDimensionMeta: [],
      defaultColDef,
      paginationValues,
      selectedPaginationValues,
      computedTableRows: [],
      clientSidePaginatedRows: [],
      computedTableColumns: [],
      downloadColumnMap: [],
      columnsToProcess: undefined,
      isTableLoaded: false,
      expandStates: {},
      expandListnerKey: null,
      unwatch: null,
      localTableRow: [],
      unwatcherMap: {},
      defaultOpenerWorkDone: false,
      tableId: '',
      longestValues: {}
    };
  },
  computed: {
    getDataCYAttribute() {
      return this.dataCYID ? `-${this.dataCYID}` : '';
    },
    getClientSidePaginatedHeight() {
      let height = this.rowHeight * this.paginationPerPageLimit;
      height += this.headerHeight;
      return 'height:' + height + 'px';
    },
    rowData: {
      get() {
        if (!this.isEmptyObj(this.tableHeaderStickyObj)) {
          const eventObject = {
            target: {
              scrollTop: 0
            }
          };
          if (this.tableHeaderStickyObj.callOnNextTick) {
            this.$nextTick(() => {
              this.stickyMethod(eventObject);
            });
          } else {
            this.stickyMethod(eventObject);
          }
        }
        if (this.clientSidePagination) {
          return this.clientSidePaginatedRows;
        }
        if (this.columnsToProcess === undefined) {
          return this.computedTableRows;
        } else {
          return this.localTableRow;
        }
      },
      set(newValue) {
        this.rowData = newValue;
      }
    },
    paginationObj() {
      const total = this.paginationTotalKey;
      let currentPage = this.config.body?.APIConfig?.page;
      const pageTotal =
        this.paginationPerPageLimit * currentPage > total
          ? total
          : this.paginationPerPageLimit * currentPage;
      let pageStart =
        this.paginationPerPageLimit * (currentPage - 1) + 1 > total
          ? currentPage - 1
          : this.paginationPerPageLimit * (currentPage - 1) + 1;
      if (currentPage === 1 || pageStart === 1) {
        pageStart = 1;
        currentPage = 1;
      }
      return {
        total,
        currentPage,
        pageTotal,
        pageStart
      };
    },
    columnDefs() {
      const columnArray =
        this.columnsToProcess === undefined
          ? this.computedTableColumns
          : this.columnsToProcess;
      const tableColumn =
        columnArray &&
        columnArray.map((column) => {
          if (
            column.children &&
            column.children.constructor === Array &&
            column.children.length > 0
          ) {
            for (const childColumn of column.children) {
              if (
                childColumn.headerComponentParams &&
                childColumn.headerComponentParams.enableSorting
              ) {
                childColumn.headerComponentParams.onColumnSort =
                  this.onColumnSort;
              }
              if (!childColumn.suppressSizeToFit) {
                childColumn.suppressSizeToFit = false;
              }
              if (!childColumn.minWidth) {
                if (
                  childColumn.headerComponentParams &&
                  childColumn.headerComponentParams.keyType
                ) {
                  childColumn.minWidth = helper.setMinWidth(
                    childColumn.headerComponentParams
                  );
                }
              }
            }
          } else {
            if (
              column.headerComponentParams &&
              column.headerComponentParams.enableSorting
            ) {
              column.headerComponentParams.onColumnSort = this.onColumnSort;
            }
            if (
              column.headerComponentParams &&
              column.headerComponentParams.enableHeaderIconInterAction
            ) {
              column.headerComponentParams.defaultIconClickHandler =
                this.defaultIconClickHandler;
            }
            if (!column.suppressSizeToFit) {
              column.suppressSizeToFit = false;
            }
            if (!column.minWidth) {
              if (
                column.headerComponentParams &&
                column.headerComponentParams.keyType
              ) {
                column.minWidth = helper.setMinWidth(
                  column.headerComponentParams
                );
              }
            }
          }
          return column;
        });
      if (this.dynamicColumnWidth?.enable && !isEmpty(this.longestValues)) {
        return this.setDynamicColumnWidth(tableColumn, this.dynamicColumnWidth);
      }
      return tableColumn || [];
    },
    noRowsTemplate() {
      return `<span>${this.noRowsMessage}</span>`;
    },
    pinnedBottomRowData() {
      return this.pinBottomRowData;
    }
  },
  watch: {
    primaryFiltersData(value) {
      this.filterDimensionMeta = value;
    },
    tableRow: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.computedTableRows = [...(newValue || [])];
        this.setTableId();
        // handing error - insights/price-war widgets.
        this.localTableRow = [...(newValue || [])];
        if (this.dynamicColumnWidth?.enable) {
          this.computeLongestValues();
        }
        if (this.hasExpand) {
          this.expandListnerKey = this.tableId + '-listener';
          eventBus.$on(this.expandListnerKey, this.expandHandler);
          this.setLevel(this.localTableRow || [], 0);
          this.tableColumn = this.addExpandCell(this.tableColumn);
        }
        if (this.hasDefaultRowExpand) {
          this.addDefaultRowExpand();
        }
      }
    },
    paginationObj(newVal) {
      this.performClientSidePagination(newVal);
    },
    computedTableRows: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.performClientSidePagination(this.paginationObj);
        }
      },
      deep: true
    },
    autoConfigure: function (data) {
      if (!data) {
        return;
      }
      if (this.isTableLoaded === false) {
        this.initializeTable();
      } else {
        this.getRowData();
      }
    },
    customTableObject: function (data) {
      if (this.autoConfigure !== undefined) {
        this.initializeTable();
      }
    },
    tableColumnObject: 'initialize',
    tableColumn: {
      handler: function (newTableColumn) {
        this.columnsToProcess = [];
        this.columnsToProcess = this.addExpandCell(newTableColumn);
      }
    },
    rowPerPageList: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        if (Array.isArray(newVal) && newVal.length > 0) {
          this.paginationValues = [...newVal];
          this.selectedPaginationValues = cloneDeep(newVal) || [];
        }
      }
    }
  },
  created() {
    this.setTableId();
    if (this.hasExpand) {
      this.localTableRow = this.tableRow ? [...this.tableRow] : [];
      this.expandListnerKey = this.tableId + '-listener';
      eventBus.$on(this.expandListnerKey, this.expandHandler);
      this.setLevel(this.localTableRow || [], 0);
      this.tableColumn = this.addExpandCell(this.tableColumn);
      if (this.hasDefaultRowExpand) {
        this.addDefaultRowExpand();
      }
    }
    this.initialize();
    if (this.getCurrentInstance) {
      this.getCurrentInstance(this);
    }
    this.debouncedAutoResizeGrid = debounce(this.autoResizeGrid, 500);
  },
  mounted() {
    if (!this.isEmptyObj(this.tableHeaderStickyObj)) {
      this.tableHeaderSticky();
    }
    this.$nextTick(() => {
      const tableHeaderInstances = document.querySelectorAll(
        '.ag-header-viewport'
      );
      const rowDataColumn = document.querySelectorAll('.ag-body-viewport');
      const previousScroll = [];
      if (tableHeaderInstances) {
        for (
          let tableHeaderInstance = 0;
          tableHeaderInstance < tableHeaderInstances.length;
          tableHeaderInstance++
        ) {
          previousScroll[tableHeaderInstance] =
            tableHeaderInstances[tableHeaderInstance].getBoundingClientRect();
          tableHeaderInstances[tableHeaderInstance].addEventListener(
            'wheel',
            (event) => {
              if (
                previousScroll[tableHeaderInstance].y ===
                tableHeaderInstances[
                  tableHeaderInstance
                ].getBoundingClientRect().y
              ) {
                rowDataColumn[tableHeaderInstance].scrollLeft += event.deltaX;
              }
              previousScroll[tableHeaderInstance] =
                tableHeaderInstances[
                  tableHeaderInstance
                ].getBoundingClientRect();
            }
          );
        }
      }
    });
  },
  destroyed() {
    if (this.expandListnerKey) {
      eventBus.$off(this.expandListnerKey);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.boundAutoResizeGrid);
    if (!this.isEmptyObj(this.tableHeaderStickyObj)) {
      document
        .querySelector(this.tableHeaderStickyObj.scrollAbleSelector)
        ?.removeEventListener('scroll', this.stickyMethod);
    }
    document
      .querySelector('.ag-header-container .ag-header-row')
      ?.removeEventListener('wheel');
  },
  methods: {
    computeLongestValues() {
      const longestValues = {};
      for (const item of this.localTableRow) {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const valueLength = String(item[key]).length;
            longestValues[key] = Math.max(longestValues[key] || 0, valueLength);
          }
        }
      }
      this.longestValues = longestValues;
      this.gridOptions?.api?.setColumnDefs?.(cloneDeep(this.columnDefs));
    },
    setDynamicColumnWidth(tableColumn, dynamicColumnWidth) {
      const numberTypes = ['currency', 'numeric', 'percentage'];
      const cols = (tableColumn || []).map((item) => {
        if (
          this.longestValues?.[item.field] &&
          !dynamicColumnWidth?.blackListFields?.includes(item.field)
        ) {
          const widthLimit = item.pinned
            ? this.dynamicColumnWidthLimits.pinned
            : this.dynamicColumnWidthLimits.unpinned;
          const minValue = widthLimit.min;
          const maxValue = widthLimit.max;
          const multiplier = widthLimit.multiplier;
          const isColumnNumberType = numberTypes?.includes(
            item.headerComponentParams?.keyType.toLowerCase()
          );
          let value = isColumnNumberType
            ? minValue
            : this.longestValues?.[item.field] * multiplier;
          value = value > maxValue ? maxValue : value;
          value = value < minValue ? minValue : value;
          item.width = value;
          item.minWidth = value;
          item.maxWidth = maxValue;
        }
        return item;
      });
      return cols;
    },
    onRowDragLeave(e) {
      console.log('onRowDragLeave', e);
    },
    performClientSidePagination(paginationObj) {
      if (this.clientSidePagination) {
        this.clientSidePaginatedRows = this.computedTableRows.slice(
          paginationObj.pageStart - 1,
          paginationObj.pageTotal
        );
      }
    },
    adjustColumns() {
      this.gridOptions.api.sizeColumnsToFit();
    },
    paginationSelected(context, val) {
      if (val && val.length === 1) {
        if (val[0].selectedIndex) {
          delete val[0].selectedIndex;
        }
        this.selectedPaginationValues =
          Vue.options.filters.array_differentiator(this.paginationValues, val);
        this.paginationChangeEvent &&
          eventBus.$emit(this.paginationChangeEvent, 1);
        this.config.body.APIConfig.page = 1;
        this.config.body.APIConfig.limit = val[0].title;
        if (this.config?.meta?.paginationChange) {
          eventBus.$emit(this.config.meta.paginationChange, this.config);
        }
        if (this.config.meta && this.config.meta.action) {
          this.$store.dispatch(this.config.meta.action, this.config);
        }
      }
    },
    clearSort() {
      // Clears sort state for columns.
      this.gridOptions.api.setSortModel(null);
    },
    redrawTableRows(node) {
      const nodeRef = node;
      setTimeout(() => {
        if (nodeRef !== undefined) {
          this.gridOptions.api && this.gridOptions.api.redrawRows(nodeRef);
        } else {
          this.gridOptions.api && this.gridOptions.api.redrawRows();
        }
      });
    },
    expandCellOnEnter(obj) {
      obj.suppressKeyboardEvent = (params) => {
        const event = params.event;
        const key = event.key;
        if (key === 'Enter') {
          const listener = params.node.data['ag-grid-event-listener-key'];
          const obj = {
            row: params.node.data,
            index: params.node.rowIndex
          };
          eventBus.$emit(listener, obj);
        }
        return false;
      };
    },
    addExpandCell(columns) {
      if (this.hasExpand) {
        if (columns && columns.length > 0) {
          if (columns[0].field !== 'ag-grid-expand') {
            const obj = {};
            obj.headerComponentFramework = 'genericTableHeader';
            obj.headerComponentParams = {
              enableSorting: false
            };
            obj.field = 'ag-grid-expand';
            obj.headerName = '';
            obj.title = 'Expand';
            obj.pinned = 'left';
            if (this.singleClickEdit) {
              this.expandCellOnEnter(obj);
            }
            obj.cellRendererFramework = 'tableExpandCell';
            obj.cellRendererParams = {
              hideToolTipIfAgGridHasExpand: true,
              showToolTip: true,
              toolTipText: this.toolTipTextForExpandIcon
            };
            obj.minWidth = this.expandCellWidth;
            obj.maxWidth = this.expandCellWidth;
            obj.width = this.expandCellWidth;
            obj.sort = false;
            obj.cellClass = (params) => {
              if (!params.data['ag-grid-has-expand']) {
                // The logic below shows that if it's not first level, then just hide the icon
                const totalLevels = params.node.data['ag-grid-total-levels'];
                const currentLevel = params.node.data['ag-grid-level'];
                if (totalLevels === currentLevel) {
                  return 'u-visibility-hidden';
                }
                // Else disable it (disabled state, with disabled pointer events)
                return 'disabled';
              }
            };
            if (!this.customHasExpandColumnOrder) {
              obj.lockPosition = true;
              columns.unshift(obj);
            } else {
              const cols = columns.map((item) => item.cellRendererFramework);
              if (!cols.includes('tableExpandCell')) {
                columns.splice(this.customHasExpandColumnOrder, 0, obj);
              }
            }
          }
        }
      }
      return columns;
    },
    initialize() {
      if (this.tableColumn === undefined) {
        if (this.tableColumnObject !== undefined) {
          this.columnsToProcess = this.tableColumnObject.displayColConfigs;
          this.downloadColumnMap = this.tableColumnObject.downloadColConfigs;
        } else if (this.autoConfigure !== undefined) {
          this.initializeTable();
        }
      } else {
        this.columnsToProcess = this.tableColumn;
        if (
          this.config &&
          this.config.header &&
          this.config.header.actions &&
          this.config.header.actions.download &&
          this.config.header.actions.download.columnMap
        ) {
          this.downloadColumnMap =
            this.config.header.actions.download.columnMap;
        }
      }
      this.gridOptions &&
        !this.gridOptions.domLayout &&
        (this.gridOptions.domLayout = 'autoHeight');
      this.gridOptions && (this.gridOptions.onGridReady = this.onGridReady);
      this.gridOptions.rowClassRules = this.rowClassRules;
      this.gridOptions.rowClass = this.rowClass;
      this.gridOptions.rowStyle = this.rowStyle;
      // This is to keep NA to the end always
      // https://www.ag-grid.com/javascript-grid-sorting/ here is the link look for accentedSort
      this.gridOptions.accentedSort = true;
      // Setting the default sorting option when the table getting initalized.
      this.$nextTick(() => {
        this.setDefaultSort();
      });
    },
    autoResizeGrid(params) {
      setTimeout(function () {
        params?.api?.sizeColumnsToFit();
      });
    },
    onGridReady(params) {
      this.boundAutoResizeGrid = this.debouncedAutoResizeGrid.bind(
        this,
        params
      );
      window.addEventListener('resize', this.boundAutoResizeGrid);
      if (this.watchResizeColumn) {
        this.addListenersToColumnResize();
      }
      this.$emit('gridReady', params);
    },
    getCellRenderParams(data) {
      const type = data?.uiField?.uiType?.toLowerCase();
      const obj = {
        percent: 'PERCENTAGE',
        currency: 'CURRENCY',
        numeric: 'numeric',
        string: 'string'
      };
      if (type === 'string') {
        return {
          keyType: 'string'
        };
      } else {
        return {
          formatterFn: formatter,
          keyType: obj[type]
        };
      }
    },
    setDefaultSort() {
      const columnArray = this.columnDefs || [];
      for (let i = 0; i < columnArray.length; i++) {
        const column = columnArray[i];
        if (column.headerComponentParams && column.headerComponentParams.sort) {
          this.gridOptions.columnApi
            .getColumn(column.field)
            .setSort(column.headerComponentParams.sort);
        }
      }
    },
    getCustomCellRender(data) {
      const colMetaData = data.uiField.metadata;
      const type = colMetaData.widget?.toLowerCase();
      const objToReturn = {};
      switch (type) {
        case 'hyperlink':
          objToReturn.cellRendererFramework = 'linkDisplay';
          objToReturn.cellRendererParams = {
            url: colMetaData.urlTableColumnName
          };
          break;
        case 'progress':
          objToReturn.cellRendererFramework = 'progressDisplay';
          objToReturn.cellRendererParams = {
            fill: colMetaData.percentTableColumnName,
            numerator: colMetaData.numeratorTableColumnName,
            dinominator: colMetaData.denominatorTableColumnName
          };
          break;
        default:
      }
      return objToReturn;
    },
    getColumnDefinition(objRecieved) {
      const colDefinitionToReturn = [];
      const columnArray = objRecieved.columns; // measureList.concat(objRecieved.groupByDimensionList);
      for (let i = 0; i < columnArray.length; i++) {
        const currDefinition = columnArray[i];
        const obj = {};
        obj.showOnUi = currDefinition.uiField.metadata.showOnUi;
        obj.checkboxSelection =
          currDefinition.uiField.metadata.checkboxSelection;
        obj.isDownloadable = currDefinition.uiField.metadata.isDownloadable;

        obj.headerComponentFramework = 'genericTableHeader';
        obj.title = currDefinition.uiField.uiLabel;
        obj.headerName = currDefinition.uiField.uiLabel;
        obj.field =
          currDefinition.uiField.metadata.tableColumnName === undefined
            ? ''
            : currDefinition.uiField.metadata.tableColumnName;

        obj.cellRendererFramework = 'genericTableCell';
        obj.cellRendererParams = this.getCellRenderParams(currDefinition);
        obj.minWidth = helper.setMinWidth(currDefinition.uiField.uiType); // currDefinition.uiField.uiType.toLowerCase() === 'string' ? 180 : 120;

        if (currDefinition.uiField.uiType === 'custom') {
          obj.minWidth = currDefinition.uiField.metadata.width;
          if (currDefinition.uiField.metadata.widget === 'progress') {
            obj.field = currDefinition.uiField.metadata.percentTableColumnName;
          }
          const cellObj = this.getCustomCellRender(currDefinition);
          obj.cellRendererFramework = cellObj.cellRendererFramework;
          obj.cellRendererParams = cellObj.cellRendererParams;
        }
        obj.keyOrder = currDefinition.uiField.uiOrder;
        obj.pinned = currDefinition.uiField.metadata.isFixed;
        colDefinitionToReturn[obj.keyOrder] = obj;
        obj.headerComponentParams = {
          enableSorting: false,
          keyType: obj.cellRendererParams.keyType,
          toolTipText: currDefinition.uiField.uiTooltip
        };
      }

      const displayColConfigs = colDefinitionToReturn.filter((elm) => {
        return elm.showOnUi === true;
      });

      const downloadColConfigs = colDefinitionToReturn.filter((elm) => {
        return elm.isDownloadable === true;
      });

      const objToReturn = {
        displayColConfigs: displayColConfigs,
        downloadColConfigs: downloadColConfigs
      };
      return objToReturn;
    },
    onColumnSort(column) {
      // Read the sort type from column.sort
      if (this.config?.body?.APIConfig?.page >= 0) {
        this.config.body.APIConfig.page = 1;
      }
      this.paginationCurrentPage = 1;
      // Some usecase involves default orderBy params to be included in api calls. eg: insights Content.
      if (
        !this.config?.body?.APIConfig?.hasOwnProperty(
          'customOrderAndSortList'
        ) &&
        this.config?.body?.defaultOrderBy &&
        this.config?.body?.defaultOrderBy?.length > 0
      ) {
        this.config.body.APIConfig.orderByList = [
          {
            // colId is generated by ag grid using the field value. colId = field or field + unique value (if two cols use same col Id)
            dimension: column?.colDef?.field || column.colId,
            direction: column.sort ? column.sort.toUpperCase() : 'DESC'
          },
          ...this.config.body.defaultOrderBy
        ];
      } else if (this.config?.body?.APIConfig?.orderByList) {
        this.config.body.APIConfig.orderByList = [
          {
            dimension: column?.colDef?.field || column.colId,
            direction: column.sort ? column.sort.toUpperCase() : 'DESC'
          }
        ];
      }
      if (this.sortingChangeEvent) {
        eventBus.$emit(this.sortingChangeEvent, column, this.config);
      } else {
        this.$store.dispatch(this.config.meta.action, this.config);
      }
    },
    pageChangeEvent(page) {
      this.paginationChangeEvent &&
        eventBus.$emit(this.paginationChangeEvent, page);
      this.config.body.APIConfig.page = page;
      if (this.config?.meta?.paginationChange) {
        eventBus.$emit(this.config.meta.paginationChange, this.config);
      }
      if (this.config.meta && this.config.meta.action) {
        this.$store.dispatch(this.config.meta.action, this.config);
      }
    },
    onSelectionChanged(event) {
      this.$emit('selectionChanged', event);
      if (this.rowSelection && this.rowSelection.onSelectionChanged) {
        this.onRowSelection = this.rowSelection.onSelectionChanged(
          event.api.getSelectedNodes(),
          event
        );
      }
    },
    isFirstColumn(params) {
      const displayedColumns =
        params && params.columnApi && params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    },
    initializeTable() {
      const obj = Object.assign({}, this.autoConfigure);
      let colConfigObj = {};
      let customObj = false;
      if (
        this.customTableObject !== null &&
        this.customTableObject !== undefined
      ) {
        customObj = Object.keys(this.customTableObject).length > 0;
      }
      if (customObj === true) {
        colConfigObj = transformer.getColumnDefinition(
          obj.columns,
          {},
          this.customTableObject
        );
      } else {
        colConfigObj = transformer.getColumnDefinition(obj.columns || []);
      }
      // var colConfigObj = this.getColumnDefinition(obj);
      this.computedTableColumns = colConfigObj.displayColConfigs;
      this.downloadColumnMap = colConfigObj.downloadColConfigs;
      if (this.computedTableColumns.length) {
        this.isTableLoaded = true;
      }
      this.getRowData();
      this.$nextTick(() => {
        this.setDefaultSort();
      });
    },
    defaultIconClickHandler(headerThis, event) {
      event.stopPropagation();
      const clickedColumnId = headerThis.params.column.colId;
      const columnName = headerThis.params.column.colDef.headerName;
      const selectedFilterObject = (this.filterDimensionMeta.filter(
        (filterDimension) => {
          // check if there is a filter entry with either matching colId or Filter display name.
          return (
            filterDimension &&
            (filterDimension.dimensionColumn === clickedColumnId ||
              columnName === filterDimension.dimensionLabel)
          );
        }
      ) || [])[0];
      this.filterInstance.addFilterInstance.toggle();
      if (!selectedFilterObject || !clickedColumnId) {
        return;
      }
      this.$nextTick(() => {
        this.filterInstance.addFilterInstance.itemClick(selectedFilterObject);
      });
      this.$emit('headerIconClick', { headerThis, event });
    },
    getRowData(obj) {
      const tableObject =
        obj === undefined
          ? JSON.parse(JSON.stringify(this.autoConfigure))
          : obj;
      this.localTableRow = tableObject.rows;
      this.computedTableRows = tableObject.rows;
    },
    downloadTable() {
      this.$refs.downloadReference.download();
    },
    setTableId() {
      this.tableId = 'tableId-' + Math.floor(Math.random() * 10000);
    },
    tableHeaderSticky() {
      const valuesObj = this.tableHeaderStickyObj;
      const scrollAbleDOMArray = document.querySelectorAll(
        valuesObj.scrollAbleSelector
      );
      let scrollAbleDOM = null;
      for (let i = 0; i < scrollAbleDOMArray.length; i++) {
        if (scrollAbleDOMArray[i].querySelector('#' + this.tableId)) {
          scrollAbleDOM = scrollAbleDOMArray[i];
          break;
        }
      }
      if (scrollAbleDOM) {
        scrollAbleDOM.addEventListener('scroll', this.stickyMethod);
      }
    },
    stickyMethod(e) {
      let topHeight = 0;
      const valuesObj = this.tableHeaderStickyObj;
      const styles = this.tableHeaderStickyObj.styles;
      const el = document.getElementById(this.tableId);
      if (!el) {
        return;
      }
      const stickyDOM = el.querySelector(valuesObj.stickySelector);
      for (let i = 0; i < valuesObj.addSpaceElementsSelector.length; i++) {
        const element = document.querySelector(
          valuesObj.addSpaceElementsSelector[i]
        );
        topHeight += element?.offsetHeight;
      }
      if (
        e.target.scrollTop >=
        el.getBoundingClientRect().top + e.target.scrollTop - topHeight
      ) {
        stickyDOM.style.position = 'fixed';
        stickyDOM.style.top = `${topHeight}px`;
        for (let j = 0; j < styles.length; j++) {
          stickyDOM.style[styles[j].key] = styles[j].value;
        }
        if (valuesObj.class) {
          stickyDOM.classList.add(valuesObj.class);
        }
      } else {
        stickyDOM.style.position = 'static';
        stickyDOM.style.top = '0px';
        if (valuesObj.class) {
          if (stickyDOM.classList.contains(valuesObj.class)) {
            stickyDOM.classList.remove(valuesObj.class);
          }
        }
      }
    },
    setLevel(rows, level, parentId, parentKey, defaultRowExpand) {
      for (let i = 0; i < rows.length; i++) {
        if (!rows[i]['ag-grid-level'] && rows[i]['ag-grid-level'] !== 0) {
          rows[i]['ag-grid-level'] = level;
          rows[i]['ag-grid-total-levels'] = this.levels.length;
          rows[i]['ag-grid-table-id'] = this.tableId;
          rows[i]['ag-grid-event-listener-key'] = this.expandListnerKey;
          rows[i]['ag-grid-row-id'] =
            'ID-' + Math.floor(Math.random() * 1000000) + new Date().getTime();
          rows[i]['ag-grid-parent-id'] = parentId;
          if (parentKey) {
            rows[i]['ag-grid-key'] = parentKey + '_' + parentId;
          } else if (defaultRowExpand) {
            rows[i]['ag-grid-key'] = parentId;
          } else {
            rows[i]['ag-grid-key'] = rows[i]['ag-grid-row-id'];
          }
        }
      }
    },
    addListenersToColumnResize() {
      const table = document.querySelector(`#${this.tableId}`);
      const headers = table.querySelectorAll('.ag-header-cell');
      const mutation = new MutationObserver(this.styleChanged);
      for (let i = 0; i < headers.length; i++) {
        mutation.observe(headers[i], {
          attributes: true,
          attributeFilter: ['style']
        });
      }
    },
    styleChanged(mutationRecords) {
      const columns = [];
      for (let i = 0; i < mutationRecords.length; i++) {
        const item = mutationRecords[i];
        columns.push({
          columnId: item.target.getAttribute('col-id'),
          width: item.target.clientWidth
        });
      }
      this.$emit('columnResized', columns);
    },
    defaultRowAdderHandler(rows, newValue, newRows) {
      const index = rows.findIndex((item) => {
        return newValue.id === item['ag-grid-row-id'];
      });
      if (index > -1) {
        rows = this.insertAnyWhere(rows, index + 1, newRows);
      }
      return rows;
    },
    async addDefaultRowExpand() {
      let rows = cloneDeep(this.localTableRow);
      const promiseArray = [];
      let isPromiseResolved = false;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row['ag-grid-has-expand'] === false && row['ag-grid-level'] > 0) {
          continue;
        }
        this.expandStates[row['ag-grid-row-id']] =
          !this.expandStates[row['ag-grid-row-id']];
        const level = row['ag-grid-level'] + 1;
        const index = this.levels.findIndex((item) => {
          return item.level === level;
        });
        const levelConfig = this.levels[index];
        if (this.expandStates[row['ag-grid-row-id']]) {
          const payload = {
            id: row['ag-grid-row-id'],
            index: index,
            row: row,
            level: level
          };
          setTimeout(() => {
            if (levelConfig.storeKey) {
              this.$store.dispatch(levelConfig.action, {
                data: payload,
                storeKey: levelConfig.storeKey
              });
            } else {
              this.$store.dispatch(levelConfig.action, payload);
            }
          }, 0);
          const promise = new Promise((resolve, reject) => {
            this.unwatcherMap[payload.id] = this.$store.watch(
              (state, getters) => {
                if (levelConfig.storeKey) {
                  return getters[levelConfig.getter][levelConfig.storeKey][
                    payload.id
                  ];
                } else {
                  return getters[levelConfig.getter][payload.id];
                }
              },
              (newValue) => {
                const newRows = (newValue || {}).rows || [];
                if (newRows.length > 0) {
                  this.unwatcherMap[payload.id]();
                } else {
                  return;
                }
                if (!isPromiseResolved) {
                  resolve(newValue);
                  return;
                }
                this.setLevel(newRows, newValue.level, newValue.id, null, true);
                rows = this.defaultRowAdderHandler(rows, newValue, newRows);

                this.localTableRow = [...rows];
              },
              { deep: true }
            );
          });
          promiseArray.push(promise);
        } else {
          this.expandStates[row['ag-grid-row-id']] =
            !this.expandStates[row['ag-grid-row-id']];
        }
      }
      const promiseResolvedArray = await Promise.all(promiseArray);
      isPromiseResolved = true;
      promiseResolvedArray.forEach((newValue) => {
        const newRows = (newValue || {}).rows || [];
        if (newRows.length === 0) {
          return;
        }
        this.setLevel(newRows, newValue.level, newValue.id, null, true);
        rows = this.defaultRowAdderHandler(rows, newValue, newRows);
      });
      this.localTableRow = [...rows];
    },
    expandHandler(event) {
      this.expandStates[event.row['ag-grid-row-id']] =
        !this.expandStates[event.row['ag-grid-row-id']];
      const level = event.row['ag-grid-level'] + 1;
      const parentRowEventKey = event.row['ag-grid-key'];

      const index = this.levels.findIndex((item) => {
        return item.level === level;
      });
      const levelConfig = this.levels[index];
      if (this.expandStates[event.row['ag-grid-row-id']]) {
        const payload = {
          id: event.row['ag-grid-row-id'],
          index: event.index,
          row: event.row,
          level: level
        };
        setTimeout(() => {
          if (levelConfig.storeKey) {
            this.$store.dispatch(levelConfig.action, {
              data: payload,
              storeKey: levelConfig.storeKey
            });
          } else {
            this.$store.dispatch(levelConfig.action, payload);
          }
        }, 0);
        this.unwatcherMap[payload.id] = this.$store.watch(
          (state, getters) => {
            if (levelConfig.storeKey) {
              return getters[levelConfig.getter][levelConfig.storeKey][
                payload.id
              ];
            } else {
              return getters[levelConfig.getter][payload.id];
            }
          },
          (newValue) => {
            const rows = (newValue || {}).rows || [];
            if (rows.length > 0) {
              this.unwatcherMap[payload.id]();
            } else {
              return;
            }
            const parentKey = parentRowEventKey || newValue.id;
            this.setLevel(rows, newValue.level, newValue.id, parentKey);
            this.tableDataChanger(rows, newValue.index + 1);
            this.$nextTick(() => {
              this.gridOptions.api?.ensureIndexVisible(event.index, 'middle');
            });
          },
          { deep: true }
        );
      } else {
        const id = event.row['ag-grid-row-id'];
        this.deleteRowsInTable(id);
      }
      this.$emit('tableExpandChangeTriggered', {
        row: event.row,
        expandStatus:
          (event?.row['ag-grid-row-id'] &&
            this?.expandStates[event?.row['ag-grid-row-id']]) ||
          false
      });
    },
    deleteRowsInTable(id) {
      // fixed expand experience for all levels
      const rowIdKey = 'ag-grid-row-id';
      const rowLevelKey = 'ag-grid-level';
      const rowKey = 'ag-grid-key';
      const rowIndex = this.localTableRow.findIndex((item) => {
        return item[rowIdKey] === id;
      });
      const row = this.localTableRow[rowIndex];
      const rowLevel = row[rowLevelKey];
      const childIds = this.localTableRow.reduce((total, current) => {
        if (rowLevel < current[rowLevelKey] && current[rowKey].includes(id)) {
          total.push(current[rowIdKey]);
          if (current.isExpanded) {
            current.isExpanded = false;
            this.expandStates[current[rowIdKey]] =
              !this.expandStates[current[rowIdKey]];
          }
        }
        return total;
      }, []);
      for (let childId of childIds) {
        const index = this.localTableRow.findIndex((item) => {
          return item[rowIdKey] === childId;
        });
        if (index > -1) {
          this.localTableRow[index].isExpanded = false;
          this.expandStates[childId] = false;
          this.localTableRow.splice(index, 1);
        }
      }
      this.localTableRow[rowIndex].isExpanded = false;
      this.expandStates[row[rowIdKey]] = false;
    },
    tableDataChanger(rows, index) {
      this.localTableRow = this.insertAnyWhere(this.localTableRow, index, rows);
    },
    insertAnyWhere(arr, index, newArray) {
      const copy = [...arr];
      arr[index - 1].isExpanded = true;
      return arr.splice(0, index).concat(newArray).concat(copy.splice(index));
      // get all the elemets from start to the insertions index
      // append the filtered data to the return array,
      // get all the elemts from the insertions index to the end of the array, and push it back to return array
      // insert data in between
    }
  }
};
</script>
<style lang="css">
.overflow {
  overflow-y: scroll;
}
.rb-data-table.ag-row-no-hover .ag-theme-material .ag-row-hover {
  box-shadow: none;
}
.paginationFooter {
  background-color: #fff;
}
.ag-row.ag-row-disabled .ag-selection-checkbox {
  pointer-events: none;
}
.ag-row.ag-row-disabled {
  opacity: 0.75;
}
</style>
