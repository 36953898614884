<template>
  <section
    class="settings-bar u-cursor-pointer u-bg-color-grey-white u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex u-spacing-ph-l u-spacing-pv-m"
    @click="showExpandedState"
  >
    <div
      class="u-font-size-5 u-color-grey-mid-light u-display-flex u-font-size-7"
    >
      <div
        v-if="enableCrawlTypeSelect"
        class="anchor-at-brand u-spacing-mr-m"
      >
        <div
          class="u-display-flex u-font-size-7"
          :class="{
            'u-font-weight-600': isExpanded
          }"
        >
          <div>Crawl Type</div>
          <div
            v-if="!isExpanded"
            class="u-spacing-ml-xs u-display-flex u-flex-align-items-center"
          >
            <rb-icon
              v-if="internalCrawlType.icon"
              class="u-spacing-mr-xs"
              :icon="internalCrawlType.icon.name"
              :class="internalCrawlType.icon.class"
            />
            <div class="u-color-grey-base u-font-weight-600">
              {{ internalCrawlType.title }}
            </div>
          </div>
          <rb-icon
            v-if="!isExpanded"
            class="rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
            :icon="'pencil'"
          />
        </div>
        <rb-select-v2
          v-if="isExpanded"
          :on-close="handleCrawlTypeSelect"
          :options="crawlTypeOptions"
          :send-details="true"
          class="u-spacing-mt-m"
        >
          <div
            slot="trigger"
            class="u-display-flex"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <rb-icon
                v-if="internalCrawlType.icon"
                class="u-spacing-mr-xs"
                :icon="internalCrawlType.icon.name"
                :class="internalCrawlType.icon.class"
              />
              <div
                class="u-color-grey-base"
                :class="{
                  'u-font-weight-600': !isExpanded
                }"
              >
                {{ internalCrawlType.title }}
              </div>
            </div>
            <rb-icon
              class="u-display-flex u-flex-align-items-center rb-icon--small u-spacing-ml-xs u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
        </rb-select-v2>
      </div>
      <div class="anchor-at-brand u-spacing-mr-m">
        <div
          class="u-display-flex u-font-size-7"
          :class="{
            'u-font-weight-600': isExpanded
          }"
        >
          <div>Anchor Shelf at Brand</div>
          <div
            v-if="!isExpanded"
            class="u-spacing-ml-xs u-color-grey-base u-font-weight-600"
          >
            {{ internalBrandSelect.title }}
          </div>
          <rb-icon
            v-if="!isExpanded"
            class="rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
            :icon="'pencil'"
          />
        </div>
        <rb-select
          v-if="isExpanded"
          :on-close="handleAnchorSelect"
          :options="brandsOptions"
          :send-details="true"
          class="u-spacing-mt-m"
        >
          <div
            slot="trigger"
            class="u-display-flex"
          >
            <div
              class="u-color-grey-base u-text-overflow-ellipsis u-max-width-100px"
              :class="{
                'u-font-weight-600': !isExpanded
              }"
            >
              {{ internalBrandSelect.title }}
            </div>
            <rb-icon
              class="u-display-flex u-flex-align-items-center rb-icon--small u-spacing-ml-xs u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
        </rb-select>
      </div>
      <div class="u-display-flex">
        <div class="u-spacing-mr-m u-display-flex">
          <SelectRow
            :is-expanded="isExpanded"
            :options="types"
            title="Share of Voice Type"
            :original-selections="selectedType"
            @selection="handleTypesSelection"
          />
          <rb-icon
            v-if="!isExpanded"
            class="rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
            :icon="'pencil'"
          />
        </div>
        <div class="u-spacing-mr-m u-display-flex">
          <SelectRow
            :is-expanded="isExpanded"
            :options="keywordType"
            title="Keyword Type"
            :original-selections="selectedKeywordType"
            @selection="handleKeywordsSelection"
          />
          <rb-icon
            v-if="!isExpanded"
            class="rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
            :icon="'pencil'"
          />
        </div>
        <div
          class="u-display-flex"
          :class="{
            'u-spacing-mr-l': isExpanded
          }"
        >
          <SelectRow
            :is-expanded="isExpanded"
            :options="placements"
            title="Share of Voice In"
            :original-selections="selectedPlacement"
            :select-type="'radio'"
            @selection="handleSovPlacementSelection"
          />
          <rb-icon
            v-if="!isExpanded"
            class="rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
            :icon="'pencil'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isExpanded"
      class="u-display-flex u-flex-align-items-center u-color-grey-lighter"
    >
      <ConfigIcon
        :class="{
          'u-color-blue-base u-cursor-pointer': isApplyButtonActive,
          'u-color-grey-lighter u-pointer-events-none u-cursor-default':
            !isApplyButtonActive
        }"
        class="u-spacing-mr-m"
        :config="applyIcon"
        @click="handleApply"
      />
      <ConfigIcon
        :config="cancelIcon"
        @click="handleCancel"
      />
    </div>
  </section>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash';
import SelectRow from '@/components/pages/insights/amazon/share-of-voice/atoms/select-row.vue';
import ViewsSelect from '@/components/pages/insights/amazon/share-of-voice/atoms/views-select';
import ConfigIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/config-icon';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
export default {
  components: {
    RbSelectV2,
    SelectRow,
    ViewsSelect,
    ConfigIcon
  },
  props: {
    selectedBrand: {
      type: Object,
      default: () => ({})
    },
    brandsOptions: {
      type: Array,
      default: () => []
    },
    selectedKeywordType: {
      type: Array,
      default: () => []
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    selectedType: {
      type: Array,
      default: () => []
    },
    selectedPlacement: {
      type: Object,
      default: () => ({})
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    selectedCrawlType: {
      type: Object,
      default: () => ({})
    },
    crawlTypeOptions: {
      type: Array,
      default: () => []
    },
    enableCrawlTypeSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      applyIcon: this.getBaseApplyIcon(),
      cancelIcon: {
        icon: {
          icon: 'cross',
          class: {}
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Cancel'
        }
      },
      isExpanded: false,
      internalSelectedType: [],
      internalKeywordType: [],
      internalSelectedPlacement: {},
      internalBrandSelect: {},
      internalCrawlType: {}
    };
  },
  computed: {
    isApplyButtonActive() {
      const isSelectedTypeSameAsOriginal = isEqual(
        this.selectedType,
        this.internalSelectedType
      );
      const isSelectedPlacementSameAsOriginal = isEqual(
        this.selectedPlacement,
        this.internalSelectedPlacement
      );
      const isSelectedKeywordTypeSameAsOriginal = isEqual(
        this.selectedKeywordType,
        this.internalKeywordType
      );
      const isSelectedBrandSameAsOriginal = isEqual(
        this.selectedBrand,
        this.internalBrandSelect
      );
      const isSelectedCrawlTypeSameAsOriginal = isEqual(
        this.selectedCrawlType,
        this.internalCrawlType
      );
      return (
        this.internalSelectedType.length &&
        this.internalKeywordType.length &&
        (!isSelectedTypeSameAsOriginal ||
          !isSelectedPlacementSameAsOriginal ||
          !isSelectedKeywordTypeSameAsOriginal ||
          !isSelectedBrandSameAsOriginal ||
          !isSelectedCrawlTypeSameAsOriginal)
      );
    }
  },
  watch: {
    selectedType: {
      immediate: true,
      handler(newSelectedType) {
        this.internalSelectedType = cloneDeep(newSelectedType);
      }
    },
    selectedKeywordType: {
      immediate: true,
      handler(selectedKeywordType) {
        this.internalKeywordType = cloneDeep(selectedKeywordType);
      }
    },
    selectedPlacement: {
      immediate: true,
      handler(selectedPlacement) {
        this.internalSelectedPlacement = cloneDeep(selectedPlacement);
      }
    },
    selectedBrand: {
      immediate: true,
      handler(newSelectedBrand) {
        this.internalBrandSelect = cloneDeep(newSelectedBrand);
      }
    },
    selectedCrawlType: {
      immediate: true,
      handler(newCrawlType) {
        this.internalCrawlType = cloneDeep(newCrawlType);
      }
    }
  },
  methods: {
    getBaseApplyIcon() {
      return {
        icon: {
          icon: 'check',
          class: {
            color: 'null'
          }
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Apply',
          class: {
            color: 'null'
          }
        }
      };
    },
    showExpandedState() {
      this.isExpanded = true;
    },
    hideExpandedState() {
      this.isExpanded = false;
    },
    handleAnchorSelect(context, payload) {
      if (payload?.[0]) {
        delete payload[0].selectedIndex;
        this.internalBrandSelect = payload[0];
      }
    },
    handleCrawlTypeSelect(context, payload) {
      if (payload?.[0]) {
        delete payload[0].selectedIndex;
        this.internalCrawlType = payload[0];
      }
    },
    handleKeywordsSelection(data) {
      this.internalKeywordType = data;
    },
    handleSovPlacementSelection(data) {
      this.internalSelectedPlacement = data;
    },
    handleTypesSelection(data) {
      this.internalSelectedType = data;
    },
    handleCancel(payload) {
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      this.$emit('cancel');
    },
    getMultiSelectionsInOrder(selections, options) {
      const indexToItemMap = {};
      const typesLabelsToIndexMap = {};
      options.forEach((type, index) => {
        typesLabelsToIndexMap[type.label] = index;
      });
      selections.forEach((item) => {
        const pushIndex = typesLabelsToIndexMap[item.label];
        indexToItemMap[pushIndex] = item;
      });
      return Object.values(indexToItemMap);
    },
    setSelectedType(data) {
      const inorderArray = this.getMultiSelectionsInOrder(data, this.types);
      const primitiveArray = inorderArray.map((item) => item.dimensionValue);
      this.$emit('setType', primitiveArray);
    },
    setKeywordsType(data) {
      const inorderArray = this.getMultiSelectionsInOrder(
        data,
        this.keywordType
      );
      const primitiveArray = inorderArray.map((item) => item.dimensionValue);
      this.$emit('setKeywordType', primitiveArray);
    },
    setSelectedBrand(data) {
      const payload = {
        title: data.title,
        clientFlag: data.client_flag
      };
      const previousAnchorPayload = {
        title: this.selectedBrand.title,
        clientFlag: this.selectedBrand.client_flag
      };
      if (this.selectedBrand?.title !== data.title) {
        this.$snackbar.open({
          message: 'Share of Voice metrics reflect the new Anchor Brand!',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
      this.$emit('previousAnchorBrand', cloneDeep(previousAnchorPayload));
      this.$emit('setAnchoredBrand', payload);
    },
    setSelectedPlacement(data) {
      this.$emit('setPlacement', data.metric);
    },
    setSelectedCrawlType(data) {
      this.$emit('setCrawlType', data);
    },
    handleApply(payload) {
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      this.setSelectedType(this.internalSelectedType);
      this.setSelectedPlacement(this.internalSelectedPlacement);
      this.setKeywordsType(this.internalKeywordType);
      this.setSelectedBrand(this.internalBrandSelect);
      this.setSelectedCrawlType(this.internalCrawlType);
      // since crawl type is not an actual setting, and a page change, we don't apply it, rather handle it differently
      if (this.selectedCrawlType.value !== this.internalCrawlType.value) {
        return;
      }
      this.$emit('apply');
    }
  }
};
</script>

<style lang="css" scoped>
input[type='radio'] {
  vertical-align: baseline;
  margin: 0;
}
input[type='checkbox'] {
  vertical-align: baseline;
  margin: 0;
}
.settings-bar .pencil-on-hover {
  opacity: 0;
}
.settings-bar:hover {
  box-shadow: 0px 8px 8px -8px #d2d4d7;
}
.settings-bar:hover .edit-button {
  color: #007cf6;
}
.settings-bar:hover .pencil-on-hover {
  color: #007cf6;
  opacity: 1;
}
.u-max-width-100px {
  max-width: 100px;
}
</style>
