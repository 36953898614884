<template>
  <div class="u-spacing-pt-xxxl u-spacing-ph-xxl">
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <settings
        :settings-profile-options="settingsProfileOptions"
        :selected-profile="selectedProfile"
        :settings-portfolio-options="settingsPortfolioOptions"
        :selected-portfolio="selectedPortfolio"
        :default-budget="budgetChange"
        :portfolio-loader="portfolioLoader"
        :profile-loader="profileLoader"
        @handleSelectedProfile="handleProfileSelect"
        @handleSelectedPortfolio="handlePortfolioSelect"
        @handleBudgetChange="handleBudgetChange"
      />
      <createCampaignFooter
        :disable-right-btn="
          !budgetChange ||
          !(selectedProfile && selectedProfile.profile_id) ||
          portfolioLoader ||
          profileLoader
        "
        class="u-margin-top-40"
        :right-button-loading="portfolioLoader || profileLoader"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import settings from './settings';
import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    settings
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    settingsProfileOptions: [],
    selectedProfile: {},
    settingsPortfolioOptions: [],
    selectedPortfolio: {},
    budgetChange: 100,
    portfolioLoader: false,
    profileLoader: false,
    nullPortfolio: {
      portfolio_id: null,
      portfolio_name: 'None'
    }
  }),
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  created() {
    this.$store.dispatch(this.selectedValues.action, {
      ...this.allStepsData,
      suggestedCampaigns: null
    });
    this.handleBudgetChange(
      this.allStepsData?.budget || this.step?.budget || 0
    );
    this.profileLoader = true;
    HttpLayer.post({
      APIData: this.step.getProfiles
    })
      .then((response) => {
        this.settingsProfileOptions = transformer.mergeResultDimension(
          response.data,
          true
        );
        this.handleProfileSelect(this.settingsProfileOptions?.[0] || {}, true);
      })
      .finally(() => {
        this.profileLoader = false;
      });
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    handleBudgetChange(value) {
      this.budgetChange = value;
      this.updateBulletDescription(
        this.budgetChange,
        this.selectedProfile.profile_name,
        this.selectedPortfolio.portfolio_name
      );
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ budget: this.budgetChange }
      });
    },
    handlePortfolioSelect(selection) {
      this.selectedPortfolio = selection;
      this.updateBulletDescription(
        this.budgetChange,
        this.selectedProfile.profile_name,
        this.selectedPortfolio.portfolio_name
      );
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ portfolio: this.selectedPortfolio }
      });
    },
    updateBulletDescription(budget, selectedProfile, selectedPortfolio) {
      if (selectedProfile && selectedPortfolio) {
        this.step.description = `${
          Vue.prototype.$currency + budget
        } budget from profile: ${selectedProfile} and portfolio: ${selectedPortfolio}`;
      }
    },
    handleProfileSelect(selection, fromCreatedHook) {
      if (fromCreatedHook && this.allStepsData?.profile) {
        this.selectedProfile = this.allStepsData.profile;
      } else {
        this.selectedProfile = selection;
      }
      this.updateBulletDescription(
        this.budgetChange,
        this.selectedProfile.profile_name,
        this.selectedPortfolio.portfolio_name
      );
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ profile: this.selectedProfile }
      });
      if (fromCreatedHook && this.allStepsData?.portfolio) {
        this.portfolioForProfile(
          this.selectedProfile?.profile_id,
          this.allStepsData.portfolio
        );
      } else {
        this.portfolioForProfile(this.selectedProfile?.profile_id);
      }
    },
    portfolioForProfile(id, selectedPortfolio) {
      const APIData = this.step.getPortfolios;
      APIData.where = {
        dimensionNameValueList: [
          {
            dimensionName: 'profile_id',
            dimensionValue: id,
            eoperator: 'EQUAL_TO'
          }
        ]
      };
      this.portfolioLoader = true;
      HttpLayer.post({
        APIData
      })
        .then((response) => {
          this.settingsPortfolioOptions = transformer.mergeResultDimension(
            response.data,
            true
          );
          this.settingsPortfolioOptions.unshift(this.nullPortfolio);
          this.handlePortfolioSelect(
            selectedPortfolio || this.settingsPortfolioOptions?.[0] || {}
          );
        })
        .finally(() => {
          this.portfolioLoader = false;
        });
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>
