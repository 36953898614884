import ComponentsTester from '@/components/pages/component-tester/component-tester.vue';
import TOTP from '@/components/pages/totp/totp.vue';
import Invite from '@/components/pages/invite/invite.vue';
import Workbench from '@/components/pages/data/workbench.vue';
import WorkbenchRoutes from '@/components/pages/data/route.js';
import ManageSubscription from '@/components/pages/emailSubscription/emailSubscription.vue';
import ActionLogs from '@/components/pages/action_logs/actionLogs.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import SkuDetails from '@/components/pages/skuDetails/skuDetails.vue';
import sqlDataValidator from '@/components/pages/ciq-internal-tools/sql-validator/sql-validator.vue';
import BusinessInsights from '@/components/pages/businessInsights/index.vue';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import HomeV2 from '@/components/homeV2.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import SupplyChainLanding from '@/pages/supply_chain/route_config/index.vue';
import SupplyChainRoutes from '@/pages/supply_chain/route_config/route.js';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import AdvertisingRoutes from '@/pages/advertising/route_config/route.js';
import RecommendationsRoute from '@/pages/recommendations/route.js';
import CreateCampaignLists from '@/pages/toolsAndSettings/entityTagging/manageCampaignLists/custom/create/index.vue';
import StandardAddSkuToTag from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/entity-catalog/bulk-entity-manage.vue';
import NotFound from '@/pages/notFound';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import GlobalSearch from '@/pages/global_search';
import Automations from '@/pages/automations/index.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import pipeline from '@/pages/pipeline/index.vue';
import StrategyPlanningLanding from '@/pages/strategy_and_planning/route_config/index.vue';
import StrategyPlanningRoute from '@/pages/strategy_and_planning/route_config/route.js';
import { proxyLinksRoute } from '@/router/commonRoutePaths.js';
import urlHelper from '@/utils/helpers/url';
import CONSTANTS from '@/utils/constants';
import operationsExcellenceScore from '@/components/pages/ciq-internal-tools/operation_excellence/index.vue';

const retailRoutes = {
  path: '/us/amazon/retail',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          'feature.pages.amazon.landingPage',
          'business_overview'
        );
      }
    },
    {
      path: 'e2e-overview',
      name: 'e2e-overview',
      component: pipeline,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'search',
      name: 'GlobalSearch',
      title: 'Search',
      component: GlobalSearch,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'recommendations',
      title: 'Recommendations',
      children: RecommendationsRoute.routes,
      component: PassThroughRoute,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'assortment',
      title: 'Assortment',
      name: 'workbenchSkusDefault',
      redirect() {
        return 'assortment/skus';
      },
      component: Workbench,
      children: WorkbenchRoutes.routes,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: '/componenttester',
      name: 'componenttester',
      component: ComponentsTester,
      props: true
    },
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'sqlDataValidator',
      name: 'SQLDataValidator',
      component: sqlDataValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    {
      path: 'totp',
      name: 'totp',
      component: TOTP
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'manageSubscription',
      name: 'Subscription',
      component: ManageSubscription,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'manageSubscription/unsubscribe',
      name: 'UnSubscription',
      component: ManageSubscription
    },
    {
      path: 'actions_log',
      name: 'ActionLogs',
      title: 'Actions Log',
      component: ActionLogs,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'automations_and_impact',
      name: 'Automations',
      title: 'Automations',
      component: Automations,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'dp/:id?',
      name: 'SkuDetails',
      component: SkuDetails,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'business_overview/:id?',
      name: 'businessInsights',
      title: 'Business Overview',
      component: BusinessInsights,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM,
        key: 'business_overview'
      }
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },

    {
      path: 'tools_and_settings/catalog/standard/tag',
      component: StandardAddSkuToTag,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'tools_and_settings/campaign-lists/tag',
      component: CreateCampaignLists,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'digital_shelf',
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'strategy_and_planning',
      component: StrategyPlanningLanding,
      children: StrategyPlanningRoute.routes
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'reports/amazon_search',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'reports/dsp',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'supply_chain',
      component: SupplyChainLanding,
      children: SupplyChainRoutes.routes
    },
    {
      path: 'advertising',
      component: AdvertisingLanding,
      children: AdvertisingRoutes.routes
    },
    {
      path: 'file-download',
      component: fileDownload
    },
    {
      path: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    {
      path: 'internal_tools',
      component: PassThroughRoute,
      children: [
        {
          path: 'operations_excellence_score',
          name: 'operations_excellence_score',
          component: operationsExcellenceScore
        },
        {
          path: '',
          redirect: () => {
            return 'operations_excellence_score';
          }
        }
      ]
    },
    proxyLinksRoute,
    {
      path: '*',
      component: NotFound,
      name: '404'
    }
  ]
};

export { retailRoutes };
