<template>
  <section :class="id">
    <div
      ref="button"
      v-tippy="tippyOptions"
      role="button"
      class="u-display-flex u-flex-direction-row u-flex-align-items-center u-cursor-pointer u-position-relative"
      @click="$emit('toggleDrawer')"
    >
      <rb-icon
        class="rb-icon--medium system-transparency-status-icon"
        :class="`u-color-${statusMap[overallStatus].color}-base`"
        :icon="statusMap[overallStatus].icon"
      />
      <system-transparency-icon
        width="20"
        height="20"
      />
    </div>
    <div :id="id">
      <Loader
        v-if="isLoading"
        :loading="isLoading"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
      <div
        v-else
        class="u-spacing-p-xs"
      >
        <div
          class="u-spacing-p-xs u-font-size-6 u-display-flex u-flex-align-items-center"
          :class="
            servicesStatus.length
              ? 'system-transparency-tooltip-header-border'
              : ''
          "
        >
          <rb-icon
            class="rb-icon--small u-spacing-mr-xs"
            :class="`u-color-${statusMap[overallStatus].color}-base`"
            :icon="statusMap[overallStatus].icon"
          />System Health
        </div>
        <div
          class="u-font-size-7"
          :class="servicesStatus.length ? 'u-spacing-p-s ' : ''"
        >
          <div
            v-for="(status, i) in servicesStatus"
            :key="i"
            :class="i < servicesStatus.length - 1 ? 'u-spacing-mb-s' : ''"
            class="u-display-flex u-flex-align-items-center"
          >
            <span
              :class="`u-spacing-mr-s system-transparency-health-status-${
                statusMap[status.status].color
              }`"
            />{{ status.health_group_name
            }}<span
              class="u-color-grey-mid-light u-spacing-ml-auto u-spacing-mr-m u-width-10"
              >{{ statusMap[status.status].text }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { STATUS_MAP } from '@/components/widgets/system-transparency/system-transparency-utils.js';
import Loader from '@/components/basic/loader';
import SystemTransparencyIcon from '@/components/widgets/system-transparency/system-transparency-icon.vue';
export default {
  name: 'SystemTransparencyIndicator',
  components: {
    Loader,
    SystemTransparencyIcon
  },
  props: {
    servicesStatus: {
      default: [],
      type: Array
    },
    overallStatus: {
      default: 'FAILED',
      type: String
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const id = 'system-transparency-tooltip';
    return {
      tippyOptions: {
        html: `#${id}`,
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        placement: 'left-start'
      },
      id,
      statusMap: STATUS_MAP
    };
  },
  watch: {
    servicesStatus: {
      handler(newValue, prevValue) {
        if (prevValue.length) {
          if (newValue.length !== prevValue.length && newValue.length > 0) {
            this.triggerRippleHover();
          } else {
            const newStatuses = newValue.map(
              (healthCheck) => healthCheck.status
            );
            const prevStatuses = prevValue.map(
              (healthCheck) => healthCheck.status
            );
            if (!newStatuses.every((status, i) => prevStatuses[i] === status)) {
              this.triggerRippleHover();
            }
          }
        }
      }
    }
  },
  beforeDestroy() {
    // Clearing the timeout to prevent memory leaks
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    triggerRippleHover() {
      this.$emit('triggerDrawerAPI');
      this.$refs.button?.dispatchEvent(
        new MouseEvent('mouseenter', {
          view: window,
          bubbles: true,
          cancelable: true
        })
      );
      this.timeoutId = setTimeout(() => {
        this.$refs.button?.dispatchEvent(
          new MouseEvent('mouseleave', {
            view: window,
            bubbles: true,
            cancelable: true
          })
        );
      }, 5000);
    }
  }
};
</script>

<style lang="css" scoped>
.system-transparency-status-icon {
  position: absolute;
  right: -9px;
  top: -6px;
}
.system-transparency-tooltip-header-border {
  border-bottom: 1px solid;
  border-color: #e9eaeb;
}

.system-transparency-health-status-green,
.system-transparency-health-status-orange {
  background-color: #3ea95e;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.system-transparency-health-status-green {
  background-color: #3ea95e;
}

.system-transparency-health-status-orange {
  background-color: #ffa800;
}
</style>
