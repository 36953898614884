<template>
  <div
    class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-width-100 business-units"
  >
    <Loader
      :loading="loading"
      class="fill--parent u-z-index-12"
      :color="'#3fadf7'"
      :size="'2rem'"
      :thickness="'.2rem'"
    />
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <div class="u-display-flex u-flex-direction-column">
        <div
          class="u-display-flex u-flex-align-items-center u-flex-wrap-yes u-spacing-mb-xl"
        >
          <div
            v-for="(item, i) in organizeOptions"
            :key="i"
            class="u-spacing-pr-m"
          >
            <rb-button
              :click-fn="() => selectOrganizeType(i)"
              :text="item.label"
              :size="'l'"
              :class="{ 'rb-button--hollow-blue': item.isSelected }"
              class="u-cursor-pointer selected-outlined-btn"
              :disabled="
                overallHistoricalDataLoading ||
                filteredHistoricalDataLoading ||
                expectedGrowthLoading
              "
            />
          </div>
        </div>
        <div v-if="organizeType === 0">
          <div
            v-if="businessUnit.dimensionLabel"
            class="u-spacing-mb-xl u-font-size-5"
          >
            <div
              v-if="overallHistoricalDataLoading || expectedGrowthLoading"
              class="u-display-flex u-flex-align-items-center u-color-grey-x-light"
            >
              <Loader
                :loading="overallHistoricalDataLoading || expectedGrowthLoading"
                :color="'#aaadb1'"
                :size="'1rem'"
                :thickness="'.1rem'"
              />
              <span class="u-spacing-ml-xs"
                >Loading the historical data and fetching the expected
                growth.</span
              >
            </div>
            <div
              v-else-if="overallHistoricalData.total_sales"
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                class="u-color-grey-lighter rb-icon--medium u-spacing-mr-xs"
                icon="info-circle-fill"
              />
              <div class="u-color-grey-base">
                {{ historicalData.initialText }}
                <span
                  v-for="(item, index) in historicalData.keyValues"
                  :key="item.key"
                  ><span class="u-font-weight-600">{{
                    `${item.title} ${item.text}`
                  }}</span>
                  <span>{{
                    index === historicalData.keyValues.length - 1 ? '.' : ', '
                  }}</span></span
                >
              </div>
            </div>

            <div
              v-if="!overallHistoricalDataLoading"
              class="u-color-grey-light u-font-size-6 u-spacing-mt-s"
            >
              <b>Disclaimer</b> : {{ historicalDataDisclaimerForSteps }}
            </div>
          </div>

          <div class="u-display-flex u-flex-align-items-center">
            <span
              class="u-spacing-pr-s u-font-size-5 u-font-weight-400 u-color-grey-base"
              >Select business unit with which you want to organize the budget
              plan</span
            >
            <RbSelectV2
              width="300px"
              class="u-width-auto u-border-radius-s u-border-width-s u-border-all u-border-color-grey-xxx-light u-spacing-pv-xs u-spacing-ph-s u-cursor-pointer"
              :send-details="true"
              :on-close="
                (__, option) => onDropdownValueChange(__, option, true)
              "
              :options="overallBusinessDropdownOptions"
              :class-name="'tableSelect'"
              :group-by-key="'metricGroup'"
              :search-enabled="false"
              :is-waterfall-dropdown="true"
              :context="[overallFilterIndex]"
            >
              <div
                slot="trigger"
                :class="[
                  !ellipsisOnOverflow &&
                    'u-display-flex u-flex-align-items-center u-cursor-pointer'
                ]"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <span
                    :class="[
                      'u-font-size-5',
                      ellipsisOnOverflow && 'overflow-styles u-display-block'
                    ]"
                    >{{
                      businessUnit.dimensionLabel || 'Select a Business Unit'
                    }}</span
                  >
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-direction-column">
                  <div class="u-display-flex u-flex-align-items-center">
                    <div class="u-width-100">
                      <div
                        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                      >
                        <div class="u-spacing-pr-m u-width-100">
                          {{ option.dimensionLabel || option.dimensionName }}
                        </div>
                      </div>
                    </div>
                    <span
                      v-if="option.children"
                      class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                      style="transform: rotate(90deg)"
                    />
                  </div>
                </div>
              </template>
            </RbSelectV2>
          </div>
          <div
            v-if="showMaxBUEntitiesWarning"
            class="u-display-flex u-flex-align-items-center u-font-size-6 u-color-red-base u-spacing-mt-xs"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small"
              icon="error-fill"
            />
            <div class="u-flex-0 u-spacing-ml-xxs">
              Selection of a business unit which has more than 50 entities is
              not allowed.
            </div>
          </div>
        </div>
        <div v-if="organizeType === 1">
          <div
            class="u-spacing-ph-l u-spacing-pv-s u-spacing-mb-s u-bg-color-page-background"
            :class="filteredHistoricalDataLoading ? 'disabled-actions' : ''"
          >
            <rb-filter-panel
              id="media-planner"
              ref="filterPanel"
              :key="filterKey"
              style="z-index: 11"
              :page="pageWiseFilter"
              :enable-save="false"
              :data="filterData"
              :primary="primaryData"
              :secondary-data="secondaryFilterData"
              :listener-event="$route['name'] + `-filters`"
              :loader-status="loaderStatus"
              :apply-filter="onApplyFilter"
              :has-search="false"
              :emit="pageWiseFilter"
              :filter-v2="filterV2"
              :enable-remove-all="false"
              :show-notification="false"
              add-filter-text="Add Categorizations"
              :use-local-storage="false"
              :default-filters="defaultFilters"
              @savedFilterApplied="filterKey++"
            />
          </div>
          <div
            v-if="showMaxFiltersWarning"
            class="u-display-flex u-flex-align-items-center u-font-size-5 u-color-red-base u-spacing-mv-s"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small"
              icon="error-fill"
            />
            <div class="u-flex-0 u-spacing-ml-xxs">
              Maximum 50 filters can be added.
            </div>
          </div>

          <div
            class="u-spacing-mb-xl"
            :class="
              filteredHistoricalDataLoading ||
              !filteredBusinessUnit.dimensionLabel
                ? 'disabled-actions'
                : ''
            "
          >
            <PreviousPeriodPerformance
              v-if="Object.keys(filters).length"
              :header-text="`Previous Period Performance (${previousPeriodDates.start} - ${previousPeriodDates.end})`"
              :column-definition="filteredHistoricalTableColDef"
              :data-rows="filteredHistoricalDataRows"
              :metric-options="metricOptions"
              :previous-period-dates="previousPeriodDates"
              :loading="filteredHistoricalDataLoading"
              ref-id="previous-period-data"
              @selectMetricType="selectMetricType"
              @tableContext="tableContext"
            >
              <div
                slot="trigger-content"
                class="u-display-flex u-flex-align-items-center"
                @click="() => fetchFilteredHistoricalData(0)"
              >
                <rb-icon
                  class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-xs"
                  icon="info-circle-fill"
                />
                <div
                  class="u-font-size-6 u-color-grey-base u-border-bottom-dotted u-border-width-s u-cursor-pointer previous-period-data"
                >
                  View Previous Period Ad Sales, ROAS, Ops
                  {{ previousPeriodDates.start }} -
                  {{ previousPeriodDates.end }}
                </div>
              </div>
            </PreviousPeriodPerformance>
          </div>

          <div
            class="u-display-flex u-flex-align-items-center"
            :class="{ 'disabled-actions': customFilterDropdownOptionsDisabled }"
          >
            <span
              class="u-spacing-pr-s u-font-size-5 u-font-weight-400 u-color-grey-base"
              >Select business unit with which you want to organize the budget
              plan</span
            >
            <RbSelectV2
              width="300px"
              :class="`
                u-width-auto
                u-border-radius-s
                u-border-width-s
                u-border-all
                u-border-color-grey-xxx-light
                u-spacing-pv-xs
                u-spacing-ph-s
                ${
                  customFilterDropdownOptionsDisabled
                    ? 'u-cursor-not-allowed'
                    : 'u-cursor-pointer'
                }
                
              `"
              :send-details="true"
              :on-close="onDropdownValueChange"
              :options="customFilterDropdownOptions"
              :class-name="'tableSelect'"
              :group-by-key="'metricGroup'"
              :search-enabled="false"
              :is-waterfall-dropdown="true"
              :context="[customFilterIndex]"
            >
              <div
                slot="trigger"
                :class="[
                  !ellipsisOnOverflow &&
                    'u-display-flex u-flex-align-items-center u-cursor-pointer'
                ]"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <span
                    :class="[
                      'u-font-size-5',
                      ellipsisOnOverflow && 'overflow-styles u-display-block'
                    ]"
                    >{{
                      filteredBusinessUnit.dimensionLabel ||
                      'Select a Business Unit'
                    }}</span
                  >
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-direction-column">
                  <div class="u-display-flex u-flex-align-items-center">
                    <div class="u-width-100">
                      <div
                        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                      >
                        <div class="u-spacing-pr-m u-width-100">
                          {{ option.dimensionLabel || option.dimensionName }}
                        </div>
                      </div>
                    </div>
                    <span
                      v-if="option.children"
                      class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                      style="transform: rotate(90deg)"
                    />
                  </div>
                </div>
              </template>
            </RbSelectV2>
          </div>
          <div
            v-if="
              filteredHistoricalDataLoading ||
              showMaxBUEntitiesWarning ||
              expectedGrowthLoading ||
              (!customFilterDropdownOptionsDisabled &&
                !filteredBusinessUnit.dimensionLabel)
            "
            class="u-display-flex u-flex-align-items-center u-font-size-5 u-spacing-mt-xs"
            :class="
              filteredHistoricalDataLoading
                ? 'u-color-grey-x-light'
                : 'u-color-red-base'
            "
          >
            <Loader
              v-if="filteredHistoricalDataLoading || expectedGrowthLoading"
              :loading="filteredHistoricalDataLoading || expectedGrowthLoading"
              :color="'#aaadb1'"
              :size="'1rem'"
              :thickness="'.1rem'"
            />
            <rb-icon
              v-else
              class="u-flex-0 rb-icon--small"
              icon="error-fill"
            />
            <div class="u-flex-0 u-spacing-ml-xxs">
              <span
                v-if="filteredHistoricalDataLoading"
                class="u-spacing-ml-xs"
              >
                Checking for number of entities in the selected business unit
                and fetching expected growth.
              </span>
              <span v-else>
                {{
                  showMaxBUEntitiesWarning
                    ? 'Selection of a business unit which has more than 50 entities is not allowed.'
                    : 'Please select a Business Unit.'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import AddFilter from '@/components/widgets/filter/addFilter.vue';
import widgetsUtil from '@/components/widgetMixin';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import Loader from '@/components/basic/loader.vue';
import PreviousPeriodPerformance from '@/components/ams/media-planner/generate-plan/steps/business-units/previous-period-performance.vue';
import {
  filteredHistoricalTableColDef,
  filteredHistoricalDataAPIConfig,
  organizeOptions,
  historicalDataKeyMap,
  getPreviousPeriodDates,
  getBUFiltersPayload,
  EXPECTED_GROWTH_SETTER,
  FETCH_FILTERED_HISTORICAL_DATA_ACTION,
  FILTERED_HISTORICAL_DATA_GETTER,
  FILTERED_HISTORICAL_DATA_SETTER,
  OVERALL_HISTORICAL_DATA_GETTER,
  EXPECTED_GROWTH_GETTER,
  INTERNAL_CATEGORIZATIONS_DATA_GETTER,
  INTERNAL_CATEGORIZATIONS_DATA_SETTER,
  RETAILER_CATEGORIZATIONS_DATA_GETTER,
  RETAILER_CATEGORIZATIONS_DATA_SETTER,
  PROFILE_NAME,
  PORTFOLIO_NAME,
  FETCH_ADDITIONAL_INPUTS
} from '@/components/ams/media-planner/config.js';
import { HISTORICAL_DATA_DISCLAIMER_FOR_STEPS } from '@/components/ams/media-planner/constants.js';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
export default {
  components: {
    CollapsibleTitle,
    AddFilter,
    RbSelectV2,
    Loader,
    rbFilterPanel,
    PreviousPeriodPerformance
  },
  mixins: [widgetsUtil],
  props: {
    storeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateKeyInStore: {
      type: Function,
      default: () => {
        return () => null;
      }
    }
  },
  data() {
    return {
      previousPeriodTableContext: null,
      organizeOptions: [],
      localLoaderStatus: false,
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      ellipsisOnOverflow: true,
      businessUnit: {},
      filteredBusinessUnit: {},
      organizeType: 0,
      pageWiseFilter: 'mediaPlanner',
      filters: [],
      previousPeriodDates: {
        start: null,
        end: null
      },
      defaultFilters: {
        order: [],
        values: {}
      },
      selectedMetric: 0,
      loading: false,
      primaryData: [],
      filterKey: 0,
      customFilterIndex: 1,
      overallFilterIndex: 2,
      fetchingBUFilters: false,
      showMaxFiltersWarning: false,
      historicalDataDisclaimerForSteps: HISTORICAL_DATA_DISCLAIMER_FOR_STEPS
    };
  },
  computed: {
    historicalData() {
      const keyValues = [];
      const historicalDataKeys = Object.keys(historicalDataKeyMap);
      historicalDataKeys.forEach((key, i) => {
        if (this.overallHistoricalData?.[key]) {
          keyValues.push({
            title: historicalDataKeyMap[key].text,
            text: this.filteredCurrency(this.overallHistoricalData[key])
          });
        }
      });
      return {
        initialText: `From previous period (${this.previousPeriodDates.start} - ${this.previousPeriodDates.end})`,
        keyValues
      };
    },
    filteredHistoricalTableColDef() {
      const selectedMetric = this.metricOptions.find(
        ({ isSelected }) => isSelected
      )?.name;
      return selectedMetric
        ? [
            ...filteredHistoricalTableColDef.map((item, i) => {
              if (i === 0) {
                const updatedItem = { ...item };
                updatedItem.name = selectedMetric;
                updatedItem.uiField.uiLabel =
                  this.filtersMap[selectedMetric]?.label;
                updatedItem.uiField.metadata.tableColumnName = selectedMetric;
                return updatedItem;
              }
              return item;
            })
          ]
        : [];
    },
    metricOptions() {
      const newMetrics = [];
      Object.keys(this.filters).forEach((key, i) => {
        newMetrics.push({
          label: this.filtersMap[key]?.label,
          name: key,
          isSelected: i === this.selectedMetric,
          type: this.filtersMap[key]?.type
        });
      });
      return newMetrics;
    },
    filteredHistoricalDataLoading() {
      return (
        this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER]?.loading || false
      );
    },
    overallHistoricalDataLoading() {
      return (
        this.$store.getters[OVERALL_HISTORICAL_DATA_GETTER]?.loading || false
      );
    },
    expectedGrowthLoading() {
      return this.$store.getters[EXPECTED_GROWTH_GETTER]?.loading;
    },
    filteredHistoricalDataRows() {
      const selectedMetric = this.metricOptions.find(
        ({ isSelected }) => isSelected
      )?.name;
      const data =
        this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER]?.data[
          selectedMetric
        ] || [];
      return data;
    },
    overallHistoricalData() {
      const data =
        this.$store.getters[OVERALL_HISTORICAL_DATA_GETTER]?.data || {};
      return data;
    },
    categorizations() {
      const internalCategorizations =
        this.$store.getters[INTERNAL_CATEGORIZATIONS_DATA_GETTER];
      const retailerCategorizations = (
        this.$store.getters[RETAILER_CATEGORIZATIONS_DATA_GETTER] || []
      ).filter(({ dimensionName }) =>
        [PROFILE_NAME, PORTFOLIO_NAME].includes(dimensionName)
      );
      return {
        internalCategorizations,
        retailerCategorizations
      };
    },
    customFilterDropdownOptionsDisabled() {
      const isDisabled = !this.customFilterDropdownOptions.every(
        ({ children }) => children.length > 0
      );
      if (
        isDisabled &&
        !this.loading &&
        !this.overallHistoricalDataLoading &&
        this.filteredBusinessUnit?.dimensionName
      ) {
        this.filteredBusinessUnit = {};
        this.updateKeyInStore('filteredBusinessUnit', {});
      }
      return isDisabled;
    },
    customFilterDropdownOptions() {
      const { internalCategorizations, retailerCategorizations } =
        this.categorizations;
      const selectedFilterKeys = Object.keys(this.filters);
      const type = this.filtersMap[selectedFilterKeys[0]]?.type;
      const isIC = type === 'CLIENT_INTERNAL_CATALOGUE';
      if (!type) {
        this.primaryData = [
          ...retailerCategorizations,
          ...internalCategorizations
        ];
      } else if (isIC) {
        this.primaryData = [...internalCategorizations];
      } else {
        this.primaryData = [...retailerCategorizations];
      }
      const internalCategorizationsOptions = {
        dimensionName: 'Internal Categorization',
        dimensionLabel: 'Internal Categorization',
        children: internalCategorizations
      };
      const retailerCategorizationsOptions = {
        dimensionName: 'Retailer Categorization',
        dimensionLabel: 'Retailer Categorization',
        children: retailerCategorizations.filter(({ dimensionName }) =>
          [PROFILE_NAME, PORTFOLIO_NAME].includes(dimensionName)
        )
      };
      if (type) {
        return isIC
          ? [internalCategorizationsOptions]
          : [retailerCategorizationsOptions];
      }
      return [internalCategorizationsOptions, retailerCategorizationsOptions];
    },
    overallBusinessDropdownOptions() {
      const { internalCategorizations, retailerCategorizations } =
        this.categorizations;
      return [
        {
          dimensionName: 'Internal Categorization',
          dimensionLabel: 'Internal Categorization',
          children: internalCategorizations
        },
        {
          dimensionName: 'Retailer Categorization',
          dimensionLabel: 'Retailer Categorization',
          children: retailerCategorizations.filter(({ dimensionName }) =>
            [PROFILE_NAME, PORTFOLIO_NAME].includes(dimensionName)
          )
        }
      ];
    },
    filtersMap() {
      const filtersMap = {};
      const { internalCategorizations, retailerCategorizations } =
        this.categorizations;
      [...internalCategorizations, ...retailerCategorizations].forEach(
        ({ dimensionType, dimensionLabel, dimensionColumn }) => {
          const lowerCasedDimensionColumn = dimensionColumn.toLowerCase();
          filtersMap[lowerCasedDimensionColumn] = {
            label: dimensionLabel,
            type: dimensionType
          };
        }
      );
      return filtersMap;
    },
    showMaxBUEntitiesWarning() {
      const filtersAboveRestrictedNumber = Object.values(
        this.filters || {}
      ).some((filters) => filters.length > 50);
      if (this.organizeType === 1) {
        this.showMaxFiltersWarning = filtersAboveRestrictedNumber;
        const filteredRows =
          this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER]?.data?.[
            this.filteredBusinessUnit?.dimensionName
          ] || [];
        const condition =
          filtersAboveRestrictedNumber || filteredRows.length > 50;
        this.$emit('disableNextButton', this.loading || condition);
        return filteredRows.length > 50;
      }
      this.showMaxFiltersWarning = false;
      this.$emit(
        'disableNextButton',
        this.loading || filtersAboveRestrictedNumber
      );
      return filtersAboveRestrictedNumber;
    }
  },
  watch: {
    loaderStatus(newValue) {
      if (!newValue) {
        this.createCategorizations();
      }
    },
    filtersMap(newValue) {
      const keyValuePairs = Object.keys(newValue).reduce(
        (keyValuePair, key) => ({
          ...keyValuePair,
          [key]: newValue[key]?.label
        }),
        {}
      );
      this.updateKeyInStore('filtersMap', keyValuePairs);
    }
  },
  created() {
    const {
      organizeType,
      filters,
      businessUnit,
      filteredBusinessUnit,
      months
    } = this.storeData;
    const isOverall = organizeType === organizeOptions.OVERALL.value;
    const bU = isOverall ? businessUnit : filteredBusinessUnit;
    const { internalCategorizations, retailerCategorizations } =
      this.categorizations;
    if (![...internalCategorizations, ...retailerCategorizations].length) {
      this.loading = true;
      this.$emit('disableNextButton', true);
      this.fetchCategorizations();
    }
    this.previousPeriodDates = getPreviousPeriodDates(months);
    this.organizeOptions = Object.values(cloneDeep(organizeOptions));
    if (organizeType) {
      const selectedIndex = organizeOptions[organizeType].index;
      this.selectOrganizeType(selectedIndex, false);
    } else {
      this.updateKeyInStore('organizeType', this.organizeOptions[0].value);
    }
    if (!isOverall) {
      if (Object.keys(filters).length) {
        this.filters = filters || {};
        this.defaultFilters = {
          order: Object.keys(this.filters),
          values: this.filters
        };
      }
    }
    if (businessUnit.dimensionColumn) {
      this.businessUnit = businessUnit;
    }
    if (filteredBusinessUnit.dimensionColumn) {
      this.filteredBusinessUnit = filteredBusinessUnit;
    }
    const isIC = bU?.dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
    this.fetchHistoricalData(
      bU?.dimensionColumn?.toLowerCase(),
      isIC,
      isOverall
    );
  },
  methods: {
    fetchCategorizations() {
      this.fetchFilters(
        'sales_dashboard',
        'FETCH_FILTERS_V2',
        'BusinessInsights'
      );
    },
    filteredCurrency(currency) {
      return Vue.options.filters.num_format(currency, 'currency');
    },
    tableContext(context) {
      this.previousPeriodTableContext = context;
    },
    selectOrganizeType(organizeType, updateStore = true) {
      if (this.organizeType !== organizeType) {
        this.organizeType = organizeType;
        this.organizeOptions.forEach((item) => {
          item.isSelected = false;
        });
        const { isSelected } = this.organizeOptions?.[organizeType] || {};
        this.organizeOptions[organizeType].isSelected = !isSelected;
        if (updateStore) {
          this.updateKeyInStore(
            'organizeType',
            this.organizeOptions[organizeType].value
          );
          if (this.organizeType === 1) {
            this.filteredBusinessUnit = {};
            this.updateKeyInStore('filteredBusinessUnit', {});
          } else {
            this.businessUnit = {};
            this.filters = {};
            this.defaultFilters = {
              order: [],
              values: {}
            };
            this.updateKeyInStore('businessUnit', {});
            this.updateKeyInStore('filters', {});
            this.$store.dispatch(FILTERED_HISTORICAL_DATA_SETTER, {
              loading: false,
              data: {}
            });
          }
        }
        if (Object.keys(this.filters).length && this.organizeType === 1) {
          this.defaultFilters = {
            order: Object.keys(this.filters),
            values: this.filters
          };
        }
      }
    },
    selectMetricType(metricType) {
      this.selectedMetric = metricType;
      this.fetchFilteredHistoricalData(metricType);
    },
    async fetchBUFilters(dimension, isIC) {
      const payload = getBUFiltersPayload(dimension, isIC);
      const res = await HttpService.post('FILTER_DATA_V2', payload);
      return res;
    },
    fetchAdditionalInputs(dimension, isOverall, forceFetch, isIC) {
      const { filters, showAdditionalInputsAtBULevel } = this.storeData;

      if (dimension && forceFetch) {
        this.$store.dispatch(EXPECTED_GROWTH_SETTER, {
          data: {
            overall: {},
            bULevel: {}
          },
          loading: false
        });
        this.$store.dispatch(FETCH_ADDITIONAL_INPUTS, {
          showAdditionalInputsAtBULevel,
          isOverall,
          filters,
          dimensionName: dimension,
          reset: forceFetch,
          isIC
        });
      }
    },
    onDropdownValueChange(__, { selectedOptions }, isOverall = false) {
      const value = selectedOptions[0];
      if (isOverall) {
        this.businessUnit = value;
        this.updateKeyInStore('businessUnit', value);
        const dimension = this.businessUnit.dimensionColumn?.toLowerCase();
        const isIC =
          this.businessUnit?.dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
        this.fetchHistoricalData(dimension, isIC, true, true);
      } else {
        this.filteredBusinessUnit = value;
        this.updateKeyInStore('filteredBusinessUnit', value);
        const dimension =
          this.filteredBusinessUnit.dimensionColumn?.toLowerCase();
        const isIC =
          this.filteredBusinessUnit?.dimensionType ===
          'CLIENT_INTERNAL_CATALOGUE';
        this.fetchHistoricalData(dimension, isIC, false, true);
      }
    },
    onApplyFilter(updatedFilters) {
      const { retailerCategorizations } = this.categorizations;
      if (retailerCategorizations.length === 0) {
        delete updatedFilters[PROFILE_NAME];
        delete updatedFilters[PORTFOLIO_NAME];
      }
      this.filters = { ...updatedFilters };
      this.updateKeyInStore('filters', this.filters);
      this.filteredBusinessUnit = {};
      this.updateKeyInStore('filteredBusinessUnit', {});
    },
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    createPayloadAndFetch(businessUnit, isOverall, isIC) {
      const APIConfig = { ...filteredHistoricalDataAPIConfig };
      APIConfig.metricsList = [...APIConfig.metricsList];
      APIConfig.where.date.from = this.previousPeriodDates.start;
      APIConfig.where.date.to = this.previousPeriodDates.end;
      let dimensionNameValueList = [];
      if (!isOverall) {
        Object.keys(this.filters).forEach((key) => {
          this.filters[key].forEach((j) => {
            dimensionNameValueList.push({
              dimensionName: key,
              dimensionValue: j
            });
          });
        });
        APIConfig.entityType = this.filteredBusinessUnit.dimensionName;
        APIConfig.where.dimensionNameValueList = dimensionNameValueList;
        APIConfig.metricsList = [...APIConfig.metricsList, businessUnit];
      }

      this.$store.dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
        APIConfig,
        filtersUpdated: true,
        overall: isOverall,
        isIC,
        applyFiltersForOverall: !isOverall,
        snackbar: this.snackbar
      });
    },
    fetchHistoricalData(
      businessUnit,
      isIC,
      isOverall,
      forceFetchAdditionalInputs
    ) {
      this.$store.dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
        APIConfig: {},
        filtersUpdated: true,
        overall: isOverall,
        forLoading: true,
        applyFiltersForOverall: true,
        isIC,
        snackbar: this.snackbar
      });
      if (isOverall) {
        this.fetchBUFilters(businessUnit, isIC).then((res) => {
          if (res?.data?.success) {
            const dimensions = (res?.data?.response?.data || []).map(
              (d) => d[businessUnit]
            );
            this.filters = { [businessUnit]: dimensions };
            this.defaultFilters = {
              order: Object.keys(this.filters),
              values: this.filters
            };
            this.updateKeyInStore('filters', this.filters);
            this.createPayloadAndFetch(businessUnit, isOverall, isIC);
          }
        });
      } else {
        const noFiltersApplied = Object.keys(this.filters).length === 0;
        if (noFiltersApplied) {
          this.fetchBUFilters(businessUnit, isIC).then((res) => {
            if (res?.data?.success) {
              const dimensions = (res?.data?.response?.data || []).map(
                (d) => d[businessUnit]
              );
              this.filters = { [businessUnit]: dimensions };
              this.updateKeyInStore('filters', this.filters);
              this.createPayloadAndFetch(businessUnit, isOverall);
            }
          });
        } else {
          this.createPayloadAndFetch(businessUnit, isOverall, isIC);
        }
      }
      this.fetchAdditionalInputs(
        businessUnit,
        isOverall,
        forceFetchAdditionalInputs,
        isIC
      );
    },
    fetchFilteredHistoricalData(metricType) {
      if (metricType === 0 && this.selectedMetric !== 0) {
        this.selectedMetric = metricType;
      }
      const selectedMetric = this.metricOptions[metricType]?.name;
      const isIC =
        this.metricOptions[metricType]?.type === 'CLIENT_INTERNAL_CATALOGUE';
      const APIConfig = { ...filteredHistoricalDataAPIConfig };
      const dimensionNameValueList = [];
      Object.keys(this.filters).forEach((key) => {
        this.filters[key].forEach((j, i) => {
          dimensionNameValueList.push({
            dimensionName: key,
            dimensionValue: j
          });
        });
      });

      APIConfig.entityType = selectedMetric;
      APIConfig.metricsList = [...APIConfig.metricsList, selectedMetric];
      APIConfig.where.date.from = this.previousPeriodDates.start;
      APIConfig.where.date.to = this.previousPeriodDates.end;
      APIConfig.where.dimensionNameValueList = dimensionNameValueList;
      this.$store.dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
        APIConfig,
        filtersUpdated: true,
        forMetrics: true,
        isIC,
        applyFiltersForOverall: true,
        snackbar: this.snackbar
      });
    },
    createCategorizations() {
      const internalCategorizations = [];
      const retailerCategorizations = [];
      this.primaryFilterData.forEach((filter) => {
        const { dimensionType, dimensionLabel, dimensionColumn } = filter;
        if (dimensionType === 'CLIENT_INTERNAL_CATALOGUE') {
          const lowerCasedDimensionColumn = dimensionColumn.toLowerCase();
          this.filtersMap[lowerCasedDimensionColumn] = {
            label: dimensionLabel,
            type: dimensionType
          };
          internalCategorizations.push(filter);
        }
        if (dimensionType === 'AMAZON_CATEGORY') {
          const lowerCasedDimensionColumn = dimensionColumn.toLowerCase();
          this.filtersMap[lowerCasedDimensionColumn] = {
            label: dimensionLabel,
            type: dimensionType
          };
          if (
            !this.businessUnit.dimensionLabel &&
            lowerCasedDimensionColumn === PROFILE_NAME
          ) {
            this.businessUnit = filter;
            this.updateKeyInStore('businessUnit', this.businessUnit);
          }
          retailerCategorizations.push(filter);
        }
      });
      this.$store.dispatch(
        INTERNAL_CATEGORIZATIONS_DATA_SETTER,
        internalCategorizations
      );
      this.$store.dispatch(
        RETAILER_CATEGORIZATIONS_DATA_SETTER,
        retailerCategorizations
      );
      this.loading = false;
      this.$emit('disableNextButton', this.showMaxFiltersWarning);
    }
  }
};
</script>

<style scoped lang="css">
.business-units .disabled-actions {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(1);
}

.selected-outlined-btn,
.selected-outlined-btn:hover {
  border-radius: 50px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  border: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  font-weight: 400;
  color: #2b333b;
}
.rb-button--hollow-blue {
  border-color: #007cf6;
  color: #007cf6;
}
.rb-button--hollow-blue:hover {
  border-color: #007cf6;
  color: #007cf6;
  background: none !important;
}

.u-width-auto {
  width: auto;
}

.u-bg-color-page-background {
  background: #f5f7fa;
}

.u-z-index-12 {
  z-index: 12;
}

.u-cursor-not-allowed {
  pointer-events: none;
}

.previous-period-data:hover {
  border-bottom-style: solid;
}
</style>
