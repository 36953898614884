<template>
  <div class="u-display-flex u-spacing-p-xs">
    <div
      v-for="(item, index) in items"
      :key="index"
      :style="getStyles(index)"
      class="u-display-flex u-flex-align-items-center"
    >
      <div
        v-tippy="{ placement: 'bottom' }"
        :class="`img-container img-container--${imageSize}`"
        :title="item.tooltip || ''"
      >
        <img
          :src="item.imageUrl"
          :alt="item.tooltip || 'item' + index"
        />
      </div>
      <span
        v-if="item.postText"
        class="u-font-size-7"
      >
        {{ item.postText }},
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  props: {
    items: {
      type: Array,
      default: () => []
    },
    imageSize: {
      type: String,
      default: 's'
    },
    lastOnTop: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getStyles(index) {
      let zIndex = this.items.length - index + 1;
      const margin = {};
      if (this.lastOnTop) {
        zIndex = index + 1;
        if (!this.items[index].postText) {
          margin.marginRight = '-7px';
        }
      } else {
        margin.marginLeft = '-7px';
      }
      return {
        zIndex: `${zIndex}`,
        ...margin
      };
    }
  }
});
</script>

<style lang="css" scoped>
.img-container {
  border-radius: 50%;
  background: white;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #e9eaeb;
  border-radius: 50%;
}
.img-container--s {
  width: 20px;
  height: 20px;
}
</style>
