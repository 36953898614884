<template>
  <div class="chargebackDefectRate">
    <div class="u-font-size-3 pb-15">
      <div class="header-container-cwc u-spacing-pb-m">
        <dynamic-component-composer
          :child-components="headerOptionsComputed"
          :container-styles="headerContainerGridStyles"
        />
      </div>
      <div class="chargeback-group-card-container">
        <ChargebackGroupCard
          v-for="(item, i) in chargebackGroupsToShow"
          :key="i"
          :index="i"
          :card-title="item.split(' ')[0]"
          :group-card-data="groupsData[item]"
          :group-chart-data="groupsChartData[item]"
          card-description="Related"
          :is-selected="i === selectedGroup"
          :show-chargeback-types="showChargebackTypes"
          :is-metric-loading="isMetricLoading"
          :is-chart-loading="isChartLoading"
          :classes="{
            'is-expanded': i === selectedGroup && showChargebackTypes
          }"
          @toggleChargebackDefectCards="toggleChargebackDefectCards"
        />
      </div>
    </div>
  </div>
</template>
<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import ChargebackTypeCard from './chargeback-type-card.vue';
import ChargebackGroupCard from './chargeback-group-card.vue';
import widgetTitle from '../atoms/widgetTitle.vue';
import { cloneDeep, isEqual } from 'lodash';
import Vue from 'vue';
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import moment from 'moment-timezone';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'ChargebackDefectRate',
  components: {
    widgetContainer,
    ChargebackTypeCard,
    DynamicComponentComposer,
    ChargebackGroupCard
  },
  mixins: [cwMixin],
  inject: {
    setSpotlightWidgetVisibility: {
      type: Function,
      default: function () {}
    }
  },
  props: {
    dateModeSelected: {
      type: String,
      default: 'week'
    }
  },
  data() {
    return {
      myKey: 1,
      isWidgetLoading: true,
      metricEventConfig: this.metadata,
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      showChargebackTypes: true,
      selectedGroup: 0,
      chargebackGroupsToShow: [
        'PO Related',
        'ASN Related',
        'PACKAGING Related',
        'Preparation Related',
        'Delivery Related',
        'Receive Related',
        'DIRECT FULLFILMENT Related'
      ],
      groupsData: {},
      groupsChartData: {},
      isMetricLoading: true,
      isChartLoading: true
    };
  },
  computed: {
    headerOptionsComputed() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'widgetTitle',
        props: {
          title: 'Defect Rate By Chargeback Groups',
          description:
            'Click on each chargeback group to analyse related insights and chargeback types',
          count: this.chargebackGroupsToShow.length
        }
      });
      return headerOptions;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.resetData();
        this.fetchData();
      }
    },
    selectedGroup: {
      immediate: true,
      handler(newVal) {
        if (newVal === null) this.setSpotlightWidgetVisibility(false, false);
        else
          this.setSpotlightWidgetVisibility(
            true,
            newVal !== 0,
            this.chargebackGroupsToShow[newVal]
          );
      }
    }
  },
  created() {
    Vue.component('widgetTitle', widgetTitle);
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isWidgetLoading = false;
      this.isMetricLoading = true;
      this.isChartLoading = true;
      let api = Object.values(this.metadata.metrics)[0].api;
      let request = api.request;
      const metricsListName = Object.values(this.metadata.metrics).map(
        ({ name }) => name
      );

      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );

      request = this.tempTransformer(request, metricsListName);

      dashUtils
        .fetchDataAPI(request, api)
        .then((args) => {
          this.transformData(args.entityData);
          this.fetchTimeseriesData();
        })
        .catch(() => {
          this.myKey++;
        });
    },
    fetchTimeseriesData() {
      let api = Object.values(this.metadata.metrics)[0].api;
      let request = api.request;
      const metricsListName = Object.values(this.metadata.metrics).map(
        ({ name }) => name
      );

      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );

      request = this.tempTransformer(request, metricsListName);

      request.dimensionsList = ['chargeback_group', 'feed_date'];

      if (this.dateModeSelected === 'month') {
        request.metricsList = [
          'shortages_chargeback_analytics_monthly_metrics__chargeback_amount_percentage_v1',
          'shortages_chargeback_analytics_monthly_metrics__total_chargeback_amount'
        ];
      } else {
        request.metricsList = [
          'shortages_chargeback_analytics_weekly_metrics__chargeback_amount_percentage_v1',
          'shortages_chargeback_analytics_weekly_metrics__total_chargeback_amount'
        ];
      }

      request.where.date.from = moment(request.where.date.to, 'YYYY-MM-DD')
        .subtract(4, this.dateModeSelected)
        .startOf(this.dateModeSelected)
        .format('YYYY-MM-DD');

      dashUtils
        .fetchDataAPI(request, api)
        .then((args) => {
          this.transformChartData(args.entityData);
        })
        .catch(() => {
          this.myKey++;
        });
    },
    transformChartData(value) {
      const chargeData = value.reduce((acc, entry) => {
        const val = entry.data;
        const chargebackGroup = val.find((el) => el.name === 'chargeback_group')
          .RESULT.chargeback_group;
        const chargebackAmountPercentage = val.find(
          (el) => el.name === 'chargeback_amount_percentage_v1'
        ).TIMESERIES;
        if (acc[chargebackGroup]) {
          acc[chargebackGroup][0].TIMESERIES.push(
            ...chargebackAmountPercentage
          );
        } else {
          acc[chargebackGroup] = [
            {
              TIMESERIES: chargebackAmountPercentage,
              name: 'chargeback_amount_percentage_v1',
              alias: 'chargeback_amount_percentage_v1',
              type: 'METRICS'
            }
          ];
        }
        return acc;
      }, {});
      this.$set(this, 'groupsChartData', chargeData);
      this.isChartLoading = false;
    },
    transformData(value) {
      value.forEach((el) => {
        const val = el.data;
        const chargebackGroup = val.find((el) => el.name === 'chargeback_group')
          .RESULT.chargeback_group;
        this.$set(this.groupsData, chargebackGroup, {
          chargeback_amount_percentage: {
            tag1: val.find((el) => el.name === 'chargeback_amount_percentage')
              .RESULT.chargeback_amount_percentage,
            tag2: val.find((el) => el.name === 'chargeback_amount_percentage')
              .PVP.PVP_DIFF_chargeback_amount_percentage
          },
          total_chargeback_amount: {
            tag1: val.find((el) => el.name === 'total_chargeback_amount').RESULT
              .total_chargeback_amount
          }
        });
      });
      this.isMetricLoading = false;
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    resetData() {
      this.chargebackGroupsToShow.forEach((chargebackGroup) => {
        this.$set(this.groupsData, chargebackGroup, {
          chargeback_amount_percentage: {
            tag1: null,
            tag2: null
          },
          total_chargeback_amount: {
            tag1: 0
          }
        });
      });
    },
    toggleChargebackDefectCards(data) {
      let { cardId } = data;
      if (cardId !== this.selectedGroup) {
        this.showChargebackTypes = true;
        this.selectedGroup = cardId;
      } else {
        this.showChargebackTypes = !this.showChargebackTypes;
        this.selectedGroup = this.showChargebackTypes ? data.cardId : null;
      }
    }
  }
};
</script>
<style lang="css">
.chargebackDefectRate {
  margin-top: 2.4rem !important;
  margin-bottom: 0 !important;
}
.chargebackDefectRate .cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.chargebackDefectRate .chargeback-group-card-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}
.chargebackDefectRate .chargeback-group-card {
  gap: 8px;
}
.chargebackDefectRate .is-expanded {
  position: relative;
}
.chargebackDefectRate .is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 16px;
  width: 100%;
  bottom: -16px;
  position: absolute;
  left: 0em;
  clip-path: inset(0px -2px 0px -2px);
  z-index: 1;
}
</style>
