export default {
  last_updated_date: '07/01/2022',
  INTERCOM_APP_ID: 'q5355oyx',
  publicPages: [
    '/login',
    '/register',
    '/forgot-password',
    '/reset',
    '/welcome'
  ],
  colorArray: ['102,109,237', '255,191,76', '114,207,225', '255,94,228'],
  typeToColumnsMap: {
    Catalogue: ['sku_id', 'title', 'brand', 'category'],
    'Price & Promotions': [
      'sku_id',
      'title',
      'avg_offer_price',
      'current_offer_price',
      'offer_price_change',
      'per_unit_price',
      'sns_price',
      'promo_type',
      'promo_value'
    ],
    'Sales and Profitability': [
      'sku_id',
      'title',
      'units_shipped',
      'cogs',
      'shipped_cogs',
      'yoy_change',
      'pvp_change',
      'retailer_revenue',
      'unit_gross_margin',
      'unit_gross_percent',
      'gross_retailer_margin'
    ],
    'Search & BSR': [
      'sku_id',
      'title',
      'search_rank_index',
      'keywords_top_3_spots',
      'keywords_top_10_spots',
      'keywords_page_1_spots',
      'avg_best_seller_l1',
      'current_best_seller_l1',
      'rank_change_yoy',
      'rank_change'
    ],
    Content: [
      'sku_id',
      'title',
      'content_score',
      'no_of_chars_in_title',
      'no_of_bullets',
      'no_of_images',
      'videos',
      'a_plus_content',
      'length_of_description',
      'current_rating',
      'no_of_reviews',
      'no_of_new_reviews',
      'no_of_questions',
      'no_of_new_questions'
    ],
    Operations: [
      'sku_id',
      'title',
      'inventory_on_hand',
      'cogs_value_of_inventory',
      'units_returned',
      'units_replaced',
      'no_of_chargebacks',
      'chargeback_value',
      'chargeback_as_percent',
      'chargeback_reason_1',
      'chargeback_reason_2',
      'fc_with_highest_inventory',
      'fc_with_lowest_inventory',
      'open_po_quantity',
      'expected_oos_date'
    ]
  },
  columnFilter: [
    'Catalogue',
    'Price & Promotions',
    'Sales & Margin',
    'Search & BSR',
    'Content',
    'Operations',
    '3rd Party',
    'Competition'
  ],
  regionFilter: ['USA', 'UK', 'France', 'Germany', 'Canada', 'China'],
  retailerFilter: ['Amazon', 'Walmart', 'Chewy', 'Petco', 'Petsmart'],
  dateRangeFilter: [
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Last 1 year',
    'Last completed month',
    'Last completed quarter',
    'Last completed year',
    'YTD',
    'Custom'
  ],
  timeRangeOptions: [
    '00:00:00',
    '01:00:00',
    '02:00:00',
    '03:00:00',
    '04:00:00',
    '05:00:00',
    '06:00:00',
    '07:00:00',
    '08:00:00',
    '09:00:00',
    '10:00:00',
    '11:00:00',
    '12:00:00',
    '13:00:00',
    '14:00:00',
    '15:00:00',
    '16:00:00',
    '17:00:00',
    '18:00:00',
    '19:00:00',
    '20:00:00',
    '21:00:00',
    '22:00:00',
    '23:00:00',
    '23:59:00'
  ],
  intradayTimeRangeOptions: ['06:00:00', '10:00:00', '16:00:00', '22:00:00'],
  crawlTypes: {
    allKeywords: {
      title: 'All Keywords',
      value: 'ALL_KEYWORDS',
      page: 'SOV'
    },
    multiCrawl: {
      title: 'Multi',
      value: 'MULTI_CRAWL',
      page: 'SOV_INTRADAY',
      icon: {
        name: 'thunder',
        class: 'rb-icon--small u-color-purple-base'
      }
    }
  },
  brandsFilter: [
    'All',
    'Soundpeats',
    'Tzumi',
    'MPow',
    'Taotronics',
    'Senso',
    'Indigo'
  ],
  categoryFilter: [
    'All',
    'Headphones',
    'Earbuds',
    'Karaoke Equipement',
    'VR Equipment',
    'Cellphone Accessories'
  ],
  competitorFilter: [
    'None',
    'Soundpeats',
    'Tzumi',
    'MPow',
    'Taotronics',
    'Senso',
    'Indigo'
  ],
  columnKeyToTitleMap: {
    sku_id: 'ASIN',
    title: 'Title',
    brand: 'Brand',
    category: 'Category',
    avg_offer_price: 'Avg Offer Price',
    current_offer_price: 'Current Offer Price',
    offer_price_change: 'Offer Price Change',
    sns_price: 'Subscribe and Save Price',
    promo_type: 'Promo Type',
    promo_value: 'Promo Value',
    units_shipped: 'Units Shipped',
    cogs: 'COGS',
    shipped_cogs: 'Shipped COGS',
    yoy_change: 'YoY Change',
    pvp_change: 'PvP Change',
    retailer_revenue: 'Retailer Revenue',
    unit_gross_margin: 'Unit Gross Margin',
    unit_gross_percent: 'Unit Gross Percent',
    gross_retailer_margin: 'Gross Retailer Margin',
    search_rank_index: 'Search Rank Index',
    keywords_top_3_spots: 'Search Ranks - Top 3',
    keywords_top_10_spots: 'Search Ranks - Top 10',
    keywords_page_1_spots: 'No of Keywords with page 1 spot',
    avg_best_seller_l1: 'Avg Best Seller Rank (L1)',
    current_best_seller_l1: 'Current Best Seller Rank (L1)',
    rank_change_yoy: 'Rank Change YoY',
    rank_change_pvp: 'Rank Change PvP',
    content_score: 'Content Score',
    no_of_chars_in_title: 'No of characters in Title',
    no_of_bullets: 'No of Bullets',
    no_of_images: 'No of Images',
    videos: 'Videos',
    a_plus_content: 'A+ content',
    length_of_description: 'Length of Description',
    current_rating: 'Current Rating',
    no_of_reviews: 'No. of reviews',
    no_of_new_reviews: 'No. of new reviews',
    no_of_questions: 'No. of Questions',
    no_of_new_questions: 'No. of new Questions',
    inventory_on_hand: 'Inventory on hand',
    cogs_value_of_inventory: 'COGS value of inventory',
    units_returned: 'Units returned',
    units_replaced: 'Units replaced',
    no_of_chargebacks: 'No. of chargebacks',
    chargeback_value: 'Chargeback value',
    chargeback_as_percent: 'Chargeback as a % of shipped COGS',
    chargeback_reason_1: 'No. 1 chargeback reason',
    fc_with_highest_inventory: 'FC with highest inventory',
    fc_with_lowest_inventory: 'FC with lowest inventory',
    open_po_quantity: 'Open PO quantity',
    expected_oos_date: 'Expected OOS date'
  },
  secondaryFilterData: {
    estimation_value: {
      multi: false,
      v1: true,
      key: 'estimation_value',
      label: 'Values',
      values: [
        {
          title: 'Top 50%',
          enable: true
        },
        {
          title: 'Top 25%',
          enable: true
        },
        {
          title: 'Top 10%',
          enable: true
        }
      ]
    },
    rec_status: {
      multi: true,
      v1: true,
      key: 'rec_status',
      label: 'Recommendation status',
      values: [
        {
          title: 'New',
          enable: true
        }
      ]
    },
    action_state: {
      multi: true,
      v1: true,
      key: 'action_state',
      label: 'Action status',
      values: [
        {
          title: 'Open',
          enable: true
        },
        {
          title: 'Approved',
          enable: true
        },
        {
          title: 'Action taken',
          enable: true
        }
      ]
    }
  },
  PRODUCT_LINE: {
    ESM: 'ESM',
    RMM: 'RMM',
    REM: 'REM',
    REVENUE_RECOVERY: 'REVENUE_RECOVERY',
    MARKET_SHARE: 'MARKET_SHARE'
  },
  ZOOM_MEETING_LINKS: {
    'Revenue Recovery': 'https://zoom.us/j/92403482703',
    'CIQ Sales': 'https://zoom.us/j/92119089682',
    'CIQ Adv': 'https://zoom.us/j/94499656603'
  },
  FRESHDESK_TICKET_URL: 'https://commerceiq.freshdesk.com/a/tickets',
  FRESHDESK_STATUS_MAP: {
    2: 'Open',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed',
    8: 'Blocked on CS',
    10: 'Moved to Backlog',
    11: 'Processing',
    12: 'Blocked on Client',
    13: 'Data Testing',
    15: 'Blocked on Engineering'
  },
  PRIORITY_MAP: {
    1: 'P3',
    2: 'P2',
    3: 'P1',
    4: 'P0'
  },
  FRESHDESK_DASHBOARD_TABS: {
    Urgent: {
      id: 'urgent',
      name: 'Urgent',
      active: false
    },
    RaisedByMe: {
      id: 'raisedByMe',
      name: 'Raised by Me',
      active: false
    },
    AllTickets: {
      id: 'all',
      name: 'All Tickets',
      active: false
    }
  },
  INITIAL_TAB_PARAMS: {
    urgent: {
      reporter: null,
      priorities: ['urgent'],
      ticketStatus: ['Requested', 'In Progress'],
      ticketTypes: ['Issues Reported']
    },
    raisedByMe: {
      reporter: 'me',
      priorities: [],
      ticketStatus: [],
      ticketTypes: []
    },
    all: {
      reporter: null,
      priorities: [],
      ticketStatus: ['Requested', 'In Progress'],
      ticketTypes: []
    }
  },
  PRIORITY_OPTIONS: [
    {
      value: 'urgent',
      title: 'Urgent (P0)',
      selected: false
    },
    {
      value: 'high',
      title: 'Critical (P1)',
      selected: false
    },
    {
      value: 'medium',
      title: 'Medium (P2)',
      selected: false
    },
    {
      value: 'low',
      title: 'Low (P3)',
      selected: false
    }
  ],
  STATUS_OPTIONS: [
    {
      value: 'Requested',
      title: 'Requested',
      selected: false
    },
    {
      value: 'In Progress',
      title: 'In Progress',
      selected: false
    },
    {
      value: 'Awaiting Response',
      title: 'Awaiting Response',
      selected: false
    },
    {
      value: 'Resolved',
      title: 'Resolved',
      selected: false
    }
  ],
  COLOR_RED: 'color-red',
  COLOR_YELLOW: 'color-yellow',
  COLOR_GREEN: 'color-green',
  COLOR_GRAY: 'color-gray',
  CLIENT_NAME_COLUMN: 'client_name',
  defaultRetailerWhiteListPages: ['REMCC-EXECUTIVE'],
  defaultGlobalRetailer: 'amazon',
  cdnBaseUrl: 'https://cdn.rboomerang.com/',
  s3AssetsPath: {
    catalog:
      'https://cdn.rboomerang.com/helpDoc/Self_Serve_Internal_Taxonomy_Ingestion.pdf',
    campaign: ''
  },
  walmartCriteo: {
    walmartAPIs: [
      '/api/proxy/entity-metadata-service/entity-tagging/digital-shelves/hierarchy',
      '/api/proxy/entity-metadata-service/entity-tagging/tag?entityType=keyword&tagType=custom&customType=Custom'
    ]
  },
  chewyPromoteIQ: {
    chewyAPIs: [
      '/api/proxy/entity-metadata-service/entity-tagging/digital-shelves/hierarchy',
      '/api/proxy/entity-metadata-service/entity-tagging/tag?entityType=keyword&tagType=custom&customType=Custom'
    ]
  },
  regionMapping: {
    us: {
      label: 'United States of America',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/us.png'
    },
    uk: {
      label: 'United Kingdom',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/uk.png'
    },
    ca: {
      label: 'Canada',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/ca.png'
    },
    fr: {
      label: 'France',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/fr.png'
    },
    de: {
      label: 'Germany',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/de.png'
    },
    in: {
      label: 'India',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/in.png'
    },
    es: {
      label: 'Spain',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/es.png'
    },
    it: {
      label: 'Italy',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/it.png'
    },
    mx: {
      label: 'Mexico',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/mx.png'
    },
    nl: {
      label: 'Netherlands',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/nl.png'
    },
    pl: {
      label: 'Poland',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/pl.png'
    },
    eu: {
      label: 'All Available EU Regions',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/eu.png',
      imgSrc2: 'https://cdn.rboomerang.com/images/flags/eu-2.png'
    },
    au: {
      label: 'Australia',
      imgSrc: 'https://cdn.rboomerang.com/images/flags/au.png'
    }
  },
  retailerMapping: {
    amazonretail: {
      label: 'Amazon Retail',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'amazonretail',
      route: 'amazon/retail'
    },
    amazonfresh: {
      label: 'Amazon Fresh',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonfresh.png',
      key: 'amazonfresh',
      route: 'amazon/fresh'
    },
    walmartretail: {
      label: 'Walmart',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/walmartretail.png',
      key: 'walmartretail',
      route: 'walmart/retail'
    },
    targetretail: {
      label: 'Target (Criteo)',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/targetretail.png',
      key: 'targetretail',
      route: 'target/retail'
    },
    instacartretail: {
      label: 'Instacart',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/instacartretail.png',
      key: 'instacartretail',
      route: 'instacart/retail'
    },
    homedepotretail: {
      label: 'Home Depot',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/homedepotretail.png',
      key: 'homedepotretail',
      route: 'homedepot/retail'
    },
    wayfairretail: {
      label: 'Wayfair',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/wayfairretail.png',
      key: 'wayfairretail',
      route: 'wayfair/retail'
    },
    overstockretail: {
      label: 'Overstock',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'overstockretail',
      route: 'overstock/retail'
    },
    lowesretail: {
      label: 'Lowes',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/lowesretail.png',
      key: 'lowesretail',
      route: 'lowes/retail'
    },
    samsclubretail: {
      label: `Sam's Club (Criteo)`,
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/samsclubretail.png',
      key: 'samsclubretail',
      route: 'samsclub/retail'
    },
    samsclub_apiretail: {
      label: `Sam's Club (API)`,
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/samsclub_apiretail.png',
      key: 'samsclub_apiretail',
      route: 'samsclub_api/retail'
    },
    allretail: {
      label: 'Omnichannel',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'allretail',
      route: 'all/retail'
    },
    chewyretail: {
      label: 'Chewy',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/chewyretail.png',
      key: 'chewyretail',
      route: 'chewy/retail'
    },
    albertsons_citrusretail: {
      label: 'Albertsons',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/albertsons_citrusretail.png',
      key: 'albertsons_citrusretail',
      route: 'albertsons_citrus/retail'
    },
    shipt_citrusretail: {
      label: 'Shipt',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'shipt_citrusretail',
      route: 'shipt_citrus/retail'
    },
    hyvee_citrusretail: {
      label: 'Hyvee',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'hyvee_citrusretail',
      route: 'hyvee_citrus/retail'
    },
    shoprite_citrusretail: {
      label: 'Shoprite',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazonretail.png',
      key: 'shoprite_citrusretail',
      route: 'shoprite_citrus/retail'
    },
    costcoretail: {
      label: 'Costco',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/costcoretail.png',
      key: 'costcoretail',
      route: 'costco/retail'
    },
    freshdirect_criteoretail: {
      label: 'Fresh Direct',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/fresh_directretail.png',
      key: 'freshdirect_criteoretail',
      route: 'freshdirect_criteo/retail'
    },
    meijerretail: {
      label: 'Meijer',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/meijerretail.png',
      key: 'meijerretail',
      route: 'meijer/retail'
    },
    target_citrusretail: {
      label: 'Target (Citrus)',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/target_citrusretail.png',
      key: 'target_citrusretail',
      route: 'target_citrus/retail'
    },
    krogerretail: {
      label: 'Kroger',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/kroger_promoteiqretail.png',
      key: 'krogerretail',
      route: 'kroger/retail'
    },
    kroger_promoteiqretail: {
      label: 'Kroger',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/kroger_promoteiqretail.png',
      key: 'kroger_promoteiqretail',
      route: 'kroger_promoteiq/retail'
    },
    shipt_criteoretail: {
      label: 'Shipt',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/shipt_criteoretail.png',
      key: 'shipt_criteoretail',
      route: 'shipt_criteo/retail'
    },
    ahold_citrusretail: {
      label: 'Ahold',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/ahold_citrusretail.png',
      key: 'ahold_citrusretail',
      route: 'ahold_citrus/retail'
    },
    bestbuyretail: {
      label: 'Best Buy',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/bestbuyretail.png',
      key: 'bestbuyretail',
      route: 'bestbuy/retail'
    },
    amazon_3pretail: {
      label: 'Amazon 3P',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/amazon_3pretail.png',
      key: 'amazon_3pretail',
      route: 'amazon_3p/retail'
    },
    amazon_b2bretail: {
      label: 'Amazon Business',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/amazon_b2bretail.png',
      key: 'amazon_b2bretail',
      route: 'amazon_b2b/retail'
    },
    amazon_hybridretail: {
      label: 'Amazon Hybrid',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/amazon_hybridretail.png',
      key: 'amazon_hybridretail',
      route: 'amazon_hybrid/retail'
    },
    walmart_criteoretail: {
      label: 'Walmart (Criteo)',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/walmart_criteoretail.png',
      key: 'walmart_criteoretail',
      route: 'walmart_criteo/retail'
    },
    chewy_promoteiqretail: {
      label: 'Chewy (PromoteIQ)',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/chewy_promoteiqretail.png',
      key: 'chewy_promoteiqretail',
      route: 'chewy_promoteiq/retail'
    },
    sainsburys_citrusretail: {
      label: 'Sainsbury',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/sainsburys_citrusretail.png',
      key: 'sainsburys_citrusretail',
      route: 'sainsburys_citrus/retail'
    },
    waitrose_citrusretail: {
      label: 'Waitrose',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/waitrose_citrusretail.png',
      key: 'waitrose_citrusretail',
      route: 'waitrose_citrus/retail'
    },
    tesco_citrusretail: {
      label: 'Tesco',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/tesco_citrusretail.png',
      key: 'tesco_citrusretail',
      route: 'tesco_citrus/retail'
    },
    gopuff_citrusretail: {
      label: 'Gopuff',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/gopuff_citrusretail.png',
      key: 'gopuff_citrusretail',
      route: 'gopuff_citrus/retail'
    },
    walgreen_criteoretail: {
      label: 'Walgreen',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/walgreen_criteoretail.png',
      key: 'walgreen_criteoretail',
      route: 'walgreen_criteo/retail'
    },
    cvs_criteoretail: {
      label: 'CVS',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/cvs_criteoretail.png',
      key: 'cvs_criteoretail',
      route: 'cvs_criteo/retail'
    },
    asda_criteoretail: {
      label: 'ASDA',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/asda_criteoretail.png',
      key: 'asda_criteoretail',
      route: 'asda_criteo/retail'
    },
    ulta_criteoretail: {
      label: 'Ulta',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/ulta_criteoretail.png',
      key: 'ulta_criteoretail',
      route: 'ulta_criteo/retail'
    },
    albertsons_criteoretail: {
      label: 'Albertsons (Criteo)',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/albertsons_criteoretail.png',
      key: 'albertsons_criteoretail',
      route: 'albertsons_criteo/retail'
    },
    ubereats_criteoretail: {
      label: 'Uber Eats',
      imgSrc:
        'https://cdn.rboomerang.com/images/retailers/ubereats_criteoretail.png',
      key: 'ubereats_criteoretail',
      route: 'ubereats_criteo/retail'
    },
    target_rmsretail: {
      label: 'Target RMS',
      imgSrc: 'https://cdn.rboomerang.com/images/retailers/targetretail.png',
      key: 'target_rmsretail',
      route: 'target_rms/retail'
    }
  },
  regions: {
    EU: 'eu',
    US: 'us',
    UK: 'uk',
    CA: 'ca',
    FR: 'fr',
    DE: 'de',
    ES: 'es',
    IT: 'it',
    MX: 'mx',
    NL: 'nl',
    PL: 'pl',
    AU: 'au'
  },
  v2TopMoversSupportedRetailers: ['amazon_3p', 'amazon_b2b', 'amazon_hybrid'],
  v2TopMoversSupportedRegions: ['eu'],
  plotTrendlineSupportedRetailers: ['amazon_3p', 'amazon_hybrid'],
  plotTrendlineSupportedRegions: [],
  callGetCatalogAsinPages: [
    'regional_availability',
    'businessInsights',
    'GlobalSearch',
    'Price War',
    'Reports',
    'Reviews & Ratings',
    'Content Scorecard',
    'shortages',
    'price_variance',
    'chargeback_claims',
    'Forecast',
    'PO Fill Rate',
    'AMS SKUs',
    'SKUs',
    'Sales Overview',
    'Financial',
    'custom-reports',
    'SelfServeReport',
    'SelfServeReportFallback',
    'Automations',
    'SkuDetails'
  ],
  callFetchMaxDatesPages: [
    'Share of voice',
    'GlobalSearch',
    'recommendationsLanding',
    'cva',
    'Price War',
    'regional_availability',
    'Reviews & Ratings',
    'Shipment Tracker',
    'Forecast',
    'PO Fill Rate',
    'edit-strategy',
    'StrategyList',
    'market_insights',
    'StrategyBuilder',
    'budget-planner-dashboard',
    'budget-planner-dashboard-v2',
    'Media Planner',
    'Campaigns',
    'Keywords',
    'Search Terms',
    'AMS SKUs',
    'ActionLogs',
    'inventory_availability',
    'Sales Overview',
    'Financial',
    'custom-reports',
    'SelfServeReport',
    'SelfServeReportFallback',
    'product-diagnostics-dashboard',
    'cross-retailer-overview',
    'EntityDetails',
    'SubEntityDetails',
    'InstacartEntityDetails',
    'samsclub_apiEntityDetails',
    'walmartEntityDetails',
    'dvtInternalTool',
    'productSLA'
  ],
  callGetCatalogAsinInSync: ['GlobalSearch']
};
