import Vue from 'vue';
import CONSTANTS from '@/utils/constants.js';
import { getProgressEstimatedTime } from '@/utils/helpers/utils.js';

function getTextConfigsForComponents(currentEntity) {
  return function (bTaxonomyExists, redirectCountdownTimer) {
    return {
      bulkUpload: {
        sectionTitle: `Upload your ${currentEntity.altLabel}`,
        sectionDesc: currentEntity.sectionDesc,
        nextButtonText: !bTaxonomyExists
          ? 'Review and Create '
          : 'Review and Overwrite',
        backButtonText: 'Back',
        downloadTemplateText: `Download ${currentEntity.altLabel} Template`,
        entityName: `${currentEntity.altLabel}`,
        pageTitle: `Manage ${currentEntity.altLabel}`,
        pageDesc: 'Preview desc',
        pageDescription: `Preview, edit and create ${currentEntity.altLabel}`,
        statusText: `Please fix these errors to succesfully <span class="u-font-weight-600"> Overwrite ${currentEntity.altLabel} </span> with the above file.`
      },
      welcome: {
        ButtonText: `Create ${currentEntity.altLabel}`,
        Description: `Looks like you haven’t created a ${currentEntity.altLabel} yet!`
      },
      page: {
        Title: `Manage ${currentEntity.altLabel}`,
        Description: `Preview, edit and create ${currentEntity.altLabel}`
      },
      success: {
        text: `${
          currentEntity.altLabel
        } is created succesfully! <span class="u-color-orange-base">It will take upto ${getProgressEstimatedTime(
          Vue.options.filters.config_check(
            'feature.ingestion.progress_estimate_minutes'
          )
        )}</span> for ${currentEntity.altLabel} Updation or Creation!`,
        subText: `<span class="u-font-style-italic">Redirecting to ${currentEntity.altLabel} Overview in <span class="u-color-blue-base"">${redirectCountdownTimer}...</span></span>`
      }
    };
  };
}

function getCurrentUploadType(bTaxonomyExists) {
  return bTaxonomyExists ? 'overwrite' : 'create';
}

export default ({ config: currentEntity, context, retailer }) => {
  return {
    dataProps: {
      bulkChangeWarningText: `Uploading a new excel sheet will overwrite all the existing ${currentEntity.label} filters.`,
      entityData: {
        file: null,
        sheetName: currentEntity.sheetName,
        entity: currentEntity.entity,
        skipFormatting: false,
        skipPostTrigger: true
      },
      path: currentEntity.path,
      upload: {
        currentType: getCurrentUploadType(context.bTaxonomyExists),
        innerComponents: {
          'select-upload-type': [
            {
              name: 'reUploadSelect',
              props: {
                text: `Overwrite ${currentEntity.altLabel}`
              },
              events: {
                onReUploadOptionSelect: context.onReUploadOptionSelect,
                onDownloadPress: (e) =>
                  context[currentEntity.download](e, false)
              }
            }
          ],
          bulk_upload: [
            {
              name: 'rb-button',
              props: {
                text: 'Remove',
                clickFn: context.onFileRemove,
                class: 'u-bg-color-blue-base u-spacing-ph-l u-color-grey-white'
              },
              events: {
                click: context.onFileRemove
              }
            }
          ]
        }
      },
      manageTaxonomyOptions: {
        tableEditMode: 'view',
        gridApi: {
          context: {
            componentParent: context
          },
          rowClassRules: {},
          defaultColDef: {
            flex: 1,
            wrapText: true,
            autoHeight: true
          }
        },
        searchPlaceholder: `Search for a ${currentEntity.entityName} or ${currentEntity.altLabel}`,
        editOptions: [
          {
            title: `Edit a few ${currentEntity.label} values`,
            internalFnTrigger: 'enableInlineTableEdit'
          },
          {
            title: `Create a ${currentEntity.label} column`,
            internalFnTrigger: 'onTriggerColumnCreate'
          },
          {
            title: 'Overwrite with an Excel sheet',
            internalFnTrigger: 'enableReUploadFlow'
          }
        ],
        actions: [
          {
            icon: 'download',
            text: 'DOWNLOAD',
            toolTipText: `Downloads an Excel with the existing ${currentEntity.altLabel} values.`,
            IconClassName: '',
            internalFnTrigger: context[currentEntity.download]
          }
        ],
        catalogCrudOperations: {}
      },
      columnMetaMapMT: {
        default: (columnContext) => {
          return {
            widget: 'DynamicCellComponent',
            dynamicCellComponentParams: {
              componentName: 'editableTagsCell',
              paramsToProps: (params) => {
                const asin = params.data[currentEntity.secondaryKey];
                const column =
                  params.colDef.headerComponentParams.props.fieldName;
                const currentColumm =
                  (params.context.componentParent.previewData.dimensionValueAndCountList.filter(
                    (dataColumn) => {
                      if (
                        dataColumn.dimensionColumn ===
                        params.colDef.headerComponentParams.props.fieldName
                      ) {
                        return true;
                      }
                    }
                  ) || [])[0];
                const pendingCrudOperations =
                  context.manageTaxonomyOptions.catalogCrudOperations;
                const currentEntities = params.data.data.map((item) => {
                  const dimensionRecordIdCellData = {};
                  dimensionRecordIdCellData.title =
                    item[params.colDef.headerComponentParams.props.fieldName];
                  if (
                    pendingCrudOperations?.[asin]?.[item.recordId]?.[column]
                      ?.length >= 0
                  ) {
                    dimensionRecordIdCellData.title = pendingCrudOperations?.[
                      asin
                    ]?.[item.recordId]?.[column].length
                      ? pendingCrudOperations?.[asin]?.[item.recordId]?.[
                          column
                        ][0]
                      : '';
                  }
                  dimensionRecordIdCellData.recordId = item.recordId;
                  return dimensionRecordIdCellData;
                });
                return {
                  metadata: {
                    asin,
                    column,
                    columnLabel:
                      params.colDef.headerComponentParams.props.displayName
                  },
                  allSuggestionEntities: (
                    currentColumm.availableValues || []
                  ).filter((value) => {
                    return value;
                  }),
                  interactiveState: context.getTableInteractiveState(
                    context.manageTaxonomyOptions.tableEditMode,
                    asin,
                    column
                  ),
                  currentEntities: currentEntities,
                  editableCells: context.editableCells,
                  preferEmitter: true
                };
              },
              eventMap: {
                tagClick: 'onTagsSelect',
                tagsChanged: 'catalogTagChange'
              }
            },
            headerComponent: {
              bDynamicHeaderComponent: true,
              componentName: 'EditableHeaderWithOptions',
              columnDefsToProps: (params) => {
                return {
                  columnDefn: params.column
                };
              },
              props: {
                displayName: `${columnContext.dimensionLabel}`,
                headerCount: `${columnContext.uniqueValueCount}`,
                fieldName: columnContext.dimensionColumn,
                headerIcon: {
                  icon: 'plus',
                  classes:
                    'u-color-orange-base u-spacing-ph-s u-cursor-pointer',
                  show: true,
                  tooltipTitle: `Add new filter under '${columnContext.dimensionLabel}'`
                },
                entity: currentEntity.entity
              },
              eventMap: {
                iconClicked: 'onHeaderAddIconClick',
                onDelete: 'onColumnDeleteTrigger',
                onRename: 'onColumnRename',
                onRenameCancel: 'onRenameCancel'
              }
            },
            showOnUi: true,
            isDownloadable: true,
            isFixed: false,
            sortOnColumn: true,
            isDefaultSortColumn: false,
            width: currentEntity.defaultColumnWidth || 250
          };
        },
        ...currentEntity.columnConfigs(retailer),
        newColumn: (columnContext) => {
          return {
            widget: 'DynamicCellComponent',
            dynamicCellComponentParams: {
              componentName: 'editableTagsCell',
              paramsToProps: (params) => {
                return {
                  allSuggestionEntities: [],
                  interactiveState: context.manageTaxonomyOptions.tableEditMode,
                  currentEntities: [{ title: 'Create a value' }],
                  dropdownOptions: [
                    { title: 'Assign a different brand', key: 'change_brand' },
                    {
                      title: `Manage ${currentEntity.entityName}s in brand`,
                      key: 'manage_brand'
                    },
                    {
                      title: `Remove brand from this ${currentEntity.entityName}`,
                      key: 'remove_brand'
                    }
                  ]
                };
              },
              eventMap: {
                iconClicked: 'onHeaderAddIconClick',
                onDelete: 'onColumnDeleteTrigger',
                onRename: 'onColumnRename',
                onRenameCancel: 'onRenameCancel'
              }
            },
            headerComponent: {
              bDynamicHeaderComponent: true,
              componentName: 'EditableHeaderWithOptions',
              columnDefsToProps: (params) => {
                return {
                  columnDefn: params.column,
                  initState: 'edit'
                };
              },
              props: {
                displayName: '',
                fieldName: columnContext.dimensionColumn,
                headerIcon: {
                  icon: 'plus',
                  classes:
                    'u-color-orange-base u-spacing-ph-s u-cursor-pointer',
                  show: true
                }
              },
              eventMap: {
                iconClicked: 'onHeaderAddIconClick',
                onDelete: 'onColumnDeleteTrigger',
                onRename: 'onColumnRename',
                onRenameCancel: 'onRenameCancel'
              }
            },
            showOnUi: true,
            isDownloadable: true,
            isFixed: false,
            sortOnColumn: true,
            isDefaultSortColumn: false,
            cellClass: 'u-border-h-s',
            width: 150
          };
        }
      },
      primaryKey: currentEntity.secondaryKey,
      readOnlyColumns: currentEntity.readOnlyColumns,
      editableTableConfig: currentEntity.editableTableConfig(retailer),
      esDataSetName: currentEntity.esDataSetName,
      entityName: currentEntity.entityName,
      selectTaxonomyConfig: {
        entityName: currentEntity.entityName,
        altLabel: currentEntity.altLabel,
        label: currentEntity.selectTaxonomyLabel,
        infoText: currentEntity.infoText
      },
      helpDialogPath: CONSTANTS.s3AssetsPath[currentEntity.entity]
    },
    computedProps: {
      textConfigs: getTextConfigsForComponents(currentEntity),
      addReadOnlyColumns: (columnList, retailer) => [
        ...currentEntity.readOnlyColumns[retailer].map((col) => ({
          dimensionLabel: col.value,
          dimensionColumn: col.key,
          uniqueValueCount: 100
        })),
        ...columnList
      ],
      getEntityReadAPIRequestBody: (retailer) => {
        return {
          metrics: currentEntity.readOnlyColumns[retailer].map(
            ({ key }) => key
          ),
          entityColumn: currentEntity.primaryKey
        };
      }
    }
  };
};
