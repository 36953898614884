<template>
  <section
    ref="breakdownTable"
    class="u-display-flex u-flex-direction-column"
  >
    <section
      v-if="isTableData"
      class="u-display-flex u-flex-direction-column u-height-400"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        color="#007cf6"
      />
    </section>
    <section
      v-else
      class="u-position-relative"
    >
      <loader
        class="u-width-100 u-height-100 fill--parent opacity-0_5"
        :loading="isLoading"
        color="#3684bb"
      />
      <rb-insights-table
        class="breakdownTable"
        :table-column="getColumnDefs"
        :table-row="tableData"
        :grid-options="searchTermsTableGridOptions"
      />
    </section>
  </section>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import BrandRankCell from '@/components/pages/insights/amazon/cva/atoms/brand-rank-cell.vue';
import parentChild from '@/components/pages/insights/amazon/market_share_pro/atoms/parentChild.vue';
import Vue from 'vue';
import loader from '@/components/basic/loader';
import {
  categoryMapping,
  getMetricDetails,
  buildUIMetricMapping,
  getMetricsMapping,
  msProConstants,
  setGlobalSettingsForMS,
  constants,
  getCategoryLevel,
  overviewSortMetrics,
  getBasicRequestFormat
} from '@/components/pages/insights/amazon/market-share/utils';
import CategoryBreakdown from '@/components/pages/insights/amazon/market_share_pro/service/categoryBreakdown.js';
import { eventBus } from '@/utils/services/eventBus';
import { fetchDownloadStatusAtInterval } from '@/components/widgets/downloadCenter/downloadCenterUtils.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  owner: 'Saumya',
  components: {
    loader,
    BrandRankCell
  },
  props: {
    globalViewId: {
      type: Number,
      required: true
    },
    filterData: {
      type: [Object, Array],
      default: () => {}
    },
    globalSettings: {
      type: [Array, Object],
      default: () => {}
    },
    selectedCategory: {
      type: String,
      default: ''
    },
    sortConfiguration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      topBrandsData: {},
      topCompetitorBrandsData: {},
      dataRequest: null,
      myTopBrandsRequest: null,
      competitorTopBrandsRequest: null,
      myBrandsApiFailure: false,
      competitorBrandsApiFailure: false,
      tableData: [],
      searchTermsTableGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      readApiResponse: {},
      metricMapping: {},
      metricDataGroupMapping: {},
      isTableData: true,
      entityTypeCategory: 'category',
      columnDefs: [],
      categoryBreakdownService: null
    };
  },
  computed: {
    getColumnDefs() {
      return this.columnDefs;
    }
  },
  watch: {
    globalSettings: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.isTableData = true;
          this.topBrandsData = {};
          this.topCompetitorBrandsData = {};
          this.myBrandsApiFailure = false;
          this.competitorBrandsApiFailure = false;
          this.getTableMetadata();
        }
      },
      deep: true,
      immediate: true
    },
    sortConfiguration: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.topBrandsData = {};
          this.topCompetitorBrandsData = {};
          this.myBrandsApiFailure = false;
          this.competitorBrandsApiFailure = false;
          this.getCategoryData();
          this.getBrandsData(false, []);
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    Vue.component('BrandRankCell', BrandRankCell);
    this.setFilterSelectionList();
    this.getTableMetadata();
    eventBus.$on('breakdown-download-clicked', () => {
      this.downloadReport();
    });
  },
  destroyed() {
    eventBus.$off('breakdown-download-clicked');
  },
  mounted() {
    Vue.component('parentChild', parentChild);
    this.categoryBreakdownService = new CategoryBreakdown();
  },
  methods: {
    setFilterSelectionList() {
      setGlobalSettingsForMS(
        constants.FILTER_SELECTION,
        this.filterData?.category
      );
    },
    constructTableData(responseTableData) {
      const tableData = (responseTableData || []).map((entity) => {
        return entity.data.reduce((previousVal, currentVal) => {
          const metricName = currentVal.name;
          const metricMapping = this.uiMetricMapping;
          if (metricMapping[metricName]) {
            previousVal[metricMapping[metricName]] =
              currentVal.RESULT?.[currentVal.name];
            const metricMetaData =
              this.readApiResponse.metrics[metricName]?.metadata;
            if (metricMetaData) {
              previousVal[`${metricMapping[metricName]}_absoluteType`] =
                metricMetaData.unit !== '' ? metricMetaData.unit : 'NUMERIC';
              previousVal[`${metricMapping[metricName]}_invertTag`] =
                metricMetaData.isInverted;
            }
            if (currentVal.PVP) {
              previousVal['PVP_DIFF_' + metricMapping[metricName]] =
                currentVal.PVP['PVP_DIFF_' + currentVal.name];
              previousVal['PVP_' + metricMapping[metricName]] =
                currentVal.PVP['PVP_' + currentVal.name];
            }
          } else {
            previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
          }
          previousVal.categoryId = entity.categoryId;
          previousVal.entityType = entity.entityType;
          previousVal.hasChild = entity.hasChild;
          previousVal.expanded = false;
          previousVal.childEntityType = entity.childEntityType;
          previousVal.parentId = entity.parentId;
          previousVal.filterToApply = entity.filterToApply;
          previousVal.myBrandsLoader = entity.myBrandsLoader;
          previousVal.myBrandsApiFailed = entity.myBrandsApiFailed;
          previousVal.competitorBrandsLoader = entity.competitorBrandsLoader;
          previousVal.competitorBrandsApiFailed =
            entity.competitorBrandsApiFailed;
          previousVal.myBrandsData = entity.myBrandsData;
          previousVal.compBrandsData = entity.compBrandsData;
          return previousVal;
        }, {});
      });
      return tableData;
    },
    makeBundledRequest(
      parentRequestFormat,
      metricDataGroupMapping,
      entityType,
      subCategoryNode
    ) {
      const parentRequest = getBasicRequestFormat(
        parentRequestFormat,
        this.globalSettings,
        entityType
      );
      parentRequest.operations.orderByList = [];
      parentRequest.operations.bundleRequestJoinType = 'FULL_OUTER_JOIN';
      parentRequest.operations.isChainingJoinEnabled = true;
      for (const metricKey in metricDataGroupMapping) {
        let metricRequest = metricDataGroupMapping[metricKey].request;
        if (
          parentRequest?.bundleDataGroupsRequest?.[
            metricDataGroupMapping[metricKey]?.dataGroup
          ]
        ) {
          parentRequest?.bundleDataGroupsRequest[
            metricDataGroupMapping[metricKey].dataGroup
          ].metricsList.push(metricDataGroupMapping[metricKey].metricList);
        } else {
          metricRequest = getBasicRequestFormat(
            metricRequest,
            this.globalSettings,
            entityType
          );
          if (entityType === this.entityTypeCategory) {
            let dimensionList =
              this.globalSettings?.where?.dimensionNameValueList;
            if (this.selectedCategory) {
              dimensionList = [
                {
                  dimensionName: 'category_hierarchy',
                  dimensionValue: this.selectedCategory,
                  operator: 'STARTSWITH'
                }
              ];
            }
            metricRequest.where.dimensionNameValueList = [
              ...dimensionList,
              ...metricRequest.where.dimensionNameValueList
            ];
          } else {
            const dimensionList = [
              {
                dimensionName: 'category_hierarchy',
                dimensionValue: subCategoryNode,
                operator: 'STARTSWITH'
              }
            ];
            metricRequest.where.dimensionNameValueList = [
              ...dimensionList,
              ...metricRequest.where.dimensionNameValueList
            ];
          }
          metricRequest.metricsList[0] =
            metricDataGroupMapping[metricKey].metricList;
          metricRequest.operations.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions =
            metricDataGroupMapping[
              metricKey
            ].request.operations.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions;
          this.updateSortConfiguration(
            parentRequest,
            metricDataGroupMapping,
            metricKey
          );
          metricRequest.operations.orderByList = [];
          parentRequest.bundleDataGroupsRequest[
            metricDataGroupMapping[metricKey].dataGroup
          ] = metricRequest;
        }
      }
      return parentRequest;
    },

    updateSortConfiguration(parentRequest, metricDataGroupMapping, metricKey) {
      const sortByMetric = this.sortConfiguration.metric;
      if (overviewSortMetrics[sortByMetric] === metricKey) {
        const metricUnit = metricDataGroupMapping[metricKey].metricUnit;
        const PVP_DIFF_TYPE =
          metricUnit === 'PERCENTAGE' ? 'pvp_diff_' : 'pvp_';
        let metricName = metricDataGroupMapping[metricKey].metricList;
        if (this.sortConfiguration.sortingOptions.sortByChange) {
          metricName = `${PVP_DIFF_TYPE}${metricDataGroupMapping[metricKey].metricList}`;
        }
        parentRequest.operations.orderByList.push({
          dimension: metricName,
          direction: this.sortConfiguration.sortingOptions.direction
        });
      }
    },
    updateResponse(responseData, parentCategoryData, subCategoryLevel) {
      return responseData
        .filter((category) => category.entityValue ?? false)
        .map((category) => {
          if (category.entityType === this.entityTypeCategory) {
            category.categoryId = `${category.entityValue}~`.toLowerCase();
          } else {
            category.categoryId =
              `${parentCategoryData.categoryId}${category.entityValue}~`.toLowerCase();
          }
          category.hasChild = categoryMapping(this.filterData?.category, {})[
            category.categoryId
          ];
          if (category.entityType === this.entityTypeCategory) {
            if (category.hasChild) {
              category.childEntityType = `sub_category_1`;
            }
            category.parentId = null;
            category.filterToApply = category.entityValue;
          } else {
            if (category.hasChild) {
              const childCategoryLevel = getCategoryLevel(
                parentCategoryData.childEntityType
              );
              category.childEntityType = `sub_category_${
                childCategoryLevel + 1
              }`;
            }
            category.parentId = parentCategoryData.categoryId;
            category.filterToApply = `${parentCategoryData.filterToApply}----${category.entityValue}`;
          }
          category.myBrandsLoader = true;
          category.myBrandsApiFailed = false;
          category.competitorBrandsLoader = true;
          category.competitorBrandsApiFailed = false;
          return category;
        });
    },
    async getTableMetadata() {
      const body = {
        widget: 'ms_pro_2.0_overview_w1_breakdown',
        page: 'MS_PRO_2.0-EXECUTIVE',
        globalViewId: this.globalViewId
      };
      try {
        const response = await HttpService.post('DASHBOARD_SERVICE', body, {
          append: '/widget/metadata/read'
        });
        this.readApiResponse = response.data;
        this.metricMapping = await getMetricsMapping(this.readApiResponse, {});
        this.getCategoryData();
        this.getBrandsData(false, []);
      } catch (error) {
        this.isTableData = false;
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },

    aggregateApiData() {
      if (this.tableData.length > 0) {
        this.handleMyBrandsApiFailure();
        this.handleCompetitorBrandsApiFailure();
        this.populateMyBrandsData();
        this.populateCompetitorBrandsData();
      }
    },
    handleMyBrandsApiFailure() {
      if (this.myBrandsApiFailure) {
        for (let categoryRow of this.tableData) {
          categoryRow.myBrandsLoader = false;
          categoryRow.myBrandsApiFailed = true;
        }
        this.myBrandsApiFailure = false;
      }
    },
    handleCompetitorBrandsApiFailure() {
      if (this.competitorBrandsApiFailure) {
        for (let categoryRow of this.tableData) {
          categoryRow.competitorBrandsLoader = false;
          categoryRow.competitorBrandsApiFailed = true;
        }
        this.competitorBrandsApiFailure = false;
      }
    },
    populateMyBrandsData() {
      if (Object.keys(this.topBrandsData).length > 0) {
        for (let categoryRow of this.tableData) {
          const category = categoryRow.categoryId;
          if (!this.topBrandsData.hasOwnProperty(category)) {
            continue;
          }
          categoryRow.myBrandsLoader = false;
          categoryRow.myBrandsApiFailed = false;
          categoryRow.myBrandsData = this.topBrandsData[category];
        }
      }
    },
    populateCompetitorBrandsData() {
      if (Object.keys(this.topCompetitorBrandsData).length > 0) {
        for (let categoryRow of this.tableData) {
          const category = categoryRow.categoryId;
          if (!this.topCompetitorBrandsData.hasOwnProperty(category)) {
            continue;
          }
          categoryRow.competitorBrandsLoader = false;
          categoryRow.competitorBrandsApiFailed = false;
          categoryRow.compBrandsData = this.topCompetitorBrandsData[category];
        }
      }
    },

    buildDimensionValueList(subCategoryList) {
      let dimensionList = [];
      for (let subCategory of subCategoryList) {
        dimensionList.push({
          dimensionName: 'category_hierarchy',
          dimensionValue: subCategory.categoryId,
          operator: 'STARTSWITH'
        });
      }
      return dimensionList;
    },

    async getBrandsData(isSubcategory, subCategoryList) {
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      const displayType =
        this.globalSettings?.settingValue?.displayShareIn?.label;
      const metricName =
        this.readApiResponse.metadata.topBrandsMetric[displayType][
          threepSelection
        ];
      const metricObj = this.metricMapping[metricName];
      const request = getBasicRequestFormat(
        metricObj.request,
        this.globalSettings,
        'brand'
      );
      request.dimensionsList.push('client_flag');
      let dimensionList = this.globalSettings?.where?.dimensionNameValueList;
      request.metricsList[0] = metricObj.metricList;
      if (this.selectedCategory) {
        dimensionList = [
          {
            dimensionName: 'category_hierarchy',
            dimensionValue: this.selectedCategory,
            operator: 'STARTSWITH'
          }
        ];
      }
      request.where.dimensionNameValueList = dimensionList;
      request.operations.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions.push(
        'category_hierarchy'
      );
      request.operations.limit = 3;
      request.operations.enableDedupBeforeRollup = true;
      request.operations.timeseriesEnabled = false;
      request.operations.orderByList = [
        {
          dimension: metricObj.metricList,
          direction: 'DESC'
        }
      ];
      if (isSubcategory) {
        const subCategories = this.buildDimensionValueList(subCategoryList);
        request.where.dimensionNameValueList = [...subCategories];
      }
      let myTopBrandsRequest = JSON.parse(JSON.stringify(request));
      let competitorTopBrandsRequest = JSON.parse(JSON.stringify(request));
      myTopBrandsRequest.operations.outerWhereClause = {
        dimensionNameValueList: [
          {
            dimensionName: 'client_flag',
            dimensionValue: 'client'
          }
        ]
      };
      competitorTopBrandsRequest.operations.outerWhereClause = {
        dimensionNameValueList: [
          {
            dimensionName: 'client_flag',
            dimensionValue: 'comp'
          }
        ]
      };
      this.myTopBrandsRequest = myTopBrandsRequest;
      this.competitorTopBrandsRequest = competitorTopBrandsRequest;
      let myBrandsPostReq = HttpService.post(
        'MARKETSHARE',
        myTopBrandsRequest,
        {
          append: '/marketshare/metrics/data/parallel/exec'
        }
      );
      let competitorBrandsPostReq = HttpService.post(
        'MARKETSHARE',
        competitorTopBrandsRequest,
        {
          append: '/marketshare/metrics/data/parallel/exec'
        }
      );

      Promise.allSettled([myBrandsPostReq, competitorBrandsPostReq]).then(
        (results) => {
          if (results[0].status === 'fulfilled') {
            this.extractTopBrands(results[0].value.data, true);
          } else {
            this.myBrandsApiFailure = true;
          }
          if (results[1].status === 'fulfilled') {
            this.extractTopBrands(results[1].value.data, false);
          } else {
            this.competitorBrandsApiFailure = true;
          }
          this.aggregateApiData();
        }
      );
    },

    extractTopBrands(categoryData, isMyBrands) {
      let categoryBrandData = {};
      for (const category in categoryData) {
        let eachCategoryData = categoryData[category].entityData;
        const brandMetricsArray = (eachCategoryData || []).map((entity) => {
          return entity.data.reduce((previousVal, currentVal) => {
            const metricName = currentVal.name;
            if (currentVal.PVP) {
              previousVal.marketShare = currentVal.RESULT[metricName];
              previousVal.pvpMarketShare =
                currentVal.PVP[`PVP_DIFF_${metricName}`];
            } else {
              previousVal[metricName] = currentVal.RESULT?.[metricName];
            }
            return previousVal;
          }, {});
        });
        const rankedBrandMetrics = brandMetricsArray.reduce(
          (accumulatedBrandsList, currentBrand, index) => {
            accumulatedBrandsList[index + 1] = {
              name: currentBrand.brand,
              clientFlag: currentBrand.client_flag,
              marketShare: currentBrand.marketShare,
              pvpMarketShare: currentBrand.pvpMarketShare
            };
            return accumulatedBrandsList;
          },
          {}
        );
        categoryBrandData[category] = rankedBrandMetrics;
      }
      if (isMyBrands) {
        this.topBrandsData = { ...this.topBrandsData, ...categoryBrandData };
      } else {
        this.topCompetitorBrandsData = {
          ...this.topCompetitorBrandsData,
          ...categoryBrandData
        };
      }
    },

    async getCategoryData() {
      this.isTableData = true;
      const metricDataForPayload = getMetricDetails(
        this.readApiResponse.metadata,
        this.globalSettings,
        this.metricMapping
      );
      this.columnDefs = this.categoryBreakdownService.updateColDefs(
        metricDataForPayload,
        this.sortConfiguration
      );
      this.metricDataGroupMapping = metricDataForPayload;
      this.uiMetricMapping = buildUIMetricMapping(metricDataForPayload);
      const dataRequest = this.makeBundledRequest(
        this.readApiResponse.metadata.dataAPI.request,
        metricDataForPayload,
        this.entityTypeCategory
      );
      this.dataRequest = dataRequest;
      try {
        const response = await HttpService.post('MARKETSHARE', dataRequest, {
          append: '/marketshare/metrics/data/exec'
        });
        const tableData = this.updateResponse(response.data.entityData);
        this.tableData = this.constructTableData(tableData);
        this.aggregateApiData();
        this.isTableData = false;
      } catch (error) {
        this.isTableData = false;
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    async getSubcategory(params, subCategoryLevel) {
      this.isLoading = true;
      const dataRequest = this.makeBundledRequest(
        this.readApiResponse.metadata.dataAPI.request,
        this.metricDataGroupMapping,
        params.data.childEntityType,
        params.data.categoryId
      );
      try {
        const response = await HttpService.post('MARKETSHARE', dataRequest, {
          append: '/marketshare/metrics/data/exec'
        });
        const tableData = this.updateResponse(
          response.data.entityData,
          params.data,
          subCategoryLevel
        );
        return tableData;
      } catch (error) {
        this.isLoading = false;
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    getAddIndex(rowIndex) {
      let addIndex = rowIndex + 1;
      return addIndex;
    },
    closeCategoryExpand(params) {
      for (let i = this.tableData.length - 1; i >= 0; i--) {
        if (this.tableData[i].categoryId === params.data.categoryId) {
          this.tableData[i].expanded = false;
        }
        if (this.tableData[i].parentId?.includes(params.data.categoryId)) {
          // Removing subcategory rows when parent category is collapsed
          delete this.topBrandsData[this.tableData[i].categoryId];
          delete this.topCompetitorBrandsData[this.tableData[i].categoryId];
          this.tableData.splice(i, 1);
        }
      }
    },
    async handleCategoryExpandButton(params, isExpanded) {
      if (isExpanded) {
        const addIndex = this.getAddIndex(params.rowIndex);
        const subTableResponse = await this.getSubcategory(params, addIndex);
        if (subTableResponse) {
          const subTableData = this.constructTableData(subTableResponse);
          this.tableData.map((item) => {
            if (item[item.entityType] === params.data[params.data.entityType]) {
              item.expanded = isExpanded;
            }
          });
          this.tableData.splice(addIndex, 0, ...subTableData);
          this.getBrandsData(true, subTableResponse);
          this.isLoading = false;
        }
      } else {
        this.isLoading = true;
        this.closeCategoryExpand(params);
        this.isLoading = false;
      }
    },
    async downloadReport() {
      try {
        const requestId = uuidv4();
        const orchestratorPayload =
          this.generateOrchestratorAPICallPayload(requestId);
        return HttpService.post(
          'ORCHESTRATOR_TRIGGER_DOWNLOAD',
          orchestratorPayload
        )
          .then(() => {
            eventBus.$emit('triggerDownloadingAnimation');
            this.$store.dispatch('fetchRecentDownloads');
            fetchDownloadStatusAtInterval(orchestratorPayload?.request_id);
            eventBus.$emit('overview-breakdown-download-initiated');
          })
          .catch(() => {
            eventBus.$emit('triggerDownloadingAnimation');
            eventBus.$emit('overview-breakdown-download-initiated');
            this.$store.dispatch('fetchRecentDownloads');
            this.$snackbar.open({
              message: `<span class="u-display-flex u-align-items-center u-spacing-pr-xl"><span class="rb-icon icon-info-circle-fill rb-icon--medium u-color-red-base"></span> <span class="u-spacing-pt-xxs u-spacing-pl-s">Download Failed for 1 file</span></span>`,
              duration: 5000,
              showIcon: false,
              customStyles:
                'position:fixed; top:16px; left:50%; transform:translate(-50%,0%);',
              actionText: 'View downloads',
              onAction: () => {
                eventBus.$emit('downloadCenterOpen', 'open');
              },
              buttonColor: '#FFD500',
              type: 'u-text-case-normal',
              actionTextCase: 'none'
            });
          });
      } catch (error) {
        eventBus.$emit('overview-breakdown-download-initiated');
        const errorMessage = `Failed to download report : ${
          error.message || 'Unknown error'
        }`;
        this.$snackbar.open({
          message: errorMessage,
          duration: 6000,
          actionText: ''
        });
        console.error(errorMessage, error);
      }
    },
    generateOrchestratorAPICallPayload(requestId) {
      let requestPayload = {
        service: 'MARKETSHARE_SERVICE_DOWNLOAD',
        endpoint: '/marketshare/entity/report/download',
        payload: this.dataRequest
      };
      let categoryListpayload =
        this.globalSettings.where.dimensionNameValueList;
      if (this.selectedCategory) {
        categoryListpayload = [
          {
            dimensionName: 'category_hierarchy',
            dimensionValue: this.selectedCategory,
            operator: 'STARTSWITH'
          }
        ];
      }
      requestPayload.payload.requestId = requestId;
      requestPayload.payload.where.dimensionNameValueList = categoryListpayload;

      const { bundleDataGroupsRequest } = requestPayload.payload;
      bundleDataGroupsRequest.ms_gv.enablePaginationCount = false;
      bundleDataGroupsRequest.ms_po.enablePaginationCount = false;
      bundleDataGroupsRequest.top_client_brands = this.myTopBrandsRequest;
      bundleDataGroupsRequest.top_client_brands.where.dimensionNameValueList =
        categoryListpayload;
      bundleDataGroupsRequest.top_comp_brands = this.competitorTopBrandsRequest;
      bundleDataGroupsRequest.top_comp_brands.where.dimensionNameValueList =
        categoryListpayload;
      const generatedPayload = {
        request_id: requestId,
        folder_name: 'Overview Category Breakdown Report',
        location: 'MS > Overview Category Breakdown Report',
        request_payload: requestPayload,
        page_name: 'MS_PRO_2.0-EXECUTIVE',
        widget_name: 'ms_pro_2.0_overview_w1_breakdown',
        file_name: 'Overview Breakdown Report being generated'
      };
      return generatedPayload;
    }
  }
};
</script>

<style lang="css">
.breakdownTable div[col-id^='marketShareMetric'] {
  border-left: 1px solid #e9eaeb;
  border-right: 1px solid #e9eaeb;
}
.breakdownTable div[col-id^='marketShareMetric'].ag-cell {
  border-width: 0 1px !important;
  /* Set border width for top/bottom and left/right */
  border-style: solid !important;
  /* Set border style */
  border-color: #e9eaeb !important;
  /* Set border color */
}
.breakdownTable .ag-body {
  max-height: 475px;
}
.breakdownTable .ag-body .ag-body-viewport {
  max-height: 475px;
  overflow-y: auto;
}
</style>

<style lang="css" scoped>
.u-height-400 {
  height: 400px;
}
</style>
