<template>
  <section>
    <div
      v-if="load"
      class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-else-if="error"
      class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="noData"
      class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>No Data</p>
    </div>
    <section v-else>
      <div
        v-if="hasHeader"
        class="u-bg-color-grey-light u-font-size-6 u-color-grey-white u-spacing-p-m u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
      >
        <div>{{ currentStep.stepTitle }}</div>
        <span @click="handleCrossClick"
          ><rb-icon
            :icon="'cross'"
            class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross"
        /></span>
      </div>
      <keep-alive>
        <component
          :is="currentStep.component"
          v-if="currentStep.keepAlive"
          ref="popup"
          :next-step="currentStep.nextStep"
          v-bind="currentStep.props"
          :current-step-index="currentStepIndex"
          :current-path="currentPath"
          @input="handleInput"
          @radioSelection="handleRadioSelection"
          @isNextApplyActive="handleIsNextApplyActive"
          @expressionRadioSelection="handleExpressionRadioSelection"
          @queryData="handleQueryData"
          @incStep="handleIncStep"
          @decStep="handleDecStep"
          @cancel="handleCancel"
          @apply="handleApply"
          @selection="handleSelection"
          @clearSelections="handleClearSelection"
        />
      </keep-alive>
      <component
        :is="currentStep.component"
        v-if="!currentStep.keepAlive"
        ref="popup"
        :next-step="currentStep.nextStep"
        v-bind="currentStep.props"
        :current-step-index="currentStepIndex"
        :current-path="currentPath"
        @input="handleInput"
        @radioSelection="handleRadioSelection"
        @isNextApplyActive="handleIsNextApplyActive"
        @expressionRadioSelection="handleExpressionRadioSelection"
        @queryData="handleQueryData"
        @incStep="handleIncStep"
        @decStep="handleDecStep"
        @cancel="handleCancel"
        @apply="handleApply"
        @selection="handleSelection"
        @clearSelections="handleClearSelection"
      />
    </section>
  </section>
</template>

<script>
import Vue from 'vue';
import ruleBuilderRadioSelect from '../atoms/rule-builder-radio-select';
import ruleBuilderExpression from '../atoms/rule-builder-expression.vue';
import ruleBuidlerMetrics from '../atoms/rule-builder-metrics.vue';
import ruleBuilderTextInput from '../atoms/rule-builder-text-input';
import loader from '@/components/basic/loader';
import { debounce } from 'lodash';
export default {
  components: {
    ruleBuilderRadioSelect,
    ruleBuilderExpression,
    ruleBuidlerMetrics,
    ruleBuilderTextInput,
    loader
  },
  props: {
    hasHeader: {
      type: Boolean,
      default: true
    },
    load: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    root: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentPath: [],
      currentStepIndex: 0,
      tempSelections: [],
      isTippyActive: false,
      isNextApplyActive: true
    };
  },
  computed: {
    currentStep() {
      return this.currentPath[this.currentStepIndex];
    },
    isLastStep() {
      return false;
      // if (this.currentStepIndex < this.stepConfig.length - 1) {
      //   return false;
      // }
      // return true;
    }
  },
  watch: {
    root: {
      immediate: true,
      handler(newRoot) {
        this.currentStepIndex = 0;
        this.currentPath = [this.root];
      }
    }
  },
  created() {
    this.currentPath = [this.root];
  },
  methods: {
    setTempSelections(payload, stepIndex = 0) {
      if (this.tempSelections.length > this.currentStepIndex + 1) {
        this.tempSelections = this.tempSelections.slice(
          0,
          this.currentStepIndex
        );
      }
      if (!this.tempSelections[this.currentStepIndex]) {
        this.tempSelections[this.currentStepIndex] = [];
      }
      Vue.set(this.tempSelections[this.currentStepIndex], stepIndex, payload);
    },
    handleRadioSelection(data) {
      this.setTempSelections(data);
    },
    handleExpressionRadioSelection(data) {
      this.setTempSelections(data);
    },
    handleQueryData(data) {
      this.setTempSelections(data, 1);
    },
    handleInput(payload) {
      this.setTempSelections(payload);
    },
    handleClearSelection() {
      this.tempSelections = [];
    },
    handleSelection(payload) {
      this.setTempSelections(payload);
    },
    handleRuleBuilderChipCrossClick() {
      this.$emit('ruleApply', []);
    },
    handleIncStep(nextStep) {
      const debouncedIncrement = debounce(() => {
        this.currentStepIndex++;
        this.currentPath[this.currentStepIndex] = nextStep;
      });
      debouncedIncrement();
    },
    handleDecStep() {
      const debouncedDecrement = debounce(() => {
        if (this.currentStepIndex === 0) {
          return;
        }
        this.currentStepIndex--;
      });
      debouncedDecrement();
    },
    handleCancel() {
      this.resetToInitialPopupState();
      this.$emit('reset');
    },
    handleApply() {
      this.$emit('ruleApply', this.tempSelections);
      this.resetToInitialPopupState();
      this.$emit('reset');
    },
    handleIsNextApplyActive(data) {
      this.isNextApplyActive = data;
    },
    handleCrossClick() {
      this.resetToInitialPopupState();
      this.$emit('crossClick');
      this.$emit('reset');
    },
    resetToInitialPopupState() {
      this.currentStepIndex = 0;
    }
  }
};
</script>
