<template>
  <div
    class="bg__full-screen--semitransparent u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      class="u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center u-bg-color-grey-white u-border-radius-s"
      :class="{
        'u-width-100 u-height-100': isFullWidth,
        'u-dialog-wrap': !isFullWidth
      }"
    >
      <div class="u-overflow-hidden u-width-100 u-height-100">
        <span @click="closeDialog">
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-cross-icon-position u-cursor-pointer"
            :icon="'cross'"
          />
        </span>
        <div
          v-if="fullPath"
          class="u-display-flex u-flex-justify-content-space-between u-width-100 u-height-100 u-iframe-class u-border-radius-s u-border-all u-border-width-m u-border-color-grey-xxx-light"
        >
          <iframe
            class="u-width-100 u-height-100"
            :src="fullPath"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpDocumentDialog',
  props: {
    fullPath: {
      type: String,
      default: ''
    },
    isFullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    closeDialog(params) {
      this.$emit('closeCallback', params);
    }
  }
};
</script>

<style scoped lang="css">
.u-dialog-wrap {
  height: 80%;
  width: 80%;
  max-width: 740px;
}

.u-cross-icon-position {
  @include get-position(absolute);
  top: 4px;
  right: 10px;
  border-radius: 50%;
  z-index: 2;
  @include get-display(flex);
  color: #f1f1f1;
  @include get-flex-justify-content(center);
  @include get-flex-align-items(center);
  padding: 18px;
  margin: 9px;
  background: #333639;
}

.u-cross-icon-position:hover {
  background: #434649;
}
</style>
