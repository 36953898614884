import dictionary from '@/pages/toolsAndSettings/entityTagging/common/createCustomEntity/dictionary.js';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import { commons } from '@/components/ams/campaigns/commons.js';

const downloadConfig = [];
function getColumnDefinition(vueRef, pageFilterKey, table) {
  var _columnDefs = [];
  for (const i in dictionary[table]) {
    if (dictionary[table][i].page.includes(pageFilterKey)) {
      const obj = {};
      obj.headerComponentParams = {};
      obj.field = i;
      obj.title = dictionary[table][i].title;
      obj.suppressSizeToFit = false;
      obj.resizable = false;
      obj.headerComponentFramework = 'genericTableHeader';
      const keyType = 'string';
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter
      };
      obj.enableSorting = true;
      obj.cellRenderer = cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      obj.width = 440;
      if (dictionary[table][i].type === 'icon') {
        delete obj.cellRenderer;
        obj.headerComponentParams.enableSorting = false;
        obj.cellRendererFramework = 'iconTableCell';
        obj.cellRendererParams.displayIcon = dictionary[table][i].icon;
        obj.cellRendererParams.alternateIcon =
          dictionary[table][i]?.alternateIcon;
        obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
        obj.cellRendererParams.iconSize = 'small';
        obj.cellRendererParams.iconClickEvent =
          vueRef[dictionary[table][i].iconClickEvent];
        obj.headerComponentParams.clickHeader =
          vueRef[dictionary[table][i].clickHeader];
        obj.headerComponentParams.customStyles = {
          color: '#007cf6',
          display: 'flex',
          width: '100%',
          'justify-content': 'center'
        };
        if (dictionary[table][i]?.toolTipText) {
          obj.headerComponentParams.headerIcon = {
            icon: 'info-circle-fill',
            toolTip: dictionary[table][i]?.toolTipText
          };
        }
        obj.width = 120;
      }
      obj.headerName = dictionary[table][i].title;
      obj.keyOrder = dictionary[table][i].keyOrder;
      dictionary[table][i].pinned && (obj.pinned = dictionary[table][i].pinned);
      // dictionary[table][i].pinned && (delete obj.cellRendererFramework);
      if (dictionary[table][i].toolTipText) {
        obj.headerComponentParams.toolTipText =
          dictionary[table][i].toolTipText;
      }
      obj.headerComponentParams.keyType = keyType;
      if (dictionary[table][i].showOnUi) {
        _columnDefs.push(obj);
      }
      if (dictionary[table][i].downloadable) {
        downloadConfig.push(obj);
      }
    }
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}

export default {
  config: (that) => {
    return {
      primaryKey: 'campaign_id',
      updateKeyMapper: {
        value: 'campaign_id',
        type: 'campaign',
        description: 'campaign_name',
        image: null,
        tagType: 'custom',
        tagCustomType: 'Custom'
      },
      default: {
        alias: {
          tag: 'Campaign list',
          entity: 'Campaign'
        },
        successMessage: {
          create: 'Campaign(s) were added to this Campaign List successfully!',
          edit: 'Updated Campaign List successfully!'
        },
        headerTitle: '',
        noRowsRightTable: 'Add campaigns to this list',
        noRowsLeftTable: 'No campaigns found',
        validations: commons.tagsValidation,
        headerTitlePlaceholder: 'Untitled Campaign List',
        headerDescription: '',
        headerDescriptionPlaceholder: 'Give this Campaign List a description',
        pageTitle:
          'Choose Campaigns that you would like to add to this Campaign List',
        pageDescription:
          'Choose the final list of Campaigns. You may also narrow your search by adding filters or conditions'
      },
      redirectToParentRoute: {
        path: '/tools_and_settings/campaign-lists'
      },
      left: {
        title: 'All Campaigns',
        searchKey: 'search',
        meta: {
          type: 'table',
          key: 'campaign_entities_source_table',
          action: 'fetchEntitiesExecuteApi',
          filters: {
            emit: 'fetchAllEntitiesFiltersTriggered',
            hasSearch: false
          },
          filterState: {
            getter: 'getExecuteApiSelectedFitlers',
            setter: 'fetchEntitiesExecuteApiFilters'
          },
          localFilters: [],
          localStoreageFilterKey: 'campaign-lists-create-custom-entity',
          paginationAction: 'fetchEntitiesExecuteApi'
        },
        footer: {
          show: false
        },
        body: {
          APIConfig: {
            cubeName: 'ams_campaigns_campaign_workbench',
            getLatestAvailableInsteadOfRollup: false,
            timeseriesEnabled: false,
            pvpenabled: false,
            yoyenabled: false,
            measuresList: ['count'],
            groupByDimensionsList: ['campaign_name', 'campaign_id'],
            orderByList: [
              {
                dimension: 'campaign_name',
                direction: 'ASC'
              }
            ],
            limit: 20,
            page: 1
          },
          gridOptions: {
            context: {
              componentParent: that
            },
            domLayout: 'normal'
          },
          columnDefs: getColumnDefinition(that, 'campaign', 'left')
        }
      },
      right: {
        title: 'Campaign List',
        meta: {
          type: 'table',
          key: 'add_keywords_to_tag',
          action: 'fetchAllEntitiesForAtag',
          listen: {}
        },
        footer: {
          show: false
        },
        body: {
          APIConfig: {
            entityType: 'campaign',
            tagType: 'custom',
            customType: 'Custom',
            tagLevel: 'level1',
            tag: ''
          },
          gridOptions: {
            suppressColumnVirtualisation: true,
            context: {
              componentParent: this
            },
            defaultColDef: {
              resizable: false
            },
            domLayout: 'normal'
          },
          columnDefs: getColumnDefinition(that, 'campaign', 'right')
        }
      }
    };
  }
};
