const getPages = (
  [campaignsTooltip, adSkusTooltip, searchTermsTooltip, keywordsTooltip],
  [campaignskeyOrder, adSkuskeyOrder, searchTermskeyOrder, keywordskeyOrder]
) => ({
  samsclub_api_campaigns: {
    keyOrder: campaignskeyOrder,
    table: {
      show: true
    },
    toolTipText: campaignsTooltip,
    chart: {}
  },
  samsclub_api_ad_skus: {
    keyOrder: adSkuskeyOrder,
    table: {
      show: true
    },
    toolTipText: adSkusTooltip,
    chart: {}
  },
  samsclub_api_search_terms: {
    keyOrder: searchTermskeyOrder,
    table: {
      show: true
    },
    toolTipText: searchTermsTooltip,
    chart: {}
  },
  samsclub_api_keywords: {
    keyOrder: keywordskeyOrder,
    table: {
      show: true
    },
    toolTipText: keywordsTooltip,
    chart: {}
  }
});

export const metricsList = {
  attributed_orders_14d: {
    title: 'Orders 14D',
    type: 'NUMBER',
    pages: getPages(
      [
        'Total number of orders generated from the campaign in the selected time period using 14 days attribution method.',
        'Total number of orders generated by the SKU in the selected time period using 14 days attribution method.',
        'Total number of orders generated by the Search term in the selected time period using 14 days attribution method.',
        'Total number of orders generated by the Keyword in the selected time period using 14 days attribution method.'
      ],
      [32, 28, 28, 28]
    )
  },
  attributed_online_sales_14d: {
    title: 'Attributed Online Sales 14D',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    pages: getPages(
      [
        'Total sales revenue based on the attributed online sales revenue based on direct click of the advertised item generated from the campaign in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed online sales revenue based on direct click of the advertised item generated from the SKU in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed online sales revenue based on direct click of the advertised item generated from the search term in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed online sales revenue based on direct click of the advertised item generated from the keyword in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>'
      ],
      [32, 28, 28, 28]
    )
  },
  ntb_orders_14d: {
    title: 'New to Brand Orders 14D',
    type: 'NUMERIC',
    pages: getPages(
      [
        'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.'
      ],
      [36, 32, 32, 32]
    )
  },
  ntb_sales_14d: {
    title: 'New to Brand Sales 14D',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    pages: getPages(
      [
        'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        'New-to-brand sales revenue is the total sales of new-to-brand orders.'
      ],
      [38, 34, 34, 34]
    )
  },
  ntb_units_14d: {
    title: 'New to Brand Units 14D',
    pages: getPages(
      [
        'New-to-brand units is the number of units purchased from new-to-brand orders.',
        'New-to-brand units is the number of units purchased from new-to-brand orders.',
        'New-to-brand units is the number of units purchased from new-to-brand orders.',
        'New-to-brand units is the number of units purchased from new-to-brand orders.'
      ],
      [40, 36, 36, 36]
    ),
    type: 'NUMERIC'
  },
  offline_attributed_sales_14d: {
    title: 'Attributed Offline Sales 14D',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    pages: getPages(
      [
        'Total sales revenue based on the attributed offline sales revenue based on direct click of the advertised item generated from the campaign in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed offline sales revenue based on direct click of the advertised item generated from the SKU in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed offline sales revenue based on direct click of the advertised item generated from the search term in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        'Total sales revenue based on the attributed offline sales revenue based on direct click of the advertised item generated from the keyword in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>'
      ],
      [32, 28, 28, 28]
    )
  },
  percentage_new_to_brand_orders: {
    title: 'Percentage New To Brand Orders',
    pages: getPages(
      [
        'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand'
      ],
      [37, 33, 33, 33]
    ),
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  percentage_new_to_brand_units: {
    title: 'Percentage New To Brand Units',
    pages: getPages(
      [
        'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        'Percentage of units new-to-brand is the percentage of total units which are new-to-brand'
      ],
      [41, 37, 37, 37]
    ),
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  percentage_new_to_brand_sales: {
    title: 'Percentage New To Brand Sales',
    pages: getPages(
      [
        'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.'
      ],
      [39, 35, 35, 35]
    ),
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  conversion_rate_orders_based: {
    title: 'Conversion Rate 14D (Orders Based)',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    pages: getPages(
      [
        'Measure of how many clicks converted into Orders calculated as Orders using 14 day attribution method divided by clicks',
        'Measure of how many clicks converted into Orders calculated as Orders using 14 day attribution method divided by clicks',
        'Measure of how many clicks converted into Orders calculated as Orders using 14 day attribution method divided by clicks',
        'Measure of how many clicks converted into Orders calculated as Orders using 14 day attribution method divided by clicks'
      ],
      [32, 28, 28, 28]
    )
  },
  conversion_rate_ntb_units: {
    title: 'Conversion Rate (NTB Units)',
    pages: getPages(
      [
        'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.'
      ],
      [42, 38, 38, 38]
    ),
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  conversion_rate_ntb_orders: {
    title: 'Conversion Rate (NTB Orders)',
    pages: getPages(
      [
        'Measure of how many clicks converted into orders calculated as orders divided by clicks',
        'Measure of how many clicks converted into orders calculated as orders divided by clicks',
        'Measure of how many clicks converted into orders calculated as orders divided by clicks.',
        'Measure of how many clicks converted into orders calculated as orders divided by clicks.'
      ],
      [35, 31, 31, 31]
    ),
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  percentage_offline_sales: {
    title: 'Percentage Offline Sales',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    pages: getPages(
      [
        'Percentage of sales attributed to offline purchases.',
        'Percentage of sales attributed to offline purchases.',
        'Percentage of sales attributed to offline purchases.',
        'Percentage of sales attributed to offline purchases.'
      ],
      [32, 28, 28, 28]
    )
  },
  percentage_online_sales: {
    title: 'Percentage Online Sales',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    pages: getPages(
      [
        'Percentage of sales attributed to online purchases.',
        'Percentage of sales attributed to online purchases.',
        'Percentage of sales attributed to online purchases.',
        'Percentage of sales attributed to online purchases.'
      ],
      [32, 28, 28, 28]
    )
  },
  total_attributed_direct_click_sales: {
    title: 'Attributed Direct Click Sales 14D',
    pages: {
      samsclub_api_campaigns: {
        keyOrder: 35,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Total sales revenue based on the direct click of the advertised item generated from the campaign in the selected time period using 14 days attribution method'
      },
      samsclub_api_ad_skus: {
        keyOrder: 31,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Total sales revenue based on the direct click of the advertised item generated from the SKU in the selected time period using 14 days attribution method'
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  total_attributed_related_click_sales: {
    title: 'Attributed Related Click Sales 14D',
    pages: {
      samsclub_api_campaigns: {
        keyOrder: 38,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Total sales revenue based on the direct click of the same brand and category of the advertised item generated from the campaign in the selected time period using 14 days attribution method.<(e.g., Clicked on a shampoo ad and later purchased a conditioner from the same brand)>'
      },
      samsclub_api_ad_skus: {
        keyOrder: 34,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Total sales revenue based on the direct click of the same brand and category of the advertised item generated from the SKU in the selected time period using 14 days attribution method.<(e.g., Clicked on a shampoo ad and later purchased a conditioner from the same brand)>'
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  total_attributed_brand_click_sales: {
    title: 'Attributed Brand Click Sales 14D',
    pages: {
      samsclub_api_campaigns: {
        keyOrder: 41,
        table: {
          show: true
        },
        toolTipText:
          'Total sales revenue based on the direct click of the same brand as the advertised item of the advertised item generated from the campaign in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        chart: {}
      },
      samsclub_api_ad_skus: {
        keyOrder: 37,
        table: {
          show: true
        },
        toolTipText:
          'Total sales revenue based on the direct click of the same brand as the advertised item of the advertised item generated from the search term in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  }
};

export default function addAdditionalMetrics() {
  return {
    ...metricsList,
    attributed_brand_click_sales: {
      title: 'Attributed Brand Click Sales 14D',
      pages: {
        samsclub_api_keywords: {
          keyOrder: 35,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total sales revenue based on the direct click of the same brand as the advertised item of the advertised item generated from the keyword in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>'
        },
        samsclub_api_search_terms: {
          keyOrder: 30,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total sales revenue based on the direct click of the same brand as the advertised item of the advertised item generated from the search term in the selected time period using 14 days attribution method.<e.g., Clicked on a shampoo ad and later purchased a t-shirt from the same brand)>'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    attributed_direct_click_sales: {
      title: 'Attributed Direct Click Sales 14D',
      pages: {
        samsclub_api_keywords: {
          keyOrder: 33,
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue based on the direct click of the advertised item generated from the keyword in the selected time period using 14 days attribution method',
          chart: {}
        },
        samsclub_api_search_terms: {
          keyOrder: 28,
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue based on the direct click of the advertised item generated from the search term in the selected time period using 14 days attribution method',
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    attributed_related_click_sales: {
      title: 'Attributed Related Click Sales 14D',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        samsclub_api_keywords: {
          keyOrder: 34,
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue based on the direct click of the same brand and category of the advertised item generated from the keyword in the selected time period using 14 days attribution method.<(e.g., Clicked on a shampoo ad and later purchased a conditioner from the same brand)>',
          chart: {}
        },
        samsclub_api_search_terms: {
          keyOrder: 29,
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue based on the direct click of the same brand and category of the advertised item generated from the search term in the selected time period using 14 days attribution method.<(e.g., Clicked on a shampoo ad and later purchased a conditioner from the same brand)>',
          chart: {}
        }
      }
    }
  };
}
