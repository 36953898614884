import HomeV2 from '@/components/homeV2.vue';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import Market_Insight from '@/components/pages/insights/amazon/share-of-voice/page.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import CONSTANTS from '@/utils/constants';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import { getCampaignManagementChildrenRoutes } from '@/router/krogerpromoteiq';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import {
  campaignOptimizerChildrenRoutes,
  homeRoute
} from '@/router/commonRoutePaths';
import { filters } from '@ciq-dev/ciq-ui';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const krogerRoutes = {
  path: '/us/kroger/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect() {
        const marketInsightsConfig = filters.config_check(
          `feature.insights.kroger_super_sov_v2`
        );
        if (marketInsightsConfig.enable) {
          return 'digital_shelf/market_insights';
        }
        return 'advertising/campaign_management';
      }
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'digital_shelf',
      name: 'digital_shelf',
      redirect() {
        return 'digital_shelf/market_insights';
      },
      component: DigitalShelfLanding,
      children: [
        {
          title: 'Market Insights',
          path: 'market_insights/:id?',
          name: 'market_insights',
          page: 'insights',
          config: 'sov_v2',
          productRoles: ['MarketingIQ'],
          component: Market_Insight,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.REM
          }
        },
        {
          path: '',
          redirect: () => {
            return 'market_insights';
          }
        }
      ]
    },
    {
      path: 'advertising',
      name: 'advertising',
      redirect() {
        return 'advertising/campaign_management';
      },
      component: AdvertisingLanding,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes.map((route) => ({
            ...route,
            meta: {
              productLine: CONSTANTS.PRODUCT_LINE.RMM,
              appendEsDataSetName: true
            }
          }))
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutes('kroger')
        },
        {
          path: '',
          redirect: () => {
            return 'campaign_management';
          }
        }
      ]
    },
    ...commonInternalRoutesArray
  ]
};

export { krogerRoutes };
