import Vue from 'vue';
import HttpService from '@/utils/services/http-service';

const state = {
  ldapUsers: { rows: [] },
  sfUsers: { rows: [] },
  attachedFiles: [],
  mailStatus: {},
  insertStatus: {},
  mailHistory: {},
  isEmailFormOpen: false
};

const getters = {
  sfUsers: (state) => {
    return state.sfUsers;
  },
  ldapUsers: (state) => {
    return state.ldapUsers;
  },
  attachedFiles: (state) => {
    return state.attachedFiles;
  },
  mailStatus: (state) => {
    return state.mailStatus;
  },
  selectedReceipients: (state) => {
    return state.selectedReceipients;
  },
  insertStatus: (state) => {
    return state.insertStatus;
  },
  mailHistory: (state) => {
    return state.mailHistory;
  },
  isEmailFormOpen: (state) => {
    return state.isEmailFormOpen;
  }
};

const mutations = {
  INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_SUCCESS: (state, data) => {
    Vue.set(state.ldapUsers, 'rows', data);
    Vue.set(state.ldapUsers, 'load', false);
    Vue.set(state.ldapUsers, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_RESET: (state) => {
    Vue.set(state.ldapUsers, 'rows', []);
    Vue.set(state.ldapUsers, 'load', true);
    Vue.set(state.ldapUsers, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_ERROR: (state) => {
    Vue.set(state.ldapUsers, 'rows', []);
    Vue.set(state.ldapUsers, 'load', false);
    Vue.set(state.ldapUsers, 'error', true);
  },
  INTERNAL_TOOLS_VALIDATE_GET_SF_USERS_SUCCESS: (state, data) => {
    Vue.set(state.sfUsers, 'rows', data);
    Vue.set(state.sfUsers, 'load', false);
    Vue.set(state.sfUsers, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_SF_USERS_RESET: (state) => {
    Vue.set(state.sfUsers, 'rows', []);
    Vue.set(state.sfUsers, 'load', true);
    Vue.set(state.sfUsers, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_SF_USERS_ERROR: (state) => {
    Vue.set(state.sfUsers, 'rows', []);
    Vue.set(state.sfUsers, 'load', false);
    Vue.set(state.sfUsers, 'error', true);
  },
  INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_RESET: (state, data) => {
    Vue.set(state.insertStatus, 'load', true);
    Vue.set(state.insertStatus, 'error', false);
    Vue.set(state.insertStatus, 'message', 'Initiated');
  },
  INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_SUCCESS: (state, data) => {
    Vue.set(state.sfUsers, 'rows', [...state.sfUsers.rows, data]);
    Vue.set(state.insertStatus, 'load', false);
    Vue.set(state.insertStatus, 'error', false);
    Vue.set(state.insertStatus, 'message', 'Success');
  },
  INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_ERROR: (state) => {
    Vue.set(state.insertStatus, 'load', false);
    Vue.set(state.insertStatus, 'error', true);
    Vue.set(state.insertStatus, 'message', 'Failure');
  },
  INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_SET_TO_LOAD: (state, data) => {
    const index = state.sfUsers.rows.findIndex((item) => item.id === data.id);
    Vue.set(state.sfUsers.rows, index, {
      ...state.sfUsers.rows[index],
      loading: true
    });
  },
  INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_SUCCESS: (state, data) => {
    Vue.set(
      state.sfUsers,
      'rows',
      state.sfUsers.rows.filter((item) => item.id !== data.id)
    );
  },
  INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_ERROR: (state, data) => {
    const index = state.sfUsers.rows.findIndex((item) => item.id === data.id);
    Vue.set(state.sfUsers.rows, index, {
      ...state.sfUsers.rows[index],
      loading: false
    });
  },
  INTERNAL_TOOLS_VALIDATE_REMOVE_FILE: (state, data) => {
    Vue.set(
      state,
      'attachedFiles',
      state.attachedFiles.filter((item) => item.filename !== data)
    );
  },
  INTERNAL_TOOL_VALIDATE_EXCEL_SUCCESS: (state, data) => {
    const { filename, response } = data;
    const index = state.attachedFiles.findIndex(
      (item) => item.filename === filename
    );
    Vue.set(state.attachedFiles, index, {
      ...state.attachedFiles[index],
      message: response[filename].workbookErrors.message,
      filepath: response[filename].filepath,
      fileUploadStatus: 'complete',
      success: response[filename].workbookErrors.success,
      error: true,
      noData: false,
      isUploading: false
    });
  },
  INTERNAL_TOOL_VALIDATE_EXCEL_ATTACH: (state, data) => {
    if (
      state.attachedFiles.findIndex((item) => item.filename === data) === -1
    ) {
      const index = state.attachedFiles.length;
      Vue.set(state.attachedFiles, index, {
        filename: data,
        message: 'Uploading File',
        filepath: null,
        fileUploadStatus: 'started',
        success: false,
        error: false,
        noData: false,
        isUploading: true
      });
    }
  },
  INTERNAL_TOOL_VALIDATE_EXCEL_RESET: (state, data) => {
    Vue.set(state, 'attachedFiles', []);
  },
  INTERNAL_TOOL_VALIDATE_EXCEL_ERROR: (state, data) => {
    const { response, filename } = data;
    const index = state.attachedFiles.findIndex(
      (item) => item.filename === filename
    );
    Vue.set(state.attachedFiles, index, {
      ...state.attachedFiles[index],
      message:
        (response &&
          response[filename] &&
          response[filename].workbookErrors &&
          response[filename].workbookErrors.message) ||
        'Unexpected Error Occured',
      filepath: response && response[filename] && response[filename].filepath,
      fileUploadStatus: 'error',
      success: false,
      error: true,
      noData: false,
      isUploading: false
    });
  },
  INTERNAL_TOOLS_VALIDATE_SEND_MAIL_SUCCESS: (state, data) => {
    Vue.set(state.mailStatus, 'load', false);
    Vue.set(state.mailStatus, 'error', false);
    Vue.set(state.mailHistory, 'rows', [data, ...state.mailHistory.rows]);
  },
  INTERNAL_TOOLS_VALIDATE_SEND_MAIL_RESET: (state) => {
    Vue.set(state.mailStatus, 'load', true);
    Vue.set(state.mailStatus, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_SEND_MAIL_ERROR: (state) => {
    Vue.set(state.mailStatus, 'load', false);
    Vue.set(state.mailStatus, 'error', true);
  },
  INTERNAL_TOOL_RECEIPIENT_ACTIVE_TOGGLE: (state, data) => {
    const { receipient, type } = data;
    const index = state[type].rows.findIndex(
      (item) => item.mail === receipient
    );
    const isActive = !state[type].rows[index].isActive;
    Vue.set(state[type].rows, index, { ...state[type].rows[index], isActive });
    Vue.set(state, 'isEmailFormOpen', true);
  },
  INTERNAL_TOOL_RESET_MAIL: (state, data) => {
    Vue.set(
      state.sfUsers,
      'rows',
      state.sfUsers.rows.map((item) => ({ ...item, isActive: false }))
    );
    Vue.set(
      state.ldapUsers,
      'rows',
      state.ldapUsers.rows.map((item) => ({ ...item, isActive: false }))
    );
    Vue.set(state, 'attachedFiles', []);
  },
  INTERNAL_TOOL_REMOVE_ACTIVE_USER: (state, mail) => {
    const indexLdapUsers = state.ldapUsers.rows.findIndex(
      (item) => item.mail === mail
    );
    const indexSfUsers = state.sfUsers.rows.findIndex(
      (item) => item.mail === mail
    );
    if (indexLdapUsers >= 0) {
      Vue.set(state.ldapUsers.rows, indexLdapUsers, {
        ...state.ldapUsers.rows[indexLdapUsers],
        isActive: false
      });
    }
    if (indexSfUsers >= 0) {
      Vue.set(state.sfUsers.rows, indexSfUsers, {
        ...state.sfUsers.rows[indexSfUsers],
        isActive: false
      });
    }
  },
  INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_SUCCESS: (state, data) => {
    const payload = data.map((item) => {
      return {
        ...item,
        to: JSON.parse(item.to),
        filepaths: item.filepaths && JSON.parse(item.filepaths)
      };
    });
    Vue.set(state.mailHistory, 'rows', payload);
    Vue.set(state.mailHistory, 'load', false);
    Vue.set(state.mailHistory, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_RESET: (state) => {
    Vue.set(state.mailHistory, 'rows', []);
    Vue.set(state.mailHistory, 'load', true);
    Vue.set(state.mailHistory, 'error', false);
  },
  INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_ERROR: (state) => {
    Vue.set(state.mailHistory, 'rows', []);
    Vue.set(state.mailHistory, 'load', false);
    Vue.set(state.mailHistory, 'error', true);
  },
  INTERNAL_TOOLS_VALIDATE_EMAIL_FORM_SHOW: (state) => {
    Vue.set(state, 'isEmailFormOpen', true);
  },
  INTERNAL_TOOLS_VALIDATE_EMAIL_FORM_HIDE: (state) => {
    Vue.set(state, 'isEmailFormOpen', false);
  }
};

const actions = {
  hideEmailForm: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_EMAIL_FORM_HIDE');
  },
  showEmailForm: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_EMAIL_FORM_SHOW');
  },
  fetchMailHistory: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_RESET');
    try {
      const response = await HttpService.post(
        'CIQ_INTERNAL_TOOL_EXCEL_MAIL_HISTORY_GET',
        {}
      );
      context.commit(
        'INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_SUCCESS',
        response.data
      );
    } catch (err) {
      console.log(err);
      context.commit('INTERNAL_TOOLS_VALIDATE_GET_MAIL_HISTORY_ERROR', err);
    }
  },
  resetMail: (context, data) => {
    context.commit('INTERNAL_TOOL_RESET_MAIL');
    context.commit('INTERNAL_TOOLS_VALIDATE_EMAIL_FORM_HIDE');
  },
  removeActiveUser: (context, data) => {
    const { mail } = data;
    context.commit('INTERNAL_TOOL_REMOVE_ACTIVE_USER', mail);
  },
  handleReceipientsToggle: (context, data) => {
    context.commit('INTERNAL_TOOL_RECEIPIENT_ACTIVE_TOGGLE', data);
  },
  resetMailStatus: (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_SEND_MAIL_RESET');
  },
  sendMailInternalTool: async (context, data) => {
    return new Promise(async (resolve, reject) => {
      context.commit('INTERNAL_TOOLS_VALIDATE_SEND_MAIL_RESET');
      try {
        const response = await HttpService.post(
          'CIQ_INTERNAL_TOOL_SUBMIT_MAIL',
          data
        );
        const payload = { ...data, ...response.data };
        context.commit('INTERNAL_TOOLS_VALIDATE_SEND_MAIL_SUCCESS', payload);
        resolve();
      } catch (err) {
        context.commit('INTERNAL_TOOLS_VALIDATE_SEND_MAIL_ERROR', err);
        reject(err);
      }
    });
  },
  attachFile: async (context, data) => {
    const { formData, filename } = data;
    context.commit('INTERNAL_TOOL_VALIDATE_EXCEL_ATTACH', filename);
    try {
      const response = await HttpService.post(
        'CIQ_INTERNAL_TOOL_EXCEL_VALIDATION',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      if (
        response.data[filename] &&
        response.data[filename].workbookErrors &&
        response.data[filename].workbookErrors.success
      ) {
        context.commit('INTERNAL_TOOL_VALIDATE_EXCEL_SUCCESS', {
          filename,
          response: response.data
        });
      } else {
        context.commit('INTERNAL_TOOL_VALIDATE_EXCEL_ERROR', {
          filename,
          response: response.data
        });
      }
    } catch (err) {
      context.commit('INTERNAL_TOOL_VALIDATE_EXCEL_ERROR', { filename, err });
    }
  },
  downloadFile: async (context, filepath) => {
    HttpService.post(
      'CIQ_INTERNAL_TOOL_EXCEL_DOWNLOAD',
      { filepath },
      { responseType: 'blob' }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const disposition =
          response.headers && response.headers['content-disposition'];
        let fileName = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        } else {
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  removeFile: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_REMOVE_FILE', data);
  },
  fetchLdapUsers: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_RESET');
    try {
      // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
      const response = await HttpService.get('USERS', {});
      context.commit(
        'INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_SUCCESS',
        response.data
      );
    } catch (err) {
      context.commit('INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_ERROR', err);
    }
  },
  fetchSfUsers: async (context, data) => {
    context.commit('INTERNAL_TOOLS_VALIDATE_GET_LDAP_USERS_RESET');
    try {
      // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
      const response = await HttpService.post(
        'CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_GET',
        {}
      );
      context.commit(
        'INTERNAL_TOOLS_VALIDATE_GET_SF_USERS_SUCCESS',
        response.data
      );
    } catch (err) {
      context.commit('INTERNAL_TOOLS_VALIDATE_GET_SF_USERS_ERROR', err);
    }
  },
  insertUserIntoSf: async (context, data) => {
    return new Promise(async (resolve, reject) => {
      const { name, mail } = data;
      context.commit('INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_RESET');
      try {
        // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
        const response = await HttpService.post(
          'CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_INSERT',
          data
        );
        const payload = { name, mail, id: response.data.id };
        context.commit(
          'INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_SUCCESS',
          payload
        );
        resolve({});
      } catch (err) {
        context.commit('INTERNAL_TOOLS_VALIDATE_INSERT_SF_USER_ERROR', err);
        reject(err);
      }
    });
  },
  deleteUserSf: (context, data) => {
    return new Promise(async (resolve, reject) => {
      context.commit(
        'INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_SET_TO_LOAD',
        data
      );
      try {
        // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
        const response = await HttpService.post(
          'CIQ_INTERNAL_TOOL_EXCEL_SF_USERS_DELETE',
          data
        );
        context.commit(
          'INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_SUCCESS',
          response.data
        );
        resolve();
      } catch (err) {
        context.commit('INTERNAL_TOOLS_VALIDATE_DELETE_SF_USER_ERROR', {
          err,
          id: data.id
        });
        reject();
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
