import { BaseConfig } from '@/pages/dashboard_service_campaign_management/types/index';
import { UtilServiceForConfig } from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';
// @ts-ignore
import { dataDiscrepancyVHtmlText } from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils.js';
const chartingWb = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  // @ts-ignore
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  // @ts-ignore
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  // @ts-ignore
  import('@/components/widgets/collaboration/collaboration.vue');
const iconHeader = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const failedCampaignsBanner = () =>
  // @ts-ignore
  import('@/pages/entity_details/common_components/banner.vue');

const PRIMARY_KEY = 'region_id';

export function regionsConfig(retailer: string): any {
  const listApiPageName = `${retailer}_cm_regions`;
  const chartWidgetName = `${retailer}_cm_regions_charting_wb`;
  const tableWidgetName = `${retailer}_cm_regions_table`;
  const localStorageFilterKey = `${retailer}-regions-filter`;
  const localStorageFilterOrderKey = `${retailer}-regions-filter-order`;
  const tabName = 'Regions';

  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  const configToReturn: BaseConfig = {
    PRIMARY_KEY,
    entity: 'campaigns',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: {
      ...utilServiceForConfig.getFilterSettings({
        backendCube: 'promoteiq_regions_workbench',
        searchDimension: 'search',
        searchInputPlaceHolder: 'Search for a Region',
        bulkSearchStringConstants: {
          dimensionNameMapping: 'region_name',
          findEntityInputPlaceholder: 'Find a region',
          subTitle: 'regions'
        },
        enableTaxonomyFilters: true
      }),
      extraCalendarInfoVHtmlText: dataDiscrepancyVHtmlText
    },
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      failedCampaignsBanner: failedCampaignsBanner
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),
    headerOptions: function (context: any) {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: {
          props: {
            ...utilServiceForConfig.getTableWidgetProps(context),
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    utilServiceForConfig.getPlotRowsActionObject()
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections: any) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
                context
              )
            ],
            enableManageColumns: !context.rowSelections.length
          }
        }
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('region'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail(),
    componentBelowFilterVHtmlText:
      '<div class="u-font-size-5">Performance metrics are available for only those regions which are explicitly added to the campaigns.</div>',
    componentBelowFilter: function (dashboardWrapperVueRef: any) {
      return {
        show: dashboardWrapperVueRef.showComponentBelowFilter,
        ui_component: 'failedCampaignsBanner',
        props: {
          showCta: false,
          showCloseIcon: true,
          closeIconCustomClass: 'u-margin-left-auto',
          iconCustomClass: 'u-color-orange-base',
          vHtmlText: this.componentBelowFilterVHtmlText
        },
        events: {
          closeIconClicked: function () {
            dashboardWrapperVueRef.showComponentBelowFilter = false;
          }
        },
        customClass: 'background-yellow-warning'
      };
    },
    async onCreated(dashboardWrapperVueRef: any) {
      dashboardWrapperVueRef.showComponentBelowFilter = true;
    }
  };
  return configToReturn;
}
