<template>
  <div
    v-tippy
    :title="naTooltipComp"
    class="u-display-flex u-width-100 data-cell"
    :class="[
      { 'u-flex-justify-content-flex-end': isRightAligned(type) },
      containerClasses
    ]"
  >
    <div
      v-if="toExpand"
      v-tippy="tippy"
      data-cy="campaignNameValue"
      class="cell--content u-white-space-normal u-color-grey-base u-flex-shrink-past-min-size u-overflow-hidden diff-content"
      :title="displayValue"
      v-html="displayValue"
    >
      >
      {{ displayValue }}
    </div>
    <span
      v-else
      v-tippy
      :title="naTooltipComp"
      class="cell--content u-white-space-normal u-color-grey-base u-overflow-hidden u-cursor-text"
      data-cy="keyValue"
    >
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import {
  formatter,
  isRightAligned,
  isTypeNumeric
} from '@/utils/helpers/formatter.js';
import helpers from '@/utils/helpers/index.js';

export default {
  name: 'DataCell',
  props: {
    containerClasses: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    type: {
      type: String,
      default: 'string'
    },
    unit: {
      type: String,
      default: null
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'top-start',
          delay: [500, 0],
          animation: 'scale',
          interactive: true,
          maxWidth: '200px',
          theme: 'click-to-expand',
          duration: [150, 0]
        };
      }
    },
    params: {
      type: Object,
      default: null
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isRightAligned: isRightAligned,
      toExpand: false
    };
  },
  computed: {
    naTooltipComp() {
      return this.naTooltip;
    },
    displayValue() {
      let value = this.value;
      if (!helpers.isNumeric(this.value) && isTypeNumeric(this.type)) {
        value = null;
      }
      return formatter(
        value,
        this.unit?.trim().length ? this.unit : this.type,
        this.naText
      );
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.params) {
        this.toExpand = helpers.expandOnHover(this.params, this.$el);
      }
    });
  }
};
</script>

<style lang="css" scoped>
@supports not (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content,
  .u-clamp-2 .cell--content,
  .u-clamp-3 .cell--content,
  .u-clamp-4 .cell--content,
  .u-clamp-5 .cell--content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
}
@supports (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-2 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-3 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-4 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-5 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  ins {
    text-decoration: none;
    color: #3ea95e;
  }
  del {
    text-decoration: line-through;
    color: #d7263d;
    padding-right: 4px;
  }
}
</style>
