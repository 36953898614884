<template>
  <card-widget
    class="u-flex-1 u-spacing-mb-m u-bg-color-grey-white"
    :config="config.widgets['widget3']"
    :data="actionsStatus"
  >
    <div slot="body">
      <div
        v-if="!isBudgetPlanArchived"
        class="u-width-100 u-display-flex u-spacing-ph-l u-flex-direction-row u-spacing-pb-xl u-spacing-pt-s"
      >
        <div
          v-for="(item, i) in actions"
          :key="i"
          class="u-width-50 innerAction u-spacing-p-l u-cursor-pointer"
          :class="{ 'u-spacing-mr-l': i === 0 }"
          @click="openBudgetPlanActions(item.routeName)"
        >
          <div
            class="u-display-flex u-flex-direction-column u-text-align-center"
          >
            <div class="u-font-size-3 u-font-weight-600 u-color-grey-base">
              {{ item.title }}
            </div>
            <div class="u-font-size-1 u-font-weight-600 u-spacing-pt-m">
              <div class="u-display-flex u-flex-justify-content-center">
                <div
                  class="u-flex-direction-column u-display-flex u-flex-align-items-center u-flex-justify-content-center"
                >
                  <div class="u-color-blue-base u-cursor-pointer">
                    {{ item.tag1 }}
                  </div>
                  <div
                    class="u-color-grey-x-light u-spacing-pt-s u-font-size-7"
                  >
                    Pending actions
                  </div>
                </div>
                <div
                  class="u-spacing-mh-m"
                  style="width: 1px; height: 40px; background-color: #e9eaeb"
                />
                <div
                  class="u-flex-direction-column u-display-flex u-flex-justify-content-center"
                >
                  <div class="u-color-grey-lighter">
                    {{ item.tag2 }}
                  </div>
                  <div
                    class="u-color-grey-x-light u-spacing-pt-s u-font-size-7"
                  >
                    Total actions
                  </div>
                </div>
              </div>
            </div>
            <div
              class="u-font-size-7 u-spacing-pt-m u-color-blue-base u-font-weight-600"
            >
              <span>{{ item.approveText }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="u-display-flex u-spacing-pb-xl u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"
      >
        <!-- <rb-icon class="rb-icon--x-large u-color-grey-xx-light" :icon="'calendar'"></rb-icon> -->
        <img
          class="u-max-width-240px"
          src="/images/no_budget.png"
          alt="no budget image"
        />
        <div class="u-spacing-pv-l u-font-weight-600 u-font-size-3">
          Looks like you haven’t created your Budget Plan for the Current Year!
        </div>
        <rb-button
          type="filled"
          :click-fn="() => $emit('createBudgetPlan')"
          text="Create a Budget Plan"
        />
      </div>
    </div>
  </card-widget>
</template>

<script>
import cardWidget from '@/components/widgets/cardWidget';
import widgetsConfig from '@/pages/budget-planner-v2/dashboard/widget.js';
import widgetsUtil from '@/components/widgetMixin';
export default {
  components: {
    cardWidget
  },
  mixins: [widgetsUtil],
  props: {
    isBudgetPlanArchived: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    widgetsConfig: {},
    config: {},
    actions: [
      {
        title: 'Adjust Campaign Budgets',
        tag1: 0,
        tag2: 0,
        routeName: 'budget-plan-campaign-budget-v2',
        approveText: 'Approve now',
        actionText: 'View action log',
        cubeName: 'ams_manual_actions_campaign_budget_change'
      },
      {
        title: 'Adjust Keywords Bids',
        tag1: 0,
        tag2: 0,
        routeName: 'budget-plan-keyword-bid-v2',
        approveText: 'Approve now',
        actionText: 'View action log',
        cubeName: 'ams_manual_actions_keywords_bid_change'
      }
    ]
  }),
  computed: {
    actionsStatus() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBPDashboardActionStatus'
      ];
    }
  },
  watch: {
    actionsStatus: {
      handler: function (newVal) {
        this.actions.forEach((item) => {
          let sum = 0;
          if (newVal[item.cubeName]) {
            for (const obj of newVal[item.cubeName]) {
              if (obj.status === 'SUCCEEDED' || obj.status === 'RECEIVED') {
                sum += obj.count_details;
              }
              item.tag2 = obj.daily_count;
            }
            item.tag1 = item.tag2 - sum;
          }
        });
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    // widget config
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
  },
  methods: {
    openBudgetPlanActions(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style lang="css" scoped>
.innerAction {
  border-radius: 4px;
  border: solid 1px #e9eaeb;
}
.innerAction:hover,
.innerAction:focus {
  border: 1px solid palette(blue);
}
</style>
