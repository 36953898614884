<template>
  <div>
    <div class="propTable u-display-flex u-spacing-mt-s u-flex-wrap-yes">
      <div
        v-for="(val, key) in displayData"
        :key="key"
        class="row u-width-50 u-flex-grow-1 u-spacing-pv-s"
      >
        <div class="key u-spacing-mb-s">
          {{ key }}
        </div>
        <div
          v-for="(kVal, kKey) in val"
          :key="kKey"
          class="desc u-spacing-mb-xs u-spacing-pr-s"
        >
          <b>{{ kKey }}:</b> {{ kVal }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css">
.propTable .key {
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}

.propTable .desc {
  font-size: 1.2rem;
}
</style>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'detailsDisplay',
  props: {
    displayData: {
      type: Object,
      default: 'is-bottom-left'
    }
  },
  data() {
    return {
      selectedItems: []
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>
