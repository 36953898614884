var enabledActions = {
  'Keyword harvesting from auto campaign': [
    {
      action: 'addKeywordToCampaigns'
    },
    {
      action: 'addKeyword'
    },
    {
      action: 'addKeywordBulk'
    }
  ],
  'Important keywords in each category': [
    {
      action: 'addKeywordToCampaigns'
    },
    {
      action: 'addKeywordBulk'
    }
  ],
  'Monetize high performing keywords': [
    {
      action: 'keywordBidChange'
    },
    {
      action: 'addKeyword'
    },
    {
      action: 'addKeywordBulk'
    }
  ],
  'Keywords with low organic share': [
    {
      action: 'keywordBidChange'
    }
  ],
  'Keywords with high acos and minimum clicks': [
    {
      action: 'keywordBidChange'
    }
  ],
  'Keywords with low acos and high impressions': [
    {
      action: 'keywordBidChange'
    }
  ],
  'Search terms with less conversion rate': [
    {
      action: 'addNegativeKeyword'
    }
  ],
  'Campaigns out of budget': [
    {
      action: 'campaignBudgetChange'
    }
  ],
  'Keywords with high impressions and week over week increase': [
    {
      action: 'keywordBidChange'
    }
  ],
  'Search term isolation': [
    {
      action: 'addKeywordBulk'
    }
  ]
};

var allActions = {
  keywordBidChange: {
    default: true,
    approve: true,
    edit: true,
    template: 'action-workspace',
    endpoint: 'SUBMIT_ACTIONS',
    edit_title: 'Edit keyword bid',
    max_bid: 1000,
    min_bid: 0.02,
    new_match_type: {
      show: true,
      editable: false,
      single: true
    },
    new_bid: {
      show: true,
      editable: true,
      single: true
    },
    campaign_name: {
      show: true,
      editable: false,
      single: true
    }
  },
  addKeywordBulk: {
    default: true,
    approve: true,
    edit: true,
    template: 'action-workspace',
    endpoint: 'SUBMIT_ACTIONS',
    edit_title: 'Edit keyword bid',
    max_bid: 1000,
    min_bid: 0.02,
    new_match_type: {
      show: true,
      editable: true,
      single: true
    },
    new_bid: {
      show: true,
      editable: true,
      single: true
    },
    campaign_name: {
      show: true,
      editable: true,
      single: true
    }
  },
  campaignBudgetChange: {
    default: true,
    approve: true,
    edit: true,
    template: 'action-workspace',
    endpoint: 'SUBMIT_ACTIONS',
    edit_title: 'Edit campaign budget',
    max_bid: 5000,
    min_bid: 0.02,
    campaign_name: {
      show: false,
      editable: false,
      single: true
    },
    new_daily_budget: {
      show: true,
      editable: true,
      single: true
    }
  },
  addKeywordToCampaigns: {
    default: true,
    approve: false,
    edit: true,
    template: 'action-workspace',
    endpoint: 'SUBMIT_ACTIONS',
    edit_title: 'Add keywords to campaigns',
    max_bid: 1000,
    min_bid: 0.02,
    new_keyword_text: {
      show: true,
      editable: false,
      single: true
    },
    new_match_type: {
      show: true,
      editable: true,
      single: false
    },
    new_bid: {
      show: true,
      editable: true,
      single: true
    },
    campaign_name: {
      show: true,
      editable: true,
      single: false
    }
  },
  addNegativeKeyword: {
    default: true,
    approve: true,
    edit: false
  }
};

export default {
  allActions: allActions,
  enabledActions: enabledActions
};
