<template>
  <div
    class="u-flex-1 u-display-flex u-flex-align-items-center u-width-100 u-flex-justify-content-center u-position-fixed u-flex-direction-column widget--full-screen"
  >
    <div class="u-font-size-3">
      <div class="u-spacing-mb-l">
        <img
          src="/images/campaign_creation_successful.png"
          class="successful-image"
        />
      </div>

      <div class="u-display-flex u-flex-align-items-center">
        <div class="u-spacing-pr-s">
          <rb-icon
            :class="'u-color-green-base'"
            :icon="'check-fill-circle'"
          />
        </div>
        <div>
          <span
            data-cy="launchedSuccessfully"
            class="u-font-weight-600"
            >{{ `"${allStepsData.campaignName}"` }}</span
          >
          {{ successfulMessage }}
        </div>
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-l">
      <div class="u-spacing-pr-m">
        <rb-button
          data-cy="createAnotherCampaign"
          text="Create another Campaign"
          type="hollow"
          size="l"
          :click-fn="createCampaign"
        />
      </div>
      <rb-button
        data-cy="viewInCampaignManagement"
        text="View in Campaign Management"
        type="filled"
        size="l"
        :click-fn="routeToCampaigns"
      />
    </div>
  </div>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';

export default {
  data() {
    return {
      pageData: null,
      campaignType: 'sponsoredBrands'
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters?.[this.pageData?.getter] || {};
    },
    successfulMessage() {
      if (this.campaignType === 'sponsoredBrands') {
        return 'is sent for review Successfully!';
      } else return 'is launched Successfully!';
    }
  },
  created() {
    this.pageData =
      this.retailer === 'samsclub_api'
        ? config?.[this.retailer]['sponsoredProductsManual']
        : config?.[this.retailer]['sponsoredBrands'];
    this.campaignType = this.allStepsData?.campaignType || this.campaignType;
  },
  methods: {
    createCampaign() {
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: 'create_campaign'
      });
    },
    routeToCampaigns() {
      this.$store.dispatch(this.pageData.action, {});
      // This will take the user back to campaign management page
      this.$router.push({
        name: 'Campaigns'
      });
    }
  }
};
</script>

<style lang="css" scoped>
.successful-image {
  display: block;
  max-height: 320px;
  margin: auto;
}
</style>
