// dictionary for Amazon DSP actions panel
import {
  addAll,
  addEntity
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/bulkCustomBid.js';

const dictionary = {
  line_items_add_inventory: {
    left: {
      AMAZON_EXCLUSIVE: {
        dsp_inventory_targeting_supply_source_name: {
          title: 'Inventory Sources',
          toolTipText: 'Inventory Sources',
          type: 'String',
          keyOrder: 1,
          showOnUi: true,
          downloadable: true
        },
        select_all: {
          title: 'Select All',
          alternateIconClass: 'u-color-green-base',
          type: 'icon',
          icon: 'plus',
          alternateIcon: 'check-fill-circle',
          iconClickEvent: 'addEntity',
          clickHeader: 'addAll',
          keyOrder: 2,
          showOnRightPanel: true,
          showOnUi: true,
          downloadable: true
        }
      },
      OPEN_EXCHANGE: {
        dsp_inventory_targeting_supply_source_name: {
          title: 'Third Party Exchanges',
          toolTipText: 'Third Party Exchanges',
          type: 'String',
          keyOrder: 1,
          showOnUi: true,
          downloadable: true
        },
        dsp_inventory_targeting_line_item_type: {
          title: 'Line Item Type',
          toolTipText: 'Line Item Type',
          type: 'String',
          keyOrder: 2,
          showOnUi: true,
          downloadable: true
        },
        select_all: {
          title: 'Select All',
          alternateIconClass: 'u-color-green-base',
          type: 'icon',
          icon: 'plus',
          alternateIcon: 'check-fill-circle',
          iconClickEvent: 'addEntity',
          clickHeader: 'addAll',
          keyOrder: 3,
          showOnRightPanel: true,
          showOnUi: true,
          downloadable: true
        }
      }
    },
    right: {
      dsp_inventory_targeting_supply_source_name: {
        title: 'Inventory Sources',
        toolTipText: 'Inventory Sources',
        type: 'append',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true,
        append: 'dsp_inventory_targeting_supply_source_type'
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  line_items_remove_inventory: {
    left: {
      dsp_inventory_targeting_supply_source_name: {
        title: 'Inventory Sources',
        toolTipText: 'Inventory Sources',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      select_all: {
        title: 'Select All',
        alternateIconClass: 'u-color-green-base',
        type: 'icon',
        icon: 'plus',
        alternateIcon: 'check-fill-circle',
        iconClickEvent: 'addEntity',
        clickHeader: 'addAll',
        keyOrder: 2,
        showOnRightPanel: true,
        showOnUi: true,
        downloadable: true
      }
    },
    right: {
      dsp_inventory_targeting_supply_source_name: {
        title: 'Inventory Sources',
        toolTipText: 'Inventory Sources',
        type: 'append',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true,
        append: 'dsp_inventory_targeting_supply_source_type'
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  line_items_remove_supply_source: {
    left: {
      dsp_outlier_supply_source_to_remove_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_outlier_supply_source_to_remove_ss_name: {
        title: 'Supply Sources',
        toolTipText: 'Supply Sources',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      select_all: {
        title: 'Select All',
        alternateIconClass: 'u-color-green-base',
        type: 'icon',
        icon: 'plus',
        alternateIcon: 'check-fill-circle',
        iconClickEvent: 'addEntity',
        clickHeader: 'addAll',
        keyOrder: 3,
        showOnRightPanel: true,
        showOnUi: true,
        downloadable: true
      }
    },
    right: {
      dsp_outlier_supply_source_to_remove_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_outlier_supply_source_to_remove_ss_name: {
        title: 'Supply Sources',
        toolTipText: 'Supply Sources',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 3,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  line_items_add_audience: {
    left: {
      dsp_line_item_audience_line_item_name: {
        title: 'Line Item',
        toolTipText: 'Line Item',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      select_all: {
        title: 'Select All',
        alternateIconClass: 'u-color-green-base',
        type: 'icon',
        icon: 'plus',
        alternateIcon: 'check-fill-circle',
        iconClickEvent: 'addEntity',
        clickHeader: 'addAll',
        keyOrder: 2,
        showOnRightPanel: true,
        showOnUi: true,
        downloadable: true
      }
    },
    right: {
      dsp_line_item_audience_line_item_name: {
        title: 'Line Item',
        toolTipText: 'Line Item',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  remove_audience_rec: {
    left: {
      dsp_remove_outlier_audience_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_remove_outlier_audience_segment: {
        title: 'Audience',
        toolTipText: 'Audience',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      select_all: {
        title: 'Select All',
        alternateIconClass: 'u-color-green-base',
        type: 'icon',
        icon: 'plus',
        alternateIcon: 'check-fill-circle',
        iconClickEvent: 'addEntity',
        clickHeader: 'addAll',
        keyOrder: 3,
        showOnRightPanel: true,
        showOnUi: true,
        downloadable: true
      }
    },
    right: {
      dsp_remove_outlier_audience_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_remove_outlier_audience_segment: {
        title: 'Audience',
        toolTipText: 'Audience',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 3,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  add_audience_rec: {
    left: {
      dsp_tna_finalised_audience_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_tna_finalised_audience_segment: {
        title: 'Audience',
        toolTipText: 'Audience',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      select_all: {
        title: 'Select All',
        alternateIconClass: 'u-color-green-base',
        type: 'icon',
        icon: 'plus',
        alternateIcon: 'check-fill-circle',
        iconClickEvent: 'addEntity',
        clickHeader: 'addAll',
        keyOrder: 3,
        showOnRightPanel: true,
        showOnUi: true,
        downloadable: true
      }
    },
    right: {
      dsp_tna_finalised_audience_line_item_name: {
        title: 'Line Items',
        toolTipText: 'Line Items',
        type: 'String',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true
      },
      dsp_tna_finalised_audience_segment: {
        title: 'Audience',
        toolTipText: 'Audience',
        type: 'String',
        keyOrder: 2,
        showOnUi: true,
        downloadable: true
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 3,
        showOnUi: true,
        downloadable: true
      }
    }
  },
  add_keywords_sp: {
    left: {
      SUGGESTED: (vueRef) => [
        {
          name: 'keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Keyword',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: '',
              tableColumnName: 'keyword',
              showOnUi: true,
              isFixed: true,
              width: 170
            }
          }
        },
        {
          name: 'keyword_type',
          type: 'STRING',
          uiField: {
            uiLabel: 'Keyword type',
            uiType: 'string',
            uiOrder: '2',
            metadata: {
              toolTipText: '',
              tableColumnName: 'keyword_type',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        },
        {
          name: 'match_type',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match type',
            uiType: 'string',
            uiOrder: '3',
            metadata: {
              toolTipText: '',
              tableColumnName: 'match_type',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        },
        {
          name: 'suggeted bid',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Sugg bid',
            uiType: 'custom',
            uiOrder: '4',
            metadata: {
              widget: 'icon',
              type: 'iconText',
              tableColumnName: 'bid',
              formatType: 'currency',
              showOnUi: true,
              isFixed: false,
              width: 80
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: () => addAll(vueRef, 'bid', 'newBid'),
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all keywords on this page'
            },
            uiOrder: '5',
            metadata: {
              toolTipText: 'Add keyword',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: (row) => addEntity(vueRef, row, 'bid', 'newBid'),
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ]
    },
    right: (vueRef) => [
      {
        name: 'recommended_keyword',
        type: 'STRING',
        uiField: {
          uiLabel: 'Keyword',
          uiType: 'string',
          uiOrder: '1',
          metadata: {
            toolTipText: '',
            tableColumnName: 'recommended_keyword',
            showOnUi: true,
            isFixed: false,
            width: 170
          }
        }
      },
      {
        name: 'keyword_type',
        type: 'STRING',
        uiField: {
          uiLabel: 'Keyword type',
          uiType: 'string',
          uiOrder: '2',
          metadata: {
            toolTipText: '',
            tableColumnName: 'keyword_type',
            showOnUi: true,
            isFixed: false,
            width: 90
          }
        }
      },
      {
        name: 'match_type',
        type: 'STRING',
        uiField: {
          uiLabel: 'Match type',
          uiType: 'string',
          uiOrder: '3',
          metadata: {
            toolTipText: '',
            tableColumnName: 'match_type',
            showOnUi: true,
            isFixed: false,
            width: 70
          }
        }
      },
      {
        name: 'suggeted bid',
        type: 'custom',
        uiField: {
          widget: 'icon',
          uiLabel: 'Sugg bid',
          uiType: 'custom',
          uiOrder: '4',
          metadata: {
            toolTipText: 'Set suggested bid',
            widget: 'icon',
            type: 'iconText',
            tableColumnName: 'bid',
            formatType: 'currency',
            displayIcon: 'trending-neutral',
            showOnUi: true,
            isFixed: false,
            width: 80,
            iconClickEvent: (context, event) => {
              var rowNodes = {
                rowNodes: [context.params.node]
              };
              if (!context.params.data.newBid) {
                context.params.data.newBid = context.params.data.bid;
              }
              vueRef.rightTableInstance.redrawTableRows(rowNodes);
            }
            // contextReturnEvent: this.getArrowButtonContext
          }
        }
      },
      {
        name: 'Bid',
        type: 'custom',
        uiField: {
          uiLabel: 'Bid',
          uiOrder: '5',
          uiType: 'custom',
          metadata: {
            toolTipText: '',
            widget: 'input',
            type: 'number',
            tableColumnName: 'newBid',
            defaultValueColumnName: 'newBid',
            showOnUi: true,
            isFixed: false,
            formatType: 'currency',
            width: 100,
            keyupEvent: '',
            onchangeEvent: (context, event) => {
              // Validatino should happen here
              vueRef.checkValidityForApply();
              context.params.data.newBid = context.bidValue;
            }
          }
        }
      },
      {
        name: 'Remove all',
        type: 'custom',
        uiField: {
          uiLabel: 'Remove all',
          uiType: 'custom',
          uiOrder: '6',
          customStyles:
            'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
          clickHeader: vueRef.removeAll,
          metadata: {
            toolTipText: 'Remove keyword',
            toolTipPosition: 'left',
            widget: 'icon',
            tableColumnName: 'na',
            showOnUi: true,
            isFixed: false,
            iconClickEvent: vueRef.removeEntity,
            displayIcon: 'cross',
            iconSize: 'small',
            suppressSizeToFit: true,
            width: 110
          }
        }
      }
    ]
  },
  add_efficient_search_terms_sp_and_sb: {
    left: {
      SUGGESTED: (vueRef) => [
        {
          name: 'keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Search Term',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: '',
              tableColumnName: 'keyword',
              showOnUi: true,
              isFixed: false,
              width: 170
            }
          }
        },
        {
          name: 'match_type',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match type',
            uiType: 'string',
            uiOrder: '2',
            metadata: {
              toolTipText: '',
              tableColumnName: 'match_type',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        },
        {
          name: 'suggeted bid',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Sugg bid',
            uiType: 'custom',
            uiOrder: '3',
            metadata: {
              widget: 'icon',
              type: 'iconText',
              tableColumnName: 'bid',
              formatType: 'currency',
              showOnUi: true,
              isFixed: false,
              width: 80
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: vueRef.addAll,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all keywords on this page'
            },
            uiOrder: '4',
            metadata: {
              toolTipText: 'Add search term',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: vueRef.addEntity,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ]
    },
    right: (vueRef) => [
      {
        name: 'recommended_keyword',
        type: 'STRING',
        uiField: {
          uiLabel: 'Search Term',
          uiType: 'string',
          uiOrder: '1',
          metadata: {
            toolTipText: '',
            tableColumnName: 'recommended_keyword',
            showOnUi: true,
            isFixed: false,
            width: 170
          }
        }
      },
      {
        name: 'match_type',
        type: 'STRING',
        uiField: {
          uiLabel: 'Match type',
          uiType: 'string',
          uiOrder: '2',
          metadata: {
            toolTipText: '',
            tableColumnName: 'match_type',
            showOnUi: true,
            isFixed: false,
            width: 70
          }
        }
      },
      {
        name: 'suggeted bid',
        type: 'custom',
        uiField: {
          widget: 'icon',
          uiLabel: 'Sugg bid',
          uiType: 'custom',
          uiOrder: '3',
          metadata: {
            toolTipText: 'Set suggested bid',
            widget: 'icon',
            type: 'iconText',
            tableColumnName: 'bid',
            formatType: 'currency',
            displayIcon: 'trending-neutral',
            showOnUi: true,
            isFixed: false,
            width: 80,
            iconClickEvent: (context, event) => {
              var rowNodes = {
                rowNodes: [context.params.node]
              };
              if (!context.params.data.newBid) {
                context.params.data.newBid = context.params.data.bid;
              }
              vueRef.rightTableInstance.redrawTableRows(rowNodes);
            }
            // contextReturnEvent: this.getArrowButtonContext
          }
        }
      },
      {
        name: 'Bid',
        type: 'custom',
        uiField: {
          uiLabel: 'Bid',
          uiOrder: '4',
          uiType: 'custom',
          metadata: {
            toolTipText: '',
            widget: 'input',
            type: 'number',
            tableColumnName: 'newBid',
            defaultValueColumnName: 'newBid',
            showOnUi: true,
            isFixed: false,
            formatType: 'currency',
            width: 100,
            keyupEvent: '',
            onchangeEvent: (context, event) => {
              // Validatino should happen here
              vueRef.checkValidityForApply();
              context.params.data.newBid = context.bidValue;
            }
          }
        }
      },
      {
        name: 'Remove all',
        type: 'custom',
        uiField: {
          uiLabel: 'Remove all',
          uiType: 'custom',
          uiOrder: '5',
          customStyles:
            'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
          clickHeader: vueRef.removeAll,
          metadata: {
            toolTipText: 'Remove search term',
            toolTipPosition: 'left',
            widget: 'icon',
            tableColumnName: 'na',
            showOnUi: true,
            isFixed: false,
            iconClickEvent: vueRef.removeEntity,
            displayIcon: 'cross',
            iconSize: 'small',
            suppressSizeToFit: true,
            width: 110
          }
        }
      }
    ]
  },
  archive_keywords_sp_and_sb: {
    left: {
      SUGGESTED: (vueRef) => [
        {
          name: 'keyword',
          type: 'STRING',
          checkboxSelection: true,
          uiField: {
            uiLabel: 'Keyword',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: '',
              checkboxSelection: true,
              tableColumnName: 'recommended_keyword',
              showOnUi: true,
              isFixed: false,
              width: 170
            }
          }
        }
      ]
    }
  },
  add_negative_keywords_sp_and_sb: {
    left: {
      SUGGESTED: (vueRef) => [
        {
          name: 'keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Negative Keywords',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: '',
              tableColumnName: 'keyword',
              showOnUi: true,
              isFixed: false,
              width: 170
            }
          }
        },
        {
          name: 'matchTypeToShow',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match type',
            uiType: 'string',
            uiOrder: '2',
            metadata: {
              toolTipText: '',
              tableColumnName: 'matchTypeToShow',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: vueRef.addAll,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all keywords on this page'
            },
            uiOrder: '3',
            metadata: {
              toolTipText: 'Add negative keywords',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: vueRef.addEntity,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ]
    },
    right: (vueRef) => [
      {
        name: 'recommended_keyword',
        type: 'STRING',
        uiField: {
          uiLabel: 'Negative Keyword',
          uiType: 'string',
          uiOrder: '1',
          metadata: {
            toolTipText: '',
            tableColumnName: 'recommended_keyword',
            showOnUi: true,
            isFixed: false,
            width: 170
          }
        }
      },
      {
        name: 'matchTypeToShow',
        type: 'STRING',
        uiField: {
          uiLabel: 'Match type',
          uiType: 'string',
          uiOrder: '2',
          metadata: {
            toolTipText: '',
            tableColumnName: 'matchTypeToShow',
            showOnUi: true,
            isFixed: false,
            width: 70
          }
        }
      },
      {
        name: 'Remove all',
        type: 'custom',
        uiField: {
          uiLabel: 'Remove all',
          uiType: 'custom',
          uiOrder: '5',
          customStyles:
            'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
          clickHeader: vueRef.removeAll,
          metadata: {
            toolTipText: 'Remove negative keyword',
            toolTipPosition: 'left',
            widget: 'icon',
            tableColumnName: 'na',
            showOnUi: true,
            isFixed: false,
            iconClickEvent: vueRef.removeEntity,
            displayIcon: 'cross',
            iconSize: 'small',
            suppressSizeToFit: true,
            width: 110
          }
        }
      }
    ]
  },
  add_negative_targets_sp_and_sb: {
    left: {
      SUGGESTED: (vueRef) => [
        {
          name: 'keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Negative Target',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: '',
              tableColumnName: 'keyword',
              showOnUi: true,
              isFixed: false,
              width: 170
            }
          }
        },
        {
          name: 'matchTypeToShow',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match type',
            uiType: 'string',
            uiOrder: '2',
            metadata: {
              toolTipText: '',
              tableColumnName: 'matchTypeToShow',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: vueRef.addAll,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all keywords on this page'
            },
            uiOrder: '3',
            metadata: {
              toolTipText: 'Add negative keywords',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: vueRef.addEntity,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ]
    },
    right: (vueRef) => ({
      entity_ui_label: {
        title: 'Categories & Product',
        toolTipText: 'Categories & Product',
        type: 'append',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true,
        keys: {
          asin: 'competitor_asin',
          asinTitle: 'competitor_asin_title',
          productUrl: 'competitor_asin_product_url',
          category: 'category'
        }
      },
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 5,
        showOnUi: true,
        downloadable: true
      }
    }),
    createAdgroup: {
      right: (vueRef) => ({
        recommended_target: [
          {
            name: 'Categories & Product',
            type: 'custom',
            uiField: {
              widget: 'icon',
              uiLabel: 'Categories & Product',
              uiType: 'string',
              uiOrder: '1',
              metadata: {
                toolTipText: 'Categories & Product',
                type: 'string',
                tableColumnName: 'recommended_target',
                showOnUi: true,
                isFixed: false,
                width: 200
              }
            }
          }
        ],
        remove_all: {
          title: 'Remove All',
          type: 'icon',
          icon: 'cross',
          iconClickEvent: 'removeEntity',
          clickHeader: 'removeAll',
          keyOrder: 2,
          showOnUi: true,
          downloadable: true
        }
      })
    }
  },
  add_targets_to_campaign_sp_and_sb: {
    right: (vueRef) => ({
      entity_ui_label: {
        title: 'Categories & Product',
        toolTipText: 'Categories & Product',
        type: 'append',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true,
        keys: {
          asin: 'competitor_asin',
          asinTitle: 'competitor_asin_title',
          productUrl: 'competitor_asin_product_url',
          category: 'category'
        }
      },
      // If the value if of type array, then we use the getColumnDefinition method
      // from data-transformer. The same one which we use in autoConfigure
      suggested_bid: [
        {
          name: 'suggested bid',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Suggested bid',
            uiType: 'custom',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Set suggested bid',
              widget: 'icon',
              type: 'iconText',
              tableColumnName: 'suggested_bid',
              formatType: 'currency',
              displayIcon: 'trending-neutral',
              showOnUi: true,
              isFixed: false,
              width: 90,
              iconClickEvent: (context, event) => {
                let rowNodes = {
                  rowNodes: [context.params.node]
                };
                if (!context.params.data.new_bid) {
                  context.params.data.new_bid =
                    context.params.data.suggested_bid;
                }
                vueRef.rightTableInstance.redrawTableRows(rowNodes);
                vueRef.checkValidityForApply();
              }
            }
          }
        }
      ],
      new_bid: [
        {
          name: 'new_bid',
          type: 'custom',
          uiField: {
            uiLabel: 'Bid',
            uiOrder: '4',
            uiType: 'custom',
            metadata: {
              toolTipText: '',
              widget: 'input',
              type: 'number',
              tableColumnName: 'new_bid',
              defaultValueColumnName: 'new_bid',
              showOnUi: true,
              isFixed: false,
              formatType: 'currency',
              width: 100,
              keyupEvent: '',
              onchangeEvent: (context, event) => {
                // Validatinon should happen here
                context.params.data.new_bid = context.bidValue;
                vueRef.checkValidityForApply();
              }
            }
          }
        }
      ],
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 5,
        showOnUi: true,
        downloadable: true
      }
    })
  },

  add_sd_audience_targets_to_campaign: {
    right: (vueRef) => ({
      entity_ui_label: {
        title: 'Audiences & Categories',
        toolTipText: 'Audiences & Categories',
        type: 'audience',
        keyOrder: 1,
        showOnUi: true,
        downloadable: true,
        keys: {
          asin: 'competitor_asin',
          asinTitle: 'competitor_asin_title',
          productUrl: 'competitor_asin_product_url',
          category: 'category',
          isAudience: 'isAudience'
        }
      },
      // If the value if of type array, then we use the getColumnDefinition method
      // from data-transformer. The same one which we use in autoConfigure
      forecastValue: [
        {
          name: 'forecastValue',
          type: 'STRING',
          uiField: {
            uiLabel: 'Potential Reach',
            uiType: 'string',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Potential Reach',
              tableColumnName: 'forecastValue',
              suppressSizeToFit: true,
              showOnUi: true,
              isFixed: false,
              width: 110
            }
          }
        }
      ],
      new_bid: [
        {
          name: 'new_bid',
          type: 'custom',
          uiField: {
            uiLabel: 'Bid',
            uiOrder: '4',
            uiType: 'custom',
            metadata: {
              toolTipText: '',
              widget: 'input',
              type: 'number',
              tableColumnName: 'new_bid',
              defaultValueColumnName: 'new_bid',
              showOnUi: true,
              isFixed: false,
              formatType: 'currency',
              width: 100,
              keyupEvent: '',
              onchangeEvent: (context, event) => {
                // Validatinon should happen here
                context.params.data.new_bid = context.bidValue;
                vueRef.checkValidityForApply();
              }
            }
          }
        }
      ],
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 5,
        showOnUi: true,
        downloadable: true
      }
    })
  },
  add_search_term_targets_to_campaign_sp_and_sb: {
    left: (vueRef) => {
      return [
        {
          // currDefinition
          name: 'sku_details',
          type: 'STRING',
          uiField: {
            uiLabel: 'Products',
            uiType: 'string',
            widget: 'sku',
            uiOrder: '1',
            metadata: {
              // widget: 'sku',
              toolTipText: '',
              tableColumnName: 'asin_title',
              columnTitleKey: 'asin_title',
              // columnAsinKey: 'ams_cm_searchterm_product_targeting',
              // columnImageUrlKey: 'ams_cm_searchterm_image_url',
              coloumnProductUrlKey: 'ams_cm_searchterm_product_page_url',
              showOnUi: true,
              isFixed: true,
              width: 170,
              getQuickFilterText: function (params) {
                return (
                  params?.data?.competitor_product_title +
                  '_' +
                  params?.data?.competitor_asin
                );
              }
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: vueRef.addAll,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all Keywords on this page'
            },
            uiOrder: '2',
            metadata: {
              toolTipText: 'Add this target',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              // iconClickEvent: row => addEntity(row, vueRef, false),
              iconClickEvent: vueRef.addEntity,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        },
        {
          name: 'ams_cm_searchterm_match_type_keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match Type',
            uiType: 'string',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Match Type',
              tableColumnName: 'ams_cm_searchterm_match_type_keyword',
              suppressSizeToFit: true,
              showOnUi: true,
              isFixed: false,
              width: 110
            }
          }
        }
      ];
    },
    right: (vueRef) => ({
      asin_title: [
        {
          name: 'Products',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Products',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: 'Products',
              type: 'string',
              tableColumnName: 'asin_title',
              showOnUi: true,
              isFixed: false,
              width: 200
            }
          }
        }
      ],
      suggested_bid: [
        {
          name: 'suggested bid',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Suggested bid',
            uiType: 'custom',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Set suggested bid',
              widget: 'icon',
              type: 'iconText',
              tableColumnName: 'suggested_bid',
              formatType: 'currency',
              // displayIcon: 'trending-neutral',
              showOnUi: true,
              isFixed: false,
              width: 90,
              iconClickEvent: (context, event) => {
                let rowNodes = {
                  rowNodes: [context.params.node]
                };
                // if (!context.params.data.new_bid) {
                context.params.data.new_bid = context.params.data.suggested_bid;
                // }
                vueRef.rightTableInstance.redrawTableRows(rowNodes);
                vueRef.checkValidityForApply();
              }
            }
          }
        }
      ],
      new_bid: [
        {
          name: 'new_bid',
          type: 'custom',
          uiField: {
            uiLabel: 'Bid',
            uiOrder: '4',
            uiType: 'custom',
            metadata: {
              toolTipText: '',
              widget: 'input',
              type: 'number',
              tableColumnName: 'new_bid',
              defaultValueColumnName: 'new_bid',
              showOnUi: true,
              isFixed: false,
              formatType: 'currency',
              width: 100,
              keyupEvent: '',
              onchangeEvent: (context, event) => {
                // Validatinon should happen here
                context.params.data.new_bid = context.bidValue;
                vueRef.checkValidityForApply();
              }
            }
          }
        }
      ],
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 5,
        showOnUi: true,
        downloadable: true
      }
    })
  },
  add_targets_to_campaign_sd: {
    left: (vueRef) => {
      return [
        {
          // currDefinition
          name: 'entity_ui_label',
          type: 'STRING',
          uiField: {
            uiLabel: 'Categories & Product',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              // widget: 'string',
              toolTipText: '',
              tableColumnName: 'entity_ui_label',
              columnTitleKey: 'entity_ui_label',
              showOnUi: true,
              isFixed: true,
              getQuickFilterText: function (params) {
                return (
                  params?.data?.competitor_product_title +
                  '_' +
                  params?.data?.competitor_asin
                );
              }
            }
          }
        },
        {
          name: 'suggested_bid',
          type: 'currency',
          uiField: {
            uiLabel: 'Suggested Bid',
            uiType: 'currency',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Suggested bid',
              tableColumnName: 'suggested_bid',
              suppressSizeToFit: true,
              showOnUi: true,
              isFixed: false,
              width: 110
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: () => {
              addAll(vueRef);
            },
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all Products on this page'
            },
            uiOrder: '2',
            metadata: {
              toolTipText: 'Add this Product',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: (row) => {
                addEntity(vueRef, row);
              },
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ];
    }
  },
  add_search_term_negative_targets_to_campaign_sp_and_sb: {
    left: (vueRef) => {
      return [
        {
          // currDefinition
          name: 'sku_details',
          type: 'STRING',
          uiField: {
            uiLabel: 'Products',
            uiType: 'string',
            widget: 'sku',
            uiOrder: '1',
            metadata: {
              // widget: 'sku',
              toolTipText: '',
              tableColumnName: 'asin_title',
              columnTitleKey: 'asin_title',
              // columnAsinKey: 'ams_cm_searchterm_product_targeting',
              // columnImageUrlKey: 'ams_cm_searchterm_image_url',
              coloumnProductUrlKey: 'ams_cm_searchterm_product_page_url',
              showOnUi: true,
              isFixed: true,
              width: 170,
              getQuickFilterText: function (params) {
                return (
                  params?.data?.competitor_product_title +
                  '_' +
                  params?.data?.competitor_asin
                );
              }
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: vueRef.addAll,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all Keywords on this page'
            },
            uiOrder: '2',
            metadata: {
              toolTipText: 'Add this target',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              // iconClickEvent: row => addEntity(row, vueRef, false),
              iconClickEvent: vueRef.addEntity,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        },
        {
          name: 'ams_cm_searchterm_match_type_keyword',
          type: 'STRING',
          uiField: {
            uiLabel: 'Match Type',
            uiType: 'string',
            uiOrder: '3',
            metadata: {
              toolTipText: 'Match Type',
              tableColumnName: 'ams_cm_searchterm_match_type_keyword',
              suppressSizeToFit: true,
              showOnUi: true,
              isFixed: false,
              width: 110
            }
          }
        }
      ];
    },
    right: (vueRef) => ({
      asin_title: [
        {
          name: 'Products',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Products',
            uiType: 'string',
            uiOrder: '1',
            metadata: {
              toolTipText: 'Products',
              type: 'string',
              tableColumnName: 'asin_title',
              showOnUi: true,
              isFixed: false,
              width: 200
            }
          }
        }
      ],
      ams_cm_searchterm_match_type_keyword: [
        {
          name: 'Match Type',
          type: 'custom',
          uiField: {
            widget: 'icon',
            uiLabel: 'Match Type',
            uiType: 'string',
            uiOrder: '2',
            metadata: {
              toolTipText: 'Match Type',
              type: 'string',
              tableColumnName: 'ams_cm_searchterm_match_type_keyword',
              showOnUi: true,
              isFixed: false,
              width: 200
            }
          }
        }
      ],
      remove_all: {
        title: 'Remove All',
        type: 'icon',
        icon: 'cross',
        iconClickEvent: 'removeEntity',
        clickHeader: 'removeAll',
        keyOrder: 3,
        showOnUi: true,
        downloadable: true
      }
    })
  }
};

export default dictionary;
