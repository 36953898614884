import { isCriteoCommonRetailer } from '@/router/utils';
import { filters } from '@ciq-dev/ciq-ui';

export function getBaseCubeName(retailer) {
  return isCriteoCommonRetailer(retailer) ? 'criteo_common' : retailer;
}

export function getBaseActionType(retailer) {
  return isCriteoCommonRetailer(retailer) ? `criteoCommon` : retailer;
}

// keeping this functionality as it's very likely that we would want to blacklist metrics for retailers in CM.

export const getFinalMeasuresList = (measuresList, retailer) => {
  const metricsToRemove =
    filters.config_check(
      `feature.criteo.${retailer}.campaign_management.metrics_to_remove`
    ) || [];
  return measuresList.filter((measure) => !metricsToRemove.includes(measure));
};
