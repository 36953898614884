import { cloneDeep } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import dictionary from '@/pages/budget-planner-v2/dashboard/helpers/dashboardDictionary.js';
import HttpLayer from '@/utils/services/http-layer';

export const getChartAPIDataPayload = (context, data) => {
  const retailer = context.rootGetters.getRetailer;
  const rollup = context.state.dashboardChartRollup;
  const spendVSPcogs = 'spend_vs_pcogs';
  let apiData = null;
  data.body.retailer[retailer].APIConfig.where =
    transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
  data.body.retailer[retailer].APIConfig.where.date =
    context.state.whereClause.date;
  const monthlyApi = cloneDeep(data.body.retailer[retailer].APIConfig);
  const weeklyApi = cloneDeep(data.body.retailer[retailer].APIConfig);
  delete weeklyApi.timeseriesEnabled;
  delete weeklyApi.timeseriesDimension;
  delete monthlyApi.timeseriesDimension;
  delete monthlyApi.timeseriesEnabled;
  monthlyApi.groupByDimensionsList = [
    'month_number',
    'month',
    'max_report_date'
  ];
  monthlyApi.measuresList = [
    'planned_budget_sum',
    'planned_budget_cumulative_monthly',
    'actual_spend_sum',
    'actual_spend_cumulative_monthly',
    'spend_vs_budget'
  ];
  if (retailer === 'amazon') {
    monthlyApi.measuresList.push(spendVSPcogs);
  }
  weeklyApi.groupByDimensionsList = ['week_number', 'week', 'max_report_date'];
  weeklyApi.measuresList = [
    'planned_budget_sum',
    'planned_budget_cumulative_weekly',
    'actual_spend_sum',
    'actual_spend_cumulative_weekly',
    'spend_vs_budget'
  ];
  if (retailer === 'amazon') {
    weeklyApi.measuresList.push(spendVSPcogs);
  }
  if (rollup === 'rollup_weeks') {
    apiData = weeklyApi;
  } else if (rollup === 'rollup_months') {
    apiData = monthlyApi;
  } else {
    apiData = data.body.retailer[retailer].APIConfig;
  }

  const legendData = cloneDeep(data);
  delete legendData.body.retailer[retailer].APIConfig.timeseriesDimension;
  legendData.body.retailer[retailer].APIConfig.groupByDimensionsList = [];
  legendData.body.retailer[retailer].APIConfig.measuresList = [
    'planned_budget_sum',
    'actual_spend_sum',
    'spend_vs_budget',
    'planned_budget_till_max_report_date'
  ];
  if (retailer === 'amazon') {
    legendData.body.retailer[retailer].APIConfig.measuresList.push(
      spendVSPcogs
    );
  }
  legendData.body.retailer[retailer].APIConfig.timeseriesEnabled = false;

  return {
    apiData,
    legendData: legendData.body.retailer[retailer].APIConfig
  };
};

export const populateChartMetricsData = async (apiData, dataObj) => {
  try {
    let response = await HttpLayer.post({
      APIData: apiData
    });
    const timeSeriesData = [];
    if (!response.success) {
      dataObj.error = true;
    } else {
      const responseData = response?.data;
      if (responseData.length > 0) {
        const data = transformer.mergeResultDimension(responseData);
        const objKeys = Object.keys(data[0]);
        objKeys.forEach((key, index) => {
          if (!dictionary.map[key]) {
            timeSeriesData.push([key]);
          } else {
            timeSeriesData.push([dictionary.map[key].title]);
          }
          timeSeriesData[index].push(...data.map((res) => res[key]));
        });
        // data exists
        dataObj.timeSeriesData = timeSeriesData;
      } else {
        // no data
        dataObj.timeSeriesData = [];
        dataObj.noData = true;
      }
    }
  } catch (err) {
    // error
    dataObj.timeSeriesData = [];
    dataObj.error = true;
    console.error(err);
    throw new Error(err);
  }
};

export const populateChartLegendsData = async (dataObj, legendData, data) => {
  try {
    const legendResponse = await HttpLayer.post({
      APIData: legendData
    });
    if (!legendResponse.success) {
      data.error = true;
      data.timeSeriesData = [];
    } else if (legendResponse.data && legendResponse.data.length > 0) {
      // data exists
      dataObj.legendData = transformer.mergeResultDimension(
        legendResponse.data
      )[0];
    } else {
      // no data
      dataObj.timeSeriesData = [];
      if (!dataObj.error) {
        dataObj.noData = true;
      }
    }
  } catch (err) {
    // error
    dataObj.timeSeriesData = [];
    dataObj.legendData = [];
    dataObj.error = true;
    dataObj.noData = false;
    throw new Error(err);
  } finally {
    dataObj.load = false;
  }
};
