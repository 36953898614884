<template>
  <div class="u-width-100">
    <div class="u-width-100 u-display-flex">
      <!-- <rb-icon v-tippy="tippy" :title="params.toolTipText" class="u-spacing-mh-s tableCell-icon" :class="iconClass" :icon="icon"></rb-icon>
      <div class="u-color-grey-base">Add another Brand</div> -->
      <div
        class="u-border-width-s u-border-left u-border-right u-border-color-grey-xxx-light u-spacing-mh-m"
      >
        <component
          :is="'metricDisplay'"
          :params="params"
        />
      </div>
    </div>
  </div>
</template>

<script>
import metricDisplay from './metricDisplay';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'cardCell',
  components: {
    metricDisplay
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>
