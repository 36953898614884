







































































































































































































import Vue from 'vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import PieChartWidgetStandalone from '@/components/pages/insights/amazon/market-share/molecules/pie-chart-widget-standalone.vue';
import metric from '@/components/basic/metric.vue';

// @ts-ignore
import { formatter } from '@/utils/helpers/formatter.js';
export default Vue.extend({
  name: 'PieChartTableHeader',
  components: {
    RbSelectV2,
    PieChartWidgetStandalone,
    metric
  },
  data() {
    return {
      displayIcon: '',
      alternateIcon: '',
      asinAvailable: true,
      tippy: {
        placement: 'top',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      searchText: null
    };
  },
  computed: {
    hidePieChartMetric(): boolean {
      return this.params.column.selectedEntity?.hidePieChartMetric || false;
    },
    sanitizedTitle(): string {
      return this.params.column.selectedEntity.title
        .replaceAll(/[^a-z0-9]/gi, '-')
        .replaceAll(' ', '');
    },
    tippyOptionsCategoryHeader(): unknown {
      return {
        html: '#navigator' + this.sanitizedTitle,
        ...this.tippy,
        placement: 'bottom'
      };
    },
    percentValue(): string | null {
      if (
        this.params.column.selectedEntity?.part?.value == null ||
        this.params.column.selectedEntity?.overall?.value == null
      ) {
        return null;
      }
      return formatter(
        this.params.column.selectedEntity?.marketSharePercent,
        'percent'
      );
    },
    percentValueTippy(): unknown {
      return { ...this.tippy, placement: 'bottom' };
    },
    getTitle(): string {
      return `Your Market Share in ${this.params.column.selectedEntity?.title}`;
    },
    metricDataNotNull(): boolean {
      return !(
        this.params.column.selectedEntity?.part?.value == null ||
        this.params.column.selectedEntity?.overall?.value == null
      );
    },
    overallAvailable(): boolean {
      return !(this.params.column.selectedEntity?.overall?.value == null);
    }
  },
  methods: {
    navigateToPage(data: string) {
      this?.params?.navigateTo?.(data);
      if (this.$router.currentRoute.path.includes('market_share_lite')) {
        this.$router.push({
          name: 'category-page-lite'
        });
      } else {
        this.$router.push({
          name: 'category-page'
        });
      }
    },
    removeAsin() {
      if (this.params.removeAsinHandler) {
        return this.params.removeAsinHandler();
      }
    }
  }
});
