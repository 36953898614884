var commons = {};
var parent = commons;

commons.debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

commons.scrollPage = function (element, yPosition = 0) {
  var elm = document.getElementsByClassName('detail-panel')[0];
  elm.scrollTo(0, yPosition);
};

commons.getBidValue = function (params, oldVal) {
  var delta = parseFloat(params.value.value);
  if (params.value.selectionType === 'percent') {
    delta = oldVal * (delta / 100);
  }
  if (params.value.type === 'increaseBids') {
    return parseFloat((oldVal + delta).toFixed(2));
  } else {
    return parseFloat((oldVal - delta).toFixed(2));
  }
};

commons.bidChangeApiAction = function (selections, payload, otherParams) {
  if (otherParams.loader !== undefined) {
    this[otherParams.loader] = true;
  }
  var promise = this.$store.dispatch('amsWorkbenchActionApi', payload);
  promise
    .then((dataRecieved) => {
      var response = dataRecieved.data;
      if (!response.success) {
        parent.displaySnackbar.call(this, otherParams.errorMessage);
      } else {
        otherParams.successFn.call(this);
        parent.displaySnackbar.call(this, otherParams.successMessage);
      }
    })
    .finally(() => {
      if (otherParams.loader !== undefined) {
        otherParams.loader = false;
      }
      otherParams.finallyFn();
    });
};

commons.appendTableDefinitionToConfig = function () {
  var vueRef = this;
  var config = this.config.getColumnDefinition(vueRef);
  var widget2 = this.config.widgets.widget2;
  widget2.body.columnDefs = config;
  widget2.body.download.columnMap = config;
};

commons.displaySnackbar = function (response, time) {
  var timer = time ? 10000 : time;
  var content = '<div class="u-spacing-pv-xs">' + response + '</div>';
  this.$snackbar.open({
    message: content,
    duration: timer,
    buttonColor: '#f5d908',
    actionText: ' '
  });
};

commons.closeActionsWorkflow = function () {
  this.showActionsBar = false;
  this.selections = [];
  this.deselectAllRows();
};

commons.checkIfActionIsValid = function (
  actionId,
  allowedActions,
  selectedNodes,
  snackbar
) {
  let removeArchived = false;
  if (allowedActions.includes(actionId)) {
    selectedNodes.forEach((node) => {
      if (node.data && node.data.status && node.data.status === 'archived') {
        removeArchived = true;
        node.setSelected(false);
      }
    });
  }
  if (removeArchived) {
    snackbar.open({
      message: 'Archived campaigns are removed from selection',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
  }
};

commons.emailStatusSnackBar = function (emailStatus, that) {
  const message =
    emailStatus.data && emailStatus.data.success && emailStatus.status === 202
      ? 'Email request successfully sent, please check your email after some time.'
      : 'Request failed, please try again!';
  that.$snackbar.open({
    message: message,
    duration: 6000,
    buttonColor: '#f5d908',
    actionText: ''
  });
};

commons.sendEmailRequest = function (columnMap, APIConfig, that, fileSuffix) {
  const object = {};
  const arr = [...APIConfig.measuresList, ...APIConfig.groupByDimensionsList];
  columnMap.forEach((column) => {
    arr.forEach((elm) => {
      if (elm === column.field) {
        object[column.field] = column.headerName;
      }
    });
  });
  APIConfig.fileSuffix = fileSuffix || null;
  APIConfig.customAPIDecisionVars = {
    downloadRequest: { columnLabels: object }
  };
  delete APIConfig.page;
  delete APIConfig.limit;
  that.$store.dispatch('sendDownloadDataAsEmail', APIConfig);
};
commons.invalidateSDActions = (rows, allowedActions) => {
  let isSDCampaign = false;
  for (const row of rows) {
    if (row?.data?.campaign_type === 'SD') {
      isSDCampaign = true;
      break;
    }
  }
  for (const action of allowedActions) {
    if (action.id !== 'show-chart') {
      action.disabled = isSDCampaign;
    }
  }
  return isSDCampaign;
};

commons.modifyTippyBasedOnActionState = (actions, actionTippys, show) => {
  actions = actions.map((item) => {
    if (item && show && actionTippys[item?.id]) {
      item.tippy = actionTippys[item.id];
    } else if (item.tippy && !show) {
      delete item.tippy;
    }
    return item;
  });
  return actions;
};

commons.validateForArchivedActions = function (actionsArray, tableSelections) {
  var archivedFound = false;
  for (var obj of tableSelections) {
    if (
      obj &&
      obj.data &&
      obj.data.status &&
      obj.data.status.toUpperCase().indexOf('ARCHIVED') > -1
    ) {
      archivedFound = true;
      break;
    }
  }

  for (var iObj of actionsArray) {
    if (iObj.id !== 'show-chart') {
      iObj.disabled = archivedFound;
    }
  }
  return archivedFound;
};

commons.invertedPatternList = [
  'acos_',
  'cpc',
  'rep_oos',
  'iacos',
  'estimated_missed_impressions',
  'estimated_missed_sales',
  'missed_auction_participation_rate'
];

commons.isInverted = function (value) {
  const invertedPatternList = commons.invertedPatternList;
  for (let i = 0; i < invertedPatternList.length; i++) {
    if (value.indexOf(invertedPatternList[i]) > -1) {
      return true;
    }
  }
  return false;
};

commons.calculateTableHeight = (sticky) => {
  const valuesObj = sticky;
  let topHeight = 0;
  for (let i = 0; i < valuesObj.addSpaceElementsSelector.length; i++) {
    const element = document.querySelector(
      valuesObj.addSpaceElementsSelector[i]
    );
    topHeight += element.offsetHeight;
  }
  console.log(topHeight);
  return `100vh - ${topHeight}px`;
};

commons.additionalDateRanges = () => {
  return {
    add: ['lastMonth', 'lastWeek'],
    order: {
      lastWeek: 3,
      lastMonth: 3
    }
  };
};

export { commons };
