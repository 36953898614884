import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { filter } from 'vuedraggable';

const fetchAudienceTaxonomyList = async (categoryPath) => {
  return await HttpService.post('GET_SD_AUDIENCES_TAXANOMY', {
    adType: 'SD',
    categoryPath: categoryPath
  });
};

const fetchAudiencesList = async (categoryPath, filterField) => {
  return await HttpService.post('GET_SD_AUDIENCES_LIST', {
    adType: 'SD',
    filters: [
      {
        field: filterField || 'category',
        values: categoryPath
      }
    ]
  });
};

const formatAudienceListResponse = (audienceResponse, prevCategoryPath) => {
  const audienceResult = audienceResponse?.data?.audiences.map((item) => {
    const forecast =
      item?.forecasts?.inventoryForecasts?.all?.dailyReach || null;
    const forecastValue =
      (forecast &&
        `${formatter(forecast?.lowerBoundInclusive, 'numeric')} - ${formatter(
          forecast?.upperBoundExclusive,
          'numeric'
        )}`) ||
      'NA';
    return {
      categoryUI: item.audienceName,
      category: item.audienceName,
      audienceId: item.audienceId,
      entity_ui_label: item.audienceId,
      categoryPath: [...prevCategoryPath, item.audienceName],
      description: item.description,
      status: item.status,
      forecastValue,
      'ag-grid-has-expand': false,
      isAudience: true
    };
  });
  return audienceResult;
};

function createApiDataForProductsInfo(recommendedProducts) {
  let skuValues = '';
  recommendedProducts.forEach((item) => {
    skuValues += `'${item.asin}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));

  // Execute API's to fetch information regarding the product
  return {
    cubeName: 'asin_campaign_relationship_catalogue_asin',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [
      'product_title',
      'product_url',
      'image_url',
      'brand',
      'sku_price',
      'weeks_of_coverage',
      'availability_status'
    ],
    groupByDimensionsList: ['asin'],
    orderByList: [
      {
        dimension: 'product_title',
        direction: 'ASC'
      }
    ],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'asin',
          dimensionValue: skuValues,
          operator: 'IN'
        }
      ]
    }
  };
}

const categoryMasterLevelCountAndUniqueValues = (arr, key) => {
  const uniqueValues = [];
  const levelMap = arr.reduce((total, currentValue, currentIndex, arr) => {
    const currentCategoryId = currentValue[key];
    if (!total?.[currentCategoryId]) {
      total[currentCategoryId] = 0;
      uniqueValues.push(currentValue);
    }
    total[currentCategoryId]++;
    return total;
  }, {});
  return {
    levelMap,
    uniqueValues
  };
};

const formSPTargetBidsRequestPayload = (recommendations, adGroupId) => {
  const requestPayloadSPTargetBids = {
    expressions: [],
    adGroupId: adGroupId,
    campaignType: 'sp'
  };
  for (const item of recommendations) {
    requestPayloadSPTargetBids.expressions.push([
      { type: 'asinCategorySameAs', value: item.categoryId }
    ]);
  }
  return requestPayloadSPTargetBids;
};

const formSBTargetBidsRequestPayload = (recommendations) => {
  const requestPayloadForBids = {
    campaignType: 'sb',
    categories: []
  };
  for (const item of recommendations) {
    requestPayloadForBids.categories.push(item.categoryId);
  }
  return requestPayloadForBids;
};
const formSPTargetBidsResponsePayload = (
  recommendations,
  suggestedBidsApiResponse
) => {
  const categoryRecommendationBids =
    suggestedBidsApiResponse?.data?.targetBidRecommendations;
  for (let item of categoryRecommendationBids) {
    for (let rec of recommendations) {
      if (item?.expression?.[0]?.value === rec?.categoryId) {
        rec.new_bid = item?.suggestedBid?.suggested || 0;
        rec.suggested_bid = item?.suggestedBid?.suggested || 0;
      }
    }
  }
};

const formSBTargetBidsResponsePayload = (
  recommendations,
  suggestedBidsApiResponse
) => {
  const categoryRecommendations =
    suggestedBidsApiResponse?.data?.categoryRecommendations;
  for (let item of categoryRecommendations) {
    for (let rec of recommendations) {
      if (item.category === rec.categoryId) {
        rec.new_bid = item.suggestedBid;
        rec.suggested_bid = item.suggestedBid;
      }
    }
  }
};

const fetchCategoryBids = async (recommendations, campaignType, adGroupId) => {
  let requestPayloadForBids = null;
  if (campaignType === 'sp' && adGroupId) {
    requestPayloadForBids = formSPTargetBidsRequestPayload(
      recommendations,
      adGroupId
    );
  } else if (campaignType === 'sb') {
    requestPayloadForBids = formSBTargetBidsRequestPayload(recommendations);
  } else {
    return recommendations;
  }

  const campaignTypeAPIMap = {
    sp: 'GET_RECOMMENDATIONS_SP_TARGETS_AMS_ACTION_MANAGER',
    sb: 'GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER'
  };

  let suggestedBidsApiResponse = null;
  try {
    suggestedBidsApiResponse = await HttpService.post(
      campaignTypeAPIMap[campaignType],
      requestPayloadForBids
    );
  } catch {
    console.log('Suggested bids API failed, showing no data state by default');
  } finally {
    if (campaignType === 'sb') {
      formSBTargetBidsResponsePayload(
        recommendations,
        suggestedBidsApiResponse
      );
    } else if (campaignType === 'sp' && adGroupId) {
      formSPTargetBidsResponsePayload(
        recommendations,
        suggestedBidsApiResponse
      );
    }
  }
  return recommendations;
};

const commonStateStructure = () => {
  // created default structure as it is used for most states.
  return { load: false, rows: [], error: false, totalRows: 0 };
};

const state = {
  allStepsData: {},
  categories: commonStateStructure(),
  storeInfo: commonStateStructure(),
  pageAsins: commonStateStructure(),
  assets: commonStateStructure(),
  products: commonStateStructure(),
  allCategories: commonStateStructure(),
  categoriesLevel1: {},
  categoriesLevel2: {},
  categoriesLevel3: {},
  categoriesLevel4: {},
  categoriesLevel5: {},
  allAudiences: commonStateStructure(),
  audienceLevel1: {},
  audienceLevel2: {},
  audienceLevel3: {},
  audienceLevel4: {},
  audienceLevel5: {},
  sdSuggestedTargets: commonStateStructure()
};
const getters = {
  getAllStepsData: (state) => state.allStepsData,
  getCategories: (state) => state.categories,
  getAssets: (state) => state.assets,
  getStoreInfo: (state) => state.storeInfo,
  getProducts: (state) => state.products,
  getAllCategories: (state) => state.allCategories,
  getAllCategoriesLevel1: (state) => state.categoriesLevel1,
  getAllCategoriesLevel2: (state) => state.categoriesLevel2,
  getAllCategoriesLevel3: (state) => state.categoriesLevel3,
  getAllCategoriesLevel4: (state) => state.categoriesLevel4,
  getAllCategoriesLevel5: (state) => state.categoriesLevel5,
  getAllAudiences: (state) => state.allAudiences,
  getAllAudiencesLevel1: (state) => state.audienceLevel1,
  getAllAudiencesLevel2: (state) => state.audienceLevel2,
  getAllAudiencesLevel3: (state) => state.audienceLevel3,
  getAllAudiencesLevel4: (state) => state.audienceLevel4,
  getAllAudiencesLevel5: (state) => state.audienceLevel5,
  getSdSuggestedTargets: (state) => state.sdSuggestedTargets
};

const mutations = {
  MUTATE_SD_SUGGESTED_TARGEGTS(state, data) {
    state.sdSuggestedTargets = cloneDeep(data);
  },
  MUTATE_ALL_STEPS_DATA(state, data) {
    state.allStepsData = cloneDeep(data);
  },
  ALL_CATEGORIES(state, data) {
    state.allCategories = cloneDeep(data);
  },
  CATEGORIES_LEVEL(state, data) {
    Vue.set(state, data.levelName, {});
    Vue.set(state[data.levelName], data.id, {});
    Vue.set(state[data.levelName][data.id], 'rows', data.rows);
    Vue.set(state[data.levelName][data.id], 'load', false);
    Vue.set(state[data.levelName][data.id], 'error', false);
    Vue.set(state[data.levelName][data.id], 'id', data.id);
    Vue.set(state[data.levelName][data.id], 'index', data.index);
    Vue.set(state[data.levelName][data.id], 'level', data.level);
  },
  CATEGORIES(state, data) {
    state.categories = cloneDeep(data);
  },
  AUDIENCES(state, data) {
    state.allAudiences = cloneDeep(data);
  },
  AUDIENCE_LEVEL(state, data) {
    Vue.set(state, data.levelName, {});
    Vue.set(state[data.levelName], data.id, {});
    Vue.set(state[data.levelName][data.id], 'rows', data.rows);
    Vue.set(state[data.levelName][data.id], 'load', false);
    Vue.set(state[data.levelName][data.id], 'error', false);
    Vue.set(state[data.levelName][data.id], 'id', data.id);
    Vue.set(state[data.levelName][data.id], 'index', data.index);
    Vue.set(state[data.levelName][data.id], 'level', data.level);
  },
  ASSETS(state, data) {
    state.assets = cloneDeep(data);
  },
  STORE_INFO(state, data) {
    state.storeInfo = cloneDeep(data);
  },
  PRODUCTS(state, data) {
    state.products = cloneDeep(data);
  }
};

const actions = {
  updateAllSetpsData(context, data) {
    context.commit('MUTATE_ALL_STEPS_DATA', data);
  },

  async fetchSdSuggestedTargets(context, data) {
    const filter = data?.body?.sdFilter;
    if (
      filter?.fromSDProductTargeting &&
      (!data?.tabs?.['Search']?.search?.value || data?.selectedTab !== 'Search')
    ) {
      return;
    }
    const getAllAsinsData = await data.body.getAllAsinsData();
    // Doing this to avaid state management
    if (filter?.fromSDProductTargeting) {
      data?.meta?.updateTargetRecommendations(getAllAsinsData);
      return;
    }
    const suggestedReqPayload = {
      tactic: filter.tactic,
      products: getAllAsinsData,
      typeFilter: filter.typeFilter
    };
    let suggestions = [];
    let load = true;
    let error = false;
    context.commit('MUTATE_SD_SUGGESTED_TARGEGTS', {
      rows: [],
      load,
      error,
      totalRows: 0
    });
    try {
      const suggestedResponse = await HttpService.post(
        filter.url,
        suggestedReqPayload
      );
      suggestions =
        suggestedResponse?.data?.targetRecommendations?.categories?.map(
          (item) => {
            const categoryPath = item.path.join(' > ');
            return {
              category: categoryPath,
              entity_ui_label: `CATEGORY ${categoryPath}`,
              categoryId: item.category,
              categoryUI: categoryPath,
              lookback: filter?.lookback
            };
          }
        );
      load = false;
    } catch {
      error = false;
      load = false;
    }
    context.commit('MUTATE_SD_SUGGESTED_TARGEGTS', {
      rows: suggestions,
      load,
      error,
      totalRows: suggestions?.length || 0
    });
  },

  async fetchAudienceLevel(context, data) {
    const prevCategoryPath = data?.row?.categoryPath || [];
    const level = data?.level;
    const audienceLevelState = `audienceLevel${level}`;
    let result = [];
    try {
      const taxonomyResponse = await fetchAudienceTaxonomyList(
        prevCategoryPath
      );
      const taxonomyResult = taxonomyResponse?.data?.categories.map((item) => {
        return {
          categoryUI: item.category,
          category: item.category,
          categoryPath: [...prevCategoryPath, item.category],
          'ag-grid-has-expand': !!item.audienceCount,
          audienceCount: item.audienceCount
        };
      });
      const audienceResponse = await fetchAudiencesList(prevCategoryPath);
      const audienceResult = formatAudienceListResponse(
        audienceResponse,
        prevCategoryPath
      );
      result = [...taxonomyResult, ...audienceResult];
      context.commit('AUDIENCE_LEVEL', {
        id: data.id,
        levelName: audienceLevelState,
        index: data.index,
        level: data.level,
        rows: result,
        load: false,
        error: false,
        totalRows: 0
      });
    } catch {
      context.commit('AUDIENCE_LEVEL', {
        id: data.id,
        levelName: audienceLevelState,
        index: data.index,
        level: data.level,
        rows: result,
        load: false,
        error: true,
        totalRows: 0
      });
    }
  },

  async fetchL0AudienceTaxonomy(context, data) {
    context.commit('AUDIENCES', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    const amazonAudienceCustomSearch =
      data.tabs?.['Amazon Audience']?.search?.value || '';
    let result = [];
    try {
      if (amazonAudienceCustomSearch?.trim()?.length) {
        const audienceResponse = await fetchAudiencesList(
          [amazonAudienceCustomSearch],
          'audienceName'
        );
        result = formatAudienceListResponse(audienceResponse, [
          amazonAudienceCustomSearch
        ]);
      } else {
        const response = await fetchAudienceTaxonomyList([]);
        result = response?.data?.categories.map((item) => {
          return {
            categoryUI: item.category,
            category: item.category,
            categoryPath: [item.category],
            'ag-grid-has-expand': !!item.audienceCount,
            audienceCount: item.audienceCount
          };
        });
      }

      context.commit('AUDIENCES', {
        rows: result,
        load: false,
        error: false,
        totalRows: 0
      });
    } catch {
      context.commit('AUDIENCES', {
        rows: [],
        load: false,
        error: true,
        totalRows: 0
      });
    }
  },
  fetchStoreInfo(context, data) {
    context.commit('STORE_INFO', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    // actionrouter/fetch/stores?profileId=614698750655456
    const { profileId } = data;
    HttpService.post('GET_AMS_SB_STORE_INFO', '', {
      append: '?profileId=' + profileId
    })
      .then((response) => {
        const storeInfo = response?.data?.response || [];
        context.commit('STORE_INFO', {
          rows: storeInfo,
          load: false,
          error: false,
          totalRows: storeInfo?.length
        });
      })
      .catch(() => {
        context.commit('STORE_INFO', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
      });
  },
  fetchAssets(context, data) {
    // Test Data
    // data.profileId = '614698750655456';
    context.commit('ASSETS', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    HttpService.post('GET_AMS_SB_ASSETS', data)
      .then((response) => {
        const assets = response?.data?.response || [];
        context.commit('ASSETS', {
          rows: assets,
          load: false,
          error: false,
          totalRows: assets?.length
        });
      })
      .catch(() => {
        context.commit('ASSETS', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
      });
  },

  fetchPageAsins(context, data) {
    const { profile, pageUrl } = data;
    return HttpService.post('GET_AMS_PAGE_ASINS', '', {
      append: `?profile=${profile}&pageUrl=${pageUrl}`
    });
  },

  async fetchCategoriesLevel(context, data) {
    const currentLevelCategoryIdKey = `level${data.level}id`;
    const currentLevelCategoryNameKey = `level${data.level}name`;
    const levelName = `categoriesLevel${data.level}`;
    const parentCategory = data?.row?.category;
    const levelAPIMap = {
      categoriesLevel1: {
        value: ['level1name', 'level1id', 'level2id', 'level2name'],
        where: 'id',
        dedup: ['level1id', 'level2id']
      },
      categoriesLevel2: {
        value: ['level2name', 'level2id', 'level3name', 'level3id'],
        where: 'level1id',
        dedup: ['level3id', 'level2id']
      },
      categoriesLevel3: {
        value: ['level3name', 'level3id', 'level4name', 'level4id'],
        where: 'level2id',
        dedup: ['level3id', 'level4id']
      },
      categoriesLevel4: {
        value: ['level4name', 'level4id', 'level5name', 'level5id'],
        where: 'level3id',
        dedup: ['level4id', 'level5id']
      },
      categoriesLevel5: {
        value: ['level5name', 'level5id'],
        where: 'level4id',
        dedup: ['level5id']
      }
    };

    const cube = {
      cubeName: 'categories_master',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: ['client_id', ...levelAPIMap[levelName].value],
      groupByDimensionsList: ['client_id', ...levelAPIMap[levelName].value],
      dimensionList: [],
      orderByList: [],
      where: {
        dimensionNameValueList: [
          {
            dimensionName: levelAPIMap[levelName].where,
            dimensionValue: data.row.categoryId,
            operator: 'ILIKE'
          },
          {
            dimensionName: currentLevelCategoryIdKey,
            dimensionValue: null,
            operator: 'IS_NOT_NULL'
          }
        ]
      },
      dedupBeforeRollup: {
        enableDedupBeforeRollup: true,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
          levelAPIMap[levelName].dedup
      }
    };
    let response = null;
    let responseValues = [];
    try {
      response = await HttpLayer.post({
        APIData: cube
      });
      response = transformer.mergeResultDimension(response.data, true);
      const categoryMasterLevelCountAndUniqueValueItems =
        categoryMasterLevelCountAndUniqueValues(
          response,
          currentLevelCategoryIdKey
        );
      const levelCountMap =
        categoryMasterLevelCountAndUniqueValueItems?.levelMap || {};
      responseValues =
        categoryMasterLevelCountAndUniqueValueItems?.uniqueValues || [];
      responseValues = responseValues.map((item) => {
        const id = item[currentLevelCategoryIdKey];
        return {
          entity_ui_label: `'CATEGORY : ${item[currentLevelCategoryNameKey]}`,
          category: `${parentCategory} > ${item[currentLevelCategoryNameKey]}`,
          'ag-grid-has-expand': levelCountMap?.[id] > 1,
          categoryUI: item[currentLevelCategoryNameKey],
          categoryId: id
        };
      });
      context.commit('CATEGORIES_LEVEL', {
        id: data.id,
        levelName: levelName,
        index: data.index,
        level: data.level,
        rows: responseValues,
        load: false,
        error: false,
        totalRows: 0
      });
    } catch {
      context.commit('CATEGORIES_LEVEL', {
        id: data.id,
        levelName: levelName,
        index: data.index,
        level: data.level,
        rows: null,
        load: false,
        error: true,
        totalRows: 0
      });
    }
  },

  fetchAllCategoriesLevel(context, data) {
    context.commit('ALL_CATEGORIES', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    const APIData = cloneDeep(data?.body?.allCatergoriesLevelCube);
    if (!APIData?.cubeName) {
      return;
    }
    APIData.where = {
      dimensionNameValueList: data?.meta?.localFilters || []
    };
    let response = null;
    let responseValues = [];
    HttpLayer.post({
      APIData
    }).then((res) => {
      response = transformer.mergeResultDimension(res.data, true);
      const categoryMasterLevelCountAndUniqueValueItems =
        categoryMasterLevelCountAndUniqueValues(response, 'id');
      const levelCountMap =
        categoryMasterLevelCountAndUniqueValueItems?.levelMap || {};
      responseValues =
        categoryMasterLevelCountAndUniqueValueItems?.uniqueValues || [];
      responseValues = responseValues.map((item) => {
        return {
          entity_ui_label: `'CATEGORY : ${item.name}`,
          category: item.name,
          categoryUI: item.name,
          'ag-grid-has-expand': levelCountMap[item.id] > 1,
          categoryId: item.id
        };
      });
      context.commit('ALL_CATEGORIES', {
        rows: responseValues,
        load: false,
        error: false,
        totalRows: 0
      });
    });
  },

  fetchSuggestedCategoriesForCampaignId(context, data) {
    context.commit('CATEGORIES', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    const tableData = data;
    const APIData = tableData?.meta?.allSkusCubeAPIRequest();
    HttpLayer.post({
      APIData
    })
      .then((response) => {
        const skus = transformer.mergeResultDimension(response.data, true);
        if (!skus?.length) {
          context.commit('CATEGORIES', {
            rows: [],
            load: false,
            error: false,
            totalRows: 0
          });
          return;
        }
        let skuValues = '';
        skus.forEach((item) => {
          skuValues += `'${item.ams_cm_asin_asin}',`;
        });
        skuValues = cloneDeep(skuValues.slice(0, -1));
        tableData.meta.allSkus = skuValues;
        context.dispatch('fetchCategories', tableData);
      })
      .catch(() => {
        context.commit('CATEGORIES', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
        data?.vueRef?.$snackbar?.open({
          message: 'Could not fetch suggested categories, please try again',
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      });
  },

  fetchCategories(context, data) {
    context.commit('CATEGORIES', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    const skus = data?.meta?.allSkus?.replace(/["']/g, '');
    const skuArr = skus.split(',');
    const adGroupId = data?.meta?.getAdGroupId?.() || null;
    const requestPayload = {
      asins: skuArr,
      campaignType: data?.meta?.campaignType || 'sp',
      resource: 'category'
    };
    HttpService.post('GET_RECOMMENDATIONS_AMS_ACTION_MANAGER', requestPayload)
      .then(async (response) => {
        const recommendationsResponse = (
          response?.data?.recommendations || []
        ).map((item) => {
          item.entity_ui_label = `CATEGORY : ${item.category}`;
          return item;
        });
        const recommendations = await fetchCategoryBids(
          recommendationsResponse,
          data?.meta?.campaignType || 'sp',
          adGroupId
        );
        context.commit('CATEGORIES', {
          rows: recommendations,
          load: false,
          error: false,
          totalRows: recommendations?.length
        });
      })
      .catch(() => {
        context.commit('CATEGORIES', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
      });
  },
  fetchTableDataRecommendationApi: async (
    context,
    { table = 'left', ...data }
  ) => {
    context.commit('PRODUCTS', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    const recommendationApiRequestPayload = data.body.APIConfig;
    try {
      // Recommendation API to get recommended products
      // 1st api call
      const recommendedProductsApiResponse = await HttpService.post(
        'GET_RECOMMENDATIONS_AMS_ACTION_MANAGER',
        recommendationApiRequestPayload
      );
      const recommendedProducts =
        recommendedProductsApiResponse?.data?.recommendations;

      // Execute api to get all info regarding the above received products
      // 2nd api call
      const apiDataForProductsInfo =
        createApiDataForProductsInfo(recommendedProducts);
      const productsInfoApiResponse = await HttpLayer.post({
        APIData: apiDataForProductsInfo
      });
      const finalProductsInfo = transformer.mergeResultDimension(
        productsInfoApiResponse?.data,
        true
      );

      // recommendationbids api to get the suggested bids for each product
      // 3rd api call
      const requestPayloadForBids = {
        campaignType: 'sb',
        adFormat: 'productCollection',
        asins: []
      };
      for (const item of recommendedProductsApiResponse.data.recommendations) {
        requestPayloadForBids.asins.push(item.asin);
      }
      const suggestedBidsApiResponse = await HttpService.post(
        'GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER',
        requestPayloadForBids
      );
      const suggestedBidRecommendations =
        suggestedBidsApiResponse?.data?.asinRecommendations;

      const finalProductsList = recommendedProducts.map((item) => {
        const asinId = item.asin;
        const correspondingInfoObject = finalProductsInfo.find(
          (product) => product?.asin === asinId
        );
        const correspondingSuggestedObject = suggestedBidRecommendations.find(
          (product) => product?.asin === asinId
        );
        return {
          competitor_asin: asinId,
          competitor_asin_title: correspondingInfoObject?.product_title || 'NA',
          competitor_asin_image_url: correspondingInfoObject?.image_url || null,
          competitor_asin_product_url:
            correspondingInfoObject?.product_url || null,
          suggested_bid: correspondingSuggestedObject?.suggestedBid || '-'
        };
      });

      context.commit('PRODUCTS', {
        rows: finalProductsList,
        load: false,
        error: false,
        totalRows: finalProductsList?.length
      });
    } catch (err) {
      console.log(err);
      context.commit('PRODUCTS', {
        rows: [],
        load: false,
        error: false,
        totalRows: 0
      });
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
