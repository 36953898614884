<template>
  <div class="time-series-cell u-width-100 u-height-100">
    <div
      v-if="!loading"
      class="u-height-100"
      :class="cellBackground"
    >
      <div
        class="cell-heading u-spacing-pv-m u-spacing-ml-m u-spacing-mr-l u-spacing-pr-xs u-display-flex u-flex-align-items-center"
        :class="!firstPvpValue ? 'u-flex-justify-content-center' : ''"
      >
        <div class="u-font-size-6 u-display-flex u-flex-align-items-center">
          <span class="u-spacing-mr-xs u-color-grey-base">{{
            displayValue(value)
          }}</span>
        </div>
      </div>
      <div
        v-if="firstPvpValue"
        class="u-display-flex u-spacing-mr-xxl u-spacing-pr-s u-spacing-ml-m u-spacing-mb-m"
      >
        <vs-value
          v-show="!hidePPColumn"
          class="u-spacing-mr-m"
          :pvp-value="firstPvpValue"
          :pvp-diff-value="firstPvpDiffValue"
          :pvp-text="firstPvpText"
          :is-inverted="firstPvpValueInverted"
        />
        <div
          v-show="!hidePPColumn"
          class="vertical-line"
        />
        <vs-value
          :pvp-value="secondPvpValue"
          :pvp-diff-value="secondPvpDiffValue"
          :pvp-text="secondPvpText"
          :is-inverted="secondPvpValueInverted"
        />
      </div>
    </div>
    <div
      v-else
      class="u-spacing-p-s"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="loading"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';
import vsValue from '@/components/basic/vsValue.vue';
import loader from '@/components/basic/loader';

export default {
  components: {
    vsValue,
    loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: null
    },
    jspValue: {
      type: Number,
      default: null
    },
    jspPvpValue: {
      type: Number,
      default: null
    },
    firstPvpText: {
      type: String,
      default: 'PP'
    },
    secondPvpText: {
      type: String,
      default: 'LY'
    },
    type: {
      type: String,
      default: 'string'
    },
    unit: {
      type: String,
      default: null
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: null
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    firstPvpValue: {
      type: Number,
      default: null
    },
    secondPvpValue: {
      type: Number,
      default: null
    },
    firstPvpDiffValue: {
      type: Number,
      default: null
    },
    secondPvpDiffValue: {
      type: Number,
      default: null
    },
    firstPvpValueInverted: {
      type: Boolean,
      default: null
    },
    secondPvpValueInverted: {
      type: Boolean,
      default: null
    },
    cellBackground: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedCurrency() {
      return this.$store?.getters?.getSelectedCurrency || {};
    },
    hidePPColumn() {
      const pvpDateObj = this.$store.getters.getWhereClause.pvpDate;

      return pvpDateObj?.compare_name === 'lastYear';
    },
    displayValue() {
      return (val) => {
        return formatter(
          val,
          this.unit?.trim().length ? this.unit : this.type,
          this.naText,
          undefined,
          undefined,
          this.selectedCurrency
        );
      };
    }
  }
};
</script>

<style lang="css" scoped>
.cell-heading {
  width: fit-content;
  height: fit-content;
}

.more-target {
  background-color: rgba(62, 169, 94, 0.1);
}

.less-target {
  background-color: rgba(215, 38, 61, 0.05);
}

.dot {
  height: 4px;
  width: 4px;
  background-color: #aaadb1;
  border-radius: 50%;
  display: inline-block;
}

.vertical-line {
  padding: 8px 8px 8px 8px;
  border-left: 1px solid #e9eaeb;
}
</style>
