import { cloneDeep, groupBy } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import {
  modifyWhereClauseForEsName,
  transformWhereClauseDimensionList
} from '@/components/ams/campaigns/storeHelper.js';
import { CubeConfig as CubeConfigKeywords } from '@/components/ams/campaigns/keywords/cubeConfig.js';
import { CubeConfig as CubeConfigCampaigns } from '@/components/ams/campaigns/campaigns/cubeConfig.js';

export const transformToBatchValues = (payload, isBidChange) => {
  let newPayload = [];
  if (payload[0].actionType === 'campaignMetadataChange') {
    for (const row of payload) {
      const transformedPayload = transformCamapigns(row, isBidChange);
      if (transformedPayload.campaignId && transformedPayload.profileId) {
        newPayload.push(transformedPayload);
      }
    }
    return {
      isRecursive: false,
      newPayload
    };
  } else {
    newPayload = transformKeywordsAndTargets(payload, isBidChange);
  }
  return newPayload;
};

const transformCamapigns = (payload, isBidChange) => {
  const {
    campaignId,
    profileId,
    campaignType = null,
    state = null,
    view_old_state = null,
    new_daily_budget = null,
    daily_budget = null
  } = payload.actionPayload;
  const { name } = payload.viewPayload;
  const actionType = payload.actionType;
  const basePayload = {
    campaignId,
    profileId,
    name,
    campaignType,
    actionType
  };
  if (isBidChange) {
    basePayload.dailyBudget = new_daily_budget;
    basePayload.oldDailyBudget = daily_budget;
  } else {
    basePayload.state = state;
    basePayload.oldState = view_old_state;
  }
  return basePayload;
};

const transformKeywordsAndTargets = (payload, isBidChange) => {
  const groupedPayload = groupBy(payload, 'actionType');
  const newPayload = [];
  for (const key in groupedPayload) {
    let tempPayload = [];
    for (const row of groupedPayload[key]) {
      const transformedPayload = generateKeywordTargetValues(
        row,
        isBidChange,
        key === 'keywordMetadataChange'
      );
      if (
        transformedPayload.targetId ||
        (transformedPayload.keywordId &&
          transformedPayload.campaignId &&
          transformedPayload.profileId)
      ) {
        tempPayload.push(transformedPayload);
      }
    }
    newPayload.push(tempPayload);
  }
  return {
    isRecursive: true,
    newPayload
  };
};

const generateKeywordTargetValues = (payload, isBidChange, keywordAction) => {
  const {
    campaignId,
    keyword_id,
    profileId,
    adgroupId = null,
    adgroup_id = null,
    new_bid = null,
    view_old_bid = null,
    previousStatus = null,
    state = null,
    campaignType = null,
    targetId = null,
    target_id = null
  } = payload.actionPayload;
  const { adgroup_name, name, match_type, keyword_text } = payload.viewPayload;
  const actionType = payload.actionType;
  const basePayload = {
    profileId,
    campaignType,
    campaignId,
    adGroupId: adgroupId || adgroup_id,
    keywordId: keyword_id,
    adGroupName: adgroup_name,
    matchType: match_type,
    campaignName: name,
    actionType
  };

  if (isBidChange) {
    basePayload.bid = new_bid;
    basePayload.oldBid = view_old_bid;
  } else {
    basePayload.state = state;
    basePayload.oldState = previousStatus;
  }

  if (keywordAction) {
    basePayload.keywordText = keyword_text;
    basePayload.keywordId = keyword_id;
  } else {
    if (isBidChange) {
      basePayload.newBid = new_bid;
      delete basePayload.bid;
    }
    basePayload.targetId = keyword_id || targetId || target_id;
    delete basePayload.keywordId;
  }
  return basePayload;
};

const isStatusFilterApplied = (payload) => {
  let statusCheck = false;
  payload.adv_metadata.where.dimensionNameValueList.forEach((dimension) => {
    if (
      dimension.dimensionName === 'campaign_status' ||
      dimension.dimensionName === 'status'
    ) {
      statusCheck = true;
    }
  });
  return statusCheck;
};

const cutomChangesToBulkPayloadForSelectAll = (payload, actionType) => {
  // overwrite 'orderByList' value in all cases
  let orderByList = [];
  if (actionType === 'campaignMetadataChange') {
    orderByList = [
      {
        dimension: 'campaign_id',
        direction: 'DESC'
      }
    ];
  } else {
    orderByList = [
      {
        dimension: 'keyword_id',
        direction: 'DESC'
      }
    ];
  }
  payload.cubeExecutionRequest.orderByList = orderByList;
  // adding default dimnesionNameValueList for adv_metadata
  const dimensionNameValueForMetadata = {
    dimensionName:
      actionType === 'campaignMetadataChange' ? 'campaign_status' : 'status',
    dimensionValue: 'archived',
    operator: 'NOT_ILIKE'
  };
  // check if there is a status filter already.
  const statusFilterCheckClone = cloneDeep(
    payload.cubeExecutionRequest.bundleCubeExecutionRequest
  );
  const doesStatusFilterExist = isStatusFilterApplied(statusFilterCheckClone);
  if (!doesStatusFilterExist) {
    payload.cubeExecutionRequest.bundleCubeExecutionRequest.adv_metadata.where.dimensionNameValueList.push(
      dimensionNameValueForMetadata
    );
  }
  // overwrite limit as 1000 and page as 1
  payload.cubeExecutionRequest.limit = 1000;
  payload.cubeExecutionRequest.page = 1;
};

export const tranformNewBatchPayloadForSelectAll = (
  context,
  payload,
  isBidChange,
  selections
) => {
  const retailer = context.$store.getters.getRetailer;
  const userName = context.$store.getters.getSessionUser?.email;
  let actionType = payload[0]?.actionType || payload?.actionType;
  if (actionType === 'targetMetadataChange') {
    actionType = 'keywordMetadataChange';
  }
  const whereClauseMapping = {
    campaignMetadataChange: 'getCampaignsWhereClause',
    targetMetadataChange: 'getCampaignsKeywordsWhereClause',
    keywordMetadataChange: 'getCampaignsKeywordsWhereClause'
  };
  const dimensionNameList = transformer.getCompleteWhereClause(
    context.config.widgets.widget1.meta.localFilters || [],
    context.$store.getters[whereClauseMapping[actionType]]
  );
  const whereClause = {
    ...context.$store.getters[whereClauseMapping[actionType]],
    dimensionNameValueList: dimensionNameList.dimensionNameValueList
  };
  const cmCubeConfig =
    actionType === 'campaignMetadataChange'
      ? CubeConfigCampaigns
      : CubeConfigKeywords;
  const cubeConfig = cloneDeep(cmCubeConfig.request);
  const inputTypeMapper = {
    increaseBids: 'increase',
    decreaseBids: 'decrease',
    newBids: 'setNewValue'
  };
  const inputActionMapper = {
    percent: 'percentage'
  };
  const action = {
    status: !isBidChange ? selections[0]?.value : null,
    metaData: isBidChange
      ? {
          inputAction:
            inputActionMapper[selections?.value?.selectionType] ||
            selections?.value?.selectionType,
          inputType:
            inputTypeMapper[selections?.value?.type] || selections?.value?.type,
          value: selections.value.value
        }
      : null
  };
  const modifiedWhereClause =
    whereClause?.dimensionNameValueList?.length > 0
      ? modifyWhereClauseForEsName(whereClause)
      : whereClause;
  // adding whereClauseValues to the cubes
  for (const cubeValue in cubeConfig.bundleCubeExecutionRequest) {
    cubeConfig.bundleCubeExecutionRequest[cubeValue].where =
      modifiedWhereClause;
  }
  const whereCloneForDimensionListFilter = cloneDeep(modifiedWhereClause);
  const requestPayload = {
    retailer,
    userName,
    actionSource: 'ui_async_actions_bulk_select',
    actionType,
    isRealtimeEnabled: true,
    action,
    cubeExecutionRequest: {
      where: modifiedWhereClause,
      bundleCubeExecutionRequest: transformWhereClauseDimensionList(
        cubeConfig,
        whereCloneForDimensionListFilter
      ),
      ...cubeConfig
    }
  };
  cutomChangesToBulkPayloadForSelectAll(requestPayload, actionType);
  return requestPayload;
};

export const createBacthPayload = (context, payload, isForBo = false) => {
  const retailer = context.$store.getters.getRetailer;
  const userName = context.$store.getters.getSessionUser?.email;
  const clonedPayload = cloneDeep(payload);
  const intermediatePayload = groupBy(clonedPayload, 'campaignType');
  const finalPayload = {};

  const keyMap = {
    SP: 'spPayload',
    SD: 'sdPayload',
    SB: 'sbPayload'
  };

  for (const key in intermediatePayload) {
    if (keyMap[key]) {
      finalPayload[keyMap[key]] = deleteKeysFromPayload(
        intermediatePayload[key]
      );
    }
  }

  for (const key in keyMap) {
    if (!finalPayload[keyMap[key]]) {
      finalPayload[keyMap[key]] = [];
    }
  }

  const requestPayload = {
    retailer,
    userName,
    actionSource: isForBo ? 'budget_optimiser' : 'ui_async_actions_bulk_select',
    actionType:
      payload[0].actionType ||
      payload?.actionType ||
      payload[0].budgetPlannerMetadata.action,
    isRealtimeEnabled: !isForBo,
    payload: finalPayload
  };
  return requestPayload;
};

const deleteKeysFromPayload = (payload) => {
  for (const row of payload) {
    delete row.campaignType;
    delete row.actionType;
  }
  return payload;
};
