<template>
  <div class="u-display-flex u-width-100 u-height-100 u-flex-direction-column">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {};
</script>
