import { cloneDeep, isNumber } from 'lodash';
import moment from 'moment-timezone';
import { getYearsToAdd } from '@/pages/budget-planner-v2/create-plan/store/helpers/utils.js';

export const sortBudgetPlanInputRows = (
  rows,
  key,
  direction,
  isAutomations = false
) => {
  return rows.sort((a, b) => {
    let aVal = isAutomations ? a?.automationConstraints?.[key] : a[key];
    let bVal = isAutomations ? b?.automationConstraints?.[key] : b[key];
    if (aVal === null) return 1; // move nulls to end
    if (bVal === null) return -1; // move nulls to end

    // convert to lowercase for case insensitive comparison
    if (typeof aVal === 'string') aVal = aVal.toLowerCase();
    if (typeof bVal === 'string') bVal = bVal.toLowerCase();

    // standard comparison for non-null values
    if (direction === 'asc') {
      return aVal > bVal ? 1 : -1;
    } else {
      return aVal < bVal ? 1 : -1;
    }
  });
};

export const slaveTableDataTransformer = (row) => {
  const newRow = cloneDeep(row);
  const months = moment.monthsShort();
  const rows = [];
  let count = 1;
  for (let i = 0; i < months.length; i++) {
    const quarterLength = 3;
    const quarter = `q${count}`;
    const currentValue = parseInt(row[months[i].toLowerCase()]);
    if (!newRow[quarter]) {
      newRow[quarter] = {
        name: `Quarter ${count}`,
        total: null,
        nextLevel: [],
        'ag-grid-has-expand': true
      };
    }

    if (currentValue) {
      const parentTotal =
        row.name === 'Entire Business' ? row?.total : row?.finalBudgetPlan;
      if (newRow[quarter].total === null) {
        newRow[quarter].total = 0;
      }
      newRow[quarter].total += currentValue;
      newRow[quarter].parent = row.name;
      newRow[quarter].percentage = (newRow[quarter].total / parentTotal) * 100;
    }
    newRow[quarter].nextLevel.push({
      name: months[i],
      total: isNaN(currentValue) ? null : currentValue
    });

    const compute = i + 1;
    if (compute % quarterLength === 0) {
      rows.push(newRow[quarter]);
      count++;
    }
  }
  return rows;
};

export const updateExpandLevelInfo = (context, data, stateName) => {
  const { level, id, index, row } = data;
  const rows = row.nextLevel;
  for (let item of rows) {
    item.percentage = (item?.total / row?.total) * 100;
    item.parent = row.name;
  }
  context.commit('MUTATE_EXPAND_TABLE', {
    stateName: stateName,
    data: {
      rows,
      id,
      load: false,
      error: false,
      index,
      level
    }
  });
};
export const masterLevelDataTransformer = (rows, months) => {
  const masterTableData = [];
  for (const row of rows) {
    const { name, nextLevel, ui_label } = row;
    let total = null;
    for (const month of months) {
      const monthKey = month.toLowerCase();
      const paresdMonthValue = parseInt(row?.[monthKey]);
      if (paresdMonthValue >= 0 && !isNaN(paresdMonthValue)) {
        if (total === null) {
          total = 0;
        }
        total += paresdMonthValue;
      }
    }
    let newNextLevel = [];
    if (nextLevel?.length) {
      newNextLevel = masterLevelDataTransformer(nextLevel, months);
    }
    masterTableData.push({
      nextLevel: newNextLevel,
      'ag-grid-has-expand': newNextLevel.length,
      name,
      ui_label,
      originalRow: row,
      total
    });
  }
  return masterTableData;
};

export const addNewLevelData = (
  response,
  parentName = null,
  levelDropdown = null,
  overrideSelectedLevelDropdown = null
) => {
  const delimiter = '|-|';
  const monthsShort = moment.monthsShort();
  const monthValues = monthsShort.reduce(
    (a, v) => ({ ...a, [v.toLowerCase()]: null }),
    {}
  );
  return response.map((item) => {
    let searchKey = '';
    const newMonthValues = cloneDeep(monthValues);
    const monthBudgetMap = {};
    for (const row of item.executionStrategy) {
      const month = moment(row.startDate, 'YYYY-MM-DD')
        .format('MMM')
        .toLowerCase();
      monthBudgetMap[month] = row.budget;
    }
    for (let key in newMonthValues) {
      if (item?.[key] >= 0 || item?.[key]) {
        newMonthValues[key] = item[key];
      }
      if (monthBudgetMap[key]) {
        newMonthValues[key] = monthBudgetMap[key];
      }
    }
    if (item.nextLevel.length) {
      searchKey = item.nextLevel.reduce((a, v) => {
        a += delimiter + v?.ui_label;
        return a;
      }, '');
    }
    searchKey += delimiter + item.ui_label;
    return {
      levelDropdown,
      searchKey: searchKey?.toLowerCase(),
      parentName,
      selectedLevelDropdown:
        item.selectedLevelDropdown ||
        overrideSelectedLevelDropdown ||
        levelDropdown?.[0] ||
        null,
      ...item,
      'ag-grid-has-expand': item?.nextLevel?.length,
      ...newMonthValues
    };
  });
};

export const commonStateStructure = () => {
  // created default structure as it is used for most states.
  return { load: false, rows: [], error: false, totalRows: 0 };
};

export const converMonthsShortToMonthsDates = (monthsShort, addYear) => {
  const monthDatesMap = {};
  for (const month of monthsShort) {
    monthDatesMap[month] = {
      startDate: moment()
        .month(month)
        .startOf('month')
        .add(getYearsToAdd(addYear), 'year')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .month(month)
        .endOf('month')
        .add(getYearsToAdd(addYear), 'year')
        .format('YYYY-MM-DD')
    };
  }
  return monthDatesMap;
};

export const computeAutomationsObj = (automations) => {
  let automationsObj = {};
  for (const i in automations) {
    if (automations[i]) {
      automationsObj[i] = automations[i];
    }
  }
  if (Object.keys(automationsObj).length === 0) {
    automationsObj = null;
  }
  return automationsObj;
};

export const transformBudgetPlanInputIntoPacing = (
  rows,
  monthDatesMap,
  seasonalityEventMap,
  selectedLevelDropdown = null,
  isAutomationsEnabledCheck = false
) => {
  const newRows = [];
  for (const row of rows) {
    let nextLevel = [];
    row.executionStrategy = [];
    computeFinalBudgetPlan(row, monthDatesMap);
    if (row.ui_label !== 'Uncategorized Campaign(s)') {
      if (row?.nextLevel?.length) {
        nextLevel = transformBudgetPlanInputIntoPacing(
          row.nextLevel,
          monthDatesMap,
          seasonalityEventMap,
          row?.selectedLevelDropdown || null,
          isAutomationsEnabledCheck
        );
      }

      const seasonality =
        computeSeasonality(
          seasonalityEventMap,
          row,
          row?.finalBudgetPlan === null
        ) || [];
      const dropdownLevel =
        selectedLevelDropdown?.title || row?.selectedLevelDropdown?.title;
      newRows.push({
        nextLevel,
        dimensionName: row.dimensionName,
        position: row.position,
        ...(isAutomationsEnabledCheck && {
          automationConstraints: row.automationConstraints
            ? computeAutomationsObj(row?.automationConstraints)
            : null
        }),
        levelName: row.levelName,
        title: row.ui_label,
        ui_label: row.ui_label,
        executionStrategy: row.executionStrategy,
        selectedLevelDropdown: row?.selectedLevelDropdown || null,
        name: row.name,
        isUserDefined:
          row.levelName === dropdownLevel && row.finalBudgetPlan >= 0,
        // add seasonality here
        seasonality,
        level: ++row.position,
        id: row?.id || null,
        finalBudgetPlan: row.finalBudgetPlan
      });
    }
  }
  return newRows;
};

const computeFinalBudgetPlan = (row, monthDatesMap) => {
  row.finalBudgetPlan = null;
  for (const month in monthDatesMap) {
    const monthBudget = row[month.toLowerCase()];
    if (parseInt(monthBudget) >= 0) {
      if (row.finalBudgetPlan === null) {
        row.finalBudgetPlan = 0;
      }
      row.finalBudgetPlan += parseInt(monthBudget);
      row.executionStrategy.push({
        ...monthDatesMap[month],
        budget: monthBudget
      });
    }
  }
};

export const addEntireBusinessToPacingPayload = (rows, seasonalityEventMap) => {
  const entireBusinessMap = {};
  const executionStrategy = [];
  let finalBudgetPlan = null;
  for (const row of rows) {
    for (const exec of row.executionStrategy) {
      const key = `${exec.startDate}_${exec.endDate}`;
      if (!entireBusinessMap[key]) {
        entireBusinessMap[key] = 0;
      }
      entireBusinessMap[key] = entireBusinessMap[key] + parseInt(exec.budget);
    }
  }
  for (const execKey in entireBusinessMap) {
    const values = execKey.split('_');
    if (finalBudgetPlan === null) {
      finalBudgetPlan = 0;
    }
    finalBudgetPlan += entireBusinessMap[execKey];
    executionStrategy.push({
      startDate: values[0],
      endDate: values[1],
      budget: entireBusinessMap[execKey]
    });
  }
  const seasonality =
    computeSeasonality(seasonalityEventMap, { name: 'EB' }) || [];
  return {
    executionStrategy,
    level: 0,
    id: null,
    finalBudgetPlan,
    title: 'EB',
    seasonality,
    name: 'EB',
    nextLevel: rows,
    isUserDefined: false
  };
};

const computeSeasonality = (seasonalityEventMap, row, invalidate = false) => {
  let seasonality = [];
  for (let key in seasonalityEventMap) {
    const newKey = key.split('--|--')[0];
    if (newKey === row?.name && seasonalityEventMap?.[key]?.length) {
      invalidate
        ? delete seasonalityEventMap[key]
        : seasonality.push(...seasonalityEventMap[key]);
    }
  }
  return seasonality;
};

export const restoreSeasonality = (entities, seasonalityEventMap) => {
  for (const entity of entities.nextLevel) {
    if (entity?.seasonality.length) {
      for (const seasonality of entity.seasonality) {
        seasonalityEventMap[`${entity.name}--|--${seasonality.id}`] = [
          seasonality
        ];
      }
    }
    restoreSeasonality(entity, seasonalityEventMap);
  }
};

export const entireBusinessMonthlyTotals = (months, masterTableData) => {
  const monthValues = months.reduce(
    (a, v) => ({ ...a, [v.toLowerCase()]: null }),
    {}
  );
  let total = null;
  for (const row of masterTableData) {
    for (const month in monthValues) {
      const monthIntValue = parseInt(row.originalRow[month]) || null;
      if (isNumber(monthIntValue)) {
        if (monthIntValue === null) {
          monthValues[month] = 0;
        }
        monthValues[month] += monthIntValue;
      }
    }
    total = calculateOverAllTotal(total, row);
  }
  return {
    total,
    monthValues
  };
};

const calculateOverAllTotal = (total, row) => {
  if (isNumber(row.total)) {
    if (total === null) {
      total = 0;
    }
    total += row.total;
  }
  return total;
};
