const allMeasureList = [
  'count',
  'parent_asin_title',
  'latest_image_url',
  'parent_asin_product_url',
  'comp_sku1_title',
  'comp_sku1_product_url',
  'comp_sku2_title',
  'comp_sku2_product_url',
  'comp_sku3_title',
  'comp_sku3_product_url',
  'comp_sku4_title',
  'comp_sku4_product_url',
  'comp_sku5_title',
  'comp_sku5_product_url',
  'avg_offer_price',
  'subscribe_and_save_price',
  'ds_glance_views',
  'customer_orders',
  'conversion_percentile',
  'unit_cogs',
  'gross_unit_margin',
  'gross_unit_margin_perc',
  'shipped_cogs',
  'shipped_revenue',
  'ordered_revenue',
  'suppressed_days',
  'lbb_days',
  'oos_days',
  'gross_shipped_margin_perc',
  'gross_ordered_margin_perc',
  'customer_returns',
  'vendor_units_received',
  'new_question_count',
  'productdetailsbulletcount',
  'imagescount',
  'apluscontent',
  'premium_apluscontent',
  'important_information',
  'new_review_count',
  'category',
  'subcategory',
  'brand',
  'asin_name',
  'parent_asin',
  'external_id',
  'id_type',
  'model_number',
  'released',
  'product_group',
  'brand_code',
  'offer_price_change',
  'promotion_types',
  'promotion_messages',
  'style_number',
  'color',
  'color_count',
  'latest_offer_price',
  'pdp_availability_text',
  'pdp_availability_status',
  'units_at_amazon',
  'sellable_on_hand_cost',
  'actual_unavailability',
  'revenue_lost_ops_unavailable_sku',
  'unsellable_units_at_amazon',
  'open_po_qty',
  'sell_through_rate',
  'KEYWORDS_WITH_TOP_3_APPS',
  'KEYWORDS_WITH_TOP_5_APPS',
  'KEYWORDS_WITH_TOP_10_APPS',
  'CATEGORY_1',
  'CATEGORY_1_BEST_SELLER_RANK',
  'CATEGORY_2',
  'CATEGORY_2_BEST_SELLER_RANK',
  'CATEGORY_3',
  'CATEGORY_3_BEST_SELLER_RANK',
  'CATEGORY_4',
  'CATEGORY_4_BEST_SELLER_RANK',
  'bsr_category_obj_size',
  'length_of_title',
  'LENGTH_OF_DESCRIPTION',
  'AVG_PRODUCT_RATING',
  'TOTAL_REVIEW_COUNT',
  'TOTAL_QUESTION_COUNT',
  'COMP_SKU1',
  'COMP_SKU2',
  'COMP_SKU3',
  'COMP_SKU4',
  'COMP_SKU5',
  'unhealthy_inventory',
  'unhealthy_units',
  'replenishment_category',
  'weeks_of_coverage',
  'weeks_on_hand_avc',
  'daily_sales_trailing_30_day_average',
  'parent_manufacturer_code',
  'manufacturer_code',
  'ds_elasticity_value',
  'ds_elasticity_bucket',
  'scrape_date',
  'video_present',
  'net_ppm_week_start_date',
  'net_ppm_week_end_date',
  'net_ppm_month_label',
  'monthly_net_ppm',
  'weekly_net_ppm',
  'actual_lbb_rate',
  'revenue_lost_lbb',
  'contribution_margin_percentage',
  'contribution_profit',
  'net_pppu',
  'package_dimension_value',
  'dimension_unit',
  'unit_weight',
  'weight_unit',
  'fulfilment_cost_per_unit',
  'total_fulfilment_cost',
  'storage_cost_per_unit',
  'total_storage_cost',
  'sns_net_sales',
  'sns_qty_sold',
  'sns_rebate_percentage',
  'percentage_sns_sales',
  'percentage_sns_units',
  'units_shipped',
  'avc_list_price',
  'weeks_of_cover_dfi',
  'stock'
];

const commonApiParamsObj = function (objRcvd) {
  const orderByListDefault = [];

  if (objRcvd === undefined) {
    return {};
  }
  return {
    cubeName: objRcvd.name,
    getLatestAvailableInsteadOfRollup:
      objRcvd.getLatestAvailableInsteadOfRollup === undefined
        ? false
        : objRcvd.getLatestAvailableInsteadOfRollup,
    timeseriesEnabled:
      objRcvd.timeseriesEnabled === undefined
        ? false
        : objRcvd.timeseriesEnabled,
    pvpenabled: objRcvd.pvpenabled === undefined ? false : objRcvd.pvpenabled,
    yoyenabled: objRcvd.yoyenabled === undefined ? false : objRcvd.yoyenabled,
    measuresList:
      objRcvd.measuresList === undefined ? [] : objRcvd.measuresList,
    groupByDimensionsList:
      objRcvd.groupByDimensionsList === undefined
        ? []
        : objRcvd.groupByDimensionsList,
    timeseriesDimension:
      objRcvd.timeseriesDimension === undefined
        ? 'FEED_DATE'
        : objRcvd.timeseriesDimension,
    dedupBeforeRollup: {
      enableDedupBeforeRollup: true,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: []
    },
    where: {
      date: {
        from: '2018-01-01',
        to: '2018-03-01'
      },
      dimensionNameValueList:
        objRcvd.dimensionNameValueList === undefined
          ? []
          : objRcvd.dimensionNameValueList
    },
    orderByList:
      objRcvd.orderByList === undefined
        ? orderByListDefault
        : objRcvd.orderByList,
    limit: objRcvd.limit === undefined ? 30 : objRcvd.limit,
    page: objRcvd.page === undefined ? 1 : objRcvd.page
  };
};

const convertToUnderScore = function (stringPassed) {
  let strToReturn = stringPassed
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');
  strToReturn = strToReturn.toUpperCase();
  return strToReturn;
};

const getCommonConfigs = function (
  configName,
  configApiConstant,
  actionType,
  state,
  paramsConfig
) {
  const obj = {
    name: configName,
    apiConstant: configApiConstant,
    storeActionType: actionType,
    params: commonApiParamsObj(paramsConfig)
  };
  obj.dataStates = {};
  obj.dataStates[state] = [];
  obj.dataStates[state + 'Loader'] = false;
  obj.dataStates[state + 'LoaderMessage'] = 'Fetching Data';
  obj.dataStates[state + 'PaginationTotal'] = 0;
  obj.storeActionMethod = 'commonApiAction';
  obj.storeActionSuccsessMethod = convertToUnderScore(state) + '_SUCCESS';
  obj.storeActionErrorMethod = convertToUnderScore(state) + '_ERROR';
  return obj;
};

const measureListTableDataParams = {
  name: 'client_workbench_full_catalogue_latest_comp_data',
  groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
  measuresList: JSON.parse(JSON.stringify(allMeasureList)),
  orderByList: [
    {
      dimension: 'ordered_revenue',
      direction: 'DESC'
    },
    {
      dimension: 'asin',
      direction: 'DESC'
    }
  ]
};
const measureListTableData = getCommonConfigs(
  'measureListTableData',
  'EXECUTE_CUBE_API',
  'POST',
  'measureListTableData',
  measureListTableDataParams
);

const workbenchTableDownloadParams = {
  name: 'client_workbench_full_catalogue_latest_comp_data',
  groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
  measuresList: JSON.parse(JSON.stringify(allMeasureList)),
  orderByList: [
    {
      dimension: 'ordered_revenue',
      direction: 'DESC'
    },
    {
      dimension: 'asin',
      direction: 'DESC'
    }
  ]
};
const workbenchDownloadData = getCommonConfigs(
  'workbenchDownloadData',
  'EXECUTE_CUBE_API',
  'POST',
  'workbenchDownloadData',
  workbenchTableDownloadParams
);

const workbenchTableSearchParams = {
  name: 'client_workbench_full_catalogue_latest_comp_data',
  groupByDimensionsList: ['asin'],
  measuresList: ['asin_name', 'latest_product_page_url', 'latest_image_url'],
  orderByList: [],
  limit: 19
};
const workbenchTableSearchData = getCommonConfigs(
  'workbenchTableSearchData',
  'EXECUTE_CUBE_API',
  'POST',
  'workbenchTableSearchData',
  workbenchTableSearchParams
);

const taggingBrandsRequest = {
  cubeName: 'client_workbench_full_catalogue_latest_comp_data',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [
    'asin_name',
    'scrape_date',
    'latest_image_url',
    'latest_product_page_url'
  ],
  groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
  timeseriesDimension: 'FEED_DATE',
  dedupBeforeRollup: {
    enableDedupBeforeRollup: true,
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: []
  },
  where: {},
  orderByList: [
    {
      dimension: 'asin',
      direction: 'ASC'
    }
  ]
};

export default {
  // dimensionListTableData: dimensionListTableData,
  taggingBrandsRequest,
  measureListTableData: measureListTableData,
  workbenchDownloadData: workbenchDownloadData,
  workbenchTableSearchData: workbenchTableSearchData
};
