<template>
  <div>
    <BrandIconCell
      :data="data"
      :enable-show-more="false"
      :is-detailed-view="isDetailedView"
      :is-tippy="true"
      :custom-classes="['u-spacing-mr-l', 'u-spacing-p-s']"
      :hide-metric-data="hideMetricData"
      :hide-desc="hideDesc"
    />
  </div>
</template>

<script>
import BrandIconCell from '@/components/pages/insights/amazon/cva/atoms/brand-icon-cell.vue';

export default {
  name: 'MoreBrandTooltip',
  conponents: {
    BrandIconCell
  },
  props: {
    hideMetricData: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {}
    },
    isDetailedView: {
      type: Boolean,
      required: true
    },
    hideDesc: {
      type: Boolean,
      required: false
    }
  }
};
</script>
