<template>
  <div
    class="objective_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <Strategy-Header :step="step" />
    <div class="u-width-100">
      <rb-list-select
        v-model="selectedValues.objective"
        :options="metaDataConfig[step.keyToRead] || []"
      />
    </div>

    <section class="u-margin-top-80 u-display-flex u-margin-bottom-80">
      <rb-button
        id="confirmButton"
        :text="'Next'"
        :disabled="disabledButton"
        :type="'filled'"
        :click-fn="moveNext.bind(this)"
      />
    </section>
  </div>
</template>
<script>
import StrategyHeader from './header';
import rbListSelect from '@/components/widgets/rbListSelect';
// import { eventBus } from '@/utils/services/eventBus';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
// import Vue from 'vue';

export default {
  name: 'ObjectiveStep',
  components: {
    StrategyHeader,
    rbListSelect
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    disabledButton() {
      let isDisable = false;
      if (this.selectedValues.objective && this.selectedValues.objective.name) {
        isDisable = false;
      } else {
        isDisable = true;
      }
      this.$parent.$emit('stepsGlobalEvent', {
        type: 'ValidationBtn',
        values: { step: this.step.textMapping, valid: !isDisable }
      });
      return isDisable;
    }
  },
  methods: {
    moveNext() {
      this.formatDisplayText(this.step.textMapping);
      this.$emit('nextStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    }
  }
};
</script>
