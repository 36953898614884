<!--
Values expected in params for rendering

-->
<template>
  <div
    v-tippy="tippy"
    :title="(params.data || {}).toolTipText || ''"
    class="u-width-100"
    :class="{ 'u-bg-color-grey-xxx-light': isDisabled }"
  >
    <!-- :class="{'percentBox': (selectedOption.value === 'percent' && bidsType !== '0')}" -->
    <div
      class="u-display-flex u-flex-0 u-flex-align-items-center u-width-100 inputContainer"
    >
      <span
        v-if="formatType !== 'percent'"
        class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
      />
      <span>{{ currency }}</span>
      <input
        ref="new_bid"
        v-model="bidValue"
        :type="inputType"
        data-cy="bidInput"
        class="rb-input currency-input u-display-flex u-flex-align-items-center"
        min="0.02"
        step="0.01"
        :max="1000"
        autofocus
        :disabled="isDisabled"
        @blur="fireBlurEvent($event)"
        @input="fireOnEventInput($event)"
      />
      <span
        v-if="formatType === 'percent'"
        class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
      >
        %
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'inputTableCell',
  components: {},
  props: {
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'bottom',
          arrow: false,
          distance: 8,
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }
        };
      }
    }
  },
  data() {
    return {
      currency: Vue.prototype.$currency,
      inputType: 'text',
      formatType: '',
      bidValue: ''
    };
  },
  computed: {
    isDisabled() {
      return this.params.data?.disabled || false;
    }
  },
  created() {
    if (this.params.contextReturnEvent !== undefined) {
      this.params.contextReturnEvent(this);
    }
    this.inputType = this.params.colDef.cellRendererParams.type;
    this.formatType = !this.params.colDef.cellRendererParams.formatType
      ? ''
      : this.params.colDef.cellRendererParams.formatType;
    this.inputDefaultValue();
  },
  methods: {
    inputDefaultValue() {
      this.bidValue =
        this.params.data[
          this.params.colDef.cellRendererParams.defaultValueColumnName
        ];
    },
    fireOnEventInput(event) {
      if (this.params.onchangeEvent !== undefined) {
        this.params.onchangeEvent(this, event);
      }
    },
    fireBlurEvent(event) {
      if (this.params.blurEvent !== undefined) {
        this.params.blurEvent(this, event);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.tableCell-icon:hover {
  color: #4b5158;
}

.inputContainer {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 0;
}
.rb-input .currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 0;
  border-radius: 2px;
}
.percentBox .rb-input .currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border-radius: 2px;
  border-left: 0;
}
.appender .currency-symbol {
  border-right: 0;
}
</style>
