import { campaignsConfig } from '@/pages/dashboard_service_campaign_management/promoteiq/campaigns.js';
import { adSkusConfig } from '@/pages/dashboard_service_campaign_management/promoteiq/adskus.ts';
import { regionsConfig } from '@/pages/dashboard_service_campaign_management/promoteiq/regions.ts';
import { categoriesConfig } from '@/pages/dashboard_service_campaign_management/promoteiq/categories.ts';
import { keywordsConfig } from '@/pages/dashboard_service_campaign_management/citrusAds/keywords.js';

export default {
  Campaigns: campaignsConfig,
  'Ad SKUs': adSkusConfig,
  Regions: regionsConfig,
  Categories: categoriesConfig,
  Keywords: keywordsConfig
};
