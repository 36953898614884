import { formatter, capitalize } from '@/utils/helpers/formatter.js';
import amsDictionary from '@/components/ams/campaigns/dictionary.js';
import instacartDictionary from '@/components/pages/instacart/campaigns/dictionary.js';
import Vue from 'vue';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import { campaignStatusComponent } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/campaignStatus';
import { skuCellDisplay } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/skusCell';
import utils from '@/utils/helpers/';
import { skuData } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/constants';
import { navigate_to_sdp } from '@/router/dynamicRoutes';
import CustomToolTip from '@/components/widgets/customToolTip.vue';
import { servingStatusMapping } from '@/components/ams/campaigns/constants.js';
const RealTimeMetadataCell = () =>
  import('@/components/ams/campaigns/tableCells/RealTimeMetadataCell.vue');
const StreamMetadataCell = () =>
  import('@/components/ams/campaigns/tableCells/StreamMetadataCell.vue');
const RealTimeMetadataHeader = () =>
  import('@/components/ams/campaigns/tableCells/RealTimeMetadataHeader.vue');

const instacartskuCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        name: 'sku_title',
        asin: 'upc',
        imageUrl: 'latest_image_url',
        productPageUrl: 'latest_product_page_url',
        showImage: true
      },
      tippyOptions: {
        theme: 'page-snapshot',
        duration: [0, 0],
        placement: 'right',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: true
            }
          }
        }
      }
    };
  },
  methods: {
    navigateToSDP(asin) {
      this.$router.push({ name: 'SkuDetails', params: { id: asin } });
    }
  },

  template:
    '<div class="u-width-100"><rb-sku v-if="params.data[renderParams.imageUrl] !== undefined" :title="params.data[renderParams.name] ? params.data[renderParams.name]: \'-----\'" :id="params.data.asin"  :imageUrl="params.data[renderParams.imageUrl]" :showImage="renderParams.showImage"  @navigateToSDP="navigateToSDP" :enableSDP="false"><div slot="sku-asin" class="rb-asin"><a title="View Instacart detail page" v-tippy="tippyOptions" target="_blank" :href="params.data[renderParams.productPageUrl]" class="instacart-asin--link--hover u-font-size-7 instacart-asin--link">{{params.data[renderParams.asin]}}</a></div></rb-sku><div v-if="params.data.asin === undefined && params.data[renderParams.imageUrl] === undefined"><span>{{params.data[renderParams.name]}}</span></div></div>'
});
const adGroupDisplayCell = Vue.extend({
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            offset: {
              offset: '30px, 4px'
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    addPadding() {
      return this.params.data.sub_type === '-' ? '' : 'u-spacing-pl-m';
    }
  },
  methods: {},
  template:
    '<div v-tippy="tippy" class="u-width-100 u-text-overflow-ellipsis u-color-grey-base" :title="params.data.ad_group_name" :class="addPadding">{{params.data.ad_group_name}}</div>'
});

export function showSbv4DiscrepancyBanner() {
  return Vue.options.filters.config_check(
    `feature.insights.campaigns.sbv4.discrepancyBanner.enable`
  );
}

const amazonSkuCellDisplay = Vue.extend({
  components: {
    CustomToolTip
  },
  data() {
    return skuData().data;
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    },
    showAmazonSkuCellDisplayDiscrepancyBanner(params) {
      if (showSbv4DiscrepancyBanner()) {
        return params.data?.campaign_type === 'SB';
      }
      return false;
    }
  },
  template: ` 
    <div class="u-width-100">
      <rb-sku v-if="params.data[renderParams.asin] !== undefined && params.data[renderParams.imageUrl] !== undefined"
        :title="params.data[renderParams.name] ? params.data[renderParams.name]: '-----'"
        :id="params.data[renderParams.asin]"
        :asin="params.data[renderParams.asin]"
        :imageUrl="params.data[renderParams.imageUrl]"
        :showImage="renderParams.showImage" 
        @navigateToSDP="navigateToSDP"
        :enableSDP="renderParams.enableSDP">
          <div slot="sku-asin" class="u-display-flex u-flex-align-items-center">
            <rb-asin
                v-if="params.data[renderParams.asin]"
                :asin="params.data[renderParams.asin]"
                :productPageUrl="params.data[renderParams.productPageUrl]"
                :tippyText="renderParams.asinTippyText"
                data-cy="asin"
            />
            <span 
                v-if="showAmazonSkuCellDisplayDiscrepancyBanner(params)"
                class="u-color-grey-xx-light u-font-size-3 u-spacing-ml-xs">
                |
            </span>
            <custom-tool-tip
              v-if="showAmazonSkuCellDisplayDiscrepancyBanner(params)"
              class="u-spacing-ml-xs"
              :tippy="{
              html: '#tooltip',
              interactive: false,
              offset: '0,0'
            }"
            >
              <div slot="toolTipData">
                <div
                    class="u-display-flex u-flex-direction-column u-width-400px u-spacing-p-s"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    <rb-icon
                        :icon="'info-circle-fill'"
                        class="rb-icon--medium u-color-blue-base"
                    />
                    <div
                        class="u-font-size-5 u-font-weight-600 u-spacing-ml-xs"
                    >
                      Purchased SKU
                    </div>
                  </div>
                  <div
                      class="u-font-size-6 u-spacing-mt-m u-font-weight-400"
                  >
                    Non sales metrics for a Sponsored Brand Campaign are allocated among the
                    purchased SKUs in proportion to the sales generated by each individual SKU within that campaign.
                  </div>
                </div>
              </div>
              <div
                  slot="body"
                  slot-scope="{ tippyConfig }"
              >
                <div
                    v-tippy="tippyConfig"
                    class="u-font-size-6 u-spacing-mr-xs u-display-flex u-flex-align-items-center u-color-blue-base"
                >
                  <rb-icon
                      :icon="'info-circle-fill'"
                      class="rb-icon--medium"
                  />
                  <div
                      class="u-font-weight-600 u-spacing-ml-xs"
                  >
                    Purchased SKU
                  </div>
                </div>
              </div>
            </custom-tool-tip>
          </div>
      </rb-sku> 
      <div v-if="params.data[renderParams.asin] === undefined && params.data[renderParams.imageUrl] === undefined">
        <span>{{params.data[renderParams.name]}}</span>
      </div>
    </div>`
});

function createTags({ result, oReturn, pvp, dictionary, map }) {
  for (let i in result) {
    let translatedI = map?.[i] ?? i;
    oReturn[translatedI] = {
      tag1: result[i],
      tag2: pvp?.['PVP_' + i] ?? undefined
    };
    if (dictionary?.map?.[i]?.unit === 'PERCENTAGE') {
      // If unit is PERCENTAGE keep tag2 as PVP_DIFF_
      const tag2Value = pvp?.['PVP_DIFF_' + i] ?? undefined;
      oReturn[translatedI] = oReturn[translatedI] || {};
      oReturn[translatedI].tag2 = tag2Value;
    }
  }
  return oReturn;
}
function getTagData(data, mapping) {
  if (!data) {
    data = [];
  }
  let oReturn = {};
  let result = data?.[0]?.RESULT;
  let pvp = data?.[0]?.PVP;
  if (!result) {
    return oReturn;
  }
  oReturn = createTags({
    oReturn,
    result,
    pvp,
    dictionary: this.dictionary,
    map: this.map
  });
  return oReturn;
}

const redirectComponent = () =>
  Vue.extend({
    template: `<redirect :params="params" />`
  });

/**
 * generate table column from the dictonary.js - Hardcoded column defn for Entity page. Same Column definition will be used for CSV download(field and title)
 * To Disable Sorting for specific column - Disable enableSorting key in headerComponentParams
 */

function getColumnDefinition(vueRef, entity) {
  let clonedColumnDefs = [];
  for (const i in this.dictionary.map) {
    if (
      (Array.isArray(this.dictionary.map[i].page) &&
        (this.dictionary.map[i].page.indexOf('common') !== -1 ||
          this.dictionary.map[i].page.indexOf(entity) !== -1)) ||
      this.dictionary.map[i].page === 'common' ||
      this.dictionary.map[i].page === entity
    ) {
      createColumnDefObj({
        i,
        clonedColumnDefs,
        vueRef,
        entity,
        dictionary: this.dictionary,
        retailer: this.retailer
      });
    }
  }
  /**
   * Sort based on key order
   */
  clonedColumnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });

  this.columnDefs = clonedColumnDefs;

  const isDemoClient =
    Vue.options.filters.config_check('feature.demo.product_demo.enable') ||
    false;
  if (!isDemoClient) {
    this.columnDefs = this.columnDefs.filter(
      (item) => item.field !== 'alertCount'
    );
  }
  return this.columnDefs;
}

const amazonCampaignSatusMapping = {
  liveValue: 'enabled',
  pausedValue: 'paused',
  disabledValue: 'disabled'
};
const instacartCampaignSatusMapping = {
  campaign: {
    statusField: 'enabled',
    liveValue: true,
    pausedValue: false
  },
  keyword: {
    liveValue: 'active',
    pausedValue: 'inactive'
  },
  sku: {
    liveValue: 'active',
    pausedValue: 'paused'
  },
  adGroups: {
    liveValue: 'active',
    pausedValue: 'paused'
  }
};

function selectCampaignStatusComponent({ retailer, obj, entity, statusItem }) {
  let campaignStatusMapping;

  if (retailer === 'amazon') {
    campaignStatusMapping = amazonCampaignSatusMapping;
  } else if (entity === 'campaign') {
    campaignStatusMapping = instacartCampaignSatusMapping.campaign;
    obj.cellRendererParams.tooltipFn = (data) => {
      const status = data.campaign_status.replace(/_/g, ' ');
      return capitalize(status);
    };
  } else {
    campaignStatusMapping = instacartCampaignSatusMapping[entity];
  }
  const metadata = {
    getter: statusItem?.getter?.[retailer]?.[entity]
  };
  obj.cellRendererFramework = campaignStatusComponent(
    campaignStatusMapping,
    metadata
  );
}
function selectSkuCellDisplay(retailer) {
  switch (retailer) {
    case 'amazon':
      return amazonSkuCellDisplay;
    case 'instacart':
      return instacartskuCellDisplay;
    default:
      return skuCellDisplay();
  }
}

function customComponentBasedOnHeader(
  obj,
  dictionary,
  i,
  retailer,
  entity,
  vueRef
) {
  if (
    obj.headerName === 'Campaign Name' &&
    dictionary.map[i].type === 'redirect'
  ) {
    delete obj.cellRenderer;
    obj.cellRendererFramework = redirectComponent();
    obj.minWidth = 250;
  }
  if (obj.headerName === 'SKU') {
    delete obj.cellRenderer;
    obj.cellRendererFramework = selectSkuCellDisplay(retailer);
    obj.headerComponentParams.enableSorting = false;
    obj.minWidth = retailer === 'amazon' ? 300 : null;
  }
  if (obj.headerName === 'Ad Group Name') {
    delete obj.cellRenderer;
    obj.cellRendererFramework = adGroupDisplayCell;
  }
  if (['Active', 'Active Status'].includes(obj.headerName)) {
    delete obj.cellRenderer;
    selectCampaignStatusComponent({
      retailer,
      obj,
      entity,
      statusItem: dictionary.map[i]
    });
    obj.cellRendererParams.switchFunctionToCall = vueRef.updateStatus;
    obj.cellRendererParams.showToolTip = dictionary.map[i].showToolTip;
    obj.headerComponentParams.enableSorting = false;
    obj.minWidth = 100;
    obj.maxWidth = 100;
  }
}

function customComponent({ obj, entity, i, vueRef, dictionary, retailer }) {
  const isInternalUser = utils.internalUserCheck(window.user);
  if (entity === 'sku') {
    obj.hide = dictionary.map[i].isHiddenInTable || false;
  }
  customComponentBasedOnHeader(obj, dictionary, i, retailer, entity, vueRef);
  if (dictionary.map[i].type === 'icon') {
    delete obj.cellRenderer;
    obj.cellRendererFramework = 'iconTableCell';
    obj.headerComponentFramework = 'iconTableHeader';
    obj.cellRendererParams.iconClickEvent = vueRef.openSidePanel;
    obj.cellRendererParams.displayIcon = 'timeline';
    obj.headerComponentParams.displayName = 'Activity log';
    obj.cellRendererParams.toolTipText = dictionary.map[i].toolTipText;
    obj.notDownloadable = true;
    obj.headerComponentParams.displayIcon = 'timeline';
    const activityLogWidth = retailer === 'amazon' ? 40 : 60;
    obj.minWidth = entity === 'search' ? 100 : activityLogWidth;
    obj.maxWidth = obj.minWidth;
  }

  if (dictionary.map[i].type === 'alerts') {
    delete obj.cellRenderer;
    obj.cellRendererParams.iconClickEvent = vueRef.openSidePanel;
    obj.cellRendererParams.displayIcon = 'alerts';
    obj.headerComponentParams.displayName = 'Recommended Action';
    obj.notDownloadable = true;
    obj.headerComponentParams.displayIcon = 'alerts';
    obj.minWidth = 120;
  }
  if (obj.headerName === 'Organic sales') {
    obj.headerComponentParams.enableSorting = false;
  }
  const streamToggleState =
    vueRef.$store.getters[
      `getStream${entity.charAt(0).toUpperCase() + entity.slice(1)}sToggle`
    ];
  streamToggleState && setStreamFields(dictionary, obj, entity, i, retailer);
  if (isInternalUser) {
    setRealtimeFields(dictionary, obj, entity, i, retailer);
  }
  instacartFields({ retailer, obj, entity, i });
}

function setStreamFields(dictionary, obj, entity, i, retailer) {
  if (
    retailer !== 'amazon' ||
    Vue.options.filters.config_check('feature.pages.disableStreamChart')
  ) {
    return;
  }
  if (
    dictionary?.map?.[i]?.streamCell &&
    dictionary?.map?.[i]?.streamPage?.includes(entity)
  ) {
    delete obj.cellRenderer;
    obj.cellRendererFramework = 'DynamicCellComponent';
    obj.cellRendererParams = {
      component: StreamMetadataCell,
      paramsToProps: (params) => {
        return {
          field: params.column.colDef.field,
          type: dictionary.map[i]?.type,
          unit: dictionary.map[i]?.unit,
          row: params.data,
          streamCell: dictionary?.map?.[i]?.streamCell,
          streamGetter:
            dictionary?.map?.[i]?.streamGetter?.[retailer]?.[entity],
          modeGetter: dictionary?.map?.[i]?.modeGetter?.[retailer]?.[entity],
          enablePVPOnly: dictionary?.map?.[i]?.enablePVPOnly?.includes?.(entity)
        };
      }
    };
  }
}

function setRealtimeFields(dictionary, obj, entity, i, retailer) {
  if (retailer !== 'amazon') {
    return;
  }
  if (dictionary.map[i]?.realTimeHeader?.includes(entity)) {
    obj.headerComponentFramework = 'DynamicHeaderComponent';
    obj.headerComponentParams = {
      enableSorting: true,
      component: RealTimeMetadataHeader,
      columnDefsToProps: (params) => ({
        getter: dictionary.map[i].getter[retailer][entity],
        title: dictionary.map[i].title,
        type: dictionary.map[i]?.type,
        unit: dictionary.map[i]?.unit,
        toolTipText: dictionary.map[i].toolTipText,
        params: params,
        streamCell:
          dictionary?.map?.[i]?.streamCell &&
          dictionary?.map?.[i]?.streamPage?.includes(entity),
        streamGetter: dictionary?.map?.[i]?.streamGetter?.[retailer]?.[entity]
      })
    };
  }
  if (dictionary.map[i]?.realTimeMetadataCell) {
    delete obj.cellRenderer;
    obj.cellRendererFramework = 'DynamicCellComponent';
    obj.cellRendererParams = {
      component: RealTimeMetadataCell,
      paramsToProps: (params) => {
        return {
          field: params.column.colDef.field,
          type: dictionary.map[i]?.type,
          unit: dictionary.map[i]?.unit,
          getter: dictionary.map[i].getter[retailer][entity],
          row: params.data,
          realtime: dictionary.map[i]?.realTimeMetadataCell,
          streamCell: dictionary?.map?.[i]?.streamCell,
          streamGetter: dictionary?.map?.[i]?.streamGetter?.[retailer]?.[entity]
        };
      }
    };
  }
}

function instacartFields({ retailer, obj, entity, i }) {
  if (retailer === 'instacart' && i === 'table_status') {
    obj.field = ['adGroups', 'sku', 'keyword'].includes(entity)
      ? 'status'
      : 'campaign_status';
  }
}
function createColumnDefObj({
  i,
  clonedColumnDefs,
  vueRef,
  entity,
  dictionary,
  retailer
}) {
  const obj = {};
  obj.field = i;
  obj.headerComponentFramework = 'genericTableHeader';
  let keyType = 'string';
  if (dictionary.map[i].type === 'NUMERIC') {
    keyType = dictionary.map[i].unit ? dictionary.map[i].unit : 'NUMERIC';
  }
  if (dictionary.map[i].type === 'date') {
    keyType = dictionary.map[i].type;
  }
  obj.cellRendererParams = {
    keyType: keyType,
    formatterFn: formatter,
    ...(['sku', 'adGroups'].includes(entity) ? { clamp: 3 } : {})
  };
  obj.cellRenderer = cellRenderer;
  obj.headerName = dictionary.map[i].title;
  obj.tooltipHeader = dictionary.map[i].tooltipHeader;
  obj.title = dictionary.map[i].title;
  if (i === 'keyword_type') {
    obj.headerName = entity === 'search' ? 'Search Term Type' : 'Keyword Type';
    obj.title = obj.headerName;
  }
  if (i === 'promotion_messages') {
    obj.headerName = 'Promo Values';
    obj.title = 'Promo Values';
  }
  obj.headerComponentParams = {
    enableSorting: true
  };
  customComponent({ obj, entity, i, vueRef, dictionary, retailer });
  obj.keyOrder =
    dictionary.map[i][`${entity === 'adGroups' ? 'sku' : entity}_keyOrder`];
  dictionary.map[i].pinned && (obj.pinned = dictionary.map[i].pinned);
  if (dictionary.map[i].toolTipText) {
    obj.headerComponentParams.toolTipText =
      dictionary.map[i][`${entity}_toolTipText`] ||
      dictionary.map[i].toolTipText;
  }
  obj.headerComponentParams.keyType = keyType;
  clonedColumnDefs.push(obj);
  addAmazonCustomCellStyling(i, dictionary, obj, retailer);
}

function addAmazonCustomCellStyling(i, dictionary, obj, retailer) {
  if (retailer !== 'amazon') {
    return;
  }
  obj.defaultTextColor = '';
  obj.cellClass = (params) => {
    let commonClass = [];
    if (
      params?.data?.[i] !== 0 &&
      !params?.data?.[i] &&
      params?.data?.hasOwnProperty(i)
    ) {
      commonClass = ['not-assigned-cell-background', 'u-color-grey-x-light'];
    } else {
      commonClass = ['ag-row-default-text-color'];
    }
    if (dictionary?.map?.[i]?.isServingStatus) {
      const value = params?.data?.[i];
      if (servingStatusMapping?.[value]) {
        commonClass = ['u-font-weight-600', servingStatusMapping[value]];
      }
    }
    return commonClass;
  };
}

function createBulkSearchObjectForWidget(action, widget, entityName, pageName) {
  const entityNameMap = {
    sku: 'Sku',
    campaign: 'Campaign',
    keyword: 'Keyword',
    search: 'SearchTerm',
    adGroups: 'AdGroup'
  };

  const searchDimensionsMap = {
    amazon: {
      sku: 'asin',
      campaign: 'campaign_name',
      keyword: 'keyword_text',
      search: 'search_term'
    },
    instacart: {
      adGroups: 'ad_group_name',
      sku: 'upc',
      keyword: 'keyword',
      campaign: 'campaign_name'
    }
  };
  const searchDimension = searchDimensionsMap[this.retailer][entityName];
  return {
    [`${this.retailer === 'amazon' ? 'ams' : 'instacart'}${pageName || ''}${
      entityNameMap[entityName]
    }sBulkSearchApplied`]: {
      action,
      transform: function (config, data) {
        if (widget === 'table') {
          config.body.APIConfig.page = 1;
        } else {
          config.meta.plotSelections = [];
        }
        if (data !== undefined) {
          config.meta.localFilters = [];
          if (data.length > 0) {
            const searchTerms = data
              .map((searchTerm) => `'${searchTerm.replaceAll("'", "\\'")}'`) // adding escape character for search terms containing single quote
              .join(',');
            config.meta.localFilters.push({
              dimensionName: searchDimension,
              operator: 'IN',
              dimensionValue: searchTerms
            });
          }
        }
        return config;
      }
    }
  };
}
const baseMeasuresList = [
  'spend',
  'impressions',
  'clicks',
  'units_1d',
  'units_7d',
  'units_14d',
  'orders_1d',
  'orders_7d',
  'orders_14d',
  'paid_sales_1d',
  'paid_sales_7d',
  'paid_sales_14d',
  'ctr',
  'conversions_1d',
  'conversions_7d',
  'conversions_14d',
  'acos_1d',
  'acos_7d',
  'acos_14d',
  'cpc',
  'halo_sales_1_day',
  'halo_sales_7_day',
  'halo_sales_14_day',
  'roi',
  'average_order_value'
];

function bulkSearchProps() {
  const entityNameMap = {
    campaign: {
      subTitle: 'campaign names',
      findEntityInputPlaceholder: 'Find a campaign name'
    },
    keyword: {
      subTitle: 'keywords',
      findEntityInputPlaceholder: 'Find a keyword'
    },
    search: {
      subTitle: 'search terms',
      findEntityInputPlaceholder: 'Find a search term'
    },
    sku: {
      subTitle: this.retailer === 'amazon' ? 'ASINs' : 'UPCs',
      findEntityInputPlaceholder:
        this.retailer === 'amazon' ? 'Find an ASIN' : 'Find a UPC'
    },
    adGroups: {
      subTitle: 'ad groups',
      findEntityInputPlaceholder: 'Find an ad group'
    }
  };
  return {
    title: 'Bulk Search',
    subTitle: entityNameMap[this.entity].subTitle,
    inputFieldPlaceholder: 'Search',
    findEntityInputPlaceholder:
      entityNameMap[this.entity].findEntityInputPlaceholder,
    maxPasteLimit: 1000
  };
}

function openBulkSearchHelperPopup(showCheckbox = false) {
  let showHelpModalCheckbox = showCheckbox;
  this.$refs.bulkSearchHelpModal?.openBulkSearchHelperPopup(
    showHelpModalCheckbox
  );
}

function createSelectedMetricsList({ data, clonedData, promoMessageIndex }) {
  this.metricsList = this.metricsList.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  this.metricsSelected = [];
  for (const element of this.metricsSelectedIndex) {
    this.metricsSelected.push(this.metricsShown[element]);
  }

  let colors = this.getColorPattern;
  let returnData = [];
  data = { ...data };

  this.metricColors = {};
  for (const element of this.metricsSelected) {
    for (let j = 0; j < this.metricsShown.length; j++) {
      if (this.metricsShown[j] === element) {
        Vue.set(this.metricColors, element, colors[j]);
      }
    }
  }
  return this.getMetricsListForChartingWb({
    returnData,
    data,
    clonedData,
    promoMessageIndex
  });
}
function getMetricsListForChartingWb({
  returnData,
  data,
  clonedData,
  promoMessageIndex
}) {
  for (let i = 0; i < this.metricsSelected.length; i++) {
    let metric = this.metricsSelected[i].toLowerCase();
    if (i % 2 === 0) {
      this.chartWkbenchAxes[this.metricsSelected[i]] = 'y';
      this.chartAxisFormat.y = this.chartAxisFormatMap[this.metricsSelected[i]];
    } else {
      this.chartWkbenchAxes[this.metricsSelected[i]] = 'y2';
      this.chartAxisFormat.y2 =
        this.chartAxisFormatMap[this.metricsSelected[i]];
    }
    this.chartWkbenchAxes[this.metricsSelected[i] + '_color'] =
      this.metricColors[this.metricsSelected[i]];

    // PROD-45517 - promo timeline not appearing correctly Fix
    if (this.metricsSelected.indexOf('Promotions') > -1) {
      let promoIndex = this.metricsSelected.indexOf('Promotions');
      this.chartWkbenchAxes[this.metricsSelected[promoIndex]] = promoIndex
        ? this.chartWkbenchAxes[this.metricsSelected[0]]
        : this.chartWkbenchAxes[this.metricsSelected[1]];
    }
    let datePushed = false;
    const result = handleDates({
      clonedData,
      promoMessageIndex,
      returnData,
      datePushed,
      metric
    });
    returnData = result[0];
    datePushed = result[1];

    'promotionDays' in this && (this.promotionDays = result[2]);
    'promoTimelineMessage' in this && (this.promoTimelineMessage = result[3]);
  }

  data.axes = this.chartWkbenchAxes;
  data.colors = this.metricColors;
  data.axis_format = this.chartAxisFormat;
  data.data = returnData;
  return data;
}
function handleDates({
  clonedData,
  promoMessageIndex,
  returnData,
  datePushed,
  metric
}) {
  let promoTimelineMessages = [];
  let promotionDays = 0;
  for (const element of clonedData) {
    if (element[0]) {
      if (element[0].toLowerCase() === 'promotion messages') {
        promoTimelineMessages = [].concat(element);
        promoTimelineMessages.shift();
      } else if (element[0].toLowerCase() === 'promotions') {
        promotionDays = computePromotionDays({
          element,
          promoMessageIndex,
          clonedData
        });
      }

      if (element[0] && element[0].toLowerCase() === metric) {
        returnData.push(element);
      } else if (
        element[0] &&
        (element[0].toLowerCase() === 'report_date' ||
          element[0].toLowerCase() === 'hour') &&
        !datePushed
      ) {
        returnData.push(element);
        datePushed = true;
      }
    }
  }
  return [returnData, datePushed, promotionDays, promoTimelineMessages];
}
function computePromotionDays({ element, clonedData, promoMessageIndex }) {
  let promotionDays = 0;
  for (let k = 1; k < element.length; k++) {
    if (clonedData[promoMessageIndex][k]) {
      element[k] = 0.5;
    } else {
      element[k] = null;
    }
    if (element[k] === 0.5) {
      promotionDays++;
    }
  }
  return promotionDays;
}

export default (retailer, entity) => {
  const dictionary =
    retailer === 'amazon' ? amsDictionary : instacartDictionary;
  let map = {};
  let units = {};
  let axes = {};
  for (let i in dictionary.map) {
    let configuredUnit = dictionary.map[i].unit;
    let unit = null;
    map[i] = dictionary.map[i].title;
    if (configuredUnit === 'CURRENCY') {
      unit = {
        pre: 'currency'
      };
    } else if (configuredUnit === 'PERCENTAGE') {
      unit = {
        suff: '%'
      };
    } else {
      unit = {
        pre: '',
        suff: ''
      };
    }
    units[map[i]] = unit;
    if (configuredUnit === 'CURRENCY') {
      axes[map[i]] = 'y';
    } else {
      axes[map[i]] = 'y2';
    }
  }
  let columnDefs = [];
  const obj = {
    dictionary,
    retailer,
    map,
    units,
    axes,
    columnDefs
  };
  const retailerEntityMap = {
    retailer,
    entity
  };
  return {
    map,
    axes,
    units,
    columnDefs,
    baseMeasuresList,
    getColumnDefinition: getColumnDefinition.bind(obj),
    createBulkSearchObjectForWidget: createBulkSearchObjectForWidget.bind(obj),
    getTagData: getTagData.bind(obj),
    bulkSearchProps: bulkSearchProps.bind(retailerEntityMap),
    openBulkSearchHelperPopup,
    createSelectedMetricsList,
    getMetricsListForChartingWb
  };
};

export const enableBulkOnlyActions = (
  allowedActions,
  areAllSelected,
  pageName
) => {
  const bulkActions = ['adjust-bids', 'edit-status'];
  allowedActions = areAllSelected
    ? allowedActions.map((action) => {
        if (!bulkActions.includes(action.id)) {
          return {
            disabled: true,
            tippy: `This action is not supported for bulk selection`,
            ...action
          };
        } else {
          return action;
        }
      })
    : allowedActions;
  return allowedActions;
};
