<template>
  <div
    class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-width-100 goals"
  >
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <div class="u-display-inline-flex u-flex-align-items-center">
        <div
          class="u-display-inline-flex u-flex-align-items-center u-spacing-mr-l u-spacing-pr-l u-spacing-pt-s"
          :class="{
            'disabled-actions': planRows.loading || disableToggles,
            'toggle-slider-off': !showPlansAtBULevel
          }"
        >
          <ToggleSlider
            :original-state="showPlansAtBULevel"
            @check="toggleEnterOPSGoal"
          />
          <span class="u-font-size-5 u-spacing-pl-s"
            >Enter {{ goalOptions[goal].secondaryLabel }} Goal at
            {{ businessUnit.dimensionLabel }} Level</span
          >
        </div>
        <div
          class="u-display-flex u-flex-align-items-center"
          :class="{ 'disabled-actions': planRows.loading }"
        >
          <span
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-pt-s u-spacing-mr-m"
          >
            Achieve OPS goal by:</span
          >
          <RadioGroup
            :radio-options="Object.values(achieveOPSGoalByOptions)"
            :value="achieveOPSGoalsBy"
            :container-class="'global-radio-wrapper u-spacing-mr-l u-spacing-mt-m'"
            @onRadioChanged="handleRadioSelection"
          />
        </div>
      </div>
      <div
        v-for="({ label, description }, key) in listOfCompetitivePlans"
        :key="key"
        class="u-spacing-mb-xl u-spacing-pb-s"
      >
        <div
          v-if="description"
          class="u-display-inline-flex u-flex-align-items-center"
        >
          <rb-checkbox
            v-if="key === competitivePlans.moderately_aggressive_plan.id"
            v-model="enableModerateAggressivePlan"
            :disabled="planRows.loading"
          >
            <div class="u-font-size-5 u-color-grey-base u-spacing-mr-s">
              {{ description }}
            </div>
          </rb-checkbox>
          <rb-checkbox
            v-if="key === competitivePlans.highly_aggressive_plan.id"
            v-model="enableHighlyAggressivePlan"
            :disabled="planRows.loading"
          >
            <div class="u-font-size-5 u-color-grey-base u-spacing-mr-s">
              {{ description }}
            </div>
          </rb-checkbox>
          <div
            v-if="
              key === competitivePlans.moderately_aggressive_plan.id
                ? enableModerateAggressivePlan
                : enableHighlyAggressivePlan
            "
            class="u-display-inline-flex u-flex-align-items-center u-width-100 u-cursor-pointer toggle-plan-visibility"
            @click="onPlanEditClickHandlerMap[key]"
          >
            <rb-icon
              class="rb-icon--medium u-color-grey-lighter u-spacing-ml-m"
              :icon="showPlanMap[key] ? 'visibility-off' : 'pencil'"
            />
            <span class="u-font-size-5 u-spacing-ml-s">
              {{ showPlanMap[key] ? 'Hide' : 'Edit' }} Plan
            </span>
          </div>
        </div>
        <div
          v-if="!description || showPlanMap[key]"
          class="u-spacing-pt-m plans-table"
        >
          <Plan
            :loading="planRows.loading"
            :column-definition="planColDef(key)"
            :data-rows="planRowsMap[key]"
            :header-text="label"
            :row-height="62"
            :header-height="110"
            :component-parent-context="componentContext"
            :dimensions="{
              width: '100%',
              height: showPlansAtBULevel ? '433px' : '246px'
            }"
            :show-footer="true"
          >
            <span
              slot="footer"
              class="u-color-grey-light u-font-size-7"
            >
              <b>Disclaimer : </b>{{ historicalDataDisclaimer }}
            </span>
          </Plan>
        </div>
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <CollapsibleTitle
          class="u-spacing-pr-s"
          title="Additional Inputs"
          :is-expanded="showAdditionalInputs"
          title-font-class="u-font-size-4 u-font-weight-600"
          :icon-class="!showAdditionalInputs ? 'rotate-270' : ''"
          @click="toggleAdditionalInputVisible"
        />
        <div
          class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
        >
          Optional
        </div>
      </div>
      <div
        v-if="showAdditionalInputs"
        class="u-spacing-mt-m u-spacing-mb-l"
      >
        <p
          class="u-font-size-5 u-color-grey-lighter u-spacing-pb-xl u-line-height-1-3"
        >
          {{ additionalInputDescription }}
        </p>
        <div
          class="u-display-inline-flex u-flex-align-items-center u-spacing-pb-m toggle-slider"
          :class="{
            'disabled-actions': planRows.loading || disableToggles,
            'toggle-slider-off': !showAdditionalInputsAtBULevel
          }"
        >
          <ToggleSlider
            :original-state="showAdditionalInputsAtBULevel"
            @check="handleAdditionalInputCheck"
          />
          <span class="u-font-size-5 u-spacing-pl-s"
            >Enter at {{ businessUnit.dimensionLabel }} Level</span
          >
        </div>
        <div class="additional-inputs-table">
          <Plan
            :column-definition="additionalInputsColDef"
            :data-rows="additionalInputDataRows"
            header-text="Additional Inputs"
            :component-parent-context="this"
            :row-height="62"
            :header-height="110"
            :dimensions="{
              width: '100%',
              height: showAdditionalInputsAtBULevel ? '406px' : '343px'
            }"
            :loading="planRows.loading || expectedGrowth.loading"
          />
          <div
            v-if="getShowErrorCondition()"
            class="u-display-flex u-flex-align-items-center u-font-size-6 u-color-red-base u-spacing-mt-s"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small"
              icon="error-fill"
            />
            <div class="u-flex-0 u-spacing-ml-xxs">
              Sum of budget distribution should be equal to 100
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import ToggleSlider from '@/components/pages/insights/amazon/share-of-voice/atoms/toggle-slider';
import RadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import Plan from '@/components/ams/media-planner/generate-plan/components/info-table.vue';
import AdjustPercentage from '@/components/ams/media-planner/generate-plan/steps/goals/adjust-percentage.vue';
import SplitCell from '@/components/ams/media-planner/generate-plan/steps/goals/split-cell.vue';
import SplitCellHeader from '@/components/ams/media-planner/generate-plan/steps/goals/header-with-split-cell.vue';
import { cloneDeep, set, get } from 'lodash';
import {
  achieveOPSGoalByOptions,
  getAdditionalInputsColDef,
  defaultCompetitivePlans as competitivePlans,
  getCompetitivePlans,
  filteredHistoricalDataAPIConfig,
  getPlanColDef,
  organizeOptions,
  convertToAbsoluteValue,
  checkIfNegative,
  convertToPercentageValue,
  convertToIntegerPercentageValue,
  historicalDataKeys,
  goalOptions,
  getPreviousPeriodDates,
  budgetDistributionMap,
  FETCH_FILTERED_HISTORICAL_DATA_ACTION,
  FILTERED_HISTORICAL_DATA_GETTER,
  OVERALL_HISTORICAL_DATA_GETTER,
  ADDITIONAL_INPUT_DESCRIPTION,
  FETCH_ADDITIONAL_INPUTS,
  EXPECTED_GROWTH_GETTER,
  EXPECTED_GROWTH_SETTER,
  checkUndefined,
  getConditionToFetchExpectedGrowth,
  getUpdatedPlansWithAdditionalInputs
} from '@/components/ams/media-planner/config.js';
import { HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED_EXTENDED } from '../../../constants';
export default {
  components: {
    CollapsibleTitle,
    RadioGroup,
    ToggleSlider,
    Plan
  },
  props: {
    storeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateKeyInStore: {
      type: Function,
      default: () => {
        return () => null;
      }
    }
  },
  data() {
    return {
      achieveOPSGoalByOptions,
      achieveOPSGoalsBy: achieveOPSGoalByOptions.percentage.type,
      additionalInputDataRows: [],
      additionalInputsColDef: [],
      additionalInputsModified: false,
      basePlanRows: [],
      componentContext: this,
      enableHighlyAggressivePlan: true,
      enableModerateAggressivePlan: true,
      highlyAggressivePlanRows: [],
      moderateAggressivePlanRows: [],
      aggressivePlansModified: false,
      competitivePlans,
      overallOPS: {
        [competitivePlans.base_plan.id]:
          competitivePlans.base_plan.defaultOPSGoal,
        [competitivePlans.moderately_aggressive_plan.id]:
          competitivePlans.moderately_aggressive_plan.defaultOPSGoal,
        [competitivePlans.highly_aggressive_plan.id]:
          competitivePlans.highly_aggressive_plan.defaultOPSGoal
      },
      overallBasePlanRow: [],
      overallModerateAggressivePlanRow: [],
      overallHighlyAggressivePlanRows: [],
      previousPeriodDates: {
        start: null,
        end: null
      },
      showAdditionalInputsAtBULevel: false,
      showAdditionalInputs: true,
      showPlansAtBULevel: false,
      showHighlyAggressivePlan: false,
      showModerateAggressivePlan: false,
      additionalInputsTableError: {},
      goalOptions,
      disableToggles: false,
      dataKey: '',
      additionalInputDescription: ADDITIONAL_INPUT_DESCRIPTION,
      hasFetchedCount: 2,
      historicalDataDisclaimer:
        HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED_EXTENDED
    };
  },
  computed: {
    goal() {
      return this.storeData.goal || goalOptions.OPS.value;
    },
    listOfCompetitivePlans() {
      const { plans, goal } = this.storeData;
      return getCompetitivePlans(
        goal,
        plans?.moderately_aggressive_plan?.overall.opsGoal,
        plans?.highly_aggressive_plan?.overall?.opsGoal
      );
    },
    businessUnit() {
      const { businessUnit, organizeType, filteredBusinessUnit } =
        this.storeData;
      return organizeType === organizeOptions.CUSTOMIZE.value
        ? filteredBusinessUnit
        : businessUnit;
    },
    planRows() {
      const filteredHistoricalData =
        this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER];
      const rows =
        filteredHistoricalData?.data[this.businessUnit.dimensionName] || [];
      const loading = filteredHistoricalData?.loading;
      if (rows.length === 1 && !this.disableToggles) {
        if (!this.showPlansAtBULevel) this.toggleEnterOPSGoal(true);
        if (!this.showAdditionalInputsAtBULevel)
          this.handleAdditionalInputCheck(true, true);
        this.disableToggles = true;
      }
      return {
        rows,
        loading
      };
    },
    expectedGrowth() {
      const expectedGrowth = this.$store.getters[EXPECTED_GROWTH_GETTER];
      return expectedGrowth;
    },
    overallHistoricalData() {
      return this.$store.getters[OVERALL_HISTORICAL_DATA_GETTER]?.data || {};
    },
    onPlanEditClickHandlerMap() {
      return {
        [competitivePlans.moderately_aggressive_plan.id]: () =>
          (this.showModerateAggressivePlan = !this.showModerateAggressivePlan),
        [competitivePlans.highly_aggressive_plan.id]: () =>
          (this.showHighlyAggressivePlan = !this.showHighlyAggressivePlan)
      };
    },
    showPlanMap() {
      return {
        [competitivePlans.moderately_aggressive_plan.id]:
          this.showModerateAggressivePlan,
        [competitivePlans.highly_aggressive_plan.id]:
          this.showHighlyAggressivePlan
      };
    },
    planRowsMap() {
      return {
        [competitivePlans.base_plan.id]: this.basePlanRows,
        [competitivePlans.moderately_aggressive_plan.id]:
          this.moderateAggressivePlanRows,
        [competitivePlans.highly_aggressive_plan.id]:
          this.highlyAggressivePlanRows
      };
    },
    updateRowsMap() {
      return {
        [competitivePlans.base_plan.id]: (data) => (this.basePlanRows = data),
        [competitivePlans.moderately_aggressive_plan.id]: (data) =>
          (this.moderateAggressivePlanRows = data),
        [competitivePlans.highly_aggressive_plan.id]: (data) =>
          (this.highlyAggressivePlanRows = data)
      };
    },
    planColDef() {
      return (tableId) => {
        const updatedPlanColDef = cloneDeep(getPlanColDef(tableId));
        updatedPlanColDef[0].name = this.businessUnit.dimensionName;
        updatedPlanColDef[0].uiField.uiLabel = this.businessUnit.dimensionLabel;
        updatedPlanColDef[0].uiField.metadata.tableColumnName =
          this.businessUnit.dimensionName;
        updatedPlanColDef[2].uiField.uiLabel = `Input ${
          this.goalOptions?.[this.goal]?.secondaryLabel
        } Goal`;
        return updatedPlanColDef;
      };
    },
    rows() {
      return this.planRows.rows.reduce((list, item) => {
        list.push({
          [this.businessUnit.dimensionName]:
            item[this.businessUnit.dimensionName],
          plan: ' '
        });
        return list;
      }, []);
    }
  },
  watch: {
    enableModerateAggressivePlan(newValue) {
      const updatedPlans = { ...this.storeData.plans };
      updatedPlans[competitivePlans.moderately_aggressive_plan.id].enabled =
        newValue;
      this.setAdditionalInputDataRows();
      this.updateKeyInStore('plans', updatedPlans);
    },
    enableHighlyAggressivePlan(newValue) {
      const updatedPlans = { ...this.storeData.plans };
      updatedPlans[competitivePlans.highly_aggressive_plan.id].enabled =
        newValue;
      this.setAdditionalInputDataRows();
      this.updateKeyInStore('plans', updatedPlans);
    }
  },
  created() {
    Vue.component('AdjustPercentage', AdjustPercentage);
    Vue.component('SplitCell', SplitCell);
    Vue.component('SplitCellHeader', SplitCellHeader);
    const {
      showAdditionalInputsAtBULevel,
      showPlansAtBULevel,
      additionalInputsTableError,
      months,
      goal
    } = this.storeData;
    this.previousPeriodDates = getPreviousPeriodDates(months);
    if (this.planRows.rows.length === 1) {
      this.showAdditionalInputsAtBULevel = true;
      this.showPlansAtBULevel = true;
      this.disableToggles = true;
    } else {
      this.disableToggles = false;
      this.showAdditionalInputsAtBULevel = showAdditionalInputsAtBULevel;
      this.showPlansAtBULevel = showPlansAtBULevel;
    }

    this.additionalInputsTableError = additionalInputsTableError || {};
    this.setOverallOPS();
    this.fetchDataRows();
    this.additionalInputsColDef = getAdditionalInputsColDef(this.businessUnit);
    this.aggressivePlansModified =
      this.storeData.plans[competitivePlans.highly_aggressive_plan.id]
        ?.isModified?.ops ||
      this.storeData.plans[competitivePlans.moderately_aggressive_plan.id]
        ?.isModified?.ops;
    this.additionalInputsModified =
      this.storeData.plans[competitivePlans.highly_aggressive_plan.id]
        ?.isModified?.inputs ||
      this.storeData.plans[competitivePlans.moderately_aggressive_plan.id]
        ?.isModified?.inputs;
    this.enableModerateAggressivePlan =
      this.storeData?.plans?.[
        competitivePlans.moderately_aggressive_plan.id
      ]?.enabled;
    this.enableHighlyAggressivePlan =
      this.storeData?.plans?.[
        competitivePlans.highly_aggressive_plan.id
      ]?.enabled;
    this.dataKey = goalOptions?.[goal]?.dataKey || 'total_sales';
    if (this.expectedGrowth.reset) {
      this.hasFetchedCount = 0;
    }
  },
  methods: {
    getShowErrorCondition() {
      const overallErrors = Object.values(
        this.additionalInputsTableError.overall || {}
      ).filter((v) => !!v).length;
      return overallErrors;
    },
    setOverallOPS(tableId, value) {
      if (tableId && !isNaN(value)) {
        const updatedOverallOPS = { ...this.overallOPS };
        const storeData = { ...this.storeData };
        updatedOverallOPS[tableId] = value;
        this.overallOPS = updatedOverallOPS;
        storeData.plans[tableId].overall.opsGoal = value;
      } else {
        const { plans } = this.storeData;
        Object.keys(this.overallOPS).forEach((tableId) => {
          this.overallOPS[tableId] = plans[tableId].overall.opsGoal;
        });
      }
    },
    getPayload() {
      const { filters } = this.storeData;
      const APIConfig = { ...filteredHistoricalDataAPIConfig };

      const dimensionNameValueList = [];
      Object.keys(filters).forEach((key) => {
        filters[key].forEach((j) => {
          dimensionNameValueList.push({
            dimensionName: key,
            dimensionValue: j
          });
        });
      });
      APIConfig.where.dimensionNameValueList = dimensionNameValueList;

      APIConfig.entityType = this.businessUnit.dimensionName;

      APIConfig.metricsList = [
        ...APIConfig.metricsList,
        this.businessUnit.dimensionName
      ];
      APIConfig.where.date.from = this.previousPeriodDates.start;
      APIConfig.where.date.to = this.previousPeriodDates.end;
      return {
        APIConfig
      };
    },
    updateBURowsInStore(tableId, index, item, overallPercentage, updatedPlans) {
      set(updatedPlans[tableId], `businessUnitLevel[${index}]`, {
        name: item[this.businessUnit.dimensionName],
        opsGoal: overallPercentage,
        historicalData: item,
        additionalInputs: !this.showAdditionalInputsAtBULevel
          ? { ...get(updatedPlans[tableId], 'overall.additionalInputs', {}) }
          : {
              ...get(
                updatedPlans[tableId],
                `businessUnitLevel[${index}].additionalInputs`,
                {}
              )
            }
      });
    },
    getBURows(tableId, forceUpdate, overallPercentage, absOverallPercentage) {
      const updatedPlans = { ...this.storeData.plans };
      const updatedRows = this.planRows.rows.reduce((list, item, index) => {
        if (forceUpdate) {
          this.updateBURowsInStore(
            tableId,
            index,
            item,
            overallPercentage,
            updatedPlans
          );
        }

        const historicalData = {};
        historicalDataKeys.forEach((key) => {
          if (item[key]) {
            historicalData[key] = item[key];
          }
        });
        const intValue = checkIfNegative(
          updatedPlans?.[tableId]?.businessUnitLevel?.[index]?.opsGoal,
          updatedPlans?.[tableId]?.businessUnitLevel?.[index]?.opsGoal ||
            overallPercentage
        );
        const absValue = checkIfNegative(
          updatedPlans?.[tableId]?.businessUnitLevel?.[index]?.opsGoal,
          Math.abs(
            updatedPlans?.[tableId]?.businessUnitLevel?.[index]?.opsGoal
          ) || absOverallPercentage
        );
        const forcedValue = forceUpdate ? overallPercentage : intValue;
        const forcedPercentage = forceUpdate ? absOverallPercentage : absValue;
        list.push({
          [this.businessUnit.dimensionName]:
            item[this.businessUnit.dimensionName],
          data: item,
          ops: {
            value:
              this.achieveOPSGoalsBy === achieveOPSGoalByOptions.percentage.type
                ? forcedPercentage
                : checkIfNegative(
                    forcedValue,
                    convertToAbsoluteValue(item[this.dataKey] || 0, forcedValue)
                  ),
            achieveOPSGoalsBy: this.achieveOPSGoalsBy
          }
        });

        return list;
      }, []);

      if (forceUpdate) this.updateKeyInStore('plans', updatedPlans);
      return updatedRows;
    },
    getRows(tableId, forceUpdate) {
      const overallPercentage = this.overallOPS[tableId];
      const absOverallPercentage =
        overallPercentage > 0
          ? Math.abs(overallPercentage)
          : -Math.abs(overallPercentage);
      const updatedPlans = { ...this.storeData }.plans;
      set(
        updatedPlans[tableId],
        'overall.historicalData',
        this.overallHistoricalData
      );
      this.updateKeyInStore('plans', updatedPlans);
      if (!this.showPlansAtBULevel) {
        return [
          {
            [this.businessUnit.dimensionName]: 'Overall',
            data: this.overallHistoricalData,
            ops: {
              value:
                this.achieveOPSGoalsBy ===
                achieveOPSGoalByOptions.percentage.type
                  ? absOverallPercentage
                  : checkIfNegative(
                      overallPercentage,
                      convertToAbsoluteValue(
                        this.overallHistoricalData[this.dataKey] || 0,
                        overallPercentage
                      )
                    ),
              achieveOPSGoalsBy: this.achieveOPSGoalsBy
            }
          }
        ];
      }
      return this.getBURows(
        tableId,
        forceUpdate,
        overallPercentage,
        absOverallPercentage
      );
    },
    setPlanRows(forceUpdate) {
      Object.keys(this.updateRowsMap).forEach((key) => {
        const updatedRows = [...this.getRows(key, forceUpdate)];
        this.updateRowsMap[key](updatedRows);
      });
    },
    updateAdditionalInputErrors(totalSum, level, rowId) {
      const updatedAdditionalInputsTableError = {
        ...this.additionalInputsTableError
      };
      if (totalSum === 100) {
        if (updatedAdditionalInputsTableError?.[level]?.[rowId])
          delete updatedAdditionalInputsTableError[level][rowId];
        if (
          Object.keys(updatedAdditionalInputsTableError.overall || {})
            .length === 0
        ) {
          this.$emit('disableNextButton', false);
        }
      } else {
        set(updatedAdditionalInputsTableError, `[${level}][${rowId}]`, true);
        this.$emit('disableNextButton', true);
      }
      this.additionalInputsTableError = updatedAdditionalInputsTableError;
    },
    checkForBudgetDistribution(colId, value, rowId, forceLevel) {
      let totalSum = 0;
      const checkSum = budgetDistributionMap[colId];
      if (checkSum) {
        Object.keys(budgetDistributionMap).forEach((col) => {
          totalSum +=
            col === colId ? value : this.additionalInputDataRows[rowId][col];
        });
        const level = this.showAdditionalInputsAtBULevel ? 'bU' : 'overall';
        this.updateAdditionalInputErrors(totalSum, forceLevel || level, rowId);
        this.updateKeyInStore(
          'additionalInputsTableError',
          this.additionalInputsTableError
        );
      }
    },
    updateRestOfAdditionalInputs(colId, value, rowId, updatedRowData) {
      if (!this.additionalInputsModified) {
        let numberOfRows = 1;
        if (
          this.enableModerateAggressivePlan &&
          this.enableHighlyAggressivePlan
        ) {
          numberOfRows = 3;
        } else if (
          this.enableModerateAggressivePlan ||
          this.enableHighlyAggressivePlan
        ) {
          numberOfRows = 2;
        }
        if (this.enableModerateAggressivePlan) {
          this.updateRow(
            {
              ...updatedRowData,
              rowId: this.additionalInputDataRows.length / numberOfRows + rowId,
              value,
              colId
            },
            null,
            'manually'
          );
        }
        if (this.enableHighlyAggressivePlan) {
          this.updateRow(
            {
              ...updatedRowData,
              rowId:
                (this.additionalInputDataRows.length / numberOfRows) *
                  (this.enableModerateAggressivePlan ? 2 : 1) +
                rowId,
              value,
              colId
            },
            null,
            'manually'
          );
        }
      }
    },
    updateRestOfBudgetDistributions(object, path, value, colId) {
      if (budgetDistributionMap[colId]) {
        Object.keys(budgetDistributionMap).forEach((col) => {
          if (col === colId) {
            set(object, `${path}[${colId}]`, value);
          } else if (!get(object, `${path}[${col}]`)) {
            set(object, `${path}[${col}]`, 0);
          }
        });
      } else {
        set(object, `${path}[${colId}]`, value);
      }
    },
    getAvgValue(updatedPlans, planId, colId) {
      let sumOfOPS = 0;
      const additionalInputs = updatedPlans[planId].businessUnitLevel.reduce(
        (list, { additionalInputs }) => {
          if (
            additionalInputs &&
            (additionalInputs[colId] || additionalInputs[colId] === 0)
          ) {
            list.push(additionalInputs[colId]);
            sumOfOPS = sumOfOPS + additionalInputs[colId];
          }

          return list;
        },
        []
      );
      return (sumOfOPS / additionalInputs.length).toFixed(2) * 1;
    },
    updateAtOverallLevel(updatedPlans, planId, colId) {
      const avg = this.getAvgValue(updatedPlans, planId, colId);
      this.updateRestOfBudgetDistributions(
        updatedPlans[planId],
        `overall.additionalInputs`,
        avg,
        colId
      );
      this.checkForBudgetDistribution(colId, avg, 0, 'overall');
    },
    updateAtBULevel(updatedPlans, planId, value, colId) {
      if (this.showPlansAtBULevel) {
        checkUndefined(updatedPlans?.[planId]?.businessUnitLevel).forEach(
          (__, index) => {
            this.updateRestOfBudgetDistributions(
              updatedPlans[planId],
              `businessUnitLevel[${index}].additionalInputs`,
              value,
              colId
            );
          }
        );
      }
    },
    updateAdditionalInputsRows(updatedRowData, triggered) {
      const { rowId, value, colId } = updatedRowData;
      const updatedPlans = { ...this.storeData }.plans;
      const updatedAdditionalInputDataRows = [...this.additionalInputDataRows];
      const { planId, planRowId } = this.additionalInputDataRows[rowId];
      if (planId === competitivePlans.base_plan.id) {
        this.updateRestOfBudgetDistributions(
          updatedAdditionalInputDataRows,
          `[${rowId}]`,
          value,
          colId
        );
        if (!this.showAdditionalInputsAtBULevel) {
          this.updateRestOfBudgetDistributions(
            updatedPlans[planId],
            `overall.additionalInputs`,
            value,
            colId
          );
          this.updateAtBULevel(updatedPlans, planId, value, colId, rowId);
        } else {
          this.updateRestOfBudgetDistributions(
            updatedPlans[planId],
            `businessUnitLevel[${rowId}].additionalInputs`,
            value,
            colId
          );
          this.updateAtOverallLevel(updatedPlans, planId, colId);
        }
        this.updateRestOfAdditionalInputs(colId, value, rowId, updatedRowData);
      } else {
        if (triggered !== 'manually') {
          this.additionalInputsModified = true;
          updatedPlans[planId].isModified.inputs = true;
        }
        this.updateRestOfBudgetDistributions(
          updatedAdditionalInputDataRows,
          `[${rowId}]`,
          value,
          colId
        );
        if (!this.showAdditionalInputsAtBULevel) {
          this.updateRestOfBudgetDistributions(
            updatedPlans[planId],
            `overall.additionalInputs`,
            value,
            colId
          );
          this.updateAtBULevel(updatedPlans, planId, value, colId, rowId);
        } else {
          this.updateRestOfBudgetDistributions(
            updatedPlans[planId],
            `businessUnitLevel[${planRowId}].additionalInputs`,
            value,
            colId
          );
          this.updateAtOverallLevel(updatedPlans, planId, colId);
        }
      }

      this.additionalInputDataRows = updatedAdditionalInputDataRows;
      this.updateKeyInStore('plans', updatedPlans);
      this.checkForBudgetDistribution(colId, value, rowId);
    },
    updatePlanRowsUsingAchieveBy() {
      Object.keys(this.planRowsMap).forEach((key) => {
        const updatedPlanRows = [...this.planRowsMap[key]];
        this.updateRowsMap[key](
          updatedPlanRows.map((item) => {
            item.ops.achieveOPSGoalsBy = this.achieveOPSGoalsBy;
            if (
              item.data[this.dataKey] &&
              this.achieveOPSGoalsBy === achieveOPSGoalByOptions.percentage.type
            ) {
              item.ops.value = checkIfNegative(
                item.ops.value,
                convertToPercentageValue(
                  item.ops.value,
                  item.data[this.dataKey]
                )
              );
            } else if (
              item.data[this.dataKey] &&
              this.achieveOPSGoalsBy !== achieveOPSGoalByOptions.percentage.type
            ) {
              item.ops.value = checkIfNegative(
                item.ops.value,
                convertToAbsoluteValue(item.data[this.dataKey], item.ops.value)
              );
            } else {
              item.ops.value =
                this.achieveOPSGoalsBy ===
                achieveOPSGoalByOptions.percentage.type
                  ? competitivePlans[key].defaultOPSGoal
                  : 0;
            }
            return item;
          })
        );
      });
    },
    getPlansUpdatedRows(planRows, updatedRowData, achieveOPSGoalByPercentage) {
      const { tableId, rowId, value } = updatedRowData;
      const updatedPlans = { ...this.storeData.plans };
      const updatedPlanRows = planRows.map((item, index) => {
        if (this.showPlansAtBULevel) {
          const integerPercentageValue = convertToIntegerPercentageValue(
            value,
            item?.data?.[this.dataKey],
            achieveOPSGoalByPercentage
          );
          set(
            updatedPlans[tableId],
            `businessUnitLevel[${index}].opsGoal`,
            integerPercentageValue
          );
        }
        item.ops.value = value;
        return item;
      });
      this.updateRowsMap[tableId](updatedPlanRows);
      const overallOPS = convertToIntegerPercentageValue(
        value,
        updatedPlanRows[rowId].data[this.dataKey],
        achieveOPSGoalByPercentage
      );
      this.setOverallOPS(tableId, overallOPS);
      this.updateKeyInStore('plans', updatedPlans);
      return updatedPlanRows;
    },
    updateRestOfPlanRows(
      updatedRowData,
      achieveOPSGoalByPercentage,
      triggered
    ) {
      const { tableId, value } = updatedRowData;
      if (
        ![
          competitivePlans.moderately_aggressive_plan.id,
          competitivePlans.highly_aggressive_plan.id
        ].includes(tableId)
      ) {
        const increasedValued = (increaseBy) => {
          const finalPercentage = increaseBy + 100;
          return achieveOPSGoalByPercentage
            ? value + increaseBy
            : checkIfNegative(
                finalPercentage,
                convertToAbsoluteValue(value, finalPercentage)
              );
        };
        if (this.enableModerateAggressivePlan) {
          this.updateRow(
            {
              ...updatedRowData,
              tableId: competitivePlans.moderately_aggressive_plan.id,
              value: increasedValued(10)
            },
            null,
            'manually'
          );
        }
        if (this.enableHighlyAggressivePlan) {
          this.updateRow(
            {
              ...updatedRowData,
              tableId: competitivePlans.highly_aggressive_plan.id,
              value: increasedValued(20)
            },
            null,
            'manually'
          );
        }
      } else {
        if (triggered !== 'manually') {
          this.aggressivePlansModified = true;
          const updatedPlans = { ...this.storeData.plans };
          updatedPlans[tableId].isModified.ops = true;
          this.updateKeyInStore('plans', updatedPlans);
        }
      }
    },
    updatePlanRows(updatedRowData, triggered) {
      const { tableId, rowId, useForAllRows, value } = updatedRowData;
      let updatedPlanRows = [...this.planRowsMap[tableId]];
      const updatedPlans = { ...this.storeData.plans };
      const achieveOPSGoalByPercentage =
        this.achieveOPSGoalsBy === achieveOPSGoalByOptions.percentage.type;
      if (useForAllRows) {
        this.getPlansUpdatedRows(
          updatedPlanRows,
          updatedRowData,
          achieveOPSGoalByPercentage
        );
      } else {
        updatedPlanRows[rowId].ops.value = value;
        const totalSalesOfUpdatingItem =
          updatedPlanRows[rowId]?.data?.[this.dataKey];
        const integerPercentageValue = convertToIntegerPercentageValue(
          value,
          totalSalesOfUpdatingItem,
          achieveOPSGoalByPercentage
        );
        if (!this.showPlansAtBULevel) {
          this.setOverallOPS(tableId, integerPercentageValue);
        } else {
          set(
            updatedPlans[tableId],
            `businessUnitLevel[${rowId}].opsGoal`,
            integerPercentageValue
          );
          const sumOfOPS = updatedPlanRows.reduce((sum, item) => {
            const value = convertToIntegerPercentageValue(
              item.ops.value,
              item.data[this.dataKey],
              achieveOPSGoalByPercentage
            );
            return sum + value;
          }, 0);
          const avgOPS = (sumOfOPS / updatedPlanRows.length).toFixed(2) * 1;
          this.setOverallOPS(tableId, avgOPS);
        }
        this.updateRowsMap[tableId](updatedPlanRows);
      }
      if (!this.aggressivePlansModified) {
        this.updateRestOfPlanRows(
          updatedRowData,
          achieveOPSGoalByPercentage,
          triggered
        );
      }
      this.updateKeyInStore('plans', updatedPlans);
    },
    updateRow(updatedRowData, achieveOPSGoalsBy, triggered) {
      if (updatedRowData?.colId) {
        this.updateAdditionalInputsRows(updatedRowData, triggered);
      } else if (achieveOPSGoalsBy) {
        this.updatePlanRowsUsingAchieveBy();
      } else {
        this.updatePlanRows(updatedRowData, triggered);
      }
    },
    updateExpectedGrowth(plans, forceUpdate) {
      if (forceUpdate) {
        const updatedPlans = getUpdatedPlansWithAdditionalInputs(
          { ...plans },
          this.expectedGrowth,
          this.showAdditionalInputsAtBULevel
        );
        this.updateKeyInStore('plans', updatedPlans);
        if (this.expectedGrowth.reset) {
          this.$store.dispatch(EXPECTED_GROWTH_SETTER, {
            ...this.expectedGrowth,
            reset: false
          });
          this.hasFetchedCount = this.hasFetchedCount + 1;
        }
      }

      this.setAdditionalInputDataRows();
    },
    fetchAdditionalInputs(forceUpdate, forceFetch) {
      const isEditing = !!this.$route.params?.id;
      const { filters, organizeType, plans } = this.storeData;
      const { dimensionType, dimensionName } = this.businessUnit;
      const isIC = dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
      const shouldFetch = getConditionToFetchExpectedGrowth(
        this.showAdditionalInputsAtBULevel,
        this.expectedGrowth,
        isEditing,
        forceFetch
      );
      if (dimensionName && shouldFetch) {
        const isOverall = organizeOptions.OVERALL.value === organizeType;
        this.$store
          .dispatch(FETCH_ADDITIONAL_INPUTS, {
            showAdditionalInputsAtBULevel: this.showAdditionalInputsAtBULevel,
            isOverall,
            filters,
            dimensionName,
            isIC
          })
          .then(() => {
            this.updateExpectedGrowth(plans, true);
          });
      } else {
        this.updateExpectedGrowth(plans, forceUpdate);
      }
    },
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    fetchDataRows() {
      if (this.businessUnit.dimensionName && !this.planRows.rows.length) {
        const isOverall =
          this.storeData.organizeType === organizeOptions.OVERALL.value;
        const isIC =
          this.businessUnit.dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
        const payload = this.getPayload();
        this.$store
          .dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
            ...payload,
            isIC,
            filtersUpdated: true,
            applyFiltersForOverall: !isOverall,
            snackbar: this.snackbar
          })
          .then(() => {
            this.setPlanRows(this.expectedGrowth.reset);
            this.fetchAdditionalInputs(this.expectedGrowth.reset);
          });
      } else if (this.planRows.rows.length === 1) {
        setTimeout(() => {
          this.setPlanRows(true);
        }, 500);
      } else {
        this.setPlanRows(this.expectedGrowth.reset);
        this.fetchAdditionalInputs(this.expectedGrowth.reset);
      }
    },
    toggleEnterOPSGoal(newValue) {
      if (this.showPlansAtBULevel !== newValue) {
        this.showPlansAtBULevel = newValue;
        this.updateKeyInStore('showPlansAtBULevel', this.showPlansAtBULevel);
        this.setPlanRows(true);
        if (!this.showPlansAtBULevel) {
          Object.keys(competitivePlans).forEach((planId) => {
            const updatedPlans = { ...this.storeData.plans };
            updatedPlans[planId].businessUnitLevel = [];
            this.updateKeyInStore('plans', updatedPlans);
          });
        }
      }
    },
    setAdditionalInputDataRows() {
      const { plans } = this.storeData;
      const planRows = (plan) => {
        const planRows = cloneDeep(this.rows);
        const { id, label } = plan;
        planRows[0].plan = label;
        planRows.forEach((__, index) => {
          planRows[index].planId = id;
          planRows[index].planRowId = index;
          planRows[index] = {
            ...planRows[index],
            ...(plans[id]?.businessUnitLevel?.[index]?.additionalInputs || {})
          };
        });

        return planRows;
      };
      this.additionalInputDataRows = !this.showAdditionalInputsAtBULevel
        ? [
            {
              plan: competitivePlans.base_plan.label,
              planId: competitivePlans.base_plan.id,
              [this.businessUnit.dimensionName]: 'Overall',
              ...(plans[competitivePlans.base_plan.id]?.overall
                ?.additionalInputs || {})
            },
            ...(this.enableModerateAggressivePlan
              ? [
                  {
                    plan: competitivePlans.moderately_aggressive_plan.label,
                    planId: competitivePlans.moderately_aggressive_plan.id,
                    [this.businessUnit.dimensionName]: 'Overall',
                    ...(plans[competitivePlans.moderately_aggressive_plan.id]
                      ?.overall?.additionalInputs || {})
                  }
                ]
              : []),
            ...(this.enableHighlyAggressivePlan
              ? [
                  {
                    plan: competitivePlans.highly_aggressive_plan.label,
                    planId: competitivePlans.highly_aggressive_plan.id,
                    [this.businessUnit.dimensionName]: 'Overall',
                    ...(plans[competitivePlans.highly_aggressive_plan.id]
                      ?.overall?.additionalInputs || {})
                  }
                ]
              : [])
          ]
        : [
            ...planRows(competitivePlans.base_plan),
            ...(this.enableModerateAggressivePlan
              ? planRows(competitivePlans.moderately_aggressive_plan)
              : []),
            ...(this.enableHighlyAggressivePlan
              ? planRows(competitivePlans.highly_aggressive_plan)
              : [])
          ];
    },
    toggleAdditionalInputVisible() {
      this.showAdditionalInputs = !this.showAdditionalInputs;
      this.setAdditionalInputDataRows();
    },
    handleAdditionalInputCheck(newValue, forceFetch) {
      if (this.showAdditionalInputsAtBULevel !== newValue) {
        this.showAdditionalInputsAtBULevel = newValue;
        this.updateKeyInStore(
          'showAdditionalInputsAtBULevel',
          this.showAdditionalInputsAtBULevel
        );
        this.fetchAdditionalInputs(
          false,
          forceFetch || this.hasFetchedCount < 2
        );
        this.hasFetchedCount = this.hasFetchedCount + 1;
      }

      if (this.showAdditionalInputsAtBULevel && !this.showPlansAtBULevel) {
        this.toggleEnterOPSGoal(true);
      }
    },
    handleRadioSelection(newValue) {
      this.achieveOPSGoalsBy = newValue;
      this.updateRow({}, this.achieveOPSGoalsBy);
    }
  }
};
</script>

<style lang="css">
.goals .disabled-actions {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(1);
}
.goals .toggle-plan-visibility:hover {
  color: #007cf6;
}
.goals .toggle-plan-visibility:hover > span {
  color: #007cf6;
}
.goals .toggle-slider-off {
  filter: grayscale(1);
}
.goals .plans-table .ag-header-cell,
.goals .additional-inputs-table .ag-header-cell {
  font-size: 1.1rem;
  color: #8b8f93;
}
.goals .plans-table .ag-header-cell:not(:first-child),
.goals .additional-inputs-table .ag-header-cell:not(:first-child) {
  padding: 0;
}
.goals .plans-table .ag-cell:not(:first-child),
.goals .additional-inputs-table .ag-cell:not(:first-child) {
  margin: 0;
  padding: 0;
}
.goals .additional-inputs-table .ag-header-cell {
  padding: 0;
}
.goals .additional-inputs-table .ag-cell {
  margin: 0;
  padding: 0;
}
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .alignRowsCenter
  .ag-cell:first-child {
  border-right: 1px solid #e9eaeb !important;
}
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .alignRowsCenter
  .ag-cell:first-child,
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .alignRowsCenter
  .ag-cell:nth-child(2) {
  text-align: left;
  padding-left: 24px;
}
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .alignRowsCenter
  .ag-cell:first-child
  .cell--content,
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .alignRowsCenter
  .ag-cell:nth-child(2)
  .cell--content {
  word-break: break-word;
}
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .hideBottomBorder {
  border-width: 0 !important;
}
.goals
  .additional-inputs-table
  .ag-pinned-left-cols-container
  .hideBottomBorder
  > div:nth-child(2) {
  border-top: 1px solid #e9eaeb !important;
}
</style>
