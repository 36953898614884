<template>
  <div
    class="u-display-flex u-flex-direction-column edit-date-cm-action u-spacing-p-l u-bg-color-white-base"
  >
    <div
      class="u-width-100 title u-font-size-5 u-spacing-pb-l u-font-weight-600"
    >
      ADJUST START/END DATE ({{ selections.length }} campaign{{
        selections.length > 1 ? 's' : ''
      }}
      selected)
    </div>
    <div class="u-display-flex u-flex-align-items-flex-start">
      <div
        v-click-outside="hideStartCalendar"
        class="u-spacing-pr-l u-display-flex u-flex-align-items-center"
      >
        <div class="u-font-size-5 u-spacing-pr-s u-font-weight-600">
          Start date:
        </div>
        <div>
          <span
            class="filter--token u-font-size-6 u-border-radius-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('startDate')"
          >
            <rb-icon
              :icon="'calendar'"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span>
              <span class="u-line-height-1_2">
                {{ startDatePlaceholder }}
              </span>
              <span>
                {{ startDateTimezoneString }}
              </span>
            </span>
          </span>
          <datepicker
            ref="startDate"
            v-model="startDate"
            style="z-index: 104"
            input-class="hide_calendar_strategy"
            :disabled-dates="disabledStartDates"
            :disabled="isStartDateDisabled"
          />
        </div>
      </div>
      <div v-click-outside="hideEndCalendar">
        <div class="u-display-flex u-flex-align-items-center">
          <div class="u-font-size-5 u-font-weight-600 u-spacing-pr-s">
            End date:
          </div>
          <div>
            <span
              class="filter--token u-font-size-6 u-border-radius-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              @click="openCalendar('endDate')"
            >
              <rb-icon
                :icon="'calendar'"
                class="rb-icon--small u-spacing-mr-xs"
              />
              <span>
                <span class="u-line-height-1_2"> {{ endDatePlaceholder }}</span>
                <span>
                  {{ endDateTimezoneString }}
                </span>
              </span>
            </span>
            <datepicker
              ref="endDate"
              v-model="endDate"
              input-class="hide_calendar_strategy"
              :disabled-dates="disabledEndDates"
            >
              <div
                slot="beforeCalendarHeader"
                class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
                :class="
                  isEndDateIndefinite(endDate) &&
                  'u-color-white-base u-bg-color-orange-base'
                "
                @click="selectNoEndDate"
              >
                No end date
              </div>
            </datepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="u-spacing-pt-xl">
      <rb-button
        text="Apply"
        type="filled"
        :click-fn="onApply"
        size="s"
        :loading="loader"
        :disabled="isApplyButtonDisabled"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
        class="u-spacing-ml-s"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import { commons } from '@/components/ams/campaigns/commons.js';

export default {
  components: {
    Datepicker
  },
  props: {
    timezone: {
      type: String,
      default: 'PST'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    },
    retailer: {
      type: String,
      default: 'walmart'
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      dateFormat: 'MM/DD/YYYY',
      loader: false,
      startDate: null,
      endDate: null,
      initEndDate: null,
      isStartDateDisabled: false,
      isApplyButtonDisabled: false,
      infiniteDateValue: '07/02/9999'
    };
  },
  computed: {
    disabledStartDates() {
      // Here the start dates are disabled until yesterday
      const result = {
        to: moment().subtract(1, 'days').toDate()
      };
      return result;
    },
    disabledEndDates() {
      // here the end dates are initially disabled until yesterday initially
      let to = moment().subtract(1, 'days').toDate();
      // If the start date is ahead of yesterday's date, then we update the disabled dates until that selected date
      if (this.startDate !== null && moment(this.startDate).diff(to) > 0) {
        to = moment(this.startDate).toDate();
      }
      return {
        to
      };
    },
    startDateTimezoneString() {
      if (!this.showDateCondition(this.startDate)) return null;
      return this.timezone;
    },
    endDateTimezoneString() {
      if (!this.showDateCondition(this.endDate, 'endDate')) return null;
      return this.timezone;
    },
    startDatePlaceholder() {
      if (!this.showDateCondition(this.startDate)) return 'Select';
      return moment(this.startDate).format('MMM DD, YYYY');
    },
    endDatePlaceholder() {
      if (this.isEndDateIndefinite()) {
        // This means the end date chosen is indefinite
        return 'No end date';
      }
      if (!this.showDateCondition(this.endDate)) return 'Select';
      return moment(this.endDate).format('MMM DD, YYYY');
    }
  },
  watch: {
    selections: {
      handler: function (newVal) {
        console.log(newVal);
        this.onChangeOfSelections(newVal);
      }
    },
    startDate: function (newStartDateValue) {
      // if startdate is changed ahead of the endDate, then we need to update the end date as well
      if (moment(newStartDateValue).diff(moment(this.endDate)) > 0) {
        this.endDate = this.startDate;
      }
    }
  },
  created() {
    this.onChangeOfSelections(this.selections, true);
  },
  methods: {
    showDateCondition(date, type) {
      if (!date) {
        return false;
      }
      if (type === 'endDate') {
        return !this.isEndDateIndefinite();
      }
      return true;
    },
    onChangeOfSelections(selections, initDates) {
      // If no selections are made, then disable apply button
      if (selections?.length === 0) {
        this.isApplyButtonDisabled = true;
      } else {
        this.isApplyButtonDisabled = false;
      }
      if (selections?.length === 1) {
        this.isStartDateDisabled =
          selections[0]?.data?.campaign_type !== 'Scheduled';
        this.startDate = selections[0]?.data?.start_date
          ? moment(selections[0]?.data?.start_date, this.dateFormat).toDate()
          : null;
        if (initDates) {
          this.initEndDate = selections[0]?.data?.end_date;
        }
        const conditionForEndDate = initDates
          ? !this.isEndDateIndefinite(selections[0]?.data?.end_date)
          : selections[0]?.data?.end_date;
        this.endDate = conditionForEndDate
          ? moment(selections[0]?.data?.end_date, this.dateFormat).toDate()
          : null;
      } else {
        // If all items selected are of type "scheduled" then enable the start date field
        if (this.areAllSelectedItemsScheduled(selections)) {
          this.isStartDateDisabled = false;
        } else {
          this.isStartDateDisabled = true;
        }
        this.startDate = null;
        this.endDate = null;
      }
    },
    areAllSelectedItemsScheduled(selections) {
      const areAllSelectedItems = selections.every(
        (item) => item?.data?.campaign_type === 'Scheduled'
      );
      return areAllSelectedItems;
    },
    isEndDateIndefinite() {
      if (
        moment(this.endDate || this.infiniteDateValue) >=
        moment(this.infiniteDateValue)
      ) {
        return true;
      }
      return false;
    },
    generateAndValidatePayload(row) {
      let message = '';
      let invalid = false;
      this.endDate = this.endDate === null ? this.initEndDate : this.endDate;
      const newStartDate =
        this.isStartDateDisabled === true
          ? null
          : this.startDate
          ? moment(this.startDate).format(this.dateFormat)
          : null;
      const newEndDate = this.endDate
        ? moment(this.endDate).format(this.dateFormat)
        : null;
      if (newStartDate === row.start_date && newEndDate === row.end_date) {
        message = 'Please update either start date or end date';
        invalid = true;
      }
      if (moment(this.endDate).diff(this.startDate) < 0) {
        message = 'Start date cannot be ahead of end date.';
        invalid = true;
      }
      return {
        payload: {
          actionPayload: {
            campaignId: row.campaign_id,
            campaignType: row.campaign_type,
            advertiser_id: row.advertiser_id,
            start_date: row.start_date,
            new_start_date: newStartDate,
            end_date: row.end_date,
            new_end_date: newEndDate
          },
          viewPayload: {
            name: row.campaign_name,
            campaignId: row.campaign_id,
            campaignType: row.campaign_type,
            advertiser_id: row.advertiser_id,
            new_start_date: newStartDate
              ? moment(newStartDate, this.dateFormat).format('ll')
              : null || row?.start_date
              ? moment(row?.start_date, this.dateFormat).format('ll')
              : null || 'NA',
            new_end_date: newEndDate
              ? moment(newEndDate, this.dateFormat).format('ll')
              : null || row?.end_date
              ? moment(row?.end_date, this.dateFormat).format('ll')
              : null || 'NA',
            start_date: row?.start_date
              ? moment(row?.start_date, this.dateFormat).format('ll')
              : null || 'NA',
            end_date: row?.end_date
              ? moment(row?.end_date, this.dateFormat).format('ll')
              : null || 'NA'
          },
          actionType: `${this.retailer}CampaignDateChange`,
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          previousEntityValue: row.start_date + ', ' + row.end_date,
          primaryKey: row.campaign_id,
          newEntityValue:
            newStartDate ||
            (row?.start_date
              ? moment(row?.start_date, this.dateFormat).format(this.dateFormat)
              : null || 'NA') +
              ', ' +
              newEndDate ||
            (row?.end_date
              ? moment(row?.end_date, this.dateFormat).format(this.dateFormat)
              : null || 'NA') // This is done to bring this to a format of something like this "07/01/2021, 01/21/2022",
        },
        validation: {
          invalid,
          message
        }
      };
    },
    onApply() {
      const requestPayload = [];
      let invalid = false;
      for (const item of this.selections) {
        const { payload, validation } = this.generateAndValidatePayload(
          item.data
        );
        if (validation.invalid) {
          invalid = true;
          this.$snackbar.open({
            message: validation.message,
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
          return;
        }
        requestPayload.push(payload);
      }
      if (invalid) return;
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage:
          'Action has been submitted for the selected campaign(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj,
        this.apiVersion
      );
    },
    onClose() {
      this.$emit('close');
    },
    hideStartCalendar() {
      this.$refs.startDate.close();
    },
    hideEndCalendar() {
      this.$refs.endDate.close();
    },
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    selectNoEndDate() {
      this.initEndDate = moment(this.infiniteDateValue, this.dateFormat);
      this.endDate = null;
      this.hideEndCalendar();
    }
  }
};
</script>

<style lang="css">
.edit-date-cm-action .vdp-datepicker__calendar {
  top: 1px;
  right: -195px;
}
</style>
<style lang="css" scoped>
.width-420px {
  width: 420px;
}

.no-end-date {
  margin: -16px -16px 0 -16px;
  padding: 16px;
}
.no-end-date:hover,
.no-end-date:focus {
  background: #ffa800;
}
</style>
