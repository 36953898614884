import { cloneDeep } from 'lodash';
import { defaultMediaPlannerConfig } from '@/components/ams/media-planner/config.js';
import { defaultState } from '@/components/ams/media-planner/constants.js';

export const state = {
  mediaPlannerConfig: cloneDeep(defaultMediaPlannerConfig),
  filteredHistoricalData: {
    loading: false,
    data: {}
  },
  overallHistoricalData: {
    loading: false,
    data: {}
  },
  internalCategorizations: [],
  retailerCategorizations: [],
  generatePlans: {
    list: [],
    loading: false,
    error: null
  },
  draftedPlans: {
    list: [],
    loading: false,
    error: null
  },
  savingOrUpdatingPlanStatus: {
    loading: false,
    error: null,
    mediaPlanId: ''
  },
  output: {
    rows: {},
    distributions: {},
    loading: false,
    error: null,
    distributionLevelToShow: {},
    excelPath: {},
    statuses: {},
    time: {}
  },
  expectedGrowth: defaultState.expectedGrowth,
  errors: {
    retry: null
  }
};

export const getters = {
  mediaPlannerConfig: ({ mediaPlannerConfig }) => mediaPlannerConfig,
  filteredHistoricalData: ({ filteredHistoricalData }) =>
    filteredHistoricalData,
  overallHistoricalData: ({ overallHistoricalData }) => overallHistoricalData,
  internalCategorizations: ({ internalCategorizations }) =>
    internalCategorizations,
  retailerCategorizations: ({ retailerCategorizations }) =>
    retailerCategorizations,
  generatePlans: ({ generatePlans }) => generatePlans,
  draftedPlans: ({ draftedPlans }) => draftedPlans,
  savingOrUpdatingPlanStatus: ({ savingOrUpdatingPlanStatus }) =>
    savingOrUpdatingPlanStatus,
  output: ({ output }) => output,
  expectedGrowth: ({ expectedGrowth }) => expectedGrowth,
  errors: ({ errors }) => errors
};

export const mutations = {
  updateMediaPlannerConfig(state, data) {
    state.mediaPlannerConfig = cloneDeep(data);
  },
  updateFilteredHistoricalData(state, data) {
    state.filteredHistoricalData = cloneDeep(data);
  },
  updateOverallHistoricalData(state, data) {
    state.overallHistoricalData = cloneDeep(data);
  },
  updateInternalCategorizations(state, data) {
    state.internalCategorizations = cloneDeep(data);
  },
  updateRetailerCategorizations(state, data) {
    state.retailerCategorizations = cloneDeep(data);
  },
  updateGeneratedPlans(state, data) {
    state.generatePlans = cloneDeep(data);
  },
  updateDraftedPlans(state, data) {
    state.draftedPlans = cloneDeep(data);
  },
  updateSavingOrUpdatingPlan(state, data) {
    state.savingOrUpdatingPlanStatus = cloneDeep(data);
  },
  updateOutput(state, data) {
    state.output = cloneDeep(data);
  },
  updateExpectedGrowth(state, data) {
    state.expectedGrowth = cloneDeep(data);
  },
  updateErrors(state, data) {
    state.errors = cloneDeep(data);
  }
};
