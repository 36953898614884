<template>
  <div class="u-width-100">
    <div>
      <section>
        <div class="u-display-flex">
          <rbSplitTabs
            :tabs-list="tabList"
            :is-tabs-list="true"
            :active-tab="selectedTab"
            @tabClicked="onTabClick"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    isTabList: {
      type: Boolean,
      default: () => false
    },
    selectedTab: {
      type: [String, Number],
      default: () => []
    }
  },
  data() {
    return {
      getActiveTab: 0
    };
  },
  methods: {
    onTabClick(tab) {
      this.$emit('tabClicked', tab);
    }
  }
};
</script>
