<template>
  <section
    v-if="className === 'open'"
    class="savedDraftPanel"
    :class="className"
  >
    <div class="header u-spacing-ph-l u-spacing-pt-l u-spacing-pb-m">
      <span class="u-color-grey-base u-font-size-4 u-font-weight-600"
        >Saved drafts</span
      >
      <span @click="closeRightPanel">
        <rb-icon
          :icon="'cross'"
          class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
        />
      </span>
    </div>
    <section class="body u-position-relative">
      <div
        v-for="(strategy, $index) in strategyList"
        :key="$index"
        class="strategy_wrp u-spacing-p-m u-spacing-pr-0"
      >
        <p class="u-font-size-4 u-display-flex u-flex-align-items-center">
          <span class="u-spacing-mr-s">{{
            ($index + 1) | priorityFilter
          }}</span>
          <span>{{ strategy.strategyName }}</span>
        </p>
        <p
          class="u-spacing-ml-l u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
        >
          <span>{{ strategy.description }}</span>
        </p>
        <p
          class="u-spacing-ml-l u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
        >
          <span
            >Created by <span>{{ strategy.userName }}</span> on
            <span> {{ strategy.creationTime | dateFormatFilter }}</span>
          </span>
        </p>
        <p
          class="u-spacing-ml-l u-font-weight-600 u-font-size-6 u-color-blue-base u-spacing-mt-s u-cursor-pointer"
          @click="submit(strategy)"
        >
          Review & Save
        </p>
      </div>
      <Loader
        v-show="isLoading"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </section>
  </section>
</template>
<script>
import { eventBus } from '@/utils/services/eventBus';
import Loader from '@/components/basic/loader';
import moment from 'moment-timezone';

export default {
  components: {
    Loader
  },
  filters: {
    priorityFilter: function (value) {
      if (!value) return '0';
      return ('0' + value).slice(-2);
    },
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM, DD YYYY');
    }
  },
  data() {
    return {
      className: 'close',
      isLoading: false,
      strategyList: []
    };
  },
  created() {
    eventBus.$on('viewSavedDraftPanelOpen', this.opeEvent);
    eventBus.$on('viewSavedDraftPanelClose', this.closeEvent);
  },
  beforeDestroy() {
    eventBus.$off('viewSavedDraftPanelOpen', this.opeEvent);
    eventBus.$off('viewSavedDraftPanelClose', this.closeEvent);
  },
  methods: {
    opeEvent() {
      this.className = 'open';
      this.isLoading = true;
      this.$store.dispatch('fetchStartegyDraftList').then((response) => {
        this.isLoading = false;
        this.strategyList = response.data;
      });
    },
    closeEvent() {
      this.className = 'close';
    },
    closeRightPanel() {
      eventBus.$emit('viewSavedDraftPanelClose');
    },
    submit(params) {
      this.$router.push({
        name: 'StrategyBuilderEdit',
        query: {
          mode: 'edit',
          id: params.strategyUniqueId
        }
      });
      // this.$emit('submitCallback', params);
    }
  }
};
</script>
<style lang="css" scoped>
.savedDraftPanel {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: white;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
}
.savedDraftPanel .header {
  width: 100%;
  height: 56px;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.savedDraftPanel .body {
  flex-grow: 1;
  overflow: auto;
}

.savedDraftPanel.close {
  width: 0%;
}
.savedDraftPanel.close .body {
  display: none;
}

.savedDraftPanel.open {
  width: 25%;
}
.savedDraftPanel.open .body {
  display: block;
}
</style>
