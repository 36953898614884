<template>
  <div class="u-display-flex u-flex-direction-column">
    <h4
      v-if="spotlightData.heading"
      class="u-font-size-4 u-font-weight-600 u-color-grey-mid-light"
    >
      {{ spotlightData.heading }}
    </h4>
    <div class="main u-display-flex u-spacing-mv-m u-line-height-m">
      <div
        class="show-chart-holder u-bg-color-blue-base u-display-flex u-flex-align-items-center u-flex-justify-content-center u-border-radius-xs u-spacing-p-xxs u-spacing-mr-s"
      >
        <rb-icon
          class="rb-icon--small u-color-grey-white"
          icon="show-chart"
        />
      </div>
      <html-component
        wrapper-element="p"
        class="u-font-size-6"
        :html-string="spotlightData.content"
      />
    </div>
    <p
      class="card-footer u-font-size-6 u-display-flex u-flex-align-items-flex-end u-flex-grow-1"
    >
      <template v-for="(action, index) in spotlightData.actions">
        <span
          :key="index"
          class="u-color-blue-base u-cursor-pointer u-font-weight-600"
          @click="handleActionClick(action.handler)"
          >{{ action.text }}</span
        >
        <span
          v-if="index !== spotlightData.actions.length - 1"
          :key="action.text"
          class="u-spacing-mh-xs"
          >or</span
        >
      </template>
    </p>
  </div>
</template>

<script>
import HtmlComponent from '@/components/basic/html-component.vue';
export default {
  components: {
    HtmlComponent
  },
  props: {
    spotlightData: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleActionClick(handlerFn) {
      handlerFn?.();
    }
  }
};
</script>

<style lang="css" scoped>
.u-line-height-m {
  line-height: 1.3;
}
.show-chart-holder {
  width: 13px;
  height: 13px;
}
</style>
