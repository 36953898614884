<template>
  <div class="u-display-flex u-flex-direction-column">
    <div class="u-flex-align-items-center u-display-flex u-spacing-mt-m">
      <CollapsibleTitle
        class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
        title="Placement Inclusion"
        :is-expanded="placementInclusionExpand"
        title-font-class="u-font-size-5 u-font-weight-600"
        @click="$parent.$emit('placementInclusionExpandClicked')"
      />
      <div class="u-spacing-pt-s">
        <div
          class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
        >
          Optional
        </div>
      </div>
    </div>
    <section
      v-show="placementInclusionExpand"
      class="u-display-flex u-flex-direction-column"
    >
      <span class="u-font-size-2 u-font-weight-600 u-color-grey-base">
        What is placement inclusion?
      </span>
      <span
        class="u-spacing-mt-s u-font-size-5 u-font-weight-400 u-color-grey-lighter"
      >
        Choose how you want to pay for clicks on your ads.
      </span>
      <span
        class="u-spacing-mt-xl u-font-size-5 u-font-weight-600 u-color-grey-base"
      >
        Placement Inclusion:
      </span>
      <div class="u-spacing-mt-m u-display-flex">
        <!-- Search Ingrid -->
        <div class="u-display-flex u-flex-direction-column">
          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-xs">
            <custom-tool-tip
              :tippy="{
                html: '#search-ingrid',
                interactive: false,
                offset: '0,0',
                placement: 'top-start'
              }"
            >
              <div slot="toolTipData">
                <div
                  class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                >
                  <div class="u-spacing-pb-m u-font-weight-600 u-font-size-5">
                    Search Ingrid
                  </div>
                  <span class="u-font-size-6 u-font-weight-400"
                    >Ads appear in Search Results and Browse pages. This type is
                    mandatory for Walmart Sponsored Products ads and cannot be
                    disabled.
                  </span>
                </div>
              </div>
              <div
                slot="body"
                slot-scope="{ tippyConfig }"
              >
                <div
                  v-tippy="tippyConfig"
                  class="u-font-size-6 u-spacing-mr-xs"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
            </custom-tool-tip>
            <span
              data-cy="searchIngridText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
              >Search Ingrid</span
            >
          </div>
          <rb-select
            class="u-spacing-mr-s valueDropdown u-width-110px disabled pointer-events-none"
            :send-details="true"
            :on-close="searchIngridSelected"
            :options="searchIngridOptions"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div class="u-display-flex u-spacing-p-s">
                <span
                  class="enabled-circle u-spacing-mr-xs u-flex-align-self-center"
                />
                <span class="u-font-size-5">{{
                  selectedSearchIngridOption.title
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                :icon="'lock'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.title }}
              </div>
            </template>
          </rb-select>
        </div>

        <!-- Search Carousel -->
        <div class="u-display-flex u-flex-direction-column u-spacing-ml-m">
          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-xs">
            <custom-tool-tip
              :tippy="{
                html: '#search-carousel',
                interactive: false,
                offset: '0,0',
                placement: 'top-start'
              }"
            >
              <div slot="toolTipData">
                <div
                  class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                >
                  <div class="u-spacing-pb-m u-font-weight-600 u-font-size-5">
                    Search Carousel
                  </div>
                  <span class="u-font-size-6 u-font-weight-400"
                    >Ads appear in browse, category, curated shelf, and the
                    bottom of search results.</span
                  >
                </div>
              </div>
              <div
                slot="body"
                slot-scope="{ tippyConfig }"
              >
                <div
                  v-tippy="tippyConfig"
                  class="u-font-size-6 u-spacing-mr-xs"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
            </custom-tool-tip>
            <span
              data-cy="searchCarouselText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
              >Search Carousel</span
            >
          </div>
          <rb-select
            class="u-spacing-mr-s valueDropdown u-width-120px"
            :send-details="true"
            :on-close="searchCarouselSelected"
            :options="searchCarouselOptions"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div class="u-display-flex u-spacing-p-s">
                <span
                  :class="[
                    selectedSearchCarouselOption.title === 'Enabled'
                      ? 'enabled-circle'
                      : 'disabled-circle'
                  ]"
                  class="u-spacing-mr-xs u-flex-align-self-center"
                />
                <span class="u-font-size-5">{{
                  selectedSearchCarouselOption.title
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.title }}
              </div>
            </template>
          </rb-select>
        </div>

        <!-- Item Carousel -->
        <div class="u-display-flex u-flex-direction-column u-spacing-ml-m">
          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-xs">
            <custom-tool-tip
              :tippy="{
                html: '#item-carousel',
                interactive: false,
                offset: '0,0',
                placement: 'top-start'
              }"
            >
              <div slot="toolTipData">
                <div
                  class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                >
                  <div class="u-spacing-pb-m u-font-weight-600 u-font-size-5">
                    Item Carousel
                  </div>
                  <span class="u-font-size-6 u-font-weight-400"
                    >Ads appear at the middle and bottom of product details page
                    through personalized modules: "Customers also Viewed,"
                    "Customers also Considered," "Customers also Bought."
                  </span>
                </div>
              </div>
              <div
                slot="body"
                slot-scope="{ tippyConfig }"
              >
                <div
                  v-tippy="tippyConfig"
                  class="u-font-size-6 u-spacing-mr-xs"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
            </custom-tool-tip>
            <span
              data-cy="itemCarouselText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
              >Item Carousel</span
            >
          </div>

          <rb-select
            class="u-spacing-mr-s valueDropdown u-width-120px"
            :send-details="true"
            :on-close="itemCarouselSelected"
            :options="itemCarouselOptions"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div class="u-display-flex u-spacing-p-s">
                <span
                  :class="[
                    selectedItemCarouselOption.title === 'Enabled'
                      ? 'enabled-circle'
                      : 'disabled-circle'
                  ]"
                  class="u-spacing-mr-xs u-flex-align-self-center"
                />
                <span class="u-font-size-5">{{
                  selectedItemCarouselOption.title
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.title }}
              </div>
            </template>
          </rb-select>
        </div>

        <!-- Item Buy Box -->
        <div class="u-display-flex u-flex-direction-column u-spacing-ml-m">
          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-xs">
            <custom-tool-tip
              :tippy="{
                html: '#item-buybox',
                interactive: false,
                offset: '0,0',
                placement: 'top-start'
              }"
            >
              <div slot="toolTipData">
                <div
                  class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                >
                  <div class="u-spacing-pb-m u-font-weight-600 u-font-size-5">
                    Item Buy Box
                  </div>
                  <span class="u-font-size-6 u-font-weight-400"
                    >Ads appear below the Buy Box located on the product detail
                    page of a relevant or complementary product.
                  </span>
                </div>
              </div>
              <div
                slot="body"
                slot-scope="{ tippyConfig }"
              >
                <div
                  v-tippy="tippyConfig"
                  class="u-font-size-6 u-spacing-mr-xs"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
            </custom-tool-tip>
            <span
              data-cy="itemBuyBoxText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
              >Item Buy Box</span
            >
          </div>
          <rb-select
            class="u-spacing-mr-s valueDropdown u-width-120px"
            :send-details="true"
            :on-close="itemBuyBoxSelected"
            :options="itemBuyBoxOptions"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div class="u-display-flex u-spacing-p-s">
                <span
                  :class="[
                    selectedItemBuyBoxOption.title === 'Enabled'
                      ? 'enabled-circle'
                      : 'disabled-circle'
                  ]"
                  class="u-spacing-mr-xs u-flex-align-self-center"
                />
                <span class="u-font-size-5">{{
                  selectedItemBuyBoxOption.title
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.title }}
              </div>
            </template>
          </rb-select>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import customToolTip from '@/components/widgets/customToolTip.vue';
export default {
  components: {
    CollapsibleTitle,
    customToolTip
  },
  props: {
    placementInclusionExpand: {
      type: Boolean,
      default: false
    },
    placementInclusionObject: {
      type: Object,
      default: {
        searchCarousel: {
          title: 'Disable',
          value: 'excluded'
        },
        itemCarousel: {
          title: 'Disable',
          value: 'excluded'
        },
        itemBuyBox: {
          title: 'Disable',
          value: 'excluded'
        }
      }
    }
  },
  data: () => ({
    // Not to be changed
    searchIngridOptions: [{ title: 'Enabled', value: 1 }],
    selectedSearchIngridOption: { title: 'Enabled', value: 1 },

    // Search Carousel Options and selected item
    searchCarouselOptions: [
      { title: 'Enabled', value: 'included' },
      { title: 'Disable', value: 'excluded' }
    ],
    selectedSearchCarouselOption: { title: 'Disable', value: 'excluded' },

    // Item Carousel Options and selected item
    itemCarouselOptions: [
      { title: 'Enabled', value: 'included' },
      { title: 'Disable', value: 'excluded' }
    ],
    selectedItemCarouselOption: { title: 'Disable', value: 'excluded' },

    // Item Buy Box Options and selected Item
    itemBuyBoxOptions: [
      { title: 'Enabled', value: 'included' },
      { title: 'Disable', value: 'excluded' }
    ],
    selectedItemBuyBoxOption: { title: 'Disable', value: 'excluded' }
  }),
  watch: {
    placementInclusionObject: {
      handler(newVal) {
        if (newVal?.searchCarousel) {
          this.selectedSearchCarouselOption = newVal.searchCarousel;
        }
        if (newVal?.itemCarousel) {
          this.selectedItemCarouselOption = newVal.itemCarousel;
        }
        if (newVal?.itemBuyBox) {
          this.selectedItemBuyBoxOption = newVal.itemBuyBox;
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    emitPlacementInclusionValuesUpdated() {
      this.$parent.$emit('placementInclusionValuesUpdated', {
        searchCarousel: this.selectedSearchCarouselOption,
        itemCarousel: this.selectedItemCarouselOption,
        itemBuyBox: this.selectedItemBuyBoxOption
      });
    },
    searchIngridSelected(context, action) {
      if (!action.length) return;
      this.selectedSearchIngridOption = action[0];
      this.emitPlacementInclusionValuesUpdated();
    },
    searchCarouselSelected(context, action) {
      if (!action.length) return;
      this.selectedSearchCarouselOption = action[0];
      this.emitPlacementInclusionValuesUpdated();
    },
    itemCarouselSelected(context, action) {
      if (!action.length) return;
      this.selectedItemCarouselOption = action[0];
      this.emitPlacementInclusionValuesUpdated();
    },
    itemBuyBoxSelected(context, action) {
      if (!action.length) return;
      this.selectedItemBuyBoxOption = action[0];
      this.emitPlacementInclusionValuesUpdated();
    },
    placementInclusionExpandClicked() {
      this.$parent.$emit('placementInclusionExpandClicked');
    }
  }
};
</script>
<style lang="css" scoped>
.enabled-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #3ea95e;
}
.disabled-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #ffa800;
}

.pointer-events-none {
  pointer-events: none;
}

.max-width-420px {
  max-width: 420px;
}
</style>
