<template>
  <div class="chip-wrapper">
    <div
      v-if="showChart"
      class="chip-active-indicator"
      :style="{
        'background-color': computedSelectedMetric[val.key]
          ? bgColor
          : mutedColor
      }"
    />
    <rb-tooltip-header
      id="tooltipTemplate"
      :title="val.key ? val.key : 'NA'"
      :message="val.tooltip ? modifyMessage(val.tooltip) : 'NA'"
    />
    <div
      v-if="isMetricToolTipEnabled"
      v-tippy="setTooltip.tippyOptions"
      class="demo u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
      :title="val.tooltip ? val.tooltip : 'NA'"
    >
      <div
        class="chip-label u-font-size-4"
        data-cy="chip-label"
      >
        {{ val.label }} :
      </div>
    </div>
    <div class="chip-value u-font-size-4">
      <metric
        v-if="hasPVP && (metricData || {})[val.key] && !hasPostCustomMetric"
        :hide-zero="hideZero"
        :size="'m'"
        class="u-display-inline-flex"
        :config="(metricConfig || {})[val.key]"
        :data="(metricData || {})[val.key]"
      />
    </div>
    <rb-select
      v-if="val && !removeDropdown && enableWatefall"
      :width="'240px'"
      :search-enable-threshold="searchEnableThreshold"
      class="u-spacing-mr-m"
      :context="[index]"
      :send-details="true"
      :options="chartChipOptions"
      :class-name="'campaigns-select'"
    >
      <div
        slot="trigger"
        :class="[
          !ellipsisOnOverflow &&
            'u-display-flex u-flex-align-items-center u-cursor-pointer'
        ]"
      >
        <div
          v-if="!isMetricToolTipEnabled"
          class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
          :title="val.tooltip ? val.tooltip : 'NA'"
        >
          <rb-icon
            class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
        <div
          v-else
          class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
        >
          <div
            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
            :title="val.tooltip ? val.tooltip : 'NA'"
          >
            <span
              :class="[
                metricFontSize,
                ellipsisOnOverflow && 'overflow-styles u-display-block'
              ]"
              >{{ val.key }}</span
            >
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
        </div>
      </div>
    </rb-select>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import WaterfallDropdownOption from '@/components/basic/atoms/chart-legends/waterfall-dropdown-option.vue';

export default {
  components: {
    metric,
    WaterfallDropdownOption,
    RbSelectV2
  },

  props: {
    val: {
      type: Object,
      default: () => ({})
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    searchEnableThreshold: {
      type: Number,
      default: 200
    },
    hasPVP: {
      type: Boolean,
      default: true
    },
    metricData: {
      type: Object,
      default: () => {}
    },
    hasPostCustomMetric: {
      type: Boolean,
      defalut: false
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    computedSelectedMetric: {
      type: Object,
      default: () => {}
    },
    showChart: {
      type: Boolean,
      default: true
    },
    removeDropdown: {
      type: Boolean,
      default: false
    },
    enableWatefall: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    ellipsisOnOverflow: {
      type: Boolean,
      default: true
    },
    metricFontSize: {
      type: String,
      default: 'u-font-size-5'
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    chartChipOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mutedColor() {
      return '#E9EAEB';
    },
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    }
  },
  methods: {
    modifyMessage(message) {
      if (!this.modifyTooltipMessage) return message;
      let messageResult = '';
      const updatedMessage = message.split('|');
      updatedMessage.forEach((item) => {
        messageResult = messageResult + item + '\n';
      });
      return messageResult;
    }
  }
};
</script>

<style>
.chip-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e9eaeb;
  color: #8b8f93;
  padding: 4px 12px;
  margin-right: 1.5rem;
  cursor: pointer;
}
.chip-active-indicator {
  height: 10px;
  width: 10px;
  border-radius: 100%;
}
.chip-value {
  margin-left: 5px;
}
</style>
