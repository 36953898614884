<template>
  <div class="u-height-100 u-width-100 u-spacing-ph-s u-cursor-default">
    <div
      v-if="isDisabledRow"
      class="u-position-absolute u-spacing-p-m u-height-100 u-bg-color-grey-base disabled-background"
      :class="disabledRowClass"
    />
    <div
      class="u-display-flex u-height-100 u-width-100 u-flex-justify-content-center u-flex-direction-column"
      :class="[customClasses]"
    >
      <div
        v-if="isDisabledRow"
        class="u-display-flex u-flex-direction-column u-flex-justify-content-center"
      >
        <span
          class="u-border-width-s u-border-top u-border-color-grey-dark"
          style="width: 2rem"
        />
      </div>
      <div
        v-else
        class="u-display-flex"
        :class="{
          'u-flex-direction-row u-flex-align-items-center u-flex-justify-content-space-around u-width-100':
            !isDetailedView && !isTippy,
          'u-flex-direction-column u-flex-justify-content-center':
            isDetailedView && !isTippy,
          'u-flex-direction-row u-flex-justify-content-space-around': isTippy,
          'confetti-bg': leader
        }"
      >
        <div
          v-for="(dataElement, index) in data"
          :key="index"
          class="u-display-flex u-flex-direction-row"
          :class="{
            'u-flex-justify-content-space-between':
              enablePrice && isClient(dataElement.client_flag),
            'u-flex-justify-content-center': leader
          }"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-width-max-content"
            :class="{
              'u-flex-direction-column u-text-align-center':
                leader && data.length === 1,
              'u-flex-direction-row': !leader,
              'u-flex-justify-content-center': !isDetailedView,
              'u-spacing-mb-m':
                isDetailedView && index < data.length - 1 && !isTippy,
              'u-spacing-pr-xs u-flex-1': enablePrice
            }"
          >
            <div
              v-tippy="{ placement: 'top' }"
              :title="dataElement.brand"
              class="u-display-block"
              :class="{
                'u-border-color-blue-base u-spacing-p-xs u-border-radius-xxxl u-border-all client-flag':
                  isClient(dataElement.client_flag)
              }"
            >
              <img
                v-if="!initials[dataElement.brand]"
                :src="dataElement.imageUrl"
                alt="brand"
                class="brand-image u-border-color-grey-xxx-light u-border-width-s"
                :class="{ 'logo-large-view': !isDetailedView }"
                @error="handleNoImage(dataElement.brand)"
              />
              <div
                v-else
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-3 u-border-width-s u-border-all u-text-case-upper u-color-grey-base u-border-color-grey-xxx-light brand-image"
              >
                {{ initials[dataElement.brand] }}
              </div>
            </div>
            <div
              v-if="isDetailedView"
              class="u-display-flex u-flex-justify-content-center u-flex-direction-column"
              :class="{ 'u-spacing-ml-m': !leader || data.length > 1 }"
            >
              <div
                class="u-font-size-7 u-spacing-mv-xs u-font-weight-600 u-white-space-normal u-text-case-title"
                :class="{
                  'u-text-align-center':
                    dataElement.desc && leader && data.length === 1,
                  'u-color-blue-base': isClient(dataElement.client_flag),
                  'u-color-grey-base': dataElement.client_flag === 'comp'
                }"
              >
                <img
                  v-if="leader"
                  src="https://cdn.rboomerang.com/cva/Leader_Crown.svg"
                  alt="leader"
                />
                {{ dataElement.brand }}
              </div>
              <span
                v-if="dataElement.desc"
                class="u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-400"
                style="white-space: break-spaces"
              >
                {{ hideDesc ? '' : dataElement.desc }}</span
              >
              <div v-if="dataElement.info">
                <div
                  v-for="infoObj in dataElement.info"
                  :key="infoObj.preText"
                >
                  <span
                    class="u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-400"
                  >
                    {{ infoObj.preText }}
                  </span>
                  <span
                    class="u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-600"
                  >
                    {{ infoObj.text }}
                  </span>
                  <rb-icon
                    v-if="infoObj.textIcon"
                    :icon="infoObj.textIcon"
                    :class="infoObj.textIconClasses"
                    class="icon-custom-class"
                  />
                </div>
              </div>
              <span
                v-if="dataElement.metricData"
                class="u-spacing-mb-s"
              >
                <span
                  v-if="!hideMetricData"
                  class="primary-metric u-font-size-4 u-font-weight-bold u-color-grey-base"
                  >{{ dataElement.metricData.result }}</span
                >
              </span>
            </div>
          </div>
          <div
            v-if="
              enableSOP(
                dataElement.module,
                dataElement.client_flag,
                dataElement.sop_lower,
                dataElement.sop_higher
              )
            "
            class="u-display-flex u-flex-direction-column u-spacing-pl-s u-border-color-grey-xx-light u-border-left-style-dashed u-spacing-mt-s u-spacing-mb-l"
          >
            <div
              class="u-spacing-mb-s u-display-flex u-flex-direction-row u-flex-align-items-center"
            >
              <img
                src="https://cdn.rboomerang.com/cva/Leader_Crown.svg"
                class="u-spacing-mr-xs u-width-10"
                alt="leader"
              />
              <span class="u-font-size-7 u-color-blue-base">Size of Prize</span>
            </div>
            <div
              class="u-display-flex u-flex-direction-row u-flex-align-items-center u-font-size-7 u-flex-wrap-yes"
            >
              <span class="u-color-grey-base">Sales</span>
              <div class="u-display-flex u-flex-direction-row">
                <span
                  class="u-font-weight-600 u-color-black-base u-spacing-ml-xxs"
                  >{{
                    sopCalculation(
                      dataElement.sop_lower,
                      dataElement.sop_higher
                    )
                  }}</span
                >
                <rb-icon
                  :icon="'sort-asc'"
                  class="rb-icon--x-small u-color-green-base u-spacing-ml-xxs"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoreBrandTooltip
        v-if="enableShowMore"
        :id="tooltipId"
        :data="tooltipData"
        :is-detailed-view="isDetailedView"
        :hide-metric-data="hideMetricData"
        :hide-desc="hideDesc"
      />
      <div
        v-if="enableShowMore"
        class="u-display-flex u-flex-justify-content-flex-end u-cursor-pointer custom-style u-font-weight-700"
      >
        <div
          v-if="tooltipData.length > 0"
          v-tippy="tippyOption"
        >
          + {{ tooltipData.length }} more
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import MoreBrandTooltip from '@/components/pages/insights/amazon/cva/atoms/MoreBrandTooltip.vue';
export default {
  name: 'BrandIconCell',
  components: { MoreBrandTooltip },
  props: {
    isTippy: {
      type: Boolean,
      default: false
    },
    enableShowMore: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: Array,
      default: () => []
    },
    isDetailedView: {
      type: Boolean,
      required: true
    },
    leader: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    tooltipData: {
      type: Array,
      required: false,
      default: () => []
    },
    isDisabledRow: {
      type: Boolean,
      default: false
    },
    enablePrice: {
      type: Boolean,
      default: false
    },
    hideMetricData: {
      type: Boolean,
      default: false
    },
    sopCalculation: {
      type: Function,
      default: () => {}
    },
    hideDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initials: {}
    };
  },
  computed: {
    tippyOption() {
      return {
        html: '#' + this.tooltipId,
        placement: 'top-start',
        interactive: true,
        reactive: true,
        arrow: false,
        theme: 'light'
      };
    },
    tooltipId() {
      return 'tooltip' + this._uid;
    },
    disabledRowClass() {
      return this.customClasses[0] || '';
    }
  },
  methods: {
    enableSOP(module, clientFlag, sopLower, sopHigher) {
      return (
        this.isDetailedView &&
        this.enablePrice &&
        !this.restrictSizeOfPrice(module) &&
        this.isClient(clientFlag) &&
        (sopLower || sopHigher)
      );
    },
    restrictSizeOfPrice(cellData) {
      const restictedRow = ['Pricing Leadership', 'RnR', 'Assortment'];
      return restictedRow.includes(cellData);
    },
    isClient(clientFlag) {
      return clientFlag === 'client';
    },
    handleNoImage(name) {
      const initials = name
        .split(' ')
        .map((item) => item[0])
        .slice(0, 2)
        .join('');
      Vue.set(this.initials, name, initials);
    }
  }
};
</script>

<style lang="css" scoped>
.u-border-left-style-dashed {
  border-left-style: dashed;
}
.icon-custom-class {
  top: 2px;
}
.dashed-border {
  border-bottom: 1px dashed #aaadb1;
}
.border-bottom-radius-left-right {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.border-top-radius-left-right {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.brand-image {
  border-radius: 50%;
  background-color: #fff;
  object-fit: contain;
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
}
.brand-image.logo-large-view {
  height: 60px;
  width: 60px;
}
.client-flag .brand-image {
  border-radius: 50%;
  background-color: #fff;
  object-fit: contain;
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
}
.client-flag {
  border-width: 4px;
}
.client-flag .brand-image.logo-large-view {
  height: 50px;
  width: 50px;
}
.confetti-bg {
  background-image: url('https://cdn.rboomerang.com/cva/Confetti.svg');
}
.disabled-background {
  width: calc(100% - 1.6rem);
  opacity: 0.08;
}
.custom-style {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.custom-style:hover {
  color: #2b333b;
}
</style>
