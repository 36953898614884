import {
  getBaseActionType,
  getBaseActionType as getCriteoBaseActionType,
  getFinalMeasuresList
} from '@/pages/campaign-management/retailers/criteo/utils';
import requestConfig from '@/pages/campaign-management/retailers/criteo/line_items/requestConfig.json';
import { klona } from 'klona/json';
import {
  generatePlotRowChip,
  getDataFromRowNode,
  payloadGenerator,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common';
import {
  additionalDateRanges,
  baseChartConfig,
  commonChartActionIcons,
  commonTableActionIcons,
  gridOptions,
  lineItemsEditStatusActionPayloadKeys
} from '@/pages/campaign-management/constants';
import HttpService from '@/utils/services/http-service';
import actionPanelConfigs from '@/pages/campaign-management/retailers/criteo/line_items/AddNegativeKeywordsPanel/addKeywordsPanelWidget';
import { getDayPartingActionObjectArray } from '@/pages/campaign-management/retailers/walmart/campaigns';
import { addKeywordsEnterListPanelUpdateTextInput } from '@/pages/dashboard_service_campaign_management/citrusAds/campaigns';
import {
  keywordRightTable,
  removeKeywordsLeftTable,
  removeKeywordsRightTable
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils';
import metricsDictionary from '@/pages/campaign-management/retailers/dictionary';

const WIDGET = 'lineItem';
const PRIMARY_KEY = 'line_item_campaign_id';

function chartRequest(retailer) {
  return {
    ...klona(requestConfig.chart),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.chart.measuresList),
      retailer
    )
  };
}

function tableRequest(retailer) {
  return {
    ...klona(requestConfig.table),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.table.measuresList),
      retailer
    )
  };
}

function getEditStatusActionObject(context, retailer) {
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Enable', value: 'active' },
      { title: 'Pause', value: 'paused' }
    ],
    handler(selectionLength, dropDownSelection, selections) {
      return new Promise((resolve, reject) => {
        const dataArray = selections.map((item) => getDataFromRowNode(item));
        const dynamicKeys = {
          primaryKey: PRIMARY_KEY,
          previousEntityValue: 'status',
          actionPayloadKeys: lineItemsEditStatusActionPayloadKeys(),
          viewPayloadKeys: lineItemsEditStatusActionPayloadKeys()
        };
        const staticValues = {
          widget: WIDGET,
          actionType: `${getBaseActionType(retailer)}UpdateLineItemState`,
          actionSource: {
            pageUrl: window.location.href
          }
        };
        const newValuesObject = {
          newEntityValue: dropDownSelection[0].value,
          actionPayload: {
            actionType: `${getBaseActionType(retailer)}UpdateLineItemState`,
            status: dropDownSelection[0].value
          },
          viewPayload: {
            current_status: dropDownSelection[0].value
          }
        };
        const payloadArray = dataArray.map((item) =>
          payloadGenerator(dynamicKeys, staticValues, newValuesObject, item)
        );
        context.openSnackbar(
          'Status change request has been submitted for the selected line item(s)'
        );
        HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
          .then((response) => {
            context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  };
}

function getAdjustBidActionObject(context) {
  return {
    icon: 'dollar',
    title: 'Adjust Bid',
    id: 'adjust-bids',
    customState: {
      component: 'BidChangePanel',
      props: {
        title: 'Adjust Bid',
        actionPanelType: 'line_items',
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY,
        infoBoxText:
          'The Line item bid should be at least the greatest value of the Minimum Bid across all products on the line item.'
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getEditOptimizationStrategyActionObject(context, retailer) {
  return {
    icon: 'pencil',
    title: 'Optimization Strategy',
    id: 'edit-optimization-strategy',
    customState: {
      component: 'EditOptimizationStrategy',
      props: {
        title: 'Edit Optimization Strategy',
        actionType: `${getBaseActionType(retailer)}OptimizationStrategyChange`,
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getEditBudgetAndPacingActionObject(context, retailer) {
  return {
    icon: 'dollar',
    title: 'Edit Budget & Pacing',
    id: 'edit-budget-and-pacing',
    customState: {
      component: 'EditBudgetAndPacing',
      props: {
        title: 'Adjust line item budget and pacing',
        actionType: `${getBaseActionType(retailer)}MonthlyAndDailyPacingChange`,
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getAddAkusActionObject(context, retailer) {
  return {
    icon: 'sku-box',
    title: 'Add SKUs',
    id: 'add-skus',
    customState: {
      component: 'AddProducts',
      props: {
        title: '',
        actionType: `${getBaseActionType(retailer)}BulkAddProductToLineItem`,
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getEditLineItemNameActionObject(context) {
  return {
    icon: 'pencil',
    title: 'Edit Line Item Name',
    id: 'edit-line-item-name',
    actionUpdater(currentAction, selections) {
      currentAction.disabled = selections.length > 1;
      currentAction.tippy =
        selections.length > 1
          ? 'Action set limited for multiple campaign selections'
          : '';
      return currentAction;
    },
    customState: {
      component: 'EditLineItemName',
      props: {
        title: '',
        actionType: 'criteoLineItemNameChange',
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getEditStartEndDateActionObject(context) {
  return {
    icon: 'calendar',
    title: 'Edit Start/End Dates',
    id: 'edit-start-end-dates',
    customState: {
      component: 'EditLineItemDates',
      props: {
        title: '',
        actionType: 'criteoLineItemDateChange',
        apiVersion: 'actionlog',
        retailer: context.$store.getters.getRetailer,
        widget: WIDGET,
        primaryKey: PRIMARY_KEY
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getAddNegativeKeywordsActionObject(context, retailer) {
  return {
    icon: 'add-circle-outline',
    title: 'ADD NEGATIVE KEYWORDS',
    id: 'add-negative-keywords',
    customState: {
      component: 'AddNegativeKeywordsPanel',
      props: {
        title: '',
        isMultiple: true,
        apiVersion: 'actionlog',
        widgetsConfigRef: actionPanelConfigs,
        actionType: `${getBaseActionType(retailer)}AddRemoveKeywordsToLineItem`,
        parentAdgroup: true,
        actionName: 'negative keyword',
        actionSubLabel: 'LINE ITEM',
        primaryKey: PRIMARY_KEY,
        widget: WIDGET,
        enableAdGroup: false,
        filterConfig: {
          header: 'Match type:',
          filterStyle: {
            Broad: 'width:100px'
          },
          items: ['Broad', 'Exact']
        },
        tableConfig: {
          sourceTableLabel: 'Add Negative Keywords',
          resultTableLabel: 'Added Negative Keywords',
          sourceTableWidth: '50%',
          resultTableWidth: '50%',
          resultTableHeight: '372px !important'
        },
        placeholder: 'Enter list and seperate each of them by new line',
        hasCustomCheckBoxOptions: false,
        matchTypes: {
          Exact: 'NegativeExactMatch',
          Broad: 'NegativeBroadMatch'
        },
        customCheckBoxOptions: null,
        defaultSelectedCustomCheckBoxOptions: [
          { label: 'Exact', value: 'exact' }
        ],
        forceAllowForMultiple: true,
        noteAboveFooter:
          'If the same keyword in added as both Exact and Broad match, it will act as a broad match negative keyword.'
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getAddPositiveKeywordsConfig(customActionPanelVueRef) {
  return {
    bidValues: {
      min: customActionPanelVueRef.selections[0]?.data?.min_bid,
      max: customActionPanelVueRef.selections[0]?.data?.max_bid
    },
    addKeywordsLimit: 1000
  };
}

function getBidErrorMessage(customActionPanelVueRef) {
  const config = getAddPositiveKeywordsConfig(customActionPanelVueRef);
  const minBid = config.bidValues.min;
  const maxBid = config.bidValues.max;

  if (maxBid === null) {
    return `Bid value must be greater than ${minBid}`;
  } else if (minBid === null) {
    return `Bid value must be between 0 and ${maxBid}`;
  } else {
    return `Bid value must be between ${minBid} and ${maxBid}`;
  }
}

function getCustomBidError(customActionPanel) {
  const bid = customActionPanel.suggestedCustomBidInput;

  return (
    (customActionPanel.cpcBidMax && bid > customActionPanel.cpcBidMax) ||
    customActionPanel < this.cpcBidMin ||
    !bid
  );
}

async function addPositiveKeywordsActionApply(
  customActionPanelVueRef,
  retailer,
  rows
) {
  const baseActionType = getCriteoBaseActionType(retailer);
  const payload = [];
  let showRemovedKeywordsSnackbar = false;
  customActionPanelVueRef.selections.forEach((lineItemSelection) => {
    const { line_item_id, campaign_id, line_item_name, min_bid, max_bid } =
      lineItemSelection.data;
    rows.forEach((keywordAdded) => {
      const keyword = keywordAdded.keyword;
      const bid = parseFloat(keywordAdded.newBid);
      if (bid < min_bid || (max_bid && bid > max_bid)) {
        showRemovedKeywordsSnackbar = true;
        return;
      }
      payload.push({
        actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
        actionSource: {
          pageUrl: window.location.href
        },
        actionPayload: {
          line_item_id,
          bid,
          keyword,
          actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
          keywordMatchType: 'PositiveExactMatch'
        },
        widget: WIDGET,
        primaryKey: `${line_item_id}_${campaign_id}`,
        viewPayload: {
          line_item_name,
          keyword,
          bid,
          keywordMatchType: 'PositiveExactMatch',
          actionType: `${baseActionType}AddRemoveKeywordsToLineItem`
        }
      });
    });
  });
  if (showRemovedKeywordsSnackbar) {
    customActionPanelVueRef.openSnackbar(
      'Keywords with Bid values outside the range were automatically removed from the respective line items.'
    );
  }
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    customActionPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    customActionPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function getAddPositiveKeywordsActionObject(context, retailer) {
  return {
    icon: 'add-circle-outline',
    title: 'Add Positive Keywords',
    id: 'add-positive-keywords',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Add Positive Keywords',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            return {
              customActionPanelContainerClass: 'u-spacing-mt-l',
              primaryKey: 'key',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: 'Add Positive Keywords to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                tabs: {
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'keyword',
                        buttonLabel: 'Add Positive keywords',
                        heading: 'Match Type Exact:',
                        emitTextInput: true,
                        entityCount: getAddPositiveKeywordsConfig(
                          customActionPanelVueRef
                        ).addKeywordsLimit
                      },
                      events: {
                        updateTextInput(data) {
                          addKeywordsEnterListPanelUpdateTextInput(
                            data,
                            customActionPanelVueRef,
                            true
                          );
                        }
                      }
                    },
                    showCustomBidInput: true,
                    applyButtonTooltip:
                      'If the line item has a maximum CPC bid set, then the maximum CPC bid will always be respected regardless of whether the keyword bid that was entered is greater.',
                    validateCustomBidInput:
                      customActionPanelVueRef.selections.length === 1,
                    suggestedCustomBidErrorMessage: getBidErrorMessage(
                      customActionPanelVueRef
                    ),
                    suggestedCustomBidError: getCustomBidError,
                    bidValues: {
                      default: getAddPositiveKeywordsConfig(
                        customActionPanelVueRef
                      ).bidValues.min,
                      min: getAddPositiveKeywordsConfig(customActionPanelVueRef)
                        .bidValues.min,
                      max: getAddPositiveKeywordsConfig(customActionPanelVueRef)
                        .bidValues.max
                    },
                    subHeading: 'Bid',
                    removeLeftTabCustomComponentTopPadding: true
                  }
                },
                table: 'left',
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    page: 1,
                    limit: 200
                  }
                },
                headerText: 'Add Positive Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: keywordRightTable(
                    customActionPanelVueRef
                  ).toSpliced(1, 1, {
                    name: 'Bid',
                    type: 'custom',
                    uiField: {
                      uiLabel: 'Bid',
                      uiOrder: '2',
                      uiType: 'custom',
                      metadata: {
                        toolTipText: '',
                        widget: 'input',
                        type: 'number',
                        tableColumnName: 'newBid',
                        defaultValueColumnName: 'newBid',
                        showOnUi: true,
                        isFixed: false,
                        formatType: 'currency',
                        width: 160,
                        keyupEvent: '',
                        onchangeEvent: (context, event) => {
                          // Validatino should happen here
                          context.params.data.newBid = context.bidValue;
                          customActionPanelVueRef.checkValidityForApply();
                        }
                      }
                    }
                  })
                },
                headerText: () =>
                  `Added Positive Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                await addPositiveKeywordsActionApply.call(
                  this,
                  customActionPanelVueRef,
                  retailer,
                  rows
                );
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return (
                  customActionPanelVueRef.errorMessage ||
                  !arrayToTraverse.length ||
                  arrayToTraverse.length >
                    getAddPositiveKeywordsConfig(customActionPanelVueRef)
                      .addKeywordsLimit
                );
              }
            };
          }
        }
      },
      events: {
        rightTableRows(newRightTableRows, customActionPanelVueRef) {
          const noOfSelections = customActionPanelVueRef.selections.length;
          for (const row of newRightTableRows) {
            if (noOfSelections > 1) {
              if (!row.newBid) {
                customActionPanelVueRef.errorMessage =
                  'Please enter a bid value';
              } else {
                customActionPanelVueRef.errorMessage = '';
              }
              return;
            }
            if (
              !row.newBid ||
              row.newBid < customActionPanelVueRef.cpcBidMin ||
              (customActionPanelVueRef.cpcBidMax &&
                row.newBid > customActionPanelVueRef.cpcBidMax)
            ) {
              customActionPanelVueRef.errorMessage = getBidErrorMessage(
                customActionPanelVueRef
              );
              return;
            }
            customActionPanelVueRef.errorMessage = '';
          }
        },
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getRemoveKeywordsActionObject(context, retailer) {
  return {
    icon: 'remove-outline',
    title: 'Remove Keywords',
    id: 'remove-keywords',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Remove Keywords',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            const lineItemIds = customActionPanelVueRef.selections?.map(
              (item) => item?.data?.line_item_id
            );
            return {
              primaryKey: 'key',
              isMultipleAllowed: true,
              customActionPanelContainerClass: 'u-spacing-mt-l',
              default: {
                noRowsRightTable: 'Add Keywords to remove to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Remove Keywords',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                tabs: {
                  'All Keywords': {
                    columnDefs: removeKeywordsLeftTable(
                      customActionPanelVueRef
                    ).toSpliced(0, 1, {
                      name: 'line_item_name',
                      type: 'STRING',
                      uiField: {
                        uiLabel: 'Line Item Name',
                        uiType: 'string',
                        uiOrder: '1',
                        metadata: {
                          toolTipText: '',
                          tableColumnName: 'line_item_name',
                          showOnUi: true,
                          width: 200
                        }
                      }
                    }),
                    search: {
                      enableSearch: true,
                      searchKey: 'search'
                    },
                    showFilterCheckBox: false
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [
                    ...lineItemIds.map((lineItemId) => ({
                      dimensionName: 'line_item_id',
                      dimensionValue: lineItemId,
                      operator: 'EQUAL_TO'
                    })),
                    {
                      dimensionName: 'is_deleted',
                      dimensionValue: false,
                      operator: 'EQUAL_TO'
                    },
                    {
                      dimensionName: 'targeting_type',
                      dimensionValue: 'Manual',
                      operator: 'EQUAL_TO'
                    }
                  ],
                  updateRows: (vueRef) => {
                    const newRows = [];
                    vueRef.leftTableData.rows.forEach((row) => {
                      newRows.push({
                        ...row,
                        key: row?.keyword_id
                      });
                    });
                    vueRef.leftTableRows = newRows;
                  },
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    cubeName: 'criteo_common_keywords_workbench',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesDimension: 'report_date',
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'is_deleted',
                      'keyword_id',
                      'keyword',
                      'line_item_id',
                      'line_item_name'
                    ],
                    groupByDimensionsList: ['campaign_id', 'keyword_id'],
                    limit: 200,
                    page: 1,
                    where: {
                      dimensionNameValueList: []
                    }
                  }
                },
                headerText: 'Remove Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: removeKeywordsRightTable(
                    customActionPanelVueRef
                  ).toSpliced(0, 1, {
                    name: 'line_item_name',
                    type: 'STRING',
                    uiField: {
                      uiLabel: 'Line Item Name',
                      uiType: 'string',
                      uiOrder: '1',
                      metadata: {
                        toolTipText: '',
                        tableColumnName: 'line_item_name',
                        showOnUi: true,
                        width: 200
                      }
                    }
                  })
                },
                headerText: () =>
                  `Selected Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                const baseActionType = getBaseActionType(retailer);
                const payload = rows.map((row) => {
                  const { line_item_id, campaign_id, line_item_name, keyword } =
                    row;
                  return {
                    actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
                    actionSource: { pageUrl: window.location.href },
                    actionPayload: {
                      line_item_id,
                      keyword,
                      actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
                      keywordMatchType: 'PositiveExactMatch',
                      is_deleted: true
                    },
                    widget: WIDGET,
                    primaryKey: `${line_item_id}_${campaign_id}`,
                    viewPayload: {
                      line_item_name,
                      keyword,
                      is_deleted: true,
                      actionType: `${baseActionType}AddRemoveKeywordsToLineItem`
                    }
                  };
                });
                try {
                  await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                  customActionPanelVueRef.$emit('afterAPIActionToCall');
                } catch (err) {
                  console.log(err);
                  customActionPanelVueRef.openSnackbar('Something went wrong.');
                }
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return !arrayToTraverse.length;
              }
            };
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

export function getBlueBarActions(context, retailer) {
  return [
    {
      icon: 'show-chart',
      title: 'PLOT ROWS',
      id: 'show-chart',
      removeSelectionState: false,
      handler(selectionLength, dropDownSelection, selections) {
        const payload = plotRowsPayloadExtraction(selections, PRIMARY_KEY);
        // this points to table-widget because have used call in the method call
        this.$emit('plotRowData', payload);
      }
    },
    getEditStatusActionObject(context, retailer),
    getAdjustBidActionObject(context),
    getEditOptimizationStrategyActionObject(context, retailer),
    getEditBudgetAndPacingActionObject(context, retailer),
    getAddAkusActionObject(context, retailer),
    getEditLineItemNameActionObject(context),
    getEditStartEndDateActionObject(context),
    getAddNegativeKeywordsActionObject(context, retailer),
    getAddPositiveKeywordsActionObject(context, retailer),
    getRemoveKeywordsActionObject(context, retailer),
    ...getDayPartingActionObjectArray(true, retailer)
  ];
}

export default (context, tab, retailer) => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Line Items'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'criteo_common_line_items.max_feed_date',
        min: 'criteo_common_line_items.min_feed_date'
      },
      api: {
        cube: 'criteo_common_campaign_line_items_workbench',
        page: `${retailer}_line_items`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'campaigns-filter',
      page: `${retailer}_line_items`,
      listenerEvent: `${retailer}-line-items-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-campaign',
          placeholder: 'Search for a Line Item',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-campaigns-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: chartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          chartConfig: baseChartConfig(),
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      activityBar: {
        pageType: 'line items'
      },
      messageBar: {
        pageType: 'line item(s)'
      },
      actionsBar: {
        actions: getBlueBarActions(context, retailer)
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: tableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'campaigns'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        },
        events: {}
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    }
  };
};
