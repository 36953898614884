<template>
  <section>
    <rb-select
      :search-enable-threshold="searchEnableThreshold"
      class="u-spacing-mh-xs"
      :send-details="true"
      :on-close="valueSelected"
      :options="computedList"
      :class-name="'search-dropdown'"
      :search-enabled="true"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <div
          v-if="hasBrandImage"
          class="u-border-radius-xxxl u-border-color-grey-xxx-light u-border-all"
        >
          <img
            v-if="!initials[selectedValue[0].name]"
            :src="getImageUrl(selectedValue[0].name)"
            alt="brand"
            class="brand-image u-border-color-grey-xxx-light u-border-width-s"
            @error="handleNoImage(selectedValue[0].name)"
          />
          <div
            v-else
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-3 u-border-width-s u-text-case-upper u-color-grey-base brand-image"
          >
            {{ initials[selectedValue[0].name] }}
          </div>
        </div>
        <div
          class="u-flex-1 u-font-size-7 u-spacing-pl-s u-text-overflow-ellipsis u-font-weight-600 u-color-grey-base"
        >
          {{ selectedValue[0].name }}
        </div>
        <rb-icon
          class="u-flex-0 u-spacing-ml-s rb-icon--medium u-color-grey-lighter"
          icon="caret-down"
        />
      </div>
      <div
        slot="header"
        class="u-spacing-pt-xs"
      >
        <span
          class="u-text-transform-capitalize u-spacing-pl-m u-font-size-6 u-font-weight-600 u-color-grey-lighter"
        >
          Select {{ listHeader }}
        </span>
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
        >
          <div
            v-if="hasBrandImage"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-border-radius-xxxl u-border-color-grey-xxx-light u-border-all"
          >
            <img
              v-if="!initials[option.name]"
              :src="getImageUrl(option.name)"
              alt="brand"
              class="brand-image u-border-color-grey-xxx-light u-border-width-s"
              @error="handleNoImage(option.name)"
            />
            <div
              v-else
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-3 u-border-width-s u-text-case-upper u-color-grey-base brand-image"
            >
              {{ initials[option.name] }}
            </div>
          </div>
          <div
            class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
          >
            <p
              class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
              data-cy="metric-dropdown-option"
            >
              {{ option.name }}
            </p>
          </div>
        </div>
      </template>
    </rb-select>
  </section>
</template>

<script>
import { differenceBy } from 'lodash';
import Vue from 'vue';

export default {
  name: 'DropdownWithSearch',
  props: {
    searchEnableThreshold: {
      type: Number,
      default: 10
    },
    optionsList: {
      type: Array,
      default: () => [
        { name: 'Nespresso' },
        { name: 'Starbucks' },
        { name: 'Green Mountain' },
        { name: "Peet's Coffee" },
        { name: 'Dunkin' },
        { name: 'Mccafe' },
        { name: 'The Original Donut Shop' },
        { name: 'Solimo' },
        { name: 'San Francisco Bay' },
        { name: "Victor Allen's Coffee" }
      ]
    },
    hasBrandImage: {
      type: Boolean,
      default: true
    },
    listHeader: {
      type: String,
      default: 'Brand'
    }
  },
  data() {
    return {
      initials: {},
      selectedValue: [],
      id: null
    };
  },
  computed: {
    computedList() {
      return differenceBy(this.optionsList, this.selectedValue, (a) => a);
    }
  },
  created() {
    // Having default selection as first value
    let selectedValue = [];
    selectedValue[0] = this.optionsList[0];
    selectedValue[0].selectedIndex = 1;
    this.selectedValue = selectedValue;
  },
  methods: {
    valueSelected(context, selectedValue) {
      // Handles when clicked outside the dropdown
      if (selectedValue.length === 0) {
        return;
      }
      this.selectedValue = selectedValue;
      this.$emit('valueSelected', selectedValue);
    },
    handleNoImage(name) {
      const initials = name
        .split(' ')
        .map((item) => item[0])
        .slice(0, 2)
        .join('');
      Vue.set(this.initials, name, initials);
    },
    getImageUrl(name) {
      const formattedBrandName = name.replaceAll(' ', '_').toLowerCase();
      const imageUrl = `https://cdn.rboomerang.com/images/brands/${formattedBrandName}.jpeg`;
      return imageUrl;
    }
  }
};
</script>
<style lang="css" scoped>
.brand-image {
  border-radius: 50%;
  object-fit: contain;
  min-height: 24px;
  min-width: 24px;
  height: 31px;
  width: 31px;
}
</style>
