<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <!-- Loader -->
    <div
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        Edit Start/End Date ({{ selections.length }} Line Item{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </div>

      <!-- start/end date -->

      <div class="u-display-flex">
        <div
          class="u-spacing-pr-l u-flex-direction-column u-color-grey-lighter"
        >
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            Start Date:
          </div>
          <div v-click-outside="hideStartCalendar">
            <span
              v-tippy="{ placement: 'top', arrow: true }"
              class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              :title="disableMultipleStartDates ? startDateTippy : ''"
              @click="openCalendar('startDate')"
            >
              <rb-icon
                icon="calendar"
                class="rb-icon--small u-spacing-mr-xs"
              />
              <span class="u-spacing-ph-xs u-line-height-1_2">
                {{
                  disableMultipleStartDates
                    ? 'Select'
                    : dateFormatFilter(campaignDates.start, 'start')
                }}</span
              >
            </span>
            <datepicker
              ref="startDate"
              v-model="campaignDates.start"
              style="right: -200px"
              name="startDate"
              :disabled-dates="disableStartDates"
            />
          </div>
        </div>

        <div class="u-flex-direction-column">
          <div class="u-spacing-pb-s u-font-size-5">
            <span class="u-font-weight-600">End Date:</span>
            <span class="u-color-grey-lighter">Optional</span>
          </div>
          <div
            v-click-outside="hideEndCalendar"
            class="entity-details-settings-calendar"
          >
            <span
              class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              @click="openCalendar('endDate')"
            >
              <rb-icon
                icon="calendar"
                class="rb-icon--small u-spacing-mr-xs"
              />
              <span class="u-spacing-ph-xs u-line-height-1_2">
                {{
                  multipleEndDates
                    ? 'Select'
                    : dateFormatFilter(campaignDates.end, 'end')
                }}</span
              >
            </span>
            <datepicker
              ref="endDate"
              v-model="campaignDates.end"
              style="right: -100px"
              name="endDate"
              :disabled-dates="disableEndDates"
            >
              <div
                slot="beforeCalendarHeader"
                class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
                :class="
                  noEndDate && 'u-color-white-base u-bg-color-orange-base'
                "
                data-cy="noEndDate"
                @click="selectNoEndDate"
              >
                No end date
              </div>
            </datepicker>
          </div>
        </div>
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pv-m u-spacing-ph-l u-display-flex"
    >
      <div
        v-if="applyButtonDisabled"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="applyButtonDisabled ? tooltipErrorMessage : ''"
      >
        <rb-button
          text="Apply"
          type="filled"
          :click-fn="onApply"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import moment from 'moment-timezone';
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    loader,
    Datepicker
  },
  props: {
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      loader: false,
      config: null,
      errorMessage: null,
      tooltipErrorMessage: '',
      noEndDate: true,
      disableMultipleStartDates: false,
      multipleEndDates: false,
      startDateTippy: '',
      disableStartDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disableEndDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      campaignDates: {
        start: this.selections[0]?.data?.start_date,
        end: this.selections[0]?.data?.end_date
      },
      startDate: {
        start_date: ':startDate',
        view_old_start_date: ':oldStartDate',
        view_start_date: ':startDateView'
      },
      endDate: {
        end_date: ':endDate',
        view_old_end_date: ':oldEndDate',
        view_end_date: ':endDateView'
      }
    };
  },
  computed: {
    applyButtonDisabled() {
      if (this.errorMessage) {
        return true;
      } else if (
        this.selections.length === 1 &&
        this.campaignDates.start === this.selections[0]?.data?.start_date &&
        this.campaignDates.end === this.selections[0]?.data?.end_date
      ) {
        this.tooltipErrorMessage = 'Change start/end date.';
        return true;
      } else if (this.multipleEndDates === true) {
        this.tooltipErrorMessage = 'End date is required.';
        return true;
      }
      return false;
    }
  },
  watch: {
    selections(newVal) {
      if (!newVal?.length) {
        this.$emit('closePanelMethod');
      }
      if (newVal.length > 1) {
        this.disableMultipleStartDates = true;
        this.startDateTippy =
          "Start date can't be changed for multiple campaigns";
        this.multipleEndDates = true;
      } else {
        this.multipleEndDates = false;
        this.disableMultipleStartDates = false;
        this.campaignDates.start = this.selections[0]?.data?.start_date;
        this.campaignDates.end = this.selections[0]?.data?.end_date;
      }
    },
    'campaignDates.start'() {
      this.disableEndDates.to = this.campaignDates.start;
    },
    'campaignDates.end'() {
      this.multipleEndDates = false;
    }
  },
  mounted() {
    if (this.selections.length > 1) {
      this.disableMultipleStartDates = true;
      this.startDateTippy =
        "Start date can't be selected for multiple campaigns";
      this.multipleEndDates = true;
    }
  },
  methods: {
    openCalendar(param) {
      if (param === 'startDate' && this.selections.length > 1) return;
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    hideStartCalendar() {
      this.$refs?.startDate?.close();
    },
    hideEndCalendar() {
      this.noEndDate = !this.campaignDates.end;
      this.$refs?.endDate?.close();
    },
    selectNoEndDate() {
      this.campaignDates.end = null;
      this.hideEndCalendar();
    },
    dateFormatFilter: function (value, type) {
      if (
        new Date(this.campaignDates.end) < new Date(this.campaignDates.start) &&
        this.campaignDates.end !== null
      ) {
        this.campaignDates.end = this.campaignDates.start;
      }
      if (value === null) return `No ${type} date`;
      return moment(value).format('MMM DD, YYYY');
    },
    dateFormatter(val) {
      if (val === null) return null;
      return moment(val).format('MM/DD/YYYY');
    },
    onClose() {
      this.$emit('closePanelMethod');
    },
    onApply() {
      let payload = [];
      payload = [...this.createPayload()];
      this.callAPI(payload);
    },
    callAPI(payload) {
      const that = this;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Line Items Dates changed successfully.',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.apiVersion
      );
    },
    createPayload() {
      let newStartDate = this.campaignDates.start;
      if (
        this.selections.length > 1 ||
        this.campaignDates.start === this.selections[0]?.data?.start_date
      ) {
        newStartDate = null;
      }
      const payload = [];
      this.selections.forEach((item) => {
        const payloadObject = {
          widget: this.widget,
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          actionPayload: {
            line_item_id: item?.data?.line_item_id,
            actionType: this.actionType,
            current_start_date: item?.data?.start_date,
            new_start_date: this.dateFormatter(newStartDate),
            current_end_date: item?.data?.end_date,
            new_end_date: this.dateFormatter(this.campaignDates.end)
          },
          viewPayload: {
            line_item_id: item?.data?.line_item_id,
            actionType: this.actionType,
            current_start_date:
              newStartDate === null ? newStartDate : item?.data?.start_date,
            new_start_date: this.dateFormatter(newStartDate)
          },
          primaryKey: item?.data?.[this.primaryKey],
          previousEntityValue: `${item?.data?.start_date},${item?.data?.end_date}`,
          newEntityValue: `${this.dateFormatter(
            newStartDate
          )},${this.dateFormatter(this.campaignDates.end)}`
        };
        if (
          item?.data?.end_date !== this.dateFormatter(this.campaignDates.end)
        ) {
          payloadObject.viewPayload.current_end_date =
            item?.data?.end_date === null ? 'NA ' : item?.data?.end_date;
          payloadObject.viewPayload.new_end_date = this.dateFormatter(
            this.campaignDates.end
          );
        }
        payload.push(payloadObject);
      });
      return payload;
    }
  }
};
</script>

<style lang="css">
.entity-details-settings-calendar .vdp-datepicker__calendar {
  padding: 0px;
}
.entity-details-settings-calendar .vdp-datepicker__calendar div {
  padding: 16px;
}
</style>
<style scoped lang="css">
.filter--token {
  border-radius: 2px;
}
.min-height-card {
  min-height: 630px;
}

.no-end-date:hover,
.no-end-date:focus {
  background: palette(orange);
}
</style>
