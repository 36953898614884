<template>
  <div
    class="u-height-100 u-font-size-5 u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <div class="u-spacing-mv-xl">
      <div>Use this Code for AVC login</div>
      <div class="u-font-size-1 u-spacing-p-m u-text-align-center">
        {{ totp }}
      </div>
      <div
        id="loadingBar"
        style="height: 3px; background: #007cf6"
      />
    </div>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import urlHelper from '@/utils/helpers/url';

export default {
  data() {
    return {
      timeout: null,
      otp: null
    };
  },
  computed: {
    totp() {
      if (this.otp) {
        if (Number.isInteger(this.otp)) {
          var seconds = new Date().getSeconds();
          if (seconds > 30) {
            seconds -= 30;
          }
          var remaining = 30 - seconds;
          this.timeout = setTimeout(this.getOTP, remaining * 1000);
          var bar = document.getElementById('loadingBar');
          bar.style.transition = 'none';
          bar.style.width = (seconds * 100) / 30 + '%';
          setTimeout(() => {
            bar.style.transition = 'width ' + remaining + 's linear';
            bar.style.width = '100%';
          }, 300);
        }
        return (
          '000000'.substring(this.otp.toString().length) + this.otp.toString()
        );
      } else {
        return 'loading';
      }
    }
  },
  mounted() {
    this.getOTP();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    async getOTP() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const clientChildAccountName =
        params.get('clientChildAccountName') ||
        urlHelper.getClientInfoFromUrl().client;
      try {
        const res = await HttpService.post('FETCH_TOTP', {
          clientChildAccountName
        });
        const { totp } = res.data;
        this.otp = totp;
      } catch (e) {
        this.$snackbar.open({
          message: 'Failed to fetch TOTP',
          duration: 2000,
          actionText: 'Failed'
        });
      }
    }
  }
};
</script>
