<template>
  <div class="ag-expand-cell">
    <div
      v-if="!hideLastLevel && params.node.data['ag-grid-has-expand'] !== false"
      :style="{ 'padding-left': params.node.data['ag-grid-level'] * 20 + 'px' }"
      :class="
        params.node.data['entity_name'] === 'Uncategorized' ? 'overlay-css' : ''
      "
      :v-tippy="tippy"
      :title="toolTipTitle"
      @click="expandCell"
    >
      <rb-icon
        v-if="!params.node.data.isExpanded"
        class="rb-icon--small u-spacing-ml-s u-color-grey-mid-light"
        icon="arrow-right"
      />
      <rb-icon
        v-if="params.node.data.isExpanded"
        class="rb-icon--small u-spacing-ml-s u-color-grey-mid-light"
        icon="arrow1-down"
      />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  name: 'TableExpandCell',
  data() {
    return {
      tippy: {
        placement: 'right-end',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    hideLastLevel() {
      const totalLevels = this.params.node.data['ag-grid-total-levels'];
      const currentLevel = this.params.node.data['ag-grid-level'];
      return totalLevels === currentLevel;
    },
    toolTipTitle() {
      if (this.params.showToolTip) {
        // This means hide the tooltip if the data property's 'ag-grid-has-expand' is present
        if (this.params.hideToolTipIfAgGridHasExpand) {
          if (!this.params.node.data['ag-grid-has-expand']) {
            return this.params.toolTipText ? this.params.toolTipText : '';
          } else return '';
        }
        return this.params.toolTipText ? this.params.toolTipText : '';
      }
      return '';
    }
  },
  methods: {
    expandCell() {
      const listener = this.params.node.data['ag-grid-event-listener-key'];
      const obj = {
        row: this.params.node.data,
        index: this.params.node.rowIndex
      };
      eventBus.$emit(listener, obj);
    }
  }
};
</script>

<style scoped>
.overlay-css {
  pointer-events: none;
}
</style>
