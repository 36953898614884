/* eslint-disable no-var */
// @ts-ignore
import HttpService from '@/utils/services/http-service';

import { AxiosResponse } from 'axios';
import { replacePlaceHolderWithData } from '@/utils/common/dashboard-service-utils';
import { modifyPayloadFor3p } from '@/components/pages/insights/amazon/market-share/utils';
import {
  V2Metrics,
  V1Metrics
} from '@/components/pages/insights/amazon/market-share/types/filters';

export interface DataApiRequestFormat<OperationsFormat> {
  page?: string;
  widget?: string;
  entityType?: string;
  metricsList?: string[];
  dimensionsList?: string[];
  eventsList?: any[];
  enablePaginationCount?: boolean;
  operations?: OperationsFormat;
  where?: Where;
  bundleDataGroupsRequest?: BundleDataGroupsRequest;
}

export interface Operations {
  getSharePercentageV2?: boolean;
  getSharePercentage?: boolean;
  enableNewPVPFormulaForSOV?: boolean;
  sharePercentageV2?: boolean;
  timeseriesEnabled?: boolean;
  timeseriesDimension?: string;
  pvpenabled?: boolean;
  enableDedupBeforeRollup?: boolean;
  timeseriesRollupBy?: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions?: string[];
  orderByList?: OrderByList[];
  outerWhereClause?: {
    dimensionNameValueList?: DimensionNameValueList[];
  };
  limit?: number;
}

export interface OrderByList {
  dimension: string;
  direction: string;
}

export interface Where {
  dimensionNameValueList: DimensionNameValueList[];
  date: Date;
  pvpDate: PvpDate;
  excludeDimensionsFromSharePercentage?: any[];
}

export interface DimensionNameValueList {
  dimensionName: string;
  dimensionValue: string | boolean;
  operator?: string;
}

export interface Date {
  from: string;
  to: string;
}

export interface PvpDate {
  from: string;
  to: string;
}

export interface BundleDataGroupsRequest {
  [key: string]: any;
}

export interface MarketshareCategoryShareDg {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: string[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations2;
  where: Where2;
}

export interface Operations2 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList2[];
  limit: number;
}

export interface OrderByList2 {
  dimension: string;
  direction: string;
}

export interface Where2 {
  dimensionNameValueList: any[];
  date: Date2;
  pvpDate: PvpDate2;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date2 {
  from: string;
  to: string;
}

export interface PvpDate2 {
  from: string;
  to: string;
}

export interface MarketshareCategorySizeDg {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: any[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations3;
  where: Where3;
}

export interface Operations3 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: any[];
}

export interface Where3 {
  dimensionNameValueList: any[];
  date: Date3;
  pvpDate: PvpDate3;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date3 {
  from: string;
  to: string;
}

export interface PvpDate3 {
  from: string;
  to: string;
}

export interface CategoryPageMarketShareBreakdown {
  entityData: EntityDaum[];
  cacheInfo: CacheInfo;
}

export interface EntityDaum {
  success: boolean;
  entityType: string;
  entityValue: string;
  data: Daum[];
  [key: string]: any; // TODO: Make it generic
}

export interface Daum {
  name: string;
  alias: string;
  type: string;
  PVP: Pvp;
  RESULT: Result;
  TIMESERIES: null;
  SPLY: null;
}

export interface Pvp {
  PVP_SALES_ESTIMATES_share_percentage: number;
  PVP_DIFF_SALES_ESTIMATES_share_percentage: number;
  SALES_ESTIMATES_share_percentage: number;
  [key: string]: number;
}

export interface Result {
  client_flag: string;
  brand: string;
  manufacturer: string;
  SALES_ESTIMATES_share_percentage?: number;
  [key: string]: any; // TODO: Make it generic
}

export interface CacheInfo {
  cacheStatus: string;
  hash: string;
}

export interface OrderBy {
  dimension: string;
  direction: 'ASC' | 'DESC';
}

export interface PieChartWidgetInputParams {
  variableReplace: {
    ':entityType': 'brand' | 'manufacturer';
  };
  substitute: {
    [key: string]: unknown;
  };
  template: V2Metrics | V1Metrics;
  displayShareIn: string;
}

export interface CategoryShareAggregateInputParams {
  variableReplace?: {
    ':from': string;
    ':to': string;
    ':pvpFrom': string;
    ':pvpTo': string;
  };
  substitute: {
    [key: string]: unknown;
  };
  template: 'SALES_ESTIMATES_SIZE' | 'UNIT_ESTIMATES_SIZE';
}

export interface InputParamsDataApi {
  variableReplace?: Record<string, unknown>;
  substitute: {
    [key: string]: unknown;
  };
}

export interface CategoryShareDownloadInputParams {
  variableReplace?: { [key: string]: unknown };
  substitute: {
    [key: string]: unknown;
  };
  template: string;
}

export interface OperationsCategorySize {
  enableNewPVPFormulaForSOV?: boolean;
  getSharePercentage?: boolean;
  getSharePercentageV2?: boolean;
  timeseriesEnabled?: boolean;
  timeseriesDimension?: string;
  pvpenabled?: boolean;
  enableDedupBeforeRollup?: boolean;
  timeseriesRollupBy?: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions?: string[];
  orderByList?: any[];
}

export interface DataApiResponse {
  entityData: EntityData[];
  cacheInfo: CacheInfo;
}

export interface EntityData {
  success: boolean;
  entityType: string;
  data: ApiData[];
  entityValue: string;
}

export interface ApiData {
  name: string;
  alias: string;
  type: string;
  PVP: Record<string, number>;
  RESULT: Record<string, unknown>;
  TIMESERIES: any; // TODO: @rhitik.b@commerceiq.ai Tightly type the timeseries and SPLY
  SPLY: any;
}

export interface SkuCheckBox {
  your: boolean;
  comp: boolean;
}
export interface CacheInfo {
  cacheStatus: string;
  hash: string;
}

function set<T>(obj: T, path: string, value: unknown): T {
  var schema: any = obj; // a moving reference to internal objects within obj
  var pList = path.split('.');
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
  return obj;
}

export function constructRequest<T>(
  data: T,
  substitutionsReplacements: {
    substitute: {
      [key: string]: unknown;
    };
    variableReplace?: { [key: string]: unknown };
  }
) {
  // TODO: This should be a generic method
  let obj = data;
  if (substitutionsReplacements.variableReplace != null) {
    obj = replacePlaceHolderWithData(
      data,
      substitutionsReplacements.variableReplace as unknown as Record<
        string,
        unknown
      >
    );
  }
  Object.keys(substitutionsReplacements.substitute).forEach((key) => {
    obj = set(obj, key, substitutionsReplacements.substitute[key]); // TODO: rhitik.b this should be generic
  });
  return obj;
}

export abstract class DataApi<DataFormat, OperationsFormat> {
  abstract generateRequestBody(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: any
  ): DataApiRequestFormat<Operations>;

  async getData(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: InputParamsDataApi,
    apiPath: string,
    service: string
  ): Promise<AxiosResponse<DataFormat>> {
    const requestCopy = this.generateRequestBody(widgetRequest, inputParams);
    modifyPayloadFor3p(requestCopy);
    return await HttpService.post(service || 'DASHBOARD_SERVICE', requestCopy, {
      append: apiPath
    });
  }

  static generateRequestBody(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: any
  ): DataApiRequestFormat<Operations> {
    return {
      ...(inputParams.variableReplace === undefined
        ? widgetRequest
        : replacePlaceHolderWithData(
            widgetRequest,
            inputParams.variableReplace as unknown as Record<string, unknown>
          )),
      ...inputParams.substitute
    };
  }
}
export class PieChartCategoryPageDataApi extends DataApi<
  CategoryPageMarketShareBreakdown,
  Operations
> {
  generateRequestBody(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: any
  ): DataApiRequestFormat<Operations> {
    return constructRequest(widgetRequest, inputParams);
  }
  // static generateRequestBody(widgetRequest: DataApiRequestFormat<Operations>, inputParams: PieChartWidgetInputParams): DataApiRequestFormat<Operations> {
  //   return constructRequest(widgetRequest, inputParams);
  // }
}

export class CategorySizeCategoryPageDataApi extends DataApi<
  DataApiResponse,
  OperationsCategorySize
> {
  generateRequestBody(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: any
  ): DataApiRequestFormat<Operations> {
    return {
      ...(inputParams.variableReplace === undefined
        ? widgetRequest
        : replacePlaceHolderWithData(
            widgetRequest,
            inputParams.variableReplace as unknown as Record<string, unknown>
          )),
      ...inputParams.substitute
    };
  }
}

export class BenchmarkWidget extends DataApi<
  DataApiResponse,
  OperationsCategorySize
> {
  // generateRequestBody(widgetRequest: DataApiRequestFormat<Operations>, inputParams: any): DataApiRequestFormat<Operations> {
  //   return {
  //     ...inputParams.variableReplace === undefined ? (widgetRequest) : (replacePlaceHolderWithData(
  //       widgetRequest,
  //       inputParams.variableReplace as unknown as Record<string, unknown>
  //     )),
  //     ...inputParams.substitute,
  //   };
  // }
  generateRequestBody(
    widgetRequest: DataApiRequestFormat<Operations>,
    inputParams: any
  ): DataApiRequestFormat<Operations> {
    return constructRequest(widgetRequest, inputParams);
  }
}
