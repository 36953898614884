import {
  baseMetricsList,
  selectedMetrics,
  shownMetrics,
  placementBase,
  platformBase,
  nonWaterfallMetrics,
  incrementalMetrics
} from './config';
const campiagnsMetrics = () => {
  const baseMetrics = baseMetricsList();
  keysEditWrapper(baseMetrics, {
    aliasKey: 'aggregated_'
  });
  return baseMetrics;
};

const nestedStructure = (baseConfig, metricGroup, keys) => {
  const { aliasKey, pathKey } = keys;
  setArrayObjectKey(baseConfig, 'metricGroup', metricGroup);
  baseConfig.forEach((item) => {
    const childMetric = keyPrependedMetricsList({
      aliasKey: `${aliasKey}${item.aliasPrefix}_`,
      pathKey: `${pathKey} - ${item.labelPrefix}: `,
      labelKey: `${item.labelPrefix} `
    });
    item.children = childMetric;
  });
  const spreadChildren = getSpreadChildren(baseConfig);
  return [...baseConfig, ...spreadChildren];
};

const keysEditWrapper = (array, prependValue) => {
  const { aliasKey, pathKey, labelKey } = prependValue;
  prependToKey(array, 'alias', aliasKey);
  prependToKey(array, 'key', pathKey);
  prependToKey(array, 'label', labelKey);
  prependToKey(array, 'tooltip', pathKey);
  prependToKey(array, 'title', pathKey);
  array.forEach((item) => (item.metricKey = item.alias));
};

const keyPrependedMetricsList = (prependValue) => {
  const metricsList = baseMetricsList();
  keysEditWrapper(metricsList, prependValue);
  return metricsList;
};

const setArrayObjectKey = (array, key, value) => {
  array.forEach((item) => (item[key] = value));
};

const prependToKey = (array, key, prefix) => {
  array.forEach((item) => {
    if (prefix) {
      item[key] = prefix + item[key];
    }
  });
};

const getSpreadChildren = (array) => {
  return array
    .map((item) =>
      item.children.map((child) => {
        child.waterfall = true;
        return child;
      })
    )
    .reduce((acc, item) => {
      acc.push(...item);
      return acc;
    }, []);
};

export default (retailer) => {
  const selectedMetricsList = selectedMetrics();
  keysEditWrapper(selectedMetricsList, {
    aliasKey: 'aggregated_'
  });
  const showMetricsList = shownMetrics();
  keysEditWrapper(showMetricsList, {
    aliasKey: 'aggregated_'
  });
  const platformBaseConfig = platformBase();
  const placementBaseConfig = placementBase();
  const nonWaterfallMetricsList = nonWaterfallMetrics(retailer);

  keysEditWrapper(nonWaterfallMetricsList, {
    aliasKey: 'aggregated_'
  });
  const metricsList = [
    ...campiagnsMetrics(),
    ...nonWaterfallMetricsList,
    ...incrementalMetrics('Campaigns'),
    ...nestedStructure(placementBaseConfig, 'Placement', {
      aliasKey: 'placement_',
      pathKey: 'Placement '
    }),
    ...nestedStructure(platformBaseConfig, 'Platform', {
      aliasKey: 'platform_',
      pathKey: 'Platform '
    })
  ];
  return {
    metricsList,
    metricsShown: showMetricsList,
    defaultSelectMetric: selectedMetricsList
  };
};
