<template>
  <div class="widget--full-screen u-display-flex u-flex-direction-column">
    <div class="widget__header u-flex-none">
      <div
        class="u-display-flex u-flex-align-items-flex-start u-spacing-ph-m u-spacing-pv-l"
      >
        <div
          class="u-flex-none u-spacing-pt-xs u-cursor-pointer"
          @click="closeModal"
        >
          <rb-icon :icon="'cross'" />
        </div>
        <div class="widget__header--title u-flex-1 u-spacing-pl-m">
          <div class="u-font-size-1 u-font-weight-bold">
            {{ $t('removed_recommendations') }}
          </div>
          <div class="u-font-size-4 u-spacing-pt-s u-color-grey-lighter">
            {{ $t('restore_removed_recommendations') }}
          </div>
        </div>
      </div>
    </div>
    <div class="widget__body u-display-flex u-flex-1">
      <div class="panel--left rb-tab rb-tab--vertical-nav u-flex-none">
        <div
          v-for="(item, index) in recommendationTypes"
          :key="index"
          class="rb-tab__item"
          :class="{
            active: item.recommendationType === selectedRecommendationType
          }"
          @click="selectRecommendationType(item)"
        >
          <div class="rb-tab-item__label u-width-100 u-display-flex">
            <div class="u-width-80">
              <div class="u-max-width-100">
                <div
                  :title="item.recommendationLabel"
                  class="u-text-overflow-ellipsis"
                >
                  {{ item.recommendationLabel }}
                </div>
              </div>
            </div>
            <div class="u-flex-1 u-text-align-right">
              ({{ item.recommendationCount }})
            </div>
          </div>
        </div>
      </div>
      <div
        id="restore-table-container"
        class="panel--right u-width-100 u-display-flex"
      >
        <Loader
          :loading="restoreAlertLoader"
          class="fill--parent"
          :color="'#3fadf7'"
          :size="'4rem'"
          :thickness="'.2rem'"
        />
        <div
          v-if="!restoreAlertLoader"
          class="u-width-100"
        >
          <el-table
            v-if="removedRecommendations"
            empty-text="No SKUs Found"
            class="el-table--scrollable-x el-table--scrollable-y"
            :height="tableHeight()"
            :data="removedRecommendations || {}"
          >
            <el-table-column
              :label="'SKU Details'"
              :render-header="appendTip"
              :sortable="false"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-align-items-center"
              >
                <div
                  style="height: 56px; width: 56px; margin: 0px"
                  class="media__img u-flex-none"
                >
                  <img
                    style="height: 56px; width: 56px"
                    :src="scope.row.image_url"
                  />
                </div>
                <div
                  class="u-spacing-ml-m u-display-flex u-flex-direction-column u-text-overflow-ellipsis"
                >
                  <div
                    :title="scope.row.title"
                    class="u-text-overflow-ellipsis u-color-grey-base u-font-size-5 u-text-case-title"
                  >
                    {{ scope.row.title }}
                  </div>
                  <a
                    target="_blank"
                    :href="scope.row.product_url"
                  >
                    <span
                      class="link--asin u-text-overflow-ellipsis u-text-case-title u-color-grey-lighter u-font-size-5"
                    >
                      {{ scope.row.key }}</span
                    >
                  </a>
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :sortable="false"
              :render-header="appendTip"
              :width="200"
              :label="'Reason'"
            >
              <div slot-scope="scope">
                <div :title="scope.row.reason">
                  {{ scope.row.reason }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :sortable="false"
              :render-header="appendTip"
              :width="200"
              :label="'Removed By'"
            >
              <div slot-scope="scope">
                <div :title="scope.row.username">
                  {{ scope.row.username }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :sortable="false"
              :render-header="appendTip"
              :width="200"
              :label="'Removed On'"
            >
              <div slot-scope="scope">
                <div>
                  {{
                    new Date(scope.row.feed_date)
                      .toLocaleString('en-US', { timeZone: 'UTC' })
                      .split(',')[0] | formattable_date
                  }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :sortable="false"
              :render-header="appendTip"
              :width="200"
              :label="'Action'"
            >
              <div slot-scope="scope">
                <rb-button
                  :text="'Restore'"
                  :render-header="appendTip"
                  :type="'filled'"
                  :size="'s'"
                  :click-fn="
                    restoreSelectedRecommendation.bind(this, scope.row)
                  "
                />
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    Loader
  },
  props: {
    applyFn: {
      type: Function,
      default: null
    },
    closeFn: {
      type: Function,
      default: null
    },
    recName: {
      type: String
    },
    recType: {
      type: String
    }
  },
  data() {
    return {
      removedRecommendations: [],
      selectedRecommendationName: null,
      selectedRecommendationType: null,
      tooltips: [
        'SKU Details: Title and ASIN of the removed SKU',
        'Reason: Reason entered while removing the SKU',
        'Removed by: ID of user who removed the SKU.',
        'Removed on: Date of removal',
        'Action: Each SKU can be restored to your recommendation feed by clicking on restore. The SKU will start appearing if it meets the recommendation criteria.'
      ]
    };
  },
  computed: {
    recommendationTypes() {
      return this.$store.getters.getRemovedRecommendationTypes;
    },
    restoreAlertLoader() {
      return this.$store.getters.getRestoreAlertLoader;
    }
  },
  created() {
    this.selectedRecommendationName = this.recName;
    this.selectedRecommendationType = this.recType;
    this.$store
      .dispatch('fetchRemovedRecommendations', {
        recommendationName: this.selectedRecommendationName,
        recommendationType: this.selectedRecommendationType
      })
      .then((removedRecommendations) => {
        this.removedRecommendations = removedRecommendations;
      });
  },
  methods: {
    tableHeight() {
      if (document.getElementById('restore-table-container')) {
        return document.getElementById('restore-table-container').clientHeight;
      } else {
      }
    },
    appendTip(h, options) {
      var toolTipText = '';
      if (options) {
        toolTipText = this.tooltips[options.$index];
      }
      return renderColumnHeaders.renderHeader(
        h,
        options.column,
        true,
        toolTipText
      );
    },
    restoreSelectedRecommendation(alertObject) {
      this.$store.dispatch('loadRecommendationsForRemoval', alertObject);
      const params = {
        alertType: this.selectedRecommendationType,
        alertName: this.selectedRecommendationName,
        bc_recommendation_type: alertObject.bc_recommendation_type,
        key:
          alertObject.bc_recommendation_type === 'asin'
            ? alertObject.key
            : alertObject.search
      };
      this.applyFn(params).then(() => {
        // refresh the modal with updated values
        this.$store
          .dispatch('fetchRemovedRecommendations', {
            recommendationName: this.selectedRecommendationName,
            recommendationType: this.selectedRecommendationType
          })
          .then((removedRecommendations) => {
            this.removedRecommendations = removedRecommendations;
            this.$snackbar.open({
              message: 'SKU restored successfully',
              duration: 3000,
              actionText: ' '
            });
          });
      });
    },
    closeModal() {
      this.closeFn();
    },
    selectRecommendationType(alertTypeObject) {
      this.selectedRecommendationType = alertTypeObject.recommendationType;
      this.selectedRecommendationName = alertTypeObject.recommendationName;
      this.$store
        .dispatch('fetchRemovedRecommendations', {
          recommendationName: this.selectedRecommendationName,
          recommendationType: this.selectedRecommendationType
        })
        .then((removedRecommendations) => {
          this.removedRecommendations = removedRecommendations;
        });
    }
  }
};
</script>

<style lang="css" scoped>
.widget__header {
  box-shadow: 2px 1px 4px 0 #e9eaeb;
  z-index: 11;
}

.panel--left {
  background-color: white;
  box-shadow: 1px 2px 4px 0 #e9eaeb;
  z-index: 10;
  width: 176px;
  overflow-y: auto;
}

.list__item.active {
  background: #e5f1fe;
}
.list__item .list__item--text {
  line-height: 20px;
}
</style>
