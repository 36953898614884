<template>
  <div
    class="u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-flex-start"
    @click.stop
  >
    <div
      v-tippy="{
        html: '#' + uniqueCellId,
        reactive: true,
        placement: 'top-start',
        offset: '-8, -50',
        delay: [500, 0],
        animation: 'scale',
        interactive: true,
        maxWidth: '200px',
        theme: 'click-to-expand',
        duration: [150, 0]
      }"
      class="cell--content u-text-overflow-ellipsis u-flex-shrink-past-min-size"
      data-cy="dataCellValue"
    >
      <span v-if="parsedValue">
        <span v-if="parsedValue.rank">#{{ parsedValue.rank }}</span>
        <span v-if="parsedValue.category">
          in
          <a
            v-for="(item, index) in parsedValue.category"
            :key="index"
            target="_blank"
            class="href--link"
            :href="parsedValue.category_url && parsedValue.category_url[index]"
            >{{ item }} <span v-if="isNotLast(index)"> > </span>
          </a>
        </span>
      </span>
      <span v-else>NA</span>
    </div>
    <div :id="uniqueCellId">
      <span v-if="parsedValue">
        <span v-if="parsedValue.rank">#{{ parsedValue.rank }}</span>
        <span v-if="parsedValue.category">
          in
          <a
            v-for="(item, index) in parsedValue.category"
            :key="index"
            target="_blank"
            class="href--link"
            :href="parsedValue.category_url && parsedValue.category_url[index]"
            >{{ item }} <span v-if="isNotLast(index)"> > </span>
          </a>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableLinkCell',
  computed: {
    uniqueCellId() {
      return this.params.column.colId + '_' + this.params.node.id;
    },
    parsedValue() {
      var currObj = this.params.value || '{}';
      return JSON.parse(currObj);
    }
  },
  mounted() {
    // remove the click to expand feature if no ellipsis is present
    this.$nextTick(() => {
      let toExpand = false;
      const el = this.$el.querySelector('.cell--content');
      if (el.clientWidth < el.scrollWidth) {
        toExpand = true;
      }
      if (!toExpand) {
        el._tippy.destroy();
        el.classList.add('u-cursor-text');
      } else {
        el.classList.add('u-cursor-pointer');
      }
    });
  },
  methods: {
    isNotLast(index) {
      if (
        this.parsedValue &&
        this.parsedValue.category &&
        this.parsedValue.category.length
      ) {
        return index !== this.parsedValue.category.length - 1;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.href--link {
  font-weight: normal;
}
.href--link:hover {
  font-weight: 600;
}
</style>
