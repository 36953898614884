import HomeV2 from '@/components/homeV2.vue';
import crossRetailerOverview from '@/pages/all_retailers/crossRetailerTableau/index.vue';

const omniRoutes = {
  path: '/us/all/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect: 'cross-retailer-overview'
    },
    {
      path: 'cross-retailer-overview',
      name: 'cross-retailer-overview',
      component: crossRetailerOverview,
      props: {
        pageName: 'Omnichannel_cross_retailer_overview'
      }
    }
  ]
};

export { omniRoutes };
