<template>
  <div
    ref="ratingEl"
    class="rb-star-rating"
    :class="{ readonly: readonly, notouch: notouch }"
    :style="mapCssProps"
  >
    <input
      :id="name + '0'"
      type="radio"
      :checked="value === 0"
      :name="name"
      value="0"
    />
    <template v-for="x in max">
      <label
        :key="'l' + x"
        :for="name + x"
      >
        <span class="active"
          ><slot name="activeLabel">{{ getActiveLabel(x) }}</slot></span
        >
        <span class="inactive"
          ><slot name="inactiveLabel">{{ getInactiveLabel(x) }}</slot></span
        > </label
      ><input
        :id="name + x"
        :key="'i' + x"
        type="radio"
        :checked="value === x"
        :name="name"
        :disabled="readonly"
        :value="x"
        @change="updateInput($event.target.value)"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'RbStarRating',
  props: {
    max: { type: Number, required: false, default: 5 },
    value: { type: Number, required: false, default: 0 },
    name: { type: String, required: false, default: 'rating' },
    char: { type: String, required: false, default: '★' },
    inactiveChar: { type: String, required: false, default: null },
    readonly: { type: Boolean, required: false, default: false },
    activeColor: { type: String, required: false, default: '#FD0' },
    inactiveColor: { type: String, required: false, default: '#999' },
    shadowColor: { type: String, required: false, default: '#FF0' },
    hoverColor: { type: String, required: false, default: '#DD0' }
  },
  computed: {
    ratingChars() {
      return Array.from(this.char);
    },
    inactiveRatingChars() {
      /* Default to ratingChars if no inactive characters have been provided */
      return this.inactiveChar
        ? Array.from(this.inactiveChar)
        : this.ratingChars;
    },
    notouch() {
      /* For iPhone specifically but really any touch device, there is no true hover state, disabled any pseudo-hover activity. */
      return !('ontouchstart' in document.documentElement);
    },
    mapCssProps() {
      return {
        '--active-color': this.activeColor,
        '--inactive-color': this.inactiveColor,
        '--shadow-color': this.shadowColor,
        '--hover-color': this.hoverColor
      };
    }
  },
  methods: {
    updateInput(v) {
      this.$emit('input', parseInt(v, 10));
    },
    getActiveLabel(x) {
      const s = this.ratingChars;
      return s[Math.min(s.length - 1, x - 1)];
    },
    getInactiveLabel(x) {
      const s = this.inactiveRatingChars;
      return s[Math.min(s.length - 1, x - 1)];
    }
  }
};
</script>
<style scoped lang="css">
.rb-star-rating {
  display: inline-flex;
  flex-wrap: nowrap;
  margin-top: -3px;
}
.rb-star-rating label {
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.rb-star-rating label span {
  display: block;
}
.rb-star-rating input,
.rb-star-rating label .inactive,
.rb-star-rating input:checked ~ label .active,
.rb-star-rating .notouch:not(.readonly):hover label .inactive,
.rb-star-rating .notouch:not(.readonly) label:hover ~ label .active {
  display: none;
}
.rb-star-rating input:checked ~ label .inactive,
.rb-star-rating .notouch:not(.readonly):hover label .active,
.rb-star-rating .notouch:not(.readonly) label:hover ~ label .inactive {
  display: inline;
}
.rb-star-rating.notouch:not(.readonly):hover label {
  color: #dd0;
  text-shadow: 0 0 0.3em #ff0;
}
.rb-star-rating input:checked ~ label,
.rb-star-rating.notouch:not(.readonly) label:hover ~ label {
  color: #999;
  text-shadow: none;
}
@supports (color: var(--prop)) {
  .rb-star-rating label {
    color: var(--active-color);
    text-shadow: 0 0 0.3em var(--shadow-color);
  }
  .rb-star-rating.notouch:not(.readonly):hover label {
    color: var(--hover-color);
    text-shadow: 0 0 0.3em var(--shadow-color);
  }
  .rb-star-rating input:checked ~ label,
  .rb-star-rating.notouch:not(.readonly) label:hover ~ label {
    color: var(--inactive-color);
  }
}
</style>
