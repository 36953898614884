import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import StrategyBuilder from '@/components/pages/strategy_builder/strategyBuilder.vue';
import StrategyList from '@/components/pages/strategy_list/strategyList.vue';
import ProxyLinks from '@/components/pages/ciq-internal-tools/proxy-links/index.vue';
import CampaignManagementWrapper from '@/pages/campaign-management/campaign-management-wrapper.vue';
import DashboardServiceCMRouteWrapper from '@/pages/dashboard_service_campaign_management/DashboardServiceCMRouteWrapper.vue';
import Strategies from '@/components/pages/strategies/strategies.vue';
import CreateStrategy from '@/components/pages/strategies/create/createStrategy.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import CONSTANTS from '@/utils/constants';

export const digitalShelfRoute = {
  path: 'digital_shelf',
  name: 'digital_shelf',
  redirect() {
    return 'digital_shelf/market_insights';
  },
  component: DigitalShelfLanding,
  children: DigitalShelfRoutes.routes
};

export const reportsRoute = {
  path: 'reports',
  component: ReportsLanding,
  children: ReportsRoutes.routes
};

export const homeRoute = {
  path: '',
  name: 'Home',
  redirect: undefined
};

export const advertisingRoute = {
  path: 'advertising',
  name: 'advertising',
  redirect: undefined,
  component: AdvertisingLanding,
  children: []
};

export const campaignOptimizerChildrenRoutes = [
  {
    path: '',
    name: 'StrategyList',
    title: 'Campaign Optimizer',
    component: StrategyList
  },
  {
    path: 'create',
    name: 'StrategyBuilder',
    component: StrategyBuilder
  },
  {
    path: 'edit',
    name: 'StrategyBuilderEdit',
    component: StrategyBuilder
  }
].map((route) => ({
  ...route,
  meta: { productLine: CONSTANTS.PRODUCT_LINE.RMM }
}));

export const getCampaignManagementChildrenRoutes = (retailer) => {
  const routes = [
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_campaigns`
    },
    {
      path: 'line_items',
      name: 'Line Items',
      title: 'Line Items',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_line_items`
    },
    {
      path: 'ad_skus',
      name: 'AMS SKUs',
      title: 'Ad SKUs',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_ad_skus`
    },
    {
      path: 'search_terms',
      name: 'Search Terms',
      title: 'Search Terms',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_search_terms`
    },
    // temporary hotfix since keywords tab is breaking for these retailers
    ...(['walgreen_criteo', 'cvs_criteo'].includes(retailer)
      ? []
      : [
          {
            path: 'keywords',
            name: 'Keywords',
            title: 'Keywords',
            component: CampaignManagementWrapper,
            productRoles: ['MarketingIQ'],
            page: 'campaignManagement',
            config: `${retailer}_keywords`
          }
        ])
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM
    }
  }));
  routes.push({
    path: '',
    redirect: () => {
      return 'campaigns';
    }
  });
  return routes;
};

export const getCampaignManagementChildrenRoutesWithTabConfig = (retailer) => {
  const routes = [
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_citrus_campaigns`,
      props: () => ({
        tabName: 'Campaigns',
        configKey: `${retailer}_citrus`
      })
    },
    {
      path: 'ad_skus',
      name: 'AMS SKUs',
      title: 'Ad SKUs',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_citrus_ad_skus`,
      props: () => ({ tabName: 'Ad SKUs', configKey: `${retailer}_citrus` })
    },
    {
      path: 'keywords',
      name: 'Keywords',
      title: 'Keywords',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_citrus_keywords`,
      props: () => ({
        tabName: 'Keywords',
        configKey: `${retailer}_citrus`
      })
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM
    }
  }));

  routes.push({
    path: '',
    redirect: () => {
      return 'campaigns';
    }
  });

  return routes;
};

export const proxyLinksRoute = {
  path: 'proxy_links',
  name: 'proxy_links',
  component: ProxyLinks
};

export const hourlyBidderRoutes = [
  {
    path: 'hourly_bidder',
    component: PassThroughRoute,
    props: true,
    children: [
      {
        path: '',
        name: 'strategies',
        title: 'Hourly Bidder',
        component: Strategies,
        props: true,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.RMM,
          appendEsDataSetName: true
        }
      },
      {
        path: 'create',
        name: 'create-strategy',
        component: CreateStrategy,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.RMM,
          appendEsDataSetName: true
        }
      },
      {
        path: 'edit',
        name: 'edit-strategy',
        component: CreateStrategy,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.RMM,
          appendEsDataSetName: true
        }
      }
    ]
  }
];
