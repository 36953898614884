<template>
  <div class="create-budget-plan widget--full-screen">
    <!-- Header Start -->
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="closeModal"
    >
      <div
        slot="header"
        class="u-text-align-center u-font-size-1 u-font-weight-600 u-width-100 u-spacing-pt-xxl"
      >
        Budget plan will not be saved.
      </div>
    </modal>
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="isDashboardLoading"
      style="z-index: 103"
      :color="'#007cf6'"
    />
    <section
      class="createBudgetPlan--header u-display-flex u-flex-align-items-center u-spacing-p-l"
    >
      <div
        class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-width-100"
      >
        <div class="u-display-flex u-flex-align-items-center u-width-100">
          <span @click="openModal()">
            <rb-icon
              :icon="'cross'"
              class="u-color-grey-lighter rb-icon--x-medium u-cursor-pointer u-spacing-mr-s"
            />
          </span>
          <input
            v-model="budgetPlanTitle"
            type="text"
            maxlength="56"
            :class="[
              budgetPlanTitle !== 'Untitled Budget Plan' || isTitleInputClicked
                ? 'active-state'
                : ' u-color-grey-lighter'
            ]"
            class="heading--input u-text-overflow-ellipsis u-font-size-1 u-font-weight-600"
            onClick="this.select();"
            @click="titleInputClicked()"
          />
        </div>
        <input
          v-model="budgetPlanDesc"
          type="text"
          maxlength="160"
          :placeholder="placeholder"
          class="heading--input budget-plan-desc u-font-size-4 u-spacing-pt-s u-spacing-ml-xl"
        />
      </div>
    </section>
    <!-- Header ends -->
    <StepComponent
      :enabled-bread-crumbs="enabledBreadCrumbs"
      :set-active-selection="setActiveSelection"
      :show-step-bullet-description="false"
      :module-class="'budgetStep'"
      :config="stepsConfig"
      :all-steps-data="{ budgetPlanTitle, budgetPlanDesc }"
    />
  </div>
</template>

<script>
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import UploadStep from './steps/uploadStep/index';
import CumulativeStep from './steps/cumulativeStep/index';
import PreviewStep from './steps/previewStep/index';
import AutomationStep from './steps/automationStep/index';
import loader from '@/components/basic/loader';
import stepsMixin from './steps/stepMixin';
import modal from '@/components/widgets/modal.vue';
import moment from 'moment-timezone';

export default {
  components: {
    StepComponent,
    loader,
    UploadStep,
    CumulativeStep,
    PreviewStep,
    AutomationStep,
    modal
  },
  mixins: [stepsMixin],
  data() {
    const thisYear = new Date().getFullYear();
    return {
      enabledBreadCrumbs: [0],
      modalConfig: {
        body: {
          text: 'To apply your latest changes, please create your budget plan.'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showModal = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Exit',
            onClick: () => {
              this.closeWithoutSave();
            },
            icon: '',
            show: true
          }
        }
      },
      showModal: false,
      isTitleInputClicked: false,
      placeholder: 'Give this budget plan a description.',
      stepsConfig: [
        {
          name: 'Upload Step',
          header: {
            title: `What's your budget plan for ${
              this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear
            }?`,
            desc: [
              'Download the budget plan template, enter your budget plan and upload the template.'
            ]
          },
          keyToRead: 'uploadStep',
          breadCrumbTitle: `Upload budget plan for ${
            this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear
          }.`,
          textMapping: 'uploadStep',
          component: UploadStep
        },
        {
          name: 'Preview Step',
          keyToRead: 'previewStep',
          breadCrumbTitle:
            'Monthly and quarterly distribution accross profiles and portfolios.',
          textMapping: 'previewStep',
          component: PreviewStep
        },
        {
          name: 'Comulative Graph',
          header: {
            title:
              'Cumulative budget plan for the year ' +
              `${this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear}`
          },
          breadCrumbTitle:
            'Cumulative budget plan for the year ' +
            `${this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear}` +
            '.',
          keyToRead: 'uploadStep',
          textMapping: 'uploadStep',
          component: CumulativeStep
        }
      ]
    };
  },
  computed: {
    isDashboardLoading() {
      return this.$store.getters.isDashboardLoading;
    },
    setActiveSelection() {
      return this.$store.getters.getCreatePlanActiveSelection;
    },
    budgetPlanExists() {
      let planExists = false;
      if (!this.$route?.query?.nextYearPlan) {
        planExists = !!this.$store.getters.getBudgetPlannerStrategies[0];
      }
      return planExists;
    }
  },
  watch: {
    budgetPlanExists: {
      handler(newVal) {
        if (newVal) {
          this.$router.push({
            name: 'edit-budget-plan',
            query: { mode: 'edit' }
          });
        }
      },
      immediate: true
    }
  },
  created() {
    if (this?.$route?.query?.nextYearPlan) {
      const today = moment();
      const isValidDayForNextYearPlan =
        today.month() === 11 && today.dayOfYear() !== 365;
      if (!isValidDayForNextYearPlan) {
        this.closeWithoutSave();
        return;
      }
      const nextYear = new Date().getFullYear() + 1;
      this.$store.dispatch('fetchBudgetPlanNextYearStrategies', nextYear);
    } else if (!this.budgetPlanExists) {
      this.$store.dispatch('fetchBudgetPlanStrategies');
    }
    if (this.$store.getters.getRetailer === 'amazon') {
      // fetch current month budget only for amazon
      this.$store.dispatch('fetchCurrentMonthBudget');
    }
    this.$store.dispatch('fetchCurrentMonthBudgetSpend');
  },
  methods: {
    closeModal(val) {
      if (val) {
        this.showModal = false;
      }
    },
    openModal() {
      if (this.isBudgetPlanModified) {
        if (this.budgetPlanExists) {
          this.modalConfig.body.text =
            'To apply your latest changes, please update your budget plan.';
        }
        this.showModal = true;
      } else {
        this.closeWithoutSave();
      }
    },
    closeWithoutSave() {
      this.$store.dispatch('resetBudgetPlan');
      this.$router.push({
        name: 'budget-planner-dashboard'
      });
    },
    titleInputClicked() {
      this.isTitleInputClicked = true;
    }
  }
};
</script>
<style lang="css">
.create-budget-plan .step-component-v2 .scrollable {
  height: 100vh;
  padding-top: 108px;
}
</style>

<style lang="css" scoped>
.heading--input {
  width: 640px;
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}

.budget-plan-desc {
  font-style: italic;
}

.create-budget-plan {
  height: 100%;
  width: 100%;
  top: 0;
  overflow: hidden;
  z-index: 103;
}
.createBudgetPlan--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: fixed;
  width: 100%;
  z-index: 101;
}
</style>
