import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import urlHelper from '@/utils/helpers/url';
import utils from '@/utils/helpers/';
import customReportsUtils from '@/components/pages/insights/customReports/utils';
import { findIndex } from 'lodash';

const customReportsConfig = require('@/components/pages/insights/customReports/widgets.json');
const logger = require('@/utils/helpers/logger').default;

const getState = () => {
  return {
    client_dashboards: [],
    client_dashboards_from_config: [],
    // version 2 is 2020/2023 version
    // going to deprecate this version. value not going to be used.
    version: 2,
    // going to deprecate this key. value not going to be used.
    tableau_user_exists: true
  };
};

const getters = {
  getCustomReportsClientDashboards: (state) => {
    if (state.version === 2) {
      return state.client_dashboards;
    }
    return state.client_dashboards_from_config;
  },
  getVersionToUse: (state) => {
    return state.version;
  },
  getIsTableauUser(state) {
    return state.tableau_user_exists;
  }
};

const mutations = {
  FETCH_CLIENT_DASHBOARDS: (state, data) => {
    Vue.set(state, 'client_dashboards', data);
  },
  SET_VERSION: (state, data) => {
    Vue.set(state, 'version', data);
  },
  FETCH_CLIENT_DASHBOARDS_FROM_CONFIGS: (state, data) => {
    Vue.set(state, 'client_dashboards_from_config', data);
  },
  SET_TABLEAU_USER_EXISTS: (state, bUserExists) => {
    Vue.set(state, 'tableau_user_exists', bUserExists);
  }
};

const actions = {
  check_and_fetch_clients_dashboard: (context, data) => {
    const dashBoardList = [];
    if (context.state.client_dashboards.length > 0) {
      return context.state.client_dashboards;
    }
    return HttpLayer.post({
      APIData: customReportsConfig
    }).then((res) => {
      const clientDashboardList = transformer.mergeResultDimension(
        res.data,
        true
      );
      clientDashboardList.forEach((clientDash, index) => {
        // during processing of dashboards before they are publihshed relatives urls will be
        // stored as -. Dont consider those dashboards
        if (!clientDash.relative_url || clientDash.relative_url === '-') {
          return;
        }
        // don't show internal only dash to end users
        if (!utils.internalUserCheck(window.user)) {
          if (clientDash.bonlyinternal) {
            return;
          }
        }
        const dashboardInfo = {
          url: '',
          name: '',
          index: 0,
          path: ''
        };
        const dashName = clientDash.template_name
          .replaceAll('.twbx', '')
          .replaceAll('.twb', '');
        dashboardInfo.url = clientDash.relative_url;
        dashboardInfo.name = dashName;
        dashboardInfo.calendarKey = clientDash.calendar_key;
        dashboardInfo.path = `${urlHelper.convertNameToUrlSection(dashName)}`;
        dashboardInfo.index = index;
        dashboardInfo.ui_page = clientDash.ui_page;
        dashboardInfo.retailer = clientDash.project_name?.toLowerCase() || '';
        dashBoardList.push(dashboardInfo);
      });
      context.commit('FETCH_CLIENT_DASHBOARDS', dashBoardList);
      return dashBoardList;
    });
  },
  fetch_trusted_token(context, username) {
    // Fall back if Tableau user check is already passed. No need to check again.
    // Optimisation so that we can call this multiple times without worrying about duplicate calls in the same session.
    const blackListedEmailSegments = ['automated-testing'];
    if (context.getters.getIsTableauUser) {
      return;
    }
    const indexOfBlackListedEmail = findIndex(
      blackListedEmailSegments,
      (blackEmailSegment) => {
        return username.includes(blackEmailSegment);
      }
    );
    if (indexOfBlackListedEmail !== -1) {
      logger.logEvent({
        eventType: 'custom_reports_token_check',
        username,
        success: true,
        skipped: true
      });
      return;
    }
    return HttpService.get('TABLEAU_GET_TRUST_TOKEN', {
      append: `?username=${username}`
    })
      .then(() => {
        context.commit('SET_TABLEAU_USER_EXISTS', true);
        logger.logEvent({
          eventType: 'custom_reports_token_check',
          username,
          success: true
        });
      })
      .catch(() => {
        context.commit('SET_TABLEAU_USER_EXISTS', false);
        logger.logEvent({
          eventType: 'custom_reports_token_check',
          username,
          success: false
        });
      });
  },
  check_and_fetch_clients_dashboard_from_config: (context, data) => {
    const dashBoardList =
      customReportsUtils.getCustomDashboardsListFromConfig();
    context.commit('FETCH_CLIENT_DASHBOARDS_FROM_CONFIGS', dashBoardList);
    return dashBoardList;
  },
  set_default_version: (context, data) => {
    context.commit('SET_VERSION', data);
  }
};

export default {
  state: getState(),
  getters,
  mutations,
  actions
};
