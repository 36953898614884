import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import tableColumns from './columnsMap.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

const asinComponent = Vue.extend({
  data() {
    return {
      showComponent: true
    };
  },
  computed: {
    asinData() {
      if (!this.params.data.parent_asin && !this.params.data.asin_name) {
        this.showComponent = false;
      }
      return {
        asin: this.params.data.parent_asin,
        title: this.params.data.asin_name,
        url: this.params.data.parent_asin_product_url
      };
    }
  },
  template: `<div class="u-display-flex u-text-overflow-ellipsis">
    <div v-if="showComponent" class="u-display-flex u-overflow-hidden u-flex-direction-column">
      <!-- <span class="u-color-grey-base u-text-overflow-ellipsis u-spacing-mb-s" v-if="(params.data.parent_asin === params.data.asin)" v-html="asinData.title"></span> -->
      <a class="rb-link link--asin" @click.stop :href="asinData.url" target="_blank" v-html="asinData.asin">
      </a>
    </div>
    <div v-if="!showComponent" class="u-display-flex u-color-grey-base"> NA </div>
    </div>
  </div>`
});

const skuComponent = Vue.extend({
  data() {
    return {
      showComponent: true,
      enableSDP: true
    };
  },
  computed: {
    rbSkuData() {
      if (
        this.params.colDef &&
        this.params.colDef.cellRendererParams &&
        this.params.colDef.cellRendererParams.keyType === 'comp_sku'
      ) {
        this.enableSDP = false;
        var compSkuNo =
          this.params.colDef.cellRendererParams.dbKey.match(/[0-9]/g)[0];
        if (
          !this.params.data['comp_sku' + compSkuNo + '_title'] &&
          !this.params.data['comp_sku' + compSkuNo]
        ) {
          this.showComponent = false;
        }
        return {
          title: this.params.data['comp_sku' + compSkuNo + '_title'],
          asin: this.params.data['comp_sku' + compSkuNo],
          productUrl: this.params.data['comp_sku' + compSkuNo + '_product_url'],
          showImage: false
        };
      }

      return {
        title: this.params.data.asin_name,
        imageUrl: this.params.data.latest_image_url,
        asin: this.params.data.asin,
        productUrl: this.params.data.asin_hyperlink,
        cacheLink: this.params.data.cache_link,
        scrapeDate: this.params.data.scrape_date,
        showImage: true
      };
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  },
  template: `<div class="u-display-flex u-text-overflow-ellipsis">
  <rb-sku v-if="showComponent" :show-image="rbSkuData.showImage" :title="rbSkuData.title" :image-url="rbSkuData.imageUrl" :asin="rbSkuData.asin" :product-page-url="rbSkuData.productUrl" @navigateToSDP="navigateToSDP" :enableSDP="enableSDP">
    <div slot="sku-context" v-if="rbSkuData.cacheLink" class="u-display-flex"><a class="link u-font-weight-500" :href="rbSkuData.cacheLink" target="_blank">Cached on {{rbSkuData.scrapeDate | formattable_date}}</a></div>
  </rb-sku>
  <div v-if="!showComponent" class="u-display-flex u-color-grey-base"> NA </div>
  </div>`
});

var columnDefs = [];
function createColDefs(columns, vueRef) {
  var _columnDefs = [];
  for (const i in columns) {
    if (columns[i].disabled || columns[i].show === false) {
      continue;
    }

    const obj = {};
    obj.field = i;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (columns[i].formatValue && columns[i].formatValue !== 'localeString') {
      if (columns[i].prefix) {
        keyType = columns[i].prefix;
        if (columns[i].width) {
          obj.minWidth = columns[i].width;
        }
      } else if (columns[i].suffix === '%') {
        keyType = 'percentage';
        obj.minWidth = columns[i].width || 125;
        obj.width = columns[i].width || 125;
      } else if (columns[i].formatValue === 'number') {
        keyType = 'number';
      } else {
        keyType = 'numeric';
        obj.minWidth = columns[i].width || 125;
        obj.width = columns[i].width || 125;
      }
    }
    if (columns[i].formatValue === 'formattable_date') {
      keyType = 'date';
    }
    obj.field = columns[i].dbKey;
    if (columns[i].enableHeaderValueGetter) {
      obj.headerValueGetter = (props) => {
        if (
          columns[i].dynamicHeaderDbKeys &&
          props.api.rowModel &&
          props.api.rowModel.rowsToDisplay &&
          props.api.rowModel.rowsToDisplay[0] &&
          props.api.rowModel.rowsToDisplay[0].data
        ) {
          const columnsHeaderKeys = columns[i].dynamicHeaderDbKeys.split(',');
          let headerColumnText = columns[i].headerTransformText;
          let nullFlag = false;
          columnsHeaderKeys.forEach((element, index) => {
            if (
              nullFlag ||
              !props.api.rowModel.rowsToDisplay[0].data[element]
            ) {
              headerColumnText = columns[i].displayName;
              nullFlag = true;
            }
            headerColumnText = headerColumnText.replace(
              `{${index + 1}}`,
              props.api.rowModel.rowsToDisplay[0].data[element]
            );
          });
          return headerColumnText;
        } else {
          // value getter are not linked to rowModel explicitly. So on data load
          // value getter might not be called. This call back will ensure that on
          // header refresh picks up the latest row data.
          setTimeout(() => {
            props.api.refreshHeader();
          }, 2000);
          return columns[i].displayName;
        }
      };
    }
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: columns[i].formatValue === false ? '' : formatter
    };
    obj.cellRendererFramework = 'genericTableCell';
    obj.headerName = columns[i].displayName;
    obj.title = columns[i].displayName;
    obj.headerComponentParams = {
      enableSorting: true
    };

    if (
      columns[i].dbKey === 'pdp_availability_text' ||
      columns[i].dbKey === 'pdp_availability_status'
    ) {
      obj.cellRendererParams.additionalClasses = 'u-word-break';
    }

    if (columns[i].keyType === 'parent_asin') {
      obj.cellRendererFramework = asinComponent;
      obj.headerComponentParams.enableSorting = true;
      obj.cellRendererParams = columns[i];
    }

    if (columns[i].keyType === 'asin' || columns[i].keyType === 'comp_sku') {
      obj.cellRendererFramework = skuComponent;
      if (columns[i].keyType === 'asin') {
        obj.headerComponentParams.enableSorting = false;
      }
      obj.cellRendererParams = columns[i];
      obj.minWidth = 300;
      obj.width = 300;
    }
    obj.keyOrder && (obj.keyOrder = columns[i].keyOrder);
    columns[i].pinned && (obj.pinned = columns[i].pinned);
    if (columns[i].toolTipText) {
      obj.headerComponentParams.toolTipText = columns[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  /**
   * Sort based on key order
   */
  // columnDefs = _columnDefs.sort((a, b) => {
  //   return (a.keyOrder) - (b.keyOrder);
  // });
  columnDefs = _columnDefs;
  return columnDefs;
}

/**
 * Generate Table Definitions for all the tabs here.
 **/
var allTabsTableColDef = {};
function generateTableDefColumns(vueRef) {
  var columnsObj = tableColumns.getTableColumnsFn();
  if (Vue.options.filters.config_check('feature.direct_fulfilment.enable')) {
    if (checkArray(columnsObj.Operations, 'dbKey', 'stock') === false) {
      // columnsObj.Operations.splice(1, 0, {
      //   displayName: 'Direct Fulfilment Inventory',
      //   dbKey: 'stock',
      //   keyType: 'numeric',
      //   group: 'Operations',
      //   cIndex: 1,
      //   formatValue: true,
      //   prefix: '',
      //   suffix: '',
      //   toolTipText: 'Units available for direct fulfillment'
      // });
      columnsObj.Operations.unshift({
        displayName: 'Direct Fulfilment Inventory',
        dbKey: 'stock',
        keyType: 'numeric',
        group: 'Operations',
        cIndex: 3,
        formatValue: true,
        prefix: '',
        suffix: '',
        toolTipText: 'Units available for direct fulfillment'
      });
    }
  }
  /*
   * Disable No.of days suppressed, No.of days unavailable, No.of days oos
   * Add No.of days unavailable
   */
  if (
    Vue.options.filters.config_check(
      'feature.merge_unavailable_recommendation.enable'
    )
  ) {
    const columnsToBeDisabled = ['suppressed_days', 'oos_days'];
    columnsObj.SalesAndMargin.forEach((column) => {
      // Disable No.of days suppressed & No.of days oos columns
      if (columnsToBeDisabled.indexOf(column.dbKey) > -1) {
        column.disabled = true;
      }
      // Change key and tooltip for No. of days unavailable column
      // if (column.dbKey === 'unavl_days') {
      //   column.dbKey = 'unavailable_merged_days';
      //   column.toolTipText = 'Number of days SKU was not available for sale in selected date range';
      // }
    });

    // Change key and tooltip for No. of days unavailable column in Operations tab.
    columnsObj.Operations.forEach((column) => {
      if (column.dbKey === 'unavl_days') {
        column.dbKey = 'unavailable_merged_days';
        column.toolTipText =
          'Number of days SKU was not available for sale in selected date range';
      }
    });
  }
  for (var key in columnsObj) {
    allTabsTableColDef[key] = createColDefs(columnsObj[key], vueRef);
  }
  return allTabsTableColDef;
}

function checkArray(arr, key, value) {
  if (
    arr &&
    arr.constructor === Array &&
    arr.length > 0 &&
    key.constructor === String
  ) {
    for (var item of arr) {
      if (
        item.constructor === Object &&
        item.hasOwnProperty(key) &&
        item[key] === value
      ) {
        return true;
      }
    }
    return false;
  } else {
  }
}

export default {
  config: (that) => {
    return {
      allTabsTableColDef: generateTableDefColumns(that),
      checkArray: checkArray
    };
  }
};
