<template>
  <div
    class="cell--content u-white-space-normal u-color-grey-base u-overflow-hidden u-cursor-text"
  >
    {{ displayValue() }}
  </div>
</template>

<script>
export default {
  props: {
    newVariants: {
      type: String,
      default: null
    },
    oldVariants: {
      type: String,
      default: null
    }
  },
  methods: {
    displayValue() {
      const newVariants = this.newVariants.split(',').map((e) => e.trim());
      const oldVariants = this.oldVariants.split(',').map((e) => e.trim());
      const added = newVariants.filter((e) => !oldVariants.includes(e));
      const removed = oldVariants.filter((e) => !newVariants.includes(e));

      if (added.length && removed.length)
        return `${this.getText(added.length)} added. ${this.getText(
          removed.length
        )} dropped.`;
      if (added.length) return `${this.getText(added.length)} added.`;
      if (removed.length) return `${this.getText(removed.length)} dropped.`;

      return 'No Change';
    },
    getText(value) {
      return `${value} variant${value > 1 ? 's' : ''}`;
    }
  }
};
</script>

<style></style>
