<template>
  <div
    class="sliders"
    :style="sliderCssVars"
  >
    <div class="sliders-wrapper">
      <input
        id="end-slider"
        :value="end"
        type="range"
        min="0"
        max="1440"
        step="60"
        class="slider"
        :style="{ background: sliderBackground }"
        @input="(e) => updateEnd(e.target.value)"
      />
      <input
        id="start-slider"
        :value="start"
        type="range"
        min="0"
        max="1440"
        step="60"
        class="slider"
        @input="(e) => updateStart(e.target.value)"
      />
      <div
        class="slider-custom-thumb"
        :style="{ left: startSliderThumbOffset }"
      >
        {{ startTime.format('LT') }}
      </div>
      <div
        class="slider-custom-thumb"
        :style="{ left: endSliderThumbOffset }"
      >
        {{ endTime.format('LT') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    startTime: {
      type: Object,
      required: true
    },
    endTime: {
      type: Object,
      required: true
    },
    timeRangeOptions: {
      type: Array,
      default: undefined
    },
    selectedRangeColor: {
      type: String,
      default: '#007cf6'
    },
    trackHeight: {
      type: Number,
      default: 8
    },
    trackWidth: {
      type: Number,
      default: 480
    },
    trackColor: {
      type: String,
      default: '#e9eaeb'
    },
    thumbHeight: {
      type: Number,
      default: 21
    },
    thumbWidth: {
      type: Number,
      default: 74
    }
  },
  data() {
    return {
      start: 0,
      end: 1440
    };
  },
  computed: {
    sliderCssVars() {
      return {
        '--slider-track-height': `${this.trackHeight}px`,
        '--slider-track-width': `${this.trackWidth}px`,
        '--slider-thumb-height': `${this.thumbHeight}px`,
        '--slider-thumb-width': `${this.thumbWidth}px`
      };
    },
    sliderBackground() {
      const startPercentCovered = (this.start / 1440) * 100;
      const endPercentCovered = (this.end / 1440) * 100;
      return `linear-gradient(to right, ${this.trackColor} 0%, ${this.trackColor} ${startPercentCovered}%, ${this.selectedRangeColor} ${startPercentCovered}%, ${this.selectedRangeColor} ${endPercentCovered}%, ${this.trackColor} ${endPercentCovered}%, ${this.trackColor} 100%)`;
    },
    startSliderThumbOffset() {
      const startCovered = this.start / 1440;
      const offsetForThumb = startCovered * this.trackWidth;
      const offsetForThumbWidth = startCovered * this.thumbWidth;
      return `${offsetForThumb - offsetForThumbWidth}px`;
    },
    endSliderThumbOffset() {
      const endCovered = this.end / 1440;
      const offsetForThumb = endCovered * this.trackWidth;
      const offsetForThumbWidth = endCovered * this.thumbWidth;
      // 4 here because for some reason, there's a gap
      return `${offsetForThumb - offsetForThumbWidth + 4}px`;
    },
    timeRangeOptionsAsInt() {
      return this.timeRangeOptions
        ?.map((v) => moment(v, 'HH:mm:ss'))
        .map((v) => v.hour() * 60 + v.minutes());
    },
    startTimeInternal() {
      return moment().set({
        hour: Math.floor(this.start / 60),
        minute: this.start % 60
      });
    },
    endTimeInternal() {
      if (this.end === 1440) {
        return moment().set({
          hour: 23,
          minute: 59
        });
      }
      return moment().set({
        hour: Math.floor(this.end / 60),
        minute: this.end % 60
      });
    }
  },
  watch: {
    startTime: {
      immediate: true,
      handler(nv, ov) {
        if (!this.isTimeEqual(nv, ov)) {
          this.updateStart(nv.hour() * 60 + nv.minutes());
        }
      }
    },
    endTime: {
      immediate: true,
      handler(nv, ov) {
        if (!this.isTimeEqual(nv, ov)) {
          this.updateEnd(nv.hour() * 60 + nv.minutes());
        }
      }
    }
  },
  methods: {
    isTimeEqual(t1, t2) {
      return t1?.hour() === t2?.hour() && t1?.minutes() === t2?.minutes();
    },
    updateStart(value) {
      if (this.timeRangeOptions) {
        this.start = this.closestStartTimeAsInt(value);
      } else if (value >= this.end - 60) {
        this.start = this.end - 60;
      }
      this.$emit('startTimeUpdated', this.startTimeInternal);
    },
    updateEnd(value) {
      if (this.timeRangeOptions) {
        this.end = this.closestEndTimeAsInt(value);
      } else if (value <= this.start + 60) {
        this.end = this.start + 60;
      }
      this.$emit('endTimeUpdated', this.endTimeInternal);
    },
    // Use binary search instead of reduce, as reduce is O(n)
    closestStartTimeAsInt(value) {
      return this.timeRangeOptionsAsInt
        ?.filter((v) => v < this.end)
        .reduce((prev, curr) =>
          Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
        );
    },
    closestEndTimeAsInt(value) {
      return this.timeRangeOptionsAsInt
        ?.filter((v) => v > this.start)
        .reduce((prev, curr) =>
          Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
        );
    }
  }
};
</script>

<style scoped lang="css">
* {
  box-sizing: border-box;
}
.sliders {
  position: relative;
  height: var(--slider-thumb-height);
  width: var(--slider-track-width);
}
.sliders-wrapper {
  position: absolute;
  top: calc((var(--slider-track-height) - var(--slider-thumb-height)) / -2);
}
.slider {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 16px;
  height: var(--slider-track-height);
  width: var(--slider-track-width);
  outline: none;
  appearance: none;
  pointer-events: none;
}
.slider::-webkit-slider-runnable-track {
  height: var(--slider-track-height);
}
.slider::-moz-range-track {
  height: var(--slider-track-height);
}
.slider::-ms-track {
  height: var(--slider-track-height);
}
.slider::-webkit-slider-thumb {
  pointer-events: all;
  appearance: none;
  background: transparent;
  border: 0;
  margin-top: calc(
    (var(--slider-track-height) - var(--slider-thumb-height)) / 2
  );
  height: var(--slider-thumb-height);
  width: var(--slider-thumb-width);
}
.slider::-moz-range-thumb {
  pointer-events: all;
  appearance: none;
  background: transparent;
  border: 0;
  margin-top: calc(
    (var(--slider-track-height) - var(--slider-thumb-height)) / 2
  );
  height: var(--slider-thumb-height);
  width: var(--slider-thumb-width);
}
.slider::-ms-thumb {
  pointer-events: all;
  appearance: none;
  background: transparent;
  border: 0;
  margin-top: calc(
    (var(--slider-track-height) - var(--slider-thumb-height)) / 2
  );
  height: var(--slider-thumb-height);
  width: var(--slider-thumb-width);
}
#start-slider {
  background: none;
}
.slider-custom-thumb {
  top: calc((var(--slider-track-height) - var(--slider-thumb-height)) / 2);
  position: absolute;
  height: var(--slider-thumb-height);
  width: var(--slider-thumb-width);
  padding: 0.5px;
  background-color: white;
  border: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.2) 8px 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  pointer-events: none;
  font-size: 1em;
  font-weight: 400;
}
</style>
