<template>
  <div
    v-if="showBanner"
    class="progress-ui-container"
  >
    <div class="u-display-flex u-flex-align-items-center">
      <rb-icon
        class="u-color-orange-base u-cursor-pointer"
        icon="in-progress-dotted"
      />
      <span class="u-spacing-pt-xs u-spacing-pl-s">
        Updation of filters is
        <span class="ingestion-progress-em-txt"> In-Progress</span>, it will
        take upto {{ getProgressEstimatedTime(progressEstimatedTime) }} for the
        changes to reflect across the product. While the filters are being
        updated, you can continue editing internal taxonomy.
      </span>
    </div>
    <div
      class="u-spacing-mt-xs"
      style="margin-left: auto"
      @click="closeBanner"
    >
      <rb-icon
        class="rb-icon--medium u-cursor-pointer"
        icon="cross"
      />
    </div>
  </div>
</template>

<script>
import { filters } from '@ciq-dev/ciq-ui';
import ingestionBadge from '@/pages/toolsAndSettings/entityTagging/common/ingestionBadge';
import { getProgressEstimatedTime } from '@/utils/helpers/utils.js';

export default {
  components: {
    ingestionBadge
  },
  props: {
    showProgress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progressEstimatedTime: null,
      hideBanner: false
    };
  },
  computed: {
    showBanner() {
      return this.showProgress && !this.hideBanner;
    }
  },
  created() {
    this.progressEstimatedTime = filters.config_check(
      'feature.ingestion.progress_estimate_minutes'
    );
  },
  methods: {
    showProgressTriggered(event) {
      this.$emit('showProgressTriggered', event);
    },
    closeBanner() {
      this.hideBanner = true;
    },
    getProgressEstimatedTime
  }
};
</script>

<style lang="css">
.progress-ui-container {
  font-size: 1.4rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  background: rgba(255, 168, 0, 0.1);
  padding-left: 2.4rem;
  display: flex;
  padding-left: 44px;
  padding-right: 44px;
}
.ingestion-progress-em-txt {
  font-weight: 600;
  color: #ffa800;
}
</style>
