import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { capitalize } from '@/utils/helpers/formatter';

const CONTANTS = {
  po_field_name: 'po_number'
};
const asnGroupMetrics = [
  'asn',
  'shipment_id',
  'shipment_status',
  'carrier_scac',
  'ship_mode',
  'estimated_delivery_date',
  'carrier_tracking_number',
  'reference_id'
];

const statusTextUIMapping = {
  PENDING_PICK_UP: 'Pending pickup',
  TRUCKLOAD: 'Truckload',
  LESS_THAN_TRUCKLOAD: 'Less than truckload',
  IN_TRANSIT: 'In transit',
  transform: (text) => {
    return capitalize(text).replaceAll('_', ' ');
  }
};

const state = {
  insightsShipmentTracker: {},
  currentInputDateSelection: {},
  skus_collection_types: ['cases', 'units'],
  selected_sku_collection_type: 'cases',
  selectedFilters: {},
  selectedPOs: {
    poList: [],
    load: false
  },
  shipmentOverviewData: {
    load: false
  },
  POTableData: {
    rows: [],
    totalRows: 0,
    load: false
  },
  SkuPOTableData: {
    load: true
  },
  showPOTable: true,
  primaryCrossWidgetColumn: CONTANTS.po_field_name
};

const appendAndCreateUrl = (baseUrl, id) => {
  if (id) {
    return baseUrl + id;
  }
};

const generateUrlArrayFromTemplate = (idList, baseUrl) => {
  const urlLists = idList.map((id) => {
    return appendAndCreateUrl(baseUrl, id);
  });
  return urlLists;
};

const getters = {
  getInputDateSelection() {
    return state.currentInputDateSelection;
  },
  getPrimaryCrossWidgetColumn: (state) => {
    return state.primaryCrossWidgetColumn;
  },
  getSelectedPOs: (state) => {
    return state.selectedPOs;
  },
  getInsightsShipmentTrackerFilters: (state) => {
    return state.selectedFilters;
  },
  getShipmentOverviewData: (state) => {
    return state.insightsShipmentTracker.shipmentOverviewData;
  },
  getShipmentPOData(state) {
    return state.insightsShipmentTracker.POTableData;
  },
  getShipmentSkuPOData() {
    return state.insightsShipmentTracker.SkuPOTableData;
  },
  getSkuCollectionType() {
    return state.selected_sku_collection_type;
  },
  getAllSkuCollectionTypes() {
    return state.skus_collection_types;
  },
  getShowPOTable() {
    return state.showPOTable;
  }
};

const mutations = {
  I_SHIP_TRACK_SUCCESS: (state, data) => {
    Vue.set(state.insightsShipmentTracker, data.key, {});
    if (Array.isArray(data.rows)) {
      Vue.set(state.insightsShipmentTracker[data.key], 'rows', data.rows);
      Vue.set(
        state.insightsShipmentTracker[data.key],
        'totalRows',
        data.totalRows
      );
    } else {
      Vue.set(state.insightsShipmentTracker[data.key], 'data', data);
    }
    Vue.set(state.insightsShipmentTracker[data.key], 'load', false);
    Vue.set(state.insightsShipmentTracker[data.key], 'page', data.page);
    Vue.set(state.insightsShipmentTracker[data.key], 'error', false);
    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsShipmentTracker[data.key], 'noData', true);
    }
  },
  I_SHIP_TRACK_RESET: (state, data) => {
    Vue.set(state.insightsShipmentTracker, data.key, {});
    Vue.set(state.insightsShipmentTracker[data.key], 'load', true);
    Vue.set(state.insightsShipmentTracker[data.key], 'rows', []);
    Vue.set(state.insightsShipmentTracker[data.key], 'data', {});
    Vue.set(state.insightsShipmentTracker[data.key], 'error', false);
    Vue.set(state.insightsShipmentTracker[data.key], 'noData', false);
  },
  I_SHIP_TRACK_ERROR: (state, data) => {
    Vue.set(state.insightsShipmentTracker, data.key, {});
    Vue.set(state.insightsShipmentTracker[data.key], 'columns', []);
    Vue.set(state.insightsShipmentTracker[data.key], 'rows', []);
    Vue.set(state.insightsShipmentTracker[data.key], 'load', false);
    Vue.set(state.insightsShipmentTracker[data.key], 'error', true);
    Vue.set(state.insightsShipmentTracker[data.key], 'noData', false);
  },
  INSIGHT_SHIPMENT_TRACKER_PO_LIST_SUCCESS: (state, data) => {
    Vue.set(state, 'POTableData', { ...data, load: false });
  },
  INSIGHT_SHIPMENT_TRACKER_SKU_PO_LIST_SUCCESS: (state, data) => {
    Vue.set(state, 'SkuPOTableData', { ...(data || {}), load: false });
  },
  SET_CALENDAR_SELECTED_VALUES: (state, data) => {
    state.selectedCalendarValues = data;
  },
  SET_CALENDAR_DATA: (state, data) => {
    state.calendarData = data;
  },
  INSIGHTS_SHIPMENT_TRACKER_RESET: (state, data) => {
    Vue.set(state, 'POTableData', { ...(data || {}), load: true });
  },
  INSIGHTS_SHIPMENT_TRACKER_ERROR: (state, data) => {},
  INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(state, 'selectedFilters', data);
  },
  INSIGHTS_SHIPMENT_TRACKER_SET_SKU_COLLECTION_TYPE: (state, type) => {
    Vue.set(state, 'selected_sku_collection_type', type);
  },
  INSIGHTS_SHIPMENT_TRACKER_SET_INPUT_DATE_TYPE: (state, type) => {
    Vue.set(state, 'currentInputDateSelection', type);
  },
  INSIGHTS_SHIPMENT_TRACKER_SET_SHOW_PO_TABLE: (state, bShow) => {
    Vue.set(state, 'showPOTable', bShow);
  },
  INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO: (state, selectedPOs) => {
    Vue.set(state, 'selectedPOs', { poList: selectedPOs, load: false });
  },
  INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO_LOADING: (state, bLoad) => {
    Vue.set(state.selectedPOs, 'load', bLoad);
  }
};
const actions = {
  selectAllPOs(context, data) {
    // const widgetData = { ...widgetConfig };
    const widgetBody = JSON.parse(JSON.stringify(data.body));
    const widgetMeta = JSON.parse(JSON.stringify(data.meta));
    let finalWhereClause = {
      dimensionNameValueList: []
    };
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    finalWhereClause = transformer.generateWhereClause(
      finalWhereClause,
      context.getters.getInsightsShipmentTrackerFilters,
      false,
      state.currentInputDateSelection.calenderKey
    );
    widgetBody.APIConfig.timeseriesDimension =
      context.getters.getInputDateSelection.key;
    widgetBody.APIConfig.groupByDimensionsList = [CONTANTS.po_field_name];
    widgetBody.APIConfig.orderByList = [];
    widgetBody.APIConfig.measuresList = [];
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    widgetBody.APIConfig.where = transformedWhereClause;
    widgetBody.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete widgetBody.APIConfig.where.tagWhereClause;

    widgetBody.APIConfig.limit = undefined;
    widgetBody.APIConfig.page = undefined;
    setTimeout(() => {
      context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO_LOADING', true);
    }, 0);
    HttpLayer.post({
      APIData: widgetBody.APIConfig
    })
      .then((response) => {
        if (!response.success) {
          // to be changed
          console.error('selectAllPOs', response);
          context.commit('I_SHIP_TRACK_ERROR', { key: data.meta.key });
        }
        const mergedData = transformer.mergeResultDimension(
          response.data,
          true
        );
        const v1PoTableData = {
          rows: mergedData,
          totalRows: mergedData[0] && mergedData[0].auto_cubesdk_count
        };
        const poList = v1PoTableData.rows.map((row) => {
          return row[context.getters.getPrimaryCrossWidgetColumn];
        });
        context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO', poList);
      })
      .catch((err) => {
        setTimeout(() => {
          context.commit(
            'INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO_LOADING',
            false
          );
        }, 0);
        console.error("error loading all PO's", err);
        this.snackbar("Error Selecting all PO's", 6000);
      });
  },
  setSelectedPOs(context, selectedPOs) {
    context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_PO', selectedPOs);
  },
  setShowPOTable(context, bShow) {
    context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SHOW_PO_TABLE', bShow);
  },
  setSkuCollectionType(context, type) {
    context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SKU_COLLECTION_TYPE', type);
  },
  setInputDateType(context, type) {
    context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_INPUT_DATE_TYPE', type);
  },
  setInsightsShipmentTrackerFilters: (context, data) => {
    context.commit('INSIGHTS_SHIPMENT_TRACKER_SET_SELECTED_FILTER', data);
  },
  downloadShipmentTableData: (context, data) => {
    // const widgetData = { ...widgetConfig };
    const widgetBody = JSON.parse(JSON.stringify(data.body));
    const widgetMeta = JSON.parse(JSON.stringify(data.meta));
    let finalWhereClause = {
      dimensionNameValueList: []
    };
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    finalWhereClause = transformer.generateWhereClause(
      finalWhereClause,
      context.getters.getInsightsShipmentTrackerFilters,
      undefined,
      state.currentInputDateSelection.calenderKey
    );

    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    widgetBody.APIConfig.where = transformedWhereClause;
    widgetBody.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete widgetBody.APIConfig.where.tagWhereClause;

    widgetBody.APIConfig.page = undefined;
    widgetBody.APIConfig.limit = undefined;

    return HttpLayer.post({
      APIData: widgetBody.APIConfig
    }).then((response) => {
      // consuming function is written in the try catch
      // hence will catch the error and open a snack bar
      if (!response.success) {
        throw response;
      }
      const rows = transformer.mergeResultDimension(response.data);
      let flattened_rows = [];
      rows.forEach((po_row) => {
        const flat_arn_rows = [];
        let arn_count = 0;
        asnGroupMetrics.forEach((group_metric) => {
          if (po_row[group_metric]) {
            po_row[group_metric] = JSON.parse(po_row[group_metric]);
          } else {
            // cases where ASN values are NULL due to historic missing data
            po_row[group_metric] = [null];
          }
          arn_count = Math.max(arn_count, po_row[group_metric].length);
        });
        po_row.shipment_status = po_row.shipment_status.map(
          (backend_ship_status) => {
            if (statusTextUIMapping[backend_ship_status]) {
              return statusTextUIMapping[backend_ship_status];
            }
            return statusTextUIMapping.transform(backend_ship_status);
          }
        );
        po_row.ship_mode = po_row.ship_mode.map((backend_ship_mode) => {
          if (statusTextUIMapping[backend_ship_mode]) {
            return statusTextUIMapping[backend_ship_mode];
          }
          return statusTextUIMapping.transform(backend_ship_mode);
        });
        const only_po_columns = {};
        Object.keys(po_row).forEach((po_column_name) => {
          if (asnGroupMetrics.indexOf(po_column_name) === -1) {
            only_po_columns[po_column_name] = po_row[po_column_name];
          }
        });
        for (let index = 0; index < arn_count; index++) {
          let arn_row = {};
          asnGroupMetrics.forEach((group_metric) => {
            arn_row[group_metric] = po_row[group_metric][index];
          });
          arn_row = { ...arn_row, ...only_po_columns };
          flat_arn_rows.push(arn_row);
        }
        flattened_rows = flattened_rows.concat(flat_arn_rows);
      });
      return flattened_rows;
    });
  },
  setCalendarSelectedValues: (context, data) => {
    context.commit('SET_CALENDAR_SELECTED_VALUES', data);
  },
  fetchShipmentOverviewData(context, data) {
    // const widgetData = { ...data };
    const widgetBody = JSON.parse(JSON.stringify(data.body));
    const widgetMeta = JSON.parse(JSON.stringify(data.meta));
    context.commit('I_SHIP_TRACK_RESET', {
      key: widgetMeta.key
    });
    let finalWhereClause = {
      dimensionNameValueList: []
    };
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    finalWhereClause = transformer.generateWhereClause(
      finalWhereClause,
      context.getters.getInsightsShipmentTrackerFilters,
      true,
      state.currentInputDateSelection.calenderKey
    );

    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    widgetBody.APIConfig.where = transformedWhereClause;
    widgetBody.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete widgetBody.APIConfig.where.tagWhereClause;

    widgetBody.APIConfig.timeseriesDimension =
      context.getters.getInputDateSelection.key;
    HttpLayer.post({
      APIData: widgetBody.APIConfig
    })
      .then((response) => {
        if (!response.success) {
          // to be changed
          console.error('fetchShipmentOverviewData', response);
          context.commit('I_SHIP_TRACK_ERROR', { key: data.meta.key });
          return;
        }
        const mergedData =
          transformer.mergeResultDimension(response.data, true) || [];
        context.commit('I_SHIP_TRACK_SUCCESS', {
          key: widgetMeta.key,
          ...mergedData[0],
          page: widgetBody.APIConfig.page
        });
      })
      .catch((err) => {
        console.error('fetchShipmentOverviewData', err);
        context.commit('I_SHIP_TRACK_ERROR', { key: widgetMeta.key });
      });
  },
  fetchPOInfoList(context, data) {
    // const widgetData = { ...widgetConfig };
    const widgetBody = JSON.parse(JSON.stringify(data.body));
    const widgetMeta = JSON.parse(JSON.stringify(data.meta));
    context.commit('I_SHIP_TRACK_RESET', {
      key: widgetMeta.key
    });
    let finalWhereClause = {
      dimensionNameValueList: []
    };
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    finalWhereClause = transformer.generateWhereClause(
      finalWhereClause,
      context.getters.getInsightsShipmentTrackerFilters,
      undefined,
      state.currentInputDateSelection.calenderKey
    );

    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    widgetBody.APIConfig.where = transformedWhereClause;
    widgetBody.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete widgetBody.APIConfig.where.tagWhereClause;

    widgetBody.APIConfig.timeseriesDimension =
      context.getters.getInputDateSelection.key;
    HttpLayer.post({
      APIData: widgetBody.APIConfig
    })
      .then((response) => {
        if (!response.success) {
          console.error('fetchPOInfoList', response);
          context.commit('I_SHIP_TRACK_ERROR', { key: widgetMeta.key });
          return;
        }
        const mergedData = transformer.mergeResultDimension(
          response.data,
          true
        );
        mergedData.forEach((po_info) => {
          asnGroupMetrics.forEach((group_metric) => {
            if (po_info[group_metric] && po_info[group_metric] !== '[]') {
              po_info[group_metric] = JSON.parse(po_info[group_metric]);
            } else {
              // cases where ASN values are NULL due to historic missing data
              po_info[group_metric] = [null];
            }
          });
          po_info.po_url = appendAndCreateUrl(
            po_info.po_url,
            po_info.po_number
          );
          po_info.asn_url = generateUrlArrayFromTemplate(
            po_info.asn,
            po_info.asn_url
          );
          po_info.shipment_id_url = generateUrlArrayFromTemplate(
            po_info.shipment_id,
            po_info.shipment_id_url
          );
          po_info.shipment_status = po_info.shipment_status.map(
            (backend_ship_status) => {
              if (statusTextUIMapping[backend_ship_status]) {
                return statusTextUIMapping[backend_ship_status];
              }
              return statusTextUIMapping.transform(backend_ship_status);
            }
          );
          po_info.ship_mode = po_info.ship_mode.map((backend_ship_mode) => {
            if (statusTextUIMapping[backend_ship_mode]) {
              return statusTextUIMapping[backend_ship_mode];
            }
            return statusTextUIMapping.transform(backend_ship_mode);
          });
        });
        const v1PoTableData = {
          rows: mergedData,
          totalRows: mergedData[0] && mergedData[0].auto_cubesdk_count
        };
        context.commit('I_SHIP_TRACK_SUCCESS', {
          ...v1PoTableData,
          key: widgetMeta.key,
          page: widgetBody.APIConfig.page
        });
      })
      .catch((err) => {
        console.error('fetchPOInfoList', err);
        context.commit('I_SHIP_TRACK_ERROR', { key: widgetMeta.key });
      });
  },
  fetchSkuPOInfoList(context, data) {
    // the po table is visible don't trigger sku table data call's
    if (context.getters.getShowPOTable) {
      return;
    }
    const widgetBody = JSON.parse(JSON.stringify(data.body));
    const widgetMeta = JSON.parse(JSON.stringify(data.meta));
    context.commit('I_SHIP_TRACK_RESET', {
      key: widgetMeta.key
    });
    // const widgetData = { ...widgetConfig };
    const selectedPOs = context.getters.getSelectedPOs || [];
    let finalWhereClause = {
      dimensionNameValueList: selectedPOs.poList.map((po_number) => {
        return {
          dimensionName: CONTANTS.po_field_name,
          dimensionValue: po_number
        };
      })
    };
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    finalWhereClause.dimensionNameValueList =
      finalWhereClause.dimensionNameValueList.concat(
        widgetMeta.localFilters || []
      );
    // passing the PO filters to SKU pages
    finalWhereClause = transformer.generateWhereClause(
      finalWhereClause,
      context.getters.getInsightsShipmentTrackerFilters,
      undefined,
      state.currentInputDateSelection.calenderKey
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    widgetBody.APIConfig.where = transformedWhereClause;
    widgetBody.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete widgetBody.APIConfig.where.tagWhereClause;

    widgetBody.APIConfig.timeseriesDimension =
      context.getters.getInputDateSelection.key;
    HttpLayer.post({
      APIData: widgetBody.APIConfig
    })
      .then((response) => {
        if (!response.success) {
          console.error('fetchSkuPOInfoList', response);
          context.commit('I_SHIP_TRACK_ERROR', { key: widgetMeta.key });
          return;
        }
        const mergedData = transformer.mergeResultDimension(
          response.data,
          true
        );
        const v2SkuPoTableMeta = {
          rows: mergedData,
          totalRows: mergedData[0] && mergedData[0].auto_cubesdk_count
        };
        context.commit('I_SHIP_TRACK_SUCCESS', {
          ...v2SkuPoTableMeta,
          key: widgetMeta.key,
          page: widgetBody.APIConfig.page
        });
      })
      .catch((err) => {
        console.error('fetchSkuPOInfoList', err);
        context.commit('I_SHIP_TRACK_ERROR', { key: widgetMeta.key });
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
