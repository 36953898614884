<template>
  <section class="u-spacing-mt-m">
    <div
      v-if="metricLabel"
      class="u-display-flex u-flex-justify-content-space-between"
    >
      <div>
        <h3
          class="u-font-size-3 u-text-case-upper u-font-weight-600 u-color-grey-light u-text-align-left u-color-grey-lighter"
        >
          {{ metricLabel }} SPOTLIGHTS
        </h3>
      </div>
    </div>
    <section v-if="spotlightsData.length">
      <spotlight-carousel :spotlights-data="spotlightsData" />
    </section>
  </section>
</template>
<script>
import Vue from 'vue';
import SpotlightCarousel from '../organisms/spotlight-carousel.vue';

export default Vue.extend({
  components: {
    SpotlightCarousel
  },
  props: {
    metricLabel: {
      type: String,
      default: () => ''
    },
    spotlightsData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { expanded: false };
  },
  computed: {
    collapsedText() {
      return this.expanded ? 'Collapse' : "See what's working";
    },
    collapsedIcon() {
      return this.expanded ? 'arrow-up' : 'arrow-down';
    }
  }
});
</script>
