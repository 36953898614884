import POFillRate from '@/components/pages/insights/amazon/pofillrate/pofillrate.vue';
import Forecast from '@/components/pages/insights/amazon/forecast/forecast.vue';
import ShipmentTracker from '@/components/pages/insights/amazon/shipment-tracker/shipment-tracker.vue';
import ShortageInvoices from '@/components/pages/insights/amazon/shortage-invoices/shortage-invoices.vue';
import ShortgaesFallbackUI from '@/components/pages/insights/amazon/shortage-invoices/shortage-fallback-ui.vue';
import PriceVariance from '@/components/pages/insights/amazon/price-variance/price-variance.vue';
import Chargeback from '@/components/pages/insights/amazon/chargeback-claims/chargeback-claims.vue';
import RRAOverview from '@/components/pages/insights/amazon/rra/shortage-rra-overview.vue';
import ChargebackAnalytics from '@/components/pages/insights/amazon/chargeback-analytics/chargeback-analytics.vue';
import DeductionManagement from '@/components/pages/insights/amazon/deduction-management/deduction-management.vue';
import CoOpDeductions from '@/components/pages/insights/amazon/co-op-deductions/co-op-deductions.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      title: 'Co-Op Deductions',
      name: 'co-op-deductions',
      path: 'coopDeduction',
      redirect: '',
      page: 'insights',
      config: 'coopDeduction',
      configCheckRequired: true,
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: CoOpDeductions,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REVENUE_RECOVERY
      }
    },
    {
      title: 'Shortages',
      name: 'shortages',
      path: 'shortages',
      redirect: '',
      config: 'shortage_invoice_report',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: ShortageInvoices,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REVENUE_RECOVERY
      }
    },
    {
      title: 'Deductions Overview',
      path: 'deduction_management',
      redirect: '',
      config: 'deduction_management',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: DeductionManagement,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REVENUE_RECOVERY
      }
    },
    {
      title: 'Profit Recovery Overview',
      path: 'praoverview',
      redirect: '',
      config: 'shortage_invoice_report',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: RRAOverview,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      title: 'Shortage Invoice Report',
      path: 'shortages_fallback_ui',
      redirect: '',
      config: 'shortage_fallback_invoice_report',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      localConfig: 'shortages-fallback-notification',
      page: 'insights',
      component: ShortgaesFallbackUI,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      title: 'price-variance',
      path: 'price_variance',
      name: 'price_variance',
      redirect: '',
      config: 'price_variance_shortage_invoice_report',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: PriceVariance,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REVENUE_RECOVERY
      }
    },
    {
      title: 'Chargeback Analytics',
      name: 'chargeback_analytics',
      path: 'chargeback_analytics',
      redirect: '',
      config: 'chargeback_claims',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: ChargebackAnalytics,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      title: 'Chargebacks',
      name: 'chargeback_claims',
      path: 'chargebacks',
      redirect: '',
      config: 'chargeback_claims',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'insights',
      component: Chargeback,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REVENUE_RECOVERY
      }
    },
    {
      configCheckRequired: true,
      path: 'shipment_tracker',
      name: 'Shipment Tracker',
      title: 'Shipment Tracker',
      page: 'insights',
      beta: true,
      config: 'shipmenttracker',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: ShipmentTracker,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'po_fill_rate',
      name: 'PO Fill Rate',
      title: 'PO Fill Rate',
      page: 'insights',
      config: 'pofillrate',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: POFillRate,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'sales_forecast',
      name: 'Forecast',
      title: 'Sales Forecast',
      page: 'insights',
      config: 'forecasting',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: Forecast,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: '',
      redirect: () => {
        return 'sales_forecast';
      }
    }
  ]
};
