<template>
  <section
    class="u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column u-height-100"
  >
    <p class="u-font-size-3">Not Found!</p>
    <div>
      <img
        alt="404 error"
        style="max-width: 537px"
        src="/images/404error.png"
      />
    </div>
    <router-link to="/">
      <rb-button
        :text="'Go Home'"
        :type="'filled'"
      />
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped></style>
