var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-component-v2"},[_c('div',{staticClass:"scrollable u-width-100 u-display-flex"},[_c('div',{staticClass:"u-height-100 u-display-flex u-flex-direction-column",class:[_vm.allBulletPointsClass],style:({ width: _vm.panelWidth + '%' })},_vm._l((_vm._config),function(step,index){return _c('div',{key:index + _vm.enabledBreadCrumbsLocalState.toString(),staticClass:"menu-point u-spacing-pl-s",class:[{ active: _vm.activeSection == index }]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!step.hideLeftPanel),expression:"!step.hideLeftPanel"}],staticClass:"u-line-height-1 u-display-flex u-flex-align-items-center u-spacing-mb-xs",class:[step.stepSectionColor, _vm.bulletPointClass],style:({
            width: _vm.showStepBulletDescription ? '100%' : '40%',
            'pointer-events':
              _vm.enabledBreadCrumbsLocalState.includes(index) ||
              _vm.disableBreadCrumbClickAction
                ? 'default'
                : 'none',
            cursor: _vm.enabledBreadCrumbsLocalState.includes(index)
              ? 'pointer'
              : 'none'
          }),on:{"click":function($event){return _vm.onClickBreadCrumb(index)}}},[_c('rb-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"u-cursor-pointer",class:step.stepSectionIconColor,attrs:{"icon":"dot","title":step.breadCrumbTitle || ("Step " + (index + 1))}}),(_vm.showStepBulletDescription)?_c('span',{staticClass:"u-line-height-1",attrs:{"data-cy":("campaignCreationStep-" + (step.name))}},[_vm._v(_vm._s(step.name))]):_vm._e()],1),(_vm.showStepBulletDescription)?_c('div',{staticClass:"u-spacing-pl-m u-font-size-7 u-line-height-1-3"},[_c('span',{staticClass:"u-color-grey-lighter u-font-size-7",class:step.stepSectionColor,attrs:{"data-cy":("stepDescriptionOf-" + (step.name))},domProps:{"innerHTML":_vm._s(
              step.description ||
              (_vm.allStepsData &&
                _vm.allStepsData.strategyUIText &&
                _vm.allStepsData.strategyUIText[step.textMapping])
            )}})]):_vm._e()])}),0),_c('div',{staticClass:"u-display-flex u-height-100 u-overflow-auto",style:({ width: 100 - _vm.panelWidth + '%' })},[_c('div',{staticClass:"section__right u-width-100",class:_vm.sectionRightClass,attrs:{"id":"steps"}},_vm._l((_vm._config),function(step,$index){return _c('div',{key:$index,staticClass:"u-width-100",class:[_vm.findModuleClass(step, $index)]},[_c('transition',{attrs:{"name":"slide-fade"}},[($index == _vm.activeSection)?_c(step.component,{tag:"component",attrs:{"step":step,"selected-values":_vm.allStepsData,"all-config":_vm._config},on:{"nextStep":_vm.moveDown,"prevStep":_vm.moveUp,"breadcrumb-indexes-to-enable":_vm.handleEnableBreadCrumbs}}):_vm._e()],1)],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }