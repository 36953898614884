import HttpService from '@/utils/services/http-service';
import Vue from 'vue';
import FnConfigs from '@/components/pages/data/functionConfigs';
import transformer from '@/utils/services/data-transformer';

const state = {
  dataWorkbenchActiveTab: 0,
  dataWorkbenchSelectedFilter: {
    date_range: {
      name: 'Last 90 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  workbenchDownloadData: {},
  page_wise_min_max_key: 'catalog'
};

const getters = {
  getSKUsWhereClause: (state) => state.whereClause,
  getDataWorkbenchActiveTabIndex: (state) => {
    return state.dataWorkbenchActiveTab;
  },
  getDWSelectedFilter: (state) => {
    return state.dataWorkbenchSelectedFilter;
  },
  getDWDataWhereClause: (state) => {
    return state.whereClause;
  },
  getTableData: (state) => {
    return state.tableData;
  },
  workbenchDownloadData: (state) => {
    return state.workbenchDownloadData;
  },
  getSkusPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

for (var key in FnConfigs) {
  var dataStates = FnConfigs[key].dataStates;
  for (const sKey in dataStates) {
    state[sKey] = dataStates[sKey];
    getters[sKey] = (state) => {
      return state[sKey];
    };
  }
}

function getFiltersInFormat(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      where.date = oFilter.values[i];
      where.date.page_wise_min_max_key = state.page_wise_min_max_key;
    }
  }
  state.whereClause = where;
}

const processTableData = function (state, apiData, stateKey) {
  var dataToReturn = [];
  for (var obj of apiData.data) {
    var newObj = Object.assign(obj.DIMENSION, obj.RESULT);
    dataToReturn.push(newObj);
  }
  return dataToReturn;
};

const mutations = {
  SET_DATA_WORKBENCH_ACTIVE_TAB: (state, data) => {
    state.dataWorkbenchActiveTab = data;
  },
  SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.dataWorkbenchSelectedFilter.date_range;
    Vue.set(state, 'dataWorkbenchSelectedFilter', data.values);
    if (!state.dataWorkbenchSelectedFilter.date_range) {
      Vue.set(state.dataWorkbenchSelectedFilter, 'date_range', dateRange);
    }
    getFiltersInFormat(data, state);
  },

  MEASURE_LIST_TABLE_DATA_SUCCESS: (state, dataRecieved) => {
    var apiData = dataRecieved.response;
    state.measureListTableData = processTableData(state, apiData);
    if (apiData.data.length > 0) {
      state.measureListTableDataPaginationTotal = apiData.data[0].RESULT.count;
    } else {
      state.measureListTableDataPaginationTotal = 0;
    }
  },

  MEASURE_LIST_TABLE_DATA_ERROR: (state, dataRecieved) => {
    state.measureListTableData = [];
    state.measureListTableDataPaginationTotal = 0;
  },

  WORKBENCH_DOWNLOAD_DATA_SUCCESS: (state, dataRecieved) => {
    state.workbenchDownloadData = dataRecieved.response.data;
  },

  RESET_DOWNLOAD_STATE: (state, dataRecieved) => {
    state.workbenchDownloadData = [];
  },

  WORKBENCH_TABLE_SEARCH_DATA_SUCCESS: (state, dataRecieved) => {
    state.workbenchTableSearchData = transformer.mergeResultDimension(
      dataRecieved.response.data
    );
  }
};

const actions = {
  commonApiAction: (context, data) => {
    var actionType =
      data.storeActionType.toUpperCase() === 'POST' ? 'post' : 'get';
    state[data.name + 'Loader'] = true;
    HttpService[actionType](data.apiConstant, data.params, data.headers)
      .then((response) => {
        state[data.name + 'Loader'] = false;
        if (response.data.success === true) {
          context.commit(data.storeActionSuccsessMethod, response.data);
        } else {
          if (data.storeActionErrorMethod !== undefined) {
            context.commit(data.storeActionErrorMethod, response.data);
          }
        }
      })
      .catch((error) => {
        state[data.name + 'Loader'] = false;
        if (data.storeActionErrorMethod !== undefined) {
          context.commit(data.storeActionErrorMethod, error.data);
        }
        console.log(error);
      });
  },

  setDataWorkbenchActiveTab: (context, data) => {
    context.commit('SET_DATA_WORKBENCH_ACTIVE_TAB', data);
  },

  setDWSelectedFilter: (context, data) => {
    context.commit('SET_SELECTED_FILTER', data);
  },

  resetDownloadState: (context, data) => {
    context.commit('RESET_DOWNLOAD_STATE', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
