import Vue from 'vue';
import { isEqual } from 'lodash';
// import HttpService from '@/utils/services/http-service';

const state = {
  metaData: {
    selectedRollUp: null,
    shownMetricList: [],
    selectedMetric: [],
    plotCompareTrendLine: false
  }
};

const getters = {
  getchartingWbSaveViewData: (state) => {
    return state.metaData;
  },
  getPerformanceWbSaveViewData: (state) => {
    return state.metaData;
  }
};

const mutations = {
  SETROLLUP: (state, data) => {
    // state.selectedRollUp = data;
    Vue.set(state.metaData, 'selectedRollUp', data);
  },
  SETMETRICVALUE: (state, data) => {
    Vue.set(state.metaData, 'shownMetricList', data.metricDisplayList);
    Vue.set(state.metaData, 'selectedMetric', data.selectedMetric);
  },
  SET_PLOT_COMPARE_TREND_LINE: (state, data) => {
    Vue.set(state.metaData, 'plotCompareTrendLine', data);
  },
  SET_COMPLETE_PAGE_METADATA: (state, data) => {
    if (!data.shownMetricList.length) {
      return;
    }
    if (!isEqual(state.metaData, data)) {
      state.metaData = data;
    }
  }
};

const actions = {
  setRollUpAction: (context, data) => {
    context.commit('SETROLLUP', data);
  },
  setMetricValueAction: (context, data) => {
    context.commit('SETMETRICVALUE', data);
  },
  setPlotCompareTrendLine: (context, data) => {
    context.commit('SET_PLOT_COMPARE_TREND_LINE', data);
  },
  setCompletePageMetadata: (context, data) => {
    context.commit('SET_COMPLETE_PAGE_METADATA', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
