<template>
  <div class="u-width-100">
    <div class="u-display-flex u-flex-align-items-flex-start u-flex-wrap-yes">
      <div
        v-for="(val, index) in chips"
        :key="index"
        class="single-chip u-spacing-ph-s u-spacing-pv-xs u-spacing-mr-s"
        :class="val.class"
      >
        <span class="u-font-size-7 u-color-grey-white u-font-weight-600">{{
          val.labelText
        }}</span>
      </div>
    </div>
  </div>
</template>
<style scoped lang="css">
.single-chip {
  border-radius: 16px;
}
</style>
<script>
export default {
  components: {},
  props: {
    chips: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {}
};
</script>
