import HomeV2 from '@/components/homeV2.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import {
  digitalShelfRoute,
  reportsRoute,
  homeRoute,
  advertisingRoute,
  campaignOptimizerChildrenRoutes,
  hourlyBidderRoutes
} from '@/router/commonRoutePaths.js';
import CONSTANTS from '@/utils/constants';
import { getCampaignManagementChildrenRoutes } from '@/router/krogerpromoteiq.js';
import urlHelper from '@/utils/helpers/url';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const chewyPromoteIqRoutes = {
  path: '/us/chewy_promoteiq/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          'feature.pages.chewy.landingPage',
          'digital_shelf/market_insights'
        );
      }
    },
    reportsRoute,
    digitalShelfRoute,
    {
      ...advertisingRoute,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes.map((route) => ({
            ...route,
            meta: {
              productLine: CONSTANTS.PRODUCT_LINE.RMM,
              appendEsDataSetName: true
            }
          }))
        },
        ...hourlyBidderRoutes,
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutes('chewy_promoteiq')
        },
        {
          path: '',
          redirect: () => {
            return 'campaign_management';
          }
        }
      ]
    },
    ...commonInternalRoutesArray
  ]
};

export { chewyPromoteIqRoutes };
