<template>
  <div class="u-spacing-pt-xl">
    <div
      v-for="(step, index) in steps"
      :key="index"
    >
      <CollapsibleTitle
        class="u-spacing-pb-l"
        :is-expanded="step.isExpanded"
        :title="computedTitle + ' ' + step.selectedActionType"
        @click="step.isExpanded = !step.isExpanded"
      />
      <div
        v-if="step.isExpanded"
        class="u-spacing-pb-xl u-display-flex"
      >
        <div
          class="u-display-flex u-min-width-240px u-flex-justify-content-center u-flex-direction-column u-border-right u-border-color-grey-xx-light u-border-width-s"
        >
          <div
            v-for="(actionType, itemIndex) in step.radioOptions"
            :key="itemIndex"
            class="u-spacing-mv-s u-font-size-5"
          >
            <rb-radio
              v-model="step.selectedRadio"
              :native-value="actionType.value"
            >
              {{ actionType.title }}
            </rb-radio>
          </div>
        </div>
        <div
          v-for="(actionType, actionTypeIndex) in step.radioOptions"
          :key="actionTypeIndex"
        >
          <div
            v-if="actionType.value === step.selectedRadio"
            class="u-spacing-pl-xl"
          >
            <div
              v-for="(subGroup, j) in actionType.children"
              :key="j"
              :class="{
                'u-spacing-pb-l': j !== actionType.children.length - 1
              }"
              class="u-width-100 u-display-flex u-flex-direction-column"
            >
              <div class="u-width-100 u-font-size-5 u-spacing-pb-m">
                {{ subGroup.subGroup }}:
              </div>
              <div class="u-display-flex">
                <div
                  v-for="(fieldItems, subGroupIndex) in subGroup.fields"
                  :key="subGroupIndex"
                  class="u-max-width-150px"
                >
                  <div class="u-spacing-mr-m">
                    <div class="u-font-size-6 u-spacing-pb-xs">
                      {{ fieldItems.name }}
                    </div>
                    <div class="u-display-flex u-width-100 u-position-relative">
                      <rb-input
                        v-model="fieldItems.formData.priceValue"
                        :rounded="true"
                        type="number"
                        :step="0.01"
                        :min="fieldItems.formConfig.validationObj.absolute.min"
                        :max="fieldItems.formConfig.validationObj.absolute.max"
                        class="u-flex-1"
                        @input="handleInput"
                      />
                      <div
                        class="u-font-size-6 u-color-grey-light percentage u-position-absolute"
                      >
                        %
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="subGroup.guardRail">
                  <div class="u-spacing-mr-m">
                    <div
                      class="u-font-size-6 u-spacing-pb-xs u-spacing-pl-m u-color-orange-base"
                    >
                      {{ subGroup.guardRail.name }}
                    </div>
                    <div
                      class="u-display-flex u-width-100 u-position-relative u-spacing-pl-m u-border-left u-border-color-grey-xxx-light u-border-width-s"
                    >
                      <rb-input
                        v-model="
                          subGroup.guardRail.formData[
                            subGroup.guardRail.formConfig.target
                          ]
                        "
                        :rounded="true"
                        type="number"
                        :step="0.01"
                        :min="
                          subGroup.guardRail.formConfig.validationObj.absolute
                            .min
                        "
                        :max="
                          subGroup.guardRail.formConfig.validationObj.absolute
                            .max
                        "
                        class="u-flex-1"
                        @input="handleInput"
                      />
                      <div
                        class="u-font-size-6 u-color-grey-light percentage u-position-absolute"
                      >
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CollapsibleTitle from '@/components/pages/strategy_builder/atoms/collapsible-title.vue';
export default {
  components: {
    CollapsibleTitle
  },
  props: {
    steps: {
      type: Array,
      default: []
    },
    selectedEntityType: {
      type: String,
      default: 'campaign'
    }
  },
  computed: {
    computedTitle() {
      return `Change on ${this.selectedEntityType}s where bid multiplier is`;
    }
  },
  methods: {
    handleInput() {
      this.$emit('form-data-updated');
    },
    isCeilingOrFloor(fieldName) {
      return fieldName.includes('Ceiling') || fieldName.includes('Floor');
    }
  }
};
</script>
<style scoped>
.u-max-width-150px {
  max-width: 150px;
}
.percentage {
  right: 8px;
  top: 12px;
}
</style>
