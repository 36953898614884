<template>
  <div
    class="u-flex-1 u-display-flex u-flex-align-items-center u-width-100 u-flex-justify-content-center u-position-fixed u-flex-direction-column widget--full-screen"
  >
    <div class="u-font-size-3">
      <div
        v-for="(campaign, index) in createdCampaigns"
        :key="index"
        class="u-display-flex"
      >
        <div class="u-spacing-pr-s">
          <rb-icon
            :class="
              campaign.code === 'SUCCESS'
                ? 'u-color-green-base'
                : 'u-color-red-base'
            "
            :icon="
              campaign.code === 'SUCCESS'
                ? 'check-fill-circle'
                : 'cross-fill-circle'
            "
          />
        </div>
        <div v-if="campaign.code === 'SUCCESS'">
          <span
            data-cy="launchedSuccessfully"
            class="u-font-weight-600"
            >"{{ campaign.campaignName }}"</span
          >
          is launched Successfully
        </div>
        <div v-else>
          <span
            data-cy="failedToLaunch"
            class="u-font-weight-600"
            >"{{ campaign.campaignName }}"</span
          >
          Failed to launch
        </div>
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-l">
      <div class="u-spacing-pr-m">
        <rb-button
          data-cy="createAnotherCampaign"
          text="Create another Campaign"
          type="hollow"
          size="l"
          :click-fn="createCampaign"
        />
      </div>
      <rb-button
        data-cy="viewInCampaignManagement"
        text="View in Campaign Management"
        type="filled"
        size="l"
        :click-fn="routeToCampaigns"
      />
    </div>
  </div>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';
import Vue from 'vue';

export default {
  data() {
    return {
      pageData: null
    };
  },
  computed: {
    createdCampaigns() {
      return (
        this.$store.getters['campaignCreation/getAllStepsData']
          ?.campaignsCreated || []
      );
    },
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  created() {
    this.pageData = config?.[this.retailer];
  },
  methods: {
    createCampaign() {
      let createCampaign = {
        name: 'setup_suggested_campaign',
        query: {
          create: true
        }
      };
      if (this.$route?.query?.flow === 'amazon') {
        createCampaign = { name: 'create_campaigns' };
      }
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push(createCampaign);
    },
    routeToCampaigns() {
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: 'Campaigns'
      });
    }
  }
};
</script>

<style></style>
