var removeKeys = [
  'insights_content_selected_filters',
  'insights_po_fill_rate_selected_filters',
  'insights_reviews_selected_filters'
];

for (var i = 0; i < removeKeys.length; i++) {
  localStorage.removeItem(removeKeys[i]);
}

var migrateToNewDatePickerKeys = [
  { key: 'insight-financial-filters', default: 'last90Days' },
  { key: 'dw-filters', default: 'last90Days' },
  { key: 'po-fillrate-filters', default: 'last90Days' },
  { key: 'sales-dashboard-filters', default: 'last7Days' },
  { key: 'campaigns-filters', default: 'last30Days' },
  { key: 'keywords-filters', default: 'last30Days' },
  { key: 'cm-skus-filters', default: 'last30Days' },
  { key: 'searchTerms-filters', default: 'last30Days' },
  { key: 'budgetPlanner-filters-v2', default: 'last30Days' },
  { key: 'campaignsManualActions-filters', default: 'last30Days' },
  { key: 'keywordsManualActions-filters', default: 'last30Days' }
];

var datePickerMigrated = JSON.parse(localStorage.getItem('datePickerMigrated'));

if (!datePickerMigrated) {
  for (i = 0; i < migrateToNewDatePickerKeys.length; i++) {
    var localStorageData = JSON.parse(
      localStorage.getItem(migrateToNewDatePickerKeys[i].key)
    );
    if (localStorageData && localStorageData.date_range) {
      localStorageData.date_range.name = migrateToNewDatePickerKeys[i].default;
      localStorageData.date_range.compare_name = null;
      delete localStorageData.date_range.value;
      localStorage.setItem(
        migrateToNewDatePickerKeys[i].key,
        JSON.stringify(localStorageData)
      );
    }
  }

  localStorage.setItem('datePickerMigrated', true);
}
