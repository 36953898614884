var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-height-100 u-font-size-5 u-display-flex u-flex-direction-column u-flex-align-items-center"},[_c('Loader',{staticClass:"fill--parent",attrs:{"loading":_vm.loading,"color":'#3fadf7',"size":'4rem',"thickness":'.2rem'}}),_c('div',{staticClass:"u-spacing-mv-xl",attrs:{"loading":!_vm.loading}},[_c('div',{staticClass:"u-spacing-pv-m u-font-size-3 u-text-align-center"},[_vm._v(" Invite Users by their Email Ids ")]),_c('table',{staticClass:"invite-user-table"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.roles),function(role,roleIndex){return _c('th',{key:roleIndex},[_vm._v(" "+_vm._s(role)+" ")])}),_c('th')],2)]),_c('tbody',_vm._l((_vm.users),function(user,index){return _c('tr',{key:index},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(user.mail),expression:"user.mail"}],staticClass:"u-spacing-pv-s",attrs:{"type":"text","placeholder":"email"},domProps:{"value":(user.mail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(user, "mail", $event.target.value)}}})]),_vm._l((_vm.roles),function(role,roleIndex1){return _c('td',{key:roleIndex1},[_c('div',{staticClass:"toggle",class:{
                on: user.roles.indexOf(role) !== -1,
                off: user.roles.indexOf(role) === -1
              },on:{"click":function($event){return _vm.toggleRoleOnNewUser(user, role)}}},[_c('div')])])}),_c('td',[(_vm.users.length > 1)?_c('rb-button',{attrs:{"text":'X',"click-fn":_vm.removeUser.bind(this, index)}}):_vm._e()],1)],2)}),0)]),_c('div',{staticClass:"u-spacing-mt-m u-display-flex u-flex-justify-content-center"},[_c('rb-button',{staticClass:"u-spacing-mr-m",attrs:{"type":'filled',"text":'Add more users',"click-fn":_vm.addMoreUsers}}),_c('rb-button',{attrs:{"type":'filled',"text":'Invite',"click-fn":_vm.triggerInvite}})],1)]),(_vm.response && _vm.response.status === 'success')?_c('div',{staticClass:"u-spacing-pv-m u-font-size-5"},_vm._l((_vm.response.message),function(a,msgIndex){return _c('div',{key:msgIndex},[_vm._v(" "+_vm._s(a.user)+" - "+_vm._s(a.error)+" ")])}),0):_vm._e(),(_vm.response && _vm.response.status === 'failure')?_c('div',{staticClass:"error_section u-spacing-pv-m u-font-size-5"},[_vm._v(" "+_vm._s(_vm.response.message)+" ")]):_vm._e(),(_vm.showUserInviteLink)?_c('div',{staticClass:"u-spacing-pv-m u-font-size-3 u-color-red-base"},[_c('rb-icon',{staticClass:"rb-icon--medium u-spacing-mr-s",attrs:{"icon":"info-circle-fill"}}),_vm._v("No welcome email enabled!!! Please copy and share the URL with the user ")],1):_vm._e(),_c('div',{staticClass:"u-spacing-pv-m u-font-size-3"},[_vm._v("Existing Users")]),_c('div',{staticClass:"u-spacing-mb-xxl"},[_c('table',{staticClass:"userTable"},[_vm._m(0),_c('tbody',{staticClass:"u-spacing-pb-xl"},_vm._l((_vm.existingUsers),function(user,userIndex){return _c('tr',{key:userIndex,class:{ inactive: user.brUserEnable === 'FALSE' }},[_c('td',[_vm._v(_vm._s(user.mail))]),_c('td',[_vm._v(_vm._s(user.roles.join(', ')))]),(user.brUserEnable === 'TRUE')?_c('td',[_vm._v("activated")]):_c('td',[_vm._v("deactivated")]),_c('td',[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('rb-button',{attrs:{"text":'Edit',"size":'s',"click-fn":_vm.editUser.bind(this, user)}}),(
                  _vm.showUserInviteLink && user.passwordResetLinkUsed === 'false'
                )?_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"u-spacing-ml-s",attrs:{"title":"Copy to clipboard"},on:{"click":function($event){return _vm.copyToClipboard($event, user)}}},[_c('rb-icon',{staticClass:"u-color-grey-lighter u-cursor-pointer",attrs:{"icon":'copy-to-clipboard'}})],1):_vm._e()],1)])])}),0)])]),(_vm.showConfirmPopup)?_c('div',[_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmPopup,"title":"Add new email Org","confirm-text":"Add anyways","message":_vm.confirmMessage},on:{"confirm":function($event){return _vm.inviteUsers()},"cancel":function($event){_vm.showConfirmPopup = false}}})],1):_vm._e(),(_vm.userToUpdate)?_c('div',{staticClass:"u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center"},[_c('div',{staticClass:"u-overflow-hidden"},[_c('div',{staticClass:"u-bg-color-blue-base u-spacing-p-m u-color-white-base u-font-weight-700"},[_c('span',{staticClass:"u-spacing-mv-m u-font-size-4 u-text-align-center"},[_vm._v(_vm._s(_vm.userToUpdate.mail))])]),_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between u-spacing-pv-m",staticStyle:{"width":"50%","margin":"auto"}},[_c('div',[_vm._v("Account Status")]),_c('div',{staticClass:"toggle",class:{
            on: _vm.userToUpdate.brUserEnable === 'TRUE',
            off: _vm.userToUpdate.brUserEnable === 'FALSE'
          },on:{"click":_vm.toggleActStatus}},[_c('div')])]),_c('h4',{staticClass:"u-font-size-5 u-text-align-center"},[_vm._v("Roles")]),_vm._l((_vm.roles),function(role,roleIndex){return _c('div',{key:roleIndex,staticClass:"u-display-flex u-flex-justify-content-space-between u-spacing-pv-s",staticStyle:{"width":"50%","margin":"auto"}},[_c('div',[_vm._v(_vm._s(role))]),_c('div',{staticClass:"toggle",class:{
            on: _vm.userToUpdate.roles.indexOf(role) !== -1,
            off: _vm.userToUpdate.roles.indexOf(role) === -1
          },on:{"click":function($event){return _vm.toggleRole(role)}}},[_c('div')])])}),_c('div',{staticClass:"u-display-flex u-flex-justify-content-center u-spacing-p-m"},[_c('rb-button',{staticClass:"u-spacing-mr-s",attrs:{"text":'Cancel',"type":'hollow',"size":'s',"click-fn":_vm.closeModal}}),_c('rb-button',{attrs:{"text":'Save',"type":'filled',"size":'s',"click-fn":_vm.save}})],1)],2)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("User")]),_c('th',[_vm._v("Roles")]),_c('th',[_vm._v("Status")]),_c('th')])])}]

export { render, staticRenderFns }