import CampaignAdGroup from '@/components/ams/campaign_creation/steps/edit/campaignAdGroup/index.vue';
import ProductTargeting from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/productTargeting.vue';
import {
  productActionState,
  categoriesActionState,
  createAdGroupAddProductActionState,
  createAdGroupAddCategoriesActionState,
  addNegativeTargetsActionState
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';
import CampaignTargeting from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/index.vue';
import CampaignTargetingKeywordAndProduct from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/campaignTargetting.vue';
import Classification from '@/components/ams/campaign_creation/steps/edit/classification/index.vue';
import Budget from '@/components/ams/campaign_creation/steps/edit/budget/index.vue';
import TimePeriod from '@/components/ams/campaign_creation/steps/edit/timePeriod/index.vue';
import Creative from '@/components/ams/campaign_creation/steps/edit/campaignCreative/index.vue';
import BiddingStrategySB from '@/components/ams/campaign_creation/steps/edit/campaignBiddingStrategy/sbBiddingStrategy.vue';
import {
  keywordTargetingState,
  negativeKeywordTargetingState,
  keywordTargetingStateForCreateAdGroup,
  negativeKeywordTargetingStateForCreateAdGroup
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/keywordTargeting.js';
import bidsByTargetingGroupComponent from '@/components/ams/campaign_creation/edit_configs/amazon/common/autoTargeting.vue';
import { adgroupCustomActionPanel } from '@/components/ams/campaign_creation/edit_configs/amazon/common/adgroupConfig.js';
import BiddingStrategy from '@/components/ams/campaign_creation/steps/edit/campaignBiddingStrategy/index.vue';
import AdFormat from '@/components/ams/campaign_creation/steps/edit/campaignAdFormat/index.vue';

export const adgroupConfig = {
  name: 'Ad Group',
  description: "add SKU's",
  targetingType: ['product', 'keyword'],
  customActionPanelCustomState: adgroupCustomActionPanel,
  header: {
    title: 'Ad Group',
    desc: [
      'Ad groups are a way to organize, manage, and track performance of the products within your campaign. Give your ad group a name that is descriptive and meaningful to you, based on the products you want to advertise. For example, by category, like “Barbeque grills.”'
    ]
  },
  key: 'adgroup',
  keyToRead: 'skus',
  breadCrumbTitle: 'Ad Group',
  textMapping: 'skus',
  autoTargetingVisibility: true,
  component: CampaignAdGroup
};

export const adFormat = {
  name: 'Ad Format',
  description: 'Select campaign Ad Format',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'Ad Format',
    desc: [
      'Strat date is the date when you campaign will start and end date is the date when your campaign will end and The budget is the amount you’re willing to spend on your campaign.'
    ]
  },
  key: 'ad_format',
  keyToRead: 'ad_format',
  breadCrumbTitle: '',
  textMapping: 'ad_format',
  component: AdFormat
};

export const creative = {
  name: 'Creative',
  description: 'Creative',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'Creative',
    desc: [
      'Ad groups are a way to organize, manage, and track performance of the products within your campaign. Give your ad group a name that is descriptive and meaningful to you, based on the products you want to advertise. For example, by category, like “Barbeque grills.”'
    ]
  },
  key: 'creative',
  keyToRead: 'creative',
  breadCrumbTitle: '',
  textMapping: 'creative',
  component: Creative
};

export const productTargeting = {
  name: 'Targeting',
  description: 'Add Product Targets',
  targetingType: ['product'],
  header: {
    title: 'What are the products and categories you would like to target?',
    desc: [
      'Your product targets determine where your ads show up for prospective shoppers on Amazon'
    ]
  },
  customActionPanelCustomState: productActionState,
  categoriesActionPanelState: categoriesActionState,
  key: 'productTarget',
  keyToRead: 'targeting',
  breadCrumbTitle: '',
  textMapping: 'targeting',
  component: ProductTargeting
};

export const keywordTargeting = {
  name: 'Targeting',
  description: "Add keyword's",
  targetingType: ['keyword'],
  header: {
    title: 'What are the keywords you would like to target?',
    desc: [
      'Your keywords (word combinations and phrases) are used to match your ads with search terms shoppers are using to find products.'
    ]
  },
  key: 'keywordTarget',
  customActionPanelCustomState: keywordTargetingState,
  customActionPanelNegativeTargetingCustomState: negativeKeywordTargetingState,
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the keywords you would like to target?',
  textMapping: 'targeting',
  component: CampaignTargeting
};

export const productAndKeywordTargeting = {
  name: 'Targeting',
  description: 'Add Product Targets',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'What is targeting?',
    desc: [
      'Targeting uses keywords and products to help your ads appear in search and detail pages.'
    ]
  },
  customActionPanelKeywordTargetingCustomState:
    keywordTargetingStateForCreateAdGroup,
  customActionPanelNegativeKeywordTargetingCustomState:
    negativeKeywordTargetingStateForCreateAdGroup,
  customActionPanelCategoriesTargetingCustomState:
    createAdGroupAddCategoriesActionState,
  customActionPanelProductTargetingCustomState:
    createAdGroupAddProductActionState,
  key: 'keywordTarget',
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the keywords you would like to target?',
  textMapping: 'targeting',
  autoTargetingVisibility: false,
  component: CampaignTargetingKeywordAndProduct
};

export const productAndAudianceTargeting = {
  name: 'Targeting',
  radioOptions: [
    //  Uncomment this code to enable audience targeting
    // {
    //   type: 'Audience Targeting',
    //   label: 'Audience Targeting',
    //   desc:
    //     'Choose which audiences you want to see your ads'
    // },
    {
      type: 'Product Targeting',
      label: 'Product Targeting',
      desc: 'Choose categories, products, brands related to your product'
    }
  ],
  description: 'Add Product Targets',
  targetingType: ['product', 'audience'],
  header: {
    title: 'What is targeting?',
    desc: [
      'Targeting uses products and audiences to help your ads appear in search and detail pages.'
    ]
  },
  customActionPanelKeywordTargetingCustomState: (vueRef) => {
    return null;
  },
  customActionPanelNegativeKeywordTargetingCustomState:
    addNegativeTargetsActionState,
  customActionPanelCategoriesTargetingCustomState:
    createAdGroupAddCategoriesActionState,
  customActionPanelProductTargetingCustomState:
    createAdGroupAddProductActionState,
  showNegativeTargeting: true,
  key: 'productTarget',
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the products you would like to target?',
  textMapping: 'targeting',
  component: CampaignTargetingKeywordAndProduct
};

/** Start - Auto Targeting step */
export const autoTargetingGroups = [
  {
    name: 'Close Match',
    expression: 'queryHighRelMatches',
    prefix: '$',
    checked: false,
    value: 0
  },
  {
    name: 'Loose Match',
    expression: 'queryBroadRelMatches',
    prefix: '$',
    checked: false,
    value: 0
  },
  {
    name: 'Substitutes',
    expression: 'asinSubstituteRelated',
    prefix: '$',
    checked: false,
    value: 0
  },
  {
    name: 'Complements',
    expression: 'asinAccessoryRelated',
    prefix: '$',
    checked: false,
    value: 0
  }
];

export const autoTargeting = {
  name: 'Automatic Targeting',
  description: 'Add Product Targets',
  targetingType: ['default_bid', 'bids_by_targeting_group'],
  radioOptions: [
    {
      type: 'default_bid',
      label: 'Default Bid',
      desc: 'Default bid applies to all clicks unless you set a different bid for a keyword. Based on the bidding strategy and the placament increase you choose, your bids might change.'
    },
    {
      type: 'bids_by_targeting_group',
      label: 'Set bids by targeting group',
      desc: 'Targeting groups use multiple strategies to match your ads to shoppers looking fo your products.'
    }
  ],
  rows: autoTargetingGroups,
  tableHeader: 'Set bids by targeting group',
  header: {
    title: 'What is targeting?',
    desc: [
      'Targeting uses keywords and products to help your ads appear in search and detail pages.'
    ]
  },
  default_bid_component: Budget,
  customActionPanelNegativeKeywordTargetingCustomState:
    negativeKeywordTargetingStateForCreateAdGroup,
  bids_by_targeting_group_component: bidsByTargetingGroupComponent,
  customActionPanelKeywordTargetingCustomState:
    keywordTargetingStateForCreateAdGroup,
  customActionPanelCategoriesTargetingCustomState:
    createAdGroupAddCategoriesActionState,
  customActionPanelProductTargetingCustomState:
    createAdGroupAddProductActionState,
  key: 'automaticTargeting',
  keyToRead: 'automaticTargeting',
  breadCrumbTitle: 'What are the keywords you would like to target?',
  textMapping: 'targeting',
  autoTargetingVisibility: true,
  component: CampaignTargetingKeywordAndProduct,
  getDescription: (campaignToEdit) => {
    const autoTargetingTypeMap = {
      default_bid: 'Default bid',
      bids_by_targeting_group: 'Set bids by targeting group'
    };
    let description = `${
      autoTargetingTypeMap[campaignToEdit.autoTargetingMetbod]
    }`;
    if (campaignToEdit?.autoTargetingMetbod === 'default_bid') {
      description += `: $${campaignToEdit?.auto_default_Bid || 100}`;
    } else if (campaignToEdit?.targets?.length) {
      const targetinGroups = campaignToEdit?.targets.filter((item) => {
        return item.checked;
      });
      const targetingGroupsLenght = targetinGroups?.length || 0;
      description += `:  ${targetingGroupsLenght} targeting group${
        targetingGroupsLenght > 1 ? "'s" : ''
      } selected`;
    }
    return description;
  },
  handleValidation: (newBid) => {
    let error = '';
    if (parseFloat(newBid) < 0.2 || parseFloat(newBid) > 1000) {
      error = 'Bid should be between $0.2 and $1000.';
    } else {
      error = '';
    }
    return error;
  },
  bidApiConfigs: {
    profileId: ':profileId',
    client: null,
    request: {
      recommendationType: 'BIDS_FOR_NEW_AD_GROUP',
      asins: ':asins', // ['B002ITJOJO', 'B000PGE032', 'B002Y682FS'],
      targetingExpressions: [
        { type: 'CLOSE_MATCH' },
        { type: 'LOOSE_MATCH' },
        { type: 'SUBSTITUTES' },
        { type: 'COMPLEMENTS' }
      ],
      bidding: {
        strategy: 'AUTO_FOR_SALES',
        adjustments: null
      }
    }
  },
  res: {
    bidRecommendations: [
      {
        theme: 'CONVERSION_OPPORTUNITIES',
        impactMetrics: {
          clicks: {
            values: [
              {
                lower: 152,
                upper: 205
              },
              {
                lower: 303,
                upper: 409
              },
              {
                lower: 454,
                upper: 614
              }
            ]
          },
          orders: {
            values: [
              {
                lower: 15,
                upper: 20
              },
              {
                lower: 29,
                upper: 40
              },
              {
                lower: 44,
                upper: 59
              }
            ]
          }
        },
        bidRecommendationsForTargetingExpressions: [
          {
            targetingExpression: {
              type: 'CLOSE_MATCH',
              value: null
            },
            bidValues: [
              {
                suggestedBid: 0.29
              },
              {
                suggestedBid: 0.39
              },
              {
                suggestedBid: 0.6
              }
            ]
          },
          {
            targetingExpression: {
              type: 'LOOSE_MATCH',
              value: null
            },
            bidValues: [
              {
                suggestedBid: 0.19
              },
              {
                suggestedBid: 0.34
              },
              {
                suggestedBid: 0.54
              }
            ]
          },
          {
            targetingExpression: {
              type: 'SUBSTITUTES',
              value: null
            },
            bidValues: [
              {
                suggestedBid: 0.21
              },
              {
                suggestedBid: 0.3
              },
              {
                suggestedBid: 0.56
              }
            ]
          },
          {
            targetingExpression: {
              type: 'COMPLEMENTS',
              value: null
            },
            bidValues: [
              {
                suggestedBid: 0.79
              },
              {
                suggestedBid: 0.89
              },
              {
                suggestedBid: 1.04
              }
            ]
          }
        ]
      }
    ]
  }
};
/** End - Auto Targeting Step */

export const classification = {
  name: 'Classification',
  description: 'Select a profile',
  targetingType: ['product', 'keyword'],
  getProfiles: {
    cubeName: 'client_profile_info',
    measuresList: ['profile_name'],
    groupByDimensionsList: ['profilename', 'profile_id'],
    orderByList: [{ dimension: 'profilename', direction: 'ASC' }]
  },
  key: 'classification',
  getPortfolios: {
    cubeName: 'client_portfolio_info',
    measuresList: ['portfolio_name'],
    groupByDimensionsList: ['portfolio_id', 'profile_id'],
    orderByList: [{ dimension: 'portfolio_name', direction: 'ASC' }]
  },
  header: {
    title: 'What is a profile ?',
    desc: ["Profiles represent an advertiser and their account's marketplace."]
  },
  breadCrumbTitle: 'Select a profile',
  keyToRead: 'classification',
  textMapping: 'classification',
  autoTargetingVisibility: true,
  component: Classification
  // component: Budget
};

export const budget = {
  name: 'Budget',
  description: 'Add a daily budget',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'What is the budget for this campaign?',
    desc: ['The budget is the amount you’re willing to spend on your campaign.']
  },
  breadCrumbTitle: 'What is the budget for this campaign?',
  keyToRead: 'budget',
  key: 'budget',
  textMapping: 'budget',
  autoTargetingVisibility: true,
  component: Budget
};

export const bid = {
  name: 'Default Bid',
  description: 'Update a default bid',
  targetingType: ['product', 'keyword'],
  breadCrumbTitle: 'What is the budget for this campaign?',
  keyToRead: 'bid',
  key: 'bid',
  textMapping: 'bid',
  autoTargetingVisibility: true,
  component: Budget
};

export const timePeriod = {
  name: 'Time Period',
  description: 'Select a time period',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'What is the time period of this Campaign?',
    desc: [
      'Strat date is the date when you campaign will start and end date is the date when your campaign will end.'
    ]
  },
  breadCrumbTitle: 'What is the time period of this Campaign?',
  keyToRead: 'budget',
  key: 'timePeriod',
  textMapping: 'budget',
  autoTargetingVisibility: true,
  component: TimePeriod
};

export const biddingStrategySP = {
  name: 'Bidding Strategy',
  description: 'Select a bidding strategy',
  showBidPlacements: true,
  boolAdjustHalfFlex: false,
  targetingType: ['product', 'keyword'],
  header: {
    title: 'What is the campaign bidding strategy?',
    desc: ['Choose how you want to pay for clicks on your ads.']
  },
  breadCrumbTitle: 'What is the campaign bidding strategy?',
  keyToRead: 'campaigns',
  key: 'biddingStrategy',
  textMapping: 'campaigns',
  autoTargetingVisibility: true,
  component: BiddingStrategy
};

export const biddingStrategySB = {
  component: BiddingStrategySB,
  name: 'Bidding Strategy',
  description: 'Select a bidding strategy',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'What is the campaign bidding strategy?',
    desc: ['Choose how you want to pay for clicks on your ads.']
  },
  breadCrumbTitle: 'What is the campaign bidding strategy?',
  keyToRead: 'campaigns',
  key: 'biddingStrategy',
  textMapping: 'campaigns'
};

export const biddingStrategySD = {
  name: 'Bidding Strategy',
  description: 'Select a bidding strategy',
  showBidPlacements: false,
  boolAdjustHalfFlex: true,
  showAdjustBidForStrategy: 'reach',
  targetingType: ['product', 'audience'],
  header: {
    title: 'What is the campaign bidding strategy?',
    desc: ['Choose how you want to pay for clicks on your ads.']
  },
  breadCrumbTitle: 'What is the campaign bidding strategy?',
  keyToRead: 'campaigns',
  key: 'biddingStrategy',
  textMapping: 'campaigns',
  component: BiddingStrategy,
  biddingStrategy: [
    {
      type: 'reach',
      label: 'Optimize for viewable Impressions',
      desc: 'We will optimize your bids for higher viewable impressions.Drive product awareness by showing your ad to relevant audience on and off amazonto maximum reach.'
    },
    {
      type: 'clicks',
      label: 'Optimize for page visits',
      desc: 'We will optimize your bids for higher detail page visits. Drive product consideration by showing your ads to shoppers more likely to click on your ad.'
    },
    {
      type: 'conversions',
      label: 'Optimise for conversions',
      desc: 'We will optimize your bids for higher conversion rates.Drive sales by showing your ad to shoppers more likely to purchase yours products.'
    }
  ]
};
