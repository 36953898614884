import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import dataTransformer from '@/utils/services/data-transformer.js';
import { generateMatchTypeRows } from '@/pages/entity_details/configs/amazon/actionConfigs.js';
import dictionary from '@/pages/entity_details/configs/amazon/dictionary.js';
import {
  addAll,
  addEntity,
  leftCustomBidValues
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/bulkCustomBid.js';
import Vue from 'vue';
import {
  generateRightHeaderFilterCheckBox,
  updateRightTableFilterFn,
  suggestedBidRowClassRules
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/keywordTargeting.js';
const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};
export const categoryHierarchyExpandLevels = [
  {
    level: 1,
    getter: 'campaignCreation/getAllCategoriesLevel1',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 2,
    getter: 'campaignCreation/getAllCategoriesLevel2',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 3,
    getter: 'campaignCreation/getAllCategoriesLevel3',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 4,
    getter: 'campaignCreation/getAllCategoriesLevel4',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 5,
    getter: 'campaignCreation/getAllCategoriesLevel5',
    action: 'campaignCreation/fetchCategoriesLevel'
  }
];
export const allCatergoriesLevelCube = {
  cubeName: 'categories_master',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['id', 'name', 'level1id', 'level1name'],
  groupByDimensionsList: ['id', 'name', 'level1id', 'level1name'],
  dimensionList: [],
  orderByList: [],
  dedupBeforeRollup: {
    enableDedupBeforeRollup: true,
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'id',
      'level1name'
    ]
  }
};

const addAllCategory = (vueRef, isAdGroupCreation = false) => {
  if (
    !vueRef.suggestedCustomBidInput ||
    vueRef.suggestedCustomBidInput === '0' ||
    vueRef.suggestedCustomBidInput > 1000 ||
    vueRef.suggestedCustomBidInput <= 0
  ) {
    vueRef.$snackbar.open({
      message: 'Please add a valid custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  const rowsToAddToRightTable = [];

  let leftTable = vueRef.leftTableRows;
  if (vueRef?.expandLeftTableConfig?.hasExpand) {
    leftTable = vueRef.leftTableInstance.localTableRow;
  }

  for (const row of leftTable) {
    let isRowPresent = false;
    row.isRowSelected = true;
    for (const rightTableRow of vueRef.rightTableRows) {
      if (
        row[vueRef.config.primaryKey] ===
        rightTableRow[vueRef.config.primaryKey]
      ) {
        isRowPresent = true;
      }
    }
    if (!isRowPresent) {
      row.suggested_bid = '-';
      row.new_bid = vueRef.suggestedCustomBidInput;
      rowsToAddToRightTable.push(row);
    }
  }
  vueRef.rightTableRows = [
    ...vueRef.computedRightTableRows,
    ...rowsToAddToRightTable
  ];
  if (vueRef?.expandLeftTableConfig?.hasExpand) {
    vueRef.leftTableInstance.redrawTableRows();
  } else {
    vueRef.leftTableRows = [...vueRef.leftTableRows];
  }

  if (vueRef.fetchedRightTableRows.rows.length) {
    const primaryKey = vueRef.config.primaryKey;
    vueRef.rightTableRows = vueRef.rightTableRows.filter(
      (item) =>
        vueRef.fetchedRightTableRows?.rows?.findIndex(
          (apiData) => apiData[primaryKey] === item[primaryKey]
        ) === -1
    );
  }
};

const addEntityCategory = (row, vueRef, isAdGroupCreation = false) => {
  row.params.data.suggested_bid = vueRef.suggestedCustomBidInput;
  row.params.data.suggested_bid = '-';
  row.params.data.new_bid = vueRef.suggestedCustomBidInput;
  if (
    !vueRef.suggestedCustomBidInput ||
    vueRef.suggestedCustomBidInput === '0' ||
    vueRef.suggestedCustomBidInput > 1000 ||
    vueRef.suggestedCustomBidInput <= 0
  ) {
    vueRef.$snackbar.open({
      message: 'Please add a valid custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  if (!row.params.data?.isRowSelected) {
    row.params.data.isRowSelected = true;
    vueRef.leftTableInstance.redrawTableRows([row.params.node]);
  }
  let rowExistsInRightTable = false;
  const tempRightTableRows = [...vueRef.computedRightTableRows];
  const that = vueRef;
  vueRef.computedRightTableRows.forEach((rightTableRow) => {
    if (
      rightTableRow[that.config.primaryKey] ===
      row.params.data[that.config.primaryKey]
    ) {
      rowExistsInRightTable = true;
    }
  });
  if (!rowExistsInRightTable) {
    tempRightTableRows.push(row.params.data);
    vueRef.rightTableRows = tempRightTableRows;
    vueRef.rightTableInstance.redrawTableRows();
  }
};

const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

export const getSkusFromAllStepsData = (context) => {
  let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const { adFormat, adFormatSubType, skus, creativeSkus, selectedPages } =
    allStepsData?.suggestedCampaigns?.[suggestedCampaignIndex] || {};
  let suggestedSkus = cloneDeep((skus?.length && skus) || []);
  if (adFormat === 'storeSpotlight') {
    const newSkus = [];
    for (const item of selectedPages) {
      newSkus.push(item.selectedSku);
    }
    suggestedSkus = cloneDeep((newSkus?.length && newSkus) || []);
  } else if (
    adFormat === 'productCollection' &&
    adFormatSubType === 'new_landing_page'
  ) {
    suggestedSkus = cloneDeep((skus?.length && skus) || []);
  } else if (adFormat === 'video' || adFormat === 'productCollection') {
    suggestedSkus = cloneDeep(
      (creativeSkus?.length && creativeSkus) || (skus?.length && skus) || []
    );
  }
  let skuValues = '';
  suggestedSkus.forEach((item) => {
    skuValues += `'${item.asin}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));
  return skuValues;
};

const getTableDate = (context, type = 'targeting') => {
  let apiRequest = {
    limit: 50,
    page: 1
  };
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const suggestedCampaignId = context?.$route?.query?.key?.split('-')?.[0];
  const skuValues = getSkusFromAllStepsData(context);
  const campaignConfig =
    allStepsData?.goalConfig?.customMetadata?.campaigns?.filter(
      (item) => item.suggestedCampaignId === suggestedCampaignId
    );
  apiRequest = campaignConfig?.[0]?.[type]?.request
    ? cloneDeep(campaignConfig?.[0]?.[type]?.request)
    : apiRequest;
  deepReplaceObjectValues(apiRequest, ':asin', skuValues);
  return apiRequest;
};

const getTableDataForCreateAdGroup = (
  context,
  type = 'targeting',
  selectedRecommendation = null
) => {
  let apiRequest = {
    limit: 50,
    page: 1
  };
  let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const suggestedSkus = cloneDeep(
    allStepsData?.suggestedCampaigns?.[suggestedCampaignIndex]?.skus
  );
  let skuValues = '';
  suggestedSkus.forEach((item) => {
    skuValues += `'${item.asin}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));
  let recommendedConfig = {};
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.product?.[type];
  if (recommendedConfigArray.length > 0) {
    if (!selectedRecommendation) {
      recommendedConfig = recommendedConfigArray[0];
    } else {
      recommendedConfig = recommendedConfigArray.filter(
        (item) => item.recommendationOrder === selectedRecommendation.value
      )[0];
    }
    apiRequest = cloneDeep(recommendedConfig?.request);
    deepReplaceObjectValues(apiRequest, ':asin', skuValues);
  }
  return apiRequest;
};

export const keywordLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'Products',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'Products',
          columnTitleKey: 'competitor_asin_title',
          columnAsinKey: 'competitor_asin',
          columnImageUrlKey: 'competitor_asin_image_url',
          coloumnProductUrlKey: 'competitor_asin_product_url',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return (
              params?.data?.competitor_product_title +
              '_' +
              params?.data?.competitor_asin
            );
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () => addAll(vueRef),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: (row) => addEntity(vueRef, row),
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];
};

const taggedCell = Vue.extend({
  props: {},
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class="u-display-flex u-width-100">
      <div v-if="paramsData.competitor_asin_title || paramsData.competitor_asin" class=" u-width-100 u-flex-direction-column">
        <div class="u-text-overflow-ellipsis u-color-grey-base u-flex-1" v-tippy :title="paramsData.competitor_asin_title || paramsData.competitor_asin">
          <span class=" u-font-weight-600">Product:</span> {{paramsData.competitor_asin_title || paramsData.competitor_asin}}</span>
        </div>
        <div>
          <rb-asin
            v-if="paramsData.competitor_asin && paramsData.competitor_asin_title"
            :asin="paramsData.competitor_asin"
            :product-page-url="paramsData.competitor_asin_product_url"
            tippy-text="Amazon Product Page"
          />
        </div>
      </div>
      <div v-if="paramsData.category" class="u-display-flex u-width-100 u-flex-wrap-yes">
        <div class="u-text-overflow-ellipsis u-width-100 u-color-grey-base" v-tippy :title="paramsData.category">
          <span class="u-font-weight-600" >{{ paramsData.isAudience ? 'Audience' : 'Category' }}:</span> {{paramsData.category}}</span>
        </div>
      </div>
      </div>    
    `
});

const audienceCell = Vue.extend({
  props: {},
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: ` <div class="u-display-flex u-width-100">
  <div v-if="paramsData.isAudience" class="u-display-flex u-width-100 u-flex-wrap-yes">
    <div class=" u-font-size-7 u-color-grey-lighter u-spacing-pb-xs">
      Amazon Audience:
    </div>
    <div class="u-color-grey-base u-font-size-6 u-width-100 u-text-overflow-ellipsis" v-tippy :title="paramsData.category">
    {{paramsData.category}}
    </div>
  </div> 
  <div v-else-if="paramsData.category" class="u-display-flex u-width-100 u-flex-wrap-yes">
    <div class=" u-font-size-7 u-color-grey-lighter u-spacing-pb-xs">
      {{paramsData.marketingType === 'views' ? 'View Remarketing' : 'Purchase Remarketing'}}
    </div>
    <div class="u-color-grey-base u-width-100 u-text-overflow-ellipsis u-font-size-6 u-spacing-pb-xs" v-tippy :title="paramsData.category">
    <span class=" u-font-weight-600" v-if="!paramsData.isDynamicSegment">Category:</span> {{paramsData.category}}
    </div>
    <div class="u-color-grey-base u-font-size-6" >
     <span class=" u-font-weight-600">Lookback:</span> {{paramsData.lookback}} days
    </div>
  </div>
  </div>    
`
});

const currency = Vue.extend({
  props: {},
  data() {
    return {
      currency: Vue.prototype.$currency
    };
  },
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class=" u-color-black-base">
        {{currency}} {{paramsData.suggested_bid}}
      </div>    
    `
});

const dictionaryRight = {
  entity_ui_label: {
    title: 'Categories & Product',
    toolTipText: 'Categories & Product',
    type: 'append',
    keyOrder: 1,
    showOnUi: true,
    downloadable: true,
    keys: {
      asin: 'competitor_asin',
      asinTitle: 'competitor_asin_title',
      productUrl: 'competitor_asin_product_url',
      category: 'category'
    }
  },
  suggested_bid: {
    title: 'Suggested Bid',
    toolTipText: 'Suggested Bid',
    type: 'currency',
    keyOrder: 2,
    showOnUi: true,
    downloadable: true
  },
  remove_all: {
    title: 'Remove All',
    type: 'icon',
    icon: 'cross',
    iconClickEvent: 'removeEntity',
    clickHeader: 'removeAll',
    keyOrder: 3,
    showOnUi: true,
    downloadable: true
  }
};

export const dictionaryRightCreateAdGroup = (vueRef) => ({
  entity_ui_label: {
    title: 'Categories & Product',
    toolTipText: 'Categories & Product',
    type: 'append',
    keyOrder: 1,
    showOnUi: true,
    downloadable: true,
    keys: {
      asin: 'competitor_asin',
      asinTitle: 'competitor_asin_title',
      productUrl: 'competitor_asin_product_url',
      category: 'category'
    }
  },
  // If the value if of type array, then we use the getColumnDefinition method
  // from data-transformer. The same one which we use in autoConfigure
  suggested_bid: [
    {
      name: 'suggested bid',
      type: 'custom',
      uiField: {
        widget: 'icon',
        uiLabel: 'Suggested bid',
        uiType: 'custom',
        uiOrder: '3',
        metadata: {
          toolTipText: 'Set suggested bid',
          widget: 'icon',
          type: 'iconText',
          tableColumnName: 'suggested_bid',
          formatType: 'currency',
          displayIcon: 'trending-neutral',
          showOnUi: true,
          isFixed: false,
          width: 90,
          iconClickEvent: (context, event) => {
            var rowNodes = {
              rowNodes: [context.params.node]
            };
            if (!context.params.data.new_bid) {
              context.params.data.new_bid = context.params.data.suggested_bid;
            }
            vueRef.rightTableInstance.redrawTableRows(rowNodes);
          }
          // contextReturnEvent: this.getArrowButtonContext
        }
      }
    }
  ],
  new_bid: [
    {
      name: 'new_bid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '4',
        uiType: 'custom',
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'new_bid',
          defaultValueColumnName: 'new_bid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 100,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validatinon should happen here
            context.params.data.new_bid = context.bidValue;
            vueRef.checkValidityForApply();
          }
        }
      }
    }
  ],
  remove_all: {
    title: 'Remove All',
    type: 'icon',
    icon: 'cross',
    iconClickEvent: 'removeEntity',
    clickHeader: 'removeAll',
    keyOrder: 5,
    showOnUi: true,
    downloadable: true
  }
});

export const categoryColumnDefWithoutAutoConfigure = (vueRef) => [
  {
    showOnUi: true,
    cellClass: null,
    isDownloadable: null,
    headerComponentFramework: 'genericTableHeader',
    title: 'Category',
    headerName: 'Category',
    checkboxSelection: null,
    field: 'categoryUI',
    cellRendererFramework: 'genericTableCell',
    cellRendererParams: { keyType: 'string' },
    minWidth: 170,
    width: 170,
    keyOrder: '1',
    pinned: false,
    suppressSizeToFit: false,
    getQuickFilterText: null,
    headerComponentParams: {
      enableHeaderIconInterAction: false,
      alignHeader: null,
      enableSorting: false,
      headerIcon: null,
      keyType: 'string',
      toolTipText: null,
      clickHeader: null,
      customStyles: null
    }
  },
  {
    showOnUi: true,
    cellClass: null,
    isDownloadable: null,
    headerComponentFramework: 'genericTableHeader',
    title: 'Add all',
    headerName: 'Add all',
    checkboxSelection: null,
    field: 'na',
    cellRendererFramework: 'iconTableCell',
    cellRendererParams: {
      iconSize: 'medium',
      iconClickEvent: (row) => addEntityCategory(row, vueRef),
      alternateIcon: 'check-fill-circle',
      alternateIconClass: 'u-color-green-base',
      displayIcon: 'plus',
      toolTipText: 'Add this category',
      toolTipPosition: 'left',
      contextReturnEvent: undefined,
      type: 'icon'
    },
    minWidth: 110,
    width: 110,
    keyOrder: '2',
    pinned: false,
    suppressSizeToFit: true,
    getQuickFilterText: null,
    headerComponentParams: {
      enableHeaderIconInterAction: false,
      alignHeader: null,
      enableSorting: false,
      headerIcon: {
        icon: 'info-circle-fill',
        toolTip: 'Add all categories on this page'
      },
      keyType: null,
      toolTipText: null,
      clickHeader: () => addAllCategory(vueRef),
      customStyles:
        'cursor:pointer; color:#037ef6 !important; padding-left:15px'
    }
  }
];

export function getColumnDefinition(vueRef, dictionary = dictionaryRight) {
  var _columnDefs = [];
  const selectedDictionary = dictionary;
  for (const i in selectedDictionary) {
    let obj = {};
    if (Array.isArray(selectedDictionary[i])) {
      const tempColDefs = dataTransformer.getColumnDefinition(
        selectedDictionary[i]
      );
      obj = tempColDefs.displayColConfigs[0];
      _columnDefs.push(obj);
      continue;
    }
    obj.headerComponentParams = {};
    obj.field = i;
    obj.title = selectedDictionary[i].title;
    obj.suppressSizeToFit = false;
    obj.resizable = false;
    obj.headerComponentFramework = 'genericTableHeader';
    const keyType = 'string';
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: formatter
    };
    obj.enableSorting = true;
    obj.cellRenderer = cellRenderer;
    obj.headerComponentParams.enableSorting = true;
    obj.width = selectedDictionary[i].width ?? 450;
    if (selectedDictionary[i].type === 'icon') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererFramework = 'iconTableCell';
      obj.cellRendererParams.displayIcon = selectedDictionary[i].icon;
      obj.cellRendererParams.alternateIcon =
        selectedDictionary[i]?.alternateIcon;
      obj.cellRendererParams.alternateIconClass =
        selectedDictionary[i]?.alternateIconClass;
      obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
      obj.cellRendererParams.iconSize = 'medium';
      obj.cellRendererParams.iconClickEvent =
        vueRef[selectedDictionary[i].iconClickEvent];
      obj.headerComponentParams.clickHeader =
        vueRef[selectedDictionary[i].clickHeader];
      obj.headerComponentParams.customStyles = {
        color: '#007cf6',
        display: 'flex',
        width: '100%',
        'justify-content': 'center'
      };
      if (selectedDictionary[i]?.toolTipText) {
        obj.headerComponentParams.headerIcon = {
          icon: 'info-circle-fill',
          toolTip: selectedDictionary[i]?.toolTipText
        };
      }
      obj.width = 120;
    }
    if (
      selectedDictionary[i].type === 'append' ||
      selectedDictionary[i].type === 'audience'
    ) {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      obj.cellRendererFramework =
        selectedDictionary[i].type === 'audience' ? audienceCell : taggedCell;
      obj.cellRendererParams.textKey = i;
      obj.cellRendererParams.keys = selectedDictionary[i].keys;
    }
    if (selectedDictionary[i].type === 'currency') {
      delete obj.cellRenderer;
      obj.cellRendererFramework = currency;
    }
    obj.headerName = selectedDictionary[i].title;
    obj.keyOrder = selectedDictionary[i].keyOrder;
    selectedDictionary[i].pinned && (obj.pinned = selectedDictionary[i].pinned);
    // selectedDictionary[i].pinned && (delete obj.cellRendererFramework);
    if (selectedDictionary[i].toolTipText) {
      obj.headerComponentParams.toolTipText = selectedDictionary[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    if (selectedDictionary[i].showOnUi) {
      _columnDefs.push(obj);
    }
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}

export const categoryLeftTable = (
  vueRef,
  isCreateAdGroup = false,
  campaignType
) => {
  const categoryTable = [
    {
      // currDefinition
      name: 'category',
      type: 'STRING',
      uiField: {
        uiLabel: 'Category',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'category',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader:
          campaignType === 'sp'
            ? () => addAllCategory(vueRef)
            : () => addAll(vueRef),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all categories on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this category',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent:
            campaignType === 'sp'
              ? (row) => addEntityCategory(row, vueRef)
              : (row) => addEntity(vueRef, row),
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
  if (campaignType !== 'sp') {
    categoryTable.push({
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    });
  }
  return categoryTable;
};

export function productActionState(context) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              rowHeight: 68,
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'search'
                  },
                  showLeftCustomBid: true,
                  leftCustomBidValues
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'target',
                      inputPlaceHolder: 'Enter ASINs separated by new line',
                      buttonLabel: 'Add ASINs',
                      inputInfo: 'You may add upto 1000 ASINs',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN: ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              new_bid: customBid,
                              suggested_bid: '-',
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  //   vueRef.rightTableRows = vueRef.rightTableRows.map(item => {
                  //     if (item.competitor_asin) {
                  //       item.entity_ui_label = `ASIN: ${item.competitor_asin}`;
                  //     }
                  //   });
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (!item?.new_bid && item?.suggested_bid) {
                      item.new_bid = cloneDeep(item.suggested_bid);
                    }
                    if (item.competitor_asin) {
                      item.entity_ui_label = `ASIN : ${item.competitor_asin}`;
                    }
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableDate(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              defaultNewBidValue: 1,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets'),
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export function categoriesActionState(context, campaignType = 'sp') {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Categories to this list',
              selections: '',
              noRowsLeftTable: 'No Categories sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Categories are not allowed'
            },
            left: {
              actionAndGetterForEachTab: true,
              hidePagination: true,
              altStore: {
                Suggested: 'campaignCreation/getCategories',
                Search: 'campaignCreation/getAllCategories'
              },
              tabs: {
                Suggested: {
                  leftCustomBidValues:
                    campaignType === 'sp'
                      ? [
                          {
                            title: 'Custom Bid',
                            value: 'custom_bid',
                            input: 'currency'
                          }
                        ]
                      : leftCustomBidValues,
                  showLeftCustomBid: true,
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: categoryLeftTable(vueRef, false, campaignType),
                  search: {
                    enableSearch: false,
                    searchKey: 'keyword'
                  },
                  showFilterCheckBox: false
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                },
                Search: {
                  hasDefaultRowExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  expandColumnWidth: 140,
                  hasExpand: true,
                  expandLevels: categoryHierarchyExpandLevels,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  columnDefs: categoryColumnDefWithoutAutoConfigure(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'name'
                  },
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: {
                  Suggested: 'campaignCreation/fetchCategories',
                  Search: 'campaignCreation/fetchAllCategoriesLevel'
                },
                allSkus: getSkusFromAllStepsData(context),
                campaignType: campaignType,
                updateRows: (vueRef) => {
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (item?.category) {
                      item.entity_ui_label = `CATEGORY : ${item.category}`;
                    }
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableDate(context),
                allCatergoriesLevelCube: allCatergoriesLevelCube,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: categoryLeftTable(vueRef)
              },
              headerText: 'Add Categories'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              defaultNewBidValue: 1,
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets'),

      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export function createAdGroupAddCategoriesActionState(
  context,
  campaignType = 'sp'
) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Categories to this list',
              selections: '',
              noRowsLeftTable: 'No Categories sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Categories are not allowed'
            },
            left: {
              actionAndGetterForEachTab: true,
              hidePagination: true,
              altStore: {
                Suggested: 'campaignCreation/getCategories',
                Search: 'campaignCreation/getAllCategories'
              },
              tabs: {
                Suggested: {
                  leftCustomBidValues:
                    campaignType === 'sp'
                      ? [
                          {
                            title: 'Custom Bid',
                            value: 'custom_bid',
                            input: 'currency'
                          }
                        ]
                      : leftCustomBidValues,
                  showLeftCustomBid: true,
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: categoryLeftTable(vueRef, true, campaignType),
                  search: {
                    enableSearch: false,
                    searchKey: 'keyword'
                  },
                  showFilterCheckBox: false
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                },
                Search: {
                  hasDefaultRowExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  expandColumnWidth: 140,
                  hasExpand: true,
                  expandLevels: categoryHierarchyExpandLevels,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  columnDefs: categoryColumnDefWithoutAutoConfigure(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'name'
                  },
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                action: {
                  Suggested: 'campaignCreation/fetchCategories',
                  Search: 'campaignCreation/fetchAllCategoriesLevel'
                },
                type: 'table',
                campaignType: campaignType,
                allSkus: getSkusFromAllStepsData(context),
                updateRows: (vueRef) => {
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (item?.category) {
                      item.entity_ui_label = `CATEGORY : ${item.category}`;
                    }
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                allCatergoriesLevelCube: allCatergoriesLevelCube,
                useExistingWhereClause: true,
                APIConfig: getTableDataForCreateAdGroup(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: categoryLeftTable(vueRef, true, campaignType)
              },
              headerText: 'Add Categories'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              defaultNewBidValue: 1,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets'),
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export function createAdGroupAddProductActionState(context) {
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.product?.['targeting'];
  const leftRecommendationsDropdownValues = recommendedConfigArray.map(
    (item) => ({
      title: item?.recommendationName,
      value: item?.recommendationOrder
    })
  );
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              rowHeight: 68,
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  showLeftCustomBid: true,
                  leftCustomBidValues,
                  search: {
                    enableSearch: true,
                    searchKey: 'competitor_asin'
                  },
                  showFilterCheckBox: false,
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                  leftRecommendationsDropdownValues:
                    leftRecommendationsDropdownValues,
                  showLeftRecommendationsDropdown:
                    leftRecommendationsDropdownValues.length > 0
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'target',
                      inputPlaceHolder: 'Enter ASINs separated by new line',
                      buttonLabel: 'Add ASINs',
                      inputInfo: 'You may add upto 1000 ASINs',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN : ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              suggested_bid: '-',
                              new_bid: customBid,
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  //   vueRef.rightTableRows = vueRef.rightTableRows.map(item => {
                  //     if (item.competitor_asin) {
                  //       item.entity_ui_label = `ASIN: ${item.competitor_asin}`;
                  //     }
                  //   });
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (item.competitor_asin) {
                      item.entity_ui_label = `ASIN : ${item.competitor_asin}`;
                    }
                    item.new_bid = item.suggested_bid;
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableDataForCreateAdGroup(
                  context,
                  'targeting',
                  vueRef.selectedLeftCustomRecommendation
                ),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              defaultNewBidValue: 1,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      },
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets')
    }
  };
}

export function createOwnSBCampaignAddProductActionState(context) {
  const getAPIConfigForSBOwnCreateCampaign = (
    context,
    type = 'targeting',
    selectedRecommendation = null
  ) => {
    let apiConfig = {};
    // let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
    // suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
    const allStepsData = fetchSavedState(
      context,
      'campaignCreation/getAllStepsData'
    );
    // const suggestedSkus = cloneDeep(
    //   allStepsData?.suggestedCampaigns?.[suggestedCampaignIndex]?.creativeSkus
    // );
    const suggestedSkus = getSkusFromAllStepsData(context)
      ?.replace(/["']/g, '')
      ?.split(',');
    let recommendedConfig = {};
    const recommendedConfigArray =
      allStepsData?.goalConfig?.customMetadata?.product?.[type];
    if (recommendedConfigArray.length > 0) {
      if (!selectedRecommendation) {
        recommendedConfig = recommendedConfigArray[0];
      } else {
        recommendedConfig = recommendedConfigArray.filter(
          (item) => item.recommendationOrder === selectedRecommendation.value
        )[0];
      }
      apiConfig = cloneDeep(recommendedConfig?.request);
      apiConfig.asins = [];
      suggestedSkus.forEach((item) => {
        apiConfig.asins.push(item);
      });
    }
    return apiConfig;
  };
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.product?.['targeting'];
  const leftRecommendationsDropdownValues = recommendedConfigArray.map(
    (item) => ({
      title: item?.recommendationName,
      value: item?.recommendationOrder
    })
  );
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              hidePagination: true,
              altStore: 'campaignCreation/getProducts',
              rowHeight: 68,
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'competitor_asin'
                  },
                  showFilterCheckBox: false,
                  leftCustomBidValues,
                  showLeftCustomBid: true,
                  leftRecommendationsDropdownValues:
                    leftRecommendationsDropdownValues,
                  showLeftRecommendationsDropdown:
                    leftRecommendationsDropdownValues.length > 0
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'target',
                      inputPlaceHolder: 'Enter ASINs separated by new line',
                      buttonLabel: 'Add ASINs',
                      inputInfo: 'You may add upto 1000 ASINs',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN: ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              suggested_bid: '-',
                              new_bid: customBid,
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'campaignCreation/fetchTableDataRecommendationApi',
                updateRows: (vueRef) => {
                  //   vueRef.rightTableRows = vueRef.rightTableRows.map(item => {
                  //     if (item.competitor_asin) {
                  //       item.entity_ui_label = `ASIN: ${item.competitor_asin}`;
                  //     }
                  //   });
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (item.competitor_asin) {
                      item.entity_ui_label = `ASIN : ${item.competitor_asin}`;
                    }
                    item.new_bid = item.suggested_bid;
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction:
                  'campaignCreation/fetchTableDataRecommendationApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getAPIConfigForSBOwnCreateCampaign(
                  context,
                  'targeting',
                  vueRef.selectedLeftCustomRecommendation
                ),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets'),
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export function productActionStateForSB(context) {
  const getAPIConfigForSB = (context) => {
    const apiConfig = {
      campaignType: 'sb',
      resource: 'asins',
      maxResults: 10,
      asins: []
    };
    const skuValues = getSkusFromAllStepsData(context);
    apiConfig.asins = skuValues?.replace(/["']/g, '')?.split(',');
    return apiConfig;
  };
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              altStore: 'campaignCreation/getProducts',
              rowHeight: 68,
              hidePagination: true,
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'competitor_asin'
                  },
                  showFilterCheckBox: false,
                  leftCustomBidValues,
                  showLeftCustomBid: true

                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'target',
                      inputPlaceHolder: 'Enter ASINs separated by new line',
                      buttonLabel: 'Add ASINs',
                      inputInfo: 'You may add upto 1000 ASINs',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN: ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              new_bid: customBid,
                              suggested_bid: '-',
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'campaignCreation/fetchTableDataRecommendationApi',
                updateRows: (vueRef) => {
                  //   vueRef.rightTableRows = vueRef.rightTableRows.map(item => {
                  //     if (item.competitor_asin) {
                  //       item.entity_ui_label = `ASIN: ${item.competitor_asin}`;
                  //     }
                  //   });
                  vueRef.leftTableData.rows.forEach((item) => {
                    if (item.competitor_asin) {
                      item.entity_ui_label = `ASIN : ${item.competitor_asin}`;
                    }
                    item.new_bid = item.suggested_bid;
                  });
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getAPIConfigForSB(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'targets'
                )
              },
              defaultNewBidValue: 1,
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionaryRightCreateAdGroup(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context, 'targets'),
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('open.open', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export const addNegativeTargetsActionState = (context) => {
  return {
    component: 'customActionPanel', // usage in multiple places
    ui_component: 'customActionPanel', // usage in multiple places
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add negative targets to this list',
              selections: '',
              noRowsLeftTable: 'No negative targets sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: 'campaign_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  columnDefs:
                    dictionary.add_negative_targets_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  search: {
                    enableSearch: false
                  },
                  showFilterCheckBox: false
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Negative Phrase', 'Negative Exact']
                  // }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'negative target',
                      buttonLabel: 'Add negative target',
                      isTarget: true // allow to add targets and categories
                    },
                    events: {}
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Targeting Expression']
                  }
                }
              },
              defaultTab: 'Enter List',
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                // action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.negativeTargets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.negativeTargets;
                  }
                },
                localFilters: [
                  {
                    dimensionName: 'campaign_id',
                    dimensionValue: context.entityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName:
                    'ams_cm_negativekeyword_recommendations_inefficient_searchterms',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: ['recommended_keyword', 'acos14d'],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'acos14d',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_negative_targets_sp_and_sb.left.SUGGESTED(
                    vueRef
                  ),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keyword === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Negative Targets'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_negative_targets_sp_and_sb.createAdgroup.right(
                    vueRef
                  )
                )
              },
              headerText: () =>
                `Added Negative Targets (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].negativeTargets =
          rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      }
    }
  };
};
