<template>
  <div
    class="banner data-lag-banner u-font-size-6 u-spacing-pv-s u-spacing-ph-l u-display-flex u-flex-align-items-center"
  >
    <rb-icon
      class="rb-icon--medium u-color-orange-base u-spacing-mr-s"
      :icon="iconName"
    />
    <span>
      {{ title }}
      <a
        v-if="externalLinkProps.enabled"
        class="u-color-blue-base"
        target="_blank"
        :href="externalLinkProps.link"
      >
        {{ externalLinkProps.externalLinkLabel }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DataLagBanner',
  props: {
    title: {
      default: '',
      type: String
    },
    iconName: {
      default: 'warning',
      type: String
    },
    externalLinkProps: {
      type: Object,
      default() {
        return {
          enabled: false,
          externalLinkLabel: 'Know more',
          link: ''
        };
      }
    }
  }
};
</script>

<style lang="css" scoped>
.data-lag-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
</style>
