<template>
  <div>
    <dashboard-inflator
      class="pricing_and_promotions"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      :enable-date="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @filtersUpdated="filtersUpdated"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import pricingSkuBreakdown from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import priceWar from './price_war';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import localDateFilter from '@/components/basic/localDateFilter';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';
import skuMultipleRetailers from '@//components/basic/skuMultipleRetailers.vue';
import DashboardPivotedDataService from '@/utils/common/dashboardPivotedTableDataService.js';
import utils from '@/utils/helpers/';
import pricingCell from '@/pages/digital_shelf/pricing_and_promotions/pricingCell.vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  mixins: [dashboardMixin],
  data() {
    // powering the default zipcode from canvas config
    const today = moment().format('YYYY-MM-DD');
    const pageInfo = {
      id: 11,
      name: 'pricing_dashboard',
      title: 'Pricing'
    };
    const context = this;
    return {
      context,
      isLoaded: false,
      skuList: [],
      options: [
        {
          label: 'North',
          title: 'north'
        },
        {
          label: 'south',
          title: 'south'
        }
      ],
      priceWarMinMaxDate: {},
      priceWarKey: 0,
      pageInfo,
      filterKey: 0,
      filterEventAndLocalStorageKey: 'pricing-promotions-filters',
      filters: {
        emit: 'pricing-promotions-filter-apply'
      },
      selectedSKU: {},
      headerOptions: () => {
        return {
          price_war: {
            props: {
              headerContainerGridStyles: {
                'grid-template-columns': '4fr auto 30px',
                'grid-row-gap': '20px',
                'grid-column-gap': '1%'
              },
              headerOptions: [
                {
                  ui_component: 'selectWrapper',
                  props: {
                    dropdownOptions: this.skuList,
                    selectedOption: this.selectedSKU,
                    typeOfDropdown: 'asin',
                    skuTooltip: 'View Walmart detail page',
                    width: '400px'
                  },
                  grid_row: '2',
                  events: {
                    onClick: this.skuSelected
                  }
                },
                {
                  ui_component: 'dateRange',
                  props: {
                    key: this.priceWarKey,
                    newDate: true,
                    minDate: this.priceWarMinMaxDate.from,
                    maxDate: this.priceWarMinMaxDate.to,
                    allowCompare: false,
                    defaultDatePostRange: 'last7Days',
                    localStorageKey: 'price_war_date'
                  },
                  events: {
                    create: this.changePriceWarDate,
                    submit: this.changePriceWarDate
                  }
                }
              ],
              footerOptions: [
                {
                  ui_component: 'iconText',
                  props: {
                    text: 'Why is there no Pricing Data for a Retailer?',
                    icon: 'help-fill',
                    tooltipTitle:
                      'Why is there no Pricing Data for a Retailer?',
                    tooltipText:
                      '<span>In case the price for a retailer which has been opted by you is not present for a particular day, it could be because of an issue in scrapping the product detail page on that day.You can opt to add more retailers for price matching by writing to us at <a href="mailto:support@commerceiq.ai" class=\'u-color-blue-base\'>support@commerceiq.ai</a>.</span>',
                    iconClasses: 'u-color-blue-base rb-icon--medium',
                    containerClass:
                      'u-cursor-pointer pricewar-help u-border-left u-border-width-s u-border-color-grey-xxx-light u-spacing-pl-s',
                    textClasses:
                      'text-container u-position-absolute u-color-grey-lighter u-text-decoration-underline u-spacing-mt-xxs'
                  }
                }
              ],
              enableDownload: true
            }
          },
          pricing_sku_breakdown: {
            props: {
              headerOptions: [
                {
                  ui_component: 'localDateFilter',
                  props: {
                    mode: 'Single',
                    customDate: this.skuBreakdownCustomDate
                  },
                  events: {
                    'selected-value': this.changeSkuBreakdownDate
                  },
                  order: 201
                }
              ],
              enableDownload: true,
              customDownloadEnable: true,
              customDownloadFunction: this.pricingDownloadBreakdown,
              searchParams: {
                show: true
              },
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto 24px',
                'grid-column-gap': '12px'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        pricing_sku_breakdown: {
          ':widget': 'pricing_sku_breakdown',
          ':tableFromDate': today,
          ':tableToDate': today,
          // adding this to individual widgets instead of global since the filterInit handler is not handling existing filters gracefully.
          // pending action item
          ':dimensionNameValueList': []
        },
        price_war: {
          ':widget': 'price_war',
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          // adding this to individual widgets instead of global since the filterInit handler is not handling existing filters gracefully.
          // pending action item
          ':dimensionNameValueList': []
        }
      },
      widgetMetadata: [],
      skuBreakdownCustomDate: {},
      filterSettings: {
        emitEvent: 'pricingFilterApply',
        uiKey: 0,
        show: true,
        defaultDatePostRange: 'last30Days',
        enableSave: false,
        listenerEvent: 'pricing-dashboard-filter',
        allowCompare: false,
        filterBarLSKey: 'pricingFilterBarLSKey',
        showFilterBar: true,
        filterBar: [
          {
            title: 'Region',
            key: 'region',
            addInFilter: false,
            selectType: 'dropdown',
            originalSelections: []
          }
        ]
      }
    };
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('pricingCell', pricingCell);
    Vue.component('pricing_sku_breakdown', pricingSkuBreakdown);
    Vue.component('price_war', priceWar);
    Vue.component('localDateFilter', localDateFilter);
    Vue.component('dateRange', dateRange);
    Vue.component('skuMultipleRetailers', skuMultipleRetailers);
  },
  methods: {
    changeSkuBreakdownDate(data) {
      const pricingTable = this.getWidgetRequestParams('pricing_sku_breakdown');
      pricingTable[':tableFromDate'] = moment(data.to).format('YYYY-MM-DD');
      pricingTable[':tableToDate'] = moment(data.to).format('YYYY-MM-DD');
      this.updateWidgetRequestParams(pricingTable, 'pricing_sku_breakdown');
      Vue.set(
        this.skuBreakdownCustomDate,
        'defaultDate',
        new Date(pricingTable[':tableFromDate'])
      );
    },
    changePriceWarDate(date) {
      const priceWarWidget = this.getWidgetRequestParams('price_war');

      priceWarWidget[':from'] = moment(date.from).format('YYYY-MM-DD');
      priceWarWidget[':to'] = moment(date.to).format('YYYY-MM-DD');
      this.updateWidgetRequestParams(priceWarWidget, 'price_war');

      localStorage.setItem(
        'price_war_date',
        JSON.stringify({ date_range: date })
      );

      this.priceWarKey = this.priceWarKey + 1;
    },
    async metadataUpdated(data) {
      this.widgetMetadata = data;
      const pricingTableData = data.find(
        (e) => e.name === 'pricing_sku_breakdown'
      );
      const pricingTableReqParams = this.getWidgetRequestParams(
        'pricing_sku_breakdown'
      );
      if (pricingTableData) {
        this.skuBreakdownCustomDate = {
          defaultDate: new Date(pricingTableData?.calendar?.max_date),
          toDate: new Date(pricingTableData?.calendar?.max_date),
          fromDate: new Date(pricingTableData?.calendar?.min_date)
        };
      }
      if (pricingTableReqParams && pricingTableData) {
        pricingTableReqParams[':tableFromDate'] = moment(
          pricingTableData?.calendar?.min_date
        ).format('YYYY-MM-DD');
        pricingTableReqParams[':tableToDate'] = moment(
          pricingTableData?.calendar?.max_date
        ).format('YYYY-MM-DD');
        this.updateWidgetRequestParams(
          pricingTableReqParams,
          'pricing_sku_breakdown'
        );
        Vue.set(
          this.skuBreakdownCustomDate,
          'defaultDate',
          new Date(pricingTableReqParams[':tableToDate'])
        );
      }
      const priceWar = data.find((e) => e.name === 'price_war');
      if (priceWar) {
        const priceWarReqParams = this.getWidgetRequestParams('price_war');
        const fromDate = moment(priceWar?.calendar?.min_date).format(
          'YYYY-MM-DD'
        );
        const toDate = moment(priceWar?.calendar?.max_date).format(
          'YYYY-MM-DD'
        );

        const minMaxObj = {
          from: fromDate,
          to: toDate
        };

        this.updateWidgetRequestParams(priceWarReqParams, 'price_war');
        Vue.set(this, 'priceWarMinMaxDate', minMaxObj);

        await this.fetchSKUList(priceWar?.metadata);
        this.skuSelected(this.skuList[0]);
      }
    },
    async filtersUpdated() {
      const pricingChart = this.widgetMetadata.find(
        (e) => e.name === 'price_war'
      );
      if (pricingChart) {
        await this.fetchSKUList(pricingChart.metadata);
        this.skuSelected(this.skuList[0]);
      }
    },
    async fetchSKUList(metadata) {
      const api = metadata.headers[0].api;
      api.request.where.dimensionNameValueList = ':dimensionNameValueList';
      const apiConfig = dashUtils.replacePlaceHolderWithData(
        api.request,
        this.getGlobalRequestParams()
      );
      return HttpService.post(api.service, apiConfig, { append: api.endPoint })
        .then((response) => {
          const data = response?.data?.response?.data;
          this.skuList = data.map((e) => {
            return {
              title: e.DIMENSION?.title,
              sku: e.DIMENSION.sku,
              image_url: e?.DIMENSION?.image_url
            };
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.open({
            message: 'Something Went Wrong',
            duration: 6000,
            actionText: ''
          });
        });
    },
    chartingWbDownloadTriggered() {
      try {
        this.dataService.downloadChartingData(
          this.requestParams.chartingWb,
          this.requestParams
        );
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    async pricingDownloadBreakdown(widgetMeta, requestParams) {
      try {
        const operationsObj = {
          limit: 999999,
          page: 1
        };

        const dataService = new DashboardPivotedDataService(
          widgetMeta,
          widgetMeta.name,
          requestParams.name,
          requestParams.id,
          widgetMeta.metadata.pivot
        );

        // Get table rows and columns from list API
        const rows = await dataService.getTableRows(
          operationsObj,
          requestParams
        );
        const columns = await dataService.getTableColumns(
          operationsObj,
          requestParams
        );

        // Construct columns in the desired format
        const columnsArr = [];
        columns.forEach((col) => {
          if (col.field === 'sku') {
            // Add SKU id and SKU title as first 2 columns
            columnsArr.push(
              {
                priority: col.keyOrder,
                field: col.field,
                title: 'SKU ID'
              },
              {
                priority: col.keyOrder + 1,
                field: 'title',
                title: 'SKU Title'
              }
            );
          } else {
            // Add all retailer prices as columns
            columnsArr.push({
              priority: col.keyOrder,
              field: col.field + ' price',
              title: col.field + ' price'
            });
          }
        });

        // Add all retailer URLs as LAST columns
        columns.forEach((col) => {
          if (col.field !== 'sku') {
            columnsArr.push({
              priority: col.keyOrder,
              field: col.field + ' URL',
              title: col.field + ' URL'
            });
          }
        });

        // Construct dataArray for download
        const rowsArr = rows.rows.map((row) => {
          const obj = {};
          obj.sku = row.sku;
          obj.title = row.title;

          // Add each retailer to the row object
          columnsArr.forEach((item, index) => {
            // Skip SKU ID and SKU title index
            if (index > 1) {
              const retailer = item.field.split(' ')[0];
              if (row.pivotedData[retailer]) {
                obj[retailer + ' price'] = row.pivotedData[retailer][0]
                  ? row.pivotedData[retailer][0]?.agg_comp_offer_price
                  : '-';
                obj[retailer + ' URL'] = row.pivotedData[retailer][0]
                  ? row.pivotedData[retailer][0]?.agg_comp_url
                  : 'NA';
              } else {
                obj[retailer + ' price'] = '-';
                obj[retailer + ' URL'] = 'NA';
              }
            }
          });

          return obj;
        });

        utils.performDownload(rowsArr, columnsArr, widgetMeta.label);
      } catch (error) {
        throw Error(error);
      }
    },
    rollUpChanged(data) {
      const chartingReqParam = cloneDeep(this.requestParams?.chartingWb);
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      this.updateWidgetRequestParams(chartingReqParam, 'chartingWb');
    },
    onSearchSubmit(data) {
      const dimensionNameValueList = this.requestParams.pricing_sku_reakdown[
        ':dimensionNameValueList'
      ].map((e) => {
        if (e.dimensionName === 'search') {
          e.dimensionValue = data;
        }
        return e;
      });
      Vue.set(
        this.requestParams.pricing_sku_breakdown,
        ':dimensionNameValueList',
        dimensionNameValueList
      );
    },
    skuSelected(skuSelected) {
      const priceWarReqParam = cloneDeep(this.requestParams.price_war);
      this.selectedSKU = skuSelected;
      priceWarReqParam[':dimensionNameValueList'] =
        priceWarReqParam[':dimensionNameValueList']?.filter(
          (e) => e.dimensionName !== 'sku'
        ) ?? [];
      priceWarReqParam[':dimensionNameValueList'].push({
        dimensionName: 'sku',
        dimensionValue: this.selectedSKU.sku
      });
      this.updateWidgetRequestParams(priceWarReqParam, 'price_war');
    }
  }
};
</script>

<style lang="css">
.pricing_and_promotions .dashboard-service-table .status-text-with-icon,
.pricing_and_promotions .dashboard-service-table .iconText {
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.pricing_and_promotions .dashboard-service-table div[col-id='fulfillment'],
.pricing_and_promotions .dashboard-service-table div[col-id='availability'] {
  padding: 0;
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.pricing_and_promotions
  .dashboard-service-table
  div[col-id='fulfillment']
  .header-text,
.pricing_and_promotions
  .dashboard-service-table
  div[col-id='availability']
  .header-text {
  padding-left: 1.6rem;
}
.pricing_and_promotions .dashboard-service-table .iconText {
  padding-top: 2.4rem;
  padding-left: 2.4rem;
}
.pricing_and_promotions .inputClassForEntityTaggingSearch {
  width: 240px;
  margin-right: 0.8rem;
  padding: 0;
}
.pricing_and_promotions .pricewar-help:hover .text-container {
  color: #2b333b;
  text-decoration-color: #2b333b;
}
.pricing_and_promotions .search__input {
  box-shadow: none;
}
</style>
