<template>
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <!-- Loader -->
    <div
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        Edit Optimization Strategy ({{ selections.length }} line item{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </div>

      <!-- Main Section where all radio buttons are shown -->
      <div>
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-m">
          Adjust strategy type:
        </div>
        <div class="u-line-height-1 u-display-flex">
          <div
            v-for="item in config.optimizationStrategySelections"
            :key="item.value"
          >
            <rb-radio
              v-model="optimizationStrategySelection"
              :native-value="item.value"
              class="u-font-size-4 u-width-100 u-spacing-mr-m"
            >
              {{ item.title }}
            </rb-radio>
          </div>
        </div>
      </div>

      <div
        v-if="optimizationStrategySelection === 'clicks'"
        class="u-font-size-5 u-color-grey-lighter u-display-flex u-flex-align-items-center u-spacing-mt-l"
      >
        <rb-icon
          class="rb-icon--medium u-spacing-mr-xs"
          :icon="'info-circle-fill'"
        />
        <div class="u-spacing-mt-xxs">
          Updates line item max bid to no max bid.
        </div>
      </div>

      <!-- Adjust max bid radioWithInput -->
      <div
        v-if="
          optimizationStrategySelection === 'conversion' ||
          optimizationStrategySelection === 'revenue'
        "
        class="u-display-flex u-spacing-mt-s"
      >
        <RadioSelectionWithInput
          title="Adjust max bid:"
          :radio-config="config.conversionsAndRevenueStrategyOptions"
          :default-radio-selected="conversionsAndRevenueOptionsRadioDefault"
          @onUpdate="updateMaxBid"
        />
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-pv-m u-spacing-ph-l"
    >
      <rb-button
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="applyButtonDisabled || !selections.length"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import config from '@/pages/campaign-management/retailers/criteo/line_items/EditOptimizationStrategy/editOptimizationStrategyConfig.js';
import RadioSelectionWithInput from '@/pages/campaign-management/retailers/criteo/line_items/RadioSelectionWithInput/index.vue';
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
export default {
  components: {
    RadioSelectionWithInput,
    loader
  },
  props: {
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      loader: false,
      config: null,
      conversionsAndRevenueOptionsRadioDefault: 'set_new_mb',
      optimizationStrategySelection: 'conversion',
      updatedMaxBidValue: {}
    };
  },
  computed: {
    applyButtonDisabled() {
      if (this.optimizationStrategySelection === 'clicks') {
        return false;
      }
      if (
        this.optimizationStrategySelection === 'conversion' ||
        this.optimizationStrategySelection === 'revenue'
      ) {
        if (
          this.updatedMaxBidValue?.radioValue === 'no_max_bid' ||
          this.updatedMaxBidValue?.inputValue
        ) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    selections(newVal) {
      if (!newVal?.length) {
        this.$emit('closePanelMethod');
      }
    }
  },
  created() {
    this.config = config[this.retailer];
  },
  methods: {
    updateMaxBid(data) {
      this.updatedMaxBidValue = data;
    },
    calculateInputValue(data, budget) {
      let val = null;
      if (data?.inputType === 'dropdown') {
        if (budget === null && !data?.radioValue?.includes('set_new')) {
          return null;
        }
        const delta = budget * (data?.inputValue / 100);
        if (data?.radioValue?.includes('increase_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget + delta).toFixed(2))
              : parseFloat(data?.inputValue) + budget;
        } else if (data?.radioValue?.includes('decrease_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget - delta).toFixed(2))
              : budget - parseFloat(data?.inputValue);
        }
      } else if (data?.inputType === 'input') {
        val = data?.inputValue;
      }
      if (val < 0) {
        val = 0;
      }
      return val;
    },
    onClose() {
      this.$emit('closePanelMethod');
    },
    onApply() {
      let payload = [];
      payload = [...this.createPayload()];
      this.callAPI(payload);
    },
    callAPI(payload) {
      const name = 'line items(s)';
      const that = this;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted for the selected ' + name,
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.apiVersion
      );
    },
    createPayload() {
      const payload = [];
      this.selections.forEach((item) => {
        const prevMaxBidValue = item?.data?.max_bid;
        let newMaxBid = null;
        if (
          !this.optimizationStrategySelection === 'clicks' ||
          this.updatedMaxBidValue?.value !== 'no_max_bid'
        ) {
          newMaxBid = this.calculateInputValue(
            this.updatedMaxBidValue,
            prevMaxBidValue
          );
        }
        const payloadObject = {
          widget: this.widget,
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: item?.data?.[this.primaryKey],
          actionPayload: {
            line_item_id: item?.data?.line_item_id,
            actionType: this.actionType,
            bid_strategy: this.optimizationStrategySelection,
            max_bid: newMaxBid
          },
          viewPayload: {
            line_item_id: item?.data?.line_item_id,
            line_item_name: item?.data?.line_item_name,
            prev_bid_strategy: item?.data?.bid_strategy,
            new_bid_strategy: this.optimizationStrategySelection,
            prev_max_bid: prevMaxBidValue
              ? Vue.options.filters.num_format(
                  this.getDisplayValue(prevMaxBidValue),
                  'currency'
                )
              : 'No Max Bid',
            new_max_bid:
              newMaxBid || newMaxBid === 0
                ? Vue.options.filters.num_format(
                    this.getDisplayValue(newMaxBid),
                    'currency'
                  )
                : 'No Max Bid'
          },
          previousEntityValue:
            String(item?.data?.bid_strategy) +
            ',' +
            String(item?.data?.max_bid),
          newEntityValue:
            String(this.optimizationStrategySelection) + ',' + String(newMaxBid)
        };
        payload.push(payloadObject);
      });
      return payload;
    }
  }
};
</script>
