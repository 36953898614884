<template>
  <form
    class="find-entity"
    @submit.prevent="onSearchInput"
  >
    <div class="u-position-relative u-spacing-mv-s">
      <rb-input
        v-model="searchText"
        :placeholder="bulkSearchPopupProps.findEntityInputPlaceholder"
        class="find-entity-input u-width-100 u-border-left-none"
        :rounded="false"
        @focus="exitEditMode"
        @input="onSearchInput"
      />
      <span class="visibility-icon u-position-absolute u-display-flex">
        <rb-icon
          icon="visibility-on"
          class="search__input-icon rb-icon--medium"
        />
      </span>
    </div>
  </form>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    bulkSearchPopupProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchText: ''
    };
  },
  methods: {
    onSearchInput: debounce(function () {
      this.$emit('highlightEntitiesOnSearch', this.searchText);
    }, 500),
    exitEditMode() {
      this.$emit('exitEditMode');
      if (this.searchText)
        this.$emit('highlightEntitiesOnSearch', this.searchText);
    },
    handleClearBulkSeachEntitySearchedTerm() {
      this.searchText = '';
    }
  }
};
</script>

<style lang="css">
.find-entity .find-entity-input {
  display: flex;
  align-items: center;
}
.find-entity .find-entity-input .rb-input {
  font-size: 12px;
  height: 24px;
  background-color: #f5f7fa;
  border: none;
  box-shadow: none;
  border-radius: 20px;
  padding: 6px 8px 6px 28px;
  width: 200px;
}
.find-entity .find-entity-input .rb-input:focus {
  outline: none;
  box-shadow: none;
}
.find-entity .find-entity-input .rb-input::placeholder {
  color: #aaadb1;
  font-size: 13px;
}
.find-entity .visibility-icon {
  top: 4px;
  left: 8px;
}
</style>
