var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-mr-m"},[_c('span',{staticClass:"u-font-size-4 u-font-weight-600 u-color-grey-x-light"},[_vm._v(_vm._s(_vm.groupLabel))]),_vm._l((_vm.radioOptions),function(ref,index){
var type = ref.type;
var label = ref.label;
var disabled = ref.disabled; if ( disabled === void 0 ) disabled = false;
return _c('div',{key:index,staticClass:"global-radio-wrapper"},[_c('div',{staticClass:"u-spacing-ml-s"},[_c('rb-radio',{attrs:{"disabled":disabled,"native-value":type},model:{value:(_vm.currentRadio),callback:function ($$v) {_vm.currentRadio=$$v},expression:"currentRadio"}},[_c('div',{class:{
            'u-font-weight-600': _vm.customLabel && type === _vm.selectedRadio,
            'u-font-weight-600 u-color-grey-mid-light':
              _vm.customLabel && type !== _vm.selectedRadio
          }},[_vm._v(" "+_vm._s(label)+" ")])])],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }