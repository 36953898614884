<template>
  <div
    v-if="panelState === 'open'"
    class="rightPanelDownload u-display-flex u-flex-direction-column"
    :class="panelState"
    data-cy="ManageDownloads"
    :style="{
      '--upperHeight': upperHeight
    }"
  >
    <div
      class="widget__header u-flex-none u-border-bottom u-border-color-grey-xxx-light u-border-width-s"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-ph-m u-spacing-pv-l"
      >
        <div class="u-flex-none">
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
            :icon="'archive'"
          />
        </div>
        <div class="widget__header--title u-flex-1">
          <div class="u-font-size-5 u-color-grey-base u-font-weight-600">
            Manage Downloads
          </div>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-flex-end u-cursor-pointer"
          @click="closeModal"
        >
          <rb-icon
            class="u-color-grey-mid-light rb-icon--small"
            :icon="'cross'"
          />
        </div>
      </div>
    </div>
    <errorBlob
      v-if="showErrorBlob"
      primary-header-text="Download process may not work seamlessly on Microsoft Edge."
      dropdown-open-header-message="Hide steps to fix issue"
      dropdown-closed-header-message="View steps to fix issue"
      local-storage-key="show-ms-edge-download-center-msg"
    >
      <div slot="dropdownContent">
        <p
          class="u-font-size-7 u-font-weight-400 u-color-grey-light u-line-height-1-3"
        >
          Open
          <span class="u-font-weight-600"
            >Settings >> Downloads >> Disable the following option</span
          >
          “Open office files in the browser”
        </p>
        <p
          class="u-spacing-pt-xs u-font-size-7 u-font-weight-400 u-color-grey-light u-line-height-1-3"
        >
          or
          <span class="u-font-weight-600">use Google Chrome</span>
          as alternative browser
        </p>
      </div>
    </errorBlob>
    <div
      v-if="showDownloadTimeBanner"
      class="u-display-flex u-flex-align-items-flex-start u-spacing-pt-s u-spacing-ph-m"
    >
      <rb-icon
        class="rb-icon--small u-color-grey-lighter u-spacing-mr-xs u-flex-align-self-flex-start"
        icon="info-circle-fill"
      />
      <span
        class="u-font-size-7 u-font-weight-400 u-color-grey-light u-line-height-1-3"
        >Please wait while we prepare your report. Downloads may take 5-10
        minutes depending on the file size.</span
      >
    </div>
    <div v-if="!isLoading && downloads.length">
      <div
        class="widget__body u-position-relative u-border-top u-border-width-s u-border-color-grey-xx-light u-spacing-mr-m"
        :class="showDownloadTimeBanner ? 'u-spacing-mt-s' : 'u-spacing-mt-m'"
      >
        <div
          class="download-year u-color-grey-x-light u-font-size-7 u-spacing-pl-s u-spacing-pv-xs"
        >
          <span>{{ displayYear }}</span>
        </div>
        <div
          class="hide-left u-border-right u-border-width-s u-border-color-grey-xx-light"
        ></div>
      </div>
    </div>
    <div
      v-if="isLoading || recentDownloads.data.length"
      class="widget__body u-display-flex u-flex-1 u-overflow-x-hidden"
      :class="[
        downloads.length < 5 ? 'u-overflow-y-hidden' : 'u-overflow-y-auto'
      ]"
    >
      <div
        slot="detail"
        class="u-width-100 u-height-100 u-position-relative"
      >
        <loader
          v-if="isLoading"
          class="fill--parent"
          :loading="true"
          :color="'#007cf6'"
        />
        <div
          v-if="!isLoading && downloads.length"
          class="u-position-relative u-spacing-mr-m"
        >
          <div
            v-for="(item, index) in downloads"
            :key="index"
            class="u-display-flex u-flex-direction-row u-spacing-ml-l u-border-left u-border-width-s u-border-color-grey-xx-light"
            :class="{
              'u-spacing-pt-xs': index === 0,
              'u-spacing-pt-m': index > 0
            }"
            :style="[
              index === downloads.length - 1 && {
                'padding-bottom': `calc(100vh - ${upperHeight})`
              }
            ]"
          >
            <div class="download-date u-display-flex u-flex-align-items-center">
              <html-component
                wrapper-element="span"
                class="download-day"
                :html-string="item.date.day"
              />
              <html-component
                wrapper-element="span"
                class="download-month"
                :html-string="item.date.month"
              />
            </div>
            <div
              class="u-display-flex u-flex-direction-column u-spacing-pl-s u-width-100 u-spacing-pb-m"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-align-items-center"
              >
                <loader
                  :loading="showLoader(item.status)"
                  :size="'14px'"
                  :color="'#007cf6'"
                />
                <rb-icon
                  v-if="!showLoader(item.status)"
                  class="rb-icon--medium u-flex-align-self-flex-start"
                  :class="[
                    iconToShow(item.status) === 'error-fill'
                      ? 'u-color-red-base'
                      : 'u-color-green-base'
                  ]"
                  :icon="iconToShow(item.status)"
                />
                <html-component
                  wrapper-element="span"
                  class="file-name u-spacing-pl-s u-font-size-6 u-font-weight-600 u-color-grey-light"
                  :html-string="fileNameGenerator(item.status, item.file_name)"
                />
              </div>
              <div class="u-display-flex u-flex-direction-row u-spacing-pv-xs">
                <html-component
                  wrapper-element="span"
                  class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                  :html-string="item.date.time"
                />
              </div>
              <div class="u-display-flex u-flex-direction-row u-spacing-pt-s">
                <span
                  class="u-font-size-7 u-font-weight-600 u-color-grey-lighter"
                  >Location</span
                >
              </div>
              <div class="u-display-flex u-flex-direction-row u-spacing-pt-xs">
                <html-component
                  wrapper-element="span"
                  class="u-font-size-7 u-font-weight-600 u-color-grey-light location-text"
                  :html-string="item.location"
                />
              </div>
              <div
                v-if="
                  showLoader(item.status) || isDownloadSuccessful(item.status)
                "
                class="u-display-flex u-flex-direction-row u-flex-align-items-center u-spacing-pt-m"
                :class="{
                  'u-color-blue-base': isDownloadSuccessful(item.status),
                  'u-cursor-pointer': isDownloadSuccessful(item.status),
                  'u-color-grey-x-light': showLoader(item.status)
                }"
                @click="downloadS3Link(item.s3_link)"
              >
                <rb-icon
                  class="rb-icon--medium u-spacing-mr-xs"
                  :icon="'download'"
                />
                <span class="u-font-size-6 u-font-weight-600"
                  >Save to desktop</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isLoading && !recentDownloads.data.length && panelState === 'open'"
      class="u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-height-100vh u-width-100vw u-spacing-ph-l"
    >
      <img
        src="/images/no_sku_found.png"
        class="no-downloads-image"
        alt="no account data found"
      />
      <span
        class="u-spacing-pt-l u-font-size-5 u-font-weight-600 u-color-grey-light"
        >Nothing found under downloads...</span
      >
      <span
        class="no-downloads-secondary-text u-spacing-pt-m u-font-size-5 u-font-weight-400 u-color-grey-light u-text-align-center"
        >There is nothing under “Manage downloads” yet to see.</span
      >
    </div>
    <div
      class="u-display-flex u-flex-direction-row u-width-100 u-spacing-pv-s u-spacing-ph-m actions-container"
    >
      <rb-icon
        class="rb-icon--medium u-color-orange-base u-flex-align-self-flex-start"
        :icon="'warning'"
      />
      <span
        class="u-spacing-pl-s u-font-size-7 u-font-weight-400 u-color-grey-mid-light"
        >Currently, we support
        <span class="u-color-grey-light"
          >SKUs dashboard & Reporting only. Last
          {{ numberOfDownloadsShown }} reports generated</span
        >
        are shown here.</span
      >
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import { debounce, cloneDeep } from 'lodash';
import HtmlComponent from '@/components/basic/html-component.vue';
import { downloadS3Uri } from '@/utils/helpers/downloader';
import { fetchRecentDownloads } from '@/components/widgets/downloadCenter/downloadCenterUtils.js';
import Vue from 'vue';
import errorBlob from '@/components/basic/errorBlob.vue';
import utils from '@/utils/helpers';

export default {
  components: {
    loader,
    HtmlComponent,
    errorBlob
  },
  props: {
    listener: {
      type: String,
      default: null
    },
    debounceTime: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      panelState: 'close',
      searchText: '',
      dateChip: new Date(),
      downloads: [],
      updatedDownloads: [],
      columnHasSearchText: false,
      searchTermCount: 0,
      isLoading: false,
      searchableKeys: ['date', 'day', 'location', 'file_name', 'month', 'time'],
      upperHeight: '0px',
      numberOfDownloadsShown:
        Vue.options.filters.config_check(
          'feature.report_downloads_list_limit'
        ) ?? 5,
      showErrorBlob: false
    };
  },
  computed: {
    displayYear() {
      return this.recentDownloads?.data?.length > 0
        ? this.recentDownloads?.data[0]?.date?.year
        : this.dateChip.getFullYear();
    },
    recentDownloads() {
      return this.$store.getters.getRecentDownloads;
    },
    showDownloadTimeBanner() {
      let showDownloadTimeBanner = false;
      this.recentDownloads?.data?.forEach((download) => {
        if (this.showLoader(download?.status)) {
          showDownloadTimeBanner = true;
        }
      });
      return showDownloadTimeBanner;
    }
  },
  watch: {
    recentDownloads() {
      this.updateDownloads();
    }
  },

  created() {
    this.updateDownloads();
    this.showErrorBlobCondition();
    eventBus.$on(this.listener, (data) => {
      this.$emit('downloadCenterOpened');
      this.panelState = data;
    });
    eventBus.$on('downloadCompleted', (status) => {
      this.$snackbar.open({
        message: this.snackbarMessageGenerator(status),
        duration: 5000,
        showIcon: false,
        customStyles:
          'position:fixed; top:16px; left:50%; transform:translate(-50%,0%);',
        actionText: 'View downloads',
        onAction: this.openModal,
        buttonColor: '#FFD500',
        type: 'u-text-case-normal',
        actionTextCase: 'none'
      });
    });
  },
  mounted() {
    fetchRecentDownloads(true);
  },
  destroyed() {
    eventBus.$off(this.listener);
    eventBus.$off('downloadCompleted');
  },
  methods: {
    onSearchInput() {
      const searchComponent = this;
      debounce(function () {
        this.filterAndSearchDownloads();
      }, this.debounceTime).call(searchComponent);
    },
    openModal() {
      eventBus.$emit(this.listener, 'open');
    },
    closeModal() {
      eventBus.$emit(this.listener, 'close');
    },
    filterAndSearchDownloads() {
      let filteredDownloads = [];
      this.searchTermCount = 0;
      let downloadsArrForSearch = cloneDeep(this.updatedDownloads);
      if (
        (this.searchText?.length && !/\S/.test(this.searchText)) || // contains only whitespace
        !this.isAllowedChars(this.searchText) || // contains not allowed special chars
        !downloadsArrForSearch.length
      ) {
        filteredDownloads = [];
      } else {
        filteredDownloads = downloadsArrForSearch?.filter((download) => {
          if (this.searchText !== '') {
            this.columnHasSearchText = false;
            download = this.handleSearchHighlight(download);
            if (this.columnHasSearchText) {
              return download;
            }
          } else {
            return download;
          }
        });
      }
      this.downloads = filteredDownloads;
      this.upperHeight = `${100 * this.downloads?.length + 184}px`;
    },
    handleSearchHighlight(download) {
      // highlight the searched text in yellow color
      let str = this.searchText;
      let reg = new RegExp(str, 'gi');
      Object.keys(download)
        .filter((downloadKey) => this.searchableKeys?.includes(downloadKey))
        .forEach((downloadKey) => {
          let value = download[downloadKey];
          if (typeof value !== 'object') {
            let replaced = value?.search(reg) >= 0;
            if (replaced && !this.columnHasSearchText) {
              this.columnHasSearchText = true;
            }
            download[downloadKey] = value?.replace(reg, (str) => {
              this.searchTermCount += 1;
              return `<mark class="searched-term">${str}</mark>`;
            });
          } else {
            this.handleSearchHighlight(download[downloadKey]);
          }
        });
      return download;
    },
    isAllowedChars(str) {
      // checking for allowed chars in search term
      let charCode;
      let strLength = str.length;
      // allowedSpecialCharecters - [space, !, #, &, *, _, :, >];
      const allowedSpecialCharecters = [32, 33, 35, 38, 42, 95, 58, 62];
      for (let i = 0; i < strLength; i++) {
        charCode = str.charCodeAt(i);
        if (
          !(charCode > 47 && charCode < 58) && // numeric (0-9)
          !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
          !(charCode > 96 && charCode < 123) && // lower alpha (a-z)
          allowedSpecialCharecters.indexOf(charCode) === -1
        ) {
          return false;
        }
      }
      return true;
    },
    showLoader(status) {
      return status === 'INITIATED' || status === 'IN-PROGRESS';
    },
    iconToShow(status) {
      if (status === 'TIMED-OUT' || status === 'FAILED') {
        return 'error-fill';
      }
      return 'check-fill-circle';
    },
    isDownloadSuccessful(status) {
      return status === 'SUCCESS';
    },
    updateDownloads() {
      const downloads = this.$store.getters.getRecentDownloads;
      this.downloads = downloads?.data;
      this.updatedDownloads = downloads?.data;
      this.isLoading = downloads?.loading;
      // This height is being calculated by testing various screen sizes and zoom levels. This includes the height of all the above download items and adjust the search and header component as well.
      this.upperHeight = `${100 * downloads?.data?.length + 184}px`;
    },
    downloadS3Link(s3Link) {
      downloadS3Uri(s3Link);
    },
    showDownloadDayAndMonth(index) {
      if (index < 1) {
        return true;
      }
      return !(
        this.downloads[index].date.day === this.downloads[index - 1].date.day &&
        this.downloads[index].date.month ===
          this.downloads[index - 1].date.month
      );
    },
    fileNameGenerator(status, fileName) {
      if (!fileName?.endsWith('.xlsx')) {
        return fileName;
      }
      if (this.showLoader(status)) {
        return 'Custom Report being generated';
      } else if (this.isDownloadSuccessful(status)) {
        return fileName;
      } else {
        return `Custom Report ${fileName} Failed`;
      }
    },
    snackbarMessageGenerator(status) {
      return status === 'SUCCESS'
        ? `<span class="u-display-flex u-align-items-center"><span class="rb-icon icon-check-fill-circle rb-icon--medium u-color-green-base"></span> <span class="u-spacing-pt-xxs u-spacing-pl-s">Download completed for 1 file</span></span>`
        : `<span class="u-display-flex u-align-items-center"><span class="rb-icon icon-error-fill rb-icon--medium u-color-red-base"></span> <span class="u-spacing-pt-xxs u-spacing-pl-s">Download Failed for 1 file</span></span>`;
    },
    showErrorBlobCondition() {
      const browser = utils.getBrowserName();
      this.showErrorBlob = browser === 'IEedge';
    }
  }
};
</script>

<style lang="css" scoped>
.rightPanelDownload {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: white;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.rightPanelDownload .header {
  width: 100%;
  height: 46px;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.widget__body .download-year {
  position: relative;
  left: calc(100% - 33px);
  top: -9px;
  background-color: #fff;
}
.widget__body .download-date {
  position: relative;
  background-color: #fff;
  display: inline-flex;
  flex-direction: column;
  left: -10px;
  max-height: 39px;
}
.widget__body .download-date .download-day {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2b333b;
  padding-bottom: 0.2rem;
}
.widget__body .download-date .download-month {
  font-size: 1.1rem;
  color: #4b5158;
  padding-bottom: 0.4rem;
  text-transform: uppercase;
}
.widget__body .download-item-cta {
  opacity: 0.7;
}
.widget__body .hide-left {
  position: absolute;
  background-color: #fff;
  top: -1px;
  left: 0px;
  width: 25px;
  height: 20px;
}
.rightPanelDownload.close {
  width: 0%;
}
.rightPanelDownload.close .body {
  display: none;
}
.rightPanelDownload.open {
  width: 400px;
}
.rightPanelDownload.open .body {
  display: block;
}
.no-downloads-image {
  width: 129.93px;
  height: 110px;
}
.actions-container {
  background: #ffa8001a;
  position: sticky;
  bottom: 0;
  box-shadow: 0px 1px 4px rgba(43, 51, 59, 0.15);
}
.hidden-date-class {
  margin-left: 22px;
}
.no-downloads-secondary-text {
  line-height: 17px;
}
.location-text {
  line-height: 13px;
}
.file-name {
  word-break: break-all;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
