<template>
  <CardContainer
    :title="title"
    :intermediate-height="intermediateHeight"
    :action-icons="[{ emit: 'close', icon: 'cross' }]"
    :last-updated-date="lastUpdatedDate"
    @close="() => $emit('close')"
  >
    <section
      slot="post-title"
      v-tippy="tippyOptions"
    >
      <rb-icon
        class="u-spacing-mr-xs u-color-grey-lighter"
        :icon="'info-circle-fill'"
      />
      <section
        :id="infoTippyId"
        ref="dropdownMenu"
        class="custom-styles u-spacing-p-l tippy-drop-down u-border-radius-s"
      >
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-m">
          Snapshot of Page 1
        </div>
        <div class="u-font-size-6 u-font-weight-normal">
          Get a quick overview of the top brands and their SKUs ranking on Page
          1 of the search result. The calculated SoV (in %) corresponds to
          <span class="u-font-weight-600">Total SoV on Page 1</span>
          for that keyword-date, weighted by rank on Page 1. We capture
          information on Page 1 during core daylight hours.
        </div>
      </section>
    </section>
    <template
      slot="body"
      slot-scope="{}"
    >
      <sovSnapshotIntraday
        ref="sovSnapshot1"
        :class="snapshotWidgetWrapperClass"
        :intermediate-height="160"
        :image-data-service="imageDataService1"
        :snapshot-shares-service="snapshotSharesService1"
        :dropdown-service="dropdownService1"
        :crawl-times-service="crawlTimesService1"
        :namespace="namespace"
        :widget-name="widgetName"
        :external-keyword-selected="externalKeywordSelected"
        :global-where-clause="globalWhereClause"
        :filter-pretext="snapshotFilterPretext"
        :colors-map="snapshotWidgetColors"
        :is-marketing-client="isMarketingClient"
        :retailer-brand-name="retailerBrandName"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :external-client-brand="externalClientBrand"
        :multi-crawl="multiCrawl"
        @keywordsDataRequest="(v) => $emit('keywordsDataRequest', v)"
        @sovDataRequest="(v) => $emit('sovDataRequest', v)"
        @newLegendData="(v) => $emit('newLegendData', v)"
      >
      </sovSnapshotIntraday>
      <sovSnapshotIntraday
        ref="sovSnapshot2"
        class="u-spacing-mt-m u-spacing-pt-m u-border-width-s u-border-top u-border-color-grey-xxx-light"
        :class="snapshotWidgetWrapperClass"
        :intermediate-height="160"
        :image-data-service="imageDataService2"
        :snapshot-shares-service="snapshotSharesService2"
        :dropdown-service="dropdownService2"
        :crawl-times-service="crawlTimesService2"
        :namespace="namespace"
        :widget-name="widgetName"
        :external-keyword-selected="externalKeywordSelected"
        :global-where-clause="globalWhereClause"
        :filter-pretext="snapshotFilterPretext"
        :colors-map="snapshotWidgetColors"
        :is-marketing-client="isMarketingClient"
        :retailer-brand-name="retailerBrandName"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :external-client-brand="externalClientBrand"
        :multi-crawl="multiCrawl"
        @keywordsDataRequest="(v) => $emit('keywordsDataRequest', v)"
        @sovDataRequest="(v) => $emit('sovDataRequest', v)"
        @newLegendData="(v) => $emit('newLegendData', v)"
      >
      </sovSnapshotIntraday>
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '../organisms/card-container';
import sovSnapshotIntraday from './sovSnapshotIntraday.vue';
import moment from 'moment/moment';

export default {
  components: {
    CardContainer,
    sovSnapshotIntraday
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    intermediateHeight: {
      type: Number,
      default: 160
    },
    imageDataService1: {
      type: Object,
      default: null
    },
    snapshotSharesService1: {
      type: Object,
      default: null
    },
    dropdownService1: {
      type: Object,
      default: null
    },
    crawlTimesService1: {
      type: Object,
      default: null
    },
    imageDataService2: {
      type: Object,
      default: null
    },
    snapshotSharesService2: {
      type: Object,
      default: null
    },
    dropdownService2: {
      type: Object,
      default: null
    },
    crawlTimesService2: {
      type: Object,
      default: null
    },
    snapshotWidgetWrapperClass: {
      type: String,
      default: ''
    },
    namespace: {
      type: String,
      default: ''
    },
    widgetName: {
      type: String,
      default: ''
    },
    externalKeywordSelected: {
      type: String,
      default: null
    },
    globalWhereClause: {
      type: Object,
      default: () => ({})
    },
    snapshotFilterPretext: {
      type: String,
      default: null
    },
    snapshotWidgetColors: {
      type: Object,
      default: null
    },
    isMarketingClient: {
      type: Boolean,
      default: true
    },
    retailerBrandName: {
      type: String,
      default: null
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    externalClientBrand: {
      type: String,
      default: null
    },
    multiCrawl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infoTippyId: null
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#' + this.infoTippyId,
        reactive: true,
        distance: 4,
        placement: 'bottom',
        interactive: true,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        lastUpdatedDate: ''
      };
    }
  },
  created() {
    this.infoTippyId = 'dropdown-' + this._uid;
    this.lastUpdatedDate = moment(this.globalWhereClause.date.to).format('LL');
  },
  methods: {
    initialiseDate() {
      this.$refs.sovSnapshot1.initialiseDate();
      this.$refs.sovSnapshot2.initialiseDate();
    },
    getData() {
      this.$refs.sovSnapshot1.getData();
      this.$refs.sovSnapshot2.getData();
    }
  }
};
</script>

<style scoped>
.custom-styles {
  width: 420px;
  line-height: 1.23;
}
</style>
