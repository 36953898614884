<template>
  <div
    data-cy="pageBody"
    class="page-body"
  >
    <expoter />
    <slot />
  </div>
</template>

<script>
import expoter from '../widgets/expoter/expoter';
export default {
  components: {
    expoter
  }
};
</script>
