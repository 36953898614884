<template>
  <div
    class="u-display-flex u-height-100 u-width-100 u-width-100 editable-header"
  >
    <customTooltip
      :tippy="{
        html: '#tooltip-' + $attrs.fieldName,
        interactive: false,
        offset: '0,30',
        trigger: 'click'
      }"
    >
      <div slot="toolTipData">
        <div class="tooltip_container">
          <div
            class="u-width-100 u-display-flex u-flex-direction-column u-spacing-pv-s"
          >
            <rb-input
              v-model="newHeaderText"
              :placeholder="inputPlaceholder"
              class="u-font-weight-600 u-spacing-ph-s u-font-size-6 u-width-100 u-spacing-mb-s"
            />
            <div class="u-display-flex">
              <div
                class="u-width-100 u-display-flex u-spacing-ph-s u-spacing-pb-m u-flex-align-items-center"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-color-orange-base"
                  :icon="'info-circle-fill'"
                />
                <div
                  class="u-font-size-5 u-font-weight-400 u-color-orange-base u-spacing-ml-s"
                >
                  This change will also reflect in budget optimizer
                </div>
              </div>
            </div>
            <div
              class="u-spacing-pt-m u-spacing-ph-s u-border-top u-border-color-grey-xxx-light u-border-width-s"
            >
              <footerButtons
                :hollow-hover-button-left="false"
                left-button-type="filled"
                btn-left-text="Apply"
                :button-size="'s'"
                :addition-left-btn-props="{ loading: isLoading }"
                :disable-left-btn="isLoading"
                :disable-right-btn="isLoading"
                @onClickBtnRight="cancelRename"
                @onClickBtnLeft="commitRename"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        slot="body"
        slot-scope="{ tippyConfig }"
      >
        <div
          :ref="'tippyRef'"
          v-tippy="tippyConfig"
          class="count-chip u-font-size-6"
        ></div>
      </div>
    </customTooltip>
    <div
      v-if="internalState == 'view'"
      :key="key"
      class="u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-space-between u-flex-justify-content-space-between header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600 u-color-grey-lighter"
    >
      <div
        v-if="leftHeaderIcon.show"
        v-tippy
        :title="leftHeaderIcon.tooltipTitle"
      >
        <rb-icon
          :class="leftHeaderIcon.classes"
          :icon="leftHeaderIcon.icon"
        />
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <span class="text-label">
          {{ displayName }}
          <span v-if="displayHeaderCount"> ({{ headerCount }}) </span>
        </span>
        <parent-asin-tooltip v-if="showParentAsinTooltip" />
      </div>
      <div
        class="header-right-options-section u-display-inline-flex u-flex-align-items-center"
      >
        <div
          v-if="headerIcon.show"
          v-tippy
          :title="headerIcon.tooltipTitle"
          @click="onIconClick"
        >
          <rb-icon
            :class="headerIcon.classes"
            :icon="headerIcon.icon"
          />
        </div>
        <rb-select
          v-if="selectOptions != null"
          :send-details="true"
          :on-close="optionSelected"
          :options="selectOptions"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          >
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-base rotate-90"
              icon="more-horizontal"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              v-tippy="option.tippyConfig"
              class="u-display-flex u-flex-align-items-center"
              :class="{ disabled: option.disabled }"
              :title="option.toolTipText"
            >
              {{ option.title }}
            </div>
          </template>
        </rb-select>
      </div>
    </div>
    <div
      v-else-if="internalState == 'edit'"
      class="u-display-inline-flex u-flex-align-items-center"
    >
      <input
        v-model="newHeaderText"
        :placeholder="inputPlaceholder"
        class="u-font-weight-600 u-font-size-6 u-width-80 u-border-radius-s u-border-color-blue-base u-border-width-s editable_table_input"
      />
      <div
        class="u-display-inline-flex u-flex-align-items-center u-spacing-pl-s"
        @click="cancelRename"
      >
        <rb-icon
          icon="cross-fill-circle"
          class="u-color-grey-x-light"
        />
      </div>
      <div
        class="u-display-inline-flex u-flex-align-items-center u-spacing-pl-s"
        @click="commitRename"
      >
        <rb-icon
          icon="check-fill-circle"
          class="u-color-blue-base"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ParentAsinTooltip from '@/components/pages/insights/amazon/market-share/molecules/parent-asin-tooltip.vue';
import customTooltip from '@/components/widgets/customToolTip.vue';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import IconText from '@/components/basic/iconText.vue';
export default {
  name: 'EditableHeaderWithOptions',
  components: {
    ParentAsinTooltip,
    customTooltip,
    footerButtons,
    IconText
  },
  props: {
    columnDefn: {
      type: Object,
      required: true
    },
    initState: {
      type: String,
      default: 'view'
    },
    displayName: {
      type: String,
      default: 'default Title'
    },
    headerCount: {
      type: String,
      default: ''
    },
    showParentAsinTooltip: {
      type: Boolean,
      default: false
    },
    toolTipText: {
      type: String,
      default: 'default Title'
    },
    headerIcon: {
      type: Object,
      default() {
        return {
          icon: '',
          classes: '',
          show: false,
          tooltipTitle: ''
        };
      }
    },
    leftHeaderIcon: {
      type: Object,
      default() {
        return {
          icon: '',
          classes: '',
          show: false,
          tooltipTitle: ''
        };
      }
    },
    enableRename: {
      type: Boolean,
      default: true
    },
    enableDelete: {
      type: Boolean,
      default: true
    },
    customComponent: {
      type: Boolean,
      default: false
    },
    entity: {
      type: String,
      default: ''
    },
    deleteProps: {
      type: Object,
      default: () => ({})
    },
    renameProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // allowed states view, edit
      key: 1,
      internalState: 'view',
      newHeaderText: '',
      inputPlaceholder: 'Catalog Column’s name',
      iconTippy: {
        placement: 'top',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    displayHeaderCount() {
      return this.headerCount !== 'null';
    },
    isLoading() {
      return (
        this.entity && this.$store.getters.getEntityLoadingState(this.entity)
      );
    },
    selectOptions() {
      const enabledOptions = [];
      if (this.enableRename) {
        enabledOptions.push({
          title: 'Rename',
          value: 'Rename',
          icon: 'pencil',
          handler: 'triggerRename',
          emitter: 'triggerRename',
          ...(this.renameProps || {})
        });
      }
      if (this.enableDelete) {
        enabledOptions.push({
          title: 'Delete',
          value: 'Delete',
          icon: 'delete',
          handler: 'triggerDelete',
          emitter: 'onDelete',
          ...(this.deleteProps || {})
        });
      }
      return enabledOptions;
    }
  },
  watch: {
    displayName(newValue) {
      this.newHeaderText = newValue;
    }
  },
  created() {
    this.internalState = this.initState;
    this.newHeaderText = this.displayName;
  },
  methods: {
    cancelRename() {
      this.internalState = 'view';
      this.newHeaderText = this.displayName;
      this.$emit('onRenameCancel', {
        columnDefn: this.columnDefn
      });
      if (this.customComponent) {
        this.$refs.tippyRef.click();
      }
    },
    commitRename() {
      if (this.newHeaderText?.trim()) {
        this.internalState = 'view';
        this.$emit('onRename', {
          columnDefn: this.columnDefn,
          newTitle: this.newHeaderText.trim()
        });
      }
    },
    optionSelected(context, selectOptions) {
      for (let index = 0; index < selectOptions.length; index++) {
        const element = selectOptions[index];
        if (element.handler && this[element.handler]) {
          this[element.handler](selectOptions, context);
        }
        if (element.emitter) {
          this.$emit(element.emitter, {
            event: element,
            columnDefn: this.columnDefn
          });
        }
      }
    },
    triggerRename(e) {
      if (this.customComponent) {
        this.key++;
        this.$refs.tippyRef.click();
      } else {
        this.internalState = 'edit';
      }
      this.newHeaderText = this.displayName;
    },
    onIconClick(event) {
      this.$emit('iconClicked', { event, columnDefn: this.columnDefn });
    }
  }
};
</script>

<style lang="css" scoped>
.reverse-content {
  flex-direction: row-reverse;
}
.clear_input_styles.rb-control {
  padding: 0px;
}
.editable_table_input {
  width: 100%;
  padding: 5px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  outline-color: #007cf6;
  background-color: rgba(0, 124, 246, 0.05);
}

.header-text {
  height: 100%;
  padding: 8px 0;
  word-break: break-word;
  overflow: hidden;
  vertical-align: middle;
  white-space: normal;
  display: flex;
  align-items: center;
  line-height: 1.1;
}
.header-text .text-label {
  max-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.header-text .tippy-backdrop {
  background: none;
}
</style>
