import moment from 'moment-timezone';
export default {
  getActivityData: (data, timeStampKey = 'timeStamp') => {
    if (!data || data.length < 0) {
      return data;
    }
    const activityData = [];
    let entries = [];
    let dateBlocks = 0;
    data.sort((a, b) => {
      return (
        new Date(b[timeStampKey]).getTime() -
        new Date(a[timeStampKey]).getTime()
      );
    });
    for (let i = 0; i < data.length; i++) {
      const activity = Object.assign({}, data[i]);
      const nextActivity = Object.assign(
        {},
        data[data.length - 1 === i ? i : i + 1]
      );
      const activityDate = moment(new Date(activity[timeStampKey])).format(
        'MM-DD-YYYY'
      );
      const nextActivityDate = moment(
        new Date(nextActivity[timeStampKey])
      ).format('MM-DD-YYYY');
      if (activityDate === nextActivityDate && i !== data.length - 1) {
        entries.push(...addEntry(activity, timeStampKey));
      } else {
        entries.push(...addEntry(activity, timeStampKey));
        activity.entries = entries;
        activity.date = splitDate(data[i][timeStampKey]);
        activity.showYear = dateBlocks === 0;
        activity.showDate = true;
        activity.cssClasses = setClass(dateBlocks);
        entries = [];
        dateBlocks++;
        activityData.push(activity);
      }
    }
    return activityData;
  }
};

const splitDate = (date) => {
  var dateTimeObj = {};
  var calendarMap = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];
  var momentDate = moment(new Date(date)).local();
  const fullDate = new Date(momentDate);
  const zoneName = moment.tz.guess();
  const timezone = moment.tz(zoneName).zoneAbbr();
  dateTimeObj.day = ('0' + fullDate.getDate()).slice(-2);
  dateTimeObj.month = calendarMap[fullDate.getMonth()];
  dateTimeObj.year = fullDate.getFullYear();
  dateTimeObj.time = moment(date).format('hh:mm A') + ' ' + timezone;
  dateTimeObj.displayChip = moment(date).format('LL');
  return dateTimeObj;
};

const setClass = (index) => {
  if (index === 0) {
    return 'u-border-top u-spacing-pt-xl u-spacing-pb-l u-spacing-mt-xl';
  } else {
    return 'u-spacing-pb-l';
  }
};

const addEntry = (activity, timeStampKey = 'timeStamp') => {
  const entry = Object.assign({}, activity);
  if (!entry.viewPayload) {
    entry.viewPayload = {};
  }
  entry.viewPayload.date = splitDate(activity[timeStampKey]);
  entry.status = getEventStatus(activity);
  entry.viewPayload.email = activity.username;
  entry.viewPayload.username = activity.username;
  entry.viewPayload = addErrorLogIfAny(entry, entry.viewPayload);

  // If an entry has multiple payloads to be spread, we expand those entries and return them all to be pushed
  // This scenario is occuring because in few actions, we have to send all items in a single array payload
  if (
    entry.viewPayload?.spreadViewPayload &&
    entry.viewPayload?.viewPayloadArray
  ) {
    const newEntries = [];
    entry.viewPayload?.viewPayloadArray.forEach((viewPayloadArrayItem) => {
      newEntries.push({
        ...entry,
        viewPayload: {
          ...entry.viewPayload,
          ...viewPayloadArrayItem
        }
      });
    });
    return newEntries;
  }
  return [entry];
};

const getEventStatus = (activity) => {
  switch (activity.actionStatus) {
    case 'successful':
      return 'success';
    case 'in progress':
      return 'in-progress';
    case 'error':
      return 'failed';
    case 'duplicate entry':
      return 'duplicate entry';
  }
};
/**
 *
 * @param {*} entry log for action
 * @param {*} payload viewPayload
 * @returns viewPayload
 */
const addErrorLogIfAny = (entry, payload) => {
  if (entry.actionStatus === 'error') {
    const parsedLog = deepParseString(entry.outputData);
    // console.log({ actionType: entry.actionType, parsedLog });
    if (entry.actionType && entry.actionType.includes('walmart')) {
      if (!parsedLog?.description && parsedLog?.details) {
        // walmart campaign create flow action's error logged here
        payload.errorMessage = parsedLog.details;
      } else {
        // walmart campaign edit action's error logged here
        if (['walmartCampaignMetadataChange'].includes(entry.actionType)) {
          payload.errorMessage =
            parsedLog?.description?.[payload?.campaignId]?.description;
        } else if (
          ['walmartKeywordMetadataChange'].includes(entry.actionType)
        ) {
          payload.errorMessage =
            parsedLog?.description?.[payload?.keywordId]?.description;
        } else if (['walmartCampaignCancelReview'].includes(entry.actionType)) {
          payload.errorMessage =
            parsedLog?.description?.[payload?.reviewId]?.description;
        } else if (
          ['walmartProfileMetadataChange'].includes(entry.actionType)
        ) {
          payload.errorMessage =
            parsedLog?.description?.[payload?.sba_profile_id]?.description;
        } else if (
          [
            'walmartAddSkuToCampaigns',
            'walmartAddKeywordToCampaigns',
            'walmartCampaignPlacementMetadataChange',
            'walmartCampaignSubmitReview',
            'walmartAddPlacementBidMetadata',
            'walmartAddPlatformBidMetadata',
            'walmartAddProfileToCampaigns'
          ].includes(entry.actionType)
        ) {
          if (parsedLog?.description?.[0]?.code?.toLowerCase() === 'failed') {
            payload.errorMessage = parsedLog.description[0].description;
          }
          if (parsedLog?.code && parsedLog.code === 'failure') {
            payload.errorMessage = parsedLog.details;
          }
        }
      }
    }
  }
  return payload;
};

const deepParseString = (input) => {
  try {
    if (typeof input === 'string') {
      const parsedString = JSON.parse(input);
      if (typeof parsedString === 'object') {
        for (let key in parsedString) {
          parsedString[key] = deepParseString(parsedString[key]);
        }
      }
      return parsedString;
    }
    return input;
  } catch (error) {
    return input;
  }
};
