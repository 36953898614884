/***
  API Documentation :
  http://kazupon.github.io/vue-i18n/en/
***/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { dateTimeFormats, numberFormats } from './formats';

const locale = window.navigator.userLanguage || window.navigator.language;

Vue.config.productionTip = false;
var englishBundle = require('./en.json');
var locales = {
  en: englishBundle,
  'en-US': englishBundle
};

Vue.use(VueI18n);
export const i18n = new VueI18n({ locale, numberFormats, dateTimeFormats });

Object.keys(locales).forEach(function (lang) {
  i18n.setLocaleMessage(lang, locales[lang]);
});
