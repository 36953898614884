import Vue from 'vue';
import { capitalize } from '@/utils/helpers/formatter';

export const legendCell = (initialState) => {
  return Vue.extend({
    computed: {
      value() {
        const value =
          this.params.data.sub_type === '-'
            ? this.params.data.campaign_name
            : this.params.data.sub_type || '';
        return capitalize(value);
      },
      isLinkEnabled() {
        return true;
      }
    },
    template: `
        <div
          class="u-display-flex u-flex-align-items-center u-position-relative"
          :class="this.params.data.sub_type === '-' ? '' : 'u-spacing-pl-m'"
        >
          <rb-icon
            v-if="this.params.data.sub_type === '-'"
            :icon="params.icon || 'dot'"
            class="rb-icon--small"
            :class="[
              {
                'u-color-orange-base': params.data.campaign_sub_category === 'placement',
                'u-color-purple-base':  params.data.campaign_sub_category === 'platform'
              }
            ]"
          />
          <redirect 
            v-if="isLinkEnabled && params.data.sub_type === '-' && ['SB','SBA','SP','SV'].includes(params.data.campaign_type)" 
            :params="params"
          />
          <div v-else class="u-spacing-ml-s u-color-grey-base">
            {{ value }}
          </div>
        </div>`
  });
};
