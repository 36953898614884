<template>
  <section
    id="scrollable"
    class="scrollable u-display-flex u-flex-direction-row"
  >
    <div class="section__left sections-menu u-width-20">
      <section
        class="u-height-100 u-spacing-pl-l u-spacing-pr-l"
        style="margin-top: 50%"
      >
        <div
          v-for="(step, index) in _config"
          v-show="activeSection !== offsets.length - 1"
          :key="index"
          class="menu-point u-spacing-mb-m"
          :class="{ active: activeSection == index }"
        >
          <p
            :class="[
              {
                'u-color-blue-base u-font-size-5 u-cursor-pointer':
                  activeSection === index
              },
              { 'u-font-size-7': activeSection < index },
              { 'u-font-size-5 u-cursor-pointer': activeSection > index }
            ]"
            class="u-line-height-1 u-display-flex u-flex-align-items-center u-spacing-mb-xs u-cursor-pointer"
            @click="scrollToSection(index)"
          >
            <rb-icon
              class="u-cursor-pointer"
              :class="[
                {
                  'u-color-blue-base rb-icon--medium': activeSection === index
                },
                {
                  'rb-icon--small u-color-grey-lighter': activeSection < index
                },
                { 'rb-icon--medium': activeSection > index }
              ]"
              :icon="'dot'"
            />
            <span class="u-line-height-1">{{ step.name }}</span>
          </p>
          <div class="u-spacing-pl-m u-font-size-7 u-line-height-1-3">
            <!-- eslint-disable -->
            <span
              class="u-color-grey-lighter"
              v-html="
                allStepsData.strategyUIText &&
                allStepsData.strategyUIText[step.textMapping]
              "
            />
            <!-- eslint-enable -->
          </div>
        </div>
      </section>
    </div>
    <div
      id="steps"
      class="section__right u-flex-1"
    >
      <section
        style="margin-top: 12%"
        class="fullpage u-position-relative"
      >
        <div
          v-for="(step, $index) in _config"
          :key="$index"
          class="step-class--pseudo strategyStep step_page u-overflow-auto"
        >
          <component
            :is="step.component"
            :step="step"
            :selected-values="allStepsData"
            :all-config="_config"
            @nextStep="moveDown"
            @prevStep="moveUp"
          />
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';

export default {
  name: 'StepComponent',
  mixins: [stepsMixin],
  props: {
    config: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allStepsData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeSection: 0,
      offsets: []
    };
  },
  computed: {
    _config() {
      return [...this.config];
    }
  },
  mounted() {
    this.calculateSectionOffsets();
  },
  methods: {
    calculateSectionOffsets() {
      const parentSection = document.getElementById('steps');
      if (parentSection) {
        const sections = parentSection.getElementsByClassName('strategyStep');
        sections[0].classList.add('active_step');
        const length = sections.length;
        for (let i = 0; i < length; i++) {
          const sectionOffset = sections[i].offsetTop;
          sections[i].style.transform = `translate3d(0%, ${i * 100}% ,0px)`;
          this.offsets.push(sectionOffset);
        }
      }
    },
    moveDown: function (step) {
      // console.log(step);
      // Vue.set(this.allStepsData, step.allStepsData);
      // this.$emit('stepsGlobalEvent', 'event');
      this.activeSection++;
      if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;
      this.scrollToSection(this.activeSection);
    },
    moveUp: function (step) {
      // console.log(step);
      this.activeSection--;
      if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;
      this.scrollToSection(this.activeSection);
    },
    scrollToSection: function (id) {
      if (Math.abs(id - this.activeSection) > 1) {
        this.$emit('stepsSkippedEvent');
      }
      const parentSection = document.getElementById('steps');
      if (parentSection.getElementsByClassName('strategyStep')[id]) {
        var elems = document.querySelectorAll('.strategyStep');
        [].forEach.call(elems, function (el, index) {
          if (id > index) {
            el.style.transform = `translate3d(0%, ${
              -1 * (index + 1) * 999
            }% ,0px)`;
          } else if (id === index) {
            el.style.transform = `translate3d(0%, ${0}% ,0px)`;
          } else {
            el.style.transform = `translate3d(0%, ${index * 100}% ,0px)`;
          }
          el.classList.remove('active_step');
        });
        parentSection
          .getElementsByClassName('strategyStep')
          [id].classList.add('active_step');
        this.activeSection = id;
      }
    }
  }
};
</script>
<style lang="css" scoped>
.scrollable {
  height: calc(100vh - 95px);
}
.fullpage {
  height: 88%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step_page {
  transition: 0.5s;
  width: 100%;
  min-height: 100%;
}

.step_page.active_step {
  position: absolute;
}

.u-height-auto {
  height: auto;
}
</style>
