import HttpService from '@/utils/services/http-service';
import { setDisplayValuesSnapshotBar } from '@/pages/digital_shelf/share_of_voice/store.js';
import { convertArrayToMap } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';
export default class DataService {
  widgetId;
  widget;
  page;
  pageId;
  globalViewId;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    rows: [],
    noData: false
  };

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getMetadata() {
    try {
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false
      };
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
    }
  }

  async getData(requestCopy, searchRankKey, listingTypeKey) {
    try {
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.post(
        'DASHBOARD_SERVICE',
        requestCopy,
        { append: '/entity/metrics/data' },
        { widgetId: this.widgetId, hashingKeysToIgnore: ['where'] }
      );
      const { entityData } = data;
      if (entityData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        const snapshotData = this.convertToSnapShotFormat(
          entityData,
          searchRankKey,
          listingTypeKey
        );
        this.data = {
          rows: setDisplayValuesSnapshotBar(snapshotData),
          error: false,
          load: false,
          noData: entityData.length === 0
        };
      }
    } catch (e) {
      if (HttpService.isCancelRequestError(e)) {
        return e;
      }
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
      return e;
    }
  }

  convertToSnapShotFormat(data, searchRankKey, listingTypeKey) {
    var dataToReturn = [];
    var tempObj = {
      ORGANIC: {
        type: 'ORGANIC',
        values: []
      },
      SPONSORED: {
        type: 'SPONSORED',
        values: []
      },
      RETAILER_BRANDED: {
        type: 'RETAILER_BRANDED',
        values: []
      },
      HEADER: {
        type: 'HEADER',
        values: []
      },
      SPONSORED_VIDEO: {
        type: 'SPONSORED_VIDEO',
        values: []
      }
    };
    for (var obj of data) {
      const flatMap = convertArrayToMap(obj.data);
      if (tempObj[flatMap[listingTypeKey]]) {
        tempObj[flatMap[listingTypeKey]].values.push(flatMap);
      }
    }
    if (tempObj.HEADER.values.length > 0) {
      dataToReturn.push(tempObj.HEADER);
    }

    if (tempObj.RETAILER_BRANDED.values.length > 0) {
      var retailerIndex = tempObj.RETAILER_BRANDED.values[0][searchRankKey];
      dataToReturn[retailerIndex] = tempObj.RETAILER_BRANDED;
    }

    for (obj of tempObj.ORGANIC.values) {
      dataToReturn[obj[searchRankKey]] = {
        type: 'ORGANIC',
        values: [obj]
      };
    }
    if (tempObj.SPONSORED.values.length > 0) {
      for (obj of tempObj.SPONSORED.values) {
        dataToReturn[obj[searchRankKey]] = {
          type: 'SPONSORED',
          values: [obj]
        };
      }
    }
    if (tempObj.SPONSORED_VIDEO.values.length > 0) {
      for (obj of tempObj.SPONSORED_VIDEO.values) {
        dataToReturn[obj[searchRankKey]] = {
          type: 'SPONSORED_VIDEO',
          values: [obj]
        };
      }
    }
    const filteredArray = dataToReturn.filter(Boolean);
    return filteredArray;
  }
}
