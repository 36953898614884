/* eslint-disable @typescript-eslint/camelcase */
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import {
  constants,
  getGlobalSettingsForMS
} from '@/components/pages/insights/amazon/market-share/utils';

export default class DataService {
  widget;
  page;
  pageId;
  globalViewId;
  isLoading;
  v2 = true;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    rows: [],
    noData: false
  };

  pagination = {
    page: 1,
    limit: 10
  };

  operations = {
    orderByList: []
  };

  savedSortState() {
    return (
      getGlobalSettingsForMS(undefined, undefined, false)?.[
        constants.SORTING_OBJECT
      ]?.skuPage.filter((state) => {
        return state.dimension !== 'parent_asin';
      }) || []
    );
  }

  constructor(page, widget, pageId, globalViewId, v2) {
    const this1 = this;
    class Operations {
      getMetricNameFromColumnName = this.getMetricNameFromColumnName;
      _orderByList = [];
      get orderByList() {
        return this._orderByList.map((orderBy) => {
          return {
            dimension:
              this1.getMetricNameFromColumnName(orderBy.dimensionMapping) ||
              orderBy.dimension,
            direction: orderBy.direction
          };
        });
      }

      set orderByList(orderByList) {
        this._orderByList = orderByList;
      }
    }
    this.operations = new Operations();
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
    this.v2 = v2;
  }

  getMetricNameFromColumnName(columnName) {
    const threepSelection =
      getGlobalSettingsForMS('nestedSettings')?.include3p?.value || 'include3p';
    const dimensionMap = this.metadata?.rows?.metadata?.dimensionMapping || {};
    return dimensionMap?.[columnName]?.[threepSelection];
  }

  async getMetadata() {
    try {
      this.isLoading = true;
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false,
        rows: data
      };
      this.isLoading = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
      this.isLoading = false;
    }
  }

  outerWhereClauseComputation(
    checkBox,
    apiPayload,
    outerWhereClauseDimensionNameValueList
  ) {
    if (checkBox && checkBox.your && checkBox.comp) {
      apiPayload.where.dimensionNameValueList =
        apiPayload.where.dimensionNameValueList.filter(
          (item) => item.dimensionName !== 'client_flag'
        );
    } else if (checkBox.your && !checkBox.comp) {
      // as per contract now we have to start sending client flag in outerWhereClause
      outerWhereClauseDimensionNameValueList.push({
        dimensionName: 'client_flag',
        dimensionValue: 'client'
      });
    } else {
      // as per contract now we have to start sending client flag in outerWhereClause
      outerWhereClauseDimensionNameValueList.push({
        dimensionName: 'client_flag',
        dimensionValue: 'comp'
      });
    }
  }

  generatePayload(metricsListDetails, globalSettings, checkBox) {
    const apiPayload = cloneDeep(metricsListDetails.api.request);
    apiPayload.dimensionsList = (apiPayload?.dimensionsList || []).filter(
      (item) => item !== apiPayload.entityType
    );
    apiPayload.metricsList = [metricsListDetails.name];

    const threepSelection =
      globalSettings?.settingValue?.include3p?.value || 'include3p';
    const dimensionMap =
      this.metadata?.rows?.metadata?.sortingCumMeasureList || {};

    const orderByList = this.savedSortState();
    if (orderByList?.length > 0) {
      const metricsListName =
        this.metadata?.rows?.metrics[
          dimensionMap?.[orderByList[0].dimensionMapping]?.[threepSelection]
        ]?.name;
      apiPayload.operations.orderByList = [{}];
      apiPayload.operations.orderByList[0].dimension =
        dimensionMap?.[orderByList[0].dimensionMapping]?.[threepSelection] ||
        orderByList[0].dimension;
      apiPayload.operations.orderByList[0].direction = orderByList[0].direction;
      if (metricsListName) {
        apiPayload.metricsList.push(metricsListName);
      }
    }

    apiPayload.operations.page = this.pagination.page;
    apiPayload.operations.limit = this.pagination.limit;
    apiPayload.where = cloneDeep(globalSettings.where);
    apiPayload.dimensionsList.push('client_flag');
    apiPayload.where.dimensionNameValueList =
      apiPayload.where.dimensionNameValueList.filter((dimension) => {
        return dimension.dimensionName !== constants.THREE_P_GROUP_FLAG;
      });
    const outerWhereClauseDimensionNameValueList = [];
    this.outerWhereClauseComputation(
      checkBox,
      apiPayload,
      outerWhereClauseDimensionNameValueList
    );
    if (globalSettings.searchedText) {
      apiPayload.dimensionsList.push('search');
      // as per contract now we have to start sending search data in outerWhereClause
      outerWhereClauseDimensionNameValueList.push({
        dimensionName: 'search',
        dimensionValue: globalSettings.searchedText,
        operator: 'ILIKE'
      });
    }
    // setting apiPayload.operations.outerWhereClause to empty object to avoid undefined error
    apiPayload.operations.outerWhereClause = {};
    apiPayload.operations.outerWhereClause.dimensionNameValueList =
      outerWhereClauseDimensionNameValueList;
    return apiPayload;
  }

  async getData(request, apiDetails) {
    const requestCopy = cloneDeep(request);
    const apiDetailsCopy = cloneDeep(apiDetails);
    try {
      this.isLoading = true;
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.post(
        apiDetailsCopy?.service || 'MARKETSHARE',
        requestCopy,
        {
          append: apiDetailsCopy?.endPoint || '/marketshare/asins/data',
          params: {
            v2: this.v2
          }
        }
      );

      const tableRowData = data?.entityData || data?.data?.entityData || [];

      if (tableRowData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        this.data = {
          rows: tableRowData,
          error: false,
          load: false,
          noData: false
        };
      }
      this.isLoading = false;
      return this.data;
    } catch (e) {
      this.isLoading = false;
      console.log(e, 'error');
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
    }
  }

  async download(whereClause) {
    return new Promise(async (resolve, reject) => {
      try {
        const { endPoint, request, service } =
          this.metadata.rows.downloadApiTemplate;

        if (request) {
          const payLoad = request;
          delete payLoad.operations.page;
          payLoad.where = cloneDeep(whereClause);
          const { data } = await HttpService.post(service, payLoad, {
            append: endPoint
          });
          downloadFile(data.url);
        }
        resolve();
      } catch (e) {
        reject(e);
        console.error('error');
      }
    });
  }

  async getEditableColumns(request, apiDetails) {
    const requestCopy = cloneDeep(request);
    const apiDetailsCopy = cloneDeep(apiDetails);
    try {
      this.isLoading = true;
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.get(
        apiDetailsCopy?.service || 'DASHBOARD_SERVICE',
        {
          append: apiDetailsCopy?.endPoint || '/entity/metrics/data',
          params: {
            v2: this.v2
          }
        }
      );

      return data;
    } catch (e) {
      this.isLoading = false;
      console.log(e, 'error');
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
    }
  }

  getEstimatedSalesMetricName(columnName, globalSettings) {
    const threepSelection =
      globalSettings.settingValue?.include3p?.value || 'include3p';
    const dimensionMap = {
      sortingCumMeasureList: {
        include3p: 'marketshareSkuPerformance_sales_estimates_v2',
        exclude3p: 'marketshareSkuPerformance_sales_estimates_exclude_all3p_v2',
        exclude3pclient:
          'marketshareSkuPerformance_sales_estimates_exclude_my3p_v2'
      }
    };
    return dimensionMap?.[columnName]?.[threepSelection];
  }
}
