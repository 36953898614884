<template>
  <div
    :class="`no-data-state u-display-flex u-flex-direction-column u-position-relative u-overflow-hidden u-flex-align-items-center u-flex-justify-content-center u-spacing-pt-xxl u-spacing-pb-xxxl ${customClass}`"
  >
    <rb-icon
      class="u-color-grey-xx-light no-data-icon"
      :icon="icon"
    />
    <span class="u-color-black-base u-font-size-7 u-spacing-pb-s">{{
      showableText
    }}</span>
    <span
      class="u-color-blue-base u-font-size-7 u-font-weight-600 u-cursor-pointer"
      @click="changeDateTypeEvent"
      >{{ dateSelectTriggerText }}</span
    >
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  props: {
    showableText: {
      type: String,
      default: 'Date Type is not compatible hence there is no data'
    },
    dateSelectTriggerText: {
      type: String,
      default: 'Change Date Type'
    },
    icon: {
      type: String,
      default: 'calendar'
    },
    customClass: {
      type: String,
      default: ''
    },
    toggleType: {
      type: String,
      default: 'date-type'
    }
  },
  methods: {
    changeDateTypeEvent() {
      switch (this.toggleType) {
        case 'date-type':
          eventBus.$emit('showOptionsDropdown');
          break;
        case 'date-range':
          eventBus.$emit('showDatePicker');
          break;
      }
    }
  }
};
</script>

<style lang="css">
.no-data-state span {
  font-size: 14px;
}
.no-data-state .no-data-icon {
  margin-bottom: 18px;
}
.no-data-state .no-data-icon:before {
  font-size: 80px;
}
</style>
