<template>
  <div
    v-show="!(config.meta.hideOnNoData && data.noData)"
    class="u-display-flex u-flex-direction-column u-position-relative"
    :class="{ card: applyCardClass }"
    :data-cy="'cardWidget--' + (config && config.header && config.header.title)"
  >
    <div
      v-if="config.header.show !== false"
      class="card-header u-width-100 u-spacing-ph-l u-spacing-pt-l u-spacing-pb-l u-display-flex u-flex-align-items-center u-flex-1"
    >
      <slot name="left-header" />
      <slot name="header">
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-flex-1"
        >
          <span
            v-if="config.header.tooltip"
            v-tippy="
              config.header.tooltip_direction
                ? { placement: config.header.tooltip_direction }
                : { placement: 'right' }
            "
            class="u-font-size-3 card__title u-color-grey-lighter u-font-weight-600"
            :title="config.header.tooltip"
            data-cy="card-header"
            >{{ config.header.title }}</span
          >
          <span
            v-if="!config.header.tooltip"
            class="u-font-size-3 card__title u-color-grey-lighter u-font-weight-600"
            data-cy="card-header"
            >{{ config.header.title }}</span
          >
          <div
            v-if="config.header.actions"
            class="u-display-flex u-flex-align-items-center"
          >
            <!-- eslint-disable -->
            <form
              v-if="config.header.search"
              class="u-spacing-ml-m"
              :title="config.header.searchTooltip"
              @submit.prevent="searchSubmit"
            >
              <!-- eslint-enable -->
              <div class="u-display-flex u-position-relative">
                <rb-input
                  v-model="searchValue"
                  :placeholder="config.header.searchPlaceholder || 'Search'"
                  class="search__input u-width-240px"
                  :rounded="false"
                />
                <span
                  class="u-position-absolute u-display-flex"
                  style="top: 0px; left: 8px; bottom: 0; margin: auto"
                  @click="searchSubmit"
                >
                  <rb-icon
                    icon="search"
                    class="search__input-icon rb-icon--medium"
                  />
                </span>
              </div>
            </form>
            <div
              v-if="
                ((config.header.actions.filter || {}).items || []).length > 0
              "
              class="u-spacing-ml-m u-color-grey-lighter u-display-flex"
            >
              <div
                v-for="(filter, index) in config.header.actions.filter.items"
                :key="index"
                class="u-spacing-ml-m"
              >
                <local-select-filter
                  :context="filter"
                  :value="getLocalFilterSelectedValue[filter.key]"
                  :list-options="
                    (getLocalFilterOptions || {})[filter.key] || []
                  "
                  :is-multi="filter.isMulti || false"
                  :selected-value="localFilterSelection"
                  :static-key="
                    filter.staticKeyToDisplay === undefined
                      ? ''
                      : filter.staticKeyToDisplay
                  "
                  :tag-edit="filter.selectTagEdit === false ? false : true"
                  :is-highlighted="getHeightedState(filter)"
                />
              </div>
            </div>
            <div
              v-if="config.header.actions.date"
              class="u-spacing-ml-m"
            >
              <local-date-filter
                :mode="config.header.actions.date.mode || 'Multi'"
                :max-date="
                  ((config.header.actions || {}).date || {}).maxDate || 'pi'
                "
                modal-position="Right"
                :enable-min-date="
                  ((config.header.actions || {}).date || {}).enableMinDate
                "
                :show-compared-to-text="
                  ((config.header.actions || {}).date || {}).showComparedToText
                "
                :default-range-select="
                  ((config.header.actions || {}).date || {})
                    .defaultRangeSelect || 'last90Days'
                "
                :date-mode="
                  ((config.header.actions || {}).date || {}).dateMode ||
                  dateMode
                "
                :additional-ranges="
                  ((config.header.actions || {}).date || {}).additionalRanges ||
                  additionalRanges
                "
                :custom-date="customDate"
                @selected-value="dateSelection"
              />
            </div>
            <div
              v-if="config.header.actions.download"
              class="u-spacing-ml-m"
            >
              <span
                v-if="
                  config.header.actions.download.triggerThroughComponent ===
                  true
                "
                class="u-cursor-pointer"
                :class="{ disabled: data.noData }"
                @click="$emit('downloadTableThroughComponent')"
              >
                <rb-tooltip :label="'Download'">
                  <loader
                    class="fill--parent"
                    :loading="downloadLoading"
                    :color="'#007cf6'"
                  />
                  <rb-icon
                    class="u-color-grey-lighter"
                    :icon="'download'"
                  />
                </rb-tooltip>
              </span>

              <span
                v-else
                class="u-cursor-pointer"
                :class="{ disabled: data.noData }"
                @click="download(data.rows)"
              >
                <rb-tooltip :label="'Download'">
                  <loader
                    class="fill--parent"
                    :loading="downloadLoading"
                    :color="'#007cf6'"
                  />
                  <rb-icon
                    class="u-color-grey-lighter"
                    :icon="'download'"
                  />
                </rb-tooltip>
              </span>
            </div>

            <!-- EMAIL -->
            <div
              v-if="config.header.actions.email"
              class="u-spacing-ml-m"
            >
              <span
                v-if="
                  config.header.actions.email.triggerThroughComponent === true
                "
                class="u-cursor-pointer"
                :class="{ disabled: data.noData }"
                @click="$emit('emailTableThroughComponent')"
              >
                <rb-tooltip label="Email">
                  <loader
                    class="fill--parent"
                    :loading="downloadLoading"
                    :color="'#007cf6'"
                  />
                  <rb-icon
                    class="u-color-grey-lighter"
                    :icon="'email-subscription'"
                  />
                </rb-tooltip>
              </span>

              <span
                v-else
                class="u-cursor-pointer"
                :class="{ disabled: data.noData }"
                @click="download(data.rows)"
              >
                <rb-tooltip label="Email">
                  <loader
                    class="fill--parent"
                    :loading="downloadLoading"
                    :color="'#007cf6'"
                  />
                  <rb-icon
                    class="u-color-grey-lighter"
                    :icon="'download'"
                  />
                </rb-tooltip>
              </span>
            </div>
          </div>
        </div>
      </slot>
      <slot name="right-header" />
    </div>
    <div
      class="card-body u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative"
    >
      <loader
        v-if="(config.meta.noAction || config.meta.action) && showLoader"
        class="fill--parent"
        :loading="data.load"
        :color="'#007cf6'"
      />
      <div
        v-if="data.noData || !data"
        class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
      >
        <slot
          name="noDataTemplate"
          data-cy="nodata-card-widget"
        >
          <p>{{ noDataMessage }}</p>
        </slot>
      </div>
      <div
        v-if="data.error"
        class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
        data-cy="error-card-widget"
      >
        <p>Error Occurred. Please try again later</p>
      </div>
      <slot name="body" />
    </div>
    <div
      v-if="config.footer.show !== false"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between card-footer u-spacing-pv-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
    >
      <slot name="footer">
        <div class="u-display-flex u-flex-justify-content-flex-start">
          <span
            class="u-color-grey-x-light u-font-size-7"
            data-cy="lastUpdated-card-widget"
            >Last Updated :
            {{ getAllDates()[config.footer.date] | formattable_date }}</span
          >
          <span
            v-if="config.footer.infoText"
            class="u-color-grey-x-light u-font-size-7"
          >
            {{ config.footer.infoText }}
          </span>
        </div>
      </slot>
      <slot name="footer-right" />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import { eventBus } from '@/utils/services/eventBus';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import localSelectFilter from '@/components/basic/localSelectFilter.vue';
import Vue from 'vue';
import moment from 'moment-timezone';
/* eslint-disable vue/require-default-prop */

export default {
  name: 'CardWidget',
  components: {
    loader,
    localDateFilter,
    localSelectFilter
  },
  props: {
    searchTooltip: {
      type: String,
      default: ''
    },
    noDataMessage: {
      type: String,
      default: 'No Data'
    },
    dispatchAction: {
      type: Boolean,
      default: true
    },
    downloadClick: Function,
    filterApplied: Function,
    config: {
      type: Object,
      default: function () {
        return {
          meta: {},
          body: {},
          header: {}
        };
      }
    },
    data: {
      type: Object,
      default: function () {
        this.noLoad = true;
        return {};
      }
    },
    applyCardClass: {
      type: Boolean,
      default: true
    },
    showLoader: {
      type: Boolean,
      default: true
    },
    ishighlighted: {
      type: Boolean,
      default: false
    },
    highlightedKey: {
      type: String,
      default: ''
    },
    getCardWidgetInstance: {
      type: Function
    },
    customDate: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    dateMode: {
      type: Object,
      default: function () {
        return {
          mode: 'day'
        };
      }
    },
    additionalRanges: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      noLoad: false,
      searchValue: '',
      downloadLoading: false,
      selectedRange: {},
      selectedLocalFilter: {}
    };
  },
  computed: {
    outsideIn() {
      return this.$store.getters.getOutsideIn;
    },
    getLocalFilterOptions() {
      if (
        this.config.header &&
        this.config.header.actions &&
        this.config.header.actions.filter &&
        this.config.header.actions.filter.optionsGetter
      ) {
        return this.$store.getters[
          this.config.header.actions.filter.optionsGetter
        ];
      } else {
        return {};
      }
    },
    getLocalFilterSelectedValue() {
      if (
        this.config.header &&
        this.config.header.actions &&
        this.config.header.actions.filter &&
        this.config.header.actions.filter.selectionGetter
      ) {
        return this.$store.getters[
          this.config.header.actions.filter.selectionGetter
        ];
      } else {
        return {};
      }
    }
  },
  created() {
    if (this.getCardWidgetInstance) {
      this.getCardWidgetInstance(this);
    }
    if (
      this.dispatchAction &&
      this.$store._actions &&
      this.$store._actions[this.config.meta.action]
    ) {
      if (
        !((this.config.header || {}).actions || {}).date ||
        (((this.config.header || {}).actions || {}).date || {}).mode ===
          'Single' ||
        !(
          this.config.meta.action &&
          ((((this.config.header || {}).actions || {}).date || {}).action ||
            {})[this.config.meta.action]
        )
      ) {
        this.$store.dispatch(this.config.meta.action, this.config);
      }
    }

    if (
      this.config.header.actions &&
      this.config.header.actions.filter &&
      this.config.header.actions.filter.action
    ) {
      if (
        this.$store._actions &&
        this.$store._actions[this.config.header.actions.filter.action]
      ) {
        this.$store.dispatch(
          this.config.header.actions.filter.action,
          this.config
        );
      }
    }
    if (this.config.meta.listen) {
      for (var i in this.config.meta.listen) {
        var that = this;
        eventBus.$on(
          i,
          function (data) {
            if (that.dispatchAction) {
              if (this.transform) {
                that.config = this.transform(that.config, data, that);
              }
              that.$store.dispatch(this.action, that.config);
            }
          }.bind(this.config.meta.listen[i])
        );
      }
    }
  },
  destroyed() {
    if (this.config.meta.listen) {
      for (var i in this.config.meta.listen) {
        eventBus.$off(i);
      }
    }
  },
  methods: {
    getSearch() {
      return this.searchValue;
    },
    setSearch(value) {
      this.searchValue = value;
    },
    searchSubmit() {
      this.$store.dispatch('setTablePage', 1);
      this.config.body.APIConfig.page = 1;
      var dimensionNameValueList = (this.config.meta || {}).localFilters || [];
      if (this.config.header.searchParams) {
        var searchParams = this.config.header.searchParams;
        if (this.searchValue && this.searchValue.length > 0) {
          for (var i = 0; i < searchParams.length; i++) {
            var found = false;
            for (var j = 0; j < dimensionNameValueList.length; j++) {
              if (dimensionNameValueList[j].dimensionName === searchParams[i]) {
                found = true;
                dimensionNameValueList[j] = {
                  dimensionName: searchParams[i],
                  dimensionValue: this.searchValue,
                  operator: 'ILIKE'
                };
              }
            }
            if (!found) {
              dimensionNameValueList.push({
                dimensionName: searchParams[i],
                dimensionValue: this.searchValue,
                operator: 'ILIKE'
              });
            }
          }
        } else {
          for (j = 0; j < dimensionNameValueList.length; j++) {
            for (i = 0; i < searchParams.length; i++) {
              if (dimensionNameValueList[j].dimensionName === searchParams[i]) {
                dimensionNameValueList.splice(j, 1);
              }
            }
          }
        }
      }

      this.config.body.APIConfig.page = 1;
      this.config.meta.localFilters = dimensionNameValueList;
      this.$store.dispatch(this.config.meta.action, this.config);
    },
    getHeightedState(filter) {
      if (filter.key === this.highlightedKey && this.ishighlighted) {
        return true;
      }
      return false;
    },
    getDateInFormat(__date) {
      var _date = moment(__date).format('YYYY-MM-DD');
      return _date;
    },
    dateSelection(date) {
      var data = null;
      if (this.filterApplied) {
        this.filterApplied('date');
      }
      for (var i in this.config.header.actions.date.action) {
        if (this.config.header.actions.date.action[i].transform) {
          data = this.config.header.actions.date.action[i].transform(
            this.config,
            data,
            this
          );
        } else {
          var _date = {
            from: this.getDateInFormat(date.from),
            to: this.getDateInFormat(date.to),
            name: date.name,
            page_wise_min_max_key: this.pageWiseMinMaxKey
          };

          var _pvpDate;

          if (date.compare_from && date.compare_to) {
            _pvpDate = {
              from: this.getDateInFormat(date.compare_from),
              to: this.getDateInFormat(date.compare_to),
              compare_name: date.compare_name ? date.compare_name : null
            };
          }

          data = {
            from: _date.from,
            to: _date.to,
            date: _date,
            pvpDate: _pvpDate
          };
        }
        this.$store.dispatch(i, data);
      }
    },
    localFilterSelection(context, data) {
      if (this.filterApplied) {
        this.filterApplied('select');
      }
      if (data && data.length > 0) {
        var filters = this.config.meta.localFilters;
        var found = false;
        for (var i = 0; i < filters.length; i++) {
          if (
            filters[i].dimensionName === context.dimensionName &&
            data[0].value !== null
          ) {
            filters[i].operator = context.operator;
            filters[i].dimensionValue = data[0].value || data[0].title;
            found = true;
          }
          if (
            filters[i].dimensionName === context.dimensionName &&
            data[0].value === null
          ) {
            filters.splice(i, 1);
          }
        }
        this.config.meta.localFilters = filters;
        if (!found && data[0].value !== null) {
          this.config.meta.localFilters.push({
            dimensionName: context.dimensionName,
            operator: context.operator,
            dimensionValue: data[0].value || data[0].title
          });
        }
        this.$store.dispatch(context.action, this.config);
      }
    },
    getAllDates() {
      var maxDates = this.$store.getters.getMaxDate;
      var returnDates = {};
      var dateRangeValues = {};

      var selectedDateRange = 'Last 7 Days';
      var selectedDateRangeValues = this.$store.getters.getDateRangeValues;

      if (selectedDateRange.length > 0) {
        for (var i in selectedDateRangeValues) {
          dateRangeValues[i] = selectedDateRangeValues[i][selectedDateRange[0]];
        }

        for (i in maxDates) {
          returnDates[i] = maxDates[i].max_feed_date;
        }
      }
      returnDates.dateRange = selectedDateRange[0];
      returnDates.dateRangeValues = dateRangeValues;
      return returnDates;
    },
    performDownload(data, fileName, config) {
      if (data.length > 0) {
        var fileRows = [];
        var dispColumnArray = [];
        var csvKeys = [];
        var tableColumnsMap;
        // Use the Configurations if it's has been through transformer Func.
        if (config && Object.keys(config).length > 0) {
          if (
            config.header &&
            config.header.actions &&
            config.header.actions.download
          ) {
            tableColumnsMap = config.header.actions.download.colMap;
            fileName = config.header.actions.download.fileName;
          }
        } else {
          tableColumnsMap = this.config.header.actions.download.colMap;
        }
        if (tableColumnsMap !== undefined && tableColumnsMap.length > 0) {
          for (var obj of tableColumnsMap) {
            var displayName = obj.displayName;
            if (!this.outsideIn && obj.altDisplayName) {
              displayName = obj.altDisplayName;
            }

            if (obj.checkOutsideIn !== undefined) {
              if (this.outsideIn === obj.checkOutsideIn) {
                dispColumnArray.push(displayName);
                csvKeys.push(obj.dbKey);
              }
            } else {
              dispColumnArray.push(displayName);
              csvKeys.push(obj.dbKey);
            }
          }
          fileRows.push(dispColumnArray);
        } else {
          var csvColumns = Object.keys(data[0]);
          fileRows.push(csvColumns);
          csvKeys = Object.keys(data[0]);
        }
        for (var i = 0; i < data.length; i++) {
          var aArray = [];
          for (var k = 0; k < csvKeys.length; k++) {
            if (Object.keys(data[i]).indexOf(csvKeys[k]) > -1) {
              if (csvKeys[k] === 'ordered_on') {
                aArray[k] =
                  '"' +
                  (
                    '' +
                    Vue.options.filters.formattable_date(data[i][csvKeys[k]])
                  ).replace(/"/g, '""') +
                  '"';
              } else {
                if (
                  data[i][csvKeys[k]] === undefined ||
                  data[i][csvKeys[k]] === null ||
                  data[i][csvKeys[k]] === ''
                ) {
                  aArray[k] = '"' + ('' + 'NA') + '"';
                } else {
                  aArray[k] =
                    '"' + ('' + data[i][csvKeys[k]]).replace(/"/g, '""') + '"';
                }
              }
            } else {
              aArray[k] = '"' + ('' + 'NA') + '"';
            }
          }
          fileRows.push(aArray);
        }
        // includeDate key on widget config will decide to append the downloaded date or not.
        // Format mm/dd/yy
        if (
          config.header.actions.download.includeDate ||
          this.config.header.actions.download.includeDate
        ) {
          fileName =
            fileName +
            '-' +
            new Date().toLocaleDateString('en-US').replace(/\//g, '-');
        }

        var file = fileRows.join('\n');
        var pom = document.createElement('a');
        var csvContent = file;
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // experimenting excel download
        // var blob = new Blob([csvContent], {type: 'application/vnd.ms-excel'});
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', fileName + '.csv');

        // experimenting excel download
        // pom.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
      }
    },
    download(data) {
      if (this.data && this.data.noData !== true) {
        this.downloadLoading = true;
        if (
          this.config.header.actions.download &&
          this.config.header.actions.download.action
        ) {
          if (this.config.header.actions.download.transform) {
            var config = this.config.header.actions.download.transform(
              Vue.util.extend({}, this.config),
              data,
              this
            );
            this.$store
              .dispatch(this.config.header.actions.download.action, config)
              .then(
                function (response) {
                  this.performDownload(
                    response,
                    this.config.header.actions.download.fileName || 'Download',
                    config
                  );
                  this.downloadLoading = false;
                }.bind(this)
              );
          }
        } else if (this.config.header.actions.download && this.downloadClick) {
          this.downloadClick(this.config).then(
            function (response) {
              this.config.header.actions.download.colMap = response.colMap;
              this.performDownload(response.data, response.fileName);
              this.downloadLoading = false;
            }.bind(this)
          );
        } else {
          this.performDownload(data);
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.rb-input {
  padding-left: 30px;
}
</style>
