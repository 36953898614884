export const OpenNewTab = {
  install(Vue) {
    window.addEventListener('keydown', (e) => {
      /**
       * Event key code is different for fixefox and Chrome.
       * More info: https://unixpapa.com/js/key.html
       */
      if (
        (e.key === 'Control' && navigator.appVersion.indexOf('Mac') === -1) ||
        (navigator.appVersion.indexOf('Mac') !== -1 && e.key === 'Meta')
      ) {
        Vue.prototype.$eventKey = true;
      } else if (Vue.prototype.$eventKey === true) {
        Vue.prototype.$eventKey = null;
      }
    });
    window.addEventListener('keyup', (e) => {
      if (
        (e.key === 'Control' && navigator.appVersion.indexOf('Mac') === -1) ||
        (navigator.appVersion.indexOf('Mac') !== -1 && e.key === 'Meta')
      ) {
        Vue.prototype.$eventKey = null;
      }
    });
  }
};
