import HttpService from '@/utils/services/http-service';
import {
  addSkuOnInput,
  APIConfig
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/skuEnterListHelpers.js';

const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

const selectSkuLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return `${params?.data?.product_title}_${params?.data?.asin}`;
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'sku_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'sku_price',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'weeks_of_coverage',
      type: 'STRING',
      uiField: {
        uiLabel: 'Weeks of coverage(Amazon)',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'weeks_of_coverage',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availablity',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];
};
const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const checkForSBMessages = (allConfig, suggestedCampaignIndex, rows) => {
  const { skus, campaignType, adFormatSubType, adFormat } =
    allConfig.suggestedCampaigns[suggestedCampaignIndex];
  const sbCampaignTypes = ['sponsoredBrands', 'createOwnCampaignSB'];
  if (!sbCampaignTypes.includes(campaignType)) {
    return;
  }
  if (!allConfig.suggestedCampaigns[suggestedCampaignIndex]?.messages) {
    allConfig.suggestedCampaigns[suggestedCampaignIndex].messages = {};
  }
  if (
    rows.length < 3 &&
    adFormat === 'productCollection' &&
    adFormatSubType === 'new_landing_page'
  ) {
    allConfig.suggestedCampaigns[suggestedCampaignIndex].messages.skus =
      'Add atleaset 3 SKUs for this ad format';
  }
  if (rows.length !== 1 && adFormat === 'video') {
    allConfig.suggestedCampaigns[suggestedCampaignIndex].messages.skus =
      'Add 1 SKU for this ad format';
  }
  if (rows !== skus) {
    allConfig.suggestedCampaigns[suggestedCampaignIndex].creativeSkus = [];
  }
};

export const adgroupCustomActionPanel = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'asin',
          customActionPanelContainerClass:
            'u-height-100 campaign-creation-add-section-min-height',
          isMultipleAllowed: true,
          default: {
            hideHeader: true,
            hideFooter: true,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple line items are not allowed'
          },
          left: {
            rowHeight: 68,
            tabs: {
              "All SKU's": {
                localFilter: {},
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'asin_search_text'
                },
                showFilterCheckBox: false
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              localFilters: [],
              filters: {
                emit: 'fetchFiltersCampaignCreation',
                hasSearch: false,
                page: 'amazon'
              },
              filterState: {
                getter: 'getExecuteApiTableDataFilters',
                setter: 'fetchTableDataExecuteApiFilters'
              },
              localStoreageFilterKey: null,
              updateRows: (vueRef) => {
                let suggestedCampaignIndex =
                  vueRef?.$route?.query?.key?.split('-')?.[1];
                suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                const suggestedCampaigns = fetchSavedState(
                  context,
                  'campaignCreation/getAllStepsData'
                )?.suggestedCampaigns;
                if (
                  suggestedCampaignIndex >= 0 &&
                  suggestedCampaigns[suggestedCampaignIndex]?.skus
                ) {
                  vueRef.rightTableRows =
                    suggestedCampaigns[suggestedCampaignIndex]?.skus;
                }
              },
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: APIConfig,
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {
            const adgroupId = context.entityId;
            const campaignId = context.parentEntityId;
            const campaignType = context.entityConfig.entityType;
            return new Promise((resolve, reject) => {
              console.log(
                context.entityId,
                context.parentEntityId,
                context.entityConfig
              );
              const payload = rows.map((row) => ({
                actionType: 'addKeywordToCampaigns',
                actionSource: {
                  pageUrl: window.location.href
                },
                widget: 'campaign',
                primaryKey: campaignId,
                actionPayload: {
                  campaignId,
                  campaignType,
                  adgroupId,
                  new_keyword_text: row.recommended_keyword,
                  new_match_type: row.match_type,
                  new_bid: row.newBid
                },
                viewPayload: {
                  campaignId,
                  campaignType,
                  adgroupId,
                  adgroupName: 'Terminate', // update this to actual ad group name
                  new_keyword_text: row.recommended_keyword,
                  new_match_type: row.match_type,
                  new_bid: row.newBid
                }
              }));
              HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                .then((response) => {
                  vueRef.$emit('afterAPIActionToCall');
                  resolve(true);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
        };
      }
    }
  },
  events: {
    rightTableRows(rows) {
      let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
      suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
      let allConfig =
        fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
      checkForSBMessages(allConfig, suggestedCampaignIndex, rows);
      allConfig.suggestedCampaigns[suggestedCampaignIndex].skus = rows;
      allConfig = { ...allConfig };
      updateSavedState(
        context,
        'campaignCreation/updateAllSetpsData',
        allConfig
      );
    },
    afterAPIActionToCall(payload) {
      this.$emit('openSnackbar', 'Action has been submitted');
    },
    closePanelMethod() {}
  }
});
