<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('3p_variants') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('3p_SKU_as_a_variant_on_') }}{{ alert.attributes.parent_asin }} ({{
        $t('parent_ASIN')
      }}).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <p class="u-spacing-pt-xl">
      {{ $t('combined_revenue_of_all_other_variants') }}:
      {{
        formatter(
          alert.attributes.other_variants_last4weeks_revenue,
          'currency'
        )
      }}
      ({{ $t('last_4_weeks') }})
    </p>
    <p class="u-font-size-6 u-spacing-pt-s u-color-grey-lighter">
      {{ $t('other_variants') }}: {{ otherVariants }}
    </p>
    <div class="u-display-flex u-spacing-pt-m u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.Ratings)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.Ratings || 'NA' }} {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.Reviews || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max) per week:
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('3p_variants_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
      {{ $t('3p_variants_rec_text') }}
    </p>
    <div class="detail-view--card">
      <el-table :data="[{}]">
        <el-table-column
          :label="$t('3p_SKU_details')"
          :width="300"
        >
          <div slot-scope="scope">
            <div>
              <p
                :title="alert.attributes.title"
                class="u-text-overflow-ellipsis"
              >
                {{ alert.attributes.title }}
              </p>
              <p>{{ alert.attributes.asin }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('category')">
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.bsr_category }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('avg_BSR') + ' (' + $t('last_1_week') + ')'"
        >
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.bsr_last_1week }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('avg_BSR') + ' (' + $t('last_3_weeks') + ')'"
        >
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.bsr_last_3weeks }}</p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      otherVariants: '',
      variantTimelineDesc: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          chartWidth: 's',
          axes: {
            BSR: 'y2',
            '3P Variant Timeline': 'y2'
          },
          tooltip_format: {
            BSR: {
              roundoff: 0
            },
            'Revenue of Parent ASIN': {
              roundoff: 2,
              pre: 'currency'
            },
            '3P Variant Timeline': function (value, ratio, id, index) {
              return this.variantTimelineDesc[index];
            }.bind(this)
          },
          axis_format: {
            y: function (a) {
              return Vue.options.filters.num_format(a.toFixed(2), 'currency');
            },
            y2: function (a) {
              return Vue.options.filters.num_format(a.toFixed(2), 'currency');
            },
            x: '%b %e, %y'
          },
          point_format: {
            '3P Variant Timeline': function (data) {
              if (this.variantTimelineDesc[data.index] !== 'No') {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    if (this.alert.attributes.Other_variants) {
      var temp1 = JSON.parse(this.alert.attributes.Other_variants).map(
        (item) => {
          return item['Other variant'];
        }
      );
      var temp2 = temp1.splice(0, 4);
      this.otherVariants = temp2.join(', ');
      if (temp2.length > 0) {
        this.otherVariants += ' and ' + temp2.length + ' other variants';
      }
    }
    var xs = {
      'Revenue of Parent ASIN': 'Revenue of Parent ASIN_x',
      BSR: 'BSR_x',
      '3P Variant Timeline': '3P Variant_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var revenueTrend = [];
    if (this.alert.attributes.revenue_trend) {
      revenueTrend = JSON.parse(this.alert.attributes.revenue_trend);
      if (revenueTrend.length) {
        dataArray = ['Revenue of Parent ASIN'];
        dateArray = ['Revenue of Parent ASIN_x'];
        for (entry of revenueTrend) {
          if (entry.parent_revenue_trend === undefined) continue;
          dataArray.push(entry.parent_revenue_trend);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var bsrTrend = [];
    if (this.alert.attributes.bsr_trend) {
      bsrTrend = JSON.parse(this.alert.attributes.bsr_trend);
      if (bsrTrend.length) {
        dataArray = ['BSR'];
        dateArray = ['BSR_x'];
        for (entry of bsrTrend) {
          if (entry.bsr === undefined) continue;
          dataArray.push(entry.bsr);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var variantTimelineTrend = [];
    if (this.alert.attributes.variant_timeline_trend) {
      variantTimelineTrend = JSON.parse(
        this.alert.attributes.variant_timeline_trend
      );
      if (variantTimelineTrend.length) {
        variantTimelineTrend = variantTimelineTrend.sort((a, b) => {
          if (a.Date < b.Date) return -1;
          else if (a.Date > b.Date) return 1;
          return 0;
        });
        dataArray = ['3P Variant Timeline'];
        dateArray = ['3P Variant_x'];
        for (entry of variantTimelineTrend) {
          if (
            entry.variant_timeline_flag === undefined ||
            entry.variant_timeline_flag === 'NA'
          )
            continue;
          dataArray.push(1);
          dateArray.push(entry.Date);
          this.variantTimelineDesc.push(
            entry.variant_timeline_flag ? 'Yes' : 'No'
          );
        }
        chartData.push(dataArray, dateArray);
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
