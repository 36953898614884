import {
  roasCube,
  spendVsBudgetCube
} from '@/components/ams/campaign_creation/setup_configs/amazon/index.js';
import Campaign from '@/components/ams/campaign_creation/steps/setup/campaign/index.vue';

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  fallbackRouteName: 'create_campaigns',
  closeCreationRouteName: 'create_campaigns',
  customCloseIcon: {
    icon: 'right-arrow',
    class: 'is-flipped rb-icon--large'
  },
  dashboardServicePage: 'cm_campaign_creation',
  stepsConfig: [
    {
      name: 'Campaigns',
      hideLeftPanel: true,
      description: '',
      roas: roasCube,
      spendVsBudget: spendVsBudgetCube,
      header: {
        title: '',
        desc: ['']
      },
      breadCrumbTitle: '',
      keyToRead: 'campaigns',
      textMapping: 'campaigns',
      component: Campaign
    }
  ]
};
