<template>
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <!-- Loader -->
    <div
      v-if="isLoading"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="isLoading"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        v-if="config.headerText"
        class="title u-font-size-5 u-spacing-pb-m u-font-weight-600 u-text-case-upper"
      >
        {{ config.headerText ? config.headerText() : 'Bid Management' }}
      </div>

      <!-- Main Section where all radio buttons are shown -->
      <div v-if="radioButtonsConfig">
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-m">
          {{ radioButtonsConfig.heading || 'Heading' }}
        </div>
        <div class="u-line-height-1 u-display-flex">
          <div
            v-for="item in radioButtonsConfig.radioOptionsArray"
            :key="item.value"
          >
            <rb-radio
              v-model="radioButtonsSelection"
              :native-value="item.value"
              class="u-font-size-4 u-width-100 u-spacing-mr-m"
            >
              {{ item.title }}
            </rb-radio>
          </div>
        </div>
      </div>

      <!-- Bid management radio selection with input -->
      <div
        v-if="radioSelectionWithInputConfig"
        class="u-display-flex u-spacing-mt-s"
      >
        <RadioSelectionWithInput
          :title="radioSelectionWithInputConfig.heading || ''"
          :radio-config="radioSelectionWithInputConfig.configArray"
          :default-radio-selected="
            radioSelectionWithInputConfig.configArray[0].value
          "
          :error-message="errorMessage"
          @onUpdate="radioSelectionWithInputValueUpdated"
        />
      </div>

      <!-- Info text -->
      <div
        v-if="infoText"
        class="u-font-size-5 u-color-grey-lighter u-display-flex u-flex-align-items-center u-spacing-mt-l"
      >
        <rb-icon
          class="rb-icon--medium u-spacing-mr-xs"
          :icon="'info-circle-fill'"
        />
        <div class="u-spacing-mt-xxs">
          {{ infoText || '' }}
        </div>
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-pv-m u-spacing-ph-l"
    >
      <rb-button
        :text="config.leftBtnLabel || 'Apply'"
        type="filled"
        :click-fn="onLeftBtnClick"
        :disabled="isLeftBtnDisabled() || !selections.length"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        :text="config.rightBtnLabel || 'Cancel'"
        :click-fn="onRightBtnClick"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import RadioSelectionWithInput from '@/pages/campaign-management/retailers/criteo/line_items/RadioSelectionWithInput/index.vue';
import loader from '@/components/basic/loader';
export default {
  name: 'BidManagementPanel',
  components: {
    RadioSelectionWithInput,
    loader
  },
  props: {
    widgetsConfig: {
      type: Function,
      default: () => {
        return () => ({});
      }
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      isLoading: false,
      config: null,
      radioButtonsSelection: '',
      updatedRadioSelectionWithInputValue: {}
    };
  },
  computed: {
    radioSelectionWithInputConfig() {
      if (this.config?.radioSelectionWithInputConfig) {
        if (typeof this.config?.radioSelectionWithInputConfig === 'function') {
          return this.config?.radioSelectionWithInputConfig();
        }
        return this.config?.radioSelectionWithInputConfig;
      }

      return null;
    },
    infoText() {
      if (this.config?.infoText) {
        if (typeof this.config?.infoText === 'function') {
          return this.config?.infoText();
        } else return this.config?.infoText;
      }
      return null;
    },
    errorMessage() {
      if (this.config?.errorMessage) {
        if (typeof this.config?.errorMessage === 'function') {
          return this.config?.errorMessage();
        } else return this.config?.errorMessage;
      }
      return null;
    },
    radioButtonsConfig() {
      if (this.config?.radioButtonsConfig) {
        if (typeof this.config?.radioButtonsConfig === 'function') {
          return this.config?.radioButtonsConfig(this);
        } else return this.config?.radioButtonsConfig;
      }
      return null;
    }
  },
  watch: {
    selections(newVal) {
      if (!newVal?.length) {
        this.$emit('closePanelMethod');
      }
    }
  },
  async created() {
    this.initializeConfig();
  },
  methods: {
    initializeConfig() {
      this.config = this.widgetsConfig(this);
      const radioButtonsConfig = this.radioButtonsConfig;
      if (radioButtonsConfig) {
        if (
          // reason for checking with null || undefined is because we can pass '' as a value for it, and assign it
          radioButtonsConfig?.defaultSelectedRadioButton !== null ||
          radioButtonsConfig?.defaultSelectedRadioButton !== undefined
        ) {
          this.radioButtonsSelection =
            radioButtonsConfig.defaultSelectedRadioButton;
        } else {
          this.radioButtonsSelection =
            radioButtonsConfig?.radioOptionsArray[0].value || '';
        }
      }
    },
    radioSelectionWithInputValueUpdated(data) {
      this.updatedRadioSelectionWithInputValue = data;
    },
    isLeftBtnDisabled() {
      if (this.config?.isLeftBtnDisabled) {
        return this.config?.isLeftBtnDisabled(this);
      }
      return false;
    },
    calculateInputValue(data, budget) {
      let val = null;
      if (data?.inputType === 'dropdown') {
        if (budget === null && !data?.radioValue?.includes('set_new')) {
          return null;
        }
        const delta = budget * (data?.inputValue / 100);
        if (data?.radioValue?.includes('increase_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget + delta).toFixed(2))
              : parseFloat(data?.inputValue) + budget;
        } else if (data?.radioValue?.includes('decrease_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget - delta).toFixed(2))
              : budget - parseFloat(data?.inputValue);
        }
      } else if (data?.inputType === 'input') {
        val = data?.inputValue;
      }
      if (val < 0) {
        val = 0;
      }
      return val;
    },
    async onLeftBtnClick() {
      try {
        this.isLoading = true;
        await this.config?.onLeftBtnClick?.(
          this.updatedRadioSelectionWithInputValue
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async onRightBtnClick() {
      try {
        this.isLoading = true;
        this.config?.onRightBtnClick?.();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
      this.$emit('closePanelMethod');
    }
  }
};
</script>
