<template>
  <section
    :class="{
      'u-spacing-pb-m':
        spotlightsData.length <= 3 && !competitorSpotlightsError,
      'u-spacing-pb-s': competitorSpotlightsError
    }"
  >
    <div class="u-display-flex u-flex-justify-content-space-between">
      <div class="u-display-flex u-flex-align-items-center">
        <h3 class="u-font-size-4 u-font-weight-bold u-text-align-left">
          Spotlights
        </h3>
      </div>
    </div>
    <section
      v-if="competitorSpotlightsError"
      class="u-width-100 u-spacing-mt-m u-bg-color-grey-lighter-bg u-border-all u-border-width-s u-border-color-grey-xxx-light u-spacing-pv-xl u-border-radius-l u-border-all u-min-height-100 u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
    >
      <img
        src="/images/noInsights.svg"
        alt="No insights"
        class="u-spacing-mb-m"
      />
      <span class="u-font-size-5 u-font-weight-bold u-spacing-mb-s"
        >Couldn’t load the data. Please try refreshing the page</span
      >
      <span class="u-font-size-5"
        >Our team has been notified and is actively working on resolving the
        issue</span
      >
    </section>
    <section
      v-if="spotlightLoading"
      class="u-display-flex u-flex-direction-column u-flex-justify-content-center u-min-height-100"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
    <section v-else>
      <spotlight-carousel :spotlights-data="spotlightsData" />
    </section>
  </section>
</template>
<script>
import SpotlightCarousel from '@/components/pages/insights/amazon/cva/molecules/spotlight-carousel.vue';
import loader from '@/components/basic/loader';

export default {
  components: {
    SpotlightCarousel,
    loader
  },
  props: {
    expandable: {
      type: Boolean,
      default: false
    },
    spotlightsData: {
      type: Array,
      default: () => []
    },
    spotlightLoading: {
      type: Boolean,
      default: true
    },
    competitorSpotlightsError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { expanded: false };
  }
};
</script>
