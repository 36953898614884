import { adGroupCustomActionPanelForAutoSP } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import {
  keywordTargetingState,
  negativeKeywordTargetingState
} from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';
import { cloneDeep } from 'lodash';
import { getAdvertiserRequestPayload } from '@/pages/entity_details/configs/walmart/utils.js';
import { getSearchTermTabsConfig } from '@/pages/entity_details/configs/walmart/sp_manual.js';

const budget = () =>
  import('@/pages/entity_details/configs/walmart/widget_templates/budget.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const settings = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/settings.vue'
  );
const adgroupsTargetingKeyword = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupKeywordTargeting.vue'
  );
const adgroupSettings = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupSettings.vue'
  );
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const bidMultiplier = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/bidMultiplier.vue'
  );
const placement = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/placement.vue'
  );
const platform = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/platform.vue'
  );
const searchTerms = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupSearchTerms.vue'
  );

const retailerToAdGroupCustomActionPanelForAutoSP = {
  walmart: adGroupCustomActionPanelForAutoSP,
  samsclub_api: adGroupCustomActionPanelForAutoSP
};

const retailerToKeywordCustomActionPanelForAutoSP = {
  walmart: keywordTargetingState,
  samsclub_api: keywordTargetingState
};

const retailerToNegativeKeywordCustomActionPanelForAutoSP = {
  samsclub_api: negativeKeywordTargetingState
};

const callChangeStatusForAdgroupsTargetingKeyword = (
  vueRef,
  dropDownSelection,
  selections,
  keywordStateKey,
  keywordTextKey
) => {
  const payloadArr = [];
  for (const selection of selections) {
    if (
      selection?.data?.[keywordStateKey]?.toLowerCase() ===
      dropDownSelection[0].value
    ) {
      vueRef.$snackbar.open({
        message: 'No new state selected for one or more entities.',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    } else {
      const payload = cloneDeep(vueRef.stateChangeRequestPayload);
      const reqValues = {
        ':campaignId': vueRef.parentEntityId,
        ':oldState': selection?.data?.[keywordStateKey]?.toLowerCase(),
        ':state': dropDownSelection[0].value,
        ':pageUrl': window.location.href,
        ':keywordId': selection?.data?.keyword_id,
        ':keywordText': selection?.data?.[keywordTextKey] || 'NA',
        ':matchType': selection?.data?.[keywordTextKey] || 'NA',
        ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
        ':advertiserId': vueRef?.$route?.query?.advertiser_id
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      payloadArr.push(payload);
    }
  }
  if (payloadArr.length) {
    vueRef.updateMetadataAction(payloadArr, {
      singular: 'Keyword Status',
      plural: 'Keyword Statuses',
      stopFetchApi: true
    });
  } else {
    vueRef.closeActionBar();
  }
};

export const callChangeStatusForAdgroupsAds = (
  vueRef,
  dropDownSelection,
  selections,
  retailer
) => {
  const payloadArr = [];
  const statusKey = `${retailer}_cm_skus_sku_status`;
  const skuKey = `${retailer}_cm_skus_sku`;
  const skuNameKey = `${retailer}_cm_skus_sku_name`;
  const adGroupContentIdKey = `${retailer}_cm_skus_aditem_id`;
  for (const selection of selections) {
    if (
      selection?.data?.[statusKey]?.toLowerCase() === dropDownSelection[0].value
    ) {
      vueRef.$snackbar.open({
        message: 'No new state selected for one or more entities.',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    } else {
      let payload = cloneDeep(vueRef.stateChangeRequestPayload);
      if (retailer === 'walmart') {
        payload.actionPayload.ad_group_content_id = ':adGroupContentId';
        payload.viewPayload.ad_group_content_id = ':adGroupContentId';
      }
      const reqValues = {
        ':campaignId': vueRef.parentEntityId,
        ':adgroupId': vueRef.entityId,
        ':oldState': selection?.data?.[statusKey]?.toLowerCase(),
        ':state': dropDownSelection[0].value,
        ':pageUrl': window.location.href,
        ':itemId': selection?.data?.[skuKey],
        ':itemName': selection?.data?.[skuNameKey],
        ':adGroupContentId': selection?.data?.[adGroupContentIdKey],
        ':advertiserId': vueRef?.$route?.query?.advertiser_id,
        ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA'
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      payloadArr.push(payload);
    }
  }
  if (payloadArr.length) {
    vueRef.updateMetadataAction(payloadArr, {
      singular: 'Sku status',
      plural: 'Sku Statuses',
      stopFetchApi: true
    });
  } else {
    vueRef.closeActionBar();
  }
};

export const showNegativeKeywordTargetingForSamsClubApi = (retailer) => {
  return retailer === 'samsclub_api'
    ? {
        negativeKeywordTargeting: getAdgroupsTargetingKeywordConfig(
          retailer,
          true
        )
      }
    : {};
};

const showAdjustBidActionForKeywords = (
  isNegative,
  keywordMetaDataChangeActionType
) => {
  return isNegative
    ? {}
    : {
        bidChange: {
          requestPayload: {
            actionPayload: {
              campaign_id: ':campaignId',
              campaignType: 'SP',
              new_bid: ':newBid',
              keyword_id: ':keywordId',
              state: ':state',
              advertiser_id: ':advertiserId',
              old_bid: ':oldBid'
            },
            viewPayload: {
              campaign_id: ':campaignId',
              view_adgroup_name: ':adgroupName',
              view_old_bid: ':oldBid',
              new_bid: ':newBid',
              keyword_text: ':keywordText',
              adgroup_name: ':adgroupName',
              match_type: ':matchType',
              keywordId: ':keywordId'
            },
            actionType: keywordMetaDataChangeActionType,
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId'
          }
        }
      };
};

export const callChangeStatusForAdgroups = (
  vueRef,
  dropDownSelection,
  selections,
  retailer
) => {
  const payloadArr = [];
  const statusKey = `${retailer}_cm_adgroup_status`;
  const adGroupNameKey = `${retailer}_cm_adgroup_name`;
  for (const selection of selections) {
    if (
      selection?.data?.[statusKey]?.toLowerCase() === dropDownSelection[0].value
    ) {
      vueRef.$snackbar.open({
        message: 'No new state selected for one or more entities.',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    } else {
      const payload = cloneDeep(vueRef.stateChangeRequestPayload);
      const reqValues = {
        ':campaignId': vueRef.entityId,
        ':oldState': selection?.data?.[statusKey]?.toLowerCase(),
        ':state': dropDownSelection[0].value,
        ':pageUrl': window.location.href,
        ':adgroupName': selection?.data?.[adGroupNameKey] || 'NA',
        ':advertiserId': vueRef?.$route?.query?.advertiser_id,
        ':adgroupId': selection?.data?.ad_group_id
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      payloadArr.push(payload);
    }
  }
  if (payloadArr.length) {
    vueRef.updateMetadataAction(payloadArr, {
      singular: 'Ad Group Status',
      plural: 'Ad Group Statuses',
      stopFetchApi: true
    });
  } else {
    vueRef.closeActionBar();
  }
};

const callCheckForSuggestedBid = (
  vueRef,
  keywordBidSuggestedKey,
  retailer,
  keywordBidKey
) => {
  vueRef.bidSuggestion = { show: false };
  vueRef.bidValue = null;
  if (
    vueRef.selections.length === 1 &&
    vueRef.selections[0]?.data?.[keywordBidSuggestedKey]
  ) {
    vueRef.bidSuggestion = {
      show: true,
      title: `${
        retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
      } Suggested Bid`,
      value: vueRef.selections[0]?.data?.[keywordBidSuggestedKey].toString(),
      currentBid: vueRef.selections[0]?.data?.[keywordBidKey].toString(),
      multipleSuggestions: false
    };
  } else if (vueRef.selections.length > 1) {
    let showBidSuggestion = true;
    vueRef.selections?.map((selection, index) => {
      if (!selection?.data?.[keywordBidSuggestedKey]) {
        showBidSuggestion = false;
      }
    });
    vueRef.bidSuggestion = {
      show: showBidSuggestion,
      title: `${
        retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
      } Suggested Bid`,
      value: 'XX',
      currentBid: 'YY',
      multipleSuggestions: showBidSuggestion,
      currentBidNote: 'YY - Respective current bid in the table below',
      suggestedBidNote: `XX - Respective ${
        retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
      } suggested bid in the table below`
    };
  } else {
    vueRef.adjustBids = false;
  }
};

const callOnSaveBid = (
  vueRef,
  keywordBidKey,
  keywordTextKey,
  keywordStateKey,
  keywordMatchTypeKey
) => {
  const payloadArr = [];
  for (const selection of vueRef.selections) {
    const oldBid = selection?.data?.[keywordBidKey] || 0;
    const newBid = vueRef.transformBid(oldBid, selection, keywordBidKey);
    const payload = cloneDeep(vueRef.bidChangeRequestPayload);
    const reqValues = {
      ':campaignId': vueRef.parentEntityId,
      ':adgroupId': vueRef.entityId,
      ':oldBid': oldBid,
      ':newBid': newBid,
      ':pageUrl': window.location.href,
      ':keywordId': selection?.data?.keyword_id,
      ':keywordText': selection?.data?.[keywordTextKey] || 'NA',
      ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
      ':state': selection?.data?.[keywordStateKey],
      ':advertiserId': vueRef?.$route?.query?.advertiser_id,
      ':matchType': selection?.data?.[keywordMatchTypeKey] || 'NA'
    };
    for (const item in reqValues) {
      deepReplaceObjectValues(payload, item, reqValues[item]);
    }
    payloadArr.push(payload);
  }
  vueRef.updateMetadataAction(payloadArr, {
    singular: 'Keyword Bid',
    plural: 'Keyword Bids',
    stopFetchApi: true
  });
};

const line3Details = (retailer) => {
  return retailer === 'walmart'
    ? [
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Remaining Budget',
            titleIcon: 'info-circle-fill',
            titleIconTooltip:
              'Remaining Budget is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
            values: ['dailyRemainingBudget'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Ad Spend today',
            titleIcon: 'info-circle-fill',
            titleIconTooltip:
              'Ad Spend today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
            values: ['todayAdSpend'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Impressions today',
            titleIcon: 'info-circle-fill',
            titleIconTooltip:
              'Impressions today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
            values: ['todayImpressions'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Clicks today',
            titleIcon: 'info-circle-fill',
            titleIconTooltip:
              'Clicks today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
            values: ['todayClicks'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        }
      ]
    : [];
};

const addPlacementBidMetadataActionType = (
  vueRef,
  key,
  subKey,
  retailer,
  tab
) => {
  return vueRef.displayState[key][subKey].value === ''
    ? `${retailer}Add${tab}BidMetadata`
    : `${retailer}${tab}BidMetadataChange`;
};

export const platformAndPlacementData = (data, vueRef, retailer) => {
  const placement = [];
  const platform = [];
  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((subKey) => {
      if (key === 'placement') {
        placement.push({
          actionPayload: {
            campaign_id: vueRef?.entityId,
            campaignType: 'SP',
            advertiser_id: vueRef?.$route?.query?.advertiser_id,
            placement_type: vueRef.typeMap[subKey],
            multiplier: data[key][subKey]
          },
          viewPayload: {
            name: vueRef?.entityInfo?.data?.name,
            campaign_id: vueRef?.entityId,
            campaignType: 'SP',
            placement_type: vueRef.typeMap[subKey],
            old_multiplier: vueRef.displayState[key][subKey].value,
            new_multiplier: data[key][subKey]
          },
          actionType: addPlacementBidMetadataActionType(
            vueRef,
            key,
            subKey,
            retailer,
            'Placement'
          ),
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: vueRef?.entityId
        });
      } else {
        platform.push({
          actionPayload: {
            campaign_id: vueRef?.entityId,
            campaignType: 'SP',
            advertiser_id: vueRef?.$route?.query?.advertiser_id,
            platform_type: vueRef.typeMap[subKey],
            multiplier: data[key][subKey]
          },
          viewPayload: {
            name: vueRef?.entityInfo?.data?.name,
            campaign_id: vueRef?.entityId,
            campaignType: 'SP',
            platform_type: vueRef.typeMap[subKey],
            old_multiplier: vueRef.displayState[key][subKey].value,
            new_multiplier: data[key][subKey]
          },
          actionType: addPlacementBidMetadataActionType(
            vueRef,
            key,
            subKey,
            retailer,
            'Platform'
          ),
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: vueRef?.entityId
        });
      }
    });
  });
  return { placement, platform };
};

const retailerSpecificPlacement = (retailer) => {
  return retailer === 'walmart'
    ? {
        buyBox: {
          label: 'Buy Box',
          value: '',
          validation: {
            min: {
              value: 0,
              exclusive: false
            }
          }
        },
        searchIngrid: {
          label: 'Search Ingrid',
          value: '',
          validation: {
            min: {
              value: 0,
              exclusive: false
            }
          }
        },
        homePage: {
          label: 'Home Page',
          value: '',
          validation: {
            min: {
              value: 0,
              exclusive: false
            }
          }
        },
        stockUp: {
          label: 'Stock Up',
          value: '',
          validation: {
            min: {
              value: 0,
              exclusive: false
            }
          }
        }
      }
    : {
        searchIngrid: {
          label: 'Search Ingrid',
          value: '',
          validation: {
            min: {
              value: 0,
              exclusive: false
            }
          }
        }
      };
};

export const getAdGroupSettingsConfig = (retailer) => ({
  isAdgroup: true,
  statusChange: true,
  requestPayload: {
    actionPayload: {
      campaign_id: ':campaignId',
      campaignType: 'SP',
      ad_group_id: ':entityId',
      status: ':state',
      advertiser_id: ':advertiserId'
    },
    viewPayload: {
      campaign_id: ':campaignId',
      view_adgroup_name: ':name',
      view_old_state: ':oldState',
      status: ':state'
    },
    actionType: `${retailer}AdgroupMetadataChange`,
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  },
  component: adgroupSettings,
  getAdvertiserRequestPayload: (advertiser_id) =>
    getAdvertiserRequestPayload(retailer, advertiser_id),
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchAdvertiser: 'entityDetails/fetchAdvertiserData',
    getAdvertiser: 'entityDetails/getAdvertiser'
  },
  hideReviewStatus: true
});

export const actionTypeForKeywordMetaDataChangeAction = (
  isNegative,
  retailer
) => {
  return isNegative
    ? `${retailer}NegativeKeywordStatusChange`
    : `${retailer}KeywordMetadataChange`;
};

export const getAdgroupsTargetingKeywordConfig = (
  retailer,
  isNegative = false
) => {
  const keywordMetaDataChangeActionType =
    actionTypeForKeywordMetaDataChangeAction(isNegative, retailer);
  const keywordStateKey = `${retailer}_cm_keyword_state`;
  const keywordTextKey = `${retailer}_cm_keyword_keyword_text`;
  const keywordMatchTypeKey = `${retailer}_cm_keyword_match_type`;
  const keywordBidSuggestedKey = `${retailer}_cm_keyword_bid_suggested`;
  const keywordBidKey = `${retailer}_cm_keyword_bid`;
  return {
    component: adgroupsTargetingKeyword,
    apiConfigs: {
      updateAction: 'entityDetails/updateCampaignDetails',
      fetchAction: 'entityDetails/fetchAdgroupDetails',
      getter: 'entityDetails/getAdgroupDetails',
      campaignGetter: 'entityDetails/getCampaignDetails',
      fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
      getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
      fetchTableDataComponentKeyGetter:
        'entityDetails/fetchIncrementTableDataKey'
    },
    ...showAdjustBidActionForKeywords(
      isNegative,
      keywordMetaDataChangeActionType
    ),
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaign_id: ':campaignId',
          keyword_id: ':keywordId',
          campaignType: 'SP',
          state: ':state',
          advertiser_id: ':advertiserId'
        },
        viewPayload: {
          campaign_id: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          adgroup_name: ':adgroupName',
          match_type: ':matchType',
          keywordId: ':keywordId'
        },
        actionType: keywordMetaDataChangeActionType,
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    },
    changeStatus: (vueRef, dropDownSelection, selections) => {
      callChangeStatusForAdgroupsTargetingKeyword(
        vueRef,
        dropDownSelection,
        selections,
        keywordStateKey,
        keywordTextKey
      );
    },
    checkForSuggestedBid: (vueRef) => {
      callCheckForSuggestedBid(
        vueRef,
        keywordBidSuggestedKey,
        retailer,
        keywordBidKey
      );
    },
    onSaveBid: (vueRef) => {
      callOnSaveBid(
        vueRef,
        keywordBidKey,
        keywordTextKey,
        keywordStateKey,
        keywordMatchTypeKey
      );
    },
    addCustomKeywordPanelConfig: isNegative
      ? retailerToNegativeKeywordCustomActionPanelForAutoSP[retailer]('edit')
      : retailerToKeywordCustomActionPanelForAutoSP[retailer]('edit'),
    searchText: keywordTextKey,
    bottomPadding: '6.5rem'
  };
};

const adgroupADsConfig = (retailer) => {
  const skuBidKey = `${retailer}_cm_skus_bid`;
  const skuKey = `${retailer}_cm_skus_sku`;
  const skuNameKey = `${retailer}_cm_skus_sku_name`;
  const adGroupContentIdKey = `${retailer}_cm_skus_aditem_id`;
  const searchTextKey = `${retailer}_cm_skus_search_text`;
  return {
    apiConfigs: {
      updateAction: 'entityDetails/updateCampaignDetails',
      fetchAction: 'entityDetails/fetchAdgroupDetails',
      getter: 'entityDetails/getAdgroupDetails',
      getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
    },
    allowedActions: [
      {
        icon: 'task-status',
        title: 'EDIT STATUS',
        id: 'editStatus',
        dropDown: true,
        dropDownOptions: [
          { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
          { title: 'Disable', value: 'disabled', color: 'u-color-orange-base' }
        ]
      },
      {
        icon: 'dollar',
        title: 'Adjust Bid',
        id: 'adjustBids'
      }
    ],
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaign_id: ':campaignId',
          item_id: ':itemId',
          ad_group_id: ':adgroupId',
          status: ':state',
          advertiser_id: ':advertiserId'
        },
        viewPayload: {
          campaign_id: ':campaignId',
          previousStatus: ':oldState',
          adgroupName: ':adgroupName',
          newStatus: ':state',
          item_id: ':itemId',
          itemName: ':itemName'
        },
        actionType: `${retailer}ProductadMetadataChange`,
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    },
    actionPanelAddCustomProductsConfig:
      retailerToAdGroupCustomActionPanelForAutoSP[retailer]('sp_auto', 'edit'),
    addCustomProducts: {
      requestPayload: getAddCustomProductsRequestPayloadTemplate(retailer)
    },
    changeStatus: (vueRef, dropDownSelection, selections) => {
      callChangeStatusForAdgroupsAds(
        vueRef,
        dropDownSelection,
        selections,
        retailer
      );
    },
    addProducts: (vueRef, selections, actionPanelRef) => {
      selections = selections.filter(
        (row) =>
          actionPanelRef.fetchedRightTableRows.rows.findIndex(
            (fetchedRow) => fetchedRow.sku === row.sku
          ) === -1
      );
      if (!selections.length)
        return actionPanelRef.openSnackbar('No products were added/modified');
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(vueRef.addCustomProductsRequestPayload);
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':sku_title': selection.product_title,
          ':search_text': `${selection.sku}|${selection.product_title}`,
          ':pageUrl': window.location.href,
          ':image_url': selection.image_url,
          ':product_url': selection.product_url,
          ':item_id': selection.sku || 'NA',
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':advertiserId': vueRef?.$route?.query?.advertiser_id,
          ':bid': selection.newBid?.toString()
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        vueRef.updateMetadataAction(payloadArr, {
          singular: 'Campaign Product',
          plural: 'Campaign Products',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        vueRef.closeActionBar();
      }
    },
    checkForSuggestedBid: (vueRef) => {
      const skuSuggestedBidKey = `${retailer}_cm_skus_suggested_bid`;
      const skuBidKey = `${retailer}_cm_skus_bid`;
      vueRef.bidSuggestion = { show: false };
      vueRef.bidValue = null;
      if (
        vueRef.selections.length === 1 &&
        vueRef.selections[0]?.data?.[skuSuggestedBidKey]
      ) {
        vueRef.bidSuggestion = {
          show: true,
          title: `${
            retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
          } Suggested Bid`,
          value: vueRef.selections[0]?.data?.[skuSuggestedBidKey].toString(),
          currentBid: vueRef.selections[0]?.data?.[skuBidKey].toString(),
          multipleSuggestions: false
        };
      } else if (vueRef.selections.length > 1) {
        let showBidSuggestion = true;
        vueRef.selections?.map((selection, index) => {
          if (!selection?.data?.[skuSuggestedBidKey]) {
            showBidSuggestion = false;
          }
        });
        vueRef.bidSuggestion = {
          show: showBidSuggestion,
          title: `${
            retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
          } Suggested Bid`,
          value: 'XX',
          currentBid: 'YY',
          multipleSuggestions: showBidSuggestion,
          currentBidNote: 'YY - Respective current bid in the table below',
          suggestedBidNote: `XX - Respective ${
            retailer === 'walmart' ? 'Walmart' : "Sam's Club Api"
          } suggested bid in the table below`
        };
      } else {
        vueRef.adjustBids = false;
      }
    },
    onBidUpdates: (vueRef, updatedValues) => {
      const skuBidKey = `${retailer}_cm_skus_bid`;
      vueRef.bidValue = updatedValues;
      vueRef.selections.map((sel) => {
        const oldBid = sel?.data?.[skuBidKey] || 0;
        let newBid = vueRef.transformBid(oldBid, sel, skuBidKey);
        if (
          (updatedValues?.inputValue && !newBid) ||
          parseFloat(newBid) < 0.2 ||
          parseFloat(newBid) > 20
        ) {
          vueRef.errorMessage =
            'Bid should be between $0.2 and $20, both inclusive.';
        } else {
          vueRef.errorMessage = '';
        }
      });
    },
    onSaveBid: (vueRef) => {
      const skuSuggestedBidKey = `${retailer}_cm_skus_suggested_bid`;

      const payloadArr = [];
      for (const selection of vueRef.selections) {
        const oldBid = selection?.data?.[skuBidKey] || 0;
        const newBid = vueRef.transformBid(
          oldBid,
          selection,
          skuSuggestedBidKey
        );
        const payload = {
          actionPayload: {
            campaign_id: ':campaignId',
            ...(retailer === 'walmart' && {
              ad_group_content_id: ':adGroupContentId'
            }),
            item_id: ':itemId',
            ad_group_id: ':adgroupId',
            advertiser_id: ':advertiserId',
            new_bid: ':newBid'
          },
          viewPayload: {
            campaign_id: ':campaignId',
            adgroupName: ':adgroupName',
            ...(retailer === 'walmart' && {
              ad_group_content_id: ':adGroupContentId'
            }),
            item_id: ':itemId',
            itemName: ':itemName',
            new_bid: ':newBid',
            old_bid: ':oldBid'
          },
          actionType: `${retailer}ProductadMetadataChange`,
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        };
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':advertiserId': vueRef?.$route?.query?.advertiser_id,
          ...(retailer === 'walmart' && {
            ':adGroupContentId': selection?.data?.[adGroupContentIdKey]
          }),
          ':itemId': selection?.data?.[skuKey],
          ':itemName': selection?.data?.[skuNameKey]
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'SKU Bid',
        plural: 'SKU Bids',
        stopFetchApi: true
      });
    },
    searchText: searchTextKey
  };
};

export const getMetadata = () => {
  return [
    {
      action: 'entityDetails/fetchCampaingDetails',
      getters: 'entityDetails/getCampaignDetails',
      bannerGetter: 'entityDetails/getBannerStatus'
    }
  ];
};

export const headerConfigForCampaigns = (retailer) => {
  return {
    maxFeedDate: 'campaigns_campaign',
    activityLog: {
      show: true
    },
    bookmark: {
      value: true,
      title: 'name',
      fromMetadata: true
    },
    redirectTo: {
      value: true,
      path: `/us/${retailer}/retail/advertising/campaign_management/campaigns`
    },
    // line 1 and 2 will wrap around date picker if its present
    metadata_api_state: {
      getter: 'entityDetails/getCampaignDetails'
    },
    line1: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Campaign',
            values: ['name'],
            hoverText: ['name'],
            titleClass: 'u-font-weight-700 u-color-grey-lighter u-font-size-2',
            valueClasses: ['u-font-weight-700 u-font-size-2'],
            altValues: ['NA']
          },
          class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
          itemType: 'attribute'
        }
      ],
      class: ''
    },
    line2: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Duration',
            values: ['startDate', 'endDate'],
            titleClass: null,
            delimiter: '-',
            valueClasses: null,
            altValues: ['No start date', 'No end date']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Total Budget',
            values: ['totalBudgetView'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Daily Budget',
            values: ['dailyBudgetView'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            title: 'Campaign ID',
            values: ['campaignId'],
            titleClass: null,
            delimiter: '',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            title: 'Type',
            values: ['campaignType', 'targetingType'],
            titleClass: null,
            delimiter: ' - ',
            valueClasses: ['', 'u-text-case-title'],
            altValues: ['NA', 'NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        }
      ],
      class: 'u-spacing-pb-m u-font-size-5'
    },
    line3: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Status',
            values: ['state'],
            titleClass: null,
            delimiter: '',
            valueClasses: ['u-color-blue-base u-font-weight-600'],
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        ...line3Details(retailer)
      ],
      class: 'u-spacing-pb-l u-font-size-5'
    }
  };
};

export const getAddCustomProductsRequestPayloadTemplate = (retailer) => {
  return {
    actionPayload: {
      campaign_id: ':campaignId',
      ad_group_id: ':adgroupId',
      item_id: ':item_id',
      status: 'enabled',
      advertiser_id: ':advertiserId',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      search_text: ':search_text',
      new_bid: ':bid'
    },
    viewPayload: {
      campaign_id: ':campaignId',
      item_id: ':item_id',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      adgroupName: ':adgroupName',
      bid: ':bid'
    },
    actionType: `${retailer}AddSkuToCampaigns`,
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  };
};

const getSearchTermsTabConfigForSamsclub = (retailer) => {
  const objectToReturn = getSearchTermTabsConfig(retailer);
  // Removing add as keyword action
  objectToReturn.allowed_actions?.shift();
  if (retailer === 'samsclub_api') {
    return objectToReturn;
  }
  return {};
};

export const getBanner = (vueRef) => {
  return {
    text: 'We have observed the placement metrics are not in sync with Sams Club Console. We are currently working with Sams club team to fix this.',
    showCta: false,
    showCloseIcon: true,
    iconCustomClass: 'u-color-orange-base',
    bannerCloseAction() {
      vueRef.$store.dispatch('entityDetails/toggleBannerStatus');
    }
  };
};

export default function (retailer = 'walmart') {
  return {
    campaign: {
      page: 'campaignDetailsSPAuto',
      metadata: getMetadata(),
      header: {
        filters: {
          config: {
            emit: 'spAutoCampaignDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'spAutoCampaignDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'spAutoCampaingDetails'
        },
        ...headerConfigForCampaigns(retailer)
      },
      chart: {
        page: 'campaignDetailsSPAuto',
        maxFeedDate: 'campaigns_campaign',
        globalViewId: 0,
        pageId: 0,
        filter: 'spAutoCampaignDetailsFiltersApplied',
        widget: 'campaignDetailsChartingWbSPAuto',
        enableDownload: false,
        enableRollup: true
      },
      // banner: getBanner,
      // show tabs data if present
      tabs: {
        'bid multiplier': {
          component: bidMultiplier,
          defaultState: {
            placement: retailerSpecificPlacement(retailer),
            platform: {
              desktop: {
                label: 'Desktop',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              },
              mobile: {
                label: 'Mobile',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              },
              app: {
                label: 'App',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              }
            }
          },
          typeMap: {
            'Buy-Box': 'buyBox',
            buyBox: 'Buy-Box',
            'Search Ingrid': 'searchIngrid',
            searchIngrid: 'Search Ingrid',
            Desktop: 'desktop',
            desktop: 'Desktop',
            Mobile: 'mobile',
            mobile: 'Mobile',
            App: 'app',
            app: 'App',
            'Home Page': 'homePage',
            homePage: 'Home Page',
            'Stock Up': 'stockUp',
            stockUp: 'Stock Up'
          },
          apply: async (vueRef, data) => {
            return Promise(async (res) => {
              const { placement, platform } = platformAndPlacementData(
                data,
                vueRef,
                retailer
              );
              if (placement.length) {
                let entityName = null;
                entityName = {
                  singular: 'Placement Bid',
                  plural: 'Placement Bids',
                  stopFetchApi: true
                };

                vueRef.$store.dispatch(vueRef.updateMetadata, {
                  updateRequest: placement,
                  entityId: vueRef.entityId,
                  entityType: 'SP_AUTO',
                  callAdgroupFetch: false,
                  snackbar: vueRef.$snackbar,
                  entityName: entityName,
                  routeData: vueRef.$route
                });
              }
              if (platform.length) {
                let entityName = null;
                entityName = {
                  singular: 'Platform Bid',
                  plural: 'Platform Bids',
                  stopFetchApi: true
                };

                vueRef.$store.dispatch(vueRef.updateMetadata, {
                  updateRequest: platform,
                  entityId: vueRef.entityId,
                  entityType: 'SP_AUTO',
                  callAdgroupFetch: false,
                  snackbar: vueRef.$snackbar,
                  entityName: entityName,
                  routeData: vueRef.$route
                });
              }
              vueRef.showComponent = false;
              vueRef.isLoading = true;
              setTimeout(async () => {
                if (placement.length || platform.length) {
                  await vueRef?.tabConfig?.init?.(vueRef);
                }
                res(true);
              }, 5000);
            });
          },
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails'
          },
          init: async (vueRef) => {
            return Promise(async (resolve, reject) => {
              try {
                vueRef.isLoading = true;
                const promiseArr = await Promise.all([
                  vueRef.fetchData('PLATFORM_BIDMULTIPLIER'),
                  vueRef.fetchData('PLACEMENT_BIDMULTIPLIER')
                ]);
                const [platformData, placementData] = promiseArr;
                placementData?.data?.response?.map?.((item) => {
                  vueRef.displayState.placement[
                    vueRef.typeMap[item.placementType]
                  ].value = item.multiplier?.toString();
                });
                platformData?.data?.response?.map?.((item) => {
                  vueRef.displayState.platform[
                    vueRef.typeMap[item.platformType]
                  ].value = item.multiplier?.toString();
                });
                vueRef.isLoading = false;
                resolve(true);
              } catch (error) {
                vueRef.isLoading = false;
                reject(error);
              }
            });
          }
        },
        budget: {
          component: budget,
          config: {
            title1: 'Total Budget',
            title2: 'Daily Budget',
            title3: 'Total & Daily Budget',
            validation: {
              ':oldDailyBudget': {
                min: 50,
                text: 'Daily budget must be $50.00 or above'
              },
              ':oldTotalBudget': {
                min: 100,
                text: 'Total budget must be $100.00 or above'
              },
              genericErrorText: {
                text: 'Please enter either total budget, daily budget, or both'
              }
            }
          },
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          reqMetadataMap: {
            ':oldDailyBudget': 'dailyBudget',
            ':oldTotalBudget': 'totalBudget',
            ':campaignId': 'entityId'
          },
          requestPayload: {
            actionPayload: {
              campaign_id: ':campaignId',
              campaignType: 'SP',
              new_daily_budget: ':newDailyBudget',
              new_total_budget: ':newTotalBudget',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              name: ':name',
              campaignId: ':campaignId',
              campaignType: 'SP',
              previousDailyBudget: ':oldDailyBudget',
              newDailyBudget: ':newDailyBudget',
              previousTotalBudget: ':oldTotalBudget',
              newTotalBudget: ':newTotalBudget'
            },
            actionType: `${retailer}CampaignMetadataChange`,
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId'
          }
        },
        adGroups: {
          component: adGroups,
          stateChange: {
            requestPayload: {
              actionPayload: {
                campaign_id: ':campaignId',
                campaignType: 'SP',
                ad_group_id: ':adgroupId',
                status: ':state',
                advertiser_id: ':advertiserId'
              },
              viewPayload: {
                campaign_id: ':campaignId',
                view_adgroup_name: ':adgroupName',
                view_old_state: ':oldState',
                status: ':state'
              },
              actionType: `${retailer}AdgroupMetadataChange`,
              actionSource: {
                pageUrl: ':pageUrl'
              },
              widget: 'campaign',
              primaryKey: ':campaignId'
            }
          },
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          disableCreateAdgroup: false,
          disableRowSelection: false,
          allowedActions: [
            {
              icon: 'task-status',
              title: 'EDIT STATUS',
              id: 'editStatus',
              dropDown: true,
              dropDownOptions: [
                {
                  title: 'Enable',
                  value: 'enabled',
                  color: 'u-color-green-base'
                },
                {
                  title: 'Disable',
                  value: 'disabled',
                  color: 'u-color-orange-base'
                }
              ]
            }
          ],
          changeStatus: (vueRef, dropDownSelection, selections) => {
            callChangeStatusForAdgroups(
              vueRef,
              dropDownSelection,
              selections,
              retailer
            );
          },
          createAdgroup: (vueRef) => {
            vueRef.$store.dispatch(
              `${retailer}CampaignCreation/updateAllStepsData`,
              {}
            );
            vueRef.$router.push({
              name: 'create_ad_group',
              query: {
                advertiserId: vueRef.$route.query.advertiser_id,
                campaignId: vueRef.entityId,
                campaignType: 'sp_auto',
                campaignName: vueRef.$route.query.parent_entity_name
              }
            });
          }
        },
        placement: {
          component: placement,
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          }
        },
        platform: {
          component: platform,
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          disableRowSelection: true
        },
        settings: {
          requestPayload: {
            actionPayload: {
              campaign_id: ':entityId',
              campaignType: 'SP',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              campaignId: ':entityId'
            },
            actionType: `${retailer}CampaignMetadataChange`,
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          },
          getAdvertiserRequestPayload: (advertiser_id) =>
            getAdvertiserRequestPayload(retailer, advertiser_id),
          component: settings,
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey',
            portfolio_campaignList: false,
            showAdvertiser: true,
            fetchAdvertiser: 'entityDetails/fetchAdvertiserData',
            getAdvertiser: 'entityDetails/getAdvertiser',
            statusValues: [
              {
                title: 'Proposal',
                value: 'Proposal'
              },
              {
                title: 'Scheduled',
                value: 'scheduled'
              },
              {
                title: 'Rescheduled',
                value: 'rescheduled'
              },
              {
                title: 'Live',
                value: 'live'
              },
              {
                title: 'Paused',
                value: 'paused'
              },
              {
                title: 'Ended',
                value: 'ended'
              },
              {
                title: 'Completed',
                value: 'ended'
              },
              {
                title: 'Extend',
                value: 'extended'
              },
              {
                title: 'Enabled',
                value: 'live'
              }
            ]
          },
          hideReviewStatus: true
        }
      }
    },
    auto_adgroup: {
      page: 'adgroupDetailsSPAuto',
      metadata: [
        {
          action: 'entityDetails/fetchAdgroupDetails',
          getters: 'entityDetails/getAdgroupDetails',
          // campaignFetchAction: 'entityDetails/fetchCampaingDetails',
          campaignGetter: 'entityDetails/getCampaignDetails'
        }
      ],
      header: {
        maxFeedDate: 'campaigns_campaign',
        line1: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group',
                values: ['name'],
                hoverText: ['name'],
                titleClass:
                  'u-color-grey-lighter u-font-weight-700 u-font-size-2',
                valueClasses: ['u-font-weight-700 u-font-size-2'],
                altValues: ['']
              },
              class: 'u-spacing-pt-xxs',
              itemType: 'attribute'
            }
          ],
          class: ''
        },
        line2: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Status',
                values: ['state'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-color-[color]-base u-text-case-title'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group ID',
                values: ['adGroupId'],
                titleClass: null,
                delimiter: '',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            }
          ],
          class: 'u-spacing-pb-m u-font-size-5'
        },
        filters: {
          config: {
            emit: 'spAutoAdgroupDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'spAutoAdgroupDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'spAutoAdgroupDetails'
        },
        bookmark: {
          value: true,
          title: 'name',
          fromMetadata: true
        },
        activityLog: {
          show: true
        },
        redirectTo: {
          value: true,
          config: (that) => ({
            name: `${retailer}EntityDetails`,
            params: {
              entityId: ':entityId',
              entityType: 'sp_auto'
            },
            query: that?.$route?.query
          })
        },
        // line 1 and 2 will wrap around date picker if its present
        metadata_api_state: {
          getter: 'entityDetails/getAdgroupDetails'
        }
      },
      chart: {
        page: 'adgroupDetailsSPAuto',
        globalViewId: 0,
        pageId: 0,
        filter: 'spAutoAdgroupDetailsFiltersApplied',
        maxFeedDate: 'campaigns_campaign',
        widget: 'adGroupDetailsChartingWbSPAuto',
        enableDownload: false,
        enableRollup: true,
        config: {
          chartingWb: {
            ':widget': 'adGroupDetailsChartingWbSPAuto',
            ':timeseriesRollupBy': 'DAY',
            keepExistingDimensionNameValueList: true,
            replaceEntityInDimensionNameValueList: {
              ':ad_group_id': ':entityId'
            },
            timeseries: 'report_date'
          }
        }
      },
      tabs: {
        keywordTargeting: getAdgroupsTargetingKeywordConfig(retailer),
        ...showNegativeKeywordTargetingForSamsClubApi(retailer),
        adItems: {
          component: adgroupProductADs,
          ...adgroupADsConfig(retailer)
        },
        searchTerms: {
          component: searchTerms,
          disableRowSelection: true,
          ...getSearchTermsTabConfigForSamsclub(retailer)
        },
        settings: getAdGroupSettingsConfig(retailer)
      }
    }
  };
}
