<template>
  <div
    class="u-width-100"
    :class="{ 'u-margin-bottom-40': defaultMarginBottom }"
  >
    <h3 class="u-font-size-3 u-font-weight-600 u-text-align-left u-padding-0">
      {{ (step.header && step.header.title) || '' }}
    </h3>
    <div
      v-if="step.header && step.header.desc"
      class="step-desc u-font-size-5 u-spacing-mt-s u-color-grey-lighter u-line-height-1-3"
    >
      <p
        v-for="(desc, $index) in step.header.desc"
        :key="$index"
      >
        {{ desc }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BudgetPlannerHeader',
  props: {
    defaultMarginBottom: {
      type: Boolean,
      default: true
    },
    step: {
      type: Object,
      default: () => {}
    }
  },
  methods: {}
};
</script>
