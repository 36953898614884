import HttpLayer from '@/utils/services/http-layer';
export default class DataService {
  data = {
    load: true,
    error: false,
    rows: {},
    noData: false
  };

  getRowsData(entityData) {
    return entityData.map((item) => ({
      ...item.DIMENSION,
      ...item.RESULT,
      ...item.PVP
    }));
  }

  getTotalRows(entityData, config) {
    return entityData[0].RESULT?.[config.api?.metadata?.paginationKey];
  }

  transformData(entityData, config) {
    const tableRow = this.getRowsData(entityData);
    const totalRows = this.getTotalRows(entityData, config);
    return {
      tableRow,
      totalRows
    };
  }

  async getData(requestCopy, config) {
    try {
      this.data = {
        load: true,
        error: false
      };
      const { data } = await HttpLayer.post({
        APIData: requestCopy
      });
      if (data) {
        if (data.length === 0) {
          this.data = {
            rows: [],
            error: false,
            load: false,
            noData: true
          };
        } else {
          this.data = {
            rows: this.transformData(data, config),
            error: false,
            load: false,
            noData: false
          };
        }
      } else {
        this.data = {
          load: false,
          error: true
        };
      }
    } catch (e) {
      this.data = {
        load: false,
        error: true
      };
      throw e;
    }
  }
}
