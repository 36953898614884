<template>
  <div
    v-if="!isDisable"
    class="u-width-100 u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      class="u-width-100 u-height-100 u-display-flex"
      :class="{ 'drop-down-disable': isDisable }"
    >
      <rb-select-v2
        class="u-width-100 u-height-100"
        :options="options"
        :group-by-key="getGroupByKey"
        :search-enabled="params.searchAction ? true : false"
        :client-search="enableClientSearch"
        :search-listener="searchListener"
        :send-details="true"
        :on-close="entityChange"
        :search-enable-threshold="20"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer u-height-100 u-width-100 u-spacing-pr-xxl u-spacing-pv-m"
        >
          <!-- <rb-icon
            class="u-spacing-mr-s rb-icon--medium"
            :class="iconClass"
            :icon="icon"
          />
          <div class="u-color-blue-base u-font-size-5">
            {{ getDisplayText }}
          </div> -->
          <div
            v-tippy="tippy"
            :title="getDisplayText"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-p-m u-width-100 u-height-100 add-column-holder available-column-holder dashed-border u-border-width-s u-border-radius-s u-border-all u-height-100 u-width-100 u-bg-color-grey-xxx-light"
          >
            <rb-icon
              class="u-flex-0 u-color-blue-base"
              :class="iconClass"
              :icon="icon"
            />
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div v-if="!params.normalDropdown">
            <rb-sku
              v-if="params.isItSKUType"
              class="u-text-overflow-ellipsis asin-table-holder"
              :show-image="true"
              :title="option.title"
              :image-url="option.image_url"
              :asin="option.asin"
              :product-page-url="option.product_url"
              :enable-s-d-p="false"
            />
            <div
              v-if="!params.isItSKUType"
              class="u-display-flex u-flex-align-items-center"
              :title="option.entityId"
            >
              {{ option.entityId }}
            </div>
          </div>
          <div v-if="params.normalDropdown">
            {{ option.id }}
          </div>
        </template>
      </rb-select-v2>

      <!-- <rb-select
        class="u-spacing-mr-s u-width-100"
        :send-details="true"
        :on-close="entityChange"
        :options="options"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer u-width-100"
        >
          <rb-icon
            class="u-spacing-mr-s "
            :class="iconClass"
            :icon="icon"
          />
          <div class="u-color-blue-base">
            {{ getDisplayText }}
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div
            class="u-display-flex u-flex-align-items-center"
            :title="option.entityId"
          >
            {{ option.entityId }}
          </div>
        </template>
      </rb-select> -->
    </div>
  </div>
  <div
    v-else-if="isDisable"
    class="u-display-flex u-flex-align-items-center u-height-100 u-width-100 u-spacing-pr-xxl u-spacing-pv-m"
  >
    <div
      v-tippy="tippy"
      :title="getDisplayText"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-p-m u-width-100 u-height-100 dashed-border u-border-width-s u-border-radius-s add-column-holder u-bg-color-grey-xxx-light"
    >
      <rb-icon
        class="u-flex-0"
        :class="iconClass"
        :icon="icon"
      />
    </div>
  </div>
</template>

<script>
//        :group-by-key="'client_flag'"
import rbSelectV2 from '../../../../components/pages/businessInsights/rbSelectV2';
export default {
  name: 'AddColumnHeader',
  components: {
    rbSelectV2
  },
  data() {
    return {
      displayIcon: '',
      alternateIcon: '',
      loading: true,
      tippy: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      options: []
    };
  },
  computed: {
    enableClientSearch() {
      return this.params.clientSearch || false;
    },
    icon() {
      let icon = '';
      if (this.params.displayIcon) {
        icon = this.params.displayIcon;
      }
      return icon;
    },
    passCurrentThis() {
      return this;
    },
    labelValue() {
      return this.params.data[this.params.colDef.field];
    },
    iconClass() {
      const iconColorClass = this.isDisable
        ? 'u-color-grey-lighter'
        : 'u-color-blue-base';
      if (this.params.iconSize) {
        return 'rb-icon--' + this.params.iconSize + ' ' + iconColorClass;
      } else {
        return 'rb-icon--small ' + iconColorClass;
      }
    },
    getGroupByKey() {
      return this.params.groupByKey;
    },
    getDisplayText() {
      if (this.params.addEnabled && this.params.options.length > 0) {
        return this.params.displayText;
      } else {
        return this.params.allCategoryDisplayText;
      }
    },
    isDisable() {
      if (this.params.addEnabled) {
        return !(this.params.addEnabled && this.params.options.length > 0);
      }
      return !this.$store.getters[this.params.disableGetter][
        this.params.storeKey
      ];
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  created() {
    if (this.params.contextReturnEvent !== undefined) {
      this.params.contextReturnEvent(this);
    }
    if (this.params.toolTipPosition !== undefined) {
      this.updateTippyPosition();
    }
    this.currentDataFilter();
    if (this.params.options) {
      this.options = this.params.options;
    } else {
      this.options =
        this.$store.getters[this.params.dropdownGetter][this.params.storeKey]
          ?.data || [];
    }
  },
  methods: {
    updateTippyPosition() {
      this.tippy.placement = this.params.toolTipPosition;
    },
    iconClickEvent(event) {
      if (this.params.iconClickEvent) {
        this.params.iconClickEvent(this, event);
      }
    },
    currentDataFilter() {
      if (this.params.type === 'iconText') {
        const formatType = this.params.colDef.cellRendererParams.formatType;
        this.dataFormatType = formatType;
      }
    },
    async searchListener(searchTerm, context) {
      this.searchText = searchTerm;
      this.options = await this.$store.dispatch(this.params.searchAction, {
        searchText: searchTerm,
        storeKey: this.params.storeKey,
        mutationDisable: true
      });
      console.log(this.options);
    },
    // entityChange (context, selectedOption) {
    //   if (selectedOption.length > 0) {
    //     // this.selectedOption.order = this.params.keyOrder;
    //     // this.$store.dispatch(this.params.deleteColumnAction, this.selectedOption);
    //     // this.selectedOption = selectedOption[0];
    //     // this.selectedOption.order = this.params.keyOrder;
    //     this.$store.dispatch(this.params.addNewColumnAction, { data: selectedOption[0], storeKey: this.params.storeKey });
    //   }
    // }
    entityChange(context, selectedOption) {
      if (this.params.addColumnAction !== undefined) {
        return this.params.addColumnAction(context, selectedOption);
      }
      if (selectedOption.length > 0) {
        // this.selectedOption.order = this.params.keyOrder;
        // this.$store.dispatch(this.params.deleteColumnAction, this.selectedOption);
        // this.selectedOption = selectedOption[0];
        // this.selectedOption.order = this.params.keyOrder;
        this.$store.dispatch(this.params.addNewColumnAction, {
          data: selectedOption[0],
          storeKey: this.params.storeKey
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.drop-down-disable {
  pointer-events: none;
  opacity: 0.5;
}
.asin-table-holder {
  width: 275px !important;
}
.dashed-border {
  border-style: dashed;
}
.add-column-holder {
  background-color: #f5f7fa;
  border-color: #caccce;
}
.add-column-holder.available-column-holder:hover {
  border-color: #007cf6;
}
</style>
