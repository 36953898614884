<template>
  <section
    class="generate-media-plan u-position-fixed widget--full-screen"
    @click.stop="removeFocus"
  >
    <Loader
      v-if="localLoading"
      :loading="localLoading"
      class="u-height-100 u-width-100"
      :color="'#3fadf7'"
      :size="'2rem'"
      :thickness="'.2rem'"
    />
    <div
      v-else
      class="u-position-fixed model u-width-100 generate-media-plan u-display-flex u-flex-direction-column"
    >
      <Header
        v-model="title"
        :show-right-btn="isOnOutputScreen && !!output.excelPath"
        :back-button-icon="'cross'"
        :placeholder="'Untitled Plan'"
        :error-message="headerErrorMessage"
        :loading="output.loading"
        :is-edit-visible="isEditVisible"
        :disabled-b-o-sheet="disabledBOSheet"
        :disabled-download-btn="disableExcelDownload"
        :saving-name="savingName"
        @handleChangeIsEditVisible="handleChangeIsEditVisible"
        @handleGoBack="handleGoBack"
        @setHeaderRef="setHeaderRef"
        @handleEditTitle="handleEditMediaPlanName"
        @handleClickDownloadBtn="downloadExcel"
        @handleClickBOButton="downloadBOSheet"
        @removeFocus="removeFocus"
      />
      <div
        v-if="currentStepIndex > 0 && !isOnOutputScreen"
        class="plan-description u-font-size-4 u-color-grey-light"
      >
        <PlanDescription
          :get-plan-description="getPlanDescription"
          :current-step-index="currentStepIndex"
          :enable-steps="true"
          :disable-rest-of-steps="centerBtnDisabled || rightBtnDisabled"
          @goToStep="goToStep"
        />
      </div>
      <div
        :class="{
          'u-spacing-mv-xl step-wrapper':
            currentStepIndex !== numberOfSteps - 1,
          'u-spacing-mb-xl': currentStepIndex === numberOfSteps - 1
        }"
        class="u-flex-1 u-overflow-auto"
      >
        <div
          v-if="currentStep.title && currentStep.description"
          class="u-spacing-pv-xl"
        >
          <p
            class="u-color-grey-base u-font-size-2 u-font-weight-600 u-spacing-pb-s"
          >
            {{ currentStep.title }}
          </p>
          <p class="u-color-grey-lighter u-font-size-5 u-line-height-24">
            {{ currentStep.description }}
          </p>
        </div>
        <div
          v-if="currentStepIndex === 0"
          class="default-plan u-font-size-3"
        >
          <PlanDescription
            :get-plan-description="getPlanDescription"
            :current-step-index="currentStepIndex"
            :enable-steps="true"
            :disable-rest-of-steps="centerBtnDisabled || rightBtnDisabled"
            @goToStep="goToStep"
          />
        </div>
        <component
          :is="currentStep.component"
          v-if="currentStep.component"
          :key="currentStep.title"
          v-bind="currentStep.componentProps"
          @disableNextButton="onDisableNextButton"
          @goToStep="goToStep"
        />
        <Footer
          class="u-spacing-pt-xl"
          :class="`u-spacing-pt-xl ${
            currentStepIndex === numberOfSteps - 1 ? 'u-spacing-ph-xl' : ''
          }`"
          :btn-center-text="isOnOutputScreen ? '' : centerBtnConfig.text"
          :btn-left-text="leftButtonConfig.text"
          :btn-right-text="btnRightConfig.text"
          :center-btn-type="centerBtnConfig.type"
          :disable-center-btn="centerBtnDisabled"
          :disable-right-btn="rightBtnDisabled"
          :disable-left-btn="leftBtnDisabled"
          :left-btn-type="leftButtonConfig.type"
          :right-btn-type="btnRightConfig.type"
          :right-button-loading="rightBtnLoading"
          :center-button-loading="centerBtnLoading"
          :left-btn-tooltip="leftButtonConfig.tooltip"
          @onClickBtnRight="btnRightConfig.clickHandler"
          @onClickBtnLeft="leftButtonConfig.clickHandler"
          @onClickCenterBtn="centerBtnConfig.clickHandler"
        />
        <div
          v-if="currentStep.nextStepTitle && currentStep.nextStepDescription"
          class="footer-text u-color-grey-xx-light u-font-weight-600"
        >
          <p class="u-font-size-4 u-spacing-pb-s">
            {{ currentStep.nextStepTitle }}
          </p>
          <p class="u-font-size-7">
            {{ currentStep.nextStepDescription }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from './components/header.vue';
import Footer from './components/footer.vue';
import PlanDescription from '@/components/ams/media-planner/components/plan-description.vue';
import {
  getStepConfig,
  organizeOptions,
  createPlanDescription,
  getBUFiltersPayload,
  filteredHistoricalDataAPIConfig,
  getPreviousPeriodDates,
  defaultCompetitivePlans,
  getIfElse,
  checkUndefined,
  FETCH_FILTERED_HISTORICAL_DATA_ACTION,
  MEDIA_PLANNER_CREATION_DATA_SETTER,
  MEDIA_PLANNER_CREATION_DATA_GETTER,
  OVERALL_HISTORICAL_DATA_GETTER,
  FILTERED_HISTORICAL_DATA_GETTER,
  SAVE_OR_GENERATE_MEDIA_PLAN_ACTION,
  UPDATE_MEDIA_PLAN_ACTION,
  SAVING_OR_UPDATING_PLAN_STATUS_GETTER,
  SAVING_OR_UPDATING_PLAN_STATUS_SETTER,
  GENERATED_MEDIA_PLANS_GETTER,
  DRAFTED_MEDIA_PLANS_GETTER,
  OUTPUT_GETTER,
  FETCH_MEDIA_PLAN_ACTION,
  MEDIA_PLAN_STATUS,
  EXPECTED_GROWTH_GETTER
} from '@/components/ams/media-planner/config.js';
import { downloadS3Uri } from '@/utils/helpers/downloader.js';
import Loader from '@/components/basic/loader.vue';
import moment from 'moment';
import HttpService from '@/utils/services/http-service';
import { set, get, cloneDeep } from 'lodash';
export default {
  components: {
    Header,
    Footer,
    PlanDescription,
    Loader
  },
  data() {
    return {
      headerRef: {},
      isEditVisible: true,
      title: '',
      currentStep: {},
      currentStepIndex: 0,
      headerErrorMessage: '',
      disableNextButton: {},
      localLoading: true,
      nameChanged: false,
      fetchedHistoricalData: false
    };
  },
  computed: {
    storeData() {
      return this.$store.getters[MEDIA_PLANNER_CREATION_DATA_GETTER];
    },
    overallHistoricalDataLoading() {
      const { data, loading } =
        this.$store.getters[OVERALL_HISTORICAL_DATA_GETTER];
      if (
        Object.keys(data || {})?.length &&
        !this.isOnOutputScreen &&
        !loading
      ) {
        Object.keys(defaultCompetitivePlans).forEach((planId) => {
          const updatedPlans = { ...this.storeData.plans };
          updatedPlans[planId].overall.historicalData = data;
          this.updateKeyInStore('plans', updatedPlans);
        });
      }
      return loading;
    },
    filteredHistoricalDataLoading() {
      const { data, loading } =
        this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER];
      const { plans, filteredBusinessUnit } = this.storeData;
      const dimensions = getIfElse(
        filteredBusinessUnit?.dimensionName,
        checkUndefined(data[filteredBusinessUnit?.dimensionName]),
        []
      );

      const updatedPlans = cloneDeep(plans);
      if (dimensions?.length && this.currentStepIndex === 3 && !loading) {
        Object.keys(defaultCompetitivePlans).forEach((planId) => {
          set(updatedPlans[planId], 'businessUnitLevel', []);
          const overallPercentage =
            plans[defaultCompetitivePlans[planId].id].overall.opsGoal;

          dimensions.forEach((dimension, index) => {
            set(updatedPlans[planId], `businessUnitLevel[${index}]`, {
              name: dimension[filteredBusinessUnit.dimensionName],
              opsGoal: overallPercentage,
              historicalData: dimension,
              additionalInputs: get(
                plans[planId],
                `businessUnitLevel[${index}].additionalInputs`,
                {}
              )
            });
          });
        });
        this.updateKeyInStore('plans', updatedPlans);
      }
      return loading;
    },
    savingOrUpdatingPlanStatus() {
      return this.$store.getters[SAVING_OR_UPDATING_PLAN_STATUS_GETTER];
    },
    expectedGrowthLoading() {
      return this.$store.getters[EXPECTED_GROWTH_GETTER]?.loading;
    },
    stepConfig() {
      return getStepConfig(
        this.storeData,
        this.updateKeyInStore,
        this.onGoingPlanIds > 0
      );
    },
    isEditingPlan() {
      return !!this.storeData?.mediaPlanId;
    },
    isFirstStep() {
      const isFirstStep = this.currentStepIndex === 0;
      if (isFirstStep && !this.fetchedHistoricalData) {
        this.fetchHistoricalData();
        this.fetchedHistoricalData = true;
      }
      return isFirstStep;
    },
    isSecondStep() {
      return this.currentStepIndex === 1;
    },
    isLastStep() {
      return this.currentStepIndex === this.numberOfSteps - 2;
    },
    isOnOutputScreen() {
      return this.currentStepIndex === this.numberOfSteps - 1;
    },
    savingName() {
      return this.savingOrUpdatingPlanStatus?.savingName;
    },
    onGoingPlanIds() {
      const statuses = this.output.statuses || {};
      const planIds = Object.keys(statuses).filter((planId) => {
        const isCreatedOfFailed =
          statuses[planId] === MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id ||
          statuses[planId] === 'FAILED' ||
          statuses[planId] === null;

        return !isCreatedOfFailed;
      });
      return planIds.length;
    },
    leftButtonConfig() {
      if (this.isFirstStep && !this.isEditingPlan) {
        return {
          text: 'Edit Plan',
          clickHandler: this.nextStep,
          type: 'filled'
        };
      }

      if (this.isSecondStep && this.isEditingPlan) {
        return {
          text: 'Cancel',
          clickHandler: this.handleGoBack,
          type: 'hollow'
        };
      }

      return {
        tooltip:
          this.isOnOutputScreen && this.onGoingPlanIds > 0
            ? 'Currently, a plan is being generated. Please wait for that to complete to edit this plan.'
            : '',
        text: 'Back',
        clickHandler: this.prevStep,
        type: 'hollow'
      };
    },
    centerBtnConfig() {
      const { totalModulusTriggers } = this.storeData;
      const isDrafted = [undefined, null].includes(totalModulusTriggers);
      const clickHandler =
        this.isEditingPlan && !isDrafted && !this.isLastStep
          ? this.saveAndExit
          : this.generateMediaPlan;
      const lastStepText =
        isDrafted && this.isLastStep && this.onGoingPlanIds > 0
          ? ''
          : 'Generate Plan';
      const text = !isDrafted ? 'Save & Exit' : lastStepText;
      return {
        text: this.isFirstStep || this.isLastStep ? text : 'Next',
        clickHandler:
          this.isFirstStep || this.isLastStep ? clickHandler : this.nextStep,
        type: this.isLastStep || this.isEditingPlan ? 'filled' : 'hollow'
      };
    },
    btnRightConfig() {
      if (this.isEditingPlan) {
        const { totalModulusTriggers } = this.storeData;
        const isDrafted = [undefined, null].includes(totalModulusTriggers);
        const text = this.isLastStep && !isDrafted ? '' : 'Save & Exit';
        return {
          text: this.isOnOutputScreen ? 'Exit' : text,
          clickHandler: this.isOnOutputScreen
            ? this.handleGoBack
            : this.saveAndExit,
          type: 'filled'
        };
      }

      return {
        text: this.isOnOutputScreen ? 'Exit' : 'Save Draft',
        clickHandler: this.isOnOutputScreen
          ? this.handleGoBack
          : this.saveAndExit,
        type: this.isLastStep || this.isOnOutputScreen ? 'filled' : 'hollow'
      };
    },
    rightBtnLoading() {
      if (this.isFirstStep) {
        return (
          this.overallHistoricalDataLoading ||
          this.savingOrUpdatingPlanStatus?.generatingPlan
        );
      }

      if (this.isSecondStep) {
        return (
          this.overallHistoricalDataLoading ||
          this.savingOrUpdatingPlanStatus?.generatingPlan ||
          this.filteredHistoricalDataLoading
        );
      }

      return (
        !this.isOnOutputScreen &&
        this.rightBtnDisabled &&
        (this.savingOrUpdatingPlanStatus?.generatingPlan ||
          this.savingOrUpdatingPlanStatus.loading)
      );
    },
    leftBtnDisabled() {
      return (
        (this.isFirstStep && this.overallHistoricalDataLoading) ||
        (this.isOnOutputScreen && this.onGoingPlanIds > 0)
      );
    },
    rightBtnDisabled() {
      const { organizeType, businessUnit, filteredBusinessUnit } =
        this.storeData;
      const hasDimension = getIfElse(
        organizeType === organizeOptions.OVERALL.value,
        businessUnit.dimensionName,
        filteredBusinessUnit.dimensionName
      );
      return (
        this.filteredHistoricalDataLoading ||
        !this.storeData?.name ||
        this.savingOrUpdatingPlanStatus.loading ||
        this.savingOrUpdatingPlanStatus.generatingPlan ||
        this.expectedGrowthLoading ||
        this.centerBtnDisabled ||
        (this.isEditingPlan && !hasDimension)
      );
    },
    centerBtnLoading() {
      if (this.isFirstStep) {
        return (
          this.overallHistoricalDataLoading ||
          this.savingOrUpdatingPlanStatus?.generatingPlan
        );
      }

      if (this.isSecondStep) {
        return (
          this.overallHistoricalDataLoading ||
          this.filteredHistoricalDataLoading ||
          this.savingOrUpdatingPlanStatus?.generatingPlan
        );
      }

      return (
        this.currentStepIndex === 4 &&
        this.savingOrUpdatingPlanStatus?.generatingPlan
      );
    },
    centerBtnDisabled() {
      const {
        minRoas,
        roasChecked,
        priority,
        organizeType,
        businessUnit,
        filteredBusinessUnit,
        name,
        showPlansAtBULevel
      } = this.storeData;
      const dataLoading = getIfElse(
        organizeType === organizeOptions.OVERALL.value && !showPlansAtBULevel,
        this.overallHistoricalDataLoading,
        this.filteredHistoricalDataLoading
      );
      const hasDimension = getIfElse(
        organizeType === organizeOptions.OVERALL.value,
        businessUnit.dimensionName,
        filteredBusinessUnit.dimensionName
      );
      if (this.mediaPlannerNameAlreadyExists) {
        return true;
      }
      if (this.isFirstStep) {
        return (
          this.savingOrUpdatingPlanStatus.generatingPlan ||
          this.overallHistoricalDataLoading
        );
      }
      if (this.currentStepIndex === 1) {
        return dataLoading;
      }
      if (this.currentStepIndex === 2) {
        return this.checkPriorityChips(priority, roasChecked, minRoas);
      }
      if (this.currentStepIndex === 3) {
        return (
          this.disableNextButton[this.currentStepIndex] ||
          dataLoading ||
          !hasDimension ||
          this.expectedGrowthLoading
        );
      }

      if (this.currentStepIndex === 4) {
        this.headerErrorMessage = !name
          ? 'Please enter the media plan name'
          : '';
        return (
          this.disableNextButton[this.currentStepIndex] ||
          this.filteredHistoricalDataLoading ||
          !name ||
          this.savingOrUpdatingPlanStatus.generatingPlan ||
          this.savingOrUpdatingPlanStatus.loading ||
          this.expectedGrowthLoading
        );
      }
      return false;
    },
    numberOfSteps() {
      return Object.keys(this.stepConfig).length;
    },
    output() {
      const { loading, excelPath, statuses } =
        this.$store.getters[OUTPUT_GETTER] || {};
      return {
        loading,
        excelPath,
        statuses
      };
    },
    disabledBOSheet() {
      const { excelPath } = this.output;
      const path = excelPath?.budgetPlan;
      return !path;
    },
    disableExcelDownload() {
      const { excelPath } = this.output;
      const path = excelPath?.rawData;
      return !path;
    }
  },
  watch: {
    title(newValue, prevValue) {
      const generatedMediaPlans =
        this.$store.getters[GENERATED_MEDIA_PLANS_GETTER]?.list;
      const draftedMediaPlans =
        this.$store.getters[DRAFTED_MEDIA_PLANS_GETTER]?.list;
      const foundPlanIndex = [
        ...generatedMediaPlans,
        ...draftedMediaPlans
      ].findIndex(
        ({ NAME, MEDIA_PLAN_ID }) =>
          NAME === newValue && MEDIA_PLAN_ID !== this.storeData?.mediaPlanId
      );
      this.headerErrorMessage =
        foundPlanIndex > -1 ? 'Enter a unique media plan name' : '';
      if (prevValue && newValue !== prevValue) {
        this.nameChanged = true;
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch(SAVING_OR_UPDATING_PLAN_STATUS_SETTER, {
      ...this.savingOrUpdatingPlanStatus,
      mediaPlanId: ''
    });
  },
  created() {
    const step = parseInt(this.$route.query?.step, 10) || 0;
    const mediaPlanId = this.$route.params?.id;
    if (!this.storeData.name && step && mediaPlanId) {
      this.$store
        .dispatch(FETCH_MEDIA_PLAN_ACTION, {
          planId: mediaPlanId,
          snackbar: this.snackbar
        })
        .then(() => {
          this.title = this.storeData.name;
          this.currentStepIndex = step;
          this.currentStep = this.stepConfig[this.currentStepIndex];
          this.localLoading = false;
        });
    } else {
      if (!this.storeData.name) {
        const defaultMediaPlanName = `Media Plan (${moment().format(
          'DD MMM YY HH:mm:ss'
        )})`;
        this.updateKeyInStore('name', defaultMediaPlanName);
        this.title = defaultMediaPlanName;
      } else {
        this.title = this.storeData.name;
      }
      this.currentStepIndex = step;
      this.currentStep = this.stepConfig[this.currentStepIndex];
      this.localLoading = false;
    }
  },
  methods: {
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    updateStoreData(updatedData) {
      this.$store.dispatch(MEDIA_PLANNER_CREATION_DATA_SETTER, updatedData);
    },
    updateKeyInStore(key, value) {
      this.updateStoreData({
        ...this.storeData,
        [key]: value
      });
    },
    checkPriorityChips(priority, roasChecked, minRoas) {
      const priorityChips = Object.keys(priority).reduce(
        (sum, item) => sum + priority[item],
        0
      );
      return (roasChecked && minRoas <= 0) || priorityChips < 5;
    },
    async fetchBUFilters(dimension, isIC) {
      const payload = getBUFiltersPayload(dimension, isIC);
      const res = await HttpService.post('FILTER_DATA_V2', payload);
      return res;
    },
    createPayloadAndFetch(months) {
      const APIConfig = { ...filteredHistoricalDataAPIConfig };
      APIConfig.where.date.from = months.start;
      APIConfig.where.date.to = months.end;
      this.$store.dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
        APIConfig,
        filtersUpdated: true,
        overall: true,
        isIC: false,
        snackbar: this.snackbar
      });
    },
    fetchHistoricalData() {
      const businessUnit = this.storeData.businessUnit.dimensionName;
      const months = getPreviousPeriodDates(this.storeData.months);
      this.fetchBUFilters(businessUnit, false).then((res) => {
        if (res?.data?.success) {
          const dimensions = (res?.data?.response?.data || []).map(
            (d) => d[businessUnit]
          );
          const filters = { [businessUnit]: dimensions };
          this.updateKeyInStore('filters', filters);
          this.createPayloadAndFetch(months);
        }
      });
    },
    getPlanDescription() {
      return createPlanDescription(this.storeData);
    },
    saveName() {
      const { totalModulusTriggers } = this.storeData;
      const isDrafted =
        [undefined, null].includes(totalModulusTriggers) &&
        !this.isOnOutputScreen;
      this.$store.dispatch(UPDATE_MEDIA_PLAN_ACTION, {
        isDrafted,
        snackbar: this.snackbar,
        updateOnly: true
      });
    },
    removeFocus() {
      this.headerRef.$refs?.['campaign-creation-input']?.blur();
      this.isEditVisible = true;
      if (this.isEditingPlan && this.nameChanged) {
        this.nameChanged = false;
        this.saveName();
      }
    },
    setHeaderRef(ref) {
      this.headerRef = ref;
    },
    handleGoBack() {
      this.$router.push({ name: 'Media Planner List' });
    },
    handleChangeIsEditVisible(isVisible) {
      this.isEditVisible = isVisible;
    },
    nextStep() {
      this.currentStepIndex++;
      this.currentStep = this.stepConfig[this.currentStepIndex];
      if (this.isEditingPlan) {
        this.$router.replace({
          path: this.$route.path,
          query: { step: this.currentStepIndex }
        });
      }
    },
    generateMediaPlan() {
      this.$store
        .dispatch(
          this.isEditingPlan
            ? UPDATE_MEDIA_PLAN_ACTION
            : SAVE_OR_GENERATE_MEDIA_PLAN_ACTION,
          {
            isDrafted: false,
            snackbar: this.snackbar
          }
        )
        .then(() => {
          const { mediaPlanId } = this.savingOrUpdatingPlanStatus;
          this.$router.replace({
            name: 'Edit Media Plan',
            params: { id: mediaPlanId },
            query: {
              step: 5,
              ...(this.isEditingPlan ? { regenerate: true } : {})
            }
          });
        });
    },
    prevStep() {
      this.currentStepIndex--;
      this.currentStep = this.stepConfig[this.currentStepIndex];
      if (this.isEditingPlan) {
        this.$router.replace({
          path: this.$route.path,
          query: { step: this.currentStepIndex }
        });
      }
    },
    saveAndExit() {
      const { totalModulusTriggers, mediaPlanId } = this.storeData;
      const isDrafted = [undefined, null].includes(totalModulusTriggers);
      this.$store
        .dispatch(
          this.isEditingPlan
            ? UPDATE_MEDIA_PLAN_ACTION
            : SAVE_OR_GENERATE_MEDIA_PLAN_ACTION,
          {
            isDrafted,
            snackbar: this.snackbar
          }
        )
        .then(() => {
          if (!isDrafted && this.isEditingPlan) {
            this.$router.replace({
              name: 'Edit Media Plan',
              params: { id: mediaPlanId },
              query: { step: 5, regenerate: true }
            });
          } else {
            this.handleGoBack();
          }
        });
    },
    goToStep(stepIndex) {
      this.currentStepIndex = stepIndex;
      this.currentStep = this.stepConfig[this.currentStepIndex];
    },
    handleEditMediaPlanName(updatedName) {
      this.updateKeyInStore('name', updatedName);
    },
    onDisableNextButton(disable) {
      const updatedDisableButtons = { ...this.disableNextButton };
      updatedDisableButtons[this.currentStepIndex] = disable;
      this.disableNextButton = updatedDisableButtons;
    },
    downloadExcel() {
      const { excelPath } = this.output;
      const path = excelPath?.rawData;
      downloadS3Uri(path);
    },
    downloadBOSheet() {
      const { excelPath } = this.output;
      const path = excelPath?.budgetPlan;
      downloadS3Uri(path);
    }
  }
};
</script>

<style lang="css">
.step-wrapper {
  padding: 0 64px;
}

.generate-media-plan {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}

.footer-text {
  margin-top: 80px;
}

.u-line-height-24 {
  line-height: 24px;
}
</style>
