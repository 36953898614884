<template>
  <section>
    <div
      :id="'head_sku_tooltip'"
      class="u-display-none u-spacing-ph-l"
    >
      <div
        class="u-spacing-p-m u-display-flex u-flex-direction-column tooltip-wrapper"
      >
        <div class="u-display-flex">
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-blue-base u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer u-spacing-mr-m"
            icon="sku-box"
          />
          <rb-icon
            class="rb-icon--large u-color-grey-lighter u-cursor-pointer"
            icon="sku-box"
          />
        </div>
        <span class="u-color-blue-base u-spacing-mv-s u-font-size-5"
          >Parent ASIN</span
        >
        <p
          class="u-font-size-6 u-color-grey-light u-font-weight-normal u-line-height-1-3"
        >
          The performance (sales and units sold) of ASINs which have multiple
          variants is reported by Parent ASIN. There is no hierarchy associated
          with the parent ASIN and the variants
        </p>
        <p
          class="u-font-size-6 u-color-grey-light u-font-weight-normal u-line-height-1-3 u-spacing-mt-m"
        >
          You may use the Parent ASIN to search for all related ASINs
        </p>
      </div>
    </div>
    <span v-tippy="tippyOptions">
      <rb-icon
        class="rb-icon--small u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs"
        icon="help-fill"
      />
    </span>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tippyOptions: {
        html: '#head_sku_tooltip',
        theme: 'light',
        placement: 'bottom-start',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  }
};
</script>

<style lang="css" scoped>
.tooltip-wrapper {
  width: 350px;
}
</style>
