const compRecommendationList = [
  'Tagged 3pt list all',
  'Duplicate Listing',
  'Independent 3P listings',
  'Comp asins with promo',
  'Comp VPC all',
  'Comp oos amazon recommendations all',
  'Trending comp asins'
];

export { compRecommendationList };
