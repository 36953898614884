<template>
  <div
    class="bulk-search-aggregated-tag"
    @click="bulkSearchPopupOpener"
  >
    <div class="u-font-size-7">
      <rb-icon
        icon="layer"
        class="rb-icon--x-small u-color-grey-lighter"
      />
    </div>
    <div class="u-font-size-7 u-spacing-pl-xs">
      <span
        >{{ totalEntityCount }}
        {{ totalEntityCount > 1 ? 'inputs' : 'input' }}</span
      >
    </div>
    <div
      v-if="hasInvalidEntities"
      v-tippy="invalidEntityIconTooltipProps.tippyConfig"
      :title="invalidEntityIconTooltipProps.title"
      class="u-spacing-pl-xs"
    >
      <rb-icon
        icon="warning"
        class="rb-icon--x-small u-color-orange-base"
      />
    </div>
    <rb-icon
      icon="pencil"
      class="rb-icon--x-small u-color-grey-lighter u-spacing-ml-s"
    />
  </div>
</template>

<script>
export default {
  props: {
    totalEntityCount: {
      type: Number,
      default: null
    },
    hasInvalidEntities: {
      type: Boolean,
      default: false
    },
    invalidEntityIconTooltipProps: {
      type: Object,
      default: function () {
        return {
          title:
            'There may be difference in search results due to invalid / duplicate inputs',
          tippyConfig: {
            placement: 'top',
            arrow: false,
            interactive: true,
            distance: 5,
            size: 'regular',
            maxWidth: '300px'
          }
        };
      }
    }
  },
  methods: {
    bulkSearchPopupOpener() {
      this.$emit('handleAggregratedTagActions', 'edit');
    }
  }
};
</script>

<style lang="css" scoped>
.bulk-search-aggregated-tag {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  padding: 4px 10px;
  margin: 0px 5px;
  cursor: pointer;
  border: 1px solid #e9eaeb;
}
</style>
