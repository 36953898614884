import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

function getFiltersInFormat(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: 'single_day',
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      where.date = _date;
    }
  }
  state.whereClause = where;
}

function getLocationDetails(zip, target) {
  // zipcode and city will be present for all clients.
  // state and fc code is optional ( eg - bimbo ), conditionally concatenate
  let locationDetails = `${zip.zipcode}, ${zip.city}`;
  if (zip.state) {
    locationDetails = `${locationDetails}, ${zip.state}`;
  }
  if (target === 'downloadFile') {
    if (zip.fc_code) {
      locationDetails = `${locationDetails} FC: ${zip.fc_code}`;
    }
    return locationDetails.replaceAll(',', '');
  } else {
    if (zip.fc_code) {
      locationDetails = `${locationDetails} \n<span class="u-font-size-7">FC: ${zip.fc_code}<span>`;
    }
    return locationDetails;
  }
}

const state = {
  tableData: {},
  mappedColumnHeaders: [],
  selectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  page_wise_min_max_key: 'fresh_regional_availability'
};

const getters = {
  getSelectedFilters: (state) => {
    return state.selectedFilters;
  },
  getData: (state) => {
    return state.tableData;
  },
  getMappedColumnHeaders: (state) => {
    return state.mappedColumnHeaders;
  }
};

const mutations = {
  REGIONAL_AVAILABILITY_SUCCESS: (state, data) => {
    Vue.set(state.tableData, data.key, {});
    Vue.set(state.tableData[data.key], 'rows', data.rows);
    Vue.set(state.tableData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.tableData[data.key], 'page', data.page);
    Vue.set(state.tableData[data.key], 'load', false);
    Vue.set(state.tableData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.tableData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.tableData[data.key], 'noData', true);
    }
  },
  REGIONAL_AVAILABILITY_COLUMNS_MAPPED: (state, data) => {
    state.mappedColumnHeaders = data.data;
  },
  REGIONAL_AVAILABILITY_RESET: (state, data) => {
    Vue.set(state.tableData, data.key, {});
    Vue.set(state.tableData[data.key], 'columns', []);
    Vue.set(state.tableData[data.key], 'rows', []);
    Vue.set(state.tableData[data.key], 'load', true);
    Vue.set(state.tableData[data.key], 'error', false);
    Vue.set(state.tableData[data.key], 'noData', false);
  },
  REGIONAL_AVAILABILITY_ERROR: (state, data) => {
    Vue.set(state.tableData, data.key, {});
    Vue.set(state.tableData[data.key], 'columns', []);
    Vue.set(state.tableData[data.key], 'rows', []);
    Vue.set(state.tableData[data.key], 'load', false);
    Vue.set(state.tableData[data.key], 'error', true);
    Vue.set(state.tableData[data.key], 'noData', false);
  },
  SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.selectedFilters.date_range;
    Vue.set(state, 'selectedFilters', data.values);
    if (!state.selectedFilters.date_range) {
      Vue.set(state.selectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormat(data, state);
  }
};

const actions = {
  async getTableData(context, data) {
    try {
      context.commit('REGIONAL_AVAILABILITY_RESET', {
        key: data.meta.key
      });
      data.body.APIConfig.where = transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
      data.body.APIConfig.where.date = context.state.whereClause.date;

      const res = await HttpLayer.post({
        cube: 'EXECUTE_CUBE_API',
        APIData: data.body.APIConfig
      });

      // Preparing the row data to send to ag grid
      const rows = [];
      res.data.map((row) => {
        var availZipcodes = 0;
        var totalZipCodes = 0;
        for (const key in row.RESULT) {
          if (key.toLowerCase().trim().includes('zipcode')) {
            let parsedVal = JSON.parse(row.RESULT[key]);
            // Edge case if for some reason null is returned back from backend
            if (parsedVal === null || parsedVal.status === 'NA') {
              parsedVal = {
                status: 'No Data'
              };
            }

            row.RESULT[key] = parsedVal;

            // Count total number of zipcodes in this asin
            if (parsedVal.status !== 'NA') {
              totalZipCodes = totalZipCodes + 1;
            }

            // Count total number of availbale zipcodes
            if (parsedVal?.status?.toLowerCase() === 'available') {
              availZipcodes = availZipcodes + 1;
            }
          }
        }

        // Assign as subtitleText for tableCellComponent.vue
        // row.RESULT.subtitleText = `${availZipcodes}/${totalZipCodes}`;
        rows.push(row.RESULT);
      });
      context.commit('REGIONAL_AVAILABILITY_SUCCESS', {
        key: data.meta.key,
        data: res,
        rows,
        totalRows: res.data[0] ? res.data[0].RESULT.count : 0,
        page: data.body.APIConfig.page
      });
    } catch (error) {
      context.commit('REGIONAL_AVAILABILITY_ERROR', {
        key: data.meta.key
      });
    }
  },
  async getPincodeMapping(context, data) {
    try {
      // Get mapping of pincode and city forom API
      const res = await HttpLayer.post({
        cube: 'EXECUTE_CUBE_API',
        APIData: data.body.APIConfig
      });

      // Create an array of objects with zipcode info
      const zipCodesWithCity = [];
      res.data.map((zip) => {
        zipCodesWithCity.push({
          key: zip.DIMENSION.zipcode_label,
          zipcode: getLocationDetails(zip.DIMENSION),
          downloadFileLocationDisaplyName: getLocationDetails(
            zip.DIMENSION,
            'downloadFile'
          )
        });
      });

      // Add to state
      await context.commit('REGIONAL_AVAILABILITY_COLUMNS_MAPPED', {
        key: 'RegionalAvailabilityTableMapPinCodes',
        data: zipCodesWithCity
      });

      return zipCodesWithCity;
    } catch (error) {
      context.commit('REGIONAL_AVAILABILITY_ERROR', {
        key: data.meta.key
      });
    }
  },
  // setSelectedFilters
  setSelectedFilters: (context, data) => {
    context.commit('SET_SELECTED_FILTER', data);
  },
  download: (context, data) => {
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;

    if (
      data.body.APIConfig.customOrderAndSort &&
      data.body.APIConfig.customOrderAndSort.limit
    ) {
      data.body.APIConfig.customOrderAndSort.limit = null;
    }

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      // Transforming the response as status key is in string format
      let transformResponse = transformer.mergeResultDimension(
        response.data,
        true
      );
      return transformResponse;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
