<template>
  <div class="rb-category">
    <p
      class="u-spacing-pt-l u-spacing-pl-l u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-pb-l u-text-case-upper u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
    >
      {{ title }}
    </p>
    <div class="widget-wrp">
      <div
        v-if="data.length > 0"
        class="header u-display-flex u-flex-justify-content-space-between"
      >
        <div
          class="border--line u-spacing-pt-m u-spacing-pb-m u-text-align-right u-spacing-pr-m u-font-size-7 u-flex-1"
        >
          Share in last 7 days
        </div>
        <div
          class="u-text-align-right u-spacing-pl-m u-spacing-pt-m u-spacing-pb-m u-display-flex u-flex-1 u-font-size-7"
        >
          Share in prior week
        </div>
      </div>
      <section
        v-for="(item, index) in data"
        :key="index"
        class="body u-display-flex u-flex-justify-content-space-between"
        :style="[
          item.colors
            ? { color: item.colors }
            : { color: `${colorArray[index % colorArray.length]}` }
        ]"
      >
        <div
          class="u-display-flex border--line u-spacing-pt-m u-flex-1"
          :class="{
            'u-spacing-pb-xl': index === data.length - 1,
            'u-spacing-pb-m': index !== data.length - 1
          }"
        >
          <div
            class="u-spacing-pl-l u-flex-1 u-display-flex u-flex-align-items-center"
            :class="{ 'u-font-weight-bold': index === 0 }"
          >
            {{ item.BRAND_NAME }}
          </div>
          <div
            v-if="index === 0"
            class="u-text-align-right u-flex-2 u-spacing-pr-m u-font-weight-bold"
          >
            {{ item.LW_VALUE_FORMAT }}
          </div>
          <div
            v-if="index !== 0"
            class="u-flex-2"
          >
            <div>
              <div
                class="u-spacing-pr-m u-text-align-right u-spacing-pb-xs u-font-size-6"
              >
                {{ item.LW_VALUE_FORMAT }}
              </div>
              <div class="rb-progress">
                <div
                  class="progress--bar"
                  :style="[
                    item.colors
                      ? { backgroundColor: item.colors }
                      : {
                          backgroundColor: `${
                            colorArray[index % colorArray.length]
                          }`
                        },
                    item.lastWeekChange <= 1
                      ? { width: '2%' }
                      : { width: `${item.lastWeekChange}%` }
                  ]"
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="u-display-flex u-flex-1 u-spacing-pt-m"
          :class="{
            'u-spacing-pb-xl': index === data.length - 1,
            'u-spacing-pb-m': index !== data.length - 1
          }"
        >
          <div
            v-if="index === 0"
            class="u-flex-2 u-spacing-pl-m u-text-align-left u-font-weight-bold"
          >
            {{ item.PW_VALUE_FORMAT }}
          </div>
          <div
            v-if="index !== 0"
            class="u-flex-2"
          >
            <div>
              <div
                class="u-spacing-pl-m u-text-align-left u-spacing-pb-xs u-font-size-6"
              >
                {{ item.PW_VALUE_FORMAT }}
              </div>
              <div class="rb-progress progress--right">
                <div
                  class="progress--bar--right"
                  :style="[
                    item.colors
                      ? { backgroundColor: item.colors }
                      : {
                          backgroundColor: `${
                            colorArray[index % colorArray.length]
                          }`
                        },
                    item.priorWeekChange <= 1
                      ? { width: '2%' }
                      : { width: `${item.priorWeekChange}%` }
                  ]"
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div
            class="u-spacing-ph-xl u-display-flex u-flex-align-items-center u-color-grey-base"
            :class="{
              'u-font-weight-bold': index === 0,
              'u-spacing-pt-xs': index !== 0
            }"
          >
            <rb-icon
              v-if="item.trendIncrese"
              class="icon--small rb-icon--small u-spacing-mr-xs u-color-blue-base u-color-green-base u-flex-align-items-center"
              :icon="'arrow-up'"
            />
            <rb-icon
              v-if="!item.trendIncrese"
              class="icon--small rb-icon--small u-spacing-mr-xs u-color-red-base u-flex-align-items-center"
              :icon="'arrow-down'"
            />
            {{ item.CHANGE_VALUE_FORMAT }}
          </div>
        </div>
      </section>
      <section
        v-if="data.length === 0"
        class="u-display-flex u-flex-justify-content-center u-spacing-mb-xl"
      >
        No Data Avaialable !!!
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RbCategoryShare',
  props: {
    data: { type: Array, required: true, default: [] },
    title: {
      type: String,
      required: false,
      default: 'Category size and brands share'
    }
  },
  data() {
    return {
      colorArray: ['#2b333b', '#ffa800', '#97cc04', '#bd10e0', '#ff6072']
    };
  }
};
</script>
<style lang="css" scoped>
.rb-progress {
  height: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
}

.progress--right {
  flex-direction: row;
}

.progress--bar {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}

.progress--bar--right {
  border-top-right-radius: 4px;
  opacity: 0.55;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}

.border--line {
  border-right: 1px solid #caccce;
}
</style>
