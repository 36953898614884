<template>
  <section
    ref="dropdownMenu"
    class="max-width-420px u-line-height-1-3 u-spacing-p-l tippy-drop-down u-border-radius-s"
  >
    <div
      v-if="title"
      class="u-font-size-5 u-font-weight-600"
    >
      {{ title }}
    </div>
    <div
      v-if="body"
      class="u-spacing-mt-m u-font-size-6 u-font-weight-normal"
    >
      {{ body }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="css" scoped>
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}
.max-width-420px {
  max-width: 420px;
}
</style>
