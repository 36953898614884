<template>
  <section
    class="u-cursor-pointer u-display-flex u-flex-align-items-center"
    @click="handleClick"
  >
    <rb-icon
      class="rb-icon--medium u-color-grey-base"
      :class="{ 'rotate-icon': isExpanded }"
      :icon="'angled-arrow-right'"
    />
    <div class="u-spacing-ml-s u-font-size-4 u-font-weight-600">
      {{ title }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="css" scoped>
.rotate-icon {
  transform: rotate(90deg);
}
</style>
