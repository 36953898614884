import HttpService from '@/utils/services/http-service';
import dictionary from '@/pages/entity_details/configs/amazon/dictionary.js';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import upperCase from 'lodash/upperCase';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import { getSegmentIdArray } from '@/pages/dashboard_service_campaign_management/utils/index';
const tabsComponentWrapper = () =>
  import('@/pages/entity_details/common_components/tabsComponentWrapper.vue');
const dashboardServiceTable = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const dspRecommendations = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/custom-recommendation.vue'
  );
const splitTabs = () => import('@/components/widgets/splitTabs.vue');
const actionsBar = () =>
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const FreqCapChangePanel = () =>
  import('@/components/ams/actionPanels/frequencyCapChangePanel.vue');
const BidChangePanel = () =>
  import('@/components/ams/actionPanels/bidChangePanel.vue');
const customActionPanel = () =>
  import('@/components/ams/actionPanels/customActionPanel.vue');
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconHeader = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const recommendationCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/recommendationCell.vue'
  );
const recChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/recChip.vue'
  );
const ApproveRec = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const editBid = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/editBid.vue'
  );

const taggedCell = Vue.extend({
  props: {
    tagMap: {
      type: Object,
      default() {
        return {
          AMAZON_EXCLUSIVE: 'Amazon',
          OPEN_EXCHANGE: 'Third Party Exchanges'
        };
      }
    }
  },
  template: `
  <div class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left u-text-overflow-ellipsis">
    <span class="u-font-weight-600">{{tagMap[params.data[params.tagKey]] || params.data[params.tagKey]}}</span> : <span>{{params.data[params.textKey]}}</span>
  </div>`
});

export function getColumnDefinition(vueRef, page, table, tab) {
  var _columnDefs = [];
  const selectedDictionary =
    dictionary[page][table][tab] || dictionary[page][table];
  for (const i in selectedDictionary) {
    const obj = {};
    obj.headerComponentParams = {};
    obj.field = i;
    obj.title = selectedDictionary[i].title;
    obj.suppressSizeToFit = false;
    obj.resizable = false;
    obj.headerComponentFramework = 'genericTableHeader';
    const keyType = 'string';
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: formatter
    };
    obj.enableSorting = true;
    obj.cellRenderer = cellRenderer;
    obj.headerComponentParams.enableSorting = true;
    obj.width = selectedDictionary[i].width ?? 450;
    if (selectedDictionary[i].type === 'icon') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererFramework = 'iconTableCell';
      obj.cellRendererParams.displayIcon = selectedDictionary[i].icon;
      obj.cellRendererParams.alternateIcon =
        selectedDictionary[i]?.alternateIcon;
      obj.cellRendererParams.alternateIconClass =
        selectedDictionary[i]?.alternateIconClass;
      obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
      obj.cellRendererParams.iconSize = 'medium';
      obj.cellRendererParams.iconClickEvent =
        vueRef[selectedDictionary[i].iconClickEvent];
      obj.headerComponentParams.clickHeader =
        vueRef[selectedDictionary[i].clickHeader];
      obj.headerComponentParams.customStyles = {
        color: '#007cf6',
        display: 'flex',
        width: '100%',
        'justify-content': 'center'
      };
      if (selectedDictionary[i]?.toolTipText) {
        obj.headerComponentParams.headerIcon = {
          icon: 'info-circle-fill',
          toolTip: selectedDictionary[i]?.toolTipText
        };
      }
      obj.width = 120;
    }
    if (selectedDictionary[i].type === 'append') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      obj.cellRendererFramework = taggedCell;
      obj.cellRendererParams.textKey = i;
      obj.cellRendererParams.tagKey = selectedDictionary[i].append;
    }
    obj.headerName = selectedDictionary[i].title;
    obj.keyOrder = selectedDictionary[i].keyOrder;
    selectedDictionary[i].pinned && (obj.pinned = selectedDictionary[i].pinned);
    // selectedDictionary[i].pinned && (delete obj.cellRendererFramework);
    if (selectedDictionary[i].toolTipText) {
      obj.headerComponentParams.toolTipText = selectedDictionary[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    if (selectedDictionary[i].showOnUi) {
      _columnDefs.push(obj);
    }
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}

export const mappedName = {
  AMAZON_EXCLUSIVE: 'Amazon',
  OPEN_EXCHANGE: 'Third Party Exchanges'
};

export function extractBid(data, labelKey, recTypeKey) {
  const parsedData = utils.parseAndReturnArray(data);
  const rec = parsedData.find((item) =>
    Object.values(item).includes(recTypeKey)
  );
  const val = rec[labelKey];
  const [prev, next] = val
    ?.split(' ')
    ?.filter((str) => !Number.isNaN(Number(str)));
  return { prev, next };
}

export default {
  orders: {
    name: 'Orders',
    metadata: [
      {
        // for fetching order details header metadata
        action: 'entityDetails/fetchWithExecute',
        getters: 'entityDetails/getDspOrderDetails',
        storeActionName: 'UPDATE_DSP_ORDER_DETAILS',
        getApiData: (entityId) => ({
          cubeName: 'dsp_orders_workbench',
          getLatestAvailableInsteadOfRollup: false,
          timeseriesEnabled: false,
          pvpenabled: false,
          yoyenabled: false,
          measuresList: [
            'start_date',
            'end_date',
            'dsp_orders_total_budget',
            'dsp_orders_delivery_activation_status',
            'dsp_orders_delivery_rate'
          ],
          groupByDimensionsList: [
            'dsp_orders_delivery_status',
            'dsp_orders_advertiser_name',
            'dsp_orders_order_name',
            'dsp_orders_campaign_goal',
            'dsp_orders_campaign_goal_kpi'
          ],
          orderByList: [],
          where: {
            dimensionNameValueList: [
              {
                dimensionName: 'dsp_orders_order_id',
                dimensionValue: entityId
              }
            ]
          }
        })
      }
    ],
    header: {
      widgetName: 'order',
      pageName: 'order',
      activityLog: {
        show: true
      },
      filters: {
        config: {
          emit: 'dspOrderDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'dspOrderDetailsAddToFilters'
        },
        filterLocalStorageKey: 'dsp-order-details-filters',
        page: 'dspOrderDetails'
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        path: '/us/amazon/retail/advertising/dsp_campaign_management/orders'
      },
      // line 1 and 2 will wrap around date picker if its present
      metadata_api_state: {
        getter: 'entityDetails/getDspOrderDetails'
      },
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Order',
              values: ['name'],
              hoverText: ['name'],
              titleClass:
                'u-font-weight-700 u-color-grey-lighter u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['NA']
            },
            class: 'u-spacing-pt-s u-text-overflow-ellipsis u-width-900px ',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-l'
      },
      line2: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Status',
              values: ['status'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-color-blue-base u-font-weight-600'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Duration',
              values: ['startDate', 'endDate'],
              titleClass: null,
              delimiter: '-',
              valueClasses: null,
              altValues: ['No start date', 'No end date']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Total Budget',
              values: ['totalBudget'],
              titleClass: null,
              delimiter: ' ',
              valueClasses: null,
              altValues: ['NA', 'Daily']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-m u-font-size-5'
      },
      line3: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Goal',
              values: ['goal'],
              titleClass: null,
              delimiter: '',
              valueClasses: [''],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Goal KPI',
              values: ['goal_kpi'],
              titleClass: null,
              delimiter: ' ',
              valueClasses: null,
              altValues: ['NA', 'Daily']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Delivery Rate',
              values: ['delivery_rate'],
              titleClass: null,
              delimiter: '',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-l u-font-size-5'
      }
    },
    chart: {
      page: 'dspOrderDetails',
      globalViewId: 0,
      pageId: 0,
      filter: 'dspOrderDetailsFiltersApplied',
      widgetName: 'dspOrdersTabLineItemsChart',
      widgetConfig: (widgetName, id) => ({
        ':widget': widgetName,
        ':timeseriesRollupBy': 'DAY',
        keepExistingDimensionNameValueList: true,
        replaceEntityInDimensionNameValueList: {
          ':order_id': id
        },
        timeseries: 'report_date'
      }),
      headerOptions: (widgetName) => {
        return {
          [widgetName]: {
            props: {
              headerOptions: [],
              enableRollup: true,
              enableToggle: true,
              enableWaterfall: true,
              enableDownload: false,
              show: false,
              selectedMetricLimit: 3,
              removeDropdown: false,
              disableAddNewMetricButton: false,
              headerContainerGridStyles: {
                display: 'flex',
                'justify-content': 'flex-end',
                gap: '1.6rem',
                'align-items': 'flex-start'
              }
            }
          }
        };
      }
    },
    // show tabs data if present
    tabs: {
      line_items: { component: tabsComponentWrapper },
      creatives: { component: tabsComponentWrapper },
      products: { component: tabsComponentWrapper },
      inventory: { component: tabsComponentWrapper },
      audience: { component: tabsComponentWrapper },
      devices: { component: tabsComponentWrapper }
    },
    tabConfig: {
      'Line Items': {
        recommendationKey: 'dsp_line_items_recommendation_data',
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabLineItems: {
            ':widget': 'dspOrdersTabLineItems',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_line_items_order_id',
                dimensionValue: id
              }
            ]
          },
          dspTopRecommendationsOrdersTabLineItems: {
            ':widget': 'dspTopRecommendationsOrdersTabLineItems',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName:
                  'dsp_orders_tab_line_items_recommendation_metadata_order_id',
                dimensionValue: id
              }
            ]
          }
        }),
        components: {
          dspOrdersTabLineItems: dashboardServiceTable,
          dspTopRecommendationsOrdersTabLineItems: dspRecommendations,
          tableSwitchComponent,
          iconTextActions,
          iconCell,
          iconHeader,
          actionsBar,
          FreqCapChangePanel,
          BidChangePanel,
          customActionPanel,
          recommendationCell,
          recChip,
          ApproveRec,
          editBid
        },
        headerOptions: (context) => ({
          dspOrdersTabLineItems: {
            props: {
              headerOptions: [
                ...(!context.rowSelections.length
                  ? []
                  : context.showRecCustomComponent
                  ? []
                  : [
                      {
                        ui_component: 'actionsBar',
                        props: {
                          actionBarActions: [
                            ...(!context?.recFilter?.value
                              ? []
                              : [
                                  {
                                    icon: 'check-fill-circle',
                                    title: `View & Approve ${
                                      context.rowSelections.length > 1
                                        ? 'all'
                                        : ''
                                    }`,
                                    id: 'approve_all',
                                    removeSelectionState: false,
                                    handler(
                                      selectionLength,
                                      dropDownSelection,
                                      selections
                                    ) {
                                      // here {this} is bound to the cw_actionsBar component
                                      this.$emit(
                                        'toggleRecCustomComponent',
                                        true
                                      );
                                    }
                                  }
                                ]),
                            {
                              icon: 'task-status',
                              title: 'Edit Status',
                              id: 'edit-status',
                              dropDown: true,
                              dropDownOptions: [
                                { title: 'Enable', value: 'ACTIVE' },
                                { title: 'End', value: 'INACTIVE' }
                              ],
                              handler(
                                selectionLength,
                                dropDownSelection,
                                selections
                              ) {
                                return new Promise((resolve, reject) => {
                                  const dataArray = selections.map(
                                    (item) => item.data
                                  );

                                  const payloadArray = dataArray.map((item) => {
                                    const actionPayload = {
                                      lineItemId:
                                        item.dsp_line_items_line_item_id,
                                      profileId: item.dsp_line_items_profile_id,
                                      deliveryActivationStatus:
                                        dropDownSelection[0].value,
                                      clientName: window.clientName,
                                      actionType: 'dspLineItemStatusChange'
                                    };
                                    return {
                                      actionPayload,
                                      actionType: 'dspLineItemStatusChange',
                                      viewPayload: {
                                        ...actionPayload,
                                        previousStatus:
                                          item.dsp_line_items_delivery_activation_status &&
                                          upperCase(
                                            item.dsp_line_items_delivery_activation_status
                                          )
                                      },
                                      widget: 'lineItem',
                                      previousEntityValue:
                                        item.dsp_line_items_delivery_activation_status &&
                                        upperCase(
                                          item.dsp_line_items_delivery_activation_status
                                        ),
                                      newEntityValue:
                                        dropDownSelection[0].value,
                                      primaryKey:
                                        item.dsp_line_items_line_item_id,
                                      actionSource: {
                                        pageUrl: window.location.href
                                      }
                                    };
                                  });
                                  HttpService.post(
                                    'AMS_ACTIONS_ACTION_LOG',
                                    payloadArray
                                  )
                                    .then((response) => {
                                      this.$emit(
                                        'openSnackbar',
                                        'Status change request has been submitted for the selected line item(s)'
                                      );
                                      this.$emit(
                                        'switchBlueBar',
                                        'line item(s)'
                                      );
                                      resolve(response);
                                    })
                                    .catch((err) => {
                                      this.$emit(
                                        'openSnackbar',
                                        'Something went wrong.'
                                      );
                                      reject(err);
                                    });
                                });
                              }
                            },
                            {
                              icon: 'dollar',
                              title: 'Base Supply Bid',
                              id: 'adjust-base-bids',
                              customState: {
                                component: 'BidChangePanel',
                                props: {
                                  title: 'Adjust Base Supply Bid',
                                  actionPanelType: 'lineItemBaseSupplyBid',
                                  apiVersion: 'actionlog',
                                  retailer: context.$store.getters.getRetailer,
                                  widget: 'lineItem',
                                  primaryKey: 'dsp_line_items_line_item_id'
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    this.$emit('switchBlueBar', 'line item(s)');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            },
                            {
                              icon: 'dollar',
                              title: 'Max Supply Bid',
                              id: 'adjust-max-bids',
                              customState: {
                                component: 'BidChangePanel',
                                props: {
                                  title: 'Adjust Max Supply Bid',
                                  actionPanelType: 'lineItemMaxSupplyBid',
                                  apiVersion: 'actionlog',
                                  retailer: context.$store.getters.getRetailer,
                                  widget: 'lineItem',
                                  primaryKey: 'dsp_line_items_line_item_id'
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    this.$emit('switchBlueBar', 'line item(s)');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            },
                            {
                              icon: 'pencil',
                              title: 'Adjust Frequency Cap',
                              id: 'adjust-freq-cap',
                              customState: {
                                component: 'FreqCapChangePanel',
                                props: {
                                  title: 'Adjust Frequency Cap',
                                  actionPanelType: 'lineItems',
                                  apiVersion: 'actionlog',
                                  retailer: context.$store.getters.getRetailer,
                                  widget: 'lineItem',
                                  primaryKey: 'dsp_line_items_line_item_id'
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    // debugger;
                                    this.$emit('switchBlueBar', 'line item(s)');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            },
                            {
                              icon: 'add-circle-outline',
                              title: 'Add Inventory',
                              id: 'add-inventory-targeting',
                              customState: {
                                component: 'customActionPanel',
                                props: {
                                  staticHeaderText: 'Add Inventory',
                                  widgetsConfig: {
                                    config: (that) => {
                                      // {that} is bound to either the selected actions component
                                      return {
                                        primaryKey:
                                          'dsp_inventory_targeting_supply_source',
                                        isMultipleAllowed: false,
                                        default: {
                                          noRowsRightTable:
                                            'Add inventory sources to this list',
                                          selections: `(${context.rowSelections.length} line item selected)`,
                                          noRowsLeftTable:
                                            'No inventory sources found',
                                          leftBtnLabel: 'Add Inventory',
                                          rightBtnLabel: 'Cancel',
                                          warningMessage:
                                            'Multiple line items are not allowed'
                                        },
                                        left: {
                                          table: 'left',
                                          tabs: {
                                            Amazon: {
                                              localFilter: {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue:
                                                  'AMAZON_EXCLUSIVE'
                                              },
                                              columnDefs: getColumnDefinition(
                                                that,
                                                'line_items_add_inventory',
                                                'left',
                                                'AMAZON_EXCLUSIVE'
                                              ),
                                              search: {
                                                enableSearch: false
                                              }
                                            },
                                            'Third Party Exchanges': {
                                              localFilter: {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue: 'OPEN_EXCHANGE'
                                              },
                                              columnDefs: getColumnDefinition(
                                                that,
                                                'line_items_add_inventory',
                                                'left',
                                                'OPEN_EXCHANGE'
                                              ),
                                              search: {
                                                enableSearch: true,
                                                placeholder: 'Search',
                                                searchKey:
                                                  'dsp_inventory_targeting_supply_source_name'
                                              }
                                            }
                                          },
                                          meta: {
                                            type: 'table',
                                            action: 'fetchTableDataExecuteApi',
                                            localFilters: [
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_order_id',
                                                dimensionValue: context.entityId
                                              },
                                              ...(context.rowSelections?.[0]
                                                ?.data
                                                ?.dsp_line_items_line_item_type
                                                ? [
                                                    {
                                                      dimensionName:
                                                        'dsp_inventory_targeting_line_item_type',
                                                      dimensionValue:
                                                        context.rowSelections?.[0]?.data?.dsp_line_items_line_item_type
                                                          ?.split?.(' ')
                                                          ?.map?.(upperCase)
                                                          ?.join?.('_')
                                                    }
                                                  ]
                                                : []),
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue:
                                                  'AMAZON_EXCLUSIVE'
                                              }
                                            ]
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              cubeName:
                                                'dsp_inventory_targeting_workbench',
                                              getLatestAvailableInsteadOfRollup: false,
                                              timeseriesEnabled: false,
                                              pvpenabled: false,
                                              yoyenabled: false,
                                              measuresList: [
                                                'dsp_inventory_targeting_supply_source_name',
                                                'dsp_inventory_targeting_supply_source_type',
                                                'dsp_inventory_targeting_line_item_type'
                                              ],
                                              groupByDimensionsList: [
                                                'dsp_inventory_targeting_supply_source'
                                              ],
                                              orderByList: [],
                                              limit: 10,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'left',
                                              'AMAZON_EXCLUSIVE'
                                            )
                                          }
                                        },
                                        right: {
                                          table: 'right',
                                          searchKey: 'search',
                                          meta: {
                                            type: 'table',
                                            action: 'fetchTableDataExecuteApi',
                                            localFilters: [
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_line_item_id',
                                                dimensionValue:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id
                                              }
                                            ]
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              cubeName:
                                                'dsp_inventory_targeting_workbench',
                                              getLatestAvailableInsteadOfRollup: false,
                                              timeseriesEnabled: false,
                                              pvpenabled: false,
                                              yoyenabled: false,
                                              measuresList: [
                                                'dsp_inventory_targeting_supply_source_name',
                                                'dsp_inventory_targeting_supply_source_type',
                                                'dsp_inventory_targeting_line_item_type'
                                              ],
                                              groupByDimensionsList: [
                                                'dsp_inventory_targeting_supply_source',
                                                'dsp_inventory_targeting_line_item_type'
                                              ],
                                              orderByList: [],
                                              limit: 500,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'right'
                                            )
                                          }
                                        },
                                        apply(rows = []) {
                                          return new Promise(
                                            (resolve, reject) => {
                                              const payload = rows.map(
                                                (row) => ({
                                                  actionPayload: {
                                                    lineItemId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_line_item_id,
                                                    profileId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemAddInventorySources',
                                                    supplySources: [
                                                      row.dsp_inventory_targeting_supply_source
                                                    ]
                                                  },
                                                  viewPayload: {
                                                    lineItemId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_line_item_id,
                                                    profileId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemAddInventorySources',
                                                    supplySources:
                                                      row.dsp_inventory_targeting_supply_source,
                                                    sourceName:
                                                      row.dsp_inventory_targeting_supply_source_name,
                                                    sourceType:
                                                      mappedName[
                                                        row
                                                          .dsp_inventory_targeting_supply_source_type
                                                      ]
                                                  },
                                                  actionType:
                                                    'dspLineItemAddInventorySources',
                                                  widget: 'lineItem',
                                                  previousEntityValue: null,
                                                  newEntityValue: null,
                                                  primaryKey:
                                                    context.rowSelections[0]
                                                      .data
                                                      .dsp_line_items_line_item_id,
                                                  actionSource: {
                                                    pageUrl:
                                                      window.location.href
                                                  }
                                                })
                                              );
                                              HttpService.post(
                                                'AMS_ACTIONS_ACTION_LOG',
                                                payload
                                              )
                                                .then((response) => {
                                                  that.$emit(
                                                    'afterAPIActionToCall'
                                                  );
                                                  resolve(true);
                                                })
                                                .catch((err) => {
                                                  that.openSnackbar(
                                                    'Something went wrong.'
                                                  );
                                                  reject(err);
                                                });
                                            }
                                          );
                                        }
                                      };
                                    }
                                  }
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    this.$emit(
                                      'openSnackbar',
                                      'Action has been submitted for the selected inventory sources'
                                    );
                                    this.switchBlueBar('line item');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            },
                            {
                              icon: 'remove-fill',
                              title: 'Remove Inventory',
                              id: 'remove-inventory-targeting',
                              customState: {
                                component: 'customActionPanel',
                                props: {
                                  staticHeaderText: 'Remove Inventory',
                                  widgetsConfig: {
                                    config: (that) => {
                                      return {
                                        primaryKey:
                                          'dsp_inventory_targeting_supply_source',
                                        isMultipleAllowed: false,
                                        default: {
                                          headerTitle: '',
                                          noRowsRightTable:
                                            'Add inventory sources to this list',
                                          selections: `(${context.rowSelections.length} line item selected)`,
                                          noRowsLeftTable:
                                            'No inventory sources found',
                                          leftBtnLabel: 'Remove Inventory',
                                          rightBtnLabel: 'Cancel',
                                          warningMessage:
                                            'Multiple line items are not allowed'
                                        },
                                        left: {
                                          table: 'left',
                                          title: '',
                                          tabs: {
                                            Amazon: {
                                              localFilter: {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue:
                                                  'AMAZON_EXCLUSIVE'
                                              },
                                              columnDefs: getColumnDefinition(
                                                that,
                                                'line_items_add_inventory',
                                                'left',
                                                'AMAZON_EXCLUSIVE'
                                              ),
                                              search: {
                                                enableSearch: false
                                              }
                                            },
                                            'Third Party Exchanges': {
                                              localFilter: {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue: 'OPEN_EXCHANGE'
                                              },
                                              columnDefs: getColumnDefinition(
                                                that,
                                                'line_items_add_inventory',
                                                'left',
                                                'OPEN_EXCHANGE'
                                              ),
                                              search: {
                                                enableSearch: true,
                                                placeholder: 'Search',
                                                searchKey:
                                                  'dsp_inventory_targeting_supply_source_name'
                                              }
                                            }
                                          },
                                          meta: {
                                            type: 'table',
                                            key: 'campaign_entities_source_table',
                                            action: 'fetchTableDataExecuteApi',
                                            filters: {
                                              emit: 'fetchAllEntitiesFiltersTriggered',
                                              hasSearch: false
                                            },
                                            filterState: {
                                              getter:
                                                'getExecuteApiSelectedFitlers',
                                              setter:
                                                'fetchEntitiesExecuteApiFilters'
                                            },
                                            localFilters: [
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_order_id',
                                                dimensionValue: context.entityId
                                              },
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_line_item_id',
                                                dimensionValue:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id
                                              },
                                              {
                                                dimensionName:
                                                  'dsp_inventory_targeting_supply_source_type',
                                                dimensionValue:
                                                  'AMAZON_EXCLUSIVE'
                                              }
                                            ]
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              cubeName:
                                                'dsp_inventory_targeting_workbench',
                                              getLatestAvailableInsteadOfRollup: false,
                                              timeseriesEnabled: false,
                                              pvpenabled: false,
                                              yoyenabled: false,
                                              measuresList: [
                                                'dsp_inventory_targeting_supply_source_type'
                                              ],
                                              groupByDimensionsList: [
                                                'dsp_inventory_targeting_supply_source_name',
                                                'dsp_inventory_targeting_supply_source',
                                                'dsp_inventory_targeting_order_id',
                                                'dsp_inventory_targeting_line_item_type',
                                                'dsp_inventory_targeting_line_item_name',
                                                'dsp_inventory_targeting_line_item_id'
                                              ],
                                              orderByList: [],
                                              limit: 10,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_remove_inventory',
                                              'left'
                                            )
                                          }
                                        },
                                        right: {
                                          table: 'right',
                                          title: '',
                                          meta: {
                                            type: 'table',
                                            localFilters: [],
                                            paginationAction: ''
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              limit: 10,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_remove_inventory',
                                              'right'
                                            )
                                          }
                                        },
                                        apply(rows = []) {
                                          return new Promise(
                                            (resolve, reject) => {
                                              const payload = rows.map(
                                                (row) => ({
                                                  actionPayload: {
                                                    lineItemId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_line_item_id,
                                                    profileId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemRemoveInventorySources',
                                                    supplySources: [
                                                      row.dsp_inventory_targeting_supply_source
                                                    ]
                                                  },
                                                  viewPayload: {
                                                    lineItemId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_line_item_id,
                                                    profileId:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_line_items_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemRemoveInventorySources',
                                                    supplySources:
                                                      row.dsp_inventory_targeting_supply_source,
                                                    sourceName:
                                                      row.dsp_inventory_targeting_supply_source_name,
                                                    sourceType:
                                                      mappedName[
                                                        row
                                                          .dsp_inventory_targeting_supply_source_type
                                                      ]
                                                  },
                                                  actionType:
                                                    'dspLineItemRemoveInventorySources',
                                                  widget: 'lineItem',
                                                  previousEntityValue: null,
                                                  newEntityValue: null,
                                                  primaryKey:
                                                    context.rowSelections[0]
                                                      .data
                                                      .dsp_line_items_line_item_id,
                                                  actionSource: {
                                                    pageUrl:
                                                      window.location.href
                                                  }
                                                })
                                              );
                                              HttpService.post(
                                                'AMS_ACTIONS_ACTION_LOG',
                                                payload
                                              )
                                                .then((response) => {
                                                  that.$emit(
                                                    'afterAPIActionToCall'
                                                  );
                                                  resolve(true);
                                                })
                                                .catch((err) => {
                                                  that.openSnackbar(
                                                    'Something went wrong.'
                                                  );
                                                  reject(err);
                                                });
                                            }
                                          );
                                        }
                                      };
                                    }
                                  }
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    this.$emit(
                                      'openSnackbar',
                                      'Action has been submitted for the selected inventory sources'
                                    );
                                    this.switchBlueBar('line item');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            }
                          ],
                          isCustomActionStateVisible:
                            context.isCustomActionStateVisible,
                          selections: context.rowSelections,
                          blacklistedActionsForMultiSelections: [
                            'Add Inventory',
                            'Remove Inventory'
                          ]
                        },
                        events: {
                          deselectSelections: (selections) => {
                            // this is on x click in actions bar;
                            context.handleSelectionChange(selections, true);
                          },
                          openSnackbar: context.openSnackbar,
                          switchBlueBar: context.switchBlueBar,
                          toggleCustomActionState:
                            context.toggleCustomActionState,
                          toggleRecCustomComponent:
                            context.toggleRecCustomComponent
                        }
                      }
                    ])
              ],
              enableDownloadWithText: context.showRecCustomComponent
                ? true
                : !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(!context.rowSelections.length
                  ? {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    }
                  : context.showRecCustomComponent
                  ? {
                      display: 'flex',
                      'justify-content': 'space-between',
                      'align-items': 'flex-start'
                    }
                  : {
                      padding: 0
                    })
              },
              searchParams: {
                show: context.showRecCustomComponent
                  ? true
                  : !context.rowSelections.length,
                placeholder: 'Search',
                searchDimension: 'dsp_line_items_line_item_name'
              },
              gridOptions: context.gridOptions,
              rowSelection: {
                mode: 'multiple',
                onSelectionChanged: context.handleSelectionChange
              },
              rowHeight: 45,
              columnKeyMapping: context.columnKeyMapping
            }
          },
          dspTopRecommendationsOrdersTabLineItems: {
            props: {
              footerOptions: [],
              showLastUpdatedDate: false,
              entityName: 'line item',
              countKey:
                'dsp_orders_tab_line_items_recommendation_metadata_count',
              labelKey:
                'dsp_orders_tab_line_items_recommendation_metadata_rec_label',
              recCustomComponent:
                (context.showRecCustomComponent &&
                  context.recCustomComponent(context)[
                    context?.recFilter?.value
                  ]) ||
                {},
              selectedRecommendation: context.selectedRecommendation,
              isCustomActionStateVisible: context.isCustomActionStateVisible
            },
            events: {
              recommendationFilter: context.recommendationFilter,
              handleRecommendation: context.handleRecommendation,
              handleSelectionChange: context.handleSelectionChange,
              toggleRecCustomComponent: context.toggleRecCustomComponent
            }
          }
        }),
        getActivityData: ({ data }) => {
          return {
            name: 'line item',
            params: {
              widget: 'lineItem',
              primaryKey: data.dsp_line_items_line_item_id
            }
          };
        },
        widgetNames: {
          table: 'dspOrdersTabLineItems'
        },
        metadataToReplaceKey: 'dsp_line_items_recommendation_data',
        metadataToReplaceWith: (recTypeKey, rest) => ({
          ...rest,
          tableConfig: {
            width: 250,
            ui_component: 'recChip',
            props: {
              isComputedChip: true,
              recTypeKey,
              data: 'dsp_line_items_recommendation_data'
            },
            parser: {
              data: 'parseAndReturnArray'
            },
            columnHeader: {
              ui_component: 'iconWithText',
              props: {
                icon: 'alerts',
                text: 'Recommended Action',
                textClasses:
                  'u-font-size-7 u-font-weight-600 u-color-grey-lighter',
                wrapperClass: 'rec-heading'
              }
            }
          }
        }),
        recCustomComponent: (context) => ({
          REMOVE_AUDIENCE: {
            ui_component: 'customActionPanel',
            props: {
              staticHeaderText: 'Remove audiences',
              widgetsConfig: {
                config: (that) => {
                  // {that} is bound to either the selected actions component
                  return {
                    primaryKey: 'customKey',
                    createPrimaryKeyFrom: [
                      'dsp_remove_outlier_audience_segment_id',
                      'dsp_remove_outlier_audience_line_item_id'
                    ],
                    isMultipleAllowed: true,
                    default: {
                      noRowsRightTable: 'Add audiences to remove in this list',
                      selections: `(${context.rowSelections.length} line item${
                        context.rowSelections.length > 1 ? 's' : ''
                      } selected)`,
                      noRowsLeftTable: 'No line items found',
                      leftBtnLabel: 'Remove audience',
                      rightBtnLabel: 'Cancel'
                    },
                    left: {
                      table: 'left',
                      tabHeader: 'Remove Audiences',
                      meta: {
                        type: 'table',
                        action: 'fetchTableDataExecuteApi',
                        localFilters: [
                          {
                            dimensionName:
                              'dsp_remove_outlier_audience_order_id',
                            dimensionValue: context.entityId
                          },
                          ...context?.rowSelections?.map(
                            ({ data: { dsp_line_items_line_item_id } }) => ({
                              dimensionName:
                                'dsp_remove_outlier_audience_line_item_id',
                              dimensionValue: dsp_line_items_line_item_id
                            })
                          )
                        ]
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          cubeName: 'dsp_remove_outlier_audience_segment',
                          getLatestAvailableInsteadOfRollup: false,
                          timeseriesEnabled: false,
                          pvpenabled: false,
                          yoyenabled: false,
                          measuresList: [
                            'dsp_remove_outlier_audience_segment',
                            'dsp_remove_outlier_audience_line_item_name',
                            'dsp_remove_outlier_audience_line_item_id',
                            'dsp_remove_outlier_audience_profile_id',
                            'dsp_remove_outlier_audience_segment_id'
                          ],
                          groupByDimensionsList: [
                            'dsp_remove_outlier_audience_segment_id',
                            'dsp_remove_outlier_audience_line_item_id'
                          ],
                          orderByList: [],
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'remove_audience_rec',
                          'left'
                        )
                      }
                    },
                    right: {
                      table: 'right',
                      searchKey: 'search',
                      meta: {
                        type: 'table',
                        localFilters: []
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'remove_audience_rec',
                          'right'
                        )
                      }
                    },
                    apply(rows = []) {
                      return new Promise((resolve, reject) => {
                        const payload = rows.map((row) => ({
                          actionPayload: {
                            lineItemId:
                              row.dsp_remove_outlier_audience_line_item_id,
                            profileId:
                              row.dsp_remove_outlier_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemRemoveAudiences',
                            segmentIds: getSegmentIdArray(
                              row.dsp_remove_outlier_audience_segment_id
                            )
                          },
                          viewPayload: {
                            lineItemId:
                              row.dsp_remove_outlier_audience_line_item_id,
                            profileId:
                              row.dsp_remove_outlier_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemRemoveAudiences',
                            segmentIds:
                              row.dsp_remove_outlier_audience_segment_id,
                            audienceName:
                              row.dsp_remove_outlier_audience_segment
                          },
                          actionType: 'dspLineItemRemoveAudiences',
                          widget: 'lineItem',
                          previousEntityValue: null,
                          newEntityValue: null,
                          primaryKey:
                            row.dsp_remove_outlier_audience_line_item_id,
                          actionSource: {
                            pageUrl: window.location.href
                          }
                        }));
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                          .then((response) => {
                            that.$emit('afterAPIActionToCall');
                            resolve(true);
                          })
                          .catch((err) => {
                            that.openSnackbar('Something went wrong.');
                            reject(err);
                          });
                      });
                    }
                  };
                }
              },
              selections: context.rowSelections
            },
            events: {
              afterAPIActionToCall(payload) {
                context.openSnackbar(
                  'Action has been submitted for the selected line items'
                );
                context.switchBlueBar('line item');
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
                context.recommendationFilter('');
                context.handleRecommendation({});
              },
              closePanelMethod() {
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
              }
            }
          },
          OUTLIER_SUPPLY_SOURCE: {
            ui_component: 'customActionPanel',
            props: {
              staticHeaderText: 'Remove supply sources',
              widgetsConfig: {
                config: (that) => {
                  // {that} is bound to either the selected actions component
                  return {
                    primaryKey: 'customKey',
                    createPrimaryKeyFrom: [
                      'dsp_outlier_supply_source_to_remove_ss_id',
                      'dsp_outlier_supply_source_to_remove_line_item_id'
                    ],
                    isMultipleAllowed: true,
                    default: {
                      noRowsRightTable:
                        'Add supply sources to remove in this list',
                      selections: `(${context.rowSelections.length} line item${
                        context.rowSelections.length > 1 ? 's' : ''
                      } selected)`,
                      noRowsLeftTable: 'No supply sources found',
                      leftBtnLabel: 'Remove supply source',
                      rightBtnLabel: 'Cancel'
                    },
                    left: {
                      table: 'left',
                      tabHeader: 'Remove Supply Sources',
                      meta: {
                        type: 'table',
                        action: 'fetchTableDataExecuteApi',
                        localFilters: [
                          {
                            dimensionName:
                              'dsp_outlier_supply_source_to_remove_order_id',
                            dimensionValue: context.entityId
                          },
                          ...context?.rowSelections?.map(
                            ({ data: { dsp_line_items_line_item_id } }) => ({
                              dimensionName:
                                'dsp_outlier_supply_source_to_remove_line_item_id',
                              dimensionValue: dsp_line_items_line_item_id
                            })
                          )
                        ]
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          cubeName: 'dsp_outlier_supply_source_to_remove',
                          getLatestAvailableInsteadOfRollup: false,
                          timeseriesEnabled: false,
                          pvpenabled: false,
                          yoyenabled: false,
                          measuresList: [
                            'dsp_outlier_supply_source_to_remove_ss_name',
                            'dsp_outlier_supply_source_to_remove_line_item_name',
                            'dsp_outlier_supply_source_to_remove_ss_id',
                            'dsp_outlier_supply_source_to_remove_profile_id'
                          ],
                          groupByDimensionsList: [
                            'dsp_outlier_supply_source_to_remove_ss_id',
                            'dsp_outlier_supply_source_to_remove_line_item_id'
                          ],
                          orderByList: [],
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'line_items_remove_supply_source',
                          'left'
                        )
                      }
                    },
                    right: {
                      table: 'right',
                      searchKey: 'search',
                      meta: {
                        type: 'table',
                        localFilters: []
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'line_items_remove_supply_source',
                          'right'
                        )
                      }
                    },
                    apply(rows = []) {
                      return new Promise((resolve, reject) => {
                        const payload = rows.map((row) => ({
                          actionPayload: {
                            lineItemId:
                              row.dsp_outlier_supply_source_to_remove_line_item_id,
                            profileId:
                              row.dsp_outlier_supply_source_to_remove_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemRemoveInventorySources',
                            supplySources: [
                              row.dsp_outlier_supply_source_to_remove_ss_id
                            ]
                          },
                          viewPayload: {
                            lineItemId:
                              row.dsp_outlier_supply_source_to_remove_line_item_id,
                            profileId:
                              row.dsp_outlier_supply_source_to_remove_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemRemoveInventorySources',
                            supplySources:
                              row.dsp_outlier_supply_source_to_remove_ss_id,
                            sourceName:
                              row.dsp_outlier_supply_source_to_remove_ss_name
                          },
                          actionType: 'dspLineItemRemoveInventorySources',
                          widget: 'lineItem',
                          previousEntityValue: null,
                          newEntityValue: null,
                          primaryKey:
                            row.dsp_outlier_supply_source_to_remove_line_item_id,
                          actionSource: {
                            pageUrl: window.location.href
                          }
                        }));
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                          .then((response) => {
                            that.$emit('afterAPIActionToCall');
                            resolve(true);
                          })
                          .catch((err) => {
                            that.openSnackbar('Something went wrong.');
                            reject(err);
                          });
                      });
                    }
                  };
                }
              },
              selections: context.rowSelections
            },
            events: {
              afterAPIActionToCall(payload) {
                context.openSnackbar(
                  'Action has been submitted for the selected line items'
                );
                context.switchBlueBar('line item');
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
                context.recommendationFilter('');
                context.handleRecommendation({});
              },
              closePanelMethod() {
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
              }
            }
          },
          TARGET_NEW_AUDIENCE: {
            ui_component: 'customActionPanel',
            props: {
              staticHeaderText: 'Add audiences',
              widgetsConfig: {
                config: (that) => {
                  // {that} is bound to either the selected actions component
                  return {
                    primaryKey: 'customKey',
                    createPrimaryKeyFrom: [
                      'dsp_tna_finalised_audience_segment_id',
                      'dsp_tna_finalised_audience_line_item_id'
                    ],
                    isMultipleAllowed: true,
                    default: {
                      noRowsRightTable: 'Add audiences to this list',
                      selections: `(${context.rowSelections.length} line item${
                        context.rowSelections.length > 1 ? 's' : ''
                      } selected)`,
                      noRowsLeftTable: 'No line items found',
                      leftBtnLabel: 'Add audience',
                      rightBtnLabel: 'Cancel'
                    },
                    left: {
                      table: 'left',
                      tabHeader: 'Add Audiences',
                      meta: {
                        type: 'table',
                        action: 'fetchTableDataExecuteApi',
                        localFilters: [
                          {
                            dimensionName:
                              'dsp_tna_finalised_audience_order_id',
                            dimensionValue: context.entityId
                          },
                          ...context?.rowSelections?.map(
                            ({ data: { dsp_line_items_line_item_id } }) => ({
                              dimensionName:
                                'dsp_tna_finalised_audience_line_item_id',
                              dimensionValue: dsp_line_items_line_item_id
                            })
                          )
                        ]
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          cubeName: 'dsp_tna_finalised_audience',
                          getLatestAvailableInsteadOfRollup: false,
                          timeseriesEnabled: false,
                          pvpenabled: false,
                          yoyenabled: false,
                          measuresList: [
                            'dsp_tna_finalised_audience_segment',
                            'dsp_tna_finalised_audience_line_item_name',
                            'dsp_tna_finalised_audience_line_item_id',
                            'dsp_tna_finalised_audience_segment_id',
                            'dsp_tna_finalised_audience_profile_id'
                          ],
                          groupByDimensionsList: [
                            'dsp_tna_finalised_audience_segment_id',
                            'dsp_tna_finalised_audience_line_item_id'
                          ],
                          orderByList: [],
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'add_audience_rec',
                          'left'
                        )
                      }
                    },
                    right: {
                      table: 'right',
                      searchKey: 'search',
                      meta: {
                        type: 'table',
                        localFilters: []
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'add_audience_rec',
                          'right'
                        )
                      }
                    },
                    apply(rows = []) {
                      return new Promise((resolve, reject) => {
                        const payload = rows.map((row) => ({
                          actionPayload: {
                            lineItemId:
                              row.dsp_tna_finalised_audience_line_item_id,
                            profileId:
                              row.dsp_tna_finalised_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemAddAudiences',
                            segmentIds: getSegmentIdArray(
                              row.dsp_tna_finalised_audience_segment_id
                            )
                          },
                          viewPayload: {
                            lineItemId:
                              row.dsp_tna_finalised_audience_line_item_id,
                            profileId:
                              row.dsp_tna_finalised_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemAddAudiences',
                            segmentIds:
                              row.dsp_tna_finalised_audience_segment_id,
                            audienceName: row.dsp_tna_finalised_audience_segment
                          },
                          actionType: 'dspLineItemAddAudiences',
                          widget: 'lineItem',
                          previousEntityValue: null,
                          newEntityValue: null,
                          primaryKey:
                            row.dsp_tna_finalised_audience_line_item_id,
                          actionSource: {
                            pageUrl: window.location.href
                          }
                        }));
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                          .then((response) => {
                            that.$emit('afterAPIActionToCall');
                            resolve(true);
                          })
                          .catch((err) => {
                            that.openSnackbar('Something went wrong.');
                            reject(err);
                          });
                      });
                    }
                  };
                }
              },
              selections: context.rowSelections
            },
            events: {
              afterAPIActionToCall(payload) {
                context.openSnackbar(
                  'Action has been submitted for the selected line items'
                );
                context.switchBlueBar('line item');
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
                context.recommendationFilter('');
                context.handleRecommendation({});
              },
              closePanelMethod() {
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
              }
            }
          },
          UNDERPERFORMING_LINE_ITEMS: {
            ui_component: 'ApproveRec',
            props: {
              config: {
                heading: 'APPROVE RECOMMENDATIONS',
                'sub-heading': `${context.rowSelections.length} recommendation${
                  context.rowSelections.length > 1 ? 's' : ''
                } selected`,
                leftBtnLabel: 'Confirm and approve',
                rightBtnLabel: 'Cancel',
                message: 'On approval, recommended action will be initiated.',
                apply: () => {
                  const payloadArray = context.rowSelections.map((item) => {
                    const actionPayload = {
                      lineItemId: item.data.dsp_line_items_line_item_id,
                      profileId: item.data.dsp_line_items_profile_id,
                      deliveryActivationStatus: 'INACTIVE',
                      clientName: window.clientName,
                      actionType: 'dspLineItemStatusChange'
                    };
                    return {
                      actionPayload,
                      actionType: 'dspLineItemStatusChange',
                      viewPayload: {
                        ...actionPayload,
                        previousStatus:
                          item.data.dsp_line_items_delivery_activation_status &&
                          upperCase(
                            item.data.dsp_line_items_delivery_activation_status
                          )
                      },
                      widget: 'lineItem',
                      previousEntityValue:
                        item.data.dsp_line_items_delivery_activation_status &&
                        upperCase(
                          item.data.dsp_line_items_delivery_activation_status
                        ),
                      newEntityValue: 'INACTIVE',
                      primaryKey: item.data.dsp_line_items_line_item_id,
                      actionSource: {
                        pageUrl: window.location.href
                      }
                    };
                  });
                  HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                    .then((response) => {
                      context.openSnackbar(
                        'Action has been submitted for the selected line items'
                      );
                      context.switchBlueBar('line item');
                      context.showRecCustomComponent = false;
                      context.handleSelectionChange([]);
                      context.recommendationFilter('');
                      context.handleRecommendation({});
                    })
                    .catch((err) => {
                      console.log(err);
                      context.openSnackbar('Something went wrong.');
                    });
                },
                handleCancel: () => {
                  context.showRecCustomComponent = false;
                  context.handleSelectionChange([]);
                }
              }
            }
          },
          IMPROVE_ORDER_PACING_BASE_BID: {
            ...(context?.rowSelections?.length === 1
              ? {
                  ui_component: 'ApproveRec',
                  props: {
                    config: {
                      heading: 'EDIT RECOMMENDED ACTION',
                      'sub-heading': '1 recommendation selected',
                      customComponent: {
                        ui_component: 'editBid',
                        props: {
                          config: (vueRef) => ({
                            heading: 'Edit line item base bid',
                            'sub-heading': '1 line item selected',
                            namelabel: 'Line Item',
                            nameValue:
                              context?.rowSelections?.[0]?.data
                                ?.dsp_line_items_line_item_name,
                            inputLabel: 'Line item Base Bid',
                            inputValue: extractBid(
                              context?.rowSelections?.[0]?.data
                                ?.dsp_line_items_recommendation_data,
                              'rec_action',
                              context?.recFilter?.value
                            )?.next,
                            max: 500000,
                            min: 1,
                            leftBtnLabel: 'Confirm and approve',
                            rightBtnLabel: 'Cancel',
                            apply: () => {
                              const payloadArray = context.rowSelections.map(
                                (item) => {
                                  const actionPayload = {
                                    lineItemId:
                                      item.data.dsp_line_items_line_item_id,
                                    profileId:
                                      item.data.dsp_line_items_profile_id,
                                    baseSupplyBid: vueRef.updatedInputValue,
                                    clientName: window.clientName,
                                    actionType: 'dspLineItemBaseSupplyBidChange'
                                  };
                                  return {
                                    actionPayload,
                                    actionType:
                                      'dspLineItemBaseSupplyBidChange',
                                    viewPayload: {
                                      ...actionPayload,
                                      previousBid: Number(
                                        item?.data?.dsp_line_items_base_bid
                                      )
                                    },
                                    widget: 'lineItem',
                                    previousEntityValue: Number(
                                      item?.data?.dsp_line_items_base_bid
                                    ),
                                    newEntityValue: vueRef.updatedInputValue,
                                    primaryKey:
                                      item.data.dsp_line_items_line_item_id,
                                    actionSource: {
                                      pageUrl: window.location.href
                                    }
                                  };
                                }
                              );
                              HttpService.post(
                                'AMS_ACTIONS_ACTION_LOG',
                                payloadArray
                              )
                                .then((response) => {
                                  context.openSnackbar(
                                    'Action has been submitted for the selected line items'
                                  );
                                  context.switchBlueBar('line item');
                                  context.showRecCustomComponent = false;
                                  context.handleSelectionChange([]);
                                  context.recommendationFilter('');
                                  context.handleRecommendation({});
                                })
                                .catch((err) => {
                                  console.log(err);
                                  context.openSnackbar('Something went wrong.');
                                });
                            },
                            handleCancel: () => {
                              context.showRecCustomComponent = false;
                              context.handleSelectionChange([]);
                            }
                          })
                        }
                      }
                    },
                    disableFooter: true
                  }
                }
              : {
                  ui_component: 'ApproveRec',
                  props: {
                    config: {
                      heading: 'APPROVE RECOMMENDATIONS',
                      'sub-heading': `${
                        context.rowSelections.length
                      } recommendation${
                        context.rowSelections.length > 1 ? 's' : ''
                      } selected`,
                      leftBtnLabel: 'Confirm and approve',
                      rightBtnLabel: 'Cancel',
                      message:
                        'On approval, recommended action will be initiated.',
                      apply: () => {
                        const payloadArray = context.rowSelections.map(
                          (item) => {
                            const actionPayload = {
                              lineItemId: item.data.dsp_line_items_line_item_id,
                              profileId: item.data.dsp_line_items_profile_id,
                              baseSupplyBid: extractBid(
                                item?.data?.dsp_line_items_recommendation_data,
                                'rec_action',
                                context?.recFilter?.value
                              )?.next,
                              clientName: window.clientName,
                              actionType: 'dspLineItemBaseSupplyBidChange'
                            };
                            return {
                              actionPayload,
                              actionType: 'dspLineItemBaseSupplyBidChange',
                              viewPayload: {
                                ...actionPayload,
                                previousBid: Number(
                                  item?.data?.dsp_line_items_base_bid
                                )
                              },
                              widget: 'lineItem',
                              previousEntityValue: Number(
                                item?.data?.dsp_line_items_base_bid
                              ),
                              newEntityValue: extractBid(
                                item?.data?.dsp_line_items_recommendation_data,
                                'rec_action',
                                context?.recFilter?.value
                              )?.next,
                              primaryKey: item.data.dsp_line_items_line_item_id,
                              actionSource: {
                                pageUrl: window.location.href
                              }
                            };
                          }
                        );
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                          .then((response) => {
                            context.openSnackbar(
                              'Action has been submitted for the selected line items'
                            );
                            context.switchBlueBar('line item');
                            context.showRecCustomComponent = false;
                            context.handleSelectionChange([]);
                            context.recommendationFilter('');
                            context.handleRecommendation({});
                          })
                          .catch((err) => {
                            console.log(err);
                            context.openSnackbar('Something went wrong.');
                          });
                      },
                      handleCancel: () => {
                        context.showRecCustomComponent = false;
                        context.handleSelectionChange([]);
                      }
                    }
                  }
                })
          },
          IMPROVE_ORDER_PACING_MAX_BID: {
            ...(context?.rowSelections?.length === 1
              ? {
                  ui_component: 'ApproveRec',
                  props: {
                    config: {
                      heading: 'EDIT RECOMMENDED ACTION',
                      'sub-heading': '1 recommendation selected',
                      customComponent: {
                        ui_component: 'editBid',
                        props: {
                          config: (vueRef) => ({
                            heading: 'Edit line item max bid',
                            'sub-heading': '1 line item selected',
                            namelabel: 'Line Item',
                            nameValue:
                              context?.rowSelections?.[0]?.data
                                ?.dsp_line_items_line_item_name,
                            inputLabel: 'Line item Max Bid',
                            inputValue: extractBid(
                              context?.rowSelections?.[0]?.data
                                ?.dsp_line_items_recommendation_data,
                              'rec_action',
                              context?.recFilter?.value
                            )?.next,
                            max: 500000,
                            min: 1,
                            leftBtnLabel: 'Confirm and approve',
                            rightBtnLabel: 'Cancel',
                            apply: () => {
                              const payloadArray = context.rowSelections.map(
                                (item) => {
                                  const actionPayload = {
                                    lineItemId:
                                      item.data.dsp_line_items_line_item_id,
                                    profileId:
                                      item.data.dsp_line_items_profile_id,
                                    maxSupplyBid: vueRef.updatedInputValue,
                                    clientName: window.clientName,
                                    actionType: 'dspLineItemMaxSupplyBidChange'
                                  };
                                  return {
                                    actionPayload,
                                    actionType: 'dspLineItemMaxSupplyBidChange',
                                    viewPayload: {
                                      ...actionPayload,
                                      previousBid: Number(
                                        item?.data?.dsp_line_items_max_bid
                                      )
                                    },
                                    widget: 'lineItem',
                                    previousEntityValue: Number(
                                      item?.data?.dsp_line_items_max_bid
                                    ),
                                    newEntityValue: vueRef.updatedInputValue,
                                    primaryKey:
                                      item.data.dsp_line_items_line_item_id,
                                    actionSource: {
                                      pageUrl: window.location.href
                                    }
                                  };
                                }
                              );
                              HttpService.post(
                                'AMS_ACTIONS_ACTION_LOG',
                                payloadArray
                              )
                                .then((response) => {
                                  context.openSnackbar(
                                    'Action has been submitted for the selected line items'
                                  );
                                  context.switchBlueBar('line item');
                                  context.showRecCustomComponent = false;
                                  context.handleSelectionChange([]);
                                  context.recommendationFilter('');
                                  context.handleRecommendation({});
                                })
                                .catch((err) => {
                                  console.log(err);
                                  context.openSnackbar('Something went wrong.');
                                });
                            },
                            handleCancel: () => {
                              context.showRecCustomComponent = false;
                              context.handleSelectionChange([]);
                            }
                          })
                        }
                      }
                    },
                    disableFooter: true
                  }
                }
              : {
                  ui_component: 'ApproveRec',
                  props: {
                    config: {
                      heading: 'APPROVE RECOMMENDATIONS',
                      'sub-heading': `${
                        context.rowSelections.length
                      } recommendation${
                        context.rowSelections.length > 1 ? 's' : ''
                      } selected`,
                      leftBtnLabel: 'Confirm and approve',
                      rightBtnLabel: 'Cancel',
                      message:
                        'On approval, recommended action will be initiated.',
                      apply: () => {
                        const payloadArray = context.rowSelections.map(
                          (item) => {
                            const actionPayload = {
                              lineItemId: item.data.dsp_line_items_line_item_id,
                              profileId: item.data.dsp_line_items_profile_id,
                              maxSupplyBid: extractBid(
                                item?.data?.dsp_line_items_recommendation_data,
                                'rec_action',
                                context?.recFilter?.value
                              )?.next,
                              clientName: window.clientName,
                              actionType: 'dspLineItemMaxSupplyBidChange'
                            };
                            return {
                              actionPayload,
                              actionType: 'dspLineItemMaxSupplyBidChange',
                              viewPayload: {
                                ...actionPayload,
                                previousBid: Number(
                                  item?.data?.dsp_line_items_max_bid
                                )
                              },
                              widget: 'lineItem',
                              previousEntityValue: Number(
                                item?.data?.dsp_line_items_max_bid
                              ),
                              newEntityValue: extractBid(
                                item?.data?.dsp_line_items_recommendation_data,
                                'rec_action',
                                context?.recFilter?.value
                              )?.next,
                              primaryKey: item.data.dsp_line_items_line_item_id,
                              actionSource: {
                                pageUrl: window.location.href
                              }
                            };
                          }
                        );
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                          .then((response) => {
                            context.openSnackbar(
                              'Action has been submitted for the selected line items'
                            );
                            context.switchBlueBar('line item');
                            context.showRecCustomComponent = false;
                            context.handleSelectionChange([]);
                            context.recommendationFilter('');
                            context.handleRecommendation({});
                          })
                          .catch((err) => {
                            console.log(err);
                            context.openSnackbar('Something went wrong.');
                          });
                      },
                      handleCancel: () => {
                        context.showRecCustomComponent = false;
                        context.handleSelectionChange([]);
                      }
                    }
                  }
                })
          }
        })
      },
      Creatives: {
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabCreatives: {
            ':widget': 'dspOrdersTabCreatives',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_creatives_order_id',
                dimensionValue: id
              }
            ]
          }
        }),
        components: {
          dspOrdersTabCreatives: dashboardServiceTable
        },
        headerOptions: (context, widgetName) => ({
          [widgetName]: {
            props: {
              enableDownloadWithText: !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(context.rowSelections.length
                  ? { padding: 0 }
                  : {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    })
              },
              searchParams: {
                show: true,
                placeholder: 'Search',
                searchDimension: 'dsp_creatives_creative_name'
              },
              gridOptions: context.gridOptions,
              rowHeight: 45
            }
          }
        })
      },
      Products: {
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabProducts: {
            ':widget': 'dspOrdersTabProducts',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_products_order_id',
                dimensionValue: id
              },
              {
                dimensionName: 'dsp_products_asin_conversion_type',
                dimensionValue: 'promoted',
                operator: 'ILIKE'
              }
            ]
          }
        }),
        components: {
          dspOrdersTabProducts: dashboardServiceTable,
          splitTabs
        },
        switch: {
          Promoted: {
            dimensionName: 'dsp_products_asin_conversion_type',
            dimensionValue: 'promoted',
            operator: 'ILIKE'
          },
          'Brand Halo': {
            dimensionName: 'dsp_products_asin_conversion_type',
            dimensionValue: 'brand halo',
            operator: 'ILIKE'
          },
          tabsList: ['Promoted', 'Brand Halo']
        },
        widgetNames: {
          table: 'dspOrdersTabProducts'
        },
        headerOptions: (context, widgetName) => ({
          [widgetName]: {
            props: {
              headerOptions: [],
              enableDownloadWithText: !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(context.rowSelections.length
                  ? { padding: 0 }
                  : {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    })
              },
              searchParams: {
                show: true,
                placeholder: 'Search',
                searchDimension: 'search'
              },
              gridOptions: context.gridOptions,
              rowHeight: 80
            }
          }
        })
      },
      Audiences: {
        recommendationKey: 'dsp_line_items_recommendation_data',
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabAudience: {
            ':widget': 'dspOrdersTabAudience',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_audience_order_id',
                dimensionValue: id
              },
              {
                dimensionName: 'dsp_audience_targetting_method',
                dimensionValue: 'behavioral'
              }
            ]
          },
          dspTopRecommendationsOrdersTabAudience: {
            ':widget': 'dspTopRecommendationsOrdersTabAudience',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName:
                  'dsp_orders_tab_audience_recommendation_metadata_order_id',
                dimensionValue: id
              }
            ]
          }
        }),
        components: {
          dspOrdersTabAudience: dashboardServiceTable,
          dspTopRecommendationsOrdersTabAudience: dspRecommendations,
          actionsBar,
          customActionPanel,
          splitTabs
        },
        switch: {
          Targeted: {
            dimensionName: 'dsp_audience_targetting_method',
            dimensionValue: 'behavioral'
          },
          Untargeted: {
            dimensionName: 'dsp_audience_targetting_method',
            dimensionValue: 'untargeted'
          },
          tabsList: ['Targeted', 'Untargeted']
        },
        widgetNames: {
          table: 'dspOrdersTabAudience'
        },
        preferNoRecFilterList: ['TARGET_NEW_AUDIENCE'],
        headerOptions: (context, widgetName) => ({
          dspOrdersTabAudience: {
            props: {
              headerOptions: [
                ...(!context.rowSelections.length
                  ? []
                  : [
                      {
                        ui_component: 'actionsBar',
                        props: {
                          actionBarActions: [
                            {
                              icon: 'add-circle-outline',
                              title: 'Add audience to line items',
                              id: 'add_audience',
                              customState: {
                                component: 'customActionPanel',
                                props: {
                                  staticHeaderText:
                                    'Add selected audience to line items',
                                  widgetsConfig: {
                                    config: (that) => {
                                      // {that} is bound to either the selected actions component
                                      return {
                                        primaryKey:
                                          'dsp_line_item_audience_line_item_id',
                                        isMultipleAllowed: false,
                                        default: {
                                          noRowsRightTable:
                                            'Add audiences in this list',
                                          selections: `(${context.rowSelections.length} audience selected)`,
                                          noRowsLeftTable:
                                            'No line items found',
                                          leftBtnLabel:
                                            'Add audience to line items',
                                          rightBtnLabel: 'Cancel',
                                          warningMessage:
                                            'Multiple audiences are not allowed'
                                        },
                                        left: {
                                          table: 'left',
                                          tabHeader: 'LINE ITEMS',
                                          search: {
                                            searchKey:
                                              'dsp_line_item_audience_line_item_name'
                                          },
                                          meta: {
                                            type: 'table',
                                            action: 'fetchTableDataExecuteApi',
                                            localFilters: [
                                              {
                                                dimensionName:
                                                  'dsp_line_item_audience_order_id',
                                                dimensionValue: context.entityId
                                              }
                                            ]
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              cubeName:
                                                'dsp_line_item_audience_workbench',
                                              getLatestAvailableInsteadOfRollup: false,
                                              timeseriesEnabled: false,
                                              pvpenabled: false,
                                              yoyenabled: false,
                                              measuresList: [
                                                'dsp_line_item_audience_line_item_name'
                                              ],
                                              groupByDimensionsList: [
                                                'dsp_line_item_audience_line_item_id',
                                                'dsp_line_item_audience_order_id',
                                                'dsp_line_item_audience_profile_id'
                                              ],
                                              orderByList: [],
                                              limit: 10,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_audience',
                                              'left'
                                            )
                                          }
                                        },
                                        right: {
                                          table: 'right',
                                          searchKey: 'search',
                                          meta: {
                                            type: 'table',
                                            action: 'fetchTableDataExecuteApi',
                                            localFilters: [
                                              {
                                                dimensionName:
                                                  'dsp_line_item_audience_order_id',
                                                dimensionValue: context.entityId
                                              },
                                              {
                                                dimensionName:
                                                  'dsp_line_item_audience_segment_id',
                                                dimensionValue:
                                                  context.rowSelections[0].data
                                                    .dsp_audience_segment_id
                                              }
                                            ]
                                          },
                                          footer: {
                                            show: false
                                          },
                                          body: {
                                            APIConfig: {
                                              cubeName:
                                                'dsp_line_item_audience_workbench',
                                              getLatestAvailableInsteadOfRollup: false,
                                              timeseriesEnabled: false,
                                              pvpenabled: false,
                                              yoyenabled: false,
                                              measuresList: [
                                                'dsp_line_item_audience_line_item_name',
                                                'dsp_line_item_audience_line_item_id',
                                                'dsp_line_item_audience_segment_id',
                                                'dsp_line_item_audience_segment'
                                              ],
                                              groupByDimensionsList: [
                                                'dsp_line_item_audience_segment',
                                                'dsp_line_item_audience_order_id',
                                                'dsp_line_item_audience_profile_id',
                                                'dsp_line_item_audience_segment_id'
                                              ],
                                              orderByList: [],
                                              limit: 500,
                                              page: 1
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_audience',
                                              'right'
                                            )
                                          }
                                        },
                                        apply(rows = []) {
                                          return new Promise(
                                            (resolve, reject) => {
                                              const payload = rows.map(
                                                (row) => ({
                                                  actionPayload: {
                                                    lineItemId:
                                                      row.dsp_line_item_audience_line_item_id,
                                                    profileId:
                                                      row.dsp_line_item_audience_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemAddAudiences',
                                                    segmentIds:
                                                      getSegmentIdArray(
                                                        context.rowSelections[0]
                                                          .data
                                                          .dsp_audience_segment_id
                                                      )
                                                  },
                                                  viewPayload: {
                                                    lineItemId:
                                                      row.dsp_line_item_audience_line_item_id,
                                                    profileId:
                                                      row.dsp_line_item_audience_profile_id,
                                                    clientName:
                                                      window.clientName,
                                                    actionType:
                                                      'dspLineItemAddAudiences',
                                                    segmentIds:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_audience_segment_id,
                                                    audienceName:
                                                      context.rowSelections[0]
                                                        .data
                                                        .dsp_audience_segment
                                                  },
                                                  actionType:
                                                    'dspLineItemAddAudiences',
                                                  widget: 'lineItem',
                                                  previousEntityValue: null,
                                                  newEntityValue: null,
                                                  primaryKey:
                                                    row.dsp_line_item_audience_line_item_id,
                                                  actionSource: {
                                                    pageUrl:
                                                      window.location.href
                                                  }
                                                })
                                              );
                                              HttpService.post(
                                                'AMS_ACTIONS_ACTION_LOG',
                                                payload
                                              )
                                                .then((response) => {
                                                  that.$emit(
                                                    'afterAPIActionToCall'
                                                  );
                                                  resolve(true);
                                                })
                                                .catch((err) => {
                                                  that.openSnackbar(
                                                    'Something went wrong.'
                                                  );
                                                  reject(err);
                                                });
                                            }
                                          );
                                        }
                                      };
                                    }
                                  }
                                },
                                events: {
                                  afterAPIActionToCall(payload) {
                                    this.$emit(
                                      'openSnackbar',
                                      'Action has been submitted for the selected line items'
                                    );
                                    this.switchBlueBar('line item');
                                    this.removeCustomCustomComponents();
                                    this.closeActionsWorkflow();
                                  },
                                  closePanelMethod() {
                                    this.removeCustomCustomComponents();
                                  }
                                }
                              }
                            }
                          ],
                          isCustomActionStateVisible:
                            context.isCustomActionStateVisible,
                          selections: context.rowSelections,
                          blacklistedActionsForMultiSelections: [
                            'Add audience to line items'
                          ]
                        },
                        events: {
                          deselectSelections: (selections) => {
                            // this is on x click in actions bar;
                            context.handleSelectionChange(selections, true);
                          },
                          openSnackbar: context.openSnackbar,
                          toggleCustomActionState:
                            context.toggleCustomActionState,
                          switchBlueBar: context.switchBlueBar
                        }
                      }
                    ])
              ],
              enableDownloadWithText: !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(context.rowSelections.length
                  ? { padding: 0 }
                  : {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    })
              },
              searchParams: {
                show: !context.rowSelections.length,
                placeholder: 'Search',
                searchDimension: 'dsp_audience_segment'
              },
              gridOptions: context.gridOptions,
              rowSelection: {
                mode: 'multiple',
                onSelectionChanged: context.handleSelectionChange
              },
              rowHeight: 45,
              columnKeyMapping: context.columnKeyMapping
            }
          },
          dspTopRecommendationsOrdersTabAudience: {
            props: {
              footerOptions: [],
              showLastUpdatedDate: false,
              entityName: 'line item',
              countKey: 'dsp_orders_tab_audience_recommendation_metadata_count',
              labelKey:
                'dsp_orders_tab_audience_recommendation_metadata_rec_label',
              recCustomComponent:
                (context.showRecCustomComponent &&
                  context.recCustomComponent(context)[
                    context?.recFilter?.value
                  ]) ||
                {},
              selectedRecommendation: context.selectedRecommendation,
              isCustomActionStateVisible: context.isCustomActionStateVisible
            },
            events: {
              recommendationFilter: context.recommendationFilter,
              handleRecommendation: context.handleRecommendation,
              handleSelectionChange: context.handleSelectionChange,
              toggleRecCustomComponent: context.toggleRecCustomComponent
            }
          }
        }),
        metadataToReplaceKey: 'dsp_line_items_recommendation_data',
        metadataToReplaceWith: (recTypeKey, rest) => ({
          ...rest,
          tableConfig: {
            width: 250,
            ui_component: 'recChip',
            props: {
              isComputedChip: true,
              recTypeKey,
              data: 'dsp_line_items_recommendation_data'
            },
            parser: {
              data: 'parseAndReturnArray'
            },
            columnHeader: {
              ui_component: 'iconWithText',
              props: {
                icon: 'alerts',
                text: 'Recommended Action',
                textClasses:
                  'u-font-size-7 u-font-weight-600 u-color-grey-lighter',
                wrapperClass: 'rec-heading'
              }
            }
          }
        }),
        recCustomComponent: (context) => ({
          TARGET_NEW_AUDIENCE: {
            ui_component: 'customActionPanel',
            props: {
              staticHeaderText: 'Add audiences',
              widgetsConfig: {
                config: (that) => {
                  // {that} is bound to either the selected actions component
                  return {
                    primaryKey: 'customKey',
                    createPrimaryKeyFrom: [
                      'dsp_tna_finalised_audience_segment_id',
                      'dsp_tna_finalised_audience_line_item_id'
                    ],
                    isMultipleAllowed: true,
                    default: {
                      noRowsRightTable: 'Add audiences to remove in this list',
                      selections: '',
                      noRowsLeftTable: 'No line items found',
                      leftBtnLabel: 'Add audience',
                      rightBtnLabel: 'Cancel'
                    },
                    left: {
                      table: 'left',
                      tabHeader: 'Add Audiences',
                      meta: {
                        type: 'table',
                        action: 'fetchTableDataExecuteApi',
                        localFilters: [
                          {
                            dimensionName:
                              'dsp_tna_finalised_audience_order_id',
                            dimensionValue: context.entityId
                          }
                        ]
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          cubeName: 'dsp_tna_finalised_audience',
                          getLatestAvailableInsteadOfRollup: false,
                          timeseriesEnabled: false,
                          pvpenabled: false,
                          yoyenabled: false,
                          measuresList: [
                            'dsp_tna_finalised_audience_segment',
                            'dsp_tna_finalised_audience_line_item_name',
                            'dsp_tna_finalised_audience_line_item_id',
                            'dsp_tna_finalised_audience_segment_id',
                            'dsp_tna_finalised_audience_profile_id'
                          ],
                          groupByDimensionsList: [
                            'dsp_tna_finalised_audience_segment_id',
                            'dsp_tna_finalised_audience_line_item_id'
                          ],
                          orderByList: [],
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'add_audience_rec',
                          'left'
                        )
                      }
                    },
                    right: {
                      table: 'right',
                      searchKey: 'search',
                      meta: {
                        type: 'table',
                        localFilters: []
                      },
                      footer: {
                        show: false
                      },
                      body: {
                        APIConfig: {
                          limit: 10,
                          page: 1
                        },
                        columnDefs: getColumnDefinition(
                          that,
                          'add_audience_rec',
                          'right'
                        )
                      }
                    },
                    apply(rows = []) {
                      return new Promise((resolve, reject) => {
                        const payload = rows.map((row) => ({
                          actionPayload: {
                            lineItemId:
                              row.dsp_tna_finalised_audience_line_item_id,
                            profileId:
                              row.dsp_tna_finalised_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemAddAudiences',
                            segmentIds: getSegmentIdArray(
                              row.dsp_tna_finalised_audience_segment_id
                            )
                          },
                          viewPayload: {
                            lineItemId:
                              row.dsp_tna_finalised_audience_line_item_id,
                            profileId:
                              row.dsp_tna_finalised_audience_profile_id,
                            clientName: window.clientName,
                            actionType: 'dspLineItemAddAudiences',
                            segmentIds:
                              row.dsp_tna_finalised_audience_segment_id,
                            audienceName: row.dsp_tna_finalised_audience_segment
                          },
                          actionType: 'dspLineItemAddAudiences',
                          widget: 'lineItem',
                          previousEntityValue: null,
                          newEntityValue: null,
                          primaryKey:
                            row.dsp_tna_finalised_audience_line_item_id,
                          actionSource: {
                            pageUrl: window.location.href
                          }
                        }));
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                          .then((response) => {
                            that.$emit('afterAPIActionToCall');
                            resolve(true);
                          })
                          .catch((err) => {
                            that.openSnackbar('Something went wrong.');
                            reject(err);
                          });
                      });
                    }
                  };
                }
              },
              selections: null
            },
            events: {
              afterAPIActionToCall(payload) {
                context.openSnackbar(
                  'Action has been submitted for the selected line items'
                );
                context.switchBlueBar('line item');
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
                context.recommendationFilter('');
                context.handleRecommendation({});
                context.selectedRecommendation = {};
              },
              closePanelMethod() {
                context.showRecCustomComponent = false;
                context.handleSelectionChange([]);
                context.selectedRecommendation = {};
              }
            }
          }
        })
      },
      Inventory: {
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabInventory: {
            ':widget': 'dspOrdersTabInventory',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_inventory_order_id',
                dimensionValue: id
              }
            ]
          }
        }),
        components: {
          dspOrdersTabInventory: dashboardServiceTable
        },
        headerOptions: (context, widgetName) => ({
          [widgetName]: {
            props: {
              enableDownloadWithText: !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(context.rowSelections.length
                  ? { padding: 0 }
                  : {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    })
              },
              searchParams: {
                show: true,
                placeholder: 'Search',
                searchDimension: 'dsp_inventory_supply_source'
              },
              gridOptions: context.gridOptions,
              rowHeight: 45
            }
          }
        })
      },
      Devices: {
        widgetConfig: (initRequestParams, id) => ({
          ...initRequestParams,
          dspOrdersTabDevices: {
            ':widget': 'dspOrdersTabDevices',
            ':orderByList': [],
            ':dimensionNameValueList': [
              {
                dimensionName: 'dsp_devices_order_id',
                dimensionValue: id
              }
            ]
          }
        }),
        components: {
          dspOrdersTabDevices: dashboardServiceTable
        },
        headerOptions: (context, widgetName) => ({
          [widgetName]: {
            props: {
              enableDownloadWithText: !context.rowSelections.length,
              enableManageColumns: !context.rowSelections.length,
              headerContainerGridStyles: {
                ...(context.rowSelections.length
                  ? { padding: 0 }
                  : {
                      'grid-template-columns': '1fr auto auto auto',
                      'grid-gap': '0rem'
                    })
              },
              searchParams: {
                show: true,
                placeholder: 'Search',
                searchDimension: 'dsp_devices_device_type'
              },
              gridOptions: context.gridOptions,
              rowHeight: 45
            }
          }
        })
      }
    }
  }
};
