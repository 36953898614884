<template>
  <a
    v-if="urlComputed"
    :class="styleClass"
    :href="urlComputed"
    target="_blank"
  >
    {{ text }}
  </a>
  <div v-else>
    {{ formatter(text) }}
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  name: 'RbLink',
  props: {
    styleClass: {
      type: String,
      default: 'u-spacing-m-s link--asin'
    },
    text: {
      type: [String, Number, undefined],
      default: 'Default'
    },
    url: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    urlBuilder: {
      type: Function
    }
  },
  data() {
    return {
      formatter
    };
  },
  computed: {
    urlComputed() {
      let url;
      if (!this.text) {
        return '';
      }
      if (this.urlBuilder) {
        url = this.urlBuilder(this.text);
      } else {
        url = this.url;
      }
      return url;
    }
  }
};
</script>

<style></style>
