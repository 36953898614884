<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('localized_inventory') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('high_inventory_of') }} {{ alert.attributes.est_inv_units }}
      {{ $t('units_at') }} {{ alert.attributes.fc_location }}
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('appeared_on_')
      }}{{ formatter(alert.attributes.last_appeared_on, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('inbound_units')"
          :item-value="alert.attributes.units_inbound"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('outbound_units')"
          :item-value="alert.attributes.units_outbound"
        />
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('surplus_cogs')"
          :item-value="formatter(alert.attributes.surplus_cogs, 'currency')"
        />
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-m">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('revenue')"
          :item-value="
            formatter(alert.attributes.last_2weeks_revenue, 'currency')
          "
        />
        <span class="u-font-size-7 u-color-grey-lighter"
          >({{ $t('last_2_weeks') }})</span
        >
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('shipment_details')"
          :item-value="
            formatter(alert.attributes.shipment_details_date, 'date') +
            ' : ' +
            formatter(alert.attributes.shipment_details_units, 'string') +
            ' ' +
            $t('units')
          "
        />
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-m u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.rating || 0)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.rating || 'NA' }} {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.reviews || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max):
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('price_compression_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{ $t('localized_inv_rec_text') }}
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          tooltip_format: {
            All: {
              roundoff: 0
            }
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    console.log(this.alert);
    var xs = {
      'Outbound Units': 'Outbound Trend_x',
      'Inbound Units': 'Inbound Trend_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    if (this.alert.attributes.outbound_units_trend) {
      var outboundTrend = JSON.parse(
        this.alert.attributes.outbound_units_trend
      ).sort((a, b) => {
        if (a.Date > b.Date) return -1;
        else if (a.Date < b.Date) return 1;
        return 0;
      });

      if (outboundTrend.length) {
        dataArray = ['Outbound Units'];
        dateArray = ['Outbound Trend_x'];
        for (entry of outboundTrend) {
          if (entry.units_shipped === undefined) continue;
          dataArray.push(entry.units_shipped);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    if (this.alert.attributes.inbound_units_trend) {
      var inboundTrend = JSON.parse(
        this.alert.attributes.inbound_units_trend
      ).sort((a, b) => {
        if (a.Date > b.Date) return -1;
        else if (a.Date < b.Date) return 1;
        return 0;
      });

      if (inboundTrend.length) {
        dataArray = ['Inbound Units'];
        dateArray = ['Inbound Trend_x'];
        for (entry of inboundTrend) {
          if (entry.units_shipped === undefined) continue;
          dataArray.push(entry.units_shipped);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
