// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// name property in the single file components.

import Vue from 'vue';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  '.',
  // Don't/Do (false, true) look in subdirectories
  true,
  // Only include .vue files
  /[\w-]+\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName).default;
  // Get the component name
  const componentName = componentConfig.name;
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});
