<template>
  <section>
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pt-m u-spacing-pb-m u-cursor-pointer"
      :class="getWrapperClass()"
      @click="expand"
    >
      <span class="u-font-size-4 u-font-weight-600 u-spacing-pl-xs">{{
        healthGroup.health_group_name
      }}</span>
      <div class="u-spacing-ml-auto">
        <rb-icon
          class="rb-icon--medium u-spacing-mr-m u-line-height-100"
          :icon="statusMap[healthGroup.status].icon"
          :class="`u-color-${statusMap[healthGroup.status].color}-base`"
        />
        <rb-icon
          class="rb-icon--medium u-color-grey-lighter u-spacing-mt-xxs"
          :class="{ 'rotate-icon': expandMap[healthGroup.health_group_id] }"
          :icon="'arrow1-down'"
        />
      </div>
    </div>

    <div
      v-if="expandMap[healthGroup.health_group_id]"
      class="u-spacing-pv-s"
    >
      <div
        v-for="(section, j) in healthGroup.checks"
        :key="j"
        class="u-spacing-pv-s"
      >
        <div>
          <div class="u-display-flex u-flex-justify-content-space-between">
            <div class="u-display-flex u-flex-align-items-center">
              <rb-icon
                class="rb-icon--medium u-spacing-mr-s u-line-height-50 u-spacing-mt-xs u-flex-align-self-baseline"
                :icon="statusMap[section.status].icon"
                :class="`u-color-${statusMap[section.status].color}-base`"
              />
              <span
                class="u-font-size-5 u-line-height-1-5 u-word-break-break-word"
              >
                {{
                  section.status === 'FAILED'
                    ? section.error_message
                    : section.success_message
                }}
                <span
                  v-if="
                    healthGroup.status === 'FAILED' &&
                    section.impacted_areas.length
                  "
                >
                  <div
                    v-tippy="viewMetricsTippyOptions"
                    class="u-font-weight-600 u-text-decoration-underline u-cursor-pointer .u-word-break-break-word"
                  >
                    View Impact
                  </div>
                  <div :id="viewMetricsId">
                    <div class="u-spacing-p-m u-position-relative">
                      <span class="u-font-weight-600">Impacted Areas</span>
                      <ul
                        class="u-font-weight-100 u-spacing-pt-m system-transparency-drawer-impact-areas u-spacing-pl-m"
                      >
                        <li
                          v-for="(area, k) in section.impacted_areas"
                          :key="k"
                          class="u-spacing-pb-s"
                        >
                          {{
                            area.impacted_area
                              ? area.impacted_area
                              : 'Impact area'
                          }}<span v-if="area.date_ranges.length">
                            ({{ formatDateRanges(area.date_ranges) }})
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </span>
            </div>
            <span
              class="u-color-grey-mid-light u-font-size-7 u-spacing-mt-xs u-white-space-no-wrap u-spacing-ml-s"
              >{{ formatTimeDifference(section.created_at) }}</span
            >
          </div>
          <div
            class="u-display-flex u-flex-justify-content-space-between u-spacing-pl-s u-spacing-mt-s u-spacing-ml-m"
          >
            <span
              v-if="section.status === 'FAILED'"
              class="u-font-size-5 u-font-weight-600"
              >Expected Resolution:
              <span class="u-font-weight-normal">
                within {{ section.eta ? section.eta : '12 hrs' }}
              </span></span
            >
            <span
              v-if="section.additional_info_for_clarity"
              class="u-spacing-ml-auto"
            >
              <span
                v-tippy="learnMoreTippyOptions"
                class="u-font-size-5 u-spacing-ml-auto u-color-blue-base u-cursor-pointer"
                >Learn More</span
              >
              <div
                :id="learnMoreId"
                class="u-bg-grey-wite u-spacing-p-m u-font-weight-100 u-line-height-1-5 u-word-break-break-word"
              >
                {{
                  section.additional_info_for_clarity
                    ? section.additional_info_for_clarity
                    : ''
                }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Loader from '@/components/basic/loader';
import {
  STATUS_MAP,
  formatDateRanges,
  formatTimeDifference
} from '@/components/widgets/system-transparency/system-transparency-utils.js';
export default {
  components: {
    Loader
  },
  props: {
    expandMap: {
      type: Object,
      default: () => {
        return {};
      }
    },
    healthGroup: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    const learnMoreId = `learn-more-tooltip-${this.healthGroup.health_group_id}`;
    const viewMetricsId = `view-metrics-tooltip-${this.healthGroup.health_group_id}`;
    return {
      statusMap: STATUS_MAP,
      learnMoreId,
      viewMetricsId,
      learnMoreTippyOptions: {
        html: `#${learnMoreId}`,
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        placement: 'top-end',
        maxWidth: '250px'
      },
      viewMetricsTippyOptions: {
        html: `#${viewMetricsId}`,
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        placement: 'center',
        maxWidth: 'auto'
      }
    };
  },
  methods: {
    expand() {
      this.$emit('expand', this.healthGroup.health_group_id);
    },
    formatDateRanges,
    formatTimeDifference,
    getWrapperClass() {
      return this.expandMap[this.healthGroup.health_group_id]
        ? 'healthGroup-header'
        : '';
    }
  }
};
</script>
<style lang="css" scoped>
.system-transparency-drawer .healthGroup-header {
  border-bottom: 1px solid;
  border-color: #e9eaeb;
}
.system-transparency-drawer .rotate-icon {
  transform: rotate(180deg);
}

.system-transparency-drawer-impact-areas {
  list-style-type: disc;
}

.u-word-break-break-word {
  word-break: break-word;
}
</style>
