<template>
  <div class="u-spacing-pb-m u-spacing-pt-s">
    <generic-tabs-component
      v-if="!rem1V2Config"
      :tabs-list="tabsList"
      :shadow="false"
      size="m"
      :tab-styles="{ gap: '10px' }"
      class="u-text-case-title"
      :class="{
        'u-border-bottom u-border-width-s u-border-color-grey-xxx-light':
          computedError || computedLoading
      }"
      @handleTabsClick="handleTabsClick"
    />
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>{{ errMsgFromService }}</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div v-show="!computedLoading && !computedError">
      <rb-insights-table
        :table-column="categoryLandscapeColDef"
        :table-row="categoryLandscapeTableData"
        :grid-options="categoryPerformanceGridOptions"
        :sorting-change-event="sortingChangeEvent"
        :enable-server-side-sorting="rem1V2Config"
        :pagination="false"
        class="rb-insights-table-scrollable"
      />
    </div>
  </div>
</template>

<script>
import genericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import TextWithButton from '@/components/basic/TextWithButton.vue';
import loader from '@/components/basic/loader';
import CategoryPerformanceService from '@/components/pages/insights/amazon/cva/services/categoryPerformance/CategoryPerformanceService.js';
import Vue from 'vue';
import CategoryCell from '@/components/pages/insights/amazon/cva/molecules/category-cell.vue';
import { eventBus } from '@/utils/services/eventBus';
import { rem1V2ConfigEnabled } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'CategoryPerformanceWidget',
  components: {
    genericTabsComponent,
    loader
  },
  data() {
    return {
      categoryPerformanceService: null,
      tabsList: ['categories', 'custom tags'],
      selectedTab: 'categories',
      categoryPerformanceGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      expandedCategories: {},
      rowIndexToSubCategoriesMapping: {},
      sortData: {
        colId: 'search_vol_post_value',
        sort: 'desc'
      },
      sortingChangeEvent: 'categoryPerformanceSortingChange',
      rem1V2Config: rem1V2ConfigEnabled()
    };
  },
  computed: {
    categoryLandscapeColDef() {
      const columns = this.categoryPerformanceService?.getColumnDefs() || [];
      return [...columns];
    },
    categoryLandscapeTableData() {
      this.categoryPerformanceGridOptions?.api?.sizeColumnsToFit();
      return this.categoryPerformanceService?.tableData || [];
    },
    computedLoading() {
      return (
        this.categoryPerformanceService?.metadata?.loading ||
        this.categoryPerformanceService?.loading
      );
    },
    computedError() {
      return (
        this.categoryPerformanceService?.metadata?.error ||
        this.categoryPerformanceService?.error
      );
    },
    errMsgFromService() {
      return (
        this.categoryPerformanceService?.metadata?.errMsg ||
        this.categoryPerformanceService?.errMsg
      );
    }
  },
  created() {
    eventBus.$on(this.sortingChangeEvent, (column, config) => {
      this.handleColumnSort(column);
    });
  },
  mounted() {
    Vue.component('TextWithButton', TextWithButton);
    Vue.component('CategoryCell', CategoryCell);
    this.categoryPerformanceService = new CategoryPerformanceService(
      'cva',
      'categoryPerformanceWidget',
      59,
      1001
    );
    this.categoryPerformanceService.fetchData(this.selectedTab);
  },
  methods: {
    handleTabsClick(selected) {
      this.selectedTab = selected;
      this.categoryPerformanceService.fetchData(this.selectedTab);
    },
    onCategoryClick(params) {
      if (rem1V2ConfigEnabled()) {
        if (params.data.rowType === 'sub-category') {
          this.$emit(
            'onSubCategoryClick',
            params.data.node,
            params.data.parentCategory
          );
        } else {
          this.$emit('onCategoryClick', params.data.node);
        }
      } else {
        this.$emit('onCategoryClick', params);
      }
    },
    handleCategoryExpandButton(params, isExpanded) {
      const requestParam = params.data.node;
      if (isExpanded) {
        const subCategories =
          this.categoryPerformanceService?.getSubcategoryData(requestParam);
        const sortedSubCategoriesData =
          this.categoryPerformanceService.getSortedTableData(
            subCategories,
            this.sortData.colId,
            this.sortData.sort
          );
        /**
         * using updateRowData function here to avoid re-rendeing of complete table
         * because setting directy tableData value will render all the rows again
         */
        const addIndex = this.getAddIndex(
          params.rowIndex,
          sortedSubCategoriesData.length
        );
        const res = this.categoryPerformanceGridOptions?.api?.updateRowData({
          add: sortedSubCategoriesData,
          addIndex
        });
        this.expandedCategories[requestParam] = res.add.map((rowNode) => {
          return rowNode.data;
        });
      } else {
        const removeItems = this.expandedCategories[requestParam];
        this.categoryPerformanceGridOptions?.api?.updateRowData({
          remove: removeItems
        });
        delete this.expandedCategories[requestParam];
        delete this.rowIndexToSubCategoriesMapping[params.rowIndex];
      }
    },
    getAddIndex(rowIndex, newItemsLength) {
      let addIndex = rowIndex + 1;
      for (let i = 0; i < rowIndex; i++) {
        if (this.rowIndexToSubCategoriesMapping[i]) {
          addIndex += this.rowIndexToSubCategoriesMapping[i];
        }
      }
      this.rowIndexToSubCategoriesMapping[rowIndex] = newItemsLength;
      return addIndex;
    },
    handleColumnSort(data) {
      this.categoryPerformanceService.loading = true;
      this.sortData.colId = data.colDef.field;
      this.sortData.sort = data.sort;
      this.categoryPerformanceService.tableData =
        this.categoryPerformanceService?.getSortedTableData(
          this.categoryPerformanceService.tableData,
          data.colDef.field,
          data.sort,
          data.colDef.keyType
        );
      this.rowIndexToSubCategoriesMapping = {};
      this.categoryPerformanceService.loading = false;
    }
  }
};
</script>
<style lang="css" scoped>
.rb-insights-table-scrollable .ag-body-viewport {
  max-height: 600px !important;
  overflow-y: auto;
}
</style>
