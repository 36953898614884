import { cloneDeep } from 'lodash';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import moment from 'moment';
import CONSTANTS from '@/utils/constants';
export const splitMetricsIntoViews = (metrics) => {
  const splitMetrics = {};
  const metricKeys = Object.keys(metrics);
  metricKeys.forEach((key) => {
    metrics[key].views.forEach((view) => {
      if (!splitMetrics[view.view]) {
        splitMetrics[view.view] = {};
      }
      splitMetrics[view.view][key] = metrics[key];
    });
  });
  return splitMetrics;
};

export const groupApis = (metrics) => {
  const grouping = {};
  const metricKeys = Object.keys(metrics);
  metricKeys.forEach((metricKey) => {
    const metric = metrics[metricKey];
    if (!grouping[metric.dataGroup]) {
      grouping[metric.dataGroup] = cloneDeep(metric);
      grouping[metric.dataGroup].api.request.metricsList = [metricKey];
    } else {
      grouping[metric.dataGroup].api.request.metricsList.push(metricKey);
    }
  });
  return grouping;
};

export const getColumnDefinition = (dictionary, precision = 1) => {
  var _columnDefs = [];
  for (const i in dictionary) {
    const obj = {};
    obj.headerComponentParams = {};
    obj.cellRendererParams = {};
    obj.field = i;
    obj.title = dictionary[i].title;
    obj.icon = dictionary[i].icon;
    obj.iconClass = dictionary[i].iconClass;
    obj.showIcon = dictionary[i].showIcon;
    obj.suppressSizeToFit = false;
    obj.resizable = false;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (dictionary[i].type === 'NUMERIC') {
      keyType = dictionary[i].unit || 'NUMERIC';
      obj.minWidth = 125;
      obj.maxWidth = 125;
    }
    if (dictionary[i].type === 'DATE') {
      keyType = 'date';
    }
    if (dictionary[i].sort) {
      obj.headerComponentParams.sort = dictionary[i].sort;
    }

    obj.enableSorting = true;
    obj.cellRenderer = cellRenderer;
    obj.headerName = dictionary[i].title;
    obj.headerComponentParams.enableSorting = true;
    obj.keyOrder = dictionary[i].keyOrder;
    dictionary[i].pinned && (obj.pinned = dictionary[i].pinned);
    if (dictionary[i].toolTipText) {
      obj.headerComponentParams.toolTipText = dictionary[i].toolTipText;
    }
    obj.cellRendererParams.keyType = keyType;
    obj.cellRendererParams.formatterFn = formatter;

    if (dictionary[i].tableConfig) {
      if (dictionary[i].tableConfig.cellRendererFramework) {
        if (
          dictionary[i].tableConfig.cellRendererFramework === 'progressDisplay'
        ) {
          delete obj.cellRenderer;
          obj.minWidth = 200;
          obj.maxWidth = 200;
          obj.cellRendererParams = {
            fill: obj.field,
            fillType: 'float',
            decimalRoundOff: 0
          };
          obj.cellRendererFramework =
            dictionary[i].tableConfig.cellRendererFramework;
        } else if (
          dictionary[i].tableConfig.cellRendererFramework === 'linkDisplay'
        ) {
          delete obj.cellRenderer;
          obj.cellRendererFramework = 'linkDisplay';
          obj.cellRendererParams = {
            ...dictionary[i].tableConfig.cellRendererParams
          };
          obj.minWidth = 220;
        }
      }
    }
    obj.cellRendererParams.precision = precision;
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
};

export const attachSortConfigToDictionary = (
  dictionary,
  sortDimension,
  sortDirection
) => {
  if (dictionary[sortDimension]) {
    dictionary[sortDimension].sort = sortDirection.toLowerCase();
  }
};
export const createDictionary = (selectedMetrics, selectableMetrics) => {
  const dictionary = {};
  selectedMetrics.forEach((metric) => {
    const { label, tooltip, metadata, priority } = selectableMetrics[metric];
    dictionary[metric] = {
      title: label,
      toolTipText: tooltip,
      keyOrder: priority,
      enableSorting: true,
      ...metadata
    };
  });
  return dictionary;
};

export const transformTableData = (entityData) => {
  const rowsFormatted = [];
  entityData.forEach((entityUnit) => {
    const dataMap = {};
    entityUnit.data.forEach((item) => {
      dataMap[item.name] = item.RESULT[item.name];
    });
    rowsFormatted.push(dataMap);
  });
  return rowsFormatted;
};

export const hasPlaceholder = (val) => {
  return !!val?.from?.includes(':');
};

export const requestDateReplacement = (requestWhere, localWhere) => {
  if (hasPlaceholder(requestWhere?.date)) {
    requestWhere.date = localWhere.date;
  }
  if (hasPlaceholder(requestWhere?.pvpDate)) {
    requestWhere.pvpDate = localWhere.pvpDate;
  }
};

export const requestTimeReplacement = (requestWhere, localWhere) => {
  if (hasPlaceholder(requestWhere?.time)) {
    requestWhere.time = localWhere.time;
  }
  if (hasPlaceholder(requestWhere?.pvpTime)) {
    requestWhere.pvpTime = localWhere.pvpTime;
  }
};

export const findInArray = (
  valueToCheck,
  valuesArray,
  arrayAccessKey = 'metric'
) => {
  if (!valueToCheck) {
    return null;
  }
  return valuesArray.find((item) => item[arrayAccessKey] === valueToCheck);
};

export const filterFromArray = (primitiveArray, valuesArray) => {
  if (!primitiveArray) {
    return null;
  }
  const lookupMap = {};
  primitiveArray.forEach((item) => (lookupMap[item] = 1));
  return valuesArray.filter((item) => {
    if (lookupMap[item.dimensionValue]) {
      return item;
    }
  });
};

export const transformConfig = (_that, config, type) => {
  const metrticList = Object.keys(config).map((key) => {
    const metric = config[key];
    metric.title = metric.label;
    metric.key = metric.label;
    if (type === 'metric') {
      const config = {};
      config.invertTag2 = metric.metadata.isInverted || false;
      if (metric.metadata.unit === 'CURRENCY') {
        config.tag1Unit = { pre: 'currency', roundoff: 1 };
        _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
          pre: 'currency'
        };
        _that.chartConfig.chartOptions.axes[metric.title] = 'y';
      } else if (metric.metadata.unit === 'PERCENTAGE') {
        config.tag1Unit = { suff: '%', roundoff: 1 };
        _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
          suff: '%'
        };
        _that.chartConfig.chartOptions.axes[metric.title] = 'y';
      }
      _that.selectedValues.metricDisplayConfig[metric.title] = config;
      if (
        metric.metadata.type &&
        metric.metadata.type.toLowerCase() === 'number'
      ) {
        config.tag1Unit = { roundoff: 1 }; // specific to sov use case
        _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
          roundoff: 1
        };
      }
      config.tag2Unit = { suff: '%', roundoff: 1 };
    }
    return metric;
  });
  // Order the merticList based on priority
  metrticList.sort((a, b) => {
    return a.priority - b.priority;
  });
  return metrticList;
};

export const getTimeseriesData = (
  xAxis,
  trendlineName,
  dashboardServiceDataUnit,
  timeseriesDataIndex
) => {
  const timeseriesDataObject =
    dashboardServiceDataUnit?.data?.[timeseriesDataIndex];
  const accessKey = timeseriesDataObject.name;
  const { TIMESERIES: timeseriesArray } = timeseriesDataObject;
  return [
    trendlineName,
    ...getTimeSeriesWithHoles(xAxis, accessKey, timeseriesArray)
  ];
};

export const getTimeSeriesWithHoles = (
  xAxis,
  accessKey,
  trendline = [],
  widget
) => {
  const data = [];
  let param = xAxis[0];
  let xAxisFormat = 'YYYY-MM-DD';
  let trendlineFormat = 'MM/DD/YYYY';
  if (widget === 'sovTimeOfDayPerformance') {
    xAxisFormat = 'HH';
    trendlineFormat = 'HH';
  }
  for (const x of xAxis.slice(1)) {
    let key = moment(x, xAxisFormat).format(trendlineFormat);
    let item = trendline.find((item) => item[param] === key)?.[accessKey];
    data.push(item ?? null);
  }
  return data;
};

export const getNumberSign = (number, isInverted = false) => {
  if (isInverted) {
    if (number < 0) {
      return '+';
    }
    if (number > 0) {
      return '-';
    }
    return '';
  } else {
    if (number > 0) {
      return '+';
    }
    if (number < 0) {
      return '-';
    }
    return '';
  }
};

export const appendPartialArrays = (
  dimensionNameValueList,
  selectedTypes,
  allTypes
) => {
  const selected = filterFromArray(selectedTypes, allTypes) || [];
  if (selected.length !== allTypes.length) {
    dimensionNameValueList.push(...selected);
  }
};

export const filterResponse = (
  dashobardServiceDataArray,
  exlusionList = []
) => {
  try {
    const excludeMap = {};
    exlusionList.forEach((item) => {
      excludeMap[item] = 1;
    });
    const oldEntityData = dashobardServiceDataArray.entityData;
    dashobardServiceDataArray.entityData = oldEntityData.filter(
      (item) => !excludeMap[item.entityValue]
    );
    return dashobardServiceDataArray;
  } catch (e) {
    console.error('e', e);
    return {};
  }
};

export const parseDropdownData = (entityDataArray) => {
  return entityDataArray.map((item) => ({
    ...item,
    title: item.entityValue
  }));
};

export const convertArrayToMap = (array) => {
  return array.reduce((a, b) => {
    a[b.name] = b?.RESULT?.[b?.name];
    a['PVP_' + b?.name] = b?.PVP?.['PVP_' + b?.name];
    a['PVP_DIFF_' + b?.name] = b?.PVP?.['PVP_DIFF_' + b?.name];
    return a;
  }, {});
};

export const flattenArrayOfObjects = (array, keyToFlattenOn = 'children') => {
  const arrayCopy = cloneDeep(array);
  for (const arrObj of arrayCopy) {
    if (arrObj[keyToFlattenOn]) {
      arrayCopy.push(...arrObj[keyToFlattenOn]);
      delete arrObj[keyToFlattenOn];
    }
  }
  return arrayCopy;
};

export const getNewSovSettings = (settings, currCrawlType) => {
  const modifiedSelectedPlacement =
    currCrawlType === CONSTANTS.crawlTypes.multiCrawl.value
      ? `${settings.selectedPlacement}_intraday`
      : settings.selectedPlacement.replace('_intraday', '');
  return {
    ...settings,
    selectedPlacement: modifiedSelectedPlacement
  };
};
