const alertDescription = {
  'All (SKUs)':
    'Shows the list of SKUs and all the alerts which the SKU is part of.',
  'Change in amazon program':
    'Identifies SKUs with change in the status (example: became Add-on or Prime Exclusive, Dropped Subscribe & Save or Amazon Choice badge or Bestseller badge).',
  'Change in Variants':
    'Identifies SKUs that dropped or added a 1P variant on the Amazon Detail Page.',
  'Sales Drop':
    'Identifies and surfaces SKUs with a significant drop in weekly ordered product sales. A significant drop is determined by analyzing median and standard deviation sales drop for catalog SKUs, so as to only surface SKUs with a significant change in sales and also excluding sales drops due to seasonality.',
  'Sales Increase':
    'Identifies and surfaces SKUs with a significant increase in weekly ordered product sales. A significant increase is determined by analyzing median and standard deviation sales increase for catalog SKUs, so as to only surface SKUs with a significant change in sales and also excluding sales increase due to seasonality.',
  'Predicted CRaP':
    'Identifies SKUs where the gross Amazon margins are low due to price war or unilateral price changes and flags potential CRaP candidates.',
  'Duplicate Listing':
    'Identifies SKUs on your PDP which are not from your catalogue but being sold by a 3P.',
  'Independent 3P listings':
    'Identifies independently listed SKUs by third-party sellers bearing one of the 1P brands. These SKUs are identified primarily from search results pages across keywords being tracked. The list is refreshed every alternate day.',
  '3P Variants':
    'Identifies SKUs on your PDP which are not from your catalogue but being sold by a 3P.',
  'Search exit from page 1':
    'Identifies SKUs which have exited page 1 on important keywords and share of page 1 has decreased.',
  'Competitor Out of Stock':
    'Identified OOS SKUs from competing brands. These are identified from search results pages for keywords being tracked. The list is refreshed every alternate day.',
  'SKUs with declining total traffic': 'Asins with traffic falling',
  'Search entry to page 1':
    'Identifies SKUs which have entered page 1 on important keywords and share of page 1 has increased.',
  'Content Change': 'Identifies all the SKUs where content has been changed.',
  'Competitor Promo':
    'Identified SKUs from competing brands that are on promotion. These are identified from search results pages for keywords being tracked. The list is refreshed every alternate day.',
  'Search term isolation':
    'Improve keyword targeting by automatically isolating high performing search terms and adding them as exact match',
  'Keyword harvesting from auto campaign':
    'Keyword harvesting from auto campaign',
  'Campaigns out of budget': 'Campaigns out of budget',
  'High ACoS keywords': 'Keywords with high acos and minimum clicks',
  'Negative keywords': 'Search terms with less conversion rate',
  'Trending comp asins': 'Trending comp asins',
  'Improve organic share of search': 'Keywords with low organic share',
  'Keyword harvesting through important search terms in a category':
    'Important keywords in each category',
  'Monetize keywords with high impression growth':
    'Keywords with high impressions and week over week increase',
  'Monetize low ACoS keywords': 'Keywords with low acos and high impressions',
  'Comp asins with promo': 'Comp asins with promo',
  'Monetize high conversion keywords': 'Monetize high performing keywords',
  'Unavailable SKUs':
    'SKUs that are not available for sale on Amazon for one of the of three following reasons: 1. Unavailable: Absence of buybox and text indicating unavailability. 2. OOS: Absence of buybox or lack of buybox offer from Amazon. 3. Suppressed: Dog pictures instead of detail page.',
  'Change in amazon forecast':
    'Identifies SKUs where next 6 weeks forecast has changed by more than 20%. Mean forecast value from ARAP is used.',
  'Lost Buy Box':
    'Identifies SKUs where Amazon is one of the sellers but BuyBox is won by a 3P seller which is due to pricing issues most of the times.',
  'Predicted OOS':
    'Identifies SKUs which are likely to go OOS within the fulfilment window based on current inventory level and expected sales velocity. The sales velocity ignores outliers due to temporary spike in sales.',
  'Localized Inventory':
    'Identifies Amazon warehouses which have high or very low inventory positions by SKU.',
  'PO Discrepancy':
    'Identify POs where case size, COGS or SKU ID are wrong and address them proactively without affecting PO Fill Rate metric.',
  'Non Product Negative Reviews':
    'Negative reviews due to reasons like delivery or packaging issues which can not be attributed to product quality.',
  'Suppressed ASINs':
    'Identifies SKUs which have been suppressed in last 30 days i.e. showing error code (technically 404) on the product details page'
};

export { alertDescription };
