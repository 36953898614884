import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

const state = {
  workbenchCampaignsData: {},
  addGroupLoader: false,
  workbenchCampaignsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  showCampaignsActivityLog: false,
  campaignPrimaryKey: null,
  campaignsKeywords: {},
  campaignsAsins: {},
  campaignsWorkspaceTableLoader: false,
  dayPartingState: {},
  emailSent: {},
  adGroupsFromCampaignId: [],
  page_wise_min_max_key: 'campaigns_campaign'
};

const getters = {
  getInstacartCampaignsSelectedFilters: (state) => {
    return state.workbenchCampaignsSelectedFilters;
  },
  getInstacartAddGroupLoader: (state) => {
    return state.addGroupLoader;
  },
  getInstacartCampaignsData: (state) => {
    return state.workbenchCampaignsData;
  },
  getShowInstaCampaignsActivityLog: (state) => {
    return state.showCampaignsActivityLog;
  },
  getInstacartCampaignPrimaryKey: (state) => {
    return state.campaignPrimaryKey;
  },
  getInstacartCampaignsKeywords: (state) => {
    return state.campaignsKeywords;
  },
  getInstacartCampaignsAsins: (state) => {
    return state.campaignsAsins;
  },
  getInstacartCampaignWorkspaceTableLoader: (state) => {
    return state.campaignsWorkspaceTableLoader;
  },
  getInstacartDayPartingState: (state) => {
    return state.dayPartingState;
  },
  getInstacartEmailSentStatus: (state) => {
    return state.emailSent;
  },
  getInstacartAdGroupsFromCampaignId: (state) => {
    return state.adGroupsFromCampaignId;
  },
  getInstaCartCampaignsPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  EMAIL_SENT: (state, data) => {
    state.emailSent = data;
  },
  AD_GROUPS_FROM_CAMPAIGN_ID: (state, data) => {
    state.adGroupsFromCampaignId = data;
  },
  RESET_CAMPAIGN_ASINS: (state) => {
    state.campaignsAsins = {};
  },
  IWKCAMPAIGNS_SUCCESS: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.workbenchCampaignsData[data.key], 'data', data.columns);
    }
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', data.rows);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.workbenchCampaignsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.workbenchCampaignsData[data.key], 'page', data.page);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.workbenchCampaignsData[data.key], 'noData', true);
    }
  },
  IWKCAMPAIGNS_RESET: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  IWKCAMPAIGNS_ERROR: (state, data) => {
    Vue.set(state.workbenchCampaignsData, data.key, {});
    Vue.set(state.workbenchCampaignsData[data.key], 'columns', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'rows', []);
    Vue.set(state.workbenchCampaignsData[data.key], 'load', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'error', true);
    Vue.set(state.workbenchCampaignsData[data.key], 'noData', false);
    Vue.set(state.workbenchCampaignsData[data.key], 'metrics', {});
  },
  IWKCAMPAIGNS_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.workbenchCampaignsSelectedFilters.date_range;
    Vue.set(state, 'workbenchCampaignsSelectedFilters', data.values);
    if (!state.workbenchCampaignsSelectedFilters.date_range) {
      Vue.set(state.workbenchCampaignsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKCampaigns(data, state);
  },
  OPEN_CAMPAIGNS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showCampaignsActivityLog', true);
  },
  CLOSE_CAMPAIGNS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showCampaignsActivityLog', false);
  },
  CHANGE_CAMPAIGNS_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'campaignPrimaryKey', key);
  },
  CAMPAIGNS_KEYWORD_SUCCESS: (state, data) => {
    var campaignID = data.campaignId;
    var responseData = data.APIData;
    Vue.set(state.campaignsKeywords, campaignID, responseData);
  },
  CAMPAIGNS_ASIN_SUCCESS: (state, data) => {
    var campaignID = data.campaignId;
    var responseData = data.APIData;
    Vue.set(state.campaignsAsins, campaignID, responseData);
  },
  CREATING_DAY_PARTING_WITH_DATA: (state, data) => {
    const mapId = data.id;
    Vue.set(state.dayPartingState, mapId, data);
  }
};

function getFiltersInFormatWKCampaigns(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  downloadCampaignsTableData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.whereClause);
    // data.body.APIConfig.where.date = context.state.whereClause.date;
    // data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;

    // delete data.body.APIConfig.customOrderAndSort;
    return HttpLayer.post({
      APIData: data
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  setInstacartCampaignsSelectedFilters: (context, data) => {
    context.commit('IWKCAMPAIGNS_SET_SELECTED_FILTER', data);
  },

  resetCampaignAsins: (context) => {
    context.commit('RESET_CAMPAIGN_ASINS');
  },

  getInstacartCampaignsTableData: (context, data) => {
    context.commit('IWKCAMPAIGNS_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKCAMPAIGNS_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        // hardcoded data
        // _aArray = transformer.mergeResultDimension(tableData.response.data);
        if (_aArray.length > 0) {
          context.commit('IWKCAMPAIGNS_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].auto_cubesdk_count || _aArray[0].count,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IWKCAMPAIGNS_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getInstacartCampaignsChartData: (context, data) => {
    context.commit('IWKCAMPAIGNS_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.body.APIConfig.pvpenabled =
      data.body.APIConfig.where.pvpDate !== undefined;

    HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKCAMPAIGNS_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          var xs = {};
          for (var i = 0; i < response.data.length; i++) {
            var aTemp = [];
            aTemp = transformer.getChartDataInFormat(
              data,
              response.data[0].TIMESERIES
            );
          }

          for (i = 0; i < aTemp.length; i++) {
            if (aTemp[i][0] !== 'report_date') {
              xs[aTemp[i][0]] = 'report_date';
            }
          }

          context.commit('IWKCAMPAIGNS_SUCCESS', {
            key: data.meta.key,
            columns: aTemp,
            xs,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            metricsUnits: data.body.getTagUnitData
              ? data.body.getTagUnitData(response.data)
              : {}
          });
        } else {
          context.commit('IWKCAMPAIGNS_SUCCESS', {
            key: data.meta.key,
            columns: [],
            metrics: {}
          });
        }
      }
    });
  },

  updateCampaignAction: (context, data) => {
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
      } else {
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
