<template>
  <div
    class="u-display-flex u-flex-direction-column u-font-size-1 campaign-management-styling ams-campaign-management"
  >
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="getShowCampaignsActivityLog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'campaigns'"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div
      class="u-display-flex u-spacing-mb-m u-bg-color-grey-white u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow filter-panel-class"
    >
      <rb-filter-panel
        id="wk-campaigns-filters"
        :key="filterKey"
        :page="'campaigns'"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'campaigns-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :allow-date-compare="true"
        :is-real-time-toggle-present="true"
        :real-time-toggle-value="realTimeToggleValue"
        :enable-save="true"
        :ingestion-tooltip-text="ingestionToolTipText"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :has-search="true"
        :loader-status="loaderStatus"
        :new-date="true"
        :additional-ranges="additionalRanges"
        :default-date-post-range="'last30Days'"
        :max-date="getCampaignsPageWiseMinMaxKey"
        :show-data-missing-msg="showDataMissingMsg"
        :data-missing-date-range="'August 11th, 2023, to October 9th, 2023,'"
        :on-create="onCreate"
        :get-filter-instance="getFilterInstance"
        :common-date-key="'common-date-cm'"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        @onRealTimeToggleClick="onRealTimeToggleClick"
        @savedFilterApplied="filterKey++"
        @clearBulkBanner="isBulkSelectedCleared"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Campaigns'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            bulk-search-ref="amsCampaigns"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          />
        </span>
        <div
          v-if="!disableStreamChart"
          slot="before-date-picker"
        >
          <div
            class="realTimeToggleWrapper u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-mr-s"
            :class="{ realTimeActiveBorder: realTimeToggleValue }"
            @click="onStreamToggleBtnClick"
          >
            <rb-switch
              :id="pvpId"
              :key="realTimeToggleValue"
              :true-value="true"
              :false-value="false"
              :value="realTimeToggleValue"
              :native-value="realTimeToggleValue"
              :disabled="false"
              class="u-width-auto"
              size="small"
            />
            <div
              v-if="realTimeToggleValue"
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                icon="dot"
                class="rb-icon--medium realtime_active_icon"
              />
              <span class="streamBtnFontActive streamBtnFont">
                Real-Time Data</span
              >
            </div>
            <div
              v-else
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                icon="dot"
                class="rb-icon rb-icon--small icon-task-status u-color-green-base"
              />
              <span class="streamBtnFont"> Real-Time Data</span>
            </div>
          </div>
        </div>
      </rb-filter-panel>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      :dates="filterInstance.selectedDates"
    />

    <card-widget
      v-show="
        !showAddKeywordsPanel &&
        !showBidChangePanel &&
        !showAddAsinsPanel &&
        !showAddNegativeKeywordsPanel
      "
      class="u-flex-1 u-spacing-mh-m u-spacing-mv-m"
      :config="config.widgets['widget1']"
      :data="streamMode ? getStreamChartData : getChartData"
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    data-cy="metricCardHover"
                    class="metric-card-hover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        data-cy="metricList"
                        :class-name="'campaigns-select'"
                        :search-enabled="true"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span
                              :data-cy="`metric-${val}`"
                              class="u-font-size-5"
                              >{{ val }}</span
                            >
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              data-cy="carrotDownIcon"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getWorkbenchCampaignsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="Enabled when a single campaign is selected"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <metric
                        v-if="val && (getChartData.metrics || {})[val]"
                        :size="'l'"
                        class="u-display-inline-flex u-spacing-mt-s"
                        :config="(getChartData.metricsUnits || {})[val]"
                        :data="(getChartData.metrics || {})[val]"
                      />
                      <streamMetricLabel
                        v-if="!disableStreamChart && realTimeToggleValue"
                        :metric-label="getStreamChartMetricValue(val)"
                        :stream-metric-tooltip="
                          config.widgets.widget1.meta.streamMetricTooltip
                        "
                      />
                      <span
                        v-if="
                          !val &&
                          !((
                            getWorkbenchCampaignsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <div class="u-display-flex">
              <section
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <span
                  v-if="numberOfPlottedSelections !== 0"
                  class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                  :class="{ isHighlighted: isHighlighted }"
                  style="min-height: 20px"
                >
                  <span
                    data-cy="graphPlottedInfo"
                    class="u-spacing-pr-s u-line-height-1_2"
                    >Graphs plotted for
                    {{ numberOfPlottedSelections }} rows</span
                  >
                  <span @click="plotRows('delete')">
                    <rb-icon
                      :icon="'cross'"
                      class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                      :class="{
                        'isHighlighted u-color-grey-white': isHighlighted
                      }"
                    />
                  </span>
                </span>
              </section>
              <span>
                <icon-text-actions
                  v-if="isExpand"
                  class="u-cursor-pointer padding--right u-width-100"
                  mode="default"
                  :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                  @clicked="isExpand = !isExpand"
                />
                <icon-text-actions
                  v-if="!isExpand"
                  class="u-cursor-pointer u-width-100 padding--right"
                  mode="default"
                  :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                  @clicked="isExpand = !isExpand"
                />
              </span>
            </div>
          </div>
          <div
            :class="streamMode ? 'chart-wrapper-realtime' : ''"
            class="u-border-radius-m"
          >
            <StreamChartActions
              entity="campaigns"
              :roll-ups="computedRollUps"
              :real-time-toggle-value="realTimeToggleValue"
              :selected-roll-up="selectedRollUpValue"
              @onYesterdayModeChange="
                (val) => onYesterdayModeChange(val, pageTitle)
              "
              @onStreamModeChange="(val) => onStreamModeChange(val, pageTitle)"
              @onRollUpChange="
                (selection) => getCampaignsChartDataWithRollUp(selection)
              "
            />
            <chart
              id="workbenchCampaignsChart"
              :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
              class="c3-large u-flex-1 u-spacing-mt-l"
              :config="chartConfig"
              :data="widget1Data"
            />
          </div>
        </div>
      </div>
    </card-widget>

    <div class="u-position-relative">
      <addNegativeKeywordsPanel
        v-if="showAddNegativeKeywordsPanel"
        :selections="selections"
        :parent-ref="parentRef"
        :widgets-config-ref="widgetsConfig"
        :bulk-actions-allowed="true"
        @closePanelMethod="() => negativeKeywordsAfterAction(true)"
        @afterAPIActionToCall="negativeKeywordsAfterAction"
      />
      <!-- transformForRealtimePayload -> stored in commons.js -->
      <bidChangePanel
        v-if="showBidChangePanel"
        :title="bidChangePanelTitle"
        :selected-values="selections"
        :parent-ref="parentRef"
        :custom-selection-length="getBidChangePanelSelectionLength"
        primary-key="campaign_id"
        widget="campaign"
        :config="config"
        :transform-payload="transformForRealtimePayload"
        api-version="metadataChange"
        :action-panel-type="'campaigns'"
        @closePanelMethod="() => bidChangeAfterAction(true)"
        @afterAPIActionToCall="bidChangeAfterAction"
      />
      <addKeywordsPanel
        v-if="showAddKeywordsPanel"
        :selections="selections"
        :parent-ref="parentRef"
        :widgets-config-ref="widgetsConfig"
        :bulk-actions-allowed="selections.length > 1"
      />
      <addAsinsPanel
        v-if="showAddAsinsPanel"
        :selections="selections"
        :parent-ref="parentRef"
        :widgets-config-ref="widgetsConfig"
        :bulk-actions-allowed="true"
      />
      <div
        v-if="showaddTagsPanel"
        class="u-display-flex u-flex-direction-column u-width-100"
      >
        <addTagsPanel
          :custom-add-tags-class="['add-tags-input-width  u-width-560px']"
          custom-tags-and-suggestions-class=""
          class="campaigns-tags-input"
          title="Add campiagn lists"
          header-text="YOUR CAMPAIGN LIST(S)"
          input-placeholder="Search for campaign list"
          tags-suggestions-container-classes="campaigns-tags-input"
          :sub-text="selectionCountText"
          :custom-selection-length="computedSelectionCount"
          :is-input-disabled="false"
          :selected-values="selections"
          :parent-ref="parentRef"
          :save-api-status="tagsLoader"
          :get-add-tag-panel-instance="getAddTagPanelInstance"
          :suggestions-data="getAllTags"
          :tags-icons="[{ icon: 'cross', emit: 'removeActiveTag' }]"
          @closePanelMethod="() => closeActionsWorkflow(false, true)"
          @saveKeywords="handleAddTagsForCampaigns"
          @textInput="handleTextInputTags"
        />
        <div
          class="u-font-size-6 u-bg-color-white-base u-border-bottom u-border-width-s u-spacing-p-m u-spacing-pt-m u-color-green-lighter u-width-100 u-text-align-center u-border-color-grey-xxx-light"
        >
          <div v-if="!isBulkSelection">
            {{
              selections.length > 1
                ? `${selections.length} campaigns`
                : '1 campaign'
            }}
            selected.
            <span
              class="u-color-blue-base u-cursor-pointer"
              @click="onBulkClick(true)"
              >Select all {{ totalRows }} campaigns.</span
            >
          </div>
          <div v-else>
            {{ totalRows }} campaigns selected.
            <span
              class="u-color-blue-base u-cursor-pointer"
              @click="onBulkClick(false)"
              >Deselect all campaigns.</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isDemoClient"
      id="pageBody"
      data-cy="pageBody"
      class="page-body u-spacing-pb-l"
    >
      <div
        class="u-display-flex u-width-100 u-height-100 u-flex-direction-column"
      >
        <div class="orders">
          <div request-params="[object Object]">
            <span>
              <div class="u-envelop-width">
                <div
                  class="recommendations-container u-spacing-mh-m u-spacing-mv-m dspTopRecommendations"
                >
                  <div
                    class="custom_widget_container card"
                    style="padding-bottom: 24px"
                  >
                    <span
                      class="spinner fill--parent u-bg-color-grey-white"
                      style="display: none"
                      ><span
                        data-v-56dfb89c=""
                        class="clip"
                        style="
                          height: 4rem;
                          width: 4rem;
                          border-color: rgb(63, 173, 247) rgb(63, 173, 247)
                            transparent;
                          border-radius: 100%;
                          border-width: 0.2rem;
                          border-style: solid;
                          background: transparent;
                        "
                    /></span>
                    <div class="header-container-cwc">
                      <div
                        class="dynamic-component-container"
                        style="
                          grid-template-columns: 2.4rem 1fr auto;
                          align-items: end;
                        "
                      />
                    </div>
                    <div class="custom_widget_body">
                      <div class="">
                        <!---->
                        <section class="u-spacing-mt-l u-spacing-ph-l header">
                          <div class="u-width-100 iconText">
                            <span
                              class="u-display-flex u-text-overflow-ellipsis"
                              ><span
                                class="rb-icon icon-alerts rb-icon--xx-medium u-color-grey-lighter"
                              /><span
                                class="textClass u-font-size-3 u-font-weight-600 u-color-grey-lighter"
                              >
                                RECOMMENDATIONS OVERVIEW
                              </span></span
                            >
                          </div>
                          <div class="rec-card-container">
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 3;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Add important keywords for campaigns
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                              >
                                Improve sales by adding AI recommended keywords
                                which are important for your campaigns</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    3
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 4;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Conquest competitor products
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                              >
                                Improve sales by adding AI recommended
                                competitor product targets which are important
                                for your campaigns</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    4
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 1;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Defend important branded shelves
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                              >
                                Target AI-recommended important branded keywords
                                which are under attack from competitors</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    1
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 7;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Reduce wasteful spend based on inventory
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                              >
                                Pause advertising on out-of-stock and low stock
                                SKU</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    7
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 9;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Add efficient search terms as targets
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                                >Add high performing search terms as exact
                                targets</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    9
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 6;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Add inefficient search terms as negative targets
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                              >
                                Based on campaign under/overpacing, adjust base
                                bids on line items with strong delivery rate
                                (&gt; 90%) and based on performance measured by
                                goal KPI, to ensure full campaign delivery
                              </span>
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    6
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 2;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Increase Awareness
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                                >Increase awareness by increasing bids on
                                keywords with high impression growth</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    2
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="card rec-card">
                              <div
                                class="rb-button--hollow rb-button__size--s rb-button u-color-blue-base action-button"
                              >
                                <div class="rb-button__content">
                                  <!----><!----><span
                                    class="rb-button__text"
                                    @click="
                                      filterCount = 9;
                                      scrollDown();
                                    "
                                    >View recommendation</span
                                  ><!---->
                                </div>
                              </div>
                              <h2 class="rec-label-class">
                                Target high traffic competitor Products with
                                Promo
                              </h2>
                              <span
                                class="u-font-size-7 u-spacing-mb-m description"
                                >Capitalize on high traffic competitor products
                                which are running Promos</span
                              >
                              <div class="u-width-100 iconText count-class">
                                <span
                                  class="u-display-flex u-text-overflow-ellipsis"
                                  ><span
                                    class="rb-icon icon-logo rb-icon--medium u-color-blue-base"
                                  /><span
                                    class="textClass count-icon-text-class"
                                  >
                                    9
                                  </span></span
                                >
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <!---->
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        :custom-selection-length="getCustomSelectionLength"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div
      v-if="!showActionsBar && filterCount === 0 && !showBidChangePanel"
      class="u-display-flex u-spacing-p-m u-bg-color-grey-white u-flex-direction-row u-flex-justify-content-space-between actionspace-dummy u-position-sticky top-0 z-index-2 u-flex-1"
    >
      <div class="u-display-flex u-flex-align-items-center">
        <div class="u-spacing-pr-l">
          <rb-button
            :click-fn="bulkCampaignCreator"
            :text="'Bulk Campaign Creator'"
            :type="'filled'"
            data-cy="BulkCampaignCreationBtn"
          />
        </div>
      </div>

      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        <splitTabs
          v-if="modeToggleEnable"
          :tab1="entityModeNames && entityModeNames.realTimeData"
          :tab2="entityModeNames && entityModeNames.comparePvP"
          class="split-tabs-container"
          @tabClicked="onModeSelect"
        >
          <span slot="tag1-pre">
            <rb-icon
              icon="dot"
              class="rb-icon--medium realtime_active_icon u-spacing-mr-s"
            />
          </span>
        </splitTabs>
        <div class="u-display-flex u-flex-align-items-baseline">
          <icon-text-actions
            mode="default"
            :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
            @clicked="openManageColumns"
          />
          <rb-download-button
            :download-email="true"
            :config="config.widgets['widget2']"
            :column-map="config.widgets['widget2'].body.download.columnMap"
            :on-email-click="openEmailPopup"
            :class-name="'enlargeEmailIcon'"
            :disabled="resultLength < 1"
          />
        </div>
      </div>
    </div>
    <div
      v-show="filterCount > 0"
      id="filterSection"
      class="u-display-flex u-spacing-ph-m u-spacing-pb-s u-spacing-mt-s u-flex-align-items-center"
    >
      <div @click="filterCount = 0">
        <rb-icon
          class="u-color-grey-lighter arrow--entity_details u-icon-size-2 u-cursor-pointer u-spacing-mr-s"
          :icon="'arrow_back'"
        />
      </div>
      <span class="u-color-blue-base u-font-size-4 u-font-weight-600"
        >Showing {{ filterCount }} campaign(s) for selected
        recommendation.</span
      >
    </div>

    <div
      v-if="showBulkBanner"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
      :class="{ 'change-bg-color': isSelectAllClicked }"
    >
      <selectDeselectAll
        page-key="Campaigns"
        :is-bulk-select-clicked="isBulkSelectClicked"
        :total-entities="
          (getWorkbenchCampaignsData[config.widgets['widget2'].meta.key] || {})
            .totalRows
        "
        :current-selected-entities="selections.length"
        :page-limit="config.widgets.widget2.body.APIConfig.limit"
        @bulkSelectClicked="isBulkSelectedClicked"
        @isBulkSelectedCleared="isBulkSelectedCleared"
      >
      </selectDeselectAll>
    </div>

    <card-widget
      :apply-card-class="false"
      class="u-flex-1 u-bg-color-grey-white"
      :config="config.widgets['widget2']"
      :data="
        getWorkbenchCampaignsData[config.widgets['widget2'].meta.key] || {}
      "
    >
      <div slot="body">
        <div class="u-display-flex u-flex-wrap-yes ams-campaigns-table-wrapper">
          <rb-insights-table
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getWorkbenchCampaignsData[config.widgets['widget2'].meta.key] ||
                {}
              ).rows
            "
            :table-column="tableColumns"
            :real-time-toggle-value="realTimeToggleValue"
            :dynamic-column-width="dynamicColumnWidth"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :scroll-to-node-id="scrollToNodeId"
            :primary-key="'campaign_id'"
            :pagination="filterCount === 0"
            :pagination-total-key="
              (
                getWorkbenchCampaignsData[config.widgets['widget2'].meta.key] ||
                {}
              ).totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :table-header-sticky-obj="sticky"
            :show-pagination-options="true"
          />
        </div>
      </div>
      <realtimeFooter
        v-if="getRealtimeMetadata.realtime"
        slot="footer"
        :last-updated="getRealtimeMetadata.lastUpdated"
        @onRefresh="triggerRefresh(false)"
      />
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="amsCampaigns-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import realtimeFooter from '@/components/ams/campaigns/components/realtimeFooter.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import addKeywordsPanel from '@/components/ams/actionPanels/addKeywordsPanel.vue';
import addAsinsPanel from '@/components/ams/actionPanels/addAsinsPanel.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import addNegativeKeywordsPanel from '@/components/ams/actionPanels/addNegativeKeywordsPanel.vue';
import addTagsPanel from '@/components/ams/actionPanels/addTagsPanel.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import transformer from '@/utils/services/data-transformer';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import {
  metricsShownForAmazonChart,
  entityModeNames,
  defaultCampaignCols
} from '@/components/ams/campaigns/constants.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';
import PvpTimeseriesSwitch from '@/components/globals/pvpTimeseriesSwitch.vue';
import { cloneDeep } from 'lodash';
import StreamChartActions from '@/components/ams/campaigns/components/streamChartActions.vue';
import { commonStreamChartMethods } from '@/components/ams/campaigns/streamChartUtils.js';
import streamMetricLabel from '@/components/ams/campaigns/components/streamMetricLabel.vue';
import { enableBulkOnlyActions } from '@/components/ams/campaigns/utils.js';
import selectDeselectAll from '@/components/ams/campaigns/SelectAll/selectDeselectAll.vue';
import splitTabs from '@/components/widgets/splitTabs';
import { formatter, cryptoRandomGenerator } from '@/utils/helpers/formatter.js';

export default {
  components: {
    splitTabs,
    streamMetricLabel,
    PvpTimeseriesSwitch,
    addTagsPanel,
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    messageBar,
    activityLog,
    addKeywordsPanel,
    bidChangePanel,
    addAsinsPanel,
    modal,
    addNegativeKeywordsPanel,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    amsAddMetricTile,
    SystemTransparencyBanner,
    realtimeFooter,
    RollUpBy,
    StreamChartActions,
    selectDeselectAll
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      pvpId: 'realTimeToggle',
      realTimeToggleValue: false,
      entityModeNames: {},
      modeToggleEnable:
        Vue.options.filters.config_check('feature.pages.enableRMMAmazonPVP') ||
        false,
      dynamicColumnWidth: {
        enable: false
      },
      isBulkSelectClicked: false,
      streamData: {},
      pageTitle: 'Campaigns',
      chartConfig: {},
      filterCount: 0,
      additionalRanges: commons.additionalDateRanges(),
      tagsLoader: { load: false },
      ingestionDependentEntities: ['campaign'],
      ingestionToolTipText:
        'Custom list updation or creation is in progress. It will take upto 45 mins for the changes to reflect.',
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      isBulkSelection: false,
      addTagsPanelInstance: null,
      unavailableMessageSD:
        'This action is currently unavailable for SD campaigns',
      notSupportedMessageSD: 'Action type not applicable for SD campaigns',
      SDMessage: '',
      pageContainerHeight: '400px',
      isSDCampaign: false,
      showEmailPopUp: false,
      modalConfig: {},
      widgetsConfig: {},
      parentRef: {},
      resultLength: 0,
      bidChangePanelTitle: 'Budget Change',
      allowedActions: [],
      showBidChangePanel: false,
      showActionsBar: false,
      selections: [],
      chartWkbenchAxes: {},
      addNegativeKeywordsIndex: -1,
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      filterKey: 0,
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getWorkbenchCampaignsSelectedFilters',
        setter: 'setWorkbenchCampaignsSelectedFilters'
      },
      showaddTagsPanel: false,
      downloadFileSuffix: 'campaign-management_campaigns',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForAmazonChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      showBlueBar: false,
      timer: 30000,
      disableMetric: [
        'Total sales',
        'PCOGS',
        'Inventory',
        'Organic sales',
        'Promo Value'
      ],
      isExpand: false,
      reDrawRows: true,
      rbTableInstance: null,
      showActionsLog: false,
      campaignId: null,
      openActivity: true,
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'edit-status': this.editCampaignsStatus,
        'add-keywords': this.addKeywords,
        'adjust-bids': this.adjustBidsPanel,
        'day-part': this.dayparting,
        'add-asins': this.addAsins,
        'add-negative': this.addNegativeKeywords,
        'add-tag': this.tagCampaigns,
        'demo-task': this.demoTask
      },
      addKeywordConfigIndex: -1,
      addAsinConfigIndex: -1,
      addAdjustBidConfigIndex: -1,
      editStatusConfigIndex: -1,
      showAddKeywordsPanel: false,
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      isHighlighted: false,
      filterInstance: null,
      showPaginationOptions: utils.internalUserCheck(window.user),
      showAddAsinsPanel: false,
      showAddNegativeKeywordsPanel: false,
      isDemoClient: Vue.options.filters.config_check(
        'feature.demo.product_demo.enable'
      ),
      manageColumnsPanelListener: 'campaignsManageColumnsPanel',
      enableManageColumns: true,
      workBenchDataKey: 'getWorkbenchCampaignsData',
      showBulkBanner: false,
      ...commons.commonDataProperties
    };
  },
  computed: {
    showDataMissingMsg() {
      return Vue.options.filters.config_check(
        'feature.pages.data_missing_banner_needed'
      ).enable;
    },
    isSelectAllClicked() {
      if (this.$store.getters.getIsAllSelectedStateCampaigns) {
        return true;
      }
    },
    getCustomSelectionLength() {
      if (this.$store.getters.getIsAllSelectedStateCampaigns) {
        const selectionLength =
          this.getWorkbenchCampaignsData?.[
            this.config?.widgets?.widget2?.meta?.key
          ]?.totalRows;
        return parseInt(selectionLength);
      } else {
        return this.selections.length;
      }
    },
    getBidChangePanelSelectionLength() {
      const selectionLength = {
        visibility: true,
        value: this.getCustomSelectionLength
      };
      return selectionLength;
    },
    getChartData() {
      return (
        this.getWorkbenchCampaignsData[this.config.widgets.widget1.meta.key] ||
        {}
      );
    },
    getStreamChartData() {
      return this.$store.getters.getStreamChartData;
    },
    getRealtimeMetadata() {
      return this.$store.getters.getCampaignsRealTimeMetadata;
    },
    selectedRollUpValue() {
      return this.selectedRollUp?.key || 'DAY';
    },
    computedRollUps() {
      return this.getComputedRollUpOptions(
        this.$store.getters.getCampaignsWhereClause,
        this.rollUps
      );
    },
    bulkSearchProps() {
      return this.config.bulkSearchProps();
    },
    selectionCountText() {
      return `${this.computedSelectionCount} campaign(s) selected`;
    },
    totalRows() {
      return (
        this.getWorkbenchCampaignsData[
          this.config?.widgets?.widget2?.meta?.key
        ] || {}
      )?.totalRows;
    },
    getAllTags() {
      return this.$store.getters.getAllTags;
    },
    computedSelectionCount() {
      if (this.isBulkSelection) {
        return this.totalRows;
      }
      return this.selections.length;
    },
    emailStatus() {
      return this.$store.getters.getEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          (
            this.getWorkbenchCampaignsData[
              this.config.widgets.widget1.meta.key
            ] || {}
          ).load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    getShowCampaignsActivityLog() {
      return this.$store.getters.getShowCampaignsActivityLog;
    },
    getWorkbenchCampaignsData() {
      const isDemoClient = Vue.options.filters.config_check(
        'feature.demo.product_demo.enable'
      );
      if (isDemoClient) {
        this.showActionsBar = false;
        let data = JSON.parse(
          JSON.stringify(this.$store.getters.getWorkbenchCampaignsData)
        );
        let results =
          (data[this.config?.widgets?.widget2?.meta?.key] || {})?.rows || [];
        const max = 6;
        const min = 1;
        results = results.map((item) => {
          item.alertCount =
            Math.round(cryptoRandomGenerator() * (max - min)) + min;
          return item;
        });
        this.resultLength = results.length;
        if (this.filterCount > 0) {
          results = results
            .sort(() => {
              const a = cryptoRandomGenerator();
              const b = cryptoRandomGenerator();
              return a - b;
            })
            .slice(0, this.filterCount);
          results = results.map((item) => {
            item.alertCount = this.filterCount;
            return item;
          });
        }
        if (data && data[this.config.widgets.widget2.meta.key]) {
          data[this.config.widgets.widget2.meta.key].rows = results;
        }

        return { ...data };
      } else {
        this.showActionsBar = false;
        this.resultLength = (
          (
            this.$store.getters.getWorkbenchCampaignsData[
              this.config.widgets.widget2.meta.key
            ] || {}
          ).rows || []
        ).length;
        return this.$store.getters.getWorkbenchCampaignsData;
      }
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.CampaignsActivityLog;
    },
    streamMode() {
      return this.$store.getters.getCampaignsStreamMode;
    },
    YesterdayMode() {
      return this.$store.getters.getCampaignsYTDMode;
    },
    widget1Data() {
      let data = (
        this.getWorkbenchCampaignsData[this.config.widgets.widget1.meta.key] ||
        {}
      ).data;
      if (this.streamMode) {
        data = this.getStreamChartData || {};
      }
      if (!this.YesterdayMode) {
        this.streamData = this.$store.getters.getStreamChartData;
      }
      let clonedData = (data || {})?.data;
      let pvpData = data?.pvp_data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let ignoreList = [
        'report_date',
        'count',
        'hour',
        'customer_orders',
        'inventory_latest',
        'incrementality_spend',
        'incrementality_paid_sales_14d',
        'weighted_percent_time_in_budget',
        'cost_denominator'
      ];
      this.metricsList = [];
      for (const element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }

      data = this.createSelectedMetricsList({ data, clonedData });
      data = this.handleStreamDataTransformer(data, pvpData);
      return data;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.campaign_id) {
            return false;
          }
          return (
            params.data.campaign_id ===
            this.$store.getters.getCampaignPrimaryKey
          );
        }.bind(this)
      };
    },
    primaryKey() {
      return this.$store.getters.getCampaignPrimaryKey[
        this.config.widgets.widget2.meta.primaryKey
      ];
    },
    getCampaignsPageWiseMinMaxKey() {
      return this.$store.getters.getCampaignsPageWiseMinMaxKey;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(`set${this.pageTitle}PVPRealtime`, false);
    next();
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeCreate() {
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchCampaignsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    let that = this;
    eventBus.$on('metadataUpdate', () => {
      that.triggerRefresh(true);
    });

    eventBus.$on('workbenchCampaignsFilterApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('workbenchCampaignsSearchTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('workbenchCampaignsPaginationTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
      that.showBulkBanner = false;
    });
    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('amsCampaignsBulkSearchApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.amsCampaigns?.openBulkSearchPopup();
    });
  },
  created() {
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.realTimeToggleValue = this.$store.getters.getStreamCampaignsToggle;
    this.entityModeNames = entityModeNames;
    this.onModeSelect(entityModeNames?.realTimeData);
    this.disableStreamChart = Vue.options.filters.config_check(
      'feature.pages.disableStreamChart'
    );
    if (this.allowedActions.length === 0) {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
      commons.overRightAllowedActions(this);
    }
    commons.editAllowedActionsKeys(this);
    this.config = widgetsConfig.config(this);
    this.config.widgets.widget1.meta.plotSelections = [];
    this.chartConfig = cloneDeep(this.config.widgets.widget1.body.chart);
    this.fetchFilters(
      'ams_campaigns_campaign_workbench',
      'FETCH_FILTERS_V2',
      'campaigns'
    );
  },
  beforeDestroy() {
    this.destoryRealtimeRefreshInterval();
    eventBus.$off('workbenchCampaignsFilterApplied');
    eventBus.$off('workbenchCampaignsSearchTriggered');
    eventBus.$off('workbenchCampaignsPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('amsCampaignsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
    eventBus.$off('metadataUpdate');
  },
  methods: {
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    async onRealTimeToggleClick(val) {
      this.enableManageColumns = false;
      this.realTimeToggleValue = val;
      this.$store.dispatch(
        'setStreamCampaignsToggle',
        this.realTimeToggleValue
      );
      this.onStreamModeChange(this.realTimeToggleValue, 'Campaigns');
      await this.$store.dispatch('getWorkbenchCampaignsTableStreamData');
      commons.appendTableDefinitionToConfig.call(this);
      this.enableManageColumns = true;
    },
    onStreamToggleBtnClick() {
      this.onRealTimeToggleClick(!this.realTimeToggleValue);
    },
    onModeSelect(value) {
      this.$store.commit('SET_AMAZON_CAMPAIGN_MODE', value);
    },
    ...commonMethods,
    ...commonStreamChartMethods,
    getSelectedEntities(value) {
      return formatter(value, 'number', 'NA');
    },
    isBulkSelectedClicked() {
      this.isBulkSelectClicked = true;
      this.selectAllClicked(true);
      this.$store.dispatch('setIsAllSelectedStateCampaigns', true);
      this.enableOnlyBulkActions(true);
    },
    isBulkSelectedCleared() {
      this.resetAllowedActionsAfterBulk();
      this.$store.dispatch('setIsAllSelectedStateCampaigns', false);
      this.toggleActionBar();
      if (this.bidPanelVisible) {
        this.bidChangeAfterAction();
      }
      this.isBulkSelectClicked = false;
    },
    checkBulkBannerVisibility(data) {
      const bulkSelectAllConfigCheck = Vue.options.filters.config_check(
        'feature.ams_bulk_select_all'
      )?.enable;
      const pageLimit = this.config.widgets.widget2.body.APIConfig.limit;
      const resultLen =
        this.getWorkbenchCampaignsData[this.config?.widgets?.widget2?.meta?.key]
          ?.rows?.length ?? 0;
      const isActionOpen =
        this.showAddAsinsPanel ||
        this.showAddNegativeKeywordsPanel ||
        this.showAddKeywordsPanel ||
        this.showaddTagsPanel;
      if (
        data.length === 0 &&
        this.$store.getters.getIsAllSelectedStateCampaigns
      ) {
        this.$store.dispatch('setIsAllSelectedStateCampaigns', false);
        this.isBulkSelectClicked = false;
        this.showBulkBanner = false;
        return;
      }
      const totalEntities = (
        this.getWorkbenchCampaignsData?.[
          this.config?.widgets?.widget2?.meta?.key
        ] || {}
      )?.totalRows;
      if (
        data.length < pageLimit &&
        data.length !== resultLen &&
        pageLimit < totalEntities &&
        data.length > 0 &&
        bulkSelectAllConfigCheck &&
        !isActionOpen
      ) {
        this.isBulkSelectClicked = false;
        this.$store.dispatch('setIsAllSelectedStateCampaigns', false);
        this.showBulkBanner = true;
        this.resetAllowedActionsAfterBulk();
        return;
      }
      const isVisible =
        totalEntities > pageLimit &&
        (data.length === pageLimit || resultLen === data.length) &&
        bulkSelectAllConfigCheck &&
        !isActionOpen;
      this.showBulkBanner = isVisible;
    },
    selectAllClicked(data) {
      data
        ? this.config.widgets.widget2.body.gridOptions.api.selectAll()
        : this.config.widgets.widget2.body.gridOptions.api.deselectAll();
    },
    enableOnlyBulkActions(areAllSelected = false) {
      this.allowedActions = enableBulkOnlyActions(
        this.allowedActions,
        areAllSelected,
        'campaigns'
      );
      return this.allowedActions;
    },
    resetAllowedActionsAfterBulk() {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
      commons.overRightAllowedActions(this);
      commons.editAllowedActionsKeys(this);
    },
    toggleActionBar() {
      this.closeActionsWorkflow(true, true);
      this.resetAllowedActionsAfterBulk();
    },
    getCampaignsChartDataWithRollUp(selection) {
      this.getChartDataWithRollUp(
        selection,
        'Campaigns',
        this.$store,
        this.selectedRollUp,
        this.config
      );
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    demoTask() {
      this.filterCount = 0;
      this.$snackbar.open({
        message: 'Recommendation approved for the selected campaign(s)',
        duration: 3000,
        actionText: ''
      });
    },
    scrollDown() {
      setTimeout(() => {
        document.querySelector('#filterSection')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }, 0);
    },
    allRowSelection() {
      if (this.config.widgets.widget2.body.gridOptions.api !== null) {
        this.config.widgets.widget2.body.gridOptions.api.selectAll();
      }
    },
    onBulkClick(value) {
      if (value) {
        this.allRowSelection();
      } else {
        this.deselectAllRows();
      }
      this.isBulkSelection = value;
    },
    getAddTagPanelInstance(that) {
      this.addTagsPanelInstance = that;
    },
    handleAddTagsForCampaigns(activeTags) {
      const tagsToValidate = activeTags?.map((item) => {
        return { value: item.title, maxLength: 100 };
      });
      const validationCheck = commons.tagsValidation;
      const charCheck = utils.textValidation(validationCheck, tagsToValidate);
      if (charCheck) {
        const commonMessage = 'Campaign list name cannot ';
        const reasonMessage =
          charCheck.reason === 'character'
            ? 'have special characters'
            : `exceed ${charCheck.maxLength} characters`;
        const snackbarObject = {
          message: commonMessage + reasonMessage,
          duration: 5000,
          actionText: ''
        };
        this.$snackbar.open(snackbarObject);
        return;
      }
      let error = false;
      let noNewEntityTag = false;
      const requestBody = this.createTagsRequest(
        activeTags,
        this.isBulkSelection
      );
      this.tagsLoader.load = true;
      const actionToCall = this.isBulkSelection
        ? 'bulkAddTag'
        : 'addTagToEntity';
      this.$store
        .dispatch(actionToCall, requestBody)
        .then((result) => {
          this.showaddTagsPanel = false;
          this.addTagsPanelInstance.tagsAndSuggestionsInstance.clearAllActiveTags();
          this.closeActionsWorkflow();
          this.deselectAllRows();
          if (result?.data === 'No new tag-entity was added') {
            noNewEntityTag = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error = true;
        })
        .finally(() => {
          this.tagsLoader.load = false;
          const snackbarObject = {
            message: 'Something went wrong!',
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ''
          };
          if (noNewEntityTag) {
            snackbarObject.message =
              'Selected campaign(s) were already a part of the selected campaign list(s)';
          } else if (!error) {
            snackbarObject.message =
              'Campaigns were added to Campaign List(s) successfully!';
            snackbarObject.actionText = 'Visit & Manage Campaign Lists';
            snackbarObject.onAction = () => {
              this.$router.push({ path: '/tools_and_settings/campaign-lists' });
            };
            this.$store
              .dispatch('fetchPostProcessingStatus', 'campaign')
              .then(() => {
                utils.checkAndClearLSFilterForEntity('campaign');
              });
          }
          this.$snackbar.open(snackbarObject);
        });
    },
    createTagsRequest(activeTags, isBulkSelection) {
      let create = true;
      const allTags = this.getAllTags?.rows?.map((item) => item.title);
      const entities = this.selections.map((item) => {
        return {
          value: item?.data?.campaign_id,
          type: 'campaign',
          description: item?.data?.campaign_name,
          image: ''
        };
      });
      const tags = activeTags.map((item) => {
        if (allTags.includes(item?.title)) {
          create = false;
        }
        return {
          level1: item?.title?.trim(),
          level2: '',
          level3: '',
          level4: '',
          level5: '',
          type: 'custom',
          desc: '',
          customType: 'Custom'
        };
      });
      if (isBulkSelection) {
        const brandsRequest = { ...this.config.widgets.widget2.body.APIConfig };
        delete brandsRequest.limit;
        delete brandsRequest.page;
        brandsRequest.orderByList = [];
        brandsRequest.measuresList = ['count'];
        brandsRequest.groupByDimensionsList = ['campaign_name', 'campaign_id'];
        transformer.getCompleteWhereClause(
          (this.config.widgets.widget2.meta.localFilters || []).concat(
            this.config.widgets.widget2.meta.plotSelections || []
          ),
          this.$store.getters?.getCampaignsWhereClause
        );
        brandsRequest.where.date = this.keywordFilters?.date;
        return {
          brandsRequest,
          tags,
          entityType: 'campaign'
        };
      }

      return {
        entityTaggingAddRequest: {
          tags,
          entities,
          create
        }
      };
    },
    tagCampaigns() {
      this.hideActionBar();
      this.showaddTagsPanel = true;
      this.showBulkBanner = false;
    },
    addNegativeKeywords() {
      this.hideActionBar();
      this.showBulkBanner = false;
      this.SDMessage = this.notSupportedMessageSD;
      this.showAddNegativeKeywordsPanel = !this.showAddNegativeKeywordsPanel;
      let that = this;
      this.$nextTick(() => {
        if (that.showAddNegativeKeywordsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      });
    },
    handleTextInputTags(data) {
      const payload = {
        entityType: 'campaign',
        tagType: 'custom',
        customType: 'Custom',
        prefix: encodeURIComponent(data.textInput)
      };
      this.fetchAllTags(payload);
    },
    fetchAllTags: commons.debounce(function (payload) {
      this.$store.dispatch('fetchAllTags', payload);
    }, 500),
    scrollToNodeId() {
      return null;
    },
    getInitialPayloadObject(data) {
      const realtimeMetadata = this.getRealtimeMetadata;
      const oldStatus =
        (realtimeMetadata?.realtime &&
          realtimeMetadata?.data[data?.campaign_id]?.status?.toLowerCase()) ||
        data?.campaign_status?.toLowerCase() ||
        data?.status?.toLowerCase();
      let obj = {
        widget: 'campaign',
        primaryKey: data.campaign_id,
        actionPayload: {
          campaignId: data.campaign_id,
          profileId: data.profile_id,
          campaignType: data.campaign_type
        },
        viewPayload: {
          name: data.campaign_name,
          campaignId: data.campaign_id,
          profileId: data.profile_id,
          campaignType: data.campaign_type
        },
        actionType: 'campaignMetadataChange',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      obj.actionPayload.state = '';
      obj.actionPayload.view_old_state = oldStatus;
      obj.viewPayload.state = '';
      obj.viewPayload.view_old_state = oldStatus;
      return obj;
    },

    processPayloadForStatusChange(params, payloadObjRef, rowData) {
      payloadObjRef.actionPayload.state = params;
      payloadObjRef.viewPayload.state = params;
    },

    getPayload(params, actionType) {
      let paramsToReturn = [];
      for (const element of this.selections) {
        let data = element.data;
        let obj = this.getInitialPayloadObject(data, actionType);
        this.processPayloadForStatusChange(params, obj, data);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    hideActionBar() {
      this.showActionsBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    editCampaignsStatus(selectedOption) {
      let that = this;
      let selectedStatus = selectedOption[0].value.toLowerCase();
      let payload = this.getPayload(selectedStatus);
      let othersObj = {
        successMessage:
          'Status change request has been submitted for the selected campaign(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closeActionsWorkflow(false, true);
          that.onSelectionChanged([]);
          that.plotRows('delete');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        selectedOption,
        payload,
        othersObj,
        'metadataChange',
        false
      );
    },

    adjustBidsPanel() {
      this.hideActionBar();
      this.showBidChangePanel = !this.showBidChangePanel;
      if (this.showBidChangePanel === true) {
        commons.scrollPage(0);
      } else {
        commons.scrollPage(500);
      }
    },

    negativeKeywordsAfterAction(isCloseMethod = false) {
      this.closeActionsWorkflow(false, true);
      this.addNegativeKeywords();
      this.onSelectionChanged([]);
      this.plotRows('delete', isCloseMethod);
    },
    bidChangeAfterAction(isCloseMethod = false) {
      this.bidLoader = false;
      this.closeActionsWorkflow(false, isCloseMethod);
      this.adjustBidsPanel();
      this.onSelectionChanged([]);
      this.plotRows('delete', isCloseMethod);
    },

    addKeywords() {
      this.hideActionBar();
      this.showBulkBanner = false;
      this.SDMessage = this.notSupportedMessageSD;
      this.showAddKeywordsPanel = !this.showAddKeywordsPanel;
      let that = this;
      setTimeout(function () {
        if (that.showAddKeywordsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      }, 100);
    },

    addAsins() {
      this.hideActionBar();
      this.showBulkBanner = false;
      this.SDMessage = this.unavailableMessageSD;
      this.showAddAsinsPanel = !this.showAddAsinsPanel;
      let that = this;
      this.$nextTick(() => {
        if (that.showAddAsinsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      });
    },

    closeActionsWorkflow(fetchChart = true, isCloseMethod = false) {
      if (this.showaddTagsPanel) {
        this.showaddTagsPanel = false;
      }
      if (this.isBulkSelection) {
        this.isBulkSelection = false;
      }
      this.hideActionBar();
      if (this.$store.getters.getIsAllSelectedStateCampaigns) {
        this.isBulkSelectClicked = false;
        this.$store.dispatch('setIsAllSelectedStateCampaigns', false);
        this.resetAllowedActionsAfterBulk();
      }
      this.selections = [];
      if (isCloseMethod) {
        this.deselectAllRows();
        this.onSelectionChanged([]);
      }
      if (fetchChart && !isCloseMethod) {
        this.deselectAllRows();
        this.onSelectionChanged([]);
        this.plotRows('delete');
      }
    },

    removeAllActionPanels(fetchChart = true) {
      this.closeActionsWorkflow(fetchChart);
      this.showBidChangePanel = false;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      const selectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      const allowedActions = [
        'add-keywords',
        'adjust-bids',
        'add-asins',
        'add-negative'
      ];
      commons.checkIfActionIsValid(
        actionId,
        allowedActions,
        selectedNodes,
        this.$snackbar,
        this.isBulkSelectClicked
      );
      const newSelectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      if (newSelectedNodes.length <= 0) {
        return;
      }
      if (actionId === 'day-part') {
        this.actionIdFunctionMap[actionId](selections);
      } else if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        if (['add-keywords', 'add-asins', 'add-negative'].includes(actionId)) {
          this.selections = newSelectedNodes;
        }
        this.actionIdFunctionMap[actionId]();
      }
    },
    plotRows(type, isCancelMethod = false) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      if (!isCancelMethod) {
        eventBus.$emit(
          'workbenchCampaignsPlotSelectedRows',
          this.plotRowsSelections
        );
      }
      document.getElementById('wk-campaigns-filters').scrollIntoView();
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    getPanelConfigIndex() {
      for (let i = 0; i < this.allowedActions.length; i++) {
        if (this.allowedActions[i].id === 'add-keywords') {
          this.addKeywordConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'add-asins') {
          this.addAsinConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'add-negative') {
          this.addNegativeKeywordsIndex = i;
        }
        if (this.allowedActions[i].id === 'adjust-bids') {
          this.addAdjustBidConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'edit-status') {
          this.editStatusConfigIndex = i;
        }
      }
    },

    checkForActionBar(data) {
      if (
        !this.showAddKeywordsPanel &&
        !this.showBidChangePanel &&
        !this.showActionsBar &&
        !this.showAddAsinsPanel &&
        !this.showAddNegativeKeywordsPanel &&
        !this.showaddTagsPanel
      ) {
        this.showActionsBar = data.length > 0;
      }
      if (data.length === 0) {
        this.showActionsBar = false;
      }
    },

    onSelectionChanged(data) {
      this.checkBulkBannerVisibility(data);
      if (data.length === 0) {
        this.showBidChangePanel = false;
        this.resetAllowedActionsAfterBulk();
      }
      this.plotRowsSelections = data.map((selection) => {
        return selection.data;
      });
      this.selections = data;
      this.checkForActionBar(data);
      if (
        this.addKeywordConfigIndex === -1 ||
        this.addAsinConfigIndex === -1 ||
        this.addNegativeKeywordsIndex === -1
      ) {
        this.getPanelConfigIndex();
      }
      this.isSDCampaign = commons.invalidateSDActions(
        data,
        this.allowedActions,
        true
      );
      if (
        this.allowedActions[this.addKeywordConfigIndex] &&
        this.allowedActions[this.addAsinConfigIndex] &&
        this.allowedActions[this.addNegativeKeywordsIndex]
      ) {
        if (this.isSDCampaign) {
          this.allowedActions[this.addAdjustBidConfigIndex].disabled = false;
          this.allowedActions[this.editStatusConfigIndex].disabled = false;
        }
      }
    },
    kpiMetricsCheck() {
      let kpiMetrics = ['Inventory', 'Organic sales', 'PCOGS', 'Total sales'];
      let resultLen = (
        this.getWorkbenchCampaignsData[
          this.config?.widgets?.widget2?.meta?.key
        ] || {}
      )?.rows?.length;
      if (
        resultLen === undefined ||
        resultLen === 1 ||
        this.plottedSelections >= 1
      ) {
        for (let index in kpiMetrics) {
          if (this.metricsShown.indexOf(kpiMetrics[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    },
    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openCampaignsActivityLog');
      this.$store.dispatch('getCampaignsActivityLog', {
        primaryKey: that.params.node.data.campaign_id
      });
      if (this.campaignId === that.params.node.data.campaign_id) {
        return;
      }
      this.campaignId = that.params.node.data.campaign_id;
      this.rbTableInstance.redrawTableRows();
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.campaignId = null;
      this.$store.dispatch('closeCampaignsActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    bulkCampaignCreator() {
      this.$store.dispatch('campaignCreation/updateAllSetpsData', {});
      this.$router.push({ name: 'create_campaigns' });
    },
    getAllTableColumns() {
      return commons.addDefaultColCheck(
        this.config.widgets.widget2.body.columnDefs || [],
        defaultCampaignCols
      );
    }
  }
};
</script>

<style scoped>
.ams-campaign-management .campaigns-tags-input .new-tags-selection:hover {
  background-color: rgba(189, 16, 224, 0.1);
}
.ams-campaign-management .campaigns-tags-input .tag_token_container {
  background-color: rgba(189, 16, 224, 0.1);
}
.ams-campaign-management
  .campaigns-tags-input
  .suggestion-item-tags-suggestion:hover {
  background-color: rgba(189, 16, 224, 0.1);
}
.ams-campaign-management .campaigns-tags-input .tag_token {
  font-weight: normal;
}
.ams-campaign-management .campaigns-tags-input .input-box {
  font-size: 18px;
}
.ams-campaign-management .campaigns-tags-input .tag-icon::before {
  font-size: 10px;
  color: #6a7075;
}
.ams-campaign-management .campaigns-tags-input .add-tags-input-width input {
  max-width: 540px;
}
.ams-campaign-management .bring-to-front {
  z-index: 1001;
}
.ams-campaign-management .realTimeActiveBorder {
  border: solid 2px #73b8fb !important;
}

.ams-campaign-management .realTimeToggleWrapper {
  height: 36px;
  padding: 6px 8px;
  border-radius: 4px;
  border: solid 2px transparent;
  background-color: #e6f3ff;
}
.ams-campaign-management .realTimeToggleWrapper:hover {
  cursor: pointer;
}
.ams-campaign-management .realTimeToggleWrapper:hover,
.ams-campaign-management .realTimeToggleWrapper:focus {
  background-color: #bfdffd;
}
.ams-campaign-management .realTimeToggleWrapper .streamBtnFontActive {
  color: #007cf6;
}
.ams-campaign-management .realTimeToggleWrapper .streamBtnFont {
  margin-left: 5px;
}
.ams-campaign-management .set-roll-up-width {
  min-width: 140px;
}
.ams-campaign-management .filter-panel-class {
  z-index: 99;
  margin-bottom: 0;
}
.ams-campaign-management .change-bg-color {
  background-color: #e6f3ff !important;
}
.ams-campaign-management .padding--right {
  padding-right: 0% !important;
}
.ams-campaign-management .recommendations-container .isLoading {
  min-height: 40vh;
}
.ams-campaign-management .recommendations-container .custom_widget_container {
  background: white;
}
.ams-campaign-management .recommendations-container .text-underline {
  text-decoration: underline;
}
</style>

<style lang="css">
.ams-campaign-management .filter__wrapper > div {
  align-items: flex-start;
}
.ams-campaign-management .filter__wrapper > div > div > div {
  align-items: flex-start;
}
.ams-campaign-management
  .realTimeToggleWrapper
  .rb-switch
  input[type='checkbox']
  + .small {
  width: 15px;
  height: 4px;
  padding: 3px;
}
.ams-campaign-management
  .realTimeToggleWrapper
  .rb-switch
  input[type='checkbox']
  + .small:before {
  width: 8px;
  height: 8px;
}
.ams-campaign-management .recommendations-container .header-container-cwc {
  padding: 2.4rem;
  display: grid;
}
.ams-campaign-management
  .recommendations-container
  .header-container-cwc
  .custom-color {
  color: #ffa800;
  border: solid 1px #ffa800;
}
.ams-campaign-management
  .recommendations-container
  .header-container-cwc
  .custom-color:hover {
  color: #dd9305;
  border: solid 1px #dd9305;
}
.ams-campaign-management .recommendations-container .rec-card-container {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  box-sizing: border-box;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  width: 26rem;
  justify-content: space-around;
  box-shadow: 0 0 4px 0 #caccce;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .action-button {
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  vertical-align: top;
  font-family: inherit;
  border-radius: 4px;
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  order: 5;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
  width: max-content;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .count-class {
  margin-bottom: 1.6rem;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .count-icon-class {
  color: #007cf6;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .count-icon-text-class {
  margin-left: 4px;
  font-size: 1.4rem;
  color: #000;
  font-weight: 600;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .textClass {
  padding-top: 0.3rem;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .rec-label-class {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .rec-description-class,
.ams-campaign-management
  .recommendations-container
  .rec-card-container
  .rec-card
  .description {
  line-height: 1.2;
  font-size: 1.1rem;
  margin-bottom: 1.6rem;
}
.ams-campaign-management .migration-banner {
  top: 0rem !important;
}
</style>
