import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import { metricCellDisplay } from '@/components/pages/insights/amazon/market-share/molecules/customCell.js';
import {
  getSortMapFunc,
  constants,
  generateOuterWhereClauseDimensionNameValueList,
  getGlobalSettingsForMS,
  add3pSuffixToV1MetricName
} from '@/components/pages/insights/amazon/market-share/utils.ts';
import { ThreePSelection } from '@/components/pages/insights/amazon/market-share/types/filters.ts';

function getColumnDefinition(column) {
  return {
    showOnUi: true,
    isDownloadable: true,
    headerComponentFramework: 'genericTableHeader',
    headerComponentParams: {
      props: {
        enableRename: false,
        enableDelete: false,
        displayName: column.dimensionLabel,
        headerCount: 'null',
        fieldName: column.dimensionColumn,
        headerIcon: {
          show: false
        }
      },
      component: 'genericTableHeader',
      enableHeaderIconInterAction: false,
      enableSorting: column.enableSorting ?? true,
      showParentAsinTooltip: column.dimensionColumn === 'parent_asin',
      toolTipText: column.toolTipText
    },
    cellRendererFramework: 'genericTableCell',
    cellRendererParams: {
      keyType: column.type || 'string',
      formatterFn: formatter
    },
    key: column.dimensionColumn,
    field: column.dimensionColumn,
    title: column.dimensionLabel,
    headerName: column.dimensionLabel,
    sortingOrder: column.sortingOrder || ['asc', 'desc'],
    minWidth: 150,
    width: 150,
    keyOrder: 2,
    pinned: column.dimensionColumn === 'parent_asin',
    sort: column.sort
  };
}
const skuCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        name: 'title',
        asin: 'asin',
        imageUrl: 'image_url',
        productPageUrl: 'product_page_url',
        showImage: true,
        count: 'num_variants',
        client_flag: 'client_flag',
        variants: 'variants',
        v2: 'v2'
      },
      tippyConfig: {
        reactive: true,
        html: '#varient_info',
        placement: 'top',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    enableSDP() {
      return this.params?.data[this.renderParams.client_flag] === 'client';
    },
    getTooltip() {
      return '';
    }
  },
  created() {
    if (this.renderParams !== undefined) {
      this.renderParams = this.renderParams;
    }
  },
  methods: {
    varientInfo(item) {
      return item?.split('|') || [];
    },
    navigateToSDP(asin) {
      // Persisting the dates while navigating from BO - SDP.
      const localStorageBoFilterValue = JSON.parse(
        localStorage.getItem('businessInsights-filters')
      );
      localStorage.setItem(
        `SDP-calendar-web${asin}`,
        JSON.stringify(localStorageBoFilterValue)
      );
      navigate_to_sdp(asin);
    },
    urlLink() {
      const routeData = this.$router.resolve({
        name: 'All',
        query: {
          name: 'asin',
          type: 'asin',
          bc_recommendation_type: 'asin',
          selectedAsin: this.params.data[this.renderParams.asin]
        }
      });
      window.open(routeData.href, '_blank');
      // return 'recommendations/all?name=asin&type=asin&bc_recommendation_type=asin&selectedAsin=' + this.params.data[this.renderParams.asin];
    }
  },
  template:
    // eslint-disable-next-line no-multi-str
    '<div class="u-width-100 u-display-flex u-flex-align-items-center"><rb-sku v-if="params.data.asin !== undefined" :title="params.data[renderParams.name]" :id="params.data.asin" :rb-asin-tippy-text=getTooltip :asin="params.data[renderParams.asin]" :imageUrl="params.data[renderParams.imageUrl]" :showImage="renderParams.showImage" :productPageUrl="params.data[renderParams.productPageUrl]" @navigateToSDP="navigateToSDP" :enableSDP="enableSDP"><div slot="sku-context" class="u-display-flex" v-if="params.data[renderParams.count] > 0"> \
  <div v-if="!params.data[renderParams.v2]" id="varient_info">\
    <div v-for="item in varientInfo(params.data[renderParams.variants])">\
      {{item}}\
    </div>\
  </div> \
  <a v-if="!params.data[renderParams.v2]" v-tippy="tippyConfig" class="link u-display-flex u-font-weight-normal" target="_blank"> + {{params.data[renderParams.count]}}&nbsp;<span v-if="params.data[renderParams.count] > 1"> more variants </span> <span v-if="params.data[renderParams.count] === 1">variant</span> </a></div></rb-sku><div v-if="params.data.asin === undefined && params.data[renderParams.imageUrl] === undefined"><span>{{params.data[renderParams.name]}}</span></div></div>'
});

export default class DataService {
  widget;
  page;
  pageId;
  globalViewId;
  isLoading;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    rows: [],
    noData: false
  };

  pagination = {
    page: 1,
    limit: 10
  };

  operations = null;

  constructor(page, widget, pageId, globalViewId, v2) {
    const this1 = this;
    class Operations {
      getMetricNameFromColumnName = this.getMetricNameFromColumnName;
      _orderByList = [];
      get orderByList() {
        return this._orderByList.map((x) => {
          return {
            dimension:
              this1.getMetricNameFromColumnName(x.dimensionMapping) ||
              x.dimension,
            direction: x.direction
          };
        });
      }

      set orderByList(orderByList) {
        this._orderByList = orderByList;
      }
    }
    this.operations = new Operations();
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
    this.v2 = v2;
  }

  savedSortState() {
    const localStorage = getGlobalSettingsForMS(undefined, undefined, false);
    return localStorage?.[constants.SORTING_OBJECT]?.skuPage;
  }

  async getMetadata() {
    try {
      this.isLoading = true;
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false,
        rows: data
      };
      this.isLoading = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
      this.isLoading = false;
    }
  }

  getColumn(nonFilterable) {
    const renderParams = {
      name: 'title',
      asin: 'asin',
      imageUrl: 'imageUrl',
      productPageUrl: 'product_page_url',
      showImage: true,
      enableSDP: true,
      asinTippyText: '',
      count: 'num_variants',
      client_flag: 'client_flag',
      variants: 'variants',
      v2: this.v2
    };
    const getCellRendererParams = (field) => {
      const metricName = this.getMetricNameFromColumnName(field);
      return {
        renderParams: {
          data1: metricName,
          data1_type: 'datatype_' + metricName,
          data2: 'PVP_' + metricName,
          data2_type: 'datatype_PVP_' + metricName,
          comparisonTooltip:
            '"Change value" is unavailable for the selected date range'
        }
      };
    };

    const columnsObj = [
      {
        headerName: this.v2 ? 'ASIN' : 'Parent ASIN and their Variants',
        headerComponentFramework: 'genericTableHeader',
        title: this.v2 ? 'ASIN' : 'Parent ASIN and their Variants',
        cellRendererFramework: skuCellDisplay,
        cellRendererParams: renderParams,
        keyOrder: 1,
        key: 'rank',
        field: 'rank',
        showToolTip: true,
        toolTipText: '',
        type: 'string',
        formatReq: false,
        headerComponentParams: {
          enableSorting: false,
          showParentAsinTooltip: !this.v2
        },
        minWidth: 300,
        pinned: 'left'
      },
      {
        headerName: 'Estimated Sales',
        headerComponentFramework: 'genericTableHeader',
        title: 'Estimated Sales',
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: getCellRendererParams('salesField'),
        sortingOrder: ['desc', 'asc'],
        keyOrder: 1,
        key: this.getMetricNameFromColumnName('salesField'),
        field: this.getMetricNameFromColumnName('salesField'),
        showToolTip: true,
        toolTipText: '',
        sortDimensionMapping: 'salesField',
        sort: 'desc',
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText:
            'The estimated sales for the SKU or the variant group in the selected period.'
        },
        minWidth: 150
      },
      {
        headerName: 'Market Share ($)',
        headerComponentFramework: 'genericTableHeader',
        title: 'Market Share ($)',
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          renderParams: {
            data1: this.v2
              ? this.getMetricNameFromColumnName('msSales')
              : 'SALES_ESTIMATES_SHARE_PERCENT',
            data1_type: this.v2
              ? 'datatype_' + this.getMetricNameFromColumnName('msSales')
              : 'datatype_SALES_ESTIMATES_SHARE_PERCENT',
            data2: this.v2
              ? add3pSuffixToV1MetricName(
                  getGlobalSettingsForMS(),
                  'PVP_SALES_ESTIMATES_SHARE_PERCENT'
                )
              : 'PVP_SALES_ESTIMATES_SHARE_PERCENT_share_percentage',
            data2_type: this.v2
              ? 'datatype_' + this.getMetricNameFromColumnName('msSales')
              : 'datatype_PVP_SALES_ESTIMATES_SHARE_PERCENT',
            comparisonTooltip:
              '"Change value" is unavailable for the selected date range'
          }
        },
        sortingOrder: ['desc', 'asc'],
        keyOrder: 1,
        key: this.v2
          ? this.getMetricNameFromColumnName('msSales')
          : 'SALES_ESTIMATES_SHARE_PERCENT',
        field: this.v2
          ? this.getMetricNameFromColumnName('msSales')
          : 'SALES_ESTIMATES_SHARE_PERCENT',
        sortDimensionMapping: 'msSales',
        showToolTip: true,
        toolTipText: '',
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText:
            "The SKU's Market Share in the selected category in the selected time period."
        },
        minWidth: 150
      },
      {
        headerName: 'Estimated Units Sold',
        headerComponentFramework: 'genericTableHeader',
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: getCellRendererParams('unitsField'),
        title: 'Estimated Units Sold',
        sortingOrder: ['desc', 'asc'],
        keyOrder: 1,
        key: this.getMetricNameFromColumnName('unitsField'),
        field: this.getMetricNameFromColumnName('unitsField'),
        showToolTip: true,
        toolTipText: '',
        sortDimensionMapping: 'unitsField',
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText:
            'The estimated units sold for the SKU or the variant group in the selected period.'
        },
        minWidth: 150
      },
      {
        headerName: 'Market Share (Units)',
        headerComponentFramework: 'genericTableHeader',
        cellRendererFramework: metricCellDisplay,
        title: 'Market Share (Units)',
        sortingOrder: ['desc', 'asc'],
        keyOrder: 1,
        key: this.v2
          ? this.getMetricNameFromColumnName('msUnits')
          : 'UNIT_ESTIMATES_SHARE_PERCENT',
        field: this.v2
          ? this.getMetricNameFromColumnName('msUnits')
          : 'UNIT_ESTIMATES_SHARE_PERCENT',
        sortDimensionMapping: 'msUnits',
        showToolTip: true,
        toolTipText: '',
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText:
            'The SKU’s Market Share calculated by the units sold in the selected category in the selected time period.'
        },
        cellRendererParams: {
          renderParams: {
            data1: this.v2
              ? this.getMetricNameFromColumnName('msUnits')
              : 'UNIT_ESTIMATES_SHARE_PERCENT',
            data1_type:
              'datatype_' +
              (this.v2
                ? this.getMetricNameFromColumnName('msUnits')
                : 'UNIT_ESTIMATES_SHARE_PERCENT'),
            data2: this.v2
              ? add3pSuffixToV1MetricName(
                  getGlobalSettingsForMS(),
                  'PVP_UNIT_ESTIMATES_SHARE_PERCENT'
                )
              : 'PVP_UNIT_ESTIMATES_SHARE_PERCENT_share_percentage',
            data2_type:
              'datatype_PVP_' +
              (this.v2
                ? this.getMetricNameFromColumnName('msUnits')
                : 'UNIT_ESTIMATES_SHARE_PERCENT'),
            comparisonTooltip:
              '"Change value" is unavailable for the selected date range'
          }
        },
        minWidth: 150
      },
      ...(this.v2
        ? [
            {
              headerName: 'Estimated 1P Sales',
              headerComponentFramework: 'genericTableHeader',
              title: 'Estimated 1P Sales',
              cellRendererFramework: metricCellDisplay,
              cellRendererParams: getCellRendererParams('sales1pField'),
              sortingOrder: ['desc', 'asc'],
              keyOrder: 1,
              sortDimensionMapping: 'sales1pField',
              key: this.getMetricNameFromColumnName('sales1pField'),
              field: this.getMetricNameFromColumnName('sales1pField'),
              showToolTip: true,
              toolTipText: '',
              headerComponentParams: {
                enableSorting: true,
                keyType: 'number',
                toolTipText:
                  'The estimated sales of the SKU by Amazon (1P seller) in the selected period.'
              },
              minWidth: 150
            },
            {
              headerName: 'Estimated 3P Sales',
              headerComponentFramework: 'genericTableHeader',
              title: 'Estimated 3P Sales',
              cellRendererFramework: metricCellDisplay,
              cellRendererParams: getCellRendererParams('sales3pField'),
              sortingOrder: ['desc', 'asc'],
              keyOrder: 1,
              sortDimensionMapping: 'sales3pField',
              key: this.getMetricNameFromColumnName('sales3pField'),
              field: this.getMetricNameFromColumnName('sales3pField'),
              showToolTip: true,
              toolTipText: '',
              headerComponentParams: {
                enableSorting: true,
                keyType: 'number',
                toolTipText:
                  'The estimated sales of the SKU by a 3P seller in the selected period.'
              },
              minWidth: 150
            },
            {
              headerName: 'Estimated 1P Units Sold',
              headerComponentFramework: 'genericTableHeader',
              cellRendererFramework: metricCellDisplay,
              cellRendererParams: getCellRendererParams('units1pField'),
              title: 'Estimated 1P Units Sold',
              sortingOrder: ['desc', 'asc'],
              keyOrder: 1,
              sortDimensionMapping: 'units1pField',
              key: this.getMetricNameFromColumnName('units1pField'),
              field: this.getMetricNameFromColumnName('units1pField'),
              showToolTip: true,
              toolTipText: '',
              headerComponentParams: {
                enableSorting: true,
                keyType: 'number',
                toolTipText:
                  'The estimated units sold for the SKU by Amazon (1P selller) in the selected period.'
              },
              minWidth: 150
            },
            {
              headerName: 'Estimated 3P Units Sold',
              headerComponentFramework: 'genericTableHeader',
              cellRendererFramework: metricCellDisplay,
              cellRendererParams: getCellRendererParams('units3pField'),
              title: 'Estimated 3P Units Sold',
              sortingOrder: ['desc', 'asc'],
              keyOrder: 1,
              sortDimensionMapping: 'units3pField',
              key: this.getMetricNameFromColumnName('units3pField'),
              field: this.getMetricNameFromColumnName('units3pField'),
              showToolTip: true,
              toolTipText: '',
              headerComponentParams: {
                enableSorting: true,
                keyType: 'number',
                toolTipText:
                  'The estimated units sold for the SKU by a 3P seller in the selected period.'
              },
              minWidth: 150
            }
          ]
        : []),
      {
        headerName: 'ASP',
        headerComponentFramework: 'genericTableHeader',
        title: 'ASP',
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: getCellRendererParams('aspField'),
        sortingOrder: ['desc', 'asc'],
        keyOrder: 1,
        key: this.getMetricNameFromColumnName('aspField'),
        field: this.getMetricNameFromColumnName('aspField'),
        sortDimensionMapping: 'aspField',
        showToolTip: true,
        toolTipText: '',
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText:
            'Average Selling price for the ASIN group in the selected time period.'
        },
        minWidth: 150
      },
      {
        headerName: 'Brand',
        headerComponentFramework: 'genericTableHeader',
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          cellAlignment: 'u-text-align-right'
        },
        title: 'brand',
        keyOrder: 1,
        key: 'brand',
        field: 'brand',
        showToolTip: true,
        toolTipText: '',
        type: 'string',
        formatReq: false,
        headerComponentParams: {
          enableSorting: true,
          keyType: 'number',
          toolTipText: 'The brand of the SKU as per Amazon PDP.'
        },
        minWidth: 150
      },
      {
        cellRendererParams: {
          cellAlignment: 'u-text-align-right'
        },
        headerName: 'Manufacturer',
        headerComponentFramework: 'genericTableHeader',
        cellRendererFramework: 'genericTableCell',
        title: 'Manufacturer',
        keyOrder: 1,
        key: 'manufacturer',
        field: 'manufacturer',
        showToolTip: true,
        toolTipText: '',
        type: 'string',
        formatReq: false,
        headerComponentParams: {
          keyType: 'number',
          enableSorting: true,
          toolTipText: 'The manufacturer of the SKU as per Amazon PDP.'
        },
        minWidth: 150
      },
      ...nonFilterable.map(getColumnDefinition)
    ];
    // return [...columnsObj];
    const orderByList = this.savedSortState();
    // Using the saved sort state to compute the column definition
    const sortMapFunc = getSortMapFunc('field', orderByList);
    return columnsObj.map(sortMapFunc);
  }

  generatePayload(metricsListDetails, globalSettings, checkBox) {
    let apiPayload = cloneDeep(metricsListDetails.api.request);
    apiPayload.dimensionsList = (apiPayload?.dimensionsList || []).filter(
      (item) => item !== apiPayload.entityType
    );
    const threepSelection = globalSettings?.settingValue?.include3p?.value;
    const dimensionMap = this.metadata?.rows?.metadata?.dimensionMapping || {};
    apiPayload.metricsList = [metricsListDetails.name];
    apiPayload.operations.orderByList[0].dimension = metricsListDetails.name;
    const orderByList = this.savedSortState();
    if (orderByList?.length > 0) {
      apiPayload.operations.orderByList = [{}];
      apiPayload.operations.orderByList[0].dimension =
        this.metadata?.rows?.metrics[
          dimensionMap?.[orderByList[0].dimensionMapping]?.[threepSelection]
        ]?.name || orderByList[0].dimension;
      apiPayload.operations.orderByList[0].direction = orderByList[0].direction;
      if (
        !apiPayload.dimensionsList.includes(
          apiPayload.operations.orderByList[0].dimension
        ) &&
        !Object.values(this.metadata?.rows?.metrics).some(
          (metric) =>
            metric.name === apiPayload.operations.orderByList[0].dimension
        ) &&
        !Object.keys(this.metadata?.rows?.metrics).some(
          (metric) => metric === apiPayload.operations.orderByList[0].dimension
        )
      ) {
        apiPayload.dimensionsList.push(
          apiPayload.operations.orderByList[0].dimension
        );
      }
    }
    apiPayload.operations.page = this.pagination.page;
    apiPayload.operations.limit = this.pagination.limit;
    apiPayload.where = cloneDeep(globalSettings.where);
    console.log(this.metadata);
    if (
      this.metadata?.rows?.name === 'marketshareSkuPerformance' ||
      this.metadata?.rows?.name === 'marketshareSkuPerformance_v2'
    ) {
      apiPayload.metricsList =
        this.metadata?.rows?.metadata?.metricsList?.[threepSelection] || [];
      if (threepSelection === 'exclude3p' && !this.v2) {
        apiPayload.dimensionsList.push(constants.THREE_P_GROUP_FLAG);
      }
      apiPayload.dimensionsList.push('title');
      apiPayload.dimensionsList.push('client_flag');
      apiPayload = generateOuterWhereClauseDimensionNameValueList(
        apiPayload,
        checkBox,
        globalSettings.searchedText,
        'data-api'
      );
    }
    return apiPayload;
  }

  async getData(request, apiDetails, metadata) {
    const requestCopy = cloneDeep(request);
    const apiDetailsCopy = cloneDeep(apiDetails);
    const threepSelection =
      getGlobalSettingsForMS('nestedSettings')?.include3p?.value || 'include3p';
    try {
      this.isLoading = true;
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.post(
        apiDetailsCopy?.service || 'DASHBOARD_SERVICE',
        requestCopy,
        { append: apiDetailsCopy?.endPoint || '/entity/metrics/data' }
      );

      const tableRowData = data.entityData.map((items) => {
        const entity = {};
        entity.entityType = items.entityType;
        entity.entityValue = items.entityValue;
        entity.totalEntityCount = items.totalEntityCount;
        entity.v2 = this.v2;
        items.data.forEach((item) => {
          entity[item.alias] = item.RESULT?.[item.alias];
          if (
            item.alias.startsWith('category_level_attr_np_') &&
            entity[item.alias] !== 'NULL'
          ) {
            entity[item.alias] = JSON.parse(entity[item.alias]);
            const name = entity[item.alias].Name.toLowerCase();
            entity[item.alias] =
              entity[item.alias].Value !== 'NA'
                ? entity[item.alias].Value
                : null;
            entity[name] = entity[item.alias];
          }

          if (
            item.alias.startsWith('UNIT_ESTIMATES_SHARE_PERCENT') ||
            item.alias.startsWith('SALES_ESTIMATES_SHARE_PERCENT') ||
            item.alias === 'SKU_ASP'
          ) {
            entity[item.alias] = parseFloat(item.RESULT?.[item.name]).toFixed(
              2
            );
          }

          if (
            item.alias.startsWith('UNIT_ESTIMATES') &&
            metadata?.metrics?.[item.alias]?.metadata?.unit !== 'PERCENTAGE'
          ) {
            entity[item.alias] = item.RESULT?.[item.alias]
              ? parseInt(item.RESULT?.[item.alias])
              : item.RESULT?.[item.alias];
          } else if (item.alias === 'SALES_ESTIMATES') {
            entity[item.alias] = item.RESULT?.[item.alias]
              ? parseFloat(item.RESULT?.[item.alias]).toFixed(2)
              : item.RESULT?.[item.alias];
          }
          if (
            (threepSelection === ThreePSelection.EXCLUDE_3P &&
              item.alias.endsWith('ESTIMATES_3P_EXCLUDE_3P')) ||
            (threepSelection === ThreePSelection.ONLY_3P &&
              item.alias.endsWith('ESTIMATES_1P_ONLY_3P'))
          ) {
            entity[item.alias] = null;
          }

          if (item.PVP) {
            entity['PVP_' + item.name] = item.PVP['PVP_DIFF_' + item.name];
          }

          if (
            metadata.metrics?.[item.alias]?.metadata?.type === 'NUMERIC' &&
            metadata?.metrics?.[item.alias]?.metadata?.unit !== 'PERCENTAGE'
          ) {
            entity['PVP_' + item.name] = item.PVP['PVP_' + item.name];
          }

          if (metadata.metrics?.[item.alias]?.metadata?.type) {
            entity['datatype_' + item.alias] =
              metadata.metrics?.[item.alias]?.metadata?.unit ||
              metadata.metrics?.[item.alias]?.metadata?.type;
            entity['datatype_PVP_' + item.alias] = 'PERCENTAGE';
          }
          if (item.alias === constants.THREE_P_GROUP_FLAG) {
            entity[item.alias] =
              item.RESULT?.[item.alias] === true ? 'Yes' : 'No';
          }
        });
        entity[items.entityType] = items.entityValue;
        return entity;
      });

      if (tableRowData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        this.data = {
          rows: tableRowData,
          error: false,
          load: false,
          noData: false
        };
      }
      this.isLoading = false;
      return this.data;
    } catch (e) {
      this.isLoading = false;
      console.log(e, 'error');
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
    }
  }

  async download(whereClause, threepSelection, checkBox, searchedText) {
    return new Promise(async (resolve, reject) => {
      console.log(this.metadata);
      try {
        const { endPoint, request, service } =
          this.metadata.rows.downloadApiTemplate;

        if (request) {
          let payLoad = cloneDeep(request);
          delete payLoad.operations.page;
          if (threepSelection) {
            payLoad.metricsList =
              this.metadata?.rows?.metadata?.downloadMetricsList?.[
                threepSelection
              ] || payLoad.metricsList;
            payLoad.operations.orderByList[0].dimension =
              payLoad.metricsList[0];
          }
          payLoad = generateOuterWhereClauseDimensionNameValueList(
            payLoad,
            checkBox,
            searchedText,
            'download'
          );

          payLoad.where = cloneDeep(whereClause);
          const { data } = await HttpService.post(service, payLoad, {
            append: endPoint
          });
          downloadFile(data.url);
        }
        resolve();
      } catch (e) {
        reject(e);
        console.error('error');
      }
    });
  }

  getMetricNameFromColumnName(columnName) {
    const threepSelection =
      getGlobalSettingsForMS('nestedSettings')?.include3p?.value || 'include3p';
    const dimensionMap = this.metadata?.rows?.metadata?.dimensionMapping || {};
    return dimensionMap?.[columnName]?.[threepSelection];
  }
}
