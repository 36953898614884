var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("rra-card-widget " + _vm.defaultClass + " u-spacing-mv-0"),attrs:{"expoter":"display-none"}},[_c('widgetContainer',{attrs:{"header-options":_vm.headerOptionsComp,"header-container-grid-styles":_vm.headerContainerGridStyles,"title":"Shortage Invoices","is-loading":_vm.isLoading,"footer-options":_vm.footerOptions(true)}},[_c('div',{attrs:{"slot":"body","id":"cardContainer"},slot:"body"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-position-relative u-overflow-hidden"},[(_vm.showWidgetData)?_c('div',[_c('div',{staticClass:"upper-metrics u-display-flex u-flex-direction-row"},_vm._l((_vm.getTopBodyMetricsList(0, _vm.metricOffset)),function(metr,key){return _c('div',{key:key},[_c('div',{staticClass:"u-display-flex u-flex-direction-row"},[_c('div',{staticClass:"metric-component u-display-flex u-flex-direction-column"},[_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light","popper-class":"rra-popper-class","visible-arrow":false}},[_c('span',{staticClass:"upper-metric-label u-font-size-4 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-spacing-pb-xs"},[_c('rb-icon',{staticClass:"dot-icon u-spacing-pr-xxs",style:({
                          color: _vm.cardPrimaryMetricColorCode[metr.alias]
                        }),attrs:{"icon":"dot"}}),_vm._v(" "+_vm._s(metr.label + "" + (_vm.isCardCollapsed ? ' : ' : ''))+" ")],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"},[_c('span',{staticClass:"u-color-grey-base"},[_vm._v(" "+_vm._s(metr.label))])]),_c('div',{staticClass:"u-spacing-mv-m u-font-size-6"},[_c('span',{staticClass:"u-color-grey-light"},[_vm._v(_vm._s(metr.tooltip))])])])]),_c('metric',{staticClass:"u-display-inline-flex u-flex-1 u-width-100",attrs:{"size":_vm.metricSize1,"label-color":_vm.getMetricLabelColor(metr.alias),"split":_vm.isSplit,"hide-zero":false,"config":metr.metadata.tagConfig,"data":_vm.cardMetricData(metr.keyName, 'primary')}})],1),(
                    key < _vm.getTopBodyMetricsList(0, _vm.metricOffset).length - 1
                  )?_c('div',{staticClass:"vertical-line"}):_vm._e()])])}),0),_c('chartWithGradient',{key:_vm.myKey,attrs:{"metric-data":_vm.getTopBodyMetricsData(0, _vm.metricOffset),"metadata":_vm.metadata,"card-primary-metric-color-code":_vm.cardPrimaryMetricColorCode,"metrics-list":_vm.getTopBodyMetricsList(0, _vm.metricOffset),"widget-request-params":_vm.widgetRequestParams,"chart-config":_vm.chartConfig}}),_c('div',{staticClass:"lower-metrics u-display-flex u-flex-direction-row"},_vm._l((_vm.getTopBodyMetricsList(
                _vm.metricOffset,
                _vm.metricOffset + 2
              )),function(metr,key){return _c('div',{key:key},[_c('div',{staticClass:"metric-component-2 u-display-flex u-flex-direction-column"},[_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light","popper-class":"rra-popper-class","visible-arrow":false}},[_c('span',{staticClass:"u-font-size-5 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-spacing-pb-xs"},[_vm._v(" "+_vm._s(metr.label)+" ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"},[_c('span',{staticClass:"u-color-grey-base"},[_vm._v(" "+_vm._s(metr.label))])]),_c('div',{staticClass:"u-spacing-mv-m u-font-size-6"},[_c('span',{staticClass:"u-color-grey-light"},[_vm._v(_vm._s(metr.tooltip))])])])]),_c('metric',{staticClass:"u-display-inline-flex u-flex-1 u-width-100",attrs:{"size":_vm.metricSize2,"label-color":_vm.cardPrimaryMetricColorCode[metr.alias],"split":_vm.isSplit,"hide-zero":false,"config":metr.metadata.tagConfig,"data":_vm.cardMetricData(metr.keyName, 'primary')}})],1)])}),0)],1):_c('div',[_c('noDataState',{attrs:{"showable-text":"Date Type is not compatible hence there is no data","date-select-trigger-text":"Change Date Type","icon":"calendar"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }