<template>
  <div class="u-display-flex u-width-100 u-height-100 u-flex-direction-column">
    <split-container class="u-spacing-pt-s">
      <div slot="master">
        <div></div>
      </div>
      <div
        slot="detail"
        class="u-spacing-p-m u-display-flex u-flex-direction-column"
      >
        <div class="u-display-flex u-spacing-m-m">
          <div class="componentWrapper u-width-50 u-flex-grow-1 u-spacing-mr-s">
            <h1>Local Date Range Filter</h1>
            <br />
            <local-date-filter
              :tooltip="true"
              modal-position="Right"
              :value="dateRangeObj"
              @selected-value="selectedRange"
            />
            <div>
              <details-display
                :display-data="descriptionObj.localDateFilterDesc"
              />
            </div>
          </div>

          <div class="componentWrapper u-width-50 u-flex-grow-1 u-spacing-ml-s">
            <h1>Local Select Filter</h1>
            <br />
            <div class="u-width-75 u-flex-grow-1">
              <local-select-filter
                :multi-select="true"
                :value="testValue"
                :list-options="filterOptions"
                @tag-event="tagClicked"
                @selected-value="selectedRange"
              />
            </div>
            <div>
              <details-display
                :display-data="descriptionObj.localSelectFilterDesc"
              />
            </div>
          </div>
        </div>

        <div class="u-display-flex u-spacing-m-m">
          <div class="componentWrapper u-width-50 u-flex-grow-1 u-spacing-mr-s">
            <h1>Time Machine Widget</h1>
            <br />
            <div class="">
              <data-bars
                client-name="KC"
                :key-to-check="'client'"
                :bars-value="descriptionObj.timeMachineOptions"
                @bar-click-event="barClicked"
              />
            </div>
            <div>
              <details-display :display-data="descriptionObj.timeMachineDesc" />
            </div>
          </div>

          <div class="componentWrapper u-width-50 u-flex-grow-1 u-spacing-ml-s">
            <h1>Progress Filler</h1>
            <br />
            <div>
              <progress-filler
                :fill="'70'"
                :tooltip="false"
                gutter-height="20px"
                @filler-event="fillerClicked"
              />
            </div>
            <div>
              <details-display
                :display-data="descriptionObj.progressFillerDesc"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- detail ends here -->
    </split-container>
  </div>
</template>

<script>
import localDateFilter from '@/components/basic/localDateFilter.vue';
import localSelectFilter from '@/components/basic/localSelectFilter.vue';
import splitContainer from '@/components/layouts/splitContainer';
import description from './desc';
import detailsDisplay from './detailsDisplay';
import dataBars from '@/components/basic/dataBars';
import progressFiller from '@/components/basic/progressFiller';

var page = {
  components: {
    detailsDisplay,
    splitContainer,
    localDateFilter,
    localSelectFilter,
    dataBars,
    progressFiller
  },
  data() {
    return {
      descriptionObj: {},
      dateRangeObj: {},
      //      For local Select filter
      filterOptions: [
        {
          title: 'Last 7 days'
        },
        {
          title: 'Last 30 days'
        },
        {
          title: 'Last 90 days'
        },
        {
          title: 'Last 190 days'
        }
      ],
      testValue: ['Last 190 days']
    };
  },
  beforeCreate() {
    // console.log("hallaaaaa!!!!!");
    // this.UIConfigs = UIConfigs;
    // this.readFromLocalStorage('data_workbench_selected_filters', 'setDWSelectedFilter');
  },
  beforeRouteUpdate(to, from, next) {},
  created() {
    this.verifyURL();
    this.descriptionObj = description;
  },
  mounted() {},
  computed: {
    getActiveTab: {
      get() {
        return this.$store.getters.getDataWorkbenchActiveTabIndex;
      },
      set(index) {
        this.$store.dispatch('setDataWorkbenchActiveTab', index);
      }
    }
  },
  beforeDestroy: function () {},
  methods: {
    verifyURL() {
      if (
        window.location.port !== '' &&
        window.location.protocol === 'https:'
      ) {
        var url = window.location.origin + '/data/All';
        window.location.href = url;
      }
    },

    selectedRange(range) {
      this.dateRangeObj = range;
    },

    tagClicked(item, index, selectedItems) {},

    barClicked(obj) {
      console.log(obj);
    },

    fillerClicked() {
      console.log('fillerClicked');
    }
  } /* End of methods */
};

export default page;
</script>

<style lang="css">
.componentWrapper {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1.2rem;
}
</style>
