<template>
  <div
    class="buy-box-base"
    :class="{
      'buy-box-1p': buyBoxType === '1p',
      'buy-box-3p': buyBoxType === '3p'
    }"
  >
    {{ BUY_BOX[buyBoxType] }}
  </div>
</template>

<script>
export default {
  name: 'BuyBox',
  props: {
    buyBoxType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    BUY_BOX: {
      '3p': '3P',
      '1p': '1P'
    }
  })
};
</script>

<style scoped lang="css">
.buy-box-base {
  font-size: '0.8rem';
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 100px;
  text-align: center;
  color: #333333;
  width: 80px;
}

.buy-box-1p {
  background-color: rgba(62, 169, 94, 0.2);
}

.buy-box-3p {
  background-color: #cce6ff;
}
</style>
