<template>
  <div class="u-spacing-pv-xxxl u-spacing-ph-xxl">
    <section class="u-spacing-pv-m">
      <Header :step="step" />
      <div class="u-display-flex">
        <div class="u-width-40">
          <div class="u-display-flex u-spacing-mb-m">
            <div class="u-spacing-pr-l">
              <DatePicker
                v-model="campaignDates.startDate"
                name="startDate"
                data-cy="startDate"
                label="Start Date:"
                :dates="campaignDates"
                @input="onDateChange"
              />
            </div>
            <DatePicker
              v-model="campaignDates.endDate"
              name="endDate"
              data-cy="enddate"
              label="End Date:"
              :dates="campaignDates"
              is-optional
              @input="onDateChange"
            />
          </div>

          <div class="u-spacing-pv-s">
            <RadioGroup
              :radio-options="budgetTypeOptions"
              :value="budgetType"
              group-label="Budget Settings:"
              @onRadioChanged="handleBudgetTypeChange"
            />
          </div>

          <div
            v-if="budgetType === DAILY_BUDGET_TYPE"
            class="u-spacing-pv-s"
          >
            <BudgetInput
              v-model="dailyBudget"
              data-cy="dailyBudgetText"
              label="Daily Budget:"
              @input="handleDailyBudgetChange"
            />
            <div
              v-if="dailyBudgetErrorMessage"
              class="u-spacing-mt-s u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                icon="error-fill"
              />
              <div
                data-cy="dailyBudgetErrorMessage"
                class="u-flex-0 u-spacing-mt-xxxs u-spacing-ml-xxs"
              >
                {{ dailyBudgetErrorMessage }}
              </div>
            </div>
          </div>
          <div class="u-spacing-pv-s">
            <BudgetInput
              v-model="lifetimeBudget"
              data-cy="lifetimeBudgetText"
              label="Lifetime Budget:"
              :is-optional="isOptimizedBidType"
              @input="handleLifetimeBudgetChange"
            />
            <div
              v-if="lifeTimeBudgetErrorMessage"
              class="u-spacing-mt-s u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                icon="error-fill"
              />
              <div
                data-cy="lifetimeBudgetErrorMessage"
                class="u-flex-0 u-spacing-mt-xxxs u-spacing-ml-xxs"
              >
                {{ lifeTimeBudgetErrorMessage }}
              </div>
            </div>
          </div>

          <div class="u-spacing-pt-m">
            <RadioGroup
              :radio-options="bidTypeOptions"
              :value="bidType"
              :is-column="true"
              group-label="Bid Settings:"
              @onRadioChanged="handleBidTypeChange"
            >
              <div
                v-if="bidType === OPTIMIZED_BID_TYPE"
                :slot="`right_radio_${bidType}`"
              >
                <div
                  v-tippy="{ placement: 'top', arrow: false }"
                  title="Optimizing for new-to-brand sales does not mean your campaign will only serve to customers who are new to your brand. Instead, it will drive sales across all customer segments while prioritizing more sales from new customers within a single campaign."
                  expoter="display-none"
                >
                  <rb-icon
                    class="rb-icon u-flex-0 rb-icon--small icon-error-fill"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
              <div
                v-if="
                  bidType === OPTIMIZED_BID_TYPE ||
                  bidType === MAXIMIZED_BID_TYPE
                "
                :slot="`below_radio_${bidType}`"
                class="u-color-grey-light u-spacing-ml-l u-spacing-mt-m"
              >
                <rb-checkbox
                  v-model="minRoasChecked"
                  :disabled="disableMinRoas"
                >
                  <span class="u-font-size-7 u-color-grey-light">
                    Set Target ROAS
                  </span>
                </rb-checkbox>
                <div class="u-display-flex u-spacing-mt-s">
                  <span
                    class="appender roas-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                    :class="{
                      'u-color-grey-xx-light': disableMinRoas || !minRoasChecked
                    }"
                  >
                    {{ $currency }}
                  </span>
                  <input
                    v-model="minRoas"
                    data-cy="inputValue"
                    type="number"
                    min="0"
                    class="rb-input roas-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                    :disabled="!minRoasChecked"
                    @input="handleMinRoadChange"
                  />
                </div>
                <div
                  v-if="minRoasError"
                  class="u-spacing-mt-s u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--small"
                    icon="error-fill"
                  />
                  <div class="u-flex-0 u-spacing-mt-xxxs u-spacing-ml-xxs">
                    {{ minRoasError }}
                  </div>
                </div>
              </div>
            </RadioGroup>
          </div>
        </div>
        <div
          v-if="budgetType !== DAILY_BUDGET_TYPE"
          class="u-width-60 u-border-left u-border-width-s u-border-color-grey-xxx-light u-display-flex u-flex-direction-column u-flex-justify-content-center"
        >
          <div
            class="u-spacing-ml-xxl u-width-400px u-display-flex u-flex-align-items-flex-start"
          >
            <rb-icon
              class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-xs"
              icon="info-circle-fill"
            />
            <div class="u-font-size-6 u-color-grey-light u-line-height-1-3">
              Bid setting as manual is only allowed for budget setting maximize
              impressions and budget can be set to only lifetime.
            </div>
          </div>
        </div>
      </div>

      <Footer
        class="u-spacing-pt-l"
        :disable-right-btn="disableRightBtn"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import Header from '@/components/ams/campaign_creation/steps/common/header.vue';
import Footer from '@/components/ams/campaign_creation/steps/common/footer.vue';
import RadioGroup from '../../radio-group.vue';
import DatePicker from './date-picker.vue';
import BudgetInput from './budget-input.vue';
import {
  CAMPAIGN_BUDGET_TYPES,
  CAMPAIGN_BID_TYPES,
  STEPS_DATA_GETTER,
  SETTINGS_SIDEBAR_TEXT_MAP,
  STEPS_DATA_SETTER,
  BID_TYPES,
  BUDGET_TYPES
} from '@/components/pages/instacart/campaign-creation/config';
import { get, set } from 'lodash';
export default {
  name: 'Settings',
  components: {
    Header,
    Footer,
    DatePicker,
    RadioGroup,
    BudgetInput
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      budgetTypeOptions: CAMPAIGN_BUDGET_TYPES,
      bidTypeOptions: CAMPAIGN_BID_TYPES(
        this.$store.getters[STEPS_DATA_GETTER].steps.objective.type
      ),
      bidType: this.$store.getters[STEPS_DATA_GETTER].steps.settings.bidType,
      budgetType: BUDGET_TYPES.dailyBudget.type,
      minRoas: 0,
      dailyBudget: 0,
      lifetimeBudget: 0,
      noEndDate: true,
      minRoasChecked: false,
      disableMinRoas: false,
      campaignDates: {
        startDate: null,
        endDate: null
      },
      showBidSettingsTooltip: false
    };
  },
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    isOptimizedBidType() {
      return [BID_TYPES.acquire_ntb.type, BID_TYPES.max_sales.type].includes(
        this.bidType
      );
    },
    disableRightBtn() {
      if (this.dailyBudgetErrorMessage || this.lifeTimeBudgetErrorMessage) {
        return true;
      } else if (this.budgetType === BUDGET_TYPES.dailyBudget.type) {
        return !(
          this.campaignDates.startDate &&
          !(this.isOptimizedBidType && this.minRoasError)
        );
      }

      return !(this.campaignDates.startDate && this.lifetimeBudget);
    },
    dailyBudgetErrorMessage() {
      if (
        this.budgetType === BUDGET_TYPES.dailyBudget.type &&
        this.isOptimizedBidType
      ) {
        if (!this.dailyBudget) {
          return 'Daily Budget is mandatory.';
        } else if (this.dailyBudget < 20) {
          return 'Minimum daily budget of $20 is required for Optimized campaigns';
        }
        return '';
      }
      return '';
    },
    lifeTimeBudgetErrorMessage() {
      if (
        !!this.lifetimeBudget &&
        this.dailyBudget > this.lifetimeBudget &&
        this.budgetType === BUDGET_TYPES.dailyBudget.type
      ) {
        return 'Lifetime Budget should be greater than Daily Budget';
      }
      if (
        !(this.dailyBudget || this.lifetimeBudget || this.isOptimizedBidType)
      ) {
        return 'At least one of the Daily Budget or Lifetime Budget is required';
      }

      return '';
    },
    minRoasError() {
      if (this.minRoasChecked && !this.minRoas) {
        return 'Enter the target ROAS for optimized bids.';
      }

      if (this.minRoasChecked && this.minRoas > 20) {
        return 'Target ROAS should be less than $20.';
      }

      if (
        this.bidType === 'acquire_ntb' &&
        this.minRoasChecked &&
        this.minRoas < 0.1
      ) {
        return 'ROAS should be more than 0.1 for Boost New to Brand';
      }

      return '';
    }
  },
  watch: {
    minRoasChecked(newValue) {
      this.updateLocalAndStoreState(newValue, 'minRoasChecked', true);
      if (!newValue) {
        this.updateLocalAndStoreState(null, 'minRoas');
      }
    },
    disableRightBtn(newValue) {
      if (newValue) {
        const stepsData = { ...this.stepsData };
        stepsData.enableSettings = !newValue;
        this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
      }
    }
  },
  created() {
    this.DAILY_BUDGET_TYPE = BUDGET_TYPES.dailyBudget.type;
    this.OPTIMIZED_BID_TYPE = BID_TYPES.acquire_ntb.type;
    this.MAXIMIZED_BID_TYPE = BID_TYPES.max_sales.type;
    const {
      campaignDates,
      dailyBudget,
      lifetimeBudget,
      bidType,
      minRoasChecked,
      minRoas
    } = this.$store.getters[STEPS_DATA_GETTER].steps.settings;
    this.campaignDates = campaignDates;
    this.budgetType = 'dailyBudget';
    this.dailyBudget = dailyBudget;
    this.lifetimeBudget = lifetimeBudget;
    this.bidType = bidType;
    this.minRoasChecked = minRoasChecked;
    this.minRoas = minRoas;
  },
  methods: {
    nextStep() {
      const stepsData = { ...this.stepsData };
      stepsData.enableSettings = true;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    generateSidebarText(fieldKey, newValue) {
      let sidebarText = '';
      SETTINGS_SIDEBAR_TEXT_MAP.forEach(({ key, text }) => {
        const currentValue = get(this, key);
        sidebarText = currentValue
          ? `${sidebarText}${text(
              key === fieldKey ? newValue : currentValue
            )} <br>`
          : sidebarText;
      });
      return sidebarText;
    },
    updateLocalAndStoreState(newValue, fieldKey, updateStoreOnly) {
      const stepsData = { ...this.stepsData };
      const sidebarText = this.generateSidebarText(fieldKey, newValue);
      if (!updateStoreOnly) {
        set(this, fieldKey, newValue);
      }
      set(stepsData, `steps.settings.${fieldKey}`, newValue);
      stepsData.strategyUIText.settings = sidebarText;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    onDateChange(newValue, name) {
      this.updateLocalAndStoreState(newValue, `campaignDates.${name}`, true);
    },
    handleBudgetTypeChange(newValue) {
      this.updateLocalAndStoreState(newValue, 'budgetType');
    },
    handleDailyBudgetChange(newValue) {
      this.updateLocalAndStoreState(newValue, 'dailyBudget', true);
    },
    handleLifetimeBudgetChange(newValue) {
      this.updateLocalAndStoreState(newValue, 'lifetimeBudget', true);
    },
    handleBidTypeChange(newValue) {
      this.updateLocalAndStoreState(newValue, 'bidType');
    },
    handleMinRoadChange(event) {
      const newValue = event.target.value;
      this.updateLocalAndStoreState(parseFloat(newValue), 'minRoas', true);
    }
  }
};
</script>

<style lang="css" scoped>
.errorMessage {
  margin: -15px 0px 10px 0px;
}
.errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .rb-icon {
  align-self: flex-start;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.roas-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
  height: 24px;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.roas-input {
  border-left: 0;
  padding-left: 4px;
  height: 24px;
}
</style>
