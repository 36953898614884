export default {
  localDateFilterDesc: {
    tooltip: {
      Type: 'Boolean',
      DefaultValue: false,
      Usage: 'To activate/deactivate tooltip on Chip.'
    },
    tooltipPosition: {
      Type: 'String',
      DefaultValue: 'is-bottom',
      Usage: 'To position the tooltip, Allowed values are of rb-tooltip.'
    },
    'modal-position': {
      Type: 'String',
      DefaultValue: 'Left',
      AllowedValues: 'Left / Right',
      Usage: "To decide calender's modal position."
    },
    'selected-value': {
      Type: 'Function',
      Usage:
        'To emit the function on selection of new value. Selection Object is passed as Param.'
    },
    rangeNames: {
      Type: 'Array',
      DefaultValue: 'Calender component options.',
      Usage: 'To change the calender predefined option range.'
    },
    value: {
      Usage: 'To set a selected value on chip'
    }
  },

  localSelectFilterDesc: {
    tooltip: {
      Type: 'Boolean',
      DefaultValue: false,
      Usage: 'To activate/deactivate tooltip on Chip.'
    },
    tooltipPosition: {
      Type: 'String',
      DefaultValue: 'is-bottom',
      Usage: 'To position the tooltip, Allowed values are of rb-tooltip.'
    },
    'multi-select': {
      Type: 'Boolean',
      DefaultValue: false,
      Usage: 'To decide if dropdown is multiselect or not.'
    },
    'selected-value': {
      Type: 'Function',
      Usage:
        'To emit the function on selection of new value. Selection Object is passed as Param.'
    },
    'tag-event': {
      Type: 'Function',
      Usage: 'To emit the function on click of tag/chip.'
    },
    'tag-edit': {
      Type: 'Boolean',
      DefaultValue: false,
      Usage: 'If tags can be removed or not'
    },
    'list-options': {
      Type: 'Array',
      Usage: 'To diplay dropdown options.'
    },
    value: {
      Type: 'Array',
      Usage: 'To diplay predefined selected values.'
    }
  },

  timeMachineDesc: {
    clientName: {
      Type: 'String',
      Usage: 'Current client name'
    },
    keyToCheck: {
      Type: 'String',
      Usage: 'Key to identify the Client/Competitors'
    },
    barsValue: {
      Type: 'Array',
      Usage: 'Array of Objects for which bars has to be shown'
    },
    legendsValue: {
      Type: 'Object',
      Default: 'null',
      Usage: 'To show customized text for each client'
    },
    'bar-click-event': {
      Type: 'Function',
      Usage:
        'To emit the function on click of each bar with Object passed as param.'
    }
  },
  timeMachineOptions: [
    {
      client: 'KC'
    },
    {
      client: 'AC'
    },
    {
      client: 'DC'
    },
    {
      client: 'PG'
    },
    {
      client: 'KC'
    },
    {
      client: 'PG'
    },
    {
      client: 'KC'
    },
    {
      client: 'AC'
    },
    {
      client: 'DC'
    },
    {
      client: 'KC'
    },
    {
      client: 'PG'
    },
    {
      client: 'PG'
    },
    {
      client: 'KC'
    },
    {
      client: 'AC'
    },
    {
      client: 'DC'
    },
    {
      client: 'KC'
    },
    {
      client: 'PG'
    },
    {
      client: 'AC'
    },
    {
      client: 'DC'
    },
    {
      client: 'PG'
    }
  ],

  progressFillerDesc: {
    fill: {
      Type: 'String',
      DefaultValue: '0',
      Usage:
        'Percentage of progress bar to be filled. Values passed will be treated as percent'
    },
    tooltip: {
      Type: 'Boolean',
      DefaultValue: false,
      Usage: 'To activate/deactivate tooltip on Chip.'
    },
    tooltipPosition: {
      Type: 'String',
      DefaultValue: 'is-bottom',
      Usage: 'To position the tooltip, Allowed values are of rb-tooltip.'
    },
    gutterHeight: {
      Type: 'String',
      DefaultValue: '10px',
      AllowedValues: 'Any CSS specific measurement.',
      Usage: 'To give height to the progress bar.'
    },
    'filler-event': {
      Type: 'Function',
      Usage: 'To emit the function filled bar is clicked.'
    },
    '#===#': {
      Message:
        'Tooltip is not fully implemented due to some issues in rb-tooltip.'
    }
  }
};
