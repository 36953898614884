import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

var organicSearch = { dimensionName: 'search_type', dimensionValue: 'ORGANIC' };
var marketingIQConfig = 'MarketingIQ';
function setWhereClauseFromFilters(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

function getWhereClause(context) {
  var returnVal = [];
  var objToCheck = context.state.shareOfSearchCheckbox;
  var allCheckboxesUnchecked =
    !objToCheck.checkboxOrganicProducts &&
    !objToCheck.checkboxSponsoredProducts &&
    !objToCheck.checkboxSponsoredBrands;
  if (allCheckboxesUnchecked || objToCheck.checkboxOrganicProducts) {
    returnVal.push({
      dimensionName: 'search_type',
      dimensionValue: 'ORGANIC'
    });
  }
  if (allCheckboxesUnchecked || objToCheck.checkboxSponsoredProducts) {
    returnVal.push({
      dimensionName: 'search_type',
      dimensionValue: 'SPONSORED'
    });
  }
  if (allCheckboxesUnchecked || objToCheck.checkboxSponsoredBrands) {
    returnVal.push({
      dimensionName: 'search_type',
      dimensionValue: 'HEADER'
    });
  }
  return returnVal;
}

export const convertToSnapShotFormat = (data, context) => {
  let searchRankKey = '';
  if (context.getters.getProductConfig.indexOf('MarketingIQ') > -1) {
    searchRankKey = 'all_over_search_rank';
  } else {
    searchRankKey = 'search_rank';
  }
  var dataToReturn = [];
  var tempObj = {
    ORGANIC: {
      type: 'ORGANIC',
      values: []
    },
    SPONSORED: {
      type: 'SPONSORED',
      values: []
    },
    RETAILER_BRANDED: {
      type: 'RETAILER_BRANDED',
      values: []
    },
    HEADER: {
      type: 'HEADER',
      values: []
    },
    SPONSORED_VIDEO: {
      type: 'SPONSORED_VIDEO',
      values: []
    }
  };
  for (var obj of data) {
    // condtion avoids breaking snapshot widget if new search_type is added.
    if (tempObj[obj.search_type]) {
      tempObj[obj.search_type].values.push(obj);
    }
  }

  if (tempObj.HEADER.values.length > 0) {
    dataToReturn.push(tempObj.HEADER);
  }

  if (tempObj.RETAILER_BRANDED.values.length > 0) {
    var retailerIndex = tempObj.RETAILER_BRANDED.values[0][searchRankKey];
    dataToReturn[retailerIndex] = tempObj.RETAILER_BRANDED;
  }

  for (obj of tempObj.ORGANIC.values) {
    dataToReturn[obj[searchRankKey]] = {
      type: 'ORGANIC',
      values: [obj]
    };
  }
  if (tempObj.SPONSORED.values.length > 0) {
    for (obj of tempObj.SPONSORED.values) {
      dataToReturn[obj[searchRankKey]] = {
        type: 'SPONSORED',
        values: [obj]
      };
    }
  }
  if (tempObj.SPONSORED_VIDEO.values.length > 0) {
    for (obj of tempObj.SPONSORED_VIDEO.values) {
      dataToReturn[obj[searchRankKey]] = {
        type: 'SPONSORED_VIDEO',
        values: [obj]
      };
    }
  }
  var filteredArray = setDisplayValuesSnapshotBar(
    dataToReturn.filter(Boolean),
    context
  );
  return filteredArray;
};

export const setDisplayValuesSnapshotBar = (bars, context) => {
  let organicSearchValue = 0;
  const retailerBrandName = 'RETAILER_BRANDED';
  for (let i = 0; i < bars.length; i++) {
    if (bars[i].type === 'HEADER') {
      bars[i].showValue = Vue.prototype.$currency;
      bars[i].toolTipHeading = 'Sponsored brand';
    } else if (bars[i].type === 'SPONSORED') {
      bars[i].showValue = Vue.prototype.$currency;
      bars[i].toolTipHeading = 'Sponsored product';
    } else if (bars[i].type === retailerBrandName) {
      bars[i].showIcon = 'amazon';
      bars[i].iconSize = 'x-medium';
      bars[i].toolTipHeading = 'Amazon branded products';
    } else if (bars[i].type === 'ORGANIC') {
      organicSearchValue++;
      bars[i].showValue = organicSearchValue;
      bars[i].toolTipHeading = 'Organic product';
    } else if (bars[i].type === 'SPONSORED_VIDEO') {
      bars[i].toolTipHeading = 'Sponsored Brand Video AD';
      bars[i].showIcon = 'videocam';
      bars[i].extendBarWidth = true;
      bars[i].iconSize = 'large';
      bars[i].showCurrencyInIcon = true;
    }
  }
  return bars;
};

function graphDataProcess(response, widgetConfig, context) {
  var xs = {};
  var _aArray = [];
  var oReturn = {};
  if (response.data.length > 0) {
    for (var i = 0; i < response.data.length; i++) {
      var oTemp = {};
      var cKey = response.data[i].DIMENSION.brand;
      var replaceObj = {
        count_share_percentage: cKey,
        feed_date: 'feed_date_' + cKey
      };

      if (
        Object.keys(response.data[i].DIMENSION).length === 0 &&
        response.data[i].RESULT.hasOwnProperty('ams_spend') &&
        response.data[i].RESULT.ams_spend
      ) {
        cKey = 'ams spend';
        replaceObj = {
          ams_spend: cKey,
          feed_date: 'feed_date_' + cKey
        };
      }
      var regEx = /^-?\d+\.?\d*$/;
      if (regEx.test(cKey) === true) {
        cKey = response.data[i].DIMENSION.brand + ' ';
      }
      if (cKey) {
        oTemp = getChartDataInFormat0(
          _aArray,
          widgetConfig,
          response.data[i].TIMESERIES,
          replaceObj
        );
        oTemp.data = response.data[i].DIMENSION;
        oReturn[cKey] = oTemp;
        xs[cKey] = 'feed_date_' + cKey;
      }
    }
    context.commit('STORE_SHAREOFSEARCHGRAPHDATA', oReturn);
    context.commit('SHAREOFSEARCHDATA_SUCCESS', {
      key: widgetConfig.meta.key,
      columns: oReturn,
      metrics: widgetConfig.body.getTagData
        ? widgetConfig.body.getTagData(response.data, context.state.mapping)
        : undefined,
      metricsUnits: widgetConfig.body.getTagUnitData
        ? widgetConfig.body.getTagUnitData(response.data)
        : {},
      page: widgetConfig.body.APIConfig.page,
      xs
    });
  } else {
    context.commit('SHAREOFSEARCHDATA_SUCCESS', {
      key: widgetConfig.meta.key,
      columns: [],
      metrics: undefined,
      page: widgetConfig.body.APIConfig.page
    });
  }
}

function fetchFilteredData({ context, keywordsTableDataList, widget, action }) {
  if (keywordsTableDataList.length > 0) {
    var filters =
      context.state.widgetsConfigState.widgets[widget].meta.localFilters || [];
    var found = false;
    for (var i = 0; i < filters.length; i++) {
      if (filters[i].dimensionName === 'search') {
        filters[i].dimensionValue = keywordsTableDataList[0].search;
        found = true;
      }
    }
    context.state.widgetsConfigState.widgets[widget].meta.localFilters =
      filters;
    if (!found) {
      context.state.widgetsConfigState.widgets[widget].meta.localFilters.push({
        dimensionName: 'search',
        dimensionValue: keywordsTableDataList[0].search
      });
    }
    context.dispatch(action, context.state.widgetsConfigState.widgets[widget]);
  }
}

const state = {
  widgetsConfigState: null,
  firstLoad: true,
  SRILocalFilters: {},
  shareOfSearchLocalFilters: {
    granularityFilter: [
      {
        title: 'Page 1',
        value: 'brands_count_page_1'
      },
      {
        title: 'Top 10',
        value: 'brands_count_top_10'
      },
      {
        title: 'Top 3',
        value: 'brands_count_top_3'
      }
    ]
  },
  shareOfSearchLocalFilterSelectedValue: {
    granularityFilter: ['Page 1']
  },
  localFilterSelectedValue: {},
  searchPerformanceKeywordDate: null,
  searchPerformanceBrandsDate: null,
  shareOfSearchDate: null,
  distributionDate: null,
  sriData: null,
  keywordsTableData: null,
  brandsTableData: null,
  shareOfSearchData: null,
  shareOfSearchGraphData: null,
  distributionData: null,
  searchFilters: [],
  insightsSearchMaxFeedDate: null,
  insightsSearchPerfSelectedFilter: {
    date_range: {
      name: 'Last 30 days'
    }
  },
  filtersMapping: {
    search_tags: 'tags',
    search_category: 'category',
    search_subcategory: 'subcategory',
    search_brand: 'brand',
    search_product_group: 'product_group'
  },
  searchPerformancePaginationTotal: 1,
  shareOfSearchLegendReset: true,
  shareOfSearchLegendDateApplied: false,
  tablePage: 1,
  distributionAutoLoad: true,
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  retailerBrandName: '',
  shareOfSearchCheckbox: {
    checkboxOrganicProducts: false,
    checkboxSponsoredProducts: false,
    checkboxSponsoredBrands: false
  },
  subPageError: {},
  showIQTipPopup: false,
  defaultProfileName: null,
  page_wise_min_max_key: 'sri'
};

const getters = {
  getSRILocalFilters: (state) => {
    return state.SRILocalFilters;
  },
  getShareOfSearchLocalFilters: (state) => {
    return state.shareOfSearchLocalFilters;
  },
  getInsightsSearchPerfWarSelectedFilter: (state) => {
    return state.insightsSearchPerfSelectedFilter;
  },
  getCheckboxesState: (state) => {
    return state.shareOfSearchCheckbox;
  },
  getKeywordsTableData: (state) => {
    return state.keywordsTableData;
  },
  getBrandsTableData: (state) => {
    return state.brandsTableData;
  },
  getInsightsSearchMaxFeedDate: (state) => {
    return state.insightsSearchMaxFeedDate;
  },
  getSelectedSRILocalFilters: (state) => {
    return state.localFilterSelectedValue;
  },
  getSelectedShareOfSearchLocalFilters: (state) => {
    return state.shareOfSearchLocalFilterSelectedValue;
  },
  getSearchPerformancePaginationTotal: (state) => {
    return state.searchPerformancePaginationTotal;
  },
  getDistributionData: (state) => {
    return state.distributionData;
  },
  getShareOfSearchData: (state) => {
    return state.shareOfSearchData;
  },
  getShareOfSearchGraphData: (state) => {
    return state.shareOfSearchGraphData;
  },
  getShareOfSearchLegendReset: (state) => {
    return state.shareOfSearchLegendReset;
  },
  getShareOfSearchLegendDateApplied: (state) => {
    return state.shareOfSearchLegendDateApplied;
  },
  getTablePage: (state) => {
    return state.tablePage;
  },
  getDistributionAutoLoad: (state) => {
    return state.distributionAutoLoad;
  },
  getRetailerBrandName: (state) => {
    return state.retailerBrandName;
  },
  getSubPageError: (state) => {
    return state.subPageError;
  },
  getPopupVisibility: (state) => {
    return state.showIQTipPopup;
  },
  getSovPageWiseMinMaxKey() {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  KEYWORDS_TABLE_SUCCESS: (state, data) => {
    Vue.set(state.keywordsTableData, data.key, {});
    Vue.set(state.keywordsTableData[data.key], 'data', data.columns);
    if (data.completeTableData !== undefined) {
      Vue.set(
        state.keywordsTableData[data.key],
        'completeTableData',
        data.completeTableData
      );
    }
    Vue.set(state.keywordsTableData[data.key], 'rows', data.rows);
    Vue.set(state.keywordsTableData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.keywordsTableData[data.key], 'load', false);
    Vue.set(state.keywordsTableData[data.key], 'page', data.page);
    Vue.set(state.keywordsTableData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.keywordsTableData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.keywordsTableData[data.key], 'noData', true);
    }
  },
  BRANDS_TABLE_SUCCESS: (state, data) => {
    Vue.set(state.brandsTableData, data.key, {});
    Vue.set(state.brandsTableData[data.key], 'data', data.columns);
    if (data.completeTableData !== undefined) {
      Vue.set(
        state.brandsTableData[data.key],
        'completeTableData',
        data.completeTableData
      );
    }
    Vue.set(state.brandsTableData[data.key], 'rows', data.rows);
    Vue.set(state.brandsTableData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.brandsTableData[data.key], 'load', false);
    Vue.set(state.brandsTableData[data.key], 'page', data.page);
    Vue.set(state.brandsTableData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.brandsTableData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.brandsTableData[data.key], 'noData', true);
    }
  },
  KEYWORDS_TABLE_RESET: (state, data) => {
    state.keywordsTableData = {};
    Vue.set(state.keywordsTableData, data.key, {});
    Vue.set(state.keywordsTableData[data.key], 'columns', []);
    Vue.set(state.keywordsTableData[data.key], 'rows', []);
    Vue.set(state.keywordsTableData[data.key], 'load', true);
    Vue.set(state.keywordsTableData[data.key], 'error', false);
    Vue.set(state.keywordsTableData[data.key], 'noData', false);
  },
  BRANDS_TABLE_RESET: (state, data) => {
    state.brandsTableData = {};
    Vue.set(state.brandsTableData, data.key, {});
    Vue.set(state.brandsTableData[data.key], 'columns', []);
    Vue.set(state.brandsTableData[data.key], 'rows', []);
    Vue.set(state.brandsTableData[data.key], 'load', true);
    Vue.set(state.brandsTableData[data.key], 'error', false);
    Vue.set(state.brandsTableData[data.key], 'noData', false);
  },
  KEYWORDS_TABLE_ERROR: (state, data) => {
    Vue.set(state.keywordsTableData, data.key, {});
    Vue.set(state.keywordsTableData[data.key], 'columns', []);
    Vue.set(state.keywordsTableData[data.key], 'rows', []);
    Vue.set(state.keywordsTableData[data.key], 'load', false);
    Vue.set(state.keywordsTableData[data.key], 'error', true);
    Vue.set(state.keywordsTableData[data.key], 'noData', false);
  },
  BRANDS_TABLE_ERROR: (state, data) => {
    Vue.set(state.brandsTableData, data.key, {});
    Vue.set(state.brandsTableData[data.key], 'columns', []);
    Vue.set(state.brandsTableData[data.key], 'rows', []);
    Vue.set(state.brandsTableData[data.key], 'load', false);
    Vue.set(state.brandsTableData[data.key], 'error', true);
    Vue.set(state.brandsTableData[data.key], 'noData', false);
  },
  SHAREOFSEARCHDATA_SUCCESS: (state, data) => {
    state.shareOfSearchData = {};
    if (data.xs || data.legends) {
      Vue.set(state.shareOfSearchData, 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.shareOfSearchData, 'data', data.columns);
    }
    if (data.completeTableData !== undefined) {
      Vue.set(
        state.shareOfSearchData,
        'completeTableData',
        data.completeTableData
      );
    }
    Vue.set(state.shareOfSearchData, 'rows', data.rows);
    Vue.set(state.shareOfSearchData, 'totalRows', data.totalRows);
    Vue.set(state.shareOfSearchData, 'metrics', data.metrics);
    Vue.set(state.shareOfSearchData, 'load', false);
    Vue.set(state.shareOfSearchData, 'page', data.page);
    Vue.set(state.shareOfSearchData, 'error', false);
    Vue.set(state.shareOfSearchData, 'metricsUnits', data.metricsUnits);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.shareOfSearchData, 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.shareOfSearchData, 'noData', true);
    }
  },
  SHAREOFSEARCHDATA_RESET: (state) => {
    state.shareOfSearchData = {};
    Vue.set(state.shareOfSearchData, 'columns', []);
    Vue.set(state.shareOfSearchData, 'rows', []);
    Vue.set(state.shareOfSearchData, 'load', true);
    Vue.set(state.shareOfSearchData, 'error', false);
    Vue.set(state.shareOfSearchData, 'noData', false);
    Vue.set(state.shareOfSearchData, 'metricsUnits', {});
  },
  SHAREOFSEARCHDATA_ERROR: (state) => {
    state.shareOfSearchData = {};
    Vue.set(state.shareOfSearchData, 'columns', []);
    Vue.set(state.shareOfSearchData, 'rows', []);
    Vue.set(state.shareOfSearchData, 'load', false);
    Vue.set(state.shareOfSearchData, 'error', true);
    Vue.set(state.shareOfSearchData, 'noData', false);
    Vue.set(state.shareOfSearchData, 'metricsUnits', {});
  },
  DISTRIBUTION_SUCCESS: (state, data) => {
    state.distributionData = {};
    if (data.completeTableData !== undefined) {
      Vue.set(
        state.distributionData,
        'completeTableData',
        data.completeTableData
      );
    }
    Vue.set(state.distributionData, 'data', data.columns);
    Vue.set(state.distributionData, 'rows', data.rows);
    Vue.set(state.distributionData, 'totalRows', data.totalRows);
    Vue.set(state.distributionData, 'metrics', data.metrics);
    Vue.set(state.distributionData, 'load', false);
    Vue.set(state.distributionData, 'page', data.page);
    Vue.set(state.distributionData, 'error', false);
    Vue.set(state.distributionData, 'tmLegends', data.tmLegends);
    Vue.set(state.distributionData, 'sortedLegends', data.sortedLegends);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.distributionData, 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.distributionData, 'noData', true);
    }
  },
  DISTRIBUTION_RESET: (state) => {
    state.distributionData = {};
    Vue.set(state.distributionData, 'completeTableData', []);
    Vue.set(state.distributionData, 'columns', []);
    Vue.set(state.distributionData, 'rows', []);
    Vue.set(state.distributionData, 'load', true);
    Vue.set(state.distributionData, 'error', false);
    Vue.set(state.distributionData, 'noData', false);
    Vue.set(state.distributionData, 'tmLegends', {});
    Vue.set(state.distributionData, 'sortedLegends', {});
  },
  DISTRIBUTION_ERROR: (state) => {
    state.distributionData = {};
    Vue.set(state.distributionData, 'completeTableData', []);
    Vue.set(state.distributionData, 'columns', []);
    Vue.set(state.distributionData, 'rows', []);
    Vue.set(state.distributionData, 'load', false);
    Vue.set(state.distributionData, 'error', true);
    Vue.set(state.distributionData, 'noData', false);
    Vue.set(state.distributionData, 'tmLegends', {});
    Vue.set(state.distributionData, 'sortedLegends', {});
  },
  ISEARCH_SET_SELECTED_FILTER: (state, data) => {
    state.firstLoad = true;
    Vue.set(
      state.insightsSearchPerfSelectedFilter,
      data.filterValueKey,
      data.values
    );
    setWhereClauseFromFilters(data, state);
  },
  SET_ISEARCH_MAX_FEED_DATE: (state, data) => {
    state.insightsSearchMaxFeedDate = data;
  },
  STORE_SRIDATA: (state, data) => {
    state.sriData = data;
  },
  STORE_SHAREOFSEARCHGRAPHDATA: (state, data) => {
    state.shareOfSearchGraphData = data;
  },
  SET_SHARE_OF_SEARCH_DATE: (state, data) => {
    state.shareOfSearchDate = data;
  },
  SET_SEARCH_PERFORMANCE_KEYWORD_DATE: (state, data) => {
    state.searchPerformanceKeywordDate = data;
  },
  SET_SEARCH_PERFORMANCE_BRANDS_DATE: (state, data) => {
    state.searchPerformanceBrandsDate = data;
  },
  SET_DISTRIBUTION_DATE: (state, data) => {
    state.distributionDate = data;
  },
  SET_LOCAL_FILTER_DATA: (state, data) => {
    state.SRILocalFilters[data.key] = data.data;
    const newData = (data.data && data.data.length && data.data.slice(0)) || [];
    if (data.isAdvertisingEnabled && data.key === 'searchDistribution') {
      newData.unshift({
        title: 'All Keywords'
      });
    }
    state.shareOfSearchLocalFilters[data.key] = newData;
  },
  UPDATE_LOCAL_FILTER_SELECTED_VALUE: (state, data) => {
    Vue.set(state.localFilterSelectedValue, data.key, data.value);
  },
  UPDATE_SOS_LOCAL_FILTER_SELECTED_VALUE: (state, data) => {
    Vue.set(state.shareOfSearchLocalFilterSelectedValue, data.key, data.value);
  },
  SHAREOFSEARCH_LEGEND_RESET: (state, val) => {
    state.shareOfSearchLegendReset = val;
  },
  SHAREOFSEARCH_LEGEND_DATE_APPLIED: (state, val) => {
    state.shareOfSearchLegendDateApplied = val;
  },
  UPDATE_TABLE_PAGE: (state, val) => {
    state.tablePage = val;
  },
  SUBPAGE_ERROR: (state, val) => {
    state.subPageError[val.page] = val.error;
  },
  SHOW_POPUP_TO_SELECT_FILTERS: (state) => {
    state.showIQTipPopup = true;
  },
  HIDE_POPUP_TO_SELECT_FILTERS: (state) => {
    state.showIQTipPopup = false;
  },
  SET_DEFAULT_PROFILE_NAME: (state, profileName) => {
    state.defaultProfileName = profileName;
  }
};

function getDistributionDataLegends(context, data, barsDataRecieved) {
  if (data.length === 0) {
    return {};
  }
  var dataToReturn = {};
  var legendsValue = data; // transformer.mergeResultDimension(data);

  var legendsValueToReturn = {};
  var clientBrandsPercent = 0;
  var compBrandsPercent = 0;
  var topComps = {};
  var regEx = /^-?\d+\.?\d*$/;

  var clientAvailable = false;
  for (var cIndex = 0; cIndex < data.length; cIndex++) {
    if (data[cIndex].client_flag === 'client') {
      clientAvailable = true;
      break;
    }
  }

  var compsLength = JSON.parse(JSON.stringify(legendsValue));
  compsLength =
    clientAvailable === true
      ? compsLength.splice(1, compsLength.length - 1)
      : compsLength;
  var barsData = legendsValue;
  var clientType =
    context.getters.getProductConfig.indexOf(marketingIQConfig) > -1;
  if (barsData.length > 0) {
    var index = 0;
    for (var i = 0; i < barsData.length; i++) {
      var condition =
        barsData[i].brand_by_client_flag !== 'client_brands' &&
        topComps.hasOwnProperty(barsData[i].brand_by_client_flag) === false;
      if (clientType) {
        condition =
          (barsData[i].brand_by_client_flag !== 'client_brands' &&
            topComps.hasOwnProperty(barsData[i].brand_by_client_flag) ===
              false) ||
          (barsData[i].client_flag !== 'RETAILER_BRANDED' &&
            topComps.hasOwnProperty(barsData[i].client_flag) === false);
      }
      if (condition) {
        var kKey = barsData[i].brand_by_client_flag;
        if (regEx.test(kKey) === true) {
          kKey = barsData[i].brand_by_client_flag + ' ';
        }
        if (kKey !== 'RETAILER_BRANDED') {
          topComps[kKey] = kKey;
          index++;
        }
      }
      if (clientAvailable) {
        if (index > 3 && compsLength.length > 4) {
          break;
        } else if (index > 3) {
          break;
        }
      } else {
        if (index > 2 && compsLength.length > 4) {
          break;
        } else if (index > 3) {
          break;
        }
      }
    }
  }

  var cKey = '';
  if (clientType) {
    let clientFound = false;
    for (let j = 0; j < legendsValue.length; j++) {
      cKey = legendsValue[j].brand_by_client_flag;
      if (regEx.test(cKey) === true) {
        cKey = legendsValue[j].brand_by_client_flag + ' ';
      }
      if (
        Object.keys(legendsValueToReturn).length < 4 &&
        legendsValue[j].brand_by_client_flag !== 'RETAILER_BRANDED'
      ) {
        if (topComps.hasOwnProperty(cKey) && cKey !== 'client_brands') {
          legendsValueToReturn[cKey] =
            cKey +
            ' (' +
            legendsValue[j].count_share_percentage.toFixed(2) +
            '%)';
          topComps[cKey] = legendsValue[j].count_share_percentage;
          compBrandsPercent =
            compBrandsPercent + legendsValue[j].count_share_percentage;
        }
      }
      if (cKey === 'client_brands') {
        clientFound = true;
        legendsValueToReturn[cKey] =
          cKey +
          ' (' +
          legendsValue[j].count_share_percentage.toFixed(2) +
          '%)';
        clientBrandsPercent = legendsValue[j].count_share_percentage;
        topComps[cKey] = legendsValue[j].count_share_percentage;
      }
      if (
        Object.keys(legendsValueToReturn).length === 4 &&
        clientFound === true
      ) {
        break;
      }
    }
  } else {
    for (let k = 0; k < legendsValue.length; k++) {
      cKey = legendsValue[k].brand_by_client_flag;
      if (regEx.test(cKey) === true) {
        cKey = legendsValue[k].brand_by_client_flag + ' ';
      }
      if (cKey === 'client_brands') {
        clientBrandsPercent = legendsValue[k].count_share_percentage;
        legendsValueToReturn[cKey] =
          cKey +
          ' (' +
          legendsValue[k].count_share_percentage.toFixed(2) +
          '%)';
      }
      if (topComps.hasOwnProperty(cKey)) {
        topComps[cKey] = legendsValue[k].count_share_percentage;
        compBrandsPercent =
          compBrandsPercent + legendsValue[k].count_share_percentage;
        legendsValueToReturn[cKey] =
          cKey +
          ' (' +
          legendsValue[k].count_share_percentage.toFixed(2) +
          '%)';
      }
    }
  }
  var othersPercent = (100 - (clientBrandsPercent + compBrandsPercent)).toFixed(
    2
  );
  legendsValueToReturn.client_brands =
    'My brands (' + clientBrandsPercent.toFixed(2) + '%)';
  dataToReturn = legendsValueToReturn;
  if (othersPercent > 0 && compsLength.length >= 4) {
    dataToReturn.Others = 'Others (' + othersPercent + '%)';
  }
  return dataToReturn;
}

function getSortedLegends(data) {
  var sortedLegends = data.sort(function (a, b) {
    return b.count_share_percentage - a.count_share_percentage;
  });

  return sortedLegends;
}

function getSearchPerformanceUpdatedData(data, percentSign) {
  var dataToReturn = transformer.mergeResultDimension(data);
  for (var i = 0; i < dataToReturn.length; i++) {
    dataToReturn[i].page1_total_sku_percent =
      dataToReturn[i].page1_total_sku_count === 0
        ? 0
        : (dataToReturn[i].page1_client_sku_count /
            dataToReturn[i].page1_total_sku_count) *
          100;
    dataToReturn[i].top10_total_sku_percent =
      dataToReturn[i].top10_total_sku_count === 0
        ? 0
        : (dataToReturn[i].top10_client_sku_count /
            dataToReturn[i].top10_total_sku_count) *
          100;
    dataToReturn[i].top3_total_sku_percent =
      dataToReturn[i].top3_total_sku_count === 0
        ? 0
        : (dataToReturn[i].top3_client_sku_count /
            dataToReturn[i].top3_total_sku_count) *
          100;

    if (percentSign === true) {
      dataToReturn[i].page1_total_sku_percent =
        dataToReturn[i].page1_total_sku_percent.toFixed(2) + '%';
      dataToReturn[i].top10_total_sku_percent =
        dataToReturn[i].top10_total_sku_percent.toFixed(2) + '%';
      dataToReturn[i].top3_total_sku_percent =
        dataToReturn[i].top3_total_sku_percent.toFixed(2) + '%';
    }
  }
  return dataToReturn;
}

function getChartDataInFormat0(_aArray, data, response, replace) {
  var _oObj = {};
  if (response === null) {
    return [];
  }
  for (var i = 0; i < response.length; i++) {
    for (var j in response[i]) {
      if (replace[j] !== undefined) {
        if (!_oObj[j]) {
          _oObj[j] = [j];
        }
        _oObj[j].push(response[i][j]);
      }
    }
  }
  for (i in replace) {
    if (_oObj[i] !== undefined) {
      _oObj[i][0] = replace[i];
    }
  }
  return _oObj;
}

function getSearchWhereClauseDate(context) {
  var returnDate = {
    to: context.getters.getDateRangeValues.sri[
      context.state.insightsSearchPerfSelectedFilter.date_range.name
    ].to,
    from: context.getters.getDateRangeValues.sri[
      context.state.insightsSearchPerfSelectedFilter.date_range.name
    ].from,
    name: context.getters.getDateRangeValues.sri[
      context.state.insightsSearchPerfSelectedFilter.date_range.name
    ].name,
    page_wise_min_max_key: state.page_wise_min_max_key
  };
  return returnDate;
}

const actions = {
  setShareOfSearchDate: (context, data) => {
    context.commit('SET_SHARE_OF_SEARCH_DATE', data);
  },

  setSearchPerformanceKeywordDate: (context, data) => {
    context.commit('SET_SEARCH_PERFORMANCE_KEYWORD_DATE', data);
  },

  setSearchPerformanceBrandsDate: (context, data) => {
    context.commit('SET_SEARCH_PERFORMANCE_BRANDS_DATE', data);
  },

  setDistributionDate: (context, data) => {
    context.commit('SET_DISTRIBUTION_DATE', data);
  },

  setWidgetsConfigState: (context, data) => {
    context.state.widgetsConfigState = data;
  },

  setShareOfSearchCheckbox: (context, data) => {
    context.state.shareOfSearchCheckbox.checkboxOrganicProducts =
      data.checkboxOrganicProducts;
    context.state.shareOfSearchCheckbox.checkboxSponsoredProducts =
      data.checkboxSponsoredProducts;
    context.state.shareOfSearchCheckbox.checkboxSponsoredBrands =
      data.checkboxSponsoredBrands;
    context.dispatch('fetchShareOfSearchData', data.apiConfig);
  },

  fetchDependentWidgetsData: (context, keywordsTableDataList) => {
    // update local filters for distribution widget
    if (keywordsTableDataList[0].search === 'All Keywords') {
      keywordsTableDataList.shift();
    }
    context.dispatch('updateLocalFilterSelectedValue', {
      key: 'searchDistribution',
      value: [keywordsTableDataList[0].search]
    });
    fetchFilteredData({
      context,
      keywordsTableDataList,
      widget: 'widget3',
      action: 'fetchDistributionData'
    });

    // update local filters for share of search widget
    const isAdvertisingEnabled =
      context.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    if (
      isAdvertisingEnabled &&
      context.state.firstLoad &&
      keywordsTableDataList[0].search !== 'All Keywords'
    ) {
      keywordsTableDataList.unshift({
        search: 'All Keywords'
      });
      if (context.state.firstLoad) {
        context.state.firstLoad = false;
      }
      context.dispatch('updateShareOfSearchLocalFilterSelectedValue', {
        key: 'searchDistribution',
        value: [keywordsTableDataList[0].search]
      });
      return;
    }
    if (context.state.firstLoad) {
      context.state.firstLoad = false;
    }
    context.dispatch('updateShareOfSearchLocalFilterSelectedValue', {
      key: 'searchDistribution',
      value: [keywordsTableDataList[0].search]
    });
    fetchFilteredData({
      context,
      keywordsTableDataList,
      widget: 'widget4',
      action: 'fetchShareOfSearchData'
    });
  },

  // last graph widget Data
  fetchShareOfSearchData: (context, data) => {
    context.commit('SHAREOFSEARCHDATA_RESET');
    const isAdvertisingEnabled =
      context.getters.getProductConfig.indexOf(marketingIQConfig) > -1;
    if (context.state.firstLoad) {
      // Setting up dummy keyword list dropdown filter for share of search graph widget
      const filters = data.meta.localFilters || [];
      const allKeywordsObj = {
        dimensionName: 'search',
        dimensionValue: 'All Keywords'
      };
      const searchDimensionIndex = filters.findIndex(
        (item) => item.dimensionName === 'search'
      );
      if (searchDimensionIndex > -1) {
        filters.splice(searchDimensionIndex, 1, allKeywordsObj);
      } else {
        filters.push(allKeywordsObj);
      }
      data.meta.localFilters = filters;
      context.dispatch('updateShareOfSearchLocalFilterSelectedValue', {
        key: 'searchDistribution',
        value: ['All Keywords']
      });
    }

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    data.body.APIConfig.where.date = getSearchWhereClauseDate(context);

    if (!context.state.shareOfSearchDate) {
      data.body.APIConfig.where.date = getSearchWhereClauseDate(context);
    } else {
      data.body.APIConfig.where.date = context.state.shareOfSearchDate.date;
      if (context.state.shareOfSearchDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.state.shareOfSearchDate.pvpDate;
      }
    }

    if (isAdvertisingEnabled) {
      var whereClause = getWhereClause(context);
      data.body.APIConfig.where.dimensionNameValueList =
        data.body.APIConfig.where.dimensionNameValueList.concat(whereClause);
      context.dispatch('shareOfSearchAdvertising', data);
    } else {
      data.body.APIConfig.where.dimensionNameValueList.push({
        dimensionName: 'search_type',
        dimensionValue: 'ORGANIC'
      });
      context.dispatch('shareOfSearchSales', data);
    }
  },

  shareOfSearchAdvertising: (context, data) => {
    var graphApiConfig = JSON.parse(JSON.stringify(data.body.APIConfig));
    var amsApiConfig = JSON.parse(JSON.stringify(graphApiConfig));

    // ['count'] measure cube API request payload
    const graphApiDimensionList = graphApiConfig.where.dimensionNameValueList;
    const isAllKeywordsPresent = graphApiDimensionList.filter((item) => {
      return (
        item.dimensionName === 'search' &&
        item.dimensionValue === 'All Keywords'
      );
    }).length;
    if (isAllKeywordsPresent) {
      graphApiConfig.where.dimensionNameValueList = graphApiDimensionList
        .filter((item) => {
          return !(
            item.dimensionName === 'search' &&
            item.dimensionValue === 'All Keywords'
          );
        })
        .map((item) => {
          if (item.dimensionName === 'profile_name') {
            item.dimensionName = 'profile_name_sov';
          }
          return item;
        });
    } else {
      graphApiConfig.where.dimensionNameValueList =
        graphApiDimensionList.filter(
          (item) => item.dimensionName !== 'profile_name'
        );
    }

    // ['ams_spend'] measure cube API request payload
    amsApiConfig.cubeName = 'sov_search_level_with_marketing_data_widget_1';
    amsApiConfig.measuresList = ['ams_spend'];
    amsApiConfig.groupByDimensionsList = [];
    amsApiConfig.orderByList = [];
    amsApiConfig.dedupBeforeRollup.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions =
      ['search'];

    const dimensionNameValueList = amsApiConfig.where.dimensionNameValueList;
    const flagConditionArrLength = dimensionNameValueList.filter(
      (item) =>
        (item.dimensionName === 'search' &&
          item.dimensionValue === 'All Keywords') ||
        item.dimensionName === 'profile_name'
    ).length;

    if (flagConditionArrLength === 2) {
      dimensionNameValueList.push({
        dimensionName: 'is_search_belongs_to_profile',
        dimensionValue: true
      });
    } else {
      const nonRequiredKeyIndex = dimensionNameValueList.findIndex(
        (item) => item.dimensionName === 'is_search_belongs_to_profile'
      );
      dimensionNameValueList.splice(nonRequiredKeyIndex, 1);
    }

    // remove non required keys from 'ams_spend' api
    amsApiConfig.where.dimensionNameValueList = dimensionNameValueList
      .filter((item) => {
        return (
          !(
            item.dimensionName === 'search' &&
            item.dimensionValue === 'All Keywords'
          ) &&
          item.dimensionName !== 'search_type' &&
          item.dimensionName !== 'search_rank'
        );
      })
      .map((item) => {
        if (item.dimensionName === 'profile_name_sov') {
          item.dimensionName = 'profile_name';
        }
        return item;
      });
    amsApiConfig.getSharePercentage = false;

    // adding campaign type filter for amsSpend.
    const campaingTypeCheckboxValueMap = [
      {
        checkboxKey: 'checkboxSponsoredProducts',
        dimensionValue: 'sponsoredProducts'
      },
      {
        checkboxKey: 'checkboxSponsoredBrands',
        dimensionValue: 'sponsoredBrands'
      }
    ];
    for (const item of campaingTypeCheckboxValueMap) {
      if (context.state.shareOfSearchCheckbox[item.checkboxKey]) {
        amsApiConfig.where.dimensionNameValueList.push({
          dimensionName: 'campaign_type',
          dimensionValue: item.dimensionValue
        });
      }
    }

    // removing search_rank from dimension name and adding it measure list. (API optimization change)
    if (graphApiConfig.where.dimensionNameValueList) {
      graphApiConfig.where.dimensionNameValueList =
        graphApiConfig.where.dimensionNameValueList.filter((item) => {
          if (item.dimensionName === 'search_rank') {
            graphApiConfig.measuresList = [item.dimensionValue];
            return false;
          } else {
            return true;
          }
        });
    }
    // order by list depending on the measure selected.
    graphApiConfig.orderByList = [
      {
        dimension: 'client_flag',
        direction: 'ASC'
      },
      {
        dimension: graphApiConfig.measuresList[0],
        direction: 'DESC'
      }
    ];
    // make both the API calls
    HttpLayer.all([
      HttpLayer.post({
        APIData: graphApiConfig
      }),
      HttpLayer.post({
        APIData: amsApiConfig
      })
    ])
      .then((responses) => {
        if (responses[1].data.length > 0 && !responses[1].data[0].TIMESERIES) {
          responses[1].data = [];
        }
        var response = {
          data: responses[0].data.concat(responses[1].data)
        };
        if (!responses[0].success) {
          context.commit('SHAREOFSEARCHDATA_ERROR');
        } else {
          // replacing new measure key with count. (API optimization change)
          const regEx = new RegExp(graphApiConfig.measuresList[0], 'g');
          response = JSON.parse(
            JSON.stringify(response).replace(regEx, 'count')
          );
          graphDataProcess(response, data, context);
          if (context.state.shareOfSearchLegendDateApplied === false) {
            context.commit('SHAREOFSEARCH_LEGEND_RESET', true);
          }
          context.commit('SHAREOFSEARCH_LEGEND_DATE_APPLIED', false);
        }
      })
      .catch((error) => {
        context.commit('SHAREOFSEARCHDATA_ERROR');
        console.log(error);
      });
  },

  shareOfSearchSales: (context, data) => {
    var graphApiConfig = data.body.APIConfig;
    var dimensionNameValueList =
      graphApiConfig.where.dimensionNameValueList.filter((item) => {
        // removing search_rank from dimension name and adding it to measure list. (API optimization change)
        if (item.dimensionName === 'search_rank') {
          graphApiConfig.measuresList = [item.dimensionValue];
          return false;
        }
        return item.dimensionName !== 'profile_name';
      });
    graphApiConfig.where.dimensionNameValueList = dimensionNameValueList;
    // order by list depending on the measure selected. (API optimization change)
    graphApiConfig.orderByList = [
      {
        dimension: 'client_flag',
        direction: 'ASC'
      },
      {
        dimension: graphApiConfig.measuresList[0],
        direction: 'DESC'
      }
    ];
    HttpLayer.post({
      APIData: graphApiConfig
    })
      .then((response) => {
        if (!response.success) {
          context.commit('SHAREOFSEARCHDATA_ERROR');
        } else {
          // replacing new measure key with count. (API optimization change)
          const regEx = new RegExp(graphApiConfig.measuresList[0], 'g');
          response = JSON.parse(
            JSON.stringify(response).replace(regEx, 'count')
          );
          graphDataProcess(response, data, context);
          if (context.state.shareOfSearchLegendDateApplied === false) {
            context.commit('SHAREOFSEARCH_LEGEND_RESET', true);
          }
          context.commit('SHAREOFSEARCH_LEGEND_DATE_APPLIED', false);
        }
      })
      .catch((error) => {
        context.commit('SHAREOFSEARCHDATA_ERROR');
        console.log(error);
      });
  },
  setInsightsSearchPerfWarSelectedFilter: (context, data) => {
    // if no filters are applied and default profile name is present, then show the popup
    if (
      context.state.defaultProfileName &&
      context.getters.getProductConfig.indexOf('MarketingIQ') > -1 &&
      data.filterValueKey === 'filter' &&
      !Object.keys(data.values).length
    ) {
      context.commit('SHOW_POPUP_TO_SELECT_FILTERS');
    }
    context.commit('ISEARCH_SET_SELECTED_FILTER', data);
  },
  updateSearchPerfFilters: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.searchFilters || [],
        'search_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
      context.commit('SHAREOFSEARCH_LEGEND_RESET', true);
      context.commit('SHAREOFSEARCH_LEGEND_DATE_APPLIED', false);
      context.commit('UPDATE_TABLE_PAGE', 1);
      context.state.distributionAutoLoad = true;
    }, 1500);
  },
  getProfileName: (context, data) => {
    const APIData = {
      cubeName: 'client_profile_info',
      measuresList: ['profile_name'],
      groupByDimensionsList: ['client', 'profilename']
    };
    return HttpLayer.post({
      APIData: APIData
    });
  },
  getSearchPerfFilters: (context, data) => {
    const APIData = {
      cubeName: 'v3_sku_level_search_rank_with_transformed_metadata',
      measuresList: [],
      groupByDimensionsList: ['tags', 'search'],
      timeseriesDimension: 'feed_date',
      orderByList: [],
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'search_type',
            dimensionValue: 'ORGANIC'
          }
        ],
        date: {}
      }
    };
    HttpLayer.post({
      APIData: APIData
    }).then((response) => {
      if (!response.success) {
        // context.commit('IO_ERROR', {key: data.key});
      } else {
        context.state.searchFilters = response.data;
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data, 'search_')
        );
      }
    });
  },

  getSearchPerformanceData: (context, data) => {
    context.commit('KEYWORDS_TABLE_RESET', {
      key: data.meta.key
    });
    if (context.state.firstLoad) {
      context.commit('DISTRIBUTION_RESET');
      context.commit('SHAREOFSEARCHDATA_RESET');
    }
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    if (!context.state.searchPerformanceKeywordDate) {
      data.body.APIConfig.where.date = getSearchWhereClauseDate(context);
    } else {
      data.body.APIConfig.where.date =
        context.state.searchPerformanceKeywordDate.date;
      if (context.state.searchPerformanceKeywordDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.state.searchPerformanceKeywordDate.pvpDate;
      }
    }
    HttpLayer.post({
      cube: 'EXECUTE_CUBE_API_V2',
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          // if (response.data.length === 0) {
          //   context.commit('KEYWORDS_TABLE_ERROR', { key: data.meta.key });
          //   return
          // }
          var updatedRows = getSearchPerformanceUpdatedData(response.data);
          var totalRows =
            response.data.length === 0
              ? 1
              : response.data[0].RESULT.auto_cubesdk_count;
          context.commit('KEYWORDS_TABLE_SUCCESS', {
            key: data.meta.key,
            rows: updatedRows,
            totalRows: totalRows,
            page: data.body.APIConfig.page,
            completeTableData:
              transformer.getTableDataFromFullResponse(response)
          });
          if (updatedRows.length) {
            context.dispatch('fetchDependentWidgetsData', updatedRows);
          } else {
            // show 'No Data' state for both dependent widgets
            context.commit('DISTRIBUTION_SUCCESS', {
              rows: [],
              totalRows: []
            });
            const isAdvertisingEnabled =
              context.getters.getProductConfig.indexOf('MarketingIQ') > -1;
            if (!isAdvertisingEnabled) {
              context.commit('SHAREOFSEARCHDATA_SUCCESS', {
                columns: [],
                metrics: undefined
              });
            }
          }
        }
      })
      .catch((error) => {
        context.commit('KEYWORDS_TABLE_ERROR', { key: data.meta.key });
        console.log(error);
      });

    // get keyword list for local filters for both dependent widgets
    context.dispatch('updateLocalFilterKeywords', data.body.APIConfig);
  },

  updateLocalFilterKeywords: (context, widgetAPIConfig) => {
    var apiConfig = JSON.parse(JSON.stringify(widgetAPIConfig));
    apiConfig.orderByList = [
      {
        dimension: 'search_frequency_rank',
        direction: 'ASC'
      },
      {
        dimension: 'search',
        direction: 'ASC'
      }
    ];

    const dimensionNameValueList = apiConfig.where.dimensionNameValueList || [];
    const isAdvertisingEnabled =
      context.getters.getProductConfig.indexOf(marketingIQConfig) > -1;
    apiConfig.where.dimensionNameValueList = isAdvertisingEnabled
      ? dimensionNameValueList.filter((item) => item.dimensionName !== 'search')
      : dimensionNameValueList.filter(
          (item) => item.dimensionName !== 'profile_name'
        );

    var filterApiObject = Object.assign({}, apiConfig);
    delete filterApiObject.limit;
    delete filterApiObject.page;
    filterApiObject.cubeName = 'keyword_search_performance_v4';
    filterApiObject.measuresList = ['search_frequency_rank'];
    filterApiObject.groupByDimensionsList = ['search'];
    HttpLayer.post({
      APIData: filterApiObject
    })
      .then((response) => {
        if (!response.success) {
        } else {
          var isAdvertisingEnabled =
            context.getters.getProductConfig.indexOf(marketingIQConfig) > -1;
          context.commit('SET_LOCAL_FILTER_DATA', {
            data: transformer.getUniqueFilters(response.data, 'search_')
              .search_search,
            key: 'searchDistribution',
            isAdvertisingEnabled
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  fetchDistributionData: (context, data) => {
    context.state.distributionAutoLoad = false;
    context.commit('DISTRIBUTION_RESET');
    var apiConfig = {
      cubeName: 'v3_sku_level_search_rank_with_transformed_metadata',
      measuresList: ['count'],
      groupByDimensionsList: ['brand_by_client_flag', 'client_flag'],
      timeseriesDimension: 'feed_date',
      orderByList: [
        { dimension: 'client_flag', direction: 'ASC' },
        { dimension: 'count', direction: 'DESC' }
      ],
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      getSharePercentage: true
    };
    var date = '';
    if (!context.state.distributionDate) {
      date = getSearchWhereClauseDate(context);
    } else {
      date = context.state.distributionDate;
    }

    console.log(date);

    var apiConfigs = JSON.parse(JSON.stringify(apiConfig));
    apiConfigs.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    apiConfigs.where.date = {};
    apiConfigs.where.date.from = date.to;
    apiConfigs.where.date.to = date.to;
    apiConfigs.where.date.name = date.name;
    apiConfigs.where.date.page_wise_min_max_key = state.page_wise_min_max_key;
    apiConfigs.getSharePercentage = true;
    apiConfigs.measuresList = ['count'];
    apiConfigs.groupByDimensionsList = ['brand_by_client_flag', 'client_flag'];

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    data.body.APIConfig.where.date = {};
    data.body.APIConfig.where.date.from = date.to;
    data.body.APIConfig.where.date.to = date.to;
    data.body.APIConfig.where.date.name = date.name;
    data.body.APIConfig.where.date.page_wise_min_max_key =
      state.page_wise_min_max_key;
    var available = false;
    for (
      var i = 0;
      i < data.body.APIConfig.where.dimensionNameValueList.length;
      i++
    ) {
      if (
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
          'search_type' &&
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionValue ===
          'ORGANIC'
      ) {
        available = true;
        break;
      }
    }
    if (available === false) {
      data.body.APIConfig.where.dimensionNameValueList.push(organicSearch);
    }
    apiConfigs.where.dimensionNameValueList =
      data.body.APIConfig.where.dimensionNameValueList;

    var searchNotPresent = true;
    var searchObj = {};
    for (var j = 0; j < apiConfigs.where.dimensionNameValueList.length; j++) {
      if (
        apiConfigs.where.dimensionNameValueList[j].dimensionName === 'search'
      ) {
        searchNotPresent = false;
        searchObj = apiConfigs.where.dimensionNameValueList[j];
        break;
      }
    }
    if (searchNotPresent === true) {
      /*
       * This shows that we don't have the search hence we trigger empty commit and return before API request to backend
       */
      context.commit('DISTRIBUTION_SUCCESS', {
        key: data.meta.key,
        rows: [],
        totalRows: [],
        page: data.body.APIConfig.page
      });
      return;
    }
    if (context.getters.getProductConfig.indexOf(marketingIQConfig) > -1) {
      data.body.APIConfig.where.dimensionNameValueList = [searchObj];
      apiConfigs.where.dimensionNameValueList = [searchObj];
    } else {
      data.body.APIConfig.where.dimensionNameValueList = [
        searchObj,
        organicSearch
      ];
      apiConfigs.where.dimensionNameValueList = [searchObj, organicSearch];
    }

    HttpLayer.all([
      HttpLayer.post({
        APIData: apiConfigs
      }),
      HttpLayer.post({
        APIData: data.body.APIConfig
      })
    ])
      .then((responses) => {
        responses[0] = transformer.mergeResultDimension(responses[0].data);
        responses[1] = transformer.mergeResultDimension(responses[1].data);
        var barsData = convertToSnapShotFormat(responses[1], context);
        context.commit('DISTRIBUTION_SUCCESS', {
          key: data.meta.key,
          rows: barsData,
          tmLegends: getDistributionDataLegends(
            context,
            responses[0],
            responses[1]
          ),
          sortedLegends: getSortedLegends(responses[0]),
          page: data.body.APIConfig.page
        });
      })
      .catch((error) => {
        context.commit('DISTRIBUTION_ERROR');
        console.log(error);
      });
  },

  getBrandsTableData: (context, data) => {
    context.commit('BRANDS_TABLE_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    if (!context.state.searchPerformanceBrandsDate) {
      data.body.APIConfig.where.date = getSearchWhereClauseDate(context);
    } else {
      data.body.APIConfig.where.date =
        context.state.searchPerformanceBrandsDate.date;
      if (context.state.searchPerformanceBrandsDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.state.searchPerformanceBrandsDate.pvpDate;
      }
    }

    HttpLayer.post({
      cube: 'SOV_BRANDS_CUSTOM',
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          context.commit('BRANDS_TABLE_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            page: data.body.APIConfig.page,
            completeTableData:
              transformer.getTableDataFromFullResponse(response)
          });
        }
      })
      .catch((error) => {
        context.commit('BRANDS_TABLE_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  downloadSearchPerformanceTableData: (context, data) => {
    var apiConfigToSend = JSON.parse(JSON.stringify(data.body.APIConfig));
    apiConfigToSend.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.whereClause
    );
    apiConfigToSend.where.date = getSearchWhereClauseDate(context);
    apiConfigToSend.where.dimensionNameValueList.push(organicSearch);
    apiConfigToSend.measuresList.splice(0, 1);

    return HttpLayer.post({
      APIData: apiConfigToSend
    }).then((response) => {
      return getSearchPerformanceUpdatedData(response.data, true);
    });
  },

  updateLocalFilterSelectedValue: (context, data) => {
    context.commit('UPDATE_LOCAL_FILTER_SELECTED_VALUE', data);
  },

  updateShareOfSearchLocalFilterSelectedValue: (context, data) => {
    context.commit('UPDATE_SOS_LOCAL_FILTER_SELECTED_VALUE', data);
  },

  setShareOfSearchLegendReset: (context, data) => {
    context.commit('SHAREOFSEARCH_LEGEND_RESET', data);
  },

  setShareOfSearchLegendDateApplied: (context, data) => {
    context.commit('SHAREOFSEARCH_LEGEND_DATE_APPLIED', data);
    context.commit('SHAREOFSEARCH_LEGEND_RESET', false);
  },
  setTablePage: (context, data) => {
    context.commit('UPDATE_TABLE_PAGE', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
