import moment from 'moment';
import Vue from 'vue';
import { timeConstant } from '@/components/ams/campaigns/constants.js';

const { filters } = require('@ciq-dev/ciq-ui');

export function formatter(
  value,
  format,
  customNAString,
  numberPrecision = 2,
  maxNumberOfPrecision = 4,
  selectedCurrency = {}
) {
  const locale =
    selectedCurrency?.locale || filters.config_check('feature.i18n.locale');
  const currencyString =
    selectedCurrency?.abbreviation ||
    filters.config_check('feature.i18n.currency');
  let formattedNum;
  if (!format) {
    format = 'string';
  }

  if (value === undefined || value === null || value === '') {
    if (customNAString) {
      return customNAString;
    } else {
      return 'NA';
    }
  }
  if (format.toLowerCase() === 'string') {
    return value;
  } else if (format.toLowerCase() === 'currency') {
    const updatedValue = value * (selectedCurrency?.factor || 1);
    // for displaying very small values that get rounded off to 2 digits after decimal eg: 0.00013
    if (updatedValue > 0 && updatedValue < 1) {
      const afterDecimalZeroCount = -Math.floor(Math.log10(value) + 1);
      if (
        afterDecimalZeroCount > 1 &&
        afterDecimalZeroCount < maxNumberOfPrecision
      ) {
        numberPrecision = Math.max(afterDecimalZeroCount + 2, numberPrecision);
      }
    }
    formattedNum = new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'long',
      style: 'currency',
      currency: currencyString,
      maximumFractionDigits: numberPrecision
    }).format(updatedValue);
    // return value;
  } else if (
    format.toLowerCase() === 'percent' ||
    format.toLowerCase() === 'percentage'
  ) {
    // for displaying very small values that get rounded off to 2 digits after decimal eg: 0.00013
    if (value > 0 && value < 1) {
      const afterDecimalZeroCount = -Math.floor(Math.log10(value) + 1);
      if (
        afterDecimalZeroCount > 1 &&
        afterDecimalZeroCount < maxNumberOfPrecision
      ) {
        numberPrecision = Math.max(afterDecimalZeroCount + 2, numberPrecision);
      }
    }
    formattedNum = new Intl.NumberFormat(locale, {
      notation: 'compact',
      style: 'percent',
      maximumFractionDigits: numberPrecision
    }).format(value / 100);
  } else if (format.toLowerCase() === 'date') {
    // return new Date(value)
    //   .toLocaleString(locale, { timeZone: 'UTC' })
    //   .split(',')[0];
    // Format Change for KC-UK
    return moment.utc(value).format('MMM DD, YYYY');
  } else if (format === 'percentFraction') {
    formattedNum = new Intl.NumberFormat(locale).format(value) + '/100';
  } else if (format.toLowerCase() === 'numeric') {
    // obj = getValueAlphabets(value, numberPrecision);
    // for displaying very small values that get rounded off to 2 digits after decimal eg: 0.00013
    if (value > 0 && value < 1) {
      const afterDecimalZeroCount = -Math.floor(Math.log10(value) + 1);
      if (
        afterDecimalZeroCount > 1 &&
        afterDecimalZeroCount < maxNumberOfPrecision
      ) {
        numberPrecision = Math.max(afterDecimalZeroCount + 2, numberPrecision);
      }
    }
    formattedNum = new Intl.NumberFormat(locale, {
      notation: 'compact',
      maximumFractionDigits: numberPrecision
    }).format(value);
  } else if (format.toLowerCase() === 'numberic_x') {
    formattedNum = new Intl.NumberFormat(locale).format(value) + 'X';
  } else if (format.toLowerCase() === 'number') {
    const number = Number(value).toFixed(numberPrecision);
    formattedNum = new Intl.NumberFormat(locale).format(number);
  } else if (format.toLowerCase() === 'decimal') {
    const number = Number(value).toFixed(2);
    formattedNum = new Intl.NumberFormat(locale).format(number);
  } else if (format.toLowerCase() === 'datetime') {
    formattedNum = moment(moment.utc(value).local()).format(
      'DD MMM, YYYY hh:mm A'
    );
  } else {
    formattedNum = value;
  }
  if (locale === 'en-IN') {
    // for india T for thousand was given by the library in compact form.
    // But product team wants K in place of T. So we have this custom logic for india region.
    formattedNum = formattedNum.replace('T', 'k');
  }
  return formattedNum;
}

export const utcToLocalDate = (utcDateString) => {
  const time = moment(moment.utc(utcDateString).local()).format('DD MMM, YYYY');
  return time;
};

export const utcToLocalTime = (utcDateString) => {
  const time = moment(moment.utc(utcDateString).local()).format('hh:mm A');
  return time;
};

export const parseTime = (timeString, format) => {
  return moment(timeString, format);
};

export const parseDate = (dateString, format) => {
  return moment(dateString, format);
};

export const formatTime = (time, format) => {
  return time.format(format);
};

export const formatDate = (date, format) => {
  return date.format(format);
};

export const capitalize = (text) => {
  if (typeof text !== 'string') return '';
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const calculateTimeString = (
  totalEntities,
  multiplier = 1,
  bundleSize = 1
) => {
  const defaultTime = 5;
  let totalTime =
    Math.floor((multiplier * (totalEntities / bundleSize)) / timeConstant) + 5; // in Mins
  totalTime = Math.ceil(totalTime / 5) * 5;
  if (totalTime > 5 && totalTime < timeConstant) {
    return `${totalTime} minutes`;
  } else if (totalTime > timeConstant) {
    const hours = Math.floor(totalTime / timeConstant);
    const leftoverMins = Math.floor((totalTime % timeConstant) / timeConstant);
    return `${hours} hours ${leftoverMins} minutes`;
  }
  return `${defaultTime} minutes`;
};

export function getTippyString(tag, func, unit) {
  const expandedCurrency = Math.round(Math.abs(tag)).toLocaleString(func(), {
    minimumFractionDigits: 0
  });
  const noTippyRequired = expandedCurrency.length < 4;
  const tippyValue = `${
    unit?.toLowerCase() === 'currency' ? Vue.prototype.$currency : ''
  }${expandedCurrency}`;
  return noTippyRequired ? '' : tippyValue;
}

export function getLocale() {
  return filters.config_check('feature.i18n.locale');
}

export const cubeDateFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  } else {
    return moment().format('YYYY-MM-DD');
  }
};

export const isRightAligned = (type) => {
  return !!['number', 'numeric'].includes(type.toLowerCase());
};
export const isTypeNumeric = (type) => {
  return !!['number', 'numeric'].includes(type.toLowerCase());
};
export function formatSelectedButton(button) {
  return button.split(' ').join('_').toLowerCase();
}

export const cryptoRandomGenerator = () => {
  const typedArray = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0];
  const randomFloat = randomValue / Math.pow(2, 8);
  return randomFloat;
};
