<template>
  <div
    v-if="!lineItem.hide"
    :class="lineItem.class"
  >
    <span
      v-if="(lineItem.attribute || {}).titleIconTooltip"
      v-tippy="tippy"
      :title="(lineItem.attribute || {}).titleIconTooltip"
    >
      <rb-icon
        v-if="(lineItem.attribute || {}).titleIcon"
        :icon="(lineItem.attribute || {}).titleIcon"
        class="u-flex-0 rb-icon--small u-color-grey-lighter"
      />
    </span>
    <span
      v-if="lineItem.attribute && lineItem.attribute.title"
      :class="
        (lineItem.attribute && lineItem.attribute.titleClass) ||
        'u-font-size-5 u-color-grey-lighter u-font-weight-600'
      "
    >
      {{ lineItem.attribute.title }}:&nbsp;
    </span>

    <span v-if="lineItem.attribute && lineItem.attribute.values">
      <span
        v-for="(value, valuesIndex) in lineItem.attribute.values"
        :key="valuesIndex"
      >
        <!--For item with toolTip-->
        <span
          v-if="lineItem.attribute.hoverText"
          v-tippy="tippy"
          class="spacing-class"
          :class="
            (lineItem.attribute.valueClasses &&
              lineItem.attribute.valueClasses[valuesIndex] &&
              createDynamicClass(
                lineItem.attribute.valueClasses[valuesIndex]
              )) ||
            'u-font-size-5'
          "
          :title="
            lineItem.attribute.hoverText &&
            lineItem.fromMetadata &&
            metadata[lineItem.attribute.hoverText[valuesIndex]]
          "
        >
          {{
            lineItem.attribute.pre && lineItem.attribute.pre[valuesIndex]
              ? lineItem.attribute.pre[valuesIndex]
              : ''
          }}
          {{
            lineItem.fromMetadata
              ? metadata[value] || lineItem.attribute.altValues[valuesIndex]
              : lineItem.attribute.altValues[valuesIndex]
          }}
          {{
            valuesIndex !== lineItem.attribute.values.length - 1
              ? lineItem.attribute.delimiter
              : ''
          }}
        </span>
        <!--For item withOut toolTip-->
        <span
          v-else
          :class="
            (lineItem.attribute.valueClasses &&
              lineItem.attribute.valueClasses[valuesIndex] &&
              createDynamicClass(
                lineItem.attribute.valueClasses[valuesIndex]
              )) ||
            'u-font-size-5'
          "
        >
          {{
            lineItem.attribute.pre && lineItem.attribute.pre[valuesIndex]
              ? lineItem.attribute.pre[valuesIndex]
              : ''
          }}
          {{
            lineItem.fromMetadata
              ? metadata[value] || lineItem.attribute.altValues[valuesIndex]
              : lineItem.attribute.altValues[valuesIndex]
          }}
          {{
            valuesIndex !== lineItem.attribute.values.length - 1
              ? lineItem.attribute.delimiter
              : ''
          }}
        </span>
      </span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    lineItem: {
      default: () => {},
      type: Object
    },
    lineIndex: {
      default: 0,
      type: Number
    },
    metadata: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      tippy: {
        theme: 'light',
        placement: 'bottom-start',
        arrow: false,
        interactive: false,
        popperOptions: {
          modifiers: {
            offset: {
              offset: '30px, 4px'
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  methods: {
    createDynamicClass(classStyle) {
      if (classStyle.includes('[')) {
        const stratIndex = classStyle.indexOf('[');
        const endIndex = classStyle.indexOf(']');
        const metadtaValue =
          this.metadata[classStyle.substring(stratIndex + 1, endIndex)];
        const newItem = classStyle.replace(
          classStyle.substring(stratIndex, endIndex + 1),
          metadtaValue
        );
        return newItem;
      }
      return classStyle;
    }
  }
};
</script>

<style scoped>
.spacing-class {
  white-space: pre;
}
</style>
