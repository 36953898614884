import addGroupAddSkuConfig, {
  addSkuSpAuto as addSkuSpAutoFromWalmart
} from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupAddSkuConfig.js';
import { getRedirectToFunctionDefinition } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';

export const addSkuSpAuto = {
  ...addSkuSpAutoFromWalmart,
  btnRightText: 'Next',
  redirectTo: getRedirectToFunctionDefinition('samsclub_apiEntityDetails')
};
export default addGroupAddSkuConfig;
