// Entity details config file for Sponsored Products
// Widget_template's are tabs that are imported below
// Common components used within widget_template files

// -- importing componenets here --
import {
  spAddKeywordsRecommendationComponentConfig,
  addEfficientSearchTermsAsKeywordsRecommendationComponentConfig,
  alignCampaignToBestPracticesRecommendationComponentConfig,
  addInEfficientSearchTermsAsNegativeTargetRecommendationComponentConfig
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';
import {
  addTargetsToCampaignActionPanel,
  addCategoriesToCampaignActionPanel,
  addSearchTermTargetsToCampaignActionPanel,
  addSearchTermNegativeTargetsToCampaignActionPanel,
  addNegativeKeywordsEnterListComponentConfig,
  addNegativeTargetsEnterListComponentConfig
} from '@/pages/entity_details/configs/amazon/actionConfigs.js';
import {
  getMetaDataConfig,
  getHeaderConfigs,
  getChartConfigs,
  getAddSearchTermsAsKeywordPayloadTemplate,
  getAddSearchTermsAsNegativeKeywordPayloadTemplate
} from '@/pages/entity_details/configs/amazon/configUtils.js';

const budget = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const placement = () =>
  import('@/pages/entity_details/widget_templates/placement.vue');
const biddingStrategy = () =>
  import('@/pages/entity_details/widget_templates/biddingStrategy.vue');
const settings = () =>
  import('@/pages/entity_details/widget_templates/settings/index.vue');
const adgroupsTargetingKeyword = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupsTargetingKeyword.vue'
  );
const adgroupSearchTermKeyword = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupSearchTerm.vue'
  );
const adgroupsTargetingProduct = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupsTargetingProduct.vue'
  );
const adgroupSearchTermProduct = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupSearchTerm.vue'
  );
const adgroupSettings = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupSettings.vue'
  );
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const adgroupKeywordNegativeTargeting = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupKeywordNegativeTargeting.vue'
  );
const adgroupProductNegativeTargeting = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupKeywordNegativeTargeting.vue'
  );
const adgroupDefaultBid = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');
export const adgroupDefaultBidConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  reqMetadataMap: {
    ':oldBudget': 'defaultBid',
    ':campaignId': 'parentEntityId'
  },
  config: {
    title: 'Default bid:',
    adjustButton: 'Adjust bid',
    adjustTitle: 'Adjust default bid:',
    type: 'default bid'
  },
  isAdgroup: true,
  component: adgroupDefaultBid,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      campaignType: 'SP',
      adgroup_id: ':entityId',
      new_bid: ':newBudget'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name',
      view_old_bid: ':oldBudget',
      new_bid: ':newBudget'
    },
    actionType: 'adgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  }
};

export const adGroupSettingsConfig = {
  isAdgroup: true,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      adgroup_id: ':entityId',
      campaignType: 'SP'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name'
    },
    actionType: 'adgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  },
  component: adgroupSettings,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  }
};

const adgroupsTargetingKeywordConfig = {
  component: adgroupsTargetingKeyword,
  recCustomComponent: (context) => {
    return {
      RECOMMENDATIONS_KEYWORDS_FOR_SKUS:
        spAddKeywordsRecommendationComponentConfig(context),
      RECOMMENDATIONS_KEYWORDS_EFFICIENT_SEARCHTERMS:
        addEfficientSearchTermsAsKeywordsRecommendationComponentConfig(context),
      RECOMMENDATIONS_KEYWORDS_CAMPAIGN_BESTPRACTICE:
        alignCampaignToBestPracticesRecommendationComponentConfig(context)
    };
  },
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        adgroup_id: ':adgroupId',
        campaignType: 'SP',
        new_bid: ':newBid',
        keyword_id: ':keywordId',
        old_bid: ':oldBid'
      },
      viewPayload: {
        campaignId: ':campaignId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName'
      },
      actionType: 'keywordMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        keyword_id: ':keywordId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName'
      },
      actionType: 'keywordMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  }
};

const adgroupsTargetingProductConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  customActionPanelConfigs: {
    products: addTargetsToCampaignActionPanel,
    categories: addCategoriesToCampaignActionPanel
  },
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
        { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
        { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
      ]
    },
    {
      icon: 'dollar',
      title: 'Adjust Bid',
      id: 'adjustBids'
    }
  ],
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        adgroup_id: ':adgroupId',
        campaignType: 'SP',
        new_bid: ':newBid',
        target_id: ':keywordId',
        old_bid: ':oldBid'
      },
      viewPayload: {
        campaignId: ':campaignId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        target_id: ':keywordId',
        asin_id: ':asin_id'
      },
      actionType: 'targetMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        target_id: ':keywordId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName',
        asin_id: ':asin_id'
      },
      actionType: 'targetMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addTargetsToCampain: {
    requestPayload: {
      actionType: 'addTargetToCampaigns',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid',
        new_expression: [
          {
            type: ':expressionType', // asinSameAs OR asinCategorySameAs
            value: ':asin_id' // asing or category
          }
        ],
        new_keyword_text: ':entity_text' // value in the new_expression
      },
      viewPayload: {
        adgroupName: ':adgroupName',
        entityId: ':asin_id',
        asin: ':asin_id',
        type: ':entityType', // product or category
        entityName: ':asin_title',
        match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid'
      }
    }
  }
};

export const adgroupADsConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
        { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
        { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
      ]
    }
  ],
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        ad_id: ':ad_id',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        view_adgroup_name: ':adgroupName',
        ad_id: ':ad_id',
        sku: ':asin_id'
      },
      actionType: 'productAdStatusChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addCustomProducts: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        sku: ':asin_id',
        asin: ':asin_id',
        state: 'enabled'
      },
      viewPayload: {
        campaignId: ':campaignId',
        sku: ':asin_id',
        asin: ':asin_id',
        product_title: ':asin_title',
        image_url: ':image_url',
        adgroupName: ':adgroupName'
      },
      actionType: 'addAsinCampaign',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  }
};

const adgroupSearchTermConfig = {
  campaignType: 'SP',
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  addAsKeywordPayload: {
    requestPayload: getAddSearchTermsAsKeywordPayloadTemplate('SP')
  },
  addAsNegativeKeywordPayload: {
    requestPayload: getAddSearchTermsAsNegativeKeywordPayloadTemplate('SP')
  },
  addSearchTermTargetsToCampaign: {
    requestPayload: {
      actionType: 'addTargetToCampaigns',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid',
        new_expression: [
          {
            type: ':expressionType', // asinSameAs OR asinCategorySameAs
            value: ':asin_id' // asing or category
          }
        ],
        new_keyword_text: ':asin_id' // value in the new_expression
      },
      viewPayload: {
        adgroupName: ':adgroupName',
        entityId: ':asin_id',
        asin: ':asin_id',
        type: ':entityType', // product or category
        entityName: ':asin_title',
        match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid'
      }
    }
  },
  addAsNegativeTargets: {
    requestPayload: {
      actionType: 'addNegativeTarget',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        state: 'enabled',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: ':matchType',
        new_keyword_text: ':asin_id',
        new_expression: [
          {
            type: 'asinSameAs', // asinCategorySameAs
            value: ':asin_id'
          }
        ]
      },
      viewPayload: {
        campaignId: ':campaignId',
        adgroupId: ':adgroupId',
        adgroupName: ':adgroupName',
        keywordText: ':asin_id', // value in new expression
        matchType: ':matchType'
      }
    }
  }
};

const adgroupNegativeTargetingConfig = {
  recCustomComponent: (context) => {
    return {
      RECOMMENDATIONS_NEGATIVEKEYWORDS_INEFFICIENT_SEARCHTERMS:
        addInEfficientSearchTermsAsNegativeTargetRecommendationComponentConfig(
          context
        )
    };
  },
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  negativeKeyword: {
    customActionPanelComponent: addNegativeKeywordsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeArchive'
      }
    ],
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaignId: ':campaignId',
          keyword_id: ':keywordId',
          campaignType: 'SP',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          match_type: ':matchType',
          adgroup_name: ':adgroupName'
        },
        actionType: 'updateNegativeKeywordState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    }
  },
  negativeTarget: {
    customActionPanelComponent: addNegativeTargetsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeTargetArchive'
      }
    ],
    addNegativeTargetRequestPayload: {
      actionType: 'addNegativeTarget',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        state: 'enabled',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: ':matchType',
        new_keyword_text: ':newKeywordText',
        new_expression: [
          {
            type: ':expressionType', // asinCategorySameAs
            value: ':newKeywordText'
          }
        ]
      },
      viewPayload: {
        campaignId: ':campaignId',
        adgroupId: ':adgroupId',
        // adgroupName: "Terminate",
        keywordText: ':viewPayloadKeywordText', // value in new expression
        matchType: ':matchType'
      }
    },
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaignId: ':campaignId',
          target_id: ':keywordId',
          campaignType: 'SP',
          expressionType: 'manual',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          match_type: ':matchType',
          adgroup_name: ':adgroupName'
        },
        actionType: 'updateNegativeTargetState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    }
  }
};

const objTabadgroupProductNegativeTargeting = {
  component: adgroupProductNegativeTargeting,
  isProductAdgroup: true,
  ...adgroupNegativeTargetingConfig
};

export default {
  campaign: {
    page: 'cm_campaign_sp',
    metadata: [
      {
        action: 'entityDetails/fetchCampaingDetails',
        getters: 'entityDetails/getCampaignDetails'
      }
    ],
    header: {
      maxFeedDate: 'ams_campaign',
      filters: {
        config: {
          emit: 'spCampaignDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'spCampaignDetailsAddToFilters'
        },
        filterLocalStorageKey: 'campaigns-filters',
        page: 'spCampaingDetails'
      },
      activityLog: {
        show: true
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        config: {
          name: 'Campaigns',
          params: {}
        }
      },
      // line 1 and 2 will wrap around date picker if its present
      metadata_api_state: {
        getter: 'entityDetails/getCampaignDetails'
      },
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Campaign',
              values: ['name'],
              hoverText: ['name'],
              titleClass:
                'u-font-weight-700 u-color-grey-lighter u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['NA']
            },
            class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
            itemType: 'attribute'
          }
        ],
        class: ''
      },
      line2: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Duration',
              values: ['startDate', 'endDate'],
              titleClass: null,
              delimiter: '-',
              valueClasses: null,
              altValues: ['No start date', 'No end date']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Budget',
              values: ['dailyBudgetView', ''],
              titleClass: null,
              delimiter: ' ',
              valueClasses: null,
              altValues: ['NA', 'Daily']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Type',
              values: ['campaignType', 'targetingTypeView'],
              titleClass: null,
              delimiter: '/',
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA', 'NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-m u-font-size-5'
      },
      line3: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Status',
              values: ['state'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-color-[color]-base u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            // since sb fetch is showing wrong data for campaign id hiding this field
            hide: true,
            fromMetadata: true,
            attribute: {
              title: 'Campaign ID',
              values: ['campaignId'],
              titleClass: null,
              delimiter: '',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
          // {
          //   fromMetadata: true,
          //   attribute: {
          //     title: 'Hourly Bidder Status',
          //     values: ['hourly_bidder_status'],
          //     titleClass: null,
          //     delimiter: null,
          //     valueClasses: null,
          //     altValues: ['NA']
          //   },
          //   class: '',
          //   itemType: 'attribute'
          // }
        ],
        class: 'u-spacing-pb-l u-font-size-5'
      }
    },
    chart: {
      page: 'cm_campaign_sp',
      maxFeedDate: 'ams_campaign',
      globalViewId: 0,
      pageId: 0,
      filter: 'spCampaignDetailsFiltersApplied'
    },
    // show tabs data if present
    tabs: {
      biddingStrategy: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SP',
            bidding_strategy: ':bidStrategy'
          },
          viewPayload: {
            bidding_strategy: ':viewBidStrategy',
            view_old_bidding_strategy: ':oldBidStrategy',
            campaignId: ':entityId'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: biddingStrategy,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        }
      },
      budget: {
        component: budget,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        reqMetadataMap: {
          ':oldBudget': 'dailyBudget',
          ':campaignId': 'entityId'
        },
        requestPayload: {
          actionPayload: {
            campaignId: ':campaignId',
            campaignType: 'SP',
            daily_budget: ':oldBudget',
            new_daily_budget: ':newBudget'
          },
          viewPayload: {
            name: ':name',
            campaignId: ':campaignId',
            campaignType: ':entityType',
            previousBudget: ':oldBudget',
            newBudget: ':newBudget'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        }
      },
      adGroups: {
        component: adGroups,
        stateChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'SP',
              adgroup_id: ':adgroupId',
              state: ':state'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_state: ':oldState',
              state: ':state'
            },
            actionType: 'adgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        bidChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'SP',
              adgroup_id: ':adgroupId',
              new_bid: ':newBid'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_bid: ':oldBid',
              new_bid: ':newBid'
            },
            actionType: 'adgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          getter: 'entityDetails/getCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        }
      },
      placement: {
        component: placement,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SP',
            bid_adjustment: ':newPercentage',
            placement: ':placement'
          },
          viewPayload: {
            campaignId: ':entityId',
            campaignName: ':campaignName',
            newPlacementBid: ':view_newPercentage',
            oldPlacementBid: ':view_oldPercentage',
            placementType: ':placement'
          },
          actionType: 'campaignPlacementChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        }
      },
      settings: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SP'
          },
          viewPayload: {
            campaignId: ':entityId'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: settings,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        }
      }
    }
  },
  keyword_adgroup: {
    page: 'cm_adgroups_keyword_sp',
    metadata: getMetaDataConfig(),
    header: getHeaderConfigs('sp'),
    chart: getChartConfigs('sp', 'keyword'),
    tabs: {
      adgroupsTargetingKeyword: adgroupsTargetingKeywordConfig,
      adgroupSearchTermKeyword: {
        component: adgroupSearchTermKeyword,
        allowed_actions: [
          {
            icon: 'add-circle-fill',
            title: 'Add as Keyword',
            id: 'addAsKeyword'
          },
          {
            icon: 'remove-fill',
            title: 'Add as Negative Keyword',
            id: 'addAsNegativeKeyword'
          }
        ],
        keyColumn: 'search_term',
        ...adgroupSearchTermConfig
      },
      adgroupProductADs: {
        component: adgroupProductADs,
        ...adgroupADsConfig
      },
      adgroupKeywordNegativeTargeting: {
        component: adgroupKeywordNegativeTargeting,
        isProductAdgroup: false,
        ...adgroupNegativeTargetingConfig
      },
      adgroupSettings: adGroupSettingsConfig,
      adgroupDefaultBid: adgroupDefaultBidConfig
    }
  },
  product_adgroup: {
    page: 'cm_adgroups_product_sp',
    metadata: getMetaDataConfig(),
    header: getHeaderConfigs('sp'),
    chart: getChartConfigs('sp', 'product'),
    tabs: {
      adgroupsTargetingProduct: {
        component: adgroupsTargetingProduct,
        ...adgroupsTargetingProductConfig
      },
      adgroupProductNegativeTargeting: objTabadgroupProductNegativeTargeting,
      adgroupSearchTermProduct: {
        boolProductTargeting: true,
        component: adgroupSearchTermProduct,
        action_panel_add_target: addSearchTermTargetsToCampaignActionPanel,
        action_panel_add_negative_target:
          addSearchTermNegativeTargetsToCampaignActionPanel,
        allowed_actions: [
          {
            icon: 'add-circle-fill',
            title: 'Add as Product Target',
            id: 'addAsTarget'
          },
          {
            icon: 'remove-fill',
            title: 'Add as Negative Product Target',
            id: 'addAsNegativeTarget'
          }
        ],
        keyColumn: 'ams_cm_searchterm_search_term',
        ...adgroupSearchTermConfig
      },
      adgroupProductADs: {
        component: adgroupProductADs,
        ...adgroupADsConfig
      },
      adgroupSettings: adGroupSettingsConfig,
      adgroupDefaultBid: adgroupDefaultBidConfig
    }
  },
  auto_adgroup: {
    page: 'cm_adgroups_auto_sp',
    metadata: getMetaDataConfig(),
    header: getHeaderConfigs('sp'),
    chart: getChartConfigs('sp', 'auto'),
    tabs: {
      adgroupsTargetingKeyword: {
        disableAddTargetButton: true,
        ...adgroupsTargetingKeywordConfig
      },
      adgroupSearchTermKeyword: {
        component: adgroupSearchTermKeyword,
        allowed_actions: [
          {
            icon: 'remove-fill',
            title: 'Add as Negative Keyword',
            id: 'addAsNegativeKeyword'
          }
        ],
        keyColumn: 'search_term',
        ...adgroupSearchTermConfig
      },
      adgroupProductADs: {
        component: adgroupProductADs,
        ...adgroupADsConfig
      },
      adgroupProductNegativeTargeting: objTabadgroupProductNegativeTargeting,
      adgroupKeywordNegativeTargeting: {
        showToggle: true,
        isProductAdgroup: false,
        component: adgroupKeywordNegativeTargeting,
        toggleTabConfigs: {
          tabsList: ['Negative Keyword', 'Negative Product'],
          switch: {
            'Negative Keyword': {
              widget: 'adgroup_sp_tab_negativekeywordtargeting',
              page: 'cm_adgroup_sp_tab_negativekeywordtargeting',
              componentName: 'adgroupKeywordNegativeTargeting',
              component: adgroupKeywordNegativeTargeting
            },
            'Negative Product': {
              widget: 'adgroup_sp_tab_negativeproducttargeting',
              page: 'cm_adgroup_sp_tab_negativeproducttargeting',
              componentName: 'adgroupProductNegativeTargeting',
              component: adgroupProductNegativeTargeting
            }
          }
        },
        ...adgroupNegativeTargetingConfig
      },
      adgroupSettings: adGroupSettingsConfig,
      adgroupDefaultBid: adgroupDefaultBidConfig
    }
  }
};
