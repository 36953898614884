<template>
  <!-- Bid Change Panel -->
  <section
    class="addKeywordPanel actionsPanel u-bg-color-grey-white u-position-relative"
  >
    <div
      v-show="selections.length > 1 && !bulkActionsAllowed"
      class="fillMessage fill--parent u-font-size-3"
    >
      Multiple campaigns are not allowed
    </div>
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="
        tableLoader || keywordsLoading || addGroupLoader || adGroupsLoading
      "
      :color="'#007cf6'"
    />
    <section
      :class="{
        visibilityHidden:
          tableLoader || keywordsLoading || addGroupLoader || adGroupsLoading
      }"
      class="containerVisibilty"
    >
      <baseSplitPanelTemplate
        v-if="selections.length === 1 && !bulkActionsAllowed"
        :panel-config="panelConfig"
        @updatedPanelData="updatePanelData"
      >
        <div
          v-if="campaignKeywordsData.rows.length === 0"
          slot="source-filter"
        />
        <div
          slot="source-body"
          class="tableContainerKeywordsPanel"
        >
          <div class="table-right u-spacing-pt-m u-position-relative">
            <div
              v-if="campaignKeywordsData.rows.length === 0"
              class="fill--parent message"
            >
              No keyword(s) found for selected Campaign
            </div>
            <rb-insights-table
              v-if="campaignKeywordsData.rows.length > 0"
              :config="tableConfigsLeft"
              :grid-options="gridOptions"
              :auto-configure="campaignKeywordsData"
              style="width: 100%"
              :row-height="40"
              :client-side-pagination="true"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :pagination-total-key="rows.length"
              :pagination-per-page-limit="18"
              :download-this-table="false"
              :get-current-instance="getCurrentSourceInstance"
              :row-class-rules="rowClassRules"
              :row-no-hover-state="true"
            />
          </div>
        </div>
        <div
          slot="result-body"
          class="tableContainerKeywordsPanel"
        >
          <div v-if="campaignKeywordsData.rows.length > 0">
            <div
              class="u-spacing-ph-l u-spacing-pb-s u-spacing-pt-l u-font-weight-600 u-font-size-5"
            >
              Ad group:
            </div>
            <div
              v-if="adGroups.length > 0"
              class="u-spacing-ph-l u-spacing-pb-m u-max-width-50 u-display-flex"
            >
              <rb-select
                class-name="bp-select"
                :is-multi-select="true"
                class="u-spacing-mr-s valueDropdown u-max-width-100"
                :send-details="true"
                :on-close="adGroupSelected"
                :options="adGroups"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-spacing-ph-xxs u-spacing-ph-xs u-cursor-pointer"
                >
                  <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                    {{ adGroupTitle }}
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                    icon="caret-down"
                  />
                </div>
                <div
                  v-if="adGroups.length"
                  slot="footer"
                  class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-m u-border-top u-border-width-s u-border-color-grey-xxx-light"
                >
                  <rb-button
                    :click-fn="clickOutside"
                    :type="'filled'"
                    text="Apply"
                    size="s"
                  />
                </div>
              </rb-select>
              <div class="dummy-element">&nbsp;</div>
            </div>
            <div
              v-else
              class="u-font-size-5 u-spacing-pb-s u-spacing-ph-l u-color-red-base"
            >
              Ad groups for this campaign are not present, hence no action can
              be taken.
            </div>
          </div>

          <div class="table-left u-position-relative u-spacing-pt-xs">
            <div
              v-if="updatedResultTableRows.length === 0"
              class="fill--parent message"
            >
              <div class="u-spacing-pt-m">No keyword(s) added</div>
            </div>
            <rb-insights-table
              v-if="updatedResultTableRows.length > 0"
              :config="tableConfigsRight"
              :grid-options="gridOptions2"
              :auto-configure="getAddedKeywordsTableData"
              style="width: 100%"
              :row-height="48"
              :client-side-pagination="true"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :row-class-rules="resultRowClassRules"
              :pagination-total-key="getAddedKeywordsTableData.rows.length"
              :pagination-per-page-limit="18"
              :download-this-table="false"
              :get-current-instance="getCurrentResultInstance"
              :row-no-hover-state="true"
            />
          </div>
        </div>
      </baseSplitPanelTemplate>
      <BulkActions
        v-if="bulkActionsAllowed"
        ref="bulkActionsComponent"
        :radio-options="bulkRadioOptions"
        :heading="bulkActionsHeader"
        :radio-heading="bulkActionConfigs.radioHeading"
        :secondary-text="bulkActionSecondaryText"
        :custom-state-map="bulkActionConfigs.customStateMap"
        :groups-table-col-definition="
          bulkActionConfigs.groupsTableColDefinition
        "
        :groups-rows="adGroupsOfSelectedCampaigns"
        @handleRadioSelection="handleRadioSelection"
        @onSelectionChange="onAdGroupsSelectionsChange"
      >
        <div
          v-if="
            bulkActionConfigs.showAboveLeftCustomBid &&
            bulkActionConfigs.showAboveLeftCustomBid($refs.bulkActionsComponent)
          "
          slot="aboveLeftCustomBid"
          class="u-display-flex u-spacing-ph-l u-spacing-pb-m u-flex-align-items-center"
        >
          <div class="u-spacing-pr-s u-font-weight-600 u-font-size-5">
            Lookback:
          </div>
          <rb-select
            :options="lookBack"
            :send-details="true"
            class="u-width-160px u-spacing-mr-xs height-custom-bid u-border-all u-border-width-s u-border-color-grey-xxx-light u-flex-align-items-center u-flex-justify-content-center u-border-radius-s u-display-flex u-flex-align-items-center"
            :on-close="onSelectLookBack"
          >
            <div
              slot="trigger"
              class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
            >
              <div
                class="u-width-100 u-flex-align-items-center u-display-flex u-flex-justify-content-space-between"
              >
                <div class="u-font-size-6 u-spacing-pl-s">
                  {{ selectedLookBack ? `${selectedLookBack} days` : 'NA' }}
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                  icon="caret-down"
                />
              </div>
            </div>
          </rb-select>
        </div>
      </BulkActions>
      <footer
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
      >
        <rb-button
          :disabled="!tableValid"
          :click-fn="addKeywordsToCampaign"
          :text="'Apply'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s"
        />
        <rb-button
          :click-fn="closePanel"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
          class="u-spacing-mr-s"
        />
        <div
          v-if="showValueError"
          class="u-spacing-ph-xs u-spacing-pt-xs u-flex-0 u-font-size-7 u-color-red-base"
        >
          {{ errorMessage }}
        </div>
      </footer>
    </section>
  </section>
</template>

<style lang="css">
.containerVisibilty {
  visibility: unset;
}
.visibilityHidden {
  visibility: hidden;
}
.tableContainerKeywordsPanel .table-right {
  height: 544px !important;
}
.tableContainerKeywordsPanel .table-left {
  height: 535px !important;
}
.tableContainerKeywordsPanel .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.tableContainerKeywordsPanel .message {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #8b8f93;
}
.hideBorderAddKeywordsToCampaigns {
  border-top: none !important;
}
.removePointerEvents {
  pointer-events: none;
}
.ag-row-diabled {
  opacity: 0.1;
  pointer-events: none;
}
.addKeywordPanel .ag-row.ag-row-level-0 div:nth-child(2) .cell--content,
.addKeywordPanel
  .ag-row.ag-row-first.ag-row-last
  div:nth-child(3)
  .cell--content {
  text-transform: capitalize;
}
.fillMessage {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.disabledTableRowKeywordsPanel .ag-cell:nth-child(2) div {
  opacity: 0.7 !important;
}
.disabledTableRowKeywordsPanel .ag-cell:nth-child(3) div {
  opacity: 0.7 !important;
}
.disableSuggBid .ag-cell:nth-child(3) div {
  opacity: 0.7 !important;
  pointer-events: none;
}
</style>

<script>
import loader from '@/components/basic/loader';
import Vue from 'vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons } from '@/components/ams/campaigns/commons.js';
import baseSplitPanelTemplate from '@/components/ams/actionPanels/baseSplitPanelTemplate.vue';
import BulkActions from '@/components/ams/actionPanels/bulk-actions.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'AddKeywordsPanel',
  components: {
    loader,
    messageBar,
    baseSplitPanelTemplate,
    BulkActions
  },
  props: {
    actionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    widgetsConfigRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    bulkActionsAllowed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultLookBack = 30;
    // var that = this;
    return {
      adGroups: [],
      selectedAdGroups: [],
      applyClicked: false,
      previouslySelectedAdGroupData: [],
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      gridOptions2: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      showValueError: false,
      errorMessage: 'Bid value should be between 0.02 to 1000',
      keywordsLoading: false,
      inputFieldContext: {},
      hideTwoRowsIndex: 0,
      hideOneRowIndex: 0,
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      tableConfigsRight: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      rows: [],
      key: 0,
      dummyData: [],
      responseTableMetadata: [],
      resultTableData: [],
      currentArrowContext: '',
      resultTableMetadata: [],
      keywordsAddedFromSource: {},
      keywordsAddedToResult: [],
      maxBid: 1000,
      minBid: 0.02,
      hideOneRow: false,
      hideTwoRows: false,
      rbTableSourceInstance: null,
      rbTableResultInstance: null,
      tableValid: false,
      currentSelectedCampaign: '',
      panelData: {
        sourceCheckBox: [],
        resultCheckBox: []
      },
      bulkActionConfigs: {},
      bulkActionSecondaryText: [],
      targetingType: '',
      bulkRadioOptions: [],
      selectedLookBack: defaultLookBack,
      lookBack: [
        {
          title: '7 days',
          value: 7
        },
        {
          title: '14 days',
          value: 14
        },
        {
          title: '30 days',
          value: 30
        },
        {
          title: '60 days',
          value: 60
        },
        {
          title: '90 days',
          value: 90
        }
      ]
    };
  },
  computed: {
    bulkActionsHeader() {
      return `ADD TARGETS (${this.selections.length} ${
        this.selections.length === 1 ? ' CAMPAIGN' : ' CAMPAIGNS'
      } SELECTED)`;
    },
    adGroupTitle() {
      let text = '';
      if (this.selectedAdGroups.length === 0) {
        text = 'Please select an ad group';
      } else {
        this.selectedAdGroups.forEach((item) => {
          if (item) {
            text = text + item.title + ', ';
          }
        });
        text = text.slice(0, -2);
      }
      return text;
    },
    fetchedAdGroups() {
      return this.$store.getters.getAdGroupsFromCampaignId.APIData
        ? this.$store.getters.getAdGroupsFromCampaignId.APIData
        : [];
    },
    adGroupsLoading() {
      return this.$store.getters.getCampaignsAdGroups?.load;
    },
    adGroupsOfSelectedCampaigns() {
      const { rows, error } = this.$store.getters.getCampaignsAdGroups || {};
      const campaignIdMap = {};
      const targetedGroups = rows.reduce((list, group, i) => {
        if (
          this.targetingType === group.targeting_type &&
          (group.campaign_targeting_type || '').toLowerCase() !== 'auto'
        ) {
          list.push({
            ...group,
            campaignName: campaignIdMap[group.campaign_id]
              ? ' '
              : group.campaign_name,
            select: ' '
          });

          campaignIdMap[group.campaign_id] = !campaignIdMap[group.campaign_id]
            ? 1
            : campaignIdMap[group.campaign_id] + 1;
        }

        return list;
      }, []);
      const numOfTargetedCampaigns = Object.keys(campaignIdMap).length;

      this.bulkActionSecondaryText = this.getBulkActionSecondaryText(
        numOfTargetedCampaigns,
        targetedGroups,
        rows
      );
      return !error ? targetedGroups : [];
    },
    addGroupLoader() {
      return this.$store.getters.getAddGroupLoader;
    },
    updatedResultTableRows() {
      return (
        (this.rbTableResultInstance &&
          this.rbTableResultInstance.computedTableRows) ||
        []
      );
    },
    resultRowClassRules() {
      return {
        'disableSuggBid ag-cell:nth-child(3)': function (params) {
          if (params.data.bid === '-') {
            return true;
          }
          return false;
        }
      };
    },
    rowClassRules() {
      const that = this;
      return {
        'disabledTableRowKeywordsPanel  ag-cell:nth-child(2) ag-cell:nth-child(3) removePointerEvents':
          function (params) {
            let val = false;
            that.resultTableData.forEach((row) => {
              if (row.key === params.data.key) {
                val = true;
              }
            });
            return val;
          },
        ' hideBorderAddKeywordsToCampaigns': function (params) {
          if (that.hideTwoRows) {
            if (params.rowIndex !== that.hideTwoRowsIndex) {
              if (
                params.rowIndex < that.hideTwoRowsIndex - 3 ||
                params.rowIndex > that.hideTwoRowsIndex + 3
              ) {
                that.hideTwoRowsIndex = params.rowIndex;
              }
              return true;
            } else {
              that.hideTwoRowsIndex += 3;
              return false;
            }
          }
          if (that.hideOneRow) {
            if (params.rowIndex !== that.hideOneRowIndex) {
              if (
                params.rowIndex < that.hideOneRowIndex - 2 ||
                params.rowIndex > that.hideOneRowIndex + 2
              ) {
                that.hideOneRowIndex = params.rowIndex;
              }
              return true;
            } else {
              that.hideOneRowIndex += 2;
            }
          }
          return false;
        }
      };
    },

    panelConfig() {
      const campaignValue =
        this.selections.length === 1 ? ' CAMPAIGN' : ' CAMPAIGNS';
      return {
        showBaseTemplate: true,
        header:
          'ADD KEYWORDS (' +
          this.selections.length +
          campaignValue +
          ' SELECTED)',
        source: {
          filter: {
            header: 'Filter by:',
            vhtmlItems: {
              Broad:
                '<div style="margin-right:10px">Broad <span class="u-color-grey-lighter u-font-size-6">(Amazon suggested bids)</span></div>'
            },
            filterStyle: {
              Broad: 'width:340px',
              Phrase: 'width:260px'
            },
            items: ['Broad', 'Phrase', 'Exact']
          },
          width: '39%',
          header: 'Suggested Keywords'
        },
        result: {
          width: '50%',
          header: 'Added Keywords (' + this.resultTableData.length + ')'
        }
      };
    },
    campaignKeywordsRows() {
      if (this.selections.length === 0) {
        return [];
      }
      const data = this.$store.getters.getCampaignsKeywords;
      const selection = this.selections[0].data.campaign_id;
      let rows = [];
      if (data && data[selection]) {
        rows = data[selection];
      }
      return rows;
    },

    campaignKeywordsData() {
      return {
        rows: this.rows,
        columns: this.responseTableMetadata
      };
    },

    tableLoader() {
      return this.$store.getters.getCampaignWorkspaceTableLoader;
    },

    getAddedKeywordsTableData() {
      return {
        columns: this.resultTableMetadata,
        rows: this.resultTableData
      };
    }
  },
  watch: {
    fetchedAdGroups(newVal) {
      this.selectedAdGroups = [];
      this.adGroups = [];
      if (newVal?.length > 0) {
        newVal.map((item) => {
          item.title = item.adgroup_name;
          item.selected = false;
        });
        this.adGroups = newVal;
        if (!this.bulkActionsAllowed) {
          this.adGroups[0].selected = true;
          this.selectedAdGroups = [this.adGroups[0]];
        }
      }
      this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
    },
    campaignKeywordsRows(newVal) {
      this.rows = newVal;
    },
    rbTableSourceInstance() {
      this.updateRowFilters();
      this.isRowSelected();
    },
    updatedResultTableRows() {
      this.checkTableValidity();
    },
    resultTableData(newVal) {
      this.isRowSelected();
      this.checkTableValidity();
    },
    selections: {
      handler: function (newValue, oldValue) {
        if (newValue.length === 0) {
          this.closePanel();
          return;
        }
        if (newValue.length === 1) {
          var currentSelectedCampaign =
            this.currentSelectedCampaign.data.campaign_id;
          var changedValue = newValue[0].data.campaign_id;
          this.adGroups = [];
          this.getAdGroupsForCampaign(this.selections[0].data);
          if (changedValue !== currentSelectedCampaign) {
            this.getKeywordsForCampaign();
            this.resultTableData = [];
          }
          this.checkTableValidity();
        } else {
          // this.checkTableValidity();
        }

        if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(newValue);
      }
    }
  },

  created() {
    this.widgetsConfig = this.widgetsConfigRef.config(this);
    this.responseTableMetadata =
      this.widgetsConfig.widgets.addKeywordsPanel.tableConfigs.metadata.sourceTable;
    this.resultTableMetadata =
      this.widgetsConfig.widgets.addKeywordsPanel.tableConfigs.metadata.resultTable;
    this.bulkActionConfigs =
      this.widgetsConfig.widgets.addKeywordsPanel.bulkActionConfigs;
    this.bulkRadioOptions = this.bulkActionConfigs?.radioOptions;
    this.currentSelectedCampaign = this.selections[0];
    this.targetingType = this.bulkActionConfigs?.radioOptions?.[0]?.type;
    this.getKeywordsForCampaign();
    this.updateRowFilters();
    this.getAdGroupsForCampaign(this.selections[0].data);
    if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(this.selections);
  },
  methods: {
    getTargetTypeText(
      targetedCampaigns,
      targetedGroups,
      totalGroups,
      targetType
    ) {
      if (!targetType) return [];
      return [
        `${targetedCampaigns}/${this.selections.length} campaign${
          targetedCampaigns > 1 ? 's' : ''
        } having ${targetedGroups.length}/${totalGroups.length} ad group${
          targetedGroups.length > 1 ? 's' : ''
        }`,
        ` ${
          targetedCampaigns > 1 ? 'are' : 'is'
        } eligible for adding ${targetType} as targets.
        Choose ${targetType} to help your products appear in shopper searches.`
      ];
    },
    getBulkActionSecondaryText(targetedCampaigns, targetedGroups, totalGroups) {
      const textMap = {
        keyword: 'Keywords',
        product: 'Products/Categories',
        audience: 'Audiences'
      };

      return this.getTargetTypeText(
        targetedCampaigns,
        targetedGroups,
        totalGroups,
        textMap[this.targetingType]
      );
    },
    onAdGroupsSelectionsChange(selectedGroups) {
      this.selectedAdGroups = selectedGroups;
      this.checkTableValidity();
    },
    handleRadioSelection(newValue) {
      this.targetingType = newValue;
      this.resultTableData = [];
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    adGroupSelected(context, data) {
      if (this.applyClicked) {
        this.selectedAdGroups = [];
        this.selectedAdGroups = data.filter((item) => item.selected);
        this.applyClicked = false;
        this.$nextTick(() => {
          this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.previouslySelectedAdGroupData.length; i++) {
            if (!this.previouslySelectedAdGroupData[i].selected) {
              delete this.adGroups[i].selected;
            } else {
              this.adGroups[i].selected = true;
            }
          }
        });
      }
      this.checkTableValidity();
    },
    getAdGroupsForCampaigns(selectedCampaigns) {
      const payload = {
        ...this.bulkActionConfigs.APIConfig
      };
      const dimensionNameValueList = selectedCampaigns.map(({ data }) => ({
        dimensionName: 'campaign_id',
        dimensionValue: data.campaign_id
      }));
      payload.where.dimensionNameValueList = dimensionNameValueList;
      this.$store.dispatch('fetchCampaignsAdGroups', payload);
    },
    getAdGroupsForCampaign(data) {
      const dimensionNameValueList = [
        {
          dimensionName: 'campaign_id',
          operator: 'EQUAL_TO',
          dimensionValue: data.campaign_id
        },
        {
          dimensionName: 'state',
          operator: 'LIKE',
          dimensionValue: 'enabled'
        }
      ];
      this.widgetsConfig.widgets.fetchAddGroups.body.APIConfig.where.dimensionNameValueList =
        dimensionNameValueList;
      this.$store.dispatch(
        'fetchAdGroupsFromCampaignId',
        this.widgetsConfig.widgets.fetchAddGroups
      );
    },
    updatePanelData(data) {
      this.panelData = data;
      this.updateRowFilters();
    },
    addAllKeywordsToTable() {
      const currentItems = this.rbTableSourceInstance.rowData;
      const updatedRows = this.updatedResultTableRows;
      currentItems.forEach((res) => {
        if (
          !this.checkRowExistsInCollection(
            res,
            'key',
            this.updatedResultTableRows
          )
        ) {
          updatedRows.push(res);
        }
      });
      this.resultTableData = updatedRows;
    },
    deleteAllKeywordsFromTable() {
      this.resultTableData = [];
    },
    updateRowFilters() {
      const newRows = [];
      this.hideTwoRows = false;
      this.hideOneRow = false;
      let fliterBroad = false;
      let filterPhrase = false;
      let filterExact = false;
      this.hideTwoRowsIndex = 0;
      this.hideOneRowIndex = 0;
      const checkBoxValues = this.panelData.sourceCheckBox
        .toString()
        .toLowerCase();
      fliterBroad = checkBoxValues.includes('broad');
      filterPhrase = checkBoxValues.includes('phrase');
      filterExact = checkBoxValues.includes('exact');
      if (
        (!filterPhrase && !filterExact && !fliterBroad) ||
        (filterPhrase && filterExact && fliterBroad)
      ) {
        filterPhrase = true;
        filterExact = true;
        fliterBroad = true;
        this.hideTwoRows = true;
      }
      if (
        (filterPhrase && filterExact) ||
        (filterPhrase && fliterBroad) ||
        (filterExact && fliterBroad)
      ) {
        this.hideOneRow = true;
      }
      this.campaignKeywordsRows.forEach((row) => {
        if (fliterBroad) {
          const broad = this.generateMatchTypeRows(row, 'broad', fliterBroad);
          newRows.push(broad);
        }
        if (filterPhrase) {
          const phrase = this.generateMatchTypeRows(
            row,
            'phrase',
            !fliterBroad
          );
          newRows.push(phrase);
        }
        if (filterExact) {
          const exact = this.generateMatchTypeRows(
            row,
            'exact',
            !fliterBroad && !filterPhrase && !fliterBroad
          );
          newRows.push(exact);
        }
      });
      this.rows = newRows;
      this.isRowSelected();
    },
    generateMatchTypeRows(row, matchType, showKeyword) {
      const tempRow = JSON.parse(JSON.stringify(row));
      const tempRecommendedKeyword = tempRow.recommended_keyword
        ? tempRow.recommended_keyword.toLowerCase()
        : null;
      tempRow.match_type = matchType;
      tempRow.key = tempRecommendedKeyword + '&' + matchType;
      tempRow.keyword = showKeyword ? tempRow.recommended_keyword : ' ';
      switch (matchType) {
        case 'exact': {
          tempRow.newBid = tempRow.exact_bid || null;
          tempRow.bid = tempRow.exact_bid || '-';
          break;
        }
        case 'phrase': {
          tempRow.newBid = tempRow.phrase_bid || null;
          tempRow.bid = tempRow.phrase_bid || '-';
          break;
        }
        case 'broad': {
          tempRow.bid = tempRow.broad_bid || '-';
          tempRow.newBid = tempRow.broad_bid || null;
          break;
        }
        default:
          break;
      }
      return tempRow;
    },
    getCurrentCampaignKeywords() {
      var currentCampaignId = this.selections[0].data.campaign_id;
      var dataExist =
        this.$store.getters.getCampaignsKeywords[currentCampaignId];
      return dataExist;
    },

    // fetch keywords data from server for the selected campaign
    getKeywordsForCampaign() {
      var currentCampaignId = this.selections[0].data.campaign_id;
      var dataExist = this.getCurrentCampaignKeywords();
      if (dataExist !== undefined) {
        return dataExist;
      }

      var dimensionNameValueList = [
        {
          dimensionName: 'campaign_id',
          operator: 'EQUAL_TO',
          dimensionValue: currentCampaignId
        }
      ];
      this.widgetsConfig.widgets.addKeywordsPanel.body.APIConfig.where.dimensionNameValueList =
        dimensionNameValueList;
      this.$store.dispatch(
        'fetchKeywordsForTheCampaign',
        this.widgetsConfig.widgets.addKeywordsPanel
      );
    },

    // To get the context of the two tables
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },

    getCurrentResultInstance(context) {
      this.rbTableResultInstance = context;
    },

    // Checking the validity of the table to enable/disable apply button
    checkTableValidity() {
      if (this.bulkActionsAllowed) {
        if (
          typeof this.bulkActionConfigs?.customStateMap?.[this.targetingType]
            ?.checkTableValidity === 'function'
        ) {
          const { tableValid, showValueError } =
            this.bulkActionConfigs.customStateMap[
              this.targetingType
            ].checkTableValidity(this.resultTableData);
          this.tableValid = tableValid;
          this.showValueError = showValueError;
          return;
        }
        if (this.resultTableData.length === 0) {
          this.tableValid = false;
          this.showValueError = false;
          return;
        }
        for (var k in this.resultTableData) {
          var value =
            this.resultTableData[k].exact_bid ||
            this.resultTableData[k].new_bid;
          if (!value) {
            this.tableValid = false;
            this.showValueError = true;
            return;
          }
          if (value < this.minBid || value > this.maxBid) {
            this.tableValid = false;
            this.showValueError = true;
            return;
          }
        }
        if (this.adGroupsOfSelectedCampaigns.length === 0) {
          this.tableValid = false;
          return;
        }
        if (this.selectedAdGroups.length === 0) {
          this.tableValid = false;
          return;
        }
      } else {
        if (this.selections.length > 1) {
          this.tableValid = false;
          this.showValueError = false;
          return;
        }
        if (this.updatedResultTableRows.length === 0) {
          this.tableValid = false;
          this.showValueError = false;
          return;
        }
        for (var k in this.updatedResultTableRows) {
          var value = this.updatedResultTableRows[k].newBid;
          if (!value) {
            this.tableValid = false;
            this.showValueError = true;
            return;
          }
          if (value < this.minBid || value > this.maxBid) {
            this.tableValid = false;
            this.showValueError = true;
            return;
          }
        }
        if (this.adGroups.length === 0) {
          this.tableValid = false;
          return;
        }
        if (this.selectedAdGroups.length === 0) {
          this.tableValid = false;
          return;
        }
      }

      this.showValueError = false;
      this.tableValid = true;
    },

    // to validate the table data with input field
    validateTableData(context, event) {
      context.params.data.newBid = context.bidValue;
      this.checkTableValidity();
    },

    addKeywordNames(rows) {
      const newRows = cloneDeep(rows);
      newRows.forEach((row) => {
        if (row.keyword === ' ') {
          row.keyword = row.recommended_keyword;
        }
      });
      return newRows;
    },

    // Adding a keyword
    addToSelection(context, event) {
      let tempArr = this.updatedResultTableRows;
      tempArr.push(context.params.data);
      tempArr = this.addKeywordNames(tempArr);
      this.resultTableData = tempArr;
      this.checkTableValidity();
    },

    // removing a keywod from the table
    removeFromSelection(context, event) {
      this.resultTableData = this.updatedResultTableRows.filter((result) => {
        if (context.params.data.key !== result.key) {
          return result;
        }
      });
    },

    // to set the default value of bid as suggested bid
    setDefaultValue(context, event) {
      if (context.params.data.bid === '-') {
        return;
      }
      var rowNodes = {
        rowNodes: [context.params.node]
      };
      context.params.data.newBid = context.params.data.bid;
      this.rbTableResultInstance.redrawTableRows(rowNodes);
      this.checkTableValidity();
    },

    getArrowButtonContext(context) {
      this.currentArrowContext = context;
    },

    getInputFieldContext(context) {
      if (this.currentArrowContext !== '') {
        this.currentArrowContext.$refs.inputField = context;
      }
      this.currentArrowContext = '';
      this.inputFieldContext[context._uid] = 0;
    },

    getPayloadTemplate(adGroup) {
      // var keyWordData = this.getCurrentCampaignKeywords();
      var data = this.selections[0].data;
      var commonPayload = {
        profileId: data.profile_id,
        campaignId: data.campaign_id,
        campaignType: data.campaign_type,
        adgroupId: adGroup.adgroup_id
      };
      var obj = {
        actionType: 'addKeywordToCampaigns',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      obj.actionPayload = Object.assign({}, commonPayload);
      obj.viewPayload = Object.assign({}, commonPayload);
      obj.viewPayload.adgroupName = adGroup.adgroup_name;
      return obj;
    },

    createPayload() {
      var payload = [];
      for (var i = 0; i < this.updatedResultTableRows.length; i++) {
        for (const adGroup of this.selectedAdGroups) {
          var obj = this.getPayloadTemplate(adGroup);
          obj.actionPayload.keywordText =
            this.updatedResultTableRows[i].keyword;
          obj.actionPayload.matchType =
            this.updatedResultTableRows[i].match_type;
          obj.actionPayload.newBid = this.updatedResultTableRows[i].newBid;
          obj.viewPayload.keywordText = this.updatedResultTableRows[i].keyword;
          obj.viewPayload.matchType = this.updatedResultTableRows[i].match_type;
          obj.viewPayload.newBid = this.updatedResultTableRows[i].newBid;
          payload.push(obj);
        }
      }
      return payload;
    },

    getBulkPayloadTemplate(adGroup) {
      const payload = [];

      this.resultTableData.forEach((keyword, i) => {
        payload[i] = {
          actionType:
            this.targetingType === 'keyword'
              ? 'addKeywordToCampaigns'
              : 'addTargetToCampaigns',
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: adGroup.campaign_id
        };
        const commonPayload = {
          campaignType: (adGroup.campaign_type || '').toUpperCase(),
          campaignId: adGroup.campaign_id
        };
        if (this.targetingType === 'keyword') {
          payload[i].actionPayload = {
            ...commonPayload,
            new_keyword_text: keyword.keyword,
            new_match_type: keyword.match_type?.toLowerCase(),
            new_bid: keyword.exact_bid,
            adgroup_id: adGroup.adgroup_id
          };
          payload[i].viewPayload = {
            ...commonPayload,
            adgroupName: adGroup.adgroup_name,
            keywordText: keyword.keyword,
            matchType: keyword.match_type,
            newBid: keyword.exact_bid,
            adgroupId: adGroup.adgroup_id
          };
        } else if (this.targetingType === 'audience') {
          payload[i] = this.bulkActionConfigs.getAudienceTargetingObject(
            adGroup,
            keyword
          );
        } else {
          payload[i].actionPayload = {
            ...commonPayload,
            new_keyword_text: keyword.categoryId || keyword.competitor_asin,
            new_bid: keyword.new_bid,
            adgroup_id: adGroup.adgroup_id,
            new_expression_type: 'manual',
            new_match_type: 'TARGETING_EXPRESSION',
            new_expression: [
              {
                type: keyword.category ? 'asinSameAs' : 'asinCategorySameAs',
                value: keyword.categoryId || keyword.competitor_asin
              }
            ]
          };
          payload[i].viewPayload = {
            ...commonPayload,
            adgroupName: adGroup.adgroup_name,
            entityId: keyword.categoryId || keyword.competitor_asin,
            asin: keyword.categoryId || keyword.competitor_asin,
            type: keyword.category ? 'Category' : 'Product',
            entityName: keyword.category || keyword.entity_ui_label,
            match_type: 'TARGETING_EXPRESSION',
            new_bid: keyword.new_bid
          };
        }
      });

      return {
        uuid: `${adGroup.campaign_id}${adGroup.adgroup_id}`,
        payload
      };
    },

    createBulkPayload() {
      const payload = [];
      this.selectedAdGroups.forEach((adGroup, i) => {
        payload[i] = this.getBulkPayloadTemplate(adGroup);
      });
      return payload;
    },

    addKeywordsToCampaign() {
      var that = this;
      var payload = this.bulkActionsAllowed
        ? this.createBulkPayload()
        : this.createPayload();
      // var alertMessage = 'Request to add keyword(s) for the selected campaign(s) has been processed';
      var othersObj = {
        loader: 'keywordsLoading',
        successMessage:
          'Request to add keyword(s) for the selected campaign(s) has been submitted ',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          // that.parentRef.campaignsAlertMessage = alertMessage;
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closePanel();
          // that.parentRef.onSelectionChanged([]);
        }
      };
      commons.bidChangeApiAction.call(
        this,
        this.selections,
        payload,
        othersObj,
        this.bulkActionsAllowed ? 'bulkaction' : undefined
      );
    },

    closePanel() {
      this.parentRef.showAddKeywordsPanel = false;
      this.parentRef.closeActionsWorkflow();
    },

    checkRowExistsInCollection(row, key, table) {
      let val = false;
      table.forEach((item) => {
        if (item[key] === row[key]) {
          val = true;
          return val;
        }
      });
      return val;
    },

    isRowSelected() {
      const rows = [];
      this.rows.forEach((row) => {
        if (this.resultTableData.length > 0) {
          if (
            this.checkRowExistsInCollection(row, 'key', this.resultTableData)
          ) {
            row.isRowSelected = true;
            rows.push(row);
          } else {
            row.isRowSelected = false;
            rows.push(row);
          }
        } else {
          row.isRowSelected = false;
          rows.push(row);
        }
      });
      this.rows = rows;
    },
    onSelectLookBack(context, data) {
      if (!data?.length) return;
      this.selectedLookBack = data[0].value;
      this.$refs?.bulkActionsComponent?.handleTabsClick();
    }
  }
};
</script>
