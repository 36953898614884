<template>
  <section
    v-if="isOpen"
    class="savedDraftPanel"
    :class="isOpen ? 'open' : 'close'"
  >
    <div class="header u-spacing-ph-l u-spacing-pt-l u-spacing-pb-m">
      <span class="u-color-grey-base u-font-size-4 u-font-weight-600"
        >Saved drafts</span
      >
      <span @click="closeDraftedPlans">
        <rb-icon
          :icon="'cross'"
          class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
        />
      </span>
    </div>
    <section class="body u-position-relative">
      <Loader
        v-if="loading"
        :loading="loading"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
      <div
        v-for="(item, $index) in list"
        v-else
        :key="$index"
        class="item_wrp u-spacing-p-m"
      >
        <p class="u-font-size-4 u-display-flex u-flex-align-items-center">
          <span class="u-spacing-mr-s">{{
            ($index + 1) | priorityFilter
          }}</span>
          <span>{{ item.NAME || 'item.NAME' }}</span>
        </p>
        <p
          class="u-spacing-ml-l u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
        >
          <span>{{ item.description }}</span>
        </p>
        <p
          class="u-spacing-ml-l u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
        >
          <span
            >Created by <span>{{ item.CREATED_BY }}</span> on
            <span> {{ item.CREATED_ON | dateFormatFilter }}</span>
          </span>
        </p>
        <div class="u-display-flex">
          <p
            class="u-spacing-ml-l u-font-weight-600 u-font-size-6 u-color-blue-base u-spacing-mt-s u-cursor-pointer"
            @click="() => reviewAndGeneratePlan(item)"
          >
            Review & Generate Plan
          </p>
          <p
            class="u-spacing-ml-l u-font-weight-600 u-font-size-6 u-color-orange-base u-spacing-mt-s u-cursor-pointer"
            @click="() => deletePlan(item.MEDIA_PLAN_ID)"
          >
            Delete this Plan
          </p>
        </div>
      </div>

      <div
        v-if="!list.length && !loading"
        class="u-font-size-4 u-text-align-center u-spacing-mt-m"
      >
        No data available.
      </div>
    </section>
  </section>
</template>
<script>
import moment from 'moment-timezone';
import Loader from '@/components/basic/loader';
import {
  getConfigFromPlan,
  DELETE_MEDIA_PLAN_ACTION,
  MEDIA_PLANNER_CREATION_DATA_SETTER
} from '@/components/ams/media-planner/config.js';
export default {
  components: {
    Loader
  },
  filters: {
    priorityFilter: function (value) {
      if (!value) return '0';
      return ('0' + value).slice(-2);
    },
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM, DD YYYY');
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDraftedPlans() {
      this.$emit('closeDraftedPlans');
    },
    reviewAndGeneratePlan(planData) {
      this.$emit('resetState');
      const planConfig = getConfigFromPlan(planData);
      const storeData = { ...this.storeData, ...planConfig };
      storeData.mediaPlanId = planData.MEDIA_PLAN_ID;
      this.$store
        .dispatch(MEDIA_PLANNER_CREATION_DATA_SETTER, storeData)
        .then(() => {
          this.$router.push({
            name: 'Edit Media Plan',
            params: { id: planData.MEDIA_PLAN_ID },
            query: { step: 1 }
          });
        });
    },
    deletePlan(planId) {
      this.$store.dispatch(DELETE_MEDIA_PLAN_ACTION, {
        planId,
        isDrafted: true
      });
    }
  }
};
</script>
<style lang="css" scoped>
.savedDraftPanel {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: #fff;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
}
.savedDraftPanel .header {
  width: 100%;
  height: 56px;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.savedDraftPanel .body {
  flex-grow: 1;
  overflow: auto;
}

.savedDraftPanel.close {
  width: 0%;
}
.savedDraftPanel.close .body {
  display: none;
}

.savedDraftPanel.open {
  width: 25%;
}
.savedDraftPanel.open .body {
  display: block;
}
</style>
