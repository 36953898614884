import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';

const customNumericComponent = Vue.extend({
  props: {
    cellValue: {
      type: String,
      default: ''
    }
  },
  template:
    '<div class="u-color-grey-base u-text-overflow-ellipsis" :title="cellValue">{{cellValue}}</div>'
});
const config = {
  search: {
    placeholder: 'Search for a Keyword',
    dimensionName: 'keyword_text'
  },
  APIConfig: {
    brandsRequest: {
      cubeName: 'ams_keyword_campaign_workbench',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: [],
      groupByDimensionsList: ['keyword_text'],
      orderByList: [
        {
          dimension: 'keyword_text',
          direction: 'ASC'
        }
      ],
      where: {
        dimensionNameValueList: []
      }
    },
    tagType: 'custom',
    customType: 'Custom',
    entityType: 'keyword',
    paginationRequest: {
      limit: 20,
      offset: 0
    }
  },
  tableConstants(that) {
    return {
      gridApi: {
        context: {
          componentParent: that
        },
        rowClassRules: {},
        defaultColDef: {
          wrapText: true,
          autoHeight: true
        }
      },
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      }
    };
  },

  generateColumns(that) {
    const colDefs = this.columnDefination.map((column, index) => {
      let columnMetaObject = {};
      const columnDefinition = {
        name: column.dimensionColumn,
        uiField: {
          uiLabel: column.dimensionLabel,
          uiOrder: index + 1,
          uiType: 'custom',
          metadata: {
            tableColumnName: column.dimensionColumn,
            showOnUi: true
          }
        }
      };
      columnMetaObject = this[column.dimensionColumn](column, that);
      columnDefinition.uiField.metadata = {
        ...columnDefinition.uiField.metadata,
        ...columnMetaObject
      };
      return columnDefinition;
    });
    const columnDefs = transformer.getColumnDefinition(colDefs);
    return columnDefs.displayColConfigs;
  },

  // column defs
  tag(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      dynamicCellComponentParams: {
        componentName: 'TagsViewCell',
        paramsToProps: (params) => {
          return {
            allSuggestionEntities: [],
            currentEntities: params.data.tags,
            interactiveState: 'view',
            dropdownOptions: [
              { title: 'Manage Digital Shelf', key: 'manage_digital_shelves' },
              { title: 'Remove Digital Shelf', key: 'remove_digital_shelevs' }
            ]
          };
        },
        eventMap: {
          onDropdownOptionSelect: 'customDigitalShelvesDropdown'
        }
      },
      width:
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) - 416
    };
  },

  keyword(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      width: 400,
      dynamicCellComponentParams: {
        componentName: customNumericComponent,
        paramsToProps: (params) => {
          return {
            cellValue: params.data.keyword
          };
        }
      }
    };
  },
  columnDefination: [
    {
      dimensionLabel: 'Keyword',
      dimensionColumn: 'keyword'
    },
    {
      dimensionLabel: 'Custom Digital Shelves',
      dimensionColumn: 'tag'
    }
  ]
};

export default config;
