<template>
  <div>
    <div class="u-display-flex u-flex-align-items-center u-spacing-pb-m">
      <span
        class="u-font-size-4 u-color-grey-lighter u-font-weight-600 u-spacing-pr-s"
        >Automation Details by</span
      >
      <tabs
        class="u-spacing-mr-s"
        :chip-size="'s'"
        :tabs-data="tabData"
        :active-tab-index="activeTabIndex"
        @tabClick="handleTabClick"
      />
    </div>
    <icon-text
      v-if="activeTabName === 'recommendation'"
      icon="info-circle-fill"
      icon-classes="rb-icon--medium u-color-grey-lighter"
      text="The table below remains unaffected by the chosen date range and consistently relies on the latest available dates' data."
      text-classes="u-font-size-6 u-color-grey-light u-font-weight-400"
      container-class="recommendation-description u-spacing-pb-m"
    />
  </div>
</template>

<script>
import tabs from '@/components/pages/insights/amazon/share-of-voice/molecules/tabs.vue';
import chipSelect from '@/components/basic/chipSelect.vue';
export default {
  name: 'AutomationTableHeader',
  components: {
    tabs,
    chipSelect
  },
  props: {
    activeTabIndex: {
      type: Number,
      default: 0
    },
    activeTabName: {
      type: String,
      default: 'ticketStatus'
    },
    handleTabClick: {
      type: Function,
      default: () => {}
    },
    tabData: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
