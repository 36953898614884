<template>
  <div class="container">
    <label class="switch">
      <input
        v-model="checkedValue"
        type="checkbox"
      />
      <div class="slider round" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    originalState: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      checkedValue: false
    };
  },
  watch: {
    checkedValue(data) {
      this.$emit('check', data);
    },
    originalState: {
      immediate: true,
      handler() {
        this.checkedValue = this.originalState;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.switch {
  display: inline-block;
  height: 8px;
  position: relative;
  width: 20px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  width: 20px;
}

.slider:before {
  background-color: #fff;
  bottom: 0px;
  content: '';
  height: 10px;
  left: 0px;
  position: absolute;
  transition: 0.4s;
  width: 10px;
  top: -2px;
}

input:checked + .slider {
  background-color: #007cf6;
}

input:checked + .slider:before {
  transform: translatex(10px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  border: solid 1px #007cf6;
}

.container {
  margin: 0 auto;
}
</style>
