<template>
  <div
    class="u-spacing-ph-m u-spacing-pv-m u-border-radius-xs metrics-card-light"
    :class="[colorClass.backgroundColor, colorClass.borderColor]"
  >
    <div
      class="u-font-size-4 u-font-weight-600 u-spacing-pb-s u-color-grey-x-light"
      :class="colorClass.titleColor"
    >
      {{ title }}
    </div>
    <div class="u-display-flex u-flex-align-items-baseline u-font-weight-600">
      <div
        class="u-font-size-2 u-spacing-mr-s"
        :class="colorClass.percentageColor"
      >
        {{ percentage }}
      </div>
      <div
        class="u-font-size-7 u-color-grey-x-light"
        :class="colorClass.numberColor"
      >
        ({{ number }} Test Runs)
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    percentage: {
      type: String,
      default: null
    },
    number: {
      type: String,
      default: null
    },
    hasShdaows: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: null
    }
  },
  computed: {
    colorClass() {
      if (this.theme === 'light') {
        return {
          backgroundColor: 'u-bg-color-grey-white',
          borderColor: 'u-border-top u-border-color-' + this.color,
          titleColor: 'u-color-grey-lighter',
          percentageColor: 'u-color-grey-base',
          numberColor: 'u-color-grey-x-light'
        };
      } else {
        return {
          backgroundColor: 'u-bg-color-' + this.color,
          borderColor: '',
          titleColor: 'u-color-grey-white',
          percentageColor: 'u-color-grey-white',
          numberColor: 'u-color-grey-white'
        };
      }
    }
  }
};
</script>

<style></style>
