<template>
  <collapsible-page
    :page-title="textConfig.pageTitle"
    :page-description="textConfig.pageDescription"
    @closePage="navigateBack"
  >
    <div class="u-width-100 u-display-flex u-flex-direction-column u-flex-1">
      <div
        v-if="
          ![
            'Manage Standard Internal Catalog',
            'Manage Standard Internal Catalog for Seller Central (3P)',
            'Manage Source of Truth for Content',
            'Manage Source of Truth for Variant',
            'Campaign Taxonomy'
          ].includes(textConfig.pageTitle)
        "
        class="u-spacing-ph-xxl u-display-flex tab_bar u-flex-align-items-center"
      >
        <div
          v-for="(tab, index) in tabsConfig"
          :key="index"
          :class="{
            'u-font-weight-600': tab.title === currentTab.title,
            'u-color-blue-base': tab.title === currentTab.title
          }"
        >
          <div
            class="u-spacing-pr-xl u-font-size-5 u-cursor-pointer"
            @click="handleTabsClick(tab)"
          >
            {{ tab.title }}
          </div>
        </div>
      </div>
      <component
        :is="currentTab.component"
        v-bind="currentTab.textConfig"
      />
    </div>
  </collapsible-page>
</template>

<script>
import loader from '@/components/basic/loader';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import collapsiblePage from '@/components/basic/collapsiblePage.vue';

export default {
  components: {
    loader,
    activityLog,
    collapsiblePage
  },
  props: {
    defaultTabIndex: {
      default: 0,
      type: Number
    },
    textConfig: {
      type: Object,
      default() {
        return {};
      }
    },
    tabsConfig: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      currentTab: null,
      openActivity: false
    };
  },
  watch: {
    tabsConfig() {
      this.populateCurrentEntityComponent();
    }
  },
  created() {
    this.populateCurrentEntityComponent();
  },
  methods: {
    navigateBack(event) {
      this.$emit('closePage', event);
    },
    handleTabsClick(selectedTab) {
      this.currentTab = selectedTab;
      this.$emit('currentTab', this.currentTab);
    },
    populateCurrentEntityComponent() {
      this.currentTab = this.tabsConfig[this.defaultTabIndex];
      this.$emit('currentTab', this.currentTab);
    }
  }
};
</script>

<style lang="css" scoped>
.tab_bar {
  background-color: #f5f7fa;
  height: 38px;
}
</style>
