<!--
Values expected in params for rendering

displayIcon:      icon to be displayed
type:             undefined/icon : only icon will be displayed, iconText: icon will be diplayed with text label
textValue:        if type is 'iconText', the label value which needs to be displayed
iconSize:         size of icon, small/medium/large etc
-->
<template>
  <div class="u-width-100">
    <div
      v-if="params.type === undefined || params.type === 'icon'"
      class="u-width-100 u-text-align-center"
      data-cy="clickOnIcon"
      @click="iconClickEvent($event)"
    >
      <rb-icon
        v-tippy="tippy"
        :title="params.toolTipText"
        class="u-spacing-mh-s tableCell-icon"
        :class="[iconClass, extraClass]"
        :icon="icon"
        data-cy="ActionalbleIcon"
      />
    </div>
    <div
      v-if="params.type === 'iconText'"
      class="u-width-100 u-display-flex u-flex-justify-content-space-between"
      @click="iconClickEvent($event)"
    >
      <div class="u-color-grey-base">
        {{ labelValue | num_format(dataFormatType) }}
      </div>
      <rb-icon
        v-tippy="tippy"
        :title="params.toolTipText"
        class="u-spacing-mh-s tableCell-icon"
        :class="[iconClass, extraClass]"
        :icon="icon"
        data-cy="ActionalbleIcon"
      />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'iconTableCell',
  components: {},
  data() {
    return {
      displayIcon: '',
      alternateIcon: '',
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        interactive: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    icon() {
      let icon = '';
      if (this.params.displayIcon) {
        if (this.params.alternateIcon && this.params.data.isRowSelected) {
          icon = this.params.alternateIcon;
        } else {
          icon = this.params.displayIcon;
        }
      }
      return icon;
    },
    passCurrentThis() {
      return this;
    },
    labelValue() {
      return this.params.data[this.params.colDef.field];
    },
    iconClass() {
      let iconColorClass = 'u-color-grey-lighter';
      if (
        this.params.data.isRowSelected &&
        this.params.alternateIcon &&
        this.params.alternateIconClass
      ) {
        iconColorClass = this.params.alternateIconClass;
      }
      if (this.params.iconSize) {
        return 'rb-icon--' + this.params.iconSize + ' ' + iconColorClass;
      } else {
        return 'rb-icon--small ' + iconColorClass;
      }
    },
    extraClass() {
      if (this.params.extraClass) {
        return this.params.extraClass;
      }
      return '';
    }
  },
  created() {
    if (this.params.contextReturnEvent !== undefined) {
      this.params.contextReturnEvent(this);
    }
    if (this.params.toolTipPosition !== undefined) {
      this.updateTippyPosition();
    }

    this.currentDataFilter();
  },
  methods: {
    updateTippyPosition() {
      this.tippy.placement = this.params.toolTipPosition;
    },
    iconClickEvent(event) {
      if (this.params.iconClickEvent) {
        this.params.iconClickEvent(this, event);
      }
    },
    currentDataFilter() {
      if (this.params.type === 'iconText') {
        var formatType = this.params.colDef.cellRendererParams.formatType;
        this.dataFormatType = formatType;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.tableCell-icon:hover {
  color: #4b5158;
}
</style>
