<template>
  <div
    class="u-position-relative u-border-radius-s u-spacing-mb-m u-spacing-mt-s u-spacing-mh-s u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-border filter-card u-flex-1"
    :class="[backgrounColorCard, cursorClass]"
    @click="handleClick"
  >
    <div
      v-if="!loading"
      class="u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
    >
      <div
        class="u-spacing-pt-l u-spacing-pb-m u-spacing-ph-m u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
      >
        <div
          class="u-font-size-4 u-spacing-pb-xxs u-font-weight-600 u-text-align-center"
          :class="
            isActive && !error ? 'u-color-grey-white' : 'u-color-grey-lighter'
          "
        >
          {{ title }}
        </div>
        <div
          class="u-font-size-4 u-text-align-center"
          :class="
            isActive && !error ? 'u-color-grey-white' : 'u-color-grey-lighter'
          "
        >
          {{ subTitle }}
        </div>
      </div>
      <div
        v-if="!error"
        class="u-font-size-2 u-spacing-ph-s u-spacing-pb-l u-font-weight-bold"
        :class="isActive ? 'u-color-grey-white' : 'u-color-grey-base'"
      >
        {{ count }}
      </div>
      <div
        v-else
        class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-6 u-spacing-p-m u-text-align-center u-color-grey-lighter"
      >
        Error Occurred. Please try again later
      </div>
      <div
        v-if="!error"
        class="u-position-absolute u-display-none u-font-size-7 u-font-weight-600 hover-text"
        :class="{ 'u-color-blue-base': count, 'u-color-grey-x-light': !count }"
      >
        {{ hoverText }}
      </div>
    </div>
    <div v-else>
      <loader
        class="u-bg-color-grey-white"
        :loading="loading"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
export default {
  components: {
    loader
  },
  props: {
    text: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false
    },
    emit: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    title() {
      const splitArray = this.text.split(' ');
      splitArray.pop();
      const title = splitArray.join(' ');
      return title;
    },
    subTitle() {
      const splitArray = this.text.split(' ');
      const subtitle = splitArray[splitArray.length - 1];
      return subtitle;
    },
    hoverText() {
      return this.count ? 'View actions' : 'No actions to view';
    },
    backgrounColorCard() {
      return this.isActive && !this.error
        ? 'u-bg-color-blue-base'
        : 'u-bg-color-grey-white';
    },
    cursorClass() {
      return !this.error && this.count
        ? 'u-cursor-pointer'
        : 'u-cursor-default';
    }
  },
  methods: {
    handleClick() {
      if (!this.loading && !this.error && this.count) {
        this.$emit('cardClicked', this);
      }
    }
  }
};
</script>

<style lang="css">
.filter-card {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  min-height: 150px;
}
.filter-card:hover .hover-text {
  display: block;
}
.hover-text {
  bottom: 13px;
}
</style>
