import { Selector } from './selector';
export class SelectAllSelector extends Selector {
  get id(): string {
    return this.isSelected ? '(Deselect All)' : '(Select All)';
  }

  get isSelected(): boolean {
    return this.group.selectorsArray
      .filter((selector) => selector !== this && !selector.disabled)
      .every((selector) => {
        return selector.isSelected;
      });
  }

  _select(childSelect: boolean): void {
    this.group.selectAll();
  }

  _deselect(): void {
    this.group.deselectAll();
  }

  get icon() {
    return 'checkbox-' + this.isSelected === null ? 'selected' : 'empty';
  }

  handleClick(): void {
    if (this.isSelected) {
      this.deselect();
    } else {
      this.select(true);
    }
  }
}
