<template>
  <component
    :is="params.column.colDef.headerComponentParams.component"
    v-bind="columnDefsToPropsComp(params)"
    v-on="eventMaper(params)"
  />
</template>

<script>
import { isEmpty } from 'lodash';
export default {
  name: 'DynamicHeaderComponent',
  methods: {
    columnDefsToPropsComp(params) {
      let props = {};
      if (params.column.colDef.headerComponentParams.props) {
        props = params.column.colDef.headerComponentParams.props;
      }
      if (params.column.colDef.headerComponentParams.columnDefsToProps) {
        props = {
          ...props,
          ...params.column.colDef.headerComponentParams.columnDefsToProps(
            params
          )
        };
      }
      props.params = params;
      return props;
    },
    eventMaper(params) {
      const userEventMap = params.column.colDef.headerComponentParams.eventMap;
      if (isEmpty(userEventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = params.context.componentParent;
      Object.keys(userEventMap).forEach((listenerKey) => {
        vueEventMap[listenerKey] = parentReference[userEventMap[listenerKey]];
      });
      return vueEventMap;
    }
  }
};
</script>

<style></style>
