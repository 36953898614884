import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import {
  addAll,
  addEntity,
  leftCustomBidValues
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/bulkCustomBid.js';

export const updateRightTableFilterFn = (
  showMessage,
  bidsLength,
  context,
  entity = 'keywords'
) => {
  context.showTargetWarningMessage = showMessage;
  if (showMessage) {
    context.targetWarningTitle = `${bidsLength} ${entity} have no bids.`;
    context.targetWarningSubText = `Default value set to ${context.$currency}1.00, but can be changed as desired.`;
  }
};

export const suggestedBidRowClassRules = {
  ' empty-suggested-bids-row': (params) => {
    return !params.data.suggested_bid;
  }
};

export const generateRightHeaderFilterCheckBox = (
  vueRef,
  filterKey = 'suggested_bid',
  entity = 'keywords'
) => {
  const key = filterKey;
  return {
    show: () => checkForShowBidFilter(vueRef, key),
    filterFn: (rows = []) => {
      const filterKey = key;
      return rows.filter((row) => !row[filterKey]);
    },
    entity: {
      name: entity,
      message: 'without bid.',
      defaultValue: `Default bid set to 1${vueRef.$currency}`
    }
  };
};

function checkForShowBidFilter(vueRef, filterKey) {
  if (!vueRef.rightTableRows.length) {
    vueRef.$emit('updateRightTableFilter', false);
    return false;
  }
  const zeroBids = vueRef.rightTableRows.filter(
    (keyword) => !keyword[filterKey]
  );
  if (zeroBids.length) {
    vueRef.$emit('updateRightTableFilter', true, zeroBids.length);
  } else {
    vueRef.$emit('updateRightTableFilter', false);
  }
  return zeroBids.length;
}

export const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

export const updateTextInputKeyword = (data, minBid = 0, maxBid = 1000) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const customBid = data?.ref?.suggestedCustomBidInput;
  const filterCheckBoxArray = data?.filterCheckBoxArray;
  if (customBid <= minBid || customBid > maxBid) {
    data?.ref?.$snackbar.open({
      message: 'Please add a valid custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  const keywords = data?.keywords;
  const addToRightKeywordsTable = [];
  for (const item of keywords) {
    let newKeyword = true;
    for (const rightTableDataItem of data.ref.rightTableRows) {
      for (const selectedMatchType of filterCheckBoxArray) {
        const key = item + '&' + capitalizeFirstLetter(selectedMatchType);
        if (rightTableDataItem.key === key) {
          newKeyword = false;
        }
      }
    }
    if (newKeyword) {
      for (const selectedMatchType of filterCheckBoxArray) {
        addToRightKeywordsTable.push({
          suggested_bid: '-',
          exact_bid: customBid,
          keyword: item,
          key: item + '&' + capitalizeFirstLetter(selectedMatchType),
          match_type: capitalizeFirstLetter(selectedMatchType)
        });
      }
    } else {
      data?.ref?.$snackbar.open({
        message: 'One or more keywords already exists in Added keywords table',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    }
  }
  data.ref.addEntityFromRawObjectArray(addToRightKeywordsTable);
};

export const generateMatchTypeRows = (row, matchType, showKeyword) => {
  const tempRow = JSON.parse(JSON.stringify(row));
  const tempKeyword = tempRow.keyword ? tempRow.keyword.toLowerCase() : null;
  tempRow.key = tempKeyword + '&' + matchType;
  tempRow.keywordToShow = showKeyword ? tempRow.keyword : ' ';
  tempRow.match_type = matchType;
  return tempRow;
};

const generateRows = (vueRef, context) => {
  const newRows = [];
  let filterBroad = false;
  let filterPhrase = false;
  let filterExact = false;
  const checkBoxValues = vueRef.leftFilterCheckBoxArray
    .toString()
    .toLowerCase();
  filterBroad = checkBoxValues.includes('broad');
  filterPhrase = checkBoxValues.includes('phrase');
  filterExact = checkBoxValues.includes('exact');
  if (
    (!filterPhrase && !filterExact && !filterBroad) ||
    (filterPhrase && filterExact && filterBroad)
  ) {
    filterPhrase = true;
    filterExact = true;
    filterBroad = true;
  }
  vueRef.leftTableData.rows.forEach((row) => {
    row.suggested_bid = row.exact_bid;
    if (filterBroad) {
      const broad = generateMatchTypeRows(row, 'Broad', filterBroad);
      newRows.push(broad);
    }
    if (filterPhrase) {
      const phrase = generateMatchTypeRows(row, 'Phrase', !filterBroad);
      newRows.push(phrase);
    }
    if (filterExact) {
      const exact = generateMatchTypeRows(
        row,
        'Exact',
        !filterBroad && !filterPhrase && !filterBroad
      );
      newRows.push(exact);
    }
  });
  vueRef.leftTableRows = newRows;
  let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
  const allConfig =
    fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
  if (allConfig?.suggestedCampaigns[suggestedCampaignIndex]?.targets) {
    vueRef.rightTableRows = allConfig?.suggestedCampaigns[
      suggestedCampaignIndex
    ]?.targets?.map((item) => {
      item.key = item.keyword + '&' + item.match_type;
      return item;
    });
  }
};
export const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

const getTableData = (context, type = 'targeting') => {
  let apiRequest = {
    limit: 50,
    page: 1
  };
  const suggestedCampaignId = context?.$route?.query?.key?.split('-')?.[0];
  let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const { adFormat, adFormatSubType, skus, creativeSkus, selectedPages } =
    allStepsData?.suggestedCampaigns?.[suggestedCampaignIndex] || {};

  let suggestedSkus = cloneDeep((skus?.length && skus) || []);
  if (adFormat === 'storeSpotlight') {
    const newSkus = [];
    for (const item of selectedPages) {
      newSkus.push(item.selectedSku);
    }
    suggestedSkus = cloneDeep((newSkus?.length && newSkus) || []);
  } else if (
    adFormat === 'productCollection' &&
    adFormatSubType === 'new_landing_page'
  ) {
    suggestedSkus = cloneDeep((skus?.length && skus) || []);
  } else if (adFormat === 'video' || adFormat === 'productCollection') {
    suggestedSkus = cloneDeep(
      (creativeSkus?.length && creativeSkus) || (skus?.length && skus) || []
    );
  }
  let skuValues = '';
  suggestedSkus.forEach((item) => {
    skuValues += `'${item.asin}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));
  const campaignConfig =
    allStepsData?.goalConfig?.customMetadata?.campaigns?.filter(
      (item) => item.suggestedCampaignId === suggestedCampaignId
    );
  apiRequest = campaignConfig?.[0]?.[type]?.request
    ? cloneDeep(campaignConfig?.[0]?.[type]?.request)
    : apiRequest;
  apiRequest.limit = context?.step?.pageLimit
    ? context?.step?.pageLimit
    : apiRequest?.limit || 50;
  deepReplaceObjectValues(apiRequest, ':asin', skuValues);
  return apiRequest;
};

const getTableDataForCreateAdGroup = (
  context,
  type = 'targeting',
  selectedRecommendation = null
) => {
  let apiRequest = {
    limit: 50,
    page: 1
  };
  let suggestedCampaignIndex = context?.$route?.query?.key?.split('-')?.[1];
  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const { adFormat, adFormatSubType, skus, creativeSkus, selectedPages } =
    allStepsData?.suggestedCampaigns?.[suggestedCampaignIndex];

  let suggestedSkus = cloneDeep((skus?.length && skus) || []);
  if (adFormat === 'storeSpotlight') {
    const newSkus = [];
    for (const item of selectedPages) {
      newSkus.push(item.selectedSku);
    }
    suggestedSkus = cloneDeep((newSkus?.length && newSkus) || []);
  } else if (
    adFormat === 'productCollection' &&
    adFormatSubType === 'new_landing_page'
  ) {
    suggestedSkus = cloneDeep((skus?.length && skus) || []);
  } else if (adFormat === 'video' || adFormat === 'productCollection') {
    suggestedSkus = cloneDeep(
      (creativeSkus?.length && creativeSkus) || (skus?.length && skus) || []
    );
  }
  let skuValues = '';
  suggestedSkus.forEach((item) => {
    skuValues += `'${item.asin}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));
  let recommendedConfig = {};
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.keyword?.[type];
  if (recommendedConfigArray.length > 0) {
    if (!selectedRecommendation) {
      recommendedConfig = recommendedConfigArray[0];
    } else {
      recommendedConfig = recommendedConfigArray.filter(
        (item) => item.recommendationOrder === selectedRecommendation.value
      )[0];
    }
    apiRequest = cloneDeep(recommendedConfig?.request);
    deepReplaceObjectValues(apiRequest, ':asin', skuValues);
  }
  return apiRequest;
};

const keywordLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      // currDefinition
      name: 'keyword_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword Type',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'exact_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'exact_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () => addAll(vueRef),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: (row) => addEntity(vueRef, row),
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

export const keywordLeftTableAdGroupCreation = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keywordToShow',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () => addAll(vueRef),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: (row) => addEntity(vueRef, row),
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'keyword_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword Type',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];
};

export const keywordRightTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'exact_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Bid',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'exact_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '4',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export const keywordRightTableAdGroupCreation = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'keyword_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 100
        }
      }
    },
    {
      name: 'suggeted bid',
      type: 'custom',
      uiField: {
        widget: 'icon',
        uiLabel: 'Suggested bid',
        uiType: 'custom',
        uiOrder: '4',
        metadata: {
          getTooltipText: (contextParams) =>
            contextParams.data?.suggested_bid && 'Set suggested bid',
          widget: 'icon',
          type: 'iconText',
          tableColumnName: 'suggested_bid',
          formatType: 'currency',
          getDisplayIcon: (context) => {
            return context.params.data?.suggested_bid && 'trending-neutral';
          },
          showOnUi: true,
          isFixed: false,
          width: 90,
          iconClickEvent: (context, event) => {
            if (!context.params.data?.suggested_bid) {
              return;
            }

            var rowNodes = {
              rowNodes: [context.params.node]
            };
            if (!context.params.data.exact_bid) {
              context.params.data.exact_bid = context.params.data.suggested_bid;
            }
            vueRef.rightTableInstance.redrawTableRows(rowNodes);
          }
          // contextReturnEvent: this.getArrowButtonContext
        }
      }
    },
    {
      name: 'exact_bid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '5',
        uiType: 'custom',
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'exact_bid',
          defaultValueColumnName: 'exact_bid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 100,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validatinon should happen here
            context.params.data.exact_bid = context.bidValue;
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '6',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export const negativeKeywordRightTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'negative_keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'negative_keyword',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '4',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Negative Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const negativeKeywordLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'negative_keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'negative_keyword',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords on this page'
        },
        uiOrder: '4',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

const negativeKeywordLeftTableAdGroupCreation = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'negative_keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'negative_keyword',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];
};

export function keywordTargetingState(context) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple keywords are not allowed'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  leftCustomBidValues,
                  showLeftCustomBid: true,
                  columnDefs: keywordLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'keyword'
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    filterStyle: {
                      Broad: 'width:140px',
                      Phrase: 'width:160px'
                    },
                    header: 'Filter by:',
                    defaultSelected: ['Broad', 'Phrase', 'Exact'],
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        updateTextInputKeyword(data);
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    filterStyle: {
                      Broad: 'width:140px',
                      Phrase: 'width:160px'
                    },
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              hidePaginationOptions:
                context?.step?.hidePaginationOptions || false,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: async (vueRef) => {
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  const selectedCampaign =
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex];
                  if (selectedCampaign?.campaignType === 'sponsoredProducts') {
                    generateRows(vueRef, context);
                    return;
                  }
                  if (
                    selectedCampaign?.campaignType === 'sponsoredBrands'
                    // (selectedCampaign?.adFormatSubType === 'store_on_amazon' ||
                    //   selectedCampaign?.adFormatSubType === 'custom_url')
                  ) {
                    // to stop multiple recommendation bid api calls
                    if (!vueRef.leftTableRows.length) {
                      return;
                    }

                    try {
                      generateRows(vueRef, context);
                      const requestPayload = {
                        keywords: [],
                        campaignType: 'sb',
                        adFormat: 'productCollection'
                      };
                      for (const item of vueRef.leftTableRows) {
                        const keywordObject = {
                          matchType: item?.match_type.toLowerCase(),
                          keywordText: item?.keyword,
                          key: `${item?.match_type.toLowerCase()}_${
                            item?.keyword
                          }`
                        };
                        requestPayload.keywords.push(keywordObject);
                      }
                      const response = await HttpService.post(
                        'GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER',
                        requestPayload
                      );
                      const keywordRecommendations =
                        response?.data?.keywordRecommendations;
                      for (let i = 0; i < keywordRecommendations.length; i++) {
                        vueRef.leftTableRows[i].suggested_bid =
                          keywordRecommendations[i]?.suggestedBid;
                        vueRef.leftTableRows[i].exact_bid =
                          keywordRecommendations[i]?.suggestedBid;
                      }
                      vueRef.leftTableRows = [...vueRef.leftTableRows];
                    } catch (err) {
                      console.log(err);
                    }
                  }
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.targets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.targets;
                  }
                  // if (!vueRef.rightTableRows?.length) {
                  //   if (vueRef.leftTableData?.rows?.length) {
                  //     const message = `Top ${
                  //       vueRef.leftTableData?.rows?.length
                  //     } suggested keyword${
                  //       vueRef.leftTableData?.rows?.length === 1 ? '' : "'s"
                  //     } added by default`;
                  //     vueRef.$snackbar.open({
                  //       message: message,
                  //       duration: 2000,
                  //       buttonColor: '#f5d908',
                  //       actionText: ''
                  //     });
                  //   }

                  //   vueRef.rightTableRows = vueRef.leftTableData.rows;
                  // }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableData(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keywordToShow === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                rowClassRules: suggestedBidRowClassRules,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: keywordRightTableAdGroupCreation(vueRef)
              },
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'keywords'
                )
              },
              defaultNewBidValue: 1,
              headerText: () =>
                `Added Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      },
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context)
    }
  };
}

export function negativeKeywordTargetingState(context) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'negative_keyword',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Negative Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Negative Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Negative keywords are not allowed'
            },
            left: {
              altStore: 'getLeftTableDataALT',
              tabs: {
                Suggested: {
                  localFilter: {},
                  columnDefs: negativeKeywordLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'negative_keyword'
                  },
                  showFilterCheckBox: false
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add Negative Keywords',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        const checkBoxValues =
                          data?.ref?.leftFilterCheckBoxArray;
                        for (const item of keywords) {
                          const keywordText = item?.trim();
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (
                              rightTableData.negative_keyword === keywordText &&
                              checkBoxValues.includes(
                                rightTableData?.match_type
                              )
                            ) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword && keywordText?.length) {
                            for (const checkItem of checkBoxValues) {
                              addToRightKeywordsTable.push({
                                negative_keyword: keywordText,
                                match_type: checkItem
                              });
                            }
                          } else {
                            data?.ref?.$snackbar.open({
                              message: keywordText?.length
                                ? 'One or more negative keywords already exists in Added Negative keywords table'
                                : 'Invalid input',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: false,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Match Type:',
                    items: ['Negative Phrase', 'Negative Exact'],
                    filterStyle: {
                      PHRASE: 'width:160px'
                    }
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApiALT',
                updateRows: (vueRef) => {
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.negativeTargets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.negativeTargets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApiALT'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableData(context, 'negativeTargeting'),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: negativeKeywordLeftTable(vueRef)
              },
              headerText: 'Add Negative Keywords'
            },
            right: {
              table: 'right',
              altStore: 'getRightTableDataALT',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: negativeKeywordRightTable(vueRef)
              },
              headerText: () =>
                `Added Negative Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].negativeTargets =
          rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}

export function keywordTargetingStateForCreateAdGroup(context) {
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.keyword?.['targeting'];
  const leftRecommendationsDropdownValues = recommendedConfigArray.map(
    (item) => ({
      title: item?.recommendationName,
      value: item?.recommendationOrder
    })
  );
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple keywords are not allowed'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: keywordLeftTableAdGroupCreation(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'keyword'
                  },
                  showLeftCustomBid: true,
                  leftCustomBidValues,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    filterStyle: {
                      Broad: 'width:140px',
                      Phrase: 'width:160px'
                    },
                    header: 'Filter by:',
                    defaultSelected: ['Broad', 'Phrase', 'Exact'],
                    items: ['Broad', 'Phrase', 'Exact']
                  },
                  leftRecommendationsDropdownValues:
                    leftRecommendationsDropdownValues,
                  showLeftRecommendationsDropdown:
                    leftRecommendationsDropdownValues.length > 0
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        updateTextInputKeyword(data);
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    filterStyle: {
                      Broad: 'width:140px',
                      Phrase: 'width:160px'
                    },
                    header: 'Filter by:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  generateRows(vueRef, context);
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableDataForCreateAdGroup(
                  context,
                  'targeting',
                  vueRef.selectedLeftCustomRecommendation
                ),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTableAdGroupCreation(vueRef),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keywordToShow === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: keywordRightTableAdGroupCreation(vueRef),
                rowClassRules: suggestedBidRowClassRules
              },
              headerText: () => {
                return `Added Keywords (${vueRef.rightTableRows.length})`;
              },
              header: {
                filterCheckbox: generateRightHeaderFilterCheckBox(
                  vueRef,
                  'suggested_bid',
                  'keywords'
                )
              },
              defaultNewBidValue: 1
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].targets = rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      },
      updateRightTableFilter: (showMessage, bidsLength) =>
        updateRightTableFilterFn(showMessage, bidsLength, context)
    }
  };
}

export function negativeKeywordTargetingStateForCreateAdGroup(context) {
  const allStepsData = fetchSavedState(
    context,
    'campaignCreation/getAllStepsData'
  );
  const recommendedConfigArray =
    allStepsData?.goalConfig?.customMetadata?.keyword?.['negativeTargeting'];
  const leftRecommendationsDropdownValues = recommendedConfigArray.map(
    (item) => ({
      title: item?.recommendationName,
      value: item?.recommendationOrder
    })
  );
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Negative Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Negative Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Negative keywords are not allowed'
            },
            left: {
              altStore: 'getLeftTableDataALT',
              tabs: {
                Suggested: {
                  localFilter: {},
                  columnDefs: negativeKeywordLeftTableAdGroupCreation(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'negative_keyword'
                  },
                  showFilterCheckBox: false,
                  leftRecommendationsDropdownValues:
                    leftRecommendationsDropdownValues,
                  showLeftRecommendationsDropdown:
                    leftRecommendationsDropdownValues.length > 0
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'negative keyword',
                      buttonLabel: 'Add Negative Keywords',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        const checkBoxValues =
                          data?.ref?.leftFilterCheckBoxArray;
                        for (const item of keywords) {
                          const keywordText = item?.trim();
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (
                              rightTableData.negative_keyword === keywordText &&
                              checkBoxValues.includes(
                                rightTableData?.match_type
                              )
                            ) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword && keywordText?.length) {
                            for (const checkItem of checkBoxValues) {
                              addToRightKeywordsTable.push({
                                key: keywordText + '&' + checkItem,
                                negative_keyword: keywordText,
                                match_type: checkItem
                              });
                            }
                          } else {
                            data?.ref?.$snackbar.open({
                              message: keywordText?.length
                                ? 'One or more negative keywords already exists in Added Negative keywords table'
                                : 'Invalid input',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: false,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Match Type:',
                    items: ['Negative Phrase', 'Negative Exact'],
                    filterStyle: {
                      'Negative Phrase': 'width:260px'
                    }
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApiALT',
                updateRows: (vueRef) => {
                  let suggestedCampaignIndex =
                    context?.$route?.query?.key?.split('-')?.[1];
                  suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
                  const allConfig =
                    fetchSavedState(
                      context,
                      'campaignCreation/getAllStepsData'
                    ) || {};
                  if (
                    allConfig?.suggestedCampaigns[suggestedCampaignIndex]
                      ?.negativeTargets
                  ) {
                    vueRef.rightTableRows =
                      allConfig?.suggestedCampaigns[
                        suggestedCampaignIndex
                      ]?.negativeTargets;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApiALT'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getTableDataForCreateAdGroup(
                  context,
                  'negativeTargeting'
                ),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: negativeKeywordLeftTableAdGroupCreation(vueRef)
              },
              headerText: 'Add Negative Keywords'
            },
            right: {
              table: 'right',
              altStore: 'getRightTableDataALT',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: negativeKeywordRightTable(vueRef)
              },
              headerText: () =>
                `Added Negative Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let suggestedCampaignIndex =
          context?.$route?.query?.key?.split('-')?.[1];
        suggestedCampaignIndex = parseInt(suggestedCampaignIndex);
        let allConfig =
          fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
        allConfig.suggestedCampaigns[suggestedCampaignIndex].negativeTargets =
          rows;
        allConfig = { ...allConfig };
        updateSavedState(
          context,
          'campaignCreation/updateAllSetpsData',
          allConfig
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        // this.switchBlueBar('line item');
        // this.removeCustomCustomComponents();
        // this.closeActionsWorkflow();
      },
      closePanelMethod() {
        // this.removeCustomCustomComponents();
      }
    }
  };
}
