import HttpService from '@/utils/services/http-service';
import { msProCategoryConstants } from '@/components/pages/insights/amazon/market-share/utils';

export default class PerformanceTrend {
  metadata;
  isLoading;

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getMetadata() {
    try {
      this.isLoading = true;
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = data;
      this.isLoading = false;
      return data;
    } catch (err) {
      console.error('err', err);
      this.isLoading = false;
      throw err;
    }
  }

  constructPayload(request, globalSettings, selectedMetric) {
    const displayShareIn = globalSettings.settingValue.displayShareIn.label;
    const include3p = globalSettings.settingValue.include3p.value;
    const metricMetaData =
      this.metadata.metadata[
        msProCategoryConstants.performanceTrendMetrics[selectedMetric]
      ][displayShareIn][include3p];
    const metric = this.metadata.metrics[metricMetaData].name;
    request.metricsList = [metric];
    if (selectedMetric !== 'categorySize') {
      request.operations.orderByList[0].dimension = metric;
      delete request.operations.limit;
      request.entityType = globalSettings.settingValue?.viewBy?.entityType;
    } else {
      request.operations.orderByList = [];
    }
    request.operations.timeseriesEnabled = true;
    request.where.date = globalSettings.where.date;
    request.where.pvpDate = globalSettings.where.pvpDate;
    request.where.dimensionNameValueList = [
      ...request.where.dimensionNameValueList,
      ...globalSettings.where.dimensionNameValueList
    ];
    return request;
  }

  async getData(apiStructure, globalSettings, selectedMetric) {
    try {
      this.isLoading = true;
      const dataRequest = this.constructPayload(
        apiStructure?.request,
        globalSettings,
        selectedMetric
      );
      const response = await HttpService.post(
        apiStructure?.service,
        dataRequest,
        {
          append: apiStructure.endPoint
        }
      );
      this.isLoading = false;
      return response?.data?.entityData || [];
    } catch (err) {
      console.log('error', err);
      this.isLoading = false;
      throw err;
    }
  }
}
