<template>
  <div class="u-width-100 u-margin-bottom-40">
    <h3 class="u-font-size-2 u-text-align-left u-padding-0">
      {{ (step.header && step.header.title) || '' }}
    </h3>
    <div
      class="step-desc u-font-size-5 u-spacing-mt-s u-color-grey-lighter u-line-height-1-3"
    >
      <p
        v-for="(desc, $index) in step.header.desc"
        :key="$index"
      >
        {{ desc }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StrategyBuilderHeader',
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  methods: {}
};
</script>
