import utilConfig from '@/components/ams/campaigns/utils.js';
import { filters } from '@ciq-dev/ciq-ui';

const { baseMeasuresList } = utilConfig('amazon', 'campaign');
const request = {
  cubeName: 'ams_campaigns_campaign_realtime_workbench',
  measuresList: [],
  groupByDimensionsList: [],
  where: {
    dimensionNameValueList: []
  },
  orderByList: [
    {
      dimension: 'spend',
      direction: 'DESC'
    }
  ],
  limit: 30,
  page: 1,
  customAPIDecisionVars: {},
  bundleCubeExecutionRequest: {
    ams_cm_campaigns_adv_data: {
      cubeName: 'ams_campaigns_campaign_workbench',
      measuresList: [
        ...baseMeasuresList,
        'paid_sales',
        'count',
        'bidplus',
        'attributedSalesNewToBrand14d',
        'PercentageSalesNewToBrand14d',
        'attributedOrdersNewToBrand14d',
        'attributedUnitsOrderedNewToBrand14d',
        'percent_time_in_budget',
        'estimated_missed_impressions_upper7d',
        'estimated_missed_clicks_upper7d',
        'estimated_missed_sales_upper7d',
        'acogs',
        'iroas',
        'incremental_fraction',
        'incremental_sales',
        'incremental_fraction_band'
      ],
      groupByDimensionsList: ['campaign_id', 'portfolio_id'],
      orderByList: [],
      timeseriesRollupBy: ':timeseriesRollupBy',
      getLatestAvailableInsteadOfRollup: false,
      getSharePercentage: false,
      disableShard: false,
      getPointInTimeMetrics: false,
      dedupBeforeRollup: {
        enableDedupBeforeRollup: true,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
          'report_date'
        ],
        excludeDedupAxes: []
      },
      commonFilterEnabled: false,
      customAPIDecisionVars: {
        system: 'amazon_campaign_management'
      },
      enableNewPVPFormulaForSOV: false,
      flowResidueEntity: false,
      entityType: 'campaign_id',
      sharePercentageV2: false,
      pvptimeSeriesEnabled: false,
      timeseriesEnabled: false,
      timeseriesDimension: 'report_date',
      yoyenabled: false,
      pvpenabled:
        filters?.config_check('feature.pages.enableRMMAmazonPVP') || false,
      pointInTimeMetrics: false,
      pvptimeseriesEnabled: false,
      splyenabled: false
    },
    adv_metadata: {
      cubeName: 'ams_campaigns_campaign_metadata',
      measuresList: [
        'start_date',
        'tactic',
        'end_date',
        'base_budget',
        'campaign_status',
        'campaign_type',
        'targeting_type',
        'serving_status',
        'suggested_budget',
        'campaign_name',
        'portfolio_name',
        'profile_name',
        'profile_id',
        'creative_type'
      ],
      groupByDimensionsList: ['campaign_id', 'portfolio_id'],
      orderByList: [],
      timeseriesRollupBy: ':timeseriesRollupBy',
      getLatestAvailableInsteadOfRollup: false,
      getSharePercentage: false,
      disableShard: false,
      getPointInTimeMetrics: false,
      dedupBeforeRollup: {
        enableDedupBeforeRollup: false,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        excludeDedupAxes: []
      },
      commonFilterEnabled: false,
      customAPIDecisionVars: {
        system: 'amazon_campaign_management'
      },
      enableNewPVPFormulaForSOV: false,
      flowResidueEntity: false,
      entityType: 'campaign_id',
      sharePercentageV2: false,
      pvptimeSeriesEnabled: false,
      timeseriesEnabled: false,
      yoyenabled: false,
      pvpenabled: false,
      pointInTimeMetrics: false,
      pvptimeseriesEnabled: false,
      splyenabled: false,
      where: {
        dimensionNameValueList: [],
        date: {},
        pvpDate: {}
      },
      tagWhereClause: []
    }
  },
  entityType: 'campaign_id'
};
export const CubeConfig = {
  request
};
