<template>
  <div>
    <sticky-filter-panel
      :last-week-ranges-compared-to-today="true"
      :hide-export="true"
      :hide-clear-all-btn="true"
      :disable-view="true"
      class="sticky-panel sticky-filter-panel category_page"
      :options-list="[]"
      :default-date-post-range="defaultDateRange"
      :enable-minimum-selection="true"
      :view-selection-enabled="false"
    >
      <div
        slot="before-filter"
        class="u-display-flex u-flex-align-items-center u-height-36px"
      >
        <div class="u-font-size-2 u-font-weight-700 u-display-flex capitalize">
          Content Automation
        </div>
      </div>
      <div
        slot="preDatePicker"
        class="u-display-flex u-flex-align-items-center"
      >
        <rb-button
          text="Setup Automation"
          icon-left="bot"
          type="filled"
          class="u-color-grey-white u-spacing-mr-s"
          :click-fn="setupAutomation.bind(this)"
        />
        <rb-button
          icon-left="info-circle-outline"
          type="hollow"
          class="u-color-grey-white u-spacing-mr-xl"
          :click-fn="setupAutomation.bind(this)"
        />
        <div class="pim-container">
          <p class="u-font-size-5 btn-label">5 Optimizations</p>
          <rb-button
            text="PIM Source of Truth"
            icon-left="sku-box"
            type="hollow"
            class="hollow-outlined-btn u-bg-color-transparent-base u-spacing-mr-s"
            :click-fn="setupAutomation.bind(this)"
          />
        </div>
      </div>
    </sticky-filter-panel>
    <div class="u-spacing-pl-m u-spacing-pr-m u-spacing-pb-m">
      <div class="score-card-container">
        <content-score-card
          v-for="contentScore in contentScoreDetails"
          :key="contentScore.key"
          :content-score-details="contentScore"
        />
      </div>
      <spotlight
        :spotlights="spotlights"
        :is-send-email="true"
      />
      <sku-breakdown />
    </div>
  </div>
</template>

<script>
import widgetsUtil from '@/components/widgetMixin';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import spotlight from '@/pages/all_retailers/dso/molecules/spotlight.vue';
import skuBreakdown from '@/pages/all_retailers/dso/content-automation/skuBreakdown.vue';
import productScore from '@/pages/all_retailers/dso/content-automation/productScore.vue';
import skuImage from '@/pages/all_retailers/dso/content-automation/skuImage.vue';
import productNameDescription from '@/pages/all_retailers/dso/content-automation/productNameDescription.vue';
import buyBox from '@/pages/all_retailers/dso/content-automation/buyBox.vue';
import contentScoreCard from '@/pages/all_retailers/dso/content-automation/contentScoreCard.vue';
import retailerImage from '@/pages/all_retailers/dso/molecules/retailerImage.vue';
import Vue from 'vue';

export default {
  components: {
    stickyFilterPanel,
    spotlight,
    skuBreakdown,
    productNameDescription,
    contentScoreCard
  },
  mixins: [widgetsUtil],
  data() {
    return {
      defaultDateRange: 'last30Days',
      contentScoreDetails: [
        {
          key: 'overall',
          score: 75,
          scoreTrend: ['overall', 85, 75, 70, 75, 60, 75],
          retailerContentScore: [
            {
              retailer: 'amazon',
              value: 85,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'target',
              value: 75,
              imgUrl: '/images/target-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'walmart',
              value: 70,
              imgUrl: '/images/walmart-logo.png'
            },
            {
              retailer: 'homedepot',
              value: 75,
              imgUrl: '/images/home-depot-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'bestbuy',
              value: 60,
              imgUrl: '/images/best-buy-logo.png'
            },
            {
              retailer: 'lowes',
              value: 75,
              imgUrl: '/images/lowes-logo.png'
            }
          ]
        },
        {
          key: 'title',
          score: 75,
          scoreTrend: ['title', 90, 95, 55, 30, 65, 60, 90],
          retailerContentScore: [
            {
              retailer: 'amazon',
              value: 90,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'target',
              value: 95,
              imgUrl: '/images/target-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'walmart',
              value: 55,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'homedepot',
              value: 65,
              imgUrl: '/images/home-depot-logo.png'
            },
            {
              retailer: 'bestbuy',
              value: 60,
              imgUrl: '/images/best-buy-logo.png'
            },
            {
              retailer: 'lowes',
              value: 90,
              imgUrl: '/images/lowes-logo.png'
            }
          ]
        },
        {
          key: 'image',
          score: 81,
          scoreTrend: ['image', 80, 95, 90, 90, 60, 55],
          retailerContentScore: [
            {
              retailer: 'amazon',
              value: 80,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'target',
              value: 95,
              imgUrl: '/images/target-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'walmart',
              value: 90,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'homedepot',
              value: 90,
              imgUrl: '/images/home-depot-logo.png'
            },
            {
              retailer: 'bestbuy',
              value: 60,
              imgUrl: '/images/best-buy-logo.png'
            },
            {
              retailer: 'lowes',
              value: 55,
              imgUrl: '/images/lowes-logo.png'
            }
          ]
        },
        {
          key: 'description',
          score: 78,
          scoreTrend: ['description', 85, 45, 70, 65, 60, 80],
          retailerContentScore: [
            {
              retailer: 'amazon',
              value: 85,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'target',
              value: 45,
              imgUrl: '/images/target-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'walmart',
              value: 70,
              imgUrl: '/images/amazon-logo.png',
              color: '#0080FF'
            },
            {
              retailer: 'homedepot',
              value: 65,
              imgUrl: '/images/home-depot-logo.png'
            },
            {
              retailer: 'bestbuy',
              value: 60,
              imgUrl: '/images/best-buy-logo.png'
            },
            {
              retailer: 'lowes',
              value: 80,
              imgUrl: '/images/lowes-logo.png'
            }
          ]
        }
      ],
      spotlights: [
        {
          type: 'positive',
          title: "What's working",
          icon: 'show-chart',
          actionItems: [
            {
              icon: 'thumbsup',
              action: 'Product Image Fixed for Priority SKUs',
              actionDescription:
                'Image for priority SKUs: B09RMRNSBF, B08WG4HKKY was fixed yesterday. No further action needed.',
              iconClasses: 'u-color-green-base'
            },
            {
              icon: 'thumbsup',
              action: 'Content Score for key categories',
              actionDescription:
                'OLED and Ultra-wide monitors has been 90+ for the month of Dec across Amazon and Best Buy',
              iconClasses: 'u-color-green-base'
            }
          ],
          iconClasses: 'u-bg-color-green-base u-border-radius-s rb-icon--medium'
        },
        {
          type: 'improved',
          title: 'What Needs to be Monitored?',
          icon: 'show-chart',
          actionItems: [
            {
              icon: 'show-chart',
              action: 'Automated Tickets Generated',
              actionDescription:
                'Generated to fix the incorrect title on Best Buy for SKUs B09WD4TWYP, B08HR3TM4Z',
              iconClasses:
                'u-bg-color-orange-base u-color-white-base u-border-radius-s rb-icon--medium'
            },
            {
              icon: 'show-chart',
              action: 'Content Score for sound bars',
              actionDescription:
                'The sound bar description scores are in the bottom 20% of your assortment.',
              iconClasses:
                'u-bg-color-orange-base u-color-white-base u-border-radius-s rb-icon--medium'
            }
          ],
          iconClasses:
            'u-bg-color-orange-base u-border-radius-s rb-icon--medium'
        },
        {
          type: 'negative',
          title: 'What need action now?',
          icon: 'show-chart',
          actionItems: [
            {
              icon: 'thumbsdown',
              action: 'SLA breached for ticket resolution for SKU',
              actionDescription:
                'B09WD4TWYP at retailer Homedepot and Lowes. The issue has been open for 14+ days.',
              iconClasses: 'u-color-red-base'
            },
            {
              icon: 'thumbsdown',
              action: 'LG Signature 88-inch image score is very low',
              actionDescription:
                'B09WJXGWZV content scores have dropped below 40 for retailer amazom',
              iconClasses: 'u-color-red-base'
            }
          ],
          iconClasses: 'u-bg-color-red-base u-border-radius-s rb-icon--medium'
        }
      ]
    };
  },
  created() {
    Vue.component('ProductScore', productScore);
    Vue.component('SkuImage', skuImage);
    Vue.component('ProductNameDescription', productNameDescription);
    Vue.component('BuyBox', buyBox);
    Vue.component('RetailerImage', retailerImage);
  },
  methods: {
    setupAutomation() {
      console.log('Setup Automation');
    }
  }
};
</script>

<style scoped lang="css">
.pim-container {
  margin-bottom: 3.7em;
}
.btn-label {
  padding-bottom: 1rem;
  text-align: center;
}
.score-card-container {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}
</style>
