<template>
  <div class="u-spacing-pv-xxxl u-height-100">
    <section
      class="u-height-100 u-spacing-pt-m u-spacing-pr-l u-spacing-ph-xxl"
    >
      <createCampaignHeader :step="step" />
      <adGroup
        v-if="!isCreateAdGroupFlow && !hideAdgroup"
        :default-value="adGroup"
        @adgroupUpdated="handleAdgroup"
      />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        class="add-sku-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <div
          v-if="skuMessages || (sbValidSKULength && skuMessages)"
          slot="right-table-footer"
          class="edit_suggested_campaign_adgroup u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
          data-cy="skuMessage"
        >
          <rb-icon
            class="u-color-orange-base rb-icon--small"
            icon="info-circle-fill"
          />
          {{ skuMessages }}
        </div>
      </component>
      <createCampaignFooter
        :show-left-btn="step.showLeftButton"
        :disable-right-btn="
          !skusLength ||
          sbValidSKULength ||
          (!isCreateAdGroupFlow && !hideAdgroup && !adGroup)
        "
        class="u-margin-top-40"
        :btn-left-text="enableBackButton ? 'Back' : 'Exit'"
        data-cy="adGroupNameError"
        :error-message="!adGroup ? 'Ad group name is required' : ''"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import adGroup from './adGroup';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';

export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    adGroup,
    CustomActionPanel
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    customActionPanelCustomState: {},
    adGroup: 'Ad Group 1',
    suggestedCampaignIndex: 0
  }),
  computed: {
    enableBackButton() {
      return this.$route?.query?.own || this.step?.enableBackButton || false;
    },
    isCreateAdGroupFlow() {
      return this.$route.name === 'create_ad_group';
    },
    hideAdgroup() {
      return this.step?.hideAdgroup;
    },
    skuMessages() {
      return this.allStepsData?.suggestedCampaigns?.[
        this.suggestedCampaignIndex
      ]?.messages?.skus;
    },
    sbValidSKULength() {
      let valid = false;
      const { adFormat, adFormatSubType, skus } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (
        adFormat === 'productCollection' &&
        adFormatSubType === 'new_landing_page'
      ) {
        if (skus?.length < 3) {
          valid = true;
        }
      } else if (adFormat === 'video') {
        if (skus?.length !== 1) {
          valid = true;
        }
      }
      return valid;
    },
    skusLength() {
      const { skus } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      return skus.length;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  watch: {
    skusLength: {
      handler(newVal) {
        this.step.description = `${newVal} SKU${
          newVal !== 1 ? "'s" : ''
        } added`;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    this.customActionPanelCustomState =
      this.step?.customActionPanelCustomState(this);
    this.setAdgroupName(null);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    handleAdgroup(value) {
      this.setAdgroupName(value);
    },
    setAdgroupName(newVal) {
      const allStepsData = { ...this.allStepsData };
      if (newVal === null) {
        if (
          allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
            .adgroupName
        ) {
          this.adGroup =
            allStepsData.suggestedCampaigns[
              this.suggestedCampaignIndex
            ].adgroupName;
        } else {
          allStepsData.suggestedCampaigns[
            this.suggestedCampaignIndex
          ].adgroupName = this.adGroup;
        }
      } else {
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].adgroupName = newVal;
        this.adGroup = newVal;
      }
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      if (this.enableBackButton) {
        this.$emit('prevStep', {});
        return;
      }
      if (this.isCreateAdGroupFlow) {
        const campaignId = this?.$route?.query?.key?.split('-')?.[0];
        this.$router.push({
          name: 'EntityDetails',
          params: {
            entityId: campaignId,
            entityType: 'sp'
          }
        });
        return;
      }
      this.$router.push({
        name: 'setup_suggested_campaign',
        query: {
          edit: true
        }
      });
    }
  }
};
</script>

<style scoped>
.edit_suggested_campaign_adgroup {
  bottom: -20px;
}
</style>
