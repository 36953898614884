<template>
  <div class="u-spacing-pv-s">
    <div
      class="u-font-size-7 u-font-weight-600 u-spacing-pb-xs u-color-grey-x-light"
    >
      {{ title }}
    </div>
    <div class="u-font-size-6 u-color-grey-base widthClass u-line-height-1-3">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="css" scoped>
.padding--right {
  padding-right: 0% !important;
}
.widthClass {
  width: 85%;
}
</style>
