var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spacing-pt-m top-contributor-card u-border-radius-m u-spacing-m-s u-border-width-s u-border-all u-border-color-grey-xxx-light u-width-100 u-height-100",attrs:{"data-cy":'top-contributor-' + _vm.title}},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"},[_c('div',{staticClass:"u-width-100 u-color-x-grey-base u-font-size-5 u-font-weight-600 u-spacing-pl-m"},[_c('strong',[_vm._v(" "+_vm._s(_vm.title))])]),_c('span',{staticClass:"u-flex-justify-content-flex-end u-width-100 u-display-flex u-flex-align-items-center u-spacing-pr-m"},_vm._l((_vm.headerOptions),function(h_item,key){return _c(h_item.ui_component,{key:key,tag:"component",class:h_item.icon_classes,style:({
          'grid-column': h_item.grid_column,
          'grid-row': h_item.grid_row
        }),attrs:{"is-loading":h_item.isLoading,"icon":h_item.icon,"tooltip-text":h_item.tooltipText,"is-metric-tool-tip-enabled":h_item.isMetricToolTipEnabled},on:{"onClick":function($event){return _vm.handler(h_item.icon)}}})}),1)]),(_vm.isEmpty)?_c('div',{staticClass:"empty u-display-flex u-flex-justify-content-center u-flex-align-items-center"},[_c('div',{staticClass:"u-font-size-3",attrs:{"data-cy":"top-contributor-no-data"}},[_vm._v(" No Data ")])]):_c('div',{staticClass:"invisible-scrollbar u-spacing-mt-m u-height-100",style:(_vm.isShippedCogsComparisonEnabled
        ? 'height100%;min-height:340px; max-height:400px '
        : 'height100%;min-height:300px; max-height:400px ')},[(_vm.isShippedCogsComparisonEnabled)?_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between u-spacing-pt-m"},[_c('div',{staticClass:"u-font-size-7 u-font-family-proximanova u-color-grey-xlight u-font-weight-600 u-font-style-normal u-spacing-pl-m"},[_vm._v(" "+_vm._s(_vm.cardLevelTitle)+" ")]),_c('div',{staticClass:"u-font-size-7 u-font-family-proximanova u-font-weight-600 u-color-grey-xlight u-font-style-normal u-spacing-pr-m"},[_vm._v(" Absolute / Shipped COGS ")])]):_vm._e(),_vm._l((_vm.rows),function(row,idx){return _c('div',{key:idx,staticClass:"u-width-100 u-spacing-mt-m u-spacing-pr-l u-spacing-pl-s",style:(row.type === 'asin'
          ? _vm.isShippedCogsComparisonEnabled
            ? 'height:44px'
            : 'height:40px'
          : 'height:40px; padding-top:4px')},[_c('div',{staticClass:"row rowflex u-display-flex u-flex-justify-content-space-between u-width-100"},[(row.type === 'asin')?_c('div',[_c('rb-sku',{attrs:{"id":idx,"title":row.payload.data['title'],"asin":row.payload.data['asin'],"image-url":row.payload.data['imageUrl'],"product-page-url":row.payload.data['productPageUrl'],"enable-s-d-p":true},on:{"navigateToSDP":function($event){return _vm.navigateToSDP(row.payload.data['asin'])}}})],1):_c('div',{staticClass:"u-display-flex-column u-flex-align-items-center u-font-size-6 u-font-weight-normal u-color-x-grey-base"},[(row.payload.url)?_c('div',{staticClass:"polink"},[_c('a',{attrs:{"href":row.payload.url,"target":"_blank"}},[_vm._v(" "+_vm._s(row.payload.data)+" ")])]):_c('div',[_vm._v(" "+_vm._s(row.payload.data)+" ")])]),(_vm.isShippedCogsComparisonEnabled)?_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-font-size-7 u-font-weight-bold u-text-align-left u-bg-color-blue"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"u-display-flex u-flex-align-items-center",attrs:{"title":row.payload.tooltipText ? row.payload.tooltipText : 'NA'}},[_c('span',{class:['overflow-styles u-display-block']},[_c('span',{staticClass:"u-font-size-7 u-font-weight-bold u-text-align-left u-color-x-grey-base"},[_vm._v(_vm._s(row.payload.value)+" /")]),_c('span',{staticClass:"shipCogstext u-font-size-7 u-color-x-grey-base"},[_vm._v(" "+_vm._s(row.payload.shippedCogsValue))])])])]):_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-font-size-7 u-font-weight-bold u-text-align-left u-bg-color-blue"},[_vm._v(" "+_vm._s(row.payload.value)+" ")])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }