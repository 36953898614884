export const MEDIA_PLANNER_TIMERS_LC_KEY = 'media-planner-timers';
export const HISTORICAL_DATA_DISCLAIMER_FOR_STEPS =
  'The advertising metrics shown here might change post backfill of data used for media plan generation';
export const HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED =
  'Data is shown for only available dimension values';
export const HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED_EXTENDED =
  'Data is shown only for available dimension values. If you see a mismatch from the previous screen it could be because some data exists outside of the shown dimensions.';
export const HISTORICAL_DATA_DISCLAIMER_FOR_DASHBOARD =
  'The forecasted metrics are generated based on historical + backfilled data.';

export const defaultState = {
  expectedGrowth: {
    data: {
      overall: {},
      bULevel: {}
    },
    loading: false
  }
};

const dw = {
  page: 'BusinessInsights',
  widget: 'chartingWb',
  entityType: '',
  metricsList: [],
  eventsList: [],
  operations: {
    showByEntities: [],
    timeseriesEnabled: false,
    timeseriesDimension: 'feed_date',
    daterangeDimension: 'feed_date',
    commonFilterEnabled: true,
    pvpenabled: false,
    samePeriodLastYearTimeseriesEnabled: false,
    enableDedupBeforeRollup: true,
    timeseriesRollupBy: 'DAY',
    timeseriesWeekStartDay: 'SUNDAY',
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'asin',
      'feed_date'
    ]
  },
  where: {
    dimensionNameValueList: [],
    date: {
      from: '',
      to: '',
      name: 'custom',
      page_wise_min_max_key: 'BusinessInsights'
    },
    pvpDate: {
      from: '2021-01-30',
      to: '2022-02-05',
      compare_name: null
    }
  }
};

const ams_campaigns_asin_workbench = {
  page: 'BusinessInsights',
  widget: 'chartingWb',
  entityType: '',
  metricsList: [],
  eventsList: [],
  operations: {
    timeseriesEnabled: false,
    timeseriesDimension: 'feed_date',
    daterangeDimension: 'feed_date',
    commonFilterEnabled: false,
    pvpenabled: false,
    samePeriodLastYearTimeseriesEnabled: false,
    enableDedupBeforeRollup: true,
    timeseriesRollupBy: 'DAY',
    timeseriesWeekStartDay: 'SUNDAY',
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'asin',
      'feed_date'
    ]
  },
  where: {
    dimensionNameValueList: [],
    date: {
      from: '',
      to: '',
      name: 'custom',
      page_wise_min_max_key: 'BusinessInsights'
    },
    pvpDate: {
      from: '2021-01-30',
      to: '2022-02-05',
      compare_name: null
    }
  }
};

export const ams_campaigns_asin_workbench_backfilled = {
  ...ams_campaigns_asin_workbench,
  page: 'BusinessInsightsBackfilled',
  widget: 'chartingWbBackfilled',
  where: {
    ...ams_campaigns_asin_workbench.where,
    date: {
      ...ams_campaigns_asin_workbench.where.date,
      page_wise_min_max_key: 'BusinessInsightsBackfilled'
    }
  }
};

const sales_dashboard = {
  page: 'BusinessInsights',
  widget: 'chartingWb',
  entityType: '',
  metricsList: [],
  eventsList: [],
  operations: {
    timeseriesEnabled: false,
    showByEntities: [],
    timeseriesDimension: 'feed_date',
    daterangeDimension: 'feed_date',
    commonFilterEnabled: true,
    pvpenabled: false,
    samePeriodLastYearTimeseriesEnabled: false,
    enableDedupBeforeRollup: true,
    timeseriesRollupBy: 'DAY',
    timeseriesWeekStartDay: 'SUNDAY',
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'asin',
      'feed_date'
    ]
  },
  where: {
    dimensionNameValueList: [],
    date: {
      from: '',
      to: '',
      name: 'custom',
      page_wise_min_max_key: 'BusinessInsights'
    },
    pvpDate: {
      from: '2021-01-30',
      to: '2022-02-05',
      compare_name: null
    }
  }
};

export const baseConfigForBULevelData = {
  page: 'WeeklyBusinessReport',
  where: {
    date: {
      to: '',
      from: ''
    },
    pvpDate: {
      to: '2023-04-08',
      from: '2023-04-02'
    },
    dimensionNameValueList: []
  },
  widget: 'breakdown',
  entityType: '',
  eventsList: [],
  operations: {
    page: 1,
    limit: 100,
    system: 'reporting_4001_WeeklyBusinessReport',
    orderByList: [],
    bundleRequestJoinType: 'FULL_OUTER_JOIN',
    isChainingJoinEnabled: true
  },
  entityValue: null,
  metricsList: [],
  enablePaginationCount: true,
  bundleDataGroupsRequest: {
    dw,
    ams_campaigns_asin_workbench,
    sales_dashboard
  }
};
