<template>
  <div
    v-if="metadataLoad || isKeywordsLoading"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metadataError || isKeywordsError"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <cardContainer
    v-else
    :title="metadata.label"
    :filters="activeTab == 0 ? computedFilterConfig : []"
    :action-icons="actionIcons"
    :last-updated-date="lastUpdatedDate"
    :horizontal-padding="activeTab == 0 ? 'l' : 'none'"
    :body-bottom-padding="activeTab == 0 ? 'l' : 'none'"
    @download="handleDownload"
    @optionSelect="handleOptionSelect"
  >
    <section
      slot="pre-filters"
      class="u-display-flex"
    >
      <tabs
        class="u-spacing-mr-m"
        :tabs-data="tabData"
        :active-tab-index="activeTab"
        @tabClick="handleTabClick"
      />
      <rb-select-v2
        class="u-spacing-mr-m"
        :on-close="handleKeywordsSelect"
        :options="keywordsFilterConfig.options"
        :send-details="true"
      >
        <multiSelectChip
          slot="trigger"
          ref="keywords-select-tigger"
          :pre-text="keywordsFilterConfig.preText"
          :selections="computedKeywordsFilterSelection"
          :chip-has-cross="true"
        />
        <!-- <div
          v-if="keywordsFilterConfig.options.length"
          slot="footer"
          class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
        >
          <rb-button
            class="u-spacing-mr-s"
            :click-fn="handleCancelClick"
            :type="'hollow'"
            text="Cancel"
            size="s"
          />
          <rb-button
            :click-fn="handleApplyClick"
            :type="'filled'"
            text="Apply"
            size="s"
          />
        </div> -->
      </rb-select-v2>
    </section>
    <section slot="body">
      <!-- :color-object="colorObject" -->
      <chart-with-legends
        v-show="activeTab == 0"
        :hide-zero="false"
        :chart-data="formattedChartData"
        :chart-config="chartConfig"
        :metrics-list="metricsList"
        :metrics-shown="selectedValues.metricsShown"
        :default-select-metric="selectedValues.selectedMetric"
        :metric-config="selectedValues.metricDisplayConfig"
        :metric-data="selectedValues.metricData"
        :enable-add-event="false"
        class="u-spacing-mt-l"
        :selected-metric-limit="selectedMetricLimit"
        :disable-add-new-metric="disableAddNewMetric"
        :is-chart-loading="isChartLoading"
        :emit-on-metric-default-change="true"
        :plot-on-y-axis-only="true"
        :show-powered-by-c-i-q-text="true"
        :custom-chart-class="[]"
        :minimum-metric="6"
        :enable-watefall="true"
        :metric-font-size="'u-font-size-4'"
        @selectedMetricList="metricChanged"
      >
        <!-- <template slot="post-legends">
          <section
            class="u-display-flex u-spacing-mt-l u-spacing-pl-m u-border-width-s u-border-left u-color-grey-xxx-light"
          >
            <skuSriContainer
              class="u-border-width-s"
              :sku-options="skusDataFormatted"
              :selected-sku="selectedSku"
              :metric-name="'SRI'"
              :metric-data="metricData"
              :metric-config="metricConfig"
              :is-sku-loading="isSkuLoading"
              :is-sku-error="isSkuError"
              sri-border-color="#97cc04"
              @skuSelect="handleSkuSelect"
            />
          </section>
        </template> -->
      </chart-with-legends>
      <section v-show="activeTab == 1">
        <div
          v-if="tableLoad"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <loader
            class="u-bg-color-grey-white"
            :loading="true"
            :color="'#007cf6'"
          />
        </div>
        <div
          v-else-if="tableError"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <p>Error Occurred. Please try again later</p>
        </div>
        <!--  -->
        <div
          v-else-if="tableNoData"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <p>No Data</p>
        </div>
        <section
          v-else
          class="u-spacing-mt-s"
        >
          <rb-insights-table
            :config="tableConfig"
            :pagination-change-event="paginationChangeEvent"
            class="pagination-overlay"
            :grid-options="gridOptions"
            :row-height="45"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :pagination="true"
            :sorting-change-event="sortChangeEvent"
            :enable-client-side-pagination="true"
            :enable-col-resize="true"
            :table-row="tableRows"
            :table-column="columnDefs"
            :pagination-total-key="totalEntityCount"
            :pagination-per-page-limit="tableLimit"
            @gridReady="handleGridReady"
          />
        </section>
      </section>
    </section>
  </cardContainer>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import cardContainer from '../organisms/card-container';
import tabs from '../molecules/tabs';
import loader from '@/components/basic/loader';
import skuSriContainer from '../molecules/sku-sri-container';
import moment from 'moment';
import multiSelectChip from '../atoms/multi-select-chip';
import { cloneDeep } from 'lodash';
import { getDateRange } from '@/utils/helpers/date.js';
import { eventBus } from '@/utils/services/eventBus';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import HttpService from '@/utils/services/http-service';
import {
  appendAnchorBrandCondition,
  isSovDataGroup,
  appendAnchorFilter,
  getAnchorFilterPayload
} from './common';
import {
  groupApis,
  createDictionary,
  getColumnDefinition,
  transformTableData,
  attachSortConfigToDictionary,
  transformConfig,
  getTimeSeriesWithHoles,
  appendPartialArrays,
  splitMetricsIntoViews,
  requestTimeReplacement,
  hasPlaceholder
} from '../templates/dashboard-service-utils';

import SelectWithText from '../molecules/select-with-text.vue';
import Vue from 'vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import CONSTANTS from '@/utils/constants';

export default {
  components: {
    RbSelectV2,
    cardContainer,
    chartWithLegends,
    tabs,
    loader,
    skuSriContainer,
    multiSelectChip,
    SelectWithText
  },
  props: {
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      default: null
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    unsavedState: {
      type: Object,
      default: () => ({})
    },
    sovDataGroups: {
      type: Object,
      default: () => ({})
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    multiCrawl: {
      type: Boolean,
      default: false
    },
    intradayCrawlingAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortChangeEvent: 'sort-change',
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      tableConfigGenerated: false,
      lastUpdatedDate: null,
      actionIcons: [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with ad and share metrics for keywords in scope in table view'
        }
      ],
      downloadApi: {
        error: false,
        loading: false
      },
      allKeywordsText: 'All Keywords',
      monthRollupThreshold: 89,
      weekRollupThreshold: 29,
      tableNoData: false,
      tableLoad: true,
      tableError: false,
      paginationChangeEvent: 'paginationPerformanceOverview',
      gridOptions: {
        suppressColumnVirtualisation: false,
        defaultColDef: {
          resizable: false
        },
        domLayout: 'normal'
      },
      tableLimit: 0,
      totalEntityCount: 0,
      tableRows: [],
      columnDefs: [],
      trendlineGrouping: {},
      tableDataGroups: {},
      splitMetrics: {},
      colorObject: {},
      keywordListOptions: [],
      isApplyClicked: true,
      tabData: [{ label: 'Trendline' }, { label: 'Table' }],
      isKeywordsLoading: true,
      isKeywordsError: false,
      allKeyWordsSelected: true,
      isSkuLoading: true,
      isSkuError: true,
      metricData: {},
      otherMetrics: {},
      chartMetricGroups: {},
      skusDataFormatted: [],
      selectedSku: {},
      metricsData: {},
      metadata: {},
      formattedChartData: { data: [] },
      fetchedDataGroup: {},
      chartConfig: {
        chartOptions: {
          legend: false,
          xFormat: '%Y-%m-%d',
          timeseries: 'feed_date',
          tooltip_format: {},
          axes: {},
          grid: 'xy',
          hasEvents: false,
          type: 'line',
          types: {},
          xAxisType: 'category',
          stack: [],
          chartGetter: '',
          show_axis_colors: true,
          events: [],
          dynamicRange: true,
          size: { height: 380 },
          axis_format: {}
        },
        stack: [],
        chartGetter: '',
        xAxisType: 'category'
      },
      selectedMetricLimit: 3,
      disableAddNewMetric: true,
      selectedValues: {
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: [],
        metricData: {}
      },
      metricsList: [],
      isChartLoading: false,
      keywordsFilterConfig: {
        preText: 'Keywords:',
        selected: [],
        options: []
      },
      filtersConfig: [
        {
          preText: 'Roll up by:',
          emit: 'optionSelect',
          selected: {},
          options: [],
          allOptions: [
            {
              name: 'Days',
              apiFormat: 'DAY',
              filterValue: 0
            },
            {
              name: 'Weeks',
              apiFormat: 'WEEK',
              filterValue: 7
            },
            {
              name: 'Months',
              apiFormat: 'MONTH',
              filterValue: 31
            }
          ]
        }
      ],
      devMetadata: {
        metricsSegregated: {
          otherMetrics: ['sov_sri']
        },
        sriMetric: 'sov_sri'
      },
      isDemoClient: Vue.options.filters.config_check(
        'feature.demo.product_demo.enable'
      )
    };
  },
  computed: {
    computedKeywordsFilterSelection() {
      return this.getSeletedKeywords();
    },
    computedFilterConfig() {
      const filterConfig = JSON.parse(JSON.stringify(this.filtersConfig));
      const selectedFilterConfig = this.getWidgetUnsavedState()?.selectedRollup;
      filterConfig[0].selected = this.findSelectedRollup(selectedFilterConfig);
      return filterConfig;
    },
    getUnsavedWidgetMetdata() {
      return this.getUnsavedState()[this.widgetName];
    },
    activeTab() {
      return this.getUnsavedWidgetMetdata?.activeTab || 0;
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metadataError() {
      return this?.dataService?.metadataError;
    }
  },
  created() {
    eventBus.$on(this.paginationChangeEvent, (data) => {
      this.tableConfig.body.APIConfig.page = data;
      this.getTableData();
    });
    eventBus.$on(this.sortChangeEvent, (data) => {
      const unsavedPayload = [
        { dimension: data.colId, direction: data.sort.toUpperCase() }
      ];
      this.setUnsavedState('defaultOrderBy', unsavedPayload);
      this.getTableData();
    });
    this.setInitialUnsavedState();
    this.init();
  },
  destroyed() {
    eventBus.$off(this.paginationChangeEvent);
    eventBus.$off(this.sortChangeEvent);
  },
  methods: {
    appendAnchorBrand(requestCopy, dataGroup) {
      const { anchoredBrand } = this.getUnsavedSettings();
      if (
        dataGroup === 'sov_search_term_level_data' &&
        appendAnchorBrandCondition(anchoredBrand)
      ) {
        requestCopy.operations.anchoredBrand = anchoredBrand.title;
      }
    },
    getSeletedKeywords() {
      const { selectedKeywords } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'][
          this.widgetName
        ];
      const selectedKeywordsMap = {};
      selectedKeywords.forEach((item) => {
        selectedKeywordsMap[item] = 1;
      });
      const formattedSelections = this.keywordsFilterConfig.options.filter(
        (item) => {
          if (selectedKeywordsMap[item.title]) {
            return item;
          }
        }
      );
      return formattedSelections.length
        ? formattedSelections
        : [this.keywordsFilterConfig.options[0]];
    },
    handleGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    resetPagination() {
      this.tableConfig.body.APIConfig.page = 1;
    },
    getUnsavedSettings() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
        .settings;
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const { endPoint, request, service } =
            this.metadata.downloadApiTemplate;
          const unsavedState = this.getWidgetUnsavedState();
          const requestCopy = this.createTableRequest(request);
          requestCopy.widget = this.widgetName;
          requestCopy.operations.orderByList = unsavedState.defaultOrderBy;
          requestCopy.page = this.page;
          const { data } = await HttpService.post(service, requestCopy, {
            append: endPoint
          });
          downloadFile(data.url);
          resolve();
        } catch (e) {
          reject(e);
          console.error('error');
        }
      });
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIcons = actionIconsCopy;
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    findSelectedRollup(selected) {
      return this.filtersConfig[0].allOptions.find(
        (item) => item.apiFormat === selected
      );
    },
    setRollUpAvailableOptions(fromDate, toDate) {
      const startDate = moment(fromDate);
      const endDate = moment(toDate);
      const diff = endDate.diff(startDate, 'days');
      const { allOptions } = this.computedFilterConfig[0];
      const computedOptions = allOptions.filter((item) => {
        if (item.filterValue <= diff) {
          return item;
        }
      });
      this.$set(this.filtersConfig[0], 'options', computedOptions);
    },
    selectRollup() {
      const { date } = this.getLocalWhereClause();
      const { from, to } = date;
      const startDate = moment(from);
      const endDate = moment(to);
      const diff = endDate.diff(startDate, 'days');
      let newSelection = null;
      this.setRollUpAvailableOptions(from, to);
      if (diff >= this.monthRollupThreshold) {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'MONTH'
        );
      } else if (diff >= this.weekRollupThreshold) {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'WEEK'
        );
      } else {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'DAY'
        );
      }
      this.$set(this.filtersConfig[0], 'selected', newSelection);
      this.setUnsavedState('selectedRollup', newSelection.apiFormat);
    },
    setUnsavedState(key, value) {
      let unsavedStateCopy = JSON.parse(
        JSON.stringify(this.getWidgetUnsavedState())
      );
      unsavedStateCopy = {
        ...unsavedStateCopy,
        [key]: value
      };
      this.pushToStateStore(unsavedStateCopy);
    },
    setInitialUnsavedState() {
      const localUnsavedState = {};
      const localDefaultState = this.setInitialSavedState();
      const unsavedState = this.getUnsavedWidgetMetdata || {};
      const defaultStateKeys = Object.keys(localDefaultState);
      defaultStateKeys.forEach((key) => {
        localUnsavedState[key] = unsavedState[key] || localDefaultState[key];
      });
      this.pushToStateStore(localUnsavedState);
    },
    setInitialSavedState() {
      const localDefaultState = {};
      const initialState = this.initialState?.[this.widgetName] || {};
      const defaultState = this.getDefaultState();
      const defaultStateKeys = Object.keys(defaultState);
      defaultStateKeys.forEach((key) => {
        localDefaultState[key] = initialState[key] || defaultState[key];
      });
      this.pushToStateStore(localDefaultState, 'pushToInitialState');
      return localDefaultState;
    },
    getDefaultState() {
      return {
        activeTab: 0,
        selectedChartMetrics: [
          'all_page_1_count',
          'organic_page_1_count',
          'ams_sov_spend'
        ],
        metricsShown: [
          'all_page_1_count',
          'organic_page_1_count',
          'ams_sov_spend'
        ],
        selectedRollup: 'WEEK',
        selectedKeywords: [this.allKeywordsText],
        defaultOrderBy: [
          { dimension: 'amazon_search_frequency_rank', direction: 'ASC' }
        ]
      };
    },
    handleTabClick(index) {
      this.setUnsavedState('activeTab', index);
      this.$nextTick(() => {
        this.gridOptions?.api?.sizeColumnsToFit();
      });
    },
    getUnsavedState() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'];
    },
    getWidgetUnsavedState() {
      return this.getUnsavedState()[this.widgetName];
    },
    i() {
      return this.$store.getters[this.namespace + 'initalWidgetStates'];
    },
    getSelectedRollupData() {
      return this.filtersConfig[0].selected;
    },
    getSelectedRollup() {
      const selectedRollup = this.getSelectedRollupData();
      return selectedRollup.apiFormat;
    },
    handleOptionSelect(payload) {
      const { selection } = payload;
      if (selection && selection[0] && !selection[0].selected) {
        selection[0].selected = true;
        this.setUnsavedState('selectedRollup', selection[0].apiFormat);
        this.resetPagination();
        this.getChartData();
      }
    },
    handleApplyClick(payload) {
      this.isApplyClicked = true;
      this.$refs['keywords-select-tigger'].$refs.container.click();
    },
    handleCancelClick(payload) {
      this.isApplyClicked = false;
      this.$refs['keywords-select-tigger'].$refs.container.click();
    },
    handleSkuSelect(payload) {
      const { selection } = payload;
      if (selection && selection[0]) {
        this.selectedSku = selection[0];
        this.getSriData();
      }
    },
    handleKeywordsSelect(context, selections) {
      if (selections && selections[0]) {
        this.isApplyClicked = false;
        this.allKeyWordsSelected =
          selections.length === this.keywordsFilterConfig.options.length;
        this.setUnsavedState('selectedKeywords', [selections[0].title]);
        this.keywordListOptions = cloneDeep(this.keywordsFilterConfig.options);
        this.resetPagination();
        this.getChartData();
        this.getTableData();
      }
    },
    metricChanged(event, rollupChanged) {
      const { selectedMetric, metricDisplayList } = event;
      if (selectedMetric.length > 0) {
        this.getSelectedMetricsData(selectedMetric, this.chartMetricGroups);
        const metricList = selectedMetric.map((item) => item.alias);
        const metricsShown = metricDisplayList.map((item) => item.alias);
        this.setUnsavedState('selectedChartMetrics', metricList);
        this.setUnsavedState('metricsShown', metricsShown);
      }
    },
    setAllLoadStates() {
      this.metricData = { tag1: 'loading' };
      this.isSkuLoading = true;
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.splitMetrics = splitMetricsIntoViews(this.metadata.metrics);
        this.otherMetrics = this.getSkuMetricsMetadata(
          this.metadata.metrics,
          this.devMetadata
        );
        this.lastUpdatedDate = moment(this.metadata.calendar.max_date).format(
          'LL'
        );
        this.tableGrouping = groupApis(this.splitMetrics.table);
        this.isIntradayKeywordKey = 'is_intraday';
        this.getData();
      } catch (e) {
        console.error('e', e);
      }
    },
    async getData() {
      try {
        const { date } = this.getLocalWhereClause();
        const { from, to } = date;
        this.setRollUpAvailableOptions(from, to);
        await this.getKeywordsData();
        this.getChartData();
        this.getTableData();
        // this.getSkuSriData();
      } catch (e) {
        console.error('e', e);
      }
    },
    createTableRequest(baseRequest) {
      const baseRequestCopy = cloneDeep(baseRequest);
      const tableDataGroupsKeys = Object.keys(this.tableGrouping);
      tableDataGroupsKeys.forEach((dataGroup) => {
        const { api } = this.tableGrouping[dataGroup];
        const dataGroupRequest = cloneDeep(api.request);
        dataGroupRequest.entityType = dataGroupRequest.entityType.replace(
          ':entityType',
          'search_term'
        );
        dataGroupRequest.page = this.page;
        dataGroupRequest.widget = this.widgetName;
        dataGroupRequest.operations.pvpenabled = false;
        dataGroupRequest.operations.timeseriesEnabled = false;
        const where = this.getLocalWhereClause(dataGroup);
        this.appendAnchorBrand(dataGroupRequest, dataGroup);
        this.setLocalFilters(dataGroupRequest.where, where);
        dataGroupRequest.where.dimensionNameValueList =
          this.getSelectedKeywordDimensionNameValue(
            where.dimensionNameValueList
          );
        baseRequestCopy.bundleDataGroupsRequest[dataGroup] = dataGroupRequest;
      });
      return baseRequestCopy;
    },
    getSelectedMetricFromDataGroupRequest(requestCopy) {
      return [...requestCopy.metricsList];
    },
    getMetricsFromBundledRequest(bundleRequestCopy) {
      const dataGroups = Object.keys(bundleRequestCopy.bundleDataGroupsRequest);
      const metrics = [];
      dataGroups.forEach((dataGroup) => {
        metrics.push(
          ...this.getSelectedMetricFromDataGroupRequest(
            bundleRequestCopy.bundleDataGroupsRequest[dataGroup]
          )
        );
      });
      return metrics;
    },
    async getTableData() {
      try {
        this.tableLoad = true;
        this.tableError = false;
        this.tableNoData = false;
        this.tableConfigGenerated = false;
        const unsavedState = this.getWidgetUnsavedState();
        const { request } = this.metadata.metadata.dataAPI;
        const { table: tableMetrics } = this.splitMetrics;
        const { dimension, direction } = unsavedState.defaultOrderBy[0];
        const requestCopy = this.createTableRequest(request);
        requestCopy.operations.orderByList = unsavedState.defaultOrderBy;
        requestCopy.operations.page = this.tableConfig.body.APIConfig.page;
        const tableMetricsConfig =
          this.getMetricsFromBundledRequest(requestCopy);
        this.tableLimit = requestCopy.operations.limit;
        this.generateTablesConfig(
          tableMetricsConfig,
          tableMetrics,
          dimension,
          direction
        );
        this.$nextTick(() => {
          this.tableConfigGenerated = true;
        });
        const { entityData } = await this.dataService.getData(requestCopy);
        this.tableLoad = false;
        this.tableError = false;
        this.totalEntityCount = entityData?.[0]?.totalEntityCount || 0;
        this.tableRows = transformTableData(entityData);
        if (this.intradayCrawlingAvailable && this.multiCrawl) {
          this.tableRows.forEach((item) => {
            item[this.isIntradayKeywordKey] = 1;
          });
        }
        if (this.isDemoClient) {
          this.tableRows.forEach((item) => {
            if (item.search_term?.toUpperCase() === 'PURE ORGANIC BAR') {
              item.ams_sov_iroas = 4.1;
            } else if (item.search_term?.toUpperCase() === 'PURE BAR') {
              item.ams_sov_iroas = 4.92;
            } else {
              item.ams_sov_iroas =
                (item.ams_sov_roas ||
                  item.walmart_sov_roas ||
                  item.target_sov_roas ||
                  0) *
                (1 - (item.organic_page_1_count || 0) * 0.01);
            }
          });
        }
        this.tableNoData = this.tableRows.length === 0;
      } catch (e) {
        this.tableLoad = false;
        this.tableError = true;
        console.error('e', e);
      }
    },
    handleKeywordSelection(context) {
      const { value } = context;
      this.$emit('setAccessedFrom', {
        hierarchy: 'search_term',
        text: value
      });
      this.$emit('setKeywordSelection', value);
    },
    generateTablesConfig(
      selectedMetrics,
      selectableMetrics,
      sortDimension,
      sortDirection
    ) {
      const dictionary = createDictionary(selectedMetrics, selectableMetrics);
      dictionary.search_term = {
        title: 'Keywords',
        toolTipText: 'Search keyword being tracked',
        keyOrder: 0,
        pinned: 'left',
        icon: CONSTANTS.crawlTypes.multiCrawl.icon,
        showIcon: (params) =>
          this.intradayCrawlingAvailable &&
          params.data[this.isIntradayKeywordKey],
        tableConfig: {
          cellRendererFramework: 'linkDisplay',
          cellRendererParams: {
            hoverRequired: true,
            hoverText: 'Snapshots of Page 1',
            hoverClickFunction: this.handleKeywordSelection
          }
        }
      };
      attachSortConfigToDictionary(dictionary, sortDimension, sortDirection);
      if (this.isDemoClient) {
        dictionary.ams_sov_iroas = {
          title: 'iROAS',
          toolTipText: 'Average return on ad spend on the keyword',
          keyOrder: 18,
          enableSorting: true,
          isInverted: false,
          type: 'NUMERIC',
          unit: 'CURRENCY'
        };
      }
      this.columnDefs = getColumnDefinition(dictionary);
    },
    createDataGroups(metricsMetadata) {
      const metricsApiGrouping = {};
      const metricsKeys = Object.keys(metricsMetadata);
      metricsKeys.forEach((metricKey) => {
        const group = metricsMetadata[metricKey].dataGroup;
        if (!metricsApiGrouping[group]) {
          metricsApiGrouping[group] = {};
        }
        metricsApiGrouping[group][metricKey] = metricsMetadata[metricKey];
      });
      return metricsApiGrouping;
    },
    async getSkuSriData() {
      try {
        await this.getSkusData();
        await this.getSriData();
      } catch (e) {
        this.metricData = { tag1: 'error' };
        console.error('e', e);
      }
    },
    async getSriData() {
      return new Promise(async (resolve, reject) => {
        try {
          this.metricData = { tag1: 'loading' };
          const { sriMetric: metric } = this.devMetadata;
          const { request } = this.otherMetrics[metric].api;
          const requestCopy = JSON.parse(JSON.stringify(request));
          const where = this.getLocalWhereClause(metric);
          requestCopy.where = where;
          requestCopy.metricsList = [metric];
          requestCopy.operations.timeseriesRollupBy = this.getSelectedRollup();
          requestCopy.where.dimensionNameValueList =
            this.getSelectedKeywordDimensionNameValue(
              requestCopy.where.dimensionNameValueList
            );
          const { entityData } = await this.dataService.getData(requestCopy);
          const sriData = entityData[0].data[0];
          const tag1 = sriData.RESULT[metric];
          const tag2 = sriData.PVP['PVP_DIFF_' + metric];
          // update when doing sri
          const trendLine = this.getSovTimeseries(
            sriData,
            this.otherMetrics[metric].label,
            metric
          );
          this.colorObject[this.otherMetrics[metric].label] = '#97cc04';
          this.formattedChartData = {
            data: [...this.formattedChartData.data, trendLine]
          };
          this.metricData = { tag1, tag2 };
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    getSelectedSkuDimensionNameValue() {
      return {
        dimensionName: this.selectedSku.entityType,
        dimensionValue: this.selectedSku.sku
      };
    },
    getSelectedKeywordDimensionNameValue(dimensionNameValueList) {
      // const unsavedState = this.getWidgetUnsavedState();
      const selectedKeywords = this.getSeletedKeywords();
      if (selectedKeywords[0].title === this.allKeywordsText) {
        return dimensionNameValueList;
      }
      // if (this.allKeyWordsSelected) {
      //   return [];
      // }
      // const sanitisedStringList = this.keywordsFilterConfig.selected.map(
      //   item => {
      //     const unsanitisedSrring = item.title;
      //     const quotationFix = unsanitisedSrring.replace("'", "''");
      //     const sanitisedString = `'${quotationFix}'`;
      //     return sanitisedString;
      //   }
      // );
      // const concatStrings = sanitisedStringList.join(',');
      const dimensionName = 'search_term';
      const newDimensionNameValueList = dimensionNameValueList.filter(
        (item) => item.dimensionName !== dimensionName
      );
      const dimensionValue = selectedKeywords[0].title;
      // .map(item => item.replace("'", "''"))
      // .join(',');
      // const dimensionValue = this.keywordsFilterConfig.selected.title.replace(
      //   "'",
      //   "''"
      // );
      newDimensionNameValueList.push({
        dimensionName,
        dimensionValue
      });
      return newDimensionNameValueList;
      // return [
      //   {
      //     dimensionName,
      //     dimensionValue: concatStrings,
      //     operator: 'IN'
      //   }
      // ];
    },
    async getSkusData() {
      return new Promise(async (resolve, reject) => {
        try {
          this.isSkuLoading = true;
          this.isSkuError = false;
          const skusApi = this.metadata.customMetadata.commonApis.skusData;
          skusApi.where = this.getLocalWhereClause();
          skusApi.where.dimensionNameValueList =
            this.getSelectedKeywordDimensionNameValue(
              skusApi.where.dimensionNameValueList
            );
          const response = await this.dataService.getData(skusApi);
          this.skusDataFormatted = [];
          response.entityData.forEach((skuData, index) => {
            const obj = {};
            skuData.data.forEach((data) => {
              obj[data.name] = data.RESULT[data.name];
            });
            obj.entityType = skuData.entityType;
            this.selectedSku = this.skusDataFormatted[0];
            this.skusDataFormatted.push(obj);
          });
          this.isSkuLoading = false;
          resolve();
        } catch (e) {
          console.error('err', e);
          this.isSkuError = true;
          this.isSkuLoading = false;
          reject(e);
        }
      });
    },
    extractKeywordsListData(entityData) {
      const keywordsDataFormatted = [];
      entityData.forEach((keywordData) => {
        const data = {
          title: keywordData.entityValue,
          entityType: keywordData.entityType
        };
        if (this.intradayCrawlingAvailable) {
          const isIntradayKeyword = this.multiCrawl
            ? true
            : keywordData.data.find(
                (item) => item.name === this.isIntradayKeywordKey
              )?.RESULT[this.isIntradayKeywordKey];
          if (isIntradayKeyword) {
            data.icon = CONSTANTS.crawlTypes.multiCrawl.icon;
          }
        }
        keywordsDataFormatted.push(data);
      });
      return keywordsDataFormatted;
    },
    setLocalFilters(requestWhere, localWhereClause) {
      requestWhere.dimensionNameValueList =
        localWhereClause.dimensionNameValueList;
      if (hasPlaceholder(requestWhere.date)) {
        requestWhere.date.from = localWhereClause.date.from;
        requestWhere.date.to = localWhereClause.date.to;
        requestWhere.date.name = localWhereClause.date.name;
        requestWhere.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      }
      if (hasPlaceholder(requestWhere.pvpDate)) {
        requestWhere.pvpDate.from = localWhereClause.pvpDate.from;
        requestWhere.pvpDate.to = localWhereClause.pvpDate.to;
        requestWhere.pvpDate.compare_name =
          localWhereClause.pvpDate.compare_name;
      }
      if (this.multiCrawl) {
        requestTimeReplacement(requestWhere, localWhereClause);
      }
    },
    getKeywordsData() {
      return new Promise(async (resolve, reject) => {
        try {
          const { anchoredBrand } = this.getUnsavedSettings();
          this.isKeywordsLoading = true;
          this.isKeywordsError = false;
          const keywordsApi = cloneDeep(
            this.metadata.customMetadata.commonApis.keywordsData
          );
          console.log(
            'unsavedWidgetState',
            JSON.stringify(this.getWidgetUnsavedState())
          );
          const localWhere = this.getLocalWhereClause();
          if (appendAnchorBrandCondition(anchoredBrand)) {
            localWhere.dimensionNameValueList.push(
              getAnchorFilterPayload(anchoredBrand.title)
            );
          }
          this.setLocalFilters(keywordsApi.where, localWhere);
          const { entityData } = await this.dataService.getData(keywordsApi);
          this.isKeywordsLoading = false;
          this.isKeywordsError = false;
          const keywordsDataFormatted = [
            {
              title: this.allKeywordsText,
              selected: true
            },
            ...this.extractKeywordsListData(entityData)
          ];
          this.$set(
            this.keywordsFilterConfig,
            'options',
            keywordsDataFormatted
          );
          this.keywordListOptions = cloneDeep(
            this.keywordsFilterConfig.options
          );
          resolve();
        } catch (e) {
          this.isKeywordsLoading = false;
          this.isKeywordsError = true;
          reject(e);
        }
      });
    },
    getChartData() {
      this.fetchedDataGroup = {};
      this.setAllLoadStates();
      const unsavedState = this.getWidgetUnsavedState();
      const where = this.getLocalWhereClause();
      const xAxis = this.getXAxis(
        where.date.from,
        where.date.to,
        unsavedState.selectedRollup
      );
      this.formattedChartData = {
        data: [xAxis]
      };
      this.chartMetricGroups = this.createDataGroups(
        this.splitMetrics.trendline
      );
      this.getMetricsList(this.splitMetrics.trendline);
      this.getSelectedMetricsData(
        this.selectedValues.metricsShown,
        this.chartMetricGroups
      );
    },
    getSkuMetricsMetadata(metricsMetadata, metadata) {
      const { otherMetrics } = metadata.metricsSegregated;
      const otherMetricsStore = {};
      otherMetrics.forEach((skuMetric) => {
        otherMetricsStore[skuMetric] = metricsMetadata[skuMetric];
        delete metricsMetadata[skuMetric];
      });
      return otherMetricsStore;
    },
    createWaterfallStructure(metricsList, treeStructure, metricsWithWaterFall) {
      metricsList.push(treeStructure);
      const filteredMetricsList = metricsList.filter((item) => {
        if (metricsWithWaterFall[item?.alias]) {
          item.waterfall = true;
          return item;
        }
      });
      // helper.disableTreeNodes(treeStructure, disabledList);
      return filteredMetricsList;
    },
    setMetrics(metricsArray, metricsMetadata) {
      const metricsList = [];
      metricsArray.forEach((metric) => {
        if (metricsMetadata[metric]) {
          metricsList.push(metricsMetadata[metric]);
        }
      });
      return metricsList;
    },
    getMetricsList(metricsMetadata) {
      const unsavedState = this.getWidgetUnsavedState();
      this.metricsList = transformConfig(this, metricsMetadata, 'metric');
      const { metricsWithWaterfall = {}, waterfall = {} } =
        this.metadata.metadata.groupInfo || {};
      const { share_of_voice = {} } = waterfall;
      const selectedMetricsList = this.setMetrics(
        unsavedState.selectedChartMetrics,
        metricsMetadata
      );
      let metricsShown = null;
      metricsShown = this.setMetrics(
        unsavedState.metricsShown,
        metricsMetadata
      );
      const disabledList = selectedMetricsList.map((item) => ({
        key: item.alias,
        name: item.label
      }));
      this.createWaterfallStructure(
        this.metricsList,
        share_of_voice,
        metricsWithWaterfall,
        disabledList
      );
      this.selectedValues.metricsShown = [...metricsShown];
      this.selectedValues.selectedMetric = [
        ...selectedMetricsList
        // this.otherMetrics.sov_sri
      ];
      const metricList = selectedMetricsList.map((item) => item.alias);
      this.setUnsavedState('selectedChartMetrics', metricList);
      this.setUnsavedState('metricsShown', metricsShown);
    },
    getXAxis(fromDate, toDate, interval) {
      const momentFormat = interval + 's';
      const dateRange = getDateRange(fromDate, toDate, momentFormat);
      return ['feed_date', ...dateRange];
    },
    createMetricsBundle(dataGroup, selectableMetrics) {
      const selectableMetricKeys = Object.keys(selectableMetrics);
      const metricsList = [];
      selectableMetricKeys.forEach((key) => {
        if (selectableMetrics[key].dataGroup === dataGroup) {
          metricsList.push(selectableMetrics[key].alias);
        }
      });
      return metricsList;
    },
    getSelectedMetricsData(selectedMetrics, chartMetricGroups) {
      selectedMetrics.forEach((item) => {
        if (
          this.devMetadata.metricsSegregated.otherMetrics.includes(item.alias)
        ) {
          return;
        }
        if (!this.fetchedDataGroup[item.dataGroup]) {
          const unsavedState = this.getWidgetUnsavedState();
          this.isChartLoading = true;
          this.fetchedDataGroup[item.dataGroup] = {};
          let api = {};
          const metricsList = [];
          const metricKeys = Object.keys(chartMetricGroups[item.dataGroup]);
          metricKeys.forEach((metric) => {
            const searchedMetric = chartMetricGroups[item.dataGroup][metric];
            api = searchedMetric.api;
            metricsList.push(searchedMetric.alias);
            this.$set(this.selectedValues.metricData, searchedMetric.key, {
              tag1: 'loading'
            });
          });
          const { request } = api;
          const requestCopy = cloneDeep(request);
          requestCopy.entityType = requestCopy.entityType.replace(
            ':entityType',
            '#ALLOVER_AGGREGATE'
          );
          requestCopy.metricsList = metricsList;
          requestCopy.operations.timeseriesRollupBy =
            unsavedState.selectedRollup;
          const where = this.getLocalWhereClause(item.dataGroup);
          this.setLocalFilters(requestCopy.where, where);
          requestCopy.where.dimensionNameValueList =
            this.getSelectedKeywordDimensionNameValue(
              where.dimensionNameValueList
            );
          this.appendAnchorBrand(requestCopy, item.dataGroup);
          this.dataService
            .getData(requestCopy)
            .then((response) => {
              this.isChartLoading = false;
              this.fetchedDataGroup[item.dataGroup] = response;
              const { entityData } = response;
              const searchMap = {};
              for (let i = 0; i < entityData.length; i++) {
                const entityUnit = entityData[i];
                for (let j = 0; j < entityUnit.data.length; j++) {
                  searchMap[entityUnit.data[j].alias] = entityUnit.data[j];
                }
              }
              const graphData = [];
              for (let i = 0; i < metricsList.length; i++) {
                const metric = metricsList[i];
                const entityArray = searchMap[metric];
                const metricMetdata = chartMetricGroups[item.dataGroup][metric];
                const xAxis = this.getXAxis(
                  where.date.from,
                  where.date.to,
                  unsavedState.selectedRollup
                );
                const trendlineData = getTimeSeriesWithHoles(
                  xAxis,
                  entityArray?.name || '',
                  entityArray?.TIMESERIES || []
                );
                graphData.push([metricMetdata.key, ...trendlineData]);
                const metricDataValue = this.getValueAndPvp(
                  entityArray,
                  metricMetdata.keyName,
                  metricMetdata
                );
                this.$set(
                  this.selectedValues.metricData,
                  metricMetdata.key,
                  metricDataValue
                );
              }
              this.formattedChartData = {
                data: [...this.formattedChartData.data, ...graphData]
              };
            })
            .catch((e) => {
              console.error('e', e);
              metricKeys.forEach((metric) => {
                const searchedMetric =
                  chartMetricGroups[item.dataGroup][metric];
                this.$set(this.selectedValues.metricData, searchedMetric.key, {
                  tag1: 'error'
                });
              });
              this.isChartLoading = false;
            });
        }
      });
    },
    getValueAndPvp(entityUnit, metricKey, metricsMetadata) {
      const pvpPrefix =
        metricsMetadata.metadata.unit === 'PERCENTAGE' ? 'PVP_DIFF_' : 'PVP_';
      return {
        tag1: entityUnit?.RESULT?.[metricKey] || 0,
        tag2: entityUnit?.PVP?.[pvpPrefix + metricKey]
      };
    },
    getLocalWhereClause(dataGroup) {
      const unsavedSettings = this.getUnsavedSettings();
      const { anchoredBrand } = this.getUnsavedSettings();
      const globalWhereClause = cloneDeep(this.getGlobalWhereClause);
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause.time_range.startTime;
        where.time.to = globalWhereClause.time_range.endTime;
        where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
        where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      }
      if (isSovDataGroup(dataGroup, this.sovDataGroups)) {
        appendPartialArrays(
          where.dimensionNameValueList,
          unsavedSettings.selectedType,
          this.types
        );
      }
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      appendAnchorFilter(where, anchoredBrand);
      where.dimensionNameValueList.push(
        ...globalWhereClause.dimensionNameValueList
      );
      return where;
    },
    pushToStateStore(data, emit = 'pushToUnsavedState') {
      this.$emit(emit, { [this.widgetName]: data });
    }
  }
};
</script>

<style lang="css" scoped>
.intermediate-dimensions {
  height: 506px;
}
.pagination-overlay {
  width: 100%;
  height: 650px;
}
</style>
