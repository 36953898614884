<template>
  <div class="u-display-flex">
    <RbSelectV2
      width="240px"
      class="u-spacing-mr-m"
      :send-details="true"
      :on-close="onMetricClose"
      :options="metricsList"
      :class-name="'tableSelect campaigns-select'"
      :search-enabled="true"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer u-height-100"
      >
        <AddMetricTile />
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div class="u-display-flex u-flex-align-items-center">
          <div
            v-if="disabledMetricsFn(option.title)"
            class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
            @click.stop
          >
            <p
              class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
            >
              {{ option.title }}
            </p>
            <rb-icon
              v-tippy="{ placement: 'top', arrow: true }"
              class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
              :icon="'info-circle-outline'"
              title="Enabled when a single campaign is selected"
            />
          </div>
          <div
            v-else
            class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
          >
            <p
              class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
            >
              {{ option.title }}
            </p>
          </div>
        </div>
      </template>
    </RbSelectV2>
  </div>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import AddMetricTile from '@/components/basic/atoms/chart-legends/add-metric-tile.vue';

export default {
  components: {
    RbSelectV2,
    AddMetricTile
  },
  props: {
    metricsList: {
      type: Array,
      default: () => []
    },
    disabledMetricsFn: {
      type: Function,
      default: () => false
    }
  },
  methods: {
    onMetricClose(context, _val) {
      this.$emit('addNewMetric', context, _val);
    }
  }
};
</script>
