\
<template>
  <div>
    <dashboard-inflator
      :key="pageName"
      class="recommendation-dashboard"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :filter="filterSettings"
      :bookmark-path="bookmarkPath"
      :wrapper-class="'u-spacing-mr-xs'"
      :enable-date="false"
      :show-back-button="true"
      :title="recommendationDetails.label"
      :back-event-listener="'handleBackEvent'"
      @requestParamsChange="requestParamsChange"
      @handleBackEvent="handleBackEvent"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Loader from '@/components/basic/loader.vue';
import recommendationList from '@/pages/recommendations/recommendationsV2/recommendationsList.json';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import moment from 'moment-timezone';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { cloneDeep } from 'lodash';
import contentDiff from '@/components/widgets/custom_widgets/content_diff.vue';
import validationDiff from '@/components/widgets/custom_widgets/validation_diff.vue';
import variantChangeCell from '@/pages/recommendations/recommendationsV2/variant_change_cell.vue';

export default {
  components: {
    Loader
  },
  mixins: [dashboardMixin],
  data() {
    const context = this;
    return {
      context,
      dataService: {},
      currentDateAppliedObj: {},
      minMaxDate: {},
      pageInfo: null,
      recommendationDetails: null,
      recommendationList,
      filterEventAndLocalStorageKey: 'inventory-filters',
      filterKey: 0,
      isLoaded: false,
      filterV2: false,
      backEventListener: null,
      filters: {
        emit: ''
      },
      // filterV2: Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      selectionTimeout: 4000,
      widgetsMetaConfig: [],
      headerOptions: {
        // walmart_recommendation_oos: {
        //   props: {
        //     enableSearch: true
        //   }
        // },
        // walmart_recommendation_price_change: {
        //   props: {
        //     enableSearch: true
        //   }
        // }
      },
      filterSettings: {
        emitEvent: 'recommendationFilterApply',
        uiKey: 0,
        show: true,
        enableSave: false,
        listenerEvent: 'recommendation-filter'
      }
    };
  },
  computed: {
    bookmarkPath() {
      return `${this.$store.getters?.getRootPath}recommendations/${this.recommendationDetails.type}?pageId=${this.recommendationDetails.pageId}`;
    },
    pageName() {
      return 'walmart_recommendation_'.concat(
        this.$route?.params?.pageNameReference
      );
    }
  },
  watch: {
    $route(to, from) {
      if (to?.path?.includes('walmart/retail/recommendations')) {
        this.loadPageData();
      }
    }
  },
  created() {
    Vue.component('contentDiff', contentDiff);
    Vue.component('validationDiff', validationDiff);
    Vue.component('variantChangeCell', variantChangeCell);
    this.loadPageData();
  },
  methods: {
    handleBackEvent() {
      this.$router.push({
        path: `${this.$store.getters.getRootPath}recommendations`
      });
    },
    loadPageData() {
      const widgetReqParams = {
        ':widget': this.pageName,
        ':entityType': '#ALLOVER_AGGREGATE',
        ':orderByList': []
      };
      Vue.set(this.headerOptions, this.pageName, {
        props: {
          headerContainerGridStyles: {
            'grid-template-columns': '1fr auto auto'
          },
          searchParams: {
            show: true
          },
          enableDownload: true
        }
      });
      this.updateWidgetRequestParams(widgetReqParams, this.pageName);
      Vue.component(this.pageName, dashboardServiceTable);
      this.recommendationDetails = this.recommendationList.find(
        (e) => e.name === this.pageName
      );
      this.pageInfo = {
        name: this.recommendationDetails?.name,
        title: this.recommendationDetails?.label,
        id: this.pageId
      };
      const globalParams = cloneDeep(this.getGlobalRequestParams());
      globalParams[':page'] = this.pageInfo.name;
      // to be discussed @aman no need to send the date params in this rec itself.
      globalParams[':from'] = moment()
        .subtract('1', 'months')
        .format('YYYY-MM-DD');
      globalParams[':to'] = moment().format('YYYY-MM-DD');
      this.updateGlobalRequestParams(globalParams);
      this.backEventListener = this.pageName.concat('-back');
      this.recommendationDetails = this.recommendationList.find(
        (e) => e.name === this.pageName
      );
      this.filterEventAndLocalStorageKey = this.pageName.concat('-key');
      const filterSettings = {
        emitEvent: this.pageName.concat('-emit'),
        listenerEvent: this.pageName.concat('-listener')
      };
      this.filterSettings = { ...this.filterSettings, ...filterSettings };
    }
  }
};
</script>

<style lang="css">
.recommendation-sticky-filter-panel {
  position: sticky;
  z-index: 9;
  top: 0;
}
.recommendation-dashboard .dashboard-service-table div[col-id='fulfillment'] {
  padding: 0;
  border: left;
  border-color: #e9eaeb;
  border-width: 0.8rem;
}
.recommendation-dashboard
  .dashboard-service-table
  div[col-id='fulfillment']
  .header-text {
  padding-left: 1.6rem;
}
.recommendation-dashboard .dashboard-service-table .iconText {
  padding-left: 2.4rem;
  border: left;
  border-color: #e9eaeb;
  border-width: 0.8rem;
}
.recommendation-dashboard .dashboard-service-table-wrapper {
  margin: 0;
}
.recommendation-dashboard .filter-wrapper {
  margin-bottom: 0;
}
.recommendation-dashboard .search__input {
  width: 240px;
}
</style>
