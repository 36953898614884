import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import { cloneDeep, isEqual } from 'lodash';
import {
  slaveTableDataTransformer,
  masterLevelDataTransformer,
  addNewLevelData,
  commonStateStructure,
  transformBudgetPlanInputIntoPacing,
  converMonthsShortToMonthsDates,
  updateExpandLevelInfo,
  addEntireBusinessToPacingPayload,
  restoreSeasonality,
  entireBusinessMonthlyTotals,
  sortBudgetPlanInputRows
} from '@/pages/budget-planner-v2/create-plan/store/helpers/transformers.js';
import { getYearsToAdd } from '@/pages/budget-planner-v2/create-plan/store/helpers/utils.js';
import { savePayload } from '@/pages/budget-planner-v2/create-plan/store/helpers/strategyServicePayload.js';

const budgetPlannerOmniDefaultState = () => {
  return {
    budgetPlanTitle: 'Untitled Budget Plan',
    latestStrategyFetched: false,
    budgetPlanDescription: '',
    planFetchCount: 0,
    selectedLevels: {},
    budgetPlanInputTableRows: commonStateStructure(),
    budgetPlanLevel1: {},
    sortInputTable: 'ui_label',
    sortInputTableDirection: 'asc',
    sortAutomationsTable: 'ui_label',
    sortAutomationsTableDirection: 'asc',
    dropdownSelection: {},
    selectedLevelsCopy: {},
    masterTableData: [],
    masterTableLevel1: {},
    masterTableLevel2: {},
    slaveTableData: [],
    slaveTableLevel1: {},
    pacingPayload: {},
    seasonality: {
      data: null,
      load: false
    },
    seasonalityEventMap: {},
    seasonalityEventMapFromStrategy: {},
    selectedLelvesFromStrategy: {},
    categorizationType: 'internal',
    breadCrumbsEnabled: [0],
    automationConfig: {
      keywordBidChange: false,
      campaignBudgetChange: false
    },
    weightsRefreshed: false
  };
};

const budgetPlannerOmniState = budgetPlannerOmniDefaultState();

const getters = {
  getBudgetPlanTitle: (state) => state.budgetPlanTitle,
  getBudgetPlanDescription: (state) => state.budgetPlanDescription,
  getPlanFetchCount: (state) => state.planFetchCount,
  getLatestStrategyFetched: (state) => state.latestStrategyFetched,
  // select step
  getSelectedLevels: (state) => state.selectedLevels,
  getSelectedLevelsCopy: (state) => state.selectedLevelsCopy,
  getCategorizationType: (state) => state.categorizationType,
  // input step
  getBudgetPlanInputTableRows: (state) => state.budgetPlanInputTableRows,
  getBudgetPlanLevel1: (state) => state.budgetPlanLevel1,
  getDropdownSelectionMap: (state) => state.dropdownSelection,
  getInputTableSort: (state) => state.sortInputTable,
  getInputTableSortInputTableDirection: (state) =>
    state.sortInputTableDirection,
  // preview step
  getMasterTableData: (state) => state.masterTableData,
  getMasterTableLevel1: (state) => state.masterTableLevel1,
  getMasterTableLevel2: (state) => state.masterTableLevel2,
  getSlaveTableData: (state) => state.slaveTableData,
  getSlaveTableLevel1: (state) => state.slaveTableLevel1,
  // automations step
  getAutomationsTableSort: (state) => state.sortAutomationsTable,
  getAutomationsTableSortDirection: (state) =>
    state.sortAutomationsTableDirection,
  // seasonality step
  getPacingPayload: (state) => state.pacingPayload,
  getSeasonality: (state) => state.seasonality,
  getSeasonalityEntityMap: (state) => state.seasonalityEventMap,
  getSelectedLevelsFromStrategy: (state) => state.selectedLelvesFromStrategy,
  getBreadCrumbsEnabled: (state) => state.breadCrumbsEnabled,
  // automations config
  getAutomationsConfig: (state) => state.automationConfig,
  // weights
  getWeightsRefreshedStatus: (state) => state.weightsRefreshed
};
const mutations = {
  WEIGHTS_REFRESHED: (state, data) => {
    state.weightsRefreshed = data;
  },
  AUTOMATIONS_UPDATE: (state, data) => {
    const { actionType, value } = data;
    state.automationConfig[actionType] = value;
  },
  SORT_INPUT_TABLE: (state, data) => {
    const { sort, direction } = data;
    state.sortInputTable = sort;
    state.sortInputTableDirection = direction;
  },
  SORT_AUTOMATIONS_TABLE: (state, data) => {
    const { sort, direction } = data;
    state.sortAutomationsTable = sort;
    state.sortAutomationsTableDirection = direction;
  },
  RESTORE_SEASONALITY_EVENT_MAP: (state) => {
    state.seasonalityEventMap = cloneDeep(
      state.seasonalityEventMapFromStrategy
    );
  },
  UPDATE_BREADCRUMBS_ENABLED: (state, data) => {
    state.breadCrumbsEnabled = data;
  },
  SAVE_SEASONALITY_EVENT_MAP: (state, data) => {
    state.seasonalityEventMap = data;
  },
  MUTATE_SEASONALITY_RESPONSE: (state, data) => {
    state.seasonality = data;
  },
  MUTATE_BUDGET_PLAN_FETCH_COUNT: (state) => {
    state.planFetchCount++;
  },
  MUTATE_LATESTSTRATEGY_FETCHED: (state, data) => {
    state.latestStrategyFetched = data;
  },
  MUTATE_BUDGET_PLAN_TITLE: (state, data) => {
    state.budgetPlanTitle = data;
  },
  MUTATE_BUDGET_PLAN_DESCRIPTION: (state, data) => {
    state.budgetPlanDescription = data;
  },
  MUTATE_RESTORE_STRATEGY: (state, data) => {
    const {
      selectedLevels,
      categorizationType,
      strategyName,
      description,
      fetched,
      seasonalityEventMap
    } = data;
    state.selectedLevels = cloneDeep(selectedLevels);
    state.selectedLevelsCopy = cloneDeep(selectedLevels);
    state.selectedLelvesFromStrategy = cloneDeep(selectedLevels);
    state.seasonalityEventMapFromStrategy = cloneDeep(seasonalityEventMap);
    state.categorizationType = categorizationType;
    state.budgetPlanDescription = description;
    state.budgetPlanTitle = strategyName;
    state.latestStrategyFetched = fetched;
    state.seasonalityEventMap = cloneDeep(seasonalityEventMap);
  },
  MUTATE_RESET_BUDGET_INPUT: (state) => {
    state.budgetPlanInputTableRows = commonStateStructure();
    state.budgetPlanLevel1 = {};
    state.planFetchCount = 0;
    state.selectedLevelsCopy = cloneDeep(state.selectedLevels);
  },
  MUTATE_SELECTED_LEVELS: (state, selectedLevels) => {
    state.selectedLevels = selectedLevels;
  },
  MUTATE_CATEGORIZATION_TYPE: (state, categorizationType) => {
    state.categorizationType = categorizationType;
  },
  MUTATE_BUDGET_PLAN_INPUT_TABLE_ROWS: (state, data) => {
    state.budgetPlanInputTableRows = data;
  },
  MUTATE_DROPDOWNSELECTION: (state, row) => {
    const { name, selectedLevelDropdown } = row;
    state.dropdownSelection[name] = selectedLevelDropdown;
  },
  MUTATE_SLAVE_TABLE: (state, data) => {
    state.slaveTableData = data;
  },
  MUTATE_MASTER_TABLE_ENTIREBUSINESS: (state, data) => {
    state.masterTableData = data;
  },
  MUTATE_PACING_PAYLOAD: (state, data) => {
    state.pacingPayload = data;
  },
  MUTATE_BUDGET_PLAN_LEVEL_1: (state, data) => {
    state.budgetPlanLevel1 = data;
  },
  MUTATE_EXPAND_TABLE: (state, updateTableData) => {
    const { stateName, data } = updateTableData;
    const { level, id, index, rows, load, error } = data;
    state[stateName][data.id] = {
      level,
      id,
      index,
      rows,
      load,
      error
    };
    state[stateName] = cloneDeep(state[stateName]);
  },
  RESET_COMPLETE_BUDGET_PLAN_STATE: (state) => {
    Object.assign(state, budgetPlannerOmniDefaultState());
  }
};
const actions = {
  refreshWeights: async (context, count) => {
    const TIMEOUT_DURATION = 120000;
    const MAX_RETRIES = 5;

    try {
      if (count > MAX_RETRIES) {
        context.commit('WEIGHTS_REFRESHED', true);
        return;
      }
      const response = await HttpService.post(
        'BUDGET_OPTIMIZER_SERVICE_SET_WEIGHTS'
      );
      const { statusCode, success } = response?.data;

      if (statusCode === 200 && success) {
        context.commit('WEIGHTS_REFRESHED', true);
      } else {
        await new Promise((resolve) => setTimeout(resolve, TIMEOUT_DURATION));
        context.dispatch('refreshWeights', count + 1);
      }
    } catch (err) {
      context.commit('WEIGHTS_REFRESHED', true);
    }
  },
  updateLatestAutomationConfig: async (context, actionTypePayload) => {
    const { snackBar, changeToggle } = actionTypePayload;
    const automationUpdate = {
      data: null,
      error: false
    };
    try {
      const payload = {
        userName: actionTypePayload.userName,
        actionType: actionTypePayload.actionType,
        flag: actionTypePayload.value
      };
      const response = await HttpService.put('AUTOMATIONS_UPDATE', payload);
      if (!response?.data?.success) {
        automationUpdate.error = true;
        throw new TypeError(response?.data?.message);
      }
      changeToggle(actionTypePayload.actionType);
      automationUpdate.data = actionTypePayload.value;
    } catch (error) {
      automationUpdate.error = true;
      snackBar('Unable to update automation config status');
      console.error(error);
    } finally {
      const config = {
        actionType: actionTypePayload.actionType,
        value: automationUpdate.data,
        error: automationUpdate.error
      };
      if (!config.error) {
        snackBar(
          `Automations turned ${automationUpdate.data ? 'ON' : 'OFF'} for ${
            actionTypePayload.actionType === 'campaignBudgetChange'
              ? 'Budgets'
              : 'Bids'
          } and will be in effect from tomorrow onwards`
        );
        context.commit('AUTOMATIONS_UPDATE', config);
      }
    }
  },
  fetchLatestAutomationConfig: async (context, payload) => {
    const { snackBar, actionType } = payload;
    const fetchAutomations = {
      data: null,
      error: false
    };
    try {
      const config = {
        keyValueParams: {
          actionType: actionType
        }
      };
      const response = await HttpService.get('AUTOMATIONS_FETCH', config);
      fetchAutomations.data = response?.data;
    } catch (error) {
      fetchAutomations.error = true;
      console.error(error);
      snackBar('Unable to fetch automation config');
    } finally {
      if (!fetchAutomations.error) {
        const data = {
          actionType: actionType,
          value: fetchAutomations.data
        };
        context.commit('AUTOMATIONS_UPDATE', data);
      }
    }
  },
  fetchLatestStrategy: async (context, nextYear) => {
    try {
      let config = {
        append: `?year=${nextYear}`
      };
      const response = nextYear
        ? await HttpService.get('ORCHESTRATOR_NEXT_YEAR_PLAN', config)
        : await HttpService.get('GET_BUDGET_PLANNER_OMNI_STRATEGIES');

      const payload = response?.data?.[0];
      if (!payload) {
        context.commit('MUTATE_LATESTSTRATEGY_FETCHED', true);
        return;
      }
      const { strategyName, description } = payload;
      const { selectedLevels, categorizationType, entities } =
        payload.actions[0].executionTemplate;
      const seasonalityEventMap = {};
      restoreSeasonality(
        { name: 'EB', nextLevel: [entities] },
        seasonalityEventMap
      );
      context.commit('MUTATE_RESTORE_STRATEGY', {
        selectedLevels,
        categorizationType,
        strategyName,
        description,
        fetched: true,
        seasonalityEventMap
      });
    } catch {
      context.commit('MUTATE_LATESTSTRATEGY_FETCHED', true);
    }
  },
  async getSeasonalityResponse(context, { where, addYear }) {
    const seasonality = {
      load: true,
      data: null,
      error: false,
      errorMessages: []
    };
    context.commit('MUTATE_SEASONALITY_RESPONSE', seasonality);
    const entity = context.getters.getPacingPayload;
    const selectedLevels = context.getters.getSelectedLevels;
    let levelConfigurations = [];
    let levelNumber = 0;
    for (const key in selectedLevels) {
      if (!selectedLevels[key]) continue;
      levelNumber++;
      const levelObj = {
        levelNumber: levelNumber.toString(),
        categorization: context.getters.getCategorizationType.toUpperCase(),
        levelName: selectedLevels[key].dimensionLabel,
        dimensionName: selectedLevels[key].dimensionName,
        dimensionId: selectedLevels[key]?.dimensionId || null
      };
      levelConfigurations.push(levelObj);
    }

    const dimensionNameValueList = where?.length
      ? where
      : [
          {
            dimensionName: 'ENTIRE_BUSINESS',
            dimensionValue: 'ENTIRE_BUSINESS'
          }
        ];

    const finalPayload = {
      entity,
      levelConfigurations,
      where: {
        dimensionNameValueList,
        date: {
          from: moment()
            .startOf('year')
            .add(getYearsToAdd(addYear), 'year')
            .format('YYYY-MM-DD'),
          to: moment()
            .endOf('year')
            .add(getYearsToAdd(addYear), 'year')
            .format('YYYY-MM-DD')
        }
      }
    };
    try {
      const response = await HttpService.post(
        'BUDGET_OPTIMIZER_SERVICE_PREVIEW_SEASONALITY',
        finalPayload
      );
      seasonality.data = response?.data?.data?.budgetPlan;
      if (!seasonality.data) {
        seasonality.error = true;
        seasonality.errorMessages = response?.data?.data?.validate?.filter(
          (val) => !val.statusPassed
        );
      }
    } catch (err) {
      seasonality.error = true;
    } finally {
      seasonality.load = false;
      context.commit('MUTATE_SEASONALITY_RESPONSE', seasonality);
    }
  },
  formPacingPayload(
    context,
    params = { isNextYearPlan: false, isAutomationsEnabled: false }
  ) {
    const { isNextYearPlan, isAutomationsEnabled } = params;
    const { rows } = context.getters.getBudgetPlanInputTableRows;
    const monthsShort = moment.monthsShort();
    const monthDatesMap = converMonthsShortToMonthsDates(
      monthsShort,
      isNextYearPlan
    );
    const newPayload = cloneDeep(rows);
    const seasonalityEventMap = context.getters.getSeasonalityEntityMap;
    const pacingPayload = transformBudgetPlanInputIntoPacing(
      newPayload,
      monthDatesMap,
      seasonalityEventMap,
      null,
      isAutomationsEnabled
    );
    const pacingPayloadWithEntireBusiness = addEntireBusinessToPacingPayload(
      pacingPayload,
      seasonalityEventMap
    );
    context.commit('MUTATE_PACING_PAYLOAD', pacingPayloadWithEntireBusiness);
  },
  createSlaveTable(context, data) {
    const rows = slaveTableDataTransformer(data);
    context.commit('MUTATE_SLAVE_TABLE', rows);
  },
  createSlaveTableLevel1(context, data) {
    updateExpandLevelInfo(context, data, 'slaveTableLevel1');
  },
  createMasterTableEntireBusinsess(context) {
    const months = moment.monthsShort();
    const { rows } = context.getters.getBudgetPlanInputTableRows;
    const masterTableData = masterLevelDataTransformer(rows, months);
    const { monthValues, total } = entireBusinessMonthlyTotals(
      months,
      masterTableData
    );

    const originalRow = {
      ...monthValues,
      name: 'Entire Business',
      levelName: '',
      total
    };

    context.commit('MUTATE_MASTER_TABLE_ENTIREBUSINESS', [
      {
        nextLevel: masterTableData,
        'ag-grid-has-expand': masterTableData.length,
        name: 'Entire Business',
        ui_label: 'Entire Business',
        levelName: '',
        originalRow,
        total,
        ...monthValues
      }
    ]);
    context.dispatch('createSlaveTable', originalRow);
  },
  createMasterTableLevel1(context, data) {
    updateExpandLevelInfo(context, data, 'masterTableLevel1');
  },
  createMasterTableLevel2(context, data) {
    updateExpandLevelInfo(context, data, 'masterTableLevel2');
  },
  updateBudgetPlanInputTableRows(context, data) {
    const budgetPlanInputTableRows =
      context.getters.getBudgetPlanInputTableRows;
    budgetPlanInputTableRows.rows = data;
    context.commit(
      'MUTATE_BUDGET_PLAN_INPUT_TABLE_ROWS',
      budgetPlanInputTableRows
    );
  },
  updateDropdownSelection(context, row) {
    const { name, selectedLevelDropdown } = row;
    context.commit('MUTATE_DROPDOWNSELECTION', {
      name,
      selectedLevelDropdown
    });
  },
  updateSelectedLevels(context, selectedLevels) {
    context.commit('MUTATE_SELECTED_LEVELS', selectedLevels);
  },
  fetchBudgetPlanLevelData(context, data) {
    const { level, id, index, row } = data;
    let rows = addNewLevelData(row?.nextLevel || [], row.name);
    rows = sortBudgetPlanInputRows(
      rows,
      context.getters.getInputTableSort,
      context.getters.getInputTableSortInputTableDirection
    );
    context.dispatch('updateDropdownSelection', row);
    context.commit('MUTATE_EXPAND_TABLE', {
      stateName: 'budgetPlanLevel1',
      data: {
        rows,
        id,
        load: false,
        error: false,
        index,
        level
      }
    });
  },
  fetchLatestBudgetPlan: async (
    context,
    params = {
      isNextYearPlan: false,
      isAutomationsEnabled: false
    }
  ) => {
    const { isNextYearPlan, isAutomationsEnabled } = params;
    const selectedLevels = context.getters.getSelectedLevels;
    const selectedLevelsCopy = context.getters.getSelectedLevelsCopy;
    if (
      isEqual(selectedLevels, selectedLevelsCopy) &&
      context.getters.getPlanFetchCount > 0
    ) {
      return;
    } else {
      context.commit('MUTATE_RESET_BUDGET_INPUT');
    }
    const tableRows = context.getters.getBudgetPlanInputTableRows;
    tableRows.load = true;
    context.commit('MUTATE_BUDGET_PLAN_INPUT_TABLE_ROWS', tableRows);
    let position = 0;
    const levels = [];
    const levelDropdown = [];
    // move to seperate function
    for (const key in selectedLevels) {
      if (!selectedLevels[key]) continue;
      const levelObj = {
        levelName: selectedLevels[key].dimensionLabel,
        dimensionName: selectedLevels[key].dimensionName,
        dimensionId: selectedLevels[key].dimensionId,
        position
      };
      levels.push(levelObj);
      levelDropdown.push({
        title: selectedLevels[key].dimensionLabel,
        key: selectedLevels[key].dimensionName,
        dimensionId: selectedLevels[key].dimensionId,
        position
      });
      position++;
    }
    const requestData = {
      isNextYearPlan,
      levels,
      entityType: 'campaign',
      isAutomationsEnabled: isAutomationsEnabled || false
    };
    try {
      const response = await HttpService.post(
        'ORCHESTRATOR_FETCH_BUDGET_PLAN',
        requestData
      );
      let rows = addNewLevelData(response.data, null, levelDropdown) || [];
      for (const row of rows) {
        context.dispatch('updateDropdownSelection', row);
      }
      tableRows.rows = sortBudgetPlanInputRows(
        rows,
        context.getters.getInputTableSort,
        context.getters.getInputTableSortInputTableDirection
      );
      context.commit('MUTATE_BUDGET_PLAN_FETCH_COUNT');
    } catch {
      // add error handling
      tableRows.error = true;
      context.commit('MUTATE_BUDGET_PLAN_INPUT_TABLE_ROWS', tableRows);
    } finally {
      tableRows.load = false;
      context.commit('MUTATE_BUDGET_PLAN_INPUT_TABLE_ROWS', tableRows);
    }
  },
  saveBudgetPlan: async (
    context,
    params = {
      isNextYearPlan: false,
      isAutomationsEnabledCheck: false
    }
  ) => {
    const {
      selectedLevels,
      budgetPlanInputTableRows,
      budgetPlanDescription,
      budgetPlanTitle,
      categorizationType,
      seasonalityEventMap
    } = context.state;
    const { isNextYearPlan, isAutomationsEnabledCheck } = params;
    const { rows } = budgetPlanInputTableRows;
    const monthsShort = moment.monthsShort();
    const monthDatesMap = converMonthsShortToMonthsDates(
      monthsShort,
      isNextYearPlan
    );
    const newPayload = cloneDeep(rows);
    const pacingPayload = transformBudgetPlanInputIntoPacing(
      newPayload,
      monthDatesMap,
      seasonalityEventMap,
      null,
      isAutomationsEnabledCheck
    );
    const executionTemplate = addEntireBusinessToPacingPayload(
      pacingPayload,
      seasonalityEventMap
    );
    const payload = savePayload({
      strategyName: budgetPlanTitle,
      description: budgetPlanDescription,
      retailer: context.rootGetters.getRetailer,
      selectedLevels,
      entities: executionTemplate,
      categorizationType,
      isNextYearPlan,
      isAutomationsEnabled: isAutomationsEnabledCheck
    });
    await (isNextYearPlan
      ? HttpService.post('ORCHESTRATOR_NEXT_YEAR_PLAN', {
          payload: { budgetPlan: payload },
          year: moment().add(1, 'year').year(),
          version: 'omni'
        })
      : HttpService.post('ORCHESTRATOR_SAVE_BUDGET_PLAN', {
          payload
        }));
  }
};

export default {
  state: budgetPlannerOmniState,
  getters,
  mutations,
  actions,
  namespaced: true
};
