import { startCase } from 'lodash';
import HttpService from '@/utils/services/http-service';

export function copyToClipboard(text = '') {
  return navigator.clipboard.writeText(text);
}

export const retailerAPIMap = {
  amazon: 'FETCH_AMAZON_RETAILER_DETAILS',
  instacart: 'FETCH_INSTACART_RETAILER_DETAILS',
  target: 'FETCH_TARGET_RETAILER_DETAILS',
  walmart: 'FETCH_WALMART_RETAILER_DETAILS',
  amazon_3p: 'FETCH_AMAZON_RETAILER_DETAILS',
  amazon_b2b: 'FETCH_AMAZON_RETAILER_DETAILS'
};

export const retailerWebsiteMap = {
  amazon: {
    sales: 'vendorcentral',
    default: 'Amazon Console',
    adv: 'AMS Console'
  },
  instacart: { default: 'instacart.com' },
  target: { default: 'criteo.com' },
  walmart: { default: 'walmart.com' }
};

export const getRetailerName = ({ retailer, system, accounts }) => {
  const mappedRetailer = retailerWebsiteMap[retailer];
  const name = mappedRetailer
    ? mappedRetailer[system] || mappedRetailer.default
    : retailer;
  return `${name.toUpperCase()} (${accounts.length})`;
};

export function fetchRetailerDetails({
  clientName,
  region,
  system,
  retailerAPI
}) {
  return HttpService.get(retailerAPI, {
    append: `?clientName=${clientName}&region=${region}&system=${system}`
  }).then(({ data }) => ({ ...data, clientName, region, system }));
}

export function getAvailableTabs(programs) {
  const accountTypesMapping = {
    SalesIQ: {
      id: 'sales',
      name: 'Sales',
      idFor3p: 'sales3p'
    },
    MarketingIQ: {
      id: 'adv',
      name: 'Advertising'
    }
  };

  let availableTabs = [];
  programs.forEach((program) => {
    let tab = accountTypesMapping[program];
    if (tab) {
      availableTabs.push(tab);
    }
  });

  return availableTabs;
}

const langMap = {
  totp: 'TOTP'
};

const typeMap = {
  totp: 'link',
  email: 'text',
  actionsEmail: 'text',
  password: 'password',
  actionsPassword: 'password'
};

const disableCopy = {
  totp: true
};

const typeDecider = (key, _) => {
  return typeMap[key];
};

const classMap = {
  email: 'account-manager-column-value-dark'
};

const widthMap = {
  email: 320
};

const getKeyValuePairs = (obj, config) =>
  Object.entries(obj)
    .filter(([_, value]) => {
      return !!value;
    })
    .map(([key, value]) => ({
      key: langMap[key] || startCase(key),
      value,
      section: config.section,
      type: typeDecider(key, value) || config.type,
      class: classMap[key],
      disableCopy: !!disableCopy[key],
      width: `${widthMap[key]}px` || 'auto'
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export function updateRetailers(response, accounts) {
  if (response && response.clientDetails) {
    const { clientDetails } = response;
    clientDetails.forEach((clientDetail) => {
      const { clientName, auth = {} } = clientDetail;

      const login = auth.login || {};
      const api = auth.api || {};

      const commonKeyValuePairs = [
        {
          key: 'Account Name',
          value: clientName,
          section: 'common',
          type: 'text',
          class: '',
          width: '200px'
        }
      ];

      const loginKeyValuePairs = getKeyValuePairs(login, {
        section: 'login',
        type: 'masked'
      });

      const apiKeyValuePairs = getKeyValuePairs(api, {
        section: 'api',
        type: 'masked'
      });

      const keyValuePairs = [
        ...commonKeyValuePairs,
        ...loginKeyValuePairs,
        ...apiKeyValuePairs
      ];

      accounts.push({
        keyValuePairs
      });
    });
  }
}
