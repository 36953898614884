import { render, staticRenderFns } from "./spotlight-card.vue?vue&type=template&id=6275b31b&scoped=true&"
import script from "./spotlight-card.vue?vue&type=script&lang=js&"
export * from "./spotlight-card.vue?vue&type=script&lang=js&"
import style0 from "./spotlight-card.vue?vue&type=style&index=0&id=6275b31b&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6275b31b",
  null
  
)

export default component.exports