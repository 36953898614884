<template>
  <div
    class="sku-image-container u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div class="u-spacing-pb-m">
      <rb-icon
        v-if="
          [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(skuDetails.state)
        "
        icon="warning"
        :class="{
          'u-color-orange-base': SKU_STATE.IMPROVED === skuDetails.state,
          'u-color-red-base': SKU_STATE.NEGATIVE === skuDetails.state
        }"
      />
      <div
        v-if="SKU_STATE.POSITIVE === skuDetails.state"
        class="dot u-bg-color-green-base u-spacing-m-s"
      />
    </div>
    <div
      class="shadow u-display-flex u-flex-direction-column u-flex-align-items-center"
    >
      <img
        class="sku-image"
        :src="skuDetails.currentImageUrl"
        alt="SKU Image"
        @click="onSkuImageClick"
      />
      <rb-asin
        v-if="skuDetails.gtinNumber"
        :asin="skuDetails.gtinNumber"
      />
    </div>
  </div>
</template>

<script>
import { SKU_STATE } from '@/pages/all_retailers/dso/constant';

export default {
  name: 'SkuImage',
  props: {
    skuDetails: {
      type: Object,
      default: {}
    },
    onImageEdit: {
      type: Function,
      required: true
    }
  },
  data() {
    return { SKU_STATE };
  },
  methods: {
    onSkuImageClick() {
      if (
        [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(this.skuDetails.state)
      ) {
        this.onImageEdit(this.skuDetails);
      }
    }
  }
};
</script>

<style scoped lang="css">
.sku-image-container .sku-image {
  border: 1px solid #e9eaeb;
  padding: 2px;
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
}
</style>
