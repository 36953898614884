import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import urlHelper from '@/utils/helpers/url';
import transformer from '@/utils/services/data-transformer';
import utils from '@/utils/helpers';
import { store } from '@/store/store';
const logger = require('@/utils/helpers/logger').default;
const customReportsConfig = require('./widgets.json');
const { filters } = require('@ciq-dev/ciq-ui');

const initCustomDashboardsFetch = async () => {
  return store.dispatch('check_and_fetch_clients_dashboard');
};

const getCustomDashboardsListFromConfig = () => {
  const dashBoardList = [];
  if (filters.config_check('tableau.enableCustomDashboards')) {
    if (
      !filters.config_check('tableau.enableForExternalUser') &&
      !utils.internalUserCheck(window.user)
    ) {
      return [];
    }
    const dashboardUrls = filters.config_check(
      'feature.tableau.dashboard_urls_common'
    )
      ? filters.config_check('feature.tableau.dashboard_urls_common').split(',')
      : [];
    const dashboardTitles = filters.config_check(
      'feature.tableau.dashboard_titles_common'
    )
      ? filters
          .config_check('feature.tableau.dashboard_titles_common')
          .split(',')
      : [];
    const dashboardCalendarKeys = filters.config_check(
      'feature.tableau.dashboard_calander_keys'
    )
      ? filters
          .config_check('feature.tableau.dashboard_calander_keys')
          .split(',')
      : [];
    let dashboardsEnable = filters.config_check(
      'feature.tableau.dashboard.enable_external'
    )
      ? filters
          .config_check('feature.tableau.dashboard.enable_external')
          .split(',')
      : [];
    dashboardsEnable = dashboardsEnable.map((boolString) => {
      if (String(boolString).toLowerCase().trim() === 'true') {
        return true;
      }
      return false;
    });
    for (let index = 0; index < dashboardUrls.length; index++) {
      const bShowDashboard = dashboardsEnable[index];
      if (!utils.internalUserCheck(window.user) && !bShowDashboard) {
        continue;
      }
      const dashboardInfo = {
        url: '',
        name: '',
        index: 0,
        path: ''
      };
      dashboardInfo.url = dashboardUrls[index];
      dashboardInfo.name = dashboardTitles[index];
      dashboardInfo.calendarKey = dashboardCalendarKeys[index] || 'catalog';
      dashboardInfo.path = `${urlHelper.convertNameToUrlSection(
        dashboardInfo.name
      )}`;
      dashboardInfo.index = index;
      dashBoardList.push(dashboardInfo);
    }
  }
  return dashBoardList;
};

const getCustomDashboardsList = async () => {
  const dashBoardList = [];
  const clientDashboardList = await HttpLayer.post({
    APIData: customReportsConfig
  })
    .then((res) => {
      console.log({ res });
      const mergedData = transformer.mergeResultDimension(res.data, true);
      return mergedData;
    })
    .catch((err) => {
      console.error(err);
    });

  clientDashboardList.forEach((clientDash, index) => {
    // const bShowDashboard = dashboardsEnable[index];
    // if (!utils.internalUserCheck(window.user) && !bShowDashboard) {
    // }
    if (!clientDash.relative_url || clientDash.relative_url === '-') {
      return;
    }
    const dashboardInfo = {
      url: '',
      name: '',
      index: 0,
      path: ''
    };
    const dashName = clientDash.template_name
      .replaceAll('.twbx', '')
      .replaceAll('.twb', '');
    dashboardInfo.url = clientDash.relative_url;
    dashboardInfo.name = dashName;
    dashboardInfo.calendarKey = clientDash.calendar_key || 'catalog';
    dashboardInfo.path = `${urlHelper.convertNameToUrlSection(dashName)}`;
    dashboardInfo.index = index;
    dashBoardList.push(dashboardInfo);
  });
  return dashBoardList;
};

const getTrustedUrl = (dashboardUrl) => {
  var url = window.encodeURIComponent(dashboardUrl);
  const user = window.user.email;
  const localTz = moment().tz(moment.tz.guess()).format('z').toLowerCase();
  return new Promise((resolve, reject) => {
    HttpService.get('TABLEAU_GET_TRUST_URL', {
      append: `?viewUrl=${url}&userTz=${localTz}`
    })
      .then((response) => {
        resolve(response.data.viewUrl);
        logger.logEvent({
          eventType: 'custom_reports_trustedToken',
          dashboardUrl,
          timezone: response.data.userTz,
          version: 1
        });
      })
      .catch((err) => {
        logger.logEvent({
          eventType: 'custom_reports_trustedToken_failure',
          dashboardUrl,
          version: 1,
          user
        });
        reject(err);
        console.error(err);
      });
  });
};

const getTrustedForClient = (dashboardUrl) => {
  const { client } = urlHelper.getClientInfoFromUrl();
  const user = window.user.email;
  var url = window.encodeURIComponent(dashboardUrl);
  const localTz = moment().tz(moment.tz.guess()).format('z').toLowerCase();
  return new Promise((resolve, reject) => {
    HttpService.get('TABLEAU_GET_TRUST_URL_USER_CLIENT', {
      append: `?dashboardUrl=${url}&username=${user}&client=${client}`
    })
      .then((response) => {
        resolve(response.data.trustedDashUrl);
        logger.logEvent({
          eventType: 'custom_reports_trustedToken',
          dashboardUrl,
          user,
          tableau_user: response.data.username,
          timezone: localTz,
          version: 2
        });
      })
      .catch((err) => {
        logger.logEvent({
          eventType: 'custom_reports_trustedToken_failure',
          dashboardUrl,
          user,
          version: 2,
          timezone: localTz
        });
        reject(err);
        console.error(err);
      });
  });
};

const changeDateParamsAndRefresh = async (
  tableauInstance,
  date,
  sessionIdentifier
) => {
  if (tableauInstance) {
    await tableauInstance.pauseAutomaticUpdatesAsync();
    var sheet = tableauInstance.getWorkbook();
    await Promise.all([
      sheet.changeParameterValueAsync('Start Date 1', date.from),
      sheet.changeParameterValueAsync('End Date 1', date.to),
      sheet.changeParameterValueAsync('Start Date Pre', date.compare_from),
      sheet.changeParameterValueAsync('End Date Pre', date.compare_to)
    ]);
    logger.logEvent({
      eventType: 'custom_reports_param_change',
      sessionIdentifier: sessionIdentifier,
      params: date
    });
    await tableauInstance.resumeAutomaticUpdatesAsync();
    // the refresh data call is taking a lot of time and resumeAutomaticUpdatesAsync()
    // anyways brings in the latest data so commenting the refreshDataAsync.
    // tableauInstance.refreshDataAsync();
  }
};

const initTableauJS = (callback) => {
  if (window.tableau) {
    if (callback) {
      callback();
    }
  } else {
    var d = document;
    var script = d.createElement('script');
    script.type = 'text/javascript';
    script.onload = function () {
      if (callback) {
        callback();
      }
    };
    script.src =
      'https://public.tableau.com/javascripts/api/tableau-2.6.0.min.js';
    d.getElementsByTagName('head')[0].appendChild(script);
  }
};

export default {
  getCustomDashboardsList,
  getCustomDashboardsListFromConfig,
  getTrustedUrl,
  getTrustedForClient,
  changeDateParamsAndRefresh,
  initTableauJS,
  initCustomDashboardsFetch
};
