<template>
  <section
    v-if="isOpen"
    class="system-transparency-drawer"
    :class="isOpen ? 'open' : 'close'"
  >
    <Loader
      v-if="healthStatus.loading"
      :loading="healthStatus.loading"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      v-if="!healthStatus.loading && healthGroups.length"
      class="header u-spacing-p-m u-spacing-pb-m"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-width-100 u-spacing-mb-m"
      >
        <img
          src="./platform-system-health.svg"
          alt="Platform System Health Icon"
        />
        <span
          class="u-color-grey-base u-font-size-4 u-font-weight-600 u-spacing-ml-s"
          >System Health</span
        >
        <span
          class="u-spacing-ml-auto"
          @click="closeDrawer"
        >
          <rb-icon
            :icon="'cross'"
            class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
          />
        </span>
      </div>
      <div
        class="u-display-flex u-flex-align-items-center u-width-100 u-flex-justify-content-space-between u-font-size-7 u-font-weight-600"
      >
        <div>
          <span class="u-color-grey-x-light">Status</span>
          <div
            class="u-font-size-5 u-spacing-mt-xs u-display-flex u-flex-align-items-center"
            :class="`u-color-${statusMap[overallStatus].color}-base`"
          >
            <rb-icon
              class="rb-icon--medium u-spacing-mr-xs u-line-height-100"
              :icon="statusMap[overallStatus].icon"
            />
            {{ statusMap[overallStatus].text }}
          </div>
        </div>
      </div>
    </div>
    <section class="body u-spacing-p-m">
      <div>
        <div
          v-for="(healthGroup, i) in healthGroups"
          :key="i"
          class="healthGroup u-spacing-ph-m"
          :class="i < healthGroups.length - 1 ? 'u-spacing-mb-m' : ''"
        >
          <health-group
            :health-group="healthGroup"
            :expand-map="expandMap"
            @expand="expandHealthGroup"
          />
        </div>
      </div>

      <div
        v-if="!healthGroups.length && !healthStatus.loading"
        class="u-font-size-4 u-text-align-center u-display-flex u-flex-direction-column"
      >
        <span
          class="u-spacing-ml-auto u-spacing-pb-m"
          @click="closeDrawer"
        >
          <rb-icon
            :icon="'cross'"
            class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
          />
        </span>
        No data available.
      </div>
    </section>
  </section>
</template>
<script>
import Loader from '@/components/basic/loader';
import {
  STATUS_MAP,
  FETCH_HEALTH_STATUS,
  GET_DETAILED_HEALTH_STATUS,
  GET_EXPANDED_HEALTH_GROUP_IDS
} from '@/components/widgets/system-transparency/system-transparency-utils.js';
import HealthGroup from './health-group.vue';
export default {
  components: {
    Loader,
    HealthGroup
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    shouldTriggerDrawerAPI: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusMap: STATUS_MAP,
      expandMap: {},
      sectionPage: {},
      overallStatus: '',
      healthGroups: []
    };
  },
  computed: {
    healthStatus() {
      const healthStatus = this.$store.getters[GET_DETAILED_HEALTH_STATUS];
      this.overallStatus = healthStatus.data.overall_status || 'FAILED';
      this.healthGroups = healthStatus.data.details || [];
      return this.$store.getters[GET_DETAILED_HEALTH_STATUS];
    },
    healthGroupIds() {
      return this.$store.getters[GET_EXPANDED_HEALTH_GROUP_IDS] || [];
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.fetchStatuses();
      } else {
        this.expandMap = {};
      }
    },
    shouldTriggerDrawerAPI(newValue) {
      if (newValue) {
        this.fetchStatuses();
      }
    },
    $route(to, from) {
      if (to.path !== from.path && this.isOpen) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.fetchStatuses();
      }
    },
    healthGroupIds(newValue) {
      newValue.forEach((id) => {
        this.expandMap = {
          ...this.expandMap,
          [id]: true
        };
      });
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    expandHealthGroup(id) {
      this.expandMap = {
        ...this.expandMap,
        [id]: !this.expandMap[id]
      };
    },
    fetchStatuses() {
      this.expandMap = {};
      if (!this.healthStatus.loading) {
        this.$store.dispatch(FETCH_HEALTH_STATUS, {
          isSummarized: false
        });
      }
    }
  }
};
</script>
<style lang="css" scoped>
.system-transparency-drawer {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: white;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
}
.system-transparency-drawer .header {
  width: 100%;
  border-bottom: solid 1px #e9eaeb;
}
.system-transparency-drawer .header .u-spacing-ml-auto {
  margin-left: auto;
}
.system-transparency-drawer .header .u-line-height-100 {
  line-height: 100%;
}
.system-transparency-drawer .body {
  flex-grow: 1;
  overflow: auto;
}
.system-transparency-drawer .healthGroup {
  background: #f8f9fc;
  border-radius: 4px;
}
.system-transparency-drawer.close {
  width: 0%;
}
.system-transparency-drawer.close .body {
  display: none;
}
.system-transparency-drawer.open {
  width: 27%;
}
.system-transparency-drawer.open .body {
  display: block;
}
</style>
