<template>
  <section
    class="u-display-flex u-flex-direction-column u-overflow-x-hidden u-height-100"
  >
    <div
      class="u-display-flex u-bg-color-grey-white u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow u-spacing-mb-0"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="regionalAvailablity-filters"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="localStorageKey"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :enable-save="false"
        :enable-remove-all="false"
        :has-search="false"
        :loader-status="loaderStatus"
        :new-date="false"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Regional Availability'" />
        </div>
        <div
          slot="right-side"
          class="localDatePickerWrapper"
        >
          <local-date-filter
            :custom-date="customDate"
            :mode="'Multi'"
            :show-reset="true"
            @selected-value="dateSelection"
          />
        </div>
      </rb-filter-panel>
    </div>
    <div class="u-width-100 u-overflow-hidden u-flex-1 u-display-flex">
      <div
        class="u-display-flex u-width-100 u-height-100 u-flex-direction-column regionalAvailabilityWrp"
      >
        <div
          v-if="regionalAvailabilityTableLoader"
          class="loader-block u-position-relative u-height-100 u-bg-color-grey-white"
        >
          <Loader
            :loading="regionalAvailabilityTableLoader"
            class="loader--expand"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </div>
        <card-widget
          v-else
          class="u-width-100 u-height-100"
          :get-card-widget-instance="getWidgetInstance"
          :config="config.widgets['widget1']"
          :data="getData[config.widgets['widget1'].meta.key] || {}"
        >
          <div
            slot="left-header"
            class="u-display-flex u-font-size-5"
          >
            <span
              class="u-color-grey-base u-font-size-7 u-display-block u-spacing-mr-s"
            >
              <rb-icon
                class="u-color-grey-lighter rb-icon--small u-display-block"
                icon="info-circle-fill"
              />
            </span>
            <span class="u-color-grey-mid-light u-font-size-7">
              Tabular view of Top ASINs across key Zip Codes mapped around
              Amazon FCs
            </span>
          </div>
          <div
            slot="body"
            class="u-display-flex u-overflow-auto u-height-100"
          >
            <rb-insights-table
              class="u-display-flex u-flex-direction-column u-height-100 regional-availability-table"
              :custom-height="false"
              :config="config.widgets['widget1']"
              :grid-options="gridOptions"
              :table-row="
                (getData[config.widgets['widget1'].meta.key] || {}).rows
              "
              :table-column="columnDefs"
              :row-height="80"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="true"
              :sorting-change-event="'sortColumns'"
              :pagination="true"
              :show-pagination-options="true"
              :pagination-total-key="
                (getData[config.widgets['widget1'].meta.key] || {}).totalRows
              "
              :pagination-per-page-limit="
                config.widgets['widget1'].body.APIConfig.limit
              "
              :download-this-table="true"
              :no-rows-message="'No Data.'"
              :header-height="80"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex"
          >
            <span class="u-color-grey-base u-font-size-7"
              >Last Updated :{{ getMaxDateFormatted }}</span
            >
            <span class="u-color-grey-base u-font-size-7 u-spacing-mh-s"
              >|</span
            >
            <span
              class="u-color-grey-base u-font-size-7 u-display-inline-flex u-flex-justify-content-center"
            >
              <rb-icon
                :icon="'cached'"
                class="u-spacing-mr-xs u-color-grey-x-light u-font-size-7 rb-icon--small"
              />
              Cached Amazon Detail Page</span
            >
          </div>
        </card-widget>
      </div>
    </div>
  </section>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
import cardWidget from '@/components/widgets/cardWidget';
import widgetsUtil from '@/components/widgetMixin';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import Loader from '@/components/basic/loader.vue';
import widgetsConfig from './widgets';
import moment from 'moment-timezone';

export default {
  components: {
    cardWidget,
    rbFilterPanel,
    localDateFilter,
    Loader
  },
  mixins: [widgetsUtil],
  data() {
    return {
      filterKey: 0,
      filterInstance: null,
      filterState: {
        getter: 'regionalAvailability/getSelectedFilters',
        setter: 'regionalAvailability/setSelectedFilters'
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        }
      },
      cardWidgetObj: {},
      customDate: {
        // defaultDate: '06/17/2021'
        defaultDate: new Date()
      },
      localStorageKey: 'regional_availability-filters',
      regionalAvailabilityTableLoader: true
    };
  },
  computed: {
    getMaxDateFormatted() {
      const { max_feed_date } =
        this.$store.getters.getMaxDate.fresh_regional_availability;
      return moment(max_feed_date).format('MMM DD, YYYY');
    },
    getData() {
      return this.$store.getters['regionalAvailability/getData'];
    },
    columnDefs() {
      // SKU details and in stock percentage column header
      const oldColumns = widgetsConfig.columns.columns;

      // sort zipCodesWithCity based on zipcode key ( eg -zipcode1, zipcode2 etc ) and return zipcode columns
      const zipcodeColumns = this.zipCodesWithCity
        .sort((zip1, zip2) => {
          const zipcodeIndexOne = zip1.key.replace('zipcode', '');
          const zipcodeIndexTwo = zip2.key.replace('zipcode', '');
          return parseInt(zipcodeIndexOne) - parseInt(zipcodeIndexTwo);
        })
        .map((zip, index) => {
          return {
            title: zip.zipcode,
            key: zip.key,
            showToolTip: true,
            toolTipText: `Column shows the Current Availability Status for a given ASIN and its average instock % rate specific to ${zip.zipcode} in the selected dates`,
            type: 'string',
            component: 'availability',
            formatReq: false,
            keyOrder: index + 2,
            enableSorting: false
          };
        });

      // concating zipcodeColumns with column header ( oldColumns )
      const columns = [...oldColumns, ...zipcodeColumns];

      // Generate the exact column definitions needed
      const returnArr = widgetsConfig.getColumnDefs(
        this,
        'RegionalAvailabilityTable',
        columns
      );

      return returnArr;
    },
    zipCodesWithCity() {
      return this.$store.state.regionalAvailability.mappedColumnHeaders;
    }
  },
  async created() {
    // Clearing the local storage because filter panel setting the default date in the localstorage
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(`${this.localStorageKey}-order`);
    this.config = widgetsConfig.config(this);
    this.fetchFilters('fresh_regional_availability_dashboard');
    this.customDate.defaultDate =
      this.$store.getters.getMaxDate.fresh_regional_availability
        ?.max_feed_date || new Date();
    this.customDate.fromDate =
      this.$store.getters.getMaxDate.fresh_regional_availability
        ?.min_feed_date || new Date();
    this.customDate.toDate =
      this.$store.getters.getMaxDate.fresh_regional_availability
        ?.max_feed_date || new Date();
    const filterObj = {
      filterValueKey: 'filter',
      values: {
        date_range: {
          from: moment(this.customDate.defaultDate, 'MM/DD/YYYY').format(
            'YYYY-MM-DD'
          ),
          to: moment(this.customDate.defaultDate, 'MM/DD/YYYY').format(
            'YYYY-MM-DD'
          )
        }
      }
    };
    this.$store.dispatch('regionalAvailability/setSelectedFilters', filterObj);
    await this.$store.dispatch(
      this.config.widgets.widget2.meta.action,
      this.config.widgets.widget2
    );
    this.appendZipcodeListToApiPayload(); // dynamic append of zipcode list to table data api payload.
    this.regionalAvailabilityTableLoader = false;
  },
  mounted() {
    eventBus.$on('sortColumns', (column, config) => {
      // Deciding the order of sort
      var order = column.sort.toUpperCase();

      // Column to be sorted
      var dimensionName = column.colDef?.field;

      // Special case, TODO: fix later
      if (dimensionName.trim().toLowerCase().includes('in_stock')) {
        dimensionName = 'IN_STOCK_PERCENTAGE';
      }

      let orderObj = [];

      // Create the orderByList
      if (order !== '') {
        orderObj = [
          {
            dimension: dimensionName,
            direction: order
          }
        ];
      } else {
        orderObj = [
          {
            dimension: 'IN_STOCK_PERCENTAGE',
            direction: 'DESC'
          },
          {
            dimension: 'asin',
            direction: 'DESC'
          }
        ];
      }

      // Temp object to update API config
      const myObj = { ...this.config.widgets.widget1 };
      myObj.body.APIConfig.orderByList = orderObj;

      // Update the config and call the action
      this.config.widgets.widget1 = myObj;
      this.$store.dispatch(
        this.config.widgets.widget1.meta.action,
        this.config.widgets.widget1
      );
    });
  },
  beforeDestroy() {
    eventBus.$off('sortColumns');
  },
  methods: {
    getWidgetInstance(instance) {
      this.cardWidgetObj = instance;
    },
    dateSelection(date) {
      const filterObj = {
        filterValueKey: 'filter',
        values:
          this.$store.getters['regionalAvailability/getSelectedFilters'] || {}
      };

      filterObj.values.date_range = {
        from: moment(date.from).format('YYYY-MM-DD'),
        to: moment(date.to).format('YYYY-MM-DD')
      };

      this.$store.dispatch(
        'regionalAvailability/setSelectedFilters',
        filterObj
      );
      // Resetting the page to 1
      this.config.widgets.widget1.body.APIConfig.page = 1;
      this.$store.dispatch(
        this.config.widgets.widget1.meta.action,
        this.config.widgets.widget1
      );
      // this.$store.dispatch(this.config.widgets.widget1.meta.action, this.cardWidgetObj?.config);
    },
    appendZipcodeListToApiPayload() {
      // Zipcode list is available from response of first api.
      // Using the list we construct api payload to get the table data
      const tempMeasuresList =
        this.config.widgets.widget1.body.APIConfig.measuresList;
      for (let i = 1; i <= this.zipCodesWithCity.length; i++) {
        tempMeasuresList.push(`zipcode${i}`);
        tempMeasuresList.push(`average_zipcode${i}_percentage`);
      }
      this.config.widgets.widget1.body.APIConfig.measuresList =
        tempMeasuresList;
    }
  }
};
</script>

<style lang="css">
.regional-availability-table
  .ag-theme-material
  .ag-body-container
  .ag-row
  .ag-cell {
  padding: 0;
}
.regional-availability-table
  .ag-theme-material
  .ag-body-container
  .ag-row
  .ag-cell
  .cell--content {
  z-index: 100;
  margin-right: auto;
  padding-left: 24px;
}
.regional-availability-table .header-text .text-label {
  font-size: 14px;
  white-space: pre-wrap;
}
.u-histogram-custom-color-blue {
  background: #e5f2fe;
}
.localDatePickerWrapper .local-filter--token {
  height: 36px;
  border-radius: 4px;
  margin-right: 0px;
}
.localDatePickerWrapper .local-filter--token .rb-icon--small:before {
  font-size: 20px;
}
.localDatePickerWrapper .local-filter--token .icon-calendar.rb-icon--small {
  height: 20px;
  width: 20px;
}
.localDatePickerWrapper .local-filter--token .u-line-height-1-1 {
  color: #6a7075;
}
.regionalAvailabilityWrp .card-header {
  flex: initial;
}
.cached-icon-hover:hover {
  color: #007cf6;
}
</style>
