<template>
  <div class="u-width-100 u-height-100">
    <div
      v-if="hideData"
      class="u-display-flex u-flex-justify-content-center u-font-size-2 no-data u-height-100 u-flex-align-items-center"
    >
      ----
    </div>
    <div
      v-else-if="editable"
      v-tippy="{ placement: 'top' }"
      :class="{
        'u-flex-direction-row-reverse u-spacing-pr-s':
          metricData.unit !== 'currency',
        'input-error': showError
      }"
      :title="showError ? errorText : ''"
      class="u-display-flex u-flex-align-items-baseline u-border-all u-border-radius-s u-border-color-grey-xxx-light u-font-size-3 u-width-100 u-height-100 u-color-grey-base grey-bg"
      @click="handleInputClick"
    >
      <span class="u-spacing-pl-s">
        {{ metricUnit }}
      </span>
      <input
        ref="inputRef"
        :value="inputValue"
        class="u-border-none grey-bg u-font-size-3 u-height-100 u-width-100 u-outline-none"
        :class="{ 'u-text-align-right': metricData.unit !== 'currency' }"
        @change="handleInputChange"
      />
      <div
        v-if="showError"
        class="u-color-red-base u-spacing-p-m u-font-size-4 u-display-flex u-flex-align-item-center"
      >
        Invalid
        <rb-icon icon="error-fill rb-icon--medium u-spacing-ph-xs" />
      </div>
    </div>
    <div
      v-else-if="metricData.value"
      class="u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-spacing-ph-s"
    >
      <metric
        size="l"
        class="u-spacing-pl-m"
        :config="computedMetricData.config"
        :data="computedMetricData.data"
      />
    </div>
    <div
      v-else-if="(percentageData.label || '').length"
      class="u-height-100"
    >
      <percentage-background-component v-bind="percentageData" />
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric.vue';
import PercentageBackgroundComponent from '@/components/basic/percentageBackgroundComponent.vue';

export default {
  name: 'PlannerCell',
  components: {
    metric,
    PercentageBackgroundComponent
  },
  props: {
    metricData: {
      type: Object,
      default: () => ({})
    },
    percentageData: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    },
    onInputChange: {
      type: Function,
      default: () => {}
    },
    hideData: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: 'Enter valid input'
    }
  },
  data() {
    return {
      inputValue: ''
    };
  },
  computed: {
    metricUnit() {
      const unitMap = {
        currency: '$',
        percent: '%'
      };
      return unitMap[this.metricData?.unit] || '';
    },
    computedMetricData() {
      return {
        config: {
          tag1Unit: {
            pre: this.metricData.unit === 'currency' ? 'currency' : undefined,
            suff: this.metricData.unit === 'percent' ? '%' : undefined
          },
          tag2Unit: {
            suff: '%'
          }
        },
        data: {
          tag1: this.metricData.value,
          tag2: this.metricData.pvpVal
        }
      };
    }
  },
  mounted() {
    this.inputValue = this.metricData?.value || '';
  },
  methods: {
    handleInputChange(event) {
      this.inputValue = event.target.value;
      this.onInputChange(this.inputValue);
    },
    handleInputClick(event) {
      if (event.target.localName !== 'input') {
        this.$refs.inputRef.focus();
      }
    }
  }
};
</script>

<style lang="css" scoped>
.grey-bg {
  background-color: #f5f7fa;
}
.u-outline-none {
  outline: none;
}
.no-data {
  letter-spacing: -2px;
}
.input-error {
  border-color: #d7263d;
}
</style>
