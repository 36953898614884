import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep } from 'lodash';
export const fetchPageAsins = (pageUrl, profile, store) => {
  return store.dispatch('campaignCreation/fetchPageAsins', {
    pageUrl,
    profile: profile.profile_id
  });
};

export const getAsinDetails = (skus) => {
  let skuValues = '';
  skus.forEach((item) => {
    skuValues += `'${item}',`;
  });
  skuValues = cloneDeep(skuValues.slice(0, -1));

  const APIConfig = {
    cubeName: 'asin_campaign_relationship_catalogue_asin',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [
      'product_title',
      'product_url',
      'image_url',
      'brand',
      'sku_price',
      'weeks_of_coverage',
      'availability_status'
    ],
    groupByDimensionsList: ['asin'],
    orderByList: [
      {
        dimension: 'product_title',
        direction: 'ASC'
      }
    ],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'asin',
          dimensionValue: skuValues,
          operator: 'IN'
        }
      ]
    }
  };
  return HttpLayer.post({
    APIData: APIConfig
  });
};
