import SalesDashboard from '@/pages/reports/report/sales-dashboard/salesDashboard.vue';
import Financial from '@/pages/reports/report/overview/overview.vue';
import Reports from '@/pages/reports';
import customReportWrapper from '@/components/pages/insights/customReports/customReportWrapper.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      path: 'sales_overview',
      name: 'Sales Overview',
      title: 'Sales Overview',
      page: 'insights',
      config: 'salesOverview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      hasIcon: false,
      icon: 'bell',
      iconColorClass: 'u-color-orange-base',
      component: SalesDashboard
    },
    {
      path: 'finance',
      name: 'Financial',
      title: 'Financial',
      page: 'insights',
      config: 'financial',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      hasIcon: false,
      icon: 'bell',
      iconColorClass: 'u-color-orange-base',
      component: Financial
    },
    {
      path: ':id',
      name: 'custom-reports',
      page: 'insights',
      section: 'CUSTOM REPORTS',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: customReportWrapper
    },
    {
      path: '',
      name: 'Reports',
      title: 'Reports',
      component: Reports
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM,
      appendEsDataSetName: true
    }
  }))
};
