<template>
  <div class="digital-shelves-container">
    <loader
      class="fill--screen entity_loader"
      :loading="isEntityLoading"
      :color="'#3684bb'"
    />
    <ingestion-welcome
      v-if="journeyStage === 'welcome'"
      :button-text="welcomeButtonText"
      :description="welcomeDescription"
      @launchTriggered="launchTriggered"
    />
    <collapsible-page
      v-if="journeyStage === 'bulk_upload'"
      :page-title="textConfigs.pageTitle"
      :page-description="textConfigs.pageDescription"
      @closePage="onBack"
    >
      <bulk-change
        v-if="journeyStage === 'bulk_upload'"
        class="u-spacing-ph-l"
        :current-stage="uploadStatus"
        :text-configs="textConfigs"
        :accepted-file-format="acceptedFileFormat"
        :file-details="getEntityFileDetails"
        :error-list="errorList"
        :info-section="infoSection"
        :inner-components="innerComponents"
        @onUpload="onUpload"
        @onNext="onNext"
        @onBack="onBack"
        @removeFile="onFileRemove"
        @downloadTemplate="downloadTemplate"
        @onCancel="onBack"
      />
    </collapsible-page>

    <div
      v-if="journeyStage === 'preview'"
      class="u-font-size-5 u-spacing-pt-xxxl preview_title_bar"
    >
      <div
        class="u-display-flex u-flex-justify-content-space-between u-spacing-pb-xxl"
      >
        <div class="u-flex-direction-column u-flex-align-items-center">
          <div class="u-font-size-3">Review the uploaded file</div>
          <div class="u-font-size-5 u-line-height-2">
            You can rerview the uploaded file and uncheck any unwanted ‘Digital
            Shelf Set’ from here.
          </div>
        </div>
        <div>
          <rb-button
            :text="textConfigs.preview.cancelBtnTxt"
            class="u-spacing-mr-m"
            type="hollow"
            :click-fn="onBack"
          />
          <rb-button
            :text="textConfigs.preview.createBtnTxt"
            type="filled"
            class="u-spacing"
            :click-fn="ingestDS"
          />
        </div>
      </div>
      <Preview
        :preview-data="reuploaded ? previewData : getSavedPreviewStep"
        class="preview-height"
      />
      <!-- <json-viewer :value="previewData" /> -->
    </div>
    <div
      v-if="journeyStage === 'saved-preview'"
      class="u-font-size-4 save-preview-height u-spacing-mh-xxl"
    >
      <div
        class="u-display-flex u-width-100 u-flex-justify-content-flex-end u-spacing-pv-l"
      >
        <rb-button
          text="Edit standard digital shelves"
          class="u-spacing-mr-m"
          type="filled"
          :click-fn="onEdit"
        />
        <div
          v-for="(iconData, index) in headerIcons"
          :key="index"
        >
          <div
            class="u-spacing-ml-l u-cursor-pointer u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
            @click="handleIconClick(iconData)"
          >
            <rb-icon
              :icon="iconData.icon"
              class="u-color-grey-lighter"
              :class="['rb-icon--' + iconData.size || 'medium']"
            />
            <div
              data-cy="actionTitle"
              class="u-font-size-8 u-spacing-mt-xs"
            >
              {{ iconData.title }}
            </div>
          </div>
        </div>
      </div>
      <Preview
        v-if="!isEntityLoading && getSavedPreviewStep"
        class="u-height-100"
        :preview-data="getSavedPreviewStep"
      />
    </div>
  </div>
</template>

<script>
import ingestionWelcome from '@/components/basic/welcomeImageBannerButton.vue';
import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import bulkChange from '@/components/widgets/upload/bulkChange';
import loader from '@/components/basic/loader';
import JsonViewer from 'vue-json-viewer';
import Preview from '@/pages/toolsAndSettings/entityTagging/manageDigitalShelves/standard/preview.vue';

export default {
  components: {
    ingestionWelcome,
    bulkChange,
    loader,
    JsonViewer,
    collapsiblePage,
    Preview
  },
  data() {
    return {
      headerIcons: [{ icon: 'download', title: 'Download' }],
      reuploaded: false,
      journeyStage: 'welcome',
      entityData: {
        file: null,
        sheetName: 'digital shelves',
        entity: 'digital-shelves',
        skipFormatting: false,
        skipPostTrigger: true
      },
      acceptedFileFormat: ['.xlsx', '.xls'],
      uploadStatusData: 'upload',
      errorListData: [],
      innerComponents: [
        {
          name: 'rb-button',
          props: {
            text: 'Remove',
            clickFn: this.onFileRemove,
            class: 'u-bg-color-blue-base u-spacing-ph-l u-color-grey-white'
          },
          events: {
            click: this.onFileRemove
          }
        }
      ],

      validationResponse: {
        errors: [],
        data: [],
        bValidationSuccess: false,
        fileDetails: {
          fileBuffer: null,
          name: null,
          lastModified: null,
          lastModifiedDate: null
        },
        success: true
      },
      uploadSectionTitle: 'Upload your Standard Digital Catalog',
      uploadSectionDesc:
        'Use an existing template or update the available template to populate your Standard Digital Catalog.',
      welcomeButtonText: 'Create Standard Digital Shelves',
      welcomeDescription:
        'Looks like you haven’t created Standard Digital Shelves yet!',
      backButtonText: 'Back',
      textConfigs: {
        sectionTitle: 'Upload your Standard Digital Shelves',
        sectionDesc:
          'Use an existing template or update the available template to populate your Standard Digital Shelves.',
        nextButtonText: 'Review and Create',
        backButtonText: 'Back',
        downloadTemplateText: 'Download Standard Digital Shelves Template',
        entityName: 'Standard Digital Shelves',
        pageTitle: 'Manage Digital Shelves',
        pageDesc: 'Preview desc',
        pageDescription: 'Preview, edit and create Standard Digital Shelves',
        preview: {
          cancelBtnTxt: 'Back',
          createBtnTxt: 'Create Standard Digital Shelves'
        }
      },
      infoSection: {
        containerClass:
          'u-width-30 u-border-radius-s u-border-color-grey-xxx-light',
        header:
          '<b>While uploading Standard Digital Shelves template, do ensure the below conditions are met:</b>',
        tips: [
          'There should be a column named Keywords containing the list of Keywords.',
          'Do not modifiy or rename the column headers.',
          'Ensure there are no undefined mid levels to succesfully create a hierarchy.',
          'Review your hierarchy within the excel sheet <b> ‘Hirerachy Live Preview’ </b> or in the next step <b>‘Review the uploaded file’</b> to ensure successful creation of Standard digital shelves.'
        ],
        footer:
          '<img class="u-max-width-100" src="/images/digital-shelves-eg-info.png" alt="" srcset=""><div>Here is how a Standard digital shelf hierarchy may look like!</div>'
      },
      errorContainerClass:
        'u-width-100 u-display-flex u-flex-align-items-center u-spacing-p-xl u-flex-justify-content-space-between u-border-radius-s errorBorder'
    };
  },
  computed: {
    getSavedPreviewStep() {
      return this.$store.getters.getSavedPreviewStep(this.entityData.entity);
    },
    isEntityLoading() {
      return this.$store.getters.getEntityLoadingState(this.entityData.entity);
    },
    catalogEntityStore() {
      return this.$store.getters.getEntityStore(this.entityData.entity);
    },
    bDSExists() {
      return this.$store.getters.getEntityExists(this.entityData.entity);
    },
    previewData() {
      return (
        this.$store.getters.getEntityPreviewData(this.entityData.entity)
          .formatResponse || {}
      );
    },
    paginationData() {
      return this.$store.getters.getEntityPreviewData(this.entityData.entity)
        ?.data?.entityData?.pagination[0];
    },
    getEntityFileDetails() {
      return this.$store.getters.getEntityFileDetails(this.entityData.entity);
    },
    uploadStatus() {
      if (this.journeyStage === 'select-upload-type') {
        return 'success';
      }
      return this.uploadStatusData;
    },
    fileDetails() {
      return this.validationResponse.fileDetails;
    },
    errorList() {
      return this.$store.getters.getEntityErrorList(this.entityData.entity);
    }
  },
  watch: {
    journeyStage(newVal) {
      console.log('journeyStage changed', newVal);
    },
    bDSExists: {
      handler(val) {
        if (val) {
          this.fetchSavedPreview();
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store
      .dispatch('fetchEntityExistsStatus', this.entityData)
      .then((response) => {
        if (this.bDSExists) {
          this.$store.dispatch('savedPreviewStep', this.entityData.entity);
          this.journeyStage = 'saved-preview';
        }
      });
  },
  methods: {
    onEdit() {
      this.uploadStatusData = 'upload';
      this.journeyStage = 'bulk_upload';
    },
    launchTriggered() {
      this.journeyStage = 'bulk_upload';
    },
    onUpload(droppedFile) {
      this.reuploaded = true;
      this.entityData.file = droppedFile;
      this.$store
        .dispatch('triggerIngestion', this.entityData)
        .then((response) => {
          this.validationResponse = response;
          if (!response) {
            this.$snackbar.open({
              message: 'Error validating file. Please try again later.',
              duration: 5000,
              actionText: ''
            });
          }
          if (response.success) {
            // this.previewData = response.data.formatResponse;
            this.uploadStatusData = 'success';
          } else {
            this.errorListData = response.errors;
            this.uploadStatusData = 'error';
          }
        });
    },
    onFileRemove(event) {
      this.uploadStatusData = 'upload';
    },
    downloadTemplate(event) {
      const a = document.createElement('a');
      a.href = this.$store.getters.getEntityTemplateFile(
        this.entityData.entity
      );
      a.download = a.href.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    onNext() {
      this.journeyStage = 'preview';
    },
    ingestDS() {
      const dsUploadEntity = { ...this.entityData };
      dsUploadEntity.skipPostTrigger = false;
      dsUploadEntity.skipPostTrigger = false;
      dsUploadEntity.skipFormatting = false;
      this.$store
        .dispatch('triggerIngestion', dsUploadEntity)
        .then((response) => {
          if (response.success) {
            // this.previewData = response.data.formatResponse;
            this.uploadStatusData = 'success';
            this.$snackbar.open({
              message: response.data.postApiResponse,
              duration: 10000,
              buttonColor: '#f5d908',
              actionText: '              '
            });
            this.fetchSavedPreview();
          } else {
            this.errorListData = response.errors;
            this.uploadStatusData = 'error';
          }
        });
    },
    onBack() {
      if (
        this.uploadStatus === 'upload' ||
        (this.uploadStatus === 'success' && this.journeyStage === 'bulk_upload')
      ) {
        if (this.bDSExists) {
          this.journeyStage = 'saved-preview';
        } else {
          this.journeyStage = 'welcome';
        }
      } else {
        this.uploadStatusData = 'success';
        this.journeyStage = 'bulk_upload';
      }
    },
    fetchSavedPreview() {
      this.$store.dispatch('savedPreviewStep', this.entityData.entity);
      this.journeyStage = 'saved-preview';
    },
    onSelectionComplete(selectedRows) {
      console.log(selectedRows);
    },
    editOptionSelected(context, selection) {
      if (selection[0].internalFnTrigger) {
        this[selection[0].internalFnTrigger]();
      }
    },
    onTagsDropdownOptionSelect(selectedOption) {},
    selectionChanged() {},
    onSearchTrigger() {}
  }
};
</script>

<style lang="css" scoped>
.preview-height {
  height: calc(100vh - 340px);
}
.save-preview-height {
  height: calc(100vh - 260px);
}
.preview_title_bar {
  padding-right: 120px;
  padding-left: 120px;
}
</style>

<style lang="css">
.digital-shelves-container .upload_file_drop_area {
  max-width: 680px;
}
.digital-shelves-container .iq_tip_container {
  max-width: 450px;
}
.digital-shelves-container .ingestion_bulk_edit_section {
  padding-top: 4.8rem;
}
.digital-shelves-container .ingestion_work_panel {
  max-width: 750px;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.u-border-h-s.ag-cell-value.u-border-h-s {
  border-left: solid 1px !important;
  border-right: solid 1px !important;
  border-color: #007cf6 !important;
  opacity: 0.4;
  z-index: 200;
}

div[col-id='newColumn'][class='ag-header-cell ag-header-cell-sortable'] {
  border-left: solid 1px !important;
  border-right: solid 1px !important;
  border-color: #007cf6 !important;
  z-index: 200;
  background-color: #fff;
}
</style>
