<template>
  <div class="chargebackSpotlights">
    <widgetContainer
      v-if="showWidget"
      :header-options="headerOptionsComputedSpotlights"
      :header-container-grid-styles="{
        'grid-template-columns': '1fr'
      }"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isWidgetLoading"
      :enable-footer="false"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <NoDataState
          v-if="isComingSoon"
          image-src="/images/ChargebacksComingSoon.svg"
          title="Coming soon..."
          description="We're curating a list of helpful spotlights for you."
        />

        <template v-else>
          <NoDataState
            v-if="spotlightsTextOrdered.length === 0"
            image-src="/images/ChargebacksNoData.svg"
            title="Nothing found under Spotlights"
            description="There is nothing under spotlights yet for this selection."
          />
          <div
            v-else
            class="chargeback-spotlights-container u-border-radius-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-display-flex u-flex-direction-column u-font-size-6 u-spacing-mb-s u-spacing-p-m"
          >
            <div
              class="u-display-flex u-spacing-mb-s u-color-grey-mid-light u-font-weight-600"
            >
              <div class="u-flex-1 u-display-flex u-flex-align-items-center">
                <span
                  class="impact-icon-holder u-display-inline-flex u-flex-align-items-center u-flex-justify-content-center u-border-radius-xs u-spacing-p-xxs u-spacing-mr-xs"
                >
                  <rb-icon
                    icon="show-chart"
                    class="rb-icon--small u-color-white-base"
                  ></rb-icon>
                </span>
                <span class="u-spacing-ml-xs"> IMPACT </span>
              </div>
              <div
                class="u-flex-1 u-display-flex u-flex-align-items-center u-spacing-ml-xxxl"
              >
                <rb-icon
                  icon="light-bulb"
                  class="u-color-blue-base u-spacing-mr-xs u-spacing-ml-s"
                ></rb-icon>
                RECOMMENDATION
              </div>
            </div>
            <div
              v-for="(item, i) in spotlightsTextOrdered"
              :key="i"
              class="u-display-flex u-spacing-mv-s u-line-height-1-5 spotlightsText u-spacing-pb-m u-color-grey-light"
            >
              <div class="u-spacing-mr-s u-font-weight-bold">{{ i + 1 }}.</div>
              <spotlightTextRenderer
                class="u-flex-1"
                :text-string="item.impact.textString"
                :values-array="item.impact.stringVariables"
              />
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <img
                  height="24px"
                  src="/images/Connection.svg"
                  alt=""
                  class="u-spacing-mh-l"
                />
              </div>
              <spotlightTextRenderer
                class="u-flex-1"
                :text-string="item.recommendation.textString"
                :values-array="item.recommendation.stringVariables"
              />
            </div>
          </div>
          <div
            v-if="spotlightsTextOrdered.length"
            class="u-spacing-mt-m"
          >
            <span
              class="u-cursor-pointer u-color-blue-base u-font-size-7 u-font-weight-600 u-display-flex u-flex-align-items-center"
              @click="isSpotlightExpanded = !isSpotlightExpanded"
            >
              <rb-icon
                :icon="isSpotlightExpanded ? 'arrow-up' : 'arrow-down'"
                class="rb-icon--small u-spacing-mr-xs"
              ></rb-icon>
              View {{ isSpotlightExpanded ? 'Less' : 'More' }} Insights
            </span>
          </div>
        </template>
      </template>
    </widgetContainer>
  </div>
</template>

<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import widgetTitle from '../atoms/widgetTitle.vue';
import SpotlightTextRenderer from '../atoms/spotlightTextRenderer.vue';
import NoDataState from '../atoms/NoDataState.vue';
import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import Vue from 'vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'ChargebackSpotlights',
  components: {
    widgetContainer,
    SpotlightTextRenderer,
    NoDataState
  },
  mixins: [cwMixin],
  inject: {},
  props: {
    showWidget: {
      type: Boolean,
      default: false
    },
    isComingSoon: {
      type: Boolean,
      default: false
    },
    widgetTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isWidgetLoading: true,
      isSpotlightExpanded: false,
      spotlightsText: []
    };
  },
  computed: {
    headerOptionsComputedSpotlights() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'widgetTitle',
        props: {
          title: this.widgetTitle + ' Spotlights',
          count: this.isComingSoon ? 0 : this.spotlightsText.length,
          infoTooltip:
            'Spotlights is for probable chargebacks only, not affected by Date picker & Vendor code.'
        }
      });
      return headerOptions;
    },
    spotlightsTextOrdered() {
      return this.isSpotlightExpanded
        ? Object.values(this.spotlightsText).sort((a, b) => a.order - b.order)
        : uniqBy(this.spotlightsText, 'order').sort(
            (a, b) => a.order - b.order
          );
    }
  },
  created() {
    Vue.component('widgetTitle', widgetTitle);
    this.fetchData();
  },
  methods: {
    resetWidget() {
      this.spotlightsText = [];
      this.fetchData();
    },
    fetchData() {
      this.isWidgetLoading = true;
      const metadata = this.metadata.metadata;
      const allValues = cloneDeep(metadata?.apiConfig?.po_related ?? {});

      Object.entries(allValues).forEach(([key, el]) => {
        const request = el.api.request;
        request.where.date = this.getDateObject();
        dashUtils.fetchDataAPI(request, el.api).then((args) => {
          this.transformData(key, args.entityData, el.metricsMapping);
          this.isWidgetLoading = false;
        });
      });
    },
    getDateObject() {
      return {
        from: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      };
    },
    getSpotlightConfigTranformed(key, data, metricMapping) {
      const spotlightsTextConfigs =
        this.metadata?.metadata?.spotlightsText ?? {};
      const spotlightEntry = cloneDeep(spotlightsTextConfigs[key]);
      let impactVariables = spotlightEntry?.impact?.stringVariables;
      let recommendationVariables =
        spotlightEntry?.recommendation?.stringVariables;
      if (!impactVariables || !recommendationVariables) return;

      if (spotlightEntry.removeZeroAcceptedUnits) {
        const acceptedUnitsValue = data.find(
          (el) => el.name === 'accepted_units'
        )?.RESULT['accepted_units'];
        if (acceptedUnitsValue === 0) return;
      }

      if (spotlightEntry.acceptZeroAcceptedUnitsOnly) {
        const acceptedUnitsValue = data.find(
          (el) => el.name === 'accepted_units'
        )?.RESULT['accepted_units'];
        if (acceptedUnitsValue !== 0) return;
      }

      impactVariables.forEach((el) => {
        const [metricMappingKey, metricMappingVal] = Object.entries(
          metricMapping
        ).find(([perKey, _]) => el.text.includes(perKey)) ?? [null, null];
        if (metricMappingKey) {
          el.text = el.text.replaceAll(
            metricMappingKey,
            data.find((perRow) => perRow.name === metricMappingVal).RESULT[
              metricMappingVal
            ]
          );
        }
      });

      recommendationVariables.forEach((el) => {
        const [metricMappingKey, metricMappingVal] = Object.entries(
          metricMapping
        ).find(([perKey, _]) => el.text.includes(perKey)) ?? [null, null];
        if (metricMappingKey) {
          el.text = el.text.replaceAll(
            metricMappingKey,
            data.find((perRow) => perRow.name === metricMappingVal).RESULT[
              metricMappingVal
            ]
          );
        }
      });
      return spotlightEntry;
    },
    transformData(key, entityData, metricMapping) {
      entityData.forEach((eachEntity) => {
        const data = eachEntity.data;
        const spotlightData = this.getSpotlightConfigTranformed(
          key,
          data,
          metricMapping
        );
        if (spotlightData) {
          this.spotlightsText.push(spotlightData);
        }
      });
    }
  }
};
</script>
<style lang="css">
.chargebackSpotlights {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.chargebackSpotlights .custom_widget_container {
  padding-bottom: 0.8rem;
}
.chargebackSpotlights .impact-icon-holder {
  background-color: #d7263d;
  opacity: 0.6;
  height: 16px;
  width: 16px;
  transform: scaleX(-1);
}
.chargebackSpotlights .chargeback-spotlights-container {
  max-height: 800px;
  overflow-y: scroll;
}
.chargebackSpotlights .spotlightsText:not(:last-child) {
  border-bottom: 1px solid #e9eaeb;
}
</style>
