import HttpService from '@/utils/services/http-service';
import {
  getOutputTableRows,
  createPayloadToSaveOrUpdateMediaPlan,
  computedFilteredData,
  getConfigFromPlan,
  getAPIConfigForBULevel,
  buildRestOfTheData,
  checkUndefined,
  OPS_AND_SCOGS_METRICS,
  ADV_METRICS,
  chartsCube,
  filteredHistoricalDataAPIConfig,
  organizeOptions,
  getPreviousPeriodDates,
  ADV_METRICS_BACKFILLED,
  getIfElse,
  getFinalDataForAdditionalInputs,
  getPayloadForFetchingAdditionalInputs
} from '../config';
import { downloadJSONFromS3Path } from '@/utils/helpers/downloader.js';
import Vue from 'vue';
import moment from 'moment';

const fetchNTBSalesAndYoY = async (date, state) => {
  try {
    const APIConfig = chartsCube(date?.from, date?.to);
    const { organizeType, filters, showPlansAtBULevel } =
      state.mediaPlannerConfig;
    const isOverall = organizeType === organizeOptions.OVERALL.value;
    APIConfig.where.dimensionNameValueList = getDimensionValueList(
      isOverall && !showPlansAtBULevel,
      filters
    );
    const res = await HttpService.post('EXECUTE_CUSTOM_CUBE_API', APIConfig);
    const ntbSales =
      res?.data?.response?.data[0]?.RESULT?.PercentageSalesNewToBrand14d || 0;
    const yoyGrowthPercentage =
      res?.data?.response?.data[0]?.PVP?.PVP_average_order_value || 0;
    return {
      ntbSales,
      yoyGrowthPercentage
    };
  } catch (err) {
    throw Error(err);
  }
};

const fetchOPS = async (APIConfig) => {
  try {
    const payload = APIConfig;
    payload.metricsList = [OPS_AND_SCOGS_METRICS[0]];
    payload.operations.commonFilterEnabled = true;
    const res = await HttpService.post('DASHBOARD_SERVICE', payload, {
      append: '/entity/metrics/data'
    });
    const ops =
      res?.data?.entityData[0]?.data[0]?.RESULT?.ordered_product_sales || 0;
    return ops;
  } catch (err) {
    throw Error(err);
  }
};

const getDimensionValueList = (isOverall, filters) => {
  let dimensionNameValueList = [];
  if (!isOverall) {
    Object.keys(filters).forEach((key) => {
      filters[key].forEach((j) => {
        dimensionNameValueList.push({
          dimensionName: key,
          dimensionValue: j
        });
      });
    });
  }

  return dimensionNameValueList;
};

const createPayloadForADVMetrics = (
  isOverall,
  entityType,
  filters,
  months,
  isIC
) => {
  const APIConfig = { ...filteredHistoricalDataAPIConfig };
  const { start, end } = getPreviousPeriodDates(months);
  APIConfig.where.date.from = start;
  APIConfig.where.date.to = end;
  APIConfig.metricsList = [...ADV_METRICS_BACKFILLED];
  APIConfig.operations.commonFilterEnabled = isIC;
  const dimensionNameValueList = getDimensionValueList(isOverall, filters);
  if (!isOverall) {
    APIConfig.where.dimensionNameValueList = dimensionNameValueList;
    APIConfig.metricsList = [...ADV_METRICS_BACKFILLED, entityType];
  }

  return APIConfig;
};

const createPayloadForMetricsAtBULevel = (
  entityType,
  filters,
  months,
  isIC
) => {
  const { start, end } = getPreviousPeriodDates(months);
  const dimensionNameValueList = getDimensionValueList(false, filters);
  if (isIC) {
    const APIConfig = { ...filteredHistoricalDataAPIConfig };
    APIConfig.where.date.from = start;
    APIConfig.where.date.to = end;
    APIConfig.entityType = entityType;
    APIConfig.where.dimensionNameValueList = dimensionNameValueList;

    return APIConfig;
  }
  return getAPIConfigForBULevel(
    end,
    start,
    entityType,
    dimensionNameValueList,
    true
  );
};

const fetchBackfilledData = async (mediaPlannerConfig, state) => {
  const backfilledData = {
    overall: {},
    businessUnitLevel: []
  };
  try {
    const {
      organizeType,
      filters,
      businessUnit,
      filteredBusinessUnit,
      months,
      showPlansAtBULevel
    } = mediaPlannerConfig;
    const isOverall = organizeType === organizeOptions.OVERALL.value;
    const bU = isOverall ? businessUnit : filteredBusinessUnit;
    const entityType = bU?.dimensionColumn?.toLowerCase();
    const isIC = bU?.dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
    if (showPlansAtBULevel || !isOverall) {
      const APIConfigForBULevel = createPayloadForMetricsAtBULevel(
        entityType,
        filters,
        months,
        isIC
      );
      if (isIC) {
        APIConfigForBULevel.metricsList = [
          ...OPS_AND_SCOGS_METRICS,
          entityType
        ];
        APIConfigForBULevel.operations = {
          ...APIConfigForBULevel.operations,
          showByEntities: [entityType]
        };
      }
      const res = await HttpService.post(
        'DASHBOARD_SERVICE',
        APIConfigForBULevel,
        {
          append: '/entity/metrics/data'
        }
      );
      let finalRes = [...checkUndefined(res?.data?.entityData)];
      if (isIC) {
        APIConfigForBULevel.metricsList = [
          ...ADV_METRICS_BACKFILLED,
          entityType
        ];
        delete APIConfigForBULevel.operations.showByEntities;
        APIConfigForBULevel.page = 'BusinessInsightsBackfilled';
        APIConfigForBULevel.widget = 'chartingWbBackfilled';
        APIConfigForBULevel.where.date.page_wise_min_max_key =
          'BusinessInsightsBackfilled';
        const advMetricsResponse = await HttpService.post(
          'DASHBOARD_SERVICE',
          APIConfigForBULevel,
          {
            append: '/entity/metrics/data'
          }
        );
        finalRes = buildRestOfTheData(advMetricsResponse, finalRes);
      }
      backfilledData.businessUnitLevel = computedFilteredData(
        checkUndefined(finalRes),
        state,
        entityType,
        { overall: false }
      );
    }
    const APIConfig = createPayloadForADVMetrics(
      !showPlansAtBULevel && isOverall,
      entityType,
      filters,
      months,
      isIC
    );
    const res = await HttpService.post('DASHBOARD_SERVICE', APIConfig, {
      append: '/entity/metrics/data'
    });
    const ops = await fetchOPS(APIConfig);
    const { ntbSales, yoyGrowthPercentage } = await fetchNTBSalesAndYoY(
      APIConfig?.where?.date,
      state
    );
    backfilledData.overall = {
      ...computedFilteredData(
        checkUndefined(res?.data?.entityData),
        state,
        '',
        { overall: true },
        ntbSales,
        yoyGrowthPercentage
      ),
      ops
    };
    return backfilledData;
  } catch (err) {
    throw Error(err);
  }
};

const logEvent = (isDrafted, ifTrueEventId, elseEventId, mediaPlanId) => {
  Vue.prototype.$logger.logEvent({
    eventType: isDrafted ? ifTrueEventId : elseEventId,
    mediaPlanId,
    success: true
  });
};

export const actions = {
  updateMediaPlannerConfig({ commit }, data) {
    commit('updateMediaPlannerConfig', data);
  },
  updatedFetchedFiltersData({ commit }, data) {
    commit('updatedFetchedFiltersData', data);
  },
  updateFilteredHistoricalData({ commit }, data) {
    commit('updateFilteredHistoricalData', data);
  },
  updateOverallHistoricalData({ commit }, data) {
    commit('updateOverallHistoricalData', data);
  },
  updateInternalCategorizations({ commit }, data) {
    commit('updateInternalCategorizations', data);
  },
  updateRetailerCategorizations({ commit }, data) {
    commit('updateRetailerCategorizations', data);
  },
  updateOutput({ commit }, data) {
    commit('updateOutput', data);
  },
  updateSavingOrUpdatingPlan({ commit }, data) {
    commit('updateSavingOrUpdatingPlan', data);
  },
  updateErrors({ commit }, data) {
    commit('updateErrors', data);
  },
  updateExpectedGrowth({ commit }, data) {
    commit('updateExpectedGrowth', data);
  },
  async fetchAdditionalInputs(
    { commit, state },
    {
      showAdditionalInputsAtBULevel,
      filters,
      dimensionName,
      reset,
      isIC,
      isOverall
    }
  ) {
    commit('updateExpectedGrowth', {
      data: state.expectedGrowth.data,
      loading: true,
      reset
    });
    const APIConfig = getPayloadForFetchingAdditionalInputs(
      showAdditionalInputsAtBULevel,
      filters,
      dimensionName,
      isIC,
      isOverall
    );

    const result = {
      overall: checkUndefined(state.expectedGrowth.data.overall, {}),
      bULevel: checkUndefined(state.expectedGrowth.data.bULevel, {})
    };
    await HttpService.post('DASHBOARD_SERVICE', APIConfig, {
      append: '/entity/metrics/data'
    }).then(async (res) => {
      let finalRes = [...checkUndefined(res?.data?.entityData)];
      if (isIC && showAdditionalInputsAtBULevel) {
        const APIConfigForROI = { ...APIConfig };
        APIConfigForROI.metricsList = [
          ADV_METRICS_BACKFILLED[2],
          ADV_METRICS_BACKFILLED[0]
        ];
        await HttpService.post('DASHBOARD_SERVICE', APIConfigForROI, {
          append: '/entity/metrics/data'
        }).then((roiRes) => {
          finalRes = buildRestOfTheData(roiRes, finalRes);
        });
      }
      const finalResult = getFinalDataForAdditionalInputs(
        finalRes,
        showAdditionalInputsAtBULevel,
        result
      );
      commit('updateExpectedGrowth', {
        data: finalResult,
        loading: false,
        reset
      });
    });
  },
  async fetchFilteredHistoricalData({ commit, state, dispatch }, data) {
    const [entityType] = checkUndefined(data?.APIConfig?.metricsList).slice(-1);
    const isIC = data.isIC;
    const makeICAPICalls = isIC && !data.overall;
    const makeOverallAPICall = !data.overall && !data.forMetrics;
    let stateToUpdate = 'filteredHistoricalData';
    let action = 'updateFilteredHistoricalData';
    let APIConfig = getAPIConfigForBULevel(
      data?.APIConfig?.where?.date?.to,
      data?.APIConfig?.where?.date?.from,
      entityType,
      data?.APIConfig?.where?.dimensionNameValueList
    );

    if (makeICAPICalls) {
      APIConfig = data.APIConfig;
      APIConfig.entityType = entityType;
      APIConfig.metricsList = [...OPS_AND_SCOGS_METRICS, entityType];
      APIConfig.operations = {
        ...APIConfig.operations,
        showByEntities: [entityType]
      };
    }

    if (data.overall) {
      stateToUpdate = 'overallHistoricalData';
      action = 'updateOverallHistoricalData';
      APIConfig = data.APIConfig;
      if (!data.applyFiltersForOverall) {
        data.APIConfig.where.dimensionNameValueList = [];
      }

      commit(action, {
        data: state?.[stateToUpdate]?.data,
        loading: true
      });
    } else {
      commit(action, {
        data: state?.[stateToUpdate]?.data,
        loading: true
      });
    }

    if (data.forLoading) {
      return;
    }

    if (!data.filtersUpdated) {
      setTimeout(() => {
        commit('updateFilteredHistoricalData', {
          data: state.filteredHistoricalData?.data,
          loading: false
        });
      }, 100);
      return;
    }

    await HttpService.post('DASHBOARD_SERVICE', APIConfig, {
      append: '/entity/metrics/data'
    })
      .then(async (res) => {
        let finalRes = [...checkUndefined(res?.data?.entityData)];

        if (makeICAPICalls) {
          APIConfig.metricsList = [...ADV_METRICS, entityType];
          delete APIConfig.operations.showByEntities;
          await HttpService.post('DASHBOARD_SERVICE', APIConfig, {
            append: '/entity/metrics/data'
          }).then((res) => {
            finalRes = buildRestOfTheData(res, finalRes);
          });
        }

        const finalData = computedFilteredData(
          finalRes,
          state,
          entityType,
          data
        );
        commit(action, {
          data: finalData,
          loading: makeOverallAPICall
        });

        if (makeOverallAPICall) {
          await dispatch('fetchFilteredHistoricalData', {
            ...data,
            overall: true,
            APIConfig: {
              ...checkUndefined(data.APIConfig),
              entityType: '#ALLOVER_AGGREGATE',
              metricsList: [...OPS_AND_SCOGS_METRICS, ...ADV_METRICS]
            }
          }).then(() => {
            commit(action, {
              data: finalData,
              loading: false
            });
          });
        }
      })
      .catch(() => {
        commit(action, {
          data: state?.[stateToUpdate]?.data,
          loading: false
        });
        data.snackbar(
          'Something went wrong while fetching the historical data'
        );
      });
  },
  async fetchListOfMediaPlans({ commit, state }, { isDrafted }) {
    const actionId = isDrafted ? 'updateDraftedPlans' : 'updateGeneratedPlans';
    const list = state?.[isDrafted ? 'draftedPlans' : 'generatePlans']?.list;
    commit(actionId, {
      list,
      loading: true,
      error: null
    });
    const error = `Something went wrong, while fetching the list of ${
      isDrafted ? 'drafted' : 'generated'
    } plans.`;
    try {
      const res = await HttpService.get('MEDIA_PLANNER_LIST', {
        params: { is_drafted: isDrafted }
      });
      const { status, data } = res.data;
      if (status === 'Success') {
        commit(actionId, {
          list: data || [],
          loading: false,
          error: null
        });
      } else {
        commit(actionId, {
          list,
          loading: false,
          error
        });
      }
    } catch (err) {
      commit(actionId, {
        list,
        loading: false,
        error
      });
    }
  },
  async getMediaPlan({ commit }, { planId, snackbar }) {
    await HttpService.get('MEDIA_PLANNER_LIST', {
      append: `/${planId}`
    })
      .then((res) => {
        const { status, data } = res.data;
        if (status === 'Success') {
          const planConfig = getConfigFromPlan(data);
          planConfig.mediaPlanId = planId;
          commit('updateMediaPlannerConfig', planConfig);
        } else {
          snackbar('Something went wrong, while fetching the media plan!');
        }
      })
      .catch(() =>
        snackbar('Something went wrong, while fetching the media plan!')
      );
  },
  async deleteMediaPlan({ commit, state }, { planId, isDrafted }) {
    const list = isDrafted
      ? state.draftedPlans?.list
      : state.generatePlans?.list;
    const actions = isDrafted ? 'updateDraftedPlans' : 'updateGeneratedPlans';
    commit(actions, {
      list: list,
      loading: true,
      error: null
    });
    const error = 'Something went wrong, while deleting the media plan.';
    try {
      const res = await HttpService.delete(
        'MEDIA_PLANNER_LIST',
        {},
        {
          append: `/${planId}`
        }
      );
      const { status } = res.data;
      if (status === 'Success') {
        Vue.prototype.$logger.logEvent({
          eventType: isDrafted
            ? 'media_plan_draft_deleted'
            : 'media_plan_generated_deleted',
          success: true
        });
        const updatedList = list.filter(
          ({ MEDIA_PLAN_ID }) => planId !== MEDIA_PLAN_ID
        );
        commit(actions, {
          list: updatedList,
          loading: false,
          error: null
        });
      } else {
        Vue.prototype.$logger.logEvent({
          eventType: isDrafted
            ? 'media_plan_draft_deletion_failed'
            : 'media_plan_generated_deletion_failed',
          success: false
        });
        commit(actions, {
          list: list,
          loading: false,
          error
        });
      }
    } catch (err) {
      Vue.prototype.$logger.logEvent({
        eventType: isDrafted
          ? 'media_plan_draft_deletion_failed'
          : 'media_plan_generated_deletion_failed',
        success: false
      });
      commit(actions, {
        list: list,
        loading: false,
        error
      });
    }
  },
  async saveOrGenerateMediaPlan({ commit, state }, { isDrafted, snackbar }) {
    commit('updateSavingOrUpdatingPlan', {
      loading: isDrafted,
      error: null,
      mediaPlanId: '',
      generatingPlan: !isDrafted
    });

    let mediaPlannerConfig = state.mediaPlannerConfig;

    if (!isDrafted) {
      const backfilledData = await fetchBackfilledData(
        mediaPlannerConfig,
        state
      );

      mediaPlannerConfig = {
        ...state.mediaPlannerConfig,
        backfilledData
      };

      commit('updateMediaPlannerConfig', mediaPlannerConfig);
    }

    const payload = createPayloadToSaveOrUpdateMediaPlan(
      mediaPlannerConfig,
      isDrafted
    );

    const failedMessage = `Something went wrong, while ${getIfElse(
      isDrafted,
      'drafting',
      'generating'
    )} the media plan`;
    await HttpService.post('MEDIA_PLANNER_LIST', payload)
      .then((res) => {
        const { status, data } = res.data;
        const { MEDIA_PLAN_ID } = data;
        if (status === 'Success') {
          logEvent(
            isDrafted,
            'media_plan_drafted',
            'media_plan_generated',
            MEDIA_PLAN_ID
          );
          commit('updateSavingOrUpdatingPlan', {
            loading: false,
            error: null,
            mediaPlanId: MEDIA_PLAN_ID,
            generatingPlan: false
          });
        } else {
          logEvent(
            isDrafted,
            'media_plan_drafting_failed',
            'media_plan_generation_failed',
            MEDIA_PLAN_ID
          );
          commit('updateSavingOrUpdatingPlan', {
            loading: false,
            error: failedMessage,
            mediaPlanId: MEDIA_PLAN_ID,
            generatingPlan: false
          });
          snackbar(failedMessage);
        }
      })
      .catch((err) => {
        logEvent(
          isDrafted,
          'media_plan_drafting_failed',
          'media_plan_generation_failed',
          ''
        );
        commit('updateSavingOrUpdatingPlan', {
          loading: false,
          error: err,
          mediaPlanId: '',
          generatingPlan: false
        });
        snackbar(failedMessage);
      });
  },
  async updateMediaPlan(
    { commit, state },
    { isDrafted, updateOnly, mediaPlanId }
  ) {
    commit('updateSavingOrUpdatingPlan', {
      loading: isDrafted,
      error: null,
      mediaPlanId: '',
      savingName: updateOnly,
      generatingPlan: !isDrafted
    });

    let mediaPlannerConfig = state.mediaPlannerConfig;

    if (!isDrafted && !updateOnly) {
      const backfilledData = await fetchBackfilledData(
        mediaPlannerConfig,
        state
      );

      mediaPlannerConfig = {
        ...state.mediaPlannerConfig,
        backfilledData
      };

      commit('updateMediaPlannerConfig', mediaPlannerConfig);
    }

    const payload = createPayloadToSaveOrUpdateMediaPlan(
      mediaPlannerConfig,
      isDrafted
    );

    const failedMessage = `Something went wrong, while updating the ${getIfElse(
      isDrafted,
      'drafted',
      'generated'
    )} media plan`;
    const res = await HttpService.put('MEDIA_PLANNER_LIST', payload, {
      append: `/${state.mediaPlannerConfig.mediaPlanId || mediaPlanId}`,
      params: { updateOnly }
    });
    try {
      const { status, data } = res.data;
      const mediaPlanId = data[0].MEDIA_PLAN_ID;
      if (status === 'Success') {
        logEvent(
          isDrafted,
          'media_plan_draft_updated',
          'media_plan_updated',
          mediaPlanId
        );
        commit('updateOutput', {
          ...state.output,
          statuses: { ...state.output.statuses, [mediaPlanId]: null }
        });
        commit('updateSavingOrUpdatingPlan', {
          loading: false,
          error: null,
          mediaPlanId,
          savingName: false,
          generatingPlan: false
        });
      } else {
        logEvent(
          isDrafted,
          'media_plan_draft_updating_failed',
          'media_plan_updating_failed',
          mediaPlanId
        );
        commit('updateSavingOrUpdatingPlan', {
          loading: false,
          error: failedMessage,
          mediaPlanId: '',
          savingName: false,
          generatingPlan: false
        });
      }
    } catch (err) {
      logEvent(
        isDrafted,
        'media_plan_draft_updating_failed',
        'media_plan_updating_failed',
        state.mediaPlannerConfig.mediaPlanId
      );
      commit('updateSavingOrUpdatingPlan', {
        loading: false,
        error: failedMessage,
        mediaPlanId: ''
      });
    }
  },
  async retryPlan({ commit, state }, { mediaPlanId }) {
    const error = 'Something went wrong, plan re-generation failed.';
    try {
      const res = await HttpService.get('MEDIA_PLANNER_RETRY', {
        append: `/${mediaPlanId}`
      });
      const { status } = res.data;
      if (status === 'Success') {
        commit('updateErrors', {
          ...state.errors,
          retry: null
        });
      } else {
        commit('updateErrors', {
          ...state.errors,
          retry: error
        });
      }
    } catch (err) {
      commit('updateErrors', {
        ...state.errors,
        retry: error
      });
    }
  },
  async fetchOutput({ commit, state }, { mediaPlanId }) {
    commit('updateOutput', { ...state.output, loading: true, error: null });
    const error = 'Something went wrong, while fetching the media plan output.';
    try {
      const res = await HttpService.get('MEDIA_PLANNER_OUTPUT', {
        append: `/${mediaPlanId}`
      });
      const { status, data } = res.data;
      const { JSON_S3_PATH, EXCEL_S3_PATH } = data[0];
      const excelPaths = JSON.parse(EXCEL_S3_PATH);
      if (JSON_S3_PATH && status === 'Success') {
        const jsonRes = await downloadJSONFromS3Path(JSON_S3_PATH);
        try {
          const distributions = {};
          jsonRes.distribution.forEach(
            (d) => (distributions[d.distribution_level] = d)
          );
          const planConfig = getConfigFromPlan(jsonRes.mediaPlanInputData);
          planConfig.mediaPlanId = mediaPlanId;
          commit('updateMediaPlannerConfig', planConfig);
          commit('updateOutput', {
            ...state.output,
            distributions,
            excelPath: excelPaths,
            loading: false,
            error: null
          });
        } catch (err) {
          commit('updateOutput', {
            ...state.output,
            excelPath: excelPaths,
            loading: false,
            error
          });
        }
      } else {
        commit('updateOutput', {
          ...state.output,
          excelPath: excelPaths,
          loading: false,
          error
        });
      }
    } catch (err) {
      commit('updateOutput', {
        ...state.output,
        loading: false,
        error
      });
    }
  },
  async getMediaPlanStatus({ commit, state }, { planIds }) {
    const error =
      'Something went wrong, while fetching the media plan statuses';
    try {
      const res = await HttpService.post('MEDIA_PLANNER_STATUS', {
        planId: planIds
      });
      const { data, status } = res.data;
      if (status === 'Success') {
        const statuses = {};
        data.forEach(
          ({
            PROCESSING_STEP,
            MEDIA_PLAN_ID,
            PLAN_STATUS,
            MODULUS_TRIGGER_TIME
          }) => {
            const timeDiffInMins = moment
              .duration(moment().diff(moment.utc(MODULUS_TRIGGER_TIME).local()))
              .asMinutes();
            if (
              PLAN_STATUS === 'FAILED' ||
              (PROCESSING_STEP !== 'MEDIA_PLAN_CREATED' && timeDiffInMins > 30)
            ) {
              statuses[MEDIA_PLAN_ID] = 'FAILED';
            } else {
              statuses[MEDIA_PLAN_ID] = PROCESSING_STEP;
            }
          }
        );
        commit('updateOutput', {
          ...state.output,
          statuses: {
            ...state.output.statuses,
            ...statuses
          },
          error: null
        });
      } else {
        commit('updateOutput', {
          ...state.output,
          error
        });
      }
    } catch (err) {
      commit('updateOutput', {
        ...state.output,
        error
      });
    }
  },
  createOutputTable({ commit, state }, data) {
    const dimensionType =
      state?.mediaPlannerConfig?.businessUnit?.dimensionType ||
      state?.mediaPlannerConfig?.filteredBusinessUnit?.dimensionType;
    const isIC = dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
    const { level, id, row } = data.data;
    const actualPlan = row.plan.trimStart();
    const planId = row.planId;
    const parentsAtEachLevel = [row.distributionLevel];
    const {
      rows: allRows,
      distributions,
      distributionLevelToShow
    } = state.output;
    const getRootParent = (parentId) => {
      const parent = allRows[parentId];
      const currentParentId = parent?.row?.['ag-grid-parent-id'];
      if (currentParentId)
        parentsAtEachLevel.unshift(parent.row.distributionLevel);
      return !currentParentId || getRootParent(currentParentId);
    };
    const parentId = row['ag-grid-parent-id'];
    getRootParent(parentId);
    const rows = getOutputTableRows(
      distributions,
      planId,
      parentsAtEachLevel,
      level,
      distributionLevelToShow,
      actualPlan,
      isIC
    );
    commit('updateOutput', {
      ...state.output,
      rows: { ...allRows, [id]: { rows, ...data.data }, level }
    });
  }
};
