import { cloneDeep } from 'lodash';
import moment from 'moment';
import Vue from 'vue';

export const streamPVPDataTransformer = (data, pvp_data) => {
  const pvpData = cloneDeep(pvp_data);
  const chartData = cloneDeep(data);
  const selectedMetrics = chartData.data
    .filter((metric) => !metric[0].includes('hour'))
    .map((metric) => metric[0]);
  const pvpHourlyData = chartData.pvp_data.find((metric) =>
    metric[0]?.includes('hour')
  );
  if (pvpHourlyData?.length) {
    pvpHourlyData[0] = 'hour_pvp';
    chartData.data.push(pvpHourlyData);
  }
  selectedMetrics.forEach((selectedMetric) => {
    const metricData = cloneDeep(
      pvpData.find((metric) => metric[0] === selectedMetric) || []
    );
    metricData[0] = metricData[0] + ' - Yesterday';
    chartData.axes[metricData[0]] = chartData.axes[selectedMetric];
    chartData.data.push(metricData);
  });

  chartData.xs = {};
  selectedMetrics.forEach((metric) => {
    chartData.xs[`${metric} - Yesterday`] = 'hour_pvp';
    chartData.xs[metric] = 'hour';
  });

  chartData.data = chartData.data.map((metricData) => {
    if (metricData[0]?.includes?.('hour_pvp')) {
      metricData = metricData.map((e) => {
        if (!e?.includes?.('hour')) {
          return moment(e).add(1, 'days').valueOf();
        }
        return e;
      });
    }
    return metricData;
  });
  return cloneDeep(chartData);
};

export const commonStreamChartMethods = {
  getStreamChartMetricValue(val) {
    return (
      Vue.options.filters.num_format(
        this.streamData?.metrics?.[val]?.tag1,
        this.streamData?.metricsUnits?.[val]?.tag1Unit?.pre
      ) || 'NA'
    );
  },
  onYesterdayModeChange(val, pageName) {
    const config = this.chartConfig;
    config.chartOptions.YesterdayMode = val;
    this.chartConfig = config;
    this.$store.dispatch(`set${pageName}PVPRealtime`, val);
    this.$store.dispatch(
      `getWorkbench${pageName}ChartData`,
      this.config.widgets.widget1
    );
  },
  onStreamModeChange(enableRealtime, pageName) {
    const config = this.chartConfig;
    config.chartOptions.realtime = enableRealtime;
    config.chartOptions.timeseries = enableRealtime ? 'hour' : 'report_date';
    this.chartConfig = config;
    if (!enableRealtime) {
      const config = this.chartConfig;
      config.chartOptions.YesterdayMode = false;
      this.chartConfig = config;
      this.$store.dispatch(`set${pageName}PVPRealtime`, false);
    }
    this.$store.dispatch(`set${pageName}StreamMode`, enableRealtime);
    this.$store.dispatch(
      `getWorkbench${pageName}ChartData`,
      this.config.widgets.widget1
    );
  },
  transforChartConfigsForRealtimeData(data) {
    const chartData = cloneDeep(data);
    const colorPattern = chartData.colors;
    chartData.data = chartData.data
      .filter((e) => !e[0].includes('_date'))
      .map((cols, index) => {
        const metricName = cols[0].split(' - Yesterday')?.[0];

        if (!chartData.colors[cols[0]]) {
          chartData.colors[cols[0]] =
            chartData.colors[metricName] || colorPattern[index];
        }

        return cols;
      });
    return chartData;
  },
  handleStreamDataTransformer(data, pvpData) {
    if (pvpData && this.YesterdayMode) {
      data = streamPVPDataTransformer(data, pvpData);
    } else {
      data.xs = {};
      const selectedMetrics = data.data
        .filter(
          (metric) => !metric[0].includes('hour') && !metric[0].includes('date')
        )
        .map((metric) => metric[0]);
      selectedMetrics.forEach((metric) => {
        data.xs[metric] = this.streamMode ? 'hour' : 'report_date';
      });
    }
    if (this.streamMode) {
      data = this.transforChartConfigsForRealtimeData(data);
    }
    return data;
  }
};
