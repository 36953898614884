<template>
  <div class="release-notes">
    <div class="release-notes-content">
      <ReleaseNotesSearch
        :releases="releases"
        @updateReleaseNotesArray="handleReleaseNotesArrayUpdate"
      />
      <div
        v-if="releases.length"
        class="release-content-wrapper"
      >
        <div
          v-for="(release, releaseIndex) in releases"
          :key="releaseIndex"
          class="u-display-flex u-flex-direction-column u-color-grey-base u-font-size-6"
        >
          <div
            v-if="showRelease(release)"
            class="release-item-wrapper"
          >
            <div class="date-wrapper">
              <!-- eslint-disable -->
              <span
                class="u-font-weight-600 u-color-blue-base"
                v-html="release.date"
              />
              <!-- eslint-disable -->
              <span
                v-if="!releaseIndex && isNewRelease"
                class="latestRelease"
              />
            </div>
            <div
              v-for="(feature, featureIndex) in release.features"
              :key="featureIndex"
              class="feature-wrapper"
              :class="{ 'u-spacing-pb-m': feature.title || feature.points }"
            >
              <div v-if="configToCheck(feature.configToCheck)">
                <!-- eslint-disable -->
                <div
                  v-if="feature.title"
                  class="u-font-weight-600"
                  :class="{ 'u-spacing-mb-s': feature.points }"
                  v-html="feature.title"
                />
                <!-- eslint-disable -->
                <div
                  v-for="(point, pointIndex) in feature.points"
                  :key="pointIndex"
                  class="points-wrapper"
                >
                  <div
                    class="u-display-flex"
                    v-if="point.subtitle"
                  >
                    <div>
                      <rb-icon
                        class="rb-icon--small u-spacing-mr-xs u-spacing-mt-xxs u-flex-align-self-flex-start u-color-grey-lighter"
                        :icon="'dot'"
                      />
                    </div>
                    <!-- eslint-disable -->
                    <div
                      class="subtitle u-line-height-1-3"
                      v-html="point.subtitle"
                    />
                    <!-- eslint-enable -->
                  </div>
                  <div
                    v-for="(subPoint, subPointIndex) in point.subpoints"
                    :key="subPointIndex"
                    class="subpoints u-spacing-ml-m"
                  >
                    <div
                      v-if="subPoint.subsubtitle"
                      class="u-display-flex"
                    >
                      <div>
                        <rb-icon
                          class="rb-icon--small u-spacing-mr-xs u-spacing-mt-xxs u-flex-align-self-flex-start u-color-grey-lighter"
                          :icon="'dot'"
                        />
                      </div>
                      <!-- eslint-disable -->
                      <div
                        class="subsubtitle u-line-height-1-3"
                        v-html="subPoint.subsubtitle"
                      />
                      <!-- eslint-enable -->
                    </div>
                    <div
                      v-for="(
                        subsubPoint, subsubPointIndex
                      ) in subPoint.subsubpoints"
                      :key="subsubPointIndex"
                      class="u-spacing-ml-m"
                    >
                      <div
                        v-if="subsubPoint.subsubsubtitle"
                        class="u-display-flex"
                      >
                        <div>
                          <rb-icon
                            class="rb-icon--small u-spacing-mr-xs u-spacing-mt-xxs u-flex-align-self-flex-start u-color-grey-lighter"
                            :icon="'dot'"
                          />
                        </div>
                        <!-- eslint-disable -->
                        <div
                          class="subsubtitle u-line-height-1-3"
                          v-html="subsubPoint.subsubsubtitle"
                        />
                        <!-- eslint-disable -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!releases.length"
      class="no-release-placeholder"
    >
      <img
        src="/images/release-notes-placeholder.png"
        alt="release-notes-placeholder"
      />
      <span
        class="u-font-size-6 u-font-weight-600 u-spacing-p-m u-color-grey-lighter"
        >There are no releases that include this search term!</span
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import utils from '@/utils/helpers/';
import { getReleaseNotes } from '@/components/basic/releasenotes/releaseNotesContent';
import ReleaseNotesSearch from '@/components/basic/releasenotes/releaseNotesSearch.vue';

export default {
  components: {
    ReleaseNotesSearch
  },
  props: {
    isNewRelease: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      ciqAdvertisingReleases: [],
      ciqSalesReleases: [],
      releases: []
    };
  },
  computed: {
    activeRetailer() {
      return this.$store.getters.getRetailer;
    },
    retailerProgram() {
      return this.$store.getters.getProgram;
    }
  },
  created() {
    [this.ciqSalesReleases, this.ciqAdvertisingReleases] = getReleaseNotes();
    // Append ciqAdvertisingReleases releases on the condition if the particular client has subscribed to advertisement pages/features.
    const isAdverstisingSubscribed =
      this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    if (isAdverstisingSubscribed) {
      this.releases = [
        ...this.ciqSalesReleases,
        ...this.ciqAdvertisingReleases
      ];
      this.releases = this.releases.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else {
      this.releases = [...this.ciqSalesReleases];
    }
  },
  methods: {
    internalUserCheck(item) {
      if (item?.checkForInternalUser === true) {
        return utils?.internalUserCheck(window.user);
      } else {
        return true;
      }
    },
    configToCheck(config) {
      if (config) {
        return Vue.options.filters.config_check(config);
      }
      // if configToCheck key is not present in pointers object then it should be displayed by default.
      return true;
    },
    showRelease(release) {
      return (
        this.configToCheck(release.configToCheck) &&
        this.internalUserCheck(release) &&
        this.applicableReatilerCheck(release.applicableRetailers)
      );
    },
    applicableReatilerCheck(applicableRetailers) {
      // show by default for all if there is no applicableRetailer specified
      if (!applicableRetailers || !applicableRetailers.length) return true;
      return applicableRetailers.some(
        (retailer) =>
          retailer === `${this.activeRetailer}${this.retailerProgram}`
      );
    },
    handleReleaseNotesArrayUpdate(searchedAndFilteredReleaseNotes) {
      if (Array.isArray(searchedAndFilteredReleaseNotes))
        this.releases = [...searchedAndFilteredReleaseNotes];
    }
  }
};
</script>

<style lang="css" scoped>
.release-notes {
  height: calc(100% - 56px);
}
.release-notes .release-notes-content .release-content-wrapper {
  padding: 0.5rem 1.6rem 1.6rem 1.6rem;
}
.release-notes .release-notes-content .release-item-wrapper {
  padding-bottom: 40px;
}
.release-notes .release-notes-content .release-item-wrapper .date-wrapper {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 16px;
}
.release-notes
  .release-notes-content
  .release-item-wrapper
  .feature-wrapper:last-child {
  padding-bottom: 0;
}
.release-notes
  .release-notes-content
  .release-item-wrapper
  .feature-wrapper
  .points-wrapper
  .subtitle {
  padding-bottom: 8px;
}
.release-notes
  .release-notes-content
  .release-item-wrapper
  .feature-wrapper
  .points-wrapper:last-child
  .subtitle {
  padding-bottom: 0;
}
.release-notes
  .release-notes-content
  .release-item-wrapper
  .feature-wrapper
  .points-wrapper
  .subpoints
  .subsubtitle {
  padding-bottom: 4px;
}
.release-notes .release-notes-content .latestRelease {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #d7263d;
  border-radius: 1px;
  margin-left: 5px;
}
.release-notes .no-release-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  padding-bottom: 110px;
}
.release-notes .no-release-placeholder img {
  height: 160px;
}
</style>
