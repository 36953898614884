<template>
  <carousel
    :scroll-per-page="false"
    :per-page="perPage"
    :navigation-enabled="true"
    :pagination-enabled="false"
  >
    <slide
      v-for="(item, $index, listIndex) in listOfCard"
      :key="item.displayName"
      :class="$index"
    >
      <div
        class="card u-spacing-p-l u-spacing-mh-s carousel_card_wrp u-display-flex u-flex-align-items-center u-flex-justify-content-center"
        :class="{ 'u-spacing-ml-0': listIndex === 0 }"
      >
        <section class="carousel_wrp">
          <div class="header">
            <p class="u-font-size-3 u-color-blue-base u-spacing-mb-s">
              {{ splitTitle(0, $index) }}
            </p>
            <p class="u-font-size-2 u-color-blue-base u-font-weight-600">
              {{ splitTitle(1, $index) }}
            </p>
          </div>
          <div class="desc u-spacing-mt-s">
            <p class="u-font-size-5 u-color-grey-lighter">
              on
              <span class="u-font-weight-600 u-color-grey-base">{{
                item.length
              }}</span>
              <span v-if="item.length > 1">&nbsp;campaigns.</span>
              <span v-else>&nbsp;campaign.</span>
            </p>
            <!-- <p class="u-font-size-5 u-color-grey-lighter">with an Ad Spend of <span class="u-font-weight-600 u-color-grey-base">$345.62K</span>.</p> -->
          </div>
        </section>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'StrategyObjectiveCarousel',
  components: {
    Carousel,
    Slide
  },
  props: {
    listOfCard: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      perPage: 4,
      detailsListMapping: [
        { displayName: 'Objective', keyToRead: 'objective' },
        { displayName: 'Scope', keyToRead: 'scope' },
        { displayName: 'Condition', keyToRead: 'rules' },
        { displayName: 'Action', keyToRead: 'actions' },
        { displayName: 'Time period', keyToRead: 'lookBackWindow' }
      ]
    };
  },
  computed: {
    lengthOfCarousel() {
      return Object.keys(this.listOfCard).length;
    }
  },
  methods: {
    splitTitle(index, desc) {
      return index === 0
        ? desc.substr(0, desc.indexOf(' '))
        : desc.substr(desc.indexOf(' ') + 1);
    }
  }
};
</script>

<style lang="css">
.VueCarousel-wrapper {
  width: 97% !important;
  margin: 0 auto;
}

.VueCarousel-slide {
  min-width: 250px !important;
}

.VueCarousel-inner {
  align-items: center;
}

.carousel_wrp {
  width: 100%;
}

.carousel_card_wrp {
  min-height: 112px;
}

.VueCarousel-navigation .VueCarousel-navigation-button {
  transform: translate(0%, -50%) !important;
}
</style>
