// Reusable Store for fetching and storing table data for certain actions ex: Inventory targeting, Add/remove audiences etc
import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';

function computeWhereClause(data, context) {
  const newWhereClause =
    transformer.getCompleteWhereClause(
      data?.meta?.localFilters || [],
      context.state.whereClause
    )?.dimensionNameValueList || [];
  const existingWhereClause =
    data?.body?.APIConfig?.where?.dimensionNameValueList || [];
  let newWCExists = false;
  for (const existingWC of existingWhereClause) {
    for (const newWC of newWhereClause) {
      if (newWC?.dimensionName === existingWC?.dimensionName) {
        newWCExists = true;
        existingWC.dimensionValue = newWC?.dimensionValue;
      }
    }
  }
  return [newWCExists, newWhereClause];
}

function getWhereClause(data, context) {
  if (!data?.body?.useExistingWhereClause) {
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data?.meta?.localFilters || [],
      context.state.whereClause
    );
  } else {
    const [newWCExists, newWhereClause] = computeWhereClause(data, context);
    if (!newWCExists && newWhereClause?.length) {
      data.body.APIConfig.where.dimensionNameValueList = [
        ...data.body.APIConfig.where.dimensionNameValueList,
        ...newWhereClause
      ];
    }
  }
  return data;
}

// helper functions
const commonStateStructure = () => {
  // created default structure as it is used for most states.
  return { load: false, rows: [], error: false, totalRows: 0 };
};

const createCommitObject = (status, data) => {
  // respononse status helper as it is same for most states
  return {
    load: status === 'loading',
    rows: data?.rows || [],
    error: status === 'error' ? data?.error || false : false,
    totalRows: data?.totalRows ? data.totalRows : 0
  };
};

const commonMutationSetter = (state, stateName, data) => {
  Vue.set(state[stateName], 'rows', data?.rows);
  Vue.set(state[stateName], 'load', data.load);
  Vue.set(state[stateName], 'error', data.error);
  Vue.set(state[stateName], 'totalRows', data.totalRows);
};

// STATE, GETTER, MUTATION, ACTION -->
const state = {
  leftTableData: commonStateStructure(),
  rightTableData: commonStateStructure(),
  leftTableDataALT: commonStateStructure(),
  rightTableDataALT: commonStateStructure(),
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  tableDataExecuteAPIFilters: {}
};
const getters = {
  getLeftTableData: (state) => state.leftTableData,
  getRightTableData: (state) => state.rightTableData,
  getLeftTableDataALT: (state) => state.leftTableDataALT,
  getRightTableDataALT: (state) => state.rightTableDataALT,
  getExecuteApiTableDataFilters: (state) => state.tableDataExecuteAPIFilters
};

const getTableDataFilters = (oFilter, state) => {
  const where = state.whereClause;
  where.dimensionNameValueList = [];
  for (const i in oFilter.values) {
    if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
      where.dimensionNameValueList.push({
        dimensionName: i,
        dimensionValue: oFilter.values[i][0].value,
        operator: oFilter.values[i][0].operator.operator
      });
    } else {
      for (const element of oFilter.values[i]) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: element
        });
      }
    }
  }
  state.whereClause = where;
};

const mutations = {
  ENTITIES_EXECUTE_API_FILTERS: (state, data) => {
    Vue.set(state, 'tableDataExecuteAPIFilters', data.values);
    getTableDataFilters(data, state);
  },
  RESET_WHERE_CLAUSE: (state, data) => {
    state.whereClause = data;
  },
  LEFT_TABLE_DATA: (state, data) => {
    commonMutationSetter(state, 'leftTableData', data);
  },
  RIGHT_TABLE_DATA: (state, data) => {
    commonMutationSetter(state, 'rightTableData', data);
  },
  LEFT_TABLE_DATA_ALT: (state, data) => {
    commonMutationSetter(state, 'leftTableDataALT', data);
  },
  RIGHT_TABLE_DATA_ALT: (state, data) => {
    commonMutationSetter(state, 'rightTableDataALT', data);
  }
};

const actions = {
  fetchTableDataExecuteApiFilters: (context, data) => {
    context.commit('ENTITIES_EXECUTE_API_FILTERS', data);
  },
  fetchTableDataExecuteApiALT(context, { table = 'left', ...data }) {
    data.ALT_ACTION =
      table === 'right' ? 'RIGHT_TABLE_DATA_ALT' : 'LEFT_TABLE_DATA_ALT';
    context.dispatch('fetchTableDataExecuteApi', { table, ...data });
  },
  fetchTableDataExecuteApi: (
    context,
    { table = 'left', customUrl = '', responseTransformer = null, ...data }
  ) => {
    // table = left or right
    if (!data?.body?.APIConfig?.cubeName) {
      return;
    }
    let ACTION_NAME =
      table === 'right' ? 'RIGHT_TABLE_DATA' : 'LEFT_TABLE_DATA';
    if (data?.ALT_ACTION) {
      ACTION_NAME = data.ALT_ACTION;
    }

    data = getWhereClause(data, context);

    context.commit(ACTION_NAME, createCommitObject('loading'));
    const promise = customUrl
      ? HttpService.post(customUrl, { ...data.body.APIConfig })
      : HttpLayer.post({
          APIData: data.body.APIConfig
        });
    promise.then((response) => {
      if (response?.data?.success || response?.success) {
        let resultArray = [];
        if (responseTransformer) {
          resultArray = responseTransformer(response.data);
        } else {
          resultArray = transformer.mergeResultDimension(response.data, true);
        }
        context.commit(
          ACTION_NAME,
          createCommitObject('success', {
            rows: resultArray,
            totalRows:
              resultArray[0]?.auto_cubesdk_count ||
              resultArray[0]?.count ||
              response?.dataSize ||
              0
          })
        );
      } else {
        context.commit(
          ACTION_NAME,
          createCommitObject('error', { error: true })
        );
      }
    });
  },
  clearTableStore: (context, altStore) => {
    if (altStore) {
      context.commit(
        'LEFT_TABLE_DATA_ALT',
        createCommitObject('success', { rows: [], totalRows: 0 })
      );
      context.commit(
        'RIGHT_TABLE_DATA_ALT',
        createCommitObject('success', { rows: [], totalRows: 0 })
      );
    } else {
      context.commit('RESET_WHERE_CLAUSE', {
        dimensionNameValueList: [],
        date: {}
      });
      context.commit(
        'LEFT_TABLE_DATA',
        createCommitObject('success', { rows: [], totalRows: 0 })
      );
      context.commit(
        'RIGHT_TABLE_DATA',
        createCommitObject('success', { rows: [], totalRows: 0 })
      );
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
