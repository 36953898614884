<template>
  <div
    class="u-display-flex u-flex-align-items-space-between u-width-100 u-height-100 u-flex-wrap"
  >
    <tag
      class="u-cursor-pointer u-spacing-ml-0 u-spacing-mr-0 u-spacing-p-xs"
      :class="{ 'srinked-title': enableTextShrink }"
      :title="currentValue"
      :enable-style-api="enableStyleApi"
      :tippy-title="currentValue"
    />
    <rb-icon
      icon="right-arrow"
      class="u-color-grey-light-base rb-icon--medium"
    />
    <tag
      class="u-cursor-pointer u-spacing-ml-0 u-spacing-p-xs edited-dashed-border"
      :class="{ 'srinked-title': enableTextShrink }"
      :title="updatedValue"
      tag-color="transparent"
      :tippy-title="updatedValue"
    />
    <rb-icon
      icon="in-progress-dotted"
      class="u-color-orange-base rb-icon--medium"
    />
  </div>
</template>

<script>
import tag from '@/components/widgets/tag';

export default {
  name: 'EditedTagCell',
  components: {
    tag
  },
  props: {
    enableStyleApi: {
      type: Boolean,
      default: false
    },
    enableTextShrink: {
      type: Boolean,
      default: false
    },
    editedEntity: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    currentValue() {
      return this.editedEntity.title ? this.editedEntity.title : 'NA';
    },
    updatedValue() {
      return this.editedEntity.updatedValue
        ? this.editedEntity.updatedValue
        : 'NA';
    }
  }
};
</script>
