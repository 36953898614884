<template>
  <div :class="containerClasses">
    <div
      v-if="iconName.length"
      @click="handleIconClick"
    >
      <rb-icon
        :icon="iconName"
        :class="iconClasses"
      />
    </div>
    <span :class="textClasses">
      {{ iconText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ClickableIconWithText',
  props: {
    iconName: {
      type: String,
      default: ''
    },
    iconClasses: {
      type: String,
      default: 'rb-icon--small'
    },
    iconText: {
      type: String,
      default: ''
    },
    onIconClick: {
      type: Function,
      default: () => {}
    },
    containerClasses: {
      type: String,
      default: 'u-display-flex'
    },
    textClasses: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleIconClick() {
      this.onIconClick();
    }
  }
};
</script>
