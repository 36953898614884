<template>
  <div>
    <div :class="textClass">
      {{ showConvertedDateTimeStampByType() }}
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'TimezoneConversion',
  props: {
    time: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    textClass: {
      type: String,
      default: 'u-color-grey-base'
    }
  },
  data() {
    return {
      convertedDateTimeStamp: '',
      convertedDate: '',
      convertedTime: ''
    };
  },
  computed: {
    clientTimezone() {
      return this.$store.getters.getClientTimezone.value;
    }
  },
  mounted() {
    this.convertToClientTimezone();
  },
  methods: {
    convertToClientTimezone() {
      const utcTime = `${this.date} ${this.time}`;
      this.convertedDateTimeStamp = moment
        .utc(utcTime, 'MM/DD/YYYY hh:mm:ss a')
        .tz(this.clientTimezone)
        .format('MM/DD/YYYY hh:mm:ss a');
    },
    showConvertedDateTimeStampByType() {
      if (!moment(this.convertedDateTimeStamp).isValid()) {
        return 'NA';
      }
      const dateTimeArray = this.convertedDateTimeStamp.split(' ');
      this.convertedDate = dateTimeArray[0];
      this.convertedTime = `${dateTimeArray[1] ? dateTimeArray[1] : ''} ${
        dateTimeArray[2] ? dateTimeArray[2] : ''
      }`;
      if (this.type === 'DATE') {
        return moment(this.convertedDate).format('MMM DD, YYYY');
      } else if (this.type === 'TIME') {
        return this.convertedTime.toUpperCase();
      }
    }
  }
};
</script>
