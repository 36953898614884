<template>
  <div>
    <div class="u-display-flex u-flex-wrap-yes">
      <span
        data-cy="barchart-label"
        :class="labelClass"
        >{{ overallData.percentage + '%' }}</span
      >
      <span class="u-font-size-4 u-color-grey-lighter u-spacing-pl-xs">•</span>
      <span v-html="subLabel" />
    </div>
    <div
      :style="barStyles"
      class="u-spacing-mt-xs u-spacing-mr-s u-max-width-100"
    >
      <span
        v-for="(bar, index) in barChartData"
        :key="index"
        class="u-display-inline-block u-border-radius-xs"
        :class="bar.class"
        :style="{
          height: barHeight
        }"
      />
    </div>
    <div
      v-if="showLegends"
      class="u-display-flex u-flex-justify-content-flex-end u-spacing-mt-s"
    >
      <span
        class="u-font-size-5 u-color-grey-lighter u-font-weight-600 u-spacing-pt-xxs"
      >
        {{ legendsLabel }}
      </span>
      <div
        v-for="(bar, index) in overallData.barData"
        :key="bar.key"
        :class="[
          index === overallData.barData.length - 1 ? '' : 'u-spacing-mr-m',
          index === 0 ? 'u-spacing-ml-xs' : ''
        ]"
      >
        <div
          v-if="bar.label"
          class="u-display-flex"
        >
          <rb-icon
            v-if="bar.legendIcon"
            :icon="bar.legendIcon"
            class="rb-icon--xx-small u-spacing-mr-xs"
            :class="bar.class"
          />
          <span v-html="bar.label" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import Vue from 'vue';

export default {
  components: {
    widgetContainer,
    cwMixin
  },
  props: {
    barHeight: {
      type: String,
      default: '16px'
    },
    data: {
      type: Object,
      default: {}
    },
    barStyles: {
      type: Object,
      default() {
        return {
          display: 'grid',
          'column-gap': '4px'
        };
      }
    },
    showLegends: {
      type: Boolean,
      default: true
    },
    subLabel: {
      type: String,
      default: 'Total SKUs'
    },
    legendsLabel: {
      type: String,
      default: 'SKUs Split:'
    },
    subLabelClass: {
      type: String,
      default:
        'u-font-size-4 u-color-grey-lighter u-font-weight-600 u-spacing-mt-xxs u-display-inline-block u-spacing-ml-xs'
    },
    labelClass: {
      type: String,
      default: 'u-font-size-2 u-font-weight-700'
    },
    showGreyBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formatter: formatter,
      overallData: [],
      barChartData: []
    };
  },
  watch: {
    data() {
      this.updateChart();
    }
  },
  created() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      this.overallData = this.data;
      let totalBarPercentage = 0;
      let templateString = '';
      const barData = this.overallData?.barData;
      const barChartData = barData.filter((e) => e.percentage > 0);
      barChartData?.forEach((bar) => {
        totalBarPercentage += parseFloat(bar.percentage);
        templateString += `${
          bar.percentage < 0.5 ? ' 4px ' : bar.percentage + '% '
        }`;
      });
      if (totalBarPercentage < 100 && this.showGreyBar) {
        barChartData.push({
          percentage: (100 - totalBarPercentage).toFixed(2),
          class: 'u-bg-color-grey-xxx-light'
        });
        templateString += `${100 - totalBarPercentage}%`;
      }
      this.barChartData = barChartData;
      Vue.set(this.barStyles, 'grid-template-columns', templateString);
    }
  }
};
</script>
