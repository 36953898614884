<!--
Similar to iconTableHeader but it doesn't expect values in params for rendering and relies on props
and thus compatible with dynamicCellComponent
-->
<template>
  <div class="u-width-100">
    <rb-tooltip-header
      v-if="toolTipText"
      :id="setTooltip.id"
      :title="displayName"
      :message="tooltipMessage"
    />
    <rb-icon
      v-tippy="setTooltip.tippyOptions"
      class="u-spacing-mh-s u-color-grey-lighter"
      :class="iconClass"
      :icon="displayIcon"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'iconHeader',
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    displayIcon: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    toolTipText: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: ''
    }
  },
  computed: {
    tooltipMessage() {
      if (this?.toolTipText) {
        if (this.toolTipText.toLowerCase() === this.displayName.toLowerCase()) {
          return '';
        }
        return this.toolTipText.includes(':')
          ? this.toolTipText.split(':')[1].trim()
          : this.toolTipText;
      }
      return '';
    },
    iconClass() {
      if (this.iconSize) {
        return 'rb-icon--' + this.iconSize;
      } else {
        return 'rb-icon--small ';
      }
    },
    setTooltip() {
      return {
        id: this.params?.column?.colId,
        tippyOptions: {
          html: '#' + this.params?.column?.colId,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    }
  }
};
</script>
