import CreatePlan from '@/components/ams/budgetPlanner/createPlan.vue';
import Actions from '@/components/ams/budgetPlanner/actions/index.vue';
import BudgetPlanDashboard from '@/components/ams/budgetPlanner/dashboard/index.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      path: '',
      title: 'Budget Optimizer',
      name: 'budget-planner-dashboard',
      component: BudgetPlanDashboard,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlanner',
      config: 'budgetPlanner',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'create',
      name: 'create-budget-plan',
      component: CreatePlan,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlanner',
      config: 'budgetPlanner',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'edit',
      name: 'edit-budget-plan',
      component: CreatePlan,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlanner',
      config: 'budgetPlanner',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'actions/keywords',
      name: 'budget-plan-keyword-bid',
      component: Actions,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlanner',
      config: 'budgetPlanner',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'actions/campaigns',
      name: 'budget-plan-campaign-budget',
      component: Actions,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlanner',
      config: 'budgetPlanner',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '*',
      redirect: () => {
        return 'budget_optimizer';
      }
    }
  ]
};
