<template>
  <div
    class="recommendations--actions u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-height-100 u-flex-shrink-past-min-width"
  >
    <div class="u-display-flex">
      <div
        v-if="isRemoveActionApplicable"
        v-tippy="tippyOptions"
        class="icon--remove u-cursor-pointer"
        title="Remove"
        :class="{ 'u-spacing-ml-m': !isRemoveActionApplicable }"
        @click.stop="onRemoveAction(params.data, $event)"
      >
        <rb-icon :icon="'remove-fill'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableActions',
  data() {
    return {
      tippyOptions: {
        placement: 'bottom',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    isRemoveActionApplicable() {
      return this.params.context.componentParent.isRemoveActionApplicable;
    }
  },
  methods: {
    onRemoveAction(attributes, event) {
      const params = {
        attributes,
        event,
        action: 'remove'
      };
      this.params.context.componentParent.onActionFunc(params);
    }
  }
};
</script>

<style lang="css" scoped>
.icon--remove {
  color: #8b8f93;
}
.icon--remove .rb-icon::before {
  font-size: 16px;
}
.icon--remove:hover {
  color: #d7263d;
}
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
