<template>
  <component :is="wrapperElement">
    <template v-for="(element, index) in elementsArray">
      <html-component
        v-if="element.childString"
        :key="index"
        :html-string="element.childString"
        :wrapper-element="element.htmlElement"
        v-bind="element.attributes"
      />
      <component
        :is="element.htmlElement || 'span'"
        v-else-if="element.htmlElement"
        :key="index"
        v-bind="element.attributes"
        >{{ element.content }}</component
      >
      <template v-else>{{ element.content }}</template>
    </template>
  </component>
</template>

<script>
export default {
  name: 'HtmlComponent',
  props: {
    htmlString: {
      type: String,
      required: true,
      default: ''
    },
    wrapperElement: {
      type: String,
      default: 'span'
    }
  },
  computed: {
    elementsArray() {
      const contentArray = this.htmlString.split(/(<.*?>)/);
      const computedElements = [];
      let index = 0;
      while (index < contentArray.length) {
        const htmlRegExp = /<([a-zA-Z]+)\s*([^>]+)\s*(\/)?>/;
        const content = contentArray[index];
        const elementObj = {};
        if (htmlRegExp.test(content)) {
          // If the content has html elements in it
          let elementName = content.match(htmlRegExp)[1];
          const attributesString = content.match(htmlRegExp)[2];
          if (attributesString.length >= 2) {
            const attributesObj = this.getAttributesObj(attributesString);
            elementObj.attributes = attributesObj;
          } else {
            elementName += attributesString;
          }
          elementObj.htmlElement = elementName;
          // Check whether the element has only text node inside of it
          if (contentArray[index + 2].includes(`</${elementName}>`)) {
            elementObj.content = contentArray[++index];
            index++;
          } else {
            // else, it means the element has further nested elements inside of it
            const { childString, childEndIndex } = this.computeChildHtmlString(
              index,
              contentArray,
              elementName
            );
            index = childEndIndex + 1;
            elementObj.childString = childString;
          }
        } else {
          elementObj.content = content;
        }
        index++;
        computedElements.push(elementObj);
      }
      return computedElements;
    }
  },
  methods: {
    computeChildHtmlString(childStartIndex, contentArray, elementName) {
      let childEndIndex = childStartIndex + 1;
      let breakLoop = true;
      while (childEndIndex < contentArray.length) {
        if (breakLoop && contentArray[childEndIndex] === `</${elementName}>`) {
          break;
        } else if (contentArray[childEndIndex] === `<${elementName}>`) {
          breakLoop = false;
        } else if (
          !breakLoop &&
          contentArray[childEndIndex] === `</${elementName}>`
        ) {
          breakLoop = true;
        }
        childEndIndex++;
      } // get the closing tag of the element
      // constructing the child html string which we will pass recursively to this component itself
      const childString = contentArray
        .slice(childStartIndex + 1, childEndIndex)
        .join('');
      return { childString, childEndIndex };
    },
    getAttributesObj(attributesString) {
      const attributesArray = attributesString.split(/["']\s+/);
      const attributesObject = {};
      attributesArray.forEach(function (attribute) {
        const keyValue = attribute.split('=');
        const key = keyValue[0];
        const value = keyValue[1].replace(/['"]+/g, '');
        attributesObject[key] = value;
      });
      return attributesObject;
    }
  }
};
</script>
