<template>
  <div>
    <div
      class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-mt-m u-spacing-p-l u-width-100 card"
    >
      <div class="u-display-flex u-flex-direction-column">
        <span class="u-font-size-3 u-font-weight-bold u-color-blue-base"
          >Action
        </span>
        <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
          >Create and schedule actions.</span
        >
      </div>
      <!-- <div class="u-display-flex u-flex-direction-column u-spacing-mt-l ">
            <span class="u-font-size-5 u-font-weight-600">Type</span>
            <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s">Choose the type of target list.</span>
            <div class="u-display-flex u-spacing-mt-m">
                <boomerang-single-select :selects="(step || {}).actionMode" :selectWidth="50" v-on:change="modeChange">
                </boomerang-single-select>
            </div>
        </div> -->
      <!-- eslint-disable -->
      <div
        v-for="(action, index) in actions"
        :key="index"
        class="u-display-flex u-flex-align-items-center"
      >
        <div
          v-if="!action.deleted"
          class="u-display-flex u-flex-align-items-center u-width-100 add--action--area u-spacing-mt-l u-spacing-p-m"
          :class="{ errorHighlight: action.overLapping }"
        >
          <div class="u-display-flex u-flex-direction-column u-width-100">
            <div class="u-display-flex u-flex-justify-content-space-between">
              <span class="u-font-size-5 u-font-weight-600"
                >Action {{ index + 1 }}</span
              >
              <span @click="removeAction(index)">
                <rb-icon
                  :icon="'cross'"
                  class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
                />
              </span>
            </div>
            <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-xs"
              >Choose a action type</span
            >
            <div class="u-spacing-mt-l">
              <div class="u-display-flex u-flex-align-items-center">
                <rb-select
                  class="u-spacing-mr-s valueDropdown typeSelect"
                  :send-details="true"
                  :on-close="selectActionType"
                  :options="step.actionTypeOptions"
                  :context="[index]"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                    >
                      <div class="u-display-flex u-flex-direction-column">
                        <span class="u-font-size-7">{{
                          (actions[index].selectedActionType || {}).title
                        }}</span>
                      </div>
                    </div>
                    <rb-icon
                      class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-font-size-7"
                    >
                      {{ option.title }}
                    </div>
                  </template>
                </rb-select>
                <custom-tool-tip
                  v-if="step.tooltipOptions"
                  :tippy="{
                    html: '#action-tooltip' + index,
                    interactive: false,
                    offset: '0,0',
                    placement: 'top-start'
                  }"
                >
                  <div slot="toolTipData">
                    <div
                      class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                    >
                      <span
                        v-for="(tooltipText, tooltipKey) in step.tooltipOptions[
                          actions[index].selectedActionType.value
                        ]"
                        :key="tooltipKey"
                        class="u-font-size-6 u-font-weight-400 u-display-flex u-flex-align-items-flex-start"
                        ><div class="u-spacing-mt-xxs">
                          <rb-icon
                            class="u-flex-0 rb-icon--small u-spacing-mr-xxs u-color-grey-lighter"
                            :icon="'dot'"
                          />
                        </div>
                        <span>{{ tooltipText }}</span></span
                      >
                    </div>
                  </div>
                  <div
                    slot="body"
                    slot-scope="{ tippyConfig }"
                  >
                    <div
                      v-tippy="tippyConfig"
                      class="u-font-size-6 u-spacing-mr-xs"
                    >
                      <rb-icon
                        class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                        :icon="'info-circle-fill'"
                      />
                    </div>
                  </div>
                </custom-tool-tip>
              </div>
            </div>
            <div
              class="u-display-flex u-spacing-mt-s u-flex-align-items-center"
            >
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="selectOperation"
                :options="
                  step.operationTypes[actions[index].selectedActionType.value]
                    .operation
                "
                :context="[index]"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                  >
                    <div class="u-display-flex u-flex-direction-column">
                      <span class="u-font-size-7">{{
                        (actions[index].selectedOperation || {}).title
                      }}</span>
                    </div>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-font-size-7"
                  >
                    {{ option.title }}
                  </div>
                </template>
              </rb-select>
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="selectMode"
                :options="
                  step.operationTypes[actions[index].selectedActionType.value]
                    .mode
                "
                :context="[index]"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                  >
                    <div class="u-display-flex u-flex-direction-column">
                      <span class="u-font-size-7">{{
                        (actions[index].selectedMode || {}).title
                      }}</span>
                    </div>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-font-size-7"
                  >
                    {{ option.title }}
                  </div>
                </template>
              </rb-select>
              <div
                class="u-display-flex u-flex-0 u-flex-align-items-center inputContainer"
              >
                <span
                  v-if="action.formatType === 'ABSOLUTE'"
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-pl-s u-flex-0"
                >
                  {{ currency }}
                </span>
                <input
                  ref="newValue"
                  type="number"
                  data-cy="bidInput"
                  class="rb-input u-display-flex u-flex-align-items-center percentage-input u-spacing-p-s u-font-size-7"
                  min="0.02"
                  step="0.01"
                  max="500"
                  placeholder="15"
                  :value="actions[index].newValue"
                  @keyup="checkForMaxBid($event, action, 'newValue', index)"
                />
                <span
                  v-if="action.formatType === 'PERCENTAGE'"
                  class="appender u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-pr-s u-flex-0"
                >
                  %
                </span>
              </div>
              <span class="u-font-size-6 u-color-grey-lighter u-spacing-ml-s"
                >of default value</span
              >
            </div>
            <div
              class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-l"
            >
              <span class="u-font-size-5 u-font-weight-600"
                >Action {{ index + 1 }} Scheduler</span
              >
              <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-xs"
                >Schedule the action as per your requirements</span
              >
              <div class="u-display-flex u-flex-direction-column u-width-100">
                <div
                  class="u-display-flex u-flex-align-items-center u-width-100 u-spacing-mt-l"
                >
                  <span
                    class="u-font-size-6 u-font-weight-600 action-legend-holder"
                    >Time period within a day :</span
                  >
                  <rb-select
                    class="time-drop-down typeSelect u-spacing-ml-s"
                    :send-details="true"
                    :on-close="selectStartTime"
                    :options="timeZoneOptions"
                    :context="[index]"
                  >
                    <div
                      slot="trigger"
                      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                      >
                        <div class="u-display-flex u-flex-direction-column">
                          <span class="u-font-size-7">{{
                            (actions[index].selectedStartTime || {}).title
                          }}</span>
                        </div>
                      </div>
                      <rb-icon
                        class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-font-size-7"
                      >
                        {{ option.title }}
                      </div>
                    </template>
                  </rb-select>
                  <rb-select
                    class="time-drop-down typeSelect u-spacing-ml-s"
                    :send-details="true"
                    :on-close="selectEndTime"
                    :options="timeZoneOptions"
                    :context="[index]"
                  >
                    <div
                      slot="trigger"
                      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                      >
                        <div class="u-display-flex u-flex-direction-column">
                          <span class="u-font-size-7">{{
                            (actions[index].selectedEndTime || {}).title
                          }}</span>
                        </div>
                      </div>
                      <rb-icon
                        class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-font-size-7"
                      >
                        {{ option.title }}
                      </div>
                    </template>
                  </rb-select>
                  <span
                    class="u-font-size-7 u-spacing-ml-m"
                    :class="{
                      'u-visibility-visible': !action.timeValidation,
                      'u-visibility-hidden': action.timeValidation
                    }"
                  >
                    <error-message
                      :error-message="'Start time needs to be before the end time. Please choose an appropriate start time.'"
                    />
                  </span>
                </div>
                <div
                  class="u-display-flex u-flex-align-items-center u-width-100 u-spacing-mt-m"
                >
                  <span
                    class="u-font-size-6 u-font-weight-600 action-legend-holder"
                    >Frequency :</span
                  >
                  <div class="u-display-flex">
                    <div
                      v-for="(day, frequencyIndex) in actions[index]
                        .frequencyList"
                      class="u-spacing-m-xs"
                      @click="changeFrequencyList(index, frequencyIndex)"
                    >
                      <div
                        class="dayChip u-display-flex u-flex-align-items-center u-flex-justify-content-center"
                        :class="{ active: day.active }"
                      >
                        <span class="u-font-size-7">{{ day.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="u-display-flex u-flex-align-items-center u-width-100 u-spacing-mt-m"
                >
                  <span
                    class="u-font-size-6 u-font-weight-600 action-legend-holder"
                    >Start and end dates :</span
                  >
                  <div
                    class="u-display-flex u-flex-align-items-center u-spacing-ml-s"
                  >
                    <local-date-filter
                      :tooltip="true"
                      modal-position="Right"
                      :custom-date="action.startDateRange"
                      :mode="'Single'"
                      :disabled="action.startDateDisabled"
                      :value="'03/15/2019'"
                      :context="[index]"
                      @selected-value="setStartDate"
                    />
                    <span class="u-font-size-6 u-spacing-mh-m">to</span>
                    <local-date-filter
                      :tooltip="true"
                      modal-position="Right"
                      :custom-date="action.endDateRange"
                      :mode="'Single'"
                      :value="'03/11/2019'"
                      :context="[index]"
                      @selected-value="setEndDate"
                    />
                  </div>
                </div>
                <span
                  class="u-font-size-7 u-spacing-mt-m"
                  :class="{
                    'u-visibility-visible': !action.dateValidation,
                    'u-visibility-hidden': action.dateValidation
                  }"
                >
                  <error-message
                    :error-message="'Start date needs to be before the end date. Please choose an appropriate start date.'"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- eslint-enable -->
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-mt-l u-spacing-p-m add--action--area"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-cursor-pointer create-action"
          @click="addActions"
        >
          <rb-icon
            class="rb-icon--medium u-spacing-mh-s"
            :icon="'plus'"
          />
          <span class="u-font-size-6"> Create an action</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import boomerangSingleSelect from '@/components/widgets/select/singleSelect.vue/';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import moment from 'moment-timezone';
import Vue from 'vue';
import errorMessage from './errorMessage.vue';
import customToolTip from '@/components/widgets/customToolTip.vue';

export default {
  components: {
    boomerangSingleSelect,
    localDateFilter,
    errorMessage,
    customToolTip
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    allStepsData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      actions: [],
      actionObj: {},
      actionCount: 0,
      selectedActionType: [],
      timeZoneOptions: [],
      defaultSelectedStartTime: new Date(),
      defaultSelectedEndTime: new Date(),
      frequencyList: [],
      startDateRange: {},
      endDateRange: {},
      startDate: new Date(),
      endDate: new Date(),
      currency: Vue.prototype.$currency
    };
  },
  computed: {},
  watch: {
    allStepsData(val) {
      const actions = (val[2].data || {}).actions
        ? (val[2].data || {}).actions
        : [];
      // this.$set(this.actions, 'actionList', actions);
      this.actions = [];
      this.actions.push(...actions);
    }
  },
  created() {
    this.actions = (this.step.data || {}).actions || [];
    this.setFrequencyList();
    this.nextStepStatus(true);
    this.setTimeSelectionOptions();
    this.setDefaultDateRange();
    this.stepValidation();
    // this.addActions();
  },
  mounted() {
    // this.changeFrequencyList();
  },
  methods: {
    removeAction(index) {
      if (this.actions[index].actionId && this.actions[index].actionId !== '') {
        this.actions[index].deleted = true;
      } else {
        this.actions.splice(index, 1);
      }
      this.stepValidation();
    },
    selectActionType(context, selectedOption) {
      if (!selectedOption?.length) return;
      const index = context[0];
      Vue.set(this.actions[index], 'selectedActionType', selectedOption[0]);
      Vue.set(
        this.actions[index],
        'selectedOperation',
        this.step.operationTypes[selectedOption[0].value].operation[0]
      );
    },
    selectOperation(context, selectedOption) {
      if (!selectedOption?.length) return;
      const index = context[0];
      Vue.set(this.actions[index], 'selectedOperation', selectedOption[0]);
    },
    selectMode(context, selectedOption) {
      if (!selectedOption?.length) return;
      const index = context[0];
      Vue.set(this.actions[index], 'selectedMode', selectedOption[0]);
      Vue.set(this.actions[index], 'formatType', selectedOption[0].value);
    },
    selectStartTime(context, selectedOption) {
      if (!selectedOption?.length) return;
      const index = context[0];
      Vue.set(this.actions[index], 'selectedStartTime', selectedOption[0]);
      this.actionTimeValidation(index);
    },
    selectEndTime(context, selectedOption) {
      if (!selectedOption?.length) return;
      const index = context[0];
      Vue.set(this.actions[index], 'selectedEndTime', selectedOption[0]);
      this.actionTimeValidation(index);
    },
    setStartDate(range, context) {
      const index = context[0];
      this.actions[index].startDate = range.from;
      this.actionDateValidation(index);
    },
    setEndDate(range, context) {
      const index = context[0];
      this.actions[index].endDate = range.to;
      this.actionDateValidation(index);
    },
    actionNewValueValidation(index) {
      if (this.actions[index].newValue) {
        Vue.set(this.actions[index], 'newValueValidation', true);
      } else {
        Vue.set(this.actions[index], 'newValueValidation', false);
      }
      Vue.set(this.actions, index, this.actions[index]);
      this.stepValidation();
    },
    actionTimeValidation(index) {
      const startTime = moment(
        this.actions[index].selectedStartTime.value,
        'HH:mm'
      );
      const endTime = moment(
        this.actions[index].selectedEndTime.value,
        'HH:mm'
      );
      if (startTime.diff(endTime) <= 0) {
        this.actions[index].timeValidation = true;
      } else {
        this.actions[index].timeValidation = false;
      }
      Vue.set(this.actions, index, this.actions[index]);
      this.stepValidation();
    },
    actionDateValidation(index) {
      const startDate = moment(this.actions[index].startDate);
      const endDate = moment(this.actions[index].endDate);
      if (startDate.diff(endDate) <= 0) {
        this.actions[index].dateValidation = true;
      } else {
        this.actions[index].dateValidation = false;
      }
      Vue.set(this.actions, index, this.actions[index]);
      this.stepValidation();
    },
    actionFrequencyValidation(index) {
      const frequencies = this.actions[index].activeDays;
      if (frequencies.length > 0) {
        this.actions[index].frequencyValidation = true;
      } else {
        this.actions[index].frequencyValidation = false;
      }
      Vue.set(this.actions, index, this.actions[index]);
      this.stepValidation();
    },
    stepValidation() {
      let deletedCount = 0;
      for (let i = 0; i < this.actions.length; i++) {
        if (this.actions[i].deleted) {
          deletedCount++;
        }
      }
      if (this.actions.length < 0 || deletedCount >= this.actions.length) {
        this.stepValid = false;
      } else {
        this.stepValid = this.clientSideActionsValidation();
      }
      if (this.stepValid) {
        this.nextStepStatus(false);
        this.stepDataOut();
      } else {
        this.nextStepStatus(true);
      }
    },
    clientSideActionsValidation() {
      let nextStep = false;
      for (let i = 0; i < this.actions.length; i++) {
        this.actions[i].overLapping = false;
        const {
          frequencyValidation,
          dateValidation,
          timeValidation,
          newValueValidation
        } = this.actions[i];
        if (
          frequencyValidation &&
          dateValidation &&
          timeValidation &&
          newValueValidation
        ) {
          nextStep = true;
          this.actions[i].isActionValid = true;
        } else {
          nextStep = false;
          break;
        }
      }
      return nextStep;
    },
    setDefaultDateRange() {
      const startDate = new Date(this.startDate);
      const defaultDate = new Date(this.startDate);
      const toDate = new Date();
      toDate.setFullYear(this.startDate.getFullYear() + 10);
      startDate.setDate(this.startDate.getDate() - 1);
      const startDateRangeObj = {
        fromDate: startDate,
        toDate: toDate,
        defaultDate: defaultDate
      };
      const endDateRangeObj = {
        fromDate: startDate,
        toDate: toDate,
        defaultDate: toDate
      };
      this.startDateRange = startDateRangeObj;
      this.endDateRange = endDateRangeObj;
      this.endDate = toDate;
    },
    addActions() {
      const actionObj = {
        selectedActionType: this.step.actionTypeOptions[0],
        selectedOperation:
          this.step.operationTypes[this.step.actionTypeOptions[0].value]
            .operation[0],
        selectedMode:
          this.step.operationTypes[this.step.actionTypeOptions[0].value]
            .mode[0],
        selectedStartTime: this.defaultSelectedStartTime,
        selectedEndTime: this.defaultSelectedEndTime,
        frequencyList: JSON.parse(JSON.stringify(this.frequencyList)),
        formatType: 'PERCENTAGE',
        startDate: JSON.parse(JSON.stringify(this.startDate)),
        endDate: JSON.parse(JSON.stringify(this.endDate)),
        startDateRange: this.startDateRange,
        endDateRange: this.endDateRange,
        dateValidation: true,
        frequencyValidation: false,
        timeValidation: true,
        startDateDisabled: false,
        isActionValid: false
      };
      this.actions.push(actionObj);
      this.stepValidation();
    },
    checkForMaxBid(event, actionObject, key, index) {
      const value = Number(event.target.value).toFixed(2);
      const maxBid = 999;
      const minBid = 0;
      actionObject[key] = Number(value);
      if (value > maxBid) {
        actionObject[key] = maxBid;
        event.target.value = maxBid;
      }
      if (value < minBid) {
        actionObject[key] = minBid;
        event.target.value = minBid;
      }
      this.actions[index].newValue = event.target.value;
      this.actionNewValueValidation(index);
    },
    modeChange(value) {
      console.log(value);
    },
    nextStepStatus(value) {
      this.$emit('nextStep', value, this.index);
    },
    formatStepData() {
      const obj = {
        actions: this.actions
      };
      return obj;
    },
    stepDataOut() {
      const obj = this.formatStepData();
      obj.index = this.index;
      this.nextStepStatus(false);
      this.$emit('stepDataOut', obj);
    },
    getTimeStops(start, end) {
      var startTime = moment(start, 'HH:mm');
      var endTime = moment(end, 'HH:mm');
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }
      var timeStops = [];
      while (startTime.isBefore(endTime)) {
        timeStops.push(moment(startTime).format('HH:mm'));
        startTime.add(60, 'minutes');
      }
      return timeStops;
    },
    setTimeSelectionOptions() {
      const hoursFormatList = this.getTimeStops('00:00', '24:00');
      hoursFormatList.push('23:59');
      for (let i = 0; i < hoursFormatList.length; i++) {
        const timeObj = {
          title: moment(hoursFormatList[i], ['HH:mm']).format('hh:mm A'),
          value: hoursFormatList[i]
        };
        this.timeZoneOptions.push(timeObj);
      }
      const currentTime = moment(new Date()).format('HH:mm');
      const defaultIndex = this.timeZoneOptions.findIndex((element) => {
        const elementMomentTime = moment(element.value, ['HH:mm']).format('HH');
        const currentMomentTime = moment(currentTime, ['HH:mm']).format('HH');
        if (elementMomentTime === currentMomentTime) {
          return true;
        }
      });
      this.defaultSelectedStartTime = this.timeZoneOptions[defaultIndex];
      this.defaultSelectedEndTime = this.timeZoneOptions[defaultIndex + 1];
    },
    setFrequencyList() {
      this.frequencyList = this.step.frequencyList;
    },
    changeFrequencyList(index, frequencyIndex) {
      this.actions[index].frequencyList[frequencyIndex].active =
        !this.actions[index].frequencyList[frequencyIndex].active;
      const list = this.actions[index].frequencyList;
      const dayObj = this.actions[index].frequencyList[frequencyIndex];
      Vue.set(this.actions[index].frequencyList, frequencyIndex, dayObj);
      Vue.set(this.actions[index], 'frequencyList', list);
      this.actions[index].activeDays = [];
      for (let i = 0; i < this.actions[index].frequencyList.length; i++) {
        if (this.actions[index].frequencyList[i].active) {
          this.actions[index].activeDays.push(
            this.actions[index].frequencyList[i]
          );
        }
      }
      this.actionFrequencyValidation(index);
    }
  }
};
</script>

<style scoped lang="css">
.add--action--area {
  background-color: #f5f7fa;
  border-radius: 4px;
}
.valueDropdown {
  min-width: 154px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: #fff;
}
.valueDropdown.typeSelect {
  width: 154px;
}
.time-drop-down {
  min-width: 80px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: #fff;
}
.time-drop-down.typeSelect {
  width: 104px;
}

.rb-input {
  outline: 0;
  line-height: 1;
  width: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #e9eaeb;
}
.rb-input::placeholder {
  color: #caccce;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
}
.rb-input.percentage-input {
  border: 0;
}
.dayChip {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e9eaeb;
}
.dayChip.active {
  background-color: #007cf6;
  border: 0;
  color: #fff;
}
.errorHighlight {
  border: 1px solid #d7263d;
}
.create-action {
  color: #8b8f93;
}
.create-action:hover {
  color: #4b5158;
}
.action-legend-holder {
  width: 20%;
}
.inputContainer {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
}
.appender {
  line-height: normal;
  border: 0;
}
.appender.currency-symbol {
  border-right: 0;
}

.max-width-420px {
  max-width: 420px;
}
</style>
