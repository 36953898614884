<template>
  <div class="u-display-flex u-flex-direction-column">
    <PlacementInclusion
      v-if="showPlacementInclusion"
      :placement-inclusion-expand="placementInclusionExpand"
      :placement-inclusion-object="placementInclusionObject"
    />
    <BidMultiplier
      :show-home-page-input-box="showHomePageInputBox"
      :show-stock-up-input-box="showStockUpInputBox"
      :show-search-ingrid-input-box="showSearchIngridInputBox"
      :show-buy-box-input-box="showBuyBoxInputBox"
      :form-error-object="formErrorObject"
      :bid-multiplier-expand="bidMultiplierExpand"
      :bid-multiplier-object="bidMultiplierObject"
    />
  </div>
</template>
<script>
import PlacementInclusion from './PlacementInclusion.vue';
import BidMultiplier from './BidMultiplier.vue';
export default {
  components: {
    PlacementInclusion,
    BidMultiplier
  },
  props: {
    placementInclusionExpand: {
      type: Boolean,
      default: false
    },
    placementInclusionObject: {
      type: Object,
      default: {
        searchCarousel: {
          title: 'Enabled',
          value: 'included'
        },
        itemCarousel: {
          title: 'Disable',
          value: 'excluded'
        },
        itemBuyBox: {
          title: 'Disable',
          value: 'excluded'
        }
      }
    },
    bidMultiplierObject: {
      type: Object,
      default: {
        placement: { buyBox: '', searchIngrid: '', homePage: '', stockUp: '' },
        platform: { desktop: '', mobile: '', app: '' }
      }
    },
    bidMultiplierExpand: {
      type: Boolean,
      default: false
    },
    allStepsData: {
      type: Object,
      default: {}
    },
    formErrorObject: {
      type: Object,
      default: {
        placementBidMultiplier: '',
        platformBidMultiplier: ''
      }
    }
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    showPlacementInclusion() {
      return !this.isCampaignSpAuto;
    },
    isCampaignSpAuto() {
      if (
        this.allStepsData?.campaignType === 'sponsoredProducts' &&
        this.allStepsData?.targetingType === 'auto'
      ) {
        return true;
      }
      return false;
    },
    showHomePageInputBox() {
      if (this.retailer === 'walmart') {
        return this.isCampaignSpAuto;
      }
      return this.retailer !== 'samsclub_api';
    },
    showStockUpInputBox() {
      if (this.retailer === 'walmart') {
        return this.isCampaignSpAuto;
      }
      return this.retailer !== 'samsclub_api';
    },
    showSearchIngridInputBox() {
      return true;
    },
    showBuyBoxInputBox() {
      return this.retailer !== 'samsclub_api';
    }
  }
};
</script>
