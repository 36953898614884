<template>
  <div class="u-overflow-hidden u-flex-1 u-width-100">
    <div
      v-if="allEntititesTagsMap.load || customLoad"
      class="fill--parent"
    >
      <loader
        class="fill--parent"
        color="#007cf6"
      />
    </div>
    <div
      class="u-display-flex u-flex-justify-content-space-between u-spacing-pt-l u-spacing-ph-xxl"
    >
      <div class="u-flex-direction-column">
        <!-- search -->
        <div class="u-width-100 u-spacing-pb-s">
          <rb-search
            class="search__input"
            :placeholder="widgetsConfig.search.placeholder"
            @onSearchTrigger="handleSearch"
          />
        </div>
        <!-- filter -->
        <div>
          <rb-filter-panel
            :id="localStoreageFilterKey || 'manage-tags'"
            :key="filterKey"
            emit="entityFilterChange"
            :data="filterData"
            :primary="primaryFilterData"
            :secondary-data="secondaryFilterData"
            :listener-event="localStoreageFilterKey || 'manage-tags'"
            :state="filterState"
            :use-local-storage="localStoreageFilterKey ? true : false"
            :enable-remove-all="false"
            :loader-status="loaderStatus"
            :allow-date-compare="false"
            :enable-save="false"
            :filter-v2="filterV2"
            :show-ingestion-progress="filterV2"
            :ingestion-dependent-entities="ingestionDependentEntities"
            :ingestion-tooltip-text="ingestionToolTipText"
            :has-search="false"
            :hide-add-filter="false"
            :new-date="false"
            :get-filter-instance="getFilterInstance"
            @savedFilterApplied="filterKey++"
          />
        </div>
      </div>
      <!-- right side -->
      <div class="u-display-flex u-flex-justify-content-flex-end">
        <slot name="top-right" />
      </div>
    </div>
    <div>
      <rb-insights-table
        key="tags_table"
        :config="tableConstants.tableConfig"
        :grid-options="tableConstants.gridApi"
        :table-row="allEntititesTagsMap.rows"
        :table-column="generateColumns"
        class="pagination-overlay tags-table"
        :row-height="80"
        :enable-client-side-sorting="false"
        :enable-server-side-sorting="false"
        :pagination="true"
        :pagination-total-key="allEntititesTagsMap.totalRows"
        pagination-change-event="entityPageChanged"
        :pagination-per-page-limit="20"
        primary-key="campaign_id"
        :show-pagination-options="false"
        :get-current-instance="getCurrentInstance"
      />
    </div>
  </div>
</template>
<script>
import rbSearch from '@/components/widgets/search.vue';
import loader from '@/components/basic/loader';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsUtil from '@/components/widgetMixin';
import editableTagsCell from '@/components/widgets/tableComponents/cells/editableTagsCell.vue';
import modal from '@/components/widgets/modal.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import tagsViewCell from '@/components/widgets/tableComponents/cells/tagsViewCell.vue';

export default {
  components: {
    rbSearch,
    loader,
    rbFilterPanel,
    modal
  },
  mixins: [widgetsUtil],
  props: {
    ingestionDependentEntities: {
      type: Array,
      default: ['campaign']
    },
    ingestionToolTipText: {
      type: String,
      default: 'Ingested filters will take some time to load'
    },
    customLoad: {
      default: false,
      type: Boolean
    },
    widgetsConfig: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: function () {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      selectedEntity: '',
      tableInstance: null,
      localFilters: [],
      showModal: false,
      selectedTag: '',
      filterInstance: null,
      filterKey: 0
    };
  },
  computed: {
    localStoreageFilterKey() {
      return this.widgetsConfig?.localStoreageFilterKey || null;
    },
    getExecuteApiSelectedFitlers() {
      return this.$store.getters.getExecuteApiSelectedFitlers;
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    allEntititesTagsMap() {
      return this.$store.getters.getAllEntititesTagsMap;
    },
    generateColumns() {
      return this.widgetsConfig.generateColumns(this);
    },
    tableConstants() {
      return this.widgetsConfig.tableConstants(this);
    },
    filterState() {
      return {
        getter: 'getExecuteApiSelectedFitlers',
        setter: 'fetchEntitiesExecuteApiFilters'
      };
    }
  },
  watch: {
    getExecuteApiSelectedFitlers() {
      this.widgetsConfig.APIConfig.paginationRequest.offset = 0;
      this.fetchTableRows(this.widgetsConfig.APIConfig);
    }
  },
  created() {
    this.$emit('entityInstance', this);
    eventBus.$on('entityPageChanged', (item) => {
      this.widgetsConfig.APIConfig.paginationRequest.offset =
        (item - 1) * this.widgetsConfig.APIConfig.paginationRequest.limit;
      this.fetchTableRows(this.widgetsConfig.APIConfig);
    });
    eventBus.$on('entityFilterChange', (item) => {
      this.tableConstants.tableConfig.body.APIConfig.page = 1;
    });
    Vue.component('EditableTagsCell', editableTagsCell);
    Vue.component('TagsViewCell', tagsViewCell);
    this.fetchFilters(
      this.widgetsConfig.APIConfig.brandsRequest.cubeName,
      'FETCH_FILTERS_V2'
    );
  },
  methods: {
    getCurrentInstance(that) {
      this.tableInstance = that;
    },
    fetchTableRows(apiConfig) {
      this.$store.dispatch('fetchAllEntitiesWithAllTags', {
        APIConfig: apiConfig || this.widgetsConfig.APIConfig,
        localFilters: this.localFilters
      });
    },
    handleSearch(data) {
      this.localFilters =
        data?.length > 0
          ? [
              {
                dimensionName: this.widgetsConfig.search.dimensionName,
                operator: 'ILIKE',
                dimensionValue: data
              }
            ]
          : [];
      this.$emit('localFilter', this.localFilters);
      this.widgetsConfig.APIConfig.paginationRequest.offset = 0;
      this.tableConstants.tableConfig.body.APIConfig.page = 1;
      this.fetchTableRows();
    }
  }
};
</script>

<style lang="css">
.page-height {
  height: calc(100vh - 100px);
}
.tags-table .page-height {
  height: calc(100vh - 356px);
}
.tags-table .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.tags-table .pagination-overlay {
  width: 100%;
  height: calc(100vh - 300px);
}
.tags-table .ag-body-viewport-wrapper .ag-layout-auto-height {
  height: calc(100vh - 380px);
  overflow: auto;
  overflow-x: hidden;
}
</style>
