import HttpService from '@/utils/services/http-service';

export const downloadLinkAsFile = (linkUrl, fileName) => {
  const targetFileName = fileName || linkUrl.split('/').pop();
  const link = document.createElement('a');
  link.setAttribute('href', linkUrl);
  link.setAttribute('download', targetFileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
};

// the parent bucket for these s3uri should be marked as
// available for download in orchestrator config if you need permission to download the object
export const getDownloadLinkOfS3Uri = async (s3Uri) => {
  return HttpService.get('S3_DOWNLOAD', {
    append: `?s3uri=${s3Uri}`
  });
};
export const downloadS3Uri = async (s3Uri, preferredDownloadName) => {
  const presignPayload = await getDownloadLinkOfS3Uri(s3Uri);
  return downloadLinkAsFile(
    presignPayload.data.presignedUrl,
    preferredDownloadName
  );
};

export const createDownloadFileFromBlob = (fileBlob, filename) => {
  var URL = window.URL || window.webkitURL;
  var downloadUrl = URL.createObjectURL(fileBlob);
  if (filename) {
    // use HTML5 a[download] attribute to specify filename
    var a = document.createElement('a');
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  } else {
    window.location.href = downloadUrl;
  }
  // setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
};
export const downloadExcelFromS3Csv = async (s3Uri, fileName, excelType) => {
  const excelFileData = await HttpService.get('EXCEL_FROM_S3_CSV_DOWNLOAD', {
    append: `?s3uri=${s3Uri}`,
    responseType: 'blob'
  });
  const targetFileName = `${fileName || s3Uri.split('/').pop()}.xlsx`;
  const blob = new Blob([excelFileData.data]);
  createDownloadFileFromBlob(blob, targetFileName);
};

export const downloadJSONFromS3Path = async (s3URI) => {
  const excelFileData = await HttpService.get('JSON_FROM_S3_DOWNLOAD', {
    append: `?s3uri=${s3URI}`,
    responseType: 'blob'
  });
  const blob = new Blob([excelFileData.data]);
  const res = await blob.text();
  const json = JSON.parse(res);
  return json.data;
};
