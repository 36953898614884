<template>
  <HelpDocumentDialog
    :full-path="`https://cdn.rboomerang.com/mailassets/FAQs-Customers%20of%20CommerceIQ.pdf`"
    :is-full-width="true"
    @closeCallback="closeCallback"
  />
</template>

<script>
import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';

export default {
  components: {
    HelpDocumentDialog
  },
  methods: {
    closeCallback() {
      this.$router.push({ name: 'Home' });
    }
  }
};
</script>
