export default {
  detailViewTemplates: {
    'Organic growth client recommendations all': 'OrganicGrowthDetail',
    'Organic growth 3pt recommendations all': 'OrganicGrowth3PDetail',
    'Organic growth comp recommendations all': 'OrganicGrowthOthersDetail',
    'Tagged 3pt list all': 'ThreePVariantsDetail',
    'Comp oos amazon recommendations all': 'compOOSDetail',
    'Predicted oos client with po all': 'PredictiveOOSWithPODetail',
    'Predicted oos client no po all': 'PredictiveOOSWithoutPODetail',
    'Predicted OOS combined all': 'PredictiveOOSCombined',
    'Unavailable SKUs all': 'UnavailableSKUsDetail',
    'FC inventory alerts all': 'localizedInventoryDetail',
    'Buybox loss all': 'lostBuyBoxDetail',
    'Price Decrease Alert': 'priceDropIncreaseDetail',
    'Price Increase Alert': 'priceDropIncreaseDetail',
    'Low Inventory': 'lowInventoryDetail',
    'Search share top3 drop': 'shareOfSearchChangeDetail',
    'Search share top3 increase': 'shareOfSearchChangeDetail',
    'Search share top10 drop': 'shareOfSearchChangeDetail',
    'Search share top10 increase': 'shareOfSearchChangeDetail',
    'Search share page1 drop': 'shareOfSearchChangeDetail',
    'Search share page1 increase': 'shareOfSearchChangeDetail',
    'Sales decrease': 'salesDropDetail',
    'Sales increase': 'salesIncreaseDetail',
    'Market Share Drop': 'marketShareDetail',
    'Market Share Increase': 'marketShareDetail'
  }
};
