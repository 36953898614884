const criteoConfigs = (that) => ({
  tableConfigs: {
    metadata: {
      sourceTable: [
        {
          // currDefinition
          name: 'sku_details',
          type: 'sku',
          uiField: {
            uiLabel: 'SKU Details',
            uiType: 'custom',
            widget: 'sku',
            uiOrder: '1',
            metadata: {
              widget: 'sku',
              tippyText: '',
              toolTipText: '',
              tableColumnName: 'SKU Details',
              columnTitleKey: 'name',
              columnAsinKey: 'product_id',
              columnImageUrlKey: 'image_url',
              columnAsinHoverText: '',
              columnDisableProductLink: true,
              //   coloumnProductUrlKey: 'product_url',
              showOnUi: true,
              isFixed: false,
              getQuickFilterText: function (params) {
                return params.data.product_title + '_' + params.data.asin;
              }
            }
          }
        },
        {
          name: 'Add all',
          type: 'custom',
          uiField: {
            uiLabel: 'Add all',
            uiType: 'custom',
            clickHeader: that.addAllAsinsToTable,
            customStyles:
              'cursor:pointer; color:#037ef6 !important; padding-left:15px',
            headerIcon: {
              icon: 'info-circle-fill',
              toolTip: 'Add all SKUs on this page'
            },
            uiOrder: '2',
            metadata: {
              toolTipText: 'Add this SKU',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              suppressSizeToFit: true,
              isFixed: false,
              iconClickEvent: that.addToSelection,
              displayIcon: 'plus',
              alternateIcon: 'check-fill-circle',
              alternateIconClass: 'u-color-green-base',
              width: 110
            }
          }
        }
      ],
      resultTable: [
        {
          name: 'sku_details',
          type: 'sku',
          uiField: {
            uiLabel: 'SKU Details',
            uiType: 'custom',
            uiOrder: '1',
            metadata: {
              widget: 'sku',
              toolTipText: '',
              tableColumnName: 'SKU Details',
              columnTitleKey: 'name',
              columnAsinKey: 'product_id',
              columnImageUrlKey: 'image_url',
              columnAsinHoverText: '',
              columnDisableProductLink: true,
              //   coloumnProductUrlKey: 'product_url',
              showOnUi: true,
              isFixed: false
            }
          }
        },
        {
          name: 'Remove all',
          type: 'custom',
          uiField: {
            uiLabel: 'Remove all',
            uiType: 'custom',
            uiOrder: '2',
            customStyles:
              'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
            clickHeader: that.deleteAllAsinsFromTable,
            metadata: {
              toolTipText: 'Remove Sku',
              toolTipPosition: 'left',
              widget: 'icon',
              tableColumnName: 'na',
              showOnUi: true,
              isFixed: false,
              iconClickEvent: that.removeFromSelection,
              displayIcon: 'cross',
              iconSize: 'small',
              suppressSizeToFit: true,
              width: 110
            }
          }
        }
      ]
    }
  }
});

export default {
  target: criteoConfigs,
  meijer: criteoConfigs,
  costco: criteoConfigs,
  freshdirect_criteo: criteoConfigs,
  shipt_criteo: criteoConfigs,
  walmart_criteo: criteoConfigs,
  walgreen_criteo: criteoConfigs,
  cvs_criteo: criteoConfigs,
  asda_criteo: criteoConfigs,
  ulta_criteo: criteoConfigs,
  albertsons_criteo: criteoConfigs,
  ubereats_criteo: criteoConfigs,
  target_rms: criteoConfigs
};
