<template>
  <section
    class="u-bg-color-grey-white shadows u-border-radius-s u-display-flex u-flex-justify-content-space-between u-flex-direction-column"
  >
    <section
      :class="['u-spacing-pb-' + bottomPadding, 'u-spacing-pt-' + topPadding]"
      class="u-spacing-ph-l u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-position-relative"
    >
      <section class="u-display-flex u-flex-align-items-center">
        <div
          class="u-font-size-3 u-font-weight-600 u-color-grey-lighter u-spacing-mr-s"
        >
          {{ title }}
        </div>
        <slot name="post-title" />
      </section>
      <section class="u-display-flex u-flex-align-items-center">
        <slot name="pre-filters" />
        <component
          :is="customComponent.component"
          v-for="(customComponent, index) in customComponents"
          :key="index"
          :class="customComponent.class"
          v-bind="customComponent.props"
          v-on="customComponent.events"
        />
        <div
          v-for="(actionIcon, index) in actionIcons"
          :key="customComponents.length + index"
          class="u-color-grey-lighter u-cursor-pointer hover-class u-spacing-pl-l u-display-flex u-flex-justify-content-center u-flex-align-items-center"
        >
          <div
            v-if="!actionIcon.loading"
            v-tippy="{ placement: 'top', arrow: false }"
            :title="actionIcon.tippyTitle"
            class="u-display-flex u-flex-align-items-center u-flex-direction-column"
            @click="$emit(actionIcon.emit, index)"
          >
            <rb-icon
              :class="[
                actionIcon.error ? 'u-color-red-base' : 'u-color-grey-lighter'
              ]"
              :icon="actionIcon.icon"
            />
            <div
              v-if="actionIcon.subText"
              data-cy="actionTitle"
              class="u-font-size-8 u-spacing-mt-xs"
            >
              {{ actionIcon.subText }}
            </div>
          </div>
          <span v-else>
            <loader
              size="20px"
              class="u-bg-color-grey-white"
              :loading="true"
              :color="'#007cf6'"
            />
          </span>
        </div>
      </section>
    </section>
    <div
      v-show="load"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-show="!load && error"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-show="!load && !error && noData"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>No Data</p>
    </div>
    <div
      v-show="isBodyRendered"
      class=""
    >
      <div
        ref="body"
        :class="[
          'u-spacing-ph-' + horizontalPadding,
          'u-spacing-pb-' + bodyBottomPadding
        ]"
      >
        <slot name="body" />
      </div>
    </div>
    <div
      v-if="!load && !error"
      class="u-font-size-6 u-spacing-pv-s u-width-100 u-spacing-ph-l u-display-flex u-flex-align-items-center u-color-grey-base u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <div class="u-width-100 u-display-flex u-flex-align-items-center">
        <div
          v-if="showLastUpdatedDate"
          class="no-wrap u-color-grey-x-light u-font-size-7"
        >
          Last Updated: {{ lastUpdatedDate }}
        </div>
        <realtimeFooter
          v-if="!showLastUpdatedDate && getRealtimeMetadata.realtime"
          slot="footer"
          class="no-wrap u-color-grey-x-light u-font-size-7"
          :last-updated="getRealtimeMetadata.lastUpdated"
          @onRefresh="triggerRefresh"
        />
        <slot name="footer-right" />
      </div>
    </div>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import TextChip from '@/pages/campaign-management/components/text-chip.vue';
import SelectWithText from '@/components/pages/insights/amazon/share-of-voice/molecules/select-with-text.vue';
import Legends from '@/pages/campaign-management/components/legends.vue';
import realtimeFooter from '@/components/ams/campaigns/components/realtimeFooter.vue';

export default {
  components: {
    loader,
    TextChip,
    SelectWithText,
    Legends,
    realtimeFooter
  },
  props: {
    showLastUpdatedDate: {
      type: Boolean,
      default: true
    },
    getRealtimeMetadata: {
      type: Object,
      default: () => {
        return {};
      }
    },
    topPadding: {
      type: String,
      default: 'l'
    },
    customComponents: {
      type: Array,
      default: () => []
    },
    intermediateHeight: {
      type: Number,
      default: 332
    },
    hasHierarchy: {
      type: Boolean,
      default: true
    },
    bodyBottomPadding: {
      type: String,
      default: 'l'
    },
    horizontalPadding: {
      type: String,
      default: 'l'
    },
    lastUpdatedDate: {
      type: String,
      default: null
    },
    filters: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    },
    actionIcons: {
      type: Array,
      default: () => []
    },
    load: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    bottomPadding: {
      type: String,
      default: 'm'
    }
  },
  computed: {
    isBodyRendered() {
      return !(this.load || this.noData || this.error);
    }
  },
  methods: {
    handleOptionsSelect(payload) {
      this.$emit('optionSelect', payload);
    },
    triggerRefresh() {
      this.$emit('refreshTable');
    }
  }
};
</script>

<style lang="css" scoped>
.hover-class:hover {
  opacity: 0.7;
}
.shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.no-wrap {
  word-wrap: normal;
  white-space: nowrap;
}
.create-campaign-button {
  left: 2.4rem;
}
</style>
