<template>
  <div
    class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-p-m u-spacing-mh-s u-height-100 u-width-100"
  >
    <div
      v-if="loading"
      class="u-display-flex"
    >
      <Loader
        :loading="loading"
        class="u-bg-color-grey-white u-spacing-mr-m"
        color="#3fadf7"
        size="1.6rem"
        thickness=".2rem"
      />
      <span class="u-font-size-6"
        >Kindly hold on. Loading <br />may take up to 20 secs.</span
      >
    </div>
    <div
      v-else-if="apiFailed"
      class="u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <span class="u-font-size-6">Error, Try later!</span>
    </div>
    <div v-else-if="data && data.length === 0">
      <span class="u-font-size-6">No Brands Available</span>
    </div>
    <div
      v-else
      class="u-width-100 u-display-flex u-flex-direction-row u-flex-align-items-center u-flex-justify-content-space-around"
    >
      <div
        v-for="(brandElement, index) in data"
        :key="index"
        class="u-display-flex u-spacing-mb-m u-flex-direction-row u-flex-justify-content-center"
        :class="`brand-rank-${brandElement.brandRank}`"
      >
        <span
          v-if="brandElement.brandRank !== 1 || !showCrownForTopBrand"
          class="u-font-size-6 u-position-absolute u-text-align-center u-color-grey-white u-icon-size-4"
          >{{ brandElement.brandRank }}</span
        >
        <span v-else>
          <img
            src="https://cdn.rboomerang.com/cva/Leader_Crown.svg"
            alt="leader"
            class="u-icon-size-3 u-flex-alignitems-center u-position-absolute crown-image"
          />
        </span>
        <div
          v-if="showTooltipWithBrandData"
          v-tippy="tippyOptions(index)"
        >
          <div
            :id="`${compId}-${index}`"
            class="u-display-none"
          >
            <div class="u-spacing-mb-s max-w-md w-full u-text-case-title">
              {{ formatBrandName(brandElement.brandName) }}
            </div>
            <span class="u-white-space-no-wrap">
              <span class="u-color-grey-light u-spacing-mr-xs">
                {{ metricName }}:</span
              >
              <metric
                :hide-zero="false"
                size="xs"
                class="u-display-inline-flex"
                :config="metricConfig"
                :data="{
                  tag1: brandElement.tag1,
                  tag2: brandElement.tag2
                }"
              />
            </span>
          </div>
          <img
            v-if="!initials[brandElement.brandName]"
            :src="brandElement.imgUrl"
            class="brand-image u-spacing-p-xs u-position-relative u-spacing-mt-m"
            :class="{ client_flag: brandElement.clientFlag === 'client' }"
            :style="{
              width: brandImageSizes[brandElement.brandRank - 1] + 'rem',
              height: brandImageSizes[brandElement.brandRank - 1] + 'rem'
            }"
            :alt="brandElement.brandName"
            @error="handleNoImage(brandElement.brandName)"
          />
          <div
            v-else
            class="fallback-image u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-4 u-text-case-upper u-font-weight-800 u-spacing-mt-m"
            :class="{ client_flag: brandElement.clientFlag === 'client' }"
            :style="{
              width: brandImageSizes[brandElement.brandRank - 1] + 'rem',
              height: brandImageSizes[brandElement.brandRank - 1] + 'rem'
            }"
          >
            {{ initials[brandElement.brandName] }}
          </div>
        </div>
        <div
          v-else
          v-tippy="{ placement: 'top' }"
          :title="formatBrandName(brandElement.brandName)"
          class="u-display-block"
        >
          <img
            v-if="!initials[brandElement.brandName]"
            :src="brandElement.imgUrl"
            class="brand-image u-spacing-p-xs u-position-relative u-spacing-mt-m"
            :class="{ client_flag: brandElement.clientFlag === 'client' }"
            :alt="brandElement.brandName"
            @error="handleNoImage(brandElement.brandName)"
          />
          <div
            v-else
            class="fallback-image u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-4 u-text-case-upper u-font-weight-800 u-spacing-mt-m"
            :class="{ client_flag: brandElement.clientFlag === 'client' }"
          >
            {{ initials[brandElement.brandName] }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showCompetitors"
      class="u-display-flex u-width-50"
    >
      <div
        v-if="showTextOnHover"
        class="cellHover u-font-weight-600 u-spacing-ml-m u-font-size-7 u-color-blue-base"
        @click.stop="onHoverParams.hoverClickFunction"
      >
        <span class="u-spacing-ml-xs hover-span">
          {{ hoverText }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Loader from '@/components/basic/loader.vue';
import metric from '@/components/basic/metric.vue';
export default {
  name: 'BrandRankCell',
  components: { Loader, metric },
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    apiFailed: {
      type: Boolean,
      default: false
    },
    metricName: {
      type: String,
      required: true
    },
    brandImageSizes: {
      type: Array,
      default: () => [5.5, 5, 4]
    },
    onHoverParams: {
      type: Object,
      default: () => {}
    },
    showCrownForTopBrand: {
      type: Boolean,
      default: false
    },
    showCompetitors: {
      type: Boolean,
      default: true
    },
    showTooltipWithBrandData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initials: {},
      id: null,
      metricConfig: {
        invertTag2: false,
        tag1Unit: {
          suff: '%',
          roundoff: 2
        },
        tag2Unit: {
          suff: '%',
          roundoff: 2
        }
      }
    };
  },
  computed: {
    compId() {
      return 'tooltip-' + this.id;
    },
    showTextOnHover() {
      return this.onHoverParams?.showHoverText || false;
    },
    hoverText() {
      return this.onHoverParams?.hoverText || '';
    }
  },
  created() {
    this.id = this._uid;
  },
  methods: {
    handleNoImage(brandName) {
      const initials = brandName
        .split(' ')
        .map((item) => item[0])
        .slice(0, 2)
        .join('');
      Vue.set(this.initials, brandName, initials);
    },
    formatBrandName(brandName) {
      return brandName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    tippyOptions(index) {
      return {
        html: '#tooltip-' + this.id + '-' + index,
        placement: 'top-left',
        maxWidth: 'none',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            },
            hide: {
              enabled: false
            },
            flip: {
              enabled: true
            }
          }
        },
        theme: 'light'
      };
    }
  }
};
</script>

<style lang="css" scoped>
.brand-rank-1 .brand-image,
.brand-rank-1 .fallback-image {
  background-color: #f1f8f2;
  border-color: #3da95d;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  object-fit: contain;
  color: #3da95d;
}
.brand-rank-1 .brand-image.client_flag,
.brand-rank-1 .fallback-image.client_flag {
  border-color: #007cf6;
  color: #007cf6;
  border-width: 4px;
}
.brand-rank-1 span {
  background-color: #3da95d;
  top: 10%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
}
.brand-rank-1 .crown-image {
  top: 12%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
  background-color: #f1f8f2;
  transform: translate(100%, -50%);
}
.brand-rank-2 .brand-image,
.brand-rank-2 .fallback-image {
  background-color: #fff8eb;
  border-color: #fea800;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  object-fit: contain;
  color: #fea800;
}
.brand-rank-2 .brand-image.client_flag,
.brand-rank-2 .fallback-image.client_flag {
  border-color: #007cf6;
  color: #007cf6;
  border-width: 4px;
}
.brand-rank-2 span {
  background-color: #fea800;
  top: 10%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
}
.brand-rank-2 .crown-image {
  top: 12%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
  background-color: #fff8eb;
  transform: translate(100%, -50%);
}
.brand-rank-3 .brand-image,
.brand-rank-3 .fallback-image {
  background-color: #fbebfd;
  border-color: #bd11e0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  object-fit: contain;
  color: #bd11e0;
}
.brand-rank-3 .brand-image.client_flag,
.brand-rank-3 .fallback-image.client_flag {
  border-color: #007cf6;
  color: #007cf6;
  border-width: 4px;
}
.brand-rank-3 span {
  background-color: #bd11e0;
  top: 10%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
}
.brand-rank-3 .crown-image {
  top: 12%;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.5rem;
  background-color: #fbebfd;
  transform: translate(100%, -50%);
}

.cellHover .hover-span {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 4px;
  border-width: 1px;
  border: 1px solid #007cf6;
}
.cellHover:hover .hover-span {
  color: #fff;
  background-color: #007cf6;
}
</style>
