<template>
  <div
    v-if="metadataLoad || load"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metadataError || error"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <div
    v-else-if="noData"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <p>No data</p>
  </div>
  <cardContainer
    v-else
    :title="computeTitle"
    :filters="computedFilterConfig"
    :action-icons="computedActionIcons"
    :last-updated-date="lastUpdatedDate"
    :bottom-padding="null"
    :show-roll-up="showRollUp"
    @download="handleDownload"
    @optionSelect="handleOptionSelect"
  >
    <template
      slot="body"
      slot-scope="{}"
    >
      <section class="u-display-flex u-spacing-pb-m u-width-100">
        <Chart-legends
          v-for="(item, index) in computedSelectedOptions"
          :key="index"
          :selected-metric-limit="item.length"
          :search-enable-threshold="20"
          :hide-zero="false"
          :metrics-max-width="'220px'"
          :component-width="null"
          :metrics-list="item.metricsList"
          :metric-display-list="computedSelectedOptions[index] || {}"
          :default-select-metric="computedSelectedOptions[index] || {}"
          :metric-config="metricConfig"
          :metric-data="metricData"
          :color-object="colorMap.brandToColor"
          :disable-add-new-metric="true"
          :show-star="item[index] && item[index].showStar && showStar"
          :icon-type="'star'"
          :show-custom-tooltip="true"
          @addMetric="handleAddMetric"
          @removedMetric="handleRemovedMetric"
        />
      </section>
      <section
        class="u-spacing-ph-m u-border-width-s u-border-left u-border-right u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-radius-s"
      >
        <chart
          :config="computedChartConfig"
          :data="chartData"
        />
      </section>
      <div
        v-if="showRollUpInfo"
        class="u-font-size-7 u-display-flex u-flex-align-items-flex-end u-spacing-mt-xs"
      >
        <rb-icon
          icon="info-circle-fill"
          class="rb-icon--small u-color-grey-lighter u-spacing-mr-xs"
        />{{ rollupText }}
      </div>
    </template>
    <template slot="footer-right">
      <div
        v-if="footerStar"
        class="u-display-flex u-flex-align-items-center market-share-chart-graph-footer-right"
      >
        <div class="content-seperator-footer" />
        <rb-icon
          icon="star"
          class="u-flex-0 rb-icon--medium u-color-grey-lighter"
        />
        <div>Your Brand</div>
      </div>
    </template>
  </cardContainer>
</template>

<script>
import selectWithText from '../molecules/select-with-text.vue';
import titleSelect from '../molecules/title-select';
import cardContainer from '../organisms/card-container';
import chart from '@/components/basic/chart';
import loader from '@/components/basic/loader';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  downloadFile,
  convertDecimalLessThanZero
} from '@/components/pages/skuDetails/helper.js';
import HttpService from '@/utils/services/http-service';
import ChartLegends from '@/components/basic/chart-legends.vue';
import {
  filterMyBrandsData,
  appendAnchorBrandCondition,
  getAnchorFilterPayload,
  tooltipRollupWidget
} from './common';
import {
  getDateRange,
  enumerateDaysBetweenDates
} from '@/utils/helpers/date.js';
import { flattenArrayOfArray } from '@/utils/helpers/utils.js';
import {
  getTimeseriesData,
  getNumberSign,
  appendPartialArrays,
  filterResponse
} from './dashboard-service-utils';
import { MY_BRANDS } from './constants';
import {
  colorPalleteIndexed,
  modifyPayloadFor3p,
  constants,
  getDisplayByShareType,
  getDisplayByLabel,
  isMarketShareLite,
  categoryConstants
} from '@/components/pages/insights/amazon/market-share/utils.ts';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {
    selectWithText,
    titleSelect,
    chart,
    cardContainer,
    loader,
    ChartLegends
  },
  props: {
    v2: {
      type: Boolean,
      default: false
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      default: null
    },
    unsavedState: {
      type: Object,
      default: () => ({})
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    widgetName: {
      type: String,
      default: null
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      default: null
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    showStar: {
      type: Boolean,
      default: false
    },
    footerStar: {
      type: Boolean,
      default: false
    },
    benchmarkBrands: {
      type: Object,
      default: undefined
    },
    globalSettings: {
      type: Object,
      default: () => ({})
    },
    showRollUp: {
      type: Boolean,
      default: true
    },
    showRollUpInfo: {
      type: Boolean,
      default: false
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    },
    multiCrawl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastUpdatedDate: '',
      metricData: {},
      metricConfig: {},
      tooltipFormat: {},
      downloadApi: {
        error: false,
        loading: false
      },
      monthRollupThreshold: 89,
      weekRollupThreshold: 29,
      load: false,
      error: false,
      noData: false,
      internalColorMaps: {},
      isSOVPage: this.page === 'SOV' || this.page === 'SOV_INTRADAY',
      filtersConfig: [
        {
          preText: 'Roll up by:',
          emit: 'optionSelect',
          options: [],
          allOptions:
            this.page === 'SOV' || this.page === 'SOV_INTRADAY'
              ? [
                  {
                    name: 'Days',
                    apiFormat: 'DAY',
                    filterValue: 0
                  },
                  {
                    name: 'Weeks',
                    apiFormat: 'WEEK',
                    filterValue: 7
                  },
                  {
                    name: 'Months',
                    apiFormat: 'MONTH',
                    filterValue: 31
                  }
                ]
              : [
                  {
                    name: 'Weeks',
                    apiFormat: 'WEEK',
                    filterValue: 7
                  }
                ]
        }
      ],
      actionIcons: isMarketShareLite()
        ? []
        : [
            {
              emit: 'download',
              icon: 'download',
              loading: false,
              error: false
            }
          ],
      selectedBrandsList: [],
      brandDetailsIndex: 0,
      timeSeriesIndex: 2,
      maxNumberTrendlines: 3,
      clientApiData: [],
      compApiData: [],
      metadata: {},
      chartData: [],
      selectedOptions: []
    };
  },
  computed: {
    pageName() {
      return isMarketShareLite()
        ? categoryConstants.mslPage
        : categoryConstants.page;
    },
    colorMap() {
      if (this.externalColorMaps.brandToColor) {
        return this.externalColorMaps;
      } else if (this.internalColorMaps.brandToColor) {
        return this.internalColorMaps;
      } else {
        return {};
      }
    },
    computedFilterConfig() {
      const filterConfig = JSON.parse(JSON.stringify(this.filtersConfig));
      const selectedFilterConfig = this.isSOVPage
        ? this.getUnsavedWidgetMetadata()?.selectedRollup
        : 'WEEK';
      filterConfig[0].selected = this.findSelectedRollup(selectedFilterConfig);
      return filterConfig;
    },
    computedActionIcons() {
      const actionIcons = JSON.parse(JSON.stringify(this.actionIcons));
      if (this.actionIcons.length) {
        if (this.isSOVPage) {
          actionIcons[0].tippyTitle =
            'Download Excel with day-level Share of Voice for the selected brands and time period';
        } else {
          actionIcons[0].tippyTitle = `Download Excel with week-level Market Share for the selected ${this.globalSettings?.settingValue?.viewBy?.entityType} and time period`;
        }
      }
      return actionIcons;
    },
    computeTitle() {
      let title = '';
      if (this.isSOVPage) {
        title = 'BENCHMARK BRANDS BY SHARE OF VOICE';
      } else {
        title = 'CATEGORY SHARE TREND';
      }
      return title;
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metadataError() {
      return this?.dataService?.metdataError;
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    rollupText() {
      return `This Chart is rolled up by ${this.getUnsavedWidgetMetadata()?.selectedRollup?.toLowerCase()}s`;
    },
    computedSelectedOptions() {
      const accessKey = this.getAccessKey();
      const data = this.selectedOptions.filter((item, index) => {
        let targetMetrics = [];
        // Combining client and comp data for Market Share
        if (this.page === this.pageName) {
          targetMetrics = [...this.clientApiData, ...this.compApiData];
        } else {
          targetMetrics = index === 0 ? this.clientApiData : this.compApiData;
        }
        if (!targetMetrics.length) {
          return false;
        }
        item.metricsList = targetMetrics;
        item.name = item.entityValue;
        item.color = this.externalColorMaps?.brandToColor?.[item.name];
        item.value =
          item?.data?.[item?.data?.length || this.timeSeriesIndex]?.RESULT?.[
            accessKey
          ];
        return item;
      });
      return data;
    },
    computedChartConfig() {
      return {
        chartOptions: {
          data: {
            colors: this?.externalColorMaps?.brandToColor || {}
          },
          legend: false,
          type: 'line',
          xFormat: '%Y-%m-%d',
          tooltipTitle: this.isSOVPage
            ? undefined
            : tooltipRollupWidget(
                this.getUnsavedWidgetMetadata()?.selectedRollup?.toLowerCase() +
                  's' || 'weeks',
                this.metadata?.calendar?.max_date
              ),
          timeseries: this.isSOVPage ? 'feed_date' : 'week_start_date',
          point_format: {},
          xDataKey: 'x',
          size: { height: 94 },
          line: { connectNull: true },
          axis: {
            x: {
              show: true
            }
          },
          tooltip_format: this.tooltipFormat
        }
      };
    }
  },
  watch: {
    externalColorMaps(newExternalColorMap) {
      if (!this.load) {
        const newChartData = this.appendColorToChart(
          this.chartData,
          newExternalColorMap.brandToColor
        );
        this.$nextTick(() => {
          this.chartData = newChartData;
        });
      }
    },
    internalColorMaps(newExternalColorMap) {
      if (!this.load) {
        const newChartData = this.appendColorToChart(
          this.chartData,
          newExternalColorMap.brandToColor
        );
        this.$nextTick(() => {
          this.chartData = newChartData;
        });
      }
    },
    globalSettings: {
      handler(newVal, oldVal) {
        this.init();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.setInitialStates();
    this.init();
  },
  methods: {
    getDevMetadata() {
      return {
        metadata: {
          sharesKey: {
            suffix: !this.v2 ? 'share_percentage' : ''
          },
          pvpKey: {
            suffix:
              this.page === this.pageName && getDisplayByShareType() === 'Sales'
                ? 'PVP_'
                : 'PVP_DIFF_'
          }
        }
      };
    },
    handleAddMetric(payload) {
      const oldTrendlines = this.getChartDataTrendlines();
      const xAxis = this.getXAxis(
        this.isSOVPage
          ? this.getGlobalWhereClause.date_range?.from
          : this.globalSettings?.where?.date?.from,
        this.isSOVPage
          ? this.getGlobalWhereClause.date_range?.to
          : this.globalSettings?.where?.date?.to,
        this.computedFilterConfig[0].selected.name.toLowerCase()
      );
      const trendlinesFromPayload = getTimeseriesData(
        xAxis,
        payload.name,
        payload,
        payload?.data?.length - 1
      );
      this.setChartDataTrendlines([...oldTrendlines, trendlinesFromPayload]);
      this.selectedBrandsList = this.selectedBrandsList.map((item) => {
        if (item === ':placeholder') {
          item = payload.name;
        }
        return item;
      });
    },
    handleRemovedMetric(payload) {
      const oldTrendlines = this.getChartDataTrendlines();
      const filteredTrendlines = oldTrendlines.filter(
        (item) => item[0] !== payload.key
      );
      this.selectedBrandsList = this.selectedBrandsList.map((item) => {
        if (item === payload.name) {
          return ':placeholder';
        }
        return item;
      });
      this.setChartDataTrendlines(filteredTrendlines);
    },
    replaceTrendLine(oldSelection, trendData) {
      const trendlinesData = this.getChartDataTrendlines();
      const newChartData = trendlinesData.map((item) => {
        if (this.replacementCondition(item, oldSelection)) {
          return trendData;
        }
        return item;
      });
      this.setChartDataTrendlines(newChartData);
    },
    replacementCondition(currentData, oldSelection) {
      if (currentData[0] === oldSelection.name) {
        return true;
      }
    },
    getTooltipFormat(data) {
      const newTooltipFormat = {};
      const suffix =
        this.page === this.pageName && getDisplayByShareType() === 'Sales'
          ? ''
          : '%';
      data.forEach((item) => {
        newTooltipFormat[item.name] = { suff: suffix };
      });
      return newTooltipFormat;
    },
    getUnsavedSettings() {
      if (this.isSOVPage) {
        return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
          .settings;
      } else {
        return {
          selectedPlacement: `${this.globalSettings?.settingValue?.displayShareIn?.metricsList}`
        };
      }
    },
    getCurrentUnsaved() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'][
        this.widgetName
      ];
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIcons = actionIconsCopy;
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const metric = this.getUnsavedSettings().selectedPlacement;
          const { downloadApiTemplate } = this.metadata;
          const { endPoint, request, service } = downloadApiTemplate;
          const requestCopy = this.isSOVPage
            ? this.createDataRequestPayload(request, metric)
            : this.createDataRequestPayloadForMarketShare(request, metric);
          const selection = this.selectedBrandsList[0];
          requestCopy.widget = this.widgetName;
          requestCopy.page = this.page;
          if (this.isSOVPage) {
            requestCopy.operations.outerWhereClause.dimensionNameValueList =
              this.selectedBrandsList.map((item) => ({
                dimensionName: 'brand_by_client_flag',
                dimensionValue: item
              }));
            requestCopy.entityType =
              selection === 'My Brands' ? 'brand_by_client_flag' : 'brand';
          }
          const { data } = await HttpService.post(service, requestCopy, {
            append: endPoint
          });
          downloadFile(data.url);
          resolve();
        } catch (e) {
          reject(e);
          console.error('e', e);
        }
      });
    },
    appendColorToChart(data, colors) {
      if (Array.isArray(data)) {
        return {
          data: data,
          colors: colors
        };
      } else {
        return {
          ...data,
          colors: colors
        };
      }
    },
    getUnsavedWidgetMetadata() {
      return this.unsavedState?.[this.widgetName] || {};
    },
    setInitialStates() {
      const localInitalState = {};
      const unsavedState = this.getCurrentUnsaved() || {};
      const initialState = this.setSavedState() || {};
      const defaultRollup =
        initialState.selectedRollup || unsavedState.selectedRollup;
      localInitalState.selectedRollup = defaultRollup;
      this.pushRollupToState(defaultRollup);
    },
    setSavedState() {
      const localInitalState = {};
      const initialState = this.initialState?.[this.widgetName] || {};
      const defaultState = this.getDefaultState();
      const defaultRollup =
        initialState.selectedRollup || defaultState.selectedRollup;
      localInitalState.selectedRollup = defaultRollup;
      this.pushRollupToState(defaultRollup, 'pushToInitialState');
      return localInitalState;
    },
    getDefaultState() {
      return {
        selectedRollup: 'WEEK'
      };
    },
    handleOptionSelect(payload) {
      const { selection } = payload;
      if (selection && selection[0]) {
        this.pushRollupToState(selection[0].apiFormat);
        this.getData();
      }
    },
    getPvpKey(metric, metadata) {
      const sharesKey = metadata.sharesKey.suffix;
      const pvpDiff = metadata.pvpKey.suffix;
      if (sharesKey.length > 0) {
        return `${pvpDiff}${metric}_${sharesKey}`;
      } else {
        return `${pvpDiff}${metric}`;
      }
    },
    getLocalWhereClause() {
      const globalWhereClause = this.getGlobalWhereClause;
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause.time_range.startTime;
        where.time.to = globalWhereClause.time_range.endTime;
        where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
        where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      }
      const unsavedSettings = this.getUnsavedSettings();
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedType,
        this.types
      );
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      const { anchoredBrand } = this.getUnsavedSettings();
      if (appendAnchorBrandCondition(anchoredBrand)) {
        where.dimensionNameValueList.push(
          getAnchorFilterPayload(anchoredBrand.title)
        );
      }
      where.dimensionNameValueList.push(
        ...(globalWhereClause.dimensionNameValueList || [])
      );
      return where;
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.lastUpdatedDate = moment(this.metadata?.calendar?.max_date).format(
          'LL'
        );
        const { date } = this.getLocalWhereClause();
        const { from, to } = date;
        this.setRollUpAvailableOptions(from, to);
        // this.selectRollup();
        if (this.isSOVPage) {
          this.getData();
        } else {
          this.getMarketShareData();
        }
      } catch (e) {
        console.error('e', e);
      }
    },
    createDataRequestPayloadForMarketShare(request) {
      const settings = this.globalSettings?.settingValue;
      const whereClause = cloneDeep(this.globalSettings?.where);
      const requestCopy = JSON.parse(JSON.stringify(request));
      requestCopy.entityType = settings?.viewBy?.entityType;
      requestCopy.metricsList = [
        this.metadata.metrics[settings?.displayShareIn?.metricsList].name
      ];
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.dimensionNameValueList = whereClause?.dimensionNameValueList;
      where.date.from = whereClause?.date?.from;
      where.date.to = whereClause?.date?.to;
      where.pvpDate.from = whereClause?.pvpDate?.from;
      where.pvpDate.to = whereClause?.pvpDate?.to;
      if (this.multiCrawl) {
        where.time.from = whereClause?.time?.from;
        where.time.to = whereClause?.time?.to;
        where.pvpTime.from = whereClause?.pvpTime?.from;
        where.pvpTime.to = whereClause?.pvpTime?.to;
      }
      requestCopy.where = where;
      (requestCopy.operations.orderByList = [
        {
          dimension: `${
            this.metadata.metrics[settings?.displayShareIn?.metricsList].name
          }`,
          direction: 'DESC'
        }
      ]),
        (requestCopy.operations.timeseriesRollupBy =
          this.findSelectedRollup('WEEK').apiFormat);
      modifyPayloadFor3p(requestCopy);
      return requestCopy;
    },
    createDataRequestPayload(request, metric) {
      const unsavedState = this.getCurrentUnsaved();
      const requestCopy = JSON.parse(JSON.stringify(request));
      requestCopy.metricsList = [metric];
      const where = this.getLocalWhereClause();
      requestCopy.where = where;
      requestCopy.operations.orderByList = [
        ...(requestCopy?.operations?.orderByList || []),
        {
          dimension: this.isSOVPage
            ? `${metric}_share_percentage`
            : `${metric}`,
          direction: 'DESC'
        }
      ];
      requestCopy.operations.timeseriesRollupBy = this.findSelectedRollup(
        unsavedState.selectedRollup || 'WEEK'
      ).apiFormat;
      return requestCopy;
    },
    findSelectedRollup(selected) {
      return this.filtersConfig[0].allOptions.find(
        (item) => item.apiFormat === selected
      );
    },
    shuffleBrandsData(clientData, compData) {
      // if anchored brand is not My Brands then the first chip should only contain the current anchored brand and all the other client brands should be pushed to 2nd and 3rd chip
      const { anchoredBrand } = this.getUnsavedSettings();
      if (!anchoredBrand || anchoredBrand.title === MY_BRANDS) {
        return {
          clientData,
          compData
        };
      } else {
        if (anchoredBrand.clientFlag === 'client') {
          const clientCompData = filterMyBrandsData(clientData, anchoredBrand);
          const _compData = clientCompData.comp
            .concat(compData)
            .sort((a, b) => b.result - a.result);

          return {
            clientData: clientCompData.client,
            compData: _compData
          };
        } else {
          return {
            clientData: [],
            compData: []
          };
        }
      }
    },
    async getMarketShareData() {
      try {
        if (this.metadata.metrics === undefined) {
          return;
        }
        this.load = true;
        this.error = false;
        this.noData = false;
        const metric =
          this.globalSettings?.settingValue?.displayShareIn?.metricsList;
        const metricMeta = this.metadata?.metrics[metric];
        const { api } = metricMeta;
        const { request } = api;
        const requestCopy = this.createDataRequestPayloadForMarketShare(
          request,
          metric
        );
        let clientDataPromise;
        if (this.metadata?.metrics[metric]) {
          clientDataPromise = this.dataService.getData(
            requestCopy,
            this.metadata?.metrics[metric]?.api?.service,
            this.metadata?.metrics[metric]?.api?.endPoint
          );
        }
        const promisesResolved = await Promise.all([clientDataPromise]);
        const filteredApiData = promisesResolved[0];
        this.load = false;
        this.error = false;
        this.noData = false;
        const responseConcat = {
          entityData: [...(filteredApiData.entityData || [])]
        };
        const newColorMap = {};
        newColorMap.brandToColor = {};
        filteredApiData.entityData.forEach((data, index) => {
          newColorMap.brandToColor[data.entityValue] =
            colorPalleteIndexed[index];
        });
        this.internalColorMaps = newColorMap;
        if (!(promisesResolved[0]?.entityData || []).length) {
          this.noData = true;
        }
        const { clientApiData, compApiData } = this.splitIntoClientOrComp(
          responseConcat,
          metric
        );
        const { clientData: shuffledClientData, compData: shuffledCompData } =
          this.shuffleBrandsData(clientApiData, compApiData);
        this.clientApiData = shuffledClientData;
        this.compApiData = shuffledCompData;
        this.$emit('brandsData', {
          clientApiData: this.clientApiData,
          compApiData: this.compApiData
        });
        this.selectDefaultDropdowns();
        const xAxis = this.getXAxis(
          requestCopy.where?.date?.from,
          requestCopy.where?.date?.to,
          this.computedFilterConfig[0].selected.name.toLowerCase()
        );
        this.selectedBrandsList = this.getSelectedBrands();
        const newChartData = [
          xAxis,
          ...this.getSelectedOptionsChartFormattedData(xAxis)
        ];
        this.chartData = newChartData;
        this.tooltipFormat = this.getTooltipFormat([
          ...this.clientApiData,
          ...this.compApiData
        ]);
      } catch (e) {
        this.load = false;
        this.error = true;
        this.noData = false;
        console.error('e', e);
      }
    },
    async getData() {
      try {
        this.load = true;
        this.error = false;
        this.noData = false;
        const metric = this.getUnsavedSettings().selectedPlacement;
        const metricMeta = this.metadata.metrics[metric];
        const { api } = metricMeta;
        const { request } = api;
        const { clientBrandAPI } = this.metadata?.metadata;

        const requestCopy = this.createDataRequestPayload(request, metric);
        const clientBrandAPICopy = this.createDataRequestPayload(
          clientBrandAPI.request,
          metric
        );
        const apiDataPromise = this.dataService.getData(requestCopy);
        const clientDataPromise = this.dataService.getData(clientBrandAPICopy);
        const promisesResolved = await Promise.all([
          apiDataPromise,
          clientDataPromise
        ]);
        const { excludeBrands = [] } = this.metadata.metadata;
        const filteredApiData = filterResponse(
          promisesResolved[0],
          excludeBrands
        );
        const filteredClientData = filterResponse(
          promisesResolved[1],
          excludeBrands
        );

        this.load = false;
        this.error = false;
        this.noData = false;
        const responseConcat = {
          entityData: [
            ...(filteredApiData.entityData || []),
            ...(filteredClientData.entityData || [])
          ]
        };
        if (!(promisesResolved[0]?.entityData || []).length) {
          this.noData = true;
        }
        const { clientApiData, compApiData } = this.splitIntoClientOrComp(
          responseConcat,
          metric
        );
        const { clientData: shuffledClientData, compData: shuffledCompData } =
          this.shuffleBrandsData(clientApiData, compApiData);
        this.clientApiData = shuffledClientData;
        this.compApiData = shuffledCompData;
        this.$emit('brandsData', {
          clientApiData: this.clientApiData,
          compApiData: this.compApiData
        });
        this.selectDefaultDropdowns();
        const xAxis = this.getXAxis(
          requestCopy.where.date.from,
          requestCopy.where.date.to,
          this.computedFilterConfig[0].selected.name.toLowerCase()
        );
        this.selectedBrandsList = this.getSelectedBrands();
        const newChartData = [
          xAxis,
          ...this.getSelectedOptionsChartFormattedData(xAxis)
        ];
        this.chartData = newChartData;
        this.tooltipFormat = this.getTooltipFormat([
          ...this.clientApiData,
          ...this.compApiData
        ]);
      } catch (e) {
        this.load = false;
        this.error = true;
        this.noData = false;
        console.error('e', e);
      }
    },
    pushRollupToState(selectedRollup, stateKey = 'pushToUnsavedState') {
      const payload = { [this.widgetName]: { selectedRollup } };
      this.$emit(stateKey, payload);
    },
    selectRollup() {
      const { date } = this.getLocalWhereClause();
      const { from, to } = date;
      const startDate = moment(from);
      const endDate = moment(to);
      const diff = endDate.diff(startDate, 'days');
      let newSelection = null;
      this.setRollUpAvailableOptions(from, to);
      if (diff >= this.monthRollupThreshold) {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'MONTH'
        );
      } else if (diff >= this.weekRollupThreshold) {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'WEEK'
        );
      } else {
        newSelection = this.filtersConfig[0].options.find(
          (item) => item.apiFormat === 'DAY'
        );
      }
      this.$set(this.filtersConfig[0], 'selected', newSelection);
      this.pushRollupToState(newSelection.apiFormat);
    },
    setRollUpAvailableOptions(fromDate, toDate) {
      const startDate = moment(fromDate);
      const endDate = moment(toDate);
      const diff = endDate.diff(startDate, 'days');
      const { allOptions } = this.computedFilterConfig[0];
      const computedOptions = allOptions.filter((item) => {
        if (item.filterValue <= diff) {
          return item;
        }
      });
      this.$set(this.filtersConfig[0], 'options', computedOptions);
    },

    getXAxis(fromDate, toDate, interval) {
      if (this.isSOVPage) {
        const dateRange = getDateRange(fromDate, toDate, interval);
        return ['feed_date', ...dateRange];
      } else {
        let startDate = moment(fromDate);
        const endDate = moment(toDate);
        const intervalLowerCase = interval.toLowerCase();
        if (intervalLowerCase === 'weeks') {
          startDate = moment(startDate).startOf('isoWeek');
          startDate = moment(startDate).subtract(1, 'd');
        }
        const range = enumerateDaysBetweenDates(
          startDate,
          endDate,
          intervalLowerCase
        );
        return ['week_start_date', ...range.slice(1, range.length)];
      }
    },
    getResult(apiShareUnit) {
      let result = apiShareUnit.RESULT?.[apiShareUnit?.name];
      if (this.page === this.pageName && result < 1) {
        // we need number formatting only if we have a very small number e.g. 6e-10
        result = formatter(result, 'numeric', undefined, 2, 2);
      }
      return result;
    },
    getPvpObject(apiShareUnit, pvpKey) {
      const obj = {};
      obj.pvp = this.getPvp(apiShareUnit, pvpKey);
      obj.pvpDirection = getNumberSign(obj.pvp);
      obj.roundOff = 1;
      if (obj.pvp !== null && obj.pvp !== undefined) {
        if (obj.pvp <= 0.1 && obj.pvp >= 0) {
          obj.pvp = convertDecimalLessThanZero(obj.pvp);
          obj.roundOff = 2;
        } else {
          obj.pvp = Math.abs(obj.pvp);
        }
      }
      return obj;
    },
    getPvp(apiShareUnit, pvpKey) {
      return apiShareUnit.PVP?.[pvpKey];
    },
    splitIntoClientOrComp(apiData, metric) {
      const { metadata } = this.getDevMetadata();
      let clientDataIndex = 0;
      let compApiDataIndex = 0;
      const clientApiData = [];
      const compApiData = [];
      const pvpKey = this.getPvpKey(metric, metadata);
      for (const entityUnit of apiData.entityData) {
        entityUnit.clientType = entityUnit?.data?.[0]?.RESULT?.client_flag;
        const pvp = this.getPvpObject(
          entityUnit?.data?.[entityUnit?.data?.length - 1] || {},
          pvpKey
        );
        const result = this.getResult(
          entityUnit?.data?.[entityUnit?.data?.length - 1] || {}
        );
        entityUnit.name = entityUnit.entityValue;
        entityUnit.key = entityUnit.entityValue;
        entityUnit.tooltip = entityUnit.entityValue;
        entityUnit.title = entityUnit.entityValue;

        if (this.showCustomTooltip) {
          // entityUnit.icon = entityUnit.clientType === 'client' ? 'star' : ''
          entityUnit.icon = '';
          // entityUnit.preText = entityUnit.clientType === 'client' ? `Your ${entityUnit.data[1].name}:` : `Competitor ${entityUnit.data[1].name}:`
          const filteredData = entityUnit.data.filter(
            (el) => el.name !== constants.THREE_P_GROUP_FLAG
          );
          entityUnit.preText =
            entityUnit.clientType === 'client'
              ? `${filteredData[1].name}:`
              : `${filteredData[1].name}:`;
        }

        entityUnit.pvp = pvp.pvp;
        entityUnit.pvpDirection = pvp.pvpDirection;
        entityUnit.result = result;
        delete entityUnit.entityType;
        if (this.isSOVPage) {
          this.metricConfig[entityUnit.key] = {
            invertTag2: false,
            tag1Unit: { suff: '%', roundoff: 1 },
            tag2Unit: { suff: '%', roundoff: pvp?.roundOff || 1 }
          };
        } else {
          const suffix =
            this.page === this.pageName && getDisplayByShareType() === 'Sales'
              ? getDisplayByLabel() === 'Dollars'
                ? 'currency'
                : ''
              : '%';
          this.metricConfig[entityUnit.key] = {
            invertTag2: false,
            tag1Unit: { suff: suffix, roundoff: 2 },
            tag2Unit: { suff: '%', roundoff: 2 }
          };
        }
        this.metricData[entityUnit.key] = {
          tag1: result,
          tag2:
            pvp.pvp !== null && pvp.pvp !== undefined
              ? `${pvp.pvpDirection}${pvp.pvp}`
              : null
        };
        if (
          entityUnit?.data?.[this.brandDetailsIndex]?.RESULT?.client_flag ===
          'client'
        ) {
          entityUnit.dropdownIndex = clientDataIndex;
          entityUnit.showStar = true;
          clientApiData.push(entityUnit);
          clientDataIndex++;
        } else {
          entityUnit.dropdownIndex = compApiDataIndex;
          entityUnit.showStar = false;
          compApiData.push(entityUnit);
          compApiDataIndex++;
        }
      }
      return { clientApiData, compApiData };
    },
    getSelectedBrands() {
      const brandsData = this.selectedOptions.map((clientOrCompArray) => {
        return clientOrCompArray.map((item) => item.entityValue);
      });
      return flattenArrayOfArray(brandsData);
    },
    getSelectedOptionsChartFormattedData(xAxis) {
      const formattedChartData = this.selectedOptions.map(
        (clientOrCompArray) => {
          return clientOrCompArray.map((item) => {
            return getTimeseriesData(
              xAxis,
              item.name,
              item,
              item?.data?.length - 1
            );
          });
        }
      );
      return flattenArrayOfArray(formattedChartData);
    },
    getAccessKey() {
      const metric = this.getUnsavedSettings().selectedPlacement;
      return metric + '_share_percentage';
    },
    selectDefaultDropdowns() {
      this.selectedOptions = [];
      const clientData = [];
      const compData = [];
      let i = 0;
      while (i < this.maxNumberTrendlines) {
        const targetArray =
          i === 0 && this.clientApiData?.length
            ? this.clientApiData
            : this.compApiData;
        // not seperating the client and comp data for market share
        const targetStore =
          i === 0 && this.clientApiData?.length && this.page !== this.pageName
            ? clientData
            : compData;
        for (let j = 0; j < targetArray.length; j++) {
          if (!targetArray[j].selected) {
            targetArray[j].selected = true;
            targetStore.push(targetArray[j]);
            break;
          }
        }
        i++;
      }
      this.selectedOptions = [clientData, compData];
    },
    replaceElementAtIndex(toBeModifiedArray, editObject, editIndex) {
      return toBeModifiedArray.map((item, index) => {
        if (editIndex === index) {
          return editObject;
        }
        return item;
      });
    },
    getChartDataTrendlines() {
      if (Array.isArray(this.chartData)) {
        return this.chartData;
      } else {
        return this.chartData.data;
      }
    },
    setChartDataTrendlines(newTrendlines) {
      if (Array.isArray(this.chartData)) {
        this.chartData = newTrendlines;
      } else {
        this.chartData = {
          ...this.chartData,
          data: newTrendlines
        };
      }
    }
  }
};
</script>

<style scoped lang="css">
.padding-cancel {
  padding-bottom: 0px;
}
.intermediate-dimensions {
  height: 307px;
}
.dot {
  border-radius: 50%;
  height: 12px;
  width: 12px;
}
.chart-dimensions {
  max-height: 170px !important;
}
.max-width {
  max-width: 33.33%;
}
.margins:nth-child(3n) {
  margin-right: 0;
}
</style>
