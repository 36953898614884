import List from '@/components/ams/media-planner/list/index.vue';
import GeneratePlan from '@/components/ams/media-planner/generate-plan/index.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      path: '',
      name: 'Media Planner List',
      component: List,
      productRoles: ['MarketingIQ'],
      page: 'list',
      config: 'list',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'generate_plan',
      name: 'Generate Media Plan',
      component: GeneratePlan,
      productRoles: ['MarketingIQ'],
      page: 'generate_plan',
      config: 'generate_plan',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'edit_plan/:id?',
      name: 'Edit Media Plan',
      component: GeneratePlan,
      productRoles: ['MarketingIQ'],
      page: 'edit_plan',
      config: 'edit_plan',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '*',
      redirect: () => {
        return 'list';
      }
    }
  ]
};
