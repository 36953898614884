<template>
  <div
    ref="trigger"
    v-tippy="exportTooltipOptions"
  >
    <rb-icon
      class="rb-icon u-color-grey-lighter u-display-flex u-cursor-pointer"
      icon="share"
    />
    <div :id="exportModalId">
      <icon-popup-component
        class="u-width-100"
        :show-popup="openPopup"
        :header-title="'Export format'"
      >
        <div
          slot="body"
          class="u-display-flex u-flex-direction-row export-icons-container"
        >
          <div
            class="u-spacing-mr-s u-spacing-ml-m"
            @click="exportAsExcel"
          >
            <loader
              v-if="isDownloadingExcel"
              style="z-index: 100"
              :loading="loaderClass"
              :color="'#3684bb'"
            />
            <rb-icon
              v-if="!isDownloadingExcel"
              class="rb-icon--large u-cursor-pointer"
              icon="export-as-excel"
            />
          </div>
          <div
            class="u-spacing-mr-m u-spacing-ml-s"
            @click="exportAsImage"
          >
            <loader
              v-if="isDownloadingImage"
              style="z-index: 100"
              :loading="loaderClass"
              :color="'#3684bb'"
            />
            <rb-icon
              v-if="!isDownloadingImage"
              class="rb-icon--large u-cursor-pointer"
              icon="export-as-image"
            />
          </div>
        </div>
      </icon-popup-component>
    </div>
  </div>
</template>

<script>
import iconPopupComponent from '@/components/basic/dialog/iconPopupComponent.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import Loader from '@/components/basic/loader';
import { inject } from '@vue/composition-api';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    iconPopupComponent,
    Loader
  },
  props: {
    applyFormattingDownload: {
      type: Boolean,
      default: false
    },
    downloadFileCustomName: {
      type: String,
      default: ''
    },
    loaderClass: {
      type: String,
      default: ''
    }
  },
  setup() {
    const metadata = inject('metadata');
    const widgetRequestParams = inject('widgetRequestParams');
    return {
      metadata,
      widgetRequestParams
    };
  },
  data() {
    return {
      exportModalId: 'export-modal',
      openPopup: false,
      customDownloadEnable: false,
      isDownloadingExcel: false,
      isDownloadingImage: false
    };
  },
  computed: {
    exportTooltipOptions() {
      return {
        html: '#' + this.exportModalId,
        reactive: true,
        interactive: true,
        placement: 'left',
        theme: 'dropdown',
        trigger: 'click',
        onShow: this.onShow,
        onHide: this.onHide
      };
    }
  },
  beforeDestroy() {
    eventBus.$off('triggerExportImageSuccess');
  },
  methods: {
    onShow(instance) {
      this.tippyInstance = instance;
      this.openPopup = true;
    },
    onHide(instance) {
      this.openPopup = false;
    },
    exportAsExcel() {
      this.customDownloadEnable = false;
      this.download();
    },
    async download() {
      try {
        const metrics = this.metadata.metrics;
        const metricsArrayListForDownload = Object.values(metrics).map(
          (item) => item.name
        );
        this.widgetRequestParams[':metricsList'] = metricsArrayListForDownload;
        if (this.customDownloadEnable) {
          console.log('downloading sankey as image');
          this.isDownloadingImage = true;
          this.imageExportHandler();
          this.isDownloadingImage = false;
        } else {
          console.log('downloading sankey as excel');
          this.isDownloadingExcel = true;
          await dashUtils.download(
            this.metadata,
            this.widgetRequestParams,
            this.applyFormattingDownload,
            this.downloadFileCustomName
          );
          this.isDownloadingExcel = false;
        }
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
        console.log(error);
      }
      this.onHide();
    },
    exportAsImage() {
      this.customDownloadEnable = true;
      this.download();
    },
    imageExportHandler() {
      const elementSelector = '.rra-sankey-chart';
      const idToMount = 'date-range-for-export';
      this.handleOnClickExport(true, elementSelector, idToMount);
      const month = moment().format('MM');
      const day = moment().format('DD');
      const fileName = `Shortage_Invoices_Journey_Map_${month}/${day}.png`;
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'Image is being generated and will be downloaded shortly.',
        scale: 3,
        elementSelector,
        fileName,
        successEventName: 'triggerExportImageSuccess'
      };
      eventBus.$emit('triggerExportImage', config);
      eventBus.$on('triggerExportImageSuccess', () => {
        this.handleOnClickExport(false, elementSelector, idToMount);
      });
    },
    handleOnClickExport(flag, elementSelector, idToMount) {
      const elementToExport = document.querySelector(elementSelector);
      const dateFilterCopy = document.getElementById(idToMount);
      if (flag && elementToExport) {
        elementToExport.classList.add('save-as-png');
        const rightPanelHTML =
          this.$refs?.dashboard_inflator?.$refs?.['right-panel'];
        if (dateFilterCopy && rightPanelHTML) {
          dateFilterCopy.innerHTML = rightPanelHTML.innerHTML;
          dateFilterCopy.classList.add(...rightPanelHTML.classList);
        }
      } else {
        if (elementToExport) {
          elementToExport.classList.remove('save-as-png');
        }
        if (dateFilterCopy) {
          dateFilterCopy.innerHTML = '';
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.save-as-png.rra-sankey-chart {
  width: 26000px !important;
  max-width: 2600px !important;
}
.save-as-png .shortage_sankey_chart {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.save-as-png .sankey-box {
  overflow: visible !important;
}
.export-icons-container {
  padding-bottom: 10px;
}
</style>
