<template>
  <div
    class="u-display-flex u-height-100 u-position-relative u-flex-align-items-center"
  >
    <div class="u-flex-direction-column">
      <div
        v-if="title"
        class="u-font-size-5 u-font-weight-600 u-spacing-pv-m"
      >
        {{ title }}
      </div>
      <div
        :class="{
          'u-border-right u-border-color-grey-xxx-light u-border-width-s u-spacing-pr-xxl':
            radioConfigObj.type && showInputValue
        }"
      >
        <div
          v-for="(item, index) in radioConfig"
          :key="item.value"
          :class="[index != radioConfig.length - 1 ? 'u-spacing-pb-m' : '']"
        >
          <rb-radio
            v-model="radioValue"
            :native-value="item.value"
            class="u-font-size-5 u-width-100 u-spacing-mr-s"
            @input="onRadioUpdate"
          >
            <div>
              {{ item.title }}
            </div>
          </rb-radio>
          <div
            v-if="item.description"
            class="u-max-width-320px u-font-size-5 u-spacing-ph-l u-spacing-pt-s u-color-grey-lighter"
          >
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showInputValue && radioConfigObj.type === 'input'"
      class="u-height-100 u-flex-direction-column u-flex-justify-content-center u-spacing-ph-xxl u-display-flex"
      :class="[title && 'u-spacing-pt-xxl']"
    >
      <!-- Input box heading -->
      <InputBoxHeadingComponent :input-box-heading="inputBoxHeading" />

      <div class="u-display-flex">
        <span
          class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
        >
          {{ $currency }}
        </span>
        <input
          v-model="inputValue"
          type="number"
          data-cy="inputValue"
          class="rb-input currency-input u-display-flex u-flex-align-items-center"
          step="0.01"
          @input="onUpdate"
        />
      </div>
      <ErrorMessageComponent :error-message="errorMessage" />

      <slot name="below-right-side-input" />
    </div>
    <div
      v-else-if="showInputValue && radioConfigObj.type === 'dropdown'"
      class="u-height-100 u-display-flex u-flex-direction-column u-flex-justify-content-center u-spacing-ph-xxl"
      :class="[title && 'u-spacing-pt-xxl']"
    >
      <!-- Input box heading -->
      <InputBoxHeadingComponent :input-box-heading="inputBoxHeading" />

      <div class="u-display-flex">
        <div class="u-max-width-200px">
          <rb-select
            class="valueDropdown"
            :send-details="true"
            :on-close="optionSelected"
            :options="radioConfigObj && radioConfigObj.dropdown"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div
                class="u-display-flex u-text-overflow-ellipsis u-flex-direction-column u-spacing-p-s u-font-size-5"
                data-cy="selectedBidValue"
              >
                {{ selectedDropdown && selectedDropdown.label }}
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                data-cy="carrotDownIcon"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.label }}
              </div>
            </template>
          </rb-select>
        </div>
        <div
          v-if="selectedDropdown && selectedDropdown.value"
          class="u-spacing-pl-s"
        >
          <div
            class="u-display-flex u-flex-0 u-flex-align-items-center u-width-20"
            :class="{ percentBox: selectedDropdown.value === 'percentage' }"
          >
            <span
              v-if="selectedDropdown.value !== 'percentage'"
              class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
              data-cy="currency"
            >
              {{ $currency }}
            </span>
            <input
              v-model="inputValue"
              data-cy="inputValue"
              type="number"
              class="rb-input currency-input rb-width u-display-flex u-flex-align-items-center"
              step="0.01"
              @input="onUpdate"
            />
            <span
              v-if="selectedDropdown.value === 'percentage'"
              class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
            >
              %
            </span>
          </div>
        </div>
      </div>
      <ErrorMessageComponent :error-message="errorMessage" />
      <slot name="below-right-side-dropdown" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ErrorMessageComponent from '@/pages/campaign-management/retailers/criteo/line_items/ErrorMessage/index.vue';
const InputBoxHeadingComponent = Vue.component('input-box-heading-component', {
  props: {
    inputBoxHeading: {
      type: String,
      default: null
    }
  },
  template: `<div
        v-if="inputBoxHeading"
        class="u-font-size-5 u-font-weight-600 u-spacing-mb-s"
      >
        {{ inputBoxHeading }}
      </div>`
});

export default {
  components: {
    InputBoxHeadingComponent,
    ErrorMessageComponent
  },
  props: {
    title: {
      type: String,
      default: null
    },
    defaultRadioSelected: {
      type: String,
      default: 'test'
    },
    showInputValue: {
      type: Boolean,
      default: true
    },
    radioConfig: {
      type: Array,
      default: () => {
        return [
          {
            title: 'Test',
            value: 'test',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Test1',
            value: 'test1',
            type: 'input'
          }
        ];
      }
    },
    defaultInputValue: {
      type: Number,
      default: 0
    },
    inputBoxHeading: {
      type: String,
      default: null
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedDropdown: {},
      radioValue: null,
      inputValue: null
    };
  },
  computed: {
    radioConfigObj() {
      const radioObj = this.radioConfig.filter(
        (item) => item.value === this.radioValue
      );
      return radioObj.length && radioObj[0];
    }
  },
  watch: {
    radioConfigObj(newValue) {
      if (this.radioConfigObj.type === 'dropdown') {
        this.selectedDropdown =
          (this.radioConfigObj?.dropdown && this.radioConfigObj?.dropdown[0]) ||
          {};
      }
    }
  },
  created() {
    this.radioValue = this.defaultRadioSelected;
  },
  methods: {
    optionSelected(c, selectedOption) {
      if (selectedOption?.length) {
        this.selectedDropdown = selectedOption[0];
        this.onUpdate();
      }
    },
    onRadioUpdate() {
      this.inputValue = null;
      this.onUpdate();
    },
    onUpdate() {
      // This ensures that the value entered is not negative
      this.inputValue =
        !!this.inputValue && Math.abs(this.inputValue) >= 0
          ? Math.abs(this.inputValue)
          : null;
      this.$nextTick(() => {
        const updatedValues = {
          radioValue: this.radioValue,
          inputValue: this.inputValue,
          inputType: this.radioConfigObj?.type,
          dropdownValue:
            this.radioConfigObj.type === 'dropdown'
              ? this.selectedDropdown.value ||
                (this.radioConfig?.dropdown[0] &&
                  this.radioConfig?.dropdown[0]?.value)
              : null
        };
        this.$emit('onUpdate', updatedValues);
      });
    }
  }
};
</script>

<style lang="css" scoped>
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}

.rb-width {
  width: 48px;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  min-width: 90px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
</style>
