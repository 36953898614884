var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"donut-details-container"},[_c('div',{staticClass:"detail-item"},[_c('div',{staticClass:"left-label"},[(_vm.listItem.label === 'Needs your Attention')?_c('rb-icon',{staticClass:"u-spacing-mr-s",attrs:{"icon":"warning-triangle u-color-orange-base rb-icon--x-medium"}}):_c('rb-icon',{staticClass:"u-spacing-mr-s",attrs:{"icon":"amazon u-color-cyan-base rb-icon--x-medium"}}),_c('span',{staticClass:"u-font-size-4"},[_vm._v(_vm._s(_vm.listItem.label))])],1),_c('div',{staticClass:"right-label"},[_c('metric',{staticClass:"u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-center u-font-weight-600 u-font-size-4",attrs:{"size":'md',"split":false,"config":_vm.tagFooterConfig,"data":_vm.cardMetricData(_vm.listItem.keyName),"hide-zero":true}}),(_vm.isMetricZero())?_c('div',[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            placement: 'bottom-start',
            arrow: false,
            interactive: true,
            size: 'regular',
            distance: 5,
            maxWidth: '300px'
          }),expression:"{\n            placement: 'bottom-start',\n            arrow: false,\n            interactive: true,\n            size: 'regular',\n            distance: 5,\n            maxWidth: '300px'\n          }"}],class:{ 'cursor-not-allowed': _vm.isMetricZero(_vm.listItem.keyName) },attrs:{"title":("There are no cases " + (_vm.listItem.label === 'Needs your Attention'
              ? 'that needs your attention'
              : 'that have pending amazon action'))}},[_c('span',{staticClass:"view-details u-display-flex u-flex-align-items-center u-spacing-ml-m u-cursor-pointer",class:{ disabled: _vm.isMetricZero(_vm.listItem.keyName) },on:{"click":function($event){return _vm.handleItemClick(_vm.listItem.keyName)}}},[_c('rb-icon',{staticClass:"icon-view-details u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-xs",attrs:{"icon":'table-chart'}}),_c('span',{staticClass:"view-details-text u-font-weight-600 u-font-size-7 u-color-grey-lighter"},[_vm._v("View Details")])],1)])]):_c('div',[_c('span',{staticClass:"view-details u-display-flex u-flex-align-items-center u-spacing-ml-m u-cursor-pointer",class:{ disabled: _vm.isMetricZero(_vm.listItem.keyName) },on:{"click":function($event){return _vm.handleItemClick(_vm.listItem.keyName)}}},[_c('rb-icon',{staticClass:"icon-view-details u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-xs",attrs:{"icon":'table-chart'}}),_c('span',{staticClass:"view-details-text u-font-weight-600 u-font-size-7 u-color-grey-lighter"},[_vm._v("View Details")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }