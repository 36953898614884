<template>
  <section
    class="u-display-flex u-border-top u-border-bottom u-border-left u-border-right u-border-width-s u-border-color-grey-xxx-light u-border-radius-xs"
  >
    <div
      class="u-display-flex u-border-right u-border-width-s u-border-color-grey-xxx-light"
    >
      <div
        v-if="isSkuLoading"
        class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
      >
        <loader
          class="u-bg-color-grey-white"
          :loading="true"
          :color="'#007cf6'"
        />
      </div>
      <div
        v-else-if="isSkuError"
        class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
      >
        <p>Error Occurred. Please try again later</p>
      </div>
      <div
        v-else
        ref="container"
        class="max-width u-display-flex"
      >
        <rb-select
          class="u-max-width-100 u-spacing-mh-s u-spacing-mv-xs"
          :send-details="true"
          :options="skuOptions"
          :on-close="handleDropdownSelect"
        >
          <template slot="trigger">
            <skuWithAsin
              ref="sku-with-asin"
              :image-url="selectedSku.image_url"
              :asin-name="selectedSku.title"
              :asin="selectedSku.sku"
            />
          </template>
          <template
            slot="item"
            slot-scope="option"
          >
            <skuSelect
              class="dropdown-max-width"
              :image-url="option.image_url"
              :asin-name="option.title"
              :asin="option.sku"
            />
          </template>
        </rb-select>
      </div>
    </div>
    <section>
      <div
        class="u-spacing-pv-m u-spacing-ph-l u-border-top u-border-width-m active-metric"
        :style="{ 'border-top': '3px solid ' + sriBorderColor }"
      >
        <div class="u-spacing-mb-s u-color-grey-lighter u-font-size-4">
          {{ metricName }}
        </div>
        <metric
          :size="'l'"
          class=""
          :config="metricConfig"
          :data="metricData"
        />
      </div>
    </section>
  </section>
</template>

<script>
import skuWithAsin from '../atoms/sku-with-asin';
import skuSelect from '../atoms/sku-select';
import metric from '@/components/basic/metric';
import loader from '@/components/basic/loader';

export default {
  components: {
    skuWithAsin,
    skuSelect,
    metric,
    loader
  },
  props: {
    selectedSku: {
      type: Object,
      default: () => ({})
    },
    skuOptions: {
      type: Array,
      default: () => []
    },
    metricName: {
      type: String,
      default: null
    },
    metricData: {
      type: Object,
      default: () => ({})
    },
    metricConfig: {
      type: Object,
      default: () => ({})
    },
    isSriLoading: {
      type: Boolean,
      default: false
    },
    isSriError: {
      type: Boolean,
      default: false
    },
    isSkuLoading: {
      type: Boolean,
      default: false
    },
    isSkuError: {
      type: Boolean,
      default: false
    },
    sriBorderColor: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleDropdownSelect(context, selection) {
      const payload = { context, selection };
      this.$emit('skuSelect', payload);
    }
  }
};
</script>

<style lang="css" scoped>
.intermediate-dimensions {
  width: 200px;
}

.active-metric {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.dropdown-max-width {
  max-width: 300px;
}
</style>
