<template>
  <div class="u-display-flex u-width-100">
    <!-- <div class="u-spacing-p-l">
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="templateLoading"
        :color="'#007cf6'"
      ></loader>
    </div> -->
    <div
      class="test-left-planner u-spacing-mt-m u-spacing-p-l u-bg-color-grey-white u-height-100vh"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pb-m u-border-width-s u-border-bottom u-border-color-grey-xxx-light"
      >
        <div class="u-font-size-5 u-color-grey-base u-font-weight-600">
          List Test Items
        </div>
        <rb-button
          :click-fn="selectAllTest"
          :text="'Select All'"
          :size="'s'"
          :type="'filled'"
          class=""
        />
      </div>
      <div
        v-for="(item, i) in sqlList"
        class="u-spacing-pv-m u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-cursor-pointer"
        :class="{ 'u-color-blue-base': item.selected }"
        @click="testItemClicked(item, i)"
      >
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pb-s"
        >
          <span class="u-font-size-5 u-font-weight-600 u-spacing-mr-m">Id</span>
          <span class="u-font-size-5 u-text-overflow-ellipsis">{{
            item.id
          }}</span>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pb-s"
        >
          <span class="u-font-size-5 u-font-weight-600 u-spacing-mr-m"
            >Category</span
          >
          <span class="u-font-size-5 u-text-overflow-ellipsis">{{
            item.category
          }}</span>
        </div>
        <div class="u-display-flex u-flex-justify-content-space-between">
          <span class="u-font-size-5 u-font-weight-600 u-spacing-mr-m"
            >Description</span
          >
          <span
            class="u-font-size-5 u-text-overflow-ellipsis"
            :title="item.description"
            >{{ item.description }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="u-spacing-ml-l u-spacing-mt-m u-width-100 u-bg-color-grey-white u-spacing-p-m"
    >
      <label
        class="u-display-block u-font-size-6 u-font-weight-600 u-color-grey-mid-light"
        >Email IDs</label
      >
      <input
        v-model="emails"
        class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s u-width-50"
        placeholder="youremail@commerceiq.ai, nikhil.t@commerceiq.ai"
        type="text"
      />
      <div>
        <div
          class="u-display-flex u-flex-align-items-center"
          @click="addNewKeyValuePair()"
        >
          <div
            class="u-font-size-5 u-color-grey-base u-font-weight-600 u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pv-m"
          >
            Key Value Pairs
          </div>
          <rb-icon
            class="u-color-blue-base rb-icon--medium is-flipped u-spacing-ml-m u-cursor-pointer"
            icon="add-circle-fill"
          />
        </div>
        <div
          v-for="(item, i) in replaceKeys"
          class="u-display-flex"
        >
          <input
            v-model="item.key"
            class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s u-width-20 u-spacing-mr-m"
            placeholder="key"
            type="text"
          />
          <input
            v-model="item.value"
            class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s u-width-20 u-spacing-mr-m"
            placeholder="value"
            type="text"
          />
          <div
            class="u-display-flex u-flex-align-item-centers u-flex-justify-content-center"
            @click="removeKeyValue(i)"
          >
            <rb-icon
              class="u-color-red-base rb-icon--small is-flipped u-spacing-ml-m u-cursor-pointer"
              icon="cross"
            />
          </div>
        </div>
      </div>
      <div>
        <rb-button
          :click-fn="submitForReport"
          :text="'Submit'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s u-spacing-mt-m"
        />
      </div>
    </div>
  </div>
</template>

<style lang="css">
.test-left-planner {
  width: 450px;
}
.internal-tool-disabled {
  pointer-events: none;
}
</style>
<script>
import loader from '@/components/basic/loader';
// import {clone, cloneDeep, get} from 'lodash'

export default {
  components: {
    loader
  },
  data() {
    return {
      replaceKeys: [],
      emails: '',
      selectedAll: false
    };
  },
  computed: {
    sqlList() {
      return this.$store.getters.getSqlTestList.rows;
    }
  },
  created() {
    this.$store.dispatch('fetchSqlListRead');
  },
  methods: {
    testItemClicked(item, index) {
      this.$store.dispatch('markSelectedTestItem', index);
    },
    selectAllTest() {
      this.selectedAll = !this.selectedAll;
      this.$store.dispatch('markAllSelectedTestItem', this.selectedAll);
    },
    addNewKeyValuePair() {
      this.replaceKeys.push({
        key: null,
        value: null
      });
    },
    removeKeyValue(index) {
      this.replaceKeys.splice(index, 1);
    },
    submitForReport() {
      const tests = this.sqlList.filter((item) => {
        return item.selected;
      });
      const body = {
        tests,
        replaceKeys: this.replaceKeys,
        emails: this.emails.split(',')
      };
      this.$store.dispatch('submitForTestReport', body).then(() => {
        this.$snackbar.open({
          message: 'Started to build the report, you will get the mail.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      });
    }
  }
};
</script>
