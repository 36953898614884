var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column u-overflow-x-hidden"},[_c('div',{staticClass:"u-display-flex u-spacing-ph-l u-bg-color-grey-white u-spacing-pb-m u-header-box-shadow sticky",staticStyle:{"z-index":"100"}},[_c('PageTitle',{attrs:{"title":'Reviews & Rating'}})],1),_c('section',{staticClass:"u-envelop-width u-width-100"},[_c('card-widget',{staticClass:"u-flex-1 u-spacing-mb-m u-spacing-mh-m",attrs:{"filter-applied":_vm.widget1DateFilterApplied,"config":_vm.config.widgets['widget1'],"data":_vm.getInsightsReviewsData[_vm.config.widgets['widget1'].meta.key] || {},"page-wise-min-max-key":_vm.pageWiseMinMaxKey,"data-cy":"review-count-widget"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-spacing-ph-l"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative",style:({
                'border-color':
                  _vm.reviewCountColors['0' + _vm.selectedShareClientBrand] ||
                  'transparent'
              })},[(_vm.shareClientBrands.length <= 0)?_c('span',{staticClass:"u-spacing-mr-m u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v("My Brands")])]):_vm._e(),(_vm.shareClientBrands.length > 0)?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"send-details":true,"on-close":_vm.shareClientBrandSelected,"options":_vm.shareClientBrands}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(_vm.selectedShareClientBrand))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])]):_vm._e(),(
                  _vm.selectedShareClientBrand &&
                  ((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget1'].meta.key
                    ] || {}
                  ).metrics || {})[_vm.selectedShareClientBrand]
                )?_c('metric',{staticClass:"u-display-inline-flex u-spacing-mt-s",attrs:{"size":'l',"config":_vm.config.widgets['widget1'].body.tagConfig,"data":((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget1'].meta.key
                    ] || {}
                  ).metrics || {})[_vm.selectedShareClientBrand]}}):_vm._e(),(!_vm.selectedShareClientBrand)?_c('span',{staticClass:"u-font-size-6 u-color-grey-lighter"},[_vm._v("No Data")]):_vm._e()],1),_vm._l((_vm.selectedShareCompBrand),function(val,index){return _c('div',{staticClass:"custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative",style:({
                'border-color': _vm.reviewCountColors['1' + val] || 'transparent'
              }),attrs:{"val":val}},[(
                  index + 1 === _vm.selectedShareCompBrand.length && index > 0
                )?_c('span',{staticClass:"removeLegend",on:{"click":function($event){return _vm.removeLegend(index)}}},[_c('rb-icon',{staticClass:"u-color-grey-x-light rb-icon--small u-cursor-pointer",attrs:{"icon":'cross'}})],1):_vm._e(),(val)?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"context":[index],"send-details":true,"on-close":_vm.shareCompBrandSelected,"options":_vm.shareCompBrands}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(val))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])]):_vm._e(),(
                  val &&
                  ((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget1'].meta.key
                    ] || {}
                  ).metrics || {})[val]
                )?_c('metric',{staticClass:"u-display-inline-flex u-spacing-mt-s",attrs:{"size":'l',"config":_vm.config.widgets['widget1'].body.tagConfig,"data":((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget1'].meta.key
                    ] || {}
                  ).metrics || {})[val]}}):_vm._e(),(!val)?_c('span',{staticClass:"u-font-size-6 u-color-grey-lighter"},[_vm._v("No Data")]):_vm._e()],1)}),(
                _vm.selectedShareCompBrand.length < 4 &&
                _vm.selectedShareCompBrand.length < _vm.shareCompBrands.length
              )?_c('div',{staticClass:"add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"},[_c('span',{on:{"click":_vm.addMore}},[_c('rb-icon',{staticClass:"u-color-grey-x-light rb-icon--large u-cursor-pointer",attrs:{"icon":'add-circle-outline'}})],1)]):_vm._e()],2),_c('chart',{staticClass:"u-flex-1 u-spacing-mt-l",attrs:{"config":_vm.config.widgets['widget1'].body.chart,"data":_vm.widget1Data}})],1)])]),_c('card-widget',{staticClass:"u-flex-1 u-spacing-mb-m u-spacing-mh-m",attrs:{"filter-applied":_vm.widget2DateFilterApplied,"config":_vm.config.widgets['widget2'],"data":_vm.getInsightsReviewsData[_vm.config.widgets['widget2'].meta.key] || {},"page-wise-min-max-key":_vm.pageWiseMinMaxKey}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-spacing-ph-l"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative",style:({
                'border-color':
                  _vm.assortmentColors[
                    '0' + _vm.selectedShareAssortmentClientBrand
                  ] || 'transparent'
              })},[(_vm.shareAssortmentClientBrands.length <= 0)?_c('span',{staticClass:"u-spacing-mr-m u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v("My Brands")])]):_vm._e(),(_vm.shareAssortmentClientBrands.length > 0)?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"send-details":true,"on-close":_vm.shareAssortmentClientBrandSelected,"options":_vm.shareAssortmentClientBrands}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(_vm.selectedShareAssortmentClientBrand))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])]):_vm._e(),(
                  _vm.selectedShareAssortmentClientBrand &&
                  ((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget2'].meta.key
                    ] || {}
                  ).metrics || {})[_vm.selectedShareAssortmentClientBrand]
                )?_c('metric',{staticClass:"u-display-inline-flex u-spacing-mt-s",attrs:{"size":'l',"config":_vm.config.widgets['widget2'].body.tagConfig,"data":((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget2'].meta.key
                    ] || {}
                  ).metrics || {})[_vm.selectedShareAssortmentClientBrand]}}):_vm._e(),(!_vm.selectedShareAssortmentClientBrand)?_c('span',{staticClass:"u-font-size-6 u-color-grey-lighter"},[_vm._v("No Data")]):_vm._e()],1),_vm._l((_vm.selectedShareAssortmentCompBrand),function(val,index){return _c('div',{staticClass:"custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative",style:({
                'border-color': _vm.assortmentColors['1' + val] || 'transparent'
              }),attrs:{"val":val}},[(
                  index + 1 === _vm.selectedShareAssortmentCompBrand.length &&
                  index > 0
                )?_c('span',{staticClass:"removeLegend",on:{"click":function($event){return _vm.removeAssortmentLegend(index)}}},[_c('rb-icon',{staticClass:"u-color-grey-x-light rb-icon--small u-cursor-pointer",attrs:{"icon":'cross'}})],1):_vm._e(),(val)?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"context":[index],"send-details":true,"on-close":_vm.shareAssortmentCompBrandSelected,"options":_vm.shareAssortmentCompBrands}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(val))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])]):_vm._e(),(
                  val &&
                  ((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget2'].meta.key
                    ] || {}
                  ).metrics || {})[val]
                )?_c('metric',{staticClass:"u-display-inline-flex u-spacing-mt-s",attrs:{"size":'l',"config":_vm.config.widgets['widget2'].body.tagConfig,"data":((
                    _vm.getInsightsReviewsData[
                      _vm.config.widgets['widget2'].meta.key
                    ] || {}
                  ).metrics || {})[val]}}):_vm._e(),(!val)?_c('span',{staticClass:"u-font-size-6 u-color-grey-lighter"},[_vm._v("No Data")]):_vm._e()],1)}),(
                _vm.selectedShareAssortmentCompBrand.length < 4 &&
                _vm.selectedShareAssortmentCompBrand.length <
                  _vm.shareAssortmentCompBrands.length
              )?_c('div',{staticClass:"add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"},[_c('span',{on:{"click":_vm.addMoreAssortment}},[_c('rb-icon',{staticClass:"u-color-grey-x-light rb-icon--large u-cursor-pointer",attrs:{"icon":'add-circle-outline'}})],1)]):_vm._e()],2),_c('chart',{staticClass:"u-flex-1 u-spacing-mt-l",staticStyle:{"height":"150px"},attrs:{"config":_vm.barChartConfigs,"data":_vm.widget2Data}})],1)])]),_c('card-widget',{staticClass:"searchPerformanceTable u-flex-1 u-spacing-mh-m u-spacing-mb-m",attrs:{"config":_vm.config.widgets['widget3'],"data":_vm.getInsightsReviewsData[_vm.config.widgets['widget3'].meta.key] || {},"page-wise-min-max-key":_vm.pageWiseMinMaxKey}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[_c('rb-insights-table',{ref:"SKU_Level_Breakdown_Table",staticStyle:{"width":"100%"},attrs:{"config":_vm.config.widgets['widget3'],"grid-options":_vm.gridOptions,"table-row":(
                _vm.getInsightsReviewsData[_vm.config.widgets['widget3'].meta.key] ||
                {}
              ).rows,"table-column":_vm.config.widgets['widget3'].body.columnDefs,"row-height":80,"enable-client-side-sorting":false,"enable-server-side-sorting":true,"pagination":true,"pagination-total-key":(
                _vm.getInsightsReviewsData[_vm.config.widgets['widget3'].meta.key] ||
                {}
              ).totalRows,"pagination-per-page-limit":_vm.config.widgets['widget3'].body.APIConfig.limit,"download-this-table":true}})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }