<!--
Values expected in props for rendering

skuData:  SKU object
-->
<template>
  <div class="u-display-flex u-text-overflow-ellipsis">
    <rb-sku
      class="u-text-overflow-ellipsis"
      :show-image="showImage"
      :asin="asin"
      :title="productTitle"
      :image-url="imageUrl"
      :product-page-url="productUrl"
      :enable-sdp="true"
      @navigateToSDP="navigateToSDP"
    >
      <div
        slot="sku-context"
        class="u-display-flex u-flex-align-items-center"
      >
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :name="asin"
          :value="latestAvgReviewRating"
          :readonly="true"
        >
          <span slot="activeLabel">
            <rb-icon :icon="'star'" />
          </span>
          <span slot="inactiveLabel"><rb-icon :icon="'star-empty'" /></span>
        </star-rating>
        <span>({{ latestTotalReviews }})</span>
      </div>
    </rb-sku>
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  name: 'SkuWithReviewAndRating',
  components: {},
  props: {
    showImage: {
      type: Boolean,
      default: true
    },
    asin: {
      type: String,
      required: true
    },
    productTitle: {
      type: String,
      required: true
    },
    productUrl: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    latestAvgReviewRating: {
      type: Number,
      default: 0
    },
    latestTotalReviews: {
      type: Number,
      default: 0
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>
