export const newToBrandMetricsMeasuresList = [
  'linear_percent_ntb_attributed_sales',
  'linear_ntb_attributed_sales',
  'ntb_attributed_sales',
  'percent_ntb_attributed_sales'
];

export const metricsShownForInstacartChart = [
  'Spend',
  'Attributed sales (Last Touch)',
  'ROAS (Last Touch)',
  'Cost per click',
  'Clicks',
  'Impressions'
];
