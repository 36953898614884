<template>
  <div
    class="search-actions u-display-flex u-flex-justify-content-flex-end u-spacing-mt-m"
  >
    <rb-button
      :click-fn="handleBulkSearchClearAll"
      :text="'Clear All'"
      :type="'filled'"
      :size="'s'"
      :disabled="!pastedText.trim().length"
      class="clear-all-btn"
    />
    <rb-button
      :click-fn="handleBulkSearchApply"
      :text="'Search'"
      :type="'filled'"
      :size="'s'"
      :icon-left="'search'"
      :disabled="!pastedText.trim().length"
      class="rb-button-icon-12 u-spacing-ml-s"
    />
  </div>
</template>

<script>
export default {
  props: {
    pastedText: {
      type: String,
      default: ''
    },
    pastedTextWhileSearchApplied: {
      type: String,
      default: ''
    }
  },
  computed: {
    isSearchApplyDisabled() {
      return (
        !this.pastedText.trim().length ||
        this.pastedText === this.pastedTextWhileSearchApplied
      );
    }
  },
  methods: {
    handleBulkSearchApply() {
      this.$emit('handleBulkSearchApplied', this.pastedText);
    },
    handleBulkSearchClearAll() {
      this.$emit('handleClearAll');
    }
  }
};
</script>

<style lang="css" scoped>
.clear-all-btn {
  color: #8b8f93;
  border: 1px solid #caccce;
  background-color: #fff;
}
.clear-all-btn[disabled] {
  opacity: 0.3;
  background-color: #fff;
}
.clear-all-btn:hover {
  color: #8b8f93;
  border: 1px solid #caccce;
  background-color: #fff;
}
</style>
