<template>
  <section class="u-sidebar u-position-relative">
    <transition name="slide">
      <section
        class="menu-container"
        :class="{ collapsed: isCollapsed }"
        @click.stop="isCollapsed ? toggle() : ''"
      >
        <section class="u-height-100 u-display-flex u-flex-direction-column">
          <div
            class="logo u-flex-justify-content-flex-start u-flex-align-items-center u-display-flex u-width-100 u-color-grey-white u-spacing-ph-m u-spacing-pv-l"
          >
            <img
              src="/images/long_logo.svg"
              alt="logo"
              class="u-cursor-pointer"
              @click.stop="navigateToHome()"
            />
          </div>
          <RegionRetailer
            v-if="isAccountV2Enable"
            :region="$store.getters.getLocation"
            :retailer="selectedRetailer.key"
            :config="accountV2Config"
            @change="onChangeRetailer"
          ></RegionRetailer>
          <section
            v-if="!isAccountV2Enable"
            class="u-spacing-mh-m u-spacing-mb-l u-spacing-mt-0"
            :class="{ 'u-pointer-events-none': retailerOption.length === 1 }"
          >
            <rb-select
              class="u-max-width-100"
              :send-details="true"
              :on-close="templateSelected"
              :options="retailerOption"
              :search-enable-threshold="50"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-bg-color-grey-light u-cursor-pointer optionsDropdown u-spacing-pt-s u-spacing-pl-m u-spacing-pb-s u-spacing-pr-s u-border-radius-s"
              >
                <div
                  class="u-display-flex u-flex-direction-column u-color-white-base"
                >
                  <span
                    class="u-font-size-6"
                    data-cy="selectRetailer"
                    >{{ selectedRetailer && selectedRetailer.id }}</span
                  >
                </div>
                <rb-icon
                  v-if="retailerOption.length > 1"
                  class="rb-icon--medium u-spacing-ml-s u-color-white-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-width-100 retailerDropdown"
                  :data-cy="`menu-${option.id}`"
                  :title="option.id"
                >
                  {{ option.id }}
                </div>
              </template>
            </rb-select>
          </section>
          <section
            v-if="searchMenuConfig"
            class="menu search_for_sku"
            :class="{
              'u-spacing-pb-s': isUserExist,
              'u-spacing-mb-s': !isUserExist
            }"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l"
              :class="{
                active:
                  (selectedMenuItem && selectedMenuItem.title) ===
                  searchMenuConfig.title
              }"
              @click="selectedMenu(searchMenuConfig)"
            >
              <rb-icon
                class="rb-icon--medium u-display-flex"
                :icon="searchMenuConfig.icon"
              />
              <p
                class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs singleMenu"
                :data-cy="`menuTitle-${searchMenuConfig.title}`"
              >
                {{ searchMenuConfig.title }}
              </p>
            </div>
          </section>
          <section
            v-if="isUserExist"
            class="menu u-spacing-pt-0 search_for_sku"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l u-flex-justify-content-space-between u-ef-link"
              @click="switchToEF()"
            >
              <p class="u-font-size-6 u-display-flex singleMenu u-spacing-mr-s">
                Go to e-fundamentals
              </p>
              <rb-icon
                v-if="!isEFLoading"
                class="u-display-flex rb-icon--small"
                :icon="'arrow-right'"
              />
              <loader
                :loading="isEFLoading"
                :color="'#ffffff'"
              />
            </div>
          </section>
          <section
            class="my-workspace u-border-bottom u-border-color-white-opacity-30 u-border-width-s u-position-relative"
            :class="{
              'u-spacing-pb-l': bookmarks.length === 0 || isBookmarkCollapsed
            }"
          >
            <div
              class="u-color-orange-base u-font-size-6 u-spacing-mh-m u-text-transform-uppercase u-font-weight-600 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
              :class="{ 'u-cursor-pointer': bookmarks.length !== 0 }"
              @click.stop="bookmarkToggle()"
            >
              <div>
                My Workspace
                <span v-if="bookmarks.length !== 0"
                  >({{ bookmarks.length }})</span
                >
              </div>
              <rb-icon
                v-if="bookmarks.length > 0"
                v-tippy="{ placement: 'top', arrow: false }"
                class="rb-icon--small u-display-flex u-color-grey-x-light u-display-inline-flex"
                :icon="isBookmarkCollapsed ? 'arrow1-down' : 'arrow1-up'"
                :title="
                  isBookmarkCollapsed
                    ? 'Expand My Workspace'
                    : 'Collapse My Workspace'
                "
              />
            </div>
            <p
              class="desc u-font-size-7 u-spacing-mt-s u-display-flex u-flex-align-items-center u-spacing-ml-m"
            >
              <rb-icon
                class="rb-icon--medium u-spacing-mr-xs u-color-orange-base"
                :class="{ 'opacity-0_5': bookmarks.length > 0 }"
                :icon="'star-empty'"
              />
              <span
                class="u-spacing-pt-xxs"
                :class="{ 'opacity-0_5': bookmarks.length > 0 }"
                >Star any page for easy access.</span
              >
            </p>
            <section
              v-show="bookmarks.length > 0 && !isBookmarkCollapsed"
              id="bookmarkSection"
              class="u-overflow-auto u-spacing-mt-s resizable"
              style="max-height: 265px"
            >
              <div
                v-for="(item, index) in bookmarks"
                :key="index"
                class="u-display-flex u-flex-align-items-flex-start u-flex-direction-column u-spacing-ph-m u-cursor-pointer"
                :class="{
                  active_menu:
                    item.path === (selectedMenuItem && selectedMenuItem.path)
                }"
                @click.stop="selectedMenu(item, 'bookmark')"
              >
                <div
                  class="menuHeader u-display-flex u-flex-justify-content-space-between u-spacing-pv-s u-width-100 u-flex-direction-column u-media-pv-s"
                >
                  <p
                    v-if="item.parent"
                    class="u-display-flex opacity-0_6"
                  >
                    <span
                      class="u-font-size-7"
                      style="text-transform: capitalize"
                      >{{ item.parent && item.parent.title }}</span
                    >
                    <rb-icon
                      class="rb-icon--xx-small u-spacing-mh-xs"
                      :icon="'arrow-right'"
                    />
                  </p>
                  <p
                    class="u-font-size-6 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-text-transform-capitalize u-line-height-1-3"
                    :class="{
                      'u-spacing-pt-xs': item.parent && item.parent.title
                    }"
                  >
                    <span
                      :title="item.title"
                      class="singleMenu u-text-transform-capitalize u-line-height-1 u-max-width-0 u-text-overflow-ellipsis u-overflow-hidden"
                      :data-cy="`menuTitle-${item.title}`"
                      >{{ item.title }}</span
                    >
                    <Bookmark
                      :path="item.path"
                      :icon-class="'rb-icon--medium'"
                      :wrapper-class="'u-spacing-mr-0 u-spacing-ml-s'"
                      :placement="'right'"
                    />
                  </p>
                </div>
              </div>
              <div class="resizer resizer-b" />
            </section>
          </section>

          <section class="menu u-flex-1 u-overflow-auto">
            <div
              v-for="(item, index) in menu"
              v-if="item.children"
              :key="index"
              class="u-display-flex u-flex-align-items-flex-start u-flex-direction-column u-subMenuItem u-spacing-pt-s"
              :class="{ 'u-spacing-pt-0': item.skipFirstLevel }"
            >
              <div
                v-if="!item.skipFirstLevel"
                class="menuHeader u-display-flex u-spacing-ph-m u-cursor-pointer u-flex-justify-content-space-between u-spacing-pv-s u-width-100"
                @click.stop="item.isCollapsed = !item.isCollapsed"
              >
                <p
                  class="u-font-size-6 u-line-height-1-3 u-font-weight-600 u-display-flex u-spacing-pt-xxs u-text-transform-uppercase u-color-flyweel"
                >
                  {{ item.title }}
                </p>
                <rb-icon
                  v-tippy="{
                    placement: 'top',
                    arrow: false,
                    popperOptions: {
                      modifiers: {
                        preventOverflow: {
                          enabled: false
                        }
                      }
                    }
                  }"
                  :title="
                    item.isCollapsed
                      ? 'Expand ' + item.title
                      : 'Collapse ' + item.title
                  "
                  class="rb-icon--small u-display-flex u-color-grey-x-light"
                  :icon="item.isCollapsed ? 'arrow1-down' : 'arrow1-up'"
                />
              </div>
              <div
                v-for="(child, childIndex) in item.children"
                v-show="!item.isCollapsed"
                :key="childIndex"
                :class="{
                  'u-cursor-pointer':
                    child.children && isMenuItemPresent(child),
                  subMenuWrapper: child.children
                }"
                class="u-display-flex u-flex-align-items-center u-menuItem u-width-100"
              >
                <div
                  class="subMenuContainer u-display-flex u-flex-direction-column u-width-100"
                >
                  <div
                    class="subMenuTitle u-display-flex u-spacing-pv-s u-spacing-ph-m u-cursor-pointer u-flex-justify-content-space-between"
                    :class="{
                      active:
                        !child.children &&
                        (selectedMenuItem && selectedMenuItem.title) ===
                          child.title
                    }"
                    @click.stop="
                      !child.children
                        ? selectedMenu(child)
                        : handleMenuChild(child)
                    "
                  >
                    <div class="u-display-flex u-flex-align-items">
                      <rb-icon
                        class="rb-icon--medium u-display-flex"
                        :icon="child.icon"
                      />
                      <p
                        class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs u-media-font-size-5"
                        :data-cy="`menuTitle-${child.title}`"
                      >
                        {{ child.title }}
                      </p>
                    </div>
                    <span v-if="child.isFallbackDashboard">
                      <rb-icon icon="lock" />
                    </span>
                    <span v-if="child.newSection || item.newSection">
                      <ciqBeta
                        style-class="u-spacing-ml-s u-font-size-8 u-color-grey-white u-bg-color-green-base u-spacing-pv-xxs u-spacing-ph-s u-border-radius-xl"
                        text="NEW"
                      />
                    </span>
                    <span v-if="child.newVersion">
                      <ciqBeta
                        style-class="u-spacing-ml-s u-font-weight-600 u-font-size-7 u-color-grey-white u-bg-color-purple-base u-spacing-pv-xxs u-spacing-ph-s u-border-radius-xl"
                        text="v2.0"
                      />
                    </span>
                    <!-- <rb-icon
                      v-if="child.children"
                      v-tippy="{ placement : 'top', arrow: false, popperOptions: {
                        modifiers: {
                          preventOverflow: {
                            enabled: false
                          }
                        }
                      } }"
                      :title="child.isCollapsed? 'Expand ' + child.title: 'Collapse ' + child.title"
                      class="rb-icon--xx-small u-display-flex u-color-grey-x-light u-spacing-ml-s u-spacing-pt-xxs"
                      :icon="child.isCollapsed ? 'arrow1-down': 'arrow1-up'"
                    /> -->
                  </div>

                  <div
                    v-for="(subChild, subChildIndex) in child.children"
                    v-show="!child.isCollapsed"
                    :key="subChildIndex"
                    class="u-display-flex u-flex-align-items-center u-spacing-pv-s u-spacing-ph-l u-menuItem u-cursor-pointer"
                    :class="{
                      'active u-cursor-pointer':
                        child.children &&
                        (selectedMenuItem && selectedMenuItem.title) ===
                          subChild.title,
                      'u-subMenu-active':
                        !child.isCollapsed ||
                        (child.children && isMenuItemPresent(child))
                    }"
                  >
                    <p
                      class="u-font-size-5 u-display-flex singleMenu"
                      :data-cy="`menuTitle-${subChild.title}`"
                      @click.stop="selectedMenu(subChild)"
                    >
                      {{ subChild.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="!item.hideInMenu && isNotFinalGroupElements(item)"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l"
              :class="{
                active:
                  (selectedMenuItem && selectedMenuItem.title) === item.title
              }"
              @click="selectedMenu(item)"
            >
              <span class="u-display-flex">
                <rb-icon
                  class="rb-icon--medium u-display-flex"
                  :icon="item.icon"
                />
                <p
                  class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs singleMenu"
                  :data-cy="`menuTitle-${item.title}`"
                >
                  {{ item.title }}
                </p>
              </span>
              <span v-if="item.newSection">
                <ciqBeta
                  style-class="u-spacing-ml-s u-font-size-8 u-color-grey-white u-bg-color-green-base u-spacing-pv-xxs u-spacing-ph-s u-border-radius-xl"
                  text="NEW"
                />
              </span>
              <span v-if="item.newVersion">
                <ciqBeta
                  style-class="u-spacing-ml-s u-font-weight-600 u-font-size-7 u-color-grey-white u-bg-color-purple-base u-spacing-pv-xxs u-spacing-ph-s u-border-radius-xl"
                  text="v2.0"
                />
              </span>
            </div>
            <div
              v-if="filteredFinalGroupMenuElements.length > 0"
              class="u-spacing-pt-m u-spacing-mt-m u-border-top u-border-color-white-opacity-30 u-border-width-s"
            >
              <div
                v-for="(item, index) in filteredFinalGroupMenuElements"
                :key="index"
                class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l"
                :class="{
                  active:
                    (selectedMenuItem && selectedMenuItem.title) === item.title
                }"
                @click="selectedMenu(item)"
              >
                <rb-icon
                  class="rb-icon--medium u-display-flex"
                  :icon="item.icon"
                />
                <p
                  class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs singleMenu"
                  :data-cy="`menuTitle-${item.title}`"
                >
                  {{ item.title }}
                </p>
              </div>
            </div>
          </section>

          <section
            v-if="!disableFooter"
            class="menu-footer u-border-top u-border-color-white-opacity-30 u-border-width-s u-spacing-pv-l"
          >
            <div
              v-if="isToolsAndSettingsEnabled"
              class="u-display-flex u-flex-align-items-center u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l singleMenu"
              @click="selectedMenu({ path: 'tools_and_settings' })"
            >
              <rb-icon
                class="rb-icon--medium u-display-flex"
                :icon="'tools_and_settings'"
              />
              <p
                class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs"
              >
                Tools and Settings
              </p>
            </div>
            <ChromePlugin
              v-if="$store.getters.getRetailProgramConfig === 'amazonretail'"
            />
          </section>
        </section>
      </section>
    </transition>
    <div
      class="u-sidebar-trigger u-position-absolute u-bg-color-blue-base u-spacing-p-xs u-cursor-pointer"
      @click.stop="toggle()"
    >
      <rb-icon
        v-tippy="{ placement: 'top', arrow: false }"
        :title="isCollapsed ? 'Expand Menu' : 'Collapse Menu'"
        class="u-color-grey-white rb-icon--medium"
        :icon="isCollapsed ? 'menu_open' : 'menu_close'"
      />
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import utils from '@/utils/helpers/';
import {
  retailConfig,
  retailersMenuConfig
} from '@/router/primaryTabsV2Config.js';
import { cloneDeep } from 'lodash';
import ChromePlugin from '@/components/basic/chromePlugin.vue';
import {
  flattenMenu,
  generateFullPath,
  findMenuPath
} from '@/utils/helpers/routeUtils.js';
import ciqBeta from '@/components/basic/chipText.vue';
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import urlHelper from '@/utils/helpers/url';
import { rem1V2ConfigEnabled } from '@/components/pages/insights/amazon/cva/utils/index.js';
import {
  removeGlobalSettingsForMS,
  categoryConstants,
  overviewConstants
} from '@/components/pages/insights/amazon/market-share/utils';
import { eventBus } from '@/utils/services/eventBus';
import RegionRetailer from '@/components/layouts/regionRetailer.vue';
import CONSTANTS from '@/utils/constants.js';

export default {
  components: {
    ChromePlugin,
    ciqBeta,
    loader,
    RegionRetailer
  },
  data() {
    const isCitrusRetailerEnabledForUser =
      this.isCitrusRetailerEnabledForUser();
    const isAlbertsonsCitrusConfigPresent = Vue.options.filters.config_check(
      'feature.albertsons_citrus'
    );
    const isAmazonConfigPresent =
      Vue.options.filters.config_check('feature.amazon');

    const isHyveeCitrusConfigPresent = Vue.options.filters.config_check(
      'feature.hyvee_citrus'
    );
    const isShopriteCitrusConfigPresent = Vue.options.filters.config_check(
      'feature.shoprite_citrus'
    );
    const isTargetCitrusConfigPresent = Vue.options.filters.config_check(
      'feature.target_citrus'
    );
    const isMeijerCriteoConfigPresent =
      Vue.options.filters.config_check('feature.meijer');
    const isUltaCriteoConfigPresent =
      Vue.options.filters.config_check('feature.ulta');
    const isAlbertsonsCriteoConfigPresent =
      Vue.options.filters.config_check('feature.albertsons');
    const isUbereatsCriteoConfigPresent = Vue.options.filters.config_check(
      'feature.ubereats_criteo'
    );
    const staticRetailer = [];
    let retailerOption = [];

    if (isAmazonConfigPresent) {
      retailerOption.push({ id: 'Amazon Retail', key: 'amazonretail' });
    }
    if (
      Vue.options.filters.config_check('feature.demo.product_demo.enable') ||
      Vue.options.filters.config_check('feature.omni.enable')
    ) {
      retailerOption.unshift({ id: 'Omnichannel', key: 'allretail' });
    }

    if (Vue.options.filters.config_check('feature.demo.product_demo.enable')) {
      staticRetailer.push({ id: 'Best Buy', key: '' });
      staticRetailer.push({ id: 'Costco', key: '' });
      staticRetailer.push({ id: 'Petco', key: '' });
      staticRetailer.push({ id: 'HomeDepot', key: '' });
      staticRetailer.push({ id: 'Ebay', key: '' });
    }

    if (Vue.options.filters.config_check('feature.bestbuy')) {
      retailerOption.push({ id: 'Best Buy', key: 'bestbuyretail' });
    }

    if (Vue.options.filters.config_check('feature.fresh')) {
      retailerOption.push({ id: 'Amazon Fresh', key: 'amazonfresh' });
    }

    const showAmazon3pRetailer = this.shouldShowRetailer(
      'feature.amazon_3p',
      'feature.is_amazon_3p_for_internal_users'
    );
    if (showAmazon3pRetailer) {
      retailerOption.push({
        id: 'Amazon 3P',
        key: 'amazon_3pretail'
      });
    }
    const showAmazonBusinessRetailer = this.shouldShowRetailer(
      'feature.amazon_b2b',
      'feature.is_amazon_b2b_for_internal_users'
    );
    if (showAmazonBusinessRetailer) {
      retailerOption.push({
        id: 'Amazon Business',
        key: 'amazon_b2bretail'
      });
    }
    const showAmazonHybridRetailer = this.shouldShowRetailer(
      'feature.amazon_hybrid',
      'feature.is_amazon_hybrid_for_internal_users'
    );
    if (showAmazonHybridRetailer) {
      retailerOption.push({
        id: 'Amazon Hybrid',
        key: 'amazon_hybridretail'
      });
    }

    if (
      Vue.options.filters.config_check('feature.walmart') &&
      !Vue.options.filters.config_check('feature.walmart_criteo')
    ) {
      retailerOption.push({ id: 'Walmart', key: 'walmartretail' });
    }
    if (Vue.options.filters.config_check('feature.target')) {
      retailerOption.push({ id: 'Target (Criteo)', key: 'targetretail' });
    }
    if (Vue.options.filters.config_check('feature.instacart')) {
      retailerOption.push({ id: 'Instacart', key: 'instacartretail' });
    }
    if (Vue.options.filters.config_check('feature.homedepot')) {
      retailerOption.push({ id: 'Homedepot', key: 'homedepotretail' });
    }
    if (Vue.options.filters.config_check('feature.wayfair')) {
      retailerOption.push({ id: 'Wayfair', key: 'wayfairretail' });
    }
    if (Vue.options.filters.config_check('feature.overstock')) {
      retailerOption.push({ id: 'Overstock', key: 'overstockretail' });
    }
    if (Vue.options.filters.config_check('feature.lowes')) {
      retailerOption.push({ id: 'Lowes', key: 'lowesretail' });
    }
    if (Vue.options.filters.config_check('feature.samsclub')) {
      retailerOption.push({ id: "Sam's Club (Criteo)", key: 'samsclubretail' });
    }
    if (Vue.options.filters.config_check('feature.samsclub_api')) {
      retailerOption.push({
        id: "Sam's Club (API)",
        key: 'samsclub_apiretail'
      });
    }
    if (
      Vue.options.filters.config_check('feature.chewy') &&
      !Vue.options.filters.config_check('feature.chewy_promoteiq')
    ) {
      retailerOption.push({ id: 'Chewy', key: 'chewyretail' });
    }
    if (isAlbertsonsCitrusConfigPresent) {
      retailerOption.push({ id: 'Albertsons', key: 'albertsons_citrusretail' });
    }
    if (isHyveeCitrusConfigPresent) {
      retailerOption.push({ id: 'Hyvee', key: 'hyvee_citrusretail' });
    }
    if (isShopriteCitrusConfigPresent) {
      retailerOption.push({ id: 'Shoprite', key: 'shoprite_citrusretail' });
    }
    if (isMeijerCriteoConfigPresent) {
      retailerOption.push({ id: 'Meijer', key: 'meijerretail' });
    }

    if (isUltaCriteoConfigPresent) {
      retailerOption.push({ id: 'Ulta', key: 'ulta_criteoretail' });
    }
    if (isAlbertsonsCriteoConfigPresent) {
      retailerOption.push({
        id: 'Albertsons (Criteo)',
        key: 'albertsons_criteoretail'
      });
    }
    if (isUbereatsCriteoConfigPresent) {
      retailerOption.push({
        id: 'Uber Eats',
        key: 'ubereats_criteoretail'
      });
    }

    if (isTargetCitrusConfigPresent) {
      retailerOption.push({
        id: 'Target (Citrus)',
        key: 'target_citrusretail'
      });
    }
    const showKrogerRetailer = this.shouldShowRetailer(
      'feature.kroger',
      'feature.is_kroger_for_internal_users'
    );
    if (showKrogerRetailer) {
      retailerOption.push({
        id: 'Kroger',
        key: 'krogerretail'
      });
    }
    const showKrogerPromoteIqRetailer = this.shouldShowRetailer(
      'feature.kroger_promoteiq',
      'feature.is_kroger_promoteiq_for_internal_users'
    );
    if (showKrogerPromoteIqRetailer) {
      retailerOption.push({
        id: 'Kroger (PromoteIQ)',
        key: 'kroger_promoteiqretail'
      });
    }

    const showChewyPromoteIqRetailer = this.shouldShowRetailer(
      'feature.chewy_promoteiq',
      'feature.is_chewy_promoteiq_for_internal_users'
    );
    if (showChewyPromoteIqRetailer) {
      retailerOption.push({
        id: 'Chewy (PromoteIQ)',
        key: 'chewy_promoteiqretail'
      });
    }

    const showShiptRetailer = this.shouldShowRetailer(
      'feature.shipt_criteo',
      'feature.is_shipt_criteo_for_internal_users'
    );
    if (showShiptRetailer) {
      retailerOption.push({ id: 'Shipt', key: 'shipt_criteoretail' });
    }

    const showWalmartCriteoRetailer = this.shouldShowRetailer(
      'feature.walmart_criteo',
      'feature.is_walmart_criteo_for_internal_users'
    );
    if (showWalmartCriteoRetailer) {
      retailerOption.push({
        id: 'Walmart (Criteo)',
        key: 'walmart_criteoretail'
      });
    }

    const showAholdCitrusRetailer = this.shouldShowRetailer(
      'feature.ahold_citrus',
      'feature.is_ahold_citrus_for_internal_users'
    );
    if (showAholdCitrusRetailer) {
      retailerOption.push({
        id: 'Ahold',
        key: 'ahold_citrusretail'
      });
    }

    Object.keys(retailersMenuConfig).forEach((retailer) => {
      const { retailerConfig, internalUserConfig, id, key } =
        retailersMenuConfig[retailer];
      const showRetailer = this.shouldShowRetailer(
        retailerConfig,
        internalUserConfig
      );
      if (showRetailer) {
        retailerOption.push({
          id,
          key
        });
      }
    });

    const blacklistRetail = window.configs.client.feature.blacklistRetail;
    if (blacklistRetail) {
      retailerOption = retailerOption.filter(
        (item) => item.key !== blacklistRetail
      );
    }

    retailerOption = retailerOption.filter((retailer) => {
      return utils.shouldRetainRetailer(retailer.key);
    });
    retailerOption = [...retailerOption, ...staticRetailer];

    let isAccountV2Enable = Vue.options.filters.config_check(
      'feature.accountMapping.enable'
    );
    let accountV2Config = Vue.options.filters.config_check(
      'feature.accountMapping.config'
    );
    try {
      accountV2Config = JSON.parse(accountV2Config);
    } catch (error) {
      isAccountV2Enable = false;
    }

    return {
      isCollapsed: false,
      isBookmarkCollapsed: false,
      selectedMenuItem: {},
      menu: [],
      selectedBookmark: {},
      retailerOption: retailerOption,
      subscribeForMutations: ['bookmarkStore/SET_BOOKMARKS', 'SET_ROOT_PATH'],
      menuClickSource: -1,
      searchMenuConfig: null,
      elementsInFinalGroup: ['Actions Log', 'Automations and Impact'], // elements in final group ( to handle css )
      isEFLoading: false,
      isUserExist: false,
      dynamicCLMenuFetched: false,
      isAccountV2Enable: isAccountV2Enable,
      accountV2Config: accountV2Config || []
    };
  },
  computed: {
    selectedTab: {
      get() {
        const count = this.$route.path.split('/').length;
        let checkString = this.$route.path;
        if (count > 2) {
          checkString = '/' + this.$route.path.split('/')[1];
        }
        return [checkString];
      }
    },
    bookmarks() {
      let list = this.$store.getters['bookmarkStore/getAllBookmarks'];
      list = list.map((item) =>
        Object.keys(item).reduce(
          (prev, current) => ({
            ...prev,
            [current.toLowerCase()]: item[current]
          }),
          {}
        )
      );

      // Filter out the region specific routes.
      list = list.filter((item) => {
        if (item.location) {
          const bookmarkRegion = `/${item.location}/${item.retailer}/${item.program}/`;
          if (bookmarkRegion === this.$store.getters.getRootPath) {
            return true;
          }
          return false;
        } else {
          if (item.path.indexOf(this.$store.getters.getRootPath) !== -1) {
            return true;
          }
          return false;
        }
      });

      let tempList = [];
      let rootPath = this.$store.getters.getRootPath;
      // Validate for last string
      if (rootPath.substring(rootPath.length, rootPath.length - 1) === '/') {
        rootPath = rootPath.substring(0, rootPath.length - 1);
      }
      const routes = cloneDeep(this.$router?.options?.routes || []).filter(
        (item) => item.path === rootPath
      )[0];
      if (routes) {
        const fullPath = generateFullPath(routes?.children, routes?.path);
        const flatternList = cloneDeep(flattenMenu(fullPath));
        tempList = list.map((item) => {
          if (item.title) {
            if (item.path.includes('recommendations')) {
              return { ...item, parent: { title: 'Recommendations' } };
            } else if (item.path.includes('business_overview')) {
              return { ...item, parent: { title: 'Business Overview' } };
            } else if (item.path.includes('assortment')) {
              return { ...item, parent: { title: 'Assortment' } };
            } else if (item.path.includes('reports') !== -1) {
              return { ...item, parent: { title: 'Reports' } };
            }
          } else {
            const itemFound = findMenuPath(flatternList, item.path);
            item.title = itemFound.title || itemFound.name;
          }
          return item;
        });
        tempList = tempList.filter((n) => {
          if (Array.isArray(n)) {
            return false;
          }
          return n;
        });
      } else {
        // backup logic
        tempList = list.map((item) => {
          if (item.title) {
            if (item.path.includes('recommendations')) {
              return { ...item, parent: { title: 'Recommendations' } };
            } else if (item.path.includes('business_overview')) {
              return { ...item, parent: { title: 'Business Overview' } };
            } else if (item.path.includes('assortment')) {
              return { ...item, parent: { title: 'SKUs' } };
            } else if (item.path.includes('dp')) {
              return { ...item, parent: { title: 'SKU Detail Page' } };
            } else if (item.path.includes('market_insights')) {
              return { ...item, parent: { title: 'Market Insights' } };
            } else if (item.path.includes('reports')) {
              return { ...item, parent: { title: 'Reports' } };
            } else if (item.path.includes('media_planner')) {
              return { ...item, parent: { title: 'Media Planner' } };
            } else if (item.path.includes('dsp')) {
              const dspCampaignManagementPath = [
                'dsp_campaign_management/orders',
                'dsp_campaign_management/line_items'
              ];
              const isDSPCampaignManagementPath =
                dspCampaignManagementPath.some((path) =>
                  item.path.endsWith(path)
                );
              if (isDSPCampaignManagementPath) {
                return {
                  ...item,
                  parent: { title: 'DSP Campaign Management' }
                };
              } else {
                const selectedPage = ['orders', 'line_items'].find((path) =>
                  item.path.includes(path)
                );
                const labelMap = {
                  orders: 'DSP Order Details',
                  line_items: 'DSP Line item Details'
                };
                return { ...item, parent: { title: labelMap[selectedPage] } };
              }
            } else if (item.path.includes('campaign_management')) {
              const campaignManagementPath = [
                'campaign_management/campaigns',
                'campaign_management/keywords',
                'campaign_management/search_terms',
                'campaign_management/ad_skus'
              ];
              const isCampaignManagementPath = campaignManagementPath.some(
                (path) => item.path.endsWith(path)
              );
              if (isCampaignManagementPath) {
                return { ...item, parent: { title: 'Campaign Management' } };
              } else {
                return { ...item, parent: { title: 'Campaign Details' } };
              }
            }
          } else {
            let splitPath = item.path.split('/');
            splitPath = splitPath[splitPath.length - 1];
            splitPath = splitPath
              .split('_')
              .map((path) => {
                if (path === 'skus') {
                  return 'SKUs';
                }
                return path;
              })
              .join(' ');
            return { ...item, title: splitPath };
          }
          return item;
        });
      }

      return [...tempList];
    },
    selectedRetailer() {
      const selectedRetailer = this.retailerOption.filter((item) => {
        const retailProgram =
          this.$store.getters.getRetailer + this.$store.getters.getProgram;
        if (item.key === retailProgram) {
          return item;
        }
      })[0];
      return selectedRetailer || {};
    },
    // Seperate Final group looping logic seperately to handle CSS
    filteredFinalGroupMenuElements() {
      return this.menu.filter((item) =>
        this.elementsInFinalGroup.includes(item.title)
      );
    },
    disableFooter() {
      const restrictedRetailersConfig =
        Vue.options.filters.config_check('feature.footer_disabled_retailers') ||
        '';
      const restrictedRetailers = restrictedRetailersConfig.split(',') || [];
      return restrictedRetailers?.includes(this.$store.getters.getRetailer);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'setHighlighter'
    }
  },
  mounted() {
    this.validEFUser();
    if (
      !utils.internalUserCheck(window.user) &&
      Vue.options.filters.config_check('feature.autoRedirectToLive')
    ) {
      this.redirectToLiveEnv();
    }
    this.configureMenu(this.selectedRetailer);
    this.setHighlighter();
    this.setResizer();
    const transitionEndEventName = utils.getTransitionEndEventName();
    document
      .querySelector('.menu-container')
      ?.addEventListener(transitionEndEventName, this.transitionEndEvent);
  },
  created() {
    this.$store.dispatch('bookmarkStore/fetchAllBookmarks');
    this.$store.dispatch('fetchUserPageMetadata');
    this.$store.dispatch('fetchMigrateStatus');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (this.subscribeForMutations.indexOf(mutation.type) !== -1) {
        this.setHighlighter();
      }
    });
    eventBus.$on('sideMenuToggle', (value) => {
      this.isCollapsed = value;
    });

    this.isToolsAndSettingsEnabled = Vue.options.filters.config_check(
      'feature.pages.tools_and_settings.enable'
    );
  },
  beforeDestroy() {
    const transitionEndEventName = utils.getTransitionEndEventName();
    document
      .querySelector('.menu-container')
      ?.removeEventListener(transitionEndEventName, this.transitionEndEvent);
    this.unsubscribe();
  },
  methods: {
    onChangeRetailer(params) {
      const location = params.region.region;
      const retailer = params.retailer.key;
      if (!location || !retailer) {
        return;
      }
      this.handleRetailerChange(retailer, location);
    },
    hideTabsInMenu(tabs) {
      const hideTabsForRetailerList = Vue.options.filters.config_check(
        `feature.pages.${this.selectedRetailer.key}.hideForExternalUsers`
      );
      if (!this.isInternalUser() && hideTabsForRetailerList?.length) {
        return tabs.filter((tab) => {
          // check if current tab needs to be hidden
          const hideTab = hideTabsForRetailerList.includes(tab.title);

          // early return to stop from recursively checking sub tabs
          // if the parent itself is to be hidden
          if (hideTab) return false;
          else {
            return this.checkForSubTabs(tab);
          }
        });
      }

      return tabs;
    },
    checkForSubTabs(tab) {
      // recursively check if sub tabs need to be hidden
      if (tab?.children?.length) {
        tab.children = this.hideTabsInMenu(tab.children); // sub tabs are filtered
      }

      // remove tab if it has children array but it's empty
      return !(tab.children && !tab.children?.length);
    },
    isCitrusRetailerEnabledForUser() {
      let citrusEnabledUserEmails = '';
      citrusEnabledUserEmails =
        Vue.options.filters.config_check('feature.citrusEnabledUserEmails') ||
        '';
      const citrusEnabledUserEmailsArr = citrusEnabledUserEmails.split(',');
      return citrusEnabledUserEmailsArr.includes(window?.user?.email);
    },
    shouldShowRetailer(retailerConfigKey, internalUserConfigKeyForRetailer) {
      let showRetailer = false;
      showRetailer = Vue.options.filters.config_check(retailerConfigKey);
      const checkIfRetailerIsEnabledOnlyForInternalUsers =
        Vue.options.filters.config_check(internalUserConfigKeyForRetailer);
      if (
        checkIfRetailerIsEnabledOnlyForInternalUsers &&
        !this.isInternalUser()
      ) {
        showRetailer = false;
      }
      return showRetailer;
    },
    isInternalUser() {
      return utils.internalUserCheck(window.user);
    },
    switchToEF() {
      this.isEFLoading = true;
      HttpService.post('createSessionUrl', {})
        .then((response) => {
          window.open(response.data.loginUrl, '_blank');
        })
        .catch(() => {
          this.$snackbar.open({
            message: 'Something went wrong.',
            duration: 5000,
            actionText: ''
          });
        })
        .finally(() => {
          console.log('');
          this.isEFLoading = false;
        });
    },
    validEFUser() {
      if (Vue.options.filters.config_check('feature.efIntegration')) {
        this.isUserExist = false;
        HttpService.post('isUserExists', {})
          .then((response) => {
            this.isUserExist = response.data.userExists;
          })
          .catch(() => {
            this.isUserExist = false;
          });
      }
    },
    redirectToLiveEnv() {
      let hostComponents = '';
      if (window.location.hostname === 'localhost') {
        hostComponents = 'centralpet.uat.commerceiq.ai'.split('.');
      } else {
        hostComponents = window.location.hostname.split('.');
      }
      if (hostComponents.indexOf('uat') !== -1) {
        hostComponents = hostComponents.filter((item) => item !== 'uat');
        hostComponents = hostComponents.join('.');
        hostComponents = 'http://' + hostComponents;
        window.location.replace(hostComponents);
      }
    },
    navigateToHome() {
      this.$logger.logEvent({
        eventType: 'CIQ Logo',
        type: 'Click',
        page: 'menu_component',
        eventContext: 'CIQ Logo Clicked'
      });
      this.$router.push({ name: 'businessInsights' });
    },
    setHighlighter() {
      // const bookmarkIndex = this.bookmarks?.findIndex(item => item.path === this.$route?.path);
      let bookmarkIndex = -1;
      if (this.menuClickSource === 'bookmark') {
        bookmarkIndex = this.bookmarks?.findIndex(
          (item) => decodeURI(item.path) === decodeURI(this.$route?.fullPath)
        );
      }
      if (bookmarkIndex === -1) {
        let fullPath = this.$route.path;
        if (fullPath?.charAt(fullPath?.length - 1) === '/') {
          fullPath = fullPath.slice(0, -1);
        }
        fullPath = fullPath.split('/');
        if (Object.keys(this.$route?.params).length > 0) {
          fullPath.pop();
        }
        fullPath = fullPath.join('/');
        let tempPath = fullPath.replace(this.$store.getters?.getRootPath, '');
        let findMenu = this.findByPath(this.menu, tempPath, 'children');
        const isMarketShareLite = fullPath.includes('market_share_lite');
        const addOnPath = isMarketShareLite ? '_lite' : '';
        if (tempPath.indexOf('digital_shelf/market_share' + addOnPath) !== -1) {
          findMenu = this.findByPath(
            this.menu,
            'digital_shelf/market_share' + addOnPath,
            'children'
          );
        }
        if (findMenu !== -1) {
          this.selectedMenuItem = findMenu;
        } else {
          const length = this.$route?.matched?.length;
          const index = length % 2 === 0 ? length - 1 : length % 2;
          if (this.$route.matched[index]) {
            tempPath = this.$route.matched[index].path.replace(
              this.$store.getters.getRootPath,
              ''
            );
            findMenu = this.findByPath(this.menu, tempPath, 'children');
            if (findMenu !== -1) {
              this.selectedMenuItem = findMenu;
            } else {
              tempPath = fullPath.replace(this.$store.getters?.getRootPath, '');
              if (tempPath.indexOf('advertising/campaign_management') !== -1) {
                const amsPath = this.findByPath(
                  this.menu,
                  'advertising/campaign_management',
                  'children'
                );
                this.selectedMenuItem = amsPath?.children?.filter((item) => {
                  return item.path === tempPath;
                })[0];
              }
            }
          }
        }
      } else {
        this.selectedMenuItem = this.bookmarks[bookmarkIndex];
      }
    },
    findByPath(arr, path, nestingKey) {
      // if empty array then return
      if (arr.length === 0) return;
      // return element if found else collect all children(or other nestedKey) array and run this function
      return (
        arr.find((d) => d.path === path) ||
        this.findByPath(
          arr.flatMap((d) => d[nestingKey] || []),
          path,
          nestingKey
        ) ||
        -1
      );

      // return arr.reduce((a, item) => {
      //   if (a) return a;
      //   if (item.id === path) return item;
      //   if (item[nestingKey]) return this.findByPath(item[nestingKey], path, nestingKey);
      // }, null);
    },
    bookmarkToggle() {
      this.$logger.logEvent({
        eventType: 'bookmark_toggle',
        type: 'Click',
        page: `${
          this.isBookmarkCollapsed ? 'bookmark-expanded' : 'bookmark-closed'
        }`,
        eventContext: 'Bookmark toggle'
      });
      this.isBookmarkCollapsed = !this.isBookmarkCollapsed;
    },
    selectedMenu(item, type) {
      this.menuClickSource = type || null;
      if (type === 'bookmark') {
        this.$router.push(`${item.path}`);
      } else if (item.redirect) {
        window.open(`${item.redirect}${item.path}`, '_blank');
      } else {
        const rootPath = this.$store.getters.getRootPath;
        this.$router.push(`${rootPath}${item.path}`);
      }
      this.$logger.logEvent({
        eventType: 'menu-click',
        type: 'Click',
        page: `${item.path}`,
        eventContext: `${type ? 'Bookmark Click' : 'Menu Click'}`
      });
      this.selectedMenuItem = item;
    },
    handleNavigation(item) {
      this.selectedMenuItem = {};
      this.selectedBookmark = item;
      this.$router.push(`${item.path}`);
    },
    isMenuItemPresent(menu) {
      return (
        menu?.children.findIndex(
          (item) => item.title === this.selectedMenuItem?.title
        ) !== -1
      );
    },
    findPathElement(item, path) {
      return (
        item?.filter((element) => {
          return element.fullPath === path;
        })[0] || []
      );
    },
    toggle(e) {
      // eventBus.$emit('chartResize');
      this.$logger.logEvent({
        eventType: 'menu_toggle',
        type: 'Click',
        page: `${this.isCollapsed ? 'menu-expanded' : 'menu-closed'}`,
        eventContext: 'Menu toggle'
      });
      this.isCollapsed = !this.isCollapsed;
      window.dispatchEvent(new Event('resize'));
    },
    handleRetailerChange(retailerKey, location) {
      if (!retailerKey && !location) {
        return;
      }

      let path = '';
      if (CONSTANTS.retailerMapping[retailerKey]?.route) {
        path =
          '/' +
          location +
          '/' +
          CONSTANTS.retailerMapping[retailerKey].route +
          '/';
      } else {
        this.resetLocalStorage();
      }
      this.maintainDefaultRoute();
      /**
       * Skipping the dispatch action due to location.href
       * this.$store.dispatch('setRootPath', path);
       * this.configureMenu(selectedRetailer);
       */
      window.location.href =
        window.location.protocol + '//' + window.location.host + path;
    },
    templateSelected(context, selected) {
      if (!selected?.[0]?.key) {
        return;
      }
      if (selected?.[0] && selected?.[0].key !== this.selectedRetailer.key) {
        const selectedRetailer = selected?.[0];
        this.handleRetailerChange(
          selectedRetailer.key,
          this.$store.getters.getLocation
        );
      }
    },
    resetLocalStorage() {
      removeGlobalSettingsForMS();
      const keysToRemove = [
        overviewConstants.mslFilterLocalStorageKey,
        overviewConstants.filterLocalStorageKey,
        categoryConstants.filterLocalStorageKey,
        categoryConstants.mslFilterLocalStorageKey
      ];
      keysToRemove.forEach(function (key) {
        localStorage.removeItem(key);
      });
    },
    addAutoAndImpactIfNotPresent(tabsConfig) {
      if (
        this.selectedRetailer?.key === 'amazonretail' &&
        !tabsConfig.some((item) => item.title === 'Automations and Impact')
      ) {
        const automationAndImpactConfig = {
          title: 'Automations and Impact',
          icon: 'bot',
          path: 'automations_and_impact',
          config: 'automations_and_impact',
          page: 'pages',
          productRoles: ['SalesIQ'],
          configCheckRequired: false
        };
        if (
          Vue.options.filters.config_check(
            'feature.pages.automations_and_impact.enable'
          ) ||
          (utils.internalUserCheck(window.user) &&
            Vue.options.filters.config_check(
              'feature.pages.internalUser.automations_and_impact.enable'
            ))
        ) {
          // add automation and impact for clients which needs it be enabled in prod
          // or internal users only
          tabsConfig.push(automationAndImpactConfig);
        }
      }
    },
    addReportsForAmazon(tabsConfig) {
      if (
        this.selectedRetailer?.key === 'amazonretail' &&
        tabsConfig.some((item) => item.title === 'Reports')
      ) {
        const index = tabsConfig.findIndex((item) => item.title === 'Reports');
        const enableAmazonReports = Vue.options.filters.config_check(
          'feature.pages.amazonReports.enable'
        );
        const enableAmazonSearchReports = Vue.options.filters.config_check(
          'feature.pages.amazonSearchReports.enable'
        );
        const enableDSPReports = Vue.options.filters.config_check(
          'feature.pages.dsp_campaign_management.enable'
        );

        if (
          enableDSPReports ||
          enableAmazonSearchReports ||
          enableAmazonReports
        ) {
          tabsConfig[index] = {
            title: 'Reports',
            icon: 'reports',
            page: 'pages',
            path: enableAmazonSearchReports
              ? 'reports/amazon_search'
              : 'reports/dsp',
            productRoles: ['SalesIQ', 'MarketingIQ']
          };
        } else {
          tabsConfig.splice(index, 1);
        }
      }
    },
    addDSPOrAmazonReports({
      enableAmazonSearchReports,
      enableDSPReports,
      index,
      tabsConfig
    }) {
      let path = enableAmazonSearchReports ? 'reports/amazon_search' : null;
      if (!path) {
        path = enableDSPReports ? 'reports/dsp' : false;
      }
      if (path) {
        tabsConfig[index] = {
          title: 'Reports',
          icon: 'reports',
          path,
          redirect: '',
          configCheckRequired: false,
          config: 'amazonReports',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          page: 'pages'
        };
      } else {
        tabsConfig.splice(index, 1);
      }
    },
    validateIfNoChildrenPresent({ tabElement, validTabs }) {
      // No children
      if (utils.tabsConfigCheckPassV2.call(this, tabElement)) {
        if (
          !(
            tabElement?.internalRelease && !utils.internalUserCheck(window.user)
          )
        ) {
          validTabs.push(tabElement);
        }
      }
    },
    checkIfInternalRelease(e) {
      if (e.configCheckRequired) {
        if (!(e?.internalRelease && !utils.internalUserCheck(window.user))) {
          return utils.tabsConfigCheckPassV2.call(this, e);
        }
      } else {
        return true;
      }
    },
    configCheckForTabs({ tabElement, subChild, validTabs }) {
      for (const element of tabElement.children) {
        if (
          utils.tabsConfigCheckPassV2.call(this, element) &&
          !(element?.internalRelease && !utils.internalUserCheck(window.user))
        ) {
          if (element?.childrenConfigCheck && element?.children?.length) {
            element.children = element.children.filter((e) => {
              return this.checkIfInternalRelease(e);
            });
          }
          subChild.children.push(element);
        }
      }
      if (subChild.children.length > 0) {
        validTabs.push(subChild);
      }
    },
    validateForTabWithChildren({ tabElement, validTabs }) {
      // Tab with single children
      const subChild = cloneDeep(tabElement);
      subChild.children = [];
      if (
        !(tabElement.internalRelease && !utils.internalUserCheck(window.user))
      ) {
        this.configCheckForTabs({ tabElement, subChild, validTabs });
      }
    },
    handleSubTabCheck({ tabElement, validTabs }) {
      if (!tabElement.children) {
        this.validateIfNoChildrenPresent({ tabElement, validTabs });
      } else if (tabElement.children) {
        this.validateForTabWithChildren({ tabElement, validTabs });
      }
    },
    validateTabs({ tabsConfig, validTabs }) {
      for (const tabElement of tabsConfig) {
        if (tabElement.configCheckRequired) {
          this.handleSubTabCheck({ tabElement, validTabs });
        } else if (
          !(tabElement.internalRelease && !utils.internalUserCheck(window.user))
        ) {
          validTabs.push(tabElement);
        }
      }
    },
    configureMenu(selectedRetailer) {
      if (!selectedRetailer?.key) {
        return;
      }
      const validTabs = [];
      const tabsConfig = retailConfig[selectedRetailer.key];
      const isAmazonEnabled = this.retailerOption.findIndex(
        (item) => item.key === 'amazonretail'
      );
      if (isAmazonEnabled !== -1) {
        this.searchMenuConfig =
          tabsConfig[tabsConfig.findIndex((item) => item.hideInMenu)];
      }

      this.addAutoAndImpactIfNotPresent(tabsConfig);
      this.addReportsForAmazon(tabsConfig);
      this.validateTabs({ validTabs, tabsConfig });
      this.menu = this.hideTabsInMenu(validTabs);
      this.addV2Tag();
      if (rem1V2ConfigEnabled() && !this.dynamicCLMenuFetched) {
        // adding this check to avoid addin the Category Leaderboard again in the option
        this.addCategoryLeaderboard();
      }
    },
    addV2Tag() {
      let productVersionUpdateList = Vue.options.filters.config_check(
        'feature.productVersionUpdateList'
      );
      if (productVersionUpdateList) {
        productVersionUpdateList = JSON.parse(productVersionUpdateList);
        for (const element of this.menu) {
          if (element?.children) {
            element.children = this.updateChild(
              element.children,
              productVersionUpdateList
            );
          } else if (
            this.isEnableV2Tag(productVersionUpdateList, element.config) &&
            !element?.newSection
          ) {
            element.newVersion = true;
          }
        }
      }
    },
    updateChild(productList, productVersionUpdateList) {
      for (const child of productList) {
        if (
          this.isEnableV2Tag(productVersionUpdateList, child.config) &&
          !child?.newSection
        ) {
          child.newVersion = true;
        }
      }
      return productList;
    },
    isEnableV2Tag(productVersionUpdateList, config) {
      return productVersionUpdateList.find((element) => element === config);
    },
    logout() {
      this.$store.dispatch('logout');
    },
    handleMenuChild(child) {
      child.isCollapsed = !child.isCollapsed;
      this.selectedMenu(child.children[0]);
    },
    setResizer() {
      // Query the element
      const ele = document.getElementById('bookmarkSection');
      if (ele) {
        // The current position of mouse
        let y = 0;

        // The dimension of the element
        let h = 0;

        // Handle the mousedown event
        // that's triggered when user drags the resizer
        const mouseDownHandler = function (e) {
          // Get the current mouse position
          y = e.clientY;

          // Calculate the dimension of element
          const styles = window.getComputedStyle(ele);
          h = parseInt(styles.height, 10);

          // Attach the listeners to `document`
          document.addEventListener('mousemove', mouseMoveHandler);
          document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
          // How far the mouse has been moved
          const dy = e.clientY - y;

          // Adjust the dimension of element
          const totalheight = h + dy;
          // keeping the minimum height for the bookmark
          if (totalheight <= 400 && totalheight >= 30) {
            ele.style.height = `${totalheight}px`;
            ele.style.maxHeight = `${totalheight}px`;
          }
        };

        const mouseUpHandler = function () {
          // Remove the handlers of `mousemove` and `mouseup`
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
        };

        const resizers = ele.querySelectorAll('.resizer');
        // Loop over them
        [].forEach.call(resizers, function (resizer) {
          resizer.addEventListener('mousedown', mouseDownHandler);
        });
      }
    },
    transitionEndEvent() {
      const transitionEndEventName = utils.getTransitionEndEventName();
      window.dispatchEvent(new Event(transitionEndEventName));
    },
    isNotFinalGroupElements(item) {
      // avoid final group elements in the first this.menu iteration
      return !this.elementsInFinalGroup.includes(item.title);
    },
    maintainDefaultRoute(selectedRetailer) {
      /**
       * Below code checks if user is currently on a (REM CC or Marketshare Lite) page
       * Then upon changing the retailer from left nav bar
       * He should see the same dashboard for whitelisted retailers
       */
      const routeCheck =
        this.$route.name === 'ecommerce_command_centre' ||
        this.$route.name === 'marketShareOverview_lite' ||
        this.$route.name === 'category-page-lite';
      const whiteListedRetailer = [
        'amazonretail',
        'walmartretail',
        'targetretail'
      ];
      const retailCheck =
        whiteListedRetailer.indexOf(selectedRetailer?.key) !== -1;
      if (routeCheck && retailCheck) {
        localStorage.setItem('retainPreviousPath', true);
        localStorage.setItem(
          'previousPath',
          urlHelper.getChildPath(
            this.$route.path,
            this.$store.getters.getRootPath
          )
        );
      } else {
        localStorage.removeItem('retainPreviousPath');
        localStorage.removeItem('previousPath');
      }
    },
    addCategoryLeaderboard() {
      const retailerParams = {
        menu: this.menu,
        selectedRetailer: this.selectedRetailer
      };
      this.$store
        ?.dispatch('cvaRem/fetchRem1EnabledRetailerList', retailerParams)
        .then(
          (response) => {
            this.menu = response;
            this.dynamicCLMenuFetched = true;
          },
          () => {
            this.dynamicCLMenuFetched = false;
          }
        );
    }
  }
};
</script>

<style lang="css" scoped>
.u-border-color-white-opacity-30 {
  border-color: rgba(228, 228, 228, 0.3);
}
.u-ef-link {
  background: #007cf6 !important;
}
</style>

<style lang="css">
.logo img {
  width: 140px;
  height: 20px;
}

.u-sidebar {
  height: 100vh;
  box-shadow: 1px 2px 4px 0 rgba(43, 51, 59, 0.25);
  z-index: 102;
  background-color: #1a212b;
  color: #fff;
}

.menu-container {
  display: block;
  height: 100%;
  width: 216px;
  transition: margin-left 200ms ease-out, transform 200ms ease-out;
}
.menu-container.collapsed {
  margin-left: -196px;
  cursor: pointer;
  overflow: hidden;
}
.menu-container.collapsed * {
  display: none;
}

.u-sidebar-trigger {
  top: 24px;
  right: -10px;
  z-index: 103;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 #2b333b26;
}

.u-text-transform-uppercase {
  text-transform: uppercase;
}

.menu {
  padding: 16px 0px 16px 0px;
}
.menu .subMenuTitle {
  align-items: center;
}
.menu .active {
  border-left: 4px solid #007cf6 !important;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  padding-left: 12px !important;
}
.menu .active p {
  font-weight: 600;
}
.menu .active .subMenuContainer .subMenuTitle,
.menu .active .subMenuContainer .u-menuItem {
  padding-left: 12px;
}
.menu .subMenuWrapper.active {
  padding-left: 0px !important;
}
.menu .u-subMenu-active.active {
  padding-left: 16px !important;
}

.subMenuTitle:hover,
.singleMenu:hover,
.u-subMenu-active:hover,
.u-menuItem:hover {
  font-weight: 600;
}

.subMenuWrapper:hover {
  font-weight: 400;
}

.active_menu {
  border-left: 4px solid #007cf6;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-left: 12px !important;
}

.u-color-flyweel {
  color: #1bdeee;
}

.u-spacing-ept-l {
  padding-top: 24px !important;
}

.u-border-color-white-opacity-30 {
  border-color: rgba(228, 228, 228, 0.3);
}

.u-height-24px {
  height: 24px;
}

.opacity-0_6 {
  opacity: 0.6;
}

.retailerDropdown {
  width: 135px !important;
}

.u-text-transform-capitalize {
  text-transform: capitalize;
}

.oldDesginChip {
  padding: 4px 16px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  margin: 16px 24px;
}

.u-subMenu-active {
  margin-left: 20px;
  border-left: 4px solid rgb(225 225 225 / 30%);
  padding-left: 16px !important;
}

@media screen and (min-width: 1632px) {
  .menu-container {
    width: 232px;
  }
}

.resizer {
  position: absolute;
}

.resizer-b {
  bottom: -2px;
  cursor: row-resize;
  height: 4px;
  left: 0;
  width: 100%;
}

.resizer-b:hover {
  background-color: palette(blue, base);
}

.icon-lock:before {
  font-size: 14px !important;
}

.search_for_sku {
  padding-top: 0px !important;
}
</style>
