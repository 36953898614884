<template>
  <CardContainer
    :last-updated-date="maxDate"
    :action-icons="actionIcons"
    bottom-padding="none"
    :custom-components="customComponents"
    :load="((dataService || {}).data || {}).load"
    :error="((dataService || {}).data || {}).error"
    :no-data="((dataService || {}).data || {}).noData"
    @maximize="handleMaximize"
    @minimize="handleMinimize"
  >
    <div slot="body">
      <chart-with-legends
        :custom-chart-class="[]"
        :chart-data="
          (((dataService || {}).data || {}).rows || {}).chartData || {
            data: []
          }
        "
        :metric-config="
          (((dataService || {}).data || {}).rows || {}).metricDisplayConfig
        "
        :metric-data="(((dataService || {}).data || {}).rows || {}).metricsData"
        :chart-config="chartConfig"
        v-bind="config.chart.props"
        :is-chart-loading="((dataService || {}).data || {} || {}).load"
        :show-chart="showChart"
      />
    </div>
  </CardContainer>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import { cloneDeep, merge } from 'lodash';
import CardContainer from '@/pages/campaign-management/components/card-conatainer-no-destroy.vue';
import {
  MAXIMIZE_ID,
  MINIMIZE_ID,
  minimizeConfig,
  maximizeConfig
} from '@/pages/campaign-management/constants.js';

export default {
  components: {
    chartWithLegends,
    CardContainer
  },
  props: {
    plotRowData: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: null
    },
    dataService: {
      type: Object,
      default: null
    },
    globalWhereClause: {
      type: Object,
      default: () => ({})
    },
    maxDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedRollup: {},
      rollupOptions: [
        {
          name: 'Days',
          apiFormat: 'DAY',
          filterValue: 0
        },
        {
          name: 'Weeks',
          apiFormat: 'WEEK',
          filterValue: 7
        },
        {
          name: 'Months',
          apiFormat: 'MONTH',
          filterValue: 31
        }
      ],
      showChart: true,
      actionIcons: this.config.headerOptions
    };
  },
  computed: {
    customComponents() {
      const dynamicComponents = [];
      if ((this.plotRowData?.dimensionNameValueList || []).length > 0) {
        if (this.config?.dynamicComponents?.plotRows) {
          const plotRowsComponents =
            this.config.dynamicComponents.plotRows.call(this, this.plotRowData);
          dynamicComponents.push(...plotRowsComponents);
        }
      }
      return dynamicComponents;
    },
    chartConfig() {
      return merge(this.config.chart.props.chartConfig, {
        chartOptions: {
          tooltip_format: this.dataService.data?.rows?.tooltipFormat || {}
        }
      });
    }
  },
  created() {
    this.getData();
  },
  methods: {
    insertInBetween(array, splitIndex, insertionObject) {
      array[splitIndex] = insertionObject;
      return [...array];
    },
    handleMaximize() {
      this.showChart = true;
      const index = this.actionIcons.findIndex(
        (item) => item.id === MAXIMIZE_ID
      );
      if (index >= 0) {
        this.actionIcons = this.insertInBetween(
          this.actionIcons,
          index,
          minimizeConfig()
        );
      }
    },
    handleMinimize() {
      this.showChart = false;
      const index = this.actionIcons.findIndex(
        (item) => item.id === MINIMIZE_ID
      );
      if (index >= 0) {
        this.actionIcons = this.insertInBetween(
          this.actionIcons,
          index,
          maximizeConfig()
        );
      }
    },
    getRequestCopy() {
      const requestCopy = cloneDeep(this.config.api.request);
      requestCopy.where = this.globalWhereClause;
      if (requestCopy.bundleCubeExecutionRequest) {
        // If the request has bundleCubeExecutionRequest, then add where clause inside as well
        Object.keys(requestCopy.bundleCubeExecutionRequest).forEach(
          (dataGroup) => {
            requestCopy.bundleCubeExecutionRequest[dataGroup].where =
              this.globalWhereClause;
          }
        );
      }
      return requestCopy;
    },
    getData() {
      const requestCopy = this.getRequestCopy();
      requestCopy.pvpenabled = requestCopy.where.pvpDate !== undefined;
      const config = {
        ...this.config,
        toDate: this.globalWhereClause.date.to,
        fromDate: this.globalWhereClause.date.from
      };
      this.dataService.getData(requestCopy, config);
    }
  }
};
</script>

<style></style>
