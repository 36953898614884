<template>
  <div
    class="u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-flex-start"
    @click.stop
  >
    <div
      class="cell--content u-text-overflow-ellipsis u-flex-shrink-past-min-size"
    >
      <span
        v-if="parsedValue"
        class="u-display-flex u-flex-direction-column"
      >
        <!-- eslint-disable -->
        <span
          v-if="
            this.params.data.event_type === 'Dropped Best Seller badge' ||
            this.params.data.event_type === 'Added Best Seller badge'
          "
          >Category :
        </span>
        <span
          v-if="
            this.params.data.event_type === 'Dropped Amazon Choice badge' ||
            this.params.data.event_type === 'Added Amazon Choice badge'
          "
          >Keyword :
        </span>
        <!-- eslint-enable -->
        <a
          v-if="parsedUrl"
          target="_blank"
          class="href--link u-text-overflow-ellipsis"
          :title="parsedValue"
          :href="parsedUrl"
        >
          {{ parsedValue }}
        </a>
        <span
          v-if="!parsedUrl"
          class="u-color-grey-dark"
          >{{ parsedValue }}</span
        >
      </span>
      <span v-else>NA</span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableBasicLinkCell',
  computed: {
    uniqueCellId() {
      return this.params.column.colId + '_' + this.params.node.id;
    },
    parsedValue() {
      var currObj = this.params.value || '';
      return currObj;
    },
    parsedUrl() {
      if (
        this.params.data.event_type === 'Dropped Best Seller badge' ||
        this.params.data.event_type === 'Dropped Amazon Choice badge' ||
        this.params.data.event_type === 'Added Best Seller badge' ||
        this.params.data.event_type === 'Added Amazon Choice badge'
      ) {
        return this.params.data.keyword_url || null;
      } else {
        const coulmnName = `${this.params.colDef.field}_url`;
        return this.params.data[coulmnName] || null;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.href--link {
  font-weight: normal;
  text-decoration: underline;
}
</style>
