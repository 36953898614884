<template>
  <div
    v-if="show && data && data.tag1 !== 'loading' && data.tag1 !== 'error'"
    class="u-flex-align-items-center"
  >
    <div
      :class="[
        metricClass,
        splitClass,
        'rb-metric',
        { 'u-flex-align-items-flex-start': splitClearPadding }
      ]"
    >
      <span
        :title="
          getTippyString(
            (data || {}).tag1,
            getLocale,
            ((config || {}).tag1Unit || {}).pre
          )
        "
        :value="(data || {}).tag1"
        class="primary_metric u-color-grey-light"
        :style="{ color: labelColor }"
        :class="{
          'u-font-weight-600':
            !(config || {}).tableCell &&
            ((data || {}).tag1 || (data || {}).tag2),
          'u-display-flex': postMetricSlotPassed,
          'u-flex-direction-column': useColumnFlexDirection
        }"
        data-cy="metricValue"
      >
        <span
          v-if="
            !data ||
            (!(data || {}).tag1 &&
              (data || {}).tag1 !== 0 &&
              !(data || {}).tag2 &&
              (data || {}).tag2 !== 0)
          "
          class=""
        >
          {{ fallbackMsgExpanded ? 'Not Available' : 'NA' }}
        </span>
        <span v-else>
          <span
            v-if="
              data.tag1 <= 9 &&
              data.tag1 > 1 &&
              !(config.tag1Unit || {}).pre &&
              hideZero
            "
            >0</span
          >{{
            data.tag1
              | num_format(
                (config.tag1Unit || {}).pre,
                (config.tag1Unit || {}).suff,
                (config.tag1Unit || {}).min,
                (config.tag1Unit || {}).roundoff,
                undefined,
                selectedCurrency
              )
          }}
        </span>
        <slot name="postTag1Metric" />
      </span>
      <div
        v-if="split"
        class="u-spacing-mt-s"
      />
      <span
        v-if="
          (config || {}).invertTag2 !== true && (data || {}).tag2 !== undefined
        "
        v-trend-indicator
        :value="data.tag2"
        class="secondary_metric"
        :class="[
          selected
            ? 'u-color-white-base'
            : {
                'u-color-red-base': data.tag2 < 0,
                'u-color-green-base': data.tag2 > 0,
                'u-color-grey-lighter': data.tag2 === 0
              }
        ]"
        :style="[splitClearPadding ? { 'padding-left': 0 } : '']"
      >
        {{
          data.tag2
            | num_format(
              (config.tag2Unit || {}).pre,
              (config.tag2Unit || {}).suff,
              (config.tag2Unit || {}).min,
              (config.tag2Unit || {}).roundoff,
              undefined,
              selectedCurrency
            )
        }}
      </span>

      <span
        v-if="
          (config || {}).invertTag2 === true && (data || {}).tag2 !== undefined
        "
        v-trend-indicator
        :value="data.tag2"
        class="secondary_metric"
        :class="[
          selected
            ? 'u-color-white-base'
            : {
                'u-color-red-base': data.tag2 > 0,
                'u-color-green-base': data.tag2 < 0
              }
        ]"
        :style="[splitClearPadding ? { 'padding-left': 0 } : '']"
      >
        {{
          data.tag2
            | num_format(
              (config.tag2Unit || {}).pre,
              (config.tag2Unit || {}).suff,
              undefined,
              undefined,
              undefined,
              selectedCurrency
            )
        }}
      </span>
      <span
        v-if="
          splitClearPadding &&
          ((data || {}).tag2 === undefined || (data || {}).tag2 === null)
        "
        class="u-spacing-p-s"
      />
    </div>
  </div>
  <div
    v-else
    class="u-flex-align-items-center u-display-flex u-width-100 u-flex-justify-content-center"
  >
    <loader
      v-if="data && data.tag1 === 'loading'"
      class="u-bg-color-grey-white"
      :loading="data && data.tag1 === 'loading'"
      :color="'#007cf6'"
    />
    <div
      v-if="data && data.tag1 === 'error'"
      class="u-font-size-6"
    >
      Unable to fetch data!
    </div>
    <!-- <rb-icon :icon="'error-fill'" class="u-color-red-base rb-icon--medium u-cursor-pointer"></rb-icon> -->
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import { getTippyString, getLocale } from '@/utils/helpers/formatter';
/* eslint-disable vue/require-default-prop */

export default {
  name: 'Metric',
  components: {
    loader
  },
  props: {
    hideZero: {
      type: Boolean,
      default: true
    },
    config: Object,
    data: Object,
    labelColor: String,
    size: String,
    fallbackMsgExpanded: {
      type: Boolean,
      default: false
    },
    split: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    splitClearPadding: {
      type: Boolean,
      default: false
    },
    useColumnFlexDirection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: true
    };
  },
  computed: {
    postMetricSlotPassed() {
      return this.getCurrentScopedSlots?.includes('postTag1Metric');
    },
    getCurrentScopedSlots() {
      return Object.keys(this.$slots);
    },
    metricClass: function () {
      return 'rb-metric--' + this.size || 's';
    },
    splitClass: function () {
      return this.split === true
        ? 'u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center'
        : '';
    },
    selectedCurrency() {
      return this.$store?.getters?.getSelectedCurrency || {};
    }
  },
  watch: {
    data(val) {
      this.show = false;
      setTimeout(
        function () {
          this.show = true;
        }.bind(this),
        1
      );
    }
  },
  methods: {
    getTippyString,
    getLocale
  }
};
</script>

<style lang="css">
.rb-metric {
  display: flex;
  align-items: baseline;
}

.rb-metric--xs {
  align-items: flex-end;
}

.rb-metric--xs .primary_metric {
  font-weight: normal;
  font-size: 1.4rem;
}

.rb-metric--xs .secondary_metric {
  font-size: 1.1rem;
  padding-left: 0.8rem;
}

.rb-metric--ml .primary_metric {
  font-size: 2.1rem;
}

.rb-metric--ml .secondary_metric {
  font-size: 1.3rem;
  padding-left: 0.8rem;
}

.rb-metric--s .primary_metric {
  font-size: 2.1rem;
}

.rb-metric--s .secondary_metric {
  font-size: 1.3rem;
  padding-left: 0.4rem;
}

.rb-metric--l .primary_metric {
  font-size: 18px;
}

.rb-metric--l .secondary_metric {
  font-size: 13px;
  padding-left: 0.8rem;
}

.rb-metric--xl .primary_metric {
  font-size: 24px;
}

.rb-metric--xl .secondary_metric {
  font-size: 13px;
  padding-left: 0.8rem;
}

.rb-metric--xxl .primary_metric {
  font-size: 40px;
}

.rb-metric--xxl .secondary_metric {
  font-size: 1.8rem;
  padding-left: 0.8rem;
}

.rb-metric--xml .primary_metric {
  font-size: 28px;
  font-weight: 600;
}

.rb-metric--xml .secondary_metric {
  font-size: 1.6rem;
  padding-left: 0.8rem;
}

.rb-metric--16-14 .primary_metric {
  font-size: 16px;
}

.rb-metric--16-14 .secondary_metric {
  padding-left: 0.8rem;
  font-size: 14px;
}

.rb-metric--14-13 .primary_metric {
  font-size: 14px;
}

.rb-metric--14-13 .secondary_metric {
  padding-left: 0.8rem;
  font-size: 13px;
}

.rb-metric--13-12 .primary_metric {
  font-size: 13px;
}

.rb-metric--13-12 .secondary_metric {
  padding-left: 0.8rem;
  font-size: 12px;
}

.rb-metric--14-12 .primary_metric {
  font-size: 14px;
}

.rb-metric--14-12 .secondary_metric {
  padding-left: 0.8rem;
  font-size: 12px;
}

.rb-metric--xxxl {
  min-height: 56px !important;
  align-items: center;
}

.rb-metric--xxxl .primary_metric {
  font-size: 3.2rem;
}

.rb-metric--xxxl .secondary_metric {
  font-size: 1.6rem;
}

.rb-metric--xss .primary_metric {
  font-size: 1.4rem;
}

.rb-metric--xss .secondary_metric {
  font-size: 1.1rem;
  padding-left: 0.8rem;
}
</style>
