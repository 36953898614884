<template>
  <div class="widget-first u-spacing-mh-m u-spacing-mb-m slide-card">
    <div>
      <div>
        <p
          class="widget-title u-font-size-3 u-font-weight-600 u-color-grey-mid-light u-text-case-upper"
        >
          {{ widgetTitle }}
          <span class="u-color-grey-base">({{ getCategoryCount }})</span>
        </p>
        <p
          class="widget-description u-spacing-mt-xs u-font-size-7 u-color-grey-light u-font-weight-400 u-spacing-mb-m"
        >
          {{ widgetDescription }}
        </p>
        <div
          class="u-spacing-pv-m u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <div
              class="u-border-radius-xxxl u-spacing-pv-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center u-bg-color-grey-white u-border-color-grey-xxx-light u-border-width-s u-border-all"
            >
              <sorting
                :enable-pvp-level-sort="true"
                :metric-list="getMetricsListForSort()"
                :sort-configuration="sortConfiguration"
                @metricSortSelected="handleMetricSortSelected"
              />
            </div>
            <div
              class="u-bg-color-grey-xx-light u-spacing-mh-m line-dimensions"
            ></div>
            <span
              class="switch-panel u-color-blue-base u-font-weight-600 u-font-size-5 u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                icon="table-chart"
                class="u-spacing-mr-s u-cursor-pointer"
              />
              <span
                class="u-cursor-pointer"
                @click="switchBetweenViews"
              >
                {{
                  defaultView ? 'Switch to table view' : 'Switch to card view'
                }}
              </span>
            </span>
          </div>
          <div class="u-color-grey-lighter u-cursor-pointer hover-class">
            <div
              v-if="defaultView"
              v-tippy="{ placement: 'top', arrow: false }"
              :title="tempDownloadText"
              class="u-display-flex u-flex-align-items-center u-flex-direction-column"
            >
              <rb-icon
                class="u-color-grey-xx-light"
                :icon="actionIcon.icon"
              />
            </div>
            <div
              v-else-if="!actionIcon.loading"
              v-tippy="{ placement: 'top', arrow: false }"
              :title="actionIcon.tippyTitle"
              class="u-display-flex u-flex-align-items-center u-flex-direction-column"
              @click.stop="handleDownload()"
            >
              <rb-icon
                class="u-color-grey-lighter"
                :icon="actionIcon.icon"
              />
            </div>
            <div v-else>
              <loader
                size="16px"
                class="u-bg-color-grey-white"
                :loading="true"
                color="#007cf6"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="defaultView"
        class="graph-header-container"
      >
        <span
          class="graph-header u-color-grey-mid-light u-font-weight-400 u-font-size-6 u-display-flex u-line-height-1.3"
        >
          <span>
            <rb-icon
              icon="info-circle-fill"
              class="u-spacing-mr-xs rb-icon--medium info-icon u-color-grey-lighter"
            />
          </span>
          <span>{{ graphHeader }}</span>
        </span>
      </div>
      <card-view
        v-if="globalSettings && defaultView"
        :page="page"
        :global-view-id="globalViewId"
        :widget="cardWidget"
        :global-settings="globalSettings"
        :filter-data="filterData"
        :sort-configuration="sortConfiguration"
      />
      <category-breakdown
        v-if="globalSettings && !defaultView"
        :filter-data="filterData"
        :global-settings="globalSettings"
        :global-view-id="globalViewId"
        :sort-configuration="sortConfiguration"
      />
    </div>
  </div>
</template>

<script>
import cardView from '@/components/pages/insights/amazon/market_share_pro/molecules/cardView.vue';
import categoryBreakdown from '@/components/pages/insights/amazon/market_share_pro/molecules/categoryBreakdown.vue';
import {
  msProOverviewConstants,
  overviewSortMetrics,
  getOverviewSortConfiguration
} from '@/components/pages/insights/amazon/market-share/utils';
import sorting from '@/components/pages/insights/amazon/market_share_pro/atoms/sorting.vue';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
export default {
  components: {
    cardView,
    categoryBreakdown,
    sorting,
    loader
  },
  props: {
    globalViewId: {
      type: Number,
      required: true
    },
    page: {
      type: String,
      default: ''
    },
    globalSettings: {
      type: Object,
      default: {}
    },
    filterData: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      widgetTitle: 'MARKET SHARE BY CATEGORIES',
      widgetDescription: 'Click on category name to view category details.',
      graphHeader:
        'In the cards below, the chart reflects your Market Share over the past 4 weeks, from the last selected date.',
      isPageDetailsFetched: false,
      cardWidget: '',
      defaultView: true,
      sortConfiguration: getOverviewSortConfiguration(),
      actionIcon: {
        icon: 'download',
        loading: false,
        tippyTitle:
          'Download Excel with category and subcategories level Market Share data for the selected period for the selected categories'
      },
      tempDownloadText:
        'Please switch to table view for this download while we continue to work on this functionality. We appreciate your patience.'
    };
  },
  computed: {
    getCategoryCount() {
      return this.globalSettings?.where?.dimensionNameValueList.length;
    }
  },
  created() {
    this.cardWidget = msProOverviewConstants.cardWidget;
    eventBus.$on('overview-breakdown-download-initiated', () => {
      this.actionIcon.loading = false;
    });
  },
  destroyed() {
    eventBus.$off('overview-breakdown-download-initiated');
  },
  methods: {
    switchBetweenViews() {
      this.defaultView = !this.defaultView;
    },
    getMetricsListForSort() {
      return Object.entries(overviewSortMetrics).map(([label, value]) => ({
        label,
        value
      }));
    },
    handleMetricSortSelected(metricSortSelected) {
      localStorage.setItem(
        'overviewSortConfiguration',
        JSON.stringify(metricSortSelected)
      );
      this.sortConfiguration = metricSortSelected;
    },
    handleDownload() {
      this.actionIcon.loading = true;
      eventBus.$emit('breakdown-download-clicked');
    }
  }
};
</script>

<style lang="css" scoped>
.graph-header-container {
  display: flex;
  align-items: center;
}

.line-dimensions {
  height: 26px;
  width: 1px;
}

.graph-header .info-icon {
  display: flex;
  width: 13px;
  height: 13px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.switch-panel {
  height: 2.4rem;
}

.widget-first {
  background: #f5f7fa;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}
.widget-first .category-group-card-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}
.widget-first .is-expanded {
  position: relative;
}
.widget-first .is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 16px;
  width: 100%;
  bottom: -16px;
  position: absolute;
  left: 0em;
  clip-path: inset(0px -2px 0px -2px);
  z-index: 1;
}
</style>
