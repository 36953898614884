<template>
  <div
    v-if="!loading"
    class="u-width-100 hover-effect"
  >
    <div
      class="u-display-flex u-flex-direction-row u-width-100 u-border-radius-s"
      :class="showTippy && 'u-word-break'"
      @mousedown.prevent
      @click="receipientToggle"
    >
      <div
        class="user-container u-spacing-mv-s u-width-100 u-display-flex u-cursor-default u-flex-justify-content-space-between u-flex-align-items-center"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-width-100"
        >
          <!-- eslint-disable -->
          <userAvatar
            :text="this.mail"
            :tippy-props="tippyProps"
            :extra-text-class="'hover-on-element'"
            class="u-cursor-pointer"
            icon-text-spacing="u-spacing-mr-s"
          />
          <!-- eslint-enable -->
          <div
            v-if="isEditable"
            class="trash-icon"
            @click.stop="handleDelete"
          >
            <rb-icon
              class="rb-icon--xx-medium u-cursor-pointer"
              :icon="'trash'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="u-width-100"
  >
    <div class="u-display-block u-flex-justify-content-center">
      <loader
        class="u-bg-color-grey-white u-spacing-mv-s"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import userAvatar from '@/components/widgets/userAvatar';
export default {
  components: {
    loader,
    userAvatar
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    mail: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'ldapUsers'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    showTippy: {
      type: Boolean,
      default: true
    },
    numActiveUsers: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    getDeleteStatusLoad() {
      return this.$store.getters.getDeleteStatus.load;
    },
    isEmailFormOpen() {
      return this.$store.getters.isEmailFormOpen;
    },
    tippyProps() {
      return {
        title: this.tippyValue,
        placement: 'bottom-end',
        offset: '10, 0',
        arrow: false,
        interactive: false,
        distance: 10
      };
    },
    tippyValue() {
      if (this.showTippy) {
        if (!this.isEmailFormOpen) {
          return 'Create A Report';
        }
        if (this.isActive) {
          return 'Remove From Recipient List';
        } else {
          return 'Add To Recipient List';
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    receipientToggle() {
      this.$emit('toggleReceipient', {
        receipient: this.mail,
        type: this.type
      });
    },
    handleDelete() {
      const payload = { id: this.id, mail: this.mail };
      this.$emit('deleteUser', payload);
    }
  }
};
</script>

<style lang="css" scoped>
.hover-effect .trash-icon {
  visibility: hidden;
}
.hover-effect:hover .trash-icon {
  visibility: visible;
}
.receipients-component {
  background-color: #ffffff;
  flex: 1;
}

.receipients-component:hover {
  color: blue;
}

.center-container {
  display: flex;
}
</style>
