<template>
  <div
    v-if="showWelcome"
    class="welcome--board u-display-flex u-height-100 u-width-100 u-flex-align-items-center u-flex-direction-column fill--parent"
  >
    <div class="page page-fixed-sider u-width-100">
      <section class="u-sidebar u-position-relative u-display-flex">
        <section
          class="menu-container"
          :class="{ collapsed: isCollapsed }"
          @click.stop="isCollapsed ? toggle() : ''"
        >
          <section class="u-height-100 u-display-flex u-flex-direction-column">
            <div
              class="logo u-flex-justify-content-flex-start u-flex-align-items-center u-display-flex u-width-100 u-color-grey-white u-spacing-ph-m u-spacing-pv-l"
            >
              <img
                src="/images/long_logo.svg"
                alt="logo"
                class="u-cursor-pointer"
                @click.stop="navigateToHome()"
              />
            </div>
            <section
              class="u-spacing-mh-m u-spacing-mb-l u-spacing-mt-0"
              :class="{ 'u-pointer-events-none': retailerOption.length === 1 }"
            >
              <rb-select
                class="u-max-width-100"
                :send-details="true"
                :on-close="templateSelected"
                :options="retailerOption"
                :search-enable-threshold="50"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-bg-color-grey-light u-cursor-pointer optionsDropdown u-spacing-pt-s u-spacing-pl-m u-spacing-pb-s u-spacing-pr-s u-border-radius-s"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-color-white-base"
                  >
                    <span class="u-font-size-6">{{
                      selectedRetailer && selectedRetailer.id
                    }}</span>
                  </div>
                  <rb-icon
                    v-if="retailerOption.length > 1"
                    class="rb-icon--medium u-spacing-ml-s u-color-white-base"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-width-100 retailerDropdown"
                    :title="option.id"
                  >
                    {{ option.id }}
                  </div>
                </template>
              </rb-select>
            </section>
            <section
              v-if="searchMenuConfig"
              class="menu search_for_sku u-spacing-mb-s"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l"
                :class="{
                  active:
                    (selectedMenuItem && selectedMenuItem.title) ===
                    searchMenuConfig.title
                }"
                @click="selectedMenu(searchMenuConfig)"
              >
                <rb-icon
                  class="rb-icon--medium u-display-flex"
                  :icon="searchMenuConfig.icon"
                />
                <p
                  class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs singleMenu"
                >
                  {{ searchMenuConfig.title }}
                </p>
              </div>
            </section>
            <section
              class="my-workspace u-border-bottom u-border-color-white-opacity-30 u-border-width-s u-position-relative"
              :class="{
                'u-spacing-pb-l': bookmarks.length === 0 || isBookmarkCollapsed
              }"
            >
              <div
                class="u-color-orange-base u-font-size-6 u-spacing-mh-m u-text-transform-uppercase u-font-weight-600 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                :class="{ 'u-cursor-pointer': bookmarks.length !== 0 }"
                @click.stop="bookmarkToggle()"
              >
                <div>
                  My Workspace
                  <span v-if="bookmarks.length !== 0"
                    >({{ bookmarks.length }})</span
                  >
                </div>
                <rb-icon
                  v-tippy="{ placement: 'top', arrow: false }"
                  class="rb-icon--small u-display-flex u-color-grey-x-light u-display-inline-flex"
                  :icon="isBookmarkCollapsed ? 'arrow1-down' : 'arrow1-up'"
                  :title="
                    isBookmarkCollapsed
                      ? 'Expand My Workspace'
                      : 'Collapse My Workspace'
                  "
                />
              </div>
              <p
                class="desc u-font-size-7 u-spacing-mt-s u-display-flex u-flex-align-items-center u-spacing-ml-m"
              >
                <rb-icon
                  class="rb-icon--medium u-spacing-mr-xs u-color-orange-base"
                  :class="{ 'opacity-0_5': bookmarks.length > 0 }"
                  :icon="'star-empty'"
                />
                <span
                  class="u-spacing-pt-xxs"
                  :class="{ 'opacity-0_5': bookmarks.length > 0 }"
                  >Star any page for easy access.</span
                >
              </p>
            </section>

            <section class="menu u-flex-1 u-overflow-auto">
              <div
                v-for="(item, index) in menu"
                v-if="item.children"
                :key="index"
                class="u-display-flex u-flex-align-items-flex-start u-flex-direction-column u-subMenuItem u-spacing-pt-s"
                :class="{
                  'u-border-bottom u-border-color-white-opacity-30 u-border-width-s u-spacing-pb-m':
                    index === menu.length - 2 &&
                    menu[menu.length - 1].title === 'Actions Log'
                }"
              >
                <div
                  class="menuHeader u-display-flex u-spacing-ph-m u-cursor-pointer u-flex-justify-content-space-between u-spacing-pv-s u-width-100"
                  @click.stop="item.isCollapsed = !item.isCollapsed"
                >
                  <p
                    class="u-font-size-6 u-line-height-1-3 u-font-weight-600 u-display-flex u-spacing-pt-xxs u-text-transform-uppercase u-color-flyweel"
                  >
                    {{ item.title }}
                  </p>
                  <rb-icon
                    v-tippy="{
                      placement: 'top',
                      arrow: false,
                      popperOptions: {
                        modifiers: {
                          preventOverflow: {
                            enabled: false
                          }
                        }
                      }
                    }"
                    :title="
                      item.isCollapsed
                        ? 'Expand ' + item.title
                        : 'Collapse ' + item.title
                    "
                    class="rb-icon--small u-display-flex u-color-grey-x-light"
                    :icon="item.isCollapsed ? 'arrow1-down' : 'arrow1-up'"
                  />
                </div>
                <div
                  v-for="(child, childIndex) in item.children"
                  v-show="!item.isCollapsed"
                  :key="childIndex"
                  :class="{
                    subMenuWrapper: child.children
                  }"
                  class="u-display-flex u-flex-align-items-center u-menuItem u-width-100"
                >
                  <div
                    class="subMenuContainer u-display-flex u-flex-direction-column u-width-100"
                  >
                    <div
                      class="subMenuTitle u-display-flex u-spacing-pv-s u-spacing-ph-m u-cursor-pointer"
                      :class="{
                        active:
                          !child.children &&
                          (selectedMenuItem && selectedMenuItem.title) ===
                            child.title
                      }"
                      @click.stop="
                        !child.children
                          ? selectedMenu(child)
                          : handleMenuChild(child)
                      "
                    >
                      <rb-icon
                        class="rb-icon--medium u-display-flex"
                        :icon="child.icon"
                      />
                      <p
                        class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs u-media-font-size-5"
                      >
                        {{ child.title }}
                      </p>
                    </div>

                    <div
                      v-for="(subChild, subChildIndex) in child.children"
                      v-show="!child.isCollapsed"
                      :key="subChildIndex"
                      class="u-display-flex u-flex-align-items-center u-spacing-pv-s u-spacing-ph-l u-menuItem u-cursor-pointer"
                      :class="{
                        'active u-cursor-pointer':
                          child.children &&
                          (selectedMenuItem && selectedMenuItem.title) ===
                            subChild.title,
                        'u-subMenu-active':
                          child.children && isMenuItemPresent(child)
                      }"
                    >
                      <p
                        class="u-font-size-5 u-display-flex singleMenu"
                        @click.stop="selectedMenu(subChild)"
                      >
                        {{ subChild.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="!item.hideInMenu"
                class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l"
                :class="{
                  active:
                    (selectedMenuItem && selectedMenuItem.title) === item.title,
                  'u-border-top u-border-color-white-opacity-30 u-border-width-s':
                    index === menu.length - 2 &&
                    menu[menu.length - 1].title === 'Actions Log',
                  'u-spacing-mt-m': index === menu.length - 1 && menu.length > 1
                }"
                @click="selectedMenu(item)"
              >
                <rb-icon
                  class="rb-icon--medium u-display-flex"
                  :icon="item.icon"
                />
                <p
                  class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs singleMenu"
                >
                  {{ item.title }}
                </p>
              </div>
            </section>

            <section
              class="menu-footer u-border-top u-border-color-white-opacity-30 u-border-width-s u-spacing-pv-l"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-pb-s u-spacing-ph-m u-menuItem u-cursor-pointer u-media-ph-l singleMenu"
                @click="selectedMenu({ path: 'tools_and_settings' })"
              >
                <rb-icon
                  class="rb-icon--medium u-display-flex"
                  :icon="'tools_and_settings'"
                />
                <p
                  class="u-font-size-6 u-display-flex u-spacing-ml-s u-spacing-pt-xxs"
                >
                  Tools and Settings
                </p>
              </div>
              <ChromePlugin
                v-if="$store.getters.getRetailProgramConfig === 'amazonretail'"
              />
            </section>
          </section>
        </section>
        <section class="u-flex-1 u-bg-color-grey-white u-position-relative">
          <loader
            class="fill--parent u-bg-color-grey-white"
            :loading="true"
            :color="'#007cf6'"
          />
        </section>
      </section>
    </div>
  </div>
</template>
<script>
import constants from '@/utils/constants';
import loader from '@/components/basic/loader';
import Vue from 'vue';
import {
  retailConfig,
  retailersMenuConfig
} from '@/router/primaryTabsV2Config.js';
import utils from '@/utils/helpers/';
import { cloneDeep } from 'lodash';
import ChromePlugin from '@/components/basic/chromePlugin.vue';

export default {
  components: {
    loader,
    ChromePlugin
  },
  data() {
    const staticRetailer = [];
    let retailerOption = [];
    const isKrogerConfigPresent =
      Vue.options.filters.config_check('feature.kroger');
    const isKrogerPromoteIqConfigPresent = Vue.options.filters.config_check(
      'feature.kroger_promoteiq'
    );
    const isChewyPromoteIqConfigPresent = Vue.options.filters.config_check(
      'feature.chewy_promoteiq'
    );
    retailerOption.push({ id: 'Amazon Retail', key: 'amazonretail' });

    if (Vue.options.filters.config_check('feature.demo.product_demo.enable')) {
      retailerOption.unshift({ id: 'All Retailers', key: 'allretail' });
    }

    if (Vue.options.filters.config_check('feature.demo.product_demo.enable')) {
      staticRetailer.push({ id: 'Best Buy', key: 'amazonretail' });
      staticRetailer.push({ id: 'Costco', key: 'amazonretail' });
      staticRetailer.push({ id: 'Gopuff', key: 'amazonretail' });
      staticRetailer.push({ id: 'Petco', key: 'amazonretail' });
      staticRetailer.push({ id: 'Kroger', key: 'amazonretail' });
      staticRetailer.push({ id: 'HomeDepot', key: 'amazonretail' });
      staticRetailer.push({ id: 'Ebay', key: 'amazonretail' });
    }

    if (Vue.options.filters.config_check('feature.fresh')) {
      retailerOption.push({ id: 'Amazon Fresh', key: 'amazonfresh' });
    }
    if (Vue.options.filters.config_check('feature.amazon_3p')) {
      retailerOption.push({
        id: 'Amazon 3P',
        key: 'amazon_3pretail'
      });
    }
    if (Vue.options.filters.config_check('feature.walmart')) {
      retailerOption.push({ id: 'Walmart', key: 'walmartretail' });
    }
    if (Vue.options.filters.config_check('feature.target')) {
      retailerOption.push({ id: 'Target (Criteo)', key: 'targetretail' });
    }
    if (Vue.options.filters.config_check('feature.instacart')) {
      retailerOption.push({ id: 'Instacart', key: 'instacartretail' });
    }
    if (Vue.options.filters.config_check('feature.homedepot')) {
      retailerOption.push({ id: 'Homedepot', key: 'homedepotretail' });
    }
    if (Vue.options.filters.config_check('feature.wayfair')) {
      retailerOption.push({ id: 'Wayfair', key: 'wayfairretail' });
    }
    if (Vue.options.filters.config_check('feature.overstock')) {
      retailerOption.push({ id: 'Overstock', key: 'overstockretail' });
    }
    if (Vue.options.filters.config_check('feature.lowes')) {
      retailerOption.push({ id: 'Lowes', key: 'lowesretail' });
    }
    if (Vue.options.filters.config_check('feature.samsclub')) {
      retailerOption.push({ id: "Sam's Club (Criteo)", key: 'samsclubretail' });
    }
    if (Vue.options.filters.config_check('feature.samsclub_api')) {
      retailerOption.push({
        id: "Sam's Club (API)",
        key: 'samsclub_apiretail'
      });
    }
    if (Vue.options.filters.config_check('feature.chewy')) {
      retailerOption.push({ id: 'Chewy', key: 'chewyretail' });
    }
    if (Vue.options.filters.config_check('feature.albertsons_citrus')) {
      retailerOption.push({ id: 'Albertsons', key: 'albertsons_citrusretail' });
    }
    if (Vue.options.filters.config_check('feature.shipt_citrus')) {
      retailerOption.push({ id: 'Shipt', key: 'shipt_citrusretail' });
    }
    if (Vue.options.filters.config_check('feature.shipt_criteo')) {
      retailerOption.push({ id: 'Shipt (Criteo)', key: 'shipt_criteoretail' });
    }
    if (Vue.options.filters.config_check('feature.walmart_criteo')) {
      retailerOption.push({
        id: 'Walmart (Criteo)',
        key: 'walmart_criteoretail'
      });
    }
    if (Vue.options.filters.config_check('feature.hyvee_citrus')) {
      retailerOption.push({ id: 'Hyvee', key: 'hyvee_citrusretail' });
    }
    if (Vue.options.filters.config_check('feature.shoprite_citrus')) {
      retailerOption.push({ id: 'Shoprite', key: 'shoprite_citrusretail' });
    }
    if (Vue.options.filters.config_check('feature.costco')) {
      retailerOption.push({ id: 'Costco', key: 'costcoretail' });
    }
    if (Vue.options.filters.config_check('feature.meijer')) {
      retailerOption.push({ id: 'Meijer', key: 'meijerretail' });
    }

    if (Vue.options.filters.config_check('feature.ulta')) {
      retailerOption.push({ id: 'Ulta', key: 'ulta_criteoretail' });
    }

    if (Vue.options.filters.config_check('feature.albertsons')) {
      retailerOption.push({
        id: 'Albertsons (Criteo)',
        key: 'albertsons_criteoretail'
      });
    }

    if (Vue.options.filters.config_check('feature.ubereats_criteo')) {
      retailerOption.push({
        id: 'Uber Eats',
        key: 'ubereats_criteoretail'
      });
    }

    if (Vue.options.filters.config_check('feature.target_rms')) {
      retailerOption.push({
        id: 'Target RMS',
        key: 'target_rmsretail'
      });
    }

    if (Vue.options.filters.config_check('feature.target_citrus')) {
      retailerOption.push({
        id: 'Target (Citrus)',
        key: 'target_citrusretail'
      });
    }
    if (Vue.options.filters.config_check('feature.bestbuy')) {
      retailerOption.push({ id: 'Best Buy', key: 'bestbuyretail' });
    }

    if (Vue.options.filters.config_check('feature.ahold_citrus')) {
      retailerOption.push({
        id: 'Ahold',
        key: 'ahold_citrusretail'
      });
    }

    if (isKrogerConfigPresent) {
      retailerOption.push({
        id: 'Kroger',
        key: 'krogerretail'
      });
    }

    if (isKrogerPromoteIqConfigPresent) {
      retailerOption.push({
        id: 'Kroger (PromoteIQ)',
        key: 'kroger_promoteiqretail'
      });
    }

    if (isChewyPromoteIqConfigPresent) {
      retailerOption.push({
        id: 'Chewy (PromoteIQ)',
        key: 'chewy_promoteiqretail'
      });
    }

    Object.keys(retailersMenuConfig).forEach((retailer) => {
      const { retailerConfig, id, key } = retailersMenuConfig[retailer];
      const isConfigPresent = Vue.options.filters.config_check(retailerConfig);
      if (isConfigPresent) {
        retailerOption.push({
          id,
          key
        });
      }
    });

    retailerOption = [...retailerOption, ...staticRetailer];

    return {
      isCollapsed: false,
      isBookmarkCollapsed: false,
      selectedMenuItem: {},
      menu: [],
      selectedBookmark: {},
      retailerOption: retailerOption,
      subscribeForMutations: ['bookmarkStore/SET_BOOKMARKS', 'SET_ROOT_PATH'],
      menuClickSource: -1,
      searchMenuConfig: null,
      bookmarks: [],
      selectedRetailer: {}
    };
  },
  computed: {
    showWelcome() {
      const publicPages = constants.publicPages;
      if (publicPages.indexOf(this.$route.path) !== -1) {
        this.$store.dispatch('showWelcome', false);
        return false;
      } else {
        return this.$store.getters.showWelcome;
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'setRetailerMenu'
    }
  },
  beforeCreate() {
    this.$store.dispatch('showWelcome', true);
  },
  methods: {
    isMenuItemPresent(menu) {
      return (
        menu?.children.findIndex(
          (item) => item.title === this.selectedMenuItem?.title
        ) !== -1
      );
    },
    templateSelected() {},
    setRetailerMenu() {
      const key =
        this.$store.getters.getRetailer + this.$store.getters.getProgram ||
        'amazonretail';
      // eslint-disable-next-line dot-notation
      this.selectedRetailer['key'] = key;
      if (!this.selectedRetailer?.key) {
        return;
      }
      const validTabs = [];
      const tabsConfig = retailConfig[this.selectedRetailer.key];
      const isAmazonEnabled = this.retailerOption?.findIndex(
        (item) => item.key === 'amazonretail'
      );
      if (isAmazonEnabled !== -1) {
        this.searchMenuConfig =
          tabsConfig[tabsConfig?.findIndex((item) => item.hideInMenu)];
      }

      for (let index = 0; index < tabsConfig.length; index++) {
        if (tabsConfig[index].configCheckRequired) {
          if (!tabsConfig[index].children) {
            // No children
            if (utils.tabsConfigCheckPassV2.call(this, tabsConfig[index])) {
              validTabs.push(tabsConfig[index] || []);
            }
          } else if (tabsConfig[index].children) {
            // Tab with single children
            const subChild = cloneDeep(tabsConfig[index]);
            subChild.children = [];
            for (let i = 0; i < tabsConfig[index]?.children?.length; i++) {
              if (
                utils.tabsConfigCheckPassV2.call(
                  this,
                  tabsConfig[index]?.children[i] || []
                )
              ) {
                subChild.children.push(tabsConfig[index].children[i]);
              }
            }

            if (subChild.children?.length > 0) {
              validTabs.push(subChild);
            }
          }
        } else {
          validTabs.push(tabsConfig[index]);
        }
      }
      this.menu = [...validTabs];
    }
  }
};
</script>

<style lang="css" scoped>
.u-border-color-white-opacity-30 {
  border-color: rgba(228, 228, 228, 0.3);
}
</style>
