<template>
  <div
    v-histogram="histogramData"
    class="u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default"
    :class="cellAlignment"
    @click.stop
  >
    <!-- eslint-disable -->
    <div class="u-display-flex u-width-100 u-flex-direction-column">
      <div
        slot="title"
        v-tippy="{
          placement: 'top-start',
          //offset: '-8, -50',
          delay: [500, 0],
          animation: 'scale',
          interactive: true,
          maxWidth: '200px',
          theme: 'click-to-expand',
          duration: [150, 0]
        }"
        :class="finalClassList"
        :title="displayValue"
        v-html="displayValue"
        data-cy="CellValue"
      >
        <!-- eslint-enable -->
        {{ getDisplayValue(displayValue) }}
        <span
          v-if="params.colDef.editable"
          data-cy="CellEDit"
          @click="startCellEdit()"
        >
          <rb-icon
            class="u-cursor-pointer u-color-grey-lighter u-spacing-ml-s"
            :icon="'pencil'"
          />
        </span>
      </div>
      <p
        v-if="subtitleText"
        class="subtitleTextClass cell--content u-color-grey-lighter u-spacing-mt-xs"
        data-cy="subTitleTxt"
      >
        {{ subtitleText }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { getLocale } from '@/utils/helpers/formatter';

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'genericTableCell',
  computed: {
    displayValue() {
      if (
        this.params.value === undefined ||
        this.params.value === null ||
        this.params.value === ''
      ) {
        return 'NA';
      }
      return this.params.formatterFn
        ? this.params.formatterFn(this.params.value, this.params.keyType)
        : this.params.value;
    },
    cellAlignment() {
      if (this.params.cellAlignment) {
        return this.params.cellAlignment;
      }
      if (this.params.keyType === 'string') {
        return 'u-flex-justify-content-flex-start';
      } else {
        return 'u-flex-justify-content-flex-end';
      }
    },
    finalClassList() {
      const textColor = this.params.textColor
        ? this.params.textColor
        : 'u-color-grey-base';
      return (
        `cell--content u-white-space-normal u-flex-shrink-past-min-size ${textColor} u-overflow-hidden ` +
        (this.params.additionalClasses ? this.params.additionalClasses : '')
      );
    },
    histogramData() {
      return {
        data: this.params.data,
        config: this.params.histogramConfig
      };
    },
    subtitleText() {
      return this.params.data.subtitleText
        ? this.params.data.subtitleText
        : undefined;
    }
  },
  mounted() {
    var clamp = Math.ceil((this.params.eGridCell.clientHeight - 32) / 16);
    clamp = clamp > 0 ? clamp : 1;
    this.params.eGridCell.classList.add('u-clamp-' + clamp);
    // remove the click to expand feature if no ellipsis is present
    const localeString = Math.round(this.params.value).toLocaleString(
      getLocale(),
      {
        minimumFractionDigits: 0
      }
    );
    this.$nextTick(() => {
      let toExpand = false;
      let isNumericalCell = false;
      const el = this.$el.querySelector('.cell--content');
      if (
        typeof this.params.value === 'number' &&
        (this.params.keyType === 'numeric' ||
          this.params.keyType === 'currency')
      ) {
        el.title = `${
          this.displayValue.includes(`${Vue.prototype.$currency}`)
            ? Vue.prototype.$currency
            : ''
        }${localeString}`;
      }
      if (
        el.clientHeight < el.scrollHeight - 1 ||
        el.clientWidth < el.scrollWidth
      ) {
        toExpand = true;
      } else if (el.children && el.children.length > 0) {
        const children = el.querySelectorAll('*');
        for (const child of children) {
          if (
            child.clientWidth < el.scrollWidth ||
            child.clientWidth < child.scrollWidth
          ) {
            toExpand = true;
            break;
          }
        }
      } else if (
        typeof this.params.value === 'number' &&
        (this.params.keyType === 'numeric' ||
          this.params.keyType === 'currency') &&
        localeString.length > 4
      ) {
        toExpand = true;
        isNumericalCell = true;
      }

      if (!toExpand) {
        el._tippy?.destroy();
        el.removeAttribute('title');
        el.removeAttribute('data-original-title');
        el.classList.add('u-cursor-text');
      } else if (isNumericalCell) {
        el.removeAttribute('tippy');
      } else {
        el.classList.add('u-cursor-pointer');
      }
    });
  },
  methods: {
    // make a cell editable on an icon click
    startCellEdit() {
      this.params.api.startEditingCell({
        rowIndex: this.params.rowIndex,
        colKey: this.params.colDef.field
      });
    }
  }
};
</script>

<style lang="css" scoped>
@supports not (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content,
  .u-clamp-2 .cell--content,
  .u-clamp-3 .cell--content,
  .u-clamp-4 .cell--content,
  .u-clamp-5 .cell--content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
}
@supports (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-2 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-3 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-4 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-5 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  ins {
    text-decoration: none;
    color: #3ea95e;
  }
  del {
    text-decoration: line-through;
    color: #d7263d;
    padding-right: 4px;
  }
}
</style>
