import HttpService from '@/utils/services/http-service';
import {
  categoryHierarchyFilterApplication,
  filterValidation
} from '@/components/pages/businessInsights/ms-on-bo';

const state = {
  whereClause: {
    dimensionNameValueList: [],
    date: {},
    pvpDate: {}
  },
  filterPanelRawValues: {},
  globalMaxDate: null,
  globalViewInfo: {},
  selectedFilters: {},
  page_wise_min_max_key: 'BusinessInsights',
  customFilter: {
    category_hierarchy: categoryHierarchyFilterApplication
  }
};

const getters = {
  getWhereClause: (state) => {
    return state.whereClause;
  },
  getGlobalMaxDate: (state) => {
    return state.globalMaxDate;
  },
  getGlobalViewInfo: (state) => {
    return state.globalViewInfo;
  },
  getBusinessInsightsFilters: (state) => {
    return state.selectedFilters;
  }
};

function getFiltersInFormat(oFilter, state, type) {
  var where = state.whereClause;
  if (type !== 'calendar') {
    where.dimensionNameValueList = [];
  }
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (filterValidation(state, where, oFilter, i)) {
        continue;
      } else if (
        oFilter.values[i].length > 0 &&
        oFilter.values[i][0].operator
      ) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const mutations = {
  SETCUSTOMFILTER: (state, data) => {
    state.customFilter = data;
  },
  SETWHERECLAUSEVALUE: (state, data) => {
    if (data && data.values) {
      getFiltersInFormat(data, state);
    } else if (data && data.date_range) {
      getFiltersInFormat({ values: data }, state);
    } else if (data && data.name) {
      getFiltersInFormat({ values: { date_range: data } }, state, 'calendar');
    }
  },
  SETGLOBALMAXDATEVALUE: (state, data) => {
    state.globalMaxDate = data;
  },
  SETGLOBALVIEWINFO: (state, data, type) => {
    const widgetList = ['chartingWb', 'topMovers', 'benchMarking'];
    if (data.type && widgetList.indexOf(data.type) === -1) {
      state.globalViewInfo[data.type] = data[data.type];
    } else if (widgetList.indexOf(data.type) !== -1) {
      if (!state.globalViewInfo?.widgetMetadata) {
        state.globalViewInfo.widgetMetadata = {};
      }
      state.globalViewInfo.widgetMetadata[data.type] = data[data.type];
    } else {
      state.globalViewInfo = data;
    }
  }
};

const actions = {
  setWhereClause: (context, data) => {
    context.commit('SETWHERECLAUSEVALUE', data);
    context.dispatch('benchMarkingWhereUpdate', {
      data: context.getters.getWhereClause,
      storeKey:
        context.getters.getLocation === 'eu'
          ? 'BusinessInsightsEU'
          : 'BusinessInsights'
    });
  },
  setGlobalMaxDate: (context, data) => {
    context.commit('SETGLOBALMAXDATEVALUE', data);
  },
  saveViewAction: (context, data) => {
    let appendAPI = '/global/view/create';
    if (data.globalViewId) {
      appendAPI = '/global/view/update';
    }
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data },
      { append: appendAPI }
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
  },
  deleteView: (context, data) => {
    const endPoint = '/global/view/delete';
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data },
      { append: endPoint }
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
  },
  draftView: (context, data) => {
    const endPoint = '/global/view/draft';
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data },
      { append: endPoint }
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
  },
  setGlobalViewAction: (context, data, type) => {
    context.commit('SETGLOBALVIEWINFO', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
