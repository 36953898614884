<template>
  <section
    class="u-bg-color-grey-white shadows u-border-radius-s u-display-flex u-flex-justify-content-space-between u-flex-direction-column"
  >
    <section
      :class="['u-spacing-pb-' + bottomPadding]"
      class="u-spacing-ph-l u-spacing-pt-l u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <section class="u-display-flex u-flex-align-items-center">
        <div
          class="u-font-size-3 u-font-weight-600 u-color-grey-lighter u-spacing-mr-s u-text-case-upper"
        >
          {{ title }}
        </div>
        <slot name="post-title" />
      </section>
      <section class="u-display-flex u-flex-align-items-center">
        <slot name="pre-filters" />
        <div v-if="showRollUp">
          <select-with-text
            v-for="(filter, index) in filters"
            :key="index"
            :pre-text="filter.preText"
            :options="filter.options"
            :selected-option="filter.selected"
            class="u-spacing-mr-m"
            @optionSelect="
              (payload) => {
                $emit(filter.emit, payload);
              }
            "
          />
        </div>
        <div
          v-for="(actionIcon, index) in actionIcons"
          :key="filters.length + index"
          class="u-color-grey-lighter u-cursor-pointer hover-class u-spacing-pl-s u-display-flex u-flex-justify-content-center u-flex-align-items-center"
        >
          <div
            v-if="!actionIcon.loading"
            v-tippy="{ placement: 'top', arrow: false }"
            :title="actionIcon.tippyTitle"
            class="u-display-flex u-flex-align-items-center u-flex-direction-column"
            @click="$emit(actionIcon.emit, index)"
          >
            <rb-icon
              :class="[
                actionIcon.error ? 'u-color-red-base' : 'u-color-grey-lighter'
              ]"
              :icon="actionIcon.icon"
            />
            <div
              v-if="actionIcon.subText"
              data-cy="actionTitle"
              class="u-font-size-8 u-spacing-mt-xs"
            >
              {{ actionIcon.subText }}
            </div>
          </div>
          <span v-else>
            <loader
              size="20px"
              class="u-bg-color-grey-white"
              :loading="true"
              :color="'#007cf6'"
            />
          </span>
        </div>
      </section>
    </section>
    <div
      v-if="load"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-else-if="!hasHierarchy"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter u-flex-direction-column"
    >
      <slot name="no-hierarchy" />
    </div>
    <div
      v-else-if="error"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="noData"
      data-cy="cardWidget--undefined"
      :style="{ height: intermediateHeight + 'px' }"
      class="u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
    >
      <p>No Data</p>
    </div>
    <div
      v-else
      class=""
    >
      <div
        :class="[
          'u-spacing-ph-' + horizontalPadding,
          'u-spacing-pb-' + bodyBottomPadding
        ]"
      >
        <slot name="body" />
      </div>
    </div>
    <div
      v-if="!load && !error"
      class="u-font-size-6 u-spacing-pv-s u-width-100 u-spacing-ph-l u-display-flex u-flex-align-items-center u-color-grey-base u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <div class="u-width-100 u-display-flex u-flex-align-items-center">
        <div
          class="no-wrap"
          :class="
            lastUpdatedDateClass
              ? lastUpdatedDateClass
              : 'u-color-grey-x-light u-font-size-7'
          "
        >
          Last Updated: {{ lastUpdatedDate }}
        </div>
        <div
          v-if="footerContentSeperator"
          class="content-seperator-footer"
        />
        <slot name="footer-right" />
      </div>
    </div>
  </section>
</template>

<script>
import selectWithText from '@/components/pages/insights/amazon/share-of-voice/molecules/select-with-text.vue';
import loader from '@/components/basic/loader';

export default {
  components: {
    selectWithText,
    loader
  },
  props: {
    lastUpdatedDateClass: {
      type: String
    },
    intermediateHeight: {
      type: Number,
      default: 332
    },
    hasHierarchy: {
      type: Boolean,
      default: true
    },
    bodyBottomPadding: {
      type: String,
      default: 'l'
    },
    horizontalPadding: {
      type: String,
      default: 'l'
    },
    lastUpdatedDate: {
      type: String,
      default: null
    },
    filters: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    },
    actionIcons: {
      type: Array,
      default: () => []
    },
    load: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    bottomPadding: {
      type: String,
      default: 'm'
    },
    showRollUp: {
      type: Boolean,
      default: true
    },
    footerContentSeperator: Boolean
  },
  methods: {
    handleOptionsSelect(payload) {
      this.$emit('optionSelect', payload);
    }
  }
};
</script>

<style lang="css" scoped>
.hover-class .rb-icon:hover {
  color: black !important;
}
.shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.no-wrap {
  word-wrap: normal;
  white-space: nowrap;
}
.content-seperator-footer {
  width: 1px;
  height: 16px;
  left: 159px;
  top: 0px;
  background: #e9eaeb;
  margin: 0px 8px;
}
</style>
