import Campaign from '@/components/ams/campaign_creation/steps/setup/campaign/index.vue';
import Goal from '@/components/ams/campaign_creation/steps/setup/goals/index.vue';
import Settings from '@/components/ams/campaign_creation/steps/setup/settings/index.vue';
import Sku from '@/components/ams/campaign_creation/steps/setup/sku/index.vue';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import {
  addSkuOnInput,
  APIConfig
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/skuEnterListHelpers.js';

const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

const selectSkuLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return params?.data?.product_title + '_' + params?.data?.asin;
          }
        }
      }
    },
    {
      name: 'sku_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'sku_price',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'weeks_of_coverage',
      type: 'STRING',
      uiField: {
        uiLabel: 'Weeks of coverage(Amazon)',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'weeks_of_coverage',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availablity',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const customActionPanelCustomState = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'asin',
          customActionPanelContainerClass:
            'u-height-100 campaign-creation-add-section-min-height',
          isMultipleAllowed: true,
          default: {
            hideHeader: true,
            hideFooter: true,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple line items are not allowed'
          },
          left: {
            rowHeight: 68,
            showTabs: true,
            tabs: {
              "All SKU's": {
                localFilter: {
                  // dimensionName: 'campaign_id',
                  // dimensionValue: context.entityId
                },
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'asin_search_text'
                },
                showFilterCheckBox: false
                // filterCheckBoxSource: {
                //   header: 'Filter by:',
                //   items: ['Broad', 'Phrase', 'Exact']
                // }
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              filters: {
                emit: 'fetchFiltersCampaignCreation',
                hasSearch: false,
                page: 'amazon'
              },
              filterState: {
                getter: 'getExecuteApiTableDataFilters',
                setter: 'fetchTableDataExecuteApiFilters'
              },
              localFilters: [],
              localStoreageFilterKey: null,
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef) => {
                const addedSkus = fetchSavedState(
                  context,
                  'campaignCreation/getAllStepsData'
                )?.skus;
                if (addedSkus?.length) {
                  vueRef.rightTableRows = addedSkus;
                }
              },
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: cloneDeep(APIConfig),
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {
            const adgroupId = context.entityId;
            const campaignId = context.parentEntityId;
            const campaignType = context.entityConfig.entityType;
            return new Promise((resolve, reject) => {
              console.log(
                context.entityId,
                context.parentEntityId,
                context.entityConfig
              );
              const payload = rows.map((row) => ({
                actionType: 'addKeywordToCampaigns',
                actionSource: {
                  pageUrl: window.location.href
                },
                widget: 'campaign',
                primaryKey: campaignId,
                actionPayload: {
                  campaignId,
                  campaignType,
                  adgroupId,
                  new_keyword_text: row.recommended_keyword,
                  new_match_type: row.match_type,
                  new_bid: row.newBid
                },
                viewPayload: {
                  campaignId,
                  campaignType,
                  adgroupId,
                  adgroupName: 'Terminate', // update this to actual ad group name
                  new_keyword_text: row.recommended_keyword,
                  new_match_type: row.match_type,
                  new_bid: row.newBid
                }
              }));
              HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                .then((response) => {
                  vueRef.$emit('afterAPIActionToCall');
                  resolve(true);
                })
                .catch((err) => {
                  // that.openSnackbar('Something went wrong.');
                  reject(err);
                });
            });
          }
        };
      }
    }
  },
  events: {
    rightTableRows(rows) {
      let allConfig =
        fetchSavedState(context, 'campaignCreation/getAllStepsData') || {};
      allConfig = { ...allConfig, ...{ skus: rows } };
      updateSavedState(
        context,
        'campaignCreation/updateAllSetpsData',
        allConfig
      );
    },
    afterAPIActionToCall(payload) {
      this.$emit('openSnackbar', 'Action has been submitted');
      // this.switchBlueBar('line item');
      // this.removeCustomCustomComponents();
      // this.closeActionsWorkflow();
    },
    closePanelMethod() {
      // this.removeCustomCustomComponents();
    }
  }
});

export const roasCube = (profileInfo) => {
  const profile_id = profileInfo.profile;
  const request = {
    cubeName: 'ams_campaigns_campaign_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: ['roi'],
    groupByDimensionsList: ['profile_id'],
    orderByList: [],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'profile_id',
          dimensionValue: profile_id
        }
      ],
      date: {
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      pvpDate: {
        from: null,
        to: null
      }
    },
    tagWhereClause: []
  };
  if (profileInfo?.portfolio) {
    request.groupByDimensionsList.push('portfolio_id');
    request.where.dimensionNameValueList.push({
      dimensionName: 'portfolio_id',
      dimensionValue: profileInfo?.portfolio
    });
  }
  return request;
};

export const spendVsBudgetCube = (profileInfo) => {
  if (!profileInfo?.profile) {
    return null;
  }
  const date = {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  };
  const request = {
    cubeName: 'budget_planner_dashboard_cm',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: ['spend_vs_budget'],
    groupByDimensionsList: [],
    orderByList: [],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'profile_id',
          dimensionValue: profileInfo.profile
        }
      ],
      date
    },
    customAPIDecisionVars: {
      skipTimeSeriesMetric: true,
      system: 'budget_optimizer'
    }
  };
  return request;
};

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  fallbackRouteName: 'create_campaigns',
  closeCreationRouteName: 'create_campaigns',
  closeCreateOptionsRouteName: 'Campaigns',
  customCloseIcon: {
    icon: 'right-arrow',
    class: 'is-flipped rb-icon--large'
  },
  dashboardServicePage: 'cm_campaign_creation',
  stepsConfig: [
    {
      name: 'SKU’s',
      description: 'n SKU’s added',
      customActionPanelCustomState: customActionPanelCustomState,
      // getAsins: {

      // },
      header: {
        title: 'What are the SKUs you want to select to create campaigns?',
        desc: [
          'Select sku’s from the table below to create goal oriented campaigns. Add Sku’s table also consists of few important metrics to help select sku’s in a better way to create campaigns. You can also check the below added sku’s table to create campaigns with single sku’s.'
        ]
      },
      moduleClass: 'campaign-creation-component-height-class',
      keyToRead: 'skus',
      breadCrumbTitle:
        'What are the SKUs you want to select to create campaigns?',
      textMapping: 'skus',
      component: Sku
    },
    {
      name: 'Goals',
      description: 'Select a goal',
      header: {
        title: 'Do you want to create campaign with a few goals?',
        desc: [
          'Select one or more goals from the list below you want to achieve from advertising the SKUs selected in previous step.'
        ]
      },
      keyToRead: 'goals',
      breadCrumbTitle: 'Do you want to create campaign with a few goals?',
      textMapping: 'goals',
      component: Goal
    },
    {
      name: 'Settings',
      description: 'Select a profile and add daily budget',
      budget: 100,
      getProfiles: {
        cubeName: 'client_profile_info',
        measuresList: ['profile_name'],
        groupByDimensionsList: ['profilename', 'profile_id'],
        orderByList: [{ dimension: 'profilename', direction: 'ASC' }]
      },
      getPortfolios: {
        cubeName: 'client_portfolio_info',
        measuresList: ['portfolio_name'],
        groupByDimensionsList: ['portfolio_id', 'profile_id'],
        orderByList: [{ dimension: 'portfolio_name', direction: 'ASC' }]
      },
      header: {
        title:
          'Select profile , portfolio and enter daily budget for the campaigns yet to be created.'
      },
      breadCrumbTitle:
        'Select profile,portfolio and input daily budget for the campaigns yet to be created.',
      keyToRead: 'settings',
      textMapping: 'settings',
      component: Settings
    },
    {
      name: 'Campaigns',
      description: '',
      roas: roasCube,
      spendVsBudget: spendVsBudgetCube,
      header: {
        title: 'All Campaigns',
        desc: [
          'This space includes all campaigns which are further grouped under recommended campaigns ready to be launched and recommended campaigns with pending actions which are created automatically based on goals.'
        ]
      },
      breadCrumbTitle: 'All Campaigns',
      keyToRead: 'campaigns',
      textMapping: 'campaigns',
      component: Campaign
    }
  ],
  enableStepsAfterValidation: true
};
