<template>
  <div class="u-display-flex u-flex-direction-column u-overflow-x-hidden">
    <div
      class="u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-mb-m sticky u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        id="sales-dashboard-filters"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'sales-dashboard-filters'"
        :loader-status="loaderStatus"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="config.filters.hasSearch"
        :new-date="true"
        :allow-date-compare="true"
        :max-date="getSalesDashboardPageWiseMinMaxKey"
        :default-date-post-range="'last7Days'"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :on-create="onCreate"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <span
            class="u-spacing-mr-s back-arrow"
            @click.stop="backButton()"
          >
            <rb-icon
              data-cy="arrow_back"
              :icon="'arrow_back'"
              class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
            />
          </span>
          <PageTitle
            :title="'Sales Overview'"
            :is-bookmark-require-title="true"
          />
        </div>
        <div
          slot="search"
          class="u-spacing-mr-m"
        >
          <asin-search :config="config.filters.search" />
        </div>
      </rb-filter-panel>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
      />
    </div>
    <div class="u-display-flex u-spacing-mh-m u-flex-wrap-yes">
      <!-- OPS  -->
      <div
        class="hover--card u-display-flex u-min-width-33"
        @click="scrollToTable(currentGraph)"
      >
        <card-widget
          v-show="currentGraph === 'ordered_product_sales'"
          class="u-spacing-mr-s u-width-100"
          :config="config.widgets['widget1']"
          :data="
            getSalesDashboardData[config.widgets['widget1'].meta.key] || {}
          "
          data-cy="sales-overview-ops"
        >
          <div slot="body">
            <!-- :data="getSalesDashboardData[config.widgets['widget1'].meta.key] || {}" -->
            <span class="u-display-flex u-flex-align-items-baseline">
              <metric
                :size="'xxl'"
                class="u-display-inline-flex u-spacing-mh-l u-spacing-mb-m"
                :config="config.widgets['widget1'].body.tagConfig"
                :data="
                  (getSalesDashboardData['ordered_product_sales'] || {})
                    .metrics || {}
                "
              />
            </span>
            <chart
              v-if="currentGraph === 'ordered_product_sales'"
              style="height: 200px"
              class="u-spacing-mh-l"
              :config="config.widgets['widget1'].body.chart"
              :data="widget1GraphData"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex u-flex-1"
          >
            <div class="u-color-grey-x-light u-font-size-7">
              Last Updated :
              {{
                getAllDates()[config.widgets['widget1'].footer.date]
                  | formattable_date
              }}
            </div>
            <div
              class="u-display-flex u-text-align-right u-font-weight-600 u-flex-1 anchorContainer"
            >
              <a
                class="u-font-size-6 footer--action u-cursor-pointer"
                @click="switchGraphCard"
              >
                {{ currentGraph === 'ordered_product_sales' ? 'PCOGS' : 'OPS' }}
                <rb-icon
                  class="rb-icon--x-small"
                  :icon="'arrow1-up'"
                  style="transform: rotate(90deg)"
                />
              </a>
            </div>
          </div>
        </card-widget>

        <!-- PCOGS -->
        <card-widget
          v-show="currentGraph === 'pcogs'"
          class="u-spacing-mr-s u-width-100"
          :config="config.widgets['widget18']"
          :data="
            getSalesDashboardData[config.widgets['widget18'].meta.key] || {}
          "
        >
          <div slot="body">
            <!-- :data="getSalesDashboardData[config.widgets['widget1'].meta.key] || {}" -->
            <span class="u-display-flex u-flex-align-items-baseline">
              <metric
                :size="'xxl'"
                class="u-display-inline-flex u-spacing-mh-l u-spacing-mb-m"
                :config="config.widgets['widget1'].body.tagConfig"
                :data="
                  (getSalesDashboardData[currentGraph] || {}).metrics || {}
                "
              />
            </span>
            <chart
              v-if="currentGraph === 'pcogs'"
              style="height: 200px"
              class="u-spacing-mh-l"
              :config="config.widgets['widget18'].body.chart"
              :data="widget18GraphData"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex u-flex-1"
          >
            <div class="u-color-grey-x-light u-font-size-7">
              Last Updated :
              {{
                getAllDates()[config.widgets['widget18'].footer.date]
                  | formattable_date
              }}
            </div>
            <div
              class="u-display-flex u-text-align-right u-font-weight-600 u-flex-1 anchorContainer"
            >
              <a
                class="u-font-size-6 footer--action u-cursor-pointer"
                @click="switchGraphCard"
              >
                {{ currentGraph === 'ordered_product_sales' ? 'PCOGS' : 'OPS' }}
                <rb-icon
                  class="rb-icon--x-small"
                  :icon="'arrow1-up'"
                  style="transform: rotate(90deg)"
                />
              </a>
            </div>
          </div>
        </card-widget>
      </div>

      <!-- Cards created through a loop -->
      <div
        class="u-display-flex u-flex-wrap-yes u-flex-1"
        data-cy="sales-overview-metrics"
      >
        <div
          v-for="(card, cardIdx) in cards"
          v-show="cardIdx < cardCount"
          :key="card.key"
          class="hover--card u-display-flex u-flex-shrink-1 u-min-width-33 u-max-width-33 u-spacing-mb-s"
          @click="scrollToTable(card.tableId)"
        >
          <card-widget
            class="u-flex-wrap-wrap u-spacing-mh-s u-flex-1"
            :data="
              getSalesDashboardData[config.widgets[card.widgetId].meta.key] ||
              {}
            "
            :config="config.widgets[card.widgetId]"
          >
            <div
              slot="header"
              class="card__title cardHeader u-color-grey-lighter"
            >
              <div
                v-tippy="{ placement: 'top' }"
                :title="config.widgets[card.widgetId].header.tooltip"
              >
                <rb-select
                  v-if="arrayDifferentiator(cards, selectedCards)"
                  :width="'260px'"
                  :send-details="true"
                  :context="[cardIdx]"
                  :on-close="cardSelected"
                  :options="arrayDifferentiator(cards, selectedCards)"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
                  >
                    <span
                      class="u-font-size-6 card__title u-color-grey-lighter u-font-weight-600 u-text-case-upper"
                      data-cy="card-header"
                    >
                      {{ card.title }}
                    </span>
                    <rb-icon
                      class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <span class="u-font-size-6 u-text-case-capitalize">
                      {{ option.title }}
                    </span>
                  </template>
                </rb-select>
                <span
                  v-else
                  class="u-font-size-6 card__title u-color-grey-lighter u-font-weight-600 u-text-case-upper"
                  data-cy="card-header"
                >
                  {{ card.title }}
                </span>
              </div>
            </div>
            <div slot="body">
              <metric
                :size="'xl'"
                class="u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-center u-spacing-mb-l"
                :split="true"
                :config="config.widgets[card.widgetId].body.tagConfig"
                :data="cardMetricData(card)"
              />
            </div>
            <div
              slot="footer"
              class="u-width-100 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
              :class="{ 'u-negative-margin-s': card.tooltipText }"
            >
              <span class="u-color-grey-x-light u-font-size-7"
                >Last Updated :
                {{
                  getAllDates()[config.widgets[card.widgetId].footer.date]
                    | formattable_date
                }}</span
              >
              <span v-if="card.tooltipText">
                <div
                  :id="cardFooterTippyId"
                  class="footer-tooltip u-display-flex u-spacing-ph-m u-spacing-mt-m u-spacing-mb-m"
                >
                  <div class="u-display-flex u-flex-direction-column">
                    <div class="u-spacing-pb-s u-font-weight-600 u-font-size-2">
                      <span class="u-color-blue-base">IQ</span> Tip
                    </div>
                    <!-- eslint-disable -->
                    <div
                      class="u-display-flex u-color-grey-base u-flex-direction-column u-font-size-6 u-spacing-mt-s"
                      v-html="card.tooltipText"
                    />
                    <!-- eslint-enable -->
                  </div>
                </div>
                <span v-tippy="cardFooterTippy">
                  <rb-icon
                    class="rb-icon--medium u-color-blue-base tool-tip-hover-sales-overview u-cursor-pointer"
                    icon="light-bulb"
                  />
                </span>
              </span>
            </div>
          </card-widget>
        </div>
      </div>
    </div>

    <div
      v-for="(item, index) in tableItems"
      v-show="
        (item.widgetKey === currentGraph &&
          ['pcogs', 'ordered_product_sales'].indexOf(item.widgetKey) > -1) ||
        (item.widgetKey !== 'pcogs' &&
          item.widgetKey !== 'ordered_product_sales' &&
          item.widgetKey !== 'avg_selling_price') ||
        (currentGraph === 'ordered_product_sales' &&
          item.widgetKey === 'avg_selling_price')
      "
      :id="item.id"
      :key="index"
      class="u-display-flex u-flex-wrap-yes u-spacing-mr-l u-spacing-ml-m"
    >
      <card-widget
        v-if="item.id !== 'price_war' && item.id !== 'glance_views'"
        class="u-flex-1 u-spacing-mv-m u-width-100"
        :config="config.widgets[item.widgetId]"
        :data="
          getSalesDashboardData[config.widgets[item.widgetId].meta.key] || {}
        "
        :no-data-message="'No data available for selected filters'"
      >
        <div slot="body">
          <el-table
            :border="true"
            height="394"
            empty-text="No Data"
            class="u-width-100 no-padding-first-cell table-header-font-size-m el-table--scrollable-x el-table--scrollable-y"
            :data="
              (
                getSalesDashboardData[config.widgets[item.widgetId].meta.key] ||
                {}
              ).rows
            "
            :default-sort="{ prop: item.sortKey, order: 'descending' }"
            @sort-change="sortTable"
          >
            <el-table-column
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              :column-key="item.widgetId"
              :fit="false"
              :label="'SKU Details'"
              :sortable="false"
              :fixed="true"
              :width="300"
            >
              <rb-sku
                slot-scope="scope"
                :image-url="scope.row.image_url"
                :title="scope.row.title"
                :asin="scope.row.asin"
                :product-page-url="scope.row.asin_hyperlink"
                :enable-s-d-p="true"
                @navigateToSDP="navigateToSDP"
              />
            </el-table-column>

            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_PVP_DIFF_' + item.widgetKey"
              :render-header="appendToolTip"
              :column-key="item.widgetId"
              :label="item.displayName + ' Change'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span v-if="item.widgetKey !== 'ordered_units'">
                  {{
                    scope.row['PVP_PVP_DIFF_' + item.widgetKey]
                      | num_format(getUnit(item.widgetKey)) ||
                    checkNull(scope.row['PVP_PVP_DIFF_' + item.widgetKey])
                  }}</span
                >
                <span v-if="item.widgetKey === 'ordered_units'">
                  {{
                    formatterFn(
                      scope.row['PVP_PVP_DIFF_' + item.widgetKey],
                      'number'
                    ) || checkNull(scope.row['PVP_PVP_DIFF_' + item.widgetKey])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_PVP_' + item.widgetKey"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="item.displayName + ' Change %'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    formatterFn(
                      scope.row['PVP_PVP_' + item.widgetKey],
                      'percent'
                    )
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="item.widgetKey"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Current Period ' + item.displayName"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span v-if="item.widgetKey !== 'ordered_units'">
                  {{
                    scope.row[item.widgetKey]
                      | num_format(getUnit(item.widgetKey)) ||
                    checkNull(scope.row[item.widgetKey])
                  }}</span
                >
                <span v-if="item.widgetKey === 'ordered_units'">
                  {{
                    formatterFn(scope.row[item.widgetKey], 'number') ||
                    checkNull(scope.row[item.widgetKey])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_' + item.widgetKey"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Previous Period ' + item.displayName"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span v-if="item.widgetKey !== 'ordered_units'">
                  {{
                    scope.row['PVP_' + item.widgetKey]
                      | num_format(getUnit(item.widgetKey)) ||
                    checkNull(scope.row['PVP_' + item.widgetKey])
                  }}</span
                >
                <span v-if="item.widgetKey === 'ordered_units'">
                  {{
                    formatterFn(scope.row['PVP_' + item.widgetKey], 'number') ||
                    checkNull(scope.row['PVP_' + item.widgetKey])
                  }}</span
                >
              </div>
            </el-table-column>

            <!-- Specific columns for Units Table -->
            <el-table-column
              v-if="item.widgetKey === 'ordered_units'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_PVP_DIFF_' + currentGraph"
              :render-header="appendToolTip"
              :column-key="item.widgetId"
              :label="'Revenue Change'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    formatCurrency(scope.row['PVP_PVP_DIFF_' + currentGraph])
                  }}
                </span>
              </div>
            </el-table-column>

            <el-table-column
              v-if="item.widgetKey === 'ordered_units'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_PVP_' + currentGraph"
              :render-header="appendToolTip"
              :column-key="item.widgetId"
              :label="'Revenue Change %'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['PVP_PVP_' + currentGraph]
                      | num_format('', '%') ||
                    checkNull(scope.row['PVP_PVP_' + currentGraph])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              v-if="item.widgetKey === 'ordered_units'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="currentGraph"
              :render-header="appendToolTip"
              :column-key="item.widgetId"
              :label="' Current Period Revenue'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row[currentGraph] | num_format('currency') ||
                    checkNull(scope.row[currentGraph])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              v-if="item.widgetKey === 'ordered_units'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_' + currentGraph"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="' Previous Period Revenue'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['PVP_' + currentGraph] | num_format('currency') ||
                    checkNull(scope.row['PVP_' + currentGraph])
                  }}</span
                >
              </div>
            </el-table-column>
            <!-- Specific columns for Units Table -->

            <!-- Specific columns for Margin & OPS only available for OPS selection -->
            <el-table-column
              v-if="
                avgSelllingPriceTables.indexOf(item.widgetKey) > -1 &&
                currentTable === 'ops'
              "
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'avg_selling_price'"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'ASP Current'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['avg_selling_price'] | num_format('currency') ||
                    checkNull(scope.row['avg_selling_price'])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              v-if="
                avgSelllingPriceTables.indexOf(item.widgetKey) > -1 &&
                currentTable === 'ops'
              "
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'PVP_avg_selling_price'"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'ASP Previous'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['PVP_avg_selling_price']
                      | num_format('currency') ||
                    checkNull(scope.row['PVP_avg_selling_price'])
                  }}</span
                >
              </div>
            </el-table-column>
            <!-- Specific columns for Margin & OPS table only available for OPS selection -->

            <!-- Specific columns for PCOGS table only available for PCOGS selection -->
            <el-table-column
              v-if="currentTable === 'pcogs' && item.widgetKey === 'pcogs'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              prop="unit_cogs"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Unit Cogs'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['unit_cogs'] | num_format('currency') ||
                    checkNull(scope.row['unit_cogs'])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              v-if="currentTable === 'pcogs' && item.widgetKey === 'pcogs'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              prop="units_shipped"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Shipped Units Current'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    formatterFn(scope.row['units_shipped'], 'number') ||
                    checkNull(scope.row['units_shipped'])
                  }}</span
                >
              </div>
            </el-table-column>

            <el-table-column
              v-if="currentTable === 'pcogs' && item.widgetKey === 'pcogs'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              prop="PVP_units_shipped"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Shipped Units Previous'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    formatterFn(scope.row['PVP_units_shipped'], 'number') ||
                    checkNull(scope.row['PVP_units_shipped'])
                  }}</span
                >
              </div>
            </el-table-column>
            <!-- Specific columns for PCOGS table only available for PCOGS selection -->

            <!-- Specific columns for ASP table -->
            <el-table-column
              v-if="item.widgetKey === 'avg_selling_price'"
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :prop="'ordered_revenue_last_30days'"
              :column-key="item.widgetId"
              :render-header="appendToolTip"
              :label="'Last 4 Weeks Revenue'"
              :sortable="'custom'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row['ordered_revenue_last_30days']
                      | num_format('currency') ||
                    checkNull(scope.row['ordered_revenue_last_30days'])
                  }}</span
                >
                <span class="u-display-none">{{ item.displayName }}</span>
              </div>
            </el-table-column>
            <!-- Specific columns for ASP table -->
          </el-table>
        </div>
        <div slot="footer-right">
          <span
            class="footer--action u-cursor-pointer u-display-flex u-flex-align-items-center"
            @click="scrollToTop"
          >
            <span class="u-font-size-7"> Scroll to top </span>
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs"
              :icon="'sort-asc'"
            />
          </span>
        </div>
      </card-widget>

      <!-- table of glance views -->
      <card-widget
        v-if="item.id === 'glance_views'"
        class="u-flex-1 u-spacing-mv-m u-width-100"
        :config="config.widgets[item.widgetId]"
        :data="
          getSalesDashboardData[config.widgets[item.widgetId].meta.key] || {}
        "
        :no-data-message="'No data available for selected filters'"
      >
        <div slot="body">
          <div
            class="u-width-100"
            style="min-height: 394px"
          >
            <el-table
              v-if="
                !(
                  getSalesDashboardData[
                    config.widgets[item.widgetId].meta.key
                  ] || {}
                ).load
              "
              :border="true"
              height="394"
              empty-text="No Data"
              class="no-padding-first-cell table-header-font-size-m el-table--scrollable-x el-table--scrollable-y"
              :data="
                (
                  getSalesDashboardData[
                    config.widgets[item.widgetId].meta.key
                  ] || {}
                ).rows
              "
              :default-sort="{ prop: item.sortKey, order: item.sortOrder }"
              @sort-change="sortTable"
            >
              <!-- SKU Details Column -->
              <el-table-column
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :render-header="appendToolTip"
                :column-key="item.widgetId"
                :fit="false"
                :label="'SKU Details'"
                :sortable="false"
                :fixed="true"
                :width="300"
              >
                <rb-sku
                  slot-scope="scope"
                  :image-url="scope.row.image_url"
                  :title="scope.row.title"
                  :asin="scope.row.asin"
                  :product-page-url="scope.row.asin_hyperlink"
                  :enable-s-d-p="true"
                  @navigateToSDP="navigateToSDP"
                />
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_PVP_ds_glance_views'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Glance Views Change %'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row.PVP_PVP_ds_glance_views | num_format('', '%') ||
                      checkNull(scope.row.PVP_PVP_ds_glance_views)
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="item.rangeKey"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current Period ' + item.displayName"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row[item.rangeKey] | num_format('') ||
                      checkNull(scope.row[item.rangeKey])
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="item.rangeKey"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Previous Period ' + item.displayName"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['PVP_' + item.rangeKey] | num_format('') ||
                      checkNull(scope.row['PVP_' + item.rangeKey])
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'ordered_units'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current Period Units Sold'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{ formatterFn(scope.row.ordered_units, 'number') }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_ordered_units'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Previous Period Units Sold'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      formatterFn(scope.row.PVP_ordered_units, 'number')
                    }}</span
                  >
                </div>
              </el-table-column>

              <!-- Current & Previous Period Revenue based on ops or pcogs -->
              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="currentGraph"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current Period Revenue '"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row[currentGraph] | num_format('currency') ||
                      checkNull(scope.row[currentGraph])
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_' + currentGraph"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Previous Period revenue'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['PVP_' + currentGraph]
                        | num_format('currency') ||
                      checkNull(scope.row['PVP_' + currentGraph])
                    }}</span
                  >
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer-right">
          <span
            class="footer--action u-cursor-pointer u-display-flex u-flex-align-items-center"
            @click="scrollToTop"
          >
            <span class="u-font-size-7"> Scroll to top </span>
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs"
              :icon="'sort-asc'"
            />
          </span>
        </div>
      </card-widget>

      <card-widget
        v-if="item.id === 'price_war' && item.id !== 'glance_views'"
        class="u-flex-1 u-spacing-mv-m u-width-100"
        :config="config.widgets[item.widgetId]"
        :data="
          getSalesDashboardData[config.widgets[item.widgetId].meta.key] || {}
        "
        :no-data-message="'No data available for selected filters'"
      >
        <div slot="body">
          <el-table
            :border="true"
            height="394"
            empty-text="No Data"
            class="u-width-100 no-padding-first-cell table-header-font-size-m el-table--scrollable-x el-table--scrollable-y"
            :data="
              (getSalesDashboardData[config.widgets['widget17'].meta.key] || {})
                .rows
            "
            :default-sort="{ prop: 'price_war_index_max', order: 'descending' }"
            @sort-change="sortTable"
          >
            <el-table-column
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              :fit="false"
              :label="'SKU Details'"
              :fixed="true"
              :width="300"
              :column-key="item.widgetId"
            >
              <rb-sku
                slot-scope="scope"
                :image-url="scope.row.client_image_url"
                :title="scope.row.asin_name"
                :asin="scope.row.asin"
                :product-page-url="scope.row.asin_hyperlink"
                :enable-s-d-p="true"
                @navigateToSDP="navigateToSDP"
              />
            </el-table-column>
            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              prop="ordered_revenue_last_30days"
              :column-key="item.widgetId"
              :sortable="'custom'"
              :fit="false"
              :label="'Revenue'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row.ordered_revenue_last_30days
                      | num_format('currency') ||
                    checkNull(scope.row.ordered_revenue_last_30days)
                  }}</span
                >
              </div>
            </el-table-column>
            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="150"
              :resizable="true"
              :render-header="appendToolTip"
              prop="price_war_index_max"
              :column-key="item.widgetId"
              :sortable="'custom'"
              :fit="false"
              :label="'PWI'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row.price_war_index_max | num_format() ||
                    checkNull(scope.row.price_war_index_max)
                  }}</span
                >
              </div>
            </el-table-column>
            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              prop="client_offer_price"
              :column-key="item.widgetId"
              :sortable="'custom'"
              :fit="false"
              :label="getMarketPlace + ' offer price'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row.client_offer_price | num_format('currency') ||
                    checkNull(scope.row.client_offer_price)
                  }}</span
                >
              </div>
            </el-table-column>
            <el-table-column
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              :fit="false"
              :label="'Retailer'"
              :fixed="false"
              :column-key="item.widgetId"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-text-overflow-ellipsis"
              >
                <span>
                  {{
                    scope.row.comp_name_latest ||
                    checkNull(scope.row.comp_name_latest)
                  }}</span
                >
              </div>
            </el-table-column>
            <el-table-column
              :class-name="'u-text-align-right'"
              :min-width="160"
              :max-width="200"
              :resizable="true"
              :render-header="appendToolTip"
              prop="comp_offer_price"
              :column-key="item.widgetId"
              :sortable="'custom'"
              :fit="false"
              :label="'Retailer offer price'"
              :fixed="false"
            >
              <div
                slot-scope="scope"
                class="u-display-flex u-flex-justify-content-flex-end"
              >
                <span>
                  {{
                    scope.row.comp_offer_price | num_format('currency') ||
                    checkNull(scope.row.comp_offer_price)
                  }}</span
                >
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer-right">
          <span
            class="footer--action u-cursor-pointer u-display-flex u-flex-align-items-center"
            @click="scrollToTop"
          >
            <span class="u-font-size-7"> Scroll to top </span>
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs"
              :icon="'sort-asc'"
            />
          </span>
        </div>
      </card-widget>
    </div>

    <div
      v-for="(item, index) in revenueLostTableItems"
      :id="item.id"
      class="u-display-flex u-flex-wrap-yes u-spacing-mr-l u-spacing-ml-m"
    >
      <card-widget
        class="u-flex-1 u-spacing-mv-m u-width-100"
        :config="config.widgets[item.widgetId]"
        :data="
          getSalesDashboardData[config.widgets[item.widgetId].meta.key] || {}
        "
        :no-data-message="'No data available for selected filters'"
      >
        <div slot="body">
          <div
            class="u-width-100"
            style="min-height: 394px"
          >
            <el-table
              v-if="
                !(
                  getSalesDashboardData[
                    config.widgets[item.widgetId].meta.key
                  ] || {}
                ).load
              "
              :border="true"
              height="394"
              empty-text="No Data"
              class="no-padding-first-cell table-header-font-size-m el-table--scrollable-x el-table--scrollable-y"
              :data="
                (
                  getSalesDashboardData[
                    config.widgets[item.widgetId].meta.key
                  ] || {}
                ).rows
              "
              :default-sort="{
                prop: item.widgetKey + '_' + currentTable,
                order: 'descending'
              }"
              @sort-change="sortTable"
            >
              <el-table-column
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :render-header="appendToolTip"
                :column-key="item.widgetId"
                :fit="false"
                :label="'SKU Details'"
                :sortable="false"
                :fixed="true"
                :width="300"
              >
                <rb-sku
                  slot-scope="scope"
                  :image-url="scope.row.image_url"
                  :title="scope.row.title"
                  :asin="scope.row.asin"
                  :product-page-url="scope.row.asin_hyperlink"
                  :enable-s-d-p="true"
                  @navigateToSDP="navigateToSDP"
                />
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="item.widgetKey + '_' + currentTable"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Total Revenue lost in the current period'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span v-if="item.displayName === 'LBB'">
                    {{
                      scope.row['revenue_lost_lbb'] | num_format('currency') ||
                      checkNull(scope.row['revenue_lost_lbb'])
                    }}</span
                  >

                  <span
                    v-if="
                      showUnavailableMergedColumns &&
                      item.displayName === 'Unavailable Skus'
                    "
                  >
                    {{
                      scope.row['revenue_lost_unavailable']
                        | num_format('currency') ||
                      checkNull(scope.row['revenue_lost_unavailable'])
                    }}
                  </span>
                </div>
              </el-table-column>

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_' + item.widgetKey + '_' + currentTable"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Total Revenue lost in the previous period'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span v-if="item.displayName === 'LBB'">
                    {{
                      scope.row['PVP_revenue_lost_lbb']
                        | num_format('currency') ||
                      checkNull(scope.row['PVP_revenue_lost_lbb'])
                    }}</span
                  >

                  <span
                    v-if="
                      showUnavailableMergedColumns &&
                      item.displayName === 'Unavailable Skus'
                    "
                  >
                    {{
                      scope.row['PVP_revenue_lost_unavailable']
                        | num_format('currency') ||
                      checkNull(scope.row['PVP_revenue_lost_unavailable'])
                    }}
                  </span>
                  <span class="u-display-none">{{ currentTable }}</span>
                </div>
              </el-table-column>

              <!-- <el-table-column
                v-if="(showUnavailableMergedColumns && item.displayName !== 'Unavailable Skus') || !showUnavailableMergedColumns"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="item.rangeKey + '_days_in_range'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current '+item.displayName+' Days'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row[item.rangeKey + '_days_in_range'] || checkNull(scope.row[item.rangeKey + '_days_in_range']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->

              <!-- <el-table-column
                v-if="(showUnavailableMergedColumns && item.displayName !== 'Unavailable Skus') || !showUnavailableMergedColumns"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_'+item.rangeKey+'_days_in_range'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Previous '+item.displayName+' Days'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['PVP_'+item.rangeKey+'_days_in_range'] || checkNull(scope.row['PVP_'+item.rangeKey+'_days_in_range']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->

              <!-- Unavailable Skus Merged Columns Start -->
              <!-- <el-table-column
                v-if="showUnavailableMergedColumns && item.displayName === 'Unavailable Skus'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'days_in_range'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current '+item.displayName+' Days'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['days_in_range'] || checkNull(scope.row['days_in_range']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->
              <!--
              <el-table-column
                v-if="showUnavailableMergedColumns && item.displayName === 'Unavailable Skus'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_' + 'days_in_range'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Previous '+item.displayName+' Days'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['PVP_' + 'days_in_range'] || checkNull(scope.row['PVP_' + 'days_in_range']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->
              <!-- Unavailable Skus Merged Columns End -->

              <!-- <el-table-column :min-width="160" :max-width="200" :resizable="true"  :prop="'revenue_lost_'+currentTable+'_daily'" :column-key="item.widgetId" :render-header="appendToolTip" :label="'Average daily sales'" :sortable="true" :fixed="false">
                <div class="u-display-flex u-flex-justify-content-flex-end" slot-scope="scope">
                  <span> {{ scope.row['revenue_lost_'+currentTable+'_daily'] | num_format( '$') }}</span>
                  <span class="u-display-none">{{currentTable}}</span>
                </div>
              </el-table-column> -->

              <el-table-column
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'PVP_PVP_DIFF_' + item.widgetKey + '_' + currentTable"
                :render-header="appendToolTip"
                :column-key="item.widgetId"
                :label="'Change in Revenue lost due to ' + item.displayName"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end u-width-100"
                >
                  <span v-if="item.displayName === 'LBB'">
                    {{
                      scope.row['PVP_PVP_DIFF_revenue_lost_lbb']
                        | num_format('currency') ||
                      checkNull(scope.row['PVP_PVP_DIFF_revenue_lost_lbb'])
                    }}</span
                  >
                  <span
                    v-if="
                      showUnavailableMergedColumns &&
                      item.displayName === 'Unavailable Skus'
                    "
                  >
                    {{
                      scope.row['PVP_PVP_DIFF_revenue_lost_unavailable']
                        | num_format('currency') ||
                      checkNull(
                        scope.row['PVP_PVP_DIFF_revenue_lost_unavailable']
                      )
                    }}
                    <!-- {{ scope.row }} -->
                  </span>
                  <span class="u-display-none">{{ currentTable }}</span>
                </div>
              </el-table-column>

              <el-table-column
                v-if="
                  item.displayName === 'Unavailable Skus' &&
                  showUnavailableMergedColumns
                "
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'actual_unavailability'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Unavailability'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['actual_unavailability']
                        | num_format('', '%', '', 2) ||
                      checkNull(scope.row['actual_unavailability'])
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                v-if="
                  item.displayName === 'Unavailable Skus' &&
                  showUnavailableMergedColumns
                "
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'actual_rep_oos'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Rep OOS'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['actual_rep_oos']
                        | num_format('', '%', '', 2) ||
                      checkNull(scope.row['actual_rep_oos'])
                    }}</span
                  >
                </div>
              </el-table-column>

              <el-table-column
                v-if="item.displayName === 'LBB'"
                :width="200"
                :max-width="200"
                :resizable="true"
                :prop="'actual_lbb_rate'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'LBB %'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  v-click-to-expand
                  slot-scope="scope"
                  class="u-width-100 u-display-flex"
                >
                  <div
                    class="u-display-flex u-flex-justify-content-flex-end u-width-100"
                  >
                    <span>
                      {{
                        scope.row['actual_lbb_rate'] | num_format('', '%') ||
                        checkNull(scope.row['actual_lbb_rate'])
                      }}
                    </span>
                    <span class="u-display-none">{{ item.displayName }}</span>
                  </div>
                </div>
              </el-table-column>

              <!-- LBB Specific Columns -->
              <!-- <el-table-column
                v-if="item.displayName === 'LBB'"
                :width="200"
                :max-width="200"
                :resizable="true"
                :prop="'buybox_seller_name'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current Buybox Winner'"
                :sortable="'custom'"
                :fixed="false"
              >
                <span
                  v-click-to-expand
                  slot-scope="scope"
                  class="u-width-100 u-display-flex"
                >
                  <div class="u-flex-1">
                    <span> {{ scope.row['buybox_seller_name'] || checkNull(scope.row['buybox_seller_name']) }}</span>
                    <span class="u-display-none">{{ item.displayName }}</span>
                  </div>
                </span>
              </el-table-column> -->

              <!-- <el-table-column
                v-if="item.displayName === 'LBB'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'buybox_offer_price'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Winner\'s Offer Price'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['buybox_offer_price'] | num_format( 'currency' ) || checkNull(scope.row['buybox_offer_price']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->

              <!-- <el-table-column
                v-if="item.displayName === 'LBB'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'sas_offer_price_not_avg'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Amazon Offer Price'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['sas_offer_price_not_avg'] | num_format( 'currency' ) || checkNull(scope.row['sas_offer_price_not_avg']) }}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->
              <!-- LBB Specific Columns -->

              <!-- Revenue Lost OOS  specific columns -->
              <el-table-column
                v-if="item.displayName === 'OOS'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'ordered_revenue_last_30days'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Last 4 Weeks Revenue'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['ordered_revenue_last_30days']
                        | num_format('currency') ||
                      checkNull(scope.row['ordered_revenue_last_30days'])
                    }}</span
                  >
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column>

              <!-- <el-table-column :min-width="100" :max-width="200" :resizable="true" v-if="item.displayName === 'OOS'" :prop="'rep_oos'" :column-key="item.widgetId" :render-header="appendToolTip" :label="'Rep OOS'" :sortable="true" :fixed="false">
                <div class="u-display-flex u-flex-justify-content-flex-end" slot-scope="scope">
                  <span> {{ scope.row['rep_oos'] | num_format( '', '%' ) }}</span>
                  <span class="u-display-none">{{item.displayName}}</span>
                </div>
              </el-table-column> -->

              <el-table-column
                v-if="item.displayName === 'OOS'"
                :width="250"
                :max-width="350"
                :resizable="true"
                :prop="'open_pos'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Open POs'"
                :sortable="'custom'"
                :fixed="false"
              >
                <span
                  v-click-to-expand
                  slot-scope="scope"
                  class="u-width-100 u-display-flex"
                >
                  <div class="u-flex-1">
                    <span>{{
                      scope.row['open_pos'] || checkNull(scope.row['open_pos'])
                    }}</span>
                    <span class="u-display-none">{{ item.displayName }}</span>
                  </div>
                </span>
              </el-table-column>
              <!-- Revenue Lost OOS  specific columns-->

              <!-- LBB and UNVL Specific Columns -->
              <el-table-column
                v-if="
                  item.displayName === 'LBB' ||
                  item.displayName === 'Unavailable Skus'
                "
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'units_at_amazon'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Current Units at Amazon'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      formatterFn(scope.row['units_at_amazon'], 'number')
                    }}</span
                  >
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column>

              <el-table-column
                v-if="
                  showDirectFulfilment() &&
                  (item.displayName === 'LBB' ||
                    item.displayName === 'Unavailable Skus')
                "
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'stock'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Direct Fulfilment Inventory'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['stock'] || checkNull(scope.row['stock'])
                    }}</span
                  >
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column>

              <!-- <el-table-column
                v-if="item.displayName === 'LBB' || item.displayName === 'Unavailable Skus'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'avg_product_rating'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Avg. Rating'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['avg_product_rating'] | num_format( '' ) || checkNull(scope.row['avg_product_rating'])}}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->
              <!-- LBB and UNVL Specific Columns -->

              <!-- UNVL specific Columns -->
              <!-- <el-table-column
                v-if="item.displayName === 'Unavailable Skus'"
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'last_seen_offer_price'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Last Seen Offer Price'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span> {{ scope.row['last_seen_offer_price'] | num_format( 'currency' ) || checkNull(scope.row['last_seen_offer_price'])}}</span>
                  <span class="u-display-none">{{ item.displayName }}</span>
                </div>
              </el-table-column> -->
              <el-table-column
                v-if="
                  item.displayName === 'Unavailable Skus' &&
                  showUnavailableMergedColumns
                "
                :class-name="'u-text-align-right'"
                :min-width="160"
                :max-width="200"
                :resizable="true"
                :prop="'ordered_revenue_last_30days'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Last 4 Weeks Revenue'"
                :sortable="'custom'"
                :fixed="false"
              >
                <div
                  slot-scope="scope"
                  class="u-display-flex u-flex-justify-content-flex-end"
                >
                  <span>
                    {{
                      scope.row['ordered_revenue_last_30days']
                        | num_format('currency') ||
                      checkNull(scope.row['ordered_revenue_last_30days'])
                    }}</span
                  >
                </div>
              </el-table-column>
              <el-table-column
                v-if="
                  item.displayName === 'Unavailable Skus' &&
                  showUnavailableMergedColumns
                "
                :class-name="'u-text-align-right'"
                :width="250"
                :max-width="350"
                :resizable="true"
                :prop="'open_pos'"
                :column-key="item.widgetId"
                :render-header="appendToolTip"
                :label="'Open POs'"
                :sortable="'custom'"
                :fixed="false"
              >
                <span
                  v-click-to-expand
                  slot-scope="scope"
                  class="u-width-100 u-display-flex"
                >
                  <div class="u-flex-1">
                    <!-- this is a string column -->
                    <span>{{
                      scope.row['open_pos'] || checkNull(scope.row['open_pos'])
                    }}</span>
                    <span class="u-display-none">{{ item.displayName }}</span>
                  </div>
                </span>
              </el-table-column>
              <!-- UNVL specific Columns -->
            </el-table>
          </div>
        </div>
        <div slot="footer-right">
          <span
            class="footer--action u-cursor-pointer u-display-flex u-flex-align-items-center"
            @click="scrollToTop"
          >
            <span class="u-font-size-7"> Scroll to top </span>
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs"
              :icon="'sort-asc'"
            />
          </span>
        </div>
      </card-widget>
    </div>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import cardWidget from '@/components/widgets/cardWidget';
import metric from '@/components/basic/metric';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
import rbPagination from '@/components/basic/pagination';
import asinSearch from '@/components/widgets/asinSearch';
import Vue from 'vue';
import tableDownloadMap from './tableDownloadMap';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { formatter } from '@/utils/helpers/formatter.js';

var oObject = {
  mixins: [widgetsUtil],
  created() {
    this.config = widgetsConfig.config(this);
    this.fetchFilters('client_workbench_full_catalogue_latest_comp_data');
    this.addDirectFulfilmentToTable();
    this.addDirectFulfilmentToDownload();
    // Disable Revenue Lost Due To Out Of Stock table based on a config.
    if (
      Vue.options.filters.config_check(
        'feature.merge_unavailable_recommendation.enable'
      )
    ) {
      if (this.revenueLostTableItems[0]?.id === 'revenue_lost_oos') {
        this.revenueLostTableItems.splice(0, 1);
      }
      // Set configs for unavailable widget card and table.
      if (this.config.widgets.widget4.meta.key === 'revenue_lost_unavl_skus') {
        this.config.widgets.widget4.body.APIConfig.cubeName = 'gv_imputations';
        // Tooltip change
        this.config.widgets.widget4.header.tooltip =
          'Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC.';
      }
      if (this.config.widgets.widget14.meta.key === 'unvlTableData') {
        this.config.widgets.widget14.body.APIConfig.cubeName = 'gv_imputations';
        // const measuresList = this.config.widgets.widget14.body.APIConfig.measuresList;
        // if (measuresList.indexOf('uavl_days_in_range') > -1) {
        //   measuresList.splice(measuresList.indexOf('uavl_days_in_range'), 1, 'days_in_range');
        // }
        // Add Last 4 weeks revenue, Open POs, Rep OOS columns to measurelists.
        // measuresList.push('ordered_revenue_last_30days', 'open_pos', 'rep_oos');
        if (
          tableDownloadMap.widget14.widgetName ===
          'Revenue Lost Due To Unavailable Skus'
        ) {
          // tableDownloadMap.widget14.tableKeys.forEach(item => {
          // if (item.dbKey === 'uavl_days_in_range') {
          //   item.dbKey = 'days_in_range';
          // }
          // if (item.dbKey === 'PVP_uavl_days_in_range') {
          //   item.dbKey = 'PVP_days_in_range';
          // }
          // });
          // Add Last 4 weeks revenue, Open POs, Rep OOS column to download
          // const columns = [{
          //   displayName: 'Last 4 Weeks Revenue',
          //   dbKey: 'ordered_revenue_last_30days'
          // },
          // {
          //   displayName: 'Open POs',
          //   dbKey: 'open_pos'
          // }];

          // const repOOSColumn = {
          //   displayName: 'Rep OOS',
          //   dbKey: 'actual_rep_oos'
          // };
          // Add Rep OOS column before Current Unavailable SKUs column
          // this.config.widgets.widget14.header.actions.download.colMap.splice(7, 0, repOOSColumn);

          tableDownloadMap.widget14.tableKeys = [
            ...tableDownloadMap.widget14.tableKeys
          ];
          this.config.widgets.widget14.header.actions.download.colMap = [
            ...this.config.widgets.widget14.header.actions.download.colMap
          ];
        }
        // Tooltip changes for Total revenue lost in current/previous period columns.
        // for (const [idx, tooltip] of this.config.widgets.widget14.body.tooltipTexts.entries()) {
        //   if (tooltip.includes('Total revenue lost in current period')) {
        //     this.config.widgets.widget14.body.tooltipTexts[idx] = 'Total revenue lost in current period: Calculates the revenue lost due to the product being unavailable for sale. It is calculated using the Average sales velocity, rep OOS% and amazon offer price. The revenue lost is calculated for all the days of the selected period and then summed up to show the revenue lost in the selected period.';
        //   } else if (tooltip.includes('Total revenue lost in previous period')) {
        //     this.config.widgets.widget14.body.tooltipTexts[idx] = 'Total revenue lost in previous period: Calculates the revenue lost due to the product being unavailable for sale. It is calculated using the Average sales velocity, rep OOS% and amazon offer price. The revenue lost is calculated for all the days of the previous period and then summed up to show the revenue lost in the previous period.';
        //   }
        // }
        // Add Rep OOS column Tooltip Before Current Unavailable SKUs column Tooltip.
        // this.config.widgets.widget14.body.tooltipTexts.splice(3, 0, 'Rep OOS: The average value of the Rep OOS % from the days the ASIN was unavailable for sale in the  selected period.');
        // Tooltips for Last 4 weeks revenue, Open POs.
        // this.config.widgets.widget14.body.tooltipTexts.push('Last 4 weeks revenue: Amazon revenue in last 4 weeks. Calculated by multiplying ordered units with ASP', 'Open POs: Open Purchase ordered for the ASIN');
      }

      // Set the order of the cards.
      const orderKey = [
        'revenue_lost_buy_box',
        'revenue_lost_unavl_skus',
        'avg_selling_price'
      ];
      const order = [6, 5, 7];
      this.cards.forEach((card) => {
        if (orderKey.indexOf(card.key) > -1) {
          const idx = orderKey.indexOf(card.key);
          card.opsOrder = order[idx];
          card.pcogsOrder = order[idx];
        }
      });
      // Sort cards after changing the order.
      this.cards.sort(
        function (a, b) {
          var sortKey =
            this.currentGraph === 'pcogs' ? 'pcogsOrder' : 'opsOrder';
          return a[sortKey] - b[sortKey];
        }.bind(this)
      );
      // Sort table order
      this.revenueLostTableItems.sort(function (a, b) {
        return a.merge_order - b.merge_order;
      });
    }
  },
  computed: {
    showUnavailableMergedColumns() {
      if (
        Vue.options.filters.config_check(
          'feature.merge_unavailable_recommendation.enable'
        )
      ) {
        return true;
      }
    },
    getSalesDashboardData() {
      return this.$store.getters.getSalesDashboardData;
    },
    widget1GraphData: {
      get: function () {
        var dataObj = this.$store.getters.getSalesDashboardGraphData || [];
        if (dataObj.length === 0) {
          return [];
        }
        var graphData = [dataObj.date];
        graphData.push(dataObj.dataValue);
        return graphData;
      }
    },
    widget18GraphData: {
      get: function () {
        var dataObj = this.$store.getters.getSalesDashboardPcogsGraphData || [];
        if (dataObj.length === 0) {
          return [];
        }
        var graphData = [dataObj.date];
        graphData.push(dataObj.dataValue);
        return graphData;
      }
    },
    getSalesDashboardPageWiseMinMaxKey() {
      return this.$store.getters.getSalesDashboardPageWiseMinMaxKey;
    }
  },
  data() {
    var cards = [
      {
        title: 'Gross Retailer Margin',
        key: 'gross_retailer_margin',
        widgetId: 'widget6',
        tableId: 'gross_retailer_margin',
        opsOrder: 1,
        pcogsOrder: 1
      },
      {
        title: 'Ordered Units',
        key: 'ordered_units',
        widgetId: 'widget7',
        tableId: 'ordered_units',
        opsOrder: 2,
        pcogsOrder: 2
      },
      {
        title: 'Glance Views',
        key: 'ds_glance_views',
        widgetId: 'widget21',
        tableId: 'glance_views',
        opsOrder: 3,
        pcogsOrder: 3
      },
      // {
      //   title: 'CONVERSION',
      //   key: 'conversion_percentile',
      //   widgetId: 'widget22',
      //   tableId: 'conversion'
      // },
      {
        title: 'Revenue Lost Due To Out Of Stock',
        key: 'revenue_lost_oos_skus',
        widgetId: 'widget3',
        tableId: 'revenue_lost_oos',
        opsOrder: 4,
        pcogsOrder: 4
      },
      {
        title: 'Revenue Lost Due To Lost Buy Box',
        key: 'revenue_lost_buy_box',
        widgetId: 'widget2',
        tableId: 'revenue_lost_lbb',
        opsOrder: 5,
        pcogsOrder: 5,
        tooltipText:
          '<div class="u-line-height-1-3 u-display-flex u-flex-direction-column"> <div class="u-display-inline-flex u-font-weight-normal"> The revenue lost is calculated on a daily basis at an ASIN level and then aggregated.<br> Revenue lost on a daily basis is calculated by following these 3 steps:  </div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m">1. Estimate the glance views when the ASIN was not available for sale. This is derived from LBB % and glance views.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> 2. Calculation of lost sales (units) i.e. identifying the LBB glance views (as calculated in Step 1) which would have converted into a sale. This is done by multiplying the LBB glance views with the unit conversion %.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> 3. Total revenue lost: Lost sales (as calculated in step 2) times the Average Selling Price of the ASIN as per AVC.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> Note: In case of high LBB %,  glance views are derived based on trends in the last 2 weeks of glance views with relatively LBB or no LBB.</div> </div>'
      },
      {
        title: 'Revenue Lost Due To Unavailable SKUS',
        key: 'revenue_lost_unavl_skus',
        widgetId: 'widget4',
        tableId: 'revenue_lost_unavailable_skus',
        opsOrder: 6,
        pcogsOrder: 6,
        tooltipText:
          '<div class="u-line-height-1-3 u-display-flex u-flex-direction-column"> <div class="u-display-inline-flex u-font-weight-normal"> The revenue lost is due to unavailability calculated on a daily basis at an ASIN level and then aggregated. Revenue lost on a daily basis is calculated by following these 3 steps: </div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m">1. Estimating the glance views when the ASIN was not available for sale. This is derived from unavailability% and glance views.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> 2. Calculation of lost sales (units) i.e. identifying the number of unavailable glance views (as calculated in Step 1) that would have converted into a sale. This is done by multiplying the unavailable glance views with the unit conversion %.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> 3. Total revenue lost: Lost sales (as calculated in step 2) times the average selling price of the ASIN as per AVC.</div> <div class="u-display-inline-flex u-font-weight-normal u-flex-direction-column u-spacing-mt-m"> Note: In the case of high unavailability %, glance views are derived based on trends in the last 30 days of glance views. In the case when there is unavailability for more than 30 days the revenue lost for that ASIN will be 0. </div> </div>'
      },
      {
        title: 'Average Selling Price',
        key: 'avg_selling_price',
        widgetId: 'widget8',
        tableId: 'avg_selling_price',
        opsOrder: 7,
        pcogsOrder: 7
      }
    ];
    const cardCount = 6;
    // Disable Revenue Lost Due To Out Of Stock card based on a config.
    if (
      Vue.options.filters.config_check(
        'feature.merge_unavailable_recommendation.enable'
      ) &&
      cards[3]?.key === 'revenue_lost_oos_skus'
    ) {
      // Remove OOS Cards from cards.
      cards.splice(3, 1);
    }
    var _cards = cards.slice(0, 6);
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      cards,
      formatterFn: formatter,
      selectedCards: _cards,
      cardCount,
      filterState: {
        getter: 'getInsightsSalesDBSelectedFilter',
        setter: 'setInsightsSalesDBSelectedFilter'
      },
      currentGraph: 'ordered_product_sales',
      currentTable: 'ops',
      tableItems: [
        {
          widgetId: 'widget9',
          sortKey: 'PVP_PVP_DIFF_pcogs',
          widgetKey: 'pcogs',
          displayName: 'PCOGS',
          id: 'pcogs'
        },
        {
          widgetId: 'widget10',
          widgetKey: 'ordered_product_sales',
          sortKey: 'PVP_PVP_DIFF_ordered_product_sales',
          displayName: 'Revenue',
          id: 'ordered_product_sales'
        },
        {
          widgetId: 'widget11',
          widgetKey: 'gross_retailer_margin',
          sortKey: 'PVP_PVP_DIFF_gross_retailer_margin',
          displayName: 'Margin',
          id: 'gross_retailer_margin'
        },
        {
          widgetId: 'widget15',
          widgetKey: 'ordered_units',
          sortKey: 'PVP_PVP_DIFF_ordered_units',
          displayName: 'Units Sold',
          id: 'ordered_units'
        },
        {
          widgetId: 'widget19',
          sortKey: 'ds_glance_views',
          sortOrder: 'descending',
          widgetKey: 'glanceViewsTableData',
          displayName: 'Glance Views',
          id: 'glance_views',
          rangeKey: 'ds_glance_views'
        },
        {
          widgetId: 'widget17',
          widgetKey: 'price_war',
          displayName: 'Price War',
          id: 'price_war'
        },
        {
          widgetId: 'widget16',
          widgetKey: 'avg_selling_price',
          sortKey: 'PVP_PVP_avg_selling_price',
          displayName: 'ASP',
          id: 'avg_selling_price'
        }
      ],
      revenueLostTableItems: [
        {
          widgetId: 'widget12',
          widgetKey: 'revenue_lost',
          displayName: 'OOS',
          id: 'revenue_lost_oos',
          rangeKey: 'oos',
          order: 1,
          merge_order: null
        },
        {
          widgetId: 'widget13',
          widgetKey: 'revenue_lost',
          displayName: 'LBB',
          id: 'revenue_lost_lbb',
          rangeKey: 'lbb',
          order: 2,
          merge_order: 2,
          apiKey: 'revenue_lost_lbb'
        },
        {
          widgetId: 'widget14',
          widgetKey: 'revenue_lost',
          displayName: 'Unavailable Skus',
          id: 'revenue_lost_unavailable_skus',
          rangeKey: 'uavl',
          order: 3,
          merge_order: 1,
          apiKey: 'revenue_lost_unavailable'
        }
      ],
      avgSelllingPriceTables: [
        'ordered_product_sales',
        'gross_retailer_margin'
      ],
      tableMap: {},
      cardFooterTippyId: 'footer-hover-tooltip-' + this._uid,
      cardFooterTippy: {
        html: '#' + 'footer-hover-tooltip-' + this._uid,
        reactive: true,
        theme: 'dropdown',
        placement: 'bottom-end',
        arrow: false,
        distance: 8,
        interactive: true,
        trigger: 'mouseenter',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      filterInstance: null,
      showActiveFilterBanner: false
    };
  },
  methods: {
    onCreate(filter) {
      this.filterInstance = filter;
    },
    formatCurrency(value) {
      return Vue.options.filters.num_format(value, 'currency');
      // (scope.row['PVP_PVP_DIFF_'+currentGraph]) | num_format( 'currency' ) || checkNull(scope.row['PVP_PVP_DIFF_'+currentGraph])
    },
    backButton() {
      this.$router.push({ path: `${this.$store.getters.getRootPath}reports` });
    },
    addDirectFulfilmentToDownload() {
      // Add Direct Fulfilment Inventory Column to table download based on a config
      const widgetsArr = ['widget13', 'widget14'];
      if (
        Vue.options.filters.config_check('feature.direct_fulfilment.enable')
      ) {
        for (var w of widgetsArr) {
          let index;
          for (var idx in tableDownloadMap[w].tableKeys) {
            if (
              tableDownloadMap[w].tableKeys[idx].dbKey === 'units_at_amazon'
            ) {
              index = idx;
              break;
            }
          }
          if (index !== undefined) {
            index++;
            tableDownloadMap[w].tableKeys.splice(index, 0, {
              displayName: 'Direct Fulfilment Inventory',
              dbKey: 'stock'
            });
          }
        }
      }
    },
    addDirectFulfilmentToTable() {
      // Add Direct Fulfilment Inventory Column to table based on a config
      if (
        Vue.options.filters.config_check('feature.direct_fulfilment.enable')
      ) {
        const widgetsArr = ['widget13', 'widget14'];
        for (var w of widgetsArr) {
          const tooltipTexts = this.config.widgets[w].body.tooltipTexts;
          if (tooltipTexts && tooltipTexts.length && tooltipTexts.length > 0) {
            let index;
            for (var idx in tooltipTexts) {
              const compareTxt = 'Current units at Amazon';
              if (tooltipTexts[idx].includes(compareTxt)) {
                index = idx;
                break;
              }
            }
            if (index !== undefined) {
              index++;
              tooltipTexts.splice(
                index,
                0,
                'Direct Fulfilment Inventory: Units available for direct fulfillment'
              );
            }
          }
        }
      }
    },
    showDirectFulfilment() {
      if (
        Vue.options.filters.config_check('feature.direct_fulfilment.enable')
      ) {
        return true;
      }
    },
    arrayDifferentiator(cards, selectedCards) {
      // compare cards with selectedCards return unmatched ones
      var returnArr = Vue.options.filters.array_differentiator(
        cards,
        selectedCards
      );
      if (returnArr.length === 0 || this.currentGraph === 'pcogs') {
        return false;
      }
      return returnArr;
    },
    cardMetricData(selCard) {
      var variableUnitKeys = [
        'revenue_lost_oos_skus',
        'revenue_lost_buy_box',
        'revenue_lost_unavl_skus'
      ];
      if (variableUnitKeys.indexOf(selCard.key) > -1) {
        return ((
          this.getSalesDashboardData[
            this.config.widgets[selCard.widgetId].meta.key
          ] || {}
        ).metrics || {})[this.currentGraph];
      }
      return (
        (
          this.getSalesDashboardData[
            this.config.widgets[selCard.widgetId].meta.key
          ] || {}
        ).metrics || {}
      );
    },
    cardSelected(context, selection) {
      var sourceIdx, destIdx, hidden;
      destIdx = context[0];
      for (var idx in this.cards) {
        if (selection.length > 0 && this.cards[idx].key === selection[0].key) {
          sourceIdx = idx;
          break;
        }
      }

      var swapKey = this.currentGraph === 'pcogs' ? 'pcogsOrder' : 'opsOrder';
      this.cards[destIdx][swapKey] = sourceIdx;
      this.cards[sourceIdx][swapKey] = destIdx;

      hidden = this.cards[destIdx];
      this.cards[destIdx] = this.cards[sourceIdx];
      this.cards[sourceIdx] = hidden;

      this.selectedCards[context[0]] = selection[0];
      this.selectedCards = Vue.util.extend([], this.selectedCards);
      this.cards = Vue.util.extend([], this.cards);
    },
    checkNull(value) {
      if (value === undefined || value === '' || value === null) {
        return 'NA';
      } else {
        return value;
      }
    },
    scrollToTop() {
      document.getElementById('sales-dashboard-filters').scrollIntoView();
    },
    scrollToTable(id) {
      var table = document.getElementById(id);
      if (table) {
        table.scrollIntoView();
      }
    },
    sortTable(column, event) {
      if (column && column.column) {
        var widget = column.column.columnKey;
        // var sortOrder = this.config.widgets[widget].body.APIConfig.orderByList;
        var x = this.$store.getters.getSalesDashboardData;
        var data = (x[this.config.widgets[widget].meta.key] || {}).rows || [];

        // Seperate out the null && undefined fields before sorting.
        var nullArr = [];
        var idxArr = [];
        for (var i in data) {
          if (
            data[i][column.prop] === undefined ||
            data[i][column.prop] === '' ||
            data[i][column.prop] === null
          ) {
            idxArr.push(i);
          }
        }
        if (idxArr.length > 0) {
          // sorting in descending order
          idxArr.sort(function (a, b) {
            return b - a;
          });

          for (var idx in idxArr) {
            nullArr.push(data.splice(idxArr[idx], 1)[0]);
          }
        }

        var currentOrder = null;
        if (column.order !== null) {
          currentOrder = column.order === 'ascending' ? 'ASC' : 'DESC';
        }

        data = data.sort(function (a, b) {
          var x = a[column.prop];
          var y = b[column.prop];

          // String Comparision
          if (
            isNaN(x) &&
            isNaN(y) &&
            x.constructor === String &&
            y.constructor === String
          ) {
            x = x.toLowerCase();
            y = y.toLowerCase();
            if (x === y) {
              return 0;
            }
            if (currentOrder === 'ASC') {
              if (x < y) {
                return -1;
              }
            } else {
              if (x > y) {
                return -1;
              }
            }
          }

          // Numeric Comparision
          if (x.constructor === Number && y.constructor === Number) {
            if (currentOrder === 'ASC') {
              return x - y;
            } else {
              return y - x;
            }
          }
        });

        data = [...data, ...nullArr];

        this.$store.dispatch('changeSalesDWData', {
          key: this.config.widgets[widget].meta.key,
          data
        });
      }
    },
    appendToolTip(h, { column, $index }) {
      // commented code use to generate table download map
      // if (this.tableMap[column.columnKey] === undefined) {
      //   this.tableMap[column.columnKey] = {
      //     widgetName: '',
      //     tableKeys: []
      //   }
      // }
      // var push = true;
      // for (var i = 0; i < this.tableMap[column.columnKey].tableKeys.length; i++) {
      //   if (this.tableMap[column.columnKey].tableKeys[i].dbKey === column.property) {
      //     push = false;
      //     break;
      //   }
      // }
      // if (push === true) {
      //   this.tableMap[column.columnKey].tableKeys.push({
      //     displayName: column.label,
      //     dbKey: column.property
      //   })
      // }
      // window.tableKeys = this.tableMap;
      var widget = column.columnKey;
      var tooltipTexts = this.config.widgets[widget].body.tooltipTexts || [];
      return renderColumnHeaders.renderHeader(
        h,
        column,
        true,
        tooltipTexts[$index]
      );
    },
    switchGraphCard(event) {
      this.currentGraph =
        this.currentGraph === 'ordered_product_sales'
          ? 'pcogs'
          : 'ordered_product_sales';
      this.currentTable = this.currentTable === 'ops' ? 'pcogs' : 'ops';

      // Sort in Ascending order when switching cards.
      var that = this;
      this.cards.sort(function (a, b) {
        var sortKey = that.currentGraph === 'pcogs' ? 'pcogsOrder' : 'opsOrder';
        return a[sortKey] - b[sortKey];
      });
      if (
        Vue.options.filters.config_check(
          'feature.merge_unavailable_recommendation.enable'
        ) &&
        this.currentGraph === 'pcogs'
      ) {
        // Remove Average Selling Price if current graph is pcogs.
        this.selectedCards = this.cards.slice(0, 5);
        this.cardCount = 5;
      } else {
        this.selectedCards = this.cards.slice(0, 6);
        this.cardCount = 6;
      }
      event.stopPropagation();
      this.triggerCommonCardsActions();
    },
    triggerCommonCardsActions() {
      var widgets = ['widget12', 'widget13', 'widget14'];
      if (
        Vue.options.filters.config_check(
          'feature.merge_unavailable_recommendation.enable'
        )
      ) {
        widgets = ['widget13', 'widget14'];
      }
      for (var i = 0; i < widgets.length; i++) {
        var config = this.config.widgets[widgets[i]];
        config.body.APIConfig.orderByList = [
          {
            dimension: 'revenue_lost_' + this.currentTable,
            direction: 'DESC'
          }
        ];
        this.revenueLostTableItems.forEach((item) => {
          if (item.apiKey && item.widgetId === widgets[i]) {
            config.body.APIConfig.orderByList = [
              {
                dimension: item.apiKey,
                direction: 'DESC'
              }
            ];
          }
        });
        this.$store.dispatch(
          this.config.widgets[widgets[i]].meta.action,
          config
        );
      }
    },
    getAllDates() {
      var maxDates = this.$store.getters.getMaxDate;
      var returnDates = {};
      var dateRangeValues = {};

      var selectedDateRange = 'Last 7 Days';
      var selectedDateRangeValues = this.$store.getters.getDateRangeValues;

      if (selectedDateRange.length > 0) {
        for (var i in selectedDateRangeValues) {
          dateRangeValues[i] = selectedDateRangeValues[i][selectedDateRange[0]];
        }

        for (i in maxDates) {
          returnDates[i] = maxDates[i].max_feed_date;
        }
      }
      returnDates.dateRange = selectedDateRange[0];
      returnDates.dateRangeValues = dateRangeValues;
      return returnDates;
    },
    getUnit(itemKey) {
      return itemKey === 'ordered_units' ? '' : 'currency';
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  },
  components: {
    chart,
    loader,
    rbFilterPanel,
    cardWidget,
    metric,
    rbPagination,
    asinSearch
  }
};

export default oObject;
</script>
<style lang="css" scoped>
.card .contentPageCustomSelect {
  line-height: 23px;
  padding: 12px 16px 9px 24px;
  width: 150px;
}
.el-table__header > th {
  text-align: right !important;
}
.table-header-font-size-m .el-table__header thead tr th:nth-child(1) {
  padding-left: 16px;
  text-align: left;
}
.table-header-font-size-m .el-table__header thead tr th:nth-child(1) .cell {
  justify-content: flex-end !important;
  padding-left: 8px;
}
.table-header-font-size-m
  .el-table__header
  thead
  tr
  th:not(:first-child)
  .cell {
  width: auto !important;
  justify-content: end !important;
}
.table-header-font-size-m .el-table__header thead tr th:not(:first-child) {
  padding-left: 16px;
  text-align: end;
}
.table-header-font-size-m
  .el-table__header
  thead
  tr
  th
  .cell
  .el-table--columnHeaderLabel {
  width: auto !important;
}
.table-header-font-size-m
  .el-table__header
  thead
  tr
  th
  .cell
  .el-table--columnHeaderLabel
  span {
  font-size: 11px;
}
.anchorContainer {
  justify-content: flex-end;
}
.cardHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cardHeader div {
  width: 100%;
  text-align: center;
}
.footer--action {
  color: #8b8f93;
}
.footer--action:hover {
  color: #4b5158;
}
.hover--card:hover {
  cursor: pointer;
}
.hover--card:hover .card {
  box-shadow: 0px 1px 8px 0px rgba(43, 51, 59, 0.15) !important;
}
.footer-tooltip {
  max-width: 500px;
  word-break: break-word;
}
.u-negative-margin-s {
  margin-top: -4px;
  margin-bottom: -2px;
}
.underline-text {
  text-decoration: underline;
}
</style>
