import { actionTemplateUtils } from '@/components/pages/strategies/create/utils.js';
const commonConfigs = {
  APIMapper: {
    actionType: {
      budget: 'CAMPAIGN_BUDGET_CHANGE',
      bid: 'KEYWORD_BID_CHANGE',
      adgroup_bid: 'ADGROUP_BID_CHANGE'
    }
  },
  editMapper: {
    CAMPAIGN_BUDGET_CHANGE: 'budget',
    KEYWORD_BID_CHANGE: 'bid',
    ADGROUP_BID_CHANGE: 'adgroup_bid',
    LINE_ITEM_BUDGET_CHANGE: 'budget',
    LINE_ITEM_BID_CHANGE: 'bid'
  },
  header: {
    title: 'Untitled strategy',
    desc: '',
    placeholder: 'Give this strategy a description'
  }
};

const getCubeRequest = (retailer) => ({
  cubeName: `${retailer}_campaigns_campaigns_workbench`,
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['campaign_type', 'status'],
  groupByDimensionsList: [
    'client_id',
    'campaign_name',
    'campaign_id',
    'advertiser_id'
  ],
  orderByList: [],
  where: { dimensionNameValueList: [] },
  limit: 20000,
  page: 1
});

const commonCubeDetailsConfig = {
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  orderByList: [],
  limit: 20000,
  page: 1
};

const promoteIqCommonCubeDetailsConfig = {
  ...commonCubeDetailsConfig,
  cubeName: 'promoteiq_campaigns_workbench',
  measuresList: ['promoteiq_cm_campaign_placement_type'],
  groupByDimensionsList: ['client_id', 'campaign_name', 'campaign_id', 'brand'],
  where: {
    dimensionNameValueList: []
  }
};

const criteoCommonCubeDetailsConfig = {
  cubeName: 'criteo_common_campaign_line_items_workbench',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['status', 'campaign_type'],
  groupByDimensionsList: ['client_id', 'line_item_id', 'line_item_name'],
  orderByList: [],
  limit: 20000,
  page: 1,
  where: {
    dimensionNameValueList: []
  }
};

const getCubeDetailsConfig = (retailerName) => ({
  cubeName: `${retailerName}_campaign_line_items_workbench`,
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['status', 'campaign_type'],
  groupByDimensionsList: ['client_id', 'line_item_id', 'line_item_name'],
  orderByList: [],
  limit: 20000,
  page: 1,
  where: {
    dimensionNameValueList: []
  }
});

const APIConfig = () => ({
  amazon: {
    ...commonCubeDetailsConfig,
    cubeName: 'ams_campaigns_campaign_workbench',
    measuresList: ['campaign_type', 'status'],
    groupByDimensionsList: [
      'client_id',
      'campaign_name',
      'campaign_id',
      'profile_id'
    ],
    orderByList: [],
    where: {
      dimensionNameValueList: []
    }
  },
  amazon_3p: {
    ...commonCubeDetailsConfig,
    cubeName: 'ams_campaigns_campaign_workbench',
    measuresList: ['campaign_type', 'status'],
    groupByDimensionsList: [
      'client_id',
      'campaign_name',
      'campaign_id',
      'profile_id'
    ],
    orderByList: [],
    where: {
      dimensionNameValueList: []
    }
  },
  walmart: getCubeRequest('walmart'),
  samsclub_api: getCubeRequest('samsclub_api'),
  instacart: {
    cubeName: 'instacart_campaigns_workbench',
    customAPIDecisionVars: {
      system: 'instacart_campaign_management'
    },
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: true,
    timeseriesDimension: 'report_date',
    pvpenabled: true,
    yoyenabled: false,
    measuresList: ['campaign_type'],
    groupByDimensionsList: ['campaign_name', 'campaign_id'],
    orderByList: [],
    where: { dimensionNameValueList: [] },
    limit: 20000,
    page: 1
  },
  kroger_promoteiq: promoteIqCommonCubeDetailsConfig,
  chewy_promoteiq: promoteIqCommonCubeDetailsConfig,
  target: getCubeDetailsConfig('target'),
  albertsons_criteo: criteoCommonCubeDetailsConfig,
  ubereats_criteo: criteoCommonCubeDetailsConfig,
  ulta_criteo: criteoCommonCubeDetailsConfig,
  target_rms: criteoCommonCubeDetailsConfig,
  walmart_criteo: criteoCommonCubeDetailsConfig,
  cvs_criteo: criteoCommonCubeDetailsConfig,
  meijer: getCubeDetailsConfig('meijer'),
  asda_criteo: criteoCommonCubeDetailsConfig,
  walgreen_criteo: criteoCommonCubeDetailsConfig,
  costco: getCubeDetailsConfig('costco'),
  freshdirect_criteo: criteoCommonCubeDetailsConfig
});

const getPromoteIqFilterParams = (retailer) => [
  'promoteiq_campaigns_workbench',
  'FETCH_FILTERS_V2',
  `${retailer}_campaigns`
];

const getCommonCriteoFilterParams = (retailer) => [
  `criteo_common_campaign_line_items_workbench`,
  'FETCH_FILTERS_V2',
  `${retailer}_line_items`
];

const getLineItemsFilterParams = (retailer) => [
  `${retailer}_campaign_line_items_workbench`,
  'FETCH_FILTERS_V2',
  `${retailer}_line_items`
];

const getFilterParams = (retailer) => [
  `${retailer}_campaigns_campaigns_workbench`,
  'FETCH_FILTERS_V2',
  `${retailer}_campaigns`
];

const filterParams = (retailer) => ({
  amazon: ['ams_campaigns_campaign_workbench', 'FETCH_FILTERS_V2', 'campaigns'],
  instacart: [
    'instacart_campaigns_workbench',
    'FETCH_FILTERS_V2',
    'instacart_campaigns'
  ],
  walmart: getFilterParams('walmart'),
  samsclub_api: getFilterParams('samsclub_api'),
  kroger_promoteiq: getPromoteIqFilterParams('kroger_promoteiq'),
  chewy_promoteiq: getPromoteIqFilterParams('chewy_promoteiq'),
  target: getLineItemsFilterParams('target'),
  albertsons_criteo: getCommonCriteoFilterParams('albertsons_criteo'),
  ubereats_criteo: getCommonCriteoFilterParams('ubereats_criteo'),
  ulta_criteo: getCommonCriteoFilterParams('ulta_criteo'),
  target_rms: getCommonCriteoFilterParams('target_rms'),
  walmart_criteo: getCommonCriteoFilterParams('walmart_criteo'),
  cvs_criteo: getCommonCriteoFilterParams('cvs_criteo'),
  meijer: getLineItemsFilterParams('meijer'),
  asda_criteo: getCommonCriteoFilterParams('asda_criteo'),
  walgreen_criteo: getCommonCriteoFilterParams('walgreen_criteo'),
  costco: getLineItemsFilterParams('costco'),
  freshdirect_criteo: getCommonCriteoFilterParams('freshdirect_criteo'),
  amazon_3p: [
    'ams_campaigns_campaign_workbench',
    'FETCH_FILTERS_V2',
    'campaigns'
  ]
});

const step1 = (retailer) => ({
  step: 'step1',
  index: 1,
  component: 'step1',
  entitySelect: [
    {
      display: 'CAMPAIGN',
      key: 'campaign'
    },
    {
      display: 'KEYWORD',
      key: 'keyword',
      disabled: true
    },
    {
      display: 'SKU',
      key: 'sku',
      disabled: true
    }
  ],
  ruleBuilder: {
    APIConfig: APIConfig()[retailer]
  },
  filterParams: filterParams()[retailer]
});

const step2 = () => ({
  step: 'step2',
  index: 2,
  component: 'step2',
  typeSelectSize: 45,
  typeSelect: [
    {
      display: 'STATIC LIST',
      key: 'STATIC',
      desc: "The target list remains constant and doen't change with time"
    },
    {
      display: 'DYNAMIC LIST',
      key: 'DYNAMIC',
      desc: 'The target list might change everything the rule runs',
      disabled: true
    }
  ],
  shortListingEntity: {
    mainList: {
      rows: []
    }
  }
});

const step4 = () => ({
  step: 'step1',
  index: 1,
  component: 'step3',
  entitySelect: [
    {
      display: 'CAMPAIGN',
      key: 'campaign'
    },
    {
      display: 'KEYWORD',
      key: 'keyword',
      disabled: true
    },
    {
      display: 'SKU',
      key: 'sku',
      disabled: true
    }
  ]
});

const mode = [
  {
    title: 'Change by percentage',
    value: 'PERCENTAGE'
  }
];

const operationTypes = {
  budget: {
    operation: [
      {
        label: 'Assign daily budget in %',
        type: 'EQUALS',
        value: 'EQUALS'
      },
      {
        label: 'Increase/Decrease budget by %',
        type: 'INCR/DECR',
        value: 'INCR/DECR'
      }
    ],
    mode
  },
  bid: {
    operation: [
      {
        label: 'Assign daily bids in %',
        type: 'EQUALS',
        value: 'EQUALS'
      },
      {
        label: 'Increase/Decrease bid by x% from base value',
        type: 'INCR/DECR',
        value: 'INCR/DECR'
      }
    ],
    mode
  },
  adgroup_bid: {
    operation: [
      {
        label: 'Assign adgroup bids in %',
        type: 'EQUALS',
        value: 'EQUALS'
      },
      {
        label: 'Increase/Decrease adgroup bid by x% from base value',
        type: 'INCR/DECR',
        value: 'INCR/DECR'
      }
    ],
    mode
  }
};

const commonStepConfigs = {
  actionSchedulerConfigs: (actions = ['budget', 'bid']) => ({
    step: 'step2',
    index: 2,
    component: 'actionScheduler',
    typeSelectSize: 45,
    actionMode: [
      {
        display: 'AUTOMATIC',
        key: 'AUTOMATIC',
        desc: "The target list remains constant and doesn't change with time"
      },
      {
        display: 'APPROVAL WORKFLOW',
        key: 'APPROVAlWORKFLOW',
        desc: 'The target list might change everything the rule runs',
        disabled: true
      }
    ],
    actionTypeOptions: [
      {
        title: 'Budget',
        value: 'budget'
      },
      {
        title: 'Bid',
        value: 'bid'
      },
      {
        title: 'Adgroup Bid',
        value: 'adgroup_bid'
      }
    ].filter((actionType) => actions.includes(actionType.value)),
    operationTypes: actions.reduce((acc, actionType) => {
      acc[actionType] = operationTypes[actionType];
      return acc;
    }, {}),
    frequencyList: [
      { title: 'S', value: 'SUN' },
      { title: 'M', value: 'MON' },
      { title: 'T', value: 'TUE' },
      { title: 'W', value: 'WED' },
      { title: 'T', value: 'THU' },
      { title: 'F', value: 'FRI' },
      { title: 'S', value: 'SAT' }
    ],
    defaultOperation: {
      'INCR/DECR': 'DECR',
      EQUALS: 'EQUALS'
    },
    defaultOperationForAction: {
      budget: 'EQUALS',
      bid: 'INCR/DECR',
      adgroup_bid: 'INCR/DECR'
    },
    currentSelectedAction: 'budget',
    selectedOperation: 'EQUALS',
    shortListingEntity: {
      mainList: {
        rows: []
      }
    },
    conditionalMarkupForSelectedOperation: {
      'INCR/DECR': {
        showActionMode: true,
        actionModes: [
          {
            title: 'Increase',
            value: 'INCR',
            color: 'green',
            icon: 'arrow-up'
          },
          { title: 'Decrease', value: 'DECR', color: 'red', icon: 'arrow-down' }
        ],
        inputLabel: ({ currentSelectedAction }) =>
          `% allocation of ${
            currentSelectedAction === 'budget' ? 'daily budget' : 'bid'
          }`,
        exampleText: ({ value, actionMode, currentSelectedAction }) =>
          `<strong>${value}%</strong> ${
            actionMode === 'INCR' ? 'increase' : 'decrease'
          } on a $100 daily ${currentSelectedAction} becomes <strong>$${
            actionMode === 'INCR' ? 100 + Number(value) : 100 - Number(value)
          }</strong>`
      },
      EQUALS: {
        showActionMode: false,
        inputLabel: ({ currentSelectedTabLabel }) =>
          `Adjust ${currentSelectedTabLabel} By`,
        exampleText: ({ value, currentSelectedAction }) =>
          `<strong>${value}%</strong> of a $100 daily ${currentSelectedAction} becomes <strong>$${Number(
            value
          )}</strong>`
      }
    },
    checkValidityForKey: 'EQUALS', // 100% overflow validation is applied to only this action
    ...actionTemplateUtils
  })
};

const indexMapping = {
  strategyScheduler: 3,
  rule: 0,
  target: 1,
  action: 2
};

const initRetailerConfig = (
  retailer,
  stepWiseConfigs = {},
  actions = ['budget', 'bid']
) => {
  return {
    [retailer]: {
      config: () => {
        return {
          strategy: {
            indexMapping,
            ...commonConfigs,
            steps: [
              {
                indexMapping,
                ...step1(retailer),
                ...(stepWiseConfigs?.[1] || {})
              },
              {
                indexMapping,
                ...step2(),
                ...(stepWiseConfigs?.[2] || {})
              },
              {
                indexMapping,
                ...commonStepConfigs.actionSchedulerConfigs(actions),
                ...(stepWiseConfigs?.[3] || {})
              },
              {
                indexMapping,
                ...step4(),
                ...(stepWiseConfigs?.[4] || {})
              }
            ]
          }
        };
      }
    }
  };
};

export default {
  ...initRetailerConfig('amazon'),
  ...initRetailerConfig('instacart', {}, ['budget']),
  ...initRetailerConfig('walmart', {
    1: {
      tooltipText:
        'Please include campaigns from the API advertiser account only.'
    }
  }),
  ...initRetailerConfig('samsclub_api', {
    1: {
      tooltipText:
        'Please include campaigns from the API advertiser account only.'
    }
  }),
  ...initRetailerConfig('kroger_promoteiq'),
  ...initRetailerConfig('chewy_promoteiq'),
  ...initRetailerConfig('target'),
  ...initRetailerConfig('albertsons_criteo'),
  ...initRetailerConfig('ubereats_criteo'),
  ...initRetailerConfig('ulta_criteo'),
  ...initRetailerConfig('target_rms'),
  ...initRetailerConfig('walmart_criteo'),
  ...initRetailerConfig('cvs_criteo'),
  ...initRetailerConfig('meijer'),
  ...initRetailerConfig('asda_criteo'),
  ...initRetailerConfig('walgreen_criteo'),
  ...initRetailerConfig('costco'),
  ...initRetailerConfig('freshdirect_criteo'),
  ...initRetailerConfig('amazon_3p')
};
