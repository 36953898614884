import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';

const state = {
  instacartSKUsData: {},
  instacartSKUsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  showAmsSkusActivityLog: false,
  AmsSkusPrimaryKey: null,
  page_wise_min_max_key: 'campaigns_sku'
};

const getters = {
  getInstacartSKUsSelectedFilters: (state) => {
    return state.instacartSKUsSelectedFilters;
  },
  getInstacartSKUsData: (state) => {
    return state.instacartSKUsData;
  },
  getInstacartSkusActivityLog: (state) => {
    return state.showAmsSkusActivityLog;
  },
  getInstacartSkusPrimaryKey: (state) => {
    return state.AmsSkusPrimaryKey;
  },
  getInstaCartSkusPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  IWKMSKUs_SUCCESS: (state, data) => {
    Vue.set(state.instacartSKUsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.instacartSKUsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.instacartSKUsData[data.key], 'data', data.columns);
    }
    Vue.set(state.instacartSKUsData[data.key], 'rows', data.rows);
    Vue.set(state.instacartSKUsData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.instacartSKUsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.instacartSKUsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.instacartSKUsData[data.key], 'page', data.page);
    Vue.set(state.instacartSKUsData[data.key], 'load', false);
    Vue.set(state.instacartSKUsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.instacartSKUsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.instacartSKUsData[data.key], 'noData', true);
    }
  },
  IWKMSKUs_RESET: (state, data) => {
    Vue.set(state.instacartSKUsData, data.key, {});
    Vue.set(state.instacartSKUsData[data.key], 'columns', []);
    Vue.set(state.instacartSKUsData[data.key], 'rows', []);
    Vue.set(state.instacartSKUsData[data.key], 'load', true);
    Vue.set(state.instacartSKUsData[data.key], 'error', false);
    Vue.set(state.instacartSKUsData[data.key], 'noData', false);
    Vue.set(state.instacartSKUsData[data.key], 'metrics', {});
  },
  IWKMSKUs_ERROR: (state, data) => {
    Vue.set(state.instacartSKUsData, data.key, {});
    Vue.set(state.instacartSKUsData[data.key], 'columns', []);
    Vue.set(state.instacartSKUsData[data.key], 'rows', []);
    Vue.set(state.instacartSKUsData[data.key], 'load', false);
    Vue.set(state.instacartSKUsData[data.key], 'error', true);
    Vue.set(state.instacartSKUsData[data.key], 'noData', false);
    Vue.set(state.instacartSKUsData[data.key], 'metrics', {});
  },
  IWKMSKUs_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.instacartSKUsSelectedFilters.date_range;
    Vue.set(state, 'instacartSKUsSelectedFilters', data.values);
    if (!state.instacartSKUsSelectedFilters.date_range) {
      Vue.set(state.instacartSKUsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKMSKUs(data, state);
  },
  OPEN_AMSSKUS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showAmsSkusActivityLog', true);
  },
  CLOSE_AMSSKUS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showAmsSkusActivityLog', false);
  },
  CHANGE_AMSSKUS_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'AmsSkusPrimaryKey', key);
  }
};

function getFiltersInFormatWKMSKUs(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  downloadSKUTableData: (context, data) => {
    return HttpLayer.post({
      cube: 'EXECUTE_CUSTOM_CUBE_SKU_API',
      APIData: data
    }).then((response) => {
      response.data = response.data.map((item) => {
        if (
          item.RESULT.total_sales === null ||
          item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU < 0
        ) {
          item.RESULT.organic_sales = null;
        } else {
          item.RESULT.organic_sales =
            item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU;
        }
        return item;
      });
      console.log(transformer.mergeResultDimension(response.data, true));
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  setInstacartSKUsSelectedFilters: (context, data) => {
    context.commit('IWKMSKUs_SET_SELECTED_FILTER', data);
  },
  getInstacartSKUsTblData: (context, data) => {
    context.commit('IWKMSKUs_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;

    // Hardcode date.
    // data.body.APIConfig.where.date = {
    //   'from': '2020-07-01',
    //   'to': '2020-07-15'
    // };

    // data.body.APIConfig.where.pvpDate = {
    //   'from': '2020-07-04',
    //   'to': '2020-07-30'
    // };
    // ends.

    HttpLayer.post({
      cube: 'EXECUTE_CUBE_API',
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKMSKUs_ERROR', {
          key: data.meta.key
        });
      } else {
        response.data = response.data.map((item) => {
          if (
            item.RESULT.total_sales === null ||
            item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU < 0
          ) {
            item.RESULT.organic_sales = null;
          } else {
            item.RESULT.organic_sales =
              item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU;
          }
          return item;
        });
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('IWKMSKUs_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].auto_cubesdk_count || _aArray[0].count,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IWKMSKUs_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getInstacartSKUsChartData: (context, data) => {
    context.commit('IWKMSKUs_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
      context.state.whereClause
    );
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.body.APIConfig.pvpenabled =
      data.body.APIConfig.where.pvpDate !== undefined;

    // Date and PVPDate hardcoded
    // data.body.APIConfig.where.date = {
    //   'from': '2020-07-01',
    //   'to': '2020-07-15'
    // };

    // data.body.APIConfig.where.pvpDate = {
    //   'from': '2020-07-04',
    //   'to': '2020-07-30'
    // };
    // Date Hardcoding ends.

    HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKMSKUs_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          // if (response.data && response.data.length > 0 && response.data[0] && response.data[0].PVP) {
          //   response.data[0].PVP.organic_sales = response.data[0].PVP.total_sales - response.data[0].PVP.attributedSales14dSameSKU;
          // }

          // if (response.data[0].RESULT.organic_sales === null || (response.data[0].RESULT.total_sales - response.data[0].RESULT.attributedSales14dSameSKU) < 0) {
          //   response.data[0].RESULT.organic_sales = null;
          // } else {
          //   response.data[0].RESULT.organic_sales = response.data[0].RESULT.total_sales - response.data[0].RESULT.attributedSales14dSameSKU;
          // }
          // if (response.data && response.data.length > 0 && response.data[0] && response.data[0].PVP && response.data[0].PVP.organic_sales) {
          //   response.data[0].PVP.PVP_organic_sales = ((response.data[0].RESULT.organic_sales - response.data[0].PVP.organic_sales) / response.data[0].PVP.organic_sales) * 100;
          // }
          if (response.data[0].TIMESERIES) {
            // response.data[0].TIMESERIES = response.data[0].TIMESERIES.map((item) => {
            //   if (item.total_sales === null || ((item.total_sales - item.attributedSales14dSameSKU) < 0)) {
            //     item.organic_sales = null;
            //   } else {
            //     item.organic_sales = item.total_sales - item.attributedSales14dSameSKU;
            //   }
            //   return item;
            // });
            response.data[0].TIMESERIES.sort((a, b) => {
              return new Date(a.report_date) - new Date(b.report_date);
            });
          }
          var xs = {};
          for (var i = 0; i < response.data.length; i++) {
            var aTemp = [];
            aTemp = transformer.getChartDataInFormat(
              data,
              response.data[0].TIMESERIES
            );
          }

          for (i = 0; i < aTemp.length; i++) {
            if (aTemp[i][0] !== 'report_date') {
              xs[aTemp[i][0]] = 'report_date';
            }
          }
          context.commit('IWKMSKUs_SUCCESS', {
            key: data.meta.key,
            columns: aTemp,
            xs,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            metricsUnits: data.body.getTagUnitData
              ? data.body.getTagUnitData(response.data)
              : {}
          });
        } else {
          context.commit('IWKMSKUs_SUCCESS', {
            key: data.meta.key,
            columns: [],
            metrics: {}
          });
        }
      }
    });
  },
  getAmsSkusActivityLogForInstacart: (context, data) => {
    context.commit('ACTIVITY_LOG_RESET', {
      key: 'AmsSkusAcitivityLog'
    });
    context.commit('CHANGE_AMSSKUS_PRIMARYKEY', data.primaryKey);
    HttpService.get('AMS_ACTIONS', {
      append: '?widget=amsSkus&primaryKey=' + data.primaryKey
    }).then((response) => {
      if (response.data && !response.data.success) {
        context.commit('ACTIVITY_LOG_ERROR', {
          key: 'AmsSkusAcitivityLog',
          rows: []
        });
      } else {
        context.commit('ACTIVITY_LOG_SUCCESS', {
          key: 'AmsSkusAcitivityLog',
          rows: activitytransformer.getActivityData(response.data.response)
        });
      }
    });
  },

  openAmsSkusActivityLog: (context) => {
    context.commit('OPEN_AMSSKUS_ACTIVITY_LOG');
  },
  closeAmsSkusActivityLog: (context) => {
    context.commit('CLOSE_AMSSKUS_ACTIVITY_LOG');
    context.commit('CHANGE_AMSSKUS_PRIMARYKEY', null);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
