<template>
  <div>
    <modal
      v-if="showEdit"
      :modal-config="modalConfig"
    >
      <div slot="body">
        <div
          v-if="!isConfirm"
          class="content-edit-container u-spacing-pv-l"
        >
          <div
            class="current-content-score"
            :style="{ background: getColors(contentDetails.contentScore) }"
          >
            <rb-icon
              v-if="
                [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(
                  contentDetails.state
                )
              "
              icon="warning"
              :class="{
                'u-color-orange-base':
                  SKU_STATE.IMPROVED === contentDetails.state,
                'u-color-red-base': SKU_STATE.NEGATIVE === contentDetails.state
              }"
            />
            <div>
              <p class="u-font-size-5 u-font-weight-600">
                <span class="u-text-transform-capitalize">{{
                  contentDetails.contentType
                }}</span>
                content Score
                <span class="u-font-size-2 u-spacing-p-s u-font-weight-800">{{
                  contentDetails.contentScore + '%'
                }}</span>
              </p>
              <p class="u-font-size-5">
                {{ contentDetails.contentScoreDescription }}
              </p>
            </div>
          </div>
          <div class="current-content u-spacing-mt-xl">
            <div class="u-display-flex u-flex-justify-content-space-between">
              <p class="u-font-size-3 u-color-grey-mid-light u-font-weight-600">
                Current Content
              </p>
              <rb-icon
                icon="pencil"
                class="u-color-blue-base"
              />
            </div>
            <div class="u-spacing-mt-m">
              <p class="u-font-size-4">
                {{ contentDetails.currentContent }}
              </p>
            </div>
          </div>
          <div class="container-base suggestion u-spacing-mt-xl">
            <div class="u-display-flex u-flex-justify-content-space-between">
              <p class="u-font-size-3 u-color-grey-mid-light u-font-weight-600">
                Suggested Content
              </p>
              <p class="u-font-size-3 u-color-grey-mid-light">
                Source Data: {{ contentDetails.source }}
              </p>
            </div>
            <div class="u-spacing-mt-m">
              <p class="u-font-size-4">
                {{ contentDetails.suggestedContent }}
              </p>
            </div>
            <rb-button
              text="Cancel"
              type="hollow"
              class="u-color-grey-white u-spacing-mt-m u-spacing-mr-m u-bg-color-grey-x-light"
              :click-fn="cancelOptimize.bind(this)"
            />
            <rb-button
              text="Optimise content"
              type="filled"
              icon-left="segment"
              class="u-color-grey-white u-spacing-mt-m"
              :click-fn="matchSOTConentent.bind(this)"
            />
          </div>
        </div>
        <div
          v-if="isConfirm && contentDetails.retailer === RETAILER.AMAZON"
          class="u-spacing-pv-l"
        >
          <p class="u-font-size-4">
            Ticket will be raised to update your content
          </p>
          <div class="u-display-flex">
            <rb-button
              text="Cancel"
              type="hollow"
              class="u-color-grey-white u-spacing-mt-m u-spacing-mr-m u-bg-color-grey-x-light"
              :click-fn="cancelRaiseTicket.bind(this)"
            />
            <rb-button
              text="Yes, raise a ticket"
              type="filled"
              class="u-color-grey-white u-spacing-mt-m"
              :click-fn="confirmRaisTicket.bind(this)"
            />
          </div>
        </div>
        <email
          v-if="isConfirm && contentDetails.retailer !== RETAILER.AMAZON"
          :show-email-modal="isConfirm"
          :email-details="emailDetails"
          @onSendEamil="confirmRaisTicket"
          @onCancelEmail="cancelRaiseTicket"
        >
          <template slot="body">
            <div class="u-font-size-4">
              <p class="u-spacing-mt-l">
                We discovered the content score for the
                {{ contentDetails.contentType }} to be
                <span class="u-font-weight-600"
                  >{{ contentDetails.contentScore }}
                </span>
                since the product
                {{ contentDetails.contentType }}
                <span class="u-font-weight-600">
                  {{ contentDetails.currentContent }}
                </span>
                is not consistent with the source
                <span class="u-font-weight-600">{{
                  contentDetails.source
                }}</span
                >.
              </p>
              <p class="u-spacing-mt-l">
                Therefore, we kindly request that you change it to
                <span class="u-font-weight-600">{{
                  contentDetails.suggestedContent
                }}</span
                >.
              </p>
              <p class="u-spacing-mt-l">
                Kindly provide us an update on any open or in-progress items.
              </p>
            </div>
          </template>
        </email>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/widgets/modal.vue';
import { SKU_STATE, RETAILER } from '@/pages/all_retailers/dso/constant';
import { getColors } from '@/pages/all_retailers/dso/utils/contentAutomation';
import email from '@/pages/all_retailers/dso/molecules/email.vue';

export default {
  name: 'EditProductName',
  components: {
    modal,
    email
  },
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    contentDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SKU_STATE,
      RETAILER,
      modalConfig: {
        footer: {}
      },
      updatedProductName: '',
      isConfirm: false,
      emailDetails: {}
    };
  },
  methods: {
    matchSOTConentent() {
      this.emailDetails = {
        to: 'vendor.manager@amazon.co',
        from: 'client@commerceiq.ai',
        body: ''
      };
      this.isConfirm = true;
    },
    confirmRaisTicket() {
      this.$emit('onNameDescriptionEdit', this.contentDetails);
      this.isConfirm = false;
    },
    cancelRaiseTicket() {
      this.isConfirm = false;
    },
    cancelOptimize() {
      this.$emit('onEditNameDescriptionCancel');
    },
    getColors
  }
};
</script>

<style scoped lang="css">
.content-edit-container {
  line-height: 2rem;
}
.content-edit-container .container-base {
  border-radius: 4px;
  padding: 16px 12px;
}
.content-edit-container .suggestion {
  background: #ebf5fe;
}
.content-edit-container .current-content-score {
  background: #fffeea;
  padding: 16px 12px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
</style>
