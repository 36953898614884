<template>
  <div class="u-spacing-pv-xxxl u-spacing-ph-xxl">
    <section class="u-spacing-pv-m">
      <Header :step="step" />
      <span class="u-font-size-5 u-font-weight-600 u-color-grey-base"
        >Objectives:</span
      >
      <RadioGroup
        :radio-options="campaignObjectives"
        :value="selectedObjective"
        :is-column="true"
        group-label-class="u-spacing-mb-l"
        @onRadioChanged="handleRadioSelection"
      />

      <Footer
        class="u-spacing-pt-l"
        :show-left-btn="false"
        @onClickBtnRight="nextStep"
      />
    </section>
  </div>
</template>

<script>
import Header from '@/components/ams/campaign_creation/steps/common/header.vue';
import Footer from '@/components/ams/campaign_creation/steps/common/footer.vue';
import RadioGroup from '../radio-group.vue';
import {
  CAMPAIGN_OBJECTIVES,
  STEPS_DATA_GETTER,
  OBJECTIVE_TYPES,
  STEPS_DATA_SETTER,
  CAMPAIGN_BID_TYPES
} from '@/components/pages/instacart/campaign-creation/config';
export default {
  name: 'Objective',
  components: {
    RadioGroup,
    Header,
    Footer
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    campaignObjectives: CAMPAIGN_OBJECTIVES,
    selectedObjective: ''
  }),
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    }
  },
  created() {
    this.OTHER_OBJECTIVE_TYPE = OBJECTIVE_TYPES.other.type;
    const { type } = this.$store.getters[STEPS_DATA_GETTER].steps.objective;
    this.selectedObjective = type;
  },
  methods: {
    nextStep() {
      this.$emit('nextStep', {});
    },
    handleRadioSelection(value) {
      const stepsData = { ...this.stepsData };

      this.selectedObjective = value;
      stepsData.steps.objective = {
        type: value,
        value: value
      };
      if (value === 'other') {
        stepsData.steps.settings.bidType = CAMPAIGN_BID_TYPES(value)[2].type;
      } else {
        stepsData.steps.settings.bidType = CAMPAIGN_BID_TYPES(value)[0].type;
      }
      stepsData.strategyUIText.objective = OBJECTIVE_TYPES[value].label;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    handleInputChange(event) {
      const { value } = event.target;
      const stepsData = { ...this.stepsData };
      stepsData.steps.objective.value = value;
      stepsData.strategyUIText.objective = value || OBJECTIVE_TYPES.other.label;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    }
  }
};
</script>

<style lang="css" scoped>
.errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .rb-icon {
  align-self: flex-start;
}

.u-width-230px {
  width: 230px;
}
</style>
