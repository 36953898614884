import actionsConfig from '@/components/pages/recommendations/actions.js';

export default {
  data() {
    return {
      actionsConfig: actionsConfig
    };
  },
  computed: {
    isBudgetPlanManualAction() {
      const validRouteNames = [
        'budget-plan-keyword-bid',
        'budget-plan-campaign-budget'
      ];
      const validUserRole = 'MarketingIQ';
      const validStatus = ['OPEN', 'FAILED'];
      if (!validRouteNames.includes(this.$route.name)) {
        return false;
      }
      if (!this.$store.getters.getSessionUser.roles.includes(validUserRole)) {
        return false;
      }
      if (!validStatus.includes(this.params.data.status)) {
        return false;
      }
      return true;
    },
    currentAction() {
      // assuming only 1 action type is supported per recommendation row
      return this.actionPayload
        ? Object.keys(this.actionPayload)[0]
        : undefined;
    },
    isActioned() {
      return this.params.data.actions;
    },
    actionPayload() {
      return this.params.data.action_payload
        ? JSON.parse(this.params.data.action_payload)
        : undefined;
    },
    isBoomerangActionSupported() {
      let actionSupported = false;
      if (this.actionPayload) {
        actionSupported = this.isEnabledFromConfig(actionSupported);
      }
      return actionSupported;
    },
    isFailedState() {
      let isFailed = false;
      if (
        this.isBudgetPlanManualAction &&
        this.params.data.status &&
        this.params.data.status === 'FAILED'
      ) {
        isFailed = true;
      }
      if (
        this.params.data.action_state &&
        this.params.data.action_state === 'FAILED'
      ) {
        isFailed = true;
      }
      return isFailed;
    },
    isActionApplicable() {
      let actionApplicable = false;
      var roles =
        (this.$store.getters.getSessionUser &&
          this.$store.getters.getSessionUser.roles) ||
        [];
      // for manual actions page
      if (this.isBudgetPlanManualAction) {
        return true;
      }
      //
      if (roles.indexOf(this.$store.state.alerts.actionRoleRequired) === -1) {
        return false;
      }
      if (
        this.params.colDef.field !== 'recommended_actions' &&
        this.params.context.componentParent.showActionsWorkspace === true
      ) {
        return false;
      }
      if (this.params.data.action_state === 'OPEN' || this.isFailedState) {
        actionApplicable = true;
      }
      return actionApplicable;
    }
  },
  methods: {
    isEnabledFromConfig(flagVariable) {
      const enabledActions =
        this.actionsConfig.enabledActions[
          this.params.context.componentParent.fetchedRecommendationType
        ];
      if (enabledActions && enabledActions.length) {
        for (var i = 0; i < enabledActions.length; i++) {
          if (enabledActions[i].action === this.currentAction) {
            flagVariable = true;
            break;
          }
        }
      }
      return flagVariable;
    }
  }
};
