const criteoConfigs = {
  optimizationStrategySelections: [
    {
      title: 'Conversions',
      value: 'conversion'
    },
    {
      title: 'Revenue',
      value: 'revenue'
    },
    {
      title: 'Clicks',
      value: 'clicks'
    }
  ],
  conversionsAndRevenueStrategyOptions: [
    {
      title: 'Set new max bid',
      type: 'input',
      value: 'set_new_mb'
    },
    {
      title: 'Increase max bid',
      type: 'dropdown',
      value: 'increase_mb',
      dropdown: [
        {
          label: 'Change by percentage',
          value: 'percentage'
        },
        {
          label: 'Change absolute value',
          value: 'absolute_value'
        }
      ]
    },
    {
      title: 'Decrease max bid',
      value: 'decrease_mb',
      type: 'dropdown',
      dropdown: [
        {
          label: 'Change by percentage',
          value: 'percentage'
        },
        {
          label: 'Change absolute value',
          value: 'absolute_value'
        }
      ]
    },
    {
      title: 'No max bid',
      value: 'no_max_bid'
    }
  ]
};
export default {
  target: criteoConfigs,
  meijer: criteoConfigs,
  costco: criteoConfigs,
  freshdirect_criteo: criteoConfigs,
  samsclub: criteoConfigs,
  shipt_criteo: criteoConfigs,
  walmart_criteo: criteoConfigs,
  walgreen_criteo: criteoConfigs,
  cvs_criteo: criteoConfigs,
  asda_criteo: criteoConfigs,
  ulta_criteo: criteoConfigs,
  albertsons_criteo: criteoConfigs,
  ubereats_criteo: criteoConfigs,
  target_rms: criteoConfigs
};
