<template>
  <div class="u-spacing-p-m u-bg-color-white-base">
    <modal
      v-if="showPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div class="u-font-size-6 u-font-weight-600">Current Recipients</div>
    <div class="u-spacing-mv-s">
      <div
        v-for="(receipient, index) in ldapUsers"
        :key="index"
        class="u-font-size-7 u-display-flex u-flex-direction-row"
      >
        <receipientComponent
          :mail="receipient.mail"
          :type="'ldapUsers'"
          :is-active="receipient.isActive"
          :num-active-users="activeUsers.length"
          @toggleReceipient="handleToggleReceipient"
        />
      </div>
      <div
        v-for="(receipient, index) in sfUsers"
        :key="ldapUsers.length + index"
        class="u-font-size-7 u-display-flex u-flex-direction-row"
      >
        <receipientComponent
          :id="receipient.id"
          :mail="receipient.mail"
          :is-editable="true"
          :loading="receipient.loading"
          :is-active="receipient.isActive"
          :type="'sfUsers'"
          :num-active-users="activeUsers.length"
          @toggleReceipient="handleToggleReceipient"
          @deleteUser="openDeleteUserModal"
        />
      </div>
    </div>
    <div class="u-display-flex u-flex-justify-content-center u-width-100">
      <loader
        class="u-bg-color-grey-white u-spacing-mv-s"
        :loading="ldapUsersLoading || sfUsersLoading"
        :color="'#007cf6'"
      />
    </div>
    <div
      class="u-font-size-6 u-color-grey-lighter u-spacing-mv-xs u-cursor-pointer u-display-flex u-flex-align-items-center u-text-hover-color"
      @click="handleAddUser"
    >
      <rb-icon
        class="rb-icon--bp-custom u-spacing-mh-xxs u-color-grey-lighter"
        :icon="'plus'"
      />
      Add Recipient
    </div>
    <div
      v-if="addingUser"
      class="u-spacing-pv-xs"
    >
      <div class="u-spacing-mv-s">
        <label
          class="u-display-block u-font-size-7 u-font-weight-600 u-color-grey-base"
          >Email Id</label
        >
        <input
          v-model="mail"
          class="u-spacing-mv-xs u-font-size-7 u-spacing-p-s u-width-100 mv-add-recipient-input"
          :class="{
            error: (formTouched && password === '') || invalidDomainError
          }"
          placeholder="jamesblunt@domain.ai"
        />
        <label
          v-if="formTouched && mail === ''"
          class="u-color-red-base u-font-size-7"
          >Please enter the E-Mail.</label
        >
        <div
          v-if="invalidDomainError"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-color-red-base rb-icon--bp-custom rb-icon--small u-spacing-mr-xs"
            :icon="'info-circle-fill'"
          />
          <label class="u-color-red-base u-font-size-7">Invalid Domain.</label>
        </div>
        <div
          v-if="userAlreadyExistsError"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-color-red-base rb-icon--bp-custom rb-icon--small u-spacing-mr-xs"
            :icon="'info-circle-fill'"
          />
          <label class="u-color-red-base u-font-size-7"
            >User Already Exists</label
          >
        </div>
      </div>
      <div class="u-display-flex u-flex-justify-content-flex-end u-width-100">
        <rb-button
          :click-fn="cancelAddUser"
          class="u-spacing-mh-s u-display-flex u-align-items-flex-center u-flex-justify-content-center u-font-size-7 u-font-weight-600"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
        />
        <rb-button
          :click-fn="checkForDuplicates"
          class="u-display-flex u-align-items-flex-center u-flex-justify-content-center u-font-size-7 u-font-weight-600"
          :loading="insertStatusLoad"
          :size="'s'"
          :text="'Add'"
          :disabled="
            isAddDisabled ||
            insertStatusLoad ||
            invalidDomainError ||
            userAlreadyExistsError
          "
          :type="'filled'"
        />
      </div>
      <div
        v-if="invalidDomainError && !insertStatusLoad"
        class="u-display-flex u-flex-justify-content-flex-end u-spacing-mv-s"
        @click="insertUser"
      >
        <label
          class="u-color-blue-base error-circumvent u-cursor-pointer u-font-size-7"
          >Ignore Error And Add</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import receipientComponent from './components/receipient-component';
import modal from '@/components/widgets/modal.vue';
export default {
  components: {
    receipientComponent,
    loader,
    modal
  },
  data() {
    return {
      mail: '',
      invalidDomainError: false,
      addingUser: false,
      formTouched: false,
      userAlreadyExistsError: false,
      modalConfig: {},
      showPopUp: false
    };
  },
  computed: {
    ldapUsersLoading() {
      return this.$store.getters.ldapUsers.load;
    },
    ldapUsers() {
      return this.$store.getters.ldapUsers.rows;
    },
    sfUsersLoading() {
      return this.$store.getters.sfUsers.load;
    },
    sfUsers() {
      return this.$store.getters.sfUsers.rows;
    },
    insertStatusLoad() {
      return this.$store.getters.insertStatus.load;
    },
    isAddDisabled() {
      return this.mail === '';
    },
    allUsers() {
      return [...this.ldapUsers, ...this.sfUsers];
    },
    activeUsers() {
      return this.allUsers.filter((item) => item.isActive);
    },
    allowedDomains() {
      const domains = this.allUsers.map((item) => {
        const splitItemArr = item.mail.split('@');
        const domain = splitItemArr[splitItemArr.length - 1];
        return domain;
      });
      const allowedDomains = [...new Set(domains)];
      return allowedDomains;
    }
  },
  watch: {
    mail: function (val) {
      this.invalidDomainError = false;
      this.userAlreadyExistsError = false;
    }
  },
  created() {
    this.$store.dispatch('fetchLdapUsers');
    this.$store.dispatch('fetchSfUsers');
  },
  methods: {
    clickedOutsideModal(val) {
      if (val) {
        this.showPopUp = false;
      }
    },
    async handleDeleteUser(data) {
      try {
        this.showPopUp = false;
        await this.$store.dispatch('deleteUserSf', data);
        this.$snackbar.open({
          message: 'Successfully deleted user',
          duration: 5000,
          actionText: ' '
        });
      } catch (err) {
        this.$snackbar.open({
          message: "Couldn't delete the user",
          duration: 5000,
          actionText: ' '
        });
      }
    },
    openDeleteUserModal(data) {
      this.modalConfig = {
        bodyTitle: {
          text: 'Delete User?',
          highlightText: '',
          icon: 'email-subscription'
        },
        body: {
          text: `Are you sure you want to delete ${data.mail} from the mailing list?`
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showPopUp = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Continue',
            onClick: () => {
              this.handleDeleteUser(data);
            },
            icon: '',
            show: true
          }
        }
      };
      this.showPopUp = true;
    },
    getLastElementAfterSplit(string, delimiter) {
      const splitItemArr = string.split('@');
      const lastItem = splitItemArr[splitItemArr.length - 1];
      return lastItem;
    },
    handleToggleReceipient(item) {
      this.$store.dispatch('handleReceipientsToggle', item);
    },
    handleAddUser() {
      this.addingUser = true;
    },
    cancelAddUser() {
      this.resetForm();
    },
    checkForDuplicates() {
      const index = this.allUsers.findIndex((item) => item.mail === this.mail);
      if (index >= 0) {
        this.userAlreadyExistsError = true;
      } else {
        this.addUser();
      }
    },
    addUser() {
      const currentDomain = this.getLastElementAfterSplit(this.mail, '@');
      if (this.allowedDomains.includes(currentDomain)) {
        this.insertUser();
      } else {
        this.invalidDomainError = true;
      }
    },
    resetForm() {
      this.invalidDomainError = false;
      this.formTouched = false;
      this.mail = '';
      this.addingUser = false;
      this.userAlreadyExistsError = false;
    },
    insertUser() {
      this.$store
        .dispatch('insertUserIntoSf', { mail: this.mail })
        .then(() => {
          this.resetForm();
          this.$snackbar.open({
            message: 'Successfully inserted user',
            duration: 5000,
            actionText: ' '
          });
        })
        .catch(() => {
          this.$snackbar.open({
            message: "Couldn't insert the user",
            duration: 5000,
            actionText: ''
          });
        });
    }
  }
};
</script>

<style lang="css" scoped>
.error-circumvent:hover {
  text-decoration: underline;
}
.container {
  overflow-y: visible;
  max-height: 50px;
}
.mv-add-recipient-input {
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
.mv-add-recipient-input::placeholder {
  color: #caccce;
}
.mv-add-recipient-input.error {
  border-color: #d7263d;
}
.mv-add-recipient-input:focus {
  border-color: #007cf6;
}
.rb-icon--bp-custom {
  height: 14px;
  width: 14px;
}
.rb-icon--bp-custom:before {
  font-size: 14px;
}

.u-text-hover-color:hover {
  color: #4b5158;
}
</style>
