import { Entities } from '@/pages/toolsAndSettings/const.js';

const redirectConfig = [
  {
    path: 'totp',
    redirect: '/totp'
  },
  {
    path: 'Business-Overview',
    redirect: '/business_overview'
  },
  {
    path: 'workbench/skus/:id?',
    redirect: '/assortment/skus/:id?'
  },
  {
    path: 'workbench/skus/:id?',
    redirect: '/assortment/skus/'
  },
  {
    path: 'campaign-management/campaigns',
    redirect: '/advertising/campaign_management/campaigns'
  },
  {
    path: 'campaign-management/campaigns',
    redirect: '/advertising/campaign_management/campaigns'
  },
  {
    path: 'campaign-management/campaigns/:entityType/:entityId',
    redirect: '/advertising/campaign_management/campaigns/:entityType/:entityId'
  },
  {
    path: 'campaign-management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
    redirect:
      '/advertising/campaign_management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId'
  },
  {
    path: 'campaign-management/campaigns/:entityType/:entityId',
    redirect: '/advertising/campaign_management/campaigns/:entityType/:entityId'
  },
  {
    path: 'campaign-management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
    redirect:
      '/advertising/campaign_management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId'
  },
  {
    path: 'campaign-management/keywords',
    redirect: '/advertising/campaign_management/keywords'
  },
  {
    path: 'campaign-management/search-terms',
    redirect: '/advertising/campaign_management/search_terms'
  },
  {
    path: 'campaign-management/ams-skus',
    redirect: '/advertising/campaign_management/ad_skus'
  },
  {
    path: 'dsp-campaign-management/orders',
    redirect: '/advertising/dsp_campaign_management/orders'
  },
  {
    path: 'dsp-campaign-management/line_items',
    redirect: '/advertising/dsp_campaign_management/line_items'
  },
  {
    path: 'budget-optimizer',
    redirect: '/advertising/budget_optimizer_v2'
  },
  {
    path: 'budget-optimizer/actions/campaigns',
    redirect: '/advertising/budget_optimizer_v2/actions/campaigns'
  },
  {
    path: 'budget-optimizer/actions/keywords',
    redirect: '/advertising/budget_optimizer_v2/actions/keywords'
  },
  {
    path: 'insights/sales-overview',
    redirect: '/reports/sales_overview'
  },
  {
    path: 'insights/sales-overview',
    redirect: '/reports/sales_overview'
  },
  {
    path: 'insights/finance',
    redirect: '/reports/finance'
  },
  {
    path: 'insights/share-of-voice',
    redirect: '/digital_shelf/share_of_voice'
  },
  {
    path: 'insights/price-war',
    redirect: '/digital_shelf/price_war'
  },
  {
    path: 'insights/shipment-tracker',
    redirect: '/supply_chain/shipment_tracker'
  },
  {
    path: 'insights/po-fill-rate',
    redirect: '/supply_chain/po_fill_rate'
  },
  {
    path: 'insights/reviews-&-ratings',
    redirect: '/digital_shelf/reviews_and_ratings'
  },
  {
    path: 'insights/content',
    redirect: '/digital_shelf/content_audit'
  },
  {
    path: 'insights/forecast',
    redirect: '/supply_chain/sales_forecast'
  },
  {
    path: 'strategies',
    redirect: '/advertising/hourly_bidder'
  },
  {
    path: 'recommendations/all',
    redirect: '/recommendations/all'
  },
  {
    path: 'recommendations/marketing',
    redirect: '/recommendations/marketing'
  },
  {
    path: 'recommendations/sales',
    redirect: '/recommendations/sales'
  },
  {
    path: 'recommendations/operations',
    redirect: '/recommendations/operations'
  },
  {
    path: 'strategyList',
    redirect: '/advertising/campaign_optimizer'
  },
  {
    path: 'actionlogs',
    redirect: '/actions_log'
  },
  {
    path: 'automations_and_impact',
    redirect: '/automations_and_impact'
  },
  {
    path: 'insights/salesoverview',
    redirect: '/reports/sales_overview'
  },
  {
    path: 'strategies/create',
    redirect: '/advertising/hourly_bidder/create'
  },
  {
    path: 'strategies/edit',
    redirect: '/advertising/hourly_bidder/create'
  },
  {
    path: 'dvtInternalTool',
    redirect: '/dvtInternalTool'
  },
  {
    path: 'mailValidator',
    redirect: '/mailValidator'
  },
  {
    path: 'sqlDataValidator',
    redirect: '/sqlDataValidator'
  },
  {
    path: 'product_diagnostics',
    redirect: '/product_diagnostics'
  },
  {
    path: 'product_sla',
    redirect: '/product_sla'
  },
  {
    path: 'account_manager',
    redirect: '/account_manager'
  },
  {
    path: 'product-diagnostics',
    redirect: '/product_diagnostics'
  },
  {
    path: 'reportedIssues',
    redirect: '/reportedIssues'
  },
  {
    path: 'internalToolBox',
    redirect: '/internalToolBox'
  },
  {
    path: 'invite',
    redirect: '/invite'
  },
  {
    path: 'file-download',
    redirect: '/file-download'
  },
  {
    path: 'performance',
    redirect: '/performance'
  },
  {
    path: 'manageSubscription',
    redirect: '/manageSubscription'
  },
  {
    path: 'manageSubscription/unsubscribe',
    redirect: '/manageSubscription/unsubscribe'
  },
  {
    path: 'strategyList',
    redirect: '/advertising/campaign_optimizer'
  },
  {
    path: 'dp/:id?',
    redirect: '/dp/:id?'
  },
  {
    path: 'tools_and_settings',
    redirect: '/tools_and_settings'
  },
  {
    path: 'tools_and_settings/digital-shelves',
    redirect: '/tools_and_settings/digital-shelves'
  },
  {
    path: 'tools_and_settings/campaign-lists',
    redirect: '/tools_and_settings/campaign-lists'
  },
  {
    path: 'tools_and_settings/catalog',
    redirect: '/tools_and_settings/catalog'
  },
  {
    path: 'tools_and_settings/content-change',
    redirect: '/tools_and_settings/content-change'
  },
  {
    path: 'tools_and_settings/variant-change',
    redirect: '/tools_and_settings/variant-change'
  },
  {
    path: `tools_and_settings/${Entities.campaign}`,
    redirect: `/tools_and_settings/${Entities.campaign}`
  }
];

// const redirectConfig = [
//   {
//     path: 'totp',
//     redirect: '/us/amazon/retail/totp'
//   },
//   {
//     path: 'Business-Overview',
//     redirect: '/us/amazon/retail/business_overview'
//   },
//   {
//     path: 'workbench/skus/:id?',
//     redirect: '/us/amazon/retail/assortment/skus/:id?'
//   },
//   {
//     path: 'campaign-management/:tab?',
//     redirect: 'us/amazon/retail/advertising/campaign_management/:tab?'
//   }
// ];

export default redirectConfig;
