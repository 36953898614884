<template>
  <multi-level-selector-dropdown
    :init-selections="selections"
    :filter-data="filterData"
    :top-level-multi="topLevelMulti"
    @selectionsChanged="selectionsChanged"
  >
    <div
      slot="childLevelSelectorHeader"
      slot-scope="{ level }"
      class="u-display-flex u-flex-align-items-center"
    >
      <div
        v-if="level != 0"
        class="filter--header"
      >
        <div class="filter--header--content">
          {{ headerString }} (Level {{ level + 1 }})
        </div>
      </div>
    </div>
  </multi-level-selector-dropdown>
</template>
<script>
import { eventBus } from '@/utils/services/eventBus';
import multiLevelSelectorDropdown from '@/components/widgets/filter/multi-level-selector-dropdown';
export default {
  name: 'StandardShelf',
  components: {
    multiLevelSelectorDropdown
  },
  props: {
    selections: {
      type: Array,
      default: undefined
    },
    api: {
      type: String,
      required: true
    },
    body: {
      type: Object,
      required: false,
      default: undefined
    },
    requestConfig: {
      type: Object,
      default: undefined
    },
    method: {
      type: String,
      default: undefined
    },
    filterDataKey: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      initSelections: {},
      headerString: 'Standard Shelf'
    };
  },
  computed: {
    filterData() {
      return this.$store.getters.getHeirarchyFilterData;
    },
    topLevelMulti() {
      return this.$store.getters.getTopLevelMulti;
    },
    filterDataCount() {
      return Object.keys(this.filterData || {}).length;
    }
  },
  created() {
    if (this.api !== 'GET_DIGITAL_SHELF_TAGGING') {
      this.headerString = 'Subcategory';
    }
    if (!this.filterData) {
      this.$store.dispatch('getFilterData', {
        body: this.body,
        api: this.api,
        method: this.method,
        filterDataKey: this.filterDataKey,
        requestConfig: this.requestConfig
      });
    }
    const selections = {};
    this.$store.dispatch('setInitialSelections', selections || {});
    eventBus.$on('digitalShelfSelection', (selections) => {
      this.$emit(
        'selection',
        Object.keys(selections).filter((selection) => selections[selection])
      );
    });
  },
  methods: {
    selectionsChanged(selections) {
      this.$store.dispatch('setSelections', selections);
    }
  }
};
</script>
<style lang="css" scoped>
.filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
  color: #fff;
  width: 100%;
  font-size: 1.4rem;
}

.filter--header--content {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
</style>
