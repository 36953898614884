<template>
  <div>
    <section
      v-if="formConfig.customText"
      class="u-display-flex u-flex-align-items-center radio-holder"
    >
      <div
        class="u-display-flex u-flex-align-items-baseline left-panel u-font-size-5 u-color-grey-light u-width-320px u-spacing-pv-m"
      >
        Custom
      </div>
      <div
        class="u-spacing-pl-xxl u-display-flex u-width-100 u-flex-align-items-baseline u-flex-justify-content-center u-flex-direction-column"
      >
        <InputComponent
          v-model="localFormData.priceValue"
          :min="formConfig.validationObj.absolute.min"
          :max="formConfig.validationObj.absolute.max"
          :error-message="priceValueErrorMessage"
          @input="handleCustomTextValueChange"
        />
      </div>
    </section>
    <section
      v-if="formConfig.showMatchTypeSelection"
      class="match-type-selection u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-pv-m"
    >
      <div
        class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
      >
        Match Type
      </div>
      <div class="u-width-auto">
        <rb-select
          class="u-spacing-mr-s valueDropdown u-width-100px"
          :width="'100px'"
          :send-details="true"
          :on-close="matchTypeSelected"
          :options="formConfig.matchTypeOptions"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          >
            <div
              class="u-flex-0 u-flex-shrink-past-min-size u-display-flex u-flex-direction-column u-spacing-p-s"
            >
              <span class="u-font-size-5 u-text-case-title">{{
                localFormData.selectedMatchType.title
              }}</span>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-text-case-title"
            >
              {{ option.title }}
            </div>
          </template>
        </rb-select>
      </div>
    </section>

    <section
      class="content u-display-flex u-flex-direction-column u-spacing-pr-l"
    >
      <div v-if="formConfig.showPriceSourceSelection">
        <div
          class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
        >
          {{ formConfig.pageKey }} {{ formConfig.priceType }}
        </div>
        <div
          class="u-color-grey-light u-display-flex u-flex-direction-row u-spacing-pt-s u-spacing-pb-m"
          @change="priceSourceChanged"
        >
          <div
            class="u-spacing-mb-s"
            style="width: 200px"
          >
            <rb-radio
              v-model="localFormData.priceSource"
              native-value="0"
            >
              Respective {{ formConfig.pageKey }} {{ formConfig.priceType }}s
            </rb-radio>
          </div>
          <div class="u-spacing-mb-s u-width-160px">
            <rb-radio
              v-model="localFormData.priceSource"
              native-value="1"
            >
              Custom {{ formConfig.priceType }}s
            </rb-radio>
          </div>
        </div>
      </div>
      <div
        v-if="localFormData.priceSource === '1'"
        class="radio-holder u-display-flex u-spacing-pb-l"
      >
        <div
          class="left-panel u-font-size-5 u-color-grey-light u-width-320px u-spacing-pv-m"
          @change="priceSetterChanged"
        >
          <div class="u-spacing-mb-m">
            <rb-radio
              v-model="localFormData.priceSetterType"
              native-value="0"
            >
              Set new {{ formConfig.priceType }}
            </rb-radio>
          </div>
          <div class="u-spacing-mb-m">
            <rb-radio
              v-model="localFormData.priceSetterType"
              native-value="1"
            >
              Increase {{ formConfig.priceType }}
            </rb-radio>
          </div>
          <div class="u-spacing-mb-xs">
            <rb-radio
              v-model="localFormData.priceSetterType"
              native-value="2"
            >
              Decrease {{ formConfig.priceType }}
            </rb-radio>
          </div>
        </div>
        <div
          class="u-spacing-pl-xxl u-display-flex u-width-100 u-flex-align-items-baseline u-flex-justify-content-center u-flex-direction-column"
        >
          <div class="u-display-flex">
            <rb-select
              v-if="localFormData.priceSetterType !== '0'"
              class="u-spacing-mr-s valueDropdown"
              :send-details="true"
              :on-close="priceChangeTypeSelected"
              :options="formConfig.priceChangeTypeOptions"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-flex-direction-column u-spacing-p-s"
                >
                  <span class="u-font-size-5">{{
                    localFormData.selectedPriceChangeType &&
                    localFormData.selectedPriceChangeType.title
                  }}</span>
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.title }}
                </div>
              </template>
            </rb-select>
            <slot name="dynamic">
              <div
                class="u-display-flex u-flex-0 u-flex-align-items-center u-width-120px"
                :class="{
                  percentBox:
                    localFormData.selectedPriceChangeType &&
                    localFormData.selectedPriceChangeType.value === 'Percent' &&
                    localFormData.priceSetterType !== '0'
                }"
              >
                <span
                  v-if="
                    localFormData.priceSetterType === '0' ||
                    (localFormData.selectedPriceChangeType &&
                      localFormData.selectedPriceChangeType.value !== 'Percent')
                  "
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                >
                  {{ $currency }}
                </span>
                <input
                  ref="new_bid"
                  v-model="localFormData.priceValue"
                  type="number"
                  class="rb-input currency-input u-display-flex u-flex-align-items-center"
                  :min="formConfig.validationObj.absolute.min"
                  step="0.01"
                  :max="formConfig.validationObj.absolute.max"
                  autofocus
                  @input="priceValueChanged"
                />
                <span
                  v-if="
                    localFormData.priceSetterType !== '0' &&
                    localFormData.selectedPriceChangeType &&
                    localFormData.selectedPriceChangeType.value === 'Percent'
                  "
                  class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
                >
                  %
                </span>
              </div>
            </slot>
          </div>
          <div
            v-if="localFormData.priceError"
            class="u-font-size-7 u-color-red-base u-spacing-mt-s"
          >
            {{ priceValueErrorMessage }}
          </div>
          <div
            v-if="
              formConfig.showFloorCeilingValue &&
              localFormData.priceSetterType !== '0'
            "
            class="u-display-flex u-spacing-pt-m u-flex-direction-column"
          >
            <div
              class="u-display-flex u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-pb-xs"
            >
              {{ localFormData.priceSetterType == 1 ? 'Ceiling ' : 'Floor '
              }}{{ formConfig.priceType }}
            </div>
            <div
              class="u-display-flex u-flex-0 u-flex-align-items-center u-width-120px"
              :class="{ percentBox: localFormData.priceSetterType !== '0' }"
            >
              <span
                v-if="
                  localFormData.priceSetterType !== '0' ||
                  (localFormData.selectedPriceChangeType &&
                    localFormData.selectedPriceChangeType.value === 'percent')
                "
                class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
              >
                {{ $currency }}
              </span>
              <input
                v-model="localFormData.floorCeilingValue"
                type="number"
                class="rb-input currency-input-right u-display-flex u-flex-align-items-center"
                :min="formConfig.validationObj.absolute.min"
                step="0.01"
                :max="formConfig.validationObj.absolute.max"
                autofocus
                @input="floorCeilingValueChanged"
              />
            </div>
            <div
              v-if="localFormData.floorCeilingError"
              class="u-font-size-7 u-color-red-base u-spacing-mt-s"
            >
              {{ currencyInputErrorMessage }}
            </div>
          </div>
          <div
            v-if="generalWarningMessage"
            class="u-font-size-7 u-color-orange-base u-spacing-mt-s"
          >
            {{ generalWarningMessage }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InputComponent from '@/components/pages/strategy_builder/steps/input-component.vue';
export default {
  name: 'GenericActionPanel',
  components: {
    InputComponent
  },
  props: {
    formConfig: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    generalWarningMessage: {
      type: String,
      default: ''
    }
    // jsonKeyMap: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  data() {
    return {
      // localFormData: {}
    };
  },
  computed: {
    currencyInputErrorMessage() {
      const floatValue = parseFloat(this.localFormData.floorCeilingValue);
      return this.getErrorMessage(floatValue);
    },
    priceValueErrorMessage() {
      const floatValue = parseFloat(this.localFormData.priceValue);
      return this.getErrorMessage(floatValue);
    },
    localFormData() {
      return this.formData;
    }
  },
  created() {
    // this.localFormData = JSON.parse(JSON.stringify(this.formData));
    this.priceValueChanged();
    this.floorCeilingValueChanged();
  },
  methods: {
    getErrorMessage(floatValue) {
      if (this.hasMinimumValueError(floatValue)) {
        return (
          'Input cannot be less than ' +
          this.formConfig.validationObj.absolute.min
        );
      }
      if (this.hasMaximumValueError(floatValue)) {
        return (
          'Input cannot be greater than ' +
          this.formConfig.validationObj.absolute.max
        );
      }
    },
    hasMinimumValueError(compareValue) {
      if (
        this.formConfig?.validationObj?.absolute?.min !== null &&
        compareValue !== null &&
        compareValue < this.formConfig.validationObj.absolute.min
      ) {
        return true;
      }
      return false;
    },
    hasMaximumValueError(compareValue) {
      if (
        this.formConfig?.validationObj?.absolute?.max !== null &&
        compareValue !== null &&
        compareValue > this.formConfig.validationObj.absolute.max
      ) {
        return true;
      }
      return false;
    },
    emitUpdatedFormData(updatedKeys) {
      this.$emit('form-data-updated', this.localFormData, updatedKeys);
    },
    matchTypeSelected(context, selectedOption) {
      if (!selectedOption.length) {
        return;
      }
      this.localFormData.selectedMatchType = selectedOption[0];
      this.emitUpdatedFormData(['selectedMatchType']);
    },
    priceChangeTypeSelected(context, selectedOption) {
      if (!selectedOption.length) {
        return;
      }
      this.localFormData.selectedPriceChangeType = selectedOption[0];
      this.localFormData.priceValue = '';
      this.emitUpdatedFormData(['selectedPriceChangeType']);
    },
    priceSourceChanged() {
      this.emitUpdatedFormData(['priceSource']);
    },
    priceSetterChanged() {
      this.localFormData.priceValue = '';
      this.localFormData.floorCeilingValue = '';
      this.$nextTick(() => {
        this.$refs.new_bid.focus();
      });
      this.emitUpdatedFormData(['priceSetterType']);
    },
    priceValueChanged() {
      const floatValue = parseFloat(this.localFormData.priceValue);
      if (
        this.hasMinimumValueError(floatValue) ||
        this.hasMaximumValueError(floatValue)
      ) {
        this.localFormData.priceError = true;
      } else {
        this.localFormData.priceError = false;
      }

      // The below condition means that if the price being set is
      // increase by or decrease by. In that scenario we should not be
      // checking for min/maxValue Error
      if (
        this.localFormData.priceSetterType === '1' ||
        this.localFormData.priceSetterType === '2'
      ) {
        this.localFormData.priceError = false;
      }
      this.emitUpdatedFormData(['priceValue']);
    },
    handleCustomTextValueChange() {
      const floatValue = parseFloat(this.localFormData.priceValue);
      if (
        this.hasMinimumValueError(floatValue) ||
        this.hasMaximumValueError(floatValue)
      ) {
        this.localFormData.customInputError = true;
      } else {
        this.localFormData.customInputError = false;
      }
      this.emitUpdatedFormData(['priceValue']);
    },
    floorCeilingValueChanged() {
      const floatValue = parseFloat(this.localFormData.floorCeilingValue);
      if (
        this.hasMinimumValueError(floatValue) ||
        this.hasMaximumValueError(floatValue)
      ) {
        this.localFormData.floorCeilingError = true;
      } else {
        this.localFormData.floorCeilingError = false;
      }
      this.emitUpdatedFormData(['floorCeilingValue']);
    }
  }
};
</script>

<style lang="css" scoped>
.radio-holder .left-panel {
  border-right: 1px solid #e9eaeb;
}
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input-right {
  border-left: 0;
  padding-right: 4px;
  height: 32px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}
.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input-right {
  border-left: 0;
  padding-left: 5px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
</style>
