import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  getActiveColumns,
  rem1V2ConfigEnabled,
  getOrderByList,
  CONSTANTS
} from '@/components/pages/insights/amazon/cva/utils';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  async fetchData(pageSettings) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    const metricsList = rem1V2ConfigEnabled()
      ? [
          'cva_ad_ineff_Score_percentage_v2',
          'cva_ad_ineff_organic_sov_percentage_v2',
          'cva_ad_ineff_paid_sov_percentage_v2'
        ]
      : [
          'cva_ad_ineff_Score_percentage',
          'cva_ad_ineff_organic_sov_percentage',
          'cva_ad_ineff_paid_sov_percentage'
        ];
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_ad_ineff_search_term',
        entityType: 'search_term',
        metricsList,
        dimensionsList: [
          'search_term',
          'organic_sov',
          'client_id',
          'paid_sov',
          'score',
          'search_frequency_rank'
        ],
        eventsList: [],
        enablePaginationCount: true,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: getOrderByList('search_frequency_rank', 'ASC'),
          limit: 20,
          page: 1,
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'RANk',
              dimensionValue: 20,
              operator: 'LESS_THAN_OR_EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Ad_ineff_ST',
              operator: 'EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'Search Term',
        field: 'search_term',
        headerComponentParams: {
          keyType: 'string',
          toolTipText: 'Search term used by customers in the search bar'
        }
      },
      {
        headerName: 'Keyword Rank',
        field: 'search_frequency_rank',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Latest retailer search frequency rank for the keyword'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Incremental Fraction',
        field: 'Score_percentage',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'Estimated share of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta. Higher the incrementality, more valuable the ad investment!'
        },
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          keyType: 'percent'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS.adIncrementality.IncrementalFraction
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Organic Share of Voice',
        field: 'organic_sov_percentage',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Your organic share of voice on the search term'
        },
        cellRendererParams: {
          keyType: 'percent',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Paid Share of Voice',
        field: 'paid_sov_percentage',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Your sponsored share of voice on the search term'
        },
        cellRendererParams: {
          keyType: 'percent',
          cellAlignment: 'u-text-align-right'
        }
      }
    ];
    columns = getActiveColumns(columns, 'ad_incrementality');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
