<template>
  <section
    :class="{ 'u-height-400': isLoading }"
    class="u-spacing-m-m"
  >
    <card-widget
      v-show="!isLoading"
      class="u-flex-1 u-overflow-auto"
      :config="tableConfig"
      :data="data || {}"
    >
      <div
        slot="header"
        class="u-display-flex u-flex-1"
      >
        <div
          class="u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-space-between"
        >
          <div class="u-font-size-3 u-font-weight-600 u-color-grey-lighter">
            {{ tableConfig.title }}
          </div>
          <div
            v-for="(actionIcon, index) in actionIconsList"
            :key="index"
            class="u-color-grey-lighter u-cursor-pointer hover-class u-spacing-pl-s u-display-flex u-flex-justify-content-center u-flex-align-items-center"
          >
            <div
              v-if="!actionIcon.loading"
              v-tippy="{ placement: 'top', arrow: false }"
              :title="actionIcon.tippyTitle"
              class="u-display-flex u-flex-align-items-center u-flex-direction-column"
              @click.stop="handleDownload(index)"
            >
              <rb-icon
                :class="[
                  actionIcon.error ? 'u-color-red-base' : 'u-color-grey-lighter'
                ]"
                :icon="actionIcon.icon"
              />
              <div
                v-if="actionIcon.subText"
                data-cy="actionTitle"
                class="u-font-size-8 u-spacing-mt-xs"
              >
                {{ actionIcon.subText }}
              </div>
            </div>
            <span v-else>
              <loader
                size="20px"
                class="u-bg-color-grey-white"
                :loading="true"
                :color="'#007cf6'"
              />
            </span>
          </div>
        </div>
      </div>
      <div slot="body">
        <rb-insights-table
          v-if="!dataService.metadata.load && !dataService.metadata.error"
          ref="table-widget-ref"
          :config="tableConfig"
          :grid-options="tableGridOptions"
          :table-row="(data || {}).rows"
          :table-column="getColumnDefs"
          style="width: 100%"
          :row-height="50"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="true"
          :pagination="true"
          :pagination-total-key="
            (((data || {}).rows || [])[0] || {}).totalEntityCount
          "
          :pagination-change-event="paginationChangeEvent"
          :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
          :download-this-table="false"
          :show-pagination-options="true"
          :row-per-page-list="tableConfig.rowPerPageList"
          :sorting-change-event="sortingChangeEvent"
        />
      </div>
      <div
        slot="footer"
        class="u-width-100 u-display-flex u-flex-align-items-center"
      >
        <div class="no-wrap u-color-grey-x-light u-font-size-7">
          Last Updated: {{ lastUpdateDate }}
        </div>
      </div>
    </card-widget>
    <section
      v-if="isLoading"
      class="u-display-flex u-flex-direction-column u-height-400"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
  </section>
</template>
<script>
import cardWidget from '@/components/widgets/cardWidget';
import {
  metricCellDisplay,
  textCellDisplay,
  textLinkCellDisplay
} from '@/components/pages/insights/amazon/market-share/molecules/customCell.js';
import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import { formatter } from '@/utils/helpers/formatter.js';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import {
  saveSortingState,
  constants,
  modifyPayloadFor3p,
  removeGlobalSettingsForMS
} from '@/components/pages/insights/amazon/market-share/utils.ts';

export default {
  name: 'TableWidget',
  owner: 'Rajan V',
  components: {
    cardWidget,
    loader
  },
  props: {
    minMaxDate: {
      type: Object,
      default: undefined
    },
    v2: {
      type: Boolean,
      default: true
    },
    globalSettings: {
      type: [Array, Object],
      default: undefined
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    actionIcons: {
      type: Array,
      default: () => [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with week level Market Share for the selected Manufacturer and time period.'
        }
      ]
    },
    shareSummaryWidget: {
      type: Function,
      default: function (widgetName) {
        return (
          widgetName === 'categoryShareSummary_executive' ||
          widgetName ===
            'categoryShareSummary_executive' + (this.v2 ? '_v2' : '')
        );
      }
    },
    categoryPageWidget: {
      type: Function,
      default: function (widgetName) {
        return (
          widgetName === 'categoryShareSummary_category' ||
          widgetName ===
            'categoryShareSummary_category' + (this.v2 ? '_v2' : '')
        );
      }
    },
    enableTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortData: {
        data: {}
      },
      actionIconsList: this.actionIcons,
      sortingChangeEvent: 'marketshare-table-widget-sorting',
      data: {},
      metadata: {},
      paginationChangeEvent: 'paginationTableWidget',
      tableConfig: {
        title: '52 WEEK CATEGORY SHARE SUMMARY',
        tooltip: '',
        meta: {},
        header: {
          search: false,
          show: true,
          actions: {
            download: {}
          }
        },
        footer: {
          show: true
        },
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        },
        rowPerPageList: [
          { title: 10 },
          { title: 20 },
          { title: 30 },
          { title: 50 }
        ]
      },
      downloadApi: {
        error: false,
        loading: false
      },
      tableGridOptions: {
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    lastUpdateDate() {
      return this.formatter(this.minMaxDate?.rawMaxDate, 'date');
    },
    viewBy() {
      return this.globalSettings?.settingValue?.viewBy?.entityType;
    },
    getColumnDefs() {
      const savedSortState = this.dataService?.savedSortState;
      const columns = this.dataService?.generateDateRange?.() || [];
      let selectedDateRange = true;
      if (this.compareToDate(this.globalSettings.where, columns[0])) {
        selectedDateRange = false;
      }
      const title = {};
      columns.forEach((column) => {
        title[column.key] = this.generateTitleBasedOnDate(
          column.date,
          column.pvpDate
        );
        title[column.key + 'Tooltip'] = this.generateTooltipBasedOnDate(
          column.date,
          column.pvpDate
        );
      });
      title.selectedDateRange = this.generateTitleBasedOnDate(
        this.globalSettings.where?.date,
        this.globalSettings.where?.pvpDate
      );
      const tooltip = this.generateTooltipBasedOnDate(
        this.globalSettings.where?.date,
        this.globalSettings.where?.pvpDate
      );
      // \n ${title.selectedDateRange}
      const widgetName = this.metadata.name;
      const viewByManufacturer = this.viewBy === 'manufacturer';
      let columnsObj = [
        {
          headerName: "<span class='u-font-size-5'>Rank</span>",
          headerComponentFramework: 'genericTableHeader',
          title: 'Rank',
          keyOrder: 1,
          key: 'rank',
          field: 'rank',
          showToolTip: true,
          toolTipText: '',
          type: 'string',
          formatReq: false,
          cellClass: ['u-display-flex', 'u-flex-justify-content-flex-end'],
          headerComponentParams: {
            enableSorting: false,
            keyType: 'number'
          },
          minWidth: 75,
          pinned: 'left'
        },
        {
          field: 'brands',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5 u-text-case-title">${this.viewBy}</span>`,
          title: this.viewBy,
          headerComponentParams: {
            enableSorting: false,
            customStyles: this.shareSummaryWidget(widgetName)
              ? 'padding-left: 8px !important;'
              : ''
          },
          type: 'string',
          keyOrder: 3,
          cellRendererFramework: textCellDisplay,
          cellRendererParams: {
            renderParams: {
              text1: viewByManufacturer ? 'manufacturer' : 'brand',
              text2: 'manufacturer',
              showBrandsTooltip:
                viewByManufacturer && this.categoryPageWidget(widgetName),
              // showIcon: "showIcon",
              showIcon: false,
              showText2: true,
              enableTooltip: this.enableTooltip
            }
          },
          suppressSizeToFit: false,
          minWidth: 220,
          pinned: 'left'
        },
        {
          field: 'selectedDateRange',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Selected Date Range \n${title.selectedDateRange}</span`,
          title: 'Selected Date Range',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            toolTipText: tooltip,
            tooltipCustomTitle: 'Selected Date Range'
          },
          sort: 'desc',
          sortingOrder: ['desc', 'asc'],
          keyOrder: 4,
          suppressSizeToFit: false,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'selectedDateRange_data1',
              data1_type: 'selectedDateRange_data1_type',
              data2: 'selectedDateRange_data2',
              data2_type: 'selectedDateRange_data2_type',
              invertTag: 'selectedDateRange_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        },
        {
          field: 'lastWeek',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Last Week \n${title.lastWeek}</span`,
          title: 'Last Week',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            tooltipCustomTitle: 'Last Week',
            toolTipText: title.lastWeekTooltip
          },
          sortingOrder: ['desc', 'asc'],
          keyOrder: 5,
          suppressSizeToFit: false,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'lastWeek_data1',
              data1_type: 'lastWeek_data1_type',
              data2: 'lastWeek_data2',
              data2_type: 'lastWeek_data2_type',
              invertTag: 'lastWeek_data2_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        },
        {
          field: 'last4Week',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Last 4 Weeks \n${title.last4Week}</span>`,
          title: 'Last 4 Weeks',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            tooltipCustomTitle: 'Last 4 Weeks',
            toolTipText: title.last4WeekTooltip
          },
          sortingOrder: ['desc', 'asc'],
          keyOrder: 6,
          suppressSizeToFit: false,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'last4Week_data1',
              data1_type: 'last4Week_data1_type',
              data2: 'last4Week_data2',
              data2_type: 'last4Week_data2_type',
              invertTag: 'last4Week_data2_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        },
        {
          field: 'last13Week',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Last 13 Weeks \n ${title.last13Week}</span>`,
          title: 'Last 13 Weeks',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            tooltipCustomTitle: 'Last 13 Weeks',
            toolTipText: title.last13WeekTooltip
          },
          sortingOrder: ['desc', 'asc'],
          keyOrder: 7,
          suppressSizeToFit: true,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'last13Week_data1',
              data1_type: 'last13Week_data1_type',
              data2: 'last13Week_data2',
              data2_type: 'last13Week_data2_type',
              invertTag: 'last13Week_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        },
        {
          field: 'last26Week',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Last 26 Weeks ${title.last26Week}</span>`,
          title: 'Last 26 Weeks',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            tooltipCustomTitle: 'Last 26 Weeks',
            toolTipText: title.last26WeekTooltip
          },
          sortingOrder: ['desc', 'asc'],
          keyOrder: 8,
          suppressSizeToFit: false,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'last26Week_data1',
              data1_type: 'last26Week_data1_type',
              data2: 'last26Week_data2',
              data2_type: 'last26Week_data2_type',
              invertTag: 'last26Week_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        },
        {
          field: 'last52Week',
          headerComponentFramework: 'genericTableHeader',
          headerName: `<span class="u-font-size-5">Last 52 Weeks ${title.last52Week}</span>`,
          title: 'Last 52 Weeks',
          headerComponentParams: {
            enableSorting: true,
            keyType: 'number',
            tooltipCustomTitle: 'Last 52 Weeks',
            toolTipText: title.last52WeekTooltip
          },
          sortingOrder: ['desc', 'asc'],
          keyOrder: 9,
          suppressSizeToFit: true,
          cellRendererFramework: metricCellDisplay,
          cellRendererParams: {
            renderParams: {
              data1: 'last52Week_data1',
              data1_type: 'last52Week_data1_type',
              data2: 'last52Week_data2',
              data2_type: 'last52Week_data2_type',
              invertTag: 'last52Week_invertTag',
              comparisonTooltip:
                '"Change value" is unavailable for this time period'
            }
          },
          minWidth: 200
        }
      ];
      if (this.shareSummaryWidget(widgetName)) {
        columnsObj.forEach((item) => {
          if (item.field === 'brands') {
            item.cellRendererFramework = textLinkCellDisplay;
            item.cellRendererParams = {
              renderParams: {
                text1: 'category',
                text2: 'manufacturer',
                showIcon: 'showIcon',
                showText2: false
              }
            };
          }
        });
      }
      if (!selectedDateRange) {
        columnsObj = columnsObj.filter(
          (col) => col.field !== 'selectedDateRange'
        );
      }
      // Using the saved sort state to compute the column definition
      columnsObj = columnsObj.map((col) => {
        if (savedSortState?.colId) {
          if (col.field === savedSortState.colId) {
            col.sort = savedSortState.sort.toLowerCase();
          } else {
            col.sort = undefined;
          }
        }
        return col;
      });
      return [...columnsObj];
    },
    isLoading() {
      return this.dataService?.isLoading;
    }
  },
  watch: {
    globalSettings: {
      handler(newVal, oldVal) {
        if (this.metadata?.metrics) {
          this.resetPagination();
          this.getData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.init();
    eventBus.$on(this.paginationChangeEvent, (data) => {
      this.tableConfig.body.APIConfig.page = data;
      this.$nextTick(() => {
        this.getData();
      });
    });
    eventBus.$on(this.sortingChangeEvent, (column, config) => {
      this.getSortData(column);
    });
  },
  destroyed() {
    eventBus.$off(this.paginationChangeEvent);
    eventBus.$off(this.sortChangeEvent);
  },
  methods: {
    formatter: formatter,
    generatePayload(metricsListDetails) {
      const apiPayload = cloneDeep(metricsListDetails.api.request);
      apiPayload.entityType =
        this.globalSettings.settingValue?.viewBy?.entityType;
      apiPayload.dimensionsList = (apiPayload?.dimensionsList || []).filter(
        (item) => item !== apiPayload.entityType
      );
      apiPayload.metricsList = [metricsListDetails.name];
      apiPayload.operations.orderByList[0].dimension = metricsListDetails.name;
      apiPayload.operations.page = this.tableConfig.body.APIConfig.page;
      apiPayload.operations.limit = this.tableConfig.body.APIConfig.limit;
      apiPayload.where = cloneDeep(this.globalSettings.where);
      const displayShareIn =
        this.globalSettings?.settingValue?.displayShareIn || {};
      if (this.shareSummaryWidget(this.metadata?.name)) {
        apiPayload.dimensionsList = cloneDeep(
          metricsListDetails.api.request?.dimensionsList || []
        );
        apiPayload.entityType = cloneDeep(
          metricsListDetails.api.request.entityType
        );
        apiPayload.where = cloneDeep(this.globalSettings.where);
        apiPayload.operations.separateWhereClause = {};
        apiPayload.operations.separateWhereClause.dimensionNameValueList =
          apiPayload.where.dimensionNameValueList.filter(
            (item) => item.dimensionName === 'category_hierarchy'
          );
        const threepSelection =
          this.globalSettings?.settingValue?.include3p?.value;
        if (threepSelection === 'exclude3p' && !this.v2) {
          apiPayload.where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: false
          });
        }
        if (displayShareIn.shareType === 'Sales') {
          apiPayload.where.dimensionNameValueList.push({
            dimensionName: 'client_flag',
            dimensionValue: 'client'
          });
        }
      }
      if (this.viewBy === 'manufacturer') {
        if (this.metadata?.name === 'categoryShareSummary_category') {
          apiPayload.metricsList.push('brands_list');
        } else if (
          this.metadata?.name === 'categoryShareSummary_category_v2' ||
          this.metadata?.name === 'ms_pro_2.0_categoryShareSummary_category_v2'
        ) {
          apiPayload.metricsList.push('brands_list_v2');
        }
      }
      return apiPayload;
    },
    async init() {
      this.metadata = await this.dataService?.getMetadata();
      await this.getData();
    },
    getSortData(data) {
      this.sortData.data.colId = data.colDef.field;
      this.sortData.data.sort = data.sort;
      saveSortingState(this.dataService?.widget, this.sortData.data);
      this.getData();
    },
    async getData() {
      console.log(this.globalSettings, this.metadata);
      const metricsListDetails = this.getMetricDetails();
      if (metricsListDetails === undefined) {
        removeGlobalSettingsForMS();
        return;
      }
      if (metricsListDetails.api) {
        let payLoad = this.generatePayload(metricsListDetails);
        const apiDetails = cloneDeep(metricsListDetails.api);
        const savedSortState = this.dataService?.savedSortState;
        const sortData = this.sortData?.data?.colId
          ? this.sortData.data
          : savedSortState;
        if (sortData?.colId) {
          payLoad = cloneDeep(payLoad);
          let dateRange = null;
          if (sortData.colId !== 'selectedDateRange') {
            dateRange = this.dataService.generateDateRange(sortData.colId);
          } else {
            dateRange = cloneDeep(this.globalSettings.where);
          }
          payLoad.where.date.from = dateRange.date.from;
          payLoad.where.date.to = dateRange.date.to;
          payLoad.where.pvpDate = dateRange.pvpDate;
          payLoad.where.date.name = sortData.colId;
          payLoad.operations.orderByList[0].direction =
            sortData.sort.toUpperCase();
        }
        const metaData = {
          load: false,
          error: false,
          rows: this.metadata
        };
        this.data = await this.dataService?.getData(
          payLoad,
          apiDetails,
          this.globalSettings,
          this.shareSummaryWidget,
          metaData
        );
        setTimeout(() => {
          this.tableGridOptions?.api?.sizeColumnsToFit();
        }, 1);
      }
    },
    getMetricDetails() {
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      const displayShareIn = this.globalSettings.settingValue?.displayShareIn;
      let metricsList = displayShareIn?.metricsList;
      if (this.shareSummaryWidget(this.metadata?.name)) {
        const marketShareMetricMap =
          displayShareIn.shareType === 'Marketshare'
            ? this.metadata?.metadata?.marketShareMetricMap || {}
            : this.metadata?.metadata?.salesMetricMap || {};
        metricsList =
          marketShareMetricMap[displayShareIn?.label]?.[threepSelection] ||
          metricsList;
      }
      return this.metadata.metrics[metricsList];
    },
    resetPagination() {
      this.tableConfig.body.APIConfig.page = 1;
    },
    generateDateRangeStrShort(startDate, endDate) {
      startDate = moment(startDate, 'YYYY-MM-DD');
      endDate = moment(endDate, 'YYYY-MM-DD');
      if (startDate.year() === endDate.year()) {
        return (
          startDate.format('MMM D') + ' - ' + endDate.format('MMM D, YYYY')
        );
      }
      return (
        startDate.format('MMM D, YYYY') + ' - ' + endDate.format('MMM D, YYYY')
      );
    },
    generateDateRangeStrLong(startDate, endDate) {
      startDate = moment(startDate, 'YYYY-MM-DD');
      endDate = moment(endDate, 'YYYY-MM-DD');
      return startDate.format('ll') + ' - ' + endDate.format('ll');
    },
    generateTitleBasedOnDate(date) {
      if (date === undefined) {
        return '';
      }
      const dateString = this.generateDateRangeStrShort(date.from, date.to);
      return `<br/> <span class="u-font-size-7">${dateString}<span/>`;
    },
    generateTooltipBasedOnDate(date, pvp) {
      const dateString = this.generateDateRangeStrLong(date.from, date.to);
      const pvpDateString = this.generateDateRangeStrLong(pvp.from, pvp.to);
      return `<span class="u-font-size-5">${dateString}<span/> </br> <span class="u-color-grey-lighter u-font-size-6">vs ${pvpDateString}</span>`;
    },
    compareToDate(objA, objB) {
      return !!(
        objA?.date?.from === objB?.date?.from &&
        objA?.date?.to === objB?.date?.to &&
        objA?.pvpDate?.from === objB?.pvpDate?.from &&
        objA?.pvpDate?.to === objB?.pvpDate?.to
      );
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      // this.actionIcons = actionIconsCopy;
      this.actionIconsList = Object.assign({}, actionIconsCopy);
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const { endPoint, request, service } =
            this.metadata.downloadApiTemplate;
          const metricsListDetails = this.getMetricDetails();
          if (metricsListDetails.api) {
            const payLoad = this.generatePayload(metricsListDetails);
            if (payLoad.metricsList.includes('brands_list_v2')) {
              payLoad.metricsList = payLoad.metricsList.filter(
                (item) => item !== 'brands_list_v2'
              );
              payLoad.metricsList.unshift('brands_list_v2');
            }
            const limit = request?.operations?.limit;
            if (limit) {
              payLoad.operations.limit = limit;
            } else {
              delete payLoad.operations.limit;
            }
            delete payLoad.operations.page;
            const keyPresent =
              payLoad.where?.dimensionNameValueList?.find(
                (item) => item.dimensionName === constants.THREE_P_GROUP_FLAG
              ) || [];
            if (keyPresent?.length > 0) {
              payLoad.dimensionsList.push(keyPresent[0].item.dimensionName);
            }
            payLoad.operations.max_date =
              this.minMaxDate.rawMaxDate.format('YYYY-MM-DD');
            payLoad.operations.min_date =
              this.minMaxDate.rawMinDate.format('YYYY-MM-DD');
            modifyPayloadFor3p(payLoad);
            const { data } = await HttpService.post(service, payLoad, {
              append: endPoint
            });
            downloadFile(data.url);
          }
          resolve();
        } catch (e) {
          reject(e);
          console.error('error');
        }
      });
    }
  }
};
</script>

<style lang="css" scoped>
.u-height-400 {
  height: 400px;
}
.divider {
  width: 1px;
}

.icon-download {
  color: #8b8f93;
}
.icon-download:hover {
  color: #000;
}
</style>
