<template>
  <div
    v-if="getMinMaxRunDate.load"
    class="u-display-flex u-flex-direction-column"
  >
    <loader
      class="fill--parent"
      :loading="true"
      :color="'#3684bb'"
    />
  </div>
  <div v-else>
    <div
      class="u-display-flex u-flex-direction-column u-overflow-auto detail-panel"
    >
      <div class="u-spacing-ph-m">
        <div
          class="u-width-100 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
        >
          <rb-select
            class="u-spacing-mt-m u-max-width-100"
            :send-details="true"
            :on-close="handleActiveReportChange"
            :options="activeReportTypeOptions"
            :is-multi-select="true"
            :width="'240px'"
          >
            <div
              slot="trigger"
              class="u-display-flex u-spacing-p-s u-cursor-pointer u-flex-justify-content-space-around"
            >
              <div class="u-flex-1 u-font-size-1 u-font-weight-600">
                {{ getActiveReportTitle }}
                <rb-icon
                  class="u-flex-1 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
            </div>
          </rb-select>
          <div>
            <div
              v-tippy="{
                placement: 'bottom',
                distance: -15,
                maxWidth: '500px',
                delay: [500, 0]
              }"
              class="u-display-flex u-flex-align-items-center u-color-grey-lighter u-cursor-default"
              title="This dashboard is WIP. We are verifying all the test cases and also adding new ones at the same time."
            >
              <rb-icon
                :icon="'info-circle-fill'"
                class="rb-icon--large u-cursor-pointer"
              />
              <span class="u-font-size-2 u-font-weight-bold u-spacing-ml-s">
                Work In Progress
              </span>
            </div>
          </div>
        </div>
        <card-widget
          class="u-flex-1 u-spacing-mv-m"
          :config="config.widgets['widget3']"
          :data="getProductDiagnosticsGraphData"
        >
          <div
            v-if="showTable"
            slot="header"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-flex-1"
          >
            <span
              class="u-font-size-3 card__title u-color-grey-lighter u-font-weight-600"
              >{{ activeSourceCard }} Health</span
            >
          </div>
          <div slot="right-header">
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pb-s"
            >
              <rb-icon
                :icon="'calendar'"
                class="rb-icon--mv-custom u-spacing-mr-xs u-color-grey-lighter"
              />
              <div class="u-font-size-6 u-color-grey-lighter">Last 7 Days</div>
            </div>
          </div>
          <div
            slot="body"
            class="page-health-container u-spacing-ph-m"
          >
            <chart-with-legends
              :metrics-list="metricsList"
              :metric-data="metricData"
              :custom-chart-class="['u-spacing-mt-s']"
              :color-object="unitColors"
              :chart-config="config.widgets['widget3'].body.chart"
              :chart-data="chartData"
              :default-legends="false"
              :metrics-shown="metricsShown"
              :default-select-metric="defaultSelectedMetric"
              :metric-config="tagConfig"
              :disable-add-new-metric="true"
              :minimum-metric="metricsShown.length"
              :remove-dropdown="true"
              :selected-metric-limit="metricsShown.length"
            >
              <div slot="post-legends">
                <div class="u-spacing-pl-s u-display-flex u-flex-direction-row">
                  <div>
                    <div
                      class="u-spacing-pb-l u-font-size-4 u-color-grey-x-light u-font-weight-600"
                    >
                      Considering the date range
                    </div>
                    <div
                      class="u-display-flex u-flex-direction-row u-spacing-pr-m"
                    >
                      <legendsCard
                        v-for="(item, index) in getHistoricalCumulativeMetrics"
                        :key="index"
                        :title="item.title"
                        :percentage="item.percentage"
                        :number="item.number"
                        :theme="'light'"
                        :color="item.color"
                        class="u-border-radius-xs u-spacing-mr-m"
                      />
                    </div>
                  </div>
                  <div
                    v-if="getCumulativeMetrics.length"
                    class="u-spacing-pl-m u-border-width-s u-border-left u-border-color-grey-xxx-light"
                  >
                    <div
                      class="u-spacing-pl-m u-spacing-pb-l u-font-size-4 u-color-grey-x-light u-font-weight-600"
                    >
                      Selected Date: {{ getActiveDate }}
                    </div>
                    <div class="u-display-flex u-flex-direction-row">
                      <legendsCard
                        v-for="(item, index) in getCumulativeMetrics"
                        :key="index"
                        :class="[item.color]"
                        :title="item.title"
                        :percentage="item.percentage"
                        :number="item.number"
                        :theme="'colored'"
                        :color="item.color"
                        class="u-border-radius-xs u-spacing-ml-m"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </chart-with-legends>
          </div>
          <div slot="footer">
            <span class="u-color-grey-x-light u-font-size-7"
              >Last Updated :{{ getMaxDateFormatted }}</span
            >
          </div>
        </card-widget>
        <card-widget
          v-if="!showTable"
          :apply-card-class="false"
          class="u-bg-color-grey-white u-spacing-mv-m"
          :config="config.widgets['widget1']"
          :data="getProductDiagnosticsSourceCardData"
          :custom-date="customDate"
        >
          <div
            slot="body"
            class="page-health-container u-spacing-ph-m"
          >
            <div class="u-display-flex u-flex-wrap-yes">
              <metricsCard
                v-for="(
                  item, index
                ) in getProductDiagnosticsSourceCardData.rows"
                :key="index"
                :total-success="item.totalSuccess"
                :total-failed="item.totalFailed"
                :total-tested="item.totalTested"
                :source-name="item.sourceName"
                :is-active="item.isActive"
                :error="false"
                :loading="false"
                @cardClicked="handleCardClick"
              />
            </div>
          </div>
          <div slot="footer">
            <span class="u-color-grey-x-light u-font-size-7"
              >Last Updated :{{ getMaxDateFormatted }}</span
            >
          </div>
        </card-widget>
        <card-widget
          v-else
          :apply-card-class="false"
          class="u-bg-color-grey-white u-spacing-mv-m"
          :config="config.widgets['widget2']"
          :data="getProductDiagnosticsTableData"
          :custom-date="customDate"
        >
          <div slot="left-header">
            <span
              class="u-cursor-pointer"
              @click="handleBackNavigations"
            >
              <rb-icon
                class="left-back-icon u-spacing-mr-s u-color-grey-lighter rb-icon--x-medium"
                icon="right-arrow"
              />
            </span>
          </div>
          <div
            slot="body"
            class="page-health-container u-spacing-ph-m"
          >
            <rb-insights-table
              :config="config.widgets['widget2']"
              :grid-options="config.widgets['widget2'].body.gridOptions"
              :table-row="getProductDiagnosticsTableData.rows"
              :table-column="config.widgets['widget2'].body.columnDefs"
              class="pagination-overlay"
              :row-selection="rowSelection"
              :row-height="45"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="true"
              primary-key="product-diagnostics-table"
              :pagination="true"
              :pagination-total-key="getProductDiagnosticsTableData.totalRows"
              :pagination-per-page-limit="
                config.widgets['widget2'].body.APIConfig.limit
              "
              :pagination-change-event="
                config.widgets['widget2'].meta.paginationAction
              "
              :row-class-rules="rowClassRules"
              :get-current-instance="getCurrentInstance"
              :show-pagination-options="true"
            />
          </div>
          <div slot="footer">
            <span class="u-color-grey-x-light u-font-size-7"
              >Last Updated :{{ getMaxDateFormatted }}</span
            >
          </div>
        </card-widget>
      </div>
    </div>
  </div>
</template>

<script>
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import metricsCard from './product-diagnostics-metrics-card';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import loader from '@/components/basic/loader';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import widgetsConfig from './widgets';
import utils from '@/utils/helpers/';
import moment from 'moment';
import legendsCard from './product-diagnostics-legends-cards.vue';

export default {
  components: {
    rbFilterPanel,
    chart,
    cardWidget,
    rbDownloadButton,
    loader,
    chartWithLegends,
    metric,
    metricsCard,
    localDateFilter,
    iconTextActions,
    legendsCard
  },
  mixins: [widgetsUtil],
  data() {
    return {
      showTable: false,
      filterKey: 0,
      selectedRow: null,
      pageContainerHeight: '400px',
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      widgetsConfig: {},
      parentRef: {},
      resultLength: 0,
      rowSelection: {
        mode: undefined,
        onSelectionChanged: this.onSelectionChanged
      },
      showBlueBar: false,
      timer: 30000,
      reDrawRows: true,
      rbTableInstance: null,
      isHighlighted: false,
      filterInstance: null,
      showPaginationOptions: utils.internalUserCheck(window.user),
      currentDate: moment().format('MM/DD/YYYY'),
      unitColors: {
        'Total Success': '#3ea95e',
        'Total Failed': '#d7263d'
      },
      metricsShown: [],
      metricsList: [{ key: 'Total Success' }, { key: 'Total Failed' }],
      defaultSelectedMetric: [
        { key: 'Total Success' },
        { key: 'Total Failed' }
      ],
      tagConfig: {
        'Total Success': { tag1Unit: {} },
        'Total Failed': { tag1Unit: {} }
      },
      activeReportTypeOptions: [
        { title: 'Data Testing ', key: 'DATA_TESTING', selected: true },
        { title: 'Cypress', key: 'CYPRESS', selected: true }
      ]
    };
  },
  computed: {
    getMaxDateFormatted() {
      const { maxRunDate } = this.getMinMaxRunDate.rows;
      return moment(maxRunDate).format('MMM DD, YYYY');
    },
    getHistoricalCumulativeMetrics() {
      return this.$store.getters
        .getProductDiagnosticsHistoricalCumulativeTestScores;
    },
    metricData() {
      return {};
    },
    customDate() {
      const { maxRunDate, minRunDate } = this.getMinMaxRunDate.rows;
      return {
        defaultDate: moment(this.getActiveDate).format('MM/DD/YYYY'),
        toDate: moment(maxRunDate).format('MM/DD/YYYY'),
        fromDate: minRunDate
          ? moment(minRunDate).format('MM/DD/YYYY')
          : undefined
      };
    },
    getProductDiagnosticsGraphData() {
      return this.$store.getters.getProductDiagnosticsGraphData;
    },
    chartData() {
      const apiData = this.$store.getters.getProductDiagnosticsGraphData.rows;
      const dataArray = [['Total Success'], ['Total Failed'], ['run_date']];
      apiData.forEach((item) => {
        dataArray[0].push(item.total_success);
        dataArray[1].push(item.total_failed);
        dataArray[2].push(item.run_date);
      });
      const colors = { 'Total Success': '#3ea95e', 'Total Failed': '#d7263d' };
      [{ key: 'Total Success' }, { key: 'Total Failed' }];
      const axes = {
        'Total Success': 'y',
        'Total Success_color': '#3ea95e',
        'Total Failed': 'y2',
        'Total Failed_color': '#d7263d'
      };
      const xs = {
        'Total Success': 'run_date',
        'Total Failed': 'run_date'
      };
      const axisFormat = {
        y: {
          show: true
        },
        y2: {
          show: true
        }
      };
      return {
        data: dataArray,
        timeseries: 'run_date',
        axis_format: axisFormat,
        show_axis_colors: true,
        xDataKey: 'x',
        grid: 'xy',
        colors,
        axes,
        xs,
        axis: { y2: { show: true } }
      };
    },
    getMinMaxRunDate() {
      return this.$store.getters.getProductDiagnosticsMinMaxDate;
    },
    getProductDiagnosticsTableData() {
      return this.$store.getters.getProductDiagnosticsTableData;
    },
    activeSourceCard() {
      return this.$store.getters.getProductDiagnosticsActiveCardFilter;
    },
    getSourceCardData() {
      return this.$store.getters.getProductDiagnosticsSourceCardData;
    },
    getActiveCardDetails() {
      const rows = this.getSourceCardData.rows;
      const activeFilterCard =
        this.$store.getters.getProductDiagnosticsActiveCardFilter;
      const index = rows.findIndex(
        (item) => item.sourceName === activeFilterCard
      );
      if (index >= 0) {
        rows[index].error = false;
        return rows[index];
      } else {
        return { error: true };
      }
    },
    getTableHasData() {
      const length = (
        (
          this.$store.getters.getProductDiagnosticsTableData[
            this.config.widgets.widget2.meta.key
          ] || {}
        ).rows || []
      ).length;
      return length;
    },
    getActiveWhereClause() {
      return this.$store.getters.getProductDiagnosticsWhereClause;
    },
    getActiveDate() {
      const activeWhereClause = this.getActiveWhereClause;
      return activeWhereClause.date.to || activeWhereClause.date.from;
    },
    getProductDiagnosticsSourceCardData() {
      const data = this.$store.getters.getProductDiagnosticsSourceCardData;
      const rows = data.rows || [];
      const activeFilterCard =
        this.$store.getters.getProductDiagnosticsActiveCardFilter;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].sourceName === activeFilterCard) {
          rows[i].isActive = true;
        } else {
          rows[i].isActive = false;
        }
      }
      return data;
    },
    getCumulativeMetrics() {
      return this.$store.getters.getProductDiagnosticsCumulativeTestScores;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          return params.data.uniqueId === this.selectedRow;
        }.bind(this)
      };
    },
    primaryKey() {
      return this.$store.getters.getCampaignPrimaryKey[
        this.config.widgets.widget2.meta.primaryKey
      ];
    },
    getActiveReportTypeDetails() {
      return this.$store.getters.getProductDiagnosticsActiveReportType;
    },
    getActiveReportTitle() {
      const activeReportTitle = this.getActiveReportTypeDetails.map(
        (item) => item.title
      );
      return activeReportTitle.join(', ');
    }
  },
  watch: {},
  beforeCreate() {},
  created() {
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);

    this.$store.dispatch('productDiagnosticsMinMax');
    // this.$store.dispatch('setProductDiagnosticsDate', { date: payload });

    // this.$store.dispatch(this.config.widgets.widget3.meta.action, this.config.widgets.widget3)
  },
  destroyed() {
    this.$store.dispatch('resetProductDiagnosticsDashboardState');
  },
  methods: {
    handleActiveReportChange(context, data) {
      let _data = data;
      if (!_data.length) {
        _data = this.activeReportTypeOptions;
      }
      this.$store.dispatch(
        'updateProductDiagnosticsActiveReportFilter',
        _data || {}
      );
      if (this.showTable) {
        this.$store.dispatch(
          this.config.widgets.widget2.meta.action,
          this.config.widgets.widget2
        );
      } else {
        this.$store.dispatch(
          this.config.widgets.widget1.meta.action,
          this.config.widgets.widget1
        );
      }
      this.$store.dispatch(
        this.config.widgets.widget3.meta.action,
        this.config.widgets.widget3
      );
    },
    downloadReport(data) {
      const { s3_path, test_category } = data.params.data;
      const params = { filepath: s3_path, type: test_category };
      this.$store.dispatch('productDiagnosticsDownloadFile', params);
    },
    handleBackNavigations() {
      this.showTable = false;
      this.$store.dispatch(
        this.config.widgets.widget1.meta.action,
        this.config.widgets.widget1
      );
      eventBus.$emit(this.config.backButtonClickEmit);
    },
    onSelectionChanged(data) {},
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    handleCardClick(data) {
      this.showTable = true;
      const { sourceName } = data;
      this.$store.dispatch('updateProductDiagnosticsCardFilter', sourceName);
      eventBus.$emit(this.config.cardClickEmit, sourceName);
      this.$store.dispatch(
        this.config.widgets.widget2.meta.action,
        this.config.widgets.widget2
      );
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    onCreate(instance) {
      this.filterInstance = instance;
    }
  }
};
</script>

<style lang="css" scoped>
.metrics-card-light {
  box-shadow: 0 0 6px 0 rgba(139, 143, 147, 0.4);
}
.pagination-overlay {
  width: 100%;
  height: calc(100vh - 182px);
}
.padding--right {
  padding-right: 0% !important;
}
.page-health-container {
  min-height: 40vh;
}
.account-health-container {
  min-height: 50vh;
}
.pagination-overlay {
  width: 100%;
  height: calc(100vh - 182px);
}
.padding--right {
  padding-right: 0% !important;
}
.left-back-icon {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
