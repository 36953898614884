export function getMetaDataConfig() {
  return [
    {
      action: 'entityDetails/fetchAdgroupDetails',
      getters: 'entityDetails/getAdgroupDetails',
      campaignFetchAction: 'entityDetails/fetchCampaingDetails',
      campaignGetter: 'entityDetails/getCampaignDetails'
    }
  ];
}

export function getHeaderConfigs(campaignType = 'sp') {
  const campaignTypeLowerCased = campaignType?.toLowerCase();
  // As this is my last commit push,so here is a small JavaScript joke
  // Why did the JavaScript variable throw a surprise party?
  // Because it was hoisted.

  // My last message to all - D907qBFqtNQOTETa6iWFcLhuxhXsRvYKBDTssUZZZUyuHXgazBL4yaY08iNviQ6mrPmw1wt31ZEUVoHvpcaPjg==
  // This message is encrypted with AES (ECB mode encryption, 128 bits, outputted in base64 format, please convert to raw string after decryption)
  // Secret key (16 characters long) - Reach out to me or solve this simple riddle
  // Riddle - `welcometo${reponame}`
  return {
    maxFeedDate: 'ams_campaign',
    line1: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Ad Group',
            values: ['name'],
            titleClass: 'u-color-grey-lighter u-font-weight-700 u-font-size-2',
            valueClasses: ['u-font-weight-700 u-font-size-2'],
            altValues: ['']
          },
          class: 'u-spacing-pt-xxs',
          itemType: 'attribute'
        }
      ],
      class: ''
    },
    line2: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Status',
            values: ['state'],
            titleClass: null,
            delimiter: '',
            valueClasses: ['u-color-[color]-base u-text-case-title'],
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        ...(campaignTypeLowerCased !== 'sb'
          ? [
              {
                fromMetadata: true,
                attribute: {
                  pre: [],
                  title: 'Bid',
                  values: ['defaultBidView'],
                  titleClass: null,
                  delimiter: ' ',
                  valueClasses: null,
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              }
            ]
          : [])
      ],
      class: 'u-spacing-pb-m u-font-size-5'
    },
    filters: {
      config: {
        emit: `${campaignTypeLowerCased}AdgroupDetailsFiltersApplied`,
        hasSearch: false,
        addToFilter: `${campaignTypeLowerCased}AdgroupDetailsAddToFilters`
      },
      filterLocalStorageKey: 'campaigns-filters',
      page: `${campaignTypeLowerCased}AdgrpoupDetails`
    },
    bookmark: {
      value: true,
      title: 'name',
      fromMetadata: true
    },
    redirectTo: {
      value: true,
      config: {
        name: 'EntityDetails',
        params: {
          entityId: ':entityId',
          entityType: campaignTypeLowerCased
        }
      }
    },
    // line 1 and 2 will wrap around date picker if its present
    metadata_api_state: {
      getter: 'entityDetails/getAdgroupDetails'
    }
  };
}

export function getChartConfigs(
  campaignType = 'sp',
  targetingType = 'keyword'
) {
  const campaignTypeLowerCased = campaignType?.toLowerCase();
  const targetingTypeLowedCased = targetingType?.toLowerCase();
  return {
    page: `cm_adgroups_${targetingTypeLowedCased}_${campaignTypeLowerCased}`,
    globalViewId: 0,
    maxFeedDate: 'ams_campaign',
    pageId: 0,
    filter: `${campaignTypeLowerCased}AdgroupDetailsFiltersApplied`,
    config: {
      chartingWb: {
        ':widget': 'ChartingWB',
        ':timeseriesRollupBy': 'DAY',
        keepExistingDimensionNameValueList: true,
        replaceEntityInDimensionNameValueList: {
          ':adgroup_id': ':entityId'
        },
        timeseries: 'report_date'
      }
    }
  };
}

export function getAddSearchTermsAsKeywordPayloadTemplate(campaignType = 'SP') {
  return {
    actionType: 'addKeywordToCampaigns',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    primaryKey: ':campaignId',
    widget: 'campaign',
    actionPayload: {
      // profileId: '614698750655456',
      campaignId: ':campaignId',
      campaignType: campaignType?.toUpperCase(),
      adgroup_id: ':adgroupId',
      new_keyword_text: ':keywordText',
      new_match_type: ':matchType',
      new_bid: ':bid'
    },
    viewPayload: {
      // profileId: '614698750655456',
      campaignId: ':campaignId',
      adgroupName: ':adgroupName',
      keywordText: ':keywordText',
      matchType: ':matchType',
      newBid: ':bid'
    }
  };
}

export function getAddSearchTermsAsNegativeKeywordPayloadTemplate(
  campaignType = 'SP'
) {
  return {
    actionType: 'addNegativeKeyword',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    primaryKey: ':campaignId',
    widget: 'campaign',
    actionPayload: {
      // profileId: '614698750655456',
      campaignId: ':campaignId',
      campaignType: campaignType?.toUpperCase(),
      adgroup_id: ':adgroupId',
      new_keyword_text: ':keywordText',
      new_match_type: ':matchType',
      new_bid: ':bid'
    },
    viewPayload: {
      // profileId: '614698750655456',
      campaignId: ':campaignId',
      adgroupName: ':adgroupName',
      keywordText: ':keywordText',
      matchType: ':matchType',
      newBid: ':bid'
    }
  };
}
