<template>
  <section
    class="u-width-100 u-position-relative custom-action-panel"
    :class="{
      'show-loader': isLoading || !isAllowed,
      [customActionPanelContainerClass]: true
    }"
  >
    <section
      v-if="isLoading || !isAllowed"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="isLoading"
        :color="'#007cf6'"
      />
      <div
        v-if="!isAllowed"
        class="fillMessage fill--parent u-font-size-3"
        data-cy="warningMessage"
      >
        {{ defaultValues.warningMessage }}
      </div>
    </section>
    <CustomEntityContainer
      v-if="!isLoading && isAllowed"
      custom-style="u-height-100"
    >
      <h2
        v-if="!defaultValues.hideHeader"
        slot="header"
        class="u-font-size-5 u-spacing-pl-l u-spacing-mb-m u-spacing-pt-l u-text-uppercase action-header"
        data-cy="headerValue"
      >
        {{ staticHeaderText }} {{ defaultValues.selections }}
      </h2>
      <div
        slot="left-table"
        class="u-display-flex u-flex-direction-column card u-border-width-s u-border-color-grey-xxx-light left-action-table"
        :class="!config.right ? 'u-width-100' : 'u-width-50'"
      >
        <div class="u-flex-1 u-display-flex u-flex-direction-column">
          <!-- Left Table header -->
          <slot name="leftTableHeader">
            <div
              v-if="config.left.headerText"
              class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"
            >
              <div
                class="u-spacing-p-xs"
                data-cy="tableHeaderText"
              >
                {{ config.left.headerText }}
              </div>
            </div>
          </slot>
          <div
            v-if="config.left.tabs"
            class="tabs-container"
            :class="
              config.left && config.left.meta && config.left.meta.filters
                ? ''
                : 'u-spacing-mb-l'
            "
          >
            <GenericTabsComponent
              v-if="config.left.showTabs"
              :default-tab-index="defaultTabIndexTabsComponent"
              :tabs-list="config.left.tabs && Object.keys(config.left.tabs)"
              @handleTabsClick="handleTabsClick($event, 'left')"
            />
          </div>
          <span
            v-if="config.left && config.left.tabHeader"
            class="u-spacing-p-m u-font-size-6 u-font-weight-600 selected"
            data-cy="leftTableHeader"
          >
            {{ config.left.tabHeader }}
          </span>

          <!-- Recommendations Dropdown -->
          <div
            v-if="leftRecommendations && leftRecommendations.show"
            class="u-display-flex u-spacing-pb-l u-spacing-ph-l u-flex-align-items-center"
            data-cy="leftRecommendation"
          >
            <div
              class="u-spacing-pr-s u-font-weight-600 u-font-size-5"
              data-cy="recommTxt"
            >
              Recommendation:
            </div>
            <rb-select
              :options="leftRecommendations.values"
              :send-details="true"
              class="u-width-200px u-spacing-mr-xs height-custom-bid u-border-all u-border-width-s u-border-color-grey-xxx-light u-flex-align-items-center u-flex-justify-content-center u-border-radius-s u-text-overflow-ellipsis u-display-flex u-flex-align-items-center"
              :on-close="leftCustomRecommendationOption"
            >
              <div
                slot="trigger"
                class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
              >
                <div
                  class="u-width-100 u-flex-align-items-center u-display-flex u-flex-justify-content-space-between"
                >
                  <div
                    class="u-font-size-6 u-spacing-pl-s u-width-90% u-text-overflow-ellipsis"
                    data-cy="selectedRecomm"
                  >
                    {{
                      (selectedLeftCustomRecommendation &&
                        selectedLeftCustomRecommendation.title) ||
                      'NA'
                    }}
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-ml-xs u-color-grey-base"
                    :icon="'caret-down'"
                    data-cy="carrotIcon"
                  />
                </div>
              </div>
            </rb-select>
          </div>

          <slot name="aboveLeftCustomBid" />

          <div
            v-if="showCustomBidInput"
            class="u-display-flex u-spacing-pb-m u-spacing-ph-m u-flex-align-items-center"
          >
            <div
              class="u-spacing-pr-s u-font-weight-600 u-font-size-5"
              data-cy="subHeaderValue"
            >
              {{ subHeading || 'Bid:' }}
            </div>
            <div class="u-position-relative u-display-flex">
              <span
                class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                data-cy="currency"
              >
                {{ currency }}
              </span>
              <input
                v-model="suggestedCustomBidInput"
                :min="cpcBidMin"
                :step="0.01"
                :max="cpcBidMax"
                type="number"
                data-cy="inputValue"
                class="rb-input u-width-100px height-custom-bid u-bg-color-grey-white borderLeftNone"
              />
            </div>
          </div>

          <div
            v-if="leftCustomBid && leftCustomBid.show"
            data-cy="bidComponent"
            class="u-display-flex u-spacing-pb-m u-spacing-ph-l u-flex-align-items-center"
          >
            <div
              class="u-spacing-pr-s u-font-weight-600 u-font-size-5"
              data-cy="subHeaderValue"
            >
              {{ subHeading || 'Bid:' }}
            </div>
            <rb-select
              v-if="!hideLeftFilterDropdown"
              :options="leftCustomBid.values"
              :send-details="true"
              class="u-width-160px u-spacing-mr-xs height-custom-bid u-border-all u-border-width-s u-border-color-grey-xxx-light u-flex-align-items-center u-flex-justify-content-center u-border-radius-s u-display-flex u-flex-align-items-center"
              :on-close="customBidOption"
            >
              <div
                slot="trigger"
                class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
              >
                <div
                  class="u-width-100 u-flex-align-items-center u-display-flex u-flex-justify-content-space-between"
                >
                  <div
                    class="u-font-size-6 u-spacing-pl-s"
                    data-cy="selectedCustomBid"
                  >
                    {{ (selectedCustomBid && selectedCustomBid.title) || 'NA' }}
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                    data-cy="carrotDownIcon"
                    :icon="'caret-down'"
                  />
                </div>
              </div>
            </rb-select>
            <div
              v-if="selectedCustomBid && selectedCustomBid.input === 'currency'"
              class="u-position-relative u-display-flex"
            >
              <span
                class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                data-cy="currency"
              >
                {{ currency }}
              </span>
              <input
                v-model="suggestedCustomBidInput"
                :min="cpcBidMin"
                :step="0.01"
                :max="cpcBidMax"
                type="number"
                data-cy="inputValue"
                class="rb-input u-width-100px height-custom-bid u-bg-color-grey-white borderLeftNone"
                @input="bidValueChange"
              />
            </div>
            <div
              v-else-if="
                selectedCustomBid &&
                selectedCustomBid.input === 'radioSelectWithInput'
              "
            >
              <radioSelectionWithInput
                :title="selectedCustomBid.radio.inputTitle"
                :radio-config="selectedCustomBid.radio.config"
                :default-radio-selected="selectedCustomBid.radio.default"
                :default-input-value="null"
                :show-radio-options="selectedCustomBid.radio.show"
                @onUpdate="adjustRadioSelectWithInput"
              />
            </div>
          </div>

          <div
            v-if="suggestedCustomBidError"
            class="u-spacing-pb-l u-spacing-ph-m u-font-size-7 u-color-red-base"
          >
            {{ suggestedCustomBidErrorMessage }}
          </div>

          <div
            v-if="matchTypeHeading"
            class="u-spacing-ml-l u-font-size-5 u-font-weight-600"
            data-cy="matchTypeTxt"
          >
            Match type :
            <span
              class="u-font-weight-400"
              :data-cy="`matchType-${matchTypeHeading}`"
              >{{ matchTypeHeading }}</span
            >
          </div>
          <div
            v-if="enableLeftFilter"
            class="u-spacing-pl-s"
            data-cy="enableLeftFilter"
          >
            <rb-filter-panel
              :id="localStorageFilterKey || 'tags-filters'"
              :key="filterKey"
              class="u-font-size-5 u-spacing-mh-m u-border-radius-s u-width-100 u-display-flex u-flex-align-items-center"
              :listener-event="localStorageFilterKey || 'tag-filters'"
              :hide-filter="true"
              :enable-remove-all="false"
              page="tags"
              :state="config.left.meta.filterState"
              :loader-status="loaderStatus"
              :enable-save="false"
              :has-search="false"
              :data="filterData"
              :use-local-storage="localStorageFilterKey ? true : false"
              :primary="primaryFilterData"
              :secondary-data="secondaryFilterData"
              :config="config.left.meta.filters"
              :emit="config.left.meta.filters.emit"
              :get-filter-instance="getFilterInstance"
              :filter-v2="false"
              data-cy="tagFilters"
              @savedFilterApplied="filterKey++"
            />
          </div>
          <div
            v-if="isLeftHandSearch"
            class="u-spacing-ph-l u-spacing-pb-l search-input-container"
            data-cy="searchBox"
          >
            <rb-search
              v-model="leftTableSearch"
              :placeholder="
                (config.left.search && config.left.search.placeholder) ||
                (config.left.tabs &&
                  config.left.tabs[activeTab] &&
                  config.left.tabs[activeTab].search.placeholder) ||
                'Search'
              "
              :input-classes="'search-input u-width-100'"
              :rounded="true"
              data-cy="inputSearch"
              @onSearchTrigger="handleLeftTableSearch"
            />
          </div>

          <!-- Filter check box -->
          <div
            v-if="showLeftFilterCheckBox"
            class="u-display-flex u-spacing-ph-l u-spacing-pb-l u-width-100 u-flex-align-items-center"
          >
            <span
              v-if="leftConfigFilterCheckBoxSource.header"
              class="u-font-weight-600 u-font-size-5 u-spacing-mr-s"
              data-cy="keywordFilterTxt"
            >
              {{ leftConfigFilterCheckBoxSource.header }}
            </span>
            <div
              class="u-display-flex u-font-weight-400 u-flex-justify-content-flex-start u-overflow-auto"
            >
              <rb-checkbox
                v-for="(item, i) in leftConfigFilterCheckBoxSource.items"
                :key="i"
                v-model="leftFilterCheckBoxBooleanValue[i]"
                :style="
                  leftConfigFilterCheckBoxSource.filterStyle &&
                  leftConfigFilterCheckBoxSource.filterStyle[item]
                    ? leftConfigFilterCheckBoxSource.filterStyle[item]
                    : ''
                "
                class="u-spacing-pr-s filter-check-box"
                native-value="false"
                @input="updateLeftFilterCheckBoxArray()"
              >
                <div
                  v-if="
                    leftConfigFilterCheckBoxSource.vhtmlItems &&
                    leftConfigFilterCheckBoxSource.vhtmlItems[item]
                  "
                >
                  <!-- eslint-disable -->
                  <div
                    v-html="leftConfigFilterCheckBoxSource.vhtmlItems[item]"
                  />
                  <!-- eslint-enable -->
                </div>
                <div v-else>
                  {{ item }}
                </div>
              </rb-checkbox>
            </div>
          </div>

          <div
            v-if="
              config.left.tabs &&
              config.left.tabs[activeTab] &&
              config.left.tabs[activeTab].ui_component
            "
            :class="{
              'u-spacing-pt-l':
                !showLeftFilterCheckBox &&
                !isLeftHandSearch &&
                !enableLeftFilter &&
                !removeLeftTabCustomComponentTopPadding
            }"
          >
            <component
              :is="config.left.tabs[activeTab].ui_component.name"
              v-bind="config.left.tabs[activeTab].ui_component.props || {}"
              :parent-ref="context"
              :filter-check-box-array="leftFilterCheckBoxArray"
              v-on="config.left.tabs[activeTab].ui_component.events || {}"
            />
          </div>

          <!-- Left table component div comes here -->
          <div
            v-else
            class="u-position-relative u-flex-1 u-width-100"
          >
            <loader
              v-if="leftTableData.load || leftTableLoad"
              class="u-bg-color-grey-white fill--parent"
              :loading="true"
              :color="'#007cf6'"
            />
            <rb-insights-table
              v-if="config.left"
              :key="'leftTable' + activeTab"
              :custom-height="false"
              :config="config.left"
              :grid-options="gridOptionsLeft"
              :auto-configure="autoConfigureLeftTable"
              :table-row="leftTableRowsProp"
              :table-column="leftTableColumnDefsProp"
              class="u-display-flex left-table u-flex-direction-column"
              :no-rows-message="defaultValues.noRowsLeftTable"
              :row-height="(config.left && config.left.rowHeight) || 48"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="
                config && config.left && config.left.hidePagination
                  ? false
                  : true
              "
              :client-side-pagination="isClientSidePagination"
              :show-pagination-options="
                config.left.hidePaginationOptions ? false : true
              "
              :pagination-total-key="
                isClientSidePagination
                  ? autoConfigureLeftTable.rows.length
                  : leftTableData.totalRows
              "
              :pagination-per-page-limit="
                config.left.body.APIConfig && config.left.body.APIConfig.limit
              "
              :get-current-instance="getLeftTableInstance"
              :row-class-rules="leftTableRowClassRules"
              :row-selection="leftRowSelection"
              :has-expand="expandLeftTableConfig.hasExpand"
              :levels="expandLeftTableConfig.levels"
              :has-default-row-expand="
                expandLeftTableConfig.hasDefaultRowExpand
              "
              :expand-cell-width="expandLeftTableConfig.expandColumnWidth"
            />
          </div>
        </div>
      </div>

      <div
        v-if="config.right"
        slot="right-table"
        class="u-display-flex u-flex-direction-column u-width-50 right-action-table"
      >
        <div
          class="u-flex-1 u-display-flex u-flex-direction-column card u-border-width-s u-border-color-grey-xxx-light"
        >
          <!-- Right Table header -->
          <div
            v-if="config.right.headerText"
            class="tableHeading u-display-flex u-spacing-ph-m u-spacing-pv-s u-font-size-5 u-flex-justify-content-space-between u-flex-align-items-center"
          >
            <div
              class="u-spacing-p-xs u-font-weight-600"
              data-cy="rightTableHeaderText"
            >
              {{ config.right.headerText() }}
            </div>
            <div
              v-if="
                rightFilterCheckboxInHeader.show &&
                rightFilterCheckboxInHeader.show()
              "
              class="u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                class="rb-icon--medium u-color-orange-base"
                :icon="'info-circle-fill'"
              />
              <span class="u-spacing-ml-s">
                <span class="u-font-weight-600">
                  <span class="u-text-decoration-underline"
                    >{{
                      rightFilterCheckboxInHeader.filterFn(rightTableRows)
                        .length
                    }}
                    {{ rightFilterCheckboxInHeader.entity.name }}
                  </span>
                  {{ rightFilterCheckboxInHeader.entity.message }}
                </span>
                {{ rightFilterCheckboxInHeader.entity.defaultValue }}
              </span>
              <rb-checkbox
                v-model="rightTableFilterApplied"
                class="u-display-flex u-flex-align-items-center u-spacing-ml-s u-width-80px"
                @input="checkBoxChange"
              >
                View All
              </rb-checkbox>
            </div>
          </div>
          <span
            v-else-if="
              fetchedRightTableRows.totalRows
                ? fetchedRightTableRows.totalRows + rightTableRows.length
                : rightTableRows.length
            "
            class="u-spacing-p-m u-font-size-6 u-font-weight-600 selected"
          >
            Selected ({{
              fetchedRightTableRows.totalRows
                ? fetchedRightTableRows.totalRows + rightTableRows.length
                : rightTableRows.length
            }})
          </span>
          <slot name="result-body-header" />
          <div class="u-position-relative u-flex-1">
            <loader
              v-if="rightTableData.load || rightTableLoad"
              class="u-bg-color-grey-white fill--parent"
              :loading="true"
              :color="'#007cf6'"
            />
            <rb-insights-table
              v-if="config.right"
              key="rightTable"
              :custom-height="false"
              :config="config.right"
              :grid-options="gridOptionsRight"
              :auto-configure="autoConfigureRightTable"
              :table-row="rightTableRowsProp"
              :table-column="rightTableColumnDefsProp"
              class="u-display-flex right-table u-flex-direction-column"
              :row-height="(config.right && config.right.rowHeight) || 48"
              :no-rows-message="defaultValues.noRowsRightTable"
              :client-side-pagination="true"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :show-pagination-options="true"
              :pagination-total-key="
                !rightTableRowsProp
                  ? rightTableFilterApplied
                    ? computedRightTableRows.length
                    : rightTableRows.length
                  : rightTableRowsProp.length || 0
              "
              :pagination-per-page-limit="config.right.body.APIConfig.limit"
              :download-this-table="false"
              :get-current-instance="getRightTableInstance"
              :row-class-rules="rightTableRowClassRules"
            />
          </div>
        </div>
        <slot name="right-table-footer" />
      </div>
      <div
        v-if="(defaultValues || {}).actionInfoText"
        slot="action-info"
        class="action-info-container u-position-relative"
      >
        <div
          class="u-width-100 u-display-flex u-spacing-ph-l u-spacing-pt-l u-spacing-pb-m u-flex-align-items-center"
        >
          <rb-icon
            class="u-flex-0 rb-icon--medium u-color-grey-lighter"
            :icon="'info-circle-fill'"
          />
          <div class="u-font-size-5 u-color-grey-light u-spacing-ml-s">
            {{ defaultValues.actionInfoText }}
          </div>
        </div>
      </div>
      <div
        v-if="errorMessage"
        slot="error-message"
        class="u-font-size-6 u-color-orange-base u-font-weight-600 u-spacing-ml-l u-spacing-mt-s"
      >
        <rb-icon
          class="u-color-orange-base rb-icon--small"
          icon="info-circle-fill"
          data-cy="errorMessage"
        />
        <span> {{ errorMessage }} </span>
      </div>
      <footer
        v-if="!defaultValues.hideFooter"
        slot="footer"
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-mt-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
      >
        <div
          v-tippy="{ placement: 'top', arrow: true }"
          :title="applyButtonDisabled() ? '' : applyButtonTooltip"
        >
          <rb-button
            :id="config.applyActionID"
            :key="identifier"
            :click-fn="apply"
            :text="defaultValues.leftBtnLabel || 'Apply'"
            :size="'s'"
            :type="'filled'"
            class="u-spacing-mr-m u-cursor-pointer"
            :disabled="applyButtonDisabled()"
            :class="{
              disabled: applyButtonDisabled()
            }"
          />
        </div>
        <rb-button
          :click-fn="handleCrossClick"
          :text="defaultValues.rightBtnLabel || 'Cancel'"
          :size="'s'"
          :type="'hollow'"
          class="u-color-grey-light u-cursor-pointer"
        />
      </footer>
    </CustomEntityContainer>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import iconText from '@/components/basic/iconText';
import CustomEntityContainer from '@/pages/dashboard_service_campaign_management/common_components/customEntityContainer.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import enterListPanel from '@/components/ams/actionPanels/enterListPanel.vue';
import { cloneDeep, isEqual } from 'lodash';
import rbSearch from '@/components/widgets/search.vue';
import rbFilterPanel from '@/components/widgets/filter-panel';
import widgetsUtil from '@/components/widgetMixin';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';

export default {
  components: {
    loader,
    iconText,
    CustomEntityContainer,
    GenericTabsComponent,
    rbSearch,
    enterListPanel,
    rbFilterPanel,
    radioSelectionWithInput
  },
  mixins: [widgetsUtil],
  props: {
    staticHeaderText: {
      type: String,
      default: ''
    },
    widgetsConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selections: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      filterKey: 0,
      radioSelectionWithInputObject: null,
      currency: Vue.prototype.$currency,
      rightTableLoad: false,
      leftTableLoad: false,
      filterInstance: null,
      defaultValues: {},
      suggestedCustomBidInput: null,
      selectedCustomBid: 'NA',
      selectedLeftCustomRecommendation: null,
      config: null,
      leftTableInstance: null,
      rightTableInstance: null,
      leftTableRows: [],
      rightTableRows: [], // state to store added rows from left table to right table which is sent as payload in the end
      filteredRightTableRows: [],
      rightTableSearch: '',
      leftTableSearch: '',
      activeTab: '',
      isLoading: false,
      leftFilterCheckBoxBooleanValue: [],
      leftFilterCheckBoxArray: [],
      leftRowSelectedRows: [],
      identifier: 1,
      cpcBidMax: 1000,
      cpcBidMin: 1,
      fetchedRightTableRows: { rows: [], totalRows: 0 }, // local copy of fetched right table data,
      gridOptionsLeft: {
        context: {
          componentParent: this
        },
        domLayout: 'normal'
      },
      gridOptionsRight: {
        context: {
          componentParent: this
        },
        domLayout: 'normal'
      },
      context: this,
      errorMessage: '',
      rightTableFilterApplied: false,
      rightTableRowsCopy: []
    };
  },
  computed: {
    rightFilterCheckboxInHeader() {
      return this.config?.right?.header?.filterCheckbox || {};
    },
    customActionPanelContainerClass() {
      if (this.config?.customActionPanelContainerClass) {
        return this.config?.customActionPanelContainerClass;
      }
      return '';
    },
    enableLeftFilter() {
      return (
        this.config?.left?.meta?.filters &&
        !this.config?.left?.tabs?.[this.activeTab]?.disableFilter
      );
    },
    expandLeftTableConfig() {
      const activeTab = this.config?.left?.tabs?.[this.activeTab];
      const defaultSel = this.config?.left;
      const hasExpand = activeTab?.hasExpand || defaultSel?.hasExpand || false;
      const levels = activeTab?.expandLevels || defaultSel?.expandLevels || [];
      const hasDefaultRowExpand =
        activeTab?.hasDefaultRowExpand ||
        defaultSel?.hasDefaultRowExpand ||
        false;
      const expandColumnWidth =
        activeTab?.expandColumnWidth || defaultSel?.expandColumnWidth || 80;
      return {
        hasExpand,
        levels,
        hasDefaultRowExpand,
        expandColumnWidth
      };
    },
    localStorageFilterKey() {
      return this?.config?.left?.meta?.localStoreageFilterKey || null;
    },
    defaultTabIndexTabsComponent() {
      let index = 0;
      for (let i = 0; i < Object.keys(this.config.left.tabs).length; i++) {
        if (this.activeTab === Object.keys(this.config.left.tabs)[i]) {
          index = i;
          return index;
        }
      }
      return index;
    },
    isLeftHandSearch() {
      return this.activeTab
        ? this.config?.left?.tabs?.[this.activeTab]?.search?.enableSearch ||
            false
        : this.config.left?.search;
    },
    isAllowed() {
      return !(!this.config?.isMultipleAllowed && this.selections.length > 1);
    },
    rightTableData() {
      const useGetter = this.config?.right?.altStore || 'getRightTableData';
      return this.$store.getters[useGetter] || { load: false };
    },
    leftTableData() {
      let useGetter = this.config?.left?.altStore || 'getLeftTableData';
      if (this.config?.left?.actionAndGetterForEachTab) {
        useGetter = this.config?.left.altStore[this.activeTab];
      }
      return this.$store.getters[useGetter] || { load: false };
    },
    computedRightTableRows() {
      return this.rightTableInstance?.computedTableRows ?? [];
    },
    overrideLeftTableAutoConfigure() {
      const overrideAutoConfigure = {
        override: false,
        value: false
      };
      const leftActiveTab = this?.config?.left?.tabs?.[this.activeTab] || {};
      if ('overrideColumnDefAutoConfigure' in leftActiveTab) {
        overrideAutoConfigure.override = true;
        overrideAutoConfigure.value =
          leftActiveTab.overrideColumnDefAutoConfigure;
      }
      return overrideAutoConfigure;
    },
    autoConfigureLeftTable() {
      if (
        this.overrideLeftTableAutoConfigure?.override &&
        !this.overrideLeftTableAutoConfigure?.value
      ) {
        return undefined;
      } else if (!this.config.left.isColumnDefAutoConfigure) {
        return undefined;
      }
      const columns =
        this.config.left?.tabs?.[this.activeTab]?.columnDefs ??
        this.config.left?.body.columnDefs;
      return {
        rows: this.leftTableRows,
        columns
      };
    },
    autoConfigureRightTable() {
      if (!this.config.right.isColumnDefAutoConfigure) {
        return undefined;
      }
      const columns =
        this.config.right?.tabs?.[this.activeTab]?.columnDefs ??
        this.config.right?.body.columnDefs;
      const rows = this.rightTableFilterApplied
        ? this.getFilteredRightTableRows()
        : this.rightTableRows;
      return {
        rows,
        columns: columns
      };
    },
    leftTableRowsProp() {
      if (
        this.overrideLeftTableAutoConfigure?.override &&
        !this.overrideLeftTableAutoConfigure?.value
      ) {
        return this.leftTableRows;
      }
      if (!this.config.left.isColumnDefAutoConfigure) {
        return this.leftTableRows;
      }
      return undefined;
    },
    rightTableRowsProp() {
      if (!this.config.right.isColumnDefAutoConfigure) {
        if (this.rightTableFilterApplied) {
          return this.getFilteredRightTableRows();
        }
        return this.fetchedRightTableRows.rows
          ? Array.from(
              new Set([
                ...this.fetchedRightTableRows.rows,
                ...this.rightTableRows
              ])
            )
          : this.rightTableRows;
      } else return undefined;
    },
    leftTableColumnDefsProp() {
      if (
        this.overrideLeftTableAutoConfigure?.override &&
        !this.overrideLeftTableAutoConfigure?.value
      ) {
        return this.config.left.body.columnDefs;
      }
      if (!this.config.left.isColumnDefAutoConfigure) {
        return this.config.left.body.columnDefs;
      }
      return undefined;
    },
    rightTableColumnDefsProp() {
      if (!this.config.right.isColumnDefAutoConfigure) {
        return this.config.right.body.columnDefs;
      } else return undefined;
    },
    leftConfigFilterCheckBoxSource() {
      return (
        this.config.left?.tabs?.[this.activeTab]?.filterCheckBoxSource ??
        this.config.left.filterCheckBoxSource
      );
    },
    leftRecommendations() {
      return {
        show:
          this.config?.left?.tabs?.[this.activeTab]
            ?.showLeftRecommendationsDropdown || false,
        values:
          this.config?.left?.tabs?.[this.activeTab]
            ?.leftRecommendationsDropdownValues || []
      };
    },
    leftCustomBid() {
      let bidConfig = null;
      if (this.config?.left?.showLeftCustomBid) {
        bidConfig = {
          show: this.config?.left?.showLeftCustomBid || false,
          values: this.config?.left?.leftCustomBidValues || []
        };
      } else {
        bidConfig = {
          show:
            this.config?.left?.tabs?.[this.activeTab]?.showLeftCustomBid ||
            false,
          values:
            this.config?.left?.tabs?.[this.activeTab]?.leftCustomBidValues || []
        };
      }
      return bidConfig;
    },
    showCustomBidInput() {
      return (
        this.config?.left?.tabs?.[this.activeTab]?.showCustomBidInput || false
      );
    },
    applyButtonTooltip() {
      return (
        this.config?.left?.tabs?.[this.activeTab]?.applyButtonTooltip || ''
      );
    },
    validateCustomBidInput() {
      return (
        this.config?.left?.tabs?.[this.activeTab]?.validateCustomBidInput ??
        true
      );
    },
    suggestedCustomBidError() {
      if (this.config?.left?.tabs?.[this.activeTab].suggestedCustomBidError) {
        return this.config?.left?.tabs?.[
          this.activeTab
        ].suggestedCustomBidError(this);
      }
      if (
        (this.selectedCustomBid &&
          this.selectedCustomBid.input === 'currency') ||
        (this.showCustomBidInput && this.validateCustomBidInput)
      ) {
        const bid = +this.suggestedCustomBidInput;
        if (bid > this.cpcBidMax || bid < this.cpcBidMin || !bid) {
          return true;
        }
      }
      return false;
    },
    suggestedCustomBidErrorMessage() {
      return (
        this.config?.left?.tabs?.[this.activeTab]
          ?.suggestedCustomBidErrorMessage || ''
      );
    },
    showLeftFilterCheckBox() {
      return (
        this.config?.left?.tabs?.[this?.activeTab]?.showFilterCheckBox ||
        this.config?.left?.filterCheckBoxSource
      );
    },
    removeLeftTabCustomComponentTopPadding() {
      return this.config?.left?.tabs?.[this?.activeTab]
        ?.removeLeftTabCustomComponentTopPadding;
    },
    hideLeftFilterDropdown() {
      return this.config?.left?.tabs?.[this?.activeTab]?.hideFilterDropdown;
    },
    matchTypeHeading() {
      return this.config?.left?.tabs?.[this?.activeTab]?.singleMatchTypeHeading;
    },
    cpcBidValues() {
      return (
        this.config?.left?.tabs?.[this?.activeTab]?.bidValues || {
          default: 1,
          min: 0.01,
          max: 1000
        }
      );
    },
    subHeading() {
      return this.config?.left?.tabs?.[this?.activeTab]?.subHeading;
    },
    leftTableRowClassRules() {
      if (this.config?.left?.body?.rowClassRules) {
        return this.config.left.body.rowClassRules;
      } else return {};
    },
    rightTableRowClassRules() {
      if (this.config?.right?.body?.rowClassRules) {
        return this.config.right.body.rowClassRules;
      } else return {};
    },
    leftRowSelection() {
      if (this.config?.left?.body?.rowSelection) {
        return this.config?.left?.body?.rowSelection;
      }
      return {};
    },
    isClientSidePagination() {
      return this.config?.left?.isClientSidePagination;
    }
  },
  watch: {
    activeTab(val) {
      this.suggestedCustomBidInput = this.cpcBidValues.default;
      this.cpcBidMax = this.cpcBidValues.max;
      this.cpcBidMin = this.cpcBidValues.min;
      this.leftFilterCheckBoxBooleanValue = [];
      if (this.leftConfigFilterCheckBoxSource?.defaultSelected?.length) {
        this.leftConfigFilterCheckBoxSource?.defaultSelected?.map((item) => {
          this.leftFilterCheckBoxBooleanValue.push(true);
        });
      }
    },
    'leftTableInstance.localTableRow': {
      handler(newValue) {
        if (!this.expandLeftTableConfig?.hasExpand) return;
        for (const rightTableRow of this.rightTableRows) {
          for (let leftTableRow of newValue) {
            if (
              leftTableRow[this.config.primaryKey] ===
              rightTableRow[this.config.primaryKey]
            ) {
              leftTableRow.isRowSelected = true;
            }
          }
        }
        if (
          this.config.left?.meta?.updateRows &&
          newValue?.length !== this.leftTableRows?.length
        ) {
          this.config.left?.meta?.updateRows(this);
        }
        this.leftTableInstance.redrawTableRows();
      }
    },
    leftCustomBid: {
      handler(newValue) {
        if (newValue?.values?.length) {
          this.selectedCustomBid = newValue.values[0];
        }
      },
      immediate: true,
      deep: true
    },
    suggestedCustomBidInput: function (newVal) {
      // to update the right table bid when we change the value from bid selections
      if (this.config?.right?.boolUpdateRightTableBid) {
        this.updateRightTableBid();
      }
    },
    leftRecommendations: {
      handler(newValue) {
        if (newValue?.values.length) {
          this.selectedLeftCustomRecommendation = newValue.values[0];
        }
      }
    },
    // isAllowed (newVal, oldVal) {
    //   if (newVal !== oldVal && newVal) {
    //     this.init(); // refetch table data on switch from multiple selected rows to single selected row
    //   }
    // },
    'leftTableData.rows': function (newVal = []) {
      // creates own custom primary key from 2 other keys
      if (this.config.createPrimaryKeyFrom && newVal.length) {
        newVal = newVal.map((item) => {
          item.customKey =
            item[this.config.createPrimaryKeyFrom[0]] +
            item[this.config.createPrimaryKeyFrom[1]];
          return item;
        });
      }
      this.leftTableRows = [...newVal];
      if (this.config.left?.meta?.updateRows) {
        this.config.left?.meta?.updateRows(
          this,
          this.config?.left?.tabs?.[this.activeTab]?.search?.searchKey,
          this.leftTableSearch
        );
      }
    },
    'rightTableData.rows': function (newVal) {
      // update local copy of fetched right table data on pagination
      if (newVal?.length) {
        // creates own custom primary key from 2 other keys
        if (this.config.createPrimaryKeyFrom && newVal.length) {
          newVal = newVal.map((item) => {
            item.customKey =
              item[this.config.createPrimaryKeyFrom[0]] +
              item[this.config.createPrimaryKeyFrom[1]];
            return item;
          });
        }
        if (this.config.right?.meta?.updateRows) {
          return this.config.right?.meta?.updateRows(this, newVal);
        }
        this.fetchedRightTableRows = {
          rows: newVal,
          totalRows: newVal?.[0]?.auto_cubesdk_count
        };
        this.updateEntityInformation(newVal || []);
        this.updateEntityInformation();
      }
    },
    leftTableRows: function (newVal) {
      if (this.fetchedRightTableRows?.rows?.length) {
        // iterate over fetched copy of right table rows
        this.updateEntityInformation(this.fetchedRightTableRows.rows);
      }
      // iterate over added right table rows
      this.updateEntityInformation();
    },
    rightTableRows: function (newVal) {
      this.$emit('rightTableRows', newVal, this);
      this.updateEntityInformation();
    },
    selections: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.init();
        }
      }
    },
    leftFilterCheckBoxArray: function () {
      if (this.config.left?.meta?.updateRows) {
        this.config.left?.meta?.updateRows(
          this,
          this.config?.left?.tabs?.[this.activeTab]?.search?.searchKey,
          this.leftTableSearch
        );
      }
    }
  },
  created() {
    this.init();
    this.suggestedCustomBidInput = this.cpcBidValues.default;
  },
  destroyed() {
    if (this.config?.left?.meta?.filters) {
      eventBus.$on(this.config.left.meta.filters.emit);
    }
    eventBus.$off('onAdGroupsSelection');
  },
  beforeDestroy() {
    if (
      this.rightFilterCheckboxInHeader?.show?.() &&
      this.rightTableFilterApplied
    ) {
      this.checkBoxChange(false);
      this.$emit('rightTableRows', this.rightTableRows, this);
    }
    this.$store.dispatch(
      'clearTableStore',
      this.config?.left?.actionAndGetterForEachTab
        ? this.config?.left?.altStore[this.activeTab]
        : this.config?.left?.altStore
    );
    this.$emit('updateRightTableFilter', false);
  },
  methods: {
    updateRightTableRowsBeforeReRendering() {
      if (this.config?.right?.updateRightTableRowsBeforeReRendering) {
        this.rightTableRows =
          this.config?.right?.updateRightTableRowsBeforeReRendering(
            this.rightTableRows,
            this
          );
      }
    },
    checkBoxChange(value) {
      if (value) {
        this.rightTableRowsCopy = cloneDeep(this.computedRightTableRows);
      } else {
        // filtered entity changes are reflected on all entities
        const computedRowsMap = this.computedRightTableRows.reduce(
          (acc, item) => {
            acc[item[this.config.primaryKey]] = item;
            return acc;
          },
          {}
        );
        for (let i = 0; i < this.rightTableRowsCopy.length; i++) {
          let row = this.rightTableRowsCopy[i];
          const updatedRow = computedRowsMap?.[row?.[this?.config?.primaryKey]];
          if (updatedRow) {
            this.rightTableRowsCopy[i] = updatedRow;
          }
        }
        this.rightTableRows = cloneDeep(this.rightTableRowsCopy);
      }
    },
    bidValueChange(e) {},
    adjustRadioSelectWithInput(value) {
      this.radioSelectionWithInputObject = cloneDeep(value);
    },
    triggerUpdateRightTableRows() {
      if (this.config.right?.updateRightTableRows) {
        this.config.right?.updateRightTableRows(this);
      }
    },
    customBidOption(i, data) {
      if (!data?.length) return;
      this.selectedCustomBid = data[0];
    },
    leftCustomRecommendationOption(i, data) {
      if (!data?.length) return;
      this.selectedLeftCustomRecommendation = data[0];
      const newConfig = this.widgetsConfig.config(this);
      // In here, we do not udpate the whole config object itself
      // If we do that, the leftRecommendations watch will get triggered
      // and the recommendations will switch back to the first selection
      // hence we just update the API config property and get new table items.
      this.config.left.body.APIConfig = newConfig?.left?.body?.APIConfig;
      this.getTable(this.config.left);
    },
    init() {
      this.config = this.widgetsConfig.config(this);
      if (!this.config.left.hasOwnProperty('showTabs')) {
        this.config.left.showTabs = true;
      }
      this.activeTab = '';
      if (this.config.left.tabs) {
        this.activeTab = this.config?.left?.defaultTab
          ? this.config?.left?.defaultTab
          : Object.keys(this.config.left.tabs)[0];
      }
      if (
        this.showLeftFilterCheckBox &&
        this.leftConfigFilterCheckBoxSource.items
      ) {
        this.leftFilterCheckBoxBooleanValue = this.generateBooleanFilter(
          this.leftConfigFilterCheckBoxSource.items,
          this.leftConfigFilterCheckBoxSource.items
        );
        this.updateLeftFilterCheckBoxArray();
      }
      if (this.config?.left?.meta?.filters) {
        this.fetchFilters(
          this.config.left.body.APIConfig.cubeName,
          'FETCH_FILTERS_V2',
          this.config?.left?.meta?.filters?.page || null
        );
        eventBus.$on(this.config.left.meta.filters.emit, () => {
          if (this.config.left.body?.APIConfig?.page) {
            this.config.left.body.APIConfig.page = 1;
          }
          this.getTable(this.config.left);
        });
      }
      this.getTable(this.config.left);
      this.setDefaultValues(this.config.default);
      this.getTable(this.config.right);
      eventBus.$on('onAdGroupsSelection', (data) => {
        this.onAdGroupsSelection(data);
      });
    },
    handleTabsClick(selectedTab, table) {
      this.leftTableSearch = '';
      this.activeTab = selectedTab;
      const tabFilter = this.config[table].tabs[selectedTab]?.localFilter;
      let localFilters = [...this.config[table]?.meta?.localFilters];
      localFilters = localFilters
        ?.map((localFilter) =>
          localFilter?.dimensionName === tabFilter?.dimensionName
            ? tabFilter
            : localFilter
        )
        .filter(
          (localFilter) =>
            !localFilter?.dimensionName ===
            this.config[table]?.tabs?.[selectedTab]?.search?.searchKey
        );
      this.config[table].meta.localFilters = localFilters;
      if (this.config[table].body?.APIConfig?.page) {
        this.config[table].body.APIConfig.page = 1;
      }

      this.config[table].body.columnDefs =
        this.config[table].tabs[selectedTab]?.columnDefs;
      this.getTable(this.config[table]);
    },
    getTable(data) {
      if (data?.meta?.action) {
        if (data?.actionAndGetterForEachTab) {
          data.selectedTab = this.activeTab;
          this.$store.dispatch(data.meta.action[this.activeTab], data);
          return;
        }
        this.$store.dispatch(data.meta.action, data);
      }
    },
    handleLeftTableSearch(data) {
      data = data?.trim();
      const searchKey =
        this.config.left?.tabs?.[this.activeTab]?.search?.searchKey ||
        this.config.left?.search?.searchKey;
      if (
        this.config.left?.tabs?.[this.activeTab].search?.customAPISearch ||
        this.config.left?.search?.customAPISearch
      ) {
        this.config.left.tabs[this.activeTab].search.value = data;
        this.getTable(this.config.left);
        return;
      }
      if (this.config.left?.tabs?.[this.activeTab]?.search?.isClientSide) {
        if (this.config?.left?.meta?.updateRows) {
          this.config?.left?.meta?.updateRows?.(this, searchKey, data);
        } else {
          this.leftTableRows = this.leftTableData.rows.filter((row) =>
            row[searchKey].toLowerCase().includes(data?.toLowerCase())
          );
        }
        return;
      }
      const filterValue = {
        dimensionName: searchKey,
        operator: 'ILIKE',
        dimensionValue: data
      };
      this.config.left.meta.localFilters =
        data === ''
          ? this.config.left.meta.localFilters.filter(
              (val) => val.dimensionName !== searchKey
            )
          : [
              ...this.config.left.meta.localFilters.filter(
                (localFilter) => !localFilter.operator
              ),
              filterValue
            ]; // remove the previous search filter and append the new one
      if (data === '') {
        // remove existing wherecluase after it is added
        if (
          this.config?.left?.body?.APIConfig?.where?.dimensionNameValueList
            ?.length
        ) {
          this.config.left.body.APIConfig.where.dimensionNameValueList =
            this.config.left.body.APIConfig.where.dimensionNameValueList.filter(
              (item) => item.dimensionName !== searchKey
            );
        }
      }
      if (this.config.left.body?.APIConfig?.page) {
        this.config.left.body.APIConfig.page = 1;
      }
      this.getTable(this.config.left);
    },
    updateEntityInformation(rightTableRows = this.rightTableRows) {
      let redrawTable = false;
      rightTableRows.forEach((rightTableRow) => {
        if (this.leftTableRows.length) {
          this.leftTableRows.forEach((leftTableRow) => {
            if (
              leftTableRow[this.config.primaryKey] ===
              rightTableRow[this.config.primaryKey]
            ) {
              leftTableRow.isRowSelected = true;
              redrawTable = true;
            }
          });
        }
      });
      if (redrawTable && !this.expandLeftTableConfig?.hasExpand) {
        this.leftTableInstance?.redrawTableRows?.();
      }
    },
    getLeftTableInstance(that) {
      this.leftTableInstance = that;
    },
    getRightTableInstance(that) {
      this.rightTableInstance = that;
    },
    setDefaultValues(val) {
      const defaultValues = val;
      this.defaultValues = defaultValues;
    },
    handleCrossClick() {
      if (this.localStorageFilterKey) {
        localStorage.removeItem(this.localStorageFilterKey);
      }
      this.$store.dispatch('clearTableStore');
      this.$emit('closePanelMethod');
    },
    refreshLeftTableIsRowSelected() {
      // This method is used to refresh the isRowSelected (ticks) on the left table
      // by comparing it to the items present in the right table rows
      const that = this;
      this.leftTableRows = this.leftTableRows.map((leftTableRow) => {
        const rightRowItemPresent = this.rightTableRows.find(
          (item) =>
            item[that.config.primaryKey] ===
            leftTableRow[that.config.primaryKey]
        );
        if (rightRowItemPresent) {
          leftTableRow.isRowSelected = true;
        } else {
          leftTableRow.isRowSelected = false;
        }
        return leftTableRow;
      });
      this.leftTableInstance.redrawTableRows();
    },
    removeEntity(row) {
      // ability to remove fetched right table rows
      const primaryKey = this.config.primaryKey;
      if (
        this.fetchedRightTableRows?.rows?.length &&
        !this.config?.right?.keepFetchedRightTableRows
      ) {
        const rows = this.fetchedRightTableRows.rows.filter((rightTableRow) => {
          if (row.params.data[primaryKey] !== rightTableRow[primaryKey]) {
            return rightTableRow;
          }
        });
        if (!isEqual(rows, this.fetchedRightTableRows.rows)) {
          this.fetchedRightTableRows = { rows, totalRows: rows.length };
        }
      } else if (this.config?.right?.keepFetchedRightTableRows) {
        const index = this.fetchedRightTableRows.rows.findIndex(
          (fetchedRow) => fetchedRow[primaryKey] === row.params.data[primaryKey]
        );
        if (index !== -1) {
          this.openSnackbar('Cannot remove already added items');
          return;
        }
      }
      const rightTableRowsShown = this.rightTableFilterApplied
        ? this.rightTableRowsCopy
        : this.computedRightTableRows;
      this.rightTableRows = rightTableRowsShown.filter(
        (rightTableRow) =>
          row.params.data[this.config.primaryKey] !==
          rightTableRow[this.config.primaryKey]
      );
      this.rightTableRowsCopy = this.rightTableRows;
      this.updateRightTableRowsBeforeReRendering();
      this.rightTableInstance.redrawTableRows();
      const that = this;
      if (!this.expandLeftTableConfig?.hasExpand) {
        this.leftTableRows = this.leftTableRows.map((leftTableRow) => {
          if (
            leftTableRow[that.config.primaryKey] ===
            row.params.data[that.config.primaryKey]
          ) {
            leftTableRow.isRowSelected = false;
          }
          return leftTableRow;
        });
      } else if (this.expandLeftTableConfig.hasExpand) {
        for (let leftTableRow of this.leftTableInstance.localTableRow) {
          if (
            leftTableRow[that.config.primaryKey] ===
            row.params.data[that.config.primaryKey]
          ) {
            leftTableRow.isRowSelected = false;
          }
        }
      }
      this.leftTableInstance.redrawTableRows();
      if (
        this.computedRightTableRows.length === 1 &&
        this.rightTableFilterApplied
      ) {
        this.rightTableFilterApplied = false;
      }
    },
    removeAll() {
      this.rightTableFilterApplied = false;
      if (this.expandLeftTableConfig.hasExpand) {
        for (let leftTableRow of this.leftTableInstance.localTableRow) {
          leftTableRow.isRowSelected = false;
        }
      } else {
        this.leftTableRows = this.leftTableRows.map((row) => {
          row.isRowSelected = false;
          return row;
        });
      }

      if (this.config?.right?.keepFetchedRightTableRows) {
        this.rightTableRows = this.fetchedRightTableRows.rows;
        this.updateEntityInformation();
      } else {
        this.rightTableRows = [];
        this.fetchedRightTableRows = { rows: [], totalRows: 0 };
      }
      this.rightTableInstance.redrawTableRows();
      this.leftTableInstance.redrawTableRows();
    },
    addAll() {
      const rowsToAddToRightTable = [];
      for (const row of this.leftTableRows) {
        let isRowPresent = false;
        // for campaign edit search terms add as target enter list
        if (this.config?.left?.boolUpdateLeftBid && !row?.isRowSelected) {
          row.new_bid = this.suggestedCustomBidInput;
        }
        row.isRowSelected = true;
        for (const rightTableRow of this.rightTableRows) {
          if (
            row[this.config.primaryKey] ===
            rightTableRow[this.config.primaryKey]
          ) {
            isRowPresent = true;
          }
        }
        if (!isRowPresent) rowsToAddToRightTable.push(row);
      }
      this.rightTableRows = [
        ...rowsToAddToRightTable,
        ...this.computedRightTableRows
      ];
      this.leftTableRows = [...this.leftTableRows];
      if (
        this.fetchedRightTableRows.rows.length &&
        !this.config.right.keepFetchedRightTableRows
      ) {
        const primaryKey = this.config.primaryKey;
        this.rightTableRows = this.rightTableRows.filter(
          (item) =>
            this.fetchedRightTableRows?.rows?.findIndex(
              (apiData) => apiData[primaryKey] === item[primaryKey]
            ) === -1
        );
      }
      this.updateRightTableRowsBeforeReRendering();
    },
    addEntity(row) {
      if (!row.params.data?.isRowSelected) {
        row.params.data.isRowSelected = true;
        // for campaign edit add as target enter list
        if (this.config?.left?.boolUpdateLeftBid) {
          row.params.data.new_bid = this.suggestedCustomBidInput;
        }
        this.leftTableInstance.redrawTableRows([row.params.node]);
      }
      let rowExistsInRightTable = false;
      const tempRightTableRows = [...this.computedRightTableRows];
      const that = this;
      this.computedRightTableRows.forEach((rightTableRow) => {
        if (
          rightTableRow[that.config.primaryKey] ===
          row.params.data[that.config.primaryKey]
        ) {
          rowExistsInRightTable = true;
        }
      });
      if (!rowExistsInRightTable) {
        tempRightTableRows.unshift(row.params.data);
        this.rightTableRows = tempRightTableRows;
        this.updateRightTableRowsBeforeReRendering();
        this.rightTableInstance.redrawTableRows();
      }
    },
    addEntityFromRawObjectArray(dataObjArray) {
      const tempRightTableRows = [...this.computedRightTableRows];
      this.rightTableRows = [...dataObjArray, ...tempRightTableRows];
    },
    openSnackbar(message) {
      const snackbarObject = {
        message: '',
        duration: 5000,
        actionText: ''
      };
      snackbarObject.message = message;
      this.$snackbar.open(snackbarObject);
    },
    async apply() {
      try {
        this.isLoading = true;
        const arrayToSend =
          this.rightTableInstance?.computedTableRows ?? this.rightTableRows;
        await this.config?.apply?.([...arrayToSend]);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    generateBooleanFilter(allItems, checkedItems) {
      const values = [];
      let check = false;
      allItems.forEach((item) => {
        check = false;
        checkedItems.forEach((checkedItem) => {
          if (item === checkedItem) {
            check = true;
          }
        });
        values.push(check);
      });
      return values;
    },
    generateCheckBoxArray(booleanValues, allItems) {
      const values = [];
      booleanValues.forEach((val, index) => {
        if (val) {
          values.push(allItems[index]);
        }
      });
      return values;
    },
    updateLeftFilterCheckBoxArray() {
      this.leftFilterCheckBoxArray = this.generateCheckBoxArray(
        this.leftFilterCheckBoxBooleanValue,
        this.leftConfigFilterCheckBoxSource.items
      );
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    handleLeftRowSelectionChange(selectedRowNodes) {
      this.leftRowSelectedRows = selectedRowNodes;
    },
    applyButtonDisabled() {
      if (this.config?.applyButtonDisabled) {
        return this.config?.applyButtonDisabled(this);
      }
      // If there is only left table and selection is enabled in left table, then enable the button only when the selections.length > 0
      if (
        !this.config?.right &&
        this.config?.left?.body?.rowSelection &&
        this.leftRowSelectedRows.length > 0
      ) {
        return false;
      }
      // disable action button if no rows are added to right table from left table
      return !this.rightTableRows.length;
    },
    checkValidityForApply() {
      this.$emit('rightTableRows', this.computedRightTableRows, this);
      const identifier = Math.random();
      this.identifier = identifier;
    },
    updateRightTableBid() {
      this.rightTableRows.forEach((rightTableRow) => {
        rightTableRow.new_bid = this.suggestedCustomBidInput;
      });
      this.rightTableInstance.redrawTableRows();
    },
    getFilteredRightTableRows() {
      return this.rightFilterCheckboxInHeader?.filterFn(
        this.rightTableRowsCopy
      );
    },
    onAdGroupsSelection(selections) {
      this.selectedAdGroups = selections;
    }
  }
};
</script>

<style lang="css">
.borderLeftNone {
  border-left: none;
}
.u-text-uppercase {
  text-transform: uppercase;
}
.custom-action-panel .radio-select-with-input {
  height: 21px !important;
}
.custom-action-panel .radio-select-with-input .dropdown-trigger .u-spacing-p-s {
  padding: 2px !important;
}
.custom-action-panel
  .radio-select-with-input
  .dropdown-trigger
  .icon-caret-down::before {
  color: #8b8f93;
  margin-right: 0px;
}
.custom-action-panel
  .radio-select-with-input
  .dropdown-trigger
  .icon-caret-down {
  margin-right: 0px;
}
.custom-action-panel .radio-select-with-input .rb-select {
  border-radius: 4px;
  height: 21px !important;
}
.custom-action-panel .radio-select-with-input .rb-input {
  height: 21px !important;
}
.custom-action-panel .radio-select-with-input .appender {
  height: 21px !important;
}
.custom-action-panel
  .radio-select-with-input
  .dropdown-radio-select-with-input {
  padding: 0px;
}
.custom-action-panel .hide-custom-action-panel-table-icon .tableCell-icon {
  display: none !important;
  visibility: none !important;
  pointer-events: node !important;
}
.custom-action-panel .height-custom-bid {
  height: 21px !important;
}
.custom-action-panel .height-custom-bid .rb-input {
  height: 21px !important;
}
.custom-action-panel.show-loader {
  height: 65vh;
}
.custom-action-panel .search-input .rb-input {
  display: flex;
  width: 100% !important;
  font-size: 1.5rem !important;
  background-color: #fff !important;
  color: #2b333b !important;
  border: 1px solid #e9eaeb !important;
}
.custom-action-panel .card > * {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.custom-action-panel .selected {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.custom-action-panel .selected + .search-input-container {
  margin-top: 2.4rem;
}
.custom-action-panel .rb-insights-table-container {
  height: 100%;
  flex: 1;
}
.custom-action-panel .tab {
  padding-top: 1.6rem;
}
.custom-action-panel .tableHeading {
  box-shadow: 0 1px 4px -1px #caccce;
  position: relative;
  z-index: 1;
  color: #4b5158;
}
.custom-action-panel .filter-check-box .rb-control-label {
  max-width: fit-content !important;
}
.custom-action-panel .appender {
  line-height: normal;
  height: 22px !important;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.custom-action-panel .appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.custom-action-panel .edit_suggested_campaign_keyword_targeting {
  bottom: var(--bottomPadding);
}
</style>
