import axios from 'axios';
import UrlFactory from '@/utils/services/url-factory';
import { store } from '@/store/store';
import moment from 'moment-timezone';
import constants from '@/utils/constants';
import { cloneDeep, omit, isEmpty } from 'lodash';
import dummyData from '@/utils/services/dummyData.js';
import hash from 'object-hash';

const getUrl = function (id, config) {
  // automatically replaces :param in a url like a/:param/b/;param
  let url = UrlFactory.getFinalURL(id);

  if (config?.append) {
    url = url + config.append;
  }
  if (config?.keyValueParams) {
    for (const keyValueParam in config.keyValueParams) {
      url =
        url + '?' + keyValueParam + '=' + config.keyValueParams[keyValueParam];
    }
  }
  if (!config?.pathParams) return url;
  const paramList = Object.keys(config.pathParams);
  for (const param of paramList) {
    const regex1 = '/:' + param + '/';
    const regex2 = '/:' + param + '$';
    url = url
      .replace(new RegExp(regex1, 'g'), '/' + config.pathParams[param] + '/')
      .replace(new RegExp(regex2, 'g'), '/' + config.pathParams[param]);
  }
  return url;
};

const updateRetailer = (request, retailerName) => {
  request.headers.retailer = retailerName;
  if (request.headers?.retailerId) {
    request.headers.retailerId = retailerName;
  }
};

const retailerNameMap = {
  walmart_criteo: {
    name: 'walmart',
    urlConfigKey: 'walmartCriteo',
    urlConfigSubKey: 'walmartAPIs'
  }
};

const entityValueConfig = {
  Amazon: {
    RESULTValue: 25.5,
    PVP_DIFF_percentage_sns_units: 6.5
  },
  Walmart: {
    RESULTValue: 15.2,
    PVP_DIFF_percentage_sns_units: 3.6
  },
  Target: {
    RESULTValue: 17.5,
    PVP_DIFF_percentage_sns_units: -0.5
  },
  Instacart: {
    RESULTValue: 10.7,
    PVP_DIFF_percentage_sns_units: 1.8
  }
};

const getConfigForEntityValue = (entityValue) => {
  const config = entityValueConfig[entityValue];
  if (config) {
    return {
      RESULTValue: config.RESULTValue,
      PVP: {
        PVP_DIFF_percentage_sns_units: config.PVP_DIFF_percentage_sns_units,
        PVP_percentage_sns_units: config.PVP_DIFF_percentage_sns_units,
        percentage_sns_units: config.PVP_DIFF_percentage_sns_units
      }
    };
  }
  return null;
};

// Specifically kept the hardcoded checks, so that it will be harder to make changes;
const productDemoHostNameList = [
  'product-demo.commerceiq.ai',
  'demo-staging.commerceiq.ai',
  'demo-staging-a.commerceiq.ai',
  'product-demo-a.commerceiq.ai'
];
const isProductDemoClient =
  productDemoHostNameList.indexOf(window.location.hostname) !== -1;

if (isProductDemoClient) {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Changes related to cross retailer
      try {
        if (config.headers.retailer === 'all') {
          config.headers.retailer = 'amazon';
          if (
            config.data?.entityType === 'dimension16' &&
            config.data?.page === 'BusinessInsights' &&
            config.data?.widget === 'benchMarking'
          ) {
            if (config.url.endsWith('data')) {
              config.replaceValuesFrom = 'dataAPI';
            } else if (config.url.endsWith('list')) {
              const x = {
                Amazon: 'tete7',
                Walmart: 'unikw',
                Target: 'unp0t',
                Instacart: 'unk2p',
                'Best Buy': 'upgca',
                "Lowe's": 'unknown',
                Costco: '9m6du',
                Gopuff: 'peshy',
                Petco: 'sphhv',
                Kroger: 'd4lto'
              };
              config.replaceValuesFrom = 'listAPI';
              config.data.where.dimensionNameValueList.map((item) => {
                item.dimensionValue = x[item.dimensionValue];
              });
            }
          }
        }
      } catch (error) {
        console.log(error, 'error');
      } finally {
        config = config;
      }
      return config;
    },
    function (error) {
      console.log(error);
      // Do something with request error
      return Promise.reject(error);
    }
  );
  axios.interceptors.request.use(
    function (request) {
      const request_copy = cloneDeep(request);
      const servicesToProxy = [
        'brands-service',
        'dashboard-service',
        'marketsharelite-service',
        'marketshare-service',
        'orchestrator-service',
        'entity-metadata-service/recommendation/getMetadata',
        '/api/proxy/marketshare-service/marketshare/asins/data'
      ];
      if (
        request?.data?.page !== 'MS_PRO_2.0-CATEGORY' ||
        request?.data?.page !== 'MS_PRO_2.0-EXECUTIVE'
      ) {
        servicesToProxy.filter((item) => item !== 'orchestrator-service');
      }
      if (
        servicesToProxy.some(
          (service) => request?.url?.indexOf(service) !== -1
        ) &&
        request?.data &&
        request?.data?.page !== 'REMCC-EXECUTIVE'
      ) {
        let last_updated_date = moment(
          moment(constants.last_updated_date).format('MM/DD/YYYY')
        );
        let today = moment(moment().format('MM/DD/YYYY'));
        let days = today.diff(last_updated_date, 'days');
        let regexp = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<date>[0-9]{2})/gm;
        if (
          request?.url?.indexOf(
            'entity-metadata-service/recommendation/getMetadata'
          ) !== -1
        ) {
          last_updated_date = moment(
            moment(constants.last_updated_date).format('YYYY-MM-DD')
          );
          today = moment(moment().format('YYYY-MM-DD'));
          days = today.diff(last_updated_date, 'days');
          regexp = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<date>[0-9]{2})/gm;
        }
        if (request.data) {
          const data = JSON.stringify(request.data);
          const matches = data.match(regexp) || [];
          if (matches.length > 0) {
            try {
              const data_copy = data.replace(regexp, function (match) {
                return moment(match)
                  .subtract(days, 'days')
                  .format('YYYY-MM-DD');
              });
              request_copy.data = JSON.parse(data_copy);
            } catch (error) {
              console.log(error);
              return request_copy;
            }
          }
        }
      }
      return request_copy;
    },
    function (err) {
      if (err?.response?.status === 401 && store.getters.getSessionValidity) {
        store.commit(
          'LOGOUT',
          'returnslink=' + encodeURIComponent(window.location)
        );
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    }
  );
  axios.interceptors.request.use(function (request) {
    const requestCopy = cloneDeep(request);
    try {
      if (
        constants.defaultRetailerWhiteListPages.includes(requestCopy.data?.page)
      ) {
        requestCopy.headers.retailer = constants.defaultGlobalRetailer;
      }
    } catch (error) {
      console.log('Http-service interceptor err', error);
    }
    return requestCopy;
  });
  axios.interceptors.response.use(function (response) {
    try {
      const locationHREF = '/all/retail/cross-retailer-dashboard';
      if (window.location.href.includes(locationHREF)) {
        const x = {
          tete7: 'Amazon',
          unikw: 'Walmart',
          unp0t: 'Target',
          unk2p: 'Instacart',
          upgca: 'Best Buy',
          unknown: "Lowe's",
          '9m6du': 'Costco',
          peshy: 'Gopuff',
          sphhv: 'Petco',
          d4lto: 'Kroger'
        };

        if (
          response.config.url.endsWith('widgets/list') &&
          response.config?.data?.includes('globalViewId') &&
          response.config?.data?.includes(1320)
        ) {
          response.data.widgets.forEach((item) => {
            if (item.widgetName === 'benchMarking') {
              item.metadata.BusinessInsights.benchMarkingTableSelectedMetricList =
                {
                  sales: {
                    ordered_product_sales: {
                      key: 'ordered_product_sales'
                    },
                    ordered_units: {
                      key: 'ordered_units'
                    },
                    gross_retailer_margin_perc: {
                      key: 'gross_retailer_margin_perc'
                    },
                    percentage_sns_units: {
                      key: 'percentage_sns_units'
                    }
                  },
                  advertising: {
                    spend: {
                      key: 'spend'
                    },
                    generic_total_count_page_1: {
                      key: 'generic_total_count_page_1'
                    },
                    paid_sales_14d: {
                      key: 'paid_sales_14d'
                    },
                    roi: {
                      key: 'roi'
                    },
                    ds_glance_views: {
                      key: 'ds_glance_views'
                    }
                  },
                  operations: {
                    po_fill_rate: {
                      key: 'po_fill_rate'
                    },
                    po_confirmation_rate: {
                      key: 'po_confirmation_rate'
                    },
                    revenue_lost_unavailable: {
                      key: 'revenue_lost_unavailable'
                    }
                  }
                };
            }
            if (item.widgetName === 'chartingWb') {
              item.metadata.shownMetricList.splice(
                1,
                1,
                'percentage_sns_units'
              );
            }
          });
        }

        // Read api response change
        if (
          response.config.url.endsWith('read') &&
          response.config?.data?.includes('benchMarking')
        ) {
          response.data.customMetadata.dropdownOptions.forEach((item) => {
            if (item.dimensionLabel === 'Vendor Code') {
              item.dimensionLabel = 'Retailers';
            }
          });
          response.data.metrics.ds_glance_views.label = 'Incremental ROAS';
          response.data.metrics.ds_glance_views.metricGroup = 'Advertising';
          response.data.metrics.ds_glance_views.metadata = {
            isInverted: false,
            type: 'NUMERIC',
            unit: 'CURRENCY'
          };
          response.data.metrics.ds_glance_views.views = [
            {
              label: 'ADVERTISING',
              entityType: 'COMMON',
              selected: false,
              view: 'advertising'
            }
          ];
          response.data.metadata.defaultMetrics.advertising.push(
            'ds_glance_views'
          );
          response.data.metadata.defaultMetrics.sales =
            response.data.metadata.defaultMetrics.sales.filter(
              (item) => item !== 'ds_glance_views'
            );
          console.log(response.data, 'response.data');
        }
        if (
          response.config.url.endsWith('read') &&
          response.config?.data?.includes('benchMarking')
        ) {
          response.data.customMetadata.dropdownOptions.forEach((item) => {
            if (item.dimensionLabel === 'Vendor Code') {
              item.dimensionLabel = 'Retailers';
            }
          });
          response.data.metrics.percentage_sns_units.label = 'Market Share';
          response.data.metrics.percentage_sns_units.tooltip = 'Market Share';

          response.data.metadata.defaultMetrics.sales.push(
            'percentage_sns_units'
          );
        }

        if (
          response.config.url.endsWith('read') &&
          response.config?.data?.includes('chartingWb')
        ) {
          response.data.metadata.defaultConfig.shownMetricList.splice(
            1,
            1,
            'percentage_sns_units'
          );
          response.data.metrics.percentage_sns_units.label = 'Market Share';
          response.data.metrics.percentage_sns_units.tooltip = 'Market Share';
          response.data.metrics.percentage_sns_units.iqTooltip = {};
        }

        // ChartingWB Changes for cross retailers
        if (
          response.config.url.endsWith('data') &&
          response.config?.data?.includes('#ALLOVER_AGGREGATE') &&
          response.config?.data?.includes('chartingWb')
        ) {
          response.data.entityData.forEach((item) => {
            item.data.forEach((dataElm) => {
              if (dataElm.name === 'roi') {
                dataElm.PVP = {
                  PVP_roi: 13.17,
                  PVP_DIFF_roi: -0.89,
                  roi: 6.76
                };
              }
              if (dataElm.name === 'ordered_units') {
                dataElm.PVP = {
                  ordered_units: 1865227.95,
                  PVP_ordered_units: 10.41,
                  PVP_DIFF_ordered_units: -194259.82
                };
              }
              if (dataElm.name === 'shipped_revenue') {
                dataElm.PVP = {
                  shipped_revenue: 24028165.42429461,
                  PVP_shipped_revenue: 8.16,
                  PVP_DIFF_shipped_revenue: -1961320.62
                };
              }
              if (dataElm.name === 'percentage_sns_units') {
                dataElm.RESULT.percentage_sns_units = '21';
                dataElm.PVP = {
                  percentage_sns_units: 5.5,
                  PVP_percentage_sns_units: 5.5,
                  PVP_DIFF_percentage_sns_units: 5.5
                };
              }
              if (
                dataElm.name === 'generic_total_count_page_1_share_percentage'
              ) {
                dataElm.PVP = {
                  PVP_DIFF_generic_total_count_page_1_share_percentage: 6.87,
                  PVP_generic_total_count_page_1_share_percentage: -41.27,
                  generic_total_count_page_1_share_percentage: 33.61
                };
              }
              if (dataElm?.RESULT) {
                if (dataElm.RESULT?.ordered_product_sales) {
                  dataElm.RESULT.ordered_product_sales = '20288953.93';
                  dataElm.PVP = {
                    PVP_DIFF_ordered_product_sales: 11.54,
                    PVP_ordered_product_sales: 11.54,
                    ordered_product_sales: 11.54
                  };
                } else if (dataElm.RESULT?.spend) {
                  dataElm.RESULT.spend = '207146.64';
                }
              }
              if (dataElm?.PVP) {
                if (dataElm.PVP?.PVP_spend) {
                  dataElm.PVP.PVP_spend = '3.29';
                }
              }
            });
          });
        }

        if (
          response.config.replaceValuesFrom &&
          response.config.replaceValuesFrom === 'dataAPI'
        ) {
          response.data.entityData.map((item) => {
            item.entityValue = x[item.entityValue];
          });
        } else if (
          response.config.replaceValuesFrom &&
          response.config.replaceValuesFrom === 'listAPI'
        ) {
          // logic for timeseies
          const payload = JSON.parse(response.config?.data);
          if (payload.operations?.timeseriesEnabled === true) {
            if (
              payload.metricsList[0] ===
              'sales_dashboard__ordered_product_sales'
            ) {
              response.data.entityData.push(
                ...cloneDeep(dummyData.OPS_Timeseries)
              );
            }
          }
          if (payload.operations?.timeseriesEnabled === true) {
            if (payload.metricsList[0] === 'roi') {
              response.data.entityData.push(
                ...cloneDeep(dummyData.ROAS_Timeseries)
              );
            }
          }

          if (
            payload.operations?.timeseriesEnabled === false &&
            response.config?.data.includes('generic_total_count_page_1')
          ) {
            response.data.entityData.push(
              ...cloneDeep(dummyData.crossRetailerSOV)
            );
          }

          if (payload.operations?.timeseriesEnabled === true) {
            if (payload.metricsList[0] === 'generic_total_count_page_1') {
              response.data.entityData.push(
                ...cloneDeep(dummyData.SOV_Timeseries)
              );
            }
          }

          response.data.entityData.map((item) => {
            item.entityValue = x[item.entityValue];
            const metricsList = [
              'ordered_product_sales',
              'ordered_units',
              'spend',
              'roi',
              'paid_sales_14d'
            ];
            item.data.map((item1) => {
              if (item1.RESULT) {
                item1.RESULT.dimension16 = x[item1.RESULT.dimension16];
              }
              // if (metricsList.includes(item1.name)) {
              if (item1.name === 'ordered_product_sales') {
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 15055066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_product_sales: 11.54,
                    PVP_ordered_product_sales: 11.54,
                    ordered_product_sales: 11.54
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 2755066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_product_sales: 10.69,
                    PVP_ordered_product_sales: 10.69,
                    ordered_product_sales: 10.69
                  };
                }

                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 2345066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_product_sales: 0.2,
                    PVP_ordered_product_sales: 0.2,
                    ordered_product_sales: 0.2
                  };
                }

                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 555066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_product_sales: 27.2,
                    PVP_ordered_product_sales: 27.2,
                    ordered_product_sales: 27.2
                  };
                }
              }

              if (item1.name === 'percentage_sns_units') {
                const config = getConfigForEntityValue(item.entityValue);
                if (config) {
                  item1.RESULT[item1.name] = config.RESULTValue;
                  item1.PVP = { ...config.PVP };
                }
              }

              if (item1.name === 'ordered_units') {
                // ordered_units
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 1315066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_units: 12.03,
                    PVP_ordered_units: 12.03,
                    ordered_units: 12.03
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 294066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_units: 9.97,
                    PVP_ordered_units: 9.97,
                    ordered_units: 9.97
                  };
                }

                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 185066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_units: 1.834,
                    PVP_ordered_units: 1.834,
                    ordered_units: 1.834
                  };
                }

                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 185066.93;
                  item1.PVP = {
                    PVP_DIFF_ordered_units: 22,
                    PVP_ordered_units: 22,
                    ordered_units: 22
                  };
                }
              }

              if (item1.name === 'spend') {
                // spend
                if (item.entityValue === 'Amazon') {
                  // 761450
                  item1.RESULT[item1.name] = 151450;
                  item1.PVP = {
                    PVP_DIFF_spend: 2.4,
                    PVP_spend: 2.4,
                    spend: 2.4
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 97070;
                  item1.PVP = {
                    PVP_DIFF_spend: 3.61,
                    PVP_spend: 3.61,
                    spend: 3.61
                  };
                }

                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 44170;
                  item1.PVP = {
                    PVP_DIFF_spend: 1.12,
                    PVP_spend: 1.12,
                    spend: 1.12
                  };
                }

                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 30150;
                  item1.PVP = {
                    PVP_DIFF_spend: -7.7,
                    PVP_spend: -7.7,
                    spend: -7.7
                  };
                }
              }

              // 'roi', 'paid_sales_14d'
              if (item1.name === 'roi') {
                // spend
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 5.09;
                  item1.PVP = {
                    PVP_DIFF_roi: 12.36,
                    PVP_roi: 12.36,
                    roi: 12.36
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 7.2;
                  item1.PVP = {
                    PVP_DIFF_roi: 6.9,
                    PVP_roi: 6.9,
                    roi: 6.9
                  };
                }

                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 5.97;
                  item1.PVP = {
                    PVP_DIFF_roi: 0.9,
                    PVP_roi: 0.9,
                    roi: 0.9
                  };
                }

                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 9.09;
                  item1.PVP = {
                    PVP_DIFF_roi: 12.1,
                    PVP_roi: 12.1,
                    roi: 12.1
                  };
                }
              }

              // paid_sales_14d
              if (item1.name === 'paid_sales_14d') {
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 761450.93;
                  item1.PVP = {
                    PVP_DIFF_paid_sales_14d: 13.45,
                    PVP_paid_sales_14d: 13.45,
                    paid_sales_14d: 13.45
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 698982;
                  item1.PVP = {
                    PVP_DIFF_paid_sales_14d: 10.23,
                    PVP_paid_sales_14d: 10.23,
                    paid_sales_14d: 10.23
                  };
                }

                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 263393;
                  item1.PVP = {
                    PVP_DIFF_paid_sales_14d: 5.57,
                    PVP_paid_sales_14d: 5.57,
                    paid_sales_14d: 5.57
                  };
                }

                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 274393.93;
                  item1.PVP = {
                    PVP_DIFF_paid_sales_14d: 11.2,
                    PVP_paid_sales_14d: 11.2,
                    paid_sales_14d: 11.2
                  };
                }
              }

              // "revenue_lost_unavailable"
              if (item1.name === 'revenue_lost_unavailable') {
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 426766;
                  item1.PVP = {
                    PVP_DIFF_revenue_lost_unavailable: 37.87,
                    PVP_revenue_lost_unavailable: 37.87,
                    revenue_lost_unavailable: 37.87
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 789566;
                  item1.PVP = {
                    PVP_DIFF_revenue_lost_unavailable: 23.2,
                    PVP_revenue_lost_unavailable: 23.2,
                    revenue_lost_unavailable: 23.2
                  };
                }
              }

              if (item1.name === 'gross_retailer_margin_perc') {
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 22.29;
                  item1.PVP = {
                    PVP_DIFF_gross_retailer_margin_perc: -9.3,
                    PVP_gross_retailer_margin_perc: -9.3,
                    gross_retailer_margin_perc: -9.3
                  };
                }
              }

              if (
                item1.name === 'generic_total_count_page_1_share_percentage'
              ) {
                if (item.entityValue === 'Amazon') {
                  item1.PVP = {
                    PVP_DIFF_generic_total_count_page_1_share_percentage: 2.67,
                    PVP_generic_total_count_page_1_share_percentage: 2.67,
                    generic_total_count_page_1_share_percentage: 2.67
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.PVP = {
                    PVP_DIFF_generic_total_count_page_1_share_percentage: 1.27,
                    PVP_generic_total_count_page_1_share_percentage: 1.27,
                    generic_total_count_page_1_share_percentage: 1.27
                  };
                }
              }
              // "ds_glance_views"

              if (item1.name === 'ds_glance_views') {
                if (item.entityValue === 'Amazon') {
                  item1.RESULT[item1.name] = 4.62;
                  item1.PVP = {
                    PVP_DIFF_ds_glance_views: 2.67,
                    PVP_ds_glance_views: 2.67,
                    ds_glance_views: 2.67
                  };
                }
                if (item.entityValue === 'Walmart') {
                  item1.RESULT[item1.name] = 6.15;
                  item1.PVP = {
                    PVP_DIFF_ds_glance_views: 1.3,
                    PVP_ds_glance_views: 1.3,
                    ds_glance_views: 1.3
                  };
                }
                if (item.entityValue === 'Target') {
                  item1.RESULT[item1.name] = 5.73;
                  item1.PVP = {
                    PVP_DIFF_ds_glance_views: 0.8,
                    PVP_ds_glance_views: 0.8,
                    ds_glance_views: 0.8
                  };
                }
                if (item.entityValue === 'Instacart') {
                  item1.RESULT[item1.name] = 8.41;
                  item1.PVP = {
                    PVP_DIFF_ds_glance_views: 0.3,
                    PVP_ds_glance_views: 0.3,
                    ds_glance_views: 0.3
                  };
                }
              }
            });
          });
        }
      }
      if (window.location.href.includes('us/amazon/retail/business_overview')) {
        if (
          response.config?.url ===
          '/api/proxy/dashboard-service/entity/metrics/data/list'
        ) {
          if (response.config?.data?.includes('generic_total_count_page_1')) {
            response.data.entityData.push(...dummyData.BOBase);
          }
        }
      }

      // Reporting Specific changes
      const reportingRoute = '/kiosk/MBRs';
      if (
        window.location.href.includes(reportingRoute) &&
        response.config.url.endsWith('metrics/data') &&
        response.config?.data?.includes('MonthlyBusinessReport_5056') &&
        response.config?.data?.includes('performanceTrend2_5060') &&
        response.config?.data?.includes(
          'sales_dashboard__ordered_product_sales'
        )
      ) {
        response.data.entityData.forEach((dataElm) => {
          dataElm.data.forEach((item) => {
            if (item.name === 'ordered_product_sales') {
              item.PVP = {
                ordered_product_sales: 16421966.57,
                PVP_ordered_product_sales: 5.34,
                PVP_DIFF_ordered_product_sales: -2053188.55
              };
            }
          });
        });
      }
    } catch (error) {
      console.log(error, 'error');
    } finally {
      response = response;
    }
    return response;
  });
  axios.interceptors.response.use(
    function (response) {
      // Date shifter logic applied
      const response_copy = cloneDeep(response);
      if (response?.data) {
        var last_updated_date = moment(
          moment(constants.last_updated_date).format('MM/DD/YYYY')
        );
        var today = moment(moment().format('MM/DD/YYYY'));
        var days = today.diff(last_updated_date, 'days');
        const regexp_yyyymmdd =
          /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<date>[0-9]{2})/gm;
        const regexp_mmddyyyy =
          /(?<month>[0-9]{2})\/(?<date>[0-9]{2})\/(?<year>[0-9]{4})/g;
        if (response.data) {
          let data = JSON.stringify(response.data);
          let data_copy = data;
          const matches_yyyymmdd = data.match(regexp_yyyymmdd) || [];
          if (matches_yyyymmdd.length > 0) {
            try {
              data_copy = data.replace(regexp_yyyymmdd, function (match) {
                let date = moment(match).add(days, 'days').format('YYYY-MM-DD');
                const today_date = moment().format('YYYY-MM-DD');
                if (!moment(today_date).isSameOrAfter(date)) {
                  date = moment().format('YYYY-MM-DD');
                }
                return date;
              });
              data = data_copy;
              response_copy.data = JSON.parse(data_copy);
            } catch (error) {
              return response_copy;
            }
          }
          let payload = JSON.stringify(response.config?.data || {});
          payload = JSON.parse(payload);
          const isMonth = payload?.operations?.timeseriesRollupBy === 'WEEK';
          const isMarketInsights = window.location.href.includes(
            '/digital_shelf/market_insights'
          );
          const isWalmart = window.location.href.includes(
            '/walmart/digital_shelf/market_share'
          );
          const matches_mmddyyyy = data.match(regexp_mmddyyyy) || [];
          if (matches_mmddyyyy.length > 0) {
            try {
              const timeseriesRollupBy =
                JSON.parse(payload)?.operations?.timeseriesRollupBy;
              data_copy = data.replace(regexp_mmddyyyy, function (match) {
                let date = moment(match).add(days, 'days').format('MM/DD/YYYY');
                if (timeseriesRollupBy === 'WEEK') {
                  if (isMarketInsights) {
                    date = moment(date).startOf('isoWeek').format('MM/DD/YYYY');
                  } else {
                    date = moment(date).startOf('week').format('MM/DD/YYYY');
                  }
                } else if (timeseriesRollupBy === 'MONTH') {
                  date = moment(date).startOf('month').format('MM/DD/YYYY');
                }
                if (
                  response.data?.response?.metadata?.cube?.name !==
                    'budget_planner_dashboard' &&
                  response.data?.response?.metadata?.cube?.name !==
                    'budget_planner_summary_consolidation' &&
                  response.data?.response?.metadata?.cube?.name !==
                    'v2_forecast_calender_options'
                ) {
                  const today_date = moment().format('MM/DD/YYYY');
                  if (!moment(today_date).isSameOrAfter(date)) {
                    date = moment().format('MM/DD/YYYY');
                  }
                }
                return date;
              });
              response_copy.data = JSON.parse(data_copy);
            } catch (error) {
              return response_copy;
            }
          }
        }
      }
      return response_copy;
    },
    function (err) {
      if (err?.response?.status === 401 && store.getters.getSessionValidity) {
        store.commit(
          'LOGOUT',
          'returnslink=' + encodeURIComponent(window.location)
        );
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    }
  );
  // Temp fix until walmart_criteo, chewy_promoteiq market insights is onboarded,
  // routing all walmart_criteo. chewy_promoteiq MI requests to walmart, chewy.
  const clientName = localStorage.getItem('clientName');
  if (clientName) {
    axios.defaults.headers.client = clientName;
  }
  const retailersToCheck = Object.keys(retailerNameMap);
  const shouldRouteMIRequests = retailersToCheck.some((retailer) =>
    window.location.href.includes(retailer)
  );
  if (shouldRouteMIRequests) {
    axios.interceptors.request.use(function (request) {
      try {
        const retailer = request?.headers?.retailer;
        if (retailersToCheck.includes(retailer)) {
          const { name, urlConfigKey, urlConfigSubKey } =
            retailerNameMap[retailer];
          const sovPage = 'sov';
          const shouldModifyRetailer =
            request?.data?.page?.toLowerCase() === sovPage ||
            request?.data?.pageName === sovPage ||
            constants[urlConfigKey][urlConfigSubKey].includes(request?.url);
          if (shouldModifyRetailer) {
            updateRetailer(request, name);
            return request;
          }
        }
        return request;
      } catch (e) {
        return request;
      }
    });
  }
} else {
  // Temp fix until walmart_criteo, chewy_promoteiq market insights is onboarded,
  // routing all walmart_criteo. chewy_promoteiq MI requests to walmart, chewy.
  const clientName = localStorage.getItem('clientName');
  if (clientName) {
    axios.defaults.headers.client = clientName;
  }
  const retailersToCheck = Object.keys(retailerNameMap);
  const shouldRouteMIRequests = retailersToCheck.some((retailer) =>
    window.location.href.includes(retailer)
  );
  if (shouldRouteMIRequests) {
    axios.interceptors.request.use(function (request) {
      try {
        const retailer = request?.headers?.retailer;
        if (retailersToCheck.includes(retailer)) {
          const { name, urlConfigKey, urlConfigSubKey } =
            retailerNameMap[retailer];
          const sovPages = ['sov', 'sov_intraday'];
          const shouldModifyRetailer =
            sovPages.includes(request?.data?.page?.toLowerCase()) ||
            sovPages.includes(request?.data?.pageName) ||
            constants[urlConfigKey][urlConfigSubKey].includes(request?.url);
          if (shouldModifyRetailer) {
            updateRetailer(request, name);
            return request;
          }
        }
        return request;
      } catch (e) {
        return request;
      }
    });
  }

  axios.interceptors.request.use(function (request) {
    try {
      if (
        window.configCheck &&
        window?.configCheck('client.feature.dbxSetup.enable', window?.configs)
      ) {
        const retailer = request?.headers?.retailer;
        const cubeNameList = [
          'ams_skus_fact_table_custom_strategy_builder',
          'campaigns_fact_table_custom_strategy_builder',
          'keywords_fact_table_custom_strategy_builder',
          'search_term_fact_table_custom_strategy_builder'
        ];
        let currentCube = request?.data?.cubeName;
        if (retailer === 'amazon' && cubeNameList.includes(currentCube)) {
          currentCube = currentCube + '_ui';
          request.data.cubeName = currentCube;
        }
      }
      return request;
    } catch (e) {
      return request;
    }
  });

  // For usual clients.
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err?.response?.status === 401 && store.getters.getSessionValidity) {
        store.commit(
          'LOGOUT',
          'returnslink=' + encodeURIComponent(window.location)
        );
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    }
  );
}

export default {
  all: axios.all,
  cancelMap: {},
  cancelAndGetFreshController(cancelConfig) {
    let widgetCancelMap = this.cancelMap[cancelConfig.widgetId];
    if (widgetCancelMap) {
      if (cancelConfig.cancelAll) {
        Object.values(widgetCancelMap).forEach((controller) => {
          controller.abort();
        });
      } else {
        widgetCancelMap[cancelConfig.hashKey]?.abort();
      }
    } else {
      this.cancelMap[cancelConfig.widgetId] = {};
      widgetCancelMap = this.cancelMap[cancelConfig.widgetId];
    }
    let cancelController = new AbortController();
    widgetCancelMap[cancelConfig.hashKey] = cancelController;
    return cancelController;
  },
  clearController(cancelConfig) {
    let widgetCancelMap = this.cancelMap[cancelConfig.widgetId];
    delete widgetCancelMap[cancelConfig.hashKey];
    if (isEmpty(widgetCancelMap)) {
      delete this.cancelMap[cancelConfig.widgetId];
    }
  },
  get(id, config) {
    return axios.get(getUrl(id, config), config);
  },
  post(id, data, config, cancelConfig) {
    if (cancelConfig) {
      cancelConfig.hashKey = hash({
        url: getUrl(id, config),
        payload: omit(data, cancelConfig.hashingKeysToIgnore)
      });
      let cancelController = this.cancelAndGetFreshController(cancelConfig);
      return axios
        .post(getUrl(id, config), data, {
          ...config,
          signal: cancelController.signal
        })
        .then((v) => {
          this.clearController(cancelConfig);
          return v;
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            throw Error('Request cancelled', { cause: e });
          } else {
            this.clearController(cancelConfig);
            throw e;
          }
        });
    }
    return axios.post(getUrl(id, config), data, config);
  },
  put(id, data, config) {
    return axios.put(getUrl(id, config), data, config);
  },
  patch(id, data, config) {
    return axios.patch(getUrl(id, config), data, config);
  },
  delete(id, data, config) {
    return axios.delete(getUrl(id, config), { data: data }, config);
  },
  isCancelRequestError(error) {
    return axios.isCancel(error.cause);
  }
};
