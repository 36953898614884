<template>
  <div>
    <div>
      <span
        ref="trigger"
        v-tippy="tippyOptions"
        role="button"
      >
        <slot name="trigger">
          <rb-button
            id="newView"
            :loading="isLoading"
            :text="title"
            :type="'hollow'"
            class="u-spacing-ml-s u-padding-extra hollow-outlined-btn u-bg-color-transparent-base"
          />
        </slot>
      </span>
      <div
        id="templateContainer"
        ref="previous_days_popover"
        class="popover"
      >
        <div class="popover-header">
          <span class="u-display-flex u-flex-align-items-center">
            <span class="u-color-grey-white">{{ title }}</span>
          </span>
          <span>
            <div @click="toggle">
              <rb-icon
                icon="cross"
                class="rb-icon--small u-color-grey-white u-cursor-pointer"
              />
            </div>
          </span>
        </div>
        <div
          class="u-display-flex u-display-flex u-flex-direction-column u-spacing-mt-l u-spacing-mh-m"
        >
          <input
            v-model="name"
            data-cy="generic-input-tippy-input"
            class="u-border-radius-s u-border-width-s u-font-size-6 u-border-all u-width-100 u-line-height-1-5 u-spacing-pt-s u-spacing-pb-s u-border-color-grey-xxx-light outline_none"
            type="text"
          />
          <span
            class="u-font-size-8 u-display-flex u-spacing-mt-xs u-spacing-mb-s"
            >View will be accessible to the team.</span
          >
        </div>
        <div
          class="u-font-size-7 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-spacing-mh-m u-spacing-mt-xs u-spacing-mb-s"
        >
          <rb-icon icon="info-circle-fill rb-icon--small" />
          <span
            >Dashboard filters, date selector and widget settings will be
            saved.</span
          >
        </div>

        <div
          class="u-display-flex u-flex-justify-content-flex-end u-spacing-pb-m u-spacing-pr-m row-top-border u-spacing-pt-m"
        >
          <rb-button
            data-cy="generic-input-tippy-apply"
            size="s"
            :click-fn="onApply"
            type="filled"
            :disabled="!name"
            text="Apply"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputSelect',
  props: {
    title: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isActive: false,
      name: '',
      tippyOptions: {
        html: '#templateContainer',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        distance: -5,
        offset: '20, 0',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      }
    };
  },
  created() {
    this.name = '';
  },
  methods: {
    enableApply() {
      return true;
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
    },
    onTippyCreate(instance) {
      this.tippyInstance = instance;
    },
    toggle($event) {
      if (this.tippyInstance) {
        if (this.tippyInstance.state.visible) {
          this.tippyInstance.hide();
        } else {
          this.tippyInstance.show();
        }
      }
    },
    onApply() {
      this.$emit('onUpdateView', 'save', this.name);
      this.name = '';
      this.tippyInstance.hide();
    }
  }
};
</script>

<style lang="css" scoped>
.popover {
  min-width: 250px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  justify-content: space-between;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b333b;
  display: flex;
  flex-direction: column;
}
.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.row-top-border {
  border-top: solid 1px #e9eaeb;
}

.outline_none {
  outline: none;
}

.u-padding-extra {
  padding: 12px 32px;
}
</style>
