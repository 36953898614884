import HttpService from '@/utils/services/http-service';
import { routeDecider } from '../router/routeDecider';
import { store } from '@/store/store';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import { allowedRegion } from '@/router/dynamicRoutes.js';

export const actions = {
  sendMail: (context, data) => {
    return new Promise((resolve, reject) => {
      HttpService.post('EMAIL', data)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            reject();
          }
        });
    });
  },
  getSessionData: (context, routeParams) => {
    if (!store.getters.getSessionUser) {
      HttpService.get('SESSION_DATA')
        .then((response) => {
          if (
            response.data &&
            response.data.user &&
            response.data.user.state &&
            response.data.user.state === 'unauthorised'
          ) {
            routeDecider(null, routeParams);
          } else {
            context.commit('LOGIN_SUCCESS', response);
            context.commit('STORE_USER_CONFIG', response.data);
            context.commit('SET_FEATURE_ENABLE_CONFIG', {
              pages: Vue.options.filters.config_check('feature.pages'),
              insights: Vue.options.filters.config_check('feature.insights'),
              campaignManagement: Vue.options.filters.config_check(
                'feature.campaign_management'
              ),
              budgetPlanner: Vue.options.filters.config_check(
                'feature.budget_planner'
              ),
              budgetPlannerV2: Vue.options.filters.config_check(
                'feature.budget_planner_v2'
              ),
              recommendations: Vue.options.filters.config_check(
                'feature.recommendations'
              ),
              simulation: utils.internalUserCheck(window.user),
              workbench: Vue.options.filters.config_check('feature.workbench'),
              dw: Vue.options.filters.config_check('feature.dw'),
              ...(Vue.options.filters.config_check(
                'feature.walmart_campaign_management'
              )
                ? {
                    walmart_campaign_management:
                      Vue.options.filters.config_check(
                        'feature.walmart_campaign_management'
                      )
                  }
                : [])
            });
            context.commit('SET_MARKETPLACE_DATA', {
              marketPlace:
                Vue.options.filters.config_check('marketplace') || 'Amazon',
              outsideIn: Vue.options.filters.config_check(
                'feature.outsideIn.enable'
              )
            });
            // context.dispatch('getCatalogAsins');
            context.dispatch('commonSetTimezone');
            if (routeParams?.path) {
              let routePath = routeParams.path.split('/');
              if (routePath.length >= 4) {
                if (allowedRegion.indexOf(routePath[1]) !== -1) {
                  routePath = routePath.slice(0, 4).join('/') + '/';
                  context.dispatch('setRootPath', routePath);
                } else {
                  // Path is different from /us or /uk or /ca. So resetting the path to root path.
                  routeParams.path = '/';
                  context.dispatch('setRootPath');
                }
              } else {
                context.dispatch('setRootPath');
              }
            } else {
              context.dispatch('setRootPath');
            }

            routeDecider(response, routeParams);
            if (window.environment === 'prod') {
              window.heap.identify(response.data.user.email);
              window.heap.addUserProperties({
                email: response.data.user.email,
                roles: response.data.user.roles,
                clientName: window.clientName
              });
            }
          }
        })
        .catch(() => {
          routeDecider(null, routeParams);
        });
    }
  }
};
