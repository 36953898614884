<template>
  <div class="u-display-flex u-height-100 u-width-100">
    <div
      class="u-width-50 fillContainerHeight u-display-flex u-flex-direction-column u-spacing-pv-xxl u-spacing-pl-xxl u-overflow-y-auto"
    >
      <budgetPlannerHeader
        class="u-flex-0"
        :step="headerStepConfig"
      />
      <div
        v-if="masterTableData"
        class="u-flex-1 bp-preview-table-container u-overflow-auto"
        style="width: calc(100% - 48px)"
      >
        <rb-insights-table
          :config="((config || {}).widgets || {})['masterTableWidget'] || {}"
          :grid-options="
            (((config || {}).widgets || {})['masterTableWidget'] || {})
              .gridOptions
          "
          :table-column="
            (((config || {}).widgets || {})['masterTableWidget'] || {})
              .columnDefs
          "
          :row-class-rules="rowClassRules"
          :table-row="masterTableData"
          :row-height="45"
          :enable-client-side-sorting="false"
          :has-expand="true"
          :expand-cell-width="65"
          :levels="
            (((config || {}).widgets || {})['masterTableWidget'] || {}).levels
          "
          table-id="budget-preview-master"
          :has-default-row-expand="true"
          :get-current-instance="getCurrentInstance"
        />
      </div>
      <budgetPlannerFooter
        class="u-flex-0 u-spacing-pt-xxl"
        custom-class-left="fs-budget-plan-preview-back"
        custom-class-right="fs-budget-plan-preview-next"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </div>
    <div
      class="u-width-50 fillContainerHeight u-display-flex u-flex-direction-column u-spacing-p-xxl u-overflow-y-auto"
      style="background: #f5f7fa"
    >
      <budgetPlannerHeader
        class="u-flex-0"
        :step="config && config.widgets && config.widgets.slaveTableWidget"
        :default-margin-bottom="false"
      />
      <p
        v-if="selectedScope"
        class="u-font-size-5 u-color-grey-mid-light u-font-weight-600 u-spacing-pb-xs distributionMarginTop"
      >
        {{ selectedScopeType }} {{ selectedScope }}
      </p>
      <div
        v-if="slaveTableData"
        class="u-flex-1 bp-preview-table-container u-overflow-auto"
      >
        <rb-insights-table
          :key="currentActiveEntity"
          :config="((config || {}).widgets || {})['slaveTableWidget'] || {}"
          :grid-options="
            (((config || {}).widgets || {})['slaveTableWidget'] || {})
              .gridOptions
          "
          :table-column="
            (((config || {}).widgets || {})['slaveTableWidget'] || {})
              .columnDefs
          "
          :row-class-rules="
            (((config || {}).widgets || {})['slaveTableWidget'] || {})
              .gridOptions.rowClassRules
          "
          :table-row="slaveTableData"
          :row-height="45"
          :enable-client-side-sorting="false"
          :has-expand="true"
          :expand-cell-width="50"
          :levels="
            (((config || {}).widgets || {})['slaveTableWidget'] || {}).levels
          "
          table-id="budget-preview-slave"
          :has-default-row-expand="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import stepsMixin from '../stepMixin';
import budgetPlannerFooter from '../common/footer';
import budgetPlannerHeader from '../common/header';
import config from './previewConfig';
import moment from 'moment-timezone';
import { groupBy } from 'lodash';
import { previewStepretailerMap } from '@/components/ams/budgetPlanner/retailerConfig';

export default {
  components: {
    budgetPlannerHeader,
    budgetPlannerFooter
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      isCurrentEntityPortfolio: false,
      selectedScope: 'Entire Business',
      selectedScopeType: null,
      selectedScopeId: undefined, // EB businessScopeId is undefined
      rbTableInstance: null
    };
  },
  computed: {
    masterTableData() {
      const tableData = this.$store.getters.getPreviewTableEntireBusiness;
      return tableData.map((entity) => {
        return {
          businessScope: entity.executionScope.businessScope,
          businessScopeName:
            entity.executionScope.businessScope === 'ENTIRE_BUSINESS'
              ? 'Entire Business'
              : entity.executionScope.businessScopeName,
          estimatedPcogs: entity.executionScope.estimatedPcogs,
          finalBudgetPlan: entity.executionScope.finalBudgetPlan,
          percentageText: entity.executionScope.percentageText
        };
      });
    },
    currentActiveEntity() {
      return this.$store.getters.getCurrentActiveEntity;
    },
    slaveTableData() {
      return this.$store.getters.getQuarterlyFormattedData;
    },
    rowClassRules() {
      return {
        'ag-row-focus': (params) => {
          return params.data.businessScopeId === this.selectedScopeId;
        },
        'is-eb': "data.businessScope === 'ENTIRE_BUSINESS'",
        'is-profile': "data.businessScope === 'PROFILE'",
        'is-portfolio': "data.businessScope === 'PORTFOLIO'"
      };
    },
    headerStepConfig() {
      const retailer = this.$store.getters.getRetailer;
      const masterTableKey =
        retailer === 'amazon'
          ? 'masterTableWidget'
          : `${retailer}MasterTableWidget`;
      return this.config?.widgets?.[masterTableKey];
    }
  },
  watch: {
    currentActiveEntity: {
      handler: function (newValue) {
        if (newValue) {
          this.$store.commit('CLEAR_PREVIEW_SLAVE_TABLE_LEVEL_DATA');
          const tableData = this.budgetPlannerEntities.filter((entity) => {
            return newValue === 'ENTIRE_BUSINESS'
              ? entity.executionScope.businessScope === newValue
              : entity.executionScope.businessScopeId === newValue;
          });
          const that = this;
          if (tableData.length && tableData[0].executionStrategies) {
            const monthlyData = tableData[0].executionStrategies
              .filter((item) => item.type === 'PACING')
              .map((item) => {
                const currValue = that.isCurrentEntityPortfolio
                  ? item.absoluteMonthlyBudget
                  : item.value;
                const monthNo = +moment(item.start).format('M');
                let quarterSum = 0;
                let quarterScope;
                if (monthNo >= 1 && monthNo <= 3) {
                  quarterScope = 'Q1';
                  const validQuarterItems =
                    tableData[0].executionStrategies.filter(
                      (ent) =>
                        ent.type === 'PACING' &&
                        [1, 2, 3].indexOf(+moment(ent.start).format('M')) > -1
                    );
                  quarterSum = validQuarterItems.reduce(
                    (acc, x) =>
                      acc +
                      (that.isCurrentEntityPortfolio
                        ? x.absoluteMonthlyBudget
                        : x.value),
                    0
                  );
                } else if (monthNo >= 4 && monthNo <= 6) {
                  quarterScope = 'Q2';
                  const validQuarterItems =
                    tableData[0].executionStrategies.filter(
                      (ent) =>
                        ent.type === 'PACING' &&
                        [4, 5, 6].indexOf(+moment(ent.start).format('M')) > -1
                    );
                  quarterSum = validQuarterItems.reduce(
                    (acc, x) =>
                      acc +
                      (that.isCurrentEntityPortfolio
                        ? x.absoluteMonthlyBudget
                        : x.value),
                    0
                  );
                } else if (monthNo >= 7 && monthNo <= 9) {
                  quarterScope = 'Q3';
                  const validQuarterItems =
                    tableData[0].executionStrategies.filter(
                      (ent) =>
                        ent.type === 'PACING' &&
                        [7, 8, 9].indexOf(+moment(ent.start).format('M')) > -1
                    );
                  quarterSum = validQuarterItems.reduce(
                    (acc, x) =>
                      acc +
                      (that.isCurrentEntityPortfolio
                        ? x.absoluteMonthlyBudget
                        : x.value),
                    0
                  );
                } else {
                  quarterScope = 'Q4';
                  const validQuarterItems =
                    tableData[0].executionStrategies.filter(
                      (ent) =>
                        ent.type === 'PACING' &&
                        [10, 11, 12].indexOf(+moment(ent.start).format('M')) >
                          -1
                    );
                  quarterSum = validQuarterItems.reduce(
                    (acc, x) =>
                      acc +
                      (that.isCurrentEntityPortfolio
                        ? x.absoluteMonthlyBudget
                        : x.value),
                    0
                  );
                }

                let percentVal = 0;
                if (quarterSum > 0) {
                  percentVal = (currValue / quarterSum) * 100;
                }
                percentVal =
                  Math.round((percentVal + Number.EPSILON) * 100) / 100;
                return {
                  ...item,
                  itemLabel: item.label.toLowerCase(),
                  displayValue: that.isCurrentEntityPortfolio
                    ? item.absoluteMonthlyBudget
                    : item.value,
                  percentageText: `<span class="u-color-grey-lighter perc-val">${percentVal}%</span>&nbsp;<span class="perc-scope">of ${quarterScope}</span>`
                };
              });

            // set the monthly distribution in store for table inner level getters
            this.$store.commit(
              'SET_PREVIEW_ACTIVE_MONTHLY_DISTRIBUTION',
              monthlyData
            );

            const allMonthsSum =
              tableData[0].executionScope.finalBudgetPlan || 0;

            const groupedByQuarters = groupBy(monthlyData, (item) => {
              const monthNo = moment(item.start).format('M');
              if (monthNo >= 1 && monthNo <= 3) {
                return '1';
              } else if (monthNo >= 4 && monthNo <= 6) {
                return '2';
              } else if (monthNo >= 7 && monthNo <= 9) {
                return '3';
              } else {
                return '4';
              }
            });

            const oArray = Object.values(groupedByQuarters).map(
              (quarterArr, index) => {
                const quarterSum = quarterArr.reduce(
                  (acc, item) => acc + item.displayValue,
                  0
                );
                let percentVal = 0;
                if (allMonthsSum > 0) {
                  percentVal = (quarterSum / allMonthsSum) * 100;
                }
                percentVal =
                  Math.round((percentVal + Number.EPSILON) * 100) / 100;
                return {
                  quarterNo: index + 1,
                  itemLabel: 'Quarter ' + (index + 1),
                  displayValue: quarterSum,
                  percentageText: `<span class="u-color-grey-lighter perc-val">${percentVal}%</span>`,
                  'ag-grid-has-expand': true
                };
              }
            );
            this.$store.commit('SET_PREVIEW_QUARTERLY_FORMATTED_DATA', oArray);
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('setVisitedSteps', 'previewStep');
    this.config = config.config(this);
    this.$store.commit('SET_PREVIEW_ACTIVE_ENTITY', 'ENTIRE_BUSINESS');
  },
  methods: {
    setPreviewActiveEntity(event) {
      const previousScope = this.selectedScope;
      const retailer = this.$store.getters.getRetailer;
      this.selectedScope = event.data.businessScopeName;
      this.selectedScopeType = null;
      this.selectedScopeId = event.data.businessScopeId;
      if (previousScope === 'Entire Business') {
        this.rbTableInstance.redrawTableRows();
      }
      this.isCurrentEntityPortfolio = event.data.businessScope === 'PORTFOLIO';
      if (event.data.businessScope === 'PROFILE') {
        this.selectedScopeType = previewStepretailerMap[retailer];
      } else if (event.data.businessScope === 'PORTFOLIO') {
        this.selectedScopeType = 'Portfolio: ';
      }
      const entityID =
        event.data.businessScopeName === 'Entire Business'
          ? 'ENTIRE_BUSINESS'
          : event.data.businessScopeId;
      this.$store.commit('SET_PREVIEW_ACTIVE_ENTITY', entityID);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    }
  }
};
</script>

<style lang="css" scoped>
.fillContainerHeight {
  height: calc(100vh - 108px);
}
.distributionMarginTop {
  margin-top: 22px;
}
</style>

<style lang="css">
.fillContainerHeight .bp-preview-table-container .ag-theme-material {
  height: auto !important;
}
</style>
