<template>
  <div class="rb-data-table u-width-100 u-height-100">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-material"
      :grid-options="gridOptions"
      :default-col-def="defaultColDef"
      :row-data="rowData"
      :pinned-bottom-row-data="pinnedBottomRowData"
      :column-defs="columnDefs"
      :enable-sorting="enableClientSideSorting"
      :enable-server-side-sorting="enableServerSideSorting"
      :row-height="rowHeight"
      :header-height="headerHeight"
      :suppress-movable-columns="true"
      :sorting-order="['asc', 'desc']"
      :enable-col-resize="true"
      :overlay-no-rows-template="noRowsTemplate"
      :overlay-loading-template="overlayLoadingTemplate"
      :grid-auto-height="gridAutoHeight"
      :column-everything-changed="onColumnsUpdated"
      :row-selection="'multiple'"
      :selection-changed="onSelectionChanged"
      :suppress-row-click-selection="true"
      :icons="icons"
      :displayed-columns-changed="onColumnsUpdated"
      :grid-size-changed="onColumnsUpdated"
      :get-row-node-id="getRowNodeId"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

var isFirstColumn = function (params) {
  if (
    this.rowSelection &&
    this.rowSelection.mode === 'multiple' &&
    params &&
    params.columnApi
  ) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    return displayedColumns[0] === params.column;
  } else {
    return false;
  }
};
/* eslint-disable vue/require-default-prop */

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'rbDataTable',
  components: {
    AgGridVue
  },
  props: {
    rowSelection: {
      type: Object,
      default: () => {}
    },
    gridOptions: {
      type: Object,
      default: () => {}
    },
    tableColumns: Array,
    tableData: Array,
    pinBottomRowData: Array,
    gridAutoHeight: {
      type: Boolean,
      default: false
    },
    rowHeight: {
      type: Number,
      default: 80
    },
    enableClientSideSorting: {
      type: Boolean,
      default: true
    },
    enableServerSideSorting: {
      type: Boolean,
      default: false
    },
    headerHeight: {
      type: Number,
      default: 52
    },
    noRowsMessage: {
      type: String,
      default: 'Could not load data this time. Please try again.'
    },
    overlayLoadingTemplate: {
      type: String,
      default: 'Loading...'
    },
    rowClassRules: {
      type: Object,
      default: () => {}
    },
    getCurrentInstance: {
      type: Function
    },
    primaryKey: {
      type: String
    },
    scrollToNodeId: {
      type: Function
    }
  },
  data() {
    var defaultColDef = {
      headerCheckboxSelection: isFirstColumn.bind(this),
      checkboxSelection: isFirstColumn.bind(this)
    };
    return {
      icons: {
        checkboxChecked:
          '<span data-cy = "checkedCheckbox" style="margin:0;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-selected u-color-blue-base u-display-flex u-flex-align-items-center"></span>',
        checkboxUnchecked:
          '<span data-cy = "uncheckCheckbox" style="margin:0;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-empty u-color-grey-lighter u-display-flex u-flex-align-items-center"></span>',
        checkboxIndeterminate:
          '<span style="margin:0;" class="rb-icon--medium u-cursor-pointer rb-icon icon-checkbox-intermediate u-color-blue-base u-display-flex u-flex-align-items-center"></span>'
      },
      defaultColDef,
      internalCounter: 0
    };
  },
  computed: {
    rowData() {
      return this.tableData;
    },
    pinnedBottomRowData() {
      return this.pinBottomRowData;
    },
    columnDefs() {
      return this.tableColumns;
    },
    noRowsTemplate() {
      return `<span>${this.noRowsMessage}</span>`;
    }
  },
  created() {
    this.gridOptions.onGridReady = this.onGridReady;
    this.gridOptions.rowClassRules = this.rowClassRules;
    if (this.getCurrentInstance) {
      this.getCurrentInstance(this);
    }
  },
  methods: {
    onSelectionChanged(event) {
      if (this.rowSelection.onSelectionChanged) {
        this.rowSelection.onSelectionChanged(
          event.api.getSelectedNodes(),
          event
        );
      }
    },
    onColumnsUpdated(params) {
      this.$nextTick(() => {
        params.api.sizeColumnsToFit();
      });
      window.addEventListener('resize', () => {
        this.$nextTick(() => {
          params.api.sizeColumnsToFit();
        });
      });
    },
    redrawTableRows() {
      setTimeout(() => {
        this.gridOptions.api && this.gridOptions.api.redrawRows();
      });
    },
    onGridReady(params) {
      this.onColumnsUpdated(params);
      this.scrollToNodeIfNeeded(params);
    },
    scrollToNodeIfNeeded(params) {
      if (this.scrollToNodeId) {
        var id = this.scrollToNodeId();
        let nodeToScroll;
        const allNodes = [];
        params.api.forEachNode((node) => allNodes.push(node));
        for (var i = 0; i < allNodes.length; i++) {
          if (
            allNodes[i].id &&
            id &&
            id.indexOf(allNodes[i].id.split('-')[0]) > -1
          ) {
            nodeToScroll = allNodes[i];
            break;
          }
        }
        if (!nodeToScroll) {
          return;
        }
        params.api.ensureNodeVisible(nodeToScroll, 'middle');
      }
    },
    getRowNodeId(params) {
      if (params[this.primaryKey]) {
        return params[this.primaryKey] + '-' + this.internalCounter++;
      }
      return Math.random() + '-' + this.internalCounter++;
    }
  }
};
</script>
