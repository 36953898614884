<template>
  <section
    :class="{
      'u-height-400': isLoading,
      'u-border-color-grey-xxx-light u-border-all': !isLoading
    }"
  >
    <section
      v-if="isLoading"
      class="u-display-flex u-flex-direction-column u-height-100"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        color="#007cf6"
      />
    </section>
    <section v-else>
      <rb-insights-table
        class="breakdownTable"
        :table-column="getColumnDefs"
        :table-row="getTableData"
        :grid-options="tableGridOptions"
        :pagination="true"
        :row-height="70"
        :config="tableConfig"
        :pagination-total-key="((getTableData || {})[0] || {}).totalEntityCount"
        :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
        :show-pagination-options="true"
        :row-per-page-list="tableConfig.rowPerPageList"
        :pagination-change-event="breakdownPaginationChangeEvent"
      />
      <div
        class="u-width-100 u-display-flex u-flex-align-items-center u-border-top u-border-color-grey-xxx-light u-spacing-ph-m u-spacing-pv-s"
      >
        <div class="no-wrap u-color-grey-x-light u-font-size-7">
          Last Updated: {{ lastUpdateDate }}
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import {
  msProConstants,
  getMetricData,
  msProCategoryConstants,
  buildUIMetricMapping
} from '@/components/pages/insights/amazon/market-share/utils';
import { eventBus } from '@/utils/services/eventBus';
import CategoryIconCell from '@/components/pages/insights/amazon/market_share_pro/atoms/categoryIconCell.vue';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import HttpService from '@/utils/services/http-service';

export default {
  name: 'PerformanceBreakdown',
  owner: 'Saumya',
  components: {
    loader,
    CategoryIconCell
  },
  props: {
    dataService: {
      type: Object,
      default: () => ({})
    },
    globalSettings: {
      type: [Array, Object],
      default: undefined
    },
    minMaxDate: {
      type: Object,
      default: undefined
    },
    sortConfiguration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      readApiResponse: {},
      entityTypeCategory: 'brand',
      uiMetricMapping: {},
      metricDataGroupMapping: {},
      tableGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      getTableData: [],
      breakdownPaginationChangeEvent: 'paginationBreakdownWidget',
      tableConfig: {
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        },
        rowPerPageList: [
          { title: 10 },
          { title: 20 },
          { title: 30 },
          { title: 50 }
        ],
        isSorting: true,
        byMetric: 'marketShareMetric'
      },
      columnDefs: []
    };
  },
  computed: {
    lastUpdateDate() {
      return formatter(this.minMaxDate?.rawMaxDate, 'date');
    },
    viewBy() {
      return this.globalSettings?.settingValue?.viewBy?.entityType;
    },
    getColumnDefs() {
      return this.columnDefs;
    },
    isLoading() {
      return this.dataService?.isLoading;
    }
  },
  watch: {
    globalSettings: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.resetPagination();
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    sortConfiguration: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.resetPagination();
          this.getData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    Vue.component('CategoryIconCell', CategoryIconCell);
    this.init();
    eventBus.$on(this.breakdownPaginationChangeEvent, (data) => {
      this.tableConfig.body.APIConfig.page = data;
      this.$nextTick(() => {
        this.getData();
      });
    });
    eventBus.$on('performance-breakdown-download-clicked', () => {
      this.downloadReport();
    });
  },
  destroyed() {
    eventBus.$off(this.breakdownPaginationChangeEvent);
    eventBus.$off('performance-breakdown-download-clicked');
  },
  methods: {
    resetPagination() {
      this.tableConfig.body.APIConfig.page = 1;
    },
    constructTableData(responseTableData) {
      const tableData = (responseTableData || []).map((entity) => {
        return entity.data.reduce((previousVal, currentVal) => {
          const metricName = currentVal.name;
          const metricMapping = this.uiMetricMapping;
          if (metricMapping[metricName]) {
            previousVal[metricMapping[metricName]] =
              currentVal.RESULT?.[currentVal.name];
            const metricMetaData =
              this.readApiResponse.metrics[metricName]?.metadata;
            if (metricMetaData) {
              previousVal[`${metricMapping[metricName]}_absoluteType`] =
                metricMetaData.unit !== '' ? metricMetaData.unit : 'NUMERIC';
              previousVal[`${metricMapping[metricName]}_invertTag`] =
                metricMetaData.isInverted;
            }
            if (currentVal.PVP) {
              previousVal['PVP_DIFF_' + metricMapping[metricName]] =
                currentVal.PVP['PVP_DIFF_' + currentVal.name];
              previousVal['PVP_' + metricMapping[metricName]] =
                currentVal.PVP['PVP_' + currentVal.name];
            }
          } else {
            previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
          }
          previousVal.entityType = entity.entityType;
          previousVal.totalEntityCount = entity.totalEntityCount;
          return previousVal;
        }, {});
      });
      return tableData;
    },
    async getData() {
      this.metricDataGroupMapping = await getMetricData(
        this.readApiResponse,
        this.globalSettings,
        msProCategoryConstants.breakdownTableMetrics
      );
      this.uiMetricMapping = buildUIMetricMapping(this.metricDataGroupMapping);
      this.columnDefs = this.dataService.updateColDefs(
        this.viewBy,
        this.sortConfiguration
      );
      const response = await this.dataService?.getData(
        this.metricDataGroupMapping,
        this.globalSettings?.settingValue?.viewBy?.entityType,
        undefined,
        this.globalSettings,
        undefined,
        this.tableConfig,
        this.sortConfiguration
      );
      this.getTableData = (await this.constructTableData(response)) || [];
    },
    async downloadReport() {
      try {
        const endPoint = '/entity/report/download';
        const service = 'DASHBOARD_SERVICE';
        const metricDataGroupMapping = await getMetricData(
          this.readApiResponse,
          this.globalSettings,
          msProCategoryConstants.breakdownTableMetrics
        );
        const dataRequest = this.dataService.constructPayload(
          this.globalSettings,
          this.globalSettings?.settingValue?.viewBy?.entityType,
          this.tableConfig,
          metricDataGroupMapping,
          true,
          this.sortConfiguration
        );
        dataRequest.enablePaginationCount = false;
        delete dataRequest.operations.limit;
        delete dataRequest.operations.page;
        const { data } = await HttpService.post(service, dataRequest, {
          append: endPoint
        });
        downloadFile(data.url);
        eventBus.$emit('performance-breakdown-download-completed');
      } catch (e) {
        eventBus.$emit('performance-breakdown-download-failed');
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    async init() {
      try {
        this.readApiResponse = await this.dataService?.getMetadata();
        this.getData();
      } catch (err) {
        this.$snackbar.open(msProConstants.errorMessage);
      }
    }
  }
};
</script>
