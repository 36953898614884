<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s u-spacing-p-m u-spacing-mt-m u-spacing-ml-s u-spacing-mr-s"
  >
    <div>
      <p class="u-font-size-2 u-font-weight-700">Optimise Your Content</p>
      <p
        class="u-font-size-4 u-display-block u-font-weight-500 u-spacing-pt-s u-color-grey-x-light"
      >
        Generate ASIN title suggestions based on your product description,
        customer reviews and customer search intent
      </p>
    </div>
    <div class="u-spacing-mt-s u-spacing-p-s">
      <div class="u-spacing-mt-l u-display-flex u-flex-align-items-center">
        <div class="u-display-flex u-flex-align-items-center">
          <label
            class="u-display-block u-font-size-4 u-font-weight-600 u-color-grey-light"
            >ASIN</label
          >
          <input
            v-model="asin"
            class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s u-spacing-ml-s"
            placeholder="Please enter the ASIN"
            type="text"
          />
        </div>
        <rb-button
          :text="generating ? 'Suggesting...' : 'Suggest Titles'"
          type="filled"
          class="u-color-grey-white u-spacing-ml-l"
          :click-fn="generateTitles.bind(this)"
        />
      </div>
      <div
        v-if="titles"
        class="title-container"
      >
        <a
          :href="productDetails.productUrl"
          target="_blank"
          class="u-width-30 u-display-flex u-flex-justify-content-center"
        >
          <img
            class="u-display-block u-height-50 u-cursor-pointer"
            alt="product image"
            :src="productImageUrl"
          />
        </a>
        <div class="u-width-70 u-spacing-ml-l">
          <div>
            <label
              class="u-display-block u-font-size-4 u-font-weight-600 u-color-grey-base"
              >Top Suggested Titles</label
            >
            <p
              v-for="title in titles"
              class="u-font-size-5 u-color-grey-light u-spacing-pt-s"
            >
              {{ title.text }}
            </p>
          </div>
          <div class="u-spacing-mt-l">
            <label
              class="u-display-block u-font-size-4 u-font-weight-600 u-color-grey-base u-spacing-pt-s"
              >Current Title</label
            >
            <p class="u-font-size-5 u-color-grey-light u-spacing-pt-s">
              {{ productDetails.existingTitle }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      class="u-font-size-6 u-display-block u-font-weight-500 u-spacing-pt-s u-color-grey-x-light u-text-align-right"
    >
      powered by CommerceIQ and ChatGPT
    </p>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';

export default {
  name: 'GenerateTitle',
  data() {
    return {
      asin: '',
      titles: '',
      generating: false,
      productDetails: {},
      productImageUrl: ''
    };
  },
  methods: {
    async generateTitles() {
      this.generating = true;
      try {
        const res = (
          await HttpService.post(
            'OPENAI',
            {
              asin: this.asin
            },
            {
              append: '/generate-title'
            }
          )
        ).data;
        this.titles = res.choices;
        this.productDetails = res.productDetails;
        this.productImageUrl = res.productDetails.imageUrls[1];
        this.generating = false;
      } catch (err) {
        this.$snackbar.open({
          message:
            'Sorry, we are experiencing a heavy load and are unable to serve you right now. Exciting times for AI and E-commerce ahead. Hang tight and please try after some time!',
          duration: 10000,
          actionText: '',
          customStyles: 'background: #007cf6'
        });
        console.log(err);
        this.generating = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.title-container {
  line-height: 2.5rem;
  display: flex;
  margin-top: 4rem;
  text-align: justify;
  justify-content: center;
}
.title-container img {
  max-height: 293px;
}
input {
  width: 382px;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}
input:focus {
  border-color: #007cf6;
}
</style>
