<template>
  <section>
    <div v-show="pageFetched">
      <sticky-filter-panel
        v-if="showFilter"
        ref="sticky-filter-panel"
        :key="stickyFilterKey"
        :hide-export="true"
        :hide-clear-all-btn="true"
        :disable-view="true"
        :show-filter="showFilter"
        class="sticky-panel sticky-filter-panel category_page"
        :filter-data="filterComputedData"
        :primary-filter-data="primaryFilterData"
        :secondary-filter-data="secondaryFilterComputedData"
        :filter-local-storage-key="filterLocalStorageKey"
        :filter-emit="filterEmit"
        :options-list="viewList || []"
        :selected-option="selectedView"
        :filter-state="filterState"
        :default-date-post-range="defaultDateRange"
        :min-max-date="minMaxDate"
        :hide-view-btn="hideViewBtn"
        :hide-remove-btn="true"
        :hide-update-view="hideUpdateView"
        :enable-open-filter="enableOpenFilter"
        :filter-key="filterKey"
        :additional-ranges="additionalRanges"
        :selection-mode="selectionMode"
        :enable-minimum-selection="true"
        :view-selection-enabled="false"
        :config="config.filters"
        :css-class="cssClass"
        @savedFilterApplied="handleSaveFilterApplied"
        @resetView="handleResetView"
        @onCreate="onCreate"
        @filterMounted="handleFilterMount"
        @filterBeforeDestroy="handleFilterBeforeDestroy"
      >
        <div
          slot="before-filter"
          class="u-display-flex u-flex-align-items-center u-height-36px"
        >
          <span @click.stop="goBack()"
            ><rb-icon
              class="rb-icon u-spacing-ml-xs u-color-grey-lighter u-spacing-mr-s u-cursor-pointer"
              :icon="'arrow_back'"
          /></span>
          <div class="u-font-size-2 u-font-weight-700 capitalize">
            SKUs &nbsp; -
            <span v-if="categoryName"> &nbsp; {{ categoryName }}</span>
          </div>
        </div>
        <div
          slot="after-filter"
          :class="{ 'subcagetory-dropdown': !enableHeirarchy }"
          class="u-border-radius-xxxl u-spacing-pv-xs u-spacing-ph-s u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center u-bg-color-grey-white"
          style="margin-bottom: 5px"
        >
          <div
            v-if="!enableHeirarchy"
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-font-size-5 u-display-inline-flex u-flex-align-items-center action--text opacity-0_5"
            title="No sub-category available for the selected category"
            style="height: 20px"
          >
            <span
              class="rb-icon rb-icon--small u-spacing-mr-xs icon-add-circle-fill"
            />
            <span>Add a sub category filter</span>
          </div>
          <SubCategoryFilter
            v-else
            :selected-category="currentCategory"
            :selected-hierarchy="selectedSubCategory"
            :selections="selections"
            :category-status-list="categoryStatusList"
            @applySubCategory="applySubCategory"
            @onShow="onSubcategoryShow"
          />
        </div>
        <div
          slot="preDatePicker"
          class="u-display-flex u-flex-align-items-center"
        />
        <div slot="download">
          <rb-button
            v-tippy="{ placement: 'top', arrow: false }"
            :loading="downloadIcon == 'loading'"
            :title="'Downloads an Excel with the SKU level data for the selected date range.'"
            text=""
            icon-left="download"
            :type="'hollow'"
            :click-fn="downloadFile"
            class="u-spacing-ml-s hollow-outlined-btn u-bg-color-transparent-base export-pdf"
          />
        </div>
        <div
          v-for="(attributes, index) in dynamicAttributes"
          :key="index"
          slot="after-filter"
          v-tippy="{
            placement: 'top-start',
            arrow: false,
            maxWidth: '200px',
            size: 'regular'
          }"
          class="u-display-flex"
          :title="
            !disableAttribute(attributes.path, categoryStatusList)
              ? 'There is no data available for the combination of this filter and the other selected filters.'
              : ''
          "
        >
          <rb-filter
            ref="filter"
            :config="{
              title: attributes.dimensionLabel,
              dimensionName: attributes.dimensionName,
              path: attributes.path
            }"
            :anchor="attributes.dimensionLabel"
            :show-notification="false"
            :multi="false"
            :use-local-storage="false"
            :hide-clear-all-btn="true"
            :is-always-on-filter="true"
            :add-filter-text="attributes.dimensionLabel"
            :secondary-data="attributeDropdownOptions(attributes)"
            :apply="applyAttribute"
            :default-filters="defaultAttributesOptions"
            :class="{
              'category-filter disabled': !disableAttribute(
                attributes.path,
                categoryStatusList
              )
            }"
          />
        </div>
        <div slot="footer">
          <Settings
            v-if="!showManageSkuClassficationTab"
            :key="key"
            :types="settingsValue.viewBy"
            :placements="settingsValue.include3p"
            :keyword-type="settingsValue.displayShareIn"
            :selected-type="computedSelectedSettingsValue.viewBy"
            :selected-placement="computedSelectedSettingsValue.include3p"
            :selected-keyword-type="
              computedSelectedSettingsValue.displayShareIn
            "
            :customer-level-filters="customerLevelFilters"
            :widget-name="'settings'"
            :hide-view-by="true"
            :hide-display-share-in="true"
            @apply="handleApply"
            @cancel="handleCancel"
          />
        </div>
      </sticky-filter-panel>

      <message-bar
        v-if="showEditInProgressMessageBar"
        :show="showEditInProgressMessageBar"
        page-type="market-share-sku"
        :position-fixed="false"
        bg-color="rgba(255, 168, 0, 0.1)"
        :show-close-button="false"
      >
        <div
          slot="message-content"
          class="message-bar-content u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            icon="in-progress-dotted"
            class="u-color-orange-base rb-icon--x-medium"
          />
          <span class="u-spacing-ml-s u-font-size-5">
            Update of some SKU attributes is
            <span class="u-color-orange-base u-font-weight-600"
              >in-progress</span
            >, it will take upto 1 week for the changes to reflect across the
            product. You can continue editing if required.
          </span>
        </div>
      </message-bar>

      <section
        v-show="pageFetched"
        class="widgetWrapper"
      >
        <section
          :class="{ 'u-height-400': isLoading }"
          class="u-spacing-mh-m"
        >
          <card-widget
            class="u-flex-1 u-overflow-auto u-spacing-mv-m"
            :config="tableConfig"
          >
            <div
              slot="header"
              class="u-display-flex u-width-100"
            >
              <div
                class="u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-space-between"
              >
                <splitTabs
                  :tab1="viewSkuPerformanceTabHeading"
                  :tab2="manageSkuClassificationTabHeading"
                  :size="'m'"
                  @tabClicked="tabChanged"
                />
                <div
                  class="u-font-size-5 u-color-grey-light u-display-flex u-color-grey-lighter u-cursor-pointer hover-class u-spacing-pl-s u-display-flex u-flex-justify-content-center u-flex-align-items-center"
                >
                  <div
                    v-tippy="tippy"
                    class="u-width-100px"
                    :title="!skuCheckBox.comp ? skuSelectionMessage : null"
                  >
                    <rb-checkbox
                      v-model="skuCheckBox.your"
                      :class="{
                        'disabled u-pointer-events-none': !skuCheckBox.comp
                      }"
                      @input="checkBoxChange('your')"
                    >
                      Your SKUs
                    </rb-checkbox>
                  </div>
                  <div
                    v-tippy="tippy"
                    class="radio-wrapper u-width-140px"
                    :title="!skuCheckBox.your ? skuSelectionMessage : null"
                  >
                    <rb-checkbox
                      v-model="skuCheckBox.comp"
                      :class="{
                        'disabled u-pointer-events-none': !skuCheckBox.your
                      }"
                      @input="checkBoxChange('comp')"
                    >
                      Competitor SKUs
                    </rb-checkbox>
                  </div>
                  <div
                    v-if="showManageSkuClassficationTab"
                    class="seprator"
                  />
                  <rb-button
                    v-if="showSaveSkuButton"
                    slot="trigger"
                    :click-fn="() => setSkuManageEditMode(false)"
                    text="Cancel"
                    class="u-spacing-p-m edit-catalog-button"
                    size="s"
                  />
                  <rb-button
                    v-if="showSaveSkuButton"
                    :click-fn="triggerSaveUpdatedData"
                    text="Save"
                    class="u-spacing-ml-xs edit-catalog-button"
                    size="s"
                    icon-left="save"
                    type="filled"
                  />
                  <rb-button
                    v-if="showEditSkuButton"
                    :click-fn="() => setSkuManageEditMode(true)"
                    text="Edit SKU classification"
                    class="u-spacing-ml-xs edit-catalog-button"
                    size="s"
                    :disabled="isLoading || showNoSkus"
                    icon-left="pencil"
                    type="filled"
                  />
                </div>
              </div>
            </div>
            <div slot="body">
              <div
                v-show="!isLoading && !showNoSkus"
                v-if="metadataLoaded"
              >
                <table-widget
                  v-if="!showManageSkuClassficationTab"
                  ref="table-widget"
                  class="widget-3"
                  :page="page"
                  :page-wise-min-max-key="pageWiseMinMaxKey"
                  :global-settings="getGlobalSettings"
                  :data-service="tableDataService"
                  :sku-check-box="skuCheckBox"
                  :metadata="metadataSkuPerformance"
                  :non-filterable-attributes-columns="
                    nonParsableAttributesColumns
                  "
                  :v2="v2"
                />
                <manage-sku-widget
                  v-if="showManageSkuClassficationTab"
                  :sku-check-box="skuCheckBox"
                  :global-settings="getGlobalSettings"
                  :data-service="manageSkuDataService"
                  :metadata="metadataSkuClassification"
                  :number-of-subcategories="numberOfSubcategories"
                  :number-of-amazon-subcategories="numberOfAmazonSubcategories"
                  :non-filterable-attributes-columns="
                    nonFilterableAttributesColumns
                  "
                  @setSkuManageColumnEdited="setSkuManageColumnEdited"
                  @updateSkuManageEditMode="updateSkuManageEditMode"
                />
              </div>
              <section
                v-if="isLoading"
                class="u-display-flex u-flex-direction-column u-min-height-700"
              >
                <loader
                  class="u-height-100 u-bg-color-grey-white fill--parent"
                  :loading="true"
                  :color="'#007cf6'"
                />
              </section>
              <section v-if="showNoSkus">
                <noData />
              </section>
            </div>
            <div
              slot="footer"
              class="u-width-100 u-display-flex u-flex-align-items-center"
            >
              <div class="no-wrap u-color-grey-x-light u-font-size-7">
                Last Updated: {{ lastUpdateDate }}
              </div>
              <div class="divider u-spacing-mh-s u-bg-color-grey-xxx-light" />
              <div
                v-if="!showManageSkuClassficationTab"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  icon="info-circle-fill"
                  class="rb-icon--small u-color-grey-lighter"
                />
                <p class="u-font-size-7 u-spacing-ml-xs">
                  Metrics of SKUs with variants are aggregated
                </p>
              </div>
            </div>
          </card-widget>
        </section>
      </section>
    </div>

    <section>
      <section
        v-if="!pageFetched"
        class="view-port u-display-flex u-flex-direction-column"
      >
        <loader
          class="u-height-100 u-bg-color-grey-white"
          :loading="true"
          :color="'#007cf6'"
        />
      </section>
      <section
        v-else-if="pageFetchedError"
        class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
      >
        <div class="u-display-flex noskuImage_container">
          <img
            alt="error"
            src="/images/no_sku_found.png"
            class="u-width-100"
          />
        </div>
        <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
          <p class="u-color-grey-base u-spacing-mr-s">Something went wrong!</p>
        </div>
      </section>
    </section>
    <section class="u-spacing-mh-m u-spacing-mv-s u-display-flex slide-card" />
  </section>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';
import splitTabs from '@/components/widgets/splitTabs';
import chart from '@/components/basic/chart';
import HttpService from '@/utils/services/http-service';
import cardWidget from '@/components/widgets/cardWidget';
import loader from '@/components/basic/loader';
import widgetMixin from '@/components/widgetsMixin.js';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import Settings from '@/components/pages/insights/amazon/market-share/molecules/settings-popup.vue';
import { cloneDeep, isEmpty } from 'lodash';
import filter from '@/components/widgets/filter/filter.vue';
import {
  getAdditionalDateRanges,
  getObjectKey,
  getHighestSelectableRange,
  getFormattedSubCategory,
  getTransformedSubcategory,
  setGlobalSettingsForMS,
  getGlobalSettingsForMS,
  getDimensionValueList,
  computeFinalWhereClause,
  getFormattedCustomerLevelFilters,
  configurationSetting,
  removeGlobalSettingsForMS,
  filterHierarchyPaths,
  getSavedCustomerFilters,
  constants,
  getAttributeList,
  onFilterApply,
  getAttributeDropdownOptions,
  getAttributesData,
  transform3pInNestedSettings,
  getSettingsValue,
  getAttributes,
  enableFilter,
  getCategoryDisableStatus,
  getDisableAttribute,
  get3pOptions,
  asinLevelV2ConfigEnabled,
  localStorageFallback
} from '@/components/pages/insights/amazon/market-share/utils';
import DataService from '@/components/pages/insights/amazon/share-of-voice/service/data';
import TableWidgetService from '@/components/pages/insights/amazon/market-share/service/sku-table-service.js';
import TableWidget from '@/components/pages/insights/amazon/market-share/templates/SKUTableWidget.vue';
import benchmarkingTable from '../../molecules/benchmarking-table.vue';
import SubCategoryFilter from '@/components/pages/insights/amazon/market-share/molecules/hierarchy.vue';
import ManageSkuWidget from '@/components/pages/insights/amazon/market-share/templates/manage-sku-widget.vue';
import ManageSkuDataService from '@/components/pages/insights/amazon/market-share/service/manage-sku-table-service.js';
import { ThreePSelection } from '@/components/pages/insights/amazon/market-share/types/filters.ts';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import transformer from '@/utils/services/data-transformer.js';
import noData from '@/components/basic/noData.vue';

export default {
  components: {
    chart,
    loader,
    stickyFilterPanel,
    Settings,
    cardWidget,
    TableWidget,
    SubCategoryFilter,
    benchmarkingTable,
    ManageSkuWidget,
    splitTabs,
    rbFilter: filter,
    messageBar,
    noData
  },
  mixins: [widgetMixin],
  data() {
    return {
      nonParsableAttributesColumns: [],
      computedSelectedSettingsValue: {
        viewBy: { entityType: 'asin', label: 'Brands' },
        displayShareIn: {
          metricsList: 'SALES_ESTIMATES',
          label: 'Dollars'
        },
        include3p: get3pOptions()[0]
      },
      numberOfAmazonSubcategories: 0,
      numberofSubcategories: 0,
      nonFilterableAttributesColumns: [],
      currentCategoryTracker: '',
      skuSelectionMessage: 'Atleast one selection needs to be enabled',
      tippy: {
        placement: 'bottom',
        arrow: false,
        distance: 8
      },
      metadataSkuPerformance: {},
      metadataSkuClassification: {},
      showManageSkuClassficationTab: false,
      viewSkuPerformanceTabHeading: 'SKU Performance',
      manageSkuClassificationTabHeading: 'SKU Classification',
      tableConfig: {
        title: '52 WEEK CATEGORY SHARE SUMMARY',
        tooltip: '',
        meta: {},
        header: {
          search: false,
          show: true,
          actions: {
            download: {}
          }
        },
        footer: {
          show: true
        },
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        }
      },
      skuCheckBox: {
        your: true,
        comp: true
      },
      benchmarkWidgetService: null,
      selectedSubCategory: [],
      selections: [],
      selectionMode: 'multi-week',
      viewList: [],
      selectedView: {},
      pageFetchedError: '',
      secondaryFilterData: {},
      additionalRanges: {
        add: ['lastWeek', 'last4Week', 'last13Week'],
        overrideRange: ['lastWeek', 'last4Week', 'last13Week'],
        order: {
          lastWeek: 3,
          last4Week: 4,
          last13Week: 5
        }
      },
      filterMounted: false,
      renderKey: 0,
      pageFetched: false,
      enableOpenFilter: true,
      filterInstanceCreated: false,
      stickyFilterKey: 0,
      filterKey: 0,
      defaultDateRange: 'last30Days',
      filterInstance: null,
      hideViewBtn: true,
      hideUpdateView: true,
      namespace: 'MARKETSHARESKU/',
      pageId: null,
      tableDataService: {},
      manageSkuDataService: {},
      showFilter: false,
      filterEmit: 'marketshare-sku-filter-apply',
      page: 'MARKETSHARE-SKU',
      filterLocalStorageKey: 'marketshare-sku-filters',
      minMaxDate: {},
      pageWiseMinMaxKey: 'marketshare-sku',
      selectedCustomerFilters: {},
      selectedSettingsValue: {
        viewBy: { entityType: 'asin', label: 'Brands' },
        displayShareIn: {
          metricsList: 'SALES_ESTIMATES',
          label: 'Dollars'
        },
        include3p: get3pOptions()[0]
      },
      globalToLocal: {
        viewBy: {
          label: {
            Category: 'Brands'
          },
          entityType: {
            category: 'asin',
            brand: 'asin'
          }
        },
        displayShareIn: {
          metricsList: {
            actual_unit_marketshare: 'UNIT_ESTIMATES',
            actual_sales_marketshare: 'SALES_ESTIMATES'
          }
        },
        include3p: {
          label: {
            'My 1P + 3P sales': 'My 1P + 3P sales out of 1P + 3P of the market',
            'My 1P Sales': 'My 1P Sales out of 1P Sales of Market',
            'My 3P Sales': 'My 3P Sales out of 3P Sales of Market'
          }
        }
      },
      globalWhereClauseValue: {},
      getGlobalSettings: {},
      downloadIcon: 'download',
      config: {
        filters: {
          hasSearch: true,
          search: {
            width: '260px',
            enable: true,
            emit: 'skuSearch',
            placeholder: 'Search by ASIN or Brand Name'
          }
        }
      },
      searchedText: '',
      dynamicAttributes: [],
      selectedAttributes: [],
      defaultAttributesOptions: {
        values: {}
      },
      categoryStatusList: {},
      cssClass: '',
      skuManageEditModeEnabled: false,
      skuManageColumnEdited: false,
      key: 0
    };
  },
  computed: {
    metadataLoaded() {
      return (
        !isEmpty(this.metadataSkuClassification) &&
        !isEmpty(this.metadataSkuPerformance)
      );
    },
    dimensionValueList() {
      return getDimensionValueList(this.selectedSubCategory);
    },
    lastUpdateDate() {
      if (this.showManageSkuClassficationTab) {
        return this.formatter(
          this.metadataSkuClassification?.calendar?.max_date,
          'date'
        );
      } else {
        return this.formatter(
          this.metadataSkuPerformance?.calendar?.max_date,
          'date'
        );
      }
    },
    isLoading() {
      if (this.showManageSkuClassficationTab) {
        return (
          this.manageSkuDataService?.data?.load &&
          !this.manageSkuDataService?.data?.noData
        );
      } else {
        return (
          this.tableDataService?.data?.load &&
          !this.manageSkuDataService?.data?.noData
        );
      }
    },
    showNoSkus() {
      if (this.showManageSkuClassficationTab) {
        return this.manageSkuDataService?.data?.noData;
      } else {
        return this.tableDataService?.data?.noData;
      }
    },
    categoryName() {
      if (this.currentCategory) {
        let categoryName =
          this.currentCategory.length > 0
            ? this.currentCategory[0].dimensionValue
            : '';
        categoryName = categoryName
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        return categoryName;
      }
    },
    enableHeirarchy() {
      let selectedCategory = cloneDeep(
        this.currentCategory[0]?.dimensionValue || ''
      );
      if (selectedCategory) {
        selectedCategory = getObjectKey(
          this.filterData?.category,
          selectedCategory
        );
      }
      const obj = this.filterData?.category?.[selectedCategory] || {};
      const length = Object.keys(obj?.children || {}).length;
      if (length === 0) {
        return false;
      }
      return true;
      // return this.$store.getters.getHeirarchyFilterData;
    },
    currentCategory() {
      const globalWhereClause = cloneDeep(this.globalWhereClauseValue) || {};
      let dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      dimensionNameValueList = dimensionNameValueList.filter((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.replace('~', '');
          return element;
        } else {
          return false;
        }
      });
      return dimensionNameValueList;
    },
    filterComputedData() {
      return [];
    },
    customerLevelFilters() {
      return getFormattedCustomerLevelFilters(
        this.filterData,
        this.selectedCustomerFilters
      );
    },
    settingsValue() {
      return getSettingsValue('asin', this.page);
    },
    secondaryFilterComputedData() {
      const category_hierarchy = {
        multi: false,
        v1: true,
        key: 'category_hierarchy',
        label: 'Category',
        values: []
      };
      Object.keys(this.filterData.category || {}).forEach((element) => {
        category_hierarchy.values.push({ title: element, enable: true });
      });
      return { category_hierarchy };
    },
    filterState() {
      return {
        getter: this.namespace + 'getGlobalFilters',
        setter: this.namespace + 'setGlobalFilters'
      };
    },
    showEditSkuButton() {
      return (
        this.showManageSkuClassficationTab && !this.skuManageEditModeEnabled
      );
    },
    showSaveSkuButton() {
      return (
        this.showManageSkuClassficationTab && this.skuManageEditModeEnabled
      );
    },
    showEditInProgressMessageBar() {
      return this.showManageSkuClassficationTab && this.skuManageColumnEdited;
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    }
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    transform3pInNestedSettings();
    this.initializePage();
    // handles the subcategory selection on page load
    this.selections = getTransformedSubcategory();

    eventBus.$on('skuSearch', (searchText) => {
      this.searchedText = searchText.trim();
      this.refreshWidgets();
    });
  },
  beforeDestroy() {
    eventBus.$off('skuSearch');
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  beforeRouteLeave(to, from, next) {
    setGlobalSettingsForMS(constants.NAVIGATE_FROM, 'marketshareSkus');
    setGlobalSettingsForMS(constants.CATEGORY, this.categoryName);
    next();
  },
  mounted() {
    console.log('MS local storage > sku page >', getGlobalSettingsForMS());
  },
  methods: {
    /**
     * @description to reset any active settings changes on clicking on cancel button
     */
    handleCancel() {
      this.key++;
    },
    /**
     * @description to disable the category level filters
     * @param {array} selectionList - hierarichal path of the subcategory on which the category level filter is applicable
     * @param {object} categoryStatusList - all possible selection paths of category/subcategory with its enable status
     * @return {boolean}
     */
    disableAttribute(selectionList, categoryStatusList) {
      return getDisableAttribute(selectionList, categoryStatusList);
    },
    /**
     * @description called when dynamic attributes are called
     * @callback onFilterApply
     * @param {object} selections - have selection data from dropdown
     * stores dynmaic attributes selections in LS
     * refresh the widgets with updated where clause
     */
    applyAttribute(selections) {
      onFilterApply(selections);
      this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      this.getDisableData(this.dynamicAttributes);
      this.refreshWidgets();
    },
    /**
     * @description called to list down the options of each attributes
     * @param {object} attributeData - have attribute data from API response
     * @returns {object} - contains object with list of dropdopwn options for specific attributes
     * does the data transformation to display list of options dynamically
     */
    attributeDropdownOptions(attributeData) {
      return getAttributeDropdownOptions(attributeData);
    },
    /** Method to compute the Non Filterable Columns that needs to be added in sku classification page.
     */
    computeAttrColumn(accessKey = 'non_filterable_attributes') {
      const columns = [];
      const nonFilterableAttributes = [];
      const categoryData = this.filterData?.category;
      /** Heirarchy Array where
       * - Index of the array represents the level of Category/Subcategory
       * - Values represents the selected subcategories at that level  */
      const hierarchyArray = [];
      /**  Object containing the category heirarchy
       * - key is the Category/SubCategory Name
       * - value is the level at which they are present in the heirarchy
       */
      let hierachyObj = {
        [this.categoryName]: 0
      };
      if (this.selectedSubCategory.length) {
        // set the heirarchyObj based on heirarchy
        hierachyObj = this.selectedSubCategory.reduce(
          (tempHeirarchyObj, heirarchyStr) => {
            const tempHeirarchyArray = heirarchyStr.split('----');
            tempHeirarchyArray.forEach((category, index) => {
              if (!tempHeirarchyObj[category]) {
                tempHeirarchyObj[category] = index;
              }
            });
            return tempHeirarchyObj;
          },
          {}
        );
      }
      for (const [key, value] of Object.entries(hierachyObj)) {
        hierarchyArray[value] = [...(hierarchyArray[value] || []), key];
      }
      /** Function to traverse the the category filter data according to the heirarchy */
      function traverseForNFA(categoryObj, level) {
        const applicableCategories = Object.keys(categoryObj).filter(
          (category) => {
            // If subcategory is selected at this level, only traverse through that, else traverse through all available subcategories
            return !(
              hierarchyArray[level] && !hierarchyArray[level].includes(category)
            );
          }
        );
        applicableCategories.forEach((category) => {
          nonFilterableAttributes.push(
            ...(categoryObj[category][accessKey] || [])
          );
          if (Object.keys(categoryObj[category].children).length) {
            traverseForNFA(categoryObj[category].children, level + 1);
          }
        });
      }
      traverseForNFA(categoryData, 0);
      // Populate columns array based on the nonFilterableAttributes
      nonFilterableAttributes.forEach((attribute) => {
        const { dimensionLabel } = attribute;
        const found = columns.find(
          (column) => column.dimensionColumn === dimensionLabel.toLowerCase()
        );
        if (!found) {
          columns.push({
            dimensionLabel,
            dimensionColumn: dimensionLabel.toLowerCase(),
            enableSorting: false
          });
        }
      });
      return columns;
    },
    async updatedDateRange(categorySelection = []) {
      if (categorySelection.length === 0) {
        if (this.dimensionValueList?.length !== 0) {
          categorySelection = this.dimensionValueList;
        } else if (
          this.globalWhereClause()?.dimensionNameValueList?.length !== 0
        ) {
          categorySelection = this.globalWhereClause()?.dimensionNameValueList;
        }
      }
      if (categorySelection.length === 0) {
        return;
      }
      const body = categorySelection.map(
        (dimension) => dimension.dimensionValue
      );
      try {
        return await HttpService.post('MARKETSHARE_DIFF_DATE', body, {
          params: {
            v2: this.v2
          }
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    async updateFilters() {
      return new Promise((resolve) => {
        this.reinitView();
        this.$nextTick(() => {});
        const categoryHierarchySelections = this.$store.getters.getSelections;

        // Hack to show the filters
        setTimeout(() => {
          document.querySelector('.filter-list__item').click();
        }, 0);
        setTimeout(() => {
          document.querySelector('body').click();
          document.querySelector('.rb-filter .icon-cross').style.display =
            'none';
          console.log(categoryHierarchySelections);
          this.$store.dispatch(
            'setInitialSelections',
            categoryHierarchySelections
          );
        }, 0);

        // Once the filter component is reinitialized, resolve the method
        const interval = setInterval(() => {
          if (this.filterInstanceCreated) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },
    async checkDatesForCategory(category = []) {
      // resetting searched text at the time of category and subcategory change
      this.searchedText = '';
      this.pageFetched = false;
      let dateRange = null;
      try {
        dateRange = await this.updatedDateRange(category);
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      try {
        this.numberOfSubcategories = parseInt(
          dateRange?.data?.response?.maxSubCategoryLevel
        );
        this.numberOfAmazonSubcategories = parseInt(
          dateRange?.data?.response?.maxAmznCategoryLevel
        );
        await this.updateFilterSelection({
          from: dateRange.data.response.minDate,
          to: dateRange.data.response.maxDate
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      this.pageFetched = true;
    },
    showErrorPageLevel(err) {
      this.pageFetched = false;
      this.pageFetchedError = false;
      console.error('err', err);
      this.openSnackbar();
    },
    async updateFilterSelection(dateRange, updateFilters = true) {
      const storage = JSON.parse(
        localStorage.getItem(this.filterLocalStorageKey)
      );

      const currentSelectedStartDate = moment(storage.date_range.from);
      const currentSelectedEndDate = moment(storage.date_range.to);
      const backendStartDate = moment(dateRange.from);
      const backendEndDate = moment(dateRange.to);

      let updatedStartDate = currentSelectedStartDate;
      let updatedEndDate = currentSelectedEndDate;
      let updateFilter = false;
      this.minMaxDate.minDate = backendStartDate.format('MM/DD/YYYY');
      this.minMaxDate.maxDate = backendEndDate.format('MM/DD/YYYY');
      this.additionalRanges = getAdditionalDateRanges(
        backendStartDate,
        backendEndDate
      );
      const availableRanges = this.additionalRanges.add.filter(
        (range) => this.additionalRanges.disabledRange.indexOf(range) === -1
      );
      const highestSelectableRange = getHighestSelectableRange(
        this.additionalRanges.order,
        availableRanges
      );

      let date_range = {};
      if (
        !currentSelectedStartDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (
        !currentSelectedEndDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (updateFilter) {
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify({
            ...storage,
            date_range
          })
        );
      }
      if (updateFilters) {
        await this.updateFilters();
      }
    },
    formatter: formatter,
    tabChanged(val) {
      if (val === this.manageSkuClassificationTabHeading) {
        this.showManageSkuClassficationTab = true;
        this.skuManageEditModeEnabled = false;
      } else {
        this.showManageSkuClassficationTab = false;
      }
    },
    checkBoxChange(key) {
      if (!this.skuCheckBox?.your && !this.skuCheckBox?.comp) {
        this.$nextTick(() => {
          this.skuCheckBox[key] = true;
        });
      }
      this.skuManageEditModeEnabled = false;
      setGlobalSettingsForMS(constants.SKU_CHECKBOX, this.skuCheckBox);
      eventBus.$emit('checkBoxChangeEvent', this.skuCheckBox);
    },
    async downloadFile() {
      let whereClause = cloneDeep(this.getGlobalSettings.where);
      whereClause.dimensionNameValueList =
        whereClause.dimensionNameValueList.filter((item) => {
          return item.dimensionName !== 'search';
        });
      this.downloadIcon = 'loading';
      const threepSelection =
        this.computedSelectedSettingsValue?.include3p?.value;
      if (this.v2) {
        await this.tableDataService.download(
          whereClause,
          threepSelection,
          this.skuCheckBox,
          this.searchedText
        );
      } else {
        if (!this.showManageSkuClassficationTab) {
          await this.tableDataService.download(
            whereClause,
            threepSelection,
            this.skuCheckBox,
            this.searchedText
          );
        } else {
          await this.manageSkuDataService.download(whereClause);
        }
      }
      this.downloadIcon = 'download';
    },
    goBack() {
      // Check if we have any page to go back to, if yes go to that page else go to Category Page
      this.$router.push({ name: this.previousPage || 'category-page' });
    },
    onSubcategoryShow() {
      // Hiding Category filter dropdown when sub category filter is opened
      const categoryFilter = this.filterInstance?.$refs?.filter?.[0];
      if (categoryFilter) {
        categoryFilter.isActive = false;
      }
    },
    async applySubCategory(data) {
      // when sub category is changed we catch the event here
      if (Object.keys(data).length !== 0) {
        setGlobalSettingsForMS(constants.SUBCATEGORY, data);
        setGlobalSettingsForMS(
          constants.FILTER_SELECTION,
          this.filterData?.category
        );
      } else {
        setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
        setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
      }
      if (this.showManageSkuClassficationTab) {
        /**
         * need these next two lines to reset edit mode enabled on Sku classification tab
         */
        this.setSkuManageColumnEdited(false);
        this.setSkuManageEditMode(false);
      }
      let globalFilter = getGlobalSettingsForMS(
        undefined,
        categorySelectionList,
        false
      );
      this.selectedSubCategory = getFormattedSubCategory(
        data,
        this.filterData?.category
      );
      const categorySelectionList = filterHierarchyPaths(
        this.filterData?.category,
        {}
      );
      this.selections = getTransformedSubcategory();
      this.dynamicAttributes = getAttributes(
        data,
        categorySelectionList,
        this.dynamicAttributes
      );
      if (globalFilter[constants.ATTRIBUTES]) {
        this.defaultAttributesOptions.values =
          globalFilter[constants.ATTRIBUTES];
        this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      }
      this.getDisableData(this.dynamicAttributes);
      await this.checkDatesForCategory();
      this.nonFilterableAttributesColumns = this.computeAttrColumn();
      this.nonParsableAttributesColumns = this.computeAttrColumn('attributes');
      this.refreshWidgets();
    },
    handleFilterMount(instance) {
      this.filterMounted = true;
      eventBus.$on(this.filterEmit, async (data) => {
        // when category and date range is changed we catch the event here
        if (!data?.from) {
          setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
          setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
          setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
        }

        let storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        for (const key in storage) {
          setGlobalSettingsForMS(key, storage[key]);
        }
        if (this.showManageSkuClassficationTab) {
          /**
           * need these next two lines to reset edit mode enabled on Sku classification tab
           */
          this.setSkuManageColumnEdited(false);
          this.setSkuManageEditMode(false);
        }
        if (
          data?.category_hierarchy !== undefined &&
          data?.category_hierarchy?.[0] !== this.currentCategoryTracker
        ) {
          // Only if category has changed then make a request for fetching differential dates for category
          this.currentCategoryTracker = data.category_hierarchy[0];
          let category = {};
          category[this.currentCategoryTracker] = true;
          const categorySelectionList = filterHierarchyPaths(
            this.filterData?.category,
            {}
          );
          this.dynamicAttributes = getAttributeList(
            category,
            categorySelectionList
          );
          this.selectedAttributes = getAttributesData(this.dynamicAttributes);
          this.getDisableData(this.dynamicAttributes);
          await this.checkDatesForCategory();
        }

        console.log('this.filterEmit', data);
        if (!data.name) {
          this.$store.dispatch('setInitialSelections', {});
          this.selectedSubCategory = [];
        }
        this.nonFilterableAttributesColumns = this.computeAttrColumn();
        this.nonParsableAttributesColumns =
          this.computeAttrColumn('attributes');
        this.refreshWidgets();
      });
    },
    handleFilterBeforeDestroy() {
      // this.filterMounted = false;
      // eventBus.$off(this.filterEmit);
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.$router.push({
            name: 'marketshareSkus',
            params: { id: selectedView.globalViewId }
          });
          return;
        }

        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList(selectedView);
      } else {
        if (this.viewList && this.viewList.length > 0) {
          selectedView = this.viewList[0];
          this.$router.push({
            name: 'marketshareSkus',
            params: { id: selectedView.globalViewId }
          });
          const initialState = {
            filters: selectedView?.pageUserFilters,
            ...selectedView?.widgetUserSelectionMetadata
          };
          this.$store.dispatch(
            this.namespace + 'setSelectedView',
            selectedView
          );
          this.$store.dispatch(
            this.namespace + 'setInitialWidgetState',
            initialState
          );
          this.fetchPageList();
        }
      }
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(
        this.$store.getters[this.namespace + 'getGlobalFilters']
      );
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: []
      };
      where.date.from = globalWhereClause?.date_range?.from;
      where.date.to = globalWhereClause?.date_range?.to;
      where.date.name = globalWhereClause?.date_range?.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause?.date_range?.compare_from;
      where.pvpDate.to = globalWhereClause?.date_range?.compare_to;
      where.pvpDate.compare_name = globalWhereClause?.date_range?.compare_name;
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      where.dimensionNameValueList.forEach((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.toLowerCase() + '~';
          element.operator = 'STARTSWITH';
        }
      });
      if (!this.showManageSkuClassficationTab && !this.v2) {
        if (
          this.selectedSettingsValue?.include3p?.value ===
          ThreePSelection.EXCLUDE_3P
        ) {
          where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: false
          });
        }
      }
      return where;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      setTimeout(() => {
        this.showFilter = true;
      });
    },
    handleResetView() {
      this.reinitView();
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    async getGlobalFilters(cube, urlFactoryId, page, where) {
      this.resetFilters();
      await this.fetchFilters(
        cube,
        urlFactoryId,
        page,
        where,
        undefined,
        undefined,
        true
      );
    },
    handleApply(payload) {
      // when settings is changed we catch the event here
      let nestedSettings = { ...payload };
      this.selectedCustomerFilters = nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(
        'customerLevelFilters',
        nestedSettings.customerLevelFilters
      );
      delete nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
      this.$nextTick(() => {
        console.log('refreshWidgets', payload);
        // updates the settings component selections
        this.computedSelectedSettingsValue = getGlobalSettingsForMS(
          'nestedSettings',
          undefined,
          false
        );
        this.refreshWidgets();
      });
    },
    async refreshWidgets() {
      this.globalWhereClauseValue = cloneDeep(this.globalWhereClause());
      const whereClause = computeFinalWhereClause(
        this.globalWhereClauseValue,
        this.dimensionValueList,
        this.selectedCustomerFilters,
        this.selectedAttributes
      );
      this.getGlobalSettings = {
        where: whereClause,
        settingValue: getGlobalSettingsForMS(
          'nestedSettings',
          undefined,
          this.v2
        ),
        searchedText: this.searchedText
      };
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      this.viewList.unshift(...defaultViewTemp);
    },
    initializePage() {
      this.showFilter = false;
      const requestBody = { page: this.page };
      HttpService.post('DASHBOARD_SERVICE', requestBody, {
        append: '/global/view/list'
      })
        .then((response) => {
          if (response.status === 200) {
            this.viewList = response.data.globalViews.map((item) => {
              item.currentUser = false;
              if (item.createdBy === window.user.email) {
                item.currentUser = true;
              }
              item.formattedAt = moment(item.createdAt).format('LLL');
              if (item.groupName !== 'Default Views') {
                item.edit = false;
              }
              return item;
            });

            this.sortViewList();

            let globalViewId = '';
            if (this.$route?.params?.id) {
              globalViewId = this.$route?.params?.id;
              this.handleViewBasedonParams(globalViewId);
            } else {
              globalViewId = response.data?.userUnsavedGlobalView?.globalViewId;
              if (globalViewId) {
                this.$router.push({
                  name: 'marketshareSkus',
                  params: { id: globalViewId }
                });
              } else {
                this.$router.push({
                  name: 'marketshareSkus',
                  params: { id: this.viewList[0]?.globalViewId }
                });
              }
            }
          }
        })
        .catch((error) => {
          console.error('e', error);
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        });
    },
    async getDisableData(dynamicAttributes) {
      this.categoryStatusList = await getCategoryDisableStatus(
        dynamicAttributes
      );
      if (!Object.keys(this.categoryStatusList)?.length) {
        this.defaultAttributesOptions.values = {};
      }
    },
    async fetchPageList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.pageId = response.data.id;
        if (response.status === 200) {
          // const { cubeName, pageName, where } =
          //   response.data.API.filterAPI.request;
          // this.getGlobalFilters(cubeName, 'FETCH_FILTERS_V2', pageName, where);
          this.fetchFilterV3(
            {
              ...response.data.API.filterAPI,
              endPoint: response.data.API.filterAPI.endPoint + '?v2=' + this.v2
            },
            (data) => data
          ).finally(async () => {
            localStorageFallback(this.filterData?.category, 'skuPage');
            if (
              response.data.calendar.min_date &&
              response.data.calendar.max_date
            ) {
              this.minMaxDate.minDate = moment(
                response.data.calendar.min_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
              this.minMaxDate.maxDate = moment(
                response.data.calendar.max_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
            } else {
              const today = moment('YYYY-MM-DD');
              const yearBack = moment('YYYY-MM-DD').subtract('1', 'years');
              this.minMaxDate.maxDate = today.format('MM/DD/YYYY');
              this.minMaxDate.minDate = yearBack.format('MM/DD/YYYY');
            }

            let storage = JSON.parse(
              localStorage.getItem(this.filterLocalStorageKey)
            );
            const storageValues = this.transformFilterFromAPI(
              response.data.filters?.selectedFilter?.filter || []
            );

            let category = '';
            let categoryExists = true;
            const categorySelectionList = filterHierarchyPaths(
              this.filterData?.category,
              {}
            );
            let globalFilter = getGlobalSettingsForMS(
              undefined,
              categorySelectionList,
              false
            );
            this.previousPage = globalFilter?.[constants.NAVIGATE_FROM];
            if (globalFilter.skuCheckBox) {
              this.skuCheckBox = globalFilter.skuCheckBox;
            }
            setGlobalSettingsForMS(constants.NAVIGATE_FROM, {}, true);
            this.selectedCustomerFilters =
              getSavedCustomerFilters(this.filterData) || {};
            category =
              globalFilter?.category ||
              globalFilter?.category_hierarchy?.[0] ||
              this.secondaryFilterComputedData.category_hierarchy?.values[0]
                ?.title;
            if (category) {
              if (!categorySelectionList[category.toLowerCase()]) {
                categoryExists = false;
              }
            }
            if (!this.enableHeirarchy) {
              // this check is needed to enable category dropdown selection on edit functionality
              const allCategoryList = this.filterData?.category || {};
              this.$store.commit('SET_ALL_CATEGORY_LIST', allCategoryList);
            }
            if (Object.keys(globalFilter).length && categoryExists) {
              let nestedSettings = {
                ...configurationSetting(
                  globalFilter.nestedSettings,
                  this.globalToLocal
                )
              };
              setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
              setGlobalSettingsForMS(
                constants.SUBCATEGORY,
                globalFilter.subcategory
              );
              this.computedSelectedSettingsValue = getGlobalSettingsForMS(
                'nestedSettings',
                undefined,
                false
              );
              storageValues.values.date_range = globalFilter?.date_range;
              if (Object.keys(globalFilter?.subcategory || {}).length) {
                this.selectedSubCategory = getFormattedSubCategory(
                  globalFilter.subcategory,
                  globalFilter.filterSelection
                );
                // handles the subcategory selection on page load
                this.selections = getTransformedSubcategory();
                this.dynamicAttributes = getAttributeList(
                  globalFilter?.subcategory,
                  categorySelectionList
                );
              } else {
                let selectedPath = {};
                selectedPath[category] = true;
                this.dynamicAttributes = getAttributeList(
                  selectedPath,
                  categorySelectionList
                );
                setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
              }
              if (globalFilter[constants.ATTRIBUTES]) {
                this.defaultAttributesOptions.values =
                  globalFilter[constants.ATTRIBUTES];
                this.selectedAttributes = getAttributesData(
                  this.dynamicAttributes
                );
                this.getDisableData(this.dynamicAttributes);
              }
              setGlobalSettingsForMS(constants.CATEGORY, {}, true);
              setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, [category]);
            } else {
              removeGlobalSettingsForMS();
              storageValues.values.date_range = {
                name: 'last4Week',
                compare: true,
                compare_name: null
              };
              storageValues.values.category_hierarchy = [category];
              storageValues.order = ['category_hierarchy'];

              let nestedSettings = { ...this.selectedSettingsValue };

              let selectedPath = {};
              selectedPath[category] = true;
              this.dynamicAttributes = getAttributeList(
                selectedPath,
                categorySelectionList
              );

              setGlobalSettingsForMS(null, {
                ...storageValues.values,
                nestedSettings
              });
            }

            storageValues.values.category_hierarchy = [category];
            storageValues.order = ['category_hierarchy'];
            if (!storage) {
              storage = {};
              storage = storageValues.values;
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              localStorage.setItem(
                `${this.filterLocalStorageKey}-order`,
                JSON.stringify(storageValues.order)
              );
            } else {
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              this.metadataSkuPerformance =
                await this.tableDataService?.getMetadata();
              this.manageSkuDataService = new ManageSkuDataService(
                this.page,
                'marketshareSkuClassification' + (this.v2 ? '_v2' : ''),
                this.selectedView.pageId,
                this.selectedView.globalViewId,
                this.v2
              );
              this.metadataSkuClassification =
                await this.manageSkuDataService?.getMetadata();
              this.nonFilterableAttributesColumns = this.computeAttrColumn();
              this.nonParsableAttributesColumns =
                this.computeAttrColumn('attributes');
              this.pageFetched = true;
            }
            this.showFilter = true;
            this.$nextTick(() => {});
            await this.checkDatesForCategory([
              { dimensionValue: category.toLowerCase() + '~' }
            ]);
            setTimeout(() => {
              // this.showFilter = true;
              document.querySelector('.filter-list__item')?.click();
            }, 0);
            setTimeout(() => {
              document.querySelector('body').click();
              document.querySelector('.rb-filter .icon-cross').style.display =
                'none';
              this.refreshWidgets();
            }, 0);
            this.tableDataService = new TableWidgetService(
              this.page,
              'marketshareSkuPerformance' + (this.v2 ? '_v2' : ''),
              this.selectedView.pageId,
              this.selectedView.globalViewId,
              this.v2
            );
            this.metadataSkuPerformance =
              await this.tableDataService?.getMetadata();
            this.manageSkuDataService = new ManageSkuDataService(
              this.page,
              'marketshareSkuClassification' + (this.v2 ? '_v2' : ''),
              this.selectedView.pageId,
              this.selectedView.globalViewId,
              this.v2
            );
            this.metadataSkuClassification =
              await this.manageSkuDataService?.getMetadata();
            this.nonFilterableAttributesColumns = this.computeAttrColumn();
            this.nonParsableAttributesColumns =
              this.computeAttrColumn('attributes');
            this.pageFetched = true;
          });
        }
      } catch (err) {
        this.pageFetched = false;
        this.pageFetchedError = false;
        console.error('err', err);
        this.openSnackbar();
      }
    },
    isJsonParsable(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return string;
      }
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    setSkuManageEditMode(value = false) {
      this.skuManageEditModeEnabled = value;
      const mode = value ? 'edit' : 'view';
      eventBus.$emit('setManageSkuTableEditMode', mode);
    },
    triggerSaveUpdatedData() {
      this.skuManageEditModeEnabled = false;
      eventBus.$emit('saveManageSkuUpdatedData');
    },
    setSkuManageColumnEdited(value = false) {
      this.skuManageColumnEdited = value;
    },
    updateSkuManageEditMode(value = false) {
      this.skuManageEditModeEnabled = value;
    }
  }
};
</script>
<style scoped lang="css" scoped>
.intermediate-height-w3 {
  height: 566px;
}
.intermediate-height-w5 {
  height: 332px;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.view-port {
  height: calc(100vh - 72px);
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: scroll;
}
.widget-2-width {
  width: calc(60% - 16px);
}
.u-height-36px {
  height: 36px;
}
.corners-rounded {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.overlay-snapshot {
  position: sticky;
  bottom: -23px;
  z-index: 99;
}
.box-shadows {
  box-shadow: 0 1px 16px 0 rgb(43 51 59 / 25%);
}
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
.subcagetory-dropdown {
  border: 1px dashed #ebeef6;
}
.radio-wrapper {
  width: 135px;
}
.u-min-height-500 {
  min-height: 500px;
}
#no_sku_image {
  width: 300px;
}
.u-min-height-400 {
  min-height: 400px;
}
.divider {
  height: 16px;
  width: 1px;
}
.u-min-height-700 {
  min-height: 700px;
}
.seprator {
  width: 1px;
  height: 25px;
  border-radius: 1.5px;
  margin-right: 2px;
  background-color: #e9eaeb;
}
.message-bar-content {
  color: #000;
}
</style>
