var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-flex-direction-column"},[_c('Strategy-Header',{attrs:{"step":_vm._step}}),_c('rb-list-select',{attrs:{"size":'small',"options":_vm._entityActionList,"display-key-to-read":'actionName'},on:{"input":_vm.handleTabSelect},model:{value:(_vm.selectedValues.actions),callback:function ($$v) {_vm.$set(_vm.selectedValues, "actions", $$v)},expression:"selectedValues.actions"}})],1),(_vm.infoBox.show)?_c('infoBox',{staticClass:"u-max-width-20 u-flex-align-self-flex-start u-spacing-mb-m",attrs:{"title":_vm.infoBox.title,"description":_vm.infoBox.description}}):_vm._e()],1),(_vm.inlineMessages.show)?_c('div',{staticClass:"pageBackground u-spacing-p-l u-display-block u-flex-justify-content-space-between u-width-100 u-font-size-5 u-color-grey-light"},_vm._l((_vm.inlineMessages.messages),function(message,key){return _c('div',{key:key},_vm._l((message),function(text,textKey){return _c('span',{key:textKey},[_c('span',{class:{
              'u-font-weight-600': text.textType && text.textType === 'bold'
            }},[_vm._v(" "+_vm._s(text.value)+" ")])])}),0)}),0):_vm._e(),(
        _vm.selectedValues.actions &&
        _vm.selectedValues.actions.metadata &&
        _vm.selectedValues.actions.metadata.component === 'BidMultiplier'
      )?_c('div',[_c('BidMultiplierChange',{attrs:{"steps":_vm.steps},on:{"form-data-updated":_vm.bidMultiplierFormData}})],1):_vm._e(),_c('section',{staticClass:"u-margin-top-40"},_vm._l(((_vm.selectedValues.actions || {})
          .substeps || []),function(actionSteps,index){return _c('div',{key:index,staticClass:"u-spacing-mb-m"},[_c('CollapsibleTitle',{staticClass:"u-spacing-mb-m",attrs:{"title":actionSteps.title,"is-expanded":actionSteps.expand},on:{"click":function($event){return _vm.handleActionsSubstepTitle(index)}}}),_c('GenericActionPanel',{directives:[{name:"show",rawName:"v-show",value:(actionSteps.expand),expression:"actionSteps.expand"}],staticClass:"u-spacing-mb-m",attrs:{"form-config":actionSteps.formConfig,"form-data":actionSteps.formData,"general-warning-message":_vm.genericActionPanelWarningMessage},on:{"form-data-updated":function($event){return _vm.formDataArrayUpdate($event, index)}}})],1)}),0),(
        _vm.selectedValues.actions &&
        !_vm.selectedValues.actions.substeps &&
        _vm.selectedValues.actions.actionType &&
        _vm.availableActionTypes.includes(_vm.selectedValues.actions.actionType)
      )?_c('div',{staticClass:"u-spacing-mt-s"},[_c('GenericActionPanel',{ref:"actionPanel",attrs:{"form-config":_vm.priceSelectionConfig,"form-data":_vm.formData,"general-warning-message":_vm.genericActionPanelWarningMessage},on:{"form-data-updated":_vm.actionPanelDataChange}},[(_vm.bidCpcEnabled)?_c('BidCpc',{attrs:{"slot":"dynamic","initial-form-data":((_vm.selectedValues || {}).actionPanelValues || {}).requestObj ||
            undefined},on:{"updateFormData":_vm.updateFormData},slot:"dynamic"}):_vm._e()],1)],1):_vm._e(),(
        _vm.selectedValues.actions &&
        _vm.selectedValues.actions['lockouts'].length > 0
      )?_c('div',{staticClass:"u-spacing-mt-l"},[_c('Lockout',{ref:"lockoutRef",attrs:{"config":(_vm.selectedValues.actions && _vm.selectedValues.actions['lockouts']) || [],"set-form-values":_vm.lockoutData,"entity-type":(_vm.selectedValues.strategyEntityType &&
            _vm.selectedValues.strategyEntityType.entityName) ||
          ''},on:{"getLockoutChangeValue":_vm.getLockoutValues}})],1):_vm._e()]),_c('section',{staticClass:"u-margin-top-80 u-margin-bottom-80 u-display-flex"},[_c('rb-button',{staticClass:"u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s",attrs:{"id":"confirmButton","text":'Back',"type":'hollow',"click-fn":_vm.movePrev.bind(this)}}),_c('rb-button',{attrs:{"id":"confirmButton","text":'Next',"disabled":_vm.disabledButton,"type":'filled',"click-fn":_vm.moveNext.bind(this)}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }