<template>
  <div class="currency-dropdown">
    <rb-select
      class="currency-select-menu u-border-radius-s u-border-all u-border-width-s u-border-color-grey-xxx-light"
      :send-details="true"
      :options="currencyList"
      :on-close="onCurrencySelected"
      class-name=""
      width="281px"
    >
      <div slot="header">
        <div class="u-spacing-pv-xs">
          <span
            class="u-font-size-7 u-spacing-ph-l u-font-weight-600 u-color-grey-lighter"
            >Select currency</span
          >
        </div>
      </div>
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-spacing-ph-s u-spacing-pv-xs u-cursor-pointer currency-dropdown-trigger"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-width-100"
        >
          <div
            v-if="selectedCurrency"
            class="u-display-flex u-flex-align-items-center"
          >
            <rb-icon
              :icon="selectedCurrency.icon"
              class="rb-icon--medium u-color-blue-base"
            />
            <span
              class="u-color-grey-base u-font-size-7 u-spacing-pl-xs u-font-weight-600 u-spacing-pt-xxs"
            >
              {{ selectedCurrency.abbreviation }}
            </span>
          </div>
          <rb-icon
            class="rb-icon--small u-color-grey-lighter"
            :icon="'arrow1-down'"
          />
        </div>
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div class="u-display-flex u-flex-direction-column currency-item">
          <div
            class="u-display-flex"
            :class="
              selectedCurrency.abbreviation === option.abbreviation
                ? 'u-flex-align-items-center'
                : 'u-flex-align-items-flex-start'
            "
            :title="option.abbreviation"
          >
            <div
              class="u-spacing-p-xxs u-border-all u-border-width-s u-border-color-grey-lighter currency-icon-container u-spacing-mr-s"
            >
              <rb-icon
                :icon="option.icon"
                class="rb-icon--medium u-color-grey-lighter"
              />
            </div>
            <div class="currency-abv">
              <p
                class="u-font-size-5 u-font-weight-600 u-spacing-pr-s u-color-grey-base"
              >
                {{ option.abbreviation }}
              </p>
            </div>
            <p
              class="u-font-size-5 u-font-weight-400 u-spacing-pr-s u-color-grey-base"
            >
              {{ option.name }}
            </p>
          </div>
          <span
            v-if="selectedCurrency.abbreviation !== option.abbreviation"
            class="u-color-grey-lighter u-spacing-pl-xl u-font-weight-600 u-font-size-7"
            >1 {{ option.abbreviation }} =
            {{ formattedValue(1 / option.factor, 'numeric') }}
            {{ selectedCurrency.abbreviation }}</span
          >
        </div>
      </template>
    </rb-select>
  </div>
</template>

<script>
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  name: 'CurrencyDropdown',
  data() {
    return {
      isLoading: false,
      currencyList: [],
      selectedCurrency: {}
    };
  },
  computed: {
    currencyExchangeList() {
      return this.$store.getters.getCurrencyExchangeList;
    },
    clientCurrency() {
      return Vue?.options?.filters?.config_check('feature.i18n.currency');
    }
  },
  watch: {
    currencyExchangeList() {
      this.setCurrencyList();
    }
  },
  mounted() {
    this.fetchCurrencyList();
  },
  created() {
    this.setCurrencyList();
  },
  beforeDestroy() {
    this.$off('currenyListChangeEvent');
  },
  methods: {
    formattedValue(value, format) {
      return formatter(value, format);
    },
    onCurrencySelected(context, selectedCurrrencyOption) {
      if (!selectedCurrrencyOption?.length) return;
      this.selectedCurrency = selectedCurrrencyOption[0];
      this.currencyList = this.currencyList?.filter((currency) => {
        return currency?.abbreviation !== this.selectedCurrency?.abbreviation;
      });
      this.currencyList.unshift(this.selectedCurrency);
      this.$store.dispatch('setSelectedCurrency', this.selectedCurrency);
      this.fetchCurrencyList();
    },
    fetchCurrencyList() {
      this.$store
        .dispatch('fetchCurrencyChangeList', {
          currency: this.clientCurrency
        })
        .then((res) => {
          if (res instanceof Array && res.length !== 0) this.setCurrencyList();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setCurrencyList() {
      const currencyList = this.currencyExchangeList;
      this.currencyList = Object.keys(currencyList?.data)?.map((currency) => {
        return {
          abbreviation: currency,
          ...currencyList?.data[currency]
        };
      });
      this.isLoading = currencyList?.isLoading;
      const selectedCurrency = this.$store?.getters?.getSelectedCurrency || {};
      if (!selectedCurrency?.abbreviation) {
        this.selectedCurrency = this.currencyList?.find((currency) => {
          return currency.abbreviation === this.clientCurrency;
        });
      } else {
        this.selectedCurrency = selectedCurrency;
      }
      this.$store.dispatch('setSelectedCurrency', this.selectedCurrency);
      this.currencyList = this.currencyList?.filter((currency) => {
        return currency.abbreviation !== this.selectedCurrency.abbreviation;
      });
      this.currencyList.unshift(this.selectedCurrency);
    }
  }
};
</script>
