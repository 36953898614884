import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import { newToBrandMetricsMeasuresList } from '@/components/pages/instacart/campaigns/constants.js';
const {
  map,
  units,
  axes,
  columnDefs,
  getTagData,
  createBulkSearchObjectForWidget,
  getColumnDefinition,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('instacart', 'keyword');

export default {
  config: () => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) => getColumnDefinition(...args, 'keyword'),
      filters: {
        emit: 'instacartKeywordsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'instacartKeywordsSearchTriggered',
          placeholder: 'Search for a Keyword'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'keywordChart',
            axisFormatMap: units,
            action: 'getInstacartKeywordsChartData',
            listen: {
              instacartKeywordsFilterApplied: {
                action: 'getInstacartKeywordsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              instacartKeywordsPlotSelectedRows: {
                action: 'getInstacartKeywordsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data && data.length > 0) {
                    for (const element of data) {
                      config.meta.plotSelections.push({
                        dimensionName: 'keyword_id',
                        operator: 'ILIKE',
                        dimensionValue: element.keyword_id
                      });
                    }
                  }
                  return config;
                }
              },
              instacartKeywordsSearchTriggered: {
                action: 'getInstacartKeywordsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getInstacartKeywordsChartData',
                'chart',
                'keyword'
              ),
              instacartKeywordPaginationTriggered: {
                action: 'getInstacartKeywordsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'campaigns_keyword'
          },
          body: {
            APIConfig: {
              cubeName: 'instacart_campaigns_keyword_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'report_date',
              pvpenabled: true,
              yoyenabled: false,
              measuresList: [
                'spend',
                'attributed_sales',
                'roas',
                'acos',
                'cpc',
                'impressions',
                'clicks',
                'ctr',
                'attributed_quantities',
                'linear_attributed_sales',
                'linear_roas',
                'linear_attributed_quantities',
                'cvr',
                'linear_cvr',
                ...newToBrandMetricsMeasuresList
              ],
              groupByDimensionsList: ['keyword', 'ad_group_id'],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              dedupBeforeRollup: {
                enableDedupBeforeRollup: false,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  []
              }
            },
            getTagUnitData(data) {
              let oReturn = {};
              let result = (data[0] || {}).RESULT;
              for (let i in result) {
                let translatedI = (map || {})[i] ? (map || {})[i] : i;
                oReturn[translatedI] = {
                  invertTag2: commons.isInverted(i),
                  tag1Unit: units[translatedI],
                  tag2Unit: {
                    suff: '%'
                  }
                };
              }
              return oReturn;
            },
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                show_axis_colors: true,
                axes: axes,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy'
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'keywordTable',
            primaryKey: 'keyword_id',
            action: 'getInstacartKeywordsTableData',
            listen: {
              instacartKeywordsFilterApplied: {
                action: 'getInstacartKeywordsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              instacartKeywordsSearchTriggered: {
                action: 'getInstacartKeywordsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getInstacartKeywordsTableData',
                'table',
                'keyword'
              )
            },
            paginationAction: 'instacartKeywordPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'campaigns_keyword'
          },
          body: {
            APIConfig: {
              cubeName: 'instacart_campaigns_keyword_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              timeseriesDimension: 'report_date',
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'count',
                'active_campaign',
                'campaign_name',
                'ad_group_name',
                'keyword_type',
                'status',
                'matching_type',
                'campaign_type',
                'spend',
                'attributed_sales',
                'roas',
                'acos',
                'cpc',
                'latest_cpc_bid',
                'suggested_bid',
                'bid_type',
                'bid_strength',
                'impressions',
                'clicks',
                'ctr',
                'attributed_quantities',
                'start_date',
                'end_date',
                'linear_attributed_sales',
                'linear_roas',
                'linear_attributed_quantities',
                'cvr',
                'linear_cvr',
                ...newToBrandMetricsMeasuresList
              ],
              groupByDimensionsList: [
                'keyword',
                'keyword_id',
                'ad_group_id',
                'campaign_id'
              ],
              dimensionList: [],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['keyword']
              },
              limit: 30,
              page: 1
            },
            download: {
              fileName: 'KeywordTableData',
              columnMap: columnDefs, // columnDefs is computed again in method "appendTableDefinitionToConfig" of index.vue and appended here
              action: 'downloadCampaignsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: columnDefs, // columnDefs is computed again in method "appendTableDefinitionToConfig" of index.vue and appended here
            rowHeight: 70
          }
        }
      }
    };
  }
};
