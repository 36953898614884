<template>
  <div class="chargeback-type-card card u-spacing-p-m u-font-size-5">
    <div
      class="chargeback-type-card__header u-font-size-5 u-font-weight-600 u-color-grey-mid-light u-text-transform-uppercase u-spacing-mb-m"
    >
      {{ cardTitle }}
    </div>
    <div class="chargeback-type-card__metric u-display-flex u-spacing-mb-l">
      <div
        v-for="(metric, idx) in metrics"
        :key="idx"
        class="u-display-flex u-flex-direction-column"
        :class="{
          'u-spacing-pr-m': idx === 0,
          'u-spacing-pl-m chargeback-type-card__metric--target': idx > 0
        }"
      >
        <span class="u-font-size-6 u-color-grey-mid-light u-spacing-mb-s">
          {{ metric.label }}
        </span>
        <span class="u-display-flex u-flex-align-items-center">
          <span
            v-if="metric.icon === 'ring'"
            :class="metric.iconClass"
          />
          <rb-icon
            v-else
            :icon="metric.icon"
            :class="metric.iconClass"
          />
          <metric
            :hide-zero="false"
            :data="cardData[metric.alias]"
            :config="metric.metricConfig"
            size="xl"
          />
        </span>
      </div>
    </div>
    <div
      class="chargeback-type-card__title u-color-grey-mid-light u-font-size-6"
    >
      {{
        isSIOCIncentive ? 'Performance Earnings' : 'Defect Rate Trend vs Target'
      }}
    </div>
    <div
      class="chargeback-type-card__chart u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <Loader
        v-if="isChartLoading"
        :loading="isChartLoading"
        class="u-bg-color-grey-white"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
      <chartWithGradient
        v-else
        :key="myKey"
        :metric-data="chartDataComputed"
        :metadata="{ name: 'chargebackTypeChart_' + cardTitle.split(' ')[0] }"
        :card-primary-metric-color-code="metricsColor"
        :metrics-list="metrics"
        :chart-config="chartConfig"
      />
    </div>
    <div class="chargeback-type-card__info u-spacing-mb-m">
      <div class="u-color-grey-mid-light u-spacing-mb-s">
        Top Impacting Sub-types
      </div>
      <div
        class="u-color-grey-light u-font-size-6 u-min-height-40 u-line-height-1-7"
      >
        <span v-if="subtypesData.length === 0">
          No sub-types are there for this chargeback type.
        </span>
        <span
          v-for="(item, i) in subtypesData"
          :key="i"
        >
          <span class="u-font-weight-600">Sub-type {{ i + 1 }}</span> has
          <span class="u-color-red-base u-font-weight-600"> {{ item }}% </span>
          contribution
          <span v-if="i < subtypesData.length - 2">, </span>
          <span v-else-if="i === subtypesData.length - 2"> & </span>
          <span v-else>.</span>
        </span>
      </div>
    </div>
    <div
      v-tippy="{ placement: 'bottom', arrow: false }"
      title="View related details in 'Chargeback Claims' Dashboard"
      class="chargeback-type-card__action u-font-size-5 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center"
      @click="navigateToChargebackClaims"
    >
      <rb-icon
        icon="arrow_back"
        class="rb-icon--small u-spacing-mr-s"
      />
      View Chargebacks
    </div>
  </div>
</template>
<script>
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';
import metric from '@/components/basic/metric.vue';
export default {
  name: 'ChargebackTypeCard',
  components: { chartWithGradient, Loader, metric },
  props: {
    index: {
      type: Number,
      default: 0
    },
    cardTitle: {
      type: String,
      default: ''
    },
    cardData: {
      type: Object,
      default: () => ({})
    },
    subtypesData: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    isChartLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      myKey: 0,
      metricsColor: {
        total_defect_rate: '#FFA800',
        target: '#007CF6'
      },
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false } },
        legend: false,
        size: { height: 90 },
        tooltip: false,
        tooltip_format: {
          'Defect Rate': { pre: '', roundoff: 2, suff: '%' },
          Target: { pre: '', roundoff: 2, suff: '%' },
          'Performance Earnings': { pre: '', roundoff: 2, suff: '%' }
        },
        point: false,
        line: { connectNull: true },
        // padding: { left: -8, right: -7, bottom: 5 },
        regions: {},
        timeseries: 'feed_date'
      }
    };
  },
  computed: {
    isSIOCIncentive() {
      return this.cardTitle === 'SIOC INCENTIVE';
    },
    metrics() {
      const metrics = [
        {
          label: this.isSIOCIncentive ? 'Performance Earnings' : 'Defect Rate',
          alias: 'total_defect_rate',
          iconClass: 'u-color-orange-base rb-icon--small u-spacing-mr-xs',
          icon: 'task-status',
          metricConfig: {
            invertTag2: !this.isSIOCIncentive,
            tag1Unit: { pre: '', suff: '%' },
            tag2Unit: { suff: '%' }
          }
        }
      ];
      if (this.isSIOCIncentive) return metrics;
      metrics.push({
        label: 'Target',
        alias: 'target',
        noGradient: true,
        iconClass:
          'u-border-width-1-5 u-border-all u-border-color-blue-base u-border-radius-m u-height-width-1rem u-spacing-mr-xs',
        icon: 'ring',
        metricConfig: { tag1Unit: { pre: '', suff: '%' } }
      });
      return metrics;
    },
    chartDataComputed() {
      return this.isSIOCIncentive
        ? this.chartData.filter((el) => el.alias === 'total_defect_rate')
        : this.chartData;
    }
  },
  watch: {
    chartData: {
      handler() {
        this.myKey++;
      },
      deep: true
    }
  },
  methods: {
    navigateToChargebackClaims() {
      this.$router.push({
        name: 'chargeback_claims'
      });
    }
  }
};
</script>
<style lang="css">
.chargeback-type-card {
  flex: 0 0 315px;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.chargeback-type-card__metric .u-border-width-1-5 {
  border-width: 1.5px;
}
.chargeback-type-card__metric .u-height-width-1rem {
  height: 1rem;
  width: 1rem;
}
.chargeback-type-card__metric--target {
  border-left: 1px solid #e9eaeb;
}
.chargeback-type-card__metric .rb-metric {
  align-items: center;
}
.chargeback-type-card__chart {
  min-height: 90px;
}
.chargeback-type-card__info {
  padding-top: 8px;
}
.chargeback-type-card__info .u-min-height-40 {
  min-height: 55px;
}
.chargeback-type-card__action .rb-icon {
  transform: rotate(180deg);
}
.chargeback-type-card .u-line-height-1-7 {
  line-height: 17px;
}
.chargeback-type-card .c3-tooltip-container .card > :first-child {
  padding: 0.8rem;
  margin: 0;
  border: none;
}
.chargeback-type-card .c3-tooltip-container .card > :first-child span {
  font-size: 13px;
}
.chargeback-type-card .c3-tooltip-container .card > :nth-child(2) {
  display: none;
}
.chargeback-type-card .c3-tooltip-container .card > :last-child,
.chargeback-type-card .c3-tooltip-container .card > :nth-last-child(2) {
  padding: 0 0.8rem;
  font-size: 11px;
  min-width: 200px !important;
}
</style>
