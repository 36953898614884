<template>
  <div
    id="shortage-rra-dashboard"
    class="shortage-rra-dashboard"
  >
    <dashboard-inflator
      ref="dashboard_inflator"
      :page="pageInfo"
      :request-params="requestParams"
      :widget-level-settings="headerOptions()"
      :header-dropdown="true"
      :filter="filterSettings"
      :min-max-date-range="minMaxDateRange"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @onPageListApiSuccess="fetchExtraData"
    >
      <div slot="right-panel">
        <div class="date-select customDateType">
          <rb-select
            :open-direction="'bottom-end'"
            class="u-spacing-ml-xxs valueDropdown u-border-none"
            :send-details="true"
            :on-close="optionSelectedFn"
            :options="dropdownOptions"
          >
            <div
              ref="selectTrigger"
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-ph-xxs u-cursor-pointer u-text-transform-capitalize"
            >
              <div class="u-display-flex">
                <text-select-component
                  v-for="(item, i) in selectedOptionsDataFormatter(
                    selectedOptionsData
                  )"
                  :key="i"
                  :class="`u-display-flex text-select ${item.class}`"
                  :selector-text="item.selectorText"
                  :selected-zip-code="item.selectedZipCode"
                  :selector-class="item.selectorClass"
                />
                <rb-icon
                  class="u-flex-1 rb-icon--large"
                  :icon="'caret-down'"
                />
              </div>
            </div>
          </rb-select>
        </div>
      </div>
      <div slot="custom-filter">
        <rb-filter-panel
          :key="filterSettings.uiKey"
          style="z-index: 10"
          add-filter-text="Vendor Code"
          :get-filter-instance="getFilterInstance"
          listener-event="vendorID"
          :disable-between-operator="!filterSettings.enableBetweenOperator"
          :data="filterData"
          :is-always-on-filter="true"
          :primary="primaryData"
          emit="VendorIDEvent"
          :enable-save="false"
          :custom-config="customConfig"
          :enable-remove-all="false"
          expoter="display-none"
          @filterMounted="handleFilterMount"
        />
      </div>
    </dashboard-inflator>
  </div>
</template>

<script>
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import RRAOverviewWidget from './rraCardWidget.vue';
import RRASankeyChartWidget from './rraSankeyChartWidget.vue';
import targetVsRecoveryChart from './targetVsRecoveryChart.vue';
import titleWithIcon from '@/components/basic/titleWithIcon.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import { cloneDeep, union } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import moment from 'moment-timezone';
import iconText from '@/components/basic/iconText.vue';
import RRASankeyExportWidget from '@/components/pages/insights/amazon/rra/rraSankeyExportWidget.vue';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    rbFilterPanel
  },
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      globalViewId: 3,
      id: 12,
      name: 'ShortageRRAOverview',
      title: 'Profit Recovery Overview'
    };
    return {
      isCardCollapsed: false,
      selectedOptionValue: {},
      widgetMetadata: [],
      selectedOptionsData: {},
      pageInfo,
      dropdownOptions: [],
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-7-21',
          ':to': '2021-10-21',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-12-20',
          ':page': pageInfo.name
        },
        shortage_rra_annual_target: {
          ':widget': 'shortage_rra_annual_target',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':timeseriesRollupBy': 'DAY',
          ':timeseriesDimension': 'feed_date'
        },
        shortages_rra_overview_summary: {
          ':widget': 'shortages_rra_overview_summary',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':operations': {
            timeseriesEnabled: true,
            timeseriesDimension: 'feed_date',
            daterangeDimension: 'feed_date',
            commonFilterEnabled: true,
            pvpenabled: true,
            enableDedupBeforeRollup: false,
            timeseriesRollupBy: 'WEEK',
            getLatestAvailableInsteadOfRollup: false,
            anchoredBrand: null
          }
        },
        shortages_rra_overview_chargeback: {
          ':widget': 'shortages_rra_overview_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':operations': {
            timeseriesEnabled: true,
            timeseriesDimension: 'invoice_date',
            daterangeDimension: 'invoice_date',
            commonFilterEnabled: true,
            pvpenabled: true,
            enableDedupBeforeRollup: false,
            timeseriesRollupBy: 'WEEK',
            getLatestAvailableInsteadOfRollup: false,
            anchoredBrand: null
          }
        },
        shortages_rra_overview_sanki: {
          ':widget': 'shortages_rra_overview_sanki',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':operations': {
            timeseriesEnabled: true,
            timeseriesDimension: 'feed_date',
            daterangeDimension: 'feed_date',
            commonFilterEnabled: true,
            pvpenabled: true,
            enableDedupBeforeRollup: false,
            timeseriesRollupBy: 'WEEK',
            getLatestAvailableInsteadOfRollup: false,
            anchoredBrand: null
          }
        }
      },
      headerOptions: () => {
        return {
          shortage_rra_annual_target: {
            props: {
              headerOptions: [],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto auto 1fr auto auto',
                'grid-column-gap': '1.5rem',
                'grid-row-gap': '2rem'
              },
              footerContainerGridStyles: {
                'grid-template-columns': 'repeat(10, max-content)'
              },
              hideTitle: true,
              widgetRequestParams: this.getWidgetRequestParams(
                'shortages_rra_target_vs_recovery'
              ),
              globalParams: this.getGlobalRequestParams(),
              enableDownload: true,
              isMetricToolTipEnabled: true,
              textClasses: 'u-font-size-8 u-color-grey-lighter u-spacing-pr-xs',
              tooltipText: 'Export',
              iconForDownload: 'share',
              textForIcon: 'Export',
              enableRollup: true,
              showWidgetData: this.isWidgetEnabled(
                'shortage_rra_annual_target'
              ),
              showLastUpdatedDate: this.isWidgetEnabled(
                'shortage_rra_annual_target'
              ),
              localStorageKey: 'ShortageRRAOverview-date-picker'
            }
          },
          shortages_rra_overview_summary: {
            props: {
              widgetRequestParams: this.getWidgetRequestParams(
                'shortages_rra_overview_summary'
              ),
              globalParams: this.getGlobalRequestParams(),
              showWidgetData: this.isWidgetEnabled(
                'shortages_rra_overview_summary'
              ),
              showLastUpdatedDate: this.isWidgetEnabled(
                'shortages_rra_overview_summary'
              ),
              defaultClass: 'shortage-invoices',
              isCardCollapsed: this.isCardCollapsed,
              isMetricToolTipEnabled: true,
              tooltipText: 'Download',
              metricSize1: 'xl',
              metricSize2: 'l',
              isSplit: false,
              metricOffset: 3,
              hideTitle: true,
              headerOptions: [
                {
                  ui_component: 'titleWithIcon',
                  props: {
                    icon: 'shortage-invoices',
                    title: 'Shortage Invoices',
                    titleTooltip: 'View Detailed Dashboard',
                    wrapperClass: '',
                    titleClass: 'widget-title',
                    onClickHeader: () =>
                      this.redirectToIndividualDashboard('shortages')
                  },
                  order: 100
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto'
              },
              footerOptions: this.footerOptionsUpdated
              // stopFetch: !this.minMaxDateRange
            }
          },
          shortages_rra_overview_chargeback: {
            props: {
              widgetRequestParams: this.getWidgetRequestParams(
                'shortages_rra_overview_chargeback'
              ),
              globalParams: this.getGlobalRequestParams(),
              showWidgetData: this.isWidgetEnabled(
                'shortages_rra_overview_chargeback'
              ),
              showLastUpdatedDate: this.isWidgetEnabled(
                'shortages_rra_overview_chargeback'
              ),
              defaultClass: 'chargeback-claims',
              isCardCollapsed: this.isCardCollapsed,
              isMetricToolTipEnabled: true,
              tooltipText: 'Download',
              showCardWidgetTitle: false,
              metricSize1: 'xl',
              metricSize2: 'l',
              isSplit: false,
              metricOffset: 2,
              hideTitle: true,
              headerOptions: [
                {
                  ui_component: 'titleWithIcon',
                  props: {
                    icon: 'chargeback',
                    title: 'Chargeback Claims',
                    titleTooltip: 'View Detailed Dashboard',
                    wrapperClass: '',
                    titleClass: 'widget-title',
                    onClickHeader: () =>
                      this.redirectToIndividualDashboard('chargeback_claims')
                  },
                  order: 100
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': 'auto',
                'grid-gap': '0rem'
              },
              footerOptions: this.footerOptionsUpdated
              // stopFetch: !this.minMaxDateRange
            }
          },
          shortages_rra_overview_sanki: {
            props: {
              widgetRequestParams: this.getWidgetRequestParams(
                'shortages_rra_overview_sanki'
              ),
              globalParams: this.getGlobalRequestParams(),
              showWidgetData: this.isWidgetEnabled(
                'shortages_rra_overview_sanki'
              ),
              showLastUpdatedDate: this.isWidgetEnabled(
                'shortages_rra_overview_sanki'
              ),
              defaultClass: 'shortage_sankey_chart',
              enableDownload: false,
              isMetricToolTipEnabled: true,
              customDownloadEnable: true,
              customDownloadFunction: this.onClickExportHandler,
              onClickHeader: () =>
                this.redirectToIndividualDashboard('shortages'),
              // stopFetch: !this.minMaxDateRange,
              hideTitle: true,
              tooltipText: 'Export',
              iconForDownload: 'share',
              textForIcon: 'Export',
              textClasses: 'u-font-size-8 u-color-grey-lighter u-spacing-pr-xs',
              expoterClass: 'visibility-hidden',
              headerOptions: [
                {
                  ui_component: 'titleWithIcon',
                  props: {
                    icon: 'shortage-invoices',
                    title: 'Shortage Invoices',
                    titleTooltip: 'View Detailed Dashboard',
                    wrapperClass: '',
                    titleClass: 'widget-title',
                    postTitleTemplate:
                      "<div class='u-font-size-3 u-font-weight-600 u-cursor-pointer u-color-grey-lighter'> - JOURNEY MAP</div>",
                    onClickHeader: () =>
                      this.redirectToIndividualDashboard('shortages')
                  },
                  order: 100
                },
                {
                  ui_component: 'div',
                  props: {
                    id: 'date-range-for-export',
                    style: { gridRow: 'span 2' }
                  },
                  order: 301
                },
                {
                  ui_component: 'rraSankeyExport',
                  order: 300
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              footerOptions: this.footerOptionsUpdated
            }
          }
        };
      },
      selectedDateType: 'Invoice Date',
      filterSettings: {
        emitEvent: 'rraDashboardFilterApply',
        uiKey: 0,
        show: false,
        defaultDatePostRange: 'last90Days',
        enableSave: false,
        enableBetweenOperator: true,
        listenerEvent: 'rra-dashboard-filter',
        filterV2: true
      },
      filterData: [],
      primaryData: [
        {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Vendor Code',
          dimensionName: 'ciq_filter',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        }
      ],
      customConfig: {
        title: 'Vendor Code',
        anchor: 'ciq_filter',
        type: 'CLIENT_INTERNAL_CATALOGUE',
        config: {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Vendor Code',
          dimensionName: 'ciq_filter',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        },
        smart: true,
        multi: true
      },
      dateWiseDisabledWidgets: {},
      minMaxDateRange: null,
      dateMinMaxMapping: {}
    };
  },
  computed: {
    isWidgetEnabled() {
      return (widgetName) => {
        if (widgetName === 'shortage_rra_annual_target') {
          const globalParams = this.getGlobalRequestParams();
          const dataRangeName = JSON.parse(
            localStorage.getItem('ShortageRRAOverview-date-picker')
          )?.date_range?.name;
          return !(
            dataRangeName === 'custom' &&
            globalParams[':from'] >
              moment(this.getLastMonthsDate).format('YYYY-MM-DD')
          );
        }
        return !this.dateWiseDisabledWidgets?.[this.selectedDateType]?.includes(
          widgetName
        );
      };
    },
    getLastMonthsDate() {
      return moment().subtract(4, 'months').endOf('month')?.format('LL');
    }
  },
  watch: {
    selectedDateType(newValue) {
      this.setMinMaxDateByDateType(newValue);
    }
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('shortages_rra_overview_summary', RRAOverviewWidget);
    Vue.component('shortages_rra_overview_chargeback', RRAOverviewWidget);
    Vue.component('shortages_rra_overview_sanki', RRASankeyChartWidget);
    Vue.component('shortage_rra_annual_target', targetVsRecoveryChart);
    Vue.component('titleWithIcon', titleWithIcon);
    Vue.component('icon-text', iconText);
    Vue.component('rraSankeyExport', RRASankeyExportWidget);

    setTimeout(() => {
      const scrollDemo = document.getElementById('pageBody');
      scrollDemo.addEventListener(
        'scroll',
        () => {
          this.handleScroll(scrollDemo.scrollTop);
        },
        { passive: true }
      );
    }, 1000);

    eventBus.$on('showOptionsDropdown', this.triggerCustomDateType);
  },
  destroyed() {
    eventBus.$off('showOptionsDropdown');
    eventBus.$off('VendorIDEvent');
  },
  beforeDestroy() {
    document.getElementById('pageBody').removeEventListener('scroll');
  },
  methods: {
    triggerCustomDateType() {
      setTimeout(() => {
        this.$refs.selectTrigger.click();
      }, 200);
    },
    handleScroll(value) {
      const scrollLimit = 0;
      // if (value > scrollLimit) {
      //   const stickyElements = document.querySelectorAll('.shortage-rra-dashboard > div > span:nth-child(2), .shortage-rra-dashboard > div > span:nth-child(3)');
      //   stickyElements.forEach(elem => {
      //     elem.classList.add('is-sticky');
      //   });
      //   // this.isCardCollapsed = true;
      // } else {
      //   const stickyElements = document.querySelectorAll('.shortage-rra-dashboard > div > span:nth-child(2), .shortage-rra-dashboard > div > span:nth-child(3)');
      //   stickyElements.forEach(elem => {
      //     elem.classList.remove('is-sticky');
      //   });
      //   this.isCardCollapsed = false;
      // }
    },
    handleOnClickExport(flag, elementSelector, idToMount) {
      const elementToExport = document.querySelector(elementSelector);
      const dateFilterCopy = document.getElementById(idToMount);
      if (flag) {
        elementToExport.classList.add('save-as-png');
        if (dateFilterCopy) {
          const rightPanelHTML =
            this.$refs?.dashboard_inflator?.$refs?.['right-panel'];
          dateFilterCopy.innerHTML = rightPanelHTML.innerHTML;
          // dateFilterCopy.classList.remove('u-display-none');
          dateFilterCopy.classList.add(...rightPanelHTML.classList);
        }
      } else {
        elementToExport.classList.remove('save-as-png');
        if (dateFilterCopy) {
          dateFilterCopy.innerHTML = '';
          // dateFilterCopy.classList.add('u-display-none');
        }
      }
    },
    redirectToIndividualDashboard(dashboardName) {
      this.$router.push({ name: dashboardName });
    },
    optionSelectedFn(context, selection) {
      if (selection?.length > 0) {
        const widgetArray = [
          'shortages_rra_overview_summary',
          'shortages_rra_overview_chargeback',
          'shortages_rra_overview_sanki'
        ];

        this.selectedDateType = selection[0].title;

        widgetArray.forEach((widget) => {
          const widgetMetadata = this.widgetMetadata.find(
            (widgetData) => widgetData?.name === widget
          );
          const tempWidgetParams = this.getWidgetRequestParams(widget);
          const selectionData = widgetMetadata?.metadata?.dateAPIMApping.find(
            (mapping) => mapping.title === selection[0].title
          );

          tempWidgetParams[':operations'].daterangeDimension =
            selectionData?.value || selection[0].value;
          tempWidgetParams[':operations'].timeseriesDimension =
            selectionData?.value || selection[0].value;

          this.updateWidgetRequestParams(tempWidgetParams, widget);
        });

        this.selectedOptionsData = selection[0];
      }
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
      const metadata = this.widgetMetadata.find(
        (widget) => widget?.name === 'shortages_rra_overview_chargeback'
      );
      this.dropdownOptions = metadata?.metadata?.dateAPIMApping || [];
      this.selectedOptionsData = metadata?.metadata?.defaultDate;
    },
    getFilterInstance(filter) {
      this.filterInstance = filter;
      this.handleFilterMount(this.filterInstance);
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        let dimensionNameValueList = [];
        if (filters?.values) {
          const global = cloneDeep(this.getGlobalRequestParams());
          const where = transformer.generateWhereClause({}, filters, true);
          global[':dimensionNameValueList'] =
            where.dimensionNameValueList || [];
          this.updateGlobalRequestParams(global);
        }
        eventBus.$on('VendorIDEvent', (data) => {
          filters = instance.getSelectedFilters();
          const filterData = {
            values: { ...data, ...filters.values }
          };
          delete filterData.values.date_range;
          const where = {};
          where.dimensionNameValueList = data?.ciq_filter?.map(
            (filterValue) => {
              return {
                dimensionName: 'vendor_code_list',
                dimensionValue: filterValue
              };
            }
          );
          dimensionNameValueList = where.dimensionNameValueList || [];
          const widgetArray = [
            'shortages_rra_overview_summary',
            'shortages_rra_overview_chargeback',
            'shortages_rra_overview_sanki',
            'shortage_rra_annual_target'
          ];
          widgetArray.forEach((widget) => {
            const tempWidgetParams = this.getWidgetRequestParams(widget);
            let updatedTempWidgetParams =
              tempWidgetParams[':dimensionNameValueList']?.filter(
                (item) => item.dimensionName !== 'vendor_code_list'
              ) || [];
            tempWidgetParams[':dimensionNameValueList'] = union(
              updatedTempWidgetParams,
              dimensionNameValueList
            );
            this.updateWidgetRequestParams(tempWidgetParams, widget);
          });
        });
      }
    },
    setVendorCodeFilter(data) {
      this.filterData = data?.entityData?.map((entry) => {
        return {
          ciq_filter: entry.entityValue
        };
      });
    },
    selectedOptionsDataFormatter(data) {
      if (!data?.title) {
        return [];
      }
      let splitArray = data?.title?.split('(');
      let splitArrayResponse = [
        {
          selectorText: 'Date Type:',
          selectorClass: 'date-type',
          selectedZipCode: {
            title: splitArray[0]
          },
          class: 'u-color-blue-base'
        }
      ];
      if (splitArray?.length > 1) {
        splitArrayResponse.push({
          selectedZipCode: {
            title: '(' + splitArray[1]
          },
          class: 'u-color-grey-mid-light'
        });
      }
      return splitArrayResponse;
    },
    fetchDateTypeMinMaxDate(pageApiData) {
      let { service, request, endPoint } = pageApiData.API.dateMinMaxAPI;

      dashUtils
        .fetchDataAPI(request, { service, endPoint })
        .then((response) => {
          if (response.success === true) {
            this.getUpdatedMinMaxDateByDateType(
              pageApiData?.metadata?.dateAPIRangeMapping,
              response?.response?.data
            );
          }
        });
    },
    fetchVendorCodeFilterData(vendorCodeFilterAPI) {
      let { service, request, endPoint } = vendorCodeFilterAPI;

      dashUtils
        .fetchDataAPI(request, { service, endPoint })
        .then((response) => {
          this.setVendorCodeFilter(response);
        });
    },
    fetchExtraData(pageApiData) {
      if (pageApiData?.API?.dateMinMaxAPI) {
        this.fetchDateTypeMinMaxDate(pageApiData);
      }
      if (pageApiData?.API?.vendorCodeFilterAPI) {
        this.fetchVendorCodeFilterData(pageApiData.API.vendorCodeFilterAPI);
      }
      if (pageApiData.metadata?.dateWiseWidgetDisableMapping) {
        this.dateWiseDisabledWidgets =
          pageApiData.metadata?.dateWiseWidgetDisableMapping;
      }
    },
    getUpdatedMinMaxDateByDateType(dateAPIRangeMapping, response) {
      let dateMinMaxMapping = {};
      dateAPIRangeMapping.forEach((el) => {
        const dateTypeWiseResponse = response.find(
          (date) => el.value === date.DIMENSION.page
        );
        if (dateTypeWiseResponse) {
          dateMinMaxMapping[el.title] = {
            maxDate: dateTypeWiseResponse.RESULT.max_feed_date,
            minDate: dateTypeWiseResponse.RESULT.min_feed_date
          };
        }
      });
      this.dateMinMaxMapping = dateMinMaxMapping;
      this.setMinMaxDateByDateType(this.selectedDateType);
    },
    setMinMaxDateByDateType(dateType) {
      this.minMaxDateRange = this.dateMinMaxMapping[dateType];
    },
    onClickExportHandler() {
      const elementSelector = '.rra-sankey-chart';
      const idToMount = 'date-range-for-export';
      this.handleOnClickExport(true, elementSelector, idToMount);

      const month = moment().format('MM');
      const day = moment().format('DD');
      const fileName = `Shortage_Invoices_Journey_Map_${month}/${day}.png`;
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'Image is being generated and will be downloaded shortly.',
        scale: 3,
        elementSelector,
        fileName,
        successEventName: 'triggerExportImageSuccess'
      };
      eventBus.$emit('triggerExportImage', config);
      eventBus.$on('triggerExportImageSuccess', () => {
        this.handleOnClickExport(false, elementSelector, idToMount);
      });
    },
    footerOptionsUpdated(showTruncationText) {
      const lastUpdatedDate = moment(this.minMaxDateRange?.maxDate).format(
        'LL'
      );
      const footerOptions = [
        {
          ui_component: 'icon-text',
          props: {
            text: `Last Updated: ${lastUpdatedDate}`,
            textClasses: 'u-color-grey-lighter u-font-size-6'
          }
        }
      ];
      if (showTruncationText) {
        footerOptions.push({
          ui_component: 'icon-text',
          props: {
            text: `Data is truncated at a week level`,
            textClasses: 'u-color-grey-lighter u-font-size-6'
          }
        });
      }
      return footerOptions;
    }
  }
};
</script>

<style lang="css">
.shortage-rra-dashboard {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.shortage-rra-dashboard .target-vs-recovery-title {
  grid-column-start: 1;
  grid-column-end: 4;
}
.shortage-rra-dashboard .date-picker-trigger {
  border-top-right-radius: 0px !important;
  border-radius: 2px;
}
.shortage-rra-dashboard .filter-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--pageHeaderHeight);
}
.shortage-rra-dashboard .metric-component {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  margin-bottom: 24px !important;
}
.shortage-rra-dashboard .card-header {
  padding: 16px 16px 8px 16px !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.shortage-rra-dashboard .tooltip-header {
  padding-bottom: 0 !important;
}
.shortage-rra-dashboard .card__title {
  font-size: 13px !important;
}
.shortage-rra-dashboard .rb-icon--large:before {
  font-size: 11px;
}
.shortage-rra-dashboard .date-select {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.shortage-rra-dashboard .valueDropdown {
  height: 16px;
  max-height: 16px;
}
.shortage-rra-dashboard .valueDropdown .rb-icon--large {
  position: relative !important;
  top: 2px !important;
  max-width: 9px !important;
}
.shortage-rra-dashboard .valueDropdown .text-select {
  margin-top: 0px;
}
.shortage-rra-dashboard .valueDropdown .text-select > div {
  font-size: 11px;
  text-transform: none;
  font-weight: normal;
  margin-right: 4px;
}
.shortage-rra-dashboard .date-type {
  font-size: 11px;
  color: #8b8f93;
  text-transform: none;
  font-weight: normal;
}
.shortage-rra-dashboard .dropdown-theme {
  position: relative !important;
  bottom: 20px !important;
}
.shortage-rra-dashboard .customDateType .dropdown-trigger {
  height: 16px;
  margin-bottom: 16px !important;
}
.shortage-rra-dashboard .card-container {
  cursor: default !important;
}
.shortage-rra-dashboard .rb-metric--xl .primary_metric {
  font-size: 21px;
}
.shortage-rra-dashboard .rb-metric--xl .secondary_metric {
  font-size: 16px;
  padding-left: 0.8rem;
}
.shortage-rra-dashboard .rb-metric--l .primary_metric {
  font-size: 18px;
}
.shortage-rra-dashboard .rb-metric--l .secondary_metric {
  font-size: 14px;
  padding-left: 0.8rem;
}
.shortage-rra-dashboard > div {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
.shortage-rra-dashboard > div .filter-wrapper {
  width: 100%;
}
.shortage-rra-dashboard > div > span {
  width: 100%;
}
.shortage-rra-dashboard > div > span:nth-child(2),
.shortage-rra-dashboard > div > span:nth-child(3) {
  top: 125px;
  margin-top: 120px;
  align-self: center;
  display: block;
  z-index: 11;
  background-color: #f4f7fa;
}
.shortage-rra-dashboard > div > span:nth-child(2) {
  width: 60%;
}
.shortage-rra-dashboard > div > span:nth-child(3) {
  width: 40%;
}
.shortage-rra-dashboard .shortage-invoices {
  margin-right: 0px !important;
}
.shortage-rra-dashboard .chargeback-claims {
  margin-right: 16px !important;
}
.shortage-rra-dashboard .shortage_sankey_chart {
  margin-right: 16px !important;
  padding-top: 16px !important;
}
.shortage-rra-dashboard .is-sticky .shortage-invoices .rb-metric,
.shortage-rra-dashboard .is-sticky .chargeback-claims .rb-metric {
  align-items: center;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .rb-metric
  .primary_metric,
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .rb-metric
  .primary_metric {
  font-weight: 600;
  font-size: 14px;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .rb-metric
  .secondary_metric,
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .rb-metric
  .secondary_metric {
  padding-left: 0px;
  font-size: 12px;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .rb-metric
  .secondary_metric
  .rb-icon,
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .rb-metric
  .secondary_metric
  .rb-icon {
  padding-left: 0;
  margin: 0px 1px 0px 4px;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .rb-metric
  .secondary_metric
  .rb-icon:before,
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .rb-metric
  .secondary_metric
  .rb-icon:before {
  font-size: 10px;
}
.shortage-rra-dashboard .is-sticky .shortage-invoices .no-data-state,
.shortage-rra-dashboard .is-sticky .chargeback-claims .no-data-state {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center !important;
  padding-top: 0px;
  padding-bottom: 16px;
}
.shortage-rra-dashboard .is-sticky .shortage-invoices .no-data-state .rb-icon,
.shortage-rra-dashboard .is-sticky .chargeback-claims .no-data-state .rb-icon {
  padding-left: 24px;
  margin: 0px 4px 0px 4px !important;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .no-data-state
  .rb-icon:before,
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .no-data-state
  .rb-icon:before {
  font-size: 18px;
}
.shortage-rra-dashboard .is-sticky .shortage-invoices .no-data-state span,
.shortage-rra-dashboard .is-sticky .chargeback-claims .no-data-state span {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 12px;
}
.shortage-rra-dashboard
  .is-sticky
  .shortage-invoices
  .no-data-state
  span:nth-child(2),
.shortage-rra-dashboard
  .is-sticky
  .chargeback-claims
  .no-data-state
  span:nth-child(2) {
  padding-right: 4px !important;
}
.shortage-rra-dashboard .is-sticky .upper-metrics > div:nth-child(1) {
  padding-left: 24px !important;
}
.shortage-rra-dashboard .is-sticky .upper-metric-label {
  padding-right: 4px !important;
  font-size: 15px !important;
}
.shortage-rra-dashboard .is-sticky .metric-component {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding-left: 0px !important;
  padding-right: 16px !important;
  margin-bottom: 16px !important;
}
.shortage-rra-dashboard .is-sticky .metric-component .dot-icon {
  display: none;
}
.shortage-rra-dashboard .is-sticky div[id^='chart-'],
.shortage-rra-dashboard .is-sticky .lower-metrics,
.shortage-rra-dashboard .is-sticky .footer-container-cwc,
.shortage-rra-dashboard .is-sticky .vertical-line {
  display: none;
}
.shortage-rra-dashboard .is-sticky .shortage-invoices,
.shortage-rra-dashboard .is-sticky .chargeback-claims,
.shortage-rra-dashboard .is-sticky .shortage_sankey_chart {
  padding-bottom: 16px !important;
}
.shortage-rra-dashboard .filter-dropdown-menu {
  top: 0px !important;
  left: 0px !important;
  width: 246px !important;
}
.shortage-rra-dashboard .icon-text {
  font-family: 'ProximaNova' !important;
  font-size: 9px !important;
}
.shortage-rra-dashboard .widget-title {
  text-underline-offset: 6px;
  text-decoration: underline;
  text-decoration-style: dotted;
}
.save-as-png.rra-sankey-chart {
  width: 26000px !important;
  max-width: 2600px !important;
}
.save-as-png .shortage_sankey_chart {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.save-as-png .sankey-box {
  overflow: visible !important;
}
</style>
