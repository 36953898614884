<template>
  <div :class="{ 'u-spacing-mb-l': config.chartOptions.legend != false }">
    <div class="c3-line-chart" />
  </div>
</template>

<script>
import chartMixin from '@/utils/mixins/boomerangChartMixin';
import c3 from 'c3';
import Vue from 'vue';
import moment from 'moment';
// import * as d3 from 'd3';

export default {
  mixins: [chartMixin],
  props: {
    isPVPTimeseriesChart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedCurrency() {
      return this.$store?.getters?.getSelectedCurrency || {};
    }
  },
  watch: {
    data: function (newData) {
      if (newData) {
        if (newData.constructor === Object) {
          if (newData.legends) {
            this.customLegends = newData.legends;
          }
          if (newData.xs) {
            delete this.chartConfig.data.x;
            this.chartConfig.data.xs = newData.xs;
          }
          if (newData.groups) {
            this.chartConfig.data.groups = newData.groups;
          }
          if (newData.names) {
            this.chartConfig.data.names = newData.names;
          }
          if (newData.colors) {
            this.chartConfig.data.colors = newData.colors;
          }
          if (newData.regions) {
            this.chartConfig.data.regions = newData.regions;
          }
          if (newData.axes) {
            this.chartConfig.data.axes = newData.axes;
          }
          if (newData.types) {
            this.chartConfig.data.types = newData.types;
          }
          // set the height & width of the chart.
          if (newData.size) {
            this.chartConfig.size = newData.size;
          }
          // Bar Configuration, To change width of the bar.
          if (newData.bar) {
            this.chartConfig.bar = newData.bar;
          }
          if (newData.color) {
            this.chartConfig.color = newData.color;
          }
          if (newData.axis_format && newData.axis_format.y) {
            this.chartConfig.axis.y = Object.assign(
              this.chartConfig.axis.y,
              newData.axis_format.y
            );
            this.chartConfig.axis.y.tick.format = (value) => {
              return Vue.options.filters.num_format(
                value,
                newData.axis_format.y.pre,
                newData.axis_format.y.suff,
                newData.axis_format.y.min,
                newData.axis_format.y.roundoff,
                undefined,
                this.selectedCurrency
              );
            };
          }
          if (newData.axis_format && newData.axis_format.y2) {
            this.chartConfig.axis.y2 = Object.assign(
              this.chartConfig.axis.y2,
              newData.axis_format.y2
            );
            this.chartConfig.axis.y2.tick.format = (value) => {
              return Vue.options.filters.num_format(
                value,
                newData.axis_format.y2.pre,
                newData.axis_format.y2.suff,
                newData.axis_format.y2.min,
                newData.axis_format.y2.roundoff,
                undefined,
                this.selectedCurrency
              );
            };
          }

          if (this.chartConfig.dynamicRange && this.d3Instance) {
            const events = this.chartConfig.events.map((item) => {
              return item.key;
            });
            this.scales = {};
            this.originalValues = {};
            const format = {};
            this.rangeInfo = {};

            var getScale = function (name, data) {
              var extent = this.d3Instance.extent(data);
              let dynamicRangeValues =
                sentMetrics.length === 2 ? [0, 100] : [35, 100];
              if (this.chartConfig?.dynamicRangeValues?.length) {
                dynamicRangeValues = this.chartConfig?.dynamicRangeValues;
              }
              var myScale = this.d3Instance
                .scaleLinear()
                .domain(extent)
                .range(dynamicRangeValues);
              this.scales[name] = myScale;
              this.rangeInfo[name] = extent;
              this.originalValues[name] = [...data];
              return [name].concat(data).map((value, index) => {
                return index ? this.scales[name](value) : value;
              });
            }.bind(this);

            const sentEvents = [];
            const sentMetrics = [];

            newData.data.map((item) => {
              var key = item[0];
              if (key.indexOf('_date') >= 0) {
                return;
              }
              if (events.indexOf(key) === -1) {
                sentMetrics.push(key);
              } else {
                sentEvents.push(key);
              }
            });
            this.chartConfig.axis.y.show = false;
            this.chartConfig.axis.y2.show = false;
            this.chartConfig.axis.y.tick = {};
            this.chartConfig.axis.y2.tick = {};
            this.config.chartOptions.axis_format = {};

            if (sentMetrics.length === 1) {
              this.chartConfig.axis.y.show = true;
              this.chartConfig.axis.y.tick.format = (value) => {
                value = this.scales[sentMetrics[0]]
                  ? this.scales[sentMetrics[0]].invert(value).toFixed(5)
                  : value;
                if (value < (this.rangeInfo[sentMetrics[0]] || [])[0] || 0) {
                  return '';
                }
                const format =
                  (this.config.chartOptions.tooltip_format || {})[
                    sentMetrics[0]
                  ] || {};
                return Vue.options.filters.num_format(
                  value,
                  format?.pre,
                  format?.suff,
                  format?.min,
                  format?.roundoff,
                  undefined,
                  this.selectedCurrency
                );
              };
            } else if (sentMetrics.length === 2) {
              this.chartConfig.axis.y2.show = true;
              this.chartConfig.axis.y.show = true;
              this.chartConfig.data.axes[sentMetrics[1]] = 'y2';
              this.chartConfig.axis.y.tick.format = (value) => {
                value = this.scales[sentMetrics[0]]
                  ? this.scales[sentMetrics[0]].invert(value).toFixed(5)
                  : value;
                if (value < (this.rangeInfo[sentMetrics[0]] || [])[0] || 0) {
                  return '';
                }
                const format =
                  (this.config.chartOptions.tooltip_format || {})[
                    sentMetrics[0]
                  ] || {};
                return Vue.options.filters.num_format(
                  value,
                  format.pre,
                  format.suff,
                  format.min,
                  format.roundoff,
                  undefined,
                  this.selectedCurrency
                );
              };
              this.chartConfig.axis.y2.tick.format = (value) => {
                value = this.scales[sentMetrics[1]]
                  ? this.scales[sentMetrics[1]] &&
                    this.scales[sentMetrics[1]].invert(value).toFixed(5)
                  : value;
                if (value < (this.rangeInfo[sentMetrics[1]] || [])[0] || 0) {
                  return '';
                }
                const format =
                  (this.config.chartOptions.tooltip_format || {})[
                    sentMetrics[1]
                  ] || {};
                return Vue.options.filters.num_format(
                  value,
                  format.pre,
                  format.suff,
                  format.min,
                  format.roundoff,
                  undefined,
                  this.selectedCurrency
                );
              };
            }

            if (sentMetrics.length > 0) {
              newData.data = newData.data.map((item) => {
                var _temp = [...item];
                var key = _temp[0];
                _temp.shift();

                format[key] = function (v, id, index, array) {
                  v = this.scales[id]
                    ? this.scales[id].invert(v).toFixed(5)
                    : v;
                  const text = array[index];
                  var valuesArray = array.map((item) => {
                    return this.d3Instance.select(item).data()[0].value;
                  });
                  const minIndex = valuesArray.indexOf(
                    Math.min(...valuesArray)
                  );
                  const maxIndex = valuesArray.indexOf(
                    Math.max(...valuesArray)
                  );

                  // if (index === 0 || index === array.length - 1 || Math.floor(index === array.length / 2)) {
                  if (index === minIndex || index === maxIndex) {
                    var tooltipFormat =
                      ((this.config.chartOptions || {}).tooltip_format || {})[
                        id
                      ] || {};
                    if (Object.keys(tooltipFormat).length === 0) {
                      tooltipFormat =
                        ((this.config.chartOptions || {}).tooltip_format || {})
                          .All || {};
                    }

                    this.d3Instance.select(text).style('font-weight', '600');
                    this.d3Instance.select(text).style('font-size', '16px');
                    const classString =
                      text.getAttribute('class') + ' metricLabel';

                    if (index === minIndex) {
                      text.setAttribute('class', classString + ' minimum');
                      this.d3Instance
                        .select(text)
                        .style('transform', 'translateY(' + 30 + 'px)');
                      this.d3Instance
                        .select(text)
                        .style('transform', 'translatex(' + 30 + 'px)');
                    } else if (index === maxIndex) {
                      text.setAttribute('class', classString + ' maximum');
                      this.d3Instance
                        .select(text)
                        .style('transform', 'translateY(' + -15 + 'px)');
                      this.d3Instance
                        .select(text)
                        .style('transform', 'translatex(' + 30 + 'px)');
                    }

                    if (tooltipFormat.format !== false) {
                      return Vue.options.filters.num_format(
                        v,
                        tooltipFormat.pre,
                        tooltipFormat.suff,
                        tooltipFormat.min,
                        tooltipFormat.roundoff,
                        undefined,
                        this.selectedCurrency
                      );
                    } else {
                      return v;
                    }
                  } else {
                    this.d3Instance.select(text).style('display', 'none');
                  }
                }.bind(this);

                if (events.indexOf(key) === -1 && key.indexOf('date') === -1) {
                  const _tempCopy = [...item];
                  _temp = getScale(key, _temp);
                  // Don't remove map function. It's to ensure the null data in the chart
                  _tempCopy.map((item, index) => {
                    if (!item && item !== 0) {
                      _temp[index] = null;
                    }
                    return item;
                  });
                } else {
                  _temp = [key].concat(_temp);
                }
                return _temp;
              });
            }
            // this.chartConfig.data.labels = {
            //   format
            // }
          }
          this.$set(this.chartConfig.data, 'columns', [
            ...(newData.data || [])
          ]);
          this.chartConfig.data.classes = newData.classes || [];
        } else {
          this.$set(this.chartConfig.data, 'columns', newData);
        }
        c3.generate(this.chartConfig);
        if (
          this.isPVPTimeseriesChart &&
          this.chartConfig.axis.x.type === 'category'
        ) {
          this.fixLabels();
        }
        this.$emit('updateStroke');
      }
    }
  },
  mounted() {
    c3.generate(this.chartConfig);
  },
  methods: {
    fixLabels() {
      if (this.d3Instance) {
        this.d3Instance.selectAll('text[y="9"]').each((a, b, c) => {
          let [currentDate, prevDate] = this.config?.xAxisCategories?.[
            a
          ]?.split('-') || ['', ''];

          [currentDate, prevDate] = [
            moment(currentDate, 'MM/DD/YYYY', true)?.isValid()
              ? moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
              : '-',
            moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
              ? moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
              : '-'
          ];
          if (c[a]) {
            c[
              a
            ].innerHTML = `<tspan x="0" dy="1em" dx="0" fill="#8B8F93">${currentDate}</tspan>
            <tspan  x="0" dy="1em" dx="0" fill="#8B8F93">${prevDate}</tspan>`;
          }
        });
      }
    }
  }
};
</script>
<style lang="css">
.realtime-stroked-chart-lines {
  stroke-dasharray: 4 4;
  opacity: 0.5;
}
g[class*='_realtime'] {
  opacity: 0.5;
}
g[class*='_realtime'] circle {
  opacity: 0.2;
  stroke: #aaadb1 !important;
}
</style>
