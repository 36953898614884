var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showErrorBlob)?_c('div',{attrs:{"data-cy":"errorBlob"}},[_c('div',{staticClass:"u-spacing-mt-m u-spacing-mh-m u-border-radius-s",class:[
      _vm.containerClasses,
      _vm.hideDropdownContent ? 'u-spacing-mb-m u' : 'error-blob-top-container'
    ]},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-ph-s u-spacing-pt-s"},[_c('rb-icon',{staticClass:"rb-icon--medium u-color-red-base u-flex-align-self-flex-start",attrs:{"icon":_vm.primaryHeaderIcon}}),_c('span',{staticClass:"u-spacing-ph-s u-font-size-7 u-font-weight-400 u-color-grey-base u-line-height-1-3",attrs:{"data-cy":"errorBlobPrimaryHeaderText"}},[_vm._v(_vm._s(_vm.primaryHeaderText))]),_c('div',{staticClass:"u-display-flex u-flex-justify-content-flex-end u-cursor-pointer u",on:{"click":_vm.closeModal}},[_c('rb-icon',{staticClass:"u-color-grey-mid-light rb-icon--small",attrs:{"icon":'cross',"data-cy":"errorBlobCloseIcon"}})],1)],1),_c('div',{staticClass:"u-spacing-pl-xl u-spacing-pr-l u-line-height-1-4"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-pt-xs u-cursor-pointer",class:_vm.isDropdownOpen ? 'u-spacing-pb-xs' : 'u-spacing-pb-s',attrs:{"data-cy":"errorBlobDropdownHeader"},on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"u-spacing-pr-xs u-font-size-7 u-font-weight-600 u-color-blue-base",class:_vm.dropdownTriggered ? 'dropdownHeaderAnim' : ''},[_vm._v(_vm._s(_vm.generateDropdownHeader))]),_c('rb-icon',{staticClass:"u-color-blue-base rb-icon--xx-small",attrs:{"icon":_vm.generateDropdownHeaderIcon}})],1)])]),(!_vm.hideDropdownContent)?_c('div',{staticClass:"error-blob-lower-container u-spacing-mb-m u-spacing-mh-m u-border-radius-s",class:[
      _vm.containerClasses,
      _vm.isDropdownOpen ? 'errorContainerOpenAnim' : 'errorContainerCloseAnim'
    ]},[_c('div',{staticClass:"u-spacing-pl-xl u-spacing-pb-s u-spacing-pr-l u-line-height-1-4"},[_c('div',{class:_vm.isDropdownOpen
            ? 'dropdownContentAnim'
            : 'dropdownContentReverseAnim',attrs:{"data-cy":"errorBlobDropdownContent"}},[(!_vm.hideDropdownContent)?_vm._t("dropdownContent"):_vm._e()],2)])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }