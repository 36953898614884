import Vue from 'vue';
import { operator } from '@/utils/helpers/operator.js';
import moment from 'moment-timezone';
import { eventBus } from '@/utils/services/eventBus';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';
const filtersExtension = multiRetailerConfig.filtersExtension.default;
const getLockoutValues = (lockoutValues) => {
  const metrics = {};
  const tempMetric = {
    attribute: lockoutValues.attribute.metric,
    dataType: lockoutValues.attribute.dataType,
    value: lockoutValues.value,
    operator: lockoutValues.operator.item
  };
  metrics[lockoutValues.metrics] = {};
  metrics[lockoutValues.metrics][tempMetric.attribute] = tempMetric;
  return metrics;
};

export default {
  data() {
    return {
      numberOfDaysmutiplier: {
        Days: 1,
        Months: 30,
        Years: 365
      },
      currency: this.$currency
    };
  },
  created() {},
  computed: {
    metaDataConfig() {
      return this.$store.getters.getStrategyStaticMetaData;
    }
  },
  methods: {
    formatAllDisplayText() {
      if (this.stepsConfig) {
        this.stepsConfig.forEach((config) => {
          if (config.textMapping) {
            this.formatDisplayText(config.textMapping);
          }
        });
      }
    },
    dimensionMetaDataMapping(params) {
      const temp = this.$store.getters.getPrimaryFilterData.filter((item) => {
        if (item.dimensionName === params) {
          return item;
        }
      })[0];
      return temp || {};
    },
    operatorNameMapping(name) {
      return operator[name];
    },
    showFiltersValues(filterArray) {
      if (filterArray.length > 0) {
        var maxStrLen = 36;
        var concatStr = '';
        for (var idx = 0; idx <= filterArray.length; idx++) {
          var curSelected = filterArray[idx];
          if (concatStr.length >= maxStrLen - 3) {
            concatStr = concatStr.slice(0, maxStrLen - 3);
            // add '...' if all the selections is not fit into concatStr
            for (var k = 0; k < idx; k++) {
              if (!concatStr.includes(filterArray[k])) {
                concatStr = concatStr.concat('...');
              }
            }
            // Handling edge case ',' in the end for unclipped selections.
            if (concatStr.charAt(concatStr.length - 1).includes(',')) {
              concatStr = concatStr.slice(0, concatStr.length - 1);
            }
            var moreCount = filterArray.length - idx;
            concatStr = concatStr.concat(
              idx < filterArray.length ? ' & ' + moreCount + ' more' : ''
            );
            break;
          } else if (curSelected && curSelected.length > 0) {
            concatStr = concatStr.concat(
              curSelected,
              idx < filterArray.length - 1 ? ', ' : ''
            );
          }
        }
        return `${concatStr}`;
      }
    },
    entityTitleFilter(value, valueToReplace) {
      if (!value && !valueToReplace) return '';
      return value.replace('Entity', valueToReplace);
    },
    findOptimalMetric(days) {
      let optimalMetric, optimalValue;
      if (days % this.numberOfDaysmutiplier.Years === 0) {
        optimalMetric = 'Years';
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Years);
      } else if (days % this.numberOfDaysmutiplier.Months === 0) {
        optimalMetric = 'Months';
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Months);
      } else {
        optimalValue = days;
        optimalMetric = optimalValue > 1 ? 'days' : 'day';
      }
      return `${optimalValue + ' ' + optimalMetric}`;
    },
    formatDisplayText(step) {
      let text = '';
      const type = step || '';
      let { selectedValues = {} } = this;
      selectedValues = JSON.parse(JSON.stringify(selectedValues));
      if (type === 'objective') {
        text = selectedValues.objective && selectedValues.objective.name;
        Vue.set(this.selectedValues.strategyInfoText, type, text);
        Vue.set(this.selectedValues.strategyUIText, type, text);
      } else if (type === 'scope') {
        text = '';
        let allFilterValuesText = '';
        if (selectedValues.scope && selectedValues.scope.subFilter) {
          text = `${selectedValues.scope.subFilter.name} of ${selectedValues.strategyEntityType.entityName}`;
        } else {
          const pluralEntities = [
            'ams skus',
            'skus',
            'search terms',
            'ad skus',
            'line items'
          ];
          const plural =
            selectedValues?.strategyEntityType?.entityName &&
            pluralEntities.includes(
              selectedValues.strategyEntityType.entityName.toLowerCase()
            )
              ? ''
              : 's';
          text =
            `All ${
              selectedValues.strategyEntityType &&
              selectedValues.strategyEntityType.entityName
            }` + plural;
        }
        allFilterValuesText = text;
        const scopeFilterValues = cloneDeep(
          this.selectedValues.scopeFilterValues || {}
        );
        if (
          scopeFilterValues &&
          selectedValues.scope.subFilter &&
          selectedValues.scope.subFilter.custom
        ) {
          text += ' in ';
          let tempObj = scopeFilterValues;
          // check if the tempObj is having transformed values of filter.
          if (Array.isArray(tempObj)) {
            tempObj = this.transformFilterFromAPI(tempObj);
          }
          Object.keys(tempObj).forEach((rule, index) => {
            const digitalShelfLabel = {
              dimensionLabel: filtersExtension[rule]?.name
            };
            const label =
              (digitalShelfLabel?.dimensionLabel && digitalShelfLabel) ||
              this.dimensionMetaDataMapping(rule) ||
              {};
            let values = tempObj[rule];
            if (filtersExtension[rule]?.delimiter) {
              values = values.map((val) =>
                val.replaceAll(
                  filtersExtension[rule]?.delimiter,
                  filtersExtension[rule]?.uiDelimiter
                )
              );
            }
            if (values.length === 2 && values[0].value) {
              const tempVal = transformer.getConversionToBetweenFilter(values);
              values[0] = tempVal;
            }
            if (values[0] && values[0].value) {
              text += `${label.dimensionLabel || 'NA'}`;
              const dimensionObj = values[0];
              // Value of type Operator
              text += ` ${dimensionObj.operator.title || '--'}`;
              if (dimensionObj.unit === '%') {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value,
                  undefined,
                  '%'
                )}`;
              } else if (dimensionObj.unit) {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value,
                  'currency'
                )}`;
              } else {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value
                )}`;
              }
              allFilterValuesText = text;
            } else {
              allFilterValuesText += `${
                label.dimensionLabel || 'NA'
              } : ${values.toString()}`;
              text += `${
                label.dimensionLabel || 'NA'
              } : ${this.showFiltersValues(values)}`;
            }
            if (Object.keys(tempObj).length - 1 !== index) {
              text += ' and ';
              allFilterValuesText += ' and ';
            }
          });
        }
        Vue.set(
          this.selectedValues.strategyInfoText,
          type,
          allFilterValuesText
        );
        Vue.set(this.selectedValues.strategyUIText, type, text);
      } else if (type === 'condition') {
        text = '';
        const { conditionFilterValues = {} } = this.selectedValues;
        if (
          conditionFilterValues &&
          Object.keys(conditionFilterValues).length > 0
        ) {
          let tempObj = conditionFilterValues;
          // check if the tempObj is having transformed values of filter.
          if (Array.isArray(tempObj)) {
            tempObj = this.transformFilterFromAPI(tempObj);
          }
          Object.keys(tempObj).forEach((rule, index) => {
            const label = this.dimensionMetaDataMapping(rule) || {};
            const values = tempObj[rule];
            if (values[0] && values[0].value) {
              text += `${label.dimensionLabel || 'NA'}`;
              const dimensionObj = values[0];
              // Value of type Operator
              text += ` ${dimensionObj.operator.title || '--'}`;
              if (dimensionObj.unit === '%') {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value,
                  undefined,
                  '%'
                )}`;
              } else if (dimensionObj.unit) {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value,
                  'currency'
                )}`;
              } else {
                text += ` ${Vue.options.filters.num_format(
                  dimensionObj.value
                )}`;
              }
            } else {
              if (label.dimensionType === 'STRATEGY') {
                text += `${label.dimensionLabel || 'NA'} : ${values
                  .map((ruleJSON) => JSON.parse(ruleJSON).strategyName)
                  .join(', ')}`;
              } else {
                text += `${
                  label.dimensionLabel || 'NA'
                } : ${this.showFiltersValues(values)}`;
              }
            }
            if (Object.keys(tempObj).length - 1 !== index) {
              text += ' and ';
            }
          });
          if (
            selectedValues.lookBackWindow &&
            selectedValues.lookBackWindow.name === 'Choose custom last X days'
          ) {
            text =
              text +
              ` from last ${this.findOptimalMetric(
                selectedValues.lookBackWindow.numberOfDays
              )} `;
            if (selectedValues.lookBackWindow.numberOfDaysExcluded > 0) {
              text =
                text +
                ` except last ${this.findOptimalMetric(
                  selectedValues.lookBackWindow.numberOfDaysExcluded
                )} `;
            }
          } else {
            text =
              text +
              ` from ${
                (selectedValues.lookBackWindow &&
                  selectedValues.lookBackWindow.name) ||
                ''
              } `;
          }
        }
        Vue.set(this.selectedValues.strategyInfoText, type, text);
        Vue.set(this.selectedValues.strategyUIText, type, text);
      } else if (type === 'actions') {
        text = '';
        text = selectedValues.actions && selectedValues.actions.actionName;
        Vue.set(this.selectedValues.strategyInfoText, type, text);
        Vue.set(this.selectedValues.strategyUIText, type, text);
      } else if (type === 'timeperiod') {
        text = '';
        text += `Starting from ${this.$options.filters.dateFormatFilter(
          selectedValues.strategyStartDate
        )} `;
        if (selectedValues.strategyEndDate) {
          text += `to ${this.$options.filters.dateFormatFilter(
            selectedValues.strategyEndDate
          )}`;
        } else {
          text += 'Until forever';
        }
        Vue.set(this.selectedValues.strategyInfoText, type, text);
        Vue.set(this.selectedValues.strategyUIText, type, text);
      }
    },
    transformFilterFromAPI(params, page) {
      const multipleValuesWithSameFilter = [];
      let multipleValues = false;
      let multipleDimension = [];
      // Transform the object to the format in which filter understand.
      // TODO: Filter revamp: In case of expression, filter object needs metadata information like unit, title from the consumer end, this should not be the case.
      var obj = {};
      params.forEach((item) => {
        multipleValues = false;
        const dimensionObj = this.dimensionMetaDataMapping(item.dimensionName);
        if (
          dimensionObj &&
          dimensionObj.dimensionType &&
          dimensionObj.dimensionType === 'EXPRESSION'
        ) {
          let unit = '';
          if (dimensionObj) {
            if (
              dimensionObj.addtionalMetadata &&
              dimensionObj.addtionalMetadata.unit === 'CURRENCY'
            ) {
              unit = this.$currency;
            } else if (
              dimensionObj.addtionalMetadata &&
              dimensionObj.addtionalMetadata.unit === 'PERCENTAGE'
            ) {
              unit = '%';
            } else {
              unit = '';
            }
          }
          let value = {
            unit: unit,
            value: item.dimensionValue,
            operator: {
              operator: item.operator,
              title: this.operatorNameMapping(item.operator)
            }
          };
          multipleValuesWithSameFilter.forEach((dimension) => {
            if (dimension[item.dimensionName]) {
              multipleValues = true;
              multipleDimension = dimension[item.dimensionName];
            }
          });
          if (multipleValues) {
            multipleDimension.push(value);
            const newTempValue =
              transformer.getConversionToBetweenFilter(multipleDimension);
            value = newTempValue;
          }
          obj[item.dimensionName] = [];
          obj[item.dimensionName].push(value);
        } else {
          for (const key in filtersExtension) {
            // remove "custom~" || "standard~" and identify filter type based on this value
            if (
              item.dimensionName === filtersExtension[key].apiValue &&
              filtersExtension[key]?.type?.length &&
              item.dimensionValue.includes(
                filtersExtension[key].type +
                  filtersExtension[key].replaceDelimiter
              )
            ) {
              item.dimensionName = key;
              item.dimensionValue = filtersExtension[key].endsWithADelimiter
                ? item.dimensionValue.slice(0, -1)
                : item.dimensionValue;
              item.dimensionValue = item.dimensionValue
                .replace(
                  filtersExtension[key].type +
                    filtersExtension[key].replaceDelimiter,
                  ''
                )
                .replaceAll(
                  filtersExtension[key].replaceDelimiter,
                  filtersExtension[key].delimiter
                );
            } else if (
              item.dimensionName === filtersExtension[key].apiValue &&
              !filtersExtension[key]?.type?.length
            ) {
              item.dimensionName = key;
            }
          }
          if (obj[item.dimensionName]) {
            obj[item.dimensionName].push(item.dimensionValue);
          } else {
            obj[item.dimensionName] = [];
            obj[item.dimensionName].push(item.dimensionValue);
          }
        }
        multipleValuesWithSameFilter.push(obj);
      });
      return obj;
    },
    transformFilter(params) {
      const rules = [];
      if (Array.isArray(params)) {
        return params;
      }
      Object.keys(params).forEach((item) => {
        params[item].forEach((value) => {
          const obj = {};
          obj.dimensionName = item;
          if (
            value.value &&
            value.operator &&
            value.operator.operator === 'BETWEEN'
          ) {
            // Rules for BETWEEN FILTER
            const arr = value.value.split(',');
            obj.dimensionValue = arr[0].trim();
            obj.operator = 'GREATER_THAN_OR_EQUAL_TO';
            const obj1 = {};
            obj1.dimensionName = item;
            obj1.dimensionValue = arr[1].trim();
            obj1.operator = 'LESS_THAN_OR_EQUAL_TO';
            rules.push(obj1);
          } else if (
            value.value ||
            ['IS_NOT_NULL', 'IS_NULL'].includes(value?.operator?.operator)
          ) {
            obj.dimensionValue = value.value ?? '';
            obj.operator = value.operator.operator;
          } else {
            obj.dimensionValue = value;
            if (obj.dimensionName === 'tags') {
              obj.operator = 'EQUAL_TO';
            }
          }
          rules.push(obj);
        });
      });
      return rules;
    },
    digitalShelfFilters(scopeRules) {
      const heirarchyData = this.$store.getters.getHeirarchyFilterData;
      const digitalShelfScopeRules = [];
      if (!heirarchyData) return digitalShelfScopeRules;
      for (const item of scopeRules) {
        if (item.dimensionName === 'standardShelf') {
          const identifiers = [];
          const values = item.dimensionValue.split('----');
          this.traverse(heirarchyData[values[0]].children, 0, identifiers);
          for (const val of identifiers) {
            if (
              val.identifier.includes(item.dimensionValue) &&
              val.level >= values.length - 1
            ) {
              digitalShelfScopeRules.push({
                dimensionName: 'standardShelf',
                dimensionValue: val.identifier
              });
            }
          }
        }
      }
      return digitalShelfScopeRules;
    },

    traverse(root, level, identifiers) {
      for (const name in root) {
        if (root[name]?.children && !!Object.keys(root[name].children).length) {
          this.traverse(root[name].children, level + 1, identifiers);
        }
        identifiers.push({
          identifier: root[name].identifier,
          level: level
        });
      }
    },

    transformExtensionFilters(scopeRules) {
      scopeRules = scopeRules.concat(this.digitalShelfFilters(scopeRules));
      return scopeRules.map((rule) => {
        for (const key in filtersExtension) {
          if (rule.dimensionName === key) {
            rule.dimensionName = filtersExtension[key].apiValue;
            rule.dimensionValue =
              filtersExtension[key].type +
              filtersExtension[key].replaceDelimiter +
              rule.dimensionValue.replaceAll(
                filtersExtension[key].delimiter,
                filtersExtension[key].replaceDelimiter
              );
            if (filtersExtension[key].endsWithADelimiter) {
              rule.dimensionValue += filtersExtension[key].replaceDelimiter;
            }
            rule.dimensionLabel = filtersExtension[key].name;
            if (filtersExtension[key].operator) {
              rule.operator = filtersExtension[key].operator;
            }
          }
        }
        return rule;
      });
    },
    saveStrategy(params) {
      // debugger;
      // Call formatAllDisplayText method again, text is formed on click of the next button but some times, when the form is valid, user clicks top save btn, text is not formed for that particular step.
      this.formatAllDisplayText();

      this.$store.dispatch('setLoadingState', true);
      let scopeRules = null;
      if (
        this.selectedValues.scope.subFilter &&
        this.selectedValues.scope.subFilter.custom
      ) {
        if (this.selectedValues.scopeFilterValues) {
          scopeRules = this.transformFilter(
            this.selectedValues.scopeFilterValues
          );
          scopeRules = this.transformExtensionFilters(scopeRules);
        }
      } else if (
        this.selectedValues.scope.subFilter &&
        !this.selectedValues.scope.subFilter.custom
      ) {
        // Standard rule
        scopeRules = [...this.selectedValues.scope.subFilter.defaultRules];
      }

      let rules = [];
      rules = this.transformFilter(this.selectedValues.conditionFilterValues);

      // Transform Rules
      const executionTemplate = {
        type: 'actionBuilder',
        actionType:
          this.selectedValues.actions && this.selectedValues.actions.actionType,
        actionFields: []
      };
      const { availableActionTypes } = multiRetailerConfig;
      if (this.selectedValues.bidMultiplierData) {
        const actionFields = this.selectedValues.bidMultiplierData;

        if (this.selectedValues.lockoutValues?.length) {
          const { lockoutValues = {} } = this.selectedValues;
          const metrics = getLockoutValues(lockoutValues);
          executionTemplate.lockoutMetadata = {};
          executionTemplate.lockoutMetadata.metrics = metrics;
        }
        executionTemplate.actionFields.push(...actionFields);
      } else if (this.selectedValues.actionsPanelSubsteps) {
        const actionFields = this.selectedValues.actionsPanelSubsteps.map(
          (item) => item.requestObj
        );
        if (this.selectedValues.lockoutValues) {
          const { lockoutValues = {} } = this.selectedValues;
          const metrics = getLockoutValues(lockoutValues);
          executionTemplate.lockoutMetadata = {};
          executionTemplate.lockoutMetadata.metrics = metrics;
        }
        executionTemplate.actionFields.push(...actionFields);
      } else if (
        this.selectedValues.actions &&
        this.selectedValues.actions.actionType &&
        availableActionTypes.includes(this.selectedValues.actions.actionType)
      ) {
        const actionFields = {
          ...(this.selectedValues.actionPanelValues &&
            this.selectedValues.actionPanelValues.requestObj)
        };
        if (this.selectedValues.lockoutValues) {
          const { lockoutValues = {} } = this.selectedValues;
          const metrics = getLockoutValues(lockoutValues);
          // const metrics = {};
          // const tempMetric = {
          //   attribute: lockoutValues.attribute.metric,
          //   dataType: lockoutValues.attribute.dataType,
          //   value: lockoutValues.value,
          //   operator: lockoutValues.operator.item
          // };
          // metrics[lockoutValues.metrics] = {};
          // metrics[lockoutValues.metrics][tempMetric.attribute] = tempMetric;
          executionTemplate.lockoutMetadata = {};
          executionTemplate.lockoutMetadata.metrics = metrics;
        }
        executionTemplate.actionFields.push(actionFields);
      }
      const obj = {
        strategyStatus: 'LIVE',
        strategyActive: true,
        timezone: 'GMT',
        strategyName: this.selectedValues.strategyName,
        description: this.selectedValues.description,
        strategyType: 'STRATEGY_BUILDER',
        objective: this.selectedValues.objective.objective,
        scope: {
          subFilter:
            this.selectedValues.scope.subFilter &&
            this.selectedValues.scope.subFilter.name,
          scopeRules: cloneDeep(scopeRules)
        },
        strategyEntityType:
          this.selectedValues.strategyEntityType &&
          this.selectedValues.strategyEntityType.entityType,
        rules: rules,
        lookBackWindow: this.selectedValues.lookBackWindow,
        actions: [{ executionTemplate: executionTemplate }],
        strategyStartDate: moment(
          `${moment(this.selectedValues.strategyStartDate).format(
            'YYYY-MM-DD'
          )} ${moment().format('HH:mm:ss')}`
        ).toISOString(),
        strategyEndDate: this.selectedValues.strategyEndDate
          ? moment(
              `${moment(this.selectedValues.strategyEndDate).format(
                'YYYY-MM-DD'
              )} ${moment().format('HH:mm:ss')}`
            ).toISOString()
          : null
      };

      if (params === 'draft' || params === 'simulate') {
        obj.strategyStatus = 'DRAFT';
        obj.strategyStartDate = obj.strategyStartDate
          ? obj.strategyStartDate
          : moment().toISOString();
      }

      let method = 'post';
      if (this.selectedValues.mode === 'edit') {
        method = 'put';
        obj.strategyUniqueId = this.$route.query.id;
      }

      // Hacky solution for Action logs
      obj.strategyInfoText = { ...this.selectedValues.strategyInfoText };

      this.$store
        .dispatch('saveStrategy', { method: method, request: obj })
        .then(
          (response) => {
            this.$store.dispatch('setLoadingState', false);
            this.$snackbar.open({
              message: 'Strategy saved successfully',
              duration: 5000,
              actionText: ''
            });
            if (params === 'draft') {
              this.$router.push({ name: 'StrategyList' });
            } else if (params === 'simulate') {
              if (this.selectedValues.mode === 'create') {
                this.$router.push({
                  name: 'StrategyBuilderEdit',
                  query: {
                    mode: 'edit',
                    id: response.data.strategyUniqueId,
                    simulate: true
                  }
                });
                // Vue.set(this.selectedValues, 'mode', 'edit');
                // Vue.set(this.selectedValues, 'strategyStatus', 'DRAFT');
                this.selectedValues.strategyStatus = 'DRAFT';
                this.selectedValues.mode = 'edit';
                eventBus.$emit('simulateStrategy');
              } else {
                Vue.set(this.selectedValues, 'strategyStatus', 'DRAFT');
                eventBus.$emit('simulateStrategy');
              }
            } else {
              this.$router.push({
                name: 'StrategyList',
                query: {
                  id: response.data.strategyUniqueId
                }
              });
            }
          },
          (error) => {
            this.$store.dispatch('setLoadingState', false);
            const { data = {} } = error.response;
            this.$snackbar.open({
              message: data.message || 'Something Went Wrong !!!',
              duration: 5000,
              actionText: ''
            });
          }
        );
    }
  },
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  }
};
