<template>
  <div
    class="v2-forecast u-display-flex u-flex-direction-column u-overflow-x-hidden u-height-100"
  >
    <div
      v-if="!isCalendarLoaded"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-width-100 u-height-100"
    >
      <span>
        <loader
          class="fill--parent"
          :loading="!isCalendarLoaded"
          :color="'#007cf6'"
        />
      </span>
    </div>
    <!-- Allow the calendar to be triggered & stop other widgets from loading -->
    <div
      v-show="isCalendarLoaded"
      class="u-display-flex u-flex-direction-column u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        id="v2-forecast"
        :get-filter-instance="getFilterInstance"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'forecast-filters'"
        :state="filterState"
        :loader-status="loaderStatus"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="config.filters.hasSearch"
        :hide-filter="config.filters.hideFilter"
        :new-date="false"
        :default-date-post-range="''"
        :extra-search-wrap-class="'u-flex-1 u-flex-justify-content-flex-end'"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :on-create="onCreate"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Sales Forecast'" />
        </div>
        <div
          slot="right-side"
          class="u-display-flex"
        >
          <span
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-border-radius-xs u-spacing-ph-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-s u-bg-color-grey-white u-display-flex"
            title="Know more about this dashboard"
            @click="openForecastHelpDialog"
          >
            <rb-icon
              class="u-color-grey-lighter"
              :icon="'info-circle-fill'"
            />
          </span>
          <div
            :id="hoverTooltipId"
            class="u-display-inline-flex u-position-relative"
          >
            <div
              id="hover-tooltip-container"
              class="dropdown-hover"
            >
              <div
                class="u-display-flex u-flex-direction-column u-bg-color-grey-base u-border-radius-s u-spacing-p-s"
              >
                <div class="u-display-flex u-color-grey-white u-font-size-6">
                  <div class="u-display-inline-flex u-text-overflow-ellipsis">
                    <span class="u-spacing-mr-xxs">Forecast type:&nbsp;</span>
                    <span class="u-text-overflow-ellipsis u-spacing-mr-s">{{
                      getSelDropdownOptions.forecast_type
                    }}</span>
                  </div>
                  <!-- <div class="u-display-inline-flex u-text-overflow-ellipsis u-spacing-ml-m">
                    <span class="">Forecast vs&nbsp;</span>
                    <span class="u-text-overflow-ellipsis">{{getSelDropdownOptions.forecast_vs}}</span>
                  </div> -->
                  <div
                    v-if="
                      getDropdownOptions.price_type &&
                      getDropdownOptions.price_type.length > 1
                    "
                    class="u-display-inline-flex u-text-overflow-ellipsis u-spacing-ml-m"
                  >
                    <span class="u-spacing-mr-xxs"
                      >&nbsp;Price type:&nbsp;</span
                    >
                    <span class="u-text-overflow-ellipsis u-spacing-mr-xxs">{{
                      getSelDropdownOptions.price_type
                    }}</span>
                  </div>
                  <div
                    :class="
                      getDropdownOptions.price_type &&
                      getDropdownOptions.price_type.length > 1
                        ? 'u-spacing-ml-m'
                        : ''
                    "
                    class="u-display-inline-flex u-text-overflow-ellipsis"
                  >
                    <span class="u-spacing-mr-xxs"
                      >&nbsp;Roll up by:&nbsp;</span
                    >
                    <span class="u-text-overflow-ellipsis u-spacing-mr-xxs"
                      >{{
                        getSelDropdownOptions.calender_granuality
                      }}&nbsp;&amp;&nbsp;{{
                        getSelDropdownOptions.cases_or_units
                      }}</span
                    >
                  </div>
                </div>
                <!-- <div class="u-display-flex u-spacing-mt-m u-font-size-6 u-color-grey-white">
                  <div v-if="getDropdownOptions.price_type && getDropdownOptions.price_type.length > 1" class="u-display-inline-flex u-text-overflow-ellipsis">
                    <span class="">Price type:&nbsp;</span>
                    <span class="u-text-overflow-ellipsis">{{getSelDropdownOptions.price_type}}</span>
                  </div>
                  <div :class="(getDropdownOptions.price_type && getDropdownOptions.price_type.length > 1) ? 'u-spacing-ml-m': ''" class="u-display-inline-flex u-text-overflow-ellipsis">
                    <span class="">Roll up by:&nbsp;</span>
                    <span class="u-text-overflow-ellipsis">{{getSelDropdownOptions.calender_granuality}}&nbsp;&amp;&nbsp;{{getSelDropdownOptions.cases_or_units}}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div
            v-tippy="hoverTippyOptions"
            class="hover-tooltip u-display-flex u-flex-direction-column"
          >
            <div
              ref="trigger"
              v-tippy="tippyOptions"
              role="button"
              class="dropdown-trigger button button--hollow u-spacing-mr-s"
              @click="toggle"
            >
              <div class="forecast-options">
                <rb-icon
                  class="u-color-grey-lighter u-spacing-mr-s"
                  :icon="'settings'"
                />
                <div class="u-display-flex text-container">
                  <div
                    class="u-display-inline-flex u-font-size-7 u-text-overflow-ellipsis forecastFilterSection"
                  >
                    <span class="u-color-grey-mid-light"
                      >Forecast type:&nbsp;</span
                    >
                    <span class="u-color-grey-base u-text-overflow-ellipsis">{{
                      getSelDropdownOptions.forecast_type
                    }}</span>
                  </div>
                  <!-- <div class="u-display-inline-flex u-font-size-7 u-spacing-ml-m forecastFilterSection u-text-overflow-ellipsis">
                    <span class="u-color-grey-mid-light">Forecast vs&nbsp;</span>
                    <span class="u-color-grey-base u-text-overflow-ellipsis">{{getSelDropdownOptions.forecast_vs}}</span>
                  </div> -->
                  <div
                    v-if="
                      getDropdownOptions.price_type &&
                      getDropdownOptions.price_type.length > 1
                    "
                    class="u-display-inline-flex u-font-size-7 u-spacing-ml-m u-text-overflow-ellipsis forecastFilterSection"
                  >
                    <span class="u-color-grey-mid-light"
                      >Price type:&nbsp;</span
                    >
                    <span class="u-color-grey-base u-text-overflow-ellipsis">{{
                      getSelDropdownOptions.price_type
                    }}</span>
                  </div>
                  <div
                    class="u-display-inline-flex u-font-size-7 u-spacing-ml-m u-text-overflow-ellipsis forecastFilterSectionRollUp"
                  >
                    <span class="u-color-grey-mid-light"
                      >Roll up by:&nbsp;</span
                    >
                    <span class="u-color-grey-base u-text-overflow-ellipsis"
                      >{{
                        getSelDropdownOptions.calender_granuality
                      }}&nbsp;&amp;&nbsp;{{
                        getSelDropdownOptions.cases_or_units
                      }}</span
                    >
                  </div>
                </div>
                <rb-icon
                  :icon="'arrow-down'"
                  class="rb-icon--x-small u-color-grey-mid-light u-spacing-ml-m no-margin"
                />
              </div>
            </div>
          </div>
          <div
            :id="id"
            ref="forecastDropdownMenu"
            class="u-display-flex u-flex-direction-column"
            style="width: 430px"
          >
            <div class="dropdownContainer">
              <div class="u-display-flex u-flex-direction-column">
                <span
                  class="u-font-size-5 u-color-grey-mid-light u-line-height-1"
                  >Forecast type:</span
                >
                <div
                  class="u-font-size-5 u-color-grey-light u-display-flex u-spacing-mt-m"
                >
                  <div class="forecast-radio-wrapper">
                    <rb-radio
                      v-model="radioBtnSelectedValues.forecast_type"
                      native-value="Mean"
                      @input="changeRadio('forecast_type')"
                    >
                      Mean
                    </rb-radio>
                  </div>
                  <div class="forecast-radio-wrapper u-spacing-ml-m">
                    <rb-radio
                      v-model="radioBtnSelectedValues.forecast_type"
                      native-value="P70"
                      @input="changeRadio('forecast_type')"
                    >
                      P 70
                    </rb-radio>
                  </div>
                  <div class="forecast-radio-wrapper u-spacing-ml-m">
                    <rb-radio
                      v-model="radioBtnSelectedValues.forecast_type"
                      native-value="P80"
                      @input="changeRadio('forecast_type')"
                    >
                      P 80
                    </rb-radio>
                  </div>
                  <div class="forecast-radio-wrapper u-spacing-ml-m">
                    <rb-radio
                      v-model="radioBtnSelectedValues.forecast_type"
                      native-value="P90"
                      @input="changeRadio('forecast_type')"
                    >
                      P 90
                    </rb-radio>
                  </div>
                </div>
              </div>
              <!-- <div class="u-display-flex u-flex-direction-column u-spacing-mt-l">
                <span class="u-font-size-5 u-color-grey-mid-light u-line-height-1">Forecast vs:</span>
                <div class="u-font-size-5 u-color-grey-light u-display-flex u-spacing-mt-m">
                  <div class="forecast-radio-wrapper">
                    <rb-radio v-model="radioBtnSelectedValues.forecast_vs" native-value="Consumption" @input="changeRadio('forecast_vs')">Consumption</rb-radio>
                  </div>
                  <div class="forecast-radio-wrapper u-spacing-ml-m">
                    <rb-radio v-model="radioBtnSelectedValues.forecast_vs" native-value="Purchase" @input="changeRadio('forecast_vs')">Purchase Orders</rb-radio>
                  </div>
                </div>
              </div> -->
              <div
                v-if="
                  getDropdownOptions.price_type &&
                  getDropdownOptions.price_type.length > 1
                "
                class="u-display-flex u-flex-direction-column u-spacing-mt-l"
              >
                <span
                  class="u-font-size-5 u-color-grey-mid-light u-line-height-1"
                  >Price type:</span
                >
                <div
                  class="u-font-size-5 u-color-grey-light u-display-flex u-spacing-mt-m"
                >
                  <div
                    v-for="(type, index) in getDropdownOptions.price_type"
                    :key="index"
                    class="forecast-radio-wrapper u-spacing-mr-m"
                  >
                    <rb-radio
                      v-model="radioBtnSelectedValues.price_type"
                      :native-value="type"
                      @input="changeRadio('price_type')"
                    >
                      {{ type }}
                    </rb-radio>
                  </div>
                </div>
              </div>
              <div
                class="u-display-flex u-flex-align-items-flex-end u-spacing-mt-l"
              >
                <div class="u-display-inline-flex u-flex-direction-column">
                  <span
                    class="u-font-size-5 u-color-grey-mid-light u-line-height-1"
                    >Roll up by:</span
                  >
                  <div
                    class="u-font-size-5 u-color-grey-light u-display-inline-flex u-spacing-mt-m"
                  >
                    <div class="forecast-radio-wrapper">
                      <rb-radio
                        v-model="radioBtnSelectedValues.calender_granuality"
                        native-value="Week"
                        @input="changeRadio('calender_granuality')"
                      >
                        Week
                      </rb-radio>
                    </div>
                    <div class="forecast-radio-wrapper u-spacing-ml-m">
                      <rb-radio
                        v-model="radioBtnSelectedValues.calender_granuality"
                        native-value="Month"
                        @input="changeRadio('calender_granuality')"
                      >
                        Month
                      </rb-radio>
                    </div>
                  </div>
                </div>
                <span class="seperator u-spacing-mh-m" />
                <div
                  class="u-font-size-5 u-color-grey-light u-display-inline-flex"
                >
                  <div class="forecast-radio-wrapper">
                    <rb-radio
                      v-model="radioBtnSelectedValues.cases_or_units"
                      native-value="Cases"
                      @input="changeRadio('cases_or_units')"
                    >
                      Cases
                    </rb-radio>
                  </div>
                  <div class="forecast-radio-wrapper u-spacing-ml-m">
                    <rb-radio
                      v-model="radioBtnSelectedValues.cases_or_units"
                      native-value="Units"
                      @input="changeRadio('cases_or_units')"
                    >
                      Units
                    </rb-radio>
                  </div>
                </div>
              </div>
              <div
                class="u-display-flex u-width-100 u-flex-justify-content-flex-end u-spacing-mt-xl"
              >
                <rb-button
                  :click-fn="cancel"
                  :text="'Cancel'"
                  :size="'s'"
                  :type="'hollow'"
                  class="u-spacing-mr-s u-cursor-pointer"
                />
                <rb-button
                  :click-fn="apply"
                  :text="'Apply'"
                  :size="'s'"
                  :type="'filled'"
                  class="u-cursor-pointer"
                  :disabled="applyButtonStatus"
                />
              </div>
            </div>
          </div>

          <forecast-calendar
            :calendar-data="getCalendarData"
            @showNoData="showNoDataTemplate"
            @isCalendarLoaded="onCalendarLoad"
          />
        </div>
        <div
          slot="add-filter-left"
          class="u-display-inline-flex u-spacing-mr-m"
        />
      </rb-filter-panel>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
        :apply-negative-margin="true"
      />
    </div>

    <section class="u-envelop-width u-width-100">
      <div
        v-if="isCalendarLoaded"
        class="u-display-flex u-flex-direction-column u-spacing-ph-m"
      >
        <card-widget
          class="u-flex-1 u-spacing-mb-m forecast_card_widget"
          :config="config.widgets['widget1']"
          :data="
            getInsightsForecastData[config.widgets['widget1'].meta.key] || {}
          "
          data-cy="forecast-widget"
        >
          <div
            slot="body"
            class="u-display-flex u-width-100 u-spacing-ph-l"
          >
            <!-- <div class="u-display-inline-flex" v-for="(val, index) in selectedForecastedUnits" :val="val">
            <div :id="w1MetricsTooltipId" class="metrics-tooltip u-display-flex u-bg-color-grey-base u-border-radius-s u-spacing-p-s">
              <span class="u-color-grey-white u-font-size-7">
                {{val.tooltip}}
              </span>
            </div>
            <div class="u-display-flex u-flex-align-items-center">
              <div v-tippy="w1MetricsTippyOptions" :style="{ 'border-color': forecastedUnitsColors[val.title] || 'transparent' }" class="metrics-card custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative" >
                <span class="u-font-size-4 u-color-grey-mid-light">{{val.title}}</span>
                <div class="u-display-flex u-flex-align-items-baseline w1Metrics">
                  <metric :size="'l'" v-if="val.tag" class="u-display-inline-flex u-spacing-mt-s" :config="(config.widgets['widget1'].body.tagConfigs[index])" :data="val.tag"></metric>
                  <span v-if="val.tag && val.title === 'Forecast'" class="u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-ml-xs">{{cases_or_units}}</span>
                  <span class="u-font-size-6 u-color-grey-lighter" v-if="!val.tag">No Data</span>
                </div>
              </div>
            </div>
          </div>
          <chart class="u-flex-1 u-spacing-mt-l" :config="config.widgets['widget1'].body.chart" :data="widget1Data"></chart> -->

            <chart-with-legends
              class=""
              :metrics-list="forecastMetricsList"
              :color-object="forecastedUnitsColors"
              :chart-data="widget1Data"
              :chart-config="config.widgets['widget1'].body.chart"
              :metrics-shown="forecastMetricsShown"
              :default-select-metric="forecastDefaultSelectMetric"
              :metric-config="config.widgets['widget1'].body.tagConfig"
              :disable-add-new-metric="true"
              :metric-data="selectedForecastedUnits"
              :minimum-metric="forecastMetricsShown.length"
              :remove-dropdown="true"
              :selected-metric-limit="forecastMetricsShown.length"
              @selectedMetricList="forecastSelMetricsFn"
              @addMetric="forecastAddMetricFn"
              @removedMetric="forecastRemovedMetricFn"
            />
          </div>
        </card-widget>
        <card-widget
          :style="{ minHeight: showInvalidTemplate ? '300px' : '720px' }"
          class="u-display-flex u-width-100 u-height-100 u-flex-direction-column u-flex-1 u-spacing-mb-m forecast_card_widget"
          :config="config.widgets['widget2']"
          :data="
            getInsightsForecastData[config.widgets['widget2'].meta.key] || {}
          "
          data-cy="past-performance-widget"
        >
          <div
            v-show="showInvalidTemplate"
            slot="noDataTemplate"
            class="u-display-flex u-flex-direction-column"
          >
            <rb-icon
              :icon="'data-unavailable'"
              class="data-unavailable-icon u-color-grey-xx-light"
            />
            <div
              class="u-display-inline-flex u-flex-direction-column u-spacing-mt-m"
            >
              <!-- eslint-disable -->
              <span
                class="u-font-size-5 u-color-grey-base u-spacing-mb-s"
                v-html="dataAvailable.message1"
              />
              <span class="u-font-size-5">
                <span
                  class="u-color-grey-lighter"
                  v-html="dataAvailable.message2"
                />
              </span>
              <span
                v-if="dataAvailable.message3"
                class="u-font-size-7 u-color-grey-lighter"
                v-html="dataAvailable.message3"
              />
              <!-- eslint-enable -->
            </div>
          </div>
          <div
            v-show="!showInvalidTemplate"
            slot="body"
            class="u-display-flex u-width-100 u-spacing-ph-l"
          >
            <chart-with-legends
              class="u-spacing-mt-m"
              :metrics-list="metricsList"
              :color-object="pastPerformanceColors"
              :chart-data="widget2ChartData"
              :chart-config="config.widgets['widget2'].body.chart"
              :metrics-shown="metricsShown"
              :default-select-metric="defaultSelectMetric"
              :metric-config="config.widgets['widget2'].body.tagConfig"
              :disable-add-new-metric="true"
              :metric-data="selectedPastPerformance"
              :minimum-metric="metricsShown.length"
              :remove-dropdown="true"
              :selected-metric-limit="metricsShown.length"
              @selectedMetricList="pastPerfSelMetrics"
              @addMetric="addMetric"
              @removedMetric="removedMetric"
            />
          </div>
        </card-widget>

        <!-- Disabling Accuracy Metrics Widget for a while -->
        <div
          v-if="false"
          class="u-display-flex u-flex-wrap-yes u-spacing-mb-m"
        >
          <card-widget
            class="u-flex-1"
            :config="config.widgets['widget3']"
            :data="
              getInsightsForecastData[config.widgets['widget3'].meta.key] || {}
            "
          >
            <div
              slot="body"
              class="card-body u-display-flex u-flex-wrap-yes u-flex-justify-content-space-around u-spacing-pt-l u-spacing-pb-xxl"
            >
              <div
                v-for="(item, index) in widget3Data"
                :key="index"
                class="u-display-inline-flex u-flex-direction-column u-flex-align-items-center"
              >
                <div
                  :id="metricsTooltipId"
                  class="metrics-tooltip u-display-flex u-bg-color-grey-base u-border-radius-s u-spacing-p-s"
                >
                  <span class="u-color-grey-white u-font-size-7">
                    {{ item.tooltip }}
                  </span>
                </div>
                <!-- <rb-tooltip :label="item.tooltip"> -->
                <span
                  v-tippy="metricsTippyOptions"
                  class="u-text-case-upper u-font-size-5 u-color-grey-lighter u-font-weight-600 u-spacing-mb-m"
                >
                  {{ item.title }}
                </span>
                <!-- </rb-tooltip> -->
                <metric
                  v-if="item.metric"
                  :size="'xl'"
                  class="u-display-inline-flex u-spacing-mb-m"
                  :config="config.widgets['widget3'].body.tagConfigsArr[index]"
                  :data="item.metric"
                />
                <span
                  v-else
                  class="u-font-size-6 u-color-grey-lighter"
                  >No Data</span
                >
              </div>
            </div>
          </card-widget>
        </div>

        <div class="u-display-flex u-flex-wrap-yes u-spacing-mb-m">
          <card-widget
            class="u-flex-1 u-width-100 u-overflow-auto"
            :get-card-widget-instance="getWidgetInstance"
            :config="config.widgets['widget4']"
            :data="
              getInsightsForecastData[config.widgets['widget4'].meta.key] || {}
            "
          >
            <div
              slot="body"
              class="u-display-flex u-overflow-auto"
            >
              <rb-insights-table
                ref="SKU_Level_BreakDown_Table"
                :custom-height="false"
                :config="config.widgets['widget4']"
                :grid-options="gridOptions"
                :table-row="
                  (
                    getInsightsForecastData[
                      config.widgets['widget4'].meta.key
                    ] || {}
                  ).rows
                "
                :table-column="columnDefs"
                style="width: 100%"
                :row-height="80"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="true"
                :sorting-change-event="'sortColumns'"
                :pagination="true"
                :show-pagination-options="true"
                :pagination-total-key="
                  (
                    getInsightsForecastData[
                      config.widgets['widget4'].meta.key
                    ] || {}
                  ).totalRows
                "
                :pagination-per-page-limit="
                  config.widgets['widget4'].body.APIConfig.limit
                "
                :download-this-table="true"
              />
            </div>
          </card-widget>
        </div>
        <forecastHelpDialog
          v-if="showForecastHelpDialog"
          @closeCallback="closeCallback"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import loader from '@/components/basic/loader';
import { eventBus } from '@/utils/services/eventBus';
import forecastCalendar from '@/components/widgets/forecastCalendar.vue';
import chart from '@/components/basic/chart';
import cardWidget from '@/components/widgets/cardWidget';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsConfig from './widgets';
import forecastHelpDialog from '@/components/pages/insights/amazon/forecast/forecastHelpDialog.vue';
// import moment from 'moment';

var oObject = {
  components: {
    loader,
    forecastCalendar,
    chart,
    metric,
    cardWidget,
    rbFilterPanel,
    chartWithLegends,
    forecastHelpDialog
  },
  mixins: [widgetsUtil],
  beforeCreate() {
    this.$store.dispatch('fetchDropdownOptions'); // Fetch DropDown Options
    // this.$store.dispatch('fetchCalendarData'); // Fetch Calendar Data
  },
  created() {
    // this.ToshowFullDropDown();
    // get stuff from localstorage
    let forecastInLclStore = localStorage.getItem('Forecast-v2');
    if (forecastInLclStore) {
      forecastInLclStore = JSON.parse(forecastInLclStore);
      if (
        forecastInLclStore.selDropdownOptions &&
        forecastInLclStore.selDropdownOptions.constructor === Object
      ) {
        this.$store.dispatch(
          'setDropdownSelectedOptions',
          forecastInLclStore.selDropdownOptions
        );
      }
    }
    this.$store.dispatch('fetchCalendarData'); // Fetch Calendar Data
    this.fetchFilters('v2_forecast_widget');
    this.config = widgetsConfig.config(this);
    // Add Direct Fulfilment Inventory Column to table based on a config
    if (Vue.options.filters.config_check('feature.direct_fulfilment.enable')) {
      if (
        this.config.widgets.widget4.body.APIConfig.measuresList.indexOf(
          'stock'
        ) === -1
      ) {
        this.config.widgets.widget4.body.APIConfig.measuresList.push('stock');
      }

      if (
        this.checkArray(
          this.config.widgets.widget4.header.actions.download.allMap,
          'dbKey',
          'stock'
        ) === false
      ) {
        this.config.widgets.widget4.header.actions.download.allMap.push({
          displayName: 'Direct Fulfilment Inventory',
          dbKey: 'stock'
        });
      }

      var directFulfilmentColumn = {
        title: 'Direct Fulfilment Inventory',
        key: 'stock',
        showToolTip: true,
        toolTipText: 'Units available for direct fulfillment',
        type: 'numeric',
        keyOrder: 3
      };

      if (
        widgetsConfig.tableColumns[1].key === 'common_columns' &&
        this.checkArray(
          widgetsConfig.tableColumns[1].children,
          'key',
          'stock'
        ) === false
      ) {
        widgetsConfig.tableColumns[1].children.push(directFulfilmentColumn);
      }
    }
  },
  mounted() {
    // var that = this;
    // // listen for resizing of screen
    // window.addEventListener('resize', function () {
    //   setTimeout(() => {
    //     that.ToshowFullDropDown();
    //   }, 500);
    // });
    localStorage.removeItem('Forecast');
    eventBus.$on(
      'sortColumns',
      function (column, config) {
        if (config.body.APIConfig.customOrderAndSortList) {
          const list = ['PVP_PVP_', 'PVP_PVP_DIFF_', 'PVP_'];
          const listMap = [
            { type: 'PVP', measureType: 'PVP' },
            { type: 'PVP', measureType: 'PVP_DIFF' },
            { type: 'PVP', measureType: 'RESULT' }
          ];
          let orderByList = [];
          let found = false;

          for (var [idx, item] of list.entries()) {
            if (
              column &&
              column.colDef &&
              column.colDef.field &&
              column.colDef.field.includes(item)
            ) {
              var selListMapItem = listMap[idx];
              selListMapItem.direction = column.sort
                ? column.sort.toUpperCase()
                : 'ASC';
              selListMapItem.measureName = column.colDef.field.slice(
                item.length
              );
              orderByList.push(selListMapItem);
              found = true;
              break;
            }
          }
          if (!found) {
            orderByList.push({
              type: 'RESULT',
              measureType: 'RESULT',
              measureName: column.colDef.field,
              direction: column.sort ? column.sort.toUpperCase() : 'ASC'
            });
          }
          if (
            config.body.defaultOrderBy &&
            config.body.defaultOrderBy.length &&
            config.body.defaultOrderBy.length > 0
          ) {
            orderByList = [...orderByList, ...config.body.defaultOrderBy];
          }
          config.body.APIConfig.customOrderAndSortList = orderByList;
          this.$store.dispatch(config.meta.action, config);
        }
      }.bind(this)
    );
  },
  data() {
    var forecastMetricsShown = [
      {
        key: 'Forecast'
      },
      {
        key: 'Value'
      }
    ];
    var forecastMetricsList = [
      {
        key: 'Forecast'
      },
      {
        key: 'Value'
      }
    ];
    var forecastDefaultSelectMetric = [
      {
        key: 'Forecast'
      }
    ];

    var metricsShown = [
      {
        key: 'Forecast'
      },
      {
        key: 'Consumption'
      },
      {
        key: 'Purchase Order'
      },
      {
        key: 'On Hand Inventory'
      },
      {
        key: 'Difference [Forecast-Purchase Orders]'
      }
    ];
    var metricsList = [
      {
        key: 'Forecast'
      },
      {
        key: 'Consumption'
      },
      {
        key: 'Purchase Order'
      },
      {
        key: 'On Hand Inventory',
        seprator: true
      },
      {
        key: 'Difference [Forecast-Purchase Orders]',
        disabled: true
      }
    ];
    var defaultSelectMetric = [
      {
        key: 'Forecast'
      },
      {
        key: 'Consumption'
      },
      {
        key: 'Purchase Order'
      },
      {
        key: 'On Hand Inventory'
      }
    ];
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      showInvalidTemplate: false,
      forecastMetricsShown: forecastMetricsShown,
      forecastMetricsList: forecastMetricsList,
      forecastDefaultSelectMetric: forecastDefaultSelectMetric,
      forecastRemovedMetric: null,
      pastPerfRemovedMetric: null,
      metricsList: metricsList,
      dataAvailable: {
        show: false
      },
      forecastedUnitsColors: {
        Forecast: '#bd10e0',
        // 'Forecasted Cases': '#bd10e0',
        Value: '#ffa800'
      },
      pastPerformanceColors: {
        Forecast: '#bd10e0',
        'Purchase Order': '#3ea95e',
        'On Hand Inventory': '#d7263d',
        Consumption: '#ffa800',
        'Difference [Forecast-Purchase Orders]': '#23b5d3',
        'Difference [Forecast-Consumption]': '#23b5d3'
      },
      id: 'forecast-options-' + this._uid,
      hoverTooltipId: 'hover-tooltip-' + this._uid,
      metricsTooltipId: 'metrics-tooltip' + this._uid,
      w1MetricsTooltipId: 'w1-metrics-tooltip-' + this._uid,
      w1MetricsTippyOptions: {
        html: '#' + 'w1-metrics-tooltip-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'top',
        interactive: false,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      w2MetricsTooltipId: 'w2-metrics-tooltip-' + this._uid,
      w2MetricsTippyOptions: {
        html: '#' + 'w2-metrics-tooltip-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'top',
        interactive: false,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      metricsTippyOptions: {
        html: '#' + 'metrics-tooltip' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'top',
        interactive: false,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      hoverTippyOptions: {
        html: '#' + 'hover-tooltip-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: false,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      tippyOptions: {
        html: '#' + 'forecast-options-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        },
        onShow: this.onShow,
        onHide: this.onHide
      },
      // showFullDropDown: '',
      filterPanelInstance: null,
      showActiveFilterBanner: false,
      filterInstance: null,
      tableCardWidgetInstance: null,
      applyButtonStatus: true,
      isCalendarLoaded: false,
      filterState: {
        getter: 'getInsightsForecastSelectedFilter',
        setter: 'setInsightsForecastSelectedFilter'
      },
      selectedForecastedUnits: {},
      allPastPerfMetrics: [],
      widget2ChartData: {},
      selectedPastPerformance: {},
      metricsShown: metricsShown,
      defaultSelectMetric: defaultSelectMetric,
      graphTextMap: [
        ['Forecast', ['Forecast Cases', 'Forecast Units']],
        ['Consumption', ['Consumption Cases', 'Consumption Units']],
        ['Purchase Order', ['Purchase Cases', 'Purchase Units']],
        [
          'On Hand Inventory',
          ['On Hand Inventory Cases', 'On Hand Inventory Units']
        ],
        [
          'Difference [Forecast-Consumption]',
          [
            'Diff[Forecast Cases - Consumption Cases]',
            'Diff[Forecast Units - Consumption Units]'
          ]
        ],
        [
          'Difference [Forecast-Purchase Orders]',
          [
            'Diff[Forecast Cases - Purchase Cases]',
            'Diff[Forecast Units - Purchase Units]'
          ]
        ]
      ],
      gridOptions: {
        context: {
          componentParent: this
        }
      },
      forecast_type: '',
      forecast_vs: '',
      calender_granuality: '',
      cases_or_units: '',
      price_type: '',
      radioBtnSelectedValues: {
        forecast_type: '',
        forecast_vs: '',
        calender_granuality: '',
        cases_or_units: '',
        price_type: ''
      },
      chartNMetricsColors: ['#bd10e0', '#ffa800', '#23b5d3'],
      showForecastHelpDialog: false
    };
  },
  methods: {
    onCreate(filter) {
      this.filterInstance = filter;
    },
    closeCallback() {
      this.showForecastHelpDialog = false;
    },
    openForecastHelpDialog() {
      this.showForecastHelpDialog = true;
    },
    showNoDataTemplate(msgObj) {
      this.dataAvailable = Object.assign({}, msgObj);
      this.dataAvailable = JSON.parse(JSON.stringify(this.dataAvailable));
      this.showInvalidTemplate = this.dataAvailable.show;
      // this.$nextTick(() => {

      // });
      // setTimeout(function () {
      //   this.showInvalidTemplate = this.dataAvailable.show;
      // }.bind(this), 5000);
    },
    scrollToId(id) {
      var elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView();
      }
    },
    checkArray(arr, key, value) {
      if (
        arr &&
        arr.constructor === Array &&
        arr.length > 0 &&
        key.constructor === String
      ) {
        for (var item of arr) {
          if (
            item.constructor === Object &&
            item?.hasOwnProperty(key) &&
            item[key] === value
          ) {
            return true;
          }
        }
        return false;
      } else {
      }
    },
    forecastAddMetricFn() {},
    forecastRemovedMetricFn(item) {
      this.forecastRemovedMetric = item;
    },
    forecastSelMetricsFn(data) {
      if (
        data &&
        data.selectedMetric &&
        data.selectedMetric.length &&
        data.selectedMetric.length > 0
      ) {
      }
    },
    generatePastPerfChartData(selectedMetrics) {
      let chartData =
        this.$store.getters.getInsightsForecastData[
          this.config.widgets.widget2.meta.key
        ];
      chartData = Object.assign({}, chartData);
      chartData = JSON.parse(JSON.stringify(chartData));
      const returnObj = {
        size: {
          height: 500
        },
        data: [],
        legends: undefined,
        xs: {},
        axis_format: {}
      };
      if (
        chartData &&
        chartData.data &&
        Object.keys(chartData.data).length > 1
      ) {
        if (selectedMetrics.length > 0) {
          for (var c of selectedMetrics) {
            if (chartData.data.data?.hasOwnProperty(c?.key)) {
              for (var d in chartData.data.data[c.key]) {
                returnObj.data.push(chartData.data.data[c.key][d]);

                if (
                  Object.keys(chartData.data.xs).indexOf(
                    chartData.data.data[c.key][d][0]
                  ) > -1
                ) {
                  returnObj.xs[chartData.data.data[c.key][d][0]] =
                    chartData.data.xs[chartData.data.data[c.key][d][0]];
                  if (c.type && c.axes) {
                    returnObj.axes = {};
                    returnObj.types = {};
                    returnObj.axes[chartData.data.data[c.key][d][0]] = c.axes;
                    returnObj.types[chartData.data.data[c.key][d][0]] = c.type;
                    returnObj[c.type] = {
                      width: {
                        ratio: 0.2
                      }
                    };
                  }
                }
              }
            }
          }
          returnObj.legends = chartData.data.legends;
        }
      }
      // returnObj.colors = this.pastPerformanceColors;

      const axisFormat = {
        y: {
          label: {
            text: this.cases_or_units,
            position: 'inner-top'
          }
        },
        y2: {
          label: {
            text: 'Difference',
            position: 'inner-top'
          }
        }
      };
      returnObj.axis_format.y = axisFormat.y;
      returnObj.axis_format.y2 = axisFormat.y2;

      const keysToRename = ['data', 'xs', 'types', 'axes'];
      for (var i1 in keysToRename) {
        if (returnObj[keysToRename[i1]]) {
          for (var j in returnObj[keysToRename[i1]]) {
            for (var k in this.graphTextMap) {
              if (
                returnObj[keysToRename[i1]].constructor === Array &&
                this.graphTextMap[k][1].indexOf(
                  returnObj[keysToRename[i1]][j][0]
                ) > -1
              ) {
                returnObj[keysToRename[i1]][j][0] = this.graphTextMap[k][0];
              } else if (returnObj[keysToRename[i1]].constructor === Object) {
                const tempObj = {};
                Object.keys(returnObj[keysToRename[i1]]).map((item) => {
                  if (this.graphTextMap[k][1].indexOf(item) > -1) {
                    tempObj[this.graphTextMap[k][0]] =
                      returnObj[keysToRename[i1]][item];
                  } else {
                    tempObj[item] = returnObj[keysToRename[i1]][item];
                  }
                });
                returnObj[keysToRename[i1]] = tempObj;
              }
            }
          }
        }
      }

      // generate colMap for download based on the selection
      if (selectedMetrics.length > 0) {
        var downloadConfig =
          this.config.widgets.widget2.header.actions.download;
        downloadConfig.colMap = [];
        for (var i of selectedMetrics) {
          const tempObj = {
            displayName: i.title,
            dbKey: i.key
          };
          downloadConfig.colMap.push(tempObj);
        }
        const others = [
          {
            displayName:
              'Start date of the ' + this.calendarGranularityLowerCase,
            dbKey: this.calendarGranularityLowerCase + '_start_date'
          }
        ];
        downloadConfig.colMap = [...others, ...downloadConfig.colMap];
        this.config.widgets.widget2.body.APIConfig.timeseriesDimension =
          this.calendarGranularityLowerCase + '_start_date';
      }
      this.widget2ChartData = { ...returnObj };
    },
    addMetric(item) {},
    removedMetric(item) {
      this.pastPerfRemovedMetric = item;
    },
    pastPerfSelMetrics(data) {
      // Todo
      // console.log(data);

      const comb = {
        'Forecast-Purchase Order': 'Difference [Forecast-Purchase Orders]',
        'Forecast-Consumption': 'Difference [Forecast-Consumption]'
      };
      const metricsTooltips = {
        Forecast:
          'Forecasted number of ' +
          this.cases_or_units.toLowerCase() +
          ' for the chosen time period',
        Consumption:
          'Number of ' +
          this.cases_or_units.toLowerCase() +
          ' sold by Amazon for the chosen time period',
        'Purchase Order':
          'Number of ' +
          this.cases_or_units.toLowerCase() +
          ' ordered  by Amazon through Purchase Orders',
        'On Hand Inventory':
          'Inventory available with Amazon on the last date of the selected date range as per the Inventory Health report on AVC.',
        'Difference [Forecast-Consumption]':
          'Difference between forecast and  consumption in the selected time period',
        'Difference [Forecast-Purchase Orders]':
          'Difference between forecast and purchase orders in the selected time period'
      };

      if (
        data &&
        data.selectedMetric &&
        data.selectedMetric.length &&
        data.selectedMetric.length > 0
      ) {
        let combinedKey = '';
        this.defaultSelectMetric = [];
        this.defaultSelectMetric = data.selectedMetric;
        for (const [idx, obj] of data.selectedMetric.entries()) {
          if (
            obj &&
            obj.key &&
            obj.key !== 'Difference [Forecast-Purchase Orders]' &&
            obj.key !== 'Difference [Forecast-Consumption]'
          ) {
            if (combinedKey !== '' && idx <= data.selectedMetric.length - 1) {
              combinedKey += '&&';
            }
            combinedKey += obj.key;
          }
        }
        let status = false;
        let difference = null;
        if (combinedKey.split('&&').length === 2) {
          const combArr = combinedKey.split('&&');
          const key1 = Object.keys(comb).indexOf(
            combArr[0].concat('-', combArr[1])
          );
          const key2 = Object.keys(comb).indexOf(
            combArr[1].concat('-', combArr[0])
          );
          const defaultSelected = [
            { key: 'Forecast' },
            { key: 'Consumption' },
            { key: 'Purchase Order' },
            { key: 'On Hand Inventory', seprator: true }
          ];
          if (key1 > -1) {
            this.metricsShown = [
              ...defaultSelected,
              ...[{ key: comb[Object.keys(comb)[key1]], disabled: false }]
            ];
            // Todo: Reason for infinite loop
            this.metricsList = this.metricsShown;
            difference = {
              key: comb[Object.keys(comb)[key1]],
              disabled: false
            };
            status = true;
          } else if (key2 > -1) {
            status = true;
            this.metricsShown = [
              ...defaultSelected,
              ...[{ key: comb[Object.keys(comb)[key2]], disabled: false }]
            ];
            // Todo: Reason for infinite loop
            this.metricsList = this.metricsShown;
            difference = {
              key: comb[Object.keys(comb)[key2]],
              disabled: false
            };
          }
        }
        if (!status) {
          for (var o of this.metricsShown) {
            if (
              o.key === 'Difference [Forecast-Purchase Orders]' ||
              o.key === 'Difference [Forecast-Consumption]'
            ) {
              o.disabled = true;
              difference = o;
            }
          }
        }
        let found = false;
        for (var indx in this.defaultSelectMetric) {
          if (
            this.defaultSelectMetric[indx].key === difference.key &&
            difference.disabled
          ) {
            found = true;
            this.defaultSelectMetric.splice(indx, 1);
          }
        }
        if (
          !found &&
          !difference.disabled &&
          this.pastPerfRemovedMetric &&
          difference.key !== this.pastPerfRemovedMetric.key &&
          !this.defaultSelectMetric.some(
            (metric) => metric.key === difference.key
          )
        ) {
          this.defaultSelectMetric.push(difference);
        }
      }

      // Set Tooltips for cards in the metricList
      if (
        this.metricsList &&
        this.metricsList.length &&
        this.metricsList.length > 0
      ) {
        for (var ob of this.metricsList) {
          ob.tooltip = metricsTooltips[ob.key];
        }
      }

      const selectedPastPerformance = [];
      const metricsData =
        this.$store.getters.getInsightsForecastData[
          this.config.widgets.widget2.meta.key
        ] &&
        this.$store.getters.getInsightsForecastData[
          this.config.widgets.widget2.meta.key
        ].metrics;

      const metricsDataMap = {
        Forecast: ['forecasted_units', 'forecasted_cases'],
        Consumption: ['consumption_units', 'consumption_cases'],
        'Purchase Order': ['purchase_order_units', 'purchase_order_cases'],
        'On Hand Inventory': [
          'units_at_amazon_weekly',
          'cases_at_amazon_weekly',
          'units_at_amazon_monthly',
          'cases_at_amazon_monthly'
        ],
        'Difference [Forecast-Purchase Orders]': [
          'diff_forecasted_purchase_order_units',
          'diff_forecasted_purchase_order_cases'
        ],
        'Difference [Forecast-Consumption]': [
          'diff_forecasted_consumption_units',
          'diff_forecasted_consumption_cases'
        ]
      };

      if (metricsData && Object.keys(metricsData).length > 0) {
        for (var itm of this.metricsShown) {
          if (Object.keys(metricsDataMap).indexOf(itm.key) > -1) {
            for (var itm1 of metricsDataMap[itm.key]) {
              if (
                itm1.toLowerCase().includes(this.cases_or_units.toLowerCase())
              ) {
                if (metricsData.hasOwnProperty(itm1))
                  metricsData[itm1].key = itm1;
                if (
                  itm.key === 'Difference [Forecast-Consumption]' ||
                  itm.key === 'Difference [Forecast-Purchase Orders]'
                ) {
                  metricsData[itm1].type = 'bar';
                  metricsData[itm1].axes = 'y2';
                }
                if (metricsData[itm1])
                  selectedPastPerformance.push(metricsData[itm1]);
              }
            }
          }
        }
      }

      // rename metrics
      if (selectedPastPerformance && selectedPastPerformance.length > 0) {
        for (var idx6 in selectedPastPerformance) {
          for (var idx7 in this.graphTextMap) {
            if (
              this.graphTextMap[idx7][1].indexOf(
                selectedPastPerformance[idx6].title
              ) > -1
            ) {
              selectedPastPerformance[idx6].title = this.graphTextMap[idx7][0];
              selectedPastPerformance[idx6].tooltip =
                metricsTooltips[this.graphTextMap[idx7][0]];
            }
          }
        }
        // Converting to Chart with Legends component required format
        this.selectedPastPerformance = {};
        for (var o1 of selectedPastPerformance) {
          this.selectedPastPerformance[o1.title] = {
            tag1: o1.tag.tag1
          };
        }
        this.selectedPastPerformance = { ...this.selectedPastPerformance };
      }

      // Todo - infinite loop
      this.metricsShown = [...this.metricsShown]; // try
      this.generatePastPerfChartData(selectedPastPerformance);
    },
    onCalendarLoad(value) {
      this.isCalendarLoaded = value;
    },
    getFilterInstance(filterInstance) {
      this.filterPanelInstance = filterInstance;
    },
    getWidgetInstance(instance) {
      this.tblCardWidgetInst = instance;
    },
    addToSearch(params) {
      const tableSearchText = this.tblCardWidgetInst.getSearch;
      if (tableSearchText) {
        this.tblCardWidgetInst.setSearch('');
      }
      if (
        params &&
        params.rbSkuData &&
        params.rbSkuData.constructor === Object
      ) {
        this.filterPanelInstance.setSearch(params.rbSkuData.asin);
        this.filterPanelInstance.searchSubmit();
        this.scrollToId('v2-forecast');
      }
    },
    apply() {
      setTimeout(
        function () {
          var emitMap = {
            forecast_type: { emit: true, event: 'dropdownOptionsChanged' },
            forecast_vs: false,
            calender_granuality: { emit: true, event: 'calendarTriggered' },
            cases_or_units: false,
            price_type: { emit: true, event: 'dropdownOptionsChanged' }
          };
          var changedKeyList = {
            emitEventKeys: [],
            nonEmitEventKeys: []
          };
          if (!this.applyButtonStatus) {
            this.$store.dispatch(
              'setDropdownSelectedOptions',
              this.radioBtnSelectedValues
            ); // update store
            this.$store.dispatch('setItemsInLocalStorage', [
              'selDropdownOptions',
              this.$store.getters.getDropdownSelectedOptions
            ]); // update localstorage, [key, data]

            // Identify which key changed
            for (const key in this.radioBtnSelectedValues) {
              if (
                this[key] !== this.radioBtnSelectedValues[key] &&
                emitMap[key] &&
                emitMap[key].constructor === Object &&
                emitMap[key].emit
              ) {
                changedKeyList.emitEventKeys.push(key);
              } else {
                changedKeyList.nonEmitEventKeys.push(key);
              }
            }

            if (changedKeyList.emitEventKeys.length > 0) {
              for (const i in changedKeyList.emitEventKeys) {
                eventBus.$emit(
                  emitMap[changedKeyList.emitEventKeys[i]].event,
                  this.radioBtnSelectedValues
                );
              }
            }
            this.applyButtonStatus = true;
            this.$refs.trigger.click();
          }
        }.bind(this),
        150
      );
    },
    cancel() {
      setTimeout(
        function () {
          for (const type in this.radioBtnSelectedValues) {
            this.radioBtnSelectedValues[type] = this[type];
          }
          this.applyButtonStatus = true;
          this.$refs.trigger.click();
        }.bind(this),
        150
      );
    },
    onShow() {},
    onHide() {
      for (const type in this.radioBtnSelectedValues) {
        this.radioBtnSelectedValues[type] = this[type];
      }
    },
    toggle() {},
    changeRadio(selectedOption) {
      // initially disable so true
      let radioStatus = true;
      for (const type in this.radioBtnSelectedValues) {
        if (this.radioBtnSelectedValues[type] !== this[type]) {
          radioStatus = false;
          break;
        }
      }
      this.applyButtonStatus = radioStatus;
    },
    columnsToProcess(obj) {
      if (!obj.reactToKey) {
        return obj;
      } else {
        if (obj.reactToKey && obj.showOn) {
          if (this[obj.reactToKey] === obj.showOn) {
            return obj;
          }
        } else if (obj.reactToKey && obj.keys) {
          if (
            obj.reactToMultiKeys &&
            obj.reactToKey.constructor === Array &&
            obj.reactToKey.length > 0
          ) {
            if (Object.keys(obj.keys).length > 0) {
              for (var item of Object.keys(obj.keys)) {
                let status = false;
                for (var idx in obj.reactToKey) {
                  if (item) {
                    const itm = item.split('&&');
                    if (itm[idx] === this[obj.reactToKey[idx]]) {
                      status = true;
                    } else {
                      status = false;
                      break;
                    }
                  }
                }
                if (status) {
                  obj.key = obj.keys[item];
                  return obj;
                }
              }
            }
          } else {
            obj.key = obj.keys[this[obj.reactToKey]];
            return obj;
          }
        }
      }
    }
  },
  watch: {
    cases_or_units: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          const mapArr = this.config.widgets.widget2.body.mapArr;
          const allSelectedMetrics = [];
          for (var obj of mapArr) {
            if (obj.reactToKey.constructor === String) {
              if (
                obj.showOn.constructor === String &&
                this[obj.reactToKey] === obj.showOn
              ) {
                allSelectedMetrics.push(obj);
              }
            } else if (
              obj.reactToKey.constructor === Array &&
              obj.showOn.constructor === Array
            ) {
              let statusIncr = 0;
              for (var idx in obj.reactToKey) {
                if (this[obj.reactToKey[idx]] === obj.showOn[idx].value) {
                  statusIncr++;
                } else {
                  break;
                }
              }
              if (statusIncr === obj.reactToKey.length) {
                allSelectedMetrics.push(obj);
              }
            }
          }
          this.allPastPerfMetrics = allSelectedMetrics;
          this.pastPerfSelMetrics();
        }
      }
    },
    'getInsightsForecastData.PastPerformance.metrics': {
      handler: function (newData) {
        if (newData && Object.keys(newData).length > 0) {
          this.pastPerfSelMetrics();
        }
      }
    }
  },
  computed: {
    getInsightsForecastData() {
      return this.$store.getters.getInsightsForecastData || [];
    },
    getSelDropdownOptions() {
      var options = this.$store.getters.getDropdownSelectedOptions;
      // sets state values to data variables
      for (var option in options) {
        this[option] = options[option];
        this.radioBtnSelectedValues[option] = options[option];
      }
      return options;
    },
    getDropdownOptions() {
      var dropDownOptions = this.$store.getters.getDropdownOptions;
      var dropDownOptionsObj = {};
      if (
        dropDownOptions &&
        dropDownOptions.constructor === Array &&
        dropDownOptions.length > 0
      ) {
        for (var options of dropDownOptions) {
          for (var key in options) {
            if (!dropDownOptionsObj?.hasOwnProperty(key)) {
              dropDownOptionsObj[key] = [];
            }
            if (
              dropDownOptionsObj[key] &&
              dropDownOptionsObj[key].indexOf(options[key]) === -1
            ) {
              dropDownOptionsObj[key].push(options[key]);
            }
          }
        }
      }
      return dropDownOptionsObj;
    },
    getCalendarData() {
      const calendarData = this.$store.getters.getCalendarData;
      const calendarObj = {};
      // Format calendar data
      if (calendarData && calendarData.length > 0) {
        for (var period of calendarData) {
          if (period.constructor === Object && Object.keys(period).length > 0) {
            const forecastOrPast = period.forecast_or_past.toLowerCase();
            const currentPeriod =
              period.period && period.period.replace(/ /g, '_').toLowerCase();
            const calGran = period.calender_granuality;
            if (!calendarObj?.hasOwnProperty(forecastOrPast)) {
              calendarObj[forecastOrPast] = {};
            }
            if (!calendarObj[forecastOrPast]?.hasOwnProperty(calGran)) {
              calendarObj[forecastOrPast][calGran] = {};
            }
            if (
              !calendarObj[forecastOrPast][calGran]?.hasOwnProperty(
                currentPeriod
              )
            ) {
              calendarObj[forecastOrPast][calGran][currentPeriod] = [];
            }
            calendarObj[forecastOrPast][calGran][currentPeriod].push(period);
          }
        }

        // set data in localstorage
        var lclStorage = localStorage.getItem('Forecast-v2');
        if (lclStorage) {
          lclStorage = JSON.parse(lclStorage);
          if (
            lclStorage.selCalendarOptions &&
            Object.keys(lclStorage.selCalendarOptions).length > 0
          ) {
          }
        }
        // if (!calendarObj.past) {
        //   let minDate = moment.utc(((this.$store.getters.getMaxDate['v2_forecast']) || {}).min_feed_date).format('MMM DD, YYYY');
        //   this.dataAvailable = moment.utc(minDate).add(13, 'Weeks');
        // }

        // Testing Needs to be Removed before pushing
        // delete calendarObj.past;
        // delete calendarObj.past.Week;
        // delete calendarObj.past.Week.last_26_weeks;
        // delete calendarObj.past.Week.last_1_year;
        // delete calendarObj.past.Month;
        // End of Testing

        return calendarObj;
      }
    },
    widget1Data: {
      get: function () {
        let chartData =
          this.$store.getters.getInsightsForecastData[
            this.config.widgets.widget1.meta.key
          ];
        var radioBtnMap = {
          Units: 'forecasted_units',
          Cases: 'forecasted_cases',
          Value: 'forecasted_value'
        };

        var selectedOption = [
          radioBtnMap[this.cases_or_units],
          radioBtnMap.Value
        ];
        const metricsTooltips = {
          forecasted_units:
            'Forecasted number of units for the chosen time period',
          forecasted_cases:
            'Forecasted number of cases for the chosen time period',
          forecasted_value:
            'Value of the forecast for the chosen time period. Computed as (Forecast * ' +
            this.price_type +
            ')'
        };

        const selectedForecastedUnits = [];
        if (chartData && chartData.metrics) {
          chartData = JSON.parse(JSON.stringify(chartData));
          for (var metric in chartData.metrics) {
            if (selectedOption.indexOf(metric) > -1) {
              chartData.metrics[metric].tooltip = metricsTooltips[metric];
              selectedForecastedUnits.push(chartData.metrics[metric]);
            }
          }
        }

        const returnObj = {
          size: {
            height: 400
          },
          data: [],
          legends: undefined,
          xs: {},
          axes: {}
        };
        if (
          chartData &&
          chartData.data &&
          Object.keys(chartData.data).length > 1
        ) {
          for (var key in chartData.data.data) {
            if (selectedOption.indexOf(key) > -1) {
              for (var key1 in chartData.data.data[key]) {
                if (key1 !== 'data') {
                  returnObj.data.push(chartData.data.data[key][key1]);
                  if (
                    Object.keys(chartData.data.xs).indexOf(
                      chartData.data.data[key][key1][0]
                    ) > -1
                  ) {
                    returnObj.xs[chartData.data.data[key][key1][0]] =
                      chartData.data.xs[chartData.data.data[key][key1][0]];
                  }
                }
              }
            }
          }
          returnObj.legends = chartData.data.legends;
          // returnObj.axes['Value'] = 'y2';
        }
        // let axisFormat = {
        //   y: {
        //     label: {
        //       text: this.cases_or_units,
        //       position: 'inner-top'
        //     }
        //   },
        //   y2: {
        //     label: {
        //       text: 'Value',
        //       position: 'inner-top'
        //     }
        //   }
        // };
        // returnObj.axis_format = {};
        // returnObj.axis_format.y = axisFormat.y;
        // returnObj.axis_format.y2 = axisFormat.y2;
        returnObj.colors = this.forecastedUnitsColors;

        // Rename of Chart and Metrics
        const map = {
          'Forecasted Units': 'Forecast',
          'Forecasted Cases': 'Forecast'
        };
        const keysToRename = ['data', 'xs'];
        for (var re of keysToRename) {
          if (returnObj?.hasOwnProperty(re)) {
            if (returnObj[re].constructor === Array) {
              for (var re1 of returnObj[re]) {
                if (
                  re1 &&
                  re1.constructor === Array &&
                  map?.hasOwnProperty(re1[0])
                ) {
                  re1[0] = map[re1[0]];
                  break;
                }
              }
            } else if (returnObj[re].constructor === Object) {
              var tempObj = {};
              tempObj[re] = {};
              Object.keys(returnObj[re]).map(function (item) {
                if (map?.hasOwnProperty(item)) {
                  var key = map[item];
                  tempObj[re][key] = returnObj[re][item];
                } else {
                  tempObj[re][item] = returnObj[re][item];
                }
              });
              returnObj[re] = tempObj[re];
            }
          }
        }
        // Rename Metrics
        for (var re2 of selectedForecastedUnits) {
          if (map?.hasOwnProperty(re2.title)) {
            re2.title = map[re2.title];
          }
        }
        // End of Renaming

        // set selected options in the download
        if (selectedOption && selectedOption.length > 0) {
          var downloadConfig =
            this.config.widgets.widget1.header.actions.download;
          downloadConfig.colMap = [];
          if (
            downloadConfig &&
            downloadConfig.allMap.constructor === Array &&
            downloadConfig.allMap.length > 0
          ) {
            for (var idx in downloadConfig.allMap) {
              if (
                selectedOption.indexOf(downloadConfig.allMap[idx].dbKey) > -1
              ) {
                downloadConfig.colMap.push(downloadConfig.allMap[idx]);
              }
            }
            const others = [
              {
                displayName:
                  'Start date of the ' + this.calendarGranularityLowerCase,
                dbKey: this.calendarGranularityLowerCase + '_start_date'
              }
            ];
            downloadConfig.colMap = [...others, ...downloadConfig.colMap];
            this.config.widgets.widget1.body.APIConfig.timeseriesDimension =
              this.calendarGranularityLowerCase + '_start_date';
          }
        }

        // Converting to Chart with Legends component required format
        this.selectedForecastedUnits = {};
        for (var o1 of selectedForecastedUnits) {
          this.selectedForecastedUnits[o1.title] = {
            tag1: o1.tag.tag1,
            tooltip: o1.tooltip
          };
        }
        // Set Tooltips for cards in the metricList
        if (
          this.forecastMetricsList &&
          this.forecastMetricsList.length &&
          this.forecastMetricsList.length > 0
        ) {
          for (var ob of this.forecastMetricsList) {
            ob.tooltip =
              ob.key === 'Forecast'
                ? metricsTooltips[
                    `forecasted_${this.cases_or_units.toLowerCase()}`
                  ]
                : metricsTooltips.forecasted_value;
          }
        }
        this.selectedForecastedUnits = { ...this.selectedForecastedUnits };
        return returnObj;
      }
    },
    widget3Data: {
      get() {
        this.config.widgets.widget3.header.title =
          'Accuracy Metrics for Forecast vs ' + this.forecast_vs;
        // this.config.widgets['widget3'].header.actions.download.fileName = 'Accuracy Metrics for Forecast vs ' + this.forecast_vs;
        const accuracyMetricsData =
          this.$store.getters.getInsightsForecastData[
            this.config.widgets.widget3.meta.key
          ];
        const returnArr = [];
        // Key Mappings for Consumption & Purchase Metrics
        const Consumption = [
          'wape_forecast_vs_consumption',
          'forecast_vs_consumption_mean_revenue_impact',
          'tracking_signal_forecast_vs_consumption',
          'durbin_watson_forecast_vs_consumption'
        ];
        const Purchase = [
          'wape_forecast_vs_purchase_order',
          'forecast_vs_purchase_order_mean_revenue_impact',
          'tracking_signal_forecast_vs_purchase_order',
          'durbin_watson_forecast_vs_purchase_order'
        ];
        const titles = [
          'wmape',
          'mean error',
          'tracking signal',
          'durbin watson metric'
        ];
        const tooltips = [
          'Weighted mean absolute percent error: Measure of percentage forecast error.',
          'Mean error in Forecast: Measure of mean revenue impact due to forecast errors.',
          'Measure of forecast bias: Value less than -4 indicates persistent over-forecasting and value greater than +4 indicates persistent under-forecasting.',
          'Measure of correlation between consecutive forecast errors: Value ranges between 0 and 4. Value of 2 indicates no autocorrelation, value between 0-2 indicates positive correlation and value between 2-4 indicates negative correlation.'
        ];
        let arrToLoop = [];
        if (accuracyMetricsData) {
          // Change based on the Forecast Vs selection from dropdown
          if (this.forecast_vs === 'Purchase') {
            arrToLoop = Purchase;
          } else if (this.forecast_vs === 'Consumption') {
            arrToLoop = Consumption;
          }
          if (
            accuracyMetricsData.metrics &&
            accuracyMetricsData.metrics.constructor === Object
          ) {
            for (var idx in arrToLoop) {
              returnArr.push({
                title: titles[idx],
                tooltip: tooltips[idx],
                metric: {
                  tag1: accuracyMetricsData.metrics[arrToLoop[idx]]
                }
              });
            }
          }
        }

        // set options for download
        if (arrToLoop.length > 0) {
          var downloadConfig =
            this.config.widgets.widget3.header.actions.download;
          downloadConfig.colMap = [];
          if (
            downloadConfig &&
            downloadConfig.allMap.constructor === Array &&
            downloadConfig.allMap.length > 0
          ) {
            for (var indx in downloadConfig.allMap) {
              if (arrToLoop.indexOf(downloadConfig.allMap[indx].dbKey) > -1) {
                downloadConfig.colMap.push(downloadConfig.allMap[indx]);
              }
            }
          }
        }

        return returnArr;
      }
    },
    columnDefs: {
      get() {
        let returnArr = [];
        const columns = widgetsConfig.tableColumns;
        const tempColumns = [];
        let tempObj = {};
        const tooltips = {
          PVP_past_forecasted_units: 'Forecast for the chosen time period',
          PVP_past_forecasted_cases: 'Forecast for the chosen time period',
          future_forecasted_value:
            'Value of the forecast for the chosen time period. Computed as (Forecast * ' +
            this.price_type +
            ')',
          PVP_past_consumption_units:
            'Number of ' +
            this.cases_or_units.toLowerCase() +
            ' sold by Amazon for the chosen time period',
          PVP_past_consumption_cases:
            'Number of ' +
            this.cases_or_units.toLowerCase() +
            ' sold by Amazon for the chosen time period',
          PVP_past_purchase_order_units:
            'Number of ' +
            this.cases_or_units.toLowerCase() +
            ' ordered by Amazon through Purchase Orders',
          PVP_past_purchase_order_cases:
            'Number of ' +
            this.cases_or_units.toLowerCase() +
            ' ordered by Amazon through Purchase Orders'
        };
        const hidePastPerfColumns =
          this.dataAvailable && this.dataAvailable.show
            ? this.dataAvailable.show
            : false;
        for (const obj of columns) {
          tempObj = JSON.parse(JSON.stringify(obj));
          if (obj.children && obj.children.length > 0) {
            tempObj.children = [];
            for (let childObj of obj.children) {
              childObj = this.columnsToProcess(childObj);
              if (childObj && Object.keys(childObj).length > 0) {
                if (Object.keys(tooltips).indexOf(childObj.key) > -1) {
                  childObj.toolTipText = tooltips[childObj.key];
                }
                if (obj.group === 'past') {
                  childObj.hide = hidePastPerfColumns;
                }
                tempObj.children.push(childObj);
              }
            }
            tempColumns.push(tempObj);
          } else {
            tempObj = this.columnsToProcess(obj);
            if (tempObj && Object.keys(tempObj).length > 0) {
              if (Object.keys(tooltips).indexOf(tempObj.key) > -1) {
                tempObj.toolTipText = tooltips[tempObj.key];
              }
              tempColumns.push(tempObj);
            }
          }
        }

        // set download columns based on the selected options
        if (tempColumns.length > 0) {
          const downloadConfig =
            this.config.widgets.widget4.header.actions.download;
          downloadConfig.colMap = [];
          for (var i in tempColumns) {
            if (tempColumns[i].children && tempColumns[i].children.length > 0) {
              for (var j in tempColumns[i].children) {
                if (downloadConfig.allMap && downloadConfig.allMap.length > 0) {
                  for (var k in downloadConfig.allMap) {
                    if (
                      downloadConfig.allMap[k].dbKey ===
                      tempColumns[i].children[j].key
                    ) {
                      downloadConfig.colMap.push(downloadConfig.allMap[k]);
                    }
                  }
                }
              }
            } else {
              for (var l of downloadConfig.allMap) {
                if (l.dbKey === tempColumns[i].key) {
                  downloadConfig.colMap.push(l);
                }
              }
            }
          }
          const defaultColumns = [
            {
              displayName: 'SKU',
              dbKey: 'asin'
            },
            {
              displayName: 'SKU Title',
              dbKey: 'asin_title'
            },
            {
              displayName: 'Product Page URL',
              dbKey: 'asin_url'
            }
          ];
          downloadConfig.colMap = [...defaultColumns, ...downloadConfig.colMap];
        }
        returnArr = widgetsConfig.getColumnDefs(
          this,
          'SKULevelBreakDown',
          tempColumns
        );
        return returnArr;
      }
    },
    calendarGranularityLowerCase() {
      return this.calender_granuality.toLowerCase();
    }
  }
};

export default oObject;
</script>

<style lang="css" scoped>
.data-unavailable-icon:before {
  font-size: 80px;
}

.dropdown-hover {
  z-index: 100;
}

.forecastFilterSectionRollUp {
  max-width: 128px;
}

.seperator {
  border: solid 0.5px #979797;
  height: 18px;
}

.forecast-options {
  border: 1px solid #e9eaeb;
  max-height: 36px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
  border-radius: 2px;
  padding: 0.8rem;
}

.dropdownContainer {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 3.2rem;
}

.metrics-tooltip {
  max-width: 350px;
  word-break: break-word;
}

.metrics-card {
  min-width: 120px;
}

.c3-chart-height {
  height: 480px;
}
</style>
