// needed for getting the number of actions which can be merged together into a single action
// if their time period doesn't have a gap
import { cloneDeep, flatten } from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

const OPERATIONS_ENUMS = {
  'INCR/DECR': 'INCR/DECR',
  EQUALS: 'EQUALS',
  INCR: 'INCR',
  DECR: 'DECR'
};

function createCustomSubArrays(ar, n) {
  let se = new Set();
  let finalArray = [];
  let initialIndex, finalIndex;
  for (let i = 0; i < n; i++) {
    // Checking if an element already exist in
    // the current sub-array
    if (se.has(ar[i]) === false) {
      // inserting the current element
      if (i) {
        finalArray.push({
          value: ar[i - 1],
          initialIndex,
          finalIndex
        });
      }
      initialIndex = i;
      finalIndex = i;
      se.clear();
      se.add(ar[i]);
    } else {
      finalIndex++;
      // clear set for new possible value
      // of subarrays
      se.clear();

      // inserting the current element
      se.add(ar[i]);
    }
  }
  finalArray.push({
    value: ar[finalIndex],
    initialIndex,
    finalIndex
  });
  return finalArray;
}

function getDefaultDateRange(startDate, endDate) {
  startDate = cloneDeep(startDate);
  const defaultDate = new Date(startDate);
  const toDate = new Date();
  toDate.setFullYear(startDate.getFullYear() + 10);
  startDate.setDate(startDate.getDate() - 1);
  const startDateRangeObj = {
    fromDate: startDate,
    toDate: toDate,
    defaultDate: defaultDate
  };
  const endDateRangeObj = {
    fromDate: new Date(),
    toDate: toDate,
    defaultDate: endDate || toDate
  };
  return {
    startDateRange: startDateRangeObj,
    endDateRange: endDateRangeObj,
    endDate: toDate
  };
}
function orderSelectedActions(actionStringArr = []) {
  const orderSelectedActionsMap = {};
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  for (let day of days) {
    for (let actionString of actionStringArr) {
      const [actionDay, time] = actionString.split('-');
      if (actionDay.includes(day)) {
        if (orderSelectedActionsMap[day]) {
          orderSelectedActionsMap[day].push(time);
          orderSelectedActionsMap[day].sort((a, b) => +a - +b);
        } else {
          orderSelectedActionsMap[day] = [time];
        }
      }
    }
  }
  return Object.entries(orderSelectedActionsMap)
    .map(([day, values]) => values.map((val) => `${day}-${val}`))
    .flat(2);
}

function sanitizeClipboardData(text = '') {
  return typeof text === 'string'
    ? text.split('\n').map((val) => val.replace('%', '').split('\t'))
    : [];
}
function createUniqueActionId() {
  return `Temp-${uuidv4()}`;
}

function getActionId(id) {
  return id.startsWith('Temp') ? '' : id;
}

function addActionId({
  actionId,
  newActionIdStore,
  currentDay,
  currentHour,
  currentSelectedAction,
  selectedOperation,
  vueRef
}) {
  vueRef.actionIdStore[currentSelectedAction][selectedOperation][currentDay][
    currentHour
  ] = newActionIdStore?.[actionId] || createUniqueActionId(); // creating new unique id for copy-pasted values from excel
}

function parseToIntegerOrFloat(val) {
  val = Number(val);
  return Number.isInteger(val) ? val : val.toFixed(1);
}

// code block simulating excel's copy/paste feature
// start

// possible scenarios
// 1. single copied cell paste to multiple selected cells
// 2. multiple copied cell paste
//  a. When selected target is a single cell => should paste starting from target cell
//  b. When multiple cells are selected
//     x. 1D array of copied content
//        i. if number of cells in a row of the target is greater than the number of cells in
//           a row of copied content, paste content cyclically.
//        ii. if number of cells in a row of the target is less than the number of cells in
//            a row of copied content, paste the original copied row.
//     y. 2D array of copied content
//        i. if number of cells in a column of the target is greater than the number of cells in
//           a column of copied content, paste content cyclically.
//        ii. if number of cells in a column of the target is less than the number of cells in
//            a column of copied content, paste the original copied column.

function getCellsToPasteCopiedValues(orderSelectedActionsArr, copiedContent) {
  let copiedActionCount = 0;
  for (let row of copiedContent) {
    copiedActionCount += row.length;
  }
  const isSingleActionCopied = copiedActionCount === 1;
  // handle cyclical row and column content paste (scenario 2)
  if (!isSingleActionCopied) {
    const dayWiseActions = orderSelectedActionsArr.reduce((acc, action) => {
      const day = action.split('-')[0];
      acc.set(day, [...(acc.get(day) || []), action]);
      return acc;
    }, new Map());
    // consider dayWiseActions ===> {
    //   MON: [MON-1, MON-2,...],
    //   TUE: [TUE-1, TUE-2,...],
    //   WED: [WED-1, WED-2,...],
    //   THU: [THU-1, THU-2,...],
    //   FRI: [FRI-1, FRI-2,...],
    // }

    const dayKeysArray = Array.from(dayWiseActions.keys());
    // const dayKeysArray ==> [MON,TUE,WED,THU,FRI]
    // consider a 2x2 copiedcontent => [
    //   [1, 4],
    //   [2, 4]
    // ];

    const resultingDays = dayKeysArray.filter(
      (d, i) =>
        !i ||
        (i % copiedContent.length === 0 &&
          i + copiedContent.length <= dayKeysArray.length)
    );

    // now resultingdays ==> [
    //   MON,
    //   WED,
    //   FRI
    // ] as column length i.e. copiedcontent length is 2 so we skip Tue and Thur

    // now we loop over actions in each resulting day and similarly skip apply
    // the above skip logic for cells in a row

    // result ==> [
    //   [MON-1, MON-3, MON-5],
    //   [WED-1, WED-3, WED-5],
    //   [FRI-1, FRI-3, FRI-5],
    // ]
    // Flatten it and return it to the paste handler
    return flatten(
      resultingDays.map((key, dayIndex) =>
        dayWiseActions
          .get(key)
          .filter(
            (val, index) =>
              !index ||
              (copiedContent[dayIndex]?.length &&
                index % copiedContent[dayIndex].length === 0 &&
                index + copiedContent[dayIndex].length <=
                  dayWiseActions.get(key).length)
          )
      )
    );
  } else {
    // return all days' action as copied content is a single cell (scenario 1)
    return orderSelectedActionsArr;
  }
}

function pasteContent({
  currentSelectedAction,
  newActionIdStore,
  selectedOperation,
  copiedContent,
  everyday,
  selectedActionsDayIndex,
  time,
  existingValue,
  startDate,
  endDate,
  error,
  vueRef
}) {
  copiedContent.forEach((rowVal, dayIndex) => {
    const currentDay = everyday[selectedActionsDayIndex + dayIndex];
    if (currentDay) {
      rowVal.forEach((cellValWithActionId, hourIndex) => {
        const currentHour = Number(time) + hourIndex;
        const cellVal =
          cellValWithActionId?.split('--')?.[0] ?? cellValWithActionId;
        const actionId = cellValWithActionId?.split('--')?.[1] ?? null;
        error = populateCellWithCopiedCell({
          currentHour,
          cellVal,
          existingValue,
          currentDay,
          startDate,
          endDate,
          vueRef,
          actionId,
          newActionIdStore,
          currentSelectedAction,
          selectedOperation,
          error
        });
      });
    }
  });
  return error;
}

// end

function getPrefixAndValue(val) {
  const hasPrefix = ['+', '-'].includes(val[0]);
  return hasPrefix ? [val[0], val.slice(1)] : ['', val];
}

function populateCellWithCopiedCell({
  currentHour,
  cellVal,
  existingValue,
  currentDay,
  startDate,
  endDate,
  vueRef,
  actionId,
  newActionIdStore,
  currentSelectedAction,
  selectedOperation,
  error
}) {
  if (currentHour < 24) {
    if (cellVal !== 'null') {
      if (!(cellVal.match(/[a-zA-Z]|[*?^${}()|[\]\\]/g) || [])?.length) {
        // regex to allow only numeric or perentage values
        existingValue[currentDay][currentHour].startDate =
          existingValue[currentDay][currentHour].startDate || startDate;
        existingValue[currentDay][currentHour].endDate =
          existingValue[currentDay][currentHour].endDate || endDate;
        const [prefix, actualValue] = getPrefixAndValue(cellVal);
        existingValue[currentDay][currentHour].value =
          parseToIntegerOrFloat(actualValue);

        const prefixOperationMap = {
          [OPERATIONS_ENUMS['INCR/DECR']]: {
            '+': OPERATIONS_ENUMS.INCR,
            '-': OPERATIONS_ENUMS.DECR,
            '': OPERATIONS_ENUMS.INCR
          },
          [OPERATIONS_ENUMS.EQUALS]: {
            '+': OPERATIONS_ENUMS.EQUALS,
            '-': OPERATIONS_ENUMS.EQUALS,
            '': OPERATIONS_ENUMS.EQUALS
          }
        };

        existingValue[currentDay][currentHour].actionMode =
          prefixOperationMap[vueRef.selectedOperation][prefix];
        existingValue[currentDay][currentHour].actualValue =
          getAbsoluteActionValue(
            existingValue[currentDay][currentHour].value,
            existingValue[currentDay][currentHour].actionMode
          );
        addActionId({
          actionId,
          newActionIdStore,
          currentDay,
          currentHour,
          currentSelectedAction,
          selectedOperation,
          vueRef
        });
      } else {
        error = 'Only numeric or percentage values are allowed.';
      }
    }
  }
  return error;
}

function populateAction({
  selectedOperation,
  newValue,
  formattedEndTime,
  formattedStartTime,
  endDate,
  startDate,
  startDateRange,
  endDateRange,
  actionId,
  passStatus
}) {
  return {
    actionMode: selectedOperation.value,
    value: newValue,
    selectedStartTime: formattedStartTime,
    selectedEndTime: formattedEndTime,
    endDate,
    startDate,
    endDateRange,
    startDateRange,
    actionId,
    passStatus,
    actualValue: getAbsoluteActionValue(newValue, selectedOperation.value)
  };
}

function isDateDisabled(date) {
  return moment(new Date(date)).diff(moment(new Date())) < 0;
}

// these colors are used just for hourly bidder so kept it here
const COLORS = {
  'green-celadon': '#B2DDBF',
  'green-papaya': '#FFF1D6',
  'green-sea-green': '#78C38E',
  'green-medium-sea-green': '#3EA95E',
  'pink-amaranth': '#EFA8B1',
  'pink-carmine': '#E36777',
  'red-rusty': '#D7263D',
  'grey-xxx-light': '#e9eaeb',
  'grey-xx-light': '#CACCCE',
  'grey-base': '#2b333b',
  'white-base': '#FFF',
  'blue-20': '#B7DBFF',
  'blue-50': '#2E97FF',
  'blue-80': '#0053A4'
};
const colorMap = new Map();

colorMap.set('sales', COLORS['green-papaya']);
colorMap.set('+low', COLORS['green-celadon']);
colorMap.set('+mid', COLORS['green-sea-green']);
colorMap.set('+high', COLORS['green-medium-sea-green']);
colorMap.set('-low', COLORS['pink-amaranth']);
colorMap.set('-mid', COLORS['pink-carmine']);
colorMap.set('-high', COLORS['red-rusty']);
colorMap.set('empty', COLORS['grey-xxx-light']);
colorMap.set('expired', COLORS['grey-xx-light']);
colorMap.set('dark', COLORS['grey-base']);
colorMap.set('light', COLORS['white-base']);
colorMap.set('blue20', COLORS['blue-20']);
colorMap.set('blue50', COLORS['blue-50']);
colorMap.set('blue80', COLORS['blue-80']);
const footerLegends = {
  EQUALS: [
    // {
    //   name: 'Sales',
    //   color: colorMap.get('sales')
    // },
    {
      name: '0-30%',
      color: colorMap.get('blue20')
    },
    {
      name: '31-60%',
      color: colorMap.get('blue50')
    },
    {
      name: '>60%',
      color: colorMap.get('blue80')
    },
    {
      name: 'Expired',
      color: colorMap.get('expired')
    }
  ],
  'INCR/DECR': [
    // {
    //   name: 'Sales',
    //   color: colorMap.get('sales')
    // },
    {
      name: '0-35%',
      color: colorMap.get('+low')
    },
    {
      name: '+36-60%',
      color: colorMap.get('+mid')
    },
    {
      name: '>60%',
      color: colorMap.get('+high')
    },
    {
      name: '0-35%',
      color: colorMap.get('-low')
    },
    {
      name: '36-60%',
      color: colorMap.get('-mid')
    },
    {
      name: '>60%',
      color: colorMap.get('-high')
    },
    {
      name: 'Expired',
      color: colorMap.get('expired')
    }
  ]
};

// color for legends
const getColorForIncreaseDecreaseAction = ({ value, prefix, expired }) => {
  if (expired) {
    return {
      bgColor: colorMap.get('expired'),
      textColor: colorMap.get('dark')
    };
  } else if (!value) {
    return {
      bgColor: colorMap.get('empty'),
      textColor: colorMap.get('dark')
    };
  } else if (value >= 0 && value < 36) {
    return {
      bgColor: colorMap.get(`${prefix}low`),
      textColor: colorMap.get('dark')
    };
  } else if (value >= 36 && value < 61) {
    return {
      bgColor: colorMap.get(`${prefix}mid`),
      textColor: colorMap.get('light')
    };
  } else if (value > 60) {
    return {
      bgColor: colorMap.get(`${prefix}high`),
      textColor: colorMap.get('light')
    };
  }
};

const getColorForAssignAction = ({ value, expired }) => {
  if (expired) {
    return {
      bgColor: colorMap.get('expired'),
      textColor: colorMap.get('dark')
    };
  } else if (!value) {
    return {
      bgColor: colorMap.get('empty'),
      textColor: colorMap.get('dark')
    };
  } else if (value >= 0 && value < 31) {
    return {
      bgColor: colorMap.get('blue20'),
      textColor: colorMap.get('dark')
    };
  } else if (value > 30 && value < 61) {
    return {
      bgColor: colorMap.get('blue50'),
      textColor: colorMap.get('light')
    };
  } else if (value > 60) {
    return {
      bgColor: colorMap.get('blue80'),
      textColor: colorMap.get('light')
    };
  }
};

const getColor = ({ value, prefix, operation, expired = false }) => {
  if (operation === 'INCR/DECR') {
    return getColorForIncreaseDecreaseAction({ value, prefix, expired });
  } else {
    return getColorForAssignAction({ value, expired });
  }
};

// calculating dynamic width for merged action slot 3.4 rem = action slot width, 0.8 rem = action slot gap
const getWidth = ({ day, time, currentMergedCellsMap }) => {
  return (
    3.4 * (currentMergedCellsMap?.[day]?.[time]?.span + 1) +
    0.8 * currentMergedCellsMap?.[day]?.[time]?.span +
    'rem'
  );
};

// calculating dynamically action slot styles
const getSlotStyle = ({
  day,
  time,
  selectedActions,
  dayObj,
  selectedOperation
}) => {
  return {
    backgroundColor: selectedActions[`${day}-${time}`]
      ? '#CCE5FD'
      : getTimeSlotValue({ day, time, dayObj, selectedOperation }).bgColor,
    color: selectedActions[`${day}-${time}`]
      ? colorMap.get('dark')
      : getTimeSlotValue({ day, time, dayObj, selectedOperation }).color,
    border: selectedActions[`${day}-${time}`] ? '1px dashed #0063C5' : 'none'
  };
};

const getTimeSlotValue = ({ day, time, dayObj, selectedOperation }) => {
  const timeSlot = dayObj[day][time];
  let prefix;
  if (selectedOperation === 'EQUALS') prefix = '';
  else {
    prefix = timeSlot.actionMode === 'DECR' ? '-' : '+';
  }
  const value = timeSlot.value;
  const finalValue = value ? `${prefix}${value}%` : '';
  const color = getColor({
    value,
    prefix,
    operation: selectedOperation,
    expired: isDateDisabled(timeSlot.endDate)
  });
  return {
    value: finalValue,
    bgColor: color.bgColor,
    color: color.textColor
  };
};

const actionTemplateUtils = {
  footerLegends,
  getWidth,
  getSlotStyle,
  getTimeSlotValue
};

function getAbsoluteActionValue(value, operation) {
  return Math.abs(value || 0) * (operation === 'DECR' ? -1 : 1);
}

export {
  createCustomSubArrays,
  getDefaultDateRange,
  orderSelectedActions,
  sanitizeClipboardData,
  populateAction,
  pasteContent,
  actionTemplateUtils,
  getAbsoluteActionValue,
  createUniqueActionId,
  getActionId,
  getCellsToPasteCopiedValues
};

export const lineItemRetailers = [
  'target_criteo',
  'target',
  'albertsons_criteo',
  'ubereats_criteo',
  'ulta_criteo',
  'target_rms',
  'walmart_criteo',
  'cvs_criteo',
  'meijer',
  'asda_criteo',
  'walgreen_criteo',
  'costco',
  'freshdirect_criteo'
];

export function isLineItemRetailer(retailer) {
  return lineItemRetailers.includes(retailer.toLowerCase());
}
