import { commons } from '@/components/ams/campaigns/commons.js';
import HttpService from '@/utils/services/http-service';
import {
  emailRequestConfig,
  postKeywordActionSubmitReview,
  areSelectedCampaignsDistinct,
  getBulkSearchConfigs,
  getPinAndUnpinMessageForCalendarPin,
  getChartWidgetHeaderOptionProps,
  getInitRequestParams
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils.js';
import {
  getActionBarActions as promoteIqKeywordActionBarActions,
  getActionsConfig as getPromoteIqKeywordActionsConfig
} from '@/pages/dashboard_service_campaign_management/promoteiq/keywords.js';
import {
  getRetailerConfig,
  retailers as promoteIqRetailers,
  retailersWithKeywordActions as promoteIqRetailersWithKeywordActions
} from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils';
import BidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
const chartingWb = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  import('@/components/widgets/collaboration/collaboration.vue');
const iconHeader = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const failedCampaignsBanner = () =>
  import('@/pages/entity_details/common_components/banner.vue');

async function actionsBarEditStatusHandler(
  dropDownSelection,
  selections,
  retailer,
  PRIMARY_KEY
) {
  const config = getActionsConfig(
    retailer,
    dropDownSelection[0].value.toLowerCase()
  );
  const payload = [];
  const campaigns = new Set();
  selections.forEach((row) => {
    if (config.validator(row)) {
      campaigns.add(row?.data?.[config.campaignIdKey]);
      payload.push({
        widget: 'keyword',
        actionType: config.payload.actionType,
        actionSource: {
          pageUrl: window.location.href
        },
        primaryKey: row?.data?.[PRIMARY_KEY],
        actionPayload: config.payload.actionPayload(row),
        viewPayload: config.payload.viewPayload(row),
        previousEntityValue: '',
        newEntityValue: ''
      });
    }
  });
  if (payload.length < selections.length) {
    this.$emit(
      'openSnackbar',
      'Invalid selections have been automatically deselected.'
    );
    if (payload.length === 0) {
      return;
    }
  }
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    if (config.shouldSubmitForReview) {
      await Promise.all(
        campaigns.map(async (campaignId) =>
          postKeywordActionSubmitReview(campaignId, retailer)
        )
      );
    }
    this.$emit('openSnackbar', 'Action has been submitted');
  } catch (err) {
    console.log(err);
    this.$emit('openSnackbar', 'Something went wrong.');
  }
}

function getNames(retailer, advancedReporting) {
  if (advancedReporting) {
    return {
      listApiPageName: `${retailer}_cm_keywords_ar`,
      chartWidgetName: `${retailer}_cm_keywords_charting_wb_ar`,
      tableWidgetName: `${retailer}_cm_keywords_table_ar`,
      localStorageFilterKey: `${retailer}-keywords-filter_ar`
    };
  }
  return {
    listApiPageName: `${retailer}_cm_keywords`,
    chartWidgetName: `${retailer}_cm_keywords_charting_wb`,
    tableWidgetName: `${retailer}_cm_keywords_table`,
    localStorageFilterKey: `${retailer}-keywords-filter`
  };
}

function getActionsConfig(retailer, action) {
  if (promoteIqRetailersWithKeywordActions.includes(retailer)) {
    return getPromoteIqKeywordActionsConfig(retailer, action);
  }
  const config = {
    'edit-status': {
      dropDownOptions: [
        { title: 'Delete', value: 'Delete' },
        { title: 'Restore', value: 'Restore' }
      ],
      campaignIdKey: 'citrus_cm_keyword_campaign_id'
    },
    delete: {
      campaignIdKey: 'citrus_cm_keyword_campaign_id',
      validator() {
        return true;
      },
      payload: {
        actionType: `${retailer}CampaignRemoveKeyword`,
        actionPayload(row) {
          return {
            campaignId: row?.data?.citrus_cm_keyword_campaign_id,
            campaignType: 'productAds',
            remove_keyword: row?.data?.citrus_cm_keyword_keyword
          };
        },
        viewPayload(row) {
          return {
            campaignId: row?.data?.citrus_cm_keyword_campaign_id,
            campaignType: 'productAds',
            remove_keyword: row?.data?.citrus_cm_keyword_keyword
          };
        }
      },
      shouldSubmitForReview: true
    },
    restore: {
      campaignIdKey: 'citrus_cm_keyword_campaign_id',
      validator(row) {
        return true;
      },
      payload: {
        actionType: `${retailer}CampaignAddBulkKeywords`,
        actionPayload(row) {
          return {
            campaignId: row?.data?.citrus_cm_keyword_campaign_id,
            campaignType: 'productAds',
            keywords: [
              {
                new_keyword_matched_type: 'MATCH_TYPE_EXACT_MATCH',
                new_keyword: row?.data?.citrus_cm_keyword_keyword,
                new_keyword_is_suggested: false
              }
            ]
          };
        },
        viewPayload(row) {
          return {
            spreadViewPayload: true,
            viewPayloadArray: [
              {
                campaignId: row?.data?.citrus_cm_keyword_campaign_id,
                new_keyword: row?.data?.citrus_cm_keyword_keyword,
                match_type: 'Exact'
              }
            ]
          };
        }
      },
      shouldSubmitForReview: true
    }
  };
  return config[action];
}

function getEditStatusActionObject(context, retailer) {
  const config = getActionsConfig(retailer, 'edit-status');
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: config.dropDownOptions,
    disabled: !areSelectedCampaignsDistinct(
      context.rowSelections,
      config.campaignIdKey
    ),
    tippy: !areSelectedCampaignsDistinct(
      context.rowSelections,
      config.campaignIdKey
    )
      ? getRetailerConfig(retailer).multiCampaignErrorMessage
      : '',
    async handler(selectionLength, dropDownSelection, selections) {
      await actionsBarEditStatusHandler.call(
        this,
        dropDownSelection,
        selections,
        retailer,
        'keyword_id'
      );
    }
  };
}

// contains actions and their handlers similar to that of walmart campaign management
const getActionBarActions = (context, retailer) => {
  return [
    {
      icon: 'show-chart',
      title: 'PLOT ROWS',
      id: 'show-chart',
      removeSelectionState: false,
      handler(selectionLength, dropDownSelection, selections) {
        // here {this} is bound to the cw_actionsBar component
        this.$emit('handlePlotRows', selections);
      }
    },
    ...(promoteIqRetailersWithKeywordActions.includes(retailer)
      ? promoteIqKeywordActionBarActions(context, retailer)
      : []),
    getEditStatusActionObject(context, retailer)
  ];
};

export function keywordsConfig(retailer, advancedReporting) {
  const tabName = 'Keywords';
  const {
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey
  } = getNames(retailer, advancedReporting);
  const PRIMARY_KEY = 'keyword_id';
  return {
    PRIMARY_KEY: PRIMARY_KEY,
    entity: 'keywords',
    styleClass: 'orders generic-campaign-management',
    // list API data
    pageInfo: {
      name: listApiPageName,
      title: 'Keywords'
    },
    initRequestParams: getInitRequestParams(
      'Keywords',
      listApiPageName,
      chartWidgetName,
      tableWidgetName
    ),
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      failedCampaignsBanner: failedCampaignsBanner,
      BidChangePanel: BidChangePanel
    },
    // header options passed to dashboard_inflator
    headerOptions: (context) => {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: getChartWidgetHeaderOptionProps(context),
        [tableWidgetName]: {
          props: {
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  actionBarActions: getActionBarActions(context, retailer),
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...(!context.rowSelections.length
                ? [
                    {
                      ui_component: 'iconTextActions',
                      props: {
                        action: { title: 'Email', icon: 'email-subscription' },
                        mode: 'default',
                        applyPadding: false
                      },
                      events: {
                        clicked: () => {
                          context.showModal = true;
                        }
                      },
                      order: 400
                    }
                  ]
                : [])
            ],
            enableManageColumns: !context.rowSelections.length,
            headerContainerGridStyles: {
              ...(context.rowSelections.length
                ? { padding: 0 }
                : {
                    display: 'flex',
                    'justify-content': 'flex-end',
                    'align-items': 'flex-start'
                  })
            },
            searchParams: {
              show: false
            },
            gridOptions: context.gridOptions,
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: context.handleSelectionChange
            },
            rowHeight: 45
            // selections: context.rowSelections
          }
        }
      };
    },
    filterSettings: {
      emitEvent: `${retailer}FilterApply`,
      uiKey: 0,
      show: true,
      // to do update backend cube
      backendCube: 'citrus_campaigns_workbench',
      defaultDatePostRange: 'last30Days',
      enableSave: true,
      enableRemoveAll: true,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: localStorageFilterKey,
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: 'Search for a Keyword',
        searchDimension: 'search'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      pinMessage: getPinAndUnpinMessageForCalendarPin().pinMessage,
      unpinMessage: getPinAndUnpinMessageForCalendarPin().unpinMessage,
      enableBetweenOperator: true,
      additionalRanges: commons.additionalDateRanges(),
      bulkSearchConfigs: getBulkSearchConfigs(retailer, tabName, {
        findEntityInputPlaceholder: 'Find a Keyword',
        subTitle: 'keywords',
        dimensionNameMapping: 'keyword'
      })
    },
    widgetNames: {
      chart: chartWidgetName,
      table: tableWidgetName
    },
    getActivityData: (data, key) => {
      return {
        params: { widget: 'keyword', primaryKey: data[key] }
      };
    },
    modal: {
      modalConfig: emailRequestConfig,
      clickedOutsideModal(dashboardWrapperVueRef) {
        dashboardWrapperVueRef.showModal = false;
        this.modalConfig = emailRequestConfig;
      }
    },
    ...(!promoteIqRetailers.includes(retailer)
      ? {
          componentBelowFilterVHtmlText:
            '<div class="u-font-size-5">The keyword tab includes campaigns whose placement type is “Search” only. Hence the performance metrics in the keyword tab will differ from other tabs.</div>',
          componentBelowFilter: function (dashboardWrapperVueRef) {
            return {
              show: dashboardWrapperVueRef.showComponentBelowFilter,
              ui_component: 'failedCampaignsBanner',
              props: {
                showCta: false,
                showCloseIcon: true,
                closeIconCustomClass: 'u-margin-left-auto',
                iconCustomClass: 'u-color-orange-base',
                vHtmlText: this.componentBelowFilterVHtmlText
              },
              events: {
                closeIconClicked: function () {
                  dashboardWrapperVueRef.showComponentBelowFilter = false;
                }
              },
              customClass: 'background-yellow-warning'
            };
          }
        }
      : {}),
    async onCreated(dashboardWrapperVueRef) {
      dashboardWrapperVueRef.showComponentBelowFilter = true;
    }
  };
}
