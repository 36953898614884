<template>
  <section>
    <div v-if="enableV2">
      <categoryPageV2 />
    </div>
    <section v-else>
      <div v-show="pageFetched">
        <sticky-filter-panel
          v-if="showFilter"
          ref="sticky-filter-panel"
          :key="stickyFilterKey"
          :hide-export="true"
          :hide-clear-all-btn="true"
          :disable-view="true"
          :show-filter="showFilter"
          class="sticky-panel sticky-filter-panel category_page"
          :filter-data="filterComputedData"
          :primary-filter-data="primaryFilterData"
          :secondary-filter-data="secondaryFilterComputedData"
          :filter-local-storage-key="filterLocalStorageKey"
          :filter-emit="filterEmit"
          :options-list="viewList || []"
          :selected-option="selectedView"
          :filter-state="filterState"
          :default-date-post-range="defaultDateRange"
          :min-max-date="minMaxDate"
          :hide-view-btn="hideViewBtn"
          :hide-remove-btn="true"
          :hide-update-view="hideUpdateView"
          :is-update-view-loading="isUpdateViewLoading"
          :is-save-view-loading="isSaveViewLoading"
          :enable-open-filter="enableOpenFilter"
          :filter-key="filterKey"
          :additional-ranges="additionalRanges"
          :date-mode="dateMode"
          :external-filter-selection-listener="externalFilterSelectionListener"
          :selection-mode="selectionMode"
          :view-selection-enabled="false"
          :css-class="cssClass"
          @savedFilterApplied="handleSaveFilterApplied"
          @exportWidgets="handleExportWidgets"
          @toggleEdit="handleToggleEdit"
          @input="handleVrSelectInput"
          @updateView="handleUpdateView"
          @resetView="handleResetView"
          @onCreate="onCreate"
          @filterMounted="handleFilterMount"
          @filterBeforeDestroy="handleFilterBeforeDestroy"
        >
          <div
            slot="before-filter"
            class="u-display-flex u-flex-align-items-center u-height-36px"
            @click="goToExecutivePage()"
          >
            <rb-icon
              class="rb-icon u-spacing-ml-xs u-color-grey-lighter u-spacing-mr-s u-cursor-pointer"
              :icon="'arrow_back'"
            />
            <div
              class="u-font-size-2 u-font-weight-700 u-display-flex capitalize"
            >
              {{ categoryName }}
            </div>
          </div>
          <div
            v-if="!isMarketShareLite"
            slot="preDatePicker"
            class="u-display-flex u-flex-align-items-center"
          >
            <rb-button
              text="View SKUs"
              :type="'filled'"
              class="u-spacing-mr-s"
              :click-fn="navigateTo.bind(this)"
            />
          </div>
          <div
            slot="after-filter"
            :class="{ 'subcagetory-dropdown': !enableHeirarchy }"
            class="u-border-radius-xxxl u-spacing-pv-xs u-spacing-ph-s u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center u-bg-color-grey-white"
            style="margin-bottom: 5px"
          >
            <div
              v-if="!enableHeirarchy"
              v-tippy="{ placement: 'top', arrow: false }"
              class="u-font-size-5 u-display-inline-flex u-flex-align-items-center action--text opacity-0_5"
              title="No sub-category available for the selected category"
              style="height: 20px"
            >
              <span
                class="rb-icon rb-icon--small u-spacing-mr-xs icon-add-circle-fill"
              />
              <span>Add a sub category filter</span>
            </div>
            <SubCategoryFilter
              v-else
              :selected-category="currentCategory"
              :selected-hierarchy="selectedSubCategory"
              :selections="selections"
              :category-status-list="categoryStatusList"
              @applySubCategory="applySubCategory"
              @onShow="onSubcategoryShow"
            />
          </div>
          <div
            v-for="(attributes, index) in dynamicAttributes"
            :key="index"
            slot="after-filter"
            v-tippy="{
              placement: 'top-start',
              arrow: false,
              maxWidth: '200px',
              size: 'regular'
            }"
            class="u-display-flex"
            :title="
              !disableAttribute(attributes.path, categoryStatusList)
                ? 'There is no data available for the combination of this filter and the other selected filters.'
                : ''
            "
          >
            <rb-filter
              ref="filter"
              :config="{
                title: attributes.dimensionLabel,
                dimensionName: attributes.dimensionName,
                path: attributes.path
              }"
              :anchor="attributes.dimensionLabel"
              :show-notification="false"
              :multi="false"
              :use-local-storage="false"
              :hide-clear-all-btn="true"
              :is-always-on-filter="true"
              :add-filter-text="attributes.dimensionLabel"
              :secondary-data="attributeDropdownOptions(attributes)"
              :apply="applyAttribute"
              :default-filters="defaultAttributesOptions"
              :class="{
                'category-filter disabled': !disableAttribute(
                  attributes.path,
                  categoryStatusList
                )
              }"
            />
          </div>
          <div slot="footer">
            <Settings
              v-if="!isMarketShareLite"
              :key="key"
              :types="settingsValue.viewBy"
              :placements="settingsValue.include3p"
              :keyword-type="settingsValue.displayShareIn"
              :selected-type="transformedComputedSelectedSettingsValue.viewBy"
              :selected-placement="
                transformedComputedSelectedSettingsValue.include3p
              "
              :selected-keyword-type="
                transformedComputedSelectedSettingsValue.displayShareIn
              "
              :customer-level-filters="customerLevelFilters"
              :widget-name="'settings'"
              @apply="handleApply"
              @cancel="handleCancel"
            />
          </div>
        </sticky-filter-panel>

        <section
          v-show="pageFetched"
          class="widgetWrapper u-envelop-width"
        >
          <versionToggle
            v-if="isMsproEnabled && !isMarketShareLite"
            class="u-spacing-mv-m"
            :selected-tab="selectedTab"
          />
          <section
            v-if="listApiResolved"
            class="u-spacing-mh-m u-spacing-mv-m u-display-flex slide-card"
          >
            <div
              class="u-display-flex"
              style="width: 100%"
            >
              <div class="u-width-40 u-spacing-mr-m">
                <market-share-overview-widget
                  :global-settings="getGlobalSettings"
                  :page-id="selectedView.pageId"
                  :global-view-id="selectedView.globalViewId"
                  :hide-metric="isMarketShareLite"
                  :hide-download="isMarketShareLite"
                />
              </div>
              <benchmarkBrands
                ref="benchmarkBrands"
                :v2="v2"
                class="widget-2 widget-2-width"
                :data-service="widget2DataService"
                :namespace="namespace"
                :widget-name="widget2Name"
                :initial-state="getInitialWidgetStates"
                :unsaved-state="getUnsavedWidgetStates"
                :page="page"
                :types="getPageFiltersMetadata.sovTypes"
                :placements="getPageFiltersMetadata.sovPlacements"
                :keyword-type="getPageFiltersMetadata.sovKeywordType"
                :page-wise-min-max-key="pageWiseMinMaxKey"
                :show-star="false"
                :footer-star="false"
                :show-roll-up="false"
                :show-roll-up-info="true"
                :global-settings="getGlobalSettings"
                :show-custom-tooltip="true"
                @clientCompData="() => {}"
                @brandsData="() => {}"
                @pushToUnsavedState="handlePushUnsavedState"
                @pushToInitialState="handlePushToInitialState"
              />
            </div>
          </section>
          <table-widget
            v-if="tableDataServiceLoaded"
            ref="table-widget"
            class="widget-3"
            :page="page"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :global-settings="getGlobalSettings"
            :data-service="tableDataService"
            :min-max-date="minMaxDate"
            :action-icons="shareSummaryWidgetActionIcons"
            :share-summary-widget="shareSummaryWidget"
            :category-page-widget="categoryPageWidget"
            :enable-tooltip="!isMarketShareLite"
          />
        </section>
      </div>

      <section>
        <section
          v-if="!pageFetched"
          class="view-port u-display-flex u-flex-direction-column"
        >
          <loader
            class="u-height-100 u-bg-color-grey-white"
            :loading="true"
            :color="'#007cf6'"
          />
        </section>
        <section
          v-else-if="pageFetchedError"
          class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
        >
          <div class="u-display-flex noskuImage_container">
            <img
              alt="error"
              src="/images/no_sku_found.png"
              class="u-width-100"
            />
          </div>
          <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
            <p class="u-color-grey-base u-spacing-mr-s">
              Something went wrong!
            </p>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import chart from '@/components/basic/chart';
import HttpService from '@/utils/services/http-service';
import categoryPageV2 from '@/components/pages/insights/amazon/market_share_pro/pages/category/index.vue';
import cardWidget from '@/components/widgets/cardWidget';
import loader from '@/components/basic/loader';
import widgetMixin from '@/components/widgetsMixin.js';
// import benchmarkBrands from './templates/benchmark-brands.vue';
import marketShareOverviewWidget from '@/components/pages/insights/amazon/market-share/templates/market-share-overview-widget.vue';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import Settings from '@/components/pages/insights/amazon/market-share/molecules/settings-popup.vue';
import { cloneDeep, isString } from 'lodash';
import filter from '@/components/widgets/filter/filter.vue';
import Vue from 'vue';
import {
  HIERARCHY_RAW_DELIMITER,
  MY_BRANDS,
  OVERALL_BUSINESS
} from '@/components/pages/insights/amazon/share-of-voice/templates/constants.js';
import {
  filterFromArray,
  findInArray
} from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils';
import { compareObjects } from './../share-of-voice/templates/views-tracking';
import {
  msProConstants,
  transformNestedSettingsInUI,
  transformNestedSettingsInDropDown,
  transformNestedSettingsValueForGlobalSettings,
  getAdditionalDateRanges,
  getObjectKey,
  getHighestSelectableRange,
  getFormattedSubCategory,
  getDimensionValueList,
  computeFinalWhereClause,
  getFormattedCustomerLevelFilters,
  getTransformedSubcategory,
  setGlobalSettingsForMS,
  getGlobalSettingsForMS,
  configurationSetting,
  removeGlobalSettingsForMS,
  filterHierarchyPaths,
  getSavedCustomerFilters,
  constants,
  getAttributeList,
  onFilterApply,
  getAttributeDropdownOptions,
  getAttributesData,
  transform3pInNestedSettings,
  getSettingsValue,
  updatedCategoryLevelFilters,
  getAttributes,
  enableFilter,
  getCategoryDisableStatus,
  getDisableAttribute,
  asinLevelV2ConfigEnabled,
  get3pOptions,
  DefaultDisplayByOptions,
  checkForDisplayShareInOptions,
  getGlobalToLocalInclude3p,
  isMarketShareLite,
  categoryConstants,
  getMetric,
  getShareSummaryWidget,
  NodeIdMap,
  getCategoryPageWidget,
  getActionIcons,
  updatePayloadWithNodeId,
  localStorageFallback,
  isMsproEnabled,
  msVersion
} from '@/components/pages/insights/amazon/market-share/utils';
import {
  replaceOrderAndValue,
  getAllPathTillLevel,
  filterStringStart,
  getAllKeyPaths,
  objectRootLength,
  flattenArrayOfArrays,
  getSegmentedBrandsData,
  splitClientCompOnAnchor,
  appendAnchorBrandCondition,
  getAnchorFilterPayload,
  disableInHierarchy
} from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
import DataService from '@/components/pages/insights/amazon/share-of-voice/service/data';
import benchmarkBrands from '@/components/pages/insights/amazon/share-of-voice/templates/benchmarkBrands';
import TableWidgetService from '@/components/pages/insights/amazon/market-share/service/table-widget-service.js';
import TableWidget from '@/components/pages/insights/amazon/market-share/templates/tableWidget.vue';
import SubCategoryFilter from '@/components/pages/insights/amazon/market-share/molecules/hierarchy.vue';
import { ThreePSelection } from '@/components/pages/insights/amazon/market-share/types/filters.ts';
import transformer from '@/utils/services/data-transformer.js';
import versionToggle from '@/components/pages/insights/amazon/market_share_pro/molecules/versionToggle.vue';

export default {
  components: {
    chart,
    loader,
    benchmarkBrands,
    marketShareOverviewWidget,
    stickyFilterPanel,
    Settings,
    cardWidget,
    TableWidget,
    SubCategoryFilter,
    rbFilter: filter,
    categoryPageV2,
    versionToggle
  },
  mixins: [widgetMixin],
  data() {
    let namespace, page, filterLocalStorageKey, diffDate, pageName, execPage;
    if (isMarketShareLite()) {
      ({
        mslNamespace: namespace,
        mslPage: page,
        mslFilterLocalStorageKey: filterLocalStorageKey,
        mslPageName: pageName,
        mslDiffDate: diffDate,
        mslExecPage: execPage
      } = categoryConstants);
    } else {
      ({
        namespace,
        page,
        filterLocalStorageKey,
        pageName,
        diffDate,
        execPage
      } = categoryConstants);
    }
    return {
      isMsproEnabled: isMsproEnabled(),
      selectedTab: msProConstants.oldVersion,
      enableV2: false,
      categoryNodeMap: {},
      shareSummaryWidgetActionIcons: getActionIcons(
        constants.shareSummaryDownloadTippyTitle
      ),
      isMarketShareLite: isMarketShareLite(),
      shareSummaryWidget: getShareSummaryWidget('categoryPage'),
      categoryPageWidget: getCategoryPageWidget(),
      pageName,
      diffDate,
      dateMode: constants.dateMode,
      execPage,
      namespace,
      page,
      filterLocalStorageKey,
      selections: [],
      listApiResolved: false,
      currentCategoryTracker: '',
      selectedSubCategory: [],
      selectionMode: 'multi-week',
      viewList: [],
      selectedView: {},
      pageFetchedError: '',
      secondaryFilterData: {
        category_hierarchy: {
          multi: false,
          v1: true,
          key: 'category_hierarchy',
          label: 'Category',
          values: [
            {
              title: 'beverages',
              enable: true
            },
            {
              title: 'baking',
              enable: true
            },
            {
              title: 'baby',
              enable: true
            },
            {
              title: 'premium water',
              enable: true
            },
            {
              title: 'prepared food',
              enable: true
            }
          ]
        }
      },
      additionalRanges: {
        add: ['lastWeek', 'last4Week', 'last13Week'],
        overrideRange: ['lastWeek', 'last4Week', 'last13Week'],
        order: {
          lastWeek: 3,
          last4Week: 4,
          last13Week: 5
        }
      },
      sovDataGroups: {
        sov_top_2: 1,
        sov_top_3: 1,
        sov_top_5: 1,
        sov_top_10: 1,
        sov_total_count: 1
      },
      previousAnchorBrand: {},
      driverCreated: false,
      sovByShelvesCreated: false,
      externalFilterSelectionListener: 'set-external-selection',
      snapshotWidgetColors: {},
      snapshotFilterPretext: null,
      snapshotRightFooter: {},
      widget3NavigateToKeywords: false,
      widget5NavigateToKeywords: false,
      snapshotWidgetWrapperClass: 'snapshot-widget',
      externalKeywordSelected: null,
      externalWhereClause: [],
      isOverlayVisible: false,
      filterMounted: false,
      standardHierarchyPromise: null,
      customHierarchyPromise: null,
      renderKey: 0,
      pageFetched: false,
      enableOpenFilter: true,
      filterInstanceCreated: false,
      stickyFilterKey: 0,
      filterKey: 0,
      recomputeGlovbalColors: false,
      isUpdateViewLoading: false,
      isSaveViewLoading: false,
      defaultDateRange: 'last30Days',
      filterInstance: null,
      hideViewBtn: true,
      hideUpdateView: true,
      widget1Data: null,
      widget2Data: null,
      widget3Data: null,
      colorPallete: [
        { hex: '#007cf6' },
        { hex: '#caccce' },
        { hex: '#3ea95e' },
        { hex: '#ffd500' },
        { hex: '#23b5d3' },
        { hex: '#97cc04' },
        { hex: '#f7981c' },
        { hex: '#ff6072' },
        { hex: '#bd10e0' },
        { hex: '#d7263d' },
        { hex: '#4f14e3' },
        { hex: '#73ff61' },
        { hex: '#eefa0a' },
        { hex: '#61ffed' },
        { hex: '#c2ff17' },
        { hex: '#f73edf' },
        { hex: '#8a0cff' },
        { hex: '#ff0000' },
        { hex: '#9e9cff' },
        { hex: '#a7dfb8' },
        { hex: '#fffda4' },
        { hex: '#b6f1e9' },
        { hex: '#c4fff7' },
        { hex: '#02e0a9' },
        { hex: '#ff74ab' },
        { hex: '#bc73ff' },
        { hex: '#83b7eb' },
        { hex: '#008b8b' },
        { hex: '#05d3fc' },
        { hex: '#c4a837' },
        { hex: '#6b5941' },
        { hex: '#a81890' },
        { hex: '#8c7382' },
        { hex: '#7c1f1a' }
      ],
      widget1Name: 'allDigitalShelves',
      widget3Name: 'sovByShelves',
      widget4Name: 'performanceOverview',
      widget5Name: 'drivers',
      widget6Name: 'sovSnapshot',
      pageId: null,
      widget1DataService: {},
      widget2DataService: {},
      widget3DataService: {},
      widget4DataService: {},
      widget5DataService: {},
      tableDataService: undefined,
      sovImagesDataService: {},
      snapShotSharesService: {},
      snapshotDropdown: {},
      widget2BrandsData: {},
      showFilter: false,
      filterEmit: 'marketshare-category-filter-apply',
      minMaxDate: {},
      widget3SortConfig: {
        load: true,
        error: false,
        noData: false,
        stepConfig: []
      },
      widget3SortConfigV2: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      widget3FilterConfigV2: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      widget3FilterConfig: {
        load: true,
        error: false,
        noData: false,
        stepConfig: []
      },
      widget3SortRadioSelectSelectedValue: [],
      metricsBaseConfig: {
        component: 'ruleBuidlerMetrics',
        stepTitle: 'Metrics',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {
          ruleBuilderChipConfig: {
            radio: {
              prefix: {},
              suffix: {}
            }
          }
        }
      },
      textInputBaseConfig: {
        component: 'ruleBuilderTextInput',
        stepTitle: 'Share of Voice of',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {}
      },
      expressionBaseConfig: {
        component: 'ruleBuilderExpression',
        stepTitle: 'Expression',
        key: 'ruleBuilderExpression',
        keepAlive: false,
        props: {
          ruleBuilderChipConfig: {
            queryBuilder: {
              prefix: {},
              suffix: {}
            },
            radio: {
              prefix: {},
              suffix: {}
            }
          },
          radioSelects: [
            { label: 'Absolute', value: 'ABSOLUTE' },
            { label: 'Change', value: 'CHANGE' }
          ],
          selectedRadio: { label: 'Absolute', value: 'ABSOLUTE' },
          absoluteRange: {
            percentage: {
              min: 0,
              max: 100
            },
            numeric: {
              min: 0
            }
          },
          selectedText: '',
          hasQueryBuilder: true,
          metrics: {
            config: {
              dimensionLabel: 'Sales',
              dimensionName: 'paid_sales',
              dimensionColumn: 'paid_sales',
              dimensionType: 'EXPRESSION',
              addtionalMetadata: {
                expressionField: {
                  expressionType: 'NUMERIC',
                  operators: [
                    'EQUAL_TO',
                    'GREATER_THAN',
                    'GREATER_THAN_OR_EQUAL_TO',
                    'LESS_THAN',
                    'LESS_THAN_OR_EQUAL_TO',
                    'NOT_EQUAL_TO',
                    'IS_NULL',
                    'IS_NOT_NULL'
                  ]
                },
                type: 'NUMERIC',
                unit: 'PERCENTAGE'
              }
            }
          }
        }
      },
      radioBaseConfig: {
        component: 'ruleBuilderRadioSelect',
        stepTitle: 'Share of Voice of',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {
          ruleBuilderChipConfig: {
            radio: {
              prefix: {},
              suffix: {}
            }
          }
        }
      },
      pageWiseMinMaxKey: 'marketshare-category',
      computedSelectedSettingsValue: {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedSettingsValue: {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedCustomerFilters: {},
      globalWhereClauseValue: {},
      getGlobalSettings: {},
      dynamicAttributes: [],
      selectedAttributes: [],
      defaultAttributesOptions: {
        values: {}
      },
      categoryStatusList: {},
      cssClass: '',
      key: 0
    };
  },
  computed: {
    widget2Name() {
      return (
        (isMarketShareLite()
          ? categoryConstants.mslBenchmarkWidget
          : categoryConstants.benchmarkWidget) + (this.v2 ? '_v2' : '')
      );
    },
    tableDataServiceLoaded() {
      return this.tableDataService !== undefined;
    },
    dimensionValueList() {
      return getDimensionValueList(this.selectedSubCategory);
    },
    categoryName() {
      if (this.currentCategory) {
        return this.currentCategory.length > 0
          ? this.currentCategory[0].dimensionValue
          : '';
      }
    },
    enableHeirarchy() {
      let selectedCategory = cloneDeep(
        this.currentCategory[0]?.dimensionValue || ''
      );
      if (selectedCategory) {
        selectedCategory = getObjectKey(
          this.filterData?.category,
          selectedCategory
        );
      }
      const obj = this.filterData?.category?.[selectedCategory] || {};
      const length = Object.keys(obj?.children || {}).length;
      if (length === 0) {
        return false;
      }
      return true;
      // return this.$store.getters.getHeirarchyFilterData;
    },
    currentCategory() {
      const globalWhereClause = cloneDeep(this.globalWhereClauseValue) || {};
      let dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      dimensionNameValueList = dimensionNameValueList.filter((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.replace('~', '');
          return element;
        } else {
          return false;
        }
      });
      return dimensionNameValueList;
    },
    // getGlobalSettings() {
    //   debugger;
    //   console.log(this.selectedSettingsValue, this.globalWhereClause());
    //   return {
    //     where: this.globalWhereClauseValue,
    //     settingValue: this.selectedSettingsValue
    //   };
    // },
    filterComputedData() {
      return [];
    },
    customerLevelFilters() {
      return getFormattedCustomerLevelFilters(
        this.filterData,
        this.selectedCustomerFilters
      );
    },
    transformedComputedSelectedSettingsValue() {
      const nestedSettings = cloneDeep(this.computedSelectedSettingsValue);
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInUI(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    settingsValue() {
      const nestedSettings = getSettingsValue('brand');
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInDropDown(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    secondaryFilterComputedData() {
      //   return {
      //   category: {
      //   multi: false,
      //   v1: true,
      //   key: 'category',
      //   label: 'Category',
      //   values: [{
      //     title: 'Top 50%',
      //     enable: true
      //   }, {
      //     title: 'Top 25%',
      //     enable: true
      //   }, {
      //     title: 'Top 10%',
      //     enable: true
      //   }]
      // }
      //   };

      const category_hierarchy = {
        multi: false,
        v1: true,
        key: 'category_hierarchy',
        label: 'Category',
        values: []
      };
      Object.keys(this.filterData.category || {}).forEach((element) => {
        category_hierarchy.values.push({ title: element, enable: true });
      });
      return { category_hierarchy };
    },
    // hierarchyDataComputed() {
    //   const temp1 = this.filterData.category;
    //   Object.keys(temp1).forEach(level1Key => {
    //     const level1Obj = temp1[level1Key];
    //     level1Obj.identifier = level1Key;
    //     temp1[level1Key] = level1Obj;

    //     console.log('level1', level1Obj);
    //     if(level1Obj?.children) {
    //       Object.keys(level1Obj?.children).forEach(level2Key => {
    //         const level2Obj = level1Obj?.children[level2Key];
    //         level2Obj.identifier = level1Key + '----' + level2Key;

    //         if(level2Obj?.children) {
    //           Object.keys(level2Obj?.children).forEach(level3Key => {
    //             const level3Obj = level2Obj?.children[level3Key];
    //             level3Obj.identifier = level1Key + '----' + level2Key + '----' + level3Key;

    //             if(level3Obj?.children) {
    //               Object.keys(level3Obj?.children).forEach(level4Key => {
    //                 const level4Obj = level2Obj?.children[level4Key];
    //                 level4Obj.identifier = level1Key + '----' + level2Key + '----' + level3Key + '----'+ level4Key;
    //                 if(level4Obj?.children) {
    //                   Object.keys(level4Obj?.children).forEach(level5Key => {
    //                     const level5Obj = level2Obj?.children[level5Key];
    //                     level5Obj.identifier = level1Key + '----' + level2Key + '----' + level3Key + '----'+ level4Key + '----' + level5Key;
    //                   });
    //                 }
    //               });
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    //   console.log(temp1, 'temp1');
    //   return temp1;
    // },
    externalClientBrand() {
      if (this.isMyBrandsAnchored) {
        return null;
      } else {
        const { anchoredBrand } = this.getUnsavedSettings;
        return anchoredBrand?.title;
      }
    },
    isMyBrandsAnchored() {
      const { anchoredBrand } = this.getUnsavedSettings;
      if (!anchoredBrand || anchoredBrand?.title === MY_BRANDS) {
        return true;
      }
      return false;
    },
    getAnchorBrandsOptions() {
      return [{ title: MY_BRANDS, client_flag: 'client' }].concat(
        this.getClientBrandsData.rows
      );
    },
    getClientBrandsData() {
      return this.$store.getters[this.namespace + 'getClientBrandsData'];
    },
    getAnchoredBrand() {
      // const { anchoredBrand } = this.getUnsavedSettings;
      // const foundObject = findInArray(
      //   anchoredBrand?.title || MY_BRANDS,
      //   this.getClientBrandsData.rows,
      //   'title'
      // );
      // return (
      //   foundObject || {
      //     title: anchoredBrand?.title || MY_BRANDS
      //   }
      // );
    },
    getRetailerBrandName() {
      return this.$store.getters.getRetailerBrandName;
    },
    isMarketingClient() {
      return this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    },
    widget6WhereClause() {
      const globalWhereClause = this.globalWhereClause();
      globalWhereClause.dimensionNameValueList.push(
        ...this.externalWhereClause
      );
      return globalWhereClause;
    },
    getCustomHierarchy() {
      // return this.$store.getters[this.namespace + 'getCustomHierarchy'];
    },
    getStandardHierarchy() {
      // return this.$store.getters[this.namespace + 'getStandardHierarchy'];
    },
    getBrandsList() {
      return this.$store.getters[this.namespace + 'getBrandsData'];
    },
    getInitialWidgetStates() {
      return this.$store.getters[this.namespace + 'getInitialWidgetStates'];
    },
    getUnsavedWidgetStates() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'];
    },
    filterState() {
      return {
        getter: this.namespace + 'getGlobalFilters',
        setter: this.namespace + 'setGlobalFilters'
      };
    },
    getUnsavedSettings() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
        ?.settings;
    },
    getSelectedSovType() {
      return filterFromArray(
        this.getUnsavedSettings?.selectedType || [],
        this.getPageFiltersMetadata?.sovTypes || []
      );
    },
    getSelectedSovPlacement() {
      return findInArray(
        this.getUnsavedSettings?.selectedPlacement,
        this.getPageFiltersMetadata?.sovPlacements || []
      );
    },
    getSettings() {
      return {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: {
          metricsList: 'categoryShareSummary_sales_estimates_cat',
          label: 'Dollars'
        },
        include3p: [get3pOptions()[0]]
      };
    },
    getPageFilters() {
      return this.$store.getters[this.namespace + 'getPageList'];
    },
    getSelectedView() {},
    getPageList() {
      return this.$store.getters[this.namespace + 'getPageList'];
    },
    getPageFiltersMetadata() {
      return this.getPageList?.rows?.metadata?.pageFilters || {};
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    },
    globalToLocal() {
      return {
        viewBy: {
          label: {
            Category: 'Brands'
          },
          entityType: {
            category: 'brand',
            asin: 'brand'
          }
        },
        displayShareIn: {
          metricsList: getMetric('category')
        },
        include3p: getGlobalToLocalInclude3p()
      };
    }
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (msVersion() && !this.isMarketShareLite) {
      this.enableV2 = true;
    } else {
      transform3pInNestedSettings();
      this.initializePage();
    }
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  mounted() {
    console.log('MS local storage > category page >', getGlobalSettingsForMS());
  },
  methods: {
    /**
     * @description to reset any active settings changes on clicking on cancel button
     */
    handleCancel() {
      this.key++;
    },
    /**
     * @description to disable the category level filters
     * @param {array} selectionList - hierarichal path of the subcategory on which the category level filter is applicable
     * @param {object} categoryStatusList - all possible selection paths of category/subcategory with its enable status
     * @return {boolean}
     */
    disableAttribute(selectionList, categoryStatusList) {
      return getDisableAttribute(selectionList, categoryStatusList);
    },
    /**
     * @description called when any options is applied in dynamic attributes
     * @callback onFilterApply
     * @param {object} selections - have selection data from dropdown
     * stores dynmaic attributes selections in LS
     * refresh the widgets with updated where clause
     */
    applyAttribute(selections) {
      onFilterApply(selections);
      this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      this.getDisableData(this.dynamicAttributes);
      this.refreshWidgets();
    },
    /**
     * @description called to list down the options of each attributes
     * @param {object} attributeData - have specific attribute data
     * @returns {object} - contains object with list of dropdopwn options for specific attributes
     * does the data transformation to display list of options dynamically
     */
    attributeDropdownOptions(attributeData) {
      return getAttributeDropdownOptions(attributeData);
    },
    navigateTo() {
      this.$router.push({ name: 'marketshareSkus' });
    },
    goToExecutivePage() {
      this.$router.push({ name: this.execPage });
    },
    onSubcategoryShow() {
      // Hiding Category filter dropdown when sub category filter is opened
      const categoryFilter = this.filterInstance?.$refs?.filter?.[0];
      if (categoryFilter) {
        categoryFilter.isActive = false;
      }
    },
    async applySubCategory(data) {
      // when sub category is changed we catch the event here
      if (Object.keys(data).length !== 0) {
        setGlobalSettingsForMS(constants.SUBCATEGORY, data);
        setGlobalSettingsForMS(
          constants.FILTER_SELECTION,
          this.filterData?.category
        );
      } else {
        setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
        setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
      }
      let globalFilter = getGlobalSettingsForMS();
      this.selectedSubCategory = getFormattedSubCategory(
        data,
        this.filterData?.category
      );
      const categorySelectionList = filterHierarchyPaths(
        this.filterData?.category,
        {}
      );
      this.selections = getTransformedSubcategory();
      this.dynamicAttributes = getAttributes(
        data,
        categorySelectionList,
        this.dynamicAttributes
      );
      if (globalFilter[constants.ATTRIBUTES]) {
        this.defaultAttributesOptions.values =
          globalFilter[constants.ATTRIBUTES];
        this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      }
      this.getDisableData(this.dynamicAttributes);
      await this.checkDatesForCategory();
      this.refreshWidgets();
    },
    showErrorPageLevel(err) {
      this.pageFetched = false;
      this.pageFetchedError = false;
      console.error('err', err);
      this.openSnackbar();
    },
    saveStandardDigitalShelfFilters(selections) {
      // this.selectedSubCategory = cloneDeep(selections);
      // this.selections = selections;
    },
    handleKeywordsRequest(request) {
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      if (appendAnchorBrandCondition(anchoredBrand)) {
        request.where.dimensionNameValueList.push(
          getAnchorFilterPayload(anchoredBrand.title)
        );
      }
    },
    handleSovRequest(request) {
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      if (appendAnchorBrandCondition(anchoredBrand)) {
        request.operations.totalClientBrandsForDigitalShelf = 0;
        request.operations.anchoredBrand = anchoredBrand.title;
        request.where.dimensionNameValueList.push(
          getAnchorFilterPayload(anchoredBrand.title)
        );
      }
    },
    getFormattedBrandsList() {
      const { client, comp } = this.getClientCompData();
      return getSegmentedBrandsData(client, comp);
    },
    getClientCompData() {
      const { rows: compApiData } =
        this.$store.getters[this.namespace + 'getBrandsData'];
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      const { rows: clientApi } =
        this.$store.getters[this.namespace + 'getClientBrandsData'];
      return splitClientCompOnAnchor(compApiData, clientApi, anchoredBrand);
    },
    handleSovByShelvesCreated(context) {
      // on component create, navigate to keywords for sovByShelves
      const { label } = this.$store.getters[this.namespace + 'getSelectedView'];
      if (
        label === OVERALL_BUSINESS &&
        !this.sovByShelvesCreated &&
        !objectRootLength(this.getStandardHierarchy.rows)
      ) {
        context.setDefaultStateToKeywords();
      }
      this.sovByShelvesCreated = true;
    },
    handleDriversCreated(context) {
      // on component create, navigate to keywords for driver
      const { label } = this.$store.getters[this.namespace + 'getSelectedView'];
      if (
        label === OVERALL_BUSINESS &&
        !this.driverCreated &&
        !objectRootLength(this.getStandardHierarchy.rows)
      ) {
        context?.setDefaultStateToKeywords();
      }
      this.driverCreated = true;
    },
    handleRefreshColors(payload) {
      const clientKeys = Object.keys(payload);
      const colorMap = clientKeys.reduce((a, client) => {
        if (this.globalColorMaps?.brandToColor[client]) {
          a[client] = this.globalColorMaps?.brandToColor[client];
        }
        return a;
      }, {});
      colorMap[MY_BRANDS] = this.globalColorMaps?.brandToColor[MY_BRANDS];
      colorMap.Others = 'grey-light';
      this.snapshotWidgetColors = colorMap;
    },
    handleClose() {
      this.isOverlayVisible = false;
    },
    handleSetAccessedFrom(payload) {
      if (payload.hierarchy === 'shelf') {
        this.snapshotFilterPretext = `Keywords in shelf - ${payload.text}:`;
        this.snapshotRightFooter = {
          prefix: 'Accessed from shelf:',
          infix: payload.text
        };
      } else if (payload.hierarchy === 'search_term') {
        this.snapshotFilterPretext = 'Keywords:';
        this.snapshotRightFooter = {
          prefix: 'Accessed from keyword:',
          infix: payload.text
        };
      }
    },
    handleSovByShelvesKeywordsDriver(payload) {
      this.handleKeywordsDriver(payload);
      this.$refs?.sovByShelves?.$el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    handleDriverKeywordsDriver(payload) {
      this.handleKeywordsDriver(payload);
      this.$refs?.drivers?.$el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    handleKeywordsDriver(payload) {
      this.widget3NavigateToKeywords = true;
      this.widget5NavigateToKeywords = true;
      this.handleAddToFilter(payload);
    },
    updateStandardShelfSelections(values, anchor) {
      // unable to change thr selections when hiearcrhy filters exists previously
      this.enableOpenFilter = false;
      eventBus.$emit(this.externalFilterSelectionListener, { values, anchor });
      this.$nextTick(() => {
        this.enableOpenFilter = true;
      });
    },
    formulateFilterStrings(filterAnchoredValues) {
      const startsWithStringsFiltered = filterStringStart(
        filterAnchoredValues,
        HIERARCHY_RAW_DELIMITER
      );
      const allStringPaths = getAllKeyPaths(startsWithStringsFiltered);
      const pathStrings = allStringPaths.map((item) =>
        item.join(HIERARCHY_RAW_DELIMITER)
      );
      return pathStrings;
    },
    getFilterStringsArray(title, type, level) {
      if (type === 'standardShelf') {
        const standardHierarchy =
          this.$store.getters[this.namespace + 'getStandardHierarchy'].rows;
        const paths = getAllPathTillLevel(standardHierarchy, title, level, [
          'children'
        ]);
        if (paths.length) {
          return [paths[0].join(HIERARCHY_RAW_DELIMITER)];
        } else {
          return [];
        }
      } else if (type === 'customShelf') {
        return [title];
      }
    },
    handleAddToFilter(payload) {
      const { title, type, level } = payload;
      const currentFilters = this.filterInstance.getSelectedFilters();
      const filterFormatted = this.getFilterStringsArray(title, type, level);
      const newFilters = replaceOrderAndValue(
        currentFilters,
        type,
        filterFormatted
      );
      newFilters.values[type] = this.formulateFilterStrings(
        newFilters.values[type]
      );
      this.updateStandardShelfSelections(newFilters.values[type], type);
      this.enableOpenFilter = false;
      this.$nextTick(() => {
        this.filterInstance.appendToFilters(newFilters);
        this.enableOpenFilter = true;
      });
    },
    handleKeywordSelection(payload) {
      this.externalWhereClause = [];
      this.externalKeywordSelected = payload;
      this.showOverlay();
    },
    handleSetWhereClause(payload) {
      this.externalWhereClause = payload;
      this.externalKeywordSelected = null;
      this.showOverlay();
    },
    showOverlay() {
      this.$nextTick(() => {
        this.isOverlayVisible = true;
        // need the component watcher to be triggered before triggering request
        this.$refs.sovSnapshot.getData();
      });
    },
    showOverlayKeywordSelected(payload) {
      this.showOverlay();
    },
    async checkDatesForCategory(category = []) {
      this.pageFetched = false;
      let dateRange = null;
      try {
        dateRange = await this.updatedDateRange(category);
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      try {
        await this.updateFilterSelection({
          from: dateRange.data.response.minDate,
          to: dateRange.data.response.maxDate
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      this.pageFetched = true;
    },
    handleFilterMount(instance) {
      this.filterMounted = true;
      // this.fetchBrands('brandsAPI', 'fetchBrands');
      // this.fetchBrands('subBrandsApi', 'fetchClientSubBrands');
      eventBus.$on(this.filterEmit, async (data) => {
        // when category and date range is changed we catch the event here
        if (!data?.from) {
          setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
          setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
          setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
        }

        let storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        for (const key in storage) {
          setGlobalSettingsForMS(key, storage[key]);
        }
        if (
          data?.category_hierarchy !== undefined &&
          data?.category_hierarchy?.[0] !== this.currentCategoryTracker
        ) {
          // Only if category has changed then make a request for fetching differential dates for category
          this.currentCategoryTracker = data.category_hierarchy[0];
          let category = {};
          category[this.currentCategoryTracker] = true;
          const categorySelectionList = filterHierarchyPaths(
            this.filterData?.category,
            {}
          );
          this.dynamicAttributes = getAttributeList(
            category,
            categorySelectionList
          );
          this.selectedAttributes = getAttributesData(this.dynamicAttributes);
          this.getDisableData(this.dynamicAttributes);
          if (!data.name) {
            this.$store.dispatch('setInitialSelections', {});
            this.selectedSubCategory = [];
          }
          await this.checkDatesForCategory();
        }
        this.setGlobalFiltersInUnsavedState();
        // this.setCustomWidgetStates();
        this.refreshWidgets();
      });
    },
    handleFilterBeforeDestroy() {
      // this.filterMounted = false;
      // eventBus.$off(this.filterEmit);
    },
    findSelectedView(params) {
      const viewList =
        this.$store.getters[this.namespace + 'getGlobalViews']?.rows || [];
      return this.getViewFromViewId(viewList, params);
    },
    getViewFromViewId(viewList, params) {
      return (
        viewList.filter((view) => {
          if (parseInt(params) === view.globalViewId) {
            return view;
          }
        })[0] || viewList[0]
      );
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.$router.push({
            name: this.pageName,
            params: { id: selectedView.globalViewId }
          });
          return;
        }

        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList(selectedView);
      } else {
        if (this.viewList && this.viewList.length > 0) {
          selectedView = this.viewList[0];
          this.$router.push({
            name: this.execPage,
            params: { id: selectedView.globalViewId }
          });
          const initialState = {
            filters: selectedView?.pageUserFilters,
            ...selectedView?.widgetUserSelectionMetadata
          };
          this.$store.dispatch(
            this.namespace + 'setSelectedView',
            selectedView
          );
          this.$store.dispatch(
            this.namespace + 'setInitialWidgetState',
            initialState
          );
          this.fetchPageList();
        }
      }
    },
    createBrandsRequet(brandsAPI) {
      // const unsavedSettings = getUnsavedSettings(this);
      const metric = this.getUnsavedWidgetStates.settings.selectedPlacement;
      const { service, request, endPoint } = cloneDeep(brandsAPI);
      const requestCopy = cloneDeep(request);
      requestCopy.metricsList = [metric];
      requestCopy.widget = this.widget2Name;
      requestCopy.page = this.page;
      requestCopy.operations.orderByList = [
        ...(requestCopy?.operations?.orderByList || []),
        {
          dimension: `${metric}_share_percentage`,
          direction: 'DESC'
        }
      ];
      requestCopy.where = this.globalWhereClause();
      return { service, request: requestCopy, endPoint };
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(
        this.$store.getters[this.namespace + 'getGlobalFilters']
      );
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: []
      };
      where.date.from = globalWhereClause?.date_range?.from;
      where.date.to = globalWhereClause?.date_range?.to;
      where.date.name = globalWhereClause?.date_range?.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause?.date_range?.compare_from;
      where.pvpDate.to = globalWhereClause?.date_range?.compare_to;
      where.pvpDate.compare_name = globalWhereClause?.date_range?.compare_name;
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      where.dimensionNameValueList.forEach((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.toLowerCase() + '~';
          element.operator = 'STARTSWITH';
        }
      });
      if (!this.v2) {
        if (
          this.computedSelectedSettingsValue?.include3p?.value ===
          ThreePSelection.EXCLUDE_3P
        ) {
          where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: false
          });
        } else if (
          this.computedSelectedSettingsValue?.include3p?.value ===
          ThreePSelection.EXCLUDE_3P_CLIENT
        ) {
          where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: 'comp'
          });
        }
      }
      return where;
    },
    getFormmatedSettings(settingsObject = {}, settingValues = {}) {
      const {
        selectedKeywordType: settingsKeywordType,
        selectedPlacement: settingsSelectedPlacement,
        selectedType: settingsSelectedType
      } = settingsObject;
      const { sovKeywordType, sovPlacements, sovTypes } = settingValues;
      const keywordType = filterFromArray(settingsKeywordType, sovKeywordType);
      const selectedPlacement = findInArray(
        settingsSelectedPlacement,
        sovPlacements
      );
      const selectedType = filterFromArray(settingsSelectedType, sovTypes);
      return { keywordType, selectedPlacement, selectedType };
    },
    setInitialRuleBuidlerState(config, metrics, hasExpressionInput) {
      const baseExpressionConfig = cloneDeep(this.expressionBaseConfig);
      const baseRadioSelectConfig = cloneDeep(this.radioBaseConfig);

      const baseExpressionConfigNumberic = cloneDeep(this.expressionBaseConfig);
      delete baseExpressionConfigNumberic.props.metrics.config.addtionalMetadata
        .unit;

      baseExpressionConfig.nextStep = null;
      baseExpressionConfig.props.hasQueryBuilder = hasExpressionInput;
      baseExpressionConfigNumberic.props.hasQueryBuilder = hasExpressionInput;
      baseRadioSelectConfig.nextStep = baseExpressionConfig;
      config.root = cloneDeep(this.metricsBaseConfig);
      metrics.forEach((item) => {
        if (item.dimensionName === 'SOV') {
          item.nextStep = baseRadioSelectConfig;
        } else {
          item.nextStep = baseExpressionConfigNumberic;
        }
      });
      config.root.props = {
        options: metrics
      };
      config.load = false;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    getDefaultDateRangeObject() {
      return {
        name: 'last30Days',
        compare: undefined,
        compare_name: undefined
      };
    },
    setGlobalFiltersInUnsavedState() {
      const filters = this.getSelectedFiltersValues();
      const payload = { filters };
      this.handlePushUnsavedState(payload);
    },
    setWidgetsMetadataToInitialState() {
      this.$store.dispatch(this.namespace + 'resetWidgetsToInitialState');
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      setTimeout(() => {
        this.showFilter = true;
      });
    },
    handleResetView() {
      const initialState = cloneDeep(
        this.$store.getters[this.namespace + 'getInitialWidgetStates']
      );
      this.setWidgetsMetadataToInitialState();
      this.validateView();
      const payload = this.createLocalStoragePayload(initialState);
      this.setFiltersLocalStorage(this.filterLocalStorageKey, payload);
      this.reinitView();
    },
    createLocalStoragePayload(state) {
      if (state.filters?.selectedFilter) {
        const payload = transformer.transformFilterFromAPI(
          state.filters?.selectedFilter?.filter
        );
        const dateRange = state?.filters?.date_range || {};
        return this.createFilterState(payload, dateRange);
      }
    },
    createFilterState(payload, dateRange) {
      return {
        order: payload.order,
        values: {
          ...payload.values,
          date_range: dateRange
        }
      };
    },
    createDraftViewPayload() {
      // const { globalViewId, label } =
      //   this.$store.getters[this.namespace + 'getSelectedView'];
      // const pageId = this.pageId;
      // const page = this.page;
      // return {
      //   name: label,
      //   page,
      //   pageId,
      //   globalViewId
      // };
    },
    sendCurrentUnsavedToDraft() {
      const payload = this.createDraftViewPayload();
      return this.$store.dispatch(this.namespace + 'draftView', payload);
    },
    handlePushUnsavedState(payload) {
      this.$store.dispatch(this.namespace + 'setUnsavedWidgetState', payload);
      this.validateView();
    },
    handlePushToInitialState(payload) {
      this.$store.dispatch(this.namespace + 'setInitialWidgetState', payload);
      this.validateView();
    },
    conmpareHashes(getter1, getter2) {
      const initialState = this.$store.getters[this.namespace + getter1] || {};
      const unsavedState = this.$store.getters[this.namespace + getter2] || {};
      return compareObjects(initialState, unsavedState);
    },
    validateView(type) {
      const isEqualWidgetStates = this.conmpareHashes(
        'getInitialWidgetStates',
        'getUnsavedWidgetStates'
      );
      if (isEqualWidgetStates) {
        this.hideViewBtn = true;
      } else {
        this.hideViewBtn = false;
        const { currentUser } =
          this.$store.getters[this.namespace + 'getSelectedView'];
        if (!currentUser) {
          this.hideUpdateView = true;
        } else {
          this.hideUpdateView = false;
        }
      }
    },
    getSelectedFiltersValues() {
      const getFilterValues = this.filterInstance?.getSelectedFilters() || {};
      if (getFilterValues.values) {
        return {
          date_range: {
            ...getFilterValues?.values.date_range
          },
          selectedFilter: {
            filter: [...this.filterInstance.getFiltersFullMetadata()],
            order: [...getFilterValues.order]
          }
        };
      }
      return getFilterValues;
    },
    transformBrandsData(data) {
      return data.map((item) => ({
        brandKey: 'brand_by_client_flag',
        brand: item.name,
        percentage: item.result,
        clientType: item.clientType
      }));
    },
    setBrandsData(target, selectedValue, segmentedData) {
      target.props.selectedValue = cloneDeep(selectedValue);
      target.props.segmentedData = cloneDeep(segmentedData);
    },
    async getGlobalFilters(cube, urlFactoryId, page, where) {
      this.resetFilters();
      await this.fetchFilters(
        cube,
        urlFactoryId,
        page,
        where,
        undefined,
        undefined,
        true
      );
    },
    handleApply(payload) {
      // when settings is changed we catch the event here
      // Payload has dynamic currency value, that is transformed so that local storage has static currency value i.e Dollars
      const nestedSettings =
        transformNestedSettingsValueForGlobalSettings(payload);
      this.selectedCustomerFilters = nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(
        'customerLevelFilters',
        nestedSettings.customerLevelFilters
      );
      delete nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
      this.$nextTick(() => {
        console.log('refreshWidgets', payload);
        // updates the settings component selections
        this.computedSelectedSettingsValue = payload;
        this.refreshWidgets();
      });
    },
    setCustomWidgetStates() {
      this.$refs?.benchmarkBrands?.selectRollup();
      this.$refs?.performanceOverview?.selectRollup();
      if (this.widget3NavigateToKeywords) {
        this.widget3NavigateToKeywords = false;
        this.$refs?.sovByShelves?.setShelfToKeywords();
      } else {
        this.$refs?.sovByShelves?.setShelfLevel();
      }
      if (this.widget5NavigateToKeywords) {
        this.$refs?.drivers?.setShelfToKeywords();
        this.widget5NavigateToKeywords = false;
      } else {
        this.$refs?.drivers?.setShelfLevel();
      }
      // set custom state for title dropdown based on filters applied, enable/dsiable contents
      disableInHierarchy(this.$refs?.drivers);
      disableInHierarchy(this.$refs?.sovByShelves);
    },
    async updatedDateRange(categorySelection = []) {
      if (categorySelection.length === 0) {
        if (this.dimensionValueList?.length !== 0) {
          categorySelection = this.dimensionValueList;
        } else if (
          this.globalWhereClause()?.dimensionNameValueList?.length !== 0
        ) {
          categorySelection = this.globalWhereClause()?.dimensionNameValueList;
        }
      }
      if (categorySelection.length === 0) {
        return;
      }

      let body = [];
      categorySelection.forEach((dimension) => {
        if (isMarketShareLite()) {
          body.push(this.categoryNodeMap[dimension.dimensionValue]);
        } else {
          body.push(dimension.dimensionValue);
        }
      });
      try {
        return await HttpService.post(this.diffDate, body, {
          params: {
            v2: this.v2
          }
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    async updateFilters() {
      return new Promise((resolve) => {
        this.reinitView();
        this.$nextTick(() => {});
        const categoryHierarchySelections = this.$store.getters.getSelections;

        // Hack to show the filters
        setTimeout(() => {
          document.querySelector('.filter-list__item').click();
        }, 0);
        setTimeout(() => {
          document.querySelector('body').click();
          document.querySelector('.rb-filter .icon-cross').style.display =
            'none';
          this.$store.dispatch(
            'setInitialSelections',
            categoryHierarchySelections
          );
        }, 0);

        // Once the filter component is reinitialized, resolve the method
        const interval = setInterval(() => {
          if (this.filterInstanceCreated) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },
    async updateFilterSelection(dateRange, updateFilters = true) {
      const storage = JSON.parse(
        localStorage.getItem(this.filterLocalStorageKey)
      );

      const currentSelectedStartDate = moment(storage.date_range.from);
      const currentSelectedEndDate = moment(storage.date_range.to);
      const backendStartDate = moment(dateRange.from);
      const backendEndDate = moment(dateRange.to);

      let updatedStartDate = currentSelectedStartDate;
      let updatedEndDate = currentSelectedEndDate;
      let updateFilter = false;
      this.minMaxDate.rawMinDate = backendStartDate;
      this.minMaxDate.rawMaxDate = backendEndDate;
      this.minMaxDate.minDate = backendStartDate.format('MM/DD/YYYY');
      this.minMaxDate.maxDate = backendEndDate.format('MM/DD/YYYY');
      this.additionalRanges = getAdditionalDateRanges(
        backendStartDate,
        backendEndDate
      );
      const availableRanges = this.additionalRanges.add.filter(
        (range) => this.additionalRanges.disabledRange.indexOf(range) === -1
      );
      const highestSelectableRange = getHighestSelectableRange(
        this.additionalRanges.order,
        availableRanges
      );

      let date_range = {};
      if (
        !currentSelectedStartDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (
        !currentSelectedEndDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (updateFilter) {
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify({
            ...storage,
            date_range
          })
        );
      }
      if (updateFilters) {
        await this.updateFilters();
      }
    },
    async refreshWidgets() {
      this.tableDataService.dateApiMaxDate = this.minMaxDate?.maxDate;
      this.globalWhereClauseValue = cloneDeep(this.globalWhereClause());
      const whereClause = computeFinalWhereClause(
        this.globalWhereClauseValue,
        this.dimensionValueList,
        this.selectedCustomerFilters,
        this.selectedAttributes
      );
      this.getGlobalSettings = {
        where: whereClause,
        settingValue: getGlobalSettingsForMS('nestedSettings')
      };
      if (isMarketShareLite()) {
        this.getGlobalSettings.where.dimensionNameValueList =
          updatePayloadWithNodeId(
            this.getGlobalSettings.where.dimensionNameValueList,
            this.categoryNodeMap
          );
      }
    },
    handleExportWidgets() {
      const month = moment().format('MM');
      const day = moment().format('DD');
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'PDF is being generated and will be downloaded shortly.',
        template: {
          header: true,
          elementSelectors: [{ selector: '.sticky-filter-panel' }]
        },
        elementSelector: '.slide-card',
        fileName: `Share Of Voice_${month}/${day}.pdf`
      };
      eventBus.$emit('triggerExport', config);
    },
    async handleUpdateView(payload) {
      try {
        const { mode, data, type } = payload;
        const requestPayload = this.createDraftViewPayload();
        if (type === 'name') {
          requestPayload.name = data.label;
          requestPayload.globalViewId = data.globalViewId;
        }
        if (mode === 'save') {
          const index = this.$store.getters[
            this.namespace + 'getGlobalViews'
          ].rows.findIndex((item) => item.label === data);
          if (index < 0) {
            delete requestPayload.globalViewId;
            requestPayload.name = data;
            this.isSaveViewLoading = true;
            const { data: responseData } = await this.$store.dispatch(
              this.namespace + 'saveViewAction',
              requestPayload
            );
            const { globalView } = responseData;
            this.$store.dispatch(
              this.namespace + 'appendToViewList',
              globalView
            );
            this.isSaveViewLoading = false;
            this.$router.push({
              name: this.pageName,
              params: { id: globalView?.globalViewId }
            });
            this.sendCurrentUnsavedToDraft();
            this.validateView();
          } else {
            this.openSnackbar('The selected view name already exists');
          }
        }
        if (mode === 'update') {
          this.isUpdateViewLoading = true;
          await this.$store.dispatch(
            this.namespace + 'saveViewAction',
            requestPayload
          );
          this.$store.dispatch(this.namespace + 'updateViewTitle', data);
          await this.sendCurrentUnsavedToDraft();
          this.isUpdateViewLoading = false;
          this.$store.dispatch(this.namespace + 'saveUnsavedState');
          this.validateView();
        }
        if (mode === 'delete') {
          await this.$store.dispatch(this.namespace + 'deleteView', data);
          const selectedView =
            this.$store.getters[this.namespace + 'getSelectedView'];
          this.$store.dispatch(this.namespace + 'removeFromViewList', data);
          if (selectedView.globalViewId === data.globalViewId) {
            const globalView =
              this.$store.getters[this.namespace + 'getGlobalViews'].rows[0];
            this.handleVrSelectInput(globalView);
          }
        }
      } catch (e) {
        this.isSaveViewLoading = false;
        this.isUpdateViewLoading = false;
        this.openSnackbar();
      }
    },
    handleToggleEdit(view) {
      this.$store.dispatch(this.namespace + 'toggleEdit', view);
      const viewList = this.$store.getters['sov/getGlobalViews'].rows;
      viewList.forEach((item) => {
        if (item.globalViewId === view.globalViewId) {
          if (!view.edit) {
            setTimeout(() => {
              document
                .querySelector(`div[title="${item.label}"]`)
                .closest('.select__dropdown-list-item')
                ?.classList?.add('overrideHover');
              const input = document.querySelector(
                `#input${view.globalViewId}`
              );
              input?.focus();
              input?.select();
            });
          } else {
            document
              .querySelector(`div[title="${item.label}"]`)
              .closest('.select__dropdown-list-item')
              ?.classList?.remove('overrideHover');
          }
        } else {
          document
            .querySelector(`div[title="${item.label}"]`)
            .closest('.select__dropdown-list-item')
            ?.classList?.remove('overrideHover');
        }
        return item;
      });
    },
    handleVrSelectInput(selectedOption) {
      this.driverCreated = false;
      this.sovByShelvesCreated = false;
      // manually navigating to keywords shelf for clients with no standard shelf, driverCreated and sovByShelvesCreated part of the condition
      if (selectedOption && selectedOption.label) {
        this.$router.push({
          name: this.pageName,
          params: { id: selectedOption?.globalViewId }
        });
        this.$store.dispatch(
          this.namespace + 'viewChangeContentRestore',
          selectedOption
        );
      }
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      this.viewList.unshift(...defaultViewTemp);
    },
    initializePage() {
      this.showFilter = false;
      const requestBody = { page: this.page };
      HttpService.post('DASHBOARD_SERVICE', requestBody, {
        append: '/global/view/list'
      })
        .then((response) => {
          if (response.status === 200) {
            this.viewList = response.data.globalViews.map((item) => {
              item.currentUser = false;
              if (item.createdBy === window.user.email) {
                item.currentUser = true;
              }
              item.formattedAt = moment(item.createdAt).format('LLL');
              if (item.groupName !== 'Default Views') {
                item.edit = false;
              }
              return item;
            });

            this.sortViewList();

            let globalViewId = '';
            if (this.$route?.params?.id) {
              globalViewId = this.$route?.params?.id;
              this.handleViewBasedonParams(globalViewId);
            } else {
              globalViewId = response.data?.userUnsavedGlobalView?.globalViewId;
              if (globalViewId) {
                this.$router.push({
                  name: this.pageName,
                  params: { id: globalViewId }
                });
              } else {
                this.$router.push({
                  name: this.pageName,
                  params: { id: this.viewList[0]?.globalViewId }
                });
              }
            }
          }
        })
        .catch((error) => {
          console.error('e', error);
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        });
    },
    fetchBrands(brandsAPIKey, storeDispatchKey) {
      try {
        const brandsAPI =
          this.$store.getters[this.namespace + 'getPageList'].rows.API[
            brandsAPIKey
          ];
        const payload = this.createBrandsRequet(brandsAPI);
        this.$store.dispatch(this.namespace + storeDispatchKey, payload);
      } catch (e) {
        console.error('e', e);
      }
    },
    setViewIdInParam(viewId) {
      this.$router.push({ name: this.pageName, params: { id: viewId } });
    },
    setDataServices(response) {
      const metadataConfigs = {};
      response.data.widgets.forEach((widget) => {
        metadataConfigs[widget.widgetName] = widget;
      });
      const widget2MetadataConfig = metadataConfigs[this.widget2Name];
      this.widget2DataService = new DataService(
        this.page,
        widget2MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
    },
    async getDisableData(dynamicAttributes) {
      this.categoryStatusList = await getCategoryDisableStatus(
        dynamicAttributes
      );
      if (!Object.keys(this.categoryStatusList)?.length) {
        this.defaultAttributesOptions.values = {};
      }
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    async fetchPageList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.pageId = response.data.id;
        if (response.status === 200) {
          // const { cubeName, pageName, where } =
          //   response.data.API.filterAPI.request;
          // this.getGlobalFilters(cubeName, 'FETCH_FILTERS_V2', pageName, where);
          this.fetchFilterV3(
            {
              ...response.data.API.filterAPI,
              endPoint: response.data.API.filterAPI.endPoint + '?v2=' + this.v2
            },
            (data) => data
          ).finally(async () => {
            localStorageFallback(this.filterData?.category, 'categoryPage');
            if (
              response.data.calendar.min_date &&
              response.data.calendar.max_date
            ) {
              this.minMaxDate.minDate = moment(
                response.data.calendar.min_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
              this.minMaxDate.maxDate = moment(
                response.data.calendar.max_date,
                'YYYY-MM-DD'
              ).format('MM/DD/YYYY');
            } else {
              const today = moment('YYYY-MM-DD');
              const yearBack = moment('YYYY-MM-DD').subtract('1', 'years');
              this.minMaxDate.maxDate = today.format('MM/DD/YYYY');
              this.minMaxDate.minDate = yearBack.format('MM/DD/YYYY');
            }

            const initialState = cloneDeep(
              this.$store.getters[this.namespace + 'getInitialWidgetStates']
            );
            const unsavedState = cloneDeep(
              this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
            );
            const initialFilters = cloneDeep(initialState?.filters);
            const unsavedFilters = cloneDeep(unsavedState?.filters);
            const targetFilterState = unsavedFilters || initialFilters;
            const targetFilterValue =
              targetFilterState?.selectedFilter?.filter || {};
            // targetFilterState = {
            //   selectedFilter: {
            //     filter: { category: ["baby"] },
            //   },
            // };
            // {"date_range":{"name":"lastWeek","from":"2022-01-30","to":"2022-02-05","compare":true,"compare_name":null,"compare_from":"2022-01-23","compare_to":"2022-01-29"},}
            // this.defaultDateRange = targetFilterValue?.date_range?.name;
            // const storageValues = this.transformFilterFromAPI(
            //   [targetFilterState?.selectedFilter?.filter] || []
            // );
            // const filterPayload = this.createFilterState(
            //   storageValues,
            //   'lastWeek' || {}
            // );
            // this.handlePushUnsavedState({ filters: targetFilterState });
            // this.validateView();
            // this.setFiltersLocalStorage(
            //   this.filterLocalStorageKey,
            //   filterPayload
            // );
            let storage = JSON.parse(
              localStorage.getItem(this.filterLocalStorageKey)
            );
            const storageValues = this.transformFilterFromAPI(
              response.data.filters?.selectedFilter?.filter || []
            );
            let category = '';
            let categoryExists = true;
            const categorySelectionList = filterHierarchyPaths(
              this.filterData?.category,
              {}
            );
            this.categoryNodeMap = NodeIdMap(this.filterData?.category, {});
            let globalFilter = getGlobalSettingsForMS(
              undefined,
              categorySelectionList,
              false
            );
            this.previousPage = globalFilter?.[constants.NAVIGATE_FROM];
            setGlobalSettingsForMS(constants.NAVIGATE_FROM, {}, true);
            this.selectedCustomerFilters =
              getSavedCustomerFilters(this.filterData) || {};
            category =
              globalFilter?.category ||
              globalFilter?.category_hierarchy?.[0] ||
              this.secondaryFilterComputedData.category_hierarchy?.values[0]
                ?.title;
            if (category) {
              if (!categorySelectionList[category.toLowerCase()]) {
                categoryExists = false;
              }
            }
            checkForDisplayShareInOptions();
            if (Object.keys(globalFilter).length && categoryExists) {
              let nestedSettings = {
                ...configurationSetting(
                  globalFilter.nestedSettings,
                  this.globalToLocal
                )
              };
              setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
              setGlobalSettingsForMS(
                constants.SUBCATEGORY,
                globalFilter.subcategory
              );
              this.computedSelectedSettingsValue = getGlobalSettingsForMS(
                'nestedSettings',
                undefined,
                false
              );
              storageValues.values.date_range = globalFilter?.date_range;
              if (Object.keys(globalFilter?.subcategory || {}).length) {
                this.selectedSubCategory = getFormattedSubCategory(
                  globalFilter.subcategory,
                  globalFilter.filterSelection
                );
                // handles the subcategory selection on page load
                this.selections = getTransformedSubcategory();
                this.dynamicAttributes = getAttributeList(
                  globalFilter?.subcategory,
                  categorySelectionList
                );
              } else {
                let selectedPath = {};
                selectedPath[category] = true;
                this.dynamicAttributes = getAttributeList(
                  selectedPath,
                  categorySelectionList
                );
                setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
              }
              if (globalFilter?.[constants.ATTRIBUTES]) {
                this.defaultAttributesOptions.values =
                  globalFilter[constants.ATTRIBUTES];
                this.selectedAttributes = getAttributesData(
                  this.dynamicAttributes
                );
                this.getDisableData(this.dynamicAttributes);
              }
              setGlobalSettingsForMS(constants.CATEGORY, {}, true);
              setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, [category]);
            } else {
              removeGlobalSettingsForMS();
              storageValues.values.date_range = {
                name: 'last4Week',
                compare: true,
                compare_name: null
              };
              storageValues.values.category_hierarchy = [category];
              storageValues.order = ['category_hierarchy'];

              let nestedSettings = { ...this.computedSelectedSettingsValue };

              let selectedPath = {};
              selectedPath[category] = true;
              this.dynamicAttributes = getAttributeList(
                selectedPath,
                categorySelectionList
              );

              setGlobalSettingsForMS(null, {
                ...storageValues.values,
                nestedSettings
              });
            }

            storageValues.values.category_hierarchy = [category];
            storageValues.order = ['category_hierarchy'];
            if (!storage) {
              storage = {};
              storage = storageValues.values;
              // storage.dimension4 = [
              //   'ajax',
              //   'fabuloso',
              //   'hum'
              // ];
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              localStorage.setItem(
                `${this.filterLocalStorageKey}-order`,
                JSON.stringify(storageValues.order)
              );
            } else {
              localStorage.setItem(
                this.filterLocalStorageKey,
                JSON.stringify(storageValues.values)
              );
              localStorage.setItem(
                `${this.filterLocalStorageKey}-order`,
                JSON.stringify(storageValues.order)
              );
            }
            this.showFilter = true;
            this.$nextTick(() => {});

            await this.checkDatesForCategory([
              { dimensionValue: category.toLowerCase() + '~' }
            ]);
            setTimeout(() => {
              // this.showFilter = true;
              document.querySelector('.filter-list__item').click();
            }, 0);
            setTimeout(() => {
              document.querySelector('body').click();
              document.querySelector('.rb-filter .icon-cross').style.display =
                'none';
              this.refreshWidgets();
            }, 0);

            const widgetName =
              (isMarketShareLite()
                ? categoryConstants.mslCategoryShareSummary
                : categoryConstants.categoryShareSummary) +
              (this.v2 ? '_v2' : '');
            this.tableDataService = new TableWidgetService(
              this.page,
              widgetName,
              this.selectedView.pageId,
              this.selectedView.globalViewId
            );
            this.tableDataService.savedSortState =
              globalFilter?.[
                constants.SORTING_OBJECT
              ]?.categoryShareSummary_category;
            this.widget2DataService = new DataService(
              this.page,
              this.widget2Name,
              this.selectedView.pageId,
              this.selectedView.globalViewId
            );
            this.listApiResolved = true;
            this.pageFetched = true;
          });
        }
      } catch (err) {
        this.pageFetched = false;
        this.pageFetchedError = false;
        console.error('err', err);
        this.openSnackbar();
      }
    },
    isJsonParsable(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return string;
      }
    },
    setFiltersLocalStorage(filterLocalStorageKey, storageValues) {
      if (storageValues?.values) {
        if (Object.keys(storageValues?.values).length > 0) {
          Object.keys(storageValues?.values).forEach((key) => {
            if (storageValues.order.indexOf(key) !== -1) {
              storageValues.values[key] = storageValues.values[key].map(
                (item) => {
                  return this.isJsonParsable(item);
                }
              );
            }
          });
        }
        this.setLocalStorage(filterLocalStorageKey, storageValues.values);
        this.setLocalStorage(
          `${filterLocalStorageKey}-order`,
          storageValues.order
        );
      }
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      this.$store.dispatch('setInitialSelections', {});
      // const payload = this.createDraftViewPayload();
      // this.$store.dispatch(this.namespace + 'draftView', payload);
      setGlobalSettingsForMS(constants.NAVIGATE_FROM, 'category-page');
      setGlobalSettingsForMS(
        'category',
        getGlobalSettingsForMS('category_hierarchy')[0]
      );
      next();
    } catch (e) {
      console.error('e', e);
    } finally {
      next();
    }
  }
};
</script>
<style scoped lang="css" scoped>
.intermediate-height-w3 {
  height: 566px;
}
.intermediate-height-w5 {
  height: 332px;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.view-port {
  height: calc(100vh - 72px);
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: scroll;
}
.widget-2-width {
  width: calc(60% - 16px);
}
.u-height-36px {
  height: 36px;
}
.corners-rounded {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.overlay-snapshot {
  position: sticky;
  bottom: -23px;
  z-index: 99;
}
.box-shadows {
  box-shadow: 0 1px 16px 0 rgb(43 51 59 / 25%);
}
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}

.capitalize {
  text-transform: capitalize;
}

.subcagetory-dropdown {
  border: 1px dashed #ebeef6;
}
</style>
