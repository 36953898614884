<template>
  <div class="u-height-100 u-spacing-pv-xxxl u-spacing-ph-xxl">
    <section class="u-height-100 u-spacing-pv-m">
      <Header :step="step" />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        class="add-sku-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      />
      <div class="u-display-flex u-flex-justify-content-flex-end">
        <div
          v-if="products.length === 0"
          class="u-font-size-6 u-display-flex u-flex-align-items-center u-color-red-base"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small"
            icon="error-fill"
          />
          <div class="u-flex-0 u-spacing-ml-xxs">
            Ad group should have at-least one product
          </div>
        </div>
      </div>
      <Footer
        class="u-spacing-pt-l"
        btn-left-text="Back"
        :btn-right-text="rightButtonText"
        :disable-right-btn="disableNextButton"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>
<script>
import Header from '@/components/ams/campaign_creation/steps/common/header.vue';
import Footer from '@/components/ams/campaign_creation/steps/common/footer.vue';
import loader from '@/components/basic/loader';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import {
  STEPS_DATA_GETTER,
  STEPS_DATA_SETTER,
  BID_TYPES,
  CREATE_ADGROUP,
  CAMPAIGN_DETAILS_GETTER
} from '../../config';

export default {
  name: 'AddAdGroup',

  components: {
    loader,
    messageBar,
    Header,
    Footer,
    CustomActionPanel
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      rightButtonText: 'Next',
      customActionPanelCustomState: {}
    };
  },
  computed: {
    campaignInfo() {
      return this.$store.getters[CAMPAIGN_DETAILS_GETTER] || {};
    },
    isOptimizedBidType() {
      return [BID_TYPES.acquire_ntb.type, BID_TYPES.max_sales.type].includes(
        this.stepsData.steps?.settings?.bidType
      );
    },
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    products() {
      const {
        tempAdGroup,
        steps: { adGroups }
      } = this.stepsData;
      const products = tempAdGroup?.products || [];
      return products;
    },
    isOptimizedBiddingEnabled() {
      const { campaignEditFlow } = this.stepsData;
      return campaignEditFlow
        ? this.campaignInfo?.data?.optimized_bidding_enabled
        : this.isOptimizedBidType;
    },
    disableNextButton() {
      const { tempAdGroup, headerError } = this.stepsData;
      if (this.isOptimizedBiddingEnabled) {
        return !(
          tempAdGroup?.adGroupName &&
          tempAdGroup?.products?.length > 0 &&
          !headerError
        );
      }

      return !(tempAdGroup?.products?.length > 0);
    }
  },
  created() {
    const {
      tempAdGroup,
      steps: { settings }
    } = this.$store.getters[STEPS_DATA_GETTER];
    if (this.isOptimizedBiddingEnabled) {
      this.rightButtonText = tempAdGroup?.adGroupName
        ? 'Save'
        : 'Create Ad Group';
    }
    this.customActionPanelCustomState =
      this.step?.customActionPanelCustomState(this);
  },
  methods: {
    nextStep() {
      const stepsData = { ...this.stepsData };
      if (this.isOptimizedBiddingEnabled) {
        const editingAdGroupIndex = stepsData.steps.adGroups.findIndex(
          ({ id }) => stepsData.tempAdGroup?.id === id
        );
        if (editingAdGroupIndex > -1) {
          stepsData.steps.adGroups[editingAdGroupIndex] = stepsData.tempAdGroup;
        } else {
          stepsData.steps.adGroups.push(stepsData.tempAdGroup);
        }
        if (stepsData.campaignEditFlow) {
          this.$store.dispatch(CREATE_ADGROUP, this).finally(() => {
            setTimeout(() => {
              this.$router.push({
                name: 'InstacartEntityDetails'
              });
            }, 1000);
          });
        } else {
          stepsData.tempAdGroup = {};
          this.$store
            .dispatch(STEPS_DATA_SETTER, stepsData)
            .then(() => this.$parent.$emit('createAdGroup', 'submitting', 2));
        }
      } else {
        this.$emit('nextStep', {});
      }
    },
    prevStep() {
      const stepsData = { ...this.stepsData };
      stepsData.tempAdGroup = {};
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
      if (stepsData?.campaignEditFlow) {
        this.$router.push({ name: 'InstacartEntityDetails' });
      }
      this.$parent.$emit('createAdGroup', 'exiting', 2);
    }
  }
};
</script>

<style lang="css">
.u-spacing-ml-auto {
  margin-left: auto;
}

.add-sku-campaign-creation
  .custom-entity
  .left-table
  .ag-body-viewport.ag-layout-normal {
  overflow-x: auto !important;
}
.add-sku-campaign-creation
  .custom-entity
  .left-table
  div[col-id^='sku_price'].ag-cell,
.add-sku-campaign-creation
  .custom-entity
  .left-table
  div[col-id^='weeks_of_coverage'].ag-cell {
  text-align: right !important;
}
.add-sku-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
</style>
