<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl">
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <div
        data-cy="biddingStrategyText"
        class="u-font-size-5 u-font-weight-600"
      >
        Bidding Strategy:
      </div>
      <rbRadioGroup
        :radio-options="radioOptions"
        :value="bidOptimization"
        :is-column="true"
        @onRadioChanged="handleRadioSelection"
      />

      <div slot="below_radio_1">
        <div v-if="bidOptimization === 'custom'">
          <div class="u-display-flex u-spacing-pt-m u-spacing-pl-l">
            <campaignSelect
              entitykey="title"
              :default-option="selectValue"
              :options="[{ title: 'Increase' }, { title: 'Decrease' }]"
              @selectedItem="onSelect"
            />
            <div class="u-width-240px u-spacing-pl-m">
              <rbNumberInput
                :options="inputOptions"
                @handleInput="updateInput"
                @handleValue="updateInput"
              />
            </div>
          </div>
          <div
            class="u-spacing-pl-l u-spacing-pt-s u-font-size-5 u-color-grey-lighter"
          >
            <div
              data-cy="exampleText"
              class="u-spacing-pt-xs"
            >
              Example: A 40% decrease on a $5.00 bid will become $3.00
            </div>
          </div>
        </div>
      </div>

      <!-- @onUpdate="adjustLifetimeBudget" -->
      <createCampaignFooter
        :disable-right-btn="false"
        btn-right-text="Exit"
        class="u-margin-top-40"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import campaignSelect from '@/components/ams/campaign_creation/steps/edit/campaignAdFormat/campaignSelect.vue';

import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import rbNumberInput from '@/components/widgets/rbNumberInput';

export default {
  components: {
    campaignSelect,
    createCampaignHeader,
    createCampaignFooter,
    rbRadioGroup,
    rbNumberInput
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    selectValue: {
      title: 'Increase'
    },
    inputOptions: {
      prefix: '%',
      value: 20
    },
    radioOptions: [
      {
        type: 'automated',
        label: 'Automated bidding',
        desc: 'Allow amazon to automatically optimize bids for placements other than top of search.'
      },
      {
        type: 'custom',
        label: 'Custom bid adjustment',
        desc: 'Allow amazon to automatically optimize bids for placements other than top of search.'
      }
    ],
    suggestedCampaignIndex: 0,
    bidOptimization: 'automated',
    bidMultiplier: null
  }),
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues?.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const { bidMultiplier } =
      this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
    this.selectValue =
      (bidMultiplier?.type && { title: bidMultiplier.type }) ||
      this.selectValue;
    this.inputOptions.value = bidMultiplier?.value || this.inputOptions.value;
    this.handleRadioSelection(null);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
      this.updateStepDescription();
    },
    updateStepDescription() {
      const { bidOptimization } =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      this.step.description = bidOptimization
        ? 'Automated bidding'
        : 'Custom bid adjustment';
    },
    handleRadioSelection(value) {
      const { bidOptimization } =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      if (!value) {
        this.bidOptimization =
          (typeof bidOptimization === 'boolean' && bidOptimization
            ? 'automated'
            : 'custom') || this.bidOptimization;
      }
      if (value) {
        this.bidOptimization = value;
      }
      this.updateSelectedStore({
        bidOptimization: this.bidOptimization === 'automated'
      });
      this.setBidMultiplier();
    },
    onSelect(value) {
      this.selectValue = value;
      this.setBidMultiplier();
    },
    updateInput(value) {
      this.inputOptions.value = value;
      this.setBidMultiplier();
    },
    setBidMultiplier() {
      const bidMultiplier = {
        type: this.selectValue?.title,
        value: this.inputOptions?.value
      };
      this.updateSelectedStore({ bidMultiplier });
    },
    nextStep() {
      const query = {
        edit: true
      };
      if (this.$route?.query?.manual) {
        query.manual = true;
      }
      this.$router.push({
        name: 'setup_suggested_campaign',
        query
      });
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style scoped></style>
