<template>
  <section>
    <section v-if="pageFetched">
      <header-section
        ref="headerSection"
        @filterUpdate="handleFilterUpdate"
      />
      <section class="u-envelop-width">
        <spotlight-widget @handleSpotlightAction="handleSpotlightAction" />
        <metric-tiles-widget
          v-if="outputMetricsWidgetService !== null"
          :service="outputMetricsWidgetService"
          :rca-service="rcaWidgetService"
          :metric-spotlight-service="metricSpotlightService"
        />
        <metric-tiles-widget
          v-if="performanceIndicatorsWidgetService !== null"
          :service="performanceIndicatorsWidgetService"
          :rca-service="rcaWidgetService"
          :metric-spotlight-service="metricSpotlightService"
        />
        <metric-tiles-widget
          v-if="inputMetricsWidgetService !== null"
          :service="inputMetricsWidgetService"
          :metric-spotlight-service="metricSpotlightService"
        />
        <!-- <ecom-planner-widget /> -->
        <ecom-planner-widget-v-2
          v-if="ecomPlannerService"
          :service="ecomPlannerService"
        />
      </section>
    </section>
    <section
      v-else-if="pageError"
      class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
    >
      <div class="u-display-flex noskuImage_container">
        <img
          alt="error"
          src="/images/no_sku_found.png"
          class="u-width-100"
        />
      </div>
      <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
        <p class="u-color-grey-base u-spacing-mr-s">Something went wrong!</p>
      </div>
    </section>
    <section
      v-else
      class="u-height-100vh u-display-flex u-flex-direction-column"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
  </section>
</template>
<script>
import Vue from 'vue';
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import splineChart from '@/components/pages/insights/amazon/rem-cc/molecules/spline-chart.vue';
import metricTile from '@/components/pages/insights/amazon/rem-cc/organisms/metric-tile.vue';
import rcaChart from '@/components/pages/insights/amazon/rem-cc/organisms/rca-chart.vue';
import SpotlightWidget from '@/components/pages/insights/amazon/rem-cc/widgets/spotlight-widget.vue';
import MetricTilesWidget from '@/components/pages/insights/amazon/rem-cc/widgets/metric-tiles-widget.vue';
import MetricTilesWidgetService from '@/components/pages/insights/amazon/rem-cc/services/MetricTilesWidgetService';
import RCAWidgetService from '@/components/pages/insights/amazon/rem-cc/services/RCAWidgetService';
import MetricSpotlightService from '@/components/pages/insights/amazon/rem-cc/services/MetricSpotlightService';
import EcomPlannerService from '@/components/pages/insights/amazon/rem-cc/services/EcomPlannerService';
import HeaderSection from '@/components/pages/insights/amazon/rem-cc/widgets/header-section.vue';
import ecomPlannerWidget from '@/components/pages/insights/amazon/rem-cc/widgets/ecom-planner-widget.vue';
import EcomPlannerWidgetV2 from '@/components/pages/insights/amazon/rem-cc/widgets/ecom-planner-widget-v2.vue';
import { eventBus } from '@/utils/services/eventBus';

export default Vue.extend({
  components: {
    loader,
    splineChart,
    metricTile,
    rcaChart,
    SpotlightWidget,
    MetricTilesWidget,
    HeaderSection,
    ecomPlannerWidget,
    EcomPlannerWidgetV2
  },
  data() {
    return {
      metricRows: [
        ['marketshare', 'sov', 'roas', 'lbb', 'adspend'],
        ['salesops', 'glanceviews']
      ],
      metricTilesWidgets: ['remcc_output', 'remcc_performance', 'remcc_input'],
      namespace: 'remcc/',
      page: 'REMCC-EXECUTIVE',
      pageFetched: false,
      pageError: false,
      selectedView: null,
      widgetsAPIs: {},
      pageId: null,
      viewList: [],
      expandedRows: {},
      outputMetricsWidgetService: null,
      rcaWidgetService: null,
      metricSpotlightService: null,
      inputMetricsWidgetService: null,
      performanceIndicatorsWidgetService: null,
      ecomPlannerService: null,
      routeName: 'ecommerce_command_centre'
    };
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.initializePage();
  },
  methods: {
    async initializePage() {
      try {
        const requestBody = {
          page: this.page
        };
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          requestBody,
          {
            append: '/global/view/list'
          }
        );
        if (response.status === 200) {
          this.viewList = response.data.globalViews?.map((view) => {
            view.currentUser = false;
            if (view.createdBy === window.user?.email) {
              view.currentUser = true;
            }
            view.formattedAt = moment(view.createdAt).format('LLL');
            if (view.groupName !== 'Default Views') {
              view.edit = false;
            }
            return view;
          });

          this.sortViewList();

          let globalViewId = '';
          if (this.$route?.params?.id) {
            globalViewId = this.$route?.params?.id;
            this.handleViewBasedonParams(globalViewId);
          } else {
            globalViewId = response.data?.userUnsavedGlobalView?.globalViewId;
            if (globalViewId) {
              this.$router.push({
                name: 'remcc',
                params: { id: globalViewId }
              });
            } else {
              this.$router.push({
                name: 'remcc',
                params: { id: this.viewList[0]?.globalViewId }
              });
            }
          }
        }
      } catch (err) {
        this.pageError = true;
      }
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter(
            (view) => parseInt(params) === view.globalViewId
          )[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.$router.push({
            name: 'remcc',
            params: { id: selectedView.globalViewId }
          });
          return;
        }
        this.fetchWidgetsList(selectedView);
      } else {
        if (this.viewList?.length > 0) {
          selectedView = this.viewList[0];
          this.$router.push({
            name: 'remcc',
            params: { id: selectedView.globalViewId }
          });
          this.fetchWidgetsList();
        }
      }
    },
    handleSpotlightAction(payload) {
      if (payload.type === 'setFilter') {
        this.$refs.headerSection.handleFilterUpdate({
          filterKey: payload.filterKey,
          selections: [payload.selection]
        });
      } else if (payload.type === 'openRCA') {
        eventBus.$emit('handleMetricTileExpand', payload.metricKey);
      }
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort((a, b) => a.label - b.label);
      this.viewList.unshift(...defaultViewTemp);
    },
    async fetchWidgetsList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.pageId = response.data.id;
        this.widgetsAPIs = response.data.widgets.reduce((prevVal, curVal) => {
          prevVal[curVal.widgetName] = curVal.API[0];
          return prevVal;
        }, {});
        this.outputMetricsWidgetService = new MetricTilesWidgetService({
          api: this.widgetsAPIs.remcc_output,
          globalViewId: this.selectedView.globalViewId,
          pageId: this.pageId
        });
        this.inputMetricsWidgetService = new MetricTilesWidgetService({
          api: this.widgetsAPIs.remcc_input,
          globalViewId: this.selectedView.globalViewId,
          pageId: this.pageId
        });
        this.performanceIndicatorsWidgetService = new MetricTilesWidgetService({
          api: this.widgetsAPIs.remcc_performance,
          globalViewId: this.selectedView.globalViewId,
          pageId: this.pageId
        });
        this.rcaWidgetService = new RCAWidgetService({
          api: this.widgetsAPIs.remcc_rca,
          globalViewId: this.selectedView.globalViewId,
          pageId: this.pageId
        });
        this.metricSpotlightService = new MetricSpotlightService({
          api: this.widgetsAPIs.remcc_spotlights,
          globalViewId: this.selectedView.globalViewId,
          pageId: this.pageId
        });
        this.ecomPlannerService = new EcomPlannerService();
        this.pageFetched = true;
      } catch (err) {
        console.log(err);
        this.pageError = true;
      }
    },
    handleFilterUpdate() {
      this.pageFetched = false;
      setTimeout(() => {
        this.pageFetched = true;
      }, 1000);
    }
  }
});
</script>
<style lang="css" scoped>
.expand-container {
  box-shadow: 0 0 4px 0 #caccce;
  min-height: 300px;
}
</style>
