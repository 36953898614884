<template>
  <div>
    <div
      v-if="
        !(
          hideIfNull &&
          displayValue.oldValue === 'NA' &&
          displayValue.newValue === 'NA'
        )
      "
      class="u-spacing-mt-s u-display-flex u-flex-direction-column"
    >
      <span
        class="u-font-size-7 u-line-height-1 u-color-grey-lighter u-font-weight-600"
        >{{ config['sub-heading'] }}</span
      >
      <div class="u-display-flex u-flex-align-items-center u-spacing-mt-xs">
        <div
          v-if="!(hideIfNull && displayValue.oldValue === 'NA')"
          :style="{ 'min-width': config.leftMinWidth }"
        >
          <span
            class="u-font-size-6 u-color-grey-light u-line-height-1.31"
            data-cy="oldValue"
            :class="{ 'u-text-case-title': !config.caseSensitive }"
            >{{ displayValue.oldValue }}
          </span>
        </div>
        <div
          v-if="
            !(
              hideIfNull &&
              (displayValue.oldValue === 'NA' || displayValue.newValue === 'NA')
            )
          "
        >
          <rb-icon
            class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
            :icon="'trending-neutral'"
          />
        </div>

        <div v-if="!(hideIfNull && displayValue.newValue === 'NA')">
          <span
            class="u-font-size-6 u-color-grey-light u-line-height-1.31 u-font-weight-600"
            data-cy="newValue"
            :class="{ 'u-text-case-title': !config.caseSensitive }"
            >{{ displayValue.newValue }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    caseSensitive: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {
        return {
          oldValue: 'NA',
          newValue: 'NA'
        };
      }
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    hideIfNull() {
      return (
        this.config?.hideIfNull ||
        this.data?.customArrowComponent?.hideIfNull ||
        false
      );
    },
    displayValue() {
      let oldValue = null;
      let newValue = null;
      if (this.data?.customArrowComponent?.multiple) {
        oldValue = this.data[this.config.oldValue];
        newValue = this.data[this.config.newValue];
      } else {
        oldValue = this.data.oldValue;
        newValue = this.data.newValue;
      }
      let displayType;
      if (this.config.mode === 'DATA') {
        displayType = this.data[this.config.displayTypeKey];
      } else {
        displayType = this.config.displayType;
      }
      switch (displayType) {
        case 'currency':
          return {
            oldValue:
              this.getDisplayValue(oldValue) === 'NA'
                ? 'NA'
                : Vue.options.filters.num_format(
                    this.getDisplayValue(oldValue),
                    'currency'
                  ),
            newValue:
              this.getDisplayValue(newValue) === 'NA'
                ? 'NA'
                : Vue.options.filters.num_format(
                    this.getDisplayValue(newValue),
                    'currency'
                  )
          };
        default:
          return {
            oldValue: this.getDisplayValue(oldValue),
            newValue: this.getDisplayValue(newValue)
          };
      }
    }
  }
};
</script>
