import { getAdSkusActionObject as getKrogerAdSkusActionObject } from '@/pages/dashboard_service_campaign_management/kroger/common';
import { isEmpty } from 'lodash';
import HttpService from '@/utils/services/http-service';
import {
  getCampaignBudgetValue,
  getEndDateValue,
  isEditBudgetAndPacingApplyDisabled
} from '@/pages/dashboard_service_campaign_management/promoteiq/campaigns';

const WIDGET = 'campaign';
const PRIMARY_KEY = 'campaign_id';

export function getAdSkusActionObject(retailer, aggregator) {
  return getKrogerAdSkusActionObject(retailer, aggregator, 'Campaigns');
}

const getBudgetTypeValue = (vueRef, campaign, aggregator) => {
  return campaign.data?.[`${aggregator}_cm_campaign_campaign_status`] ===
    'SCHEDULED'
    ? vueRef?.adjustLineItemBudgetTypeSelection?.toUpperCase()
    : null;
};

export function getEditBudgetAndPacingActionObject(
  context,
  retailer,
  aggregator
) {
  const actionType = `${retailer}CampaignBudgetPropertiesChange`;
  return {
    icon: 'dollar',
    title: 'Adjust Budget & Pacing',
    id: 'edit-budget-and-pacing',
    getValidSelections: (selections) =>
      selections.filter(
        (selection) =>
          selection.data[`${aggregator}_cm_campaign_campaign_status`] !==
          'ENDED'
      ),
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    customState: {
      component: 'EditBudgetAndPacing',
      props: {
        heading: 'Adjust campaign budget and pacing',
        title: 'Budget Interval',
        widgetsConfig: (editBudgetPacingVueRef) => {
          return {
            showCheckboxForBudget: true,
            shouldDisableBudgetType: (selections) =>
              selections.every(
                (selection) =>
                  selection.data[
                    `${aggregator}_cm_campaign_campaign_status`
                  ] !== 'SCHEDULED'
              ),
            disabledBudgetTypeTooltipMessage:
              'Please select Scheduled campaigns',
            endDateParam: `${aggregator}_cm_campaign_end_date`,
            secondHeading: 'Adjust Campaign Budget',
            showMonthlyAndDailyCap: false,
            showAdjustPacing: true,
            adjustPacingOptions: [
              {
                title: 'Even',
                value: 'even'
              },
              {
                title: 'Asap',
                value: 'asap'
              },
              ...(retailer === 'chewy_promoteiq'
                ? [
                    {
                      title: 'Front-Loaded',
                      value: 'front_loaded'
                    }
                  ]
                : [])
            ],
            adjustLineItemBudgetTypeSelection: [
              {
                title: 'Daily',
                value: 'daily'
              },
              {
                title: 'Weekly',
                value: 'weekly'
              },
              {
                title: 'Monthly',
                value: 'monthly'
              },
              {
                title: 'Lifetime',
                value: 'lifetime'
              }
            ],
            adjustLineItemBudgetCappedOptions: [
              {
                title: 'Set new budget',
                type: 'input',
                value: 'set_new_budget'
              },
              {
                title: 'Increase budget',
                type: 'dropdown',
                value: 'increase_budget',
                dropdown: [
                  {
                    label: 'Change by percentage',
                    value: 'percentage'
                  },
                  {
                    label: 'Change absolute value',
                    value: 'absolute_value'
                  }
                ]
              },
              {
                title: 'Decrease budget',
                value: 'decrease_budget',
                type: 'dropdown',
                dropdown: [
                  {
                    label: 'Change by percentage',
                    value: 'percentage'
                  },
                  {
                    label: 'Change absolute value',
                    value: 'absolute_value'
                  }
                ]
              }
            ],
            title: 'Adjust Campaign Budget Type',
            heading: 'Adjust campaign budget and pacing',
            actionType: actionType,
            apiVersion: 'actionlog',
            retailer: context.$store.getters.getRetailer,
            widget: WIDGET,
            primaryKey: PRIMARY_KEY,
            leftBtnDisabled(editBudgetAndPacingComponentRef) {
              return isEditBudgetAndPacingApplyDisabled.call(
                this,
                editBudgetAndPacingComponentRef
              );
            },
            async onLeftBtnClick() {
              const selectedRows = editBudgetPacingVueRef.selections;
              const invalidCampaignNames = [];
              const payload = selectedRows
                .map((campaign) => {
                  const campaignId = campaign?.data?.campaign_id;
                  const prevBudget =
                    campaign?.data?.[`${aggregator}_cm_campaign_total_budget`];
                  const newBudget = getCampaignBudgetValue(
                    editBudgetPacingVueRef,
                    prevBudget
                  );
                  const newBudgetType = getBudgetTypeValue(
                    editBudgetPacingVueRef,
                    campaign,
                    aggregator
                  );
                  const adjustPacingValue =
                    editBudgetPacingVueRef?.adjustPacingSelection?.toUpperCase();
                  const newEndDate = getEndDateValue(
                    campaign,
                    editBudgetPacingVueRef,
                    aggregator
                  );
                  if (
                    !(
                      newBudget ||
                      newBudgetType ||
                      adjustPacingValue ||
                      newEndDate
                    )
                  ) {
                    invalidCampaignNames.push(
                      campaign?.data?.[
                        `${aggregator}_cm_campaign_campaign_name`
                      ]
                    );
                    return null;
                  }
                  return {
                    widget: 'campaign',
                    actionType: actionType,
                    actionSource: {
                      pageUrl: window.location.href
                    },
                    primaryKey: campaignId,
                    actionPayload: {
                      campaign_id: campaignId,
                      new_budget: newBudget,
                      new_budget_type: newBudgetType,
                      new_pacing: adjustPacingValue,
                      new_end_date: newEndDate
                    },
                    viewPayload: {
                      new_budget: newBudget,
                      new_budget_type: newBudgetType,
                      old_budget: prevBudget,
                      old_budget_type:
                        campaign?.data?.[
                          `${aggregator}_cm_campaign_budget_interval`
                        ],
                      old_pacing:
                        campaign?.data?.[`${aggregator}_cm_campaign_pacing`],
                      new_pacing: adjustPacingValue,
                      new_end_date: newEndDate
                    }
                  };
                })
                .filter(Boolean);
              try {
                if (!isEmpty(invalidCampaignNames)) {
                  editBudgetPacingVueRef.openSnackbar(
                    'Following campaigns were automatically deselected as they were invalid for the actions chosen: ' +
                      invalidCampaignNames.join(', ')
                  );
                }
                await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                editBudgetPacingVueRef.$emit('afterAPIActionToCall');
              } catch (err) {
                editBudgetPacingVueRef.openSnackbar('Something went wrong.');
              }
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}
