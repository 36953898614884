<template>
  <section
    class="fit-content u-cursor-pointer u-font-size-7 u-cursor-pointer u-color-grey-lighter"
    :class="{
      'rule-builder-chip': chipSize === 'm',
      'filter-chip-s-match': chipSize === 'filter-chip-s-match'
    }"
    @click="handleChipClick"
  >
    <div class="u-display-flex u-flex-align-items-center u-spacing-ph-xs">
      <span
        v-if="preTextIcon"
        class="u-spacing-mr-xs"
      >
        <rb-icon
          :icon="preTextIcon"
          class="rb-icon rb-icon--small"
        />
      </span>
      <span>{{ preText }}</span>
      <div class="u-display-flex u-spacing-ml-xs">
        <div
          v-for="(selectionNested, tempSlectionsIndex) in chipSelections"
          :key="tempSlectionsIndex"
          class="u-display-flex"
        >
          <div
            v-for="(selection, index) in selectionNested"
            :key="index"
          >
            <span
              v-if="((selection || {}).prefix || {}).text"
              class="u-spacing-mr-xs"
              >{{ ((selection || {}).prefix || {}).text }}</span
            >
            <span
              v-if="((selection || {}).infix || {}).text"
              class="u-spacing-mr-xs"
              :class="[
                'u-color-' +
                  ((((selection || {}).infix || {}).class || {}).color ||
                    'grey-base')
              ]"
            >
              {{ ((selection || {}).infix || {}).text }}
            </span>
            <span
              v-if="((selection || {}).suffix || {}).text"
              class="u-spacing-mr-xs"
              >{{ ((selection || {}).suffix || {}).text }}</span
            >
          </div>
        </div>
      </div>
      <span
        v-if="hasCaret"
        class="u-spacing-mr-xs"
        ><rb-icon
          :icon="'caret-down'"
          class="rb-icon rb-icon--medium u-cursor-pointer"
      /></span>
      <slot name="before-cross" />
      <span
        v-if="hasCross"
        class="cross-icon u-spacing-pv-xxs"
        @click.stop="handleCrossClick"
        ><rb-icon
          :icon="'cross'"
          class="rb-icon rb-icon--xx-small u-cursor-pointer"
      /></span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    hasCaret: {
      type: Boolean,
      deafult: true
    },
    hasCross: {
      type: Boolean,
      default: true
    },
    chipSize: {
      type: String,
      default: 'm'
    },
    chipSelections: {
      type: Array,
      default: () => []
    },
    preTextIcon: {
      type: String,
      default: null
    },
    preText: {
      type: String,
      default: null
    }
  },
  methods: {
    handleChipClick() {
      this.$emit('chipClick');
    },
    handleCrossClick() {
      this.$emit('crossClick');
    }
  }
};
</script>

<style lang="css" scoped>
.fit-content {
  height: fit-content;
  width: fit-content;
}
.filter-chip-s-match {
  font-size: 1.1rem;
  padding: 4px 2px;
  background: #fff;
  color: #8b8f93;
  border: 1px solid #e9eaeb;
  border-radius: 24px;
}
</style>
