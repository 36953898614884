// All values in modalConfig prop are required (for each sub group, ie header,
body, footer) if you need to use the default template. Otherwise just override
the required slot with your custom component

<template>
  <div
    class="u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    @click.self="$emit('clickedOutside', true)"
  >
    <div @click="$emit('clickedOutside', false)">
      <slot name="header" />
      <div class="u-overflow-hidden u-spacing-ph-xxl">
        <slot name="body">
          <div
            v-if="modalConfig.bodyTitle"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-1 u-font-weight-600 u-spacing-pt-xxl"
          >
            <rb-icon
              class="largeIcon u-color-blue-base"
              style="padding-top: 1px; padding-right: 4px"
              :icon="modalConfig.bodyTitle.icon"
            />
            <div
              v-if="
                modalConfig.bodyTitle.highlightText ||
                modalConfig.bodyTitle.text
              "
              class="u-flex-0 u-spacing-pt-xs"
            >
              <span class="u-color-blue-base">{{
                ' ' + modalConfig.bodyTitle.highlightText
              }}</span>
              {{ modalConfig.bodyTitle.text }}
            </div>
          </div>
          <div
            v-if="modalConfig.body"
            class="u-display-flex u-spacing-pv-m"
          >
            <div
              class="u-font-size-3 u-flex-align-items-center u-text-align-center u-width-100"
              style="line-height: 23px"
            >
              <div v-if="modalConfig.body.text">
                {{ modalConfig.body.text }}
              </div>
              <div
                v-if="modalConfig.body.subtext"
                class="subtextReq"
              >
                {{ modalConfig.body.subtext }}
              </div>
            </div>
          </div>
        </slot>

        <slot name="footer">
          <div
            v-if="
              modalConfig.footer.leftButton || modalConfig.footer.rightButton
            "
            class="u-display-flex u-flex-justify-content-center u-spacing-pt-m u-spacing-pb-xxl"
          >
            <rb-button
              v-if="modalConfig.footer.leftButton.show"
              class="hollow-outlined-btn u-spacing-mr-m"
              :text="modalConfig.footer.leftButton.text"
              type="hollow"
              style="min-width: 105px"
              :icon-left="modalConfig.footer.leftButton.icon"
              :click-fn="modalConfig.footer.leftButton.onClick"
            />
            <rb-button
              v-if="modalConfig.footer.rightButton.show"
              class="open-filter-btn"
              style="min-width: 105px"
              :text="modalConfig.footer.rightButton.text"
              :icon-left="modalConfig.footer.rightButton.icon"
              type="filled"
              :click-fn="modalConfig.footer.rightButton.onClick"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    clickedOutside: {
      type: Function,
      default: null
    },
    modalConfig: {
      type: Object,
      default: function () {
        return {
          bodyTitle: {
            text: 'Default',
            highlightText: '',
            icon: 'email-subscription'
          },
          body: {
            text: 'Default'
          },
          footer: {
            leftButton: {
              text: 'Left',
              onClick: null,
              icon: 'filter',
              show: true
            },
            rightButton: {
              text: 'Right',
              onClick: null,
              icon: 'filter',
              show: true
            }
          }
        };
      }
    }
  }
};
</script>
<style scoped lang="css">
.largeIcon {
  height: 48px;
  width: 48px;
}
.largeIcon:before {
  font-size: 48px;
}
.subtextReq {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #8b8f93;
}
.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
}
.modal > div {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 400px;
  max-width: 650px;
}
.flex-row {
  margin-bottom: 0;
}
</style>
