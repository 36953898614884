<template>
  <div
    class="u-height-100 u-spacing-pt-xxxl u-spacing-pb-xxl u-spacing-pl-xxl u-spacing-pr-l"
  >
    <section class="u-height-100 u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        class="add-sku-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <div
          slot="right-table-footer"
          class="u-position-absolute"
          style="bottom: -20px"
        >
          <div class="u-width-480px u-display-flex u-flex-direction-column">
            <div
              v-if="skuLength > 3"
              class="u-font-size-6 u-color-grey-lighter u-font-weight-600 u-display-flex u-flex-align-items-center"
              data-cy="txtTocreatesingleSkuCampaign"
            >
              <rb-icon
                class="u-color-grey-lighter rb-icon--medium u-spacing-mr-xs"
                icon="info-circle-fill"
              />
              Select a maximum of 3 SKUs to create single sku campaigns
            </div>
            <div v-else>
              <rb-checkbox
                v-model="singleSku"
                @input="handleSingleSkuSelection"
              >
                <span
                  class="u-font-size-6 u-font-weight-600"
                  data-cy="createCamWithSingleSkutxt"
                >
                  Create Campaigns with single Sku’s
                </span>
              </rb-checkbox>
            </div>
          </div>
        </div>
      </component>

      <createCampaignFooter
        class="u-margin-top-40"
        :show-left-btn="false"
        :disable-right-btn="
          !(allStepsData && allStepsData.skus && allStepsData.skus.length)
        "
        @onClickBtnRight="nextStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';

export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    CustomActionPanel
  },
  props: {
    step: {
      type: Object,
      disableRightButton: true,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      disableRightButton: true,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      singleSku: false,
      customActionPanelCustomState: {}
    };
  },
  computed: {
    skuLength() {
      return this.allStepsData?.skus?.length;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  watch: {
    allStepsData: {
      handler(newValue) {
        const skuLength = newValue?.skus?.length || 0;
        this.step.description = `${skuLength} SKU${
          skuLength !== 1 ? "'s" : ''
        } added`;
        newValue?.skus?.length;
        if (skuLength) {
          this.disableRightButton = true;
        } else {
          this.disableRightButton = false;
        }
        if (skuLength > 3 && this.singleSku) {
          this.singleSku = false;
          this.handleSingleSkuSelection();
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.singleSku = this.allStepsData?.singleSku || this.singleSku;
    this.$store.dispatch(this.selectedValues.action, {
      ...this.allStepsData,
      suggestedCampaigns: null,
      singleSku: this.singleSku
    });
    this.customActionPanelCustomState =
      this.step?.customActionPanelCustomState(this);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    handleSingleSkuSelection() {
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        singleSku: this.singleSku
      });
    },
    nextStep() {
      this.$emit('nextStep', {});
    }
  }
};
</script>

<style lang="css">
.add-sku-campaign-creation
  .custom-entity
  .left-table
  .ag-body-viewport.ag-layout-normal {
  overflow-x: auto !important;
}
.add-sku-campaign-creation
  .custom-entity
  .left-table
  div[col-id^='sku_price'].ag-cell,
.add-sku-campaign-creation
  .custom-entity
  .left-table
  div[col-id^='weeks_of_coverage'].ag-cell {
  text-align: right !important;
}
.add-sku-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
</style>
