var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rb-data-table u-width-100 u-height-100 rb-insights-table-container",class:{ 'ag-row-no-hover': _vm.rowNoHoverState },attrs:{"data-cy":"tableContainer"}},[_c('ag-grid-vue',{staticClass:"ag-theme-material u-display-flex u-flex-direction-column u-flex-1",class:{ overflow: _vm.hasOverflow },staticStyle:{"width":"100%"},style:([
      _vm.customHeight &&
        (_vm.pagination && _vm.paginationTotalKey / _vm.paginationPerPageLimit > 0
          ? { height: '90%' }
          : { height: '100%' })
    ]),attrs:{"id":_vm.tableId,"data-cy":'tableID' + _vm.getDataCYAttribute,"grid-options":_vm.gridOptions,"default-col-def":_vm.defaultColDef,"row-data":_vm.rowData,"stop-editing-when-grid-loses-focus":_vm.stopEditingWhenGridLosesFocus,"pinned-bottom-row-data":_vm.pinnedBottomRowData,"column-defs":_vm.columnDefs,"enable-sorting":_vm.enableClientSideSorting,"enable-server-side-sorting":_vm.enableServerSideSorting,"row-height":_vm.rowHeight,"header-height":_vm.headerHeight,"suppress-column-virtualisation":_vm.suppressColumnVirtualisation,"suppress-movable-columns":_vm.suppressMovableColumns,"sorting-order":['asc', 'desc'],"enable-col-resize":_vm.enableColResize,"overlay-no-rows-template":_vm.noRowsTemplate,"overlay-loading-template":_vm.overlayLoadingTemplate,"grid-auto-height":_vm.gridAutoHeight,"row-selection":(_vm.rowSelection || {}).mode || 'multiple',"selection-changed":_vm.onSelectionChanged,"suppress-row-click-selection":true,"single-click-edit":_vm.singleClickEdit,"enable-cell-text-selection":_vm.enableCellTextSelection,"icons":_vm.icons,"pagination-change-event":_vm.paginationChangeEvent,"is-full-width-cell":_vm.isFullWidthCell,"full-width-cell-renderer":_vm.fullWidthCellRenderer,"framework-components":_vm.frameworkComponents,"get-row-height":_vm.getRowHeight,"is-row-selectable":_vm.isRowSelectable,"row-drag-managed":true,"column-everything-changed":_vm.debouncedAutoResizeGrid,"displayed-columns-changed":_vm.debouncedAutoResizeGrid},on:{"row-drag-enter":_vm.onRowDragLeave,"row-drag-end":_vm.onRowDragLeave,"row-drag-move":_vm.onRowDragLeave}}),(
      _vm.pagination &&
      Math.ceil(_vm.paginationTotalKey / _vm.paginationPerPageLimit) >= 1
    )?_c('div',{staticClass:"u-border-top u-border-color-grey-xxx-light u-border-width-s u-spacing-ph-l u-spacing-pv-m paginationFooter u-overflow-auto u-display-flex u-flex-justify-content-flex-end u-flex-align-items-center",attrs:{"data-cy":'pagination' + _vm.getDataCYAttribute}},[(_vm.showPaginationOptions)?_c('span',{staticClass:"u-font-size-5 u-spacing-ph-s",attrs:{"data-cy":"rowsPerPagetxt"}},[_vm._v("Rows per page:")]):_vm._e(),(_vm.showPaginationOptions)?_c('rb-select',{attrs:{"width":'70px',"send-details":true,"on-close":_vm.paginationSelected,"options":_vm.selectedPaginationValues}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',{staticClass:"u-font-size-5",attrs:{"data-cy":"paginationPerpageLimit"}},[_vm._v(_vm._s(_vm.paginationPerPageLimit))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs",attrs:{"icon":'caret-down'}})],1)]):_vm._e(),_c('span',{staticClass:"u-font-size-5 u-spacing-pl-l u-spacing-pr-m",attrs:{"data-cy":"totalNoOfPages"}},[_vm._v(_vm._s(_vm.paginationObj.pageStart)+" - "+_vm._s(_vm.paginationObj.pageTotal)+" / "+_vm._s(_vm.paginationObj.total))]),_c('b-pagination',{attrs:{"total":_vm.paginationTotalKey,"current":_vm.config.body.APIConfig.page,"size":'is-small',"simple":false,"rounded":true,"per-page":_vm.paginationPerPageLimit},on:{"update:current":function($event){return _vm.$set(_vm.config.body.APIConfig, "page", $event)},"change":_vm.pageChangeEvent}})],1):_vm._e(),(_vm.downloadThisTable)?_c('rb-download-button',{ref:'downloadReference',staticClass:"u-display-none",attrs:{"config":_vm.config,"column-map":_vm.downloadColumnMap,"file-name":_vm.config.header && _vm.config.header.actions.download.fileName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }