// Points to note
// Try to avoid HTML as string. HTML as string causes issue in search terms.
// Incase its needed - use disableSearch: true property to bypass search for those title/points/subpoints.
// Supports retailer specific showing of release notes using applicableRetailers property
import utils from '@/utils/helpers/';
import { filters } from '@ciq-dev/ciq-ui';
import { store } from '@/store/store';
class ReleaseNotesUtilities {
  // Utility functions to support release notes.
  // If some feature points need to access dynamic state/store variables, can be implemented as a method here.
  getExtensionDownloadUrl() {
    const browser = utils.getBrowserName();
    const redirectUrl = 'https://chrome.google.com/webstore/detail/commerceiq/';
    const extensionId =
      filters.config_check('extension.' + browser + '.id') ||
      'knmkalnmhkhjpojmnlfkmfbdagpcommg';
    if (browser === 'chrome') {
      return redirectUrl + extensionId;
    } else {
      return null;
    }
  }

  checkIfAdvertisingSubscribed() {
    return store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
  }
}
const releaseNotes = new ReleaseNotesUtilities();

// getReleaseNotes will be only invoked when the rightPanel is loaded
// This is done to access all the store variables, handling reload edge case where store can be empty
// Json created in such a way to support recursive search and to support any number of sub level points
export const getReleaseNotes = () => {
  const ciqSalesReleases = [
    {
      date: 'Apr 19, 2024',
      features: [
        {
          title: 'Data Export',
          points: [
            {
              subtitle: `We are thrilled to announce a streamlined and robust export feature that facilitates effortless data extraction and analysis.
          To open, click on 'Reports' and then click on the 'Data export' button on the top right side of the page.`
            },
            {
              subtitle: `Here's why you'll love it:`,
              subpoints: [
                {
                  subsubtitle: `Option to add dimensions, metrics and time in 1 view.`
                },
                {
                  subsubtitle: `Multiple formats of report supported for direct consumption`
                },
                {
                  subsubtitle: `More metrics support. Eg: Reviews and Ratings, Price war. More to come during Q2`
                },
                {
                  subsubtitle: `Save and reuse feature`
                },
                {
                  subsubtitle: `Quick learning curve since this is a simpler functionality.`
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'Mar 14, 2024',
      features: [
        {
          title: 'Add Widgets for Fully Tailored Reports',
          points: [
            {
              subtitle:
                'Say hello to full customization! You can now add/remove widgets to your self-serve reports, allowing you to shape and personalize your reports exactly the way you want.'
            }
          ]
        },
        {
          title: 'Budget Reporting Widget with Comprehensive Metrics',
          points: [
            {
              subtitle:
                "Introducing the Budget Reporting Widget, a game-changer in understanding your campaign budgets and performance. We've introduced new metrics that provide a comprehensive view of your budget and spending dynamics:",
              subpoints: [
                {
                  subsubtitle:
                    'Total Budget: Displays the total budget for the current period based on the selection in the time period dropdown.'
                },
                {
                  subsubtitle:
                    'Planned Budget: Displays the budget plan for the current period till date.'
                },
                {
                  subsubtitle:
                    'Actual Spend: Highlights the ad spend for the current period till date.'
                },
                {
                  subsubtitle:
                    'Plan vs Spend: Difference between the budget plan and actual spend. A negative value represents overspend, while a positive value represents underspend.'
                },
                {
                  subsubtitle:
                    'Plan vs Spend in %: Percentage difference between actual spend and planned spend.'
                },
                {
                  subsubtitle:
                    'Remaining Budget for the Period: Indicates the remaining budget for the selected period based on actual spend.'
                },
                {
                  subsubtitle:
                    'Average Daily Spend for Last 7 Days: Presents the average ad spend for the last 7 days.'
                },
                {
                  subsubtitle:
                    'Estimated utilization for the Period: Estimates budget utilization assuming spending continues as per the last 7 days.'
                },
                {
                  subsubtitle:
                    'Required Daily Spend: Daily spend needed for 100% utilization of the budget by the end of the period.'
                }
              ]
            },
            {
              subtitle:
                'Get ready to unlock deeper insights and take control of your campaigns like never before!'
            }
          ]
        }
      ]
    },
    {
      date: 'April 13, 2023',
      features: [
        {
          title: 'Introducing in-product download center',
          points: [
            {
              subtitle:
                'We are excited to introduce Download Center - a new feature that makes it easier for users to download and view any report right within the product.'
            },
            {
              subtitle:
                'Users just need to navigate to the table/report which they need to download and click the new download icon. The download will start in the background while users can continue their work.'
            },
            {
              subtitle:
                'Once download is complete, it will be available in the manage downloads section accessible from ‘Top-Right’ area of the product. These downloads are exclusive to the user and cannot be accessed by any other user.'
            },
            {
              subtitle:
                'This feature is currently available for SKUs dashboard and reporting section and will soon be available for other sections of the product also.'
            }
          ]
        }
      ]
    },
    {
      date: 'February 7, 2023',
      applicableRetailers: ['amazonretail'],
      features: [
        {
          title: 'Enhancements to SKUs Tab'
        },
        {
          title: 'Search multiple SKUs in a click',
          points: [
            {
              subtitle:
                'Users now have an option to search for multiple SKUs using Bulk Search feature. Type or copy & paste SKUs and SKU names separated using a new line and just click the Search.'
            }
          ]
        },
        {
          title: 'Create Custom Views for SKUs report',
          points: [
            {
              subtitle:
                'Manage the columns on SKUs tab which helps users to quickly deep dive into any metric, verify the data points, do the necessary comparisons and also save the configuration done as one of the custom view to be accessed anytime anywhere.'
            }
          ]
        },
        {
          title: 'Internal Taxonomy and New Metrics',
          points: [
            {
              subtitle:
                'Internal taxonomy values set by our users (which were used for internal filters) are also available on SKUs page now. Along with this we have added few operations related metrics like Gross Sales, PO Fill rate, Last PO date, and Mean Forecast.'
            }
          ]
        }
      ]
    },
    {
      date: 'September 15, 2022',
      applicableRetailers: ['amazonretail', 'amazonfresh'],
      features: [
        {
          title: 'Improving calendar and last period comparison',
          points: [
            {
              subtitle:
                'We have incorporated the standard week definition (Sun -Sat) in the Amazon distribution channels (retail and fresh) and the calendar present in different modules highlights week numbers next to the given dates.'
            },
            {
              subtitle:
                'As an improved functionality, while comparing "Last week" to "Same period last year" we now compare the same fiscal week (Sunday-Saturday) instead of dates in CIQ ESM Product.'
            },
            {
              subtitle:
                "User's current saved views might be affected as the new functionality works on number of weeks instead of number of days. As a result they will need to be saved again."
            }
          ]
        }
      ]
    },
    {
      date: 'August 31, 2022',
      features: [
        {
          title: 'Introducing Intra-day capabilities for 3P Recommendations',
          points: [
            {
              subtitle:
                'As a part of this release we are launching Intraday 3P Recommendations feature and this Intra-Day scraping capability will help us identify exceptions and log tickets upto 4 times a day (at an equal intervals of 6 hours each).'
            },
            {
              subtitle:
                'This will ensure early detection thus actioning of exceptions and maintaining clean PDPs for a larger portion of the day.'
            },
            {
              subtitle:
                'The base intraday capability is offered free of cost to our users and it includes scraping your entire AVC catalog once a day and only the "Active" ASINs (i.e., SKUs having sales within the last 12 months or having a release date in the last 12 months) an additional 3 times a day. Any incremental scrape outside the base capability is subject to additional cost.'
            },
            {
              subtitle:
                'Also, we have ensured that the intraday capabilities will not affect any of your current email subscriptions, bookmarks of 3P variants and has the right impact calculation in Automations and Impact Dashboard.'
            }
          ]
        }
      ]
    },
    {
      date: 'August 19, 2022',
      features: [
        {
          title: 'Introducing Smart filters for CIQ Sales',
          points: [
            {
              subtitle:
                'With the launch of smart filters, we expand the types of filters provided by CommerceIQ Sales platform to three different categories i.e Internal Categorization, Retailer Categorization and the newly added CIQ Categorization.'
            },
            {
              subtitle:
                'This set of Smart Filters (present under CIQ Categorization) will enable you to see data for very specific segments of the business like viewing data for SKUs that are being sold on different amazon programs like Amazon Retail, Amazon Fresh, identifying SKUs that are being offered as part of Subscribe & Save program, viewing SKUs contributing the top 20% of revenue, and much more.'
            },
            {
              subtitle:
                'Smart Filters can be accessed in the very same way we have been using our existing filters in CIQ product i.e at the banner section of the respective page by clicking "Add Filters".'
            }
          ]
        }
      ]
    },
    {
      date: 'August 4, 2022',
      features: [
        {
          title: 'Introducing the new Content Scorecard dashboard',
          points: [
            {
              subtitle:
                'Create your own scoring thresholds. Customize scoring thresholds across various product detail page attributes like Title Length, Number of Images, Number of Bullet Points and many others.'
            },
            {
              subtitle:
                'Quickly identify SKUs with low content scores. Develop scorecards and analyze content scores across the entire assortment or for a particular Business Unit or Category. '
            },
            {
              subtitle:
                'Improve the quality of your PDP content. The dashboard highlights the exact improvement areas and makes suggestions on content that will improve scores.'
            }
          ]
        }
      ]
    },
    {
      date: 'June 9, 2022',
      configToCheck: 'feature.pages.automations_and_impact.enable',
      features: [
        {
          title: 'Introducing Automations and Impact dashboard',
          points: [
            {
              subtitle:
                'The newly launched dashboard provides insights into all the tickets filed by CommerceIQ and their impact on the revenue. We have also revisited the impact computation logic which helps in predicting the impact of the automations done by CommerceIQ. The dashboard helps you by providing:',
              subpoints: [
                {
                  subsubtitle:
                    'Visibility on the tickets created by CommerceIQ and its impact ($) across all automations.'
                },
                {
                  subsubtitle:
                    'Actionability by listing out all the tickets that need intervention from you. This will help in improving the resolution rates of automations.'
                },
                {
                  subsubtitle:
                    'Details and current status of all the tickets created by CommerceIQ.'
                }
              ]
            },
            {
              subtitle:
                'The new dashboard "Automations and Impact" can be accessed from the bottom of the left-hand navigation bar.'
            }
          ]
        }
      ]
    },
    {
      date: 'April 18, 2022',
      configToCheck: 'feature.sourcing_distributor_type.enable',
      features: [
        {
          title:
            'ASINs that previously only appeared in the "Sourcing View" in AVC Brand Analytics will now be available in the product.',
          points: [
            {
              subtitle:
                'Shipped Revenue and Shipped Units metrics will provide you a comprehensive view of your business. Additionally, scraped insights have also been enabled for SKUs that only appear in the Sourcing view.'
            },
            {
              subtitle:
                'The "Sourcing Only" ASINs are enabled in the following parts of the product:',
              subpoints: [
                {
                  subsubtitle: 'Insights & Filters',
                  subsubpoints: [
                    {
                      subsubsubtitle:
                        'Business Overview / Top Movers / Benchmarking SKU'
                    },
                    {
                      subsubsubtitle: 'SKU Detail Page'
                    },
                    {
                      subsubsubtitle: 'SKUs tab'
                    },
                    {
                      subsubsubtitle: 'Internal Catalog Manager'
                    }
                  ]
                },
                {
                  subsubtitle: 'Recommendations -',
                  subsubpoints: [
                    {
                      subsubsubtitle: '3P Variants'
                    },
                    {
                      subsubsubtitle: 'Unavailable SKUs'
                    },
                    {
                      subsubsubtitle: 'Content Change'
                    },
                    {
                      subsubsubtitle: 'Change in Variants'
                    },
                    {
                      subsubsubtitle: 'Lost Buy Box'
                    },
                    {
                      subsubsubtitle: 'Search Entry / Exit'
                    }
                  ]
                }
              ]
            },
            {
              subtitle:
                'Please note that Amazon does not provide the following metrics for Sourcing Only ASINs - Ordered Revenue, Ordered Units, Ordered COGS, Glance Views, Conversion Rate, Rep OOS, LBB (Price), Amazon Forecasts (Mean, P70, P80, P90), Fast Track %, NetPPM and thus these will not be viewable for Sourcing only SKUs.'
            }
          ]
        }
      ]
    },
    {
      date: 'April 13, 2022',
      features: [
        {
          title:
            'New metric Contribution Margin and Contribution Profit are now available on Business Overview, SKUs Details Page and SKUs page.',
          points: [
            {
              subtitle:
                'Contribution Profit (CP) and Contribution Margin (CM) are the true profitability of Amazon after deducting the fulfillment (shipping) cost, storage cost and factoring in Ad dollars spent on a SKU. Availability of these metrics allows you to measure the profitability of your business at any level.'
            },
            {
              subtitle:
                'Contribution Profit (CP) and Contribution Margin (CM) are the true profitability of Amazon after deducting the fulfillment (shipping) cost, storage cost and factoring in Ad dollars spent on a SKU. Availability of these metrics allows you to measure the profitability of your business at any level.'
            },
            {
              subtitle: 'Understanding profitability allows you to:',
              subpoints: [
                {
                  subsubtitle:
                    'Identify the non-profitable ASINs and take steps to improve the profitability of these SKUs.'
                },
                {
                  subsubtitle:
                    'Understand the impact of change of cost and ASP on profitability.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'December 23, 2021',
      features: [
        {
          title:
            'Ticket ID and the resolution status are now available on the recommendation:',
          points: [
            {
              subtitle:
                'These details will help in tracking the resolution status and also indicate if any intervention required.'
            }
          ]
        },
        {
          title:
            'Global SKU search enables you to search for a SKU and directly go to the SKUs details page from anywhere in the product.'
        },
        {
          title: 'Filters for recommendations. The new filters allows you to:',
          points: [
            {
              subtitle:
                'Filter out by different Unavailability type in Unavailable SKUs recommendation.'
            },
            {
              subtitle:
                'Filter out recommendation which "Matches with Source of truth" under Content and Change in Variants recommendations.'
            },
            {
              subtitle: 'Type of discrepancy in PO discrepancy recommendation.'
            }
          ]
        },
        {
          title: 'New date range selection options on Business Overview:',
          points: [
            {
              subtitle:
                'Last 12 months, Last year and last week have been added for quicker access.'
            }
          ]
        },
        {
          title:
            'Ability to bulk select all filters under "Add filters" option.'
        },
        {
          title:
            'Ability to export full ASINs list from top gainers and losers on Business Overview.'
        },
        {
          title:
            'New metric, Fast track % added to Business Overview dashboard.'
        },
        {
          title:
            "New metric 'On-Hand Inventory' has been added to the Sales Forecast dashboard."
        }
      ]
    },
    {
      date: 'September 14, 2021',
      configToCheck: 'feature.filter_v2.enable',
      features: [
        {
          title: 'Self Serve tool to update Internal catalog',
          points: [
            {
              subtitle:
                "Now you can edit your internal catalog in the user interface and update taxonomy values almost immediately.  The launch of this feature will reduce the turnaround time for catalog updates from 48 hours to 45 mins as you don't need to share Excel sheets anymore."
            },
            {
              subtitle: 'This new capability allows you to:',
              subpoints: [
                {
                  subsubtitle:
                    'Upload an Excel within the UI to update the catalog in bulk.'
                },
                {
                  subsubtitle:
                    'Update catalog filters or specific values for a subset of SKUs. '
                }
              ]
            },
            {
              subtitle:
                'It can be found under Tools and Setting → Manage Standard Internal Catalog on the left navigation bar.'
            }
          ]
        },
        {
          title: 'New metrics added to the SKUs details page',
          points: [
            {
              subtitle:
                '5 new metrics, Shipped Revenue, Units Shipped, Ordered COGS, Gross Margin, Gross Margin% have been added to the SKUs details page and Chrome plugin.'
            }
          ]
        },
        {
          title: 'Lost buy box data within the SKUs dashboard',
          points: [
            {
              subtitle:
                'The metrics "Number of days Lost Buy Box", "LBB%", "Revenue lost due to Lost Buy Box" have been added under the SKUs → Operations tab.'
            }
          ]
        }
      ]
    },
    {
      date: 'July 6, 2021',
      configToCheck: 'feature.fresh.enable',
      features: [
        {
          title:
            'CommerceIQ expands its reporting and tracking capabilities to Amazon Fresh as part of CIQ Data suite',
          points: [
            {
              subtitle:
                'All the key metrics related to Fresh tied in one place to drive insights into trends, top movers and Benchmark SKUs.'
            },
            {
              subtitle:
                'One of a kind Regional availability dashboard to track the top ASINs across major zip codes mapped around Amazon FCs.'
            }
          ]
        },
        {
          title: 'Key enhancements which are part of this release are:',
          points: [
            {
              subtitle:
                'Business Overview dashboard providing trends and outliers across a set of predefined metrics for all our customers.'
            },
            {
              subtitle:
                'Regional Availability dashboard - Tabular view of availability status the Top ASINs contributing to over 50% of the revenue spread across the key zip codes (which are mapped around Amazon FCs) driving major sales for the customers.'
            }
          ]
        }
      ]
    },
    {
      version: '4.7.0',
      date: 'June 24, 2021',
      features: [
        {
          title:
            'We have launched a new interface. The goal with this release is twofold:',
          points: [
            {
              subtitle:
                'Make it easy for you to look for specific screens and personalize the experience.'
            },
            {
              subtitle:
                'Add more features in the future without compromising on ease of use.'
            }
          ]
        },
        {
          title: 'Key enhancements which are part of this release are:',
          points: [
            {
              subtitle:
                'Easy access to features: The main navigation is moving from the top of the screen to the left.'
            },
            {
              subtitle:
                'Logical grouping: Features are being grouped together based on themes like Digital Shelf, Supply Chain, Advertising, Assortment to align with how your teams operate.'
            },
            {
              subtitle:
                "Personalize the software: We have added a section 'My Workspace' which provides quick access to all the starred pages."
            },
            {
              subtitle:
                "Reports: All other reports will reside under the 'Reports' tab."
            }
          ]
        }
      ]
    },
    {
      version: '4.6.0',
      date: 'June 16, 2021',
      features: [
        {
          title:
            'We have made changes to the following metrics to improve their accuracy',
          points: [
            {
              subtitle:
                'Unavailability %: We are introducing a new metric, Unavailability %, which aims at improving the accuracy of determining when an ASIN was not available for sale. Unavailability % uses Rep OOS% from AVC and Market Intelligence data to determine the percentage of glance views when an ASIN was not available for sale.'
            },
            {
              subtitle:
                'Revenue lost due to unavailability: The metric, unavailability % will be used to calculate the revenue lost due to SKUs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC. Change in methodology aims at improving the accuracy of determining the revenue lost due to unavailability.'
            },
            {
              subtitle:
                'Rep OOS% : Rep OOS % metric will now report the exact values as reported by AVC. We will not be doing any imputation on the Rep OOS%.'
            }
          ]
        }
      ]
    },
    {
      version: '4.5.0',
      date: 'May 12, 2021',
      features: [
        {
          title:
            'We have improved the calculation logic for revenue lost due to unavailability and lost buy box to make them more accurate.',
          points: [
            {
              subtitle:
                'This will cause the revenue lost numbers to change as the new logic relies more on daily Rep OOS and LBB% numbers from AVC. Details on the changes are as follows:',
              subpoints: [
                {
                  subsubtitle:
                    'Revenue lost due to Unavailability: We have removed the dependency on the market intelligence data (i.e. availability status on PDP) and are now using the Rep OOS% metric from AVC. Daily revenue lost at an ASIN level is calculated using Rep OOS %, glance view, conversion rate and average selling price from AVC and then aggregated.'
                },
                {
                  subsubtitle:
                    'Revenue lost due to lost buy box: We have removed the dependency on the market intelligence data (i.e. Buybox status on PDP) and are now using the LBB% metrics from AVC. Daily revenue lost at an ASIN level is calculated using the LBB%, Glance views, Conversion rate and average selling price from AVC and then aggregated.'
                },
                {
                  subsubtitle:
                    'New metric LBB% added to the Business Overview: We have added LBB% to Business Overview dashboard as reported by AVC.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      version: '4.3.0',
      date: 'March 4, 2021',
      features: [
        {
          title:
            'Addition of shipment information to the Shipment tracker dashboard',
          configToCheck: 'feature.insights.shipmenttracker.enable',
          points: [
            {
              subtitle:
                'Users can now track the shipment details of all the POs as present in the Shipment tracker report on AVC. Shipment details like the ASN, ARN, carrier, PRO # details are mapped against the PO numbers. Please get in touch with your EMs to get access to the Shipment tracker.'
            }
          ]
        },
        {
          title: 'Addition of Weeks of Cover as reported by AVC',
          points: [
            {
              subtitle:
                'Weeks of Cover data has been added to the product as reported by AVC. This metric has been added to the following places.',
              subpoints: [
                {
                  subsubtitle: 'SKU details page'
                },
                {
                  subsubtitle: 'Unavailable recommendations'
                },
                {
                  subsubtitle: 'SKU workbench'
                }
              ]
            }
          ]
        },
        {
          title: 'Revenue lost due to unavailability in the SKU workbench',
          points: [
            {
              subtitle:
                'For a selected date range, the user can now see the revenue lost against each SKU on the SKU workbench. This metrics is available in SKU --> Operations.'
            }
          ]
        },
        {
          title: 'Filter invalidation on Business Overview dashboard',
          points: [
            {
              subtitle:
                'Users will be informed in case a filter has been removed from the internal catalog but is part of a saved view. The filter will be removed from the view and the user can save the new view with the updated filters.'
            }
          ]
        },
        {
          title: 'Addition of rep OOS% on Business Overview dashboard',
          points: [
            {
              subtitle:
                'Users will now be able to plot and analyze the Rep OOS% metrics across their catalog on the Business Overview dashboard. This metric can be found under the operations tab in the list of metrics available.'
            }
          ]
        }
      ]
    },
    {
      date: 'December 21, 2020',
      features: [
        {
          title: 'Introducing Business Overview dashboard',
          points: [
            {
              subtitle:
                'Business Overview dashboard enables you to look at trendlines and correlation across 50+ sales, operations and advertising* (if applicable) metrics and understand drivers of performance. It also helps you compare metrics across different business lines and save reusable reports as "Views". It allows you to:',
              subpoints: [
                {
                  subsubtitle:
                    'View trendlines and understand correlations across 20+ sales, operations metrics and 30+ advertising* metrics.'
                },
                {
                  subsubtitle:
                    'The dashboard helps you understand the top drivers of performance by surfacing the top movers across any metrics. It also helps connect SKU level performance to recommendations.'
                },
                {
                  subsubtitle:
                    'Compare performance for multiple business lines or brands for any of the metrics both in a graphical and tabular format.'
                },
                {
                  subsubtitle:
                    'Save the combination of your favorite metrics and drill-downs as "views" and access them in one click.'
                },
                {
                  subsubtitle:
                    'Download the reports as high fidelity PDF for collaboration and offline review purposes.'
                },
                {
                  subsubtitle:
                    'Advertising metrics are available only if you have a subscription to CommerceIQ advertising.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'September 28, 2020',
      configToCheck: 'feature.merge_unavailable_recommendation.enable',
      features: [
        {
          title: 'Actionability at ad group level in Campaign Management',
          points: [
            {
              subtitle:
                'We now show ad group information in Keywords, Search Terms and AMS SKUs tabs within Campaign Management.'
            },
            {
              subtitle:
                'We have also enabled actionability at the ad group level from all 4 tabs within Campaign Management.'
            }
          ]
        },
        {
          title:
            'Merging of Out of Stock/Suppressed/Unavailable Recommendations',
          points: [
            {
              subtitle:
                'The 3 recommendation types have been merged into one to make ASIN availability information easily accessible and consolidated. The new merged recommendation can be found under Recommendations → Operations → Unavailable SKUs. Corresponding changes have been made to other parts of the product:',
              subpoints: [
                {
                  subsubtitle:
                    'Both "Sales overview" and "Financials" Insights now have just 1 card for “Revenue Lost due to Unavailable” which includes revenue lost numbers for all SKUs which are not available for sale, i.e. - Out of Stock, Unavailable, and Suppressed.'
                },
                {
                  subsubtitle:
                    'CommerceIQ Insights Chrome plugin and SKU Details Pages now have a single event called “Unavailable” which will show the days when the SKU was not available for sale.'
                },
                {
                  subsubtitle:
                    'SKU workbench now has a single column which shows the number of days when the ASIN was not available for sale.'
                }
              ]
            }
          ]
        },
        {
          title: 'Addition of new metric "Net PPM"',
          points: [
            {
              subtitle:
                'Net PPM metric has been added to SKU Detail Page and the Chrome Plugin. Weekly and monthly Net PPM trends can now be plotted for any ASIN. Within the SKUs tab, 2 new columns have been added under "sales and margin" which show last week\'s and last month\'s Net PPM values. The source of this data is the "Net PPM" report within the Amazon Brand Analytics report.'
            }
          ]
        }
      ]
    },
    {
      date: 'May 20, 2020',
      features: [
        {
          title:
            'Our official Chrome extension "CommerceIQ Insights" is available on Amazon Detail Page',
          points: [
            {
              subtitle:
                'We have launched our official Chrome extension "CommerceIQ Insights" to allow you to view important performance metrics for any of your 1P SKUs on the Amazon detail page (PDP).'
            },
            {
              subtitle:
                'You can view trendlines of metrics like revenue, glance views, conversion, AMS spend and ad attributed sales along with events like out of stock, change in content and more to understand the performance of a SKU.'
            },
            {
              subtitle: 'Steps to Install:',
              subpoints: [
                {
                  subsubtitle: `Click <a class="u-color-blue-base" href="${releaseNotes.getExtensionDownloadUrl()}" target="_blank"> here </a> to install on your browser from Chrome Webstore`,
                  disableSearch: true
                },
                {
                  subsubtitle:
                    'Authenticate yourself by logging in with the same login ID and password that you use to login to CommerceIQ.'
                },
                {
                  subsubtitle:
                    'Click on the "IQ" icon on your 1P ASIN pages on Amazon to view the metrics.'
                }
              ]
            }
          ]
        },
        {
          title: 'SKU Insights',
          points: [
            {
              subtitle:
                'You will be able to view the SKU performance metrics within the product through a SKU Insights page. Click on the SKU name within the SKU Details column on any table in the product, to view the performance metrics for that SKU.'
            }
          ]
        }
      ]
    },
    {
      date: 'January 29, 2020',
      features: [
        {
          title: 'Enhancement to Predicted CRaP recommendation:',
          configToCheck: 'releaseNotes.predicted_crap.enable',
          points: [
            {
              subtitle:
                'We have enhanced the recommendation to predict CRaP SKUs based on machine learning models. The machine learning model uses historical trends of ASINs which were crapped out and learns which metrics can be used to predict a future CRaP ASIN. The trends being learned are based on data from sales, inventory, PO, PPM and price competitiveness.'
            },
            {
              subtitle:
                'We have discontinued the detail page on this recommendation.'
            }
          ]
        },
        {
          title: 'Enhancement to Forecast Dashboard.',
          points: [
            {
              subtitle:
                'The following enhancements are now available on the forecast dashboard:',
              subpoints: [
                {
                  subsubtitle:
                    "Calendar: The following options are now available in the comparison period in the calendar: 'Previous Period' and 'Same Period Last Year'"
                },
                {
                  subsubtitle:
                    "Past Performance Graph now allows you to compare 'Forecast', 'Consumption' and 'Purchase orders' on the same graph"
                },
                {
                  subsubtitle:
                    "Accuracy metrics for 'Forecast versus Consumption' and 'Forecast versus Purchase Orders' are now available in the SKU Table."
                },
                {
                  subsubtitle:
                    'The SKU level table is now sortable by any column'
                },
                {
                  subsubtitle:
                    "The settings within the forecast dashboard no longer has the option to choose whether Forecast should be compared with 'Consumption' or 'Purchase Orders'."
                }
              ]
            }
          ]
        },
        {
          title: 'New Recommendation Type:',
          points: [
            {
              subtitle:
                'Change in Variants (under Sales): This new recommendation type flags parent ASINs that have had a change in variants.'
            }
          ],
          configToCheck: 'releaseNotes.change_in_variants.enable'
        },
        {
          title: 'Cached link added to SKU Tables.',
          points: [
            {
              subtitle:
                'You can now view a snapshot of how the product detail page looked when CommerceIQ Market Intelligence Data was collected. This link is available on all the tables within the SKU Section.'
            }
          ]
        },
        {
          title:
            'Direct Fulfillment Inventory Details available on CIQ: Direct Fulfillment Inventory information is now available on CIQ. It can be found in the following places in the product:',
          configToCheck: 'feature.direct_fulfilment.enable',
          points: [
            {
              subtitle:
                "SKU Section: Within the 'All' and 'Operations' sections"
            },
            {
              subtitle: 'Recommendations:',
              subpoints: [
                {
                  subsubtitle: 'Unavailable SKUs recommendation'
                },
                {
                  subsubtitle: 'OOS SKUs recommendation'
                },
                {
                  subsubtitle: 'Predicted OOS recommendation'
                },
                {
                  subsubtitle: 'Sales Drop recommendation'
                },
                {
                  subsubtitle: 'Predicted CRaP recommendation'
                },
                {
                  subsubtitle:
                    "Suppressed ASINs recommendation - We have also added a column for 'Current Units in Hand' to this recommendation"
                },
                {
                  subsubtitle: 'Lost Buy Box recommendation'
                },
                {
                  subsubtitle: 'Sales Increase recommendation'
                },
                {
                  subsubtitle: 'Change in Amazon Forecast recommendation'
                }
              ]
            },
            {
              subtitle: 'Insights:',
              subpoints: [
                {
                  subsubtitle: 'The Forecast Dashboard - SKU level Table'
                },
                {
                  subsubtitle:
                    "Sales Overview: 'Revenue Lost due to Unavailable SKUs' and 'Revenue lost due to Lost Buy Box' tables"
                }
              ]
            }
          ]
        },
        {
          title: 'Enhancement to Predicted OOS SKUs recommendation.',
          points: [
            {
              subtitle:
                'We have done the following enhancements to the Predicted OOS SKUs recommendation:',
              subpoints: [
                {
                  subsubtitle:
                    'We have reordered the columns present the most relevant information first.'
                },
                {
                  subsubtitle:
                    "We have renamed the column 'Open PO Quantity' to 'Open PO Quantity within Fulfillment Window'. This gives the number of confirmed open POs that have their expected delivery date set within the fulfillment window."
                },
                {
                  subsubtitle:
                    "We have added a new column called 'Open PO quantity as per inventory'. This gives the total number of confirmed open POs available as per the PO report."
                }
              ]
            }
          ]
        },
        {
          title:
            'Enhancement to Change in Content Recommendation: In addition to reporting if content on Amazon has changed when compared to the previous day, we will now also start reporting if the content on Amazon has changed compared to the content on the source of truth. We have added the following columns:',
          configToCheck: 'releaseNotes.content_change.enable',
          points: [
            {
              subtitle:
                'Deviation from expected value: This column indicates if the current value on Amazon has changed compared to the source of truth.'
            },
            {
              subtitle:
                'Expected Value: This displays the value from the source of truth.'
            }
          ]
        }
      ]
    },
    {
      date: 'December 03, 2019',
      features: [
        {
          title: 'Enhancement to Insights > Forecast dashboard:',
          points: [
            {
              subtitle:
                'A new version of the forecast dashboard is now available. We have added the following functionality to this new dashboard: Settings and date selector on top of the page to configure the dashboard.The following information is now available'
            }
          ]
        },
        {
          title: 'Enhancement to Predicted OOS Recommendation:',
          points: [
            {
              subtitle:
                'Predicted OOS recommendation will now ignore spike in sales due to events like Prime Day and use the sales velocity outside of these events to recommend SKUs that are most likely to go OOS.'
            }
          ]
        },
        {
          title: 'Enhancement to Column Configuration in Recommendations:',
          points: [
            {
              subtitle:
                'The column configuration within recommendations has been enhanced to save the changes for all users.'
            }
          ]
        },
        {
          title: 'Enhancement to Filters:',
          points: [
            {
              subtitle:
                "'Back' option: 'Add Filter' now has a 'Back' option to let you set multiple filters in a single go."
            }
          ]
        },
        {
          title: 'Insights > Price War: Quick link to Price Trend Graph:',
          points: [
            {
              subtitle:
                "The leftmost column in the 'SKUs under Price War' table has been replaced by a 'Plot Graph' within the SKU Details Section. When hovering over the SKU Details Section, the 'Plot Graph' appears and clicking on it will display the Price trend for the SKU."
            }
          ]
        },
        {
          title: 'Insights > PO Fill Rate: Quick Link to PO Details:',
          points: [
            {
              subtitle:
                "The leftmost column in the 'SKU level Summary' table has been replaced by 'More Details' within the SKU Details Section. When hovering over the SKU Details Section, the 'More Details' appears and clicking on it will display the PO Details for the SKU"
            }
          ]
        }
      ]
    },
    {
      date: 'November 11, 2019',
      configToCheck: 'feature.change_in_variant.enable',
      features: [
        {
          title: 'New Recommendation Type: Change in Variants (under Sales):',
          points: [
            {
              subtitle:
                'This new recommendation type flags parent ASINs that have had a change in variants.'
            }
          ]
        }
      ]
    },
    {
      date: 'October 24, 2019',
      features: [
        {
          title:
            "Enhancement to Content Change Recommendation. We have added the following additional columns to the 'Content Change' recommendation:",
          points: [
            {
              subtitle:
                'Average daily unit conversion rate before content change'
            },
            {
              subtitle: 'Change in daily unit conversion rate'
            },
            {
              subtitle: 'Average daily glance views before content change'
            },
            {
              subtitle: 'Change in daily glance views'
            },
            {
              subtitle: 'Average daily ordered units before content change'
            },
            {
              subtitle: 'Change in daily ordered units'
            },
            {
              subtitle: 'Average daily offer price before content change'
            },
            {
              subtitle: 'Change in daily offer price'
            },
            {
              subtitle: 'Revenue lost due to OOS before content change'
            },
            {
              subtitle: 'Change in revenue lost due to OOS'
            },
            {
              subtitle: 'Revenue lost due to Lost Buy Box before content change'
            },
            {
              subtitle: 'Change in revenue lost due to Lost Buy Box'
            },
            {
              subtitle: 'Previous SnS status'
            },
            {
              subtitle: 'Current SnS status'
            }
          ]
        },
        {
          title:
            'Enhancement to PO Discrepancy Recommendation. We have made the following changes to the discrepancy type "Replenishment Code":',
          points: [
            {
              subtitle:
                "The type 'Replenishment Code' has been renamed as 'Availability'"
            },
            {
              subtitle:
                'Current value column will display "PO found for unavailable SKU"'
            },
            {
              subtitle:
                'Expected value column will display "No PO expected for SKU"'
            }
          ]
        },
        {
          title: "Removed count from 'SKU Detail Section' in recommendation:",
          points: [
            {
              subtitle:
                "We have removed count within the 'SKU Detail Section' in recommendations."
            }
          ]
        }
      ]
    },
    {
      date: 'September 26, 2019',
      features: [
        {
          title:
            "New recommendation type: Duplicate Listing (Under Sales): &nbsp; A new recommendation called 'Duplicate Listing' has been added under Sales Tab. It Identifies SKUs which are listed by 3P sellers and are duplicates of 1P SKUs.",
          configToCheck: 'feature.duplicate_listing.enable'
        },
        {
          title: 'Enhancement to Change in Amazon Program Recommendation:',
          points: [
            {
              subtitle:
                "For all Amazon programs we track, this recommendation will now start flagging both 'added to' and 'dropped from'. The following are the new events tracked:",
              subpoints: [
                {
                  subtitle: 'SKU is dropped from Prime Exclusive, Add-on'
                },
                {
                  subtitle: 'SKU becomes Subscribe and Save'
                },
                {
                  subtitle: 'SKU adds Bestseller and Amazon choice badges'
                }
              ]
            }
          ]
        },
        {
          title: 'Enhancement to SKU > Sales & Margin Tab:',
          points: [
            {
              subtitle:
                "Four new columns have been added under 'Sales & Margin' and 'All' tabs within the SKU section:",
              subpoints: [
                {
                  subsubtitle:
                    'Number of Days Unavailable: This column will display the number of days the SKU was unavailable in the chosen time range.'
                },
                {
                  subsubtitle:
                    'Number of Days Suppressed: This column will display the number of days the SKU was suppressed in the chosen time range.'
                },
                {
                  subsubtitle:
                    'Number of Days OOS: This column will display the number of days the SKU was OOS in the chosen time range.'
                },
                {
                  subsubtitle:
                    'Number of Days Lost Buybox: This column will display the number of days the SKU lost Buybox in the chosen time range.'
                }
              ]
            }
          ]
        },
        {
          title: 'Enhancement to Insights > Sales Overview section',
          points: [
            {
              subtitle:
                'The following have been added to Sales Overview section within Insights:',
              subpoints: [
                {
                  subsubtitle:
                    "The top tiles now include 'Glance View' for the chosen time period."
                },
                {
                  subsubtitle:
                    "'Average Selling Price' is available within a drop-down on the tiles and can be displayed on any of the top tiles."
                },
                {
                  subsubtitle:
                    "A 'Glance Views' table has been added and will display the top 10 SKUs sorted by glance view. "
                }
              ]
            }
          ]
        },
        {
          title: 'Enhancement to SKU Table > Search & BSR Tab:',
          points: [
            {
              subtitle:
                "We have added a new column to the 'Search & BSR' and 'All' tabs within the SKU section:",
              subpoints: [
                {
                  subsubtitle:
                    'Number of BSR Categories: This column will list the number of BSR categories the SKU is listed in.'
                }
              ]
            }
          ]
        },
        {
          title:
            'New column titles. The following metrics have been renamed on the SKU tab:',
          points: [
            {
              subtitle: "'Customer Orders' is now called 'Ordered Units'"
            },
            {
              subtitle: "'Conversion' is now called 'Unit Conversion %'"
            }
          ]
        },
        {
          title: 'Discontinuing four recommendations:',
          points: [
            {
              subtitle:
                'To make the most relevant recommendations more accessible, we are discontinuing four recommendations. Discontinued recommendations:',
              subpoints: [
                {
                  subsubtitle: '3P Organic Growth'
                },
                {
                  subsubtitle: 'Organic Growth'
                },
                {
                  subsubtitle: 'New Competitor Variants'
                },
                {
                  subsubtitle:
                    "BSR Category Change (Even though the recommendation is disabled, BSR category and rank information for any SKU can be found within 'SKU > Search & BSR section')"
                },
                {
                  subsubtitle:
                    'Have questions about this? Please contact your Engagement Manager or send us an email on <a class="u-color-blue-base" mailto=\'support@commerceiq.ai\'>support@commerceiq.ai</a>',
                  disableSearch: true
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'August 28, 2019',
      features: [
        {
          title:
            'Email subscription to recommendations. Capability to subscribe to recommendations is now available.',
          points: [
            {
              subtitle:
                'Subscription icon has been added to every recommendation page. Clicking on it will subscribe you to that particular recommendation with the filters set at the time of subscription.'
            },
            {
              subtitle:
                'Manage subscription page has been added to let you review and configure subscription to all recommendations. Link to this page is available in the user menu and within the subscription icon on every recommendation page.'
            },
            {
              subtitle:
                'Manage Subscription page also allows you to set filters and choose the columns for any recommendation you have subscribed to. You can also set the order in which the recommendations should appear in the subscription email.'
            }
          ]
        },
        {
          title:
            "Glance Views and Conversion data available in SKU Table. We have added two new columns to 'Sales and Margin' and 'All' sections of the SKU tab:",
          points: [
            {
              subtitle:
                'Glance Views: Absolute value of the glance view a SKU received in the chosen period.'
            },
            {
              subtitle:
                'Conversion: Absolute value of the conversion a SKU received in the chosen period.'
            }
          ]
        },
        {
          title: 'Enhancement to date range selectors',
          points: [
            {
              subtitle:
                'We have unified the date range selectors within SKU and Insights to have the same options.',
              subpoints: [
                {
                  subsubtitle:
                    "'Year to Date' and 'Month to Date' have been added as new options to the current period selector."
                },
                {
                  subsubtitle:
                    'We have also removed date options that were not widely used.'
                }
              ]
            }
          ]
        },
        {
          title: 'Information on videos available in SKU Table',
          points: [
            {
              subtitle:
                "We have added a new column called 'Videos' in the 'Content' and 'All' sections of SKU tab. Videos column indicates whether a video is present in the image block of the detail page for a SKU."
            }
          ]
        },
        {
          title:
            'Enhancement to Sales Increase and Sales Drop recommendations.',
          points: [
            {
              subtitle:
                'Sales Increase and Sales Drop recommendations will now take seasonality into consideration and only recommend SKUs that have significant drop in Ordered Product Sales inspite of seasonality.'
            }
          ]
        }
      ]
    },
    {
      date: 'August 1, 2019',
      features: [
        {
          title: 'Enhancement to OOS and Unavailable SKUs recommendations',
          points: [
            {
              subtitle:
                'We have added the following fields to the OOS and Unavailable SKUs recommendations:',
              subpoints: [
                {
                  subsubtitle:
                    'Last PO date: The date on which the last purchase order was received for the SKU.'
                },
                {
                  subsubtitle:
                    'Last PO number: The last purchase order number for the SKU.'
                }
              ]
            }
          ]
        },
        {
          title: 'Enhancement to Filters',
          points: [
            {
              subtitle:
                'Filters have been enhanced to show the selected values (either partially or fully based on the length of the selection).'
            }
          ]
        }
      ]
    },
    {
      date: 'June 26, 2019',
      features: [
        {
          title: 'New CommerceIQ URL:',
          points: [
            {
              subtitle:
                'We have rebranded as CommerceIQ.  The login URL has been changed to <span class="u-color-blue-base">' +
                window.location.origin +
                '</span>. Please bookmark this URL for easy access. This will not have any impact on your login or software experience. Everything stays the same except for the URL.',
              disableSearch: true
            }
          ]
        },
        {
          title:
            'New recommendation type: Independent 3P Listings (under Sales)',
          points: [
            {
              subtitle:
                "This new recommendation type flags all 3P Amazon Listings that are not part of your catalogue but contain products with your brand name and being sold by 3P Sellers. This will replace the 'New variant detected' recommendation which was available under Marketing."
            }
          ]
        },
        {
          title: 'Cached link on recommendations',
          points: [
            {
              subtitle:
                'You can now view a snapshot of how the product detail page looked when CommerceIQ Market Intelligence Data was collected. This link is available on the following recommendations:',
              subpoints: [
                {
                  subsubtitle: 'Suppressed ASINS'
                },
                {
                  subsubtitle: 'Unavailable SKUs'
                },
                {
                  subsubtitle: 'Lost Buy Box'
                },
                {
                  subsubtitle: 'OOS SKUs'
                }
              ]
            }
          ]
        },
        {
          title: 'CIQ Market Intelligence date for ASINs',
          points: [
            {
              subtitle:
                "On the SKUs 'All' section, you can now view the date of CIQ Market Intelligence update for every ASIN. This will help you identify if an ASIN information is up to date."
            }
          ]
        }
      ]
    },
    {
      date: 'June 13, 2019',
      features: [
        {
          title: 'Enhancement to the Change in Amazon Program Recommendation',
          points: [
            {
              subtitle:
                'This recommendation will now start flagging two new Amazon Programs: "Amazon\'s Choice" and "Bestseller". You will be able to track if any of your products are dropped from these.'
            }
          ]
        },
        {
          title:
            'SKU Image and the capability to copy URL to clipboard available on SKU page:',
          points: [
            {
              subtitle:
                'The SKU image and the capability to easily copy Amazon URL to clipboard are now available on the SKU page.'
            }
          ]
        },
        {
          title: 'Capability to open CommerceIQ links in a New Tab',
          points: [
            {
              subtitle: `You can now open CommerceIQ links in a new tab. Recommendations/Insights/SKU${
                releaseNotes.checkIfAdvertisingSubscribed()
                  ? '/Campaign Management'
                  : ''
              } and any link that redirects you to a new page can now be opened in a new tab.`,
              subpoints: [
                {
                  subtitle: 'On Mac: Press command and click on the link.'
                },
                {
                  subtitle: 'On Windows: Press Ctrl and click on the link.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'April 22, 2019',
      features: [
        {
          title: 'Recommendation Status',
          points: [
            {
              subtitle:
                'Every recommendation has an action status which is the default value of "OPEN". It can manually be changed to "Approved" or "Action Taken".',
              subpoints: [
                {
                  subsubtitle:
                    'Status can be used by teams to keep track of no. of actions being taken.'
                },
                {
                  subsubtitle:
                    '"Approved" means you have reviewed and deserves to be acted upon. "Action Taken" means that the next step has been taken by you or a team mate.'
                },
                {
                  subsubtitle:
                    'Status can be changed for each recommendation individually or in bulk by selecting more than 1.'
                }
              ]
            }
          ]
        },
        {
          title: 'Price Elasticity',
          points: [
            {
              subtitle:
                'There are 2 new columns introduced within SKUs section under the Price and Promotions Tab.'
            },
            {
              subtitle:
                'Elasticity: This is the price elasticity no. based on last 90 days of price and demand change data on Amazon. The nos. Are typically negative because of inverse relationship between price changes and demand changes. Greater the absolute no., more elastic the SKU is. Please note that all SKUs will not have elasticity values. Only those which have shown sufficient no. of price changes will have the values.'
            },
            {
              subtitle:
                "Confidence Band: Confidence band tells you the machine learning model's confidence in the elasticity values. A high confidence means large no. of price and corresponding demand changes in last 90 days leading to robust calculation."
            },
            {
              subtitle:
                'How to use price elasticity? The best use of this data is to drive your promotions strategy not only on Amazon but across channels. Promotions on highly elastic SKUs deliver better ROI.'
            }
          ]
        },
        {
          title: 'New recommendation Type: PO Discrepancy (Under Operations)',
          points: [
            {
              subtitle:
                'This new recommendation type flags all Purchase Orders which have discrepancy in any of the 4:',
              subpoints: [
                {
                  subsubtitle: 'Case size'
                },
                {
                  subsubtitle: 'Case cost'
                },
                {
                  subsubtitle: 'SKU ID'
                },
                {
                  subsubtitle: 'Availability'
                }
              ]
            },
            {
              subtitle: 'How to opt in?',
              subpoints: [
                {
                  subsubtitle:
                    'Please send a file (CVS or Excel) containing internal source of truth to <a href="mailto:product_feedback@boomerangcommerce.com" target="_blank">product_feedback@boomerangcommerce.com</a> with the following format: ASIN | SKU ID | Availability | Case cost | Case Size',
                  disableSearch: true
                },
                {
                  subsubtitle:
                    'Contact your Engagement Manager for more details.'
                }
              ]
            }
          ]
        },
        {
          title: 'New recommendation Type: Suppressed ASINs (Under Sales)',
          points: [
            {
              subtitle:
                'This new recommendation type flags those ASINs which show you 404 error codes or dog images on Amazon.com'
            },
            {
              subtitle: 'Generally available to all customers by default.'
            }
          ]
        },
        {
          title: 'Data Workbench tab has been renamed as "SKUs".',
          points: [
            {
              subtitle:
                'The change signifies status of this tab as the single source of truth for every SKU being sold on Amazon.'
            }
          ]
        }
      ]
    },
    {
      date: 'March 28, 2019',
      features: [
        {
          title: 'Easy Year over Year analysis in Sales Overview',
          points: [
            {
              subtitle:
                "The date range selector, under 'previous period' now provides the option to select 'Same Period Last Year' for quick and easy YoY analysis. Please note that CommerceIQ may not have many data points like OOS, Unavailable etc for last year hence YoY analysis should only be done for financial metrics like OPS and PCOGS."
            }
          ]
        },
        {
          title: 'Premium A+ Content',
          configToCheck: 'feature.hardline.enable',
          points: [
            {
              subtitle:
                "Workbench: New column 'Premium A+ Content' in the Workbench section with Y/N values. It can be used to audit SKUs which have this content type."
            },
            {
              subtitle: 'Insights > Content:',
              subpoints: [
                {
                  subsubtitle:
                    'Top tiles now include a new metric for Number of Premium A+ Content- this will show you the number of listings in your current catalogue that have the A+ Premium Content type.'
                },
                {
                  subsubtitle:
                    "SKU Count with Potential Content Issues: Addition of new column 'SKUs Without Premium A+ Content'."
                },
                {
                  subsubtitle:
                    "SKU Level Analysis: New column 'Premium A+ Content' with Y/N values."
                }
              ]
            }
          ]
        },
        {
          title:
            'Due to Amazon displaying availability for Pantry and Fresh SKUs by zip code only,we are now excluding Pantry and Fresh items in the OOS and Unavailable Recommendations.',
          points: [
            {
              subtitle:
                'The revenue lost calculations under Insights widgets > Sales Overview and Financial will also no longer include Pantry and Fresh items. These changes will ensure those recommendations and revenue lost calculations are more accurate.'
            }
          ]
        }
      ]
    },
    {
      date: 'March 6, 2019',
      features: [
        {
          title: 'New Content Columns in the Workbench Section',
          points: [
            {
              subtitle:
                'A+ Content, Number of Images, Number of Bullets, Important Information'
            }
          ]
        },
        {
          title: 'New Content Columns in the Insights Content Widget'
        },
        {
          title: 'Enhancement to the Insights Price War widget',
          points: [
            {
              subtitle:
                'Customize the Price War Index as page level setting. The default PWI that flags items as under a price war is PWI >= 0.5. This setting can now be customized according to your own definition of a price war using a scale of 0 to 1 on the Price War Index.'
            }
          ]
        },
        {
          title: 'Content Change Recommendation',
          points: [
            {
              subtitle:
                "See the specific content changes made when toggling between 'previous value' and 'current value' to quickly identify what was changed, added, or removed"
            }
          ]
        },
        {
          title: 'New recommendation title',
          points: [
            {
              subtitle: "Price Compression is now titled 'Predicted CRaP'"
            }
          ]
        },
        {
          title: 'All SKUs Recommendation tab',
          points: [
            {
              subtitle:
                "Selecting a chip for a given ASIN under the 'Recommendation' column will transition you to that particular recommendation list and highlight the specific item within that recommendation."
            }
          ]
        },
        {
          title: 'Date Range Selector',
          points: [
            {
              subtitle:
                "Info statement within the date range picker in Insights Sales Overview widget will alert you when you've reached the end of the available data set."
            }
          ]
        }
      ]
    },
    {
      date: 'December 10, 2018',
      features: [
        {
          title: 'Reorganization of recommendations:',
          points: [
            {
              subtitle:
                'Recommendations are now categorized into sales, marketing and operations for ease of navigation and to align with different functional areas. ',
              subpoints: [
                {
                  subsubtitle:
                    'All of revenue change recommendations are under “Sales” tab.'
                },
                {
                  subsubtitle:
                    'Search and competitor related recommendations are now under “Marketing” tab.'
                },
                {
                  subsubtitle:
                    'All the supply chain related recommendations like OOS, Unavailable etc are now under “Operations” tab.'
                },
                {
                  subsubtitle:
                    '“All” tab is still available to look at consolidated and prioritized list of recommendations.'
                },
                {
                  subsubtitle:
                    'Definitions: Definition and logic for all recommendations can now be found on the top.'
                },
                {
                  subsubtitle:
                    'Search functionality: Searching for a SKU is now much more intuitive and faster. Search bar location is now next to definitions. Specific recommendation names can be searched inside the left navigation panel.'
                },
                {
                  subsubtitle:
                    'Actions: All possible actions are now grouped together on the right top corner which includes download, column management and removed recommendations. '
                }
              ]
            }
          ]
        },
        {
          title: 'Better visualization of tables:',
          points: [
            {
              subtitle:
                'Recommendations table is used to consume and interpret a lot of data. We have revamped the experience to make it more intuitive.'
            }
          ]
        },
        {
          title: 'New header:',
          points: [
            {
              subtitle:
                'We have updated the top header which now boasts of a blue bar. Contrast has been used for better readability.'
            }
          ]
        }
      ]
    }
  ];

  const ciqAdvertisingReleases = [
    {
      date: 'December 21, 2023',
      features: [
        {
          title: 'Citrus (Target) Advanced Reporting',
          points: [
            {
              subtitle:
                'In addition to the availability of advanced reporting metrics in Campaign Management & Reporting, these metrics are now available for use within Campaign Optimizer & Market Insights.'
            },
            {
              subtitle: 'What is supported in this release?',
              subpoints: [
                {
                  subsubtitle:
                    'Campaign Optimizer : Leverage advanced reporting metrics for precise campaign optimization. Utilize advanced insights to refine advertising strategies and enhance overall campaign performance efficiently.'
                },
                {
                  subsubtitle:
                    'Market Insights : Track advanced reporting metrics in Market Insights to correlate Share of Voice (SoV) changes with Spend and Ad Return adjustments.'
                }
              ]
            }
          ]
        },
        {
          title: '2024 Budget Plan Upload',
          points: [
            {
              subtitle:
                'Seamlessly plan your 2024 budgets in advance now in budget optimizer. Navigate to budget optimizer, click on ‘Edit Budget Plan’ and select the 2024 budget plan option to upload plan for the new year in advance.'
            }
          ]
        },
        {
          title:
            'Fixing keyword-type level budget distribution in Media Planner',
          points: [
            {
              subtitle:
                'Budget distribution at keyword type level is now mandatory. This fix is essential to prevent an imbalance in budget distribution particularly favoring client-branded terms, and thereby ensuring higher incremental allocation. Recommended values for allocation at keyword type level would be pre-filled, which can be easily modified as per need.'
            }
          ]
        }
      ]
    },
    {
      date: 'November 30, 2023',
      features: [
        {
          title: 'Budget Optimizer v2.0',
          points: [
            {
              subtitle:
                "We're thrilled to unveil the latest iteration of our Budget Optimizer! Version 2.0 brings a superior user experience, empowering you with more control and flexibility in budget planning."
            },
            {
              subtitle: "Here's why you'll love it:",
              subpoints: [
                {
                  subsubtitle:
                    'Intuitive In-Product Budget Entry: Experience a revamped user interface for seamless budget entry directly within the product.'
                },
                {
                  subsubtitle:
                    'Campaign Taxonomy based planning and optimization: Set and optimize budget plans effortlessly by leveraging campaign taxonomy, giving you more granular control.'
                }
              ]
            }
          ]
        },
        {
          title: 'Intraday crawling view within Market Insights',
          points: [
            {
              subtitle:
                'We are excited to bring to you intraday crawling capabilities within market insights, helping you track your share of voice, both sponsored and organic more accurately and granularly in an isolated view.'
            },
            {
              subtitle: 'Key Features:',
              subpoints: [
                {
                  subsubtitle:
                    'Isolated Keyword Tracking : Track a set of keywords in an isolated view enabling you to track their SoV changes throughout the day, while leveraging the existing filters and widgets including custom shelves.'
                },
                {
                  subsubtitle:
                    'Time Range Selection : Users can now filter SOV based on a customizable time range. The time range picker facilitates viewing and comparing SOV data during specific intervals throughout the day.'
                },
                {
                  subsubtitle:
                    'Time of the day SoV Widget : Introducing a new widget that aggregates SoV data across days based on hourly intervals. Now users can gain enhanced insights into the changes of Share of Voice by hour, providing a more detailed understanding of daily trends.'
                },
                {
                  subsubtitle:
                    'Snapshot comparison : Compare the snapshots of two different crawls within the same day differentiated by time in the isolated intraday crawling view.'
                }
              ]
            }
          ]
        },
        {
          title: 'Sams Club SBA Campaign Support',
          points: [
            {
              subtitle:
                'Now, users will be able to take actions, view reports and create rules for SBA campaigns using CIQ platform.'
            },
            {
              subtitle: 'What is supported through CIQ UI',
              subpoints: [
                {
                  subsubtitle: 'Campaign Management tab',
                  subsubpoints: [
                    {
                      subsubsubtitle: 'Adjust Daily Budget'
                    },
                    {
                      subsubsubtitle: 'Adjust Total Budget'
                    },
                    {
                      subsubsubtitle: 'Enable/Pause/End Campaign'
                    },
                    {
                      subsubsubtitle: 'Adjust Start Date/ End Date'
                    },
                    {
                      subsubsubtitle: 'Day Parting'
                    },
                    {
                      subsubsubtitle: 'Plot Rows'
                    }
                  ]
                },
                {
                  subsubtitle: 'Keywords tab',
                  subsubpoints: [
                    {
                      subsubsubtitle: 'Plot Rows'
                    },
                    {
                      subsubsubtitle: 'Adjust Bids'
                    },
                    {
                      subsubsubtitle: 'Pause keyword'
                    }
                  ]
                },
                {
                  subsubtitle: 'Ad SKU tab',
                  subsubpoints: [
                    {
                      subsubsubtitle: 'Plot Rows'
                    },
                    {
                      subsubsubtitle: 'Pause SKU'
                    }
                  ]
                },
                {
                  subsubtitle: 'Reporting'
                }
              ]
            },
            {
              subtitle: 'What is not supported through CIQ UI',
              subpoints: [
                {
                  subsubtitle:
                    'SBA Campaign creation. It will still happen using the Excel template provided to the users.'
                },
                {
                  subsubtitle: 'Few Bluebar actions will not be supported'
                },
                {
                  subsubtitle: 'Double Click experience on Campaigns'
                },
                {
                  subsubtitle: 'Hourly Bidder for SBA Campaigns'
                },
                {
                  subsubtitle: 'Incrementality'
                }
              ]
            },
            {
              subtitle: 'What is coming next',
              subpoints: [
                {
                  subsubtitle: 'SBA campaign support in Hourly Bidder'
                },
                {
                  subsubtitle: 'Incrementality'
                }
              ]
            }
          ]
        },
        {
          title: 'Citrus (Target) Advanced Reporting',
          points: [
            {
              subtitle:
                'Now, users can access, report & optimize advanced reporting metrics for Citrus(Target) on CommerceIQ UI. Using these metrics users can easily differentiate between direct/halo sales and online/in-store sales with the attribution window set to 14-day click or 1-day view attribution.'
            },
            {
              subtitle: 'What is supported in this release?',
              subpoints: [
                {
                  subsubtitle:
                    'Campaign Management: Effortlessly toggle between standard (attribution based on 14 days same SKU and direct click only) and advanced reporting metrics to refine your campaigns.'
                },
                {
                  subsubtitle:
                    'Reporting: Leverage these metrics within the reporting tool, to conduct in-depth analysis and formulate strategies with possible configurations around direct/halo sales and in-store/online distinctions.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'October 5, 2023',
      applicableRetailers: [
        'amazonretail',
        'walmartretail',
        'instacartretail',
        'kroger_promoteiqretail'
      ],
      features: [
        {
          title: 'New Campaign Taxonomy feature',
          points: [
            {
              subtitle:
                'Enjoy a more organized, efficient, campaign management experience with Campaign Taxonomy feature.'
            },
            {
              subtitle:
                "Create Campaign Taxonomy conveniently from the 'Tools and Settings' menu and use the categories you created in the campaign optimizer strategies' scope & in campaign management, and reporting filters."
            },
            {
              subtitle: "Here's why you'll love it:",
              subpoints: [
                {
                  subsubtitle:
                    'Enhanced Organization & Management: Say goodbye to campaign chaos! You can now categorize campaigns by any criteria, creating a structured account hierarchy. Manage campaigns in campaign management and analyze campaigns in keyword reports effortlessly, independently of retailer-level categorizations.'
                },
                {
                  subsubtitle:
                    'Improved Campaign Insights: Dive deeper into performance analysis by comparing metrics across different campaign categories to pinpoint strong performers and optimization opportunities, leading to informed, results-driven decisions.'
                },
                {
                  subsubtitle:
                    'Targeted Optimization: Write campaign optimization strategies at the campaign category level to optimize strategically and maximize ROI.'
                },
                {
                  subsubtitle:
                    'Flexible Budget Allocation (Coming Soon): Gain budget flexibility! Allocate budgets in budget optimizer based on your business objectives which are linked to campaign categories and not rigid retailer classifications.'
                }
              ]
            },
            {
              subtitle: 'Try it today!'
            }
          ]
        }
      ]
    },
    {
      date: 'August 28, 2023',
      features: [
        {
          title: 'Introducing Goal based Media Planner for Amazon.',
          points: [
            {
              subtitle:
                'Define revenue/sales targets and simulate a budget plan that aligns with your objectives, reducing manual work and time spent on your annual / quarterly planning.'
            },
            {
              subtitle:
                'Prioritize between Return on Ad Spend (RoAS), Year-over-Year (YoY) growth, Ad Sales, and New to Brand sales to optimize your budget based on your strategy.'
            },
            {
              subtitle:
                'Get the most granular view of your budget, broken down into five levels: retailer, business unit, campaign type, keyword type, and month.'
            },
            {
              subtitle:
                'Upload the plan to Budget Optimizer and utilize it to monitor and fine-tune your budget to ensure maximum effectiveness.'
            }
          ]
        }
      ]
    },
    {
      date: 'August 9, 2023',
      features: [
        {
          title: 'Sams Club New Metrics.',
          points: [
            {
              subtitle:
                'New to Brand metrics and Offline Attribution metric have now been added to Sams Club product. These metrics will be available across Campaign Management, Strategy builder, Hourly Bidder and Reporting modules.'
            }
          ]
        }
      ]
    },
    {
      date: 'April 26, 2023',
      features: [
        {
          title: 'Renaming of metrics in Instacart.',
          points: [
            {
              subtitle:
                'To provide better distinction between last touch and linear touch attribution following metrics have been renamed.',
              subpoints: [
                {
                  subsubtitle:
                    'Attributed Sales, Unit, and ROAS to Attributed Sales (Last Touch), Units (Last Touch) and ROAS (Last Touch)'
                },
                {
                  subsubtitle:
                    'Linear Attributed Sales, Linear Unit and Linear ROAS to Attributed Sales (Linear), Units (Linear), and ROAS (Linear).'
                }
              ]
            }
          ]
        },
        {
          title: 'New to brand Metrics for Instacart.',
          points: [
            {
              subtitle:
                'New to brand sales and New to brand sales % metrics both for linear and last touch attributions are added to Instacart product. These metrics will be available in the Campaign Management, Strategy builder and Reporting modules.'
            }
          ]
        },
        {
          title: 'Bulk Search in Campaign Management',
          points: [
            {
              subtitle:
                'Bulk Search functionality is added in Campaign Management tabs for all Omni retailers. Now, multiple campaigns, SKUs and Keywords can be searched in the respective tabs using the Bulk Search functionality.'
            }
          ]
        }
      ]
    },
    {
      date: 'January 11, 2023',
      features: [
        {
          title: 'New Incrementality metrics in Amazon',
          points: [
            {
              subtitle:
                'iRoAS, incremental sales, incremental fraction, and incrementality band metrics are available for all SP KW targeting campaigns and corresponding keywords.'
            },
            {
              subtitle:
                'These metrics are also available in Reporting and Campaign Optimizer.'
            }
          ]
        },
        {
          title:
            'Amazon Hourly Bidder now supports Sponsored Display campaigns',
          points: [
            {
              subtitle:
                'You can add your active SD campaigns to existing or new hourly bidder strategies.'
            }
          ]
        },
        {
          title:
            'Self-Serve Reporting capability is now available for Costco (Criteo) and Meijer (Criteo), Albertson, Hyvee, Target, and Shoprite (Citrus)'
        },
        {
          title:
            'Market Insights is now available for Albertson, Hyvee, Target, and Shoprite (Citrus)'
        },
        {
          title:
            'Amazon Budget Optimizer now allows bulk edit for recommendations',
          points: [
            {
              subtitle:
                'You can now select multiple recommendations and make edits to all of them in one go.'
            }
          ]
        }
      ]
    },
    {
      date: 'November 11, 2022',
      applicableRetailers: ['instacartretail'],
      features: [
        {
          title: 'Instacart Budget Tracker release',
          points: [
            {
              subtitle:
                'Ability to track budget at a brand/sub brand level at a daily/monthly/quarterly level.'
            },
            {
              subtitle:
                'Ability to update planned budget and track it for specific events.'
            }
          ]
        },
        {
          title: 'Instacart Strategy Builder release',
          points: [
            {
              subtitle:
                'Ability to increase/decrease Campaign budget based on Campaign metrics.'
            },
            {
              subtitle:
                'Ability to increase/decrease keyword bid based on various Keyword metrics like ROAS, Bid Strength, Sales etc.'
            },
            {
              subtitle: 'Ability to pause underperforming keywords.'
            },
            {
              subtitle: 'Ability to modify default bids at an ad group level.'
            },
            {
              subtitle: 'Ability to enable and pause SKUs.'
            }
          ]
        }
      ]
    },
    {
      date: 'November 7, 2022',
      configToCheck: 'feature.walmart_campaign_management.enable',
      applicableRetailers: ['walmartretail'],
      features: [
        {
          title: 'Introducing Advanced Campaign Management for Walmart',
          points: [
            {
              subtitle: 'Ability to create SP and SB campaigns.'
            },
            {
              subtitle:
                'Recommended keywords stack ranked according to relevance to help users quickly add keywords.'
            },
            {
              subtitle: 'Ability to create multi profiles for SB campaigns.'
            },
            {
              subtitle:
                'Ability save campaigns in draft stage and launch later.'
            },
            {
              subtitle:
                'Ability to double click inside a campaign and view/edit details.'
            },
            {
              subtitle:
                'Ability to modify placement and platform bid multipliers.'
            },
            {
              subtitle: 'Ability to add search terms as keywords.'
            }
          ]
        }
      ]
    },
    {
      date: 'August 12, 2022',
      features: [
        {
          title: 'Introducing Advanced Campaign Management for Amazon',
          points: [
            {
              subtitle:
                'This release features new Campaign Management capabilities for Sponsored Products and Sponsored Brands campaigns for Amazon.'
            },
            {
              subtitle:
                'Dive deep into your search campaigns and analyze their performance through campaign details section.'
            },
            {
              subtitle:
                'Make campaign edits, like placement level bid adjustment, bidding strategy change, creation or editing of Ad groups, target and negative target addition, or change of campaign settings from CommerceIQ product.'
            },
            {
              subtitle:
                'View and use contextual AI-based recommendations to improve your campaign performance.'
            },
            {
              subtitle:
                'Create AI-powered campaigns in bulk, automatically or on your own, directly from the CommerceIQ platform.'
            }
          ]
        }
      ]
    },
    {
      date: 'December 13, 2021',
      features: [
        {
          title:
            'Campaign Optimizer - Support for more campaign types, new variables to optimize against',
          points: [
            {
              subtitle:
                'You can now optimize product targeting (in SP, SB) and auto campaigns from Campaign Optimizer! These can be selected from Keywords scope with the Match type column in Conditions'
            },
            {
              subtitle:
                'Write more granular SoV-based rules, with all SoV types available for Top 3, Top 10 and Page 1'
            },
            {
              subtitle:
                'Use Weeks of Cover (AVC) to run retail-aware advertising, which pauses and re-enables SKUs based on inventory level automatically'
            }
          ]
        }
      ]
    },
    {
      date: 'July 22, 2021',
      features: [
        {
          title:
            'CommerceIQ has launched its new Market Insights dashboard for Share of Voice! You can find it under Digital Shelves on the left',
          points: [
            {
              subtitle:
                'Identify and track important Digital Shelves: Automatically group together related keywords into your market hierarchy, understand volume ranks and drill down into different levels to identify Share of Voice and advertising spend.'
            },
            {
              subtitle:
                'Track top Competitors, drivers of Share of Voice: View Share of Voice on shelves side-by-side along with top competitors in each shelf; identify emerging threats, drive SoV-aware advertising.'
            },
            {
              subtitle:
                'Analyze the Market and Identify Opportunities: Discover trending shelves and keywords, create a SoV landscape chart against  competitors through custom 2 x 2 bubble charts; plot share and ad metrics to understand performance across shelves.'
            }
          ]
        }
      ]
    },
    {
      date: 'April 8, 2021',
      features: [
        {
          title: 'Sponsored Display reports are now available!',
          points: [
            {
              subtitle:
                'Sponsored Display data has been integrated into Campaign Management! You can now view your SD campaigns data along with the targeting under Keywords and AMS SKUs tab'
            },
            {
              subtitle:
                'Actions on SD campaigns will be enabled in a following release'
            }
          ]
        }
      ]
    },
    {
      date: 'July 2, 2020',
      configToCheck: 'feature.pages.budget_planner.enable',
      features: [
        {
          title: 'Budget Optimizer is now available!',
          points: [
            {
              subtitle:
                'Budget Optimizer takes in your monthly advertising budget for the year and creates the daily spend schedule by pacing your budget based on the historical spend data and day to day variation in consumer demand. This means you spend more during days of high demand and cut back during lean days.'
            },
            {
              subtitle:
                "It also does an assessment of spend vs budget every single day and if there has been an over or underspending by more than a day's worth of budget, the optimization engine generates budget and bid optimization actions that cut budgets/bids on low performing campaigns and reallocate excess budget over high performing campaigns to drive incremental sales.  This ensures there are more frequent and less abrupt budget & bid adjustments and the budgets sustain through to the end of the month when usually CPCs are low and the competition is running out of budget."
            },
            {
              subtitle: 'What do you need to do?',
              subpoints: [
                {
                  subsubtitle:
                    'Navigate to Budget Optimizer and upload your monthly budget at the profile and portfolio level.'
                },
                {
                  subsubtitle:
                    'Add seasonality events to allocate specific budgets to profiles and portfolios for days of high demand such as Prime Day or Black Friday.'
                },
                {
                  subsubtitle:
                    'Track your ad spend against your planned budget at the Entire Business, Profile and Portfolio level.'
                },
                {
                  subsubtitle:
                    'Review and approve campaign budget optimisation and keyword bid optimization actions generated by our optimization engine to ensure your ad spend closely follows your budget while driving incrementality.'
                }
              ]
            },
            {
              subtitle: 'Reach out to your EMs in case of queries.'
            }
          ]
        }
      ]
    },
    {
      date: 'May 5, 2020',
      features: [
        {
          title:
            'Enhancement to Campaign Optimizer. More SKU entity actions are now supported in Campaign Optimizer:',
          points: [
            {
              subtitle:
                'We have enhanced Campaign Optimizer to support more inventory aware strategies. You can create strategies that will automatically enable SKUs paused due to low coverage or pause and replace SKUs limited in stock with alternate SKUs that are in stock.'
            },
            {
              subtitle: 'How does this help you?',
              subpoints: [
                {
                  subsubtitle:
                    'Your ad campaigns and sales can suffer if SKUs or inventory associated with your campaigns go out of stock or are low on coverage. Now you can create strategies that track such SKUs and pause or replace them so that your ad budget is spent only on campaigns with adequate inventory.'
                },
                {
                  subsubpoints: [
                    {
                      subsubsubtitle:
                        "Create an anti-strategy that automatically enables SKUs that were paused because of low 'weeks of coverage' once the coverage has improved. This ensures that as soon as the SKU is back in inventory, enable it start allocating your ad budget towards it once again."
                    },
                    {
                      subsubsubtitle:
                        'Create a strategy that automatically pauses SKUs that have limited stock AND replaces the paused SKU with an alternate SKU that is in stock in selected ad campaigns. This again ensures your ad budget is always going to in stock SKUs.'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'March 19, 2020',
      features: [
        {
          title:
            'Enhancement to Campaign Management > Campaigns > Add keyword: Adding new keywords and adjusting keyword bids is enabled.',
          points: [
            {
              subtitle: 'How does this help you?',
              subpoints: [
                {
                  subsubtitle:
                    'You can add new broad, phrase and exact match type keywords to your campaigns from Campaign Management page.'
                },
                {
                  subsubtitle:
                    'You can also adjust bids on keywords while adding them.'
                }
              ]
            },
            {
              subtitle: 'What do you need to do?',
              subpoints: [
                {
                  subsubtitle:
                    "On the Campaign Management > Campaigns page, select one or more campaigns and clock on 'Add keyword' option. You will see a list of keywords with broad, exact and phrase match types against them. Click on '+' button to add them to your selected campaigns. You can use the suggested bids on these keywords or adjust them on this screen before clicking 'Apply' to save the changes."
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: 'March 2, 2020',
      features: [
        {
          title: 'Enhancement to Campaign Optimizer',
          points: [
            {
              subtitle:
                'SKU entity actions are now allowed in Campaign Optimizer:',
              subpoints: [
                {
                  subsubtitle:
                    'We have enhanced Campaign Optimizer to allow creation of inventory aware strategies. You can now create strategies that will automatically enable paused SKUs or pause or archive active SKUs provided they meet the defined condition'
                },
                {
                  subsubtitle: 'How does this help you?',
                  subsubpoints: [
                    {
                      subsubsubtitle:
                        'Your ad campaigns and sales can suffer if SKUs or inventory associated with your campaigns go out of stock or are low on coverage. Now you can create strategies that track such SKUs and pause them so that your ad budget is not spent on campaigns with low inventory.'
                    }
                  ]
                },
                {
                  subsubtitle: 'What do you need to do?',
                  subsubpoints: [
                    {
                      subsubsubtitle:
                        "Creating the above strategy is simple. You need to select 'active SKUs' in the scope section, in the condition section set the appropriate rule such as 'OOS' or 'weeks of coverage < x' and select the action as 'Pause SKU'. Finally, you can simulate the strategy to see the results and then save it to make it live."
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  return [ciqSalesReleases, ciqAdvertisingReleases];
};
