<template>
  <div class="scroller">
    <div
      v-if="isLeftScrollerEnabled && showLeftScroller"
      id="scroller"
      class="scroll-action left-scroller u-font-size-3"
      @click="scrollToLeft()"
    >
      <div>
        <rb-icon
          :icon="'arrow-right'"
          class="rb-icon--x-large u-color-grey-mid-light scroll-icon"
        />
      </div>
    </div>
    <div
      v-if="isRightScrollerEnabled && showRightScroller"
      id="scroller"
      class="scroll-action right-scroller u-font-size-3"
      @click="scrollToRight()"
    >
      <div>
        <rb-icon
          :icon="'arrow-right'"
          class="rb-icon--x-large u-color-grey-mid-light scroll-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    targetElement: {
      type: String,
      default: ''
    },
    offsetToScroll: {
      type: Number,
      default: 500
    },
    isLeftScrollerEnabled: {
      type: Boolean,
      default: true
    },
    isRightScrollerEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showLeftScroller: false,
      showRightScroller: false
    };
  },
  computed: {
    selectedTargetElement() {
      return document.getElementById(this.targetElement);
    }
  },
  mounted() {
    if (
      this.selectedTargetElement.scrollWidth !==
      this.selectedTargetElement.clientWidth
    ) {
      this.showRightScroller = true;
    }
    var maxScrollLeft =
      this.selectedTargetElement.scrollWidth -
      this.selectedTargetElement.clientWidth;
    this.selectedTargetElement.addEventListener(
      'scroll',
      () => {
        if (this.selectedTargetElement.scrollLeft > 0) {
          this.showLeftScroller = true;
          if (this.selectedTargetElement.scrollLeft === maxScrollLeft) {
            this.showRightScroller = false;
          } else {
            this.showRightScroller = true;
          }
        } else {
          this.showLeftScroller = false;
        }
      },
      { passive: true }
    );
  },
  beforeDestroy() {
    this.selectedTargetElement.removeEventListener('scroll');
  },
  methods: {
    scrollToRight() {
      this.selectedTargetElement.scrollLeft += this.offsetToScroll;
    },
    scrollToLeft() {
      this.selectedTargetElement.scrollLeft -= this.offsetToScroll;
    }
  }
};
</script>

<style lang="css">
.scroller .scroll-action {
  background-color: rgba(43, 51, 59, 0.05);
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  transition: all 0.3s ease;
  width: 70px;
  top: 17%;
  height: 140px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
}
.scroller .right-scroller .scroll-icon,
.scroller .left-scroller .scroll-icon {
  margin-left: 5px;
  visibility: hidden;
  color: #007cf6;
}
.scroller .right-scroller:hover,
.scroller .left-scroller:hover {
  background-color: rgba(43, 51, 59, 0.1);
  width: 83px;
  height: 166px;
}
.scroller .right-scroller:hover .scroll-icon,
.scroller .left-scroller:hover .scroll-icon {
  visibility: visible;
}
.scroller .right-scroller {
  right: -40px;
  border-radius: 150px 0px 0 150px;
}
.scroller .right-scroller .scroll-icon {
  margin-left: 5px;
}
.scroller .right-scroller:hover {
  top: 12%;
  right: -20px;
}
.scroller .right-scroller:hover .scroll-icon {
  margin-left: 20px;
}
.scroller .left-scroller {
  border-radius: 0px 150px 150px 0px;
  left: -40px;
}
.scroller .left-scroller .scroll-icon {
  margin-left: 15px;
  transform: rotate(180deg);
}
.scroller .left-scroller:hover {
  top: 12%;
  left: -20px;
}
.scroller .left-scroller:hover .scroll-icon {
  margin-left: 20px;
}
.is-sticky .scroller .scroll-action {
  width: 65px;
  height: 65px;
}
.is-sticky .scroller .right-scroller {
  top: 10px;
  right: -45px;
}
.is-sticky .scroller .right-scroller:hover {
  top: 12%;
  right: -35px;
}
.is-sticky .scroller .right-scroller:hover .scroll-icon {
  margin-left: 10px;
}
.is-sticky .scroller .left-scroller {
  top: 10px;
  left: -45px;
}
.is-sticky .scroller .left-scroller:hover {
  top: 12%;
  left: -35px;
}
.is-sticky .scroller .left-scroller:hover .scroll-icon {
  margin-left: 37px;
}
.is-sticky .scroller .rb-icon--x-large {
  height: 18px;
  width: 18px;
}
.is-sticky .scroller .rb-icon--x-large::before {
  font-size: 18px;
}
.is-sticky .scroller .right-scroller .scroll-icon {
  margin-left: 4px;
}
.is-sticky .scroller .left-scroller .scroll-icon {
  margin-left: 44px;
}
</style>
