<template>
  <div class="campaign-list-create-entity">
    <addEntity
      :widgets-config="config"
      :get-tag-desc="getTagDesc"
      :all-entities="allEntities"
      :subset-entities="subsetEntities"
    />
  </div>
</template>
<script>
import addEntity from '@/pages/toolsAndSettings/entityTagging/common/createCustomEntity/index.vue';
import widgetsCongig from './widgets';
export default {
  components: {
    addEntity
  },
  data() {
    return {
      config: null
    };
  },
  computed: {
    getTagDesc() {
      return this.$store.getters.getTagDescription;
    },
    allEntities() {
      return this.$store.getters.getAllEntities;
    },
    subsetEntities() {
      return this.$store.getters.getAllEntitiesForATag;
    }
  },
  created() {
    this.config = widgetsCongig;
  }
};
</script>
<style lang="css">
.campaign-list-create-entity .table-container-entity-asin-tagging {
  height: calc(100vh - 300px) !important;
}
.campaign-list-create-entity .static-title-entity-sub-entity-page {
  color: #8b8f93;
  margin: 1.6rem;
}
.campaign-list-create-entity .editable-title-entity-sub-entity-page {
  color: #bd10e0;
  text-decoration: underline;
  min-width: 400px;
}
</style>
