<template>
  <section
    class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <span
      v-if="numberOfPlottedSelections !== 0"
      class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
      :class="[{ isHighlighted: isHighlighted }, chipStyle]"
    >
      <span
        data-cy="graphPlottedInfo"
        class="u-spacing-pr-s u-line-height-1_2"
        >Graphs plotted for {{ numberOfPlottedSelections }} rows</span
      >
      <span @click="removeChip">
        <rb-icon
          :icon="'cross'"
          class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
          :class="{
            'isHighlighted u-color-grey-white': isHighlighted
          }"
        />
      </span>
    </span>
  </section>
</template>

<script>
export default {
  props: {
    numberOfPlottedSelections: {
      type: Number,
      default: 0
    },
    chipStyle: {
      type: Array,
      default() {
        return ['chipHeight'];
      }
    }
  },
  computed: {
    isHighlighted() {
      return this.numberOfPlottedSelections !== 0;
    }
  },
  methods: {
    removeChip() {
      this.$emit('removeChip');
    }
  }
};
</script>

<style lang="css" scoped>
.chipStyle {
  min-height: 20px;
}
</style>
