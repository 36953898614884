export default {
  applicableRetailers: ['amazon'],
  defaultValues: {
    newBid: {
      numericActionType: 'Set',
      numericActionChangeType: 'Absolute',
      setNewValue: true,
      dynamicAllocation: false,
      dynamicMetric: '',
      value: null
    },
    matchTo: {
      numericActionType: 'Set',
      numericActionChangeType: 'Absolute',
      setNewValue: false,
      dynamicAllocation: true,
      dynamicMetric: 'multiplier_adjusted_cpc',
      value: null
    }
  },
  numericActionTypes: [
    {
      title: '+',
      value: 'Increase'
    },
    {
      title: '-',
      value: 'Decrease'
    }
  ],
  matchToOptions: (retailer) => {
    const cpcTooltipText = 'Average CPC over the last 14 days.';
    let multiplierAdjustedCpcTooltipText;
    if (retailer === 'amazon') {
      multiplierAdjustedCpcTooltipText =
        'Average CPC over the last 14 days divided by the Top of search bid multiplier for the campaign.  If bid multiplier is not present, then the value is set to average CPC. Used when the maximum bid value should not exceed the CPC. \n\n Eg: If the CPC is $3, and campaign bid multiplier for top search is 50%, then the multiplier adjusted CPC will be $3 / (100% + 50%) = $2.';
    }
    if (retailer === 'walmart') {
      multiplierAdjustedCpcTooltipText =
        'Average CPC over the last 14 days divided by the weighted average bid multiplier for the campaign. If bid multiplier is not present, then the latest CPC is considered. Used when the maximum bid value should not exceed the CPC. \n\n Eg: If the CPC is $3, and weighted average of all campaign bid multipliers with (weighted by ad spend) is 50% and , then the multiplier adjusted CPC will be $3 / (100% + 50%) = $2.';
    }
    return {
      multiplier_adjusted_cpc: {
        title: 'Multiplier Adjusted CPC',
        value: 'multiplier_adjusted_cpc',
        alias: 'multiplier_adjusted_cpc',
        metricKey: 'multiplier_adjusted_cpc',
        tooltipText: multiplierAdjustedCpcTooltipText,
        tooltip: `<div class="u-font-weight-400" style="white-space: pre-line">${multiplierAdjustedCpcTooltipText}</div>`,
        metadata: {
          metricSelectionTooltipEnabled: true,
          tooltipHeader: 'Multiplier Adjusted CPC'
        }
      },
      cpc: {
        title: 'CPC',
        value: 'cpc',
        alias: 'cpc',
        metricKey: 'cpc',
        tooltipText: 'Average CPC over the last 14 days.',
        tooltip: `<div class="u-font-weight-400">${cpcTooltipText}</div>`,
        metadata: {
          metricSelectionTooltipEnabled: true,
          tooltipHeader: 'CPC'
        }
      }
    };
  }
};
