var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"budget-planner u-width-100 u-display-flex"},[(_vm.openActivity)?_c('div',[(_vm.showActionsLog)?_c('activity-log',{attrs:{"data":_vm.getActivityLogData,"page-name":'budgetPlan',"close-activity-log":_vm.closeActivityLog,"heading":'Budget plan edit history',"top-spacing":'73px',"retailer":_vm.retailer,"is-budget-optimizer":true}}):_vm._e()],1):_vm._e(),(_vm.uncategorized)?_c('div',[_c('uncategorizedSkuViews',{attrs:{"retailer":_vm.retailer},on:{"closeUncategorizedView":_vm.closeUncategorizedView}})],1):_vm._e(),_c('Loader',{staticClass:"loader--expand",attrs:{"loading":_vm.isDashboardLoading,"color":'#3fadf7',"size":'4rem',"thickness":'.2rem'}}),(!_vm.isDashboardLoading)?_c('div',{staticClass:"u-display-flex u-width-100 u-flex-justify-content-center"},[(!_vm.budgetPlanExists)?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"},[_c('img',{staticStyle:{"max-width":"18%"},attrs:{"src":"/images/no_budget.png","alt":"no budget image"}}),_c('div',{staticClass:"u-spacing-pv-l u-font-weight-600 u-font-size-3"},[_vm._v(" Looks like you haven’t created your budget plan yet! ")]),_c('div',{staticClass:"u-display-flex"},[_c('rb-button',{attrs:{"type":"filled","click-fn":_vm.createPlan,"text":"Create a Budget Plan" +
            (_vm.showNextYearPlanButton ? (" for " + _vm.thisYear) : '')}}),(_vm.showNextYearPlanButton)?_c('div',{staticClass:"u-spacing-ph-m"},[_c('rb-button',{attrs:{"type":"filled","click-fn":_vm.routeToNextYearPlan,"text":("Create a Budget Plan for " + (_vm.thisYear + 1))}})],1):_vm._e()],1)]):_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100"},[_c('div',{staticClass:"sticky u-spacing-ph-l u-header-box-shadow",class:{ 'u-spacing-mb-s': _vm.isBudgetPlanArchived },staticStyle:{"z-index":"11"}},[_c('rb-filter-panel',{staticStyle:{"z-index":"11"},style:({
            'pointer-events':
              _vm.saveBudgetPlanStatus === 'not_triggered' ? 'auto' : 'none'
          }),attrs:{"id":"wk-budgetPlanner-filters","listener-event":_vm.config.filters.filterKey,"hide-filter":true,"page":"budgetPlanner","allow-date-compare":false,"state":_vm.filterState,"loader-status":_vm.loaderStatus,"enable-save":false,"has-search":true,"data":_vm.filterData,"primary":_vm.primaryFilterData,"secondary-data":_vm.secondaryFilterData,"config":_vm.config.filters,"emit":_vm.config.filters.emit,"new-date":true,"use-different-date-for-canned-dates":'budget_planner_dashboard',"max-date":_vm.getBudgetPlannerDateWiseMinMaxKey,"default-date-post-range":'thisMonth',"additional-ranges":_vm.additionalRanges,"get-filter-instance":_vm.getFilterInstance}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs",attrs:{"slot":"leftSide-searchPanel"},slot:"leftSide-searchPanel"},[_c('PageTitle',{attrs:{"title":'Budget Optimizer'}})],1),_c('div',{staticClass:"u-spacing-mr-s",attrs:{"slot":"preDatePicker"},slot:"preDatePicker"},[_c('rb-button',{staticClass:"u-width-100 u-spacing-pv-s fs-edit-budget-plan u-spacing-ph-m rb-button-icon-12",attrs:{"disabled":_vm.saveBudgetPlanStatus !== 'not_triggered',"click-fn":_vm.createPlan,"text":_vm.isBudgetPlanArchived
                  ? 'Create a Budget Plan'
                  : 'Edit Budget Plan',"size":"m","icon-left":_vm.isBudgetPlanArchived ? 'add-circle-outline' : 'pencil',"type":"filled"}})],1),_c('div',{staticClass:"u-spacing-mr-s",attrs:{"slot":"preDatePicker"},slot:"preDatePicker"},[_c('rb-select',{attrs:{"open-direction":'bottom-end',"send-details":true,"options":_vm.actions,"on-close":_vm.actionSelected},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer u-action-padding"},[(option.icon)?_c('rb-icon',{staticClass:"icon-color",class:option.class,attrs:{"icon":option.icon}}):_vm._e(),_c('div',{staticClass:"u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"},[_vm._v(" "+_vm._s(option.name)+" ")])],1)]}}],null,false,2861764809)},[_c('div',{staticClass:"u-more-action-color u-cursor-pointer u-more-action u-flex-1 u-border-all u-border-radius-s u-color-grey-lighter u-border-color-grey-xxx-light u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s",attrs:{"slot":"trigger"},slot:"trigger"},[_c('rb-icon',{staticClass:"rb-icon--medium u-spacing-mh-s",staticStyle:{"transform":"rotate(270deg) scaleY(1.3)"},attrs:{"icon":'more-horizontal'}}),_c('div',{staticClass:"u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"},[_vm._v(" "+_vm._s(' More Actions')+" ")])],1)])],1)])],1),_c('div',{staticClass:"u-spacing-ph-m u-spacing-pb-s"},[(
            _vm.isBudgetPlanArchived && _vm.saveBudgetPlanStatus === 'not_triggered'
          )?_c('banner',{staticClass:"u-envelop-width u-width-100",attrs:{"header-text":"Looks like you haven’t created a Budget Plan for the Current Year!"}},[_c('section',{staticClass:"u-spacing-mt-s u-font-size-7 u-color-grey-mid-light",attrs:{"slot":"sub-text"},slot:"sub-text"},[_vm._v(" Go ahead and create a Budget Plan soon to let us help you track and optimize. "),_c('span',{staticClass:"u-color-blue-base u-cursor-pointer u-font-weight-600 u-text-decoration-underline",on:{"click":_vm.createPlan}},[_vm._v("Create a Budget Plan")])])]):_vm._e()],1),(_vm.saveBudgetPlanStatus === 'not_triggered')?_c('div',{staticClass:"u-spacing-ph-m"},[_c('section',{staticClass:"u-envelop-width u-width-100"},[_c('budgetPlannerChart',{staticClass:"u-spacing-mb-m",on:{"openSidePanel":_vm.openSidePanel}}),_c('budgetPlannerTable',{staticClass:"u-spacing-mb-m"}),(_vm.retailer === 'amazon')?_c('budgetPlannerActions',{staticClass:"u-spacing-mb-m",attrs:{"is-budget-plan-archived":_vm.isBudgetPlanArchived},on:{"createBudgetPlan":_vm.createPlan}}):_vm._e()],1)]):_c('div',{staticClass:"u-display-flex updating-budget-plan-card u-spacing-ph-m"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"},[_c('img',{staticStyle:{"max-width":"24%"},attrs:{"src":"/images/budget_plan_loading_summary.png","alt":"budget plan loading summary image"}}),_c('div',{staticClass:"u-spacing-mt-xl u-font-size-4 u-font-style-italic"},[_vm._v(" Give us a moment while we build the summary page for you… ")]),_c('div',{staticClass:"u-spacing-mt-s u-spacing-mb-s u-width-30",class:_vm.saveBudgetPlanStatus === 'loading'
                ? 'updating-budget-plan-loader'
                : ''})])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }