<template>
  <div :id="targetId">
    <div>
      <span class="u-spacing-pr-xs u-text-case-title u-color-grey-xx-light">{{
        primaryPreText
      }}</span
      ><span>{{ primaryText }}</span>
    </div>
    <p class="u-line-height-1-3 u-spacing-mt-s">
      <span
        class="u-spacing-pr-xs u-text-case-title u-color-grey-xx-light u-white-space-no-wrap"
        >{{ secondaryPreText }}</span
      ><span v-show="!showAllItems">
        {{ computedItems.itemsToShow }}
        <span
          class="u-text-decoration-underline u-cursor-pointer u-color-blue-base"
          @click="showAllItems = true"
          >{{ computedItems.remainingItems }}
        </span>
      </span>
      <span v-show="showAllItems">
        {{ itemsList.join(', ') }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ExpandableTooltip',
  props: {
    primaryPreText: {
      type: String,
      required: true
    },
    primaryText: {
      type: String,
      required: true
    },
    secondaryPreText: {
      type: String,
      required: true
    },
    itemsList: {
      type: Array,
      default: () => []
    },
    targetId: {
      type: String,
      required: true
    },
    maxItemsToDisplay: {
      type: Number,
      default: 3
    }
  },
  data: function () {
    return { showAllItems: false };
  },
  computed: {
    computedItems() {
      let remainingItems = '';
      let tooltipText = '';
      // No. of items to display
      this.itemsList
        .slice(0, this.maxItemsToDisplay)
        .forEach((brand, index) => {
          if (index !== 0) {
            tooltipText += ', ';
          }
          tooltipText += brand;
        });
      if (this.itemsList.length > this.maxItemsToDisplay) {
        const remaining = this.itemsList.length - this.maxItemsToDisplay;
        tooltipText += ' and ';
        remainingItems = `${remaining} ${remaining > 1 ? 'others' : 'other'}`;
      }

      return {
        itemsToShow: tooltipText,
        remainingItems
      };
    }
  }
};
</script>
