export function createRestoreOrDeleteSkuPayloadActionPayload(
  selectedSkuRow,
  dropdownSelectionValue,
  aggregator,
  retailer = 'kroger_promoteiq'
) {
  const contentId = selectedSkuRow?.data?.[`${aggregator}_cm_sku_content_id`];
  const skuId = selectedSkuRow?.data?.sku_id;
  const skuCode = selectedSkuRow?.data?.[`${aggregator}_cm_sku_sku`];
  const currentSkuBid =
    selectedSkuRow?.data?.[`${aggregator}_cm_sku_current_bid`];
  const currentSkuCampaignId =
    selectedSkuRow?.data?.[`${aggregator}_cm_sku_campaign_id`];
  const skuTitle = selectedSkuRow?.data?.[`${aggregator}_cm_sku_sku_title`];
  const skuImageUrl =
    selectedSkuRow?.data?.[`${aggregator}_cm_sku_latest_image_url`];
  const selectionValueToActionTypeMapping = {
    Restore: `${retailer}CampaignAddProduct`,
    Delete: `${retailer}CampaignRemoveProduct`
  };
  const tempPayload = {
    widget: 'amsSkus',
    actionType: selectionValueToActionTypeMapping[dropdownSelectionValue],
    actionSource: {
      pageUrl: window.location.href
    },
    actionPayload: {},
    viewPayload: {},
    primaryKey: skuId,
    previousEntityValue: '',
    newEntityValue: ''
  };
  if (dropdownSelectionValue === 'Restore') {
    tempPayload.actionPayload = {
      campaignId: currentSkuCampaignId,
      publisher_reference: skuCode,
      sku_bid: currentSkuBid
    };
    tempPayload.viewPayload = {
      campaignId: currentSkuCampaignId,
      sku: skuCode,
      sku_title: skuTitle,
      image_url: skuImageUrl
    };
  }
  if (dropdownSelectionValue === 'Delete') {
    tempPayload.actionPayload = {
      campaignId: currentSkuCampaignId,
      content_id: contentId
    };
    tempPayload.viewPayload = {
      campaignId: currentSkuCampaignId,
      product_code: skuCode,
      product_name: skuTitle,
      sku_image_url: skuImageUrl
    };
  }
  return tempPayload;
}

function getBidBasedOnDropdownSelection(
  currentDropdownSelection,
  rowData,
  inputBoxBidInput
) {
  switch (currentDropdownSelection) {
    case 'custom_bid':
      return inputBoxBidInput || null;
    case 'minimum_bid':
      return rowData?.minimum_bid || null;
    case 'max_suggested_bid':
      return rowData?.max_suggested_bid || null;
    case 'min_suggested_bid':
      return rowData?.min_suggested_bid || null;
    case 'base_bid':
      return null;
    default:
      return inputBoxBidInput;
  }
}

const minimumBidColumnConfig = {
  name: 'minimum_bid',
  type: 'currency',
  uiField: {
    uiLabel: 'Minimum Bid',
    uiType: 'currency',
    uiOrder: undefined,
    metadata: {
      toolTipText: '',
      tableColumnName: 'minimum_bid',
      alignHeader: 'left',
      showOnUi: true,
      isFixed: false,
      width: 120,
      minWidth: 110
    }
  }
};

export const selectSkuLeftTable = (vueRef, retailer) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'sku_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'sku_latest_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: false,
          getQuickFilterText: function (params) {
            return `${params?.data?.sku_title}_${params?.data?.sku}`;
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () => {
          vueRef.leftTableRows.forEach((item) => {
            const bidForItem = getBidBasedOnDropdownSelection(
              vueRef.selectedCustomBid.value,
              item,
              vueRef.suggestedCustomBidInput
            );
            item.newBid = bidForItem;
          });
          vueRef.addAll();
        },
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: function (row) {
            const bidForItem = getBidBasedOnDropdownSelection(
              vueRef.selectedCustomBid.value,
              row.params.data,
              vueRef.suggestedCustomBidInput
            );
            row.params.data.newBid = bidForItem;
            vueRef.addEntity(row);
          },
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'availability',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availability',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability',
          showOnUi: true,
          isFixed: false,
          width: 90
        }
      }
    },
    ...(retailer === 'kroger'
      ? []
      : [
          {
            ...minimumBidColumnConfig,
            uiField: {
              ...minimumBidColumnConfig.uiField,
              uiOrder: '4'
            }
          }
        ]),
    {
      name: 'price_current',
      type: 'currency',
      uiField: {
        uiLabel: 'Current Price',
        uiType: 'currency',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'price_current',
          alignHeader: 'left',
          showOnUi: true,
          isFixed: false,
          width: 90,
          minWidth: 90
        }
      }
    },
    {
      name: 'Suggested Bid',
      type: 'STRING',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'string',
        uiOrder: '6',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid_range',
          showOnUi: true,
          isFixed: false,
          width: 110
        }
      }
    }
  ];
};

export const selectSkuRightTable = (vueRef, retailer) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'sku_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'sku_latest_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    ...(retailer === 'kroger'
      ? []
      : [
          {
            ...minimumBidColumnConfig,
            uiField: {
              ...minimumBidColumnConfig.uiField,
              uiOrder: '2'
            }
          }
        ]),
    {
      name: 'Bid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '3',
        uiType: 'custom',
        ...(retailer === 'kroger'
          ? {
              customStyles: 'cursor:pointer; padding-left:15px',
              headerIcon: {
                icon: 'info-circle-fill',
                toolTip:
                  'If you leave the bid empty, the base bid of the respective adgroup will be considered'
              }
            }
          : {}),
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'newBid',
          defaultValueColumnName: 'newBid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 160,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validatino should happen here
            context.params.data.newBid = context.bidValue;
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '4',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export const dataDiscrepancyVHtmlText =
  '<div class="u-font-size-7 u-color-blue-base u-spacing-pb-s u-spacing-ph-l u-spacing-ml-m u-border-width-s u-display-flex u-flex-align-items-center u-font-weight-normal">There might be data discrepancy prior to<b class="u-spacing-ph-xs"> Mar 10, 2023 </b> as historical metadata is not provided by PIQ.</div>';

export const retailers = ['chewy_promoteiq'];
export const retailersWithKeywordActions = ['chewy_promoteiq'];

const retailerConfig = {
  chewy_promoteiq: {
    bidValues: {
      min: 1.1,
      max: 5
    },
    addKeywordsLimit: 1000,
    multiCampaignErrorMessage:
      'Action is not allowed for multiple keywords under the same campaign. Please use Block/Unblock functionality on Campaign Management tab for that.'
  },
  default: {
    bidValues: {
      min: 1,
      max: 100
    },
    addKeywordsLimit: 500,
    multiCampaignErrorMessage:
      'Action is not allowed for multiple keywords under the same campaign. Please use Remove/Add functionality on Campaign Management tab for that.'
  }
};
export function getRetailerConfig(retailer) {
  return retailerConfig[retailer] ?? retailerConfig.default;
}
