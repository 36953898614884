<template>
  <div class="u-position-relative">
    <div
      ref="trigger"
      v-tippy="{
        html: '#' + id,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        offset: commonDateKey ? '20, 0' : '0, 0',
        // eslint-disable-next-line vue/this-in-template
        onShow: this.onShow,
        // eslint-disable-next-line vue/this-in-template
        onHide: this.onHide
      }"
      role="button"
      data-cy="calender"
      class="dropdown-trigger button button--hollow"
      @click="toggle"
    >
      <slot name="trigger">
        <div
          v-if="triggerStyle === 'circular'"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between circular-date-picker-trigger"
        >
          <rb-icon
            :icon="'calendar'"
            class="rb-icon--small u-color-grey-lighter"
            data-cy="calendarIcon"
          />
          <span
            class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start"
          >
            <span
              class="u-spacing-ml-xs u-spacing-mr-s u-line-height-1-1"
              data-cy="widget_level_calendar_text"
            >
              {{ dateText }}
            </span>
            <!-- <span v-if="allowCompare" class="u-font-size-8 u-spacing-pt-xxs">
              <span class="u-color-grey-x-light">Compare to : </span>
              <span>{{ compareDateText }}</span>
            </span> -->
          </span>
          <rb-icon
            :icon="'arrow-down'"
            class="rb-icon--xx-small u-color-grey-lighter"
          />
        </div>
        <div
          v-else
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between date-picker-trigger"
        >
          <rb-icon
            :icon="'calendar'"
            data-cy="calendarIcon"
          />
          <span
            class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-ph-s"
          >
            <span
              class="u-color-grey-mid-light"
              data-cy="widget_level_calendar_text"
            >
              {{ dateText }}
            </span>
            <span
              v-if="allowCompare && useCompareTo"
              class="u-font-size-8 u-spacing-pt-xxs"
            >
              <span class="u-color-grey-x-light">Compare to : </span>
              <span data-cy="widget_level_calendar_text">{{
                compareDateText
              }}</span>
            </span>
          </span>
          <rb-icon
            :icon="'arrow-down'"
            class="rb-icon--x-small"
          />
        </div>
      </slot>
    </div>

    <div
      :id="id"
      ref="dropdownMenu"
      class="daterangepicker-row u-display-flex u-flex-direction-column"
      style="width: 830px"
      data-cy="dateRangePicker"
    >
      <div class="u-display-flex">
        <div
          class="u-spacing-p-l u-display-flex u-border-width-s u-border-right u-border-color-grey-xxx-light"
          data-cy="calendar"
        >
          <div
            v-for="calendarIndex in calendarCount"
            :key="calendarIndex"
            class="daterangepicker-col"
            :class="{ 'u-spacing-pr-l': calendarIndex === 1 }"
          >
            <date-range-picker-calendar
              :selection-mode="selectionMode"
              :hovered-start-date="hoveredStartDate"
              :hovered-end-date="hoveredEndDate"
              :hovered-start-date-compare="hoveredStartDateCompare"
              :hovered-end-date-compare="hoveredEndDateCompare"
              :calendar-index="calendarIndex"
              :calendar-count="calendarCount"
              :month="month"
              :max-date="maxDate"
              :min-date="minDate"
              :start-date="startDate"
              :end-date="endDate"
              :compare="compare"
              :start-date-compare="startDateCompare"
              :end-date-compare="endDateCompare"
              :step="step"
              :allow-compare="allowCompare && useCompareTo"
              :show-week-numbers="showWeekNumbers"
              @goToPrevMonth="goToPrevMonth"
              @goToNextMonth="goToNextMonth"
              @selectDate="selectDate"
              @nextStep="nextStep"
              @onHover="onHover"
              @onHoverLeave="onHoverLeave"
            />
          </div>
        </div>
        <div
          class="daterangepicker-col u-display-flex u-flex-direction-column u-spacing-p-l u-font-weight-normal u-flex-justify-content-space-between"
          style="min-width: 248px"
        >
          <div class="u-display-flex u-flex-direction-column">
            <span
              class="u-color-blue-base u-font-weight-600 u-font-size-6 u-spacing-pb-s"
              data-cy="dateRangeTxt"
              >Date range</span
            >
            <rb-select
              v-if="isActive"
              :send-details="true"
              :on-close="cannedCalendarSelected"
              :search-enable-threshold="20"
              :options="cannedCalendars"
              custom-tippy-direction-dropdown="left"
              class="u-width-100 date-compare--select-l"
            >
              <div
                slot="trigger"
                :class="compare ? 'daterangepicker-range-border' : ''"
                class="u-display-flex u-flex-justify-content-space-between u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
              >
                <span
                  class="u-text-overflow-ellipsis u-text-transform-capitalize u-font-size-6"
                  data-cy="selectedcannedcalendartitle"
                >
                  {{ selectedCannedCalendar.title }}</span
                >
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'arrow-down'"
                />
              </div>
            </rb-select>

            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pv-s"
            >
              <div class="daterangepicker-date-input rb-control">
                <input
                  ref="startDate"
                  data-cy="dateRange_date_option"
                  :readonly="enableReadOnly"
                  placeholder="MMM DD, YYYY"
                  :value="startDate | dateFormat"
                  class="rb-input"
                  @keydown.tab="keydown($event, 'startDate')"
                  @input="typeDate($event, 'startDate')"
                  @keyup.enter="typeDate($event, 'startDate', true)"
                  @focus="step = 'selectStartDate'"
                  @blur="inputDate"
                />
              </div>
              <span class="u-color-grey-xx-light u-spacing-ph-s u-font-size-1">
                -
              </span>
              <div class="daterangepicker-date-input rb-control">
                <input
                  ref="endDate"
                  data-cy="dateRange_date_option"
                  :readonly="enableReadOnly"
                  placeholder="MMM DD, YYYY"
                  :value="endDate | dateFormat"
                  class="rb-input"
                  @keydown.tab="keydown($event, 'endDate')"
                  @input="typeDate($event, 'endDate')"
                  @keyup.enter="typeDate($event, 'endDate', true)"
                  @focus="step = 'selectEndDate'"
                  @blur="inputDate"
                />
              </div>
            </div>
            <p
              class="u-font-size-7 u-color-blue-base"
              :class="
                showInvalidDateMessage1
                  ? 'u-visibility-visible'
                  : 'u-visibility-hidden'
              "
            >
              {{ invalidDateMessage }}
            </p>
            <div
              v-if="allowCompare || showComparedToText"
              class="u-spacing-pv-s u-spacing-mt-m u-display-flex"
            >
              <rb-checkbox
                v-if="commonDateKey"
                v-model="useCompareTo"
                class="use-compare-to-checkbox"
              />
              <div>
                <span
                  class="u-font-size-5 u-color-orange-base u-font-weight-600"
                  >Compare to</span
                >
              </div>
            </div>
            <div
              v-if="allowCompare && compare"
              :class="{ disableCompareTo: !useCompareTo }"
            >
              <rb-select
                v-if="isActive"
                :send-details="true"
                :search-enable-threshold="20"
                :on-close="cannedCompareCalendarSelected"
                :options="compareCannedCalendars"
                class="u-width-100 date-compare--select-s"
              >
                <div
                  slot="trigger"
                  :class="compare ? 'daterangepicker-range-border compare' : ''"
                  class="u-display-flex u-flex-justify-content-space-between u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
                >
                  <span
                    class="u-text-overflow-ellipsis u-text-transform-capitalize u-font-size-6"
                    data-cy="selectedcomparecalendartitle"
                  >
                    {{ selectedCompareCannedCalendar.title }}</span
                  >
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    data-cy="CalendarDropDownArrow"
                    :icon="'arrow-down'"
                  />
                </div>
              </rb-select>
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-pv-s"
              >
                <div class="daterangepicker-date-input rb-control compare">
                  <input
                    ref="startDateCompare"
                    data-cy="dateRange_date_option"
                    :readonly="enableReadOnly"
                    placeholder="MMM DD, YYYY"
                    :value="startDateCompare | dateFormat"
                    class="rb-input"
                    @keydown.tab="keydown($event, 'startDateCompare')"
                    @input="typeDate($event, 'startDateCompare')"
                    @keyup.enter="typeDate($event, 'startDateCompare', true)"
                    @focus="step = 'selectStartDateCompare'"
                    @blur="inputDate"
                  />
                </div>
                <span
                  class="u-color-grey-xx-light u-spacing-ph-s u-font-size-1"
                >
                  -
                </span>
                <div class="daterangepicker-date-input rb-control compare">
                  <input
                    ref="endDateCompare"
                    data-cy="dateRange_date_option"
                    :readonly="enableReadOnly"
                    placeholder="MMM DD, YYYY"
                    :value="endDateCompare | dateFormat"
                    class="rb-input"
                    @keydown.tab="keydown($event, 'endDateCompare')"
                    @input="typeDate($event, 'endDateCompare')"
                    @keyup.enter="typeDate($event, 'endDateCompare', true)"
                    @focus="step = 'selectEndDateCompare'"
                    @blur="inputDate"
                  />
                </div>
              </div>
            </div>
            <div v-if="!allowCompare && showComparedToText">
              <div class="u-display-flex u-flex-align-items-center">
                <div>{{ startDateCompare | dateFormat }}</div>
                <div class="u-color-grey-xx-light u-spacing-ph-s u-font-size-1">
                  -
                </div>
                <div>{{ endDateCompare | dateFormat }}</div>
              </div>
            </div>
            <p
              v-show="allowCompare || showComparedToText"
              v-if="showInvalidDateMessage2"
              class="u-font-size-7 u-color-orange-base"
            >
              {{ invalidDateMessage }}
            </p>
          </div>
          <div
            class="u-display-flex u-flex-justify-content-flex-end u-spacing-pt-s u-flex-align-items-center"
          >
            <span class="u-display-flex u-flex-align-items-center">
              <rb-button
                v-if="showReset"
                class="u-spacing-mr-s"
                :text="'Reset'"
                :disabled="!mutation"
                :type="'hollow'"
                :size="'s'"
                :click-fn="reset"
              />
              <rb-button
                data-cy="calendar-apply"
                :text="'Apply'"
                :type="'filled'"
                :size="'s'"
                :click-fn="submit"
              />
            </span>
          </div>
        </div>
      </div>
      <!-- eslint-disable -->
      <div
        v-if="this.minDate && this.maxDate"
        class="u-font-size-7 u-color-blue-base u-spacing-pv-s u-spacing-ph-l u-border-width-s u-border-top u-border-color-grey-xxx-light u-display-flex u-flex-align-items-center u-font-weight-normal"
      >
        <!-- eslint-enable -->
        <rb-icon
          :icon="'info-circle-outline'"
          class="rb-icon--small u-spacing-pr-m"
        />
        Data available from
        <span
          class="u-font-weight-bold u-spacing-pl-xs"
          data-cy="date_text_at_bottom_of_calendar"
          >{{ minDateInDisplayFormat }} - {{ maxDateInDisplayFormat }}</span
        >. Hence the date selection can be done only within this range.
      </div>

      <!-- Data Missing Info -->
      <div
        v-if="showDataMissingMsg"
        class="u-font-size-7 u-color-blue-base u-spacing-pv-s u-spacing-ph-l u-border-width-s u-border-top u-border-color-grey-xxx-light u-display-flex u-flex-align-items-center u-font-weight-normal"
      >
        <rb-icon
          :icon="'info-circle-outline'"
          class="rb-icon--small u-spacing-pr-m"
        />
        Data is not available between
        <span
          class="u-font-weight-bold u-spacing-pl-xs"
          data-cy="date_text_at_bottom_of_calendar"
          >{{ dataMissingDateRange }}
        </span>
        due to loss of access to
        {{ getTextForDataLossBanner }}
        services.
      </div>

      <!-- eslint-disable -->
      <html-component
        v-if="extraCalendarInfoVHtmlText"
        :html-string="extraCalendarInfoVHtmlText"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import DateRangePickerCalendar from './DateRangePickerCalendar';
import moment from 'moment';
import {
  getDateRanges,
  lastNWeeksPerviousPeriodFn,
  lastNWeeksSamePeriodLastYearFn,
  customPerviousPeriodFn,
  customSamePeriodLastYearFn,
  checkIfGivenDurationHas53rdWeek
} from '@/utils/helpers/date';
import { eventBus } from '@/utils/services/eventBus';
import HtmlComponent from '@/components/basic/html-component.vue';
export default {
  filters: {
    dateFormat: function (value) {
      return value ? value.format('MMM DD, YYYY') : '';
    }
  },
  components: { DateRangePickerCalendar, HtmlComponent },
  props: {
    showDataMissingMsg: {
      type: Boolean,
      default: false
    },
    dataMissingDateRange: {
      type: String,
      default: ''
    },
    showComparedToText: {
      type: Boolean,
      default: false
    },
    calendarCount: {
      type: Number,
      default: 2
    },
    emitOnCreate: {
      type: Boolean,
      default: false
    },
    allowCompare: {
      type: Boolean,
      default: true
    },
    maxDate: {
      type: String,
      default: undefined
    },
    minDate: {
      type: String,
      default: undefined
    },
    ranges: {
      type: Object,
      default: function () {
        return {};
      }
    },
    localStorageKey: {
      type: String,
      default: null
    },
    defaultRangeSelect: {
      type: String,
      default: 'last90Days'
    },
    defaultRangeSelectCompare: {
      type: String,
      default: null
    },
    triggerStyle: {
      type: String,
      default: 'rectangle'
    },
    showReset: {
      type: Boolean,
      default: true
    },
    useDifferentDateForCannedDates: {
      type: String,
      default: null
    },
    additionalRanges: {
      type: Object,
      default: function () {
        return {};
      }
    },
    dateMode: {
      type: Object,
      default: function () {
        return {
          mode: 'day'
        };
      }
    },
    commonDateKey: {
      type: String,
      default: null
    },
    selectionMode: {
      type: String,
      default: ''
    },
    lastWeek: {
      type: Object,
      default: function () {
        return {
          startDay: 'Saturday',
          endDay: 'Friday'
        };
      }
    },
    customMandatoryDateRanges: {
      type: Array,
      default: function () {
        return ['mtd', 'qtd', 'ytd', 'lastYear'];
      }
    },
    extraCalendarInfoVHtmlText: {
      type: String,
      default: null
    },
    page: {
      type: String,
      default: ''
    },
    enableDatePersistence: {
      type: Boolean,
      default: false
    },
    userPageMetadata: {
      type: Object,
      default: () => {}
    },
    showWeekNumbers: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      // selectionMode: 'multi-week',
      hoveredStartDate: null,
      hoveredEndDate: null,
      hoveredStartDateCompare: null,
      hoveredEndDateCompare: null,
      useCompareTo: true,
      validDateTypedAndRemained: false,
      invalidDateMessage: 'Date changed to latest available date',
      showInvalidDateMessage1: false,
      showInvalidDateMessage2: false,
      showRightPanel: false,
      mutation: false,
      id: null,
      selectedCannedCalendar: {},
      selectedCompareCannedCalendar: {},
      cannedCalendars: [],
      compareCannedCalendars: [],
      isActive: false,
      startDate: moment.utc(),
      endDate: moment.utc(),
      startDateCompare: moment.utc(),
      endDateCompare: moment.utc(),
      rangeSelect: null,
      rangeSelectCompare: null,
      compare: true,
      month: moment.utc().subtract(1, 'month').startOf('month'),
      step: null,
      compareRanges: {},
      dateText: '',
      compareDateText: '',
      compareDropdownSelection: undefined,
      customCannedRange: {
        title: 'Custom',
        key: 'custom',
        prevPeriodFn: customPerviousPeriodFn,
        samePeriodLastYearFn: customSamePeriodLastYearFn
      }
    };
  },
  computed: {
    getTextForDataLossBanner() {
      return (
        this.$store.getters.getRetailer.charAt(0).toUpperCase() +
        this.$store.getters.getRetailer.slice(1)
      );
    },
    enableReadOnly() {
      return this.selectionMode?.includes('week') || false;
    },
    maxDateInDisplayFormat: function () {
      return this.$options.filters.dateFormat(moment.utc(this.maxDate));
    },
    minDateInDisplayFormat: function () {
      return this.$options.filters.dateFormat(moment.utc(this.minDate));
    },
    nextMonth: function () {
      return moment.utc(this.month).add(1, 'month');
    },
    range: function () {
      const startDateCheck = this.checkForMinMaxScenarios(
        this.startDate,
        this.$refs.startDate
      );
      const endDateCheck = this.checkForMinMaxScenarios(
        this.endDate,
        this.$refs.endDate
      );
      if (!(startDateCheck || endDateCheck)) {
        this.showInvalidDateMessage1 = false;
      }
      return [this.startDate, this.endDate];
    },
    rangeCompare: function () {
      const startDateCompareCheck = this.checkForMinMaxScenarios(
        this.startDateCompare,
        this.$refs.startDateCompare
      );
      const endDateCompareCheck = this.checkForMinMaxScenarios(
        this.endDateCompare,
        this.$refs.endDateCompare
      );
      if (!(startDateCompareCheck || endDateCompareCheck)) {
        this.showInvalidDateMessage2 = false;
      }

      return [this.startDateCompare, this.endDateCompare];
    }
  },
  watch: {
    rangeSelect: function (rangeKey) {
      this.selectRange(rangeKey);
    },
    rangeSelectCompare: function (rangeKey) {
      if (rangeKey === null) {
        return;
      }

      this.selectRangeCompare(rangeKey);
    },
    range: function () {
      let predefinedRange = false;

      for (const rangeKey of Object.keys(this.ranges)) {
        const range = this.ranges[rangeKey];
        if (
          this.startDate.isSame(range.startDate) &&
          this.endDate.isSame(range.endDate)
        ) {
          predefinedRange = true;
          // 2nd condition - to prevent 'last week' being replaced by 'Last 7 days' and vice-versa, taking it as a predefined range.
          if (this.rangeSelect !== rangeKey && !this.ranges[this.rangeSelect]) {
            this.rangeSelect = rangeKey;
          }
        }
      }
      const diff = this.endDate.diff(this.startDate, 'day');
      if (
        diff >= 0 &&
        (this.rangeSelectCompare === null || !this.simulating) &&
        (this.step === 'selectStartDateCompare' || this.step === null)
      ) {
        if (
          !this.selectedCompareCannedCalendar.title ||
          this.selectedCompareCannedCalendar.title === `Previous ${this.period}`
        ) {
          this.following = true;
          this.startDateCompare = this.startDate.clone();
          this.endDateCompare = this.endDate.clone();
          this.startDateCompare = this.getPreviousPeriodDate(
            this.startDateCompare,
            diff,
            'start'
          );
          this.endDateCompare = this.getPreviousPeriodDate(
            this.endDateCompare,
            diff,
            'end'
          );
          this.selectedCompareCannedCalendar = {
            title: `Previous ${this.period}`
          };
          this.rangeSelectCompare = null;
        } else if (
          this.selectedCompareCannedCalendar.title ===
          `Same ${this.period} last year`
        ) {
          this.startDateCompare = this.getSamePeriodLastYearDate(
            this.startDate,
            'start',
            diff
          );
          this.endDateCompare = this.getSamePeriodLastYearDate(
            this.endDate,
            'end',
            diff
          );
          if (
            checkIfGivenDurationHas53rdWeek(this.startDate, this.endDate) ||
            checkIfGivenDurationHas53rdWeek(
              this.startDateCompare,
              this.endDateCompare
            )
          ) {
            this.startDateCompare = customSamePeriodLastYearFn(this.startDate);
            this.endDateCompare = customSamePeriodLastYearFn(this.endDate);
          }
        }
      }

      // Setting selected range as custom if the dates doesn't match any predefined ranges.
      if (!predefinedRange) {
        if (this.rangeSelect !== 'custom') {
          this.rangeSelect = 'custom';
          this.selectedCannedCalendar = this.customCannedRange;
        }
      }
      if (this.rangeSelect === 'custom') {
        this.selectedCannedCalendar = this.customCannedRange;
      }
    },
    rangeCompare: function () {
      if (this.following === true) {
        this.following = false;
        this.selectedCompareCannedCalendar = {
          title: `Previous ${this.period}`
        };
        this.simulating = false;
        return;
      }

      let predefinedRange = false;

      for (const rangeKey of Object.keys(this.compareRanges)) {
        const range = this.compareRanges[rangeKey];
        if (
          this.startDateCompare.isSame(range.startDate) &&
          this.endDateCompare.isSame(range.endDate)
        ) {
          predefinedRange = true;
          if (this.rangeSelectCompare !== rangeKey) {
            this.rangeSelectCompare = rangeKey;
          }
        }
      }

      if (!predefinedRange) {
        if (this.simulating) {
          if (
            (this.step !== null && this.step !== 'selectStartDate') ||
            this.rangeSelectCompare !== 'lastYear'
          ) {
            if (this.rangeSelectCompare === null) {
              return;
            }

            this.rangeSelectCompare = 'custom';
            this.selectedCompareCannedCalendar = this.customCannedRange;
          }
        } else {
          if (
            this.step !== null &&
            (this.step === 'selectStartDateCompare' ||
              this.step === 'selectEndDateCompare')
          ) {
            this.rangeSelectCompare = 'custom';
            this.selectedCompareCannedCalendar = this.customCannedRange;
          }
        }
      }
      this.simulating = false;
    }
  },
  created: function () {
    this.period = 'Period';
    this.id = 'date-range-' + this._uid;
    if (this.useDifferentDateForCannedDates) {
      this.formulateRanges(
        moment
          .utc(this.useDifferentDateForCannedDates)
          .startOf('day')
          .format('YYYY-MM-DD')
      );
    } else {
      this.formulateRanges(this.maxDate);
    }
    this.$on('submit', function (date) {
      this.dateText =
        this.$options.filters.dateFormat(this.startDate) +
        ' - ' +
        this.$options.filters.dateFormat(this.endDate);
      this.compareDateText =
        this.$options.filters.dateFormat(this.startDateCompare) +
        ' - ' +
        this.$options.filters.dateFormat(this.endDateCompare);
    });
    this.initialize(true);
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside);
    }

    eventBus.$on('showDatePicker', this.showDatePicker);
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  methods: {
    checkForMinMaxScenarios(date, input) {
      if (!date.isSameOrBefore(moment.utc(this.maxDate))) {
        this.handleSameOrBeforeMaxDateScenario(date, input);
        return true;
      } else if (!date.isSameOrAfter(moment.utc(this.minDate))) {
        this.handleSameOrAfterMinDateScenario(date, input);
        return true;
      }

      return false;
    },
    onShow() {
      this.resetHoverDate();
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
    },
    inputDate() {
      this.step = null;
      this.startDate = this.startDate.clone();
      this.endDate = this.endDate.clone();
      // this.startDateCompare = this.startDateCompare.clone();
      // this.endDateCompare = this.endDateCompare.clone();

      if (this.endDate.isBefore(this.startDate)) {
        this.endDate = this.startDate.clone();
      }
      if (this.endDateCompare.isBefore(this.startDateCompare)) {
        this.endDateCompare = this.startDateCompare.clone();
      }
      // this.resetHoverDate();
    },
    resetHoverDate() {
      this.hoveredStartDate = null;
      this.hoveredEndDate = null;
      this.hoveredStartDateCompare = null;
      this.hoveredEndDateCompare = null;
    },
    onHoverLeave() {
      this.resetHoverDate();
    },
    onHover(date) {
      if (this.step === 'selectStartDate') {
        this.hoveredStartDate = date;
        this.hoveredEndDate = date;
      } else if (this.step === 'selectEndDate') {
        if (date.isBefore(this.hoveredStartDate)) {
          this.hoveredEndDate = this.hoveredStartDate
            .clone()
            .endOf('week')
            .startOf('day');
          return;
          // eslint-disable-next-line brace-style
        }
        // Case for selecting the end date directly.
        else if (this.startDate) {
          this.hoveredEndDate = date.clone().endOf('week').startOf('day');
          return;
        }
        this.hoveredEndDate = date;
      } else if (this.step === 'selectStartDateCompare') {
        this.hoveredStartDateCompare = date;
        this.hoveredEndDateCompare = date;
      } else if (this.step === 'selectEndDateCompare') {
        if (date.isBefore(this.hoveredStartDateCompare)) {
          this.hoveredEndDateCompare = this.hoveredStartDateCompare
            .clone()
            .endOf('week')
            .startOf('day');
          return;
        }
        this.hoveredEndDateCompare = date;
      }
    },
    handleSameOrAfterMinDateScenario(date, input) {
      let messageKey = 'showInvalidDateMessage2';
      let dateKey = 'startDate';

      switch (input) {
        case this.$refs.startDate:
          messageKey = 'showInvalidDateMessage1';
          dateKey = 'startDate';
          break;
        case this.$refs.endDate:
          messageKey = 'showInvalidDateMessage1';
          dateKey = 'endDate';
          break;
        case this.$refs.startDateCompare:
          messageKey = 'showInvalidDateMessage2';
          dateKey = 'startDateCompare';
          break;
        case this.$refs.endDateCompare:
          messageKey = 'showInvalidDateMessage2';
          dateKey = 'endDateCompare';
          break;
      }

      if (dateKey && this.minDate) {
        this[messageKey] = true;
        this.invalidDateMessage =
          'Data available only from ' +
          this.$options.filters.dateFormat(moment.utc(this.minDate)) +
          '.';
      }
    },
    handleSameOrBeforeMaxDateScenario(date, input) {
      let messageKey = 'showInvalidDateMessage2';
      let dateKey = 'startDate';

      switch (input) {
        case this.$refs.startDate:
          messageKey = 'showInvalidDateMessage1';
          dateKey = 'startDate';
          break;
        case this.$refs.endDate:
          messageKey = 'showInvalidDateMessage1';
          dateKey = 'endDate';
          break;
        case this.$refs.startDateCompare:
          messageKey = 'showInvalidDateMessage2';
          dateKey = 'startDateCompare';
          break;
        case this.$refs.endDateCompare:
          messageKey = 'showInvalidDateMessage2';
          dateKey = 'endDateCompare';
          break;
      }

      if (dateKey.indexOf('start') > -1) {
        this.invalidDateMessage = 'Dates changed to latest available dates.';
      } else {
        this.invalidDateMessage = 'Date changed to latest available date.';
      }

      this[messageKey] = true;
      if (!date.isValid()) {
        this.invalidDateMessage = 'Date is invalid, changed to previous value.';
      } else {
        this[dateKey] = moment.utc(this.maxDate);
      }

      setTimeout(() => {
        this[messageKey] = false;
      }, 5000);
    },
    keydown(event, _date) {
      const input = event.target;
      const date = moment.utc(input.value, 'MMM DD, YYYY', true);
      if (
        date.isValid() &&
        this[_date] &&
        !this[_date].isSame(date) &&
        date.isSameOrBefore(moment.utc(this.maxDate))
      ) {
        event.preventDefault();
        this.selectDate(date);
      } else {
        if (!date.isSameOrBefore(moment.utc(this.maxDate))) {
          this.handleSameOrBeforeMaxDateScenario(date, input);
        } else if (!date.isSameOrAfter(moment.utc(this.minDate))) {
          this.handleSameOrAfterMinDateScenario(date, input);
        }
      }
    },
    typeDate: function (input, _date, enter) {
      const date = moment.utc(input.target.value, 'MMM DD, YYYY', true);
      if (
        date.isValid() &&
        this[_date] &&
        !this[_date].isSame(date) &&
        date.isSameOrBefore(moment.utc(this.maxDate))
      ) {
        if (enter) {
          this.selectDate(date);
        } else {
          this.selectDate(date, false);
        }
      } else {
        if (enter) {
          if (!date.isSameOrBefore(moment.utc(this.maxDate))) {
            this.handleSameOrBeforeMaxDateScenario(date, input.target);
          } else if (!date.isSameOrAfter(moment.utc(this.minDate))) {
            this.handleSameOrAfterMinDateScenario(date, input.target);
          }
          this.nextStep();
        }
      }
    },
    initialize(fromCreated) {
      let date = {};
      let startDate, endDate, startDateCompare, endDateCompare;
      // this.compareDropdownSelection = undefined;
      let commonLocalStoredDate = null;
      if (this.commonDateKey) {
        commonLocalStoredDate = JSON.parse(
          localStorage.getItem(this.commonDateKey)
        );
      }

      if (
        this.enableDatePersistence &&
        this.userPageMetadata?.page?.[this.page]
      ) {
        if (this.userPageMetadata?.page?.[this.page]?.calendarSelection) {
          localStorage.setItem(
            this.localStorageKey,
            JSON.stringify(
              this.userPageMetadata.page[this.page].calendarSelection
            )
          );
        }
      }

      if (this.localStorageKey) {
        this.validDateTypedAndRemained = false;
        let localStoredDate = (
          JSON.parse(localStorage.getItem(this.localStorageKey)) || {}
        ).date_range;
        if (commonLocalStoredDate) {
          localStoredDate = commonLocalStoredDate;
        }

        this.step === null;
        this.mutation = false;
        if (localStoredDate) {
          this.useCompareTo = true;
          if (localStoredDate.compare === false) {
            this.useCompareTo = false;
          }

          this.simulating = true;
          if (!localStoredDate.compare_name) {
            this.following = true;
          }
          if (localStoredDate.name && localStoredDate.name !== 'custom') {
            if (!this.ranges[localStoredDate.name]) {
              this.rangeSelect = this.defaultRangeSelect;
            } else {
              this.rangeSelect = localStoredDate.name;
            }
            startDate = this.ranges[this.rangeSelect].startDate.clone();
            endDate = this.ranges[this.rangeSelect].endDate.clone();
          } else {
            this.rangeSelect = localStoredDate.name;
            this.startDate = moment.utc(localStoredDate.from);
            this.endDate = moment.utc(localStoredDate.to);
            startDate = this.startDate.clone();
            endDate = this.endDate.clone();
          }
          if (this.rangeSelect) this.selectRange(this.rangeSelect);
          this.rangeSelectCompare = localStoredDate.compare_name;
          if (this.rangeSelectCompare) {
            let diff;
            if (startDate && endDate) {
              diff = endDate.diff(startDate, 'day');
            } else {
              diff = this.endDate.diff(this.startDate, 'day');
            }
            if (this.rangeSelectCompare === 'lastYear') {
              this.startDateCompare = this.getSamePeriodLastYearDate(
                startDate,
                'start',
                diff
              );
              this.endDateCompare = this.getSamePeriodLastYearDate(
                endDate,
                'end',
                diff
              );
              if (
                checkIfGivenDurationHas53rdWeek(startDate, endDate) ||
                checkIfGivenDurationHas53rdWeek(
                  this.startDateCompare,
                  this.endDateCompare
                )
              ) {
                this.startDateCompare = customSamePeriodLastYearFn(startDate);
                this.endDateCompare = customSamePeriodLastYearFn(endDate);
              }

              startDateCompare = this.startDateCompare.clone();
              endDateCompare = this.endDateCompare.clone();
            } else {
              this.startDateCompare = moment.utc(localStoredDate.compare_from);
              this.endDateCompare = moment.utc(localStoredDate.compare_to);
              startDateCompare = this.startDateCompare.clone();
              endDateCompare = this.endDateCompare.clone();
            }
          } else {
            const difference = endDate.diff(startDate, 'day');
            if (difference >= 0) {
              this.startDateCompare = this.getPreviousPeriodDate(
                startDate.clone(),
                difference,
                'start'
              );
              this.endDateCompare = this.getPreviousPeriodDate(
                endDate.clone(),
                difference,
                'end'
              );
              startDateCompare = this.startDateCompare.clone();
              endDateCompare = this.endDateCompare.clone();
            }
          }
        } else {
          this.rangeSelect = this.defaultRangeSelect;
          this.rangeSelectCompare = this.defaultRangeSelectCompare;
          startDate = this.ranges[this.rangeSelect].startDate.clone();
          endDate = this.ranges[this.rangeSelect].endDate.clone();
          if (this.rangeSelectCompare) {
            startDateCompare =
              this.compareRanges[this.rangeSelectCompare].startDate.clone();
            endDateCompare =
              this.compareRanges[this.rangeSelectCompare].endDate.clone();
          } else {
            this.following = true;
            const diff = endDate.diff(startDate, 'day');
            if (diff >= 0) {
              startDateCompare = this.getPreviousPeriodDate(
                startDate.clone(),
                diff,
                'start'
              );
              endDateCompare = this.getPreviousPeriodDate(
                endDate.clone(),
                diff,
                'end'
              );
            }
          }
        }

        setTimeout(
          function () {
            this.submit(true);
          }.bind(this),
          1000
        );

        date = {
          name: this.rangeSelect,
          from: startDate.clone().format('YYYY-MM-DD'),
          to: endDate.clone().format('YYYY-MM-DD'),
          compare: this.useCompareTo,
          compare_name: this.rangeSelectCompare,
          compare_from: startDateCompare.clone().format('YYYY-MM-DD'),
          compare_to: endDateCompare.clone().format('YYYY-MM-DD')
        };

        this.dateText =
          this.$options.filters.dateFormat(startDate) +
          ' - ' +
          this.$options.filters.dateFormat(endDate);

        this.compareDateText =
          this.$options.filters.dateFormat(startDateCompare) +
          ' - ' +
          this.$options.filters.dateFormat(endDateCompare);
      } else {
        if (!this.rangeSelect) {
          this.rangeSelect = this.defaultRangeSelect;
          startDate = this.ranges[this.rangeSelect].startDate.clone();
          endDate = this.ranges[this.rangeSelect].endDate.clone();
        } else {
          startDate = this.startDate;
          endDate = this.endDate;
        }

        if (fromCreated) {
          this.dateText =
            this.$options.filters.dateFormat(startDate) +
            ' - ' +
            this.$options.filters.dateFormat(endDate);
        }

        date = {
          from: startDate.clone().format('YYYY-MM-DD'),
          to: endDate.clone().format('YYYY-MM-DD'),
          name: this.rangeSelect
        };
      }
      this.$emit('create', date, fromCreated);
    },
    getSamePeriodLastYearDate(date, id, diff) {
      var returnDate = null;
      if (this.selectedCannedCalendar.samePeriodLastYearFn) {
        return this.selectedCannedCalendar.samePeriodLastYearFn(date, id);
      }
      if (this.dateMode?.mode === 'week') {
        returnDate = lastNWeeksSamePeriodLastYearFn(date, id);
      } else if (this.dateMode?.mode === 'month') {
        let month = date.clone().startOf('day').month();
        if (id === 'start') {
          returnDate = date
            .clone()
            .subtract(1, 'year')
            .month(month)
            .startOf('month')
            .startOf('day');
        } else {
          const endDate = date.clone();
          const startDate = endDate.subtract(diff, 'day');
          // ref Anand43512
          month = startDate.clone().startOf('day').month();
          returnDate = startDate
            .subtract(1, 'year')
            .month(month)
            .endOf('month')
            .startOf('day');
        }
      } else if (this.dateMode?.mode === 'quarter') {
        let quarter = date.clone().startOf('day').quarter();
        if (id === 'start') {
          returnDate = date
            .clone()
            .subtract(1, 'year')
            .quarter(quarter)
            .startOf('quarter')
            .startOf('day');
        } else {
          const endDate = date.clone();
          const startDate = endDate.subtract(diff, 'day');
          // ref Anand43512
          quarter = startDate.clone().startOf('day').quarter();
          returnDate = startDate
            .subtract(1, 'year')
            .quarter(quarter)
            .endOf('quarter')
            .startOf('day');
        }
      } else {
        returnDate = date.clone().subtract(1, 'year').startOf('day');
      }
      return returnDate;
    },
    getPreviousPeriodDate(date, diff, id) {
      var returnDate = null;
      if (this.selectedCannedCalendar.prevPeriodFn) {
        return this.selectedCannedCalendar.prevPeriodFn(date, diff);
      }
      if (this.dateMode?.mode === 'month') {
        if (id === 'start') {
          returnDate = date
            .clone()
            .subtract(1, 'month')
            .startOf('month')
            .startOf('day');
        } else {
          const endDate = date.clone();
          // ref Anand2462436
          const startDate = endDate.subtract(diff, 'day');
          returnDate = startDate
            .subtract(1, 'month')
            .endOf('month')
            .startOf('day');
        }
      } else if (this.dateMode?.mode === 'quarter') {
        if (id === 'start') {
          returnDate = date
            .clone()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .startOf('day');
        } else {
          const endDate = date.clone();
          // ref Anand2462436
          const startDate = endDate.subtract(diff, 'day');
          returnDate = startDate
            .subtract(1, 'quarter')
            .endOf('quarter')
            .startOf('day');
        }
      } else {
        returnDate = date.subtract(diff + 1, 'day');
      }
      return returnDate;
    },
    cannedCompareCalendarSelected(context, val, programaticallyTriggered) {
      this.mutation = true;
      if (val && val.length > 0) {
        this.rangeSelectCompare = val[0].key;
        this.selectedCompareCannedCalendar = val[0];
        if (this.rangeSelectCompare === 'custom') {
          this.$refs.startDateCompare.focus();
        } else if (this.rangeSelectCompare === null) {
          this.startDateCompare = this.startDate.clone();
          this.endDateCompare = this.endDate.clone();
          this.following = true;
          const diff = this.endDate.diff(this.startDate, 'day');
          if (diff >= 0) {
            this.startDateCompare = this.getPreviousPeriodDate(
              this.startDateCompare,
              diff,
              'start'
            );
            this.endDateCompare = this.getPreviousPeriodDate(
              this.endDateCompare,
              diff,
              'end'
            );
          }
          this.selectedCompareCannedCalendar = {
            title: `Previous ${this.period}`
          };
        } else if (this.rangeSelectCompare === 'lastYear') {
          const diff = this.endDate.diff(this.startDate, 'day');
          this.startDateCompare = this.getSamePeriodLastYearDate(
            this.startDate,
            'start',
            diff
          );
          this.endDateCompare = this.getSamePeriodLastYearDate(
            this.endDate,
            'end',
            diff
          );
          if (
            checkIfGivenDurationHas53rdWeek(this.startDate, this.endDate) ||
            checkIfGivenDurationHas53rdWeek(
              this.startDateCompare,
              this.endDateCompare
            )
          ) {
            this.startDateCompare = customSamePeriodLastYearFn(this.startDate);
            this.endDateCompare = customSamePeriodLastYearFn(this.endDate);
          }
        }
        if (!programaticallyTriggered) {
          this.compareDropdownSelection = this.rangeSelectCompare;
        }
      }
    },
    cannedCalendarSelected(context, val) {
      this.mutation = true;
      if (val && val.length > 0) {
        this.rangeSelect = val[0].key;
        this.selectedCannedCalendar = val[0];
        if (
          this.rangeSelect === 'custom' ||
          this.selectionMode?.includes('week')
        ) {
          this.$refs.startDate.focus();
        }
      }
    },
    toggle() {
      this.isActive = !this.isActive;
      setTimeout(
        function () {
          this.initialize();
          // if(!this.selectionMode) {
          this.$refs.startDate.focus();
          // }
        }.bind(this),
        100
      );
    },
    goToPrevMonth: function () {
      this.month = moment.utc(this.month).subtract(1, 'month');
    },
    goToNextMonth: function () {
      this.month = moment.utc(this.month).add(1, 'month');
    },
    selectRange: function (rangeKey) {
      let predefinedRange = false;
      for (const _rangeKey of Object.keys(this.ranges)) {
        const range = this.ranges[_rangeKey];
        if (rangeKey === _rangeKey) {
          predefinedRange = true;
          this.selectedCannedCalendar = range;
          if (!this.startDate.isSame(range.startDate)) {
            this.startDate = moment.utc(range.startDate);
          }
          if (!this.endDate.isSame(range.endDate)) {
            this.endDate = moment.utc(range.endDate);
          }
        }
      }

      if (!predefinedRange && this.step === null) {
        this.step = 'selectStartDate';
        this.$refs.startDate?.focus();
      }
      this.month = moment
        .utc(this.endDate)
        .subtract(1, 'month')
        .startOf('month');
      if (
        this.compareDropdownSelection === undefined &&
        !this.simulating &&
        this.dateMode?.mode === 'day'
      ) {
        if (rangeKey === 'ytd' || rangeKey === 'mtd') {
          this.rangeSelectCompare = 'lastYear';
          this.cannedCompareCalendarSelected(
            null,
            [
              {
                key: 'lastYear',
                title: `Same ${this.period} last year`
              }
            ],
            true
          );
        } else {
          this.rangeSelectCompare = null;
          this.cannedCompareCalendarSelected(
            null,
            [
              {
                key: null,
                title: `Previous ${this.period}`
              }
            ],
            true
          );
        }
      }
    },
    selectRangeCompare: function (rangeKey) {
      if (rangeKey === 'custom') {
        this.rangeSelectCompare = 'custom';
        this.selectedCompareCannedCalendar = {
          title: 'Custom'
        };
        return;
      } else if (rangeKey === 'lastYear') {
        this.rangeSelectCompare = 'lastYear';
        this.selectedCompareCannedCalendar = {
          title: `Same ${this.period} last year`
        };
        return;
      }

      for (const _rangeKey of Object.keys(this.compareRanges)) {
        const range = this.compareRanges[_rangeKey];
        if (rangeKey === _rangeKey) {
          this.selectedCompareCannedCalendar = range;
          if (!this.startDateCompare.isSame(range.startDate)) {
            this.startDateCompare = moment.utc(range.startDate);
          }
          if (!this.endDateCompare.isSame(range.endDate)) {
            this.endDateCompare = moment.utc(range.endDate);
          }
        }
      }
    },
    selectDate: function (date, nextStep) {
      // this.hoveredStartDate = null;
      // this.hoveredEndDate = null;
      this.validDateTypedAndRemained = true;
      this.mutation = true;
      if (this.step === 'selectStartDate') {
        if (this.selectionMode === 'week' || this.selectionMode === 'month') {
          this.hoveredStartDate = null;
          this.hoveredEndDate = null;
          this.startDate = date.startDate;
          this.endDate = date.endDate;
          this.nextStep();
          this.nextStep();
          this.nextStep();
          // this.step = null;
        } else if (this.selectionMode === 'multi-week') {
          this.startDate = date.startDate;
          // this.endDate = date.endDate;
          // this.nextStep();
        } else {
          this.startDate = date;
        }
      } else if (this.step === 'selectEndDate') {
        if (this.selectionMode === 'multi-week') {
          this.endDate = date.endDate;
          this.hoveredStartDate = null;
          this.hoveredEndDate = null;
          this.nextStep();
          this.nextStep();
        } else if (date.isBefore(this.startDate)) {
          this.endDate = this.startDate.clone();
        } else {
          this.endDate = date;
        }
        // custom needs to be handled differently for reporting (wbr, mbr, qbr)
        if (this.rangeSelect === 'custom' && this.dateMode?.mode !== 'day') {
          this.rangeSelectCompare = 'custom';
        }
      } else if (this.step === 'selectStartDateCompare') {
        if (this.selectionMode === 'week') {
          this.hoveredStartDateCompare = null;
          this.hoveredEndDateCompare = null;
          this.startDateCompare = date.startDate;
          this.endDateCompare = date.endDate;
          this.nextStep();
          this.nextStep();
          this.nextStep();
          // this.step = null;
        } else if (this.selectionMode === 'multi-week') {
          this.startDateCompare = date.startDate;
          // this.endDate = date.endDate;
          // this.nextStep();
        } else {
          this.startDateCompare = date;
        }
      } else if (this.step === 'selectEndDateCompare') {
        if (this.selectionMode === 'multi-week') {
          this.endDateCompare = date.endDate;
          this.hoveredStartDateCompare = null;
          this.hoveredEndDateCompare = null;
          this.nextStep();
          this.nextStep();
        } else if (date.isBefore(this.startDateCompare)) {
          this.endDateCompare = this.startDateCompare.clone();
        } else {
          this.endDateCompare = date;
        }
      }
      if (nextStep !== false) {
        this.nextStep();
        this.validDateTypedAndRemained = false;
      } else {
        this.validDateTypedAndRemained = true;
      }
    },
    nextStep: function () {
      if (this.step === 'selectStartDate') {
        this.step = 'selectEndDate';
        this.$refs.endDate.focus();
      } else if (this.step === 'selectEndDate') {
        if (this.allowCompare) {
          this.step = 'selectStartDateCompare';
          this.$refs.startDateCompare.focus();
        } else {
          this.step = null;
          this.$refs.endDate.blur();
        }
      } else if (this.step === 'selectStartDateCompare') {
        this.step = 'selectEndDateCompare';
        this.$refs.endDateCompare.focus();
      } else if (this.step === 'selectEndDateCompare') {
        this.step = null;
        this.$refs.endDateCompare.blur();
      }
    },
    setDateExternal(dateObject) {
      this.rangeSelect = dateObject.name || this.rangeSelect;
      this.startDate = dateObject.startDate || this.startDate;
      this.endDate = dateObject.to || this.endDate;
      this.useCompareTo = dateObject.useCompareTo || this.useCompareTo;
      this.rangeSelectCompare =
        dateObject.compare_name || this.rangeSelectCompare;
      this.startDateCompare = dateObject.compare_from || this.startDateCompare;
      this.endDateCompare = dateObject.compare_to || this.endDateCompare;
    },
    submit: function (fromInit) {
      setTimeout(
        function () {
          const date = {
            name: this.rangeSelect,
            from: this.startDate.format('YYYY-MM-DD'),
            to: this.endDate.format('YYYY-MM-DD'),
            compare: this.useCompareTo,
            compare_name: this.rangeSelectCompare,
            compare_from: this.startDateCompare.format('YYYY-MM-DD'),
            compare_to: this.endDateCompare.format('YYYY-MM-DD')
          };

          if (fromInit === true) {
            if (this.emitOnCreate) {
              this.$emit('submit', date);
            }
          } else {
            this.$emit('submit', date);
            this.$refs.trigger.click();
            this.nextStep();
          }
        }.bind(this),
        150
      );
    },
    reset: function () {
      this.initialize();
      this.$emit('reset');
    },
    formulateRanges(maxDate) {
      const today = maxDate;
      const additionalRangesConfigs = {
        last7Days: {
          key: 'last7Days',
          title: 'Last 7 days',
          startDate: moment.utc(today).subtract(6, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day'),
          prevPeriodFn: customPerviousPeriodFn,
          samePeriodLastYearFn: customSamePeriodLastYearFn
        },
        last30Days: {
          key: 'last30Days',
          title: 'Last 30 days',
          startDate: moment.utc(today).subtract(29, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day'),
          prevPeriodFn: customPerviousPeriodFn,
          samePeriodLastYearFn: customSamePeriodLastYearFn
        },
        thisMonth: {
          key: 'thisMonth',
          title: 'Current Month',
          startDate: moment.utc(today).startOf('month'),
          endDate: moment.utc(today).endOf('month').startOf('day')
        },
        thisQuarter: {
          key: 'thisQuarter',
          title: 'Current Quarter',
          startDate: moment.utc(today).startOf('quarter'),
          endDate: moment.utc(today).endOf('quarter').startOf('day')
        },
        last60Days: {
          key: 'last60Days',
          title: 'Last 60 days',
          startDate: moment.utc(today).subtract(59, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        last90Days: {
          key: 'last90Days',
          title: 'Last 90 days',
          startDate: moment.utc(today).subtract(89, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        previousWeek: {
          key: 'previousWeek',
          title: 'Previous Week',
          startDate: moment.utc(today).subtract(6, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        previousMonth: {
          key: 'previousMonth',
          title: 'Previous Month',
          startDate: moment.utc(today).subtract(1, 'month').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        previousQuarter: {
          key: 'previousQuarter',
          title: 'Previous Quarter',
          startDate: moment.utc(today).subtract(1, 'quarter').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        last12Months: {
          key: 'last12Months',
          title: 'Last 12 Months',
          startDate: moment.utc(today).subtract(364, 'day').startOf('day'),
          endDate: moment.utc(today).startOf('day')
        },
        lastYear: {
          key: 'lastYear',
          title: 'Last Year',
          startDate: moment
            .utc(today)
            .subtract(1, 'year')
            .startOf('year')
            .startOf('day'),
          endDate: moment.utc(today).subtract(1, 'year').endOf('year')
        },
        lastWeek: {
          key: 'lastWeek',
          title: 'Last Week',
          startDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(1, 'weeks')
            .startOf('week')
            .isoWeekday(this.lastWeek.startDay)
            .startOf('day'),
          endDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(1, 'weeks')
            .endOf('week')
            .isoWeekday(this.lastWeek.endDay)
            .startOf('day')
        },
        lastMonth: {
          key: 'lastMonth',
          title: 'Last Month',
          startDate: moment.utc(today).subtract(1, 'month').startOf('month'),
          endDate: moment.utc(today).subtract(1, 'month').endOf('month')
        },
        last4Week: {
          key: 'last4Week',
          title: 'Last 4 Weeks',
          prevPeriodFn: lastNWeeksPerviousPeriodFn,
          samePeriodLastYearFn: lastNWeeksSamePeriodLastYearFn,
          startDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(4, 'weeks')
            .startOf('week')
            .startOf('day'),
          endDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(1, 'weeks')
            .endOf('week')
            .startOf('day')
        },
        last13Week: {
          key: 'last13Week',
          title: 'Last 13 Weeks',
          prevPeriodFn: lastNWeeksPerviousPeriodFn,
          samePeriodLastYearFn: lastNWeeksSamePeriodLastYearFn,
          startDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(13, 'weeks')
            .startOf('week')
            .startOf('day'),
          endDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(1, 'weeks')
            .endOf('week')
            .startOf('day')
        },
        last52Week: {
          key: 'last52Week',
          title: 'Last 52 Weeks',
          prevPeriodFn: lastNWeeksPerviousPeriodFn,
          samePeriodLastYearFn: lastNWeeksSamePeriodLastYearFn,
          startDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(52, 'weeks')
            .startOf('week')
            .startOf('day'),
          endDate: moment
            .utc(today)
            .add(1, 'day')
            .subtract(1, 'weeks')
            .endOf('week')
            .startOf('day')
        }
      };

      const ranges = getDateRanges(
        today,
        this.dateMode?.mode,
        this.dateMode?.count,
        this.customMandatoryDateRanges
      );
      this.cannedCalendars = [];
      for (const range in ranges) {
        this.ranges[range] = ranges[range];
        this.cannedCalendars.push(ranges[range]);
        additionalRangesConfigs[range] = ranges[range];
      }

      if (this.additionalRanges && this.additionalRanges.add) {
        this.additionalRanges.add.map((item, index) => {
          if (additionalRangesConfigs[item]) {
            this.ranges[item] = additionalRangesConfigs[item];
            if (
              (this.additionalRanges.order &&
                this.additionalRanges.order[item]) ||
              this.additionalRanges.order?.[item] === 0
            ) {
              this.cannedCalendars.splice(
                this.additionalRanges.order[item],
                0,
                additionalRangesConfigs[item]
              );
            } else {
              this.cannedCalendars.push(additionalRangesConfigs[item]);
            }
          }
        });
      }

      if (this.additionalRanges && this.additionalRanges.overrideRange) {
        this.cannedCalendars = [];
        this.additionalRanges.overrideRange.map((item, index) => {
          if (additionalRangesConfigs[item]) {
            this.ranges[item] = additionalRangesConfigs[item];
            if (
              this.additionalRanges.order &&
              this.additionalRanges.order[item]
            ) {
              this.cannedCalendars.splice(
                this.additionalRanges.order[item],
                0,
                additionalRangesConfigs[item]
              );
            } else {
              this.cannedCalendars.push(additionalRangesConfigs[item]);
            }
          }
        });
      }

      if (this.additionalRanges && this.additionalRanges.disabledRange) {
        this.additionalRanges.disabledRange.map((item, index) => {
          if (additionalRangesConfigs[item]) {
            this.ranges[item] = additionalRangesConfigs[item];
            const disabledRange = {
              ...additionalRangesConfigs[item],
              disabled: true,
              tooltipText: 'There is no data available for the date range'
            };
            if (
              this.additionalRanges.order &&
              this.additionalRanges.order[item]
            ) {
              this.cannedCalendars.splice(
                this.additionalRanges.order[item],
                0,
                disabledRange
              );
            } else {
              this.cannedCalendars.push(disabledRange);
            }
          }
        });
      }

      // TODO : Add  support for additionalRanges.remove

      this.cannedCalendars.push(this.customCannedRange);

      this.compareCannedCalendars = [];
      this.compareCannedCalendars.push({
        key: null,
        title: `Previous ${this.period}`
      });

      this.compareCannedCalendars.push({
        key: 'lastYear',
        title: `Same ${this.period} last year`
      });

      this.compareCannedCalendars.push({
        key: 'custom',
        title: 'Custom'
      });
    },
    isInWhiteList(el) {
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      if (this.$refs.dropdownMenu !== undefined) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }

      if (this.$refs.trigger !== undefined) {
        const children = this.$refs.trigger.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      return false;
    },
    clickedOutside(event) {
      // if (!this.isInWhiteList(event.target)) {
      //   this.isActive = false;
      // }
    },
    showDatePicker() {
      setTimeout(() => {
        this.$refs.trigger?.click();
      }, 200);
    }
  }
};
</script>

<style lang="css" scoped>
.date-picker-trigger {
  font-size: 11px;
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  line-height: 1;
  vertical-align: top;
  font-family: inherit;
  border-radius: 4px;
  box-sizing: border-box;
  color: #8b8f93;
  border: 1px solid #e9eaeb;
  padding: 0px 12px;
  background: #fff;
  height: 36px;
}
.date-picker-trigger:hover,
.date-picker-trigger:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}

.rb-input {
  box-sizing: border-box;
  display: inline-flex;
  font-size: 13px;
  justify-content: flex-start;
  line-height: 1;
  position: relative;
  vertical-align: top;
  background-color: #fff;
  color: #2b333b;
  border: 1px solid #e9eaeb;
  max-width: 100%;
  width: 100%;
}

.rb-input:focus {
  outline: none;
}

.daterangepicker-row {
  background: #fff;
  box-shadow: 0 0 8px 0 #caccce;
}

.daterangepicker-date-input input {
  padding: 0px 8px !important;
  font-family: ProximaNova;
  outline: none !important;
  height: 30px !important;
}

.daterangepicker-date-input input:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #007cf6 !important;
}

.daterangepicker-date-input.compare input:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #ffa800 !important;
}

.disableCompareTo {
  pointer-events: none;
  opacity: 0.2;
}

.use-compare-to-checkbox {
  flex: 0;
}
</style>
