const getRowSpan = (params) => {
  if (params.data.asn_count > 1) {
    return params.data.asn_count;
  } else {
    return 1;
  }
};
const getPayload = () => {
  const payload = [
    {
      name: 'po_number',
      description: 'po_number',
      source: 'any(po_number)',
      unit: '',
      type: 'STRING',
      uiField: {
        uiLabel: 'PO Number',
        uiTooltip: 'The PO number as per Vendor Central.',
        uiType: 'string',
        uiOrder: 1,
        metadata: {
          rowSpan: getRowSpan,
          suppressMenu: false,
          sortable: true,
          decimalRoundOff: 0,
          isDefaultSortColumn: false,
          isDownloadable: true,
          isFormattingRequired: false,
          isFixed: 'left',
          showOnUi: true,
          sortOnColumn: true,
          tableColumnName: 'po_number',
          checkboxSelection: true
        }
      },
      listOfColumnsInvolvedInMeasure: ['po_number']
    },
    {
      name: 'po_status',
      uiField: {
        uiLabel: 'PO Status',
        uiTooltip: 'Current status of the PO as per the PO report.',
        uiType: 'string',
        uiOrder: 2,
        headerIcon: {
          icon: 'filter',
          toolTip: 'Filter by column'
        },
        metadata: {
          rowSpan: getRowSpan,
          width: 125,
          enableHeaderIconInterAction: true,
          showOnUi: true,
          isDownloadable: true,
          tableColumnName: 'po_status',
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'ordered_date',
      description: 'ordered_date',
      source: 'any(ordered_date)',
      unit: '',
      filterProductTag: ['MarketingIQ'],
      uiField: {
        uiLabel: 'Ordered On',
        uiTooltip: 'Ordered On date as per the PO report.',
        uiType: 'string',
        uiOrder: 3,
        metadata: {
          decimalRoundOff: 0,
          isDefaultSortColumn: false,
          isDownloadable: true,
          isFixed: false,
          suppressMenu: false,
          isFormattingRequired: false,
          showOnUi: true,
          sortOnColumn: true,
          tableColumnName: 'ordered_on'
        }
      },
      listOfColumnsInvolvedInMeasure: ['ordered_date']
    },
    {
      name: 'ship_window_start',
      uiField: {
        uiLabel: 'Ship Window Start',
        uiTooltip: 'Shipping window start date as per the PO report.',
        uiType: 'string',
        uiOrder: 4,
        metadata: {
          width: 140,
          tableColumnName: 'ship_window_start',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'ship_window_end',
      uiField: {
        uiLabel: 'Ship Window End',
        uiTooltip: 'Shipping window end date as per the PO report.',
        uiType: 'string',
        uiOrder: 5,
        metadata: {
          width: 140,
          tableColumnName: 'ship_window_end',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'days_past_shipment_window_end',
      uiField: {
        uiLabel: 'Days Past Ship Window End',
        uiTooltip:
          'The number of days from today since the ship window end date. This metric helps in identifying the number of days since the ship window date ended. NA indicates that the ship window end date is a future date.',
        uiType: 'number',
        uiOrder: 6,
        headerIcon: {
          icon: 'filter',
          toolTip: 'Filter by column'
        },
        metadata: {
          alignHeader: 'left',
          enableHeaderIconInterAction: true,
          tableColumnName: 'days_past_shipment_window_end',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'ordered_quantity_sum',
      uiField: {
        uiLabel: 'Ordered Quantity (Cases)',
        uiTooltip:
          'The number of cases ordered by Amazon as per the PO report.',
        uiType: 'number',
        uiOrder: 7,
        metadata: {
          tableColumnName: 'ordered_quantity_sum',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'accepted_quantity_sum',
      uiField: {
        uiLabel: 'Accepted Quantity (Cases)',
        uiTooltip: 'The number of cases accepted as per the PO report.',
        uiType: 'number',
        uiOrder: 8,
        metadata: {
          tableColumnName: 'accepted_quantity_sum',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'received_quantity_sum',
      uiField: {
        uiLabel: 'Received Quantity (Cases)',
        uiTooltip:
          'The number of cases received by Amazon at its Fulfilment center as per the PO report.',
        uiType: 'number',
        uiOrder: 9,
        metadata: {
          tableColumnName: 'received_quantity_sum',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'outstanding_cases',
      uiField: {
        uiLabel: 'Outstanding Quantity (Cases)',
        uiTooltip:
          'The number of outstanding cases reported by Amazon at its Fulfilment center as per the PO report.',
        uiType: 'number',
        uiOrder: 10,
        metadata: {
          tableColumnName: 'outstanding_cases',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'received_percentage_po',
      uiField: {
        uiLabel: 'Received Percentage',
        uiTooltip:
          'The received percentage is the ratio of cases received by the number of cases accepted Comment end . NA is displayed in the case where there are 0 accepted units.',
        uiType: 'percent',
        uiOrder: 11,
        headerIcon: {
          icon: 'filter',
          toolTip: 'Filter by column'
        },
        metadata: {
          enableHeaderIconInterAction: true,
          alignHeader: 'left',
          tableColumnName: 'received_percentage_po',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'vendor',
      uiField: {
        uiLabel: 'Vendor Code',
        uiTooltip: 'Vendor code for the Purchase Order as per the PO report.',
        uiType: 'string',
        uiOrder: 12,
        headerIcon: {
          icon: 'filter',
          toolTip: 'Filter by column'
        },
        metadata: {
          width: 125,
          enableHeaderIconInterAction: true,
          decimalRoundOff: 0,
          isDefaultSortColumn: false,
          isDownloadable: true,
          isFixed: false,
          isFormattingRequired: false,
          showOnUi: true,
          sortOnColumn: true,
          tableColumnName: 'vendor'
        }
      }
    },
    {
      name: 'ship_to_location',
      uiField: {
        uiLabel: 'Ship To Location',
        uiTooltip:
          'The location to which the Purchase Order has to be shipped as per the PO report.',
        uiType: 'string',
        uiOrder: 13,
        headerIcon: {
          icon: 'filter',
          toolTip: 'Filter by column'
        },
        metadata: {
          minWidth: 250,
          enableHeaderIconInterAction: true,
          tableColumnName: 'ship_to_location',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'ASN',
      uiField: {
        uiLabel: 'ASN #',
        uiTooltip:
          'Advanced Shipment Notification (ASN) number as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 14,
        metadata: {
          minWidth: 150,
          key_name: 'asn',
          subComponent: {
            name: 'RbLink',
            props: {
              text: 'asn',
              url: 'asn_url'
            }
          },
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'asn',
          showOnUi: true,
          isDownloadable: true,
          // 'isFixed': 'right',
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'shipment_id',
      uiField: {
        uiLabel: 'Shipment ID',
        uiTooltip:
          'Shipment ID or ARN (Amazon Reference Number) as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 15,
        metadata: {
          minWidth: 150,
          key_name: 'shipment_id',
          subComponent: {
            name: 'RbLink',
            props: {
              text: 'shipment_id',
              url: 'shipment_id_url'
            }
          },
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'shipment_id',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'shipment_status',
      uiField: {
        uiLabel: 'Shipment Status',
        uiTooltip:
          'Current status of the shipment as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 16,
        metadata: {
          minWidth: 150,
          key_name: 'shipment_status',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'shipment_status',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'estimated_delivery_date',
      uiField: {
        uiLabel: 'Estimated Delivery Date (YYYY-MM-DD)',
        uiTooltip:
          'The date when the shipment is expected to reach the fulfillment center as per the latest data downloaded from Vendor central.',
        uiType: 'custom',
        uiOrder: 17,
        metadata: {
          minWidth: 150,
          key_name: 'estimated_delivery_date',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'estimated_delivery_date',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'carrier_tracking_number',
      uiField: {
        uiLabel: 'Carrier Tracking Number (PRO number)',
        uiTooltip:
          'Carrier tracking number as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 18,
        metadata: {
          minWidth: 150,
          key_name: 'carrier_tracking_number',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'carrier_tracking_number',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'reference_id',
      uiField: {
        uiLabel: 'Shipment Reference Number',
        uiTooltip:
          'Shipment Reference Number associated to a PO as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 19,
        metadata: {
          minWidth: 150,
          key_name: 'reference_id',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'reference_id',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'carrier_scac',
      uiField: {
        uiLabel: 'Carrier SCAC',
        uiTooltip:
          'Carrier SCAC is a unique two-to-four-letter code as per the shipment report from Vendor Central.',
        uiType: 'custom',
        uiOrder: 20,
        metadata: {
          minWidth: 150,
          key_name: 'carrier_scac',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'carrier_scac',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'ship_mode',
      uiField: {
        uiLabel: 'Shipment Type',
        uiTooltip:
          'Shipment type as per the shipment report from Vendor Central. Shipment type can be either Truck Load (TL) or Less than Truckload (LTL).',
        uiType: 'custom',
        uiOrder: 21,
        metadata: {
          minWidth: 150,
          key_name: 'ship_mode',
          widget: 'columnListCell',
          enableHeaderIconInterAction: true,
          tableColumnName: 'ship_mode',
          showOnUi: true,
          isDownloadable: true,
          isFixed: false,
          sortOnColumn: true,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'asin',
      uiField: {
        uiLabel: '',
        uiTooltip: '',
        uiType: 'string',
        uiOrder: 0,
        metadata: {
          showOnUi: false,
          isDownloadable: false,
          tableColumnName: 'asin',
          isFixed: false,
          sortOnColumn: false,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'product_title',
      uiField: {
        uiLabel: '',
        uiTooltip: '',
        uiType: 'string',
        uiOrder: 0,
        metadata: {
          showOnUi: false,
          isDownloadable: false,
          tableColumnName: 'product_title',
          isFixed: false,
          sortOnColumn: false,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'product_url',
      uiField: {
        uiLabel: '',
        uiTooltip: '',
        uiType: 'string',
        uiOrder: 0,
        metadata: {
          showOnUi: false,
          isDownloadable: false,
          tableColumnName: 'product_url',
          isFixed: false,
          sortOnColumn: false,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'image_url',
      uiField: {
        uiLabel: '',
        uiTooltip: '',
        uiType: 'string',
        uiOrder: 0,
        metadata: {
          showOnUi: false,
          isDownloadable: false,
          tableColumnName: 'image_url',
          isFixed: false,
          sortOnColumn: false,
          isDefaultSortColumn: false
        }
      }
    },
    {
      name: 'client_id',
      uiField: {
        uiLabel: 'client_id',
        uiTooltip: 'client_id',
        uiType: 'string',
        uiOrder: 0,
        metadata: {
          tableColumnName: 'client_id',
          showOnUi: false,
          isDownloadable: false,
          isFixed: false,
          sortOnColumn: false,
          isDefaultSortColumn: false
        }
      }
    }
  ];
  payload.forEach((column) => {
    column.uiField.metadata.rowSpan = getRowSpan;
  });
  return payload;
};

module.exports = {
  getPayload
};
