import HttpService from '@/utils/services/http-service';
import { formatter } from '@/utils/helpers/formatter.js';
import { convertArrayToMap } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';
export default class DataService {
  widgetId;
  widget;
  page;
  pageId;
  globalViewId;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    sortedLegends: [],
    legendValues: {},
    noData: false
  };

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getMetadata() {
    try {
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false
      };
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
    }
  }

  async getData(requestCopy, accessKeys, keyToCheck, clientKey) {
    try {
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      const { data } = await HttpService.post(
        'DASHBOARD_SERVICE',
        requestCopy,
        { append: '/entity/metrics/data' },
        { widgetId: this.widgetId, hashingKeysToIgnore: ['where'] }
      );
      const { entityData } = data;
      if (entityData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        this.data = {
          sortedLegends: this.getSortedLegends(
            entityData,
            accessKeys,
            keyToCheck
          ),
          legendValues: this.getLegends(entityData, accessKeys, clientKey),
          error: false,
          load: false,
          noData: entityData.length === 0
        };
      }
    } catch (e) {
      if (HttpService.isCancelRequestError(e)) {
        return e;
      }
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
      return e;
    }
  }

  getSortedLegends(entityArray, accessKeys, keyToCheck) {
    const flatMap = convertArrayToMap(entityArray[0].data);
    const sortedLegends = [];
    accessKeys.forEach((item) => {
      const obj = {};
      obj[keyToCheck] = flatMap[item.brandKey];
      sortedLegends.push(obj);
    });
    return sortedLegends;
  }

  getUnitLegend(flatMap, brandKey, shareKey, precision = 1) {
    if (flatMap[shareKey]) {
      return `${flatMap[brandKey]} (${formatter(
        flatMap[shareKey],
        'percentage',
        undefined,
        precision
      )})`;
    } else {
      return `${flatMap[brandKey]} (${formatter(
        0,
        'percentage',
        undefined,
        precision
      )})`;
    }
  }

  getLegends(entityArray, accessKeys, clientKey) {
    const flatMap = convertArrayToMap(entityArray[0].data);
    const legendsMap = {};
    const clientAccessKey = accessKeys[0];
    legendsMap[clientKey] = this.getUnitLegend(
      flatMap,
      clientAccessKey.brandKey,
      clientAccessKey.brandShareKey
    );
    for (let i = 1; i < accessKeys.length; i++) {
      const accessKey = accessKeys[i];
      legendsMap[flatMap[accessKey.brandKey]] = this.getUnitLegend(
        flatMap,
        accessKey.brandKey,
        accessKey.brandShareKey
      );
    }
    return legendsMap;
  }
}
