var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[(_vm.text)?_c('div',{staticClass:"u-color-grey-white u-text-case-title u-display-flex u-flex-align-items-center u-flex-justify-content-center",class:[_vm.avatarFontSize, _vm.iconTextSpacing],style:({
      'background-color': _vm.getAvatarColor,
      'border-radius': '50%',
      'max-height': _vm.diameter,
      'min-height': _vm.diameter,
      'min-width': _vm.diameter,
      'max-width': _vm.diameter
    })},[_vm._v(" "+_vm._s(_vm.text[0])+" ")]):_vm._e(),(_vm.hasText && _vm.text)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      placement: _vm.tippyProps.placement,
      offset: _vm.tippyProps.offset,
      arrow: _vm.tippyProps.arrow,
      interactive: _vm.tippyProps.interactive,
      distance: _vm.tippyProps.distance
    }),expression:"{\n      placement: tippyProps.placement,\n      offset: tippyProps.offset,\n      arrow: tippyProps.arrow,\n      interactive: tippyProps.interactive,\n      distance: tippyProps.distance\n    }"}],staticClass:"u-color-grey-mid-light",class:[_vm.textFontSize, _vm.extraTextClass],attrs:{"title":_vm.tippyProps.title}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(!_vm.text)?_c('div',{staticClass:"u-color-grey-mid-light"},[_vm._v(" NA ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }