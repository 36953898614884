<template>
  <div class="u-font-size-5 target_vs_recovery_chart_wrapper">
    <widgetContainer
      :key="myKey"
      :header-options="uiHeaderOptions"
      :footer-options="uiFooterOptions"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isChartLoading"
      :header-container-grid-styles="headerContainerGridStyles"
      :footer-container-grid-styles="footerContainerGridStyles"
      :last-updated-date="lastUpdatedDate"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <div v-if="showWidgetData">
          <div class="u-display-flex u-flex-align-items-center u-spacing-pb-s">
            <RollUpBy
              class="roll-up-by u-spacing-pr-m"
              label="Time Period:"
              :value="selectedTimePeriod"
              :options="timePeriodOptions"
              @onRollUpChange="timePeriodChanged"
            />
          </div>
          <div class="custom-metrics-wrapper u-spacing-pb-m">
            <div
              class="custom-metrics-wrapper__left u-spacing-mt-s u-spacing-mr-m"
            >
              <div
                v-for="item in staticMetricsLeft"
                :key="item.name"
                class="custom-metric custom-metric-static hover-disabled u-spacing-ph-m u-spacing-pb-m u-spacing-pt-s u-cursor-default u-height-100"
              >
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  popper-class="rra-popper-class"
                  :visible-arrow="false"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    {{ item.label }}
                  </div>
                  <div slot="content">
                    <div
                      class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                    >
                      <span class="u-color-grey-base"> {{ item.label }}</span>
                    </div>
                    <div class="u-spacing-mv-m u-font-size-6">
                      <span class="u-color-grey-light">{{
                        metricTooltips[item.keyName]
                      }}</span>
                    </div>
                  </div>
                </el-tooltip>
                <metric
                  class="u-spacing-mt-s"
                  size="16-14"
                  :config="metricDisplayConfig[item.label]"
                  :data="metricData[item.label]"
                />
              </div>
              <div
                v-for="(item, index) in localMetricDisplayList"
                :key="index"
                class="custom-metric u-spacing-ph-m u-spacing-pb-m u-spacing-pt-s"
                :class="{ 'u-cursor-pointer': showChart }"
                :style="getMetricStyle(item)"
                @click="changeMetric(item)"
              >
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  popper-class="rra-popper-class"
                  :visible-arrow="false"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center"
                    v-html="getHTML(item.label)"
                  >
                    <!-- <rb-icon
                      :icon="getMetricIcon(item)"
                      class="rb-icon--small u-spacing-mr-xs u-color-grey-lighter"
                    /> -->
                    <!-- {{ item.label }} -->
                  </div>
                  <div slot="content">
                    <div
                      class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                    >
                      <span
                        class="u-color-grey-base"
                        v-html="item.label"
                      />
                    </div>
                    <div class="u-spacing-mv-m u-font-size-6">
                      <span class="u-color-grey-light">{{
                        formattedTooltip(item)
                      }}</span>
                    </div>
                    <div
                      v-if="isIncludesSmartMatch(item)"
                      class="u-display-flex u-flex-direction-row u-font-size-6"
                    >
                      <rb-icon
                        class="u-flex-0 rb-icon--small u-color-grey-lighter u-spacing-mr-xs"
                        icon="info-circle-outline"
                      />
                      <span class="u-color-grey-light"
                        >This metric includes
                        <span class="u-font-weight-600">Smart match</span
                        >.</span
                      >
                    </div>
                  </div>
                </el-tooltip>
                <div>
                  <metric
                    class="u-spacing-mt-s u-spacing-mb-xs"
                    size="16-14"
                    :config="metricDisplayConfig[item.label]"
                    :data="metricData[item.label]"
                  />
                  <span
                    v-tippy="{ placement: 'bottom-start', arrow: false }"
                    :title="getMetricTooltip(item)"
                    class="u-color-grey-mid-light u-font-size-7"
                  >
                    ({{ metricPercentage(item) }})
                  </span>
                </div>
              </div>
              <div
                class="custom-metric custom-metric-static hover-disabled u-spacing-ph-m u-spacing-pb-m u-spacing-pt-s u-cursor-default"
                :style="{
                  backgroundColor:
                    metricColorIconObject[differenceMetricSelected.label]
                      .color || 'unset'
                }"
              >
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  popper-class="rra-popper-class"
                  :visible-arrow="false"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <span
                      class="u-spacing-mr-xs u-flex-0"
                      v-html="getHTML(differenceMetricSelected.label)"
                    />
                  </div>
                  <div slot="content">
                    <div
                      class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                    >
                      <span class="u-color-grey-base">
                        {{ differenceMetricSelected.label }}</span
                      >
                    </div>
                    <div class="u-spacing-mv-m u-font-size-6">
                      <span class="u-color-grey-light">{{
                        metricTooltips[differenceMetricSelected.keyName]
                      }}</span>
                    </div>
                  </div>
                </el-tooltip>
                <metric
                  class="u-spacing-mt-s u-spacing-mb-xs"
                  size="16-14"
                  :config="metricDisplayConfig[differenceMetricSelected.label]"
                  :data="metricData[differenceMetricSelected.label]"
                />
                <span
                  v-tippy="{ placement: 'bottom-start', arrow: false }"
                  :title="getMetricTooltip(differenceMetricSelected)"
                  class="u-color-grey-mid-light u-font-size-7"
                >
                  ({{ metricPercentage(differenceMetricSelected) }})
                </span>
              </div>
            </div>
            <div class="custom-metrics-wrapper__right u-spacing-mt-s">
              <div
                v-for="(item, index) in staticMetrics"
                :key="index"
                class="custom-metric custom-metric-static hover-disabled u-spacing-ph-m u-spacing-pb-m u-spacing-pt-s u-cursor-default u-height-100"
              >
                <el-tooltip
                  placement="bottom"
                  effect="light"
                  popper-class="rra-popper-class"
                  :visible-arrow="false"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    <!-- <rb-icon
                      :icon="metricColorIconObject[item.label].icon"
                      class="rb-icon--small u-spacing-mr-xs"
                    /> -->
                    {{ item.label }}
                  </div>
                  <div slot="content">
                    <div
                      class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                    >
                      <span class="u-color-grey-base"> {{ item.label }}</span>
                    </div>
                    <div class="u-spacing-mv-m u-font-size-6">
                      <span class="u-color-grey-light">{{
                        metricTooltips[item.keyName]
                      }}</span>
                    </div>
                  </div>
                </el-tooltip>

                <metric
                  class="u-spacing-mt-s"
                  size="16-14"
                  :config="metricDisplayConfig[item.label]"
                  :data="metricData[item.label]"
                />
                <!-- :config="{tag1Unit:{pre:'',roundoff:'',suff:'Hrs'}}" -->
              </div>
            </div>
          </div>
          <div
            v-if="showChart && enableRollup"
            class="u-display-flex u-flex-align-items-center"
          >
            <RollUpBy
              class="roll-up-by u-spacing-pr-m"
              label="Roll Up By:"
              :value="selectedRollUp"
              :options="rollUpOptions"
              @onRollUpChange="rollUpChanged"
            />
            <!-- <div
              v-if="showInProgressInfo"
              v-tippy="{placement: 'bottom', arrow: true}"
              title="It can take upto 3 months to recover some of the disputes. Data may still be in progress."
              class="u-display-flex u-flex-align-items-center u-border-left u-border-color-grey-xx-light u-border-width-s"
            >
              <rb-icon
                icon="in-progress-dotted"
                class="rb-icon--medium u-color-blue-base u-spacing-mr-xs u-spacing-pl-m"
              />
              <span class="u-color-grey-base u-font-size-6 u-spacing-pl-m">Recovery in Progress</span>
            </div> -->
          </div>
          <chart-with-legends
            ref="chartWithLegends"
            :show-metrics="false"
            :show-powered-by-c-i-q-text="true"
            :show-chart="showChart"
            :chart-config="chartConfig"
            :chart-data="chartData"
            :plot-on-y-axis-only="true"
            :custom-chart-class="[]"
            :show-padding-between="false"
            @updateStroke="updateStrokeLines"
          />
        </div>
        <div v-else>
          <noDataState
            showable-text="Date is not compatible hence there is no data"
            date-select-trigger-text="Change Date"
            icon="calendar"
            toggle-type="date-range"
          />
        </div>
      </template>
    </widgetContainer>
  </div>
</template>

<script>
import Vue from 'vue';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import chipSelect from '@/components/basic/chipSelect.vue';
import metric from '@/components/basic/metric';
import loader from '@/components/basic/loader';
import ToggleChartVisibility from '@/components/widgets/custom_widgets/custom_widget_components/charts/ToggleChartVisibility.vue';
import { cloneDeep, flatten, isEqual } from 'lodash';
import moment from 'moment-timezone';
import noDataState from './no-data-state.vue';
import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import { eventBus } from '@/utils/services/eventBus';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  name: 'TargetVsRecoveryChart',
  components: {
    chartWithLegends,
    widgetContainer,
    ToggleChartVisibility,
    metric,
    loader,
    noDataState
  },
  mixins: [cwMixin],
  props: {
    enableRollup: {
      type: Boolean,
      default: false
    },
    chartConfig: {
      type: Object,
      default: function () {
        return {
          chartOptions: {
            legend: false,
            xFormat: '%m/%d/%Y',
            timeseries: 'feed_date',
            tooltip_format: {
              All: {
                customNameFn: (data) => this.formatTooltip(data)
              }
            },
            append_to_tooltip: (title) => this.appendToTooltip(title),
            grid: 'xy',
            hasEvents: false,
            type: 'line',
            types: {},
            xAxisType: 'category',
            stack: [],
            chartGetter: '',
            show_axis_colors: true,
            events: [],
            size: { height: 380 },
            axis_format: {
              y: true
            }
          },
          stack: [],
          chartGetter: '',
          xAxisType: 'category',
          lastUpdatedDate: false
        };
      }
    },
    iconShow: {
      type: String,
      default: 'show-chart'
    },
    iconHide: {
      type: String,
      default: 'collapse'
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: false
    },
    showWidgetData: {
      type: Boolean,
      default: true
    },
    showLastUpdatedDate: {
      type: Boolean,
      default: true
    },
    localStorageKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      myKey: 1,
      selectedYear: 2022,
      localMetricDisplayList: [],
      differenceMetricList: [],
      differenceMetricSelected: {},
      staticMetrics: [],
      staticMetricsLeft: [],
      metricData: {},
      metricsArray: [],
      metricDisplayConfig: {},
      selectedMetrics: [],
      selectedMetricsList: [],
      shownMetricList: [],
      metricsDataResponse: [],
      metricsTimeseriesData: {},
      isChartLoading: false,
      totalMetricValue: 1,
      totalMetricLabel: '',
      showChart: false,
      chartData: {
        data: []
      },
      selectedRollUp: '',
      rollUpOptions: [
        {
          name: 'Days',
          key: 'DAY',
          feed_date: 'feed_date'
        },
        {
          name: 'Weeks',
          key: 'WEEK',
          feed_date: 'week_start_date'
        },
        {
          name: 'Months',
          key: 'MONTH',
          feed_date: 'month_start_date'
        }
      ],
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency',
        TIME: 'Hrs'
      },
      timeseriesX: 'feed_date',
      formattedChartData: {},
      selectedTimePeriod: 'last90Days',
      timePeriodOptions: [
        {
          name: 'Last 7 days',
          key: 'last7Days'
        },
        {
          name: 'Last 30 days',
          key: 'last30Days'
        },
        {
          name: 'Last 90 days',
          key: 'last90Days'
        },
        {
          name: 'Month To Date',
          key: 'mtd'
        },
        {
          name: 'Year To Date',
          key: 'ytd'
        }
      ],
      metricTooltips: {
        difference:
          'This metric compares the payment received by the client with CIQ and the probable payment without CIQ intervention. The value derived shows the additional incremental $ value provided by CIQ to the client',
        est_client_annual_recovery:
          "Total estimated payment against all the submitted invoices. This metric is an estimate based on client's historical 12 month invoice payment rate which stands at {{percent}} and aims to shows client's performance without CommerceIQ intervention.",
        man_hours_saved:
          'This metric displays the total hours saved for the client due to CommerceIQ intervention. This value is derived by multiplying the average time taken to manually dispute a shortage claim across all phases of a dispute cycle (15 mins) and the total shortage claims available to dispute during the selected time period.',
        recovery:
          'Total payments received by the client for the invoices submitted in the selected time period, with CIQ intervention.',
        total_invoiced_amount:
          'Total $ value of all the invoices submitted by the client for the selected time period.',
        unpaid_invoices:
          'Total unpaid $ value of all the invoices submitted by the client for the selected time period.'
      }
    };
  },
  computed: {
    getMetricStyle() {
      return (item) => {
        if (!this.showChart) return {};
        const isMetricSelected = this.selectedMetrics.includes(item.name);
        if (isMetricSelected) {
          const borderType = this.metricColorIconObject[item.label]
            ?.dashedMetric
            ? 'dashed'
            : 'solid';
          const borderColor = this.metricColorIconObject[item.label]?.color;
          return { 'border-top': `3px ${borderType} ${borderColor}` };
        }
      };
    },
    getMetricIcon() {
      return (item) => {
        return this.metricColorIconObject[item.label]?.icon;
      };
    },
    colorObject() {
      const colorObj = {};
      Object.entries(this.metricColorIconObject).forEach(([key, value]) => {
        colorObj[key] = value.color;
      });
      return colorObj;
    },
    availableYears() {
      const minYears = moment(new Date(this.minMaxDate?.minDate)).year();
      const maxYears = moment(new Date(this.minMaxDate?.maxDate)).year();
      const yearArray = [];
      for (let i = minYears; i <= maxYears; ++i) {
        yearArray.push({ label: i });
      }
      return yearArray;
    },
    uiHeaderOptions() {
      let tempHeaderOptions = cloneDeep(this.headerOptionsComp) || [];

      tempHeaderOptions.push({
        ui_component: 'titleWithIcon',
        props: {
          icon: 'shortage-invoices',
          title: 'Invoices',
          titleTooltip: 'View invoices on AVC',
          wrapperClass: 'target-vs-recovery-title',
          titleClass: 'widget-title u-cursor-pointer title-header',
          postTitleTemplate: `<span class="u-font-weight-600 u-font-size-4 u-color-grey-lighter"> - <span class="u-color-grey-base">C</span><span class="u-color-blue-base">IQ</span> <span class="u-color-grey-base">Target</span> vs <span class="u-color-grey-base">Recovery until ${this.getLastMonthsDate}</span></span>`,
          onClickHeader: () => this.redirectToIndividualDashboard('shortages'),
          postTitleIcon: 'info-circle-outline',
          postTitleIconClasses:
            'u-spacing-pl-s rb-icon--medium u-fill-color-blue-base',
          tooltipText: `<span><p class="u-font-weight-600 u-font-size-4 u-fill-color-grey-base"> This widget is not affected by the date picker</p> <p class="u-spacing-pt-m u-font-size-5 u-fill-color-grey-light"> Invoices dated post <span class="u-font-weight-600">${this.getLastMonthsDate}</span> have not been considered for this widget, as many of such invoices might not have been through a complete payment cycle yet.</p><p class="u-spacing-pt-m u-font-size-5 u-fill-color-grey-light"> Therefore, all calculations for this widget are relative to <span class="u-font-weight-600">${this.getLastMonthsDate}</span></p></span>`
        },
        order: 100
      });

      if (this.showWidgetData) {
        tempHeaderOptions.push({
          ui_component: 'ToggleChartVisibility',
          props: {
            showChart: this.showChart,
            iconShow: 'show-chart',
            iconHide: 'collapse'
          },
          order: 150,
          events: {
            toggleChartVisibilty: this.toggleChartVisibilty
          }
        });
      } else {
        tempHeaderOptions = tempHeaderOptions.filter(
          (headerItem) => headerItem.ui_component !== 'iconText'
        );
      }
      return tempHeaderOptions;
    },
    uiFooterOptions() {
      return [
        {
          ui_component: 'iconText',
          props: {
            text: 'Data is available until',
            textClasses:
              'u-fill-color-grey-lighter u-font-size-6 u-spacing-pr-0'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: ` ${this.getLastMonthsDate}`,
            textClasses:
              'u-spacing-pl-0 u-font-weight-600 u-fill-color-grey-lighter u-font-size-6 u-border-right u-border-color-grey-x-light u-border-width-s'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            icon: 'info-circle-fill',
            iconClasses:
              'u-fill-color-grey-lighter u-spacing-pl-s rb-icon--small u-display-flex u-align-items-center'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: 'Recovery',
            textClasses:
              'u-fill-color-grey-lighter u-font-size-6 u-font-weight-600 u-spacing-pr-0'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: ' metric includes Smart Match',
            textClasses:
              'u-fill-color-grey-lighter u-font-size-6 u-spacing-pl-0 u-border-right u-border-color-grey-x-light u-border-width-s'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            icon: 'info-circle-fill',
            iconClasses:
              'u-fill-color-grey-lighter u-spacing-pl-s rb-icon--small u-display-flex u-align-items-center'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: 'Date Picker',
            textClasses:
              'u-fill-color-grey-lighter u-font-size-6 u-font-weight-600 u-spacing-pr-0'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: ' is not applicable, ',
            textClasses:
              'u-spacing-ph-0 u-fill-color-grey-lighter u-font-size-6'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: 'Date Type',
            textClasses:
              'u-spacing-pl-0 u-fill-color-grey-lighter u-font-size-6 u-font-weight-600 u-spacing-pr-0'
          }
        },
        {
          ui_component: 'iconText',
          props: {
            text: ' is Invoice Date',
            textClasses:
              'u-spacing-pl-0 u-fill-color-grey-lighter u-font-size-6'
          }
        }
      ];
    },
    differenceMetricOptions() {
      return (
        this.differenceMetricList?.filter(
          (el) => el.label !== this.differenceMetricSelected?.label
        ) || []
      );
    },
    getMetricTooltip() {
      return (item) => {
        const metricConfig =
          this.metricDisplayConfig[this.totalMetricLabel]?.tag1Unit;
        let val = Vue.options.filters.num_format(
          this.totalMetricValue,
          metricConfig?.pre,
          metricConfig?.suff
        );
        if (val) {
          return (
            this.metricPercentage(item) +
            ' of ' +
            this.totalMetricLabel +
            ' (' +
            val +
            ')'
          );
        }
        return '';
      };
    },
    metricPercentage() {
      return (item) => {
        let metricValue = this.metricData[item.label]?.tag1;
        if (typeof metricValue !== 'number') metricValue = 0;
        return `${((metricValue / this.totalMetricValue) * 100).toFixed(2)}%`;
      };
    },
    getDateObject() {
      // const localStorageItem = JSON.parse(localStorage.getItem(this.localStorageKey));
      const dateRangeName = this.selectedTimePeriod;
      const lastMonthEndDate = moment().subtract(4, 'months').endOf('month');

      let fromDate = this.widgetRequestParams[':from'];
      let endDate = this.widgetRequestParams[':to'];

      switch (dateRangeName) {
        case 'last7Days':
          (fromDate = moment(lastMonthEndDate).subtract(7, 'days')),
            (endDate = lastMonthEndDate);
          break;
        case 'last30Days':
          (fromDate = moment(lastMonthEndDate).subtract(1, 'months')),
            (endDate = lastMonthEndDate);
          break;
        case 'last90Days':
          (fromDate = moment(lastMonthEndDate).subtract(3, 'months')),
            (endDate = lastMonthEndDate);
          break;
        case 'mtd':
          (fromDate = moment(lastMonthEndDate).startOf('month')),
            (endDate = lastMonthEndDate);
          break;
        case 'ytd':
          (fromDate = moment(lastMonthEndDate).startOf('year')),
            (endDate = lastMonthEndDate);
          break;
        default:
          if (
            moment(lastMonthEndDate).diff(
              moment(this.widgetRequestParams[':to'])
            ) >= 0 ||
            !this.showWidgetData
          ) {
            endDate = this.widgetRequestParams[':to'];
          } else {
            endDate = lastMonthEndDate.format('YYYY-MM-DD');
          }
      }

      return {
        from: moment(fromDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      };
    },
    showInProgressInfo() {
      const currentYear = moment().year();
      return currentYear === this.selectedYear;
    },
    getLastMonthsDate() {
      return moment().subtract(4, 'months').endOf('month')?.format('LL');
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    },
    selectedMetrics(newValue) {
      this.formatChartData(newValue);
    }
  },
  created() {
    Vue.component('ToggleChartVisibility', ToggleChartVisibility);
    Vue.component('chipSelect', chipSelect);
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    this.initData();
    this.fetchData();
  },
  methods: {
    formattedTooltip(item) {
      return this.metricTooltips[item.keyName].replace(
        '{{percent}}',
        this.metricPercentage(item)
      );
    },
    redirectToIndividualDashboard() {
      const vendorCentralURLMap =
        this.metadata.metadata.defaultConfig.vendorCentalURLMap;
      const location = this.$store.getters.getLocation;
      window.open(vendorCentralURLMap[location], '_blank');
    },
    metricSelected(context, value) {
      if (value.length) {
        this.differenceMetricSelected = value[0];
      }
    },
    changeMetric(item) {
      if (!this.showChart) return;
      const index = this.selectedMetrics.findIndex((el) => el === item.name);
      if (index !== -1) {
        this.selectedMetrics = this.selectedMetrics.filter(
          (el) => el !== item.name
        );
      } else {
        this.selectedMetrics.push(item.name);
      }
    },
    appendToTooltip(title) {
      const date = moment(title, 'MMM DD, YYYY').format('MM/DD/YYYY');
      const header =
        '<div class="u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex u-spacing-mb-s"></div>';
      let text = '';
      this.differenceMetricList.forEach((el) => {
        let val = this.getDifferenceValue(el, date);
        const isPositive = val > 0;
        const metricConfig = this.metricDisplayConfig[el.label].tag1Unit;
        if (val) {
          val = Vue.options.filters.num_format(
            val,
            metricConfig.pre,
            metricConfig.suff
          );
          text += `<div style="min-width: 240px; max-width: 500px;" class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start u-font-size-5 u-color-grey-light u-spacing-pb-s u-spacing-ph-m">
                <span class="u-display-flex u-flex-align-items-center" style="min-width:136px">
                  <span class="u-spacing-mr-s">${this.getHTML(el.label)}</span>
                </span>
                <span class="u-font-weight-600 u-line-height-1-3 ${
                  isPositive ? 'u-color-green-base' : 'u-color-red-base'
                }">${isPositive ? '+' : ''}${val}</span>
              </div>`;
        }
      });
      return text && header + text;
    },
    getDifferenceValue(el, date) {
      const recoveryMetricData = this.metricsTimeseriesData.recovery.find(
        (el) => el.feed_date === date
      ).recovery;
      const estClientRecoveryMetricData =
        this.metricsTimeseriesData.est_client_annual_recovery.find(
          (el) => el.feed_date === date
        ).est_client_annual_recovery;

      return recoveryMetricData - estClientRecoveryMetricData;
    },
    formatTooltip(data) {
      const name = data.id;
      if (this.metricColorIconObject[name].icon === 'logo') {
        return (
          '<span class="rb-icon icon-logo rb-icon--small u-spacing-mh-xs"></span>' +
          name
        );
      }
      return name;
    },
    getHTML(text) {
      const txtArray = text.split('CIQ');
      const CIQLabelString =
        '<span class="u-spacing-mh-xs"><span class="u-font-weight-600">C</span><span class="u-color-blue-base u-font-weight-600">IQ</span></span>';
      // const CIQLabelString = '<span class="rb-icon icon-logo rb-icon--small u-spacing-mh-xs"></span>';
      return `<span class="u-display-flex u-flex-align-items-center">${txtArray.join(
        CIQLabelString
      )}</span>`;
    },
    changeYear(value) {
      this.selectedYear = value.label;
    },
    legendsTransformer(metricsDataResponse) {
      metricsDataResponse.forEach((metricGroup) => {
        metricGroup.forEach((metric) => {
          this.metricData[this.metadata.metrics[metric.name].label] = {
            tag1: metric.RESULT[metric.name]
          };
        });
      });

      const totalMetric = this.metricsArray.find(
        (el) =>
          el.name === this.metadata.metadata.defaultConfig.metricForTotalCount
      );
      this.totalMetricLabel = totalMetric.label;
      this.totalMetricValue = this.metricData[totalMetric.label]?.tag1 || 1;
    },
    timeseriesTransformer(data) {
      let timeseriesToLegendMap = {
        // 'annual_target_cumulative': 'annual_target',
        recovery_cumulative: 'recovery',
        est_client_annual_recovery_cumulative: 'est_client_annual_recovery',
        unpaid_invoices_cumulative: 'unpaid_invoices'
      };

      // mapping cumulative timeseries metrics to legend metrics and discarding others
      const timeSeriesMetricsDataResponse = flatten(data).reduce(
        (finalObj, eachMetric) => {
          const metricMapper = timeseriesToLegendMap[eachMetric.name];
          const timeseriesDimension = this.rollUpOptions?.find(
            (el) => el.key === this.selectedRollUp
          )?.feed_date;
          return metricMapper
            ? {
                ...finalObj,
                [metricMapper]: eachMetric.TIMESERIES?.map((el) => ({
                  [metricMapper]: el[eachMetric.name],
                  feed_date: el[timeseriesDimension]
                }))
              }
            : finalObj;
        },
        {}
      );

      this.metricsTimeseriesData = timeSeriesMetricsDataResponse;
      this.chartData.data = [];
      const meta = this.metadata.metrics;
      const metricsSeriesList = [];
      const chartXS = {};
      Object.keys(timeSeriesMetricsDataResponse).forEach((metricKey) => {
        const label = meta[metricKey]?.label;
        const timeSeriesLabel =
          this.chartConfig?.chartOptions?.timeseries + '_' + label;
        chartXS[label] = timeSeriesLabel;
        const metricValues = timeSeriesMetricsDataResponse[metricKey]
          ? timeSeriesMetricsDataResponse[metricKey].map(
              (metricEntry) => metricEntry[metricKey]
            )
          : [];
        const metricFeedDateValues = timeSeriesMetricsDataResponse[metricKey]
          ? timeSeriesMetricsDataResponse[metricKey].map(
              (metricEntry) => metricEntry.feed_date
            )
          : [];
        metricValues.unshift(label);
        metricFeedDateValues.unshift(timeSeriesLabel);
        metricsSeriesList.push(metricValues, metricFeedDateValues);
      });

      this.chartData.xs = chartXS;
      Vue.set(this.chartData, 'data', metricsSeriesList);
      this.formatChartData(this.selectedMetrics);
      this.isChartLoading = false;
    },
    async download() {
      try {
        this.isDownloadLoading = true;
        let apiForDownload = this.metadata.metadata.apiForDownload;
        let request = apiForDownload.request;
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        request.where.date = this.getDateObject;

        const response = await HttpService.post(
          apiForDownload.service,
          request,
          {
            append: `/${apiForDownload.endPoint}`
          }
        );
        const { url } = response.data;
        downloadLinkAsFile(url);
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
      this.isDownloadLoading = false;
    },
    formatChartData(selectedMetrics) {
      const metricListToPass = this.localMetricDisplayList.filter((el) =>
        selectedMetrics.includes(el.name)
      );
      this.$nextTick(() => {
        this.$refs.chartWithLegends?.formatChartData(
          this.colorObject,
          metricListToPass
        );
      });
    },
    initData() {
      const defaultConfig = this.metadata.metadata.defaultConfig;
      this.metricsArray = Object.values(this.metadata.metrics);
      this.selectedMetrics = defaultConfig.selectedMetric;
      this.shownMetricList = defaultConfig.shownMetricList;
      this.localMetricDisplayList = this.selectedMetrics.map((el) => {
        const metric = this.metricsArray.find((e) => e.name === el);
        return { ...metric, key: metric.label, title: metric.label };
      });
      this.differenceMetricList = this.metricsArray.filter((el) =>
        defaultConfig.differenceMetrics.includes(el.name)
      );
      this.differenceMetricSelected = this.differenceMetricList[0];
      this.metricColorIconObject = defaultConfig.metricColorIconObject;
      this.staticMetrics = this.metricsArray.filter((el) =>
        defaultConfig.staticMetrics.includes(el.name)
      );
      this.staticMetricsLeft = this.metricsArray.filter(
        (el) => el.name === 'shortages_rra_overview__total_invoiced_amount'
      );
      this.selectedRollUp = defaultConfig.selectedRollUp;
      this.populateMetricsData(this.shownMetricList, 'loading');
    },
    populateMetricsData(metricList, value) {
      this.metricsArray.forEach((metric) => {
        if (metricList.includes(metric.name)) {
          this.metricData[metric.label] = { tag1: value };
        }
      });
    },
    fetchData() {
      if (!this.showWidgetData) {
        return;
      }
      this.fetchLegendsData();
      this.fetchTimeseriesData();
    },
    fetchTimeseriesData() {
      this.isChartLoading = true;
      let apiForTimeseries = this.metadata.metadata.apiForTimeseries;
      let request = apiForTimeseries.request;

      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );
      request.where.date = this.getDateObject;

      dashUtils.fetchDataAPI(request, apiForTimeseries).then((args) => {
        this.timeseriesTransformer(args.entityData[0].data);
      });
    },
    fetchLegendsData() {
      // this.isChartLoading = true;
      const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);

      this.metricDisplayConfig = this.generateMetricDisplayConfig(
        this.metricsArray
      );
      let request, api;
      const dataGroupsList = Object.keys(dataGroups);
      for (let i = 0; i < dataGroupsList.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );

        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );

        request.where.date = this.getDateObject;
        request = this.tempTransformer(request, metricsListName);

        dashUtils
          .fetchDataAPI(request, api)
          .then((args) => {
            Vue.set(this.metricsDataResponse, i, args.entityData[0].data);
            this.legendsTransformer(this.metricsDataResponse);
          })
          .catch(() => {
            this.populateMetricsData(metricsListName, 'error');
            this.isChartLoading = false;
            this.myKey = Math.random();
          });
      }
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    generateMetricDisplayConfig(metricData) {
      const metricConfig = {};
      // Construct the required configs
      metricData.forEach((metric) => {
        const metricMeta = metric.metadata;

        // Percentage, currency and metric suffix/prefix in chart tooltip
        if (metricMeta.unit === 'PERCENTAGE') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            suff: '%'
          };
        } else if (metricMeta.unit === 'CURRENCY') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            pre: 'currency'
          };
        } else if (metricMeta.unit === 'NUMBER') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            roundoff: 0
          };
        }
        metricConfig[metric.label] = {
          invertTag2: metricMeta.isInverted,
          tag1Unit: {
            pre: metricMeta.unit === 'CURRENCY' ? 'currency' : '',
            suff:
              metricMeta.unit in this.suffMap
                ? this.suffMap[metricMeta.unit]
                : '',
            roundoff:
              metricMeta.type === 'NUMBER' && metricMeta.unit === ''
                ? 0
                : undefined
          },
          tag2Unit: {
            suff: '%'
          }
        };
      });
      return metricConfig;
    },
    rollUpChanged(data) {
      const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
      this.selectedRollUp = data.key;
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      chartingReqParam[':timeseriesDimension'] = data.feed_date;
      this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    },
    updateStrokeLines() {
      const [metricToStroke, __] = Object.entries(
        this.metricColorIconObject
      ).find(([_, value]) => value.dashedMetric);
      if (metricToStroke) {
        const c3LineCLass = metricToStroke.split(' ').join('-');
        document.querySelectorAll('.c3-lines').forEach((el) => {
          if (el.classList.value.includes(c3LineCLass)) {
            el.classList.add('stroked-chart-lines');
          }
        });
      }
    },
    isIncludesSmartMatch(item) {
      let acceptedMetrics = ['Recovery'];
      return acceptedMetrics.includes(item.key);
    },
    toggleChartVisibilty(val) {
      this.showChart = val;
    },
    changeDateHandler() {
      eventBus.$emit('showDatePicker');
    },
    timePeriodChanged(data) {
      if (this.selectedTimePeriod === data.key) return;
      this.selectedTimePeriod = data.key;
      this.fetchData();
    }
  }
};
</script>

<style lang="css">
.target_vs_recovery_chart_wrapper .header-container-cwc {
  padding-bottom: 1.6rem;
}
.target_vs_recovery_chart_wrapper .custom-metrics-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.target_vs_recovery_chart_wrapper .custom-metrics-wrapper__left {
  display: flex;
  border-right: 1px solid #caccce;
}
.target_vs_recovery_chart_wrapper .custom-metrics-wrapper .custom-metric {
  box-shadow: 0 0 4px 0 #caccce;
  min-width: 100px;
  gap: 1rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-width: 3px;
  border-radius: 2px;
  margin-right: 1.6rem;
}
.target_vs_recovery_chart_wrapper
  .custom-metrics-wrapper
  .custom-metric:not(.hover-disabled):hover {
  box-shadow: 0 0 8px 0 #caccce;
}
.target_vs_recovery_chart_wrapper
  .custom-metrics-wrapper
  .custom-metric-static {
  box-shadow: none;
  border: solid 1px #e9eaeb;
}
.target_vs_recovery_chart_wrapper .roll-up-by .rollUpWrapperClass {
  padding: 1.2rem 0.8rem;
}
.target_vs_recovery_chart_wrapper .u-ciq-style {
  z-index: 1;
  top: 12px;
}
.target_vs_recovery_chart_wrapper .stroked-chart-lines {
  stroke-dasharray: 4;
}
.rra-popper-class {
  max-width: 420px !important;
  padding: 24px !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15) !important;
  border-color: #fff !important;
}
</style>
