<template>
  <div
    class="u-width-100 u-spacing-mt-0"
    :class="chartId"
  >
    <chart-legends
      v-if="showMetrics"
      :key="chartKey"
      :fill-metric="enablePVPTimeseries"
      :metric-display-list="localMetricsShown"
      :metrics-list="computedMetricsList"
      :default-select-metric="defaultSelectMetric"
      :metric-config="metricConfig"
      :metric-data="metricData"
      :disable-add-new-metric="disableAddNewMetric"
      :remove-dropdown="removeDropdown"
      :minimum-metric="minimumMetric"
      :enable-legend-search="enableLegendSearch"
      :color-object="colorObject"
      :selected-metric-limit="
        enablePVPTimeseries ? 1 : computedSelectedMetricLimit
      "
      :note-generator-metric-tooltip="noteGeneratorMetricTooltip"
      :emit-on-metric-default-change="emitOnMetricDefaultChange"
      :plot-on-y-axis-only="plotOnYAxisOnly"
      :default-legends="defaultLegends"
      :enable-watefall="enableWatefall"
      :additional-data="additionalData"
      :has-post-custom-metric="hasPostCustomMetric"
      :metric-font-size="metricFontSize"
      :hide-zero="hideZero"
      :show-big-tooltip="showBigTooltip"
      :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
      :modify-tooltip-message="modifyTooltipMessage"
      :show-legends-as-chips="showLegendsAsChips"
      :show-chart="showChart"
      :margin-top-large="marginTopLarge"
      :margin-top-small="marginTopSmall"
      :metric-tooltip-one-liner="metricTooltipOneLiner"
      :metric-tooltip-theme="metricTooltipTheme"
      :show-custom-tooltip="showCustomTooltip"
      :restrict-single-metric-deselection="restrictSingleMetricDeselection"
      @selectedList="metricChanged"
      @removedMetric="removedMetric"
      @addMetric="addMetric"
    >
      <div
        slot="post-legends"
        class="u-flex-1"
      >
        <slot name="post-legends" />
      </div>
      <div
        slot="customLegend"
        slot-scope="{ val }"
      >
        <slot
          name="customLegendForChart"
          :val="val"
        />
      </div>
      <div
        slot="customMetric"
        slot-scope="{ value }"
      >
        <slot
          name="postCustomMetric"
          :value="value"
        />
      </div>
    </chart-legends>
    <groupby-chart-legends
      v-if="isGroupByChart"
      :is-group-by-selected="isGroupBySelected"
      :is-p-v-p-timeseries-enabled="enablePVPTimeseries"
      :metric-config="groupByConfig.metricConfig"
      :metric-data="groupByConfig.metricData"
      :available-group-by="groupByConfig.availableGroupBy"
      :selected-group-by="groupByConfig.selectedGroupBy"
      :selected-sorting="groupByConfig.selectedSorting"
      :total-entity-count="groupByConfig.totalEntityCount"
      :is-group-by-metric-loading="groupByConfig.isGroupByMetricLoading"
      :has-error-occurred="groupByConfig.hasErrorOccurred"
      :color-pattern="getColorPattern"
      :master-metric-color="masterMetricColor"
      :master-metric="masterMetricList[0]"
      :add-more-metrics-func="addMoreMetricsFunc"
      @groupByChanged="groupByChanged"
      @metricAdded="groupByMetricChanged"
      @metricChanged="groupByMetricChanged"
      @groupByMetricColors="setGroupByMetricsChartColor"
    >
      <slot
        slot="add-metric-footer"
        slot-scope="{ type }"
        name="add-metric-footer"
        :type="type"
      />
    </groupby-chart-legends>
    <chart-event-legends
      v-if="hasChartEvents"
      :disable-add-new-event="disableAddNewEvent"
      :metric-display-list="eventsShown"
      :metrics-list="eventsList"
      :default-select-metric="defaultSelectEvents"
      :selected-metric-limit="3"
      class=""
      @selectedList="eventsChanged"
    />
    <slot name="space-between-legend-and-chart" />
    <div
      v-show="showChart"
      class=""
      style="
        justify-content: center;
        display: flex;
        align-self: stretch;
        height: auto;
      "
    >
      <div
        v-show="!isChartLoading"
        data-cy="workbenchCampaignsChart"
        :class="[
          { 'u-width-100': true },
          { 'u-height-auto': true },
          { 'u-spacing-mt-l': showPaddingBetween },
          { 'u-position-relative': true }
        ]"
      >
        <div
          v-if="showPoweredByCIQText"
          class="u-color-grey-x-light u-font-size-7 u-position-absolute u-ciq-style u-font-weight-600"
          :style="{ right: selectedMetric.length === 2 ? '70px' : '12px' }"
        >
          Powered by <span class="u-color-grey-base">CommerceIQ</span>
        </div>
        <chart
          :id="chartId"
          :class="customChartClass"
          class="c3-large u-flex-1 chart_space_class"
          :style="{ height: chartHeight }"
          :config="chartConfig"
          :data="formattedChartData"
          :chart-change="chartChange"
          :is-p-v-p-timeseries-chart="enablePVPTimeseries"
          @pointClicked="pointClicked"
          @updateStroke="$emit('updateStroke')"
        />
      </div>
      <loader
        class="u-bg-color-grey-white u-spacing-mt-0"
        :loading="isChartLoading"
        :color="'#007cf6'"
        :style="{
          height: groupByConfig.isGroupByMetricLoading ? '550px' : '450px'
        }"
      />
    </div>
    <legend-with-pvp
      v-if="showLegendWithPVP"
      :config="pvpLegendConfig"
    />
  </div>
</template>
<script>
// import Vue from 'vue';
import chart from './chart.vue';
import chartLegends from './chart-legends.vue';
import groupbyChartLegends from './groupby-chart-legends.vue';
import chartEventLegends from './chart-events-legends';
import widgetsUtil from '@/components/widgetMixin';
import loader from '@/components/basic/loader';
import legendWithPvp from './legend-with-pvp.vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export default {
  components: {
    chart,
    chartLegends,
    groupbyChartLegends,
    chartEventLegends,
    loader,
    legendWithPvp
  },
  mixins: [widgetsUtil],
  props: {
    showSeparatedRangeOnTooltip: {
      type: Boolean,
      default: true
    },
    pvpChartData: {
      type: Object,
      default: () => {}
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    },
    metricTooltipOneLiner: {
      type: Boolean,
      default: true
    },
    metricTooltipTheme: {
      type: String,
      default: 'dark'
    },
    showBigTooltip: {
      type: Boolean,
      default: false
    },
    showChart: {
      type: Boolean,
      default: true
    },
    chartHeight: {
      type: String,
      default: '100%'
    },
    defaultLegends: {
      type: Boolean,
      default: true
    },
    customChartClass: {
      type: Array,
      default: () => ['u-spacing-mt-l']
    },
    noteGeneratorMetricTooltip: {
      type: Function,
      default: () => {}
    },
    ignoreList: {
      type: Array,
      default: () => []
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    chartConfig: {
      type: Object,
      default: () => {}
    },
    defaultSelectMetric: {
      type: Array,
      default: () => []
    },
    metricsShown: {
      type: Array,
      default: () => []
    },
    eventsShown: {
      type: Array,
      default: () => []
    },
    eventsList: {
      type: Array,
      default: () => []
    },
    defaultSelectEvents: {
      type: Array,
      default: () => []
    },
    hasChartEvents: {
      type: Boolean,
      default: false
    },
    metricsList: {
      type: Array,
      default: () => []
    },
    metricColors: {
      type: Object,
      default: () => {}
    },
    disableAddNewMetric: {
      type: Boolean,
      default: false
    },
    removeDropdown: {
      type: Boolean,
      default: false
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    enableLegendSearch: {
      type: Boolean,
      default: true
    },
    colorObject: {
      type: Object,
      default: null
    },
    selectedMetricLimit: {
      type: Number,
      default: 2
    },
    heightRatio: {
      type: Number,
      default: 2.8
    },
    disableAddNewEvent: {
      type: Boolean,
      default: false
    },
    isChartLoading: {
      type: Boolean,
      default: false
    },
    emitOnMetricDefaultChange: {
      type: Boolean,
      default: false
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: false
    },
    showPoweredByCIQText: {
      type: Boolean,
      default: false
    },
    enableWatefall: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    metricFontSize: {
      type: String,
      default: 'u-font-size-5'
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    showLegendsAsChips: {
      type: Boolean,
      default: false
    },
    showMetrics: {
      type: Boolean,
      default: true
    },
    showPaddingBetween: {
      type: Boolean,
      default: true
    },
    marginTopLarge: {
      type: Boolean,
      default: true
    },
    marginTopSmall: {
      type: Boolean,
      default: false
    },
    enablePVPTimeseries: {
      type: Boolean,
      default: false
    },
    pvpMetricData: {
      type: Object,
      default: () => {}
    },
    pvpDateText: {
      type: String,
      default: ''
    },
    restrictSingleMetricDeselection: {
      type: Boolean,
      default: false
    },
    isGroupByChart: {
      type: Boolean,
      default: false
    },
    isGroupBySelected: {
      type: Boolean,
      default: false
    },
    groupByConfig: {
      type: Object,
      default: () => ({})
    },
    addMoreMetricsFunc: {
      type: Function,
      default: null
    },
    metricsTooltipFormat: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartKey: 0,
      selectedGroupBy: null,
      groupByMetricColors: null,
      isGroupByToggled: false,
      metricsSelectedIndex: [0, 1],
      chartWkbenchAxes: {},
      metricsSelected: [],
      formattedChartData: {},
      selectedMetric: [],
      selectedEvents: [],
      localMetricsShown: [...this.metricsShown],
      chartChange: 0,
      masterMetricList: [],
      masterColorObj: {},
      pvpLegendConfig: {},
      selectedMetricLimitData: 1
    };
  },
  computed: {
    computedSelectedMetricLimit() {
      return this.selectedMetricLimitData;
    },
    hasPostCustomMetric() {
      return !!this.$scopedSlots.postCustomMetric;
    },
    computedMetricsList() {
      if (this.metricsList.length > 0) {
        return this.metricsList;
      }
      var data = this.chartData.data;
      if (!data) {
        return [];
      }
      const returnArr = [];
      for (let i = 0; i < data.length; i++) {
        returnArr.push({
          title: data[i][0],
          key: data[i][0]
        });
      }
      // console.log('returnArr', returnArr);
      return returnArr.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    showLegendWithPVP() {
      return (
        this.enablePVPTimeseries &&
        !this.isChartLoading &&
        this.pvpLegendConfig.hasOwnProperty('metricConfig') &&
        this.pvpLegendConfig.hasOwnProperty('metricData')
      );
    },
    masterMetricColor() {
      const masterMetricLabel = this.masterMetricList[0]?.label;
      return this.masterColorObj[masterMetricLabel];
    }
  },
  watch: {
    groupByMetricColors(value) {
      const data = cloneDeep(this.formattedChartData);
      if (this.isGroupByToggled) {
        Object.values(data.data || {}).forEach((metric) => {
          if (metric[0].includes('feed_date')) {
            return;
          }
          if (metric[0].includes('3p')) {
            data.colors[metric[0]] = value['3P'];
          } else if (metric[0].includes('Retail')) {
            data.colors[metric[0]] = value.Retail;
          } else {
            data.colors[metric[0]] = value['Retail+3p'];
          }
        });
        this.formattedChartData = data;
      }
    },
    metricsShown(newValue) {
      this.localMetricsShown = newValue;
    },
    chartData: {
      handler() {
        this.formatChartData(this.masterColorObj, this.masterMetricList);
      },
      deep: true
    }
  },
  updated() {
    this.updateStrokedLines();
  },
  mounted() {
    // this.calculateChartSize();
    window.addEventListener('resize', this.handleResizeEvent);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeEvent);
  },
  created() {
    this.selectedMetricLimitData = this.selectedMetricLimit;
    this.chartId = 'chartingWorkbenchId' + Math.round(Math.random() * 10000000);
  },
  methods: {
    handleResizeEvent() {
      // var chart = document.getElementById(this.chartId);
      // if (chart) {
      //   chart.style.height = ((chart.clientWidth || 320) / this.heightRatio) + 'px';
      //   // console.log(chart.style.height);
      //   this.chartChange++;
      // }
      const metricCardElement = document
        .querySelector(
          `.${this.chartId} .metric-card-hover-trigger .active-metric-card`
        )
        .closest('.metric-card-hover-trigger');
      metricCardElement.click();
      setTimeout(() => {
        metricCardElement.click();
      }, 2);
    },
    selectNewMetric(context, val) {
      // console.log(context, val)
      if (this.metricsSelectedIndex.indexOf(context[0]) === -1) {
        this.metricsSelectedIndex.shift();
        this.metricsSelectedIndex.push(context[0]);
        // Logic for showing 2 y axis. Controlled by passing prop plotOnYAxisOnly as true/false from root widget.
        if (this.plotOnYAxisOnly) {
          this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y';
        } else {
          this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y2';
        }
        // console.log(this.chartWkbenchAxes)
        this.isExpand = false;
      }
    },
    metricSelected(context, val) {
      if (this.localMetricsShown.indexOf(val[0].title) === -1) {
        this.localMetricsShown.splice(context[0], 1, val[0].title);
        this.selectNewMetric(context, [val[0].title]);
        this.isExpand = false;
      }
    },
    metricChanged(data) {
      this.$emit('selectedMetricList', data);
      this.selectedMetric = data.selectedMetric;
      const metircList = this.selectedMetric.concat(this.selectedEvents);
      this.formatChartData(data.metricColors, metircList);
    },
    removedMetric(data) {
      this.$emit('removedMetric', data);
    },
    addMetric(data) {
      this.$emit('addMetric', data);
    },
    eventsChanged(data) {
      this.selectedEvents = data.selectedMetric;
      const metircList = this.selectedMetric.concat(this.selectedEvents);
      this.formatChartData(data.metricColors, metircList);
      this.$emit('selectedList', data);
    },
    pointClicked(data) {
      this.$emit('pointClicked', data);
    },
    tooltipContentForPVPChart(value, currentColor, prevColor) {
      let [currentDate, prevDate] = value?.split('-') || ['', ''];
      if (this.selectedRollUp === 'WEEK') {
        [currentDate, prevDate] = [
          this.currentDateForWeek(currentDate),
          this.prevDateForWeek(prevDate)
        ];
      } else if (this.selectedRollUp === 'MONTH') {
        [currentDate, prevDate] = [
          this.currentDateForMonth(currentDate),
          this.prevDateForMonth(prevDate)
        ];
      } else {
        [currentDate, prevDate] = [
          this.defaultCurrentDate(currentDate),
          this.defaultPrevDate(prevDate)
        ];
      }

      let htmlText = '';

      if (currentDate && prevDate) {
        htmlText = `<span style="color:${currentColor}; display:block">${currentDate}</span>
            <span class="u-font-size-7 u-color-grey-lighter">Compared to <span style="color:${prevColor};">${prevDate}</span></span>`;
      } else if (currentDate) {
        htmlText = `<span style="color:${currentColor}; display:block">${currentDate}</span>`;
      } else if (prevDate) {
        htmlText = `<span style="color:${prevColor}; display:block">${prevDate}</span>`;
      }

      return htmlText;
    },
    formatChartData(metricColors, selectedMetric) {
      const axes = {};
      const data = [];
      this.masterMetricList = [...selectedMetric];
      this.masterColorObj = {
        ...this.masterColorObj,
        ...metricColors
      };
      // Fix for Color are not matching, https://boomerang.atlassian.net/browse/SDP-95
      const _metricColor = {};
      console.log(this.chartData, 'this.chartData');
      if (this.enablePVPTimeseries) {
        const indexOfMetric = this.chartData?.data?.findIndex((metric) => {
          return (
            metric[0] === this.selectedMetric?.[0]?.key ||
            metric[0] === `${this.selectedMetric?.[0]?.key} - 3p` ||
            metric[0] === `${this.selectedMetric?.[0]?.key} - Retail`
          );
        });
        if (indexOfMetric === -1) return;
        const currentData = this.chartData?.data?.[indexOfMetric];
        const previousData = this.pvpChartData?.data?.[indexOfMetric];

        const currentTimeseries = [currentData[0] + ' - Current'];
        if (!previousData) return;
        currentTimeseries.push(...(currentData?.slice(1) || []));
        const prevTimeseries = [previousData[0] + ' - Previous'];
        prevTimeseries.push(...(previousData?.slice(1) || []));
        const data = [currentTimeseries, prevTimeseries];
        const currentLabel = currentData[0] + ' - Current';
        const prevLabel = previousData[0] + ' - Previous';

        _metricColor[currentLabel] = this.masterColorObj[currentData[0]];
        _metricColor[prevLabel] =
          this.getColorPattern.find(
            (el) => el !== _metricColor[currentLabel]
          ) || '#23b5d3';

        this.updatePVPLegendConfig(
          currentData[0],
          _metricColor[prevLabel],
          this.groupByConfig.metricPVPData,
          this.pvpMetricData[selectedMetric[0].key]
        );

        this.updatePVPTimeseriesTooltipForChart(
          currentData[0],
          currentLabel,
          prevLabel
        );

        if (this.showSeparatedRangeOnTooltip) {
          this.$set(this.chartConfig, 'tooltip_format_title', (val) => {
            return this.tooltipContentForPVPChart(
              categories[val],
              _metricColor[currentLabel],
              _metricColor[prevLabel]
            );
          });
        }

        // To add stroke classes to the second trendline
        this.updateStrokedLines();

        const categories = this.chartData?.data?.[0]
          ?.slice(1)
          ?.map((element, index) => {
            return (
              this.chartData?.data?.[0]?.[index + 1] +
              '-' +
              this.pvpChartData?.data?.[0]?.[index + 1]
            );
          });

        this.$set(this.chartConfig, 'xAxisCategories', categories);
        const obj = {
          data: data,
          colors: _metricColor,
          onclick: function (e) {
            alert(e.value);
          }
        };
        this.formattedChartData = {
          ...this.pvpChartData,
          ...obj
        };
      } else {
        for (let i = 0; i < selectedMetric.length; i++) {
          const metric = selectedMetric[i];
          _metricColor[metric.key] = this.masterColorObj[metric.key];
          if (this.plotOnYAxisOnly) {
            axes[metric.key] = i % 2 === 0 ? 'y' : 'y';
          } else {
            axes[metric.key] = i % 2 === 0 ? 'y' : 'y2';
          }
          const indexOfMetric = this.chartData?.data?.findIndex((item) => {
            return (
              (item && item[0] === metric.key) ||
              item[0] === `${metric.key} - 3p` ||
              item[0] === `${metric.key} - Retail`
            );
          });

          if (indexOfMetric !== -1) {
            this.chartData.data.forEach((chartDataValue) => {
              if (`${metric.key} - 3p` === chartDataValue[0]) {
                data.push(chartDataValue);
              }
              if (`${metric.key} - Retail` === chartDataValue[0]) {
                data.push(chartDataValue);
              }
            });
            data.push(this.chartData.data[indexOfMetric]);
          }
        }
        const timeseriesIndex = this.chartData?.data?.findIndex((item) => {
          return item[0] === this.chartConfig.chartOptions.timeseries;
        });
        if (timeseriesIndex !== -1) {
          data.push(this.chartData.data[timeseriesIndex]);
        }
        if (this.chartData.xs) {
          const xs = this.chartData.xs;
          for (const key in this.chartData.xs) {
            const xsSeries = this.chartData?.data?.findIndex((item) => {
              return item[0] === xs[key];
            });
            if (xsSeries !== -1) {
              data.push(this.chartData.data[xsSeries]);
            }
          }
        }
        const obj = {
          data: data,
          axes: this.chartData.axes ? this.chartData.axes : axes,
          colors: _metricColor,
          onclick: function (e) {
            alert(e.value);
          }
        };
        if (this.isGroupByToggled) {
          const feed_date = Object.values(obj.data)?.find(
            (e) => e[0] === 'feed_date'
          );
          obj.data = Object.values(obj.data)?.reduce((acc, currentValue) => {
            if (
              this.selectedGroupBy?.includes('3P') &&
              currentValue[0]?.includes('3p')
            ) {
              acc.push(currentValue);
            }
            if (
              this.selectedGroupBy?.includes('Retail') &&
              currentValue[0]?.includes('Retail')
            ) {
              acc.push(currentValue);
            }
            if (
              this.selectedGroupBy?.includes('Retail+3p') &&
              !currentValue[0]?.includes('3p') &&
              !currentValue[0]?.includes('Retail')
            ) {
              acc.push(currentValue);
            }
            return acc;
          }, []);
          obj.data.push(feed_date);
          obj.data.colors = {};
          if (this.groupByMetricColors) {
            Object.values(obj.data || {}).forEach((metric) => {
              if (metric[0]?.includes('feed_date')) {
                return;
              }
              if (metric[0]?.includes('3p')) {
                obj.colors[metric[0]] = this.groupByMetricColors['3P'];
              } else if (metric[0]?.includes('Retail')) {
                obj.colors[metric[0]] = this.groupByMetricColors.Retail;
              } else {
                obj.colors[metric[0]] = this.groupByMetricColors['Retail+3p'];
              }
            });
          }
        }
        this.formattedChartData = {
          ...this.chartData,
          ...obj
        };
      }
    },
    updateStrokedLines() {
      // To make the PVP Timeseries trendline as stroked
      if (this.enablePVPTimeseries) {
        document.querySelectorAll('.c3-lines').forEach((el) => {
          if (el.classList.value.includes('Previous')) {
            el.classList.add('stroked-chart-lines');
          }
        });
      }
    },
    updatePVPLegendConfig(label, color, metricData, entityValue) {
      this.pvpLegendConfig = {
        color,
        entityValue,
        metricLabel: label,
        dateText: this.pvpDateText,
        metricConfig: this.metricConfig[label],
        metricData
      };
    },
    updatePVPTimeseriesTooltipForChart(metricLabel, currentLabel, prevLabel) {
      this.$set(
        this.chartConfig.chartOptions,
        'tooltip_label_text',
        metricLabel
      );

      const metricTooltipFormat = this.metricsTooltipFormat[metricLabel];

      this.$set(this.chartConfig.chartOptions, 'tooltip_format', {
        [currentLabel]: metricTooltipFormat,
        [prevLabel]: metricTooltipFormat
      });
    },
    currentDateForWeekGenerator(currentDate) {
      return (
        moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(currentDate, 'MM/DD/YYYY')
          .add(6, 'd')
          .isAfter(this.dateSelected.to)
          ? moment(this.dateSelected.to).format('MMM DD, YYYY')
          : moment(currentDate, 'MM/DD/YYYY')
              .add(6, 'd')
              .format('MMM DD, YYYY'))
      );
    },
    currentDateForWeek(currentDate) {
      return currentDate ? this.currentDateForWeekGenerator(currentDate) : '';
    },
    prevDateForWeekGenerator(prevDate) {
      return (
        moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(prevDate, 'MM/DD/YYYY')
          .add(6, 'd')
          .isAfter(this.dateSelected.pvpTo)
          ? moment(this.dateSelected.pvpTo).format('MMM DD, YYYY')
          : moment(prevDate, 'MM/DD/YYYY').add(6, 'd').format('MMM DD, YYYY'))
      );
    },
    prevDateForWeek(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? this.prevDateForWeekGenerator(prevDate)
        : '';
    },
    currentDateForMonthGenerator(currentDate) {
      return (
        moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(currentDate, 'MM/DD/YYYY')
          .endOf('month')
          .isAfter(this.dateSelected.to)
          ? moment(this.dateSelected.to).format('MMM DD, YYYY')
          : moment(currentDate, 'MM/DD/YYYY')
              .endOf('month')
              .format('MMM DD, YYYY'))
      );
    },
    currentDateForMonth(currentDate) {
      return currentDate ? this.currentDateForMonthGenerator(currentDate) : '';
    },
    prevDateForMonthGenerator(prevDate) {
      return (
        moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY') +
        ' - ' +
        (moment(prevDate, 'MM/DD/YYYY')
          .endOf('month')
          .isAfter(this.dateSelected.pvpTo)
          ? moment(this.dateSelected.pvpTo).format('MMM DD, YYYY')
          : moment(prevDate, 'MM/DD/YYYY')
              .endOf('month')
              .format('MMM DD, YYYY'))
      );
    },
    prevDateForMonth(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? this.prevDateForMonthGenerator(prevDate)
        : '';
    },
    defaultCurrentDate(currentDate) {
      return currentDate
        ? moment(currentDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
        : '';
    },
    defaultPrevDate(prevDate) {
      return moment(prevDate, 'MM/DD/YYYY', true)?.isValid()
        ? moment(prevDate, 'MM/DD/YYYY').format('MMM DD, YYYY')
        : '';
    },
    groupByChanged(params) {
      if (params.isGroupBy === null || params.isGroupBy === undefined) {
        return;
      }
      this.isGroupByToggled = params.isGroupBy;
      this.$emit('groupByChanged', params);
      if (params.isGroupBy === false) {
        this.$nextTick(() => {
          this.formatData();
        });
        this.selectedMetricLimitData = 3;
        this.chartKey++;
      } else {
        this.selectedMetricLimitData = 1;
      }
    },
    groupByMetricChanged(params) {
      // params.isGroupBy = true;
      this.selectedGroupBy = params.dimensionNameValueList.map(
        (e) => e.dimensionValue
      );
      this.$emit('groupByMetricChanged', params);
      this.$nextTick(() => {
        this.formatData();
      });
    },
    setGroupByMetricsChartColor(colors) {
      this.groupByMetricColors = colors;
    },
    formatData() {
      this.formatChartData(this.masterColorObj, this.masterMetricList);
    }
  }
};
</script>

<style lang="css">
.u-ciq-style {
  z-index: 1;
  top: 12px;
}
.stroked-chart-lines {
  stroke-dasharray: 2;
}
</style>
