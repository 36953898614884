<template>
  <div id="app">
    <welcome />
    <attention-popup v-if="loginSuccess" />
    <transition
      name="slide"
      mode="out-in"
    >
      <router-view />
    </transition>
    <browser-incompatibility-widget />
  </div>
</template>

<script>
import browserIncompatibilityWidget from '@/components/browserIncompatibility.vue';
import welcome from '@/components/widgets/welcome.vue';
import attentionPopup from '@/components/widgets/attentionPopup.vue';
import tabIdleTimeout from '@/utils/tabIdleTimeout';
import Vue from 'vue';

export default {
  name: 'App',
  components: {
    browserIncompatibilityWidget,
    welcome,
    attentionPopup
  },
  mixins: [tabIdleTimeout],
  data() {
    return {
      lastActivity: new Date()
    };
  },
  computed: {
    loginSuccess() {
      return this.$store.getters.getLoginSuccess;
    }
  },
  created() {
    document.body.addEventListener('mousedown', () => {
      this.activity();
    });
    document.body.addEventListener('mousemove', () => {
      this.activity();
    });
    document.body.addEventListener('keypress', () => {
      this.activity();
    });
    setInterval(() => {
      this.$store.dispatch('logActivity', this.lastActivity);
    }, 60000);
    this.tabIdleTimeoutHandler = this.reloadApp;
    this.tabIdleTimeout = Vue.options.filters.config_check(
      'feature.tab_idle.app.timeout'
    );
  },
  methods: {
    activity() {
      this.lastActivity = new Date();
    },
    reloadApp() {
      location.reload();
    }
  }
};
</script>
<style src="../node_modules/@ciq-dev/style-guide/icons/style.css"></style>
<style src="../node_modules/@ciq-dev/style-guide/dist/style.css"></style>

<style lang="scss">
// injecting css files associated with ciq-ui
@import '@/style/scss/style.scss';
@import '~@ciq-dev/ciq-ui/build_output/lib/ciq-ui.css';
</style>
