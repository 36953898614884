export function getFormattedAutomationTypesCardData(
  automationType,
  overviewData,
  metricConfig,
  selectedRetailer
) {
  if (selectedRetailer === 'retail_and_3p') {
    switch (automationType) {
      case 'unavailable': {
        return {
          skus_hybrid: {
            value: overviewData.unavailable,
            config: metricConfig.unavailable
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_unavailable,
            config: metricConfig.impact_unavailable
          },
          tickets_logged: {
            value: overviewData.total_cases_unavailable_skus,
            config: metricConfig.total_cases_unavailable_skus
          },
          skus_retail: {
            value: overviewData.unavailable_skus__retail,
            config: metricConfig.unavailable_skus__retail
          },
          revenue_impact_retail: {
            value: overviewData.impact_unavailable__retail,
            config: metricConfig.impact_unavailable__retail
          },
          skus_3p: {
            value: overviewData.unavailable_skus__3p,
            config: metricConfig.unavailable_skus__3p
          },
          revenue_impact_3p: {
            value: overviewData.impact_unavailable__3p,
            config: metricConfig.impact_unavailable__3p
          },
          attention_tickets: {
            value: overviewData.pending_on_you_unavailable_skus,
            config: metricConfig.pending_on_you_unavailable_skus
          }
        };
      }
      case '3pVariant': {
        return {
          skus_hybrid: {
            value: overviewData.three_p_variants,
            config: metricConfig.three_p_variants
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_three_p_variants,
            config: metricConfig.impact_three_p_variants
          },
          tickets_logged: {
            value: overviewData.total_cases_3p_variants,
            config: metricConfig.total_cases_3p_variants
          },
          skus_retail: {
            value: overviewData.three_p_variants__retail,
            config: metricConfig.three_p_variants__retail
          },
          revenue_impact_retail: {
            value: overviewData.impact_three_p_variants__retail,
            config: metricConfig.impact_three_p_variants__retail
          },
          skus_3p: {
            value: overviewData.three_p_variants__3p,
            config: metricConfig.three_p_variants__3p
          },
          revenue_impact_3p: {
            value: overviewData.impact_three_p_variants__3p,
            config: metricConfig.impact_three_p_variants__3p
          },
          attention_tickets: {
            value: overviewData.pending_on_you_3p_variants,
            config: metricConfig.pending_on_you_3p_variants
          }
        };
      }
      case 'contentAuthority': {
        return {
          skus_hybrid: {
            value: overviewData.content_authority,
            config: metricConfig.content_authority
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_content_authority,
            config: metricConfig.impact_content_authority
          },
          tickets_logged: {
            value: overviewData.total_cases_content_authority,
            config: metricConfig.total_cases_content_authority
          },
          skus_retail: {
            value: overviewData.content_authority__retail,
            config: metricConfig.content_authority__retail
          },
          revenue_impact_retail: {
            value: overviewData.impact_content_authority__retail,
            config: metricConfig.impact_content_authority__retail
          },
          skus_3p: {
            value: overviewData.content_authority__3p,
            config: metricConfig.content_authority__3p
          },
          revenue_impact_3p: {
            value: overviewData.impact_content_authority__3p,
            config: metricConfig.impact_content_authority__3p
          },
          attention_tickets: {
            value: overviewData.pending_on_you_content_authority,
            config: metricConfig.pending_on_you_content_authority
          }
        };
      }
      case 'variationAuthority': {
        return {
          skus_hybrid: {
            value: overviewData.variation_authority,
            config: metricConfig.variation_authority
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_variation_authority,
            config: metricConfig.impact_variation_authority
          },
          tickets_logged: {
            value: overviewData.total_cases_variation_authority,
            config: metricConfig.total_cases_variation_authority
          },
          skus_retail: {
            value: overviewData.variation_authority__retail,
            config: metricConfig.variation_authority__retail
          },
          revenue_impact_retail: {
            value: overviewData.impact_variation_authority__retail,
            config: metricConfig.impact_variation_authority__retail
          },
          skus_3p: {
            value: overviewData.variation_authority__3p,
            config: metricConfig.variation_authority__3p
          },
          revenue_impact_3p: {
            value: overviewData.impact_variation_authority__3p,
            config: metricConfig.impact_variation_authority__3p
          },
          attention_tickets: {
            value: overviewData.pending_on_you_variation_authority,
            config: metricConfig.pending_on_you_variation_authority
          }
        };
      }
    }
  } else if (selectedRetailer === 'retail') {
    switch (automationType) {
      case 'unavailable': {
        return {
          skus_hybrid: {
            value: overviewData.unavailable_skus__retail,
            config: metricConfig.unavailable_skus__retail
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_unavailable__retail,
            config: metricConfig.impact_unavailable__retail
          },
          tickets_logged: {
            value: overviewData.total_cases_unavailable_skus,
            config: metricConfig.total_cases_unavailable_skus
          },
          attention_tickets: {
            value: overviewData.pending_on_you_unavailable_skus,
            config: metricConfig.pending_on_you_unavailable_skus
          }
        };
      }
      case '3pVariant': {
        return {
          skus_hybrid: {
            value: overviewData.three_p_variants__retail,
            config: metricConfig.three_p_variants__retail
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_three_p_variants__retail,
            config: metricConfig.impact_three_p_variants__retail
          },
          tickets_logged: {
            value: overviewData.total_cases_3p_variants,
            config: metricConfig.total_cases_3p_variants
          },
          attention_tickets: {
            value: overviewData.pending_on_you_3p_variants,
            config: metricConfig.pending_on_you_3p_variants
          }
        };
      }
      case 'contentAuthority': {
        return {
          skus_hybrid: {
            value: overviewData.content_authority__retail,
            config: metricConfig.content_authority__retail
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_content_authority__retail,
            config: metricConfig.impact_content_authority__retail
          },
          tickets_logged: {
            value: overviewData.total_cases_content_authority,
            config: metricConfig.total_cases_content_authority
          },
          attention_tickets: {
            value: overviewData.pending_on_you_content_authority,
            config: metricConfig.pending_on_you_content_authority
          }
        };
      }
      case 'variationAuthority': {
        return {
          skus_hybrid: {
            value: overviewData.variation_authority__retail,
            config: metricConfig.variation_authority__retail
          },
          revenue_impact_hybrid: {
            value: overviewData.impact_variation_authority__retail,
            config: metricConfig.impact_variation_authority__retail
          },
          tickets_logged: {
            value: overviewData.total_cases_variation_authority,
            config: metricConfig.total_cases_variation_authority
          },
          attention_tickets: {
            value: overviewData.pending_on_you_variation_authority,
            config: metricConfig.pending_on_you_variation_authority
          }
        };
      }
    }
  } else {
    return {
      skus_hybrid: {
        value: overviewData.unavailable_skus__3p,
        config: metricConfig.unavailable_skus__3p
      },
      revenue_impact_hybrid: {
        value: overviewData.impact_unavailable__3p,
        config: metricConfig.impact_unavailable__3p
      },
      tickets_logged: {
        value: {
          tag1: 0
        },
        config: metricConfig.total_cases_unavailable_skus
      }
    };
  }
}
