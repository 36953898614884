var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widgetContainer',{staticClass:"chargebackStatsWidget",attrs:{"header-options":[],"footer-options":[],"context":_vm.context,"title":(_vm.metadata || {}).label,"is-loading":_vm.isWidgetLoading,"enable-footer":false},scopedSlots:_vm._u([{key:"body",fn:function(ref){return [_c('div',{staticClass:"stats-info card"},[_c('div',{staticClass:"u-font-size-5 u-color-grey-mid-light u-spacing-mb-s"},[_vm._v(" Total Chargebacks ")]),_c('metric',{staticClass:"u-spacing-mb-xs",attrs:{"data":_vm.chargebacksAmount,"config":{
          invertTag2: true,
          tag1Unit: { pre: '', suff: 'currency' },
          tag2Unit: { suff: '%' }
        },"size":"l"}}),_c('div',{staticClass:"u-font-size-7 u-color-grey-lighter u-display-flex u-flex-align-items-center"},[_vm._v(" Across "),_c('span',{staticClass:"u-font-weight-600 u-color-grey-light u-spacing-mh-xs"},[_vm._v(" "+_vm._s(_vm.totalChargebackGroups.tag1)+" ")]),_vm._v(" Chargeback groups and "),_c('span',{staticClass:"u-font-weight-600 u-color-grey-light u-spacing-mh-xs"},[_vm._v(" "+_vm._s(_vm.totalChargebackTypes.tag1)+" ")]),_vm._v(" chargeback types ")])],1),_c('div',{staticClass:"stats-info card"},[_c('div',{staticClass:"u-font-size-5 u-color-grey-mid-light u-spacing-mb-s"},[_vm._v(" No. of Chargeback Types meeting the target ")]),_c('div',{staticClass:"stats-info__metric u-spacing-mb-xs"},[_c('metric',{staticClass:"chargeback-type-metric",attrs:{"hide-zero":false,"data":_vm.chargebacksMetTarget,"config":{
            invertTag2: false,
            tag1Unit: { pre: '', suff: '' },
            tag2Unit: { suff: ' CBT' }
          },"size":"l"}})],1),_c('div',{staticClass:"u-font-size-7 u-color-grey-lighter u-display-flex u-flex-align-items-center"},[_vm._v(" Across "),_c('span',{staticClass:"u-font-weight-600 u-color-grey-light u-spacing-mh-xs"},[_vm._v(" "+_vm._s(_vm.totalChargebackTypes.tag1)+" ")]),_vm._v(" Chargeback types ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }