<template>
  <div
    class="u-display-flex u-flex-direction-column"
    :class="isPopup ? '' : `${panelState} rightPanel`"
  >
    <div
      v-if="!isPopup"
      class="widget__header u-flex-none u-border-bottom u-border-color-grey-xxx-light u-border-width-s"
      data-cy="manageColumnHeader"
    >
      <div
        class="u-display-flex u-flex-align-items-flex-start u-spacing-ph-m u-spacing-pv-l"
      >
        <div class="u-display-flex">
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
            :icon="'manage-coloumns'"
          />
        </div>
        <div class="widget__header--title u-flex-1 u-spacing-pl-xs">
          <div class="u-font-size-4 u-color-grey-base u-font-weight-600">
            Manage Columns
          </div>
          <div class="u-font-size-5 u-color-grey-base u-spacing-pt-s">
            Drag and drop items to rearrange the columns
          </div>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-flex-end u-cursor-pointer"
          @click="closeModal"
        >
          <rb-icon
            class="u-color-grey-mid-light rb-icon--small"
            :icon="'cross'"
          />
        </div>
      </div>
    </div>
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      :class="!isPopup ? 'u-spacing-ph-m u-spacing-pv-m' : 'u-spacing-pv-s'"
    >
      <form
        data-cy="searchField"
        class="u-position-relative u-width-100 u-display-flex"
        :class="[!isPopup ? 'u-spacing-mr-s' : '']"
        @submit.prevent="onSearchInput"
      >
        <rb-input
          v-model="searchText"
          placeholder="Search"
          class="search__input u-width-100 u-border-left-none u-spacing-pl-0"
          :class="isPopup ? 'u-spacing-ph-0' : ''"
          :rounded="false"
          @input="onSearchInput"
        />
        <span
          class="u-position-absolute u-display-flex"
          style="top: 0px; bottom: 0; margin: auto"
          :style="{
            left: isPopup ? '24px' : '8px'
          }"
          @click="onSearchInput"
        >
          <rb-icon
            icon="search"
            class="search__input-icon rb-icon--medium"
          />
        </span>
        <span
          class="u-position-absolute u-display-flex"
          style="top: 0px; right: 8px; bottom: 0; margin: auto"
          @click="onSearchInput"
        >
          <span
            v-if="searchText"
            class="search-text-input u-display-flex u-flex-align-items-center u-color-grey-lighter u-font-size-7"
          >
            {{
              searchTermCount === 1
                ? `${searchTermCount} Result`
                : `${searchTermCount} Results`
            }}
          </span>
        </span>
      </form>
    </div>
    <div
      class="widget__body u-display-flex u-flex-1 u-overflow-auto"
      :class="isPopup ? 'manage-columns-popup' : ''"
    >
      <div
        slot="detail"
        class="u-width-100 u-height-100 u-position-relative"
      >
        <div
          class="panel--right u-width-100 u-display-flex u-flex-direction-column"
        >
          <div
            class=""
            :class="!isPopup ? 'u-spacing-pt-s u-spacing-pb-s' : ''"
          >
            <div
              v-if="!isPopup"
              class="u-font-size-4 u-color-grey-base u-font-weight-600 u-spacing-ph-m u-spacing-pb-m"
            >
              All Columns View
            </div>
            <div
              v-for="item in detailedNonNegotiableColumns"
              :key="item.alias"
              class="list__item u-spacing-ph-m u-spacing-pv-s u-display-flex"
            >
              <rb-icon
                :icon="'lock'"
                class="rb-icon--medium u-color-grey-lighter u-spacing-mr-m"
              />
              <div
                v-if="isPopup"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  :icon="'top_movers'"
                  class="rb-icon--medium u-color-blue-base u-spacing-mr-xs"
                />
                <span
                  class="u-font-size-5 u-color-grey-light u-font-weight-400"
                  >{{ item.label }}</span
                >
              </div>
              <rb-checkbox
                v-else
                v-model="item.visible"
                :title="item.label"
                disabled
              >
                {{ item.label }}
              </rb-checkbox>
            </div>
          </div>
          <div
            class="u-spacing-mh-m u-border-width-s u-border-color-grey-xxx-light"
            :class="!isPopup ? 'u-border-top u-spacing-pv-s' : ''"
          >
            <div
              v-if="!isPopup"
              class="u-display-flex u-flex-align-items-baseline u-position-sticky u-bg-color-white-base u-z-index-1"
              style="top: 0px"
            >
              <div
                class="u-font-size-5 u-color-grey-mid-light u-font-weight-600 u-spacing-ph-xxs u-spacing-pv-m"
              >
                {{ selectedColumnsLength }} / {{ totalColumnsLength }}
              </div>
              <rb-icon
                icon="dot"
                class="rb-icon--small u-color-grey-xx-light"
              />
              <span
                class="u-color-blue-base u-cursor-pointer u-font-weight-600 u-spacing-pl-xs"
                @click="selectAllClick"
              >
                <span
                  v-if="selectedColumnsLength === totalColumnsLength"
                  class="u-spacing-mr-s u-font-size-5"
                  data-cy="deselectAll"
                >
                  Deselect All </span
                ><span
                  v-else
                  class="u-spacing-mr-s u-font-size-5"
                  data-cy="selectAll"
                >
                  Select All
                </span>
              </span>
            </div>
            <draggable
              v-model="updatedColumns"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                v-for="(item, index) in updatedColumns"
                :key="item.keyName"
                class="list__item u-spacing-pv-s u-display-flex"
                :class="!isPopup ? 'u-spacing-ph-xxs' : ''"
              >
                <rb-icon
                  :icon="'draggable-reorder'"
                  class="rb-icon--medium u-color-grey-lighter u-spacing-mr-m u-cursor-move"
                />
                <rb-checkbox
                  v-model="item.visible"
                  :disabled="disableNonSelectedMetrics && !item.visible"
                  class="manageColumnRow"
                >
                  <div class="u-display-flex u-flex-align-items-flex-end">
                    <html-component
                      wrapper-element="span"
                      class="u-spacing-mr-s u-font-size-5"
                      :class="isPopup ? 'u-font-weight-400' : ''"
                      :html-string="item.label"
                    />
                    <div class="move-item u-display-none">
                      <span
                        v-tippy="moveItemTippyConfig"
                        class="u-cursor-pointer"
                        title="Move to top"
                        @click.prevent="moveItem(index, 0)"
                      >
                        <rb-icon
                          :icon="'move-to-top'"
                          class="rb-icon--small u-color-grey-lighter"
                        />
                      </span>
                      <span
                        v-tippy="moveItemTippyConfig"
                        class="u-cursor-pointer u-spacing-ml-s"
                        title="Move to bottom"
                        @click.prevent="moveItem(index, null)"
                      >
                        <rb-icon
                          :icon="'move-to-bottom'"
                          class="rb-icon--small u-color-grey-lighter"
                        />
                      </span>
                    </div>
                  </div>
                </rb-checkbox>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-width-100 u-bg-color-grey-white u-spacing-p-m actions-container u-width-50"
      :class="[
        isPopup
          ? 'u-flex-justify-content-space-between'
          : 'u-flex-justify-content-flex-end'
      ]"
    >
      <rb-button
        v-if="!isPopup"
        class="u-border-color-grey-xx-light u-spacing-mr-s"
        :click-fn="reset"
        :text="'Reset'"
        :size="'s'"
        :type="'hollow'"
      />
      <span
        v-if="maxSelectableMetrics !== 1000"
        class="u-color-grey-lighter u-font-size-6 u-font-weight-400"
        >Select max. {{ maxSelectableMetrics }} metrics</span
      >
      <rb-button
        :size="'s'"
        :disabled="applyBtnDisabled"
        :click-fn="applyChanges"
        class=""
        :class="[
          applyBtnDisabled ? 'u-bg-color-grey-x-light' : 'u-bg-color-blue-base'
        ]"
        text="Apply"
        :type="'filled'"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import splitContainer from '@/components/layouts/splitContainer';
import { eventBus } from '@/utils/services/eventBus';
import { debounce, cloneDeep, isEqual } from 'lodash';
import { mergeByProperty } from '@/utils/helpers/utils.js';
import HtmlComponent from '@/components/basic/html-component.vue';

export default {
  components: {
    draggable,
    splitContainer,
    HtmlComponent
  },
  props: {
    apply: {
      type: Function,
      default: () => {}
    },
    listener: {
      type: String,
      default: null
    },
    selectedColumns: {
      type: Array,
      default: []
    },
    unselectedColumns: {
      type: Array,
      default: []
    },
    detailedMetrics: {
      type: Object,
      default: {}
    },
    nonNegotiableColumns: {
      type: Array,
      default: []
    },
    debounceTime: {
      type: Number,
      default: 500
    },
    isPopup: {
      type: Boolean,
      default: false
    },
    maxSelectableMetrics: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      tru: true,
      tooltipActive: false,
      selectedRecommendationType: null,
      columnsToFilter: [
        'asin',
        'imageUrl',
        'title',
        'vendor_action_required',
        'normalized_outcome',
        'ticket_id'
      ],
      reorderingStartIndex: null,
      panelState: 'close',
      searchText: '',
      applyBtnDisabled: true,
      updatedColumns: [],
      searchTermCount: 0,
      lastFilteredColumns: [],
      detailedNonNegotiableColumns: [],
      finalColumns: [],
      initUpdatedColumns: [],
      selectAll: null,
      triggerSelectAll: true,
      moveItemTippyConfig: {
        placement: 'bottom',
        delay: [500, 0]
      },
      disableNonSelectedMetrics: false
    };
  },
  computed: {
    selectedColumnsLength() {
      return this.updatedColumns.filter((column) => column.visible === true)
        .length;
    },
    totalColumnsLength() {
      return this.updatedColumns.length;
    }
  },
  watch: {
    updatedColumns: {
      deep: true,
      handler(nv) {
        if (!isEqual(nv, this.initUpdatedColumns)) {
          this.applyBtnDisabled = false;
        }
        let selectAll = this.updatedColumns.reduce((acc, value, index) => {
          return value.visible ? acc + 1 : acc;
        }, 0);
        this.disableNonSelectedMetrics = selectAll >= this.maxSelectableMetrics;
        if (selectAll === this.updatedColumns.length) {
          this.triggerSelectAll = true;
          this.selectAll = true;
        } else if (selectAll === 0) {
          this.triggerSelectAll = true;
          this.selectAll = false;
        } else {
          this.triggerSelectAll = false;
          this.selectAll = false;
        }
      }
    },
    selectAll(val) {
      if (this.triggerSelectAll) {
        this.updatedColumns.map((item) => {
          item.visible = val;
        });
      }
    }
  },
  created() {
    eventBus.$on(this.listener, (data) => {
      this.panelState = data;
    });
    this.updatedColumns = this.generateWidgetColumns();
    this.initUpdatedColumns = cloneDeep(this.updatedColumns);
    this.finalColumns = this.updatedColumns;
    this.detailedNonNegotiableColumns = this.generateNonNegotiableColumns();
  },
  methods: {
    moveItem(from, to) {
      let element = this.updatedColumns[from];
      this.updatedColumns.splice(from, 1);
      this.updatedColumns.splice(
        to === null ? this.updatedColumns.length : to,
        0,
        element
      );
    },
    selectAllClick() {
      this.triggerSelectAll = true;
      if (this.selectedColumnsLength === this.totalColumnsLength) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    },
    applyChanges() {
      mergeByProperty(this.finalColumns, this.updatedColumns, 'alias');

      this.finalColumns.sort((first, second) => {
        return (
          this.updatedColumns.indexOf(first) -
          this.updatedColumns.indexOf(second)
        );
      });

      let selectedColumns = this.finalColumns
        .filter((column) => column.visible === true)
        .map((column) => column.alias);

      selectedColumns = this.nonNegotiableColumns.concat(selectedColumns);

      this.$emit('manageColumnsApply', {
        selectedColumns
      });

      this.closeModal();
    },
    closeModal() {
      eventBus.$emit(this.listener, 'close');
    },
    openModal() {
      eventBus.$emit(this.listener, 'open');
    },
    reset() {
      this.updatedColumns = this.generateWidgetColumns();
      this.searchText = '';
      this.applyBtnDisabled = true;
    },
    generateWidgetColumns() {
      let widgetColumns = [];
      widgetColumns = this.selectedColumns
        .filter(
          (column) =>
            this.detailedMetrics[column] !== undefined &&
            !this.nonNegotiableColumns.includes(column) &&
            this.detailedMetrics[column]?.metadata?.visible !== false
        )
        .map((column) => {
          return {
            ...this.detailedMetrics[column],
            visible: true
          };
        });
      const unselectedColumns = this.unselectedColumns
        .filter(
          (column) =>
            this.detailedMetrics[column] !== undefined &&
            !this.nonNegotiableColumns.includes(column) &&
            this.detailedMetrics[column]?.metadata?.visible !== false &&
            this.detailedMetrics[column].label !== ''
        )
        .map((column) => {
          return {
            ...this.detailedMetrics[column],
            visible: false
          };
        });

      widgetColumns = widgetColumns.concat(unselectedColumns);

      return widgetColumns;
    },
    onSearchInput() {
      const searchComponent = this;
      debounce(function () {
        this.filterAndSearchColumns();
      }, this.debounceTime).call(searchComponent);
    },
    filterAndSearchColumns() {
      mergeByProperty(this.finalColumns, this.updatedColumns, 'alias');
      this.searchTermCount = 0;
      let filteredAndSearchedArray = [];
      let columnsArrForSearch = cloneDeep(this.generateWidgetColumns());
      if (
        (this.searchText?.length && !/\S/.test(this.searchText)) || // contains only whitespace
        !this.isAllowedChars(this.searchText) || // contains not allowed special chars
        !columnsArrForSearch.length
      ) {
        filteredAndSearchedArray = [];
      } else {
        filteredAndSearchedArray = columnsArrForSearch?.filter((column) => {
          if (this.searchText !== '') {
            this.columnHasSearchText = false;
            column.label = this.handleSearchHighlight(column.label);
            if (this.columnHasSearchText) {
              return column;
            }
          } else {
            return column;
          }
        });
      }

      filteredAndSearchedArray = filteredAndSearchedArray?.map(
        (filteredItem) => {
          let targetItem = this.finalColumns.find((column) => {
            return column.alias === filteredItem.alias;
          });
          return {
            ...filteredItem,
            visible: targetItem.visible
          };
        }
      );

      this.updatedColumns = filteredAndSearchedArray;
    },
    handleSearchHighlight(text) {
      // highlight the searched text in yellow color
      let str = this.searchText;
      let reg = new RegExp(str, 'gi');
      let replaced = text?.search(reg) >= 0;
      if (replaced && !this.columnHasSearchText) {
        this.columnHasSearchText = true;
      }
      return text?.replace(reg, (str) => {
        this.searchTermCount += 1;
        return `<mark class="searched-term">${str}</mark>`;
      });
    },
    isAllowedChars(str) {
      // checking for allowed chars in search term
      let charCode;
      let strLength = str.length;
      // allowedSpecialCharecters - [space, !, #, &, *];
      const allowedSpecialCharecters = [32, 33, 35, 38, 42];
      for (let i = 0; i < strLength; i++) {
        charCode = str.charCodeAt(i);
        if (
          !(charCode > 47 && charCode < 58) && // numeric (0-9)
          !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
          !(charCode > 96 && charCode < 123) && // lower alpha (a-z)
          allowedSpecialCharecters.indexOf(charCode) === -1
        ) {
          return false;
        }
      }
      return true;
    },
    generateNonNegotiableColumns() {
      let widgetColumns = [];
      widgetColumns = this.nonNegotiableColumns
        .filter(
          (column) =>
            this.detailedMetrics[column] !== undefined &&
            this.detailedMetrics[column]?.metadata?.visible !== false
        )
        .map((column) => {
          return {
            ...this.detailedMetrics[column],
            visible: true
          };
        });
      return widgetColumns;
    }
  }
};
</script>

<style lang="css" scoped>
.manageColumnRow .move-item {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
}
.manageColumnRow:hover .move-item {
  display: block;
}

.rightPanel {
  z-index: 105;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: white;
  display: flex;
  width: 0;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
  overflow-y: auto;
}

.rightPanel .header {
  width: 100%;
  height: 46px;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightPanel .body {
  flex-grow: 1;
  overflow: auto;
}

.rightPanel.close {
  width: 0%;
}
.rightPanel.close .body {
  display: none;
}

.rightPanel.open {
  width: 25%;
}
.rightPanel.open .body {
  display: block;
}

.list__item.active {
  background: #e5f1fe;
}
.list__item .list__item--text {
  line-height: 20px;
}

.actions-container {
  position: sticky;
  bottom: 0;
  box-shadow: 0 -1px 4px 0 rgba(43, 51, 59, 0.15);
}
.search-text-input {
  width: 60px;
  margin-right: 10px;
}
.manage-columns-popup {
  max-height: 250px;
  overflow-y: scroll;
}
</style>
