import {
  adgroupConfig,
  keywordTargeting,
  campaignType,
  settings,
  placementAndBidMultiplier
} from '@/components/ams/campaign_creation/setup_configs/samsclub_api/common/index.js';
import { cloneDeep } from 'lodash';
import { adGroupCustomActionPanelForAutoSP } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';

const formStepConfigForManual = () => {
  const keywordTargetingConfig = cloneDeep(keywordTargeting.manual);
  keywordTargetingConfig.noKeywordsErrorMessage =
    'One keyword is required for SP campaigns';
  keywordTargetingConfig.keywordBidValidationObject = {
    min: 0.3
  };

  const adGroupConfigCloned = cloneDeep(adgroupConfig);
  adGroupConfigCloned.skusCountValidationObject = {
    min: 1,
    max: 2000
  };

  const clonedSettings = cloneDeep(settings);
  clonedSettings.rightInfoAlert = {
    title: 'Campaign will be created!',
    desc: 'Going to next step will create the campaign, you can continue adding campaign details. We recommend you to please proceed forward to complete the campaign details.'
  };

  const stepsConfig = [
    campaignType,
    clonedSettings,
    adGroupConfigCloned,
    keywordTargetingConfig
    // placementAndBidMultiplier
  ];

  return stepsConfig;
};

export const manual = {
  header: {
    title: 'Campaign Creator',
    desc: 'Create Sponsored Product campaigns on Sam’s Club'
  },
  getter: 'samsclub_apiCampaignCreation/getAllStepsData',
  action: 'samsclub_apiCampaignCreation/updateAllStepsData',
  stepsConfig: formStepConfigForManual()
};

const formStepConfigForAuto = () => {
  // Make changes in placementAndBidMultiplier config for auto
  const placementAndBidMultiplierConfig = cloneDeep(placementAndBidMultiplier);
  placementAndBidMultiplierConfig.name = 'Bid Multiplier (Optional)';

  // Make changes in adGroup page for auto
  let adGroupConfigCloned = cloneDeep(adgroupConfig);
  const newConfigsForAdGroup = {
    customActionPanelCustomState: adGroupCustomActionPanelForAutoSP(
      'sp_auto',
      'create'
    ),
    skusCountValidationObject: {
      min: 1,
      max: 2000
    },
    skuBidValidationObject: {
      min: 0.2,
      message:
        'The minimum bid for products in Auto campaign products should be $0.2.'
    }
  };
  adGroupConfigCloned = {
    ...adGroupConfigCloned,
    ...newConfigsForAdGroup
  };

  const clonedSettings = cloneDeep(settings);
  clonedSettings.rightInfoAlert = {
    title: 'Campaign will be created!',
    desc: 'Going to next step will create the campaign, you can continue adding campaign details. We recommend you to please proceed forward to complete the campaign details.'
  };
  const keywordTargetingConfig = cloneDeep(keywordTargeting.auto);
  keywordTargetingConfig.noKeywordsErrorMessage =
    'One keyword is required for SP campaigns';

  const stepsConfig = [
    campaignType,
    clonedSettings,
    adGroupConfigCloned,
    keywordTargetingConfig,
    placementAndBidMultiplierConfig
  ];
  return stepsConfig;
};

export const auto = {
  header: {
    title: 'Campaign Creator',
    desc: 'Create Sponsored Product campaigns on Sam’s Club'
  },
  getter: 'samsclub_apiCampaignCreation/getAllStepsData',
  action: 'samsclub_apiCampaignCreation/updateAllStepsData',
  stepsConfig: formStepConfigForAuto()
};
