import { criteoRetailers, citrusRetailers } from '@/router/utils.js';
import { uniq } from 'lodash';

const conditionStepConfigForCitrusRetailers = [
  { name: 'Last bid change', activeFor: ['CAMPAIGN', 'SEARCH_TERM'] },
  { name: 'Last target bid change', activeFor: ['LINE_ITEM'] },
  { name: 'Last override bid change', activeFor: ['SKU'] }
];

const infoBoxConfig = {};

criteoRetailers.forEach((retailer) => {
  infoBoxConfig[retailer] = {
    show: true,
    KEYWORD:
      'Bid Actions will be taken across Manual and Automated keywords. To filter out either of these, please use these filters in these conditions section'
  };
});

const listConfigForCitrusRetailers = ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM'];

export default {
  availableActionTypes: [
    'CAMPAIGN_BUDGET_CHANGE',
    'WALMART_CAMPAIGN_BUDGET_CHANGE',
    'KEYWORD_BID_CHANGE',
    'WALMART_KEYWORD_BID_CHANGE',
    'WALMART_PRODUCTAD_BID_CHANGE',
    'TARGET_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'TARGET_LINE_ITEM_TARGET_BID_CHANGE',
    'TARGET_SKU_BID_CHANGE',
    'TARGET_LINE_ITEM_MAX_BID_CHANGE',
    'SAMS_CLUB_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'SAMS_CLUB_LINE_ITEM_BID_CHANGE',
    'SAMS_CLUB_SKU_BID_CHANGE',
    'SAMS_CLUB_LINE_ITEM_MAX_BID_CHANGE',
    'INSTACART_CAMPAIGN_BUDGET_CHANGE',
    'INSTACART_KEYWORD_BID_CHANGE',
    'INSTACART_MODIFY_DEFAULT_BID_CHANGE',
    'ALBERTSONS_CAMPAIGN_BUDGET_CHANGE',
    'ALBERTSONS_CAMPAIGN_BID_CHANGE',
    'HYVEE_CAMPAIGN_BUDGET_CHANGE',
    'HYVEE_CAMPAIGN_BID_CHANGE',
    'SHIPT_CAMPAIGN_BUDGET_CHANGE',
    'SHIPT_CAMPAIGN_BID_CHANGE',
    'SHOPRITE_CAMPAIGN_BUDGET_CHANGE',
    'SHOPRITE_CAMPAIGN_BID_CHANGE',
    'COSTCO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'COSTCO_LINE_ITEM_BID_CHANGE',
    'COSTCO_SKU_BID_CHANGE',
    'COSTCO_LINEITEM_KEYWORDS_BID_CHANGE',
    'SHIPT_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'SHIPT_CRITEO_LINE_ITEM_BID_CHANGE',
    'SHIPT_CRITEO_SKU_BID_CHANGE',
    'WALMART_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'WALMART_CRITEO_LINE_ITEM_BID_CHANGE',
    'WALMART_CRITEO_SKU_BID_CHANGE',
    'WALGREEN_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'WALGREEN_CRITEO_LINE_ITEM_BID_CHANGE',
    'WALGREEN_CRITEO_SKU_BID_CHANGE',
    'CVS_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'CVS_CRITEO_LINE_ITEM_BID_CHANGE',
    'CVS_CRITEO_SKU_BID_CHANGE',
    'ASDA_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'ASDA_CRITEO_LINE_ITEM_BID_CHANGE',
    'ASDA_CRITEO_SKU_BID_CHANGE',
    'CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'CRITEO_COMMON_LINE_ITEM_BID_CHANGE',
    'CRITEO_COMMON_SKU_BID_CHANGE',
    'CRITEO_COMMON_LINEITEM_KEYWORDS_BID_CHANGE',
    'MEIJER_LINE_ITEM_DAILY_BUDGET_CHANGE',
    'MEIJER_LINE_ITEM_BID_CHANGE',
    'MEIJER_LINEITEM_KEYWORDS_BID_CHANGE',
    'MEIJER_SKU_BID_CHANGE',
    'TARGET_CITRUS_CAMPAIGN_BUDGET_CHANGE',
    'TARGET_CITRUS_CAMPAIGN_BID_CHANGE',
    'SAMSCLUB_API_PRODUCTAD_BID_CHANGE',
    'SAMSCLUB_API_KEYWORD_BID_CHANGE',
    'SAMSCLUB_API_CAMPAIGN_BUDGET_CHANGE',
    'AHOLD_CAMPAIGN_BID_CHANGE',
    'AHOLD_CAMPAIGN_BUDGET_CHANGE',
    'KROGER_PROMOTEIQ_SKU_BID_CHANGE',
    'KROGER_PROMOTEIQ_CAMPAIGN_DAILY_BUDGET_CHANGE',
    'CHEWY_PROMOTEIQ_SKU_BID_CHANGE',
    'CHEWY_PROMOTEIQ_CAMPAIGN_DAILY_BUDGET_CHANGE',
    'CHEWY_PROMOTEIQ_KEYWORD_BID_MODIFIER_CHANGE',
    'SAINSBURYS_CITRUS_CAMPAIGN_BUDGET_CHANGE',
    'SAINSBURYS_CITRUS_CAMPAIGN_BID_CHANGE',
    'WAITROSE_CITRUS_CAMPAIGN_BUDGET_CHANGE',
    'WAITROSE_CITRUS_CAMPAIGN_BID_CHANGE',
    'TESCO_CITRUS_CAMPAIGN_BUDGET_CHANGE',
    'TESCO_CITRUS_CAMPAIGN_BID_CHANGE',
    'GOPUFF_CITRUS_CAMPAIGN_BUDGET_CHANGE',
    'GOPUFF_CITRUS_CAMPAIGN_BID_CHANGE',
    'KROGER_CAMPAIGN_BUDGET_CHANGE',
    'KROGER_SKU_BID_CHANGE',
    'KROGER_ADGROUP_BUDGET_CHANGE',
    'KROGER_ADGROUP_BASE_BID_CHANGE'
  ],
  list: {
    amazon: ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM', 'ASIN'],
    target: ['LINE_ITEM', 'SKU'],
    costco: ['LINE_ITEM', 'SKU'],
    meijer: ['LINE_ITEM', 'SKU'],
    shipt_criteo: ['LINE_ITEM', 'SKU'],
    walmart_criteo: ['LINE_ITEM', 'SKU'],
    walgreen_criteo: ['LINE_ITEM', 'SKU'],
    cvs_criteo: ['LINE_ITEM', 'SKU'],
    asda_criteo: ['LINE_ITEM', 'SKU'],
    ulta_criteo: ['LINE ITEM', 'SKU'],
    albertsons_criteo: ['LINE ITEM', 'SKU'],
    ubereats_criteo: ['LINE ITEM', 'SKU'],
    target_rms: ['LINE ITEM', 'SKU'],
    freshdirect_criteo: ['LINE_ITEM', 'SKU'],
    samsclub: ['LINE_ITEM', 'SKU'],
    shipt_citrus: listConfigForCitrusRetailers,
    shoprite_citrus: listConfigForCitrusRetailers,
    hyvee_citrus: listConfigForCitrusRetailers,
    albertsons_citrus: listConfigForCitrusRetailers,
    target_citrus: listConfigForCitrusRetailers,
    ahold_citrus: listConfigForCitrusRetailers,
    walmart: ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM', 'PRODUCTADS'],
    samsclub_api: ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM', 'PRODUCTADS'],
    instacart: ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM', 'AD_GROUP'],
    kroger: ['CAMPAIGN', 'SKU', 'AD_GROUP'],
    kroger_promoteiq: ['CAMPAIGN', 'SKU'],
    chewy_promoteiq: ['CAMPAIGN', 'SKU', 'KEYWORD'],
    sainsburys_citrus: listConfigForCitrusRetailers,
    waitrose_citrus: listConfigForCitrusRetailers,
    tesco_citrus: listConfigForCitrusRetailers,
    gopuff_citrus: listConfigForCitrusRetailers,
    default: ['CAMPAIGN', 'KEYWORD', 'SEARCH_TERM', 'PRODUCTADS']
  },
  scopeStep: {
    default: {
      desc: [
        'A strategy can be applied to specific campaigns, keywords, search terms or SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    target: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    costco: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    freshdirect_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    meijer: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    shipt_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    walmart_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    walgreen_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    cvs_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    asda_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    ulta_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    albertsons_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    target_rms: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    },
    ubereats_criteo: {
      desc: [
        'A strategy can be applied to specific line items or Ad SKUs. This specifies items on which conditions apply.',
        'Click any item below to narrow down on options.'
      ]
    }
  },
  conditionStep: {
    default: [
      { name: 'Last bid change', activeFor: ['KEYWORD', 'SEARCH_TERM'] },
      { name: 'Last target bid change', activeFor: ['LINE_ITEM'] },
      { name: 'Last override bid change', activeFor: ['SKU'] }
    ],
    instacart: [
      {
        name: 'Last bid change',
        activeFor: ['AD_GROUP', 'KEYWORD', 'SEARCH_TERM']
      },
      { name: 'Last target bid change', activeFor: ['LINE_ITEM'] },
      { name: 'Last override bid change', activeFor: ['SKU'] }
    ],
    albertsons_citrus: conditionStepConfigForCitrusRetailers,
    hyvee_citrus: conditionStepConfigForCitrusRetailers,
    shipt_citrus: conditionStepConfigForCitrusRetailers,
    shoprite_citrus: conditionStepConfigForCitrusRetailers,
    target_citrus: conditionStepConfigForCitrusRetailers,
    ahold_citrus: conditionStepConfigForCitrusRetailers,
    sainsburys_citrus: conditionStepConfigForCitrusRetailers,
    waitrose_citrus: conditionStepConfigForCitrusRetailers,
    tesco_citrus: conditionStepConfigForCitrusRetailers,
    gopuff_citrus: conditionStepConfigForCitrusRetailers
  },
  conditionStepFilterDisable: {
    target_citrus: {
      shouldDisable: (item, context) => {
        const selectedFilterTypes = uniq(
          Object.keys(context.selectedValues.conditionFilterValues).map(
            (filterVal) =>
              context.primaryFilterDataCondition.find(
                (item) => item.dimensionName === filterVal
              ).dimensionType
          )
        );
        if (typeof item === 'object') {
          return (
            (item.dimensionType === 'ADVANCED_FILTERS' &&
              selectedFilterTypes.includes('EXPRESSION')) ||
            (item.dimensionType === 'EXPRESSION' &&
              selectedFilterTypes.includes('ADVANCED_FILTERS'))
          );
        }
        return true;
      },
      tooltipFn: (item, context) => {
        return 'Please select one of Expression (Standard) or Advanced Reporting Filters only';
      }
    }
  },
  filtersExtension: {
    default: {
      standardShelf: {
        apiValue: 'shelf_hierarchy',
        name: ' Standard Shelf',
        type: 'standard',
        delimiter: '----',
        replaceDelimiter: '~',
        uiDelimiter: '>',
        operator: 'ARRAY_CONTAINS',
        endsWithADelimiter: true
      },
      customShelf: {
        apiValue: 'shelf_hierarchy',
        type: 'custom',
        name: ' Custom Shelf',
        delimiter: '----',
        replaceDelimiter: '~',
        uiDelimiter: '>',
        operator: 'ARRAY_CONTAINS',
        endsWithADelimiter: true
      },
      campaignList: {
        apiValue: 'campaign_list',
        type: '',
        name: ' Campaign List',
        delimiter: '',
        replaceDelimiter: '',
        uiDelimiter: '',
        operator: 'ARRAY_CONTAINS',
        endsWithADelimiter: false
      }
    }
  },
  actionStep: {
    default: {
      validationObj: {
        absolute: {
          max: 1000,
          min: 0.02
        }
      }
    },
    target: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    costco: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    freshdirect_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    meijer: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    shipt_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    walmart_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    walgreen_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    cvs_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    asda_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    ulta_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    albertsons_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    target_rms: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    ubereats_criteo: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.1
        }
      }
    },
    instacart: {
      validationObj: {
        absolute: {
          max: 99999,
          min: 0.3
        }
      }
    },
    walmartSP: {
      validationObj: {
        absolute: {
          max: 100,
          min: 0.3
        }
      }
    },
    walmartSB: {
      validationObj: {
        absolute: {
          max: 100,
          min: 1
        }
      }
    },
    walmartSPandSB: {
      validationObj: {
        absolute: {
          max: 100,
          min: 0.3
        }
      },
      warningMessageValidationObject: {
        absolute: {
          max: 1,
          min: 0.3
        },
        warningMessage:
          'Input between $0.3 and $1 will be set for SP campaigns only'
      }
    },
    chewy_promoteiq: {
      validationObj: {
        absolute: {
          max: 1000,
          min: 0.02
        }
      },
      bidModifierValidationObj: {
        absolute: {
          max: 5,
          min: 1.1
        }
      }
    },
    kroger: {
      validationObj: {
        absolute: {
          max: 999999,
          min: 0.3
        }
      },
      skuBidValidationObj: {
        absolute: {
          max: 50,
          min: 0.3
        }
      },
      adGroupBaseBidValidationObj: {
        absolute: {
          max: 50,
          min: 0.3
        }
      }
    }
  },
  actionType: {
    target: {
      TARGET_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      TARGET_LINE_ITEM_TARGET_BID_CHANGE: 'bid',
      TARGET_LINE_ITEM_MAX_BID_CHANGE: 'maxbid'
    },
    samsclub: {
      SAMS_CLUB_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      SAMS_CLUB_LINE_ITEM_BID_CHANGE: 'bid',
      SAMS_CLUB_LINE_ITEM_MAX_BID_CHANGE: 'maxbid'
    },
    target_citrus: {
      TARGET_CITRUS_CAMPAIGN_BUDGET_CHANGE: 'budget',
      TARGET_CITRUS_CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    sainsburys_citrus: {
      SAINSBURYS_CITRUS_CAMPAIGN_BUDGET_CHANGE: 'budget',
      SAINSBURYS_CITRUS_CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    waitrose_citrus: {
      WAITROSE_CITRUS_CAMPAIGN_BUDGET_CHANGE: 'budget',
      WAITROSE_CITRUS_CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    tesco_citrus: {
      TESCO_CITRUS_CAMPAIGN_BUDGET_CHANGE: 'budget',
      TESCO_CITRUS_CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    gopuff_citrus: {
      GOPUFF_CITRUS_CAMPAIGN_BUDGET_CHANGE: 'budget',
      GOPUFF_CITRUS_CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    citrus_retailer: {
      CAMPAIGN_BUDGET_CHANGE: 'budget',
      CAMPAIGN_BID_CHANGE: 'maxbid'
    },
    promoteiq_retailer: {
      CAMPAIGN_DAILY_BUDGET_CHANGE: 'budget',
      SKU_BID_CHANGE: 'bid'
    },
    costco: {
      COSTCO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      COSTCO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    freshdirect_criteo: {
      CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CRITEO_COMMON_LINE_ITEM_BID_CHANGE: 'bid'
    },
    meijer: {
      MEIJER_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      MEIJER_LINE_ITEM_BID_CHANGE: 'bid'
    },
    ulta_criteo: {
      CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CRITEO_COMMON_LINE_ITEM_BID_CHANGE: 'bid'
    },
    albertsons_criteo: {
      CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CRITEO_COMMON_LINE_ITEM_BID_CHANGE: 'bid'
    },
    ubereats_criteo: {
      CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CRITEO_COMMON_LINE_ITEM_BID_CHANGE: 'bid'
    },
    target_rms: {
      CRITEO_COMMON_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CRITEO_COMMON_LINE_ITEM_BID_CHANGE: 'bid'
    },
    shipt_criteo: {
      SHIPT_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      SHIPT_CRITEO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    walmart_criteo: {
      WALMART_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      WALMART_CRITEO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    walgreen_criteo: {
      WALGREEN_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      WALGREEN_CRITEO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    cvs_criteo: {
      CVS_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      CVS_CRITEO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    asda_criteo: {
      ASDA_CRITEO_LINE_ITEM_DAILY_BUDGET_CHANGE: 'budget',
      ASDA_CRITEO_LINE_ITEM_BID_CHANGE: 'bid'
    },
    kroger: {
      KROGER_CAMPAIGN_BUDGET_CHANGE: 'budget',
      KROGER_SKU_BID_CHANGE: 'bid',
      KROGER_ADGROUP_BUDGET_CHANGE: 'budget',
      KROGER_ADGROUP_BASE_BID_CHANGE: 'base Bid'
    },
    kroger_promoteiq: {
      KROGER_PROMOTEIQ_CAMPAIGN_DAILY_BUDGET_CHANGE: 'budget',
      KROGER_PROMOTEIQ_SKU_BID_CHANGE: 'bid'
    },
    chewy_promoteiq: {
      CHEWY_PROMOTEIQ_KEYWORD_BID_MODIFIER_CHANGE: 'bid modifier'
    }
  },
  criteoRetailers,
  citrusRetailers,
  infoBoxConfig,
  customConditions: {
    amazon: {
      ASIN: [
        {
          dimensionLabel: 'Strategy',
          dimensionName: 'tags',
          dimensionColumn: 'tags',
          dimensionType: 'STRATEGY',
          multi: false
        }
      ]
    }
  }
};
