<template>
  <chart
    :id="`chart-${metadata.name}`"
    class="c3-large u-flex-1 chart_space_class"
    :config="chartConfigObject"
    :data="chartDataObject"
  />
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import chart from '@/components/basic/chart.vue';
const d3 = require('d3');

export default {
  components: {
    chart
  },
  props: {
    metricData: {
      type: Array,
      default: []
    },
    metadata: {
      type: Object,
      default: {}
    },
    cardPrimaryMetricColorCode: {
      type: Object,
      default: {}
    },
    metricsList: {
      type: Array,
      default: []
    },
    chartConfig: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      regionsObject: {},
      chartingDataArray: [],
      chartChange: 0,
      feedDateArray: [],
      colorsObject: {},
      typesObject: {}
    };
  },
  computed: {
    getChartingDataArray() {
      return this.chartingDataArray;
    },
    chartDataObject() {
      return {
        data: [
          [`${this.chartConfig.timeseries}`, ...(this.feedDateArray || [])],
          ...this.chartingDataArray
        ],
        types: this.typesObject,
        colors: this.colorsObject,
        regions: this.regionsObject
      };
    },
    chartConfigObject() {
      return {
        chartOptions: {
          oninit: () => {
            this.metricGradientGenerator();
            // Add your custom functions to be called on initialization of chart here
          },
          ...this.chartConfig,
          regions: this.regionsObject
        }
      };
    }
  },
  watch: {
    metricData(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.chartDataGenerator();
      }
    }
  },
  mounted() {
    this.chartDataGenerator();
  },
  methods: {
    chartDataGenerator() {
      this.chartingDataArray = this.metricData?.reduce(
        (prevMetr, metr, index) => {
          let metricsArray =
            metr?.TIMESERIES?.map((obj) => {
              if (index === 0) {
                this.feedDateArray.push(obj[this.chartConfig.timeseries]);
              }
              return obj[metr.name];
            }) || [];
          let gradientArray = cloneDeep(metricsArray);
          gradientArray.unshift(`gradient${index}`);

          metricsArray.unshift(
            this.metricsList.find((metric) => metric.alias === metr.alias)
              ?.label
          );

          return prevMetr.concat([metricsArray, gradientArray]);
        },
        []
      );

      this.chartingDataArray = this.chartingDataArray.map((elem) => {
        return elem.map((el, index) => {
          return (el =
            (index === 1 || index === elem.length - 1) && el === null ? 0 : el);
        });
      });

      this.regionsObject = this.dashedChartRegionGenerator(
        this.chartingDataArray
      );

      this.metricsList.forEach((metr, index) => {
        this.colorsObject[`${metr.label}`] =
          this.cardPrimaryMetricColorCode[metr.alias];
        this.typesObject[`${metr.label}`] = 'line';
        this.colorsObject[
          `gradient${index}`
        ] = `url(#myGradient${index}${this.metadata.name})`;
        this.typesObject[`gradient${index}`] = 'area';
      });
    },
    dashedChartRegionGenerator(chartingDataArray) {
      let regionsObject = {};

      let chartingPoints = chartingDataArray.map((metric) => metric[0]);

      chartingDataArray = chartingDataArray.filter(
        (element, index) => index % 2 === 0
      );

      chartingDataArray.forEach((elem, index1) => {
        let flag = false;
        let flagIndex = -1;

        elem.forEach((el, index) => {
          if (el === null) {
            if (flag === false) {
              flag = true;
              flagIndex = index;
            }
          } else if (flag === true) {
            if (regionsObject[chartingPoints[index1 * 2]] === undefined) {
              regionsObject[chartingPoints[index1 * 2]] = [];
              regionsObject[chartingPoints[index1 * 2 + 1]] = [];
            }
            regionsObject[chartingPoints[index1 * 2]].push({
              start: this.feedDateArray[flagIndex - 1],
              end: this.feedDateArray[index - 1]
            });
            regionsObject[chartingPoints[index1 * 2 + 1]].push({
              start: this.feedDateArray[flagIndex - 1],
              end: this.feedDateArray[index - 1]
            });
            flag = false;
          }
        });
        if (flag === true) {
          if (regionsObject[chartingPoints[index1 * 2]] === undefined) {
            regionsObject[chartingPoints[index1 * 2]] = [];
            regionsObject[chartingPoints[index1 * 2 + 1]] = [];
          }
          regionsObject[chartingPoints[index1 * 2]].push({
            start: this.feedDateArray[flagIndex - 1]
          });
          regionsObject[chartingPoints[index1 * 2 + 1]].push({
            start: this.feedDateArray[flagIndex - 1]
          });
          flag = false;
        }
      });

      return regionsObject;
    },
    gradientGenerator(gradientId, strokeColor) {
      const gradient = d3
        .select(`#chart-${this.metadata.name} svg defs`)
        .append('linearGradient')
        .attr('id', `${gradientId}`)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '0%')
        .attr('y2', '100%');

      gradient
        .append('stop')
        .attr('offset', '60%')
        .attr('stop-color', strokeColor)
        .attr('stop-opacity', '0.5');

      gradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#fff')
        .attr('stop-opacity', '0');
    },
    metricGradientGenerator() {
      this.metricsList.forEach((metric, index) => {
        if (!metric.noGradient) {
          const gradient = this.gradientGenerator(
            `myGradient${index}${this.metadata.name}`,
            this.cardPrimaryMetricColorCode[metric.alias]
          );
        }
      });
    }
  }
};
</script>

<style></style>
