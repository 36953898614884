import entityConfigBuilder from '@/pages/toolsAndSettings/entityConfigBuilder.js';
import { Entities } from '@/pages/toolsAndSettings/const.js';

const readOnlyColumns = {
  amazon: [{ key: 'asin', altKey: 'asin', value: 'ASIN' }], // added 'altKey' to create columns for add new filter page. 'key' is used to create columns for editable dynamic table
  amazon_3p: [{ key: 'asin', altKey: 'asin', value: 'ASIN' }] // added 'altKey' to create columns for add new filter page. 'key' is used to create columns for editable dynamic table
};

const editableTableConfig = {
  amazon: {
    entityName: Entities.catalog,
    filterCubeName: 'client_workbench_full_catalogue_latest_comp_data',
    filterConfig: 'feature.filter_v2.enable'
  }
};
const config = {
  label: 'catalog',
  sheetName: 'Standard catalog',
  path: 'catalog/standard/tag',
  entity: 'catalog',
  altLabel: 'Standard Internal Catalog',
  entityName: 'SKU',
  primaryKey: 'asin',
  secondaryKey: 'asin',
  sectionDesc: `Use an existing template or update the available template to populate your Standard Internal Catalog`,
  columnConfigs: () => ({
    asin: (columnContext) => {
      return {
        widget: 'dynamicCellComponent',
        isFixed: 'left',
        dynamicCellComponentParams: {
          componentName: 'rb-sku',
          paramsToProps: (params) => {
            return {
              title: params.data.asinTitle,
              asin: params.data.asin,
              'image-url': params.data.asinImageURL,
              'show-image': true,
              'product-page-url': params.data.productURL,
              'enable-s-d-p': true
            };
          },
          eventMap: {
            navigateToSDP: 'navigateToSDP'
          }
        },
        showOnUi: true,
        isDownloadable: true,
        sortOnColumn: false,
        isDefaultSortColumn: false,
        width: 250
      };
    }
  }),
  readOnlyColumns,
  download: 'downloadExistingFile',
  editableTableConfig: (retailer) => editableTableConfig[retailer],
  infoText: 'will be your New Standard Catalog', // used in selectTaxonomy.vue
  selectTaxonomyLabel: 'Catalog',
  defaultColumnWidth: 250
};
const getDataAndComputedProps = (context, retailer) =>
  entityConfigBuilder({ config, context, retailer });

export default {
  config,
  getDataAndComputedProps
};
