import getSbManualConfig from '@/pages/entity_details/configs/walmart/sb_manual.js';
import getSpManualConfig from '@/pages/entity_details/configs/walmart/sp_manual.js';
import getSpAutoConfig from '@/pages/entity_details/configs/walmart/sp_auto.js';

const sb_manual = getSbManualConfig('walmart');
const sp_manual = getSpManualConfig('walmart');
const sp_auto = getSpAutoConfig('walmart');

export default {
  sb_manual,
  sp_manual,
  sp_auto
};
