<template>
  <div>
    <addEntity
      :widgets-config="config"
      :get-tag-desc="getTagDesc"
      :all-entities="allEntities"
      :subset-entities="subsetEntities"
    />
  </div>
</template>
<script>
import addEntity from '@/pages/toolsAndSettings/entityTagging/common/createCustomEntity/index.vue';
import widgetsCongig from './widgets';
export default {
  components: {
    addEntity
  },
  data() {
    return {
      config: null
    };
  },
  computed: {
    getTagDesc() {
      return this.$store.getters.getTagDescription;
    },
    allEntities() {
      return this.$store.getters.getAllEntities;
    },
    subsetEntities() {
      return this.$store.getters.getAllEntitiesForATag;
    }
  },
  created() {
    this.config = widgetsCongig;
  }
};
</script>
