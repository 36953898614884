import moment from 'moment-timezone';

export const ignoreAGValues = ['ag-grid-has-expand', 'ag-grid-total-levels'];

export const assignKeyInTempRow = (row, tempRowData) => {
  for (let key in tempRowData[row.name]) {
    row[key] = tempRowData[row.name][key];
  }
};

export const deleteKeysInRow = (row, ignoreAGValues) => {
  for (let key in row) {
    if (key.includes('ag-') && !ignoreAGValues.includes(key)) {
      delete row[key];
    }
  }
};

export const updateClonedPlan = (
  plan,
  tempRowData,
  ignoreAGValues,
  dropdownSelection
) => {
  for (let row of plan) {
    row.isExpanded = false;
    if (row.position === 0) {
      if (dropdownSelection[row.name]) {
        row.selectedLevelDropdown = dropdownSelection[row.name];
      }
    }
    deleteKeysInRow(row, ignoreAGValues);
    row.executionStrategy = [];
    if (tempRowData[row.name]) {
      assignKeyInTempRow(row, tempRowData);
    }
    if (row?.nextLevel?.length) {
      updateClonedPlan(
        row.nextLevel,
        tempRowData,
        ignoreAGValues,
        dropdownSelection
      );
    }
  }
};

export const convertExecutionStrategyToTempRow = (rows, tempRowData) => {
  for (let row of rows) {
    if (Array.isArray(row.executionStrategy)) {
      for (let strategy of row.executionStrategy) {
        const month = moment(strategy.startDate, 'YYYY-MM-DD')
          .format('MMM')
          .toLowerCase();
        if (!tempRowData[row.name]) {
          tempRowData[row.name] = {};
        }
        tempRowData[row.name][month] = strategy.budget;
      }
    }
    if (row.nextLevel?.length) {
      convertExecutionStrategyToTempRow(row.nextLevel, tempRowData);
    }
  }
};
