import MetadataService from './MetadataService';
import HttpService from '@/utils/services/http-service';
export default class RCAWidgetService {
  page = null;
  widget = null;
  metadata = null;
  pageId = null;
  globalViewId = null;
  data = null;
  loading = false;
  error = false;
  readApiConfig = null;
  rcaKey = null;
  selectedMetric = null;
  formattedRCAData = null;
  constructor(widgetConfig) {
    this.globalViewId = widgetConfig.globalViewId;
    this.pageId = widgetConfig.pageId;
    this.readApiConfig = widgetConfig.api;
    this.metadata = new MetadataService({
      globalViewId: this.globalViewId,
      pageId: this.pageId,
      api: this.readApiConfig
    });
  }

  async fetchData() {
    try {
      this.loading = true;
      if (!this.rcaKey) {
        return;
      }
      const metricDetails = this.metadata.data.metrics.rca_graph_json;
      const { api } = metricDetails;
      const { request } = api;
      request.metricsList = [metricDetails.name];
      request.where.dimensionNameValueList = [
        {
          dimensionName: 'rca_metric_name_dimension',
          dimensionValue: this.rcaKey
        }
      ];
      request.where.date = {
        from: '2022-12-10',
        to: '2022-12-17',
        name: 'lastWeek'
      };
      request.where.pvpDate = {
        from: '2022-11-20',
        to: '2022-11-26',
        compare_name: null
      };
      this.data = await HttpService.post(
        api.service,
        { ...request },
        { append: api.endPoint }
      );
      this.formatRCAData();
    } catch (err) {
      this.error = false;
    } finally {
      this.loading = false;
    }
  }

  formatRCAData() {
    const metricData = this.data.data.entityData[0].data.reduce(
      (prevVal, curVal) => {
        prevVal = curVal.RESULT?.[curVal?.name];
        return prevVal;
      },
      {}
    );
    this.formattedRCAData = JSON.parse(metricData || {});
  }
}
