import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep } from 'lodash';
import activitytransformer from '@/utils/services/activity-data-transformer';
import transformer from '@/utils/services/data-transformer';
import moment from 'moment-timezone';

const state = {
  budgetPlanTitle: 'Untitled Budget Plan',
  budgetPlanDesc: null,
  saveBudgetPlanStatus: 'not_triggered', // ['not_triggered', 'loading', 'success', 'failed']
  budgetPlanFileUploadError: '',
  visitedBudgetPlanSteps: [],
  budgetPlanFileDetails: {},
  budgetPlanFileUploadStatus: 'not_started', // ['not_started', 'started', 'complete', 'incomplete', 'error', 'icorrect_template']
  budgetPlanEntitiesArray: [],
  budgetPlanEntireBusiness: [],
  budgetPlanProfiles: [],
  budgetPlanPortfolios: [],
  previewTableProfileData: {},
  previewTablePortfolioData: {},
  previewCurrentActiveEntity: 'ENTIRE_BUSINESS',
  previewCurrentMonthlyDistribution: null,
  previewQuarterlyFormatData: null,
  previewCurrentQuarterData: null,
  currentMonthAmsSpendHash: {},
  budgetPlanPacingChartData: null,
  budgetPlanSeasonalityEvents: [],
  saveBudgetPlanPayload: null,
  budgetPlannerStrategyList: [],
  showCumulativeGraphLoader: false,
  budgetPlanActivitylogData: {},
  filePath: null,
  budgetPlanAbsolutePacingChartData: [],
  isBudgetPlanModified: false,
  isBudgetPlanArchived: false,
  plannedBudgetMTD: {},
  lastUploadedBudgetPlan: null
};

const getters = {
  getBudgetPlanTitle: (state) => state.budgetPlanTitle,
  getLastUploadedBudgetPlan: (state) => state.lastUploadedBudgetPlan,
  getIsBudgetPlanModifiedFlag: (state) => state.isBudgetPlanModified,
  getBudgetPlanDesc: (state) => state.budgetPlanDesc,
  getBudgetPlannerStrategies: (state) => state.budgetPlannerStrategyList,
  getIsBudgetPlanArchived: (state) => state.isBudgetPlanArchived,
  getSaveBudgetPlanStatus: (state) => state.saveBudgetPlanStatus,
  getBudgetPlanFileUploadStatus: (state) => state.budgetPlanFileUploadStatus,
  getBudgetPlanFileUploadError: (state) => state.budgetPlanFileUploadError,
  getBudgetPlannerSavePayload: (state) => state.saveBudgetPlanPayload,
  getBudgetPlannerStrategyEntities: (state) => {
    return (
      state.saveBudgetPlanPayload &&
      state.saveBudgetPlanPayload.budgetPlan &&
      state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate
        .entities
    );
  },
  getVisitedBudgetPlanSteps: (state) => state.visitedBudgetPlanSteps,
  getPreviewTableEntireBusiness: (state) => state.budgetPlanEntireBusiness,
  getBudgetPlanProfiles: (state) => state.budgetPlanProfiles,
  getBudgetPlanPortfolios: (state) => state.budgetPlanPortfolios,
  getPreviewTableProfiles: (state) => state.previewTableProfileData,
  getPreviewTablePortfolios: (state) => state.previewTablePortfolioData,
  getCurrentActiveEntity: (state) => state.previewCurrentActiveEntity,
  getCurrentQuarterData: (state) => state.previewCurrentQuarterData,
  getQuarterlyFormattedData: (state) => state.previewQuarterlyFormatData,
  getCumulativePacingChartData: (state) => state.budgetPlanPacingChartData,
  getAbsolutePacingChartData: (state) =>
    state.budgetPlanAbsolutePacingChartData,
  getSeasonalityEventsEntities: (state) => state.budgetPlanSeasonalityEvents,
  getBudgetActivityLog: (state) => state.budgetPlanActivitylogData,
  getChartDataLoader: (state) => state.showCumulativeGraphLoader,
  getBudgetPlanFileDetails: (state) => state.budgetPlanFileDetails,
  getCurrentMonthAmsSpend: (state) => {
    const validProfiles = state.budgetPlanProfiles.filter(
      (entity) => entity.executionScope.finalBudgetPlan > 0
    );
    const validPortfolios = state.budgetPlanPortfolios.filter(
      (entity) =>
        entity.executionScope.finalBudgetPlan > 0 &&
        entity.executionScope.businessScopeId.toLowerCase() !== 'rest'
    );
    let entireBusinessSpend = 0;
    Object.keys(state.currentMonthAmsSpendHash).forEach((entityId) => {
      if (
        validProfiles.findIndex(
          (profile) => entityId === profile.executionScope.businessScopeId
        ) === -1 &&
        validPortfolios.findIndex(
          (portfolio) => entityId === portfolio.executionScope.businessScopeId
        ) === -1
      ) {
        delete state.currentMonthAmsSpendHash[entityId];
      }
      if (
        validProfiles.findIndex(
          (profile) => entityId === profile.executionScope.businessScopeId
        ) > -1
      ) {
        entireBusinessSpend += state.currentMonthAmsSpendHash[entityId]
          ? state.currentMonthAmsSpendHash[entityId]
          : 0;
      }
    });
    state.currentMonthAmsSpendHash.ENTIRE_BUSINESS =
      state?.plannedBudgetMTD?.['00'] || entireBusinessSpend;
    for (const item in state.plannedBudgetMTD) {
      if (state.plannedBudgetMTD?.[item] > 0) {
        // override spend values if they are greater than 0
        state.currentMonthAmsSpendHash[item] = state.plannedBudgetMTD?.[item];
      }
    }
    return state.currentMonthAmsSpendHash;
  }
};

const mutations = {
  SET_MODIFIED_BUDGET_PLAN_FLAG: (state, value) => {
    state.isBudgetPlanModified = value;
  },
  SET_PLANNED_BUDGET_MTD: (state, value) => {
    state.plannedBudgetMTD = value;
  },
  SET_LASTUPLOAD_TIME: (state, strategies) => {
    state.lastUploadedBudgetPlan =
      strategies?.[0]?.actions?.[0]?.executionTemplate?.lastUploadTime || null;
  },
  RESET_BUDGET_PLAN: (state) => {
    // will revisit code to reduce state items to reset
    state.budgetPlanTitle = 'Untitled Budget Plan';
    state.saveBudgetPlanStatus = 'not_triggered';
    state.budgetPlanDesc = null;
    state.budgetPlanFileUploadError = '';
    state.visitedBudgetPlanSteps = [];
    state.budgetPlanFileDetails = {};
    state.budgetPlanFileUploadStatus = 'not_started';
    state.saveBudgetPlanPayload = null;
    state.filePath = null;
    state.isBudgetPlanModified = false;
  },
  SET_BUDGET_PLAN_TITLE: (state, value) => {
    state.budgetPlanTitle = value;
  },
  SET_BUDGET_PLAN_DESCRIPTION: (state, value) => {
    state.budgetPlanDesc = value;
  },
  ARCHIVED_BUDGET_PLAN_STRATEGIES: (state, data) => {
    state.isBudgetPlanArchived = data;
  },
  SAVE_BUDGET_PLANNER_STRATEGIES: (state, data) => {
    state.budgetPlannerStrategyList = data;
  },
  VISITED_BUDGET_PLAN_STEPS: (state, data) => {
    state.visitedBudgetPlanSteps = data;
  },
  BUDGET_PLAN_FILE_DETAILS: (state, data) => {
    state.budgetPlanFileDetails = data;
  },
  BUDGET_PLAN_FILE_UPLOAD_STATUS: (state, uploadState) => {
    state.budgetPlanFileUploadStatus = uploadState;
  },
  BUDGET_PLAN_FILE_UPLOAD_ERROR: (state, data) => {
    state.budgetPlanFileUploadError = data;
  },
  BUDGET_PLAN_FILE_PATH: (state, data) => {
    state.filePath = data;
  },
  BUDGET_PLANNER_DASHBOARD_TABLE: (state, budgetPlannerDashboardTable) => {
    state.budgetPlannerDashboardTable = budgetPlannerDashboardTable;
  },
  SET_BUDGET_PLAN_ENTITIES: (state, data) => {
    const profiles = [];
    const portfolios = [];
    const entireBusiness = [];
    let seasonalityEvents = [];
    if (!state.saveBudgetPlanPayload) {
      state.saveBudgetPlanPayload = data.rows;
    } else {
      /* modifying entities array while reuploading the strategy & storing the orchestrator */
      state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate.entities =
        cloneDeep(data.rows.budgetPlan.actions[0].executionTemplate.entities);
      state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate.lastUploadTime =
        cloneDeep(
          data.rows.budgetPlan.actions[0].executionTemplate.lastUploadTime
        );
      state.saveBudgetPlanPayload.orchestrator = cloneDeep(
        data.rows.orchestrator
      );
    }
    if (
      state.saveBudgetPlanPayload.budgetPlan &&
      state.saveBudgetPlanPayload.budgetPlan.actions &&
      state.saveBudgetPlanPayload.budgetPlan.actions[0] &&
      state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate
    ) {
      state.budgetPlanEntitiesArray = cloneDeep(
        state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate
          .entities
      );
      state.budgetPlanEntitiesArray = addChildRowCount(
        state.budgetPlanEntitiesArray
      );
      state.budgetPlanEntitiesArray = addPercentageTextForHover(
        state.budgetPlanEntitiesArray
      );
      state.budgetPlanEntitiesArray.forEach((entity) => {
        const entityExecScope = entity.executionScope;
        const tempObj = {
          executionScope: entityExecScope
        };
        if (entityExecScope.businessScope === 'PROFILE') {
          profiles.push(tempObj);
        } else if (entityExecScope.businessScope === 'PORTFOLIO') {
          portfolios.push(tempObj);
        } else if (entityExecScope.businessScope === 'ENTIRE_BUSINESS') {
          entireBusiness.push(tempObj);
        }
        const currentScopeEvents = entity.executionStrategies
          .filter((item) => item.type === 'SEASONALITY')
          .map((item) => {
            const month = moment(item.start).format('MMM');
            const keyToSelect =
              entityExecScope.businessScope === 'ENTIRE_BUSINESS' ||
              entityExecScope.businessScope === 'PROFILE'
                ? 'value'
                : 'absoluteMonthlyBudget';
            const monthlyBudget = entity.executionStrategies.find(
              (item) =>
                item.type === 'PACING' &&
                item.label.toUpperCase() === month.toUpperCase()
            )[keyToSelect];
            return {
              ...item,
              isValid: true,
              isSaved: true,
              titleInvalid: false,
              budgetValueInvalid: false,
              calculatedAbsAmount:
                item.valueType === 'PERCENTAGE'
                  ? (item.value / 100) * monthlyBudget
                  : item.value,
              selectedScope: {
                dimensionName: entityExecScope.businessScope,
                dimensionValue:
                  entityExecScope.businessScope === 'ENTIRE_BUSINESS'
                    ? 'ENTIRE_BUSINESS'
                    : entityExecScope.businessScopeId,
                relatedEntityId:
                  entityExecScope.businessScope === 'PROFILE'
                    ? 'ENTIRE_BUSINESS'
                    : entityExecScope.relatedBusinessScopeId,
                relatedEntityScope: entityExecScope.relatedBusinessScope,
                title: entityExecScope.businessScopeName
              }
            };
          });
        if (currentScopeEvents.length) {
          seasonalityEvents.push(...currentScopeEvents);
        }
      });
      state.budgetPlanEntireBusiness = entireBusiness;
      state.budgetPlanProfiles = profiles.sort(sortByFinalBudgetPlan);
      state.budgetPlanPortfolios = portfolios.sort(sortByFinalBudgetPlan);
      if (
        seasonalityEvents.length === 0 &&
        state.budgetPlanSeasonalityEvents.length > 0
      ) {
        seasonalityEvents = state.budgetPlanSeasonalityEvents;
      }
      state.budgetPlanSeasonalityEvents = seasonalityEvents;
    }
  },
  SET_CUMULATIVE_CURVE_DATA: (state, data) => {
    state.budgetPlanPacingChartData = data;
  },
  SET_ABSOLUTE_CURVE_DATA: (state, data) => {
    state.budgetPlanAbsolutePacingChartData = data;
  },
  SHOW_CUMULATIVE_CURVE_LOADER: (state) => {
    state.showCumulativeGraphLoader = true;
  },
  HIDE_CUMULATIVE_CURVE_LOADER: (state) => {
    state.showCumulativeGraphLoader = false;
  },
  SET_BUDGET_ON_SAVE_STATUS: (state, data) => {
    state.saveBudgetPlanStatus = data;
  },
  SET_BUDGET_PLAN_SAVE_PAYLOAD: (state, data) => {
    state.saveBudgetPlanPayload.budgetPlan = Object.assign(
      {},
      state.saveBudgetPlanPayload.budgetPlan,
      data.budgetPlan
    );
    state.saveBudgetPlanPayload.whereClause = Object.assign(
      {},
      state.saveBudgetPlanPayload.whereClause,
      data.whereClause
    );
  },
  SET_PREVIEW_MASTER_TABLE_LEVEL_DATA: (state, data) => {
    const levelName = data.levelKey;
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  },
  SET_PREVIEW_ACTIVE_ENTITY: (state, entityId) => {
    state.previewCurrentActiveEntity = entityId;
  },
  SET_PREVIEW_ACTIVE_MONTHLY_DISTRIBUTION: (state, monthlyData) => {
    state.previewCurrentMonthlyDistribution = monthlyData;
  },
  SET_PREVIEW_QUARTERLY_FORMATTED_DATA: (state, quarterlyData) => {
    state.previewQuarterlyFormatData = quarterlyData;
  },
  SET_PREVIEW_SLAVE_TABLE_LEVEL_DATA: (state, data) => {
    const levelName = data.levelKey;
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  },
  CLEAR_PREVIEW_SLAVE_TABLE_LEVEL_DATA: (state) => {
    Vue.set(state, 'previewCurrentMonthlyDistribution', []);
    Vue.set(state, 'previewQuarterlyFormatData', []);
    Vue.set(state, 'previewCurrentQuarterData', []);
  },
  BUDGET_PLAN_ACTIVITY_LOG_SUCCESS: (state, data) => {
    Vue.set(state.budgetPlanActivitylogData, 'rows', data.rows);
    Vue.set(state.budgetPlanActivitylogData, 'load', false);
    Vue.set(state.budgetPlanActivitylogData, 'error', false);
  },
  BUDGET_PLAN_ACTIVITY_LOG_ERROR: (state) => {
    Vue.set(state.budgetPlanActivitylogData, 'rows', []);
    Vue.set(state.budgetPlanActivitylogData, 'load', false);
    Vue.set(state.budgetPlanActivitylogData, 'error', true);
  },
  BUDGET_PLAN_ACTIVITY_LOG_RESET: (state) => {
    Vue.set(state.budgetPlanActivitylogData, 'rows', []);
    Vue.set(state.budgetPlanActivitylogData, 'load', true);
    Vue.set(state.budgetPlanActivitylogData, 'error', false);
  },
  UPDATE_SEASONALITY_EVENTS_IN_STORE: (state, seasonalityEvents) => {
    state.budgetPlanSeasonalityEvents = [...seasonalityEvents];
    state.isBudgetPlanModified = true;
  },
  SET_SEASONALITY_EVENTS_TO_SAVE_PAYLOAD: (state) => {
    const budgetPlanSeasonalityEvents = cloneDeep(
      state.budgetPlanSeasonalityEvents
    );
    // distribute the seasonality events to entities array before saving the plan
    state.budgetPlanEntitiesArray.forEach((entity) => {
      entity.executionStrategies = entity.executionStrategies.filter(
        (entry) => entry.type === 'PACING'
      );
    });
    if (budgetPlanSeasonalityEvents.length) {
      budgetPlanSeasonalityEvents.forEach((seasonalityEvent) => {
        const relevantScopeObj = state.budgetPlanEntitiesArray.find(
          (entity) => {
            const entityScope = entity.executionScope.businessScope;
            const entityScopeId = entity.executionScope.businessScopeId;
            return entityScope === 'ENTIRE_BUSINESS'
              ? entityScope === seasonalityEvent.selectedScope.dimensionValue
              : entityScopeId === seasonalityEvent.selectedScope.dimensionValue;
          }
        );
        if (relevantScopeObj) {
          const eventIndex = relevantScopeObj.executionStrategies.findIndex(
            (item) => item.eventId === seasonalityEvent.eventId
          );
          delete seasonalityEvent.selectedScope;
          delete seasonalityEvent.isValid;
          delete seasonalityEvent.isSaved;
          delete seasonalityEvent.titleInvalid;
          delete seasonalityEvent.budgetValueInvalid;
          delete seasonalityEvent.calculatedAbsAmount;
          if (eventIndex > -1) {
            relevantScopeObj.executionStrategies[eventIndex] = seasonalityEvent;
          } else {
            relevantScopeObj.executionStrategies.push(seasonalityEvent);
          }
        }
      });
    }
    state.saveBudgetPlanPayload.budgetPlan.actions[0].executionTemplate.entities =
      state.budgetPlanEntitiesArray;
  }
};

const actions = {
  resetBudgetPlan: (context) => {
    context.commit('RESET_BUDGET_PLAN');
  },
  resetVisitedSteps: (context, steps) => {
    context.commit('VISITED_BUDGET_PLAN_STEPS', steps);
  },
  setVisitedSteps: (context, data) => {
    const steps = context.state.visitedBudgetPlanSteps;
    let isVisited = false;
    steps.forEach((item) => {
      if (item === data) {
        isVisited = true;
      }
    });
    if (!isVisited) {
      steps.push(data);
    }
    context.commit('VISITED_BUDGET_PLAN_STEPS', steps);
  },
  fetchBudgetPlanNextYearStrategies: (context, nextYear) => {
    context.commit('SHOW_BP_DASHBOARD_LOADER');
    context.commit('SAVE_BUDGET_PLANNER_STRATEGIES', []);
    context.commit('RESET_BUDGET_PLAN');
    context.commit('VISITED_BUDGET_PLAN_STEPS', []);
    context.commit('CREATE_PLAN_ACTIVE_SELECTION', 0);
    context.commit('HIDE_BP_DASHBOARD_LOADER');
    context.commit('UPDATE_SEASONALITY_EVENTS_IN_STORE', []);
    context.commit('SET_CUMULATIVE_CURVE_DATA', null);
    context.dispatch('fetchBudgetPlanStrategies', nextYear);
  },
  fetchBudgetPlanStrategies: async (context, nextYear) => {
    context.commit('SHOW_BP_DASHBOARD_LOADER');
    try {
      let config = (config = {
        append: `?year=${nextYear}`
      });
      const response = nextYear
        ? await HttpService.get('ORCHESTRATOR_NEXT_YEAR_PLAN', config)
        : await HttpService.get('GET_BUDGET_PLANNER_STRATEGIES');
      const acrivedStrategies = nextYear
        ? { data: [] }
        : await HttpService.get('GET_BUDGET_PLANNER_ARCHIVED_STRATEGIES');
      context.commit('SAVE_BUDGET_PLANNER_STRATEGIES', response.data);
      context.commit('SET_LASTUPLOAD_TIME', response.data);
      context.commit(
        'ARCHIVED_BUDGET_PLAN_STRATEGIES',
        !!acrivedStrategies?.data?.length
      );
      if (response.data.length > 0) {
        setEditFlowDetails(context, response.data[0], nextYear);
      }
    } catch (err) {
      context.commit('SET_BP_DASHBOARD_ERROR', {
        error: err,
        errorMessage: 'API failed. Cannot get list of strategies.'
      });
    } finally {
      context.commit('HIDE_BP_DASHBOARD_LOADER');
    }
  },
  setBudgetPlanFileUploadStatus: (context, uploadState) => {
    const validStatus = [
      'started',
      'not_started',
      'complete',
      'incomplete',
      'error',
      'icorrect_template'
    ];
    if (validStatus.includes(uploadState)) {
      context.commit('BUDGET_PLAN_FILE_UPLOAD_STATUS', uploadState);
    }
  },
  setBudgetPlanFileDetails: (context, payload) => {
    context.commit('BUDGET_PLAN_FILE_DETAILS', payload);
  },
  uploadBudgetPlanFile: async (context, payload) => {
    const { formData, year } = payload;
    context.commit('BUDGET_PLAN_FILE_UPLOAD_STATUS', 'started');
    // defaulting to error status if the api doesnt respond as expected.
    let uploadStatus = 'error';
    try {
      const response = await HttpService.post(
        'ORCHESTRATOR_UPLOAD_EXCEL',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          append: '?year=' + year
        }
      );
      if (response && response.status === 200 && response.data) {
        uploadStatus = 'complete';
        if (context.state.budgetPlannerStrategyList.length > 0) {
          response.data.response.rows.orchestrator.isEditMode = true;
        }
        const filePath =
          response.data.response &&
          response.data.response.rows &&
          response.data.response.rows.orchestrator &&
          response.data.response.rows.orchestrator.tempFilePath;
        context.commit('BUDGET_PLAN_FILE_PATH', filePath);
        const name = context.state.budgetPlanFileDetails.name;
        context.commit('BUDGET_PLAN_FILE_DETAILS', {
          name,
          time: `${moment().format('lll')} ${moment.tz.guess()}`
        });
        context.commit('SET_BUDGET_PLAN_ENTITIES', response.data.response);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        // incomplete data in excel sheet
        uploadStatus = 'incomplete';
        if (error.response.data.issues && error.response.data.errorCount) {
          const filePath = error.response.data.filePath;
          const errorLength = error.response.data.errorCount;
          const message = `${errorLength} error${
            errorLength > 1 ? 's' : ''
          } found.`;
          context.commit('BUDGET_PLAN_FILE_PATH', filePath);
          context.commit('BUDGET_PLAN_FILE_UPLOAD_ERROR', message);
        }
      } else if (error && error.response && error.response.status === 422) {
        uploadStatus = 'icorrect_template';
        context.commit(
          'BUDGET_PLAN_FILE_UPLOAD_ERROR',
          'Template is modified or incorrect template used.'
        );
      } else {
        uploadStatus = 'error';
        const responseErrors = {
          500: 'Oops something went wrong. Please try again'
        };
        context.commit(
          'BUDGET_PLAN_FILE_UPLOAD_ERROR',
          responseErrors[error.response.status] || 'Oops something went wrong.'
        );
      }
    } finally {
      if (context.state.budgetPlanFileUploadStatus === 'started') {
        context.commit('BUDGET_PLAN_FILE_UPLOAD_STATUS', uploadStatus);
        context.commit('SET_MODIFIED_BUDGET_PLAN_FLAG', true);
      }
    }
  },
  downloadBudgetPlanExcel: async (context, payload) => {
    const response = await HttpService.post(
      'ORCHESTRATOR_DOWNLOAD_EXCEL',
      payload,
      { responseType: 'blob' }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const disposition =
      response.headers && response.headers['content-disposition'];
    let fileName = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    } else {
      fileName = payload.downloadNewFile
        ? 'BudgetPlannerTemplate.xlsx'
        : 'BudgetPlan' + payload.year + '.xlsx';
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  downloadBudgetPlanFile: async (context) => {
    let fileName =
      context.state.budgetPlanFileDetails &&
      context.state.budgetPlanFileDetails.name;
    const filePath = context.state.filePath;
    const response = await HttpService.get('ORCHESTRATOR_DOWNLOAD_FILE', {
      responseType: 'blob',
      append: '?filePath=' + filePath
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const disposition =
      response.headers && response.headers['content-disposition'];
    if (!fileName) {
      // use filename from downloaded file
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      } else {
        //  default file name
        fileName = 'BudgetPlan2020.xlsx';
      }
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    return response;
  },
  setPreviewTableProfiles: async (context, data) => {
    const profileArray = context.state.budgetPlanProfiles;
    data.rows = await profileArray.map((profileEntity) => {
      return {
        businessScope: profileEntity.executionScope.businessScope,
        businessScopeName: profileEntity.executionScope.businessScopeName,
        businessScopeId: profileEntity.executionScope.businessScopeId,
        estimatedPcogs: profileEntity.executionScope.estimatedPcogs,
        finalBudgetPlan: profileEntity.executionScope.finalBudgetPlan,
        percentageText: profileEntity.executionScope.percentageText,
        'ag-grid-has-expand': profileEntity.executionScope['ag-grid-has-expand']
      };
    });
    data.levelKey = 'previewTableProfileData';
    context.commit('SET_PREVIEW_MASTER_TABLE_LEVEL_DATA', data);
  },
  setPreviewTablePortfolios: (context, data) => {
    const portfolioArray = context.state.budgetPlanPortfolios;
    data.rows = portfolioArray
      .filter((portfolioEntity) => {
        return (
          portfolioEntity.executionScope.relatedBusinessScopeId ===
          data.row.businessScopeId
        );
      })
      .map((portfolioEntity) => {
        const tempObj = portfolioEntity.executionScope;
        return {
          businessScope: tempObj.businessScope,
          businessScopeName: tempObj.businessScopeName,
          businessScopeId: tempObj.businessScopeId,
          finalBudgetPlan: tempObj.finalBudgetPlan,
          percentageText: tempObj.percentageText
        };
      });
    data.levelKey = 'previewTablePortfolioData';
    context.commit('SET_PREVIEW_MASTER_TABLE_LEVEL_DATA', data);
  },
  setCurrentQuarterData: (context, data) => {
    data.levelKey = 'previewCurrentQuarterData';
    const currentQuarter = data.row.quarterNo;
    const monthlyData = context.state.previewCurrentMonthlyDistribution;
    data.rows = monthlyData.filter((item) => {
      const monthNo = moment(item.start).format('M');
      if (currentQuarter === 1) {
        return monthNo >= 1 && monthNo <= 3;
      } else if (currentQuarter === 2) {
        return monthNo >= 4 && monthNo <= 6;
      } else if (currentQuarter === 3) {
        return monthNo >= 7 && monthNo <= 9;
      } else if (currentQuarter === 4) {
        return monthNo >= 10 && monthNo <= 12;
      }
    });
    context.commit('SET_PREVIEW_SLAVE_TABLE_LEVEL_DATA', data);
  },
  fetchCurrentMonthBudget: (context) => {
    const requestData = {
      cubeName: 'budget_plan_till_current_date',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: ['mtd_planned_budget', 'qtd_planned_budget'],
      groupByDimensionsList: [
        'entity_id',
        'mtd_planned_budget',
        'qtd_planned_budget'
      ],
      customAPIDecisionVars: {
        system: 'budget_optimizer'
      },
      orderByList: [],
      where: {}
    };

    HttpLayer.post({
      APIData: requestData
    })
      .then((response) => {
        const mergeResultObj = {};
        const mergeResult =
          transformer.mergeResultDimension(response.data) || [];
        for (const item of mergeResult) {
          mergeResultObj[item.entity_id] = item.mtd_planned_budget;
        }
        context.commit('SET_PLANNED_BUDGET_MTD', mergeResultObj);
      })
      .catch(() => {
        context.commit('SET_PLANNED_BUDGET_MTD', {});
      });
  },
  fetchCurrentMonthBudgetSpend: (context) => {
    const profileRequest = {
      cubeName: 'actual_spend_details_for_a_year',
      measuresList: ['actual_spend'],
      groupByDimensionsList: ['profile_id'],
      where: {
        date: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().endOf('month').format('YYYY-MM-DD')
        }
      }
    };

    // fetch all profiles current monthly spend
    HttpLayer.post({
      APIData: profileRequest
    }).then((response) => {
      const _mergeResult =
        transformer.mergeResultDimension(response.data) || [];
      _mergeResult.forEach((item) => {
        if (item.profile_id) {
          context.state.currentMonthAmsSpendHash[item.profile_id.toString()] =
            item.actual_spend;
        }
      });
    });

    // fetch all portfolios current monthly spend
    const portfolioRequest = cloneDeep(profileRequest);
    portfolioRequest.groupByDimensionsList = ['portfolio_id'];
    HttpLayer.post({
      APIData: portfolioRequest
    }).then((response) => {
      const _mergeResult =
        transformer.mergeResultDimension(response.data) || [];
      _mergeResult.forEach((item) => {
        if (item.portfolio_id) {
          context.state.currentMonthAmsSpendHash[item.portfolio_id.toString()] =
            item.actual_spend;
        }
      });
    });
  },
  setSeasonalityToSavePayload(context) {
    context.commit('SET_SEASONALITY_EVENTS_TO_SAVE_PAYLOAD');
  },
  getBudgetPacingGraphData: async (context, data = {}) => {
    context.commit('SHOW_CUMULATIVE_CURVE_LOADER');
    context.commit('SET_SEASONALITY_EVENTS_TO_SAVE_PAYLOAD');
    const requestPayload = {
      where: {
        dimensionNameValueList: [],
        date: {
          from: moment().startOf('year').format('YYYY-MM-DD'),
          to: moment().endOf('year').format('YYYY-MM-DD')
        }
      },
      entities: context.state.budgetPlanEntitiesArray
    };
    if (data.rollUp) {
      requestPayload.rollUp = data.rollUp;
    }
    if (data.dimensionNameValueList) {
      requestPayload.where.dimensionNameValueList = data.dimensionNameValueList;
    }
    try {
      // console.log(requestPayload.entities)
      const response = await HttpService.post(
        'BUDGET_PLAN_DS_CURVE',
        requestPayload
      );
      if (response && response.status === 200) {
        const transformedData = transformApiResponseToChartData(response.data);
        context.commit('SET_CUMULATIVE_CURVE_DATA', transformedData.cumulative);
        context.commit('SET_ABSOLUTE_CURVE_DATA', transformedData.absolute);
      }
    } catch (error) {
      console.log(error);
    } finally {
      context.commit('HIDE_CUMULATIVE_CURVE_LOADER');
    }
  },
  setBudgetPlanSavePayload: (context, data) => {
    context.commit('SET_BUDGET_PLAN_SAVE_PAYLOAD', data);
  },
  saveBudgetPlanStrategy: async (context, savePayload) => {
    let saveBudgetPlanStatus = 'loading';
    context.commit('SET_BUDGET_ON_SAVE_STATUS', saveBudgetPlanStatus);
    try {
      let isNextYear = false;
      if (savePayload?.nextYearPlan) {
        isNextYear = true;
        delete savePayload.nextYearPlan;
      }
      const response = !isNextYear
        ? await HttpService.post('ORCHESTRATOR_SAVE_STRATEGY', savePayload)
        : await HttpService.post('ORCHESTRATOR_NEXT_YEAR_PLAN', savePayload);
      if (response && response.status === 200) {
        saveBudgetPlanStatus = 'success';
      } else {
        saveBudgetPlanStatus = 'failed';
      }
    } catch (error) {
      saveBudgetPlanStatus = 'failed';
    } finally {
      context.commit('SET_BUDGET_ON_SAVE_STATUS', saveBudgetPlanStatus);
    }
  },
  getBudgetActivityLog: (context) => {
    context.commit('BUDGET_PLAN_ACTIVITY_LOG_RESET');
    HttpService.get('BUDGET_OPTIMIZER_HISTORY', {
      append: '?limit=1000&offset=0'
    })
      .then((response) => {
        if (response.data && !response.data.success) {
          context.commit('BUDGET_PLAN_ACTIVITY_LOG_ERROR', {
            rows: []
          });
        } else {
          context.commit('BUDGET_PLAN_ACTIVITY_LOG_SUCCESS', {
            rows: activitytransformer.getActivityData(
              formatBudgetActivityData(response.data.response.rows),
              'createdAt'
            )
          });
        }
      })
      .catch(() => {
        context.commit('BUDGET_PLAN_ACTIVITY_LOG_ERROR', {
          rows: []
        });
      });
  }
};

const sortByFinalBudgetPlan = (a, b) => {
  const afinalBudgetPlan = a.executionScope.finalBudgetPlan;
  const bfinalBudgetPlan = b.executionScope.finalBudgetPlan;
  let comparison = 0;
  if (afinalBudgetPlan > bfinalBudgetPlan) {
    comparison = -1;
  } else if (afinalBudgetPlan < bfinalBudgetPlan) {
    comparison = 1;
  }
  return comparison;
};

const getDisplayValue = (display, displayType) => {
  if (displayType === 'currency') {
    return Vue.options.filters.num_format(display, 'currency');
  } else if (displayType === 'percentage') {
    return Vue.options.filters.num_format(display, undefined, '%');
  }
  return Vue.options.filters.num_format(display);
};

const getLabel = (data) => {
  let value;
  value = getDisplayValue(data.value, getValueTypeLabel(data.valueType));
  if (data?.eventInfo?.description) {
    value += ` ${data.eventInfo.description}`;
  }
  return value;
};

const getValueTypeLabel = (valuetype) => {
  const map = {
    ABSOLUTE: 'currency',
    PERCENTAGE: 'percentage'
  };
  return map[valuetype];
};

const capitalize = (s) => {
  s = s.toLowerCase();
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const setEditFlowDetails = (context, data, nextYear) => {
  context.commit('SET_BUDGET_PLAN_TITLE', data.strategyName);
  context.commit('SET_BUDGET_PLAN_DESCRIPTION', data.description);
  const executionTemplate =
    data &&
    data.actions &&
    data.actions[0] &&
    data.actions[0].executionTemplate;
  context.commit('BUDGET_PLAN_FILE_UPLOAD_STATUS', 'complete');
  context.commit('BUDGET_PLAN_FILE_PATH', executionTemplate.storageLink);
  const name = executionTemplate.userFilename || 'BudgetPlan.xlsx';
  context.commit('BUDGET_PLAN_FILE_DETAILS', {
    name: name,
    time: `${moment(executionTemplate.lastUploadTime).format(
      'lll'
    )} ${moment.tz.guess()}`
  });
  const budgetPlan = {
    rows: {
      budgetPlan: data,
      orchestrator: {
        s3Path: executionTemplate.storageLink,
        year: nextYear || executionTemplate.year,
        isEditMode: true
      }
    }
  };
  context.commit('SET_BUDGET_PLAN_ENTITIES', budgetPlan);
};

const transformApiResponseToChartData = (data) => {
  const innerObj = data[0];
  const feedDateArr = ['feed_date'];
  const cumulativeChartDataArr = ['Budget'];
  const absoluteChartDataArr = ['Budget'];
  if (!innerObj) {
    return false;
  }
  const timeSeriesArr = innerObj.timeSeries;
  timeSeriesArr.forEach((element) => {
    feedDateArr.push(element.feed_date);
    cumulativeChartDataArr.push(element.plannedBudget);
    absoluteChartDataArr.push(element.absoluteBudget);
  });
  return {
    cumulative: [feedDateArr, cumulativeChartDataArr],
    absolute: [feedDateArr, absoluteChartDataArr]
  };
};

const formatBudgetActivityData = (rows) => {
  const _rows = [];
  for (let i = 0; i < rows.length; i++) {
    const obj = {
      actionStatus: 'successful',
      ...rows[i]
    };
    if (
      obj.actionType === 'VALUE_ADDED' ||
      obj.actionType === 'VALUE_REMOVED'
    ) {
      obj.viewPayload.value = getLabel(obj.viewPayload);
      if (obj.viewPayload.entityType === 'ENTIRE_BUSINESS') {
        obj.viewPayload.entityName = 'Entire Business';
      } else {
        obj.viewPayload.entityName =
          capitalize(obj.viewPayload.entityType) +
          ' - ' +
          obj.viewPayload.entityName;
      }
    }
    obj.viewPayload.timeperiod =
      moment(obj.viewPayload.start).format('MMM D') +
      ' - ' +
      moment(obj.viewPayload.end).format('MMM D, YYYY');
    _rows.push(obj);
  }
  return _rows;
};

const addChildRowCount = (tableData) => {
  const relatedMap = {};
  tableData.map((row, index) => {
    row.executionScope['ag-grid-has-expand'] = false;
    if (!relatedMap[row.executionScope.relatedBusinessScopeId]) {
      relatedMap[row.executionScope.relatedBusinessScopeId] = {
        count: 0
      };
    }
    relatedMap[row.executionScope.relatedBusinessScopeId].count++;
  });
  for (const relatedEntity in relatedMap) {
    const index = tableData.findIndex((item) => {
      return relatedEntity === item.executionScope.businessScopeId;
    });
    if (index > -1) {
      tableData[index].executionScope.childCount =
        relatedMap[relatedEntity].count;
      tableData[index].executionScope['ag-grid-has-expand'] =
        relatedMap[relatedEntity].count > 0;
    }
  }
  return tableData;
};

const addPercentageTextForHover = (tableData) => {
  const ebAmount = tableData.filter(
    (entity) => entity.executionScope.businessScope === 'ENTIRE_BUSINESS'
  )[0].executionScope.finalBudgetPlan;
  tableData.forEach((entity) => {
    if (entity.executionScope.businessScope !== 'ENTIRE_BUSINESS') {
      let baseAmount = ebAmount;
      let baseEntity = 'EB';
      if (entity.executionScope.businessScope === 'PORTFOLIO') {
        baseAmount = tableData.filter(
          (ent) =>
            ent.executionScope.businessScopeId ===
            entity.executionScope.relatedBusinessScopeId
        )[0].executionScope.finalBudgetPlan;
        baseEntity = 'Profile';
      }
      let percentVal =
        (entity.executionScope.finalBudgetPlan / baseAmount) * 100;
      percentVal = Math.round((percentVal + Number.EPSILON) * 100) / 100;
      entity.executionScope.percentageText = `<span class="u-color-grey-lighter perc-val">${percentVal}%</span>&nbsp;<span class="perc-scope">of ${baseEntity}</span>`;
    } else {
      entity.executionScope.percentageText =
        '<span class="u-color-grey-lighter perc-val">100%</span>';
    }
  });
  return tableData;
};

export default {
  state,
  getters,
  mutations,
  actions
};
