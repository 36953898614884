<template>
  <div
    v-if="openPopup"
    v-show="openPopup"
    ref="dropdownMenu"
    :style="popupPosition"
    :class="['popup-dropdown-menu', popupClass]"
  >
    <slot name="header">
      <div class="popup--header">
        <span class="u-display-flex u-flex-align-items-center">
          <span
            class="u-color-grey-white u-font-size-5"
            data-cy="saveReportTitle"
            >{{ headerTitle }}</span
          >
        </span>
        <span @click="headerIconClicked">
          <rb-icon
            :icon="'cross'"
            class="rb-icon--small u-color-grey-white u-cursor-pointer"
            data-cy="saveReportCrossIcon"
          />
        </span>
      </div>
    </slot>
    <slot name="body" />
    <slot name="footer">
      <div
        v-if="firstButtonConfig.showButton || secondButtonConfig.showButton"
        class="popup--footer u-display-flex u-flex-justify-content-flex-end u-spacing-pt-m u-spacing-pb-m u-flex-align-items-center"
      >
        <span class="u-display-flex u-flex-align-items-center">
          <rb-button
            v-if="firstButtonConfig.showButton"
            class="u-spacing-mr-s"
            :text="firstButtonConfig.text"
            :type="firstButtonConfig.type"
            :size="firstButtonConfig.size"
            :click-fn="firstButtonConfig.clickFn"
          />
          <rb-button
            v-if="secondButtonConfig.showButton"
            class="u-spacing-mr-s"
            :text="secondButtonConfig.text"
            :type="secondButtonConfig.type"
            :size="secondButtonConfig.size"
            :click-fn="secondButtonConfig.clickFn"
          />
        </span>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PopupComponent',
  components: {},
  props: {
    popupClass: {
      type: String,
      default: 'u-position-absolute'
    },
    showPopup: {
      type: Boolean,
      default: true
    },
    popupPosition: {
      type: Object,
      default: () => {
        return {
          top: '0px',
          left: '0px',
          width: '240px'
        };
      }
    },
    headerTitle: {
      type: String,
      default: 'Report Configuration'
    },
    firstButtonConfig: {
      type: Object,
      default: () => ({
        showButton: true,
        text: 'Cancel',
        type: 'hollow',
        size: 's'
      })
    },
    secondButtonConfig: {
      type: Object,
      default: () => ({
        showButton: true,
        text: 'Save',
        type: 'filled',
        size: 's'
      })
    }
  },
  data() {
    return {
      openPopup: false
    };
  },
  watch: {
    showPopup(newVal) {
      this.openPopup = newVal;
    }
  },
  created() {
    this.openPopup = this.showPopup;
  },
  methods: {
    headerIconClicked() {
      this.$emit('headerIconClickFn');
    }
  }
};
</script>

<style lang="css" scoped>
.popup-dropdown-menu {
  z-index: 999999;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(43, 51, 59, 0.2);
  overflow: auto;
}
.popup-dropdown-content {
  max-height: 304px;
  min-height: 136px;
  padding: 0px 0px 16px;
}

.popup--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
}
</style>
