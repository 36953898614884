import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
// import moment from 'moment';

const state = {
  testSqlTestList: {}
};

const getters = {
  getSqlTestList: (state) => {
    return state.testSqlTestList;
  }
};

const mutations = {
  INTERNAL_TOOLS_SQL_TEST_LIST_SUCCESS: (state, rows) => {
    Vue.set(state.testSqlTestList, 'rows', rows);
    Vue.set(state.testSqlTestList, 'load', false);
    Vue.set(state.testSqlTestList, 'error', false);
    if (rows.length === 0) {
      Vue.set(state.testSqlTestList, 'noData', true);
    } else {
      Vue.set(state.testSqlTestList, 'noData', false);
    }
  },
  INTERNAL_TOOLS_SQL_TEST_LIST_RESET: (state) => {
    Vue.set(state.testSqlTestList, 'rows', []);
    Vue.set(state.testSqlTestList, 'load', true);
    Vue.set(state.testSqlTestList, 'error', false);
    Vue.set(state.testSqlTestList, 'noData', false);
  },
  INTERNAL_TOOLS_SQL_TEST_LIST_ERROR: (state) => {
    Vue.set(state.testSqlTestList, 'rows', []);
    Vue.set(state.testSqlTestList, 'load', false);
    Vue.set(state.testSqlTestList, 'error', true);
    Vue.set(state.testSqlTestList, 'noData', false);
  },
  INTERNAL_TOOLS_SET_SELECTED_ITEM: (state, index) => {
    Vue.set(
      state.testSqlTestList.rows[index],
      'selected',
      !state.testSqlTestList.rows[index].selected
    );
  },
  INTERNAL_TOOLS_MARK_ALL_SET_SELECTED_ITEM: (state, selectedAll) => {
    for (let i = 0; i < state.testSqlTestList.rows.length; i++) {
      Vue.set(state.testSqlTestList.rows[i], 'selected', selectedAll);
    }
  }
};

const actions = {
  fetchSqlListRead: async (context) => {
    context.commit('INTERNAL_TOOLS_SQL_TEST_LIST_RESET');
    try {
      // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
      const response = await HttpService.post(
        'CIQ_INTERNAL_SQL_TEST_LIST_READ',
        {}
      );
      context.commit('INTERNAL_TOOLS_SQL_TEST_LIST_SUCCESS', response.data);
    } catch (err) {
      context.commit('INTERNAL_TOOLS_SQL_TEST_LIST_ERROR', err);
    }
  },
  markSelectedTestItem(context, index) {
    context.commit('INTERNAL_TOOLS_SET_SELECTED_ITEM', index);
  },
  markAllSelectedTestItem(context, selectedAll) {
    context.commit('INTERNAL_TOOLS_MARK_ALL_SET_SELECTED_ITEM', selectedAll);
  },
  submitForTestReport(context, body) {
    return HttpService.post('CIQ_INTERNAL_SQL_TEST_LIST_RUNTEST', body);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
