<template>
  <div
    id="widget_wrapper"
    class="u-border-width-s u-border-color-grey-xxx-light u-border-all"
    :class="customClasses"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-spacing-p-m u-flex-align-items-center u-border-width-s u-border-color-grey-xxx-light u-border-bottom u-text-case-title"
    >
      <span class="u-font-size-5 u-font-weight-600">{{ title }}</span>
      <div @click.stop="onClose">
        <rb-icon
          :icon="'cross'"
          class="u-cursor-pointer u-color-grey-lighter"
        />
      </div>
    </div>
    <div
      class="u-border-width-s u-border-color-grey-xxx-light u-border-bottom u-display-flex u-flex-justify-content-space-between"
    >
      <generic-tabs-component
        :key="title"
        :tabs-list="widgets"
        :shadow="false"
        :tab-styles="{ gap: '0px' }"
        @handleTabsClick="onWidgetChange"
      />
      <!-- eslint-disable -->
      <p
        v-html="infoText"
        class="u-font-size-5 u-spacing-p-m"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import genericTabsComponent from '@/components/basic/genericTabsComponent.vue';

export default {
  components: {
    genericTabsComponent
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    customClasses: {
      type: Array,
      default: () => ['u-spacing-mb-l']
    },
    widgets: {
      type: Array,
      required: true
    },
    infoText: {
      type: String,
      default: 'Some One Liner'
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onWidgetChange(selectedWidget) {
      this.$emit('onWidgetChange', selectedWidget);
    }
  }
};
</script>

<style lang="css" scoped>
#widget_wrapper ::v-deep .ag-body {
  max-height: 300px;
}
#widget_wrapper ::v-deep .ag-body .ag-body-viewport {
  max-height: 300px;
  overflow-y: auto;
}
</style>
