














import Vue, { PropType } from 'vue';
import { DonutDataService } from '@/components/pages/insights/amazon/market-share/service/donut-chart-service';
import { setDonutLegendMetadata } from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
import { DonutChartData, InputParams } from '../types/pie-chart-widget';
import { MetadataChart } from '@/components/pages/insights/amazon/market-share/service/backend-types';
import PieChartWidget from '@/components/pages/insights/amazon/market-share/molecules/pie-chart-widget.vue';

export default Vue.extend({
  name: 'PieChartWidgetStandalone',
  components: {
    PieChartWidget
  },
  props: {
    interactionEnabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'm'
    },
    popupTitle: {
      type: String,
      default: null
    },
    popupMetadata: {
      type: Object,
      default: () => ({})
    },
    isTitleClickable: {
      type: Boolean,
      default: true
    },
    dataService: {
      type: Object as PropType<DonutDataService>,
      required: true
    },
    color: {
      type: Function,
      default: undefined
    },
    // eslint-disable-next-line vue/require-default-prop
    downloadHandler: {
      type: Function
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    width: {
      type: Number
    }
  },
  data: () => {
    return {
      metricConfig: {
        invertTag2: false,
        tag1Unit: { pre: '$', roundoff: 1 },
        tag2Unit: { suff: '%', roundoff: 1 }
      },
      inputParams: undefined as unknown as InputParams
    };
  },
  computed: {
    metricData(): { tag1: number; tag2: number | undefined } {
      return {
        tag1: this.inputParams.chartTop.subText,
        tag2: this.inputParams.chartTop.pvp
      };
    },
    actionIcons(): {
      emit: string;
      icon: string;
      loading: boolean;
      error: boolean;
      tippyTitle: string;
    }[] {
      return [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle: this.inputParams.chartTop.downloadTooltipText
        }
      ];
    },
    chartData(): DonutChartData[] {
      const chartData = this.dataService.donutChartData.map(
        (unitDigitalShelfArray, index) => {
          const returnItem = setDonutLegendMetadata(
            unitDigitalShelfArray,
            {},
            {
              title: unitDigitalShelfArray.brand
            }
          );
          return returnItem;
        }
      );
      return chartData;
    },
    metaDataChart(): MetadataChart {
      return this.dataService.donutPopupData;
    },
    metadataLoad(): boolean {
      return this?.dataService.metadataLoad;
    },
    load(): boolean {
      return this?.dataService.load;
    },
    error(): boolean {
      return this?.dataService.error;
    },
    noData(): boolean {
      return this?.dataService.noData;
    }
  },
  watch: {
    'dataService.data': function (value) {
      if (value === null) {
      } else {
        this.inputParams = value;
      }
    }
  },
  methods: {}
});
