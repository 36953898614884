export default {
  config: (that) => {
    return {
      widgets: {
        addNegativeKeywords: {
          tableConfigs: {
            metadata: {
              resultTable: [
                {
                  name: 'keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword',
                      showOnUi: true,
                      isFixed: false,
                      width: 170
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '5',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                    clickHeader: that.removeAllKeywords,
                    metadata: {
                      toolTipText: 'Remove keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeKeyword,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 110
                    }
                  }
                }
              ]
            }
          }
        }
      }
    };
  }
};
