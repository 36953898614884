<template>
  <section>
    <div
      v-for="(text, index) in planDescription.description"
      :key="index"
      :class="dynamicClass(index, text)"
      class="u-display-inline-block"
      @click="() => goToStep(index)"
    >
      <div
        v-if="
          planDescription.valueIndexes[index] &&
          (planDescription.valueIndexes[index].tooltip || forceDisabledSteps)
        "
        v-tippy="tippy"
        :title="
          forceDisabledSteps
            ? 'Currently, a plan is being generated. Please wait for that to complete to edit this plan.'
            : planDescription.valueIndexes[index].tooltip
        "
      >
        {{ planDescription.valueIndexes[index].text }}
      </div>
      <template v-else>
        {{
          planDescription.valueIndexes[index]
            ? planDescription.valueIndexes[index].text
            : text
        }}
      </template>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    getPlanDescription: {
      type: Function,
      default: function () {
        return () => {};
      }
    },
    currentStepIndex: {
      type: Number,
      default: -1
    },
    enableSteps: {
      type: Boolean,
      default: false
    },
    disableRestOfSteps: {
      type: Boolean,
      default: true
    },
    forceDisabledSteps: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippy: {
        placement: 'top-start',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    dynamicClass() {
      return (index, text) => {
        const baseCondition = !!(this.planDescription?.valueIndexes?.[index]
          ? this.planDescription?.valueIndexes?.[index]?.text
          : text);
        return {
          'current-step':
            baseCondition &&
            this.planDescription.valueIndexes[index] &&
            this.currentStepIndex ===
              this.planDescription.valueIndexes[index].stepIndex,
          step: this.planDescription.valueIndexes[index],
          'active-step':
            baseCondition &&
            !this.enableSteps &&
            this.planDescription.valueIndexes[index],
          'u-cursor-pointer enabled-step':
            baseCondition & this.enableSteps &&
            this.planDescription.valueIndexes[index],
          'u-disabled-step':
            baseCondition &&
            (this.checkDisabled(index) || this.forceDisabledSteps),
          'u-spacing-mr-xs': baseCondition
        };
      };
    },
    planDescription() {
      return this.getPlanDescription();
    }
  },
  methods: {
    checkDisabled(index) {
      return (
        this.enableSteps &&
        this.disableRestOfSteps &&
        (this.planDescription?.valueIndexes?.[index]?.stepIndex ?? -1) >
          this.currentStepIndex
      );
    },
    goToStep(index) {
      const step = this.planDescription.valueIndexes[index];
      if (step) {
        this.$emit('goToStep', step.stepIndex);
      }
    }
  }
};
</script>

<style lang="css">
.step-wrapper {
  padding: 40px 64px;
}

.plan-description {
  line-height: 32px;
  padding: 24px 64px;
  background: rgba(0, 124, 246, 0.05);
}

.step {
  color: #2b333b;
  border-color: #2b333b;
  border-bottom: 1px dashed;
  font-weight: 600;
  line-height: 100%;
}

.current-step,
.active-step {
  color: #007cf6;
  border-color: #007cf6;
}

.current-step,
.enabled-step:hover {
  border-bottom-style: solid;
}

.default-plan {
  line-height: 32px;
}

.u-disabled-step {
  color: #aaadb1;
  border-color: #aaadb1;
  pointer-events: none;
}
</style>
