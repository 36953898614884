<template>
  <section class="campaign-creation-setup u-position-fixed widget--full-screen">
    <div class="u-position-fixed model u-width-100 campaign-creation-setup">
      <!-- Header Start -->
      <div class="">
        <section
          class="campaign-creation-setup--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span @click="closeWithoutSave()">
                <rb-icon
                  :icon="customCloseIcon.icon || 'cross'"
                  class="u-color-grey-lighter u-cursor-pointer u-spacing-mr-s"
                  :class="customCloseIcon.class || 'rb-icon--medium'"
                />
              </span>
              <span
                class="u-font-size-1 u-font-weight-600"
                data-cy="campaigncreateTitle"
                >{{ header && header.title }}
              </span>
            </div>
            <span
              class="u-font-size-4 u-color-grey-mid-light u-spacing-pt-s u-spacing-ml-l"
              data-cy="campaigncreateDescription"
              >{{ header && header.desc }}
            </span>
          </div>
        </section>
      </div>
      <!-- Header ends -->
      <!-- Scroll section -->
      <StepComponent
        :config="stepsConfig"
        :panel-width="10"
        :all-steps-data="pageData"
        :enabled-bread-crumbs="enabledBreadCrumbs"
        :show-step-bullet-description="true"
        bullet-point-class="u-spacing-pt-m"
        all-bullet-points-class="marign-top-campaign-creation-bullets"
        section-right-class="u-display-flex u-flex-direction-column u-height-100"
        class="u-width-100"
        :set-active-selection="setActiveSelection"
        @onStepNextBtnDisabled="handleStepNextBtnDisabled"
      />
      <!-- Scroll section -->
      <loader
        v-show="false"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import loader from '@/components/basic/loader';
import modal from '@/components/widgets/modal.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import Vue from 'vue';
import { cloneDeep, setWith } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    StepComponent,
    loader,
    modal
  },
  mixins: [stepsMixin],
  data() {
    return {
      enabledBreadCrumbs: [0],
      stepsConfig: [],
      header: {},
      pageData: null,
      setActiveSelection: 0
    };
  },
  computed: {
    customCloseIcon() {
      return config?.[this.retailer]?.customCloseIcon || {};
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters?.[this.pageData?.getter] || {};
    }
  },
  watch: {
    stepsConfig: {
      handler(newVal) {
        this.$store?.dispatch(this.pageData.action, {
          ...this.allStepsData,
          stepsConfig: newVal
        });
      },
      deep: true
    }
  },
  created() {
    this.header = config?.[this.retailer]?.header;
    this.pageData = config?.[this.retailer];
    this.setStepConfig();
    if (this?.$route?.query?.manual) {
      this.setActiveSelection = 0;
    } else if (
      this?.$route?.query?.edit &&
      this.allStepsData?.suggestedCampaigns?.length
    ) {
      this.setActiveSelection = 3;
    }
    if (this?.$route?.query?.create) {
      this.setActiveSelection = 0;
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: this.pageData?.fallbackRouteName
      });
    }
    this.getPageData();
    this.enabledBreadCrumbs = this.allStepsData.enabledBreadCrumbs || [0];
  },
  methods: {
    handleStepNextBtnDisabled(value) {
      const allStepsData = { ...this.allStepsData };
      if (this.pageData.enableStepsAfterValidation) {
        const { disabled, index } = value;
        let enabledBreadCrumbs =
          allStepsData?.enabledBreadCrumbs || this.enabledBreadCrumbs;
        const stepIndexToEnable = index + 1;
        const indexOfStepIndex = enabledBreadCrumbs.indexOf(stepIndexToEnable);
        if (!disabled && indexOfStepIndex === -1) {
          enabledBreadCrumbs.push(stepIndexToEnable);
        }
        if (disabled && indexOfStepIndex > -1) {
          enabledBreadCrumbs = enabledBreadCrumbs.filter(
            (i) => i < indexOfStepIndex
          );
        }
        this.enabledBreadCrumbs = enabledBreadCrumbs;
        setWith(allStepsData, 'enabledBreadCrumbs', enabledBreadCrumbs, Object);
        this.$store.dispatch(this.pageData.action, { ...allStepsData });
      }
    },
    setStepConfig() {
      if (!this.allStepsData?.stepsConfig) {
        this.stepsConfig = cloneDeep(config?.[this.retailer]?.stepsConfig);
      } else {
        this.stepsConfig = cloneDeep(this.allStepsData.stepsConfig);
      }
      Object.keys(this.stepsConfig).forEach((key, index) => {
        this.stepsConfig[key].index = index;
      });
      this.$store.dispatch(this.pageData.action, {
        ...this.allStepsData,
        stepsConfig: this.stepsConfig
      });
    },
    async getPageData() {
      const pageData = await dashUtils.getPageWidgets(
        0,
        this.pageData?.dashboardServicePage
      );
      this.pageData = { ...this.pageData, ...pageData?.data };
    },
    closeWithoutSave() {
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: this.pageData?.closeCreationRouteName
      });
    }
  }
};
</script>

<style lang="css">
.campaign-creation-setup {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.campaign-creation-setup .step-component-v2 .scrollable {
  padding-top: 0px;
}
.campaign-creation-setup
  .step-component-v2
  .marign-top-campaign-creation-bullets {
  margin-top: 160px;
  padding-left: 8px;
}
.campaign-creation-setup .campaign-creation-setup--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
</style>
