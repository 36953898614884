<template>
  <!-- using route name to map a selection of campaign or keyword. -->
  <div
    class="budget-plan-actions u-display-flex u-flex-direction-column widget--full-screen"
  >
    <!-- header -->
    <div
      v-if="refreshBar"
      class="refresh-bar u-spacing-pv-m u-spacing-ph-l u-bg-color-blue-base u-color-grey-white u-display-flex"
    >
      <rb-icon
        class="rb-icon--small u-spacing-mr-xs u-color-white-base"
        :icon="'info-circle-fill'"
      />
      <span class="u-font-size-5"
        >Updates on your actions may take time. Please refresh in sometime to
        see the latest information.</span
      >
      <span
        class="u-font-size-5 u-cursor-pointer"
        style="margin-left: auto"
        @click="
          fetchActionsTable();
          refreshBar = false;
        "
        >REFRESH</span
      >
      <span @click="refreshBar = false"
        ><rb-icon
          class="rb-icon--small u-spacing-ml-s u-cursor-pointer u-color-white-base"
          :icon="'cross'"
      /></span>
    </div>
    <section
      class="budget-plan-actions--header u-display-flex u-flex-align-items-center u-spacing-p-m"
    >
      <div
        class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
      >
        <div class="u-display-flex">
          <div @click="routeToBudgetOptimiserDashboard">
            <rb-icon
              icon="right-arrow"
              class="u-color-grey-lighter is-flipped rb-icon--x-medium u-cursor-pointer u-spacing-mr-s"
            />
          </div>
          <div>
            <div class="heading--input u-font-size-2 u-font-weight-600">
              Budget Planner
            </div>
            <div class="u-spacing-pt-m u-display-flex u-flex-direction-row">
              <rb-select
                class="u-spacing-mr-s u-max-width-100"
                :send-details="true"
                :options="actions"
                :on-close="onSelectAction"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                >
                  <div
                    class="u-font-size-4 u-text-overflow-ellipsis u-color-grey-base"
                  >
                    {{ selectedAction.name }}
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-xs u-color-grey-base"
                    icon="caret-down"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    {{ option.name }}
                  </div>
                </template>
              </rb-select>
              <span
                v-tippy="tippyConfig"
                :title="aggregateDaysTippyText"
              >
                <rb-icon
                  icon="help-fill"
                  class="u-color-blue-base rb-icon--medium u-cursor-pointer u-spacing-mr-s"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- filter panel -->
    <section
      v-show="selectedRows.length === 0 && $route.name"
      class="budget-plan-filter-panel u-display-flex u-spacing-ph-m u-flex-align-items-center"
    >
      <rb-filter-panel
        id="actions-bp-filters"
        :key="$route['name']"
        style="z-index: 11"
        :page="pageWiseFilter"
        :enable-save="false"
        :data="filterData"
        :primary="computedPrimaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="$route['name'] + `-filters`"
        :loader-status="loaderStatus"
        :state="filterState"
        :has-search="false"
        :emit="pageWiseFilter"
      >
        <div
          slot="right-side-without-search"
          class="u-display-flex"
        >
          <rb-download-button
            :key="$route.name"
            :disabled="getTableData.rows && getTableData.rows.length === 0"
            :config="config.widgets[selectedActionConfig[$route.name]]"
            :column-map="
              config.widgets[selectedActionConfig[$route.name]].body.download
                .columnMap
            "
            :file-name="
              config.widgets[selectedActionConfig[$route.name]].body.download
                .fileName
            "
          />
        </div>
      </rb-filter-panel>
    </section>
    <section
      v-show="selectedRows.length > 0 && $route.name"
      class="u-display-flex budget-plan-action-workspace u-width-100 u-flex-direction-column"
    >
      <div
        v-if="showActionsBar"
        class="u-width-100"
        style="z-index: 201"
      >
        <actionsBar
          :custom-selection-length="customSelectionLength"
          :selections="selectedRows"
          :close-fn="closeActionsWorkflow"
          :passed-actions="allowedActions"
          @open-workspace="openActionsWorkspace"
        />
      </div>
      <actions-workspace
        v-if="
          openActionWorkSpaceId &&
          showActionsWorkSpacePanel &&
          openActionWorkSpaceId !== 'adjust-bids'
        "
        :current-primary-key="currentKeyMap[$route.name]"
        :selections="selectedRows"
        :loaded-workspace="loadedWorkspace"
        :total-selections="
          bulkActionSelected ? getTableData.totalRows : selectedRows.length
        "
        :valid-selections="
          bulkActionSelected ? getTableData.totalRows : validSelections
        "
        :submit-action="onSaveAction"
        :close-fn="actionsWorkSpaceAfterAction"
        :requested-action="{
          id: openActionWorkSpaceId,
          successMsg: 'Approved'
        }"
      />
      <div
        v-if="
          openActionWorkSpaceId &&
          showBidChangePanel &&
          openActionWorkSpaceId === 'adjust-bids'
        "
      >
        <div
          v-if="!validSelections"
          class="u-spacing-ph-l"
        >
          <div
            class="action--title u-font-weight-600 u-font-size-5 u-spacing-pt-l u-text-case-upper"
          >
            Approve Recommendations
          </div>
          <div
            class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
          >
            <div v-if="validSelections === 0">
              None of the recommendations selected can be approved.
            </div>
          </div>
          <div class="u-font-size-5 u-spacing-pv-m">
            On approval, recommended actions with
            <rb-icon
              class="rb-icon--small u-color-blue-base"
              :icon="'logo'"
            />(Supported by CommerceIQ) will be initiated.
          </div>
          <div
            class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
          >
            <footerButtons
              :button-size="'s'"
              :show-left-btn="false"
              @onClickBtnRight="closeActionBar"
            />
          </div>
        </div>

        <section
          v-if="enableLoading && validSelections"
          class="loaderContainer"
        >
          <loader
            class="fill--parent"
            :loading="enableLoading"
            :color="'#007cf6'"
          />
        </section>

        <section v-show="!enableLoading && validSelections">
          <div class="u-font-size-6 u-spacing-p-l">
            <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
              ADJUST BID ({{
                bulkActionSelected
                  ? getTableData.totalRows
                  : selectedRows.length
              }}
              <!-- {{ tabMetadata.targetingType.toUpperCase() }} -->
              {{ selectedRows.length > 1 ? `Keyword's` : 'Keyword' }} selected)
            </div>
            <radioSelectionWithInput
              class="u-spacing-pt-m"
              :radio-config="bidChangeConfig"
              default-radio-selected="set_new_bid"
              :bid-suggestion="bidSuggestion"
              :default-input-value="defaultInputValue"
              @onUpdate="onBidUpdates"
            />
          </div>
          <div
            class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
          >
            <footerButtons
              :disable-left-btn="!(bidValue && bidValue.inputValue)"
              class="u-spacing-ph-l"
              :hollow-hover-button-left="false"
              left-button-type="filled"
              btn-left-text="Apply"
              :button-size="'s'"
              @onClickBtnRight="closeActionBar"
              @onClickBtnLeft="onSaveBid"
            />
          </div>
        </section>
      </div>

      <div
        v-if="
          openActionWorkSpaceId &&
          showBidChangePanel &&
          openActionWorkSpaceId === 'adjust-budget'
        "
      >
        <div
          v-if="!validSelections"
          class="u-spacing-ph-l"
        >
          <div
            class="action--title u-font-weight-600 u-font-size-5 u-spacing-pt-l u-text-case-upper"
          >
            Approve Recommendations
          </div>
          <div
            class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
          >
            <div v-if="validSelections === 0">
              None of the recommendations selected can be approved.
            </div>
          </div>
          <div class="u-font-size-5 u-spacing-pv-m">
            On approval, recommended actions with
            <rb-icon
              class="rb-icon--small u-color-blue-base"
              :icon="'logo'"
            />(Supported by CommerceIQ) will be initiated.
          </div>
          <div
            class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
          >
            <footerButtons
              :button-size="'s'"
              :show-left-btn="false"
              @onClickBtnRight="closeActionBar"
            />
          </div>
        </div>

        <section
          v-if="enableLoading && validSelections"
          class="loaderContainer"
        >
          <loader
            class="fill--parent"
            :loading="enableLoading"
            :color="'#007cf6'"
          />
        </section>
        <section v-show="!enableLoading && validSelections">
          <div class="u-font-size-6 u-spacing-p-l">
            <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
              ADJUST BUDGET ({{
                bulkActionSelected
                  ? getTableData.totalRows
                  : selectedRows.length
              }}
              {{ selectedRows.length > 1 ? `Campaign's` : 'Campaign' }}
              selected)
            </div>
            <radioSelectionWithInput
              class="u-spacing-pt-m"
              :radio-config="budgetChangeConfig"
              default-radio-selected="set_new_bid"
              :bid-suggestion="bidSuggestion"
              :default-input-value="defaultInputValue"
              @onUpdate="onBidUpdates"
            />
          </div>
          <div
            class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
          >
            <footerButtons
              :disable-left-btn="!(bidValue && bidValue.inputValue)"
              class="u-spacing-ph-l"
              :hollow-hover-button-left="false"
              left-button-type="filled"
              btn-left-text="Apply"
              :button-size="'s'"
              @onClickBtnRight="closeActionBar"
              @onClickBtnLeft="onSaveBid"
            />
          </div>
        </section>
      </div>

      <div
        v-if="!hideBulkSelection"
        class="u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-center u-spacing-p-m u-font-size-6 u-border-width-s u-border-top u-border-color-grey-xxx-light"
      >
        <div
          v-if="!bulkActionSelected"
          class="fs-manual-actions-select-all"
        >
          <!-- eslint-disable -->
          {{ this.selectedRows.length }}
          {{ this.selectedRows.length > 1 ? 'actions' : 'action' }} selected.
          <!-- eslint-enable -->
          <span
            class="u-color-blue-base u-cursor-pointer"
            style="text-decoration: underline"
            @click="
              bulkActionSelected = true;
              customSelectionLength = getTableData.totalRows;
              selecteAllRows();
            "
            >Select all {{ getTableData.totalRows }} actions.</span
          >
        </div>
        <div v-else>
          All {{ getTableData.totalRows }} actions selected.
          <span
            class="u-color-blue-base u-cursor-pointer"
            style="text-decoration: underline"
            @click="
              bulkActionSelected = false;
              deselctAllRows();
            "
            >Clear selection.</span
          >
        </div>
      </div>
    </section>
    <!-- <div style="width: 10px; height: 50px"></div> -->
    <section>
      <card-widget
        :key="$route.name"
        :apply-card-class="false"
        class="u-bg-color-grey-white"
        :config="config.widgets[selectedActionConfig[$route.name]]"
        :data="getTableData"
      >
        <div
          slot="body"
          class="customOverflow"
          :style="{ height: tableHeight + 'px' }"
        >
          <div
            class="budgetPlannerActionsTable u-height-100"
            :class="bulkActionSelected ? 'overlay-css' : ''"
          >
            <rb-insights-table
              :key="$route.name"
              :config="
                ((config || {}).widgets || {})[
                  selectedActionConfig[$route.name]
                ] || {}
              "
              :grid-options="gridOptions"
              :table-row="getTableData.rows"
              :table-column="
                (
                  (
                    ((config || {}).widgets || {})[
                      selectedActionConfig[$route.name]
                    ] || {}
                  ).body || {}
                ).columnDefs
              "
              :row-height="50"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :row-selection="rowSelection"
              :pagination="true"
              :pagination-total-key="getTableData.totalRows"
              :pagination-change-event="
                config.widgets[selectedActionConfig[$route.name]].meta
                  .paginationAction
              "
              :pagination-per-page-limit="
                config.widgets[selectedActionConfig[$route.name]].body.APIConfig
                  .limit
              "
              primary-key="campaign_id"
              :show-pagination-options="true"
              :get-current-instance="getCurrentInstance"
            />
          </div>
        </div>
      </card-widget>
    </section>
  </div>
</template>

<script>
import widgetsConfig from './widgets';
import Vue from 'vue';

import moment from 'moment-timezone';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsUtil from '@/components/widgetMixin';
import cardWidget from '@/components/widgets/cardWidget';
import actionsBar from '@/components/widgets/actionsBar.vue';
import actionsWorkspace from '@/components/widgets/actions/actionsWorkspace.vue';
import { eventBus } from '@/utils/services/eventBus';
import { cloneDeep } from 'lodash';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import transformAction from '@/components/ams/budgetPlanner/actions/actionsTransformer.js';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
import actionsConfiguration from '@/components/pages/recommendations/actions.js';
import loader from '@/components/basic/loader';
import { updateComputedFilterData } from '@/pages/budget-planner-v2/dashboard/helpers/filterUpdate.js';
import { createBacthPayload } from '@/components/ams/campaigns/batchPayloadCreator.js';
import { transformEditPayload } from '@/pages/budget-planner-v2/create-plan/store/helpers/utils';

const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
export default {
  components: {
    rbFilterPanel,
    actionsWorkspace,
    cardWidget,
    actionsBar,
    rbDownloadButton,
    bidChangePanel,
    radioSelectionWithInput,
    footerButtons,
    loader
  },
  mixins: [widgetsUtil],
  data() {
    return {
      validStatus: ['OPEN', 'FAILED'],
      hideBulkSelection: true,
      currentKeyMap: {
        'budget-plan-campaign-budget': 'campaign',
        'budget-plan-keyword-bid': 'keyword_text',
        'budget-plan-campaign-budget-v2': 'campaign',
        'budget-plan-keyword-bid-v2': 'keyword_text'
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        }
      },
      widgetsConfig: {},
      tableHeight: 0,
      selectedRows: [],
      refreshBar: false,
      customSelectionLength: null,
      pageWiseFilter: 'campaignsManualActions',
      bulkActionSelected: false,
      loadedWorkspace: true,
      allowedActions: [],
      rbTableInstance: null,
      openActionWorkSpaceId: null,
      // use selectedActionConfig to map the right widget in actionWidgets.js
      selectedActionConfig: {
        'budget-plan-campaign-budget': 'widget1',
        'budget-plan-keyword-bid': 'widget2',
        'budget-plan-campaign-budget-v2': 'widget1',
        'budget-plan-keyword-bid-v2': 'widget2'
      },
      config: {},
      timeoutVariable: null,
      parentRef: null,
      actions: [
        {
          name: 'Adjust campaign budget',
          type: 'budget-plan-campaign-budget',
          filterEvent: 'campaignsManualActions'
        },
        {
          name: 'Adjust keyword bid',
          type: 'budget-plan-keyword-bid',
          filterEvent: 'keywordsManualActions'
        }
      ],
      filterState: {
        getter: 'getBudgetPlannerActionsSelectedFilters',
        setter: 'setBudgetPlannerActionsFilter'
      },
      actionsConfiguration: actionsConfiguration,
      bidChangePanelTitle: 'Budget Change',
      showBidChangePanel: false,
      showActionsWorkSpacePanel: false,
      showActionsBar: false,
      bidSuggestion: { show: false },
      bidValue: null,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      budgetChangeConfig: [
        {
          title: 'Set new budget',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase budget',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease budget',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      enableLoading: false,
      tippyConfig: {
        placement: 'bottom',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      }
    };
  },
  computed: {
    aggregateDaysTippyText() {
      const actionSelected = this.selectedAction.type;
      return `All the metrics are for the ${
        actionSelected === 'budget-plan-campaign-budget-v2'
          ? '7-day period from 9 days ago to 2 days ago'
          : '14-day period from 16 days ago to 2 days ago'
      }`;
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    computedPrimaryFilterData() {
      let filterData = this.primaryFilterData;
      if (
        Vue.options.filters.config_check(
          `feature.pages.${this.retailer}_budget_planner_v2`
        )?.enable
      ) {
        const { categorizationType = null, selectedLevels = null } =
          this.currentBudgetPlan?.[0]?.actions?.[0]?.executionTemplate || {};
        filterData = updateComputedFilterData(
          categorizationType,
          selectedLevels,
          this.primaryFilterData
        );
      }
      return filterData;
    },
    currentBudgetPlan() {
      // right now we will have only one strategy
      return this.$store.getters['budgetPlannerDashboardV2/getStrategies'];
    },
    validSelections() {
      let count = 0;
      this.selectedRows.forEach((sel) => {
        if (
          sel.data &&
          sel.data.status &&
          this.validStatus.includes(sel.data.status)
        ) {
          count++;
        }
      });
      return count;
    },
    maxFeedDate() {
      const maxDate =
        this.$store.getters.getMaxDate.budget_planner_manual_actions &&
        this.$store.getters.getMaxDate.budget_planner_manual_actions
          .max_feed_date;
      const formatedDate = moment(maxDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
      return formatedDate;
    },
    rowSelection() {
      return {
        mode: 'multiple',
        onSelectionChanged: this.onRowSelection
      };
    },
    actionTriggered() {
      return this.$store.getters.getBudgetPlanActionTriggered;
    },
    selectedAction() {
      for (const action of this.actions) {
        if (action.type === this.$route.name) {
          return action;
        }
      }
      return 'N/A';
    },
    getTableData() {
      return this.$store.getters.getBudgetPlannerActionTable;
    },
    currentAction() {
      // assuming only 1 action type is supported per recommendation row
      return this.actionPayload
        ? Object.keys(this.actionPayload)[0]
        : undefined;
    },
    actionPayload() {
      let payload = {};
      if (this.selectedRows.length) {
        const selection = this.selectedRows[0];
        if (selection.data && selection.data.action_payload) {
          payload = JSON.parse(selection.data.action_payload);
        }
      }
      return payload;
    },
    isEditable() {
      let tempValidSelections = this.bulkActionSelected
        ? this.getTableData.totalRows
        : this.validSelections;
      return (
        this.actionConfig && tempValidSelections === 1 && this.actionConfig.edit
      );
    },
    actionConfig() {
      return this.actionsConfiguration.allActions[this.currentAction];
    },
    defaultInputValue() {
      if (
        this.selectedAction?.type === 'budget-plan-campaign-budget' ||
        this.selectedAction?.type === 'budget-plan-campaign-budget-v2'
      ) {
        return this.selectedRows.length > 1
          ? 1
          : JSON.parse(this.actionPayload[this.currentAction].actions)[0]
              .new_daily_budget || 0;
      } else if (
        this.selectedAction?.type === 'budget-plan-keyword-bid' ||
        this.selectedAction?.type === 'budget-plan-keyword-bid-v2'
      ) {
        return this.selectedRows.length > 1
          ? 1
          : JSON.parse(this.actionPayload[this.currentAction].actions)[0]
              .new_bid || 0;
      }
    }
  },
  watch: {
    actionTriggered(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.refreshBar = true;
      }
    },
    getTableData() {
      // any table changes, deselect rows.
      this.deselctAllRows();
    },
    $route: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.refreshBar = false;
          this.selectedRows = [];
          this.openActionWorkSpaceId = null;
          if (newVal.name !== oldVal.name) {
            this.allowedActions = allActionConfig.getConfigs.call(this);
            this.loadActions(); // reset filters once route has been set as its asynchronous
          }
        }
        // restting widget mixin data properties
        this.filterData = [];
        this.primaryFilterData = [];
        this.secondaryFilterData = {};
        // fetching filters
        this.fetchActionFilters();
      },
      deep: true
    }
  },
  created() {
    this.updateActionsConfigV2();
    this.modifyFiltersBeforeLoad();
    this.$store.dispatch('budgetPlannerDashboardV2/fetchLatestStrategy');
    // widget config
    if (this.allowedActions.length === 0) {
      this.allowedActions = allActionConfig.getConfigs.call(this);
    }
    this.loadActions();

    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    this.fetchActionFilters();
    eventBus.$on(this.selectedAction.filterEvent, () => {
      // resetting page on applying filter.
      (
        (
          (
            ((this.config || {}).widgets || {})[
              this.selectedActionConfig[this.$route.name]
            ] || {}
          ).body || {}
        ).APIConfig || {}
      ).page = 1;
      this.fetchActionsTable();
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.calculateTableHeight);
    });
  },
  updated() {
    this.calculateTableHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateTableHeight);
  },
  destroyed() {
    eventBus.$off('campaignsManualActions');
    eventBus.$off('keywordsManualActions');
    localStorage.removeItem('budget-plan-campaign-budget-v2-filters');
    localStorage.removeItem('budget-plan-keyword-bid-v2-filters');
  },
  methods: {
    routeToBudgetOptimiserDashboard() {
      const v2 = Vue.options.filters.config_check(
        `feature.pages.${this.retailer}_budget_planner_v2`
      )?.enable;
      const routeName = 'budget-planner-dashboard';
      this.$router.push({ name: v2 ? `${routeName}-v2` : routeName });
    },
    updateActionsConfigV2() {
      if (
        Vue.options.filters.config_check(
          `feature.pages.${this.retailer}_budget_planner_v2`
        )?.enable
      ) {
        this.actions = this.actions.map((item) => {
          item.type = item.type + '-v2';
          return item;
        });
      }
    },
    calculateTableHeight() {
      const classes = [
        '.refresh-bar',
        '.budget-plan-actions--header',
        '.budget-plan-filter-panel',
        '.budget-plan-action-workspace'
      ];
      const cardFooterHeight = 28;
      let height = 0;
      for (const item of classes) {
        if (document.querySelector(item)) {
          height += document.querySelector(item).offsetHeight;
        }
      }
      this.tableHeight = window.innerHeight - height - cardFooterHeight;
    },
    async manualActionsApi(payload, actionName) {
      this.loadedWorkspace = false;
      let message = '';
      try {
        await this.$store.dispatch(actionName, payload);
        message =
          'Selected action(s) are being approved. Please wait for sometime before the changes are reflected.';
        this.onActionSuccess(actionName);
        this.closeActionBar();
        this.enableLoading = false;
      } catch (err) {
        console.log(err);
        message = 'Oops something went wrong. Please try again.';
        this.enableLoading = false;
      } finally {
        this.openSnackbar(message);
        this.loadedWorkspace = true;
        this.closeActionsWorkflow();
        this.enableLoading = false;
      }
    },
    onApproveAction(editMode, actionType, editPayload) {
      // store api to approve action
      this.enableLoading = true;
      if (this.bulkActionSelected) {
        this.onBulkAction();
      } else {
        this.onSingleAction(editMode, actionType, editPayload);
      }
    },
    onEditMode(editPayload, actionType) {
      let temppayload = [];
      this.selectedRows.forEach((payload, index) => {
        temppayload = transformAction.generateEditActionPayload(
          payload.data,
          editPayload.actions[index],
          actionType
        );
        if (!temppayload) {
          return null;
        }
      });
      return temppayload;
    },
    onAdjustMode(editPayload, actionType) {
      let temppayload = [];
      this.selectedRows.forEach((sel, index) => {
        if (sel?.data?.status && this.validStatus.includes(sel.data.status)) {
          if (
            this.openActionWorkSpaceId === 'approve-actions' &&
            sel?.data?.action_payload_for_api
          ) {
            temppayload.push(JSON.parse(sel.data.action_payload_for_api));
          } else {
            const tempValue = transformAction.generateEditActionPayload(
              sel.data,
              editPayload.actions[index],
              actionType
            );
            if (!tempValue) {
              temppayload = [];
              return null;
            }
            temppayload.push(tempValue);
          }
        }
      });
      return temppayload;
    },
    onSingleAction(editMode, actionType, editPayload) {
      // handle keywords scenario where targets and keywords both are present
      const validRows = this.selectedRows.filter((row) =>
        this.validStatus.includes(row.data.status)
      );
      if (actionType === 'keywordBidChange' && editPayload.actions.length > 1) {
        const keywordsPayload = validRows.filter((row) => {
          const currentRowActionPayloadForApi = JSON.parse(
            row.data.action_payload_for_api
          );
          return !currentRowActionPayloadForApi.viewPayload.budgetPlannerMetadata.matchType.includes(
            'TARGETING'
          );
        });
        const targetingPayload = validRows.filter((row) => {
          const currentRowActionPayloadForApi = JSON.parse(
            row.data.action_payload_for_api
          );
          return currentRowActionPayloadForApi.viewPayload.budgetPlannerMetadata.matchType.includes(
            'TARGETING'
          );
        });
        // make keywords call
        if (keywordsPayload.length > 0) {
          let finalKeywordsPayload = transformEditPayload(
            keywordsPayload,
            actionType,
            this,
            editMode,
            editPayload
          );
          finalKeywordsPayload = createBacthPayload(
            this,
            finalKeywordsPayload,
            true
          );
          this.manualActionsApi(
            finalKeywordsPayload,
            'approveBudgetPlannerActionNew'
          );
        }

        // make targets call
        if (targetingPayload.length > 0) {
          let finalTargetPayload = transformEditPayload(
            targetingPayload,
            actionType,
            this,
            editMode,
            editPayload
          );
          finalTargetPayload = createBacthPayload(
            this,
            finalTargetPayload,
            true
          );
          this.manualActionsApi(
            finalTargetPayload,
            'approveBudgetPlannerActionNew'
          );
        }

        return;
      }

      let payload = transformEditPayload(
        validRows,
        actionType,
        this,
        editMode,
        editPayload
      );
      payload = createBacthPayload(this, payload, true);
      this.manualActionsApi(payload, 'approveBudgetPlannerActionNew');
    },
    onBulkAction() {
      const actionType = JSON.parse(
        this.selectedRows[0].data.action_payload_for_api
      ).actionType;
      const cubeExecutionRequest = cloneDeep(
        this.config.widgets[this.selectedActionConfig[this.$route.name]].body
          .APIConfig
      );
      delete cubeExecutionRequest.limit;
      delete cubeExecutionRequest.page;
      this.manualActionsApi(
        {
          feedDate: this.maxFeedDate,
          actionType,
          service: 'budget_optimizer',
          cubeExecutionRequest
        },
        'approveBudgetPlannerBulkAction'
      );
      this.$store.dispatch('budgetPlannerManualUpdateAllStatus');
    },
    onActionSuccess(actionName) {
      if (actionName === 'approveBudgetPlannerBulkAction') {
        return;
      }
      this.selectedRows.forEach((node) => {
        if (this.validStatus.includes(node.data.status)) {
          node.data.status = 'APPROVED';
        }
      });
    },
    invalidPayload() {
      this.enableLoading = false;
      const entity =
        this.selectedAction?.type === 'budget-plan-campaign-budget'
          ? 'Campaign Budget'
          : 'Keyword Bid';
      this.openSnackbar(`Invalid ${entity} entered for one or more selections`);
    },
    openSnackbar(content) {
      this.$snackbar.open({
        message: content,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    modifyFiltersBeforeLoad() {
      const whiteListedRoutes = [
        'budget-plan-campaign-budget',
        'budget-plan-campaign-budget-v2'
      ];
      const routeName = this.$route.name;
      if (!whiteListedRoutes.includes(routeName) && !this.$route.query) return;
      const filterToSet = {};
      const orderToSet = [];
      let modifyFilter = false;
      const filters = ['profile_name', 'portfolio_name', 'level1', 'level2'];
      for (const filter of filters) {
        if (this.$route.query[filter]) {
          modifyFilter = true;
          orderToSet.push(filter);
          filterToSet[filter] = [this.$route.query[filter]];
        }
      }
      if (!modifyFilter) {
        return;
      }
      const v2BudgetPlanner = 'v2';
      for (const filterKey in this.currentKeyMap) {
        if (
          routeName.includes(v2BudgetPlanner) &&
          filterKey.includes(v2BudgetPlanner)
        ) {
          localStorage.setItem(
            `${filterKey}-filters`,
            JSON.stringify(filterToSet)
          );
          localStorage.setItem(
            `${filterKey}-filters-order`,
            JSON.stringify(orderToSet)
          );
        }
      }
    },
    fetchActionsTable() {
      this.$store.dispatch(
        'budgetPlannerManualActionTable',
        this.config.widgets[this.selectedActionConfig[this.$route.name]]
      );
    },
    closeActionsWorkflow() {
      this.deselctAllRows();
      this.openActionWorkSpaceId = null;
      this.bulkActionSelected = false;
    },
    selecteAllRows() {
      this.rbTableInstance.gridOptions.api.forEachNode((node) => {
        node.setSelected(true);
      });
    },
    fetchActionFilters() {
      const cubeName =
        this.config.widgets[this.selectedActionConfig[this.$route.name]].body
          .APIConfig.cubeName;
      const filterMap = {
        ams_manual_actions_keywords_bid_change: 'keywordsManualActions',
        ams_manual_actions_campaign_budget_change: 'campaignsManualActions'
      };
      this.pageWiseFilter = filterMap[cubeName];
      this.fetchFilters(cubeName, 'FETCH_FILTERS_V2', filterMap[cubeName]);
    },
    deselctAllRows() {
      this.rbTableInstance.gridOptions.api.deselectAll();
      this.customSelectionLength = null;
      this.bulkActionSelected = false;
    },
    onSelectAction(context, action) {
      if (action && action[0] && action[0].type) {
        if (
          this.selectedAction &&
          this.selectedAction.type !== action[0].type
        ) {
          eventBus.$off(this.selectedAction.filterEvent);
          eventBus.$on(action[0].filterEvent, () => {
            this.fetchActionsTable();
          });
          this.$router.push({ name: action[0].type });
          this.allowedActions = allActionConfig.getConfigs.call(this);
          this.loadActions();
        }
      }
    },
    loadActions() {
      if (
        this.selectedAction?.type === 'budget-plan-campaign-budget' ||
        this.selectedAction?.type === 'budget-plan-campaign-budget-v2'
      ) {
        this.allowedActions = this.allowedActions.filter((action) => {
          return action.id !== 'adjust-bids';
        });
      } else if (
        this.selectedAction?.type === 'budget-plan-keyword-bid' ||
        this.selectedAction?.type === 'budget-plan-keyword-bid-v2'
      ) {
        this.allowedActions = this.allowedActions.filter((action) => {
          return action.id !== 'adjust-budget';
        });
      }
    },
    openActionsWorkspace(id) {
      this.openActionWorkSpaceId = id;
      switch (id) {
        case 'adjust-bids':
        case 'adjust-budget':
          this.adjustBidsPanel();
          break;
        case 'approve-actions':
        case 'edit-actions':
          this.actionsWorkSpacePanel();
          break;
      }
    },
    onDownload() {},
    // onColumns () {},
    onRowSelection(rows) {
      if (rows.length === 0) {
        this.openActionWorkSpaceId = null;
        this.showBidChangePanel = false;
        this.showActionsWorkSpacePanel = false;
      }
      this.selectedRows = rows;
      this.checkForActionBar(rows);
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    checkForActionBar(data) {
      if (
        !this.showBidChangePanel &&
        !this.showActionsWorkSpacePanel &&
        !this.showActionsBar
      ) {
        this.showActionsBar = data.length > 0;
      }
      if (data.length === 0) {
        this.showActionsBar = false;
      }
    },
    hideActionBar() {
      this.showActionsBar = false;
    },
    bidChangeAfterAction() {
      this.closeActionsWorkflow();
      this.adjustBidsPanel();
    },
    actionsWorkSpaceAfterAction() {
      this.closeActionsWorkflow();
      this.actionsWorkSpacePanel();
    },
    adjustBidsPanel() {
      this.hideActionBar();
      this.showBidChangePanel = !this.showBidChangePanel;
    },
    actionsWorkSpacePanel() {
      this.hideActionBar();
      this.showActionsWorkSpacePanel = !this.showActionsWorkSpacePanel;
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
    },
    onSaveAction(editMode, actionType, editPayload) {
      if (
        (this.openActionWorkSpaceId === 'approve-actions' ||
          this.openActionWorkSpaceId === 'edit-actions') &&
        this.selectedRows.length === 1
      ) {
        this.onApproveAction(editMode, actionType, editPayload);
      } else {
        this.onSaveBid();
      }
    },
    onSaveBid() {
      const payloadArr = [];
      for (const selection of this.selectedRows) {
        const oldBudget =
          this.currentAction !== 'keywordBidChange'
            ? selection?.data?.previous_budget || 0
            : selection?.data?.previous_bid || 0;
        const newBudget = this.transformBid(oldBudget, selection);
        const isEditActionViewOpen =
          this.openActionWorkSpaceId === 'edit-actions';
        let reqValues = {};
        if (this.currentAction === 'keywordBidChange') {
          reqValues = {
            action_type: this.currentAction,
            campaignType: selection?.data?.campaign_type,
            campaign_name: selection?.data?.campaign_name,
            campaign_id: selection?.data?.campaign_id,
            current_bid: selection?.data?.current_bid,
            keyword_id: selection?.data?.keyword_id,
            new_bid: isEditActionViewOpen
              ? selection?.data?.current_bid
              : newBudget,
            profile_id: selection?.data?.profile_id
          };
        } else {
          reqValues = {
            action_type: this.currentAction,
            campaignType: selection?.data?.campaign_type,
            campaign_id: selection?.data?.campaign_id,
            campaign_name: selection?.data?.campaign_name,
            daily_budget: selection?.data?.previous_budget,
            new_daily_budget: isEditActionViewOpen
              ? selection?.data?.current_budget
              : newBudget,
            profile_id: selection?.data?.profile_id
          };
        }
        payloadArr.push(reqValues);
      }
      this.onApproveAction(this.isEditable, this.currentAction, {
        actions: payloadArr
      });
    },
    transformBid(oldBudget, selection) {
      let delta = parseFloat(this.bidValue?.inputValue);
      if (this.bidValue?.radioValue === 'set_new_bid') {
        if (this.bidValue?.inputValue === 'YY') {
          return parseFloat(oldBudget);
        }
        if (this.bidValue?.inputValue === 'XX') {
          return parseFloat(selection.data?.ams_cm_targeting_bid_suggested);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBudget * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBudget + delta).toFixed(2));
      } else {
        return parseFloat((oldBudget - delta).toFixed(2));
      }
    },
    closeActionBar() {
      this.showActionsWorkSpacePanel = false;
      this.closeActionsWorkflow();
      this.adjustBidsPanel();
    }
  }
};
</script>

<style lang="css">
.budget-plan-actions {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  top: 0;
  z-index: 200;
}
.budget-plan-actions--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  width: 100%;
  z-index: 101;
}
.budgetPlannerActionsTable .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.budgetPlannerActionsTable .actionsIQTipRowHover {
  visibility: hidden;
}
.budgetPlannerActionsTable .ag-row-hover .actionsIQTipRowHover {
  visibility: visible;
  display: flex;
}
.overlay-css {
  pointer-events: none;
}
.customOverflow {
  overflow: auto;
}
.customOverflow::-webkit-scrollbar {
  display: none;
}
.loaderContainer {
  position: relative;
  height: 221px;
}
</style>
