var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"u-display-flex u-flex-align-items-center borders-outline tabs-radius dashboard-background"},_vm._l((_vm.tabsData),function(tab,index){return _c('div',{key:index,staticClass:"u-height-100 tabs-radius u-spacing-pv-xs u-spacing-ph-m u-cursor-pointer u-font-size-7",class:{
      'u-bg-color-blue-base': index === _vm.activeTabIndex,
      'u-color-grey-white': index === _vm.activeTabIndex,
      'u-font-weight-600': index === _vm.activeTabIndex,
      'chip-size-s': _vm.chipSize === 's',
      'chip-size-m': _vm.chipSize === 'm',
      'chip-size-xm': _vm.chipSize === 'xm'
    },on:{"click":function($event){return _vm.handleTabClick(index)}}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: false }),expression:"{ placement: 'top', arrow: false }"}],staticClass:"u-display-flex u-flex-justify-content-center u-flex-align-items-center",attrs:{"title":_vm.activeTabIndex === index ? '' : 'Switch to ' + tab.label + ' view'}},[_c('div',{staticClass:"u-spacing-pv-xs"},[_vm._v(" "+_vm._s(tab.label)+" ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }