<template>
  <chart
    :id="chartConfig.chartId"
    class="u-max-width-100 u-height-100 u-flex-align-items-center"
    :config="computedChartConfig"
    :data="finalChartData"
  />
</template>
<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import * as d3 from 'd3';
export default Vue.extend({
  components: {
    chart
  },
  props: {
    chartData: {
      type: Array,
      required: true,
      default: () => []
    },
    chartConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      finalChartData: []
    };
  },

  computed: {
    computedChartConfig() {
      const {
        showArea,
        showTooltip,
        showPoints,
        showGradrient,
        colors,
        curved,
        size = { height: 300, width: 500 }
      } = this.chartConfig;
      let chartConfig;
      chartConfig = {
        chartOptions: {
          data: {},
          types: {}
        }
      };
      let colorsAndTypes = {
        colors: {},
        types: {}
      };
      colorsAndTypes = this.chartData.reduce((prevVal, curVal, index) => {
        let lineType = curved ? 'spline' : 'line';
        if (showArea && !showGradrient) {
          lineType = 'area' + (curved ? '-' + lineType : '');
        }
        const dataId = curVal[0];
        prevVal.colors[dataId] = colors[dataId];
        prevVal.types[dataId] = lineType;
        if (showArea && showGradrient) {
          prevVal.types['gradient' + dataId] =
            'area' + (curved ? '-' + lineType : '');
          prevVal.colors['gradient' + dataId] = `url(#gradient${dataId})`;
        }
        return prevVal;
      }, colorsAndTypes);
      chartConfig.chartOptions.data.colors = colorsAndTypes.colors;
      chartConfig.chartOptions.data.classes = {};
      this.chartConfig.dashedlines?.forEach((dataId) => {
        if (chartConfig.chartOptions.data.classes) {
          chartConfig.chartOptions.data.classes[dataId] = 'dashed-chart-line';
          chartConfig.chartOptions.data.classes['gradient' + dataId] =
            'dashed-chart-area';
        }
      });
      chartConfig.chartOptions.types = colorsAndTypes.types;
      chartConfig.chartOptions.tooltip = {
        show: showTooltip
      };
      chartConfig.chartOptions.point = showPoints;
      chartConfig.chartOptions.size = size;
      chartConfig.chartOptions.legend = false;
      chartConfig.chartOptions.oninit = () => {
        if (showArea && showGradrient) {
          this.initGradients();
        }
        this.chartConfig.oninit?.();
      };
      if (showArea && showGradrient) {
        this.finalChartData = this.chartData.reduce(
          (prevVal, curVal, index) => {
            const last = [...curVal.slice(1, curVal.length)];
            prevVal.push(curVal, ['gradient' + curVal[0], ...last]);
            return prevVal;
          },
          []
        );
      } else {
        this.finalChartData = this.chartData;
      }
      return chartConfig;
    }
  },
  methods: {
    initGradients() {
      this.chartData.forEach((dataEl) => {
        const dataId = dataEl[0];
        const color = this.chartConfig.colors[dataId];
        const gradientId = 'gradient' + dataId;
        this.gradientGenerator(gradientId, color);
      });
    },
    gradientGenerator(gradientId, strokeColor) {
      const gradient = d3
        .select('svg defs')
        .append('linearGradient')
        .attr('id', gradientId)
        .attr('gradientTransform', 'rotate(90)');
      gradient
        .append('stop')
        .attr('offset', '5%')
        .attr('stop-color', strokeColor)
        .attr('stop-opacity', '0.7');
      gradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#fff')
        .attr('stop-opacity', '0');
    }
  }
});
</script>

<style lang="css">
.c3-target-dashed-chart-line .c3-lines .c3-line {
  stroke-dasharray: 5, 3;
}
.c3-target-dashed-chart-area .c3-lines .c3-line {
  display: none;
}
</style>
