import HttpService from '@/utils/services/http-service';
import UrlFactory from '@/utils/services/url-factory';

const logger = {};
logger.log = (message) => {
  try {
    if (navigator.sendBeacon && typeof navigator.sendBeacon === 'function') {
      try {
        const blob = new Blob(
          [JSON.stringify({ ...message, agent: 'Product' })],
          { type: 'application/json; charset=UTF-8' }
        );
        navigator.sendBeacon(UrlFactory.getFinalURL('EVENT_LOGS'), blob);
        console.log('NR Event sent via beacon', message);
      } catch {
        console.log('Metric logged using HTTP', message);
        HttpService.post('EVENT_LOGS', { ...message, agent: 'Product' });
      }
    } else {
      console.log('Metric logged using HTTP', message);
      HttpService.post('EVENT_LOGS', { ...message, agent: 'Product' });
    }
    if (message) {
      window.FS.event(message?.eventType, message);
    }
  } catch (err) {
    console.error('error in logging', err);
  }
};
export default logger;
