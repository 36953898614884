import {
  adgroupConfig,
  productAndKeywordTargeting,
  biddingStrategySP
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/index.js';

const formStepConfig = () => {
  adgroupConfig.name = 'Products';
  adgroupConfig.header = {
    title: 'What are the Products you would like to add?',
    desc: ['Add Products you want to promote in this campaign.']
  };
  adgroupConfig.breadCrumbTitle = 'Products';

  const stepsConfig = [
    adgroupConfig,
    productAndKeywordTargeting,
    biddingStrategySP
  ];

  return stepsConfig;
};

export default {
  header: {
    title: 'Create Ad Group',
    desc: 'Create Ad Group for a campaign'
  },
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  stepsConfig: formStepConfig()
};
