var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"u-display-flex u-flex-justify-content-space-between u-width-100",class:'u-font-size-' + _vm.fontSize},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center text-width"},[(_vm.colored)?_c('div',{staticClass:"u-spacing-pr-s"},[_c('rb-icon',{staticClass:"u-color-grey-lighter",class:[_vm.computedStyles.legendIconSize, _vm.iconCustomClass],style:({ color: _vm.barBackground }),attrs:{"icon":_vm.legendIcon}})],1):_vm._e(),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"u-overflow-hidden u-display-flex u-flex-align-items-center u-width-100",attrs:{"title":_vm.brand}},[_c('div',{staticClass:"overflow-styles u-display-block"},[_vm._v(" "+_vm._s(_vm.brand)+" ")]),_vm._t("post-brand")],2)]),_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-flex-1 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-start",class:_vm.computedStyles.percentageWidth},[_vm._v(" "+_vm._s(_vm.displayValue)+" ")]),_c('div',{staticClass:"u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start",class:[
        _vm.computedStyles.pvpWidth,
        {
          'u-color-green-base': _vm.pvpDirection === '+',
          'u-color-red-base': _vm.pvpDirection === '-',
          'u-color-grey-lighter': _vm.pvpDirection === ''
        }
      ]},[_c('rb-icon',{staticClass:"u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-center u-spacing-mh-s",class:[
          'rb-icon--' + _vm.pvpIconSize,
          {
            'hide-pvp': _vm.pvp === null
          }
        ],attrs:{"icon":_vm.pvpDirection === '+' || _vm.pvpDirection === ''
            ? 'arrow-up'
            : 'arrow-down'}}),_c('div',{staticClass:"u-flex-align-items-center",class:[
          'u-font-size-' + _vm.pvpFontSize,
          _vm.pvp === null ? 'u-display-none' : 'u-display-flex'
        ]},[_vm._v(" "+_vm._s(_vm.pvp)+"% ")]),(_vm.pvp === null)?_c('div',{staticClass:"u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start"},[_vm._v(" NA ")]):_vm._e()],1)]),(_vm.showCustomTooltip)?_c('customTippy',{attrs:{"id":"customTooltip","icon":_vm.legendTooltip.icon,"pre-text":_vm.legendTooltip.preText,"title":_vm.legendTooltip.title}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }