import Vue from 'vue';
import moment from 'moment-timezone';
import progressTableComponent from '@/components/globals/dataTable/tableComponentsWrapper/progressTableComponent.vue';
import customToolTip from '@/components/widgets/customToolTip';
import { groupBy } from 'lodash';
import utils from '@/utils/helpers/';
import { retailerFiltersMap } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import { eventBus } from '@/utils/services/eventBus';
const units = {};
const map = {};

const getFilterKeyForRetailer = (that) => {
  const retailer = that.$store.getters.getRetailer;
  return retailerFiltersMap[retailer];
};

const getMaxDateDashboard = (that) => {
  let maxDate = that.$store.getters.getMaxDate;
  maxDate = maxDate.budget_planner_dashboard?.max_feed_date || 'NA';
  maxDate =
    maxDate === 'NA' ? maxDate : Vue.options.filters.formattable_date(maxDate);
  return maxDate;
};

const getMaxFeedData = (that) => {
  const maxDate =
    that.$store.getters.getMaxDate.budget_planner_manual_actions?.max_feed_date;
  const formatedDate = moment(maxDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  return formatedDate;
};

const monthToDateProgressTableComponent = Vue.extend({
  components: {
    progressTableComponent,
    customToolTip
  },
  computed: {
    mtdProfilesTooltipContent() {
      // mtd --> month to date.
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBudgetPlanMTDProfilesTooltipContent'
      ];
    },
    tooltipContent() {
      const content = {
        title: {
          pre: "Here's what we have observed with this profile",
          mid: null,
          suff: null
        },
        show: false, // show is decided based on internal user and if description provided by ds team is not empty.
        description: []
      };

      const enableExplainability = this.isExplainabilityEnabled();

      if (!enableExplainability) {
        return content;
      }

      if (this.params.data.entity_type === 'PROFILE') {
        const entity_id = this.params.data.entity_id.toString();
        const profile = this.findMatchingProfile(entity_id);

        if (profile && this.isBudgetRecommendationNeeded(profile)) {
          content.title = this.getBudgetRecommendationTitle(profile);
        }

        this.computeDescription(content, profile);
      }
      return content;
    }
  },
  methods: {
    isExplainabilityEnabled() {
      return (
        Vue.options.filters.config_check(
          'feature.budget_planner.explainability.enable_external'
        ) || utils.internalUserCheck(window.user)
      );
    },
    findMatchingProfile(entity_id) {
      const profilesData = groupBy(
        this.mtdProfilesTooltipContent,
        'profile_id'
      );
      return profilesData?.[entity_id]?.[0] || null;
    },
    isBudgetRecommendationNeeded(profile) {
      return (
        profile.today_plan &&
        profile.yesterday_plan &&
        parseInt(profile.today_plan) - parseInt(profile.yesterday_plan) !== 0
      );
    },
    getBudgetRecommendationTitle(profile) {
      return {
        pre: 'We recommend',
        mid: `${
          parseInt(profile.today_plan) > parseInt(profile.yesterday_plan)
            ? 'increasing'
            : 'decreasing'
        } the daily budget of the profile to ${Vue.options.filters.num_format(
          profile.today_plan,
          'currency'
        )}`,
        suff: 'because of the following factors:'
      };
    },
    computeDescription(content, profile) {
      if (profile?.tooltip_description) {
        let desc = JSON.parse(profile.tooltip_description);
        desc = desc.filter((item) => item.length > 0);
        content.description = desc;
        content.show = desc.length > 0;
      }
    }
  },
  template: `
    <div class="u-width-100  u-display-flex u-height-100">

      <progressTableComponent :wrappedInParent="true">

        <div slot="customComponent">
          <customToolTip v-if="tooltipContent.show">
            <div slot="toolTipData">
              <div class="u-max-width-480px">
                <div class="u-spacing-pl-l u-spacing-pt-m u-spacing-pb-s u-font-weight-600 u-font-size-2"><span class="u-color-blue-base">IQ</span> Tip</div>
                <div
                  class="u-font-weight-400 u-line-height-1-3 u-color-grey-light u-spacing-ml-l u-spacing-mr-m u-spacing-mb-m u-font-size-5"
                >
                    {{tooltipContent.title.pre}}
                    <span class=" u-font-weight-600 " v-if="tooltipContent.title.mid"> "{{tooltipContent.title.mid}}" </span>
                    <span v-if="tooltipContent.title.suff">{{tooltipContent.title.suff}}</span>
                </div>
                <div class="u-font-size-6 u-font-weight-normal u-color-grey-base">
                  <div
                    v-for="(item, key) in tooltipContent.description"
                    class="u-display-flex u-spacing-ml-xs"
                    :key="key"
                    :class="{'u-spacing-mb-m': item.length > 0}"
                  >
                    <div v-if="item.length > 0" class="u-spacing-mr-m u-display-flex u-flex-align-items-flex-start u-width-100">
                      <div
                        class="u-bullet-points u-flex-none u-border-radius-l u-bg-color-blue-base u-spacing-ml-s u-spacing-mt-xs"
                      ></div>
                      <span class="u-spacing-pl-s u-line-height-1-3">{{item}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div slot="body" slot-scope="{tippyConfig}">
              <div class="u-spacing-mr-s budgetPlanIQTipRowHover" v-if="tooltipContent.show">
                <div>
                  <rb-icon
                    v-tippy="tippyConfig"
                    class="u-cursor-pointer rb-icon--xx-medium u-color-blue-base"
                    icon="light-bulb"
                  />
                </div>
              </div>
            </div>
          </customToolTip>
        </div>

      </progressTableComponent>

    </div>
  `
});

const customStringComponent = Vue.extend({
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            offset: {
              offset: '30px, 4px'
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    dashboardTable() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBudgetPlannerDashboardTable'
      ];
    },
    validateEntity() {
      let isValid = true;
      const disableForRetailers = ['walmart', 'target', 'instacart'];
      const retailer = this.$store.getters.getRetailer;
      if (
        disableForRetailers.includes(retailer) ||
        this.params.data.level1 === 'ENTIRE_BUSINESS'
      ) {
        isValid = false;
      }
      return isValid;
    },
    paddingLeft() {
      let padding = '0';
      if (this.params.data.level1 && !this.params.data.level2) {
        padding = '16';
      } else {
        padding = '32';
      }

      if (
        this.params.data.level1 === this.params.data.level2 &&
        this.params.data.level1 === 'ENTIRE_BUSINESS'
      ) {
        padding = '0';
      }

      return `padding-left: ${padding}px;`;
    },
    checkIfUnctegorised() {
      return (
        this.cellValue.toLowerCase() === 'Uncategorized'.toLocaleLowerCase()
      );
    },
    cellValue() {
      if (!this.params.data.level_id) {
        return 'Entire business';
      }
      if (
        this.params.data.level_id &&
        !this.params.data.level_id.includes(':')
      ) {
        return this.params.data.level1;
      }
      if (this.params.data.level_id.includes(':')) {
        return this.params.data.level2;
      }
      return 'NA';
    }
  },
  methods: {
    openUncategorisedSKUPanel() {
      eventBus.$emit('openBudgetPlannerUncategorized');
    },
    omniBudgetRouteTo() {
      let query = {};
      const level1 = this.params.data.level1;
      const level2 = this.params.data.level2;
      if (level1) {
        query.level1 = level1;
      }
      if (level2) {
        query.level2 = level2;
      }
      this.$router.push({ name: 'budget-plan-campaign-budget-v2', query });
    }
  },
  template: `
  <div :style="paddingLeft" class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left u-text-overflow-ellipsis u-flex-align-items-center">
    <div
    class="u-flex-align-items-center u-display-flex"
    >
      <span :title="cellValue" v-tippy="tippy">{{cellValue}}</span>
      <rb-icon
        v-if="checkIfUnctegorised"
        class="rb-icon--medium u-color-orange-base u-spacing-ml-xs"
        :icon="'warning'"
        v-tippy="tippy"
        :title="'SKUs under this category are not mapped to any brand'"
      />
      <div v-if="checkIfUnctegorised" class="optimize-budget-plan cellHover u-width-120px u-font-weight-600 u-spacing-pl-m u-font-size-7 u-color-blue-base" @click="openUncategorisedSKUPanel">
      <span class="u-spacing-ml-xs hover-span u-text-align-center">View SKUs</span>  
      </div>
      <div v-else-if="validateEntity" class="optimize-budget-plan cellHover u-width-120px u-font-weight-600 u-spacing-pl-m u-font-size-7 u-color-blue-base" @click="omniBudgetRouteTo"> 
        <span class="u-spacing-ml-xs hover-span u-text-align-center">Optimize budget</span>
      </div>
      </div>
    </div>
  </div>`
});

const customNumericComponent = Vue.extend({
  computed: {
    cellValue() {
      const val = this.params.data[this.params.key]?.toFixed(2) || null;
      return val ? `${val}%` : '---';
    }
  },
  template:
    '<div class="u-width-100 u-height-100 u-flex-justify-content-flex-end u-spacing-p-m u-color-grey-base u-display-flex u-text-overflow-ellipsis">{{cellValue}}</div>'
});

const progressHeaderComponent = Vue.extend({
  computed: {
    name() {
      return this.params.displayName || 'NA';
    }
  },
  template:
    '<div class="u-display-inline-flex u-height-100 u-flex-justify-content-flex-end u-width-100 u-flex-align-items-center reverse-content"><div class="budget-planner-custom-header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600"><div style="font-size:14px" class="budget-planner-custom-text-label u-color-grey-lighter reverse-content" :class="name !== `Scope` ? `u-text-align-center` : ``">{{name}}<span style="font-size:11px" v-if="name !== `Scope`"><br>Spend / Budget</span></div></div></div>'
});

const customHeaderComponent = Vue.extend({
  computed: {
    name() {
      return this.params.displayName || 'NA';
    },
    range() {
      const filterKey = getFilterKeyForRetailer(this);
      const budgetPlannerFilter = JSON.parse(
        localStorage.getItem(filterKey) || '{}'
      );
      const from = budgetPlannerFilter.date_range?.from || null;
      const to = budgetPlannerFilter.date_range?.to || null;
      return `${moment(from, 'YYYY-MM-DD').format('ll')} - ${moment(
        to,
        'YYYY-MM-DD'
      ).format('ll')}`;
    }
  },
  template:
    '<div class="u-display-inline-flex u-height-100 u-flex-justify-content-flex-end u-width-100 u-flex-align-items-center reverse-content"><div class="budget-planner-custom-header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600"><div style="font-size:14px" class="budget-planner-custom-text-label u-color-grey-lighter reverse-content u-text-align-right">{{name}}<br><span style="font-size:11px">{{range}}</span></div></div></div>'
});

const columns = [
  {
    key: 'dimension_name',
    name: 'Entity Type',
    mode: 'download',
    order: 0,
    showOnUI: true
  },
  {
    key: 'dimension_name',
    name: 'Scope',
    order: 1,
    showOnUI: true,
    pinned: 'left',
    mode: 'common',
    type: 'CUSTOM_STRING'
  },
  {
    key: 'month_to_date',
    name: 'Month to date',
    order: 2,
    mode: 'column',
    showOnUI: true,
    type: 'PROGRESS',
    keys: {
      keyOne: 'actual_spend_month_to_date',
      keyTwo: 'planned_budget_month_to_date',
      greaterThanTextColor: '#d7263d',
      lesserThanTextColor: '#3ea95e',
      lesserThanIcon: 'sort-desc',
      greaterThanIcon: 'sort-asc'
    }
  },
  {
    key: 'quarter_to_date',
    name: 'Quarter to date',
    order: 3,
    mode: 'column',
    showOnUI: true,
    type: 'PROGRESS',
    keys: {
      keyOne: 'actual_spend_quarter_to_date',
      keyTwo: 'planned_budget_quarter_to_date',
      greaterThanTextColor: '#d7263d',
      lesserThanTextColor: '#3ea95e',
      lesserThanIcon: 'sort-desc',
      greaterThanIcon: 'sort-asc',
      equalToColor: '#fafafa',
      equalToTextColor: '#8b8f93'
    }
  },
  {
    key: 'year_to_date',
    name: 'Year to date',
    order: 4,
    mode: 'column',
    showOnUI: true,
    type: 'PROGRESS',
    keys: {
      keyOne: 'actual_spend_year_to_date',
      keyTwo: 'planned_budget_year_to_date',
      greaterThanTextColor: '#d7263d',
      lesserThanTextColor: '#3ea95e',
      lesserThanIcon: 'sort-desc',
      greaterThanIcon: 'sort-asc',
      equalToColor: '#fafafa',
      equalToTextColor: '#8b8f93'
    }
  },
  {
    key: 'planned_budget_month_to_date',
    name: 'Planned budget month to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'actual_spend_month_to_date',
    name: 'Actual spend month to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'planned_budget_quarter_to_date',
    name: 'Planned budget quarter to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'actual_spend_quarter_to_date',
    name: 'Actual spend quarter to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'planned_budget_year_to_date',
    name: 'Planned budget year to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'actual_spend_year_to_date',
    name: 'Actual spend year to date',
    mode: 'download',
    showOnUI: true
  },
  {
    key: 'spend_vs_pcogs',
    name: 'Spend/PCOGS',
    order: 7,
    mode: 'common',
    showOnUI: true,
    type: 'custom'
  },
  {
    key: 'spend_vs_budget',
    name: 'Spend/Budget',
    order: 8,
    mode: 'common',
    showOnUI: true,
    type: 'custom'
  }
];
function buildColDefObject(col) {
  const obj = {};
  obj.headerComponentFramework = progressHeaderComponent;
  obj.field = col.key;
  obj.headerName = col.name;
  obj.title = col.name;
  obj.order = col.order;
  obj.pinned = col.pinned;
  obj.dataType = col.type;
  obj.minWidth = 150;
  if (col.key === 'year_to_date' || col.key === 'quarter_to_date') {
    obj.cellRendererFramework = 'progressTableComponent';
    obj.cellRendererParams = col;
    obj.minWidth = 225;
  }
  if (col.key === 'month_to_date') {
    obj.cellRendererFramework = monthToDateProgressTableComponent;
    obj.cellRendererParams = col;
    obj.minWidth = 225;
  }
  if (col.type === 'CUSTOM_STRING') {
    obj.cellRendererFramework = customStringComponent;
    obj.cellRendererParams = col;
    obj.minWidth = 250;
  }
  if (col.key === 'spend_vs_pcogs' || col.key === 'spend_vs_budget') {
    delete obj.headerComponentFramework;
    obj.headerComponentFramework = customHeaderComponent;
    obj.cellRendererFramework = customNumericComponent;
    obj.cellRendererParams = col;
    obj.minWidth = 200;
  }
  return obj;
}
function getColumnDefinition(vueRef, mode) {
  // templateId = vueRef.selectedTemplate.ID
  const _columnDefs = [];
  let columnDefs = [];
  // Pushing edit column always
  for (let col of columns) {
    if (!col.showOnUI) {
      continue;
    }
    const obj = buildColDefObject(col);
    const PCOGS_DISABLED_RETAILERS = ['target', 'instacart'];
    if (
      col.key === 'spend_vs_pcogs' &&
      PCOGS_DISABLED_RETAILERS.includes(vueRef.$store.getters.getRetailer)
    ) {
      col.showOnUI = false;
    }
    if (col.mode === 'common' || col.mode === mode) {
      _columnDefs.push(obj);
    }
  }
  // /**
  //  * Sort based on key order
  //  */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.order - b.order;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      filters: {
        emit: 'budgetPlannerFiltersApplied',
        hasSearch: true,
        filterKey: getFilterKeyForRetailer(that),
        search: {
          width: '240px',
          enable: true,
          emit: 'budgetPlannerSearchTriggered',
          placeholder: 'Search for profiles and portfolios'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'budgetPlannerChart',
            axisFormatMap: units,
            action: 'budgetPlannerDashboardV2/dashboardChartDataBP',
            listen: {
              budgetPlannerFiltersApplied: {
                action: 'budgetPlannerDashboardV2/dashboardChartDataBP',
                transform: function (config, data, that) {
                  return config;
                }
              },
              budgetPlannerSearchTriggered: {
                action: 'budgetPlannerDashboardV2/dashboardChartDataBP',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            getMaxDateDashboard: getMaxDateDashboard(that),
            date: 'budget_planner_dashboard'
          },
          body: {
            retailer: {
              amazon: {
                APIConfig: {
                  cubeName: 'budget_planner_dashboard_v2',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesDimension: 'feed_date',
                  timeseriesEnabled: true,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'planned_budget_sum',
                    'planned_budget_cumulative',
                    'actual_spend_sum',
                    'actual_spend_cumulative',
                    'spend_vs_budget',
                    'spend_vs_pcogs'
                  ],
                  groupByDimensionsList: ['feed_date', 'max_report_date'],
                  orderByList: [],
                  where: {
                    dimensionNameValueList: []
                  },
                  customAPIDecisionVars: {
                    skipTimeSeriesMetric: true,
                    system: 'budget_optimizer_omni'
                  }
                }
              }
            },

            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                show_axis_colors: false,
                tooltip_format: {
                  'Ad Spend': { pre: 'currency', roundoff: 2 },
                  'Planned Budget': { pre: 'currency', roundoff: 2 },
                  'Spend / PCOGS': { suff: '%' },
                  'Spend / Budget': { suff: '%' }
                },
                axes: {
                  'Planned Budget': 'y',
                  'Ad Spend': 'y',
                  'Spend / PCOGS': 'y2',
                  'Spend / Budget': 'y2'
                },
                xDataKey: 'x',
                grid: 'xy',
                line: {
                  connectNull: false
                },
                point_format: {
                  'Ad Spend': () => that.pointFormatValue,
                  'Planned Budget': () => that.pointFormatValue,
                  'Spend / PCOGS': () => that.pointFormatValue,
                  'Spend / Budget': () => that.pointFormatValue
                }
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'budgetPlannerDashboardTable',
            primaryKey: 'campaign_id',
            action:
              'budgetPlannerDashboardV2/budgetPlannerSummaryColletiveData',
            listen: {
              budgetPlannerFiltersApplied: {
                action:
                  'budgetPlannerDashboardV2/budgetPlannerSummaryColletiveData',
                transform: function (config) {
                  return config;
                }
              },
              budgetPlannerSearchTriggered: {
                action:
                  'budgetPlannerDashboardV2/budgetPlannerSummaryColletiveData',
                transform: function (config, data, that) {
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            search: false,
            title: 'BUDGET SUMMARY'
          },
          footer: {
            getMaxDateDashboard: getMaxDateDashboard(that),
            date: 'budget_planner_dashboard'
          },
          body: {
            profileDescriptionAPIConfig: {
              cubeName: 'budget_planner_dashboard_profile_description',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'tooltip_description',
                'today_plan',
                'yesterday_plan'
              ],
              groupByDimensionsList: ['profile_id'],
              where: {
                dimensionNameValueList: [
                  {
                    dimensionName: 'feed_date',
                    dimensionValue: getMaxFeedData(that)
                  }
                ]
              }
            },
            retailer: {
              amazon: {
                APIConfig: {
                  cubeName: 'budget_planner_summary_consolidation_v2',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'planned_budget_year_to_date',
                    'actual_spend_year_to_date',
                    'planned_budget_quarter_to_date',
                    'actual_spend_quarter_to_date',
                    'planned_budget_month_to_date',
                    'actual_spend_month_to_date',
                    'spend_vs_budget',
                    'spend_vs_pcogs'
                  ],
                  groupByDimensionsList: [
                    'level_id',
                    'level_type',
                    'level1',
                    'level2'
                  ],
                  customAPIDecisionVars: {
                    system: 'budget_optimizer_omni'
                  },
                  orderByList: [],
                  where: {
                    dimensionNameValueList: []
                  }
                }
              }
            },
            download: {
              fileName: 'DashboardSummary',
              columnMap: getColumnDefinition(that, 'download'),
              action: 'budgetPlannerDashboardV2/downloadDashboardTable'
            },
            gridOptions: {
              context: {
                componentParent: this
              }
            },
            columnDefs: getColumnDefinition(that, 'column')
          }
        },
        widget3: {
          meta: {
            type: 'custom',
            key: 'budgetPlannerDashboardActions',
            primaryKey: 'campaign_id',
            action: 'budgetPlannerDashboardV2/budgetPlannerDashboardActions',
            listen: {}
          },
          header: {
            show: true,
            search: false,
            title: 'ACTIONS TO PACE THE SPEND AS PER PLAN',
            tooltip: ''
          },
          footer: {
            date: 'budget_planner_manual_actions'
          },
          body: {
            cubesToCall: [
              'ams_manual_actions_campaign_budget_change',
              'ams_manual_actions_keywords_bid_change'
            ],
            APIConfig: {
              cubeName: 'ams_manual_actions_campaign_budget_change',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: ['daily_count', 'count_details'],
              groupByDimensionsList: ['status'],
              orderByList: [],
              where: {
                dimensionNameValueList: [
                  {
                    dimensionName: 'feed_date',
                    dimensionValue: getMaxFeedData(that)
                  }
                ]
              }
            }
          }
        }
      }
    };
  }
};
