var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-spacing-mb-m u-spacing-ph-l u-bg-color-grey-white sticky u-header-box-shadow",staticStyle:{"z-index":"100"}},[_c('rb-filter-panel',{attrs:{"data":_vm.filterData,"primary":_vm.primaryFilterData,"secondary-data":_vm.secondaryFilterData,"listener-event":'po-fillrate-filters',"state":_vm.filterState,"loader-status":_vm.loaderStatus,"config":_vm.config.filters,"emit":_vm.config.filters.emit,"has-search":false,"new-date":true,"allow-date-compare":true,"max-date":_vm.getPoFillPageWiseMinMaxKey,"filter-v2":_vm.filterV2,"show-ingestion-progress":_vm.filterV2,"enable-remove-all":false,"default-date-post-range":_vm.defaultDatePostRange,"additional-ranges":_vm.additionalRanges,"date-mode":_vm.dateMode,"on-create":_vm.onCreate,"disable-between-operator":_vm.disableBetweenOperator}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs",attrs:{"slot":"leftSide-searchPanel"},slot:"leftSide-searchPanel"},[_c('PageTitle',{attrs:{"title":'PO Fill Rate'}})],1)]),(_vm.showActiveFilterBanner)?_c('ActiveFilterBanner',{attrs:{"icon-name":"info-circle-fill","filter-instance":_vm.filterInstance,"apply-negative-margin":true}}):_vm._e()],1),_c('section',{staticClass:"u-envelop-width u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-wrap-yes u-spacing-mh-m"},[_c('card-widget',{staticClass:"u-spacing-mr-m u-min-width-25",attrs:{"config":_vm.config.widgets['widget1'],"data":_vm.getInsightsPOFillRateData[_vm.config.widgets['widget1'].meta.key] || {},"data-cy":"PO-Fill-Rate-Card"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('metric',{staticClass:"u-display-inline-flex u-spacing-mh-l u-spacing-mb-m",attrs:{"size":'xxl',"config":_vm.config.widgets['widget1'].body.tagConfig,"data":(
                _vm.getInsightsPOFillRateData[
                  _vm.config.widgets['widget1'].meta.key
                ] || {}
              ).metrics || {}}})],1)]),_c('card-widget',{staticClass:"u-min-width-30 u-spacing-mr-m",attrs:{"config":_vm.config.widgets['widget2'],"data":_vm.getInsightsPOFillRateData[_vm.config.widgets['widget2'].meta.key] || {},"data-cy":"Unfulfilled-Revenue"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('metric',{staticClass:"u-display-inline-flex u-spacing-mh-l u-spacing-mb-m",attrs:{"size":'xxl',"config":_vm.config.widgets['widget2'].body.tagConfig,"data":(
                _vm.getInsightsPOFillRateData[
                  _vm.config.widgets['widget2'].meta.key
                ] || {}
              ).metrics || {}}})],1)]),_c('card-widget',{staticClass:"u-flex-1",attrs:{"config":_vm.config.widgets['widget3'],"data":_vm.getInsightsPOFillRateData[_vm.config.widgets['widget3'].meta.key] || {}}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-flex-1 u-display-flex u-flex-direction-column u-spacing-ph-l u-flex-justify-content-flex-end"},[_vm._l(((
                _vm.getInsightsPOFillRateData[
                  _vm.config.widgets['widget3'].meta.key
                ] || {}
              ).data),function(i,index){return _c('div',{staticClass:"u-border-radius-l u-spacing-mb-s",style:({
                background: _vm.getColorPattern[index + 1],
                height: '8px',
                width: i['perc'] * 100 + '%'
              })})}),_c('div',{staticClass:"u-spacing-mv-s"},_vm._l(((
                  _vm.getInsightsPOFillRateData[
                    _vm.config.widgets['widget3'].meta.key
                  ] || {}
                ).data),function(i,index){return _c('span',{staticClass:"u-display-inline-flex u-flex-align-items-center u-font-size-5",class:{ 'u-spacing-pl-s': index !== 0 }},[_c('rb-icon',{style:({ color: _vm.getColorPattern[index + 1] }),attrs:{"icon":'dot'}}),_c('span',[_vm._v(_vm._s(i.text)),_c('span',[_vm._v(" ( "+_vm._s(_vm._f("num_format")(i.units))+" )")])])],1)}),0)],2)])])],1),_c('div',{staticClass:"u-display-flex u-flex-wrap-yes u-spacing-mh-m"},[_c('card-widget',{staticClass:"u-flex-1 u-overflow-auto u-spacing-mv-m",attrs:{"config":_vm.config.widgets['widget4'],"data":_vm.getInsightsPOFillRateData[_vm.config.widgets['widget4'].meta.key] || {},"data-cy":"SKU-Level-Summary"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[_c('rb-insights-table',{ref:"SKU_Level_Summary_Table",staticStyle:{"width":"100%"},attrs:{"data-c-y-i-d":"SKU-Level-Summary","config":_vm.config.widgets['widget4'],"grid-options":_vm.widget4GridOptions,"table-row":(
                  _vm.getInsightsPOFillRateData[
                    _vm.config.widgets['widget4'].meta.key
                  ] || {}
                ).rows,"table-column":_vm.config.widgets['widget4'].body.columnDefs,"row-height":80,"enable-client-side-sorting":false,"enable-server-side-sorting":true,"pagination":true,"pagination-total-key":(
                  _vm.getInsightsPOFillRateData[
                    _vm.config.widgets['widget4'].meta.key
                  ] || {}
                ).totalRows,"pagination-per-page-limit":_vm.config.widgets['widget4'].body.APIConfig.limit,"download-this-table":true}})],1)])])],1),_c('div',{staticClass:"u-display-flex u-flex-wrap-yes u-spacing-mh-m"},[_c('card-widget',{staticClass:"u-flex-1 u-spacing-mb-m u-width-100",attrs:{"config":_vm.config.widgets['widget5'],"data":_vm.getInsightsPOFillRateData[_vm.config.widgets['widget5'].meta.key] || {}}},[_c('div',{staticClass:"u-min-height-100",attrs:{"slot":"body"},slot:"body"},[(
              (
                _vm.getInsightsPOFillRateData[
                  _vm.config.widgets['widget5'].meta.key
                ] || {}
              ).load === false && _vm.selectedAsin
            )?_c('rb-select',{staticClass:"u-spacing-mh-m u-spacing-mb-m u-width-80 asin_select",attrs:{"search-enabled":true,"client-search":false,"search-listener":_vm.asinListener,"width":'400px',"send-details":true,"on-close":_vm.asinSelected,"options":_vm.getInsightsPOFillFilterValues['asin'],"context":_vm.config.widgets['widget5']},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"media__img u-flex-none",staticStyle:{"height":"48px","width":"48px","margin":"0px"}},[_c('img',{staticStyle:{"height":"48px","width":"48px","margin":"0px"},attrs:{"src":option.image_url}})]),_c('div',{staticClass:"u-spacing-ml-m u-overflow-hidden"},[_c('p',{staticClass:"u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"},[_vm._v(" "+_vm._s(option.title)+" ")]),_c('p',{staticClass:"u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"},[_vm._v(" "+_vm._s(option.asin)+" ")])])])]}}],null,false,3361912505)},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('SkuComponentWithBadge',{attrs:{"image-src":_vm.selectedAsin.image_url,"asin-id":_vm.selectedAsin.asin,"asin-title":_vm.selectedAsin.title}})],1)]):_vm._e(),_c('div',[_c('rb-insights-table',{ref:"PO_Details_Table",staticStyle:{"width":"100%"},attrs:{"config":_vm.widget5Config,"grid-options":_vm.widget5GridOptions,"table-row":(_vm.getInsightsPOFillRateData[_vm.widget5Config.meta.key] || {}).rows,"table-column":_vm.widget5Config.body.columnDefs,"get-current-instance":_vm.widget5RbInsightsTableInstanceFunc,"row-height":45,"enable-client-side-sorting":false,"enable-server-side-sorting":true,"pagination":true,"pagination-total-key":(_vm.getInsightsPOFillRateData[_vm.widget5Config.meta.key] || {})
                  .totalRows,"pagination-per-page-limit":_vm.widget5Config.body.APIConfig.limit,"download-this-table":true}})],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }