<template>
  <span @click="$emit('click', $event)">
    <div v-if="entityProcessiongStatus">
      <el-tooltip
        effect="dark"
        :content="tooltipText"
        placement="right"
      >
        <rb-icon
          class="u-color-orange-base u-cursor-pointer"
          icon="in-progress-dotted"
        />
      </el-tooltip>
    </div>
  </span>
</template>

<script>
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
export default {
  props: {
    dependentEntities: {
      type: Array,
      default() {
        return [EntitiesForPostProcessingStatus.catalog];
      }
    },
    tooltipText: {
      type: String,
      default:
        'Internal Taxonomy updation or creation is in progress. It will take upto 3 hours for the changes to reflect.'
    }
  },
  computed: {
    entityProcessiongStatus() {
      return this.$store.getters.getEntityPostProcessingStatus(
        this.dependentEntities
      );
    }
  }
};
</script>

<style></style>
