<template>
  <section
    class="u-display-flex u-position-relative u-spacing-p-m budget-box-shadow"
  >
    <loader
      v-if="loadCards"
      class="fill--parent"
      :color="'#3fadf7'"
    />
    <div
      v-for="item in cardsList"
      :key="item.label"
      class="u-position-relative u-border-radius-s u-spacing-mr-l"
    >
      <div class="u-display-flex">
        <span
          class="u-font-size-5 u-font-weight-400 u-color-grey-lighter u-spacing-pr-xs"
          data-cy="BudgetCardlabel"
          >{{ item.label }}</span
        >
        <span
          class="u-font-size-5 u-font-weight-600"
          data-cy="budgetLabelValue"
          >{{ formatterFn(item.value, item.unit, undefined, 0) }}</span
        >
      </div>
    </div>
  </section>
</template>
<script>
import { formatter } from '@/utils/helpers/formatter.js';
import loader from '@/components/basic/loader';
export default {
  components: {
    loader
  },
  props: {
    loadCards: {
      type: Boolean,
      default: false
    },
    cardsList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    formatterFn: formatter
  })
};
</script>
<style lang="css" scoped>
.budget-box-shadow {
  box-shadow: 0px 0px 4px #caccce;
}
</style>
