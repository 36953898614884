<template>
  <section v-if="config.type === 'hierarchyPopup'">
    <section
      v-if="shelfHierarchy"
      v-tippy="tippyOptions"
    >
      <ConfigIcon
        :config="config"
        class="hierarchy-popup"
      />
      <HierarchyPopup
        :id="'popup' + _uid"
        title="Hierarchy"
        icon="hierarchy"
        class="tippy-drop-down u-border-radius-s"
        :split-hierarchy="shelfHierarchy.result"
      />
    </section>
  </section>
  <section v-else-if="config.type === 'iconDropdown'">
    <rb-select
      :send-details="true"
      :options="config.dropdown.options"
      :on-close="handleDropdownSelect"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <ConfigIcon :config="config" />
      </div>
    </rb-select>
  </section>
  <section
    v-else
    class="u-cursor-pointer u-display-flex u-flex-align-items-center"
    @click="handleClick"
  >
    <ConfigIcon :config="config" />
  </section>
</template>
<script>
import HierarchyPopup from '../atoms/hierarchy-popup.vue';
import ConfigIcon from '../atoms/config-icon.vue';
export default {
  components: {
    HierarchyPopup,
    ConfigIcon
  },
  props: {
    config: {
      type: Object,
      default: null
    },
    shelfHierarchy: {
      type: Object,
      default: null
    }
  },
  computed: {
    tippyOptions() {
      return {
        html: '#' + 'popup' + this._uid,
        ...this.config.tooltip.tippyOptions
      };
    }
  },
  methods: {
    handleClick() {
      if (this.config.click) {
        this.$emit(this.config.click.emit);
      }
    },
    handleDropdownSelect(context, details) {
      const payload = { context, details };
      this.$emit('optionSelect', payload);
    }
  }
};
</script>

<style lang="css" scoped>
.hierarchy-popup :hover {
  color: #007cf6;
}
</style>
