<template>
  <section
    class="generic-campaign-management"
    :class="containerClass"
  >
    <messageBar
      :show="showBlueBar"
      :page-type="messageBarPageType"
      @hideAction="hideBlueBar"
    />
    <div v-if="openActivity">
      <ActivityLog
        :data="AcitivityLogDataService.data"
        :page-name="activityLogPage"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <modal
      v-if="showPopup"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div
      class="sticky-panel u-display-flex u-flex-direction-column u-bg-color-grey-white sticky u-header-box-shadow"
      style="z-index: 99"
    >
      <div class="u-spacing-ph-l">
        <rb-filter-panel
          :id="config.filters.id"
          :key="filterKey"
          :page="config.filters.page"
          :data="filterData"
          :primary="primaryFilterData"
          :secondary-data="secondaryFilterData"
          :listener-event="config.filters.listenerEvent"
          :state="config.filters.state"
          :emit="config.filters.config.emit"
          :allow-date-compare="true"
          :enable-save="true"
          :min-max-date="minMaxDate"
          :config="config.filters.config"
          :has-search="true"
          :loader-status="loaderStatus"
          :new-date="true"
          :additional-ranges="config.filters.additionalRanges"
          :default-date-post-range="'last30Days'"
          :on-create="onCreate"
          :filter-v2="filterV2"
          :show-ingestion-progress="filterV2"
          :ingestion-dependent-entities="ingestionDependentEntities"
          :common-date-key="config.filters.commonDateKey"
          @savedFilterApplied="filterKey++"
          @filterMounted="handleFilterMount"
          @filterBeforeDestroy="handleFilterBeforeDestroy"
        >
          <div
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
          >
            <PageTitle :title="config.bookmark.title" />
          </div>
          <span
            slot="search"
            class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
          >
            <BulkSearch
              bulk-search-ref="entity"
              :bulk-search-props="bulkSearchProps"
              :standard-search-config="config.filters.config.search"
            />
          </span>
        </rb-filter-panel>
      </div>
      <div>
        <div
          v-if="showFailedCampaignsBanner"
          class="u-spacing-mt-m"
        >
          <FailedCampaignsBanner
            :show-cta="false"
            :show-close-icon="true"
            :icon-name="'error-fill'"
            :custom-class="config.failedCampaignsBanner(this).customClass"
            v-on="config.failedCampaignsBanner(this).events"
          >
            <div
              slot="customBannerTextSlot"
              class="u-font-size-5 u-line-height-1-2"
            >
              <span class="u-font-weight-600 u-spacing-mr-16px"
                >{{ showFailedCampaignsBannerText }}
              </span>
              <span
                v-for="item in failedCampaignsArray"
                :key="item.campaign_id"
                style="
                  text-decoration: underline;
                  text-decoration-style: dotted;
                "
                class="u-cursor-pointer u-spacing-mr-16px"
                @click="openFailedCampaign(item)"
              >
                {{ `${item.campaign_name}` }}
              </span>
            </div>
          </FailedCampaignsBanner>
        </div>
        <div
          v-if="componentBelowFilter && componentBelowFilter.show"
          class="u-spacing-mt-m"
        >
          <component
            :is="componentBelowFilter.ui_component"
            v-if="componentBelowFilter && componentBelowFilter.show"
            v-bind="componentBelowFilter.props"
            :class="componentBelowFilter.customClass"
            v-on="componentBelowFilter.events"
          />
        </div>
        <div
          v-if="systemTransparencyBanner && systemTransparencyBanner.show"
          class="u-spacing-mt-m"
        >
          <component
            :is="systemTransparencyBanner.ui_component"
            v-bind="systemTransparencyBanner.props"
          />
        </div>
      </div>
    </div>
    <ChartWithLegendsWidget
      v-if="filterMounted"
      ref="chartWidget"
      class="u-spacing-mh-m u-spacing-mb-m"
      :data-service="ChartWithLegendsDataService"
      :config="config.widget1"
      :global-where-clause="widget1WhereClause"
      :max-date="lastUpdatedDate"
      :plot-row-data="plotRowData"
      @removePlottedEntity="handleRemovePlottedEntity"
    />
    <TableWidget
      v-if="filterMounted"
      ref="tableWidget"
      :data-service="TableDataService"
      :config="config.widget2"
      :page="config.filters.page"
      :max-date="lastUpdatedDate"
      :global-where-clause="globalWhereClause"
      :show-plot-rows-chip="showPlotRowsChip"
      :filter-instance="filterInstance"
      @plotRowData="handlePlotRows"
      @showModal="showModal"
      @updateModalConfig="handleUpdateModalConfig"
      @openActivityLog="openSidePanel"
      @showBlueBar="handleBlueBar"
    />
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="entities-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </section>
</template>

<script>
import widgetsUtil from '@/components/widgetMixin';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import ChartWithLegendsDataService from '@/pages/campaign-management/services/chart-with-legends-data-service.js';
import TableDataService from '@/pages/campaign-management/services/table-data-service.js';
import AcitivityLogDataService from '@/pages/campaign-management/services/activity-log-data-service.js';
import ChartWithLegendsWidget from '@/pages/campaign-management/widgets/chart-with-legends-widget.vue';
import TableWidget from '@/pages/campaign-management/widgets/table-widget.vue';
import { cloneDeep, get, isEqual } from 'lodash';
import moment from 'moment';
import * as modalMaps from '@/pages/campaign-management/modals-map.js';
import modal from '@/components/widgets/modal.vue';
import ActivityLog from '@/components/widgets/collaboration/collaboration.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import FailedCampaignsBanner from '@/pages/entity_details/common_components/banner.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';

export default {
  components: {
    rbFilterPanel,
    ChartWithLegendsWidget,
    TableWidget,
    modal,
    ActivityLog,
    messageBar,
    FailedCampaignsBanner,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    SystemTransparencyBanner
  },
  mixins: [widgetsUtil],
  props: {
    config: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      plotRowData: null,
      activityLogPage: null,
      messageBarPageType: null,
      showBlueBar: false,
      openActivity: false,
      modalConfig: {},
      showPopup: false,
      filterMounted: false,
      searchInput: null,
      filterInstance: null,
      filterKey: 0,
      ChartWithLegendsDataService:
        this.config.widget1.dataService ?? new ChartWithLegendsDataService(),
      TableDataService:
        this.config.widget2.dataService ?? new TableDataService(),
      AcitivityLogDataService: new AcitivityLogDataService(),
      showFailedCampaignsBanner: false,
      showFailedCampaignsBannerText: '',
      failedCampaignsArray: [],
      showComponentBelowFilter: false,
      filterV2: this.config?.enableFilterV2,
      containerClass: ''
    };
  },
  computed: {
    ingestionDependentEntities() {
      return [EntitiesForPostProcessingStatus.campaign];
    },
    bulkSearchProps() {
      return {
        title: 'Bulk Search',
        subTitle: this.config?.multiSearch?.subTitle || 'inputs',
        inputFieldPlaceholder: 'Search',
        findEntityInputPlaceholder:
          this.config?.multiSearch?.findEntityInputPlaceholder ||
          'Find an input',
        maxPasteLimit: 1000
      };
    },
    lastUpdatedDate() {
      return moment(this.minMaxDate.maxDate).format('LL');
    },
    minMaxDate() {
      const maxDates = this.$store.getters.getMaxDate;
      const maxDate = get(maxDates, this.config.filters.date.max);
      const minDate = get(maxDates, this.config.filters.date.min);
      return {
        maxDate,
        minDate
      };
    },
    widget1WhereClause() {
      const globaWhereClause = cloneDeep(this.globalWhereClause);
      globaWhereClause.dimensionNameValueList =
        globaWhereClause.dimensionNameValueList.concat(
          this.plotRowData?.dimensionNameValueList || []
        );
      return globaWhereClause;
    },
    globalWhereClause() {
      let globalFiltersCopy = cloneDeep(
        this.$store.getters[this.config.namespace + 'getGlobalFilters']
      );
      // multi search
      if (Array.isArray(this.searchInput)) {
        if (this.searchInput.length) {
          let searchInput = this.searchInput
            .map((searchTerm) => `'${searchTerm.replaceAll("'", "\\'")}'`) // adding escape character for search terms containing single quote
            .join(',');
          const dimensionName = this.config.multiSearch.searchDimension;
          const operator = 'IN';
          if (dimensionName) {
            globalFiltersCopy.dimensionNameValueList.push({
              dimensionName,
              dimensionValue: searchInput,
              operator
            });
          }
        }
      } else if (
        this.searchInput &&
        this.config?.filters?.config?.search?.metadata?.dimensionName
      ) {
        globalFiltersCopy.dimensionNameValueList.push({
          dimensionName:
            this.config.filters.config.search.metadata.dimensionName,
          dimensionValue: this.searchInput,
          operator: this.config?.filters?.config?.search?.metadata?.operator
        });
      }

      return globalFiltersCopy;
    },
    showPlotRowsChip() {
      return this.plotRowData !== null;
    },
    componentBelowFilter() {
      if (this.config?.componentBelowFilter) {
        if (typeof this.config?.componentBelowFilter === 'function') {
          return this.config?.componentBelowFilter(this);
        }
        return this.config?.componentBelowFilter;
      }
      return null;
    },
    systemTransparencyBanner() {
      const dates = this?.filterInstance?.selectedDates;
      if (this.config?.systemTransparencyBanner && dates) {
        return this.config.systemTransparencyBanner(dates);
      }
      return null;
    }
  },
  mounted() {
    const that = this;
    eventBus.$on(that.config.filters.config.emit, (filterValues) => {
      that.$refs.tableWidget?.resetPagination();
      that.refreshWidgets();
      that.config?.filterUpdated(that);
    });
    eventBus.$on(that.config.filters.config.search.emit, (payload) => {
      that.handleSearchInput(payload);
    });
    eventBus.$on('entityBulkSearchApplied', (payload) => {
      that.handleSearchInput(payload);
    });

    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.entity?.openBulkSearchPopup();
    });
  },
  created() {
    this.containerClass = this.config.containerClass || '';
    this.fetchGlobalFilters();
    if (this.config.onCreated) {
      this.config.onCreated(this);
    }
  },
  beforeDestroy() {
    // to-do: hot reload causing the event bus handler to be removed, reintroduce in prod build
    eventBus.$off(this.config.filters.config.emit);
    eventBus.$off(this.config.filters.config.search.emit);
    eventBus.$off('entityBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  methods: {
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    openBulkSearchHelperPopup(showCheckbox = false) {
      let showHelpModalCheckbox = showCheckbox;
      this.$refs.bulkSearchHelpModal?.openBulkSearchHelperPopup(
        showHelpModalCheckbox
      );
    },
    openFailedCampaign(item) {
      this.$router.push({
        name: 'walmartEntityDetails',
        params: {
          entityType: item?.campaign_type,
          entityId: item?.campaign_id
        },
        query: {
          advertiser_id: item?.advertiser_id,
          parent_entity_name: item?.campaign_name
        }
      });
    },
    onBannerCloseIconClicked() {
      this.config.banner(this).onBannerCloseIconClicked();
    },
    handleRemovePlottedEntity() {
      this.plotRowData = null;
      this.$refs?.tableWidget?.closeActionsWorkflow();
      this.$nextTick(() => {
        this.$refs?.chartWidget?.getData();
      });
    },
    handlePlotRows(payload) {
      if (isEqual(this.plotRowData, payload)) {
        return;
      }
      this.plotRowData = payload;
      this.$refs.chartWidget.handleMaximize();
      this.$nextTick(() => {
        this.$refs.chartWidget?.getData();
      });
    },
    handleBlueBar(payload) {
      const { show, pageType } = payload;
      this.showBlueBar = show;
      this.messageBarPageType = pageType;
    },
    hideBlueBar() {
      this.showBlueBar = false;
    },
    closeActivityLog() {
      this.openActivity = false;
    },
    openSidePanel(payload) {
      const { show, activityLogPage } = payload;
      this.openActivity = show;
      if (show) {
        this.activityLogPage = activityLogPage;
        this.AcitivityLogDataService.getData(undefined, payload);
      }
    },
    changeModalConfig(newModalConfig) {
      this.$refs.tableWidget.sendEmailRequest();
      this.handleUpdateModalConfig(newModalConfig);
    },
    handleUpdateModalConfig(modalConfig) {
      if (typeof modalConfig === 'string' || modalConfig instanceof String) {
        this.modalConfig = modalMaps[modalConfig](this);
      } else {
        this.modalConfig = modalConfig;
      }
    },
    showModal() {
      this.showPopup = true;
    },
    hideModal() {
      this.showPopup = false;
    },
    clickedOutsideModal(val) {
      if (val) {
        this.hideModal();
      }
    },
    openEmailPopup() {
      this.showEmailPopUp = true;
    },
    handleFilterBeforeDestroy() {
      this.filterMounted = false;
    },
    handleFilterMount() {
      this.filterMounted = true;
    },
    refreshWidgets() {
      this.$nextTick(() => {
        // since where clause is being passed as a prop, using next tick to get updated prop
        this.$refs.chartWidget?.getData();
        this.$refs.tableWidget?.getData();
      });
    },
    handleSearchInput(payload) {
      this.searchInput = payload;
      this.$refs.tableWidget?.resetPagination();
      this.refreshWidgets();
    },
    fetchGlobalFilters() {
      const { cube, endPoint, page } = this.config.filters.api;
      this.fetchFilters(cube, endPoint, page);
    },
    onCreate(instance) {
      this.filterInstance = instance;
    }
  }
};
</script>

<style lang="css">
.generic-campaign-management .filter__wrapper > div {
  align-items: flex-start;
}
.generic-campaign-management .filter__wrapper > div > div > div {
  align-items: flex-start;
}
.generic-campaign-management .sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
.generic-campaign-management .background-red-warning {
  background-color: rgba(215, 38, 61, 0.1) !important;
}
.generic-campaign-management .u-spacing-mr-16px {
  margin-right: 8px;
}

.walmart-skus [col-id='toggle_status'] .ag-selection-checkbox.ag-hidden {
  display: inherit !important;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
