<template>
  <div class="u-position-relative">
    <div
      ref="trigger"
      v-tippy="tippyOptions"
      role="button"
      class="dropdown-trigger button button--hollow"
      @click="toggle"
    >
      <slot name="trigger">
        <div class="forecast-date-picker-trigger">
          <rb-icon
            :icon="'calendar'"
            class="u-color-grey-lighter"
            data-cy="calendarIcon"
          />
          <span
            class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-ph-s"
          >
            <span>
              <span class="u-font-size-7 u-color-grey-mid-light"
                >Forecast period:&nbsp;</span
              >
              <span
                v-if="dateText"
                class="u-font-size-7 u-color-grey-base"
              >
                {{ dateText }}</span
              >
            </span>
            <span
              v-if="compareDateText && !showInvalidMsg"
              class="u-font-size-8 u-spacing-pt-xxs"
            >
              <span class="u-font-size-8 u-color-grey-lighter"
                >Past Performance Period:&nbsp;</span
              >
              <span class="u-font-size-7 u-color-grey-base">{{
                compareDateText
              }}</span>
            </span>
          </span>
          <rb-icon
            :icon="'arrow-down'"
            class="rb-icon--x-small u-color-grey-mid-light"
          />
        </div>
      </slot>
    </div>
    <div
      :id="id"
      ref="dropdownMenu"
      class="daterangepicker-row u-display-flex u-flex-direction-column"
      style="width: 532px"
    >
      <div class="dropdownContainer">
        <div
          class="forecast-period u-display-flex u-flex-direction-column u-width-100"
        >
          <div class="u-display-inline-flex u-font-size-5 u-line-height-1">
            <span class="u-color-grey-mid-light">Forecast period:&nbsp;</span>
            <span class="u-color-grey-lighter u-font-size-7"
              ><span class="u-font-size-7 u-color-grey-lighter"
                >( Data available from </span
              >{{ setForecastPeriodFeedDate.feedStartDate }}&nbsp;-&nbsp;{{
                setForecastPeriodFeedDate.feedEndDate
              }}
              )</span
            >
          </div>
          <div
            class="forecast-period-dropdown u-display-flex u-flex-direction-column"
          >
            <rb-select
              v-if="isActive"
              :send-details="true"
              :on-close="forecastDdSelected"
              :search-enable-threshold="30"
              :options="forecastOptions"
              class="u-width-30 u-spacing-mt-m"
            >
              <div
                slot="trigger"
                class="u-display-flex u-height-36px u-flex-justify-content-space-between u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all u-border-radius-s"
              >
                <span
                  class="u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
                >
                  {{ forecastSelected.title }}</span
                >
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'arrow-down'"
                />
              </div>
            </rb-select>
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-mt-s"
            >
              <rb-select
                v-if="isActive"
                :send-details="true"
                :context="['dropdownFrom']"
                :on-close="forecastDateSelected"
                :search-enable-threshold="30"
                :options="forecastFromOptions"
                class="u-width-40"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-justify-content-space-between u-height-36px u-border-radius-s u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
                >
                  <span
                    class="u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
                  >
                    {{ forecastSelectedFrom.title }}</span
                  >
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'arrow-down'"
                  />
                </div>
              </rb-select>
              <span class="seperator u-spacing-mh-s" />
              <rb-select
                v-if="isActive"
                :context="['dropdownTo']"
                :send-details="true"
                :on-close="forecastDateSelected"
                :search-enable-threshold="30"
                :options="forecastToOptions"
                class="u-width-40"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-justify-content-space-between u-height-36px u-border-radius-s u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
                >
                  <span
                    class="u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
                  >
                    {{ forecastSelectedTo.endTitle }}</span
                  >
                  <rb-icon
                    class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'arrow-down'"
                  />
                </div>
              </rb-select>
            </div>
          </div>
        </div>

        <div
          v-show="!showInvalidMsg"
          class="past-performance-period u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-xxl"
        >
          <div class="u-display-inline-flex u-font-size-5 u-line-height-1">
            <span class="u-color-grey-mid-light"
              >Past Performance Period:&nbsp;</span
            >
            <!-- <span class="u-font-size-7 u-color-grey-lighter"><span class=" u-font-size-7 u-color-grey-lighter">( Data available from </span>{{setPastPeriodFeedDate.feedStartDate}}&nbsp;-&nbsp;{{setPastPeriodFeedDate.feedEndDate}} )</span> -->
          </div>
          <div
            class="past-performance-dropdown u-display-flex u-flex-direction-column"
          >
            <rb-select
              v-if="isActive && pastOptions.length > 0"
              :send-details="true"
              :on-close="pastPerformanceSelected"
              :search-enable-threshold="30"
              :options="pastOptions"
              class="u-width-50 u-spacing-mt-m"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-justify-content-space-between u-height-36px u-border-radius-s u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
              >
                <span
                  class="u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
                >
                  {{ pastSelected.title }}</span
                >
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'arrow-down'"
                />
              </div>
            </rb-select>
            <div
              v-else
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-width-50 u-height-36px u-border-radius-s u-spacing-p-s u-spacing-mt-m u-border-color-grey-xxx-light u-border-width-s u-border-all"
            >
              <span
                class="u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
              >
                {{ pastSelected.title }}</span
              >
            </div>
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-mt-s"
            >
              <rb-input
                v-model="pastSelectedFrom.title"
                :disabled="true"
                :placeholder="''"
                class="u-width-40 forecast-calendar-input"
                :rounded="true"
              />
              <span
                class="seperator u-spacing-mh-s u-font-size-5 u-color-grey-mid-light"
              />
              <rb-input
                v-model="pastSelectedTo.endTitle"
                :disabled="true"
                :placeholder="''"
                class="u-width-40 forecast-calendar-input"
                :rounded="true"
              />
            </div>
          </div>
        </div>
        <!-- Show when Calendar doesn't have past performance data -->
        <div
          v-show="showInvalidMsg"
          slot="noDataTemplate"
          class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-xl u-spacing-mb-s"
        >
          <div class="u-display-flex u-font-size-5 u-color-grey-mid-light">
            Past Performance Period:
          </div>
          <div
            class="u-display-inline-flex u-flex-direction-column u-text-align-center u-spacing-mt-l"
          >
            <!-- eslint-disable -->
            <span
              class="u-font-size-5 u-color-grey-base u-spacing-mb-s"
              v-html="invalidDateMsg.message1"
            />
            <span class="u-font-size-5">
              <span
                class="u-color-grey-lighter"
                v-html="invalidDateMsg.message2"
              />
            </span>
            <span
              v-if="invalidDateMsg.message3"
              class="u-font-size-7 u-color-grey-lighter u-spacing-mt-s"
              v-html="invalidDateMsg.message3"
            />
            <!-- eslint-enable -->
          </div>
        </div>

        <div
          class="u-display-flex u-width-100 u-flex-justify-content-flex-end u-spacing-mt-xl"
        >
          <rb-button
            :click-fn="cancel"
            :text="'Cancel'"
            :size="'s'"
            :type="'hollow'"
            class="u-spacing-mr-s u-cursor-pointer"
          />
          <rb-button
            :click-fn="apply"
            :text="'Apply'"
            :size="'s'"
            :type="'filled'"
            class="u-cursor-pointer"
            :disabled="applyButtonStatus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { eventBus } from '@/utils/services/eventBus';

export default {
  components: {},
  props: {
    calendarData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: 'forecast-calendar-' + this._uid,
      calenderGranuality: '',
      tippyOptions: {
        html: '#' + 'forecast-calendar-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        },
        onShow: this.onShow,
        onHide: this.onHide
      },
      map: {},
      tempMap: {},
      applyButtonStatus: true,
      isActive: false,
      dateText: '',
      compareDateText: '',
      forecastSelected: {},
      forecastOptions: [],
      forecastSelectedFrom: {},
      forecastFromOptions: [],
      forecastSelectedTo: {},
      forecastToOptions: [],
      pastSelected: {},
      pastOptions: [],
      pastSelectedFrom: {},
      pastSelectedTo: {},
      calDropdownOptions: {},
      userInteract: false,
      invalidDateMsg: {
        show: false
      },
      forecastSelBeforeCustom: null,
      showInvalidMsg: false,
      calDropdownOptionsTemp: null
    };
  },
  computed: {
    setForecastPeriodFeedDate() {
      if (
        this.forecastSelectedFrom.value &&
        this.forecastSelectedFrom.value.period_start_date &&
        this.forecastSelectedFrom.value.period_end_date
      ) {
        return {
          feedStartDate: moment
            .utc(this.forecastSelectedFrom.value.period_start_date)
            .format('MMM DD, YYYY'),
          feedEndDate: moment
            .utc(this.forecastSelectedFrom.value.period_end_date)
            .format('MMM DD, YYYY')
        };
      } else {
        return {};
      }
    },
    setPastPeriodFeedDate() {
      if (
        this.pastSelectedFrom.value &&
        this.pastSelectedFrom.value.period_start_date &&
        this.pastSelectedFrom.value.period_end_date
      ) {
        return {
          feedStartDate: moment
            .utc(this.pastSelectedFrom.value.period_start_date)
            .format('MMM DD, YYYY'),
          feedEndDate: moment
            .utc(this.pastSelectedFrom.value.period_end_date)
            .format('MMM DD, YYYY')
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    'forecastSelected.title': function (newVal, oldVal) {},
    calendarData: {
      handler: function (newVal, oldVal) {
        this.calendarData = newVal;
        this.initialize();
      }
    },
    tempMap: {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        if (
          newVal &&
          newVal.constructor === Object &&
          Object.keys(newVal).length > 0
        ) {
          this.checkOrRestoreMaps('check');
        }
      }
    }
  },
  beforeCreate() {},
  created() {
    this.calenderGranuality =
      this.$store.getters.getDropdownSelectedOptions.calender_granuality;
    if (!this.calenderGranuality) {
      this.calenderGranuality = 'Week';
    }
  },
  mounted() {
    eventBus.$on(
      'calendarTriggered',
      function (options) {
        let map = {};
        if (options && options.calender_granuality) {
          if (options.calender_granuality === 'Week') {
            map = {
              forecast: {
                key: 'next_13_weeks'
              },
              past: {
                key: 'previous_period'
              }
            };
          } else if (options.calender_granuality === 'Month') {
            map = {
              forecast: {
                key: 'next_3_months'
              },
              past: {
                key: 'previous_period'
              }
            };
          }
        }
        // clear old dropdown selections
        this.forecastSelected = {};
        this.forecastOptions = [];
        this.forecastSelectedFrom = {};
        this.forecastFromOptions = [];
        this.forecastSelectedTo = {};
        this.forecastToOptions = [];
        this.pastSelected = {};
        this.pastOptions = [];
        this.pastSelectedFrom = {};
        this.pastSelectedTo = {};

        this.$store.dispatch('setCalendarSelectedValues', map);
        this.$store.dispatch('setItemsInLocalStorage', [
          'selCalendarOptions',
          this.$store.getters.getSelectedCalendarValues
        ]);
        this.calenderGranuality = options.calender_granuality;
        this.setDropDowns();
        eventBus.$emit('calendarChanged', options);
      }.bind(this)
    );
  },
  methods: {
    showNoDataTemplate(status, No, No1) {
      // If Calendar doesn't have past data
      let momentDuration;
      this.showInvalidMsg = status;
      const msgObj = {};
      let textDuration;
      msgObj.show = status;
      if (status) {
        if (this.calenderGranuality === 'Week') {
          momentDuration = 'weeks';
          No = No === null || No === undefined ? 13 : No;
        } else if (this.calenderGranuality === 'Month') {
          momentDuration = 'months';
          No = No === null || No === undefined ? 3 : No;
        }
        textDuration = momentDuration;
        if (this.forecastSelected.key === 'custom') {
          momentDuration = 'year';
          No = 1;
          var len =
            this.calDropdownOptionsTemp.past &&
            this.calDropdownOptionsTemp.past[this.calenderGranuality] &&
            this.calDropdownOptionsTemp.past[this.calenderGranuality].length;
          if (len > 0) {
            No1 =
              this.calDropdownOptionsTemp.past[this.calenderGranuality][len - 1]
                .length;
          }
        }
        if (
          !this.calDropdownOptionsTemp.past ||
          !this.calDropdownOptionsTemp.past[this.calenderGranuality]
        ) {
          if (this.forecastSelected.key === 'next_13_weeks') {
            No = 13;
          } else if (this.forecastSelected.key === 'next_26_weeks') {
            No = 26;
          } else if (this.forecastSelected.key === 'next_3_months') {
            No = 3;
          } else if (this.forecastSelected.key === 'next_6_months') {
            No = 6;
          }
        }
        const minDate = moment.utc(
          (this.$store.getters.getMaxDate.v2_forecast || {}).min_feed_date
        );
        const date = moment
          .utc(minDate)
          .add(No, momentDuration)
          .format('MMM DD, YYYY');
        msgObj.message1 = 'Insufficient data for selected period!';
        msgObj.message2 =
          'Past ' +
          No +
          ' ' +
          momentDuration +
          ' data should be available by ' +
          '<span class="u-color-grey-base">' +
          date +
          '</span>';
        if (No1 !== undefined && No1 > 0) {
          msgObj.message3 =
            'Data available for past ' +
            '<span class="u-color-grey-base">' +
            No1 +
            ' ' +
            textDuration +
            '</span>';
        }
        this.invalidDateMsg = msgObj;
      }
    },
    initialize() {
      // generate dropdown options for Weeks & Months in Forecast & Past Performance
      const calDropdownOptions = {};
      let calendarData = Object.assign({}, this.calendarData);
      calendarData = JSON.parse(JSON.stringify(calendarData));
      for (var type in calendarData) {
        calDropdownOptions[type] = {};
        for (var granularity in calendarData[type]) {
          var index = 0;
          calDropdownOptions[type][granularity] = [];
          for (var range in calendarData[type][granularity]) {
            var title = '';
            var key = '';
            if (type === 'past') {
              if (range === 'last_1_year') {
                title = 'Same period last year';
                key = range;
              } else {
                title = 'Previous period';
                key = 'previous_period';
              }
            } else if (type === 'forecast') {
              title = Vue.options.filters.first_caps(
                calendarData[type][granularity][range][0].period,
                true
              );
              key = range;
            }
            const obj = {
              index: index++,
              title: title,
              key: key,
              length: calendarData[type][granularity][range].length,
              allValues: calendarData[type][granularity][range],
              values: calendarData[type][granularity][range].map(function (
                option
              ) {
                if (type === 'forecast') {
                  delete option.period;
                  delete option.sort_order;
                }
                var tempObj = {
                  title:
                    option.interval_label +
                    moment
                      .utc(option.interval_start_date)
                      .format(' (MMM DD, YYYY)'),
                  startTitle:
                    option.interval_label +
                    moment
                      .utc(option.interval_start_date)
                      .format(' (MMM DD, YYYY)'),
                  endTitle:
                    option.interval_label +
                    moment
                      .utc(option.interval_end_date)
                      .format(' (MMM DD, YYYY)'),
                  value: option
                };
                return tempObj;
              })
            };
            calDropdownOptions[type][granularity].push(obj);
          }
          // push Custom for Forecast dropdowns
          if (type === 'forecast' && index >= 1) {
            const r = Object.keys(this.calendarData[type][granularity])[
              index - 1
            ];
            calDropdownOptions[type][granularity].push({
              index: index,
              title: 'Custom',
              key: 'custom',
              length: this.calendarData[type][granularity][r].length,
              allValues: this.calendarData[type][granularity][r],
              values: this.calendarData[type][granularity][r].map(function (
                option
              ) {
                // delete period andn sort_order
                // As they create problems on array comparision
                delete option.period;
                delete option.sort_order;
                return {
                  title:
                    option.interval_label +
                    moment
                      .utc(option.interval_start_date)
                      .format(' (MMM DD, YYYY)'),
                  startTitle:
                    option.interval_label +
                    moment
                      .utc(option.interval_start_date)
                      .format(' (MMM DD, YYYY)'),
                  endTitle:
                    option.interval_label +
                    moment
                      .utc(option.interval_end_date)
                      .format(' (MMM DD, YYYY)'),
                  value: option
                };
              })
            });
          }
        }
      }

      // remove undefined from values
      for (const k in calDropdownOptions) {
        for (const k1 in calDropdownOptions[k]) {
          for (const idx in calDropdownOptions[k][k1]) {
            calDropdownOptions[k][k1][idx].values = calDropdownOptions[k][k1][
              idx
            ].values.filter(function (valObj) {
              if (valObj && Object.keys(valObj).length > 0) {
                return valObj;
              }
            });
          }
        }
      }
      this.calDropdownOptionsTemp = JSON.parse(
        JSON.stringify(calDropdownOptions)
      );
      this.calDropdownOptions = calDropdownOptions;
      if (calDropdownOptions.past && calDropdownOptions.past.Week) {
        var temp = [];
        calDropdownOptions.past.Week.forEach((item) => {
          if (item.key === 'previous_period') {
            temp.push(...item.values);
          }
        });
        calDropdownOptions.past.Week = calDropdownOptions.past.Week.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.key === thing.key)
        );
        calDropdownOptions.past.Week = calDropdownOptions.past.Week.map(
          (item) => {
            if (item.key === 'previous_period') {
              item.allValues = [];
              item.allValues = [...temp];
              item.values = [...temp];
            }
            return item;
          }
        );
      }
      // Calculate 3 + 6 Month data
      if (calDropdownOptions.past && calDropdownOptions.past.Month) {
        var tempMonth = [];
        calDropdownOptions.past.Month.forEach((item) => {
          if (item.key === 'previous_period') {
            tempMonth.push(...item.values);
          }
        });
        calDropdownOptions.past.Month = calDropdownOptions.past.Month.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.key === thing.key)
        );
        calDropdownOptions.past.Month = calDropdownOptions.past.Month.map(
          (item) => {
            if (item.key === 'previous_period') {
              item.allValues = [];
              item.allValues = [...tempMonth];
              item.values = [...tempMonth];
            }
            return item;
          }
        );
      }
      // check localstorage for saved calendar
      var lclStorage = localStorage.getItem('Forecast-v2');
      if (lclStorage) {
        lclStorage = JSON.parse(lclStorage);
        if (
          lclStorage.selCalendarOptions &&
          Object.keys(lclStorage.selCalendarOptions).length > 0
        ) {
          this.$store.dispatch(
            'setCalendarSelectedValues',
            lclStorage.selCalendarOptions
          );
        }
      }
      this.setDropDowns();
    },
    arrayDifferentiator(wholeArray, selectedArray) {
      // compare wholeArray with selectedArray return unmatched ones
      var returnArr = Vue.options.filters.array_differentiator(
        wholeArray,
        selectedArray
      );
      return returnArr;
    },
    computeDdSelectedOptions(map, trigger) {
      for (var type in map) {
        if (
          this.calDropdownOptions[type] &&
          this.calDropdownOptions[type][this.calenderGranuality]
        ) {
          const selArr = this.calDropdownOptions[type][this.calenderGranuality];
          let isKeyPresent = false;
          let lengthToCheck;
          if (type === 'past') {
            if (this.calenderGranuality === 'Week') {
              if (
                map.forecast.key === 'custom' &&
                map.forecast.endInterval > 13
              ) {
                lengthToCheck = 26;
              } else if (
                this.forecastSelBeforeCustom &&
                map.forecast.key === 'custom' &&
                map.forecast.startInterval >= 1 &&
                map.forecast.endInterval <= 13
              ) {
                lengthToCheck = this.forecastSelBeforeCustom.length;
              } else {
                lengthToCheck = this.forecastSelected.length;
              }
            }
            if (this.calenderGranuality === 'Month') {
              if (
                map.forecast.key === 'custom' &&
                map.forecast.endInterval > 3
              ) {
                lengthToCheck = 6;
              } else if (
                this.forecastSelBeforeCustom &&
                map.forecast.key === 'custom' &&
                map.forecast.startInterval >= 1 &&
                map.forecast.endInterval <= 3
              ) {
                lengthToCheck = this.forecastSelBeforeCustom.length;
              } else {
                lengthToCheck = this.forecastSelected.length;
              }
            }
          }
          for (const obj of selArr) {
            const startInterval = map[type].startInterval || 1;
            const endInterval = map[type].endInterval || obj.length;
            if (obj.key === map[type].key) {
              map[type].startInterval = startInterval;
              map[type].endInterval = endInterval;
              isKeyPresent = true;
              this[type + 'Selected'] = obj;
              this[type + 'Options'] = this.arrayDifferentiator(selArr, [obj]);
              let pastStartIntervalFound;
              let pastEndIntervalFound;
              let forecastStartIntervalFound;
              let forecastEndIntervalFound;
              for (const obj1 of obj.values) {
                if (type === 'forecast') {
                  if (obj1.value.interval_number === startInterval) {
                    this[type + 'SelectedFrom'] = obj1;
                    forecastStartIntervalFound = true;
                  }
                  if (obj1.value.interval_number === endInterval) {
                    this[type + 'SelectedTo'] = obj1;
                    forecastEndIntervalFound = true;
                  }
                } else if (type === 'past') {
                  if (map.past.key !== 'last_1_year') {
                    if (
                      obj1.value.interval_number === startInterval &&
                      obj1.value.period.includes(lengthToCheck)
                    ) {
                      this[type + 'SelectedFrom'] = obj1;
                      pastStartIntervalFound = true;
                    }
                    if (
                      obj1.value.interval_number === endInterval &&
                      obj1.value.period.includes(lengthToCheck)
                    ) {
                      this[type + 'SelectedTo'] = obj1;
                      pastEndIntervalFound = true;
                    }
                  } else {
                    if (obj1.value.interval_number === startInterval) {
                      this[type + 'SelectedFrom'] = obj1;
                      pastStartIntervalFound = true;
                    }
                    if (obj1.value.interval_number === endInterval) {
                      this[type + 'SelectedTo'] = obj1;
                      pastEndIntervalFound = true;
                    }
                  }
                }
                if (
                  type === 'forecast' &&
                  forecastStartIntervalFound &&
                  forecastEndIntervalFound
                ) {
                  break;
                }
              }
              if (
                type === 'past' &&
                (!pastStartIntervalFound || !pastEndIntervalFound)
              ) {
                // Set isKeyPresent to false if one of the interval is missing.
                isKeyPresent = false;
              }
            }
            // Set options for start and end dropdown boxes for forecast
            if (type !== 'past' && obj.key === 'custom') {
              // delete selectedIndex key on the object before comparing
              if (this[type + 'SelectedFrom'].hasOwnProperty('selectedIndex')) {
                delete this[type + 'SelectedFrom'].selectedIndex;
              } else if (
                this[type + 'SelectedTo'].hasOwnProperty('selectedIndex')
              ) {
                delete this[type + 'SelectedTo'].selectedIndex;
              }
              this[type + 'FromOptions'] = selArr[selArr.length - 1].values;
              this[type + 'ToOptions'] = selArr[selArr.length - 1].values;
            }
          }
          if (!isKeyPresent) {
            const selIdx = selArr.length - 1;
            if (selIdx >= 0) {
              const selObj = selArr[selIdx];
              this[type + 'Selected'] = selObj;
              this[type + 'Options'] = this.arrayDifferentiator(selArr, [
                selObj
              ]);
              let stIdx;
              let endIdx;
              if (type === 'past') {
                let startIntervalFound = false;
                let endIntervalFound = false;
                for (let idx1 = 0; idx1 < selObj.values.length; idx1++) {
                  if (
                    selObj.values[idx1].value.period.includes(lengthToCheck) &&
                    selObj.values[idx1].value.interval_number ===
                      this.tempMap.forecast.startInterval
                  ) {
                    stIdx = idx1;
                    startIntervalFound = true;
                  }
                  if (
                    selObj.values[idx1].value.period.includes(lengthToCheck) &&
                    selObj.values[idx1].value.interval_number ===
                      this.tempMap.forecast.endInterval
                  ) {
                    endIdx = idx1;
                    endIntervalFound = true;
                  }
                }
                if (startIntervalFound && endIntervalFound) {
                  this[type + 'SelectedFrom'] = selArr[selIdx].values[stIdx];
                  this[type + 'SelectedTo'] = selArr[selIdx].values[endIdx];
                  map[type].key = selObj.key;
                  map[type].startInterval =
                    selArr[selIdx].values[stIdx].value.interval_number;
                  map[type].endInterval =
                    selArr[selIdx].values[endIdx].value.interval_number;
                } else {
                  this[type + 'Selected'] = {};
                  this[type + 'Options'] = [];
                  this[type + 'SelectedFrom'] = {};
                  this[type + 'SelectedTo'] = {};
                  this.showNoDataTemplate(
                    true,
                    this.forecastSelected.length,
                    selObj.length
                  );
                }
              }
            }
          } else {
            this.showInvalidMsg = false;
            this.invalidDateMsg = {
              show: false
            };
          }
        } else {
          this.showNoDataTemplate(true);
        }
      }
      // Set End Date as Title for the Forecast To Dropdowns
      if (this.forecastToOptions.length && this.forecastToOptions.length > 0) {
        this.forecastToOptions = JSON.parse(
          JSON.stringify(this.forecastToOptions)
        );
        for (var idx in this.forecastToOptions) {
          this.forecastToOptions[idx].title =
            this.forecastToOptions[idx].endTitle;
        }
      }
    },
    setTriggerText() {
      this.dateText =
        moment
          .utc(this.forecastSelectedFrom.value.interval_start_date)
          .format('MMM DD, YYYY') +
        ' - ' +
        moment
          .utc(this.forecastSelectedTo.value.interval_end_date)
          .format('MMM DD, YYYY');

      if (
        this.pastSelectedFrom &&
        this.pastSelectedFrom.value &&
        this.pastSelectedFrom.value.interval_start_date
      ) {
        this.compareDateText =
          moment
            .utc(this.pastSelectedFrom.value.interval_start_date)
            .format('MMM DD, YYYY') +
          ' - ' +
          moment
            .utc(
              this.pastSelectedTo.value &&
                this.pastSelectedTo.value.interval_end_date
            )
            .format('MMM DD, YYYY');
      }
      // emit event to forecast to show/hide no data template in past performance widget
      this.$emit('showNoData', this.invalidDateMsg);
    },
    setDropDowns() {
      this.map = this.$store.getters.getSelectedCalendarValues;
      const selForecast =
        this.calDropdownOptions.forecast &&
        this.calDropdownOptions.forecast[this.calenderGranuality][0];
      const selPast =
        this.calDropdownOptions.past &&
        this.calDropdownOptions.past[this.calenderGranuality] &&
        this.calDropdownOptions.past[this.calenderGranuality][0];

      // when localstorage doesn't have forecast and past entries.
      if (!this.map.forecast.key && !this.map.past.key) {
        if (selForecast) {
          this.map.forecast = {
            key: selForecast.key,
            startInterval: 1,
            endInterval: selForecast.length
          };
        }
        if (selPast) {
          this.map.past = {
            key: selPast.key,
            startInterval: 1,
            endInterval: selForecast.length // Since Previous period has both 13 and 26 Weeks or 3 and 6 Months, Set the Forecast length
          };
        }
      }

      // null local saved dates if any
      if (this.map.forecast) {
        this.map.forecast.startDate = null;
        this.map.forecast.feedDate = null;
        this.map.forecast.endDate = null;
      }

      if (this.map.past) {
        this.map.past.startDate = null;
        this.map.past.endDate = null;
        this.map.past.feedDate = null;
      }

      if (!this.map.past || !selPast) {
        this.map.past = {
          key: 'previous_period',
          startInterval: 1
        };
        if (this.calenderGranuality === 'Week') {
          if (this.map.forecast.key === 'next_13_weeks') {
            this.map.past.endInterval = 13;
          } else if (this.map.forecast.key === 'next_26_weeks') {
            this.map.past.endInterval = 26;
          } else if (this.map.forecast.key === 'custom') {
            this.map.past.key = 'last_1_year';
            this.map.past.startInterval = this.map.forecast.startInterval;
            this.map.past.endInterval = this.map.forecast.endInterval;
          }
        } else if (this.calenderGranuality === 'Month') {
          if (this.map.forecast === 'next_3_months') {
            this.map.past.endInterval = 3;
          } else if (this.forecastSelected.key === 'next_6_months') {
            this.map.past.endInterval = 6;
          } else if (this.map.forecast.key === 'custom') {
            this.map.past.key = 'last_1_year';
            this.map.past.startInterval = this.map.forecast.startInterval;
            this.map.past.endInterval = this.map.forecast.endInterval;
          }
        }
      }

      this.computeDdSelectedOptions(this.map);
      this.setTriggerText();
      this.tempMap = Object.assign({}, this.map); // create a copy of map
      this.tempMap = JSON.parse(JSON.stringify(this.tempMap)); // create new reference

      this.$store.dispatch(
        'setCalendarSelectedValues',
        this.getCalendarFormatInStore()
      ); // set in stores
      this.$emit('isCalendarLoaded', true); // emit back to parent to load other widgets
    },
    onShow() {
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
      this.checkOrRestoreMaps('restore');
      this.showInvalidMsg = false;
      this.invalidDateMsg = {
        show: false
      };
      this.computeDdSelectedOptions(this.map);
    },
    toggle(instance) {
      this.isActive = !this.isActive;
    },
    checkOrRestoreMaps(option) {
      if (
        this.map &&
        Object.keys(this.map).length > 0 &&
        this.tempMap &&
        Object.keys(this.tempMap).length > 0
      ) {
        let isChanged = false;
        for (const key in this.tempMap) {
          for (const key1 in this.tempMap[key]) {
            if (this.tempMap[key][key1] !== this.map[key][key1]) {
              if (option === 'check') {
                isChanged = true;
                break;
              } else if (option === 'restore') {
                this.tempMap[key][key1] = this.map[key][key1];
              }
            }
          }
        }
        if (isChanged && option !== 'restore') {
          this.applyButtonStatus = false;
        } else {
          this.applyButtonStatus = true;
        }
      }
      // this.applyButtonStatus = false; // always show button (Needs to be Remove)
    },
    cancel() {
      setTimeout(
        function () {
          this.checkOrRestoreMaps('restore');
          this.computeDdSelectedOptions(this.tempMap);
          this.$refs.trigger.click();
        }.bind(this),
        150
      );
    },
    getCalendarFormatInStore() {
      // This format is used in store & localstorage
      const returnObj = {
        forecast: {
          key: this.forecastSelected.key,
          length: this.forecastSelected.length,
          startInterval: this.forecastSelectedFrom.value.interval_number,
          endInterval: this.forecastSelectedTo.value.interval_number,
          startDate: moment
            .utc(
              this.forecastSelectedFrom.value.interval_start_date,
              'MM/DD/YYYY'
            )
            .format('YYYY-MM-DD'),
          endDate: moment
            .utc(this.forecastSelectedTo.value.interval_end_date, 'MM/DD/YYYY')
            .format('YYYY-MM-DD'),
          feedDate: moment
            .utc(this.forecastSelectedFrom.value.period_feed_date, 'MM/DD/YYYY')
            .format('YYYY-MM-DD')
        }
      };
      if (
        this.pastSelectedFrom.value &&
        this.pastSelectedTo.value &&
        this.pastSelectedFrom.value.interval_start_date &&
        this.pastSelectedTo.value.interval_end_date &&
        this.pastSelectedFrom.value.period_feed_date
      ) {
        returnObj.past = {
          key: this.pastSelected.key,
          length: this.pastSelected.length,
          startInterval: this.pastSelectedFrom.value.interval_number,
          endInterval: this.pastSelectedTo.value.interval_number,
          startDate: moment
            .utc(this.pastSelectedFrom.value.interval_start_date, 'MM/DD/YYYY')
            .format('YYYY-MM-DD'),
          endDate: moment
            .utc(this.pastSelectedTo.value.interval_end_date, 'MM/DD/YYYY')
            .format('YYYY-MM-DD'),
          feedDate: moment
            .utc(this.pastSelectedFrom.value.period_feed_date, 'MM/DD/YYYY')
            .format('YYYY-MM-DD')
        };
      }
      return returnObj;
    },
    apply() {
      setTimeout(
        function () {
          const returnObj = this.getCalendarFormatInStore();
          this.$store.dispatch('setCalendarSelectedValues', returnObj); // set in store
          this.$store.dispatch('setItemsInLocalStorage', [
            'selCalendarOptions',
            this.$store.getters.getSelectedCalendarValues
          ]); // set in localstorage
          eventBus.$emit('calendarChanged', {
            calender_granuality: this.calenderGranuality
          }); // emit event to reload all cards
          this.map = Object.assign({}, this.tempMap);
          this.map = JSON.parse(JSON.stringify(this.map));
          this.applyButtonStatus = true;
          this.setTriggerText();
          this.$refs.trigger.click();
          // emit event to forecast to show/hide no data template in past performance widget
          this.$emit('showNoData', this.invalidDateMsg);
        }.bind(this),
        150
      );
    },
    forecastDdSelected(context, val) {
      if (val[0].hasOwnProperty('selectedIndex')) {
        delete val[0].selectedIndex;
      }
      this.tempMap.forecast.key = val[0].key;
      this.tempMap.forecast.startInterval = 1;
      this.tempMap.forecast.endInterval = val[0].length;
      this.tempMap.past.key = !this.userInteract
        ? 'previous_period'
        : this.pastSelected.key;
      if (val[0].key === 'custom') {
        this.tempMap.forecast.startInterval =
          this.forecastSelectedFrom.value.interval_number;
        this.tempMap.forecast.endInterval =
          this.forecastSelectedTo.value.interval_number;

        this.tempMap.past.key = !this.userInteract
          ? 'last_1_year'
          : this.pastSelected.key;
      }

      if (val[0].key === 'custom' && this.forecastSelected.key !== 'custom') {
        this.forecastSelBeforeCustom = JSON.parse(
          JSON.stringify(this.forecastSelected)
        );
      }
      this.tempMap.past.startInterval = this.tempMap.forecast.startInterval;
      this.tempMap.past.endInterval = this.tempMap.forecast.endInterval;
      this.computeDdSelectedOptions(this.tempMap, 'select');
    },
    forecastDateSelected(context, selected) {
      if (selected[0].hasOwnProperty('selectedIndex')) {
        delete selected[0].selectedIndex;
      }
      this.tempMap.forecast.key = this.forecastSelected.key;
      const selArr = this.calDropdownOptions.forecast[this.calenderGranuality];

      if (context[0] === 'dropdownFrom') {
        if (
          selected[0].value.interval_number >= this.tempMap.forecast.endInterval
        ) {
          this.tempMap.forecast.startInterval =
            selected[0].value.interval_number;
          this.tempMap.forecast.endInterval = selected[0].value.interval_number;
        } else {
          this.tempMap.forecast.startInterval =
            selected[0].value.interval_number;
          this.tempMap.forecast.endInterval =
            this.forecastSelectedTo.value.interval_number;
        }
      }

      if (context[0] === 'dropdownTo') {
        if (
          selected[0].value.interval_number <=
          this.tempMap.forecast.startInterval
        ) {
          this.tempMap.forecast.startInterval =
            selected[0].value.interval_number;
          this.tempMap.forecast.endInterval = selected[0].value.interval_number;
        } else {
          this.tempMap.forecast.startInterval =
            this.forecastSelectedFrom.value.interval_number;
          this.tempMap.forecast.endInterval = selected[0].value.interval_number;
        }
      }
      for (const obj of selArr) {
        if (obj.values && obj.values.length > 0) {
          if (
            this.tempMap.forecast.startInterval ===
              obj.values[0].value.interval_number &&
            this.tempMap.forecast.endInterval ===
              obj.values[obj.values.length - 1].value.interval_number
          ) {
            this.tempMap.forecast.key = obj.key;
            break;
          } else {
            this.tempMap.forecast.key = 'custom';
          }
        }
      }
      if (
        this.tempMap.forecast.key === 'custom' &&
        this.forecastSelected.key !== 'custom'
      ) {
        this.forecastSelBeforeCustom = JSON.parse(
          JSON.stringify(this.forecastSelected)
        );
      }
      if (!this.userInteract) {
        if (this.tempMap.forecast.key === 'custom') {
          this.tempMap.past.key = 'last_1_year';
        } else {
          this.tempMap.past.key = 'previous_period';
        }
      }
      this.tempMap.past.startInterval = this.tempMap.forecast.startInterval;
      this.tempMap.past.endInterval = this.tempMap.forecast.endInterval;

      this.computeDdSelectedOptions(this.tempMap, 'select');
    },
    pastPerformanceSelected(context, val) {
      // remove selectedIndex key
      if (val[0].hasOwnProperty('selectedIndex')) {
        delete val[0].selectedIndex;
      }
      if (
        (val[0] === 'last_1_year' &&
          this.forecastSelected.length <= val[0].length) ||
        (this.tempMap.forecast.startInterval <= val[0].length &&
          this.tempMap.forecast.endInterval < val[0].length)
      ) {
        this.tempMap.past.key = val[0].key;
        this.userInteract = true;
        this.tempMap.past.startInterval =
          this.forecastSelectedFrom.value.interval_number;
        this.tempMap.past.endInterval =
          this.forecastSelectedTo.value.interval_number;
        this.computeDdSelectedOptions(this.tempMap, 'select');
      }
      if (val[0] && val[0].values && val[0] !== 'last_1_year') {
        this.tempMap.past.key = val[0].key;
        this.userInteract = true;
        for (const o of val[0].values) {
          if (
            o.value.period.includes(this.forecastSelected.length) &&
            o.value.interval_number === this.tempMap.forecast.startInterval
          ) {
            this.tempMap.past.startInterval = o.value.interval_number;
          }
          if (
            o.value.period.includes(this.forecastSelected.length) &&
            o.value.interval_number === this.tempMap.forecast.endInterval
          ) {
            this.tempMap.past.endInterval = o.value.interval_number;
          }
        }
        this.computeDdSelectedOptions(this.tempMap, 'select');
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-height-36px {
  height: 36px;
}

.forecast-date-picker-trigger {
  border: 1px solid #e9eaeb;
  min-width: 285px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
  border-radius: 2px;
  padding: 0.8rem;
}

.dropdownContainer {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 3.2rem;
}

.seperator {
  width: 8px;
  height: 1px;
  background-color: #caccce;
}
</style>
