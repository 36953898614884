import amazon from '@/components/ams/campaign_creation/setup_configs/amazon/index.js';
import walmart from '@/components/ams/campaign_creation/setup_configs/walmart/index.js';
import samsclub_api from '@/components/ams/campaign_creation/setup_configs/samsclub_api/index.js';
import amazon_manual from '@/components/ams/campaign_creation/setup_configs/amazon/manual.js';

export default {
  amazon,
  walmart,
  samsclub_api,
  amazon_manual
};
