<template>
  <div>
    <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-s">
      {{ label }}
      <span
        v-if="isOptional"
        class="u-color-grey-lighter"
        data-cy="optionaltxt"
      >
        Optional</span
      >
    </div>
    <div
      v-click-outside="onClickOutside"
      class="entity-details-settings-calendar"
    >
      <span
        v-tippy="{ placement: 'top', arrow: true }"
        class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
        style="border-radius: 2px"
        @click="onOpenCalendar"
      >
        <rb-icon
          icon="calendar"
          class="rb-icon--small u-spacing-mr-xs"
        />
        <span
          class="u-spacing-ph-xs u-line-height-1_2"
          data-cy="fromdate"
        >
          {{ value | formatDate(name) }}
        </span>
      </span>
      <datepicker
        :ref="name"
        :value="value"
        style="right: -200px"
        :name="name"
        :disabled-dates="disabledDates"
        @input="onDateChange"
      >
        <div
          v-if="name === 'endDate'"
          slot="beforeCalendarHeader"
          class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
          :class="noEndDate && 'u-color-white-base u-bg-color-orange-base'"
          data-cy="noEndDate"
          @click="selectNoEndDate"
        >
          No end date
        </div>
      </datepicker>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
  name: 'DatePicker',
  components: {
    Datepicker
  },
  filters: {
    formatDate: function (value, type) {
      if (value === null) {
        return `No ${type === 'startDate' ? 'start' : 'end'} date`;
      }
      return moment(value).format('MMM DD, YYYY');
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Date,
      default: null
    },
    dates: {
      type: Object,
      default: function () {
        return {
          startDate: null,
          endDate: null
        };
      }
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noEndDate: true
    };
  },
  computed: {
    disabledDates() {
      const { startDate, endDate } = this.dates;
      const { year, month, day } = this.getDateInPST(
        this.name === 'endDate' ? startDate : null
      );
      if (this.name === 'endDate') {
        const disableFrom = new Date(year, month, day + 2);
        if (endDate && new Date(endDate).setHours(0, 0, 0, 0) <= startDate) {
          this.$emit('input', disableFrom, this.name);
        }
        return {
          to: disableFrom
        };
      }

      return {
        to: new Date(year, month, day)
      };
    }
  },
  created() {
    if (this.name === 'startDate') {
      const { year, month, day } = this.getDateInPST();
      const defaultSelectedStartDate = new Date(year, month, day);
      this.$emit('input', defaultSelectedStartDate, this.name);
    }
  },
  methods: {
    getDateInPST(date) {
      const dateInPST = moment.tz(
        date ? new Date(date) : new Date(),
        'America/Los_Angeles'
      );
      const year = dateInPST.year();
      const month = dateInPST.month();
      const day = dateInPST.date();

      return { year, month, day };
    },
    onOpenCalendar() {
      this.$refs[this.name] && this.$refs[this.name].showCalendar();
    },
    onClickOutside() {
      if (this.name === 'endDate') {
        this.noEndDate = !this.value;
      }
      this.$refs?.[this.name]?.close();
    },
    selectNoEndDate() {
      this.$emit('input', null, this.name);
      this.onClickOutside();
    },
    onDateChange(newValue) {
      this.$emit('input', newValue, this.name);
    }
  }
};
</script>
