import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
// import dummyData from '@/components/pages/ciq-internal-tools/internal-catalogue-ingestion/data/data.js'

const state = {
  internalCatalogData: {},
  uploadStatus: 'Not Started',
  lastUploadedStatus: {},
  notificationMessage: null,
  stopUploading: false
};

const getters = {
  getInternalCatalogData: (state) => {
    return state.internalCatalogData;
  },
  getUploadStatus: (state) => {
    return state.uploadStatus;
  },
  getLastUploadedStatus: (state) => {
    return state.lastUploadedStatus;
  },
  getNotificationMessage: (state) => {
    return state.notificationMessage;
  },
  getStopUploadingFlag: (state) => {
    return state.stopUploading;
  }
};

const mutations = {
  INTERNAL_CATALOG_SUCCESS: (state, data) => {
    Vue.set(state.internalCatalogData, data.key, {});
    Vue.set(state.internalCatalogData[data.key], 'data', data.columns);
    Vue.set(state.internalCatalogData[data.key], 'rows', data.rows);
    Vue.set(state.internalCatalogData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.internalCatalogData[data.key], 'metrics', data.metrics);
    Vue.set(state.internalCatalogData[data.key], 'page', data.page);
    Vue.set(state.internalCatalogData[data.key], 'load', false);
    Vue.set(state.internalCatalogData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.internalCatalogData[data.key], 'noData', true);
    }

    if (
      (data.rows &&
        data.rows.constructor === Array &&
        data.rows.length === 0) ||
      (data.rows &&
        data.rows.constructor === Object &&
        Object.keys(data.rows).length === 0)
    ) {
      Vue.set(state.internalCatalogData[data.key], 'noData', true);
    }
  },
  INTERNAL_CATALOG_RESET: (state, data) => {
    Vue.set(state.internalCatalogData, data.key, {});
    Vue.set(state.internalCatalogData[data.key], 'columns', []);
    Vue.set(state.internalCatalogData[data.key], 'rows', []);
    Vue.set(state.internalCatalogData[data.key], 'load', true);
    Vue.set(state.internalCatalogData[data.key], 'error', false);
    Vue.set(state.internalCatalogData[data.key], 'noData', false);
  },
  INTERNAL_CATALOG_ERROR: (state, data) => {
    Vue.set(state.internalCatalogData, data.key, {});
    Vue.set(state.internalCatalogData[data.key], 'columns', []);
    Vue.set(state.internalCatalogData[data.key], 'rows', []);
    Vue.set(state.internalCatalogData[data.key], 'load', false);
    Vue.set(state.internalCatalogData[data.key], 'error', true);
    Vue.set(state.internalCatalogData[data.key], 'noData', false);
  },
  SET_UPLOAD_STATUS: (state, data) => {
    state.uploadStatus = data;
  },
  SET_LAST_UPLOADED_STATUS: (state, data) => {
    state.lastUploadedStatus = data;
  },
  SET_NOTIFICATION_MESSAGE: (state, message) => {
    state.notificationMessage = message;
  },
  SET_STOP_UPLOAD_FLAG: (state, status) => {
    state.stopUploading = status;
  }
};

function showSnackbarNotification(context, message) {
  let msg;
  if (
    message &&
    message.response &&
    message.response.data &&
    message.response.data.message
  ) {
    msg = message.response.data.message;
  } else if (message && message.message) {
    msg = message.message;
  } else if (message && message.constructor === String) {
    msg = message;
  } else {
    msg = 'Error Occured. Please try again after sometime.';
  }
  if (msg.length > 150) {
    msg = msg.substring(0, 150) + '...';
  }
  context.commit('SET_NOTIFICATION_MESSAGE', msg);
}

const actions = {
  setStopUploadFlag: (context, status) => {
    context.commit('SET_STOP_UPLOAD_FLAG', status);
  },
  setUploadStatus: (context, data) => {
    context.commit('SET_UPLOAD_STATUS', data);
  },
  getExistingCatalogTableData: (context, data) => {
    HttpService.post('GET_EXISTING_INTERNAL_CATALOG').then((response) => {
      // response = dummyData.existingCatalogData;
      if (!response.data && !response.data.success) {
        context.commit('INTERNAL_CATALOG_ERROR', { key: data.meta.key });
      } else {
        const rows = response.data.response;
        if (
          rows &&
          rows.dimensionValueCountList &&
          rows.dimensionValueCountList.length > 0
        ) {
          context.commit('INTERNAL_CATALOG_SUCCESS', {
            key: data.meta.key,
            rows: rows,
            totalRows: rows.dimensionValueCountList.length,
            page: 1
          });
        } else {
          context.commit('INTERNAL_CATALOG_SUCCESS', {
            key: data.meta.key,
            rows: rows,
            page: 1
          });
        }
      }
    });
  },
  downloadExistingCatalogTable: (context) => {
    const promise = new Promise(function (resolve, reject) {
      resolve(
        HttpService.post('GET_EXISTING_INTERNAL_CATALOG').then((response) => {
          // response = dummyData.existingCatalogData;
          if (response.data && response.data.success) {
            const _aArray =
              response.data.response &&
              response.data.response.dimensionValueCountList;
            return _aArray;
          } else {
            return [];
          }
        })
      );
    });
    return promise;
  },
  getUploadedCatalogTableData: (context, params) => {
    const file = params.file;
    const widgetConfig = params.widgetConfig;
    context.commit('SET_UPLOAD_STATUS', 'Started');
    HttpService.post('UPLOAD_NEW_INTERNAL_CATALOG', file)
      .then((response) => {
        // response = dummyData.uploadedCatalogData;
        if (!response.success) {
          context.commit('SET_UPLOAD_STATUS', 'Failed');
          context.commit('INTERNAL_CATALOG_ERROR', {
            key: widgetConfig.meta.key
          });
        } else {
          context.commit('SET_UPLOAD_STATUS', 'Success');
          var _aArray = [];
          _aArray = response.response && response.response.newDimensions;
          const rows = {
            tableRows: _aArray,
            actualRows: response.response
          };
          if (_aArray.length > 0) {
            context.commit('INTERNAL_CATALOG_SUCCESS', {
              key: widgetConfig.meta.key,
              rows: rows,
              totalRows: _aArray.length,
              page: 1
            });
          } else {
            context.commit('INTERNAL_CATALOG_SUCCESS', {
              key: widgetConfig.meta.key,
              rows: [],
              page: widgetConfig.body.APIConfig.page
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        showSnackbarNotification(context, error);
        context.commit('INTERNAL_CATALOG_ERROR', {
          key: widgetConfig.meta.key
        });
      });
  },
  pushCatalog: (context, params) => {
    const file = params.file;
    const widgetConfig = params.widgetConfig;
    try {
      context.commit('SET_UPLOAD_STATUS', 'Started');
      HttpService.post('PUSH_INTERNAL_CATALOG', file)
        .then((response) => {
          // response = dummyData.uploadedCatalogData;
          const stopUploading = context.getters.getStopUploadingFlag;
          if (stopUploading) {
            context.commit('SET_UPLOAD_STATUS', 'Not Started');
            showSnackbarNotification(
              context,
              'Upload action cancelled by the user'
            );
            context.commit('SET_STOP_UPLOAD_FLAG', false);
          } else {
            if (!response.data && !response.data.success) {
              context.commit('SET_UPLOAD_STATUS', 'Failed');
              context.commit('INTERNAL_CATALOG_ERROR', {
                key: widgetConfig.meta.key
              });
            } else {
              showSnackbarNotification(context, 'File uploaded successfully.');
              context.commit('SET_UPLOAD_STATUS', 'Success');
              const rows = response.data.response;
              if (
                rows &&
                rows.dimensionValueCountList &&
                rows.dimensionValueCountList.length > 0
              ) {
                context.commit('INTERNAL_CATALOG_SUCCESS', {
                  key: widgetConfig.meta.key,
                  rows: rows,
                  totalRows: rows.dimensionValueCountList.length,
                  page: 1
                });
              } else {
                context.commit('INTERNAL_CATALOG_SUCCESS', {
                  key: widgetConfig.meta.key,
                  rows: [],
                  page: widgetConfig.body.APIConfig.page
                });
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
          showSnackbarNotification(context, error);
          context.commit('SET_UPLOAD_STATUS', 'Failed');
          context.commit('INTERNAL_CATALOG_ERROR', {
            key: widgetConfig.meta.key
          });
        });
    } catch (error) {
      console.error(error);
      context.commit('SET_UPLOAD_STATUS', 'Failed');
      showSnackbarNotification(context, error);
      context.commit('INTERNAL_CATALOG_ERROR', { key: widgetConfig.meta.key });
    }
  },
  mergeCatalog: (context, params) => {
    // let widgetConfig = params.widgetConfig;
    HttpService.post('MERGE_INTERNAL_CATALOG', params.requestPayload)
      .then((response) => {
        if (!response.data && !response.data.success) {
          context.commit('SET_UPLOAD_STATUS', 'Failed');
          showSnackbarNotification(context, 'Merge failed!');
        } else {
          context.commit('SET_UPLOAD_STATUS', 'Success');
          showSnackbarNotification(
            context,
            'Merge action completed successfully'
          );
          context.dispatch('showLastUploadedInfo', params);
        }
      })
      .catch((error) => {
        console.error(error);
        context.commit('SET_UPLOAD_STATUS', 'Failed');
        showSnackbarNotification(context, error);
      });
  },
  replaceCatalog: (context, params) => {
    // let widgetConfig = params.widgetConfig;
    HttpService.post('REPLACE_INTERNAL_CATALOG', params.requestPayload)
      .then((response) => {
        if (!response.data && !response.data.success) {
          context.commit('SET_UPLOAD_STATUS', 'Failed');
          showSnackbarNotification(context, 'Repace failed!');
        } else {
          context.commit('SET_UPLOAD_STATUS', 'Success');
          showSnackbarNotification(
            context,
            'Replace action completed successfully'
          );
          context.dispatch('showLastUploadedInfo', params);
        }
      })
      .catch((error) => {
        console.error(error);
        context.commit('SET_UPLOAD_STATUS', 'Failed');
        showSnackbarNotification(context, error);
      });
  },
  showLastUploadedInfo: (context, params) => {
    // let widgetConfig = params.widgetConfig;
    const requestPayload = {
      limit: 1,
      page: 1
    };
    HttpService.post('GET_LAST_UPLOADED_CATALOG_INFO', requestPayload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          context.commit('SET_UPLOAD_STATUS', 'Uploaded');
          context.commit('SET_LAST_UPLOADED_STATUS', response.data.response);
        } else {
          context.commit('SET_UPLOAD_STATUS', 'Not Started');
        }
      })
      .catch((error) => {
        console.error(error);
        showSnackbarNotification(context, error);
        context.commit('SET_UPLOAD_STATUS', 'Failed');
      });
  },
  removeUploadedCatalog: (context, params) => {
    // let widgetConfig = params.widgetConfig;
    HttpService.post('REMOVE_LAST_UPLOADED_CATALOG', params.requestPayload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          // delete data from lastUploadedStatus Object
          const arr = context.state.lastUploadedStatus.uploadList;
          const reqId = params.requestPayload.requestId;
          if (arr && arr.constructor === Array) {
            for (const [index, obj] of arr.entries()) {
              if (obj.requestId === reqId) {
                arr.splice(index, 1);
                break;
              }
            }
          }
          context.commit('SET_LAST_UPLOADED_STATUS', { uploadList: [] });
          showSnackbarNotification(context, 'Removed successfully.');
          context.commit('SET_UPLOAD_STATUS', 'Not Started');
        } else {
          context.commit('SET_UPLOAD_STATUS', 'Not Started');
        }
      })
      .catch((error) => {
        console.error(error);
        showSnackbarNotification(context, error);
        context.commit('SET_UPLOAD_STATUS', 'Failed');
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
