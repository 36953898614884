import { commons } from '@/components/ams/campaigns/commons.js';
import HttpService from '@/utils/services/http-service';
import {
  emailRequestConfig,
  areSelectedCampaignsDistinct,
  getDataMismatchComponentObjectArray,
  getAdSkusTabPrimaryKey,
  getBulkSearchConfigs,
  getPinAndUnpinMessageForCalendarPin,
  getChartWidgetHeaderOptionProps,
  getInitRequestParams
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils.js';
const chartingWb = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  import('@/components/widgets/collaboration/collaboration.vue');

const iconHeader = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const DataMismatchBanner = () =>
  import(
    '@/pages/dashboard_service_campaign_management/citrusAds/DataMismatchBanner.vue'
  );

function getNames(retailer, advancedReporting) {
  if (advancedReporting) {
    return {
      listApiPageName: `${retailer}_cm_skus_ar`,
      chartWidgetName: `${retailer}_cm_skus_charting_wb_ar`,
      tableWidgetName: `${retailer}_cm_skus_table_ar`,
      localStorageFilterKey: `${retailer}-skus-filter_ar`
    };
  }
  return {
    listApiPageName: `${retailer}_cm_skus`,
    chartWidgetName: `${retailer}_cm_skus_charting_wb`,
    tableWidgetName: `${retailer}_cm_skus_table`,
    localStorageFilterKey: `${retailer}-skus-filter`
  };
}

export function adSkusConfig(retailer, advancedReporting) {
  const tabName = 'Ad SKUs';
  const {
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey
  } = getNames(retailer, advancedReporting);
  const PRIMARY_KEY = getAdSkusTabPrimaryKey(retailer);
  return {
    PRIMARY_KEY: PRIMARY_KEY,
    entity: 'adskus',
    styleClass: 'orders generic-campaign-management',
    // list API data
    pageInfo: {
      name: listApiPageName,
      title: 'Ad SKUs'
    },
    initRequestParams: getInitRequestParams(
      'Ad SKUs',
      listApiPageName,
      chartWidgetName,
      tableWidgetName
    ),
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      DataMismatchBanner: DataMismatchBanner
    },
    // header options passed to dashboard_inflator
    headerOptions: (context) => {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: getChartWidgetHeaderOptionProps(context),
        [tableWidgetName]: {
          props: {
            headerOptions: [
              ...getDataMismatchComponentObjectArray(retailer),
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    {
                      icon: 'show-chart',
                      title: 'PLOT ROWS',
                      id: 'show-chart',
                      removeSelectionState: false,
                      handler(selectionLength, dropDownSelection, selections) {
                        // here {this} is bound to the cw_actionsBar component
                        this.$emit('handlePlotRows', selections);
                      }
                    },
                    {
                      icon: 'task-status',
                      title: 'Edit Status',
                      id: 'edit-status',
                      dropDown: true,
                      dropDownOptions: [
                        { title: 'Delete', value: 'Delete' },
                        { title: 'Restore', value: 'Restore' }
                      ],
                      disabled: !areSelectedCampaignsDistinct(
                        context.rowSelections,
                        'citrus_cm_sku_campaign_id'
                      ),
                      tippy: !areSelectedCampaignsDistinct(
                        context.rowSelections,
                        'citrus_cm_sku_campaign_id'
                      )
                        ? 'Action is not allowed for multiple SKUs under the same campaign. Please use Remove/Add functionality on Campaign Management tab for that.'
                        : '',
                      async handler(
                        selectionLength,
                        dropDownSelection,
                        selections
                      ) {
                        if (dropDownSelection[0].value === 'Delete') {
                          const payload = [];
                          selections.forEach((selectedSkuRow) => {
                            const campaignId =
                              selectedSkuRow?.data?.citrus_cm_sku_campaign_id;
                            payload.push({
                              widget: 'amsSkus',
                              actionType: `${retailer}CampaignRemoveProduct`,
                              actionSource: {
                                pageUrl: window.location.href
                              },
                              primaryKey: selectedSkuRow?.data?.[PRIMARY_KEY], // sku_catalog_id
                              actionPayload: {
                                campaignId: campaignId,
                                campaignType: 'productAds',
                                remove_product:
                                  selectedSkuRow?.data
                                    ?.citrus_cm_sku_product_code,
                                remove_product_catalog_id:
                                  selectedSkuRow?.data?.citrus_cm_sku_catalog_id
                              },
                              viewPayload: {
                                campaignId: campaignId,
                                campaignType: 'productAds',
                                product_code:
                                  selectedSkuRow?.data
                                    ?.citrus_cm_sku_product_code,
                                product_name:
                                  selectedSkuRow?.data?.citrus_cm_sku_sku_title,
                                sku_image_url:
                                  selectedSkuRow?.data
                                    ?.citrus_cm_sku_latest_image_url
                              },
                              previousEntityValue: '',
                              newEntityValue: ''
                            });
                          });
                          try {
                            await HttpService.post(
                              'AMS_ACTIONS_ACTION_LOG',
                              payload
                            );
                            this.$emit(
                              'openSnackbar',
                              'Action has been submitted'
                            );
                          } catch (err) {
                            console.log(err);
                            this.$emit('openSnackbar', 'Something went wrong.');
                          }
                        }
                        if (dropDownSelection[0].value === 'Restore') {
                          const payload = [];
                          selections.forEach((selectedSkuRow) => {
                            const campaignId =
                              selectedSkuRow?.data?.citrus_cm_sku_campaign_id;
                            const tempPayload = {
                              widget: 'amsSkus',
                              actionType: `${retailer}CampaignAddBulkProducts`,
                              actionSource: {
                                pageUrl: window.location.href
                              },
                              primaryKey: selectedSkuRow?.data?.[PRIMARY_KEY],
                              actionPayload: null,
                              viewPayload: null,
                              previousEntityValue: '',
                              newEntityValue:
                                selectedSkuRow?.data?.citrus_cm_keyword_keyword
                            };
                            tempPayload.actionPayload = {
                              campaignId: campaignId,
                              campaignType: 'productAds',
                              products: [
                                {
                                  new_product:
                                    selectedSkuRow?.data
                                      ?.citrus_cm_sku_product_code,
                                  new_product_catalog_id:
                                    selectedSkuRow?.data
                                      ?.citrus_cm_sku_catalog_id
                                }
                              ]
                            };
                            tempPayload.viewPayload = {
                              spreadViewPayload: true,
                              viewPayloadArray: [
                                {
                                  campaignId: campaignId,
                                  campaignType: 'productAds',
                                  product_code:
                                    selectedSkuRow?.data
                                      ?.citrus_cm_sku_product_code,
                                  product_name:
                                    selectedSkuRow?.data
                                      ?.citrus_cm_sku_sku_title,
                                  sku_image_url:
                                    selectedSkuRow?.data
                                      ?.citrus_cm_sku_latest_image_url
                                }
                              ]
                            };
                            payload.push(tempPayload);
                          });
                          try {
                            HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                            this.$emit(
                              'openSnackbar',
                              'Action has been submitted'
                            );
                          } catch (err) {
                            console.log(err);
                            this.$emit('openSnackbar', 'Something went wrong.');
                          }
                        }
                      }
                    }
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...(!context.rowSelections.length
                ? [
                    {
                      ui_component: 'iconTextActions',
                      props: {
                        action: { title: 'Email', icon: 'email-subscription' },
                        mode: 'default',
                        applyPadding: false
                      },
                      events: {
                        clicked: () => {
                          context.showModal = true;
                        }
                      },
                      order: 400
                    }
                  ]
                : [])
            ],
            enableManageColumns: !context.rowSelections.length,
            headerContainerGridStyles: {
              ...(context.rowSelections.length
                ? { padding: 0 }
                : {
                    display: 'flex',
                    'justify-content': 'flex-end',
                    'align-items': 'center'
                  })
            },
            searchParams: {
              show: false
            },
            gridOptions: context.gridOptions,
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: context.handleSelectionChange
            },
            rowHeight: 80
            // selections: context.rowSelections
          }
        }
      };
    },
    filterSettings: {
      emitEvent: `${retailer}FilterApply`,
      uiKey: 0,
      show: true,
      // to do update backend cube
      backendCube: 'citrus_campaigns_workbench',
      defaultDatePostRange: 'last30Days',
      enableSave: true,
      enableRemoveAll: true,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: localStorageFilterKey,
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: 'Search for a SKU',
        // todo update search dimension
        searchDimension: 'search'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      pinMessage: getPinAndUnpinMessageForCalendarPin().pinMessage,
      unpinMessage: getPinAndUnpinMessageForCalendarPin().unpinMessage,
      additionalRanges: commons.additionalDateRanges(),
      bulkSearchConfigs: getBulkSearchConfigs(retailer, tabName, {
        findEntityInputPlaceholder: 'Find a SKU',
        subTitle: 'SKUs',
        dimensionNameMapping: 'product_code'
      })
    },
    widgetNames: {
      chart: chartWidgetName,
      table: tableWidgetName
    },
    getActivityData: (data, key) => ({
      params: { widget: 'amsSkus', primaryKey: data[key] }
    }),
    modal: {
      modalConfig: emailRequestConfig,
      clickedOutsideModal(dashboardWrapperVueRef) {
        dashboardWrapperVueRef.showModal = false;
        this.modalConfig = emailRequestConfig;
      }
    }
  };
}
