<template>
  <span :class="styleClass">
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    styleClass: {
      type: String,
      default:
        'u-spacing-mr-s u-font-size-7 u-color-grey-white u-bg-color-orange-base u-spacing-pv-xxs u-spacing-ph-s u-border-radius-xl u-font-weight-600'
    },
    text: {
      type: String,
      default: 'BETA'
    }
  }
};
</script>

<style></style>
