import HomeV2 from '@/components/homeV2.vue';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import urlHelper from '@/utils/helpers/url';
import { commonInternalRoutesArray } from '@/router/albertsons.js';

const bestbuyRoutes = {
  path: '/us/bestbuy/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          'feature.bestbuy.home',
          'digital_shelf/market_insights'
        );
      }
    },
    {
      path: 'digital_shelf',
      name: 'digital_shelf',
      redirect() {
        return 'digital_shelf/market_insights';
      },
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    ...commonInternalRoutesArray
  ]
};

export { bestbuyRoutes };
