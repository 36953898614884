<template>
  <!-- Add Negative Keywords Panel -->
  <section
    class="addNegativeKeywordPanel actionsPanel u-bg-color-grey-white u-position-relative"
  >
    <div
      v-show="
        selections.length > 1 &&
        !isMultiple &&
        !bulkActionsAllowed &&
        !forceAllowForMultiple
      "
      class="fill-message-negative-keywords fill--parent u-font-size-3"
      style="z-index: 90"
    >
      Multiple campaigns are not allowed
    </div>
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="keywordsLoading || addGroupLoader || load || adGroupsLoading"
      :color="'#007cf6'"
    />
    <section
      :class="{
        visibilityHidden:
          keywordsLoading || addGroupLoader || load || adGroupsLoading
      }"
      class="containerVisibilty"
    >
      <baseSplitPanelTemplate
        v-if="
          (selections.length === 1 && !bulkActionsAllowed) ||
          forceAllowForMultiple
        "
        :panel-config="panelConfig"
        @updatedPanelData="updatePanelData"
      >
        <div
          slot="source-body"
          class="u-spacing-p-m"
        >
          <div v-if="defaultBid !== undefined && defaultBid !== null">
            <span class="u-font-size-5 u-color-grey-base u-font-weight-600">
              Default Bid:
            </span>
            <InputComponent
              v-model="userDefinedDefaultBid"
              class="u-spacing-mt-s"
              placeholder="Default Bid"
            />
          </div>
          <div
            v-if="showAddKeywordsError"
            class="u-spacing-mt-xs u-flex-0 u-font-size-7 u-color-red-base"
          >
            {{ bidConfig.addKeywordsErrorMessage }}
          </div>
          <div
            v-if="hasCustomCheckBoxOptions === true && customCheckBoxOptions"
            class="u-display-flex u-flex-align-items-center"
          >
            <div
              class="u-font-size-5 u-color-grey-base u-font-weight-600 u-spacing-mr-s"
            >
              Match type:
            </div>
            <SelectRow
              class="u-font-size-5 u-spacing-mb-m"
              :input-element-height="'16px'"
              :input-element-width="'16px'"
              :is-expanded="true"
              :options="customCheckBoxOptions"
              :original-selections="customSelectedChecks"
              @selection="handleSelection"
            />
          </div>
          <div
            class="u-display-flex u-width-100 u-flex-justify-content-space-between u-flex-direction-column"
          >
            <rb-input
              ref="searchBox"
              v-model="keywordTextInput"
              rows="2"
              :placeholder="placeholder"
              type="textarea"
              class="u-flex-1 search--input-s input-text-height"
              :rounded="false"
            />
            <div
              class="u-width-100 u-display-flex u-flex-justify-content-space-between u-flex-direction-row u-spacing-pv-s"
            >
              <div class="u-font-size-7">
                You may add upto 1000 {{ actionName }}s.
              </div>
              <div class="u-spacing-pt-s">
                <rb-button
                  :click-fn="addKeywordsFn"
                  :disabled="isAddKeywordsDisabled"
                  text="Add keywords"
                  size="s"
                  icon-left="right-arrow"
                  type="filled"
                  class="u-color-grey-white"
                  style="border-left: none"
                />
              </div>
            </div>
          </div>
        </div>
        <div slot="result-body">
          <div class="tableBodyLeftNegativeKeywords">
            <div v-if="enableAdGroup">
              <div
                class="u-spacing-ph-l u-spacing-pb-s u-spacing-pt-l u-font-weight-600 u-font-size-5"
              >
                Ad group:
              </div>
              <div
                v-if="
                  adGroups.length > 0 && !showTippyTableHeaderForAdGroupsList
                "
                class="u-spacing-ph-l u-spacing-pb-m u-max-width-50 u-display-flex"
              >
                <rb-select
                  class-name="bp-select"
                  :is-multi-select="true"
                  class="u-spacing-mr-s valueDropdown u-max-width-100"
                  :send-details="true"
                  :on-close="adGroupSelected"
                  :options="adGroups"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-ph-xs u-spacing-pv-xxs u-cursor-pointer"
                  >
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis"
                    >
                      {{ adGroupTitle }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                      :icon="'caret-down'"
                    />
                  </div>
                  <div
                    v-if="adGroups.length"
                    slot="footer"
                    class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
                  >
                    <rb-button
                      :click-fn="clickOutside"
                      :type="'filled'"
                      text="Apply"
                      size="s"
                    />
                  </div>
                </rb-select>
                <div class="dummy-element">&nbsp;</div>
              </div>
              <div
                v-else-if="showTippyTableHeaderForAdGroupsList && !load"
                class="u-spacing-ph-l u-spacing-pb-m"
              >
                <div
                  v-if="tippyTableHeaderAdGroupsList.length > 0"
                  class="u-display-flex u-flex-align-items-flex-end u-width-50"
                >
                  <div
                    class="u-border-radius-s u-border-all u-border-width-xs u-border-color-grey-xxx-light u-font-size-6 u-spacing-p-xs u-display-inline-block u-spacing-mr-s u-text-overflow-ellipsis"
                  >
                    {{ tippyTableHeaderAdGroupsList[0].campaign_name }}... +
                    {{ tippyTableHeaderAdGroupsList.length - 1 }} more
                  </div>
                  <TippyTableWithHeader
                    header-text="Ad Groups"
                    :column-definition="
                      widgetsConfig.widgets.addNegativeKeywords
                        .groupsTableColDefinition
                    "
                    :data-rows="tippyTableHeaderAdGroupsList"
                    @onSelectionChange="onAdGroupsSelectionsChange"
                  >
                    <rb-button
                      slot="trigger-content"
                      class="edit-groups-btn"
                      :text="'Edit'"
                      :type="'filled'"
                      size="s"
                      icon-left="pencil"
                    />
                  </TippyTableWithHeader>
                </div>
                <div
                  v-else
                  class="u-font-size-5 u-spacing-pb-s u-spacing-mb-s u-spacing-ph-l u-color-red-base"
                >
                  Ad groups for the selected campaigns are not present, hence no
                  action can be taken.
                </div>
              </div>
              <div
                v-else
                class="u-font-size-5 u-spacing-pb-m u-spacing-ph-l u-color-red-base"
              >
                Ad groups for this campaign are not present, hence no action can
                be taken.
              </div>
            </div>
            <div
              class="u-position-relative table-left"
              :style="{ height: computedTableConfig.resultTableHeight }"
            >
              <div
                v-if="getNegativeKeywordsData.rows.length === 0"
                class="fill--parent message"
              >
                No keywords added.
              </div>
              <rb-insights-table
                v-else
                :config="tableConfigs"
                :grid-options="gridOptions"
                :auto-configure="getNegativeKeywordsData"
                style="width: 100%"
                :row-height="48"
                :client-side-pagination="true"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="false"
                :pagination="true"
                :pagination-total-key="getNegativeKeywordsData.rows.length"
                :pagination-per-page-limit="25"
                :download-this-table="false"
                :get-current-instance="getCurrentResultInstance"
                :row-no-hover-state="true"
              />
            </div>
          </div>
        </div>
      </baseSplitPanelTemplate>
      <BulkActions
        v-if="bulkActionsAllowed"
        :radio-options="bulkRadioOptions"
        :heading="bulkActionsHeader"
        :radio-heading="bulkActionConfigs.radioHeading"
        :secondary-text="bulkActionSecondaryText"
        :custom-state-map="bulkActionConfigs.customStateMap"
        :groups-table-col-definition="
          bulkActionConfigs.groupsTableColDefinition
        "
        :groups-rows="adGroupsOfSelectedCampaigns"
        @handleRadioSelection="handleRadioSelection"
        @onSelectionChange="onAdGroupsSelectionsChange"
      />
      <div
        v-if="noteAboveFooter"
        class="u-font-size-6 u-font-weight-600 u-color-blue-base u-spacing-pb-l u-spacing-ph-l"
      >
        {{ noteAboveFooter }}
      </div>
      <footer
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
      >
        <rb-button
          :disabled="isApplyDisabled"
          :click-fn="onApply"
          :text="'Apply'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s"
        />
        <rb-button
          :click-fn="closePanel"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
          class="u-spacing-mr-s"
        />
        <div
          v-if="showAddedKeywordsError"
          class="u-spacing-mt-xs u-flex-0 u-font-size-7 u-color-red-base"
        >
          {{ bidConfig.addedKeywordsErrorMessage }}
        </div>
        <div
          v-if="showValueError"
          class="u-spacing-ph-xs u-spacing-pt-xs u-flex-0 u-font-size-7 u-color-red-base"
        >
          {{ errorMessage }}
        </div>
      </footer>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons } from '@/components/ams/campaigns/commons.js';
import baseSplitPanelTemplate from './baseSplitPanelTemplate.vue';
import { cloneDeep, camelCase } from 'lodash';
import SelectRow from '@/components/pages/insights/amazon/share-of-voice/atoms/select-row.vue';
import InputComponent from '@/components/pages/strategy_builder/steps/input-component.vue';
import BulkActions from '@/components/ams/actionPanels/bulk-actions.vue';
import TippyTableWithHeader from '@/components/ams/actionPanels/table-tooltip.vue';

export default {
  components: {
    loader,
    messageBar,
    baseSplitPanelTemplate,
    SelectRow,
    InputComponent,
    BulkActions,
    TippyTableWithHeader
  },
  props: {
    primaryKey: {
      type: String,
      default: null
    },
    widget: {
      type: String,
      default: null
    },
    defaultBid: {
      type: Number,
      default: null
    },
    bidConfig: {
      type: Object,
      default: function () {
        return {};
      }
    },
    hasCustomCheckBoxOptions: {
      type: Boolean,
      default: false
    },
    customCheckBoxOptions: {
      type: Array,
      default: null
    },
    defaultSelectedCustomCheckBoxOptions: {
      type: Array,
      default: null
    },
    apiVersion: {
      type: String,
      default: 'worklog'
    },
    actionName: {
      default: 'negative keyword',
      type: String
    },
    actionType: {
      default: 'addNegativeKeyword',
      type: String
    },
    isMultiple: {
      default: false,
      type: Boolean
    },
    enableAdGroup: {
      default: true,
      type: Boolean
    },
    filterConfig: {
      type: Object,
      default: function () {
        return {
          header: 'Match type:',
          filterStyle: {
            'Negative phrase': 'width:200px'
          },
          items: ['Negative phrase', 'Negative exact']
        };
      }
    },
    tableConfig: {
      type: Object,
      default: function () {
        return {
          sourceTableLabel: 'Add Negative Keywords',
          resultTableLabel: 'Added Negative Keywords',
          sourceTableWidth: '39%',
          resultTableWidth: '51%',
          resultTableHeight: '295px !important'
        };
      }
    },
    placeholder: {
      type: String,
      default: 'Enter keywords seperated by new line'
    },
    actionSubLabel: {
      type: String,
      default: 'CAMPAIGN'
    },
    fetchedAdgroups: {
      type: Array,
      default: () => {
        return [];
      }
    },
    load: {
      default: false,
      type: Boolean
    },
    parentAdgroup: {
      default: false,
      type: Boolean
    },
    defaultMatchType: {
      default: '',
      type: String
    },
    useDefaultMatchType: {
      default: false,
      type: Boolean
    },
    actionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    widgetsConfigRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    matchTypes: {
      type: Object,
      default: function () {
        return {
          'Negative exact': 'negativeExact',
          'Negative phrase': 'negativePhrase',
          exact: 'exact'
        };
      }
    },
    bulkActionsAllowed: {
      type: Boolean,
      default: false
    },
    forceAllowForMultiple: {
      type: Boolean,
      default: false
    },
    showTippyTableHeaderForAdGroupsList: {
      type: Boolean,
      default: false
    },
    noteAboveFooter: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userDefinedDefaultBid: null,
      customSelectedChecks: null,
      keywordsLoading: false,
      adGroups: [],
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      selectedAdGroups: [],
      applyClicked: false,
      previouslySelectedAdGroupData: [],
      showValueError: false,
      keywordTextInput: '',
      resultTableData: [],
      rbTableResultInstance: [],
      widgetsConfig: {},
      resultTableMetadata: [],
      tableConfigs: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      panelData: {
        sourceCheckBox: [],
        resultCheckBox: []
      },
      showAddKeywordsError: false,
      showAddedKeywordsError: false,
      bulkActionConfigs: {},
      bulkRadioOptions: [],
      targetingType: '',
      bulkActionSecondaryText: []
    };
  },
  computed: {
    tippyTableHeaderAdGroupsList() {
      const campaignIdMap = {};
      const finalAdGroupsList = this.adGroups.reduce((list, group) => {
        list.push({
          ...group,
          campaignName: campaignIdMap[group.campaign_id]
            ? ' '
            : group.campaign_name,
          select: ' '
        });

        campaignIdMap[group.campaign_id] = !campaignIdMap[group.campaign_id]
          ? 1
          : campaignIdMap[group.campaign_id] + 1;

        return list;
      }, []);

      return finalAdGroupsList || [];
    },
    computedTableConfig() {
      return this.actionName === 'keyword'
        ? {
            sourceTableLabel: 'Add Keywords',
            resultTableLabel: 'Added Keywords',
            sourceTableWidth: '39%',
            resultTableWidth: '51%',
            resultTableHeight: '295px !important'
          }
        : this.tableConfig;
    },
    bulkActionsHeader() {
      return `ADD NEGATIVE TARGETS (${this.selections.length} ${
        this.selections.length === 1 ? ' CAMPAIGN' : ' CAMPAIGNS'
      } SELECTED)`;
    },
    adGroupsLoading() {
      return this.$store.getters.getCampaignsAdGroups?.load;
    },
    adGroupsOfSelectedCampaigns() {
      const { rows, error } = this.$store.getters.getCampaignsAdGroups || {};
      const campaignIdMap = {};
      const targetedGroups = rows.reduce((list, group) => {
        if (
          this.targetingType === group.targeting_type ||
          (group.campaign_targeting_type || '').toLowerCase() === 'auto'
        ) {
          list.push({
            ...group,
            campaignName: campaignIdMap[group.campaign_id]
              ? ' '
              : group.campaign_name,
            select: ' '
          });

          campaignIdMap[group.campaign_id] = !campaignIdMap[group.campaign_id]
            ? 1
            : campaignIdMap[group.campaign_id] + 1;
        }

        return list;
      }, []);
      const numOfTargetedCampaigns = Object.keys(campaignIdMap).length;

      this.bulkActionSecondaryText = [
        `${numOfTargetedCampaigns}/${this.selections.length} campaign${
          numOfTargetedCampaigns > 1 ? 's' : ''
        } having ${targetedGroups.length}/${rows.length} ad group${
          targetedGroups.length > 1 ? 's' : ''
        }`,
        ` are eligible for adding ${this.targetingType}s as negative targets. 
        Choose ${this.targetingType}s to help your products appear in shopper searches.`
      ];

      return !error ? targetedGroups : [];
    },
    isDefaultBidValid() {
      return (
        this.userDefinedDefaultBid !== null &&
        this.userDefinedDefaultBid !== undefined
      );
    },
    areCheckboxSelectionsValid() {
      if (!this.customSelectedChecks) {
        return true;
      }
      return this.customSelectedChecks.length > 0;
    },
    isAddKeywordsDisabled() {
      if (this.enableAdGroup) {
        return (
          this.keywordTextInput.length === 0 ||
          (this.parentAdgroup
            ? !this.parentAdgroup
            : this.panelData.sourceCheckBox.length === 0) ||
          !this.areCheckboxSelectionsValid ||
          (!this.isDefaultBidValid && this.showAddKeywordsError)
        );
      } else {
        return (
          this.keywordTextInput.length === 0 ||
          this.panelData.sourceCheckBox.length === 0 ||
          !this.areCheckboxSelectionsValid ||
          this.showAddKeywordsError
        );
      }
    },
    isApplyDisabled() {
      if (this.enableAdGroup) {
        return this.getNegativeKeywordsData.rows.length < 1 ||
          this.selectedAdGroups.length < 1 ||
          this.bulkActionsAllowed
          ? this.adGroupsOfSelectedCampaigns < 1
          : this.adGroups.length < 1 || this.showAddedKeywordsError;
      } else {
        return this.getNegativeKeywordsData.rows.length < 1;
      }
    },
    adGroupTitle() {
      let text = '';
      if (this.selectedAdGroups.length === 0) {
        text = 'Please select an ad group';
      } else {
        this.selectedAdGroups.forEach((item) => {
          if (item) {
            text = text + item.title + ', ';
          }
        });
        text = text.slice(0, -2);
      }
      return text;
    },
    fetchedAdGroups() {
      return this.$store.getters?.getAdGroupsFromCampaignId?.APIData || [];
    },
    addGroupLoader() {
      return this.$store.getters.getAddGroupLoader;
    },
    panelConfig() {
      const campaignValue = this.actionSubLabel
        ? this.selections.length === 1
          ? this.actionSubLabel
          : `${this.actionSubLabel}S`
        : null;
      let filter = {
        header: 'Match type:',
        filterStyle: {
          'Negative phrase': 'width:200px'
        },
        items: ['Negative phrase', 'Negative exact']
      };
      filter = { ...this.filterConfig };
      if (this.useDefaultMatchType || this.hasCustomCheckBoxOptions) {
        filter = null;
      }
      return {
        showBaseTemplate: true,
        header:
          'ADD ' +
          this.actionName.toUpperCase() +
          'S (' +
          this.selections.length +
          ' ' +
          campaignValue +
          ' SELECTED)',
        source: {
          filter,
          width: this.computedTableConfig.sourceTableWidth,
          header: this.computedTableConfig.sourceTableLabel
            ? this.computedTableConfig.sourceTableLabel
            : 'Add Keywords'
        },
        result: {
          width: this.computedTableConfig.resultTableWidth,
          header: this.computedTableConfig.resultTableLabel
            ? `${this.computedTableConfig.resultTableLabel} (${this.resultTableData.length})`
            : 'Added Keywords (' + this.resultTableData.length + ')'
        }
      };
    },
    getNegativeKeywordsData() {
      return {
        columns: this.resultTableMetadata,
        rows: this.resultTableData
      };
    }
  },
  watch: {
    selections(newValue) {
      if (newValue.length === 0) {
        this.closePanel();
      } else if (newValue.length === 1) {
        this.adGroups = [];
        this.getAdGroupsForCampaign(this.selections[0].data);
      }
      if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(newValue);
    },
    fetchedAdgroups() {
      this.getAdGroupsForCampaign();
    },
    fetchedAdGroups(newVal) {
      this.selectedAdGroups = [];
      this.adGroups = [];
      if (newVal?.length > 0) {
        newVal.map((item) => {
          item.title = item.adgroup_name;
          item.selected = false;
        });
        this.adGroups = newVal;
        if (!this.bulkActionsAllowed) {
          this.adGroups[0].selected = true;
          this.selectedAdGroups = [this.adGroups[0]];
        }
      }
      this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
    },
    userDefinedDefaultBid(newVal) {
      if (newVal) {
        this.showAddKeywordsError = !this.isBidValid(
          newVal,
          this.bidConfig.minBid,
          this.bidConfig.maxBid
        );
      }
    },
    resultTableData() {
      // This means a bid config to show error has been passed down as prop, hence check for min/max bid conditions
      if (this.bidConfig.addedKeywordsErrorMessage) {
        this.loopThroughResultTableDataAndValidate();
      }
    }
  },
  created() {
    this.widgetsConfig = this.widgetsConfigRef.config(this);
    this.resultTableMetadata =
      this.widgetsConfig.widgets.addNegativeKeywords.tableConfigs.metadata.resultTable;
    this.bulkActionConfigs =
      this.widgetsConfig.widgets.addNegativeKeywords?.bulkActionConfigs;
    this.bulkRadioOptions = this.bulkActionConfigs?.radioOptions;
    this.targetingType = this.bulkActionConfigs?.radioOptions?.[0]?.type;
    this.getAdGroupsForCampaign(this.selections?.[0].data);
    if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(this.selections);
    this.customSelectedChecks = cloneDeep(
      this.defaultSelectedCustomCheckBoxOptions
    );
    this.userDefinedDefaultBid = cloneDeep(this.defaultBid);
  },
  methods: {
    onAdGroupsSelectionsChange(selectedGroups) {
      this.selectedAdGroups = selectedGroups;
    },
    handleRadioSelection(newValue) {
      this.targetingType = newValue;
    },
    isBidValid(bidToValidate, minBid, maxBid) {
      // This method is called only on very few scenarios with min/max bid restriction
      // The check below is added to not allow the user to go forward if the value in the
      // input box is empty. Doing a direct check of if(bidToValidate) will also consider 0, hence not doing that
      if (
        bidToValidate === '' ||
        bidToValidate === undefined ||
        bidToValidate === null
      )
        return false;
      if (bidToValidate < minBid) return false;
      if (bidToValidate > maxBid) return false;
      return true;
    },
    setDefaultBid(bid) {
      this.resultTableData = this.resultTableData.map((row) => {
        if (row.newBid === undefined) {
          row.newBid = this.userDefinedDefaultBid;
        }
        return row;
      });
    },
    handleSelection(selections) {
      this.customSelectedChecks = selections;
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    adGroupSelected(context, data) {
      if (this.applyClicked) {
        this.selectedAdGroups = [];
        this.selectedAdGroups = data.filter((item) => item.selected);
        this.applyClicked = false;
        this.$nextTick(() => {
          this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.previouslySelectedAdGroupData.length; i++) {
            if (!this.previouslySelectedAdGroupData[i].selected) {
              delete this.adGroups[i].selected;
            } else {
              this.adGroups[i].selected = true;
            }
          }
        });
      }
    },
    getAdGroupsForCampaign(data) {
      if (this.parentAdgroup) {
        this.adGroups = this.fetchedAdgroups.map((item) => {
          return {
            ...item,
            adgroup_id: item.ad_group_id,
            adgroup_name: item.ad_group_name,
            title: item.ad_group_name,
            campaign_id: item.campaign_id
          };
        });
        return;
      }
      const dimensionNameValueList = [
        {
          dimensionName: 'campaign_id',
          operator: 'EQUAL_TO',
          dimensionValue: data.campaign_id
        },
        {
          dimensionName: 'state',
          operator: 'LIKE',
          dimensionValue: 'enabled'
        }
      ];
      this.widgetsConfig.widgets.fetchAddGroups.body.APIConfig.where.dimensionNameValueList =
        dimensionNameValueList;
      this.$store.dispatch(
        'fetchAdGroupsFromCampaignId',
        this.widgetsConfig.widgets.fetchAddGroups
      );
    },
    getAdGroupsForCampaigns(selectedCampaigns) {
      const payload = {
        ...this.bulkActionConfigs.APIConfig
      };
      const dimensionNameValueList = selectedCampaigns.map(({ data }) => ({
        dimensionName: 'campaign_id',
        dimensionValue: data.campaign_id
      }));
      payload.where.dimensionNameValueList = dimensionNameValueList;
      this.$store.dispatch('fetchCampaignsAdGroups', payload);
    },
    addKeywordsFn() {
      const keywords = this.keywordTextInput.split(/\r?\n/);
      const uniqueKeywords = [];
      let keywordNotPresent = true;
      let keywordsExist = false;
      let duplicatePresent = false;
      for (const value of keywords) {
        if (uniqueKeywords.indexOf(value) === -1) {
          uniqueKeywords.push(value);
        } else {
          duplicatePresent = true;
        }
      }
      uniqueKeywords.forEach((keyword) => {
        if (this.useDefaultMatchType) {
          for (const value of this.resultTableData) {
            if (value.keyword === keyword.trim()) {
              keywordNotPresent = false;
              keywordsExist = true;
            }
          }
        }
        if (this.useDefaultMatchType && keywordNotPresent) {
          this.resultTableData.push({
            keyword: keyword.trim(),
            match_type: this.defaultMatchType
          });
        } else {
          let checkBoxSelectionsToLoopThrough;
          if (
            this.customCheckBoxOptions !== null &&
            this.customCheckBoxOptions !== undefined
          ) {
            checkBoxSelectionsToLoopThrough = this.customSelectedChecks.map(
              (item) => item.label
            );
          } else {
            checkBoxSelectionsToLoopThrough = this.panelData.sourceCheckBox;
          }
          checkBoxSelectionsToLoopThrough.forEach((matchType) => {
            if (keyword.trim().length > 0) {
              keywordNotPresent = true;
              for (const value of this.resultTableData) {
                if (
                  value.keyword === keyword.trim() &&
                  value.match_type === matchType
                ) {
                  keywordNotPresent = false;
                  keywordsExist = true;
                }
              }
              if (keywordNotPresent) {
                this.resultTableData.push({
                  keyword: keyword.trim(),
                  match_type: matchType
                });
              }
            }
          });
        }
      });
      if (
        this.userDefinedDefaultBid !== null &&
        this.userDefinedDefaultBid !== undefined
      ) {
        this.setDefaultBid();
      }

      if (this.resultTableData.length >= 1000) {
        const remainingKeywords = this.resultTableData.length - 1000;
        this.resultTableData = this.resultTableData.slice(0, 1000);
        this.snackbar(
          'Unable to add the last ' +
            remainingKeywords +
            ' keywords (You may add upto 1000' +
            this.actionName +
            ')',
          6000
        );
      }
      if (keywordsExist && duplicatePresent) {
        this.snackbar(
          'Duplicate keyword(s) removed, keyword(s) & match-type(s) combination already exists.',
          6000
        );
      } else if (keywordsExist) {
        this.snackbar(
          'Keyword(s) & match-type(s) combination already exists.',
          6000
        );
      } else if (duplicatePresent) {
        this.snackbar('Duplicate keyword(s) removed.', 6000);
      }
      this.keywordTextInput = '';
    },
    snackbar(message, timer) {
      this.$snackbar.open({
        message: message,
        duration: timer,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    removeAllKeywords() {
      this.resultTableData = [];
      this.tableConfigs.body.APIConfig.page = 1;
    },
    removeKeyword(context) {
      this.resultTableData = this.resultTableData.filter((item) => {
        if (JSON.stringify(item) !== JSON.stringify(context.params.data)) {
          return item;
        }
      });
    },
    getCurrentResultInstance(context) {
      this.rbTableResultInstance = context;
    },
    updatePanelData(data) {
      this.panelData = data;
    },
    deleteFromActionAndViewPayload(obj, key) {
      delete obj.actionPayload[key];
      delete obj.viewPayload[key];
    },
    addToActionAndViewPayload(obj, key, data) {
      obj.actionPayload[key] = data;
      obj.viewPayload[key] = data;
    },
    getInitialPayloadObject(keywordsObj, adGroup) {
      const matchTypes = this.matchTypes;
      let data = this.selections[0].data;
      if (this.parentAdgroup) {
        // get the selection with respect to the adGroup
        const requiredSelection = this.selections.filter(
          (item) => item.data.campaign_id === adGroup?.campaign_id
        )[0];
        if (requiredSelection) {
          data = requiredSelection.data;
        }
      }
      const obj = {
        actionType: this.actionType,
        actionSource: {
          pageUrl: window.location.href
        }
      };
      obj.actionPayload = {
        campaignId: data.campaign_id,
        profileId: data.profile_id || 0,
        campaignType: data.campaign_type,
        keywordText: keywordsObj.keyword,
        adgroupId: adGroup.adgroup_id,
        matchType: matchTypes[keywordsObj.match_type] || 'exact'
      };
      if (this.parentAdgroup) {
        obj.actionPayload.newBid = keywordsObj.newBid;
        obj.actionPayload.isDuplicateKeyword = false;
      }
      obj.viewPayload = { ...obj.actionPayload };
      obj.viewPayload.campaign_name = data.campaign_name;
      obj.viewPayload.adgroupName = adGroup.adgroup_name;
      if (this.apiVersion === 'actionlog') {
        obj.primaryKey = data[this.primaryKey];
        obj.widget = this.widget;
        this.addToActionAndViewPayload(
          obj,
          'campaign_id',
          obj.actionPayload.campaignId
        );
        this.addToActionAndViewPayload(
          obj,
          'profile_id',
          obj.actionPayload.profileId
        );
        this.addToActionAndViewPayload(
          obj,
          'new_keyword_text',
          obj.actionPayload.keywordText
        );
        this.addToActionAndViewPayload(
          obj,
          'ad_group_id',
          obj.actionPayload.adgroupId
        );
        this.addToActionAndViewPayload(
          obj,
          'new_match_type',
          obj.actionPayload.matchType
        );
        this.addToActionAndViewPayload(obj, 'current_bid', null);
        this.addToActionAndViewPayload(obj, 'new_bid', keywordsObj.newBid);
        this.addToActionAndViewPayload(
          obj,
          'is_duplicate_keyword',
          obj.actionPayload.isDuplicateKeyword
        );
        this.addToActionAndViewPayload(obj, 'state', data.status);
        this.addToActionAndViewPayload(
          obj,
          'advertiser_id',
          data.advertiser_id
        );

        obj.previousEntityValue = obj.actionPayload.current_bid;
        obj.newEntityValue = obj.actionPayload.new_bid;

        this.deleteFromActionAndViewPayload(obj, 'campaignId');
        this.deleteFromActionAndViewPayload(obj, 'profileId');
        this.deleteFromActionAndViewPayload(obj, 'keywordText');
        this.deleteFromActionAndViewPayload(obj, 'adgroupId');
        this.deleteFromActionAndViewPayload(obj, 'matchType');
        this.deleteFromActionAndViewPayload(obj, 'newBid');
        this.deleteFromActionAndViewPayload(obj, 'isDuplicateKeyword');
      }
      return obj;
    },
    getInitialPayloadObjectMultiple(keywordsObj) {
      const matchTypes = this.matchTypes;
      var resultantPayload = this.selections.map((item) => {
        const obj = {
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          }
        };
        obj.actionPayload = {
          line_item_id: item.data.campaign_id,
          actionType: item.data.campaign_type,
          keyword: keywordsObj.keyword,
          keywordMatchType: matchTypes[keywordsObj.match_type] || 'exact'
        };
        obj.viewPayload = { ...obj.actionPayload };
        if (this.apiVersion === 'actionlog') {
          obj.primaryKey = item.data[this.primaryKey];
          obj.widget = this.widget;
          this.addToActionAndViewPayload(
            obj,
            'line_item_id',
            item.data.line_item_id
          );
          this.addToActionAndViewPayload(obj, 'actionType', this.actionType);
          this.addToActionAndViewPayload(obj, 'keyword', keywordsObj.keyword);
          this.addToActionAndViewPayload(obj, 'is_deleted', 'false');
          obj.viewPayload.campaign_name = item.data.line_item_name;
          obj.viewPayload.keywordMatchType = `Negative${keywordsObj.match_type}`;
          obj.previousEntityValue = null;
          obj.newEntityValue = null;
        }
        return obj;
      });
      return resultantPayload;
    },
    getBulkPayloadTemplate(adGroup) {
      const payload = [];

      this.resultTableData.forEach((keyword, i) => {
        payload[i] = {
          actionType:
            this.targetingType === 'keyword'
              ? 'addNegativeKeyword'
              : 'addNegativeTarget',
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: adGroup.campaign_id
        };
        const commonPayload = {
          campaignId: adGroup.campaign_id,
          campaignType: (adGroup.campaign_type || '').toUpperCase()
        };
        if (this.targetingType === 'keyword') {
          payload[i].actionPayload = {
            ...commonPayload,
            new_keyword_text: keyword.negative_keyword,
            new_match_type: camelCase(keyword.match_type),
            adgroup_id: adGroup.adgroup_id
          };
          payload[i].viewPayload = {
            ...commonPayload,
            adgroupName: adGroup.adgroup_name,
            keywordText: keyword.negative_keyword,
            matchType: keyword.match_type,
            adgroupId: adGroup.adgroup_id
          };
        } else {
          payload[i].actionPayload = {
            campaignId: adGroup.campaign_id,
            campaignType: (adGroup.campaign_type || '').toUpperCase(),
            new_keyword_text: keyword.competitor_asin,
            adgroup_id: adGroup.adgroup_id,
            new_expression_type: 'manual',
            new_match_type: 'TARGETING_EXPRESSION',
            new_expression: [
              {
                type: 'asinSameAs',
                value: keyword.competitor_asin
              }
            ],
            ...(adGroup?.campaign_type?.toLowerCase() === 'sb'
              ? {}
              : { state: 'enabled' })
          };
          payload[i].viewPayload = {
            campaignId: adGroup.campaign_id,
            adgroupId: adGroup.adgroup_id,
            keywordText: keyword.competitor_asin,
            matchType: 'TARGETING_EXPRESSION'
          };
        }
      });

      return {
        uuid: `${adGroup.campaign_id}${adGroup.adgroup_id}`,
        payload
      };
    },
    createBulkPayload() {
      const payload = [];
      this.selectedAdGroups.forEach((adGroup, i) => {
        payload[i] = this.getBulkPayloadTemplate(adGroup);
      });
      return payload;
    },
    getPayload() {
      var paramsToReturn = [];
      for (let i = 0; i < this.resultTableData.length; i++) {
        if (this.enableAdGroup) {
          for (const adGroup of this.selectedAdGroups) {
            paramsToReturn.push(
              this.getInitialPayloadObject(this.resultTableData[i], adGroup)
            );
          }
        } else {
          let resultantPayload = this.getInitialPayloadObjectMultiple(
            this.resultTableData[i]
          );
          for (let j = 0; j < resultantPayload.length; j++) {
            paramsToReturn.push(resultantPayload[j]);
          }
        }
      }
      return paramsToReturn;
    },
    validateTableData(context, event) {
      context.params.data.newBid = context.bidValue;
      for (const item of this.resultTableData) {
        if (
          context.params.data.keyword === item.keyword &&
          context.params.data.match_type === item.match_type
        ) {
          item.newBid = context.bidValue;
        }
      }
      // This means a bid config to show error has been passed down as prop, hence check for min/max bid conditions
      if (this.bidConfig.addedKeywordsErrorMessage) {
        this.loopThroughResultTableDataAndValidate();
      }
    },
    loopThroughResultTableDataAndValidate() {
      for (const item of this.resultTableData) {
        if (
          !this.isBidValid(
            item.newBid,
            this.bidConfig.minBid,
            this.bidConfig.maxBid
          )
        ) {
          this.showAddedKeywordsError = true;
          return;
        }
      }
      this.showAddedKeywordsError = false;
    },
    callBidActionApi() {
      var that = this;
      that.keywordsLoading = true;
      var payload = this.bulkActionsAllowed
        ? this.createBulkPayload()
        : this.getPayload();
      var othersObj = {
        loader: that.loader,
        successMessage: `Action has been submitted for the selected ${this.actionSubLabel.toLowerCase()}.`,
        errorMessage: 'Something went wrong with the action, please try again.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          that.keywordsLoading = false;
          that.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.bulkActionsAllowed ? 'bulkaction' : this.apiVersion
      );
    },
    onApply() {
      if (this.parentAdgroup && this.enableAdGroup) {
        let bidsPresent = true;
        for (const item of this.resultTableData) {
          if (
            !item?.newBid &&
            this.actionType !== 'samsclub_apiAddNegativeKeywordToCampaigns'
          ) {
            bidsPresent = false;
            this.snackbar('Please update valid bids for the added keyword(s)');
            break;
          }
        }
        if (!bidsPresent) {
          return;
        }
      }
      this.callBidActionApi();
    },
    closePanel() {
      this.$emit('closePanelMethod');
    }
  }
};
</script>

<style lang="css">
.tableBodyLeftNegativeKeywords {
  position: relative;
  z-index: 10;
}
.tableBodyLeftNegativeKeywords .paginationFooter {
  border-top: solid 1px #e9eaeb;
}
.tableBodyLeftNegativeKeywords .message {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #8b8f93;
}
.tableBodyLeftNegativeKeywords .table-left {
  height: 295px !important;
}
.input-text-height .rb-textarea {
  height: 240px !important;
}
.fill-message-negative-keywords {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.addNegativeKeywordPanel .ag-row.ag-row-level-0 div:nth-child(2) .cell--content,
.addNegativeKeywordPanel
  .ag-row.ag-row-first.ag-row-last
  div:nth-child(3)
  .cell--content {
  text-transform: capitalize;
}
</style>
