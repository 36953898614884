<template>
  <div
    class="u-flex-1 u-display-flex u-position-relative"
    componet="benchmarkingWidget"
  >
    <div
      id="benchMarking"
      ref="content"
      class="u-display-flex u-width-100 u-flex-direction-column u-font-size-1 u-height-100 u-overflow-auto"
    >
      <div
        v-if="isBenchMarkingOverallLoading"
        class="u-height-100 u-width-100 benchmarking-widget-loader"
      >
        <loader
          class="fill--parent u-bg-color-grey-white"
          :loading="isBenchMarkingOverallLoading"
          :color="'#007cf6'"
        />
      </div>
      <div
        v-if="isMetadataErrored"
        class="u-display-flex u-font-size-5 u-flex-align-items-center u-flex-justify-content-center benchmarking-chart-loader"
      >
        Something went wrong
      </div>
      <!-- <div @click="getCurrentState()">
        Save state
      </div>
      <div @click="setNewState()">
        Set Old State
      </div> -->
      <div
        v-if="!isBenchMarkingOverallLoading && !isMetadataErrored"
        class="pageTableContainer u-bg-color-grey-white"
      >
        <div class="u-spacing-p-l u-bg-color-grey-white">
          <div
            class="u-display-flex u-spacing-mb-l u-flex-justify-content-space-between"
          >
            <div class="u-display-flex">
              <span
                class="u-font-size-3 benchmarkingHeader u-font-weight-600 u-color-grey-lighter"
                >BENCHMARKING</span
              >
              <rb-select-v2
                v-model="selectedOptionValue"
                class="u-spacing-ml-xs u-max-width-100"
                :options="mainDropDownOptions"
                :group-by-key="'dimensionType'"
                :on-close="onBenchmarkingTypeSelection"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                >
                  <div
                    class="u-flex-1 u-font-size-3 u-font-weight-600 u-text-overflow-ellipsis"
                  >
                    {{ mainDropDownSelectedOption.label }}
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                  >
                    <div :class="{ 'u-color-grey-lighter': option.disable }">
                      {{ option.dimensionLabel || option.label }}
                    </div>
                    <span
                      v-if="option.children"
                      class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                      style="transform: rotate(90deg)"
                    />
                  </div>
                </template>
              </rb-select-v2>
            </div>

            <div class="u-display-flex u-flex-align-items-center" />
            <customWidgetsDownloader
              :is-download-loading="downloadLoading"
              :is-download-error="downloadError"
              :tooltip-text="'Downloads an Excel with the entities and metrics selected. '"
              @download-report-trigger="downloadReportTrigger"
            />
          </div>
          <div
            v-if="mainDropDownOptionsNoData"
            class="u-display-flex u-font-size-5 u-flex-align-items-center u-flex-justify-content-center benchmarking-chart-loader"
          >
            No Data
          </div>
          <div
            v-if="isBenchMarkingOverallErrored"
            class="u-display-flex u-font-size-5 u-flex-align-items-center u-flex-justify-content-center benchmarking-chart-loader"
          >
            Something went wrong
          </div>
          <div
            v-if="!mainDropDownOptionsNoData"
            class="u-spacing-p-m u-border-width-s u-border-radius-s u-border-all u-border-color-grey-xxx-light"
            :class="{ 'dashed-border': !isPlotted }"
          >
            <div
              v-if="isPlotted"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
            >
              <span
                class="u-font-size-3 u-font-weight-600 u-color-grey-mid-base"
                >{{ displayMetric.name }}</span
              >
              <div class="u-display-flex u-flex-align-items-center">
                <rb-select
                  v-if="isPlotted"
                  class="u-spacing-ml-m u-spacing-mr-l rounded-dropdown u-max-width-100"
                  :on-close="getChartDataWithRollUp"
                  :send-details="true"
                  :options="computedRollUps"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                  >
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="calendar"
                    />
                    <span
                      class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
                      >Roll up by:&nbsp;&nbsp;</span
                    >
                    <div
                      class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
                    >
                      {{ selectedRollupBy.name }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="caret-down"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      {{ option.name }}
                    </div>
                  </template>
                </rb-select>
                <div
                  class="u-cursor-pointer u-display-flex u-flex-align-items-center"
                  @click="removeChart"
                >
                  <rb-icon
                    class="rb-icon--medium u-color-grey-lighter"
                    :icon="'cross'"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isPlotted && chartState.load"
              class="benchmarking-chart-loader u-display-flex u-flex-align-items-center u-flex-justify-content-center"
            >
              <loader
                class="u-bg-color-grey-white"
                :loading="chartState.load"
                :color="'#007cf6'"
              />
            </div>
            <div
              v-if="isPlotted && chartState.error"
              class="u-display-flex u-font-size-5 u-flex-align-items-center u-flex-justify-content-center benchmarking-chart-loader"
            >
              Something went wrong
            </div>
            <div
              v-if="
                !chartState.error &&
                !chartState.load &&
                isPlotted &&
                ((benchmarkingChartData || {}).defaultSelectedMetric || [])
                  .length === 0
              "
              class="u-display-flex u-font-size-5 u-flex-align-items-center u-flex-justify-content-center benchmarking-chart-loader"
            >
              No Data
            </div>
            <div
              v-if="!isPlotted"
              class="u-display-flex u-flex-align-items-center"
            >
              <div class="show-chart-holder u-spacing-mr-m">
                <rb-icon
                  class="rb-icon--medium u-color-grey-white"
                  :icon="'show-chart'"
                />
              </div>
              <div class="u-color-grey-lighter u-font-size-6 u-border-radius-s">
                Hover on any metric on the left panel and click this icon to
                <span class="u-color-grey-base u-font-weight-600"
                  >Plot Graph</span
                >
                for the benchmarked entities.
              </div>
            </div>
            <chart-with-legends
              v-if="
                !isLoading &&
                benchmarkingChartData &&
                benchmarkingChartData.defaultSelectedMetric.length &&
                isPlotted &&
                chartLoading &&
                !chartState.load &&
                !chartState.error
              "
              id="branchmarking-chart"
              class="branchmarking-chart-holder"
              :metrics-list="[]"
              :metric-data="benchmarkingChartData.metricData"
              :custom-chart-class="['u-spacing-mt-s']"
              :chart-config="benchmarkingChartData.chartConfig"
              :chart-data="benchmarkingChartData.chartData"
              :metrics-shown="benchmarkingChartData.metricsShown"
              :default-select-metric="
                benchmarkingChartData.defaultSelectedMetric
              "
              :metric-config="benchmarkingChartData.tagConfig"
              :disable-add-new-metric="true"
              :minimum-metric="benchmarkingChartData.metricsShown.length"
              :remove-dropdown="true"
              :selected-metric-limit="benchmarkingChartData.metricsShown.length"
              :default-legends="!chartDefaultMetricType"
              :additional-data="benchmarkingChartData.additionalData"
              :chart-height="'240px'"
              :plot-on-y-axis-only="true"
            >
              <div
                v-if="chartDefaultMetricType"
                slot="customLegendForChart"
                slot-scope="{ val }"
                style="width: 245px"
              >
                <div
                  class="u-font-size-6 u-spacing-pb-s u-color-grey-lighter u-spacing-ph-m u-spacing-mt-s"
                >
                  <rb-sku
                    :show-image="true"
                    :title="val.additionalData.title"
                    :image-url="val.additionalData.image_url"
                    :asin="val.additionalData.asin"
                    :product-page-url="val.additionalData.product_url"
                    :enable-s-d-p="true"
                    @navigateToSDP="navigateToSDP"
                  />
                  <div class="u-spacing-mt-m">
                    <metric
                      v-if="val.metricData"
                      :size="'l'"
                      class="u-display-inline-flex"
                      :config="val.metricConfig || {}"
                      :data="getMetricData(val)"
                    />
                  </div>
                </div>
              </div>
            </chart-with-legends>
          </div>
        </div>
        <div
          v-if="!mainDropDownOptionsNoData"
          class="u-spacing-pb-s"
        >
          <div class="u-display-flex u-flex-wrap-yes u-height-100">
            <rb-insights-table
              :grid-options="gridOptions"
              :table-row="metricTypes"
              :table-column="getTableColumns"
              :row-selection="rowSelection"
              :row-height="47"
              :header-height="tableHeaderHeight"
              :pagination="true"
              :show-pagination-options="true"
              :custom-height="false"
              :has-expand="true"
              :has-default-row-expand="true"
              :levels="levels"
              :expand-cell-width="32"
              :suppress-movable-columns="false"
              class="table-without-border u-spacing-ph-m u-spacing-pb-m u-spacing-pt-s"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
// import { eventBus } from '@/utils/services/eventBus';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import utils from '@/utils/helpers/';
import benchMarkingTableSelect from '@/components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarkingTableSelect.vue';
import benchMarkingMetricCell from '@/components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarkingMetricCell.vue';
import loader from '@/components/basic/loader';
import customWidgetsDownloader from '@/components/widgets/custom_widgets/customWidgetsDownloader';
import { downloadLinkAsFile } from '@/utils/helpers/downloader';
import rbSelectV2 from '@/components/pages/businessInsights/rbSelectV2';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { cloneDeep, isEmpty } from 'lodash';

export default {
  components: {
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    loader,
    chartWithLegends,
    customWidgetsDownloader,
    rbSelectV2
  },
  mixins: [widgetsUtil],
  props: {
    page: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => {}
    },
    isDefaultView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOptionValue: {},
      levels: [
        {
          level: 1,
          getter: 'getbenchmarkingTableFields',
          action: 'benchmarkingTableFields',
          storeKey: this.storeKey
        }
      ],
      tagConfig: {},
      isLoading: true,
      gridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      chartLoading: false,
      selectedRollUp: {
        name: 'Days',
        value: 'DAY',
        diff: 'day'
      },
      rowSelection: {
        mode: undefined,
        onSelectionChanged: this.onSelectionChanged
      },
      testSaveState: {},
      downloadLoading: false,
      downloadError: false,
      widget: 'benchmarking',
      isGroupingRequired: false
    };
  },
  computed: {
    tableHeaderHeight() {
      if (this.isGroupingRequired) {
        return 65;
      } else {
        return (
          this.mainDropDownSelectedOption.additionalData.tableHeaderHeight || 60
        );
      }
    },
    isPlotted() {
      return this.$store.getters.getBechmarkingIsPlotted[this.storeKey];
    },
    chartDefaultMetricType() {
      return (
        this.mainDropDownSelectedOption.additionalData.entityHeaderComp ===
        'asinTableHeader'
      );
    },
    chartState() {
      return this.$store.getters.getBenchmarkingChartState[this.storeKey];
    },
    isMetadataLoading() {
      return this.$store.getters.getBenchmarkingMetadata[this.storeKey].load;
    },
    metadata() {
      return this.$store.getters.getBenchmarkingMetadata[this.storeKey].data;
    },
    isMetadataErrored() {
      return this.$store.getters.getBenchmarkingMetadata[this.storeKey].error;
    },
    isBenchMarkingOverallLoading() {
      return this.$store.getters.getBenchMarkingOverallState[this.storeKey]
        .load;
    },
    isBenchMarkingOverallErrored() {
      return this.$store.getters.getBenchMarkingOverallState[this.storeKey]
        .error;
    },
    metricTypes() {
      return this.$store.getters.getBenchmarkingMetricTypes[this.storeKey];
    },
    displayMetric() {
      return (
        this.$store.getters.getBenchmarkingPlottedMetric[this.storeKey] || {}
      );
    },
    mainDropDownOptions() {
      return (
        this.$store.getters.getBenchmarkingMainDropdownData[this.storeKey]
          .rows || []
      );
    },
    mainDropDownOptionsNoData() {
      return this.$store.getters.getBenchmarkingDropdownList[this.storeKey]
        .nodata;
    },
    mainDropDownSelectedOption() {
      return (
        this.$store.getters.getBenchmarkingMainDropdownSelection[this.storeKey]
          .data || {}
      );
    },
    getTableColumns() {
      const columnMap =
        this.$store.getters.getBenchmarkingSelectedColumn[this.storeKey];
      return this.getColumnDefinition(columnMap);
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.campaign_id) {
            return false;
          }
          return (
            params.data.campaign_id ===
            this.$store.getters.getCampaignPrimaryKey
          );
        }.bind(this)
      };
    },
    computedRollUps() {
      const rollUps = utils.getRollUps(this.$store.getters.getWhereClause.date);
      this.$store.dispatch('bechmarkingUpdateRollupOpions', {
        storeKey: this.storeKey,
        data: rollUps
      });
      return rollUps;
    },
    benchmarkingChartData() {
      this.isLoading = true;
      this.chartLoading = true;
      const data = this.$store.getters.getBenchMarkingTimeseries[this.storeKey];
      const nameToMetricsMapping =
        this.$store.getters.getBenchmarkingNameToMetricMapping[this.storeKey];
      const plottedMetric =
        this.$store.getters.getBenchmarkingPlottedMetric[this.storeKey];
      let plottedData = null;
      if (!plottedMetric) {
        return null;
      }
      if (data[plottedMetric.keyLookUp]) {
        plottedData = data[plottedMetric.keyLookUp];
      } else if (data[nameToMetricsMapping[plottedMetric?.key]?.keyName]) {
        plottedData = data[nameToMetricsMapping[plottedMetric.key].keyName];
      }
      setTimeout(() => {
        this.isLoading = false;
      });
      if (plottedData) {
        return this.buildChartConfig(plottedData);
      } else {
        return null;
      }
    },
    selectedRollupBy() {
      return this.$store.getters.getBenchmarkingRollUpBy[this.storeKey];
    },
    benchmarkingSaveData() {
      return this.$store.getters.getbenchMarkingSaveViewData;
    }
  },
  watch: {
    benchmarkingSaveData(newVal) {
      if (
        isEmpty(newVal?.[this.storeKey]?.benchMarkingTableSelectedMetricList) ||
        !this.isDefaultView
      ) {
        return;
      }
      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: 'businessInsights',
        benchmarking: {
          ...newVal
        }
      });
    }
  },
  beforeCreate() {},
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  created() {
    // test
    // this.$store.dispatch('initBenchmarkingStore', this.storeKey);
    this.storeKey = this.page;
    this.levels[0].storeKey = this.storeKey;
    // Changes for views - Reading the widgets config
    this.$store
      .dispatch('fetchBenchmarkingMetadata', {
        storeKey: this.storeKey,
        config: this.config
      })
      .finally(() => {
        console.log('success', this.config?.metadata?.[this.storeKey]);
        // this.$store.dispatch('benchmarkingSetNewState', { data: this.config?.metadata?.[this.storeKey], storeKey: this.storeKey });
      });
    // this.$store.dispatch('benchmarkingTableMetrics');
    this.parentRef = this;
    if (this.$hj) {
      console.log('$hj');
      this.$hj('stateChange', '/your/url/here');
    }
  },
  methods: {
    getMetricData(value) {
      const metric = cloneDeep(value);
      const name = this.displayMetric?.key;
      if (
        this.metadata?.metadata?.excludePvpDisplay?.includes(name) &&
        metric?.metricData?.tag2
      ) {
        metric.metricData.tag2 = 0;
      }
      return metric?.metricData;
    },
    async downloadReportTrigger() {
      this.downloadLoading = true;
      this.downloadError = false;
      this.$logger.logEvent({
        eventType: 'download_button_click',
        type: 'Click',
        page: `${this.page}`,
        widget: this.widget
      });
      try {
        if (
          this.mainDropDownSelectedOption.dimensionColumn === 'businessModel' &&
          this.$store.getters.getRetailer === 'amazon_hybrid'
        ) {
          this.downloadLoading = false;
          return this.$snackbar.open({
            message:
              "Oops! Downloads aren't currently supported for Benchmarking by Business Model.",
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
        const data = await this.$store.dispatch(
          'downloadBenchmarking',
          this.storeKey
        );
        let fileName = data.url.split('/');
        fileName = fileName[fileName.length - 1];
        downloadLinkAsFile(data.url, fileName);
        this.downloadLoading = false;
      } catch (err) {
        this.downloadError = true;
        this.downloadLoading = false;
        this.$snackbar.open({
          message: 'Oops! The download was not successful. Please try again',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    },
    getCurrentState() {
      this.testSaveState = this.$store.getters.getbenchMarkingSaveViewData;
      console.log(this.$store.getters.getbenchMarkingSaveViewData);
    },
    setNewState() {
      this.$store.dispatch('benchmarkingSetNewState', {
        data: this.testSaveState[this.storeKey],
        storeKey: this.storeKey
      });
    },
    removeChart() {
      this.$store.dispatch('bechmarkingRemoveChart', {
        data: false,
        storeKey: this.storeKey
      });
    },
    getChartDataWithRollUp(context, selection) {
      if (
        selection.length > 0 &&
        selection[0].value &&
        selection[0].value !==
          (this.selectedRollUp && this.selectedRollUp.value)
      ) {
        this.$logger.logEvent({
          eventType: 'roll_up_change',
          type: 'Click',
          page: `${this.page}`,
          widget: this.widget,
          from: this.selectedRollUp.value,
          to: selection[0].value
        });
        this.selectedRollUp = selection[0];
        this.$store.dispatch('benchmarkingSetRollUpBy', {
          data: this.selectedRollUp,
          storeKey: this.storeKey
        });
      }
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    // onBenchmarkingTypeSelection (context, selectedOption) {
    //   if (selectedOption?.length > 0) {
    //     this.$store.dispatch('benchmarkingMainDropdownChange', { data: selectedOption[0], storeKey: this.storeKey });
    //   }
    // },
    onBenchmarkingTypeSelection(context, selectedOption) {
      if (Array.isArray(selectedOption)) {
        return;
      }
      if (selectedOption && !selectedOption?.disable) {
        this.$logger.logEvent({
          eventType: 'benchmark_change',
          type: 'Click',
          page: `${this.page}`,
          widget: this.widget,
          from: this.mainDropDownSelectedOption.label,
          to: selectedOption.label
        });
        this.$store.dispatch('benchmarkingMainDropdownChange', {
          data: selectedOption,
          storeKey: this.storeKey
        });
      }
    },
    buildChartConfig(data) {
      const chartData = {
        data: []
      };
      const defaultSelectedMetric = [];
      const metricsShown = [];
      const metricData = {};
      const tagConfig = {};
      const timeseries = [];
      const units = {};
      const keyMapper = {};
      const additionalData = {};
      for (const key in data.chartLegends) {
        defaultSelectedMetric.push({
          key: key
        });
        metricsShown.push({
          key: key
        });
        additionalData[key] = data.chartLegends[key].additionalData;
        keyMapper[key] = true;
        units[key] = data.chartLegends[key].config.tag1Unit;
        tagConfig[key] = data.chartLegends[key].config;
        metricData[key] = data.chartLegends[key].displayData;
        const indexToEntityMap = {};
        for (let i = 0; i < data.timeseries.length; i++) {
          if (i === 0) {
            let index = 0;
            for (const key in data.timeseries[i]) {
              keyMapper[key] = false;
              indexToEntityMap[key] = index;
              timeseries[index] = [key];
              index++;
            }
          }
          for (const key in data.timeseries[i]) {
            timeseries[indexToEntityMap[key]].push(data.timeseries[i][key]);
          }
        }
      }
      for (const key in keyMapper) {
        if (keyMapper[key]) {
          const index = defaultSelectedMetric.findIndex((item) => {
            return item.key === key;
          });
          if (index > -1) {
            defaultSelectedMetric.splice(index, 1);
          }
          timeseries.push([key]);
        }
      }
      chartData.data = timeseries;
      const chartConfig = {
        chartOptions: {
          legend: false,
          type: 'line',
          xFormat: '%m/%d/%Y',
          timeseries: 'report_date',
          tooltip_label_text: this.displayMetric.name,
          tooltip_format: units,
          // axes: axes,
          show_axis_colors: false,
          axis_format: {
            y: {
              tick: {
                count: 5
              }
            }
          },
          xDataKey: 'x',
          grid: 'xy'
        }
      };
      const gridOptions = {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      };
      const _metricsShown = [];
      const _chartData = [];
      const metricOrderOnTable = this.getBechmarkingColumnSortedOrder();
      for (let i = 0; i < metricOrderOnTable.length; i++) {
        _metricsShown.push({
          key: metricOrderOnTable[i]
        });
        const index = chartData.data.findIndex((item) => {
          return item[0] === metricOrderOnTable[i];
        });
        if (index > -1) {
          _chartData.push(chartData.data[index]);
        }
      }
      const index = chartData.data.findIndex((item) => {
        return item[0] === 'report_date';
      });
      if (index > -1) {
        _chartData.push(chartData.data[index]);
      }
      chartData.data = _chartData;
      return {
        chartData,
        defaultSelectedMetric,
        metricsShown: _metricsShown,
        metricData,
        tagConfig,
        chartConfig,
        gridOptions,
        additionalData
      };
    },
    getBechmarkingColumnSortedOrder() {
      const domList = document.querySelectorAll(
        '.custom-table-header.asin-available'
      );
      const list = [];
      for (let i = 0; i < domList.length; i++) {
        list.push(domList[i].getAttribute('custom-table-header-column-id'));
      }
      return list;
    },
    changeDate() {
      this.$store.dispatch('benchMarkingDateChange', {});
    },
    getColumnDefinition(columns) {
      var _columnDefs = [];
      const isGroupingRequired =
        this.$store.getters.getBenchmarkingIsGroupingRequired[this.storeKey];
      let groupByKey = null;
      if (
        isGroupingRequired &&
        this.mainDropDownSelectedOption.additionalData.dropdownGroupBy
      ) {
        groupByKey =
          'label_' +
          this.mainDropDownSelectedOption.additionalData.dropdownGroupBy;
        this.isGroupingRequired = true;
      } else {
        this.isGroupingRequired = false;
      }
      const columnWidth =
        (window.screen.width - (16 * 2 + 16 * 2 + 32 + 200)) / 5 + 30;
      const firstColumn = {
        field: 'entity',
        headerComponentParams: {
          enableSorting: false,
          displayIcon: 'add-circle-fill',
          dropdownGetter: 'getBenchmarkingDropdownList',
          addNewColumnAction: 'bechmarkingAddNewSelectedColumn',
          disableGetter: 'isBenchmarkingDropdownEnabled',
          searchAction: 'benchmarkingEntitySearch',
          storeKey: this.storeKey,
          displayText: `Add another ${this.mainDropDownSelectedOption.dimensionLabel} to Benchmark`,
          groupByKey: groupByKey,
          isItSKUType: this.chartDefaultMetricType,
          dataWarehouseGetter: 'getbenchmarkingDataWarehouse',
          iconSize: this.chartDefaultMetricType ? 'x-large' : 'x-medium'
        },
        pinned: 'left',
        lockPosition: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        headerComponentFramework: 'addColumnHeader',
        keyOrder: -1,
        cellRendererFramework: benchMarkingTableSelect,
        cellRendererParams: {
          storeKey: this.storeKey,
          groupByKey: groupByKey
        }
      };
      _columnDefs.push(firstColumn);
      // (1408 - 16 - 200) / 5
      const replacer = {};
      for (const i in columns) {
        const obj = {
          keyOrder: columns[i].data.order,
          field: columns[i].data.entityId,
          main_order: columns[i].data.main_order,
          replace: columns[i].data.replace,
          headerComponentFramework:
            this.mainDropDownSelectedOption.additionalData.entityHeaderComp ||
            'entityTableHeader',
          headerComponentParams: {
            selectedAsin: columns[i].data.entityId,
            dropdownGetter: 'getBenchmarkingDropdownList',
            addNewColumnAction: 'bechmarkingAddNewSelectedColumn',
            deleteColumnAction: 'benchmarkingDeleteSelectedColumn',
            keyOrder: columns[i].data.order,
            storeKey: this.storeKey,
            metadata: this.metadata,
            groupByKey: groupByKey,
            searchAction: 'benchmarkingEntitySearch',
            dataWarehouseGetter: 'getbenchmarkingDataWarehouse'
          },
          cellRendererFramework: benchMarkingMetricCell,
          minWidth: columnWidth,
          maxWidth: columnWidth,
          width: columnWidth,

          // maxWidth: 256,
          cellRendererParams: {
            tableCardCellConfig: {
              enabled: true
            },
            storeKey: this.storeKey,
            entityGroupbyObject:
              this.mainDropDownSelectedOption.additionalData.dropdownGroupBy,
            clientFlag: columns[i].data.client_flag
          }
        };
        if (columns[i].data.replace) {
          replacer[i] = obj;
        } else {
          _columnDefs.push(obj);
        }
      }

      /**
       * Sort based on key order
       */
      const columnDefs = _columnDefs.sort((a, b) => {
        return a.keyOrder - b.keyOrder;
      });
      for (const key in replacer) {
        columnDefs.splice(replacer[key].main_order, 0, replacer[key]);
      }
      setTimeout(() => {
        this.$store.dispatch('benchmarkingChatOrdering', {
          storeKey: this.storeKey
        });
      });
      return columnDefs;
    }
  }
};
</script>

<style lang="css" scoped>
.benchmarking-widget-loader {
  height: 400px;
}
.benchmarking-chart-loader {
  height: 200px;
  width: 100%;
}
.show-chart-holder {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007cf6;
}
.dashed-border {
  border-style: dashed;
}
</style>
