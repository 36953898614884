import getSpManualConfig from '@/pages/entity_details/configs/walmart/sp_manual.js';
import getSpAutoConfig from '@/pages/entity_details/configs/walmart/sp_auto.js';

const sp_manual = getSpManualConfig('samsclub_api');
const sp_auto = getSpAutoConfig('samsclub_api');

export default {
  sp_manual,
  sp_auto
};
