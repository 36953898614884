<template>
  <div
    class="u-display-flex u-flex-direction-column u-spacing-ph-xl u-spacing-pv-s automation-form-container u-spacing-m-s"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-cursor-pointer u-spacing-ph-s u-spacing-pv-xs"
      @click="isExpanded = !isExpanded"
    >
      <span>{{ automationTitle }}</span>
      <div class="u-display-flex u-align-items-center">
        <rb-switch
          v-model="localFormData.isEnabled"
          class="u-spacing-mr-s"
          @input="automationToggled"
        />
        <rb-icon
          class="u-color-grey-mid-light"
          :class="{ 'rotate-icon': isExpanded }"
          icon="arrow-right"
        ></rb-icon>
      </div>
    </div>
    <div
      v-if="isExpanded"
      class="u-display-flex u-flex-direction-column u-spacing-ph-s u-spacing-pv-s automation-form"
    >
      <div class="automation-form__inputs">
        <input-component
          v-for="(value, key) in visibleFormFields"
          :key="key"
          v-model="localFormData[key]"
          :has-error="errorFields[key]"
          :input-label="inputFields[key].label"
          :is-required="inputFields[key].required"
          :input-description="inputFields[key].description"
          :input-type="inputFields[key].componentType"
          :primary-filter-data="primaryFilterData"
          :filter-data="filterData"
        ></input-component>
      </div>
      <div class="u-display-flex">
        <rb-button
          style="min-width: 93px"
          text="Save"
          type="filled"
          class="u-spacing-mr-s"
          :click-fn="() => saveDetails()"
        />
      </div>
    </div>
    <div
      v-else
      class="u-spacing-ph-s u-font-weight-600 u-font-size-7 u-spacing-pb-s"
    >
      <span v-if="localFormData.isEnabled && localFormData.isManual">
        Manual
      </span>
      <span v-else-if="localFormData.isEnabled">Enabled</span>
      <span v-else>Disabled</span>
    </div>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import dynamicCcUpload from './dynamic-cc-upload.vue';
import inputComponent from './components/input-component.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { dynamicCcUpload, inputComponent },
  props: {
    automationTitle: {
      type: String,
      default: 'Variation Authority'
    },
    automationKey: {
      type: String,
      required: true
    },
    childClient: {
      type: String,
      default: ''
    },
    filterData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    primaryFilterData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    inputFields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isExpanded: false,
      localFormData: {},
      errorFields: {},
      stopToggle: false
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.getSessionUser?.email;
    },
    visibleFormFields() {
      let { isEnabled, isDynamicCcListEnabled, pocEmail, ...formFields } =
        this.localFormData;
      return formFields;
    }
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.localFormData = cloneDeep(newVal);
      }
    }
  },
  methods: {
    saveDetails() {
      Object.entries(this.localFormData).forEach(([key, value]) => {
        if (this.inputFields[key].componentType === 'LIST') {
          this.localFormData[key] = value?.map((el) => el?.trim()) ?? [];
        }
      });
      this.validateForm({ ...this.localFormData, pocEmail: this.userEmail })
        .then((response) => {
          this.errorFields = {};
          this.submitDetails(response);
        })
        .catch((errorFields) => {
          this.errorFields = errorFields;
          this.$snackbar.open({
            message: `Please input all the required fields`,
            duration: 2000,
            buttonColor: '#d7263d',
            actionText: 'Failed'
          });
        });
    },
    validateForm(data) {
      return new Promise((resolve, reject) => {
        const emptyRequiredFields = {};
        Object.entries(data).forEach(([key, value]) => {
          if (
            this.inputFields[key].required &&
            (value === null ||
              value === '' ||
              (this.inputFields[key].componentType === 'LIST' &&
                value.length === 0) ||
              (this.inputFields[key].componentType === 'QUOTED_LIST' &&
                value.length === 0))
          ) {
            emptyRequiredFields[key] = 'Please input a value';
          }
        });

        if (Object.keys(emptyRequiredFields).length) {
          return reject(emptyRequiredFields);
        }
        return resolve(data);
      });
    },
    submitDetails(data) {
      HttpService.post('ACTIONS_SCHEDULER_SELF_SERVE', data, {
        append: `/${this.automationKey}/${this.childClient}`
      })
        .then(() => {
          this.$snackbar.open({
            message: `Details Saved Successfully`,
            duration: 2000,
            buttonColor: '#3ea95e',
            actionText: ''
          });
        })
        .catch((error) => {
          if (error?.response?.data) {
            error.response.data.forEach((el) => {
              this.$snackbar.open({
                message: `${el.nameOfField}: ${el.issueSummary}`,
                duration: 2000,
                buttonColor: '#d7263d',
                actionText: 'FAILED'
              });
            });
          }
        });
    },
    patchedToggle(value) {
      HttpService.patch(
        'ACTIONS_SCHEDULER_SELF_SERVE',
        {
          isEnabled: value,
          pocEmail: this.userEmail
        },
        {
          append: `/${this.automationKey}/${this.childClient}`
        }
      )
        .then(() => {
          this.$snackbar.open({
            message: `${this.automationTitle} ${
              value ? 'enabled' : 'disabled'
            } successfully`,
            duration: 2000,
            buttonColor: '#3ea95e',
            actionText: ''
          });
        })
        .catch((err) => {
          this.localFormData.isEnabled = !value;
          this.stopToggle = true;
          if (err?.response?.data) {
            this.$snackbar.open({
              message: `Can not ${value ? 'enable' : 'disable'} ${
                this.automationTitle
              }: Fill the required details`,
              duration: 8000,
              buttonColor: '',
              actionText: ''
            });
            this.validateForm({
              ...this.localFormData,
              pocEmail: this.userEmail
            }).catch((errorFields) => {
              this.errorFields = errorFields;
            });
          }
        });
    },
    automationToggled(value) {
      if (this.stopToggle) {
        this.stopToggle = false;
        return;
      }
      if (value) this.isExpanded = true;
      this.patchedToggle(value);
    }
  }
};
</script>

<style lang="css" scoped>
.automation-form-container {
  box-shadow: 0px 8px 8px -8px #d2d4d7;
  border: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  border-radius: 4px;
}
.automation-form-container .rotate-icon {
  transform: rotate(90deg);
}
.automation-form-container .automation-form__inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
}
</style>
