<template>
  <div>
    <section class="u-display-flex u-spacing-mt-s">
      <div
        v-for="(item, index) in adFormatTypes"
        :key="index"
        class="u-flex-1 u-border-all u-border-radius-s container u-cursor-pointer"
        :class="{
          'u-border-color-blue-base': selectedFormat.title === item.title,
          'u-border-color-grey-xxx-light': selectedFormat.title != item.title
        }"
        data-cy="selectAdFormatClick"
        @click="selectFormat(item)"
      >
        <div class="u-display-flex u-spacing-m-l">
          <span class="u-wdith-20 u-spacing-mr-l u-flex-align-self-center">
            <img
              class="campaign-types-img"
              :src="item.image"
              alt="no image"
              data-cy="adFormatImage"
            />
          </span>
          <div class="u-display-flex u-flex-direction-column">
            <span
              class="u-font-size-5 u-color-grey-base u-font-weight-600"
              data-cy="adFormatTypeTitle"
              >{{ item.title }}</span
            >
            <span
              class="u-font-size-7 u-color-grey-lighter u-spacing-mt-s"
              data-cy="adFormatTypeDesc"
              >{{ item.desc }}</span
            >
          </div>
        </div>
      </div>
    </section>
    <div
      v-if="selectedFormat.title === 'Product Collection'"
      class="u-spacing-pt-xl"
    >
      <span
        class="u-font-size-5 u-font-weight-600 u-color-grey-base"
        data-cy="landingPageTxt"
        >Landing page</span
      >
      <div>
        <rbRadioGroup
          :radio-options="radioOptionsProductCollecton"
          :value="selectedValueProductCollection"
          :is-column="true"
          @onRadioChanged="handleRadioSelection"
        >
          <div
            slot="below_radio_0"
            class=""
          >
            <div
              v-if="selectedValueProductCollection === 'store_on_amazon'"
              class="u-position-relative u-spacing-pt-m u-max-width-560px u-spacing-pl-l u-display-flex"
              data-cy="storOnAmazon"
            >
              <loader
                v-if="storeInfo && storeInfo.load"
                class="fill--parent"
                :color="'#3fadf7'"
              />
              <div class="u-max-width-260px u-spacing-mr-l">
                <div
                  class="u-font-weight-600 u-spacing-pb-s u-font-size-5"
                  data-cy="chooseAStoreTxt"
                >
                  Choose a store
                </div>
                <campaignSelect
                  entitykey="storeName"
                  :default-option="selectedStoreOnAmazonStore"
                  :options="storeInfo && storeInfo.rows"
                  data-cy="selectStoreName"
                  @selectedItem="onSelectStoreOnAmazonStore"
                />
              </div>
              <div class="u-max-width-260px u-spacing-ml-s">
                <div
                  class="u-spacing-pb-s u-font-weight-600 u-font-size-5"
                  data-cy="chooseApageTxt"
                >
                  Choose a page
                </div>
                <campaignSelect
                  entitykey="storePageName"
                  :default-option="selectedStoreOnAmazonPage"
                  :options="storeOnAmazonPageOptions"
                  data-cy="chooseStorepage"
                  @selectedItem="onSelectStoreOnAmzonPage"
                />
              </div>
            </div>
          </div>

          <div slot="below_radio_2">
            <div
              v-if="selectedValueProductCollection === 'custom_url'"
              class="u-spacing-pl-l u-spacing-pt-m u-max-width-400px"
            >
              <div
                class="u-spacing-pb-s u-font-weight-600 u-font-size-6"
                data-cy="customUrltxt"
              >
                Custom URL
              </div>
              <rb-input
                v-model="customUrl"
                placeholder="Type right here"
                class="u-width-100 u-spacing-mt-s"
                data-cy="selectCustomUrl"
                @input="updateSelectedStore({ customURL: customUrl })"
              />
            </div>
          </div>
        </rbRadioGroup>
      </div>
    </div>
    <div
      v-if="selectedFormat.title === 'Store Spotlight'"
      class="u-spacing-pt-xl"
    >
      <div>
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="landingPageTxt"
          >Landing page</span
        >
      </div>
      <div class="u-spacing-mt-m">
        <span
          class="u-font-size-5 u-font-weight-400 u-color-grey-base"
          data-cy="amazonStoreDesc"
          >Store on Amazon (must have 4 or more pages, each with 1 or more
          unique products)</span
        >
      </div>
      <div class="u-spacing-mt-s fit-content u-position-relative">
        <loader
          v-if="storeInfo && storeInfo.load"
          class="fill--parent"
          :color="'#3fadf7'"
        />
        <campaignSelect
          entitykey="storeName"
          :default-option="selectedStoreOnAmazonStore"
          :options="storeInfo && storeInfo.rows"
          data-cy="storeName"
          @selectedItem="onSelectStoreOnAmazonStore"
        />
      </div>
    </div>

    <div
      v-if="selectedFormat.title === 'Video'"
      class="u-spacing-pt-xl"
    >
      <div>
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="landingpageTxt"
          >Landing page</span
        >
      </div>
      <div class="u-spacing-mt-m">
        <span
          class="u-font-size-5 u-font-weight-400 u-color-grey-base"
          data-cy="productDetailpageTxt"
          >Product detail page</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import campaignSelect from '@/components/ams/campaign_creation/steps/edit/campaignAdFormat/campaignSelect.vue';
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import loader from '@/components/basic/loader';
export default {
  components: {
    rbRadioGroup,
    campaignSelect,
    loader
  },
  props: {
    step: {
      type: Object,
      default: () => {}
    },
    storeInfo: {
      type: Object,
      default: () => {}
    },
    suggestedCampaignIndex: {
      type: Number,
      default: 0
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    allStepsData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    customUrl: '',
    radioOptionsProductCollecton: [
      {
        type: 'store_on_amazon',
        label: 'Store on amazon',
        desc: 'Choose keywords to help your products appear in shopper searches.'
      },
      {
        type: 'new_landing_page',
        label: 'New Landing Page ',
        desc: 'Choose keywords to help your products appear in shopper searches.'
      },
      {
        type: 'custom_url',
        label: 'Custom URL',
        desc: 'Choose keywords to help your products appear in shopper searches.'
      }
    ],
    selectedValueProductCollection: 'new_landing_page',
    selectedFormat: {},
    adFormatTypes: [
      {
        url: '',
        title: 'Product Collection',
        key: 'productCollection',
        desc: 'Promote multiple products from a landing page of yours',
        image: '/images/Productcollection.png'
      },
      {
        url: '',
        title: 'Store Spotlight',
        key: 'storeSpotlight',
        desc: 'Drive traffic to a store, including subpages.',
        image: '/images/Storespotlight.png'
      }
      // {
      //   url: '',
      //   title: 'Video',
      //   key: 'video',
      //   desc: 'Feature a single product with an autoplaying video.',
      //   image: '/images/Video.png'
      // }
    ],
    selectedStoreOnAmazonStore: {},
    storeOnAmazonStoreOptions: [],
    selectedStoreOnAmazonPage: {},
    storeOnAmazonPageOptions: []
  }),
  watch: {
    storeInfo: {
      handler(newValue) {
        if (newValue?.rows?.length) {
          this.selectedStoreOnAmazonStore =
            this.allStepsData.suggestedCampaigns[0]?.storeOnAmazonStore ||
            newValue?.rows[0];
          this.storeOnAmazonPageOptions =
            this.selectedStoreOnAmazonStore?.storePageInfo;
          this.selectedStoreOnAmazonPage =
            this.allStepsData.suggestedCampaigns[0]?.storeOnAmazonPage ||
            this.selectedStoreOnAmazonStore?.storePageInfo?.[0];
          this.updateSelectedStore({
            storeOnAmazonPage: this.selectedStoreOnAmazonPage,
            storeOnAmazonStore: this.selectedStoreOnAmazonStore
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    const { adFormat, customURL, adFormatSubType } =
      this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
    const adFormatVal = adFormat || this.adFormatTypes?.[0].key;
    this.selectedFormat = this.adFormatTypes.filter(
      (item) => item.key === adFormatVal
    )?.[0];
    this.updateSelectedStore({ adFormat: adFormatVal });
    this.handleRadioSelection(
      adFormatSubType || this.selectedValueProductCollection,
      true
    );
    this.customUrl = customURL || this.customUrl;
  },
  methods: {
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
      this.updateStepDescription();
    },
    updateStepDescription() {
      const adFormatMap = {
        productCollection: 'Product Collection',
        storeSpotlight: 'Store Spotlight',
        video: 'Video'
      };
      const { adFormat } =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      this.step.description = `Ad Format: ${adFormatMap[adFormat]}`;
    },
    handleRadioSelection(value, created) {
      this.selectedValueProductCollection = value;
      if (!created) {
        this.updateSelectedStore({
          creativeSkus: []
        });
      }
      this.updateSelectedStore({
        adFormatSubType: value
      });
    },
    selectFormat(format) {
      this.selectedFormat = format;
      let adFormat = {
        adFormat: format?.key,
        creativeSkus: [],
        selectedAsset: null
      };
      this.updateSelectedStore(adFormat);
    },
    onSelectStoreOnAmazonStore(action) {
      this.selectedStoreOnAmazonStore = action;
      this.storeOnAmazonPageOptions =
        this.selectedStoreOnAmazonStore?.storePageInfo;
      this.selectedStoreOnAmazonPage =
        this.selectedStoreOnAmazonStore?.storePageInfo?.[0];
      this.updateSelectedStore({
        storeOnAmazonPage: this.selectedStoreOnAmazonPage,
        storeOnAmazonStore: this.selectedStoreOnAmazonStore,
        creativeSkus: [],
        selectedAsset: null,
        selectedPages: null
      });
    },
    onSelectStoreOnAmzonPage(action) {
      this.selectedStoreOnAmazonPage = action;
      this.updateSelectedStore({
        storeOnAmazonPage: this.selectedStoreOnAmazonPage,
        storeOnAmazonStore: this.selectedStoreOnAmazonStore,
        creativeSkus: [],
        selectedAsset: null,
        selectedPages: null
      });
    }
  }
};
</script>
<style lang="css" scoped>
.container {
  margin-right: 2.4rem;
}
.container:last-child {
  margin-right: 0;
}
.campaign-types-img {
  height: 55px;
  width: 85px;
}
.fit-content {
  width: fit-content;
}
</style>
