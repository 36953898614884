<template>
  <div v-show="showComponent">
    <loader
      v-if="loading"
      class="entity_loader u-color-blue-base fill--screen"
      :color="'#3684bb'"
      :loading="loading"
    />
    <upload-header
      :title="uploadSectionTitle"
      :description="uploadSectionDesc"
      class="header_container"
    />
    <span class="u-font-size-5 u-spacing-mb-s">
      <span class="u-color-blue-base u-font-weight-600"
        >{{ skusCount }} {{ entityConfig.entityName }}s
      </span>
      and
      <span class="u-color-blue-base u-font-weight-600"
        >{{ catalogCount }} {{ entityConfig.label }} Columns </span
      >{{ entityConfig.infoText }}</span
    >
    <div class="select-taxonomy-container">
      <rb-insights-table
        :config="tableConfig"
        :table-column="columnDefs"
        :row-class-rules="gridOptions.rowClassRules"
        :table-row="allRows"
        :row-height="45"
        :grid-options="gridOptions"
        @selectionChanged="selectionChanged"
      />
    </div>
    <div
      class="u-font-size-7 u-font-weight-600 u-spacing-mt-m u-display-flex u-flex-direction-column"
    >
      <span v-if="!bAllCatalogSelected">
        <rb-icon
          :icon="infoIcons[0].icon"
          :class="infoIcons[0].class"
        />
        <span class="u-spacing-ml-xs">{{ infoIcons[0].text }}</span>
      </span>
      <span v-else>
        <rb-icon
          :icon="infoIcons[1].icon"
          :class="infoIcons[1].class"
        />
        <span class="u-spacing-ml-xs">{{ infoIcons[1].text }}</span>
      </span>
    </div>
    <span class="u-font-size-2 u-font-weight-600 u-spacing-mt-xxl">{{
      uploadType === 'overwrite'
        ? `Overwrite ${entityConfig.altLabel}`
        : `Create ${entityConfig.altLabel}`
    }}</span>
    <span
      class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s u-spacing-mb-m"
      >{{
        uploadType === 'overwrite'
          ? `Click on Overwrite to start the overwrite process on your existing ${entityConfig.altLabel}.`
          : `Click on Create to start the creation process of your ${entityConfig.altLabel}.'`
      }}</span
    >
    <upload-footer
      :btn-right-text="uploadType === 'overwrite' ? 'Overwrite' : 'Create'"
      :btn-left-text="backButtonText"
      class="footer_container"
      @onClickBtnRight="goToNextStep"
      @onClickBtnLeft="goBack"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import uploadFooter from '@/components/widgets/upload/footer.vue';
import uploadHeader from '@/components/widgets/upload/header.vue';

export default {
  components: {
    collapsiblePage,
    uploadFooter,
    uploadHeader
  },
  props: {
    showComponent: {
      type: Boolean,
      default: false
    },
    allRows: {
      type: Array,
      default() {
        return [];
      }
    },
    columnDefs: {
      type: Array,
      default() {
        return [];
      }
    },
    subEntityCount: {
      type: Number,
      default: 0
    },
    skusCount: {
      type: Number,
      default: 0
    },
    catalogCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: String,
      default: 'overwrite'
    },
    entityConfig: {
      type: Object,
      default: () => ({
        entityName: 'SKU',
        altLabel: 'Standard Internal Catalog',
        infoText: 'will be your New Standard Catalog',
        label: 'Catalog'
      })
    }
  },
  data() {
    return {
      bAllCatalogSelected: false,
      selectedRows: [],
      backButtonText: 'Back',
      uploadSectionTitle: 'Review the uploaded file',
      uploadSectionDesc: `You can review the uploaded file and uncheck any unwanted columns from here.`,
      infoIcons: [
        {
          icon: 'warning',
          class: 'rb-icon--small u-color-red-base',
          text: `Unchecked ${this.entityConfig.label} Columns will not be present in your account.`,
          show: () => {
            return this.bColumnUnchecked;
          }
        },
        {
          icon: 'info-circle-fill',
          class: 'rb-icon--small u-color-grey-base',
          text: `Uncheck from ${this.entityConfig.altLabel} Columns to exclude from your account. `
        }
      ],
      gridOptions: {
        context: {
          componentParent: this
        },
        rowClassRules: {}
      },
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      }
    };
  },
  watch: {
    allRows(newVal) {
      this.selectedRows = cloneDeep(newVal);
      this.$nextTick(() => {
        this.gridOptions.api.selectAll();
        this.gridOptions.api.sizeColumnsToFit();
      });
    },
    showComponent() {
      this.$nextTick(() => {
        this.gridOptions.api.selectAll();
        this.gridOptions.api.sizeColumnsToFit();
      });
    }
  },
  methods: {
    checkIfUncheckedColumns() {
      if (
        this.gridOptions.api.getSelectedNodes().length === this.allRows.length
      ) {
        this.bAllCatalogSelected = true;
      } else {
        this.bAllCatalogSelected = false;
      }
    },
    selectionChanged(params) {
      this.selectedRows = params.api.getSelectedRows();
      this.checkIfUncheckedColumns();
    },
    goToNextStep(event) {
      this.$emit('onNext', event);
      this.$emit('onSelectionComplete', this.selectedRows);
    },
    goBack() {
      this.$emit('onBack', event);
    },
    navigateBack() {
      this.goBack();
    }
  }
};
</script>

<style lang="css">
.select-taxonomy-container {
  max-width: 60%;
}
.select-taxonomy-container .ag-row.ag-row-selected {
  background-color: #fff;
}
.select-taxonomy-container .ag-row {
  background-color: rgba(215, 38, 61, 0.1);
  box-shadow: none;
}
.select-taxonomy-container div[col-id^='dimensionLabel'] {
  color: #e9eaeb;
  padding-left: 2.4rem;
  border-left: 1px solid !important;
}
.select-taxonomy-container .skuCount-text-color-red .cell--content {
  color: #d7263d;
}
</style>

<style lang="css" scoped>
.footer_container {
  margin-bottom: 3.5rem;
}
.text-italics {
  font-style: italic;
}
.errorBorder {
  border: solid 1px #d7263d;
}

.taxonomy_error_section {
  max-height: 200px;
  width: 100%;
  overflow: scroll;
  margin-top: 1.6rem;
}
.select-taxonomy-container {
  color: #e9eaeb;
  max-height: 100vh;
  overflow: auto;
  border-bottom: 1px solid !important;
}
</style>
