<template>
  <section class="box-shadows u-bg-color-grey-white u-border-radius-xs">
    <section class="u-spacing-p-m">
      <div class="u-font-size-3 u-font-weight-600 u-spacing-mb-m">
        {{ (popupData || {}).text }}
      </div>
      <BubbleChartPopupRow
        :axis-heading="'Horizontal Axis'"
        :axis-label="popupData.xAxisLabel"
        :coordinate="
          getCoordinate(
            ((popupData || {}).originalValues || {}).x,
            popupData.xAxisUnit,
            popupData.coordinatePrecision
          )
        "
      />
      <BubbleChartPopupRow
        class="u-spacing-mt-l"
        :axis-heading="'Vertical Axis'"
        :axis-label="popupData.yAxisLabel"
        :coordinate="
          getCoordinate(
            ((popupData || {}).originalValues || {}).y,
            popupData.yAxisUnit,
            popupData.coordinatePrecision
          )
        "
      />
      <BubbleChartPopupRow
        v-if="((popupData || {}).originalValues || {}).z"
        class="u-spacing-mt-l"
        :axis-heading="'Bubble Radius'"
        :axis-label="popupData.zAxisLabel"
        :coordinate="
          getCoordinate(
            ((popupData || {}).originalValues || {}).z,
            popupData.zAxisUnit,
            popupData.coordinatePrecision
          )
        "
      />
    </section>
    <slot name="footer" />
  </section>
</template>

<script>
import BubbleChartPopupRow from './bubble-chart-popup-row.vue';
import { formatter } from '@/utils/helpers/formatter.js';
export default {
  components: {
    BubbleChartPopupRow
  },
  props: {
    popupData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    xCoordinate() {
      return formatter();
    }
  },
  methods: {
    getCoordinate(position, unit, precision) {
      return formatter(position, unit, undefined, precision);
    }
  }
};
</script>

<style lang="css" scoped>
.box-shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
