<template>
  <widgetContainer
    :header-options="uiHeaderOptions"
    :footer-options="footerOptionsComp"
    :context="context"
    :last-updated-date="lastUpdatedDate"
    :title="(metadata || {}).label"
    :description="(metadata || {}).description"
    :is-loading="isChartLoading"
    :header-container-grid-styles="headerContainerGridStyles"
    :enable-footer="enableFooter"
  >
    <div slot="body">
      <chart
        v-if="!isAllMetricZero"
        class="u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-mb-m"
        :config="derviedChartConfig"
        :data="chartData"
      />
      <FallbackDonutChart v-else />
      <div
        v-for="(listItem, key) in metricsArray"
        :key="key"
        class="u-flex-1"
      >
        <OpenCasesSummary
          :list-item="listItem"
          :metric-data="metricData"
          :widget-request-params="widgetRequestParams"
          @handleViewDetails="handleViewDetails"
        />
      </div>
    </div>
  </widgetContainer>
</template>

<script>
import chart from '@/components/basic/chart';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import loader from '@/components/basic/loader';
import { isEqual, cloneDeep } from 'lodash';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import FallbackDonutChart from '@/pages/automations/FallbackDonutChart.vue';
import OpenCasesSummary from '@/pages/automations/OpenCasesSummary.vue';
import moment from 'moment';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    widgetContainer,
    FallbackDonutChart,
    OpenCasesSummary,
    chart,
    loader
  },
  mixins: [cwMixin],
  props: {
    metadata: {
      type: Object,
      default: () => {}
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    enableFooter: {
      type: Boolean,
      default: true
    },
    chartConfig: {
      type: Object,
      default: function () {
        return {
          chartOptions: {
            legend: false,
            type: 'donut',
            data: {
              colors: {}
            },
            size: {
              donutDimensions: {
                height: 270,
                width: 270
              }
            }
          },
          donut: {
            title: '',
            expand: false,
            label: {
              show: false,
              position: 'right'
            },
            width: 27
          }
        };
      }
    }
  },
  data() {
    return {
      metricsArray: [],
      metricData: [],
      isChartLoading: false,
      chartData: [],
      donutTitle: 0,
      isAllMetricZero: false,
      derviedChartConfig: {}
    };
  },
  computed: {
    uiHeaderOptions() {
      const tempHeaderOptions = cloneDeep(this.headerOptionsComp) || [];
      const obj = {};
      tempHeaderOptions.push(obj);
      return tempHeaderOptions;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    }
  },
  created() {
    this.derviedChartConfig = this.chartConfig;
    this.getMetricList();
    this.isAllMetricZero = false;
    this.fetchData();
  },
  methods: {
    getMetricList() {
      const metadata = this.metadata.metadata;
      this.metricsArray = Object.values(this.metadata.metrics).map((item) => {
        item.key = item.label;
        item.title = item.label;
        return item;
      });
      this.metricsArray = this.metricsArray.filter((item) => {
        return !metadata?.defaultHiddenMetrics?.includes(item.alias);
      });
    },
    async fetchData() {
      this.isChartLoading = true;
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response;
      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        if (this.minMaxDate.minDate.includes('/')) {
          request.where.date.from = moment(
            this.minMaxDate.minDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DD');
          request.where.date.to = moment().format('YYYY-MM-DD');
        } else if (this.minMaxDate.minDate.includes('-')) {
          request.where.date.from = this.minMaxDate.minDate;
          request.where.date.to = moment().format('YYYY-MM-DD');
        }
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];
        await dashUtils.fetchDataAPI(request, api).then((args) => {
          response = args?.entityData[0]?.data;
        });
      }
      const data = {};
      response.forEach((e) => {
        data[':' + e.name] = e.RESULT[e.name];
      });
      this.metricData = response;
      this.setInitialValues();
      this.isChartLoading = false;
    },
    setInitialValues() {
      this.donutTitle = 0;
      this.chartData = [];
      this.isAllMetricZero = false;
      const meta = this.metadata.metrics;
      this.metricData.forEach((item) => {
        if (
          this.metadata?.metadata?.defaultConfig?.shownMetricList?.includes(
            item.name
          )
        ) {
          const resultValue = item.RESULT[item.name];
          this.donutTitle = this.donutTitle + resultValue;
          this.chartData.push([meta[item.name].label, resultValue]);
        }
      });
      this.derviedChartConfig.donut.title = `${this.donutTitle}`;
      if (this.donutTitle === 0) {
        this.isAllMetricZero = true;
      }
    },
    handleViewDetails(keyName) {
      this.$emit('handleViewDetails', {
        statusType: keyName,
        minMaxDate: this.minMaxDate
      });
    }
  }
};
</script>

<style lang="css">
.donut-details-container {
  font-size: 1.5rem;
}
.donut-details-container .detail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
}
.donut-details-container .detail-item .left-label {
  display: flex;
  align-items: center;
}
.donut-details-container .detail-item .right-label {
  display: flex;
  align-items: center;
}
.donut-details-container .detail-item .cursor-not-allowed {
  cursor: not-allowed;
}
.donut-details-container .detail-item .view-details.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.donut-details-container .detail-item .view-details:hover .icon-view-details,
.donut-details-container .detail-item .view-details:hover .view-details-text {
  color: #4b5158;
}
</style>
