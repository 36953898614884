import HomeV2 from '@/components/homeV2.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import {
  digitalShelfRoute,
  reportsRoute,
  homeRoute,
  advertisingRoute,
  campaignOptimizerChildrenRoutes,
  getCampaignManagementChildrenRoutesWithTabConfig
} from '@/router/commonRoutePaths.js';
const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const aholdcitrusRoutes = {
  path: '/us/ahold_citrus/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect: 'advertising/campaign_management'
    },
    reportsRoute,
    digitalShelfRoute,
    {
      ...advertisingRoute,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutesWithTabConfig('ahold')
        }
      ]
    },

    ...commonInternalRoutesArray
  ]
};

export { aholdcitrusRoutes };
