export const emailRequestConfig = (context) => ({
  bodyTitle: {
    text: 'Email Report',
    highlightText: '',
    icon: 'email-subscription'
  },
  body: {
    text: 'Request for an email with a link to download this report.'
  },
  footer: {
    leftButton: {
      text: 'Cancel',
      onClick: () => {
        context.hideModal();
      },
      icon: '',
      show: true
    },
    rightButton: {
      text: 'Request',
      onClick: () => {
        context.changeModalConfig('emailConfirmConfig');
      },
      icon: '',
      show: true
    }
  }
});

export const emailConfirmConfig = (context) => ({
  bodyTitle: {
    text: 'Request Recieved',
    highlightText: '',
    icon: 'Send'
  },
  body: {
    text: 'Look out for an email with a link to download the report.',
    subtext: 'Requests may take upto 5 minutes to be processed'
  },
  footer: {
    leftButton: {
      show: false
    },
    rightButton: {
      text: 'Done',

      onClick: () => {
        context.hideModal();
      },
      icon: '',
      show: true
    }
  }
});
