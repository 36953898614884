<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.739258"
      width="11"
      height="8"
      rx="1.5"
      stroke="#8B8F93"
      fill="#8B8F93"
    />
    <g clip-path="url(#clip0_810_18324)">
      <line
        x1="7.6666"
        y1="6.61188"
        x2="4.95229"
        y2="4.18327"
        stroke="white"
      />
      <line
        x1="7.50363"
        y1="6.02877"
        x2="11.6465"
        y2="1.88595"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="3.63655"
        y2="-0.5"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 2.71436 6.38232)"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="2.42437"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 2.71436 6.38232)"
        stroke="white"
      />
    </g>
    <g clip-path="url(#clip1_810_18324)"></g>
    <rect
      x="0.5"
      y="-0.5"
      width="3"
      height="1"
      transform="matrix(1 0 0 -1 4 9.76025)"
      stroke="#8B8F93"
    />
    <g clip-path="url(#clip2_810_18324)"></g>
    <rect
      x="0.25"
      y="-0.25"
      width="5.5"
      height="0.5"
      transform="matrix(1 0 0 -1 3 11.2603)"
      stroke="#8B8F93"
      stroke-width="0.5"
    />
    <defs>
      <clipPath id="clip0_810_18324">
        <rect
          y="0.239258"
          width="12"
          height="9"
          rx="2"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_810_18324">
        <rect
          width="4"
          height="2"
          transform="matrix(1 0 0 -1 4 10.7603)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_810_18324">
        <rect
          width="6"
          height="1"
          transform="matrix(1 0 0 -1 3 11.7603)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SystemTransparencyIcon',
  props: {
    width: {
      default: '',
      type: String
    },
    height: {
      default: '',
      type: String
    }
  }
};
</script>
