<template>
  <div class="u-spacing-pv-xxxl">
    <section class="u-spacing-pt-m u-spacing-ph-xxl">
      <creativeHeader :step="step" />
      <campaignCreative
        :profile-assets="allAssetsForProfile"
        :suggested-campaign-index="suggestedCampaignIndex"
        :selected-values="selectedValues"
        :all-steps-data="allStepsData"
        :skus="skus"
        :load="
          (allAssetsForProfile && allAssetsForProfile.load) || pageAsinLoader
        "
      />
      <creativeFooter
        :disable-right-btn="!isNextStepValid"
        class="u-margin-top-40"
        :error-message="errorMessage"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import creativeHeader from '@/components/ams/campaign_creation/steps/common/header';
import creativeFooter from '@/components/ams/campaign_creation/steps/common/footer';
import campaignCreative from './campaignCreative.vue';
import { cloneDeep } from 'lodash';
export default {
  components: {
    creativeHeader,
    creativeFooter,
    campaignCreative
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    skus: [],
    suggestedCampaignIndex: 0,
    pageAsinLoader: false,
    errorMessage: ''
  }),
  computed: {
    allAssetsForProfile() {
      const assets = cloneDeep(
        this.$store?.getters?.['campaignCreation/getAssets']
      );
      if (this.selectedAdFormat === 'video') {
        assets.rows = assets.rows.filter(
          (asset) => asset?.mediaType === 'video'
        );
      }
      return assets;
    },
    isNextStepValid() {
      let valid = true;
      const {
        creativeSkus,
        brandInfo,
        selectedAsset,
        adFormat,
        selectedPages
      } = this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (adFormat === 'productCollection') {
        valid = creativeSkus?.length === 3;
      } else if (adFormat === 'storeSpotlight') {
        valid = selectedPages?.length === 3;
      }
      if (
        !(
          brandInfo?.brandName?.length &&
          brandInfo?.brandName?.length <= 30 &&
          brandInfo?.headline?.length &&
          brandInfo?.headline?.length <= 50
        ) &&
        this.selectedAdFormat !== 'video'
      ) {
        valid = false;
      }
      if (!selectedAsset) {
        valid = false;
      }

      return valid;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const { adFormat, creativeSkus, adFormatSubType, skus } =
      this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex] ||
      {};
    this.selectedAdFormat = adFormat;
    this.getAssets();
    if (adFormat === 'productCollection' || adFormat === 'video') {
      if (adFormatSubType === 'new_landing_page' || adFormat === 'video') {
        this.skus = skus;
      } else {
        this.skus = creativeSkus;
        this.updateSelectedStore({ creativeSkus: [] });
      }
    } else if (adFormat === 'storeSpotlight') {
      this.getPages();
    }
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    getPages() {
      const { storeOnAmazonStore } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      const storePageInfo = storeOnAmazonStore?.storePageInfo.filter(
        (item) => (item) => item?.storePageName?.toLowerCase() !== 'home'
      );
      const homePage = storePageInfo.filter(
        (item) => item?.storePageName?.toLowerCase() === 'home'
      )?.[0];
      this.updateSelectedStore({
        storePageInfo,
        homePage
      });
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    getAssets() {
      const reqParams = {
        profileId:
          this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
            ?.profile?.profile_id,
        mediaType: 'brandLogo'
      };
      if (this.selectedAdFormat === 'video') {
        delete reqParams.mediaType;
      }
      this.$store.dispatch('campaignCreation/fetchAssets', reqParams);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>
