<template>
  <section
    v-if="!pageFetched && !pageFetchedError"
    class="view-port u-display-flex u-flex-direction-column"
  >
    <loader
      class="u-height-100 u-bg-color-grey-white"
      :loading="true"
      color="#007cf6"
    />
  </section>
  <section
    v-else-if="pageFetchedError"
    class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
  >
    <div class="u-display-flex noskuImage_container">
      <img
        alt="error"
        src="/images/no_sku_found.png"
        class="u-width-100"
      />
    </div>
    <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
      <p class="u-color-grey-base u-spacing-mr-s">Something went wrong!</p>
    </div>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';

export default {
  components: {
    loader
  },
  props: {
    pageFetched: {
      type: Boolean,
      default: false
    },
    pageFetchedError: {
      type: Boolean,
      default: false
    }
  }
};
</script>
