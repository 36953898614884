<template>
  <div
    class="u-height-100 u-font-size-5 u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <Loader
      :loading="loading"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      :loading="!loading"
      class="u-spacing-mv-xl"
    >
      <div class="u-spacing-pv-m u-font-size-3 u-text-align-center">
        Invite Users by their Email Ids
      </div>
      <table class="invite-user-table">
        <thead>
          <tr>
            <th />
            <th
              v-for="(role, roleIndex) in roles"
              :key="roleIndex"
            >
              {{ role }}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in users"
            :key="index"
          >
            <td>
              <input
                v-model="user.mail"
                class="u-spacing-pv-s"
                type="text"
                placeholder="email"
              />
            </td>
            <td
              v-for="(role, roleIndex1) in roles"
              :key="roleIndex1"
            >
              <div
                class="toggle"
                :class="{
                  on: user.roles.indexOf(role) !== -1,
                  off: user.roles.indexOf(role) === -1
                }"
                @click="toggleRoleOnNewUser(user, role)"
              >
                <div />
              </div>
            </td>
            <td>
              <rb-button
                v-if="users.length > 1"
                :text="'X'"
                :click-fn="removeUser.bind(this, index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="u-spacing-mt-m u-display-flex u-flex-justify-content-center">
        <rb-button
          :type="'filled'"
          class="u-spacing-mr-m"
          :text="'Add more users'"
          :click-fn="addMoreUsers"
        />
        <rb-button
          :type="'filled'"
          :text="'Invite'"
          :click-fn="triggerInvite"
        />
      </div>
    </div>
    <div
      v-if="response && response.status === 'success'"
      class="u-spacing-pv-m u-font-size-5"
    >
      <div
        v-for="(a, msgIndex) in response.message"
        :key="msgIndex"
      >
        {{ a.user }} - {{ a.error }}
      </div>
    </div>
    <div
      v-if="response && response.status === 'failure'"
      class="error_section u-spacing-pv-m u-font-size-5"
    >
      {{ response.message }}
    </div>
    <div
      v-if="showUserInviteLink"
      class="u-spacing-pv-m u-font-size-3 u-color-red-base"
    >
      <rb-icon
        class="rb-icon--medium u-spacing-mr-s"
        icon="info-circle-fill"
      />No welcome email enabled!!! Please copy and share the URL with the user
    </div>
    <div class="u-spacing-pv-m u-font-size-3">Existing Users</div>
    <div class="u-spacing-mb-xxl">
      <table class="userTable">
        <thead>
          <tr>
            <th>User</th>
            <th>Roles</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody class="u-spacing-pb-xl">
          <tr
            v-for="(user, userIndex) in existingUsers"
            :key="userIndex"
            :class="{ inactive: user.brUserEnable === 'FALSE' }"
          >
            <td>{{ user.mail }}</td>
            <td>{{ user.roles.join(', ') }}</td>
            <td v-if="user.brUserEnable === 'TRUE'">activated</td>
            <td v-else>deactivated</td>
            <td>
              <div class="u-display-flex u-flex-align-items-center">
                <rb-button
                  :text="'Edit'"
                  :size="'s'"
                  :click-fn="editUser.bind(this, user)"
                />
                <span
                  v-if="
                    showUserInviteLink && user.passwordResetLinkUsed === 'false'
                  "
                  v-tippy=""
                  title="Copy to clipboard"
                  class="u-spacing-ml-s"
                  @click="copyToClipboard($event, user)"
                >
                  <rb-icon
                    class="u-color-grey-lighter u-cursor-pointer"
                    :icon="'copy-to-clipboard'"
                  />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="showConfirmPopup">
      <confirm-dialog
        :show-dialog="showConfirmPopup"
        title="Add new email Org"
        confirm-text="Add anyways"
        :message="confirmMessage"
        @confirm="inviteUsers()"
        @cancel="showConfirmPopup = false"
      />
    </div>
    <div
      v-if="userToUpdate"
      class="u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <div class="u-overflow-hidden">
        <div
          class="u-bg-color-blue-base u-spacing-p-m u-color-white-base u-font-weight-700"
        >
          <span class="u-spacing-mv-m u-font-size-4 u-text-align-center">{{
            userToUpdate.mail
          }}</span>
        </div>
        <div
          style="width: 50%; margin: auto"
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pv-m"
        >
          <div>Account Status</div>
          <div
            class="toggle"
            :class="{
              on: userToUpdate.brUserEnable === 'TRUE',
              off: userToUpdate.brUserEnable === 'FALSE'
            }"
            @click="toggleActStatus"
          >
            <div />
          </div>
        </div>
        <h4 class="u-font-size-5 u-text-align-center">Roles</h4>
        <div
          v-for="(role, roleIndex) in roles"
          :key="roleIndex"
          style="width: 50%; margin: auto"
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pv-s"
        >
          <div>{{ role }}</div>
          <div
            class="toggle"
            :class="{
              on: userToUpdate.roles.indexOf(role) !== -1,
              off: userToUpdate.roles.indexOf(role) === -1
            }"
            @click="toggleRole(role)"
          >
            <div />
          </div>
        </div>
        <div class="u-display-flex u-flex-justify-content-center u-spacing-p-m">
          <rb-button
            class="u-spacing-mr-s"
            :text="'Cancel'"
            :type="'hollow'"
            :size="'s'"
            :click-fn="closeModal"
          />
          <rb-button
            :text="'Save'"
            :type="'filled'"
            :size="'s'"
            :click-fn="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
import confirmDialog from '@/components/basic/confirmDialog.vue';
import Vue from 'vue';
import helper from '@/utils/helpers/index.js';

export default {
  components: {
    Loader,
    confirmDialog
  },
  data() {
    return {
      users: [{ mail: '', roles: [] }],
      roles: Vue.options.filters.config_check('users.validRoles').split(','),
      userToUpdate: null,
      showConfirmPopup: false,
      confirmMessage: '',
      showUserInviteLink:
        Vue.options.filters.config_check('feature.userInviteLink.enable') ||
        false
    };
  },
  computed: {
    response() {
      return this.$store.getters.getUserInviteResponse;
    },
    loading() {
      return this.$store.getters.getuserInviteLoading;
    },
    existingUsers() {
      return this.$store.getters.getExistingUsers;
    }
  },
  mounted: function () {
    this.$store.dispatch('loadUsers');
  },
  methods: {
    copyToClipboard(event, user) {
      const link =
        window.location.host + `/welcome?id=${user.passwordResetHash}`;
      helper.copyToClipboardText(link, event);
      this.$snackbar.open({
        message: 'Copied to clipboard',
        position: 'is-bottom-left',
        actionText: '',
        duration: 1000
      });
    },
    getConfirmMessage(domains) {
      return `You are adding user(s) from  new domain(s) "${domains.join(
        ', '
      )}".  Please make sure these email ID's are correct, as having access to the product can expose sensitive data.
        Do you want to continue?`;
    },
    getNewUsersDomains(existingUsers, newUsers) {
      const existingClientDomains = new Set();
      const conflictDomains = [];
      existingUsers.forEach((user) => {
        const domain = user.mail.split('@')[1];
        existingClientDomains.add(domain);
      });
      newUsers.forEach((userInfo) => {
        const emailId = userInfo.mail;
        const emailDomain = emailId.split('@')[1];
        if (!existingClientDomains.has(emailDomain)) {
          if (conflictDomains.indexOf(emailDomain) === -1) {
            conflictDomains.push(emailDomain);
          }
        }
      });
      return conflictDomains;
    },
    triggerInvite() {
      var finalUsers = this.users
        .map((user) => {
          const mail = user.mail.trim();
          const roles = user.roles;
          return {
            mail,
            roles
          };
        })
        .filter((user) => {
          return user.mail.length;
        });
      const conflictDomains = this.getNewUsersDomains(
        this.existingUsers,
        finalUsers
      );
      if (conflictDomains.length > 0) {
        this.showConfirmPopup = true;
        this.confirmMessage = this.getConfirmMessage(conflictDomains);
      } else {
        this.inviteUsers();
      }
    },
    inviteUsers() {
      this.showConfirmPopup = false;
      var finalUsers = this.users
        .map((user) => {
          const mail = user.mail.trim();
          const roles = user.roles;
          return {
            mail,
            roles
          };
        })
        .filter((user) => {
          return user.mail.length;
        });
      this.$store.dispatch('inviteUsers', finalUsers);
    },
    addMoreUsers() {
      this.users.push({ mail: '', roles: [] });
    },
    toggleRoleOnNewUser(user, role) {
      if (user.roles.indexOf(role) === -1) {
        user.roles.push(role);
      } else {
        user.roles = user.roles.filter((a) => {
          return a !== role;
        });
      }
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },
    editUser(user) {
      this.userToUpdate = JSON.parse(JSON.stringify(user));
    },
    toggleActStatus() {
      this.userToUpdate.brUserEnable =
        this.userToUpdate.brUserEnable === 'TRUE' ? 'FALSE' : 'TRUE';
    },
    toggleRole(role) {
      if (this.userToUpdate.roles.indexOf(role) === -1) {
        this.userToUpdate.roles.push(role);
      } else {
        this.userToUpdate.roles.splice(
          this.userToUpdate.roles.indexOf(role),
          1
        );
      }
    },
    closeModal() {
      this.userToUpdate = null;
    },
    save() {
      this.$store
        .dispatch('saveUsers', [this.userToUpdate])
        .then((response) => {
          this.closeModal();
          this.$store.dispatch('loadUsers');
        });
    }
  }
};
</script>

<style lang="css" scoped>
.error_section {
  max-width: 800px;
  text-align: center;
}
.invite-user-table {
  width: 600px;
}
.invite-user-table th {
  padding: 8px;
  text-align: left;
}
.invite-user-table td > div {
  margin: auto;
}
.invite-user-table input {
  width: 400px;
  padding: 8px;
}
.userTable {
  width: 800px;
  text-align: left;
  border-spacing: 0;
}
.userTable .inactive {
  background: #f88;
}
.userTable th,
.userTable td {
  padding: 8px 8px;
  border-bottom: 1px solid #ccc;
}
.userTable th:nth-child(1) {
  width: 500px;
}
.userTable th:nth-child(2) {
  width: 200px;
}
.userTable th:nth-child(3) {
  width: 100px;
}
.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
.modal > div {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 500px;
}
.toggle {
  height: 16px;
  width: 24px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}
.toggle.off {
  background: #f88;
}
.toggle.on {
  background: #0e0;
}
.toggle.on > div {
  margin-left: 10px;
}
.toggle > div {
  transition: margin, 300ms;
  height: 14px;
  width: 14px;
  background: #fff;
  border-radius: 8px;
  margin: 1px;
}
</style>
