<template>
  <div
    class="u-display-flex u-flex-direction-column u-spacing-mh-s u-width-100 u-color-grey-mid-light"
  >
    <div class="u-font-size-6 u-spacing-mv-s">
      <img
        src="https://cdn.rboomerang.com/cva/Dimension.svg"
        class="u-color-blue-base u-spacing-mr-s"
      />
      <span class="u-font-weight-600"> Share of Voice </span> in Top Categories
    </div>
    <div
      class="u-display-flex u-flex-direction-column u-flex-justify-content-space-around categoryWrapper u-position-relative"
    >
      <div
        v-for="(category, index) in topPerformers"
        :key="index"
        class="u-display-flex u-flex-align-items-center"
      >
        <div
          class="u-flex-1 u-font-size-6 u-font-weight-600 u-text-case-title u-spacing-pr-m"
        >
          <span v-if="category.title">
            {{ category.title }}
          </span>
          <span v-else> Category Name NA </span>
          <div class="u-spacing-pt-s u-font-weight-500 u-text-case-title">
            <span
              v-if="category.volume"
              class="u-color-grey-dark u-font-weight-600"
            >
              {{ formatByCurrency(category.volume) }} {{ subText }}
            </span>
            <span v-else> {{ noSearchVolText }} </span>
          </div>
        </div>
        <div class="u-flex-1">
          <div class="u-height-category-bar">
            <div
              v-if="category.categoryStrength"
              class="u-display-flex u-height-100 u-line-height-category-bar"
            >
              <span
                :style="{
                  width:
                    `${findCategoryStrength(
                      category.categoryStrength,
                      index
                    )}` + '%',
                  'background-color': `${fillColors[index]}`,
                  'border-color': `${borderColors[index]}`
                }"
                class="u-border-all u-border-radius-s u-spacing-pl-s"
              />
              <span
                class="u-font-size-5 u-font-weight-600 u-text-align-center u-spacing-pl-xs"
              >
                {{ category.categoryStrength.toFixed(2) }}&#37;
              </span>
            </div>
            <span
              v-else
              class="u-font-size-7"
            >
              SoV not available
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { showSearchVolumeColumn } from '@/components/pages/insights/amazon/cva/utils';
export default {
  props: {
    topPerformers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      subText: 'searches/ day',
      fillColors: ['#ECF6D0', '#D2F5FC', '#FFE2E6'],
      borderColors: ['#97CC04', '#23B5D3', '#FF6072']
    };
  },
  computed: {
    noSearchVolText() {
      if (showSearchVolumeColumn()) {
        return 'Search Vol Not available';
      }
    }
  },
  methods: {
    formatByCurrency(value) {
      return Vue.options.filters.num_format(value);
    },
    findCategoryStrength(value, index) {
      if (index === 0) {
        this.maxValue = value;
        return 80;
      } else {
        value = ((value * 80) / this.maxValue).toFixed(0);
        return value;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.categoryWrapper {
  height: 80%;
}
.categoryWrapper::before {
  content: '';
  width: 1px;
  height: 85%;
  position: absolute;
  background-color: #e9eaeb;
  left: 48%;
}

.u-height-category-bar {
  height: 35px;
}
.u-line-height-category-bar {
  line-height: 32px;
}
</style>
