<template>
  <div class="u-display-flex u-flex-direction-column">
    <!-- <filter-panel :context="'overview'" class="u-spacing-ph-m u-spacing-pt-m" :emit="config.filters.emit" :maxDate="'sales_by_account_type'" :config="config.filters" :state="filterState" :apply-filter="applyFilter">
    <div slot="search">
      <asin-search :config="config.filters.search"></asin-search>
    </div>
  </filter-panel> -->

    <div
      class="u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-mb-m sticky u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        :data="filterData"
        :loader-status="loaderStatus"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'insight-financial-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="config.filters.hasSearch"
        :new-date="true"
        :allow-date-compare="true"
        :max-date="getOverviewPageWiseMinMaxKey"
        :apply-filter="applyFilter"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :on-create="onCreate"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <span
            class="u-spacing-mr-s back-arrow"
            @click.stop="backButton()"
          >
            <rb-icon
              data-cy="arrow_back"
              :icon="'arrow_back'"
              class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
            />
          </span>
          <PageTitle :title="'Finance'" />
        </div>
        <div
          slot="search"
          class="u-spacing-mr-m"
        >
          <asin-search :config="config.filters.search" />
        </div>
      </rb-filter-panel>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
      />
    </div>
    <div
      class="u-display-flex u-spacing-ph-m u-spacing-pb-l u-flex-wrap-yes"
      data-cy="financial-report"
    >
      <div
        v-for="(widget, index) in config.widgets"
        :key="index"
        class="card u-position-relative u-spacing-mb-l u-min-width-40 u-flex-1 u-spacing-pb-s"
        :class="{
          'u-spacing-mr-l':
            index % 2 === 0 && index !== config.widgets.length - 1
        }"
      >
        <div class="card__header">
          <div
            v-if="widget.header1"
            class="u-display-flex u-flex-direction-column"
          >
            <rb-tooltip
              :label="widget.header1.tooltip"
              position="is-right"
              multilined
            >
              <span class="card__title u-color-grey-mid-light">
                {{ widget.header1.title }}
              </span>
            </rb-tooltip>
            <div
              v-if="
                getInsightsOverviewData[widget.chartDataKey] &&
                getInsightsOverviewData[widget.chartDataKey].header1
              "
              class="flex-row u-flex-align-items-center u-spacing-mt-s"
            >
              <div class="card__value">
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag1
                  "
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag1
                  "
                  class="u-font-size-2 u-color-grey-light u-font-weight-600"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header1.tag1
                      | num_format(
                        (widget.header1.tag1Unit || {}).pre,
                        (widget.header1.tag1Unit || {}).suff,
                        undefined,
                        undefined,
                        (widget.header1.tag1Unit || {}).format
                      )
                  }}
                </span>
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2 &&
                    !(widget.header1.tag2 || {}).invert
                  "
                  v-trend-indicator
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2
                  "
                  class="u-font-size-6 u-spacing-pl-xs"
                  :class="{
                    'u-color-red-base':
                      getInsightsOverviewData[widget.chartDataKey].header1
                        .tag2 < 0,
                    'u-color-green-base':
                      getInsightsOverviewData[widget.chartDataKey].header1
                        .tag2 > 0
                  }"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2
                      | num_format(
                        widget.header1.tag2Unit.pre,
                        widget.header1.tag2Unit.suff
                      )
                  }}
                </span>
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2 &&
                    (widget.header1.tag2 || {}).invert
                  "
                  v-trend-indicator
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2
                  "
                  class="u-font-size-6 u-spacing-pl-xs"
                  :class="{
                    'u-color-green-base':
                      getInsightsOverviewData[widget.chartDataKey].header1
                        .tag2 < 0,
                    'u-color-red-base':
                      getInsightsOverviewData[widget.chartDataKey].header1
                        .tag2 > 0
                  }"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header1.tag2
                      | num_format(
                        widget.header1.tag2Unit.pre,
                        widget.header1.tag2Unit.suff
                      )
                  }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="widget.header2"
            class="u-display-flex u-flex-direction-column u-flex-align-items-flex-end"
          >
            <rb-tooltip
              :label="widget.header2.tooltip"
              position="is-left"
              multilined
            >
              <span class="card__title u-color-grey-mid-light">
                {{ widget.header2.title }}
              </span>
            </rb-tooltip>
            <div
              v-if="
                getInsightsOverviewData[widget.chartDataKey] &&
                getInsightsOverviewData[widget.chartDataKey].header2
              "
              class="flex-row u-flex-align-items-center u-spacing-mt-s"
            >
              <div class="card__value">
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2 &&
                    !(widget.header2.tag2 || {}).invert
                  "
                  v-trend-indicator
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2
                  "
                  class="u-font-size-6 u-spacing-pr-xs"
                  :class="{
                    'u-color-red-base':
                      getInsightsOverviewData[widget.chartDataKey].header2
                        .tag2 < 0,
                    'u-color-green-base':
                      getInsightsOverviewData[widget.chartDataKey].header2
                        .tag2 > 0
                  }"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2
                      | num_format(
                        widget.header2.tag2Unit.pre,
                        widget.header2.tag2Unit.suff
                      )
                  }}
                </span>
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2 &&
                    (widget.header2.tag2 || {}).invert
                  "
                  v-trend-indicator
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2
                  "
                  class="u-font-size-6 u-spacing-pr-xs"
                  :class="{
                    'u-color-green-base':
                      getInsightsOverviewData[widget.chartDataKey].header2
                        .tag2 < 0,
                    'u-color-red-base':
                      getInsightsOverviewData[widget.chartDataKey].header2
                        .tag2 > 0
                  }"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header2.tag2
                      | num_format(
                        widget.header2.tag2Unit.pre,
                        widget.header2.tag2Unit.suff
                      )
                  }}
                </span>
                <span
                  v-if="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag1
                  "
                  :value="
                    getInsightsOverviewData[widget.chartDataKey].header2.tag1
                  "
                  class="u-font-size-2 u-color-grey-light u-font-weight-600"
                >
                  {{
                    getInsightsOverviewData[widget.chartDataKey].header2.tag1
                      | num_format(
                        (widget.header2.tag1Unit || {}).pre,
                        (widget.header2.tag1Unit || {}).suff
                      )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="(getInsightsOverviewData[widget.chartDataKey] || {}).noData"
          class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-4"
        >
          <p>No data available for selected filters.</p>
        </div>
        <div
          v-if="(getInsightsOverviewData[widget.chartDataKey] || {}).error"
          class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-4"
        >
          <p>Error Occurred. Please try again later</p>
        </div>
        <loader
          class="fill--parent"
          :loading="(getInsightsOverviewData[widget.chartDataKey] || {}).load"
          :color="'#3684bb'"
        />
        <chart
          class="u-spacing-mh-m"
          :config="
            extendChartConfig(
              widget,
              (getInsightsOverviewData[widget.chartDataKey] || {}).config
            )
          "
          :data="(getInsightsOverviewData[widget.chartDataKey] || {}).data"
        />
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between card-footer u-spacing-pt-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
        >
          <span class="u-color-grey-x-light u-font-size-7">
            Last Updated : {{ getAllDates()[widget.date] | formattable_date }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetsMixin';
import utils from '@/utils/helpers';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import asinSearch from '@/components/widgets/asinSearch';

var oObject = {
  beforeCreate() {
    this.config = widgetsConfig;
    /*
     * Change cube name, label & tooltip based on a config
     * Revenue Lost due to OOS chart becomes Revenue Lost due to Unavailable SKUs
     */
    if (
      Vue.options.filters.config_check(
        'feature.merge_unavailable_recommendation.enable'
      )
    ) {
      this.config.widgets.forEach((widget) => {
        if (widget?.widgetId && widget.widgetId === 'revenue-lost-oos') {
          widget.widgetId = 'revenue-lost-unavailable-skus';
          widget.header1.title = 'Revenue Lost due to Unavailable SKUs';
          widget.header1.tooltip =
            'Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC.';
          widget.APIConfig.cubeName = 'gv_imputations';
        }
      });
    }
  },
  mixins: [widgetsUtil],
  created() {
    this.fetchFilters('client_workbench_full_catalogue_latest_comp_data');
    eventBus.$on(
      'overviewSearchTriggered',
      function (data) {
        window.overviewAsin = data;
        this.$store.dispatch(
          'getRevenueData',
          Vue.util.extend({}, this.config.widgets[0])
        );
        this.$store.dispatch(
          'getMarginData',
          Vue.util.extend({}, this.config.widgets[1])
        );
        this.$store.dispatch(
          'getOOSData',
          Vue.util.extend({}, this.config.widgets[2])
        );
      }.bind(this)
    );
  },
  computed: {
    getInsightsOverviewData() {
      return this.$store.getters.getInsightsOverviewData;
    },
    getOverviewPageWiseMinMaxKey() {
      return this.$store.getters.getOverviewPageWiseMinMaxKey;
    }
  },
  data() {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      filterState: {
        getter: 'getInsightsOverviewSelectedFilter',
        setter: 'setInsightsOverviewSelectedFilter'
      },
      filterInstance: null,
      showActiveFilterBanner: true
    };
  },
  methods: {
    onCreate(filter) {
      this.filterInstance = filter;
    },
    extendChartConfig(baseObj, newObj) {
      return utils.nativeExtend({}, baseObj, newObj);
    },
    backButton() {
      this.$router.push({ path: `${this.$store.getters.getRootPath}reports` });
    }
  },
  components: {
    chart,
    loader,
    rbFilterPanel,
    asinSearch
  }
};

export default oObject;
</script>

<style lang="css">
.underline-text {
  text-decoration: underline;
}
</style>
