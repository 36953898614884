import { Category } from './backend-type';
import { Checkbox, CheckboxGroup, NestedCheckbox } from './checkbox';
import { NestedRadio, Radio, RadioGroup } from './radio';
import { DisabledHook, Selector } from './selector';
import { SelectorGroup } from './selector-group';

export function constructGroupsFromHierarichalData(
  data: Category,
  multi: boolean | undefined,
  parent: Selector | null,
  disabledHook?: DisabledHook
): SelectorGroup<Selector> {
  const group = multi ? new CheckboxGroup(parent) : new RadioGroup(parent);
  Object.keys(data)
    .map((id): (NestedCheckbox | Checkbox) | (Radio | NestedRadio) => {
      if (Object.keys(data[id].children).length > 0) {
        const selector = multi
          ? new NestedCheckbox(id, group)
          : new NestedRadio(id, group);
        selector.addChildGroup(
          constructGroupsFromHierarichalData(
            data[id].children,
            data[id]?.multi !== undefined ? data[id]?.multi : true,
            selector,
            disabledHook
          )
        );
        selector.registerDisabledHook(disabledHook);
        return selector;
      } else {
        const selector = multi ? new Checkbox(id, group) : new Radio(id, group);
        selector.registerDisabledHook(disabledHook);
        return selector;
      }
    })
    .forEach(group.addSelector.bind(group));
  return group;
}
