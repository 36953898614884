import productDiagnostics from './product-diagnostics-dashboard.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: false,
  routes: [
    {
      path: 'product-diagnostics',
      name: 'product-diagnostics-dashboard',
      component: productDiagnostics
    },
    {
      path: '',
      name: 'product-diagnostics-dashboard',
      component: productDiagnostics
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.ESM
    }
  }))
};
