<template>
  <div class="u-width-100">
    <rb-tooltip-header
      v-if="params.toolTipText"
      :id="setTooltip.id"
      :title="params.displayName"
      :message="tooltipMessage"
    />
    <rb-icon
      v-tippy="setTooltip.tippyOptions"
      class="rb-icon--small u-spacing-mh-s u-color-grey-lighter"
      :icon="params.displayIcon"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'iconTableHeaders',
  components: {},
  data() {},
  computed: {
    tooltipMessage() {
      if (this.params.toolTipText) {
        if (
          this.params.toolTipText.toLowerCase() ===
          this.params.displayName.toLowerCase()
        ) {
          return '';
        }
        return this.params.toolTipText.includes(':')
          ? this.params.toolTipText.split(':')[1].trim()
          : this.params.toolTipText;
      }
      return '';
    },
    setTooltip() {
      return {
        id: this.params.column.colId,
        tippyOptions: {
          html: '#' + this.params.column.colId,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    }
  },
  created() {},
  methods: {}
};
</script>
