import { render, staticRenderFns } from "./name-message.vue?vue&type=template&id=4fd29d3a&scoped=true&"
import script from "./name-message.vue?vue&type=script&lang=js&"
export * from "./name-message.vue?vue&type=script&lang=js&"
import style0 from "./name-message.vue?vue&type=style&index=0&id=4fd29d3a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd29d3a",
  null
  
)

export default component.exports