<template>
  <div
    v-tippy="tippyOptions"
    class="tag_token tag_token_container u-spacing-mv-xs u-spacing-pv-xs u-spacing-mr-s u-cursor-default u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    :class="[
      'u-font-size-' + tagFontSize,
      'u-color-' + tagFontColor,
      isTagClickable && 'u-cursor-pointer',
      extraWrpClass
    ]"
    :style="enableStyleApi && { 'background-color': tagColor }"
    :title="tippyTitle"
    @click.stop="handleTagClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-white-space-no-wrap tag-text"
    >
      {{ title }}
    </div>
    <div
      v-for="(iconData, index) in icons"
      :key="index"
      :class="[
        'rb-icon--' + tagIconSize,
        'icon-' + iconData.icon,
        'u-color-' + iconData.color
      ]"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer u-spacing-ml-s tag-icon"
      @click.stop="onIconClick(iconData)"
    />
  </div>
</template>

<script>
export default {
  props: {
    enableStyleApi: {
      type: Boolean,
      default: true
    },
    extraWrpClass: {
      type: String,
      default: ''
    },
    isTagClickable: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    },
    tippyTitle: {
      type: String,
      default: null
    },
    tippyProps: {
      type: Object,
      default: null
    },
    icons: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    },
    tagColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    tagFontSize: {
      type: String,
      default: '7'
    },
    tagFontColor: {
      type: String,
      default: 'grey-base'
    },
    tagIconSize: {
      type: String,
      default: 'xx-small'
    },
    normalClass: {
      type: String,
      default: ''
    },
    highlightingClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // isHovering: false
    };
  },
  computed: {
    tippyOptions() {
      let _tippyOptions = {};
      if (this.tippyProps) {
        _tippyOptions = this.tippyProps;
      } else {
        _tippyOptions = {
          title: 'Manage keywords with this tag',
          placement: 'bottom-start',
          arrow: false,
          offset: '50px, 10px',
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }
        };
      }
      return _tippyOptions;
    }
  },
  created() {},
  methods: {
    onIconClick(icon) {
      this.$emit(icon.emit, this);
    },
    handleMouseEnter() {
      this.$emit('mouseEnter');
      this.isHovering = true;
    },
    handleMouseLeave() {
      this.$emit('mouseLeave');
      this.isHovering = false;
    },
    handleTagClick() {
      this.$emit('tagClicked', this);
    }
  }
};
</script>

<style lang="css" scoped>
.tag_token {
  padding: 10px 8px 9px;
  border-radius: 100px;
}

.tag_item_style_class {
  background-color: rgba(189, 16, 224, 0.1);
}
</style>
