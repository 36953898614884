<template>
  <div
    v-if="showDialogData"
    :class="containerClass"
    class="confirm_dialog_container u-font-size-5 bg__full-screen--semitransparent"
  >
    <slot name="content">
      <div
        class="content u-border-radius-s u-bg-color-grey-white u-flex-direction-column u-spacing-p-xl"
      >
        <div
          class="header u-font-size-1 u-spacing-ph-l u-spacing-pt-l u-spacing-pb-m u-border-radius-s u-font-weight-600 u-text-align-center"
        >
          {{ title }}
        </div>
        <div
          class="message_container u-spacing-ph-l u-spacing-mb-s u-font-size-3 u-text-align-center"
        >
          {{ message }}
        </div>
        <div
          class="action_section u-display-flex u-flex-justify-content-center u-spacing-pv-l u-border-width-s u-border-color-grey-xxx-light"
        >
          <span
            class="u-cursor-pointer u-border-all hollow-outlined-btn u-border-color-blue-base u-border-width-s u-color-grey-white u-spacing-pv-s u-spacing-ph-l u-font-size-5 u-font-weight-600 u-border-radius-s u-spacing-mr-m"
            @click="cancel"
            >{{ cancelText }}</span
          >
          <span
            :class="{
              'u-bg-color-blue-base': alertType === '',
              'u-bg-color-red-base': alertType === 'error',
              'u-bg-color-orange-base': alertType === 'warning'
            }"
            class="u-cursor-pointer u-color-grey-white u-font-size-5 u-font-weight-600 u-border-radius-s u-spacing-pv-s u-spacing-ph-l"
            @click="confirm"
            >{{ confirmText }}</span
          >
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Title'
    },
    message: {
      type: String,
      default: 'dialog confirm text'
    },
    width: {
      type: String,
      default: '100px'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    showDialog: {
      type: Boolean,
      default: true
    },
    alertType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialogData: true
    };
  },
  watch: {
    showDialog(bShow) {
      this.showDialogData = bShow;
    }
  },
  methods: {
    confirm() {
      this.showDialogData = false;
      this.$emit('confirm');
    },
    cancel() {
      this.showDialogData = false;
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="css">
.confirm_dialog_container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
