<template>
  <div>
    <div class="u-display-flex">
      <div
        :class="{
          'left-button-campaign-details': hollowHoverButtonLeft,
          'u-spacing-pr-m': showLeftBtn
        }"
      >
        <rb-button
          v-if="showLeftBtn"
          :text="btnLeftText"
          :type="leftButtonType"
          :class="[customClassLeft, buttonMinWidth]"
          :click-fn="btnLeftClick"
          :disabled="disableLeftBtn"
          :size="buttonSize"
          v-bind="additionLeftBtnProps"
        />
      </div>
      <slot name="center" />
      <rb-button
        v-if="showRightBtn"
        :text="btnRightText"
        :class="[customClassRight, buttonMinWidth]"
        :disabled="disableRightBtn"
        :type="rightButtonType"
        :size="buttonSize"
        :click-fn="btnRightClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hollowHoverButtonLeft: {
      type: Boolean,
      default: true
    },
    leftButtonType: {
      type: String,
      default: 'hollow'
    },
    rightButtonType: {
      type: String,
      default: 'hollow'
    },
    customClassLeft: {
      type: String,
      default: ''
    },
    customClassRight: {
      type: String,
      default: ''
    },
    showLeftBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    },
    btnRightText: {
      type: String,
      default: 'Cancel'
    },
    btnLeftText: {
      type: String,
      default: 'Save'
    },
    disableLeftBtn: {
      type: Boolean,
      default: false
    },
    disableRightBtn: {
      type: Boolean,
      default: false
    },
    buttonSize: {
      type: String,
      default: 'm'
    },
    buttonMinWidth: {
      type: String,
      default: ''
    },
    additionLeftBtnProps: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    btnLeftClick() {
      this.$emit('onClickBtnLeft');
    },
    btnRightClick() {
      this.$emit('onClickBtnRight');
    }
  }
};
</script>

<style lang="css">
.left-button-campaign-details .rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
.left-button-campaign-details .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
</style>
