export function getProfilesToUpdate(
  currentProfilesData,
  fetchedProfileResponse
) {
  const profilesToUpdate = [];
  if (fetchedProfileResponse.length === 0) {
    return profilesToUpdate;
  }
  let wasProfile1Updated = false;
  let profile1UpdateObject = {};
  let wasProfile2Updated = false;
  let profile2UpdateObject = {};

  // Comparing Profile 1 values
  const currentProfile1 = currentProfilesData[0] || null;
  const fetchedProfile1 = fetchedProfileResponse[0] || null;
  if (currentProfile1 && fetchedProfile1) {
    if (currentProfile1.brandName !== fetchedProfile1?.searchAmpName) {
      profile1UpdateObject.brandName = currentProfile1.brandName;
      wasProfile1Updated = true;
    }
    if (currentProfile1.headline !== fetchedProfile1?.headlineText) {
      profile1UpdateObject.headline = currentProfile1.headline;
      wasProfile1Updated = true;
    }
    if (currentProfile1.url !== fetchedProfile1?.clickUrl) {
      profile1UpdateObject.url = currentProfile1.url;
      wasProfile1Updated = true;
    }
    if (currentProfile1.file !== fetchedProfile1?.logoUrl) {
      profile1UpdateObject.file = currentProfile1.file;
      wasProfile1Updated = true;
    }
    profile1UpdateObject.sbaProfileId = fetchedProfile1?.sbaProfileId;
  }

  // Comparing Profile 2 values
  const currentProfile2 = currentProfilesData[1] || null;
  const fetchedProfile2 = fetchedProfileResponse[1] || null;
  if (currentProfile2 && fetchedProfile2) {
    if (currentProfile2.brandName !== fetchedProfile2?.searchAmpName) {
      profile2UpdateObject.brandName = currentProfile2.brandName;
      wasProfile2Updated = true;
    }
    if (currentProfile2.headline !== fetchedProfile2?.headlineText) {
      profile2UpdateObject.headline = currentProfile2.headline;
      wasProfile2Updated = true;
    }
    if (currentProfile2.url !== fetchedProfile2?.clickUrl) {
      profile2UpdateObject.url = currentProfile2.url;
      wasProfile2Updated = true;
    }
    if (currentProfile2.file !== fetchedProfile2?.logoUrl) {
      profile2UpdateObject.file = currentProfile2.file;
      wasProfile2Updated = true;
    }
    profile2UpdateObject.sbaProfileId = fetchedProfile2?.sbaProfileId;
  }

  if (wasProfile1Updated === true) {
    profilesToUpdate.push(profile1UpdateObject);
  }
  if (wasProfile2Updated === true) {
    profilesToUpdate.push(profile2UpdateObject);
  }
  return profilesToUpdate;
}

export function getProfilesToAdd(currentProfilesData, fetchedProfileResponse) {
  const profilesToAdd = [];
  if (fetchedProfileResponse.length === 0 && currentProfilesData.length > 0) {
    currentProfilesData.forEach((profileItem) => {
      if (profileItem) {
        profilesToAdd.push(profileItem);
      }
    });
  }
  if (fetchedProfileResponse.length === 1 && currentProfilesData.length > 1) {
    if (currentProfilesData[1]) {
      profilesToAdd.push(currentProfilesData[1]);
    }
  }
  return profilesToAdd;
}

export const validateHeightAndWidthOfLogo = (height, width) => {
  let message = '';
  const expectedHeight = 180;
  const expectedWidth = 300;
  if (height !== expectedHeight) {
    message = `Logo height should be ${expectedHeight}px`;
  }
  if (width !== expectedWidth) {
    message = `Logo width should be ${expectedWidth}px`;
  }
  return message;
};

function checkImageDimensions(imgObject) {
  return new Promise((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(imgObject);
    img.onload = function () {
      const errorMessage = validateHeightAndWidthOfLogo(
        this.height,
        this.width
      );
      URL.revokeObjectURL(objectUrl);
      resolve(errorMessage);
    };
    img.src = objectUrl;
  });
}

export async function validateFile(file) {
  let errorMessage = '';
  if (!file) {
    errorMessage = 'Logo is mandatory';
  } else if (file && typeof file !== 'string') {
    if (file.size > 204800) {
      errorMessage = 'The logo should be within 200kb.';
    } else if (!file.type.includes('png')) {
      errorMessage = 'The logo should be a png file';
    } else {
      errorMessage = await checkImageDimensions(file);
    }
  }
  return errorMessage;
}

export function validateBrandName(brandName) {
  let errorMessage = '';
  if (!brandName) {
    errorMessage = 'Brand name is mandatory';
  } else if (brandName.length > 35) {
    errorMessage = 'The Brand name text should not exceed 35 characters.';
  }
  return errorMessage;
}

export function validateHeadline(headline) {
  let errorMessage = '';
  if (!headline) {
    errorMessage = 'Headline is mandatory';
  } else if (headline.length > 45) {
    errorMessage = 'The Headline text should not exceed 45 characters.';
  }
  return errorMessage;
}

export function validateUrl(url) {
  let errorMessage = '';
  if (!url) {
    errorMessage = 'URL is mandatory';
  } else if (!url.startsWith('https://www.walmart.com')) {
    errorMessage =
      'The click URL should be a valid URL starting with https://www.walmart.com';
  }
  return errorMessage;
}
