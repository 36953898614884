<template>
  <div class="bar-chart-container">
    <div
      v-for="barData in chartData"
      class="bar-container"
    >
      <img
        :title="barData.retailer"
        :src="barData.imgUrl"
        alt="retailer logo"
      />
      <div
        class="vertical-bar"
        :style="{
          height: `${barData.value}%`,
          background: barData.color || chartConfig.color
        }"
      />
      <p class="u-font-size-7 u-spacing-p-xs u-font-weight-600">
        {{ barData.value + '%' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarChartWithImage',
  props: {
    chartConfig: {
      type: Object,
      default() {
        return {
          color: '#72b9ff'
        };
      }
    },
    chartData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped lang="css">
.bar-chart-container {
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.bar-chart-container .bar-container {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}
.bar-chart-container .bar-container p {
  text-align: center;
}
.bar-chart-container .bar-container img {
  height: 16px;
  width: 16px;
  margin-left: auto;
  margin-right: auto;
}
.bar-chart-container .bar-container .vertical-bar {
  width: 22px;
  border-radius: 2px;
}
</style>
