<template>
  <section>
    <section
      v-show="data.load"
      class="u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white fill--parent"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
    <section
      v-show="data.error"
      class="u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </section>
    <section
      v-show="!(data.error || data.load)"
      class="u-bg-color-grey-white u-border-all u-border-width-s u-border-color-grey-xxx-light"
    >
      <section
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pv-s"
      >
        <section class="u-font-size-5 u-spacing-ph-m u-font-weight-600">
          {{ header }}
        </section>
        <section class="u-spacing-pv-s u-spacing-ph-m">
          <div class="u-display-flex u-position-relative">
            <input
              v-model="searchValue"
              :placeholder="'Search'"
              class="search__input"
              @keypress="searchSubmit"
            />
            <!-- <rb-input
              class="u-width-100"
              :classList="['search__input']"
              :rounded="false"
            ></rb-input> -->
            <span
              class="u-position-absolute u-display-flex search-class"
              @click="searchSubmit"
            >
              <rb-icon
                icon="search"
                class="search__input-icon rb-icon--medium u-color-grey-x-light"
              />
            </span>
          </div>
        </section>
      </section>
      <section>
        <slot name="body" />
      </section>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
export default {
  components: {
    loader
  },
  props: {
    header: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: () => {
        return {};
      }
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      searchValue: ''
    };
  },
  created() {},
  methods: {
    searchSubmit(event) {
      console.log(event);
      this.$emit(
        'onSearch',
        this.searchValue,
        event.key === 'Enter' || event.type === 'click'
      );
    },
    handleOptionSelect() {}
  }
};
</script>

<style lang="css" scoped>
.search__input {
  height: 24px;
  width: 200px;
  background-color: f5f7fa;
  border: 1px solid #e9eaeb;
}
.search__input:focus {
  outline: none;
}
.search-class {
  top: 0px;
  right: 12px;
  bottom: 0;
  margin: auto;
}
::placeholder {
  padding-left: 6px;
  color: #aaadb1;
}
</style>
