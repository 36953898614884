<template>
  <div
    class="u-border-radius-xxxl u-border-color-grey-xxx-light u-border-width-s u-border-all"
  >
    <rb-select
      class="u-display-flex u-flex-align-items-center u-spacing-mr-xs"
      :style="{ height: varHeight }"
      open-direction="bottom-end"
      :send-details="true"
      :options="options"
      :on-close="onRollupChangeSelected"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small u-spacing-ml-s u-spacing-mr-xs u-color-grey-lighter"
          :icon="iconVal"
        />
        <span
          class="u-spacing-mr-xs u-flex-0 u-color-grey-mid-light u-font-weight-600 u-font-size-7"
          >{{ label }}</span
        >
        <div
          class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-font-weight-600 u-color-grey-base"
        >
          {{ selectedRollUp.name }}
        </div>
        <rb-icon
          class="u-flex-0 u-spacing-ml-xs rb-icon--small u-color-grey-lighter"
          icon="caret-down"
        />
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div class="u-display-flex u-font-size-6 u-flex-align-items-center">
          {{ option.name }}
        </div>
      </template>
    </rb-select>
  </div>
</template>

<script>
export default {
  props: {
    iconVal: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    varHeight: {
      type: String,
      default: '24px'
    }
  },
  data() {
    return {
      selectedRollUp: this.options.find((el) => el.key === this.value)
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedRollUp = this.options.find((el) => el.key === val);
      },
      immediate: true
    }
  },
  methods: {
    onRollupChangeSelected(context, rollUp) {
      if (rollUp && rollUp[0]) {
        this.selectedRollUp = rollUp[0];
        this.$emit('onRollUpChange', rollUp[0]);
      }
    }
  }
};
</script>
