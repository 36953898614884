<template>
  <div class="u-spacing-pv-xxxl u-position-relative">
    <!-- todo -->
    <loader
      v-show="rightButtonLoading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <section class="u-height-100 u-spacing-pt-m u-spacing-ph-xxl">
      <creativeHeader :step="step" />
      <Profile
        ref="profileComponent"
        :selected-values="selectedValues"
        :all-steps-data="allStepsData"
        :profile2expand="profile2Expand"
        :form-error-object="formErrorObject"
        @profile2ExpandClicked="profile2ExpandClicked"
        @hook:mounted="profileComponentMounted"
      />
      <creativeFooter
        :disable-right-btn="disableRightButton"
        :right-button-loading="rightButtonLoading"
        btn-right-text="Send for review"
        class="u-spacing-pt-l"
        :error-message="errorMessage"
        @onClickBtnRight="onClickBtnRight"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import creativeHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import creativeFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import Profile from './profile.vue';
import loader from '@/components/basic/loader.vue';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import {
  getProfilesToUpdate,
  getProfilesToAdd,
  validateFile,
  validateBrandName,
  validateHeadline,
  validateUrl
} from '@/components/ams/campaign_creation/walmart_steps/profile/util.js';
import {
  ErrorCodes,
  WalmartCreateCustomException
} from '@/components/ams/campaign_creation/walmart_steps/errorUtility';
export default {
  name: 'ProfileIndexComponent',
  components: {
    creativeHeader,
    creativeFooter,
    Profile,
    loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      campaignId: null,
      adGroupId: null,
      advertiserId: null,
      rightButtonLoading: false,
      initialFetchLoading: false,
      profile2Expand: false,
      walmartCampaignCreateDataService: {},
      formErrorObject: {
        profile1: {
          file: '',
          brandName: '',
          headline: '',
          src: ''
        },
        profile2: {
          file: '',
          brandName: '',
          headline: '',
          src: ''
        }
      },
      errorMessage: ''
    };
  },
  computed: {
    areValidationsErrorPresentForProfile1() {
      const formErrorObject = this.formErrorObject.profile1;
      let areValidationsErrorPresent =
        this.areValidationsErrorPresentInObject(formErrorObject);
      return areValidationsErrorPresent;
    },
    areValidationsErrorPresentForProfile2() {
      const formErrorObject = this.formErrorObject.profile2;
      let areValidationsErrorPresent =
        this.areValidationsErrorPresentInObject(formErrorObject);
      return areValidationsErrorPresent;
    },
    disableRightButton() {
      let disabled = false;
      const profile1 = this.allStepsData?.profile1;
      if (this.areValidationsErrorPresentForProfile1) {
        disabled = true;
        return disabled;
      }
      if (this.allStepsData?.campaignNameAlreadyExists === true) {
        disabled = true;
        return disabled;
      }
      if (
        !profile1?.brandName ||
        !profile1?.headline ||
        !profile1?.url ||
        !profile1?.file
      ) {
        disabled = true;
        return disabled;
      }
      if (this.profile2Expand) {
        const profile2 = this.allStepsData?.profile2;
        if (this.areValidationsErrorPresentForProfile2) {
          disabled = true;
          return disabled;
        }
        if (
          !profile2?.brandName ||
          !profile2?.headline ||
          !profile2?.url ||
          !profile2?.file
        ) {
          disabled = true;
          return disabled;
        }
      }
      if (this.rightButtonLoading) {
        disabled = true;
        return disabled;
      }
      return disabled;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  watch: {
    allStepsData: {
      handler: function (newVal) {
        this.checkFormValidity();
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.initializeDataService();
    await this.fetchSaveAndPreFillValues();
  },
  methods: {
    handleCaughtError(err) {
      if (err.message === ErrorCodes.SOMETHING_WENT_WRONG) {
        this.errorMessage =
          'Something went wrong. Please try again after some time.';
      }
      if (err.message === ErrorCodes.FETCHING_PROFILE_DETAILS_FAILED) {
        this.errorMessage =
          'Something went wrong while fetching profile details. Please close the page and try again';
      }
      if (err.message === ErrorCodes.ADD_PROFILES_FAILED) {
        this.errorMessage =
          'Something went wrong while adding the profiles. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_PROFILES_FAILED) {
        this.errorMessage =
          'Something went wrong while updating the profiles. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_PROFILE_IMAGES_FAILED) {
        this.errorMessage =
          'Something went wrong while updating the Brand logo. Please try again after some time.';
      }
      if (err.message === ErrorCodes.SUBMIT_FOR_REVIEW_FAILED) {
        this.errorMessage =
          'Something went wrong while sending the profile for review. Please try again after some time.';
      }
    },
    areValidationsErrorPresentInObject(formErrorObject) {
      let areValidationsErrorPresent = false;
      for (const key of Object.keys(formErrorObject)) {
        if (formErrorObject[key] !== '') {
          areValidationsErrorPresent = true;
          return areValidationsErrorPresent;
        }
      }
      return areValidationsErrorPresent;
    },
    async checkFormValidity() {
      const formErrorObject = { ...this.formErrorObject };

      async function validateProfile(profileData) {
        const errorObjectToReturn = {
          file: '',
          brandName: '',
          headline: '',
          url: ''
        };
        errorObjectToReturn.file = await validateFile(profileData?.file);
        errorObjectToReturn.brandName = validateBrandName(
          profileData?.brandName
        );
        errorObjectToReturn.headline = validateHeadline(profileData?.headline);
        errorObjectToReturn.url = validateUrl(profileData?.url);
        return errorObjectToReturn;
      }

      formErrorObject.profile1 = await validateProfile(
        this.allStepsData?.profile1
      );
      formErrorObject.profile2 = await validateProfile(
        this.allStepsData?.profile2
      );

      this.formErrorObject = formErrorObject;
    },
    profileComponentMounted() {},
    async fetchSaveAndPreFillValues() {
      try {
        const fetchedProfileResponse = await this.fetchProfileDetails();
        let newFetchedProfileResponse = fetchedProfileResponse?.data?.response;
        // This means there are no profiles found for this ad group
        if (!Array.isArray(fetchedProfileResponse?.data?.response)) {
          newFetchedProfileResponse = [];
        }

        // For some reason removing await from the dispatch method seems to break the call
        // we make in the next control line which is 'this.$refs?.profileComponent?.preFillFieldsFromFetchedData()'
        await this.$store.dispatch(this.selectedValues.action, {
          ...this.allStepsData,
          ...{
            fetchedProfileResponse: newFetchedProfileResponse
          }
        });
        this.$refs?.profileComponent.preFillFieldsFromFetchedData();
        return newFetchedProfileResponse;
      } catch (err) {
        console.log(err);
        this.handleCaughtError(err);
      }
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      const retailer = this.$store.getters.getRetailer;
      this.campaignId = parseInt(campaignId) || undefined;
      this.adGroupId = parseInt(adGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      this.walmartCampaignCreateDataService =
        new WalmartCampaignCreateDataService(
          campaignId,
          adGroupId,
          advertiserId,
          campaignType,
          retailer
        );
    },
    async fetchProfileDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchProfileDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_PROFILE_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_PROFILE_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    profile2ExpandClicked() {
      this.profile2Expand = !this.profile2Expand;
    },
    async triggerSendForReview() {
      const { profile1, profile2 } = this.allStepsData;
      this.rightButtonLoading = true;
      try {
        const profilesArray = [profile1];
        if (this.profile2Expand) {
          profilesArray.push(profile2);
        }
        const response =
          await this.walmartCampaignCreateDataService.addProfiles([profile1]);
        if (response.data?.success === 1) {
          const sbaProfileId1 = response.data?.response[0]?.sbaProfileId;
          const sbaProfileId2 = response.data?.response[1]?.sbaProfileId;
          // Make upload api call here
          let uploadProfileImageResponse1;
          // let uploadProfileImageResponse2;
          if (sbaProfileId1) {
            uploadProfileImageResponse1 =
              await this.walmartCampaignCreateDataService.uploadProfileImage(
                profile1?.file,
                sbaProfileId1
              );
          }
          if (sbaProfileId2) {
            await this.walmartCampaignCreateDataService.uploadProfileImage(
              profile1?.file,
              sbaProfileId2
            );
          }
          if (uploadProfileImageResponse1?.data.status === 'OK') {
            // const submitForReviewResponse = await this.walmartCampaignCreateDataService.submitForReview();
            // if (submitForReviewResponse.data?.response[0]?.code === 'success') {
            //   this.$router.push({
            //     name: 'campaign_created'
            //   });
            // }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    checkIfProfilesWasUpdated() {
      try {
        const allStepsData = this.allStepsData;
        let fetchedProfileResponse = allStepsData?.fetchedProfileResponse;
        // If fetched profile response is not present, or if it is not an array, make it an empty array
        if (!fetchedProfileResponse || !Array.isArray(fetchedProfileResponse)) {
          fetchedProfileResponse = [];
        }
        const profile2 = this.profile2Expand ? allStepsData?.profile2 : null;
        const currentProfilesData = [allStepsData?.profile1, profile2];
        const profilesToAdd = getProfilesToAdd(
          currentProfilesData,
          fetchedProfileResponse
        );
        const profilesToUpdate = getProfilesToUpdate(
          currentProfilesData,
          fetchedProfileResponse
        );
        const wasProfilesUpdated =
          profilesToAdd.length > 0 || profilesToUpdate.length > 0 || false;
        return [wasProfilesUpdated, profilesToAdd, profilesToUpdate];
      } catch (err) {
        console.log(err);
      }
    },
    async addProfiles(profilesToAdd) {
      this.rightButtonLoading = true;
      try {
        const addProfilesResponse =
          await this.walmartCampaignCreateDataService.addProfiles(
            profilesToAdd
          );
        if (addProfilesResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.ADD_PROFILES_FAILED,
            addProfilesResponse
          );
        }
        return addProfilesResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.ADD_PROFILES_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async uploadProfileImages(profileArray) {
      // profileArrayFormat = {
      //   sbaProfileId : 12321,
      //   file : File() //File Format
      // }
      this.rightButtonLoading = true;
      try {
        for (let item of profileArray) {
          const uploadProfileImageResponse =
            await this.walmartCampaignCreateDataService.uploadProfileImage(
              item?.file,
              item?.sbaProfileId
            );
          if (uploadProfileImageResponse.data?.failure > 0) {
            throw new WalmartCreateCustomException(
              ErrorCodes.UPDATE_PROFILE_IMAGES_FAILED,
              uploadProfileImageResponse
            );
          }
        }
      } catch (err) {
        if (err.message === ErrorCodes.UPDATE_PROFILE_IMAGES_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async updateProfiles(profilesToUpdate) {
      this.rightButtonLoading = true;
      try {
        const updateProfilesResponse =
          await this.walmartCampaignCreateDataService.updateProfiles(
            profilesToUpdate
          );
        if (updateProfilesResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_PROFILES_FAILED,
            updateProfilesResponse
          );
        }
        return updateProfilesResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.UPDATE_PROFILES_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async submitForReview() {
      this.rightButtonLoading = true;
      try {
        const submitForReviewResponse =
          await this.walmartCampaignCreateDataService.submitForReview();
        if (submitForReviewResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.SUBMIT_FOR_REVIEW_FAILED,
            submitForReviewResponse
          );
        }
        return submitForReviewResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.SUBMIT_FOR_REVIEW_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async onClickBtnRight() {
      try {
        const [wasProfilesUpdated, profilesToAdd, profilesToUpdate] =
          this.checkIfProfilesWasUpdated();
        if (wasProfilesUpdated) {
          if (profilesToAdd.length > 0) {
            const addProfilesResponse = await this.addProfiles(profilesToAdd);
            const sbaProfileId1 =
              addProfilesResponse?.data?.response[0]?.sbaProfileId;
            const sbaProfileId2 =
              addProfilesResponse?.data?.response[1]?.sbaProfileId;
            const uploadProfileImagesArray = [];
            // sbaPRofileId1 will map to 1st item of profiledToAdd
            if (profilesToAdd[0]) {
              uploadProfileImagesArray.push({
                sbaProfileId: sbaProfileId1,
                file: profilesToAdd[0]?.file
              });
            }
            if (profilesToAdd[1]) {
              uploadProfileImagesArray.push({
                sbaProfileId: sbaProfileId2,
                file: profilesToAdd[1]?.file
              });
            }
            await this.uploadProfileImages(uploadProfileImagesArray);
          }

          if (profilesToUpdate.length > 0) {
            // Here don't make the call if there is just file update
            const updateProfilesResponse = await this.updateProfiles(
              profilesToUpdate
            );

            const profilesToUpdateFiles = profilesToUpdate.filter(
              (profileUpdateItem) => profileUpdateItem.file
            );

            await this.uploadProfileImages(profilesToUpdateFiles);
          }
        }
        // After everything is complete, send for review call is still yet to be made

        // Make the fetch call again, if atleast 1 profile is there and is successful, make the review API call
        const newFetchedProfileResponse =
          await this.fetchSaveAndPreFillValues();
        if (newFetchedProfileResponse.length > 0) {
          // This means there is atleast 1 profile present
          const submitForReviewResponse = await this.submitForReview();

          if (submitForReviewResponse.data?.response[0]?.code === 'success') {
            this.$router.push({
              name: 'campaign_created'
            });
          }
        }
      } catch (err) {
        this.handleCaughtError(err);
      }
    },
    nextStep() {
      this.triggerSendForReview();
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>
