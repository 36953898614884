import {
  adgroupConfig,
  productAndKeywordTargeting,
  classification,
  budget,
  timePeriod,
  biddingStrategySP,
  autoTargeting,
  autoTargetingGroups
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/index.js';

import CampaignType from '@/components/ams/campaign_creation/steps/edit/campaignTypes/index.vue';
import { cloneDeep } from 'lodash';

const campaignType = {
  name: 'Select Campaign Type',
  description: '',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'Select Campaign Type',
    desc: [
      'There are two main types of campaigns or ads you can create in Amazon: Sponsored Product ads and Sponsored Brands ads. The first of these, Sponsored Product ads, are the most common.'
    ]
  },
  key: 'campaignType',
  keyToRead: 'campaignType',
  breadCrumbTitle: 'Select Campaign Type',
  rows: autoTargetingGroups,
  textMapping: 'campaignType',
  radioGroupConfigs: {
    radioOptions: [
      {
        type: 'manual',
        label: 'Manual Campaign',
        desc: 'With a manual campaign, advertisers handpick their keywords they want to bid on and match types. Advertisers can also consult Amazon keyword Analytics tool to weed out low-performing keywords from their campaigns.'
      },
      {
        type: 'auto',
        label: 'Automatic campaign',
        desc: 'Automatic campaigns are the easiest way to bid on Amazon.com. Easy setup high-traffic placements, no keyword management and higher impression volume.'
      }
    ],
    selectedValue: 'manual',
    showRadioGroup: true
  },
  autoTargetingVisibility: true,
  component: CampaignType
};

const formStepConfig = (boolAutoCampaign = false) => {
  const adgroup = cloneDeep(adgroupConfig);
  const targetingroup = cloneDeep(productAndKeywordTargeting);
  const dates = cloneDeep(timePeriod);
  const bidStrategy = cloneDeep(biddingStrategySP);
  bidStrategy.boolLastStep = true;
  adgroup.name = 'Ad Group';
  adgroup.showLeftButton = true;
  dates.useNextButton = true;
  adgroup.requiredRows = 1;
  adgroup.moduleClass = 'campaign-creation-component-height-class-with-adgroup';
  targetingroup.requiredRows = 1;
  let stepsConfig = [
    campaignType,
    classification,
    budget,
    dates,
    adgroup,
    targetingroup,
    autoTargeting,
    bidStrategy
  ];

  return stepsConfig;
};

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  autoCampaignStepConfigs: formStepConfig(true),
  stepsConfig: formStepConfig(),
  enableStepsAfterValidation: true
};
