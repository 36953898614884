<template>
  <div
    v-show="showFeedback"
    class="bg__full-screen--semitransparent u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <div class="feedback-modal">
      <loader
        class="fill--parent"
        :loading="submitting"
        :color="'#3684bb'"
      />
      <div
        class="header u-display-flex u-spacing-p-m u-flex-justify-content-space-between u-flex-align-items-center"
      >
        <div class="u-flex u-font-size-5 u-font-weight-600">Feedback</div>
        <div @click="closeFeedback()">
          <rb-icon
            class="u-flex rb-icon--x-small u-cursor-pointer"
            icon="cross"
          />
        </div>
      </div>
      <div
        v-if="!showSuccess && !showAlertConfirmModal"
        class="body u-spacing-p-l"
      >
        <p class="u-font-size-5 u-font-weight-600">Feedback type*</p>
        <div class="u-spacing-mt-s u-font-size-5 u-color-grey-light">
          <div class="u-display-flex">
            <rb-select
              class="valueDropdown u-width-100"
              :send-details="true"
              :on-close="onFeedbackSelected"
              :options="feedbackTypes"
              class-name="feedback-select"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer u-width-100"
              >
                <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                  <p
                    v-if="!selectedType"
                    class="u-color-grey-lighter"
                  >
                    Choose an issue to report
                  </p>
                  <div
                    v-else
                    class="u-display-flex u-flex-align-items-center"
                  >
                    <rb-icon
                      :icon="selectedType.icon"
                      class="rb-icon--medium"
                      :class="selectedType.colorClass"
                    />
                    <p class="u-color-grey-base u-font-size-6 u-spacing-pl-xs">
                      {{ selectedType.title }}
                    </p>
                  </div>
                </div>
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-spacing-mt-s"
                  :title="option.title"
                >
                  <div class="u-spacing-pr-s">
                    <rb-icon
                      :icon="option.icon"
                      class="rb-icon--large"
                      :class="option.colorClass"
                    />
                  </div>
                  <div>
                    <p class="u-font-size-6 u-font-weight-600">
                      {{ option.title }}
                    </p>
                    <p
                      class="u-font-size-7 u-color-grey-mid-light u-spacing-mt-xs"
                    >
                      {{ option.description }}
                    </p>
                  </div>
                </div>
              </template>
            </rb-select>
          </div>
        </div>
        <p
          v-if="type === FEEDBACK_TYPES.ISSUE"
          class="u-font-size-5 u-spacing-mt-m u-font-weight-600"
        >
          Priority*
        </p>
        <div
          v-if="type === FEEDBACK_TYPES.ISSUE"
          class="u-spacing-mt-s u-font-size-5 u-color-grey-light"
        >
          <div class="u-display-flex">
            <rb-select
              class="valueDropdown u-width-100"
              :send-details="true"
              :on-close="onPrioritySelected"
              :options="priorities"
              class-name="feedback-select"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer u-width-100"
              >
                <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                  <p
                    v-if="!selectedPriority"
                    class="u-color-grey-lighter"
                  >
                    Choose a priority for the issue
                  </p>
                  <div
                    v-else
                    class="u-display-flex u-flex-align-items-center"
                  >
                    <rb-icon
                      :icon="selectedPriority.icon"
                      class="rb-icon--medium"
                      :class="selectedPriority.colorClass"
                    />
                    <p class="u-color-grey-base u-font-size-6 u-spacing-pl-xs">
                      {{ selectedPriority.title }}
                    </p>
                  </div>
                </div>
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-spacing-mt-s"
                  :title="option.title"
                >
                  <div class="u-spacing-pr-s u-spacing-pb-s">
                    <rb-icon
                      :icon="option.icon"
                      class="rb-icon--large"
                      :class="option.colorClass"
                    />
                  </div>
                  <div>
                    <p class="u-font-size-6 u-font-weight-600">
                      {{ option.title }}
                    </p>
                    <p
                      class="u-font-size-7 u-color-grey-mid-light u-spacing-mt-xs"
                    >
                      {{ option.description }}
                    </p>
                  </div>
                </div>
              </template>
            </rb-select>
          </div>
          <div
            v-if="priority === PRIORITY_TYPES.URGENT"
            class="u-spacing-pt-xs"
          >
            <rb-switch v-model="alertToggleValue" />
            Alert a representative immediately

            <el-tooltip
              placement="bottom-start"
              effect="light"
              popper-class="rra-popper-class"
              :visible-arrow="false"
            >
              <rb-icon
                class="u-cursor-pointer rb-icon--medium u-color-grey-lighter"
                :icon="'info-circle-outline'"
                style="position: relative; top: 2px"
              />
              <div slot="content">
                <span class="u-color-grey-base u-font-weight-600 u-font-size-5">
                  Alert a representative immediately!
                </span>
                <div class="u-spacing-mt-m u-font-size-6">
                  <span class="u-color-grey-light">
                    This option will page a representative immediately. Enable
                    it if you need immediate attention on your issue. If the
                    issue does not require immediately attention leave this
                    disabled. Our team will respond within 24 hours.
                  </span>
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>

        <div
          class="u-spacing-mt-l u-flex-align-items-center u-display-flex u-flex-justify-content-space-between u-display-flex"
        >
          <p class="u-font-size-5 u-font-weight-600">
            Share screenshot<span v-if="type === FEEDBACK_TYPES.ISSUE">*</span>
          </p>
          <span
            v-if="canvas"
            class="u-flex-align-items-center u-display-flex u-cursor-pointer action--text"
            @click="removeScreenshot"
          >
            <rb-icon
              class="rb-icon--small u-spacing-mr-xxs"
              icon="delete"
            />
            <span class="u-font-size-6 u-spacing-mr-xxs">Remove</span>
          </span>
        </div>
        <img
          v-if="canvas"
          class="u-spacing-mt-s"
          style="width: 376px; border: solid 1px #e9eaeb"
          :src="canvas"
        />
        <div
          v-else
          class="u-display-flex u-flex-align-items-center u-spacing-mt-s u-cursor-pointer u-spacing-p-m u-border-width-s u-border-all u-border-color-blue-base u-border-style-dashed u-border-radius-xs"
          @click="captureScreenshot"
        >
          <rb-icon
            icon="add-circle-fill"
            class="rb-icon--small u-color-blue-base u-spacing-mr-xs u-items-center"
          />
          <p class="u-font-size-7 u-font-weight-600 u-color-blue-base">
            Include a screenshot for better understanding
          </p>
        </div>
        <p class="u-font-size-5 u-font-weight-600 u-spacing-mt-l">
          Please add details&#42;
        </p>
        <textarea
          v-model="details"
          class="details-box u-spacing-mt-s u-border-radius-xs u-border-width-s u-border-color-grey-xxx-light"
          placeholder="Type details here"
        />
        <div
          class="u-display-flex u-spacing-mt-xs u-color-grey-lighter"
          style="width: 376px"
        >
          <div class="u-spacing-mt-xxs">
            <rb-icon icon="info-circle-fill rb-icon--small" />
          </div>
          <p class="u-font-size-7 u-spacing-ml-xs u-line-height-1-4">
            Having more details will help us understand better. Please be as
            descriptive as you can.
          </p>
        </div>
        <div
          class="u-display-flex u-flex-direction-row u-spacing-mt-l u-flex-justify-content-space-between"
        >
          <div class="u-flex-justify-content-flex-start">
            <div
              class="text-link u-text-align-center u-text-decoration-underline u-cursor-pointer u-line-height-27 u-font-size-6 u-font-weight-600 u-spacing-pl-s"
              @click="redirectToReportedIssues"
            >
              View Previous Issues
            </div>
          </div>
          <div class="u-flex-justify-content-flex-end">
            <rb-button
              slot="trigger"
              :click-fn="submit"
              :disabled="!isFeedbackFormValid"
              text="Submit"
              type="filled"
            />
          </div>
        </div>
      </div>
      <div v-else-if="!showSuccess && showAlertConfirmModal">
        <div class="body alert-confirm-modal">
          <div class="alert-img-container">
            <img src="/images/FeedbackAlertImg.png" />
          </div>
          <div class="u-spacing-pl-l u-spacing-pr-l">
            <span class="u-font-weight-600 u-colot-grey-base u-font-size-3">
              Alert a representative immediately!
            </span>
            <div
              class="u-spacing-pt-m u-font-size-6 u-font-weight-400 u-color-grey-light"
              style="line-height: 16px"
            >
              This option will page a representative immediately. Enable it if
              you need immediate attention on your issue. If the issue does not
              require immediately attention leave this disabled. Our team will
              respond within 24 hours.
            </div>
          </div>
          <div
            class="u-display-flex u-flex-direction-row-reverse u-spacing-p-l"
          >
            <rb-button
              :click-fn="confirmAlert"
              text="Confirm"
              type="filled"
              class="u-spacing-mr-s confirm-btn"
            />
            <rb-button
              :click-fn="cancelAlert"
              text="Cancel"
              type="hollow"
              class="u-spacing-mr-s cancel-btn"
            />
          </div>
        </div>
      </div>
      <transition
        v-else
        name="fade"
      >
        <div class="body feedback-success u-spacing-pv-l u-spacing-ph-xxl">
          <div class="u-display-flex u-flex-align-items-center">
            <rb-icon
              class="rb-icon--x-medium u-spacing-mr-xs u-color-green-base"
              icon="check-fill-circle"
            />
            <p class="u-font-size-2 u-font-weight-400">Feedback is shared</p>
          </div>
          <p
            class="u-font-size-5 u-spacing-mt-s u-font-weight-600 u-color-grey-lighter"
          >
            Progress updates will be sent to your
            <span class="u-color-grey-base">e-mail</span>
          </p>
          <div class="u-spacing-mt-l">
            <rb-button
              :click-fn="closeFeedback"
              text="Done"
              type="filled"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import getScreenshot from '@/utils/helpers/screenshot';
import CONSTANTS from '@/utils/constants';

export default {
  components: {
    loader
  },
  data() {
    return {
      details: '',
      type: '',
      priority: '',
      submitting: false,
      attachScreenshot: true,
      canvas: '',
      alertToggleValue: false,
      showAlertConfirmModal: false,
      show: true,
      selectedType: undefined,
      selectedPriority: undefined,
      feedbackTypes: [
        {
          value: 'question',
          title: 'Raise a query',
          description: 'Ask us your questions or queries.',
          icon: 'help-fill',
          colorClass: 'u-color-blue-base'
        },
        {
          value: 'enhancement',
          title: 'Suggest an enhancement',
          description: 'Tell us how we can improve your experience!',
          icon: 'light-bulb',
          colorClass: 'u-color-orange-base'
        },
        {
          value: 'issue',
          title: 'Report an issue',
          description:
            'Report issues like Data discrepancies, Loading time errors etc.',
          icon: 'bug',
          colorClass: 'u-color-red-base'
        }
      ],
      priorities: [
        {
          value: 'low',
          title: 'Low Priority',
          description:
            'Response provided within 24-48 hours. Target resolution time 15 days',
          icon: 'low-priority',
          colorClass: 'u-color-blue-base'
        },
        {
          value: 'high',
          title: 'High Priority',
          description:
            'Response provided within 24 hours. Target resolution time 72 hours.',
          icon: 'caution',
          colorClass: 'u-color-orange-base'
        },
        {
          value: 'urgent',
          title: 'Urgent',
          description:
            'Response is provided within 12 hours. Target resolution time is 24 hours.',
          icon: 'remove-fill',
          colorClass: 'u-color-red-base'
        }
      ],
      PRIORITY_TYPES: {
        URGENT: 'urgent',
        HIGH: 'high',
        LOW: 'low'
      },
      FEEDBACK_TYPES: {
        ISSUE: 'issue',
        ENHANCEMENT: 'enhancement',
        QUERY: 'question'
      },
      showSuccess: false
    };
  },
  computed: {
    showFeedback() {
      return this.$store.getters.getShowFeedback && this.show;
    },
    isFeedbackFormValid() {
      if (!this.type) return false;
      if (this.type === this.FEEDBACK_TYPES.ISSUE) {
        if (this.priority === '' || this.details === '' || this.canvas === '') {
          return false;
        }
      } else if (
        this.type === this.FEEDBACK_TYPES.ENHANCEMENT ||
        this.type === this.FEEDBACK_TYPES.QUERY
      ) {
        if (this.details === '') {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    alertToggleValue(newToggleValue, oldToggleValue) {
      if (newToggleValue !== oldToggleValue && newToggleValue === true)
        this.showAlertConfirmModal = true;
    }
  },
  methods: {
    submit() {
      if (this.details === '') {
        return false;
      }
      this.submitting = true;
      var feedback = {
        details: this.details,
        type: this.type,
        screenshot: this.attachScreenshot ? this.canvas : '',
        priority:
          this.type === this.FEEDBACK_TYPES.QUERY
            ? this.PRIORITY_TYPES.HIGH
            : this.priority,
        productLine: this.getProductLine() || CONSTANTS.PRODUCT_LINE.ESM,
        path: window.location.pathname,
        dontRaisePDAlert: !this.alertToggleValue
      };
      this.$store
        .dispatch('sendFeedback', feedback)
        .then(() => {
          this.canvas = '';
          this.submitting = false;
          this.details = '';
          this.type = '';
          this.showSuccess = true;
        })
        .catch(() => {
          this.canvas = '';
          this.submitting = false;
          this.details = '';
          this.type = '';
          this.closeFeedback();
          this.$snackbar.open({
            message: 'Something went wrong while submitting feedback.',
            duration: 5000,
            actionText: ' '
          });
        });
    },
    closeFeedback() {
      this.$store.commit('CLOSE_FEEDBACK');
      this.canvas = '';
      this.showSuccess = false;
      this.details = '';
      this.alertToggleValue = false;
      this.showAlertConfirmModal = false;
      this.type = '';
      this.selectedPriority = undefined;
      this.selectedType = undefined;
    },
    async captureScreenshot() {
      this.show = false;
      try {
        const screenshot = await getScreenshot();
        this.canvas = screenshot;
      } catch (err) {
        this.$snackbar.open({
          message:
            'Screen sharing has been declined - please activate it to take a screenshot.',
          duration: 5000,
          actionText: ' '
        });
        this.closeFeedback();
      } finally {
        this.show = true;
      }
    },
    removeScreenshot() {
      this.canvas = '';
    },
    onFeedbackSelected(context, selectedTypeOption) {
      this.selectedType = selectedTypeOption[0];
      this.type = selectedTypeOption[0].value;
      this.priority = '';
      this.selectedPriority = undefined;
    },
    onPrioritySelected(context, selectedPriorityOption) {
      this.selectedPriority = selectedPriorityOption[0];
      this.priority = selectedPriorityOption[0].value;
    },
    getProductLine() {
      const meta = this.$router.currentRoute.meta;
      if (meta && meta.productLine) {
        return meta.productLine;
      }
    },
    redirectToReportedIssues() {
      const reportedIssuesRoute = 'reportedIssues';
      this.$router.push({ name: reportedIssuesRoute });
      this.closeFeedback();
    },
    cancelAlert() {
      this.alertToggleValue = false;
      this.showAlertConfirmModal = false;
    },
    confirmAlert() {
      this.showAlertConfirmModal = false;
    }
  }
};
</script>

<style lang="css" scoped>
.feedback-modal {
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
  /* right: 56px; */
}
.feedback-modal .header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #4b5158;
  color: #fff;
}
.feedback-modal .body {
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.details-box {
  width: 376px;
  height: 88px;
  resize: none;
}

.u-border-style-dashed {
  border-style: dashed;
}

.u-width-35 {
  width: 35%;
}
textarea {
  padding: 8px;
}

textarea::placeholder {
  color: #caccce;
}
.u-hover:hover {
  color: #333;
}
.feedback-select p {
  width: inherit;
  white-space: pre-line;
  line-height: 1.5rem;
}

.feedback-success {
  min-height: 500px;
  width: 424px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.alert-confirm-modal {
  min-height: 500px;
  width: 424px;
}
.cancel-btn {
  color: #007cf6;
  border: 1px solid #007cf6;
  width: 101px;
}
.cancel-btn:hover {
  color: #3396f8;
  border: 1px solid #3396f8;
}
.confirm-btn {
  background: #d7263d;
}
.confirm-btn:hover {
  background: #f2334c;
}
.alert-img-container {
  padding: 40px 57px;
}
</style>
