<template>
  <div class="u-font-size-5 charting_wb_wrapper">
    <widgetContainer
      :header-options="headerOptionsComp"
      :footer-options="footerOptionsComp"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isChartLoading"
      :header-container-grid-styles="headerContainerGridStyles"
      :last-updated-date="lastUpdatedDate"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <template>
          <div
            v-if="enableDisplayIn"
            class="u-display-flex u-spacing-mt-m"
          >
            <chip-select
              class="u-spacing-mr-m"
              :show-settings-icon="true"
              :value="selectedDisplay"
              label="Display in: "
              :options="displayInRadioOptions"
              :display-label-over-value="true"
              @onSelectEvent="displayChanged"
            />
            <chip-select
              :show-settings-icon="true"
              :value="selectedDataSource"
              label="AVC Data Source: "
              :options="availableDataSources"
              :help-tooltip="dataSourceHelpTooltip()"
              :display-label-over-value="true"
              @onSelectEvent="dataSourceChanged"
            />
          </div>
        </template>
        <chart-with-legends
          :key="myKey"
          :chart-config="chartConfig"
          :chart-data="chartData"
          :metrics-list="metricList"
          :metrics-shown="shownMetricList"
          :default-select-metric="defaultSelectMetric"
          :metric-config="metricDisplayConfig"
          :metric-data="metricData"
          :disable-add-new-metric="disableAddNewMetricButtonComputed"
          :is-chart-loading="isChartLoading"
          :show-powered-by-c-i-q-text="true"
          :remove-dropdown="removeDropdownComputed"
          :selected-metric-limit="selectedMetricLimitChart"
          :minimum-metric="minimumMetric"
          :metric-font-size="'u-font-size-4'"
          :hide-zero="hideZero"
          :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
          :modify-tooltip-message="modifyTooltipMessage"
        >
          <div
            v-if="enableRollup"
            slot="space-between-legend-and-chart"
            class="u-display-flex u-spacing-mt-m"
          >
            <RollUpBy
              class="roll-up-by"
              label="Roll Up By:"
              :value="selectedRollUp"
              :date-obj="getGlobalRequestParams()"
              @onRollUpChange="rollUpChanged"
            />
          </div>
        </chart-with-legends>
      </template>
    </widgetContainer>
  </div>
</template>

<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import dualChartingMixin from './dual_charting_mixin.js';
import { cloneDeep, isEqual } from 'lodash';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import chipSelect from '@/components/basic/chipSelect.vue';
import Vue from 'vue';
import moment from 'moment-timezone';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  name: 'PerformanceOverviewShortages',
  components: {
    chartWithLegends,
    widgetContainer,
    chipSelect
  },
  mixins: [cwMixin, dualChartingMixin],
  data() {
    return {
      myKey: 1,
      dataGroups: {},
      metricData: {},
      metricsArray: [],
      metricDisplayConfig: {},
      metricEventConfig: this.metadata,
      selectedMetrics: [],
      metricList: [],
      shownMetricList: [],
      defaultSelectMetric: [],
      metricsDataResponse: [],
      selectedMetricLimitChart: this.selectedMetricLimit,
      isChartLoading: false,
      chartData: {
        data: []
      },
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      filtersConfig: [],
      selectedDisplay: '',
      selectedRollUp: 'WEEK',
      dualDataSources: true,
      selectedDataSource: ''
    };
  },
  computed: {
    displayInRadioOptions() {
      if (this.enableDisplayIn) {
        const availableDisplays =
          this.metadata.metadata.defaultConfig.availableDisplays;
        const selectOptions = Object.keys(availableDisplays).map((key) => ({
          value: key,
          label: availableDisplays[key].displayLabel?.replaceAll(
            'currency',
            Vue?.options?.filters?.config_check(
              'feature.i18n.currencyLabel.plural'
            )
          )
        }));
        return selectOptions;
      }
      return [];
    },
    availableDataSources() {
      const availableDataSources =
        this.metadata.metadata.defaultConfig.availableDataSources;
      const selectOptions = Object.values(availableDataSources).map(
        ({ value, label, tooltip }) => ({
          value,
          label,
          tooltip
        })
      );
      return selectOptions;
    },
    computedFilterConfig() {
      const filterConfig = JSON.parse(JSON.stringify(this.filtersConfig));
      return filterConfig;
    },
    removeDropdownComputed() {
      return (
        this.removeDropdown ||
        this.selectedMetricLimitChart === this.metricsArray.length
      );
    },
    disableAddNewMetricButtonComputed() {
      return (
        this.disableAddNewMetricButton ||
        this.selectedMetricLimitChart === this.metricsArray.length
      );
    },
    bypassMetricGroups() {
      return this?.metadata?.metadata?.selectedMetricsListUnaffected;
    },
    metricsFromMetadata() {
      return Object.values(this.metadata?.metrics)
        .filter((el) =>
          this.metadata.metadata.defaultConfig.availableDataSources[
            this.selectedDataSource
          ].metricsWhiteList.includes(el.name)
        )
        .reduce((obj, el) => {
          obj[el.keyName] = el;
          return obj;
        }, {});
    }
  },
  watch: {
    widgetRequestParams(nv, ov) {
      console.log(nv, ov);
      if (!isEqual(nv, ov)) {
        this.fetchData();
      }
    },
    selectedDisplay(nv, ov) {
      if (nv && ov) {
        this.generateSelectAndShownMetrics();
      }
    },
    selectedDataSource(nv, ov) {
      if (nv && ov) {
        this.initFunction();
        this.fetchData();
      }
    }
  },
  created() {
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    this.selectedDisplay = this.metadata.metadata.defaultConfig.selectedDisplay;
    this.selectedDataSource =
      this.metadata.metadata.defaultConfig.selectedDataSource;
    this.selectedRollUp = this.metadata.metadata.defaultConfig.selectedRollUp;
    this.initFunction();
    this.fetchData();
  },
  methods: {
    dataSourceHelpTooltip() {
      return (
        this.metadata.metadata.defaultConfig?.availableDataSources?.[
          this.selectedDataSource
        ]?.tooltip ?? ''
      );
    },
    initFunction() {
      const metadata = this.metadata.metadata;
      this.metricsDataResponse = [];

      // metrics from the response
      this.metricsArray = Object.values(this.metadata.metrics)
        .map((item) => {
          item.key = item.label;
          item.title = item.label;
          return item;
        })
        .filter((el) =>
          this.metadata.metadata.defaultConfig.availableDataSources[
            this.selectedDataSource
          ].metricsWhiteList.includes(el.name)
        )
        .filter((item) => !metadata?.defaultHiddenMetrics?.includes(item.name));

      this.metricList = cloneDeep(this.metricsArray);
      this.metricDisplayConfig = this.generateMetricDisplayConfig(
        this.metricsArray
      );

      this.generateSelectAndShownMetrics();
    },
    generateSelectAndShownMetrics() {
      this.myKey = Math.random();
      this.selectedMetrics =
        this.metadata.metadata.defaultConfig.availableDisplays[
          this.selectedDisplay
        ].selectedMetric[this.selectedDataSource];

      const shownMetricListFromMetadata =
        this.metadata.metadata.defaultConfig.availableDisplays[
          this.selectedDisplay
        ].shownMetricList[this.selectedDataSource];

      if (shownMetricListFromMetadata.length) {
        this.shownMetricList = this.metricsArray.filter((el) =>
          shownMetricListFromMetadata.includes(el.name)
        );
      } else {
        this.shownMetricList = cloneDeep(
          this.metricsArray.slice(0, this.selectedMetricLimit)
        );
      }

      // All the metrics should be rendered by default
      this.defaultSelectMetric = this.metricsArray.filter((el) =>
        this.selectedMetrics.includes(el.name)
      );
    },
    fetchData() {
      this.isChartLoading = true;
      const dataGroups = dashUtils.createDataGroups(this.metricsFromMetadata);

      let request, api;
      const dataGroupsList = Object.keys(dataGroups);

      for (let i = 0; i < dataGroupsList.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );
        let updatedDimensionValueList = [];

        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        if (this.bypassMetricGroups?.includes(dgKey)) {
          updatedDimensionValueList = this.widgetRequestParams[
            ':dimensionNameValueList'
          ]?.filter((item) => !this.bypassFilters.includes(item.dimensionName));
        } else {
          updatedDimensionValueList =
            this.widgetRequestParams[':dimensionNameValueList'];
        }
        request.where.dimensionNameValueList = updatedDimensionValueList;
        request = this.tempTransformer(request, metricsListName);
        dashUtils.fetchDataAPI(request, api).then((args) => {
          Vue.set(this.metricsDataResponse, i, args.entityData[0].data);
          this.dataTransformer(this.metricsDataResponse);
        });
      }
    },
    rollUpChanged(data) {
      this.selectedRollUp = data.key;
      const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    },
    displayChanged(data) {
      this.selectedDisplay = data.value;
    },
    dataSourceChanged(data) {
      this.selectedDataSource = data.value;
    }
  }
};
</script>

<style lang="css">
.charting_wb_wrapper .metricListClass {
  margin-bottom: 0;
}
.charting_wb_wrapper .chart_space_class {
  margin-top: 0;
}
.charting_wb_wrapper .header-container-cwc {
  padding-right: 24px;
}
</style>
