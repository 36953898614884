import HttpService from '@/utils/services/http-service';
import { store } from '@/store/store';
import utils from '@/utils/helpers/index.js';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
const STATUS_CHECK_INTERVAL_MIN = 5;

export const doEntityValueRename = (
  columnId,
  newColmValue,
  oldColmValue,
  entity
) => {
  const data = {
    entity,
    columnId: columnId,
    newColumnValue: newColmValue,
    oldColumnValue: oldColmValue
  };
  return HttpService.post('ENTITY_VALUE_RENAME', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

let fetchPostProcessingInterval = false;
export const fetchPostProcessingStatusAtInterval = (
  entities = [
    EntitiesForPostProcessingStatus.catalog,
    EntitiesForPostProcessingStatus.campaign,
    EntitiesForPostProcessingStatus.campaignList
  ]
) => {
  if (!fetchPostProcessingInterval) {
    fetchPostProcessingInterval = (entitiesList) => {
      for (const entityName of entitiesList) {
        store.dispatch('fetchPostProcessingStatus', entityName).then(() => {
          utils.checkAndClearLSFilterForEntity(entityName);
        });
      }
    };
    // for immediately triggering
    fetchPostProcessingInterval(entities);
    setInterval(() => {
      fetchPostProcessingInterval(entities);
    }, STATUS_CHECK_INTERVAL_MIN * 60 * 1000);
  }
};
