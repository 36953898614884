<template>
  <div
    class="u-spacing-pv-xxxl u-spacing-ph-xxl campaign-creation-campaign-targeting"
  >
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        class="add-keyword-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <div
          v-if="targetMessages && !targetsLength"
          slot="right-table-footer"
          class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
        >
          <rb-icon
            class="u-color-orange-base rb-icon--small"
            icon="info-circle-fill"
            data-cy="targetMsg"
          />
          {{ targetMessages }}
        </div>
      </component>

      <div class="u-flex-align-items-center u-display-flex">
        <CollapsibleTitle
          class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
          title="Negative Keyword Targeting"
          :is-expanded="negativeKeywordsExpand"
          title-font-class="u-font-size-5 u-font-weight-600"
          @click="() => (negativeKeywordsExpand = !negativeKeywordsExpand)"
        />
        <div class="u-spacing-pt-s">
          <div
            data-cy="optionalTxt"
            class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
          >
            Optional
          </div>
        </div>
      </div>

      <div v-if="negativeKeywordsExpand">
        <component
          :is="customActionPanelNegativeTargetingCustomState.component"
          v-if="customActionPanelNegativeTargetingCustomState"
          class="add-keyword-campaign-creation"
          v-bind="customActionPanelNegativeTargetingCustomState.props"
          v-on="customActionPanelNegativeTargetingCustomState.events"
        >
          <div
            v-if="targetMessages && !targetsLength"
            slot="right-table-footer"
            class="edit_suggested_campaign_keyword_targeting u-display-flex u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
          >
            <rb-icon
              class="u-color-orange-base rb-icon--small"
              icon="info-circle-fill"
              data-cy="targetMsg"
            />
            {{ targetMessages }}
          </div>
        </component>
      </div>

      <bidWarning
        :show="showTargetWarningMessage"
        :title="targetWarningTitle"
        :sub-text="targetWarningSubText"
      />

      <!-- <campaignTargetting /> -->
      <createCampaignFooter
        :disable-right-btn="disableRightButton"
        :class="showTargetWarningMessage ? 'u-spacing-mt-s' : 'u-margin-top-40'"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import bidWarning from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/bidWarning.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
// import campaignTargetting from './campaignTargetting';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    // campaignTargetting
    CollapsibleTitle,
    CustomActionPanel,
    bidWarning
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    negativeKeywordsExpand: false,
    showTargetWarningMessage: false,
    targetWarningTitle: '',
    targetWarningSubText: '',
    customActionPanelCustomState: null,
    customActionPanelNegativeTargetingCustomState: null,
    suggestedCampaignIndex: 0
  }),
  computed: {
    targetMessages() {
      return this.allStepsData?.suggestedCampaigns?.[
        this.suggestedCampaignIndex
      ]?.messages?.targets;
    },
    targetsLength() {
      return this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
        ?.targets?.length;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    },
    disableRightButton() {
      const targetsArray =
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.targets;
      for (const i in targetsArray) {
        let exact_bid = targetsArray[i]?.['exact_bid'];
        exact_bid = parseFloat(exact_bid);
        if (!exact_bid) {
          return true;
        }
      }
      return !this.targetsLength;
    }
  },
  watch: {
    targetsLength: {
      handler(newVal) {
        this.step.description = `${newVal || 0} keyword${
          newVal !== 1 ? "'s" : ''
        } added`;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    this.customActionPanelCustomState =
      this.step?.customActionPanelCustomState(this);
    this.customActionPanelNegativeTargetingCustomState =
      this.step?.customActionPanelNegativeTargetingCustomState(this);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style lang="css">
add-keyword-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
</style>

<style scoped>
.edit_suggested_campaign_keyword_targeting {
  bottom: -20px;
}
</style>
