<template>
  <div
    slot="dynamic"
    class="u-display-flex forecast-radio-wrapper"
  >
    <div class="u-display-flex u-flex-direction-column u-spacing-mr-l">
      <rb-radio
        v-model="formData.field"
        class="u-spacing-mr-l"
        native-value="newBid"
      >
        New bid
      </rb-radio>
      <span
        class="u-font-size-6 u-spacing-mt-xs u-spacing-mb-s u-color-grey-x-light"
        >Add a new bid</span
      >
      <div
        class="u-display-flex"
        :class="{
          'disabled u-pointer-events-none': formData.field !== 'newBid'
        }"
      >
        <span
          data-cy="currency"
          class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
        >
          {{ currency }}
        </span>
        <input
          v-model.number="newBidValue"
          data-cy="inputValue"
          type="number"
          min="0"
          class="rb-input currency-input"
          step="1"
          @input="inputChanged"
        />
      </div>
    </div>
    <div class="u-display-flex u-flex-direction-column">
      <rb-radio
        v-model="formData.field"
        native-value="matchTo"
      >
        Match bid to
      </rb-radio>
      <span
        class="u-font-size-6 u-spacing-mt-xs u-spacing-mb-s u-color-grey-x-light"
        >Match your bid to other metric</span
      >
      <div
        class="u-display-flex"
        :class="{
          'disabled u-pointer-events-none': formData.field !== 'matchTo'
        }"
      >
        <rb-select-v2
          class-name="bp-select"
          :is-multi-select="false"
          class="u-spacing-mr-s valueDropdown u-max-width-100"
          :send-details="true"
          :on-close="selectOption"
          :initial-options="Object.values(matchToOptions)"
          :options="Object.values(matchToOptions)"
          :note-generator-metric-tooltip="() => ''"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-ph-xs u-spacing-pv-xxs u-cursor-pointer"
          >
            <div
              class="u-display-flex u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-flex-align-items-center u-flex-justify-content-space-between customHeight"
            >
              {{ selectedMatchType.title }}
              <customTooltip
                :tippy="{
                  html: '#field-tooltip',
                  interactive: false,
                  placement: 'right',
                  trigger: 'mouseenter'
                }"
              >
                <div slot="toolTipData">
                  <div
                    class="tooltip_container u-width-400px u-display-flex u-flex-direction-column u-spacing-ph-s u-spacing-pv-s"
                  >
                    <span class="u-font-size-4">{{
                      selectedMatchType.title
                    }}</span>
                    <br />
                    <div class="u-font-weight-400 new-line">
                      {{ matchToOptions[selectedMatchType.value].tooltipText }}
                    </div>
                  </div>
                </div>
                <div
                  slot="body"
                  slot-scope="{ tippyConfig }"
                >
                  <div
                    :ref="'tippyRef'"
                    v-tippy="tippyConfig"
                    class="count-chip u-font-size-6"
                  >
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                      :icon="'help-fill'"
                    />
                  </div>
                </div>
              </customTooltip>
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
        </rb-select-v2>
        <div
          v-if="showMatchBidAdjustment"
          class="u-display-flex"
        >
          <rb-select
            class-name="bp-select"
            :is-multi-select="false"
            class="valueDropdown u-max-width-100 u-border-right-none"
            :send-details="true"
            :on-close="selectNumericActionType"
            :options="numericActionTypes"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-ph-s u-spacing-pv-xs u-cursor-pointer"
            >
              <div
                class="u-display-flex u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-flex-align-items-center u-flex-justify-content-space-between customHeight"
              >
                {{ selectedNumericActionType }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
          </rb-select>
          <div class="u-position-relative">
            <rb-input
              v-model.number="matchBidAdjustmentValue"
              class="u-border-light-grey"
              :placeholder="'%'"
              data-cy="inputValue"
              type="number"
              min="0"
              step="0.01"
              @input="inputChanged"
            />
            <span
              v-if="showError"
              class="u-position-absolute errorMessage u-flex-0 u-font-size-7 u-color-red-base"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small u-spacing-mr-xxs u-spacing-mt-xxs"
                :icon="'warning-triangle'"
              />
              Input should be between 0 and 100 inclusive</span
            >
          </div>
        </div>
        <span
          class="u-display-flex u-flex-align-items-center u-font-size-5 u-spacing-mt-xs u-spacing-mb-s u-cursor-pointer"
          :class="
            !showMatchBidAdjustment ? 'u-color-blue-base' : 'u-color-red-base'
          "
          @click="toggleMatchBidAdjustment"
        >
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-mh-s"
            :icon="!showMatchBidAdjustment ? 'plus' : 'cross'" />
          {{ showMatchBidAdjustment ? 'Remove' : 'Add' }}
          adjustment
          <rb-icon
            v-tippy
            :title="'Further increase/decrease bid by %'"
            class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-xx-light"
            :icon="'info-circle-fill'"
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import customTooltip from '@/components/widgets/customToolTip.vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import ConfigIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/config-icon.vue';
import bidCpcConfig from '@/components/pages/strategy_builder/steps/bidCpcConfig';

export default {
  name: 'BidCpc',
  components: {
    ConfigIcon,
    RbSelectV2,
    customTooltip
  },
  props: {
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        field: 'new',
        value: null
      },
      showMatchBidAdjustment: false,
      initialSetFromApi: true,
      selectedMatchType: bidCpcConfig.matchToOptions(
        this.$store.getters.getRetailer
      )[bidCpcConfig.defaultValues.matchTo.dynamicMetric],
      newBidValue: bidCpcConfig.defaultValues.newBid.value,
      matchBidAdjustmentValue: bidCpcConfig.defaultValues.matchTo.value
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    selectedNumericActionType() {
      return bidCpcConfig.numericActionTypes.find(
        (option) => option.value === this.formData.numericActionType
      )?.title;
    },
    showError() {
      return (
        !Number.isNaN(this.formData.value) &&
        (this.formData.value > 100 || this.formData.value < 0)
      );
    }
  },
  watch: {
    'initialFormData.dynamicMetric': {
      handler: function (val) {
        if (val) {
          this.formData.field = 'matchTo';
        }
      }
    },
    'formData.field': {
      handler: function (val) {
        const keys = [
          'numericActionType',
          'numericActionChangeType',
          'setNewValue',
          'dynamicAllocation',
          'dynamicMetric',
          'value'
        ];
        const formData = {};
        for (let key of keys) {
          formData[key] = this.initialSetFromApi
            ? this.initialFormData?.[key] ??
              bidCpcConfig.defaultValues[val][key]
            : bidCpcConfig.defaultValues[val][key];
          if (key === 'value') {
            formData[key] = Number(formData[key]) || null;
          }
        }
        Vue.set(this, 'formData', { ...this.formData, ...formData });
        if (this.initialSetFromApi) {
          if (this.formData.field === 'newBid') {
            this.newBidValue = formData.value;
          } else {
            this.matchBidAdjustmentValue = formData.value;
            this.selectedMatchType =
              this.matchToOptions[formData.dynamicMetric];
          }
        } else {
          this.newBidValue = this.formData.value;
          this.matchBidAdjustmentValue = this.formData.value;
          this.selectedMatchType =
            this.matchToOptions[
              bidCpcConfig.defaultValues.matchTo.dynamicMetric
            ];
        }
        this.initialSetFromApi = false;
        this.showMatchBidAdjustment =
          this.formData.dynamicMetric && this.formData.value;
        this.$emit('updateFormData', this.formData);
      }
    }
  },
  created() {
    this.initializeConstants();
    if (this.initialFormData.dynamicMetric) {
      this.formData.field = 'matchTo';
    } else {
      this.formData.field = 'newBid';
    }
  },
  methods: {
    initializeConstants() {
      this.currency = Vue.prototype.$currency;
      this.numericActionTypes = bidCpcConfig.numericActionTypes;
      this.matchToOptions = bidCpcConfig.matchToOptions(this.retailer);
    },
    selectOption(context, option) {
      if (option?.[0]?.value) {
        this.formData.dynamicMetric = option[0].value;
        this.selectedMatchType = option[0];
        this.$emit('updateFormData', this.formData);
      }
    },
    toggleMatchBidAdjustment() {
      this.showMatchBidAdjustment = !this.showMatchBidAdjustment;
      if (!this.showMatchBidAdjustment) {
        this.matchBidAdjustmentValue = null;
        this.formData.value = null;
        this.formData.numericActionType = 'Set';
        this.formData.numericActionChangeType = 'Absolute';
        this.formData.setNewValue = true;
      } else {
        this.formData.numericActionType = 'Increase';
        this.formData.numericActionChangeType = 'Percent';
        this.formData.setNewValue = false;
      }
      this.$emit('updateFormData', this.formData);
    },
    selectNumericActionType(context, option) {
      if (option?.[0]?.value) {
        this.formData.numericActionType = option[0].value;
        this.$emit('updateFormData', this.formData);
      }
    },
    inputChanged() {
      this.formData.value =
        this.formData.field === 'newBid'
          ? this.newBidValue
          : this.matchBidAdjustmentValue;
      this.$emit('updateFormData', this.formData);
    }
  }
};
</script>

<style lang="css" scoped>
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
  height: 3.6rem;
}
.appender .u-width-400px {
  width: 40rem;
}

.customHeight {
  padding: 0.6rem 0.1rem;
}
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
.valueDropdown.u-border-right-none {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rb-icon.plus {
  width: 1.2rem;
  height: 1.2rem;
}
.rb-icon.rb-icon--medium.icon-plus:before {
  margin-top: -0.3rem;
  font-size: 2.1rem;
}

.u-border-light-grey {
  border-left-color: #e9eaeb;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.errorMessage {
  margin-top: 0.2rem;
}
.new-line {
  white-space: pre-line;
}
</style>
