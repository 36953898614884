<template>
  <source-of-truth
    :entity-type="entityType"
    :path="path"
    :bulk-change-warning-text="bulkChangeWarningText"
    :text-configs="textConfigs"
    :column-defs="columnDefs"
    :api-config="apiConfig"
  />
</template>
<script>
import SourceOfTruth from '@/pages/toolsAndSettings/entityTagging/common/sourceOfTruth/index.vue';
import widgetConfig from './widgets';

export default {
  components: {
    SourceOfTruth
  },
  data() {
    const widgetConfigInstance = widgetConfig.config();
    return {
      entityType: {
        file: null,
        sheetName: 'Variant Change',
        entity: 'variants',
        skipFormatting: false,
        skipPostTrigger: true
      },
      path: 'variant-change',
      bulkChangeWarningText:
        'Uploading a new excel sheet will overwrite all the existing source of truth for variant.',
      columnDefs:
        widgetConfigInstance.widgets.contentChangeColumns.columnDefs || [],
      apiConfig: widgetConfigInstance.widgets.APIConfig
    };
  },
  computed: {
    textConfigs() {
      return {
        bulkUpload: {
          sectionTitle: 'Update your Source of Truth for Variant',
          sectionDesc:
            'Use an existing template or update the available template to populate your Source of Truth for Variant.',
          backButtonText: 'Back',
          downloadTemplateText: 'Download Source of Truth for Variant Template',
          entityName: 'Source of Truth for Variant',
          pageTitle: 'Manage Source of Truth for Variant',
          pageDesc: 'Preview desc',
          pageDescription: 'Preview and create Source of Truth for Variant',
          statusText:
            'Please fix these errors to succesfully <span class="u-font-weight-600"> Overwrite Source of Truth for Variant </span> with the above file.'
        },
        welcome: {
          ButtonText: 'Create Source of Truth for Variant',
          Description:
            'Looks like you haven’t created Source of Truth for Variant yet!'
        },
        page: {
          Title: 'Manage Source of Truth for Variant',
          Description: 'Preview and create Source of Truth for Variant'
        },
        success: {
          text: 'Source of Truth for Variant is created succesfully!'
        },
        bulkUploadInfoSection: {
          containerClass:
            'u-width-30 u-border-radius-s u-border-color-grey-xxx-light',
          header:
            '<span class="u-font-weight-600">Are you using your own template to upload Source of Truth for Variant ? Do ensure the below conditions are met:</span>',
          tips: [
            'There should be a column named ASINs containing the list of ASINs.',
            'All columns should have header names. Header names are case sensitive so use "ASIN" and "Variation Group" as the header names.',
            'To update Source of Truth values of few ASINs you can either download the existing excel sheet and edit the values and re-upload to overwrite.',
            'To add a few new ASINs to the Source of Truth, you can download the existing excel sheet and add the new ASINs to the excel sheet and re-upload to overwrite.'
          ],
          footer:
            'You may download a new <span id="sot-info-download-trigger" class="u-color-blue-base u-cursor-pointer" style="text-decoration: underline"> Source of Truth for Variant Template </span> if you do not have an existing template.'
        }
      };
    }
  }
};
</script>
