<template>
  <div class="u-cursor-pointer u-display-flex u-flex-align-items-center">
    <span
      v-tippy="{ placement: 'top', arrow: false }"
      class="u-cursor-pointer"
      :title="tooltipText"
      @click="downloadReportTrigger"
    >
      <rb-icon
        v-if="!downloadLoading"
        class="rb-icon u-color-grey-lighter icon-download"
        :class="{
          'u-color-red-base': downloadError,
          'u-color-grey-lighter': !downloadError
        }"
        :icon="'download'"
      />
    </span>
    <loader
      :loading="downloadLoading"
      class=""
      :color="'#007cf6'"
    />
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
export default {
  components: {
    loader
  },
  props: {
    isDownloadLoading: {
      type: Boolean,
      default: false
    },
    isDownloadError: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadLoading: false,
      downloadError: false
    };
  },
  watch: {
    isDownloadLoading(newVal) {
      this.downloadLoading = newVal;
    },
    isDownloadError(newVal) {
      this.downloadError = newVal;
    }
  },
  methods: {
    downloadReportTrigger() {
      this.$emit('download-report-trigger');
    },
    downloadTheFile() {}
  }
};
</script>
