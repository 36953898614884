export function transformMetaData(value, type) {
  const viewMetricMapping = {};
  const config = Object.keys(value).map((key) => {
    const metric = value[key];
    metric.title = metric.label;
    metric.key = metric.label;
    // Extract views from the views object.
    // Selected Metric to View Mapping
    metric.views.forEach((item) => {
      if (viewMetricMapping[item.label]) {
        if (
          item.selected ||
          viewMetricMapping[item.label].selectedMetric.length < 3
        ) {
          viewMetricMapping[item.label].selectedMetric.push(metric);
        }
      } else {
        viewMetricMapping[item.label] = {};
        viewMetricMapping[item.label].selectedMetric = [];
      }
    });
    return metric;
  });
  return { metricList: config || [], views: viewMetricMapping || {} };
}

export function mergeResponseForSKUDetails(value) {
  if (value) {
    return value.reduce((newItem, item) => {
      newItem[item.name] = item.RESULT?.[item.name];
      return newItem;
    }, {});
  } else {
    return {};
  }
}

export function downloadFile(filePath) {
  let fileName = filePath.split('/');
  fileName = fileName[fileName.length - 1];
  const anchor = document.createElement('a');
  anchor.href = filePath;
  anchor.setAttribute('download', fileName);
  anchor.click();
}

// number less than 0.1 will return two decimal digit values e.g 0.026 => 0.03
export function convertDecimalLessThanZero(floatNumber) {
  if (!floatNumber) return floatNumber;
  if (floatNumber <= 0.1 && floatNumber >= 0) {
    return parseFloat(floatNumber.toFixed(2));
  }
  return floatNumber;
}
