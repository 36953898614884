<template>
  <div
    v-if="showBanner"
    class="banner sb-banner u-font-size-6 u-spacing-pv-s u-display-flex u-flex-align-items-center"
  >
    <rb-icon
      class="rb-icon--medium u-color-orange-base u-spacing-mr-s"
      :icon="'warning'"
    />
    Attention : The
    <span
      v-tippy
      :title="metrics.join(', ')"
      class="u-text-decoration-underline u-font-weight-600 u-spacing-ph-xs"
      >advertisement metrics</span
    >
    includes data from SB Campaigns since April 6th, 2022.
  </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
export default {
  name: 'SbBanner',
  props: {
    dates: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      metrics: [
        'Spend',
        'Paid sales 14 Days',
        'ROAS',
        'ACoS 14 days',
        'Cost Per Click',
        'Impressions',
        'Clicks',
        'Click through rate',
        'Orders 14 days',
        'Units 14 days',
        'Conversions 14 days',
        'Total Ad Sales'
      ]
    };
  },
  computed: {
    showBanner() {
      let bReturn = false;
      if (
        moment('2022-04-06').isBetween(
          this.dates.from,
          this.dates.to,
          'days',
          '[]'
        ) ||
        moment('2022-04-06').isBetween(
          this.dates.compare_from,
          this.dates.compare_to,
          'days',
          '[]'
        )
      ) {
        bReturn = true;
      }
      const isAMSLiteClient = Vue.options.filters.config_check(
        'feature.product.ams_lite'
      );
      let bIsAdvClient = false;
      if (
        this.$store.getters.getRetailer === 'amazon' &&
        this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1
      ) {
        bIsAdvClient = true;
      }
      return bReturn && bIsAdvClient && !isAMSLiteClient;
    }
  }
};
</script>
<style lang="css">
.sb-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
</style>
