<template>
  <div class="u-position-relative u-display-flex">
    <loader
      v-if="load"
      class="fill--parent"
      :color="'#3fadf7'"
    />
    <div class="u-display-flex u-flex-direction-column">
      <div>
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
          data-cy="selectedFormat"
          >{{
            selectedAdFormat !== 'video' ? 'Brand Name and logo:' : 'Video'
          }}</span
        >
        <div
          class="u-spacing-mt-m u-display-flex u-width-560px u-flex-align-items-flex-end"
        >
          <div
            v-if="selectedAdFormat !== 'video'"
            class="u-width-20 u-spacing-mr-m u-flex-align-self-center"
          >
            <div
              class="u-border-all u-border-width-s u-border-color-grey-xxx-light u-spacing-p-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center"
            >
              <img
                class="brand-name-logo"
                :src="(selectedAsset && selectedAsset.url) || ''"
                alt="no budget image"
                data-cy="brandNameLogo"
              />
            </div>
          </div>
          <div
            v-else
            class="u-spacing-mr-m"
          >
            <video
              :key="(selectedAsset && selectedAsset.url) || ''"
              width="320"
              height="240"
              controls
            >
              <source
                :src="((selectedAsset && selectedAsset.url) || '') + '#t=2'"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="u-spacing-pl-s u-font-size-7 u-color-blue-base">
            <!--  <div class="u-spacing-pb-l">
              <input
                id="fileElm"
                ref="fileElm"
                class=" u-display-none"
                type="file"
                :multiple="false"
                @change="processFile"
              />
              <span        
              class=" u-cursor-pointer" @click="chooseFile"
                >Upload logo</span
              >
            </div> -->
            <rb-select
              :send-details="true"
              :on-close="(val, data) => selectAsset(data)"
              :options="(profileAssets && profileAssets.rows) || []"
              class="u-max-width-400px"
            >
              <div
                slot="trigger"
                class="u-display-flex u-font-size-7 u-color-blue-base u-cursor-pointer u-cursor-pointer"
                data-cy="selectCreativetxt"
              >
                Select from creative asset
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-spacing-p-m select-asset u-display-flex u-flex-align-items-center u-width-100"
                >
                  <div class="u-font-size-2">
                    <img
                      v-if="selectedAdFormat !== 'video'"
                      class="brand-name-logo"
                      :src="(option && option.url) || ''"
                      data-cy="creativeLogo"
                    />
                    <video
                      v-else
                      :key="(option && option.url) || ''"
                      width="160"
                      height="120"
                      controls
                    >
                      <source
                        :src="((option && option.url) || '') + '#t=2'"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    class="u-font-size-5 u-spacing-pl-s u-width-100 u-font-weight-400 u-text-overflow-ellipsis"
                    :title="option && option.name"
                    data-cy="creatviveTitle"
                  >
                    <span data-cy="creativeOption">{{
                      option && option.name
                    }}</span>
                    <br />
                    <span
                      v-if="selectedAdFormat !== 'video'"
                      class="u-color-grey-lighter u-font-size-7 asset-px"
                      data-cy="creativeSize"
                      >{{ option && option.height }} X
                      {{ option && option.width }} px
                    </span>
                  </div>
                </div>
              </template>
            </rb-select>
          </div>
        </div>
      </div>
      <div
        v-if="selectedAdFormat !== 'video'"
        class="u-spacing-pt-l u-display-flex u-flex-direction-column u-width-100"
      >
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
          data-cy="brandnametxt"
          >Brand Name:</span
        >
        <rb-input
          v-model="brandName"
          placeholder="Enter name"
          class="u-width-100 u-spacing-mt-s"
          data-cy="selectBrand"
          @input="selectBrandInfo({ brandName, headline })"
        />
        <div class="u-display-flex u-spacing-mt-xs">
          <div class="u-color-red-base u-font-size-8 u-flex-1">
            <span v-if="brandErrorMessage">{{ brandErrorMessage }}</span>
          </div>
          <div class="u-flex-align-self-flex-end">
            <span
              class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
              data-cy="charLimit30Txt"
              >Max of 30 characters</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="selectedAdFormat !== 'video'"
        class="u-spacing-mt-l"
      >
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
          daat-cy="headlinetxt"
          >Headline:</span
        >
        <div
          class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-s"
        >
          <rb-input
            v-model="headline"
            placeholder="Enter name"
            class="u-width-100"
            @input="selectBrandInfo({ brandName, headline })"
          />
          <div class="u-display-flex u-spacing-mt-xs">
            <div class="u-color-red-base u-font-size-8 u-flex-1">
              <span
                v-if="headlineErrorMessage"
                data-cy="headlineErrorMessage"
                >{{ headlineErrorMessage }}</span
              >
            </div>
            <div class="u-flex-align-self-flex-end">
              <span
                class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                data-cy="charLimit50Txt"
                >Max of 50 characters</span
              >
            </div>
          </div>
        </div>
        <div
          v-if="
            selectedAdFormat === 'productCollection' ||
            selectedAdFormat === 'video'
          "
        >
          <list
            :key="skuKey"
            :product-list="creativeSkus"
            :all-skus="skus"
            @skuPositionChanged="skuPositionChanged"
            @updateSkusItem="updateSkusItem"
          />
        </div>
        <div v-if="selectedAdFormat === 'storeSpotlight'">
          <pageList
            :selected-values="selectedValues"
            :all-steps-data="allStepsData"
            :suggested-campaign-index="suggestedCampaignIndex"
          />
        </div>
      </div>
      <!-- <div class="u-flex-1 u-spacing-mh-l u-spacing-mv-l">
      <div class="u-width-100 u-height-100 u-border-radius-s u-display-flex u-flex-direction-column">
        <div class="u-flex-1 u-bg-color-grey-xxx-light u-display-flex u-flex-justify-content-center u-flex-align-items-center">
          <span> <rb-icon
            class="rb-icon--small u-spacing-mr-xl"
            :icon="'angled-arrow-left'"
          /></span>
          <span class="u-font-size-5 u-font-weight-600 u-color-grey-base">Ad preview - Top of search mobile ad</span>
          <span> <rb-icon
            class="rb-icon--small u-spacing-ml-xl"
            :icon="'angled-arrow-right'"
          /></span>
        </div>
        <div class="u-flex-9 u-bg-color-grey-x-light" />
      </div>
    </div> -->
    </div>
  </div>
</template>

<script>
import list from './campaignCreativeList.vue';
import loader from '@/components/basic/loader';
import pageList from '@/components/ams/campaign_creation/steps/edit/campaignCreative/pageList.vue';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
export default {
  components: {
    list,
    loader,
    pageList
  },
  props: {
    load: {
      default: false,
      type: Boolean
    },
    profileAssets: {
      default: () => {},
      type: Object
    },
    suggestedCampaignIndex: {
      type: Number,
      default: 0
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    skus: {
      type: Array,
      default: () => []
    },
    allStepsData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    creativeSkus: [],
    selectedAdFormat: null,
    brandName: null,
    brandErrorMessage: '',
    headline: null,
    headlineErrorMessage: '',
    selectedAsset: null,
    skuKey: 0
  }),
  watch: {
    skus: {
      handler() {
        this.selectSkus();
      },
      deep: true,
      immediate: true
    },
    profileAssets: {
      handler() {
        this.selectAsset();
      },
      deep: true,
      immediate: true
    },
    brandName(data) {
      data.length > 30
        ? (this.brandErrorMessage =
            'Brand name should not exceed 30 characters')
        : (this.brandErrorMessage = '');
    },
    headline(data) {
      data.length > 50
        ? (this.headlineErrorMessage =
            'Headline should not exceed 50 characters')
        : (this.headlineErrorMessage = '');
    }
  },
  created() {
    this.selectedAdFormat =
      this.allStepsData.suggestedCampaigns[
        this.suggestedCampaignIndex
      ]?.adFormat;
    this.selectAsset();
    this.selectSkus();
    this.selectBrandInfo();
  },
  methods: {
    skuPositionChanged(newSkus) {
      this.selectSkus(cloneDeep(newSkus));
    },
    updateSkusItem(sku, i) {
      const allStepSkus =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.creativeSkus;
      allStepSkus[i] = sku;
      this.selectSkus(cloneDeep(allStepSkus));
    },
    chooseFile() {
      this.$refs.fileElm.click();
    },
    processFile(e) {
      const file = e.target?.files?.[0];
      const formData = new FormData();
      formData.append('file', file);
      HttpService.post('UPLOAD_AMS_SB_ASSETS', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': file?.name
        }
      });
    },
    selectSkus(item) {
      const allStepSkus =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.creativeSkus;
      const creativeSkus =
        item ||
        (allStepSkus?.length > 2 && allStepSkus) ||
        cloneDeep(this.skus?.slice(0, 3));
      this.skuKey++;
      this.creativeSkus = creativeSkus;
      this.updateSelectedStore({ creativeSkus });
    },
    selectAsset(item) {
      const selectedAsset =
        item?.[0] ||
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.selectedAsset ||
        this.profileAssets?.rows?.[0];
      this.selectedAsset = selectedAsset;
      this.updateSelectedStore({ selectedAsset });
    },
    selectBrandInfo(item) {
      const currentInfo = {
        brandName: this.brandName,
        headline: this.headline
      };
      const storedInfo =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          ?.brandInfo;
      const brandInfo = item || storedInfo || currentInfo;
      this.brandName =
        item?.brandName || storedInfo?.brandName || this.brandName;
      this.headline = item?.headline || storedInfo?.headline || this.headline;
      this.updateSelectedStore({ brandInfo: brandInfo });
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    }
  }
};
</script>

<style lang="css" scoped>
.select-asset:hover {
  background: #007cf6;
  cursor: pointer;
}
.select-asset:hover .asset-px {
  color: #fff;
}
.brand-name-logo {
  height: 86px;
  width: 105px;
}
</style>
