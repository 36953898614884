import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import { filters } from '@ciq-dev/ciq-ui';
const {
  map,
  units,
  axes,
  baseMeasuresList,
  getTagData,
  createBulkSearchObjectForWidget,
  getColumnDefinition,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('amazon', 'search');

export default {
  config: (that) => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) => getColumnDefinition(...args, 'search'),
      filters: {
        emit: 'workbenchSearchTermsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'workbenchSearchTermsSearchTriggered',
          placeholder: 'Search for a Search Term'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'searchTermsChart',
            axisFormatMap: units,
            action: 'getWorkbenchSearchTermsChartData',
            listen: {
              workbenchSearchTermsFilterApplied: {
                action: 'getWorkbenchSearchTermsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              workbenchSearchTermsPlotSelectedRows: {
                action: 'getWorkbenchSearchTermsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data && data.length > 0) {
                    for (const element of data) {
                      config.meta.plotSelections.push({
                        dimensionName: 'search_text_id',
                        operator: 'ILIKE',
                        dimensionValue: element.search_text_id
                      });
                    }
                  }
                  return config;
                }
              },
              workbenchSearchTermsSearchTriggered: {
                action: 'getWorkbenchSearchTermsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }

                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchSearchTermsChartData',
                'chart',
                'search'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchSearchTermsChartData',
                'chart',
                'campaign',
                'SearchTerms'
              ),
              workbenchSearchTermsPaginationTriggered: {
                action: 'getWorkbenchSearchTermsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_search_term'
          },
          body: {
            APIConfig: {
              cubeName: 'ams_search_term_campaign_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesDimension: 'report_date',
              timeseriesRollupBy: 'WEEK',
              daterangeDimension: 'report_date',
              timeseriesEnabled: true,
              pvpenabled: true,
              yoyenabled: false,
              customAPIDecisionVars: {
                enableShareOfVoice: false,
                skipLimitAndOffset: false,
                additionalWhereClause: false,
                enableForecastSkuLevelWidget: false,
                enableFetchFromShards: false,
                skipExternalCount: false,
                skipTimeSeriesMetric: false,
                enableDigitalShelf: false,
                totalCompBrandsForDigitalShelf: 0,
                totalOthersBrandsForDigitalShelf: 0,
                totalClientBrandsForDigitalShelf: 0,
                anchoredBrand: null,
                preQueriesMap: {},
                ignoreMeasuresWhileDeduping: {},
                type: null,
                downloadRequest: null,
                contentScorecardRequest: null,
                queryReplacementRequest: null,
                system: null,
                enableChartsSku: false,
                timeSeriesDimensionAggregateRequest: null,
                jspEnabled: false,
                sovDataGroup: false,
                timeSeriesDimensionEnabled: false,
                chainingJoinEnabled: false
              },
              measuresList: [
                ...baseMeasuresList,
                'total_sales',
                'organic_sales',
                'pcogs',
                'inventory',
                'avg_selling_price',
                'customer_orders',
                'search_frequency_rank'
              ],
              groupByDimensionsList: [
                'search_term',
                'keyword_text',
                'campaign_name',
                'match_type_keyword',
                'keyword_id'
              ],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ]
            },
            getTagUnitData(data) {
              let oReturn = {};
              let result = (data[0] || {}).RESULT;
              for (let i in result) {
                let translatedI = (map || {})[i] ? (map || {})[i] : i;
                oReturn[translatedI] = {
                  invertTag2: commons.isInverted(i),
                  tag1Unit: units[translatedI],
                  tag2Unit: {
                    suff: '%'
                  }
                };
              }
              return oReturn;
            },
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                show_axis_colors: true,
                axes: axes,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy'
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'searchTermsTable',
            primaryKey: 'worklogger_primary_key',
            action: 'getWorkbenchSearchTermsTableData',
            listen: {
              workbenchSearchTermsFilterApplied: {
                action: 'getWorkbenchSearchTermsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              workbenchSearchTermsSearchTriggered: {
                action: 'getWorkbenchSearchTermsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }

                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchSearchTermsTableData',
                'table',
                'search'
              ),
              ...createBulkSearchObjectForWidget(
                'getWorkbenchSearchTermsTableData',
                'table',
                'campaign',
                'SearchTerms'
              )
            },
            paginationAction: 'workbenchSearchTermsPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_search_term'
          },
          body: {
            APIConfig: {
              cubeName: 'ams_search_term_campaign_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesDimension: 'report_date',
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [],
              groupByDimensionsList: [],
              dimensionList: [],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              limit: 30,
              page: 1,
              customAPIDecisionVars: {
                type: null
              },
              tagWhereClause: [],
              entityType: 'search_term',
              primaryDataGroup: 'adv_metrics',
              bundleCubeExecutionRequest: {
                adv_metrics: {
                  cubeName: 'ams_search_term_campaign_workbench',
                  entityType: 'search_term',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesDimension: 'report_date',
                  timeseriesEnabled: false,
                  pvpenabled:
                    filters.config_check('feature.pages.enableRMMAmazonPVP') ||
                    false,
                  yoyenabled: false,
                  measuresList: [
                    ...baseMeasuresList,
                    'total_sales',
                    'organic_sales',
                    'count',
                    'keyword_volume',
                    'bid',
                    'status',
                    'keyword_type',
                    'campaign_type',
                    'search_text_id',
                    'adgroup_id',
                    'campaign_id',
                    'is_search_term_present_as_keyword_in_campaign',
                    'adgroup_name',
                    'search_frequency_rank',
                    'creative_type'
                  ],
                  groupByDimensionsList: [
                    'search_term',
                    'keyword_text',
                    'campaign_name',
                    'match_type_keyword',
                    'keyword_id',
                    'profile_id',
                    'worklogger_primary_key',
                    'portfolio_id',
                    'portfolio_name',
                    'profile_name'
                  ],
                  dimensionList: [],
                  customAPIDecisionVars: {
                    type: null
                  },
                  orderByList: [],
                  where: {
                    dimensionNameValueList: [],
                    date: {
                      from: '2023-05-05',
                      to: '2023-06-03',
                      name: 'last30Days',
                      page_wise_min_max_key: 'ams_search_term'
                    },
                    pvpDate: {
                      from: '2023-04-05',
                      to: '2023-05-04',
                      compare_name: null
                    }
                  },
                  tagWhereClause: []
                },
                sov_metrics: {
                  cubeName: 'ams_search_term_campaign_workbench',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesDimension: 'report_date',
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'share_of_organic_search',
                    'share_of_sponsered_search'
                  ],
                  groupByDimensionsList: ['search_term'],
                  dimensionList: [],
                  customAPIDecisionVars: {
                    type: null
                  },
                  orderByList: [],
                  dedupBeforeRollup: {
                    enableDedupBeforeRollup: true,
                    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                      ['report_date']
                  },
                  tagWhereClause: []
                }
              }
            },
            download: {
              fileName: 'SearchTermTableData',
              columnMap: getColumnDefinition(that, 'search'),
              action: 'downloadSearchTermsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'search'),
            rowHeight: 70
          }
        }
      }
    };
  }
};
