<!--
Similar to iconTableCell but it doesn't expect values in params for rendering and relies on props
and thus compatible with dynamicCellComponent
-->
<template>
  <div class="u-width-100">
    <div
      class="u-width-100 u-text-align-center"
      @click="iconClickEvent($event)"
    >
      <rb-icon
        v-tippy="tippy"
        :title="toolTipText"
        class="u-spacing-mh-s tableCell-icon"
        :class="[iconClass, extraClass]"
        :icon="icon"
        data-cy="ActionalbleIcon"
      />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    displayIcon: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: 'Activity Log'
    },
    alternateIcon: {
      type: String,
      default: ''
    },
    alternateIconClass: {
      type: String,
      default: ''
    },
    toolTipText: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: ''
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    icon() {
      let icon = '';
      if (this.displayIcon) {
        if (this.alternateIcon && this.params?.data?.isRowSelected) {
          icon = this.alternateIcon;
        } else {
          icon = this.displayIcon;
        }
      }
      return icon;
    },
    passCurrentThis() {
      return this;
    },
    labelValue() {
      return this.params?.data?.[this.params?.colDef?.field];
    },
    iconClass() {
      let iconColorClass = 'u-color-grey-lighter';
      if (
        this.params?.data?.isRowSelected &&
        this.alternateIcon &&
        this.alternateIconClass
      ) {
        iconColorClass = this.alternateIconClass;
      }
      if (this.iconSize) {
        return 'rb-icon--' + this.iconSize + ' ' + iconColorClass;
      } else {
        return 'rb-icon--small ' + iconColorClass;
      }
    }
  },
  created() {
    if (this.params.contextReturnEvent !== undefined) {
      this.params.contextReturnEvent(this);
    }
    if (this.params.toolTipPosition !== undefined) {
      this.updateTippyPosition();
    }

    this.currentDataFilter();
  },
  methods: {
    updateTippyPosition() {
      this.tippy.placement = this.params.toolTipPosition;
    },
    iconClickEvent(event) {
      this.$emit('iconClickEvent', this.params);
    },
    currentDataFilter() {
      if (this.params.type === 'iconText') {
        var formatType = this.params?.colDef?.cellRendererParams?.formatType;
        this.dataFormatType = formatType;
      }
    }
  }
};
</script>
