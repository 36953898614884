import HomeV2 from '@/components/homeV2.vue';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import {
  campaignOptimizerChildrenRoutes,
  getCampaignManagementChildrenRoutesWithTabConfig,
  proxyLinksRoute
} from '@/router/commonRoutePaths.js';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const targetcitrusRoutes = {
  path: '/us/target_citrus/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect: 'digital_shelf/market_insights'
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'digital_shelf',
      name: 'digital_shelf',
      redirect() {
        return 'digital_shelf/market_insights';
      },
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'advertising',
      name: 'advertising',
      redirect() {
        return 'advertising/campaign_optimizer';
      },
      component: AdvertisingLanding,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutesWithTabConfig('target')
        },
        {
          path: '',
          redirect: () => {
            return 'campaign_management';
          }
        }
      ]
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    {
      path: 'file-download',
      component: fileDownload
    },
    {
      path: 'ciq-efundamentals-announcement',
      name: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    proxyLinksRoute
  ]
};

export { targetcitrusRoutes };
