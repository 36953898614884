<template>
  <div
    class="condition_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <Strategy-Header :step="_step" />
    <div
      v-if="infoBoxAboveFilter"
      class="u-spacing-mv-s"
    >
      <div
        class="u-font-size-5 u-color-blue-base u-border-radius-s u-spacing-pl-m u-width-100 u-display-flex u-flex-align-items-center u-scope-filter_wrp"
        style="min-height: 60px"
      >
        <rb-icon
          class="u-color-orange-base rb-icon--large u-spacing-mr-s"
          icon="info-circle-fill"
        />
        <p>{{ infoBoxAboveFilterContent }}</p>
      </div>
    </div>
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <!-- filter - condition filter -->
      <div
        class="u-font-size-5 pageBackground u-border-radius-s u-spacing-pl-m u-width-100 u-display-flex u-flex-align-items-center u-margin-bottom-40 u-condition-filter_wrp"
        style="min-height: 63px"
      >
        <rb-filter-panel
          :enable-save="false"
          :data="filterDataCondition"
          :primary="primaryFilterDataCondition"
          :add-filter-disabled-fn="addFilterDisabledFn"
          :add-filter-disabled-tooltip-fn="addFilterDisabledTooltipFn"
          :secondary-data="{}"
          :filter-for-operators="step.retailer === 'instacart'"
          :has-search="false"
          :loader-status="selectedValues.conditionStepLoaderStatus"
          :enable-remove-all="false"
          :on-create="onCreateFilter"
          :emit="'conditionFilterChangeEvent'"
          :use-local-storage="false"
          :listener-event="'strategy-condition'"
          :show-notification="false"
          :default-filters="defaultFilters"
          :operators-to-filter-out="['IS_NULL', 'IS_NOT_NULL']"
        />
      </div>
      <div class="">
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-m">
          Using data from:
        </div>
        <rb-list-select
          v-model="selectedValues.lookBackWindow"
          :size="'small'"
          :options="
            ((metaDataConfig[step.keyToRead] &&
              metaDataConfig[step.keyToRead].timeWindows) ||
              []) | filterTimeWindow(selectedValues)
          "
          :display-key-to-read="'name'"
        />
      </div>
    </div>
    <section class="u-margin-top-80 u-display-flex u-margin-bottom-80">
      <rb-button
        id="confirmButton"
        :text="'Back'"
        :type="'hollow'"
        :click-fn="movePrev.bind(this)"
        class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s"
      />
      <rb-button
        id="confirmButton"
        :text="'Next'"
        :disabled="disabledButton"
        :type="'filled'"
        :click-fn="moveNext.bind(this)"
      />
    </section>
  </div>
</template>
<script>
import StrategyHeader from './header';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';
import rbListSelect from '@/components/widgets/rbListSelect';
import rbFilterPanel from '@/components/widgets/filter-panel';
import { eventBus } from '@/utils/services/eventBus';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import Vue from 'vue';
import {
  conditionStepMultiSelectFilters,
  conditionStepBlackListedDimensionTypes
} from '@/components/pages/strategy_builder/steps/amazonStepsConstants';
import { cloneDeep } from 'lodash';

export default {
  name: 'ConditonStep',
  components: {
    StrategyHeader,
    rbListSelect,
    rbFilterPanel
  },
  filters: {
    // TODO: Custom Changes - Not to be done in FE. Doing it in a Hacky way.
    filterTimeWindow: function (value, selectedValues) {
      if (!value) return [];
      const entityType =
        (selectedValues.strategyEntityType &&
          selectedValues.strategyEntityType.entityType) ||
        '';
      let tempFilter = [];
      const retailer = selectedValues.retailer;
      const conditionOptionsConfig =
        multiRetailerConfig?.conditionStep?.[retailer] ||
        multiRetailerConfig?.conditionStep?.default;
      if (value.length > 0) {
        tempFilter = value.filter((item) => {
          const isActiveForCurrentEntity = conditionOptionsConfig.find(
            (option) =>
              option.name === item.name && option.activeFor.includes(entityType)
          );
          const isConditionalOption = conditionOptionsConfig.find(
            (option) => option.name === item.name
          );
          if (isActiveForCurrentEntity) {
            return item;
          } else if (!isConditionalOption) {
            return item;
          } else {
            return false;
          }
        });
      }
      return tempFilter;
    }
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      filterKey: 0
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    _step() {
      const { header } = this.step;
      if (header && header.title) {
        header.title = this.entityTitleFilter(
          header.placeholder || '',
          (this.selectedValues.strategyEntityType &&
            this.selectedValues.strategyEntityType.entityName) ||
            ''
        );
      }
      this.step.header = { ...header };
      return { ...this.step };
    },
    filterDataCondition() {
      return this.$store.getters.getFilterData || [];
    },
    primaryFilterDataCondition() {
      const { metrics = {} } = this.metaDataConfig.conditions || {};
      const { entityType = {} } = this.selectedValues.strategyEntityType || {};
      let tempArr = [];

      if (metrics[entityType]) {
        tempArr = cloneDeep(this.$store.getters.getPrimaryFilterData).filter(
          (filterObj) => {
            const dimensionName = filterObj.dimensionName;
            const dimensionType = filterObj.dimensionType;

            if (
              !conditionStepBlackListedDimensionTypes.includes(dimensionType) &&
              !metrics[entityType].disallowedMetrics.includes(dimensionName)
            ) {
              filterObj.multi = false;
              return filterObj;
            }
          }
        );
      }
      return tempArr;
    },
    disabledButton() {
      let isDisable = true;
      // TODO: Don't remove the following line. It's retrigger the validation when the filter values changes. also in the edit case, need to trigger the validation when the filter data returned from API.
      if (this.selectedValues.conditionFilterValues) {
      }
      if (this.primaryFilterDataCondition) {
      }

      if (
        this.selectedValues.lookBackWindow &&
        this.selectedValues.lookBackWindow.name &&
        this.validateFilter()
      ) {
        isDisable = false;
      } else {
        isDisable = true;
      }
      this.$parent.$emit('stepsGlobalEvent', {
        type: 'ValidationBtn',
        values: { step: this.step.textMapping, valid: !isDisable }
      });
      return isDisable;
    },
    defaultFilters() {
      if (this.selectedValues.rules) {
        // TODO: We are using in this way because, when filters are set using computed property, it's not emiting the filter values once it's set.
        const obj = this.transformFilterFromAPI(this.selectedValues.rules);
        return { order: Object.keys(obj) || [], values: obj };
      } else {
        return { order: [], values: {} };
      }
    },
    multiRetailerConfigConditionStepFilterDisable() {
      const retailer = this.retailer;
      return multiRetailerConfig.conditionStepFilterDisable[retailer];
    },
    infoBoxAboveFilter() {
      return (
        this.selectedValues.strategyEntityType?.entityType === 'KEYWORD' &&
        multiRetailerConfig?.infoBoxConfig?.[this.retailer]?.show
      );
    },
    infoBoxAboveFilterContent() {
      return multiRetailerConfig?.infoBoxConfig?.[this.retailer]?.[
        this.selectedValues.strategyEntityType?.entityType
      ];
    }
  },
  created() {
    this.selectedValues.retailer = this.step.retailer;
  },
  mounted() {
    const that = this;
    eventBus.$on('conditionFilterChangeEvent', (params) => {
      Vue.set(that.selectedValues, 'conditionFilterValues', params);
    });
  },
  methods: {
    onCreateFilter(instance) {
      this.filterConditionInstance = instance;
    },
    validateFilter() {
      let valid = false;
      if (this.filterConditionInstance) {
        const temp = this.filterConditionInstance.getSelectedFilters() || {};
        // Checking for Order - Becoz values are not updating properly on closing the filter.
        // TODO Filter Revamp
        if (temp.order.length > 0) {
          valid = true;
        } else {
          valid = false;
        }
      }
      return valid;
    },
    moveNext() {
      this.formatDisplayText(this.step.textMapping);
      this.$emit('nextStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    movePrev() {
      this.$emit('prevStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    addFilterDisabledFn(item) {
      return (
        this.multiRetailerConfigConditionStepFilterDisable?.shouldDisable(
          item,
          this
        ) ?? false
      );
    },
    addFilterDisabledTooltipFn(item) {
      return (
        this.multiRetailerConfigConditionStepFilterDisable?.tooltipFn(
          item,
          this
        ) ?? ''
      );
    }
  }
};
</script>
