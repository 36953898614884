<template>
  <div class="u-width-100">
    <div class="u-display-flex u-flex-align-items-flex-start u-flex-wrap-yes">
      <div
        v-for="(productUrl, index) in productUrlsList"
        :key="index"
      >
        <img
          v-tippy=""
          :title="`Go to ${productUrl.split('.')[1]} page for this SKU`"
          :src="getRetailerImageForProductUrl(productUrl)"
          class="u-spacing-mr-xs u-border-radius-s retailerIconClass"
          alt="logo"
          @click="logoClicked(productUrl)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import urlHelper from '@/utils/helpers/url';

export default {
  components: {},
  props: {
    productUrlsList: {
      type: Array,
      default: []
    },
    retailersLogoMap: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    logoClicked(productUrlLink) {
      window.open(productUrlLink, '_blank');
    },
    getRetailerImageForProductUrl(productUrl) {
      const domain = urlHelper.getHostName(productUrl);

      // To make this mapping region flexible. Example: 'www.amazon.in'
      const domainName = domain.split('.')[1];

      return this.retailersLogoMap[domainName];
    }
  }
};
</script>

<style lang="css" scoped>
.retailerIconClass {
  height: 16px;
  width: 54px;
  padding: 0 3px;
  border: solid 1px #e9eaeb;
  border-radius: 100px;
}
</style>
