<template>
  <div class="budget-planner u-width-100 u-display-flex">
    <div v-if="openActivity">
      <activity-log
        v-if="showActionsLog"
        :data="getActivityLogData"
        :page-name="'budgetPlan'"
        :close-activity-log="closeActivityLog"
        :heading="'Budget plan edit history'"
        :top-spacing="'73px'"
        :retailer="retailer"
        :is-budget-optimizer="true"
      />
    </div>
    <div v-if="uncategorized">
      <uncategorizedSkuViews
        :retailer="retailer"
        @closeUncategorizedView="closeUncategorizedView"
      />
    </div>
    <Loader
      :loading="isDashboardLoading"
      class="loader--expand"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      v-if="!isDashboardLoading"
      class="u-display-flex u-width-100 u-flex-justify-content-center"
    >
      <div
        v-if="!budgetPlanExists"
        class="u-display-flex u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"
      >
        <img
          style="max-width: 18%"
          src="/images/no_budget.png"
          alt="no budget"
        />
        <div class="u-spacing-pv-l u-font-weight-600 u-font-size-3">
          Looks like you haven’t created your budget plan yet!
        </div>
        <div class="u-display-flex">
          <rb-button
            type="filled"
            :click-fn="createPlan"
            :text="
              `Create a Budget Plan` +
              (showNextYearPlanButton ? ` for ${thisYear}` : '')
            "
          />
          <div
            v-if="showNextYearPlanButton"
            class="u-spacing-ph-m"
          >
            <rb-button
              type="filled"
              :click-fn="routeToNextYearPlan"
              :text="`Create a Budget Plan for ${thisYear + 1}`"
            />
          </div>
        </div>
      </div>
      <!-- The dashboard is rendered if a budget plan exists -->
      <div
        v-else
        class="u-display-flex u-flex-direction-column u-width-100"
      >
        <div
          class="sticky u-spacing-ph-l u-header-box-shadow"
          :class="{ 'u-spacing-mb-s': isBudgetPlanArchived }"
          style="z-index: 11"
        >
          <rb-filter-panel
            id="wk-budgetPlanner-filters"
            :listener-event="config.filters.filterKey"
            :hide-filter="true"
            style="z-index: 11"
            page="budgetPlanner"
            :allow-date-compare="false"
            :state="filterState"
            :loader-status="loaderStatus"
            :enable-save="false"
            :has-search="true"
            :data="filterData"
            :primary="computedPrimaryFilterData"
            :secondary-data="secondaryFilterData"
            :config="config.filters"
            :emit="config.filters.emit"
            :new-date="true"
            :use-different-date-for-canned-dates="'budget_planner_dashboard'"
            :max-date="getBudgetPlannerDateWiseMinMaxKey"
            :default-date-post-range="'thisMonth'"
            :additional-ranges="additionalRanges"
            :get-filter-instance="getFilterInstance"
          >
            <div
              slot="leftSide-searchPanel"
              class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
            >
              <PageTitle :title="'Budget Optimizer'" />
            </div>
            <div
              slot="preDatePicker"
              class="u-spacing-mr-s"
            >
              <rb-select
                :open-direction="'bottom-end'"
                :send-details="true"
                :options="budgetPlanOptions"
                :on-close="actionSelected"
              >
                <div
                  slot="trigger"
                  class="u-more-action-color u-cursor-pointer u-more-action budget-plan-option u-flex-1 u-border-all u-border-radius-s u-border-color-blue-base u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s"
                >
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s"
                    :icon="
                      isBudgetPlanArchived ? 'add-circle-outline' : 'pencil'
                    "
                  />
                  <div
                    class="u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"
                  >
                    {{
                      isBudgetPlanArchived
                        ? 'Create a Budget Plan'
                        : 'Edit Budget Plan'
                    }}
                  </div>
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer u-action-padding"
                  >
                    <rb-icon
                      v-if="option.icon"
                      class="icon-color"
                      :class="option.class"
                      :icon="option.icon"
                    />
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"
                    >
                      {{ option.name }}
                    </div>
                  </div>
                </template>
              </rb-select>
            </div>
            <!-- Automations Toggle Added -->

            <div
              v-if="uiAutomationToggleConfig"
              slot="preDatePicker"
              class="u-spacing-mr-s"
            >
              <rb-select-v2
                :open-direction="'bottom-end'"
                :send-details="true"
                :search-enabled="false"
                :options="automationsOptions"
                :custom-padding="true"
                :is-on-click-custom-present="true"
                @onSwitchChangeCustom="(value) => updateAutomations(value)"
              >
                <div
                  slot="trigger"
                  class="u-cursor-pointer u-more-action u-flex-1 u-border-all u-border-radius-s u-color-grey-lighter u-border-color-grey-xxx-light u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s"
                >
                  <div
                    class="u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"
                  >
                    Edit Automations
                  </div>
                  <span
                    v-tippy="tippyConfig"
                    :title="'Changes will be in effect from next day onwards, once the next set of actions are generated.'"
                  >
                    <rb-icon
                      icon="help-fill"
                      class="u-color-blue-base rb-icon--medium u-cursor-pointer"
                    />
                  </span>
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <rb-switch
                      v-if="option.addToggle"
                      :id="'automationsToggle'"
                      :true-value="true"
                      :false-value="false"
                      :value="automationConfig[option.key]"
                      :native-value="automationConfig[option.key]"
                      :disabled="false"
                      class="u-width-auto"
                      size="small"
                    />
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"
                    >
                      {{ option.name }}
                    </div>
                  </div>
                </template>
              </rb-select-v2>
            </div>
            <div
              slot="preDatePicker"
              class="u-spacing-mr-s"
            >
              <rb-select
                :open-direction="'bottom-end'"
                :send-details="true"
                :options="actions"
                :on-close="actionSelected"
              >
                <div
                  slot="trigger"
                  class="u-more-action-color u-cursor-pointer u-more-action u-flex-1 u-border-all u-border-radius-s u-color-grey-lighter u-border-color-grey-xxx-light u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s"
                >
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s"
                    :icon="'more-horizontal'"
                    style="transform: rotate(270deg) scaleY(1.3)"
                  />
                  <div
                    class="u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"
                  >
                    {{ ' More Actions' }}
                  </div>
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer u-action-padding"
                  >
                    <rb-icon
                      v-if="option.icon"
                      class="icon-color"
                      :class="option.class"
                      :icon="option.icon"
                    />
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"
                    >
                      {{ option.name }}
                    </div>
                  </div>
                </template>
              </rb-select>
            </div>
          </rb-filter-panel>
        </div>
        <div class="u-spacing-ph-m u-spacing-pb-s">
          <banner
            v-if="isBudgetPlanArchived"
            class="u-envelop-width u-width-100"
            header-text="Looks like you haven’t created a Budget Plan for the Current Year!"
          >
            <section
              slot="sub-text"
              class="u-spacing-mt-s u-font-size-7 u-color-grey-mid-light"
            >
              Go ahead and create a Budget Plan soon to let us help you track
              and optimize.
              <span
                class="u-color-blue-base u-cursor-pointer u-font-weight-600 u-text-decoration-underline"
                @click="createPlan"
                >Create a Budget Plan</span
              >
            </section>
          </banner>
        </div>

        <div class="u-spacing-ph-m">
          <section class="u-envelop-width u-width-100">
            <!-- if budget plan update is not triggered  -->
            <budgetPlannerChart
              class="u-spacing-mb-m"
              @openSidePanel="openSidePanel"
            />
            <budgetPlannerTable class="u-spacing-mb-m" />
            <budgetPlannerActions
              v-if="retailer === 'amazon'"
              :is-budget-plan-archived="isBudgetPlanArchived"
              class="u-spacing-mb-m"
              @createBudgetPlan="createPlan"
            />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import widgetsConfig from '@/pages/budget-planner-v2/dashboard/widget.js';
import { updateComputedFilterData } from '@/pages/budget-planner-v2/dashboard/helpers/filterUpdate.js';
import budgetPlannerActions from '@/pages/budget-planner-v2/dashboard/widgets/budgetPlannerActions.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import budgetPlannerChart from '@/pages/budget-planner-v2/dashboard/widgets/chart/index.vue';
import widgetsUtil from '@/components/widgetMixin';
import budgetPlannerTable from '@/pages/budget-planner-v2/dashboard/widgets/table/index.vue';
import banner from '@/components/widgets/banner.vue';
import { retailerMap } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import { eventBus } from '@/utils/services/eventBus';
import uncategorizedSkuViews from '@/components/ams/budgetPlanner/dashboard/uncategoriszedSkuViews.vue';
import moment from 'moment-timezone';
import Loader from '@/components/basic/loader';
import Vue from 'vue';

export default {
  components: {
    Loader,
    budgetPlannerActions,
    rbFilterPanel,
    budgetPlannerChart,
    budgetPlannerTable,
    activityLog,
    banner,
    uncategorizedSkuViews,
    RbSelectV2
  },
  mixins: [widgetsUtil],
  data() {
    return {
      thisYear: null,
      tippyConfig: {
        placement: 'bottom',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      },
      nextYearPlanRoute: {
        name: 'create-budget-plan-v2',
        query: { nextYearPlan: true }
      },
      showNextYearPlanButton: false,
      uncategorized: false,
      additionalRanges: {
        add: ['thisMonth', 'thisQuarter']
      },
      filterInstance: null,
      filterState: {
        getter: 'budgetPlannerDashboardV2/getWorkbenchBudgetPlannerFilters',
        setter: 'budgetPlannerDashboardV2/setWorkbenchBudgetPlannerFilters'
      },
      widgetsConfig: {},
      config: {},
      openActivity: true,
      showActionsLog: false,
      actions: [
        {
          icon: 'equalizer',
          name: 'Adjust seasonality',
          class: 'rb-icon--small u-spacing-mr-s'
        },
        {
          icon: 'timeline',
          name: 'View edit history',
          class: 'rb-icon--medium u-spacing-mr-xs'
        }
      ],
      automationsOptions: [
        {
          addToggle: true,
          key: 'keywordBidChange',
          name: 'Bid Automation'
        },
        {
          addToggle: true,
          key: 'campaignBudgetChange',
          name: 'Budget Automation'
        }
      ],
      automationToggleValues: {
        campaignBudgetChange: false,
        keywordBidChange: false
      },
      budgetPlanOptions: []
    };
  },
  computed: {
    uiAutomationToggleConfig() {
      return Vue.options.filters.config_check(
        'feature.pages.automations_ui_toggle'
      ).enable;
    },
    automationConfig() {
      this.$set(
        this.automationToggleValues,
        'campaignBudgetChange',
        this.$store.getters['budgetPlannerOmni/getAutomationsConfig']
          .campaignBudgetChange
      );
      this.$set(
        this.automationToggleValues,
        'keywordBidChange',
        this.$store.getters['budgetPlannerOmni/getAutomationsConfig']
          .keywordBidChange
      );
      return {
        campaignBudgetChange: this.automationToggleValues.campaignBudgetChange,
        keywordBidChange: this.automationToggleValues.keywordBidChange
      };
    },
    computedPrimaryFilterData() {
      const { categorizationType = null, selectedLevels = null } =
        this.currentBudgetPlan?.[0]?.actions?.[0]?.executionTemplate || {};
      const filterData = updateComputedFilterData(
        categorizationType,
        selectedLevels,
        this.primaryFilterData
      );
      return filterData;
    },
    getBudgetPlannerDateWiseMinMaxKey() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBudgetPlannerDateWiseMinMaxKey'
      ];
    },
    isDashboardLoading() {
      return this.$store.getters['budgetPlannerDashboardV2/isDashboardLoading'];
    },
    isBudgetPlanArchived() {
      return (
        this.$store.getters.getIsBudgetPlanArchived &&
        !this.$store.getters.getBudgetPlannerStrategies?.length
      );
    },
    budgetPlanExists() {
      return this.isBudgetPlanArchived || !!this.currentBudgetPlan?.length;
    },
    currentBudgetPlan() {
      // right now we will have only one strategy
      return this.$store.getters['budgetPlannerDashboardV2/getStrategies'];
    },
    getShowBudgetActivityLog() {
      return this.$store.getters.getShowBudgetActivityLog;
    },
    getActivityLogData() {
      return this.$store.getters.getBudgetActivityLog;
    },
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  watch: {
    currentBudgetPlan: {
      handler: function (newValue) {
        if (newValue) {
          this.fetchFilters(
            retailerMap[this.retailer],
            'FETCH_FILTERS_V2',
            'budgetPlanner',
            undefined,
            'budget_optimizer_omni',
            undefined,
            undefined
          );
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('budgetPlannerDashboardV2/fetchLatestStrategy');
    const today = moment();
    this.thisYear = today.year();
    this.budgetPlanOptions = [
      {
        icon: 'budget_optimizer',
        name: `${this.thisYear} budget plan`,
        class: 'rb-icon--medium u-spacing-mr-xs'
      }
    ];
    if (
      Vue.options.filters.config_check('feature.omniBO.nextYearPlan') &&
      today.month() === 11 &&
      today.dayOfYear() !== 365
    ) {
      this.showNextYearPlanButton = true;
      // show action from 1st dec to 30th dec of the year.
      this.budgetPlanOptions.push({
        icon: 'budget_optimizer',
        name: `${this.thisYear + 1} budget plan`,
        class: 'rb-icon--medium u-spacing-mr-xs'
      });
    }
    eventBus.$on('openBudgetPlannerUncategorized', () => {
      this.uncategorized = true;
    });
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    this.config.filters.search.placeholder = 'Search';
    if (this.uiAutomationToggleConfig) {
      ['campaignBudgetChange', 'keywordBidChange'].forEach((automation) => {
        this.$store.dispatch('budgetPlannerOmni/fetchLatestAutomationConfig', {
          actionType: automation,
          snackBar: this.openSnackbar
        });
      });
    }
  },
  destroyed() {
    eventBus.$off('openBudgetPlannerUncategorized');
  },
  methods: {
    async updateAutomations(item) {
      const actionType = item.key;
      await this.$store.dispatch(
        'budgetPlannerOmni/updateLatestAutomationConfig',
        {
          userName: this.$store.getters.getSessionUser?.email,
          actionType: actionType,
          value: !this.automationToggleValues[actionType],
          snackBar: this.openSnackbar,
          changeToggle: this.changeToggle
        }
      );
    },
    changeToggle(actionType) {
      this.automationToggleValues[actionType] =
        !this.automationToggleValues[actionType];
    },
    actionSelected(context, data) {
      if (!data?.length) {
        return;
      }
      if (data?.[0]?.name === 'Adjust seasonality') {
        this.openSeasonality();
      } else if (data?.[0]?.name === 'View edit history') {
        this.openSidePanel();
      } else if (data?.[0]?.name === `${this.thisYear + 1} budget plan`) {
        this.routeToNextYearPlan();
      } else if (data?.[0]?.name === `${this.thisYear} budget plan`) {
        this.createPlan();
      }
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    createPlan() {
      this.$store.dispatch(
        'budgetPlannerDashboardV2/setCreatePlanActiveSelection',
        0
      );
      this.$store.dispatch('resetVisitedSteps', ['uploadStep']);
      const name = this.budgetPlanExists
        ? 'edit-budget-plan-v2'
        : 'create-budget-plan-v2';
      this.$router.push({ name });
    },
    routeToNextYearPlan() {
      this.$router.push(this.nextYearPlanRoute);
    },
    openSnackbar(message) {
      this.$snackbar.open({
        message,
        duration: 6000,
        actionText: ''
      });
    },
    closeUncategorizedView() {
      this.uncategorized = false;
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
    },
    openSidePanel() {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('getBudgetActivityLog');
    },
    openSeasonality() {
      this.$store.dispatch('resetVisitedSteps', ['uploadStep', 'previewStep']);
      this.$store.dispatch(
        'budgetPlannerDashboardV2/setCreatePlanActiveSelection',
        3
      );
      this.$router.push({
        name: 'create-budget-plan-v2',
        query: { page: 'seasonality' }
      });
    }
  }
};
</script>

<style lang="css">
.budget-planner .rb-button-icon-12 .rb-icon:before {
  font-size: 12px !important;
}
</style>

<style lang="css" scoped>
.updating-budget-plan-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.updating-budget-plan-loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 95%;
  height: 4px;
  background-color: #007cf6;
  animation: loading 20s linear;
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 95%;
  }
}
.updating-budget-plan-card {
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  background-color: #fff;
  height: calc(100% - 110px);
  margin-bottom: 24px;
}
.budget-planner {
  min-height: 100%;
}
.u-more-action.budget-plan-option {
  background-color: #007cf6;
  color: #fff;
}
.u-more-action.budget-plan-option:hover {
  opacity: 0.8;
}
.u-more-action:hover {
  border: solid;
  border-radius: 4px;
  border-color: #007cf6;
}
.u-more-action-color.budget-plan-option:hover {
  color: #fff;
}
.u-more-action-color:hover {
  color: #007cf6;
}
.u-action-padding {
  padding: 0 !important;
}
.automations-toggle {
  padding: 0px !important;
}
.icon-color {
  color: grey;
}
</style>
