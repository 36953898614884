<template>
  <div class="u-display-flex u-flex-direction-column">
    <span
      class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-display-flex u-flex-align-items-center"
      :class="{ 'u-spacing-mb-s': description }"
    >
      {{ title }}
    </span>
    <div class="u-display-flex u-flex-align-items-center">
      <span v-if="icon">
        <rb-icon
          :icon="icon"
          class="rb-icon u-color-grey-lighter rb-icon--small u-spacing-mr-xs"
        />
      </span>
      <span class="u-color-grey-light u-font-size-6">{{ description }}</span>
    </div>
    <div>
      <chip-select
        class="u-display-inline-block u-spacing-mt-m"
        style="background-color: white"
        :show-settings-icon="true"
        :value="selectedOption"
        label="Business model: "
        :options="filterOptions"
        :display-label-over-value="true"
        @onSelectEvent="onFilterChange"
      />
    </div>
  </div>
</template>
<script>
import chipSelect from '@/components/basic/chipSelect.vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
export default {
  name: 'CardGroupHeader',
  components: {
    chipSelect,
    RbSelectV2,
    rbFilterPanel
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    filterOptions: {
      type: Array,
      default: () => []
    },
    selectedOption: {
      type: [String, Number],
      default: ''
    },
    onFilterChange: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
