<template>
  <section class="container">
    <div class="u-display-flex u-flex-align-items-flex-start">
      <div class="u-display-flex u-spacing-mr-xs">
        <rb-icon
          class="rb-icon--medium u-color-orange-base u-display-flex"
          icon="bell"
        />
      </div>
      <div>
        <div
          class="u-font-size-6 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center"
        >
          <div class="u-display-flex u-flex-align-items-center">
            {{ headerText }}
            <span
              class="u-color-blue-base u-display-flex u-flex-align-items-center u-spacing-mh-xs"
            >
              {{ headerDate }}
            </span>
            !
          </div>
        </div>
        <div>
          <slot name="sub-text" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      default: null
    },
    headerDate: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="css" scoped>
.container {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(43, 51, 59, 0.15);
  background-color: #fff;
  padding: 16px;
}
</style>
