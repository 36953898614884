<template>
  <div
    :key="chipSelectKey"
    v-tippy="isDisabled ? { placement: 'bottom', arrow: true } : null"
    :title="isDisabled ? disableTextForTooltip : null"
    class="chipSelectWrapper u-font-size-7"
  >
    <rb-select
      class="u-display-flex u-flex-align-items-center"
      :class="{ 'u-pointer-events-none disabled': isDisabled }"
      :send-details="true"
      :options="options"
      :on-close="onSelectEvent"
      :disabled="isDisabled"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <rb-icon
          v-if="showSettingsIcon"
          class="rb-icon--small u-color-grey-lighter u-spacing-mr-xs"
          icon="settings"
        ></rb-icon>
        <span
          class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
          >{{ label }}</span
        >
        <div
          class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-base"
        >
          {{ displayValue }}
        </div>
        <rb-icon
          class="u-flex-0 rb-icon--medium u-color-grey-lighter"
          icon="caret-down"
        />
        <template v-if="helpTooltip">
          <rb-tooltip-header
            id="tooltipTemplate"
            :title="displayValue"
            :message="helpTooltip"
          />
          <div
            v-tippy="{
              html: '#' + 'tooltipTemplate',
              theme: 'rb-tooltip-header',
              placement: 'bottom',
              interactive: true,
              reactive: true,
              arrow: false
            }"
            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger4"
            :title="helpTooltip"
          >
            <rb-icon
              class="u-flex-0 rb-icon--medium u-color-grey-lighter"
              icon="help-fill"
            />
          </div>
        </template>
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div class="u-display-flex u-flex-align-items-center">
          {{ option.label }}
        </div>
      </template>
    </rb-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disableForSingleOption: {
      type: Boolean,
      default: false
    },
    disableTextForTooltip: {
      type: String,
      default: 'Only one option is available to be selected'
    },
    showSettingsIcon: {
      type: Boolean,
      default: false
    },
    helpTooltip: {
      type: String,
      default: ''
    },
    displayLabelOverValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chipSelectKey: 0
    };
  },
  computed: {
    isDisabled() {
      return this.disableForSingleOption && this.options.length === 1;
    },
    displayValue() {
      return this.displayLabelOverValue
        ? this.options?.find((el) => el.value === this.value)?.label ?? ''
        : this.value;
    }
  },
  watch: {
    isDisabled() {
      this.chipSelectKey++;
    }
  },
  methods: {
    onSelectEvent(context, value) {
      if (value && value[0]) {
        this.$emit('onSelectEvent', value[0]);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.chipSelectWrapper {
  border-radius: 13.5px;
  padding: 2px 8px;
  border: solid 1px #e9eaeb;
}
.chipSelectWrapper:hover,
.chipSelectWrapper:focus {
  border: 1px solid #caccce;
}
</style>
