<template>
  <div
    :class="{
      'u-flex-1 u-flex-direction-column u-display-flex':
        strategyList.length === 0
    }"
  >
    <div
      class="u-display-flex u-spacing-ph-l u-bg-color-grey-white u-spacing-pb-m u-header-box-shadow sticky u-z-index-1 u-spacing-mb-s u-flex-justify-content-space-between"
    >
      <PageTitle :title="'Hourly Bidder'" />
      <rb-button
        v-if="isMarketingEnabled && isDaypartingEnabled"
        :click-fn="createStrategy"
        :text="'Create New Strategy'"
        :type="'filled'"
        data-cy="CreateStrategy"
      />
    </div>
    <section
      class="u-envelop-width u-width-100"
      :class="{
        'u-flex-1 u-flex-direction-column u-display-flex u-flex-justify-content-center':
          strategyList.length === 0
      }"
    >
      <div
        v-if="isMarketingEnabled && isDaypartingEnabled"
        class="u-spacing-ph-m"
      >
        <div
          v-if="!isLoaded"
          class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative createStratgey"
        >
          <loader
            v-if="!isLoaded"
            class="fill--parent"
            :loading="!isLoaded"
            :color="'#007cf6'"
          />
        </div>
        <div
          v-else
          class="u-spacing-pb-m u-display-flex u-flex-align-items-center u-width-100"
        >
          <div
            class="u-display-flex u-flex-wrap-yes u-width-100 u-flex-align-items-center u-flex-justify-content-center u-height-100"
          >
            <div
              v-for="(strategy, index) in strategyList"
              :key="index"
              class="card u-spacing-m-s"
              style="width: calc(50% - 16px); min-height: 185px"
              :class="{
                'status-achived': strategy.strategyStatus === 'ARCHIVED'
              }"
            >
              <div class="u-spacing-p-l">
                <div
                  class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
                >
                  <span
                    class="u-font-size-5 u-font-weight-bold u-color-blue-base"
                    >{{ 'STRATEGY ' + (index + 1) }}</span
                  >
                  <div v-if="strategy.submitLoading">
                    <div class="mini-spinner">
                      <div class="bounce1" />
                      <div class="bounce2" />
                      <div class="bounce3" />
                    </div>
                  </div>
                  <div v-else>
                    <rb-select
                      v-if="strategy.isEditable"
                      :send-details="true"
                      :on-close="statusSelected"
                      :options="strategy.options"
                      :context="[index]"
                    >
                      <div
                        slot="trigger"
                        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                      >
                        <div class="u-display-flex u-flex-align-items-center">
                          <div class="u-display-flex u-flex-direction-column">
                            <div
                              class="statusChip u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
                              :class="
                                statusColorClassesMap[strategy.strategyStatus]
                              "
                            >
                              <span
                                class="u-font-size-6 u-color-grey-white u-font-weight-600"
                                >{{
                                  (strategy.selectedStatus || {}).title
                                }}</span
                              >
                              <rb-icon
                                class="rb-icon--small u-spacing-ml-xs u-color-grey-white"
                                :icon="'arrow1-down'"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <template
                        slot="item"
                        slot-scope="option"
                      >
                        <div class="u-display-flex u-flex-align-items-center">
                          {{ option.title }}
                        </div>
                      </template>
                    </rb-select>
                    <div
                      v-else
                      class="statusChip u-display-flex u-flex-align-items-center u-flex-justify-content-center"
                      :class="
                        statusColorClassesMap[
                          (strategy.selectedStatus || {}).value
                        ]
                      "
                    >
                      <span
                        class="u-font-size-6 u-color-grey-white u-font-weight-600"
                        >{{ (strategy.selectedStatus || {}).title }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="u-display-flex u-flex-direction-column u-spacing-mt-m"
                >
                  <span class="u-font-size-4 u-font-weight-bold">{{
                    strategy['startegyName']
                  }}</span>
                  <span
                    class="u-font-size-6 u-spacing-mt-s u-line-height-1-3"
                    >{{ strategy['strategyDesc'] }}</span
                  >
                </div>
                <div
                  v-if="strategy.isEditable"
                  class="u-display-flex u-spacing-mt-m u-cursor-pointer"
                >
                  <router-link
                    :to="{
                      name: 'edit-strategy',
                      query: { strategyUniqueId: strategy.strategyUniqueId }
                    }"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
                    >
                      <rb-icon
                        class="rb-icon--small"
                        :icon="'pencil'"
                      />
                      <span class="u-font-size-7 u-spacing-ml-xs"
                        >Edit strategy</span
                      >
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div
              v-if="strategyList.length === 0"
              class="u-spacing-pv-l u-font-weight-600 u-font-size-3"
            >
              No Hourly Bidder Strategies created!
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="u-spacing-p-xl u-spacing-pt-0"
      >
        <div class="card">
          <div class="u-flex-1">
            <div
              v-if="!isLoaded"
              class="u-spacing-p-xl"
            >
              <Loader
                :loading="!isLoaded"
                class="fill--parent"
                :color="'#3fadf7'"
                :size="'4rem'"
                :thickness="'.2rem'"
              />
            </div>
            <div v-if="isLoaded && strategyList.length">
              <rb-data-table
                :grid-options="gridOptions"
                :table-columns="columnList"
                :table-data="strategyList"
                :enable-client-side-sorting="false"
                :grid-auto-height="true"
                :row-height="68"
                :no-rows-message="'No strategies found.'"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loader from '@/components/basic/loader';
import Vue from 'vue';

export default {
  components: {
    Loader
  },
  data() {
    return {
      strategies: [],
      statusColorClassesMap: {
        LIVE: 'u-bg-color-green-base',
        PAUSED: 'u-bg-color-orange-base',
        ARCHIVED: 'u-bg-color-red-base'
      },
      columnList: [
        {
          field: 'strategy',
          headerName: 'Name',
          cellRendererFramework: Vue.extend({
            template: `<div class="u-flex-1 u-display-flex u-flex-direction-column u-flex-shrink-past-min-size" :class="[!params.context.componentParent.isStrategyLive(params.data.status) ? 'fade--out' : '']">
                          <div class="u-font-weight-600 u-spacing-mb-s u-color-grey-base">{{params.data.startegyName}}</div>
                          <div class="u-font-size-5 u-color-grey-base u-text-overflow-ellipsis" :title="params.data.strategyDesc">{{params.data.strategyDesc}}</div>
                        </div>`
          })
        },
        {
          field: 'status',
          headerName: 'Status',
          maxWidth: 200,
          cellRendererFramework: Vue.extend({
            template: `<div class="u-display-flex u-flex-align-items-center" :class="[!params.context.componentParent.isStrategyLive(params.data.status) ? 'fade--out' : '']">
                          <span class="u-flex-none u-display-flex u-flex-align-items-center" :class="[params.context.componentParent.isStrategyLive(params.data.status) ? 'u-color-green-base' : 'u-color-yellow-base']">
                            <rb-icon :icon="'dot'"></rb-icon>
                          </span>
                          <span class="u-flex-1 u-font-size-5 u-color-grey-base u-spacing-pl-s">{{params.data.status}}</span>
                        </div>`
          })
        },
        {
          field: 'createdBy',
          headerName: 'Created By',
          maxWidth: 300,
          cellRendererFramework: Vue.extend({
            template:
              '<div class="u-display-flex u-color-grey-base" :class="[!params.context.componentParent.isStrategyLive(params.data.status) ? \'fade--out\' : \'\']">{{params.data.createdBy}}</div>'
          })
        }
      ],
      gridOptions: {
        context: {
          componentParent: this
        },
        onGridReady: function ($event) {
          $event.api.sizeColumnsToFit();
        }
      }
    };
  },
  computed: {
    strategyList() {
      let strategies = this.$store.getters.getStrategies;
      if (this.strategies.length > 0) {
        return this.strategies;
      }
      strategies = strategies.filter(
        (item) => item.createdBy !== 'BoomerangCommerce'
      );
      this.strategies = this.formatStrategies(strategies);
      return this.strategies;
    },
    isLoaded() {
      return this.$store.getters.areStrategiesLoaded;
    },
    isMarketingEnabled() {
      var productIDs = this.$store.getters.getProductConfig || ['SalesIQ'];
      return productIDs.indexOf('MarketingIQ') > -1;
    },
    isDaypartingEnabled() {
      let config = 'feature.dayparting';
      const retailer = this.$store.getters.getRetailer;
      if (retailer !== 'amazon') {
        config = `feature.pages.${retailer}_strategies.enable`;
      }
      return Vue.options.filters.config_check(config);
    }
  },
  created() {
    this.$store.dispatch('setAppActiveTab', 2);
    this.$store.dispatch('fetchStrategies');
  },
  methods: {
    createStrategy() {
      this.$router.push({
        name: 'create-strategy'
      });
    },
    isStrategyLive(key) {
      return key && key.toLowerCase() === 'live';
    },
    formatStrategies(strategies) {
      const options = [
        {
          title: 'Active',
          value: 'LIVE'
        },
        {
          title: 'Paused',
          value: 'PAUSED'
        },
        {
          title: 'Archived',
          value: 'ARCHIVED'
        }
      ];
      for (let i = 0; i < strategies.length; i++) {
        strategies[i].startegyName = unescape(strategies[i].strategyName);
        strategies[i].strategyDesc = unescape(strategies[i].description);
        strategies[i].isEditable = true;
        const defaultIndex = options.findIndex((element) => {
          const status = strategies[i].strategyStatus;
          return element.value === status.toUpperCase();
        });
        if (!strategies[i].selectedStatus) {
          strategies[i].selectedStatus = options[defaultIndex];
        }
        strategies[i].submitLoading =
          strategies[i].submitLoading === undefined
            ? false
            : strategies[i].submitLoading;
        if (strategies[i].isEditable) {
          strategies[i].options = options;
        }
      }
      return strategies;
    },
    statusSelected(context, selectedOption) {
      const index = context[0];
      const selectedStatus = selectedOption[0].value;
      const data = {
        strategyUniqueId: this.strategyList[index].strategyUniqueId,
        status: selectedStatus
      };
      const that = this;
      that.strategies[index].submitLoading = true;
      Vue.set(that.strategies, index, that.strategies[index]);
      this.$store
        .dispatch('updateStrategyData', data)
        .then((data) => {
          that.strategies[index].submitLoading = false;
          that.strategies[index].selectedStatus = selectedOption[0];
          that.strategies[index].status = selectedStatus;
          Vue.set(that.strategies, index, that.strategies[index]);
        })
        .catch((error) => {
          that.strategies[index].error = error;
          that.strategies[index].submitLoading = false;
          Vue.set(that.strategies, index, that.strategies[index]);
        });
    }
  }
};
</script>

<style scoped lang="css">
.statusChip {
  width: 75px;
  height: 20px;
  border-radius: 4px;
}
.table--heading {
  font-weight: normal;
}

.fade--out {
  opacity: 0.4;
}
.mini-spinner {
  border: 0;
  width: 36px;
  text-align: center;
  padding: 2px;
}
.u-line-height-1-3 {
  line-height: 1.3;
}
.status-achived {
  opacity: 0.7;
}
</style>
