import Vue from 'vue';

/**
 * Filter **array_differentiator**
 * key1 and key2 are the names of the value to be compared for filtering
 * key1 and key2 are the optional **String** parameters
 * WholeArray and selectedArray should be of type **Array** for the filtering
 * Result = wholeArray - selectedArray
 */
Vue.filter(
  'array_differentiator',
  function (wholeArray, selectedArray, key1, key2) {
    if (
      wholeArray.constructor !== Array ||
      selectedArray.constructor !== Array
    ) {
      return wholeArray;
    }
    return wholeArray.filter((el) => {
      if (el) {
        if (key1 && key2) {
          for (let i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i][key2] === el[key1]) {
              return true;
            }
          }
          return false;
        } else if (key1 && !key2) {
          return !selectedArray.includes(el[key1]);
        } else if (!key1 && key2) {
          for (let i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i][key2] === el) {
              return true;
            }
          }
          return false;
        } else if (!key1 && !key2) {
          return !selectedArray.includes(el);
        }
      } else {
        return false;
      }
    });
  }
);
