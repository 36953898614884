<template>
  <div
    class="u-display-flex u-flex-align-items-center u-spacing-mh-l u-spacing-pv-m"
    :class="customClassArray"
  >
    <span @click="backButtonClicked">
      <rb-icon
        icon="arrow_back"
        class="u-color-grey-lighter rb-icon--x-medium u-cursor-pointer u-spacing-mr-s"
      />
    </span>
    <div>
      <div
        class="u-font-size-6 u-display-flex u-flex-align-items-center u-color-grey-lighter u-spacing-mt-xs"
      >
        Recently Failed Campaigns >
      </div>
      <div
        class="u-color-grey-lighter u-font-size-3 u-text-case-upper u-font-weight-600 u-spacing-mt-xs"
      >
        BACK TO ALL CAMPAIGNS
      </div>
      <div
        class="u-font-size-7 u-display-flex u-flex-align-items-center u-color-grey-lighter u-spacing-mt-xs"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small"
          :icon="'info-circle-fill'"
        />
        <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
          Currently you are looking at the campaigns which were failed in the
          last 48hrs
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackToAllCampaigns',
  props: {
    backButtonClicked: {
      type: Function,
      default: function () {}
    },
    customClassArray: {
      type: Array,
      default: function () {
        return [];
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-flex-align-self-flex-center {
  align-self: center;
}

.u-margin-right-auto {
  margin-right: auto;
}
</style>
