<template>
  <div class="u-display-flex u-flex-align-items-center">
    <chart
      :config="chartConfig"
      :data="chartData"
    />
    <div class="legend-container">
      <div
        v-for="legend of legends"
        class="donut-legend u-spacing-ph-l"
      >
        <div class="u-display-flex u-flex-align-items-center u-spacing-mb-s">
          <div
            class="dot"
            :style="{
              background: legend.color
            }"
          />
          <span class="u-font-size-5 u-color-grey-x-light u-spacing-pl-s">{{
            legend.label
          }}</span>
          <span
            v-if="showPercentage"
            class="u-font-size-4 u-font-weight-600 u-spacing-pl-s"
          >
            {{ legend.percentage }}%
          </span>
        </div>
        <p class="u-font-size-3 u-font-weight-600">{{ legend.value }} SKUs</p>
      </div>
    </div>
  </div>
</template>

<script>
import chart from '@/components/basic/chart.vue';

export default {
  name: 'DonutChartWithLegend',
  components: {
    chart
  },
  props: {
    chartData: {
      type: Array,
      required: true
    },
    donutColors: {
      type: Object,
      required: true
    },
    legends: {
      type: Array,
      required: true
    },
    totalSkus: {
      type: Number,
      default: 0
    },
    showPercentage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chartConfig() {
      return {
        interaction: { enabled: true },
        chartOptions: {
          legend: false,
          type: 'donut',
          tooltip_format: { roundOff: 0 },
          data: {
            colors: this.donutColors
          },
          size: { height: 100, width: 100 }
        },
        donut: {
          expand: false,
          label: { show: false },
          width: 10,
          title: this.totalSkus ? `${this.totalSkus} SKUs` : ''
        },
        xAxisType: 'sales'
      };
    }
  }
};
</script>
<style scoped lang="css">
.legend-container {
  display: flex;
  gap: 2rem;
}
.legend-container .donut-legend:nth-child(2) {
  border-left: 1px solid #e9eaeb;
  border-right: 1px solid #e9eaeb;
}
</style>
