var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.refreshDates),function(dateMetric,index){return _c('span',{key:index},[_c('div',{staticClass:"u-display-flex u-flex-direction-column",attrs:{"id":'date-level-tippy' + index}},[_c('span',{staticClass:"u-border-bottom u-border-color-grey-xxx-light updated_date_title_section u-font-size-4 u-color-grey-base u-font-weight-600 u-spacing-p-m u-border-width-s"},[_vm._v("Updated on "+_vm._s(_vm.formatter(dateMetric.date, 'date')))]),_c('div',{staticClass:"u-spacing-pv-s u-display-flex u-flex-direction-column"},_vm._l((dateMetric.metrics),function(metricName,m_index){return _c('span',{key:m_index,staticClass:"u-spacing-ph-m u-spacing-pv-s u-font-size-7 u-color-grey-base metric_refresh_date_metrics_item"},[_vm._v(_vm._s(metricName))])}),0),_c('span',{staticClass:"u-font-size-7 u-spacing-p-s u-border-color-grey-xxx-light u-border-all refresh_metrics_info_footer u-border-width-s u-font-weight-400"},[_c('rb-icon',{staticClass:"rb-icon--small u-color-blue-base u-spacing-mr-xs",attrs:{"icon":"info-circle-fill"}}),_vm._v(" These metrics now have higher refresh rate ")],1)]),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
        html: '#' + 'date-level-tippy' + index,
        reactive: true,
        distance: 4,
        placement: _vm.tippyPlacement,
        interactive: true,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0],
        boundary: 'viewport'
      }),expression:"{\n        html: '#' + 'date-level-tippy' + index,\n        reactive: true,\n        distance: 4,\n        placement: tippyPlacement,\n        interactive: true,\n        theme: 'dropdown',\n        trigger: 'mouseenter',\n        duration: [0, 0],\n        boundary: 'viewport'\n      }"}],staticClass:"u-cursor-pointer refresh_metric_count_trigger u-spacing-ml-s underline-on-focus u-text-decoration-underline"},[_vm._v(" "+_vm._s(dateMetric.metrics.length)+" metrics")]),_vm._v(" are updated on "),_c('span',{staticClass:"u-color-grey-dark"},[_vm._v(_vm._s(_vm.formatter(dateMetric.date, 'date')))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }