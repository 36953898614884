import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      name: 'All',
      path: 'skus',
      config: 'all',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations'
    },
    {
      name: 'Sales',
      path: 'recommendations/sales',
      config: 'sales',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations'
    },
    {
      name: 'Marketing',
      path: 'recommendations/marketing',
      config: 'marketing',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations'
    },
    {
      name: 'Operations',
      path: 'recommendations/operations',
      config: 'operations',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations'
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.ESM
    }
  }))
};
