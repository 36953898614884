<template>
  <div
    class="u-width-100 u-height-100 u-display-flex u-flex-align-items-center"
    :class="[
      getCellBackgroundColor(),
      valueIsString
        ? 'u-spacing-pl-l'
        : 'u-display-flex u-flex-align-items-center u-flex-justify-content-center'
    ]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      v-if="shouldShowTooltip"
      v-tippy
      class="u-display-inline-block u-color-black-base u-font-size-5"
      :title="getToolTipText"
    >
      {{ value }}
    </div>
    <div
      v-else-if="value !== 0"
      class="u-display-inline-block u-color-black-base u-font-size-5"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/utils/constants';

export default {
  name: 'SlaTrackerCell',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    columnName: {
      type: String,
      default: null
    },
    rowData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  computed: {
    getToolTipText() {
      const cellBackgroundColor = this.getCellBackgroundColor();
      if (
        cellBackgroundColor === CONSTANTS.COLOR_RED ||
        cellBackgroundColor === CONSTANTS.COLOR_YELLOW
      ) {
        const slaBreachDatesColumn = `${this.columnName}_sla_breach_dates`;
        const slaBreachDates = this.rowData[slaBreachDatesColumn];
        const dataItemsHTML = slaBreachDates
          .map((item) => `<li>${item}</li>`)
          .join('');
        return `<p>SLA Breach Dates: <br><br><ul>${dataItemsHTML}</ul></p>`;
      } else if (cellBackgroundColor === CONSTANTS.COLOR_GRAY) {
        return `<p>${this.value}</p>`;
      } else {
        return null;
      }
    },
    valueIsString() {
      return typeof this.value === 'string';
    },
    shouldShowTooltip() {
      return this.value !== 0 && this.isHovered;
    }
  },
  methods: {
    getCellBackgroundColor() {
      if (this.columnName !== CONSTANTS.CLIENT_NAME_COLUMN) {
        const slaBreachPercentageColumn = `${this.columnName}_percent`;
        const slaBreachPercentValues = this.rowData[slaBreachPercentageColumn];
        if (slaBreachPercentValues > 15) {
          return CONSTANTS.COLOR_RED;
        } else if (slaBreachPercentValues > 0) {
          return CONSTANTS.COLOR_YELLOW;
        }
        return CONSTANTS.COLOR_GREEN;
      }
      return CONSTANTS.COLOR_GRAY;
    },
    handleMouseEnter() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
    }
  }
};
</script>

<style lang="css" scoped>
.color-green {
  background-color: #3ea95e;
}
.color-yellow {
  background-color: #ffa800;
}
.color-red {
  background-color: #d7263d;
}
.color-gray {
  background-color: #e9eaeb;
}
</style>
