<template>
  <editable-dynamic-table
    class="manageSKUTable"
    :all-rows="rowList"
    :column-defs="columnDefs"
    :grid-options="manageTaxonomyOptions.gridApi"
    :table-config="tableConfig"
    :show-filter="false"
    :show-search="false"
    :pagination-change-event="paginationChangeEvent"
    :table-header-sticky-obj="stickyObj"
    :sorting-change-event="sortChangeEvent"
    :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
    :show-pagination-options="true"
    :row-per-page-list="rowPerPageList"
    :loading="loading"
  />
</template>

<script>
import Vue from 'vue';
import editableTagsCell from '@/components/widgets/tableComponents/cells/editableTagsCell.vue';
import ingestionWelcome from '@/components/basic/welcomeImageBannerButton.vue';
import bulkChange from '@/components/widgets/upload/bulkChange';
import selectTaxonomy from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/selectTaxonomy.vue';
import editableDynamicTable from '@/components/widgets/tables/editableDynamicTable.vue';
import { formatter } from '@/utils/helpers/formatter.js';
import confirmDialog from '@/components/basic/confirmDialog.vue';
import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';
import { eventBus } from '@/utils/services/eventBus';

import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import { cloneDeep } from 'lodash';
// import reUploadSelect from './reUploadSelect.vue';
import addSkuToCatalog from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/entity-catalog/bulk-entity-manage.vue';
import genericTableCell from '@/components/globals/dataTable/tableCellComponent.vue';
import {
  saveSortingState,
  getSortMapFunc,
  asinLevelV2ConfigEnabled,
  add3pSuffixToV1MetricName
} from '@/components/pages/insights/amazon/market-share/utils.ts';
import editableCategoryCell from '@/components/widgets/tableComponents/cells/editableCategoryCell.vue';

export default {
  components: {
    ingestionWelcome,
    bulkChange,
    selectTaxonomy,
    collapsiblePage,
    editableDynamicTable,
    confirmDialog,
    addSkuToCatalog,
    HelpDocumentDialog
  },
  props: {
    metadata: {
      type: Object,
      default: {}
    },
    dataService: {
      type: Object,
      default: {}
    },
    globalSettings: {
      type: Object,
      default: {}
    },
    skuCheckBox: {
      type: Object,
      default: {}
    },
    numberOfSubcategories: {
      type: Number,
      required: true
    },
    numberOfAmazonSubcategories: {
      type: Number,
      required: true
    },
    nonFilterableAttributesColumns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rowPerPageList: [
        { title: 10 },
        { title: 20 },
        { title: 30 },
        { title: 50 }
      ],
      paginationChangeEvent: 'paginationSKUTableWidget',
      sortChangeEvent: 'sortingSKUClassification',
      rowList: [],
      journeyStage: 'preview',
      editableCells: {},
      entityData: {
        file: null,
        sheetName: 'Standard catalog',
        entity: 'catalog',
        skipFormatting: false,
        skipPostTrigger: true
      },
      manageTaxonomyOptions: {
        gridApi: {
          context: {
            componentParent: this
          },
          rowClassRules: {},
          defaultColDef: {
            flex: 1,
            wrapText: true,
            autoHeight: true
          }
        },
        catalogCrudOperations: {}
      },
      stickyObj: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.page-body#pageBody',
        callOnNextTick: true,
        addSpaceElementsSelector: ['.page-header', '.sticky-panel'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      tableConfig: {
        totalRows: 0,
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        }
      },
      bestSellerCategoryLevelList: ['First', 'Second', 'Third'],
      editedColumns: {},
      editableColumns: [],
      editableCategory: {},
      loading: false,
      skuManageColumnEdited: false,
      dataFetched: false
    };
  },
  computed: {
    columnDefs() {
      /**
       * if dataFetched is true means we have made the data API call
       * columnDefs computed prop gets called even before data API returns any response.
       * This check will avoid that issue
       * it doesn't check if data API was success or failure
       */
      if (this.dataFetched) {
        let columnList = this.decodeApstrophe(
          cloneDeep([
            {
              type: 'currency',
              formatter: 'numeric',
              dimensionLabel: 'Estimated Sales',
              dimensionColumn: add3pSuffixToV1MetricName(
                this.globalSettings,
                'SALES_ESTIMATES'
              ),
              uniqueValueCount: null,
              toolTipText:
                'The estimated sales for the SKU in the selected period.',
              sortDimensionMapping: 'salesField'
            },
            {
              dimensionLabel: 'Brand',
              dimensionColumn: 'brand_name',
              uniqueValueCount: null,
              toolTipText: 'The brand of the SKU as per Amazon PDP.',
              enableSorting: false
            },
            {
              dimensionLabel: 'Manufacturer',
              dimensionColumn: 'manufacturer_name',
              uniqueValueCount: null,
              toolTipText: 'The manufacturer of the SKU as per Amazon PDP.',
              enableSorting: false
            },
            {
              dimensionLabel: 'Category',
              dimensionColumn: 'category',
              uniqueValueCount: null,
              toolTipText: 'The category to which the SKU belongs.'
            },
            // TODO: Revisit if we want to add value to a subcategory
            // how do we do it?
            ...[...Array(this.numberOfSubcategories).keys()].map(
              (subCategoryLevel) => {
                this.editableCategory[
                  `sub_category_${subCategoryLevel + 1}`
                ] = true;
                return {
                  dimensionLabel: `Sub Category L${subCategoryLevel + 1}`,
                  dimensionColumn: `sub_category_${subCategoryLevel + 1}`,
                  uniqueValueCount: null,
                  toolTipText: `The ${this.mapNumToText(
                    subCategoryLevel + 1
                  )} level of sub-category to which the SKU belongs.`
                };
              }
            ),
            ...[...Array(this.numberOfAmazonSubcategories).keys()].map(
              (subCategoryLevel) => {
                return {
                  dimensionLabel: `Amazon Category L${subCategoryLevel + 1}`,
                  dimensionColumn: `amazon_category_l${subCategoryLevel + 1}`,
                  uniqueValueCount: null,
                  toolTipText: `The ${this.mapNumToText(
                    subCategoryLevel + 1
                  )} level of Category as per the browse node on Amazon.`
                };
              }
            ),
            ...this.bestSellerCategoryLevelList.map((level, index) => {
              return {
                dimensionLabel: `Best Seller Category ${index + 1}`,
                dimensionColumn: `best_seller_rank_node_l${index + 1}`,
                uniqueValueCount: null,
                toolTipText: `${level} Best Seller Category node which appears on the Product details page.`
              };
            }),
            ...this.nonFilterableAttributesColumns,
            {
              type: 'numeric',
              formatter: 'numeric',
              dimensionLabel: 'Weight (lb)',
              dimensionColumn: 'weight_lb',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText: 'The weight of the SKU as per Amazon PDP.'
            },
            {
              type: 'numeric',
              formatter: 'numeric',
              dimensionLabel: 'Length (in)',
              dimensionColumn: 'package_length_in',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText: 'The length of the SKU as per Amazon PDP.'
            },
            {
              type: 'numeric',
              formatter: 'numeric',
              dimensionLabel: 'Width (in)',
              dimensionColumn: 'package_width_in',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText: 'The width of the SKU as per Amazon PDP.'
            },
            {
              type: 'numeric',
              formatter: 'numeric',
              dimensionLabel: 'Height (in)',
              dimensionColumn: 'package_height_in',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText: 'The height of the SKU as per Amazon PDP.'
            },
            {
              type: 'number',
              formatter: 'numeric',
              dimensionLabel: 'Product Rating',
              dimensionColumn: 'product_rating',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText:
                "The rating of the ASIN as per the latest CommerceIQ's market intelligence data."
            },
            {
              type: 'number',
              formatter: 'numeric',
              dimensionLabel: 'Number of Ratings',
              dimensionColumn: 'ratings_count',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText:
                "Number of ratings of the ASIN as per the latest CommerceIQ's market intelligence data."
            },
            {
              type: 'number',
              formatter: 'numeric',
              dimensionLabel: 'Number of Reviews',
              dimensionColumn: 'reviews_count',
              uniqueValueCount: null,
              sortingOrder: ['desc', 'asc'],
              toolTipText:
                "Number of reviews of the ASIN as per the latest CommerceIQ's market intelligence data."
            }
          ])
        );
        if (!this.v2) {
          columnList.unshift({
            dimensionLabel: 'Parent ASIN',
            dimensionColumn: 'parent_asin',
            sort: 'desc',
            uniqueValueCount: null,
            uiType: 'string'
          });
        }
        columnList.unshift({
          dimensionLabel: 'SKU Details',
          dimensionColumn: 'asin',
          uniqueValueCount: null
        });
        const orderByList = this.dataService.savedSortState();
        // Using the saved sort state to compute the column definition
        const sortMapFunc = getSortMapFunc('dimensionColumn', orderByList);
        columnList = columnList.map(sortMapFunc);
        const columnDefs = columnList.map(this.getColumnDefinition);
        return [...columnDefs];
      }
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    }
  },
  watch: {
    skuCheckBox: {
      handler(newVal) {
        if (!newVal?.your && !newVal?.comp) {
        } else {
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    globalSettings: {
      handler() {
        if (this.metadata?.metrics) {
          // this.resetPagination();
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    skuManageColumnEdited(newVal) {
      this.$emit('setSkuManageColumnEdited', newVal);
    }
  },
  created() {
    // AG Grid wants the components to be present as global to recognise it.
    Vue.component('editableTagsCell', editableTagsCell);
    Vue.component('genericTableCell', genericTableCell);
    Vue.component('editableCategoryCell', editableCategoryCell);
    eventBus.$on(this.paginationChangeEvent, (data) => {
      this.tableConfig.body.APIConfig.page = data;
      this.dataService.pagination.page = data;
      this.$emit('updateSkuManageEditMode', false);
      this.tableEditModeChange('view');
      this.$nextTick(() => {
        this.dataService.pagination.limit =
          this.tableConfig.body.APIConfig.limit;
        this.getData();
      });
    });
    eventBus.$on(this.sortChangeEvent, (data, config) => {
      const orderByList = [
        {
          dimensionMapping: data.colDef.sortDimensionMapping,
          dimension: data.colDef.field,
          direction: data.sort.toUpperCase()
        }
      ];
      this.dataService.operations.orderByList = orderByList;
      saveSortingState('skuPage', orderByList);
      this.$emit('updateSkuManageEditMode', false);
      this.tableEditModeChange('view');
      this.dataService.pagination.page = 1;
      this.getData();
    });
    eventBus.$on('setManageSkuTableEditMode', (data) => {
      this.tableEditModeChange(data);
    });
    eventBus.$on('saveManageSkuUpdatedData', () => {
      this.triggerSkuDataSave();
    });
    this.init();
  },
  destroyed() {
    eventBus.$off('setManageSkuTableEditMode');
    eventBus.$off('saveManageSkuUpdatedData');
  },
  methods: {
    onRenameCancel() {
      return null;
    },
    onHeaderAddIconClick() {
      return null;
    },
    onColumnDeleteTrigger() {
      return null;
    },
    onColumnRename() {
      return null;
    },
    async getData() {
      const metricsListDetails = !this.v2
        ? this.metadata.metrics.title
        : this.metadata.metrics.SALES_ESTIMATES;
      if (metricsListDetails.api) {
        this.dataFetched = false;
        this.skuManageColumnEdited = false;
        this.editedColumns = {};
        const payLoad = this.dataService.generatePayload(
          metricsListDetails,
          this.globalSettings,
          this.skuCheckBox
        );
        const apiDetails = cloneDeep(metricsListDetails.api);
        const data = await this.dataService?.getData(payLoad, apiDetails);
        const rowList = data.rows.map((row) => {
          const rowData = row.data
            .map((dataElement) => {
              let name = dataElement.name;
              let result = dataElement.RESULT[name];
              if (
                name.startsWith('category_level_attr_nf_') &&
                result &&
                result !== 'NULL'
              ) {
                result = JSON.parse(result);
                name = result.Name.toLowerCase();
                result = result.Value !== 'NA' ? result.Value : null;
              }
              let resultObj = { [name]: result };
              this.checkForEditedColumn(resultObj, dataElement.RESULT);
              return resultObj;
            })
            .reduce(
              (dataElement, currentValue) =>
                Object.assign(dataElement, currentValue),
              {}
            );
          return {
            asinTitle: rowData.title,
            asinImageURL: rowData.image_url,
            productURL: rowData.product_page_url,
            headSku: rowData.parent_asin,
            ...rowData,
            data: [rowData]
          };
        });
        this.rowList = rowList;
        this.tableConfig.totalRows = data.rows[0].totalEntityCount;
        setTimeout(() => {
          this.tableGridOptions?.api?.sizeColumnsToFit();
        }, 1);
        this.loading = false;
        this.dataFetched = true;
        this.tableEditModeChange('view');
      } else {
        this.dataFetched = true;
      }
    },
    async init() {
      this.loading = true;
      const apiDetails = {
        service: 'DASHBOARD_SERVICE',
        endPoint: '/marketshare/editableColumns'
      };
      const editableColumnsData = await this.dataService?.getEditableColumns(
        {},
        apiDetails
      );
      this.editableColumns = editableColumnsData?.editableColumns || {};
      this.editableCategory.category = true;
      this.getData();
    },
    enocdeApstrophe(rows) {
      rows = rows.map((col) => {
        if (col.dimensionLabel) {
          col.dimensionLabel = col?.dimensionLabel.replace("'", '%27');
        }
        return col;
      });
      return rows;
    },
    decodeApstrophe(rows) {
      rows = rows.map((col) => {
        if (col.dimensionLabel) {
          col.dimensionLabel = col?.dimensionLabel.replace('%27', "'");
        }
        return col;
      });
      return rows;
    },
    getTableInteractiveState(tableEditMode, isEdited) {
      if (tableEditMode === 'edit') {
        return 'edit';
      }
      return isEdited ? 'edited' : 'view';
    },
    mapNumToText(num) {
      const numToText = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth'
      };
      return numToText[num];
    },
    getColumnDefinition(column) {
      if (column.dimensionColumn === 'asin') {
        return {
          sortDimensionMapping: column.sortDimensionMapping,
          showOnUi: true,
          isDownloadable: true,
          headerComponentFramework: 'genericTableHeader',
          title: 'SKU Details',
          headerName: 'SKU Details',
          field: 'asin',
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            component: 'rb-sku',
            eventMap: {
              navigateToSDP: 'navigateToSDP'
            },
            paramsToProps: (params) => {
              return {
                title: params.data.asinTitle,
                asin: params.data.asin,
                'image-url': params.data.asinImageURL,
                'show-image': true,
                'product-page-url': params.data.productURL,
                'enable-s-d-p': true
              };
            }
          },
          minWidth: 250,
          width: 250,
          keyOrder: 1,
          pinned: 'left',
          headerComponentParams: {
            enableHeaderIconInterAction: false,
            enableSorting: false
          }
        };
      } else if (this.editableColumns[column.dimensionColumn]) {
        return {
          sortDimensionMapping: column.sortDimensionMapping,
          showOnUi: true,
          isDownloadable: true,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            props: {
              enableRename: false,
              enableDelete: false,
              displayName: column.dimensionLabel,
              headerCount: 'null',
              fieldName: column.dimensionColumn,
              headerIcon: {
                show: false
              }
            },
            component: 'genericTableHeader',
            enableHeaderIconInterAction: false,
            enableSorting: column.enableSorting ?? true,
            showParentAsinTooltip: column.dimensionColumn === 'parent_asin',
            toolTipText: column.toolTipText
          },
          title: column.dimensionLabel,
          headerName: column.dimensionLabel,
          field: column.dimensionColumn,
          sortingOrder: column.sortingOrder || ['asc', 'desc'],
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            component: 'editableTagsCell',
            paramsToProps: (params) => {
              const column =
                params.colDef.headerComponentParams.props.fieldName;
              const asinData = params.data.data[0];
              const asin = asinData.asin;
              const asinValue = asinData[column];
              let currentEntities = [
                {
                  recordId: asin,
                  title: asinValue
                }
              ];
              let isEdited = false;
              if (asinData[`${column}_updated_value`]) {
                isEdited = true;
                this.skuManageColumnEdited = true;
                currentEntities = [
                  {
                    recordId: asin,
                    title: asinValue,
                    updatedValue: asinData[`${column}_updated_value`]
                  }
                ];
              }
              return {
                metadata: {
                  asin,
                  column,
                  columnLabel:
                    params.colDef.headerComponentParams.props.displayName
                },
                allSuggestionEntities: this.editableColumns[column],
                showDropDown: true,
                interactiveState: this.getTableInteractiveState(
                  this.manageTaxonomyOptions.tableEditMode,
                  isEdited
                ),
                currentEntities: currentEntities,
                editableCells: this.editableCells,
                preferEmitter: true,
                assignable: false,
                editableCellsStyle:
                  'display: flex; align-items: center; justify-content: center; height: auto; padding: 0px',
                searchAction: 'MARKETSHARESKU/marketShareSkuEntitySearch'
              };
            },
            eventMap: {
              tagsChanged: 'catalogTagChange'
            }
          },
          minWidth: this.getColumnWidth(column.dimensionColumn),
          width: this.getColumnWidth(column.dimensionColumn),
          keyOrder: 2,
          pinned: false,
          sort: column.sort
        };
      } else if (this.editableCategory[column.dimensionColumn]) {
        return {
          sortDimensionMapping: column.sortDimensionMapping,
          showOnUi: true,
          isDownloadable: true,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            props: {
              enableRename: false,
              enableDelete: false,
              displayName: column.dimensionLabel,
              headerCount: 'null',
              fieldName: column.dimensionColumn,
              headerIcon: {
                show: false
              }
            },
            component: 'genericTableHeader',
            enableHeaderIconInterAction: false,
            enableSorting: true,
            showParentAsinTooltip: false,
            toolTipText: column.toolTipText
          },
          title: column.dimensionLabel,
          headerName: column.dimensionLabel,
          field: column.dimensionColumn,
          sortingOrder: column.sortingOrder || ['asc', 'desc'],
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            component: 'editableCategoryCell',
            paramsToProps: (params) => {
              const column =
                params.colDef.headerComponentParams.props.fieldName;
              const asinData = params.data.data[0];
              const asin = asinData.asin;
              const asinValue = asinData[column];
              let currentCategory = {
                recordId: asin,
                title: asinValue
              };
              if (
                this.manageTaxonomyOptions.catalogCrudOperations[asin] &&
                this.manageTaxonomyOptions.catalogCrudOperations[asin][column]
              ) {
                currentCategory.title =
                  this.manageTaxonomyOptions.catalogCrudOperations[asin][
                    column
                  ].updatedValue;
              }
              let isEdited = false;
              if (asinData[`${column}_updated_value`]) {
                isEdited = true;
                this.skuManageColumnEdited = true;
                currentCategory = {
                  recordId: asin,
                  title: asinValue,
                  updatedValue: asinData[`${column}_updated_value`]
                };
              }
              return {
                metadata: {
                  asin,
                  column,
                  columnLabel:
                    params.colDef.headerComponentParams.props.displayName
                },
                interactiveState: this.getTableInteractiveState(
                  this.manageTaxonomyOptions.tableEditMode,
                  isEdited
                ),
                currentCategory: currentCategory,
                editableCells: this.editableCells,
                preferEmitter: true,
                editableCellsStyle:
                  'display: flex; align-items: center; justify-content: center; height: auto; padding: 0px',
                selections: this.getCategoryHierarchyForAsin(asinData)
              };
            },
            eventMap: {
              updateCategory: 'updateCatalogCategories'
            }
          },
          minWidth: this.getColumnWidth(column.dimensionColumn),
          width: this.getColumnWidth(column.dimensionColumn),
          keyOrder: 2,
          pinned: false,
          sort: column.sort
        };
      } else {
        return {
          sortDimensionMapping: column.sortDimensionMapping,
          showOnUi: true,
          isDownloadable: true,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            props: {
              enableRename: false,
              enableDelete: false,
              displayName: column.dimensionLabel,
              headerCount: 'null',
              fieldName: column.dimensionColumn,
              headerIcon: {
                show: false
              }
            },
            component: 'genericTableHeader',
            enableHeaderIconInterAction: false,
            enableSorting: column.enableSorting ?? true,
            showParentAsinTooltip: column.dimensionColumn === 'parent_asin',
            toolTipText: column.toolTipText
          },
          cellRendererFramework: 'genericTableCell',
          cellRendererParams: {
            keyType: column.type || 'string',
            formatterFn: formatter
          },
          key: column.dimensionColumn,
          field: column.dimensionColumn,
          title: column.dimensionLabel,
          headerName: column.dimensionLabel,
          sortingOrder: column.sortingOrder || ['asc', 'desc'],
          minWidth: 150,
          width: 150,
          keyOrder: 2,
          pinned: column.dimensionColumn === 'parent_asin',
          sort: column.sort
        };
      }
    },
    checkForEditedColumn(resultObj, data) {
      const columnName = data.updatedColumnValue?.columnName;
      const updatedValue = data.updatedColumnValue?.updatedValue;
      /**
       * if current value and updated value are same we won't show progress bar and message
       */
      if (updatedValue && updatedValue !== data[columnName]) {
        resultObj[`${columnName}_updated_value`] = updatedValue;
        this.editedColumns[columnName] = true;
      }
    },
    tableEditModeChange(mode) {
      this.editableCells = {};
      this.manageTaxonomyOptions.tableEditMode = mode;
      if (mode === 'view') {
        this.clearPendingSaveItems();
      }
    },
    catalogTagChange(newTagsInfo) {
      const { currentValue, metadata, updatedValue } = newTagsInfo;
      if (!this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin]) {
        Vue.set(
          this.manageTaxonomyOptions.catalogCrudOperations,
          metadata.asin,
          {}
        );
      }
      const columnData = {
        currentValue,
        updatedValue: updatedValue.title
      };
      Vue.set(
        this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
        metadata.column,
        columnData
      );
    },
    updateCatalogCategories(newTagsInfo) {
      const { currentValue, metadata, updatedValue } = newTagsInfo;
      const currentNumberOfCategories = currentValue.split('----').length;
      let updatedNumberOfCategories = 0;

      if (!this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin]) {
        Vue.set(
          this.manageTaxonomyOptions.catalogCrudOperations,
          metadata.asin,
          {}
        );
      }
      if (typeof updatedValue !== 'string') return '';

      Vue.set(
        this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
        'unsaved_category_selection',
        updatedValue
      );
      if (updatedValue.includes('----')) {
        updatedValue.split('----').map((category, index) => {
          if (category && category.trim()) {
            const columnData = {
              updatedValue: category
            };
            if (index === 0) {
              Vue.set(
                this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
                'category',
                columnData
              );
            } else {
              Vue.set(
                this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
                `sub_category_${index}`,
                columnData
              );
            }
          }
          updatedNumberOfCategories++;
        });
        if (updatedNumberOfCategories < currentNumberOfCategories) {
          for (
            let i = updatedNumberOfCategories;
            i < currentNumberOfCategories;
            i++
          ) {
            Vue.set(
              this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
              `sub_category_${i}`,
              { updatedValue: 'NA' }
            );
          }
        }
        this.editableCells = {};
      } else if (updatedValue && updatedValue.trim()) {
        // this means the updated selection is a category w/o any sub-categories
        Vue.set(
          this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
          'category',
          { updatedValue: updatedValue }
        );
        for (let i = 1; i < currentNumberOfCategories; i++) {
          Vue.set(
            this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
            `sub_category_${i}`,
            { updatedValue: 'NA' }
          );
        }
      }
    },
    triggerSkuDataSave() {
      this.loading = true;
      const dataUpdatePayload = [];
      const crudPayload = this.manageTaxonomyOptions.catalogCrudOperations;
      Object.keys(crudPayload).forEach((asin) => {
        let updateAttributes = [];
        const asinRecordIdValues = crudPayload[asin];
        Object.keys(asinRecordIdValues).forEach((column) => {
          if (asinRecordIdValues[column].updatedValue) {
            updateAttributes.push({
              columnName: column,
              updatedValue: asinRecordIdValues[column].updatedValue
            });
          }
        });
        if (updateAttributes.length > 0) {
          dataUpdatePayload.push({
            asin,
            updateAttributes
          });
        }
      });
      if (dataUpdatePayload.length === 0) {
        this.tableEditModeChange('view');
        this.loading = false;
        return;
      }
      this.$store
        .dispatch('MARKETSHARESKU/saveSkuDataUpdates', dataUpdatePayload)
        .then((response) => {
          this.tableEditModeChange('view');
          if (!response) {
            this.$snackbar.open({
              message:
                'Something Went Wrong with Update. Please try again later',
              duration: 5000,
              actionText: ''
            });
          }
          if (response.status) {
            this.$logger.logEvent({
              eventType: 'ui_edit',
              entityType: 'Market Share',
              type: 'tags',
              action: 'UPDATE'
            });
          }
          this.getData();
        });
    },
    getColumnWidth(column) {
      return this.editedColumns[column] ? 250 : 150;
    },
    clearPendingSaveItems() {
      this.manageTaxonomyOptions.catalogCrudOperations = {};
    },
    getCategoryHierarchyForAsin(asinData) {
      let selection = asinData.category;
      const asin = asinData.asin;
      const catalogCrudOperations =
        this.manageTaxonomyOptions?.catalogCrudOperations || {};
      if (
        catalogCrudOperations[asin] &&
        catalogCrudOperations[asin].unsaved_category_selection
      ) {
        return [catalogCrudOperations[asin].unsaved_category_selection];
      }
      Object.keys(this.editableCategory).map((columnName) => {
        if (columnName !== 'category' && asinData[columnName]) {
          selection = selection + '----' + asinData[columnName];
        }
      });
      return [selection];
    }
  }
};
</script>

<style lang="css">
.catalog_ingestion_container .upload_file_drop_area {
  max-width: 680px;
}
.catalog_ingestion_container .iq_tip_container {
  max-width: 450px;
}
.catalog_ingestion_container .ingestion_bulk_edit_section {
  padding-top: 6.4rem;
  margin-top: 1.6rem;
}
.catalog_ingestion_container .ingestion_work_panel {
  max-width: 750px;
}
.select_taxonomy_section {
  height: 80%;
  width: 80%;
}
.entity_loader {
  z-index: 1002;
  opacity: 0.7;
}
.catalog_ingestion_container .tag_item_style_class {
  background-color: rgba(255, 168, 0, 0.1);
}
.catalog_ingestion_container .tag_item_style_class:hover {
  background-color: #ffa800;
}
</style>

<style lang="css">
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.u-border-h-s.ag-cell-value.u-border-h-s {
  border-left: solid 1px !important;
  border-right: solid 1px !important;
  border-color: #007cf6 !important;
  opacity: 0.4;
  z-index: 200;
}

.edit-catalog-button .rb-icon::before {
  font-size: 12px;
}
.taxonomy-icon-button:hover {
  color: #333;
}
</style>

<style lang="css">
.manageSKUTable div[col-id^='parent_asin'].ag-cell {
  padding: 0;
}
.manageSKUTable
  div[col-id^='parent_asin'].ag-cell
  .editable-tags-cell-container {
  height: inherit;
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.manageSKUTable
  div[col-id^='parent_asin'].ag-cell
  .editable-tags-cell-container
  .edit-tag-cell-list-item
  .tags_view_cell_container
  .tag_token_container {
  padding-left: 1.6rem;
}
.manageSKUTable div[col-id^='parent_asin'] {
  padding-right: 2.4rem;
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.manageSKUTable div[col-id^='parent_asin'] .ag-cell {
  padding: 0;
}
.manageSKUTable
  div[col-id^='parent_asin']
  .ag-cell
  .editable-tags-cell-container {
  height: inherit;
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.manageSKUTable
  div[col-id^='parent_asin']
  .ag-cell
  .editable-tags-cell-container
  .edit-tag-cell-list-item
  .tags_view_cell_container
  .tag_token_container {
  padding-left: 1.6rem;
}
.manageSKUTable div[col-id^='parent_asin'] .editable-tags-cell-container {
  display: flex;
  align-items: center;
}
.manageSKUTable
  div[col-id^='parent_asin']
  .editable-tags-cell-container
  .edit-tag-cell-list-item
  .tags_view_cell_container
  .tag_token_container {
  background-color: #fff;
}
.manageSKUTable
  div[col-id^='parent_asin']
  .editable-tags-cell-container
  .edit-tag-cell-list-item
  .tags_view_cell_container
  .tag_token_container:hover {
  background-color: #fff;
  color: black;
}
</style>
