<template>
  <div
    class="custom-height u-bg-color-white-base u-width-100 u-spacing-p-l u-display-flex u-flex-direction-column u-flex-justify-content-center u-flex-align-items-center"
  >
    <div class="fillMessage fill--parent u-font-size-3 u-width-100">
      Check downloads!
    </div>
  </div>
</template>

<script>
import { downloadS3Uri } from '@/utils/helpers/downloader';
import urlHelper from '@/utils/helpers/url';

export default {
  created() {
    let s3Uri = this.$route.query.S3Uri;
    if (s3Uri && decodeURIComponent(this.$route.fullPath).includes('&')) {
      s3Uri = decodeURIComponent(/.*S3Uri=(.*)/.exec(this.$route.fullPath)[1]);
      s3Uri = encodeURIComponent(s3Uri);
    }

    if (!s3Uri) {
      s3Uri =
        's3://' +
        this.$route.query.s3Bucket +
        '/' +
        urlHelper.getClientInfoFromUrl().client +
        '/' +
        this.$route.query.s3FilePath;
    }
    downloadS3Uri(s3Uri);
  }
};
</script>

<style scoped lang="css">
.fillMessage {
  text-align: center;
  opacity: 0.75;
}
.custom-height {
  height: 100vh;
}
</style>
