import { eventBus } from '@/utils/services/eventBus';
import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';
import {
  filterOpenerBO,
  featureEnabled
} from '@/components/pages/businessInsights/ms-on-bo';

function fetch(config, searchFilter) {
  if (config.widgets && config.widgets.length > 0) {
    for (var i = 0; i < config.widgets.length; i++) {
      if (
        this.$store._actions &&
        this.$store._actions[config.widgets[i].action]
      ) {
        const _config = config.widgets[i] || {};
        if (searchFilter !== undefined) {
          _config.searchFilter = searchFilter;
        }
        this.$store.dispatch(config.widgets[i].action, _config);
      }
    }
  }

  if (config.tables && config.tables.length > 0) {
    for (i = 0; i < config.tables.length; i++) {
      if (
        this.$store._actions &&
        this.$store._actions[config.tables[i].action]
      ) {
        this.$store.dispatch(config.tables[i].action, config.tables[i] || {});
      }
    }
  }
}

function readFromLocalStorage(lsKey, storeSetter) {
  let _selectedFilters = localStorage.getItem(lsKey) || '{}';
  _selectedFilters = JSON.parse(_selectedFilters);
  for (const i in _selectedFilters) {
    this.$store.dispatch(storeSetter, {
      filterValueKey: i,
      values: _selectedFilters[i]
    });
  }
}

const oObject = {
  computed: {
    getMarketPlace() {
      return this.$store.getters.getMarketPlace;
    },
    outsideIn() {
      return this.$store.getters.getOutsideIn;
    }
  },
  beforeCreate() {
    this.readFromLocalStorage = readFromLocalStorage.bind(this);
  },
  created() {
    if (this.config) {
      // fetch.call(this, this.config);
      if (
        this.config.filters &&
        this.config.filters.search &&
        this.config.filters.search.emit
      ) {
        eventBus.$on(
          this.config.filters.search.emit,
          function (data) {
            fetch.call(this, Vue.util.extend({}, this.config), data);
          }.bind(this)
        );
      }
      if (this.config.filters && this.config.filters.listen) {
        for (var i in this.config.filters.listen) {
          eventBus.$on(
            i,
            function (data, mapping) {
              this.config.mapping = mapping;
              if (this.config.filters.listen[i].transform) {
                this.config = this.config.filters.listen[i].transform(
                  this.config,
                  data,
                  this
                );
              }
              this.$store.dispatch(
                this.config.filters.listen[i].action,
                this.config
              );
            }.bind(this)
          );
        }
      }
    }
  },
  data() {
    return {
      filterData: [],
      primaryFilterData: [],
      secondaryFilterData: {},
      loaderStatus: false
    };
  },
  methods: {
    loadFilter(value) {
      this.loaderStatus = value;
    },
    // page params is added as it is required for saved filters. if page is not undefined, it is added to the payload object.
    // pushToStore, storeActionName Options added for Strategy builder Filter.
    fetchFilters(
      cube,
      page,
      customFunction,
      amsLite,
      pushToStore,
      storeActionName,
      payload = {} // added a parameter to pass extra payload to API
    ) {
      this.loadFilter(true);
      // resetting filter data. Causes a bug in strategy builder while switiching scope, retains old scope data.
      this.filterData = [];
      const that = this;
      if (this.filterV2) {
        return HttpService.post('FILTER_METADATA_V2', {
          cubeName: cube,
          pageName: page
        })
          .then((response) => {
            const data = transformer.getFilterPanelData(
              response?.data?.response
            );
            that.primaryFilterData = data.panelData;
          })
          .finally(() => {
            that.loadFilter(false);
          });
      } else {
        const obj = {
          cubeName: cube,
          ...payload
        };
        let cubeToCall = 'FETCH_FILTERS';
        if (page !== undefined) {
          obj.pageName = page;
          cubeToCall = 'FETCH_FILTERS_V2';
        }
        return HttpLayer.post({
          cube: cubeToCall,
          APIData: obj
        })
          .then((response) => {
            this.processResponse(
              response,
              customFunction,
              amsLite,
              pushToStore,
              storeActionName
            );
          })
          .catch(() => {
            this.loadFilter(false);
          });
      }
    },
    /**
     * Fetchfilter in the custom widgets - Keeping the same contract with fetchFilter
     * @param {*} apiConfig
     * @param {*} customFunction
     * @param {*} amsLite
     * @param {*} pushToStore
     * @param {*} storeActionName
     */
    /**
      please use - src/components/widgetMixin.js for all further pages unless this file is very necessary
      Both file has almost same code. This one should be deprecated in the future after copying necessary methods to widgetMixin.js
     */

    // fetchFilterV2 method consumed in BO for filter integration
    fetchFilterV2(
      apiConfig,
      customFunction,
      amsLite,
      pushToStore,
      storeActionName
    ) {
      this.loadFilter(true);
      return HttpLayer.post({
        urlId: apiConfig.service,
        APIData: apiConfig.request || {},
        header: { append: apiConfig.endPoint }
      })
        .then((response) => {
          this.processResponse(
            response,
            customFunction,
            amsLite,
            pushToStore,
            storeActionName
          );
        })
        .catch(() => {
          this.loadFilter(false);
        });
    },
    processResponse(
      response,
      customFunction,
      amsLite,
      pushToStore,
      storeActionName
    ) {
      if (typeof Worker !== 'undefined') {
        this.$worker
          .run(transformer.mergeResultDimension, [response.data])
          .then((data) => {
            this.filterData = data;
            if (customFunction) {
              const fnc = customFunction();
              this.$worker.run(fnc, [this.filterData]).then((customData) => {
                this.filterData = customData;
              });
            }

            let primaryFilterData;
            if (this.filterV2) {
              const filterPanelData = transformer.getFilterPanelData(
                response?.fullResponse
              );
              primaryFilterData = filterPanelData?.panelData;
            } else {
              primaryFilterData =
                (
                  (response.fullResponse && response.fullResponse.metadata) ||
                  {}
                ).dimensionMappingData || [];
            }

            const dimensionList =
              ((response.fullResponse && response.fullResponse.metadata) || {})
                .groupByDimensionList || [];

            if (amsLite) {
              const excludeList = dimensionList
                .filter((item) => {
                  if (!(item && item.filterProductTag)) {
                    return false;
                  }

                  return (
                    item.filterProductTag.length === 1 &&
                    item.filterProductTag[0] === 'MarketingIQ'
                  );
                })
                .map((value) => {
                  return value.name;
                });

              primaryFilterData = primaryFilterData?.filter((item) => {
                return !excludeList.includes(item.dimensionName);
              });
            }
            primaryFilterData = primaryFilterData?.map((col) => {
              if (col.dimensionLabel) {
                col.dimensionLabel = col.dimensionLabel.replace('%27', "'");
              }
              return col;
            });
            this.primaryFilterData = primaryFilterData;
            if (pushToStore && storeActionName) {
              this.$store.dispatch(storeActionName, {
                primaryData: this.primaryFilterData,
                filterData: this.filterData
              });
            }
            if (featureEnabled(this.$store)) {
              this.primaryFilterData.push(filterOpenerBO);
            }
            this.loadFilter(false);
          });
      } else {
        const data = transformer.mergeResultDimension(response.data);
        this.filterData = data;
        if (customFunction) {
          const fnc = customFunction();
          this.filterData = fnc(data);
        }
        this.primaryFilterData =
          (response.fullResponse?.metadata || {}).dimensionMappingData || [];
        if (pushToStore && storeActionName) {
          this.$store.dispatch(storeActionName, this.primaryFilterData);
        }
        this.loadFilter(false);
      }
    },
    // fetchFilterV3 method consumed in marketshare  for filter integration
    fetchFilterV3(
      apiConfig,
      customFunction,
      amsLite,
      pushToStore,
      storeActionName
    ) {
      this.loadFilter(true);
      const that = this;
      return HttpLayer.post({
        urlId: apiConfig.service,
        APIData: apiConfig.request || {},
        header: { append: apiConfig.endPoint }
      })
        .then((response) => {
          that.filterData = response.fullResponse;
        })
        .catch(() => {
          this.loadFilter(false);
        })
        .finally(() => {
          this.loadFilter(false);
        });
    },
    getComputedFn(widget) {
      return this._computedWatchers[widget.dataSource].getter();
    },
    applyFilter() {
      if (this.config) {
        fetch.call(this, this.config);
      }
    },
    getAllDates() {
      const maxDates = this.$store.getters.getMaxDate;
      const returnDates = {};

      for (const i in maxDates) {
        returnDates[i] = maxDates[i].max_feed_date;
      }

      return returnDates;
    }
  }
};

export default oObject;
