<template>
  <section>
    <div class="u-spacing-ph-m tippy-drop-down u-spacing-pv-l u-font-size-6">
      <div class="u-display-flex u-flex-align-items-center">
        <label
          v-for="(radioSelect, index) in radioSelects"
          :key="index"
          class="u-font-weight-normal u-color-grey-light u-spacing-mb-s u-spacing-mr-m u-display-flex u-flex-align-items-center"
        >
          <input
            type="radio"
            :value="radioSelect"
            :checked="radioSelect.label === internalRadioSelection.label"
            @input.prevent="handleInput"
          />
          <div class="u-spacing-pl-xs">{{ radioSelect.label }}</div>
        </label>
      </div>
      <query-builder
        v-if="hasQueryBuilder"
        :style="{ padding: 0, margin: '24px 0 0 0' }"
        :disable-between-operator="true"
        :apply-changes="queryFilters"
        :hide-metrics="true"
        :add-rules="false"
        :metrics="metrics"
        addtional-metadata="type"
        :selected-rules="[]"
      />
      <div
        v-if="queryErrorMessage"
        class="u-color-red-base u-font-size-7 u-spacing-mt-s"
      >
        {{ queryErrorMessage }}
      </div>
    </div>
    <div
      class="u-spacing-p-m u-display-flex u-flex-justify-content-flex-end u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="cancelOrBack.clickFn"
        :text="cancelOrBack.text"
        :size="'s'"
        :type="'hollow'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
      <rb-button
        :click-fn="nextOrApply.clickFn"
        :disabled="isApplyNextDisabled"
        :text="nextOrApply.text"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
    </div>
  </section>
</template>
<script>
import queryBuilder from '@/components/widgets/queryBuilder.vue';
export default {
  components: {
    queryBuilder
  },
  props: {
    nextStep: {
      type: Object,
      default: () => ({})
    },
    absoluteRange: {
      type: Object,
      default: () => ({})
    },
    hasQueryBuilder: {
      type: Boolean,
      default: false
    },
    ruleBuilderChipConfig: {
      type: Object,
      default: () => ({})
    },
    metrics: {
      type: Object,
      default: () => ({})
    },
    radioSelects: {
      type: Array,
      default: () => []
    },
    selectedRadio: {
      type: Object,
      default: () => ({})
    },
    selectedText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      internalRadioSelection: {},
      internalQuery: null,
      queryHasData: false,
      querySuccess: true,
      queryErrorMessage: null
    };
  },
  computed: {
    cancelOrBack() {
      if (this.currentStepIndex === 0) {
        return {
          clickFn: this.handleCancel,
          text: 'Cancel'
        };
      }
      return {
        clickFn: this.handleBack,
        text: 'Back'
      };
    },
    nextOrApply() {
      if (this.currentStepIndex !== this.lastStepIndex) {
        return {
          clickFn: this.handleNext,
          text: 'Next'
        };
      }
      return {
        clickFn: this.handleApply,
        text: 'Apply'
      };
    },
    isApplyNextDisabled() {
      if (!this.hasQueryBuilder) {
        return !this.isSelectedRadioInList();
      }
      return !(
        this.queryHasData &&
        this.isSelectedRadioInList() &&
        this.querySuccess
      );
    }
  },
  watch: {
    selectedRadio: {
      immediate: true,
      handler(newSelectedRadio) {
        this.internalRadioSelection = JSON.parse(
          JSON.stringify(newSelectedRadio || {})
        );
      }
    }
  },
  methods: {
    isSelectedRadioInList() {
      if (this.internalRadioSelection?.label !== null) {
        const index = this.radioSelects.findIndex(
          (item) => item?.label === this.internalRadioSelection?.label
        );
        return index >= 0;
      } else {
        return false;
      }
    },
    handleInput(evt) {
      this.internalRadioSelection = evt.target._value;
    },
    formRadioPayload() {
      return {
        ...this.internalRadioSelection,
        ...this.ruleBuilderChipConfig.radio,
        infix: {
          text:
            this.internalRadioSelection.value === 'CHANGE' ? ' (Change)' : ''
        }
      };
    },
    emitCurrentRadio(payload) {
      this.$emit('radioSelection', payload);
    },
    formQueryPayload() {
      if (!this.hasQueryBuilder) {
        return;
      }
      const suffix =
        this.internalQuery[0].unit === 'PERCENTAGE' ||
        this.internalQuery[0].unit === '%'
          ? '%'
          : '';
      const infixText =
        this.internalQuery[0].operator.title +
        ' ' +
        this.internalQuery[0]?.value +
        suffix;

      const infix = { text: infixText, class: { color: 'blue-base' } };
      const payload = {
        ...this.internalQuery[0],
        ...this.ruleBuilderChipConfig.query,
        infix
      };
      return payload;
    },
    emitCurrentQuery(payload) {
      if (payload) {
        this.$emit('queryData', payload);
      }
    },
    handleNext() {
      this.emitCurrentRadio();
      this.emitCurrentQuery();
      this.$emit('incStep');
    },
    handleBack() {
      this.$emit('decStep');
    },
    handleCancel() {
      this.$emit('cancel');
    },
    addErrorStateQueryBuilder(message) {
      this.querySuccess = false;
      this.queryErrorMessage = message;
    },
    removeErrorStateQueryBuilder() {
      this.querySuccess = true;
      this.queryErrorMessage = null;
    },
    handleApply() {
      const radioPayload = this.formRadioPayload();
      const queryPayload = this.formQueryPayload();
      if (radioPayload.value === 'ABSOLUTE' && queryPayload) {
        if (queryPayload?.unit === 'PERCENTAGE') {
          if (
            queryPayload?.value &&
            this.absoluteRange.percentage?.max &&
            queryPayload?.value < this.absoluteRange.percentage?.min
          ) {
            this.addErrorStateQueryBuilder(
              'Input cannot be less than ' + this.absoluteRange.percentage?.min
            );
            return;
          }
          if (
            queryPayload?.value &&
            this.absoluteRange.percentage?.max &&
            queryPayload?.value > this.absoluteRange.percentage?.max
          ) {
            this.addErrorStateQueryBuilder(
              'Input cannot be greater than ' +
                this.absoluteRange.percentage?.max
            );
            return;
          }
        } else {
          if (
            queryPayload?.value &&
            this.absoluteRange.percentage?.max &&
            queryPayload?.value < this.absoluteRange.percentage?.min
          ) {
            this.addErrorStateQueryBuilder(
              'Input cannot be less than ' + this.absoluteRange.percentage?.min
            );
            return;
          }
        }
      }

      this.removeErrorStateQueryBuilder();
      this.emitCurrentRadio(radioPayload);
      this.emitCurrentQuery(queryPayload);
      this.$emit('apply');
    },
    queryFilters(data) {
      this.removeErrorStateQueryBuilder();
      if (data?.length) {
        this.queryHasData = true;
      } else {
        this.queryHasData = false;
      }
      this.internalQuery = data;
    }
  }
};
</script>

<style lang="css" scoped>
.tippy-drop-down {
  max-height: 300px;
  overflow-y: scroll;
}
input[type='radio'] {
  vertical-align: baseline;
  margin: 0;
}
</style>
