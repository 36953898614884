<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl u-spacing-pb-xxl">
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <campaignBiddingStrategy
        :default-selection="defaultSelection"
        :step="step"
        @selectedBiddingStrategy="updateBiddingStrategy"
        @updateBid="updateBid"
      />

      <div
        v-if="step.showBidPlacements"
        class="u-flex-align-items-center u-display-flex"
      >
        <CollapsibleTitle
          class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
          title="Adjust bids by placement (replaces Bid+)"
          :is-expanded="bidPlacementExpand"
          title-font-class="u-font-size-5 u-font-weight-600"
          data-cy="bidPlacementExpand"
          @click="() => (bidPlacementExpand = !bidPlacementExpand)"
        />

        <div class="u-spacing-pt-s">
          <div
            class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
            data-cy="optionalTxt"
          >
            Optional
          </div>
        </div>
      </div>

      <div v-if="bidPlacementExpand">
        <div
          class="u-font-size-3 u-font-weight-600 u-text-align-left"
          data-cy="CampaignBidStrtTxt"
        >
          What is the campaign bidding strategy?
        </div>
        <div
          class="u-font-size-5 u-spacing-mt-s u-color-grey-lighter u-line-height-1-3 u-spacing-pb-s"
          data-cy="BidStrtDesc"
        >
          Choose how you want to pay for clicks on your ads.
        </div>
        <div
          class="u-spacing-pt-xl u-font-size-5 u-display-flex u-flex-align-items-center"
        >
          <div
            class="u-flex-direction-column u-font-weight-600 u-spacing-pr-xxl"
          >
            <div data-cy="topOfSearch">Top of search (first page):</div>
            <div class="u-spacing-pt-s">
              <div
                v-if="isCreateAdGroupFlow"
                class="u-font-weight-400 u-spacing-mt-s"
                data-cy="topOfSearchPercentage"
              >
                {{ topOfSearchPercentageForCreateAdGroup }}
              </div>
              <rbNumberInput
                v-else
                :options="topOfSearch"
                @handleInput="updateTopOfSearch"
                @handleValue="updateTopOfSearch"
              />
            </div>
          </div>
          <div class="u-spacing-pt-s">
            <div
              class="u-color-grey-lighter u-font-size-5 u-spacing-pl-xxl u-border-color-grey-xxx-light u-border-left u-spacing-pv-m"
              data-cy="topOfSearchBidTargetDesc"
            >
              Example: A $0.75 bid will remain $0.75 for this placement.
            </div>
          </div>
        </div>
        <div class="u-spacing-pt-xl u-font-size-5 u-display-flex">
          <div
            class="u-flex-direction-column u-font-weight-600 u-spacing-pr-xxl"
          >
            <div data-cy="ProductPageTxt">Product pages:</div>
            <div class="u-spacing-pt-s">
              <div
                v-if="isCreateAdGroupFlow"
                class="u-font-weight-400 u-spacing-mt-s"
                data-cy="ProductPagePercentage"
              >
                {{ productPagesPercentageForCreateAdGroup }}
              </div>
              <rbNumberInput
                v-else
                :options="productPages"
                @handleInput="updateProductPages"
                @handleValue="updateProductPages"
              />
            </div>
          </div>
          <div class="u-spacing-pt-s">
            <div
              class="u-color-grey-lighter u-font-size-5 u-spacing-pl-xxl u-border-color-grey-xxx-light u-border-left u-spacing-pv-m"
              data-cy="productpageBidTargetDesc"
            >
              Example: A $0.75 bid will remain $0.75 for this placement.
            </div>
          </div>
        </div>
      </div>

      <createCampaignFooter
        :disable-right-btn="false"
        :btn-right-text="
          isCreateAdGroupFlow
            ? 'Create Ad Group'
            : step.boolLastStep
            ? 'Save'
            : 'Next'
        "
        class="u-margin-top-40"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import rbNumberInput from '@/components/widgets/rbNumberInput';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import campaignBiddingStrategy from './campaignBiddingStrategy';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';

export default {
  components: {
    rbNumberInput,
    createCampaignHeader,
    createCampaignFooter,
    campaignBiddingStrategy,
    CollapsibleTitle
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    topOfSearch: {
      prefix: '%',
      value: 0
    },
    productPages: {
      prefix: '%',
      value: 0
    },
    bidAdjustment: {
      value: 1
    },
    suggestedCampaignIndex: 0,
    bidPlacementExpand: false,
    defaultSelection: null,
    biddingStrategyMap: {
      legacyForSales: 'Dynamic bids - down only',
      autoForSales: 'Dynamic bids - up and down',
      manual: 'Fixed bids',
      reach: 'Optimize for viewable Impressions',
      clicks: 'Optimize for page visits',
      conversions: 'Optimise for conversions'
    }
  }),
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues?.getter];
    },
    isCreateAdGroupFlow() {
      if (this.$route.name === 'create_ad_group') {
        return true;
      } else {
        return false;
      }
    },
    topOfSearchPercentageForCreateAdGroup() {
      let percentage = 0;
      const adjustments =
        this.allStepsData?.campaignDetails?.bidding?.adjustments;
      if (adjustments.length > 0) {
        const placementTopIndex = adjustments.findIndex(
          (item) => item.predicate === 'placementTop'
        );
        if (placementTopIndex !== -1) {
          percentage = adjustments[placementTopIndex].percentage;
        }
      }
      return `${percentage}%`;
    },
    productPagesPercentageForCreateAdGroup() {
      let percentage = 0;
      const adjustments =
        this.allStepsData?.campaignDetails?.bidding?.adjustments;
      if (adjustments.length > 0) {
        const placementProductPageIndex = adjustments.findIndex(
          (item) => item.predicate === 'placementProductPage'
        );
        if (placementProductPageIndex !== -1) {
          percentage = adjustments[placementProductPageIndex].percentage;
        }
      }
      return `${percentage}%`;
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const currentCampaign =
      this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
    this.defaultSelection = currentCampaign?.biddingStrategy;
    this.productPages.value = currentCampaign?.productPages || 0;
    this.topOfSearch.value = currentCampaign?.topOfSearch || 0;
    this.bidAdjustment.value = currentCampaign?.adgroupBid || 1;
    this.step.bidAdjustment = this.bidAdjustment;
    this.setStepDescription(this.defaultSelection);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    updateProductPages(value) {
      this.productPages.value = value;
      this.updatePlacement(null, value, null);
    },
    updateTopOfSearch(value) {
      this.topOfSearch.value = value;
      this.updatePlacement(value, null, null);
    },
    updateBid(value) {
      this.bidAdjustment.value = value;
      this.updatePlacement(null, null, value);
    },
    setStepDescription(value) {
      this.step.description = this.biddingStrategyMap?.[value];
    },
    updatePlacement(topOfSearch, productPages, bid) {
      const allStepsData = { ...this.allStepsData };
      if (topOfSearch) {
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].topOfSearch = topOfSearch;
      }
      if (productPages) {
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].productPages = productPages;
      }
      if (bid) {
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].adgroupBid = bid;
      }
      this.$store.dispatch(this.selectedValues?.action, { ...allStepsData });
    },
    updateBiddingStrategy(value) {
      const allStepsData = { ...this.allStepsData };
      if (
        allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
          .biddingStrategy
      ) {
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].biddingStrategy = value;
      }
      this.setStepDescription(value);
      this.$store.dispatch(this.selectedValues?.action, { ...allStepsData });
    },
    nextStep() {
      if (this.isCreateAdGroupFlow) {
        this.$parent.$emit('createAdGroup');
        return;
      }
      if (this.step?.boolLastStep) {
        const query = {
          edit: true
        };
        if (this.$route?.query?.manual) {
          query.manual = true;
        }
        this.$router.push({
          name: 'setup_suggested_campaign',
          query
        });
      }
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style scoped></style>
