import AdGroupAndSkusIndex from '@/components/ams/campaign_creation/walmart_steps/adGroup/index.vue';
import { DataService } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupDataService.js';
import { adGroupCustomActionPanelForAutoSP } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import { getRedirectToFunctionDefinition } from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';

export const addSkuSpAuto = {
  name: 'Products',
  description: '',
  targetingType: ['product', 'keyword'],
  customActionPanelCustomState: adGroupCustomActionPanelForAutoSP(
    'sp_auto',
    'create_adgroup'
  ),
  header: {
    title: 'What are the Products you would like to add?',
    desc: ['Add Products you want to promote in this campaign.']
  },
  key: 'adgroup',
  keyToRead: 'skus',
  breadCrumbTitle: 'Products',
  textMapping: 'skus',
  hideAdgroup: true,
  component: AdGroupAndSkusIndex,
  dataService: DataService,
  isCreateAdGroup: true,
  btnRightText: 'Create Ad Group',
  skusCountValidationObject: {
    min: 1,
    max: 2000
  },
  skuBidValidationObject: {
    min: 0.2,
    message:
      'The minimum bid for products in Auto campaign products should be $0.2.'
  },
  redirectTo: getRedirectToFunctionDefinition('walmartEntityDetails')
};
export default {
  name: 'Products',
  description: '',
  targetingType: ['product', 'keyword'],
  customActionPanelCustomState: adGroupCustomActionPanelForAutoSP(
    'sp_manual',
    'create_adgroup'
  ),
  header: {
    title: 'What are the Products you would like to add?',
    desc: ['Add Products you want to promote in this campaign.']
  },
  key: 'adgroup',
  keyToRead: 'skus',
  breadCrumbTitle: 'Products',
  textMapping: 'skus',
  hideAdgroup: true,
  component: AdGroupAndSkusIndex,
  dataService: DataService,
  isCreateAdGroup: true,
  skusCountValidationObject: {
    min: 1,
    max: 2000
  }
};
