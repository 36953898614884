<template>
  <SettingsPopup
    :key="key"
    class="u-width-100"
    :types="types"
    :placements="placements"
    :selected-type="selectedType"
    :selected-placement="selectedPlacement"
    :selected-keyword-type="selectedKeywordType"
    :brands-options="brandsOptions"
    :selected-brand="anchoredBrand"
    :keyword-type="keywordType"
    :selected-crawl-type="selectedCrawlType"
    :crawl-type-options="crawlTypeOptions"
    :enable-crawl-type-select="enableCrawlTypeSelect"
    @previousAnchorBrand="handlePreviousAnchorBrand"
    @setAnchoredBrand="handleSetBrand"
    @setType="handleSovSetType"
    @setPlacement="handleSovPlacement"
    @setCrawlType="handleCrawlType"
    @apply="handleApply"
    @cancel="handleCancel"
    @setKeywordType="handleKeywordType"
  />
</template>

<script>
import SettingsPopup from '@/components/pages/insights/amazon/share-of-voice/molecules/settings-popup.vue';
export default {
  components: {
    SettingsPopup
  },
  props: {
    anchoredBrand: {
      type: Object,
      default: () => ({})
    },
    brandsOptions: {
      type: Array,
      default: () => []
    },
    selectedKeywordType: {
      type: Array,
      default: () => []
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    namespace: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    selectedType: {
      type: Array,
      default: () => []
    },
    selectedPlacement: {
      type: Object,
      default: () => ({})
    },
    selectedCrawlType: {
      type: Object,
      default: () => ({})
    },
    crawlTypeOptions: {
      type: Array,
      default: () => []
    },
    enableCrawlTypeSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: 0,
      id: null,
      isTippyActive: null,
      tippyOptions: {},
      tippyInstance: null,
      internalUnsavedState: {}
    };
  },
  created() {
    this.id = 'dropdown-' + this._uid;
  },
  beforeDestroy() {
    this.tippyInstance?.destroy();
  },
  methods: {
    handlePreviousAnchorBrand(payload) {
      this.$emit('previousAnchorBrand', payload);
    },
    getCurrentUnsaved() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'][
        this.widgetName
      ];
    },
    pushToStateStore(data, storeKey = 'pushToUnsavedState') {
      const unsavedState = this.getCurrentUnsaved();
      const newUnsavedState = { ...unsavedState, ...data };
      this.$emit(storeKey, { [this.widgetName]: newUnsavedState });
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    handleApply(data) {
      this.$emit('apply', data);
    },
    handleCancel(data) {
      this.key++;
      this.$emit('cancel', data);
    },
    handleSetBrand(data) {
      const payload = { anchoredBrand: data };
      this.pushToStateStore(payload);
    },
    handleSovSetType(data) {
      if (data.length > 0) {
        const payload = { selectedType: data };
        this.pushToStateStore(payload);
      } else {
        this.openSnackbar('No SOV Type Selected!!!');
      }
    },
    handleKeywordType(data) {
      if (data.length > 0) {
        const payload = { selectedKeywordType: data };
        this.pushToStateStore(payload);
      } else {
        this.openSnackbar('No Keyword Type Selected!!!');
      }
    },
    handleSovPlacement(data) {
      const payload = { selectedPlacement: data };
      this.pushToStateStore(payload);
    },
    handleCrawlType(data) {
      this.$emit('setCrawlType', data);
    }
  }
};
</script>

<style lang="css" scoped>
.padding {
  padding: 7px;
}
</style>
