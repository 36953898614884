import { BaseConfig } from '@/pages/dashboard_service_campaign_management/types/index';
import {
  UtilServiceForConfig,
  nativeRetailers
} from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';
import {
  createRestoreOrDeleteSkuPayloadActionPayload,
  dataDiscrepancyVHtmlText
  // @ts-ignore
} from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils.js';
// @ts-ignore
import HttpService from '@/utils/services/http-service';
const chartingWb = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  // @ts-ignore
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  // @ts-ignore
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  // @ts-ignore
  import('@/components/widgets/collaboration/collaboration.vue');
const iconHeader = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const EditBid = () =>
  // @ts-ignore
  import('@/pages/dashboard_service_campaign_management/promoteiq/editBid.vue');

const PRIMARY_KEY = 'sku_id';

export function adSkusConfig(retailer: string): any {
  const listApiPageName = `${retailer}_cm_skus`;
  const chartWidgetName = `${retailer}_cm_skus_charting_wb`;
  const tableWidgetName = `${retailer}_cm_skus_table`;
  const localStorageFilterKey = `${retailer}-skus-filter`;
  const localStorageFilterOrderKey = `${retailer}-skus-filter-order`;
  const tabName = 'Ad SKUs';

  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  let aggregator = nativeRetailers.includes(retailer) ? retailer : 'promoteiq';
  const configToReturn: BaseConfig = {
    PRIMARY_KEY,
    entity: 'adskus',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: {
      ...utilServiceForConfig.getFilterSettings({
        backendCube: `${aggregator}_campaigns_sku_workbench`,
        searchDimension: 'search',
        searchInputPlaceHolder: 'Search for a SKU',
        bulkSearchStringConstants: {
          dimensionNameMapping: 'sku',
          findEntityInputPlaceholder: 'Find a SKU',
          subTitle: 'SKUs'
        },
        enableTaxonomyFilters: true
      }),
      extraCalendarInfoVHtmlText: dataDiscrepancyVHtmlText
    },
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      EditBid: EditBid,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),
    // @ts-ignore
    headerOptions: function (context: any) {
      const isRetailerKroger = retailer === 'kroger_promoteiq';
      const suggestedBidPreText = isRetailerKroger ? 'Kroger ' : '';
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: {
          props: {
            ...utilServiceForConfig.getTableWidgetProps(context),
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    utilServiceForConfig.getPlotRowsActionObject(),
                    {
                      icon: 'task-status',
                      title: 'Edit Status',
                      id: 'edit-status',
                      dropDown: true,
                      dropDownOptions: [
                        { title: 'Restore', value: 'Restore' },
                        { title: 'Delete', value: 'Delete' }
                      ],
                      async handler(
                        selectionLength: number,
                        dropDownSelection: any,
                        selections: any[]
                      ) {
                        const payload = [] as any;
                        selections.forEach((selectedSkuRow) => {
                          const tempPayload =
                            createRestoreOrDeleteSkuPayloadActionPayload(
                              selectedSkuRow,
                              dropDownSelection[0].value,
                              aggregator,
                              retailer
                            );
                          payload.push(tempPayload);
                        });
                        try {
                          await HttpService.post(
                            'AMS_ACTIONS_ACTION_LOG',
                            payload
                          );
                          // @ts-ignore
                          this.$emit(
                            'openSnackbar',
                            'Action has been submitted'
                          );
                        } catch (err) {
                          console.log(err);
                          // @ts-ignore
                          this.$emit('openSnackbar', 'Something went wrong.');
                        }
                      }
                    },
                    {
                      icon: 'dollar',
                      title: 'Edit Bid',
                      id: 'edit-bid',
                      customState: {
                        component: 'editBid',
                        props: {
                          title: 'Edit Bid',
                          selectedEntity: 'SKU',
                          actionType: `${retailer}ProductBidChange`,
                          showInputTitle: true,
                          aggregator: aggregator,
                          getSingleSelectBidSuggestions: (
                            editBidComponentRef: never
                          ) => ({
                            show: true,
                            multipleSuggestions: false,
                            title: `${suggestedBidPreText}Suggested max bid`,
                            value:
                              // @ts-ignore
                              editBidComponentRef.selections?.[0]?.data?.[
                                `${aggregator}_cm_sku_max_suggested_bid`
                              ].toString(),
                            secondTitle: `${suggestedBidPreText}Suggested min bid`,
                            secondValue:
                              // @ts-ignore
                              editBidComponentRef.selections?.[0]?.data?.[
                                `${aggregator}_cm_sku_min_suggested_bid`
                              ].toString(),
                            currentBid: null,
                            currentBidNote: '',
                            suggestedBidNote: '',
                            secondSuggestedBidNote: ''
                          }),
                          getMultiSelectBidSuggestions: (_: never) => ({
                            show: true,
                            value: 'XX',
                            valueKey: `${aggregator}_cm_sku_suggested_bid`,
                            secondValue: 'ZZ',
                            secondValueKey: `${aggregator}_cm_sku_minimum_bid`,
                            currentBid: null,
                            title: 'Suggested max bid',
                            secondTitle: 'Suggested min bid',
                            multipleSuggestions: false,
                            currentBidNote: '',
                            suggestedBidNote:
                              '**XX - Respective suggested bid in the table below',
                            secondSuggestedBidNote:
                              '**ZZ - Respective suggested bid in the table below'
                          }),
                          currentBidKey: `${aggregator}_cm_sku_current_bid`,
                          defaultMinBid: 0.1,
                          minBidKey: `${aggregator}_cm_sku_minimum_bid`,
                          radioConfig: [
                            {
                              title: 'Set new bid',
                              type: 'input',
                              value: 'set_new'
                            },
                            {
                              title: 'Increase bid',
                              value: 'increase',
                              type: 'dropdown',
                              dropdown: [
                                {
                                  label: 'Change by percentage',
                                  value: 'percentage'
                                },
                                {
                                  label: 'Change absolute value',
                                  value: 'absolute_value'
                                }
                              ]
                            },
                            {
                              title: 'Decrease bid',
                              value: 'decrease',
                              type: 'dropdown',
                              dropdown: [
                                {
                                  label: 'Change by percentage',
                                  value: 'percentage'
                                },
                                {
                                  label: 'Change absolute value',
                                  value: 'absolute_value'
                                }
                              ]
                            }
                          ],
                          async handleApply(vueRef: any, payload: any) {
                            try {
                              await HttpService.post(
                                'AMS_ACTIONS_ACTION_LOG',
                                payload
                              );
                              vueRef.$emit('afterAPIActionToCall');
                            } catch (err) {
                              vueRef.openSnackbar('Something went wrong.');
                            }
                          }
                        },
                        events: {
                          closePanelMethod() {
                            // @ts-ignore
                            this.removeCustomCustomComponents();
                          },
                          afterAPIActionToCall(payload: any) {
                            // @ts-ignore
                            this.$emit(
                              'openSnackbar',
                              'Action has been submitted'
                            );
                            // @ts-ignore
                            this.removeCustomCustomComponents();
                            // @ts-ignore
                            this.closeActionsWorkflow();
                          }
                        }
                      }
                    }
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections: any) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
                context
              )
            ],
            enableManageColumns: !context.rowSelections.length
          }
        }
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('amsSkus'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail()
  };
  return configToReturn;
}
