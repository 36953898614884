<template>
  <div
    class="u-position-relative u-display-flex u-flex-direction-column u-flex-1"
  >
    <loader
      v-if="loading"
      class="entity_loader u-color-blue-base fill--parent"
      :color="'#3684bb'"
      :loading="loading"
    />
    <span
      class="u-display-inline-flex u-flex-justify-content-space-between u-flex-align-items-center u-spacing-mh-xl"
    >
      <slot
        v-if="showSearch"
        name="left_action_header"
      >
        <span class="u-spacing-mh-m">
          <rb-search
            :placeholder="searchPlaceholder"
            @onSearchTrigger="$emit('onSearchTrigger', $event)"
          />
        </span>
      </slot>
      <slot name="action_header" />
    </span>
    <span v-if="showFilter">
      <rb-filter-panel
        id="standard_catalog"
        class="u-spacing-ml-xxl"
        v-bind="filterProps"
      />
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
      />
    </span>
    <span>
      <ingestionProgress :show-progress="entityProcessiongStatus" />
    </span>
    <slot name="banner" />
    <slot name="table_header" />
    <div class="taxonomy-edit-table u-overflow-y-auto">
      <rb-insights-table
        :config="tableConfig"
        :table-column="columnDefs"
        :show-pagination-options="true"
        :row-class-rules="gridOptions.rowClassRules"
        :table-row="allRows"
        :grid-options="gridOptions"
        :default-col-def="gridOptions.defaultColDef"
        :pagination="true"
        :get-row-height="getRowHeight"
        :pagination-total-key="tableConfig.totalRows"
        :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
        :pagination-change-event="paginationChangeEvent"
        :sorting-change-event="sortingChangeEvent"
        :table-header-sticky-obj="tableHeaderStickyObj"
        :row-per-page-list="rowPerPageList"
        :row-selection="tableConfig.rowSelection || {}"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import rbSearch from '@/components/widgets/search.vue';
import widgetsUtil from '@/components/widgetsMixin';
import ingestionProgress from '@/pages/toolsAndSettings/entityTagging/common/ingestionProgress.vue';
import loader from '@/components/basic/loader';
import rbFilterPanel from '@/components/widgets/filter-panel';
import { eventBus } from '@/utils/services/eventBus';
import {
  Entities,
  EntitiesForPostProcessingStatus
} from '@/pages/toolsAndSettings/const.js';
export default {
  components: {
    loader,
    rbSearch,
    rbFilterPanel,
    ingestionProgress
  },
  mixins: [widgetsUtil],
  props: {
    paginatedTable: {
      type: Boolean,
      default: false
    },
    paginationChangeEvent: {
      type: String,
      default: undefined
    },
    sortingChangeEvent: {
      type: String,
      default: undefined
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    allRows: {
      type: Array,
      default() {
        return [];
      }
    },
    gridOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    columnDefs: {
      type: Array,
      default() {
        return [];
      }
    },
    mode: {
      type: String,
      // enum of 'edit' and 'view'
      default: 'view'
    },
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    tableConfig: {
      type: Object,
      default: {}
    },
    tableHeaderStickyObj: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowPerPageList: {
      type: Array,
      default: []
    },
    showActiveFilterBanner: {
      type: Boolean,
      default: true
    },
    entityConfig: {
      type: Object,
      default: () => ({
        entityName: Entities.catalog,
        filterCubeName: 'client_workbench_full_catalogue_latest_comp_data',
        filterConfig: 'feature.filter_v2.enable'
      })
    }
  },
  data() {
    return {
      config: {
        filters: {
          emit: 'updateEntityFilters'
        }
      },
      dependentEntities: [
        EntitiesForPostProcessingStatus[this.entityConfig.entityName]
      ],
      filterEventAndLocalStorageKey: `${
        EntitiesForPostProcessingStatus[this.entityConfig.entityName]
      }-filter`,
      filterV2:
        Vue.options.filters.config_check(this.entityConfig.filterConfig) ||
        false,
      filterInstance: null
    };
  },
  computed: {
    entityProcessiongStatus() {
      return this.$store.getters.getEntityPostProcessingStatus(
        this.dependentEntities
      );
    },
    filterProps() {
      return {
        'hide-filter': true,
        'enable-remove-all': false,
        'enable-save': false,
        'has-search': false,
        'disable-between-operator': true,
        'show-ingestion-progress': true,
        'dependent-entities': this.dependentEntities,
        'listener-event': this.filterEventAndLocalStorageKey,
        data: this.filterData,
        primary: this.primaryFilterData,
        'default-date-post-range': 'last60Days',
        config: this.config.filters,
        emit: this.config.filters.emit,
        'enable-client-side-sorting': false,
        'enable-server-side-sorting': false,
        'filter-v2': this.filterV2,
        'show-filter-icon': false,
        'on-create': this.onCreate
      };
    }
  },
  watch: {
    gridOptions: {
      handler: (nv) => {
        console.log(nv);
      },
      immediate: true
    },
    tableConfig(nv) {}
  },
  created() {
    this.fetchFilters(this.entityConfig.filterCubeName);
    eventBus.$on('updateEntityFilters', (data) => {
      this.$emit('filtersApplied', data);
      // clear table row selections on filter update
      this.gridOptions?.api?.deselectAll();
    });
  },
  methods: {
    onCreate(instance) {
      this.filterInstance = instance;
    },
    getRowHeight(params) {
      return (params.data.data.length || 1) * 70;
    }
  }
};
</script>

<style lang="css">
.taxonomy-edit-table .rb-insights-table-container {
  height: auto;
}
.taxonomy-edit-table .paginationFooter {
  padding: 1.6rem 6.4rem;
}
.taxonomy-edit-table div[col-id^='dimension'][class*='ag-cell'] {
  padding: 0px;
}
.taxonomy-edit-table
  div[col-id='newColumn'][class='ag-header-cell ag-header-cell-sortable'] {
  border-left: solid 1px !important;
  border-right: solid 1px !important;
  border-color: #007cf6 !important;
  z-index: 200;
  background-color: white;
}
</style>
