<template>
  <section class="u-spacing-mh-m u-spacing-mv-m">
    <div class="u-display-flex u-flex-align-items-center">
      <span
        class="graph-header u-color-grey-mid-light u-font-weight-400 u-font-size-6 u-display-flex u-line-height-1.3"
      >
        <span>
          <rb-icon
            icon="info-circle-fill"
            class="u-spacing-mr-xs rb-icon--medium u-display-flex u-flex-align-items-center u-icon-size-6 u-flex-justify-content-center u-flex-shrink-0 u-color-grey-lighter"
          />
        </span>
        <span>{{ graphHeader }}</span>
      </span>
    </div>
    <div class="category-card-row u-spacing-pt-s u-display-flex">
      <div
        v-for="item in configArray"
        :key="item.name"
        class="u-width-100"
      >
        <div
          class="category-card u-bg-color-grey-white box-shadow u-display-flex u-flex-justify-content-space-between u-flex-direction-row u-border-radius-s u-spacing-pv-m u-spacing-ph-l"
        >
          <div class="u-display-flex u-flex-direction-column">
            <span
              class="u-font-size-5 u-white-space-no-wrap u-color-grey-mid-light u-font-weight-400"
            >
              {{ item.displayName }}
            </span>
            <metric
              v-if="metricsDataResponse"
              :hide-zero="false"
              size="s"
              class="u-display-inline-flex u-spacing-mt-s"
              :config="item.metricConfig || {}"
              :data="
                isMetricLoading
                  ? { tag1: 'loading' }
                  : metricBundledDataForCategory[item.name] || {}
              "
            />
            <span
              v-else
              class="u-spacing-mt-m u-font-size-5 u-color-grey-lighter"
            >
              Error, Try later!
            </span>
          </div>
          <div
            class="chart-component u-display-flex u-flex-align-items-center u-flex-justify-content-center"
          >
            <div
              v-if="
                timeSeriesDataResponse[item.name] || isChartLoading[item.name]
              "
            >
              <Loader
                v-if="isChartLoading[item.name]"
                :loading="isChartLoading[item.name]"
                class="u-bg-color-grey-white"
                color="#3fadf7"
                size="2rem"
                thickness=".2rem"
              />
              <chartWithGradient
                v-else
                :key="item.name"
                :metric-data="chartDataForCategory[item.name].timeSeriesData"
                :metadata="{ name: item.name }"
                :card-primary-metric-color-code="chartColor(item.name)"
                :metrics-list="item.metricsList"
                :chart-config="item.chartConfig"
              />
            </div>
            <div v-else>
              <span
                class="u-font-size-5 u-color-grey-lighter u-text-align-center"
              >
                Error, Try later!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  updateTimeSelectionForChart,
  categoryInCardConstants,
  msProConstants,
  makeBundledRequest,
  updateRequestFormat
} from '@/components/pages/insights/amazon/market-share/utils';
import metric from '@/components/basic/metric.vue';
import HttpService from '@/utils/services/http-service';
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';

export default {
  components: { metric, chartWithGradient, Loader },
  props: {
    globalViewId: {
      type: Number,
      required: true
    },
    pageId: {
      type: Number,
      required: true
    },
    page: {
      type: String,
      default: ''
    },
    widget: {
      type: String,
      default: ''
    },
    globalSettings: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      entityTypeCategory: 'category',
      graphHeader:
        "In the cards below, the chart reflects the category's performance trend over the past 4 weeks, using data from the last selected date.",
      noOfWeeksInChart: 4,
      isMetricLoading: true,
      isChartLoading: {
        categorySize: true,
        marketShareMetric: true,
        sales: true
      },
      readApiResponse: {},
      metricsDataResponse: {},
      timeSeriesDataResponse: [],
      chartDataForCategory: {},
      metricBundledDataForCategory: {},
      metricDataGroupMapping: {},
      displayShareInValue:
        this.globalSettings.settingValue?.displayShareIn.label,
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false } },
        legend: false,
        size: { height: 43 },
        tooltip: false,
        point: false,
        line: { connectNull: true },
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        regions: {},
        timeseries: 'week_start_date',
        tooltip_position: function (a, b, c, d) {
          return { top: -45, left: -20 };
        }
      },
      metricConfig: {
        invertTag2: false,
        tag1Unit: {
          pre: '',
          suff: '',
          roundoff: 2
        },
        tag2Unit: {
          suff: '%',
          roundoff: 2
        }
      }
    };
  },
  computed: {
    configArray() {
      let prefixVal = '';
      if (this.displayShareInValue === 'Dollars') {
        prefixVal = 'currency';
      }
      return [
        {
          name: 'categorySize',
          displayName: 'Total Category Size',
          metricConfig: { ...this.metricConfig, tag1Unit: { pre: prefixVal } },
          chartConfig: {
            ...this.chartConfig,
            tooltip_format: {
              'Total Category Size': { pre: prefixVal, roundoff: 2, suff: '' }
            }
          },
          metricsList: [
            {
              label: 'Total Category Size',
              alias: 'categorySize'
            }
          ]
        },
        {
          name: 'marketShareMetric',
          displayName: 'My Overall Market Share',
          metricConfig: { ...this.metricConfig, tag1Unit: { suff: '%' } },
          chartConfig: {
            ...this.chartConfig,
            tooltip_format: {
              'Market Share': { pre: '', roundoff: 2, suff: '%' }
            }
          },
          metricsList: [
            {
              label: 'Market Share',
              alias: 'marketShareMetric'
            }
          ]
        },
        {
          name: 'sales',
          displayName: 'My Overall Sales',
          metricConfig: { ...this.metricConfig, tag1Unit: { pre: prefixVal } },
          chartConfig: {
            ...this.chartConfig,
            tooltip_format: {
              'My Overall Sales': { pre: prefixVal, roundoff: 2, suff: '' }
            }
          },
          metricsList: [
            {
              label: 'My Overall Sales',
              alias: 'sales'
            }
          ]
        }
      ];
    }
  },
  watch: {
    globalSettings: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.fetchData();
          this.fetchTimeSeriesData();
        }
        this.displayShareInValue =
          this.globalSettings.settingValue?.displayShareIn.label;
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.fetchMetadata();
  },
  methods: {
    chartColor(metricKey) {
      const chartDataForMetric =
        this.chartDataForCategory[metricKey].timeSeriesData[0];
      const metricName = chartDataForMetric.name;
      const { TIMESERIES } = chartDataForMetric;

      if (TIMESERIES === null) {
        return { [metricKey]: msProConstants.chartColors.gray };
      } else {
        const noOfWeeksInChart = TIMESERIES.length;
        const initialMetricValue = TIMESERIES[0]?.[metricName];
        const finalMetricValue = TIMESERIES[noOfWeeksInChart - 1]?.[metricName];

        if (finalMetricValue < initialMetricValue) {
          return { [metricKey]: msProConstants.chartColors.red };
        } else if (finalMetricValue > initialMetricValue) {
          return { [metricKey]: msProConstants.chartColors.green };
        } else {
          return { [metricKey]: msProConstants.chartColors.gray };
        }
      }
    },
    async fetchMetadata() {
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      try {
        const response = await HttpService.post('DASHBOARD_SERVICE', body, {
          append: '/widget/metadata/read'
        });
        if (response.status === 200) {
          this.readApiResponse = response.data;
          await this.fetchData();
          await this.fetchTimeSeriesData();
        }
      } catch (error) {
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },

    async fetchData() {
      this.isMetricLoading = true;
      const metricArray = this.getMetricDetails(
        this.readApiResponse.metrics,
        this.readApiResponse.metadata
      );
      const dataRequest = makeBundledRequest(
        this.readApiResponse.metadata.dataAPI.request,
        metricArray,
        this.globalSettings,
        this.entityTypeCategory
      );
      try {
        const response = await HttpService.post('MARKETSHARE', dataRequest, {
          append: '/marketshare/metrics/data/exec'
        });
        if (response.status === 200) {
          this.metricsDataResponse = response.data;
          this.getMappingForMetrics(this.metricsDataResponse.entityData);
        }
      } catch (error) {
        this.metricsDataResponse = null;
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },

    buildUIMetricMapping(metricDataGroupMapping) {
      const prefix = categoryInCardConstants.msPro_prefix;
      this.uiMetricMapping = {};
      for (const metricKey in metricDataGroupMapping) {
        const metricNamewithPrefix =
          metricDataGroupMapping[metricKey].brandServiceKey;
        const metricName = metricNamewithPrefix.replace(
          new RegExp(`^${prefix}`),
          ''
        );
        this.uiMetricMapping[metricName] = metricKey;
      }
    },

    getMappingForMetrics(categoryData) {
      const metrics = categoryInCardConstants.metrics;
      const keys = Object.keys(metrics);
      let dataObject = {};
      for (let j = 1; j <= keys.length; j++) {
        const metricName = categoryData[0].data[j].name;
        const resultValue = categoryData[0].data[j].RESULT[metricName];
        const metricUnit =
          this.metricDataGroupMapping[this.uiMetricMapping[metricName]]
            .metricUnit;
        const PVP_DIFF_TYPE =
          metricUnit === 'PERCENTAGE' ? 'PVP_DIFF_' : 'PVP_';
        const pvpMetricName = PVP_DIFF_TYPE + metricName;
        const pvpValue = categoryData[0].data[j].PVP[pvpMetricName];
        const metricObject = {
          tag1: resultValue,
          tag2: pvpValue
        };
        dataObject[this.uiMetricMapping[metricName]] = metricObject;
      }
      this.metricBundledDataForCategory = dataObject;
      this.isMetricLoading = false;
    },

    resetIsMetricLoading() {
      Object.keys(this.isChartLoading).forEach((key) => {
        this.isChartLoading[key] = true;
      });
    },

    async fetchTimeSeriesData() {
      this.resetIsMetricLoading();
      const metricArray = this.getMetricDetails(
        this.readApiResponse.metrics,
        this.readApiResponse.metadata
      );
      for (const metricKey in metricArray) {
        let metricRequest = metricArray[metricKey].request;
        metricRequest = updateRequestFormat(metricRequest, this.globalSettings);
        metricRequest = updateTimeSelectionForChart(
          metricRequest,
          this.noOfWeeksInChart
        );
        metricRequest.operations.timeseriesEnabled = true;
        metricRequest.metricsList[0] =
          metricArray[metricKey].dashboardServiceKey;
        metricRequest.operations.orderByList = [];
        try {
          const response = await HttpService.post(
            'MARKETSHARE',
            metricRequest,
            {
              append: '/marketshare/metrics/data/exec'
            }
          );
          if (response.status === 200) {
            this.timeSeriesDataResponse[metricKey] =
              response.data.entityData[0].data[1];
          }
        } catch (error) {
          this.timeSeriesDataResponse[metricKey] = null;
          this.$snackbar.open(msProConstants.errorMessage);
        }
      }
      this.transformData();
    },

    getMetricDetails(metricDetails, metadata) {
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      const displayType =
        this.globalSettings.settingValue?.displayShareIn.label;

      const prefix = categoryInCardConstants.msPro_prefix;
      const metrics = categoryInCardConstants.metrics;

      const metricDataGroupMapping = {};
      for (const metricKey in metrics) {
        const metricName =
          metadata[metrics[metricKey]][displayType][threepSelection];
        const newName = metricName.replace(new RegExp(`^${prefix}`), '');
        const metricObject = {
          brandServiceKey: metricName,
          dashboardServiceKey: metricDetails[newName].name,
          metricUnit: metricDetails[newName].metadata.unit,
          dataGroup: metricDetails[newName].dataGroup,
          request: metricDetails[newName].api.request
        };
        metricDataGroupMapping[metrics[metricKey]] = metricObject;
      }
      this.buildUIMetricMapping(metricDataGroupMapping);
      this.metricDataGroupMapping = metricDataGroupMapping;
      return metricDataGroupMapping;
    },

    transformData() {
      const metrics = categoryInCardConstants.metrics;
      this.chartDataForCategory = [];
      for (const metricKey in metrics) {
        const keyName = metrics[metricKey];
        if (!this.timeSeriesDataResponse[keyName]) {
          this.isChartLoading[keyName] = false;
          continue;
        }
        const metricName = this.timeSeriesDataResponse[keyName].name;
        this.chartDataForCategory[keyName] = {};
        const timeSeriesData = this.timeSeriesDataResponse[keyName].TIMESERIES;
        this.chartDataForCategory[keyName].timeSeriesData = [];
        const timeSeriesObject = {
          TIMESERIES: timeSeriesData,
          name: metricName,
          alias: keyName,
          type: 'METRICS'
        };
        this.chartDataForCategory[keyName].timeSeriesData.push(
          timeSeriesObject
        );
        this.isChartLoading[keyName] = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.category-card-row {
  grid-gap: 16px;
}
.category-card-row .category-card {
  height: 75px;
}
.chart-component {
  width: 186px;
  height: 43px;
}
</style>
