<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <div
      v-show="selections.length > 1"
      class="fillMessage fill--parent u-font-size-3"
    >
      Multiple Line Items are not allowed
    </div>
    <!-- Loader -->
    <div
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-font-weight-600 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        Edit Line Item Name ({{ selections.length }} Line Item{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </div>
      <div class="u-font-size-5 u-font-weight-600">Line Item Name:</div>
      <div>
        <rb-input
          ref="currentLineItemRef"
          v-model="currentLineItemName"
          type="text"
          class="u-font-size-2 u-spacing-mt-s line-item-name-input"
        />
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-pv-m u-spacing-ph-l u-display-flex"
    >
      <div
        v-if="applyButtonDisabled"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="applyButtonDisabled ? tooltipErrorMessage : ''"
      >
        <rb-button
          text="Apply"
          type="filled"
          :click-fn="onApply"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import config from '@/pages/campaign-management/retailers/criteo/line_items/EditLineItemName/editLineItemName.js';
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import HttpService from '@/utils/services/http-service';
export default {
  components: {
    loader
  },
  props: {
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      loader: false,
      config: null,
      errorMessage: null,
      currentLineItemName: this.selections[0].data.line_item_name,
      lineItemNames: [],
      tooltipErrorMessage: 'Line Item name already exists',
      showTooltip: true
    };
  },
  computed: {
    applyButtonDisabled() {
      if (this.tooltipErrorMessage.length > 0 || this.errorMessage) {
        return true;
      } else if (this.currentLineItemName.length === 0) {
        this.tooltipErrorMessage = 'Line Item Name is required';
        return true;
      } else if (this.currentLineItemName.length > 255) {
        this.tooltipErrorMessage =
          'Line item name should not exceed 255 characters';
        return true;
      }
      return false;
    }
  },
  watch: {
    selections(newVal) {
      if (newVal?.length === 0) {
        this.$emit('closePanelMethod');
      } else if (newVal?.length === 1) {
        this.currentLineItemName = this.selections[0].data.line_item_name;
        this.$refs.currentLineItemRef.focus();
      } else {
        this.currentLineItemName = '';
        this.$refs.currentLineItemRef.blur();
      }
    },
    currentLineItemName() {
      this.checkIfLineItemNameExists();
    }
  },
  created() {
    this.config = config[this.retailer];
    this.getLineItemNames();
  },
  mounted() {
    this.$refs.currentLineItemRef.focus();
  },
  methods: {
    checkIfLineItemNameExists() {
      if (
        this.lineItemNames.find(
          (item) => item.RESULT.line_item_name === this.currentLineItemName
        ) !== undefined
      ) {
        this.tooltipErrorMessage = 'Line Item name already exists';
      } else {
        this.tooltipErrorMessage = '';
      }
    },

    getLineItemNames() {
      HttpService.post('EXECUTE_CUBE_API', this.config.lineItemName.reqBody)
        .then((response) => {
          this.lineItemNames = [...response.data.response.data];
        })
        .catch((error) => console.log(error));
    },
    onClose() {
      this.$emit('closePanelMethod');
    },
    onApply() {
      let payload = [];
      payload = [...this.createPayload()];
      this.callAPI(payload);
    },
    callAPI(payload) {
      const that = this;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Line Item Name changed successfully.',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.apiVersion
      );
    },
    createPayload() {
      return [
        {
          widget: this.widget,
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          actionPayload: {
            line_item_id: this.selections[0]?.data.line_item_id,
            actionType: this.actionType,
            new_name: this.currentLineItemName
          },
          viewPayload: {
            line_item_id: this.selections[0]?.data.line_item_id,
            actionType: this.actionType,
            new_name: this.currentLineItemName,
            current_name: this.selections[0]?.data.line_item_name
          },
          primaryKey: this.selections[0]?.data?.[this.primaryKey],
          previousEntityValue: this.selections[0]?.data.line_item_name,
          newEntityValue: this.currentLineItemName
        }
      ];
    }
  }
};
</script>

<style lang="css" scoped>
.line-item-name-input {
  width: 30%;
}
</style>
