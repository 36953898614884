<template>
  <div
    class="u-display-flex u-flex-align-items-center u-border-color-grey-xxx-light u-border-width-s u-border-left u-spacing-ml-s u-spacing-pl-s"
  >
    <rb-icon
      class="u-display-flex u-flex-align-items-center rb-icon--small u-color-grey-lighter"
      :icon="icon"
    />
    <div
      class="u-font-size-7 u-display-flex u-flex-align-items-center u-spacing-ml-xs"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'star'
    },
    text: {
      type: String,
      default: 'Client Brand'
    }
  }
};
</script>

<style></style>
