var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spacing-mv-s"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      placement: 'bottom-start',
      interactive: true,
      reactive: true,
      arrow: false
    }),expression:"{\n      placement: 'bottom-start',\n      interactive: true,\n      reactive: true,\n      arrow: false\n    }"}],staticClass:"u-spacing-mb-s u-font-size-5 u-color-grey-base",attrs:{"title":_vm.inputDescription}},[_vm._v(" "+_vm._s(_vm.inputLabel)+" "),(_vm.isRequired)?_c('span',[_vm._v("*")]):_vm._e()]),(_vm.inputType === 'NUMBER')?_c('rb-input',{staticClass:"u-display-flex u-flex-align-items-center u-font-size-7",class:{ error: _vm.hasError },attrs:{"type":"number","placeholder":"15"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.inputType === 'SWITCH')?_c('rb-switch',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.inputType === 'LIST')?_c('rb-input',{staticClass:"u-width-100",class:{ error: _vm.hasError },attrs:{"type":"textarea"},model:{value:(_vm.arrayModel),callback:function ($$v) {_vm.arrayModel=$$v},expression:"arrayModel"}}):(_vm.inputType === 'QUOTED_LIST')?_c('rb-input',{staticClass:"u-width-100",class:{ error: _vm.hasError },attrs:{"type":"textarea"},on:{"blur":_vm.validateAndResetQuotedListData},model:{value:(_vm.quotedArrayModel),callback:function ($$v) {_vm.quotedArrayModel=$$v},expression:"quotedArrayModel"}}):(_vm.inputType === 'STRING')?_c('rb-input',{class:{ error: _vm.hasError },model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.inputType === 'FILTERS')?_c('rb-filter-panel',{key:_vm.filterKey,style:({
      'z-index': _vm.isFilterActive ? 2 : 0
    }),attrs:{"data":_vm.filterData,"primary":_vm.primaryFilterData,"hide-filter":true,"new-date":false,"enable-remove-all":false,"filter-v2":true,"show-ingestion-progress":true,"default-filters":_vm.appliedFilters,"use-local-storage":false},on:{"filterMounted":_vm.handleFilterMount,"allFilter":function($event){_vm.appliedFilters = $event}}}):_vm._e(),(_vm.hasError)?_c('div',{staticClass:"u-font-size-5 u-spacing-mt-s u-color-red-base"},[_vm._v(" * "+_vm._s(_vm.hasError)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }