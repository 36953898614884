<template>
  <div class="u-spacing-ph-m u-width-100">
    <div
      class="entity-holder u-border-width-s u-border-left u-border-bottom u-border-top u-border-right u-border-color-grey-xxx-light u-height-100 u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <div
        class="u-width-100 u-spacing-p-m u-height-100 custom-table-header asin-holder-medium"
        :class="{ 'asin-available': asinAvailable }"
        :custom-table-header-column-id="selectedAsin.entityId"
      >
        <div class="u-display-flex">
          <div
            v-tippy="tippy"
            class="u-display-flex"
            :title="'Drag to change the placement'"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small u-spacing-mr-s u-color-grey-light u-cursor-pointer u-user-select-none"
              :icon="'draggable-reorder'"
            />
          </div>
          <rb-select-v2
            class="u-spacing-mr-s u-overflow-auto u-width-100"
            :options="options"
            :search-enabled="true"
            :client-search="false"
            :search-listener="searchListener"
            :send-details="true"
            :on-close="asinChanged"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer u-width-100"
            >
              <div
                v-tippy="tippy"
                :title="selectedAsin.entityId"
                class="u-flex-1 u-font-size-3 u-font-weight-600 u-text-overflow-ellipsis u-line-height-1"
              >
                <span>{{ selectedAsin.entityId }}</span>
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div>
                <rb-sku
                  class="asin-table-holder"
                  :show-image="true"
                  :title="option.title"
                  :image-url="option.image_url"
                  :asin="option.asin"
                  :product-page-url="option.product_url"
                  :enable-s-d-p="false"
                />
              </div>
            </template>
          </rb-select-v2>

          <span
            class="u-display-flex"
            @click="removeAsin()"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small u-color-grey-xx-light u-cursor-pointer"
              :icon="'cross'"
            />
          </span>
        </div>
        <div
          class="asin-image-wrapper u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-mt-m"
        >
          <div
            class="holder u-border-all u-border-width-s u-border-color-grey-xxx-light"
          >
            <img
              class="asin-image"
              :src="selectedAsin.image_url"
            />
          </div>
          <DistributionTypeBadge
            :asin-id="selectedAsin.asin"
            class="rb-badge--large"
          />
        </div>
        <div
          v-tippy="tippy"
          class="asin-title"
          :title="selectedAsin.title"
        >
          <span>{{ selectedAsin.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import DistributionTypeBadge from '@/components/basic/DistributionTypeBadge.vue';
export default {
  name: 'AsinTableHeader',
  components: {
    RbSelectV2,
    DistributionTypeBadge
  },
  data() {
    return {
      displayIcon: '',
      alternateIcon: '',
      selectedAsin: null,
      asinAvailable: true,
      tippy: {
        placement: 'top',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      searchText: null,
      options: []
    };
  },
  computed: {
    // options () {
    //   return this.$store.getters[this.params.dropdownGetter][this.params.storeKey]?.data || [];
    // }
  },
  created() {
    this.options =
      this.$store.getters[this.params.dropdownGetter][this.params.storeKey]
        ?.data || [];
    const index = this.options.findIndex((item) => {
      return this.params.selectedAsin === item.entityId;
    });
    if (index > -1) {
      this.selectedAsin = this.options[index];
    } else {
      const warehouse =
        this.$store.getters[this.params.dataWarehouseGetter][
          this.params.storeKey
        ];
      this.selectedAsin = warehouse[this.params.selectedAsin];
    }
  },
  methods: {
    async searchListener(searchTerm) {
      this.searchText = searchTerm;
      this.options = await this.$store.dispatch(this.params.searchAction, {
        searchText: searchTerm,
        storeKey: this.params.storeKey,
        mutationDisable: true
      });
      console.log(this.options);
    },
    asinChanged(context, selectedOption) {
      if (selectedOption.length > 0) {
        this.selectedAsin.order = this.params.keyOrder;
        const orders = this.sortOrderColumns();
        const index = orders.findIndex((item) => {
          return this.selectedAsin.entityId === item;
        });
        this.$store.dispatch(this.params.deleteColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
        this.selectedAsin = selectedOption[0];
        this.selectedAsin.main_order = index + 1;
        this.selectedAsin.replace = true;
        this.$store.dispatch(this.params.addNewColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
      }
    },
    removeAsin() {
      this.asinAvailable = false;
      setTimeout(() => {
        this.$store.dispatch(this.params.deleteColumnAction, {
          data: this.selectedAsin,
          storeKey: this.params.storeKey
        });
      });
    },
    sortOrderColumns() {
      const domList = document.querySelectorAll('.custom-table-header');
      const list = [];
      for (let i = 0; i < domList.length; i++) {
        list.push(domList[i].getAttribute('custom-table-header-column-id'));
      }
      return list;
    }
  }
};
</script>

<style lang="css">
.asin-holder-medium {
  .holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 142px;
    width: 100%;
    border-radius: 4px;
  }
  .asin-image {
    height: 118px;
    max-width: 100%;
  }
  .asin-title {
    width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-size: 1.4rem;
    color: #2b333b;
  }
}
.asin-table-holder {
  width: 275px !important;
}
.entity-holder {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.asin-image-wrapper {
  position: relative;
}
.asin-image-wrapper .sku--badge .rb-badge {
  position: absolute;
}
.asin-image-wrapper .sku--badge:first-child .rb-badge {
  bottom: -5px;
  right: -5px;
}
.asin-image-wrapper .sku--badge:nth-child(2) .rb-badge {
  bottom: -5px;
  right: 12px;
}
</style>
