<template>
  <section class="u-bg-color-grey-white">
    <section
      v-if="load"
      class="intermediate-height u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
    <section
      v-else-if="noData"
      data-cy="cardWidget--undefined"
      class="intermediate-height u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>No Data</p>
    </section>
    <section
      v-else-if="error"
      class="intermediate-height u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </section>
    <!-- not using the v-else for rbInsights, in order to persist the dataPoroperties across a session -->
    <section
      v-show="isTableRendered"
      class="u-height-100"
    >
      <rb-insights-table
        ref="rb-insights-table"
        :config="config"
        class="pagination-overlay"
        :row-selection="rowSelection"
        :grid-options="gridOptions"
        :table-row="tableRow"
        :table-column="tableColumn"
        :row-height="rowHeight"
        :enable-client-side-sorting="enableClientSideSorting"
        :sorting-change-event="sortingChangeEvent"
        :enable-server-side-sorting="enableServerSideSorting"
        :pagination="true"
        :pagination-total-key="paginationTotalKey"
        :pagination-per-page-limit="paginationPerPageLimit"
        :pagination-change-event="paginationChangeEvent"
        :enable-client-side-pagination="false"
        :enable-col-resize="true"
        :show-pagination-options="showPaginationOptions"
        :has-expand="hasExpand"
        :tool-tip-text-for-expand-icon="toolTipTextForExpandIcon"
        :expand-cell-width="expandCellWidth"
        :levels="levels"
        :has-default-row-expand="hasDefaultRowExpand"
        :custom-has-expand-column-order="customHasExpandColumnOrder"
        @gridReady="handleGridReady"
        @tableExpandChangeTriggered="$emit('tableExpandChangeTriggered')"
      />
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import { debounce } from 'lodash';

export default {
  components: {
    loader
  },
  props: {
    enableClientSideSorting: {
      type: Boolean,
      default: true
    },
    enableServerSideSorting: {
      type: Boolean,
      default: true
    },
    hasExpand: {
      type: Boolean,
      default: false
    },
    expandCellWidth: {
      type: Number,
      default: 80
    },
    levels: {
      type: Array,
      default: () => []
    },
    hasDefaultRowExpand: {
      type: Boolean,
      default: false
    },
    watchResizeColumn: {
      type: Boolean,
      default: false
    },
    customHasExpandColumnOrder: {
      type: Number,
      default: 0
    },
    showPaginationOptions: {
      type: Boolean,
      default: false
    },
    sortingChangeEvent: {
      type: String,
      default: 'table-container-sort'
    },
    noData: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({})
    },
    gridOptions: {
      type: Object,
      default: () => ({})
    },
    tableRow: {
      type: Array,
      default: () => []
    },
    tableColumn: {
      type: Array,
      default: () => []
    },
    paginationTotalKey: {
      type: Number,
      default: null
    },
    paginationPerPageLimit: {
      type: Number,
      default: null
    },
    paginationChangeEvent: {
      type: String,
      default: null
    },
    load: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    rowHeight: {
      type: Number,
      default: 45
    },
    toolTipTextForExpandIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      }
    };
  },
  computed: {
    isTableRendered() {
      return !(this.load || this.noData || this.error);
    }
  },
  methods: {
    isAllChildrenSelected(selections) {
      // TODO: add nested table level selection check logic
      return true;
    },
    selectAllChildren() {
      this.gridOptions?.api?.forEachNode((node) => {
        node.setSelected(true);
      });
    },
    handleGridReady(params) {
      params?.api?.sizeColumnsToFit();
    },
    onSelectionChanged: debounce(function (selections, event) {
      const payload = { selections, event };
      if (!this.isAllChildrenSelected(selections)) {
        this.selectAllChildren();
      } else {
        this.$emit('rowSelect', payload);
      }
    })
  },
  watch: {
    isTableRendered(newTableRender) {
      // watching isTableRendered because if grid ready is called before table is iisible width and other properties arent applied from columnDefs. Manually applying with the gridOptions api in the watcher
      if (newTableRender) {
        this.gridOptions.api?.sizeColumnsToFit();
      }
    }
  }
};
</script>

<style lang="css" scoped>
.pagination-overlay {
  width: 100%;
  height: 650px;
  overflow-y: scroll;
}
.intermediate-height {
  height: 650px;
}
</style>
