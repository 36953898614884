<template>
  <div class="u-display-flex">
    <rb-select
      v-if="isActive"
      :on-close="updateStartTime"
      :options="startTimeOptions"
      :search-enable-threshold="10"
      :send-details="true"
      class="u-width-100"
      custom-tippy-direction-dropdown="left"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-justify-content-space-between u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
      >
        <span
          class="u-text-overflow-ellipsis u-text-transform-capitalize u-font-size-6"
        >
          {{ startTime.format('LT') }}
        </span>
        <rb-icon
          :icon="'arrow-down'"
          class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
        />
      </div>
    </rb-select>
    <span class="u-color-grey-xx-light u-spacing-ph-s u-font-size-1"> - </span>
    <rb-select
      v-if="isActive"
      :on-close="updateEndTime"
      :options="endTimeOptions"
      :search-enable-threshold="10"
      :send-details="true"
      class="u-width-100"
      custom-tippy-direction-dropdown="left"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-justify-content-space-between u-spacing-p-s u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-width-s u-border-all"
      >
        <span
          class="u-text-overflow-ellipsis u-text-transform-capitalize u-font-size-6"
        >
          {{ endTime.format('LT') }}
        </span>
        <rb-icon
          :icon="'arrow-down'"
          class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
        />
      </div>
    </rb-select>
  </div>
</template>

<script>
import moment from 'moment';
import CONSTANTS from '@/utils/constants';
export default {
  props: {
    startTime: {
      type: Object,
      required: true
    },
    endTime: {
      type: Object,
      required: true
    },
    timeRangeOptions: {
      type: Array,
      default: CONSTANTS.timeRangeOptions
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeRangeOptionsAsMoment() {
      return this.timeRangeOptions?.map((v) => moment(v, 'HH:mm:ss'));
    },
    startTimeOptions() {
      return this.timeRangeOptionsAsMoment
        .filter((v) => v.isBefore(this.endTime, 'minute'))
        .map((v) => ({ title: v.format('LT'), value: v }));
    },
    endTimeOptions() {
      return this.timeRangeOptionsAsMoment
        .filter((v) => v.isAfter(this.startTime, 'minute'))
        .map((v) => ({ title: v.format('LT'), value: v }));
    }
  },
  methods: {
    updateStartTime(_, val) {
      if (
        val &&
        val.length > 0 &&
        !val[0].value.isSame(this.startTime, 'minute')
      ) {
        this.$emit('startTimeUpdated', val[0].value);
      }
    },
    updateEndTime(_, val) {
      if (
        val &&
        val.length > 0 &&
        !val[0].value.isSame(this.endTime, 'minute')
      ) {
        this.$emit('endTimeUpdated', val[0].value);
      }
    }
  }
};
</script>
