<template>
  <span class="editable-category-cell-container">
    <div v-if="!currentCategory.title && !currentCategory.updatedValue">NA</div>
    <div
      v-else
      :style="editableCellsStyle"
    >
      <div
        v-if="interactiveStateData === 'edit' && tippyOptions"
        class="u-width-100"
      >
        <div
          v-tippy="tippyOptions"
          :identifier="getID"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-p-s u-cursor-pointer"
          :class="{ 'active-dropdown': showDropDown }"
        >
          <div
            class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-text-align-center"
          >
            {{ currentCategoryData.title }}
          </div>
          <rb-icon
            class="u-flex-0 rb-icon--medium u-color-grey-base"
            icon="caret-down"
          />
        </div>
        <div
          :id="getID"
          class="filter-dropdown-menu"
        >
          <div
            v-if="showDropDown"
            class="filter-dropdown-content-custom"
          >
            <div class="filter--header">
              <span class="u-display-flex u-flex-align-items-center">
                <span class="u-color-grey-white u-font-size-5">CATEGORY</span>
              </span>
              <span @click.stop="toggle">
                <rb-icon
                  icon="cross"
                  class="rb-icon--small u-color-grey-white u-cursor-pointer"
                />
              </span>
            </div>
            <multi-level-selector-dropdown
              apply-class-on-popper="decrease-z-index"
              :spawn-in-same-direction="true"
              :parent-tippy-id="parentTippyId"
              :init-selections="selections"
              :filter-data="filterData"
              :top-level-multi="false"
              @selectionsChanged="selectionsChanged"
            >
              <div
                slot="childLevelSelectorHeader"
                slot-scope="{ level }"
                class="u-display-flex u-flex-align-items-center"
              >
                <div class="filter--header">
                  <div class="filter--header--content">
                    SUB CATEGORY (Level {{ level }})
                  </div>
                </div>
              </div>
            </multi-level-selector-dropdown>
            <div
              class="u-display-flex u-bg-color-grey-white u-spacing-p-m u-flex-align-items-center u-position-relative u-flex-justify-content-flex-end"
              style="box-shadow: 0px -1px 2px 0px #e9eaeb"
            >
              <span class="u-display-flex u-flex-align-items-center">
                <rb-button
                  text="Apply"
                  type="filled"
                  size="s"
                  :click-fn="applyFilters"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <edited-tag-cell
        v-else-if="interactiveStateData === 'edited'"
        :enable-style-api="false"
        class="tags_view_cell_container u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto"
        :edited-entity="currentCategory"
        :enable-text-shrink="true"
      />
      <tags-view-cell
        v-else
        :prefer-emitter="preferEmitter"
        :enable-style-api="false"
        :class="{
          'empty-tags-cell': !currentCategoryData,
          tags_view_cell_container: currentCategoryData
        }"
        class="u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto"
        :dropdown-options="dropdownOptions"
        :tags-list="getItemTags(currentCategoryData)"
        @onDropdownOptionSelect="
          onDropdownOptionSelect($event, currentCategory)
        "
        @tagClicked="tagClicked"
      />
    </div>
  </span>
</template>

<script>
import multiLevelSelectorDropdown from '@/components/widgets/filter/multi-level-selector-dropdown.vue';
import editedTagCell from '@/components/widgets/tableComponents/cells/editedTagCell.vue';
import tagsViewCell from '@/components/widgets/tableComponents/cells/tagsViewCell.vue';

import { isEmpty } from 'lodash';

export default {
  name: 'EditableCategoryCell',
  components: {
    tagsViewCell,
    editedTagCell,
    multiLevelSelectorDropdown
  },
  props: {
    currentCategory: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dropdownOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editableCells: {
      type: Object,
      default: function () {
        return {};
      }
    },
    editableCellsStyle: {
      type: String,
      default: ''
    },
    interactiveState: {
      type: String,
      default: 'view'
    },
    metadata: {
      type: Object,
      default() {
        return {};
      }
    },
    preferEmitter: {
      type: Boolean,
      default: false
    },
    selections: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      parentTippyId: '',
      currentCategoryData: this.currentCategory,
      interactiveStateData: this.interactiveState,
      showDropDown: false,
      tippyid: null,
      categoryChanged: false,
      updatedCategorySelection: {}
    };
  },
  computed: {
    filterData() {
      const allCategories = this.$store.getters.getAllCategoryList;
      this.setMultiSelect(allCategories);
      return allCategories;
    },
    getID() {
      return (
        'edit-multi-select-' + this.metadata.asin + '-' + this.metadata.column
      );
    },
    tippyOptions() {
      const id =
        'edit-multi-select-' + this.metadata.asin + '-' + this.metadata.column;
      return {
        html: '#' + id,
        appendTo: () => {
          return document.querySelector('.widgetWrapper');
        },
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        title: id,
        onShow: this.onShow,
        onHide: this.onHide
      };
    },
    disableApplyButton() {
      return !this.categoryChanged;
    }
  },
  watch: {
    currentCategory(newVal) {
      this.currentCategoryData = newVal;
    },
    interactiveState(newVal) {
      this.interactiveStateData = newVal;
    }
  },
  created() {},
  methods: {
    getItemTags(item) {
      if (typeof item.title === 'number') {
        item.title = parseFloat(item?.title).toFixed(2);
      } else {
        item.title = item?.title?.replace('%27', "'");
      }
      if (
        !isEmpty(item.title) ||
        this.interactiveStateData === 'edit' ||
        !isNaN(item.title)
      ) {
        return [
          {
            id: item.recordId,
            ...item
          }
        ];
      } else {
        return [
          {
            id: item.recordId,
            title: 'Assign a value',
            type: 'newTagTrigger',
            recordId: item.recordId
          }
        ];
      }
    },
    tagClicked(tagPayload) {
      const recordId = tagPayload?.tagItem?.recordId;
      this.$emit('tagClick', {
        tagPayload,
        metadata: this.metadata,
        instance: this,
        recordId
      });
    },
    selectionsChanged(selections) {
      // todo add comment explaining how this works
      if (selections[this.selections[0]]) {
        this.categoryChanged = false;
        this.updatedCategorySelection = this.selections[0];
      } else {
        this.categoryChanged = true;
        for (let selection in selections) {
          if (selections[selection] === true) {
            this.updatedCategorySelection = selection;
          }
        }
      }
    },
    triggerDropDown() {
      this.showDropDown = true;
    },
    setMultiSelect(data) {
      for (const i in data) {
        data[i].multi = false;
        if (Object.keys(data[i].children).length > 0) {
          this.setMultiSelect(data[i].children);
        }
      }
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.$emit('onShow');
      instance.popper.classList.add('decrease-z-index');
      this.parentTippyId = instance.popper.id;
      this.showDropDown = true;
    },
    onHide(instance) {
      this.showDropDown = false;
    },
    toggle($event) {
      if (this.tippyInstance) {
        if (this.tippyInstance.state.visible) {
          this.tippyInstance.hide();
        } else {
          this.tippyInstance.show();
        }
      }
    },
    applyFilters() {
      this.$emit('updateCategory', {
        metadata: this.metadata,
        currentValue: this.selections[0],
        updatedValue: this.updatedCategorySelection
      });
      if (this.tippyInstance) {
        if (this.tippyInstance.state.visible) {
          this.tippyInstance.hide();
        } else {
          this.tippyInstance.show();
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.filter-dropdown-menu {
  width: 240px !important;
  min-width: 240px !important;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  overflow: auto;
}

.suggestion-item-tags-suggestion:hover {
  background-color: rgba(255, 213, 0, 0.1);
}

.suggestion-item-tags-suggestion:focus {
  background-color: rgba(255, 213, 0, 0.1);
}

.edit-cell-tag .add-tags-panel-tags-suggestion {
  width: 'fit-content';
  min-width: '10px';
  margin: 0px;
}
.edit-cell-tag .add-tags-panel {
  margin-left: 0px;
}
.editable-tags-suggestion-container .new-tags-selection:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container {
  cursor: default;
  background-color: rgba(255, 168, 0, 0.1);
}
.editable-tags-suggestion-container .tag_token_container .tag-icon {
  margin-top: 1px;
  margin-left: 9px;
}
.editable-tags-suggestion-container .tag_token_container .tag-text {
  color: #2b333b;
  font-size: 11px;
  font-weight: normal;
}

.tags_view_cell_container {
  overflow: hidden;
}
.tags_view_cell_container .new-tags-selection:hover {
  background-color: #ffa800;
}
.tags_view_cell_container .tag_token_container {
  background-color: rgba(255, 168, 0, 0.1);
}
.tags_view_cell_container .tag_token_container:hover {
  background-color: #ffa800;
  color: white;
}

.tags_view_cell_container.empty-tags-cell .new-tags-selection:hover {
  background-color: #ffffff;
  border: solid 1px #e9eaeb;
  color: #ffa800;
}
.tags_view_cell_container.empty-tags-cell .tag_token_container {
  padding: 4px 8px;
  background-color: #ffffff;
  border: solid 1px #e9eaeb;
  color: #ffa800;
}

.filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
  color: #fff;
  width: 100%;
}

.filter--header--content {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.active-dropdown {
  border: 1px solid #f5d908;
  border-radius: 4px;
}
</style>
