import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';

const state = {
  strategies: [],
  strategiesLoader: false,
  filterResult: {}
};

const getters = {
  getStrategies: (state) => {
    return state.strategies;
  },
  areStrategiesLoaded: (state) => {
    return !state.strategiesLoader;
  },
  getFilterResult: (state) => {
    return state.filterResult;
  }
};

const mutations = {
  STRATEGIES_FETCHED_SUCCESS: (state, data) => {
    state.strategies = data.sort((a, b) => {
      return a.strategyOrder - b.strategyOrder;
    });
  },
  STRATEGIES_FETCHED_ERROR: (state, data) => {
    state.strategies = [];
  },
  SHOW_STRATEGIES_LOADER: (state) => {
    state.strategiesLoader = true;
  },
  HIDE_STRATEGIES_LOADER: (state) => {
    state.strategiesLoader = false;
  },
  RB_STRATEGIES_SUCCESS: (state, data) => {
    Vue.set(state.filterResult, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.filterResult[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.filterResult[data.key], 'data', data.columns);
    }
    Vue.set(state.filterResult[data.key], 'rows', data.rows);
    Vue.set(state.filterResult[data.key], 'totalRows', data.totalRows);
    Vue.set(state.filterResult[data.key], 'metrics', data.metrics);
    Vue.set(state.filterResult[data.key], 'metricsUnits', data.metricsUnits);
    Vue.set(state.filterResult[data.key], 'page', data.page);
    Vue.set(state.filterResult[data.key], 'load', false);
    Vue.set(state.filterResult[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.filterResult[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.filterResult[data.key], 'noData', true);
    }
  },
  RB_STRATEGIES_RESET: (state, data) => {
    Vue.set(state.filterResult, data.key, {});
    Vue.set(state.filterResult[data.key], 'columns', []);
    Vue.set(state.filterResult[data.key], 'rows', []);
    Vue.set(state.filterResult[data.key], 'load', true);
    Vue.set(state.filterResult[data.key], 'error', false);
    Vue.set(state.filterResult[data.key], 'noData', false);
    Vue.set(state.filterResult[data.key], 'metrics', {});
  },
  RB_STRATEGIES_ERROR: (state, data) => {
    Vue.set(state.filterResult, data.key, {});
    Vue.set(state.filterResult[data.key], 'columns', []);
    Vue.set(state.filterResult[data.key], 'rows', []);
    Vue.set(state.filterResult[data.key], 'load', false);
    Vue.set(state.filterResult[data.key], 'error', true);
    Vue.set(state.filterResult[data.key], 'noData', false);
    Vue.set(state.filterResult[data.key], 'metrics', {});
  }
};

const actions = {
  fetchStrategies: (context) => {
    const retailer = context.getters.getRetailer;
    context.commit('SHOW_STRATEGIES_LOADER');
    HttpService.get('GET_STRATEGIES', {
      headers: { account: 'deprecated', retailer }
    })
      .then((response) => {
        if (response.data && response.data.length) {
          context.commit('STRATEGIES_FETCHED_SUCCESS', response.data);
        }
        context.commit('HIDE_STRATEGIES_LOADER');
      })
      .catch((error) => {
        if (error.response) {
          context.commit('STRATEGIES_FETCHED_ERROR', error.response);
          context.commit('HIDE_STRATEGIES_LOADER');
        }
      });
  },
  fetchRuleBuilder: (context, data) => {
    context.commit('RB_STRATEGIES_RESET', {
      key: data.meta.key
    });
    return new Promise((resolve, reject) => {
      HttpLayer.post({
        APIData: data.APIData
      }).then((response) => {
        if (!response.success) {
          context.commit('RB_STRATEGIES_ERROR', {
            key: data.meta.key
          });
        } else {
          var _aArray = [];
          _aArray = transformer.mergeResultDimension(response.data, true);
          const localData = {
            key: data.meta.key,
            rows: _aArray,
            totalRows:
              (_aArray[0] || {}).auto_cubesdk_count || (_aArray[0] || {}).count
          };
          context.commit('RB_STRATEGIES_SUCCESS', localData);
          resolve(localData);
        }
      });
    });
  },
  fetchOverLapEntityValidation: (context, reqBody) => {
    return new Promise((resolve, reject) => {
      HttpService.post('ENTITY_VALIDATION', reqBody)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createStratgey: (context, reqBody) => {
    if (reqBody.mode === 'create') {
      delete reqBody.mode;
      return HttpService.post('CREATE_STRATEGY', reqBody);
    } else if (reqBody.mode === 'edit') {
      delete reqBody.mode;
      return HttpService.put('CREATE_STRATEGY', reqBody);
    }
  },
  getStrategyData: (context, strategyUniqueId) => {
    return HttpService.get('GET_STRATEGY_DATA', {
      append: '?strategyUniqueId=' + strategyUniqueId
    });
  },
  updateStrategyData: (context, data) => {
    const { strategyUniqueId, status } = data;
    return HttpService.patch('CREATE_STRATEGY', null, {
      append: `?strategyUniqueId=${strategyUniqueId}&strategyStatus=${status}`
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
