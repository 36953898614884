var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-align-items-center",class:[_vm.parentClass]},[_c('rb-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.realtimeMetricTippyConfig.tippyOptions),expression:"realtimeMetricTippyConfig.tippyOptions"}],staticClass:"rb-icon",class:[
      _vm.iconClass,
      _vm.metricLabel === 'NA' || _vm.streamMetricData === 'NA'
        ? 'u-color-grey-lighter'
        : 'u-color-blue-base'
    ],attrs:{"icon":"in-progress-dotted"}}),_c('rb-tooltip-header',{attrs:{"id":_vm.realtimeMetricTippyConfig.id}},[_c('div',{staticClass:"tooltip-message stream-metric-card-tooltip u-display-flex u-flex-justify-content-flex-start",attrs:{"slot":"tooltip-message"},slot:"tooltip-message"},[_c('span',[_c('rb-icon',{staticClass:"rb-icon rb-icon--medium",class:{
            'u-color-blue-base':
              _vm.metricLabel !== 'NA' || _vm.streamMetricData !== 'NA',
            'u-color-grey-lighter':
              _vm.metricLabel === 'NA' || _vm.streamMetricData === 'NA'
          },attrs:{"icon":"in-progress-dotted"}})],1),_c('html-component',{staticClass:"u-spacing-ml-xs",attrs:{"html-string":_vm.metricLabel || _vm.streamMetricData
            ? _vm.getTippyTextForRealTimeData()
            : _vm.streamMetricTooltip,"wrapper-element":'span'}})],1)]),(_vm.showLabel)?_c('span',{staticClass:"u-font-size-6 u-font-weight-600 u-spacing-ml-xs",class:{
      'u-color-blue-base': _vm.metricLabel !== 'NA' || _vm.streamMetricData !== 'NA',
      'u-color-grey-lighter':
        _vm.metricLabel === 'NA' || _vm.streamMetricData === 'NA'
    }},[_vm._v(" "+_vm._s(_vm.metricLabel)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }