var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collapsible-page',{attrs:{"page-title":_vm.textConfig.pageTitle,"page-description":_vm.textConfig.pageDescription},on:{"closePage":_vm.navigateBack}},[_c('div',{staticClass:"u-width-100 u-display-flex u-flex-direction-column u-flex-1"},[(
        ![
          'Manage Standard Internal Catalog',
          'Manage Standard Internal Catalog for Seller Central (3P)',
          'Manage Source of Truth for Content',
          'Manage Source of Truth for Variant',
          'Campaign Taxonomy'
        ].includes(_vm.textConfig.pageTitle)
      )?_c('div',{staticClass:"u-spacing-ph-xxl u-display-flex tab_bar u-flex-align-items-center"},_vm._l((_vm.tabsConfig),function(tab,index){return _c('div',{key:index,class:{
          'u-font-weight-600': tab.title === _vm.currentTab.title,
          'u-color-blue-base': tab.title === _vm.currentTab.title
        }},[_c('div',{staticClass:"u-spacing-pr-xl u-font-size-5 u-cursor-pointer",on:{"click":function($event){return _vm.handleTabsClick(tab)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])])}),0):_vm._e(),_c(_vm.currentTab.component,_vm._b({tag:"component"},'component',_vm.currentTab.textConfig,false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }