<template>
  <div>
    <div
      v-tippy="tippy"
      class="cell--content u-white-space-normal u-color-grey-base u-flex-shrink-past-min-size u-overflow-hidden diff-content"
      :title="content"
      v-html="content"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
import helpers from '@/utils/helpers/index.js';

export default {
  props: {
    content: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: null
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'top-start',
          delay: [500, 0],
          animation: 'scale',
          interactive: true,
          maxWidth: '200px',
          theme: 'click-to-expand',
          duration: [150, 0]
        };
      }
    }
  },
  data() {
    return {
      toExpand: false
    };
  },
  created() {
    this.$nextTick(() => {
      this.toExpand = helpers.expandOnHover(this.params, this.$el);
    });
  }
};
</script>

<style lang="css" scoped>
@supports not (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content,
  .u-clamp-2 .cell--content,
  .u-clamp-3 .cell--content,
  .u-clamp-4 .cell--content,
  .u-clamp-5 .cell--content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
}
@supports (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-2 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-3 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-4 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .u-clamp-5 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  ins {
    text-decoration: none;
    color: #3ea95e;
  }
  del {
    text-decoration: line-through;
    color: #d7263d;
    padding-right: 4px;
  }
}
</style>
