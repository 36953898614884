export const retailerMap = {
  amazon: 'budget_planner_dashboard',
  walmart: 'walmart_budget_planner_dashboard',
  target: 'target_budget_planner_dashboard',
  instacart: 'instacart_budget_planner_dashboard'
};

export const retailerPlaceHolder = {
  amazon: 'Search for profiles and portfolios',
  walmart: 'Search for Advertisers',
  target: 'Search for Brands',
  instacart: 'Search for Brands'
};

export const retailerFiltersMap = {
  amazon: 'budgetPlanner-filters',
  walmart: 'budgetPlanner-walmart-filters',
  target: 'budgetPlanner-target-filters',
  instacart: 'budgetPlanner-target-filters'
};

export const pageWiseMinMaxKey = {
  amazon: 'budget_optimizer_dashboard',
  walmart: 'walmart_budget_optimizer_dashboard',
  target: 'target_budget_optimizer_dashboard',
  instacart: 'target_budget_optimizer_dashboard'
};

export const cumulativeStepretailerMap = {
  amazon: 'profile',
  walmart: 'advertiser',
  target: 'brand',
  instacart: 'brand'
};

export const previewStepretailerMap = {
  amazon: 'Profile:',
  walmart: 'Advertiser:',
  target: 'Brand:',
  instacart: 'Brand:'
};

export const scopeItemsRetailerMap = {
  amazon: {
    scopeItems: [
      { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      { name: 'Profiles', key: 'PROFILE' },
      { name: 'Portfolios', key: 'PORTFOLIO' }
    ]
  },
  walmart: {
    scopeItems: [
      { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      { name: 'Advertisers', key: 'PROFILE' }
    ]
  },
  target: {
    scopeItems: [
      { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      { name: 'Brands', key: 'PROFILE' }
    ]
  },
  instacart: {
    scopeItems: [
      { name: 'Entire Business', key: 'ENTIRE_BUSINESS' },
      { name: 'Brands', key: 'PROFILE' },
      { name: 'Portfolios', key: 'PORTFOLIO' }
    ]
  }
};

export const tempCurrentScopeRetailerMap = {
  amazon: {
    ENTIRE_BUSINESS: 'Entire Business',
    PROFILE: 'Profiles',
    PORTFOLIO: 'Portfolios'
  },
  walmart: {
    ENTIRE_BUSINESS: 'Entire Business',
    PROFILE: 'Advertisers'
  },
  target: {
    ENTIRE_BUSINESS: 'Entire Business',
    PROFILE: 'Advertisers'
  },
  instacart: {
    ENTIRE_BUSINESS: 'Entire Business',
    PROFILE: 'Brands',
    PORTFOLIO: 'Portfolios'
  }
};

export const retailerLevelSubStep = {
  amazon: {
    header: {
      title:
        'Would you like to include any specific events to adjust your budget plan?',
      desc: [
        'Add seasonality events such as Black Friday, Prime Day etc. at entire business, profile or portfolio level for specific time period.'
      ]
    }
  },
  walmart: {
    header: {
      title:
        'Would you like to include any specific events to adjust your budget plan?',
      desc: [
        'Add seasonality events such as Black Friday, Prime Day etc. at entire business, profile or portfolio level for specific time period.'
      ]
    }
  },
  target: {
    header: {
      title:
        'Would you like to include any specific events to adjust your budget plan?',
      desc: [
        'Add seasonality events such as Black Friday, Prime Day etc. at entire account, brand level for specific time period.'
      ]
    }
  },
  instacart: {
    header: {
      title:
        'Would you like to include any specific events to adjust your budget plan?',
      desc: [
        'Add seasonality events such as Black Friday, Prime Day etc. at entire account, brand level for specific time period.'
      ]
    }
  }
};

export const enableSpendPcogs = {
  amazon: true,
  walmart: true,
  target: false,
  instacart: false
};

export const budgetMetricsShown = {
  amazon: [
    { key: 'Planned Budget' },
    { key: 'Ad Spend' },
    { key: 'Spend / Budget' },
    { key: 'Spend / PCOGS' }
  ],
  walmart: [
    { key: 'Planned Budget' },
    { key: 'Ad Spend' },
    { key: 'Spend / Budget' },
    { key: 'Spend / PCOGS' }
  ],
  target: [
    { key: 'Planned Budget' },
    { key: 'Ad Spend' },
    { key: 'Spend / Budget' }
  ],
  instacart: [
    { key: 'Planned Budget' },
    { key: 'Ad Spend' },
    { key: 'Spend / Budget' }
  ]
};

export const retailerMessage = {
  amazon: 'Amazon data is typically delayed by upto 24 hours.',
  walmart: 'Walmart data is typically delayed by upto 24 hours.',
  target: 'Target data is typically delayed by upto 24 hours.',
  instacart: 'Instacart data is typically delayed by upto 24 hours.'
};

export const unCategorizedSKUConfig = {
  target: {
    cubeName: 'target_uncategorized_skus_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: ['gtin', 'product_name', 'brand', 'image_url', 'ad_spend'],
    groupByDimensionsList: ['gtin', 'product_id'],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    where: {
      dimensionNameValueList: []
    }
  },
  instacart: {
    cubeName: 'instacart_uncategorized_skus_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [
      'gtin',
      'product_name',
      'brand',
      'portfolio',
      'image_url',
      'ad_spend'
    ],
    groupByDimensionsList: ['gtin', 'product_id'],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    where: {
      dimensionNameValueList: []
    }
  }
};

export const editHistoryRetailerConfig = {
  amazon: 'Profile',
  walmart: 'Advertiser',
  target: 'Brand',
  instacart: 'Brand'
};

export const multiRetailerConfig = {
  hideRestForSinglePortfolio: ['instacart'],
  disableCollapseForUncategorized: ['instacart']
};
