import HomeV2 from '@/components/homeV2.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import { digitalShelfRoute } from '@/router/commonRoutePaths.js';
import urlHelper from '@/utils/helpers/url';

const chewyRoutes = {
  path: '/us/chewy/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          'feature.pages.chewy.landingPage',
          'digital_shelf/market_insights'
        );
      }
    },
    digitalShelfRoute,
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    }
  ]
};

export { chewyRoutes };
