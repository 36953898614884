<template>
  <div class="shortages-content-dashboard">
    <dashboard-inflator
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :filter="filterSettings"
      :show-active-filter-banner="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @onPageListApiSuccess="fetchExtraData"
    >
      <div slot="custom-filter">
        <div class="u-display-flex u-flex-direction-row">
          <rb-filter-panel
            :key="filterSettings.uiKey"
            style="z-index: 11"
            add-filter-text="Add a dispute vs recovery source filter"
            :get-filter-instance="getFilterInstance"
            listener-event="dispute"
            :disable-between-operator="!filterSettings.enableBetweenOperator"
            :data="ciqFilterData"
            :is-always-on-filter="true"
            :primary="ciqFilterPrimaryData"
            emit="DisputeEvent"
            :enable-save="false"
            :custom-config="customConfig[0]"
            :enable-remove-all="false"
            @onCreate="getFilterInstance"
            @filterMounted="handleFilterMount"
          />
          <rb-filter-panel
            :key="filterSettings.uiKey + 1"
            style="z-index: 10"
            add-filter-text="Vendor Code"
            :get-filter-instance="getFilterInstance"
            listener-event="shortageVendorID"
            :disable-between-operator="!filterSettings.enableBetweenOperator"
            :data="vendorIDFilterData"
            :is-always-on-filter="true"
            :primary="vendorIDPrimaryData"
            emit="shortagesVendorIDEvent"
            :enable-save="false"
            :custom-config="customConfig[1]"
            :enable-remove-all="false"
            @onCreate="getFilterInstance"
            @filterMounted="handleFilterMount"
          />
        </div>
      </div>
    </dashboard-inflator>
  </div>
</template>

<script>
import PerformanceOverviewWidget from './perfromanceOverview.vue';
import summary_purchase_order_shortage from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import top_contributors_by from './TopContributors.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import transformer from '@/utils/services/data-transformer';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import RbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import RbCheckboxGroup from '@/components/basic/rbCheckboxGroup.vue';
import RbTabs from '@/components/basic/rbTabs.vue';
import RbSplitTabs from '@/components/widgets/splitTabs.vue';
import iconText from '@/components/basic/iconText.vue';
import { cloneDeep, union } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    PerformanceOverviewWidget,
    summary_purchase_order_shortage,
    top_contributors_by,
    rbFilterPanel
  },
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      globalViewId: 3,
      id: 12,
      name: 'ShortageInvoiceReport',
      title: 'Shortages'
    };
    const context = this;
    return {
      context,
      filterInstance: null,
      isDownloadLoading: false,
      isDownloadPaymentsReportLoading: false,
      isDownloadSummaryReportLoading: false,
      dataService: {},
      customConfig: [
        {
          title: 'Dispute vs Recovery Source',
          anchor: 'ciq_filter',
          type: 'CLIENT_INTERNAL_CATALOGUE',
          config: {
            dimensionColumn: 'ciq_filter',
            dimensionLabel: 'Dispute Vs Recovery Source',
            dimensionName: 'ciq_filter',
            dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
          },
          smart: true,
          multi: true
        },
        {
          title: 'Vendor Code',
          anchor: 'vendor_code',
          type: 'CLIENT_INTERNAL_CATALOGUE',
          config: {
            dimensionColumn: 'vendor_code',
            dimensionLabel: 'Vendor Code',
            dimensionName: 'vendor_code',
            dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
          },
          smart: true,
          multi: true
        }
      ],
      ciqFilterData: [
        {
          ciq_filter: 'CIQ'
        },
        {
          ciq_filter: 'You'
        }
      ],
      ciqFilterPrimaryData: [
        {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Dispute Vs Recovery Source',
          dimensionName: 'ciq_filter',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        }
      ],
      vendorIDPrimaryData: [
        {
          dimensionColumn: 'vendor_code',
          dimensionLabel: 'Vendor Code',
          dimensionName: 'vendor_code',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        }
      ],
      pageInfo,
      columnCellMapping: {},
      selectedFilters: [],
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name
        },
        PerformanceOverviewWidget: {
          ':widget': 'PerformanceOverviewWidget',
          ':timeseriesRollupBy': 'WEEK',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        summary_purchase_order_shortage: {
          ':widget': 'summary_purchase_order_shortage',
          ':entityType': 'root_invoice'
        },
        shortage_rate_impact_by_combinations_of: {
          ':widget': 'shortage_rate_impact_by_combinations_of',
          ':dimensionsList': ['fc', 'carrier'],
          ':entityType': 'asin',
          ':orderByList': [
            { dimension: 'shortages_received', direction: 'DESC' },
            { dimension: 'title', direction: 'ASC' },
            { dimension: 'number_of_po_accpeted', direction: 'DESC' }
          ],
          ':metricsList': [
            'shortage_rate_impact_by_combination__asin',
            'shortage_rate_impact_by_combination__title',
            'shortage_rate_impact_by_combination__image_url',
            'shortage_rate_impact_by_combination__product_url',
            'shortage_rate_impact_by_combination__fc',
            'shortage_rate_impact_by_combination__carrier',
            'shortage_rate_impact_by_combination__number_of_po_accpeted',
            'shortage_rate_impact_by_combination__shortages_received'
          ]
        },
        top_contributors_by: {
          ':widget': 'top_contributors_by',
          ':entityType': 'asin',
          ':orderByList': [
            {
              dimension: 'invoices_received',
              direction: 'DESC'
            }
          ]
        }
      },
      filterSettings: {
        emitEvent: 'shortageInvoiceFilterApply',
        uiKey: 0,
        show: true,
        backendCube: 'shortage_invoice_dashboard',
        defaultDatePostRange: 'last90Days',
        enableSave: false,
        enableBetweenOperator: true,
        listenerEvent: 'shortage-invoice-filter',
        search: {
          enable: true,
          placeholder: 'Search for a SKU or PO',
          width: '240px',
          searchUniqKey: 'searchSubmit'
        },
        filterV2: true
      },
      metricGlobalParamMapping: {
        overall_content_score_per: '',
        title_len: ':title_len',
        title_contains_brand_metric: ':title_conatins_brand',
        desc_contains_productname_metric: ':desc_contains_product',
        desc_len: ':desc_len',
        no_of_key_features: ':bullet_points',
        no_of_reviews: ':reviews',
        no_of_images: ':images',
        avg_rating: ':avg_rating',
        desc_contains_keyword_metric: ':product_desc_contains_keyword',
        product_title_contains_keyword_metric: ':product_title_contains_keyword'
      },
      widgetMetadata: [],
      vendorIDFilterData: [],
      widgetArray: [
        'PerformanceOverviewWidget',
        'top_contributors_by',
        'shortage_rate_impact_by_combinations_of',
        'summary_purchase_order_shortage'
      ]
    };
  },
  computed: {
    headerOptions() {
      return {
        PerformanceOverviewWidget: {
          props: {
            'data-cy': 'performanceOverview',
            headerOptions: [],
            enableRollup: true,
            iconForDownload: 'share',
            enableDownload: true,
            textForIcon: 'Export',
            selectedMetricLimit: 2,
            textClasses:
              'icon-text u-font-size-8 u-text-case-upper u-spacing-pt-xs',
            loaderClass: 'loader',
            minimumMetric: 4,
            tooltipText: 'Export',
            removeDropdown: false,
            disableAddNewMetricButton: true,
            isMetricToolTipEnabled: true,
            modifyTooltipMessage: true,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto auto'
            },
            chartConfig: {
              chartOptions: {
                legend: false,
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {},
                axes: {},
                grid: 'xy',
                hasEvents: false,
                type: 'line',
                types: {},
                xAxisType: 'category',
                stack: [],
                chartGetter: '',
                show_axis_colors: true,
                events: [],
                dynamicRange: true,
                size: { height: 380 },
                axis_format: {}
              },
              stack: [],
              chartGetter: '',
              xAxisType: 'category',
              lastUpdatedDate: null
            },
            bypassFilters: ['ciq_filter']
          }
        },
        summary_purchase_order_shortage: {
          props: {
            enableDownload: true,
            tooltipText: 'Export',
            textClasses:
              'icon-text u-font-size-8 u-text-case-upper u-spacing-pt-xs',
            iconForDownload: 'share',
            textForIcon: 'Export',
            loaderClass: 'loader',
            isMetricToolTipEnabled: true,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto auto',
              'grid-gap': '0rem'
            },
            searchParams: {
              show: false,
              placeholder: 'SKU, PO, Inovice, Dispute, FC, Carrier',
              searchDimension: 'local_search'
            }
          }
        },
        shortage_rate_impact_by_combinations_of: {
          props: {
            headerOptions: [
              {
                ui_component: 'rbCheckboxGroup',
                props: {
                  checkboxOptions: [
                    { label: 'SKU Details', type: 'SKU', disabled: false },
                    { label: 'FC', type: 'FC', disabled: false },
                    { label: 'Carrier', type: 'CARRIER', disabled: false }
                  ],
                  value: ['SKU', 'FC', 'CARRIER']
                },
                events: {
                  onCheckboxChanged: this.filterChanged
                },
                order: 400
              }
            ],
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto',
              'grid-template-rows': '1fr auto',
              'grid-gap': '1.5rem'
            },
            enableDownload: true,
            showTabs: false,
            loaderClass: 'loader',
            isMetricToolTipEnabled: true,
            tooltipText: 'Export',
            iconForDownload: 'share',
            textClasses:
              'icon-text u-font-size-8 u-text-case-upper u-spacing-pt-xs',
            textForIcon: 'Export',
            showCheckboxFilters: false,
            searchParams: {
              show: false,
              placeholder: '',
              searchDimension: ''
            },
            columnKeyMapping: this.columnCellMapping,
            bypassFilters: ['ciq_filter']
          }
        },
        top_contributors_by: {
          props: {
            'data-cy': 'top_contributors_by',
            enableDownload: false,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr',
              'grid-template-rows': '1fr ',
              'grid-gap': '1 rem'
            },
            searchParams: {
              show: false
            },
            bypassFilters: ['ciq_filter']
          }
        }
      };
    }
  },
  watch: {
    requestParams(newVal) {}
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('PerformanceOverviewWidget', PerformanceOverviewWidget);
    Vue.component(
      'summary_purchase_order_shortage',
      summary_purchase_order_shortage
    );
    Vue.component(
      'shortage_rate_impact_by_combinations_of',
      summary_purchase_order_shortage
    );
    Vue.component('top_contributors_by', top_contributors_by);
    Vue.component('RbRadioGroup', RbRadioGroup);
    Vue.component('RbCheckboxGroup', RbCheckboxGroup);
    Vue.component('rbTabsRounded', RbTabs);
    Vue.component('rbSplitTabs', RbSplitTabs);
    Vue.component('icon-text', iconText);
  },
  methods: {
    async detailDownload(reportName, downloadLoading) {
      try {
        this[downloadLoading] = true;
        const currentMetric = this.widgetMetadata.find(
          (item) => item.name === 'summary_purchase_order_shortage'
        );
        const widgetRequestParams = this.getWidgetRequestParams(
          'summary_purchase_order_shortage'
        );
        const global = this.getGlobalRequestParams();
        widgetRequestParams[':page'] = 'ShortageInvoiceReport';
        widgetRequestParams[':from'] = global[':from'];
        widgetRequestParams[':to'] = global[':to'];
        const metricsArrayListForDownload =
          currentMetric.metadata?.defaultConfig?.detailDownload;
        const api = currentMetric.downloadApiTemplate;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          widgetRequestParams
        );
        request.where.dimensionNameValueList = [];
        request.metricsList = metricsArrayListForDownload.metricsList;

        request.entityType = metricsArrayListForDownload.entityType;
        request.operations.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions =
          [];
        if (widgetRequestParams[':dimensionNameValueList'].length > 0) {
          let allowedFilters = ['ciq_filter'];
          const updatedTempWidgetParams =
            widgetRequestParams[':dimensionNameValueList']?.filter((item) =>
              allowedFilters.includes(item.dimensionName)
            ) || [];
          request.where.dimensionNameValueList = updatedTempWidgetParams;
        }
        request.dimensionsList = [];
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        const { url } = response.data;
        downloadLinkAsFile(url);
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
      this[downloadLoading] = false;
    },

    PerformanceOverviewWidgetDownloadTriggered() {
      try {
        this.dataService.downloadChartingData(
          this.requestParams?.value?.PerformanceOverviewWidget,
          this.requestParams?.value
        );
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    getFilterInstance(filter) {
      this.filterInstance = filter;
      this.handleFilterMount(this.filterInstance);
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        if (filters?.values) {
          const global = cloneDeep(this.getGlobalRequestParams());
          const where = transformer.generateWhereClause({}, filters, true);
          // currently does not handle the global pre existing dimensionNameValue lists gracefully since we updating this directly not considering that case.
          // to be improved
          global[':dimensionNameValueList'] =
            where.dimensionNameValueList || [];
          this.updateGlobalRequestParams(global);
        }
        eventBus.$on('DisputeEvent', (data) => {
          this.ciqFilterEventHandler(instance, data);
        });
        eventBus.$on('shortagesVendorIDEvent', (data) => {
          this.vendorIDFilterEventHandler(instance, data);
        });
      }
    },
    tabChanged(selectedTab) {
      const shoratgeRateImpactReqParams = this.getWidgetRequestParams(
        'shortage_rate_impact_by_combinations_of'
      );
      var tempDirection = '';
      selectedTab === 'Top by Shortages Received'
        ? (tempDirection = 'DESC')
        : (tempDirection = 'ASC');
      shoratgeRateImpactReqParams[':orderByList'].map((item) => {
        if (item.dimension === 'shortages_received')
          item.direction = tempDirection;
        if (item.dimension === 'number_of_po_accpeted')
          item.direction = tempDirection;
      });
      this.updateWidgetRequestParams(
        shoratgeRateImpactReqParams,
        'shortage_rate_impact_by_combinations_of'
      );
    },
    filterChanged(selectedFilters) {
      this.selectedFilters = selectedFilters;
      var shoratgeRateImpactReqParams = this.getWidgetRequestParams(
        'shortage_rate_impact_by_combinations_of'
      );
      var modifiedReqParams = this.getModifiedReqPayload(selectedFilters);
      Object.keys(modifiedReqParams).forEach((item) => {
        shoratgeRateImpactReqParams[item] = modifiedReqParams[item];
      });
      this.updateWidgetRequestParams(
        shoratgeRateImpactReqParams,
        'shortage_rate_impact_by_combinations_of'
      );
      this.updateColumnMapping();
    },
    getModifiedReqPayload(selectedFiltersList) {
      var formattedList = selectedFiltersList.filter((item) => item).join('-');
      var metricsList = [
        'shortage_rate_impact_by_combination__asin',
        'shortage_rate_impact_by_combination__title',
        'shortage_rate_impact_by_combination__image_url',
        'shortage_rate_impact_by_combination__product_url',
        'shortage_rate_impact_by_combination__fc',
        'shortage_rate_impact_by_combination__carrier',
        'shortage_rate_impact_by_combination__number_of_po_accpeted',
        'shortage_rate_impact_by_combination__shortages_received'
      ];
      var tempMetricsList = metricsList;
      const shoratgeRateImpactReqParams = this.getWidgetRequestParams(
        'shortage_rate_impact_by_combinations_of'
      );
      var orderByList = shoratgeRateImpactReqParams[':orderByList'];

      var tempOrderByList = orderByList.map((item) => {
        if (formattedList === 'FC-CARRIER' || formattedList === 'FC') {
          if (
            item.dimension === 'title' ||
            item.dimension === 'fc' ||
            item.dimension === 'carrier'
          )
            return { dimension: 'fc', direction: 'ASC' };
        } else if (formattedList === 'CARRIER') {
          if (item.dimension === 'title' || item.dimension === 'fc')
            return { dimension: 'carrier', direction: 'ASC' };
        } else if (
          formattedList === 'SKU-FC-CARRIER' ||
          formattedList === 'SKU-FC' ||
          formattedList === 'SKU-CARRIER' ||
          formattedList === 'SKU'
        ) {
          if (
            item.dimension === 'title' ||
            item.dimension === 'fc' ||
            item.dimension === 'carrier'
          )
            return { dimension: 'title', direction: 'ASC' };
        } else if (formattedList === '') {
          if (
            item.dimension === 'title' ||
            item.dimension === 'fc' ||
            item.dimension === 'carrier'
          )
            return { dimension: 'title', direction: 'ASC' };
        }
        return item;
      });

      if (formattedList === 'SKU-FC-CARRIER') tempMetricsList = metricsList;
      else if (formattedList === 'SKU-FC')
        tempMetricsList = tempMetricsList.filter(
          (item) => !item.includes('__carrier')
        );
      else if (formattedList === 'SKU-CARRIER')
        tempMetricsList = tempMetricsList.filter(
          (item) => !item.includes('__fc')
        );
      else if (formattedList === 'FC-CARRIER')
        tempMetricsList = tempMetricsList.filter(
          (item) =>
            !item.includes('__asin') &&
            !item.includes('__title') &&
            !item.includes('__image_url') &&
            !item.includes('__product_url')
        );
      else if (formattedList === 'SKU')
        tempMetricsList = tempMetricsList.filter(
          (item) => !item.includes('__fc') && !item.includes('__carrier')
        );
      else if (formattedList === 'FC')
        tempMetricsList = tempMetricsList.filter(
          (item) =>
            !item.includes('__asin') &&
            !item.includes('__carrier') &&
            !item.includes('__title') &&
            !item.includes('__image_url') &&
            !item.includes('__product_url')
        );
      else if (formattedList === 'CARRIER')
        tempMetricsList = tempMetricsList.filter(
          (item) =>
            !item.includes('__asin') &&
            !item.includes('__fc') &&
            !item.includes('__title') &&
            !item.includes('__image_url') &&
            !item.includes('__product_url')
        );
      const filters = {
        'SKU-FC-CARRIER': {
          ':entityType': 'asin',
          ':dimensionsList': ['fc', 'carrier'],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        'SKU-FC': {
          ':entityType': 'asin',
          ':dimensionsList': ['fc'],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        'SKU-CARRIER': {
          ':entityType': 'asin',
          ':dimensionsList': ['carrier'],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        'FC-CARRIER': {
          ':entityType': 'fulfillment_center',
          ':dimensionsList': ['carrier'],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        SKU: {
          ':entityType': 'asin',
          ':dimensionsList': [],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        FC: {
          ':entityType': 'fulfillment_center',
          ':dimensionsList': [],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        CARRIER: {
          ':entityType': 'carrier_name',
          ':dimensionsList': [],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        },
        '.default': {
          ':entityType': 'asin',
          ':dimensionsList': ['fc', 'carrier'],
          ':metricsList': tempMetricsList,
          ':orderByList': tempOrderByList
        }
      };
      return filters[formattedList] || filters['.default'];
    },
    updateColumnMapping() {
      const breakdownTable = this.widgetMetadata.find(
        (e) => e?.name === 'shortage_rate_impact_by_combinations_of'
      );
      var formattedList = this.selectedFilters.filter((item) => item).join('-');
      if (breakdownTable) {
        const metrics = Object.keys(breakdownTable.metrics);
        const columnCellMapping = {};
        for (var i of metrics) {
          const { alias } = breakdownTable.metrics[i];
          const metricMetadata = cloneDeep(breakdownTable.metrics[i].metadata);

          if (
            i === 'asin' &&
            (formattedList === 'CARRIER' ||
              formattedList === 'FC' ||
              formattedList === 'FC-CARRIER')
          ) {
            if (metricMetadata?.visible) {
              metricMetadata.visible = false;
              columnCellMapping[alias] = metricMetadata;
            } else {
              metricMetadata.visible = true;
              columnCellMapping[alias] = metricMetadata;
            }
          } else if (
            i === 'fc' &&
            (formattedList === 'SKU-CARRIER' ||
              formattedList === 'SKU' ||
              formattedList === 'CARRIER')
          ) {
            if (metricMetadata?.visible) {
              metricMetadata.visible = false;
              columnCellMapping[alias] = metricMetadata;
            } else {
              metricMetadata.visible = true;
              columnCellMapping[alias] = metricMetadata;
            }
          } else if (
            i === 'carrier' &&
            (formattedList === 'SKU-FC' ||
              formattedList === 'SKU' ||
              formattedList === 'FC')
          ) {
            if (metricMetadata?.visible) {
              metricMetadata.visible = false;
              columnCellMapping[alias] = metricMetadata;
            } else {
              metricMetadata.visible = true;
              columnCellMapping[alias] = metricMetadata;
            }
          } else {
            columnCellMapping[alias] = metricMetadata;
          }
        }
        this.columnCellMapping = columnCellMapping;
      }
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
      this.updateColumnMapping();
    },
    fetchVendorCodeFilterData(vendorCodeFilterAPI) {
      let { service, request, endPoint } = vendorCodeFilterAPI;

      dashUtils
        .fetchDataAPI(request, { service, endPoint })
        .then((response) => {
          this.setVendorCodeFilter(response);
        });
    },
    setVendorCodeFilter(data) {
      this.vendorIDFilterData = data?.entityData?.map((entry) => {
        return {
          vendor_code: entry.entityValue
        };
      });
    },
    fetchExtraData(pageApiData) {
      if (pageApiData?.API?.vendorCodeFilterAPI) {
        this.fetchVendorCodeFilterData(pageApiData.API.vendorCodeFilterAPI);
      }
    },
    ciqFilterEventHandler(instance, data) {
      let filters = instance.getSelectedFilters();
      const filterData = {
        values: { ...data, ...filters.values }
      };
      delete filterData.values.date_range;
      let where = {};
      if (data?.ciq_filter?.length === 1) {
        if (data.ciq_filter[0] === 'CIQ') {
          where.dimensionNameValueList = [
            {
              dimensionName: 'ciq_filter',
              dimensionValue: 'CIQ',
              operator: 'ILIKE'
            }
          ];
        } else {
          where.dimensionNameValueList = [
            {
              dimensionName: 'ciq_filter',
              dimensionValue: 'CIQ',
              operator: 'NOT_LIKE'
            }
          ];
        }
      } else {
        where.dimensionNameValueList = [];
      }

      this.widgetArray.map((widget) => {
        const tempWidgetParams = this.getWidgetRequestParams(widget);
        const updatedTempWidgetParams =
          tempWidgetParams[':dimensionNameValueList']?.filter(
            (item) => item.dimensionName !== 'ciq_filter'
          ) || [];
        tempWidgetParams[':dimensionNameValueList'] = union(
          updatedTempWidgetParams,
          where.dimensionNameValueList
        );
        this.updateWidgetRequestParams(tempWidgetParams, widget);
      });
    },
    vendorIDFilterEventHandler(instance, data) {
      let filters = instance.getSelectedFilters();
      const filterData = {
        values: { ...data, ...filters.values }
      };
      delete filterData.values.date_range;
      let where = {};
      where.dimensionNameValueList = data?.vendor_code?.map((filterValue) => {
        return {
          dimensionName: 'vendor_code_list',
          dimensionValue: filterValue
        };
      });
      this.widgetArray.map((widget) => {
        const tempWidgetParams = this.getWidgetRequestParams(widget);
        const updatedTempWidgetParams =
          tempWidgetParams[':dimensionNameValueList']?.filter(
            (item) => item.dimensionName !== 'vendor_code_list'
          ) || [];
        tempWidgetParams[':dimensionNameValueList'] = union(
          updatedTempWidgetParams,
          where.dimensionNameValueList
        );
        this.updateWidgetRequestParams(tempWidgetParams, widget);
      });
    }
  }
};
</script>

<style lang="css">
.inputClassForEntityTaggingSearch input {
  width: 240px !important;
}
.rb-sku {
  width: 100% !important;
}

.shortages-content-dashboard .icon-download:before {
  font-size: 16px;
}
.shortages-content-dashboard .icon-text {
  font-family: 'ProximaNova' !important;
  font-size: 9px !important;
}
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='asin'].ag-cell,
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='po_number'].ag-cell,
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='invoice_number'].ag-cell,
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='dispute_id'].ag-cell {
  border-right: 1px solid #e9eaeb !important;
}
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='payment_number'].ag-cell
  > div {
  margin: 0.8rem !important;
}
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='po_number'][class='ag-header-cell ag-header-cell-sortable'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='invoice_number'][class='ag-header-cell ag-header-cell-sortable'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='dispute_id'][class='ag-header-cell ag-header-cell-sortable'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='asin'][class='ag-header-cell ag-header-cell-sortable'] {
  border-right: 1px solid #e9eaeb !important;
}
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='po_number'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='invoice_number'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='dispute_id'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.shortages-content-dashboard
  .summary_purchase_order_shortage
  div[col-id^='asin'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'] {
  border-right: 1px solid #e9eaeb !important;
}
.shortages-content-dashboard .summary_purchase_order_shortage .link--asin {
  color: #2b333b;
  border-bottom: 1px dotted !important;
}
.shortages-content-dashboard
  .summary_purchase_order_shortage
  .link--asin:hover {
  color: #007cf6 !important;
  text-decoration: none !important;
}
.shortages-content-dashboard .filter-dropdown-menu {
  top: 0px !important;
  left: 0px !important;
  width: 246px !important;
}
.shortages-content-dashboard .filter__wrapper {
  flex: auto !important;
}
.shortages-content-dashboard .custom-chart-legend {
  max-width: 380px !important;
}
.table-header-tooltip .tooltip-message {
  white-space: pre-line !important;
}
.PerformanceOverviewWidget .header-container-cwc {
  padding-bottom: 0;
}
.PerformanceOverviewWidget .rollUpWrapper {
  margin-right: 0.8rem;
}
.add-filter-text {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.4rem;
  color: #8b8f93;
}
.custom-filter :hover .add-filter-text {
  color: #4b5158;
}
.custom-filter :hover .rb-icon {
  color: #4b5158;
}
.filter-token-dashed {
  border: 1px dashed #e9eaeb !important;
}
.filter-wrapper {
  padding-left: 0px !important;
}
.filter-dropdown-content {
  min-height: 90px !important;
}
.u-color-grey-lighter {
  color: #8b8f93;
}
.filter-dropdown-menu {
  top: 0px;
  left: 0px;
}
.loader {
  width: 53px;
}
.export-with-payment-details {
  width: 151px;
}
.export {
  width: 50px;
}
.rollUpWrapper {
  align-self: center;
}
</style>
