<template>
  <div
    class="u-width-100 u-display-flex u-flex-align-items-center"
    :class="'benchmarking-cid-' + params.data['entity']"
  >
    <div
      v-if="params.data['ag-grid-level'] === 0"
      class="u-spacing-mr-m u-width-100"
    >
      <div
        class="u-spacing-pb-xs u-width-100 u-border-width-s u-border-bottom u-border-color-grey-xxx-light"
      >
        <span class="u-color-grey-light u-font-size-3 u-font-weight-600">
          {{ params.data['entity'] }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="u-display-flex u-flex-align-items-center u-spacing-mr-m u-width-100 u-flex-justify-content-space-between"
    >
      <!-- <div class="show-chart-holder " @click="plotMetric">
                <rb-icon class="rb-icon--small u-color-grey-white" :icon="'show-chart'"></rb-icon>
            </div> -->
      <rb-select-v2
        :send-details="true"
        class="u-spacing-pr-s table-select-holder"
        :class-name="'tableSelect'"
        :on-close="onMetricSelect"
        :options="metricOptions"
        :open-direction="'right'"
        :is-waterfall-dropdown="true"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-width-100"
          >
            <span
              v-tippy="metricTooltip"
              :title="tooltipText"
              class="u-font-size-5 u-text-overflow-ellipsis u-color-grey-light"
              >{{ (params.data.selectedMetric || {}).name }}</span
            >
            <rb-icon
              class="rb-icon--x-medium u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
          >
            <div
              class="u-width-100"
              :class="{ 'u-color-grey-lighter': option.disable }"
            >
              <div
                class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-width-100"
              >
                <div>{{ option.label || option.name }}</div>
                <div v-if="option.showIcon">
                  <rb-icon
                    v-tippy="metricTooltip"
                    class="u-spacing-pl-m u-cursor-pointer u-color-grey-lighter"
                    :icon="'info-circle-outline'"
                    :title="option.disableTooltip"
                  />
                </div>
                <div
                  v-if="option.isAvailable && params.groupByKey"
                  class="u-spacing-pl-m u-color-orange-base"
                >
                  ●
                </div>
              </div>
            </div>

            <span
              v-if="option.children"
              class="rb-icon u-display-flex u-spacing-ml-m u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
              :class="{ 'u-color-orange-base': params.groupByKey }"
              style="transform: rotate(90deg)"
            />
          </div>
        </template>
        <div
          v-if="footerLabel && params.groupByKey"
          slot="footer"
          class="u-display-flex u-spacing-ph-l u-spacing-pt-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
        >
          <div>
            <span class="u-color-orange-base">●</span>
            <span
              class="u-font-size-7 u-font-weight-normal u-color-grey-light"
              >{{ footerLabel }}</span
            >
          </div>
        </div>
      </rb-select-v2>
      <div
        v-if="metricPlotted"
        v-tippy="tippy"
        class="show-chart-holder"
        :class="{ 'selected-metric-plotted': isThisMetricPlotted }"
        :title="'Plot Graph for ' + (params.data.selectedMetric || {}).name"
        @click="plotMetric"
      >
        <rb-icon
          class="rb-icon--small u-color-grey-white"
          :icon="'show-chart'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import rbSelectV2 from '@/components/pages/businessInsights/rbSelectV2';
import { cloneDeep } from 'lodash';

export default Vue.extend({
  components: {
    rbSelectV2
  },
  data() {
    return {
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      metricTooltip: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      footerLabel: null
    };
  },
  computed: {
    metricOptions() {
      if (this.params.metricOptions !== undefined) {
        return this.params.metricOptions;
      }
      let label = null;
      const options =
        this.$store.getters.getBenchmarkingTableMetricsList[
          this.params.storeKey
        ][this.params.data.rowCategory] || [];
      for (let iThOption = 0; iThOption < options.length; iThOption++) {
        if (options[iThOption].availableLabel) {
          label = options[iThOption].availableLabel;
          break;
        }
      }
      this.footerLabel = label;
      return (
        this.$store.getters.getBenchmarkingTableMetricsList[
          this.params.storeKey
        ][this.params.data.rowCategory] || []
      );
    },
    isThisMetricPlotted() {
      if (this.params.isThisMetricPlotted !== undefined) {
        return this.params.isThisMetricPlotted;
      }
      const plottedMetric =
        this.$store.getters.getBenchmarkingPlottedMetric[this.params.storeKey];
      const isSamePlottedMetric =
        plottedMetric?.key === this.params.data.selectedMetric?.key;
      return isSamePlottedMetric;
    },
    metadata() {
      return this.$store.getters.getBenchmarkingMetadata[this.params.storeKey]
        .data;
    },
    tooltipText() {
      if (this.params.data.selectedMetric.tooltip !== undefined) {
        return this.params.data.selectedMetric.tooltip;
      }
      return (
        this.metadata.metrics[this.params.data.selectedMetric.key].tooltip ||
        this.metadata.metrics[this.params.data.selectedMetric.key].label
      );
    },
    metricPlotted() {
      if (this.params.metricPlotted !== undefined) {
        return this.params.metricPlotted;
      } else {
        return true;
      }
    }
  },
  methods: {
    onMetricSelect(context, selection) {
      if (this.params.onMetricSelect !== undefined) {
        return this.params.onMetricSelect(
          this.params.data.selectedMetric,
          selection
        );
      }
      console.log(selection);
      let optionSelection = selection;
      if (selection.treePath.length > 0) {
        const metricKey = selection.selectedOptions[0].metricKey;
        optionSelection = [];
        optionSelection.push({
          key: metricKey,
          name: this.metadata.metrics[metricKey].label,
          disable: selection.selectedOptions[0].disable,
          tooltip: selection.selectedOptions[0].tooltip
        });
      } else {
        optionSelection = selection.selectedOptions;
      }
      if (optionSelection?.length > 0) {
        if (optionSelection[0].disable) {
          return;
        }
        this.params.data.oldSelectedMetric = cloneDeep(
          this.params.data.selectedMetric
        );
        this.$logger.logEvent({
          eventType: 'metric_change',
          type: 'Click',
          page: this.params.storeKey,
          widget: 'benchmarking',
          from:
            this.params.data.selectedMetric.label ||
            this.params.data.selectedMetric.name,
          to: optionSelection[0].label || optionSelection[0].name
        });
        this.params.data.selectedMetric = optionSelection[0];
        this.$store.dispatch('benchmarkingTableSelection', {
          data: this.params.data,
          storeKey: this.params.storeKey
        });
        // this.$store.dispatch()
      }
    },
    plotMetric() {
      console.log(this.params.data.selectedMetric.name);
      this.$store.dispatch('benchMarkingChangePlottedMetric', {
        data: this.params.data.selectedMetric,
        storeKey: this.params.storeKey
      });
    }
  }
});
</script>

<style scoped lang="css">
.table-select-holder {
  width: 164px;
}
.fit-content {
  width: fit-content;
}
.show-chart-holder {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007cf6;
  visibility: hidden;
}
.ag-row-hover .show-chart-holder {
  visibility: visible;
  opacity: 0.8;
}
.selected-metric-plotted {
  visibility: visible;
}
.selected-metric {
  font-size: 14px;
}
</style>
