<template>
  <section
    class="u-spacing-mh-m u-spacing-mb-l u-spacing-mt-0 u-display-flex u-flex-direction-column"
    @click.stop
  >
    <section>
      <p class="u-font-size-7">Region & Retailer</p>
      <div class="u-max-width-100 u-spacing-mt-xs">
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-bg-color-grey-light u-cursor-pointer optionsDropdown u-spacing-pt-s u-spacing-pl-s u-spacing-pb-s u-spacing-pr-s u-border-radius-s"
          @click.stop="openPopup"
        >
          <div
            class="u-display-flex u-flex-direction-row u-color-white-base u-flex-align-items-center"
          >
            <span class="u-spacing-mr-m u-country-logo-16 u-border-radius-xl">
              <img
                alt="Country Logo"
                class="u-width-100 u-height-100 u-border-radius-xl"
                :src="
                  selectedAccount.region.imgSrc2 ||
                  selectedAccount.region.imgSrc
                "
              />
            </span>
            <span class="u-font-size-5">{{
              selectedAccount.retailer && selectedAccount.retailer.label
            }}</span>
          </div>
          <rb-icon
            class="rb-icon--medium u-spacing-ml-s u-color-white-base"
            :icon="'settings'"
          />
        </div>
      </div>
    </section>

    <section
      v-if="
        selectedAccount.retailer &&
        selectedAccount.retailer.account &&
        selectedAccount.retailer.account.length > 1
      "
      class="account_section u-spacing-mt-s"
    >
      <p class="u-font-size-7">Account</p>
      <rb-select
        class="u-max-width-100 u-spacing-mt-xs"
        :send-details="true"
        :on-close="onAccountChange"
        :options="selectedAccount.retailer.account"
        :search-enable-threshold="50"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer optionsDropdown u-spacing-p-s u-spacing-pl-0 u-spacing-pt-0 u-spacing-pb-0"
        >
          <div
            class="u-display-flex u-flex-direction-row u-color-white-base u-flex-align-items-center"
          >
            <span
              class="u-font-size-6"
              data-cy="selectRetailer"
              >{{
                selectedAccount.account && selectedAccount.account.label
              }}</span
            >
            <rb-icon
              class="rb-icon--medium u-spacing-ml-s u-color-white-base"
              :icon="'caret-down'"
            />
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-width-100 retailerDropdown"
            :title="option.id"
          >
            {{ option.label }}
          </div>
        </template>
      </rb-select>
    </section>
    <confirmDialog v-if="showPopup">
      <div
        slot="content"
        class="content u-bg-color-grey-white u-flex-direction-column u-width-400 u-border-radius-s"
      >
        <div class="popup--header popup-dropdown-menu">
          <span class="u-display-flex u-flex-align-items-center">
            <span class="u-color-grey-white u-font-size-5 u-font-weight-600"
              >Region and Retailer Settings</span
            >
          </span>
          <span @click="closePopup">
            <rb-icon
              :icon="'cross'"
              class="rb-icon--small u-color-grey-white u-cursor-pointer"
            />
          </span>
        </div>
        <section class="body u-dialog-flex u-spacing-p-l">
          <section>
            <p
              class="u-font-size-6 u-color-grey-mid-light u-font-weight-600 u-spacing-pb-m"
            >
              Select Region
              <span class="u-color-grey-base"
                >({{ accountMapping.length }})</span
              >
            </p>
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              :title="accountMapping.length === 1 ? regionTooltipText : ''"
            >
              <rb-select
                :class="{
                  'u-pointer-events-none': accountMapping.length === 1
                }"
                :class-name="'u-regionRetailerDropdown'"
                class="u-max-width-100"
                :send-details="true"
                :on-close="onRegionChange"
                :options="accountMapping"
                :search-enable-threshold="3"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer optionsDropdown u-spacing-p-m u-border-radius-s u-color-grey-light u-border-width-s u-border-all u-border-color-grey-xxx-light"
                >
                  <div
                    class="u-display-flex u-flex-direction-row u-flex-justify-content-center u-flex-align-items-center"
                  >
                    <span
                      class="u-spacing-mr-m u-image-size-32 u-bg-color-grey-white"
                    >
                      <img
                        alt="Region Logo"
                        class="u-width-100 u-height-100 u-border-radius-xs"
                        :src="tempSelectedAccount.region.imgSrc"
                      />
                    </span>
                    <span class="u-font-size-5">{{
                      tempSelectedAccount.region.label
                    }}</span>
                  </div>
                  <rb-icon
                    v-if="accountMapping.length > 1"
                    class="rb-icon--medium u-spacing-ml-s"
                    :icon="'caret-down'"
                  />
                </div>
                <div
                  slot="header"
                  class="u-spacing-p-m u-font-size-6 u-color-grey-lighter u-spacing-pv-s"
                >
                  <p>Select Region:</p>
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-min-width-100 retailerDropdown"
                    :title="option.id"
                  >
                    <span
                      class="u-spacing-mr-m u-image-size-32 u-border-radius"
                    >
                      <img
                        alt="Region Logo"
                        class="u-width-100 u-height-100 u-object-fit-contain u-border-radius-xs u-spacing-ph-xs"
                        :src="option.imgSrc"
                      />
                    </span>
                    <span class="u-font-size-6">{{ option.label }}</span>
                  </div>
                </template>
              </rb-select>
            </div>
          </section>

          <section class="u-spacing-mt-l u-spacing-mp-xl">
            <p
              class="u-font-size-6 u-color-grey-mid-light u-font-weight-600 u-spacing-pb-m"
            >
              Select Retailer
              <span class="u-color-grey-base"
                >({{ tempSelectedAccount.region.retailers.length }})</span
              >
            </p>
            <div class="u-regionRetailerDropdown">
              <rb-select
                :class-name="'u-regionRetailerDropdown'"
                class="u-max-width-100 u-regionRetailerDropdown"
                :send-details="true"
                :on-close="onRetailerChange"
                :options="tempSelectedAccount.region.retailers"
                :search-enable-threshold="3"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer optionsDropdown u-spacing-p-m u-border-radius-s u-color-grey-light u-border-width-s u-border-all u-border-color-grey-xxx-light"
                >
                  <div
                    class="u-display-flex u-flex-direction-row u-flex-justify-content-center u-flex-align-items-center"
                  >
                    <span class="u-spacing-mr-m u-image-size-32">
                      <img
                        alt="Retailer Logo"
                        class="u-width-100 u-height-100 u-border-radius-s u-object-fit-contain u-border-radius-xs u-spacing-ph-xs"
                        :src="
                          tempSelectedAccount.retailer &&
                          tempSelectedAccount.retailer.imgSrc
                        "
                      />
                    </span>
                    <span class="u-font-size-5">{{
                      tempSelectedAccount.retailer &&
                      tempSelectedAccount.retailer.label
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-ml-s"
                    :icon="'caret-down'"
                  />
                </div>
                <div
                  slot="header"
                  class="u-spacing-p-m u-font-size-6 u-color-grey-lighter u-spacing-pv-s"
                >
                  <p>Select Retailer:</p>
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-width-100 retailerDropdown"
                    :title="option.id"
                  >
                    <span
                      class="u-spacing-mr-m u-image-size-32 u-object-fit-contain u-border-radius-s u-border-width-s u-border-all u-border-color-grey-xxx-light"
                    >
                      <img
                        alt="Retailer Logo"
                        class="u-width-100 u-height-100 u-object-fit-contain u-border-radius-xs u-spacing-ph-xs"
                        :src="option.imgSrc"
                      />
                    </span>
                    <span class="u-font-size-6">{{ option.label }}</span>
                  </div>
                </template>
              </rb-select>
            </div>
          </section>
        </section>
        <section class="popup-footer">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-spacing-pv-m u-spacing-ph-l"
          >
            <p
              class="u-spacing-mr-m u-flex-align-items-center u-font-size-6 u-color-grey-light u-cursor-pointer u-display-flex"
            ></p>
            <div class="u-display-flex u-flex-justify-content-space-between">
              <rb-button
                class="u-spacing-mr-s rb-button--hollow rb-button__size--s rb-button"
                :text="'Reset'"
                :size="'s'"
                :type="'hollow'"
                :click-fn="closePopup.bind(this)"
              />
              <rb-button
                :disabled="isValidSubmission"
                class="rb-button--filled rb-button__size--s rb-button"
                :size="'s'"
                :text="'Apply'"
                :type="'filled'"
                :click-fn="submit.bind(this)"
              />
            </div>
          </div>
        </section>
      </div>
    </confirmDialog>
  </section>
</template>

<script>
import confirmDialog from '@/components/basic/confirmDialog.vue';
import popupComponent from '@/components/basic/dialog/popupComponent.vue';
import constants from '@/utils/constants.js';

export default {
  components: {
    confirmDialog,
    popupComponent
  },
  props: {
    config: {
      type: [Array, Boolean, String],
      default: false
    },
    region: {
      type: String,
      default: ''
    },
    retailer: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      regionTooltipText:
        'Region selections are limited. Please enroll with CommerceIQ if you operate business in multiple regions.',
      showPopup: false,
      selectedAccount: {
        region: {},
        retailer: {},
        account: {}
      },
      tempSelectedAccount: {
        region: {},
        retailer: {},
        account: {}
      },
      accountMapping: []
    };
  },
  computed: {
    isValidSubmission() {
      const isRegionDifferent =
        this.tempSelectedAccount.region.region !==
        this.selectedAccount.region.region;
      const isRetailerDifferent =
        this.tempSelectedAccount.retailer.key !==
        this.selectedAccount.retailer.key;

      if (isRegionDifferent) {
        return false;
      }

      return !isRetailerDifferent;
    }
  },
  created() {
    this.accountMapping = this.config.map((item) => {
      const regionDetails = constants.regionMapping[item.region];
      return { ...item, ...regionDetails };
    });
    const euRegionItem = this.accountMapping?.find(
      (item) => item.region === constants.regions.EU
    );
    this.accountMapping = this.accountMapping?.filter(
      (item) => item.region !== constants.regions.EU
    );
    if (euRegionItem) {
      this.accountMapping?.unshift(euRegionItem);
    }

    this.accountMapping.forEach((item) => {
      if (item.retailers && item.retailers.length > 0) {
        item.retailers = item.retailers.map((retailer) => {
          const retailerDetails = constants.retailerMapping[retailer.key];
          return { ...retailer, ...retailerDetails };
        });
      }
    });

    this.selectedAccount.region = this.region
      ? this.accountMapping.find((item) => item.region === this.region)
      : this.accountMapping[0];

    if (this.retailer && this.selectedAccount.region) {
      this.selectedAccount.retailer =
        this.selectedAccount.region.retailers.find(
          (item) => item.key === this.retailer
        );
    } else {
      this.selectedAccount.retailer = this.selectedAccount.region.retailers[0];
    }

    const existingAccountName = localStorage.getItem('clientName');
    const isAccountActive =
      this.selectedAccount.region?.clientName === existingAccountName;

    if (!isAccountActive) {
      this.selectedAccount.account =
        this.selectedAccount.retailer?.account?.[0] || {};
    }

    if (this.selectedAccount.retailer?.account?.length > 0) {
      const accountIndex = this.selectedAccount.retailer.account.findIndex(
        (item) => item.clientName === existingAccountName
      );

      if (accountIndex !== -1) {
        this.selectedAccount.account =
          this.selectedAccount.retailer.account[accountIndex];
      } else {
        this.selectedAccount.account =
          this.selectedAccount.retailer.account?.[0] || {};
      }
    }
  },
  methods: {
    onRegionChange(index, selectedOption) {
      if (!selectedOption || selectedOption.length === 0) {
        return;
      }
      const existingRegion = this.tempSelectedAccount.region.region;
      if (existingRegion === selectedOption[0].region) {
        return;
      }
      if (selectedOption && selectedOption.length > 0) {
        this.tempSelectedAccount.region = selectedOption[0];
        this.tempSelectedAccount.retailer = selectedOption[0].retailers[0];
      }
    },
    onRetailerChange(index, selectedOption) {
      if (!selectedOption || selectedOption.length === 0) {
        return;
      }
      const existingRetailer = this.tempSelectedAccount.retailer.key;
      if (existingRetailer === selectedOption[0].key) {
        return;
      }
      if (selectedOption && selectedOption.length > 0) {
        this.tempSelectedAccount.retailer = selectedOption[0];
      }
    },
    onAccountChange(index, selectedOption) {
      if (!selectedOption || selectedOption.length === 0) {
        return;
      }
      const existingClientName = this.selectedAccount.account.clientName;
      if (existingClientName === selectedOption[0].clientName) {
        return;
      }
      this.selectedAccount.account = selectedOption[0];
      localStorage.setItem('clientName', selectedOption[0].clientName);
      this.$emit('change', this.selectedAccount); //
    },
    submit() {
      this.selectedAccount.region = this.tempSelectedAccount.region;
      this.selectedAccount.retailer = this.tempSelectedAccount.retailer;
      let accountName = '';
      if (this.selectedAccount.retailer.account) {
        accountName = this.selectedAccount.retailer.account[0].clientName;
      } else if (this.selectedAccount.region.clientName) {
        accountName = this.selectedAccount.region.clientName;
      }
      const existingAccountName = localStorage.getItem('clientName');
      if (existingAccountName !== accountName && accountName) {
        localStorage.setItem('clientName', accountName);
      } else if (!accountName) {
        localStorage.removeItem('clientName');
      }
      this.$emit('change', this.selectedAccount);
      this.closePopup();
    },
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.tempSelectedAccount.region = this.selectedAccount.region;
      this.tempSelectedAccount.retailer = this.selectedAccount.retailer;
      this.showPopup = true;
    }
  }
};
</script>

<style lang="css" scoped>
.u-country-logo-16 {
  width: 16px;
  height: 16px;
}
.u-width-400 {
  width: 400px;
}

.u-image-size-32 {
  width: 40px;
  height: 32px;
}
.popup--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  border-radius: 4px 4px 0px 0px;
}

.popup-footer {
  box-shadow: rgb(233, 234, 235) 0px -1px 2px 0px;
}
</style>
