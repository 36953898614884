<template>
  <div class="u-display-flex u-width-100">
    <data-cell
      :value="getOfferPrice"
      :type="type"
      :unit="unit"
      :tippy="tippy"
      :params="params"
      :na-text="naText"
      :na-tooltip="naTooltip"
    />
    <a
      v-if="getCacheLink"
      :href="getCacheLink"
      target="_blank"
    >
      <rb-icon
        :icon="'cached'"
        class="rb-icon--medium u-color-grey-lighter u-spacing-ml-m u-cursor-pointer cached-icon-hover"
      />
    </a>
  </div>
</template>

<script>
import dataCell from '@/components/widgets/tableComponents/cells/dataCell.vue';

export default {
  components: {
    dataCell
  },
  props: {
    containerClasses: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'string'
    },
    unit: {
      type: String,
      default: null
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'top-start',
          delay: [500, 0],
          animation: 'scale',
          interactive: true,
          maxWidth: '200px',
          theme: 'click-to-expand',
          duration: [150, 0]
        };
      }
    },
    params: {
      type: Object,
      default: null
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: null
    }
  },
  computed: {
    getCacheLink() {
      if (this.value?.cache_link === 'NULL') {
        return null;
      }
      return this.value?.cache_link;
    },
    getOfferPrice() {
      return this.value?.agg_comp_offer_price;
    }
  }
};
</script>

<style lang="css" scoped>
.cached-icon-hover:hover {
  color: #007cf6;
}
</style>
