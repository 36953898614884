import moment from 'moment';
import CONSTANTS from '@/utils/constants';
export const enumerateDaysBetweenDates = (
  startDate,
  endDate,
  interval = 'days'
) => {
  var now = startDate.clone();
  var dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, interval);
  }
  return dates;
};

export const getDateRange = (fromDate, toDate, interval = 'days') => {
  let startDate = moment(fromDate);
  const endDate = moment(toDate);
  const intervalLowerCase = interval.toLowerCase();
  if (intervalLowerCase === 'weeks') {
    startDate = moment(startDate).startOf('isoWeek');
  } else if (intervalLowerCase === 'months') {
    startDate = moment(startDate).startOf('month');
  }
  return enumerateDaysBetweenDates(startDate, endDate, intervalLowerCase);
};

export const getTimeRange = (from, to) => {
  const startTimeIndex = CONSTANTS.timeRangeOptions.indexOf(from);
  const endTimeIndex = CONSTANTS.timeRangeOptions.indexOf(to);
  return CONSTANTS.timeRangeOptions.slice(startTimeIndex, endTimeIndex + 1);
};

/**
 * Returns the week range in the past from a point of view,
 * if the POV lies in between the week, then an offset of 1 week is applied
 * @param {number} weekCount number of weeks you want the range of
 * @returns Returns the week range definition containing the startDate, endDate and the key and title for the week range
 */
export function generateWeeklyRange(weekCount, currentDate = moment()) {
  let key = '';
  let title = '';
  if (weekCount !== 1) {
    key = `last${weekCount}Week`;
    title = `Last ${weekCount} Week`;
  } else {
    // For last week there is a non-numeral key, and title thus a seperate case
    key = 'lastWeek';
    title = 'Last Week';
  }
  let pov = currentDate;

  const endDate = moment
    .utc(pov)
    .add(1, 'day')
    // Last week end of day
    .subtract(1, 'weeks')
    .endOf('week')
    .startOf('day');
  const startDate = moment
    .utc(pov)
    .add(1, 'day')
    // Last nth week start of day
    .subtract(weekCount, 'weeks')
    .startOf('week')
    .startOf('day');

  return {
    key: key,
    title: title,
    startDate,
    endDate
  };
}

export const mtdPreviousPeriodFn = (date) => {
  return date.subtract(1, 'month');
};

export const qtdPreviousPeriodFn = (date) => {
  return date.subtract(1, 'quarter');
};

export const ytdPreviousPeriodFn = (date) => {
  return date.subtract(1, 'year');
};

export const ytdSamePeriodLastYearFn = (date) => {
  return date.clone().subtract(1, 'year').startOf('day');
};

export const mtdSamePeriodLastYearFn = (date) => {
  return date.clone().subtract(1, 'year').startOf('day');
};

export const lastNWeeksPerviousPeriodFn = (date, diff) => {
  return date.subtract(diff + 1, 'day');
};

export const lastNWeeksSamePeriodLastYearFn = (date, id) => {
  /* 
  The logic that we follow here at CIQ to calculate the first week of the year is that the new year should have atleast 4 or more days in the week for it to be considered as the first week, else it rolls back to last year's last week. 
  For Example: 1 Jan 2022 should be considered as part of 2021's last week and not as part of 2022's first week because 2021 has more than 4 days in the same week.
  But moment.js considers whichever week having 1 Jan as the first week of the year. 
  The below logic is written to tackle that.
  */

  let firstDayOfYear = date.clone().startOf('year').day();
  let isMomentFirstWeekOurFirstWeek = firstDayOfYear <= 3;
  let weekNumberOfCurrentDate = date.clone().startOf('day').week();
  let initWeekNumberOfCurrentDate = weekNumberOfCurrentDate;
  let isCurrentWeekNextYearsFirstWeek = false;
  let isCurrentWeekPrevYearsLastWeek = false;

  /* 
  If Moment.js returns the week number as 1, it can either be last year's last week (according to our logic explained above) or it can be the current year's first week.
  But moment.js does not tell us that. So we call momentFirstWeekConditionChecker function to identify which part it lies in.
  */
  if (weekNumberOfCurrentDate === 1) {
    let {
      updatedWeekNumberOfCurrentDate,
      isNextYearsFirstWeek,
      isPrevYearsLastWeek
    } = momentFirstWeekConditionChecker(date);

    weekNumberOfCurrentDate = updatedWeekNumberOfCurrentDate;
    isCurrentWeekNextYearsFirstWeek = isNextYearsFirstWeek;
    isCurrentWeekPrevYearsLastWeek = isPrevYearsLastWeek;
  }

  // If the week is part of the current year only, ww call weekInTheMiddleOfYear function to get the corresponding week from last year.
  if (!isCurrentWeekNextYearsFirstWeek && !isCurrentWeekPrevYearsLastWeek) {
    return weekInTheMiddleOfYear({
      date,
      weekNumberOfCurrentDate,
      initWeekNumberOfCurrentDate,
      isMomentFirstWeekOurFirstWeek,
      id
    });
  } else if (isCurrentWeekNextYearsFirstWeek) {
    /* 
    Now we know that the week we are in is part of the next year's first week, so the corresponding prev date will be THIS YEAR's Firs week. So we call momentFirstWeekConditionChecker to check if the first week of THIS year is according to our logic or not. If it is, then we return that week, else we go one week forward.
    For eg. Dry run this scenario for Dec 31 2019
    */
    let { updatedWeekNumberOfCurrentDate } = momentFirstWeekConditionChecker(
      date.clone().startOf('year')
    );

    if (updatedWeekNumberOfCurrentDate === 1) {
      return finalDateGenerator(date.clone().startOf('year'), id);
    } else {
      return finalDateGenerator(
        date.clone().startOf('year').add(1, 'week'),
        id
      );
    }
  } else if (isCurrentWeekPrevYearsLastWeek) {
    /* 
    Now we know that the week we are in is part of the prev year's last week, so the corresponding prev date will be LAST TO LAST YEAR'S LAST WEEK, so here we pass that wek to the momentFirstWeekConditionChecker function to check if that week is NOT prev year's last week.
    */
    let { updatedWeekNumberOfCurrentDate, isNextYearsFirstWeek } =
      momentFirstWeekConditionChecker(
        date.clone().subtract(2, 'year').endOf('year').startOf('day')
      );

    if (!isNextYearsFirstWeek) {
      /* 
      This condition is specifically written to handle 52-53 week year discrepancy. If we get a difference in the week number for the last week of the correspoding year, then we have to adjust it accordingly.
      The logic states:
      if current week is 53 and last year's last week is 52. Compare them.
      If current week is 52 and last year's last week is 53. Then go one week back and get 52nd week.
      This subtraction handles that.
      This scenario can be tested with the week: Dec 26 2021 - Jan 1 2022 (52nd week for 2021)
      The corresponding last year week is: Dec 20 2020 - Dec 26 2020 (52nd week for 2020 which is a 53 week year)
      */
      let weekSubtracted =
        updatedWeekNumberOfCurrentDate > weekNumberOfCurrentDate ? 1 : 0;
      return finalDateGenerator(
        date
          .clone()
          .subtract(2, 'year')
          .endOf('year')
          .subtract(weekSubtracted, 'week'),
        id
      );
    } else {
      /* 
      If the week found is part of next year's first week, just go one week back and return it.
      */
      return finalDateGenerator(
        date.clone().subtract(2, 'year').endOf('year').subtract(1, 'week'),
        id
      );
    }
  }
};

export const customPerviousPeriodFn = (date, diff) => {
  return date.subtract(diff + 1, 'day');
};

export const customSamePeriodLastYearFn = (date) => {
  return date.clone().subtract(1, 'year').startOf('day');
};

export const momentFirstWeekConditionChecker = (date) => {
  let isNextYearsFirstWeek = false;
  let isPrevYearsLastWeek = false;
  let weekNumberOfCurrentDate = date.clone().startOf('day').week();

  /* 
  This condition checks if the week's last day has a year greater than the current day's year.
  This condition satisfies when we are in the last 7 days of a year.
  True for 31 Jan 2020
  */
  if (
    date.clone().week(date.clone().week()).endOf('week').year() >
    date.clone().year()
  ) {
    /* 
    Here we check if the week belongs to the next year or prev year.
    */
    let lastDayOfYear = date.clone().endOf('year').day();
    if (lastDayOfYear >= 3) {
      weekNumberOfCurrentDate = date.clone().subtract(1, 'week').week() + 1;
      weekNumberOfCurrentDate =
        date.clone().startOf('year').day() <= 3
          ? weekNumberOfCurrentDate
          : weekNumberOfCurrentDate - 1;
    } else {
      isNextYearsFirstWeek = true;
    }
    /* 
    The below condition checks if the week's first day has a year smaller than the current day's year.
    This condition satisfies when we are in the first 7 days of a year.
    True for 1 Jan 2020
    */
  } else if (
    date.clone().week(date.clone().week()).startOf('week').year() <=
    date.clone().year()
  ) {
    /* 
    Here we check if the week belongs to the next year or prev year.
    */
    let firstDayOfYear = date.clone().startOf('year').day();
    if (firstDayOfYear > 3) {
      isPrevYearsLastWeek = true;
      weekNumberOfCurrentDate = date.clone().subtract(1, 'week').week() + 1;
      weekNumberOfCurrentDate =
        date.clone().subtract(1, 'year').startOf('year').day() <= 3
          ? weekNumberOfCurrentDate
          : weekNumberOfCurrentDate - 1;
    }
  }

  return {
    updatedWeekNumberOfCurrentDate: weekNumberOfCurrentDate,
    isNextYearsFirstWeek,
    isPrevYearsLastWeek
  };
};

export const finalDateGenerator = (date, id) => {
  if (id === 'start') {
    return date.startOf('week');
  } else {
    return date.endOf('week');
  }
};

/* 
This function runs when the week for the date given is not the first or the last week of a year.
*/
export const weekInTheMiddleOfYear = ({
  date,
  weekNumberOfCurrentDate,
  initWeekNumberOfCurrentDate,
  isMomentFirstWeekOurFirstWeek,
  id
}) => {
  let prevYearFirstDayOfYear = date
    .clone()
    .subtract(1, 'year')
    .startOf('year')
    .day();
  let isMomentPrevYearFirstWeekOurFirstWeek = prevYearFirstDayOfYear <= 3;

  let weekCalculated = weekNumberOfCurrentDate;

  if (initWeekNumberOfCurrentDate !== 1) {
    /* 
    Here we are adjusting the week number according to the first week of the year and it is complies with our week condition.
    We are not doing this if the week number is 1 because we already have done it in momentFirstWeekConditionChecker function which runs before this.
    */
    weekCalculated = isMomentFirstWeekOurFirstWeek
      ? weekNumberOfCurrentDate
      : weekNumberOfCurrentDate - 1;
  }

  /* 
  Here we are adjusting the week number according to the last year's corresponding week.
  */
  weekCalculated = isMomentPrevYearFirstWeekOurFirstWeek
    ? weekCalculated
    : weekCalculated + 1;

  /* 
  The below logic runs when the week number is above 52. Sometimes the above logic adjusts the week number to 53,54 because of the first week not complying with our logic, since the previous year wont have 53 or 54 weeks (as 53 weeks happen once in every 4 or so years). We add a week to account for it.
  For Example: For Week Dec 25 2022 - Dec 31 2022. The week number is 52 but the moment adjusts it to 53.
  The corresponding week should be Dec 26 2021 - Jan 1 2022. But to access this week. We firstly go to the last week because you are not sure to directly find week 53. And manually add one week to it.
  */
  let weekSubtraction = 0;
  if (weekCalculated >= 53) {
    weekSubtraction =
      !isMomentPrevYearFirstWeekOurFirstWeek || !isMomentFirstWeekOurFirstWeek
        ? -1
        : 0;
  }

  return finalDateGenerator(
    date
      .clone()
      .subtract(1, 'year')
      .startOf('year')
      .week(Math.min(weekCalculated, 52))
      .subtract(weekSubtraction, 'week'),
    id
  );
};

export const checkIfGivenDurationHas53rdWeek = (startDate, endDate) => {
  let currentDate = startDate.clone();
  let doesDurationHas53rdWeek = false;

  while (currentDate <= endDate) {
    let { updatedWeekNumberOfCurrentDate } =
      momentFirstWeekConditionChecker(currentDate);

    let diffInDays = endDate.diff(startDate, 'days');

    if (updatedWeekNumberOfCurrentDate === 53 && diffInDays > 7) {
      doesDurationHas53rdWeek = true;
    }

    currentDate = currentDate.clone().add(1, 'week');
  }

  return doesDurationHas53rdWeek;
};

export const dateFormat = (value) => {
  return value ? value.format('MMM DD, YYYY') : '';
};
function addMandatoryDateRanges(finalDateRanges, today, listOfDateRanges = []) {
  const weekConfig = {
    startDay: 'Saturday',
    endDay: 'Friday'
  };
  const mandatoryRanges = {
    mtd: {
      key: 'mtd',
      title: 'Month to Date',
      startDate: moment.utc(today).startOf('month'),
      endDate: moment.utc(today).startOf('day'),
      prevPeriodFn: mtdPreviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    qtd: {
      key: 'qtd',
      title: 'Quarter to Date',
      startDate: moment.utc(today).startOf('quarter').startOf('day'),
      endDate: moment.utc(today).startOf('day'),
      prevPeriodFn: qtdPreviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    ytd: {
      key: 'ytd',
      title: 'Year to Date',
      prevPeriodFn: ytdPreviousPeriodFn,
      samePeriodLastYearFn: ytdSamePeriodLastYearFn,
      startDate: moment.utc(today).startOf('year'),
      endDate: moment.utc(today).startOf('day')
    },
    lastYear: {
      key: 'lastYear',
      title: 'Last Year',
      startDate: moment
        .utc(today)
        .subtract(1, 'year')
        .startOf('year')
        .startOf('day'),
      endDate: moment.utc(today).subtract(1, 'year').endOf('year'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    previousWeek: {
      key: 'previousWeek',
      title: 'Previous Week',
      startDate: moment
        .utc(today)
        .subtract(1, 'weeks')
        .startOf('week')
        .isoWeekday(weekConfig.startDay)
        .startOf('day'),
      endDate: moment
        .utc(today)
        .subtract(1, 'weeks')
        .endOf('week')
        .isoWeekday(weekConfig.endDay)
        .startOf('day'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    previousMonth: {
      key: 'previousMonth',
      title: 'Previous Month',
      startDate: moment.utc(today).subtract(1, 'month').startOf('month'),
      endDate: moment.utc(today).subtract(1, 'month').endOf('month'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    previousQuarter: {
      key: 'previousQuarter',
      title: 'Previous Quarter',
      startDate: moment.utc(today).subtract(1, 'quarter').startOf('quarter'),
      endDate: moment.utc(today).subtract(1, 'quarter').endOf('quarter'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    }
  };
  listOfDateRanges.forEach((dateRange) => {
    finalDateRanges[dateRange] = mandatoryRanges[dateRange];
  });
}

export function getDateRanges(today, mode, count, customMandatoryDateRanges) {
  const dayRanges = {
    last7Days: {
      key: 'last7Days',
      title: 'Last 7 days',
      startDate: moment.utc(today).subtract(6, 'day').startOf('day'),
      endDate: moment.utc(today).startOf('day'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    last30Days: {
      key: 'last30Days',
      title: 'Last 30 days',
      startDate: moment.utc(today).subtract(29, 'day').startOf('day'),
      endDate: moment.utc(today).startOf('day'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    last90Days: {
      key: 'last90Days',
      title: 'Last 90 days',
      startDate: moment.utc(today).subtract(89, 'day').startOf('day'),
      endDate: moment.utc(today).startOf('day'),
      prevPeriodFn: customPerviousPeriodFn,
      samePeriodLastYearFn: customSamePeriodLastYearFn
    },
    mtd: {
      key: 'mtd',
      title: 'Month to Date',
      type: 'month',
      samePeriodLastYearFn: mtdSamePeriodLastYearFn,
      prevPeriodFn: mtdPreviousPeriodFn,
      startDate: moment.utc(today).startOf('month'),
      endDate: moment.utc(today).startOf('day')
    }
  };

  const weekRanges = {
    lastWeek: {
      key: 'lastWeek',
      title: 'Last Week',
      startDate: moment
        .utc(today)
        .add(1, 'day')
        .subtract(1, 'weeks')
        .startOf('week')
        .startOf('day'),
      endDate: moment
        .utc(today)
        .add(1, 'day')
        .subtract(1, 'weeks')
        .endOf('week')
        .startOf('day')
    }
  };

  for (let i = 1; i <= count; i++) {
    const startDate = moment
      .utc(today)
      .add(1, 'day')
      .subtract(i + 1, 'weeks')
      .startOf('week')
      .startOf('day');
    const endDate = moment
      .utc(today)
      .add(1, 'day')
      .subtract(i + 1, 'weeks')
      .endOf('week')
      .startOf('day');
    weekRanges['W - ' + i] = {
      key: 'W - ' + i,
      title: dateFormat(startDate) + ' - ' + dateFormat(endDate),
      startDate,
      endDate
    };
  }

  const monthRanges = {
    mtd: {
      key: 'mtd',
      title: 'Month to Date',
      samePeriodLastYearFn: mtdSamePeriodLastYearFn,
      prevPeriodFn: mtdPreviousPeriodFn,
      startDate: moment.utc(today).startOf('month'),
      endDate: moment.utc(today).startOf('day')
    }
  };

  for (let i = 1; i <= count; i++) {
    const startDate = moment
      .utc(today)
      .subtract(i, 'months')
      .startOf('month')
      .startOf('day');
    const endDate = moment
      .utc(today)
      .subtract(i, 'months')
      .endOf('month')
      .startOf('day');
    monthRanges['M - ' + i] = {
      key: 'M - ' + i,
      title: startDate.clone().format('MMMM'),
      startDate,
      endDate
    };
  }

  const quarterRanges = {
    qtd: {
      key: 'qtd',
      title: 'Quarter to Date',
      startDate: moment.utc(today).startOf('quarter').startOf('day'),
      endDate: moment.utc(today).startOf('day')
    }
  };

  for (let i = 1; i <= count; i++) {
    const startDate = moment
      .utc(today)
      .subtract(i, 'quarters')
      .startOf('quarter')
      .startOf('day');
    const endDate = moment
      .utc(today)
      .subtract(i, 'quarters')
      .endOf('quarter')
      .startOf('day');
    quarterRanges['Q - ' + i] = {
      key: 'Q - ' + i,
      title:
        'Q' +
        startDate.clone().quarter() +
        ', ' +
        startDate.clone().format('YYYY'),
      startDate,
      endDate
    };
  }

  let finalDateRanges;

  if (mode === 'week') {
    finalDateRanges = weekRanges;
  } else if (mode === 'month') {
    finalDateRanges = monthRanges;
  } else if (mode === 'quarter') {
    finalDateRanges = quarterRanges;
  } else {
    finalDateRanges = dayRanges;
  }

  addMandatoryDateRanges(finalDateRanges, today, customMandatoryDateRanges);
  return finalDateRanges;
}
