import DashboardDataService from '@/utils/common/dashboardDataService.js';
import {
  groupApis,
  getDynamicColumnDefs,
  replacePlaceHolderWithData,
  createDictionary
} from '@/utils/common/dashboard-service-utils';
import { isEmpty, cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';

export default class LowInventoryDataService extends DashboardDataService {
  getTableColumns(operations, widgetRequestParams, columnCellMapping) {
    try {
      this.load = true;
      const metricKeys =
        this.metadata.metadata.metricsForExpandedTable ??
        Object.keys(this.metadata.metrics);
      const finalTableMetrics = cloneDeep(this.metadata.metrics);
      let columns = createDictionary(metricKeys, finalTableMetrics);
      let colDefs = getDynamicColumnDefs(
        columns,
        this.metadata?.metadata?.defaultOperations || operations?.orderByList
          ? operations
          : null || {
              orderByList: widgetRequestParams?.[':orderByList'] || []
            },
        columnCellMapping
      );
      if (this.metadata?.metadata?.stripColumn) {
        colDefs = colDefs.map((colDef) => ({
          ...colDef,
          colSpan: (params) => {
            const status =
              colDef.field ===
              this.metadata.metadata.stripColumn.stripStartColumn
                ? params.data?.[
                    this.metadata.metadata.stripColumn.stripStartColumnData
                  ]
                : null;
            if (['pending', 'rejected'].includes(status)) {
              return colDefs.filter((col) => !col.pinned).length;
            } else {
              return 1;
            }
          }
        }));
      }
      this.load = false;
      return colDefs;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  async getDataList(operations, widgetRequestParams) {
    try {
      this.load = true;
      const metricsForTable =
        this.metadata.metadata.metricsForExpandedTable || [];
      const filteredMetrics = {};
      metricsForTable.forEach((metric) => {
        filteredMetrics[metric] = this.metadata.metrics[metric];
      });
      this.tableGrouping = groupApis(filteredMetrics);
      const metricsResponseData = {};
      for (const groupName of Object.keys(this.tableGrouping)) {
        const api = this.tableGrouping[groupName].api;
        const request = this.prepareRequest(
          api.request,
          widgetRequestParams,
          operations
        );
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        metricsResponseData[groupName] = response?.data;
      }

      this.load = false;
      return metricsResponseData;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  prepareRequest(requestTemplate, widgetRequestParams, operations) {
    const request = replacePlaceHolderWithData(
      requestTemplate,
      widgetRequestParams
    );
    request.operations = { ...operations, ...request.operations };

    if (
      !widgetRequestParams?.keepExistingDimensionNameValueList &&
      !isEmpty(widgetRequestParams.replaceEntityInDimensionNameValueList)
    ) {
      this.updateDimensionNameValueList(
        request.where.dimensionNameValueList,
        widgetRequestParams.replaceEntityInDimensionNameValueList,
        request,
        widgetRequestParams
      );
    }

    if (widgetRequestParams[':metricsList']?.length > 0) {
      request.metricsList = widgetRequestParams[':metricsList'];
    }

    if (!request?.where?.date?.from) {
      delete request.where.date;
      delete request.where.pvpDate;
    }

    if (widgetRequestParams.resetColumnOrder) {
      request.operations.orderByList = [];
    }

    request.enablePaginationCount = true;
    return request;
  }

  updateDimensionNameValueList(
    dimensionNameValueList,
    replaceEntityInDimensionNameValueList,
    request,
    widgetRequestParams
  ) {
    for (const item of dimensionNameValueList) {
      item.dimensionValue =
        replaceEntityInDimensionNameValueList[item.dimensionValue] ||
        item.dimensionValue;
    }

    const metricAndDimensionList = dimensionNameValueList.concat(
      request.metricsList || [],
      request.dimensionsList || []
    );
    for (const bundleDataDimension of widgetRequestParams[
      ':dimensionNameValueList'
    ]) {
      if (
        metricAndDimensionList.some(
          (item) => item.dimensionName === bundleDataDimension.dimensionName
        )
      ) {
        dimensionNameValueList.push(bundleDataDimension);
      }
    }
  }
}
