<template>
  <div class="u-width-100">
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start u-flex-wrap-yes"
    >
      <div class="u-display-flex u-flex-wrap-yes u-flex-align-items-center">
        <div
          v-for="(val, index) in localMetricDisplayList"
          :key="index"
          :val="val"
          :class="{ disabled: !computedSelectedMetric[val.key] }"
          class="metric-card-hover-trigger u-spacing-mt-l"
          @click.stop="selectNewMetric(index, val)"
        >
          <div
            class="custom-chart-events-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
          >
            <!-- <div class="cross-button-holder" v-if="index > minimumMetric - 1">
                             <div class="cross-icon" v-on:click.stop="deleteMetric(index)">
                                    <rb-icon class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter" :icon="'cross'" ></rb-icon>
                             </div>
            </div>-->
            <div class="u-spacing-p-xs">
              <div
                class="u-flex-complete-center u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  v-tippy="{
                    placement: 'bottom',
                    arrow: true,
                    theme: 'page-snapshot'
                  }"
                  class="rb-icon--x-medium u-spacing-mr-s"
                  :icon="val.icon"
                  :class="val.color"
                  :title="
                    !computedSelectedMetric[val.key]
                      ? 'Show Event'
                      : 'Hide Event'
                  "
                />
                <rb-select
                  v-if="val"
                  :width="'240px'"
                  class="u-spacing-mr-s"
                  :context="[index]"
                  :send-details="true"
                  :on-close="metricSelected"
                  :options="computedMetricsList"
                  :class-name="'campaigns-select'"
                  :search-enable-threshold="searchEnableThreshold"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                    >
                      <span class="u-font-size-5">{{ val.key }}</span>
                      <span
                        class="u-font-size-5 u-spacing-ml-s"
                        :class="{
                          'u-color-grey-xx-light': val.eventCount === 0,
                          'u-color-grey-mid-light': val.eventCount !== 0
                        }"
                        >{{ val.eventCount }}</span
                      >
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <div
                        class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                      >
                        <div class="u-display-flex u-flex-align-items-center">
                          <rb-icon
                            :icon="option.icon"
                            :class="option.color"
                            class="u-spacing-mr-s"
                          />
                          <p
                            class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                          >
                            {{ option.title }}
                          </p>
                        </div>
                        <span
                          :class="{
                            'u-color-grey-xx-light': option.eventCount === 0,
                            'u-color-grey-mid-light': option.eventCount !== 0
                          }"
                          >{{ option.eventCount }}</span
                        >
                      </div>
                    </div>
                  </template>
                </rb-select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!disableAddNewEvent">
          <rb-select
            :width="'240px'"
            class="u-spacing-mr-m"
            :send-details="true"
            :on-close="addNewMetric"
            :options="computedMetricsList"
            :class-name="'campaigns-select u-height-100 select-trigger-height-100'"
            :search-enable-threshold="searchEnableThreshold"
          >
            <div
              slot="trigger"
              class="metric-card-hover-trigger u-spacing-mt-l"
            >
              <div
                class="custom-chart-events-legend u-display-flex u-flex-align-items-center u-font-size-5 u-color-grey-lighter u-position-relative u-cursor-pointer workbench-hover-card add-event-card u-flex-justify-content-center"
              >
                <rb-icon
                  class="rb-icon--small u-cursor-pointer u-spacing-mr-xs icon-add-circle-fill"
                  :icon="'add-circle-fill'"
                />
                <span class=""> Add event </span>
              </div>
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                <div
                  class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-start"
                >
                  <rb-icon
                    :icon="option.icon"
                    :class="option.color"
                    class="u-spacing-mr-s"
                  />
                  <p
                    class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                  >
                    {{ option.title }}
                  </p>
                </div>
              </div>
            </template>
          </rb-select>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="css">
.metric-card-hover-trigger:hover .cross-icon {
  visibility: visible !important;
}

.cross-button-holder {
  display: flex;
  justify-content: flex-end;
}
.cross-button-holder .cross-icon {
  visibility: hidden;
  padding-right: 0.8rem;
  padding-top: 0.8rem;
}
.custom-chart-events-legend {
  box-shadow: 0 0 4px 0 #caccce;
  min-width: 120px;
  min-height: 32px;
  padding: 1.6rem;
  border-width: 3px;
  border: top;
  border-radius: 2px;
  margin-right: 1.6rem;
}
</style>

<script>
import chart from './chart.vue';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import { differenceBy } from 'lodash';
export default {
  components: {
    chart,
    metric
  },
  mixins: [widgetsUtil],
  props: {
    metricDisplayList: {
      type: Array,
      default: () => []
    },
    metricsList: {
      type: Array,
      default: () => []
    },
    defaultSelectMetric: {
      type: Array,
      default: () => []
    },
    selectedMetricLimit: {
      type: Number,
      default: 2
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Object,
      default: () => {}
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    disableAddNewEvent: {
      type: Boolean,
      default: false
    },
    searchEnableThreshold: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      metricsSelectedIndex: [0, 1],
      chartWkbenchAxes: {},
      selectedMetric: [],
      localMetricDisplayList: []
    };
  },
  computed: {
    computedSelectedMetric() {
      const returnData = {};
      for (let i = 0; i < this.selectedMetric.length; i++) {
        returnData[this.selectedMetric[i].key] = this.selectedMetric[i];
      }
      return returnData;
    },
    metricColors() {
      // Not being used in the events.
      const _colors = {};
      var colors = this.getColorPattern;
      for (let i = 0; i < this.localMetricDisplayList.length; i++) {
        _colors[this.localMetricDisplayList[i].key] = colors[i];
      }
      return _colors;
    },
    computedMetricsList() {
      const differenceByList = this.selectedMetric.concat(
        this.localMetricDisplayList
      );
      return differenceBy(this.metricsList, differenceByList, (a, b) => {
        return a.key;
      });
    }
  },
  watch: {
    defaultSelectMetric(newValue) {
      this.selectedMetric = [...newValue];
      // When default metric is changed, need to emit the event out.
      this.emitEventOut();
    },
    metricDisplayList(newValue) {
      // this.localMetricDisplayList = newValue;
      this.localMetricDisplayList = this.genratingFormatedData(newValue);
      // When metric list is changed, need to emit the event out.
      this.emitEventOut();
    }
  },
  created() {
    this.selectedMetric = this.defaultSelectMetric;
    this.localMetricDisplayList = this.genratingFormatedData(
      JSON.parse(JSON.stringify(this.metricDisplayList))
    );
    this.emitEventOut();
  },
  methods: {
    genratingFormatedData(newValue) {
      const returnArr = [];
      for (let i = 0; i < newValue.length; i++) {
        const searchKey = newValue[i].key;
        const indexValue = this.metricsList.findIndex((item) => {
          return item.key === searchKey;
        });
        if (indexValue > -1) {
          returnArr.push(this.metricsList[indexValue]);
        }
      }
      return returnArr;
    },
    // On Select of New Metric
    selectNewMetric(context, val) {
      const valueIndex = this.selectedMetric.findIndex((item) => {
        return item.key === val.key;
      });
      if (valueIndex === -1) {
        this.smartPushQueue(val);
        this.chartWkbenchAxes[this.localMetricDisplayList[context].key] = 'y';
      } else {
        this.queueDelete(val.key);
      }
    },
    // On Select of new Metric of a card through drop down
    metricSelected(context, val) {
      if (val && val.length > 0) {
        // let selectedIndex = val[0].selectedIndex;
        const selectedIndex = this.computedMetricsList.findIndex((item) => {
          return item.key === val[0].key;
        });
        const tempOldMetric = this.localMetricDisplayList[context[0]];
        if (
          this.selectedMetric.findIndex((item) => {
            return item.key === this.computedMetricsList[selectedIndex].key;
          }) === -1
        ) {
          this.localMetricDisplayList[context[0]] =
            this.computedMetricsList[selectedIndex];
          this.chartWkbenchAxes[this.computedMetricsList[selectedIndex].key] =
            'y';
          this.localMetricDisplayList = [...this.localMetricDisplayList];
        }
        this.queueReplace(
          this.localMetricDisplayList[context[0]],
          tempOldMetric
        );
      }
    },
    // Pushing into the queue and queue is full we removed the last element (First in last out idea)
    smartPushQueue(value) {
      if (this.selectedMetric.length >= this.selectedMetricLimit) {
        const removedValue = this.selectedMetric.shift();
        this.$emit('removedMetric', removedValue);
      }
      this.$emit('addMetric', value);
      this.selectedMetric.push(value);
      this.emitEventOut();
    },
    // Searching for the item in the queue and replacing it with newValue
    queueReplace(newValue, oldValue) {
      const indexLocation = this.selectedMetric.findIndex((item) => {
        return item.key === oldValue.key;
      });
      if (indexLocation === -1) {
        this.smartPushQueue(newValue);
      } else {
        this.$emit('removedMetric', this.selectedMetric[indexLocation]);
        this.selectedMetric[indexLocation] = newValue;
        this.selectedMetric = [...this.selectedMetric];
        this.$emit('addMetric', this.selectedMetric[indexLocation]);
        this.emitEventOut();
      }
    },
    // Removing the element from the queue if found in the array
    queueDelete(value) {
      const valueIndex = this.selectedMetric.findIndex((item) => {
        // console.log(item.key, value)
        return item.key === value;
      });
      if (valueIndex !== -1) {
        this.$emit('removedMetric', this.selectedMetric[valueIndex]);
        this.selectedMetric.splice(valueIndex, 1);
      }
      this.emitEventOut();
    },
    // Just emitting the event
    emitEventOut() {
      const obj = {
        selectedMetric: this.selectedMetric,
        metricColors: this.metricColors,
        metricDisplayList: this.localMetricDisplayList
      };
      this.$emit('selectedList', obj);
    },
    // Adding new metric and pushing into the queue
    addNewMetric(context, val) {
      if (val && val.length > 0) {
        // let selectedIndex = val[0].selectedIndex;
        // find out the index in the metricslist
        const index = this.metricsList.findIndex(
          (item) => item.key === val[0].key
        );
        this.localMetricDisplayList.push(this.metricsList[index]);
        this.smartPushQueue(this.metricsList[index]);
      }
    },
    // Deleting the metric and deleting from the queue
    deleteMetric(index) {
      this.queueDelete(this.localMetricDisplayList[index].key);
      this.localMetricDisplayList.splice(index, 1);
    }
  }
};
</script>
