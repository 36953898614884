import { merge } from 'lodash';
import { graphsPlottedTextConfig } from '@/pages/campaign-management/constants.js';
export const getDataFromRowNode = (rowNode) => {
  return rowNode.data;
};

export const extractValues = (data, actionsPayload) => {
  return Object.keys(actionsPayload).reduce((acc, item) => {
    let alias = item;
    if (actionsPayload[item].alias) {
      alias = actionsPayload[item].alias;
    }
    acc[alias] = data[item];
    return acc;
  }, {});
};

export const payloadGenerator = (
  dynamicKeys,
  staticValues,
  newValuesObject,
  dataObject
) => {
  const {
    actionPayloadKeys,
    viewPayloadKeys,
    primaryKey,
    previousEntityValue
  } = dynamicKeys;
  const actionPayload = extractValues(dataObject, actionPayloadKeys);
  const viewPayload = extractValues(dataObject, viewPayloadKeys);
  const staticPayload = {
    ...staticValues,
    primaryKey: dataObject[primaryKey],
    previousEntityValue: dataObject[previousEntityValue],
    actionPayload: actionPayload,
    viewPayload: viewPayload
  };
  return merge(staticPayload, newValuesObject);
};

export const plotRowsPayloadExtraction = (
  rowNodes,
  payloadKey,
  shouldPassOperator = true
) => {
  const dataArray = rowNodes.map((item) => getDataFromRowNode(item));
  const dimensionNameValueList = dataArray.map((item) => ({
    dimensionName: payloadKey,
    dimensionValue: item[payloadKey],
    ...(shouldPassOperator ? { operator: 'ILIKE' } : {})
  }));
  return {
    dimensionNameValueList
  };
  // this points to table-widget because have used call in the method call
};

export function generatePlotRowChip(plotRowData) {
  const textArrayConfig = graphsPlottedTextConfig(
    plotRowData.dimensionNameValueList.length
  );
  return [
    {
      component: 'TextChip',
      props: {
        textArray: textArrayConfig.textArray,
        chipIcons: textArrayConfig.chipIcons
      },
      events: {
        removePlottedEntity: () => {
          this.$emit('removePlottedEntity');
        }
      }
    }
  ];
}
