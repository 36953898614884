export default {
  map: {
    status: {
      title: 'Status',
      type: 'status',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 1,
      keyword_keyOrder: 1,
      pinned: 'left',
      toolTipText: 'Action status for campaign',
      keyword_toolTipText: 'Action status for keyword'
    },
    keyword_text: {
      title: 'Keyword',
      type: 'String',
      toolTipText: 'Keyword',
      page: ['keyword'],
      pinned: 'left',
      campaign_keyOrder: 3,
      keyword_keyOrder: 2
    },
    campaign_name: {
      title: 'Campaign Name',
      toolTipText: 'Campaign name of which the keyword is a part of',
      type: 'String',
      pinned: 'left',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 2,
      keyword_keyOrder: 3
    },
    suggested_action: {
      title: 'Suggested Action',
      toolTipText: 'Action recommended by Budget Optimizer',
      type: 'String',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 3,
      keyword_keyOrder: 3
    },
    current_budget: {
      title: 'Recommended Budget',
      toolTipText: 'Keyword bid recommended by Budget Optimizer',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign'],
      campaign_keyOrder: 4
    },
    current_bid: {
      title: 'Recommended Bid',
      toolTipText: 'Campaign budget recommended by Budget Optimizer',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword'],
      keyword_keyOrder: 4
    },
    previous_bid: {
      title: 'Current bid',
      toolTipText: 'Current bid amount on the keyword',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword'],
      campaign_keyOrder: 5
    },
    previous_budget: {
      title: 'Current Budget',
      toolTipText: 'Current daily budget allocated to the campaign',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign'],
      campaign_keyOrder: 5
    },
    campaign_type: {
      title: 'Campaign type',
      type: 'String',
      toolTipText: 'Type of campaign (Sponsored products, Sponsored brands)',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 6,
      keyword_keyOrder: 6
    },
    start_date: {
      title: 'Start Date',
      toolTipText: 'Starte date of the campaign',
      type: 'date',
      page: ['campaign'],
      campaign_keyOrder: 19
    },
    end_date: {
      title: 'End Date',
      toolTipText: 'End date of the campaign',
      type: 'date',
      page: ['campaign'],
      campaign_keyOrder: 20
    },
    paid_sales_14d: {
      title: 'Paid sales',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Paid sales generated from the keyword in the selected time period using',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 7,
      keyword_keyOrder: 7
    },
    avg_cost: {
      title: 'Average Daily Spend',
      toolTipText: 'Average daily spend on this campaign',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign'],
      campaign_keyOrder: 9
    },
    roi: {
      title: 'ROAS',
      toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as sales divided by ad spend',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 10,
      keyword_keyOrder: 10
    },
    iroas: {
      title: 'iROAS',
      toolTipText:
        'Incremental Sales generated for each dollar of ad spend. This is an ML-driven metric that is currently in beta.',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 10,
      keyword_keyOrder: 10
    },
    impressions: {
      title: 'Impressions',
      toolTipText:
        'Total number of impressions generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of impressions generated from the keyword in the selected time period',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 11,
      keyword_keyOrder: 11
    },
    cpc: {
      title: 'Cost per click',
      toolTipText:
        'Average cost per click in the selected time period calculated as spend divided by clicks',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 14,
      keyword_keyOrder: 14
    },
    halo_sales_14_day: {
      title: 'Brand Halo Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      toolTipText: 'Brand Halo Sales occuring through click on ads',
      page: ['campaign'],
      campaign_keyOrder: 16
    },
    clicks: {
      title: 'Clicks',
      toolTipText:
        'Total number of clicks generated from the campaign in the selected time period',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 12,
      keyword_keyOrder: 12
    },
    ctr: {
      title: 'Click through rate',
      toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword'],
      keyword_keyOrder: 13,
      campaign_keyOrder: 13
    },
    orders_14d: {
      title: 'Orders',
      toolTipText:
        'Total number of orders generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of orders generated from the keyword in the selected time period',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 17,
      keyword_keyOrder: 17
    },
    units_14d: {
      title: 'Units',
      toolTipText:
        'Total number of units sold from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of units sold from the keyword in the selected time period',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 18,
      keyword_keyOrder: 18
    },
    conversions_14d: {
      title: 'Conversion',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders divided by clicks',
      keyword_toolTipText:
        'Measure of how many clicks converted into orders calculated as orders divided by clicks',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 15,
      keyword_keyOrder: 15
    },
    match_type_keyword: {
      title: 'Match type keyword',
      toolTipText: 'Targeting type (exact, phrase, broad)',
      type: 'String',
      page: ['keyword'],
      keyword_keyOrder: 9
    },
    spend: {
      title: 'Ad Spend',
      toolTipText: 'Total spend on the campaign in the selected time period',
      keyword_toolTipText:
        'Total spend on the keyword in the selected time period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword'],
      keyword_keyOrder: 10
    },
    last_bid_change_date: {
      title: 'Last bid change date',
      toolTipText: 'Date of last bid change on the keyword',
      type: 'date',
      page: ['keyword'],
      keyword_keyOrder: 11
    }
  }
};
