<template>
  <div>
    <div :class="containerClasses">
      <span
        v-if="selectorText"
        :class="selectorClass"
      >
        {{ selectorText }}
      </span>
      {{ selectedZipCode.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'TextSelectComponent',
  props: {
    selectedZipCode: {
      type: Object,
      default: function () {
        return {};
      }
    },
    containerClasses: {
      type: String,
      default:
        'u-flex-0 u-font-size-3 u-text-overflow-ellipsis u-spacing-pt-xxs u-font-weight-600'
    },
    selectorText: {
      type: String,
      default: null
    },
    selectorClass: {
      type: String,
      default: null
    }
  }
};
</script>
