// The Vue build version to load with the `import` command
import Vue from 'vue';
import App from './App';
import router from './router';
import { store } from './store/store';
import { i18n } from './utils/i18n';
import directives from '@/utils/directives';
import './external/init';
import ElementUI from 'element-ui';
import VueTippy from 'vue-tippy';
import VueWorker from 'vue-worker';
import '@/utils/filters/formatters';
import '@/utils/filters/utilities';
import '@/components/globals';
import './external/ga';
import './external/pageLoadTime';
import './external/fullStory';
import './external/maintainLocalStorage';
import plugins from './utils/plugins/plugins';
import { OpenNewTab } from '@/utils/helpers/openNewTab';
import localStorageWrapper from '@/utils/helpers/localStorageWrapper';
import VrSelect from 'vr-select';
import VueCompositionAPI from '@vue/composition-api';

Vue.use(VrSelect);
const sentry = require('./external/sentry');
sentry.init(Vue);
const ciqUiLib = require('@ciq-dev/ciq-ui');
Vue.use(ciqUiLib.vueInstantiator);
Vue.use(ciqUiLib.filters);
Vue.use(localStorageWrapper);
ciqUiLib.default.initBoomerangUI(Vue);
Vue.use(ElementUI);
Vue.use(directives);
Vue.use(router);
Vue.use(VueTippy, {
  duration: 0,
  interactive: true,
  placement: 'bottom-start'
});
Vue.use(plugins);
Vue.use(VueWorker);
Vue.config.productionTip = false;
/* eslint-disable no-new */
Vue.use(OpenNewTab);

Vue.use(VueCompositionAPI);

new Vue({
  i18n,
  el: '#app',
  router: router,
  store: store,
  components: { App },
  template: '<App/>'
});

/**
  Temporary code : To remove previous version of stored filters
**/

var release = 'Dec 04';
var releaseFromLS = localStorage.getItem('release');
var aCheckArray = [
  'sales-dashboard-filters',
  'sales-dashboard-filters-order',
  'campaigns-filters',
  'keywords-filters',
  'searchTerms-filters',
  'cm-skus-filters',
  'campaigns-filters-order',
  'keywords-filters-order',
  'searchTerms-filters-order',
  'cm-skus-filters-order',
  'budgetPlanner-filters',
  'campaignsManualActions-filters',
  'keywordsManualActions-filters'
];

if (!releaseFromLS || releaseFromLS !== release) {
  if (aCheckArray.length === 0) {
    localStorage.clear();
  } else {
    for (var i = 0; i < aCheckArray.length; i++) {
      var localStorageValue = JSON.parse(localStorage.getItem(aCheckArray[i]));
      if (localStorageValue !== null) {
        localStorage.removeItem(aCheckArray[i]);
      }
    }
  }
}

localStorage.setItem('release', release);
