export function getPlacementInclusionObject(
  currentPlacementInclusionObject = {},
  fetchedPlacementInclusionValuesArray = []
) {
  // This object will just be a filtered version of currentPlacementInclusionObject
  const newObjectToReturn = {};

  const keyValuePairForItemsToMap = {
    itemBuyBox: 'Item Buybox',
    itemCarousel: 'Item Carousel',
    searchCarousel: 'Search Carousel'
  };
  for (const currentObjectKey of Object.keys(currentPlacementInclusionObject)) {
    // Now for every key, check if the corresponding array item is present
    // if present, check if the value was changed from the old item
    // if yes, add it to new object
    const itemToCompareWith = fetchedPlacementInclusionValuesArray.find(
      (item) => item?.placement === keyValuePairForItemsToMap[currentObjectKey]
    );
    if (itemToCompareWith) {
      const newVal = currentPlacementInclusionObject[currentObjectKey].value;
      const oldVal = itemToCompareWith?.status;
      if (newVal !== oldVal) {
        newObjectToReturn[currentObjectKey] =
          currentPlacementInclusionObject[currentObjectKey];
      }
    } else {
      // else skip
      newObjectToReturn[currentObjectKey] =
        currentPlacementInclusionObject[currentObjectKey];
    }
  }
  return newObjectToReturn;
}

export function getBidMultiplierObject(
  currentBidMultiplierObject = {},
  fetchedBidMultiplierValuesArray = [],
  bidMultiplierType = 'placement'
) {
  // This object will just be a filtered version of currentPlacementInclusionObject
  // It will be of this format
  // {
  //   buyBox: 500,
  //   searchIngrid: 300
  // }
  const newObjectToReturn = {};

  const placementBidMultiplierKeyMappings = {
    buyBox: 'Buy-Box',
    searchIngrid: 'Search Ingrid',
    homePage: 'Home Page',
    stockUp: 'Stock Up'
  };

  const platformBidMultiplierKeyMappings = {
    desktop: 'Desktop',
    mobile: 'Mobile',
    app: 'App'
  };

  const keyValuePairForItemsToMatch =
    bidMultiplierType === 'platform'
      ? platformBidMultiplierKeyMappings
      : placementBidMultiplierKeyMappings;
  const keyToMatchWith =
    bidMultiplierType === 'platform' ? 'platformType' : 'placementType';

  for (const currentObjectKey of Object.keys(currentBidMultiplierObject)) {
    // Now for every key, check if the corresponding array item is present
    // if present, check if the value was changed from the old item
    // if yes, add it to new object
    const itemToCompareWith = fetchedBidMultiplierValuesArray.find(
      (item) =>
        item?.[keyToMatchWith] === keyValuePairForItemsToMatch[currentObjectKey]
    );
    if (itemToCompareWith) {
      const newVal = currentBidMultiplierObject[currentObjectKey] || null;
      const oldVal = itemToCompareWith?.multiplier;
      if (newVal !== oldVal) {
        newObjectToReturn[currentObjectKey] =
          currentBidMultiplierObject[currentObjectKey];
      }
    } else {
      const newVal = currentBidMultiplierObject[currentObjectKey];
      if (newVal === '') {
        // Skip it
      } else {
        newObjectToReturn[currentObjectKey] =
          currentBidMultiplierObject[currentObjectKey];
      }
    }
  }
  return newObjectToReturn;
}
