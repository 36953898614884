var oObject = {
  created() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (this.checkForTimeout() && this.tabIdleTimeoutHandler) {
          this.tabIdleTimeoutHandler();
          this.timer = new Date().getTime();
        }
      } else {
        this.timer = new Date().getTime();
      }
    });
  },
  data() {
    return {
      tabIdleTimeout: 600,
      timer: new Date().getTime()
    };
  },
  methods: {
    checkForTimeout() {
      var timeDifference = new Date().getTime() - this.timer;
      return timeDifference / 1000 / 60 > (this.tabIdleTimeout || 600);
    }
  }
};

export default oObject;
