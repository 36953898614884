<template>
  <div
    class="u-display-flex u-flex-direction-column u-flex-justify-content-center"
  >
    <icon-text
      class="u-cursor-pointer"
      :icon="showChart ? iconHide : iconShow"
      :tooltip-text="showChart ? 'Close trend lines' : 'View trend lines'"
      icon-classes="u-color-grey-lighter"
      :is-metric-tool-tip-enabled="true"
      :text="
        showText ? (showChart ? 'Close trend lines' : 'View trend lines') : ''
      "
      text-classes="u-font-size-8 u-color-grey-lighter u-spacing-pr-xs"
      @onClick="handleClick"
    />
  </div>
</template>

<script>
import iconText from '@/components/basic/iconText.vue';
export default {
  components: { iconText },
  props: {
    showChart: {
      type: Boolean,
      default: true
    },
    iconShow: {
      type: String,
      default: 'show-chart'
    },
    iconHide: {
      type: String,
      default: 'collapse'
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('toggleChartVisibilty', !this.showChart);
    }
  }
};
</script>
