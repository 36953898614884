export default {
  left: {
    keyword_text: {
      title: 'Keywords',
      toolTipText: 'Keywords',
      type: 'String',
      keyOrder: 1,
      page: ['keyword'],
      showOnUi: true,
      downloadable: true
    },
    campaign_name: {
      title: 'Campaigns',
      toolTipText: 'Campaigns',
      type: 'String',
      keyOrder: 1,
      page: ['campaign'],
      showOnUi: true,
      downloadable: true
    },
    asin: {
      title: 'SKU Details',
      toolTipText: 'SKU Details',
      type: 'sku',
      page: ['sku'],
      showOnUi: true,
      keyOrder: 1
    },
    tag_all: {
      title: 'Add All',
      toolTipText: 'All entites on this page will be tagged',
      type: 'icon',
      icon: 'plus',
      alternateIcon: 'check-fill-circle',
      iconClickEvent: 'addEntity',
      clickHeader: 'addAll',
      page: ['sku', 'keyword', 'campaign'],
      keyOrder: 3,
      showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    }
  },
  right: {
    campaign_name: {
      title: 'Campaigns',
      toolTipText: 'Campaigns',
      type: 'String',
      keyOrder: 1,
      page: ['campaign'],
      showOnUi: true,
      downloadable: true
    },
    keyword_text: {
      title: 'Keywords',
      toolTipText: 'Keywords',
      type: 'String',
      page: ['keyword'],
      keyOrder: 1,
      showOnUi: true,
      downloadable: true
    },
    sku_details: {
      title: 'SKU Details',
      toolTipText: 'SKU Details',
      type: 'sku',
      showOnUi: true,
      page: ['sku'],
      keyOrder: 1
    },
    remove_all: {
      title: 'Remove All',
      toolTipText: 'All entites will be removed',
      type: 'icon',
      icon: 'cross',
      iconClickEvent: 'removeEntity',
      clickHeader: 'removeAll',
      page: ['sku', 'keyword', 'campaign'],
      keyOrder: 2,
      showOnUi: true,
      downloadable: true
    }
  }
};
