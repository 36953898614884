<template>
  <div>
    <div
      class="metric-tile u-border-radius-s u-bg-color-white-base u-display-flex u-flex-direction-column"
      :class="{ 'u-border-bottom-left-right-none': isExpanded }"
    >
      <div
        class="u-spacing-pv-m u-spacing-pl-m u-spacing-pr-s u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
      >
        <span
          v-tippy="{ placement: 'top-start' }"
          class="u-font-size-6 u-font-weight-600 u-width-60 u-white-space-no-wrap u-text-overflow-ellipsis"
          :title="metricLabel"
          >{{ metricLabel }}</span
        >
        <div
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
          :style="`pointer-events: ${disableExpand ? 'none' : 'all'}`"
          @click="handleExpand"
        >
          <span class="u-color-blue-base u-font-size-7 u-spacing-pr-xs">{{
            collapseText
          }}</span>
          <rb-icon
            class="u-color-blue-base rb-icon--x-small"
            :icon="collapseIcon"
          />
        </div>
      </div>
      <section
        v-if="loading"
        class="u-display-flex u-flex-direction-column u-flex-grow-1"
      >
        <loader
          class="u-height-100 u-bg-color-grey-white"
          :loading="true"
          :color="'#007cf6'"
        />
      </section>
      <template v-else>
        <metric
          size="xl"
          class="u-spacing-pl-m"
          :config="computedMetricData.config"
          :data="computedMetricData.data"
        />
        <!-- <p class="u-spacing-pl-m u-font-size-7 u-spacing-mt-s">
          Relevent Categories grew by <span class="u-color-green-base">2%</span>
        </p> -->
        <spline-chart
          class="u-spacing-m-auto"
          :chart-config="chartConfig"
          :chart-data="chartData"
        />
        <div
          v-if="topPerformers.length"
          class="u-display-flex u-flex-align-items-center u-spacing-ph-s u-border-top u-border-width-s u-border-color-grey-xxx-light u-border-top-style-dashed"
        >
          <span class="u-font-size-7 u-color-grey-light"
            >Top Competitors:
          </span>
          <top-performers :items="topPerformers" />
        </div>
        <a
          v-if="redirectionData.text"
          class="u-spacing-mt-auto u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-spacing-p-s u-border-top u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer"
          :href="redirectionData.link"
          target="_blank"
        >
          <span class="u-font-size-7 u-font-weight-600 u-color-grey-lighter">{{
            redirectionData.text
          }}</span>
          <rb-icon
            icon="open-new-window"
            class="rb-icon--small u-color-grey-lighter u-spacing-ml-xs"
          />
        </a>
      </template>
    </div>
    <div
      v-show="isExpanded"
      class="u-spacing-mh-s collapsible-attacher u-bg-color-white-base"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import loader from '@/components/basic/loader';
import metric from '@/components/basic/metric.vue';
import SplineChart from '../molecules/spline-chart.vue';
import TopPerformers from '../molecules/top-performers.vue';
import { eventBus } from '@/utils/services/eventBus';
import * as d3 from 'd3';
export default Vue.extend({
  name: 'MetricTile',
  components: {
    metric,
    loader,
    SplineChart,
    TopPerformers
  },
  props: {
    redirectionData: {
      type: Object,
      default: () => ({})
    },
    disableExpand: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    metricLabel: {
      type: String,
      default: '',
      required: true
    },
    chartData: {
      type: Array,
      required: true,
      default: () => []
    },
    topPerformers: {
      type: Array,
      default: () => []
    },
    metricData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isExpanded: false,
      chartHeights: {
        s: 35,
        m: 64,
        l: 70
      },
      viewBox: {
        s: '39 0 148 26',
        m: '29.5 0 167 40'
      }
    };
  },
  computed: {
    collapseText() {
      return !this.isExpanded ? 'More details' : 'Collapse';
    },
    collapseIcon() {
      return !this.isExpanded ? 'enlarge' : 'collapse';
    },
    computedMetricData() {
      const metricData = {
        config: {
          tag1Unit: {},
          tag2Unit: {},
          invertTag2: this.metricData.isInverted
        },
        data: {}
      };
      if (this.metricData?.data?.unit === 'PERCENTAGE') {
        metricData.config.tag1Unit.suff = '%';
      } else if (this.metricData?.data?.unit === 'CURRENCY') {
        metricData.config.tag1Unit.pre = 'currency';
      }
      metricData.config.tag2Unit.suff = '%';
      metricData.data.tag1 = this.metricData?.data?.value;
      metricData.data.tag2 = this.metricData?.pvp;
      return metricData;
    },
    chartHeight() {
      let height;
      if (this.showTopCompetitors && this.redirectionData.text) {
        height = this.chartHeights.s;
      } else if (this.showTopCompetitors || this.redirectionData.text) {
        height = this.chartHeights.m;
      } else {
        height = this.chartHeights.l;
      }
      return height;
    },
    chartConfig() {
      return {
        showArea: true,
        showGradrient: true,
        chartId: 'metric-spline',
        showTooltip: false,
        curved: true,
        showPoints: false,
        size: {
          width: 225,
          height: this.chartHeight
        },
        oninit: this.chartOninit,
        onresized: this.chartOnResized,
        colors: {
          positive: '#3EA95E',
          negative: '#D7263D'
        }
      };
    }
  },
  methods: {
    chartOnResized() {
      const parentElement = d3.select(`#${this.$attrs.id} svg`);
      const parentWidth = parentElement.node().getBoundingClientRect().width;
      const gElement = d3.select(`#${this.$attrs.id} svg g.c3-chart-lines`);
      const scaleXValue = parentWidth / gElement.node().getBBox().width;
      gElement.style('transform-origin', 'center');
      // Scaling the svg g element to fit the svg element
      gElement.style('transform', `scaleX(${scaleXValue})`);
    },
    chartOninit() {
      this.$nextTick(() => {
        this.chartOnResized();
      });
    },
    handleRedirection() {
      this.$router.push({
        name: this.redirectionData.pageName
      });
    },
    handleExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        eventBus.$emit('handleMetricTileExpand', this);
      } else {
        this.$parent.expandedRows = {};
      }
    }
  }
});
</script>
<style lang="css" scoped>
.metric-tile {
  width: 225px;
  box-shadow: 0 0 4px 0 #caccce;
  margin: 0 8px;
  height: 180px;
}
.metric-tile ::v-deep .c3-line {
  stroke-width: 1px !important;
}

.u-border-bottom-left-right-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.u-spacing-m {
  &t-auto {
    margin-top: auto;
  }
  &-auto {
    margin: auto;
  }
}

.collapsible-attacher {
  width: 225px;
  height: 20px;
}
.u-border-top-style-dashed {
  border-top-style: dashed;
}
</style>
