import { EntityData } from '../data-api';
import { MetricGroup } from './metric';
import { cloneDeep, isString } from 'lodash';
import { DonutDataServiceBenchmarkBrands } from '../donut-chart-service';
// @ts-ignore
import benchMarkingMetricCell from '@/components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarkingMetricCell.vue';
import { AxiosResponse } from 'axios';
import { MetaDataReadApi } from '../meta-data';
// @ts-ignore
import benchMarkingTableSelect from '@/components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarkingTableSelect.vue';
import { Option } from './types';
import {
  setGlobalSettingsForMS,
  constants,
  asinLevelV2ConfigEnabled,
  isMarketShareLite,
  getMetricName
} from '@/components/pages/insights/amazon/market-share/utils';
import { ThreePSelection } from '@/components/pages/insights/amazon/market-share/types/filters';

export class BenchmarkingColumn {
  private v2 = asinLevelV2ConfigEnabled();
  selectedEntity: Option;
  constructor(
    public id: string,
    public data: EntityData,
    /**
     * Shows the column at this position, if value is Infinity don't show the column */
    public order: number,
    estimateType: string | undefined,
    private metrics: MetricGroup | null,
    threepSelection: ThreePSelection | null,
    metadataResponse: AxiosResponse<MetaDataReadApi> | null
  ) {
    this.selectedEntity = {
      entityId: data.entityValue,
      entityName: data.entityValue,
      title: data.entityValue,
      asin: data.entityValue,
      label: data.entityValue,
      part: this.getEstimate(
        data,
        estimateType,
        threepSelection,
        metadataResponse
      ),
      overall: this.getTotalEstimate(
        data,
        estimateType,
        threepSelection,
        metadataResponse
      ),
      pvpPercent: this.getDiffEstimate(
        data,
        estimateType,
        threepSelection,
        metadataResponse
      ),
      marketSharePercent: this.getMarketShare(
        data,
        estimateType,
        threepSelection,
        metadataResponse
      ),
      hidePieChartMetric: isMarketShareLite()
    };
  }

  colDef(
    options: any[],
    changeSelectionHandler: any,
    removeAsinHandlerFn: any,
    estimateType: string
  ) {
    const data = this.data;

    const showChart = {
      showChart: true
    };

    const colorPopulator = {
      colorPopulator: function (color: string, d: any): string {
        let id = '';
        if (isString(d)) {
          id = d;
        } else {
          id = d.id ? d.id : '';
        }
        if (id === 'myShare') {
          return '#BD10E0';
        } else {
          return '#E9EAEB';
        }
      }
    };
    const donutDataService = new DonutDataServiceBenchmarkBrands();
    const estimate = this.selectedEntity.part;
    const total = this.selectedEntity.overall;
    if (estimate && total) {
      const rest = total.value - estimate.value;
      donutDataService.update(estimate.value, rest);
    } else {
      donutDataService.update(null, null);
    }
    return {
      keyOrder: 0,
      field: data.entityValue,
      main_order: null,
      replace: false,
      headerComponentFramework: 'pieChartTableHeader',
      headerComponentParams: {
        column: this,
        ...showChart,
        ...colorPopulator,
        donutDataService: donutDataService,
        options: options,
        navigateTo: (category: any) => {
          setGlobalSettingsForMS(constants.CATEGORY, category);
          // resets the category selection of other pages
          setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
          setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
          setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, {}, true);
          setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
        },
        changeSelectionColumn: (
          _: unknown,
          value: {
            entityId: string;
            entityName: string;
            product_url: string;
            image_url: string;
            title: string;
            ordered_product_sales: number;
            asin: string;
            label: string;
          }[]
        ) => {
          changeSelectionHandler(this.id, value[0].entityId);
        },
        removeAsinHandler: () => {
          removeAsinHandlerFn(this.id);
        }
      },
      cellRendererFramework: benchMarkingMetricCell,
      minWidth: 260,
      maxWidth: 260,
      width: 260,
      cellRendererParams: {
        tableCardCellConfig: { enabled: true },
        cardClass: 'highlighted-card',
        metricClass: '13-12'
      },
      suppressSizeToFit: false
    };
  }

  getMetricMap(metadataResponse: AxiosResponse<MetaDataReadApi> | null) {
    return isMarketShareLite()
      ? metadataResponse?.data?.metadata?.marketShareLiteMetric
      : metadataResponse?.data?.metadata?.marketShareMetric;
  }

  private getDiffEstimate(
    data: EntityData,
    estimateType: string | undefined,
    threepSelection: ThreePSelection | null,
    metadataResponse: AxiosResponse<MetaDataReadApi> | null
  ): { value: number; unit: string } | undefined {
    if (estimateType && threepSelection) {
      const marketShareMetricMap = this.getMetricMap(metadataResponse);
      let metricName = marketShareMetricMap?.[estimateType]?.[
        threepSelection
      ] as string;
      if (metricName.endsWith('_v2')) {
        metricName = metricName.substring(0, metricName.length - 3);
      }

      if (metricName === getMetricName().salesMetricNameComp) {
        metricName = getMetricName().salesMetricName;
      }
      if (metricName === getMetricName().unitMetricNameComp) {
        metricName = getMetricName().unitMetricName;
      }
      const resultantMetric = `PVP_DIFF_` + metricName;
      const d = data.data.find((x) => x.name === metricName);
      if (d?.PVP) {
        return {
          value: d.PVP[resultantMetric] as number,
          unit: 'percentage'
        };
      }
      return undefined;
    }
    return undefined;
  }

  getTotalEstimate(
    data: EntityData,
    estimateType: string | undefined,
    threepSelection: ThreePSelection | null,
    metadataResponse: AxiosResponse<MetaDataReadApi> | null
  ): { value: number; unit: string } | undefined {
    let metricName: string = '';
    if (this.v2) {
      if (estimateType && threepSelection) {
        const marketShareMetricMap =
          metadataResponse?.data.metadata?.[
            <'total_unit_estimates' | 'total_sales_estimates'>(
              ('total_' + estimateType.toLowerCase())
            )
          ];
        metricName = marketShareMetricMap?.dimensionMapping?.[
          threepSelection
        ] as string;
        if (metricName.endsWith('_v2')) {
          metricName = metricName.substring(0, metricName.length - 3);
        }
      } else {
        return;
      }
    } else {
      metricName = `EV_TOTAL_${estimateType}`;
    }

    const d = data.data.find((x) => x.name === metricName);
    if (d) {
      return {
        value: d.RESULT[metricName] as number,
        unit: this.metrics
          ?.findMetric(metricName)
          ?.metricData['metadata']['unit'].toLowerCase()
      };
    } else {
      return undefined;
    }
  }
  /** function to return the market share metric from the API data */
  getMarketShare(
    data: EntityData,
    estimateType: string | undefined,
    threepSelection: ThreePSelection | null,
    metadataResponse: AxiosResponse<MetaDataReadApi> | null
  ) {
    if (estimateType && threepSelection) {
      const marketShareMetricMap = this.getMetricMap(metadataResponse);
      let metricName = marketShareMetricMap?.[estimateType]?.[
        threepSelection
      ] as string;
      if (metricName.endsWith('_v2')) {
        metricName = metricName.substring(0, metricName.length - 3);
      }
      if (metricName === getMetricName().salesMetricNameComp) {
        metricName = getMetricName().salesMetricName;
      }
      if (metricName === getMetricName().unitMetricNameComp) {
        metricName = getMetricName().unitMetricName;
      }
      const d = data.data.find((x) => x.name === metricName);
      return d?.RESULT[metricName] as number;
    }
    return undefined;
  }

  getEstimate(
    data: EntityData,
    estimateType: string | undefined,
    threepSelection: ThreePSelection | null,
    metadataResponse: AxiosResponse<MetaDataReadApi> | null
  ): { value: number; unit: string } | undefined {
    let metricName: string = '';
    if (this.v2) {
      if (estimateType && threepSelection) {
        const marketShareMetricMap =
          metadataResponse?.data.metadata?.[
            <'unit_estimates' | 'sales_estimates'>estimateType.toLowerCase()
          ];
        metricName = marketShareMetricMap?.dimensionMapping?.[
          threepSelection
        ] as string;
        if (metricName.endsWith('_v2')) {
          metricName = metricName.substring(0, metricName.length - 3);
        }
      } else {
        return;
      }
    } else {
      metricName = `EV_${estimateType}`;
    }

    const d = data.data.find((x) => x.name === metricName);
    if (d) {
      return {
        value: d.RESULT[metricName] as number,
        unit: this.metrics
          ?.findMetric(metricName)
          ?.metricData['metadata']['unit'].toLowerCase()
      };
    } else {
      return undefined;
    }
  }
}

export class BenchmarkingColumns {
  constructor(
    public columns: BenchmarkingColumn[],
    public metadataResponse: AxiosResponse<MetaDataReadApi>,
    public estimateType: 'UNIT_ESTIMATES' | 'SALES_ESTIMATES',
    public metrics: MetricGroup[],
    private switchMetric: (currentMetricId: string, newMetricId: string) => void
  ) {}

  get showedColumns() {
    return this.sortedColumns.filter(
      (x) => x.order !== Infinity // Using Infinity to hide the columns, also used for sorting the columns
    );
  }

  get sortedColumns() {
    return this.columns.sort((a, b) => {
      // Since we are filtering out the undefines in the filter step
      // return (b.getEstimate(b.data, this.estimateType)?.value as number) - (a.getEstimate(a.data, this.estimateType)?.value as number)
      return a.order - b.order;
    });
  }

  hideAllColumns() {
    this.columns.forEach((c) => {
      c.order = Infinity;
    });
  }

  private findInShowedColumns(id: string): [BenchmarkingColumn, number] {
    const matchingColumn = this.showedColumns.findIndex(
      (x) => id.replace('~', '').toUpperCase() === x.id.toUpperCase()
    );
    return [this.showedColumns[matchingColumn], matchingColumn];
  }

  private findColumn(id: string): [BenchmarkingColumn, number] {
    const matchingColumn = this.columns.findIndex(
      (x) => id.replace('~', '').toUpperCase() === x.id.toUpperCase()
    );
    return [this.columns[matchingColumn], matchingColumn];
  }

  get options() {
    return this.columns.filter(
      (x) => !this.showedColumns.some((y) => x.id === y.id)
    );
  }

  public addColumn(id: string) {
    const [columnToShow, _] = this.findColumn(id);
    if (columnToShow) {
      columnToShow.order = this.showedColumns.length;
      setGlobalSettingsForMS(
        constants.SHOWED_BENCHMARKING_COLUMNS,
        this.showedColumns.map((x) => x.id)
      );
    }
  }

  public removeColumn(id: string) {
    const [_, index] = this.findInShowedColumns(id);
    this.showedColumns[index].order = Infinity;
    for (const col of this.showedColumns.slice(index)) {
      if (col.order !== Infinity) {
        col.order = col.order - 1;
      }
    }
    setGlobalSettingsForMS(
      constants.SHOWED_BENCHMARKING_COLUMNS,
      this.showedColumns.map((x) => x.id)
    );
  }

  public switchColumn(id: any, newId: any) {
    const colIdx = this.findInShowedColumns(id);
    colIdx[0].order = Infinity;
    const [_, newColumnIndex] = this.findColumn(newId);
    this.columns[newColumnIndex].order = colIdx[1];
    setGlobalSettingsForMS(
      constants.SHOWED_BENCHMARKING_COLUMNS,
      this.showedColumns.map((x) => x.id)
    );
  }

  get columnDef() {
    const metricOptions: {
      metricOptions: {
        key: string;
        name: string;
        disable: boolean;
        tooltip: string;
        disableTooltip: string;
        showIcon: boolean;
        notAvailableMetricTooltip: {
          notAvailableclient: string;
        };
        isAvailable: string;
        availableLabel: string;
      }[];
    } = {
      metricOptions: this.metadataResponse
        ? Object.entries(this.metadataResponse.data.metrics).map(
            ([key, value]) => {
              return {
                availableLabel: value.label,
                disable: false,
                disableTooltip: '',
                isAvailable: 'true',
                key: value.label,
                name: value.label,
                notAvailableMetricTooltip: {
                  notAvailableclient: ''
                },
                showIcon: false,
                tooltip: value.label
              };
            }
          )
        : []
    };

    const showChart = {
      showChart: true
    };

    const colorPopulator = {
      colorPopulator: function (color: string, d: any): string {
        let id = '';
        if (isString(d)) {
          id = d;
        } else {
          id = d.id ? d.id : '';
        }
        if (id === 'myShare') {
          return '#BD10E0';
        } else {
          return '#E9EAEB';
        }
      }
    };
    // TODO: @rhitik.b@commerceiq.ai Currently assumes there is just one metric group
    const metricGroup = this.metrics[0];
    return [
      {
        field: 'entity',
        headerComponentParams: {
          ...showChart,
          ...colorPopulator,
          addColumnAction: (context: any, selectedEntity: any) => {
            this.addColumn(selectedEntity[0].id);
          },
          normalDropdown: true,
          enableSorting: false,
          displayIcon: 'add-circle-fill',
          displayText: 'Add another Category to Benchmark',
          allCategoryDisplayText:
            'All available categories are added to benchmark',
          iconSize: 'x-large',
          options: this.options,
          addEnabled: true,
          clientSearch: true
        },
        pinned: 'left',
        lockPosition: true,
        minWidth: 212,
        maxWidth: 212,
        width: 212,
        headerComponentFramework: 'addColumnHeader',
        keyOrder: -1,
        cellRendererFramework: benchMarkingTableSelect,
        cellRendererParams: {
          isThisMetricPlotted: false,
          metricPlotted: false,
          dropdownDisabled: true,
          groupByKey: null,

          metricOptions: metricGroup.options.map((x) => {
            return {
              key: x.id,
              name: x.metricName,
              disable: false,
              tooltip: x.tooltip,
              disableTooltip: 'This metric is unavailable at a category level',
              showIcon: false,
              notAvailableMetricTooltip: {
                notAvailableclient:
                  'Units Shipped is currently unavailable for SKU'
              },
              isAvailable: undefined,
              availableLabel: undefined
            };
          }),
          onMetricSelect: this.switchMetric
        },
        suppressSizeToFit: false
      },
      ...this.showedColumns.map((col) => {
        return col.colDef(
          this.options.map((col) => {
            const x = col.data;
            return {
              entityId: x.entityValue,
              entityName: x.entityValue,
              product_url: x.entityValue,
              image_url:
                'https://images-na.ssl-images-amazon.com/images/I/61As6NyGwpL.__AC_SX300_SY300_QL70_FMwebp_.jpg',
              title: x.entityValue,
              ordered_product_sales: 349334.7,
              asin: x.entityValue,
              label: x.entityValue
            };
          }),
          this.switchColumn.bind(this),
          this.removeColumn.bind(this),
          this.estimateType as string
        );
      })
    ];
  }
}
