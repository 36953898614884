<template>
  <div class="u-display-flex u-flex-direction-column">
    <div
      class="u-display-flex u-flex-direction-column u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        id="pricewar"
        :apply-filter="applyFilter"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'pricewar-filters'"
        :state="filterState"
        :config="config.filters"
        :loader-status="loaderStatus"
        :emit="config.filters.emit"
        :has-search="config.filters.hasSearch"
        :new-date="false"
        :max-date="getPriceWarPageWiseMinMaxKey"
        :default-date-post-range="''"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :on-create="onCreate"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Price War'" />
        </div>
        <div slot="search">
          <asin-search :config="config.filters.search" />
        </div>
      </rb-filter-panel>
      <div
        class="u-display-flex u-font-weight-500 u-font-size-6 settings-panel u-flex-align-items-center u-spacing-mb-xs"
        @mouseover="showEdit = true"
        @mouseleave="showEdit = false"
      >
        <rb-icon
          class="u-spacing-mr-xs rb-icon--small"
          :icon="'settings'"
        />
        <span>Price war index</span>
        <span
          v-show="!editPWISection"
          class="u-spacing-ph-s"
          >{{ selectedPWIOption.title }}</span
        >
        <rb-select
          v-show="editPWISection"
          class=""
          :send-details="true"
          :on-close="onPWIOptionSelected"
          :options="PWIArithmeticOptions"
        >
          <rb-button
            slot="trigger"
            class="u-spacing-mh-s"
            :text="selectedPWIOption.title"
            :type="'dropdown'"
            :size="'s'"
            :icon-right="'arrow-down'"
          />
        </rb-select>
        <span v-show="!editPWISection">{{ PWIText }}</span>
        <rb-input
          v-show="editPWISection"
          v-model="PWITextModel"
          :type="'number'"
          :placeholder="'PWI'"
          min="0"
          max="1"
          step="0.1"
          :rounded="false"
          class="u-width-60px rb-control-s"
          @input="onPWIInput"
        />
        <span
          v-if="!editPWISection && showEdit"
          class="u-cursor-pointer u-spacing-ph-s"
          @click="
            editPWISection = true;
            PWITextModel = PWIText;
          "
        >
          <rb-icon
            class="rb-icon--small"
            :icon="'pencil'"
          />
        </span>
        <span
          v-if="editPWISection"
          class="u-spacing-pl-m"
        >
          <span class="u-display-flex u-flex-align-items-center">
            <rb-button
              :text="'Apply'"
              :type="'filled'"
              :size="'s'"
              :disabled="PWITextModel.length === 0"
              :click-fn="applyPWIFilter"
            />
            <rb-button
              class="u-spacing-ml-s"
              :text="'Cancel'"
              :type="'hollow'"
              :size="'s'"
              :disabled="PWITextModel.length === 0"
              :click-fn="cancelPWIFilter"
            />
          </span>
        </span>
      </div>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
        :apply-negative-margin="true"
      />
    </div>

    <section class="u-envelop-width u-width-100">
      <div class="u-display-flex u-spacing-mh-m">
        <div
          v-if="
            (getInsightsPriceWarData[config.widgets['widget1'].meta.key] || {})
              .noData
          "
          class="u-flex-1 u-bg-color-blue-x-light u-font-size-5 u-color-blue-base u-spacing-p-m u-spacing-mb-m u-border-radius-s u-text-align-center u-border-all u-border-width-s"
        >
          Great news! No SKUs under price war.
        </div>
        <card-widget
          class="u-width-50 u-spacing-mb-m"
          :config="config.widgets['widget1']"
          :data="
            getInsightsPriceWarData[config.widgets['widget1'].meta.key] || {}
          "
        >
          <div
            slot="body"
            class="u-margin-auto u-width-100"
            data-cy="no_of_skus"
          >
            <div
              class="u-display-flex u-spacing-mh-l u-flex-align-items-center"
            >
              <div class="u-display-flex u-width-50 u-flex-1">
                <div class="u-display-flex u-flex-direction-column">
                  <metric
                    :size="'xxl'"
                    :config="selectedPWIMetric.config"
                    :data="
                      ((
                        getInsightsPriceWarData[
                          config.widgets['widget1'].meta.key
                        ] || {}
                      ).metrics || {})[selectedPWIMetric.metric]
                    "
                  />
                  <rb-tooltip
                    v-if="
                      outsideIn &&
                      (
                        getInsightsPriceWarData[
                          config.widgets['widget1'].meta.key
                        ] || {}
                      ).metrics
                    "
                    :label="getRevenueTooltip"
                    :position="'is-right'"
                  >
                    <div
                      class="u-flex-align-self-center u-bg-color-grey-lighter u-spacing-pv-s u-spacing-ph-m u-width-auto u-color-grey-white u-font-size-6 u-border-radius-s"
                    >
                      <span
                        >Revenue:
                        {{
                          (
                            ((
                              getInsightsPriceWarData[
                                config.widgets['widget1'].meta.key
                              ] || {}
                            ).metrics || {})['Revenue'] || {}
                          ).tag1 | num_format('currency')
                        }}</span
                      >
                    </div>
                  </rb-tooltip>
                </div>
                <chart
                  class="u-flex-1 u-spacing-ml-m"
                  style="height: 85px"
                  :config="config.widgets['widget1'].body.chart"
                  :data="
                    ((
                      getInsightsPriceWarData[
                        config.widgets['widget1'].meta.key
                      ] || {}
                    ).data || {})[selectedPWIMetric.metric]
                  "
                />
              </div>
            </div>
          </div>
        </card-widget>

        <card-widget
          class="u-width-50 u-spacing-ml-m u-spacing-mb-m"
          :config="config.widgets['widget2']"
          :data="
            getInsightsPriceWarData[config.widgets['widget2'].meta.key] || {}
          "
        >
          <template slot="body">
            <div
              class="u-flex-1 u-display-flex u-flex-direction-column u-spacing-ph-l u-flex-justify-content-center"
              data-cy="retailer_breakdown"
            >
              <div
                v-for="(i, index) in (
                  getInsightsPriceWarData[config.widgets['widget2'].meta.key] ||
                  {}
                ).data"
                :key="index"
                class="u-border-radius-l u-spacing-mb-s"
                :style="{
                  background: getColorPattern[index + 1],
                  height: '8px',
                  width: i[selectedPWIMetric['retailerGroupbyPerc']] * 100 + '%'
                }"
              />
              <div class="u-spacing-mv-m">
                <span
                  v-for="(i, index) in (
                    getInsightsPriceWarData[
                      config.widgets['widget2'].meta.key
                    ] || {}
                  ).data"
                  :key="index"
                  class="u-spacing-pr-s u-display-inline-flex u-flex-align-items-center u-font-size-5"
                >
                  <rb-icon
                    :style="{ color: getColorPattern[index + 1] }"
                    :icon="'dot'"
                  />
                  <span
                    >{{ i.comp_name
                    }}<span>
                      (
                      {{
                        i[selectedPWIMetric['retailerGroupbyValue']]
                          | num_format(
                            selectedPWIMetric['retailerGroupbyValueUnits'].pre,
                            selectedPWIMetric['retailerGroupbyValueUnits'].suff,
                            selectedPWIMetric['retailerGroupbyValueUnits'].min,
                            selectedPWIMetric['retailerGroupbyValueUnits']
                              .roundoff
                          )
                      }}
                      )</span
                    ></span
                  >
                </span>
              </div>
            </div>
          </template>
        </card-widget>
      </div>
      <card-widget
        class="u-spacing-mh-m u-spacing-mb-m"
        :config="config.widgets['widget3']"
        :data="
          getInsightsPriceWarData[config.widgets['widget3'].meta.key] || {}
        "
      >
        <div slot="body">
          <div>
            <rb-insights-table
              ref="SKUS_Under_Price_War_Table"
              :config="config.widgets['widget3']"
              :grid-options="gridOptions"
              :table-row="
                (
                  getInsightsPriceWarData[config.widgets['widget3'].meta.key] ||
                  {}
                ).rows
              "
              :table-column="config.widgets['widget3'].body.columnDefs"
              style="width: 100%"
              :row-height="80"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="true"
              :pagination="true"
              :pagination-total-key="
                (
                  getInsightsPriceWarData[config.widgets['widget3'].meta.key] ||
                  {}
                ).totalRows
              "
              :pagination-per-page-limit="
                config.widgets['widget3'].body.APIConfig.limit
              "
              :download-this-table="true"
            />
          </div>
        </div>
      </card-widget>
      <card-widget
        class="u-spacing-mh-m u-spacing-mb-m"
        :config="config.widgets['widget4']"
        :data="
          getInsightsPriceWarData[config.widgets['widget4'].meta.key] || {}
        "
        :page-wise-min-max-key="getPriceWarPageWiseMinMaxKey"
        data-cy="price-trend-widget"
      >
        <div slot="body">
          <div class="u-display-flex u-flex-direction-column u-spacing-ph-l">
            <rb-select
              v-if="
                (
                  getInsightsPriceWarData[config.widgets['widget4'].meta.key] ||
                  {}
                ).load === false && getSelectedAsin
              "
              :search-enabled="true"
              :client-search="false"
              :search-listener="asinListener"
              :width="'400px'"
              :send-details="true"
              :on-close="asinSelected"
              :options="getPriceWarFilterValues['pricewar_asin']"
              :context="config.widgets['widget4']"
              class="u-spacing-mb-m asin_select"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-cursor-pointer"
              >
                <SkuComponentWithBadge
                  :image-src="getSelectedAsin.client_image_url"
                  :asin-id="getSelectedAsin.asin"
                  :asin-title="getSelectedAsin.asin_name"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <div
                    style="height: 48px; width: 48px; margin: 0px"
                    class="media__img u-flex-none"
                  >
                    <img
                      style="height: 48px; width: 48px; margin: 0px"
                      :src="option.client_image_url"
                    />
                  </div>
                  <div class="u-spacing-ml-m u-overflow-hidden">
                    <p
                      class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                    >
                      {{ option.asin_name }}
                    </p>
                    <p
                      class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
                    >
                      {{ option.asin }}
                    </p>
                  </div>
                </div>
              </template>
            </rb-select>
            <chart
              :config="config.widgets['widget4'].body.chart"
              :data="
                (
                  getInsightsPriceWarData[config.widgets['widget4'].meta.key] ||
                  {}
                ).data
              "
            />
          </div>
        </div>
        <div slot="footer-right">
          <span
            v-if="
              (
                getInsightsPriceWarData[config.widgets['widget4'].meta.key] ||
                {}
              ).custom
            "
            class="u-font-size-5 u-color-grey-lighter"
          >
            No match was found for this SKU on other retail platforms!
          </span>
        </div>
      </card-widget>
    </section>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import metric from '@/components/basic/metric';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import Vue from 'vue';
import cardWidget from '@/components/widgets/cardWidget';
// import renderColumnHeaders from '@/utils/services/el-table-column-header'
import localDateFilter from '@/components/basic/localDateFilter.vue';
import localSelectFilter from '@/components/basic/localSelectFilter.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbPagination from '@/components/basic/pagination';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import SkuComponentWithBadge from '@/components/basic/SkuComponentWithBadge.vue';

const oObject = {
  beforeCreate() {
    // this.config = widgetsConfig.config(this);
  },
  mixins: [widgetsUtil],
  created() {
    this.$store.dispatch('storeOverrideToDate', this.overrideToDate);
    this.fetchFilters('price_war_v2');
    let storedPWIValue = JSON.parse(
      localStorage.getItem('pricewar-filters-PWI')
    );
    if (!(storedPWIValue && storedPWIValue.value && storedPWIValue.operator)) {
      storedPWIValue = {
        value: '0.5',
        operator: {
          title: '> =',
          operator: 'GREATER_THAN_OR_EQUAL_TO'
        }
      };
    }

    this.$store.dispatch('setPriceWarSettings', [
      {
        dimensionName: 'price_war_index',
        dimensionValue: storedPWIValue.value,
        operator: storedPWIValue.operator.operator
      },
      {
        dimensionName: 'price_change_diff_percentage',
        dimensionValue: '10',
        operator: 'LESS_THAN_OR_EQUAL_TO'
      }
    ]);
    this.PWIText = storedPWIValue.value;
    this.PWITextModel = storedPWIValue.value;
    this.storedPWIOption = storedPWIValue.operator;
    this.selectedPWIOption = storedPWIValue.operator;
    this.config = widgetsConfig.config(this);
  },
  computed: {
    getRevenueTooltip() {
      const dates = this.getAllDates();
      return (
        'Combined ordered revenue for ' +
        Vue.options.filters.printable_date(
          new Date(dates.sales_by_account_type || null)
        )
      );
    },
    overrideToDate() {
      return this.$route.query.to_date;
    },
    getSelectedAsin() {
      const selectedAsin = this.$store.getters.getSelectedAsin || null;
      this.selectedAsin = selectedAsin;
      return selectedAsin;
    },
    getPriceWarFilterValues() {
      return this.$store.getters.getPriceWarFilterValues || {};
    },
    getInsightsPriceWarData() {
      return this.$store.getters.getInsightsPriceWarData;
    },
    getPriceWarPageWiseMinMaxKey() {
      return this.$store.getters.getPriceWarPageWiseMinMaxKey;
    }
  },
  data() {
    const PWIHeaderMetrics = [
      {
        name: 'NO. OF SKUS UNDER PRICE WAR',
        title: 'No. of SKUs under price war',
        metric: 'SKU Count',
        retailerGroupbyValue: 'count',
        retailerGroupbyPerc: 'count_perc',
        retailerGroupbyValueUnits: {
          roundoff: 0
        },
        config: {
          invertTag2: true,
          tag1Unit: {
            roundoff: 0
          },
          tag2Unit: {
            suff: '%'
          }
        },
        tooltip:
          'Count of unique SKUs which are exhibiting symptoms of price war i.e. PWI >50% in last 2 weeks. Count is broken down by retailer which seems to be engaged in price war activity. Retailer specific counts may not add up due to some SKUs being part of 2 or more retailers.'
      },
      {
        title: 'Revenue under price war',
        name: 'REVENUE UNDER PRICE WAR',
        metric: 'Revenue',
        retailerGroupbyValue: 'ordered_revenue',
        retailerGroupbyPerc: 'revenue_perc',
        retailerGroupbyValueUnits: {
          pre: 'currency'
        },
        config: {
          tag1Unit: {
            pre: 'currency'
          },
          tag2Unit: {
            suff: '%'
          }
        },
        tooltip:
          'Average PWI score for SKUs in selected categories which are showing symptoms of price war across retailers. PWI is determined by no. of downward price movements by 2 or more retailers in last 14 days. The value is represented on a scale of 0 to 100%. Usually a value of more than 50% represents price war.'
      }
    ];
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      showEdit: false,
      editPWISection: false,
      PWIText: '0.5',
      PWITextModel: '0.5',
      storedPWIOption: null,
      selectedPWIOption: {
        title: '> =',
        operator: 'GREATER_THAN_OR_EQUAL_TO'
      },
      PWIArithmeticOptions: [
        {
          title: '> =',
          operator: 'GREATER_THAN_OR_EQUAL_TO'
        },
        {
          title: '< =',
          operator: 'LESS_THAN_OR_EQUAL_TO'
        },
        {
          title: '=',
          operator: 'EQUAL_TO'
        }
      ],
      selectedAsin: {},
      selectedPWIMetric: PWIHeaderMetrics[0],
      PWIHeaderMetrics,
      filterState: {
        getter: 'getInsightsPriceWarSelectedFilter',
        setter: 'setInsightsPriceWarSelectedFilter'
      },
      gridOptions: {
        context: {
          componentParent: this
        }
      },
      filterInstance: null,
      showActiveFilterBanner: false
    };
  },
  methods: {
    onCreate(filter) {
      this.filterInstance = filter;
    },
    cancelPWIFilter() {
      this.editPWISection = false;
      this.selectedPWIOption = this.storedPWIOption;
    },
    applyPWIFilter() {
      this.PWIText = this.PWITextModel;
      this.$store.dispatch('setPriceWarSettings', [
        {
          dimensionName: 'price_war_index',
          dimensionValue: this.PWITextModel,
          operator: this.selectedPWIOption.operator
        },
        {
          dimensionName: 'price_change_diff_percentage',
          dimensionValue: '10',
          operator: 'LESS_THAN_OR_EQUAL_TO'
        }
      ]);

      eventBus.$emit('pricewarSettingsChanged');

      this.editPWISection = false;
      localStorage.setItem(
        'pricewar-filters-PWI',
        JSON.stringify({
          value: this.PWITextModel,
          operator: this.selectedPWIOption
        })
      );
      this.storedPWIOption = this.selectedPWIOption;
    },
    onPWIInput() {
      if (
        parseFloat(this.PWITextModel) < 0 ||
        parseFloat(this.PWITextModel) > 1
      ) {
        this.PWITextModel = '0.5';
      }
    },
    onPWIOptionSelected(context, selection) {
      this.selectedPWIOption = selection[0];
    },
    asinListener(searchTerm, context) {
      context.meta.localFilters = [
        {
          dimensionName: 'search',
          dimensionValue: searchTerm,
          operator: 'ILIKE'
        }
      ];
      context.meta.from = 'search';
      this.$store.dispatch(context.meta.action, context);
    },
    sortTable(column) {
      if (column && column.column) {
        const widget = column.column.columnKey;
        let sortOrder = this.config.widgets[widget].body.APIConfig.orderByList;

        let currentOrder = null;
        if (column.order !== null) {
          currentOrder = column.order === 'ascending' ? 'ASC' : 'DESC';
          sortOrder = [
            {
              dimension: column.prop,
              direction: currentOrder
            }
          ];
        }
        const defaultSortOrder =
          this.config.widgets[widget].body.defaultOrderBy || [];
        this.config.widgets[widget].body.APIConfig.orderByList =
          sortOrder.concat(defaultSortOrder);
        this.config.widgets[widget].body.APIConfig.page = 1;
        this.$store.dispatch(
          this.config.widgets[widget].meta.action,
          this.config.widgets[widget]
        );
      }
    },
    asinSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedAsin = selection[0];
        this.$store.dispatch('setSelectedAsin', this.selectedAsin);
        eventBus.$emit('pricewarAsinSelected', this.selectedAsin);
      } else {
        this.selectedAsin = null;
      }
    },
    pwiHeaderMetricsOnClose(context, selection) {
      if (selection.length > 0) {
        this.selectedPWIMetric = selection[0];
      }
    },
    showAsGraph(that) {
      const footers = document.getElementsByClassName('card-footer');
      if (footers) {
        if (
          footers[footers.length - 1] &&
          footers[footers.length - 1].scrollIntoViewIfNeeded
        ) {
          footers[footers.length - 1].scrollIntoViewIfNeeded();
        }
      }
      if (that && that.params && that.params.data && that.params.data) {
        eventBus.$emit('pricewarAsinSelected', that.params.data);
      }
    }
    // appendToolTip (h, {column, $index}) {
    //   var dates = this.getAllDates();
    //   var pricewarDates = ((dates.dateRangeValues || {}).sales_by_account_type);
    //   var tooltipTexts = [
    //     '',
    //     'SKU title and ID as per the catalogue.',
    //     '4 weeks ordered revenue (ordered units * ASP) from ' + Vue.options.filters.printable_date(new Date(pricewarDates.from)) + ' to ' + Vue.options.filters.printable_date(new Date(pricewarDates.to)),
    //     'Current value of Price War Index. The table is sorted in decreasing order of PWI by default.',
    //     (this.getMarketPlace + ' Offer Price: Latest offer price at .' + this.getMarketPlace),
    //     'Retailer which is engaged in price war.',
    //     'Retailer Offer Price: Latest offer price at retailer.'
    //   ];

    //   if (!this.outsideIn) {
    //     tooltipTexts[2] = 'Priority';
    //   }

    //   return renderColumnHeaders.renderHeader(h, column, true, tooltipTexts[$index]);
    // }
  },
  components: {
    chart,
    cardWidget,
    metric,
    localDateFilter,
    localSelectFilter,
    rbPagination,
    rbFilterPanel,
    SkuComponentWithBadge
  }
};

export default oObject;
</script>

<style lang="css" scoped>
.settings-panel {
  line-height: 2.1;
}
</style>
