<template>
  <section class="add-tags-panel u-width-100 u-bg-color-grey-white">
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="loader || saveApiStatus.load"
      :color="'#007cf6'"
    />
    <section v-if="!loader">
      <div
        v-if="showHeader"
        class="u-spacing-ph-l u-spacing-pv-m"
      >
        <div
          class="u-text-case-upper title u-font-size-5 u-font-weight-600 u-spacing-pb-xs"
        >
          {{ title }}
        </div>
        <div class="u-font-size-5 u-color-grey-lighter">
          {{ subText }}
        </div>
      </div>
      <tagsAndSuggestions
        :custom-class="customTagsAndSuggestionsClass"
        :class="customAddTagsClass"
        class="u-spacing-mh-l"
        :enable-style-api="enableStyleApi"
        :dropdown-data="suggestionsData"
        :container-classes="tagsSuggestionsContainerClasses"
        :get-tags-and-suggestions-instance="getTagsAndSuggestionsInstance"
        :tags-aggregated-view="tagsAggregatedView"
        :tags-icons="tagsIcons"
        :delimiters="delimiters"
        :initial-tags="initialTags"
        :is-input-disabled="saveApiStatus.load || isInputDisabled"
        :input-placeholder="inputPlaceholder"
        :has-tags-buffer="false"
        :hide-dropdown-at-select="hideDropdownAtSelect"
        :show-drop-down="showDropDown"
        :allow-multiple-tags="allowMultipleTags"
        @suggestionClick="suggestionClick"
        @removeActiveTag="handleRemoveActiveTag"
        @tagClicked="handleTagClick"
        @textInput="handleTextInput"
        @handleEnter="handleEnter"
      >
        <div
          slot="dropdown-header"
          class="u-spacing-ph-m u-spacing-pv-m u-color-grey-lighter u-cursor-default u-font-size-7 u-spacing-pb-xs"
        >
          {{ headerText }}
        </div>
        <div
          v-show="
            scope.textInput.length &&
            !suggestionsDataFlattened.includes(scope.textInput)
          "
          slot="dropdown-footer"
          slot-scope="scope"
          class="u-spacing-ph-m u-spacing-pv-s u-cursor-pointer new-tags-selection"
          :style="
            enableStyleApi && scope.hoverIndex === -1 && scope.styleObject
          "
          @mouseenter="scope.handleMouseEnter(-1)"
          @mouseleave="scope.handleMouseLeave()"
          @click.stop="scope.handleSuggestionClick(scope.textInput)"
        >
          {{ scope.textInput }}
          <span class="u-color-grey-lighter">(Add as a new)</span>
        </div>
      </tagsAndSuggestions>
      <footer
        v-if="showFooter"
        class="u-spacing-pt-xl"
      >
        <div
          class="u-display-flex u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
        >
          <rb-button
            :click-fn="takeAction"
            :disabled="saveApiStatus.load"
            :text="'Cancel'"
            :size="'s'"
            :type="'hollow'"
            class="u-spacing-mr-s u-cursor-pointer"
          />
          <rb-button
            :click-fn="takeAction"
            :disabled="isApplyButtonDisabled || saveApiStatus.load"
            :loading="saveApiStatus.load"
            :text="'Save'"
            :size="'s'"
            :type="'filled'"
            class="u-spacing-mr-s u-cursor-pointer"
          />
        </div>
      </footer>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import tagsAndSuggestions from '@/components/widgets/tagsAndSuggestions';

export default {
  name: 'AddTagsPanel',
  components: {
    loader,
    tagsAndSuggestions
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    getAddTagPanelInstance: {
      type: Function
    },
    inputPlaceholder: {
      type: String,
      default: 'Type your tag here.'
    },
    initialTags: {
      type: Array,
      default() {
        return [];
      }
    },
    enableStyleApi: {
      type: Boolean,
      default: false
    },
    dropdownHightlightColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    tagsSuggestionsContainerClasses: {
      type: String,
      default: 'tags-suggestion-container'
    },
    headerText: {
      type: String,
      default: 'Your Tag'
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    saveApiStatus: {
      type: Object,
      default: () => ({
        load: false
      })
    },
    tagsAggregatedView: {
      type: Boolean,
      deafult: false
    },
    tagsIcons: {
      type: Array,
      default: () => [{ icon: 'cross', emit: 'removeActiveTag' }]
    },
    delimiters: {
      type: Array,
      default: () => [{ token: ',' }, { token: '\n' }]
    },
    subText: {
      type: String,
      default: null
    },
    numberOfSelections: {
      type: Number,
      default: null
    },
    suggestionsData: {
      type: Object,
      default: () => {
        return { rows: [] };
      }
    },
    selectedValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    },
    afterAPIActionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    isInputDisabled: {
      type: Boolean,
      default: false
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    allowMultipleTags: {
      type: Boolean,
      default: true
    },
    customTagsAndSuggestionsClass: {
      type: String,
      default: 'searchWrapper'
    },
    customAddTagsClass: {
      type: Array,
      default: () => ['add-tags-panel-tags-suggestion']
    }
  },
  data: () => {
    return {
      id: null,
      loader: false,
      tagsAndSuggestionsInstance: null,
      hideDropdownAtSelect: true
    };
  },
  computed: {
    suggestionsDataFlattened() {
      return this.suggestionsData.rows.map((item) => item.title);
    },
    showDropDown() {
      return this.tagsAndSuggestionsInstance?.textInput?.length > 0;
    },
    isApplyButtonDisabled() {
      return !this.tagsAndSuggestionsInstance?.localActiveTags?.length;
    }
  },
  watch: {
    selectedValues(newVal) {
      if (!newVal.length) {
        this.$emit('closePanelMethod');
      }
    }
  },
  created() {
    if (this.getAddTagPanelInstance) {
      this.getAddTagPanelInstance(this);
    }
  },
  methods: {
    handleRemoveActiveTag(localTags) {
      this.$emit('tagsChanged', localTags || []);
    },
    handleTextInput(data) {
      this.$emit('textInput', data);
      this.$emit(
        'tagsChanged',
        this.tagsAndSuggestionsInstance?.tagsInputInstance?.localActiveTags ||
          []
      );
    },
    getTagsAndSuggestionsInstance(instance) {
      this.tagsAndSuggestionsInstance = instance;
    },
    handleTagClick(data) {
      this.$emit('tagClicked', data);
    },
    handleEnter() {
      this.$emit(
        'tagsChanged',
        this.tagsAndSuggestionsInstance?.tagsInputInstance?.localActiveTags ||
          []
      );
    },
    suggestionClick(event) {
      this.$emit(
        'tagsChanged',
        this.tagsAndSuggestionsInstance?.tagsInputInstance?.localActiveTags ||
          []
      );
    },
    takeAction(type) {
      if (type.innerText === 'Cancel') {
        this.tagsAndSuggestionsInstance.clearAllActiveTags();
        this.$emit('closePanelMethod');
      } else {
        this.$emit(
          'saveKeywords',
          this.tagsAndSuggestionsInstance?.tagsInputInstance?.localActiveTags ||
            []
        );
      }
    }
  }
};
</script>
