<template>
  <div>
    <content-diff
      :content="contentDiff"
      :params="params"
    />
  </div>
</template>

<script>
import contentDiff from './content_diff.vue';
import htmldiff from '@/utils/helpers/htmldiff.js';

export default {
  components: {
    contentDiff
  },
  props: {
    previousContent: {
      type: String,
      default: null
    },
    currentContent: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    ignoreTypes: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: null
    }
  },
  computed: {
    contentDiff() {
      if (!this.ignoreTypes.includes(this.type)) {
        return htmldiff(this.previousContent, this.currentContent);
      }
      return this.currentContent;
    }
  }
};
</script>
