<template>
  <section
    class="u-display-flex u-flex-justify-content-space-between u-width-100"
    :class="'u-font-size-' + fontSize"
  >
    <div class="u-display-flex u-flex-align-items-center text-width">
      <div
        v-if="colored"
        class="u-spacing-pr-s"
      >
        <rb-icon
          class="u-color-grey-lighter"
          :class="[computedStyles.legendIconSize, iconCustomClass]"
          :icon="legendIcon"
          :style="{ color: barBackground }"
        />
      </div>
      <div
        v-tippy="tippy"
        :title="brand"
        class="u-overflow-hidden u-display-flex u-flex-align-items-center u-width-100"
      >
        <div class="overflow-styles u-display-block">
          {{ brand }}
        </div>
        <slot name="post-brand" />
      </div>
    </div>
    <div class="u-display-flex u-flex-justify-content-space-between">
      <div
        class="u-flex-1 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-start"
        :class="computedStyles.percentageWidth"
      >
        {{ displayValue }}
      </div>
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start"
        :class="[
          computedStyles.pvpWidth,
          {
            'u-color-green-base': pvpDirection === '+',
            'u-color-red-base': pvpDirection === '-',
            'u-color-grey-lighter': pvpDirection === ''
          }
        ]"
      >
        <rb-icon
          class="u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-center u-spacing-mh-s"
          :class="[
            'rb-icon--' + pvpIconSize,
            {
              'hide-pvp': pvp === null
            }
          ]"
          :icon="
            pvpDirection === '+' || pvpDirection === ''
              ? 'arrow-up'
              : 'arrow-down'
          "
        />
        <div
          class="u-flex-align-items-center"
          :class="[
            'u-font-size-' + pvpFontSize,
            pvp === null ? 'u-display-none' : 'u-display-flex'
          ]"
        >
          {{ pvp }}%
        </div>
        <div
          v-if="pvp === null"
          class="u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start"
        >
          NA
        </div>
      </div>
    </div>
    <customTippy
      v-if="showCustomTooltip"
      id="customTooltip"
      :icon="legendTooltip.icon"
      :pre-text="legendTooltip.preText"
      :title="legendTooltip.title"
    />
  </section>
</template>

<script>
import customTippy from './customTippy.vue';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {
    customTippy
  },
  props: {
    iconCustomClass: {
      type: String,
      default: null
    },
    legendIcon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'm'
    },
    percentage: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: undefined
    },
    brand: {
      type: String,
      default: null
    },
    pvpDirection: {
      type: String,
      default: null
    },
    pvp: {
      type: Number,
      default: null
    },
    colored: {
      type: Boolean,
      default: false
    },
    barBorder: {
      type: String,
      default: null
    },
    barBackground: {
      type: String,
      default: null
    },
    fontSize: {
      type: Number,
      default: 4
    },
    pvpFontSize: {
      type: Number,
      default: 5
    },
    pvpIconSize: {
      type: String,
      default: 'xx-small'
    },
    legendTooltip: {
      type: Object,
      default: () => {}
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.showCustomTooltip) {
      var tippy = {
        html: '#customTooltip',
        placement: 'top-start',
        interactive: true,
        offset: '10,2',
        reactive: true,
        arrow: false
      };
    } else {
      var tippy = { placement: 'top', arrow: false };
    }
    return {
      tippy: tippy
    };
  },
  computed: {
    displayValue() {
      return this.value?.data
        ? formatter(this.value?.data, this.value.type)
        : formatter(this.percentage, 'percentage');
    },
    computedStyles() {
      if (this.size === 'm') {
        return {
          percentageWidth: 'percentage-width-medium',
          pvpWidth: 'pvp-width-medium',
          colorBoxDimensions: 'dimensions-medium',
          legendIconSize: 'rb-icon--small'
        };
      }
      return {
        percentageWidth: 'percentage-width-large',
        pvpWidth: 'pvp-width-large',
        colorBoxDimensions: 'dimensions-large',
        legendIconSize: 'rb-icon--medium'
      };
    }
  }
};
</script>

<style lang="css" scoped>
.percentage-width-large {
  min-width: 50px;
  max-width: 70px;
}
.pvp-width-large {
  min-width: 62px;
  max-width: 62px;
}
.dimensions-large {
  min-width: 12px;
  min-height: 12px;
}
.percentage-width-medium {
  min-width: 45px;
  max-width: 45px;
}
.pvp-width-medium {
  min-width: 60px;
  max-width: 60px;
}
.dimensions-medium {
  min-width: 8px;
  min-height: 8px;
}
.text-width {
  min-width: 50%;
  max-width: 50%;
}
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hide-pvp {
  visibility: hidden;
}
</style>
