<template>
  <div
    class="u-display-flex campaign-management-styling u-flex-direction-column u-font-size-1 ams-search-terms"
  >
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="getShowSearchTermsActivityLog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'search term(s)'"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div
      class="u-display-flex u-bg-color-grey-white u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="wk-searchTerms-filters"
        :key="filterKey"
        :data="filterData"
        :loader-status="loaderStatus"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'searchTerms-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="true"
        :new-date="true"
        :show-data-missing-msg="showDataMissingMsg"
        :data-missing-date-range="'August 11th, 2023, to October 9th, 2023,'"
        :ingestion-tooltip-text="ingestionToolTipText"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :allow-date-compare="true"
        :default-date-post-range="'last30Days'"
        :max-date="getSearchTermsPageWiseMinMaxKey"
        :on-create="onCreate"
        :enable-save="true"
        :page="'search_terms'"
        :common-date-key="'common-date-cm'"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :additional-ranges="additionalRanges"
        @savedFilterApplied="filterKey++"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Search Terms'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            :bulk-search-ref="selectedBulkSearchType"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          >
            <rbRadioGroup
              slot="custom-bulk-type"
              class="search-terms-radio-group"
              :radio-options="bulkSearchOptions"
              :value="selectedBulkSearchType"
              group-label="Search for:"
              @onRadioChanged="handleRadioSelection"
            />
          </BulkSearch>
        </span>
      </rb-filter-panel>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      class="u-spacing-mt-xxs"
      :dates="filterInstance.selectedDates"
    />
    <card-widget
      v-show="!showNegativePanel && !showKeywordsPanel"
      class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
      :config="config.widgets['widget1']"
      :data="
        getWorkbenchSearchTermsData[config.widgets['widget1'].meta.key] || {}
      "
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    class="metric-card-hover"
                    data-cy="metricCardHover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        data-cy="metricList"
                        :class-name="'campaigns-select'"
                        :search-enabled="true"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span class="u-font-size-5">{{ val }}</span>
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getWorkbenchSearchTermsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="This metric is enabled only when 1 row is plotted"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <metric
                        v-if="
                          val &&
                          ((
                            getWorkbenchSearchTermsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                        :size="'l'"
                        class="u-display-inline-flex u-spacing-mt-s"
                        :config="
                          ((
                            getWorkbenchSearchTermsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metricsUnits || {})[val]
                        "
                        :data="
                          ((
                            getWorkbenchSearchTermsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                      />
                      <span
                        v-if="
                          !val &&
                          !((
                            getWorkbenchSearchTermsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <section
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
            >
              <span
                v-if="numberOfPlottedSelections !== 0"
                class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                :class="{ isHighlighted: isHighlighted }"
                style="min-height: 20px"
              >
                <span class="u-spacing-pr-s u-line-height-1_2"
                  >Graphs plotted for {{ numberOfPlottedSelections }} rows</span
                >
                <span @click="plotRows('delete')">
                  <rb-icon
                    :icon="'cross'"
                    class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                    :class="{
                      'isHighlighted u-color-grey-white': isHighlighted
                    }"
                  />
                </span>
              </span>
            </section>
            <span>
              <icon-text-actions
                v-if="isExpand"
                class="u-cursor-pointer padding--right u-width-100"
                mode="default"
                :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                @clicked="isExpand = !isExpand"
              />
              <icon-text-actions
                v-if="!isExpand"
                class="u-cursor-pointer u-width-100 padding--right"
                mode="default"
                :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                @clicked="isExpand = !isExpand"
              />
            </span>
          </div>
          <div class="u-display-flex set-roll-up-width">
            <div class="u-display-flex u-flex-align-items-center">
              <roll-up-by
                label="Roll up by:"
                :value="selectedRollUpValue"
                :options="computedRollUps"
                @onRollUpChange="
                  (selection) => getSearchTermsChartDataWithRollUp(selection)
                "
              />
            </div>
          </div>
          <chart
            id="workbenchSearchTermsChart"
            :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
            class="c3-large u-flex-1 u-spacing-mt-l"
            :config="config.widgets['widget1'].body.chart"
            :data="widget1Data"
          />
        </div>
      </div>
    </card-widget>

    <div class="action-workspace-panel">
      <addSearchTermToKeywordPanel
        v-if="showKeywordsPanel"
        :selected-values="selections"
        :profile-names="currentProfileNames"
        :parent-ref="parentRef"
        :selected-action-type="selectedActionType"
        @closePanelMethod="
          () => {
            closeActionsWorkflow(true);
          }
        "
        @afterAPIActionToCall="closeActionsWorkflow"
      />
      <addNegativesPanel
        v-if="showNegativePanel"
        :selected-values="selections"
        :title="'Add Negative Keywords'"
        :profile-names="currentProfileNames"
        :parent-ref="parentRef"
        :selected-action-type="selectedActionType"
        @closePanelMethod="
          () => {
            closeActionsWorkflow(true);
          }
        "
        @afterAPIActionToCall="closeActionsWorkflow"
      />
    </div>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div
      v-if="!showActionsBar"
      class="u-display-flex u-spacing-p-m u-bg-color-grey-white u-flex-direction-row-reverse actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <div class="u-display-flex u-flex-align-items-baseline">
        <icon-text-actions
          mode="default"
          :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
          @clicked="openManageColumns"
        />
        <rb-download-button
          :download-email="true"
          :config="config.widgets['widget2']"
          :column-map="config.widgets['widget2'].body.download.columnMap"
          :on-email-click="openEmailPopup"
          :class-name="'enlargeEmailIcon'"
          :disabled="resultLength < 1"
        />
      </div>
    </div>

    <card-widget
      :apply-card-class="false"
      class="u-bg-color-grey-white u-flex-1 u-spacing-mb-m"
      :config="config.widgets['widget2']"
      :data="
        getWorkbenchSearchTermsData[config.widgets['widget2'].meta.key] || {}
      "
    >
      <div slot="body">
        <div class="u-display-flex u-flex-wrap-yes">
          <rb-insights-table
            :dynamic-column-width="dynamicColumnWidth"
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getWorkbenchSearchTermsData[
                  config.widgets['widget2'].meta.key
                ] || {}
              ).rows
            "
            :table-column="tableColumns"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :pagination="true"
            :primary-key="'search_term'"
            :pagination-total-key="
              (
                getWorkbenchSearchTermsData[
                  config.widgets['widget2'].meta.key
                ] || {}
              ).totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :table-header-sticky-obj="sticky"
            :row-class-rules="rowClassRules"
            :show-pagination-options="true"
            :get-current-instance="getCurrentInstance"
          />
        </div>
      </div>
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="amsSearchTerms-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import addNegativesPanel from '@/components/ams/actionPanels/addNegativesPanel.vue';
import addSearchTermToKeywordPanel from '@/components/ams/actionPanels/addSearchTermAsKeywordPanel.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import rbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import utilConfig from '@/components/ams/campaigns/utils.js';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import {
  metricsShownForAmazonChart,
  defaultSearchTermsCols
} from '@/components/ams/campaigns/constants.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';

export default {
  components: {
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    addNegativesPanel,
    addSearchTermToKeywordPanel,
    modal,
    activityLog,
    messageBar,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    rbRadioGroup,
    amsAddMetricTile,
    SystemTransparencyBanner,
    RollUpBy
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      dynamicColumnWidth: {
        enable: false
      },
      additionalRanges: commons.additionalDateRanges(),
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      ingestionDependentEntities: ['campaign'],
      ingestionToolTipText:
        'Custom list updation or creation is in progress. It will take upto 45 mins for the changes to reflect.',
      resultLength: 0,
      filterKey: 0,
      showEmailPopUp: false,
      modalConfig: {},
      chartWkbenchAxes: {},
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getWorkbenchSearchTermsSelectedFilters',
        setter: 'setWorkbenchSearchTermsSelectedFilters'
      },
      downloadFileSuffix: 'campaign-management_search-terms',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForAmazonChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      disableMetric: [
        'Total sales',
        'PCOGS',
        'Inventory',
        'Organic sales',
        'Promo Value',
        'Average Selling price',
        'Amazon Frequency Rank'
      ],
      isExpand: false,
      showActionsBar: false,
      showBlueBar: false,
      timer: 30000,
      selections: [],
      availableActions: [
        {
          icon: 'show-chart',
          title: 'PLOT ROWS',
          id: 'show-chart',
          action: this.plotRows,
          allowedUser: 'all'
        }
      ],
      allowedActions: [],
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'add-negative': this.toggleNegativePanel,
        'add-keywords': this.toggleAddKeywordPanel
      },
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      parentRef: null,
      isHighlighted: false,
      showNegativePanel: false,
      showKeywordsPanel: false,
      showPaginationOptions: utils.internalUserCheck(window.user),
      showActionsLog: false,
      rbTableInstance: null,
      workLoggerPrimaryKey: null,
      openActivity: true,
      hasInvalidSearchTerm: false,
      selectedActionType: null,
      selectedBulkSearchType: 'amsSearchTerms',
      bulkSearchOptions: commons.getBulkSearchOptions('searchTerms'),
      enableManageColumns: true,
      manageColumnsPanelListener: 'searchTermsManageColumnsPanel',
      workBenchDataKey: 'getWorkbenchSearchTermsData',
      filterInstance: null,
      ...commons.commonDataProperties
    };
  },
  computed: {
    showDataMissingMsg() {
      return Vue.options.filters.config_check(
        'feature.pages.data_missing_banner_needed'
      ).enable;
    },
    bulkSearchProps() {
      if (this.selectedBulkSearchType === 'amsSearchTermsCampaigns') {
        const { bulkSearchProps } = utilConfig('amazon', 'campaign');
        return bulkSearchProps();
      }
      return this.config.bulkSearchProps();
    },
    currentProfileNames() {
      return this.$store.getters.getWorkbenchSearchTermsSelectedFilters
        .profile_name;
    },
    emailStatus() {
      return this.$store.getters.getEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          (
            this.getWorkbenchSearchTermsData[
              this.config.widgets.widget1.meta.key
            ] || {}
          ).load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    getWorkbenchSearchTermsData() {
      this.showActionsBar = false;
      this.resultLength = (
        (
          this.$store.getters.getWorkbenchSearchTermsData[
            this.config.widgets.widget2.meta.key
          ] || {}
        ).rows || []
      ).length;
      return this.$store.getters.getWorkbenchSearchTermsData;
    },
    widget1Data() {
      let data = (
        this.getWorkbenchSearchTermsData[
          this.config.widgets.widget1.meta.key
        ] || {}
      ).data;
      let clonedData = (data || {}).data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let ignoreList = ['report_date', 'count', 'customer_orders'];
      this.metricsList = [];
      for (const element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }
      data = this.createSelectedMetricsList({ data, clonedData });
      return data;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.worklogger_primary_key) {
            return false;
          }
          return (
            params.data.worklogger_primary_key ===
            this.$store.getters.getSearchTermPrimaryKey
          );
        }.bind(this)
      };
    },
    primaryKey() {
      return this.$store.getters.getSearchTermPrimaryKey[
        this.config.widgets.widget2.meta.primaryKey
      ];
    },
    getShowSearchTermsActivityLog() {
      return this.$store.getters.getShowSearchTermsActivityLog;
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.SearchTermsActivityLog;
    },
    selectedRollUpValue() {
      return this.selectedRollUp?.key || 'DAY';
    },
    computedRollUps() {
      return this.getComputedRollUpOptions(
        this.$store.getters.getSearchTermsPageWhereClause,
        this.rollUps
      );
    },
    getSearchTermsPageWiseMinMaxKey() {
      return this.$store.getters.getSearchTermsPageWiseMinMaxKey;
    }
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeCreate() {
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchSearchTermsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    let that = this;
    eventBus.$on('workbenchSearchTermsFilterApplied', () => {
      that.isExpand = true;
      that.plottedSelections = 0;
      this.closeActionsWorkflow();
    });
    eventBus.$on('workbenchSearchTermsSearchTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow();
    });
    eventBus.$on('workbenchSearchTermsPaginationTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow();
    });
    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('amsSearchTermsBulkSearchApplied', () => {
      that.plottedSelections = 0;
    });
    eventBus.$on('amsSearchTermsCampaignsBulkSearchApplied', () => {
      that.plottedSelections = 0;
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.amsSearchTerms?.openBulkSearchPopup();
    });
  },
  created() {
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    if (this.allowedActions.length === 0) {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
    }
    this.config.widgets.widget1.meta.plotSelections = [];
    this.fetchFilters(
      'ams_search_term_campaign_workbench',
      'FETCH_FILTERS_V2',
      'search_terms'
    );
  },
  beforeDestroy() {
    eventBus.$off('workbenchSearchTermsFilterApplied');
    eventBus.$off('workbenchSearchTermsSearchTriggered');
    eventBus.$off('workbenchSearchTermsPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('amsSearchTermsBulkSearchApplied');
    eventBus.$off('amsSearchTermsCampaignsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  methods: {
    ...commonMethods,
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    getSearchTermsChartDataWithRollUp(selection) {
      this.getChartDataWithRollUp(
        selection,
        'SearchTerms',
        this.$store,
        this.selectedRollUp,
        this.config
      );
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    toggleNegativePanel(close) {
      let elm = document.getElementsByClassName('detail-panel');
      if (close === undefined) {
        this.showNegativePanel = true;
        this.showActionsBar = false;
        elm[0].scrollTo(0, 0);
        return;
      }
      this.showNegativePanel = false;
      elm[0].scrollTo(0, 500);
    },

    toggleAddKeywordPanel(close) {
      let elm = document.getElementsByClassName('detail-panel');
      if (close === undefined) {
        this.hasInvalidSearchTerm = false;
        const updatedSelections = this.getValidSelections();
        if (!updatedSelections.length) {
          this.deselectAllRows();
          this.showActionsBar = false;
          this.showKeywordsPanel = false;
          elm[0].scrollTo(0, 0);
          return;
        }
        this.showKeywordsPanel = true;
        this.showActionsBar = false;
        elm[0].scrollTo(0, 0);
        return;
      }
      this.showKeywordsPanel = false;
      elm[0].scrollTo(0, 500);
    },

    hideBlueBar() {
      this.showBlueBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    closeActionsWorkflow(dontFetchChart = false) {
      this.showActionsBar = false;
      this.selections = [];
      this.deselectAllRows();
      this.onSelectionChanged([]);
      this.toggleAddKeywordPanel(true);
      this.toggleNegativePanel(true);
      this.plotRows('delete', dontFetchChart);
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection
    ) {
      this.selectedActionType = actionId;
      if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        this.actionIdFunctionMap[actionId]();
      }
    },
    getValidSelections() {
      const selectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      selectedNodes.forEach((node) => {
        if (!node.data.search_term) {
          node.setSelected(false);
          this.hasInvalidSearchTerm = true;
        }
      });
      if (this.hasInvalidSearchTerm) {
        const message =
          'Some of the search terms could not be selected as they were null or empty.';
        commons.displaySnackbar.call(this, message, 10000);
      }
      const updatedSelections =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      return updatedSelections;
    },
    plotRows(type, isCancelMethod = false) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      if (!isCancelMethod) {
        eventBus.$emit(
          'workbenchSearchTermsPlotSelectedRows',
          this.plotRowsSelections
        );
      }
      document.getElementById('wk-searchTerms-filters').scrollIntoView();
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    onSelectionChanged(data) {
      this.plotRowsSelections = data.map(function (selection) {
        return selection.data;
      });
      this.selections = data;
      if (!data.length) {
        this.showKeywordsPanel = false;
        const elm = document.getElementsByClassName('detail-panel');
        elm[0].scrollTo(0, 0);
      }
      this.showActionsBar = data.length && this.showKeywordsPanel === false;
    },
    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      const primaryKey = `${that.params.node.data.worklogger_primary_key}`;
      this.$store.dispatch('openSearchTermsActivityLog');
      this.$store.dispatch('getSearchTermsActivityLog', {
        primaryKey: primaryKey
      });
      if (this.workLoggerPrimaryKey === primaryKey) {
        return;
      }
      this.workLoggerPrimaryKey = primaryKey;
      this.rbTableInstance.redrawTableRows();
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.workLoggerPrimaryKey = null;
      this.$store.dispatch('closeCampaignsActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    getAllTableColumns() {
      return commons.addDefaultColCheck(
        this.config.widgets.widget2.body.columnDefs || [],
        defaultSearchTermsCols
      );
    }
  }
};
</script>

<style lang="css">
.ams-search-terms .filter__wrapper > div {
  align-items: flex-start;
}
.ams-search-terms .filter__wrapper > div > div > div {
  align-items: flex-start;
}
.ams-search-terms .padding--right {
  padding-right: 0% !important;
}
.set-roll-up-width {
  min-width: 210px;
  margin-top: 16px;
}
.search-terms-radio-group > span {
  font-size: 13px !important;
  color: #6a7075;
}
.search-terms-radio-group .rb-control-label {
  padding: 4px !important;
  font-size: 13px !important;
}
</style>
