import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';

const state = {
  insightsSalesDBFilterValues: {},
  salesDBFilters: [],
  salesDashboardData: {},
  insightsSalesDBSelectedFilter: {
    date_range: {
      value: 'Last 7 days'
    }
  },
  salesDBGraph: [],
  saledDBPcogsGraph: [],
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    salesDB_category: 'category',
    salesDB_subcategory: 'subcategory',
    salesDB_product_group: 'product_group',
    salesDB_brand: 'mapped_brand'
  },
  page_wise_min_max_key: 'sales_by_account_type'
};

const getters = {
  getInsightsSalesDBFilterValues: (state) => {
    return state.insightsSalesDBFilterValues;
  },
  getInsightsSalesDBSelectedFilter: (state) => {
    return state.insightsSalesDBSelectedFilter;
  },
  getSalesDashboardData: (state) => {
    return state.salesDashboardData;
  },
  getSalesDashboardGraphData: (state) => {
    return state.salesDBGraph;
  },
  getSalesDashboardPcogsGraphData: (state) => {
    return state.saledDBPcogsGraph;
  },
  getSalesDashboardPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  ISalesDB_SUCCESS: (state, data) => {
    Vue.set(state.salesDashboardData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.salesDashboardData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.salesDashboardData[data.key], 'data', data.columns);
    }
    Vue.set(state.salesDashboardData[data.key], 'rows', data.rows);
    Vue.set(state.salesDashboardData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.salesDashboardData[data.key], 'metrics', data.metrics);
    Vue.set(state.salesDashboardData[data.key], 'page', data.page);
    Vue.set(state.salesDashboardData[data.key], 'load', false);
    Vue.set(state.salesDashboardData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.salesDashboardData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.salesDashboardData[data.key], 'noData', true);
    }
  },
  ISalesDB_RESET: (state, data) => {
    Vue.set(state.salesDashboardData, data.key, {});
    Vue.set(state.salesDashboardData[data.key], 'columns', []);
    Vue.set(state.salesDashboardData[data.key], 'rows', []);
    Vue.set(state.salesDashboardData[data.key], 'load', true);
    Vue.set(state.salesDashboardData[data.key], 'error', false);
    Vue.set(state.salesDashboardData[data.key], 'noData', false);
    Vue.set(state.salesDashboardData[data.key], 'metrics', {});
  },
  SET_NEW_DATA: (state, data) => {
    Vue.set(state.salesDashboardData[data.key], 'rows', data.data);
  },
  ISalesDB_ERROR: (state, data) => {
    Vue.set(state.salesDashboardData, data.key, {});
    Vue.set(state.salesDashboardData[data.key], 'columns', []);
    Vue.set(state.salesDashboardData[data.key], 'rows', []);
    Vue.set(state.salesDashboardData[data.key], 'load', false);
    Vue.set(state.salesDashboardData[data.key], 'error', true);
    Vue.set(state.salesDashboardData[data.key], 'noData', false);
    Vue.set(state.salesDashboardData[data.key], 'metrics', {});
  },
  ISalesDB_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.insightsSalesDBSelectedFilter.date_range;
    Vue.set(state, 'insightsSalesDBSelectedFilter', data.values);
    if (!state.insightsSalesDBSelectedFilter.date_range) {
      Vue.set(state.insightsSalesDBSelectedFilter, 'date_range', dateRange);
    }
    getFiltersInFormat5(data, state);
  },
  SET_PO_FILTER_VALUES: (state, data) => {
    Vue.set(
      state.insightsSalesDBFilterValues,
      data.filterValueKey,
      data.values
    );
  },
  SET_SALES_DB_GRAPH: (state, data) => {
    state.salesDBGraph = data;
  },
  SET_SALES_DB_PCOGS_GRAPH: (state, data) => {
    state.saledDBPcogsGraph = data;
  }
};

function getChartData(data, dateType, key) {
  var dateArray = [dateType];
  var pCogsArray = ['PCOGS'];
  if (key === 'ordered_product_sales') {
    pCogsArray = ['Ordered Product Sales (OPS)'];
  }
  if (data.TIMESERIES !== null) {
    data.TIMESERIES.forEach((elm) => {
      dateArray.push(elm[dateType]);
      pCogsArray.push(elm[key]);
      // pSalesArray.push(elm['ordered_product_sales']);
    });
  }

  return {
    date: dateArray,
    dataValue: pCogsArray
    // ordered_product_sales: pSalesArray
  };
}

function getFiltersInFormat5(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  changeSalesDWData: (context, data) => {
    context.commit('SET_NEW_DATA', data);
  },
  setInsightsSalesDBSelectedFilter: (context, data) => {
    context.commit('ISalesDB_SET_SELECTED_FILTER', data);
  },
  downloadInSalesDashboardPage: (context, data) => {
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    // if (data.body.APIConfig.customOrderAndSort) {
    //   delete data.body.APIConfig.customOrderAndSort;
    // }

    if (
      data.body.APIConfig.customOrderAndSort &&
      data.body.APIConfig.customOrderAndSort.limit
    ) {
      data.body.APIConfig.customOrderAndSort.limit = null;
    }

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  getOrderedProductSales: (context, data) => {
    context.commit('ISalesDB_RESET', {
      key: data.meta.key
    });

    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.timeseriesEnabled = true;
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    // var poweredTiles = ['ordered_product_sales', 'pcogs', 'gross_retailer_margin', 'ordered_units', 'avg_selling_price'];
    // poweredTiles.forEach((elm) => {
    //   context.commit('ISalesDB_RESET', {
    //     key: elm
    //   });
    // })
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        // poweredTiles.forEach((elm) => {
        context.commit('ISalesDB_ERROR', {
          key: data.meta.key
        });
        // })
      } else {
        if (response.data.length > 0) {
          // poweredTiles.forEach((elm) => {
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data, data.meta.key)
              : undefined
          });
          // })
          if (data.meta.key === 'ordered_product_sales') {
            context.commit(
              'SET_SALES_DB_GRAPH',
              getChartData(
                response.data[0],
                'feed_date',
                'ordered_product_sales'
              )
            );
          } else if (data.meta.key === 'pcogs') {
            context.commit(
              'SET_SALES_DB_PCOGS_GRAPH',
              getChartData(response.data[0], 'feed_date', 'pcogs')
            );
          }
        } else {
          // poweredTiles.forEach((elm) => {
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            metrics: {}
          });
          // })
        }
      }
    });
  },

  // getLostBuyBox: (context, data) => {
  getRevenueLost: (context, data) => {
    context.commit('ISalesDB_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date || {};
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ISalesDB_ERROR', { key: data.meta.key });
      } else {
        if (response.data.length > 0) {
          let key1 = 'revenue_lost_ops';
          let key2 = 'revenue_lost_pcogs';
          if (data.meta.key === 'revenue_lost_unavl_skus') {
            key1 = 'revenue_lost_unavailable';
            key2 = 'revenue_lost_unavailable_pcogs';
          } else if (data.meta.key === 'revenue_lost_buy_box') {
            key1 = 'revenue_lost_lbb';
            key2 = 'revenue_lost_lbb_pcogs';
          }
          var allMetrics = {
            ordered_product_sales: data.body.getTagData
              ? data.body.getTagData(response.data, key1)
              : undefined,
            pcogs: data.body.getTagData
              ? data.body.getTagData(response.data, key2)
              : undefined
          };
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            metrics: allMetrics
            // page: data.body.APIConfig.page
          });
        } else {
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            metrics: {}
            // page: data.body.APIConfig.page
          });
        }
      }
    });
  },

  getOPSTableData: (context, data) => {
    context.commit('ISalesDB_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date || {};
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;

    var keyType = [
      'ordered_product_sales',
      'pcogs',
      'gross_retailer_margin',
      'oos_skus',
      'ordered_units',
      'avg_selling_price'
    ];
    var responseKey = 'revenue_lost';
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ISalesDB_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);

        if (response.data.length > 0) {
          for (var i = 0; i < keyType.length; i++) {
            if (
              response.data[0].RESULT.hasOwnProperty(keyType[i]) &&
              response.data[0].RESULT.hasOwnProperty('ordered_units') === false
            ) {
              responseKey = keyType[i];
              break;
            } else if (
              response.data[0].RESULT.hasOwnProperty(keyType[i]) &&
              response.data[0].RESULT.hasOwnProperty('ordered_units') === true
            ) {
              responseKey = 'ordered_units';
            }
          }
        }

        _aArray.forEach((elm) => {
          if (keyType.indexOf(responseKey) > -1) {
            elm[responseKey + '_diff'] =
              elm[responseKey] - elm['PVP_' + responseKey];
          } else {
            elm[responseKey + '_ops_diff'] =
              elm[responseKey + '_ops'] - elm['PVP_' + responseKey + '_ops'];
            elm[responseKey + '_pcogs_diff'] =
              elm[responseKey + '_pcogs'] -
              elm['PVP_' + responseKey + '_pcogs'];
          }
        });

        if (_aArray.length > 0) {
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('ISalesDB_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
