<template>
  <section class="u-font-size-5">
    <section :id="chartId" />
  </section>
</template>

<script>
const d3 = require('d3');

export default {
  props: {
    bubbleTextDistance: {
      type: Number,
      default: 8
    },
    textBubbleRadius: {
      type: Number,
      default: 3
    },
    textBubbleLineSpacing: {
      type: Number,
      default: 4
    },
    labelTextSize: {
      type: Number,
      default: 11
    },
    domain: {
      type: Object,
      default: () => ({
        min: 1,
        max: 100
      })
    },
    range: {
      type: Object,
      default: () => ({
        min: 1,
        max: 100
      })
    },
    legend: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      size: null,
      computedWidth: 0,
      computedHeight: 0
    };
  },
  computed: {
    height() {
      return this.maxRadius + Math.floor(this.labelTextSize / 2);
    },
    maxRadius() {
      const radii = this.legend.map((item) => item.radius);
      const maxLegend = Math.max(...radii);
      return maxLegend;
    },
    labelBegin() {
      return (
        2 * this.maxRadius +
        2 * this.textBubbleRadius +
        this.textBubbleLineSpacing
      );
    }
  },
  created() {
    this.chartId = 'bubble-chart-legend-' + this._uid;
  },
  mounted() {
    this.initChart();
    const that = this;
    const svg = this.getSvg();
    this.size = d3
      .scaleLinear()
      .domain([that.domain.min, that.domain.max])
      .range([that.range.min, that.range.max]);
    svg
      .selectAll('.legend-line')
      .data(that.legend)
      .enter()
      .append('line')
      .attr('class', 'legend-line')
      .attr('x1', function (d) {
        return that.height;
      })
      .attr('x2', that.labelBegin)
      .attr('y1', function (d) {
        return that.getLabelOffset(d) + 1;
      })
      .attr('y2', function (d) {
        return that.getLabelOffset(d) + 1;
      })
      .attr('stroke', '#e9eaeb');
    svg
      .selectAll('.legend-circle')
      .data(that.legend)
      .enter()
      .append('circle')
      .attr('class', 'legend-circle')
      .attr('cx', that.height)
      .attr('cy', function (d) {
        return 2 * that.height - that.size(d.radius);
      })
      .attr('r', function (d) {
        return that.size(d.radius) - 1;
        // completely using the svg results in parts of line to loose width, subtracting 1 to always keep it below the svg dimenions;
      })
      .style('fill', 'none')
      .attr('stroke', '#8b8f93');
    const legendLabels = svg
      .selectAll('.legend-labels')
      .data(that.legend)
      .enter()
      .append('g')
      .attr('class', function (d, index) {
        return `legend-labels legend-label-${index}`;
      })
      .attr('transform', function (d) {
        return `translate(${that.labelBegin}, ${that.getLabelOffset(d) + 1})`;
      });
    legendLabels
      .append('circle')
      .attr('r', function (d) {
        return that.textBubbleRadius;
      })
      .attr('fill', '#007cf6');
    legendLabels
      .append('text')
      .attr('x', that.bubbleTextDistance)
      .text(function (d) {
        return d.label;
      })
      .attr('class', 'u-font-weight-600')
      .attr('font-size', this.labelTextSize + 'px')
      .attr('dominant-baseline', 'central');
    this.setSvgWidth();
    // setTimeout(() => {

    // });
  },
  methods: {
    getLabelOffset(d) {
      return 2 * this.height - 2 * this.size(d.radius);
    },
    setSvgWidth() {
      let maxWidth = -Infinity;
      for (let i = 0; i < this.legend.length; i++) {
        const dimensions = this.getSvgDimensions(`.legend-label-${i}`);
        const { width } = dimensions;
        if (width > maxWidth) {
          maxWidth = width;
        }
      }
      const newWidth =
        2 * this.maxRadius +
        maxWidth +
        this.textBubbleLineSpacing +
        this.bubbleTextDistance +
        8;
      this.computedWidth = newWidth;
      const svg = this.getSvg();
      svg.attr('width', this.computedWidth);
    },
    getSvgDimensions(svgSelector) {
      const svg = this.getSvg();
      return svg?.select(svgSelector)?.node()?.getBoundingClientRect();
    },
    initChart() {
      d3.select('#' + this.chartId)
        ?.append('svg')
        ?.attr('class', 'bubble-radius-legend')
        ?.attr('height', 2 * this.height);
    },
    getSvg() {
      return d3.select('#' + this.chartId).select('svg');
    }
  }
};
</script>
