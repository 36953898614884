<template>
  <div class="rb-filter u-spacing-pb-s">
    <span
      ref="trigger"
      class="u-cursor-pointer u-font-size-5 u-display-inline-flex u-flex-align-items-center action--text"
      data-cy="addFilterBtn"
      @click.stop="toggle"
    >
      <rb-icon
        class="rb-icon--small u-spacing-mr-xs"
        data-cy="addFilter"
        :icon="'add-circle-fill'"
      />

      <span data-cy="add-filter-text">{{ addFilterText }}</span>
    </span>

    <div
      v-show="isActive"
      ref="dropdownMenu"
      :style="getTopValue"
      class="filter-dropdown-menu"
    >
      <div class="filter--header">
        <span class="u-display-flex u-flex-align-items-center">
          <span class="u-color-grey-white u-font-size-5">Filters</span>
        </span>

        <span @click.stop="toggle">
          <rb-icon
            :icon="'cross'"
            class="rb-icon--small u-color-grey-white u-cursor-pointer"
          />
        </span>
      </div>

      <div
        class="u-display-flex u-width-100 u-box-sizing-border-box u-spacing-ph-m u-spacing-pt-m"
      >
        <rb-input
          ref="searchBox"
          v-model="searchText"
          :placeholder="placeHolder"
          class="u-flex-1 search--input-s"
          data-cy="searchBoxFilter"
          :rounded="false"
          @input="onSearch"
        />

        <rb-icon
          style="right: 25px"
          class="u-color-grey-lighter u-position-absolute rb-icon--medium"
          :icon="'search'"
        />
      </div>

      <div
        class="filter-dropdown-content u-display-flex u-overflow-hidden u-bg-color-grey-white u-position-relative"
        style="content-visibility: auto"
      >
        <loader
          class="fill--parent"
          :loading="loading || loaderStatus"
          :color="'#007cf6'"
        />

        <div
          data-cy="filterContainer"
          class="filter-container u-border-width-s u-border-color-grey-xxx-light"
        >
          <div
            v-if="panels.length === 0 || loaderStatus"
            class="u-spacing-m-m u-font-size-7 u-text-align-center u-color-grey-light"
          >
            No Results
          </div>

          <div v-if="!loaderStatus">
            <div
              v-for="(panel, index) in sortedPanels"
              :key="index"
            >
              <div
                v-if="panel.visible && !disabledTooltipPanel(panel.filterBy)"
                class="u-cursor-pointer u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pt-m u-spacing-ph-m u-spacing-pb-s"
                :class="{ 'disabled-opacity': panelDisabled(panel.filterBy) }"
                @click="expandCollapsePanel(panel)"
              >
                <span
                  :class="{
                    'u-font-weight-normal': panel.collapsed,
                    'u-font-weight-600': !panel.collapsed
                  }"
                  class="u-color-grey-light u-font-size-5 u-line-height-1-3"
                  :data-cy="`filter-${panel.title}`"
                >
                  {{ panel.title }}
                </span>

                <rb-icon
                  v-if="panel.collapsed"
                  :icon="'arrow1-down'"
                  class="rb-icon--small u-color-grey-light"
                />

                <rb-icon
                  v-if="!panel.collapsed"
                  :icon="'arrow1-up'"
                  class="rb-icon--small u-color-grey-light"
                />
              </div>

              <div
                v-if="panel.visible && disabledTooltipPanel(panel.filterBy)"
                v-tippy="tippy"
                class="u-cursor-pointer u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pt-m u-spacing-ph-m u-spacing-pb-s"
                :class="{ 'disabled-opacity': panelDisabled(panel.filterBy) }"
                :title="disabledTooltipPanel(panel.filterBy)"
                @click="expandCollapsePanel(panel)"
              >
                <span
                  :class="{
                    'u-font-weight-normal': panel.collapsed,
                    'u-font-weight-600': !panel.collapsed
                  }"
                  class="u-color-grey-light u-font-size-5 u-line-height-1-3"
                  :data-cy="`filter-${panel.title}`"
                >
                  {{ panel.title }}
                </span>

                <rb-icon
                  v-if="panel.collapsed"
                  icon="arrow1-down"
                  class="rb-icon--small u-color-grey-light"
                />

                <rb-icon
                  v-if="!panel.collapsed"
                  icon="arrow1-up"
                  class="rb-icon--small u-color-grey-light"
                />
              </div>

              <div
                v-for="(item, i) in filteredOptions"
                :key="i"
                v-tippy="tippy"
              >
                <div
                  v-show="!panel.collapsed"
                  v-if="
                    item.dimensionType === panel.filterBy &&
                    !disabledTooltip(item)
                  "
                  :class="{
                    active: hoveredItem === item,
                    'u-spacing-pl-xl': panel.visible,
                    'u-spacing-pl-m': !panel.visible,
                    'u-spacing-mt-xs': !panel.visible,
                    disabled: disabled(item)
                  }"
                  class="filter-list__item u-display-flex u-cursor-pointer u-spacing-pv-s u-flex-justify-content-space-between u-spacing-pr-m u-flex-align-items-center"
                  :data-cy="`filterItem-${panel.title}`"
                  @click.stop="itemClick(item)"
                  @mouseover="hoverItem(item)"
                >
                  <span
                    class="u-font-size-5 u-color-grey-light"
                    :data-cy="item.dimensionLabel"
                  >
                    {{ item.dimensionLabel }}
                  </span>

                  <span
                    v-if="item.dimensionType === 'MY_SAVED_FILTERS'"
                    v-tippy
                    title="Delete"
                    @click.stop="deleteFilter($event, item)"
                  >
                    <rb-icon
                      :icon="'trash'"
                      class="rb-icon--medium u-display-flex deleteIcon"
                    />
                  </span>
                </div>

                <div
                  v-show="!panel.collapsed"
                  v-if="
                    item.dimensionType === panel.filterBy &&
                    disabledTooltip(item)
                  "
                  v-tippy="tippy"
                  :class="{
                    active: hoveredItem === item,
                    'u-spacing-pl-xl': panel.visible,
                    'u-spacing-pl-m': !panel.visible,
                    'u-spacing-mt-xs': !panel.visible,
                    disabled: disabled(item)
                  }"
                  class="filter-list__item u-display-flex u-cursor-pointer u-spacing-pv-s u-flex-justify-content-space-between u-spacing-pr-m u-flex-align-items-center"
                  :title="disabledTooltip(item)"
                  :data-cy="`filter-${item}`"
                  @click.stop="itemClick(item)"
                  @mouseover="hoverItem(item)"
                >
                  <span class="u-font-size-5 u-color-grey-light">
                    {{ item.dimensionLabel }}
                  </span>

                  <span
                    v-if="item.dimensionType === 'MY_SAVED_FILTERS'"
                    v-tippy
                    title="Delete"
                    @click.stop="deleteFilter($event, item)"
                  >
                    <rb-icon
                      icon="trash"
                      class="rb-icon--medium u-display-flex deleteIcon"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <slot name="extra-metrics" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import { eventBus } from '@/utils/services/eventBus';
import filterUtils from '@/components/widgets/filter/filterUtils';
import loader from '@/components/basic/loader';

export default {
  components: {
    loader
  },
  props: {
    disabledFn: {
      type: Function,
      default: function () {
        return false;
      }
    },
    disabledTooltipFn: {
      type: Function,
      default: function () {
        return '';
      }
    },
    filterV2: {
      type: Boolean,
      default: false
    },
    enableSave: {
      type: Boolean,
      default: false
    },
    onCreate: {
      type: Function,
      default: function () {}
    },
    secondaryData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selection: {
      type: Function,
      default: function () {}
    },
    data: {
      type: Array,
      default: function () {
        return [];
      }
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    },
    widget: {
      type: String,
      default: ''
    },
    loaderStatus: {
      type: Boolean,
      default: false
    },
    addFilterText: {
      type: String,
      default: 'Add Filters'
    }
  },
  data() {
    return {
      tippy: {
        placement: 'left',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      placeHolder: 'Search for any filter',
      options: [],
      isActive: false,
      panels: [],
      hoveredItem: null,
      searchText: '',
      loading: false,
      collapseConfig: {
        EXPRESSION: false
      },
      mapping: {
        CLIENT_INTERNAL_CATALOGUE: 'Internal Categorization',
        CAMPAIGN_INTERNAL_CATALOGUE: 'Campaign Categorization',
        AVC_CATALOGUE: 'AVC Categorization',
        AMAZON_CATEGORY: 'Retailer Categorization',
        RETAILER_CATEGORY: 'Retailer Categorization',
        SMART_FILTERS: 'CIQ Categorization',
        OTHERS: 'Other Filters',
        SAVED: 'Saved Filters',
        CLIENT_SAVED_FILTERS: 'Saved Filters',
        MY_SAVED_FILTERS: 'My Saved Filters',
        STRATEGY: 'Impacted by',
        EXPRESSION: 'Expression (Column) Filters',
        COLUMN: 'Column Filters',
        SOV: 'Share of Voice',
        DIGITAL_SHELF: 'Digital Shelf',
        TAG_ASIN_CUSTOM: 'Custom Catalog',
        CUSTOM_LISTS: 'Custom Lists',
        TAG_CAMPAIGN_CUSTOM: 'Custom Lists',
        MARKET_SHARE_CATEGORISATION: 'Market Share Categorization',
        ADVANCED_FILTERS: 'Advanced Reporting Filters'
      },
      getTopValue: {
        top: '0px',
        left: '0px'
      }
    };
  },
  computed: {
    sortedPanels() {
      for (var i = 0; i < this.panels.length; i++) {
        if (this.collapseConfig[this.panels[i].filterBy] !== undefined) {
          this.panels[i].collapsed =
            this.collapseConfig[this.panels[i].filterBy];
        }
      }

      this.panels.sort((a, b) => {
        var keys = Object.keys(this.mapping);
        return (
          (keys.indexOf(a.filterBy) || 1) - (keys.indexOf(b.filterBy) || 1)
        );
      });
      return this.panels;
    },
    filteredOptions() {
      var localSavedFilters = filterUtils.getLocalFilters(this.widget);
      this.options = filterUtils.mergeLocalFilters(
        this.options,
        localSavedFilters
      );
      var data = this.options;
      var _data = [];
      for (var i = 0; i < data.length; i++) {
        if (
          data[i] &&
          data[i].dimensionLabel &&
          data[i].dimensionLabel
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) !== -1
        ) {
          _data.push(data[i]);
        }
      }
      return _data;
    }
  },
  watch: {
    data(val) {
      this.mixData();
    },
    secondaryData(val) {
      this.mixData();
    },
    isActive(val) {
      Vue.nextTick(
        function () {
          if (this.$refs.searchBox && this.$refs.searchBox.$el) {
            this.$refs.searchBox.$el.children[0].focus();
          }
        }.bind(this)
      );

      setTimeout(
        function () {
          if (
            this.$refs.dropdownMenu &&
            this.$refs.dropdownMenu.closest('#filter__wrapper')
          ) {
            const leftBoundDropDown =
              this.$refs.dropdownMenu &&
              this.$refs.dropdownMenu.getBoundingClientRect().right;
            const leftBoundWrap =
              this.$refs.dropdownMenu &&
              this.$refs.dropdownMenu
                .closest('#filter__wrapper')
                .getBoundingClientRect().right;
            if (leftBoundWrap > leftBoundDropDown) {
              this.getTopValue.left = '0px';
            } else {
              this.getTopValue.left =
                -Math.abs(leftBoundWrap - leftBoundDropDown) + 'px';
            }
          }
        }.bind(this),
        0
      );

      eventBus.$emit('filterStateChanged', val);
      if (val) {
        this.panels.forEach((panel) => {
          if (
            panel.title === 'Column filters' ||
            panel.filterBy === 'STRATEGY'
          ) {
            panel.collapsed = false;
          }
        });
      }
    }
  },
  created() {
    this.mixData();
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside);
    }
    if (this.onCreate) {
      this.onCreate(this);
    }
    eventBus.$on('savedFilterAdded', () => {
      var localSavedFilters = filterUtils.getLocalFilters(this.widget);
      this.options = filterUtils.mergeLocalFilters(
        this.options,
        localSavedFilters
      );
      this.panels = this.getPanels(this.options);
    });
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  methods: {
    disabledTippyOptionsPanel(index) {
      return {
        placement: 'right',
        interactive: true,
        arrow: false,
        distance: 8,
        offset: '0, -100'
      };
    },
    disabledTooltip(item) {
      if (this.disabledFn(item)) {
        return this.disabledTooltipFn(item);
      }
    },
    disabledTooltipPanel(item) {
      if (this.panelDisabled(item)) {
        return this.disabledTooltip(item);
      }
    },
    panelDisabled(key) {
      const panelItems = this.filteredOptions.filter(
        (option) => option.dimensionType === key
      );
      if (panelItems.every(this.disabledFn)) {
        return true;
      } else {
        return false;
      }
    },
    disabled(item) {
      return this.disabledFn(item);
    },
    mixData() {
      var secondary = [];
      for (var i in this.secondaryData || {}) {
        secondary.push({
          dimensionColumn: this.secondaryData[i].key,
          dimensionLabel: this.secondaryData[i].label,
          dimensionName: this.secondaryData[i].key,
          dimensionType: this.secondaryData[i].type || 'OTHERS',
          smart: false,
          multi: this.secondaryData[i].multi,
          item: this.secondaryData[i],
          v1: this.secondaryData[i]?.v1
        });
      }
      // secondary.push({
      //   dimensionColumn: 'Share Of Voice',
      //   dimensionLabel: 'Share of Voice',
      //   dimensionName: 'Share Of Voice',
      //   dimensionType: 'SOV',
      //   customState: true,
      //   customChip: true
      // });
      this.options = (this.data || []).concat(secondary || []);
      this.panels = this.getPanels(this.options);
    },
    deleteFilter(event, filter) {
      event.stopImmediatePropagation();
      this.loading = true;
      return HttpLayer.post({
        cube: 'DELETE_FILTER',
        APIData: {
          id: filter.addtionalMetadata.filterInfo.id
        }
      }).then((response) => {
        this.loading = false;
        if (response.success && response.fullResponse[0].status === 'DELETED') {
          this.$snackbar.open({
            message: 'Filter deleted.',
            duration: 2000,
            buttonColor: '#f5d908',
            actionText: '              '
          });
          filterUtils.removeLocalFilter(
            this.widget,
            response.fullResponse[0].id
          );
          setTimeout(() => {
            eventBus.$emit('savedFilterAdded');
          }, 500);
        }
      });
    },
    onSearch() {
      var _data = this.filteredOptions;
      var collapse = false;
      if (this.searchText.length === 0) {
        collapse = true;
      }
      if (_data.length !== 0) {
        this.panels = this.getPanels(_data, collapse, true);
      } else {
        this.panels = [];
      }
    },
    getPanels(val, collapse, onSearch) {
      if (collapse === undefined) {
        collapse = true;
      }

      var panels = [];
      var _panels = {};
      if (collapse !== false) {
        val = val.concat(this.options);
      }

      if (!onSearch) {
        var localSavedFilters = filterUtils.getLocalFilters(this.widget);
        val = filterUtils.mergeLocalFilters(val, localSavedFilters || []);
      }

      for (var i = 0; i < val.length; i++) {
        if (!_panels[val[i].dimensionType]) {
          _panels[val[i].dimensionType] = [];
          var isStale = false;
          var metadata = (val[i].addtionalMetadata || {}).filterInfo || {};
          if (metadata.stale !== undefined) {
            isStale = metadata.stale;
          }

          if (!isStale) {
            panels.push({
              visible: !val[i].enableClickOnly,
              filterBy: val[i].dimensionType,
              title: this.mapping[val[i].dimensionType] || val[i].dimensionType,
              collapsed: val[i].enableClickOnly ? false : collapse
            });
          }
        }
        _panels[val[i].dimensionType].push(val[i]);
      }

      return panels;
    },
    open() {
      Vue.nextTick(
        function () {
          this.toggle();
        }.bind(this)
      );
    },
    expandCollapsePanel(panel) {
      Vue.set(panel, 'collapsed', !panel.collapsed);
    },
    filterData(val, filters) {
      var _data = [];
      return _data;
    },
    hoverItem(item) {
      this.hoveredItem = item;
    },
    itemClick(item) {
      if (this.selection) {
        this.selection(item);
        this.toggle();
      }
    },
    toggle() {
      this.isActive = !this.isActive;
      if (!this.isActive) {
        this.hoveredItem = null;
      }
      this.$emit('filter-state-change', this.isActive);
    },
    isInWhiteList(el) {
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      if (this.$refs.dropdownMenu !== undefined) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      if (this.$refs.trigger !== undefined) {
        const children = this.$refs.trigger.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      return false;
    },
    clickedOutside(event) {
      if (this.inline) return;

      if (!this.isInWhiteList(event.target)) {
        this.isActive = false;
        this.hoveredItem = null;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.filter-dropdown-menu {
  width: 240px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  position: absolute;
  overflow: auto;
}

.filter-dropdown-content {
  max-height: 304px;
  min-height: 136px;
  padding: 0px 0px 16px;
}

.filter-container {
  overflow: auto;
  width: 100%;
}

.rb-filter {
  position: relative;
  display: inline-flex;
  padding-bottom: 5px;
  line-height: 0;
  min-height: 24px;
}

.filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
}

.filter-list__item .deleteIcon {
  display: none;
}
.filter-list__item {
  line-height: 1;
}
.filter-list__item.active {
  background: #007cf6;
}
.filter-list__item.active p,
.filter-list__item.active span {
  color: white;
}
.filter-list__item.active .deleteIcon {
  display: flex;
}
.filter-list__item.disabled {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
