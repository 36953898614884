<template>
  <div>
    <div class="u-position-relative">
      <icon-text-actions
        v-if="isError"
        class="u-width-100"
        mode="default"
        :action="{ title: 'Error', icon: 'error-fill' }"
      />
      <icon-text-actions
        v-if="!entity.hasSubscribed && !isError"
        class="u-width-100"
        mode="default"
        :action="{ title: 'Subscribe', icon: 'email' }"
        @clicked="subscribeCallback"
      />
      <loader
        v-if="isLoading"
        class="fill--parent"
        :color="'#007cf6'"
      />
      <div
        v-tippy="tippyOptions"
        @click.stop="toggle"
      >
        <icon-text-actions
          v-if="entity.hasSubscribed && !isError"
          class="u-width-100"
          mode="default"
          :action="{ title: 'Subscribed', icon: 'email' }"
          :class-name="'u-color-blue-base'"
        />
      </div>
      <div
        :id="id"
        class="subscription__detail u-display-flex u-flex-direction-column u-font-weight-normal"
      >
        <section>
          <div
            class="title u-spacing-m-l u-font-size-5 u-spacing-mb-m u-font-weight-600"
          >
            Subscription info:
          </div>
          <div
            class="u-spacing-mh-l u-display-flex u-color-grey-light u-spacing-mb-s"
          >
            <p
              class="u-spacing-mr-m u-font-weight-600 u-font-size-5 u-light-height-1-5"
            >
              Frequency:
            </p>
            <p
              class="u-flex-1 u-font-size-6 u-display-flex u-flex-align-items-center"
            >
              <span>Daily</span>
            </p>
          </div>
          <!-- <div class="u-spacing-mh-l u-display-flex u-font-size-7 u-spacing-mb-s">
              <p class="u-width-40">Frequency:</p>
              <p class="u-flex-1">
                <span>Daily</span>
              </p>
          </div>-->
          <div
            class="u-spacing-ph-l u-display-flex u-font-size-6 u-spacing-pb-m u-flex-direction-column u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
          >
            <p
              v-if="!entity.isEntityFilterInvalidated"
              class="u-width-40 u-font-size-5 u-color-grey-light u-font-weight-600 u-display-flex u-flex-align-items-center u-line-height-1-5"
            >
              Filters:
            </p>
            <p
              v-if="entity.isEntityFilterInvalidated"
              v-tippy
              class="u-width-40 u-display-flex u-flex-align-items-center"
              title="Due to a recent update of internal catalogue, the filters you have applied are invalidated."
            >
              <rb-icon
                :icon="'error-fill'"
                class="rb-icon--medium u-color-red-base u-spacing-mr-xs"
              />
              <span
                class="u-color-red-base u-font-size-5 u-color-grey-light u-font-weight-600 u-line-height-1-5"
                >Filters:</span
              >
            </p>
            <!-- <div class="u-display-flex u-cursor-pointer u-flex-align-items-center u-spacing-mt-s" title="Due to a recent update of internal catalogue, the filters you have applied are invalidated." v-tippy v-if="entity.isEntityFilterInvalidated">

              <p class="u-flex-1 u-font-size-6">
                <span class="u-color-red-base">No Filters</span>
              </p>
            </div> -->
            <section v-if="!entity.isEntityFilterInvalidated">
              <p
                v-if="!entity.entityFilter"
                class="u-flex-1 u-font-size-6 u-color-grey-mid-light u-spacing-mt-s"
              >
                <span>No Filters</span>
              </p>
              <p
                v-for="(dimensions, index) in entity.entityFilter &&
                entity.entityFilter[0]"
                v-if="entity.entityFilter"
                :key="index"
                class="u-font-size-6 u-color-grey-mid-light u-spacing-mt-xs"
              >
                {{ dimensions.dimensionLabel }} :
                <span
                  class="u-color-grey-base"
                  :title="dimensions.values"
                >
                  {{ showFiltersValues(dimensions.values) }}</span
                >
              </p>
            </section>
            <section v-else>
              <p
                class="u-flex-1 u-font-size-6 u-color-grey-mid-light u-spacing-mt-s"
              >
                <span>No Filters</span>
              </p>
            </section>
          </div>
          <div class="title u-spacing-m-m u-font-size-6">
            <div
              class="u-display-flex u-spacing-ml-s u-flex-align-items-center u-cursor-pointer manage__subscription__wrp"
              @click="manageSubscibeCallback"
            >
              <rb-icon
                :icon="'settings'"
                class="rb-icon--medium"
              />
              <span class="u-spacing-ml-xs">Manage Subscriptions</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import Vue from 'vue';

export default {
  name: 'QuickSubscription',
  owner: 'Rajan V',
  components: {
    loader,
    iconTextActions
  },
  props: {
    hasSubscribed: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: function () {
        return {};
      }
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isActive: false
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#' + this.id,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      };
    }
  },
  created: function () {
    this.id = '__quick-subscription' + this._uid;
  },
  methods: {
    onShow() {
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
    },
    toggle($event) {
      Vue.set(this, 'isActive', !this.isActive);
      // this.isActive = !this.isActive;
    },
    manageSubscibeCallback() {
      this.$emit('manageSubscibeCallback', this.entity);
    },
    subscribeCallback() {
      this.$emit('subscribeCallback', this.entity);
    },
    showFiltersValues(filterArray) {
      if (filterArray.length > 0) {
        var maxStrLen = 24;
        var concatStr = '';
        for (var idx = 0; idx <= filterArray.length; idx++) {
          var curSelected = filterArray[idx];
          if (concatStr.length >= maxStrLen - 3) {
            concatStr = concatStr.slice(0, maxStrLen - 3);
            // add '...' if all the selections is not fit into concatStr
            for (var k = 0; k < idx; k++) {
              if (!concatStr.includes(filterArray[k])) {
                concatStr = concatStr.concat('...');
              }
            }
            // Handling edge case ',' in the end for unclipped selections.
            if (concatStr.charAt(concatStr.length - 1).includes(',')) {
              concatStr = concatStr.slice(0, concatStr.length - 1);
            }
            var moreCount = filterArray.length - idx;
            concatStr = concatStr.concat(
              idx < filterArray.length ? ' & ' + moreCount + ' more' : ''
            );
            break;
          } else if (curSelected && curSelected.length > 0) {
            concatStr = concatStr.concat(
              curSelected,
              idx < filterArray.length - 1 ? ', ' : ''
            );
          }
        }
        return concatStr;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.subscription__detail {
  min-width: 224px;
  max-width: 384px;
}
.manage__subscription__wrp {
  color: #6a7075;
}

.manage__subscription__wrp:hover {
  color: #2b333b;
}
</style>
