<template>
  <div>
    <div
      v-if="Boolean(errorMessage)"
      class="u-spacing-mb-m"
    >
      <!-- eslint-disable -->
      <div class="u-font-size-6 u-font-weight-bold u-color-red-base pre-wrap">
        {{ errorMessage }}
      </div>
      <slot name="slot-below-error-message" />
    </div>

    <div class="u-display-flex u-spacing-mt-xs">
      <div
        :title="leftBtnTooltip"
        v-tippy="{ placement: 'top', arrow: true }"
      >
        <rb-button
          v-if="btnLeftText"
          style="min-width: 93px"
          :text="btnLeftText"
          :type="leftBtnType"
          :class="customClassLeft"
          :click-fn="btnLeftClick"
          :disabled="disableLeftBtn"
          :loading="leftButtonLoading"
          class="u-spacing-mr-m"
        />
      </div>
      <rb-button
        v-if="btnCenterText"
        style="min-width: 93px"
        :text="btnCenterText"
        :type="centerBtnType"
        :class="customClassLeft"
        :click-fn="centerBtnClick"
        :disabled="disableCenterBtn"
        :loading="centerButtonLoading"
        class="u-spacing-mr-m"
      />
      <slot name="center" />
      <rb-button
        v-if="btnRightText"
        style="min-width: 93px"
        :text="btnRightText"
        :class="customClassRight"
        :disabled="disableRightBtn"
        :loading="rightButtonLoading"
        :type="rightBtnType"
        :click-fn="btnRightClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rightButtonLoading: {
      type: Boolean,
      default: false
    },
    leftButtonLoading: {
      type: Boolean,
      default: false
    },
    centerButtonLoading: {
      type: Boolean,
      default: false
    },
    customClassLeft: {
      type: String,
      default: ''
    },
    customClassRight: {
      type: String,
      default: ''
    },
    btnRightText: {
      type: String,
      default: 'Next'
    },
    btnLeftText: {
      type: String,
      default: 'Back'
    },
    skipStepText: {
      type: String,
      default: 'Skip step'
    },
    disableLeftBtn: {
      type: Boolean,
      default: false
    },
    disableRightBtn: {
      type: Boolean,
      default: false
    },
    disableCenterBtn: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    centerBtnType: {
      type: String,
      default: 'filled'
    },
    btnCenterText: {
      type: String,
      default: ''
    },
    rightBtnType: {
      type: String,
      default: 'filled'
    },
    leftBtnType: {
      type: String,
      default: 'filled'
    },
    leftBtnTooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  watch: {
    disableRightBtn: {
      handler(newValue) {
        this.$emit('onRightBtnDisabled', newValue);
      },
      immediate: true
    }
  },
  methods: {
    btnLeftClick() {
      this.$emit('onClickBtnLeft');
    },
    btnRightClick() {
      this.$emit('onClickBtnRight');
    },
    centerBtnClick() {
      this.$emit('onClickCenterBtn');
    },
    skipStep() {
      this.$emit('onClickSkipStep');
    }
  }
};
</script>

<style lang="css" scoped>
.rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
.rb-button--hollow[disabled] {
  background: #aaadb1 !important;
  border: none !important;
  color: #fff !important;
}

.pre-wrap {
  white-space: pre-wrap;
}
</style>
