<template>
  <div class="u-display-flex u-flex-direction-column u-font-size-1 ams-skus">
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <messageBar
      :show="showBlueBar"
      :page-type="'keywords'"
      @hideAction="hideBlueBar"
    />
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="getAdGroupsActivitylog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'Ad Groups'"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <div
      class="u-display-flex u-bg-color-grey-white u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="wk-m-adGroups-filters"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'instacart_adgroups-filter'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="true"
        :new-date="true"
        :allow-date-compare="true"
        :default-date-post-range="'last30Days'"
        :max-date="getInstaCartAdGroupsPageWiseMinMaxKey"
        :enable-save="true"
        :on-create="onCreate"
        :page="'campaigns_adgroup'"
        :loader-status="loaderStatus"
        :additional-ranges="additionalRanges"
        :common-date-key="'common-date-cm'"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        @savedFilterApplied="filterKey++"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Ad Groups'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            bulk-search-ref="instacartAdGroups"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          />
        </span>
      </rb-filter-panel>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      :dates="filterInstance.selectedDates"
    />
    <card-widget
      class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
      :config="config.widgets['widget1']"
      :data="getInstacartAdGroupsData[config.widgets['widget1'].meta.key] || {}"
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    class="metric-card-hover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        :class-name="'campaigns-select'"
                        :search-enabled="true"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span class="u-font-size-5">{{ val }}</span>
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getInstacartAdGroupsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="This metric is enabled only when 1 row is plotted"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <metric
                        v-if="
                          val &&
                          ((
                            getInstacartAdGroupsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val] &&
                          val !== 'Promotions'
                        "
                        :size="'l'"
                        class="u-display-inline-flex u-spacing-mt-s"
                        :config="
                          ((
                            getInstacartAdGroupsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metricsUnits || {})[val]
                        "
                        :data="
                          ((
                            getInstacartAdGroupsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                      />
                      <span
                        v-if="
                          !val &&
                          !((
                            getInstacartAdGroupsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val] &&
                          val !== 'Promotions'
                        "
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                      <!-- eslint-disable -->
                      <span
                        v-if="val === 'Promotions'"
                        class="u-font-size-3 u-color-grey-light u-font-weight-bold u-spacing-mt-s"
                        v-html="getPromotionDays()"
                      />
                      <!-- eslint-enable -->
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <div class="u-display-flex">
              <section
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <span
                  v-if="numberOfPlottedSelections !== 0"
                  class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                  :class="{ isHighlighted: isHighlighted }"
                  style="min-height: 20px"
                >
                  <span class="u-spacing-pr-s u-line-height-1_2"
                    >Graphs plotted for
                    {{ numberOfPlottedSelections }} rows</span
                  >
                  <span @click="plotRows('delete')">
                    <rb-icon
                      :icon="'cross'"
                      class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                      :class="{
                        'isHighlighted u-color-grey-white': isHighlighted
                      }"
                    />
                  </span>
                </span>
              </section>
              <span>
                <icon-text-actions
                  v-if="isExpand"
                  class="u-cursor-pointer padding--right u-width-100"
                  mode="default"
                  :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                  @clicked="isExpand = !isExpand"
                />
                <icon-text-actions
                  v-if="!isExpand"
                  class="u-cursor-pointer u-width-100 padding--right"
                  mode="default"
                  :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                  @clicked="isExpand = !isExpand"
                />
              </span>
            </div>
          </div>
          <chart
            id="workbenchMSKUsChart"
            :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
            class="c3-large u-flex-1 u-spacing-mt-l"
            :config="config.widgets['widget1'].body.chart"
            :data="widget1Data"
          />
        </div>
      </div>
    </card-widget>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div class="u-position-relative">
      <bidChangePanel
        v-if="showBidChangePanel"
        :selected-values="filteredSelections"
        retailer="instacart"
        action-panel-type="adgroups"
        :title="bidChangePanelTitle"
        :parent-ref="parentRef"
        @closePanelMethod="bidChangeAfterAction"
        @afterAPIActionToCall="bidChangeAfterAction"
      />
      <addSkus
        v-if="showAddSkus"
        :selections="filteredSelections"
        :show-adgroup="false"
        @afterAPIActionToCall="closeActionsWorkflow"
        @close="closeActionsWorkflow"
      />
    </div>

    <div
      v-if="!showActionsBar"
      class="u-display-flex u-flex-justify-content-flex-end u-flex-align-items-baseline u-spacing-p-m u-bg-color-grey-white actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <icon-text-actions
        mode="default"
        :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
        @clicked="openManageColumns"
      />
      <rb-download-button
        :download-email="true"
        :config="config.widgets['widget2']"
        :column-map="config.widgets['widget2'].body.download.columnMap"
        :on-email-click="openEmailPopup"
        :class-name="'enlargeEmailIcon'"
      />
    </div>

    <card-widget
      :apply-card-class="false"
      class="u-bg-color-grey-white u-flex-1 u-spacing-mb-m u-min-height-100"
      :show-loader="getSubGroupsLoader"
      :config="config.widgets['widget2']"
      :data="getInstacartAdGroupsData[config.widgets['widget2'].meta.key] || {}"
    >
      <div slot="body">
        <div
          v-if="!getSubGroupsLoader"
          class="u-display-flex u-flex-wrap-yes"
        >
          <rb-insights-table
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getInstacartAdGroupsData[config.widgets['widget2'].meta.key] ||
                {}
              ).rows
            "
            :table-column="tableColumns"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :scroll-to-node-id="scrollToNodeId"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="false"
            :primary-key="'ad_id'"
            :levels="levels"
            :has-expand="true"
            :expand-cell-width="50"
            :custom-has-expand-column-order="2"
            :pagination="true"
            :pagination-total-key="
              (
                getInstacartAdGroupsData[config.widgets['widget2'].meta.key] ||
                {}
              ).totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :table-header-sticky-obj="sticky"
            :show-pagination-options="true"
            @tableExpandChangeTriggered="expandTriggered"
          />
        </div>
      </div>
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="instacartAdGroups-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from '@/components/pages/instacart/campaigns/skus/widgets.js';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import addSkus from '@/components/pages/instacart/campaigns/campaigns/addSkus.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import { metricsShownForInstacartChart } from '@/components/pages/instacart/campaigns/constants.js';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';

export default {
  components: {
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    bidChangePanel,
    messageBar,
    activityLog,
    modal,
    addSkus,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    amsAddMetricTile,
    SystemTransparencyBanner
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      additionalRanges: commons.additionalDateRanges(),
      levels: [
        {
          level: 1,
          getter: 'getInstacartSubGroupTypes',
          action: 'instacartSubGroupTypes'
        }
      ],
      showBidChangePanel: false,
      showAddSkus: false,
      parentRef: {},
      resultLength: 0,
      isSDCampaign: false,
      showEmailPopUp: false,
      modalConfig: {},
      filterKey: 0,
      chartWkbenchAxes: {},
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      promoTimelineMessages: [],
      promotionDays: 0,
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getInstacartAdGroupsSelectedFilters',
        setter: 'setInstacartAdGroupsSelectedFilters'
      },
      downloadFileSuffix: 'instacart_campaign-management_ad-groups',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForInstacartChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      disableMetric: [
        'Promotions',
        'Change in Traffic %',
        'Change in Conversion'
      ],
      isExpand: false,
      selections: [],
      showActionsBar: false,
      showBlueBar: false,
      timer: 30000,
      showActionsLog: false,
      adId: null,
      openActivity: true,
      rowSelectionMap: {},
      previousRowSelection: {
        old: [],
        new: []
      },
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'edit-status': this.updateStatus,
        'adjust-bid': this.adjustBidsPanel,
        'add-skus': this.addSkus
      },
      bidChangePanelTitle: 'Modify default bid',
      allowedActions: [
        {
          icon: 'show-chart',
          title: 'Plot Rows',
          id: 'show-chart'
        },
        {
          icon: 'pencil',
          title: 'Edit Status',
          id: 'edit-status',
          dropDown: true,
          dropDownOptions: [
            { title: 'Active', value: 'active', color: 'u-color-green-base' },
            {
              title: 'Inactive',
              value: 'inactive',
              color: 'u-color-orange-base'
            }
          ]
        },
        {
          icon: 'dollar',
          title: 'Modify default bid',
          id: 'adjust-bid'
        },

        {
          icon: 'add-circle-outline',
          title: 'Add Keywords',
          id: 'edit-actions1'
        },
        {
          icon: 'sku-box',
          title: 'Add skus',
          id: 'add-skus'
        }
      ],
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      isHighlighted: false,
      showPaginationOptions: utils.internalUserCheck(window.user),
      enableManageColumns: true,
      manageColumnsPanelListener: 'instacartAdGroupsManageColumnsPanel',
      workBenchDataKey: 'getInstacartAdGroupsData',
      filterInstance: null,
      ...commons.commonDataProperties,
      ...utils.filterConfigs('instacart')
    };
  },
  computed: {
    bulkSearchProps() {
      return this.config.bulkSearchProps();
    },
    filteredSelections() {
      return this.selections.filter((item) => item.data.sub_type === '-');
    },
    getSubGroupsLoader() {
      return this.$store.getters.getSubgroupLoader;
    },
    emailStatus() {
      return this.$store.getters.getInstacartEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          (
            this.getInstacartAdGroupsData[
              this.config.widgets.widget1.meta.key
            ] || {}
          ).load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    getAdGroupsActivitylog() {
      return this.$store.getters.getInstacartAdGroupsActivityLog;
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.AdGroupsActivityLog;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.ad_id) {
            return false;
          }
          return (
            params.data.ad_id ===
            this.$store.getters.getInstacartAdGroupsPrimaryKey
          );
        }.bind(this)
      };
    },
    getInstacartAdGroupsData() {
      this.showActionsBar = false;
      this.resultLength = (
        (
          this.$store.getters.getInstacartAdGroupsData[
            this.config.widgets.widget2.meta.key
          ] || {}
        ).rows || []
      ).length;
      return this.$store.getters.getInstacartAdGroupsData;
    },
    widget1Data() {
      let data = (
        this.getInstacartAdGroupsData[this.config.widgets.widget1.meta.key] ||
        {}
      ).data;
      let clonedData = (data || {}).data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let promoMessageIndex = this.getPromoMessageIndex({ clonedData });
      let ignoreList = [
        'report_date',
        'count',
        'ordered_units',
        'Promotion Messages',
        'inventory_latest'
      ];
      this.metricsList = [];
      for (const element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }
      data = this.createSelectedMetricsList({
        data,
        clonedData,
        promoMessageIndex
      });
      return data;
    },
    getInstaCartAdGroupsPageWiseMinMaxKey() {
      return this.$store.getters.getInstaCartAdGroupsPageWiseMinMaxKey;
    }
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForInstacartChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeCreate() {
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchMSKUsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    let that = this;
    eventBus.$on('instacartAdGroupsFilterApplied', () => {
      that.plottedSelections = 0;
      that.isExpand = true;
      this.closeActionsWorkflow(false);
    });
    eventBus.$on('instacartAdGroupsSearchTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow(false);
    });
    eventBus.$on('instacartAdGroupsPaginationTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow(false);
    });

    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('instacartAdGroupsBulkSearchApplied', () => {
      that.plottedSelections = 0;
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.instacartAdGroups?.openBulkSearchPopup();
    });
  },
  created() {
    this.parentRef = this;
    if (this.allowedActions.length === 0) {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
    }
    this.config = widgetsConfig.config('adGroups')(this);
    this.config.widgets.widget1.meta.plotSelections = [];
    this.fetchFilters(
      'instacart_campaigns_ad_group_workbench',
      'FETCH_FILTERS_V2',
      'instacart_adGroups'
    );
    if (this.config.widgets.widget1.body.chart) {
      this.config.widgets.widget1.body.chart.chartOptions.tooltip_format.Promotions =
        function (value, ratio, id, index) {
          return '-  ' + this.promoTimelineMessages[index] || '';
        }.bind(this);
    }
  },
  beforeDestroy() {
    eventBus.$off('instacartAdGroupsFilterApplied');
    eventBus.$off('instacartAdGroupsSearchTriggered');
    eventBus.$off('instacartAdGroupsPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('instacartAdGroupsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  methods: {
    ...commonMethods,
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    getPromoMessageIndex({ clonedData }) {
      let promoMessageIndex = null;
      for (let m = 0; m < clonedData.length; m++) {
        if (clonedData[m][0]) {
          if (clonedData[m][0].toLowerCase() === 'promotion messages') {
            promoMessageIndex = m;
            break;
          }
        }
      }
      return promoMessageIndex;
    },
    triggerLevelSelection() {
      const gridOptions = this.rbTableInstance.gridOptions.api;
      const selectedGroupIds = this.selections
        .map((selectedRow) => selectedRow.data.ad_group_id)
        .filter((v, i, a) => a.indexOf(v) === i);
      const defaultRowMapValues = {
        '-': false,
        'Browse & Impulse': false,
        'Default bid Aggregate': false,
        'Override bid Aggregate': false
      };
      gridOptions.forEachNode((row) => {
        if (!this.rowSelectionMap[row.data.ad_group_id]) {
          this.rowSelectionMap[row.data.ad_group_id] = JSON.parse(
            JSON.stringify(defaultRowMapValues)
          );
        }
        this.selections.forEach((selectedRow) => {
          if (
            this.rowSelectionMap.hasOwnProperty(selectedRow.data.ad_group_id)
          ) {
            if (row.data.isExpand || row.data['ag-grid-level'] === 1) {
              this.rowSelectionMap[selectedRow.data.ad_group_id] = JSON.parse(
                JSON.stringify(defaultRowMapValues)
              );
            }
            this.rowSelectionMap[selectedRow.data.ad_group_id][
              selectedRow.data.sub_type
            ] = true;
            this.rowSelectionMap[selectedRow.data.ad_group_id]['-'] = true;
          }
        });
      });
      for (const row of Object.keys(this.rowSelectionMap)) {
        if (!selectedGroupIds.includes(row)) {
          this.rowSelectionMap[row] = JSON.parse(
            JSON.stringify(defaultRowMapValues)
          );
        }
      }
      this.selectMappedRows();
    },
    selectMappedRows() {
      const gridOptions = this.rbTableInstance.gridOptions.api;
      gridOptions.forEachNode((row) => {
        if (this.rowSelectionMap[row.data.ad_group_id][row.data.sub_type]) {
          row.gridApi.selectNode(row, true);
        }
      });
    },
    expandTriggered(expandObj) {
      setTimeout(() => {
        this.selectMappedRows();
      }, 0);
    },
    bidChangeAfterAction() {
      this.bidLoader = false;
      this.closeActionsWorkflow();
      this.adjustBidsPanel();
      this.onSelectionChanged([]);
      this.plotRows('delete');
    },
    scrollToNodeId() {
      return null;
    },
    getInitialPayloadObject(data, params) {
      let obj = {
        actionPayload: {
          profileId: data.profile_id || 0,
          campaignId: data.campaign_id,
          adgroupId: data.ad_group_id,
          previousStatus: data.status,
          newStatus: params
        },
        viewPayload: {
          name: data.ad_group_name,
          campaignId: data.campaign_id,
          adgroupId: data.ad_group_id,
          previousStatus: data.status,
          newStatus: params
        },
        actionType: 'instacartAdGroupStatusChange',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      return obj;
    },

    processPayloadForBidChange(params, payloadObjRef, rowData) {
      if (
        params.value.type === 'increaseBids' ||
        params.value.type === 'decreaseBids'
      ) {
        if (params.value.selectionType === 'percent') {
          payloadObjRef.actionPayload.newBid = commons.getBidPercentValue(
            params.value.type,
            rowData.bid,
            params.value.value
          );
        } else {
          payloadObjRef.actionPayload.newBid = params.value.value;
        }
      } else {
        payloadObjRef.currentBid = params.value.value;
        payloadObjRef.actionPayload.newBid = params.value.value;
      }
    },

    getPayload(params, actionType) {
      const paramsToReturn = [];
      for (const element of this.selections) {
        const data = element.data;
        const obj = this.getInitialPayloadObject(data, params);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },
    addSkus() {
      this.showAddSkus = !this.showAddSkus;
      this.showActionsBar = false;
    },
    hideBlueBar() {
      this.showBlueBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    updateStatus(selectedOption) {
      if (!(selectedOption && selectedOption[0])) {
        return;
      }
      let that = this;
      let selectedStatus = selectedOption[0].value.toLowerCase();
      let payload = this.getPayload(selectedStatus, 'productAdStatusChange');
      let othersObj = {
        successMessage:
          'Status change request has been submitted for the selected ASIN(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closeActionsWorkflow();
          that.onSelectionChanged([]);
          that.plotRows('delete');
        }
      };
      commons.bidChangeApiAction.call(this, selectedOption, payload, othersObj);
    },
    adjustBidsPanel() {
      this.showActionsBar = false;
      this.showBidChangePanel = !this.showBidChangePanel;
      let elm = document.getElementsByClassName('detail-panel');
      if (this.showBidChangePanel === true) {
        elm[0].scrollTo(0, 0);
      } else {
        elm[0].scrollTo(0, 500);
      }
    },
    adjustBidAction(params) {
      if (params.actionType.toLowerCase() === 'apply') {
        this.callBidActionApi(params);
      } else {
        this.closeActionsWorkflow();
        this.adjustBidsPanel();
      }
    },

    closeActionsWorkflow(fetchChart = true) {
      this.showAddSkus = false;
      commons.closeActionsWorkflow.call(this);
      if (fetchChart) {
        this.onSelectionChanged([]);
        this.plotRows('delete');
      }
    },

    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection
    ) {
      if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        this.actionIdFunctionMap[actionId]();
      }
    },

    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openAmsSkusActivityLog');
      this.$store.dispatch('getAdGroupsProductAd', {
        primaryKey: that.params.node.data.ad_group_id
      });
      if (this.adId === that.params.node.data.ad_group_id) {
        return;
      }
      this.adId = that.params.node.data.ad_group_id;
      this.rbTableInstance.redrawTableRows();
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.adId = null;
      this.$store.dispatch('closeAdgroupsActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    plotRows(type) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 0) {
        this.$snackbar.open({
          message: 'Graps are plotted only for parent Ad Group level',
          duration: 3000,
          actionText: ''
        });
      }
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForInstacartChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      eventBus.$emit(
        'instacartAdGroupsPlotSelectedRows',
        this.plotRowsSelections
      );
      document.getElementById('wk-m-adGroups-filters').scrollIntoView();
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    onSelectionChanged(data) {
      this.plotRowsSelections = data.map(function (selection) {
        return selection.data;
      });
      this.selections = data;
      this.triggerLevelSelection();
      this.showActionsBar = data.length > 0;
      commons.validateForArchivedActions(this.allowedActions, this.selections);
      this.isSDCampaign = commons.invalidateSDActions(
        data,
        this.allowedActions
      );
      this.onSDCampaignsSelection();
      const avaliableActions = [
        'edit-status',
        'adjust-bid',
        'add-skus',
        'show-chart'
      ];
      const noMultiSelect = ['add-skus'];
      const multipleSelected = this.selections.length > 1;
      for (const element of this.allowedActions) {
        if (!avaliableActions.includes(element.id)) {
          element.disabled = true;
          element.tippy =
            'Action will be available soon, please take this action from the campaigns tab in the mean time';
        } else if (noMultiSelect.includes(element.id) && multipleSelected) {
          element.disabled = true;
          element.tippy = 'Action set limited for multiple campaign selections';
        }
      }
    },
    onSDCampaignsSelection() {
      const unavailableMessage =
        'This action is currently unavailable for SD campaigns';
      const actionTippys = {
        'edit-status': unavailableMessage
      };
      this.allowedActions = commons.modifyTippyBasedOnActionState(
        this.allowedActions,
        actionTippys,
        this.isSDCampaign
      );
      if (this.isSDCampaign) {
        this.$snackbar.open({
          message: 'Action set limited for SD campaigns',
          duration: 3000,
          actionText: ''
        });
      }
    },
    getPromotionDays() {
      if (this.promotionDays === 0) {
        return 'No Promos';
      } else {
        return this.promotionDays + (this.promotionDays > 1 ? ' Days' : ' Day');
      }
    },
    getAllTableColumns() {
      return this.config.widgets.widget2.body.columnDefs;
    }
  }
};
</script>

<style lang="css" scoped>
.padding--right {
  padding-right: 0% !important;
}
</style>
