import Recommendations from '@/components/pages/recommendations/recommendations.vue';
import RecommendationLanding from '@/pages/recommendations/recommendationsLanding';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      path: '',
      component: RecommendationLanding,
      name: 'recommendationsLanding',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'all',
      name: 'All',
      config: 'all',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations',
      component: Recommendations,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'sales',
      component: Recommendations,
      name: 'Sales',
      config: 'sales',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      name: 'Marketing',
      path: 'marketing',
      config: 'marketing',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations',
      component: Recommendations,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      name: 'Operations',
      path: 'operations',
      config: 'operations',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'recommendations',
      component: Recommendations,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    { path: '/', redirect: '' }
  ]
};
