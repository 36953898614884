<template>
  <div class="widget--full-screen u-display-flex u-flex-direction-column">
    <section class="page--header u-display-flex">
      <div
        class="u-display-flex u-flex-justify-content-flex-start u-width-100 u-spacing-pv-l"
      >
        <span
          class="u-display-flex u-spacing-mh-s u-flex-direction-column u-spacing-mt-xs"
          @click="closePage"
        >
          <rb-icon
            :icon="closeIcon"
            :class="closeIconClasses"
            :style="closeIconStyles"
            class="u-color-grey-lighter rb-icon--xx-medium u-cursor-pointer u-spacing-ml-s"
          />
        </span>
        <div class="u-display-flex u-width-100 u-flex-direction-column">
          <span
            class="heading--input u-text-overflow-ellipsis u-font-size-1 u-font-weight-600 u-spacing-mb-s"
          >
            {{ pageTitle }}
          </span>
          <span
            class="heading--input text-italics u-font-size-4 u-color-grey-lighter"
          >
            {{ pageDescription }}
          </span>
        </div>
      </div>
    </section>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: 'Default Page Ttl'
    },
    pageDescription: {
      type: String,
      default: 'Default Page Decs'
    },
    closeIcon: {
      type: String,
      default: 'cross'
    },
    closeIconClasses: {
      type: String,
      default: ''
    },
    closeIconStyles: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  methods: {
    closePage(event) {
      this.$emit('closePage', event);
    }
  }
};
</script>

<style scoped lang="css">
.collapsible-page-close-button {
}
.page--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  width: 100%;
}
</style>
