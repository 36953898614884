<template>
  <div
    v-if="!(hideIfNull && displayValue === 'NA')"
    class="u-display-flex u-spacing-mt-s u-flex-direction-column"
  >
    <span
      class="u-font-size-7 u-line-height-1 u-line-height-1.31 u-color-grey-lighter u-font-weight-600"
      >{{ config['sub-heading'] }}</span
    >
    <div class="u-display-flex u-spacing-mt-xs">
      <span
        :class="[!config.caseSensitive ? 'u-text-case-title' : '', textClass]"
      >
        {{ displayValue }}</span
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          textMessage: 'NA'
        };
      }
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    hideIfNull() {
      return this.config?.hideIfNull || false;
    },
    displayValue() {
      let display = this.getDisplayValue(this.data[this.config.textMessage]);
      let displayType;
      if (this.config.mode === 'DATA') {
        displayType = this.data[this.config.displayTypeKey];
      } else {
        displayType = this.config.displayType;
      }
      switch (displayType) {
        case 'currency':
          if (display === 'NA') break;
          display = Vue.options.filters.num_format(display, 'currency');
          break;
        case 'percentage':
          display = Vue.options.filters.num_format(display, undefined, '%');
      }
      return display;
    },
    textClass() {
      return (
        this.config?.textClass ||
        'u-font-size-6 u-color-grey-light u-font-weight-600'
      );
    }
  }
};
</script>
