<template>
  <div class="content-dashboard price-variance">
    <dashboard-inflator
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      :show-active-filter-banner="false"
      @requestParamsChange="requestParamsChange"
    >
      <div slot="custom-filter">
        <div class="u-display-flex u-flex-direction-row">
          <rb-filter-panel
            :key="filterSettings.uiKey"
            style="z-index: 11"
            add-filter-text="Add a dispute vs recovery source filter"
            :get-filter-instance="getFilterInstance"
            listener-event="pricevar"
            :disable-between-operator="!filterSettings.enableBetweenOperator"
            :data="ciqFilterData"
            :is-always-on-filter="true"
            :primary="ciqFilterPrimaryData"
            emit="PriceVarEvent"
            :enable-save="false"
            :custom-config="customConfig"
            :enable-remove-all="false"
            @onCreate="getFilterInstance"
            @filterMounted="handleFilterMount"
          />
        </div>
      </div>
    </dashboard-inflator>
  </div>
</template>

<script>
import shortage_price_var_overview from '@/components/widgets/custom_widgets/custom_widget_components/charts/dual-charting-wb.vue';
import price_var_summary from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import Vue from 'vue';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import RbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import { cloneDeep, union } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import { eventBus } from '@/utils/services/eventBus';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';

export default {
  components: {
    shortage_price_var_overview,
    price_var_summary,
    rbFilterPanel
  },
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      globalViewId: 0,
      // id: 46,
      name: 'PriceVarianceReport',
      title: 'Price Variance'
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      headerOptions: () => {
        return {
          shortage_price_var_overview: {
            props: {
              'data-cy': 'performanceOverview',
              headerOptions: [],
              enableRollup: true,
              enableDownload: true,
              iconForDownload: 'share',
              tooltipText: 'Export',
              selectedMetricLimit: 2,
              minimumMetric: 4,
              removeDropdown: false,
              disableAddNewMetricButton: true,
              isMetricToolTipEnabled: true,
              modifyTooltipMessage: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto auto'
              },
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'feed_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  size: { height: 380 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: null
              },
              bypassFilters: ['ciq_filter']
            }
          },
          price_var_summary: {
            props: {
              enableDownload: true,
              isMetricToolTipEnabled: true,
              iconForDownload: 'share',
              tooltipText: 'Export',
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              searchParams: {
                show: true,
                placeholder: 'Search By SKU, PO, Inovice, Dispute',
                searchDimension: 'local_search'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name
        },
        shortage_price_var_overview: {
          ':widget': 'shortage_price_var_overview',
          ':timeseriesRollupBy': 'WEEK',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        price_var_summary: {
          ':widget': 'price_var_summary',
          ':entityType': 'asin'
        }
      },
      filterSettings: {
        emitEvent: 'shortageInvoiceFilterApply',
        uiKey: 0,
        show: true,
        backendCube: 'shortage_invoice_dashboard',
        defaultDatePostRange: 'last90Days',
        enableSave: false,
        listenerEvent: 'price-variance-filter',
        search: {
          enable: true,
          placeholder: 'Search for a SKU or PO',
          width: '240px',
          searchUniqKey: 'searchSubmit'
        },
        filterV2: true,
        enableBetweenOperator: true
      },
      widgetMetadata: [],
      ciqFilterData: [
        {
          ciq_filter: 'CIQ'
        },
        {
          ciq_filter: 'You'
        }
      ],
      ciqFilterPrimaryData: [
        {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Dispute Vs Recovery Source',
          dimensionName: 'ciq_filter',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        }
      ],
      customConfig: {
        title: 'Dispute vs Recovery Source',
        anchor: 'ciq_filter',
        type: 'CLIENT_INTERNAL_CATALOGUE',
        config: {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Dispute Vs Recovery Source',
          dimensionName: 'ciq_filter',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        },
        smart: true,
        multi: true
      },
      widgetArray: ['shortage_price_var_overview', 'price_var_summary']
    };
  }, //
  watch: {
    requestParams(newVal) {}
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('shortage_price_var_overview', shortage_price_var_overview);
    Vue.component('price_var_summary', price_var_summary);
    Vue.component('RbRadioGroup', RbRadioGroup);
  },
  methods: {
    getFilterInstance(filter) {
      this.filterInstance = filter;
      this.handleFilterMount(this.filterInstance);
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        if (filters?.values) {
          const global = cloneDeep(this.getGlobalRequestParams());
          const where = transformer.generateWhereClause({}, filters, true);
          // currently does not handle the global pre existing dimensionNameValue lists gracefully since we updating this directly not considering that case.
          // to be improved
          global[':dimensionNameValueList'] =
            where.dimensionNameValueList || [];
          this.updateGlobalRequestParams(global);
        }
        eventBus.$on('PriceVarEvent', (data) => {
          this.ciqFilterEventHandler(instance, data);
        });
      }
    },
    ciqFilterEventHandler(instance, data) {
      let filters = instance.getSelectedFilters();
      const filterData = {
        values: { ...data, ...filters.values }
      };
      delete filterData.values.date_range;
      let where = {};
      if (data?.ciq_filter?.length === 1) {
        if (data.ciq_filter[0] === 'CIQ') {
          where.dimensionNameValueList = [
            {
              dimensionName: 'ciq_filter',
              dimensionValue: 'CIQ',
              operator: 'ILIKE'
            }
          ];
        } else {
          where.dimensionNameValueList = [
            {
              dimensionName: 'ciq_filter',
              dimensionValue: 'CIQ',
              operator: 'NOT_LIKE'
            }
          ];
        }
      } else {
        where.dimensionNameValueList = [];
      }

      this.widgetArray.map((widget) => {
        const tempWidgetParams = this.getWidgetRequestParams(widget);
        const updatedTempWidgetParams =
          tempWidgetParams[':dimensionNameValueList']?.filter(
            (item) => item.dimensionName !== 'ciq_filter'
          ) || [];
        tempWidgetParams[':dimensionNameValueList'] = union(
          updatedTempWidgetParams,
          where.dimensionNameValueList
        );
        this.updateWidgetRequestParams(tempWidgetParams, widget);
      });
    }
  }
};
</script>

<style lang="css">
.inputClassForEntityTaggingSearch input {
  width: 240px !important;
}
.content-dashboard .price_var_summary .link--asin:hover,
.content-dashboard .price_var_summary div[col-id^='dispute_id'].ag-cell:hover {
  color: #4b5158 !important;
}
.content-dashboard .price_var_summary div[col-id^='po_number'].ag-cell > div {
  margin: 0.8rem !important;
}
.content-dashboard .price_var_summary div[col-id^='asin'].ag-cell,
.content-dashboard .price_var_summary div[col-id^='po_number'].ag-cell,
.content-dashboard .price_var_summary div[col-id^='invoice_number'].ag-cell,
.content-dashboard .price_var_summary div[col-id^='dispute_id'].ag-cell {
  border-right: 1px solid #e9eaeb !important;
}
.content-dashboard
  .price_var_summary
  div[col-id^='po_number'][class='ag-header-cell ag-header-cell-sortable'],
.content-dashboard
  .price_var_summary
  div[col-id^='invoice_number'][class='ag-header-cell ag-header-cell-sortable'],
.content-dashboard
  .price_var_summary
  div[col-id^='dispute_id'][class='ag-header-cell ag-header-cell-sortable'],
.content-dashboard
  .price_var_summary
  div[col-id^='asin'][class='ag-header-cell ag-header-cell-sortable'] {
  border-right: 1px solid #e9eaeb !important;
}
.content-dashboard
  .price_var_summary
  div[col-id^='po_number'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.content-dashboard
  .price_var_summary
  div[col-id^='invoice_number'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.content-dashboard
  .price_var_summary
  div[col-id^='dispute_id'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'],
.content-dashboard
  .price_var_summary
  div[col-id^='asin'][class='ag-header-cell ag-header-cell-sortable ag-column-hover'] {
  border-right: 1px solid #e9eaeb !important;
}
.content-dashboard .price_var_summary .link--asin {
  color: #2b333b;
  border-bottom: 1px dotted !important;
}
.content-dashboard .price_var_summary .link--asin:hover {
  color: #007cf6 !important;
  text-decoration: none !important;
}
.table-header-tooltip .tooltip-message {
  white-space: pre-line !important;
}
.shortage_price_var_overview .header-container-cwc {
  padding-bottom: 0;
}
.price-variance .rollUpWrapper {
  margin-right: 1.6rem;
}
</style>
