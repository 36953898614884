<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="u-display-flex u-flex-direction-column">
    <h4
      v-if="spotlightData.heading"
      class="u-font-size-4 heading"
      v-html="spotlightData.heading"
    />
    <div class="main u-display-flex u-spacing-mv-m u-line-height-m">
      <div
        v-if="spotlightData.skuDetails"
        id="sku_tooltip"
        class="u-width-320px"
      >
        <rb-sku
          class="u-text-overflow-ellipsis"
          :show-image="true"
          :title="spotlightData.skuDetails.title"
          :image-url="spotlightData.skuDetails.imageUrl"
          :asin="spotlightData.skuDetails.asin"
          :product-page-url="spotlightData.skuDetails.pdpUrl"
          :enable-s-d-p="false"
        />
      </div>
      <div
        v-if="spotlightData.skuDetails"
        v-tippy="skuTippyOptions"
        class="sku-img u-spacing-mr-xs"
      >
        <img
          :src="spotlightData.skuDetails.imageUrl"
          alt="Sku Image"
          @error="invalidImageFallback"
        />
      </div>
      <rb-icon
        v-else
        icon="light-bulb"
        class="u-color-blue-base u-flex-align-self-flex-start u-spacing-mr-xs"
      />
      <p
        class="u-font-size-5"
        v-html="spotlightData.content"
      />
    </div>
    <p
      class="card-footer u-font-size-6 u-display-flex u-flex-align-items-flex-end u-flex-grow-1"
    >
      <template v-for="(action, index) in spotlightData.actions">
        <span
          :key="index"
          class="u-color-blue-base u-cursor-pointer u-font-weight-600"
          @click="handleActionClick(action.handler)"
          >{{ action.text }}</span
        >
        <span
          v-if="index !== spotlightData.actions.length - 1"
          :key="action.text"
          class="u-spacing-mh-xs"
          >or</span
        >
      </template>
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  props: {
    spotlightData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      skuTippyOptions: {
        html: '#sku_tooltip',
        theme: 'light light-shadow',
        placement: 'top-start',
        interactive: true,
        reactive: true,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  methods: {
    handleActionClick(handlerFn) {
      handlerFn?.();
    },
    invalidImageFallback(e) {
      let urls = [
        'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_1.jpg',
        'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_2.jpg',
        'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_3.jpeg',
        'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_4.jpg',
        'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_5.jpeg'
      ];
      e.target.src = urls[Math.floor(Math.random() * urls.length)];
    }
  }
});
</script>

<style lang="css" scoped>
.sku-img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}
.sku-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
#sku_tooltip ::v-deep .sku--image img {
  object-fit: contain;
}
.u-line-height-m {
  line-height: 1.3;
}
</style>
