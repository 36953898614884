<template>
  <div class="edit-container">
    <form @submit.prevent="apply">
      <div class="u-spacing-ph-m u-spacing-pt-m">
        <div
          v-for="(section, index) in sections"
          :key="index"
          class="u-spacing-mb-m"
        >
          <span class="u-font-size-6 u-font-weight-700">{{
            section.title
          }}</span>
          <rb-tooltip-header
            v-if="section.tooltipText"
            :id="section.key"
            :title="section.title"
            :message="section.tooltipText"
          />
          <rb-icon
            v-tippy="setTooltip(section.key).tippyOptions"
            class="u-color-grey-lighter tooltip-icon u-spacing-ml-xs u-cursor-pointer rb-icon--small"
            icon="help-fill"
          />
          <div v-if="section.rows && section.type !== 'score_bands'">
            <div
              v-for="row in section.rows"
              :key="row.key"
              class="u-spacing-mv-m u-display-flex u-flex-direction-column"
            >
              <div v-if="row.type === 'numeric'">
                <label
                  class="u-font-size-6 u-color-grey-lighter u-font-weight-600"
                  >{{ row.title }}</label
                >
                <div
                  class="input-container u-spacing-mv-s u-display-flex u-flex-justify-content-space-between"
                >
                  <span
                    v-if="row.operator"
                    class="u-spacing-pl-s u-spacing-pr-l u-spacing-pt-s u-color-grey-lighter u-border-all u-border-width-s u-border-color-grey-xxx-light u-font-size-3"
                    >{{ row.operator }}</span
                  >
                  <input
                    v-model="row.value"
                    type="number"
                    min="0"
                    class="u-width-100 u-color-grey-base u-font-weight-400 u-spacing-pl-m u-border-width-s u-border-color-grey-xxx-light u-border-all"
                    :required="row.required"
                  />
                </div>
              </div>
              <div
                v-if="row.type === 'switch'"
                class="u-display-flex u-flex-justify-content-space-between"
              >
                <span class="u-font-size-5 u-font-weight-600">{{
                  row.title
                }}</span>
                <div>
                  <toggle-slider
                    :original-state="row.value === 'true'"
                    @check="(data) => switchListener(data, row)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="section.type === 'score_bands'">
            <score-bands
              :rows="section.rows"
              @update="(data) => scoreBandListener(data, section.key)"
            />
          </div>
        </div>
      </div>
      <div
        class="u-display-flex u-width-100 u-bg-color-grey-white u-spacing-p-m actions-container u-width-50"
      >
        <rb-button
          :click-fn="cancel"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
        />
        <div>
          <input
            :disabled="applyBtnDisabled"
            class="apply u-cursor-pointer"
            :class="[
              applyBtnDisabled
                ? 'u-bg-color-grey-x-light'
                : 'u-bg-color-blue-base'
            ]"
            type="submit"
            value="Apply"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import toggleSlider from '@/components/pages/insights/amazon/share-of-voice/atoms/toggle-slider.vue';
import scoreBands from '@/components/basic/score_bands.vue';
import { cloneDeep, isEqual } from 'lodash';

export default {
  components: { toggleSlider, scoreBands },
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      sections: [],
      applyBtnDisabled: false
    };
  },
  watch: {
    sections: {
      handler() {
        this.applyBtnDisabled = isEqual(this.data, this.sections);

        this.sections?.forEach((section) => {
          if (section.type === 'score_bands') {
            Object.keys(section.rows)?.forEach((e) => {
              if (
                !section.rows[e].maxValue?.length ||
                !section.rows[e].minValue?.length
              ) {
                this.applyBtnDisabled = true;
              }
            });
          } else {
            section?.rows?.forEach((e) => {
              if (!e?.value?.length) {
                this.applyBtnDisabled = true;
              }
            });
          }
        });
      },
      deep: true
    }
  },
  created() {
    this.sections = cloneDeep(this.data);
  },
  methods: {
    setTooltip(id) {
      return {
        id: id,
        tippyOptions: {
          html: '#' + id,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    apply(e) {
      e.preventDefault();
      e.stopPropagation();
      const keyDataMapping = {};
      this.sections.forEach((section) => {
        if (section.type === 'score_bands') {
          Object.keys(section.rows).forEach((e) => {
            const scoreBand = section.rows[e];
            keyDataMapping[`${scoreBand.key}_greater_then`] =
              scoreBand.minValue;
            keyDataMapping[`${scoreBand.key}_less_then`] = scoreBand.maxValue;
          });
        } else {
          section.rows.forEach((e) => (keyDataMapping[e.key] = e.value));
        }
      });
      this.$emit('apply', keyDataMapping);
    },
    scoreBandListener(data, key) {
      const sections = cloneDeep(this.sections);
      const scoreBand = sections.find((e) => e.key === key);
      scoreBand.rows = data;
      this.sections = sections;
    },
    cancel() {
      this.$emit('cancel');
    },
    switchListener(val, row) {
      row.value = val.toString();
    }
  }
};
</script>

<style lang="css" scoped>
.edit-container .input-container {
  height: 36px;
}

.edit-container .tooltip-icon {
  top: 1px;
}

.edit-container .apply {
  background-color: #e9eaeb;
  border-radius: 4px;
  margin-left: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 11px;
  border: none;
  height: 27px;
  font-family: inherit;
  font-weight: 600;
}

.edit-container .actions-container {
  position: sticky;
  bottom: 0;
  box-shadow: rgb(233, 234, 235) 0px -1px 2px 0px;
}
</style>
