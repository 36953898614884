<template>
  <div>
    <modal v-if="displayBulkSearchHelperPopup">
      <div slot="header">
        <div class="bulk-search-help-modal-img u-spacing-mt-xxl">
          <img
            src="/images/bulk-search.png"
            alt="bulk-search-help-modal-img"
          />
        </div>
      </div>
      <div slot="body">
        <div
          class="u-font-size-3 u-font-weight-600 u-spacing-mb-m u-color-grey-base"
        >
          Bulk Search
        </div>
        <div class="u-font-size-5 u-color-grey-light u-spacing-mb-m">
          <div class="u-spacing-mb-m">
            You can now type / paste multiple {{ entity }} to search.
          </div>
          <div class="u-font-weight-600 u-color-grey-light u-spacing-mb-m">
            Here is how:
          </div>
          <ul class="bulk-search-steps u-spacing-mb-m">
            <li class="points u-spacing-mb-m">
              Copy & paste a list of {{ entity }} from an Excel sheet.
            </li>
            <li class="points u-spacing-mb-m">
              Copy & paste a list of {{ entity }} from any document such as MS
              Word or Notepad. Make sure the list of {{ entity }} are separated
              using a new line.
            </li>
          </ul>
          <div class="u-spacing-mb-l">
            <span class="u-color-red-base u-font-weight-600">Note:</span> If
            there is a difference in number of result it may be due to duplicate
            / Invalid inputs or the separation criteria is not adhered
          </div>
          <rb-checkbox
            v-if="isAutoTriggeredPopup"
            v-model="showBulkSearchHelpModalAgain"
          >
            Don't show me again
          </rb-checkbox>
        </div>
      </div>
      <div slot="footer">
        <div
          v-if="isAutoTriggeredPopup"
          class="u-display-flex u-flex-justify-content-flex-start u-spacing-mb-xxl u-spacing-mt-m"
        >
          <rb-button
            :click-fn="gotItButtonClickHandler"
            :text="'Got it'"
            :type="'filled'"
            :size="'m'"
            class="got-it-btn-outline"
          />
          <rb-button
            :click-fn="exploreButtonClickHandler"
            :text="'Explore'"
            :type="'filled'"
            :size="'m'"
          />
        </div>
        <div
          v-else
          class="u-display-flex u-flex-justify-content-flex-start u-spacing-mb-xxl u-spacing-mt-m"
        >
          <rb-button
            :click-fn="gotItButtonClickHandler"
            :text="'Got it'"
            :type="'filled'"
            :size="'m'"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/widgets/modal.vue';
import { eventBus } from '@/utils/services/eventBus';
export default {
  components: {
    modal
  },
  props: {
    helpModalLocalStorageKey: {
      type: String,
      default: ''
    },
    entity: {
      type: String,
      default: 'ASINs & SKU names'
    }
  },
  data() {
    return {
      displayBulkSearchHelperPopup: false,
      showBulkSearchHelpModalAgain: false,
      isAutoTriggeredPopup: true
    };
  },
  methods: {
    openBulkSearchHelperPopup(isAutoTriggeredPopup = true) {
      this.isAutoTriggeredPopup = isAutoTriggeredPopup;
      this.displayBulkSearchHelperPopup = true;
    },
    closeBulkSearchHelperPopup() {
      this.displayBulkSearchHelperPopup = false;
    },
    gotItButtonClickHandler() {
      this.closeBulkSearchHelperPopup();
      this.handleModalDontShowLocalStorage();
    },
    exploreButtonClickHandler() {
      this.closeBulkSearchHelperPopup();
      eventBus.$emit('openBulkSearchOverview');
      this.handleModalDontShowLocalStorage();
    },
    handleModalDontShowLocalStorage() {
      if (this.isAutoTriggeredPopup && this.showBulkSearchHelpModalAgain) {
        this.setHelpModalLocalStorageKey();
      }
    },
    setHelpModalLocalStorageKey() {
      localStorage.setItem(this.helpModalLocalStorageKey, true);
    }
  }
};
</script>

<style lang="css" scoped>
.bulk-search-help-modal-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}
.bulk-search-help-modal-img img {
  height: 250px;
}
.bulk-search-steps .points {
  list-style-type: disc;
  margin-left: 10px;
}
.bulk-search-steps .points::marker {
  color: #007cf6;
}
.got-it-btn-outline {
  color: #007cf6;
  border: 1px solid #007cf6;
  background-color: #fff;
  margin-right: 8px;
}
.got-it-btn-outline[disabled] {
  opacity: 0.3;
  background-color: #fff;
}
.got-it-btn-outline:hover {
  color: #73b8fb;
  border: 1px solid #007cf6;
  background-color: #fff;
}
</style>
