var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    {
      'u-display-inline-flex u-flex-align-items-center':
        _vm.alignDirection === 'horizontal',
      'u-display-flex u-flex-direction-column u-flex-justify-content-center':
        _vm.alignDirection === 'vertical'
    },
    _vm.containerClass
  ],on:{"click":_vm.onClick}},[_c('div',[_c('loader',{class:_vm.loaderClass,staticStyle:{"z-index":"100"},attrs:{"loading":_vm.isLoading,"color":'#3684bb'}})],1),(_vm.tooltipText && !_vm.isMetricToolTipEnabled)?_c('rb-tooltip-header',{attrs:{"slot":"tooltip-message","id":'tooltipTemplate',"title":_vm.tooltipTitle},slot:"tooltip-message"},[_c('div',{staticClass:"u-spacing-mt-m tooltip-message",attrs:{"slot":"tooltip-message"},domProps:{"innerHTML":_vm._s(_vm.tooltipText)},slot:"tooltip-message"})]):_vm._e(),(_vm.tooltipText && _vm.isMetricToolTipEnabled && !_vm.isLoading)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"icon-text u-font-size-3 card__title card__title u-color-grey-lighter u-font-weight-600 u-display-flex u-flex-direction-column",attrs:{"title":_vm.tooltipText}},[_c('rb-icon',{class:_vm.iconClasses,attrs:{"icon":_vm.icon}}),(_vm.text)?_c('span',{staticClass:"u-spacing-ph-s",class:_vm.textClasses},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1):_vm._e(),(!_vm.isLoading && !_vm.isMetricToolTipEnabled)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tooltipText ? _vm.setTooltip.tippyOptions : ''),expression:"tooltipText ? setTooltip.tippyOptions : ''"}]},[_c('rb-icon',{class:_vm.iconClasses,attrs:{"icon":_vm.icon}}),(_vm.text)?_c('span',{staticClass:"u-spacing-ph-s",class:_vm.textClasses},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }