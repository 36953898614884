<template>
  <div
    class="u-height-100 u-display-flex u-flex-direction-column u-bg-color-grey-white"
  >
    <section
      class="page_header u-spacing-ph-l u-spacing-pt-0 u-spacing-pb-m u-bg-color-grey-white sticky u-z-index-1 u-header-box-shadow u-spacing-mb-0"
    >
      <div class="u-display-flex">
        <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
          Tools & Settings
        </h2>
      </div>
    </section>
    <section class="u-envelop-width u-width-100 u-height-100">
      <div
        v-if="tabs.length > 0"
        class="toolbox-card-container u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-overflow-auto u-flex-1 u-spacing-p-xl u-flex-wrap-yes"
      >
        <span
          v-for="(item, index) in tabs"
          :key="index"
        >
          <internalToolboxCard
            v-if="!item.hide"
            :title="item.title"
            :subtext="item.subtext"
            :asset="item.asset"
            :redirect="item.redirect"
            @click="handleClick"
          />
        </span>
      </div>
      <div
        v-if="tabs.length === 0"
        class="u-height-100 u-font-size-3 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        No tools available
      </div>
    </section>
  </div>
</template>

<script>
import internalToolboxCard from '@/components/pages/ciq-internal-tools/internal-toolbox-card.vue';
import utils from '@/utils/helpers';
import { Entities } from '@/pages/toolsAndSettings/const.js';
const { filters } = require('@ciq-dev/ciq-ui');

export default {
  components: {
    internalToolboxCard
  },
  data() {
    const tabs = [];
    tabs.push(
      {
        title: 'Manage Subscriptions',
        subtext:
          'Manage email subscriptions by enabling recommendations, choosing columns within a recommendation and setting filters for each recommenation type.',
        asset: 'email',
        redirect: 'manageSubscription',
        hide: this.$store.getters.getRetailProgramConfig !== 'amazonretail'
      },
      {
        title: 'Manage Standard Internal Catalog',
        subtext: 'Preview, edit and create Standard Internal Catalog',
        asset: 'sku-box',
        // show internal catalog for only internal users
        hide: !['amazonretail']?.includes(
          this.$store.getters.getRetailProgramConfig
        ),
        redirect: 'tools_and_settings/catalog'
      },
      {
        title: 'Manage Standard Internal Catalog for Seller Central (3P)',
        subtext:
          'Preview, edit and create Standard Internal Catalog for Seller Central (3P)',
        asset: 'sku-box',
        // show internal catalog for only internal users
        hide: !['amazon_3pretail']?.includes(
          this.$store.getters.getRetailProgramConfig
        ),
        redirect: 'tools_and_settings/catalog'
      },
      {
        title: 'Manage Source of Truth for Content',
        subtext: 'Preview and create Source of Truth for Content',
        asset: 'sku-box',
        // show content change for only internal users
        hide:
          this.$store.getters.getRetailProgramConfig !== 'amazonretail' ||
          !utils.internalUserCheck(window.user),
        redirect: 'tools_and_settings/content-change'
      },
      {
        title: 'Manage Source of Truth for Variant',
        subtext: 'Preview and create Source of Truth for Variant',
        asset: 'sku-box',
        // show variant change for only internal users
        hide:
          this.$store.getters.getRetailProgramConfig !== 'amazonretail' ||
          !utils.internalUserCheck(window.user),
        redirect: 'tools_and_settings/variant-change'
      },
      {
        title: 'Manage Digital Shelves',
        subtext: 'Preview, edit and create Standard and Custom Digital Shelves',
        asset: 'digital_shelf',
        hide:
          !filters.config_check('feature.pages.digital_shelves.enable') ||
          !utils.internalUserCheck(window.user) ||
          ['amazon_3pretail']?.includes(
            this.$store.getters.getRetailProgramConfig
          ),
        redirect: 'tools_and_settings/digital-shelves'
      },
      {
        title: 'Manage Campaign Lists',
        subtext: 'Preview, edit and create Campaign Lists',
        asset: 'campaign_management',
        hide: !(
          (filters.config_check('feature.productHeader') || '').includes(
            'MarketingIQ'
          ) &&
          this.$store.getters.getRetailProgramConfig === 'amazonretail' &&
          !filters.config_check('feature.product.ams_lite')
        ),
        redirect: 'tools_and_settings/campaign-lists'
      },
      {
        title: 'Retail Link Connect',
        subtext:
          'Please connect your Retail link account with CommerceIQ to help us get the POS and Inventory data',
        asset: 'sync',
        hide: !(this.$store.getters.getRetailProgramConfig === 'walmartretail'),
        redirect: 'retail_link_connect'
      },
      {
        title: 'Campaign Taxonomy',
        subtext: 'Preview, edit and create Campaign Taxonomy',
        asset: 'campaign_management',
        hide: !filters.config_check(
          `feature.pages.${this.$store.getters.getRetailer}_campaign_taxonomy.enable`
        ),
        redirect: `tools_and_settings/${Entities.campaign}`
      }
    );
    if (
      this.$store.getters.getSessionUser.roles.filter((a) => {
        return a === 'ADMIN';
      }).length > 0
    ) {
      tabs.push({
        title: 'Manage Users',
        subtext: 'Add, remove or update Users.',
        asset: 'bot',
        hide: ['amazon_3pretail']?.includes(
          this.$store.getters.getRetailProgramConfig
        ),
        redirect: '/invite'
      });
    }
    return {
      tabs: tabs
    };
  },
  methods: {
    handleClick(param) {
      this.$router.push(param);
    }
  }
};
</script>

<style lang="css" scoped>
.viewport-container {
  height: 84vh;
}
</style>
