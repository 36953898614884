import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import moment from 'moment';

const state = {
  insightsForecastData: {},
  forecastFilters: [],
  insightsForecastSelectedFilter: {},
  // used by filters
  whereClause: {
    dimensionNameValueList: []
  },
  calendarData: [],
  dropdownOptions: {},
  // defaults for dropdown
  dropdownSelectedOptions: {
    forecast_type: 'Mean',
    forecast_vs: 'Purchase',
    calender_granuality: 'Week',
    cases_or_units: 'Units',
    price_type: 'COGS'
  },
  selectedCalendarValues: {
    forecast: {
      key: null,
      startInterval: null,
      endInterval: null,
      startDate: null,
      endDate: null,
      feedDate: null
    },
    past: {
      key: null,
      startInterval: null,
      endInterval: null,
      startDate: null,
      endDate: null,
      feedDate: null
    }
  },
  page_wise_min_max_key: 'v2_forecast'
};

const getters = {
  getInsightsForecastSelectedFilter: (state) => {
    return state.insightsForecastSelectedFilter;
  },
  getInsightsForecastData: (state) => {
    return state.insightsForecastData;
  },
  getCalendarData: (state) => {
    return state.calendarData;
  },
  getDropdownOptions: (state) => {
    return state.dropdownOptions;
  },
  getDropdownSelectedOptions: (state) => {
    return state.dropdownSelectedOptions;
  },
  getSelectedCalendarValues: (state) => {
    return state.selectedCalendarValues;
  }
};

const mutations = {
  SET_DROPDOWN_OPTIONS: (state, data) => {
    state.dropdownOptions = data;
  },
  SET_DROPDOWN_SELECTED_OPTIONS: (state, data) => {
    Object.keys(data).map(function (key) {
      state.dropdownSelectedOptions[key] = data[key];
    });
  },
  SET_CALENDAR_SELECTED_VALUES: (state, data) => {
    state.selectedCalendarValues = data;
  },
  SET_CALENDAR_DATA: (state, data) => {
    state.calendarData = data;
  },
  INSIGHTS_FORECAST_SUCCESS: (state, data) => {
    Vue.set(state.insightsForecastData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsForecastData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.insightsForecastData[data.key], 'data', data.columns);
    }
    Vue.set(state.insightsForecastData[data.key], 'rows', data.rows);
    Vue.set(state.insightsForecastData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.insightsForecastData[data.key], 'metrics', data.metrics);
    Vue.set(state.insightsForecastData[data.key], 'page', data.page);
    Vue.set(state.insightsForecastData[data.key], 'load', false);
    Vue.set(state.insightsForecastData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsForecastData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsForecastData[data.key], 'noData', true);
    }
  },
  INSIGHTS_FORECAST_RESET: (state, data) => {
    Vue.set(state.insightsForecastData, data.key, {});
    Vue.set(state.insightsForecastData[data.key], 'columns', []);
    Vue.set(state.insightsForecastData[data.key], 'rows', []);
    Vue.set(state.insightsForecastData[data.key], 'load', true);
    Vue.set(state.insightsForecastData[data.key], 'error', false);
    Vue.set(state.insightsForecastData[data.key], 'noData', false);
  },
  INSIGHTS_FORECAST_ERROR: (state, data) => {
    Vue.set(state.insightsForecastData, data.key, {});
    Vue.set(state.insightsForecastData[data.key], 'columns', []);
    Vue.set(state.insightsForecastData[data.key], 'rows', []);
    Vue.set(state.insightsForecastData[data.key], 'load', false);
    Vue.set(state.insightsForecastData[data.key], 'error', true);
    Vue.set(state.insightsForecastData[data.key], 'noData', false);
  },
  INSIGHTS_FORECAST_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsForecastSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat(data, state);
  }
};

function getChartDataInFormat(data, response, replace) {
  var _oObj = {};
  if (response === null) {
    return [];
  }
  for (var i = 0; i < response.length; i++) {
    for (var j in response[i]) {
      if (replace[j] !== undefined) {
        if (!_oObj[j]) {
          _oObj[j] = [j];
        }
        _oObj[j].push(response[i][j]);
      }
    }
  }
  for (i in replace) {
    if (_oObj[i] !== undefined) {
      _oObj[i][0] = replace[i];
    }
  }
  return _oObj;
}

// Formulate chart widget data from the response
function formChartData(response, data) {
  const oReturn = {
    columns: {}
  };
  const xs = {};
  let tagKey;
  if (response && response.data && response.data.length > 0) {
    for (var i = 0; i < response.data.length; i++) {
      if (
        response.data[i] &&
        response.data[i].RESULT &&
        response.data[i].TIMESERIES &&
        response.data[i].TIMESERIES.length > 0
      ) {
        for (var key in response.data[i].RESULT) {
          if (Object.keys(response.data[i].TIMESERIES[0]).indexOf(key) > -1) {
            var oTemp = {};
            var cKey = key;
            tagKey = key;
            var regEx = /^-?\d+\.?\d*$/;
            if (regEx.test(cKey) === true) {
              cKey = key + '_';
            }
            if (cKey) {
              var chartKey = data.body.APIConfig.timeseriesDimension
                ? data.body.APIConfig.timeseriesDimension
                : 'feed_date';
              var chartDataObj = {
                [chartKey]: chartKey + '_' + cKey
              };
              chartDataObj[tagKey] = data.body.map[cKey]
                ? data.body.map[cKey]
                : cKey;
              oTemp = getChartDataInFormat(
                data,
                response.data[i].TIMESERIES,
                chartDataObj
              );
              oReturn.columns[cKey] = oTemp;
              xs[data.body.map[cKey] ? data.body.map[cKey] : cKey] =
                chartKey + '_' + cKey;
            }
          }
        }
      }
    }
  }
  oReturn.xs = xs;
  return oReturn;
}

function getFiltersInFormat(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    }
  }
  state.whereClause = where;
}

function getForecastWhereClauseDate(context, key, type = 'defaultDate') {
  const calendarSettings = context.getters.getSelectedCalendarValues;
  let returnDate;
  if (
    calendarSettings[key] &&
    calendarSettings[key].startDate &&
    calendarSettings[key].endDate
  ) {
    returnDate = {
      from: calendarSettings[key].startDate,
      to: calendarSettings[key].endDate
    };
  }
  if (type === 'defaultDate') {
    returnDate.name = calendarSettings[key].key;
    returnDate.page_wise_min_max_key = state.page_wise_min_max_key;
  } else if (type === 'pvpDate') {
    returnDate.compare_name = calendarSettings[key].key;
  }
  return returnDate;
}

function setItemsInLocalStorage(key, data) {
  let storage = localStorage.getItem('Forecast-v2');
  if (!storage) {
    localStorage.setItem('Forecast-v2', JSON.stringify({ [key]: data }));
  } else {
    storage = JSON.parse(storage);
    storage[key] = data;
    localStorage.setItem('Forecast-v2', JSON.stringify(storage));
  }
}

function getForecastWhereClause(context, keyArr, config) {
  const dropdownSettings = context.getters.getDropdownSelectedOptions;
  const calendarSettings = context.getters.getSelectedCalendarValues;
  var oReturn = {
    dimensionNameValueList: []
  };

  // Dropdown Setting keys to be added in all widgets
  const keysToAdd = ['forecast_type', 'price_type'];
  for (const idx in keysToAdd) {
    oReturn.dimensionNameValueList.push({
      dimensionName: keysToAdd[idx],
      dimensionValue: dropdownSettings[keysToAdd[idx]]
    });
  }

  // add null flag for widget1 & widget2
  const widgetIdArr = ['widget1', 'widget2'];
  if (
    dropdownSettings &&
    dropdownSettings.calender_granuality &&
    config.meta.widgetId &&
    widgetIdArr.indexOf(config.meta.widgetId) > -1
  ) {
    if (dropdownSettings.calender_granuality === 'Week') {
      oReturn.dimensionNameValueList.push({
        dimensionName: 'week_start_date_null_flag',
        dimensionValue: 1,
        operator: 'GREATER_THAN_OR_EQUAL_TO'
      });
    } else if (dropdownSettings.calender_granuality === 'Month') {
      oReturn.dimensionNameValueList.push({
        dimensionName: 'month_start_date_null_flag',
        dimensionValue: 1,
        operator: 'GREATER_THAN_OR_EQUAL_TO'
      });
    }
  }

  // Adding calendar specific keys
  if (keyArr && keyArr.constructor === Array && keyArr.length > 0) {
    var breakLoop = false;
    for (const i in keyArr) {
      if (
        !(calendarSettings[keyArr[i]] && calendarSettings[keyArr[i]].feedDate)
      ) {
        breakLoop = true;
        break;
      } else {
        oReturn.dimensionNameValueList.push({
          dimensionName: 'feed_date',
          dimensionValue: calendarSettings[keyArr[i]].feedDate
        });
      }
    }
    if (breakLoop && config.meta.widgetId !== 'widget4') {
      return null;
    }
  }

  // Other Local Filters that has to be added
  const localFilters = config.meta.localFilters;
  if (
    localFilters &&
    localFilters.constructor === Array &&
    localFilters.length > 0
  ) {
    for (const obj of localFilters) {
      oReturn.dimensionNameValueList.push(obj);
    }
  }

  // set Filters selected
  if (context.state.whereClause.dimensionNameValueList.length > 0) {
    for (const k in context.state.whereClause.dimensionNameValueList) {
      if (
        oReturn.dimensionNameValueList.indexOf(
          context.state.whereClause.dimensionNameValueList[k]
        ) === -1
      ) {
        oReturn.dimensionNameValueList.push(
          context.state.whereClause.dimensionNameValueList[k]
        );
      }
    }
  }

  return oReturn;
}

function sortTimeseriesData(data, context) {
  let key = '';
  const calGran =
    context.getters.getDropdownSelectedOptions.calender_granuality;
  if (calGran === 'Week') {
    key = 'week_start_date';
  } else if (calGran === 'Month') {
    key = 'month_start_date';
  }
  let timeSeriesData = data[0].TIMESERIES;
  if (timeSeriesData && timeSeriesData.length > 0) {
    timeSeriesData = timeSeriesData.sort(function (a, b) {
      const aDate = a[key];
      const bDate = b[key];
      return new Date(aDate) - new Date(bDate);
    });
  }
  return timeSeriesData;
}

function getOnHandInventoryCardValues(
  onHandCardValueApiPayload,
  type,
  modifiedToDate
) {
  // Changes the request json for firing card value API
  onHandCardValueApiPayload.body.APIConfig.cubeName = `forecast_inventory_card_value_${type}`;
  onHandCardValueApiPayload.body.APIConfig.timeseriesDimension = 'feed_date';
  onHandCardValueApiPayload.body.APIConfig.timeseriesEnabled = false;
  const onHandInventoryKey = [`${type}_at_amazon_daily`];
  onHandCardValueApiPayload.body.APIConfig.measuresList = onHandInventoryKey;

  if (modifiedToDate)
    onHandCardValueApiPayload.body.APIConfig.where.date.to = modifiedToDate;
  // For card api, from & to date will be same.
  onHandCardValueApiPayload.body.APIConfig.where.date.from =
    onHandCardValueApiPayload.body.APIConfig.where.date.to;

  return new Promise((resolve) => {
    HttpLayer.post({
      APIData: onHandCardValueApiPayload.body.APIConfig
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data[0]?.RESULT);
        } else {
          resolve();
        }
      })
      .catch((error) => {
        console.log(error);
        // Skipping as chart needs to render even if OHI api fails.
        resolve();
      });
  });
}

function getOnHandInventoryTimeSeriesValues(
  onHandTimeSeriesApiPayload,
  calendarGranularity,
  type
) {
  // Changes the request json for firing timeseries value API
  onHandTimeSeriesApiPayload.body.APIConfig.cubeName = `forecast_inventory_timeseries_${calendarGranularity}_${type}`;
  const onHandInventoryKey = [`${type}_at_amazon_${calendarGranularity}`];
  onHandTimeSeriesApiPayload.body.APIConfig.measuresList = onHandInventoryKey;

  return new Promise((resolve) => {
    HttpLayer.post({
      APIData: onHandTimeSeriesApiPayload.body.APIConfig
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data[0]?.TIMESERIES);
        } else {
          resolve();
        }
      })
      .catch((error) => {
        console.log(error);
        // Skipping as chart needs to render even if OHI api fails.
        resolve();
      });
  });
}

function modifyPayloadForOnHandInventoryApi(data) {
  const onHandInventorModifiedApiPaylod = JSON.parse(JSON.stringify(data));
  onHandInventorModifiedApiPaylod.body.APIConfig.dedupBeforeRollup.additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions =
    ['asin', 'feed_date'];
  // only price_type and filter params needed for OHI Api.
  const dimensionNameToExclude = [
    'forecast_type',
    'week_start_date_null_flag',
    'feed_date',
    'month_start_date_null_flag'
  ];
  onHandInventorModifiedApiPaylod.body.APIConfig.where.dimensionNameValueList =
    onHandInventorModifiedApiPaylod.body.APIConfig.where.dimensionNameValueList.filter(
      (list) => dimensionNameToExclude.indexOf(list.dimensionName) === -1
    );
  return onHandInventorModifiedApiPaylod;
}

function getCalendarGranulity(data) {
  return `${data.body.APIConfig.timeseriesDimension.split('_')[0]}`;
}

function getMergedTimeSeries(
  currentTimeSeries,
  unitsTimeSeries,
  casesTimeSeries,
  calendarGranularity
) {
  let mergedTimeSeries = [];
  for (var i = 0; i < currentTimeSeries.length; i++) {
    // Find matching jsons with respect to week_start_date/month_start_date
    const matchingUnitsItem = findMatchingItem(
      currentTimeSeries,
      unitsTimeSeries,
      calendarGranularity,
      i,
      'units'
    );
    const matchingCasesItem = findMatchingItem(
      currentTimeSeries,
      casesTimeSeries,
      calendarGranularity,
      i,
      'cases'
    );
    mergedTimeSeries = [
      ...mergedTimeSeries,
      { ...currentTimeSeries[i], ...matchingUnitsItem, ...matchingCasesItem }
    ];
  }
  return mergedTimeSeries;
}

function findMatchingItem(
  defaultTimeSeries,
  ohiTimeSeries,
  calendarGranularity,
  index,
  type
) {
  let matchingItem;
  // Null check for timeseries in case cases/units timeseries resolves undefined/null.
  if (ohiTimeSeries) {
    matchingItem = ohiTimeSeries.find(
      (item) =>
        item[`${calendarGranularity}_start_date`] ===
        defaultTimeSeries[index][`${calendarGranularity}_start_date`]
    );
  }
  // Fallback json to plot null for OHI for missing week_start_date/month_start_date.
  if (!matchingItem || !matchingItem[`${calendarGranularity}_start_date`]) {
    const fallbackObj = {};
    (fallbackObj[`${calendarGranularity}_start_date`] =
      defaultTimeSeries[index][`${calendarGranularity}_start_date`]),
      (fallbackObj[`${type}s_at_amazon_weekly`] = null);
    return fallbackObj;
  }
  return matchingItem;
}

const actions = {
  setInsightsForecastSelectedFilter: (context, data) => {
    context.commit('INSIGHTS_FORECAST_SET_SELECTED_FILTER', data);
  },

  updateForecastSelectedFilter: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.forecastFilters || [],
        'forecast_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },

  setDropdownSelectedOptions: (context, data) => {
    context.commit('SET_DROPDOWN_SELECTED_OPTIONS', data);
  },

  setCalendarSelectedValues: (context, data) => {
    context.commit('SET_CALENDAR_SELECTED_VALUES', data);
  },

  setItemsInLocalStorage: (context, data) => {
    const key = data[0];
    data = data[1];
    setItemsInLocalStorage(key, data);
  },

  fetchDropdownOptions: (context) => {
    var APIConfig = {
      cubeName: 'v2_forecast_calender_options',
      measuresList: [],
      groupByDimensionsList: [
        'calender_granuality',
        'forecast_or_past',
        'forecast_type',
        'price_type'
      ]
    };
    HttpLayer.post({
      APIData: APIConfig
    }).then((response) => {
      if (!response.success) {
        console.error('Error: Unable to fetch forecast dropdown options');
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data);
        context.commit('SET_DROPDOWN_OPTIONS', _aArray);
      }
    });
  },

  fetchCalendarData: (context) => {
    var APIConfig = {
      cubeName: 'v2_forecast_calender_options',
      measuresList: [],
      groupByDimensionsList: [
        'forecast_or_past',
        'calender_granuality',
        'period',
        'period_start_date',
        'period_end_date',
        'period_feed_date',
        'interval_label',
        'interval_number',
        'interval_start_date',
        'interval_end_date',
        'sort_order'
      ],
      orderByList: [
        {
          dimension: 'sort_order',
          direction: 'ASC'
        }
      ],
      timeseriesDimension: 'feed_date',
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'interval_number',
            dimensionValue: '0',
            operator: 'GREATER_THAN_OR_EQUAL_TO'
          }
        ]
      }
    };

    const finalWhereClause = APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    APIConfig.where = transformedWhereClause;
    APIConfig.tagWhereClause = transformedWhereClause.tagWhereClause || [];
    delete APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: APIConfig
    }).then((response) => {
      if (!response.success) {
        console.error('Error: Unable to fetch forecast calendar data');
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data);
        context.commit('SET_CALENDAR_DATA', _aArray);
      }
    });
  },

  downloadForecastedUnits: (context, data) => {
    data.body.APIConfig.where = getForecastWhereClause(
      context,
      ['forecast'],
      data
    );
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'forecast'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (response.data && response.data.length && response.data.length > 0) {
        return sortTimeseriesData(response.data, context);
      }
    });
  },

  getForecastedUnits: (context, data) => {
    context.commit('INSIGHTS_FORECAST_RESET', {
      key: data.meta.key
    });

    data.body.APIConfig.where = getForecastWhereClause(
      context,
      ['forecast'],
      data
    );
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'forecast'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          var oReturn = {};
          if (response.data.length > 0) {
            oReturn = formChartData(response, data);

            context.commit('INSIGHTS_FORECAST_SUCCESS', {
              key: data.meta.key,
              columns: oReturn.columns,
              metrics: data.body.getTagData
                ? data.body.getTagData(response.data, data.body.map)
                : undefined,
              page: data.body.APIConfig.page,
              xs: oReturn.xs
            });
          } else {
            context.commit('INSIGHTS_FORECAST_SUCCESS', {
              key: data.meta.key,
              columns: [],
              page: data.body.APIConfig.page,
              metrics: undefined
            });
          }
        }
      })
      .catch((error) => {
        context.commit('INSIGHTS_FORECAST_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  downloadPastPerformance: (context, data) => {
    data.body.APIConfig.where = getForecastWhereClause(context, ['past'], data);
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'past'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    // Modify the request payload for on hand inventory time series API.
    const onHandInventorModifiedApiPaylod =
      modifyPayloadForOnHandInventoryApi(data);
    const calendarGranularity = getCalendarGranulity(data);

    let downloadApiPromiseArray = [];
    downloadApiPromiseArray = [
      HttpLayer.post({ APIData: data.body.APIConfig }),
      getOnHandInventoryTimeSeriesValues(
        onHandInventorModifiedApiPaylod,
        `${calendarGranularity}ly`,
        'units'
      ),
      getOnHandInventoryTimeSeriesValues(
        onHandInventorModifiedApiPaylod,
        `${calendarGranularity}ly`,
        'cases'
      )
    ];

    return Promise.all(downloadApiPromiseArray).then((result) => {
      const [response, unitsTimeSeries, casesTimeSeries] = result;
      const currentTimeSeries = response.data[0].TIMESERIES;
      if (currentTimeSeries?.length && calendarGranularity) {
        const mergedTimeSeries = getMergedTimeSeries(
          currentTimeSeries,
          unitsTimeSeries,
          casesTimeSeries,
          calendarGranularity
        );
        if (mergedTimeSeries.length)
          response.data[0].TIMESERIES = mergedTimeSeries;
      }
      return sortTimeseriesData(response.data, context);
    });
  },

  getPastPerformance: (context, data) => {
    context.commit('INSIGHTS_FORECAST_RESET', {
      key: data.meta.key
    });
    var whereClause = getForecastWhereClause(context, ['past'], data);
    if (whereClause === null) {
      context.commit('INSIGHTS_FORECAST_SUCCESS', {
        key: data.meta.key,
        columns: [],
        page: data.body.APIConfig.page,
        metrics: undefined
      });
      return;
    }
    data.body.APIConfig.where = whereClause;
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'past'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    const calendarGranularity = getCalendarGranulity(data);
    let modifiedToDate;
    let pastPerformancePromiseArray = [];

    // Modify the request payload for on hand inventory card value  and time series API.
    const onHandInventorCardValueModifiedApiPaylod =
      modifyPayloadForOnHandInventoryApi(data);
    const onHandInventorTimeSeriesModifiedApiPaylod =
      modifyPayloadForOnHandInventoryApi(data);

    // Case : Roll up: monthly
    // Replace To date in where clause for on hand inventory card value API IF maxFeedDate < To Date:
    const maxFeedDate =
      context.getters.getMaxDate.forecast_inventory_card_value?.max_feed_date;
    const toDate = data.body.APIConfig.where.date.to;
    if (
      maxFeedDate &&
      moment(toDate, 'YYYY-MM-DD').isAfter(moment(maxFeedDate, 'MM/DD/YYYY'))
    ) {
      modifiedToDate = moment(maxFeedDate).format('YYYY-MM-DD');
    }
    pastPerformancePromiseArray = [
      HttpLayer.post({ APIData: data.body.APIConfig }),
      getOnHandInventoryCardValues(
        onHandInventorCardValueModifiedApiPaylod,
        'units',
        modifiedToDate
      ),
      getOnHandInventoryCardValues(
        onHandInventorCardValueModifiedApiPaylod,
        'cases',
        modifiedToDate
      ),
      getOnHandInventoryTimeSeriesValues(
        onHandInventorTimeSeriesModifiedApiPaylod,
        `${calendarGranularity}ly`,
        'units'
      ),
      getOnHandInventoryTimeSeriesValues(
        onHandInventorTimeSeriesModifiedApiPaylod,
        `${calendarGranularity}ly`,
        'cases'
      )
    ];

    // Proceeds to designing the desired JSON once all 4 promises are resolved.
    // Details of API's - 1. API for all other metrics. 2/3. OnHandInventory Card data metric 4/5. OnHandInventory Time Series metric.
    // Only 1st API essential for plotting. Resolves even if All 4 or Any 1, other than the 1st is rejected.
    Promise.all(pastPerformancePromiseArray)
      .then((result) => {
        const [
          mainResponse,
          unitsCardValue,
          casesCardValue,
          unitsTimeSeries,
          casesTimeSeries
        ] = result;

        // Append On hand inventory timeseries to the Main Response timeseries if the week_start_date/month_start_date of responses matches.
        // else proceed without On hand inventory metric to plot other metric.
        const currentTimeSeries = mainResponse?.data[0]?.TIMESERIES;
        if (currentTimeSeries?.length && calendarGranularity) {
          const mergedTimeSeries = getMergedTimeSeries(
            currentTimeSeries,
            unitsTimeSeries,
            casesTimeSeries,
            calendarGranularity
          );
          if (mergedTimeSeries.length) {
            mainResponse.data[0].TIMESERIES = mergedTimeSeries;
            // Appending to Result with the same keyword as in timeseries for avoiding errors as
            // Core logic works with same keyname for RESULT and TIMESERIES so appening with same 'keyname' to RESULT for card data
            // Will make this dynamic once cases is implemented
          }
        }

        // Will be showing the card with value NA if card API resolves as undefined/null value.
        if (mainResponse?.data[0]?.RESULT) {
          mainResponse.data[0].RESULT[
            `units_at_amazon_${calendarGranularity}ly`
          ] = unitsCardValue?.units_at_amazon_daily;
          mainResponse.data[0].RESULT[
            `cases_at_amazon_${calendarGranularity}ly`
          ] = casesCardValue?.cases_at_amazon_daily;
        }

        if (!mainResponse.success) {
          console.error('Unable to fetch past performance data');
        } else {
          var oReturn = {};
          if (mainResponse.data.length > 0) {
            oReturn = formChartData(mainResponse, data);
            context.commit('INSIGHTS_FORECAST_SUCCESS', {
              key: data.meta.key,
              columns: oReturn.columns,
              metrics: data.body.getTagData
                ? data.body.getTagData(mainResponse.data, data.body.map)
                : undefined,
              page: data.body.APIConfig.page,
              xs: oReturn.xs
            });
          } else {
            context.commit('INSIGHTS_FORECAST_SUCCESS', {
              key: data.meta.key,
              columns: [],
              page: data.body.APIConfig.page,
              metrics: undefined
            });
          }
        }
      })
      .catch((error) => {
        context.commit('INSIGHTS_FORECAST_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  downloadAccuracyMetrics: (context, data) => {
    data.body.APIConfig.where = getForecastWhereClause(context, ['past'], data);
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'past'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data);
    });
  },

  getAccuracyMetricsData: (context, data) => {
    context.commit('INSIGHTS_FORECAST_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = getForecastWhereClause(context, ['past'], data);
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'past'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        console.error('Error: Unable to fetch metrics.');
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data);
        if (_aArray.length > 0) {
          context.commit('INSIGHTS_FORECAST_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            metrics: _aArray[0],
            totalRows: response.dataSize,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTS_FORECAST_SUCCESS', {
            key: data.meta.key,
            rows: [],
            metrics: {},
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },

  downloadSKULevelBreakDownTableData: (context, data) => {
    data.body.APIConfig.where = getForecastWhereClause(
      context,
      ['forecast', 'past'],
      data
    );
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'forecast'
    );
    data.body.APIConfig.where.pvpDate = getForecastWhereClauseDate(
      context,
      'past',
      'pvpDate'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },

  getSKULevelBreakDownTableData: (context, data) => {
    context.commit('INSIGHTS_FORECAST_RESET', {
      key: data.meta.key
    });
    var whereClause = getForecastWhereClause(
      context,
      ['forecast', 'past'],
      data
    );
    if (whereClause === null) {
      context.commit('INSIGHTS_FORECAST_SUCCESS', {
        key: data.meta.key,
        columns: [],
        page: data.body.APIConfig.page,
        metrics: undefined
      });
      return;
    }

    data.body.APIConfig.where = whereClause;
    const finalWhereClause = data.body.APIConfig.where;
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getForecastWhereClauseDate(
      context,
      'forecast'
    );
    data.body.APIConfig.where.pvpDate = getForecastWhereClauseDate(
      context,
      'past',
      'pvpDate'
    );
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTS_FORECAST_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('INSIGHTS_FORECAST_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTS_FORECAST_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
