<template>
  <div
    v-if="cellValue"
    v-tippy="{
      placement: tooltipPlacement,
      distance: tooltipDistance,
      maxWidth: tooltipMaxWidth
    }"
    :title="tooltipText"
  >
    <div
      class="u-border-radius-xs u-color-grey-white u-spacing-p-xs u-font-size-7"
      :class="bgColor"
    >
      {{ cellValue }}
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'statusCell',
  computed: {
    cellValue() {
      return this.params.value;
    },
    tooltipPlacement() {
      if (this.params.tooltipPlacement) {
        return this.params.tooltipPlacement;
      }
      return 'bottom';
    },
    tooltipDistance() {
      if (this.params.tooltipDistance) {
        return this.params.tooltipDistance;
      }
      return -15;
    },
    tooltipMaxWidth() {
      if (this.params.tooltipMaxWidth) {
        return this.params.tooltipMaxWidth;
      }
      return '500px';
    },
    tooltipText() {
      if (this.params.tooltipText) {
        return this.params.tooltipText;
      }
      // return 'Actions with this icon are supported by the CommerceIQ platform.'
      return '';
    },
    bgColor() {
      if (this.params.bgColor) {
        return this.params.bgColor;
      } else {
        if (this.params.value.toLowerCase() === 'success') {
          return 'u-bg-color-green-base';
        } else {
          return 'u-bg-color-red-base';
        }
      }
    }
  },
  methods: {
    iconClickEvent(event) {
      if (this.params.iconClickEvent) {
        this.params.iconClickEvent(this, event);
      }
    }
  }
};
</script>
