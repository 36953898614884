<template>
  <div>
    <span
      v-if="!isDescription"
      class="u-font-size-3 card__title u-color-grey-lighter u-font-weight-600 u-spacing-mt-s"
      data-cy="reportTitle"
      >{{ text }}
    </span>
    <div
      v-if="isDescription"
      class="u-color-grey-base u-font-size-7 u-display-flex u-spacing-mr-s u-spacing-mt-s"
    >
      <rb-icon
        class="u-color-grey-lighter rb-icon--small u-display-block u-spacing-mr-s"
        icon="info-circle-fill"
      />
      <span
        class="description u-color-grey-mid-light u-font-size-7"
        :data-cy="`reportDescription-${description}`"
      >
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    isDescription: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
