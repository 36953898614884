<template>
  <div class="u-display-flex">
    <div class="u-flex-0">
      <rb-icon
        class="rb-icon--small"
        :class="{
          'u-color-blue-base': isOptionSelected() === 'selected',
          'u-color-grey-lighter': isOptionSelected() === 'empty'
        }"
        :icon="'radio-' + isOptionSelected()"
      />
    </div>
    <div class="u-flex-1 u-spacing-pl-xs u-line-height-1">
      <div class="u-font-size-7 u-font-weight-600 u-color-grey-lighter">
        Strategy name:
      </div>
      <div class="u-font-size-5 u-spacing-pt-xs">
        {{ filterObj.strategyName }}
      </div>
      <div
        class="u-font-size-7 u-spacing-pt-s u-font-weight-600 u-color-grey-lighter"
      >
        Strategy conditions:
      </div>
      <div class="u-spacing-pt-xs u-display-flex u-flex-wrap-yes">
        <div
          v-for="(rule, index) in filterObj.rules"
          :key="index"
          class="u-flex-0 u-display-flex u-flex-shrink-past-min-size u-spacing-mb-xs u-spacing-pr-s"
        >
          <span
            :title="ruleAsText(rule)"
            class="filter--token filter--token__small u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
          >
            <span class="u-line-height-1 u-text-overflow-ellipsis">{{
              ruleAsText(rule)
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { operator } from '@/utils/helpers/operator.js';

export default {
  name: 'StrategyFilter',
  props: {
    filterData: {
      type: Object,
      default: () => {}
    },
    primaryData: {
      type: Array,
      default: () => []
    },
    tempSelections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filterObj() {
      return JSON.parse(this.filterData.title) || {};
    }
  },
  methods: {
    isOptionSelected() {
      const isOptionSelected = this.tempSelections.filter(
        (strategyJsonString) => {
          return (
            JSON.parse(strategyJsonString || {}).strategyUniqueId ===
            this.filterObj.strategyUniqueId
          );
        }
      ).length;
      // console.log(this.tempSelections, this.filterObj)
      return isOptionSelected ? 'selected' : 'empty';
    },
    ruleAsText(rule) {
      const dimensionNameMapping = this.primaryData.filter((item) => {
        return item.dimensionName === rule.dimensionName;
      })[0];
      if (dimensionNameMapping) {
        if (dimensionNameMapping.dimensionType === 'STRATEGY') {
          return `${dimensionNameMapping.dimensionLabel} : ${
            JSON.parse(rule.dimensionValue).strategyName
          }`;
        } else {
          return `${dimensionNameMapping.dimensionLabel} ${
            operator[rule.operator]
          } ${rule.dimensionValue}`;
        }
      }
    }
  }
};
</script>
<style lang="css" scoped>
.filter--token__small {
  padding: 4px 8px;
}
</style>
