<template>
  <section>
    <div class="u-width-100 u-display-flex u-flex-justify-content-center">
      <img
        :src="imageSource"
        :width="imageWidth"
        :height="imageHeight"
      />
    </div>
    <slot name="text" />
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <div
        v-for="(button, index) in buttons"
        :key="index"
        :class="['u-spacing-mr-' + buttonSpacing]"
      >
        <rb-button
          :click-fn="() => $emit(button.emit)"
          :type="button.type"
          :text="button.text"
          :size="button.size"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    imageHeight: {
      type: String,
      default: '200px'
    },
    imageWidth: {
      type: String,
      default: '200px'
    },
    imageSource: {
      type: String,
      default: null
    },
    buttons: {
      type: Array,
      default: () => []
    },
    buttonSpacing: {
      type: String,
      default: 's'
    }
  }
};
</script>

<style></style>
