<template>
  <div
    class="u-display-flex u-flex-direction-row u-color-grey-base u-flex-align-items-center"
    :class="addCategorySpacing(categoryData.entityType)"
  >
    <span
      v-if="categoryData.hasChild"
      class="u-spacing-mr-s"
      @click="handleExpandClick"
    >
      <rb-icon
        v-if="categoryData.expanded"
        class="rb-icon--medium"
        icon="arrow1-up"
      />
      <rb-icon
        v-else
        class="rb-icon--medium"
        icon="arrow-right"
      />
    </span>
    <div v-tippy="tippyOptions">
      <div
        :id="compId"
        v-tippy-html
        class="u-display-none"
      >
        <div class="max-w-md w-full u-text-case-title">
          {{ categoryData[categoryData.entityType] }}
        </div>
        <div class="u-spacing-mt-s u-color-grey-xx-light">
          Click for "Category View"
        </div>
      </div>
      <span
        :class="addCategorySpacing(categoryData.entityType)"
        class="u-color-grey-base u-font-weight-400 u-line-height-1.3 u-font-size-5 u-border-bottom-dotted"
        @click="navigateToCategoryPage(categoryData)"
      >
        {{ categoryData[categoryData.entityType] }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  setGlobalSettingsForMS,
  constants,
  categoryConstants,
  setCategoryFilterInCategoryPage
} from '@/components/pages/insights/amazon/market-share/utils';

export default {
  name: 'ParentChild',
  props: {
    params: {
      type: Object,
      default: null
    },
    expandButtonClickFunction: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      spacingList: [
        '',
        'u-spacing-ml-m',
        'u-spacing-ml-l',
        'u-spacing-ml-xxl',
        'u-spacing-ml-xxxl'
      ],
      id: null
    };
  },
  computed: {
    compId() {
      return 'tooltip-' + this.id;
    },
    categoryData() {
      return { ...this.params.data };
    },
    tippyOptions() {
      return {
        html: '#tooltip-' + this.id,
        placement: 'top-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      };
    }
  },
  created() {
    this.id = this._uid;
  },
  methods: {
    navigateToCategoryPage(categoryData) {
      if (categoryData.entityType === 'category') {
        setCategoryFilterInCategoryPage(
          categoryData.filterToApply || categoryData[categoryData.entityType]
        );
      } else {
        const l0Category = categoryData.filterToApply.split('----')[0];
        const subCategorySelection = { [categoryData.filterToApply]: true };
        setGlobalSettingsForMS(constants.CATEGORY, l0Category);
        setGlobalSettingsForMS(constants.SUBCATEGORY, subCategorySelection);
      }
      this.$nextTick(() => {
        this.$router.push({ name: categoryConstants.pageName });
      });
    },
    addCategorySpacing(str) {
      let number = '';
      for (let i = 0; i < str.length; i++) {
        const char = str.charAt(i);
        if (!isNaN(char)) {
          number += char;
        } else if (number !== '') {
          // Break loop if a non-digit character is encountered after the number
          break;
        }
      }
      if (number !== '') {
        number = parseInt(number); // Convert the extracted number to an integer
      } else {
        number = 0;
      }
      return this.spacingList[number];
    },
    handleExpandClick() {
      const expandCategory = !this.categoryData.expanded;
      this.expandButtonClickFunction(expandCategory);
    }
  }
};
</script>
