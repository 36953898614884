import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';
const startCase = require('lodash/startCase');
const toLower = require('lodash/toLower');

const getTitle = (key) => {
  const words = key.split('_');
  const formattedWords = words
    .map((word, index) =>
      /^\d+(\.\d+)?$/.test(word) ? word : startCase(toLower(word))
    )
    .filter((word, index) => index !== 0);
  return formattedWords.join(' ');
};

const getKeyName = (input) => {
  const words = input.split('_');
  const formattedWords = [];

  for (const word of words) {
    if (/^\d+(\.\d+)?$/.test(word)) {
      const [integerPart, decimalPart] = word.split('.');
      if (decimalPart) {
        formattedWords.push(`${integerPart}${decimalPart}`);
      } else {
        formattedWords.push(integerPart);
      }
    } else {
      formattedWords.push(word);
    }
  }
  return formattedWords.join('_');
};

const generateColumns = (keys) => {
  const clientNameColumn = {
    isFixed: true,
    keyOrder: 1,
    name: 'client_name',
    sortable: false,
    title: 'Client Name',
    type: 'STRING',
    tooltip: '',
    unit: '',
    visible: true
  };

  let dynamicColumns = [];
  if (keys.length > 0) {
    dynamicColumns = keys.map((key, index) => ({
      suppressSizeToFit: true,
      keyOrder: index + 2,
      name: getKeyName(key),
      sortable: false,
      tooltip:
        Vue.options.filters.config_check(
          `feature.slaTrackerDashboard.${getKeyName(key)}.tooltip`
        ) || '',
      title: getTitle(key),
      type: 'NUMERIC',
      unit: '',
      visible: true
    }));
  }
  return [clientNameColumn, ...dynamicColumns];
};

export const getColumnDefs = (coulmnHealthFroupKeys) => {
  const selectedColumns = generateColumns(coulmnHealthFroupKeys);
  let columnDefs = [];
  selectedColumns.forEach((column) => {
    const columnDefinition = {
      name: column.name,
      uiField: {
        uiLabel: column.title,
        uiTooltip: column.toolTipText,
        uiType: 'custom',
        uiOrder: column.keyOrder,
        metadata: {
          tableColumnName: column.name,
          showOnUi: column?.visible,
          sortOnColumn: column?.sortable,
          isFixed: column.isFixed ?? false,
          headerComponent: {
            bDynamicHeaderComponent: true,
            componentName: 'dataHeader',
            props: {},
            columnDefsToProps: (params) => {
              return {
                displayName: params.displayName,
                params: params,
                toolTipText: column.tooltip,
                enableSorting: column.sortable,
                alignHeader: 'center',
                textColor: 'u-color-black-base'
              };
            }
          }
        }
      }
    };
    const columnMetaObject = {
      widget: 'dynamicCellComponent',
      dynamicCellComponentParams: {
        componentName: 'SlaTrackerCell',
        paramsToProps: (params) => {
          return {
            params,
            value: params.data[column.name],
            columnName: column.name,
            rowData: params.data
          };
        }
      },
      width: column.type?.toLowerCase() === 'string' ? 80 : 40
    };
    columnDefinition.uiField.metadata = {
      ...columnDefinition.uiField.metadata,
      ...columnMetaObject
    };
    columnDefs.push(columnDefinition);
  });

  columnDefs = transformer.getColumnDefinition(columnDefs);
  return columnDefs.displayColConfigs;
};

const generateKeyMapping = (keys) => {
  const keyToPropertyMap = {};
  for (const key of keys) {
    const formatted_key = getKeyName(key);
    keyToPropertyMap[key] = {
      dataProperty: formatted_key,
      dataPropertyPercent: `${formatted_key}_percent`,
      slaBreachDate: `${formatted_key}_sla_breach_dates`
    };
  }
  return keyToPropertyMap;
};

export const transformRowData = (responseData, healthGroupKeys) => {
  const transformedData = [];
  const keyMapping = generateKeyMapping(healthGroupKeys);
  responseData.clients_data.forEach((clientData) => {
    const clientRow = {
      client_name: clientData.client_name
    };
    for (const key in keyMapping) {
      const dataProperty = keyMapping[key].dataProperty;
      const dataPropertyPercent = keyMapping[key].dataPropertyPercent;
      const slaBreachDate = keyMapping[key].slaBreachDate;
      // Initializing dataProperty and dataPropertyPercent
      clientRow[dataProperty] = 0;
      clientRow[dataPropertyPercent] = 0;
      const slaBreachDates = [];

      // Iterating through daily_health_status
      clientData.daily_health_status.forEach((healthStatus) => {
        if (healthStatus.health_group_key === key) {
          const statusDetails = healthStatus.statuses;
          // Counting failed statuses and collecting sla breach dates
          let failedStatusCount = 0;
          statusDetails.forEach((item) => {
            if (item.status === 'FAILED') {
              failedStatusCount++;
              slaBreachDates.push(item.date);
            }
          });
          // Updating the clientRow with data
          clientRow[dataProperty] = failedStatusCount;
          clientRow[dataPropertyPercent] =
            statusDetails.length === 0
              ? 0
              : (failedStatusCount / statusDetails.length) * 100;
          clientRow[slaBreachDate] = slaBreachDates;
        }
      });
    }
    transformedData.push(clientRow);
  });
  return transformedData;
};
