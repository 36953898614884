import HomeV2 from '@/components/homeV2.vue';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import {
  digitalShelfRoute,
  campaignOptimizerChildrenRoutes,
  getCampaignManagementChildrenRoutesWithTabConfig,
  proxyLinksRoute
} from '@/router/commonRoutePaths.js';

// Al of these have to be moved to commonRoutePaths.js later and be refactored across all retailers
export const toolsAndSettingsRoute = {
  path: 'tools_and_settings',
  name: 'ToolsAndSettings',
  component: ToolsAndSettings,
  children: toolsAndSettingsRoutes.routes
};
export const inviteRoute = {
  path: 'invite',
  name: 'invite',
  component: Invite
};
export const dvtInternalToolsRoute = {
  path: 'dvtInternalTool',
  name: 'dvtInternalTool',
  component: DvtInternalTool
};
export const mailValidatorRoute = {
  path: 'mailValidator',
  name: 'mailValidator',
  component: MailValidator
};
export const productDiagnosticsRoute = {
  path: 'product_diagnostics',
  name: 'productDiagnostics',
  component: productDiagnostics,
  children: productDiagnosticsRoutes.routes
};
export const passwordManagerRoute = {
  path: 'account_manager',
  name: 'passwordManager',
  component: passwordManager
};
export const internalToolBoxRoute = {
  path: 'internalToolBox',
  name: 'internalToolBox',
  component: internalToolBox
};
export const fileDownloadRoute = {
  path: 'file-download',
  component: fileDownload
};
export const ciqEfundamentalsFAQRoute = {
  path: 'ciq-efundamentals-announcement',
  name: 'ciq-efundamentals-announcement',
  component: CiqEfundamentalsFAQ
};
export const reportedIssueRoute = {
  path: 'reportedIssues',
  name: 'reportedIssues',
  component: reportedIssues
};
export const productSLARoute = {
  path: 'product_sla',
  name: 'productSLA',
  component: productSLA
};

export const commonInternalRoutesArray = [
  toolsAndSettingsRoute,
  inviteRoute,
  dvtInternalToolsRoute,
  mailValidatorRoute,
  productDiagnosticsRoute,
  passwordManagerRoute,
  internalToolBoxRoute,
  fileDownloadRoute,
  ciqEfundamentalsFAQRoute,
  proxyLinksRoute,
  reportedIssueRoute,
  productSLARoute
];

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const albertsonsRoutes = {
  path: '/us/albertsons_citrus/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect: 'digital_shelf/market_insights'
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    digitalShelfRoute,
    {
      path: 'advertising',
      name: 'advertising',
      redirect() {
        return 'advertising/campaign_optimizer';
      },
      component: AdvertisingLanding,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children:
            getCampaignManagementChildrenRoutesWithTabConfig('albertsons')
        },
        {
          path: '',
          redirect: () => {
            return 'campaign_management';
          }
        }
      ]
    },
    ...commonInternalRoutesArray
  ]
};

export { albertsonsRoutes };
