<template>
  <div
    :style="{
      width: width() + '%',
      background: findBackgroundColor(),
      color: findTextColor()
    }"
    class="u-display-flex setProgressStyle u-flex-align-items-center"
  >
    <div
      style="position: absolute; left: 16px; color: #2b333b"
      class="u-display-flex u-flex-direction-column"
    >
      <div
        v-tippy="tippyConfig"
        class="u-font-size-5 u-spacing-mb-xs"
        :title="getTooltip(formatter(progressData.keyOne))"
      >
        {{ formatter(progressData.keyOne) }}
      </div>
      <div
        v-tippy="tippyConfig"
        class="u-font-size-7"
        style="opacity: 0.7"
        :title="getTooltip(formatter(progressData.keyTwo))"
      >
        <span v-if="!progressData.hideComparator"> / </span>
        {{ formatter(progressData.keyTwo) }}
      </div>
    </div>
    <div
      class="u-display-flex u-position-absolute u-min-width-80px metricPostion u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <!-- use case of this slot: wrappedInParent = true -->
      <div>
        <slot name="customComponent" />
      </div>
      <span
        class="u-font-size-7"
        :class="{
          'u-fill-color-grey-light': progressData.keyOne === progressData.keyTwo
        }"
      >
        <rb-icon
          v-if="progressData.keyOne !== progressData.keyTwo"
          :icon="getProgressBarIcon()"
          class="rb-icon rb-icon--x-small"
          :class="{ 'u-display-none': progressData.pvp === 'NA' }"
        />
        <span
          v-if="!!progressData.pvp || progressData.pvp === 0"
          v-tippy="tippyConfig"
          :class="{ 'u-fill-color-grey-light': progressData.pvp === 'NA' }"
          :title="getTooltip(formatPVP(progressData.pvp))"
        >
          {{ formatPVP(progressData.pvp) }}
        </span>
        <span
          v-else
          v-tippy="tippyConfig"
          :title="
            getTooltip(
              formatter(getPvp(progressData.keyOne, progressData.keyTwo))
            )
          "
        >
          {{
            formatter(getPvp(progressData.keyOne, progressData.keyTwo))
          }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'progressTableComponent',
  props: {
    wrappedInParent: {
      default: false,
      type: Boolean
    },
    pvp: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      currentContextParams: {},
      baseVal: 0,
      maxVal: 100,
      tippyConfig: { placement: 'top', arrow: false }
    };
  },
  computed: {
    progressData() {
      return {
        keyOne: this.currentContextParams.handleNull
          ? this.currentContextParams.data[
              this.currentContextParams.keys.keyOne
            ] === null
            ? 'NA'
            : this.currentContextParams.data[
                this.currentContextParams.keys.keyOne
              ]
          : this.currentContextParams.data[
              this.currentContextParams.keys.keyOne
            ] === null
          ? 0
          : this.currentContextParams.data[
              this.currentContextParams.keys.keyOne
            ],
        keyTwo: this.currentContextParams.handleNull
          ? this.currentContextParams.data[
              this.currentContextParams.keys.keyTwo
            ] === null
            ? 'NA'
            : this.currentContextParams.data[
                this.currentContextParams.keys.keyTwo
              ]
          : this.currentContextParams.data[
              this.currentContextParams.keys.keyTwo
            ] === null
          ? 0
          : this.currentContextParams.data[
              this.currentContextParams.keys.keyTwo
            ],
        pvp: this.currentContextParams.handleNull
          ? this.currentContextParams.data[
              this.currentContextParams.keys.pvp
            ] === null
            ? 'NA'
            : this.currentContextParams.data[this.currentContextParams.keys.pvp]
          : this.currentContextParams.data[
              this.currentContextParams.keys.pvp
            ] === null
          ? 0
          : this.currentContextParams.data[this.currentContextParams.keys.pvp],
        fillVal:
          this.currentContextParams.data[
            this.currentContextParams.keys.fillVal
          ] || 0,
        greaterThanColor: this.currentContextParams.keys.greaterThanColor, // numerator > denominator
        lesserThanColor: this.currentContextParams.keys.lesserThanColor, // numerator < denominator
        greaterThanTextColor:
          this.currentContextParams.keys.greaterThanTextColor,
        lesserThanTextColor: this.currentContextParams.keys.lesserThanTextColor,
        greaterThanIcon: this.currentContextParams.keys.greaterThanIcon,
        lesserThanIcon: this.currentContextParams.keys.lesserThanIcon,
        equalToColor: this.currentContextParams.keys.equalToColor,
        equalToTextColor: this.currentContextParams.keys.equalToTextColor,
        hideComparator: this.currentContextParams.keys.hideComparator || false,
        handleNull: this.currentContextParams.keys.handleNull
      };
    }
  },
  created() {
    this.currentContextParams = this.wrappedInParent
      ? this.$parent.params
      : this.params;
  },
  methods: {
    getPvp(keyOne, keyTwo) {
      return Math.abs(keyOne - keyTwo);
    },
    getTooltip(data) {
      if (this.currentContextParams?.handleNullTooltip) {
        return data === 'NA'
          ? this.currentContextParams.handleNullTooltip
          : null;
      }
      return null;
    },
    formatter(item) {
      if (item === 'NA' || item === undefined || item === null) return 'NA';
      if (this.currentContextParams.unit === 'percent') {
        return item.toFixed(2) + '%';
      }
      if (this.currentContextParams.unit === 'volume') {
        return Vue.options.filters.num_format(item);
      }
      return Vue.options.filters.num_format(item, 'currency');
    },
    formatPVP(item) {
      if (item === 'NA' || item === undefined || item === null) return 'NA';
      else {
        return Math.abs(item.toFixed(1)) + '%';
      }
    },
    getProgressBarIcon() {
      if (this.currentContextParams.showBarColorsOnValueSign) {
        return this.progressData.pvp > this.baseVal
          ? this.progressData.greaterThanIcon
          : this.progressData.lesserThanIcon;
      } else
        return this.width() === this.maxVal
          ? this.progressData.greaterThanIcon
          : this.progressData.lesserThanIcon;
    },
    width() {
      if (this.currentContextParams.hasFillValue) {
        return this.progressData.fillVal;
      }
      if (this.progressData.keyTwo === this.baseVal) {
        return this.progressData.keyOne > this.baseVal
          ? this.maxVal
          : this.baseVal;
      }
      return this.progressData.keyOne / this.progressData.keyTwo >= 1
        ? this.maxVal
        : (this.progressData.keyOne / this.progressData.keyTwo) * this.maxVal;
    },
    findBackgroundColor() {
      if (this.currentContextParams.showBarColorsOnValueSign) {
        return this.progressData.pvp > this.baseVal
          ? this.progressData.greaterThanColor
          : this.progressData.lesserThanColor;
      }
      if (this.progressData.keyOne === this.progressData.keyTwo) {
        return this.progressData.equalToColor;
      }
      return this.progressData.keyOne > this.progressData.keyTwo
        ? this.progressData.greaterThanColor
        : this.progressData.lesserThanColor;
    },
    findTextColor() {
      if (this.currentContextParams.showBarColorsOnValueSign) {
        return this.progressData.pvp > this.baseVal
          ? this.progressData.greaterThanTextColor
          : this.progressData.lesserThanTextColor;
      }
      if (this.progressData.keyOne === this.progressData.keyTwo) {
        return this.progressData.equalToTextColor;
      }
      return this.progressData.keyOne > this.progressData.keyTwo
        ? this.progressData.greaterThanTextColor
        : this.progressData.lesserThanTextColor;
    }
  }
};
</script>

<style lang="css" scoped>
.setProgressStyle {
  height: 100%;
}
.metricPostion {
  right: 16px;
}
</style>
