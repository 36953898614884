<template>
  <div class="u-font-size-5">
    <div class="u-display-flex u-flex-align-items-center u-spacing-pb-s">
      <div class="u-font-size-5 u-color-grey-mid-light">
        {{ axisHeading }}
      </div>
      <div class="u-font-size-5 u-spacing-ml-xs u-color-grey-base">
        {{ axisLabel }}
      </div>
    </div>
    <div class="u-font-size-3 u-font-weight-600 u-color-grey-base">
      {{ coordinate }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axisLabel: {
      type: String,
      default: null
    },
    coordinate: {
      type: String,
      default: null
    },
    axisHeading: {
      type: String,
      default: null
    }
  }
};
</script>

<style></style>
