<template>
  <rb-select
    :options="computedOptions"
    :on-close="handleOnClose"
    :send-details="true"
    class="u-spacing-mt-m"
  >
    <div
      slot="trigger"
      class="u-display-flex u-flex-align-items-center u-cursor-pointer"
    >
      <div class="u-display-flex u-flex-align-items-center u-width-100">
        <span class="u-color-grey-light">{{ params.value }}</span>
        <rb-icon
          class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
          :icon="'caret-down'"
        />
      </div>
    </div>
    <template
      slot="item"
      slot-scope="option"
    >
      <div>{{ option.label }}</div>
    </template>
  </rb-select>
</template>

<script>
export default {
  name: 'SelectCell',
  computed: {
    computedOptions() {
      return (
        this.params.options ||
        this.params.getOptions?.(this.params.node?.rowIndex) ||
        []
      );
    }
  },
  methods: {
    handleOnClose(_, newSelection) {
      this.params.onOptionSelect(
        newSelection,
        this.params.node?.rowIndex,
        this.params.value
      );
    }
  }
};
</script>
