import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  mappedName,
  extractBid
} from '@/pages/entity_details/configs/amazon/dsp.js';
import upperCase from 'lodash/upperCase';
import { commons } from '@/components/ams/campaigns/commons.js';
import { getSegmentIdArray } from '@/pages/dashboard_service_campaign_management/utils/index';
const chartingWb = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const dspRecommendations = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/alt-recommendations.vue'
  );
const actionsBar = () =>
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const FreqCapChangePanel = () =>
  import('@/components/ams/actionPanels/frequencyCapChangePanel.vue');
const BidChangePanel = () =>
  import('@/components/ams/actionPanels/bidChangePanel.vue');
const activityLog = () =>
  import('@/components/widgets/collaboration/collaboration.vue');
const plotRowsChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconHeader = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');
const recommendationCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/recommendationCell.vue'
  );
const customActionPanel = () =>
  import('@/components/ams/actionPanels/customActionPanel.vue');
const recChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/recChip.vue'
  );
const ApproveRec = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const editBid = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/editBid.vue'
  );

export default {
  Orders: {
    PRIMARY_KEY: 'dsp_orders_order_id',
    recommendationKey: 'dsp_orders_recommendation_data',
    entity: 'order',
    styleClass: 'orders',
    // list API data
    pageInfo: {
      name: 'dspOrdersWorkbench',
      title: 'Orders'
    },
    initRequestParams: {
      global: {
        name: 'dspOrdersWorkbench',
        title: 'Orders',
        ':from': '2021-10-21',
        ':to': '2021-10-27',
        ':pvpFrom': '2021-10-14',
        ':pvpTo': '2021-10-20',
        ':page': 'dspOrdersWorkbench',
        ':entityType': '#ALLOVER_AGGREGATE'
      },
      dspOrdersWorkbenchCharts: {
        ':widget': 'dspOrdersWorkbenchCharts',
        ':timeseriesRollupBy': 'DAY',
        ':dimensionNameValueList': [],
        timeseries: 'report_date'
      },
      dspOrdersWorkbenchTable: {
        ':widget': 'dspOrdersWorkbenchTable',
        ':orderByList': [],
        ':dimensionNameValueList': []
      },
      dspTopRecommendations: {
        ':widget': 'dspTopRecommendations',
        ':orderByList': [],
        ':dimensionNameValueList': [],
        keepExistingDimensionNameValueList: true
      }
    },
    // components for dynamic registration in DashboardWrapper
    components: {
      dspOrdersWorkbenchCharts: chartingWb,
      dspOrdersWorkbenchTable: dashboardServiceTable,
      dspTopRecommendations: dspRecommendations,
      actionsBar: actionsBar,
      FreqCapChangePanel: FreqCapChangePanel,
      activityLog: activityLog,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconHeader: iconHeader,
      iconTextActions: iconTextActions,
      recommendationCell: recommendationCell,
      recChip: recChip
    },

    // header options passed to dashboard_inflator
    headerOptions: (context) => ({
      // context is bound to the DashboardWrapper component
      dspOrdersWorkbenchCharts: {
        props: {
          headerOptions: [
            {
              ...(!context.showPlotRowsChip
                ? {}
                : {
                    ui_component: 'plotRowsChip',
                    props: {
                      numberOfPlottedSelections:
                        context.rowSelections.length || 0
                    },
                    events: {
                      removeChip: context.removeChip
                    }
                  })
            }
          ],
          enableRollup: true,
          enableToggle: true,
          enableDownload: true,
          enableWaterfall: true,
          selectedMetricLimit: 3,
          removeDropdown: false,
          disableAddNewMetricButton: false,
          headerContainerGridStyles: {
            display: 'flex',
            'justify-content': 'flex-end',
            gap: '1.6rem',
            'align-items': 'center'
          }
        }
      },
      dspOrdersWorkbenchTable: {
        props: {
          headerOptions: [
            {
              ui_component: 'actionsBar',
              props: {
                // contains actions and their handlers similar to that of walmart campaign management
                actionBarActions: [
                  {
                    icon: 'show-chart',
                    title: 'PLOT ROWS',
                    id: 'show-chart',
                    removeSelectionState: false,
                    handler(selectionLength, dropDownSelection, selections) {
                      // here {this} is bound to the cw_actionsBar component
                      this.$emit('handlePlotRows', selections);
                    }
                  },
                  {
                    icon: 'task-status',
                    title: 'Edit Status',
                    id: 'edit-status',
                    dropDown: true,
                    dropDownOptions: [
                      { title: 'Enable', value: 'ACTIVE' },
                      { title: 'End', value: 'INACTIVE' }
                    ],
                    handler(selectionLength, dropDownSelection, selections) {
                      // here {this} is bound to the cw_actionsBar component
                      return new Promise((resolve, reject) => {
                        const dataArray = selections.map((item) => item.data);

                        const payloadArray = dataArray.map((item) => {
                          const actionPayload = {
                            orderId: item.dsp_orders_order_id,
                            profileId: item.dsp_orders_profile_id,
                            deliveryActivationStatus:
                              dropDownSelection[0].value,
                            clientName: window.clientName,
                            actionType: 'dspOrderStatusChange'
                          };
                          return {
                            actionPayload,
                            actionType: 'dspOrderStatusChange',
                            viewPayload: {
                              ...actionPayload,
                              previousStatus:
                                item.dsp_orders_delivery_activation_status &&
                                upperCase(
                                  item.dsp_orders_delivery_activation_status
                                )
                            },
                            widget: 'order',
                            previousEntityValue:
                              item.dsp_orders_delivery_activation_status &&
                              upperCase(
                                item.dsp_orders_delivery_activation_status
                              ),
                            newEntityValue: dropDownSelection[0].value,
                            primaryKey: item.dsp_orders_order_id,
                            actionSource: {
                              pageUrl: window.location.href
                            }
                          };
                        });
                        HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                          .then((response) => {
                            this.$emit(
                              'openSnackbar',
                              'Status change request has been submitted for the selected order(s)'
                            );
                            this.switchBlueBar('order(s)');
                            resolve(response);
                          })
                          .catch((err) => {
                            this.$emit('openSnackbar', 'Something went wrong.');
                            reject(err);
                          });
                      });
                    }
                  },
                  {
                    icon: 'pencil',
                    title: 'Adjust Frequency Cap',
                    id: 'adjust-freq-cap',
                    customState: {
                      component: 'FreqCapChangePanel',
                      props: {
                        title: 'Adjust Frequency Cap',
                        actionPanelType: 'orders',
                        apiVersion: 'actionlog',
                        retailer: context.$store.getters.getRetailer,
                        widget: 'order',
                        primaryKey: 'dsp_orders_order_id'
                      },
                      events: {
                        afterAPIActionToCall(payload) {
                          this.removeCustomCustomComponents();
                          this.closeActionsWorkflow();
                        },
                        closePanelMethod() {
                          this.removeCustomCustomComponents();
                        }
                      }
                    }
                  }
                ],
                isCustomActionStateVisible: context.isCustomActionStateVisible,
                selections: context.rowSelections,
                showPlotRowsChip: context.showPlotRowsChip
              },
              events: {
                handlePlotRows: context.handlePlotRows,
                deselectSelections: (selections) => {
                  // this is on x click in actions bar;
                  context.handleSelectionChange(selections, true);
                },
                openSnackbar: context.openSnackbar,
                toggleCustomActionState: context.toggleCustomActionState,
                switchBlueBar: context.switchBlueBar
              }
            }
          ],
          columnKeyMapping: context.columnKeyMapping,
          headerContainerGridStyles: {
            ...(context.rowSelections.length
              ? { padding: 0 }
              : {
                  display: 'flex',
                  'justify-content': 'flex-end',
                  'align-items': 'flex-start'
                })
          },
          searchParams: {
            show: false
          },
          gridOptions: context.gridOptions,
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: context.handleSelectionChange
          },
          enableDownloadWithText: !context.rowSelections.length,
          enableManageColumns: !context.rowSelections.length,
          rowHeight: 70
          // selections: context.rowSelections
        }
      },
      dspTopRecommendations: {
        props: {
          footerOptions: [],
          showLastUpdatedDate: false,
          entityName: 'order',
          countKey: 'count',
          labelKey: 'recLabel',
          selectedRecommendation: context.selectedRecommendation,
          isCustomActionStateVisible: context.isCustomActionStateVisible,
          APIConfig: {
            endPoint: '/recommendation/getMetadata',
            request: {
              brandsRequest: {
                cubeName: 'dsp_orders_workbench_client_catalog',
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [],
                groupByDimensionsList: ['dsp_orders_order_id'],
                orderByList: [],
                where: {
                  dimensionNameValueList: [],
                  date: {
                    from: ':from',
                    to: ':to'
                  },
                  pvpDate: {
                    from: ':pvpFrom',
                    to: ':pvpTo'
                  }
                }
              },
              entityType: 'order'
            },
            service: 'ENTITY_METADATA_SERVICE'
          }
        },
        events: {
          recommendationFilter: context.recommendationFilter,
          handleRecommendation: context.handleRecommendation,
          handleSelectionChange: context.handleSelectionChange,
          toggleRecCustomComponent: context.toggleRecCustomComponent
        }
      }
    }),
    filterSettings: {
      emitEvent: 'dspFilterApply',
      uiKey: 0,
      show: true,
      backendCube: 'dsp_orders_workbench',
      defaultDatePostRange: 'last30Days',
      enableSave: false,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: 'dsp-orders-filter',
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: 'Search for an Order',
        searchDimension: 'dsp_orders_search'
      },
      commonDateKey: 'amazon-dsp-common-date',
      enableBetweenOperator: true,
      additionalRanges: commons.additionalDateRanges()
    },
    widgetNames: {
      chart: 'dspOrdersWorkbenchCharts',
      table: 'dspOrdersWorkbenchTable'
    },
    getActivityData: (data, key) => ({
      params: { widget: 'order', primaryKey: data[key] }
    }),
    metadataToReplaceKey: 'dsp_orders_recommendation_data',
    metadataToReplaceWith: (recTypeKey, rest) => ({
      ...rest,
      tableConfig: {
        width: 180,
        ui_component: 'recChip',
        props: {
          isComputedChip: true,
          recTypeKey,
          data: 'dsp_orders_recommendation_data'
        },
        parser: {
          data: 'parseAndReturnArray'
        },
        columnHeader: {
          ui_component: 'iconWithText',
          props: {
            icon: 'alerts',
            text: 'Recommended Action',
            textClasses: 'u-font-size-7 u-font-weight-600 u-color-grey-lighter',
            wrapperClass: 'rec-heading'
          }
        }
      }
    })
  },
  'Line Items': {
    PRIMARY_KEY: 'dsp_line_items_line_item_id',
    recommendationKey: 'dsp_line_items_recommendation_data',
    entity: 'line item',
    styleClass: 'line_items',
    pageInfo: {
      name: 'dspLineItemsWorkbench',
      title: 'Line Items'
    },
    initRequestParams: {
      global: {
        name: 'dspLineItemsWorkbench',
        title: 'Line Items',
        ':from': '2021-10-21',
        ':to': '2021-10-27',
        ':pvpFrom': '2021-10-14',
        ':pvpTo': '2021-10-20',
        ':page': 'dspLineItemsWorkbench',
        ':entityType': '#ALLOVER_AGGREGATE'
      },
      dspLineItemsWorkbenchCharts: {
        ':widget': 'dspLineItemsWorkbenchCharts',
        ':timeseriesRollupBy': 'DAY',
        ':dimensionNameValueList': [],
        timeseries: 'report_date'
      },
      dspLineItemsWorkbenchTable: {
        ':widget': 'dspLineItemsWorkbenchTable',
        ':orderByList': [],
        ':dimensionNameValueList': []
      },
      dspTopRecommendationsLineItemsWorkbench: {
        ':widget': 'dspTopRecommendationsLineItemsWorkbench',
        ':orderByList': [],
        ':dimensionNameValueList': [],
        keepExistingDimensionNameValueList: true
      }
    },
    components: {
      dspLineItemsWorkbenchCharts: chartingWb,
      dspLineItemsWorkbenchTable: dashboardServiceTable,
      dspTopRecommendationsLineItemsWorkbench: dspRecommendations,
      actionsBar: actionsBar,
      FreqCapChangePanel: FreqCapChangePanel,
      activityLog: activityLog,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconHeader: iconHeader,
      iconTextActions: iconTextActions,
      recommendationCell: recommendationCell,
      customActionPanel: customActionPanel,
      BidChangePanel: BidChangePanel,
      recChip,
      ApproveRec,
      editBid
    },
    headerOptions: (context) => {
      return {
        dspLineItemsWorkbenchCharts: {
          props: {
            headerOptions: [
              {
                ...(!context.showPlotRowsChip
                  ? {}
                  : {
                      ui_component: 'plotRowsChip',
                      props: {
                        numberOfPlottedSelections:
                          context.rowSelections.length || 0
                      },
                      events: {
                        removeChip: context.removeChip
                      }
                    })
              }
            ],
            enableRollup: true,
            enableToggle: true,
            enableDownload: true,
            enableWaterfall: true,
            selectedMetricLimit: 3,
            removeDropdown: false,
            disableAddNewMetricButton: false,
            headerContainerGridStyles: {
              display: 'flex',
              'justify-content': 'flex-end',
              'align-items': 'center',
              gap: '1.6rem'
            }
          }
        },
        dspLineItemsWorkbenchTable: {
          props: {
            headerOptions: [
              ...(!context.rowSelections.length
                ? []
                : context.showRecCustomComponent
                ? []
                : [
                    {
                      ui_component: 'actionsBar',
                      props: {
                        actionBarActions: [
                          ...(!context?.recFilter?.value
                            ? []
                            : [
                                {
                                  icon: 'check-fill-circle',
                                  title: `View & Approve ${
                                    context.rowSelections.length > 1
                                      ? 'all'
                                      : ''
                                  }`,
                                  id: 'approve_all',
                                  removeSelectionState: false,
                                  handler(
                                    selectionLength,
                                    dropDownSelection,
                                    selections
                                  ) {
                                    // here {this} is bound to the cw_actionsBar component
                                    this.$emit(
                                      'toggleRecCustomComponent',
                                      true
                                    );
                                  }
                                }
                              ]),
                          {
                            icon: 'show-chart',
                            title: 'PLOT ROWS',
                            id: 'show-chart',
                            removeSelectionState: false,
                            handler(
                              selectionLength,
                              dropDownSelection,
                              selections
                            ) {
                              // here {this} is bound to the cw_actionsBar component
                              this.$emit('handlePlotRows', selections);
                            }
                          },
                          {
                            icon: 'task-status',
                            title: 'Edit Status',
                            id: 'edit-status',
                            dropDown: true,
                            dropDownOptions: [
                              { title: 'Enable', value: 'ACTIVE' },
                              { title: 'End', value: 'INACTIVE' }
                            ],
                            handler(
                              selectionLength,
                              dropDownSelection,
                              selections
                            ) {
                              // here {this} is bound to the cw_actionsBar component
                              return new Promise((resolve, reject) => {
                                const dataArray = selections.map(
                                  (item) => item.data
                                );

                                const payloadArray = dataArray.map((item) => {
                                  const actionPayload = {
                                    lineItemId:
                                      item.dsp_line_items_line_item_id,
                                    profileId: item.dsp_line_items_profile_id,
                                    deliveryActivationStatus:
                                      dropDownSelection[0].value,
                                    clientName: window.clientName,
                                    actionType: 'dspLineItemStatusChange'
                                  };
                                  return {
                                    actionPayload,
                                    actionType: 'dspLineItemStatusChange',
                                    viewPayload: {
                                      ...actionPayload,
                                      previousStatus:
                                        item.dsp_line_items_delivery_activation_status &&
                                        upperCase(
                                          item.dsp_line_items_delivery_activation_status
                                        )
                                    },
                                    widget: 'lineItem',
                                    previousEntityValue:
                                      item.dsp_line_items_delivery_activation_status &&
                                      upperCase(
                                        item.dsp_line_items_delivery_activation_status
                                      ),
                                    newEntityValue: dropDownSelection[0].value,
                                    primaryKey:
                                      item.dsp_line_items_line_item_id,
                                    actionSource: {
                                      pageUrl: window.location.href
                                    }
                                  };
                                });
                                HttpService.post(
                                  'AMS_ACTIONS_ACTION_LOG',
                                  payloadArray
                                )
                                  .then((response) => {
                                    this.$emit(
                                      'openSnackbar',
                                      'Status change request has been submitted for the selected line item(s)'
                                    );
                                    this.switchBlueBar('line item(s)');
                                    resolve(response);
                                  })
                                  .catch((err) => {
                                    this.$emit(
                                      'openSnackbar',
                                      'Something went wrong.'
                                    );
                                    reject(err);
                                  });
                              });
                            }
                          },
                          {
                            icon: 'dollar',
                            title: 'Base Supply Bid',
                            id: 'adjust-base-bids',
                            customState: {
                              component: 'BidChangePanel',
                              props: {
                                title: 'Adjust Base Supply Bid',
                                actionPanelType: 'lineItemBaseSupplyBid',
                                apiVersion: 'actionlog',
                                retailer: context.$store.getters.getRetailer,
                                widget: 'lineItem',
                                primaryKey: 'dsp_line_items_line_item_id'
                              },
                              events: {
                                afterAPIActionToCall(payload) {
                                  this.$emit('switchBlueBar', 'line item(s)');
                                  this.removeCustomCustomComponents();
                                  this.closeActionsWorkflow();
                                },
                                closePanelMethod() {
                                  this.removeCustomCustomComponents();
                                }
                              }
                            }
                          },
                          {
                            icon: 'dollar',
                            title: 'Max Supply Bid',
                            id: 'adjust-max-bids',
                            customState: {
                              component: 'BidChangePanel',
                              props: {
                                title: 'Adjust Max Supply Bid',
                                actionPanelType: 'lineItemMaxSupplyBid',
                                apiVersion: 'actionlog',
                                retailer: context.$store.getters.getRetailer,
                                widget: 'lineItem',
                                primaryKey: 'dsp_line_items_line_item_id'
                              },
                              events: {
                                afterAPIActionToCall(payload) {
                                  this.$emit('switchBlueBar', 'line item(s)');
                                  this.removeCustomCustomComponents();
                                  this.closeActionsWorkflow();
                                },
                                closePanelMethod() {
                                  this.removeCustomCustomComponents();
                                }
                              }
                            }
                          },
                          {
                            icon: 'pencil',
                            title: 'Adjust Frequency Cap',
                            id: 'adjust-freq-cap',
                            customState: {
                              component: 'FreqCapChangePanel',
                              props: {
                                title: 'Adjust Frequency Cap',
                                actionPanelType: 'lineItems',
                                apiVersion: 'actionlog',
                                retailer: context.$store.getters.getRetailer,
                                widget: 'lineItem',
                                primaryKey: 'dsp_line_items_line_item_id'
                              },
                              events: {
                                afterAPIActionToCall(payload) {
                                  this.removeCustomCustomComponents();
                                  this.closeActionsWorkflow();
                                },
                                closePanelMethod() {
                                  this.removeCustomCustomComponents();
                                }
                              }
                            }
                          },
                          {
                            icon: 'add-circle-outline',
                            title: 'Add Inventory',
                            id: 'add-inventory-targeting',
                            customState: {
                              component: 'customActionPanel',
                              props: {
                                staticHeaderText: 'Add Inventory',
                                widgetsConfig: {
                                  config: (that) => {
                                    // {that} is bound to actionsBar component
                                    return {
                                      primaryKey:
                                        'dsp_inventory_targeting_supply_source',
                                      isMultipleAllowed: false,
                                      default: {
                                        noRowsRightTable:
                                          'Add Inventory Sources to this list',
                                        selections: `(${context.rowSelections.length} line item selected)`,
                                        noRowsLeftTable:
                                          'No inventory sources found',
                                        leftBtnLabel: 'Add Inventory',
                                        rightBtnLabel: 'Cancel',
                                        warningMessage:
                                          'Multiple line items are not allowed'
                                      },
                                      left: {
                                        table: 'left',
                                        tabs: {
                                          Amazon: {
                                            localFilter: {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'AMAZON_EXCLUSIVE'
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'left',
                                              'AMAZON_EXCLUSIVE'
                                            ),
                                            search: {
                                              enableSearch: false
                                            }
                                          },
                                          'Third Party Exchanges': {
                                            localFilter: {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'OPEN_EXCHANGE'
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'left',
                                              'OPEN_EXCHANGE'
                                            ),
                                            search: {
                                              enableSearch: true,
                                              placeholder: 'Search',
                                              searchKey:
                                                'dsp_inventory_targeting_supply_source_name'
                                            }
                                          }
                                        },
                                        meta: {
                                          type: 'table',
                                          action: 'fetchTableDataExecuteApi',
                                          localFilters: [
                                            {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'AMAZON_EXCLUSIVE'
                                            },
                                            ...(context.rowSelections?.[0]?.data
                                              ?.dsp_line_items_line_item_type
                                              ? [
                                                  {
                                                    dimensionName:
                                                      'dsp_inventory_targeting_line_item_type',
                                                    dimensionValue:
                                                      context.rowSelections?.[0]?.data?.dsp_line_items_line_item_type
                                                        ?.split?.(' ')
                                                        ?.map?.(upperCase)
                                                        ?.join?.('_')
                                                  }
                                                ]
                                              : [])
                                          ],
                                          localStoreageFilterKey:
                                            'dsp-line-item-inventory-targeting'
                                        },
                                        footer: {
                                          show: false
                                        },
                                        body: {
                                          APIConfig: {
                                            cubeName:
                                              'dsp_inventory_targeting_workbench',
                                            getLatestAvailableInsteadOfRollup: false,
                                            timeseriesEnabled: false,
                                            pvpenabled: false,
                                            yoyenabled: false,
                                            measuresList: [
                                              'dsp_inventory_targeting_supply_source_name',
                                              'dsp_inventory_targeting_supply_source_type',
                                              'dsp_inventory_targeting_line_item_type'
                                            ],
                                            groupByDimensionsList: [
                                              'dsp_inventory_targeting_supply_source'
                                            ],
                                            orderByList: [],
                                            limit: 10,
                                            page: 1
                                          },
                                          columnDefs: getColumnDefinition(
                                            that,
                                            'line_items_add_inventory',
                                            'left',
                                            'AMAZON_EXCLUSIVE'
                                          )
                                        }
                                      },
                                      right: {
                                        table: 'right',
                                        searchKey: 'search',
                                        meta: {
                                          type: 'table',
                                          action: 'fetchTableDataExecuteApi',
                                          localFilters: [
                                            {
                                              dimensionName:
                                                'dsp_inventory_targeting_line_item_id',
                                              dimensionValue:
                                                context.rowSelections[0].data
                                                  .dsp_line_items_line_item_id
                                            }
                                          ],
                                          localStoreageFilterKey:
                                            'dsp-line-item-inventory-targeting'
                                        },
                                        footer: {
                                          show: false
                                        },
                                        body: {
                                          APIConfig: {
                                            cubeName:
                                              'dsp_inventory_targeting_workbench',
                                            getLatestAvailableInsteadOfRollup: false,
                                            timeseriesEnabled: false,
                                            pvpenabled: false,
                                            yoyenabled: false,
                                            measuresList: [
                                              'dsp_inventory_targeting_supply_source_name',
                                              'dsp_inventory_targeting_supply_source_type'
                                            ],
                                            groupByDimensionsList: [
                                              'dsp_inventory_targeting_supply_source',
                                              'dsp_inventory_targeting_line_item_type'
                                            ],
                                            orderByList: [],
                                            limit: 500,
                                            page: 1
                                          },
                                          columnDefs: getColumnDefinition(
                                            that,
                                            'line_items_add_inventory',
                                            'right'
                                          )
                                        }
                                      },
                                      apply(rows = []) {
                                        return new Promise(
                                          (resolve, reject) => {
                                            const payload = rows.map((row) => ({
                                              actionPayload: {
                                                lineItemId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id,
                                                profileId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_profile_id,
                                                clientName: window.clientName,
                                                actionType:
                                                  'dspLineItemAddInventorySources',
                                                supplySources: [
                                                  row.dsp_inventory_targeting_supply_source
                                                ]
                                              },
                                              viewPayload: {
                                                lineItemId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id,
                                                profileId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_profile_id,
                                                clientName: window.clientName,
                                                actionType:
                                                  'dspLineItemAddInventorySources',
                                                supplySources:
                                                  row.dsp_inventory_targeting_supply_source,
                                                sourceName:
                                                  row.dsp_inventory_targeting_supply_source_name,
                                                sourceType:
                                                  mappedName[
                                                    row
                                                      .dsp_inventory_targeting_supply_source_type
                                                  ]
                                              },
                                              actionType:
                                                'dspLineItemAddInventorySources',
                                              widget: 'lineItem',
                                              previousEntityValue: null,
                                              newEntityValue: null,
                                              primaryKey:
                                                context.rowSelections[0].data
                                                  .dsp_line_items_line_item_id,
                                              actionSource: {
                                                pageUrl: window.location.href
                                              }
                                            }));
                                            HttpService.post(
                                              'AMS_ACTIONS_ACTION_LOG',
                                              payload
                                            )
                                              .then((response) => {
                                                that.$emit(
                                                  'afterAPIActionToCall'
                                                );
                                                resolve(true);
                                              })
                                              .catch((err) => {
                                                that.openSnackbar(
                                                  'Something went wrong.'
                                                );
                                                reject(err);
                                              });
                                          }
                                        );
                                      }
                                    };
                                  }
                                }
                              },
                              events: {
                                afterAPIActionToCall(payload) {
                                  this.$emit(
                                    'openSnackbar',
                                    'Action has been submitted for the selected inventory sources'
                                  );
                                  this.switchBlueBar('line item');
                                  this.removeCustomCustomComponents();
                                  this.closeActionsWorkflow();
                                },
                                closePanelMethod() {
                                  this.removeCustomCustomComponents();
                                }
                              }
                            }
                          },
                          {
                            icon: 'remove-fill',
                            title: 'Remove Inventory',
                            id: 'remove-inventory-targeting',
                            customState: {
                              component: 'customActionPanel',
                              props: {
                                staticHeaderText: 'Remove Inventory',
                                widgetsConfig: {
                                  config: (that) => {
                                    return {
                                      primaryKey:
                                        'dsp_inventory_targeting_supply_source',
                                      isMultipleAllowed: false,
                                      default: {
                                        headerTitle: '',
                                        noRowsRightTable:
                                          'Add Inventory Sources to this list',
                                        selections: `(${context.rowSelections.length} line item selected)`,
                                        noRowsLeftTable:
                                          'No inventory sources found',
                                        leftBtnLabel: 'Remove Inventory',
                                        rightBtnLabel: 'Cancel',
                                        warningMessage:
                                          'Multiple line items are not allowed'
                                      },
                                      left: {
                                        table: 'left',
                                        title: '',
                                        tabs: {
                                          Amazon: {
                                            localFilter: {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'AMAZON_EXCLUSIVE'
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'left',
                                              'AMAZON_EXCLUSIVE'
                                            ),
                                            search: {
                                              enableSearch: false
                                            }
                                          },
                                          'Third Party Exchanges': {
                                            localFilter: {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'OPEN_EXCHANGE'
                                            },
                                            columnDefs: getColumnDefinition(
                                              that,
                                              'line_items_add_inventory',
                                              'left',
                                              'OPEN_EXCHANGE'
                                            ),
                                            search: {
                                              enableSearch: true,
                                              placeholder: 'Search',
                                              searchKey:
                                                'dsp_inventory_targeting_supply_source_name'
                                            }
                                          }
                                        },
                                        meta: {
                                          type: 'table',
                                          key: 'campaign_entities_source_table',
                                          action: 'fetchTableDataExecuteApi',
                                          filters: {
                                            emit: 'fetchAllEntitiesFiltersTriggered',
                                            hasSearch: false
                                          },
                                          filterState: {
                                            getter:
                                              'getExecuteApiSelectedFitlers',
                                            setter:
                                              'fetchEntitiesExecuteApiFilters'
                                          },
                                          localFilters: [
                                            {
                                              dimensionName:
                                                'dsp_inventory_targeting_line_item_id',
                                              dimensionValue:
                                                context.rowSelections[0].data
                                                  .dsp_line_items_line_item_id
                                            },
                                            {
                                              dimensionName:
                                                'dsp_inventory_targeting_supply_source_type',
                                              dimensionValue: 'AMAZON_EXCLUSIVE'
                                            }
                                          ],
                                          localStoreageFilterKey:
                                            'dsp-line-item-inventory-targeting'
                                        },
                                        footer: {
                                          show: false
                                        },
                                        body: {
                                          APIConfig: {
                                            cubeName:
                                              'dsp_inventory_targeting_workbench',
                                            getLatestAvailableInsteadOfRollup: false,
                                            timeseriesEnabled: false,
                                            pvpenabled: false,
                                            yoyenabled: false,
                                            measuresList: [
                                              'dsp_inventory_targeting_supply_source_type'
                                            ],
                                            groupByDimensionsList: [
                                              'dsp_inventory_targeting_supply_source_name',
                                              'dsp_inventory_targeting_supply_source',
                                              'dsp_inventory_targeting_order_id',
                                              'dsp_inventory_targeting_line_item_type',
                                              'dsp_inventory_targeting_line_item_name',
                                              'dsp_inventory_targeting_line_item_id'
                                            ],
                                            orderByList: [],
                                            limit: 10,
                                            page: 1
                                          },
                                          columnDefs: getColumnDefinition(
                                            that,
                                            'line_items_remove_inventory',
                                            'left'
                                          )
                                        }
                                      },
                                      right: {
                                        table: 'right',
                                        title: '',
                                        meta: {
                                          type: 'table',
                                          localFilters: [],
                                          localStoreageFilterKey:
                                            'dsp-line-item-inventory-targeting',
                                          paginationAction: ''
                                        },
                                        footer: {
                                          show: false
                                        },
                                        body: {
                                          APIConfig: {
                                            limit: 10,
                                            page: 1
                                          },
                                          columnDefs: getColumnDefinition(
                                            that,
                                            'line_items_remove_inventory',
                                            'right'
                                          )
                                        }
                                      },
                                      apply(rows = []) {
                                        return new Promise(
                                          (resolve, reject) => {
                                            const payload = rows.map((row) => ({
                                              actionPayload: {
                                                lineItemId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id,
                                                profileId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_profile_id,
                                                clientName: window.clientName,
                                                actionType:
                                                  'dspLineItemRemoveInventorySources',
                                                supplySources: [
                                                  row.dsp_inventory_targeting_supply_source
                                                ]
                                              },
                                              viewPayload: {
                                                lineItemId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_line_item_id,
                                                profileId:
                                                  context.rowSelections[0].data
                                                    .dsp_line_items_profile_id,
                                                clientName: window.clientName,
                                                actionType:
                                                  'dspLineItemRemoveInventorySources',
                                                supplySources:
                                                  row.dsp_inventory_targeting_supply_source,
                                                sourceName:
                                                  row.dsp_inventory_targeting_supply_source_name,
                                                sourceType:
                                                  mappedName[
                                                    row
                                                      .dsp_inventory_targeting_supply_source_type
                                                  ]
                                              },
                                              actionType:
                                                'dspLineItemRemoveInventorySources',
                                              widget: 'lineItem',
                                              previousEntityValue: null,
                                              newEntityValue: null,
                                              primaryKey:
                                                context.rowSelections[0].data
                                                  .dsp_line_items_line_item_id,
                                              actionSource: {
                                                pageUrl: window.location.href
                                              }
                                            }));
                                            HttpService.post(
                                              'AMS_ACTIONS_ACTION_LOG',
                                              payload
                                            )
                                              .then((response) => {
                                                that.$emit(
                                                  'afterAPIActionToCall'
                                                );
                                                resolve(true);
                                              })
                                              .catch((err) => {
                                                that.openSnackbar(
                                                  'Something went wrong.'
                                                );
                                                reject(err);
                                              });
                                          }
                                        );
                                      }
                                    };
                                  }
                                }
                              },
                              events: {
                                afterAPIActionToCall(payload) {
                                  this.$emit(
                                    'openSnackbar',
                                    'Action has been submitted for the selected inventory sources'
                                  );
                                  this.switchBlueBar('line item');
                                  this.removeCustomCustomComponents();
                                  this.closeActionsWorkflow();
                                },
                                closePanelMethod() {
                                  this.removeCustomCustomComponents();
                                }
                              }
                            }
                          }
                        ],
                        selections: context.rowSelections,
                        blacklistedActionsForMultiSelections: [
                          'Add Inventory',
                          'Remove Inventory'
                        ],
                        isCustomActionStateVisible:
                          context.isCustomActionStateVisible,
                        showPlotRowsChip: context.showPlotRowsChip
                      },
                      events: {
                        handlePlotRows: context.handlePlotRows,
                        deselectSelections: (selections) => {
                          // this is on x click in actions bar;
                          context.handleSelectionChange(selections, true);
                        },
                        openSnackbar: context.openSnackbar,
                        switchBlueBar: context.switchBlueBar,
                        toggleCustomActionState:
                          context.toggleCustomActionState,
                        toggleRecCustomComponent:
                          context.toggleRecCustomComponent
                      }
                    }
                  ])
            ],
            enableDownloadWithText: context.showRecCustomComponent
              ? true
              : !context.rowSelections.length,
            enableManageColumns: !context.rowSelections.length,
            headerContainerGridStyles: {
              ...(!context.rowSelections.length
                ? {
                    display: 'flex',
                    'justify-content': 'flex-end',
                    'align-items': 'flex-start'
                  }
                : context.showRecCustomComponent
                ? {
                    display: 'flex',
                    'justify-content': 'flex-end',
                    'align-items': 'flex-start'
                  }
                : {
                    padding: 0
                  })
            },
            searchParams: {
              show: false
            },
            gridOptions: context.gridOptions,
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: context.handleSelectionChange
            },
            rowHeight: 45,
            columnKeyMapping: context.columnKeyMapping
          }
        },
        dspTopRecommendationsLineItemsWorkbench: {
          props: {
            footerOptions: [],
            showLastUpdatedDate: false,
            entityName: 'line item',
            countKey: 'count',
            labelKey: 'recLabel',
            recCustomComponent:
              (context.showRecCustomComponent &&
                context.recCustomComponent(context)[
                  context?.recFilter?.value
                ]) ||
              {},
            selectedRecommendation: context.selectedRecommendation,
            isCustomActionStateVisible: context.isCustomActionStateVisible,
            APIConfig: {
              endPoint: '/recommendation/getMetadata',
              request: {
                brandsRequest: {
                  cubeName: 'dsp_line_items_workbench_client_catalog',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [],
                  groupByDimensionsList: ['dsp_line_items_line_item_id'],
                  orderByList: [],
                  where: {
                    dimensionNameValueList: [],
                    date: {
                      from: ':from',
                      to: ':to'
                    },
                    pvpDate: {
                      from: ':pvpFrom',
                      to: ':pvpTo'
                    }
                  }
                },
                entityType: 'line_item'
              },
              service: 'ENTITY_METADATA_SERVICE'
            }
          },
          events: {
            recommendationFilter: context.recommendationFilter,
            handleRecommendation: context.handleRecommendation
          }
        }
      };
    },
    filterSettings: {
      emitEvent: 'dspFilterApply',
      uiKey: 0,
      show: true,
      backendCube: 'dsp_line_items_workbench',
      defaultDatePostRange: 'last30Days',
      enableSave: false,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: 'dsp-line-items-filter',
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: 'Search for a Line Item',
        searchDimension: 'dsp_line_items_search'
      },
      commonDateKey: 'amazon-dsp-common-date',
      enableBetweenOperator: true,
      additionalRanges: commons.additionalDateRanges()
    },
    widgetNames: {
      chart: 'dspLineItemsWorkbenchCharts',
      table: 'dspLineItemsWorkbenchTable'
    },
    getActivityData: (data, key) => ({
      params: { widget: 'lineItem', primaryKey: data[key] }
    }),
    metadataToReplaceKey: 'dsp_line_items_recommendation_data',
    metadataToReplaceWith: (recTypeKey, rest) => ({
      ...rest,
      tableConfig: {
        width: 250,
        ui_component: 'recChip',
        props: {
          isComputedChip: true,
          recTypeKey,
          data: 'dsp_line_items_recommendation_data'
        },
        parser: {
          data: 'parseAndReturnArray'
        },
        columnHeader: {
          ui_component: 'iconWithText',
          props: {
            icon: 'alerts',
            text: 'Recommended Action',
            textClasses: 'u-font-size-7 u-font-weight-600 u-color-grey-lighter',
            wrapperClass: 'rec-heading'
          }
        }
      }
    }),
    recCustomComponent: (context) => ({
      REMOVE_AUDIENCE: {
        ui_component: 'customActionPanel',
        props: {
          staticHeaderText: 'Remove audiences',
          widgetsConfig: {
            config: (that) => {
              // {that} is bound to actionsBar component
              return {
                primaryKey: 'customKey',
                createPrimaryKeyFrom: [
                  'dsp_remove_outlier_audience_segment_id',
                  'dsp_remove_outlier_audience_line_item_id'
                ],
                isMultipleAllowed: true,
                default: {
                  noRowsRightTable: 'Add audiences to remove in this list',
                  selections: `(${context.rowSelections.length} line item${
                    context.rowSelections.length > 1 ? 's' : ''
                  } selected)`,
                  noRowsLeftTable: 'No line items found',
                  leftBtnLabel: 'Remove audience',
                  rightBtnLabel: 'Cancel'
                },
                left: {
                  table: 'left',
                  tabHeader: 'Remove Audiences',
                  meta: {
                    type: 'table',
                    action: 'fetchTableDataExecuteApi',
                    localFilters: [
                      ...context?.rowSelections?.map(
                        ({ data: { dsp_line_items_line_item_id } }) => ({
                          dimensionName:
                            'dsp_remove_outlier_audience_line_item_id',
                          dimensionValue: dsp_line_items_line_item_id
                        })
                      )
                    ]
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      cubeName: 'dsp_remove_outlier_audience_segment',
                      getLatestAvailableInsteadOfRollup: false,
                      timeseriesEnabled: false,
                      pvpenabled: false,
                      yoyenabled: false,
                      measuresList: [
                        'dsp_remove_outlier_audience_segment',
                        'dsp_remove_outlier_audience_line_item_name',
                        'dsp_remove_outlier_audience_line_item_id',
                        'dsp_remove_outlier_audience_profile_id',
                        'dsp_remove_outlier_audience_segment_id'
                      ],
                      groupByDimensionsList: [
                        'dsp_remove_outlier_audience_segment_id',
                        'dsp_remove_outlier_audience_line_item_id'
                      ],
                      orderByList: [],
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'remove_audience_rec',
                      'left'
                    )
                  }
                },
                right: {
                  table: 'right',
                  searchKey: 'search',
                  meta: {
                    type: 'table',
                    localFilters: []
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'remove_audience_rec',
                      'right'
                    )
                  }
                },
                apply(rows = []) {
                  return new Promise((resolve, reject) => {
                    const payload = rows.map((row) => ({
                      actionPayload: {
                        lineItemId:
                          row.dsp_remove_outlier_audience_line_item_id,
                        profileId: row.dsp_remove_outlier_audience_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemRemoveAudiences',
                        segmentIds: getSegmentIdArray(
                          row.dsp_remove_outlier_audience_segment_id
                        )
                      },
                      viewPayload: {
                        lineItemId:
                          row.dsp_remove_outlier_audience_line_item_id,
                        profileId: row.dsp_remove_outlier_audience_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemRemoveAudiences',
                        segmentIds: row.dsp_remove_outlier_audience_segment_id,
                        audienceName: row.dsp_remove_outlier_audience_segment
                      },
                      actionType: 'dspLineItemRemoveAudiences',
                      widget: 'lineItem',
                      previousEntityValue: null,
                      newEntityValue: null,
                      primaryKey: row.dsp_remove_outlier_audience_line_item_id,
                      actionSource: {
                        pageUrl: window.location.href
                      }
                    }));
                    HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                      .then((response) => {
                        that.$emit('afterAPIActionToCall');
                        resolve(true);
                      })
                      .catch((err) => {
                        that.openSnackbar('Something went wrong.');
                        reject(err);
                      });
                  });
                }
              };
            }
          },
          selections: context.rowSelections
        },
        events: {
          afterAPIActionToCall(payload) {
            context.openSnackbar(
              'Action has been submitted for the selected line items'
            );
            context.switchBlueBar('line item');
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
            context.recommendationFilter('');
            context.handleRecommendation({});
          },
          closePanelMethod() {
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
          }
        }
      },
      OUTLIER_SUPPLY_SOURCE: {
        ui_component: 'customActionPanel',
        props: {
          staticHeaderText: 'Remove supply sources',
          widgetsConfig: {
            config: (that) => {
              // {that} is bound to actionsBar component
              return {
                primaryKey: 'customKey',
                createPrimaryKeyFrom: [
                  'dsp_outlier_supply_source_to_remove_ss_id',
                  'dsp_outlier_supply_source_to_remove_line_item_id'
                ],
                isMultipleAllowed: true,
                default: {
                  noRowsRightTable: 'Add supply sources to remove in this list',
                  selections: `(${context.rowSelections.length} line item${
                    context.rowSelections.length > 1 ? 's' : ''
                  } selected)`,
                  noRowsLeftTable: 'No supply sources found',
                  leftBtnLabel: 'Remove supply source',
                  rightBtnLabel: 'Cancel'
                },
                left: {
                  table: 'left',
                  tabHeader: 'Remove Supply Sources',
                  meta: {
                    type: 'table',
                    action: 'fetchTableDataExecuteApi',
                    localFilters: [
                      ...context?.rowSelections?.map(
                        ({ data: { dsp_line_items_line_item_id } }) => ({
                          dimensionName:
                            'dsp_outlier_supply_source_to_remove_line_item_id',
                          dimensionValue: dsp_line_items_line_item_id
                        })
                      )
                    ]
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      cubeName: 'dsp_outlier_supply_source_to_remove',
                      getLatestAvailableInsteadOfRollup: false,
                      timeseriesEnabled: false,
                      pvpenabled: false,
                      yoyenabled: false,
                      measuresList: [
                        'dsp_outlier_supply_source_to_remove_ss_name',
                        'dsp_outlier_supply_source_to_remove_line_item_name',
                        'dsp_outlier_supply_source_to_remove_line_item_id',
                        'dsp_outlier_supply_source_to_remove_ss_id',
                        'dsp_outlier_supply_source_to_remove_profile_id'
                      ],
                      groupByDimensionsList: [
                        'dsp_outlier_supply_source_to_remove_ss_id',
                        'dsp_outlier_supply_source_to_remove_line_item_id'
                      ],
                      orderByList: [],
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'line_items_remove_supply_source',
                      'left'
                    )
                  }
                },
                right: {
                  table: 'right',
                  searchKey: 'search',
                  meta: {
                    type: 'table',
                    localFilters: []
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'line_items_remove_supply_source',
                      'right'
                    )
                  }
                },
                apply(rows = []) {
                  return new Promise((resolve, reject) => {
                    const payload = rows.map((row) => ({
                      actionPayload: {
                        lineItemId:
                          row.dsp_outlier_supply_source_to_remove_line_item_id,
                        profileId:
                          row.dsp_outlier_supply_source_to_remove_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemRemoveInventorySources',
                        supplySources: [
                          row.dsp_outlier_supply_source_to_remove_ss_id
                        ]
                      },
                      viewPayload: {
                        lineItemId:
                          row.dsp_outlier_supply_source_to_remove_line_item_id,
                        profileId:
                          row.dsp_outlier_supply_source_to_remove_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemRemoveInventorySources',
                        supplySources:
                          row.dsp_outlier_supply_source_to_remove_ss_id,
                        sourceName:
                          row.dsp_outlier_supply_source_to_remove_ss_name
                      },
                      actionType: 'dspLineItemRemoveInventorySources',
                      widget: 'lineItem',
                      previousEntityValue: null,
                      newEntityValue: null,
                      primaryKey:
                        row.dsp_outlier_supply_source_to_remove_line_item_id,
                      actionSource: {
                        pageUrl: window.location.href
                      }
                    }));
                    HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                      .then((response) => {
                        that.$emit('afterAPIActionToCall');
                        resolve(true);
                      })
                      .catch((err) => {
                        that.openSnackbar('Something went wrong.');
                        reject(err);
                      });
                  });
                }
              };
            }
          },
          selections: context.rowSelections
        },
        events: {
          afterAPIActionToCall(payload) {
            context.openSnackbar(
              'Action has been submitted for the selected line items'
            );
            context.switchBlueBar('line item');
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
            context.recommendationFilter('');
            context.handleRecommendation({});
          },
          closePanelMethod() {
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
          }
        }
      },
      TARGET_NEW_AUDIENCE: {
        ui_component: 'customActionPanel',
        props: {
          staticHeaderText: 'Add audiences',
          widgetsConfig: {
            config: (that) => {
              // {that} is bound to actionsBar component
              return {
                primaryKey: 'customKey',
                createPrimaryKeyFrom: [
                  'dsp_tna_finalised_audience_segment_id',
                  'dsp_tna_finalised_audience_line_item_id'
                ],
                isMultipleAllowed: true,
                default: {
                  noRowsRightTable: 'Add audiences in this list',
                  selections: `(${context.rowSelections.length} line item${
                    context.rowSelections.length > 1 ? 's' : ''
                  } selected)`,
                  noRowsLeftTable: 'No line items found',
                  leftBtnLabel: 'Add audience',
                  rightBtnLabel: 'Cancel'
                },
                left: {
                  table: 'left',
                  tabHeader: 'Add Audiences',
                  meta: {
                    type: 'table',
                    action: 'fetchTableDataExecuteApi',
                    localFilters: [
                      ...context?.rowSelections?.map(
                        ({ data: { dsp_line_items_line_item_id } }) => ({
                          dimensionName:
                            'dsp_tna_finalised_audience_line_item_id',
                          dimensionValue: dsp_line_items_line_item_id
                        })
                      )
                    ]
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      cubeName: 'dsp_tna_finalised_audience',
                      getLatestAvailableInsteadOfRollup: false,
                      timeseriesEnabled: false,
                      pvpenabled: false,
                      yoyenabled: false,
                      measuresList: [
                        'dsp_tna_finalised_audience_segment',
                        'dsp_tna_finalised_audience_line_item_name',
                        'dsp_tna_finalised_audience_line_item_id',
                        'dsp_tna_finalised_audience_segment_id',
                        'dsp_tna_finalised_audience_profile_id'
                      ],
                      groupByDimensionsList: [
                        'dsp_tna_finalised_audience_segment_id',
                        'dsp_tna_finalised_audience_line_item_id'
                      ],
                      orderByList: [],
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'add_audience_rec',
                      'left'
                    )
                  }
                },
                right: {
                  table: 'right',
                  searchKey: 'search',
                  meta: {
                    type: 'table',
                    localFilters: []
                  },
                  footer: {
                    show: false
                  },
                  body: {
                    APIConfig: {
                      limit: 10,
                      page: 1
                    },
                    columnDefs: getColumnDefinition(
                      that,
                      'add_audience_rec',
                      'right'
                    )
                  }
                },
                apply(rows = []) {
                  return new Promise((resolve, reject) => {
                    const payload = rows.map((row) => ({
                      actionPayload: {
                        lineItemId: row.dsp_tna_finalised_audience_line_item_id,
                        profileId: row.dsp_tna_finalised_audience_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemAddAudiences',
                        segmentIds: getSegmentIdArray(
                          row.dsp_tna_finalised_audience_segment_id
                        )
                      },
                      viewPayload: {
                        lineItemId: row.dsp_tna_finalised_audience_line_item_id,
                        profileId: row.dsp_tna_finalised_audience_profile_id,
                        clientName: window.clientName,
                        actionType: 'dspLineItemAddAudiences',
                        segmentIds: row.dsp_tna_finalised_audience_segment_id,
                        audienceName: row.dsp_tna_finalised_audience_segment
                      },
                      actionType: 'dspLineItemAddAudiences',
                      widget: 'lineItem',
                      previousEntityValue: null,
                      newEntityValue: null,
                      primaryKey: row.dsp_tna_finalised_audience_line_item_id,
                      actionSource: {
                        pageUrl: window.location.href
                      }
                    }));
                    HttpService.post('AMS_ACTIONS_ACTION_LOG', payload)
                      .then((response) => {
                        that.$emit('afterAPIActionToCall');
                        resolve(true);
                      })
                      .catch((err) => {
                        that.openSnackbar('Something went wrong.');
                        reject(err);
                      });
                  });
                }
              };
            }
          },
          selections: context.rowSelections
        },
        events: {
          afterAPIActionToCall(payload) {
            context.openSnackbar(
              'Action has been submitted for the selected line items'
            );
            context.switchBlueBar('line item');
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
            context.recommendationFilter('');
            context.handleRecommendation({});
          },
          closePanelMethod() {
            context.showRecCustomComponent = false;
            context.handleSelectionChange([]);
          }
        }
      },
      UNDERPERFORMING_LINE_ITEMS: {
        ui_component: 'ApproveRec',
        props: {
          config: {
            heading: 'APPROVE RECOMMENDATIONS',
            'sub-heading': `${context.rowSelections.length} recommendation${
              context.rowSelections.length > 1 ? 's' : ''
            } selected`,
            leftBtnLabel: 'Confirm and approve',
            rightBtnLabel: 'Cancel',
            message: 'On approval, recommended action will be initiated.',
            apply: () => {
              const payloadArray = context.rowSelections.map((item) => {
                const actionPayload = {
                  lineItemId: item.data.dsp_line_items_line_item_id,
                  profileId: item.data.dsp_line_items_profile_id,
                  deliveryActivationStatus: 'INACTIVE',
                  clientName: window.clientName,
                  actionType: 'dspLineItemStatusChange'
                };
                return {
                  actionPayload,
                  actionType: 'dspLineItemStatusChange',
                  viewPayload: {
                    ...actionPayload,
                    previousStatus:
                      item.data.dsp_line_items_delivery_activation_status &&
                      upperCase(
                        item.data.dsp_line_items_delivery_activation_status
                      )
                  },
                  widget: 'lineItem',
                  previousEntityValue:
                    item.data.dsp_line_items_delivery_activation_status &&
                    upperCase(
                      item.data.dsp_line_items_delivery_activation_status
                    ),
                  newEntityValue: 'INACTIVE',
                  primaryKey: item.data.dsp_line_items_line_item_id,
                  actionSource: {
                    pageUrl: window.location.href
                  }
                };
              });
              HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                .then((response) => {
                  context.openSnackbar(
                    'Action has been submitted for the selected line items'
                  );
                  context.switchBlueBar('line item');
                  context.showRecCustomComponent = false;
                  context.handleSelectionChange([]);
                  context.recommendationFilter('');
                  context.handleRecommendation({});
                })
                .catch((err) => {
                  console.log(err);
                  context.openSnackbar('Something went wrong.');
                });
            },
            handleCancel: () => {
              context.showRecCustomComponent = false;
              context.handleSelectionChange([]);
            }
          }
        }
      },
      IMPROVE_ORDER_PACING_BASE_BID: {
        ...(context?.rowSelections?.length === 1
          ? {
              ui_component: 'ApproveRec',
              props: {
                config: {
                  heading: 'EDIT RECOMMENDED ACTION',
                  'sub-heading': '1 recommendation selected',
                  customComponent: {
                    ui_component: 'editBid',
                    props: {
                      config: (vueRef) => ({
                        heading: 'Edit line item base bid',
                        'sub-heading': '1 line item selected',
                        namelabel: 'Line Item',
                        nameValue:
                          context?.rowSelections?.[0]?.data
                            ?.dsp_line_items_line_item_name,
                        inputLabel: 'Line item Base Bid',
                        inputValue: extractBid(
                          context?.rowSelections?.[0]?.data
                            ?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        max: 500000,
                        min: 1,
                        leftBtnLabel: 'Confirm and approve',
                        rightBtnLabel: 'Cancel',
                        apply: () => {
                          const payloadArray = context.rowSelections.map(
                            (item) => {
                              const actionPayload = {
                                lineItemId:
                                  item.data.dsp_line_items_line_item_id,
                                profileId: item.data.dsp_line_items_profile_id,
                                baseSupplyBid: vueRef.updatedInputValue,
                                clientName: window.clientName,
                                actionType: 'dspLineItemBaseSupplyBidChange'
                              };
                              return {
                                actionPayload,
                                actionType: 'dspLineItemBaseSupplyBidChange',
                                viewPayload: {
                                  ...actionPayload,
                                  previousBid: Number(
                                    item?.data?.dsp_line_items_base_bid
                                  )
                                },
                                widget: 'lineItem',
                                previousEntityValue: Number(
                                  item?.data?.dsp_line_items_base_bid
                                ),
                                newEntityValue: vueRef.updatedInputValue,
                                primaryKey:
                                  item.data.dsp_line_items_line_item_id,
                                actionSource: {
                                  pageUrl: window.location.href
                                }
                              };
                            }
                          );
                          HttpService.post(
                            'AMS_ACTIONS_ACTION_LOG',
                            payloadArray
                          )
                            .then((response) => {
                              context.openSnackbar(
                                'Action has been submitted for the selected line items'
                              );
                              context.switchBlueBar('line item');
                              context.showRecCustomComponent = false;
                              context.handleSelectionChange([]);
                              context.recommendationFilter('');
                              context.handleRecommendation({});
                            })
                            .catch((err) => {
                              console.log(err);
                              context.openSnackbar('Something went wrong.');
                            });
                        },
                        handleCancel: () => {
                          context.showRecCustomComponent = false;
                          context.handleSelectionChange([]);
                        }
                      })
                    }
                  }
                },
                disableFooter: true
              }
            }
          : {
              ui_component: 'ApproveRec',
              props: {
                config: {
                  heading: 'APPROVE RECOMMENDATIONS',
                  'sub-heading': `${
                    context.rowSelections.length
                  } recommendation${
                    context.rowSelections.length > 1 ? 's' : ''
                  } selected`,
                  leftBtnLabel: 'Confirm and approve',
                  rightBtnLabel: 'Cancel',
                  message: 'On approval, recommended action will be initiated.',
                  apply: () => {
                    const payloadArray = context.rowSelections.map((item) => {
                      const actionPayload = {
                        lineItemId: item.data.dsp_line_items_line_item_id,
                        profileId: item.data.dsp_line_items_profile_id,
                        baseSupplyBid: extractBid(
                          item?.data?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        clientName: window.clientName,
                        actionType: 'dspLineItemBaseSupplyBidChange'
                      };
                      return {
                        actionPayload,
                        actionType: 'dspLineItemBaseSupplyBidChange',
                        viewPayload: {
                          ...actionPayload,
                          previousBid: Number(
                            item?.data?.dsp_line_items_base_bid
                          )
                        },
                        widget: 'lineItem',
                        previousEntityValue: Number(
                          item?.data?.dsp_line_items_base_bid
                        ),
                        newEntityValue: extractBid(
                          item?.data?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        primaryKey: item.data.dsp_line_items_line_item_id,
                        actionSource: {
                          pageUrl: window.location.href
                        }
                      };
                    });
                    HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                      .then((response) => {
                        context.openSnackbar(
                          'Action has been submitted for the selected line items'
                        );
                        context.switchBlueBar('line item');
                        context.showRecCustomComponent = false;
                        context.handleSelectionChange([]);
                        context.recommendationFilter('');
                        context.handleRecommendation({});
                      })
                      .catch((err) => {
                        console.log(err);
                        context.openSnackbar('Something went wrong.');
                      });
                  },
                  handleCancel: () => {
                    context.showRecCustomComponent = false;
                    context.handleSelectionChange([]);
                  }
                }
              }
            })
      },
      IMPROVE_ORDER_PACING_MAX_BID: {
        ...(context?.rowSelections?.length === 1
          ? {
              ui_component: 'ApproveRec',
              props: {
                config: {
                  heading: 'EDIT RECOMMENDED ACTION',
                  'sub-heading': '1 recommendation selected',
                  customComponent: {
                    ui_component: 'editBid',
                    props: {
                      config: (vueRef) => ({
                        heading: 'Edit line item max bid',
                        'sub-heading': '1 line item selected',
                        namelabel: 'Line Item',
                        nameValue:
                          context?.rowSelections?.[0]?.data
                            ?.dsp_line_items_line_item_name,
                        inputLabel: 'Line item Max Bid',
                        inputValue: extractBid(
                          context?.rowSelections?.[0]?.data
                            ?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        max: 500000,
                        min: 1,
                        leftBtnLabel: 'Confirm and approve',
                        rightBtnLabel: 'Cancel',
                        apply: () => {
                          const payloadArray = context.rowSelections.map(
                            (item) => {
                              const actionPayload = {
                                lineItemId:
                                  item.data.dsp_line_items_line_item_id,
                                profileId: item.data.dsp_line_items_profile_id,
                                maxSupplyBid: vueRef.updatedInputValue,
                                clientName: window.clientName,
                                actionType: 'dspLineItemMaxSupplyBidChange'
                              };
                              return {
                                actionPayload,
                                actionType: 'dspLineItemMaxSupplyBidChange',
                                viewPayload: {
                                  ...actionPayload,
                                  previousBid: Number(
                                    item?.data?.dsp_line_items_max_bid
                                  )
                                },
                                widget: 'lineItem',
                                previousEntityValue: Number(
                                  item?.data?.dsp_line_items_max_bid
                                ),
                                newEntityValue: vueRef.updatedInputValue,
                                primaryKey:
                                  item.data.dsp_line_items_line_item_id,
                                actionSource: {
                                  pageUrl: window.location.href
                                }
                              };
                            }
                          );
                          HttpService.post(
                            'AMS_ACTIONS_ACTION_LOG',
                            payloadArray
                          )
                            .then((response) => {
                              context.openSnackbar(
                                'Action has been submitted for the selected line items'
                              );
                              context.switchBlueBar('line item');
                              context.showRecCustomComponent = false;
                              context.handleSelectionChange([]);
                              context.recommendationFilter('');
                              context.handleRecommendation({});
                            })
                            .catch((err) => {
                              console.log(err);
                              context.openSnackbar('Something went wrong.');
                            });
                        },
                        handleCancel: () => {
                          context.showRecCustomComponent = false;
                          context.handleSelectionChange([]);
                        }
                      })
                    }
                  }
                },
                disableFooter: true
              }
            }
          : {
              ui_component: 'ApproveRec',
              props: {
                config: {
                  heading: 'APPROVE RECOMMENDATIONS',
                  'sub-heading': `${
                    context.rowSelections.length
                  } recommendation${
                    context.rowSelections.length > 1 ? 's' : ''
                  } selected`,
                  leftBtnLabel: 'Confirm and approve',
                  rightBtnLabel: 'Cancel',
                  message: 'On approval, recommended action will be initiated.',
                  apply: () => {
                    const payloadArray = context.rowSelections.map((item) => {
                      const actionPayload = {
                        lineItemId: item.data.dsp_line_items_line_item_id,
                        profileId: item.data.dsp_line_items_profile_id,
                        maxSupplyBid: extractBid(
                          item?.data?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        clientName: window.clientName,
                        actionType: 'dspLineItemMaxSupplyBidChange'
                      };
                      return {
                        actionPayload,
                        actionType: 'dspLineItemMaxSupplyBidChange',
                        viewPayload: {
                          ...actionPayload,
                          previousBid: Number(
                            item?.data?.dsp_line_items_max_bid
                          )
                        },
                        widget: 'lineItem',
                        previousEntityValue: Number(
                          item?.data?.dsp_line_items_max_bid
                        ),
                        newEntityValue: extractBid(
                          item?.data?.dsp_line_items_recommendation_data,
                          'rec_action',
                          context?.recFilter?.value
                        )?.next,
                        primaryKey: item.data.dsp_line_items_line_item_id,
                        actionSource: {
                          pageUrl: window.location.href
                        }
                      };
                    });
                    HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
                      .then((response) => {
                        context.openSnackbar(
                          'Action has been submitted for the selected line items'
                        );
                        context.switchBlueBar('line item');
                        context.showRecCustomComponent = false;
                        context.handleSelectionChange([]);
                        context.recommendationFilter('');
                        context.handleRecommendation({});
                      })
                      .catch((err) => {
                        console.log(err);
                        context.openSnackbar('Something went wrong.');
                      });
                  },
                  handleCancel: () => {
                    context.showRecCustomComponent = false;
                    context.handleSelectionChange([]);
                  }
                }
              }
            })
      }
    })
  }
};
