<template>
  <div>
    <modal
      v-if="showExitBulkSearchPopup"
      class="exit-bulk-search-modal"
    >
      <div slot="header">
        <div
          data-cy="exitBulkSearchHeading"
          class="u-font-size-1 u-font-weight-600 u-text-align-center u-spacing-mt-xxl"
        >
          Exit bulk search
        </div>
      </div>
      <div slot="body">
        <div
          class="u-display-flex u-flex-direction-column u-flex-align-items-center u-font-size-5 u-color-grey-light u-spacing-mb-m"
        >
          <div
            data-cy="exitBulkSearchInfo"
            class="u-font-size-3 u-text-align-center u-spacing-mv-l u-line-height-1-3"
          >
            All your currently populated inputs will be removed once you exit
            bulk search.
          </div>
          <div class="u-width-100">
            <rb-checkbox
              v-model="showBulkSearchExitModalAgain"
              class="u-flex-justify-content-center"
            >
              Don't show me again
            </rb-checkbox>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div
          class="u-display-flex u-flex-justify-content-center u-spacing-mb-xxl u-spacing-mt-l"
        >
          <rb-button
            :click-fn="handleCancelExitBulkSearch"
            :text="'Cancel'"
            :type="'filled'"
            :size="'m'"
            class="exit-bulk-search-cancel-btn"
          />
          <rb-button
            :click-fn="handleExitBulkSearch"
            :text="'Exit bulk search'"
            :type="'filled'"
            :size="'m'"
            class="u-spacing-ml-s"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/widgets/modal.vue';
export default {
  components: {
    modal
  },
  data() {
    return {
      showExitBulkSearchPopup: false,
      showBulkSearchExitModalAgain: false
    };
  },
  methods: {
    openBulkSearchExitPopup() {
      this.showExitBulkSearchPopup = true;
    },
    closeBulkSearchExitPopup() {
      this.showExitBulkSearchPopup = false;
    },
    handleExitBulkSearch() {
      this.$emit('handleExitBulkSearch', this.showBulkSearchExitModalAgain);
      this.closeBulkSearchExitPopup();
    },
    handleCancelExitBulkSearch() {
      this.closeBulkSearchExitPopup();
    }
  }
};
</script>

<style lang="css" scoped>
.exit-bulk-search-cancel-btn {
  color: #007cf6;
  border: 1px solid #007cf6;
  background-color: #fff;
  &[disabled] {
    opacity: 0.3;
    background-color: #fff;
  }
  &:hover {
    color: #007cf6;
    border: 1px solid #007cf6;
    background-color: #fff;
  }
}
</style>

<style lang="css">
.exit-bulk-search-modal > div {
  max-width: 550px !important;
}
</style>
