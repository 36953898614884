<template>
  <div
    :class="[containerClasses, 'u-spacing-p-m']"
    :style="{
      background: `linear-gradient(to right, ${bgColor} ${getValue}%, #fff 0%)`,
      color: labelColor
    }"
  >
    <data-cell
      v-if="!showCustomTippy"
      :container-classes="containerClasses"
      :params="params"
      :value="getLabel"
      :type="type"
      :na-text="naText"
      :na-tooltip="naTooltipComp"
    />
    <contentDiff
      v-else
      :content="getLabel"
      :params="params"
      :tippy="tippy"
    />
  </div>
</template>
<script>
import { isNull } from 'lodash';
import dataCell from '@/components/widgets/tableComponents/cells/dataCell.vue';
import contentDiff from '@/components/widgets/custom_widgets/content_diff.vue';

export default {
  name: 'PercentageBackgroundComponent',
  components: {
    dataCell,
    contentDiff
  },
  props: {
    type: {
      type: String,
      default: 'string'
    },
    params: {
      type: Object,
      default: null
    },
    label: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [Number, String],
      default: null
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 50
    },
    containerClasses: {
      type: String,
      default:
        'u-width-100 u-height-100 u-display-flex u-flex-align-items-center'
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: null
    },
    rangeColorMap: {
      type: Array,
      default: null
      // [{
      //   min: '70',
      //   max: '100',
      //   color: 'rgba(62, 169, 94, 0.1)'
      // }, {
      //   min: 40,
      //   max: 70,
      //   color: 'rgba(255, 168, 0, 0.1)'
      // },
      // {
      //   min: 0,
      //   max: 40,
      //   color: 'rgba(215, 38, 61, 0.1)'
      // }
      // ]
    },
    showNaLabel: {
      type: Boolean,
      default: false
    },
    contentScore: {
      type: Boolean,
      default: false
    },
    showCustomBgColor: {
      type: Boolean,
      default: false
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'top-start',
          delay: [500, 0],
          animation: 'scale',
          interactive: true,
          maxWidth: '200px',
          theme: 'click-to-expand',
          duration: [150, 0]
        };
      }
    },
    showCustomTippy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelData: null,
      bgColor: this.backgroundColor
    };
  },
  computed: {
    getLabel() {
      if (this.contentScore) {
        return (+parseFloat(this.label).toFixed(2))?.toString()?.concat('%');
      }
      return this.labelData;
    },
    naTooltipComp() {
      if (this.value == null || this.value === undefined) {
        return this.naTooltip;
      } else {
        return isNull(this.label) ? this.naTooltip : '';
      }
    },
    getValue() {
      return this.value;
    }
  },
  watch: {
    label(newVal) {
      this.labelData = newVal;
    }
  },
  created() {
    this.labelData = this.label;
    if (this.rangeColorMap?.length) {
      for (const e of this.rangeColorMap) {
        const comparedValue = parseInt(this.label);
        if (
          (comparedValue >= parseInt(e.min) &&
            comparedValue <= parseInt(e.max)) ||
          this.label?.toString()?.includes('No (') ||
          this.showCustomBgColor
        ) {
          this.bgColor = e.color;
        }
        this.labelData = this.label?.toString().match(/(Yes|No)/g)
          ? this.label?.toString()?.split('(')[1].split(')')[0].trim()
          : this.label;
      }
    }
  }
};
</script>
