<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column edit-budget-pacing"
  >
    <!-- Loader -->
    <div
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        {{ heading }} ({{ selections.length }} {{ subHeading
        }}{{ selections.length > 1 ? 's' : '' }}
        selected)
      </div>

      <!-- Main content comes below this -->

      <!-- Adjust line item budget container -->
      <div :class="{ 'u-spacing-pb-40px': !config.showCheckboxForBudget }">
        <!-- Adjust line item budget checkbox -->
        <div
          v-tippy="{ placement: 'top-start', arrow: false }"
          :title="
            shouldDisableBudgetType
              ? config.disabledBudgetTypeTooltipMessage
              : ''
          "
        >
          <div v-if="showCheckbox">
            <rb-checkbox
              v-model="isAdjustLineItemBudgetSelected"
              native-value="false"
              :disabled="shouldDisableBudgetType"
              class="u-font-weight-600 u-spacing-mb-s"
            >
              {{ title }}:
            </rb-checkbox>
          </div>
          <div
            v-else
            class="u-font-size-5 u-font-weight-600 u-spacing-pb-s u-font-weight-600"
          >
            {{ title }}:
          </div>
        </div>

        <!-- Adjust line item capped and uncapped selections -->
        <div
          class="u-line-height-1 u-display-flex"
          :class="
            disableCappedAndUncappedSelections
              ? 'disabled pointer-events-none'
              : ''
          "
        >
          <div
            v-for="item in config.adjustLineItemBudgetTypeSelection"
            :key="item.value"
          >
            <rb-radio
              v-model="adjustLineItemBudgetTypeSelection"
              :native-value="item.value"
              class="u-font-size-4 u-width-100 u-spacing-mr-m"
            >
              {{ item.title }}
            </rb-radio>
          </div>
        </div>
        <!-- Adjust line item budget with radioWithInput -->
        <div>
          <div
            v-if="config.showCheckboxForBudget"
            class="u-spacing-mt-l"
          >
            <rb-checkbox
              v-model="isCheckboxForBudgetSelected"
              native-value="false"
              class="u-min-width-320px u-font-weight-600 u-spacing-mb-s"
            >
              {{ config.secondHeading }}:
            </rb-checkbox>
          </div>
          <div
            class="u-display-flex u-spacing-mt-xl"
            :class="{
              'disabled pointer-events-none':
                disableCappedAdjustBudgetContents || disabledBudget,
              'u-spacing-mt-m': config.showCheckboxForBudget
            }"
          >
            <RadioSelectionWithInput
              input-box-heading="Budget:"
              :radio-config="config.adjustLineItemBudgetCappedOptions"
              :default-radio-selected="
                adjustLineItemBudgetCappedOptionsRadioDefault
              "
              :error-message="errorMessage"
              @onUpdate="updateLineItemBudget"
            />
          </div>
        </div>
      </div>

      <!-- Grey border in between -->
      <div
        v-if="showCheckbox && !config.showAdjustPacing"
        class="u-border-bottom u-border-color-grey-xxx-light u-border-width-s"
        style="width: 540px"
      />

      <!-- Adjust monthly cap and adjust daily cap containers -->
      <div
        v-if="showCheckbox && config.showMonthlyAndDailyCap"
        class="u-display-flex"
        :class="{ 'u-spacing-pt-40px': !config.showCheckboxForBudget }"
      >
        <!-- Adjust monthly cap container -->
        <div>
          <!-- Adjust monthly cap checkbox -->
          <div>
            <rb-checkbox
              v-model="isAdjustMonthlyCapSelected"
              native-value="false"
              class="u-font-weight-600 u-spacing-mb-s"
            >
              Adjust Monthly Cap:
            </rb-checkbox>
          </div>
          <!-- Radio boxes -->
          <div
            class="u-spacing-mt-s"
            :class="
              disableMonthlyCapContents ? 'disabled pointer-events-none' : ''
            "
          >
            <!-- Radio with input -->
            <div class="u-spacing-pb-m">
              <rb-radio
                v-model="adjustMonthlyCapRadioValue"
                native-value="input"
                class="u-font-size-5 u-width-100 u-spacing-mr-s"
              >
                <!-- Input box -->
                <div
                  class="u-display-flex"
                  :class="
                    disableMonthlyCapInputBox ? 'pointer-events-none' : ''
                  "
                >
                  <span
                    class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  >
                    $
                  </span>
                  <input
                    v-model="updatedMonthlyCappedBudget"
                    type="number"
                    min="0"
                    class="rb-input currency-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                    @input="onInputOfMonthlyCappedBudget"
                  />
                </div>
              </rb-radio>
            </div>
            <!-- None option -->
            <div>
              <rb-radio
                v-model="adjustMonthlyCapRadioValue"
                native-value="none"
                class="u-font-size-5 u-width-100 u-spacing-mr-s"
              >
                <div>None</div>
              </rb-radio>
            </div>
          </div>
        </div>

        <!-- Adjust daily cap container -->
        <div class="u-spacing-ml-xxxl">
          <!-- Adjust daily cap checkbox -->
          <div>
            <rb-checkbox
              v-model="isAdjustDailyCapSelected"
              native-value="false"
              class="u-font-weight-600 u-spacing-mb-s"
            >
              Adjust Daily Cap:
            </rb-checkbox>
          </div>
          <!-- Check boxes -->
          <div
            class="u-spacing-mt-s"
            :class="
              disableDailyCapContents ? 'disabled pointer-events-none' : ''
            "
          >
            <!-- Radio with input -->
            <div class="u-spacing-pb-m">
              <rb-radio
                v-model="adjustDailyCapRadioValue"
                native-value="input"
                class="u-font-size-5 u-width-100 u-spacing-mr-s"
              >
                <!-- Input box -->
                <div
                  class="u-display-flex"
                  :class="disableDailyCapInputBox ? 'pointer-events-none' : ''"
                >
                  <span
                    class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  >
                    $
                  </span>
                  <input
                    v-model="updatedDailyCappedBudget"
                    type="number"
                    min="0"
                    class="rb-input currency-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                    @input="onInputOfDailyCappedBudget"
                  />
                </div>
              </rb-radio>
            </div>
            <!-- Automatic option -->
            <div class="u-spacing-pb-m">
              <rb-radio
                v-model="adjustDailyCapRadioValue"
                native-value="automatic"
                class="u-font-size-5 u-width-100 u-spacing-mr-s"
                :class="
                  isAutomaticOptionDisabled
                    ? 'disabled pointer-events-none'
                    : ''
                "
              >
                <div>Automatic</div>
              </rb-radio>
            </div>

            <!-- None option -->
            <div>
              <rb-radio
                v-model="adjustDailyCapRadioValue"
                native-value="none"
                class="u-font-size-5 u-width-100 u-spacing-mr-s"
              >
                <div>None</div>
              </rb-radio>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="config.showAdjustPacing"
        class="u-spacing-mt-l"
      >
        <div>
          <!-- Adjust daily cap checkbox -->
          <div>
            <rb-checkbox
              v-model="isAdjustPacingSelected"
              native-value="false"
              class="u-font-weight-600"
            >
              Adjust Pacing:
            </rb-checkbox>
          </div>
          <!-- Check boxes -->

          <div
            class="u-spacing-mb-s"
            :class="!disableAdjustPacing ? 'disabled pointer-events-none' : ''"
          >
            <div
              v-for="item in config.adjustPacingOptions"
              :key="item.value"
            >
              <div class="u-spacing-pt-m">
                <rb-radio
                  v-model="adjustPacingSelection"
                  :native-value="item.value"
                  class="u-font-size-4 u-width-100 u-spacing-mr-m"
                >
                  {{ item.title }}
                </rb-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End date component -->
    <div
      v-if="
        noOfCampaignsWithoutEndDate > 0 &&
        adjustLineItemBudgetTypeSelection === 'lifetime' &&
        isAdjustLineItemBudgetSelected
      "
    >
      <div
        class="u-border-bottom u-color-grey-xx-light u-border-bottom-dotted u-border-width-s u-spacing-ph-l"
      ></div>
      <div class="u-spacing-ml-l u-spacing-mb-m">
        <div class="u-font-weight-600 u-font-size-5 u-spacing-mt-l">
          {{ noOfCampaignsWithoutEndDate }}/{{ selections.length }} Campaigns
          requires selection of end date which is mandatory for “lifetime”
          budget type
        </div>
        <div class="u-font-weight-600 u-font-size-5 u-spacing-mt-l">
          End Date:
        </div>
        <div
          v-click-outside="hideEndCalendar"
          class="u-spacing-mt-m"
        >
          <span
            class="filter--token u-font-size-6 u-border-radius-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('endDate')"
          >
            <rb-icon
              :icon="'calendar'"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-line-height-1_2">
              {{ endDate | dateFormatFilter }}</span
            >
          </span>
          <datepicker
            ref="endDate"
            v-model="endDate"
            style="left: 0"
            input-class="hide_calendar_strategy u-spacing-left-0"
            :disabled-dates="disableEndDates"
          />
        </div>
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pv-m u-spacing-ph-l"
    >
      <rb-button
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="applyButtonDisabled || !selections.length"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import config from '@/pages/campaign-management/retailers/criteo/line_items/EditBudgetAndPacing/editBudgetAndPacingConfig.js';
import RadioSelectionWithInput from '@/pages/campaign-management/retailers/criteo/line_items/RadioSelectionWithInput/index.vue';
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';

const criteoCampaignEditBudgetActionList = [
  'targetCampaignEditBudget',
  'costcoCampaignEditBudget',
  'freshdirect_criteoCampaignEditBudget',
  'meijerCampaignEditBudget',
  'shipt_criteoCampaignEditBudget',
  'walmart_criteoCampaignEditBudget',
  'walgreen_criteoCampaignEditBudget',
  'cvs_criteoCampaignEditBudget',
  'asda_criteoCampaignEditBudget',
  'ulta_criteoCommonCampaignEditBudget',
  'albertsons_criteoCommonCampaignEditBudget',
  'ubereats_criteoCommonCampaignEditBudget',
  'target_rmsCommonCampaignEditBudget'
];
export default {
  components: {
    RadioSelectionWithInput,
    loader,
    Datepicker
  },
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'Select End Date';
      return moment(value).format('MMM DD, YYYY');
    }
  },
  props: {
    showCheckbox: {
      type: Boolean,
      default: true
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    widgetsConfig: {
      type: Function,
      default: null
    },
    title: {
      type: String,
      default: 'Adjust Line Item Budget'
    },
    heading: {
      type: String,
      default: 'Adjust Line Item Budget and Pacing'
    },
    subHeading: {
      type: String,
      default: 'Line Item'
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      loader: false,
      config: null,
      endDate: null,
      noOfCampaignsWithoutEndDate: 0,
      isAdjustLineItemBudgetSelected: true,
      isCheckboxForBudgetSelected: true,
      isAdjustMonthlyCapSelected: false,
      isAdjustDailyCapSelected: false,
      isAdjustPacingSelected: false,
      // Data items for adjust line item budget
      adjustLineItemBudgetTypeSelection: null,
      adjustPacingSelection: null,
      adjustLineItemBudgetCappedOptionsRadioDefault: 'set_new_budget',
      updatedLineItemBudget: {},

      // data for adjust monthly cap
      adjustMonthlyCapRadioValue: 'none', // can be none/input
      updatedMonthlyCappedBudget: null,

      // data for adjust daily cap
      adjustDailyCapRadioValue: 'automatic', // can be none/automatic/input
      updatedDailyCappedBudget: null,

      errorMessage: null
    };
  },
  computed: {
    disableEndDates() {
      return {
        to: moment().toDate()
      };
    },
    isPromoteIQRetailer() {
      const retailer = this.$store.getters.getRetailer;
      return ['kroger_promoteiq', 'chewy_promoteiq'].includes(retailer);
    },
    isAutomaticOptionDisabled() {
      if (
        this.isAdjustDailyCapSelected === true &&
        this.selections.length === 1
      ) {
        const data = this.selections[0]?.data;
        const isEndDatePresent = !!data?.end_date;
        let isBudgetPresent = false;
        let isMonthlyCapPresent = false;

        // If capped or uncapped is selected, based on that select
        if (
          data?.budget ||
          (this.isAdjustLineItemBudgetSelected === true &&
            this.adjustLineItemBudgetTypeSelection === 'capped' &&
            this.updatedLineItemBudget?.inputValue)
        ) {
          isBudgetPresent = true;
        }

        // If adjust monthly cap is selected
        if (
          data?.monthly_pacing ||
          (this.isAdjustMonthlyCapSelected === true &&
            this.adjustMonthlyCapRadioValue === 'input' &&
            this.updatedMonthlyCappedBudget)
        ) {
          isMonthlyCapPresent = true;
        }

        return !((isEndDatePresent && isBudgetPresent) || isMonthlyCapPresent);
      }
      return false;
    },
    applyButtonDisabled() {
      if (this.config.leftBtnDisabled) {
        return this.config.leftBtnDisabled(this);
      }
      if (this.errorMessage) {
        return true;
      }
      if (
        this.isAdjustLineItemBudgetSelected === false &&
        this.isAdjustMonthlyCapSelected === false &&
        this.isAdjustDailyCapSelected === false
      ) {
        return true;
      }
      // If adjust line item is selected
      if (this.validateAdjustLineItemBudgetSelection()) {
        return true;
      }

      // If adjust monthly cap is selected
      if (this.validateAdjustMonthlyCap()) {
        return true;
      }

      // If adjust daily cap is selected
      if (this.validateAdjustDailyCap()) {
        return this.validateAdjustDailyCap();
      }
    },
    disableCappedAdjustBudgetContents() {
      return (
        (!this.isAdjustLineItemBudgetSelected &&
          !this.config?.showCheckboxForBudget) ||
        (this.adjustLineItemBudgetTypeSelection === 'uncapped' &&
          !this.config?.showCheckboxForBudget)
      );
    },
    disabledBudget() {
      return (
        this.config?.showCheckboxForBudget && !this.isCheckboxForBudgetSelected
      );
    },
    disableCappedAndUncappedSelections() {
      return this.isAdjustLineItemBudgetSelected === false;
    },
    disableMonthlyCapContents() {
      return this.isAdjustMonthlyCapSelected === false;
    },
    disableAdjustPacing() {
      return this.isAdjustPacingSelected;
    },
    disableDailyCapContents() {
      return this.isAdjustDailyCapSelected === false;
    },
    disableMonthlyCapInputBox() {
      return this.adjustMonthlyCapRadioValue !== 'input';
    },
    disableDailyCapInputBox() {
      return this.adjustDailyCapRadioValue !== 'input';
    },
    shouldDisableBudgetType() {
      const shouldDisable = !!this.config?.shouldDisableBudgetType?.(
        this.selections
      );
      if (shouldDisable) {
        this.isAdjustLineItemBudgetSelected = false;
      }
      return shouldDisable;
    }
  },
  watch: {
    selections(newVal) {
      if (!newVal?.length) {
        this.$emit('closePanelMethod');
      }
      this.setDataForSingleCampaign();
      this.checkForEndDates();
      this.checkForErrorsInInput();
    },
    isAdjustLineItemBudgetSelected(val) {
      this.checkForErrorsInInput();
      if (!val) this.endDate = null;
    },
    adjustLineItemBudgetTypeSelection(val) {
      this.checkForErrorsInInput();
      if (val === 'lifetime') {
        this.checkForEndDates();
      } else {
        this.endDate = null;
      }
    },
    updatedLineItemBudget() {
      this.checkForErrorsInInput();
    },
    isAutomaticOptionDisabled(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.adjustDailyCapRadioValue = 'none';
      }
    }
  },
  created() {
    this.config = this.widgetsConfig
      ? this.widgetsConfig(this)
      : config[this.retailer];
    this.adjustLineItemBudgetTypeSelection =
      this.config?.adjustLineItemBudgetTypeSelection?.[0]?.value;
    this.isAdjustLineItemBudgetSelected = !this.config?.showCheckboxForBudget;
    this.isAdjustPacingSelected = !this.config?.showCheckboxForBudget;
    this.adjustPacingSelection = this.config?.adjustPacingOptions?.[0]?.value;
    this.setDataForSingleCampaign();
  },

  methods: {
    setDataForSingleCampaign() {
      if (this.isPromoteIQRetailer) {
        if (this.selections.length === 1) {
          this.adjustPacingSelection = `${this.selections[0]?.data?.promoteiq_cm_campaign_pacing.toLowerCase()}`;
          this.adjustLineItemBudgetTypeSelection = `${this.selections[0]?.data?.promoteiq_cm_campaign_budget_interval.toLowerCase()}`;
        } else {
          this.adjustPacingSelection =
            this.config?.adjustPacingOptions?.[0]?.value;
          this.adjustLineItemBudgetTypeSelection =
            this.config?.adjustLineItemBudgetTypeSelection?.[0]?.value;
        }
      }
    },
    checkForEndDates() {
      this.noOfCampaignsWithoutEndDate = 0;
      this.selections.forEach((item) => {
        if (item?.data?.[`${this.config.endDateParam}`] === null) {
          this.noOfCampaignsWithoutEndDate++;
        }
      });
    },
    validateAdjustMonthlyCap() {
      if (this.isAdjustMonthlyCapSelected === true) {
        if (
          this.adjustMonthlyCapRadioValue === 'input' &&
          !this.updatedMonthlyCappedBudget
        ) {
          return true;
        }
      }
    },
    validateAdjustDailyCap() {
      if (this.isAdjustDailyCapSelected === true) {
        if (
          this.adjustDailyCapRadioValue === 'input' &&
          !this.updatedDailyCappedBudget
        ) {
          return true;
        }
      }
    },
    validateAdjustLineItemBudgetSelection() {
      if (this.isAdjustLineItemBudgetSelected === true) {
        // If capped or uncapped is selected, based on that select
        if (
          this.adjustLineItemBudgetTypeSelection === 'capped' &&
          !this.updatedLineItemBudget?.inputValue
        ) {
          return true;
        }
      }
    },
    hideEndCalendar() {
      this.$refs.endDate.close();
    },
    openCalendar(param) {
      this.$refs[param]?.showCalendar();
    },
    createActionPayload(item) {
      const prevBudget =
        criteoCampaignEditBudgetActionList.indexOf(this.actionType) !== -1
          ? item?.data?.total_budget
          : item?.data?.budget;
      const is_budget_change = this.isAdjustLineItemBudgetSelected;
      const is_monthly_pacing_change = this.isAdjustMonthlyCapSelected;
      const is_daily_pacing_change = this.isAdjustDailyCapSelected;
      const campaign_id = item?.data?.campaign_id;
      const campaign_name = item?.data?.campaign_name;
      let budget = null;
      let monthly_pacing = null;
      let daily_pacing = null;
      let is_auto_daily_pacing = false;
      // Calculate budget
      if (
        this.isAdjustLineItemBudgetSelected === true &&
        this.adjustLineItemBudgetTypeSelection === 'capped'
      ) {
        budget = this.calculateInputValue(
          this.updatedLineItemBudget,
          prevBudget
        );
      }
      if (
        this.isAdjustMonthlyCapSelected === true &&
        this.adjustMonthlyCapRadioValue === 'input'
      ) {
        monthly_pacing = this.updatedMonthlyCappedBudget;
      }
      if (this.isAdjustDailyCapSelected === true) {
        if (this.adjustDailyCapRadioValue === 'input') {
          daily_pacing = this.updatedDailyCappedBudget;
        }
        if (this.adjustDailyCapRadioValue === 'automatic') {
          is_auto_daily_pacing = true;
        }
      }
      if (criteoCampaignEditBudgetActionList.indexOf(this.actionType) !== -1) {
        return {
          actionType: this.actionType,
          budget,
          campaign_id,
          campaign_name
        };
      }
      return {
        actionType: this.actionType,
        line_item_id: item?.data?.line_item_id,
        is_budget_change,
        is_monthly_pacing_change,
        is_daily_pacing_change,
        budget,
        monthly_pacing,
        daily_pacing,
        is_auto_daily_pacing
      };
    },
    getBudgetPayload(data, actionPayload) {
      let new_budget_type = null;
      let old_budget = null;
      let new_budget = null;
      // If Adjust line item budget was selected, then form payload for it
      if (actionPayload.is_budget_change === true) {
        if (actionPayload.budget === null) {
          new_budget_type = 'Uncapped';
        } else {
          new_budget_type = 'Capped';
          old_budget = data?.budget
            ? this.convertToCurrencyFormat(data?.budget)
            : 'NA ';
          new_budget = this.convertToCurrencyFormat(actionPayload.budget);
        }
      }
      return { old_budget, new_budget, new_budget_type };
    },
    getPacingPayload(data, actionPayload) {
      let old_monthly_pacing = null;
      let new_monthly_pacing = null;
      let old_daily_pacing = null;
      let new_daily_pacing = null;

      // If adjust monthly pacing was selected, then form payload for it.
      if (actionPayload.is_monthly_pacing_change === true) {
        old_monthly_pacing = data?.monthly_pacing
          ? this.convertToCurrencyFormat(data?.monthly_pacing)
          : 'None';
        new_monthly_pacing = actionPayload.monthly_pacing
          ? this.convertToCurrencyFormat(actionPayload.monthly_pacing)
          : 'None';
      }

      // If adjust daily pacing was selected, then form payload for it.
      if (actionPayload.is_daily_pacing_change === true) {
        if (data?.is_auto_daily_pacing === true) {
          old_daily_pacing = 'Automatic';
        } else if (!data?.daily_pacing) {
          old_daily_pacing = 'None';
        } else {
          old_daily_pacing = this.convertToCurrencyFormat(data?.daily_pacing);
        }

        if (actionPayload.is_auto_daily_pacing === true) {
          new_daily_pacing = 'Automatic';
        } else if (!actionPayload.daily_pacing) {
          new_daily_pacing = 'None';
        } else {
          new_daily_pacing = this.convertToCurrencyFormat(
            actionPayload.daily_pacing
          );
        }
      }

      return {
        old_monthly_pacing,
        new_monthly_pacing,
        old_daily_pacing,
        new_daily_pacing
      };
    },
    createViewPayload(data, actionPayload) {
      const { old_budget, new_budget, new_budget_type } = this.getBudgetPayload(
        data,
        actionPayload
      );
      const {
        old_monthly_pacing,
        new_monthly_pacing,
        old_daily_pacing,
        new_daily_pacing
      } = this.getPacingPayload(data, actionPayload);
      const campaign_id = data?.campaign_id;
      const campaign_name = data?.campaign_name;

      if (criteoCampaignEditBudgetActionList.indexOf(this.actionType) !== -1) {
        return {
          actionType: this.actionType,
          budget: actionPayload.budget
            ? this.convertToCurrencyFormat(actionPayload.budget)
            : 'Uncapped',
          campaign_id,
          campaign_name,
          old_budget: data.total_budget
            ? this.convertToCurrencyFormat(data?.total_budget)
            : 'Uncapped'
        };
      }
      return {
        line_item_id: data?.line_item_id,
        line_item_name: data?.line_item_name,
        old_budget_type: null,
        new_budget_type,
        old_budget,
        new_budget,
        old_monthly_pacing,
        new_monthly_pacing,
        old_daily_pacing,
        new_daily_pacing
      };
    },
    convertToCurrencyFormat(value) {
      return Vue.options.filters.num_format(
        this.getDisplayValue(value),
        'currency'
      );
    },
    updateLineItemBudget(data) {
      this.updatedLineItemBudget = data;
    },
    onInputOfDailyCappedBudget() {
      // this ensures that the user does not enter a negative value
      this.updatedDailyCappedBudget =
        this.updatedDailyCappedBudget &&
        Math.abs(this.updatedDailyCappedBudget) >= 0
          ? Math.abs(this.updatedDailyCappedBudget)
          : null;
    },
    onInputOfMonthlyCappedBudget() {
      // this ensures that the user does not enter a negative value
      this.updatedMonthlyCappedBudget =
        this.updatedMonthlyCappedBudget &&
        Math.abs(this.updatedMonthlyCappedBudget) >= 0
          ? Math.abs(this.updatedMonthlyCappedBudget)
          : null;
    },
    calculateInputValue(data, budget) {
      let val = null;
      if (data?.inputType === 'dropdown') {
        if (budget === null && !data?.radioValue?.includes('set_new')) {
          return null;
        }
        const delta = budget * (data?.inputValue / 100);
        if (data?.radioValue?.includes('increase_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget + delta).toFixed(2))
              : parseFloat(data?.inputValue) + budget;
        } else if (data?.radioValue?.includes('decrease_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget - delta).toFixed(2))
              : budget - parseFloat(data?.inputValue);
        }
      } else if (data?.inputType === 'input') {
        val = data?.inputValue;
      }
      if (val < 0) {
        val = 0;
      }
      return val;
    },
    onClose() {
      this.$emit('closePanelMethod');
    },
    onApply() {
      if (this.config?.onLeftBtnClick) {
        this.config?.onLeftBtnClick();
        return;
      }
      let payload = [];
      payload = [...this.createPayload()];
      this.callAPI(payload);
    },
    callAPI(payload) {
      const name = 'line items(s)';
      const that = this;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted for the selected ' + name,
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.apiVersion
      );
    },
    createPayload() {
      const payload = [];
      this.selections.forEach((item) => {
        const actionPayload = this.createActionPayload(item);
        const viewPayload = this.createViewPayload(item?.data, actionPayload);
        const payloadObject = {
          widget: this.widget,
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: item?.data?.[this.primaryKey],
          actionPayload,
          viewPayload,
          previousEntityValue:
            String(item?.data?.budget) +
            ',' +
            String(item?.data?.monthly_pacing) +
            ',' +
            String(item?.data?.daily_pacing) +
            ',' +
            String(item?.data?.is_auto_daily_pacing),
          newEntityValue:
            String(actionPayload.budget) +
            ',' +
            String(actionPayload.monthly_pacing) +
            ',' +
            String(actionPayload.daily_pacing) +
            ',' +
            String(actionPayload.is_auto_daily_pacing)
        };
        payload.push(payloadObject);
      });
      return payload;
    },
    checkForErrorsInInput() {
      // That is, if the adjust line item budget is selected and if the option is capped, only then we need to check this condition
      if (
        this.isAdjustLineItemBudgetSelected &&
        this.adjustLineItemBudgetTypeSelection === 'capped' &&
        this.updatedLineItemBudget?.inputValue !== null &&
        this.updatedLineItemBudget?.inputValue !== undefined &&
        this.updatedLineItemBudget?.inputValue !== ''
      ) {
        const payloadArray = this.createPayload();
        const finalBudgetArray = payloadArray.map(
          (item) => item.actionPayload.budget
        );
        let countOfNull = 0;
        finalBudgetArray.forEach((item) => {
          if (item === null) {
            countOfNull++;
          }
        });
        if (countOfNull > 0) {
          const singularErrorMessage =
            'line item selected has null budget. Kindly set absolute value for the budget of that line item.';
          const pluralErrorMessage =
            'line items selected have null budgets. Kindly set absolute value for the budgets of those line items.';
          this.errorMessage = `${countOfNull} ${
            countOfNull > 1 ? pluralErrorMessage : singularErrorMessage
          }`;
          return;
        }
      }
      this.errorMessage = null;
    },
    openSnackbar(message) {
      this.$snackbar.open({
        message
      });
    }
  }
};
</script>

<style lang="css">
.edit-budget-pacing .vdp-datepicker__calendar {
  left: 0 !important;
}
.edit-budget-pacing .u-spacing-pb-40px {
  padding-bottom: 40px;
}
.edit-budget-pacing .u-spacing-pt-40px {
  padding-top: 40px;
}
.edit-budget-pacing .pointer-events-none {
  pointer-events: none;
}
.edit-budget-pacing .percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.edit-budget-pacing .percentBox .rb-input.currency-input {
  border-right: 0;
}
.edit-budget-pacing .appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.edit-budget-pacing .appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.edit-budget-pacing .rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  min-width: 90px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.edit-budget-pacing .rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
</style>
