import Vue from 'vue';
import moment from 'moment';
import { formatter } from '@/utils/helpers/formatter.js';

const numFormatter = function (
  num,
  prefixPassed,
  suffixPassed,
  minValToFormat,
  roundOff,
  applyFormat,
  selectedCurrency
) {
  if (applyFormat === false) {
    return num;
  }

  if (num === undefined || num === null) {
    return;
  }
  if (isNaN(num)) {
    return '---';
  }

  if (roundOff === undefined || isNaN(roundOff)) {
    roundOff = 2;
  }
  var prefix = prefixPassed === undefined ? '' : prefixPassed;
  // Temporary Fix for i18N Implementation - Don't remove this code
  if (prefixPassed === 'currency' || suffixPassed === 'currency') {
    return formatter(
      num,
      'currency',
      undefined,
      undefined,
      undefined,
      selectedCurrency
    );
  }
  var suffix = suffixPassed === undefined ? '' : suffixPassed;

  if (num.constructor === String) {
    num = parseFloat(num);
  }
  let returnString;
  if (num <= minValToFormat) {
    returnString = num.toFixed(roundOff);
    returnString = parseFloat(returnString);
    returnString = formatter(returnString, 'number');
    returnString = prefix + '' + returnString + '' + suffix;
    return returnString;
  }
  if (roundOff > 0) {
    // for back ward compatibility.
    // when roundOff > 0 we need to format number and show it in in K, M notations if possible.
    returnString = formatter(num, 'numeric', undefined, roundOff);
  } else {
    returnString = formatter(num, 'number', undefined, roundOff);
  }
  returnString = prefix + '' + returnString + '' + suffix;
  return returnString;
};

const printableDate = function (date) {
  var days = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return (
    days[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
  );
};

const nullCheck = function (value, defaultValue) {
  if (value === null || value === undefined) {
    value = defaultValue;
  }
  return value;
};

const configCheck = function (key, configs) {
  key = key.split('.');
  var subtree = configs;
  for (var i = 0; i < key.length; i++) {
    if (subtree.hasOwnProperty(key[i])) {
      subtree = subtree[key[i]];
      if (typeof subtree === 'object') {
        if (subtree.hasOwnProperty('enable')) {
          if (subtree.enable.toString().toLowerCase() !== 'true') {
            return false;
          }
        }
      }
    } else {
      return null;
    }
  }
  if (typeof subtree === 'string') {
    if (subtree.toString().toLowerCase() === 'true') {
      return true;
    } else if (subtree.toString().toLowerCase() === 'false') {
      return false;
    } else {
      return subtree;
    }
  } else {
    return subtree;
  }
};

window.configCheck = configCheck;

Vue.filter('config_check', function (key) {
  if (window.configs && window.configs.common && window.configs.client) {
    var commonCheck = configCheck(key, window.configs.common);
    var clientCheck = configCheck(key, window.configs.client);

    if (clientCheck === null) {
      if (commonCheck === null) {
        return false;
      } else {
        return commonCheck;
      }
    } else {
      return clientCheck;
    }
  } else {
    return false;
  }
});

Vue.filter('first_caps', function (string, convertToLower) {
  if (!string || string.constructor !== String) {
    return '';
  }

  if (string.toLowerCase() === 'na') {
    return 'NA';
  }

  if (!isNaN(parseFloat(string.replace(Vue.prototype.$currency, '')))) {
    return string;
  }

  if (convertToLower) {
    string = string.toLowerCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter(
  'num_format',
  function (
    num,
    prefixPassed,
    suffixPassed,
    minValToFormat,
    roundOff,
    applyFormat,
    selectedCurrency
  ) {
    return numFormatter(
      num,
      prefixPassed,
      suffixPassed,
      minValToFormat,
      roundOff,
      applyFormat,
      selectedCurrency
    );
  }
);

Vue.filter('null_check', function (value, defaultValue) {
  return nullCheck(value, defaultValue);
});

Vue.filter('printable_date', function (date) {
  if (date) {
    return printableDate(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    );
  } else {
    return null;
  }
});

Vue.filter('formattable_date', function (date) {
  if (date) {
    return moment(date, ['MM/DD/YYYY']).format('MMM DD, YYYY');
  } else {
    return null;
  }
});
