<template>
  <div
    class="bulk-search-overview-popup"
    :class="{ 'u-display-none': !showSearchOverviewPopup }"
  >
    <bulk-search-overview-header
      :bulk-search-popup-props="bulkSearchPopupProps"
      @closeSearchOverviewPopup="closeSearchOverviewPopup"
    />
    <slot name="custom-bulk-type" />
    <bulk-search-entity-search
      ref="bulkSearchEntitySearch"
      :bulk-search-popup-props="bulkSearchPopupProps"
      @highlightEntitiesOnSearch="highlightEntitiesOnSearch"
      @exitEditMode="exitEditMode"
    />
    <textarea
      v-if="isTextAreaEnabled"
      id="bulkSearchInput"
      v-model="pastedText"
      :placeholder="bulkSearchPopupProps.textAreaPlaceholder"
      class="bulk-searched-textarea"
    />
    <div
      v-else
      id="bulkSearchTermsPreview"
      class="bulk-searched-entity-list-preview"
      @click="enableTextArea"
    >
      <div v-if="entitiesToPreview.length">
        <div
          v-for="entity in entitiesToPreview"
          :key="entity.id"
        >
          <rb-icon
            v-if="entity.isDuplicate"
            icon="warning"
            class="u-color-orange-base rb-icon--small u-cursor-pointer"
          />
          <span
            v-tippy="{
              placement: 'top',
              arrow: false,
              interactive: true,
              distance: 5,
              size: 'regular',
              maxWidth: '300px'
            }"
            :class="{ 'u-spacing-pl-xs': entity.isDuplicate }"
            :title="getTooltipTitle(entity.isDuplicate)"
          >
            <!-- eslint-disable -->
            <span
              class="u-spacing-mr-s u-font-size-5"
              v-html="entity.entityValue"
            />
            <!-- eslint-disable -->
          </span>
        </div>
      </div>
      <div v-else>
        <div class="u-color-grey-lighter">
          Type or Paste inputs, each of them seperated with a new line
        </div>
      </div>
    </div>
    <bulk-search-overview-footer
      :search-overview-entity-list="searchOverviewEntityList"
      :max-paste-limit="bulkSearchPopupProps.maxPasteLimit"
      :invalid-entity-count="invalidEntityCount"
      :is-text-area-enabled="isTextAreaEnabled"
      @handleViewInvalidEntitiesClick="handleViewInvalidEntitiesClick"
      @handleDeleteInvalidEntitiesClick="handleDeleteInvalidEntitiesClick"
    />
    <bulk-search-overview-actions
      ref="bulkSearchOverviewActions"
      :pasted-text="pastedText"
      :pastedTextWhileSearchApplied="previouslySearchedText"
      @handleClearAll="handleClearAll"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { getParsedList } from '@/components/basic/bulk-search/bulkSearchUtils';
import BulkSearchOverviewHeader from '@/components/basic/bulk-search/bulkSearchOverview/BulkSearchOverviewHeader.vue';
import BulkSearchOverviewFooter from '@/components/basic/bulk-search/bulkSearchOverview/BulkSearchOverviewFooter.vue';
import BulkSearchEntitySearch from '@/components/basic/bulk-search/bulkSearchOverview/BulkSearchEntitySearch.vue';
import BulkSearchOverviewActions from '@/components/basic/bulk-search/bulkSearchOverview/BulkSearchOverviewActions.vue';

const RANDOMSTRINGFORSPLIT = '<%%>';
const ALTERNATERANDOMSTRINGFORSPLIT = '<>';

export default {
  components: {
    BulkSearchOverviewHeader,
    BulkSearchOverviewFooter,
    BulkSearchOverviewActions,
    BulkSearchEntitySearch
  },
  props: {
    bulkSearchPopupProps: {
      type: Object,
      default: () => ({})
    },
    currentStandardSearchedTerm: {
      type: String,
      default: ''
    },
    previouslySearchedText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showSearchOverviewPopup: false,
      pastedText: '',
      isTextAreaEnabled: true,
      entitiesToPreview: [],
      entitiesToPreviewClone: [],
      searchOverviewEntityList: []
    };
  },
  computed: {
    invalidEntityCount() {
      return this.searchOverviewEntityList.filter(
        (entity) => entity.isDuplicate
      ).length;
    }
  },
  watch: {
    pastedText: {
      handler(value) {
        this.searchOverviewEntityList = getParsedList(value);
        this.getEntitiesToPreview();
      },
      deep: true,
      immediate: true
    },
    currentStandardSearchedTerm: {
      handler(value) {
        if (typeof value === 'string') {
          this.pastedText = value;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    if (this.previouslySearchedText) {
      this.pastedText = this.previouslySearchedText;
    }
  },
  methods: {
    closeSearchOverviewPopup() {
      this.showSearchOverviewPopup = false;
    },
    openBulkSearchPopup() {
      this.showSearchOverviewPopup = true;
    },
    handleClearPopup() {
      this.pastedText = '';
      this.handleClearBulkSeachEntitySearchedTerm();
    },
    handleClearBulkSeachEntitySearchedTerm() {
      this.$refs.bulkSearchEntitySearch?.handleClearBulkSeachEntitySearchedTerm();
    },
    enableTextArea() {
      this.isTextAreaEnabled = true;
    },
    scrollToBottomOfPreview() {
      setTimeout(() => {
        const previewNode = document.getElementById('bulkSearchTermsPreview');
        previewNode.scrollTop = previewNode.scrollHeight;
      });
    },
    getTooltipTitle(isDuplicate) {
      if (isDuplicate) {
        return 'Duplicate input';
      }
    },
    getEntitiesToPreview() {
      const pastedTextArray = this.sortPastedText(this.pastedText);
      this.entitiesToPreview = pastedTextArray;
      this.entitiesToPreviewClone = cloneDeep(this.entitiesToPreview);
    },
    sortPastedText(text) {
      const tempArr = getParsedList(text);
      tempArr.sort((x, y) => {
        return x.isDuplicate === y.isDuplicate ? 0 : this.sortIfDuplicate(x);
      });
      return tempArr;
    },
    sortIfDuplicate(item) {
      return item.isDuplicate ? 1 : -1;
    },
    concatPastedText(tempArr) {
      let tempPastedText = '';
      tempArr.forEach((item) => {
        tempPastedText += `${item.entityValue}\n`;
      });
      this.pastedText = tempPastedText;
    },
    handleSearchHighlight(text, searchText) {
      // highlight the searched text in yellow color
      let str = searchText;
      let reg = new RegExp(str, 'gi');
      return text?.replace(reg, (str) => {
        return `<mark class="searched-term">${str}</mark>`;
      });
    },
    removeDuplicatesFromEntities() {
      this.searchOverviewEntityList = this.searchOverviewEntityList.filter(
        (item) => !item.isDuplicate
      );
      this.entitiesToPreview = this.searchOverviewEntityList.filter(
        (item) => !item.isDuplicate
      );
      this.entitiesToPreviewClone = cloneDeep(this.entitiesToPreview);
      this.concatPastedText(this.entitiesToPreview);
    },
    highlightEntitiesOnSearch(searchText) {
      let tempEntitiesToPreviewClone = cloneDeep(this.entitiesToPreviewClone);
      const newVal = tempEntitiesToPreviewClone.map((entity) => {
        entity.entityValue = this.handleSearchHighlight(
          entity.entityValue,
          searchText
        );
        return entity;
      });
      this.entitiesToPreview = newVal;
    },
    handleClearAll() {
      this.handleClearBulkSeachEntitySearchedTerm();
      this.isTextAreaEnabled = true;
    },
    handleViewInvalidEntitiesClick() {
      this.isTextAreaEnabled = false;
      const tempArr = this.sortPastedText(this.pastedText);
      this.concatPastedText(tempArr);
      this.scrollToBottomOfPreview();
    },
    handleDeleteInvalidEntitiesClick() {
      this.removeDuplicatesFromEntities();
      this.isTextAreaEnabled = true;
    },
    exitEditMode() {
      this.isTextAreaEnabled = false;
    }
  }
};
</script>

<style scoped lang="css">
.bulk-search-overview-popup {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  padding: 2.4rem;
  top: 40px;
  width: 464px;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  z-index: 10;
}
.bulk-search-overview-popup .bulk-searched-entity-list-preview,
.bulk-search-overview-popup .bulk-searched-textarea {
  display: inline-block;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  margin-bottom: 4px;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  padding: 8px;
  font-size: 14px;
  resize: none;
  white-space: nowrap;
  line-height: 1.5;
  cursor: text;
}
.bulk-search-overview-popup .bulk-searched-entity-list-preview .highlight,
.bulk-search-overview-popup .bulk-searched-textarea .highlight {
  background-color: yellow;
}
.bulk-search-overview-popup .bulk-searched-textarea::placeholder {
  font-size: 14px;
  color: #8b8f93;
}
.bulk-search-overview-popup .search-actions {
  margin-top: 16px;
}
.bulk-search-overview-popup textarea {
  font-family: inherit;
}
.bulk-search-overview-popup textarea:focus {
  outline: none;
}
</style>

<style lang="css">
.bulk-search-overview-popup .rb-button-icon-12 .rb-icon:before {
  font-size: 12px !important;
}
</style>
