var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ul',{staticClass:"u-display-flex u-bg-color-grey-white u-bg-color-grey-white u-border-width-s",class:_vm.computedClasses,style:(_vm.tabGap)},_vm._l((_vm.tabsList),function(tab,index){return _c('li',{key:index,staticClass:"tab",class:{
        underline: tab === _vm.currentTab && _vm.underLine,
        'u-spacing-pt-l': _vm.size === 'm'
      }},[_c('span',{staticClass:"u-cursor-pointer",class:{
          'u-color-blue-base u-font-weight-bold': tab === _vm.currentTab,
          'u-font-size-5': _vm.size === 'm',
          'u-font-size-7': _vm.size === 's'
        },attrs:{"data-cy":("tabName-" + tab)},on:{"click":function($event){return _vm.handleTabsClick(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }