import { sponsoredBrands } from '@/components/ams/campaign_creation/setup_configs/walmart/sponsoredBrands/index.js';
import {
  manual,
  auto
} from '@/components/ams/campaign_creation/setup_configs/walmart/sponsoredProducts/index.js';

export default {
  sponsoredBrands,
  sponsoredProductsManual: manual,
  sponsoredProductsAuto: auto
};
