const flattenMenu = function (list) {
  return list.reduce((flat, toFlatten) => {
    if (toFlatten.children) {
      toFlatten.children = toFlatten.children.map((v) => ({
        ...v,
        parent: toFlatten
      }));
    }
    return flat.concat(
      toFlatten.children ? flattenMenu(toFlatten.children) : toFlatten
    );
  }, []);
};

const findMenuPath = function (item, path) {
  // console.log(item, path);
  return (
    item?.filter((element, index) => {
      if (
        element.fullPath?.substring(
          element.fullPath.length,
          element.fullPath.length - 1
        ) === '/'
      ) {
        element.fullPath = element.fullPath.substring(
          0,
          element.fullPath.length - 1
        );
      }
      if (path?.substring(path.length, path.length - 1) === '/') {
        path = path.substring(0, path.length - 1);
      }
      return element.fullPath === path;
    })[0] || []
  );
};

const generateFullPath = (array, target) => {
  if (target) {
    if (target.substring(target.length, target.length - 1) === '/') {
      target = target.substring(0, target.length - 1);
    } else {
      target = target + '/';
    }
    return array.map((item) => {
      if (
        item?.path?.substring(item?.path.length, item?.path.length - 1) !== '/'
      ) {
        item.path = item.path + '/';
      }
      if (target.substring(target.length, target.length - 1) !== '/') {
        target = target + '/';
      }
      item.fullPath = target + (item.path !== '/' ? item.path : '');
      if (item.children) {
        const temp = (item.children = generateFullPath(
          item.children,
          item.fullPath
        ));
        item.children = temp;
        return item;
      }
      return item;
    });
  }
  return array;
};

export { flattenMenu, findMenuPath, generateFullPath };
