import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import columns from './columnConfigs';

const skuComponent = Vue.extend({
  data() {
    return {
      showComponent: true,
      enableSDP: false
    };
  },
  computed: {
    rbSkuData() {
      return {
        title: this.params.data.title,
        imageUrl: this.params.data.image_url,
        asin: this.params.data.asin,
        productUrl: this.params.data.product_url,
        cacheLink: this.params.data.cache_link,
        scrapeDate: this.params.data.scrape_date,
        showImage: true
      };
    }
  },
  methods: {
    navigateToSDP(asin) {
      this.$router.push({ path: `/dp/${asin}` });
    }
  },
  template: `<div class="u-display-flex u-text-overflow-ellipsis">
    <rb-sku 
      :show-image="rbSkuData.showImage"
      :title="rbSkuData.title"
      :image-url="rbSkuData.imageUrl"
      :asin="rbSkuData.asin"
      :product-page-url="rbSkuData.productUrl"
      :enableSDP="enableSDP" 
      @navigateToSDP="navigateToSDP" >
    </rb-sku>
  </div>`
});

const availabilityComponent = Vue.extend({
  data() {
    return {
      bgColorList: {
        Available: '#edf6ef',
        Unavailable: '#fbe9ec',
        'No Data': '#ffffff'
      },
      iconColor: {
        Available: 'u-color-grey-lighter',
        Unavailable: 'u-color-grey-lighter',
        'No Data': 'u-color-grey-xxx-light'
      }
    };
  },
  computed: {
    status() {
      const paramData = this.params.data[this.params.column?.colDef?.field];
      const paramPercentage =
        typeof this.params.data[
          `average_${this.params.column?.colDef?.field}_percentage`
        ] === 'number'
          ? `${
              this.params.data[
                `average_${this.params.column?.colDef?.field}_percentage`
              ]
            } %`
          : 'No Data';
      if (!paramData) {
        return {};
      } else {
        return {
          instock_percentage: paramPercentage,
          title: paramData.status,
          cacheUrl: paramData.cache_url,
          bgColor: this.bgColorList[paramData.status],
          iconColor: this.iconColor[paramData.status],
          showErrorIcon: paramData.status === 'No Data'
        };
      }
    },
    tooltipText() {
      const paramData = this.params.data[this.params.column?.colDef?.field];
      const asin = this.params.data.asin;
      const zipcodeData = this.params.title;
      if (paramData?.status === 'NA') {
        return 'The SKU is not sold in this zip code.';
      } else if (paramData?.status === 'No Data') {
        return `Latest Availability Status as per the selected dates is not available for "${asin}" at zipcode "${zipcodeData}" as there was a problem in scraping the product page.`;
      }
    }
  },
  methods: {},
  template: `<div
  :style="{ background: status.bgColor }"
  v-if="status"
  class="u-display-flex u-flex-direction-column u-text-overflow-ellipsis u-width-100 u-height-100 u-spacing-pt-m u-spacing-pl-l u-spacing-pr-m">
  <div class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center">
    <div class="u-display-inline-flex u-flex-align-items-center">
      <span v-if="status.showErrorIcon" class="u-spacing-mr-s">
        <rb-icon
          :icon="'error-fill'"
          class="rb-icon--medium u-color-orange-base"
        />
      </span>
      <span v-tippy :title="tooltipText">
        <span class="u-font-size-6 u-line-height-2 u-color-grey-base">
          {{status.title}}
        </span>
      </span>
    </div>
    <div
      class="u-display-inline-flex"
      v-if="['No Data', 'NA'].includes(status.title)"
    >
      <rb-icon
        :icon="'cached'"
        class="rb-icon--medium u-color-grey-xxx-light u-cursor-pointer"
      />
    </div>
    <div
      v-else
      class="u-display-inline-flex"
      v-tippy
      title="View Cached Amazon Detail Page."
    >
      <a :href="status.cacheUrl" target="_blank">
        <rb-icon
          :icon="'cached'"
          class="rb-icon--medium u-color-grey-lighter u-cursor-pointer cached-icon-hover"
          :class="status.iconColor"
        />
      </a>
    </div>
  </div>
  <div style="white-space: nowrap; display: block; clear: both">
    <span class="u-font-size-6 u-line-height-2 u-color-grey-base"
      >({{ status.instock_percentage }})
    </span>
  </div>
</div>`
});

// Generate Ag-grid Column Format for a single column
function generateColumnDef(column, vueRef) {
  const obj = {};
  obj.headerComponentFramework = 'genericTableHeader';
  let keyType = 'string';
  if (column.type === 'number') {
    keyType = 'number';
  }
  if (column.type === 'numeric') {
    keyType = column.unit ? column.unit : 'numeric';
  }
  if (column.type === 'date') {
    keyType = column.type;
  }
  obj.field = column.key;
  obj.cellRendererParams = {
    keyType: keyType,
    formatterFn: column.formatReq === false ? undefined : formatter
  };
  obj.cellRendererFramework = 'genericTableCell';
  obj.headerName = column.title;
  obj.title = column.title;
  obj.headerComponentParams = {
    enableSorting: true
  };
  if (column.hasOwnProperty('hide')) {
    obj.hide = column.hide;
  }
  if (column.hasOwnProperty('enableSorting')) {
    obj.headerComponentParams.enableSorting = false;
  }
  if (column.type === 'asin') {
    // obj.cellRendererFramework = 'RbSku';
    obj.cellRendererFramework = skuComponent;
    obj.headerComponentParams.enableSorting = false;
    obj.cellRendererParams = column;
    obj.cellRendererParams.hoverTextClickEvent = vueRef.addToSearch;
    obj.minWidth = 300;
  } else if (column.component && column.component === 'availability') {
    obj.cellRendererFramework = availabilityComponent;
    obj.cellRendererParams = column;
  } else if (column.component && column.component === 'histogram') {
    const histogramConfig = {
      enableHistogram: true,
      historyKey: column.key,
      inverted: false,
      customColorClass: function () {
        return 'u-histogram-custom-color-blue u-histogram-custom-z-index';
      }
    };
    obj.cellRendererParams.histogramConfig = histogramConfig;
  }

  if (column.type !== 'asin') {
    obj.headerComponentParams.alignHeader = 'left';
    obj.minWidth = 200;
  }
  obj.keyOrder = column.keyOrder;
  column.pinned && (obj.pinned = column.pinned);
  if (column.showToolTip) {
    obj.headerComponentParams.tooltipCustomTitle =
      column?.title?.split('<')?.[0];
    obj.headerComponentParams.toolTipText = column.toolTipText;
    obj.headerComponentParams.removeSplit = true;
  }
  obj.headerComponentParams.keyType = keyType;
  return obj;
}

var columnDefs = [];
function getColumnDefinition(vueRef, tableKey, columns) {
  const _columnDefs = [];
  for (let i = 0; i < columns.length; i++) {
    _columnDefs.push(generateColumnDef(columns[i], vueRef));
  }

  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

const getMaxDateDashboard = (that) => {
  let maxDate = that.$store.getters.getMaxDate;
  maxDate =
    (maxDate.budget_planner_dashboard &&
      maxDate.budget_planner_dashboard.max_feed_date) ||
    'NA';
  maxDate =
    maxDate === 'NA' ? maxDate : Vue.options.filters.formattable_date(maxDate);
  return maxDate;
};

export default {
  config: (that) => {
    return {
      footer: {
        getMaxDateDashboard: getMaxDateDashboard(that),
        date: 'fresh_regional_availability'
      },
      filters: {
        emit: 'filterApplied',
        listen: {
          filterApplied: {
            action: 'regionalAvailability/setSelectedFilters'
          }
        },
        hasSearch: true,
        search: {
          enable: true,
          emit: 'regionalSearchTriggered',
          width: '240px',
          placeholder: 'Search ASIN or ASIN Name'
        }
      },
      widgets: {
        widget1: {
          meta: {
            widgetId: 'widget1',
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'regionalAvailability/getTableData',
            key: 'RegionalAvailabilityTable',
            listen: {
              filterApplied: {
                action: 'regionalAvailability/getTableData',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  _config.body.APIConfig.page = 1;
                  return config;
                }
              },
              regionalSearchTriggered: {
                action: 'regionalAvailability/getData',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            },
            localFilters: []
          },
          header: {
            search: true,
            searchPlaceholder: 'Search for SKUs',
            searchParams: ['search'],
            title: '',
            actions: {
              download: {
                includeDate: true,
                fileName: 'RegionalAvailability',
                allMap: [
                  {
                    displayName: 'SKU Details',
                    dbKey: 'asin'
                  },
                  {
                    displayName: 'Title',
                    dbKey: 'title'
                  },
                  {
                    displayName: 'In Stock Percentage',
                    dbKey: 'in_stock_percentage'
                  }
                ],
                action: 'regionalAvailability/download',
                transform: function (config, data, that) {
                  // Generating the colmap from the store which has the column mapping
                  const allZipcodeColumn = JSON.parse(
                    JSON.stringify(
                      that.$store.getters[
                        'regionalAvailability/getMappedColumnHeaders'
                      ]
                    )
                  );
                  config.header.actions.download.colMap = allZipcodeColumn.map(
                    (item, index) => {
                      item.displayName = item.downloadFileLocationDisaplyName;
                      item.dbKey = `average_${item.key}_percentage`;
                      item.key = item.dbKey;
                      return item;
                    }
                  );
                  config.header.actions.download.colMap.unshift(
                    ...config.header.actions.download.allMap
                  );
                  var _config = Object.assign({}, config);
                  _config = JSON.parse(JSON.stringify(_config));
                  delete _config.body.APIConfig.page;
                  delete _config.body.APIConfig.limit;
                  return _config;
                }
              }
            }
          },
          footer: {
            // date: 'regionalAvailability/getLastDate'
          },
          body: {
            defaultOrderBy: [],
            APIConfig: {
              cubeName:
                'fresh_regional_availability_dasboard_instock_percentage',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'count',
                'asin',
                'title',
                'image_url',
                'product_url',
                'in_stock_percentage',
                'ordered_revenue_last_30days',
                'FEED_DATE',
                'crawl_date'
              ],
              groupByDimensionsList: ['asin'],
              timeseriesDimension: 'FEED_DATE',
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  []
              },
              where: {},
              orderByList: [
                {
                  dimension: 'ORDERED_REVENUE_LAST_30DAYS',
                  direction: 'DESC'
                }
              ],
              limit: 30,
              page: 1
            }
          }
        },
        widget2: {
          meta: {
            widgetId: 'widget2',
            action: 'regionalAvailability/getPincodeMapping',
            key: 'RegionalAvailabilityTableMapPinCodes'
          },
          header: {},
          footer: {
            date: ''
          },
          body: {
            defaultOrderBy: [],
            APIConfig: {
              cubeName: 'fresh_regional_availability_metadata',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: ['count'],
              groupByDimensionsList: [
                'zipcode_label',
                'zipcode',
                'city',
                'state',
                'fc_code'
              ],
              timeseriesDimension: 'client_id',
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  []
              },
              where: {},
              orderByList: [],
              limit: 50,
              page: 1
            }
          }
        }
      }
    };
  },
  columns,
  getColumnDefs: getColumnDefinition
};
