<template>
  <div class="u-display-flex u-text-overflow-ellipsis sku-with-rating">
    <rb-sku
      class="u-text-overflow-ellipsis"
      :show-image="showImage"
      :title="title"
      :image-url="imageUrl"
      :asin="asin"
      :product-page-url="productPageUrl"
      :enable-s-d-p="enableSDP"
      :rb-asin-tippy-text="pdpTooltip"
      @navigateToSDP="navigateToSDP"
    >
      <div
        v-if="showRating"
        slot="sku-context"
        class="u-spacing-p-0 u-spacing-mt-0"
      >
        <ratingStars
          :text-class="'u-font-size-7 u-spacing-pt-xxs'"
          :count="parseInt(latestTotalReviews)"
          :star="latestAvgReviewRating"
          :icon-size="iconSize"
          :active-class="activeClass"
          :inactive-class="inactiveClass"
        />
      </div>
    </rb-sku>
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import ratingStars from '@/components/basic/ratingStars.vue';

export default {
  components: {
    ratingStars
  },
  props: {
    title: {
      type: String,
      default: null
    },
    showImage: {
      type: Boolean,
      default: true
    },
    asin: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    },
    productPageUrl: {
      type: String,
      default: null
    },
    latestTotalReviews: {
      type: Number,
      default: 0
    },
    latestAvgReviewRating: {
      type: Number,
      default: 0
    },
    enableSDP: {
      type: Boolean,
      default: true
    },
    activeClass: {
      type: String,
      default: 'u-color-yellow-base'
    },
    inactiveClass: {
      type: String,
      default: 'u-color-grey-xxx-light'
    },
    iconSize: {
      type: String,
      default: 'rb-icon--medium'
    },
    pdpTooltip: {
      type: String,
      default: 'View Walmart detail page'
    },
    showRating: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style lang="css">
.sku-with-rating .bottom-row {
  margin-top: 0.4rem;
}
</style>
