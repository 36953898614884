<template>
  <div>
    <loader
      v-if="isLoading"
      :loading="isLoading"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      class="u-spacing-p-xl u-display-flex u-flex-direction-column u-flex-justify-content-center u-flex-align-items-center"
    >
      <span class="u-font-size-1 u-font-weight-600"> Retail Link Connect</span>
      <icon-text
        :icon="'info-circle-fill'"
        :container-class="'u-spacing-ml-m u-spacing-mt-m'"
        :icon-classes="'u-color-grey-lighter rb-icon--medium'"
        :text="'It will take upto 24 hours to establish connection and start to ingest data.'"
        :text-classes="'u-font-size-4 u-color-grey-lighter u-spacing-mt-xxs'"
      />
      <form
        class="u-width-400px u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
        @submit.prevent="submit()"
      >
        <div class="u-spacing-mt-xl">
          <div class="u-spacing-mt-l">
            <label
              class="u-display-block u-font-size-6 u-font-weight-600 u-color-grey-mid-light"
              >Login ID</label
            >
            <input
              v-model="loginId"
              class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
              :class="{ error: loginId === '' }"
              placeholder="tim@emailclient.com"
              type="text"
              name="loginId"
            />
            <div class="u-spacing-mt-s">
              <label
                class="u-display-block u-font-size-6 u-font-weight-600 u-color-grey-mid-light"
                >Password</label
              >
              <input
                v-model="password"
                class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                :class="{ error: password === '' }"
                placeholder="Please keep it to yourself"
                type="password"
                name="password"
              />
            </div>
          </div>
          <iconText
            v-if="showErrorText"
            :icon="'cross-fill-circle'"
            :text-classes="'u-spacing-p-0 u-color-red-base u-font-size-5'"
            :icon-classes="'u-color-red-base rb-icon--small'"
            :text="'Missing credentials. Please provide the loginId/password'"
          />
          <div class="u-spacing-mt-l">
            <rb-button
              :click-fn="submit"
              class="u-width-100"
              :text="'Submit'"
              :type="'filled'"
            />
          </div>
          <input
            class="u-display-none"
            type="submit"
            value="ok"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import iconText from '@/components/basic/iconText.vue';
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';

export default {
  components: {
    iconText,
    loader
  },
  data() {
    return {
      password: null,
      loginId: null,
      showErrorText: false,
      isLoading: false
    };
  },
  methods: {
    async submit() {
      if (!this.loginId?.trim()?.length || !this.password?.trim()?.length) {
        this.showErrorText = true;
        return;
      }
      this.showErrorText = false;
      this.isLoading = true;
      const data = {
        loginId: this.loginId,
        password: this.password,
        username: this.$store.getters.getSessionUser?.email
      };
      try {
        await HttpService.post('RETAIL_LINK_CONNECT', data);
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!',
          duration: 5000,
          actionText: ''
        });
      } finally {
        this.isLoading = false;
        this.$snackbar.open({
          message: 'Credentials Saved Successfully !',
          duration: 5000,
          actionText: ''
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
input {
  width: 382px;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}
input:focus {
  border-color: #007cf6;
}

.link:hover {
  color: #007cf6;
  text-decoration: none;
}
</style>
