<template>
  <div class="sla-tracker-table-wrapper">
    <div
      class="u-bg-color-grey-white u-display-flex u-width-100 u-height-100 u-flex-direction-column"
    >
      <div
        class="u-display-flex u-spacing-ph-l u-spacing-pb-m u-header-box-shadow u-spacing-mb-0"
        style="z-index: 100"
      >
        <PageTitle title="SLA Tracker" />
        <div
          class="u-flex-justify-content-flex-end u-flex-grow-1 u-spacing-pr-xl"
        >
          <rb-filter-panel
            :listener-event="'sla-tracker-dashboard-filters'"
            :new-date="true"
            :max-date="currentDate"
            :apply-filter="applyFilter"
            :default-date-post-range="selectedDateRange"
            :date-mode="dateMode"
            :hide-add-filter-button="true"
          >
          </rb-filter-panel>
        </div>
      </div>
    </div>
    <div class="u-display-flex u-flex-justify-content-center">
      <div class="u-bg-color-grey-white u-width-70">
        <div
          class="u-display-flex u-flex-direction-row u-height-35 u-margin-top-24"
        >
          <div
            v-for="tab in tabs"
            :key="tab.id"
            class="u-cursor-pointer u-font-size-18 u-font-weight-500 u-color-black-base u-display-flex u-flex-justify-content-center u-width-100"
            :class="{
              'sla-tracker-active-tab': selectedTab && selectedTab.id === tab.id
            }"
            @click="selectTab(tab)"
          >
            {{ tab.name }}
          </div>
        </div>
        <hr class="u-color-black-base" />
        <div
          v-if="loadingData"
          class="u-min-height-500 u-position-relative u-height-100 u-bg-color-grey-white"
        >
          <loader
            :loading="loadingData"
            class="loader--expand"
            color="#007cf6"
            size="3rem"
            thickness=".2rem"
          />
        </div>
        <div
          v-if="!loadingData"
          class="u-display-flex u-flex-justify-content-center"
        >
          <div class="u-width-100">
            <div class="u-height-100">
              <rb-insights-table
                class="u-display-flex u-flex-direction-column sla-tracker-table"
                custom-height="false"
                :get-current-instance="setSlaTableInstance"
                :grid-options="gridOptions"
                :table-column="columns"
                :table-row="rows"
                :row-height="rowHeight"
                :header-height="rowHeight"
                no-rows-message="No Results."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import slaTrackerCell from '@/components/pages/ciq-internal-tools/product-sla/slaTrackerCell.vue';
import loader from '@/components/basic/loader';
import { getColumnDefs, transformRowData } from './product-sla-utils';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
const DAYS_PER_WEEK = 7;

export default {
  components: {
    rbFilterPanel,
    loader
  },
  data() {
    return {
      currentDate: moment().format('MM/DD/YYYY'),
      selectedDateRange: 'last7Days',
      selectedDatefrom: moment()
        .subtract(DAYS_PER_WEEK, 'days')
        .format('YYYY-MM-DD'),
      selectedDateTo: moment().format('YYYY-MM-DD'),
      dateMode: {
        mode: 'day',
        count: 0
      },
      tabs: {
        ESM: {
          id: 'esm',
          name: 'ESM',
          active: false
        },
        RMM: {
          id: 'rmm',
          name: 'RMM',
          active: false
        }
      },
      selectedTab: null,
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        }
      },
      loadingData: false,
      columns: [],
      rows: [],
      rowHeight: 55,
      tabToKeyMapping: {
        esm: 'esmKeys',
        rmm: 'rmmKeys'
      },
      esmKeys: [],
      rmmKeys: [],
      slaTableInstance: null
    };
  },
  created() {
    Vue.component('SlaTrackerCell', slaTrackerCell);
    this.getColumnsForEachTab();
    this.selectTab(this.tabs.ESM);
  },
  methods: {
    setSlaTableInstance(instance) {
      this.slaTableInstance = instance;
    },
    getColumnsForEachTab() {
      const tabKeys = Object.values(this.tabToKeyMapping);
      tabKeys.forEach((tabKey) => {
        const keys =
          Vue.options.filters.config_check(
            `feature.slaTrackerDashboard.${tabKey}`
          ) || '';
        this[tabKey] = keys.split(',');
      });
    },
    selectTab(tab) {
      const previousTab = this.selectedTab;
      if (previousTab === null || previousTab !== tab) {
        if (previousTab !== null) {
          previousTab.active = false;
        }
        tab.active = true;
        this.selectedTab = tab;
        this.handleTabChange(tab);
      }
    },
    async applyFilter(date) {
      this.selectedDateRange = date.date_range.name;
      this.selectedDatefrom = date.date_range.from;
      this.selectedDateTo = date.date_range.to;
      await this.getSlaBreachedData(this.selectedTab.id);
      this.slaTableInstance.adjustColumns();
    },
    async handleTabChange(tab) {
      const keyProperty = this.tabToKeyMapping[tab.id];
      if (keyProperty) {
        const columnHealthGroupKeys = this[keyProperty] || [];
        this.columns = getColumnDefs(columnHealthGroupKeys);
        await this.getSlaBreachedData(tab.id);
        this.slaTableInstance.adjustColumns();
      } else {
        console.error(`No key property found for tab: ${tab.id}`);
      }
    },
    async getSlaBreachedData(tabId) {
      const healthGroupKeys = this.tabToKeyMapping[tabId];
      const keyValues = this[healthGroupKeys];
      this.loadingData = true;
      try {
        const response = await HttpService.post('GET_SLA_BREACH_STATUS', {
          date_range: {
            start_date: this.selectedDatefrom,
            end_date: this.selectedDateTo
          },
          clients: [],
          retailer: '',
          health_group_keys: keyValues
        });
        this.rows = transformRowData(response.data, keyValues);
      } catch (e) {
        this.$snackbar.open({
          message: 'Error encountered while fetching SLA Breach data',
          duration: 2000,
          actionText: 'Failed'
        });
        console.error('Error encountered while fetching SLA Breach data: ', e);
      } finally {
        this.loadingData = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.sla-tracker-active-tab {
  border-bottom: 5px solid black;
}
</style>
