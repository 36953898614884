var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"u-font-size-6 u-display-flex u-color-grey-base u-flex-direction-row u-border-radius-l u-cursor-pointer split-tabs",class:{ 'u-border-radius-xxl': _vm.isTabsList || _vm.size === 'm' }},[(!_vm.isTabsList)?_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-text-align-center",class:{
          'isHighlighted tabBoxShadow': _vm.tab1Active,
          'hover-inactive': !_vm.tab1Active,
          'u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s': _vm.size === 'm',
          'u-border-radius-xl u-spacing-ph-m u-spacing-pv-xs': _vm.size === 's'
        },on:{"click":function($event){return _vm.tabClicked(_vm.tab1)}}},[_vm._t("tag1-pre"),_c('span',[_vm._v(_vm._s(_vm.tab1))])],2),_c('div',{staticClass:"u-border-radius-xl u-spacing-ph-l u-spacing-pv-s u-text-align-center",class:{
          'isHighlighted tabBoxShadow': !_vm.tab1Active,
          'hover-inactive': _vm.tab1Active,
          'u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s': _vm.size === 'm',
          'u-border-radius-xl u-spacing-ph-m u-spacing-pv-xs': _vm.size === 's'
        },on:{"click":function($event){return _vm.tabClicked(_vm.tab2)}}},[_c('span',[_vm._v(_vm._s(_vm.tab2))])])]):_vm._e(),_vm._l((_vm.tabsList),function(tab){return (_vm.isTabsList)?_c('section',{key:tab},[_c('div',{staticClass:"u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s u-text-align-center",class:{
          'isHighlighted tabBoxShadow': _vm.computedActiveTab === tab,
          'hover-inactive': _vm.computedActiveTab !== tab
        },on:{"click":function($event){$event.stopPropagation();return _vm.tabClicked(tab)}}},[_c('span',{attrs:{"data-cy":'tab-' + tab}},[_vm._v(_vm._s(tab))])])]):_vm._e()})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }