<template>
  <section v-if="categoryListFetched">
    <rb-filter
      ref="cva-category-filter"
      class="u-text-case-title"
      :anchor="categoryFilterConfig.filterKey"
      :config="categoryFilterConfig"
      :show-notification="false"
      :multi="false"
      :use-local-storage="false"
      :hide-clear-all-btn="true"
      :is-always-on-filter="true"
      :secondary-data="categoryFilterOptions"
      :apply="updateCategory"
      :default-filters="selectedCategoryValue"
      :filter-list-tooltip-fn="(title) => title"
      :show-dynamic-tooltip="false"
      :get-title="getTitle"
      :hide-remove-btn="true"
      :max-string-length="24"
    />
    <rb-filter
      v-if="hasSubCategory"
      ref="cva-sub-category-filter"
      class="u-text-case-title"
      :anchor="subCategoryFilterConfig.filterKey"
      :config="subCategoryFilterConfig"
      :add-filter-text="subCategoryFilterConfig.addFilterText"
      :show-notification="false"
      :multi="false"
      :use-local-storage="false"
      :hide-clear-all-btn="true"
      :is-always-on-filter="true"
      :secondary-data="subCategoryFilterOptions"
      :default-filters="selectedSubCategoryValue"
      :apply="updateSubCategory"
      :max-string-length="24"
    />
  </section>
</template>

<script>
import filter from '@/components/widgets/filter/filter.vue';

export default {
  name: 'CategoryFilter',
  components: {
    rbFilter: filter
  },
  props: {
    categoryFilterOptions: {
      type: Object,
      default: () => {}
    },
    selectedCategory: {
      type: Object,
      default: () => {}
    },
    selectedSubCategoryValue: {
      type: Object,
      default: () => {}
    },
    getTitle: {
      type: Function,
      default: (item) => {
        return item.title;
      }
    }
  },
  data() {
    return {
      categoryListFetched: false,
      selectedCategoryValue: {},
      subCategoryFilterOptions: {}
    };
  },
  computed: {
    categoryFilterConfig() {
      return {
        filterKey: 'category',
        title: 'Category'
      };
    },
    subCategoryFilterConfig() {
      return {
        filterKey: 'sub_category',
        title: 'Sub Category',
        addFilterText: 'Sub Category'
      };
    },
    hasSubCategory() {
      const length = (this.selectedCategory?.subCategories || []).length;
      if (length === 0) {
        return false;
      }
      const subCategoriesValues = [];
      this.selectedCategory?.subCategories.forEach((subCat) => {
        subCategoriesValues.push({
          title: subCat.name,
          enable: true
        });
      });
      this.subCategoryFilterOptions = {
        sub_category: {
          values: subCategoriesValues
        }
      };
      return true;
    }
  },
  mounted() {
    this.getSelectedCategory();
  },
  methods: {
    getSelectedCategory() {
      this.selectedCategoryValue = {
        values: {
          category: [this.selectedCategory.requestParam]
        }
      };
      this.categoryListFetched = true;
    },
    updateCategory(data) {
      this.$emit('apply', data, 'category');
    },
    updateSubCategory(data) {
      this.$emit('apply', data, 'sub_category');
    }
  }
};
</script>
