<template>
  <div
    class="u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center u-position-relative u-width-100 real-time-header-header-rmm"
    :class="[params.enableSorting ? 'u-cursor-pointer' : '']"
    @click="customSort()"
  >
    <customToolTip
      class="u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center"
      :class="cellAlignment"
      :tippy="{
        placement: 'bottom',
        offset: null
      }"
    >
      <div slot="toolTipData">
        <div class="u-spacing-p-m">
          <div class="u-spacing-pb-m">
            {{ title }}
          </div>
          <div
            :class="
              metadata.intervalId || !metadata.dataFetched
                ? 'u-spacing-pb-m'
                : ''
            "
            class="u-word-break u-font-size-6 u-font-weight-400"
          >
            {{ toolTipText }}
          </div>
          <div
            v-if="metadata.intervalId || !metadata.dataFetched"
            class="u-color-blue-base u-flex-align-items-center u-display-flex"
          >
            <rb-icon
              class="rb-icon--xx-medium u-color-blue-base u-spacing-pr-s"
              icon="info-circle-fill"
            ></rb-icon>

            {{ realtimeMessage }}
          </div>
        </div>
      </div>

      <div
        slot="body"
        slot-scope="{ tippyConfig }"
      >
        <div
          v-tippy="tippyConfig"
          class="header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600 u-color-grey-lighter"
        >
          <span class="text-label u-flex-align-items-center">
            {{ title }}
          </span>
        </div>
      </div>
    </customToolTip>
    <div
      v-if="metadata.intervalId || !metadata.dataFetched"
      class="real-time-border-laoder"
      @click="clickHeader"
    ></div>
  </div>
</template>

<script>
import customToolTip from '@/components/widgets/customToolTip.vue';
export default {
  name: 'RealTimeMetadataHeader',
  components: {
    customToolTip
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    getter: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: 'NA'
    },
    type: {
      type: String,
      default: undefined
    },
    toolTipText: {
      type: String,
      default: 'NA'
    }
  },
  data() {
    return {};
  },
  computed: {
    realtimeMessage() {
      return `Real-time ${this.title} retrieval, updates may take up to 2 minutes`;
    },
    metadata() {
      return this.$store.getters[this.getter];
    },
    cellAlignment() {
      const rightAlignedKeys = ['numeric'];
      return rightAlignedKeys.includes(this.type.toLowerCase())
        ? 'u-flex-justify-content-flex-end'
        : 'u-flex-justify-content-flex-start';
    }
  },
  methods: {
    customSort() {
      this.$nextTick(() => {
        this.params?.enableSorting &&
          this.params?.onColumnSort &&
          this.params?.onColumnSort(this.params.column);
      });
      if (!this.params?.enableSorting) {
        return;
      }
      this.params?.progressSort();
    },
    clickHeader(event) {
      if (this.params?.clickHeader) {
        this.params?.clickHeader(this, event);
      }
    }
  }
};
</script>
