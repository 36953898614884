<template>
  <div>
    <!-- <img class="u-position-absolute login-logo-svg" style="top:40px; right:40px;" src="/images/logo.svg"/> -->
    <div class="u-display-flex page-body u-height-100vh">
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center bg-blue-base-gradient"
      >
        <div
          class="u-display-flex u-flex-justify-content-center u-color-grey-white u-width-100"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-align-items-center"
          >
            <div>
              <img
                style="max-width: 537px"
                src="/images/welcome.png"
              />
            </div>
            <div class="u-spacing-mt-xxl u-font-size-2 u-font-weight-bold">
              MACHINE POWERED, HUMAN EMPOWERED!
            </div>
            <div
              class="login-long-message u-spacing-mt-m u-font-size-4 u-text-align-center"
            >
              Save time and drive sustainable growth by minimizing revenue
              leakage and maximizing sales opportunities.
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        <div class="u-display-flex u-width-400px">
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-font-size-4 u-spacing-mb-s">Welcome to</div>
            <div
              class="u-font-size-1 u-font-weight-bold u-spacing-mb-xl u-color-grey-light"
            >
              <img
                style="width: 200px"
                src="/images/logo.svg"
              />
            </div>
            <div class="u-spacing-mt-m u-font-size-3 u-font-weight-600">
              Forgot password?
            </div>
            <div class="u-spacing-mt-s u-font-size-5">
              Don’t worry, we are here to help!
            </div>
            <form
              class="u-width-400px"
              @submit.prevent="passwordResetLink()"
            >
              <div class="u-spacing-mt-l">
                <label class="u-display-block u-font-size-6 u-font-weight-600"
                  >Email ID</label
                >
                <input
                  ref="email_input"
                  v-model="username"
                  class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                  :class="{ error: usernameTouched && username === '' }"
                  placeholder="tim@emailclient.com"
                  type="text"
                  name="username"
                  @blur="usernameTouched = true"
                />
                <label
                  v-if="usernameTouched && username === ''"
                  class="u-color-red-base u-font-size-7"
                  >Please enter an email ID.</label
                >
              </div>
              <div
                v-if="errorMessage"
                class="u-display-flex u-color-red-base u-spacing-mt-l u-flex-align-items-center"
              >
                <div><rb-icon icon="cross-fill-circle" /></div>
                <div class="u-spacing-ml-xs u-font-size-7">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="u-spacing-mt-l">
                <rb-button
                  :click-fn="passwordResetLink"
                  class="u-width-100"
                  :text="'Send an email with a reset link'"
                  :loading="passwordResetLinkLoading"
                  :disabled="passwordResetLinkLoading || username === ''"
                  :type="'filled'"
                />
              </div>
              <input
                class="u-display-none"
                type="submit"
                value="ok"
              />
            </form>
            <div
              v-if="successMessage"
              class="u-display-flex u-color-green-base u-spacing-mt-l u-flex-align-items-center"
            >
              <div><rb-icon icon="check-fill-circle" /></div>
              <div class="u-spacing-ml-xs u-font-size-7">
                {{ successMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import urlHelper from '@/utils/helpers/url';
import moment from 'moment-timezone';
export default {
  data() {
    return {
      username: '',
      usernameTouched: false,
      errorMessage: null,
      passwordResetLinkLoading: false,
      successMessage: null
    };
  },
  computed: {
    msg() {
      return this.$route.query.interror;
    }
  },
  mounted() {
    const currentUrl = window.location.href;
    const autoFillEmail = urlHelper.getURLParamValue(currentUrl, 'email');
    this.username = autoFillEmail;
    this.$refs.email_input.focus();
    if (
      this.msg === 'the code has expired' ||
      this.msg === 'the code is invalid'
    ) {
      this.errorMessage =
        "Sorry, it seems your link has expired or broken. Don't worry, you can request a new link here.";
    }
  },
  methods: {
    passwordResetLink() {
      const timezone = moment.tz.guess();
      const registerParams = {
        username: this.username,
        timezone
      };
      this.passwordResetLinkLoading = true;
      this.errorMessage = null;
      this.successMessage = null;
      this.$store.dispatch('passwordResetLink', registerParams).then(
        (response) => {
          this.passwordResetLinkLoading = false;
          if (response.data.status === 'success') {
            this.successMessage = response.data.message;
          } else {
            this.errorMessage = response.data.message;
          }
        },
        (error) => {
          this.passwordResetLinkLoading = false;
          if (error.response) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = 'something went wrong. Please try again later.';
          }
        }
      );
    }
  }
};
</script>

<style lang="css" scoped>
input {
  width: 382px;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}
input:focus {
  border-color: #007cf6;
}
</style>
