<template>
  <RbSelectV2
    :ref="`${refId}-select`"
    class="time-drop-down typeSelect u-spacing-ml-s"
    :on-close="onMonthChange"
    :options="monthOptions"
    :search-enabled="false"
    :send-details="true"
    :context="[refId]"
    :search-enable-threshold="100"
  >
    <div
      slot="trigger"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
      @click="onTrigger"
    >
      <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
        <div class="u-display-flex u-flex-align-items-flex-end">
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-mr-s u-color-grey-lighter"
            icon="calendar"
          />
          <span class="u-font-size-5">{{ selectedMonth }}</span>
        </div>
      </div>
      <rb-icon
        class="rb-icon--medium u-spacing-mr-s u-color-grey-lighter"
        :icon="'caret-down'"
      />
    </div>
    <template
      slot="item"
      slot-scope="option"
    >
      <div
        ref="option"
        class="u-display-flex u-flex-align-items-center u-font-size-5"
      >
        {{ option.title }}
      </div>
    </template>
  </RbSelectV2>
</template>
<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
export default {
  components: {
    RbSelectV2
  },
  props: {
    selectedMonth: {
      type: String,
      default: ''
    },
    monthOptions: {
      type: Array,
      default: []
    },
    refId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        document.addEventListener('mousedown', this.handleClickOutside);
      } else {
        this.stopEventListener();
      }
    }
  },
  beforeDestroy() {
    this.stopEventListener();
  },
  methods: {
    onMonthChange(context, selections) {
      if (selections[0]) {
        this.$emit('onMonthChange', selections[0]);
      }
    },
    handleClickOutside({ target }) {
      if (this.isOpen) {
        const tippyInstance = this.$refs[`${this.refId}-select`].tippyInstance;
        if (tippyInstance?.popper && !tippyInstance?.popper?.contains(target)) {
          tippyInstance.hide();
          this.isOpen = false;
        }
      }
    },
    onTrigger() {
      this.isOpen = true;
    },
    stopEventListener() {
      this.isOpen = false;
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
};
</script>

<style scoped lang="css">
.time-drop-down {
  min-width: 80px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: #fff;
}
.time-drop-down.typeSelect {
  width: 130px;
}
</style>
