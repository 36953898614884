<template>
  <div class="progressWrapper">
    <div
      v-if="showValue === true"
      class="u-spacing-mr-s percentContainer u-color-grey-base"
    >
      {{ fillPercent }}
    </div>
    <div
      class="u-display-flex progress u-width-100"
      :style="gutterStyleObj"
    >
      <div
        v-if="!tooltip"
        class="progress__filler"
        :style="fillerStyleObj"
        @click="fillerClicked()"
      />
      <div v-if="tooltip">
        <!-- <rb-tooltip slot="trigger" :label="tooltipText" :position="tooltipPosition" @click="fillerClicked()"> -->
        <div
          v-tippy="{
            html: '#progressToolTip',
            theme: 'rb-tooltip-sku',
            placement: 'bottom',
            interactive: true,
            reactive: true,
            arrow: false
          }"
          class="progress__filler"
          :style="fillerStyleObj"
        />
        <!-- </rb-tooltip> -->
      </div>
      <!-- <div class="" id="progressToolTip">
        {{toolTipText}}
      </div> -->
    </div>
  </div>
</template>

<style lang="css">
.progressWrapper {
  width: 100%;
  display: inline-flex;
}
.progressWrapper .percentContainer {
  min-width: 50px;
  text-align: right;
}
.progress {
  background: #e9eaeb;
  overflow: hidden;
  border-radius: 100px;
}

.progress__filler {
  background: #23b5d3;
  width: 30%;
  height: 100%;
  border-radius: inherit;
}
</style>

<script>
// import Vue from 'vue';
export default {
  name: 'ProgressFiller',
  props: {
    fillType: {
      type: String,
      default: 'int'
    },
    fill: {
      type: [String],
      default: '0'
    },
    gutterHeight: {
      type: [String],
      default: '10px'
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    showValue: {
      type: Boolean,
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'is-top'
    },
    toolTipText: {
      type: String,
      default: '---'
    },
    disabled: Boolean,
    decimalRoundOff: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      gutterStyleObj: {},
      fillerStyleObj: {},
      fillPercent: 0
    };
  },
  computed: {
    anchorClasses() {
      return {
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.value !== null && this.value === this.$parent.selected
      };
    },
    itemClasses() {
      return {
        'dropdown-item': !this.hasLink,
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active':
          this.value !== null && this.value === this.$parent.selected,
        'has-link': this.hasLink
      };
    },
    /**
     * Check if item can be clickable.
     */
    isClickable() {
      return !this.separator && !this.disabled;
    }
  },
  watch: {},
  created() {
    this.updateGutterStyle();
    this.updateFillerStyle();
  },
  methods: {
    updateGutterStyle() {
      this.gutterStyleObj.height = this.gutterHeight;
    },
    updateFillerStyle() {
      this.fillerStyleObj.width = this.fill + '%';
      this.fillerStyleObj.height = this.gutterHeight;
      /**
       * Decimal RoundOff default value set to 2 if not passed by the API or to this file props
       */
      if (this.fillType === 'float') {
        this.fillPercent =
          parseFloat(this.fill).toFixed(this.decimalRoundOff) + '%';
      } else {
        this.fillPercent =
          parseInt(this.fill).toFixed(this.decimalRoundOff) + '%';
      }
    },

    fillerClicked() {
      this.$emit('filler-event');
    }
  }
};
</script>
