<template>
  <div>
    <div class="u-font-size-7 u-spacing-mb-xs u-color-grey-mid-light">
      vs {{ pvpText }}
    </div>
    <div class="u-display-flex u-flex-align-items-flex-end">
      <span
        v-trend-indicator
        class="u-font-size-6"
        :title="displayPvpValue"
        :class="{
          'u-color-grey-base':
            displayPvpValue === 'NA' ||
            businessTrendMultiplier * pvpValueReady === 0,
          'u-color-red-base':
            businessTrendMultiplier * pvpValueReady < 0 &&
            displayPvpValue !== 'NA',
          'u-color-green-base':
            businessTrendMultiplier * pvpValueReady > 0 &&
            displayPvpValue !== 'NA'
        }"
      >
        <html-component
          wrapper-element="span"
          :html-string="displayPvpValue"
        />
      </span>
      <span
        class="u-spacing-pl-s u-font-size-7 u-color-grey-lighter"
        :title="displayPvpDiffValue"
      >
        <html-component
          wrapper-element="span"
          :html-string="displayPvpDiffValue"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { formatter, isTypeNumeric } from '@/utils/helpers/formatter.js';
import helpers from '@/utils/helpers/index.js';
import HtmlComponent from '@/components/basic/html-component.vue';

export default {
  components: {
    HtmlComponent
  },
  props: {
    pvpValue: {
      type: Number,
      default: null
    },
    pvpDiffValue: {
      type: Number,
      default: null
    },
    pvpText: {
      type: String,
      default: 'PP'
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'string'
    }
  },
  computed: {
    businessTrendMultiplier() {
      return this.isInverted ? -1 : 1;
    },
    pvpValueReady() {
      let value = this.pvpValue;
      if (!helpers.isNumeric(value) && isTypeNumeric(this.type)) {
        value = null;
      }
      return value;
    },
    displayPvpValue() {
      return formatter(this.pvpValue, 'numeric', this.naText);
    },
    displayPvpDiffValue() {
      return formatter(this.pvpDiffValue, 'percent', this.naText);
    }
  }
};
</script>
