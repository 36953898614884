import { commons } from '@/components/ams/campaigns/commons.js';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer.js';
import transformer from '@/utils/services/data-transformer';
import {
  emailRequestConfig,
  calculateInputValue,
  keywordRightTable,
  selectSkuLeftTable,
  selectSkuRightTable,
  postKeywordActionSubmitReview,
  removeKeywordsLeftTable,
  removeKeywordsRightTable,
  removeSkusLeftTable,
  removeSkusRightTable,
  getDataMismatchComponentObjectArray,
  getSearchKeyForAddAndRemoveSkusAction,
  placeholderTextForAddRemoveSkusSearchBox,
  getBulkSearchConfigs,
  getPinAndUnpinMessageForCalendarPin,
  getChartWidgetHeaderOptionProps,
  getInitRequestParams,
  isRadioEnabled,
  createAddKeywordsPayload,
  createRemoveKeywordsPayload,
  createRemoveSKUActionPayload,
  createSKUActionPayload
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils.js';
import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
const chartingWb = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  import('@/components/widgets/collaboration/collaboration.vue');

const iconHeader = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );
const customActionPanel = () =>
  import('@/components/ams/actionPanels/customActionPanel.vue');
const nameChangePanel = () =>
  import('@/components/ams/actionPanels/nameChangePanel.vue');
const bidManagementPanel = () =>
  import('@/components/ams/actionPanels/bidManagementPanel.vue');
const adjustDatesPanel = () =>
  import('@/components/ams/actionPanels/adjustDatesPanel.vue');
const failedCampaignsBanner = () =>
  import('@/pages/entity_details/common_components/banner.vue');
const BackToAllCampaigns = () =>
  import(
    '@/pages/dashboard_service_campaign_management/citrusAds/BackToAllCampaigns.vue'
  );
const DataMismatchBanner = () =>
  import(
    '@/pages/dashboard_service_campaign_management/citrusAds/DataMismatchBanner.vue'
  );

function isAddKeywordsDisabled(selections, retailer) {
  let isAddKeywordsDisabled = false;
  for (const selection of selections) {
    const selectionPlacement = selection.data?.citrus_cm_campaign_placement;
    const baseCondition = !selectionPlacement
      ?.toLowerCase()
      ?.includes('search');
    const clientSpecificCondition =
      retailer === 'sainsburys_citrus'
        ? baseCondition ||
          selectionPlacement === "Search Below Grid (Sainsbury's Only)"
        : baseCondition;
    if (clientSpecificCondition) {
      isAddKeywordsDisabled = true;
      break;
    }
  }
  return isAddKeywordsDisabled;
}

const isApprovalStatusRejectedFilterAlreadySelected = (
  currentFilterLocalStorage
) => {
  return currentFilterLocalStorage?.approval_status?.includes('Rejected');
};

const isRecentlyFailedFilterAlreadySelected = (currentFilterLocalStorage) => {
  const arrayToCheck = currentFilterLocalStorage?.is_recently_failed;
  if (Array.isArray(arrayToCheck)) {
    if (arrayToCheck.length === 1 && arrayToCheck[0] === 'true') {
      return true;
    }
  }
  return false;
};

async function toggleAddAndRemoveIsRecentlyFailedFilter(
  localStorageFilterKey,
  localStorageFilterOrderKey
) {
  const currentFilterLocalStorage = getFilterFromLocalStorage(
    localStorageFilterKey
  );
  let currentFilterOrderLocalStorage = getFilterOrderFromLocalStorage(
    localStorageFilterOrderKey
  );
  if (isRecentlyFailedFilterAlreadySelected(currentFilterLocalStorage)) {
    // this means is failed filter is already there
    currentFilterLocalStorage.is_recently_failed = [];
    const indexOfIsRecentlyFailedOrder =
      currentFilterOrderLocalStorage.indexOf('is_recently_failed');
    if (indexOfIsRecentlyFailedOrder > -1) {
      currentFilterOrderLocalStorage.splice(indexOfIsRecentlyFailedOrder, 1);
    }
  } else {
    // This means the filter is not present
    currentFilterLocalStorage.is_recently_failed = ['true'];
    currentFilterOrderLocalStorage = [
      'is_recently_failed',
      ...currentFilterOrderLocalStorage
    ];
  }
  await localStorage.setItem(
    localStorageFilterKey,
    JSON.stringify(currentFilterLocalStorage)
  );
  await localStorage.setItem(
    localStorageFilterOrderKey,
    JSON.stringify(currentFilterOrderLocalStorage)
  );
}

const getTextAndIconForApprovalStatusRejectedToggle = (
  localStorageFilterKey
) => {
  const currentFilterLocalStorage = {
    ...JSON.parse(localStorage.getItem(localStorageFilterKey))
  };
  const campaignApprovalStatusFilter =
    currentFilterLocalStorage?.approval_status || [];

  return {
    title:
      campaignApprovalStatusFilter.length === 1 &&
      campaignApprovalStatusFilter[0] === 'Rejected'
        ? 'VIEW ALL CAMPAIGNS'
        : 'VIEW REJECTED CAMPAIGNS',
    icon:
      campaignApprovalStatusFilter.length === 1 &&
      campaignApprovalStatusFilter[0] === 'Rejected'
        ? 'campaign_management'
        : 'campaign'
  };
};

function getFilterFromLocalStorage(localStorageKey) {
  return {
    ...JSON.parse(localStorage.getItem(localStorageKey))
  };
}

function getFilterOrderFromLocalStorage(localStorageKey) {
  let currentFilterOrderLocalStorage = [];
  try {
    const rawCurrentFilterOrderLocalStorage =
      localStorage.getItem(localStorageKey);
    if (rawCurrentFilterOrderLocalStorage) {
      currentFilterOrderLocalStorage = [
        ...JSON.parse(rawCurrentFilterOrderLocalStorage)
      ];
    }
    return currentFilterOrderLocalStorage;
  } catch (err) {
    console.log(err);
    return currentFilterOrderLocalStorage;
  }
}

function removeArrayElementsWithAlreadyExistingPrimaryKey(actionPayloadArray) {
  const newArrayToReturn = [];
  const uniquePrimaryKeySet = new Set();
  actionPayloadArray.forEach((arrayItem) => {
    const primaryKey = arrayItem.primaryKey;
    if (!uniquePrimaryKeySet.has(primaryKey)) {
      uniquePrimaryKeySet.add(primaryKey);
      newArrayToReturn.push(arrayItem);
    }
  });
  return newArrayToReturn;
}

function updateAddSkusTableArrayToShowOrHideItemInTable(
  oldRowsToLoopThrough,
  propertyKey = 'product_code'
) {
  const newRows = [];
  if (oldRowsToLoopThrough.length === 0) {
    return newRows;
  }
  if (oldRowsToLoopThrough.length === 1) {
    oldRowsToLoopThrough[0].hideInTable = false;
    return oldRowsToLoopThrough;
  }
  if (oldRowsToLoopThrough.length > 1) {
    oldRowsToLoopThrough[0].hideInTable = false;
    newRows.push(oldRowsToLoopThrough[0]);
    for (let i = 1; i < oldRowsToLoopThrough.length; i++) {
      const currentItem = oldRowsToLoopThrough[i];
      const previousItem = oldRowsToLoopThrough[i - 1];
      if (previousItem?.[propertyKey] === currentItem?.[propertyKey]) {
        currentItem.hideInTable = true;
      }
      newRows.push(currentItem);
    }
    return newRows;
  }
  return newRows;
}

function groupItemsByPropertyKey(rows, propertyKey = 'product_code') {
  const mapSet = new Map();
  rows.forEach((oldArrayItem) => {
    const productCode = oldArrayItem?.[propertyKey];
    const associatedArray = mapSet.get(productCode);
    if (associatedArray) {
      associatedArray.push(oldArrayItem);
    } else {
      mapSet.set(productCode, [oldArrayItem]);
    }
  });
  let tempNewRows = [];
  for (let productArray of mapSet.values()) {
    tempNewRows = [...tempNewRows, ...productArray];
  }
  tempNewRows = tempNewRows.map((item) => cloneDeep(item));
  return tempNewRows;
}

const rowClassRules = {
  ' hideBorderAddKeywordsToCampaigns': function (params) {
    return params.data?.hideInTable;
  }
};

async function adjustCpcActionObjectApplyLeftBtnClick(
  bidManagementPanelVueRef,
  updatedRadioSelectionWithInputValue,
  retailer
) {
  const selectedRows = bidManagementPanelVueRef.selections;
  const payload = selectedRows.map((rowItem) => {
    const campaignId = rowItem?.data?.citrus_cm_campaign_campaign_id;
    const previousValue = rowItem?.data?.citrus_cm_campaign_max_cpc;
    let newValue = calculateInputValue(
      updatedRadioSelectionWithInputValue,
      previousValue
    );
    if (newValue) {
      newValue = parseFloat(newValue).toFixed(2);
    }
    return {
      widget: 'campaign',
      actionType: `${retailer}CampaignEditMaxBid`,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      actionPayload: {
        campaignId,
        campaignType: 'productAds',
        new_max_bid: newValue || null
      },
      viewPayload: {
        campaignId,
        campaignType: 'productAds',
        new_max_bid: newValue || null,
        old_max_bid: previousValue || null
      },
      previousEntityValue: previousValue,
      newEntityValue: newValue
    };
  });
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    bidManagementPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    bidManagementPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function getAdjustCpcActionObject(retailer) {
  return {
    icon: 'dollar',
    title: 'Adjust Max CPC',
    id: 'adjust-cpc',
    customState: {
      component: 'bidManagementPanel',
      props: {
        widgetsConfig: (bidManagementPanelVueRef) => {
          return {
            headerText: () => {
              const selectionLength =
                bidManagementPanelVueRef.selections.length;
              return `Adjust Max CPC (${selectionLength} Campaign${
                selectionLength > 1 ? 's' : ''
              } Selected)`;
            },
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            radioSelectionWithInputConfig: {
              configArray: [
                {
                  title: 'Set new max cpc',
                  type: 'input',
                  value: 'set_new_max_cpc'
                },
                {
                  title: 'Increase max cpc',
                  type: 'dropdown',
                  value: 'increase_max_cpc',
                  dropdown: [
                    {
                      label: 'Change by percentage',
                      value: 'percentage'
                    },
                    {
                      label: 'Change absolute value',
                      value: 'absolute_value'
                    }
                  ]
                },
                {
                  title: 'Decrease max cpc',
                  value: 'decrease_max_cpc',
                  type: 'dropdown',
                  dropdown: [
                    {
                      label: 'Change by percentage',
                      value: 'percentage'
                    },
                    {
                      label: 'Change absolute value',
                      value: 'absolute_value'
                    }
                  ]
                }
              ]
            },
            async onLeftBtnClick(updatedRadioSelectionWithInputValue) {
              await adjustCpcActionObjectApplyLeftBtnClick(
                bidManagementPanelVueRef,
                updatedRadioSelectionWithInputValue,
                retailer
              );
            },
            errorMessage() {
              const minimumValue = 0.6;
              if (
                bidManagementPanelVueRef?.updatedRadioSelectionWithInputValue
              ) {
                if (
                  bidManagementPanelVueRef?.updatedRadioSelectionWithInputValue
                    ?.inputType === 'input'
                ) {
                  const inputValue =
                    bidManagementPanelVueRef
                      ?.updatedRadioSelectionWithInputValue?.inputValue;
                  if (parseFloat(inputValue).toFixed(2) < minimumValue) {
                    return `Minimum cpc should be greater than ${minimumValue}`;
                  }
                }
              }
              return '';
            },
            isLeftBtnDisabled() {
              if (bidManagementPanelVueRef.errorMessage) {
                return true;
              }
              return !bidManagementPanelVueRef
                .updatedRadioSelectionWithInputValue?.inputValue;
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

function editBudgetApplyLeftBtnClickViewPayload(
  isDailyBudget,
  previousValue,
  newValue,
  isTotalBudget,
  isAlwaysOn
) {
  const viewPayload = {
    previousDailyBudget: null,
    newDailyBudget: null,
    previousTotalBudget: null,
    newTotalBudget: null,
    alwaysOnBudget: null
  };
  if (isDailyBudget) {
    viewPayload.previousDailyBudget = previousValue;
    viewPayload.newDailyBudget = newValue || ' NA';
  } else if (isTotalBudget) {
    viewPayload.previousTotalBudget = previousValue;
    viewPayload.newTotalBudget = newValue || ' NA';
  } else if (isAlwaysOn) {
    viewPayload.alwaysOnBudget = 'Always On';
  }
  return viewPayload;
}

async function editBudgetApplyLeftBtnClick(
  bidManagementPanelVueRef,
  updatedRadioSelectionWithInputValue,
  retailer
) {
  const selectedRows = bidManagementPanelVueRef.selections;
  const payload = selectedRows.map((rowItem) => {
    const radioButtonsSelection =
      bidManagementPanelVueRef.radioButtonsSelection;
    const campaignId = rowItem?.data?.citrus_cm_campaign_campaign_id;
    const isDailyBudget = radioButtonsSelection === 'dailyBudget';
    const isTotalBudget = radioButtonsSelection === 'totalBudget';
    const isAlwaysOn = radioButtonsSelection === 'alwaysOn';
    const previousValue = isDailyBudget
      ? rowItem?.data?.citrus_cm_campaign_daily_budget
      : rowItem?.data?.citrus_cm_campaign_total_budget;
    let newValue = calculateInputValue(
      updatedRadioSelectionWithInputValue,
      previousValue
    );
    if (newValue) {
      newValue = parseFloat(newValue).toFixed(2);
    }
    const actionPayloadKeyForNewValue = isDailyBudget
      ? 'new_daily_budget'
      : 'new_total_budget';

    // Extracted independent statement for actionType determination
    let actionType;
    if (isAlwaysOn) {
      actionType = `${retailer}CampaignAlwaysOnBudgetChange`;
    } else if (isDailyBudget) {
      actionType = `${retailer}CampaignDailyBudgetChange`;
    } else {
      actionType = `${retailer}CampaignTotalBudgetChange`;
    }

    const viewPayload = editBudgetApplyLeftBtnClickViewPayload(
      isDailyBudget,
      previousValue,
      newValue,
      isTotalBudget,
      isAlwaysOn
    );

    return {
      widget: 'campaign',
      actionType: actionType,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      actionPayload: {
        campaignId: campaignId,
        campaignType: 'productAds',
        ...(isAlwaysOn
          ? {}
          : {
              [actionPayloadKeyForNewValue]: newValue?.toString() || null
            })
      },
      viewPayload: viewPayload,
      previousEntityValue: previousValue,
      newEntityValue: newValue
    };
  });

  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    bidManagementPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    bidManagementPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function editBudgetApplyRadioSelectionWithInputConfig(
  bidManagementPanelVueRef
) {
  const radioButtonsSelection = bidManagementPanelVueRef.radioButtonsSelection;
  if (
    radioButtonsSelection === 'dailyBudget' ||
    radioButtonsSelection === 'totalBudget'
  ) {
    return {
      heading: `Adjust ${
        radioButtonsSelection === 'dailyBudget' ? 'daily' : 'total'
      } budget:`,
      configArray: [
        {
          title: 'Set new budget',
          type: 'input',
          value: 'set_new_bud'
        },
        {
          title: 'Increase budget',
          type: 'dropdown',
          value: 'increase_budget',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease budget',
          value: 'decrease_bud',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ]
    };
  } else {
    return null;
  }
}

function getEditBudgetActionObject(retailer) {
  return {
    icon: 'dollar',
    title: 'Edit Budget',
    id: 'edit-budget',
    customState: {
      component: 'bidManagementPanel',
      props: {
        widgetsConfig: (bidManagementPanelVueRef) => {
          return {
            headerText: () => {
              const selectionLength =
                bidManagementPanelVueRef.selections.length;
              return `Edit Budget (${selectionLength} Campaign${
                selectionLength > 1 ? 's' : ''
              } Selected)`;
            },
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            radioButtonsConfig: () => {
              let defaultSelectedRadioButton = '';
              const selectedItemsBudgetTypesArray =
                bidManagementPanelVueRef.selections.map(
                  (selectedItem) =>
                    selectedItem?.data?.citrus_cm_campaign_budget_type
                );
              const areAllBudgetTypesSame = selectedItemsBudgetTypesArray.every(
                (val, i, arr) => val === arr[0]
              );
              if (areAllBudgetTypesSame === true) {
                const budgetType = selectedItemsBudgetTypesArray[0];
                switch (budgetType) {
                  case 'AlwaysOn':
                    defaultSelectedRadioButton = 'alwaysOn';
                    break;
                  case 'Daily':
                    defaultSelectedRadioButton = 'dailyBudget';
                    break;
                  case 'Total':
                    defaultSelectedRadioButton = 'totalBudget';
                    break;
                  default:
                    defaultSelectedRadioButton = 'alwaysOn';
                }
              }
              return {
                heading: 'Budget type:',
                radioOptionsArray: [
                  // will enable this once the api is available for this
                  {
                    title: 'Always on',
                    value: 'alwaysOn'
                  },
                  {
                    title: 'Daily Budget',
                    value: 'dailyBudget'
                  },
                  {
                    title: 'Total Budget',
                    value: 'totalBudget'
                  }
                ],
                defaultSelectedRadioButton: defaultSelectedRadioButton
              };
            },
            radioSelectionWithInputConfig: () => {
              return editBudgetApplyRadioSelectionWithInputConfig(
                bidManagementPanelVueRef
              );
            },
            infoText: () => {
              const radioButtonsSelection =
                bidManagementPanelVueRef.radioButtonsSelection;
              if (radioButtonsSelection === 'alwaysOn') {
                return 'Keep your campaign active at all times, maximising sales opportunities in peak periods of the day/week. The campaign will become inactive if the selected wallet runs out of funds.';
              } else {
                return null;
              }
            },
            async onLeftBtnClick(updatedRadioSelectionWithInputValue) {
              await editBudgetApplyLeftBtnClick(
                bidManagementPanelVueRef,
                updatedRadioSelectionWithInputValue,
                retailer
              );
            },
            isLeftBtnDisabled() {
              const isAlwaysOn =
                bidManagementPanelVueRef.radioButtonsSelection === 'alwaysOn';
              if (isAlwaysOn) {
                return false;
              }
              return !bidManagementPanelVueRef
                .updatedRadioSelectionWithInputValue?.inputValue;
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.switchBlueBar('line item');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

export function addKeywordsEnterListPanelUpdateTextInput(
  data,
  customActionPanelVueRef,
  matchType,
  retailer,
  addBidValue = false
) {
  const keywords = data?.keywords?.filter((item) => item.length <= 80);
  const entityCountLimit = data?.enterListRef.entityCount;
  const newBid = customActionPanelVueRef.suggestedCustomBidInput;
  let addToRightKeywordsTable = [];
  let keywordsExist = false;
  for (const keyword of keywords) {
    addToRightKeywordsTable.push({
      keyword: keyword,
      key: keyword,
      match_type: isRadioEnabled && (matchType ?? 'exact'),
      ...(addBidValue ? { newBid } : {})
    });
  }
  const computedRightTableRowsInTable =
    customActionPanelVueRef.computedRightTableRows;
  addToRightKeywordsTable = addToRightKeywordsTable.filter((keywordItem) => {
    const indexInTable = computedRightTableRowsInTable.findIndex(
      (item) => item.key === keywordItem.key
    );
    if (indexInTable === -1) {
      return true;
    } else {
      keywordsExist = true;
      return false;
    }
  });
  if (keywordsExist) {
    data.enterListRef.snackbar(
      'Keyword(s) & match-type(s) combination already exists.',
      6000
    );
  }

  // Logic to see how much more keywords I can add;
  const numberOfMoreKeywordsAllowedToAdd =
    entityCountLimit - computedRightTableRowsInTable.length;
  if (numberOfMoreKeywordsAllowedToAdd > 0) {
    // this means you can add more keywords, but splice the array;
    const oldAddToRightKeywordsTableLength = addToRightKeywordsTable.length;
    addToRightKeywordsTable = addToRightKeywordsTable.slice(
      0,
      numberOfMoreKeywordsAllowedToAdd
    );
    const numberOfKeywordsNotAdded =
      oldAddToRightKeywordsTableLength - addToRightKeywordsTable.length;
    if (numberOfKeywordsNotAdded > 0) {
      data.enterListRef.snackbar(
        'Unable to add the last ' +
          numberOfKeywordsNotAdded +
          ` keywords (You may add upto ${entityCountLimit} ` +
          'keywords' +
          ')',
        6000
      );
    }
    data.ref.addEntityFromRawObjectArray(addToRightKeywordsTable);
  } else {
    data.enterListRef.snackbar(
      'Unable to add the last ' +
        addToRightKeywordsTable.length +
        ` keywords (You may add upto ${entityCountLimit} ` +
        'keywords' +
        ')',
      6000
    );
  }
  data.enterListRef.keywordTextInput = '';
}

function getEditBugdetAddKeywordsObject(context, retailer) {
  return {
    icon: 'add-circle-outline',
    title: 'Add Keywords',
    id: 'add-keywords',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Add Keywords',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            eventBus.$on('matchTypeChange', (matchType) => {
              customActionPanelVueRef.matchType = matchType;
            });
            const widgetConfigs = {
              customActionPanelContainerClass: 'u-spacing-mt-l',
              primaryKey: 'key',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: 'Add Keywords to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
                // actionInfoText: 'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
              },
              left: {
                tabs: {
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'keyword',
                        buttonLabel: 'Add keywords',
                        heading: isRadioEnabled ? '' : 'Match Type Exact:',
                        isMatchTypeEnabled: isRadioEnabled,
                        emitTextInput: true,
                        entityCount: 500
                      },
                      events: {
                        updateTextInput(data) {
                          addKeywordsEnterListPanelUpdateTextInput(
                            data,
                            customActionPanelVueRef,
                            customActionPanelVueRef.matchType,
                            retailer
                          );
                        }
                      }
                    },
                    removeLeftTabCustomComponentTopPadding: true
                  }
                },
                table: 'left',
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    page: 1,
                    limit: 200
                  }
                },
                headerText: 'Add Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },

                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: keywordRightTable(customActionPanelVueRef)
                },
                headerText: () =>
                  `Added Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                const selectedCampaignIds = customActionPanelVueRef.selections;
                const payload = createAddKeywordsPayload(
                  selectedCampaignIds,
                  rows,
                  retailer,
                  isRadioEnabled
                );
                try {
                  await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                  for (const element of selectedCampaignIds) {
                    await postKeywordActionSubmitReview(element, retailer);
                  }
                  customActionPanelVueRef.$emit('afterAPIActionToCall');
                } catch (err) {
                  customActionPanelVueRef.openSnackbar('Something went wrong.');
                }
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                if (
                  isAddKeywordsDisabled(
                    customActionPanelVueRef.selections,
                    retailer
                  )
                ) {
                  return true;
                }
                return !arrayToTraverse.length || arrayToTraverse.length > 500;
              }
            };
            return widgetConfigs;
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    },
    disabled: isAddKeywordsDisabled(context.rowSelections, retailer),
    tippy: isAddKeywordsDisabled(context.rowSelections, retailer)
      ? 'Add Keywords is only allowed for Search Ingrid type of campaigns.'
      : null
  };
}

function getEditBudgetRemoveKeywordsObject(context, retailer) {
  return {
    icon: 'remove-outline',
    title: 'Remove Keywords',
    id: 'remove-keywords',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Remove Keywords',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            const campaignIds = customActionPanelVueRef.selections?.map(
              (item) => item?.data?.citrus_cm_campaign_campaign_id
            );
            const widgetConfigs = {
              primaryKey: 'key',
              isMultipleAllowed: true,
              customActionPanelContainerClass: 'u-spacing-mt-l',
              default: {
                noRowsRightTable: 'Add Keywords to remove to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Remove Keywords',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
                // actionInfoText: 'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
              },
              left: {
                tabs: {
                  'All Keywords': {
                    columnDefs: removeKeywordsLeftTable(
                      customActionPanelVueRef
                    ),
                    search: {
                      enableSearch: true,
                      searchKey: 'search'
                    },
                    showFilterCheckBox: false
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [
                    ...campaignIds.map((campaignId) => ({
                      dimensionName: 'campaign_id',
                      dimensionValue: campaignId,
                      operator: 'EQUAL_TO'
                    })),
                    {
                      dimensionName: 'keyword_status',
                      dimensionValue: 'active',
                      operator: 'EQUAL_TO'
                    }
                  ],
                  updateRows: (vueRef) => {
                    const newRows = [];
                    vueRef.leftTableData.rows.forEach((row) => {
                      newRows.push({
                        ...row,
                        campaign_name: row?.citrus_cm_keyword_campaign_name,
                        key: row?.citrus_cm_keyword_campaign_name + row?.keyword
                      });
                    });
                    vueRef.leftTableRows = newRows;
                  },
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    cubeName: 'citrus_campaigns_keyword_workbench',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'citrus_cm_keyword_campaign_name',
                      'citrus_cm_keyword_matching_type',
                      'citrus_cm_keyword_keyword_id',
                      'citrus_cm_keyword_keyword'
                    ],
                    groupByDimensionsList: ['campaign_id', 'keyword'],
                    limit: 200,
                    page: 1,
                    customAPIDecisionVars: {
                      system: 'citrus_campaign_management'
                    },
                    where: {
                      dimensionNameValueList: []
                    }
                  }
                },
                headerText: 'Remove Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: removeKeywordsRightTable(customActionPanelVueRef)
                },
                headerText: () =>
                  `Selected Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                const payload = [];
                const campaignIdToRowsMapping = {};
                rows.forEach((rightTableRowItem) => {
                  const campaignId = rightTableRowItem?.campaign_id;
                  if (campaignIdToRowsMapping[campaignId]) {
                    campaignIdToRowsMapping[campaignId].push(rightTableRowItem);
                  } else {
                    campaignIdToRowsMapping[campaignId] = [rightTableRowItem];
                  }
                });
                payload.push(
                  ...createRemoveKeywordsPayload(
                    retailer,
                    rows,
                    campaignIdToRowsMapping
                  )
                );
                try {
                  await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                  for (const campaignId in campaignIdToRowsMapping) {
                    await postKeywordActionSubmitReview(campaignId, retailer);
                  }
                  customActionPanelVueRef.$emit('afterAPIActionToCall');
                } catch (err) {
                  console.log(err);
                  customActionPanelVueRef.openSnackbar('Something went wrong.');
                }
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return !arrayToTraverse.length;
              }
            };
            return widgetConfigs;
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    },
    disabled: isAddKeywordsDisabled(context.rowSelections, retailer),
    tippy: isAddKeywordsDisabled(context.rowSelections, retailer)
      ? 'Remove Keywords is only allowed for Search Ingrid type of campaigns.'
      : null
  };
}

const getSKUActionObj = (retailer) => {
  return {
    icon: 'sku-box',
    title: 'Add SKUs',
    id: 'add-skus',
    customState: {
      component: 'customActionPanel',
      staticHeaderText: "Add SKU's",
      props: {
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            return {
              primaryKey: 'primaryKey',
              customActionPanelContainerClass: 'u-spacing-mt-l',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: "Add SKU's to this list",
                selections: '',
                noRowsLeftTable: "No SKU's sources found",
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                tabs: {
                  "All SKU's": {
                    columnDefs: selectSkuLeftTable(customActionPanelVueRef),
                    search: {
                      enableSearch: true,
                      searchKey:
                        getSearchKeyForAddAndRemoveSkusAction(retailer),
                      placeholder:
                        placeholderTextForAddRemoveSkusSearchBox(retailer)
                    },
                    showFilterCheckBox: false
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [],
                  updateRows: (vueRef) => {
                    let oldRowsToLoopThrough = vueRef.leftTableData.rows;
                    oldRowsToLoopThrough = oldRowsToLoopThrough.map((item) => {
                      item.primaryKey = item?.product_code + item?.catalog_id;
                      return item;
                    });
                    const newRows =
                      updateAddSkusTableArrayToShowOrHideItemInTable(
                        oldRowsToLoopThrough
                      );
                    vueRef.leftTableRows = newRows;
                  },
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  APIConfig: {
                    cubeName: 'citrus_product_catalog',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'product_code',
                      'product_name',
                      'sku_image_url',
                      'catalog_id',
                      'catalog_name'
                    ],
                    groupByDimensionsList: ['product_code', 'catalog_id'],
                    orderByList: [
                      {
                        dimension: 'product_name',
                        direction: 'ASC'
                      }
                    ],
                    limit: 10,
                    page: 1,
                    where: {
                      dimensionNameValueList: []
                    }
                  },
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  columnDefs: selectSkuLeftTable(customActionPanelVueRef),
                  rowClassRules: rowClassRules
                },
                headerText: "Add SKU's",
                rowHeight: 48
              },
              right: {
                rowHeight: 48,
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 10,
                    page: 1
                  },
                  columnDefs: selectSkuRightTable(customActionPanelVueRef),
                  rowClassRules: rowClassRules
                },
                headerText: () =>
                  `Added SKU's (${customActionPanelVueRef.rightTableRows.length})`,
                updateRightTableRowsBeforeReRendering(newRightTableRows) {
                  if (newRightTableRows.length > 0) {
                    const tempNewRows = groupItemsByPropertyKey(
                      newRightTableRows,
                      'product_code'
                    );
                    const newRows =
                      updateAddSkusTableArrayToShowOrHideItemInTable(
                        tempNewRows,
                        'product_code'
                      );
                    return newRows;
                  }
                  return newRightTableRows;
                }
              },
              async apply(rows = []) {
                let payload = [];
                customActionPanelVueRef.selections.forEach(
                  (selectedCampaign) => {
                    payload.push(
                      ...createSKUActionPayload(
                        retailer,
                        rows,
                        selectedCampaign
                      )
                    );
                  }
                );
                payload =
                  removeArrayElementsWithAlreadyExistingPrimaryKey(payload);
                try {
                  await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                  customActionPanelVueRef.$emit('afterAPIActionToCall');
                } catch (err) {
                  customActionPanelVueRef.openSnackbar('Something went wrong.');
                }
              }
            };
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    }
  };
};

function getRemoveSKUActionObj(retailer) {
  return {
    icon: 'remove-outline',
    title: 'Remove SKUs',
    id: 'remove-skus',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Remove SKUs',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            const campaignIds = customActionPanelVueRef.selections?.map(
              (item) => item?.data?.citrus_cm_campaign_campaign_id
            );
            const widgetConfigs = {
              customActionPanelContainerClass: 'u-spacing-mt-l',
              primaryKey: 'primaryKey',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: 'Add SKUs to this list',
                selections: '',
                noRowsLeftTable: 'No SKUs sources found',
                leftBtnLabel: 'Remove SKUs',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
                // actionInfoText: 'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
              },
              left: {
                rowHeight: 68,
                tabs: {
                  'All SKUs': {
                    columnDefs: removeSkusLeftTable(customActionPanelVueRef),
                    search: {
                      enableSearch: true,
                      searchKey: getSearchKeyForAddAndRemoveSkusAction(
                        retailer,
                        true
                      ),
                      placeholder:
                        placeholderTextForAddRemoveSkusSearchBox(retailer)
                    },
                    showFilterCheckBox: false
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [
                    ...campaignIds.map((campaignId) => ({
                      dimensionName: 'campaign_id',
                      dimensionValue: campaignId,
                      operator: 'EQUAL_TO'
                    })),
                    {
                      dimensionName: 'sku_status',
                      dimensionValue: 'active',
                      operator: 'EQUAL_TO'
                    }
                  ],
                  updateRows: (vueRef) => {
                    const tempRows = [];
                    vueRef.leftTableData.rows.forEach((row) => {
                      tempRows.push({
                        ...row,
                        campaign_name: row?.citrus_cm_sku_campaign_name,
                        campaign_name_to_show: row?.citrus_cm_sku_campaign_name,
                        primaryKey:
                          row?.campaign_id + row?.catalog_id + row?.sku_id
                      });
                    });
                    const tempNewRows = groupItemsByPropertyKey(
                      tempRows,
                      'campaign_id'
                    );
                    const newRows =
                      updateAddSkusTableArrayToShowOrHideItemInTable(
                        tempNewRows,
                        'campaign_id'
                      );
                    newRows.forEach((item) => {
                      if (item?.hideInTable) {
                        item.campaign_name_to_show = ' ';
                      }
                    });
                    vueRef.leftTableRows = newRows;
                  },
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    cubeName: 'citrus_campaigns_sku_workbench',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'citrus_cm_sku_sku_title',
                      'citrus_cm_sku_latest_image_url',
                      'citrus_cm_sku_catalog_id',
                      'citrus_cm_sku_catalog_name',
                      'citrus_cm_sku_sku_id',
                      'citrus_cm_sku_sku_status',
                      'citrus_cm_sku_sku_title',
                      'citrus_cm_sku_product_code',
                      'citrus_cm_sku_campaign_name',
                      'citrus_cm_sku_catalog_name'
                    ],
                    groupByDimensionsList: [
                      'campaign_id',
                      'catalog_id',
                      'sku_id'
                    ],
                    limit: 500,
                    page: 1,
                    customAPIDecisionVars: {
                      system: 'citrus_campaign_management'
                    },
                    where: {
                      dimensionNameValueList: []
                    }
                  },
                  rowClassRules: rowClassRules
                },
                headerText: 'Remove SKUs'
              },
              rowHeight: 68,
              right: {
                rowHeight: 68,
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: removeSkusRightTable(customActionPanelVueRef),
                  rowClassRules: rowClassRules
                },
                headerText: () =>
                  `Selected SKUs (${customActionPanelVueRef.rightTableRows.length})`,
                updateRightTableRowsBeforeReRendering(newRightTableRows) {
                  // Refill campaign_name_to_show field with original campaign name values, later on hide it based on conditions
                  const refilledRightTableRowsWithCampaignName =
                    newRightTableRows.map((item) => {
                      return {
                        ...item,
                        campaign_name_to_show: item?.citrus_cm_sku_campaign_name
                      };
                    });
                  if (refilledRightTableRowsWithCampaignName.length > 0) {
                    const tempNewRows = groupItemsByPropertyKey(
                      refilledRightTableRowsWithCampaignName,
                      'campaign_id'
                    );
                    const newRows =
                      updateAddSkusTableArrayToShowOrHideItemInTable(
                        tempNewRows,
                        'campaign_id'
                      );
                    newRows.forEach((item) => {
                      if (item?.hideInTable) {
                        item.campaign_name_to_show = ' ';
                      }
                    });
                    return newRows;
                  }
                  return newRightTableRows;
                }
              },
              async apply(rows = []) {
                const campaignIdToRowsMapping = {};
                rows.forEach((rightTableRowItem) => {
                  const campaignId = rightTableRowItem?.campaign_id;
                  if (campaignIdToRowsMapping[campaignId]) {
                    campaignIdToRowsMapping[campaignId].push(rightTableRowItem);
                  } else {
                    campaignIdToRowsMapping[campaignId] = [rightTableRowItem];
                  }
                });
                const payload = createRemoveSKUActionPayload(
                  retailer,
                  campaignIdToRowsMapping
                );
                try {
                  await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                  customActionPanelVueRef.$emit('afterAPIActionToCall');
                } catch (err) {
                  console.log(err);
                  customActionPanelVueRef.openSnackbar('Something went wrong.');
                }
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return !arrayToTraverse.length;
              }
            };
            return widgetConfigs;
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getCampaignNameActionObj(context, retailer) {
  return {
    icon: 'pencil',
    title: 'Edit Campaign Name',
    id: 'edit-campaign-name',
    customState: {
      component: 'nameChangePanel',
      props: {
        widgetsConfig: (nameChangePanelVueRef) => {
          return {
            fillTextOnMultipleSelections:
              'Action set limited for multiple campaign selections',
            headerText: () => {
              const selectionLength = nameChangePanelVueRef.selections.length;
              return `Change Campaign Name (${selectionLength} Campaign${
                selectionLength > 1 ? 's' : ''
              } Selected)`;
            },
            defaultItemName: () => {
              const selectedItem = nameChangePanelVueRef.selections[0];
              return selectedItem?.data?.citrus_cm_campaign_campaign_name || '';
            },
            subHeaderText: 'Campaign Name:',
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            itemNameAlreadyExistErrorText: 'Campaign name already exists',
            onLeftBtnClick: async () => {
              const selectedRow = nameChangePanelVueRef.selections[0];
              const oldCampaignName =
                selectedRow?.data?.citrus_cm_campaign_campaign_name;
              const campaignId =
                selectedRow?.data?.citrus_cm_campaign_campaign_id;
              const newCampaignName =
                nameChangePanelVueRef.currentItemName.trim();
              const payload = [
                {
                  widget: 'campaign',
                  actionType: `${retailer}CampaignNameChange`,
                  previousEntityValue: oldCampaignName,
                  newEntityValue: newCampaignName,
                  actionSource: {
                    pageUrl: window.location.href
                  },
                  primaryKey: campaignId,
                  actionPayload: {
                    campaignId,
                    campaignType: 'productAds',
                    new_campaign_name: newCampaignName
                  },
                  viewPayload: {
                    campaignId,
                    campaignType: 'productAds',
                    old_campaign_name: oldCampaignName,
                    new_campaign_name: newCampaignName
                  }
                }
              ];
              try {
                await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                nameChangePanelVueRef.$emit('afterAPIActionToCall');
              } catch (err) {
                nameChangePanelVueRef.openSnackbar('Something went wrong.');
              }
            },
            checkIfItemAlreadyExists(
              currentCampaignName,
              alreadyExistingCampaignNames
            ) {},
            isLeftBtnDisabled() {
              if (nameChangePanelVueRef.tooltipErrorMessage.length > 0) {
                return true;
              } else if (nameChangePanelVueRef.currentItemName.length === 0) {
                nameChangePanelVueRef.tooltipErrorMessage =
                  'Campaign Name is required';
                return true;
              }
              return false;
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.switchBlueBar('line item');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    },
    disabled: context.rowSelections.length > 1,
    tippy:
      context.rowSelections.length > 1
        ? 'Action set limited for multiple campaign selections'
        : null
  };
}

const getDefaultStartEndDateForCalendar = (rowSelections) => {
  if (rowSelections.length === 1) {
    const startDate = rowSelections[0].data?.citrus_cm_campaign_start_date;
    const endDate = rowSelections[0].data?.citrus_cm_campaign_end_date;
    return {
      start: startDate ? new Date(moment(startDate, 'MM/DD/YYYY')) : null,
      end: endDate ? new Date(moment(endDate, 'MM/DD/YYYY')) : null
    };
  }
  return {
    start: new Date(),
    end: new Date(moment().add(1, 'days'))
  };
};
const handleOnStartDateUpdated = (rowSelections) => {
  if (rowSelections.length === 1) {
    const startDate = rowSelections[0].data?.citrus_cm_campaign_start_date;
    let endDateToBeDisabledTill = new Date();
    // check if start date is greater than today
    // if yes, endDateToBeDisabledTill will be till that day, else today's date
    if (moment(startDate).isAfter(moment().format('MM/DD/YYYY'))) {
      endDateToBeDisabledTill = new Date(
        moment(startDate, 'MM/DD/YYYY').add(1, 'day')
      );
    }
    return {
      endDateToBeDisabledTill
    };
  }
  return {
    endDateToBeDisabledTill: new Date()
  };
};

function getCalendarActionObj(retailer) {
  return {
    icon: 'calendar',
    title: 'Adjust End Dates',
    id: 'adjust-end-dates',
    customState: {
      component: 'adjustDatesPanel',
      props: {
        widgetsConfig: (adjustDatesPanelVueRef) => {
          return {
            headerText: () => {
              const selectionLength = adjustDatesPanelVueRef.selections.length;
              return `Adjust End Date (${selectionLength} Campaign${
                selectionLength > 1 ? 's' : ''
              } Selected)`;
            },
            leftCalendarDisabled: true,
            defaultStartEndDate() {
              return getDefaultStartEndDateForCalendar(
                adjustDatesPanelVueRef.selections
              );
            },
            onStartDateUpdated() {
              return handleOnStartDateUpdated(
                adjustDatesPanelVueRef.selections
              );
            },
            async onLeftBtnClick(newCampaignsDataObject) {
              const selectedCampaigns = adjustDatesPanelVueRef.selections;
              const payload = selectedCampaigns.map((selectedRow) => {
                const campaignId =
                  selectedRow?.data?.citrus_cm_campaign_campaign_id;
                const newEndDate = newCampaignsDataObject.end
                  ? moment(newCampaignsDataObject.end).format('YYYY-MM-DD')
                  : null;
                const oldEndDate = selectedRow?.data
                  ?.citrus_cm_campaign_end_date
                  ? moment(
                      selectedRow?.data?.citrus_cm_campaign_end_date,
                      'MM/DD/YYYY'
                    ).format('YYYY-MM-DD')
                  : null;
                return {
                  widget: 'campaign',
                  actionType: `${retailer}CampaignStartEndDateChange`,
                  actionSource: {
                    pageUrl: window.location.href
                  },
                  primaryKey: campaignId,
                  actionPayload: {
                    campaignId,
                    campaignType: 'productAds',
                    new_start_time: moment(
                      selectedRow?.data?.citrus_cm_campaign_start_date,
                      'MM/DD/YYYY'
                    ).format('YYYY-MM-DD'),
                    new_end_time: newEndDate
                  },
                  viewPayload: {
                    campaignId,
                    campaignType: 'productAds',
                    current_end_date:
                      selectedRow?.data?.citrus_cm_campaign_end_date ||
                      'No End Date',
                    new_end_date: newEndDate
                      ? moment(newEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                      : 'No End Date'
                  },
                  previousEntityValue: oldEndDate,
                  newEntityValue: newEndDate
                };
              });
              try {
                await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                adjustDatesPanelVueRef.$emit('afterAPIActionToCall');
              } catch (err) {
                console.log(err);
              }
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.switchBlueBar('line item');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

function getNames(retailer, advancedReporting) {
  if (advancedReporting) {
    return {
      listApiPageName: `${retailer}_cm_campaigns_ar`,
      chartWidgetName: `${retailer}_cm_campaigns_charting_wb_ar`,
      tableWidgetName: `${retailer}_cm_campaigns_table_ar`,
      localStorageFilterKey: `${retailer}-campaigns-filter_ar`,
      localStorageFilterOrderKey: `${retailer}-campaigns-filter_ar-order`
    };
  }
  return {
    listApiPageName: `${retailer}_cm_campaigns`,
    chartWidgetName: `${retailer}_cm_campaigns_charting_wb`,
    tableWidgetName: `${retailer}_cm_campaigns_table`,
    localStorageFilterKey: `${retailer}-campaigns-filter`,
    localStorageFilterOrderKey: `${retailer}-campaigns-filter-order`
  };
}

export function campaignsConfig(retailer, advancedReporting) {
  const tabName = 'Campaigns';
  const {
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey
  } = getNames(retailer, advancedReporting);
  const PRIMARY_KEY = 'campaign_id';
  const configToReturn = {
    PRIMARY_KEY: PRIMARY_KEY,
    entity: 'campaigns',
    styleClass: 'orders generic-campaign-management',
    // list API data
    pageInfo: {
      name: listApiPageName,
      title: 'Campaigns'
    },
    initRequestParams: getInitRequestParams(
      'Campaigns',
      listApiPageName,
      chartWidgetName,
      tableWidgetName
    ),
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      customActionPanel: customActionPanel,
      nameChangePanel: nameChangePanel,
      bidManagementPanel: bidManagementPanel,
      adjustDatesPanel: adjustDatesPanel,
      BackToAllCampaigns: BackToAllCampaigns,
      failedCampaignsBanner: failedCampaignsBanner,
      DataMismatchBanner: DataMismatchBanner
    },
    // header options passed to dashboard_inflator
    headerOptions: function (context) {
      const textAndIconForApprovalStatusRejectedToggle =
        getTextAndIconForApprovalStatusRejectedToggle(localStorageFilterKey);
      const currentFilterLocalStorageObject = getFilterFromLocalStorage(
        localStorageFilterKey
      );
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: getChartWidgetHeaderOptionProps(context),
        [tableWidgetName]: {
          props: {
            headerOptions: [
              ...(isRecentlyFailedFilterAlreadySelected(
                currentFilterLocalStorageObject
              )
                ? [
                    {
                      ui_component: 'BackToAllCampaigns',
                      props: {
                        backButtonClicked: async () => {
                          // do toggle here
                          await toggleAddAndRemoveIsRecentlyFailedFilter(
                            localStorageFilterKey,
                            localStorageFilterOrderKey
                          );
                          let currentFilterUiKey = this.filterSettings.uiKey;
                          this.filterSettings = {
                            ...this.filterSettings,
                            uiKey: currentFilterUiKey + 1
                          };
                        },
                        customClassArray: ['u-margin-right-auto']
                      }
                    }
                  ]
                : []),
              ...(!isRecentlyFailedFilterAlreadySelected(
                currentFilterLocalStorageObject
              ) && getDataMismatchComponentObjectArray(retailer)),
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    {
                      icon: 'show-chart',
                      title: 'PLOT ROWS',
                      id: 'show-chart',
                      removeSelectionState: false,
                      handler(selectionLength, dropDownSelection, selections) {
                        // here {this} is bound to the cw_actionsBar component
                        this.$emit('handlePlotRows', selections);
                      }
                    },
                    getEditStatusActionObject(context, retailer),
                    getAdjustCpcActionObject(retailer),
                    getEditBudgetActionObject(retailer),
                    getEditBugdetAddKeywordsObject(context, retailer),
                    getEditBudgetRemoveKeywordsObject(context, retailer),
                    getSKUActionObj(retailer),
                    getRemoveSKUActionObj(retailer),
                    getCampaignNameActionObj(context, retailer),
                    getCalendarActionObj(retailer)
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...(!context.rowSelections.length
                ? [
                    {
                      ui_component: 'iconTextActions',
                      props: {
                        action: textAndIconForApprovalStatusRejectedToggle,
                        mode: 'default',
                        applyPadding: false
                      },
                      events: {
                        clicked: async () => {
                          const currentFilterLocalStorage =
                            getFilterFromLocalStorage(localStorageFilterKey);
                          let currentFilterOrderLocalStorage =
                            getFilterOrderFromLocalStorage(
                              localStorageFilterOrderKey
                            );
                          if (
                            isApprovalStatusRejectedFilterAlreadySelected(
                              currentFilterLocalStorage
                            )
                          ) {
                            // this means failed filter is already there
                            currentFilterLocalStorage.approval_status = [];
                            const indexOfApprovalStateOrder =
                              currentFilterOrderLocalStorage.indexOf(
                                'approval_status'
                              );
                            if (indexOfApprovalStateOrder > -1) {
                              currentFilterOrderLocalStorage.splice(
                                indexOfApprovalStateOrder,
                                1
                              );
                            }
                          } else {
                            // This means the filter is not present
                            currentFilterLocalStorage.approval_status = [
                              'Rejected'
                            ];
                            currentFilterOrderLocalStorage = [
                              'approval_status',
                              ...currentFilterOrderLocalStorage
                            ];
                          }
                          await localStorage.setItem(
                            localStorageFilterKey,
                            JSON.stringify(currentFilterLocalStorage)
                          );
                          await localStorage.setItem(
                            localStorageFilterOrderKey,
                            JSON.stringify(currentFilterOrderLocalStorage)
                          );
                          let currentFilterUiKey = this.filterSettings.uiKey;
                          this.filterSettings = {
                            ...this.filterSettings,
                            uiKey: currentFilterUiKey + 1
                          };
                        }
                      },
                      order: 400
                    }
                  ]
                : []),
              ...(!context.rowSelections.length
                ? [
                    {
                      ui_component: 'iconTextActions',
                      props: {
                        action: { title: 'Email', icon: 'email-subscription' },
                        mode: 'default',
                        applyPadding: false
                      },
                      events: {
                        clicked: () => {
                          context.showModal = true;
                        }
                      },
                      order: 400
                    }
                  ]
                : [])
            ],
            enableManageColumns: !context.rowSelections.length,
            headerContainerGridStyles: {
              ...(context.rowSelections.length
                ? { padding: 0 }
                : {
                    display: 'flex',
                    'justify-content': 'flex-end',
                    'align-items': 'center'
                  })
            },
            searchParams: {
              show: false
            },
            gridOptions: context.gridOptions,
            rowSelection: {
              mode: 'multiple',
              // this is called on selection change row + -
              onSelectionChanged: context.handleSelectionChange
            },
            rowHeight: 45
            // selections: context.rowSelections
          }
        }
      };
    },
    filterSettings: {
      emitEvent: `${retailer}FilterApply`,
      uiKey: 0,
      show: true,
      // to do update backend cube
      backendCube: 'citrus_campaigns_workbench',
      defaultDatePostRange: 'last30Days',
      enableSave: true,
      enableRemoveAll: true,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: localStorageFilterKey,
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: 'Search for a Campaign',
        // to do update search dimension
        searchDimension: 'search'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      pinMessage: getPinAndUnpinMessageForCalendarPin().pinMessage,
      unpinMessage: getPinAndUnpinMessageForCalendarPin().unpinMessage,
      enableBetweenOperator: true,
      additionalRanges: commons.additionalDateRanges(),
      bulkSearchConfigs: getBulkSearchConfigs(retailer, tabName, {
        findEntityInputPlaceholder: 'Find a campaign name',
        subTitle: 'campaign names',
        dimensionNameMapping: 'campaign_name'
      })
    },
    widgetNames: {
      chart: chartWidgetName,
      table: tableWidgetName
    },
    getActivityData: (data, key) => ({
      params: { widget: 'campaign', primaryKey: data[key] }
    }),
    modal: {
      modalConfig: emailRequestConfig,
      clickedOutsideModal(dashboardWrapperVueRef) {
        dashboardWrapperVueRef.showModal = false;
        this.modalConfig = emailRequestConfig;
      }
    },
    componentBelowFilterVHtmlText: '',
    componentBelowFilter: function (dashboardWrapperVueRef) {
      const currentFilterLocalStorageObject = getFilterFromLocalStorage(
        localStorageFilterKey
      );
      return {
        show:
          dashboardWrapperVueRef.showComponentBelowFilter &&
          !isRecentlyFailedFilterAlreadySelected(
            currentFilterLocalStorageObject
          ),
        ui_component: 'failedCampaignsBanner',
        props: {
          showCta: true,
          ctaText: 'View Failed Campaigns',
          ctaCustomClass: 'u-margin-left-auto',
          showCloseIcon: true,
          closeIconCustomClass: 'u-flex-0 u-spacing-ml-m',
          vHtmlText: this.componentBelowFilterVHtmlText,
          action: async () => {
            await toggleAddAndRemoveIsRecentlyFailedFilter(
              localStorageFilterKey,
              localStorageFilterOrderKey
            );
            // do toggle here

            let currentFilterUiKey = this.filterSettings.uiKey;
            this.filterSettings = {
              ...this.filterSettings,
              uiKey: currentFilterUiKey + 1
            };
          }
        },
        events: {
          closeIconClicked: function () {
            dashboardWrapperVueRef.showComponentBelowFilter = false;
          }
        },
        customClass: 'background-red-warning'
      };
    },
    async onCreated(dashboardWrapperVueRef) {
      this.componentBelowFilterVHtmlText = '';
      dashboardWrapperVueRef.showComponentBelowFilter = false;
      const response = await HttpLayer.post({
        APIData: {
          cubeName: 'citrus_campaigns_workbench',
          getLatestAvailableInsteadOfRollup: false,
          customAPIDecisionVars: {},
          timeseriesEnabled: false,
          pvpenabled: false,
          yoyenabled: false,
          measuresList: [
            'citrus_cm_campaign_campaign_name',
            'citrus_cm_campaign_is_recently_failed'
          ],
          groupByDimensionsList: ['campaign_id'],
          orderByList: [],
          limit: 30,
          page: 1,
          where: {
            dimensionNameValueList: [
              {
                dimensionName: 'citrus_cm_campaign_is_recently_failed',
                dimensionValue: 'true'
              }
            ],
            date: {}
          }
        }
      });
      let mergedResult = transformer.mergeResultDimension(response.data) || [];
      if (mergedResult.length > 0) {
        let vhtmlelement = '';
        const failedCampaigns = mergedResult.map(
          (failedCampaignsInLast48Hours) => {
            return failedCampaignsInLast48Hours?.citrus_cm_campaign_campaign_name;
          }
        );
        vhtmlelement = '<div class="u-font-size-5">';
        if (failedCampaigns.length > 1) {
          vhtmlelement =
            vhtmlelement +
            `<b>${failedCampaigns[0]}</b> +${
              failedCampaigns.length - 1
            } Campaigns failed in the last 48 hours. `;
        }
        if (failedCampaigns.length === 1) {
          vhtmlelement =
            vhtmlelement +
            `<b>${failedCampaigns[0]}</b> failed in the last 48 hours. `;
        }
        vhtmlelement =
          vhtmlelement +
          '<span>Please click view failed campaign button to view them.</span>';
        vhtmlelement = vhtmlelement + '</div>';
        this.componentBelowFilterVHtmlText = vhtmlelement;
        dashboardWrapperVueRef.showComponentBelowFilter = true;
      } else {
        this.componentBelowFilterVHtmlText = '';
        dashboardWrapperVueRef.showComponentBelowFilter = false;
      }
    }
  };
  return configToReturn;
}

// Edit Campaign Status.
const getEditStatusActionObject = (context, retailer) => ({
  icon: 'task-status',
  title: 'Edit Status',
  id: 'edit-status',
  dropDown: true,
  dropDownOptions: [
    { title: 'Enable', value: 'Active' },
    { title: 'Pause', value: 'Paused' },
    { title: 'Archive', value: 'Archived' }
  ],
  actionUpdater(currentAction, selections) {
    if (
      selections?.[0]?.data?.citrus_cm_campaign_campaign_status
        ?.toLowerCase()
        ?.includes('draft')
    ) {
      currentAction.tippy = 'Edit status is not allowed for draft campaigns';
      currentAction.disabled = true;
    }
    return currentAction;
  },
  async handler(selectionLength, dropDownSelection, selections) {
    const payload = [];
    selections.forEach((selectedCampaignRow) => {
      // Ignore if the status is draft.
      if (
        selectedCampaignRow?.data?.citrus_cm_campaign_campaign_status.toLowerCase() ===
        'draft'
      ) {
        return;
      }
      const campaignId = selectedCampaignRow?.data?.campaign_id;
      // Mapping Used for API calls.
      const dropdownToStateMapping = {
        Active: 'CAMPAIGN_STATE_ACTIVE',
        Paused: 'CAMPAIGN_STATE_PAUSED',
        Archived: 'CAMPAIGN_STATE_ARCHIVED'
      };

      // Mapping Required for Action Logs.
      const dropdownToActionStateMapping = {
        Active: 'Enabled',
        Paused: 'Paused',
        Archived: 'Archived'
      };
      const currentStatus =
        dropdownToActionStateMapping[
          selectedCampaignRow?.data?.citrus_cm_campaign_campaign_status
        ];
      const status = dropdownToActionStateMapping[dropDownSelection[0].value];
      const oldState =
        dropdownToStateMapping[
          selectedCampaignRow?.data?.citrus_cm_campaign_campaign_status
        ];
      const newState = dropdownToStateMapping[dropDownSelection[0].value];
      payload.push({
        widget: 'campaign',
        actionType: `${retailer}CampaignStatusChange`,
        actionSource: {
          pageUrl: window.location.href
        },
        primaryKey: campaignId,
        actionPayload: {
          campaignId: campaignId,
          campaignType: 'productAds',
          new_campaign_state: newState
        },
        viewPayload: {
          campaignId: campaignId,
          campaignType: 'productAds',
          old_campaign_state: oldState,
          new_campaign_state: newState,
          current_status: currentStatus?.toUpperCase(),
          status: status?.toUpperCase()
        },
        previousEntityValue: oldState,
        newEntityValue: newState
      });
    });
    try {
      if (payload.length > 0) {
        await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
        this.$emit('openSnackbar', 'Action has been submitted');
      }
    } catch (err) {
      console.log(err);
      this.$emit('openSnackbar', 'Something went wrong.');
    }
  }
});
