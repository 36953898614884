<template>
  <div class="u-display-flex u-flex-direction-column">
    <!-- Start and end date will come here -->
    <StartAndNoEndDate
      :default-calender-dates="defaultCalenderDates"
      :show-time-zone="true"
      timezone="PST"
      @datesValuesUpdated="datesValuesUpdated"
    />

    <!-- Total Budget Section -->
    <div class="u-spacing-pt-xl">
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="totalBudgetTxt"
      >
        Total Budget:
        <span
          v-if="isWalmart"
          class="u-color-grey-lighter"
        >
          Optional
        </span>
      </div>
      <div class="u-spacing-pt-s">
        <rbNumberInput
          class="fit-content"
          :allow-empty-value="true"
          :options="totalBudgetInputOptions"
          data-cy="totalBudgetSection"
          @handleValue="handleTotalBudgetChange"
          @handleInput="handleTotalBudgetChange"
        />
        <FormErrorMessage
          :show-error="Boolean(formErrorObject.totalBudget)"
          :error-text="formErrorObject.totalBudget"
          data-cy="errorMsg"
        />
      </div>
    </div>

    <!-- Daily Budget Section -->
    <div class="u-spacing-pt-xl">
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="dailyBudgetTxt"
      >
        Daily Budget:
      </div>
      <div class="u-spacing-pt-s">
        <rbNumberInput
          class="fit-content"
          :allow-empty-value="true"
          :options="dailyBudgetInputOptions"
          data-cy="dailyBudgetSection"
          @handleValue="handleDailyBudgetChange"
          @handleInput="handleDailyBudgetChange"
        />
        <FormErrorMessage
          :show-error="Boolean(formErrorObject.dailyBudget)"
          :error-text="formErrorObject.dailyBudget"
          data-cy="errorMsg"
        />
      </div>
    </div>

    <!-- Advertiser section -->
    <div class="u-spacing-pt-xl fit-content">
      <div class="heading--input u-font-size-5 u-font-weight-600">
        Advertiser:
      </div>
      <div
        class="u-border-all u-position-relative u-border-radius-s u-border-width-s u-border-color-grey-xxx-light u-color-grey-base u-spacing-mt-s"
        :class="{ disabled: disableAdvertiserDropdown }"
      >
        <loader
          v-if="advertiserLoader"
          class="fill--parent"
          :color="'#3fadf7'"
        />
        <rb-select
          class="typeSelect"
          :send-details="true"
          :on-close="onSelectAdvertiserAction"
          :options="settingsAdvertisersOptions"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          >
            <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
              <div class="u-display-flex u-flex-direction-column">
                <span
                  data-cy="selectedAdvertiser"
                  class="u-font-size-7"
                  >{{
                    (selectedAdvertiser &&
                      selectedAdvertiser.advertiser_name) ||
                    'NA'
                  }}</span
                >
              </div>
            </div>
            <rb-icon
              data-cy="advertiserCaretDown"
              class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div class="u-display-flex u-flex-align-items-center u-font-size-7">
              {{ option.advertiser_name }}
            </div>
          </template>
        </rb-select>
      </div>
    </div>
  </div>
</template>
<script>
import rbNumberInput from '@/components/widgets/rbNumberInput.vue';
import loader from '@/components/basic/loader.vue';
import Vue from 'vue';
import StartAndNoEndDate from '@/components/ams/campaign_creation/walmart_steps/settings/StartAndNoEndDate.vue';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
export default {
  name: 'SettingsBody',
  components: {
    rbNumberInput,
    loader,
    StartAndNoEndDate,
    FormErrorMessage
  },
  props: {
    advertiserLoader: {
      type: Boolean,
      default: false
    },
    settingsAdvertisersOptions: {
      type: Array,
      default: () => []
    },
    selectedAdvertiser: {
      type: Object,
      default: () => {}
    },
    disableAdvertiserDropdown: {
      type: Boolean,
      default: false
    },
    defaultTotalBudget: {
      type: [Number, String],
      default: 0
    },
    defaultDailyBudget: {
      type: [Number, String],
      default: 0
    },
    defaultCalenderDates: {
      type: Object,
      default: () => ({
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      })
    },
    formErrorObject: {
      type: Object,
      default: {
        totalBudget: '',
        dailyBudget: ''
      }
    }
  },
  data: () => ({
    totalBudgetInputOptions: {
      prefix: Vue.prototype.$currency,
      value: 1
    },
    dailyBudgetInputOptions: {
      prefix: Vue.prototype.$currency,
      value: 1
    }
  }),
  computed: {
    isWalmart() {
      return this.$store.getters.getRetailer === 'walmart';
    }
  },
  watch: {
    defaultTotalBudget(newVal) {
      this.totalBudgetInputOptions.value = newVal;
    },
    defaultDailyBudget(newVal) {
      this.dailyBudgetInputOptions.value = newVal;
    }
  },
  created() {
    this.totalBudgetInputOptions.value = this.defaultTotalBudget;
    this.dailyBudgetInputOptions.value = this.defaultDailyBudget;
  },
  methods: {
    onSelectAdvertiserAction(context, action) {
      if (!action.length) return;
      this.$emit('handleSelectedAdvertiser', action[0]);
    },
    roundOffToTwoDecimalPlaces(value) {
      let newVal = value;
      if (newVal !== '') {
        newVal = Math.round(parseFloat(value) * 100) / 100;
      }
      return newVal;
    },
    handleTotalBudgetChange(value) {
      const newVal = this.roundOffToTwoDecimalPlaces(value);
      this.totalBudgetInputOptions.value = newVal;
      this.$emit('handleTotalBudgetChange', newVal);
    },
    handleDailyBudgetChange(value) {
      const newVal = this.roundOffToTwoDecimalPlaces(value);
      this.dailyBudgetInputOptions.value = newVal;
      this.$emit('handleDailyBudgetChange', newVal);
    },
    datesValuesUpdated(value) {
      this.$emit('datesValuesUpdated', value);
    }
  }
};
</script>
<style lang="css" scoped>
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.select-dropdown {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
  color: #e9eaeb;
}
.fit-content {
  width: fit-content;
  min-width: 20%;
}
.budget-container {
  border-radius: 4px;
}
.budget-input {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
}
.rb-input {
  border: none !important;
}
</style>
