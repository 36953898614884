<template>
  <div>
    <div
      class="u-display-flex u-flex-0 u-flex-align-items-center u-width-120px"
      :class="{
        percentBox: percentBox
      }"
    >
      <span
        v-if="showCurrency"
        class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
      >
        {{ $currency }}
      </span>
      <input
        :value="value"
        :type="inputType"
        class="rb-input currency-input u-display-flex u-flex-align-items-center"
        :min="min"
        :step="step"
        :max="max"
        autofocus
        @input="handleInput"
      />
      <span
        v-if="showPercentage"
        class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
      >
        %
      </span>
    </div>
    <div
      v-if="errorMessage"
      class="u-font-size-7 u-color-red-base u-spacing-mt-s"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0.01
    },
    value: {
      type: [String, Number],
      default: null
    },
    inputType: {
      type: String,
      default: 'number'
    },
    percentBox: {
      type: Boolean,
      default: false
    },
    showCurrency: {
      type: Boolean,
      default: true
    },
    showPercentage: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      textContent: this.value
    };
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style lang="css" scoped>
rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
rb-input.currency-input-right {
  border-left: 0;
  padding-right: 4px;
  height: 32px;
}
rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}
.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input-right {
  border-left: 0;
  padding-left: 5px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
</style>
