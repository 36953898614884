import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';

export default class Table1Service extends BaseWidgetService {
  getColumnDefs() {
    return [
      {
        headerName: 'SKU Details',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        }
      },
      {
        headerName: 'Brand',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        }
      },
      {
        headerName: 'Overall Content Score',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        }
      },
      {
        headerName: 'Title Length',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        }
      },
      {
        headerName: 'Description Length (words)',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        },
        maxWidth: 200
      },
      {
        headerName: 'Number of Bullet Points',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        },
        maxWidth: 200
      },
      {
        headerName: 'Number of Images',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        },
        maxWidth: 200
      },
      {
        headerName: 'Average Rating',
        field: 'dimension',
        type: 'string',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false
        },
        maxWidth: 200
      }
    ];
  }
}
