<template>
  <div
    class="chargeback-group-card card u-font-size-5"
    :class="classes"
  >
    <div
      class="chargeback-group-card__header u-font-size-6 u-font-weight-600 u-fill-color-grey-light u-text-transform-uppercase u-spacing-mb-xs"
    >
      {{ cardTitle }}
    </div>
    <div
      class="chargeback-group-card__description u-font-size-6 u-color-grey-mid-light"
    >
      {{ cardDescription }}
    </div>
    <div
      class="chargeback-group-card__chart u-spacing-pv-m u-display-flex u-flex-justify-content-center"
    >
      <Loader
        v-if="isChartLoading"
        :loading="isChartLoading"
        class="u-bg-color-grey-white"
        :color="'#3fadf7'"
        :size="'7rem'"
        :thickness="'.2rem'"
      />
      <chartWithGradient
        v-else
        :key="myKey"
        :metric-data="groupChartData"
        :metadata="{ name: 'chargebackDefectRate_' + index }"
        :card-primary-metric-color-code="chartColor"
        :metrics-list="metricsList"
        :chart-config="chartConfig"
      />
    </div>
    <metric
      :hide-zero="false"
      :data="
        isMetricLoading
          ? { tag1: 'loading' }
          : groupCardData.chargeback_amount_percentage
      "
      :config="metricConfig"
      size="xl"
    />
    <span class="u-display-flex u-spacing-mt-s u-spacing-mb-xs">
      <span class="u-spacing-mr-xs u-color-grey-lighter"> Amount : </span>
      <metric
        class="u-flex-1"
        :data="
          isMetricLoading
            ? { tag1: 'loading' }
            : groupCardData.total_chargeback_amount
        "
        :config="{ tag1Unit: { pre: 'currency' } }"
        size="xs"
      />
    </span>
    <span
      class="u-cursor-pointer u-color-grey-lighter u-font-weight-600 u-font-size-6 u-border-width-s u-border-bottom u-border-color-grey-xx-light view-chargebacks-action"
      @click="navigateToChargebackClaims"
    >
      View Chargebacks
    </span>
    <div
      class="chargeback-group-card__action u-font-size-7 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-mt-m"
      @click="toggleChargebackDefectCards"
    >
      <rb-icon
        :icon="isSelected ? 'collapse' : 'full-screen'"
        class="rb-icon--small u-spacing-mr-s"
      />
      {{ isSelected ? 'Hide Details' : 'More Details' }}
    </div>
  </div>
</template>
<script>
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';
import metric from '@/components/basic/metric.vue';
export default {
  name: 'ChargebackGroupCard',
  components: { chartWithGradient, Loader, metric },
  props: {
    index: {
      type: Number,
      default: 0
    },
    groupCardData: {
      type: Object,
      default: () => ({})
    },
    groupChartData: {
      type: Array,
      default: () => []
    },
    cardTitle: {
      type: String,
      default: ''
    },
    cardDescription: {
      type: String,
      default: ''
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Object,
      default: ''
    },
    isChartLoading: {
      type: Boolean,
      default: true
    },
    isMetricLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      myKey: 0,
      metricConfig: {
        invertTag2: true,
        tag1Unit: { pre: '', suff: '%', roundoff: 2 },
        tag2Unit: { suff: '%' }
      },
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false } },
        legend: false,
        size: { height: 65 },
        tooltip: false,
        tooltip_format: {
          'Chargeback Amount in %': { pre: '', roundoff: 2, suff: '%' }
        },
        point: false,
        line: { connectNull: true },
        // padding: { left: -8, right: -7, bottom: 5 },
        regions: {},
        timeseries: 'feed_date',
        tooltip_position: function (a, b, c, d) {
          return { top: -45, left: -20 };
        }
      },
      metricsList: [
        {
          label: 'Chargeback Amount in %',
          alias: 'chargeback_amount_percentage_v1'
        }
      ]
    };
  },
  computed: {
    chartColor() {
      return {
        chargeback_amount_percentage_v1:
          this.groupCardData?.chargeback_amount_percentage?.tag2 > 0
            ? '#D7263D'
            : '#3EA95E'
      };
    }
  },
  watch: {
    groupChartData: {
      handler() {
        this.myKey++;
      },
      deep: true
    }
  },
  methods: {
    navigateToChargebackClaims() {
      this.$router.push({
        name: 'chargeback_claims'
      });
    },
    toggleChargebackDefectCards() {
      this.$emit('toggleChargebackDefectCards', { cardId: this.index });
    }
  }
};
</script>
<style lang="css">
.chargeback-group-card {
  width: calc((100% - 7.2rem) / 7);
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  padding: 1.6rem 1.2rem;
}
.chargeback-group-card .rb-metric {
  align-items: center;
}
.chargeback-group-card .view-chargebacks-action:hover {
  color: #4b5158;
}
.chargeback-group-card .c3-tooltip-container .card > :first-child {
  padding: 0.8rem;
  margin: 0;
  border: none;
}
.chargeback-group-card .c3-tooltip-container .card > :first-child span {
  font-size: 13px;
}
.chargeback-group-card .c3-tooltip-container .card > :nth-child(2) {
  display: none;
}
.chargeback-group-card .c3-tooltip-container .card > :last-child {
  padding: 0 0.8rem;
  font-size: 11px;
  min-width: 200px !important;
}
</style>
