<template>
  <div
    class="collaboration expand"
    :style="{ top: topSpacing }"
  >
    <slot name="header">
      <div class="header">
        <span class="u-spacing-p-m u-font-size-5 u-font-weight-600">{{
          heading
        }}</span>
        <div
          class="u-display-flex u-flex-align-items-center u-cursor-pointer u-spacing-pr-m"
        >
          <span @click="closeWidget">
            <rb-icon
              class="u-color-grey-lighter rb-icon--small"
              :icon="'cross'"
            />
          </span>
        </div>
      </div>
    </slot>
    <slot name="body">
      <div
        id="activityBody"
        class="body"
      >
        <div
          v-if="data.load || data.noData || data.error"
          class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative"
        >
          <loader
            v-if="data.load"
            class="fill--parent"
            :loading="data.load"
            :color="'#007cf6'"
          />
          <div
            v-if="data.noData || !data"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter line-height-1_5"
          >
            <p>{{ emptyStateMessage }}</p>
          </div>
          <div
            v-if="data.error"
            class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
          >
            <p>Error Occurred. Please try again later</p>
          </div>
        </div>
        <div v-if="!data.load && !data.noData">
          <div
            v-if="showDateChip"
            class="u-display-flex u-flex-justify-content-center u-width-100 u-position-absolute"
            style="z-index: 10"
          >
            <span
              class="u-color-grey-white u-spacing-pv-xs u-spacing-ph-s u-border-radius-xl u-font-size-7 u-spacing-mt-xs u-bg-color-grey-lighter"
              style="opacity: 0.9"
              >{{ dateChip }}</span
            >
          </div>
          <div
            v-for="(activity, $index) in data.rows || []"
            :key="$index"
            class="u-position-relative u-border-left u-border-width-s u-border-color-grey-xx-light u-spacing-ml-xl u-spacing-mr-m u-spacing-pt-m u-spacing-pb-s u-spacing-ph-l timeline-holders"
            :class="activity.cssClasses"
          >
            <div
              v-if="activity.showYear"
              class="created-year"
            >
              <span>{{ activity.date.year }}</span>
            </div>
            <div
              v-if="activity.showDate"
              class="activity-date u-display-flex u-flex-align-items-center"
            >
              <span class="activity-day">{{ activity.date.day }}</span>
              <span class="activity-month">{{ activity.date.month }}</span>
            </div>
            <div
              v-for="(entry, index) in activity.entries"
              :key="index"
              class="templatesContainer u-spacing-pt-xs"
            >
              <eventComponent :widget-data="getUpdatedEntryData(entry)" />
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import eventComponent from '@/components/widgets/collaboration/eventComponent.vue';
import loader from '@/components/basic/loader';
import { editHistoryRetailerConfig } from '@/components/ams/budgetPlanner/retailerConfig.js';
import { cloneDeep } from 'lodash';
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    eventComponent,
    loader
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          load: true
        };
      }
    },
    pageName: {
      type: String
    },
    closeActivityLog: {
      type: Function
    },
    heading: {
      type: String,
      default: 'Activity log'
    },
    topSpacing: {
      type: String,
      default: '104px'
    },
    retailer: {
      type: String,
      default: 'amazon'
    },
    isBudgetOptimizer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateChip: new Date(),
      showDateChip: false
    };
  },
  computed: {
    emptyStateMessage() {
      return `No activity has been logged for this ${this.pageName} yet.`;
    }
  },
  mounted() {
    document
      .getElementById('activityBody')
      .addEventListener('scroll', this.setDisplayTopYear);
  },
  methods: {
    closeWidget() {
      this.closeActivityLog();
    },
    setDisplayTopYear(e) {
      const datesComponent = document.querySelectorAll('.timeline-holders');
      let totalHeight = 0;
      for (let i = 0; i < datesComponent.length; i++) {
        totalHeight += datesComponent[i].offsetHeight;
        if (totalHeight >= e.target.scrollTop) {
          this.setDateChip(i);
          break;
        }
      }
      this.showDateChip = e.target.scrollTop > 40;
    },
    setDateChip(index) {
      this.dateChip = this.data.rows[index].date.displayChip;
    },
    debounce(func, delay) {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },
    getUpdatedEntryData(entry) {
      if (entry.viewPayload.entityName && this.isBudgetOptimizer) {
        const updatedEntry = cloneDeep(entry);
        const enityName = updatedEntry.viewPayload.entityName.split('-');
        if (enityName[0] === 'Profile ') {
          updatedEntry.viewPayload.entityName = `${
            editHistoryRetailerConfig[this.retailer]
          } - ${enityName[1]}`;
        }
        return updatedEntry;
      }
      return entry;
    }
  }
};
</script>
<style lang="css" scoped>
.collaboration {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 22%;
  min-width: 22%;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.1);
  background: #fff;
  z-index: 101;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  box-shadow: 0 0 4px 0 rgba(43, 51, 59, 0.2);
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9eaeb;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.body .created-year {
  right: 0;
  top: -6px;
  color: #aaadb1;
  font-size: 1.1rem;
  position: absolute;
  background-color: #fff;
  padding-left: 4px;
}

.body .activity-date {
  left: -9px;
  background-color: #fff;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
}

.body .activity-date .activity-day {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2b333b;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
}

.body .activity-date .activity-month {
  font-size: 1.1rem;
  color: #4b5158;
  padding-bottom: 0.4rem;
}
.expand {
  transition: max-width 1s ease-in-out;
}

.collapsed {
  transition: max-width 1s ease-in-out;
  max-width: 0px;
}

.logo--client-name {
  background-color: #ff6072;
  cursor: pointer;
  color: #fff;
  font-size: 9px;
  font-family: 'ProximaNova';
  border-radius: 1000px;
  width: 16px;
  height: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.line-height-1_5 {
  line-height: 1.5;
}
</style>
