<template>
  <div
    class="u-display-flex u-flex-justify-content-center u-flex-direction-column u-height-100 u-spacing-p-m u-spacing-mh-s u-cursor-auto"
    :class="{
      'dashed-border': params.rowIndex !== params.dataListLength - 1,
      'disabled-background': isDisabledRow
    }"
  >
    <span
      class="u-spacing-mb-s u-font-size-5 u-color-grey-lighter u-font-weight-600 u-text-case-upper"
      >{{ dimensionTitle }}</span
    >

    <div
      class="u-font-size-6 u-color-grey-mid-light"
      style="white-space: normal"
    >
      {{ params.value.desc }}
      <div
        class="u-font-weight-bold u-border-bottom-dotted u-fit-content u-spacing-pt-s u-cursor-pointer"
        :class="{ disabled: isDisabledRow }"
        :data-heap="params.value.name"
        @click="toggleExpandable"
      >
        Know more
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  computed: {
    dimensionTitle() {
      return this.params.value.label;
    },
    isDisabledRow() {
      if (this.params?.getDisabledRowValue) {
        return this.params?.getDisabledRowValue(this.params);
      }
      return false;
    }
  },
  methods: {
    toggleExpandable() {
      if (!this.isDisabledRow) {
        eventBus.$emit('toggleExpandable', this.params.value);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.dashed-border {
  border-bottom: 1px dashed #aaadb1;
}
.u-fit-content {
  width: fit-content;
}
.u-cursor-auto {
  cursor: auto;
}
.disabled-background {
  background: rgba($color: #000000, $alpha: 0.08);
}
</style>
