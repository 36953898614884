<template>
  <rb-insights-table
    :table-column="columnDefs"
    :table-row="tableData"
    :grid-options="gridOptions"
    :expand-cell-width="32"
    :row-height="200"
    class="table-without-border cva-leaders-table"
  />
</template>

<script>
import { checkForNoDataRow } from '@/components/pages/insights/amazon/cva/utils';
export default {
  props: {
    service: {
      type: Object,
      required: true
    },
    pageSettings: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      gridOptions: {
        suppressColumnVirtualisation: true,
        getRowHeight: this.getRowHeight
      },
      rowHeights: {
        noData: 120,
        isDetailedView: 220,
        default: 200
      }
    };
  },
  computed: {
    tableData() {
      return this.service.tableData;
    },
    columnDefs() {
      return this.service.getColumnDefs();
    }
  },
  watch: {
    'service.isDetailedView': {
      handler() {
        this.gridOptions?.api?.resetRowHeights();
      },
      deep: true,
      immediate: true
    },
    pageSettings: {
      handler(newVal) {
        this.service.fetchData(newVal);
      },
      deep: true,
      immediate: true
    },
    columnDefs: {
      handler() {
        this.gridOptions.api?.resetRowHeights();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getRowHeight(params) {
      const dimensionName = params.data?.dimension?.name;
      if (checkForNoDataRow(params)) {
        return this.rowHeights.noData;
      }
      if (
        dimensionName === 'Pricing Leadership' &&
        this.service.isDetailedView
      ) {
        return this.rowHeights.isDetailedView;
      }
      return this.rowHeights.default;
    }
  }
};
</script>

<style lang="css">
.cva-leaders-table.table-without-border
  .ag-pinned-left-header
  .ag-header-cell:last-child,
.cva-leaders-table.table-without-border .ag-pinned-left-cols-viewport {
  border-right: none;
}
</style>
