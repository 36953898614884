<template>
  <div
    :id="id + '-tippy-reference'"
    ref="tippy-reference"
    :class="[
      position,
      {
        'is-disabled': disabled,
        'is-hoverable': hoverable,
        'is-inline': inline,
        'is-active': isActive || inline,
        'is-mobile-modal': isMobileModal
      },
      className
    ]"
    class="rb-select"
    @tippyMounted="handleTippyMount"
  >
    <div
      v-if="!inline"
      ref="trigger"
      v-tippy="tippyOptions"
      role="button"
      class="u-width-100 dropdown-trigger button button--hollow u-height-100"
      @click.stop="selectTriggered"
    >
      <slot name="trigger" />
    </div>
    <div
      v-show="showDropdown"
      :id="id"
      ref="dropdownMenu"
      :class="[
        className ? className + ' u-width-' + width : 'u-width-' + width,
        'depth-' + depth
      ]"
      class="rb-select-menu u-spacing-pv-s"
    >
      <slot name="header" />
      <div
        v-if="searchEnabled || enableSearchInternally"
        class="u-display-flex search__input u-width-100 u-box-sizing-border-box"
      >
        <rb-input
          v-if="!clientSearch"
          ref="searchInput"
          v-model="searchText"
          :placeholder="placeHolder"
          :rounded="false"
          class="u-flex-1"
          data-cy="searchInputDropdown"
          @input="filterOptions"
        />
        <rb-input
          v-if="clientSearch"
          ref="localSearch"
          v-model="searchText"
          :placeholder="placeHolder"
          :rounded="false"
          data-cy="localSearchInputDropdown"
          class="u-flex-1"
        />
        <div class="icon-frame icon-frame_right u-color-grey-white">
          <rb-button
            :type="'hollow'"
            :icon-right="'search'"
          />
        </div>
      </div>
      <div
        v-if="!clientSearch && !groupByKey"
        class="dropdown-content u-overflow-auto u-max-height-400"
      >
        <div
          v-if="noData && !backendSearching"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
        >
          {{ noData }}
        </div>
        <div
          v-if="!noData && backendSearching"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
        >
          Fetching results.
        </div>
        <div
          v-for="(dropdownItem, index) in options"
          v-if="!backendSearching"
          :key="index"
          :ref="'currentDropdownElements'"
          :value="dropdownItem"
          class="select__dropdown-list-item"
          :class="{
            'has-children': dropdownItem.children,
            'tree-spawn-hover-effect': index === hoverIndex && spawnsTree,
            'u-color-grey-lighter removePointerEvents': dropdownItem.disable
          }"
          data-cy="dropDownItem"
          @mouseover="handleMouseEnter(dropdownItem, index)"
          @click="selectItem(dropdownItem, index)"
        >
          <div
            class="field"
            data-cy="dropDownItem"
          >
            <slot
              v-bind="dropdownItem"
              name="item"
            >
              <rb-checkbox
                v-if="isMultiSelect"
                v-model="dropdownItem.selected"
                @input="onInput(dropdownItem)"
              >
                <div slot="label">
                  <div class="u-display-flex u-flex-align-items-center">
                    <rb-icon
                      v-if="dropdownItem.icon"
                      class="u-spacing-mr-xs"
                      :icon="dropdownItem.icon.name"
                      :class="dropdownItem.icon.class"
                      :data-cy="`metricTitle-${index}-${dropdownItem.icon}`"
                    />
                    <p
                      class="u-display-block u-white-space-no-wrap u-text-case-title"
                      :data-cy="`metricTitle-${index}-${dropdownItem.title}`"
                    >
                      {{ dropdownItem.title }}
                    </p>
                  </div>
                  <span
                    v-if="dropdownItem.description"
                    class="u-white-space-no-wrap u-text-case-title"
                    :data-cy="`metricDescription-${dropdownItem.description}`"
                    >{{ dropdownItem.description }}</span
                  >
                </div>
              </rb-checkbox>
              <div
                v-if="!isMultiSelect"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  v-if="dropdownItem.icon"
                  class="u-spacing-mr-xs"
                  :icon="dropdownItem.icon.name"
                  :class="dropdownItem.icon.class"
                  :data-cy="`metricTitle-${index}-${dropdownItem.icon}`"
                />
                <p
                  class="u-text-overflow-ellipsis u-text-case-title"
                  :data-cy="`metricTitle-${index}-${dropdownItem.title}`"
                >
                  {{ dropdownItem.title }}
                </p>
              </div>
              <p
                v-if="!isMultiSelect && dropdownItem.description"
                class="u-spacing-pv-s u-text-overflow-ellipsis u-font-size-7 u-text-case-title"
                :data-cy="`metricDescription-${dropdownItem.description}`"
              >
                {{ dropdownItem.description }}
              </p>
            </slot>
          </div>
        </div>
      </div>
      <section
        v-if="!clientSearch && groupByKey"
        class="dropdown-content u-overflow-auto u-max-height-400"
        data-cy="dropDownContent"
      >
        <div
          v-if="noData && !backendSearching"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
          data-cy="noData"
        >
          {{ noData }}
        </div>
        <div
          v-if="!noData && backendSearching"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
          data-cy="fetchingResultsTxt"
        >
          Fetching results.
        </div>
        <div
          v-for="(option, title, $index) in getOptions"
          :id="option.label"
          :key="$index"
        >
          <p
            v-show="title != 'undefined'"
            class="select__dropdown-list-heading u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between u-cursor-pointer u-font-size-5"
            :data-cy="`DropDownOptionMetricListType-${title}`"
            @click.stop="option.open = !option.open"
          >
            {{ title }}
            <rb-icon
              v-if="!option.open"
              style="margin-left: 12px"
              :icon="'arrow1-down'"
              class="rb-icon--small u-color-grey-light"
              data-cy="clickDropDownCaret"
            />
            <rb-icon
              v-if="option.open"
              style="margin-left: 12px"
              :icon="'arrow1-up'"
              class="rb-icon--small u-color-grey-light"
              data-cy="arrowUpClikIcon"
            />
          </p>
          <div
            v-for="(dropdownItem, index) in option.list"
            v-show="option.open"
            :key="index"
            :ref="'currentDropdownElements' + title"
            class="select__dropdown-list-item"
            :class="{
              'has-children': dropdownItem.children,
              'tree-spawn-hover-effect':
                index === hoverIndex &&
                title === hoverIndexNamespace &&
                spawnsTree,
              'u-color-grey-lighter removePointerEvents': dropdownItem.disable
            }"
            data-cy="dropDownItem"
            @click="selectItem(dropdownItem, index)"
            @mouseover="handleMouseEnter(dropdownItem, index, title)"
          >
            <slot
              v-bind="dropdownItem"
              name="item"
            >
              <rb-checkbox
                v-if="isMultiSelect"
                v-model="dropdownItem.selected"
                @input="onInput(dropdownItem)"
              >
                <div slot="label">
                  <div class="u-display-flex u-flex-align-items-center">
                    <rb-icon
                      v-if="dropdownItem.icon"
                      class="u-spacing-mr-xs"
                      :icon="dropdownItem.icon.name"
                      :class="dropdownItem.icon.class"
                      :data-cy="`DropDownIcon-${dropdownItem.icon}`"
                    />
                    <p
                      class="u-display-block u-white-space-no-wrap u-text-case-title"
                      :data-cy="`DropDownTitle-${dropdownItem.title}`"
                    >
                      {{ dropdownItem.title }}
                    </p>
                  </div>
                  <span
                    v-if="dropdownItem.description"
                    class="u-white-space-no-wrap u-text-case-title"
                    :data-cy="`DropDownDesc-${dropdownItem.description}`"
                    >{{ dropdownItem.description }}</span
                  >
                </div>
              </rb-checkbox>
              <div
                v-if="!isMultiSelect"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  v-if="dropdownItem.icon"
                  class="u-spacing-mr-xs"
                  :icon="dropdownItem.icon.name"
                  :class="dropdownItem.icon.class"
                  :data-cy="`DropDownIcon-${dropdownItem.icon}`"
                />
                <p
                  class="u-text-overflow-ellipsis u-text-case-title"
                  :data-cy="`DropDownTitle-${dropdownItem.title}`"
                >
                  {{ dropdownItem.title }}
                </p>
              </div>
              <p
                v-if="!isMultiSelect && dropdownItem.description"
                class="u-spacing-pv-s u-text-overflow-ellipsis u-font-size-7 u-text-case-title"
                :data-cy="`DropDownDesc-${dropdownItem.description}`"
              >
                {{ dropdownItem.description }}
              </p>
            </slot>
          </div>
        </div>
      </section>
      <div
        v-if="clientSearch && !groupByKey"
        class="dropdown-content u-overflow-auto u-max-height-400"
      >
        <div
          v-if="getOptions && getOptions.length === 0"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
          data-cy="NoResultsFound"
        >
          No results found.
        </div>
        <div
          v-for="(dropdownItem, index) in getOptions"
          :key="index"
          :ref="'currentDropdownElements'"
          v-tippy="{
            interactive: false,
            theme: 'light',
            placement: 'left',
            arrow: false,
            maxWidth: '456px',
            popperOptions: {
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }
          }"
          :title="
            metricTooltipEnabled(dropdownItem) ? getTooltip(dropdownItem) : null
          "
          :value="dropdownItem"
          class="select__dropdown-list-item"
          :class="{
            'has-children': dropdownItem.children,
            'custom-padding': customPadding,
            'tree-spawn-hover-effect': index === hoverIndex && spawnsTree,
            'u-color-grey-lighter removePointerEvents': dropdownItem.disable
          }"
          data-cy="dropDownItem"
          @click.stop.prevent="selectItem(dropdownItem, index)"
          @mouseover="handleMouseEnter(dropdownItem, index)"
        >
          <div
            class="field"
            data-cy="dropDownItem"
          >
            <slot
              v-bind="dropdownItem"
              name="item"
            >
              <rb-checkbox
                v-if="isMultiSelect"
                v-model="dropdownItem.selected"
                @input="onInput(dropdownItem)"
              >
                <div slot="label">
                  <div class="u-display-flex u-flex-align-items-center">
                    <rb-icon
                      v-if="dropdownItem.icon"
                      class="u-spacing-mr-xs"
                      :icon="dropdownItem.icon.name"
                      :class="dropdownItem.icon.class"
                      data-cy="dropdownIcon"
                    />
                    <p
                      class="u-display-block u-white-space-no-wrap u-text-case-title"
                      data-cy="dropDownTitle"
                    >
                      {{ dropdownItem.title }}
                    </p>
                  </div>
                  <span
                    v-if="dropdownItem.description"
                    class="u-white-space-no-wrap u-text-case-title"
                    data-cy="dropDownDescription"
                    >{{ dropdownItem.description }}</span
                  >
                </div>
              </rb-checkbox>
              <div
                v-if="!isMultiSelect"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  v-if="dropdownItem.icon"
                  class="u-spacing-mr-xs"
                  :icon="dropdownItem.icon.name"
                  :class="dropdownItem.icon.class"
                />
                <p class="u-text-overflow-ellipsis u-text-case-title">
                  {{ dropdownItem.title }}
                </p>
              </div>
              <p
                v-if="!isMultiSelect && dropdownItem.description"
                class="u-spacing-pv-s u-text-overflow-ellipsis u-font-size-7 u-text-case-title"
              >
                {{ dropdownItem.description }}
              </p>
            </slot>
          </div>
        </div>
      </div>
      <section
        v-if="clientSearch && groupByKey"
        class="dropdown-content u-overflow-auto u-max-height-400"
      >
        <div
          v-if="getGroupedKeysLength"
          class="u-font-size-5 u-display-flex u-spacing-m-xl"
        >
          No results found.
        </div>
        <div
          v-for="(option, title, $index) in getOptions"
          :id="option.label"
          :key="$index"
        >
          <div />
          <p
            v-show="title != 'undefined'"
            class="select__dropdown-list-heading u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between u-cursor-pointer u-font-size-5"
            data-cy="DropDownOptionMetricListTypeHeading"
            @click.stop="option.open = !option.open"
          >
            {{ title }}
            <rb-icon
              v-if="!option.open"
              style="margin-left: 12px"
              :icon="'arrow1-down'"
              class="rb-icon--small u-color-grey-light"
            />
            <rb-icon
              v-if="option.open"
              style="margin-left: 12px"
              :icon="'arrow1-up'"
              class="rb-icon--small u-color-grey-light"
            />
          </p>
          <div
            v-for="(dropdownItem, index) in option.list"
            v-show="option.open && !dropdownItem.waterfall"
            :key="index"
            :ref="'currentDropdownElements' + title"
            class="select__dropdown-list-item"
            data-cy="dropDownItem"
            :class="{
              'has-children': dropdownItem.children,
              'tree-spawn-hover-effect':
                index === hoverIndex &&
                title === hoverIndexNamespace &&
                spawnsTree
            }"
            @click="selectItem(dropdownItem, index)"
            @mouseover="handleMouseEnter(dropdownItem, index, title)"
          >
            <slot
              v-bind="dropdownItem"
              name="item"
            >
              <rb-checkbox
                v-if="isMultiSelect"
                v-model="dropdownItem.selected"
                @input="onInput(dropdownItem)"
              >
                <div slot="label">
                  <p
                    class="u-display-block u-white-space-no-wrap u-text-case-title"
                    data-cy="dropDownTitle"
                  >
                    {{ dropdownItem.title }}
                  </p>
                  <span
                    v-if="dropdownItem.description"
                    class="u-white-space-no-wrap u-text-case-title"
                    >{{ dropdownItem.description }}</span
                  >
                </div>
              </rb-checkbox>
              <p
                v-if="!isMultiSelect"
                class="u-text-overflow-ellipsis u-text-case-title"
              >
                {{ dropdownItem.title }}
              </p>
              <p
                v-if="!isMultiSelect && dropdownItem.description"
                class="u-spacing-pv-s u-text-overflow-ellipsis u-font-size-7 u-text-case-title"
              >
                {{ dropdownItem.description }}
              </p>
            </slot>
          </div>
        </div>
      </section>
      <slot name="footer" />
    </div>
    <div
      v-for="(option, index) in getOptionsToRecurseOn"
      :key="depth + '-' + index"
    >
      <RbSelectV2
        v-if="index === hoverIndex && option.children"
        :id="id + '-nested'"
        :initial-options="initialOptions ? initialOptions : options"
        :note-generator-metric-tooltip="noteGeneratorMetricTooltip"
        :options="option.children"
        :depth="depth + 1"
        :send-details="sendDetails"
        :on-close="onClose"
        :width="width"
        :is-waterfall-dropdown="isWaterfallDropdown"
        :dropdown-direction="nextDropdownDirection"
        :dropdown-tree-id="getDropdownTreeId"
        :tree-path="getTreePath"
        :is-multi-select="isMultiSelect"
        :context="context"
        @tippyMounted="handleTippyMount"
      >
        <!-- :customTriggerEnabled="customTriggerEnabled"
        :customTrigger="customTrigger"
        :showAll="showAll"
        :value="value"
        :placeHolder="placeHolder"
        :searchEnableThreshold="searchEnableThreshold"
        :disabled="disabled"
        :hoverable="hoverable"
        :clientSearch="clientSearch"
        :searchListener="searchListener"
        :position="position"
        :mobileModal="mobileModal"
        :className="className"
        :groupByKey="groupByKey" -->
        <template
          v-for="slot in getCurrentScopedSlots"
          :slot="slot"
          slot-scope="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </RbSelectV2>
    </div>
  </div>
</template>
<script>
import { debounce, cloneDeep } from 'lodash';
export default {
  name: 'RbSelectV2',
  components: {},
  props: {
    isOnClickCustomPresent: {
      type: Boolean,
      default: false
    },
    onSwitchChangeCustom: {
      type: Function,
      default: function () {}
    },
    customPadding: {
      type: Boolean,
      default: false
    },
    noteGeneratorMetricTooltip: {
      type: Function,
      default: () => {}
    },
    treePath: {
      type: Array,
      default: () => {
        return [];
      }
    },
    initialOptions: {
      type: [Array],
      default: null
    },
    dropdownTreeId: {
      type: String,
      default: null
    },
    dropdownDirection: {
      type: String,
      default: null
    },
    isWaterfallDropdown: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 30
    },
    openDirection: {
      type: String,
      default: 'bottom-start'
    },
    width: {
      type: String,
      default: 'auto'
    },
    sendDetails: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    context: [Object, Array],
    customTriggerEnabled: {
      type: Boolean,
      default: false
    },
    customTrigger: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: true
    },
    searchEnabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    },
    options: {
      type: [Array],
      default: null
    },
    placeHolder: {
      type: String,
      default: 'Search'
    },
    searchEnableThreshold: {
      type: Number,
      default: 10
    },
    disabled: Boolean,
    hoverable: Boolean,
    inline: Boolean,
    clientSearch: {
      type: Boolean,
      default: true
    },
    // eslint-disable-next-line vue/require-default-prop
    searchListener: Function,
    // eslint-disable-next-line vue/require-default-prop
    onClose: Function,
    isMultiSelect: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    position: {
      type: String,
      validator(value) {
        return (
          [
            'is-top-right',
            'is-top-left',
            'is-bottom-left',
            'is-bottom-right'
          ].indexOf(value) > -1
        );
      }
    },
    mobileModal: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: null
    },
    groupByKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      COMPONENT_OFFSET: 2,
      VERTICAL_PADDING: 8,
      showDropdown: false,
      tippyOptions: {},
      id: null,
      noData: false,
      backendSearching: false,
      searchText: '',
      _customTrigger: false,
      selected: [],
      selectedDetails: [],
      isActive: false,
      _isDropdown: true, // Used internally by DropdownItem
      text: this.placeHolder,
      enableSearchInternally: false,
      groupByOptions: {},
      hoverIndex: null,
      hoverIndexNamespace: null,
      nextDropdownDirection: null
    };
  },
  computed: {
    getCurrentScopedSlots() {
      delete this.$scopedSlots.trigger;
      return Object.keys(this.$scopedSlots);
    },
    spawnsTree() {
      const condition = (this.getOptionsToRecurseOn[this.hoverIndex] || {})
        .children;
      if (condition) {
        return true;
      }
      return false;
    },
    getOptionsToRecurseOn() {
      if (this.groupByKey) {
        return (
          (this.groupByOptions &&
            this.groupByOptions[this.hoverIndexNamespace] &&
            this.groupByOptions[this.hoverIndexNamespace].list) ||
          []
        );
      } else {
        return this.getOptions;
      }
    },
    getGroupedKeysLength() {
      const optionKeys = Object.keys(this.getOptions || []);
      return optionKeys.length === 0;
    },
    getOptionsLength() {
      return this.options.length;
    },
    getTreePath() {
      const treePath = cloneDeep(this.treePath);
      if (this.hoverIndex || this.hoverIndex === 0) {
        treePath.push({ ...this.options[this.hoverIndex] });
      }
      return treePath;
    },
    currentTreePath() {},
    getDropdownTreeId() {
      if (this.dropdownTreeId) {
        return this.dropdownTreeId;
      }
      return this.id;
    },
    dropdownOffset() {
      let activeDropdownElement = null;
      if (this.hoverIndexNamespace) {
        activeDropdownElement =
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace] &&
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace][
            this.hoverIndex
          ];
      } else {
        activeDropdownElement =
          this.$refs.currentDropdownElements &&
          this.$refs.currentDropdownElements[this.hoverIndex];
      }
      const currentTippyDimesions =
        activeDropdownElement?.getBoundingClientRect() || 0;
      return currentTippyDimesions.width;
    },
    isMobileModal() {
      return this.mobileModal && !this.inline && !this.hoverable;
    },
    getOptions() {
      if (!this.options) {
        return null;
      }

      var _aReturn = this.options.filter(
        function (item) {
          if (item.title === 'All' && this.showAll === false) {
            return false;
          }

          for (var i in item) {
            if (
              (item[i] || '')
                .toString()
                .toLowerCase()
                .indexOf((this.searchText || '').toLowerCase()) !== -1
            ) {
              return true;
            }
          }

          return false;
        }.bind(this)
      );

      if (_aReturn.length >= this.searchEnableThreshold) {
        this.enableSearchInternally = true;
      }
      if (this.groupByKey) {
        let _temp = [...this.options];
        if (this.searchText && this.clientSearch) {
          _temp = _temp.filter(
            function (item) {
              if (item.title === 'All' && this.showAll === false) {
                return false;
              }
              if (
                (item?.label &&
                  item?.label
                    .toLowerCase()
                    .indexOf((this.searchText || '').toLowerCase()) !== -1) ||
                (item?.title &&
                  item?.title
                    .toLowerCase()
                    .indexOf((this.searchText || '').toLowerCase()) !== -1)
              ) {
                return true;
              }

              return false;
            }.bind(this)
          );
        }
        this.groupByOptions = _temp.reduce((acc, item) => {
          if (acc[item[this.groupByKey]]) {
            acc[item[this.groupByKey]].list.push(item);
          } else {
            acc[item[this.groupByKey]] = {};
            acc[item[this.groupByKey]].open = true;
            acc[item[this.groupByKey]].list = [];
            acc[item[this.groupByKey]].list.push(item);
          }
          return acc;
        }, {});
        return { ...this.groupByOptions };
      }
      return _aReturn;
    }
  },
  watch: {
    options(options) {
      // console.log('options')
      this.selectedDetails = [];
      for (let i = 0; i < (options || []).length; i++) {
        if (options[i].selected) {
          this.selectedDetails.push(options[i]);
        }
      }
      if (!this.clientSearch) {
        if (!options) {
          options = [];
        }
        if (options.length === 0) {
          this.backendSearching = false;
          this.noData = 'No results found, change search query';
        } else {
          this.backendSearching = false;
          this.noData = null;
        }
      }
    },
    customTrigger(value) {
      this._customTrigger = value;
      if (value) {
        this.isActive = value;
      }
    },
    /**
     * When v-model is changed set the new selected item.
     */
    value(value) {
      // console.log('value')
      this.setValue(value);
    },
    /**
     * Emit event when isActive value is changed.
     */
    isActive(value) {
      // console.log('isActive')
      this.$emit('active-change', value);
      if (
        this.onClose &&
        value === false &&
        !this.wasClickedOutside &&
        !this.isWaterfallDropdown
      ) {
        this.isActive = false;
        if (this.sendDetails) {
          this.onClose(this.context, this.selectedDetails);
          this.tippyInstance.hide();
        } else {
          this.onClose(this.context, this.selected);
          this.tippyInstance.hide();
        }
      } else {
        if (!value) {
          this.tippyInstance.hide();
          this.isActive = false;
        }
        this.closeAllDropdowns(this.getDropdownTreeId);
      }
    }
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.id = 'select-' + this._uid;
      setTimeout(() => {
        this.showDropdown = true;
        this.tippyOptions = {
          html: '#' + this.id,
          reactive: true,
          offset: '0px, 0px',
          placement: this.openDirection,
          interactive: true,
          theme: 'dropdown',
          trigger: 'click',
          duration: [0, 0],
          onShow: this.onShow,
          onHide: this.onHide,
          class: this.className,
          tippyTreeId: this.getDropdownTreeId
        };
        setTimeout(() => {
          this.tippyInstance =
            this.$el.querySelector('.dropdown-trigger')._tippy;
          this.$emit('tippyMounted', {
            tippyInstance: this.tippyInstance,
            id: this.id
          });
        });
      });
    });
  },
  created() {
    // console.log('this.slots', this.$slots, this.$scopedSlots);
    this.selectedDetails = [];
    for (let i = 0; i < (this.options || []).length; i++) {
      if (this.options[i].selected) {
        this.selectedDetails.push(this.options[i]);
      }
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  destroyed() {},
  methods: {
    metricTooltipEnabled(option) {
      const initialOptions = this.initialOptions;
      const metric = initialOptions?.find((x) => x.alias === option.metricKey);
      return metric?.metadata?.metricSelectionTooltipEnabled;
    },
    getTooltip(option) {
      const initialOptions = this.initialOptions;
      const metric = initialOptions?.find((x) => x.alias === option.metricKey);
      const tooltip = metric?.tooltip;
      const header = metric?.metadata?.tooltipHeader;
      const note = this.noteGeneratorMetricTooltip(option);
      return `
      <div class="ph-0">
        <div class="u-spacing-p-m">
          <div class="u-spacing-mb-m u-font-size-4 u-font-weight-600">${header}</div>
          <p class="u-font-size-5 u-color-grey-light u-line-height-1-5 u-font-weight-normal">${tooltip}</p>
        </div>
        ${note}
      </div>
      `;
    },
    findDropdownDirection(currentTippyDimensions, nestedTippyDimensions) {
      const viewPortWidth = document.documentElement.clientWidth;
      const currentTippyXStart = currentTippyDimensions.x;
      const currentTippyXEnd =
        currentTippyXStart + currentTippyDimensions.width;
      if (this.dropdownDirection) {
        if (this.dropdownDirection === 'right') {
          if (currentTippyXEnd + nestedTippyDimensions.width > viewPortWidth) {
            this.nextDropdownDirection = 'left';
          } else {
            this.nextDropdownDirection = 'right';
          }
        } else {
          if (currentTippyXStart - nestedTippyDimensions.width < 0) {
            this.nextDropdownDirection = 'right';
          } else {
            this.nextDropdownDirection = 'left';
          }
        }
      } else {
        const leftDistance = currentTippyXStart;
        const rightDistance = viewPortWidth - currentTippyXEnd;
        const direction = rightDistance >= leftDistance ? 'right' : 'left';
        this.nextDropdownDirection = direction;
        // this.nextDropdownDirection = 'right'
      }
    },
    toggleDropdownDirection() {
      if (this.nextDropdownDirection === 'right') {
        this.nextDropdownDirection = 'left';
      } else {
        this.nextDropdownDirection = 'right';
      }
    },
    verticalIndent(nestedTippyNode) {
      let displacement = 0;
      let activeDropdownElement = null;
      const viewPortHeight = document.documentElement.clientHeight;
      if (this.hoverIndexNamespace) {
        activeDropdownElement =
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace] &&
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace][
            this.hoverIndex
          ];
      } else {
        activeDropdownElement =
          this.$refs.currentDropdownElements &&
          this.$refs.currentDropdownElements[this.hoverIndex];
      }
      if (activeDropdownElement) {
        const activeDropdownElementDimensions =
          activeDropdownElement?.getBoundingClientRect();
        const nestedTippyNodeDimensions =
          nestedTippyNode?.getBoundingClientRect();
        if (activeDropdownElementDimensions && nestedTippyNodeDimensions) {
          if (
            activeDropdownElementDimensions.y +
              nestedTippyNodeDimensions.height <=
            viewPortHeight
          ) {
            displacement =
              activeDropdownElementDimensions.y -
              nestedTippyNodeDimensions.y -
              this.VERTICAL_PADDING;
          } else {
            displacement =
              activeDropdownElementDimensions.y +
              activeDropdownElementDimensions.height -
              nestedTippyNodeDimensions.y -
              nestedTippyNodeDimensions.height +
              this.VERTICAL_PADDING;
          }
        }
      }
      nestedTippyNode.style.transform =
        nestedTippyNode.style.transform + `translateY(${displacement}px)`;
    },
    handleMouseLeave() {
      this.hoverIndex = null;
    },
    handleTippyMount(data) {
      const { tippyInstance: thatTippyInstance } = data;
      const { id: currentTippyId } = this.tippyInstance;
      const { id: nestedTippyId } = thatTippyInstance;
      thatTippyInstance.show();
      this.currentTippyId = currentTippyId;
      this.nestedTippyId = nestedTippyId;
      const currentTippyNode = document.querySelector(
        '#tippy-' + currentTippyId + '> .tippy-tooltip'
      );
      const nestedTippyNode = document.querySelector(
        '#tippy-' + nestedTippyId + '> .tippy-tooltip'
      );
      nestedTippyNode.style.visibility = 'hidden';
      setTimeout(() => {
        const currentTippyNodeDimensions =
          currentTippyNode?.getBoundingClientRect();
        const nestedTippyNodeDimensions =
          nestedTippyNode?.getBoundingClientRect();
        this.findDropdownDirection(
          currentTippyNodeDimensions,
          nestedTippyNodeDimensions
        );
        // const nestedTippyReferenceDimensions = currentTippyReference.getBoundingClientRect();
        if (currentTippyNodeDimensions && nestedTippyNodeDimensions) {
          let xCords = null;
          if (this.nextDropdownDirection === 'right') {
            xCords =
              currentTippyNodeDimensions.right - nestedTippyNodeDimensions.left;
          } else {
            xCords =
              currentTippyNodeDimensions.left -
              nestedTippyNodeDimensions.left -
              nestedTippyNodeDimensions.width;
          }
          nestedTippyNode.style.transform = `translate(${xCords}px)`;
          this.verticalIndent(nestedTippyNode);
          nestedTippyNode.style.visibility = '';
        }
        // console.log('currentTippyNodeDimensions.width', currentTippyNodeDimensions.width);
      });
    },
    handleMouseEnter(item, index, namespace) {
      this.hoverIndex = index;
      this.hoverIndexNamespace = namespace;
    },
    genenrateUniqueId(index, namespace) {
      return index;
    },
    selectTriggered() {},
    filterOptions: debounce(function (searchTerm, context) {
      // console.log('filterOptions')
      if (this.searchListener) {
        this.backendSearching = true;
        this.searchListener(this.searchText, this.context);
        this.noData = false;
      }
    }, 1000),
    onInput(value) {
      // console.log('onInput')
      if (
        value.selected === true &&
        this.selected.indexOf(value.title) === -1
      ) {
        this.selected.push(value.title);
        this.selectedDetails.push(value);
      } else {
        this.selected.splice(this.selected.indexOf(value.title), 1);
        const index = this.selectedDetails.findIndex((item) => {
          return value.title === item.title;
        });
        if (index !== -1) {
          this.selectedDetails.splice(index, 1);
        }
      }
    },
    setValue(value) {
      this.selected = value;
      this.selectedDetails = value;
      this.$emit('change', value);
    },
    /**
     * Click listener from DropdownItem.
     *   1. Set new selected item.
     *   2. Emit input event to update the user v-model.
     *   3. Close the dropdown.
     */
    selectItem(item, index) {
      if (this.isOnClickCustomPresent && this.onSwitchChangeCustom) {
        this.$emit('onSwitchChangeCustom', item);
      }
      if (item.children) {
        return;
      }
      if (!this.isMultiSelect && !this.isOnClickCustomPresent) {
        this.selected = [];
        this.selectedDetails = [];
        this.selected.push(item.title);
        item.selectedIndex = index;
        this.selectedDetails.push(item);
        this.text = item;
        this.$emit('input', item);
        this.$emit('change', item);
        this.isActive = false;
        this.closeAllDropdowns();
      }
      if (this.isWaterfallDropdown) {
        const callbackParam = {
          treePath: this.getTreePath,
          selectedOptions: this.selectedDetails
        };
        this.onClose(this.context, callbackParam);
        // return
      }
    },
    closeAllDropdowns(treeId) {
      document.querySelectorAll('.tippy-popper').forEach((popper) => {
        if (treeId) {
          if (popper._tippy.options.tippyTreeId !== treeId) {
            popper._tippy.hide();
          }
        } else if (popper._tippy.state.visible) {
          popper._tippy.hide();
        }
      });
    },
    /**
     * White-listed items to not close when clicked.
     */
    isInWhiteList(el) {
      // console.log('isInWhiteList')
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      // All chidren from dropdown
      if (this.$refs.dropdownMenu !== undefined) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      // All children from trigger
      if (this.$refs.trigger !== undefined) {
        const children = this.$refs.trigger.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside(event) {
      if (this.inline) return;
      if (!this.isInWhiteList(event.target)) {
        this.wasClickedOutside = true;
        this.isActive = false;
        this._customTrigger = false;
      } else {
        this.wasClickedOutside = false;
      }
    },
    onShow(instance) {
      this.isActive = true;
      var that = this;
      if (!this.isWaterfallDropdown) {
        document.querySelectorAll('.tippy-popper').forEach((popper) => {
          if (
            popper !== instance.popper &&
            !popper.contains(that.$refs.trigger)
          ) {
            if (popper._tippy.state.visible) {
              popper._tippy.hide();
            }
          }
        });
      }
      setTimeout(function () {
        if (
          that.$refs.localSearch &&
          that.$refs.localSearch.$el &&
          that.$refs.localSearch.$el.children &&
          that.$refs.localSearch.$el.children.length > 0
        ) {
          that.$refs.localSearch.$el.children[0].focus();
        }
      }, 100);
    },
    onHide(instance) {
      (this.hoverIndex = null), (this.hoverIndexNamespace = null);
      this.isActive = false;
    },
    /**
     * Toggle dropdown if it's not disabled.
     */
    toggle(instance) {
      // if (this.customTriggerEnabled && this.isActive) this.isActive = false
      if (this.disabled || this.hoverable || this.customTriggerEnabled) return;
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style lang="css" scoped>
.tippy-content {
  display: inline-block;
  position: relative;
  line-height: 1;
}
.tippy-content .rb-select-menu {
  background: #fff;
  box-shadow: 0 0 8px 0 #caccce;
  display: inline-block;
  min-width: 100%;
  border-radius: 4px;
}
.tippy-content .rb-select-menu .rb-input {
  background-color: #fff;
  color: #2b333b;
  border: 1px solid #e9eaeb;
}
.is-bottom-left .rb-select-menu {
  right: 0;
  left: auto;
}
.dropdown-trigger .rb-button__content {
  max-width: 150px;
  justify-content: space-between;
}
.dropdown-trigger .rb-button__content span {
  margin-right: 4px;
}
.rb-select-size-l .dropdown-trigger .rb-button__content {
  max-width: 100%;
  justify-content: space-between;
}
.rb-select-size-l .dropdown-trigger .rb-button__content span {
  margin-right: 4px;
}
.dropdown-trigger .rb-button__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown-trigger .rb-button__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown-menu {
  padding: 0;
  top: calc(100% + 2px);
  min-width: 100%;
}
.search__input {
  background: white;
  padding: 8px 16px 8px;
}
.is-bottom-left .dropdown-menu {
  left: auto;
  right: 0;
}
.list--dropdown.is-active {
  background: #007cf6;
  color: #fff;
}
.rb-checkbox {
  width: 100%;
}
.select__dropdown-list-item {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}
.tree-spawn-hover-effect {
  background: #007cf6 !important;
  color: #fff !important;
}
.custom-padding {
  padding: 4px 8px !important;
}
.removePointerEvents {
  pointer-events: none;
}
</style>
