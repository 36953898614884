<template>
  <section :id="id">
    <div
      class="u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center u-spacing-pv-m u-spacing-ph-m u-border-bottom u-border-color-grey-xxx-light u-border-width-s"
    >
      <rb-icon
        :icon="icon"
        class="rb-icon--medium u-color-blue-base"
      />
      <div class="u-font-size-4 u-spacing-ml-xs">
        {{ title }}
      </div>
    </div>
    <div class="u-spacing-pv-m u-spacing-pr-xl">
      <div
        v-for="(item, index) in chainText"
        :key="index"
        class="u-font-size-7 u-font-weight-normal u-spacing-ph-m u-spacing-pb-s"
      >
        <div class="u-spacing-mb-s">
          {{ item }}
        </div>
        <rb-icon
          icon="angled-arrow-right"
          class="u-color-blue-base rb-icon--xx-small"
          style="transform: rotate(90deg)"
        />
      </div>
      <div class="u-font-size-7 u-font-weight-600 u-spacing-ph-m">
        {{ lastText }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    splitHierarchy: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    chainText() {
      if (!this.splitHierarchy.length) {
        return [];
      }
      return this.splitHierarchy.filter(
        (item, index) => index < this.splitHierarchy.length - 1
      );
    },
    lastText() {
      if (!this.splitHierarchy.length) {
        return '';
      }
      return this.splitHierarchy[this.splitHierarchy.length - 1];
    }
  }
};
</script>
