<template>
  <section>
    <div v-show="pageFetched">
      <sticky-filter-panel
        v-if="showFilter"
        ref="sticky-filter-panel"
        :hide-export="true"
        :hide-clear-all-btn="true"
        :disable-view="true"
        :show-filter="showFilter"
        class="sticky-panel sticky-filter-panel category_page"
        :secondary-filter-data="secondaryFilterComputedData"
        :filter-local-storage-key="filterLocalStorageKey"
        :filter-emit="filterEmit"
        :options-list="viewList || []"
        :selected-option="selectedView"
        :filter-state="filterState"
        :default-date-post-range="defaultDateRange"
        :min-max-date="minMaxDate"
        :hide-view-btn="hideViewBtn"
        :hide-remove-btn="true"
        :hide-update-view="hideUpdateView"
        :filter-key="filterKey"
        :additional-ranges="additionalRanges"
        :date-mode="dateMode"
        :selection-mode="selectionMode"
        :view-selection-enabled="false"
        @savedFilterApplied="handleSaveFilterApplied"
        @onCreate="onCreate"
        @filterMounted="handleFilterMount"
      >
        <div
          slot="before-filter"
          class="u-display-flex u-flex-align-items-center u-height-36px"
          @click="goToExecutivePage()"
        >
          <rb-icon
            class="rb-icon u-spacing-ml-xs u-color-grey-lighter u-spacing-mr-s u-cursor-pointer"
            :icon="'arrow_back'"
          />
          <div
            class="u-font-size-2 u-font-weight-700 u-display-flex u-text-transform-capitalize"
          >
            {{ categoryName }}
          </div>
        </div>
        <div
          slot="preDatePicker"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-button
            text="View SKUs"
            :type="'filled'"
            class="u-spacing-mr-s"
            :click-fn="navigateTo.bind(this)"
          />
        </div>
        <div
          slot="after-filter"
          :class="{ 'subcagetory-dropdown': !enableHeirarchy }"
          class="u-border-radius-xxxl u-spacing-pv-xs u-spacing-ph-s u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center u-bg-color-grey-white"
          style="margin-bottom: 5px"
        >
          <div
            v-if="!enableHeirarchy"
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-font-size-5 u-display-inline-flex u-flex-align-items-center action--text opacity-0_5"
            title="No sub-category available for the selected category"
            style="height: 20px"
          >
            <span
              class="rb-icon rb-icon--small u-spacing-mr-xs icon-add-circle-fill"
            />
            <span>Add a sub category filter</span>
          </div>
          <SubCategoryFilter
            v-else
            :selected-category="currentCategory"
            :selected-hierarchy="selectedSubCategory"
            :selections="selections"
            :category-status-list="categoryStatusList"
            @applySubCategory="applySubCategory"
            @onShow="onSubcategoryShow"
          />
        </div>
        <div
          v-for="(attributes, index) in dynamicAttributes"
          :key="index"
          slot="after-filter"
          v-tippy="{
            placement: 'top-start',
            arrow: false,
            maxWidth: '200px',
            size: 'regular'
          }"
          class="u-display-flex"
          :title="
            !disableAttribute(attributes.path, categoryStatusList)
              ? 'There is no data available for the combination of this filter and the other selected filters.'
              : ''
          "
        >
          <rb-filter
            ref="filter"
            :config="{
              title: attributes.dimensionLabel,
              dimensionName: attributes.dimensionName,
              path: attributes.path
            }"
            :anchor="attributes.dimensionLabel"
            :show-notification="false"
            :multi="false"
            :use-local-storage="false"
            :hide-clear-all-btn="true"
            :is-always-on-filter="true"
            :add-filter-text="attributes.dimensionLabel"
            :secondary-data="attributeDropdownOptions(attributes)"
            :apply="applyAttribute"
            :default-filters="defaultAttributesOptions"
            :class="{
              'category-filter disabled': !disableAttribute(
                attributes.path,
                categoryStatusList
              )
            }"
          />
        </div>
        <div slot="footer">
          <Settings
            :key="key"
            :types="settingsValue.viewBy"
            :placements="settingsValue.include3p"
            :keyword-type="settingsValue.displayShareIn"
            :selected-type="transformedComputedSelectedSettingsValue.viewBy"
            :selected-placement="
              transformedComputedSelectedSettingsValue.include3p
            "
            :selected-keyword-type="
              transformedComputedSelectedSettingsValue.displayShareIn
            "
            :customer-level-filters="customerLevelFilters"
            :widget-name="'settings'"
            @apply="handleApply"
            @cancel="handleCancel"
          />
        </div>
      </sticky-filter-panel>
    </div>
    <section
      v-if="pageFetched"
      class="widgetWrapper u-envelop-width"
    >
      <versionToggle
        v-if="isMsproEnabled"
        class="u-spacing-mv-m"
        :selected-tab="selectedTab"
      />
      <metrics-card
        :page="page"
        :global-view-id="selectedView.globalViewId"
        :widget="metricsCardWidget"
        :page-id="selectedView.pageId"
        :global-settings="getGlobalSettings"
      />
      <section class="u-spacing-mh-m u-spacing-mv-m">
        <section
          v-if="!pfBreakdowntableDataServiceLoaded"
          class="u-display-flex u-flex-direction-column u-height-400"
        >
          <loader
            class="u-height-100 u-bg-color-grey-white"
            :loading="true"
            color="#007cf6"
          />
        </section>
        <section
          v-else
          class="u-width-100 u-bg-color-grey-white u-border-radius-s box-shadow"
          :class="{ 'u-spacing-pb-l': showPerformanceBreakdown }"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-ph-l u-spacing-pt-l u-spacing-mb-l"
          >
            <div class="u-font-size-3 u-font-weight-600 u-color-grey-lighter">
              PERFORMANCE BREAKDOWN
            </div>
            <div class="u-color-grey-lighter u-cursor-pointer hover-class">
              <div
                v-if="!showPerformanceBreakdown"
                v-tippy="{ placement: 'top', arrow: false }"
                :title="actionIcon.performanceTrendsTippyTitle"
                class="u-display-flex u-flex-align-items-center u-flex-direction-column"
              >
                <rb-icon
                  class="u-color-grey-xx-light"
                  :icon="actionIcon.icon"
                />
              </div>
              <div
                v-else-if="!actionIcon.performanceBreakdownLoading"
                v-tippy="{ placement: 'top', arrow: false }"
                :title="getTippyTitle()"
                class="u-display-flex u-flex-align-items-center u-flex-direction-column"
                @click.stop="handleDownload()"
              >
                <rb-icon
                  :class="[
                    getErrorStatus ? 'u-color-red-base' : 'u-color-grey-lighter'
                  ]"
                  :icon="actionIcon.icon"
                />
              </div>
              <span v-else>
                <loader
                  size="16px"
                  class="u-bg-color-grey-white"
                  :loading="true"
                  color="#007cf6"
                />
              </span>
            </div>
          </div>
          <div class="u-display-flex u-spacing-mb-l u-spacing-ph-l">
            <splitTabs
              :tab1="breakdownTabHeading"
              :tab2="trendsTabHeading"
              size="m"
              @tabClicked="tabChanged"
            />
            <div
              v-if="showPerformanceBreakdown"
              class="u-bg-color-grey-xx-light u-spacing-mh-m line-dimensions"
            ></div>
            <div
              v-if="showPerformanceBreakdown"
              class="u-border-radius-xxxl u-spacing-pv-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center u-bg-color-grey-white u-border-color-grey-xxx-light u-border-width-s u-border-all"
            >
              <sorting
                :enable-pvp-level-sort="true"
                :metric-list="getMetricsListForSort()"
                :sort-configuration="sortConfiguration"
                @metricSortSelected="handleMetricSortSelected"
              />
            </div>
            <div
              v-if="!showPerformanceBreakdown"
              class="u-spacing-ml-m"
            >
              <compare-dropdown
                class="compare-by"
                label="Compare By:"
                icon-val="settings"
                :value="selectedComparision"
                :options="comparisionOptionsArray"
                @onRollUpChange="rollUpChanged"
              />
            </div>
          </div>
          <performanceBreakdown
            v-if="pfBreakdowntableDataServiceLoaded && showPerformanceBreakdown"
            :data-service="breakdownTableDataService"
            :global-settings="getGlobalSettings"
            :min-max-date="minMaxDate"
            :sort-configuration="sortConfiguration"
            class="u-spacing-mh-l"
          />
          <performanceTrends
            v-if="!showPerformanceBreakdown"
            :data-service="performanceTrendsService"
            :global-settings="getGlobalSettings"
            :selected-comparision="selectedComparision"
          />
        </section>
      </section>
      <table-widget
        v-if="tableDataServiceLoaded"
        ref="table-widget"
        class="widget-3"
        :page="page"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :global-settings="getGlobalSettings"
        :data-service="tableDataService"
        :min-max-date="minMaxDate"
        :action-icons="shareSummaryWidgetActionIcons"
        :share-summary-widget="shareSummaryWidget"
        :category-page-widget="getMsProCategoryPageWidget"
      />
    </section>

    <section v-else>
      <pageLoader
        :page-fetched="pageFetched"
        :page-fetched-error="pageFetchedError"
      />
    </section>
  </section>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import metricsCard from '@/components/pages/insights/amazon/market_share_pro/molecules/metrics-card.vue';
import loader from '@/components/basic/loader';
import widgetMixin from '@/components/widgetsMixin.js';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import Settings from '@/components/pages/insights/amazon/market-share/molecules/settings-popup.vue';
import { cloneDeep } from 'lodash';
import filter from '@/components/widgets/filter/filter.vue';
import Vue from 'vue';
import {
  msProConstants,
  transformNestedSettingsInUI,
  transformNestedSettingsInDropDown,
  transformNestedSettingsValueForGlobalSettings,
  getAdditionalDateRanges,
  getObjectKey,
  getHighestSelectableRange,
  getFormattedSubCategory,
  getDimensionValueList,
  computeFinalWhereClause,
  getFormattedCustomerLevelFilters,
  getTransformedSubcategory,
  setGlobalSettingsForMS,
  getGlobalSettingsForMS,
  configurationSetting,
  removeGlobalSettingsForMS,
  filterHierarchyPaths,
  getSavedCustomerFilters,
  constants,
  getAttributeList,
  onFilterApply,
  getAttributeDropdownOptions,
  getAttributesData,
  transform3pInNestedSettings,
  getSettingsValue,
  getAttributes,
  getCategoryDisableStatus,
  getDisableAttribute,
  asinLevelV2ConfigEnabled,
  get3pOptions,
  DefaultDisplayByOptions,
  checkForDisplayShareInOptions,
  getGlobalToLocalInclude3p,
  categoryConstants,
  getMetric,
  getShareSummaryWidget,
  NodeIdMap,
  getActionIcons,
  localStorageFallback,
  isMsproEnabled,
  msProCategoryConstants,
  performanceBreakDownSortMetrics,
  getPerformanceBreakDownSortConfiguration,
  getCompareConfiguration,
  getOptionsListForCompareDropdown,
  updateComparisionConfiguration
} from '@/components/pages/insights/amazon/market-share/utils';
import TableWidgetService from '@/components/pages/insights/amazon/market-share/service/table-widget-service.js';
import TableWidget from '@/components/pages/insights/amazon/market-share/templates/tableWidget.vue';
import SubCategoryFilter from '@/components/pages/insights/amazon/market-share/molecules/hierarchy.vue';
import { ThreePSelection } from '@/components/pages/insights/amazon/market-share/types/filters.ts';
import transformer from '@/utils/services/data-transformer.js';
import pageLoader from '@/components/pages/insights/amazon/market_share_pro/atoms/pageLoader.vue';
import versionToggle from '@/components/pages/insights/amazon/market_share_pro/molecules/versionToggle.vue';
import performanceBreakdown from '@/components/pages/insights/amazon/market_share_pro/molecules/performanceBreakdown.vue';
import BreakdownWidgetService from '@/components/pages/insights/amazon/market_share_pro/service/performanceBreakdown.js';
import sorting from '@/components/pages/insights/amazon/market_share_pro/atoms/sorting.vue';
import splitTabs from '@/components/widgets/splitTabs';
import performanceTrends from '@/components/pages/insights/amazon/market_share_pro/molecules/performanceTrends.vue';
import performanceTrendsService from '@/components/pages/insights/amazon/market_share_pro/service/performanceTrends.js';
import compareDropdown from '@/components/pages/insights/amazon/market_share_pro/atoms/compareDropdown.vue';

export default {
  components: {
    loader,
    metricsCard,
    stickyFilterPanel,
    Settings,
    TableWidget,
    SubCategoryFilter,
    rbFilter: filter,
    pageLoader,
    versionToggle,
    performanceBreakdown,
    sorting,
    splitTabs,
    performanceTrends,
    compareDropdown
  },
  mixins: [widgetMixin],
  data() {
    let { namespace, filterLocalStorageKey, diffDate, pageName, execPage } =
      categoryConstants;
    let { page, metricsCardWidget } = msProCategoryConstants;
    return {
      showPerformanceBreakdown: true,
      breakdownTabHeading: 'Breakdown',
      trendsTabHeading: 'Trends',
      isMsproEnabled: isMsproEnabled(),
      selectedComparision: '',
      comparisionOptionsArray: [],
      metricsCardWidget,
      selectedTab: msProConstants.newVersion,
      categoryNodeMap: {},
      shareSummaryWidgetActionIcons: getActionIcons(
        constants.shareSummaryDownloadTippyTitle
      ),
      shareSummaryWidget: getShareSummaryWidget('categoryPage'),
      pageName,
      diffDate,
      dateMode: constants.dateMode,
      execPage,
      namespace,
      page,
      filterLocalStorageKey,
      selections: [],
      listApiResolved: false,
      currentCategoryTracker: '',
      selectedSubCategory: [],
      selectionMode: 'multi-week',
      viewList: [],
      selectedView: {},
      pageFetchedError: false,
      additionalRanges: {
        add: ['lastWeek', 'last4Week', 'last13Week'],
        overrideRange: ['lastWeek', 'last4Week', 'last13Week'],
        order: {
          lastWeek: 3,
          last4Week: 4,
          last13Week: 5
        }
      },
      actionIcon: {
        icon: 'download',
        performanceBreakdownLoading: false,
        performanceBreakdownError: false,
        performanceTrendsLoading: false,
        performanceTrendsError: false,
        performanceBreakdownTippyTitle:
          'Download week-over-week brand and metric level data for selected periods for this category',
        performanceTrendsTippyTitle:
          'We are currently developing this functionality. We appreciate your patience.'
      },
      filterMounted: false,
      pageFetched: false,
      filterInstanceCreated: false,
      filterKey: 0,
      defaultDateRange: 'last30Days',
      filterInstance: null,
      hideViewBtn: true,
      hideUpdateView: true,
      tableDataService: undefined,
      showFilter: false,
      filterEmit: 'marketshare-category-filter-apply',
      minMaxDate: {},
      pageWiseMinMaxKey: 'marketshare-category',
      computedSelectedSettingsValue: {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedSettingsValue: {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedCustomerFilters: {},
      globalWhereClauseValue: {},
      getGlobalSettings: {},
      dynamicAttributes: [],
      selectedAttributes: [],
      defaultAttributesOptions: {
        values: {}
      },
      categoryStatusList: {},
      key: 0,
      breakdownTableDataService: undefined,
      metadataBreakdownTable: {},
      sortConfiguration: getPerformanceBreakDownSortConfiguration(),
      performanceTrendsService: null
    };
  },
  computed: {
    pfBreakdowntableDataServiceLoaded() {
      return this.breakdownTableDataService !== undefined;
    },
    getMsProCategoryPageWidget() {
      const widget = msProCategoryConstants.categoryShareSummary;
      return function (widgetName) {
        return widgetName === widget;
      };
    },
    getDownloadLoadingStatus() {
      if (this.showPerformanceBreakdown) {
        return this.actionIcon.performanceBreakdownLoading;
      } else {
        return this.actionIcon.performanceTrendsLoading;
      }
    },
    getErrorStatus() {
      if (this.showPerformanceBreakdown) {
        return this.actionIcon.performanceBreakdownError;
      } else {
        return this.actionIcon.performanceTrendsError;
      }
    },
    tableDataServiceLoaded() {
      return this.tableDataService !== undefined;
    },
    dimensionValueList() {
      return getDimensionValueList(this.selectedSubCategory);
    },
    categoryName() {
      if (this.currentCategory) {
        return this.currentCategory.length > 0
          ? this.currentCategory[0].dimensionValue
          : '';
      }
    },
    enableHeirarchy() {
      let selectedCategory = cloneDeep(
        this.currentCategory[0]?.dimensionValue || ''
      );
      if (selectedCategory) {
        selectedCategory = getObjectKey(
          this.filterData?.category,
          selectedCategory
        );
      }
      const obj = this.filterData?.category?.[selectedCategory] || {};
      const length = Object.keys(obj?.children || {}).length;
      return length !== 0;
    },
    currentCategory() {
      const globalWhereClause = cloneDeep(this.globalWhereClauseValue) || {};
      let dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      dimensionNameValueList = dimensionNameValueList.filter((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.replace('~', '');
          return element;
        } else {
          return false;
        }
      });
      return dimensionNameValueList;
    },
    customerLevelFilters() {
      return getFormattedCustomerLevelFilters(
        this.filterData,
        this.selectedCustomerFilters
      );
    },
    transformedComputedSelectedSettingsValue() {
      const nestedSettings = cloneDeep(this.computedSelectedSettingsValue);
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInUI(
        nestedSettings,
        Vue
      );
      this.selectedComparision = updateComparisionConfiguration(
        updatedNestedSettings?.viewBy?.entityType
      );
      this.comparisionOptionsArray = getOptionsListForCompareDropdown(
        updatedNestedSettings?.viewBy?.entityType
      );
      return updatedNestedSettings;
    },
    settingsValue() {
      const nestedSettings = getSettingsValue('brand', undefined, true);
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInDropDown(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    secondaryFilterComputedData() {
      const category_hierarchy = {
        multi: false,
        v1: true,
        key: 'category_hierarchy',
        label: 'Category',
        values: []
      };
      Object.keys(this.filterData.category || {}).forEach((element) => {
        category_hierarchy.values.push({ title: element, enable: true });
      });
      return { category_hierarchy };
    },
    getBrandsList() {
      return this.$store.getters[this.namespace + 'getBrandsData'];
    },
    filterState() {
      return {
        getter: this.namespace + 'getGlobalFilters',
        setter: this.namespace + 'setGlobalFilters'
      };
    },
    getSettings() {
      return {
        viewBy: { entityType: 'brand', label: 'Brands' },
        displayShareIn: {
          metricsList: 'categoryShareSummary_sales_estimates_cat',
          label: 'Dollars'
        },
        include3p: [get3pOptions()[0]]
      };
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    },
    globalToLocal() {
      return {
        viewBy: {
          label: {
            Category: 'Brands'
          },
          entityType: {
            category: 'brand',
            asin: 'brand'
          }
        },
        displayShareIn: {
          metricsList: getMetric('category')
        },
        include3p: getGlobalToLocalInclude3p()
      };
    }
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.handleViewBasedonParams(id);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    transform3pInNestedSettings();
    this.initializePage();
    eventBus.$on('performance-breakdown-download-completed', () => {
      this.actionIcon.performanceBreakdownLoading = false;
      this.actionIcon.performanceBreakdownError = false;
    });
    eventBus.$on('performance-trends-download-completed', () => {
      this.actionIcon.performanceTrendsLoading = false;
      this.actionIcon.performanceTrendsError = false;
    });
    eventBus.$on('performance-breakdown-download-failed', () => {
      this.actionIcon.performanceBreakdownLoading = false;
      this.actionIcon.performanceBreakdownError = true;
      setTimeout(() => {
        this.actionIcon.performanceBreakdownError = false;
      }, 7000);
    });
    eventBus.$on('performance-trends-download-failed', () => {
      this.actionIcon.performanceTrendsLoading = false;
      this.actionIcon.performanceTrendsError = true;
      setTimeout(() => {
        this.actionIcon.performanceTrendsError = false;
      }, 1000);
    });
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    eventBus.$off('performance-breakdown-download-completed');
    eventBus.$off('performance-trends-download-completed');
    eventBus.$off('performance-breakdown-download-failed');
    eventBus.$off('performance-trends-download-failed');
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  methods: {
    rollUpChanged(selectedRollUp) {
      let selectedComparision = JSON.parse(
        localStorage.getItem('selectedComparison') ?? '{}'
      );
      if (selectedRollUp.key === selectedComparision) {
        return;
      }
      this.selectedComparision = selectedRollUp.key;
      selectedComparision = selectedRollUp.key;
      localStorage.setItem(
        'selectedComparison',
        JSON.stringify(selectedComparision)
      );
    },
    tabChanged(val) {
      if (val === this.breakdownTabHeading) {
        this.showPerformanceBreakdown = true;
      } else {
        this.showPerformanceBreakdown = false;
      }
    },
    getMetricsListForSort() {
      return Object.entries(performanceBreakDownSortMetrics).map(
        ([label, value]) => ({
          label,
          value
        })
      );
    },
    handleMetricSortSelected(metricSortSelected) {
      localStorage.setItem(
        'performanceBreakDownSortConfiguration',
        JSON.stringify(metricSortSelected)
      );
      this.sortConfiguration = metricSortSelected;
    },
    async initializeTable() {
      this.breakdownTableDataService = new BreakdownWidgetService(
        this.page,
        msProCategoryConstants.breakdownWidget,
        this.selectedView.pageId,
        this.selectedView.globalViewId
      );
      this.performanceTrendsService = new performanceTrendsService(
        this.page,
        msProCategoryConstants.performanceTrendsWidget,
        this.selectedView.pageId,
        this.selectedView.globalViewId
      );
    },
    /**
     * @description to reset any active settings changes on clicking on cancel button
     */
    handleCancel() {
      this.key++;
    },
    /**
     * @description to disable the category level filters
     * @param {array} selectionList - hierarichal path of the subcategory on which the category level filter is applicable
     * @param {object} categoryStatusList - all possible selection paths of category/subcategory with its enable status
     * @return {boolean}
     */
    disableAttribute(selectionList, categoryStatusList) {
      return getDisableAttribute(selectionList, categoryStatusList);
    },
    /**
     * @description called when any options is applied in dynamic attributes
     * @callback onFilterApply
     * @param {object} selections - have selection data from dropdown
     * stores dynmaic attributes selections in LS
     * refresh the widgets with updated where clause
     */
    applyAttribute(selections) {
      onFilterApply(selections);
      this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      this.getDisableData(this.dynamicAttributes);
      this.refreshWidgets();
    },
    /**
     * @description called to list down the options of each attributes
     * @param {object} attributeData - have specific attribute data
     * @returns {object} - contains object with list of dropdopwn options for specific attributes
     * does the data transformation to display list of options dynamically
     */
    attributeDropdownOptions(attributeData) {
      return getAttributeDropdownOptions(attributeData);
    },
    navigateTo() {
      this.$router.push({ name: 'marketshareSkus' });
    },
    goToExecutivePage() {
      this.$router.push({ name: this.execPage });
    },
    onSubcategoryShow() {
      // Hiding Category filter dropdown when sub category filter is opened
      const categoryFilter = this.filterInstance?.$refs?.filter?.[0];
      if (categoryFilter) {
        categoryFilter.isActive = false;
      }
    },
    async applySubCategory(data) {
      // when sub category is changed we catch the event here
      if (Object.keys(data).length !== 0) {
        setGlobalSettingsForMS(constants.SUBCATEGORY, data);
        setGlobalSettingsForMS(
          constants.FILTER_SELECTION,
          this.filterData?.category
        );
      } else {
        setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
        setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
      }
      let globalFilter = getGlobalSettingsForMS();
      this.selectedSubCategory = getFormattedSubCategory(
        data,
        this.filterData?.category
      );
      const categorySelectionList = filterHierarchyPaths(
        this.filterData?.category,
        {}
      );
      this.selections = getTransformedSubcategory();
      this.dynamicAttributes = getAttributes(
        data,
        categorySelectionList,
        this.dynamicAttributes
      );
      if (globalFilter[constants.ATTRIBUTES]) {
        this.defaultAttributesOptions.values =
          globalFilter[constants.ATTRIBUTES];
        this.selectedAttributes = getAttributesData(this.dynamicAttributes);
      }
      this.getDisableData(this.dynamicAttributes);
      await this.checkDatesForCategory();
      this.refreshWidgets();
    },
    showErrorPageLevel(err) {
      this.pageFetched = false;
      this.pageFetchedError = true;
      console.log('err', err);
      this.$snackbar.open(msProConstants.errorMessage);
    },
    async checkDatesForCategory(category = []) {
      this.pageFetched = false;
      let dateRange = null;
      try {
        dateRange = await this.updatedDateRange(category);
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      try {
        await this.updateFilterSelection({
          from: dateRange.data.response.minDate,
          to: dateRange.data.response.maxDate
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
      this.pageFetched = true;
    },
    handleFilterMount() {
      this.showPerformanceBreakdown = true;
      this.filterMounted = true;
      eventBus.$on(this.filterEmit, async (data) => {
        // when category and date range is changed we catch the event here
        if (!data?.from) {
          setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
          setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
          setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
        }

        let storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        for (const key in storage) {
          setGlobalSettingsForMS(key, storage[key]);
        }
        if (
          data?.category_hierarchy !== undefined &&
          data?.category_hierarchy?.[0] !== this.currentCategoryTracker
        ) {
          // Only if category has changed then make a request for fetching differential dates for category
          this.currentCategoryTracker = data.category_hierarchy[0];
          let category = {};
          category[this.currentCategoryTracker] = true;
          const categorySelectionList = filterHierarchyPaths(
            this.filterData?.category,
            {}
          );
          this.dynamicAttributes = getAttributeList(
            category,
            categorySelectionList
          );
          this.selectedAttributes = getAttributesData(this.dynamicAttributes);
          this.getDisableData(this.dynamicAttributes);
          if (!data.name) {
            this.$store.dispatch('setInitialSelections', {});
            this.selectedSubCategory = [];
          }
          await this.checkDatesForCategory();
        }
        this.refreshWidgets();
      });
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.$router.push({
            name: this.pageName,
            params: { id: selectedView.globalViewId }
          });
          return;
        }

        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList(selectedView);
      } else if (this.viewList && this.viewList.length > 0) {
        selectedView = this.viewList[0];
        this.$router.push({
          name: this.execPage,
          params: { id: selectedView.globalViewId }
        });
        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList();
      }
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(
        this.$store.getters[this.namespace + 'getGlobalFilters']
      );
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: []
      };
      where.date.from = globalWhereClause?.date_range?.from;
      where.date.to = globalWhereClause?.date_range?.to;
      where.date.name = globalWhereClause?.date_range?.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause?.date_range?.compare_from;
      where.pvpDate.to = globalWhereClause?.date_range?.compare_to;
      where.pvpDate.compare_name = globalWhereClause?.date_range?.compare_name;
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      where.dimensionNameValueList.forEach((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.toLowerCase() + '~';
          element.operator = 'STARTSWITH';
        }
      });
      if (!this.v2) {
        if (
          this.computedSelectedSettingsValue?.include3p?.value ===
          ThreePSelection.EXCLUDE_3P
        ) {
          where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: false
          });
        } else if (
          this.computedSelectedSettingsValue?.include3p?.value ===
          ThreePSelection.EXCLUDE_3P_CLIENT
        ) {
          where.dimensionNameValueList.push({
            dimensionName: constants.THREE_P_GROUP_FLAG,
            dimensionValue: 'comp'
          });
        }
      }
      return where;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    getDefaultDateRangeObject() {
      return {
        name: 'last30Days',
        compare: undefined,
        compare_name: undefined
      };
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      setTimeout(() => {
        this.showFilter = true;
      });
    },
    handleApply(payload) {
      // when settings is changed we catch the event here
      // Payload has dynamic currency value, that is transformed so that local storage has static currency value i.e Dollars
      const nestedSettings =
        transformNestedSettingsValueForGlobalSettings(payload);
      this.selectedCustomerFilters = nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(
        'customerLevelFilters',
        nestedSettings.customerLevelFilters
      );
      delete nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
      this.selectedComparision = getCompareConfiguration(
        nestedSettings?.viewBy?.entityType
      );
      this.comparisionOptionsArray = getOptionsListForCompareDropdown(
        nestedSettings?.viewBy?.entityType
      );
      this.$nextTick(() => {
        // updates the settings component selections
        this.computedSelectedSettingsValue = payload;
        this.refreshWidgets();
      });
    },
    getTippyTitle() {
      if (this.showPerformanceBreakdown)
        return this.actionIcon.performanceBreakdownTippyTitle;
      else return this.actionIcon.performanceTrendsTippyTitle;
    },
    handleDownload() {
      if (this.showPerformanceBreakdown) {
        this.actionIcon.performanceBreakdownLoading = true;
        this.actionIcon.performanceBreakdownError = false;
        eventBus.$emit('performance-breakdown-download-clicked');
      } else {
        this.actionIcon.performanceTrendsLoading = true;
        this.actionIcon.performanceTrendsError = false;
        eventBus.$emit('performance-trends-download-clicked');
      }
    },
    async updatedDateRange(categorySelection = []) {
      if (categorySelection.length === 0) {
        if (this.dimensionValueList?.length !== 0) {
          categorySelection = this.dimensionValueList;
        } else if (
          this.globalWhereClause()?.dimensionNameValueList?.length !== 0
        ) {
          categorySelection = this.globalWhereClause()?.dimensionNameValueList;
        }
      }
      if (categorySelection.length === 0) {
        return;
      }

      let body = [];
      categorySelection.forEach((dimension) => {
        body.push(dimension.dimensionValue);
      });
      try {
        return await HttpService.post(this.diffDate, body, {
          params: {
            v2: this.v2
          }
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    async updateFilters() {
      return new Promise((resolve) => {
        this.reinitView();
        this.$nextTick(() => {});
        const categoryHierarchySelections = this.$store.getters.getSelections;

        // Hack to show the filters
        setTimeout(() => {
          document.querySelector('.filter-list__item').click();
        }, 0);
        setTimeout(() => {
          document.querySelector('body').click();
          document.querySelector('.rb-filter .icon-cross').style.display =
            'none';
          this.$store.dispatch(
            'setInitialSelections',
            categoryHierarchySelections
          );
        }, 0);

        // Once the filter component is reinitialized, resolve the method
        const interval = setInterval(() => {
          if (this.filterInstanceCreated) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },
    async updateFilterSelection(dateRange, updateFilters = true) {
      const storage = JSON.parse(
        localStorage.getItem(this.filterLocalStorageKey)
      );

      const currentSelectedStartDate = moment(storage.date_range.from);
      const currentSelectedEndDate = moment(storage.date_range.to);
      const backendStartDate = moment(dateRange.from);
      const backendEndDate = moment(dateRange.to);

      let updateFilter = false;
      this.minMaxDate.rawMinDate = backendStartDate;
      this.minMaxDate.rawMaxDate = backendEndDate;
      this.minMaxDate.minDate = backendStartDate.format('MM/DD/YYYY');
      this.minMaxDate.maxDate = backendEndDate.format('MM/DD/YYYY');
      this.additionalRanges = getAdditionalDateRanges(
        backendStartDate,
        backendEndDate
      );
      const availableRanges = this.additionalRanges.add.filter(
        (range) => this.additionalRanges.disabledRange.indexOf(range) === -1
      );
      const highestSelectableRange = getHighestSelectableRange(
        this.additionalRanges.order,
        availableRanges
      );

      let date_range = {};
      if (
        !currentSelectedStartDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (
        !currentSelectedEndDate.isBetween(
          backendStartDate,
          backendEndDate,
          undefined,
          '[]'
        )
      ) {
        updateFilter = true;
        date_range = {
          name: highestSelectableRange,
          compare: true,
          compare_name: null
        };
      }
      if (updateFilter) {
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify({
            ...storage,
            date_range
          })
        );
      }
      if (updateFilters) {
        await this.updateFilters();
      }
    },
    async refreshWidgets() {
      this.tableDataService.dateApiMaxDate = this.minMaxDate?.maxDate;
      this.globalWhereClauseValue = cloneDeep(this.globalWhereClause());
      const whereClause = computeFinalWhereClause(
        this.globalWhereClauseValue,
        this.dimensionValueList,
        this.selectedCustomerFilters,
        this.selectedAttributes
      );
      this.getGlobalSettings = {
        where: whereClause,
        settingValue: getGlobalSettingsForMS('nestedSettings')
      };
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      this.viewList.unshift(...defaultViewTemp);
    },
    async initializePage() {
      this.showFilter = false;
      const requestBody = { page: this.page };
      try {
        const globalViewData = await HttpService.post(
          'DASHBOARD_SERVICE',
          requestBody,
          {
            append: '/global/view/list'
          }
        );

        this.viewList = globalViewData.data.globalViews.map((item) => {
          item.currentUser = false;
          if (item.createdBy === window.user.email) {
            item.currentUser = true;
          }
          item.formattedAt = moment(item.createdAt).format('LLL');
          if (item.groupName !== 'Default Views') {
            item.edit = false;
          }
          return item;
        });

        this.sortViewList();

        let globalViewId = '';
        if (this.$route?.params?.id) {
          globalViewId = this.$route?.params?.id;
          this.handleViewBasedonParams(globalViewId);
        } else {
          globalViewId =
            globalViewData.data?.userUnsavedGlobalView?.globalViewId;
          if (globalViewId) {
            this.$router.push({
              name: this.pageName,
              params: { id: globalViewId }
            });
          } else {
            this.$router.push({
              name: this.pageName,
              params: { id: this.viewList[0]?.globalViewId }
            });
          }
        }
      } catch (error) {
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    async getDisableData(dynamicAttributes) {
      this.categoryStatusList = await getCategoryDisableStatus(
        dynamicAttributes
      );
      if (!Object.keys(this.categoryStatusList)?.length) {
        this.defaultAttributesOptions.values = {};
      }
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    updateSettingsFilterData() {
      const globalSettingsForMS = getGlobalSettingsForMS();
      const shareType =
        globalSettingsForMS?.nestedSettings?.displayShareIn?.shareType;
      if (shareType !== 'Marketshare') {
        setGlobalSettingsForMS(
          constants.NESTED_SETTINGS,
          msProConstants.nestedSettings
        );
      }
    },

    setSubcategory(globalFilter, category, categorySelectionList) {
      if (Object.keys(globalFilter?.subcategory || {}).length) {
        this.selectedSubCategory = getFormattedSubCategory(
          globalFilter.subcategory,
          globalFilter.filterSelection
        );
        // handles the subcategory selection on page load
        this.selections = getTransformedSubcategory();
        this.dynamicAttributes = getAttributeList(
          globalFilter?.subcategory,
          categorySelectionList
        );
      } else {
        let selectedPath = {};
        selectedPath[category] = true;
        this.dynamicAttributes = getAttributeList(
          selectedPath,
          categorySelectionList
        );
        setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
      }
    },
    async setFilterData(response) {
      localStorageFallback(this.filterData?.category, 'categoryPage');
      if (response.data.calendar.min_date && response.data.calendar.max_date) {
        this.minMaxDate.minDate = moment(
          response.data.calendar.min_date,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
        this.minMaxDate.maxDate = moment(
          response.data.calendar.max_date,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
      } else {
        const today = moment('YYYY-MM-DD');
        const yearBack = moment('YYYY-MM-DD').subtract('1', 'years');
        this.minMaxDate.maxDate = today.format('MM/DD/YYYY');
        this.minMaxDate.minDate = yearBack.format('MM/DD/YYYY');
      }

      const storageValues = this.transformFilterFromAPI(
        response.data.filters?.selectedFilter?.filter || []
      );
      let category = '';
      let categoryExists = true;
      const categorySelectionList = filterHierarchyPaths(
        this.filterData?.category,
        {}
      );
      this.categoryNodeMap = NodeIdMap(this.filterData?.category, {});
      let globalFilter = getGlobalSettingsForMS(
        undefined,
        categorySelectionList,
        false
      );
      this.previousPage = globalFilter?.[constants.NAVIGATE_FROM];
      setGlobalSettingsForMS(constants.NAVIGATE_FROM, {}, true);
      this.selectedCustomerFilters =
        getSavedCustomerFilters(this.filterData) || {};
      category =
        globalFilter?.category ||
        globalFilter?.category_hierarchy?.[0] ||
        this.secondaryFilterComputedData.category_hierarchy?.values[0]?.title;
      if (category) {
        if (!categorySelectionList[category.toLowerCase()]) {
          categoryExists = false;
        }
      }
      const hasLocalStorage =
        Object.keys(globalFilter).length && categoryExists;
      if (hasLocalStorage) {
        this.updateSettingsFilterData();
        globalFilter = getGlobalSettingsForMS(
          undefined,
          categorySelectionList,
          false
        );
      }
      checkForDisplayShareInOptions();
      if (hasLocalStorage) {
        let nestedSettings = {
          ...configurationSetting(
            globalFilter.nestedSettings,
            this.globalToLocal
          )
        };
        setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
        setGlobalSettingsForMS(constants.SUBCATEGORY, globalFilter.subcategory);
        this.computedSelectedSettingsValue = getGlobalSettingsForMS(
          'nestedSettings',
          undefined,
          false
        );
        storageValues.values.date_range = globalFilter?.date_range;
        this.setSubcategory(globalFilter, category, categorySelectionList);
        if (globalFilter?.[constants.ATTRIBUTES]) {
          this.defaultAttributesOptions.values =
            globalFilter[constants.ATTRIBUTES];
          this.selectedAttributes = getAttributesData(this.dynamicAttributes);
          this.getDisableData(this.dynamicAttributes);
        }
        setGlobalSettingsForMS(constants.CATEGORY, {}, true);
        setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, [category]);
      } else {
        removeGlobalSettingsForMS();
        storageValues.values.date_range = {
          name: 'last4Week',
          compare: true,
          compare_name: null
        };
        storageValues.values.category_hierarchy = [category];
        storageValues.order = ['category_hierarchy'];

        let nestedSettings = { ...this.computedSelectedSettingsValue };

        let selectedPath = {};
        selectedPath[category] = true;
        this.dynamicAttributes = getAttributeList(
          selectedPath,
          categorySelectionList
        );

        setGlobalSettingsForMS(null, {
          ...storageValues.values,
          nestedSettings
        });
      }

      storageValues.values.category_hierarchy = [category];
      storageValues.order = ['category_hierarchy'];
      localStorage.setItem(
        this.filterLocalStorageKey,
        JSON.stringify(storageValues.values)
      );
      localStorage.setItem(
        `${this.filterLocalStorageKey}-order`,
        JSON.stringify(storageValues.order)
      );
      this.showFilter = true;

      await this.checkDatesForCategory([
        { dimensionValue: category.toLowerCase() + '~' }
      ]);
      this.initializeFilter();

      const widgetName = msProCategoryConstants.categoryShareSummary;
      this.tableDataService = new TableWidgetService(
        this.page,
        widgetName,
        this.selectedView.pageId,
        this.selectedView.globalViewId
      );
      this.tableDataService.savedSortState =
        globalFilter?.[constants.SORTING_OBJECT]?.categoryShareSummary_category;

      this.listApiResolved = true;
      this.pageFetched = true;
    },
    initializeFilter() {
      setTimeout(() => {
        document.querySelector('.filter-list__item').click();
      }, 0);
      setTimeout(() => {
        document.querySelector('body').click();
        document.querySelector('.rb-filter .icon-cross').style.display = 'none';
        this.refreshWidgets();
      }, 0);
    },
    getFilterData(response) {
      const reqbody = {
        service: 'DASHBOARD_SERVICE',
        endPoint: '/marketshare/filters',
        request: {
          page: 'MARKETSHARE-EXECUTIVE'
        }
      };
      if (response.status === 200) {
        this.fetchFilterV3(
          {
            ...reqbody,
            endPoint: '/marketshare/filters' + '?v2=' + this.v2
          },
          (data) => data
        ).finally(async () => {
          await this.setFilterData(response);
          this.initializeTable();
        });
      }
    },
    async fetchPageList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.getFilterData(response);
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      this.$store.dispatch('setInitialSelections', {});
      setGlobalSettingsForMS(constants.NAVIGATE_FROM, 'category-page');
      setGlobalSettingsForMS(
        'category',
        getGlobalSettingsForMS('category_hierarchy')[0]
      );
      next();
    } catch (e) {
      this.$snackbar.open(msProConstants.errorMessage);
    } finally {
      next();
    }
  }
};
</script>
<style lang="css" scoped>
.line-dimensions {
  height: 26px;
  width: 1px;
}
.view-port {
  height: calc(100vh - 72px);
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: scroll;
}
.u-height-36px {
  height: 36px;
}
.box-shadows {
  box-shadow: 0 1px 16px 0 rgb(43 51 59 / 25%);
}
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
.mspro-banner {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
.subcagetory-dropdown {
  border: 1px dashed #ebeef6;
}
.u-height-400 {
  height: 400px;
}
</style>
