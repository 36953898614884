<template>
  <widgetContainer
    class="chargebackStatsWidget"
    :header-options="[]"
    :footer-options="[]"
    :context="context"
    :title="(metadata || {}).label"
    :is-loading="isWidgetLoading"
    :enable-footer="false"
  >
    <template
      slot="body"
      slot-scope="{}"
    >
      <div class="stats-info card">
        <div class="u-font-size-5 u-color-grey-mid-light u-spacing-mb-s">
          Total Chargebacks
        </div>
        <metric
          :data="chargebacksAmount"
          :config="{
            invertTag2: true,
            tag1Unit: { pre: '', suff: 'currency' },
            tag2Unit: { suff: '%' }
          }"
          size="l"
          class="u-spacing-mb-xs"
        />
        <div
          class="u-font-size-7 u-color-grey-lighter u-display-flex u-flex-align-items-center"
        >
          Across
          <span class="u-font-weight-600 u-color-grey-light u-spacing-mh-xs">
            {{ totalChargebackGroups.tag1 }}
          </span>
          Chargeback groups and
          <span class="u-font-weight-600 u-color-grey-light u-spacing-mh-xs">
            {{ totalChargebackTypes.tag1 }}
          </span>
          chargeback types
        </div>
      </div>
      <div class="stats-info card">
        <div class="u-font-size-5 u-color-grey-mid-light u-spacing-mb-s">
          No. of Chargeback Types meeting the target
        </div>
        <div class="stats-info__metric u-spacing-mb-xs">
          <metric
            class="chargeback-type-metric"
            :hide-zero="false"
            :data="chargebacksMetTarget"
            :config="{
              invertTag2: false,
              tag1Unit: { pre: '', suff: '' },
              tag2Unit: { suff: ' CBT' }
            }"
            size="l"
          />
        </div>
        <div
          class="u-font-size-7 u-color-grey-lighter u-display-flex u-flex-align-items-center"
        >
          Across
          <span class="u-font-weight-600 u-color-grey-light u-spacing-mh-xs">
            {{ totalChargebackTypes.tag1 }}
          </span>
          Chargeback types
        </div>
      </div>
    </template>
  </widgetContainer>
</template>
<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import metric from '@/components/basic/metric.vue';
import { cloneDeep, isEqual } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'ChargebackStats',
  components: {
    widgetContainer,
    metric
  },
  mixins: [cwMixin],
  inject: {
    scrollTo: {
      type: Function,
      default: function () {
        console.log('Scroll To Widget');
      }
    }
  },
  props: {},
  data() {
    return {
      myKey: 1,
      isWidgetLoading: true,
      metricEventConfig: this.metadata,
      metricsArray: [],
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      metricsDataResponse: [],
      metricData: {},
      totalChargebackGroups: {},
      totalChargebackTypes: {},
      chargebacksAmount: {},
      chargebacksMetTarget: {}
    };
  },
  computed: {},
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    }
  },
  created() {
    this.initWidget();
    this.fetchData();
  },
  methods: {
    initWidget() {
      this.metricsArray = Object.values(this.metadata.metrics);
    },
    legendsTransformer(metricsDataResponse) {
      metricsDataResponse.forEach((metricGroup) => {
        metricGroup.forEach((metric) => {
          this.$set(this.metricData, this.metadata.metrics[metric.name].label, {
            tag1: metric.RESULT[metric.name],
            tag2: metric.PVP[
              metric.alias === 'no_of_chargebacks_met_target'
                ? 'PVP_DIFF_' + metric.name
                : 'PVP_' + metric.name
            ]
          });
        });
      });
      this.chargebacksAmount = this.metricData['Total Chargeback Amount'];
      this.totalChargebackGroups = this.metricData['Total Chargeback Groups'];
      this.chargebacksMetTarget =
        this.metricData['No of chargeback types met target'];
      this.totalChargebackTypes = this.metricData['Total Chargeback Types'];
      this.isWidgetLoading = false;
    },
    fetchData() {
      this.isWidgetLoading = true;
      const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);

      let request, api;
      const dataGroupsList = Object.keys(dataGroups);
      for (let i = 0; i < dataGroupsList.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );

        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        request = this.tempTransformer(request, metricsListName);

        dashUtils
          .fetchDataAPI(request, api)
          .then((args) => {
            this.$set(this.metricsDataResponse, i, args.entityData[0].data);
            this.legendsTransformer(this.metricsDataResponse);
          })
          .catch(() => {
            this.myKey++;
          });
      }
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    pinWidget() {
      this.togglePinnedWidget();
    }
  }
};
</script>
<style lang="css">
.chargebackStatsWidget {
  background: unset !important;
  box-shadow: none !important;
}
.chargebackStatsWidget .header-container-cwc {
  padding: 0;
}
.chargebackStatsWidget .custom_widget_body {
  padding: 0;
  background: unset;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
}
.chargebackStatsWidget .custom_widget_body .stats-info {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}
.chargebackStatsWidget
  .custom_widget_body
  .stats-info
  .chargeback-type-metric
  .primary_metric {
  color: #3ea95e;
}
</style>
