import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

const skuComponent = Vue.extend({
  computed: {
    rbSkuData() {
      return {
        title: this.params.data.asin_name,
        imageUrl: this.params.data.image_url,
        asin: this.params.data.asin,
        productUrl: this.params.data.product_url,
        showImage: true
      };
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  },
  template: `<div class="u-display-flex u-text-overflow-ellipsis">
  <rb-sku :show-image="rbSkuData.showImage" :title="rbSkuData.title" :image-url="rbSkuData.imageUrl" :asin="rbSkuData.asin" :product-page-url="rbSkuData.productUrl" @navigateToSDP="navigateToSDP" :enableSDP="true">
  </rb-sku>
  </div>`
});

const selectcomponent = Vue.extend({
  computed: {
    selectcomponentData() {
      if (this.params && this.params.data) {
        return this.params.data;
      }
    }
  },
  template: `<div class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-end">
    <rb-select class="contentPageCustomSelect u-font-size-5 u-border-bottom u-border-width-s u-border-color-grey-xxx-light" :width="'240px'" :sendDetails="true" :context="(selectcomponentData || {})" :onClose="params.vueRef.brandSelected" :options="((selectcomponentData || {}).brands || [])">
      <div class="u-display-flex u-flex-align-items-center u-cursor-pointer" slot="trigger">
        <span class="u-text-overflow-ellipsis" :title="(selectcomponentData || {}).brand"> {{ (selectcomponentData || {}).brand }}</span>
        <rb-icon class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter" :icon="'caret-down'"></rb-icon>
      </div>
    </rb-select>
  </div>`
});

const skuCountPotentialContentIssuesColumns = [
  {
    title: '',
    key: 'brand_name',
    showToolTip: true,
    toolTipText: '',
    type: 'selectcomponent',
    keyOrder: 1
  },
  {
    title: 'Title < 40 characters',
    key: 'is_title_less_than_40_chars',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 2
  },
  {
    title: 'Images < 4',
    key: 'is_image_count_less_than_4',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 3
  },
  {
    title: 'A+ content',
    key: 'is_aplus_content_present',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 4
  },
  {
    title: 'SKUs without Premium A+ content',
    key: 'sku_without_premium_aplus_content_present',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 5
  },
  {
    title: 'Bullet points < 3',
    key: 'is_bullet_count_less_than_3',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 6
  },
  {
    title: 'Video content',
    key: 'is_video_present',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 7
  },
  {
    title: 'Reviews < 21',
    key: 'is_review_count_less_than_21',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 8
  },
  {
    title: 'Ratings < 3.5',
    key: 'is_avg_product_rating_less_than_3_pt_5',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 9
  }
];

const skuLevelAnalysisColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    pinned: 'left',
    showToolTip: true,
    toolTipText: '',
    type: 'asin',
    keyOrder: 1
  },
  {
    title: 'Last 30 days revenue',
    key: 'ordered_revenue_last_30days',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    unit: 'currency',
    keyOrder: 2
  },
  {
    title: 'Title length',
    key: 'length_of_title',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 3
  },
  {
    title: 'No. of images',
    key: 'imagescount',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 4
  },
  {
    title: 'A+ content',
    key: 'apluscontent',
    showToolTip: true,
    toolTipText: '',
    type: 'string',
    keyOrder: 5
  },
  {
    title: 'Premium A+ content',
    key: 'premium_apluscontent',
    showToolTip: true,
    toolTipText: '',
    type: 'string',
    keyOrder: 6
  },
  {
    title: 'No. of bullets',
    key: 'productdetailsbulletcount',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 7
  },
  {
    title: 'Videos',
    key: 'videopresent',
    showToolTip: true,
    toolTipText: '',
    type: 'string',
    keyOrder: 8
  },
  {
    title: 'Total reviews',
    key: 'total_review_count',
    showToolTip: true,
    toolTipText: '',
    type: 'number',
    formatReq: true,
    keyOrder: 9
  },
  {
    title: 'Average rating',
    key: 'avg_product_rating',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    formatReq: true,
    keyOrder: 10
  },
  {
    title: 'Important Information',
    key: 'important_information',
    showToolTip: true,
    toolTipText: '',
    type: 'string',
    keyOrder: 11
  }
];

function processColumnForDownload(colMapArr) {
  var _colMapArr = [];
  var hardlineKeys = [
    'premium_apluscontent',
    'sku_without_premium_aplus_content_present'
  ];
  for (const i in colMapArr) {
    if (
      hardlineKeys.indexOf(colMapArr[i].dbKey) > -1 &&
      !Vue.options.filters.config_check('feature.hardline.enable')
    ) {
      continue;
    } else {
      _colMapArr.push(colMapArr[i]);
    }
  }
  return _colMapArr;
}

var columnDefs = [];
function getColumnDefinition(vueRef, tableKey, columns) {
  var _columnDefs = [];
  var keysToCheck = [
    'is_aplus_content_present',
    'is_bullet_count_less_than_3',
    'ordered_revenue_last_30days',
    'apluscontent',
    'productdetailsbulletcount'
  ];
  var hardlineKeys = [
    'premium_apluscontent',
    'sku_without_premium_aplus_content_present'
  ];
  for (const i in columns) {
    if (keysToCheck.indexOf(columns[i].key) > -1) {
      // if outsideIn holds true then enable the columns in keysToCheck array in the table otherwise don't.
      if (!vueRef.outsideIn) {
        continue;
      }
    }
    // show hardline columns based on the config
    if (hardlineKeys.indexOf(columns[i].key) > -1) {
      if (!Vue.options.filters.config_check('feature.hardline.enable')) {
        continue;
      }
    }
    const obj = {};
    obj.field = i;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (columns[i].type === 'numeric') {
      keyType = columns[i].unit ? columns[i].unit : 'numeric';
    }
    if (columns[i].type === 'number') {
      keyType = columns[i].type;
    }
    if (columns[i].type === 'date') {
      keyType = columns[i].type;
    }
    obj.field = columns[i].key;
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: columns[i].formatReq === false ? undefined : formatter
    };
    obj.cellRendererFramework = 'genericTableCell';
    obj.headerName = columns[i].title;
    obj.title = columns[i].title;
    if (tableKey === 'skuCountForContentIssues') {
      obj.headerComponentParams = {
        enableSorting: false
      };
    } else {
      obj.headerComponentParams = {
        enableSorting: true
      };
    }
    if (columns[i].type === 'asin') {
      obj.cellRendererFramework = skuComponent;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = columns[i];
      obj.minWidth = 300;
    }
    if (columns[i].type === 'selectcomponent') {
      obj.cellRendererFramework = selectcomponent;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = {};
      obj.cellRendererParams.vueRef = vueRef;
      obj.suppressSizeToFit = true;
      obj.width = 150;
    }
    obj.keyOrder = columns[i].keyOrder;
    columns[i].pinned && (obj.pinned = columns[i].pinned);
    if (columns[i].showToolTip) {
      obj.headerComponentParams.toolTipText = columns[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      filters: {
        emit: 'contentFilterApplied',
        listen: {
          contentFilterApplied: {
            action: 'updateContentSelectedFilter'
          }
        },
        left: [
          {
            title: 'Product Group',
            filterValueKey: 'content_product_group',
            smart: true
          },
          {
            title: 'Category',
            subFilterValueKey: 'content_subcategory',
            filterValueKey: 'content_category',
            twoLevel: true,
            smart: true
          },
          {
            title: 'Brand',
            filterValueKey: 'content_brand',
            smart: true
          }
        ]
      },
      widgets: {
        widget1: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getContentIssuesData',
            key: 'contentIssues',
            listen: {
              contentFilterApplied: {
                action: 'getContentIssuesData'
              }
            },
            localFilters: [
              {
                dimensionName: 'is_catalog_sku',
                dimensionValue: 1
              }
            ]
          },
          header: {},
          footer: {
            date: 'pi'
          },
          body: {
            APIConfig: {
              cubeName: 'content_index_v2',
              measuresList: [
                'is_title_less_than_40_chars',
                'is_image_count_less_than_4',
                'is_bullet_count_less_than_3',
                'is_video_present',
                'is_aplus_content_present',
                'is_premium_aplus_content_present',
                'is_avg_product_rating_less_than_3_pt_5',
                'is_review_count_less_than_21'
              ],
              groupByDimensionsList: [],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin']
              }
            },
            getTagData(data, mapping) {
              var metrics = {};
              for (var i in data.RESULT) {
                metrics[i] = {
                  tag1: data.RESULT[i] || 0
                };
              }
              return metrics;
            },
            tagConfig: {
              tag1Unit: {
                roundoff: 0
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getSKUCountForContentIssuesData',
            key: 'skuCountForContentIssues',
            listen: {
              contentFilterApplied: {
                action: 'getSKUCountForContentIssuesData'
              }
            }
          },
          header: {
            title: 'SKU COUNT WITH POTENTIAL CONTENT ISSUES',
            actions: {
              download: {
                fileName: 'SKU count with potential content issues',
                colMap: processColumnForDownload([
                  {
                    displayName: 'Brand',
                    dbKey: 'brand'
                  },
                  {
                    displayName: 'Title < 40 characters',
                    dbKey: 'is_title_less_than_40_chars'
                  },
                  {
                    displayName: 'No. of images < 4',
                    dbKey: 'is_image_count_less_than_4'
                  },
                  {
                    displayName: 'A+ content',
                    dbKey: 'is_aplus_content_present'
                  },
                  {
                    displayName: 'SKUs without Premium A+ content',
                    dbKey: 'sku_without_premium_aplus_content_present'
                  },
                  {
                    displayName: 'No. of bullet points < 3',
                    dbKey: 'is_bullet_count_less_than_3'
                    // checkOutsideIn: true
                  },
                  {
                    displayName: 'Video content',
                    dbKey: 'is_video_present'
                  },
                  {
                    displayName: 'Rating < 3.5',
                    dbKey: 'is_avg_product_rating_less_than_3_pt_5'
                  },
                  {
                    displayName: 'No. of reviews < 21',
                    dbKey: 'is_review_count_less_than_21'
                  }
                ]),
                action: 'downloadInContentPage',
                transform: function (config, data, that) {
                  var _config = Vue.util.extend({}, config);
                  // Deep clone or JSON stringify are not used here
                  // Since APIConfig by default doesn't have page nor limit
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'pi'
          },
          body: {
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              cubeName: 'content_index_v2',
              measuresList: [
                'is_title_less_than_40_chars',
                'is_image_count_less_than_4',
                'is_bullet_count_less_than_3',
                'is_video_present',
                'is_aplus_content_present',
                'sku_without_premium_aplus_content_present',
                'is_avg_product_rating_less_than_3_pt_5',
                'is_review_count_less_than_21'
              ],
              groupByDimensionsList: ['brand', 'is_catalog_sku'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin']
              },
              orderByList: [
                {
                  dimension: 'is_catalog_sku',
                  direction: 'DESC'
                },
                {
                  dimension: 'is_title_less_than_40_chars',
                  direction: 'DESC'
                }
              ]
            },
            columnDefs: getColumnDefinition(
              that,
              'skuCountForContentIssues',
              skuCountPotentialContentIssuesColumns
            )
          }
        },
        widget3: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getSKULevelAnalysisData',
            key: 'skuLevelAnalysis',
            listen: {
              contentFilterApplied: {
                action: 'getSKULevelAnalysisData',
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            },
            localFilters: [
              {
                dimensionName: 'is_catalog_sku',
                dimensionValue: 1
              }
            ]
          },
          header: {
            search: true,
            searchPlaceholder: 'Search for a SKU',
            searchParams: ['search'],
            title: 'SKU LEVEL ANALYSIS',
            actions: {
              download: {
                fileName: 'Content analysis - SKU level',
                colMap: processColumnForDownload([
                  {
                    displayName: 'SKU Title',
                    dbKey: 'asin_name'
                  },
                  {
                    displayName: 'SKU',
                    dbKey: 'asin'
                  },
                  {
                    displayName: 'Product Page URL',
                    dbKey: 'product_url'
                  },
                  // {
                  //   displayName: 'SKU Image URL',
                  //   dbKey: 'image_url'
                  // },
                  {
                    displayName: 'Last 30 days revenue',
                    dbKey: 'ordered_revenue_last_30days'
                    // checkOutsideIn: true
                  },
                  {
                    displayName: 'Title length',
                    dbKey: 'length_of_title'
                  },
                  {
                    displayName: 'No. of images',
                    dbKey: 'imagescount'
                  },
                  {
                    displayName: 'A+ content',
                    dbKey: 'apluscontent'
                    // checkOutsideIn: true
                  },
                  {
                    displayName: 'Premium A+ content',
                    dbKey: 'premium_apluscontent'
                  },
                  {
                    displayName: 'No. of bullets',
                    dbKey: 'productdetailsbulletcount'
                    // checkOutsideIn: true
                  },
                  {
                    displayName: 'Videos',
                    dbKey: 'videopresent'
                  },
                  {
                    displayName: 'Total reviews',
                    dbKey: 'total_review_count'
                  },
                  {
                    displayName: 'Average rating',
                    dbKey: 'avg_product_rating'
                  },
                  {
                    displayName: 'Important Information',
                    dbKey: 'important_information'
                  }
                ]),
                action: 'downloadInContentPage',
                transform: function (config, data, that) {
                  var _config = Vue.util.extend({}, config);
                  // Stringify works here since on extend function somehow doesn't include the transform function on _config
                  // So it doesn't cause Circular reference error.
                  _config = JSON.parse(JSON.stringify(_config));
                  _config.body.APIConfig.page = undefined;
                  _config.body.APIConfig.limit = undefined;
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'pi'
          },
          body: {
            defaultOrderBy: [
              {
                dimension: 'asin',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              limit: 5,
              page: 1,
              cubeName: 'content_index_v2',
              measuresList: [
                'count',
                'asin_name',
                'image_url',
                'ordered_revenue_last_30days',
                'length_of_title',
                'imagescount',
                'apluscontent',
                'premium_apluscontent',
                'productdetailsbulletcount',
                'videopresent',
                'product_url',
                'total_review_count',
                'avg_product_rating',
                'important_information'
              ],
              groupByDimensionsList: ['asin'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              orderByList: [
                {
                  dimension: 'ordered_revenue_last_30days',
                  direction: 'DESC'
                },
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ]
            },
            columnDefs: getColumnDefinition(
              that,
              'skuLevelAnalysis',
              skuLevelAnalysisColumns
            )
          }
        }
      }
    };
  }
};
