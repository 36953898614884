













// @ts-ignore
import PieChartWidget from '@/components/pages/insights/amazon/market-share/molecules/pie-chart-widget-with-card.vue';
// @ts-ignore
import { downloadFile } from '@/components/pages/skuDetails/helper';
import { isString, cloneDeep } from 'lodash';
// @ts-ignore
import Vue, { PropType } from 'vue';
import {
  PieChartWidgetInputParams,
  CategoryShareAggregateInputParams,
  CategoryShareDownloadInputParams
} from '../service/data-api';
import { DonutDataService } from '../service/donut-chart-service';
import { V1Metrics, V2Metrics } from '../types/filters';
import {
  colorPalleteIndexed,
  constants,
  asinLevelV2ConfigEnabled
} from '@/components/pages/insights/amazon/market-share/utils';

export interface Where {
  date: Date;
  pvpDate: PvpDate;
  dimensionNameValueList: DimensionNameValueList[];
}

export interface Date {
  from: string;
  to: string;
  name: string;
  page_wise_min_max_key: string;
}

export interface PvpDate {
  from: string;
  to: string;
  compare_name: any;
}

export interface DimensionNameValueList {
  dimensionName: string;
  dimensionValue: string;
}

export interface SettingValue {
  viewBy: ViewBy;
  displayShareIn: DisplayShareIn;
  include3p: Include3p[];
}

export interface ViewBy {
  entityType: string;
  label: string;
}

export interface DisplayShareIn {
  metricsList: string;
  label: string;
}

export interface Include3p {
  dimensionName: string;
  label: string;
}

export default Vue.extend({
  name: 'MarketShareOverviewWidget',
  components: {
    PieChartWidget
  },
  props: {
    hideMetric: {
      type: Boolean,
      default: false
    },
    hideDownload: {
      type: Boolean,
      default: false
    },
    pageId: {
      type: Number,
      required: true
    },
    globalViewId: {
      type: Number,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    globalSettings: Object as PropType<{
      where: Where;
      settingValue: {
        viewBy: {
          entityType: 'brand' | 'manufacturer';
          label: string;
        };
        displayShareIn: {
          metricsList: 'SALES_ESTIMATES' | 'UNIT_ESTIMATES';
          label: string;
        };
        include3p: {
          dimensionName: string;
          label: string;
        }[];
      };
    }>
  },
  data: () => {
    return {
      colorPalleteIndexed: colorPalleteIndexed,
      dataService: new DonutDataService()
    };
  },
  computed: {
    // TODO: Move it out of here to the service
    computeTippyTitle(): string {
      let title = '';
      if (
        this.globalSettings?.settingValue?.displayShareIn.metricsList.includes(
          'SALES_ESTIMATES'
        )
      ) {
        title = `The estimated sales across all brands for the selected category/sub category`;
      } else {
        title = `The estimated number of units sold across all brands for the selected category/sub category`;
      }
      return title;
    },
    v2(): boolean {
      return asinLevelV2ConfigEnabled();
    }
  },
  watch: {
    globalSettings: {
      immediate: true,
      handler(
        newVal: {
          where: Where;
          settingValue: {
            viewBy: {
              entityType: 'brand' | 'manufacturer';
              label: string;
            };
            displayShareIn: {
              metricsList: 'SALES_ESTIMATES' | 'UNIT_ESTIMATES';
              label: string;
            };
            include3p: {
              dimensionName: string;
              label: string;
            }[];
          };
        },
        oldVal: unknown
      ) {
        if (newVal.settingValue === undefined) {
          return;
        }
        const inputParams = this.inputParamsSelf(newVal);
        this.dataService.update(
          this.pageId,
          this.globalViewId,
          inputParams.pieChartDataApiCallConfig,
          inputParams.categorySharePercentConfig
        );
      }
    }
  },
  created() {},
  methods: {
    async download() {
      let { downloadCategorySharePercentConfig } = this.inputParamsSelf(
        this.globalSettings
      );
      const data = await this.dataService.download(
        downloadCategorySharePercentConfig
      );
      downloadFile(data);
    },
    inputParamsSelf(globalSettings: {
      where: Where;
      settingValue: {
        viewBy: {
          entityType: 'brand' | 'manufacturer';
          label: string;
        };
        displayShareIn: {
          metricsList: 'SALES_ESTIMATES' | 'UNIT_ESTIMATES';
          label: string;
        };
        include3p: {
          dimensionName: string;
          label: string;
        }[];
      };
    }): {
      pieChartDataApiCallConfig: PieChartWidgetInputParams;
      categorySharePercentConfig: CategoryShareAggregateInputParams;
      downloadCategorySharePercentConfig: CategoryShareDownloadInputParams;
    } {
      const globalWhereClause = cloneDeep(globalSettings.where);
      // For Category Size metric the dimensionValueList should be same for include3p and exclude3p for client
      let categoryShareWhereClause = {
        ...globalWhereClause,
        dimensionNameValueList: globalWhereClause.dimensionNameValueList.filter(
          (el) =>
            !(
              el.dimensionName === constants.THREE_P_GROUP_FLAG &&
              el.dimensionValue === 'comp'
            )
        )
      };
      return {
        pieChartDataApiCallConfig: {
          template:
            (`${globalSettings.settingValue.displayShareIn.metricsList}` +
              (!this.v2 ? '_SHARE_PERCENT' : '')) as V1Metrics | V2Metrics,
          variableReplace: {
            ':entityType': globalSettings.settingValue.viewBy.entityType
          },
          substitute: {
            where: globalWhereClause,
            'operations.limit': 8
          },
          displayShareIn: globalSettings.settingValue.displayShareIn.metricsList
        },
        categorySharePercentConfig: {
          template:
            `${globalSettings.settingValue.displayShareIn.metricsList.replace(
              '_SHARE_PERCENT',
              ''
            )}_SIZE` as 'SALES_ESTIMATES_SIZE',
          substitute: {
            where: categoryShareWhereClause
          }
        },
        downloadCategorySharePercentConfig: {
          template:
            `${globalSettings.settingValue.displayShareIn.metricsList}` +
            (!this.v2 ? '_SHARE_PERCENT' : ''),
          variableReplace: {
            ':widget': 'pieChart' + (this.v2 ? '_v2' : ''),
            ':page': 'MARKETSHARE-CATEGORY'
          },
          substitute: {
            where: globalWhereClause,
            entityType: globalSettings.settingValue.viewBy.entityType,
            'operations.orderByList': [
              {
                dimension: `marketshares_${globalSettings.settingValue.displayShareIn.metricsList.toLowerCase()}_percent`,
                direction: 'DESC'
              }
            ]
          }
        }
      };
    },
    colorPopulator(
      color: string,
      d: string | c3.DataSeries | c3.DataPoint
    ): string {
      let id = '';
      if (isString(d)) {
        id = d;
      } else {
        id = d.id ? d.id : '';
      }
      if (id === 'Others') {
        return '#e9eaeb';
      }
      const idx = this.dataService.inputParamsCurrent?.chartData.findIndex(
        (x) => x.brand?.trim() === id?.trim()
      );
      return idx !== undefined ? colorPalleteIndexed[idx] : '';
    }
  }
});
