export const SKU_STATE = {
  POSITIVE: 'positive',
  IMPROVED: 'improved',
  NEGATIVE: 'negative'
};

export const SKU_STATUS = {
  ticketRaised: 'Ticket Raised',
  pending: 'Pending'
};

export const RETAILER = {
  AMAZON: 'amazon',
  WALMART: 'walmart',
  TARGET: 'target',
  BEST_BUY: 'best_buy',
  HOME_DEPOT: 'the_home_depot',
  LOWES: 'lowes'
};

export const SKU_DATA = [
  {
    id: 1,
    retailer: RETAILER.AMAZON,
    scoreDetails: {
      title: 90,
      description: 85,
      image: 90,
      overall: 89
    },
    skuDetails: {
      id: 1,
      contentType: 'image',
      currentImageUrl:
        'https://m.media-amazon.com/images/I/91vAUToHkcL._AC_SY355_.jpg',
      gtinNumber: 'B09RMRNSBF',
      state: 'positive',
      suggestedImageUrl:
        'https://m.media-amazon.com/images/I/91vAUToHkcL._AC_SY355_.jpg',
      contentScore: 90,
      contentScoreDescription: 'Content matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 1,
      contentType: 'name',
      state: 'positive',
      status: '',
      contentScore: 90,
      currentContent:
        'LG C2 Series 65-Inch Class OLED evo Gallery Edition Smart TV OLED65C2PUA, 2022 - AI-Powered 4K TV, Alexa Built-in',
      suggestedContent:
        'LG C2 Series 65-Inch Class OLED evo Gallery Edition Smart TV OLED65C2PUA, 2022 - AI-Powered 4K TV, Alexa Built-in',
      contentScoreDescription: 'Content matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    netQuantity: '10',
    descriptionDetails: {
      id: 1,
      contentType: 'description',
      state: 'positive',
      status: '',
      contentScore: 65,
      currentContent:
        'Experience amazing, beautiful picture quality with infinite contrast, deep black, and over a billion colors, now even brighter',
      suggestedContent:
        '3 Musketeers and this is a long name wrapping into 2 lines and it would show the whole content.',
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    buyBoxType: '1p'
  },
  {
    id: 2,
    retailer: RETAILER.AMAZON,
    scoreDetails: {
      overall: 76,
      image: 95,
      title: 70,
      description: 65
    },
    skuDetails: {
      id: 2,
      contentType: 'image',
      currentImageUrl:
        'https://m.media-amazon.com/images/I/916NVBBT0mS._AC_SX679_.jpg',
      gtinNumber: 'B0795VHRJZ',
      state: 'positive',
      suggestedImageUrl:
        'https://m.media-amazon.com/images/I/916NVBBT0mS._AC_SX679_.jpg',
      contentScore: 95,
      contentScoreDescription: 'Content matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 2,
      contentType: 'name',
      state: 'improved',
      status: 'pending',
      contentScore: 70,
      currentContent: 'LG C1 Series 65-Inch',
      suggestedContent:
        'LG C1 Series 65-Inch Class OLED Smart TV OLED65C1PUB, 2021 - 4K TV, Alexa Built-in',
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    netQuantity: '2',
    descriptionDetails: {
      id: 2,
      contentType: 'description',
      state: 'improved',
      status: 'pending',
      contentScore: 65,
      currentContent:
        "LG OLED TV is a joy to behold LG's 8 million self-lit OLED pixels turn on and off",
      suggestedContent:
        "LG OLED TV is a joy to behold LG's 8 million self-lit OLED pixels turn on and off independently for content with deep black, over a billion rich colors, and infinite contrast for a viewing experience like no other",
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    buyBoxType: '1p'
  },
  {
    id: 3,
    retailer: RETAILER.AMAZON,
    scoreDetails: {
      title: 95,
      description: 85,
      image: 65,
      overall: 81
    },
    skuDetails: {
      id: 3,
      contentType: 'image',
      currentImageUrl:
        'https://m.media-amazon.com/images/I/71G6Vh3fhPL._AC_SY450_.jpg',
      gtinNumber: 'B0795VHRJZ',
      state: 'negative',
      suggestedImageUrl:
        'https://m.media-amazon.com/images/I/71AWPASl7rL._AC_SX679_.jpg',
      contentScore: 65,
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 3,
      contentType: 'name',
      state: 'positive',
      status: '',
      contentScore: 95,
      currentContent:
        'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X, High-Res Audio, Synergy TV, Meridian, HDMI eARC, 4K Pass Thru with Dolby Vision',
      suggestedContent:
        'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X, High-Res Audio, Synergy TV, Meridian, HDMI eARC, 4K Pass Thru with Dolby Vision',
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    netQuantity: '1',
    descriptionDetails: {
      id: 3,
      contentType: 'description',
      state: 'positive',
      status: '',
      contentScore: 85,
      currentContent: `[Immersive 3.1.2ch Great Cinema Sound] 3.1.2 Channel audio with 380W power
      [Premium Sound Solutions] Dolby Atmos for an immersive sound experience.`,
      suggestedContent: `[Immersive 3.1.2ch Great Cinema Sound] 3.1.2 Channel audio with 380W power
        [Premium Sound Solutions] Dolby Atmos for an immersive sound experience. Meridian Audio Technology partnering up to advance sound. High-Res Audio for more accurate sound and a more enjoyable listening experience.`,
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    buyBoxType: '3p'
  },
  {
    id: 4,
    retailer: RETAILER.WALMART,
    scoreDetails: {
      title: 55,
      description: 70,
      image: 95,
      overall: 53
    },
    skuDetails: {
      id: 4,
      contentType: 'image',
      currentImageUrl:
        'https://i5.walmartimages.com/asr/1e0b8142-c238-4fe4-bc32-44577d4c1b0d.b56335f32f0fb256c786b6593eaf6422.jpeg',
      gtinNumber: '1026258229',
      state: 'positive',
      suggestedImageUrl:
        'https://i5.walmartimages.com/asr/1e0b8142-c238-4fe4-bc32-44577d4c1b0d.b56335f32f0fb256c786b6593eaf6422.jpeg',
      contentScore: 95,
      contentScoreDescription: 'Content matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 4,
      contentType: 'name',
      state: 'negative',
      status: 'pending',
      contentScore: 55,
      currentContent: 'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X',
      suggestedContent:
        'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X, High-Res Audio, Synergy with LG TV, Meridian, HDMI eARC, 4K Pass Thru with Dolby Vision - (Open Box)',
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    netQuantity: '1',
    descriptionDetails: {
      id: 4,
      contentType: 'description',
      state: 'improved',
      status: 'pending',
      contentScore: 70,
      currentContent:
        'Wall-Mountable, Meridian Audio Technology, High-Resolution Audio, TV Sound Mode Share, USB Playback',
      suggestedContent:
        'Wall-Mountable, Meridian Audio Technology, High-Resolution Audio, TV Sound Mode Share, USB Playback, LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X, High-Res Audio, Synergy TV, Meridian, HDMI eARC, 4K Pass Thru with Dolby Vision',
      contentScoreDescription: 'Content not matching retailer PIM',
      source: 'PIM'
    },
    buyBoxType: '3p'
  },
  {
    id: 5,
    retailer: RETAILER.BEST_BUY,
    scoreDetails: {
      title: 75,
      description: 55,
      image: 95,
      overall: 75
    },
    skuDetails: {
      id: 5,
      contentType: 'image',
      currentImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      gtinNumber: '6517511',
      state: 'positive',
      suggestedImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      contentScore: 95,
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 5,
      contentType: 'name',
      state: 'improved',
      status: 'pending',
      contentScore: 75,
      currentContent: 'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV',
      suggestedContent:
        'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV with Dolby sound',
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    netQuantity: '1',
    descriptionDetails: {
      id: 5,
      contentType: 'description',
      state: 'negative',
      status: 'pending',
      contentScore: 55,
      currentContent: 'More of what you want in one ultra-slim 4K package.',
      suggestedContent:
        "More of what you want in one ultra-slim 4K package. It's Ultra High Definition backed by LG's Quad Core Processor 4K to enhance color, contrast, detail and sound. Not to mention the huge library of content right at your fingertips with over 300+ free LG Channels¹ with everything from comedy to movies to sports.",
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    buyBoxType: '3p'
  },
  {
    id: 6,
    retailer: RETAILER.HOME_DEPOT,
    scoreDetails: {
      title: 75,
      description: 55,
      image: 95,
      overall: 75
    },
    skuDetails: {
      id: 6,
      contentType: 'image',
      currentImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      gtinNumber: '6517511',
      state: 'positive',
      suggestedImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      contentScore: 95,
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 6,
      contentType: 'name',
      state: 'improved',
      status: 'pending',
      contentScore: 75,
      currentContent: 'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV',
      suggestedContent:
        'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV with Dolby sound',
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    netQuantity: '1',
    descriptionDetails: {
      id: 6,
      contentType: 'description',
      state: 'negative',
      status: 'pending',
      contentScore: 55,
      currentContent: 'More of what you want in one ultra-slim 4K package.',
      suggestedContent:
        "More of what you want in one ultra-slim 4K package. It's Ultra High Definition backed by LG's Quad Core Processor 4K to enhance color, contrast, detail and sound. Not to mention the huge library of content right at your fingertips with over 300+ free LG Channels¹ with everything from comedy to movies to sports.",
      contentScoreDescription: 'Content not matching retailer guideline',
      source: 'CIQ Intelligence'
    },
    buyBoxType: '3p'
  },
  {
    id: 7,
    retailer: RETAILER.LOWES,
    scoreDetails: {
      title: 75,
      description: 55,
      image: 95,
      overall: 75
    },
    skuDetails: {
      id: 7,
      contentType: 'image',
      currentImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      gtinNumber: '6517511',
      state: 'positive',
      suggestedImageUrl:
        'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6517/6517511_sd.jpg',
      contentScore: 95,
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    productNameDetails: {
      id: 7,
      contentType: 'name',
      state: 'improved',
      status: 'pending',
      contentScore: 75,
      currentContent: 'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV',
      suggestedContent:
        'LG - 75” Class UP7300PUC LED 4K UHD Smart webOS TV with Dolby sound',
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    netQuantity: '1',
    descriptionDetails: {
      id: 7,
      contentType: 'description',
      state: 'negative',
      status: 'pending',
      contentScore: 55,
      currentContent: 'More of what you want in one ultra-slim 4K package.',
      suggestedContent:
        "More of what you want in one ultra-slim 4K package. It's Ultra High Definition backed by LG's Quad Core Processor 4K to enhance color, contrast, detail and sound. Not to mention the huge library of content right at your fingertips with over 300+ free LG Channels¹ with everything from comedy to movies to sports.",
      contentScoreDescription: 'Content not matching PIM',
      source: 'PIM'
    },
    buyBoxType: '3p'
  }
];

export const IMAP_SKU_DATA = [
  {
    id: 1,
    retailer: RETAILER.AMAZON,
    skuDetails: {
      title:
        'LG C2 Series 65-Inch Class OLED evo Gallery Edition Smart TV OLED65C2PUA, 2022 - AI-Powered 4K TV, Alexa Built-in',
      imageUrl:
        'https://m.media-amazon.com/images/I/91vAUToHkcL._AC_SY355_.jpg',
      asin: 'B09RMRNSBF',
      retailerPrices: [
        { retailer: RETAILER.AMAZON, price: 5 },
        { retailer: RETAILER.BEST_BUY, price: 5.5 },
        { retailer: RETAILER.TARGET, price: 5.2 }
      ],
      priceTrend: [
        [
          'price_trend_date',
          '12/22/2022',
          '12/23/2022',
          '12/24/2022',
          '12/25/2022',
          '12/26/2022',
          '12/27/2022',
          '12/28/2022',
          '12/29/2022',
          '12/30/2022',
          '12/31/2022',
          '01/01/2023',
          '01/02/2023',
          '01/03/2023',
          '01/04/2023'
        ],
        [
          'IMAP',
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600,
          1600
        ],
        [
          RETAILER.AMAZON,
          1796,
          1796,
          1796,
          1796,
          1699,
          1699,
          1699,
          1699,
          1699,
          1699,
          1550,
          1550,
          1550,
          1550
        ],
        [
          RETAILER.WALMART,
          1796,
          1796,
          1796,
          1796,
          1750,
          1710,
          1710,
          1710,
          1710,
          1710,
          1710,
          1575,
          1575,
          1575
        ]
      ]
    },
    mapPrice: '$1600',
    currentSellingPrice: '$1550',
    firstMover: 'Amazon',
    followedBy: 'Walmart',
    dateOfViolation: '01/01/23',
    daysOfViolation: '3',
    status: 'open'
  },
  {
    id: 2,
    retailer: RETAILER.AMAZON,
    skuDetails: {
      title:
        'LG C1 Series 65-Inch Class OLED Smart TV OLED65C1PUB, 2021 - 4K TV, Alexa Built-in',
      imageUrl:
        'https://m.media-amazon.com/images/I/916NVBBT0mS._AC_SX679_.jpg',
      asin: 'B08WG4HKKY',
      retailerPrices: [
        { retailer: RETAILER.AMAZON, price: 5 },
        { retailer: RETAILER.WALMART, price: 5.5 },
        { retailer: RETAILER.TARGET, price: 5.2 }
      ],
      priceTrend: [
        [
          'price_trend_date',
          '12/22/2022',
          '12/23/2022',
          '12/24/2022',
          '12/25/2022',
          '12/26/2022',
          '12/27/2022',
          '12/28/2022',
          '12/29/2022',
          '12/30/2022',
          '12/31/2022',
          '01/01/2023',
          '01/02/2023',
          '01/03/2023',
          '01/04/2023'
        ],
        [
          'IMAP',
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500,
          1500
        ],
        [
          RETAILER.AMAZON,
          1596,
          1596,
          1596,
          1596,
          1599,
          1599,
          1599,
          1599,
          1599,
          1599,
          1490,
          1490,
          1490,
          1490
        ],
        [
          RETAILER.WALMART,
          1596,
          1596,
          1596,
          1596,
          1599,
          1599,
          1599,
          1599,
          1599,
          1599,
          1599,
          1460,
          1460,
          1460
        ]
      ]
    },
    mapPrice: '$1500',
    currentSellingPrice: '$1490',
    firstMover: 'Amazon',
    followedBy: 'Walmart',
    dateOfViolation: '01/01/23',
    daysOfViolation: '3',
    status: 'open'
  },
  {
    id: 3,
    retailer: RETAILER.WALMART,
    skuDetails: {
      title: 'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X',
      imageUrl:
        'https://m.media-amazon.com/images/I/71G6Vh3fhPL._AC_SY450_.jpg',
      asin: 'B09WD4TWYP',
      retailerPrices: [
        { retailer: RETAILER.AMAZON, price: 5 },
        { retailer: RETAILER.WALMART, price: 5.5 },
        { retailer: RETAILER.TARGET, price: 5.2 }
      ],
      priceTrend: [
        [
          'price_trend_date',
          '12/22/2022',
          '12/23/2022',
          '12/24/2022',
          '12/25/2022',
          '12/26/2022',
          '12/27/2022',
          '12/28/2022',
          '12/29/2022',
          '12/30/2022',
          '12/31/2022',
          '01/01/2023',
          '01/02/2023',
          '01/03/2023',
          '01/04/2023'
        ],
        [
          'IMAP',
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400,
          400
        ],
        [
          RETAILER.AMAZON,
          448,
          448,
          448,
          448,
          440,
          440,
          440,
          440,
          440,
          440,
          390,
          390,
          390,
          390
        ],
        [
          RETAILER.WALMART,
          448,
          448,
          448,
          448,
          445,
          445,
          445,
          445,
          445,
          445,
          445,
          395,
          395,
          395
        ]
      ]
    },
    mapPrice: '$400',
    currentSellingPrice: '$390',
    firstMover: 'Amazon',
    followedBy: 'Walmart',
    dateOfViolation: '12/12/22',
    daysOfViolation: '6',
    status: 'ticketRaised'
  }
];

export const priceTrendSkus = [
  {
    asin: 'B00TQJWF1I',
    title: 'T-fal Ultimate Hard Anodized Nonstick 17 Piece Cookware Set, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/81ljA5GL6gL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B001167VIQ',
    title:
      'T-fal Signature Nonstick Dishwasher Safe Cookware Set, 12-Piece, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/81x3WzWy-LL.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B00NQ7QFGM',
    title:
      'T-fal Deep Fryer with Basket, Stainless Steel, Easy to Clean Deep Fryer, Oil Filtration, 2.6-Pound, Silver, Model FR8000',
    imageUrl:
      'https://m.media-amazon.com/images/I/713j2j371bL.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B00CLD39C0',
    title:
      'Rowenta DW9280 Digital Display Steam Iron, Stainless Steel Soleplate, 1800-Watt, 400-Hole, Blue',
    imageUrl:
      'https://m.media-amazon.com/images/W/WEBP_402378-T2/images/I/81OSLAr-vOL.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B00FUF5K8W',
    title:
      'All-Clad D3 Stainless Cookware, 12-Inch Fry Pan with Lid, Tri-Ply Stainless Steel, Professional Grade, Silver, Model: 41126',
    imageUrl:
      'https://m.media-amazon.com/images/I/61oJUqHHOAL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B084JBZPLG',
    title:
      'Rowenta DG8624U1 Perfect Pro Station 1500 Watts Fast Heat Up, and 430g / min steam Burst, Advanced Technology, Blue',
    imageUrl:
      'https://m.media-amazon.com/images/I/61Cmcggt-oL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B005H8KD3E',
    title:
      'All-Clad D3 Stainless Cookware Set, Pots and Pans, Tri-Ply Stainless Steel, Professional Grade, 10-Piece',
    imageUrl:
      'https://m.media-amazon.com/images/I/71sRq4GDzEL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B0170TFNXM',
    title:
      'All-Clad E785S264/E785S263 HA1 Hard Anodized Nonstick Dishwasher Safe PFOA Free 8 and 10-Inch Fry Pan Cookware Set, 2-Piece, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/51olxVfceyL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B006ZNCMBQ',
    title:
      'All-Clad Brushed D5 Stainless Cookware Set, Pots and Pans, 5-Ply Stainless Steel, Professional Grade, 10-Piece - 8400001085',
    imageUrl:
      'https://m.media-amazon.com/images/I/61SoAGgjJZL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B01JT76L2K',
    title:
      'All-Clad E785SB64 HA1 Hard Anodized Nonstick Cookware Set, Pots and Pans Set, 13 Piece, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/71Gkv++q1LL._AC_SY300_SX300_.jpg'
  },
  {
    asin: 'B01FYIAQA6',
    title:
      'KRUPS Belgian Waffle Maker, Waffle Maker with Removable Plates, 4 Slices, Silver/Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/71m7eQGF5GL.__AC_SY300_SX300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B07Q622YLB',
    title:
      'KRUPS Precision Grinder Flat Burr Coffee for Drip/Espresso/PourOver/ColdBrew, 12 Cup, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/81msMmP2T1L.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B00TQJWERI',
    title:
      'T-fal E765SEFA Ultimate Hard Anodized Nonstick 14 Piece Cookware Set, Dishwasher Safe Pots and Pans Set, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/81j4uAeLwZL.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B004T6PS80',
    title:
      'All-Clad - 8701004398 All-Clad 4203 Stainless Steel Tri-Ply Bonded Dishwasher Safe Sauce Pan with Lid / Cookware, 3-Quart, Silver -',
    imageUrl:
      'https://m.media-amazon.com/images/I/61HNl-8JRBL.__AC_SX300_SY300_QL70_FMwebp_.jpg'
  },
  {
    asin: 'B08DZJX8L1',
    title: 'Rowenta Focus Steam Garment Iron, Standard, Blue',
    imageUrl:
      'https://m.media-amazon.com/images/I/71vB7k3qsgL.__AC_SX300_SY300_QL70_ML2_.jpg'
  },
  {
    asin: 'B083JXZJMJ',
    title:
      'Rowenta DW2459 Access Steam Iron with Retractable Cord and Stainless Steel Soleplate, Black',
    imageUrl:
      'https://m.media-amazon.com/images/I/61wUF1lwnUL.__AC_SY300_SX300_QL70_ML2_.jpg'
  }
];

export const RETAILER_LIST = [
  { label: 'Amazon', value: 'amazon' },
  { label: 'Walmart', value: 'walmart' },
  { label: 'Target', value: 'target' }
];
