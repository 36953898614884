<template>
  <div class="widget--full-screen u-display-flex u-flex-direction-column">
    <div class="widget__header u-flex-none">
      <div
        class="u-display-flex u-flex-align-items-flex-start u-spacing-ph-m u-spacing-pv-l"
      >
        <div
          class="u-flex-none u-spacing-pt-xs u-cursor-pointer"
          @click="closeModal"
        >
          <rb-icon :icon="'cross'" />
        </div>
        <div class="widget__header--title u-flex-1 u-spacing-pl-m">
          <div class="u-font-size-1 u-font-weight-bold">
            {{ $t('manage_columns') }}
          </div>
          <div class="u-font-size-4 u-spacing-pt-s u-color-grey-lighter">
            {{ $t('drag_and_drop_desc') }}
          </div>
        </div>
        <div class="u-flex-1 u-display-flex u-flex-justify-content-flex-end">
          <rb-button
            :click-fn="applyChanges"
            :type="'filled'"
            :text="$t('save_for_all_users')"
          />
        </div>
      </div>
    </div>
    <div class="widget__body u-display-flex u-flex-1 u-overflow-auto">
      <split-container>
        <div slot="master">
          <div class="panel--left rb-tab rb-tab--vertical-nav u-flex-none">
            <div
              v-for="(item, index) in columnCustomizations"
              class="rb-tab__item"
              :class="{ active: item === selectedRecommendationType }"
              @click="selectedRecommendationType = item"
            >
              <div class="u-display-flex u-spacing-pr-s">
                <div
                  :title="item.recommendationLabel"
                  class="rb-tab-item__label u-text-overflow-ellipsis"
                >
                  {{ item.recommendationLabel }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          slot="detail"
          class="u-width-100 u-height-100 u-position-relative"
        >
          <div
            class="panel--right u-width-100 u-display-flex u-flex-direction-column"
          >
            <div class="u-spacing-pt-xl u-spacing-pl-xl u-spacing-pb-s">
              <div
                v-for="item in nonNegotiable"
                class="list__item u-spacing-ph-l u-spacing-pv-s u-display-flex"
              >
                <rb-icon
                  :icon="'lock'"
                  class="rb-icon--medium u-color-grey-lighter u-spacing-mr-m"
                />
                <rb-checkbox
                  v-model="tru"
                  disabled
                >
                  {{ item }}
                </rb-checkbox>
              </div>
            </div>
            <div
              class="u-spacing-pv-s u-spacing-pl-xl u-border-top u-border-width-s u-border-color-grey-xxx-light"
            >
              <draggable
                v-model="columns"
                @start="drag = true"
                @end="drag = false"
              >
                <div
                  v-for="item in columns"
                  :key="item.tableField"
                  class="list__item u-spacing-ph-l u-spacing-pv-s u-display-flex"
                >
                  <rb-icon
                    :icon="'draggable-reorder'"
                    class="rb-icon--medium u-color-grey-lighter u-spacing-mr-m u-cursor-move"
                  />
                  <rb-checkbox
                    v-model="item.visible"
                    :title="item.uiLabel"
                  >
                    {{ item.uiLabel }}
                  </rb-checkbox>
                </div>
              </draggable>
              <!-- <rb-button :click-fn="selectAll" :type="'link'" :text="$t('select_all')" class="u-color-grey-base"></rb-button> -->
            </div>
          </div>
        </div>
      </split-container>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import splitContainer from '@/components/layouts/splitContainer';
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    draggable,
    splitContainer
  },
  props: {
    currentName: {
      type: String
    },
    columnCustomizations: {
      type: Array
    },
    apply: {
      type: Function,
      default: null
    },
    closeFn: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      tru: true,
      tooltipActive: false,
      selectedRecommendationType: null,
      // TODO: Change the automation column to nonNegotiable type
      columnsToFilter: [
        'asin',
        'imageUrl',
        'title',
        'vendor_action_required',
        'normalized_outcome',
        'ticket_id'
      ],
      reorderingStartIndex: null
    };
  },
  computed: {
    fixedColumnType() {
      if (
        !(
          this.selectedRecommendationType &&
          this.selectedRecommendationType.recommendationType
        )
      ) {
        return '';
      } else if (
        this.selectedRecommendationType.recommendationType
          .toLowerCase()
          .startsWith('search share') ||
        this.selectedRecommendationType.recommendationType.toLowerCase() ===
          'search' ||
        this.selectedRecommendationType.recommendationType.toLowerCase() ===
          'search_na'
      ) {
        return 'keyword';
      } else {
        return 'sku details';
      }
    },
    nonNegotiable() {
      if (this.fixedColumnType === 'keyword') {
        return ['Actions', 'Keyword'];
      } else {
        return ['Actions', 'SKU Details'];
      }
    },
    columns: {
      get() {
        return this.selectedRecommendationType.recommendationUiLabelMappings.filter(
          (item) => {
            return this.columnsToFilter.indexOf(item.uiKey) === -1;
          }
        );
      },
      set(value) {
        this.selectedRecommendationType.userSorted = true;
        this.selectedRecommendationType.recommendationUiLabelMappings = value;
      }
    }
  },
  created() {
    for (var item of this.columnCustomizations) {
      if (item.recommendationName === this.currentName) {
        this.selectedRecommendationType = item;
        this.reorderingStartIndex =
          this.selectedRecommendationType.recommendationUiLabelMappings[0].keyOrder;
        break;
      }
    }
  },
  methods: {
    selectAll() {
      for (var item of this.columns) {
        item.visible = true;
      }
    },
    applyChanges() {
      this.selectedRecommendationType.userSorted = true;
      this.selectedRecommendationType.reorderingStartIndex =
        this.reorderingStartIndex;
      this.apply(this.columnCustomizations, this.selectedRecommendationType);
      this.closeFn();
    },
    closeModal() {
      this.closeFn();
    }
  }
};
</script>

<style lang="css" scoped>
.widget__header {
  z-index: 10;
  box-shadow: 2px 1px 4px 0 #e9eaeb;
}

.list__item.active {
  background: #e5f1fe;
}
.list__item .list__item--text {
  line-height: 20px;
}
</style>
