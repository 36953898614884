const Entities = {
  campaign: 'campaign',
  catalog: 'catalog'
};

const EntitiesForPostProcessingStatus = {
  catalog: 'catalogTaxonomy',
  campaign: 'campaignTaxonomy',
  campaignList: 'campaign'
};

export { Entities, EntitiesForPostProcessingStatus };
