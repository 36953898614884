<template>
  <div class="u-display-flex u-width-100">
    <div
      v-for="(select, index) in options"
      :style="{ width: selectWidth + '%' }"
      class="u-spacing-mr-m"
    >
      <div
        class="custom--select u-display-flex u-flex-align-center u-width-100 u-height-100"
        :class="{ disabled: select.disabled }"
        @click="setSelect(index)"
      >
        <div
          :class="{
            active: index === selectedIndex,
            'hover-line': !select.disabled
          }"
        />
        <div class="u-spacing-p-m">
          <slot name="selectOption">
            <div class="u-display-flex u-flex-direction-column">
              <span class="u-font-size-5 u-font-weight-600">{{
                select.display
              }}</span>
              <span
                v-if="select.desc"
                class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
                >{{ select.desc }}</span
              >
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/require-default-prop */
export default {
  components: {},
  model: {
    prop: 'entityType',
    event: 'change'
  },
  props: {
    selects: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selectWidth: {
      type: Number,
      default: 30
    },
    defaultSelectedKey: {
      type: String
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selected: {},
      selectedIndex: -1
    };
  },
  computed: {
    options() {
      return this.selects;
    }
  },
  created() {
    this.setDefault();
  },
  methods: {
    getDefaultSelectedIndex() {
      let index = -1;
      if (this.defaultSelectedKey) {
        index = this.selects.findIndex((element) => {
          return element.key === this.defaultSelectedKey;
        });
      }
      this.selectedIndex = index > -1 ? index : 0;
      return this.selectedIndex;
    },
    setSelect(index, defaultSelect = false) {
      if (this.selects[index].disabled) {
        return;
      }
      for (let i = 0; this.selects.length > i; i++) {
        this.selects[i].active = false;
      }
      this.selects[index].active = true;
      this.selected = this.selects[index];
      this.selectedIndex = index;
      if (defaultSelect) {
        this.selected.defaultSet = true;
      } else {
        this.selected.defaultSet = false;
      }
      this.$emit('change', this.selected);
    },
    setDefault() {
      const index = this.getDefaultSelectedIndex();
      this.setSelect(index, true);
    }
  }
};
</script>

<style scoped lang="css">
.custom--select {
  width: 33%;
  border-radius: 4px;
  border: solid 1px #e9eaeb;
  background-color: #ffffff;
  border-left: solid 1px #e9eaeb;
  cursor: pointer;
}
.custom--select.disabled {
  opacity: 0.5;
  cursor: default;
}

.active {
  height: 100%;
  width: 4px;
  background-color: #007cf6 !important;
  box-shadow: 0 0 4px 0 #e9eaeb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.custom--select:hover .hover-line {
  height: 100%;
  width: 4px;
  background-color: #99cbfc;
  box-shadow: 0 0 4px 0 #e9eaeb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>
