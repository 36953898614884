<template>
  <div
    class="u-width-100 u-display-flex u-spacing-ph-l u-spacing-pv-m u-flex-align-items-start banner"
    :class="[
      {
        'banner-expanded': overflowing && !collapsed
      },
      customClass
    ]"
  >
    <rb-icon
      class="rb-icon--medium u-spacing-mr-8-px u-flex-align-self-center"
      :class="iconCustomClass"
      :icon="iconName"
    />
    <div
      v-if="text"
      class="u-font-size-5 u-color-grey-base u-flex-align-self-center"
    >
      {{ text }}
    </div>
    <div
      v-if="vHtmlText"
      class="u-flex-align-self-center"
      v-html="vHtmlText"
    />
    <slot name="customBannerTextSlot" />
    <div
      class="u-spacing-ml-l u-flex-align-self-center u-display-flex u-flex-align-items-center u-margin-left-auto"
    >
      <div v-if="showCta">
        <rb-button
          :text="ctaText"
          class="cta-button"
          type="hollow"
          :click-fn="action"
        />
      </div>
      <div class="u-spacing-ml-m u-display-flex">
        <span
          v-if="overflowing"
          class="u-spacing-mr-8-px"
          @click="toggleCollapse"
        >
          <rb-icon
            class="rb-icon--medium u-cursor-pointer"
            :icon="collapsed ? 'arrow1-down' : 'arrow1-up'"
          />
        </span>
        <span
          v-if="showCloseIcon"
          @click="closeIconClicked"
        >
          <rb-icon
            class="rb-icon--medium u-cursor-pointer"
            icon="cross"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useCollapsibleComponent } from '@/utils/composables/useCollapsibleComponent';

export default {
  name: 'Banner',
  props: {
    iconName: {
      type: String,
      default: 'info-circle-fill'
    },
    text: {
      type: String,
      default: ''
    },
    showCta: {
      type: Boolean,
      default: true
    },
    ctaText: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      default: () => {}
    },
    customClass: {
      type: String,
      default: ''
    },
    iconCustomClass: {
      type: String,
      default: ''
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    vHtmlText: {
      type: String,
      default: null
    }
  },
  setup() {
    const { overflowing, collapsed, toggleCollapse } =
      useCollapsibleComponent();
    return { overflowing, collapsed, toggleCollapse };
  },
  methods: {
    closeIconClicked() {
      this.$emit('closeIconClicked');
    }
  }
};
</script>

<style lang="css" scoped>
.banner {
  margin-top: -1.6rem;
  background-color: rgba(255, 168, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.banner-expanded {
  max-height: 1000px;
}
.banner .rb-button--hollow {
  background-color: rgba(255, 168, 0, 0.05);
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
.banner .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
.banner .u-flex-1 {
  text-align: end;
}
.banner .u-flex-0 {
  flex: 0;
}
.banner .u-margin-left-auto {
  margin-left: auto;
}
.banner .cta-button {
  min-width: 15.6rem;
}
.banner .u-spacing-mr-8-px {
  margin-right: 8px;
}
</style>
