<template>
  <div class="u-height-100 u-display-flex u-flex-direction-column">
    <section
      class="page_header u-spacing-ph-l u-spacing-pt-0 u-spacing-pb-m u-bg-color-grey-white sticky u-z-index-1 u-header-box-shadow u-spacing-mb-0"
    >
      <div class="u-display-flex">
        <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
          Internal Tools
        </h2>
      </div>
    </section>
    <div
      class="toolbox-card-container u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-width-100 u-height-100 u-overflow-auto u-bg-color-grey-white u-flex-wrap-yes u-spacing-p-xl"
    >
      <internalToolboxCard
        v-for="(item, index) in tabs"
        v-show="!item.hide"
        :key="index"
        :title="item.title"
        :subtext="item.subtext"
        :asset="item.asset"
        :redirect="item.redirect"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import internalToolboxCard from './internal-toolbox-card';
import utils from '@/utils/helpers/';
import Vue from 'vue';
export default {
  components: {
    internalToolboxCard
  },
  data() {
    const enableAccountManager =
      Vue.options.filters.config_check('feature.account_manager.enable') &&
      utils.internalUserCheck(window.user);

    return {
      tabs: [
        {
          title: 'Automations Manager',
          subtext:
            'Record new automations and view the current status of automations.',
          asset: 'bot',
          redirect: '/dvtInternalTool',
          hide: this.hideIndividualTab(
            'feature.dvtInternalTool_disabled_retailers'
          )
        },
        {
          title: 'Report Validator',
          subtext: 'Validate reports before sending it across to customers.',
          asset: 'email-rounded',
          redirect: '/mailValidator',
          hide: this.hideIndividualTab(
            'feature.mailValidator_disabled_retailers'
          )
        },
        {
          title: 'Product Diagnostics',
          subtext: 'See how the product is doing overall or dive deep.',
          asset: 'diagnostics',
          redirect: '/product_diagnostics',
          hide: this.hideIndividualTab(
            'feature.product_diagnostics_disabled_retailers'
          )
        },
        {
          title: 'Product SLA',
          subtext: 'See the SLA breach counts and dates for all clients',
          asset: 'diagnostics',
          redirect: '/product_sla',
          hide: this.hideIndividualTab('feature.sla_tracker_disabled_retailers')
        },
        {
          title: 'E2E Overview',
          subtext:
            'See the progress of data refresh for logged in client and also retry failed workflows.',
          asset: 'diagnostics',
          redirect: 'e2e-overview',
          hide: this.hideIndividualTab(
            'feature.e2e_overview_disabled_retailers'
          )
        },
        {
          title: 'Account Manager',
          subtext:
            'Manage access of data providers. Example: AVC, AMS and Walmart',
          asset: 'accounts-manager',
          redirect: '/account_manager',
          hide:
            !enableAccountManager ||
            this.hideIndividualTab('feature.account_manager_disabled_retailers')
        },
        {
          title: 'Proxy Links',
          subtext: "View where the proxy url's are actually pointing to.",
          asset: 'link',
          redirect: 'proxy_links',
          hide: this.hideIndividualTab('feature.proxy_links_disabled_retailers')
        },
        {
          title: 'Operations Excellence Score',
          subtext:
            'Operations Excellence Score is an indicator that helps us understand if the basic checks and basic performance of the account.',
          asset: 'diagnostics',
          redirect: 'internal_tools/operations_excellence_score',
          hide: !Vue.options.filters.config_check('feature.oes.enable')
        }
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(param) {
      this.$router.push(param);
    },
    hideIndividualTab(config) {
      const restrictedRetailersConfig =
        Vue.options.filters.config_check(config) || '';
      const restrictedRetailers = restrictedRetailersConfig.split(',') || [];
      return restrictedRetailers?.includes(this.$store.getters.getRetailer);
    }
  }
};
</script>

<style lang="css" scoped>
.viewport-container {
  height: 84vh;
}

.toolbox-card-container {
  align-content: center;
}
</style>
