








































































































import metric from '@/components/basic/metric.vue';
import loader from '@/components/basic/loader.vue';
import donutWithLegends from '@/components/pages/insights/amazon/share-of-voice/molecules/donut-with-legends.vue';
import absoluteChange from '@/components/pages/insights/amazon/share-of-voice/atoms/absolute-change.vue';
import FooterIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/footer-icons.vue';
import cardContainer from '@/components/pages/insights/amazon/share-of-voice/organisms/card-container.vue';
import digitalShelfDropdownRow from '@/components/pages/insights/amazon/share-of-voice/atoms/digital-shelf-dropdown-row.vue';
import Vue, { PropType } from 'vue';
import { DonutDataService } from '@/components/pages/insights/amazon/market-share/service/donut-chart-service';
import { setDonutLegendMetadata } from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
import { DonutChartData, InputParams } from '../types/pie-chart-widget';
import { MetadataChart } from '@/components/pages/insights/amazon/market-share/service/backend-types';

export default Vue.extend({
  name: 'PieChartWidget',
  components: {
    FooterIcon,
    loader,
    donutWithLegends,
    absoluteChange,
    cardContainer,
    digitalShelfDropdownRow,
    metric
  },
  props: {
    hideMetric: {
      type: Boolean,
      default: false
    },
    hideDownload: {
      type: Boolean,
      default: false
    },
    dataService: {
      type: Object as PropType<DonutDataService>,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: Function
    },
    // eslint-disable-next-line vue/require-default-prop
    downloadHandler: {
      type: Function
    },
    showStar: {
      type: Boolean,
      default: false
    },
    tippyTitle: {
      type: String,
      default: ''
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      inputParams: undefined as unknown as InputParams
    };
  },
  computed: {
    metricConfig(): unknown {
      if (this.inputParams.chartTop) {
        return {
          invertTag2: false,
          tag1Unit: {
            pre: this.inputParams.chartTop.prefixPvpValue,
            roundoff: this.dataService.data?.chartTop.precision
          },
          tag2Unit: {
            suff: '%',
            roundoff: this.dataService.data?.chartTop.precision
          }
        };
      }
    },
    metricData(): { tag1: number; tag2: number | undefined } {
      return {
        tag1: this.inputParams.chartTop.subText,
        tag2: this.inputParams.chartTop.pvp
      };
    },
    actionIcons(): {
      emit: string;
      icon: string;
      loading: boolean;
      error: boolean;
      tippyTitle: string;
    }[] {
      return this.hideDownload
        ? []
        : [
            {
              emit: 'download',
              icon: 'download',
              loading: false,
              error: false,
              tippyTitle: this.inputParams.chartTop.downloadTooltipText
            }
          ];
    },
    chartData(): DonutChartData[] {
      const chartData = this.dataService.donutChartData.map(
        (unitDigitalShelfArray, index) => {
          const returnItem = setDonutLegendMetadata(
            unitDigitalShelfArray,
            {},
            {
              title: unitDigitalShelfArray.brand
            }
          );
          returnItem.legendIcon = 'legend-box';
          return returnItem;
        }
      );
      return chartData;
    },
    metaDataChart(): MetadataChart {
      return this.dataService.donutPopupData;
    },
    metadataLoad(): boolean {
      return this?.dataService.metadataLoad;
    },
    load(): boolean {
      return this?.dataService.load;
    },
    error(): boolean {
      return this?.dataService.error;
    },
    noData(): boolean {
      return this?.dataService.noData;
    }
  },
  watch: {
    'dataService.data': function (value) {
      if (value === null) {
      } else {
        this.inputParams = value;
      }
    }
  },
  methods: {}
});
