import { cloneDeep } from 'lodash';
import {
  CAMPAIGN_OBJECTIVES,
  CAMPAIGN_BUDGET_TYPES,
  CAMPAIGN_BID_TYPES
} from '@/components/pages/instacart/campaign-creation/config';

export const initialStepsDataState = {
  campaignName: '',
  strategyUIText: {
    objective: CAMPAIGN_OBJECTIVES[0].label
  },
  steps: {
    objective: {
      type: CAMPAIGN_OBJECTIVES[0].type,
      value: ''
    },
    settings: {
      campaignDates: {
        startDate: null,
        endDate: null
      },
      budgetType: CAMPAIGN_BUDGET_TYPES[0].type,
      dailyBudget: 0,
      lifetimeBudget: 0,
      bidType: CAMPAIGN_BID_TYPES()[0].type,
      minRoas: 0,
      minRoasChecked: false
    },
    adGroups: []
  },
  headerError: '',
  tempAdGroup: {},
  campaignNameCheck: {
    alreadyExists: false,
    loading: false
  }
};

export const state = {
  stepsData: cloneDeep(initialStepsDataState),
  launchedCampaign: {}
};

export const getters = {
  stepsData: ({ stepsData }) => stepsData,
  launchedCampaign: ({ launchedCampaign }) => launchedCampaign
};

export const mutations = {
  updateStepsData(state, data) {
    state.stepsData = cloneDeep(data);
  },
  updateLaunchedCampaign(state, data) {
    state.launchedCampaign = cloneDeep({ ...state.launchedCampaign, ...data });
  }
};
