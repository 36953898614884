<template>
  <div v-if="skusTableService">
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="brandViewTable"
      v-show="!showSearchTermsTable"
    >
      <rb-insights-table
        :table-column="brandTableColDef"
        :table-row="table1Data"
        :grid-options="brandTableGridOptions"
      />
    </div>
    <div v-show="showSkusTable && !computedLoading && !computedError">
      <rb-insights-table
        :table-column="skusTableColDef"
        :table-row="skusTableData"
        sorting-change-event="assortmentSortEvent"
        :grid-options="skusTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import BrandView from '@/components/pages/insights/amazon/cva/services/brandStrength/BrandView.js';
import SkusService from '@/components/pages/insights/amazon/cva/services/assortment/SkusService.js';
import benchmarkBrands from '@/components/pages/insights/amazon/share-of-voice/templates/benchmarkBrands';
import skuWithRating from '@/components/basic/skuWithRating.vue';
import loader from '@/components/basic/loader';
import { isCloseDC } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'BrandStrength',
  components: {
    benchmarkBrands,
    skuWithRating,
    loader
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewTable: null,
      skusTableService: null,
      selectedView: null,
      skusTableGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this // Helps in calling eventMap passed in the Column Definition
        }
      },
      brandTableGridOptions: {
        suppressColumnVirtualisation: true
      }
    };
  },
  computed: {
    showSkusTable() {
      return this.selectedWidget === 'SKU Details';
    },
    computedLoading() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.loading ||
          this.skusTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.error || this.skusTableService.error
        );
      }
    },
    skusTableColDef() {
      const columns = this.skusTableService?.getColumnDefs() || [];
      return [...columns];
    },
    skusTableData() {
      this.skusTableGridOptions?.api?.sizeColumnsToFit();
      return this.skusTableService?.getTableData();
    },
    brandTableColDef() {
      const columns = this.brandViewTable?.getColumnDefs() || [];
      return [...columns];
    },
    table1Data() {
      return [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.skusTableService = new SkusService('cva', 'table2', 459, 100);
    this.skusTableService.fetchData(this.pageSettings, this.selectedSkus);
    this.fetchLiner();
  },
  created() {
    Vue.component('skuWithRating', skuWithRating);
  },
  methods: {
    async fetchLiner() {
      const x = await this.skusTableService.fetchInsights(
        ['Double click Assortment'],
        this.pageSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    }
  }
};
</script>
