import {
  adgroupConfig,
  productTargeting,
  keywordTargeting,
  classification,
  budget,
  timePeriod,
  biddingStrategySP
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/index.js';
import { cloneDeep } from 'lodash';

const formStepConfig = () => {
  const adgroup = cloneDeep(adgroupConfig);
  adgroup.showLeftButton = false;

  const stepsConfig = [
    adgroup,
    productTargeting,
    keywordTargeting,
    biddingStrategySP,
    classification,
    budget,
    timePeriod
  ];

  return stepsConfig;
};

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  stepsConfig: formStepConfig(),
  enableStepsAfterValidation: true
};
