import { render, staticRenderFns } from "./dataTable.vue?vue&type=template&id=57d870ab&"
import script from "./dataTable.vue?vue&type=script&lang=js&"
export * from "./dataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports