<template>
  <div class="u-position-relative u-display-flex">
    <rb-input
      v-model="searchText"
      :class-list="['u-width-216px']"
      :placeholder="placeHolder"
      class="search__input u-width-100 u-border-left-none"
      :rounded="false"
      @input="onSearchInput"
    />
    <span
      class="icon-right u-position-absolute u-display-flex"
      @click="iconClick"
    >
      <rb-icon
        :icon="icon"
        class="search__input-icon rb-icon--medium"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'search'
    },
    initialText: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchText: ''
    };
  },
  methods: {
    resetText() {
      this.searchText = this.initialText;
    },
    onSearchInput(data) {
      this.$emit('input', data);
    },
    iconClick() {
      this.$emit('iconClick', this.searchText);
    }
  }
};
</script>

<style lang="css">
.rb-input.u-width-216px {
  width: 216px;
}
.rb-input.u-icon-right {
  padding-left: 8px !important;
  padding-right: 32px !important;
}
.icon-right {
  top: 0px;
  left: 8px;
  bottom: 0;
  margin: auto;
}
</style>
