<template>
  <div>
    <span
      v-for="(item, i) in textStringArray"
      :key="i"
    >
      {{ item }}
      <component
        :is="getClassAndComponent(i).component"
        v-if="valuesArray[i]"
        v-bind="getClassAndComponent(i).props"
      >
        {{ valuesArray[i].text }}
      </component>
    </span>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';
export default {
  name: 'SpotlightTextRenderer',
  components: { metric },
  props: {
    textString: {
      type: String,
      default: ''
    },
    valuesArray: {
      type: Array,
      default: () => []
    },
    defaultComponent: {
      type: String,
      default: 'span'
    }
  },
  data() {
    return {
      typeClassMapping: {
        bold: {
          component: 'span',
          props: {
            class: 'u-font-weight-600'
          }
        },
        'underlined-bold': {
          component: 'span',
          props: {
            class:
              'u-font-weight-600 u-border-width-s u-border-bottom u-border-color-grey-xx-light'
          }
        },
        'asin-link': {
          component: 'a',
          props: {
            target: '_blank',
            class:
              'u-font-weight-600 u-cursor-pointer u-border-width-s u-border-bottom u-border-color-grey-xx-light'
          },
          propsConstructor: [
            {
              key: 'href',
              value: (val) =>
                window.location.origin + '/us/amazon/retail/dp/' + val
            }
          ]
        },
        'highlighted-bold': {
          component: 'span',
          props: {
            class: 'u-color-red-base u-font-weight-600'
          }
        },
        link: {
          component: 'a',
          props: {
            target: '_blank',
            class:
              'u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-block'
          },
          propsConstructor: [
            {
              key: 'href',
              value: (val) => {
                return (
                  'https://vendorcentral.amazon.com/po/vendor/members/po-mgmt/order?poId=' +
                  val.split('Edit PO - ')[1]
                );
              }
            }
          ]
        },
        metric: {
          component: 'metric',
          props: {
            class: 'spotlight-metric',
            config: { tag1Unit: { pre: 'currency' } },
            size: '13-12'
          },
          propsConstructor: [
            {
              key: 'data',
              value: (val) => ({ tag1: val })
            }
          ]
        }
      }
    };
  },
  computed: {
    textStringArray() {
      return this.textString.split('{}');
    },
    getClassAndComponent() {
      return (i) => {
        const valueType = this.valuesArray[i].type;
        if (this.typeClassMapping[valueType]) {
          let typeMapping = this.typeClassMapping[valueType];

          // To dynamically add values to the props
          typeMapping.propsConstructor?.forEach((prop) => {
            typeMapping.props[prop.key] = prop.value(this.valuesArray[i].text);
          });

          return typeMapping;
        } else {
          return {
            component: this.defaultComponent
          };
        }
      };
    }
  }
};
</script>

<style lang="css">
.spotlight-metric {
  display: inline;
}
.spotlight-metric .rb-metric {
  display: inline;
}
.spotlight-metric .rb-metric .primary_metric {
  color: #d7263d;
}
</style>
