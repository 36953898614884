var hash = require('object-hash');
var localSavedFilters = {};
var savedFilters = {};
var savedFilterNames = {};
var savedFilterHash = {};

var object = {
  init: (key, data) => {
    savedFilters[key] = [];
    savedFilterNames[key] = [];
    savedFilterHash[key] = [];
    localSavedFilters[key] = [];

    data.filter((item) => {
      var returnData =
        item.dimensionType === 'CLIENT_SAVED_FILTERS' ||
        item.dimensionType === 'MY_SAVED_FILTERS';
      var isStale = false;
      var metadata = (item.addtionalMetadata || {}).filterInfo || {};
      if (metadata.stale !== undefined) {
        isStale = metadata.stale;
      }

      if (returnData && !isStale) {
        object.addHash(item, key);
      }
      return returnData;
    });
  },
  addHash: (item, key) => {
    savedFilters[key].push(item);
    localSavedFilters[key].push(item);
    savedFilterNames[key].push(item.dimensionName);
    item = JSON.parse(JSON.stringify(item));
    var metadata =
      ((item.addtionalMetadata || {}).filterInfo || {}).fields || {};
    savedFilterHash[key].push(object.calculateHash(metadata));
  },
  removeHash: (item, key, id) => {
    savedFilters[key] = savedFilters[key].filter((item) => {
      return item.addtionalMetadata.filterInfo.id !== id;
    });

    savedFilterNames[key] = savedFilterNames[key].filter((_item) => {
      return _item !== item.dimensionName;
    });

    var metadata =
      ((item.addtionalMetadata || {}).filterInfo || {}).fields || {};
    var hash = object.calculateHash(metadata);

    savedFilterHash[key] = savedFilterHash[key].filter((_item) => {
      return _item !== hash;
    });
  },
  getHash: (key) => {
    return {
      names: savedFilterNames[key] || [],
      hash: savedFilterHash[key] || [],
      filters: localSavedFilters[key]
    };
  },
  calculateHash: (metadata) => {
    metadata.order = (metadata.order || []).filter((item) => {
      return (metadata.filter || {})[item] !== undefined;
    });

    if (metadata.filter && metadata.filter.date_range) {
      delete metadata.filter.date_range;
      // metadata.filter.date_range = {
      //   name: metadata.filter.date_range.name
      // }
    }
    return hash(metadata, {
      respectType: false,
      unorderedArrays: true
    });
  },
  addLocalFilter: (key, data) => {
    if (!localSavedFilters[key]) {
      localSavedFilters[key] = [];
    }
    object.addHash(data, key);
  },
  getLocalFilters: (key) => {
    return localSavedFilters[key];
  },
  mergeLocalFilters: (primary, local) => {
    if (!local || (local && local.length === 0)) {
      return primary;
    }

    primary = primary.filter((item) => {
      return (
        item.dimensionType !== 'CLIENT_SAVED_FILTERS' &&
        item.dimensionType !== 'MY_SAVED_FILTERS'
      );
    });
    return primary.concat(local);
  },
  removeLocalFilter: (key, data) => {
    if (!localSavedFilters[key]) {
      localSavedFilters[key] = [];
    }

    var _object = localSavedFilters[key].filter((item) => {
      return item.addtionalMetadata.filterInfo.id === data;
    });

    object.removeHash(_object[0], key, data);

    localSavedFilters[key] = localSavedFilters[key].filter((item) => {
      return item.addtionalMetadata.filterInfo.id !== data;
    });
  }
};

export default object;
