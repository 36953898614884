<template>
  <div
    :style="containerStyles"
    :class="containerClass"
    class="dynamic-component-container"
  >
    <component
      :is="item.ui_component"
      v-for="(item, key) in childComponents"
      :key="key"
      :style="{
        'grid-column': item.grid_column,
        'grid-row': item.grid_row
      }"
      :class="item.class"
      v-bind="item.props"
      v-on="eventMaper(item.events)"
    />
  </div>
</template>
<script>
import { isEmpty } from 'lodash';
export default {
  name: 'DynamicComponentComposer',
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    containerStyles: {
      type: Object,
      default: null
    },
    childComponents: {
      type: Array,
      default() {
        return [
          {
            name: 'dataCell',
            props: {
              value: 'dummyText'
            },
            events: {
              onClick: 'onClick'
            }
          }
        ];
      }
    },
    parentContext: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    eventMaper(userEventMap) {
      if (isEmpty(userEventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = this.parentContext;
      Object.keys(userEventMap).forEach((listenerKey) => {
        const eventValue = userEventMap[listenerKey];
        if (typeof eventValue === 'function') {
          vueEventMap[listenerKey] = eventValue;
        } else if (typeof eventValue === 'string') {
          vueEventMap[listenerKey] = parentReference[eventValue];
        } else {
          console.error('Object not supported for eventMap');
        }
      });
      return vueEventMap;
    }
  }
};
</script>
