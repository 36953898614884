<template>
  <div class="footer-custom-class">
    <div class="u-spacing-mb-m">
      <!-- eslint-disable -->
      <div
        v-if="Boolean(errorMessage)"
        class="u-font-size-6 u-font-weight-bold u-color-red-base pre-wrap u-display-flex u-flex-align-items-center"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small u-spacing-mr-xxs"
          :icon="'error-fill'"
        />
        {{ errorMessage }}
      </div>
      <slot name="slot-below-error-message" />
    </div>

    <div class="u-display-flex u-spacing-mt-xs">
      <rb-button
        v-if="showLeftBtn"
        style="min-width: 93px"
        :text="btnLeftText"
        type="hollow"
        :class="customClassLeft"
        :click-fn="btnLeftClick"
        :disabled="disableLeftBtn"
        :loading="leftButtonLoading"
        class="u-spacing-mr-m"
      />
      <slot name="center" />
      <rb-button
        v-if="showRightBtn"
        style="min-width: 93px"
        :text="btnRightText"
        :class="customClassRight"
        :disabled="disableRightBtn"
        :loading="rightButtonLoading"
        type="filled"
        :click-fn="btnRightClick"
      />
    </div>
    <div
      v-if="showSkipStep"
      class="u-spacing-mt-m u-font-size-5 u-cursor-pointer"
      style="color: #4a90e2"
      @click="skipStep"
    >
      {{ skipStepText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rightButtonLoading: {
      type: Boolean,
      default: false
    },
    leftButtonLoading: {
      type: Boolean,
      default: false
    },
    customClassLeft: {
      type: String,
      default: ''
    },
    customClassRight: {
      type: String,
      default: ''
    },
    showLeftBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    },
    btnRightText: {
      type: String,
      default: 'Next'
    },
    btnLeftText: {
      type: String,
      default: 'Back'
    },
    skipStepText: {
      type: String,
      default: 'Skip step'
    },
    disableLeftBtn: {
      type: Boolean,
      default: false
    },
    disableRightBtn: {
      type: Boolean,
      default: false
    },
    showSkipStep: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  watch: {
    disableRightBtn: {
      handler(newValue) {
        this.$emit('onRightBtnDisabled', newValue);
      },
      immediate: true
    }
  },
  methods: {
    btnLeftClick() {
      this.$emit('onClickBtnLeft');
    },
    btnRightClick() {
      this.$emit('onClickBtnRight');
    },
    skipStep() {
      this.$emit('onClickSkipStep');
    }
  }
};
</script>

<style lang="css" scoped>
.rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
.rb-button--hollow[disabled] {
  background: #aaadb1 !important;
  border: none !important;
  color: #fff !important;
}

.pre-wrap {
  white-space: pre-wrap;
}
.footer-custom-class {
  padding-bottom: 40px;
}
</style>
