import {
  getDataFromRowNode,
  payloadGenerator,
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common.js';
import HttpService from '@/utils/services/http-service';
import metricsDictionary, {
  retailerSpecificMetrics
} from '@/pages/campaign-management/retailers/dictionary.js';
import {
  commonTableActionIcons,
  baseChartConfig,
  commonChartActionIcons,
  additionalDateRanges,
  keywordsEditStatusActionPayloadKey,
  gridOptions
} from '@/pages/campaign-management/constants.js';
import { tippyForRetailer } from '@/pages/campaign-management/retailers/walmart/skus/index.js';
import { incrementalMetrics } from '@/pages/campaign-management/retailers/walmart/campaigns/config.js';
import { incrementalMetricsForTables } from '@/pages/campaign-management/retailers/walmart/campaigns/index.js';
import { systemTransparencyBannerConfig } from '@/pages/campaign-management/retailers/walmart/utils.js';
import {
  isRealTimeUpdateEnabled,
  fetchRealtimeTableData
} from '@/pages/campaign-management/store';

const SB_CAMPAIGN_TYPES = ['sba', 'sb'];
const updatedIncrementalMetricsForTables = {
  ...incrementalMetricsForTables,
  iroas: {
    ...incrementalMetricsForTables.iroas,
    keyOrder: 16
  },
  incremental_fraction: {
    ...incrementalMetricsForTables.incremental_fraction,
    keyOrder: 17
  },
  incremental_sales: {
    ...incrementalMetricsForTables.incremental_sales,
    keyOrder: 18
  },
  incremental_fraction_band: {
    ...incrementalMetricsForTables.incremental_fraction_band,
    keyOrder: 19
  }
};

const WIDGET = 'keyword';
const PRIMARY_KEY = 'keyword_id';

const incrementalMetricsForCharts = incrementalMetrics();

const doesRetailerHaveIncrementality = (retailer) => {
  return retailer === 'walmart' || retailer === 'samsclub_api';
};

const getRetailerSpecificMetrics = (retailer) => {
  return retailer === 'walmart'
    ? retailerSpecificMetrics[retailer]
    : [
        'attributed_direct_click_sales',
        'attributed_related_click_sales',
        'attributed_brand_click_sales',
        ...retailerSpecificMetrics[retailer]
      ];
};

const getChartRequest = (retailer) => {
  const objectToReturn = {
    cubeName: `${retailer}_campaigns_keyword_workbench`,
    measuresList: [],
    groupByDimensionsList: [],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    timeseriesEnabled: true,
    pvpenabled: true,
    where: {},
    timeseriesDimension: 'report_date',
    bundleCubeExecutionRequest: {
      adv_metrics: {
        cubeName: `${retailer}_campaigns_keyword_workbench`,
        measuresList: [
          'ad_spend',
          'roas_14d',
          'impressions',
          'clicks',
          'conversion_rate',
          'units_sold',
          'ctr',
          'acos_14d',
          'cpc',
          'total_attributed_sales',
          'iroas',
          'incremental_fraction',
          'incremental_sales',
          'incrementality_ad_spend',
          'incrementality_total_attributed_sales',
          ...getRetailerSpecificMetrics(retailer)
        ],
        groupByDimensionsList: [
          'keyword_text',
          'keyword_id',
          'ad_group_id',
          'advertiser_id',
          'campaign_id'
        ],
        where: {},
        timeseriesDimension: 'report_date',
        orderByList: [],
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        enableNewPVPFormulaForSOV: false,
        disableShard: false,
        filterWhereClause: {
          dimensionNameValueList: []
        },
        outerWhereClause: {
          dimensionNameValueList: []
        },
        tagEnabled: false,
        whereClauseCombination: [],
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        splyenabled: false,
        digitalShelfEnabled: false,
        sharePercentageV2: false
      },
      sov_metrics: {
        cubeName: `${retailer}_campaigns_keyword_workbench`,
        measuresList: [
          'total_organic_page_1_count',
          'organic_page_1_count',
          'organic_sov_page_1',
          'total_sp_page_1_count',
          'sp_page_1_count',
          'sp_sov_page_1',
          'total_sb_page_1_count',
          'sb_page_1_count',
          'sb_sov_page_1'
        ],
        groupByDimensionsList: ['keyword_text'],
        orderByList: [],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        commonFilterEnabled: false,
        where: {},
        timeseriesDimension: 'report_date',
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        enableNewPVPFormulaForSOV: false,
        disableShard: false,
        filterWhereClause: {
          dimensionNameValueList: []
        },
        outerWhereClause: {
          dimensionNameValueList: []
        },
        tagEnabled: false,
        whereClauseCombination: [],
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        splyenabled: false,
        digitalShelfEnabled: false,
        sharePercentageV2: false
      }
    }
  };
  return objectToReturn;
};

const getTableRequest = (retailer) => {
  const objectToReturn = {
    cubeName: `${retailer}_campaigns_keyword_workbench`,
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    timeseriesDimension: 'report_date',
    pvpenabled: false,
    yoyenabled: false,
    tagEnabled: false,
    commonFilterEnabled: false,
    measuresList: [],
    groupByDimensionsList: [],
    dimensionList: [],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      }
    ],
    limit: 30,
    page: 1,
    customAPIDecisionVars: {
      type: null
    },
    entityType: 'keyword_text',
    primaryDataGroup: 'adv_metrics',
    where: {
      date: {
        from: ':date',
        to: ':date'
      },
      pvpDate: {
        from: ':date',
        to: ':date'
      }
    },
    bundleCubeExecutionRequest: {
      adv_metrics: {
        cubeName: `${retailer}_campaigns_keyword_workbench`,
        entityType: 'keyword_text',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        tagEnabled: false,
        commonFilterEnabled: false,
        measuresList: [
          'bid',
          'units_sold',
          'roas_14d',
          'acos_14d',
          'cpc',
          'ad_spend',
          'impressions',
          'clicks',
          'ctr',
          'conversion_rate',
          'total_attributed_sales',
          'advertiser_name',
          'campaign_name',
          'campaign_type',
          'targeting_type',
          'match_type',
          'status',
          'keyword_type',
          'campaign_status',
          'adgroup_status',
          'adgroup_name',
          'iroas',
          'incremental_fraction',
          'incremental_sales',
          'incremental_fraction_band',
          ...getRetailerSpecificMetrics(retailer)
        ],
        groupByDimensionsList: [
          'keyword_text',
          'keyword_id',
          'ad_group_id',
          'advertiser_id',
          'campaign_id'
        ],
        dimensionList: [],
        orderByList: [],
        customAPIDecisionVars: {
          type: null
        },
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      },
      sov_metrics: {
        cubeName: `${retailer}_campaigns_keyword_workbench`,
        entityType: 'keyword_text',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        commonFilterEnabled: false,
        measuresList: [
          'total_organic_page_1_count',
          'organic_page_1_count',
          'organic_sov_page_1',
          'sp_page_1_count',
          'total_sp_page_1_count',
          'sp_sov_page_1',
          'total_sb_page_1_count',
          'sb_page_1_count',
          'sb_sov_page_1'
        ],
        groupByDimensionsList: ['keyword_text'],
        customAPIDecisionVars: {
          type: null
        },
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        dimensionList: [],
        orderByList: [],
        tagEnabled: false,
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      }
    }
  };
  if (retailer === 'walmart') {
    objectToReturn.bundleCubeExecutionRequest = {
      adv_metrics: {
        cubeName: 'walmart_campaigns_keyword_workbench',
        entityType: 'keyword_id',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        tagEnabled: false,
        commonFilterEnabled: false,
        measuresList: [
          'units_sold',
          'roas_14d',
          'acos_14d',
          'cpc',
          'ad_spend',
          'impressions',
          'clicks',
          'ctr',
          'conversion_rate',
          'total_attributed_sales',
          'advertiser_name',
          'campaign_name',
          'campaign_type',
          'targeting_type',
          'match_type',
          'keyword_type',
          'keyword_text',
          'campaign_status',
          'adgroup_status',
          'adgroup_name',
          'iroas',
          'incremental_fraction',
          'incremental_sales',
          'incremental_fraction_band',
          'attributed_orders',
          'advertised_sku_units',
          'other_sku_units',
          'advertised_sku_sales',
          'other_sku_sales',
          'conversion_rate_14d_orders_based',
          'ntb_orders',
          'percentageordersnewtobrand14d',
          'ntb_revenue',
          'percentagesalesnewtobrand14d',
          'ntb_units',
          'percentageunitsnewtobrand14d',
          'conversion_rate_ntb'
        ],
        groupByDimensionsList: [
          'keyword_id',
          'ad_group_id',
          'advertiser_id',
          'campaign_id'
        ],
        dimensionList: [],
        orderByList: [],
        customAPIDecisionVars: {
          type: null
        },
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      },
      sov_metrics: {
        cubeName: 'walmart_campaigns_keyword_workbench',
        entityType: 'keyword_id',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        pvpenabled: false,
        yoyenabled: false,
        commonFilterEnabled: false,
        measuresList: [
          'total_organic_page_1_count',
          'organic_page_1_count',
          'organic_sov_page_1',
          'sp_page_1_count',
          'total_sp_page_1_count',
          'sp_sov_page_1',
          'total_sb_page_1_count',
          'sb_page_1_count',
          'sb_sov_page_1'
        ],
        groupByDimensionsList: ['keyword_id'],
        customAPIDecisionVars: {
          type: null
        },
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        dimensionList: [],
        orderByList: [],
        tagEnabled: false,
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        }
      },
      adv_metadata: {
        cubeName: 'walmart_campaigns_keyword_workbench_metadata',
        measuresList: ['bid', 'status'],
        groupByDimensionsList: ['keyword_id'],
        orderByList: [],
        timeseriesRollupBy: ':timeseriesRollupBy',
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        disableShard: false,
        getPointInTimeMetrics: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          excludeDedupAxes: []
        },
        commonFilterEnabled: false,
        customAPIDecisionVars: {
          system: 'walmart_campaigns_keyword_management'
        },
        enableNewPVPFormulaForSOV: false,
        flowResidueEntity: false,
        entityType: 'keyword_id',
        sharePercentageV2: false,
        pvptimeSeriesEnabled: false,
        timeseriesEnabled: false,
        yoyenabled: false,
        pvpenabled: false,
        pointInTimeMetrics: false,
        pvptimeseriesEnabled: false,
        splyenabled: false,
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          }
        },
        tagWhereClause: []
      }
    };
    objectToReturn.measuresList = [];
    objectToReturn.groupByDimensionsList = [];
    objectToReturn.entityType = 'keyword_id';
  }
  return objectToReturn;
};

export default (context, tab, retailer = 'walmart') => {
  const namespace = 'campaignManagement/';
  const chartsConfig = {
    ...metricsDictionary(tab, 'chart'),
    metricsList: [
      ...metricsDictionary(tab, 'chart').metricsList,
      ...incrementalMetricsForCharts
    ]
  };
  const getActionUpdater = (currentAction, selections) => {
    const retailer = context.$store?.getters?.getRetailer;
    if (retailer === 'samsclub_api') {
      // Enable the action for all campaign types if the retailer is 'samsclub_api'
      currentAction.disabled = false;
    } else {
      const hasSB = selections.some((selection) =>
        SB_CAMPAIGN_TYPES.includes(
          selection?.data?.campaign_type?.toLowerCase?.()
        )
      );
      // Enable the action for campaign types other than 'sb' or 'sba'
      currentAction.disabled = hasSB;
    }

    return currentAction;
  };

  const handleEditStatusAction = async (
    dropDownSelection,
    retailer,
    selections
  ) => {
    const hasSB = selections.some((item) =>
      SB_CAMPAIGN_TYPES.includes(item.data?.campaign_type?.toLowerCase())
    );

    const dataArray = selections
      .map((item) => getDataFromRowNode(item))
      .filter((data) => {
        const campaignType = data?.campaign_type?.toLowerCase();
        const isSamsclubApi = retailer === 'samsclub_api';
        const allowAllCampaignTypes = isSamsclubApi;
        const allowOtherCampaignTypes =
          !isSamsclubApi && !SB_CAMPAIGN_TYPES.includes(campaignType);

        return allowAllCampaignTypes || allowOtherCampaignTypes;
      });
    const dynamicKeys = {
      primaryKey: 'keyword_id',
      previousEntityValue: 'status',
      actionPayloadKeys: keywordsEditStatusActionPayloadKey(),
      viewPayloadKeys: keywordsEditStatusActionPayloadKey()
    };

    const staticValues = {
      widget: WIDGET,
      actionType: `${retailer}UpdateKeywordState`,
      actionSource: { pageUrl: window.location.href }
    };

    const newValuesObject = {
      newEntityValue: dropDownSelection[0].value,
      actionPayload: { state: dropDownSelection[0].value },
      viewPayload: { state: dropDownSelection[0].value }
    };

    const payloadArray = dataArray.map((item) =>
      payloadGenerator(dynamicKeys, staticValues, newValuesObject, item)
    );

    if (hasSB) {
      context.openSnackbar(
        'Removed SB campaign type keyword(s) from status change request'
      );
    }

    context.openSnackbar(
      'Status change request has been submitted for the selected keyword(s)'
    );

    const response = await HttpService.post(
      'AMS_ACTIONS_ACTION_LOG',
      payloadArray
    );

    const tableData = {
      rows: {
        tableRow: context.config.widget2.table.props.gridOptions.rowData
      }
    };

    fetchRealtimeTableData(
      'blueBarAction',
      'walmart',
      tableData,
      'campaignManagement/',
      'keyword',
      context
    );

    context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();

    return response;
  };

  return {
    namespace,
    bookmark: {
      title: 'Keywords'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'campaigns_keyword.max_feed_date',
        min: 'campaigns_keyword.min_feed_date'
      },
      api: {
        cube: `${retailer}_campaigns_keyword_workbench`,
        endPoint: 'FETCH_FILTERS_V2',
        page: `${retailer}_keywords`
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: `keywords-filter`,
      page: `${retailer}_keywords`,
      listenerEvent:
        retailer === 'walmart'
          ? 'keyword-filters'
          : `${retailer}-keyword-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-keywords',
          placeholder: 'Search for a Keyword',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-keywords-filter'
      }
    },
    widget1: {
      widget: 'keyword',
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: getChartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          enableWatefall: true,
          chartConfig: baseChartConfig(),
          ...chartsConfig,
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      activityBar: {
        pageType: 'keyword'
      },
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          {
            icon: 'pencil',
            title: 'Edit Status',
            id: 'edit-status',
            dropDown: true,
            tippy: tippyForRetailer(retailer),
            dropDownOptions: [
              { title: 'Enable', value: 'enabled' },
              { title: 'Disable', value: 'paused' }
            ],
            actionUpdater: getActionUpdater,
            handler(selectionLength, dropDownSelection, selections) {
              return handleEditStatusAction(
                dropDownSelection,
                retailer,
                selections
              );
            }
          },
          {
            icon: 'dollar',
            title: 'Adjust Bid',
            id: 'adjust-bids',
            customState: {
              component: 'BidChangePanel',
              props: {
                title: 'Adjust Bid',
                actionPanelType: 'keywords',
                apiVersion: 'actionlog',
                handeApplyExternally: true,
                retailer: context.$store.getters.getRetailer,
                widget: WIDGET,
                primaryKey: PRIMARY_KEY
              },
              events: {
                afterAPIActionToCall(payload) {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.removeCustomCustomComponents();
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.closeActionsWorkflow();
                  if (
                    isRealTimeUpdateEnabled.includes(
                      context.$store.getters.getRetailer
                    )
                  ) {
                    fetchRealtimeTableData(
                      'blueBarAction',
                      'walmart',
                      {
                        rows: {
                          tableRow:
                            context.config.widget2.table.props.gridOptions
                              .rowData
                        }
                      },
                      'campaignManagement/',
                      'keyword',
                      context
                    );
                  }
                },
                closePanelMethod: () => {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget?.removeCustomCustomComponents();
                }
              }
            }
          }
        ]
      },
      widget: 'keyword',
      primaryKey: 'keyword_id',
      messageBar: {
        pageType: 'keywords'
      },
      headerOptions: commonTableActionIcons(),
      api: {
        request: getTableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'keywords'
      },
      metrics: {
        ...metricsDictionary(tab, 'table', 'walmart').allMetrics,
        ...(doesRetailerHaveIncrementality(retailer)
          ? updatedIncrementalMetricsForTables
          : {})
      },
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        }
      }
    },
    ...systemTransparencyBannerConfig
  };
};
