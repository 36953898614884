<template>
  <div class="u-width-100">
    <div
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-spacing-m-l"
    >
      <div class="u-spacing-ph-m">
        <rb-button
          class="u-border-color-grey-xx-light"
          :click-fn="handleManageDataSource"
          :text="'Manage data source'"
          :size="'s'"
          :type="'filled'"
          :loading="loading"
          :disabled="isTableLoading || loading"
        />
      </div>
      <div
        class="u-border-color-grey-xxx-light u-border-left u-border-width-s u-display-flex u-spacing-ph-m"
        @click="handleDownload"
      >
        <div
          class="u-display-flex u-flex-direction-column u-spacing-m-xs taxonomy-icon-button u-color-grey-lighter"
        >
          <rb-icon
            class="rb-icon--medium u-cursor-pointer"
            icon="download"
          />
          <div
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-spacing-pt-xs"
            :title="downloadTooltipTitle"
          >
            <span
              class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-cursor-pointer"
              >DOWNLOAD</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="u-position-relative">
      <loader
        v-if="isTableLoading"
        class="entity_loader u-color-blue-base fill--parent"
        :color="'#3684bb'"
        :loading="isTableLoading"
      />
      <rb-insights-table
        :config="tableConfig"
        :table-column="columnDefs"
        :show-pagination-options="true"
        :row-class-rules="gridOptions.rowClassRules"
        :table-row="rowList"
        :grid-options="gridOptions"
        :default-col-def="gridOptions.defaultColDef"
        :pagination="true"
        :pagination-total-key="tableConfig.totalRows"
        :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
        :pagination-change-event="null"
      />
      <div
        v-if="lastUpdatedAt"
        class="u-width-100 u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-mb-xl u-spacing-ph-l"
      >
        <div class="no-wrap u-color-grey-x-light u-font-size-7">
          Last Updated: {{ lastUpdatedAt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import dataCell from '@/components/widgets/tableComponents/cells/dataCell.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  name: 'SotDataPreview',
  components: {
    loader,
    dataCell
  },
  props: {
    sotType: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnDefs: [],
      rowList: [],
      paginationData: {},
      entityData: {},
      isTableLoading: false,
      currentPage: 1,
      currentLimit: 10,
      lastUpdatedAt: null
    };
  },
  computed: {
    gridOptions() {
      return {
        context: {
          componentParent: this
        },
        rowClassRules: {},
        defaultColDef: {
          flex: 1,
          wrapText: true,
          autoHeight: true
        }
      };
    },
    tableConfig() {
      return {
        meta: { paginationChange: 'emitPaginationDataForSOT' },
        totalRows: this.paginationData?.count,
        body: {
          APIConfig: {
            page: this.paginationData?.page,
            limit: this.paginationData?.limit
          }
        }
      };
    },
    columnMetaMapMT() {
      return {
        default: (columnContext) => {
          return {
            widget: 'DynamicCellComponent',
            dynamicCellComponentParams: {
              componentName: 'dataCell',
              paramsToProps: (params) => {
                const column = params.colDef.field;
                const columnValue = params?.data?.data[0][column];
                return {
                  params,
                  value: columnValue
                };
              },
              eventMap: {}
            },
            headerComponent: {
              bDynamicHeaderComponent: false,
              componentName: 'dataHeader',
              columnDefsToProps: (params) => {
                return {
                  displayName: `${columnContext.dimensionLabel}`,
                  params: params
                };
              },
              props: {
                fieldName: columnContext.dimensionColumn
              },
              eventMap: {}
            },
            showOnUi: true,
            isDownloadable: true,
            isFixed: false,
            sortOnColumn: true,
            isDefaultSortColumn: false,
            width: 250
          };
        }
      };
    },
    downloadTooltipTitle() {
      return `Downloads an Excel with the existing Source of Truth for ${this.sotType}`;
    }
  },
  created() {
    Vue.component('dataCell', dataCell);

    eventBus.$on('emitPaginationDataForSOT', (data) => {
      const { page, limit } = data?.body?.APIConfig;
      this.currentPage = page;
      this.currentLimit = limit;
      this.fetchTableData();
    });
  },
  mounted() {
    this.fetchTableData();
    this.getLastUpdatedAt();
  },
  methods: {
    handleManageDataSource() {
      this.$emit('handleManageDataSource', false);
    },
    handleDownload() {
      this.$emit('handleDownload');
    },
    fetchTableData() {
      const readApiPayload = {
        sotType: 'CONTENT_AUTHORITY_SOT_GEN',
        pagination: {
          page: this.currentPage,
          limit: this.currentLimit
        }
      };
      this.isTableLoading = true;
      HttpService.post('ENTITY_METADATA_SERVICE', readApiPayload, {
        append: '/sot/read'
      })
        .then((response) => {
          this.isTableLoading = false;
          const data = response?.data;
          const entityData = data?.response[0];
          this.rowList = entityData?.skuDataAndValues;
          this.columnDefs = this.getDynamicColumnDefs(
            entityData?.dimensionValueAndCountList
          );
          this.paginationData = data?.pagination;
        })
        .catch((error) => {
          this.isTableLoading = false;
          console.log(error);
        });
    },
    getDynamicColumnDefs(columnList) {
      const columnMeta = this.columnMetaMapMT;
      let columnDefs = columnList.map((column, index) => {
        let columnMetaObject = {};
        const columnDefinition = {
          name: column.dimensionColumn,
          uiField: {
            uiLabel: column.dimensionLabel,
            uiOrder: index + 1,
            // uiTooltip: "ASIN list as per AVC's Manufacturing catalog.",
            uiType: 'custom',
            metadata: {
              tableColumnName: column.dimensionColumn,
              showOnUi: true,
              isFixed: false
            }
          }
        };
        if (columnMeta[column.dimensionColumn]) {
          columnMetaObject = columnMeta[column.dimensionColumn](column);
        } else {
          columnMetaObject = columnMeta.default(column);
        }
        columnDefinition.uiField.metadata = {
          ...columnDefinition.uiField.metadata,
          ...columnMetaObject
        };
        return columnDefinition;
      });
      columnDefs = transformer.getColumnDefinition(columnDefs);
      return columnDefs.displayColConfigs;
    },
    getLastUpdatedAt() {
      const data = {
        entity: this.sotType
      };
      return HttpService.post('ENTITY_EXISTS', data).then((status) => {
        let lastUpdatedAt =
          status?.data?.data?.entityExists?.fileDetails?.lastUpdatedAt;
        if (lastUpdatedAt) {
          this.lastUpdatedAt = formatter(lastUpdatedAt, 'datetime');
        }
      });
    }
  }
};
</script>
