<template>
  <div class="step-component-v2">
    <div class="scrollable u-width-100 u-display-flex">
      <div
        :style="{ width: panelWidth + '%' }"
        class="u-height-100 u-display-flex u-flex-direction-column"
        :class="[allBulletPointsClass]"
      >
        <div
          v-for="(step, index) in _config"
          :key="index + enabledBreadCrumbsLocalState.toString()"
          class="menu-point u-spacing-pl-s"
          :class="[{ active: activeSection == index }]"
        >
          <div
            v-show="!step.hideLeftPanel"
            :class="[step.stepSectionColor, bulletPointClass]"
            class="u-line-height-1 u-display-flex u-flex-align-items-center u-spacing-mb-xs"
            :style="{
              width: showStepBulletDescription ? '100%' : '40%',
              'pointer-events':
                enabledBreadCrumbsLocalState.includes(index) ||
                disableBreadCrumbClickAction
                  ? 'default'
                  : 'none',
              cursor: enabledBreadCrumbsLocalState.includes(index)
                ? 'pointer'
                : 'none'
            }"
            @click="onClickBreadCrumb(index)"
          >
            <rb-icon
              v-tippy="tippy"
              class="u-cursor-pointer"
              :class="step.stepSectionIconColor"
              icon="dot"
              :title="step.breadCrumbTitle || `Step ${index + 1}`"
            />
            <span
              v-if="showStepBulletDescription"
              class="u-line-height-1"
              :data-cy="`campaignCreationStep-${step.name}`"
              >{{ step.name }}</span
            >
          </div>
          <div
            v-if="showStepBulletDescription"
            class="u-spacing-pl-m u-font-size-7 u-line-height-1-3"
          >
            <!-- eslint-disable -->
            <span
              class="u-color-grey-lighter u-font-size-7"
              :class="step.stepSectionColor"
              v-html="
                step.description ||
                (allStepsData &&
                  allStepsData.strategyUIText &&
                  allStepsData.strategyUIText[step.textMapping])
              "
              :data-cy="`stepDescriptionOf-${step.name}`"
            />
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
      <div
        :style="{ width: 100 - panelWidth + '%' }"
        class="u-display-flex u-height-100 u-overflow-auto"
      >
        <div
          id="steps"
          class="section__right u-width-100"
          :class="sectionRightClass"
        >
          <div
            v-for="(step, $index) in _config"
            :key="$index"
            class="u-width-100"
            :class="[findModuleClass(step, $index)]"
          >
            <transition name="slide-fade">
              <component
                :is="step.component"
                v-if="$index == activeSection"
                :step="step"
                :selected-values="allStepsData"
                :all-config="_config"
                @nextStep="moveDown"
                @prevStep="moveUp"
                @breadcrumb-indexes-to-enable="handleEnableBreadCrumbs"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepComponent',
  props: {
    disableBreadCrumbClickAction: {
      type: Boolean,
      default: false
    },
    config: {
      type: Array,
      default: () => {
        return [];
      }
    },
    enabledBreadCrumbs: {
      type: Array,
      default: () => {
        return [0];
      }
    },
    setActiveSelection: {
      type: Number,
      default: 0
    },
    panelWidth: {
      type: Number,
      default: 5
    },
    allStepsData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showStepBulletDescription: {
      type: Boolean,
      default: true
    },
    moduleClass: {
      type: String,
      default: ''
    },
    sectionRightClass: {
      type: String,
      default: ''
    },
    bulletPointClass: {
      type: String,
      default: 'u-flex-justify-content-center'
    },
    allBulletPointsClass: {
      type: String,
      default: 'u-flex-justify-content-center'
    },
    editSuggestted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeSection: 0,
      enabledBreadCrumbsLocalState: [0],
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        maxWidth: '200px',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    _config() {
      return [...this.config];
    }
  },
  watch: {
    setActiveSelection: {
      handler: function (newValue) {
        this.activeSection = newValue;
      },
      immediate: true
    },
    enabledBreadCrumbs: {
      handler: function (newVal) {
        this.handleEnableBreadCrumbs(newVal);
      },
      immediate: true
    },
    _config: {
      handler: function (newValue) {
        this.stepSectionColorConfig();
        this.stepSectionIconColorConfig();
      },
      deep: true,
      immediate: true
    },
    activeSection: {
      handler: function (newValue) {
        this.stepSectionColorConfig();
        this.stepSectionIconColorConfig();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.$emit('vueRef', this);
  },
  methods: {
    onClickBreadCrumb(index) {
      if (this.disableBreadCrumbClickAction) {
        return;
      }
      this.activeSection = index;
    },
    findModuleClass(step, index) {
      if (index === this.activeSection) {
        return step?.moduleClass;
      }
      return this.step;
    },
    handleEnableBreadCrumbs(newVal) {
      this.enabledBreadCrumbsLocalState = newVal;
    },
    moveDown: function (step) {
      this.activeSection++;
      if (this.activeSection > this._config.length - 1) this.activeSection = 0;
      // this.$emit('currentStep', this._config[this.activeSection]['name'])
      this.$emit('activeSelectionUpdated', this.activeSection);
    },
    moveUp: function (step) {
      this.activeSection--;
      if (this.activeSection < 0) this.activeSection = this._config.length - 1;
      // this.$emit('currentStep', this._config[this.activeSection]['name'])
      this.$emit('activeSelectionUpdated', this.activeSection);
    },
    stepSectionColorConfig() {
      this.config.forEach((item, index) => {
        if (
          this.activeSection === index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionColor = 'u-color-orange-base u-font-size-5';
        } else if (
          this.activeSection < index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionColor = 'u-color-orange-base u-font-size-7';
        } else if (
          this.activeSection > index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionColor = 'u-color-orange-base u-font-size-5';
        } else {
          if (this.activeSection === index)
            item.stepSectionColor = 'u-color-blue-base u-font-size-5';
          if (this.activeSection < index)
            item.stepSectionColor = 'u-font-size-7';
          if (this.activeSection > index)
            item.stepSectionColor = 'u-font-size-5';
        }
      });
    },
    stepSectionIconColorConfig() {
      this.config.forEach((item, index) => {
        if (
          this.activeSection === index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionIconColor = 'u-color-orange-base rb-icon--medium';
        } else if (
          this.activeSection < index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionIconColor = 'u-color-orange-base rb-icon--small';
        } else if (
          this.activeSection > index &&
          item.incomplete &&
          this.editSuggestted &&
          item?.addedRows < item?.requiredRows
        ) {
          item.stepSectionIconColor = 'u-color-orange-base rb-icon--medium';
        } else {
          if (this.activeSection === index)
            item.stepSectionIconColor = 'u-color-blue-base rb-icon--medium';
          if (this.activeSection < index)
            item.stepSectionIconColor = 'rb-icon--small u-color-grey-lighter';
          if (this.activeSection > index)
            item.stepSectionIconColor = 'rb-icon--medium';
        }
      });
    }
  }
};
</script>
<style lang="css" scoped>
.step-component-v2 .scrollable {
  height: calc(100vh - 108px);
  padding-top: 108px;
}
.step-component-v2 .step_page {
  transition: 0.5s;
  width: 100%;
  min-height: 100%;
}
.step-component-v2 .slide-fade-enter-active {
  transition: all 0.4s ease-out;
}
.step-component-v2 .slide-fade-leave-active {
  transition: all 0.1s ease-out;
}
.step-component-v2 .slide-fade-enter,
.step-component-v2 .slide-fade-leave-to {
  /* .slide-fade-leave-active below version 2.1.8 */
  transform: translateY(180px);
  opacity: 0;
}
</style>
