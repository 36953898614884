import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled,
  CONSTANTS,
  getDimensionList,
  getKeywordColumn
} from '@/components/pages/insights/amazon/cva/utils';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  async fetchData(
    pageSettings,
    selectedFilter = CONSTANTS.defaultTrendSelection,
    dcName = 'brandStrength'
  ) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_brand_strength_search_term',
        entityType: 'search_term',
        metricsList: rem1V2ConfigEnabled()
          ? [
              'cva_brand_strength_client_id_v2',
              'cva_bs_search_frequency_rank_pvp_v2',
              'cva_bs_search_frequency_rank_percentage_change_v2',
              'cva_bs_combined_sfr_pvp_v2'
            ]
          : ['cva_brand_strength_client_id'],
        dimensionsList: [
          'search_term',
          'search_frequency_rank',
          'time_period',
          'brand',
          'N1_CLICKED_ASIN',
          'N2_CLICKED_ASIN',
          'N3_CLICKED_ASIN',
          'client_id',
          'pre_search_frequency_rank'
        ],
        eventsList: [],
        enablePaginationCount: true,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: CONSTANTS[dcName]?.[selectedFilter]?.orderByList,
          limit: CONSTANTS[dcName]?.[selectedFilter]?.limit,
          page: 1,
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Brand_strength_ST',
              operator: 'EQUAL_TO'
            },
            ...getDimensionList(selectedFilter, dcName)
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'Search Term',
        field: 'search_term',
        toolTipText: 'Search term used by customers in the search bar'
      },
      {
        headerName: 'Brand',
        field: 'brand',
        toolTipText: 'Brand related to the search term',
        cellClass: 'u-text-transform-capitalize'
      },
      getKeywordColumn('search_frequency_rank_percentage_change'),
      {
        headerName: '#1 Clicked Product',
        field: 'N1_CLICKED_ASIN',
        headerComponentParams: {
          enableSorting: false,
          toolTipText: '#1 clicked product for the search term'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        minWidth: 200,
        maxWidth: 200
      },
      {
        headerName: '#2 Clicked Product',
        field: 'N2_CLICKED_ASIN',
        headerComponentParams: {
          enableSorting: false,
          toolTipText: '#2 clicked product for the search term'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        minWidth: 200,
        maxWidth: 200
      },
      {
        headerName: '#3 Clicked Product',
        field: 'N3_CLICKED_ASIN',
        headerComponentParams: {
          enableSorting: false,
          toolTipText: '#3 clicked product for the search term'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        minWidth: 200,
        maxWidth: 200
      }
    ];
    columns = getActiveColumns(columns, 'brand_strength');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = [
      'N1_CLICKED_ASIN',
      'N2_CLICKED_ASIN',
      'N3_CLICKED_ASIN'
    ];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        previousVal.search_frequency_rank_pvp_invertTag = true;
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
