import { isEmpty } from 'lodash';

// replace a value in an object containing any data structure
export function deepReplaceObjectValues(obj, keyName, replacer) {
  for (const key in obj) {
    if (obj[key] === keyName) {
      obj[key] = replacer;
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((member) =>
        deepReplaceObjectValues(member, keyName, replacer)
      );
    } else if (typeof obj[key] === 'object') {
      deepReplaceObjectValues(obj[key], keyName, replacer);
    }
  }
}

export function deepReplaceUnusedValuesWithNull(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key][0] === ':') {
      obj[key] = null;
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((member) => deepReplaceUnusedValuesWithNull(member));
    } else if (typeof obj[key] === 'object') {
      deepReplaceUnusedValuesWithNull(obj[key]);
    }
  }
}
// check for empty row whose key column is empty
export function checkForNullKeyColumnInRows(
  selections,
  columnKey,
  errMesage,
  context
) {
  if (selections.length) {
    selections.map((row, index) => {
      if (!row.data[columnKey]) {
        context.snackbar(errMesage, 6000);
        context.selections[index].setSelected(false);
        return true;
      }
    });
  }
  return false;
}

export const updateExistingWidgetParams = (
  localStorageState,
  widget,
  entityId
) => {
  const campaignCreationState = JSON.parse(
    localStorage.getItem(localStorageState) || '{}'
  );
  const savedWidgetData =
    campaignCreationState?.value?.[widget[':widget']] || null;
  if (savedWidgetData && campaignCreationState.entityId === entityId) {
    widget[':orderByList'] = savedWidgetData?.[':orderByList'];
    widget[':dimensionNameValueList'] =
      savedWidgetData?.[':dimensionNameValueList'];
  }
};

export const saveExistingWidgetParams = (
  localStorageState,
  value,
  entityId
) => {
  if (isEmpty(value)) {
    return;
  }
  const campaignCreationState = {
    entityId: entityId || null,
    value
  };
  localStorage.setItem(
    localStorageState,
    JSON.stringify(campaignCreationState)
  );
};

export const findDimensionValueFromWidgetParams = (
  allWidgets,
  widgetName,
  dimensionName
) => {
  const dimensionNameValueList =
    allWidgets?.[widgetName]?.[':dimensionNameValueList'] || [];
  const searchTextArray = dimensionNameValueList.filter(
    (item) => item.dimensionName === dimensionName
  );
  if (searchTextArray?.length) {
    return searchTextArray[0];
  } else {
    return {};
  }
};

export const adjustPayloadForTargets = (isTarget, payload, selection) => {
  if (isTarget) {
    // set actiontype
    payload.actionPayload.target_id = selection?.data?.keyword_id;
    payload.actionType = 'targetMetadataChange';
    delete payload?.actionPayload?.keyword_id;
  } else {
    // set actiontype
    payload.actionType = 'keywordMetadataChange';
    delete payload?.actionPayload?.target_id;
  }
};
