<template>
  <div
    v-if="skusTableService"
    id="pricing_power"
  >
    <generic-tabs-component
      :tabs-list="priceRanges"
      :shadow="false"
      :tab-styles="{ gap: '0px' }"
      :class="{
        'u-border-bottom u-border-width-s u-border-color-grey-xxx-light':
          computedError || computedLoading
      }"
      @handleTabsClick="handlePriceRangeChange"
    />
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div v-show="showSkusTable && !computedLoading && !computedError">
      <rb-insights-table
        :table-column="skusTableColDefs"
        :table-row="skusTableData"
        sorting-change-event="pricingSortEvent"
        :grid-options="skusTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SkusTableService from '@/components/pages/insights/amazon/cva/services/pricingPower/skusTableService';
import genericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import skuWithRating from '@/components/basic/skuWithRating.vue';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
import { isCloseDC } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'BrandStrength',
  components: {
    skuWithRating,
    loader,
    genericTabsComponent
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewService: null,
      skusTableService: null,
      selectedView: null,
      skusTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      brandTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      selectedPriceRange: 'High Price',
      priceRanges: ['High Price', 'Medium Price', 'Low Price']
    };
  },
  computed: {
    showSkusTable() {
      return this.selectedWidget === 'SKUs';
    },
    computedLoading() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.loading ||
          this.skusTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.error || this.skusTableService.error
        );
      }
    },
    brandTableColDef() {
      const columns = this.brandViewService?.getColumnDefs() || [];
      return [...columns];
    },
    skusTableColDefs() {
      const columns = this.skusTableService?.getColumnDefs() || [];
      return [...columns];
    },
    table1Data() {
      return [];
    },
    skusTableData() {
      this.skusTableGridOptions?.api?.sizeColumnsToFit();
      return this.skusTableService?.getTableData() || [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.skusTableService = new SkusTableService('cva', 'table2', 459, 100);
    this.skusTableService.fetchData(this.pageSettings, this.selectedPriceRange);
    this.fetchLiner();
  },
  created() {
    Vue.component('skuWithRating', skuWithRating);
  },
  methods: {
    async fetchLiner() {
      const widgetSettings = cloneDeep(this.pageSettings);
      widgetSettings?.where?.dimensionNameValueList.push({
        dimensionName: 'attributes',
        dimensionValue:
          this.skusTableService?.priceBandMap[this.selectedPriceRange],
        operator: 'EQUAL_TO'
      });
      const x = await this.skusTableService.fetchInsights(
        ['Double click Pricing'],
        widgetSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    },
    handlePriceRangeChange(newPriceRange) {
      this.selectedPriceRange = newPriceRange;
      this.skusTableService.fetchData(this.pageSettings, newPriceRange);
      this.fetchLiner();
    }
  }
};
</script>
