import HttpLayer from '@/utils/services/http-layer';
import { commons } from '@/components/ams/campaigns/commons.js';
import moment from 'moment';
import { getDateRange } from '@/utils/helpers/date.js';
import BaseDataService from '@/pages/campaign-management/services/base-data-service.js';
export default class ChartWithLegendsDataService extends BaseDataService {
  data = {
    load: true,
    error: false,
    rows: {},
    noData: false
  };

  getUnitsAxesMap(allMetrics) {
    const map = {};
    const units = {};
    const axes = {};
    for (var i in allMetrics) {
      var configuredUnit = allMetrics[i].unit;
      var unit = null;
      map[i] = allMetrics[i].key;
      if (configuredUnit === 'CURRENCY') {
        unit = {
          pre: 'currency'
        };
      } else if (configuredUnit === 'PERCENTAGE') {
        unit = {
          suff: '%'
        };
      } else {
        unit = {
          pre: '',
          suff: ''
        };
      }
      units[map[i]] = unit;
      if (configuredUnit === 'CURRENCY') {
        axes[map[i]] = 'y';
      } else {
        axes[map[i]] = 'y2';
      }
    }
    return {
      units,
      axes,
      map
    };
  }

  getTimeSeriesWithHoles(
    xAxis,
    accessKey,
    trendline = [],
    aAxisKey = 'report_date'
  ) {
    //  to-do: refcator into a utils file
    const dateValueMap = {};
    const data = [];
    trendline.forEach((item) => {
      const timeSeriesDate = moment(item[aAxisKey]).format('DD/MM/YYYY');
      dateValueMap[timeSeriesDate] = item[accessKey];
    });

    for (let i = 1; i < xAxis.length; i++) {
      const axisDate = moment(xAxis[i]).format('DD/MM/YYYY');
      if (
        dateValueMap[axisDate] === null ||
        dateValueMap[axisDate] === undefined
      ) {
        data.push(null);
      } else {
        data.push(dateValueMap[axisDate]);
      }
    }
    return data;
  }

  getXAxis(startDate, endDate, accessKey = 'report_date') {
    return [accessKey, ...getDateRange(startDate, endDate)];
  }

  getTrendlineDataWithDate(dataArray, dataKey, xAxisKey) {
    return dataArray.map((item) => {
      return {
        [dataKey]: item[dataKey],
        [xAxisKey]: item[xAxisKey]
      };
    });
  }

  getChartFormattedData(data, metricListMap, config) {
    const { xAxisKey = 'report_date', toDate, fromDate } = config;
    const xAxis = this.getXAxis(fromDate, toDate, xAxisKey);
    const metricKeys = Object.keys(metricListMap);
    const formattedChartData = metricKeys.map((metric) => {
      const metricTimeseries = this.getTrendlineDataWithDate(
        data[0].TIMESERIES,
        metric,
        xAxisKey
      );
      return [
        metric,
        ...this.getTimeSeriesWithHoles(xAxis, metric, metricTimeseries)
      ];
    });
    formattedChartData.push(xAxis);
    // const formattedChartData = this.getTimeSeriesWithHoles(xAxis);
    // const formattedChartData = transformer.getChartDataInFormat(data, data[0].TIMESERIES);
    // set timeseries key same as the cards keys
    formattedChartData.forEach((item) => {
      if (metricListMap?.[item?.[0]]?.key) {
        item[0] = metricListMap[item[0]].key;
      }
    });
    return {
      data: formattedChartData
    };
  }

  getMetricFromMap(metricObject = {}) {
    return metricObject?.key;
  }

  getTagUnitData(data, units, metricListMap) {
    var oReturn = {};
    var result = (data[0] || {}).RESULT;
    for (var i in result) {
      const key = this.getMetricFromMap(metricListMap[i]) || i;
      oReturn[key] = {
        invertTag2: commons.isInverted(i),
        tag1Unit: units[key],
        tag2Unit: {
          suff: '%'
        }
      };
    }
    return oReturn;
  }

  getTagData(data, metricListMap = {}) {
    if (!data) {
      data = [];
    }
    var oReturn = {};
    var result = (data[0] || {}).RESULT;
    var pvp = (data[0] || {}).PVP;
    if (!result) {
      return oReturn;
    }

    for (var i in result) {
      const key = this.getMetricFromMap(metricListMap[i]) || i;
      oReturn[key] = {
        tag1: result[i],
        tag2: (pvp || {})['PVP_' + i] || undefined
      };
      if (metricListMap[i]?.unit === 'PERCENTAGE') {
        // If unit is PERCENTAGE keep tag2 as PVP_DIFF_
        oReturn[key].tag2 = (pvp || {})['PVP_DIFF_' + i] || undefined;
      }
    }
    return oReturn;
  }

  transformData(entityData, config) {
    const _allMetrics = config.chart.props.metricsList;
    const metricListMap = _allMetrics.reduce((acc, item) => {
      acc[item.alias] = item;
      return acc;
    }, {});
    const unitAxesMap = this.getUnitsAxesMap(_allMetrics);
    const metricsData = this.getTagData(entityData, metricListMap);
    const metricDisplayConfig = this.getTagUnitData(
      entityData,
      unitAxesMap.units,
      metricListMap
    );
    const chartData = this.getChartFormattedData(
      entityData,
      metricListMap,
      config
    );
    const tooltipFormat = unitAxesMap.units;
    return {
      metricsData,
      metricDisplayConfig,
      chartData,
      tooltipFormat
    };
  }

  async getData(requestCopy, config) {
    try {
      this.data = {
        load: true,
        error: false
      };
      const { data } = await HttpLayer.post({
        APIData: requestCopy,
        cube: 'EXECUTE_CUBE_CHART_API'
      });
      if (data && data[0]) {
        if (!data[0].TIMESERIES || data[0].TIMESERIES.length === 0) {
          this.data = {
            rows: [],
            error: false,
            load: false,
            noData: true
          };
        } else {
          this.data = {
            rows: this.transformData(data, config),
            error: false,
            load: false,
            noData: false
          };
        }
      } else {
        this.data = {
          load: false,
          error: true
        };
      }
    } catch (e) {
      this.data = {
        load: false,
        error: true
      };
      throw e;
    }
  }
}
