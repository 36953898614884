<template>
  <section
    class="u-display-flex u-flex-direction-column"
    :class="className"
    :style="{ width: dimensions.width, height: dimensions.height }"
  >
    <div
      class="u-flex-1 u-display-flex u-flex-direction-column card u-border-width-s u-border-color-grey-xxx-light"
    >
      <!-- Right Table header -->
      <div
        v-if="showHeader"
        class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5 u-display-flex u-flex-justify-content-space-between u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
      >
        <div class="u-spacing-pv-s">
          {{ headerText }}
        </div>
        <div
          v-if="showCloseIcon"
          class="u-display-flex u-flex-align-items-center"
          @click="onClose"
        >
          <rb-icon
            class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-xs"
            icon="cross"
          />
        </div>
      </div>
      <div class="u-display-inline-flex">
        <slot name="action-content" />
      </div>
      <div
        class="u-position-relative u-display-flex u-flex-direction-column u-flex-1 u-width-100"
      >
        <Loader
          v-if="loading"
          :loading="loading"
          class="u-height-100"
          :color="'#3fadf7'"
          :size="'2rem'"
          :thickness="'.2rem'"
        />
        <rb-insights-table
          v-else
          class="u-display-flex table-tooltip u-flex-direction-column"
          :grid-options="gridOptions"
          :config="tableConfig"
          :auto-configure="autoConfigureOption"
          :row-height="rowHeight"
          :header-height="headerHeight"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="false"
          :pagination="false"
          :client-side-pagination="false"
          :pagination-total-key="autoConfigureOption.rows.length"
          :get-current-instance="getCurrentSourceInstance"
          :pagination-per-page-limit="paginationPerPageLimit"
          :download-this-table="false"
          :show-pagination-options="false"
          :row-no-hover-state="true"
          :enable-col-resize="false"
          :row-class-rules="rowClassRules"
        />
      </div>
      <div
        v-if="showFooter"
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between card-footer u-spacing-pv-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <slot name="footer" />
      </div>
    </div>
  </section>
</template>

<script>
import Loader from '@/components/basic/loader.vue';
export default {
  name: 'TableTooltip',
  components: {
    Loader
  },
  props: {
    columnDefinition: {
      type: Array,
      default: () => []
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paginationPerPageLimit: {
      type: Number,
      default: 10
    },
    headerText: {
      type: String,
      default: 'Header'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    componentParentContext: {
      type: Object,
      default() {
        return {};
      }
    },
    rowHeight: {
      type: Number,
      default: 48
    },
    headerHeight: {
      type: Number,
      default: 48
    },
    dimensions: {
      type: Object,
      default: () => ({
        width: '849px',
        height: '400px'
      })
    },
    className: {
      type: String,
      default: ''
    },
    showFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rbTableSourceInstance: null,
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this.componentParentContext
        }
      },
      selectionsMap: {},
      currentPage: 1,
      fromChangingTheSelection: false
    };
  },
  computed: {
    autoConfigureOption() {
      return {
        rows: this.dataRows,
        columns: this.columnDefinition
      };
    },
    rowClassRules() {
      return {
        alignRowsCenter: function () {
          return true;
        },
        hideBottomBorder: function (params) {
          const { data } = params;
          return data.plan === ' ';
        }
      };
    }
  },
  methods: {
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
      this.$emit('tableContext', context);
    },
    onClose() {
      this.$emit('onClose');
    }
  }
};
</script>
