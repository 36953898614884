var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scope_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"},[(_vm.step.name === 'Scope')?_c('div',{staticClass:"u-width-100 u-spacing-mb-xxl"},[_c('div',{staticClass:"u-width-100 u-display-flex u-flex-justify-content-space-between"},[_c('div',[_c('Strategy-Header',{attrs:{"step":_vm.step}}),_c('rb-list-select',{attrs:{"options":(_vm.metaDataConfig[_vm.step.keyToRead] &&
              _vm.metaDataConfig[_vm.step.keyToRead].entityList) ||
            [],"display-key-to-read":'entityName'},model:{value:(_vm.selectedValues.strategyEntityType),callback:function ($$v) {_vm.$set(_vm.selectedValues, "strategyEntityType", $$v)},expression:"selectedValues.strategyEntityType"}}),_c('div',{staticClass:"u-spacing-mt-m"},[_c('rb-list-select',{attrs:{"size":'small',"options":(_vm.selectedValues.strategyEntityType &&
                _vm.selectedValues.strategyEntityType.filters) ||
              [],"display-key-to-read":'name'},model:{value:(_vm.selectedValues.scope.subFilter),callback:function ($$v) {_vm.$set(_vm.selectedValues.scope, "subFilter", $$v)},expression:"selectedValues.scope.subFilter"}})],1)],1),(_vm.infoBox.show)?_c('infoBox',{staticClass:"u-max-width-20 u-flex-align-self-flex-start u-spacing-mb-m",attrs:{"title":_vm.infoBox.title,"description":_vm.infoBox.description}}):_vm._e()],1),(
        _vm.selectedValues.scope.subFilter &&
        _vm.selectedValues.scope.subFilter.name &&
        _vm.selectedValues.scope.subFilter.custom
      )?_c('div',{staticClass:"u-font-size-5 u-color-grey-x-light u-spacing-mt-s pageBackground u-border-radius-s u-spacing-pl-m u-width-100 u-display-flex u-flex-align-items-center u-scope-filter_wrp",staticStyle:{"min-height":"63px"}},[_c('rb-filter-panel',{attrs:{"enable-save":false,"data":_vm.filterData,"primary":_vm.primaryFilterDataScope,"secondary-data":{},"has-search":false,"enable-remove-all":false,"loader-status":_vm.loaderStatus,"on-create":_vm.onCreate,"use-local-storage":false,"emit":'scopeFilterChangeEvent',"show-notification":false,"default-filters":_vm.defaultFilters,"listener-event":'strategy-scope',"operators-to-filter-out":['IS_NULL', 'IS_NOT_NULL']}})],1):_vm._e(),(_vm.showEditValidationError)?_c('div',{staticClass:"u-font-size-5 u-spacing-mt-l u-color-red-base"},[_c('p',[_vm._v("Validation Error.")])]):_vm._e()]):_vm._e(),_c('section',{staticClass:"u-margin-top-80 u-display-flex u-margin-bottom-80"},[_c('rb-button',{staticClass:"u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s",attrs:{"id":"confirmButton","text":'Back',"type":'hollow',"click-fn":_vm.movePrev.bind(this)}}),_c('rb-button',{attrs:{"id":"confirmButton","text":'Next',"disabled":_vm.disabledButton,"type":'filled',"click-fn":_vm.moveNext.bind(this)}})],1),_c('div',{staticClass:"u-spacing-mb-xxl"}),(_vm.filterIsActive)?_c('div',{staticClass:"scope-fill-space"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }