<template>
  <div>
    <div class="u-font-size-5 u-font-weight-600 u-display-inline-block">
      {{ label }}
      <span
        v-if="isOptional"
        class="u-color-grey-lighter"
      >
        (Optional)</span
      >
    </div>
    <div class="u-display-flex u-spacing-mt-s">
      <span
        class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
      >
        {{ $currency }}
      </span>
      <input
        :value="value"
        data-cy="inputValue"
        type="number"
        min="0"
        class="rb-input currency-input u-display-flex u-flex-align-items-center"
        step="0.01"
        @input="onBudgetChange"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BudgetInput',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onBudgetChange(event) {
      const { value } = event.target;
      this.$emit('input', parseFloat(value));
    },
    onBlur() {
      this.$emit('onBlur');
    }
  }
};
</script>

<style lang="css">
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
  height: 36px;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 36px;
}
</style>
