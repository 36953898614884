<template>
  <div
    v-if="showError"
    class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
  >
    <rb-icon
      v-if="showErrorIcon"
      class="u-flex-0 rb-icon--small"
      :icon="iconName"
    />
    <div
      data-cy="errorMessage"
      class="u-flex-0 u-spacing-mt-xxxs"
      :class="{ 'u-spacing-ml-xxs': showErrorIcon }"
    >
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormErrorMessage',
  props: {
    showError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: 'Error'
    },
    showErrorIcon: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: 'error-fill'
    }
  }
};
</script>

<style lang="css" scoped>
.errorMessage {
  margin-top: 4px;
}
.errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .rb-icon {
  align-self: flex-start;
}
</style>
