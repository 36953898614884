<template>
  <div
    class="rb-filter"
    :class="{ 'filter--open': isActive }"
  >
    <span
      v-if="selections.length > 0 || isAlwaysOnFilter"
      :key="filterId"
      ref="trigger"
      v-tippy="{
        placement: 'bottom-start',
        arrow: false,
        interactive: false,
        size: 'regular',
        distance: 2
      }"
      class="u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
      :class="{
        'filter--token': chipSize === 'm',
        'filter-token-dashed': selections.length === 0,
        'filter--token-s': chipSize === 's',
        'custom-filter': isAlwaysOnFilter && selections.length === 0,
        'no-selection': isAlwaysOnFilter && selections.length === 0,
        disabled: isDisabledFilter === true
      }"
      :title="tippyTitle"
      style="min-height: 20px; max-height: 300px"
      @click="toggle"
    >
      <div
        v-if="isAlwaysOnFilter"
        class="u-display-inline-flex u-flex-align-items-center"
      >
        <rb-icon
          v-if="selections.length > 0"
          :icon="'filter'"
          class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
        />
        <rb-icon
          v-else
          :icon="'add-circle-fill'"
          class="rb-icon rb-icon--small u-spacing-mr-xs icon-add-circle-fill"
        />
        <!-- eslint-disable -->
        <span class="u-spacing-pl-xs u-spacing-pr-s u-line-height-1-1">
          <span v-if="isAllChipShown"
            >{{ this.config.title }} :
            <span class="u-color-grey-base">{{
              selections.join(selectionsJoiningString)
            }}</span></span
          >
          <span
            v-else-if="isAlwaysOnFilter && selections.length === 0"
            :class="{ 'add-filter-text': isAlwaysOnFilter }"
            >{{ addFilterText }}
          </span>
          <span
            v-html="showSelectedFilters"
            v-else
          ></span>
        </span>
        <!-- eslint-enable -->
      </div>
      <div
        v-if="!isAlwaysOnFilter && !selections[0]['operator']"
        class="u-display-inline-flex u-flex-align-items-center"
      >
        <rb-icon
          :icon="'filter'"
          class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
        />
        <!-- eslint-disable -->
        <span class="u-spacing-pl-xs u-spacing-pr-s u-line-height-1-1">
          <span v-if="multi && isAllChipShown"
            >{{ this.config.title }} :
            <span class="u-color-grey-base">All</span></span
          >
          <span
            v-html="showSelectedFilters"
            v-else
          ></span>
        </span>
        <!-- eslint-enable -->
      </div>
      <span
        v-if="!isAlwaysOnFilter && selections[0]['operator']"
        class="u-spacing-pr-s u-line-height-1_2"
      >
        {{ config.title }}
        {{
          selections[0].operator.operator === 'BETWEEN'
            ? ''
            : selections[0].operator.title
        }}
        <span>{{ getExpressionFilterSpecificMessage }}</span>
      </span>
      <rb-icon
        v-if="selections.length > 0 && hideRemoveBtn"
        class="rb-icon--small u-color-grey-lighter"
        :icon="'caret-down'"
      />
      <span
        v-if="!hideRemoveBtn"
        @click="removeSelections"
      >
        <rb-icon
          v-if="selections.length > 0"
          data-cy="remove-filters-icon"
          :icon="'cross'"
          class="u-cursor-pointer u-color-grey-lighter"
          :class="{
            'rb-icon--x-small': chipSize === 'm',
            'rb-icon--xx-small': chipSize === 's'
          }"
        />
      </span>
    </span>
    <div
      v-if="isActive"
      ref="dropdownMenu"
      :style="getTopValue"
      class="filter-dropdown-menu"
      :class="[type === 'column' ? 'u-overflow-visible' : 'u-overflow-auto']"
    >
      <div class="filter--header u-width-100">
        <div class="u-display-flex u-flex-align-items-center">
          <div
            v-if="showBackButton"
            @click="goToPrimaryFilterPanel"
          >
            <rb-icon
              data-cy="filters-back-button"
              icon="arrow-right"
              class="is-flipped rb-icon--small u-color-grey-white u-spacing-pr-l u-cursor-pointer"
            />
          </div>
          <div
            class="u-color-grey-white u-display-flex"
            style="max-width: 186px"
          >
            <span
              class="u-spacing-pv-s u-text-overflow-ellipsis"
              :title="config.title"
              :data-cy="`filterTitle-${config.title}`"
            >
              {{ config.title }} {{ showStandardShelf ? '(Level 1)' : '' }}
            </span>
          </div>
        </div>
        <span @click="toggle">
          <rb-icon
            :icon="'cross'"
            class="rb-icon--small u-color-grey-white u-cursor-pointer"
            data-cy="icon"
          />
        </span>
      </div>
      <div v-if="config.title === 'Share of Voice' && type === 'EXPRESSION'">
        <RuleBuilderV2
          :root="ruleBuilderRoot"
          :has-header="false"
          @ruleApply="handleRuleApply"
        />
      </div>
      <div v-else>
        <div
          v-if="
            (config.title !== 'Standard Shelf' &&
              type !== 'EXPRESSION' &&
              type !== 'ADVANCED_FILTERS' &&
              type !== 'MARKET_SHARE_CATEGORISATION' &&
              !isAlwaysOnFilter) ||
            (type === 'DIGITAL_SHELF' &&
              config.title === 'Custom Shelf' &&
              !isAlwaysOnFilter) ||
            type === 'CUSTOM_LISTS' ||
            type === 'CLIENT_INTERNAL_CATALOGUE'
          "
        >
          <div
            v-if="showSelectAll"
            class="u-spacing-pt-s"
            style="padding-bottom: 0px"
          >
            <filter-select-all
              v-if="options.length > 0"
              :is-all-selected="isAllSelected"
              select-all-text="Select All"
              de-select-all-text="Deselect All"
              @handleFilterSelectAll="handleFilterSelectAll"
            >
            </filter-select-all>
          </div>
          <div
            v-else
            class="u-display-flex u-width-100 u-box-sizing-border-box u-spacing-ph-m u-spacing-pt-m u-spacing-pb-s"
          >
            <rb-input
              ref="searchBox"
              v-model="searchText"
              :placeholder="placeHolder"
              class="u-flex-1 search--input-s"
              data-cy="searchFilters"
              :rounded="false"
            />
            <rb-icon
              style="right: 25px"
              class="u-color-grey-lighter u-position-absolute rb-icon--medium"
              :icon="'search'"
            />
          </div>
        </div>
        <div
          v-if="
            type !== 'EXPRESSION' &&
            type !== 'ADVANCED_FILTERS' &&
            type !== 'DIGITAL_SHELF' &&
            type !== 'CUSTOM_LISTS' &&
            type !== 'MARKET_SHARE_CATEGORISATION'
          "
          class="u-position-relative filter-dropdown-content u-display-flex u-overflow-hidden u-bg-color-grey-white"
          style="content-visibility: auto"
        >
          <loader
            v-if="showLoader"
            class="fill--parent"
            :loading="showLoader"
            :color="'#007cf6'"
          />
          <div
            ref="scrollContainer"
            :class="[
              'u-border-width-s',
              'u-border-color-grey-xxx-light',
              showSelectAll ? 'filter-container-select-all' : 'filter-container'
            ]"
          >
            <div
              v-if="!optionsAfterSearch.length"
              class="u-spacing-m-m u-font-size-7 u-text-align-center u-color-grey-light"
            >
              No Results
            </div>
            <div v-else>
              <filter-select-all
                v-if="
                  multi && optionsAfterSearch.length > 1 && !isAlwaysOnFilter
                "
                :is-all-selected="isAllSelected"
                :options-after-search="optionsAfterSearch"
                :search-text="searchText"
                :is-all-disabled="isAllDisabled"
                :is-some-disabled="isSomeDisabled"
                @handleFilterSelectAll="handleFilterSelectAll"
              />
              <div
                v-for="(item, index) in optionsAfterSearch"
                :key="index"
              >
                <div
                  v-tippy="tippyOption"
                  :title="
                    showDynamicTooltip
                      ? filterListTooltipFn(item.title)
                      : disabledTooltipText(item.enable)
                  "
                >
                  <div
                    :class="[
                      'u-cursor-pointer filter-list__item',
                      { disabled: !item.enable && smart }
                    ]"
                  >
                    <span
                      class="u-display-flex u-flex-align-items-center u-spacing-pv-s u-spacing-ph-m"
                      @click="itemClick(item)"
                    >
                      <div
                        v-if="type === 'STRATEGY'"
                        class="u-display-flex u-width-100"
                      >
                        <strategy-filter
                          :primary-data="primaryData"
                          :temp-selections="tempSelections"
                          :filter-data="item"
                        />
                      </div>
                      <div
                        v-else
                        class="u-display-flex u-width-100"
                      >
                        <rb-icon
                          :icon="getIcon(item.title)"
                          data-cy="filterValue"
                          :class="[
                            'u-flex-align-self-flex-start u-flex-0 rb-icon--medium',
                            getIconClass(item.title)
                          ]"
                        />
                        <p
                          class="u-font-weight-400 u-spacing-pl-s u-line-height-1 u-font-size-5 u-color-grey-light"
                          :class="{
                            'u-text-overflow-ellipsis-3-line':
                              checkForLongTitle(item.title),
                            'u-text-overflow-ellipsis': !checkForLongTitle(
                              item.title
                            )
                          }"
                          :title="item.title"
                          :data-cy="`filterValue-${item.title}`"
                        >
                          {{ getTitle(item) }}
                        </p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showStandardShelf"
          class="filter-dropdown-content-custom u-display-flex u-flex-align-items-start u-overflow-auto u-bg-color-grey-white"
        >
          <standard-shelf
            :selections="selections"
            :body="standardShelfParams['body']"
            :method="standardShelfParams['method']"
            :filter-data-key="standardShelfParams['filterDataKey']"
            :api="standardShelfParams['api']"
            :request-config="standardShelfParams['config']"
            @selection="saveStandardDigitalShelfFilters"
          />
        </div>
        <div
          v-if="
            (type === 'DIGITAL_SHELF' && config.title === 'Custom Shelf') ||
            type === 'CUSTOM_LISTS'
          "
          class="filter-dropdown-content-custom u-display-flex u-flex-align-items-start u-overflow-auto u-bg-color-grey-white"
        >
          <custom-shelf
            :selections="tempSelections"
            :available-options-count="optionsAfterSearch.length"
            :selected-selections-count="tempSelections.length"
            :search-text="searchText"
            :filter-type="type"
            @selection="saveCustomDigitalShelfFilters"
            @options-fetched="customDataFetched"
          />
        </div>
        <div
          v-if="type === 'EXPRESSION' || type === 'ADVANCED_FILTERS'"
          data-cy="filterField"
          class="filter-dropdown-content u-display-flex u-flex-align-items-center u-bg-color-grey-white"
        >
          <query-builder
            ref="queryBuilder"
            :filter-for-operators="filterForOperators"
            :disable-between-operator="disableBetweenOperator"
            :apply-changes="queryFilters"
            :hide-metrics="true"
            :add-rules="false"
            :metrics="config"
            addtional-metadata="type"
            :selected-rules="selectedRules"
            :operators-to-filter-out="operatorsToFilterOut"
          />
        </div>
        <div
          class="u-display-flex u-bg-color-grey-white u-spacing-p-m u-flex-align-items-center u-position-relative"
          style="box-shadow: 0px -1px 2px 0px #e9eaeb"
          :class="{
            'u-flex-justify-content-space-between': showSelectionCount,
            'u-flex-justify-content-flex-end': !showSelectionCount
          }"
        >
          <span
            v-show="showSelectionCount"
            class="u-font-size-6 u-color-grey-lighter"
            ><span class="u-color-blue-base u-font-weight-600">{{
              tempSelections.length
            }}</span
            ><span> / </span
            ><span data-cy="totalFilterValueCount">{{
              options.length
            }}</span></span
          >
          <span class="u-display-flex u-flex-align-items-center">
            <rb-button
              v-if="!hideClearAllBtn"
              v-show="
                (config.title !== 'Standard Shelf' &&
                  type !== 'EXPRESSION' &&
                  type !== 'ADVANCED_FILTERS' &&
                  !isAlwaysOnFilter) ||
                (type === 'DIGITAL_SHELF' &&
                  config.title === 'Custom Shelf' &&
                  !isAlwaysOnFilter) ||
                type === 'CUSTOM_LISTS'
              "
              class="u-spacing-mr-s"
              :text="'Clear all'"
              :type="'hollow'"
              :size="'s'"
              :disabled="tempSelections.length === 0"
              :click-fn="clearAllFilters"
            />
            <rb-button
              data-cy="filter-apply"
              :text="'Apply'"
              :type="'filled'"
              :size="'s'"
              :disabled="!mutations"
              :click-fn="applyFilters"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import queryBuilder from '@/components/widgets/queryBuilder.vue';
import strategyFilter from '@/components/widgets/filter/strategyFilter.vue';
/* eslint-disable vue/require-default-prop */
import HttpLayer from '@/utils/services/http-layer';
import customShelf from '@/components/widgets/filter/customShelf.vue';
import standardShelf from '@/components/widgets/filter/standardShelf.vue';
import RuleBuilderV2 from '@/components/pages/insights/amazon/share-of-voice/molecules/rule-builder-v2.vue';
import filterSelectAll from '@/components/widgets/filter/filterSelectAll.vue';
import loader from '@/components/basic/loader';
import { filterType, filterTitle } from '@/utils/helpers/filter';
import { generateTruncatedString } from '@/utils/helpers/utils.js';
import {
  asinLevelV2ConfigEnabled,
  isMarketShareLite,
  categoryConstants
} from '@/components/pages/insights/amazon/market-share/utils';
import { cloneDeep } from 'lodash';
import transformer from '@/utils/services/data-transformer.js';

export default {
  components: {
    queryBuilder,
    strategyFilter,
    customShelf,
    standardShelf,
    RuleBuilderV2,
    loader,
    filterSelectAll
  },
  props: {
    showSelectAll: {
      type: Boolean,
      default: false
    },
    getTitle: {
      type: Function,
      default: (item) => {
        return item.title;
      }
    },
    showDynamicTooltip: {
      type: Boolean,
      default: false
    },
    filterListTooltipFn: {
      type: Function,
      default: () => {}
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false
    },
    hideClearAllBtn: {
      type: Boolean,
      default: false
    },
    filterForOperators: {
      type: Boolean,
      default: false
    },
    externalSelectionListener: {
      type: String,
      default: null
    },
    ruleBuilderRoot: {
      type: Object,
      default: () => ({})
    },
    filterV2: {
      type: Boolean,
      default: false
    },
    isRemovable: {
      type: Boolean,
      default: false
    },
    chipSize: {
      type: String,
      default: 'm'
    },
    disableBetweenOperator: {
      type: Boolean,
      default: false
    },
    useLocalStorage: {
      type: Boolean,
      default: true
    },
    defaultFilters: {
      type: Object,
      default() {
        return {
          order: [],
          values: {}
        };
      }
    },
    getter: {
      type: String,
      default: null
    },
    anchor: String,
    data: Array,
    open: Boolean,
    apply: Function,
    multi: {
      type: Boolean,
      default: true
    },
    secondaryData: {
      type: Object
    },
    onCreate: Function,
    filters: {
      type: Object,
      default: function () {
        return {};
      }
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    },
    listenerEvent: {
      type: String,
      default: null
    },
    primaryData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    showNotification: {
      type: Boolean,
      default: true
    },
    isAlwaysOnFilter: {
      type: Boolean,
      default: () => false
    },
    addFilterText: {
      type: String,
      default: () => 'Add Filter'
    },
    enableMinimumSelection: {
      type: Boolean,
      default: false
    },
    selectionsJoiningString: {
      type: String,
      default: ', '
    },
    showNextLineEllipsis: {
      type: Boolean,
      default: false
    },
    maxStringLength: {
      type: Number,
      default: 24
    },
    isDisabledFilter: {
      type: Boolean,
      default: false
    },
    operatorsToFilterOut: {
      type: Array,
      default: () => []
    }
  },
  data() {
    if (this.showDynamicTooltip) {
      var tippyOption = {
        placement: 'right',
        arrow: false,
        interactive: true,
        size: 'regular',
        distance: 5,
        maxWidth: '250px',
        delay: [750, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      };
    } else {
      var tippyOption = {
        placement: 'bottom-start',
        arrow: false,
        interactive: true,
        size: 'regular',
        distance: 5,
        maxWidth: '200px',
        delay: [750, 0]
      };
    }
    return {
      tippyOption: tippyOption,
      filterMetadata: {},
      showBackButton: false,
      mutations: true,
      placeHolder: 'Search',
      options: [],
      isActive: false,
      searchText: '',
      selections: [],
      tempSelections: [],
      smart: true,
      smartFilters: [],
      filtersAppliedOnce: false,
      type: null,
      selectedRules: [],
      tooltipBlacklist: ['campaign_name'],
      showLoader: false,
      getTopValue: {
        top: '0px',
        left: '0px'
      },
      blackListDimensionTypeForV2Read: [
        'EXPRESSION',
        'ADVANCED_FILTERS',
        'MARKET_SHARE_CATEGORISATION'
      ],
      isAllSelected: false,
      isEveryAvailableOptionSelected: false,
      filterId: 0
    };
  },
  computed: {
    showStandardShelf() {
      return (
        (this.type === filterType.digitalShelf &&
          this.config.title === filterTitle.standardShelf) ||
        (this.type === filterType.marketShareCategorisation &&
          this.config.title === filterTitle.marketShareCategory)
      );
    },
    msAsinV2() {
      return asinLevelV2ConfigEnabled();
    },
    standardShelfParams() {
      this.$store.dispatch('resetHierarchyFilterData');
      if (
        this.type === filterType.digitalShelf &&
        this.config.title === filterTitle.standardShelf
      ) {
        return {
          api: 'GET_DIGITAL_SHELF_TAGGING'
        };
      } else {
        return {
          api: isMarketShareLite()
            ? 'MARKETSHARELITE_FILTER'
            : 'MARKETSHARE_FILTER',
          filterDataKey: 'category',
          method: 'post',
          body: {
            page: isMarketShareLite()
              ? categoryConstants.mslPage
              : categoryConstants.page
          },
          config: {
            params: {
              v2: this.msAsinV2
            }
          }
        };
      }
    },
    showSelectionCount() {
      return (
        (this.config.title !== filterTitle.standardShelf &&
          this.type !== filterType.expression &&
          !this.isAlwaysOnFilter &&
          this.type !== filterType.marketShareCategorisation) ||
        (this.type === filterType.digitalShelf &&
          this.config.title === filterTitle.customShelf &&
          !this.isAlwaysOnFilter) ||
        this.type === filterType.customLists
      );
    },
    tippyTitle() {
      const LIMIT = 20;
      const chipTitleSplit = (this.chipTitle || '').split(',');
      if (chipTitleSplit.length <= 20) {
        return this.trimText(chipTitleSplit).join(', ');
      } else {
        const shownTextArray = chipTitleSplit.slice(0, LIMIT);
        return (
          this.trimText(shownTextArray).join(', ') +
          `... & ${chipTitleSplit.length - LIMIT} more`
        );
      }
    },
    getExpressionFilterSpecificMessage() {
      let message = '';
      const unit =
        this.selections[0]?.unit === null ? '' : this.selections[0]?.unit;
      if (this.selections[0]?.operator?.operator === 'BETWEEN') {
        message = ' is between ' + this.selections[0]?.value.replace(',', ' &');
        if (unit === '%') {
          message = message + unit;
        } else {
          message = unit + message;
        }
      } else {
        const value = this.selections[0]?.value || '';
        if (unit === '%') {
          message = value + unit;
        } else {
          message = value === '' ? '' : unit + value;
        }
      }
      return message;
    },
    getSelectedFilter() {
      let selectedFiltersFromStore = {};
      if (this.getter) {
        selectedFiltersFromStore = this.$store.getters[this.getter] || {};
      }
      return selectedFiltersFromStore;
    },
    optionsAfterSearch() {
      const data = this.options;
      const _data = [];
      for (let i = 0; i < data.length; i++) {
        if (
          data[i]?.title
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.searchText.toLowerCase()) !== -1
        ) {
          _data.push(data[i]);
        }
      }
      return _data;
    },
    optionsAfterSearchMinusDisabledDeselections() {
      // This is for getting the total number of options which are present after search and in disabled-not-selected state.
      return this.optionsAfterSearch.filter(
        (item) => item.enable || this.isItemSelected(item.title) === 'selected'
      );
    },
    isAllDisabled() {
      return !this.optionsAfterSearchMinusDisabledDeselections.length;
    },
    isSomeDisabled() {
      return this.optionsAfterSearch.some((item) => !item.enable);
    },
    chipTitle() {
      if (this.config.type === 'STRATEGY') {
        return this.selections
          ?.map((item) => JSON.parse(item).strategyName)
          .join(', ');
      } else if (
        this.config.title === 'Share of Voice' &&
        this.config.type === 'EXPRESSION'
      ) {
        let title = '';
        this.selections.forEach((step) => {
          step.forEach((component) => {
            title += component?.prefix?.text ? component.prefix.text + ' ' : '';
            title += component?.infix?.text ? component.infix.text + ' ' : '';
            title += component?.suffix?.text ? component.suffix.text + ' ' : '';
          });
        });
        return title;
      } else {
        return (
          !this.selections[0]?.operator &&
          this.tooltipBlacklist.indexOf(this.anchor) === -1 &&
          this.selections
            .map((item) => item?.replaceAll('----', ' > '))
            .join(' , ')
        );
      }
    },
    showSelectedFilters() {
      if (this.selections.length > 0) {
        if (this.type === 'STRATEGY') {
          const concatStr = this.selections
            .map((item) => JSON.parse(item).strategyName)
            .join(', ');
          return (
            '<span>' +
            this.config.title +
            ' : ' +
            '</span> <span class="u-color-grey-base">' +
            concatStr +
            '</span>'
          );
        }
        if (
          this.config.title === 'Share of Voice' &&
          this.config.type === 'EXPRESSION'
        ) {
          return `<span>${this.config.title}: </span><span>${this.chipTitle}</span>`;
        }
        const maxStrLen = this.maxStringLength;
        const concatStr = generateTruncatedString(this.selections, maxStrLen);
        return (
          '<span>' +
          this.config.title +
          ' : ' +
          '</span> <span class="u-color-grey-base">' +
          concatStr +
          '</span>'
        );
      }
    },
    isAllChipShown() {
      return (
        ['EXPRESSION', 'ADVANCED_FILTERS'].indexOf(this.type) === -1 &&
        this.isEveryAvailableOptionSelected
      );
    }
  },
  watch: {
    data(val) {
      if (!val || !this.smart) return;
      const _data = this.filterData(val, this.filters);
      this.options = this.sort(_data, 'title');
    },
    config(config) {
      if (config?.config?.dimensionType)
        this.type = this.config.config.dimensionType;
    },
    secondaryData(val) {
      if (!val || this.smart) return;
      const _data = (val[this.anchor] || {}).values || [];
      this.options = this.sort(_data, 'title');
    },
    primaryData(val) {
      if (!val) return;
      this.extractSmartFilters(val);
    },
    isActive(val) {
      Vue.nextTick(
        function () {
          if (this.$refs.searchBox && this.$refs.searchBox.$el) {
            this.$refs.searchBox.$el.children[0].focus();
          }
          if (this.$refs.scrollContainer) {
            this.$refs.scrollContainer.scrollTop = 0;
          }
        }.bind(this)
      );
      if (val === false) {
        if (this.selections.length === 0) {
          this.tempSelections = [];
        }
      }
      setTimeout(
        function () {
          if (
            this.$refs.dropdownMenu &&
            this.$refs.dropdownMenu.closest('#filter__wrapper')
          ) {
            const leftBoundDropDown =
              this.$refs.dropdownMenu &&
              this.$refs.dropdownMenu.getBoundingClientRect().right;
            const leftBoundWrap =
              this.$refs.dropdownMenu &&
              this.$refs.dropdownMenu
                .closest('#filter__wrapper')
                .getBoundingClientRect().right;
            if (leftBoundWrap > leftBoundDropDown) {
              this.getTopValue.left = '0px';
            } else {
              this.getTopValue.left =
                -Math.abs(leftBoundWrap - leftBoundDropDown) + 'px';
            }
          }
        }.bind(this),
        0
      );
      eventBus.$emit('filterStateChanged', val);
    },
    optionsAfterSearchMinusDisabledDeselections() {
      this.checkIfAllSelected();
    },
    selections() {
      this.allChipLogic();
    }
  },
  created() {
    if (this.config && this.config.config && this.config.config.dimensionType) {
      this.type = this.config.config.dimensionType;
    }
    this.extractSmartFilters(this.primaryData);
    let localStoredData = {};
    if (this.useLocalStorage && this.listenerEvent) {
      localStoredData =
        JSON.parse(localStorage.getItem(this.listenerEvent)) || {};
    } else {
      localStoredData = this.defaultFilters.values;
    }
    localStoredData = Object.assign({}, localStoredData);
    if ((localStoredData[this.anchor] || []).length === 0) {
      this.selections = [].concat(
        this.removeSeparator(this.getSelectedFilter[this.anchor] || [])
      );
      this.tempSelections = [].concat(
        this.removeSeparator(this.getSelectedFilter[this.anchor] || [])
      );
    } else {
      this.selections = [].concat(
        this.removeSeparator(localStoredData[this.anchor] || [])
      );
      if (this.selections[0]?.operator) {
        this.selectedRules.push(
          [].concat(this.removeSeparator(localStoredData[this.anchor] || []))
        );
      }
      this.tempSelections = [].concat(
        this.removeSeparator(localStoredData[this.anchor] || [])
      );
    }
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside);
    }

    if (!this.config.smart) {
      this.smart = false;
      if (this.secondaryData) {
        var _data = (this.secondaryData[this.anchor] || {}).values || [];
        this.options = this.sort(_data, 'title');
      }
    } else {
      this.smart = true;
      if (this.data) {
        _data = this.filterData(this.data, this.filters);
        this.options = this.sort(_data, 'title');
      }
    }
    if (this.onCreate) {
      this.onCreate(this);
    }
    if (this.listenerEvent) {
      eventBus.$on(
        this.listenerEvent,
        function (data) {
          this.addFilter(data || {});
        }.bind(this)
      );
    }
    if (this.externalSelectionListener) {
      eventBus.$on(this.externalSelectionListener, (newSelections) => {
        // use case for market insights our external filters set methods was only working the first time, and not the subsequent times, because the data property 'selections' was not changing, externally setiing the same property using this segment of code
        if (this.anchor === newSelections.anchor) {
          this.tempSelections = newSelections.values;
          this.applyFilters();
        }
      });
    }
    // Get data API call
    // this.getFilterDataV2();
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  destroyed() {
    eventBus.$off(this.externalSelectionListener);
    // eventBus.$off(this.listenerEvent);
  },
  methods: {
    removeSeparator(arr) {
      // prevents the esDataSetName from being seen in the filter chip
      return arr?.map((val) => val?.split?.('---')?.[0] || val);
    },
    trimText(arrayOfStrings = []) {
      return arrayOfStrings.map((item) => (item || '').trim());
    },
    handleRuleApply(payload) {
      this.tempSelections = payload;
      this.$emit('ruleApply', payload);
      this.applyFilters();
    },
    getWhereClauseForFilterAPI() {
      const whereClauseObj = {};
      for (let i in this.filters) {
        if (
          !this.filterMetadata[i] ||
          this.blackListDimensionTypeForV2Read.includes(
            this.filterMetadata[i].dimensionType
          )
        ) {
          continue;
        }

        for (let j = 0; j < this.filters[i].length; j++) {
          const filterMetadata = this.filterMetadata[i];
          if (!whereClauseObj[filterMetadata.whereClauseID]) {
            whereClauseObj[filterMetadata.whereClauseID] = {
              entityType: filterMetadata.entityType,
              tagType: filterMetadata.tagType,
              where: {
                dimensionNameValueList: []
              }
            };
          }
          // es requires the dimension column value and not dimenstion name
          const dimensionConfig = this.primaryData.filter(
            (item) => item.dimensionName === i
          );
          const dimensionName = dimensionConfig.length
            ? dimensionConfig[0]?.dimensionColumn?.toLowerCase()
            : i;
          const dimensionValue = this.filters[i][j];

          const obj = {
            ...transformer.addEsDataSetName(dimensionName, dimensionValue),
            operator: 'EQUAL_TO'
          };
          if (obj.dimensionName.indexOf('__') !== -1) {
            obj.dimensionName = obj.dimensionName.split('__')[2];
          }
          whereClauseObj[
            filterMetadata.whereClauseID
          ].where.dimensionNameValueList.push(obj);
        }
      }

      return Object.values(whereClauseObj).filter(
        (item) => item.where.dimensionNameValueList.length > 0
      );
    },
    getFilterDataV2() {
      return new Promise((resolve, reject) => {
        try {
          if (
            !this.anchor ||
            this.config?.v1 ||
            this.blackListDimensionTypeForV2Read.includes(this.type)
          ) {
            return resolve();
          }
          this.showLoader = true;
          const whereClause = this.getWhereClauseForFilterAPI();
          const esDataSetName =
            this.primaryData.length > 0
              ? this.config?.config?.esDataSetName ||
                this.primaryData[0].esDataSetName
              : 'common_filter';
          const anchorFilterMetadata = this.filterMetadata[this.anchor];

          if (
            anchorFilterMetadata?.entityType &&
            anchorFilterMetadata?.tagType
          ) {
            whereClause.push({
              entityType: anchorFilterMetadata.entityType,
              tagType: anchorFilterMetadata?.tagType,
              where: {}
            });
          }
          // Elastic search expects only lower case value for dimension column.
          let lowerCaseDimensionColumn =
            this.config.config.dimensionName.toLowerCase();

          if (
            Vue.options.filters.config_check('feature.campaignListFix') &&
            anchorFilterMetadata.tagType === 'custom'
          ) {
            lowerCaseDimensionColumn =
              this.config.config.dimensionColumn.toLowerCase();
          }
          const postData = {
            esDataSetName: esDataSetName,
            entityType: anchorFilterMetadata.entityType,
            tagType: anchorFilterMetadata.tagType,
            enableGreyout: true,
            enableNextHierarchyIndicator: false,
            // 'dimensionsList': [this.anchor],
            dimensionsList: [lowerCaseDimensionColumn],
            whereClause
          };
          HttpLayer.post({
            cube: 'FILTER_DATA_V2',
            APIData: postData
          })
            .then((response) => {
              this.showLoader = false;
              if (response.success) {
                const result = [];
                response.data.map((item) => {
                  result.push({
                    title: item[lowerCaseDimensionColumn],
                    enable: !item.isGreyout
                  });
                });
                this.options = result;
              } else {
                this.options = [];
              }
              resolve();
              // var data = transformer.mergeResultDimension(response.data);
              // that.filterData = data;
              // that.primaryFilterData = ((response.fullResponse.metadata || {}).dimensionMappingData || []);
            })
            .catch((e) => {
              reject(e);
            });
        } catch (e) {
          this.showLoader = false;
          throw e;
        }
      });
    },
    goToPrimaryFilterPanel(event) {
      this.isActive = false;
      this.showBackButton = false;
      this.$emit('back');
      event.stopPropagation();
      // this?.$refs?.queryBuilder?.clearInputValues();
    },
    saveCustomDigitalShelfFilters(selections) {
      this.tempSelections = selections;
      this.mutations = true;
    },
    saveStandardDigitalShelfFilters(selections) {
      this.tempSelections = selections;
      this.mutations = !!selections.length;
    },
    customDataFetched(data) {
      this.options = data;
    },
    queryFilters(selection) {
      this.query = selection;
      if (typeof selection === 'object' && selection !== null) {
        this.tempSelections = [selection];
        if (
          Array.isArray(selection) &&
          selection[0] &&
          selection[0]?.operator
        ) {
          this.tempSelections = selection.slice(0);
        }
      } else {
        this.tempSelections = [Object.values(selection)];
      }
      this.mutations = true;
      if (selection.mutations !== undefined && selection.mutations !== null) {
        this.mutations = selection.mutations;
      }
      if (this.tempSelections[0]?.operator) {
        this.selectedRules = [];
        this.selectedRules.push(selection || []);
      }
    },
    extractSmartFilters(val) {
      for (let i = 0; i < (val || []).length; i++) {
        this.filterMetadata[
          transformer.addEsDataSetNameAsIdentifier(
            this.$route?.meta?.appendEsDataSetName,
            val[i].dimensionName,
            val[i]?.esDataSetName
          )
        ] = {
          entityType: val[i].entityType,
          tagType: val[i].tagType,
          whereClauseID: val[i].entityType + '--' + val[i].tagType,
          dimensionType: val[i].dimensionType
        };
      }
      const _data = (val || [])
        .filter((obj) => {
          return obj.smart !== false;
        })
        .map((obj) => {
          return obj.dimensionName;
        });
      this.smartFilters = _data;
    },
    clearAllFilters() {
      this.mutations = true;
      this.tempSelections = [];
      this.isAllSelected = false;
      this.$emit('clearAllSelection', true);
    },
    openFilter(bShowBackButton) {
      Vue.nextTick(
        function () {
          this.toggle(bShowBackButton);
        }.bind(this)
      );
    },
    addFilter(val) {
      for (const i in val) {
        if (i !== this.anchor) {
          this.filters[i] = val[i];
        }
      }
      if (this.smart) {
        const _data = this.filterData(this.data, this.filters);
        this.options = this.sort(_data, 'title');
      }
    },
    isItemSelected(title) {
      return this.tempSelections.indexOf(title) !== -1 ? 'selected' : 'empty';
    },
    filterData(val, filters) {
      const _data = [];
      const enableState = {};
      for (var i = 0; i < val.length; i++) {
        if (val[i][this.anchor] && val[i][this.anchor].length > 0) {
          let found = true;
          for (const k in filters) {
            if (
              this.smartFilters.indexOf(k) !== -1 &&
              filters[k] &&
              filters[k].length > 0 &&
              filters[k].indexOf(val[i][k]) === -1
            ) {
              found = false;
            }
            if (
              filters[k] &&
              filters[k].length > 0 &&
              filters[k][0].constructor === Object
            ) {
              found = true;
            }
          }
          if (found) {
            enableState[val[i][this.anchor]] = true;
          } else {
            if (enableState[val[i][this.anchor]] !== true) {
              enableState[val[i][this.anchor]] = false;
            }
          }
        }
      }
      for (i in enableState) {
        const index = this.selections.indexOf(i);
        if (index !== -1 && !enableState[i]) {
          // this.selections.splice(index, 1);
          enableState[i] = true;
        }
        _data.push({
          title: i,
          enable: enableState[i]
        });
      }
      return _data;
    },
    clearUpdates() {
      this.tempSelections = [];
    },
    removeSelections(event) {
      this.tempSelections = [];
      this.selections = [];
      if (this.config.type === 'SOV') {
        this.$emit('ruleApply', []);
      }
      let showSnackbar = true;
      if (event && event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      } else {
        showSnackbar = false;
      }
      this.applyFilters(showSnackbar);
      // to reset the tooltip
      this.resetTippy();
    },
    // changing the key on every filter removal so that tippy will get reinitialised.
    resetTippy() {
      if (this.isAlwaysOnFilter && this.selections.length === 0) {
        this.filterId = this.filterId + 1;
      }
    },
    applyFilters(showSnackbar) {
      this.selections = cloneDeep(this.tempSelections);
      if (!this.selections.length) {
        this.resetTippy();
      }
      let message = '"' + this.config.title + '" filter applied';
      if (this.selections.length === 0) {
        message = '"' + this.config.title + '" filter removed';
      }
      if (showSnackbar !== false && this.showNotification) {
        setTimeout(
          function () {
            this.$snackbar.open({
              message,
              duration: 2000,
              buttonColor: '#f5d908',
              actionText: '              '
            });
          }.bind(this),
          1000
        );
      }
      const data = this.config;
      data.selections = this.selections;
      data.simulate = this.config?.config?.simulate;
      if (this.apply) {
        this.apply(data);
      }
      if (this.selections.length > 0) {
        this.toggle();
      }

      if (this.selections.length === 0 && this.isAlwaysOnFilter) {
        this.isActive = false;
      }
    },

    itemClick(item) {
      this.mutations = true;
      if (this.multi) {
        let index;
        if (this.config.type === 'STRATEGY') {
          index = this.tempSelections.findIndex((strategyJsonString) => {
            return (
              JSON.parse(strategyJsonString || {}).strategyUniqueId ===
              JSON.parse(item.title).strategyUniqueId
            );
          });
        } else {
          index = this.tempSelections.indexOf(item.title);
        }
        if (index === -1) {
          this.tempSelections.push(item.title);
        } else {
          this.tempSelections.splice(index, 1);
        }
      } else {
        this.tempSelections = [item.title];
      }
      this.checkIfAllSelected();
    },
    fetchDataIfActive() {
      if (this.isActive && this.filterV2) {
        this.getFilterDataV2();
      }
    },
    toggle(bShowBackButton) {
      if (this.isDisabledFilter) {
        return;
      }
      this.mutations = false;
      this.isActive = !this.isActive;
      this.showBackButton = bShowBackButton === true ? bShowBackButton : false;
      if (!this.isActive) {
        this.restoreIncompleteEventValues();
        this.clearUpdates();
      } else {
        this.tempSelections = [].concat(this.selections);
      }
      this.fetchDataIfActive();
      this.checkIfAllSelected();
      // removing input valuse after toggling cauising an issue with strat build, https://boomerang.atlassian.net/browse/PROD-112367
      // if (!this.isActive) {
      //   this?.$refs?.queryBuilder?.clearInputValues();
      // }
    },
    sort(data, key) {
      return data.sort(function (item1, item2) {
        const text1 = ((item1 || {})[key] || '').toString().toLowerCase();
        const text2 = ((item2 || {})[key] || '').toString().toLowerCase();
        return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
      });
    },
    isInWhiteList(el) {
      let returnValue = false;
      document.querySelectorAll('.tippy-popper').forEach((popper) => {
        if (popper._tippy.state.visible) {
          returnValue = true;
        }
      });
      if (returnValue) {
        return true;
      }
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      if (this.$refs.dropdownMenu !== undefined) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      if (this.$refs.trigger !== undefined) {
        const children = this.$refs.trigger.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      // select__dropdown-list-item is active only when dropdown is open.
      const whiteListClasses = ['query-builder', 'select__dropdown-list-item'];
      if (
        el?._prevClass &&
        whiteListClasses.some((item) => el?._prevClass.includes(item))
      ) {
        return true;
      }
      return false;
    },
    restoreIncompleteEventValues() {
      const localStoredData =
        JSON.parse(localStorage.getItem(this.listenerEvent)) || {};
      if (this.anchor && localStoredData?.[this.anchor]?.[0]?.operator) {
        if (localStoredData[this.anchor][0]?.operator?.operator === 'BETWEEN') {
          this.selections[0].value1 = localStoredData[this.anchor][0].value1;
          this.selections[0].value2 = localStoredData[this.anchor][0].value2;
        }
        this.selections[0].value = localStoredData[this.anchor][0].value;
        const nullOperators = ['IS_NOT_NULL', 'IS_NULL'];
        if (
          nullOperators.includes(
            localStoredData[this.anchor][0]?.operator.operator
          ) ||
          nullOperators.includes(this.selections[0]?.operator?.operator)
        ) {
          this.selections[0] = localStoredData[this.anchor][0];
        }
      }
    },
    clickedOutside(event) {
      if (this.inline) return;
      if (!this.isInWhiteList(event.target)) {
        this.isActive = false;
        this.restoreIncompleteEventValues();
        this.clearUpdates();
        // this?.$refs?.queryBuilder?.clearInputValues();
      }
    },
    handleFilterSelectAll() {
      this.mutations = true;
      if (
        this.searchText &&
        this.optionsAfterSearchMinusDisabledDeselections.length ===
          this.filterTempSelectionBySearchTerm().length
      ) {
        const optionTitles =
          this.optionsAfterSearchMinusDisabledDeselections.map((item) =>
            item?.title?.toLowerCase()
          );
        this.tempSelections = this.tempSelections.filter(
          (item) => !(optionTitles.indexOf(item.toLowerCase()) !== -1)
        );
      } else if (
        this.optionsAfterSearchMinusDisabledDeselections.length ===
        this.tempSelections.length
      ) {
        this.tempSelections = [];
      } else {
        this.optionsAfterSearchMinusDisabledDeselections.forEach((item) => {
          if (this.tempSelections.indexOf(item.title) === -1) {
            this.tempSelections.push(item.title);
          }
        });
      }
      this.checkIfAllSelected();
    },
    checkIfAllSelected() {
      if (this.enableMinimumSelection) {
        if (this.tempSelections.length === 0) {
          this.mutations = false;
        }
      }
      if (!this.optionsAfterSearchMinusDisabledDeselections.length) {
        return false;
      }
      if (this.searchText) {
        const optionsFilteredBySearchTerm =
          this.optionsAfterSearchMinusDisabledDeselections.filter(
            (item) =>
              item?.title
                ?.toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1
          );
        this.isAllSelected =
          this.filterTempSelectionBySearchTerm().length ===
          optionsFilteredBySearchTerm.length;
      } else {
        this.isAllSelected =
          this.tempSelections.length ===
          this.optionsAfterSearchMinusDisabledDeselections.length;
      }
    },
    filterTempSelectionBySearchTerm() {
      return this.tempSelections.filter(
        (title) =>
          title?.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
      );
    },
    async allChipLogic() {
      // The options are being set to empty array when the filter is closed.
      // Chip logic needs options to calculated even while filter isn't opened, to be able to calculate isEveryAvailableOptionSelected.
      try {
        if (this.filterV2 && !this.options.length && !this.isActive) {
          await this.getFilterDataV2();
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (!this.options.length) {
          this.isEveryAvailableOptionSelected = false;
        } else {
          this.isEveryAvailableOptionSelected =
            this.options.length === this.selections.length;
        }
      }
    },
    getIcon(title) {
      return this.multi
        ? 'checkbox-' + this.isItemSelected(title)
        : 'radio-' + this.isItemSelected(title);
    },
    getIconClass(title) {
      return this.isItemSelected(title) === 'selected'
        ? 'u-color-blue-base'
        : 'u-color-grey-lighter';
    },
    disabledTooltipText(enable) {
      if (!enable) {
        return 'There is no data available for the combination of this filter and the other selected filters.';
      }
    },
    checkForLongTitle(title) {
      return title && title.length > 44;
    }
  }
};
</script>
<style lang="css" scoped>
.no-selection {
  border: 1px dashed #ebeef6;
}
.filter-dropdown-menu {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  position: absolute;
  overflow: auto;
}

.filter-dropdown-content {
  max-height: 304px;
  min-height: 136px;
  overflow: auto;
  padding: 0px 0px 8px;
}

.filter-dropdown-content-custom {
  max-height: 304px;
  min-height: 136px;
}

.filter--open {
  width: auto;
  z-index: 100;
  min-width: 100px;
}

.filter-container,
.filter-container-select-all {
  overflow: auto;
  width: 100%;
}

.filter-container {
  padding-top: 8px;
}
.rb-filter {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  line-height: 0;
  min-height: 24px;
}

.filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
  color: #fff;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
}
.filter-list__item.active {
  background: #007cf6;
}
.filter-list__item.active p,
.filter-list__item.active span {
  color: white;
}
.filter-list__item.disabled {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
.u-text-overflow-ellipsis-next-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>
