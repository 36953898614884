<template>
  <div>
    <div
      class="u-font-size-6 u-display-flex u-color-grey-base u-flex-direction-row u-border-radius-l u-cursor-pointer split-tabs"
      :class="{ 'u-border-radius-xxl': isTabsList || size === 'm' }"
    >
      <div
        v-if="!isTabsList"
        class="u-display-flex"
      >
        <div
          class="u-text-align-center"
          :class="{
            'isHighlighted tabBoxShadow': tab1Active,
            'hover-inactive': !tab1Active,
            'u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s': size === 'm',
            'u-border-radius-xl u-spacing-ph-m u-spacing-pv-xs': size === 's'
          }"
          @click="tabClicked(tab1)"
        >
          <slot name="tag1-pre" />
          <span>{{ tab1 }}</span>
        </div>
        <div
          class="u-border-radius-xl u-spacing-ph-l u-spacing-pv-s u-text-align-center"
          :class="{
            'isHighlighted tabBoxShadow': !tab1Active,
            'hover-inactive': tab1Active,
            'u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s': size === 'm',
            'u-border-radius-xl u-spacing-ph-m u-spacing-pv-xs': size === 's'
          }"
          @click="tabClicked(tab2)"
        >
          <span>{{ tab2 }}</span>
        </div>
      </div>

      <section
        v-for="tab in tabsList"
        v-if="isTabsList"
        :key="tab"
      >
        <div
          class="u-border-radius-xxl u-spacing-ph-l u-spacing-pv-s u-text-align-center"
          :class="{
            'isHighlighted tabBoxShadow': computedActiveTab === tab,
            'hover-inactive': computedActiveTab !== tab
          }"
          @click.stop="tabClicked(tab)"
        >
          <span :data-cy="'tab-' + tab">{{ tab }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab1: {
      type: String,
      default: 'tab1'
    },
    tab2: {
      type: String,
      default: 'tab2'
    },
    tabsList: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 's'
    },
    isTabsList: Boolean,
    activeTab: {
      type: String,
      default: null
    }
  },
  data: () => ({
    tab1Active: true,
    localActiveTab: ''
  }),
  computed: {
    computedActiveTab() {
      return this.localActiveTab;
    }
  },
  watch: {
    activeTab(newValue) {
      this.localActiveTab = newValue;
    }
  },
  mounted() {
    this.localActiveTab = this.activeTab;
  },
  methods: {
    tabClicked(tab) {
      if (this.isTabsList) {
        this.localActiveTab = tab;
      } else {
        this.tab1Active = tab === this.tab1;
      }
      this.$emit('tabClicked', tab);
    }
  }
};
</script>

<style lang="css" scoped>
.tabBoxShadow {
  box-shadow: 0 2px 4px 0 rgba(43, 51, 59, 0.15);
}
.split-tabs {
  background: #f5f7fa;
  line-height: 0.73;
}
.hover-inactive:hover {
  background: #e9eaeb;
}

.u-border-radius-xxl {
  border-radius: 24px;
}
</style>
