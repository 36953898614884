<template>
  <div
    :class="[
      'u-display-flex u-width-100 u-flex-align-items-center u-spacing-p-s u-cursor-pointer filter-list__item',
      { disabled: isAllDisabled }
    ]"
    @click="handleCheckboxClick"
  >
    <p
      class="u-font-weight-normal u-spacing-pl-s u-line-height-1 u-text-overflow-ellipsis u-font-size-5 u-width-100"
      data-cy="isAllSelected"
    >
      <span class="u-font-weight-600 u-color-blue-base">
        {{ isAllSelected ? deSelectAllText : selectAllText }}
      </span>
      <span
        v-if="isSomeDisabled && !searchText"
        class="u-color-grey-lighter"
        >available</span
      >
      <span
        v-if="searchText"
        class="u-color-grey-lighter"
        data-cy="searchInFilter"
      >
        <span>{{ optionsAfterSearch.length }}</span>
        <span v-if="isSomeDisabled"> available</span>
        with <i><span>&#8220;</span>{{ searchText }}<span>&#8221;</span></i>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isAllSelected: {
      type: Boolean,
      default: false
    },
    isAllDisabled: {
      type: Boolean,
      default: false
    },
    optionsAfterSearch: {
      type: Array,
      default: function () {
        return [];
      }
    },
    searchText: {
      type: String,
      default: ''
    },
    selectAllText: {
      type: String,
      default: '(Select All)'
    },
    deSelectAllText: {
      type: String,
      default: '(Deselect All)'
    },
    handleFilterSelectAll: {
      type: Function,
      default: function () {}
    },
    isSomeDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCheckboxClick() {
      this.$emit('handleFilterSelectAll');
    }
  }
};
</script>
