<template>
  <div class="sku-info-holder u-display-flex">
    <div
      class="u-spacing-mr-m u-width-100"
      :class="{ 'u-height-100': isLoading }"
    >
      <div
        class="card u-display-flex u-width-100"
        :class="{ 'u-height-100': showError }"
      >
        <section
          v-if="!isLoading && !showError"
          class="u-width-100"
        >
          <section class="u-spacing-m-l u-spacing-mb-0">
            <div class="sku-image-holder u-flex-complete-center">
              <img
                v-if="getDataValue('image_url')"
                :src="getDataValue('image_url')"
                class="u-spacing-p-s u-width-100"
              />
            </div>
            <div class="u-spacing-mt-s">
              <span
                class="u-font-size-4 u-font-weight-600 u-color-grey-base u-line-height-1-3"
                >{{ getDataValue('title') }}</span
              >
            </div>
            <div class="u-spacing-mt-s">
              <a
                v-tippy="tippyOptions"
                :href="getDataValue('product_url')"
                target="_blank"
                class="u-font-size-4 u-color-grey-lighter u-cursor-pointer asin_url"
                title="View Amazon detail page"
                rel="noopener"
                >{{ data.asin }}</a
              >
            </div>
            <div
              v-if="getDataValue('variant_info')"
              class="u-spacing-mt-m"
            >
              <div>
                <div class="u-spacing-mb-s">
                  <span
                    v-for="(value, key) in getDataValue('variant_info')"
                    :key="value"
                    class="u-font-size-4 u-spacing-mr-s u-spacing-mb-xs u-display-block"
                  >
                    <span
                      class="u-color-grey-lighter u-text-transform u-font-size-5"
                      >{{ key }}:</span
                    >
                    <span
                      class="u-color-grey-base u-text-transform u-font-size-5 u-font-weight-600"
                    >
                      {{ value }}
                    </span>
                  </span>
                </div>
                <rb-select
                  v-if="
                    getDataValue('other_variants') &&
                    getDataValue('other_variants').length > 0
                  "
                  :width="'260px'"
                  class="u-spacing-mr-m"
                  :send-details="true"
                  :on-close="onVariantClose"
                  :options="getDataValue('other_variants')"
                  :class-name="'u-spacing-pt-0'"
                  :search-enable-threshold="200"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <span
                        class="u-font-size-6 asin_url"
                        style="
                          font-size: 11px;
                          font-weight: 600;
                          color: #007cf6;
                        "
                        >View
                        {{ getDataValue('other_variants').length }}
                        other variants</span
                      >
                    </div>
                  </div>
                  <template slot="header">
                    <div
                      class="u-bg-color-grey-light u-color-grey-white u-spacing-p-m"
                    >
                      <span class="u-color-white u-font-size-5"
                        >Other Variants</span
                      >
                      <!-- <span @click="onVariantClose">
                        <rb-icon
                          class="rb-icon--small"
                          :icon="'cross'"
                        ></rb-icon>
                      </span> -->
                    </div>
                  </template>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <rb-sku
                      :image-url="option.image_url"
                      :title="option.title"
                      :asin="option.child_sku"
                      :product-page-url="option.product_url"
                      :enable-s-d-p="false"
                      @navigateToSDP="navigateToSDP"
                    >
                      <template slot="sku-context">
                        <span
                          v-for="(value, key) in option.variant_json"
                          :key="value"
                          class="u-spacing-mr-s"
                        >
                          <span class="u-color-grey-lighter u-text-transform"
                            >{{ key }}:</span
                          >
                          <span class="u-color-grey-base">
                            {{ value }}
                            <span>•</span>
                          </span>
                        </span>
                      </template>
                    </rb-sku>
                  </template>
                </rb-select>
              </div>
            </div>
            <div class="u-display-flex u-flex-align-items-center">
              <div
                v-if="
                  getDataValue('events') && getDataValue('events').length > 0
                "
                class="u-spacing-mt-l"
              >
                <chips :chips="getDataValue('events')" />
              </div>
              <div
                v-if="distributionType && distributionType.length > 0"
                class="u-spacing-mt-l"
              >
                <chips :chips="distributionType" />
              </div>
            </div>
            <div
              class="u-display-flex u-flex-complete-center"
              :class="{
                'u-spacing-mt-l':
                  getDataValue('offer_price') ||
                  getDataValue('avg_product_rating') ||
                  getDataValue('review_count')
              }"
            >
              <span
                v-if="getDataValue('offer_price')"
                class="u-font-size-4 u-color-red-base"
              >
                {{
                  getDataValue('offer_price')
                    | num_format(
                      'currency',
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      selectedCurrency
                    )
                }}
              </span>
              <span
                v-if="getDataValue('offer_price')"
                class="dot"
                >•</span
              >
              <rating-stars
                v-if="
                  getDataValue('avg_product_rating') ||
                  getDataValue('review_count')
                "
                :star="getDataValue('avg_product_rating')"
                :count="getDataValue('review_count')"
              />
            </div>
            <div
              v-if="
                getDataValue('badges') && getDataValue('badges').length !== 0
              "
              class="u-display-flex u-spacing-mt-m u-flex-wrap-yes"
            >
              <div
                v-for="(val, index) in getDataValue('badges')"
                :key="index"
                class="u-display-flex u-flex-align-items-center u-spacing-mr-s u-spacing-mt-s"
                style="width: 102px; height: 18px"
              >
                <img
                  :src="'/sku-images/' + val.name + '.png'"
                  class="u-width-100 u-height-100"
                  style="object-fit: contain"
                />
              </div>
            </div>
            <div class="u-spacing-mt-s u-display-flex">
              <list-view :list="getDataValue('infoList')" />
            </div>
          </section>
          <div
            class="u-spacing-mt-m u-flex-justify-content-flex-start u-display-flex u-border-top u-border-color-grey-xxx-light u-color-grey-x-light u-border-width-s u-font-size-6 card-footer u-spacing-p-m u-spacing-ml-s"
          >
            <span
              >Last Updated :
              <span class="u-color-grey-dark">{{
                formatter(metaDataConfig.calendar.max_date, 'date')
              }}</span>
            </span>
          </div>
        </section>
        <section
          v-if="isLoading"
          class="u-spacing-m-l u-width-100 u-display-flex isLoadingWidget"
          style="display: flex"
        >
          <loader
            class="fill--parent u-bg-color-grey-white"
            :color="'#007cf6'"
          />
        </section>
        <section
          v-if="!isLoading && showError"
          class="u-spacing-m-l u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
        >
          <div class="u-font-size-4">No Data.</div>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped lang="css">
.u-text-transform {
  text-transform: capitalize;
}
.sku-image-holder {
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  height: 300px;
  border-color: #e9eaeb;
}
.sku-image-holder img {
  height: 300px;
  object-fit: contain;
}
.dot {
  color: #2b333b;
  font-size: 1.8rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.badges-image {
  width: 65px;
}
</style>
<script>
import chips from '@/components/basic/chips.vue';
import ratingStars from '@/components/basic/ratingStars.vue';
import listView from '@/components/basic/listView.vue';
import { formatter } from '@/utils/helpers/formatter.js';
import HttpService from '@/utils/services/http-service';
import { transformMetaData, mergeResponseForSKUDetails } from './helper.js';
import loader from '@/components/basic/loader';
import Vue from 'vue';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { eventBus } from '@/utils/services/eventBus';

export default {
  components: {
    chips,
    'rating-stars': ratingStars,
    'list-view': listView,
    loader
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    skuID: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      _skuID: '',
      isLoading: false,
      metaDataConfig: {},
      metricsList: [],
      userConfigList: [],
      data: {},
      badges: [],
      infoList: [],
      showError: false,
      APITemplate: {},
      distributionType: [],
      tippyOptions: {
        theme: 'page-snapshot',
        duration: [0, 0],
        placement: 'right',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: true
            }
          }
        }
      },
      metricKeyNameMapping: {}
    };
  },
  computed: {
    selectedCurrency() {
      return this.$store?.getters?.getSelectedCurrency || {};
    }
  },
  watch: {
    skuID(newValue) {
      this._skuID = newValue;
      this.init();
    }
  },
  created() {
    this._skuID = this.skuID;
    this.init();
    eventBus.$on('catalogAsinDataReceived', () => {
      this.getDistributionType();
    });
  },
  destroyed() {
    eventBus.$off('catalogAsinDataReceived');
  },
  methods: {
    formatter: formatter,
    init() {
      this.isLoading = true;
      // fetch the metadata information
      const requests = [];
      this.config.API.forEach((api) => {
        requests.push(
          HttpService.post(
            api.service,
            { widget: 'skuDetailInfo', id: this._skuID, page: this.page },
            { append: api.endPoint }
          )
        );
      });
      HttpService.all(requests).then(
        (responses) => {
          responses.forEach((response) => {
            this.metaDataConfig = JSON.parse(JSON.stringify(response.data));
            const blackList = ['sdp_sku_level_cube__is_campaign_exist'];
            this.userConfigList = [
              ...this.metaDataConfig.metadata.defaultConfig
            ];
            if (Vue.options.filters.config_check('feature.product.ams_lite')) {
              this.userConfigList = this.userConfigList.filter((item) => {
                if (blackList.indexOf(item) === -1) {
                  return item;
                }
              });
            }
            this.metricKeyNameMapping =
              this.metaDataConfig?.metadata?.metricKeyNameMapping || {};
            this.metricsList = transformMetaData(
              response.data.metrics
            ).metricList;
            this.APITemplate = this.metricsList[0].api;
          });
          this.prepareDataRequest();
        },
        (error) => {
          this.showError = false;
          this.isLoading = false;
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
      );
    },
    onVariantClose(context, values) {
      if (values && values.length > 0) {
        localStorage.removeItem(`SDP-calendar-web${this._skuID}`);
        this.$store.dispatch('fetchUserPageMetadata');
        this.$router.push({ path: `/dp/${values[0].child_sku}` });
      }
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    },
    prepareDataRequest() {
      const measureList = [];
      measureList.push(...this.metaDataConfig.metadata.staticConfig);
      measureList.push(...this.userConfigList);
      const opetations = this.metricsList[0].api.request.operations;
      const date = {
        from: this.metaDataConfig.calendar.min_date,
        to: this.metaDataConfig.calendar.max_date,
        name: 'skuDetailInfo',
        page_wise_min_max_key: 'skuDetailInfo'
      };
      const APITemplate = { ...this.APITemplate };
      APITemplate.request.operations = { ...opetations };
      APITemplate.request.metricsList = [...measureList];
      APITemplate.request.where.dimensionNameValueList = [];
      APITemplate.request.where.date = { ...date };
      APITemplate.request.where.pvpDate = {};
      APITemplate.request.page = 'sku_details';
      APITemplate.request.entityValue = this.$route.params.id;
      APITemplate.request.entityType = 'asin';
      APITemplate.request.eventsList = [];
      this.fetchData(APITemplate);
    },
    fetchData(api) {
      this.isLoading = true;
      const _that = this;
      HttpService.post(
        api.service,
        { ...api.request },
        { append: '/entity/metrics/data' }
      ).then(
        (response) => {
          // Merge the response.
          const mergedResponseArray = response.data.entityData.reduce(
            (acc, item) => {
              if (item.success) {
                acc.push(...item.data);
              }
              return acc;
            },
            []
          );
          _that.data = mergeResponseForSKUDetails(mergedResponseArray) || {};
          if (Object.keys(_that.data).length === 0) {
            _that.showError = true;
            // _that.$snackbar.open({
            //   message: 'Something Went Wrong !!',
            //   duration: 5000,
            //   buttonColor: '#f5d908',
            //   actionText: ' '
            // });
          } else {
            const badge = [
              'is_amazon_choice',
              'is_prime',
              'is_sns',
              'is_prime_pantry',
              'is_bestseller'
            ];
            const events = ['is_oos', 'is_instock'];
            _that.data.infoList = _that.userConfigList.map((item) => {
              const newList = {};
              const metricObj =
                _that.metricsList.filter((metric) => {
                  return metric.name === item;
                })[0] || {};
              newList.labelText = metricObj.label;
              if (!(metricObj.metadata && metricObj.metadata.unit)) {
                newList.value = formatter(
                  _that.data[metricObj.keyName],
                  metricObj.metadata.type,
                  undefined,
                  undefined,
                  undefined,
                  this.selectedCurrency
                );
              } else {
                newList.value = formatter(
                  _that.data[metricObj.keyName],
                  metricObj.metadata.unit,
                  undefined,
                  undefined,
                  undefined,
                  this.selectedCurrency
                );
              }
              return newList;
            });
            _that.data.badges = badge
              .filter((item) => {
                if (_that.data[item] === 'true') {
                  return item;
                }
              })
              .map((item) => {
                const newList = {};
                newList.name = item;
                newList.height = '80px';
                return newList;
              });
            if (_that.data.variant_info) {
              _that.data.variant_info = JSON.parse(_that.data.variant_info);
            }
            if (_that.data.other_variants) {
              _that.data.other_variants = JSON.parse(_that.data.other_variants);
              _that.data.other_variants = _that.data.other_variants.map(
                (item) => {
                  const tempObj = JSON.parse(item);
                  if (tempObj.variant_json) {
                    tempObj.variant_json = JSON.parse(tempObj.variant_json);
                  }
                  return tempObj;
                }
              );
            }
            _that.data.events = events.reduce((acc, item) => {
              if (_that.data[item] === 'true') {
                const newList = {};
                newList.labelText = _that.metaDataConfig.metrics[item].label;
                newList.impact =
                  _that.data[item] === 'true' ? 'POSITIVE' : 'NEGATIVE';
                newList.class =
                  _that.data[item] === 'true'
                    ? 'u-bg-color-green-base'
                    : 'u-bg-color-red-base';
                acc.push(newList);
              }
              return acc;
            }, []);
          }
          _that.getDistributionType();
          _that.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.showError = true;
          this.data = {};
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
      );
    },
    getDistributionType() {
      const asin = this.data.asin ?? this._skuID;
      const targetPage = 'SDP';
      this.$store
        .dispatch('getDistributionType', { asin, targetPage })
        .then((response) => {
          this.distributionType = response;
        });
    },
    getDataValue(key) {
      const metricName = this.metricKeyNameMapping[key] ?? key;
      return this.data[metricName];
    }
  }
};
</script>
<style lang="css" scoped>
.asin_url:hover {
  text-decoration: underline;
}
.isLoadingWidget {
  height: 100vh;
}
</style>
