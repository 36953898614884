var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recommendation--detail-view u-display-flex u-flex-direction-column u-height-100 u-overflow-auto"},[_c('div',{staticClass:"section--top u-flex-none u-display-flex u-flex-align-items-center u-spacing-pv-m u-position-relative"},[_c('span',{staticClass:"u-spacing-mr-s back-arrow",on:{"click":function($event){$event.stopPropagation();return _vm.deselectAlert()}}},[_c('rb-icon',{staticClass:"rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer",attrs:{"icon":'arrow_back'}})],1),(this.fetchedBcRecommendationType === 'asin')?_c('div',[_c('rb-sku',{staticClass:"u-text-overflow-ellipsis",attrs:{"title":_vm.selectedAlert.attributes.title,"image-url":_vm.selectedAlert.attributes.image_url,"asin":_vm.selectedAlert.attributes.asin,"product-page-url":_vm.selectedAlert.attributes.product_url,"enable-s-d-p":_vm.enableSDP},on:{"navigateToSDP":_vm.navigateToSDP}},[_c('div',{staticClass:"u-display-flex u-font-size-7 u-text-overflow-ellipsis",attrs:{"slot":"sku-context"},slot:"sku-context"},[(_vm.selectedAlert.attributes.recency == 0)?_c('span',[_vm._v(_vm._s(_vm.$t('new')))]):_vm._e(),(
              _vm.selectedAlert.attributes.recency !== 0 &&
              !(
                _vm.selectedAlert.appName &&
                _vm.selectedAlert.appName !== 'MarketingIQ'
              )
            )?_c('span',[_vm._v(_vm._s(_vm.selectedAlert.attributes.recency)+" times")]):_vm._e(),(
              _vm.selectedAlert.attributes.mean_estimate_value &&
              _vm.showPerformanceOverview
            )?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"u-color-blue-base",attrs:{"title":_vm.$t('mean_estimate_value')}},[(
                _vm.selectedAlert.attributes.recency == 0 ||
                !(
                  _vm.selectedAlert.appName &&
                  _vm.selectedAlert.appName !== 'MarketingIQ'
                )
              )?_c('span',[_vm._v(" · ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.selectedAlert.attributes.mean_estimate_value,'currency'))+" ")]):_vm._e(),(_vm.selectedAlert.attributes.cached_html_link)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions),expression:"tippyOptions"}],staticClass:"u-display-flex",attrs:{"title":_vm.$t('page_snapshot_tooltip')}},[(
                _vm.selectedAlert.attributes.recency == 0 ||
                !(
                  _vm.selectedAlert.appName &&
                  _vm.selectedAlert.appName !== 'MarketingIQ'
                ) ||
                (_vm.selectedAlert.attributes.mean_estimate_value &&
                  _vm.showPerformanceOverview)
              )?_c('span',[_vm._v(" · ")]):_vm._e(),_c('a',{staticClass:"link--asin u-color-grey-lighter",attrs:{"href":_vm.selectedAlert.attributes.cached_html_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Cached Page")])]):_vm._e()])])],1):_vm._e(),(this.fetchedBcRecommendationType === 'search')?_c('div',{staticClass:"u-spacing-pv-m u-font-weight-600 u-font-size-2"},[_vm._v(" Keyword: “"+_vm._s(_vm.selectedAlert.attributes.search)+"” ")]):_vm._e(),(this.fetchedBcRecommendationType === 'brand')?_c('div',[_c('div',{staticClass:"u-spacing-pb-s u-font-weight-600 u-font-size-2"},[_vm._v(" "+_vm._s(_vm.selectedAlert.attributes.bc_brand_alert_type)+" ")]),_c('div',{staticClass:"u-color-grey-lighter u-font-size-5"},[_vm._v(" "+_vm._s(_vm.selectedAlert.attributes.bc_category_alert_type)+" ")])]):_vm._e()]),_c('div',{staticClass:"u-flex-1 section--details u-envelop-width u-width-100"},[_c(_vm.selectedComponent,{tag:"component",attrs:{"alert":_vm.selectedAlert,"change-task-status":_vm.changeTaskStatus,"formatter":_vm.format}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }