<template>
  <div class="u-display-flex u-flex-direction-column">
    <div
      class="u-spacing-mt-m u-border-color-grey-xxx-light u-border-width-s u-border-all u-border-radius-m"
    >
      <tree :tree-data="treeData" />
    </div>
  </div>
</template>

<script>
import tree from '../molecules/tree';

export default {
  components: {
    tree
  },
  props: {
    treeData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
