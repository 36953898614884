import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep } from 'lodash';
import transformer from '@/utils/services/data-transformer';

const state = {
  primaryKeyHashMap: {
    asin: 'asin',
    search: 'search',
    brand: 'bc_brand_alert_type',
    keyword_text: 'keyword_text',
    campaign: 'campaign',
    search_term: 'search_term'
  }
};

const getters = {};

const mutations = {};

const actions = {
  fetchAllAlerts: (context, options) => {
    // console.log(context.getRecommendationFiltersPayload)
    var updatedFilters =
      context.rootGetters.getRecommendationFiltersPayload.filters.filter(
        (elm) => {
          delete elm.date_range;
          return elm;
        }
      );
    const recommendationGroup = ['Sales', 'Marketing', 'Operations'];
    // const recommendationGroup = ['All'];

    const updatedWhereClause = transformer.transformWhereClauseWithTag(
      updatedFilters || [],
      'recommendation'
    );

    const config = {
      filters: updatedWhereClause.updatedFilters || [],
      recency: context.rootGetters.getRecommendationFiltersPayload.recency,
      topRecommendation:
        context.rootGetters.getRecommendationFiltersPayload.topRecommendation,
      filter_hook: 'asin',
      tagWhereClause: [{ ...updatedWhereClause.tagWhereClause }]
    };
    const requests = recommendationGroup.map((item) => {
      const requestObj = cloneDeep(config);
      requestObj.recommendationGroup = item;
      return HttpService.post('RECOMMENDATIONS', requestObj, {
        headers: { account: 'deprecated' }
      });
    });
    return HttpLayer.all(requests)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
