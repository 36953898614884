<template>
  <div class="u-font-size-5 u-color-red-base">
    <div
      v-for="(error, index) in errorList"
      :key="index"
    >
      <span>
        {{ error.errorEntity }}
      </span>
      <span class="u-spacing-pl-m">
        {{ error.errorDescription }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorList: {
      // individual error objects of the format,
      // {errorDescription:String,errorEntity: String }
      type: Array,
      default: () => {
        return [{ errorDescription: 'String', errorEntity: 'String' }];
      }
    }
  }
};
</script>

<style lang="css">
.list_table {
  table-layout: fixed;
  width: 100%;
}
</style>
