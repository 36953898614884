import Vue from 'vue';
import transformer from '@/utils/services/data-transformer';
import HttpLayer from '@/utils/services/http-layer';
import { levelName } from '@/pages/campaign-management/constants.js';
import { cloneDeep } from 'lodash';
const initialState = () => {
  return {
    globalWhereClause: {},
    filteredCampaignsSubgroupData: {},
    keywordsRealTimeMetadata: {},
    campaignsRealTimeMetadata: {},
    skusRealTimeMetadata: {},
    campaignsLevel2Data: { load: true, rows: {}, error: false, noData: false },
    campaignsLevel1Data: { load: true, rows: {}, error: false, noData: false }
  };
};

const state = initialState();

const getters = {
  getGlobalFilters: (state) => state.globalWhereClause,
  getFilteredSubgroupData: (state) => state.filteredCampaignsSubgroupData,
  getCampaignsLevel1Data: (state) => state.campaignsLevel1Data,
  getCampaignsLevel2Data: (state) => state.campaignsLevel2Data,
  getCampaignsRealTimeMetadata: (state) => state.campaignsRealTimeMetadata,
  getKeywordsRealTimeMetadata: (state) => state.keywordsRealTimeMetadata,
  getSkusRealTimeMetadata: (state) => state.skusRealTimeMetadata
};

export const getRealtimeUpdateAttributes = (retailer) => {
  const realtime_update_attributes = {
    walmart: {
      store_name_campaign: 'setCampaignRealtimeMetadata',
      store_name_keyword: 'setKeywordRealtimeMetadata',
      store_name_skus: 'setSkusRealtimeMetadata',
      state_key_campaign: 'campaignsRealTimeMetadata',
      state_key_keyword: 'keywordsRealTimeMetadata',
      state_key_skus: 'skusRealTimeMetadata',
      primary_key_campaign: 'campaign_id',
      primary_key_keyword: 'keyword_id',
      primary_key_skus: 'ad_group_content_id',
      cube_name_campaign: 'walmart_campaigns_campaign_workbench_metadata',
      cube_name_keyword: 'walmart_campaigns_keyword_workbench_metadata',
      cube_name_skus: 'walmart_campaigns_skus_workbench_metadata',
      measure_list_campaign: [
        'campaign_id',
        'status',
        'daily_budget',
        'total_budget'
      ],
      measure_list_keyword: ['bid', 'status'],
      measure_list_skus: ['bid', 'status'],
      group_by_dimensions_list_campaign: ['campaign_id'],
      group_by_dimensions_list_keyword: ['keyword_id'],
      group_by_dimensions_list_skus: [
        'ad_group_id',
        'advertiser_id',
        'campaign_id',
        'ad_group_content_id'
      ]
    }
  };
  return realtime_update_attributes[`${retailer}`];
};

const formatWhereClause = (where, widget) =>
  where?.map((item) => ({
    dimensionName: `${widget !== 'skus' ? widget : 'ad_group_content'}_id`,
    dimensionValue:
      item[`${widget !== 'skus' ? widget : 'ad_group_content'}_id`]
  }));

export const isRealTimeUpdateEnabled = ['walmart'];

export const fetchRealtimeTableData = (
  actionSource,
  retailerName,
  dataSource,
  namespace,
  widget,
  context
) => {
  const finalWhereClause = cloneDeep(dataSource.rows.tableRow);
  if (!finalWhereClause) {
    return;
  }
  const formattedWhereClause = formatWhereClause(finalWhereClause, widget);
  const stateKey =
    getRealtimeUpdateAttributes(retailerName)[`state_key_${widget}`];
  const metaDataActionKey = `${namespace}${
    getRealtimeUpdateAttributes(retailerName)[`store_name_${widget}`]
  }`;
  const primaryKey =
    getRealtimeUpdateAttributes(retailerName)[`primary_key_${widget}`];

  const requestObject = {
    cubeName: getRealtimeUpdateAttributes(retailerName)[`cube_name_${widget}`],
    groupByDimensionsList:
      getRealtimeUpdateAttributes(retailerName)[
        `group_by_dimensions_list_${widget}`
      ],
    measuresList:
      getRealtimeUpdateAttributes(retailerName)[`measure_list_${widget}`],
    orderByLst: [
      {
        dimension: 'status',
        direction: 'DESC'
      }
    ],
    where: { dimensionNameValueList: formattedWhereClause },
    tagWhereClause: []
  };
  let counter = 1;
  if (actionSource === 'blueBarAction') {
    counter = 3;
  }

  const executeRealtimeTableData = async () => {
    counter--;
    if (counter >= 0) {
      // metadata api call
      try {
        const response = await HttpLayer.post({
          header: {
            headers: {
              cache: false
            }
          },
          cube: 'EXECUTE_CUBE_REALTIME_METADATA_API',
          APIData: requestObject
        });
        if (!response.success) {
          context.$store.dispatch(metaDataActionKey, {
            data: {},
            dataFetched: true,
            intervalId: counter,
            primaryKey,
            stateKey,
            realtime: true,
            apiFailed: true
          });
          return;
        }
        const responseData = transformer.mergeResultDimension(response.data);
        const responseMetadata = responseData?.reduce((acc, entity) => {
          acc[entity[primaryKey]] = entity;
          return acc;
        }, {});
        context.$store.dispatch(metaDataActionKey, {
          data: responseMetadata,
          dataFetched: true,
          lastUpdated: new Date(),
          intervalId: counter,
          primaryKey,
          stateKey,
          realtime: true,
          apiFailed: false
        });
        transformer.mutateTableDataStateWithMetadataRows(
          dataSource.rows.tableRow,
          responseMetadata,
          primaryKey
        );
      } catch (error) {
        context.$store.dispatch(metaDataActionKey, {
          data: {},
          dataFetched: false,
          intervalId: counter,
          primaryKey,
          stateKey,
          realtime: false,
          apiFailed: true
        });
      }
      setTimeout(executeRealtimeTableData, 20000); // 2 more calls after 20 seconds delay
    }
  };
  executeRealtimeTableData(); // 1st call
};

const mutations = {
  SET_GLOBAL_WHERE_CLAUSE: (state, data) => {
    const newWhereClause = getGlobalWhereClause(data);
    Vue.set(state, 'globalWhereClause', newWhereClause);
  },
  CAMPAIGN_SUBGROUPS: (state, data) => {
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, {});
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  },
  CAMPAIGNS_LEVEL1_NO_DATA: (state, data) => {
    Vue.set(state.campaignsLevel1Data, 'rows', {});
    Vue.set(state.campaignsLevel1Data, 'load', false);
    Vue.set(state.campaignsLevel1Data, 'error', false);
    Vue.set(state.campaignsLevel1Data, 'noData', true);
  },
  CAMPAIGNS_LEVEL2_NO_DATA: (state, data) => {
    Vue.set(state.campaignsLevel2Data, 'rows', {});
    Vue.set(state.campaignsLevel2Data, 'load', false);
    Vue.set(state.campaignsLevel2Data, 'error', false);
    Vue.set(state.campaignsLevel2Data, 'noData', true);
  },
  CAMPAIGNS_LEVEL1_RESET: (state, data) => {
    Vue.set(state.campaignsLevel1Data, 'rows', {});
    Vue.set(state.campaignsLevel1Data, 'load', true);
    Vue.set(state.campaignsLevel1Data, 'error', false);
    Vue.set(state.campaignsLevel1Data, 'noData', false);
  },
  CAMPAIGNS_LEVEL1_SUCCESS: (state, data) => {
    Vue.set(state.campaignsLevel1Data, 'rows', {
      tableRow: data,
      totalRow: data?.[0]?.RESULT?.final_auto_cubesdk_count
    });
    Vue.set(state.campaignsLevel1Data, 'load', false);
    Vue.set(state.campaignsLevel1Data, 'error', false);
    Vue.set(state.campaignsLevel1Data, 'noData', data.length === 0);
  },
  CAMPAIGNS_LEVEL1_FAILURE: (state, data) => {
    Vue.set(state.campaignsLevel1Data, 'rows', {});
    Vue.set(state.campaignsLevel1Data, 'load', false);
    Vue.set(state.campaignsLevel1Data, 'error', true);
    Vue.set(state.campaignsLevel1Data, 'noData', false);
  },
  CAMPAIGNS_LEVEL2_RESET: (state, data) => {
    Vue.set(state.campaignsLevel2Data, 'rows', {});
    Vue.set(state.campaignsLevel2Data, 'load', true);
    Vue.set(state.campaignsLevel2Data, 'error', false);
  },
  CAMPAIGNS_LEVEL2_SUCCESS: (state, data) => {
    Vue.set(state.campaignsLevel2Data, 'rows', {
      tableRow: data,
      totalRow: data?.[0]?.RESULT?.final_auto_cubesdk_count
    });
    Vue.set(state.campaignsLevel2Data, 'load', false);
    Vue.set(state.campaignsLevel2Data, 'error', false);
  },
  CAMPAIGNS_LEVEL2_FAILURE: (state, data) => {
    Vue.set(state.campaignsLevel2Data, 'rows', {});
    Vue.set(state.campaignsLevel2Data, 'load', false);
    Vue.set(state.campaignsLevel2Data, 'error', true);
  },
  CAMPAIGNS_REALTIME_META_DATA: (state, data) => {
    state.campaignsRealTimeMetadata = data;
  },
  KEYWORDS_REALTIME_META_DATA: (state, data) => {
    state.keywordsRealTimeMetadata = data;
  },
  SKUS_REALTIME_META_DATA: (state, data) => {
    state.skusRealTimeMetadata = data;
  }
};

const addChildRowCount = (tableData) => {
  const relatedMap = {};
  tableData.map((row, index) => {
    if (row.sub_type !== '-') {
      row['ag-grid-has-expand'] = false;
      if (!relatedMap[row.campaign_id]) {
        relatedMap[row.campaign_id] = {
          count: 0
        };
      }
      relatedMap[row.campaign_id].count++;
    }
  });
  for (const relatedEntity in relatedMap) {
    const index = tableData.findIndex((item) => {
      return !!(item.campaign_id === relatedEntity && item.sub_type === '-');
    });
    if (index > -1) {
      tableData[index].childCount = relatedMap[relatedEntity].count;
      tableData[index]['ag-grid-has-expand'] =
        relatedMap[relatedEntity].count > 0;
    }
  }
  return tableData;
};

const actions = {
  setCampaignRealtimeMetadata: (context, data) => {
    context.commit('CAMPAIGNS_REALTIME_META_DATA', data);
  },
  setKeywordRealtimeMetadata: (context, data) => {
    context.commit('KEYWORDS_REALTIME_META_DATA', data);
  },
  setSkusRealtimeMetadata: (context, data) => {
    context.commit('SKUS_REALTIME_META_DATA', data);
  },
  setLoadStateCampaignsLevel1: (context, state) => {
    context.commit('CAMPAIGNS_LEVEL1_RESET');
    context.commit('CAMPAIGNS_LEVEL2_RESET');
  },
  setNoDataStateCampaignsLevel1: (context, state) => {
    context.commit('CAMPAIGNS_LEVEL1_NO_DATA');
    context.commit('CAMPAIGNS_LEVEL2_NO_DATA');
  },
  setErrorStateCampaignsLevel1: (context, state) => {
    context.commit('CAMPAIGNS_LEVEL1_FAILURE');
    context.commit('CAMPAIGNS_LEVEL2_FAILURE');
  },
  setGlobalFilters: (context, data) => {
    context.commit('SET_GLOBAL_WHERE_CLAUSE', data);
  },
  filterSubgroupData: (context, data) => {
    const { tableRow: table } = context.state.campaignsLevel2Data.rows;
    const level1TableData = table.filter(
      (row) => row.campaign_id === data.row.campaign_id && row.sub_type !== '-'
    );
    data.rows = [
      ...level1TableData.filter(
        (row) => row.campaign_sub_category === 'placement'
      ),
      ...level1TableData.filter(
        (row) => row.campaign_sub_category === 'platform'
      )
    ];
    context.commit('CAMPAIGN_SUBGROUPS', { ...data });
  },
  async getCampaignsLevel1Data(context, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const { request } = data;
        context.commit('CAMPAIGNS_LEVEL1_RESET');
        context.commit('CAMPAIGNS_LEVEL2_RESET');
        const response = await HttpLayer.post(request);
        let rowsData = response.data.map((item) => {
          return {
            ...item.DIMENSION,
            ...item.RESULT,
            ...item.PVP
          };
        });
        if (rowsData.length === 0) {
          context.commit('CAMPAIGNS_LEVEL1_NO_DATA');
          context.commit('CAMPAIGNS_LEVEL2_NO_DATA');
        } else {
          rowsData = addChildRowCount(rowsData);
          const level1Data = rowsData.filter((item) => item.sub_type === '-');
          const level2Data = rowsData
            .filter((item) => item.sub_type !== '-')
            .sort((a, b) => {
              return a.sub_type > b.sub_type ? 1 : -1;
            });
          context.commit('CAMPAIGNS_LEVEL1_SUCCESS', level1Data);
          context.commit('CAMPAIGNS_LEVEL2_SUCCESS', level2Data);
        }
        resolve();
      } catch (e) {
        console.error('e', e);
        context.commit('CAMPAIGNS_LEVEL1_FAILURE');
        context.commit('CAMPAIGNS_LEVEL2_FAILURE');
        reject(e);
      }
    });
  },
  setCampaignsData: (context, data) => {
    context.commit('CAMPAIGNS_LEVEL1_SUCCESS', data);
  }
};

const getGlobalWhereClause = (oFilter) => {
  const where = {
    dimensionNameValueList: []
  };
  for (let i in oFilter.values) {
    if (i !== 'date_range') {
      if (
        oFilter.values[i].length > 0 &&
        oFilter?.values?.[i]?.[0]?.operator?.operator === 'BETWEEN'
      ) {
        const { obj1, obj2 } =
          transformer.convertBetweenOperatorToGreaterThanLessThan(
            oFilter.values[i][0].value,
            i
          );
        where.dimensionNameValueList.push(obj1, obj2);
      } else if (
        oFilter.values[i].length > 0 &&
        oFilter.values[i][0].operator
      ) {
        if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][0].value,
            operator: oFilter.values[i][0].operator.operator
          });
        }
      } else {
        for (const element of oFilter.values[i]) {
          // creates the final filter object with esDataSetName in case of V2 filters
          const filter = transformer.addEsDataSetName(i, element);
          where.dimensionNameValueList.push(filter);
        }
      }
    } else {
      let date = oFilter.values[i];
      let _date = {
        from: date.from,
        to: date.to,
        name: date.name
      };
      let _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  return where;
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
