<template>
  <div class="u-display-flex u-flex-direction-column">
    <div class="u-spacing-p-l u-display-flex u-flex-direction-column">
      <div class="u-font-size-5 u-text-uppercase u-font-weight-600">
        {{ header }}
      </div>
      <div class="u-display-flex u-spacing-pv-m column-container">
        <div
          v-for="(column, index) in columns"
          :key="`columns-${index}`"
          class="u-display-flex u-flex-direction-column"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span class="u-font-size-6 u-font-weight-600">Column:</span>
            <rb-select
              class="select-dropdown"
              :send-details="true"
              :on-close="(...args) => optionSelected(index, ...args)"
              :options="columnDropdownOptions"
            >
              <div
                slot="trigger"
                class="option u-display-flex u-flex-align-items-center u-flex-justify-content-space-around u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-flex-direction-column u-spacing-p-s"
                >
                  <span
                    class="u-font-size-5 u-color-grey-lighter u-font-weight-600"
                    >{{ column.selectedColumn.title }}</span
                  >
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.title }}
                </div>
              </template>
            </rb-select>
          </div>
          <div class="u-display-flex">
            <input-with-suggestions
              :key="`input-box-${column.selectedColumn.title}`"
              :input="column.value"
              :input-classes="'u-width-100'"
              :placeholder="'Assign a value'"
              :suggestions="column.selectedColumn.suggestions || []"
              @input="($e) => itemClick($e, index)"
            >
              <span
                v-if="index > 0"
                slot="post-input"
                class="u-cursor-pointer u-font-size-5 u-display-inline-flex u-flex-align-items-center u-flex-align-self-flex-end u-spacing-mb-s u-spacing-ml-s action--text"
                data-cy="removeColumnBtn"
                @click.stop="() => removeColumn(index)"
              >
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-xs"
                  data-cy="removeColumn"
                  :icon="'cross-fill-circle'"
                />
              </span>
            </input-with-suggestions>
          </div>
        </div>
        <span
          v-if="columns.length !== columnDropdownOptions.length"
          class="u-cursor-pointer u-font-size-5 u-display-inline-flex u-flex-align-items-center u-flex-align-self-flex-end u-spacing-mb-s u-spacing-ml-s action--text"
          data-cy="addColumnBtn"
          @click.stop="addColumn"
        >
          <rb-icon
            class="rb-icon--small u-spacing-mr-xs"
            data-cy="addColumn"
            :icon="'add-circle-fill'"
          />

          <span>Add Column</span>
        </span>
      </div>
    </div>
    <div
      class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
    >
      <footer-buttons
        class="u-spacing-ph-l"
        :disable-left-btn="disableApplyButton"
        :hollow-hover-button-left="false"
        left-button-type="filled"
        btn-left-text="Apply"
        :button-size="'s'"
        @onClickBtnRight="() => $emit('closePanelMethod')"
        @onClickBtnLeft="applyAction"
      ></footer-buttons>
    </div>
  </div>
</template>

<script>
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import Vue from 'vue';
import inputWithSuggestions from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/inputWithSuggestions.vue';
export default {
  name: 'ColumnValueEdit',
  components: { footerButtons, inputWithSuggestions },
  props: {
    columnDropdownOptions: {
      type: Array,
      default: []
    },
    getHeader: {
      type: Function,
      default: () => ''
    },
    selections: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      columns: [],
      suggestions: []
    };
  },
  computed: {
    header() {
      return this.getHeader(this.selections.length);
    },
    disableApplyButton() {
      return this.columns.some((column) => !column?.value?.trim());
    }
  },
  created() {
    this.columns = [
      { selectedColumn: this.columnDropdownOptions[0], value: '' }
    ];
  },
  methods: {
    optionSelected(index, context, selectedOption) {
      const selectedColumnOptions = this.columns.map(
        (column) => column.selectedColumn?.title
      );
      if (!selectedColumnOptions.includes(selectedOption[0].title))
        Vue.set(this.columns, index, {
          selectedColumn: selectedOption[0],
          value: ''
        });
    },
    addColumn() {
      const selectedColumnMap = this.columns.map(
        (column) => column?.selectedColumn?.title
      );
      for (let option of this.columnDropdownOptions) {
        if (!selectedColumnMap.includes(option.title)) {
          this.columns.push({
            selectedColumn: option,
            value: ''
          });
          break;
        }
      }
    },
    removeColumn(index) {
      this.columns.splice(index, 1);
    },
    itemClick(suggestion, i) {
      Vue.set(this.columns, i, {
        ...this.columns[i],
        value: suggestion
      });
    },
    applyAction() {
      const actions = this.columns.map((col) => ({
        [col.selectedColumn.value]: [col.value]
      }));
      this.$emit('afterAPIActionToCall', {
        selections: this.selections,
        actions
      });
    }
  }
};
</script>

<style lang="css" scoped>
.select-dropdown {
  width: fit-content;
}
.column-container {
  gap: 2.4rem;
  flex-wrap: wrap;
}
</style>
