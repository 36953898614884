<template>
  <div class="u-display-flex u-flex-direction-column">
    <name-message-template :data="nameMessageData" />
    <div class="u-spacing-mt-s">
      <div
        v-for="(diff, index) in formatedDiffArray"
        :key="index"
      >
        <arrow-change
          :config="diff.config"
          :data="diff.data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import nameMessageTemplate from '@/components/widgets/collaboration/templates/name-message.vue';
import arrowChange from '@/components/widgets/collaboration/templates/arrowChange.vue';
import textMessage from '@/components/widgets/collaboration/templates/textMessage.vue';
import moment from 'moment';
import Vue from 'vue';
export default {
  components: {
    'name-message-template': nameMessageTemplate,
    arrowChange,
    textMessage
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      diffArray: [],
      mapper: {
        label: 'Seasonality name',
        businessScopeName: 'Scope Name',
        start: 'Start Date',
        end: 'End Date',
        value: 'Budget',
        eventInfo: 'Budget',
        valueType: 'Budget'
      }
    };
  },
  computed: {
    nameMessageData() {
      const obj = {
        username: this.data.email,
        message: this.getMessage(),
        date: {
          time: this.data.date.time
        }
      };
      return obj;
    },
    formatedDiffArray() {
      const diffArray = [];
      const index = this.data.diffArray.findIndex((item) => {
        return item.key === 'value';
      });
      if (index > -1) {
        const valueTypeIndex = this.data.diffArray.findIndex((item) => {
          return item.key === 'valueType';
        });
        if (valueTypeIndex > -1) {
          this.data.diffArray.splice(index, 1);
        }
        const labelIndex = this.data.diffArray.findIndex((item) => {
          return item.key === 'eventInfo';
        });
        if (labelIndex > -1) {
          this.data.diffArray.splice(index, 1);
        }
      }
      for (let i = 0; i < this.data.diffArray.length; i++) {
        const diffObj = this.data.diffArray[i];
        if (this.mapper[diffObj.key]) {
          if (diffObj.key === 'valueType') {
            diffObj.key = 'value';
            diffObj.newValue = this.data.newStrategy.strategy.value;
            diffObj.oldValue = this.data.oldStrategy.strategy.value;
          }
          diffArray.push({
            config: {
              'sub-heading': this.mapper[diffObj.key],
              textMessage: 'newValue',
              leftMinWidth: '95px'
            },
            data: this.getFormattedValue(diffObj, this.data)
          });
        }
      }
      return diffArray;
    }
  },
  created() {},
  methods: {
    getMessage() {
      return `edited "${this.data.oldStrategy.strategy.label}" seasonality`;
    },
    getValueTypeLabel(valuetype) {
      const map = {
        ABSOLUTE: 'currency',
        PERCENTAGE: 'percentage'
      };
      return map[valuetype];
    },
    capitalize(s) {
      s = s.toLowerCase();
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    addingScopeName(businessScope, value) {
      if (businessScope === 'ENTIRE_BUSINESS') {
        return 'Entire Business';
      } else {
        return this.capitalize(businessScope) + ' - ' + value;
      }
    },
    getFormattedValue(diffObj, data) {
      if (diffObj.key === 'value' || diffObj.key === 'eventInfo') {
        diffObj = this.getLabel(data);
        console.log(diffObj);
      }
      if (diffObj.key === 'start' || diffObj.key === 'end') {
        diffObj.newValue = moment(diffObj.newValue).format('MMM D, YYYY');
        diffObj.oldValue = moment(diffObj.oldValue).format('MMM D, YYYY');
      }
      if (diffObj.key === 'businessScopeName') {
        diffObj.newValue = this.addingScopeName(
          data.newStrategy.entity.businessScope,
          diffObj.newValue
        );
        diffObj.oldValue = this.addingScopeName(
          data.oldStrategy.entity.businessScope,
          diffObj.oldValue
        );
      }
      return diffObj;
    },
    getLabel(data) {
      const diffObj = {};
      diffObj.newValue = this.getDisplayValue(
        data.newStrategy.strategy.value,
        this.getValueTypeLabel(data.newStrategy.strategy.valueType)
      );
      diffObj.oldValue = this.getDisplayValue(
        data.oldStrategy.strategy.value,
        this.getValueTypeLabel(data.oldStrategy.strategy.valueType)
      );
      diffObj.newValue += ` ${
        (data.newStrategy.strategy.eventInfo || {}).description
      }`;
      diffObj.oldValue += ` ${
        (data.oldStrategy.strategy.eventInfo || {}).description
      }`;
      return diffObj;
    },
    getDisplayValue(display, displayType) {
      switch (displayType) {
        case 'currency':
          display = Vue.options.filters.num_format(display, 'currency');
          break;
        case 'percentage':
          display = Vue.options.filters.num_format(display, undefined, '%');
      }
      return Vue.options.filters.num_format(display);
    }
  }
};
</script>
