<template>
  <div class="u-display-flex u-width-100 u-height-100 u-flex-direction-column">
    <split-container :master-width="0">
      <div slot="detail">
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </div>
    </split-container>
  </div>
</template>

<script>
import splitContainer from '@/components/layouts/splitContainer';
export default {
  components: {
    splitContainer
  }
};
</script>
