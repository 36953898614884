var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentLevelData)?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100 u-overflow-auto"},[(_vm.level > 0)?_vm._t("childLevelSelectorHeader",null,{"level":_vm.level}):_vm._e(),_c('div',{staticClass:"u-overflow-auto",class:{ 'u-spacing-mt-s': _vm.level > 0, 'u-spacing-mb-s': _vm.level > 0 }},[(_vm.getSelectAllText())?_c('div',{staticClass:"u-cursor-pointer u-spacing-pv-s u-spacing-ph-m u-width-100 u-display-flex u-flex-align-items-center",on:{"click":_vm.handleBulkSelections}},[_c('div',{staticClass:"u-line-height-1 u-text-overflow-ellipsis u-font-size-5 u-color-blue-base u-font-weight-600"},[_vm._v(" "+_vm._s(_vm.getSelectAllText())+" ")])]):_vm._e(),_c('div',_vm._l((_vm.showData),function(item,index){return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          placement: 'top-start',
          arrow: false,
          maxWidth: '200px',
          size: 'regular'
        }),expression:"{\n          placement: 'top-start',\n          arrow: false,\n          maxWidth: '200px',\n          size: 'regular'\n        }"}],key:item.text + index,staticClass:"u-cursor-pointer u-spacing-pv-s u-spacing-ph-m",class:{
          'u-bg-color-blue-xx-light':
            _vm.getID(item, index, _vm.level) === _vm.openedTippy,
          'category disabled': item.disabled
        },attrs:{"id":'dropdown-item' + _vm.getID(item, index, _vm.level),"title":_vm.disabledTooltip(item)}},[(_vm.currentLevelData[item.text])?_c('div',{staticClass:"u-display-flex u-width-100 u-flex-justify-content-space-between",class:{ 'category disabled': item.disabled }},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-center u-overflow-auto",on:{"click":function($event){_vm.handleClick(item, _vm.getID(item, index, _vm.level))}}},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--medium",class:item.iconColorClass,attrs:{"icon":item.icon}}),_c('div',{staticClass:"u-font-weight-normal u-spacing-pl-s u-line-height-1 u-text-overflow-ellipsis u-font-size-5 u-color-grey-light",attrs:{"title":item.text}},[_vm._v(" "+_vm._s(item.text)+" ")])],1),_c('div',[(_vm.count(_vm.currentLevelData[item.text].children).length > 0)?_c('rb-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions(item, index, _vm.level)),expression:"tippyOptions(item, index, level)"}],staticClass:"rb-icon--small next-level-trigger u-color-grey-light",attrs:{"id":(item.selected ? 'selected-icon' : 'unselected-icon') +
                _vm.getID(item, index, _vm.level),"identifier":_vm.getID(item, index, _vm.level),"icon":"arrow-right"}}):_vm._e()],1)]):_vm._e(),(_vm.currentLevelData[item.text])?_c('div',{staticClass:"u-display-flex u-flex-direction-column child-multi-level-selector",attrs:{"id":_vm.getID(item, index, _vm.level)}},[(_vm.nextLevel[_vm.getID(item, index, _vm.level)])?_c('childMultiLevelSelector',{ref:'nextLevel' + _vm.getID(item, index, _vm.level),refInFor:true,attrs:{"parent-tippy":_vm.tippyInstance,"root-tippy-id":_vm.rootTippyId,"parent-tippy-id":_vm.getID(item, index, _vm.level),"spawn-in-same-direction":_vm.spawnInSameDirection,"path":_vm.path.concat([item.text]),"data":_vm.currentLevelData[item.text].children,"level":_vm.level + 1,"identifier":item.identifier,"multi":_vm.currentLevelData[item.text].multi,"current-level-data":_vm.currentLevelData[item.text].children,"root-selection-group":_vm.rootSelectionGroup,"current-level-selection-group":_vm.nextLevelSelectorGroup(item),"selections":item.children},on:{"selection":_vm.selectionHandler,"mountHandle":_vm.handleTippyMount},scopedSlots:_vm._u([{key:"childLevelSelectorHeader",fn:function(ref){
                var level = ref.level;
return [_vm._t("childLevelSelectorHeader",null,{"level":level})]}}],null,true)}):_vm._e()],1):_vm._e()])}),0)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }