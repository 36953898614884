<template>
  <card-widget
    class="u-flex-1 budget-panner-dashboard"
    :config="config.widgets['widget1']"
    :data="chartData"
  >
    <div
      slot="body"
      class="u-display-flex u-flex-direction-column u-width-100 u-spacing-ph-l u-spacing-pb-l"
    >
      <chartHeader :error="chartData.error" />
      <chart-with-legends
        :metrics-list="budgetMetricsList"
        :custom-chart-class="['u-spacing-mt-s']"
        :color-object="budgetUnitsColors"
        :chart-config="config.widgets['widget1'].body.chart"
        :chart-data="widget1Data"
        :default-legends="false"
        :metrics-shown="getBudgetMetricsShown"
        :default-select-metric="budgetDefaultSelectMetric"
        :metric-config="tagConfig"
        :disable-add-new-metric="true"
        :metric-data="dashboardLegendsData"
        :minimum-metric="getBudgetMetricsShown.length"
        :remove-dropdown="true"
        :selected-metric-limit="getBudgetMetricsShown.length"
        :show-padding-between="false"
        :margin-top-large="false"
        :margin-top-small="true"
        @selectedMetricList="selectedMetricList"
      >
        <!-- custom chart legends ui -->
        <div
          slot="customLegendForChart"
          slot-scope="{ val }"
        >
          <chartMetricCard :metric="val" />
        </div>
        <div slot="post-legends">
          <chartCustomMetric :dashboard-legends-data="dashboardLegendsData" />
        </div>
        <div
          slot="space-between-legend-and-chart"
          class="u-display-flex u-flex-justify-content-flex-start"
        >
          <chartActions
            :roll-ups="rollUps"
            @onTabClick="onTabClick"
            @onRollupChange="onRollupChange"
          />
        </div>
      </chart-with-legends>
    </div>
    <div
      slot="footer"
      class="u-color-grey-x-light u-font-size-7"
    >
      <chartFooter
        :last-updated-date="lastUpdatedDate"
        :retailer-message="getRetailerMessage"
      />
    </div>
  </card-widget>
</template>

<script>
import Vue from 'vue';
import widgetsConfig from '@/pages/budget-planner-v2/dashboard/widget.js';
import widgetsUtil from '@/components/widgetMixin';
import cardWidget from '@/components/widgets/cardWidget';
import { cloneDeep } from 'lodash';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import {
  budgetMetricsShown,
  retailerMessage,
  retailerFiltersMap
} from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import chartHeader from '@/pages/budget-planner-v2/dashboard/widgets/chart/components/chartHeader.vue';
import chartFooter from '@/pages/budget-planner-v2/dashboard/widgets/chart/components/chartFooter.vue';
import chartActions from '@/pages/budget-planner-v2/dashboard/widgets/chart/components/chartActions.vue';
import chartCustomMetric from '@/pages/budget-planner-v2/dashboard/widgets/chart/components/chartCustomMetric.vue';
import chartMetricCard from '@/pages/budget-planner-v2/dashboard/widgets/chart/components/chartMetricCard.vue';

export default {
  components: {
    chartMetricCard,
    chartCustomMetric,
    chartActions,
    chartHeader,
    chartFooter,
    chartWithLegends,
    cardWidget
  },
  mixins: [widgetsUtil],
  data: () => ({
    // point_format is a c3 config used to determine the size of the point on a graph. We are dynamically generating it here.
    pointFormatValue: 3,
    pointFormatDefault: {
      numberOfMonthsToShowFor: 6,
      showPointValue: 3,
      hidePointValue: 0,
      dateFormat: 'YYYY-MM-DD'
    },
    message: 'Amazon data is typically delayed by upto 24 hours.',
    showY2: false,
    widgetsConfig: {},
    budgetType: 'Cumulative',
    config: {},
    rollUps: [
      { name: 'Days', key: 'rollup_days' },
      { name: 'Weeks', key: 'rollup_weeks' },
      { name: 'Months', key: 'rollup_months' }
    ],
    selectedRollUp: { name: 'Days', key: 'rollup_days' },
    budgetMetricsList: [
      { key: 'Planned Budget' },
      { key: 'Ad Spend' },
      { key: 'Spend / Budget' },
      { key: 'Spend / PCOGS' }
    ],
    budgetDefaultSelectMetric: [{ key: 'Planned Budget' }, { key: 'Ad Spend' }],
    budgetMetricsShown: [],
    tagConfig: {
      'Planned Budget': { tag1Unit: { pre: 'currency' } },
      'Ad Spend': { tag1Unit: { pre: 'currency' } },
      'Spend / PCOGS': { tag1Unit: { pre: '', suff: '%' } },
      'Spend / Budget': { tag1Unit: { pre: '', suff: '%' } }
    },
    budgetUnitsColors: {
      'Planned Budget': '#bd10e0',
      'Ad Spend': '#ffa800',
      'Spend / PCOGS': 'brown',
      'Spend / Budget': 'green'
    }
  }),
  computed: {
    lastUpdatedDate() {
      return this.config?.widgets?.widget1?.footer?.getMaxDateDashboard;
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    getRetailerMessage() {
      return retailerMessage[this.retailer];
    },
    getBudgetMetricsShown() {
      return budgetMetricsShown[this.retailer];
    },
    chartData() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getDashboardChartDataBP'
      ];
    },
    dashboardLegendsData() {
      const data = this.chartData.legendData || {};
      const plannedBudgetTag = data.planned_budget_sum || null;
      const adSpendTag = data.actual_spend_sum || null;
      let spendStatus = 'N/A';
      const spendVsPcogs = data.spend_vs_pcogs
        ? data.spend_vs_pcogs.toFixed(2)
        : 'NA';
      const spendVsBudget = data.spend_vs_budget
        ? data.spend_vs_budget.toFixed(2)
        : 'NA';
      const plannedBudgetMaxFeedDate =
        data.planned_budget_till_max_report_date || null;
      let difference = 'N/A';
      if (adSpendTag && plannedBudgetMaxFeedDate) {
        spendStatus =
          adSpendTag > plannedBudgetMaxFeedDate
            ? 'Overspending'
            : 'Underspending';
        difference = Vue.options.filters.num_format(
          Math.abs(adSpendTag - plannedBudgetMaxFeedDate),
          'currency'
        );
      }
      return {
        'Planned Budget': {
          tag1: plannedBudgetTag,
          tooltip: 'PD tooltip'
        },
        'Ad Spend': {
          tag1: adSpendTag,
          tooltip: 'Ad Tooltip'
        },
        'Spend / PCOGS': {
          tag1: spendVsPcogs
        },
        'Spend / Budget': {
          tag1: spendVsBudget
        },
        cumulativeDifference: {
          difference,
          spendStatus
        },
        spendVsBudget,
        spendVsPcogs
      };
    },
    widget1Data() {
      const data = this.chartData.timeSeriesData || [];
      let absolute = cloneDeep(data);
      const cumulative = cloneDeep(data);
      if (this.budgetType === 'Absolute') {
        absolute = absolute.filter((row) => {
          if (row[0] !== 'Planned Budget' || row[0] !== 'Ad Spend') {
            if (row[0] === 'Cumulative Planned Budget') {
              row[0] = 'Planned Budget';
            }
            if (row[0] === 'Cumulative Ad Spend') {
              row[0] = 'Ad Spend';
            }
            return row;
          }
        });
      }
      const colors = { 'Planned Budget': '#bd10e0', 'Ad Spend': '#ffa800' };
      const axes = {
        'Planned Budget': 'y',
        'Planned Budget_color': '#bd10e0',
        'Ad Spend': 'y',
        'Ad Spend_color': '#ffa800',
        'Spend / Budget': 'y2',
        'Spend / Budget_color': 'green',
        'Spend / PCOGS': 'y2',
        'Spend / PCOGS_color': 'brown'
      };
      const xs = {
        'Planned Budget': 'feed_date',
        'Ad Spend': 'feed_date',
        'Spend / PCOGS': 'feed_date',
        'Spend / Budget': 'feed_date'
      };
      const axisFormat = {
        y: {
          pre: 'currency',
          show: true
        },
        y2: {
          pre: '',
          suff: '%',
          show: this.showY2
        }
      };
      return {
        data: this.budgetType === 'Absolute' ? absolute : cumulative,
        colors,
        axes,
        xs,
        timeseries: 'feed_date',
        axis_format: axisFormat,
        show_axis_colors: true,
        xDataKey: 'x',
        grid: 'xy',
        axis: { y2: { show: true } }
      };
    }
  },
  created() {
    // widget config
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    this.checkDateRageForPoints();
    eventBus.$on(this.config.filters.emit, () => {
      this.checkDateRageForPoints();
    });
  },
  methods: {
    checkDateRageForPoints() {
      // this function is used to dynamically adjust the point size on the c3 line graph based on date range and rollup selection
      const dateRange =
        JSON.parse(localStorage.getItem(retailerFiltersMap[this.retailer]))
          ?.date_range || {};
      const from = moment(dateRange.from, this.pointFormatDefault.dateFormat);
      const to = moment(dateRange.to, this.pointFormatDefault.dateFormat);
      const diff = to.diff(from, 'months');
      this.pointFormatValue =
        this.selectedRollUp?.key === 'rollup_days' &&
        diff > this.pointFormatDefault.numberOfMonthsToShowFor
          ? this.pointFormatDefault.hidePointValue
          : this.pointFormatDefault.showPointValue;
    },
    selectedMetricList(metricList) {
      this.showY2 = false;
      metricList.selectedMetric.forEach((item) => {
        if (!(item.key === 'Planned Budget' || item.key === 'Ad Spend')) {
          this.showY2 = true;
        }
      });
    },
    onTabClick(tab) {
      this.budgetType = tab;
    },
    openSeasonality() {
      this.$store.dispatch('budgetPlannerDashboardV2/resetVisitedSteps', [
        'uploadStep',
        'previewStep'
      ]);
      this.$store.dispatch(
        'budgetPlannerDashboardV2/setCreatePlanActiveSelection',
        2
      );
      this.$router.push({ name: 'create-budget-plan' });
    },
    onRollupChange(context, rollUp) {
      if (rollUp.length > 0) {
        this.selectedRollUp = rollUp[0];
        this.$store.dispatch(
          'budgetPlannerDashboardV2/setDashboardChartRollup',
          rollUp[0].key
        );
        this.$store.dispatch(
          'budgetPlannerDashboardV2/dashboardChartDataBP',
          this.config.widgets.widget1
        );
        this.checkDateRageForPoints();
      }
    },
    openSidePanel() {
      this.$emit('openSidePanel');
    }
  }
};
</script>

<style lang="css">
.budget-panner-dashboard .split-tabs-container .split-tabs {
  line-height: 1.3 !important;
}
.budget-panner-dashboard .split-tabs-container .split-tabs div {
  border-radius: 24px !important;
}
</style>
