import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { addCustomProductsCustomActionPanelState } from '@/pages/entity_details/configs/instacart/actionConfigs.js';
import { spAddKeywords } from '@/pages/entity_details/configs/instacart/addKeywordsConfig.js';

const bidBudget = () =>
  import('@/pages/entity_details/widget_templates/bidBudget/bidBudget.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const adGroupsSubType = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const objective = () =>
  import('@/pages/entity_details/widget_templates/objective.vue');
const settings = () =>
  import('@/pages/entity_details/widget_templates/instacart-settings.vue');
const adgroupsTargetingKeyword = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupKeywordTargeting.vue'
  );
const adgroupSettings = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupSettings.vue'
  );
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const adgroupDefaultBid = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');
const adgroupDefaultBidConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
  },
  reqMetadataMap: {
    ':oldBudget': 'default_bid',
    ':campaignId': 'parentEntityId'
  },
  config: {
    title: 'Default bid:',
    adjustButton: 'Adjust bid',
    adjustTitle: 'Adjust default bid:',
    type: 'default bid'
  },
  minMaxRestrictions: {
    show: true,
    min: 0.3,
    max: 85
  },
  isAdgroup: true,
  component: adgroupDefaultBid,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      campaignType: 'SP',
      adgroup_id: ':entityId',
      new_bid: ':newBudget'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name',
      view_old_bid: ':oldBudget',
      new_bid: ':newBudget'
    },
    actionType: 'instacartAdgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  }
};

const adGroupSettingsConfig = {
  isAdgroup: true,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      adgroup_id: ':entityId'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name'
    },
    actionType: 'instacartAdgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  },
  component: adgroupSettings,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
  }
};

const adgroupsTargetingKeywordConfig = {
  component: adgroupsTargetingKeyword,
  addCustomKeywordPanelConfig: spAddKeywords,
  searchText: 'instacart_cm_targeting_keyword',
  checkForSuggestedBid: (vueRef) => {
    vueRef.bidSuggestion = { show: false };
    vueRef.bidValue = null;
    if (
      vueRef.selections.length === 1 &&
      vueRef.selections[0]?.data?.instacart_cm_targeting_suggested_bid
    ) {
      vueRef.bidSuggestion = {
        show: true,
        title: 'Instacart Suggested Bid',
        value:
          vueRef.selections[0]?.data?.instacart_cm_targeting_suggested_bid.toString(),
        currentBid:
          vueRef.selections[0]?.data?.instacart_cm_targeting_latest_bid.toString(),
        multipleSuggestions: false
      };
    } else if (vueRef.selections.length > 1) {
      let showBidSuggestion = true;
      vueRef.selections.map((selection, index) => {
        if (!selection.data.instacart_cm_targeting_suggested_bid) {
          showBidSuggestion = false;
        }
      });
      vueRef.bidSuggestion = {
        show: showBidSuggestion,
        title: 'Instacart Suggested Bid',
        value: 'XX',
        currentBid: null,
        multipleSuggestions: false,
        suggestedBidNote:
          'XX - Respective Instacart suggested bid in the table below'
      };
    } else {
      vueRef.adjustBids = false;
    }
  },
  onSaveBid: (vueRef) => {
    const payloadArr = [];
    for (const selection of vueRef.selections) {
      const oldBid = selection?.data?.instacart_cm_targeting_latest_bid || 0;
      let newBid = vueRef.transformBid(
        oldBid,
        selection,
        'instacart_cm_targeting_suggested_bid'
      );
      if (newBid === oldBid) {
        vueRef.$snackbar.open({
          message: 'No new bid selected for one or more entities.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      } else {
        const payload = cloneDeep(vueRef.bidChangeRequestPayload);
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.instacart_cm_targeting_keyword_id,
          ':keywordText':
            selection?.data?.instacart_cm_targeting_keyword || 'NA',
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':matchType':
            selection?.data?.instacart_cm_targeting_match_type || 'NA',
          ':state': selection?.data?.instacart_cm_targeting_keyword_status,
          ':advertiserId': vueRef?.$route?.query?.advertiser_id
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
    }
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Keyword Bid',
        plural: 'Keyword Bids',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  actionType: 'instacartAdgroupMetadataChange',
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        adgroup_id: ':adgroupId',
        campaignType: 'SP',
        new_bid: ':newBid',
        keyword_id: ':keywordId'
      },
      viewPayload: {
        campaignId: ':campaignId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName'
      },
      actionType: 'instacartKeywordMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  }
};

const adgroupADsConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'active', color: 'u-color-green-base' },
        { title: 'Pause', value: 'inactive', color: 'u-color-orange-base' }
      ]
    }
  ],
  searchText: 'instacart_cm_sku_sku_name',
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        ad_id: ':ad_id',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName',
        ad_id: ':ad_id',
        asin_id: ':asin_id'
      },
      actionType: 'instacartProductMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  actionPanelAddCustomProductsConfig: addCustomProductsCustomActionPanelState,
  addCustomProducts: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SP',
        adgroup_id: ':adgroupId',
        sku: ':asin_id',
        asin: ':asin_id',
        state: 'enabled'
      },
      viewPayload: {
        campaignId: ':campaignId',
        sku: ':asin_id',
        asin: ':asin_id',
        product_title: ':asin_title',
        image_url: ':image_url',
        adgroupName: ':adgroupName'
      },
      actionType: 'addAsinCampaign',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  }
};

export default {
  campaign: {
    page: 'instacart_cm_campaign_page',
    metadata: [
      {
        action: 'entityDetails/fetchCampaingDetails',
        getters: 'entityDetails/getCampaignDetails'
      }
    ],
    header: {
      maxFeedDate: 'instacart_campaign',
      filters: {
        config: {
          emit: 'spCampaignDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'spCampaignDetailsAddToFilters'
        },
        filterLocalStorageKey: 'campaigns-filters',
        page: 'spCampaingDetails'
      },
      activityLog: {
        show: true
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        path: '/us/instacart/retail/advertising/campaign_management/campaigns'
      },
      // line 1 and 2 will wrap around date picker if its present
      metadata_api_state: {
        getter: 'entityDetails/getCampaignDetails'
      },
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Campaign',
              values: ['name'],
              hoverText: ['name'],
              titleClass:
                'u-font-weight-700 u-color-grey-lighter u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['NA']
            },
            class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
            itemType: 'attribute'
          }
        ],
        class: ''
      },
      line2: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Duration',
              values: ['startDate', 'endDate'],
              titleClass: null,
              delimiter: '-',
              valueClasses: null,
              altValues: ['No start date', 'No end date']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Daily budget',
              values: ['dailyBudgetView'],
              titleClass: null,
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Lifetime budget',
              values: ['lifetimeBudgetView'],
              titleClass: null,
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Type',
              values: ['campaignType', 'targetingTypeView'],
              titleClass: null,
              delimiter: '-',
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA', 'NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],

        class: 'u-spacing-pb-m u-font-size-5'
      },
      line3: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Budget Type',
              values: ['budgetType'],
              titleClass: null,
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Objective',
              values: ['objective_custom'],
              titleClass: null,
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Campaign ID',
              values: ['campaignId'],
              titleClass: null,
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Status',
              values: ['campaign_status_view'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-color-[color]-base u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            hide: true,
            fromMetadata: true,
            attribute: {
              title: 'Campaign ID',
              values: ['campaignId'],
              titleClass: null,
              delimiter: '',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-l u-font-size-5'
      }
    },
    chart: {
      page: 'instacart_cm_campaign_page',
      widget: 'instacart_cm_campaign_details_charting_wb',
      globalViewId: 0,
      pageId: 0,
      filter: 'spCampaignDetailsFiltersApplied'
    },
    tabs: {
      objective: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SP',
            new_objective: ':newObjective',
            new_objective_custom: ':newObjectiveCustom'
          },
          viewPayload: {
            campaignId: ':entityId',
            new_objective: ':newObjective',
            new_objective_custom: ':newObjectiveCustomView',
            old_objective: ':oldObjective',
            old_objective_custom: ':oldObjectiveCustom'
          },
          actionType: 'instacartCampaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: objective,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        }
      },
      bidBudget: {
        component: bidBudget,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        },
        requestPayload: {
          actionPayload: {
            campaignId: ':campaignId',
            campaignType: 'SP',
            new_lifetime_budget: ':new_lifetime_budget',
            optimized_bidding_enabled: ':optimized_bidding_enabled',
            new_daily_budget: ':new_daily_budget',
            roas_constraint: ':roas_constraint',
            new_optimized_bidding_goal: ':view_new_optimized_bidding_enabled'
          },
          viewPayload: {
            campaignId: ':campaignId',
            new_budget_type: ':new_budget_type',
            old_budget_type: ':old_budget_type',
            new_daily_budget: ':new_daily_budget',
            old_daily_budget: ':old_daily_budget',
            new_lifetime_budget: ':new_lifetime_budget',
            old_lifetime_budget: ':old_lifetime_budget',
            roas_constraint: ':roas_constraint',
            old_roas_constraint: ':old_roas_constraint',
            view_new_optimized_bidding_enabled:
              ':view_new_optimized_bidding_enabled',
            view_old_optimized_bidding_enabled:
              ':view_old_optimized_bidding_enabled'
          },
          actionType: 'instacartCampaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        },
        requestPayloadForManual: {
          actionPayload: {
            campaignId: ':campaignId',
            campaignType: 'SP',
            adgroup_id: ':adgroupId',
            new_bid: ':newBid'
          },
          viewPayload: {
            campaignId: ':campaignId',
            campaignType: 'featured_product',
            newBid: ':newBid',
            previousBid: ':previousBid',
            adgroupName: ':adgroupName'
          },
          actionType: 'instacartAdgroupMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        }
      },
      adGroups: {
        component: adGroups,
        stateChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'featured_product',
              adgroup_id: ':adgroupId',
              state: ':state'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_state: ':oldState',
              state: ':state'
            },
            actionType: 'instacartAdgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        allowedActions: [
          {
            icon: 'task-status',
            title: 'EDIT STATUS',
            id: 'editStatus',
            dropDown: true,
            dropDownOptions: [
              { title: 'Enable', value: 'active', color: 'u-color-green-base' },
              {
                title: 'Pause',
                value: 'inactive',
                color: 'u-color-orange-base'
              }
            ]
          },
          {
            icon: 'dollar',
            title: 'Adjust Bid',
            id: 'adjustBids'
          }
        ],
        bidChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'featured_product',
              adgroup_id: ':adgroupId',
              new_bid: ':newBid'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_bid: ':oldBid',
              new_bid: ':newBid'
            },
            actionType: 'instacartAdgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          getter: 'entityDetails/getCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        }
      },
      settings: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'featured_product'
          },
          viewPayload: {
            campaignId: ':entityId',
            name: ':name',
            view_old_name: ':view_old_name',
            view_new_end_date: ':view_new_end_date',
            view_old_end_date: ':view_old_end_date',
            view_old_start_date: ':view_old_start_date',
            view_start_date: ':startDateView',
            view_old_state: ':view_old_state'
          },
          actionType: 'instacartCampaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: settings,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        }
      }
    }
  },
  auto_adgroup: {
    page: 'instacart_cm_adgroup_page',
    metadata: [
      {
        action: 'entityDetails/fetchAdgroupDetails',
        getters: 'entityDetails/getAdgroupDetails',
        // campaignFetchAction: 'entityDetails/fetchCampaingDetails',
        campaignGetter: 'entityDetails/getCampaignDetails'
      }
    ],
    header: {
      activityLog: {
        show: true
      },
      maxFeedDate: 'instcart_campaign',
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Ad Group',
              values: ['name'],
              titleClass:
                'u-color-grey-lighter u-font-weight-700 u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['']
            },
            class: 'u-spacing-pt-xxs',
            itemType: 'attribute'
          }
        ],
        class: ''
      },
      line2: (vueRef) => {
        if (
          vueRef.$store.getters['entityDetails/getCampaignDetails'].data
            .optimized_bidding_enabled === true
        ) {
          return {
            items: [
              {
                fromMetadata: true,
                attribute: {
                  title: 'Ad Group ID',
                  values: ['agId'],
                  titleClass: null,
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              },
              {
                fromMetadata: true,
                attribute: {
                  title: 'Status',
                  values: ['stateView'],
                  titleClass: null,
                  delimiter: '',
                  valueClasses: ['u-color-[color]-base u-text-case-title'],
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              }
            ],
            class: 'u-spacing-pb-m u-font-size-5'
          };
        } else {
          return {
            items: [
              {
                fromMetadata: true,
                attribute: {
                  pre: [],
                  title: 'Max CPC Bid',
                  values: ['defaultBidView'],
                  titleClass: null,
                  delimiter: ' ',
                  valueClasses: null,
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              },
              {
                fromMetadata: true,
                attribute: {
                  title: 'Ad Group ID',
                  values: ['agId'],
                  titleClass: null,
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              },
              {
                fromMetadata: true,
                attribute: {
                  title: 'Status',
                  values: ['stateView'],
                  titleClass: null,
                  delimiter: '',
                  valueClasses: ['u-color-[color]-base u-text-case-title'],
                  altValues: ['NA']
                },
                class: 'u-spacing-pr-l',
                itemType: 'attribute'
              }
            ],
            class: 'u-spacing-pb-m u-font-size-5'
          };
        }
      },
      filters: {
        config: {
          emit: 'spAdgroupDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'spAdgroupDetailsAddToFilters'
        },
        filterLocalStorageKey: 'campaigns-filters',
        page: 'spAdgrpoupDetails'
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        config: {
          name: 'InstacartEntityDetails',
          params: {
            entityId: ':entityId',
            entityType: 'sp'
          }
        }
      },
      metadata_api_state: {
        getter: 'entityDetails/getAdgroupDetails'
      }
    },
    chart: {
      maxFeedDate: 'ams_campaign',
      widget: 'instacart_cm_adgroup_details_charting_wb',
      page: 'instacart_cm_adgroup_page',
      globalViewId: 0,
      pageId: 0,
      filter: 'spAdgroupDetailsFiltersApplied',
      config: {
        chartingWb: {
          ':widget': 'ChartingWB',
          ':timeseriesRollupBy': 'DAY',
          keepExistingDimensionNameValueList: true,
          replaceEntityInDimensionNameValueList: {
            ':ad_group_id': ':entityId'
          },
          timeseries: 'report_date'
        }
      }
    },
    tabs: {
      ads: {
        component: adgroupProductADs,
        ...adgroupADsConfig
      },
      keywords: adgroupsTargetingKeywordConfig,
      adgroupsubtypes: {
        component: adGroupsSubType,
        disableCreateAdgroup: true,
        disableRowSelection: true,
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          fetchAction: 'entityDetails/fetchAdgroupDetails',
          getter: 'entityDetails/getAdgroupDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        }
      },
      cpc: adgroupDefaultBidConfig,
      settings: adGroupSettingsConfig
    }
  }
};
