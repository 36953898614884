var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-width-100 goals"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100"},[_c('div',{staticClass:"u-display-inline-flex u-flex-align-items-center"},[_c('div',{staticClass:"u-display-inline-flex u-flex-align-items-center u-spacing-mr-l u-spacing-pr-l u-spacing-pt-s",class:{
          'disabled-actions': _vm.planRows.loading || _vm.disableToggles,
          'toggle-slider-off': !_vm.showPlansAtBULevel
        }},[_c('ToggleSlider',{attrs:{"original-state":_vm.showPlansAtBULevel},on:{"check":_vm.toggleEnterOPSGoal}}),_c('span',{staticClass:"u-font-size-5 u-spacing-pl-s"},[_vm._v("Enter "+_vm._s(_vm.goalOptions[_vm.goal].secondaryLabel)+" Goal at "+_vm._s(_vm.businessUnit.dimensionLabel)+" Level")])],1),_c('div',{staticClass:"u-display-flex u-flex-align-items-center",class:{ 'disabled-actions': _vm.planRows.loading }},[_c('span',{staticClass:"u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-pt-s u-spacing-mr-m"},[_vm._v(" Achieve OPS goal by:")]),_c('RadioGroup',{attrs:{"radio-options":Object.values(_vm.achieveOPSGoalByOptions),"value":_vm.achieveOPSGoalsBy,"container-class":'global-radio-wrapper u-spacing-mr-l u-spacing-mt-m'},on:{"onRadioChanged":_vm.handleRadioSelection}})],1)]),_vm._l((_vm.listOfCompetitivePlans),function(ref,key){
        var label = ref.label;
        var description = ref.description;
return _c('div',{key:key,staticClass:"u-spacing-mb-xl u-spacing-pb-s"},[(description)?_c('div',{staticClass:"u-display-inline-flex u-flex-align-items-center"},[(key === _vm.competitivePlans.moderately_aggressive_plan.id)?_c('rb-checkbox',{attrs:{"disabled":_vm.planRows.loading},model:{value:(_vm.enableModerateAggressivePlan),callback:function ($$v) {_vm.enableModerateAggressivePlan=$$v},expression:"enableModerateAggressivePlan"}},[_c('div',{staticClass:"u-font-size-5 u-color-grey-base u-spacing-mr-s"},[_vm._v(" "+_vm._s(description)+" ")])]):_vm._e(),(key === _vm.competitivePlans.highly_aggressive_plan.id)?_c('rb-checkbox',{attrs:{"disabled":_vm.planRows.loading},model:{value:(_vm.enableHighlyAggressivePlan),callback:function ($$v) {_vm.enableHighlyAggressivePlan=$$v},expression:"enableHighlyAggressivePlan"}},[_c('div',{staticClass:"u-font-size-5 u-color-grey-base u-spacing-mr-s"},[_vm._v(" "+_vm._s(description)+" ")])]):_vm._e(),(
            key === _vm.competitivePlans.moderately_aggressive_plan.id
              ? _vm.enableModerateAggressivePlan
              : _vm.enableHighlyAggressivePlan
          )?_c('div',{staticClass:"u-display-inline-flex u-flex-align-items-center u-width-100 u-cursor-pointer toggle-plan-visibility",on:{"click":_vm.onPlanEditClickHandlerMap[key]}},[_c('rb-icon',{staticClass:"rb-icon--medium u-color-grey-lighter u-spacing-ml-m",attrs:{"icon":_vm.showPlanMap[key] ? 'visibility-off' : 'pencil'}}),_c('span',{staticClass:"u-font-size-5 u-spacing-ml-s"},[_vm._v(" "+_vm._s(_vm.showPlanMap[key] ? 'Hide' : 'Edit')+" Plan ")])],1):_vm._e()],1):_vm._e(),(!description || _vm.showPlanMap[key])?_c('div',{staticClass:"u-spacing-pt-m plans-table"},[_c('Plan',{attrs:{"loading":_vm.planRows.loading,"column-definition":_vm.planColDef(key),"data-rows":_vm.planRowsMap[key],"header-text":label,"row-height":62,"header-height":110,"component-parent-context":_vm.componentContext,"dimensions":{
            width: '100%',
            height: _vm.showPlansAtBULevel ? '433px' : '246px'
          },"show-footer":true}},[_c('span',{staticClass:"u-color-grey-light u-font-size-7",attrs:{"slot":"footer"},slot:"footer"},[_c('b',[_vm._v("Disclaimer : ")]),_vm._v(_vm._s(_vm.historicalDataDisclaimer)+" ")])])],1):_vm._e()])}),_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('CollapsibleTitle',{staticClass:"u-spacing-pr-s",attrs:{"title":"Additional Inputs","is-expanded":_vm.showAdditionalInputs,"title-font-class":"u-font-size-4 u-font-weight-600","icon-class":!_vm.showAdditionalInputs ? 'rotate-270' : ''},on:{"click":_vm.toggleAdditionalInputVisible}}),_c('div',{staticClass:"u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"},[_vm._v(" Optional ")])],1),(_vm.showAdditionalInputs)?_c('div',{staticClass:"u-spacing-mt-m u-spacing-mb-l"},[_c('p',{staticClass:"u-font-size-5 u-color-grey-lighter u-spacing-pb-xl u-line-height-1-3"},[_vm._v(" "+_vm._s(_vm.additionalInputDescription)+" ")]),_c('div',{staticClass:"u-display-inline-flex u-flex-align-items-center u-spacing-pb-m toggle-slider",class:{
          'disabled-actions': _vm.planRows.loading || _vm.disableToggles,
          'toggle-slider-off': !_vm.showAdditionalInputsAtBULevel
        }},[_c('ToggleSlider',{attrs:{"original-state":_vm.showAdditionalInputsAtBULevel},on:{"check":_vm.handleAdditionalInputCheck}}),_c('span',{staticClass:"u-font-size-5 u-spacing-pl-s"},[_vm._v("Enter at "+_vm._s(_vm.businessUnit.dimensionLabel)+" Level")])],1),_c('div',{staticClass:"additional-inputs-table"},[_c('Plan',{attrs:{"column-definition":_vm.additionalInputsColDef,"data-rows":_vm.additionalInputDataRows,"header-text":"Additional Inputs","component-parent-context":this,"row-height":62,"header-height":110,"dimensions":{
            width: '100%',
            height: _vm.showAdditionalInputsAtBULevel ? '406px' : '343px'
          },"loading":_vm.planRows.loading || _vm.expectedGrowth.loading}}),(_vm.getShowErrorCondition())?_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-font-size-6 u-color-red-base u-spacing-mt-s"},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--small",attrs:{"icon":"error-fill"}}),_c('div',{staticClass:"u-flex-0 u-spacing-ml-xxs"},[_vm._v(" Sum of budget distribution should be equal to 100 ")])],1):_vm._e()],1)]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }