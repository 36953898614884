<template>
  <div
    class="u-display-inline-flex u-height-100 u-flex-justify-content-flex-end u-width-100 u-flex-align-items-center reverse-content"
  >
    <div
      class="budget-planner-custom-header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600"
    >
      <div
        style="font-size: 14px"
        class="budget-planner-custom-text-label u-color-grey-lighter reverse-content u-text-align-right"
      >
        {{ name }}<br /><span style="font-size: 11px">{{ range }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { retailerFiltersMap } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import moment from 'moment';

export default {
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    name() {
      return this.params.displayName || 'NA';
    },
    range() {
      const filterKey = this.getFilterKeyForRetailer(this);
      const budgetPlannerFilter = JSON.parse(
        localStorage.getItem(filterKey) || '{}'
      );
      const from = budgetPlannerFilter.date_range?.from || null;
      const to = budgetPlannerFilter.date_range?.to || null;
      return `${moment(from, 'YYYY-MM-DD').format('ll')} - ${moment(
        to,
        'YYYY-MM-DD'
      ).format('ll')}`;
    }
  },
  methods: {
    getFilterKeyForRetailer() {
      const retailer = this.$store.getters.getRetailer;
      return retailerFiltersMap[retailer];
    }
  }
};
</script>
