import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import { newToBrandMetricsMeasuresList } from '@/components/pages/instacart/campaigns/constants.js';
const {
  map,
  units,
  axes,
  getTagData,
  getColumnDefinition,
  createBulkSearchObjectForWidget,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('instacart', 'campaign');

export default {
  config: (that) => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) =>
        getColumnDefinition(...args, 'campaign'),
      filters: {
        emit: 'instacartCampaignsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'InstacartCampaignsSearchTriggered',
          placeholder: 'Search for a Campaign'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'campaignsChart',
            axisFormatMap: units,
            action: 'getInstacartCampaignsChartData',
            listen: {
              instacartCampaignsFilterApplied: {
                action: 'getInstacartCampaignsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              workbenchInstacartCampaignsPlotSelectedRows: {
                action: 'getInstacartCampaignsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data && data.length > 0) {
                    for (const element of data) {
                      config.meta.plotSelections.push({
                        dimensionName: 'campaign_id',
                        operator: 'ILIKE',
                        dimensionValue: element.campaign_id
                      });
                    }
                  }
                  return config;
                }
              },
              InstacartCampaignsSearchTriggered: {
                action: 'getInstacartCampaignsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getInstacartCampaignsChartData',
                'chart',
                'campaign'
              ),
              workbenchCampaignsPaginationTriggered: {
                action: 'getInstacartCampaignsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'campaigns_campaign'
          },
          body: {
            APIConfig: {
              cubeName: 'instacart_campaigns_workbench',
              customAPIDecisionVars: {
                system: 'instacart_campaign_management'
              },
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'report_date',
              pvpenabled: true,
              yoyenabled: false,
              measuresList: [
                'spend',
                'attributed_sales',
                'roas',
                'acos',
                'cpc',
                'impressions',
                'clicks',
                'ctr',
                'attributed_quantities',
                'linear_attributed_sales',
                'linear_roas',
                'linear_attributed_quantities',
                'missed_auction_participation_rate',
                'estimated_missed_impressions',
                'estimated_missed_sales',
                'cvr',
                'linear_cvr',
                ...newToBrandMetricsMeasuresList
              ],
              // measuresList: [
              //   'count',
              //   'daily_budget',
              //   'budget',
              //   'campaign_status',
              //   'campaign_type',
              //   'spend',
              //   'attributed_sales',
              //   'roas',
              //   'acos_14d',
              //   'cpc',
              //   'impressions',
              //   'clicks',
              //   'ctr',
              //   'attributed_quantities',
              //   'scheduled_start_at_date',
              //   'scheduled_end_at_date',
              //   'budget_utilization',
              //   'planned_budget_pace_ratio',
              //   'time_elapsed',
              //   'target_daily_budget',
              //   'pacing_method'
              // ],
              groupByDimensionsList: ['campaign_name', 'campaign_id'],
              where: {},
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ]
            },
            getTagUnitData(data) {
              let oReturn = {};
              let result = (data[0] || {}).RESULT;
              for (let i in result) {
                let translatedI = (map || {})[i] ? (map || {})[i] : i;
                oReturn[translatedI] = {
                  invertTag2: commons.isInverted(i),
                  tag1Unit: units[translatedI],
                  tag2Unit: {
                    suff: '%'
                  }
                };
              }
              return oReturn;
            },
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                axes: axes,
                show_axis_colors: true,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy'
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'campaignsTable',
            primaryKey: 'campaign_id',
            action: 'getInstacartCampaignsTableData',
            listen: {
              instacartCampaignsFilterApplied: {
                action: 'getInstacartCampaignsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              InstacartCampaignsSearchTriggered: {
                action: 'getInstacartCampaignsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getInstacartCampaignsTableData',
                'table',
                'campaign'
              )
            },
            paginationAction: 'workbenchCampaignsPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'campaigns_campaign'
          },
          body: {
            APIConfig: {
              cubeName: 'instacart_campaigns_workbench',
              getLatestAvailableInsteadOfRollup: false,
              customAPIDecisionVars: {
                system: 'instacart_campaign_management'
              },
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'enabled',
                'budget',
                'campaign_status',
                'campaign_type',
                'spend',
                'attributed_sales',
                'roas',
                'acos',
                'cpc',
                'impressions',
                'clicks',
                'ctr',
                'attributed_quantities',
                'scheduled_start_at_date',
                'scheduled_end_at_date',
                'budget_utilization',
                'planned_budget_pace_ratio',
                'time_elapsed',
                'target_daily_budget',
                'budget_type',
                'roas_constraint',
                'optimized_bidding_enabled',
                'campaign_bid_type',
                'linear_attributed_sales',
                'linear_roas',
                'linear_attributed_quantities',
                'missed_auction_participation_rate',
                'estimated_missed_impressions',
                'estimated_missed_sales',
                'cvr',
                'linear_cvr',
                'optimized_bidding_goal',
                ...newToBrandMetricsMeasuresList
              ],
              groupByDimensionsList: ['campaign_name', 'campaign_id'],
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ],
              limit: 30,
              page: 1
            },
            download: {
              fileName: 'CampaignTableData',
              columnMap: getColumnDefinition(that, 'campaign'),
              action: 'downloadCampaignsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'campaign'),
            rowHeight: 70
          }
        },
        addKeywordsPanel: {
          body: {
            APIConfig: {
              cubeName: 'ams_suggested_keywords_for_adgroups',
              measuresList: ['count'],
              groupByDimensionsList: [
                'client_id',
                'campaign_id',
                'adgroup_id',
                'keyword_text',
                'match_type',
                'bid'
              ],
              orderByList: [
                {
                  dimension: 'bid',
                  direction: 'DESC'
                }
              ],
              where: {
                dimensionNameValueList: []
              },
              limit: 200,
              page: 1
            }
          },
          tableConfigs: {
            metadata: {
              sourceTable: [
                {
                  name: 'keyword_text',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword_text',
                      showOnUi: true,
                      isFixed: false,
                      width: 170
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'new_bid',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Sugg Bid',
                    uiType: 'string',
                    uiOrder: '3',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'new_bid',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'Add all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Add all',
                    uiType: 'custom',
                    clickHeader: that.addAllKeywordsToTable,
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important; padding-left:15px',
                    headerIcon: {
                      icon: 'info-circle-fill',
                      toolTip: 'Add all keywords on this page'
                    },
                    uiOrder: '4',
                    metadata: {
                      toolTipText: 'Add keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      suppressSizeToFit: true,
                      isFixed: false,
                      iconClickEvent: that.addToSelection,
                      displayIcon: 'plus',
                      alternateIcon: 'check-fill-circle',
                      alternateIconClass: 'u-color-green-base',
                      width: 110
                    }
                  }
                }
              ],
              resultTable: [
                {
                  name: 'new_Keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'new_Keyword',
                      showOnUi: true,
                      isFixed: false,
                      width: 170
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'suggeted bid',
                  type: 'custom',
                  uiField: {
                    widget: 'icon',
                    uiLabel: 'Sugg bid',
                    uiType: 'custom',
                    uiOrder: '3',
                    metadata: {
                      toolTipText: 'Set suggested bid',
                      widget: 'icon',
                      type: 'iconText',
                      tableColumnName: 'bid',
                      formatType: 'currency',
                      displayIcon: 'trending-neutral',
                      showOnUi: true,
                      isFixed: false,
                      width: 110,
                      iconClickEvent: that.setDefaultValue
                      // contextReturnEvent: this.getArrowButtonContext
                    }
                  }
                },
                {
                  name: 'Bid',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Bid',
                    uiOrder: '4',
                    uiType: 'custom',
                    metadata: {
                      toolTipText: '',
                      widget: 'input',
                      type: 'number',
                      tableColumnName: 'bid',
                      defaultValueColumnName: 'newBid',
                      showOnUi: true,
                      isFixed: false,
                      formatType: 'currency',
                      width: 100,
                      keyupEvent: '',
                      // blurEvent: that.validateTableData
                      onchangeEvent: that.validateTableData
                      // contextReturnEvent: this.getInputFieldContext
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '5',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                    clickHeader: that.deleteAllKeywordsFromTable,
                    metadata: {
                      toolTipText: 'Remove keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeFromSelection,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 110
                    }
                  }
                }
              ]
            }
          }
        },
        addAsinsPanel: {
          body: {
            APIConfig: {
              cubeName: 'ams_campaign_asin_relationship',
              measuresList: [],
              groupByDimensionsList: [
                'asin',
                'product_title',
                'product_url',
                'image_url'
              ],
              orderByList: [
                {
                  dimension: 'product_title',
                  direction: 'ASC'
                }
              ],
              where: {
                dimensionNameValueList: []
              }
            }
          },
          tableConfigs: {
            metadata: {
              sourceTable: [
                {
                  // currDefinition
                  name: 'sku_details',
                  type: 'sku',
                  uiField: {
                    uiLabel: 'SKU Details',
                    uiType: 'custom',
                    widget: 'sku',
                    uiOrder: '1',
                    metadata: {
                      widget: 'sku',
                      toolTipText: '',
                      tableColumnName: 'SKU Details',
                      columnTitleKey: 'product_title',
                      columnAsinKey: 'asin',
                      columnImageUrlKey: 'image_url',
                      coloumnProductUrlKey: 'product_url',
                      showOnUi: true,
                      isFixed: false,
                      getQuickFilterText: function (params) {
                        return (
                          params.data.product_title + '_' + params.data.asin
                        );
                      }
                    }
                  }
                },
                {
                  name: 'Add all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Add all',
                    uiType: 'custom',
                    clickHeader: that.addAllAsinsToTable,
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important; padding-left:15px',
                    headerIcon: {
                      icon: 'info-circle-fill',
                      toolTip: 'Add all SKUs on this page'
                    },
                    uiOrder: '2',
                    metadata: {
                      toolTipText: 'Add this SKU',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      suppressSizeToFit: true,
                      isFixed: false,
                      iconClickEvent: that.addToSelection,
                      displayIcon: 'plus',
                      alternateIcon: 'check-fill-circle',
                      alternateIconClass: 'u-color-green-base',
                      width: 110
                    }
                  }
                }
              ],
              resultTable: [
                {
                  name: 'sku_details',
                  type: 'sku',
                  uiField: {
                    uiLabel: 'SKU Details',
                    uiType: 'custom',
                    uiOrder: '1',
                    metadata: {
                      widget: 'sku',
                      toolTipText: '',
                      tableColumnName: 'SKU Details',
                      columnTitleKey: 'product_title',
                      columnAsinKey: 'asin',
                      columnImageUrlKey: 'image_url',
                      coloumnProductUrlKey: 'product_url',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '2',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                    clickHeader: that.deleteAllAsinsFromTable,
                    metadata: {
                      toolTipText: 'Remove Sku',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeFromSelection,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 110
                    }
                  }
                }
              ]
            }
          }
        },
        fetchAddGroups: {
          body: {
            APIConfig: {
              cubeName: 'campaign_adgroups_mapping',
              measuresList: [],
              groupByDimensionsList: ['adgroup_name', 'adgroup_id'],
              where: {
                dimensionNameValueList: []
              }
            }
          }
        },
        adgroupsInstacart: {
          APIConfig: {
            cubeName: 'instacart_campaigns_ad_group_workbench',
            getLatestAvailableInsteadOfRollup: false,
            timeseriesEnabled: false,
            timeseriesDimension: 'report_date',
            pvpenabled: false,
            yoyenabled: false,
            measuresList: [],
            groupByDimensionsList: [
              'ad_group_name',
              'campaign_name',
              'ad_group_id',
              'campaign_id'
            ],
            dimensionList: [],
            orderByList: [
              {
                dimension: 'ad_group_name',
                direction: 'DESC'
              }
            ],
            dedupBeforeRollup: {
              enableDedupBeforeRollup: true,
              additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                ['ad_group_id']
            },
            where: {
              dimensionNameValueList: [
                {
                  dimensionName: 'campaign_id',
                  dimensionValue: '-'
                }
              ],
              date: {},
              pvpDate: {}
            }
          }
        },
        addNegativeKeywords: {
          body: {
            APIConfig: {
              cubeName: 'ams_campaign_asin_relationship',
              measuresList: [],
              groupByDimensionsList: [
                'asin',
                'product_title',
                'product_url',
                'image_url'
              ],
              orderByList: [
                {
                  dimension: 'product_title',
                  direction: 'ASC'
                }
              ],
              where: {
                dimensionNameValueList: []
              }
            }
          },
          tableConfigs: {
            metadata: {
              resultTable: [
                {
                  name: 'keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'Bid',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Bid',
                    uiOrder: '3',
                    uiType: 'custom',
                    metadata: {
                      toolTipText: '',
                      widget: 'input',
                      type: 'number',
                      tableColumnName: 'bid',
                      defaultValueColumnName: 'newBid',
                      showOnUi: true,
                      isFixed: false,
                      formatType: 'currency',
                      width: 110,
                      keyupEvent: '',
                      // blurEvent: that.validateTableData
                      onchangeEvent: that.validateTableData
                      // contextReturnEvent: this.getInputFieldContext
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '4',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:10px;',
                    clickHeader: that.removeAllKeywords,
                    metadata: {
                      toolTipText: 'Remove keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeKeyword,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 125
                    }
                  }
                }
              ]
            }
          }
        }
      }
    };
  }
};
