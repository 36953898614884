<template>
  <section class="u-font-size-5 u-position-relative u-width-100">
    <!-- adding any html here breaks the popup placement logic -->
    <section
      v-show="showTooltip"
      ref="bubble-chart-tooltip"
      class="bubble-chart-tooltip"
      :style="{
        transform: `translate(${tooltipLeft}px, ${tooltipTop}px)`
      }"
    >
      <slot
        :popupData="popupData"
        name="tooltip"
      />
    </section>
    <section
      v-show="showMidpointPopup"
      ref="bubble-chart-midpoint-tooltip"
      class="u-position-absolute bubble-chart-midpoint-tooltip"
      :style="{
        'z-index': 9999,
        transform: `translate(${midPointLeft}px, ${midPointTop}px)`
      }"
    >
      <section class="u-bg-color-grey-white box-shadows u-spacing-p-m">
        <div class="u-spacing-pb-m u-display-flex u-flex-align-items-center">
          <rb-icon
            class="u-cursor-pointer u-color-orange-base"
            :icon="'mid_point_inverted'"
          />
          <div
            class="u-spacing-ml-s u-color-grey-base u-font-size-3 u-font-weight-600"
          >
            Midpoint
          </div>
        </div>
        <BubbleChartPopupRow
          :axis-label="xAxisLabel"
          :coordinate="
            getCoordinate(
              xAxisOffset - xTranslationOffset,
              xAxisUnit,
              undefined,
              coordinatePrecision
            )
          "
          axis-heading="Horizontal Axis:"
        />
        <BubbleChartPopupRow
          class="u-spacing-mt-l"
          :axis-label="yAxisLabel"
          :coordinate="
            getCoordinate(
              yAxisOffset - yTranslationOffset,
              yAxisUnit,
              undefined,
              coordinatePrecision
            )
          "
          axis-heading="Vertical Axis:"
        />
      </section>
    </section>
    <section
      :id="chartId"
      class="u-width-100"
    />
  </section>
</template>

<script>
// import { debounce } from 'lodash';
import { formatter } from '@/utils/helpers/formatter.js';
import BubbleChartPopupRow from '@/components/pages/insights/amazon/share-of-voice/atoms/bubble-chart-popup-row.vue';
const d3 = require('d3');
export default {
  components: {
    BubbleChartPopupRow
  },
  props: {
    xScaleType: {
      type: String,
      default: 'scaleLog'
    },
    yScaleType: {
      type: String,
      default: 'scaleLog'
    },
    xScaleDomain: {
      type: Object,
      default: () => ({
        min: 0,
        max: 100
      })
    },
    yScaleDomain: {
      type: Object,
      default: () => ({
        min: 0,
        max: 100
      })
    },
    coordinatePrecision: {
      type: Number,
      default: 1
    },
    highlightSelectedBubbles: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 500
    },
    width: {
      type: Number,
      default: 1000
    },
    showForcedLabelsOnly: {
      type: Boolean,
      default: false
    },
    xAxisLabel: {
      type: String,
      default: ''
    },
    yAxisLabel: {
      type: String,
      default: ''
    },
    zAxisLabel: {
      type: String,
      default: ''
    },
    xAxisUnit: {
      type: String,
      default: 'NUMBER'
    },
    yAxisUnit: {
      type: String,
      default: 'NUMBER'
    },
    zAxisUnit: {
      type: String,
      default: 'NUMBER'
    },
    labelCountThreshold: {
      type: Number,
      default: 40
    },
    isZoomEnabled: {
      type: Boolean,
      default: true
    },
    isLassoEnabled: {
      type: Boolean,
      default: true
    },
    minZoomLevel: {
      type: Number,
      default: 0.8
    },
    maxZoomLevel: {
      type: Number,
      default: 10
    },
    maxBubbleRadius: {
      type: Number,
      default: 0
    },
    minBubbleRadius: {
      type: Number,
      default: 0
    },
    initialZoomLevel: {
      type: Number,
      default: 0.1
    },
    selectedBubbleColor: {
      type: String,
      default: '#007cf6'
    },
    defaultOpacity: {
      type: Number,
      default: 0.5
    },
    nonSelectedBubblesOpacity: {
      type: Number,
      default: 0.2
    },
    hoverOpacity: {
      type: Number,
      default: 1
    },
    xTranslationOffset: {
      type: Number,
      default: 0
    },
    yTranslationOffset: {
      type: Number,
      default: 0
    },
    quadrantColors: {
      type: Object,
      default: () => ({
        1: '#23b5d3',
        2: '#ffd500',
        3: '#ff6072',
        4: '#bd10e0'
      })
    },
    data: {
      type: Array,
      default: () => ({})
    },
    xAxisOffset: {
      type: Number,
      default: null
    },
    yAxisOffset: {
      type: Number,
      default: null
    },
    clickedBubble: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      centerPoint: null,
      bubbleClickId: null,
      midPointLeft: 0,
      midPointTop: 0,
      showMidpointPopup: false,
      minActiveLabel: { x: 0, y: 0 },
      maxActiveLabel: { x: 0, y: 0 },
      newScaledViewportExtremes: {},
      viewPortBubblesCount: 0,
      zoomLevel: null,
      zoomController: null,
      yAxisId: null,
      xAxisId: null,
      chartId: null,
      xAxis: null,
      yAxis: null,
      originalXAxisScale: null,
      originalYAxisScale: null,
      newXAxisScale: null,
      newYAxisScale: null,
      showTooltip: false,
      tooltipLeft: 0,
      tooltipTop: 0,
      popupData: {},
      lassoPath: null,
      lassoPolygon: null,
      closePath: null
    };
  },
  computed: {
    extremesOffset() {
      return this.maxBubbleRadius;
    },
    xMidpointOffset() {
      if (this.newXAxisScale) {
        return this.newXAxisScale(this.xAxisOffset);
      }
      return 0;
    },
    yMidpointOffset() {
      if (this.newYAxisScale) {
        return this.newYAxisScale(this.yAxisOffset);
      }
      return 0;
    }
  },
  watch: {
    isLassoEnabled: {
      immediate: true,
      handler(isLassoEnabled) {
        if (isLassoEnabled) {
          this.attachCrosshairCursor();
        } else {
          this.attachGrabCursor();
        }
      }
    },
    data: {
      immediate: false,
      handler(newData) {
        this.removeBubblesEventListeners();
        this.generateBubbles(newData);
        this.setQuadrantWiseColors();
      }
    },
    xAxisOffset: {
      immediate: false,
      handler(newXAxisOffset) {
        this.setXAxisOffset();
        this.setQuadrantWiseColors();
        // this.placeAllQuadrantLabels();
        this.placeCenterPoint();
      }
    },
    yAxisOffset: {
      immediate: false,
      handler(newYAxisOffset) {
        this.setYAxisOffset();
        this.setQuadrantWiseColors();
        // this.placeAllQuadrantLabels();
        this.placeCenterPoint();
      }
    }
  },
  created() {
    this.chartId = 'bubble-chart-' + this._uid;
    this.xAxisId = 'x-axis-' + this._uid;
    this.yAxisId = 'y-axis-' + this._uid;
    this.setZoomLevel(this.initialZoomLevel);
    this.setScales();
  },
  beforeDestroy() {
    this.removeD3DragListeners();
    this.removeSvgEventListerners();
    this.removeCenterPointEventListerners();
    this.removeBubblesEventListeners();
    this.removeZoomControllerListeners();
    this.removeLassoListeners();
  },
  mounted() {
    this.initChart();
    const xTickMinMax = {
      min: this.xScaleDomain.min,
      max: this.xScaleDomain.max
    };
    const yTickMinMax = {
      min: this.yScaleDomain.min,
      max: this.yScaleDomain.max
    };
    // incase of axis domains being equal, the zoom functionality becomes buggy
    this.xAxis = this.generateAxis(
      this.newXAxisScale,
      'axisBottom',
      this.xAxisId,
      xTickMinMax,
      this.xTranslationOffset
    );
    this.yAxis = this.generateAxis(
      this.newYAxisScale,
      'axisLeft',
      this.yAxisId,
      yTickMinMax,
      this.yTranslationOffset
    );

    this.attachZoomListener();
    this.generateBubbles();
    this.setQuadrantWiseColors();
  },
  methods: {
    setScales() {
      this.originalXAxisScale = d3[this.xScaleType]()
        .domain([this.xScaleDomain.min, this.xScaleDomain.max])
        .range([0, this.width]);
      this.newXAxisScale = this.originalXAxisScale;
      this.originalYAxisScale = d3[this.yScaleType]()
        .domain([this.yScaleDomain.min, this.yScaleDomain.max])
        .range([this.height, 0]);
      this.newYAxisScale = this.originalYAxisScale;
      this.$emit('axisScale', {
        x: this.newXAxisScale,
        y: this.newYAxisScale
      });
    },
    removeD3DragListeners() {
      d3.drag().on('start', null).on('drag', null).on('end', null);
    },
    removeSvgEventListerners() {
      const svg = this.getSvg();
      svg.on('mouseover', null);
      svg.on('mouseout', null);
      svg.on('click', null);
    },
    removeCenterPointEventListerners() {
      this.centerPoint
        .on('mouseover', null)
        .on('mouseout', null)
        .on('click', null);
    },
    removeBubblesEventListeners() {
      const circleGroups = this.getAllCircleTextGroups();
      circleGroups
        .selectAll('circle')
        .on('mouseover', null)
        .on('mouseout', null)
        .on('click', null);
    },
    removeZoomControllerListeners() {
      this.zoomController.on('zoom', null).on('end', null);
    },
    removeLassoListeners() {
      this.lasso().on('end', null).on('start', null);
    },
    getBubbleId(d) {
      return `${d.text}-${d.x}-${d.y}-${d.r}`;
    },
    attachGrabCursor() {
      const svg = this.getSvg();
      svg
        ?.on('mouseover', function (d) {
          d3.select(this).attr('cursor', 'grab');
        })
        ?.on('mouseout', function (d) {
          d3.select(this).attr('cursor', 'default');
        });
    },
    attachCrosshairCursor() {
      const svg = this.getSvg();
      svg
        ?.on('mouseover', function (d) {
          d3.select(this).attr('cursor', 'crosshair');
        })
        ?.on('mouseout', function (d) {
          d3.select(this).attr('cursor', 'default');
        });
    },
    normalizeValue(value, oldMax, oldMin, newMax = 100, newMin = 0) {
      const oldRange = oldMax - oldMin;
      if (oldRange === 0) return newMin;
      else {
        const newRange = newMax - newMin;
        return ((value - oldMin) * newRange) / oldRange + newMin;
      }
    },
    attachLabels(svg, axis) {
      const label = svg
        .append('g')
        .attr('class', `label-${axis} u-font-size-5 u-font-weight-600`);
      const midPointText = label
        .append('text')
        .attr('class', 'label-center u-color-grey-lighter');

      midPointText
        .append('tspan')
        .attr('class', 'label-midpoint u-color-grey-lighter');
      midPointText
        .append('tspan')
        .attr('class', 'midpoint-number u-color-orange-base');

      label.append('text').attr('class', 'axis-label u-color-grey-lighter');
    },
    getAxisLabels(axis) {
      const svg = this.getSvg();
      const label = svg.select(`.label-${axis}`);
      return label;
    },
    getCenterLabel(label) {
      return label.select('.label-center');
    },
    placeCenterLabel(axis, centerLabel, centerPoint, transformAttribute) {
      const label = this.getAxisLabels(axis);
      this.svgTextStyle(label);
      const midPointLabels = this.getCenterLabel(label);
      midPointLabels
        .attr('transform', transformAttribute)
        .attr('text-anchor', 'end');

      midPointLabels.select('.label-midpoint').text(centerLabel);
      midPointLabels.select('.midpoint-number').text(' ' + centerPoint);
    },
    placeAxialLabel(axis, axialLabel, transformAttribute) {
      const label = this.getAxisLabels(axis);
      this.svgTextStyle(label);
      label
        .select('.axis-label')
        .text(axialLabel)
        .attr('text-anchor', 'end')
        .attr('transform', transformAttribute);
    },
    placeYAxisLabel() {
      const { max } = this.newScaledViewportExtremes.y;
      const scaledXAxisOffset = this.newXAxisScale(this.xAxisOffset);
      const axialTransformAttribute = `translate(${scaledXAxisOffset - 4}, ${
        max + 10
      }) rotate(-90)`;
      this.placeAxialLabel('y', this.yAxisLabel, axialTransformAttribute);
    },
    placeXAxisLabel() {
      const { max } = this.newScaledViewportExtremes.x;
      const scaledYAxisOffset = this.newYAxisScale(this.yAxisOffset);
      const axialTransformAttribute = `translate(${max - 10}, ${
        scaledYAxisOffset - 4
      })`;
      this.placeAxialLabel('x', this.xAxisLabel, axialTransformAttribute);
    },
    initChart() {
      const that = this;
      const svg = d3
        .select('#' + this.chartId)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`)
        .on('click', function () {
          that.showTooltip = false;
          that.$emit('svgClick');
        });
      this.attachGrabCursor();
      const chart = svg.append('g').attr('class', 'chart');
      chart.append('g').attr('class', 'lasso-group');
      const axisArea = chart.append('g').attr('class', 'axis-area');

      const centerArea = chart.append('g').attr('class', 'center-area');
      chart.append('g').attr('class', 'bubbles-area');

      this.attachLabels(axisArea, 'x');
      this.attachLabels(axisArea, 'y');
      this.centerPoint = this.appendCustomSvg(
        centerArea,
        'center-point',
        '/chartIcons/midpoint_on_circle.svg'
      );
      this.centerPoint
        .on('mouseover', function (d) {
          that.attachMidpointPopup(d);
        })
        .on('mouseout', function (d) {
          that.removeMidpointPopup(d);
        })
        .attr('class', 'u-color-orange-base')
        .attr('width', '40px')
        .attr('height', '40px');
    },
    appendCustomSvg(svg, id, url) {
      return svg
        .append('g')
        .attr('id', id)
        .append('image')
        .attr('xlink:href', url);
    },
    placeSvg(svg, selector, x, y) {
      const quadrant = svg.select(selector);
      quadrant.attr('transform', `translate(${x}, ${y})`);
    },
    generateBubbleDataToClassMapping(d) {
      const base = `bubble-${d.x}-${d.y}-${d.r}`;
      const dotFormatted = base.replaceAll('.', '-');
      return dotFormatted;
    },
    generateBubbles() {
      const svg = this.getSvg();
      const that = this;
      const root = svg
        .select('.bubbles-area')
        .selectAll('.bubble-text-group')
        .data(this.data);

      const group = root
        .enter()
        .append('g')
        .attr('cursor', 'default')
        .attr('class', function (d) {
          return `bubble-text-group ${that.generateBubbleDataToClassMapping(
            d
          )}`;
        })
        .attr('transform', function (d) {
          return that.translateBubbleGroupToScale(d);
        });
      group.append('circle');
      group.append('text');
      this.generateBubbleCircle();
      this.generateBubbleText();
      this.generateBubbleIcon();
    },
    attachMidpointPopup() {
      this.showMidpointPopup = true;
      this.$nextTick(() => {
        const tooltipDimenions =
          this.$refs['bubble-chart-midpoint-tooltip'].getBoundingClientRect();
        const d = {
          x: this.xAxisOffset,
          y: this.yAxisOffset,
          r: 40 // size of center svg icon
        };
        const { top, left } = this.setTooltipPosition(d, tooltipDimenions);
        this.midPointLeft = left;
        this.midPointTop = top;
      });
    },
    removeMidpointPopup(d) {
      this.showMidpointPopup = false;
    },
    generateBubbleCircle(elem) {
      const that = this;
      const circleGroups = this.getAllCircleTextGroups();
      const circles = circleGroups.selectAll('circle');
      return circles
        .on('mouseover', function (d) {
          if (!that.clickedBubble) {
            that.attachD3Popup(d);
          }
          that.$emit('bubble-mouseover', that);
        })
        .on('mouseout', function (d) {
          if (!that.clickedBubble) {
            that.removeD3Popup(d);
          }
          that.$emit('bubble-mouseout', that);
        })
        .on('click', function (d) {
          d3.event.stopPropagation();
          const bubbleId = that.getBubbleId(d);
          if (this.bubbleClickId !== bubbleId) {
            this.bubbleClickId = bubbleId;
            that.attachD3Popup(d);
          }
          that.$emit('bubble-click', d);
        })
        .attr('r', function (d) {
          return d.r;
        });
    },
    svgTextStyle(elem) {
      elem
        .style('paint-order', 'stroke')
        .style('stroke', 'white')
        .style('stroke-width', '2px')
        .style('stroke-linecap', 'butt')
        .style('stroke-linejoin', 'miter');
    },
    generateBubbleText(elem) {
      const circleGroups = this.getAllCircleTextGroups();
      const text = circleGroups.selectAll('text');
      const that = this;
      this.svgTextStyle(text);
      this.minActiveLabel.x = Infinity;
      this.minActiveLabel.y = Infinity;
      this.maxActiveLabel.x = -Infinity;
      this.maxActiveLabel.y = -Infinity;
      text
        .text(function (d) {
          const label = that.showOrHideLabelText(d);
          if (label.length) {
            if (d.x < that.minActiveLabel.x) {
              that.minActiveLabel.x = d.x;
            }
            if (d.x > that.maxActiveLabel.x) {
              that.maxActiveLabel.x = d.x;
            }
            if (d.y < that.minActiveLabel.y) {
              that.minActiveLabel.y = d.y;
            }
            if (d.y > that.maxActiveLabel.y) {
              that.maxActiveLabel.y = d.y;
            }
          }
          return label;
        })
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('pointer-events', 'none')
        .attr('class', 'u-font-size-7')
        .attr('x', 0)
        .attr('y', 0);
    },
    generateBubbleIcon() {
      const circleGroups = this.getAllCircleTextGroups();
      circleGroups.each(function (d) {
        if (d.icon) {
          const circleGroupSvgElement = d3.select(this);
          const textSvgElement = circleGroupSvgElement.select('text');
          circleGroupSvgElement.select('.circle-icon')?.remove();
          if (textSvgElement.text() !== '') {
            d.icon.width = d.icon.width ?? 16;
            d.icon.height = d.icon.height ?? 16;
            circleGroupSvgElement
              .append('image')
              .attr('class', 'circle-icon')
              .attr('xlink:href', d.icon.path)
              .attr('width', d.icon.width)
              .attr('alignment-baseline', 'central')
              .attr('height', d.icon.height)
              .attr(
                'x',
                -((d.icon.width + textSvgElement.node().getBBox().width) / 2)
              )
              .attr('y', -(d.icon.height / 2));
            textSvgElement.attr('x', d.icon.width / 2);
          }
        }
      });
    },
    showOrHideLabelText(d) {
      try {
        if (this.showForcedLabelsOnly) {
          if (d.forceLabel) {
            return d.text || '';
          }
          return '';
        }
        if (
          d.forceLabel ||
          this.viewPortBubblesCount <= this.labelCountThreshold
        ) {
          return d.text || '';
        }
        const normalizedRadius = this.normalizeValue(
          d.r,
          this.maxBubbleRadius,
          this.minBubbleRadius
        );
        const normalizedZoom = this.normalizeValue(
          this.zoomLevel,
          this.maxZoomLevel,
          this.minZoomLevel
        );
        if (100 - normalizedZoom > normalizedRadius) {
          return '';
        }
        return d.text || '';
      } catch (e) {
        console.error('e', e);
        return '';
      }
    },
    positionAxis(
      oldAxis,
      axisScale,
      axisPosition,
      tickMinMax,
      translationOffset = 0
    ) {
      const { min, max } = tickMinMax;
      const tickValues = [
        ...this.positiveLogScale(1, max, translationOffset),
        ...this.negativeLogScale(-1, min, translationOffset)
      ].sort((a, b) => a - b);
      return oldAxis.call(
        d3[axisPosition](axisScale)
          .tickValues(tickValues)
          .tickFormat((d, i) => d - translationOffset)
      );
    },
    generateTicks(min, max, translationOffset, step) {
      const ticks = [];
      let currentValue = min;
      while (currentValue < max) {
        ticks.push(currentValue + translationOffset);
        currentValue *= step;
      }
      ticks.push(currentValue);
      return ticks;
    },
    positiveLogScale(min, max, translationOffset = 0, step = 10) {
      if (min <= 0 || max <= 0) {
        return [];
      }
      return this.generateTicks(min, max, translationOffset, step);
    },
    negativeLogScale(min, max, translationOffset = 0, step = 10) {
      if (min >= 0 || max >= 0) {
        return [];
      }
      return this.generateTicks(max, min, translationOffset, step);
    },
    generateAxis(
      axisScale,
      axisPosition,
      axisId,
      tickMinMax,
      translationOffset
    ) {
      const svg = this.getSvg();
      const newAxis = svg.select('.axis-area').append('g').attr('id', axisId);
      // .attr('stroke', '#8b8f93');
      const axis = this.positionAxis(
        newAxis,
        axisScale,
        axisPosition,
        tickMinMax,
        translationOffset
      );
      axis.select('.domain').attr('stroke', '#8b8f93');
      const ticks = axis.selectAll('.tick');
      ticks.select('line').attr('stroke', '#8b8f93');
      ticks.select('text').attr('fill', '#8b8f93');
      return axis;
    },
    setXAxisOffset() {
      const svg = this.getSvg();
      const translationOffset = this.newXAxisScale(this.xAxisOffset);
      const axisArea = svg.select('.axis-area');
      axisArea
        .select('#' + this.yAxisId)
        .attr('transform', `translate(${translationOffset}, 0)`);
      this.placeXAxisLabel();
      this.placeYAxisLabel();
    },
    setYAxisOffset() {
      const svg = this.getSvg();
      const translationOffset = this.newYAxisScale(this.yAxisOffset);
      const axisArea = svg.select('.axis-area');
      axisArea
        .select('#' + this.xAxisId)
        .attr('transform', `translate(0, ${translationOffset})`);
      this.placeXAxisLabel();
      this.placeYAxisLabel();
    },
    getCoordinate(position, unit) {
      return formatter(position, unit, undefined, this.coordinatePrecision);
    },
    setTooltipPosition(d, tooltipDimenions) {
      const scaledXMax = this.newXAxisScale.range()[1];
      const scaledYMax = this.newYAxisScale.range()[0];
      const scaledX = this.newXAxisScale(d.x);
      const scaledY = this.newYAxisScale(d.y);
      let top = 0;
      let left = 0;
      if (scaledX + tooltipDimenions.width < scaledXMax) {
        left = scaledX + d.r / 2;
      } else {
        left = scaledX - (tooltipDimenions.width + d.r / 2);
      }
      if (scaledY + tooltipDimenions.height < scaledYMax) {
        top = scaledY + d.r / 2;
      } else {
        top = scaledY - (tooltipDimenions.height + d.r / 2);
      }
      return { top, left };
    },
    attachD3Popup(d) {
      const classMap = '.' + this.generateBubbleDataToClassMapping(d);
      d3.select(classMap).select('circle').style('opacity', this.hoverOpacity);
      this.showTooltip = true;
      this.popupData = {
        ...d,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        zAxisLabel: this.zAxisLabel,
        xAxisUnit: this.xAxisUnit,
        yAxisUnit: this.yAxisUnit,
        zAxisUnit: this.zAxisUnit,
        coordinatePrecision: this.coordinatePrecision
      };
      this.$nextTick(() => {
        const tooltipDimenions =
          this.$refs['bubble-chart-tooltip'].getBoundingClientRect();
        this.setBubblePopupPosition(d, tooltipDimenions);
      });
    },
    setBubblePopupPosition(d, tooltipDimenions) {
      const { top, left } = this.setTooltipPosition(d, tooltipDimenions);
      this.tooltipLeft = left;
      this.tooltipTop = top;
    },
    removeD3Popup(d) {
      const classMap = '.' + this.generateBubbleDataToClassMapping(d);
      d3.select(classMap)
        .select('circle')
        .style('opacity', this.defaultOpacity);
      this.showTooltip = false;
    },
    setQuadrantWiseColors() {
      const svg = this.getSvg();
      const circles = svg.select('.bubbles-area').selectAll('circle');
      this.setBubbleColor(circles);
    },
    setBubbleColor(elem) {
      const that = this;
      try {
        elem
          .style('fill', function (d) {
            const quadrant = that.getQuadrant(d);
            return that.quadrantColors[quadrant];
          })
          .attr('stroke', (d) => {
            return d.selected || d.searchSelected
              ? that.selectedBubbleColor
              : '';
          })
          .attr('stroke-width', function (d) {
            return d.selected || d.searchSelected ? '2px' : '';
          })
          .attr('opacity', function (d) {
            if (that.highlightSelectedBubbles) {
              return d.selected || d.searchSelected
                ? that.defaultOpacity
                : that.nonSelectedBubblesOpacity;
            }
            return that.defaultOpacity;
          });
      } catch (e) {
        console.error('e', e);
      }
    },
    getQuadrant(point) {
      if (point.x >= this.xAxisOffset && point.y >= this.yAxisOffset) {
        return 1;
      }
      if (point.x < this.xAxisOffset && point.y >= this.yAxisOffset) {
        return 2;
      }
      if (point.x < this.xAxisOffset && point.y < this.yAxisOffset) {
        return 3;
      }
      if (point.x >= this.xAxisOffset && point.y < this.yAxisOffset) {
        return 4;
      }
    },
    getSvg() {
      return d3.select('#' + this.chartId).select('svg');
    },
    programmaticZoom(zoomLevel) {
      const svg = this.getSvg();
      this.setZoomLevel(zoomLevel);
      this.zoomController.scaleTo(
        svg.transition().duration(750),
        this.zoomLevel
      );
    },
    programmaticTranslate(x, y) {
      const svg = this.getSvg();
      this.zoomController.translateTo(svg.transition().duration(750), x, y);
    },
    setZoomLevel(zoomLevel) {
      if (zoomLevel < this.minZoomLevel) {
        this.zoomLevel = this.minZoomLevel;
      } else if (zoomLevel > this.maxZoomLevel) {
        this.zoomLevel = this.maxZoomLevel;
      } else {
        this.zoomLevel = zoomLevel;
      }
    },
    attachZoomListener() {
      const svg = this.getSvg();
      const translateExtent = [
        [0 - 300, 0 - this.extremesOffset - 150],
        [this.width + 300, this.height + this.extremesOffset + 150]
      ];
      this.zoomController = d3
        .zoom()
        .scaleExtent([this.minZoomLevel, this.maxZoomLevel])
        .translateExtent(translateExtent)
        .on('zoom', () => {
          if (d3.event?.sourceEvent?.type === 'mousemove') {
            svg.attr('cursor', 'grabbing');
          }
          this.zoomRerender();
        })
        .on('end', () => {
          svg.attr('cursor', 'grab');
        });
      svg.call(this.zoomController);
      this.programmaticZoom(this.initialZoomLevel);
    },
    getViewPortExtremes(xAxisScale, yAxisScale) {
      const [xMin, xMax] = xAxisScale.domain();
      const [yMin, yMax] = yAxisScale.domain();
      const newScaledViewportExtremes = {
        x: {
          min: xAxisScale(xMin),
          max: xAxisScale(xMax)
        },
        y: {
          min: yAxisScale(yMin),
          max: yAxisScale(yMax)
        }
      };
      return newScaledViewportExtremes;
    },
    zoomRerender() {
      if (!this.isZoomEnabled || this.clickedBubble) {
        return;
      }
      this.$emit('zoom');
      this.viewPortBubblesCount = 0;
      this.setZoomLevel(d3.event.transform.k);
      const that = this;
      const xTickMinMax = {
        min: this.xScaleDomain.min,
        max: this.xScaleDomain.max
      };
      const yTickMinMax = {
        min: this.yScaleDomain.min,
        max: this.yScaleDomain.max
      };
      this.newXAxisScale = d3.event.transform.rescaleX(this.originalXAxisScale);
      this.newYAxisScale = d3.event.transform.rescaleY(this.originalYAxisScale);
      this.newScaledViewportExtremes = this.getViewPortExtremes(
        this.newXAxisScale,
        this.newYAxisScale
      );
      this.xAxis = this.positionAxis(
        this.xAxis,
        this.newXAxisScale,
        'axisBottom',
        xTickMinMax,
        this.xTranslationOffset
      );
      this.yAxis = this.positionAxis(
        this.yAxis,
        this.newYAxisScale,
        'axisLeft',
        yTickMinMax,
        this.yTranslationOffset
      );

      const circleGroups = this.getAllCircleTextGroups();
      circleGroups.attr('transform', (d) => {
        return that.translateBubbleGroupToScale(d);
      });
      // const textNodes = circleGroups.selectAll('text');
      this.generateBubbleText();
      this.generateBubbleIcon();
      this.setXAxisOffset();
      this.setYAxisOffset();
      // this.placeAllQuadrantLabels();
      this.placeCenterPoint();
    },
    placeCenterPoint() {
      const svg = this.getSvg();
      const centerArea = svg.select('.center-area');
      const OFF_CENTER_MARGINS = 19.5;
      this.placeSvg(
        centerArea,
        '#center-point',
        this.newXAxisScale(this.xAxisOffset) - OFF_CENTER_MARGINS,
        this.newYAxisScale(this.yAxisOffset) - OFF_CENTER_MARGINS
      );
    },
    getSvgDimensions(svgSelector) {
      const svg = this.getSvg();
      return svg.select(svgSelector).node()?.getBoundingClientRect();
    },
    getLowerCoordinate(minValue, maxValue, sizeThreshold, padding) {
      let minCoordinate = 0;
      if (minValue + sizeThreshold + padding > maxValue) {
        minCoordinate = maxValue - sizeThreshold - padding;
      } else {
        minCoordinate = minValue + padding;
      }
      return minCoordinate;
    },
    polygonToPath(polygon) {
      return `M${polygon.map((d) => d.join(',')).join('L')}`;
    },
    distance(pt1, pt2) {
      return Math.sqrt((pt2[0] - pt1[0]) ** 2 + (pt2[1] - pt1[1]) ** 2);
    },
    getAllCircleTextGroups() {
      const svg = this.getSvg();
      return svg.selectAll('.bubble-text-group');
    },
    updateSelectedPoints(selectedPoints, points) {
      if (!selectedPoints.length) {
        points.forEach((d) => {
          d.color = 'tomato';
        });
      } else {
        points.forEach((d) => {
          d.color = '#eee';
        });
        selectedPoints.forEach((d) => {
          d.color = '#000';
        });
      }
    },
    translateBubbleGroupToScale(d) {
      const { x, y } = this.getTranslatedXYPoints(d);
      this.setViewportActiveCount(d.x, d.y);
      return `translate(${x},${y})`;
    },
    setViewportActiveCount(x, y) {
      const [xMin, xMax] = this.newXAxisScale.domain();
      const [yMin, yMax] = this.newYAxisScale.domain();
      if (x >= xMin && x <= xMax && y >= yMin && y <= yMax) {
        this.viewPortBubblesCount++;
      }
    },
    getTranslatedXYPoints(d) {
      const x = this.newXAxisScale(d.x);
      const y = this.newYAxisScale(d.y);
      return { x, y };
    },
    handleLassoEnd(lassoPolygon) {
      const that = this;
      const selectedPoints = this.data.filter((d) => {
        return d3.polygonContains(that.lassoPolygon, [
          that.newXAxisScale(d.x),
          that.newYAxisScale(d.y)
        ]);
      });
      this.updateSelectedPoints(selectedPoints, this.data);
      this.$emit('lassoSelect', selectedPoints);
    },
    handleLassoStart(lassoPolygon) {
      this.updateSelectedPoints([], this.data);
    },
    lasso() {
      const that = this;
      const dispatch = d3.dispatch('start', 'end');
      const closeDistance = 500;
      function lasso(root) {
        const g = root.select('.chart').select('.lasso-group');
        const bbox = root.node().getBoundingClientRect();
        const area = g
          .append('rect')
          .attr('class', 'lasso-area')
          .attr('width', bbox.width)
          .attr('height', bbox.height)
          .attr('fill', 'tomato')
          .attr('opacity', 0);
        const drag = d3
          .drag()
          .on('start', handleDragStart)
          .on('drag', handleDrag)
          .on('end', handleDragEnd);

        area.call(drag);

        function handleDragStart() {
          that.lassoPolygon = [d3.mouse(this)];
          if (that.lassoPath) {
            that.lassoPath.remove();
          }

          that.lassoPath = g
            .append('path')
            .attr('fill', '#0bb')
            .attr('fill-opacity', 0.1)
            .attr('stroke', '#0bb')
            .attr('stroke-dasharray', '3, 3');

          that.closePath = g
            .append('line')
            .attr('x2', that.lassoPolygon[0][0])
            .attr('y2', that.lassoPolygon[0][1])
            .attr('stroke', '#0bb')
            .attr('stroke-dasharray', '3, 3')
            .attr('opacity', 0);

          dispatch.call('start', lasso, that.lassoPolygon);
        }

        function handleDrag() {
          const point = d3.mouse(this);
          that.lassoPolygon.push(point);
          that.lassoPath.attr('d', that.polygonToPath(that.lassoPolygon));
          if (
            that.distance(
              that.lassoPolygon[0],
              that.lassoPolygon[that.lassoPolygon.length - 1]
            ) < closeDistance
          ) {
            that.closePath
              .attr('x1', point[0])
              .attr('y1', point[1])
              .attr('opacity', 1);
          } else {
            that.closePath.attr('opacity', 0);
          }
        }

        function handleDragEnd() {
          that.closePath.remove();
          that.closePath = null;
          if (
            that.distance(
              that.lassoPolygon[0],
              that.lassoPolygon[that.lassoPolygon.length - 1]
            ) < closeDistance
          ) {
            that.lassoPath.attr(
              'd',
              that.polygonToPath(that.lassoPolygon) + 'Z'
            );
            dispatch.call('end', lasso, that.lassoPolygon);
          } else {
            that.lassoPath.remove();
            that.lassoPath = null;
            that.lassoPolygon = null;
          }
        }

        lasso.reset = () => {
          if (that.lassoPath) {
            that.lassoPath.remove();
            that.lassoPath = null;
          }

          that.lassoPolygon = null;
          if (that.closePath) {
            that.closePath.remove();
            that.closePath = null;
          }
        };
      }

      lasso.on = (type, callback) => {
        dispatch.on(type, callback);
        return lasso;
      };

      return lasso;
    },
    removeLassoSelect() {
      this.lassoPath?.remove();
      d3.selectAll('.lasso-area').remove();
    },
    setLassoSelect() {
      const svg = this.getSvg();
      const lassoInstance = this.lasso()
        .on('end', this.handleLassoEnd)
        .on('start', this.handleLassoStart);
      svg.call(lassoInstance);
    }
  }
};
</script>

<style lang="css" scoped>
.metric {
  font-weight: 600;
}
circle {
  fill-opacity: 0.5;
}

.lasso path {
  stroke: rgb(80, 80, 80);
  stroke-width: 2px;
}

.lasso .drawn {
  fill-opacity: 0.05;
}

.lasso .loop_close {
  fill: none;
  stroke-dasharray: 4, 4;
}

.lasso .origin {
  fill: #3399ff;
  fill-opacity: 0.5;
}

.not_possible {
  fill: rgb(200, 200, 200);
}

.possible {
  fill: #ec888c;
}

.selected {
  fill: steelblue;
}
.bubble-chart-tooltip {
  position: absolute;
  z-index: 999999;
}
.hidden-tooltip {
  visibility: hidden;
}
.visible-tooltip {
  visibility: visible;
}
.box-shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
