var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-cursor-default u-spacing-mv-s"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: false }),expression:"{ placement: 'top', arrow: false }"}],staticClass:"loaderCard card u-spacing-ph-l u-flex-1 u-spacing-pv-m u-width-100",class:{ errorBorder: _vm.fileUploadStatus === 'error' },style:({
      background: _vm.fileUploadStatus === 'complete' ? '#3ea95e' : '#fff'
    }),attrs:{"title":_vm.filename}},[(_vm.fileUploadStatus === 'started')?_c('div',{},[_c('div',{staticClass:"u-display-flex u-width-100 u-flex-justify-content-space-between u-font-weight-600"},[_c('div',{staticClass:"u-font-size-4 u-font-weight-600 u-text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.filename)+" ")])]),_c('div',{staticClass:"u-spacing-mv-s",class:_vm.fileUploadStatus === 'started' ? 'loader' : ''})]):_c('div',{staticClass:"u-width-100 container"},[_c('div',{staticClass:"card-stack",class:{ 'u-color-grey-white': _vm.fileUploadStatus === 'complete' }},[_c('div',{staticClass:"u-font-size-6 u-width-100 u-display-flex u-flex-align-items-center"},[_c('rb-icon',{staticClass:"rb-icon--bp-custom",class:_vm.fileUploadStatus === 'error' ? 'u-color-red-base' : '',style:(_vm.fileUploadStatus === 'complete'
                ? ''
                : 'transform: rotate(180deg)'),attrs:{"icon":_vm.fileUploadStatus === 'complete'
                ? 'check-fill-circle'
                : 'info-circle-fill'}}),_c('div',{staticClass:"u-spacing-ph-s u-text-overflow-ellipsis u-width-100 u-font-weight-600 u-font-size-4 u-font-weight-600 u-text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.filename)+" ")])],1),_c('div',{staticClass:"u-font-size-6 u-width-100 u-display-flex u-flex-align-items-flex-start"},[_c('rb-icon',{staticClass:"u-visibility-hidden rb-icon--bp-custom",class:_vm.fileUploadStatus === 'error' ? 'u-color-red-base' : '',style:(_vm.fileUploadStatus === 'complete'
                ? ''
                : 'transform: rotate(180deg)'),attrs:{"icon":_vm.fileUploadStatus === 'complete'
                ? 'check-fill-circle'
                : 'info-circle-fill'}}),_c('div',{staticClass:"u-spacing-ph-s u-spacing-pv-xs u-text-overflow-ellipsis u-width-100 u-font-size-7 u-font-weight-600 u-text-overflow-ellipsis"},[_c('div',{class:_vm.fileUploadStatus === 'error' ? 'u-color-red-base' : ''},[_vm._v(" "+_vm._s(_vm.message)+" ")])])],1)]),_c('div',{staticClass:"buttons u-display-flex u-flex-justify-content-flex-end on-hover-show u-position-absolute"},[(
            (_vm.fileUploadStatus === 'error' ||
              _vm.fileUploadStatus === 'complete') &&
            _vm.filePath
          )?_c('div',{staticClass:"rb-icon rb-icon--x-m u-spacing-mh-s u-cursor-pointer icon-download",class:_vm.fileUploadStatus === 'error' ? '' : 'u-color-grey-white',on:{"click":function($event){return _vm.onClickDownload()}}}):_vm._e(),_c('span',{on:{"click":function($event){return _vm.onClickRemove(_vm.filename)}}},[(
              _vm.fileUploadStatus === 'error' || _vm.fileUploadStatus === 'complete'
            )?_c('rb-icon',{staticClass:"rb-icon--x-medium u-cursor-pointer",class:_vm.fileUploadStatus === 'error' ? '' : 'u-color-grey-white',attrs:{"icon":'trash'}}):_vm._e()],1)])])]),(_vm.fileUploadStatus === 'error')?_c('div',{staticClass:"u-display-flex u-spacing-m-xs u-flex-align-items-center",on:{"click":function($event){return _vm.onClickDownload()}}},[_c('rb-icon',{staticClass:"rb-icon--small",style:(_vm.fileUploadStatus === 'complete' ? '' : 'transform: rotate(180deg)'),attrs:{"icon":'info-circle-fill'}}),_c('div',{staticClass:"u-font-size-8 u-cursor-pointer u-spacing-ph-xxs"},[_vm._v(" Download To Fix ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }