import HttpLayer from '@/utils/services/http-layer';
import utils from '@/utils/helpers/';
import UrlFactory from '@/utils/services/url-factory';
const logger = {};

// Actual methods which logs in the backend. currently hardcoded to use logzio with fixed type.
logger.log = (logData, options) => {
  // options to log to other logging setups when available. defaulted to logz.io for now.
  if (!options) {
    HttpLayer.post({
      cube: 'LOGS',
      APIData: logData
    }).then((data) => {
      // console.log(data);
    });
    if (logData) {
      window.FS.event(logData?.eventType, logData);
    }
  }
};

logger.logEvent = (logData) => {
  logData.isExternalUser = !utils.internalUserCheck(window.user);
  if (navigator.sendBeacon && typeof navigator.sendBeacon === 'function') {
    try {
      const blob = new Blob([JSON.stringify(logData)], {
        type: 'application/json; charset=UTF-8'
      });
      navigator.sendBeacon(UrlFactory.getFinalURL('EVENT_LOGS'), blob);
    } catch (error) {
      HttpLayer.post({
        urlId: 'EVENT_LOGS',
        APIData: logData
      });
    }
  } else {
    HttpLayer.post({
      urlId: 'EVENT_LOGS',
      APIData: logData
    });
  }
  try {
    if (logData) {
      window.FS.event(logData?.eventType, logData);
    }
  } catch (err) {
    console.log('FS Event Error', err);
  }
};

logger.addLog = (logData) => {
  if (navigator.sendBeacon && typeof navigator.sendBeacon === 'function') {
    try {
      const blob = new Blob([JSON.stringify(logData)], {
        type: 'application/json; charset=UTF-8'
      }); // the blob
      navigator.sendBeacon(UrlFactory.getFinalURL('SEARCH_LOGS'), blob);
      console.log('NR Event sent via beacon', logData);
    } catch (error) {
      console.log('NR Event sent error:', error);
    }
  } else {
    logData.isExternalUser = !utils.internalUserCheck(window.user);
    HttpLayer.post({
      urlId: 'SEARCH_LOGS',
      APIData: logData
    });
  }
};

logger.getLogs = (logData) => {
  HttpLayer.post({
    urlId: 'SEARCH_LOGS',
    APIData: logData
  });
};

export default {
  install(Vue, options) {
    // options used for extensability.
    // in-future if additional logging are supported other than logz.io
    // this options objects can be used to enhance which logger needs to be
    // initialized.
    if (!options) {
      Vue.prototype.$logger = logger;
    }
  },
  logEvent: logger.logEvent,
  addLog: logger.addLog,
  getLogs: logger.getLogs
};
