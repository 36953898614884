import HomeV2 from '@/components/homeV2.vue';
import campaignManagementRoutes from '@/components/pages/instacart/campaign_management/route.js';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import Invite from '@/components/pages/invite/invite.vue';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import internalToolRoutes from '@/components/pages/ciq-internal-tools/route.js';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import budgetPlanner from '@/components/ams/budgetPlanner/budgetPlanner.vue';
import budgetPlannerRoutes from '@/components/ams/budgetPlanner/route.js';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import ActionLogs from '@/components/pages/action_logs/actionLogs.vue';
import Strategies from '@/components/pages/strategies/strategies.vue';
import CreateStrategy from '@/components/pages/strategies/create/createStrategy.vue';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import {
  campaignOptimizerChildrenRoutes,
  proxyLinksRoute
} from '@/router/commonRoutePaths.js';
import CONSTANTS from '@/utils/constants';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const instacartRoutes = {
  path: '/us/instacart/retail/',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        return 'advertising';
      }
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'advertising',
      component: AdvertisingLanding,
      children: [
        {
          path: 'hourly_bidder',
          component: PassThroughRoute,
          props: true,
          children: [
            {
              path: '',
              name: 'strategies',
              title: 'Hourly Bidder',
              component: Strategies,
              props: true
            },
            {
              path: 'create',
              name: 'create-strategy',
              component: CreateStrategy
            },
            {
              path: 'edit',
              name: 'edit-strategy',
              component: CreateStrategy
            }
          ].map((route) => ({
            ...route,
            meta: {
              productLine: CONSTANTS.PRODUCT_LINE.RMM
            }
          }))
        },
        {
          path: 'budget_optimizer',
          title: 'Budget Optimizer',
          component: budgetPlanner,
          children: budgetPlannerRoutes.routes
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: campaignManagementRoutes.routes.map((route) => ({
            ...route,
            meta: {
              ...route.meta,
              appendEsDataSetName: true
            }
          }))
        },
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes.map((route) => ({
            ...route,
            meta: {
              ...route.meta,
              appendEsDataSetName: true
            }
          }))
        },
        {
          path: '',
          redirect() {
            return 'campaign_management';
          }
        }
      ]
    },
    {
      path: 'actions_log',
      name: 'ActionLogs',
      title: 'Actions Log',
      component: ActionLogs,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    // this needs to be improved. Currently all internal tool pages are not nested inside /internalToolBox path.
    // so we are not having a childern path implementation like toolsAndSettings.
    ...internalToolRoutes.routes,
    ...[
      {
        path: 'invite',
        name: 'invite',
        component: Invite
      },
      {
        path: 'file-download',
        component: fileDownload
      },
      {
        path: 'ciq-efundamentals-announcement',
        name: 'ciq-efundamentals-announcement',
        component: CiqEfundamentalsFAQ
      }
    ].map((route) => ({
      ...route,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    })),
    proxyLinksRoute
  ]
};
export { instacartRoutes };
