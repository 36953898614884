import router from '@/router/index';
import utils from '../helpers/index';
var clickToExpandListenerPages = ['recommendations', 'data', 'insights'];

router.beforeEach((to, from, next) => {
  document.removeEventListener('click', clickToExpandListener);
  if (clickToExpandListenerPages.indexOf(to.path.split('/')[1]) !== -1) {
    document.addEventListener('click', clickToExpandListener);
  } else {
    document.removeEventListener('click', clickToExpandListener);
  }

  next();
});

var clickToExpandListener = function (event) {
  var previouslyOpened = document.getElementsByClassName('clickToExpandParent');
  for (var i = 0; i < previouslyOpened.length; i++) {
    previouslyOpened[i].classList = previouslyOpened[i].classList.value.replace(
      'clickToExpandParent',
      ''
    );
  }
};

export default {
  'click-outside': {
    bind: function (el, binding, vnode) {
      el.event = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.event);
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.event);
    }
  },
  'trend-indicator': {
    bind: function (el, binding, vnode) {
      el.className =
        (el.classList.value || '') +
        ' u-display-inline-flex u-flex-align-items-center';
      var value = parseFloat(el.textContent.trim());
      if (binding?.value?.value) {
        value = binding?.value?.value;
      } else if (utils.isNumeric(el?.attributes?.value?.value)) {
        // if the element has a value attribute use that to check if value is less or greater
        value = el.attributes.value.value;
      }
      if (!isNaN(value)) {
        var trendIcon = 'icon-arrow-up';
        if (value < 0) {
          trendIcon = 'icon-arrow-down';
          el.textContent = el.textContent.replace('-', '');
        }
        var icon = document.createElement('span');
        icon.className = 'rb-icon rb-icon--small u-spacing-mr-xs ' + trendIcon;
        el.insertBefore(icon, el.firstChild);
      }
    }
  },
  'click-to-expand': {
    bind: function (el) {
      el.onclick = function (event) {
        if (!el.closest) {
          return;
        }
        var totalLength = el.closest('tr').parentElement.childElementCount;
        var previouslyOpened = document.getElementsByClassName(
          'clickToExpandParent'
        );
        for (var i = 0; i < previouslyOpened.length; i++) {
          previouslyOpened[i].classList = previouslyOpened[
            i
          ].classList.value.replace('clickToExpandParent', '');
        }

        var expand = false;
        if (el.clientWidth < el.scrollWidth) {
          expand = true;
        } else if (el.children && el.children.length > 0) {
          const children = el.querySelectorAll('*');
          for (const child of children) {
            if (
              child.clientWidth < el.scrollWidth ||
              child.clientWidth < child.scrollWidth
            ) {
              expand = true;
              break;
            }
          }
        }
        if (expand) {
          this.classList = this.classList.value.replace(
            ' clickToExpandItem',
            ''
          );
          this.classList = (this.classList.value || '') + ' clickToExpandItem';
          el.parentElement.parentElement.classList =
            (el.parentElement.parentElement.classList.value || '') +
            ' clickToExpandParent';
          if (
            totalLength > 5 &&
            (el.closest('tr:last-child') ||
              el.closest('tr:nth-last-child(2)') ||
              el.closest('tr:nth-last-child(3)'))
          ) {
            el.closest('.cell').style.bottom = '5px';
            el.closest('.cell').style.top = 'auto';
          } else {
            el.closest('.cell').style.top = '5px';
            el.closest('.cell').style.bottom = 'auto';
          }
        }
        event.stopImmediatePropagation();
      };
    }
  },
  tracker: {
    bind(el, { arg: event, value }, vnode) {
      window.listeners = () => {
        console.log(el, value, event);
        // Push the new Relic event.
      };
      el.addEventListener(event, window.listeners);
    },
    unbind(el, { arg: event }, vnode) {
      el.removeEventListener(event, window.listener);
    }
  },
  tableCardCell: {
    bind(el, data, vnode) {
      const tableCardCellConfig = data.value;
      if (tableCardCellConfig && tableCardCellConfig.enabled) {
        const html = `
        <div class="u-border-width-s u-border-left u-border-right u-border-color-grey-xxx-light u-spacing-mh-m u-height-100
        u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center">
         ${el.innerHTML}
        </div>
      `;
        el.innerHTML = html;
      }
    }
  },
  histogram: {
    bind(el, data, vnode) {
      utils.histogramModification(el, data);
    }
  },
  progressFill: {
    bind(element, value, vnode) {
      const data = value.value;
      element.style.position = 'relative';
      const width = data.data[data?.config?.dataKey];
      const inverted = data?.config?.inverted;
      const colorFlag = width >= 0;
      const _colorFlag = inverted ? !colorFlag : colorFlag;
      const colorClass = _colorFlag
        ? 'u-bg-color-green-light-bg'
        : 'u-bg-color-red-light-bg';
      const htmlStr = `
        <div class="u-width-100 u-height-100 u-position-absolute">
          <div style="width: ${Math.abs(
            width
          )}%;" class="u-height-100 ${colorClass}">
          </div>
        </div>
      `;
      element.innerHTML = htmlStr + element.innerHTML;
    }
  },
  'viewport-visible': {
    bind(el, binding) {
      const options = binding.value.options ?? {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      };
      const observer = new IntersectionObserver(intersectionCallback, options);
      function intersectionCallback(entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            binding.value.onWidgetVisible(true);
            observer.unobserve(el);
          }
        });
      }
      observer.observe(el);
    }
  }
};
