const operator = {
  STRING_EQUAL_TO: 'exact',
  ILIKE: 'contains',
  NOT_ILIKE: 'does not contain',
  STARTSWITH: 'starts with',
  ENDSWITH: 'ends with',
  IS_NULL: 'is NULL',
  IS_NOT_NULL: 'is not NULL',
  EQUAL_TO: '=',
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '> =',
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL_TO: '< =',
  NOT_EQUAL_TO: '! =',
  BETWEEN: '> & <'
};
const operatorTitle = {
  '=': 'Equals',
  '>': 'Greater than',
  '> =': 'Greater than equal to',
  '<': 'Less than',
  '< =': 'Less than equal to',
  '! =': 'Not equal to',
  '> & <': 'Between'
};

export { operator, operatorTitle };
