<template>
  <div
    class="u-display-flex u-flex-align-items-center u-text-align-center"
    :class="wrapperClass"
  >
    <rb-icon
      v-if="icon"
      class="u-color-grey-lighter u-spacing-pr-xs"
      :icon="icon"
    />
    <span
      v-tippy="
        titleTooltip ? { placement: 'bottom-start', arrow: false } : null
      "
      :title="titleTooltip || null"
      class="u-font-size-3 u-color-grey-light u-font-weight-600 u-cursor-pointer"
      :class="titleClass"
      @click="onClickHeader"
    >
      {{ title }}
    </span>
    <span
      v-if="postTitleTemplate"
      class="u-spacing-pl-s"
      v-html="postTitleTemplate"
    />
    <span v-if="postTitleText">{{ postTitleText }}</span>
    <el-tooltip
      v-if="tooltipText"
      placement="bottom"
      effect="light"
      popper-class="title-with-icon-info-tooltip"
      :visible-arrow="false"
    >
      <rb-icon
        v-if="tooltipText"
        :class="
          postTitleIconClasses ||
          'u-color-grey-lighter rb-icon--small u-spacing-pl-s'
        "
        :icon="postTitleIcon"
      />
      <div
        slot="content"
        v-html="tooltipText"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    titleTooltip: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    postTitleText: {
      type: String,
      default: ''
    },
    postTitleTemplate: {
      type: String,
      default: ''
    },
    postTitleIcon: {
      type: String,
      default: 'info-circle-fill'
    },
    postTitleIconClasses: {
      type: String,
      default: ''
    },
    onClickHeader: {
      type: Function,
      default: () => {}
    },
    tooltipText: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="css">
.title-with-icon-info-tooltip {
  max-width: 444px !important;
  padding: 24px !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15) !important;
  border-color: #fff !important;
}
</style>
