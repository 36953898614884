export const metricsShownForAmazonChart = [
  'Ad Spend',
  'Ad Sales',
  'ROAS',
  'CPC',
  'Impressions',
  'CVR'
];

export const BoKeyTypeMapper = {
  newValueKey: {
    keyword: {
      keyName: 'bid',
      keyValueName: 'new_bid'
    },
    target: {
      keyName: 'newBid',
      keyValueName: 'new_bid'
    },
    campaign: {
      keyName: 'dailyBudget',
      keyValueName: 'new_daily_budget'
    }
  },
  oldValueKey: {
    keyword: {
      keyName: 'oldBid',
      keyValueName: 'current_bid'
    },
    target: {
      keyName: 'oldBid',
      keyValueName: 'current_bid'
    },
    campaign: {
      keyName: 'oldDailyBudget',
      keyValueName: 'daily_budget'
    }
  },
  typeId: {
    campaign: {
      keyName: 'campaignId',
      keyValueName: 'campaign_id'
    },
    keyword: {
      keyName: 'keywordId',
      keyValueName: 'keyword_id'
    },
    target: {
      keyName: 'targetId',
      keyValueName: 'target_id'
    }
  }
};

export const entityModeNames = {
  realTimeData: 'Real-time Data',
  comparePvP: 'Compare PvP'
};

export const entityModeMap = {
  [entityModeNames.realTimeData]: 'realtime',
  [entityModeNames.comparePvP]: 'pvp'
};

const colorMap = {
  GREEN: 'ag-row-green-text-color',
  ORANGE: 'ag-row-orange-text-color',
  RED: 'ag-row-red-text-color',
  GREY: 'ag-row-grey-text-color',
  LIGHT_GREY: 'ag-row-light-grey-text-color',
  BLACK: 'ag-row-black-text-color'
};

export const servingStatusMapping = {
  'Ad group archived': colorMap.LIGHT_GREY,
  'Ad group enabled': colorMap.GREEN,
  'Ad group incomplete': colorMap.LIGHT_GREY,
  'Ad group bid less than minimum allowed': colorMap.RED,
  'Ad group paused': colorMap.GREY,
  'Keyword archived': colorMap.LIGHT_GREY,
  'Keyword paused': colorMap.GREY,
  'Keyword suspended': colorMap.RED,
  'Keyword blocked': colorMap.RED,
  'Keyword ineligible no inventory': colorMap.GREY,
  'Campaign pending': colorMap.ORANGE,
  'Campaign Archived': colorMap.LIGHT_GREY,
  'Campaign out of budget': colorMap.ORANGE,
  'Campaign Paused': colorMap.GREY,
  'Campaign Ended': colorMap.BLACK,
  'Campaign Enabled': colorMap.GREEN,
  'Campaign Incomplete': colorMap.LIGHT_GREY,
  'SKU enabled': colorMap.GREEN,
  'SKU paused': colorMap.GREY,
  'SKU archived': colorMap.GREY,
  'SKU missing image': colorMap.ORANGE,
  'SKU missing decoration': colorMap.ORANGE,
  'SKU noy buyable': colorMap.RED,
  'SKU lost buy box': colorMap.ORANGE,
  'SKU out of stock': colorMap.RED,
  'Account eligibility status Null': colorMap.GREY,
  'Account enabled': colorMap.GREEN,
  'Account paused': colorMap.GREY,
  'Account out of budget': colorMap.RED,
  'Account payment failure': colorMap.RED,
  'Portfolio Out Of Budget': colorMap.ORANGE,
  'Portfolio Ended': colorMap.BLACK,
  'Ad Policing Suspended': colorMap.RED,
  'Targeting Clause Status Live': colorMap.GREEN
};

export const streamMetricTooltipWithData = `<div><span class="u-font-weight-600">value Today</span> <span>(date)</span></div> <span> This data is real-time and represents today's data. Real-time data is directional and subject to fluctuate slightly over the next 72 hours.</span>`;
export const streamMetricTooltip = `This data is real-time and represents today's data. Real-time data is directional and subject to fluctuate slightly over the next 72 hours.`;
export const streamMetricTooltipWithNoData = `<div><span class="u-font-weight-600">Today</span> <span>(date)</span></div><span class="u-font-weight-600 u-color-grey-light">No Real-time Data</span> present due to current filters or data is not avalaible`;
export const defaultCampaignCols = [
  'timeline',
  'campaign_name',
  'campaign_status',
  'serving_status',
  'spend',
  'paid_sales_14d',
  'roi',
  'iroas',
  'base_budget',
  'percent_time_in_budget',
  'cpc',
  'conversions_14d',
  'incremental_sales',
  'incremental_fraction',
  'PercentageSalesNewToBrand14d'
];
export const defaultKeywordsCols = [
  'timeline',
  'campaign_name',
  'status',
  'match_type',
  'keyword_text',
  'spend',
  'roi',
  'iroas',
  'base_bid',
  'clicks',
  'cpc',
  'ctr',
  'conversions_14d',
  'paid_sales_14d',
  'incremental_fraction'
];
export const defaultSearchTermsCols = [
  'timeline',
  'campaign_name',
  'match_type_keyword',
  'keyword_text',
  'search_term',
  'spend',
  'roi',
  'clicks',
  'cpc',
  'ctr',
  'conversions_14d',
  'paid_sales_14d'
];
export const defaultSkuCols = [
  'timeline',
  'campaign_name',
  'status',
  'campaign_type',
  'asin',
  'targeting_type',
  'spend',
  'paid_sales_14d',
  'roi',
  'cpc',
  'ctr',
  'conversions_14d',
  'units_14d',
  'orders_14d',
  'avg_selling_price',
  'rep_oos',
  'lbb_rate',
  'category_1',
  'category_1_best_seller_rank'
];
export const timeConstant = 60;

export const getterMapping = {
  campaignMetadataChange: 'getIsAllSelectedStateCampaigns',
  targetMetadataChange: 'getIsAllSelectedStateKeywords',
  keywordMetadataChange: 'getIsAllSelectedStateKeywords'
};

export const setMapping = {
  Keywords: 'setIsAllSelectedStateKeywords',
  Campaigns: 'setIsAllSelectedStateCampaigns'
};
