<!--Common wrapper for Orders and Line items page -->
<template>
  <div
    :key="name"
    :class="config.styleClass"
  >
    <messageBar
      :show="showBlueBar"
      :page-type="config.entity"
      @hideAction="hideBlueBar"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="showActionsLog"
        :data="activityLogData"
        :page-name="config.entity"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <dashboard-inflator
      :key="dashboardInflatorKey"
      :page="config.pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :filter="computedFilterSettings"
      :last-week="{ startDay: 'Sunday', endDay: 'Saturday' }"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
    >
      <div
        slot="slotBelowFilter"
        class="u-bg-color-white-base"
      >
        <component
          :is="componentBelowFilter.ui_component"
          v-if="componentBelowFilter && componentBelowFilter.show"
          v-bind="componentBelowFilter.props"
          :class="componentBelowFilter.customClass"
          v-on="componentBelowFilter.events"
        />
      </div>
      <div
        v-if="advancedReportingAvailable"
        slot="pre-right-panel"
      >
        <AdvancedReportingSwitch
          :advanced-reporting="advancedReporting"
          @toggleAdvancedReporting="toggleAdvanceReporting"
        />
      </div>
    </dashboard-inflator>
  </div>
</template>

<script>
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { cloneDeep } from 'lodash';
import modal from '@/components/widgets/modal.vue';
import { eventBus } from '@/utils/services/eventBus';
import { v4 as uuidv4 } from 'uuid';
import AdvancedReportingSwitch from '@/pages/dashboard_service_campaign_management/common_components/AdvancedReportingSwitch.vue';

export default {
  name: 'DashboardWrapper',
  components: { AdvancedReportingSwitch, messageBar, modal },
  mixins: [dashboardMixin],
  props: {
    name: {
      type: String,
      default: 'Orders'
    },
    dashboardConfig: {
      type: Object,
      default: {}
    },
    advancedReportingAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const context = this;
    return {
      config: null,
      context,
      gridOptions: {
        context: { componentParent: this },
        domLayout: 'normal',
        onRowDataChanged: this.rowDataChanged
      },
      showPlotRowsChip: false,
      showActionsLog: false,
      openActivity: false,
      rowSelections: [],
      activityLogData: {
        load: true,
        rows: null
      },
      showBlueBar: false,
      timer: 30000,
      columnKeyMapping: {},
      widgetMetaData: [],
      // custom-recommendations state variables
      recFilter: '',
      recCustomComponent: () => {},
      showRecCustomComponent: false,
      preferNoRecFilterList: [],
      selectedRecommendation: {},
      isCustomActionStateVisible: false,
      showModal: false,
      showComponentBelowFilter: false,
      advancedReporting: this.advancedReportingAvailable,
      dashboardInflatorKey: ''
    };
  },
  computed: {
    headerOptions() {
      // header options for the all the widgets passed in the dashboard inflator from the config file
      return this.config.dashboard.headerOptions(this.context);
    },
    modalConfig() {
      if (this.config?.dashboard?.modal?.modalConfig) {
        if (typeof this.config?.dashboard?.modal?.modalConfig === 'function') {
          const tableWidgetData = this.widgetMetaData.find(
            (widgetMetaDataItem) =>
              widgetMetaDataItem?.name === this.config?.widgetNames.table
          );
          return this.config?.dashboard?.modal?.modalConfig(
            this,
            tableWidgetData
          );
        }
        return this.config?.dashboard?.modal?.modalConfig;
      }
      return {};
    },
    componentBelowFilter() {
      if (this.config?.dashboard?.componentBelowFilter) {
        if (
          typeof this.config?.dashboard?.componentBelowFilter === 'function'
        ) {
          return this.config?.dashboard?.componentBelowFilter(this);
        }
        return this.config?.dashboard?.componentBelowFilter;
      }
      return null;
    },
    computedFilterSettings() {
      return this.config.dashboard.filterSettings;
    }
  },
  watch: {
    requestParams(newVal) {}
  },
  created() {
    this.initConstants();
    this.initAdvancedReporting();
    this.initialize();
  },
  beforeDestroy() {
    eventBus.$off(this.config.dashboard?.filterSettings?.emitEvent);
  },
  methods: {
    initConstants() {
      this.advanceReportingLocalStorageKey = `${this.$store.getters.getRetailer}-${this.name}-adv-reporting-enabled`;
    },
    initAdvancedReporting() {
      if (this.advancedReportingAvailable) {
        const advancedReporting = JSON.parse(
          localStorage.getItem(this.advanceReportingLocalStorageKey)
        );
        this.toggleAdvanceReporting(advancedReporting ?? true, false);
      }
    },
    initialize() {
      this.dashboardInflatorKey = uuidv4();
      let dashboard = {};
      if (typeof this.dashboardConfig[this.name] === 'function') {
        dashboard = this.dashboardConfig[this.name](
          this.$store.getters.getRetailer,
          this.advancedReporting
        );
      } else {
        dashboard = this.dashboardConfig[this.name];
      }
      // setting initial state for the following keys from the config file for DSP orders and line items
      const {
        pageInfo,
        components,
        initRequestParams,
        filterSettings,
        widgetNames,
        entity,
        styleClass,
        recCustomComponent = () => {},
        preferNoRecFilterList = []
      } = cloneDeep(dashboard);
      this.config = {
        pageInfo,
        components,
        initRequestParams,
        filterSettings,
        widgetNames,
        entity,
        styleClass,
        dashboard
      };
      this.recCustomComponent = recCustomComponent;
      this.preferNoRecFilterList = preferNoRecFilterList;
      this.updateEntireRequestParams(initRequestParams);
      this.registerComponents();
      if (this.config.dashboard?.onCreated) {
        this.config.dashboard?.onCreated(this);
      }
      eventBus.$on(this.config.dashboard?.filterSettings?.emitEvent, () => {
        this.config = cloneDeep(this.config);
      });
    },
    clickedOutsideModal(clickedOutside) {
      if (
        this.config?.dashboard?.modal?.clickedOutsideModal &&
        clickedOutside
      ) {
        this.config?.dashboard?.modal?.clickedOutsideModal(this);
      }
    },
    // event listener for dashboardServiceTable row selection
    handleSelectionChange(selections, manuallyDeselectGrid = false) {
      this.rowSelections = selections.map((sel) => ({ data: sel.data })) || [];
      if (this.rowSelections.length <= 0) {
        this.isCustomActionStateVisible = false;
      }
      const selectedNodes = this.gridOptions?.api?.getSelectedNodes();
      selectedNodes.forEach((node) => {
        if (
          !selections.find(
            (sel) => sel.data?.campaign_id === node.data?.campaign_id
          )
        ) {
          node.setSelected(false);
        }
      });
      if (manuallyDeselectGrid === true) {
        // this scenario happens when user clicks on x mark in acitons bar, we send true manually
        this.gridOptions?.api?.deselectAll();
      }
    },
    // event listener for chartingWb remove plot row chip
    removeChip() {
      this.handlePlotRows([]);
    },

    // event listener for plotting selected metrics of dashboardServiceTable in chartingWb component
    handlePlotRows(data) {
      const chartingRequestParams = cloneDeep(
        this.getWidgetRequestParams(this.config.widgetNames?.chart)
      );
      chartingRequestParams[':dimensionNameValueList'] = [];
      if (Array.isArray(data) && data.length) {
        data.forEach(({ data }) =>
          chartingRequestParams[':dimensionNameValueList'].push({
            dimensionName: this.config.dashboard.PRIMARY_KEY,
            dimensionValue: data[this.config.dashboard.PRIMARY_KEY]
          })
        );
        this.showPlotRowsChip = true;
      } else {
        this.showPlotRowsChip = false;
        this.handleSelectionChange([]);
      }
      this.updateWidgetRequestParams(
        chartingRequestParams,
        this.config.widgetNames.chart
      );
    },
    switchFunctionToCall() {},

    // event listener for activity log component triggered in dashboardServiceTable
    openActivityLog({ data }) {
      this.openSidePanel(data);
    },

    openSnackbar(content) {
      this.$snackbar.open({
        message: content,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },

    // fetch data to show in activity log component
    openSidePanel(data) {
      this.showActionsLog = true;
      this.openActivity = true;
      const res = {};
      HttpService.get(
        'AMS_ACTIONS',
        this.config.dashboard.getActivityData(
          data,
          this.config.dashboard.PRIMARY_KEY
        )
      )
        .then((response) => {
          res.data = response.data.response;
        })
        .catch((error) => {
          res.error = error;
        })
        .finally(() => {
          if (res.error) {
            this.activityLogData = {
              load: false,
              error: res.error
            };
            return;
          }
          this.activityLogData = {
            load: false,
            ...(res.data.length
              ? { rows: activitytransformer.getActivityData(res.data) }
              : { noData: true })
          };
        });
    },
    closeActivityLog() {
      this.showActionsLog = false;
      this.openActivity = false;
      this.activityLogData = {
        load: true,
        rows: []
      };
    },
    registerComponents() {
      // dynamic component registration as orders and line items may have different components
      for (const [key, value] of Object.entries(this.config.components)) {
        Vue.component(key, value);
      }
    },
    rowDataChanged(event) {
      this.handleSelectionChange([]);
      if (this.recFilter) {
        if (this.$options?.propsData?.name !== 'Orders') {
          this.gridOptions.api.selectAll();
        }
      }
    },
    // event listener to filter dashboardServiceTable based on selected recommendation
    recommendationFilter(val, label) {
      const tableRequestParams = cloneDeep(
        this.getWidgetRequestParams(this.config.widgetNames?.table)
      );
      if (val) {
        const [value] = Object.values(val);
        tableRequestParams[':dimensionNameValueList'].push({
          dimensionName: this.config.dashboard.recommendationKey,
          dimensionValue: value,
          operator: 'ILIKE'
        });
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
          return;
        }
        this.updateColumnMapping();
      } else {
        tableRequestParams[':dimensionNameValueList'] = [];
        this.recFilter = '';
        this.updateColumnMapping();
      }
      this.updateWidgetRequestParams(
        tableRequestParams,
        this.config.widgetNames.table
      );
    },

    // event listener for message bar
    hideBlueBar() {
      this.showBlueBar = false;
    },

    // event listener for message bar
    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    metadataUpdated(data) {
      this.widgetMetaData = cloneDeep(data);
    },

    // function to replace an existing column with a another
    updateColumnMapping() {
      const table = cloneDeep(
        this.widgetMetaData?.find(
          (e) => e?.name === this.config.widgetNames?.table
        )
      );
      if (table) {
        const columnKeyMapping = {};
        const metadataToReplaceKey = this.config.dashboard.metadataToReplaceKey;
        const { alias: metricAlias } = table.metrics[metadataToReplaceKey];
        const metadataToReplace = cloneDeep(
          table.metrics[metadataToReplaceKey].metadata
        );
        if (this.recFilter) {
          const metadataToReplaceWith =
            this.config.dashboard.metadataToReplaceWith(
              this.recFilter.value,
              cloneDeep(metadataToReplace)
            );
          columnKeyMapping[metricAlias] = metadataToReplaceWith;
          this.columnKeyMapping = columnKeyMapping;
        } else {
          columnKeyMapping[metricAlias] = metadataToReplace;
          this.columnKeyMapping = columnKeyMapping;
        }
      }
    },

    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    // event listener to capture the selected recommendation
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },

    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },

    toggleAdvanceReporting(val, init = true) {
      this.advancedReporting = val;
      localStorage.setItem(
        this.advanceReportingLocalStorageKey,
        JSON.stringify(val)
      );
      if (init) {
        this.initialize();
      }
    }
  }
};
</script>

<style lang="css">
.orders .u-envelop-width,
.line_items .u-envelop-width {
  max-width: initial;
}
.orders .dashboard-service-table-wrapper,
.line_items .dashboard-service-table-wrapper {
  margin: 0;
}
.orders .dashboard-service-table-wrapper .header-container-cwc,
.line_items .dashboard-service-table-wrapper .header-container-cwc {
  padding: 0;
  padding-top: 2.4rem;
}
.orders
  .dashboard-service-table-wrapper
  div[col-id='dsp_orders_recommendation_data']
  .rb-icon--small,
.line_items
  .dashboard-service-table-wrapper
  div[col-id='dsp_orders_recommendation_data']
  .rb-icon--small,
.orders
  .dashboard-service-table-wrapper
  div[col-id='dsp_line_items_recommendation_data']
  .rb-icon--small,
.line_items
  .dashboard-service-table-wrapper
  div[col-id='dsp_line_items_recommendation_data']
  .rb-icon--small {
  height: 16px;
  width: 16px;
}
.orders
  .dashboard-service-table-wrapper
  div[col-id='dsp_orders_recommendation_data']
  .rb-icon--small:before,
.line_items
  .dashboard-service-table-wrapper
  div[col-id='dsp_orders_recommendation_data']
  .rb-icon--small:before,
.orders
  .dashboard-service-table-wrapper
  div[col-id='dsp_line_items_recommendation_data']
  .rb-icon--small:before,
.line_items
  .dashboard-service-table-wrapper
  div[col-id='dsp_line_items_recommendation_data']
  .rb-icon--small:before {
  font-size: 1.6rem;
}
.orders .dashboard-service-table-wrapper .dynamic-component-container,
.line_items .dashboard-service-table-wrapper .dynamic-component-container {
  align-items: flex-start;
}
.orders
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .u-icon-text-action,
.line_items
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .u-icon-text-action {
  margin-right: 2.4rem;
  margin-left: 2.4rem;
  margin-bottom: 2.4rem;
}
.orders
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .u-spacing-mh-s
  > div.u-icon-text-action,
.line_items
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .u-spacing-mh-s
  > div.u-icon-text-action {
  margin: 0;
}
.orders
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .card__title,
.line_items
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .card__title {
  margin: 0;
  margin-right: 2.4rem;
  margin-left: 2.4rem;
}
.orders .dashboard-service-table-wrapper .custom_widget_container.card,
.line_items .dashboard-service-table-wrapper .custom_widget_container.card {
  border-top: none;
  box-shadow: none;
}
.orders .dashboard-service-table-wrapper .rec-heading,
.line_items .dashboard-service-table-wrapper .rec-heading {
  margin-top: -0.8rem;
  margin-left: 0.8rem;
}
.orders .dashboard-service-table-wrapper .custom-action-panel .action-header,
.line_items
  .dashboard-service-table-wrapper
  .custom-action-panel
  .action-header {
  padding-top: 0;
}
.orders .recommendations-container,
.line_items .recommendations-container {
  margin: 0;
}
.orders .recommendations-container .header-container-cwc,
.line_items .recommendations-container .header-container-cwc {
  padding: 0;
}
.orders .recommendations-container .count-chip,
.line_items .recommendations-container .count-chip {
  margin-top: -0.5rem;
}
.orders .recommendations-container .rec-card-container,
.line_items .recommendations-container .rec-card-container {
  padding-bottom: 0;
}
.orders .recommendations-container .custom_widget_body,
.line_items .recommendations-container .custom_widget_body {
  padding: 0;
}
.orders .recommendations-container .header,
.line_items .recommendations-container .header {
  padding-bottom: 0.2rem;
}
.orders
  .recommendations-container
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after,
.line_items
  .recommendations-container
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
.orders .recommendations-container .custom-action-panel .action-header,
.line_items .recommendations-container .custom-action-panel .action-header {
  padding-top: 0;
}
.orders .charting_wb_wrapper .header-container-cwc,
.line_items .charting_wb_wrapper .header-container-cwc {
  padding-bottom: 0;
}
.orders .charting_wb_wrapper .custom_widget_body,
.line_items .charting_wb_wrapper .custom_widget_body {
  margin-bottom: 2.4rem;
}
.orders .charting_wb_wrapper .override-padding,
.line_items .charting_wb_wrapper .override-padding {
  padding-top: 1.6rem;
}
.orders .bidChangePanel .title,
.line_items .bidChangePanel .title,
.orders .freqCapChangePanel .title,
.line_items .freqCapChangePanel .title {
  padding-top: 0;
}
.orders .dashboard-service-table .tableCell-icon:hover,
.line_items .dashboard-service-table .tableCell-icon:hover {
  color: #4b5158;
}
.generic-campaign-management
  .dashboard-service-table-wrapper
  .header-container-cwc {
  padding: 0;
}
.generic-campaign-management
  .dashboard-service-table-wrapper
  .dynamic-component-container
  .u-icon-text-action {
  margin: 1.6rem;
  text-align: center;
}

.background-red-warning {
  background-color: rgb(215, 38, 61, 0.1) !important;
}

.background-yellow-warning {
  background-color: rgba(255, 168, 0, 0.1);
}
</style>
