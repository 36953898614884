<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s u-spacing-p-m content-score-card"
  >
    <rb-select
      :on-close="dropdownClose"
      :options="dropdownOptions"
      :send-details="true"
      class="u-width-100"
    >
      <div
        slot="trigger"
        class="u-display-flex"
      >
        <div
          v-tippy="{ placement: 'top' }"
          :title="metricTitleTooltip"
          class="u-font-size-4 u-text-overflow-ellipsis u-color-grey-mid-light u-font-weight-600 u-cursor-pointer u-text-case-upper"
          @mouseenter="metricLabelHover"
        >
          {{ selectedOption && selectedOption.label }}
        </div>
        <rb-icon
          class="u-display-flex u-flex-justify-content-flex-end u-color-grey-lighter rb-icon--small u-spacing-ml-xs u-color-grey-base"
          icon="caret-down"
        />
      </div>
      <div
        slot="item"
        slot-scope="option"
      >
        <span>{{ option.label }}</span>
      </div>
    </rb-select>
    <span
      class="u-font-size-1 u-spacing-pt-xl u-spacing-pl-xs u-font-weight-600"
      :style="{
        color: getColors()
      }"
    >
      {{ contentScoreDetails.score + '%' }}
    </span>
    <line-chart-with-gradient
      class="u-spacing-mt-m"
      :chart-config="lineChartConfig"
      :chart-data="[contentScoreDetails.scoreTrend]"
    />
    <bar-chart-with-image
      :chart-data="contentScoreDetails.retailerContentScore"
    />
    <div
      class="view-sku u-display-flex u-spacing-mt-m u-cursor-pointer u-border-top u-border-width-s u-border-color-grey-xxx-light u-spacing-pt-xs"
    >
      <rb-icon
        class="icon-view-sku u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-s"
        :icon="'table-chart'"
      />
      <span
        class="view-sku-text u-font-weight-600 u-font-size-5 u-color-grey-lighter u-spacing-pt-xs"
      >
        View Retailers
      </span>
    </div>
  </div>
</template>

<script>
import lineChartWithGradient from '@/components/basic/lineChartWithGradient.vue';
import barChartWithImage from '@/pages/all_retailers/dso/molecules/barChartWithImage.vue';
export default {
  name: 'ContentScoreCard',
  components: {
    lineChartWithGradient,
    barChartWithImage
  },
  props: {
    contentScoreDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dropdownOptions: [
        { label: 'Overall Score', value: 'overall' },
        { label: 'Title Score', value: 'title' },
        { label: 'Image Score', value: 'image' },
        { label: 'Description', value: 'description' }
      ],
      lineChartData: [['contentScore', 20, 50, 40, 30, 40, 40, 50]],
      metricTitleTooltip: null
    };
  },
  computed: {
    selectedOption() {
      return this.dropdownOptions.find(
        (option) => option.value === this.contentScoreDetails.key
      );
    },
    lineChartConfig() {
      return {
        showArea: true,
        showGradrient: true,
        chartId: 'content-score',
        showTooltip: false,
        curved: false,
        showPoints: false,
        size: {
          width: 300,
          height: 100
        },
        dashedlines: [this.contentScoreDetails.key],
        colors: {
          [this.contentScoreDetails.key]: this.getColors()
        }
      };
    }
  },
  methods: {
    dropdownClose(_ctx, data) {
      console.log('event', data);
    },
    metricLabelHover(e) {
      const metricEl = e?.target;
      if (metricEl?.offsetWidth < metricEl?.scrollWidth) {
        this.metricTitleTooltip = metricEl.innerText;
      } else {
        this.metricTitleTooltip = null;
      }
    },
    getColors() {
      if (
        this.contentScoreDetails.score > 0 &&
        this.contentScoreDetails.score <= 65
      ) {
        return '#D7263D';
      } else if (
        this.contentScoreDetails.score > 65 &&
        this.contentScoreDetails.score <= 80
      ) {
        return '#FFA800';
      } else {
        return '#3EA95E';
      }
    }
  }
};
</script>

<style scoped lang="css">
.content-score-card {
  height: 400px;
  width: 25%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1.6rem;
}
</style>
