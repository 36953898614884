<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl">
    <createCampaignHeader
      class="u-spacing-pt-m"
      :step="step"
    />
    <div
      class="heading--input u-font-size-5 u-font-weight-600"
      data-cy="profileHeading"
    >
      Profile:
    </div>
    <div
      class="u-border-all u-position-relative u-border-radius-s u-border-width-s u-max-width-240px u-border-color-grey-xxx-light u-color-grey-base u-spacing-mt-s"
    >
      <loader
        v-if="profileLoader"
        class="fill--parent"
        :color="'#3fadf7'"
      />
      <rb-select
        class="typeSelect"
        :send-details="true"
        :on-close="onSelectProfileSelection"
        :options="settingsProfileOptions"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          data-cy="selectProfile"
        >
          <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
            <div class="u-display-flex u-flex-direction-column">
              <span
                class="u-font-size-7"
                data-cy="selectedProfile"
                >{{
                  (selectedProfile && selectedProfile.profile_name) || 'NA'
                }}</span
              >
            </div>
          </div>
          <rb-icon
            class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
            :icon="'caret-down'"
            data-cy="caretIcon"
          />
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div
            class="u-display-flex u-flex-align-items-center u-font-size-7"
            data-cy="selctedProfileName"
          >
            {{ option.profile_name }}
          </div>
        </template>
      </rb-select>
    </div>

    <div class="u-flex-align-items-center u-display-flex">
      <CollapsibleTitle
        class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
        title="Portfolio"
        :is-expanded="portfolioExpand"
        title-font-class="u-font-size-5 u-font-weight-600"
        data-cy="portfolioHeadingExpand"
        @click="() => (portfolioExpand = !portfolioExpand)"
      />
      <div class="u-spacing-pt-s">
        <div
          class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
          data-cy="optionalTxt"
        >
          Optional
        </div>
      </div>
    </div>
    <div v-if="portfolioExpand">
      <div class="u-spacing-pb-xl">
        <div
          class="u-font-size-3 u-font-weight-600"
          data-cy="whatIsPortfoliotxt"
        >
          What is portfolio
        </div>
        <div
          class="step-desc u-font-size-5 u-spacing-mt-s u-color-grey-lighter u-line-height-1-3"
          data-cy="portfolioDesc"
        >
          Campaign portfolios offer a new way to create custom groupings of your
          campaigns.
        </div>
      </div>
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="portfolioLabel"
      >
        Portfolio:
      </div>
      <div
        class="u-border-all u-spacing-mt-s u-position-relative u-max-width-240px u-border-radius-s u-border-width-s u-border-color-grey-xxx-light u-color-grey-base"
      >
        <loader
          v-if="profileLoader || portfolioLoader"
          class="fill--parent"
          :color="'#3fadf7'"
        />
        <rb-select
          class="typeSelect"
          :send-details="true"
          :on-close="onSelectPortfolioSelection"
          :options="settingsPortfolioOptions"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            data-cy="selectPortfolio"
          >
            <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
              <div class="u-display-flex u-flex-direction-column">
                <span
                  class="u-font-size-7"
                  data-cy="selectedPortfolio"
                  >{{
                    (selectedPortfolio && selectedPortfolio.portfolio_name) ||
                    'NA'
                  }}</span
                >
              </div>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              :icon="'caret-down'"
              data-cy="caretIconPortfolio"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-font-size-7"
              data-cy="selectedPortfolioname"
            >
              {{ option.portfolio_name }}
            </div>
          </template>
        </rb-select>
      </div>
    </div>

    <createCampaignFooter
      :disable-right-btn="false"
      :show-left-btn="step && step.enableBackButton"
      class="u-margin-top-40"
      data-cy="campaignFooter"
      @onClickBtnRight="nextStep"
      @onClickBtnLeft="prevStep"
      @onRightBtnDisabled="handleRightBtnDisabled"
    />
  </div>
</template>

<script>
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import loader from '@/components/basic/loader';
export default {
  components: {
    CollapsibleTitle,
    createCampaignHeader,
    createCampaignFooter,
    loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    suggestedCampaignIndex: 0,
    profileLoader: false,
    portfolioLoader: false,
    portfolioExpand: false,
    settingsProfileOptions: [],
    selectedProfile: {},
    settingsPortfolioOptions: [],
    selectedPortfolio: {},
    nullPortfolio: {
      portfolio_id: null,
      portfolio_name: 'None'
    }
  }),
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    this.profileLoader = true;
    HttpLayer.post({
      APIData: this.step.getProfiles
    })
      .then((response) => {
        this.settingsProfileOptions = transformer.mergeResultDimension(
          response.data,
          true
        );
        this.handleProfileSelect(this.settingsProfileOptions?.[0] || {}, true);
      })
      .finally(() => {
        this.profileLoader = false;
      });
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    updateBulletDescription(selectedProfile, selectedPortfolio) {
      if (selectedProfile && selectedPortfolio) {
        this.step.description = ` Selected profile: ${selectedProfile} and portfolio: ${selectedPortfolio}`;
      }
    },
    onSelectPortfolioSelection(i, data) {
      if (!data.length) return;
      this.handlePortfolioSelect(data[0], false);
    },
    onSelectProfileSelection(i, data) {
      if (!data.length) return;
      this.handleProfileSelect(data[0], false);
    },
    handlePortfolioSelect(selection) {
      this.selectedPortfolio = selection;
      const allStepsData = { ...this.allStepsData };
      this.updateBulletDescription(
        this.selectedProfile?.profile_name,
        this.selectedPortfolio?.portfolio_name
      );
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].portfolio =
        this.selectedPortfolio;
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
    },
    handleProfileSelect(selection, fromCreatedHook) {
      if (
        fromCreatedHook &&
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
          .profile
      ) {
        this.selectedProfile =
          this.allStepsData?.suggestedCampaigns[
            this.suggestedCampaignIndex
          ].profile;
      } else {
        this.selectedProfile = selection;
      }
      this.updateBulletDescription(
        this.selectedProfile?.profile_name,
        this.selectedPortfolio?.portfolio_name
      );
      const allStepsData = { ...this.allStepsData };
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].profile =
        this.selectedProfile;
      if (!allStepsData?.profile) {
        allStepsData.profile = this.selectedProfile;
      }
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
      if (
        fromCreatedHook &&
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
          .portfolio
      ) {
        this.portfolioForProfile(
          this.selectedProfile?.profile_id,
          this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
            .portfolio
        );
      } else {
        this.portfolioForProfile(this.selectedProfile?.profile_id);
      }
    },
    portfolioForProfile(id, selectedPortfolio) {
      const APIData = this.step.getPortfolios;
      APIData.where = {
        dimensionNameValueList: [
          {
            dimensionName: 'profile_id',
            dimensionValue: id,
            eoperator: 'EQUAL_TO'
          }
        ]
      };
      this.portfolioLoader = true;
      HttpLayer.post({
        APIData
      })
        .then((response) => {
          this.settingsPortfolioOptions = transformer.mergeResultDimension(
            response.data,
            true
          );
          this.settingsPortfolioOptions.unshift(this.nullPortfolio);
          this.handlePortfolioSelect(
            selectedPortfolio || this.settingsPortfolioOptions?.[0] || {}
          );
        })
        .finally(() => {
          this.portfolioLoader = false;
        });
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>
<style lang="css" scoped>
.select-dropdown {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
  color: #e9eaeb;
}
.fit-content {
  width: fit-content;
  min-width: 20%;
}
.budget-container {
  border-radius: 4px;
}
.budget-input {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
}
.rb-input {
  border: none !important;
}
</style>
