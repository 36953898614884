import HomeV2 from '@/components/homeV2.vue';
import Workbench from '@/components/pages/data/workbench.vue';
import WorkbenchRoutes from '@/components/pages/data/route.js';
import BusinessInsights from '@/components/pages/businessInsights/index.vue';
import ListingsHealth from '@/components/pages/listingsHealth/ListingsHealth.vue';
import SkuDetails from '@/components/pages/skuDetails/skuDetails.vue';
import CONSTANTS from '@/utils/constants';
import urlHelper from '@/utils/helpers/url';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import pipeline from '@/pages/pipeline/index.vue';
import { proxyLinksRoute } from '@/router/commonRoutePaths.js';
import NotFound from '@/pages/notFound';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import AdvertisingLanding from '@/pages/advertising/route_config/index.vue';
import AdvertisingRoutes from '@/pages/advertising/route_config/route.js';
import ActionLogs from '@/components/pages/action_logs/actionLogs.vue';

const amazonVariantRoutes = (retailer) => {
  return {
    path: `/us/${retailer}/retail`,
    name: 'Home',
    component: HomeV2,
    children: [
      {
        path: '',
        name: 'Home',
        redirect() {
          return urlHelper.getDefaultHomeRoute(
            `feature.pages.${retailer}.landingPage`,
            'business_overview'
          );
        }
      },
      {
        path: 'assortment',
        title: 'Assortment',
        name: 'workbenchSkusDefault',
        redirect() {
          return 'assortment/skus';
        },
        component: Workbench,
        children: WorkbenchRoutes.routes
      },
      {
        path: 'business_overview/:id?',
        name: 'businessInsights',
        title: 'Business Overview',
        component: BusinessInsights,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.ESM
        }
      },
      {
        title: 'Listings Health',
        path: 'listings_health',
        name: 'listingsHealth',
        productRoles: ['MarketingIQ', 'SalesIQ'],
        component: ListingsHealth,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.ESM
        }
      },
      {
        path: 'dp/:id?',
        name: 'SkuDetails',
        component: SkuDetails,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.ESM
        }
      },
      {
        path: 'internalToolBox',
        name: 'internalToolBox',
        component: internalToolBox
      },
      {
        path: 'account_manager',
        name: 'passwordManager',
        component: passwordManager
      },
      {
        path: 'dvtInternalTool',
        name: 'dvtInternalTool',
        component: DvtInternalTool
      },
      {
        path: 'mailValidator',
        name: 'mailValidator',
        component: MailValidator
      },
      {
        path: 'product_diagnostics',
        name: 'productDiagnostics',
        component: productDiagnostics,
        children: productDiagnosticsRoutes.routes
      },
      {
        path: 'e2e-overview',
        name: 'e2e-overview',
        component: pipeline,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.ESM
        }
      },
      {
        path: 'tools_and_settings',
        name: 'ToolsAndSettings',
        component: ToolsAndSettings,
        children: toolsAndSettingsRoutes.routes
      },
      {
        path: 'reports/',
        component: ReportsLanding,
        children: ReportsRoutes.routes
      },
      {
        path: 'digital_shelf',
        component: DigitalShelfLanding,
        children: DigitalShelfRoutes.routes
      },
      {
        path: 'advertising',
        component: AdvertisingLanding,
        children: AdvertisingRoutes.routes
      },
      {
        path: 'actions_log',
        name: 'ActionLogs',
        title: 'Actions Log',
        component: ActionLogs,
        meta: {
          productLine: CONSTANTS.PRODUCT_LINE.RMM
        }
      },
      proxyLinksRoute,
      {
        path: '*',
        component: NotFound,
        name: '404'
      }
    ]
  };
};

export { amazonVariantRoutes };
