import { metricsList } from '@/pages/campaign-management/retailers/walmart/additionalMetrics.js';
import { metricsList as samsclubMetricsList } from '@/pages/campaign-management/retailers/samsclub_api/additionalMetrics.js';

export const selectedMetrics = () => {
  return [
    {
      title: 'Ad Spend',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      key: 'Ad Spend',
      tooltip: 'Total spend on the campaign in the selected time period',
      alias: 'ad_spend',
      metricGroup: 'Campaigns',
      label: 'Ad Spend'
    },
    {
      title: 'Total Attributed Sales 14D',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      key: 'Total Attributed Sales 14D',
      tooltip:
        'Total sales generated on the campaign in the selected time period using 14 days attribution method',
      alias: 'total_attributed_sales',
      metricGroup: 'Campaigns',
      label: 'Total Attributed Sales 14D'
    }
  ];
};

export const incrementalMetrics = (metricGroup) => [
  {
    title: 'IROAS',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    chart: {},
    key: 'IROAS',
    tooltip:
      'Incremental Sales generated for each dollar of ad spend. This is an ML-driven metric that is currently in beta.',
    alias: 'iroas',
    ...(metricGroup ? { metricGroup: 'Campaigns' } : {}),
    label: 'IROAS'
  },
  {
    title: 'Incremental Fraction',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    chart: {},
    key: 'Incremental Fraction',
    tooltip:
      'Estimated share of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.',
    alias: 'incremental_fraction',
    ...(metricGroup ? { metricGroup: 'Campaigns' } : {}),
    label: 'Incremental Fraction'
  },
  {
    title: 'Incremental Sales',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    chart: {},
    key: 'Incremental Sales',
    tooltip:
      'Amount of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.',
    alias: 'incremental_sales',
    ...(metricGroup ? { metricGroup: 'Campaigns' } : {}),
    label: 'Incremental Sales'
  }
];

const createChartConfig =
  (retailer) =>
  (acc, [key, value]) => {
    acc.push({
      title: value.title,
      type: value.type,
      unit: value?.unit,
      chart: value.chart,
      key: value.title,
      alias: key,
      tooltip:
        value?.pages?.campaigns?.toolTipText ||
        value?.pages?.samsclub_api_campaigns?.toolTipText ||
        value?.toolTipText,
      metricGroup: 'Campaigns',
      label: value.title
    });
    return acc;
  };

const getMetricsMap = (retailer) =>
  Object.entries(
    retailer === 'walmart' ? metricsList : samsclubMetricsList
  ).reduce(createChartConfig(retailer), []);

export const nonWaterfallMetrics = (retailer) => [
  ...(getMetricsMap(retailer) || [])
];

export const shownMetrics = () => {
  return [
    ...selectedMetrics(),
    {
      title: 'Cost Per Click',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      chart: {
        shown: true
      },
      key: 'Cost Per Click',
      tooltip:
        'Average cost per click in the selected time period calculated as spend divided by clicks',
      alias: 'cpc',
      metricGroup: 'Campaigns',
      label: 'Cost Per Click'
    },
    {
      title: 'ROAS 14D',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      key: 'ROAS 14D',
      tooltip:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      alias: 'roas_14d',
      metricGroup: 'Campaigns',
      label: 'ROAS 14D'
    },
    {
      title: 'Impressions',
      type: 'NUMERIC',
      chart: {
        shown: true
      },
      key: 'Impressions',
      tooltip:
        'Total number of impressions generated from the campaign in the selected time period',
      alias: 'impressions',
      metricGroup: 'Campaigns',
      label: 'Impressions'
    },
    {
      title: 'Units Sold 14D',
      type: 'NUMERIC',
      key: 'Units Sold 14D',
      tooltip:
        'Total number of units sold from the campaign in the selected time period using 14 days attribution method',
      alias: 'units_sold',
      metricGroup: 'Campaigns',
      label: 'Units Sold 14D'
    }
  ];
};

export const baseMetricsList = () => [
  ...shownMetrics(),
  {
    title: 'CPA',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    chart: {},
    key: 'CPA',
    tooltip:
      'Cost Per Acquisition is the cost incurred to sell one unit from the campaign in the selected time period using 14 days attribution method calculated as Total spend divided by units sold.',
    alias: 'cpa',
    metricGroup: 'Campaigns',
    label: 'CPA'
  },

  {
    title: 'Clicks',
    type: 'NUMERIC',
    chart: {},
    key: 'Clicks',
    tooltip:
      'Total number of clicks generated from the campaign in the selected time period',
    alias: 'clicks',
    metricGroup: 'Campaigns',
    label: 'Clicks'
  },
  {
    title: 'ACoS 14D',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    chart: {},
    key: 'ACoS 14D',
    tooltip:
      'Advertised cost of sales generated on the campaign in the selected time period using 14 day attribution method calculated as Total spend/ attributed sales',
    alias: 'acos_14d',
    metricGroup: 'Campaigns',
    label: 'ACoS 14D'
  },
  {
    title: 'Click Through Rate',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    chart: {},
    key: 'Click Through Rate',
    tooltip:
      'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions',
    alias: 'ctr',
    metricGroup: 'Campaigns',
    label: 'Click Through Rate'
  }
];

export const placementBase = () => [
  {
    label: 'Search Ingrid ',
    title: 'Search Ingrid ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Search Ingrid ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'search_ingrid',
    labelPrefix: 'Search In Grid',
    aliasPrefix: 'search_in_grid'
  },
  {
    label: 'Buybox ',
    title: 'Buybox ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Buybox ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'buy_box',
    labelPrefix: 'Buy Box',
    aliasPrefix: 'buy_box'
  },
  {
    label: 'Homepage ',
    title: 'Homepage ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Homepage ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'home_page',
    labelPrefix: 'Home Page',
    aliasPrefix: 'home_page'
  },
  {
    label: 'Stock Up ',
    title: 'Stock Up ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Stock Up ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'stock_up',
    labelPrefix: 'Stock Up',
    aliasPrefix: 'stock_up'
  },
  {
    label: 'Carousel ',
    title: 'Carousel ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Carousel ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'carousel',
    labelPrefix: 'Carousel',
    aliasPrefix: 'carousel'
  },
  {
    label: 'Browse Ingrid ',
    title: 'Browse Ingrid ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Browse Ingrid ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'browse_in_grid',
    labelPrefix: 'Browse In Grid',
    aliasPrefix: 'browse_in_grid'
  },
  {
    label: 'Other Placement ',
    title: 'Other Placement ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Other Placement ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'other',
    aliasPrefix: 'others',
    labelPrefix: 'Others'
  }
];

export const platformBase = () => [
  {
    label: 'Desktop ',
    title: 'Desktop ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Desktop ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'desktop_units_sold',
    labelPrefix: 'Desktop',
    aliasPrefix: 'desktop'
  },
  {
    label: 'App ',
    title: 'App ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'App ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'app_units_sold',
    labelPrefix: 'App',
    aliasPrefix: 'app'
  },
  {
    label: 'Mobile ',
    title: 'Mobile ',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Mobile ',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'mobile_units_sold',
    labelPrefix: 'Mobile',
    aliasPrefix: 'mobile'
  },
  {
    label: 'Others Platform Unit Sold 14D',
    title: 'Others Platform Unit Sold 14D',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    key: 'Others Platform Unit Sold 14D',
    tooltip: 'Total spend on the campaign in the selected time period',
    alias: 'others_units_sold',
    labelPrefix: 'Others',
    aliasPrefix: 'others'
  }
];
