<template>
  <rb-select
    class="u-spacing-mr-s"
    :send-details="true"
    :options="options"
    :on-close="handleDropdownSelect"
  >
    <div
      slot="trigger"
      :class="[
        {
          'rule-builder-chip': chipSize === 'xs'
        },
        'u-font-size-' + textSize
      ]"
      class="u-display-flex u-flex-align-items-center u-cursor-pointer u-spacing-ph-xs u-spacing-pv-5px"
    >
      <slot
        name="icon"
        :color="selectedOption.color"
      >
        <rb-icon
          class="u-spacing-pl-xxs rb-icon--small u-spacing-mr-xs u-color-grey-lighter"
          :icon="icon"
        />
      </slot>
      <div
        v-if="preText"
        class="u-spacing-pl-xs u-spacing-mr-xs u-color-grey-lighter"
      >
        {{ preText }}
      </div>
      <div
        v-tippy="tippyOptions"
        class="overflow-styles"
        :class="[preText ? 'u-color-grey-base' : 'u-color-grey-lighter']"
        :title="!preText ? selectedOption.name : ''"
      >
        {{ selectedOption.name }}
      </div>
      <span class="u-spacing-mr-xs u-color-grey-lighter">
        {{ colon }}
      </span>
      <div
        class="u-color-grey-base"
        :class="['u-font-weight-' + valueFontWeight]"
      >
        {{ selectedOption.value }}{{ percentSign }}
      </div>
      <slot name="post-value" />
      <span class="u-spacing-pr-xxs">
        <rb-icon
          class="rb-icon--small u-color-grey-lighter"
          icon="caret-down"
        />
      </span>
    </div>
    <template
      slot="item"
      slot-scope="option"
    >
      <div
        class="u-display-flex u-flex-align-items-center"
        :class="[option.selected && 'u-color-grey-lighter']"
      >
        {{ option.name }}
      </div>
    </template>
  </rb-select>
</template>

<script>
export default {
  props: {
    chipSize: {
      type: String,
      default: 'xs'
    },
    textSize: {
      type: Number,
      default: 7
    },
    tippyOptions: {
      type: Object,
      default: () => ({})
    },
    preText: {
      type: String,
      default: null
    },
    hasPvp: {
      type: Boolean,
      default: false
    },
    valueFontWeight: {
      type: String,
      default: 'normal'
    },
    dropdownId: {
      type: Number,
      default: null
    },
    selectedOption: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'filter'
    }
  },
  data() {
    return {};
  },
  computed: {
    colon() {
      if (!this.preText) {
        return ':';
      }
      return '';
    },
    percentSign() {
      if (!this.preText) {
        return '%';
      }
      return '';
    }
  },
  methods: {
    handleDropdownSelect(context, selection) {
      this.$emit('optionSelect', {
        dropdownId: this.dropdownId,
        context,
        selection
      });
    }
  }
};
</script>

<style scoped lang="css">
.u-spacing-pv-5px {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
}
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
