<template>
  <section class="u-display-inline-flex">
    <tippy
      :ref="refId"
      to="infoTooltip"
      trigger="click"
      :distance="0"
      interactive="true"
      reactive="true"
      :hide-on-click="true"
      :duration="0"
      placement="bottom-end"
    >
      <InfoTable
        :header-text="headerText"
        :column-definition="columnDefinition"
        :data-rows="dataRows"
        :show-close-icon="true"
        :loading="loading"
        :class-name="'previous-period-data-container'"
        :show-footer="true"
        @onClose="onClose"
        @tableContext="tableContext"
      >
        <div
          slot="action-content"
          class="u-display-flex u-flex-align-items-center u-spacing-mv-m u-spacing-ml-m"
        >
          View Performance by:
          <div
            v-for="(item, i) in metricOptions"
            :key="i"
            class="u-spacing-pl-s"
          >
            <rb-button
              :click-fn="() => selectMetricType(i)"
              :text="item.label"
              :size="'s'"
              :class="{ 'rb-button--hollow-blue': item.isSelected }"
              class="u-cursor-pointer selected-outlined-btn-s"
            />
          </div>
        </div>
        <span
          slot="footer"
          class="u-color-grey-light u-font-size-7 u-font-weight-400"
        >
          <b>Disclaimer</b> : {{ historicalDataDisclaimerForSteps }} &
          {{ historicalDataDisclaimerForUncategorized }}
        </span>
      </InfoTable>
    </tippy>
    <div
      ref="trigger-content"
      name="infoTooltip"
      class="u-display-inline-flex"
      @click="onTrigger"
    >
      <slot
        name="trigger-content"
        class="u-cursor-pointer"
      />
    </div>
  </section>
</template>

<script>
import InfoTable from '@/components/ams/media-planner/generate-plan/components/info-table.vue';
import {
  HISTORICAL_DATA_DISCLAIMER_FOR_STEPS,
  HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED
} from '@/components/ams/media-planner/constants.js';
export default {
  name: 'TableTooltip',
  components: {
    InfoTable
  },
  props: {
    columnDefinition: {
      type: Array,
      default: () => []
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paginationPerPageLimit: {
      type: Number,
      default: 10
    },
    headerText: {
      type: String,
      default: 'Header'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    metricOptions: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    refId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpen: false,
      historicalDataDisclaimerForSteps: HISTORICAL_DATA_DISCLAIMER_FOR_STEPS,
      historicalDataDisclaimerForUncategorized:
        HISTORICAL_DATA_DISCLAIMER_FOR_UNCATEGORIZED.toLowerCase()
    };
  },
  computed: {
    autoConfigureOption() {
      return {
        rows: this.dataRows,
        columns: this.columnDefinition
      };
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        document.addEventListener('mousedown', this.handleClickOutside);
      } else {
        this.stopEventListener();
      }
    }
  },
  methods: {
    tableContext(context) {
      this.$emit('tableContext', context);
    },
    onClose() {
      this.$refs['trigger-content'].click();
      this.isOpen = false;
    },
    selectMetricType(type) {
      this.$emit('selectMetricType', type);
    },
    handleClickOutside({ target }) {
      if (this.isOpen) {
        const tippyInstance = this.$refs[this.refId].$el;
        if (tippyInstance && !tippyInstance.contains(target)) {
          this.onClose();
        }
      }
    },
    onTrigger() {
      this.isOpen = true;
    },
    stopEventListener() {
      this.isOpen = false;
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
};
</script>

<style lang="css" scoped>
.selected-outlined-btn-s,
.selected-outlined-btn-s:hover {
  border-radius: 50px !important;
  padding: 4px 8px !important;
  border: solid;
  border-width: 1px;
  border-color: #e9eaeb;
  font-weight: 400;
  color: #2b333b;
}

.rb-button--hollow-blue {
  border-color: #007cf6;
  color: #007cf6;
}
.rb-button--hollow-blue:hover {
  border-color: #007cf6;
  color: #007cf6;
  background: none !important;
}

.previous-period-data-container {
  margin: -10px;
}
</style>
