export default {
  map: {
    planned_budget_sum: {
      title: 'Cumulative Planned Budget',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    actual_spend_sum: {
      title: 'Cumulative Ad Spend',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    planned_budget_cumulative: {
      title: 'Planned Budget',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    actual_spend_cumulative: {
      title: 'Ad Spend',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    month: {
      title: 'feed_date',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    planned_budget_cumulative_monthly: {
      title: 'Planned Budget',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    actual_spend_cumulative_monthly: {
      title: 'Ad Spend',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    spend_vs_budget: {
      title: 'Spend / Budget'
    },
    spend_vs_pcogs: {
      title: 'Spend / PCOGS'
    },
    week: {
      title: 'feed_date'
    },
    planned_budget_cumulative_weekly: {
      title: 'Planned Budget',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    },
    actual_spend_cumulative_weekly: {
      title: 'Ad Spend',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method'
    }
  }
};
