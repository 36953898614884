import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import alerts from './modules/alerts';
import data from './modules/data';
import search from './modules/search';
import strategies from './modules/strategies';
import insightsOverview from './modules/insights_overview';
import insightsPriceWar from './modules/insights_pricewar';
import insightsSearchPerf from '@/pages/digital_shelf/share_of_voice/store.js';
import insightsSalesDashboard from './modules/insights_salesDashboard';
import insightsSales from './modules/insights_sales';
import insightsPOFillRate from './modules/insights_pofillrate';
import insightsReviews from './modules/insights_reviews';
import insightsContent from './modules/insights_content';
import insightsForecast from './modules/insights_forecast';
import insightShipmentTracker from './modules/insights_shipment_tracker';
import insightsPerformanceOverview from './modules/insights_performance';
import custom_reports from './modules/custom_reports';
import activityLog from './modules/activity_log';
import commonStore from './modules/common_store';
import campaigns from './modules/campaigns';
import keywords from './modules/keywords';
import skus from './modules/skus_m';
import searchTerms from './modules/search_terms';
import sov from './modules/sov';
import app from './modules/app';
import { actions } from './actions';
import { mutations } from './mutations';
import subscription from './modules/subscription';
import userPageMetadataConfig from './modules/userPageMetadataConfig';

import strategyList from '../components/pages/strategy_list/strategyList.store.js';
import strategyBuilder from '../components/pages/strategy_builder/strategyBuilder.store.js';
import actionLogs from '../components/pages/action_logs/actionLogs.store.js';
import dvtStore from './modules/internal_tools/dvt.store';
import catalogIngestion from './modules/internal_tools/internalCatalogIngestion.js';
import mailValidatorStore from './modules/internal_tools/mailValidator.store';
import budgetPlanner from '../components/ams/budgetPlanner/budgetPlanner.store.js';
import budgetPlannerDashboard from '../components/ams/budgetPlanner/dashboard/budgetPlannerDashboard.store.js';
import budgetPlannerActions from '../components/ams/budgetPlanner/actions/budgetPlannerActions.store.js';
import sqlDataValidatorStore from './modules/internal_tools/sqlValidator.store';
import productDiagnosticsStore from './modules/internal_tools/productDiagnostics.store';
import customWidgetBenchMarking from '../components/widgets/custom_widgets/custom_widget_components/benchmarking/benchMarking.store';
import businessInsightsFilterState from '../components/pages/businessInsights/businessInsights.store.js';
import performancewb from '../components/pages/businessInsights/performancewb.store.js';
import topMovers from '../components/pages/businessInsights/topMovers.store.js';
import filter from '../components/widgets/filter/filter.js';

import regionalAvailability from '@/pages/digital_shelf/regional_availability/store.js';

import alertStore from '@/pages/recommendations/recommendationsLanding/store.js';
import bookmarkStore from './modules/bookmark.store.js';
import manageEntity from './modules/entityTagging/manageEntity';
import tagsStore from './modules/entityTagging/tagsStore';
import customTableStore from '@/store/modules/customTableStore.js';
import instacartCampaigns from './modules/instacart_campaigns';
import instacartSkus from './modules/instacart_skus';
import instacartAdGroups from './modules/instacart_adGroups';
import instacartKeywords from './modules/instacart_keywords';
import campaignManagement from '@/pages/campaign-management/store.js';
import entityDetails from '@/pages/entity_details/store/index.js';
import marketShare from '@/components/pages/insights/amazon/market-share/market_share.js';
import campaignCreation from '@/components/ams/campaign_creation/store.js';
import walmartCampaignCreation from '@/components/ams/campaign_creation/walmart_steps/store.js';
import samsclub_apiCampaignCreation from '@/components/ams/campaign_creation/samsclub_api_steps/store.js';
import instacartCampaignCreation from '@/components/pages/instacart/campaign-creation/store/index.js';
import mediaPlanner from '@/components/ams/media-planner/store/index.js';
import cvaRem from '@/components/pages/insights/amazon/cva/store/index.js';
import budgetPlannerOmni from '@/pages/budget-planner-v2/create-plan/store/budgetPlannerOmni.store.js';
import budgetPlannerDashboardV2 from '@/pages/budget-planner-v2/dashboard/store/budgetPlannerDashboardV2.store.js';
import systemTransparencyDashboard from '@/components/widgets/system-transparency/store/index.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app,
    login,
    alerts,
    search,
    strategies,
    insightsOverview,
    insightsPriceWar,
    insightsSearchPerf,
    insightsReviews,
    insightsSales,
    insightsPOFillRate,
    insightsSalesDashboard,
    insightsPerformanceOverview,
    insightsContent,
    insightsForecast,
    data,
    campaigns,
    keywords,
    skus,
    searchTerms,
    sov,
    commonStore,
    activityLog,
    subscription,
    strategyList,
    actionLogs,
    strategyBuilder,
    dvtStore,
    insightShipmentTracker,
    catalogIngestion,
    mailValidatorStore,
    budgetPlanner,
    budgetPlannerDashboard,
    budgetPlannerActions,
    sqlDataValidatorStore,
    customWidgetBenchMarking,
    productDiagnosticsStore,
    businessInsights: {
      ...businessInsightsFilterState
    },
    performancewb,
    topMovers,
    alertStore,
    bookmarkStore,
    custom_reports,
    manageEntity,
    tagsStore,
    instacartCampaigns,
    instacartSkus,
    instacartAdGroups,
    instacartKeywords,
    regionalAvailability,
    filter,
    campaignManagement,
    entityDetails,
    'marketshare-category': marketShare,
    marketShareOverview: marketShare,
    MARKETSHARESKU: marketShare,
    'marketshare-category-lite': marketShare,
    'marketShareOverview-lite': marketShare,
    campaignCreation,
    customTableStore,
    walmartCampaignCreation,
    instacartCampaignCreation,
    cvaRem,
    samsclub_apiCampaignCreation,
    budgetPlannerOmni,
    mediaPlanner,
    budgetPlannerDashboardV2,
    systemTransparencyDashboard,
    userPageMetadataConfig
  },
  actions,
  mutations
});
