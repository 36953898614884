<template>
  <contentTableCell
    :label="generatedLabel"
    :na-text="naText"
    :na-tooltip="naTooltip"
    :params="params"
    :range-color-map="rangeColorMap"
    :show-na-label="showNaLabel"
    :type="type"
    :value="value"
    :container-classes="containerClasses"
    :show-custom-bg-color="showCustomBgColor"
    :tippy="tippy"
    :show-custom-tippy="showCustomTippy"
  />
</template>

<script>
import contentTableCell from '@/pages/digital_shelf/content/content_table_cell.vue';

export default {
  name: 'TextWithColor',
  components: {
    contentTableCell
  },
  props: {
    label: {
      type: [String, Number],
      default: ''
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: 'null'
    },
    params: {
      type: Object,
      default: null
    },
    rangeColorMap: {
      type: Array,
      default: null
    },
    showNaLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'string'
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      containerClasses:
        'text-with-color u-width-100 u-height-100 u-display-flex u-flex-align-items-center',
      showCustomBgColor: true,
      tippy: {
        placement: 'top-start',
        delay: [500, 0],
        animation: 'scale',
        interactive: true,
        reactive: true,
        maxWidth: '200px',
        theme: 'click-to-expand',
        duration: [150, 0],
        arrow: false
      },
      showCustomTippy: true
    };
  },
  computed: {
    generatedLabel() {
      let finalLabel = '';
      let parsedLabel = this.isJsonParsable(this.label);

      if (!parsedLabel) {
        finalLabel = null;
        this.showCustomBgColor = false;
        this.showCustomTippy = false;
        return finalLabel;
      }

      parsedLabel?.map((labelItem, index) => {
        const labelItemObj = labelItem[Object.keys(labelItem)[0]];
        if (labelItemObj.is_present === true) {
          this.showCustomBgColor = false;
          finalLabel += `<span class="is-present-label">${
            Object.keys(labelItem)[0]
          }</span>`;
        } else {
          finalLabel += Object.keys(labelItem)[0];
        }

        if (index < parsedLabel.length - 1) {
          finalLabel += ' | ';
        }
      });

      return finalLabel;
    }
  },
  methods: {
    isJsonParsable(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return string;
      }
    }
  }
};
</script>

<style lang="css">
.is-present-label {
  color: #3ea95e !important;
  font-weight: 400 !important;
}
</style>
