<template>
  <div class="bulk-search-input">
    <div
      v-if="isBulkSearchActive"
      class="u-display-flex"
    >
      <rb-icon
        icon="search"
        class="rb-icon--medium u-color-grey-lighter"
      />
      <bulk-search-aggregated-tag
        :has-invalid-entities="hasInvalidEntities"
        :total-entity-count="totalEntityCount"
        v-on="$listeners"
      />
    </div>
    <slot
      v-else
      name="custom-standard-search"
    >
      <standard-search :search-config="searchConfig" />
    </slot>
    <bulk-search-toggler
      :is-bulk-search-active="isBulkSearchActive"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import StandardSearch from '@/components/basic/bulk-search/bulkSerachInput/StandardSearch.vue';
import BulkSearchToggler from '@/components/basic/bulk-search/bulkSerachInput/BulkSearchToggler.vue';
import BulkSearchAggregatedTag from '@/components/basic/bulk-search/bulkSerachInput/BulkSearchAggregatedTag.vue';
export default {
  components: {
    StandardSearch,
    BulkSearchToggler,
    BulkSearchAggregatedTag
  },
  props: {
    totalEntityCount: {
      type: Number,
      default: null
    },
    isBulkSearchActive: {
      type: Boolean,
      default: false
    },
    hasInvalidEntities: {
      type: Boolean,
      default: false
    },
    searchConfig: {
      type: Object,
      default: () => ({ emit: '', placeholder: '' })
    }
  }
};
</script>
