var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100"},[(_vm.panelConfig.showBaseTemplate)?_c('section',[_c('header',{staticClass:"title u-font-size-5 u-font-weight-600 u-spacing-pt-l u-spacing-ph-l u-text-case-upper"},[_vm._v(" "+_vm._s(_vm.panelConfig.header)+" ")]),_c('div',{staticClass:"actionsPanelBody u-display-flex u-spacing-p-l"},[_c('div',{staticClass:"tableContainerSplitPanel",style:({
          width: _vm.panelConfig.source.width ? _vm.panelConfig.source.width : '33%'
        })},[_vm._t("source-header",[(_vm.panelConfig.result.header)?_c('div',{staticClass:"tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"},[_c('div',{staticClass:"u-spacing-p-xs"},[_vm._v(" "+_vm._s(_vm.panelConfig.source.header)+" ")])]):_vm._e()]),_vm._t("source-filter",[(_vm.panelConfig.source.filter)?_c('div',{staticClass:"u-spacing-ph-l u-spacing-pt-l u-display-flex u-font-weight-600 u-font-size-5"},[_vm._v(" "+_vm._s(_vm.panelConfig.source.filter.header)+" ")]):_vm._e(),(_vm.panelConfig.source.filter)?_c('div',{staticClass:"u-spacing-ph-l u-spacing-pt-m u-display-flex u-font-weight-400 u-flex-justify-content-flex-start u-overflow-auto"},_vm._l((_vm.panelConfig.source.filter.items),function(item,i){return _c('rb-checkbox',{key:i,style:(_vm.panelConfig.source.filter.filterStyle &&
                _vm.panelConfig.source.filter.filterStyle[item]
                  ? _vm.panelConfig.source.filter.filterStyle[item]
                  : ''),attrs:{"native-value":"false"},on:{"input":function($event){return _vm.updateSourcePanelCheckBox()}},model:{value:(_vm.sourceCheckBox[i]),callback:function ($$v) {_vm.$set(_vm.sourceCheckBox, i, $$v)},expression:"sourceCheckBox[i]"}},[(
                  _vm.panelConfig.source.filter.vhtmlItems &&
                  _vm.panelConfig.source.filter.vhtmlItems[item]
                )?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.panelConfig.source.filter.vhtmlItems[item])}})]):_c('div',[_vm._v(" "+_vm._s(item)+" ")])])}),1):_vm._e()]),_vm._t("source-body",[_vm._v(" slot = \"source-body\" ")])],2),_c('div',{staticClass:"tableContainerSplitPanel u-spacing-mh-l",style:({
          width: _vm.panelConfig.result.width ? _vm.panelConfig.result.width : '33%'
        })},[_vm._t("result-header",[(_vm.panelConfig.result.header)?_c('div',{staticClass:"tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"},[_c('div',{staticClass:"u-spacing-p-xs"},[_vm._v(" "+_vm._s(_vm.panelConfig.result.header)+" ")])]):_vm._e()]),_vm._t("result-filter",[(_vm.panelConfig.result.filter)?_c('div',{staticClass:"u-spacing-ph-l u-spacing-pt-l u-display-flex u-font-weight-600 u-font-size-5",class:_vm.panelConfig.result.filterClass
                ? _vm.panelConfig.result.filterClass
                : ''},[_vm._v(" "+_vm._s(_vm.panelConfig.result.filter.header)+" ")]):_vm._e(),(_vm.panelConfig.result.filter)?_c('div',{staticClass:"u-spacing-ph-l u-spacing-pt-m u-display-flex u-font-weight-400 u-flex-justify-content-flex-start u-overflow-auto"},_vm._l((_vm.panelConfig.result.filter.items),function(item,i){return _c('rb-checkbox',{key:i,style:(_vm.panelConfig.result.filter.filterStyle &&
                _vm.panelConfig.result.filter.filterStyle[item]
                  ? _vm.panelConfig.result.filter.filterStyle[item]
                  : ''),attrs:{"native-value":"false"},on:{"input":function($event){return _vm.updateResultPanelCheckBox()}},model:{value:(_vm.resultCheckBox[i]),callback:function ($$v) {_vm.$set(_vm.resultCheckBox, i, $$v)},expression:"resultCheckBox[i]"}},[(
                  _vm.panelConfig.result.filter.vhtmlItems &&
                  _vm.panelConfig.result.filter.vhtmlItems[item]
                )?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.panelConfig.result.filter.vhtmlItems[item])}})]):_c('div',[_vm._v(" "+_vm._s(item)+" ")])])}),1):_vm._e()]),_vm._t("result-body",[_c('div',{staticClass:"tableBody"},[_vm._t("result-body",[_vm._v(" slot = \"result-body\" ")])],2)])],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }