<template>
  <div class="u-posiiton-relative">
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="clientTabsLoading"
      :color="'#007cf6'"
    />
    <template v-if="!clientTabsLoading">
      <div
        class="u-display-flex u-flex-align-items-center client-tabs u-spacing-pl-l u-spacing-pr-l"
      >
        <div
          v-for="tab in clientTabs"
          :key="tab.name"
          class="client-tab"
          :class="{
            'client-tab-active': selectedTab && selectedTab.name === tab.name
          }"
          @click="selectTab(tab)"
        >
          {{ tab.name }}
        </div>
      </div>
      <div
        class="u-display-flex u-flex-direction-column u-spacing-ph-xxl u-font-size-5 u-position-relative u-min-height-300"
      >
        <loader
          class="fill--parent u-bg-color-grey-white"
          :loading="automationTypesLoading"
          :color="'#007cf6'"
        />
        <template v-if="!automationTypesLoading">
          <automations-rpa-form
            v-for="(value, key) in automationTypes"
            :key="key"
            :input-fields="inputFields"
            :automation-key="key"
            :automation-title="inputFields[key] && inputFields[key].label"
            :form-data="value"
            :filter-data="filterData"
            :child-client="selectedTab && selectedTab.name"
            :primary-filter-data="primaryFilterData"
          ></automations-rpa-form>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import AutomationsRpaForm from './automations-rpa-form.vue';
import {
  retailerAPIMap,
  fetchRetailerDetails
} from '../password-manager/utils';
import HttpService from '@/utils/services/http-service';
import urlHelper from '@/utils/helpers/url';
import widgetsUtil from '@/components/widgetMixin';
export default {
  components: { loader, AutomationsRpaForm },
  mixins: [widgetsUtil],
  data() {
    return {
      clientTabsLoading: true,
      clientTabs: [],
      selectedTab: {},
      automationTypesLoading: true,
      automationTypes: {},
      filterV2: true,
      inputFields: {}
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    region() {
      return window.location.href.split('/')[3];
    },
    clientName() {
      return urlHelper.getClientInfoFromUrl().client;
    }
  },
  watch: {
    selectedTab(newVal) {
      this.fetchAllAutomationTypes(newVal.name);
    }
  },
  created() {
    this.fetchClientTabs();
    this.fetchFilters(
      'recommendation_filter',
      'BRANDS_SERVICE',
      'recommendations'
    );
    this.fetchFieldLabels();
  },
  methods: {
    async fetchClientTabs() {
      try {
        const response = await fetchRetailerDetails({
          clientName: this.clientName,
          region: this.region.toUpperCase(),
          system: 'sales',
          retailerAPI: retailerAPIMap[this.retailer]
        });
        this.updateClientTabs(response);
      } catch (e) {
        this.$snackbar.open({
          message: `Fetching retailer details`,
          duration: 2000,
          actionText: 'Failed'
        });
      } finally {
        this.clientTabsLoading = false;
      }
    },
    updateClientTabs(response) {
      if (response?.clientDetails) {
        this.clientTabs = response.clientDetails?.map((el) => ({
          name: el.clientName
        }));
        this.selectTab(this.clientTabs[0]);
      }
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    async fetchFieldLabels() {
      const response = await HttpService.get('ACTIONS_SCHEDULER_SELF_SERVE', {
        append: '/fieldLabelsDescriptions'
      });
      this.inputFields = response.data.fields;
    },
    async fetchAllAutomationTypes(childClient) {
      try {
        this.automationTypesLoading = true;
        const response = await HttpService.get('ACTIONS_SCHEDULER_SELF_SERVE', {
          append: `/${childClient}/all`
        });
        this.generateAutomationTypes(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.automationTypesLoading = false;
      }
    },
    generateAutomationTypes(data) {
      this.automationTypes = data;
    }
  }
};
</script>

<style lang="css" scoped>
.client-tabs {
  font-size: 1.4rem;
  color: #4b5158;
  background-color: #fff;
  height: 38px;
  box-shadow: 0px 1px 4px #e9eaeb;
}
.client-tabs .client-tab {
  font-size: 1.4rem;
  cursor: pointer;
}
.client-tabs .client-tab + .client-tab {
  margin-left: 40px;
}
.client-tabs .client-tab-active {
  color: #2690f8;
  font-weight: 600;
}
</style>
