<template>
  <div
    class="rightPanel"
    :class="className"
  >
    <div class="header u-spacing-ph-m u-spacing-pt-l u-spacing-pb-m">
      <span class="u-color-grey-base u-font-size-4 u-font-weight-600"
        >Release Notes</span
      >
      <span @click="closeRightPanel">
        <rb-icon
          :icon="'cross'"
          class="u-color-grey-lighter rb-icon--small u-cursor-pointer"
        />
      </span>
    </div>
    <div class="body">
      <ReleaseNotes :is-new-release="isNewRelease" />
    </div>
    <div class="footer" />
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
import ReleaseNotes from '@/components/basic/releasenotes/releaseNotes.vue';

export default {
  components: {
    ReleaseNotes
  },
  data() {
    return {
      className: 'close',
      isNewRelease: false
    };
  },
  created() {
    eventBus.$on('rightPanelOpen', (isNewRelease) => {
      this.isNewRelease = isNewRelease;
      this.className = 'open';
    });

    eventBus.$on('rightPanelClose', () => {
      this.className = 'close';
    });
  },
  methods: {
    closeRightPanel() {
      eventBus.$emit('rightPanelClose');
    }
  }
};
</script>

<style lang="css" scoped>
.rightPanel {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: white;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
}
.rightPanel .header {
  width: 100%;
  height: 56px;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightPanel .body {
  flex-grow: 1;
  overflow: auto;
}
.rightPanel.close {
  width: 0%;
}
.rightPanel.close .body {
  display: none;
}
.rightPanel.open {
  width: 27%;
}
.rightPanel.open .body {
  display: block;
}
</style>
