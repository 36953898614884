<template>
  <div>
    <!-- <img class="u-position-absolute login-logo-svg" src="/images/logo.svg"/> -->
    <div class="u-display-flex page-body u-height-100vh">
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center bg-blue-base-gradient"
      >
        <div
          class="u-display-flex u-flex-justify-content-center u-color-grey-white u-width-100"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-align-items-center"
          >
            <div>
              <img
                style="max-width: 537px"
                src="/images/welcome.png"
              />
            </div>
            <div class="u-spacing-mt-xxl u-font-size-2 u-font-weight-bold">
              MACHINE POWERED, HUMAN EMPOWERED!
            </div>
            <div
              class="login-long-message u-spacing-mt-m u-font-size-4 u-text-align-center"
            >
              Save time and drive sustainable growth by minimizing revenue
              leakage and maximizing sales opportunities.
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        <div class="u-display-flex u-width-400px">
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-font-size-4 u-spacing-mb-s">Welcome to</div>
            <div
              class="u-font-size-1 u-font-weight-bold u-spacing-mb-xl u-color-grey-light"
            >
              <img
                style="width: 200px"
                src="/images/logo.svg"
              />
            </div>
            <div
              v-if="action === 'resetPassword'"
              class="u-spacing-mt-m u-font-size-3 u-font-weight-600"
            >
              Reset password?
            </div>
            <div
              v-if="action === 'setPassword'"
              class="u-spacing-mt-m u-font-size-3 u-font-weight-600"
            >
              Activate your new account
            </div>
            <div class="u-spacing-mt-s u-font-size-5">
              Please set a password as per the guidelines.
              <span
                class="u-cursor-pointer u-color-blue-base"
                @click="showGuidlines = !showGuidlines"
                >{{ showGuidlines ? 'Hide' : 'View' }} guidelines.</span
              >
            </div>
            <div
              v-if="showGuidlines"
              class="u-display-flex u-font-size-6 u-spacing-mt-xs u-color-grey-light"
            >
              <ul class="guidlines-list">
                <li>Minimum 8 characters</li>
                <li>Atleast one uppercase letter</li>
                <li>Atleast one lowercase letter</li>
                <li>Atleast one number</li>
                <li>
                  Atleast one special character from (
                  ~!@#$%{}^&amp;-*_+=`|:;()"'[]&lt;&gt;,.? )
                </li>
              </ul>
            </div>
            <form
              v-if="!endState"
              class="u-width-400px"
              @submit.prevent="resetPassword()"
            >
              <div class="u-spacing-mt-xl">
                <label class="u-display-block u-font-size-6 u-font-weight-600"
                  >Password</label
                >
                <input
                  v-model="password"
                  class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                  :class="{ error: passwordTouched && password === '' }"
                  placeholder="Please keep it to yourself"
                  type="password"
                  name="password"
                  @blur="passwordTouched = true"
                />
                <label
                  v-if="passwordTouched && password === ''"
                  class="u-color-red-base u-font-size-7"
                  >Please enter password.</label
                >
              </div>
              <div class="u-spacing-mt-m">
                <label class="u-display-block u-font-size-6 u-font-weight-600"
                  >Confirm Password</label
                >
                <input
                  v-model="confirmPassword"
                  class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                  :class="{
                    error:
                      confirmPasswordTouched &&
                      (confirmPassword === '' || !matchPassword)
                  }"
                  placeholder="Please keep it to yourself"
                  type="password"
                  name="confirmPassword"
                  @blur="confirmPasswordTouched = true"
                />
                <label
                  v-if="confirmPasswordTouched && confirmPassword === ''"
                  class="u-color-red-base u-font-size-7"
                  >Please enter password</label
                >
                <label
                  v-if="!matchPassword"
                  class="u-color-red-base u-font-size-7"
                  >Password does not match</label
                >
              </div>
              <div
                v-if="errorMessage"
                class="u-display-flex u-color-red-base u-spacing-mt-l u-flex-align-items-center"
              >
                <div><rb-icon icon="cross-fill-circle" /></div>
                <div class="u-spacing-ml-xs u-font-size-7">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="u-spacing-mt-l">
                <rb-button
                  class="u-width-100"
                  :text="'Reset password'"
                  :type="'filled'"
                  :click-fn="
                    passwordChangeLoading ||
                    !matchPassword ||
                    password === '' ||
                    confirmPassword === ''
                      ? null
                      : setOrResetPassword
                  "
                  :loading="passwordChangeLoading"
                  :disabled="
                    passwordChangeLoading ||
                    !matchPassword ||
                    password === '' ||
                    confirmPassword === ''
                  "
                />
              </div>
              <input
                class="u-display-none"
                type="submit"
                value="ok"
              />
            </form>
            <div
              v-if="successMessage"
              class="u-display-flex u-color-green-base u-spacing-mt-l u-flex-align-items-center"
            >
              <div><rb-icon icon="check-fill-circle" /></div>
              <div class="u-spacing-ml-xs u-font-size-7">
                {{ successMessage }}
              </div>
            </div>
            <rb-button
              v-if="endState"
              class="u-spacing-mt-m u-width-100"
              :text="'Login'"
              :type="'filled'"
              :click-fn="goToLogin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'action'],
  data() {
    return {
      password: '',
      passwordTouched: false,
      confirmPassword: '',
      confirmPasswordTouched: false,
      showGuidlines: false,
      inputField: {
        type: 'password',
        text: 'Show Password'
      },
      errorMessage: null,
      successMessage: null,
      passwordChangeLoading: false,
      endState: false
    };
  },
  computed: {
    matchPassword() {
      // true if matched
      if (this.password.trim === '' || this.confirmPassword === '') {
        return true;
      }
      return this.password === this.confirmPassword;
    }
  },
  mounted() {
    this.checkUserStatus();
  },
  methods: {
    checkUserStatus() {
      const registerParams = {
        id: this.id
      };
      this.$store.dispatch('checkUserStatus', registerParams).then(
        (response) => {
          if (response.data.status === 'failure') {
            this.$router.push({
              name: 'forgot-password',
              query: {
                interror: response.data.message
              }
            });
          }
        },
        (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage =
              'unable to check code validity. pls try again later';
          }
        }
      );
    },
    goToLogin() {
      const isInviteForExtension = Boolean(this.$route.query.extensionInvite);
      if (isInviteForExtension) {
        this.syncExtension();
        return;
      }
      this.$router.push({
        name: 'login'
      });
    },
    setOrResetPassword() {
      this.errorMessage = null;
      this.successMessage = null;
      this.passwordChangeLoading = true;
      const registerParams = {
        id: this.id,
        password: this.password
      };
      this.$store.dispatch('setOrResetPassword', registerParams).then(
        (response) => {
          if (response.data.status === 'success') {
            this.successMessage =
              'password has been updated. You can now login with the new password';
            this.passwordChangeLoading = false;
            this.endState = true;
          } else {
            this.passwordChangeLoading = false;
            this.errorMessage = response.data.message;
          }
        },
        (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = 'something went wrong. try again later';
          }
        }
      );
    },
    resetPassword() {
      return false;
    },
    syncExtension() {
      // getting extension id specific for browser e.g. 'extension.chrome.id'
      const extensionId = 'knmkalnmhkhjpojmnlfkmfbdagpcommg';
      const hostname = window.location.host;
      const hostComponents = hostname.split('.');
      const client = hostComponents[0];
      const environment =
        hostComponents.length === 4 ? hostComponents[1] : 'prod';
      const configs = this.$store.getters.getConfigs;
      if (window?.chrome?.runtime) {
        window.chrome.runtime.sendMessage(
          extensionId,
          {
            methodType: 'clientSetup',
            clientDetails: {
              name: client,
              env: environment,
              isInternalUser: false
            },
            configs
          },
          (response) => {
            if (response?.success) {
              this.$snackbar.open({
                message: 'Extension synced to ' + hostname,
                duration: 10000,
                actionText: ''
              });
            }
          }
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
input {
  width: 382px;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}
input:focus {
  border-color: #007cf6;
}

.guidlines-list {
  list-style: initial;
  margin-left: 18px;
}
</style>
