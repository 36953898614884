<template>
  <div class="split--container">
    <div
      ref="master"
      class="master-panel"
    >
      <slot name="master" />
      <div
        :id="leftHandleId"
        class="leftBuffer"
      />
    </div>
    <div
      :id="handleId"
      class="resize-handle"
    >
      <!-- <div class="handleIndicator"></div> -->
    </div>
    <div
      id="detail-panel"
      class="detail-panel"
    >
      <div
        :id="rightHandleId"
        class="rightBuffer"
      />
      <slot name="detail" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitContainer',
  components: {},
  props: {
    masterWidth: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      handleId: null,
      leftHandleId: null,
      rightHandleId: null
    };
  },
  watch: {
    masterWidth: {
      handler: function (newWidth, oldWidth) {
        if (newWidth !== oldWidth) {
          this.initialiseWidget();
        }
      }
    }
  },
  created() {
    this.handleId = 'handle-' + this._uid + this.$route.path.split('/')[1];
    this.leftHandleId =
      'left-handle-' + this._uid + this.$route.path.split('/')[1];
    this.rightHandleId =
      'right-handle-' + this._uid + this.$route.path.split('/')[1];
  },
  mounted() {
    this.$slots.detail[0].elm.classList.value = 'u-height-100';
    this.initialiseWidget();
  },
  methods: {
    initialiseWidget() {
      var handleId = this.handleId;
      var resizeHandle = document.getElementById(handleId);
      var leftHandle = document.getElementById(this.leftHandleId);
      var rightHandle = document.getElementById(this.rightHandleId);

      var master = this.$refs.master;
      var storedWidth = localStorage.getItem('master-width-' + handleId);

      if (!storedWidth) {
        storedWidth = this.masterWidth;
      } else if (
        this.$route.path.split('/')[1] === 'recommendations' &&
        this.$route.path.split('/')[2] === 'all'
      ) {
        storedWidth = 0; // special case for recommendations all section
      }

      master.style.width = storedWidth + 'px';
      if (this.masterWidth === 0) {
        resizeHandle.style.display = 'none';
      } else {
        resizeHandle.style.display = 'block';
      }

      if (resizeHandle && leftHandle && rightHandle) {
        resizeHandle.addEventListener('mousedown', initialiseResize, false);
        leftHandle.addEventListener('mousedown', initialiseResize, false);
        rightHandle.addEventListener('mousedown', initialiseResize, false);
      }

      function initialiseResize(e) {
        window.addEventListener('mousemove', startResizing, false);
        window.addEventListener('mouseup', stopResizing, false);
      }

      function startResizing(e) {
        var width = e.clientX - master.offsetLeft;
        if (width <= 300 && width >= 176) {
          master.style.width = width + 'px';
          localStorage.setItem('master-width-' + handleId, width);
        }
      }

      function stopResizing(e) {
        window.removeEventListener('mousemove', startResizing, false);
        window.removeEventListener('mouseup', stopResizing, false);
      }
    }
  }
};
</script>

<style lang="css">
.handleIndicator {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 24px;
  border-radius: 10px;
  background-color: #caccce;
}

.leftBuffer {
  position: absolute;
  width: 7px;
  top: 0;
  background: transparent;
  right: 0;
  bottom: 0;
  cursor: col-resize;
  z-index: 99;
}

.rightBuffer {
  position: absolute;
  width: 7px;
  top: 0;
  background: transparent;
  left: 0;
  bottom: 0;
  cursor: col-resize;
  z-index: 99;
}

.resize-handle {
  position: relative;
  border-radius: 100px;
  width: 2px;
  height: 100%;
  cursor: col-resize;
  background-color: white;
  box-shadow: 2px 2px 4px 0 rgba(43, 51, 59, 0.25);
  z-index: 11;
}
.resize-handle .handleIndicator {
  display: none;
}
.resize-handle:hover .handleIndicator {
  display: block;
}

.master-panel {
  background-color: #fff;
  overflow: auto;
  position: relative;
  z-index: 5;
}

.detail-panel {
  position: relative;
  flex-grow: 1;
  overflow: auto;
}

.split--container {
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}
</style>
