import { cloneDeep } from 'lodash';

export const leftCustomBidValues = [
  {
    title: 'Suggested Bid',
    value: 'suggested_bid',
    input: 'radioSelectWithInput',
    radio: {
      config: [
        {
          title: 'Increase daily budget',
          value: 'suggested_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Amazon'
            },
            {
              label: 'Increase by',
              value: 'percentage'
            },
            {
              label: 'Decrease by',
              value: 'percentage'
            }
          ]
        }
      ],
      default: 'suggested_bid',
      inputTitle: '',
      show: false
    }
  },
  {
    title: 'Custom Bid',
    value: 'custom_bid',
    input: 'currency'
  }
];

const openSnackbar = (vueRef, message) => {
  vueRef.$snackbar.open({
    message: message,
    duration: 5000,
    actionText: ''
  });
};

const isValueInvalid = (vueRef, value) => {
  const invalidValueEntered =
    'Defaulting to Amazon suggested bid as value entered is invalid';
  if (!value) {
    openSnackbar(vueRef, invalidValueEntered);
    return true;
  }
  return false;
};

export const findBidValue = (vueRef) => {
  let bid = null;
  if (vueRef?.selectedCustomBid?.value === 'suggested_bid') {
    const selectionObj = vueRef.radioSelectionWithInputObject;
    if (selectionObj?.dropdownLabel === 'Increase by') {
      if (isValueInvalid(vueRef, selectionObj?.inputValue)) return null;
      bid = {
        value: selectionObj?.inputValue && parseFloat(selectionObj?.inputValue),
        type: selectionObj?.dropdownValue,
        method: 'increase'
      };
    } else if (selectionObj?.dropdownLabel === 'Decrease by') {
      if (isValueInvalid(vueRef, selectionObj?.inputValue)) return null;
      bid = {
        value: selectionObj?.inputValue && parseFloat(selectionObj?.inputValue),
        type: selectionObj?.dropdownValue,
        method: 'decrease'
      };
    } else {
      bid = null;
    }
  } else if (vueRef?.selectedCustomBid?.value === 'custom_bid') {
    if (isValueInvalid(vueRef, vueRef?.suggestedCustomBidInput)) return null;
    bid = {
      value: parseFloat(vueRef?.suggestedCustomBidInput),
      method: 'absolute',
      type: 'number'
    };
  }
  return bid;
};

export const updateBid = (oldBid, bid, defaultNewBidValue = 0) => {
  if (!oldBid && defaultNewBidValue) {
    return defaultNewBidValue;
  }
  let newBid = parseFloat(oldBid);
  if (bid?.method === 'increase') {
    newBid = newBid + newBid * (bid.value / 100);
  } else if (bid?.method === 'decrease') {
    newBid = newBid - newBid * (bid.value / 100);
  } else if (bid?.method === 'absolute') {
    newBid = bid.value;
  }
  if (parseFloat(newBid)) {
    newBid = parseFloat(newBid)?.toFixed(2);
  }
  return newBid;
};

export const addEntity = (
  vueRef,
  row,
  suggBid = 'suggested_bid',
  finalBid = 'new_bid'
) => {
  const bid = findBidValue(vueRef);
  let rowExistsInRightTable = false;
  const tempRightTableRows = vueRef.rightTableFilterApplied
    ? [...vueRef.rightTableRowsCopy]
    : [...vueRef.computedRightTableRows];
  vueRef.rightTableFilterApplied = false;
  const that = vueRef;
  tempRightTableRows.forEach((rightTableRow) => {
    if (
      rightTableRow[that.config.primaryKey] ===
      row.params.data[that.config.primaryKey]
    ) {
      rowExistsInRightTable = true;
    }
  });
  if (!rowExistsInRightTable) {
    row.params.data.isRowSelected = true;
    const newRow = cloneDeep(row.params.data);
    let newBid = updateBid(
      newRow[suggBid],
      bid,
      vueRef.config?.right?.defaultNewBidValue
    );
    newRow.exact_bid = newBid;
    newRow[finalBid] = newBid;
    tempRightTableRows.unshift(newRow);
    vueRef.rightTableRows = tempRightTableRows;
    vueRef.rightTableRowsCopy = tempRightTableRows;
    vueRef.rightTableInstance.redrawTableRows();
    vueRef.leftTableInstance.redrawTableRows();
  }
};

export const addAll = (
  vueRef,
  suggBid = 'suggested_bid',
  finalBid = 'new_bid'
) => {
  const bid = findBidValue(vueRef);
  const rowsToAddToRightTable = [];
  for (const row of vueRef.leftTableRows) {
    let newRow = cloneDeep(row);
    let isRowPresent = false;
    // for campaign edit search terms add as target enter list
    if (
      vueRef.config?.left?.boolUpdateLeftBid &&
      !row?.isRowSelected &&
      bid?.method !== 'absolute'
    ) {
      newRow.new_bid = vueRef.suggestedCustomBidInput;
    } else {
      let newBid = updateBid(
        newRow[suggBid],
        bid,
        vueRef.config?.right?.defaultNewBidValue
      );
      newRow.exact_bid = newBid;
      newRow[finalBid] = newBid;
    }

    row.isRowSelected = true;
    for (const rightTableRow of vueRef.rightTableRows) {
      if (
        row[vueRef.config.primaryKey] ===
        rightTableRow[vueRef.config.primaryKey]
      ) {
        isRowPresent = true;
      }
    }
    if (!isRowPresent) rowsToAddToRightTable.push(newRow);
  }
  const computedRightTableRows = vueRef.rightTableFilterApplied
    ? [...vueRef.rightTableRowsCopy]
    : [...vueRef.computedRightTableRows];
  vueRef.rightTableRows = [...rowsToAddToRightTable, ...computedRightTableRows];
  vueRef.rightTableRowsCopy = vueRef.rightTableRows;
  vueRef.rightTableFilterApplied = false;
  vueRef.leftTableRows = [...vueRef.leftTableRows];
  if (
    vueRef.fetchedRightTableRows.rows.length &&
    !vueRef.config.right.keepFetchedRightTableRows
  ) {
    const primaryKey = vueRef.config.primaryKey;
    vueRef.rightTableRows = vueRef.rightTableRows.filter(
      (item) =>
        vueRef.fetchedRightTableRows?.rows?.findIndex(
          (apiData) => apiData[primaryKey] === item[primaryKey]
        ) === -1
    );
  }
};
