<template>
  <div class="skeleton" />
</template>
<script>
export default {
  name: 'SkeletonLoader',
  props: {},
  created() {}
};
</script>
<style scoped lang="css">
.skeleton:empty {
  margin: auto;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(#e9eaeb 100%, transparent 0);
  background-repeat: repeat-y;
  background-size: 30% 100%, 100% 100%;
  background-position: 0 0, 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
</style>
