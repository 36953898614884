export default {
  shareOfSearchType: {
    tooltipText: {
      'SKU Details': 'SKU title and ID',
      'SKU Priority': 'SKU priority',
      'Current Search Rank': 'Current rank of the SKU on search page.',
      'Previous Search Rank':
        'Average search rank for 3 weeks prior to last week.',
      'Content Changes':
        'Status indicating whether a content change happened during last 2 weeks.',
      'Price Changes':
        'No.of price changes in last 4 weeks (Includes Pre Period and Post Period)',
      OOS: 'No. of days the SKU was out of stock in last 3 weeks prior to last week.',
      Rollback:
        'No.of days the SKU was in Rollback in last 3 weeks prior to last week.',
      Clearance:
        'No. of days the SKU was in Clearance in last 3 weeks prior to last week.',
      Promo: 'No. of days the SKU was in Promotion in last 2 weeks.'
    }
  }
};
