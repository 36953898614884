<template>
  <div class="u-spacing-pt-xxxl u-spacing-ph-xxl u-width-100">
    <section class="u-width-100 u-spacing-pt-m u-font-size-1">
      <createCampaignHeader :step="step" />
      <goals
        :all-goals="allGoals"
        :is-single-select="true"
        @goalsSelection="handleGoalsSelection"
      />
      <createCampaignFooter
        :disable-right-btn="
          !(allStepsData && allStepsData.goalConfig) || rightButtonLoading
        "
        class="u-spacing-mt-s"
        :right-button-loading="rightButtonLoading"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import goals from './goals';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    goals
  },
  props: {
    selectedValues: {
      type: Object,
      default: () => {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    allGoals: [],
    rightButtonLoading: false
  }),
  computed: {
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  created() {
    this.setAllGoals();
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    async setAllGoals() {
      if (this.allStepsData?.allGoals?.length) {
        this.allGoals = [...this.allStepsData.allGoals];
        return;
      }
      this.allGoals = this.selectedValues?.widgets.map((item) => {
        item.label = item.name;
        item.isSelected = false;
        return item;
      });
    },
    async callGoalBasedData(selectedGoal) {
      this.rightButtonLoading = true;
      const widget = await dashUtils.getWidgetMetadata(
        selectedGoal,
        this.selectedValues?.id
      );
      this.step.description = widget?.[0]?.data?.label;
      const goalConfig = {
        goalConfig: {
          customMetadata: widget?.[0]?.data?.customMetadata,
          metadata: widget?.[0]?.data?.metadata,
          label: widget?.[0]?.data?.label
        }
      };
      const skus = this.allStepsData.skus;
      const singleSku = this.allStepsData.singleSku;
      const allGoals = this.allStepsData.allGoals;
      this.$store.dispatch(this.selectedValues.action, {
        skus,
        allGoals,
        singleSku,
        ...goalConfig
      });
      this.rightButtonLoading = false;
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    handleGoalsSelection(selections) {
      this.allGoals = [...selections];
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ allGoals: this.allGoals }
      });
      const selectedGoal = this.allGoals.filter((item) => item.isSelected);
      this.callGoalBasedData(selectedGoal[0]);
    }
  }
};
</script>

<style scoped>
.selected-outlined-btn {
  border-radius: 50px !important;
}
</style>
