<template>
  <div class="u-bg-color-grey-white">
    <div
      class="u-bg-color-white-base u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pb-l u-spacing-pl-l u-spacing-pr-l u-width-100 account-manager-header"
    >
      <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
        Automations Manager
      </h2>
      <div class="u-display-flex u-flex-justify-content-flex-end">
        <splitTabs
          class="account-manager-split-tabs"
          tab1="Track"
          tab2="Manage"
          @tabClicked="changePageType"
        />
      </div>
    </div>
    <manageAutomations v-if="pageType === 'Manage'"> </manageAutomations>
    <template v-else>
      <div class="u-spacing-p-l">
        <loader
          class="fill--parent u-bg-color-grey-white"
          :loading="templateLoading"
          :color="'#007cf6'"
        />
        <div
          v-if="!templateLoading"
          class="u-display-flex u-flex-justify-content-space-between"
        >
          <rb-select
            class="u-spacing-mr-s u-max-width-100"
            :send-details="true"
            :on-close="templateSelection"
            :options="templateOptions"
            :width="'240px'"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
            >
              <div
                class="u-flex-1 u-font-size-4 u-text-overflow-ellipsis u-font-weigth-600"
              >
                <span class="u-color-grey-light u-font-weight-bold"
                  >AUTOMATION TYPE:</span
                >
                {{ selectedTemplate.TEMPLATENAME }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--x-medium u-spacing-ml-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                class="u-display-flex u-flex-align-items-center"
                :title="option.TEMPLATENAME"
              >
                {{ option.TEMPLATENAME }}
              </div>
            </template>
          </rb-select>
          <div class="u-width-35 u-display-flex u-flex-align-items-center">
            <div
              class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-font-weigth-600 u-text-align-right u-spacing-mr-m"
            >
              <span class="u-color-grey-light u-font-weight-bold"
                >Client Timezone:</span
              >
              {{ clientTimezone }}
            </div>
            <rb-filter-panel
              v-if="isTemplateSelected"
              id="dvt-template-selection"
              :key="filterKey"
              :config="config.filters"
              :emit="config.filters.emit"
              :allow-date-compare="false"
              :enable-save="true"
              :has-search="true"
              :new-date="true"
              :min-max-date="minMaxDate"
              :default-date-post-range="'last90Days'"
              :apply-filter="onApplyFilter"
              :hide-filter="true"
              :hide-add-filter="true"
              :loader-status="loaderStatus"
              :show-reset="false"
              @savedFilterApplied="filterKey++"
            />
          </div>
        </div>
      </div>
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="templateData.load || localLoader"
        :color="'#007cf6'"
      />
      <div v-if="isTemplateSelected && !removeTable">
        <div
          class="u-border-top u-border-width-s u-border-color-grey-xxx-light u-width-100"
        >
          <div
            class="u-position-sticky filter-dvt-holder top-0 z-index-2 u-bg-color-grey-white"
          >
            <div
              class="u-spacing-p-m u-display-flex u-flex-justify-content-space-between"
            >
              <div>
                <div
                  v-for="(val, index) in selectedTemplate.TEMPLATECONIFG
                    .FILTERS || []"
                  :key="index"
                >
                  <rb-select
                    class="u-spacing-mr-s u-max-width-100"
                    :send-details="true"
                    :is-multi-select="true"
                    :context="[val.key]"
                    :on-close="selectFilter"
                    :options="val.options"
                    :width="'240px'"
                  >
                    <div
                      slot="trigger"
                      class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
                    >
                      <div
                        class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-font-weigth-600"
                      >
                        <span
                          class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                          style="min-height: 20px"
                        >
                          <div
                            class="u-display-inline-flex u-flex-align-items-center"
                          >
                            <rb-icon
                              :icon="'filter'"
                              class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
                            />
                            <span
                              class="u-spacing-pl-xs u-spacing-pr-s u-line-height-1-1 u-display-flex u-flex-align-items-center"
                            >
                              <span class="u-spacing-mr-xs"
                                >{{ val.name }}:
                              </span>
                              <span class="u-color-grey-base">
                                <span
                                  v-for="(chip, chipIndex) in selectedFilter"
                                  :key="chipIndex"
                                  >{{ chip.title
                                  }}<span
                                    v-if="
                                      chipIndex !== selectedFilter.length - 1
                                    "
                                    >,
                                  </span>
                                </span>
                              </span>
                              <rb-icon
                                class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                                :icon="'caret-down'"
                              />
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <!-- <template slot-scope="option" slot="item">
                            <div class="u-display-flex u-flex-align-items-center" :title="option.key">
                            {{option.title}}
                            </div>
                </template>-->
                  </rb-select>
                </div>
              </div>
              <div class="u-display-flex u-flex-align-items-center">
                <form
                  slot="trigger"
                  class="u-position-relative"
                  @submit.prevent="onSearchSubmit"
                >
                  <rb-input
                    v-model="searchTerm"
                    :placeholder="searchPlaceholder"
                    class="search__input internal-tool-dvt-search"
                    :rounded="false"
                  />
                  <span
                    class="u-position-absolute u-display-flex"
                    style="top: 0px; right: 8px; bottom: 0; margin: auto"
                    @click.stop.prevent="onSearchSubmit"
                  >
                    <rb-icon
                      icon="search"
                      class="search__input-icon rb-icon--medium"
                    />
                  </span>
                </form>
                <div
                  class="u-display-flex u-flex-direction-column u-cursor-pointer u-icon-text-action u-border-radius-xs default u-spacing-ml-s u-spacing-p-s"
                  @click="addRow"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter u-cursor-pointer"
                    :icon="'add-circle-fill'"
                  />
                  <span
                    class="u-font-size-8 u-text-case-upper u-color-grey-lighter u-spacing-pt-xs"
                    >Add Row</span
                  >
                </div>
                <rb-download-button
                  :download-email="false"
                  :config="config.widgets['widget2']"
                  :column-map="
                    config.widgets['widget2'].body.download.columnMap
                  "
                  :class-name="'enlargeEmailIcon'"
                  :file-name="selectedTemplate.TEMPLATENAME"
                />
              </div>
            </div>
            <div
              v-show="showActionsBar"
              class="actions-bar--wrapper actionspace-dummy"
            >
              <actions-bar
                :close-fn="closeActionsWorkflow"
                :selections="selections"
                :passed-actions="actionsConfig"
                @open-workspace="openActionsWorkspace"
              />
            </div>
          </div>
          <rb-insights-table
            v-show="templateData.load === false"
            :config="((config || {}).widgets || {})['widget2'] || {}"
            :grid-options="
              ((((config || {}).widgets || {})['widget2'] || {}).body || {})
                .gridOptions
            "
            :table-row="templateRows || []"
            :table-column="
              ((((config || {}).widgets || {})['widget2'] || {}).body || {})
                .columnDefs
            "
            style="width: 100%"
            :row-height="45"
            :row-selection="rowSelection"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :pagination="true"
            :pagination-total-key="templateData.totalRows"
            :pagination-per-page-limit="
              (
                ((((config || {}).widgets || {})['widget2'] || {}).body || {})
                  .APIConfig || {}
              ).limit
            "
            :pagination-change-event="
              ((((config || {}).widgets || {})['widget2'] || {}).meta || {})
                .paginationAction
            "
            :show-pagination-options="true"
            :table-header-sticky-obj="sticky"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="css">
.internal-tool-input-cells {
  border: 0;
  background-color: transparent;
  border-radius: 4px;
  width: 100%;
}
.internal-tool-input-cells:focus {
  padding: 8px;
  box-shadow: none;
  outline: none;
  border: 1px solid #007cf6;
}
.internal-tool-input-cells-error {
  border: 1px solid #d7263d;
  padding: 8px;
}

.internal-tool-dvt-search {
  width: 300px;
}
.internal-tool-dvt-search input {
  border: 0 !important;
  background-color: #f5f7fa !important;
}
.internal-tool-disabled {
  pointer-events: none;
}
</style>
<script>
import loader from '@/components/basic/loader';
import widgetsConfig from './widget';
import actionsBar from '@/components/widgets/actionsBar.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import moment from 'moment';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import splitTabs from '@/components/widgets/splitTabs';
import manageAutomations from './manage-automations/manage-automations.vue';
import { clone, cloneDeep, get } from 'lodash';

export default {
  components: {
    loader,
    actionsBar,
    rbFilterPanel,
    rbDownloadButton,
    splitTabs,
    manageAutomations
  },
  data() {
    return {
      pageType: 'Track',
      minMaxDate: {
        maxDate: moment(new Date()).add(1, 'd').format('MM/DD/YYYY')
        // due to utc conversions on the backend, adding 1 day to current date so any entries made within the dvt table posy 4 (convert to next day in utc) will be still accessible
      },
      selectedTemplate: {},
      isTemplateSelected: false,
      widgetsConfig: {},
      config: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      showActionsBar: false,
      actions: [],
      actionsConfig: [
        {
          icon: 'add-circle-fill',
          title: 'Clone Row',
          id: 'clone-row'
        },
        {
          icon: 'remove-fill',
          title: 'Remove Row',
          id: 'remove-row'
        }
      ],
      selections: [],
      rawSelections: [],
      removeTable: false,
      selectedFilter: [],
      searchTerm: '',
      filterKey: 0,
      defaults: {},
      localLoader: false,
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.page-body',
        addSpaceElementsSelector: ['.filter-dvt-holder', '.page-header'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      }
    };
  },
  computed: {
    clientTimezone() {
      return this.$store.getters.getClientTimezone.shortForm;
    },
    clientBrowserTimezoneInGmt() {
      return this.$store.getters.getClientBrowserTimezone?.gmtValue;
    },
    templateOptions() {
      const list = this.$store.getters.getInternalToolTemplateList.rows;
      return list;
    },
    templateRows: {
      get: function () {
        const rows = this.$store.getters.getInternalToolTemplateData.rows || [];
        return rows;
      },
      set: function (newValue) {
        return newValue;
      }
    },
    templateData() {
      return this.$store.getters.getInternalToolTemplateData;
    },
    templateLoading() {
      return this.$store.getters.getInternalToolTemplateList.load;
    },
    searchPlaceholder() {
      let str = 'Search for a ';
      const searchColumns = get(
        this.selectedTemplate,
        'TEMPLATECONIFG.SEARCHCONFIG.columns'
      );
      for (let i = 0; i < (searchColumns || []).length; i++) {
        const columns = get(this.selectedTemplate, 'TEMPLATECONIFG.columns');
        const index = columns.findIndex((item) => {
          return item.key === searchColumns[i];
        });
        str += columns[index].name;
        if (i + 1 !== searchColumns.length) {
          str += ' or ';
        }
      }
      return str;
    }
  },
  created() {
    this.selectedTemplate.TEMPLATENAME = 'Select a template';
    this.widgetsConfig = widgetsConfig;
    this.$store.dispatch('fetchInternalToolsTemplates');
    this.config = this.widgetsConfig.config(this, []);
  },
  methods: {
    changePageType(data) {
      this.pageType = data;
    },
    templateSelection(context, selectedOption) {
      if (selectedOption.length === 0) {
        return;
      }
      this.selectedFilter = [];
      this.isTemplateSelected = false;
      this.selectedTemplate = selectedOption[0];
      this.$store.dispatch('setSelectedTemplate', this.selectedTemplate);
      this.setTableConfig();
      setTimeout(() => {
        this.isTemplateSelected = true;
      });
    },
    setTableConfig() {
      this.$store.dispatch('setEditModeIds', []);
      this.config = this.widgetsConfig.config(
        this,
        this.selectedTemplate.TEMPLATECONIFG.columns
      );
      if (this.selectedTemplate.TEMPLATECONIFG.UI_DEFAULTS) {
        const defaultWhere =
          this.selectedTemplate.TEMPLATECONIFG.UI_DEFAULTS.FILTERS;
        const orderList =
          this.selectedTemplate.TEMPLATECONIFG.UI_DEFAULTS.ORDER_BY;
        if (defaultWhere) {
          this.selectedFilter = [];
          for (let i = 0; i < defaultWhere.length; i++) {
            const index = (
              this.selectedTemplate.TEMPLATECONIFG.FILTERS || []
            ).findIndex((item) => {
              return item.key === defaultWhere[i].dimensionName;
            });
            if (index !== -1) {
              const arr =
                this.selectedTemplate.TEMPLATECONIFG.FILTERS[index].options;
              const valueIndex = arr.findIndex((item) => {
                return item.value === defaultWhere[i].dimensionValue;
              });
              if (valueIndex !== -1) {
                this.selectedTemplate.TEMPLATECONIFG.FILTERS[index].options[
                  valueIndex
                ].selected = true;
              }
            }
            this.selectedFilter.push({
              title: defaultWhere[i].dimensionValue,
              selected: true
            });
          }
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList =
            defaultWhere;
        } else {
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList =
            [];
        }
        if (orderList) {
          this.config.widgets.widget2.body.APIConfig.orderByList = orderList;
        } else {
          this.config.widgets.widget2.body.APIConfig.orderByList = [];
        }
      }
      this.removeTable = false;
      this.localLoader = false;
    },
    addRow() {
      const columns = this.selectedTemplate.TEMPLATECONIFG.columns;
      const obj = {};
      for (let i = 0; i < columns.length; i++) {
        obj[columns[i].key] = null;
      }
      obj.ID = Math.floor(Math.random() * 1000000) + new Date().getTime();
      obj.inserted = true;
      const currentIds = this.$store.getters.getEditModeIds.ids || [];
      currentIds.push(obj.ID);
      this.$store.dispatch('setEditModeIds', currentIds);
      this.templateRows.unshift(obj);
    },
    onSelectionChanged(data) {
      this.selections = [];
      this.rawSelections = data;
      for (let i = 0; i < data.length; i++) {
        this.selections.push(data[i].data);
      }
      if (this.selections.length > 0) {
        this.showActionsBar = true;
      } else {
        this.showActionsBar = false;
      }
    },
    closeActionsWorkflow() {
      this.selections = [];
      this.deselectAllRows();
      this.showActionsBar = false;
    },
    deselectAllRows() {
      if (this.config.widgets.widget2.body.gridOptions.api !== null) {
        this.config.widgets.widget2.body.gridOptions.api.deselectAll();
      }
    },
    cloneRows(selections) {
      const ids = [];
      for (let i = 0; i < selections.length; i++) {
        const obj = clone(selections[i]);
        obj.ID = Math.floor(Math.random() * 1000000000) + new Date().getTime();
        obj.inserted = true;
        obj.edited = true;
        obj.clone = true;
        ids.push(obj.ID);
        this.$store.dispatch('setEditModeIds', ids);
        this.templateRows.unshift(obj);
      }
      this.closeActionsWorkflow();
    },
    formatFullRowData(columns, row) {
      const _row = {};
      const clonedRow = cloneDeep(row);
      for (const key in clonedRow) {
        const index = columns.findIndex((item) => {
          return item.field === key;
        });
        if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].dataType === 'DATETIME_PST'
        ) {
          _row[key] = moment(
            clonedRow[key] + ` ${this.clientBrowserTimezoneInGmt}`
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        } else if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].type === 'DATETIME'
        ) {
          _row[key] = moment(clonedRow[key]).format('YYYY-MM-DD HH:mm:ss');
        } else if (
          index !== -1 &&
          clonedRow[key] &&
          columns[index].type === 'DATE'
        ) {
          _row[key] = moment(clonedRow[key]).format('YYYY-MM-DD');
        } else {
          _row[key] = clonedRow[key];
        }
      }
      return cloneDeep(_row);
    },
    removeRows(selections) {
      const rows = [];
      const _selections = cloneDeep(selections);
      let count = 0;
      for (let i = 0; i < _selections.length; i++) {
        const row = cloneDeep(_selections[i]);
        if (row.ID) {
          if (typeof row.ID === 'string') {
            const hasNew = row.ID.includes('new');
            if (hasNew) {
              this.templateRows.splice(
                this.rawSelections[i].rowIndex - count,
                1
              );
              count++;
              continue;
            }
          }
        }
        row.ISDELETED = true;
        delete row.TOTAL_ROWS;
        delete row.edited;
        delete row.inserted;
        delete row.loading;
        rows.push(
          this.formatFullRowData(
            ((((this.config || {}).widgets || {}).widget2 || {}).body || {})
              .columnDefs,
            row
          )
        );
        // this.templateRows[this.rawSelections[i].rowIndex].loading = true;
      }
      // if (rows.length === 0) {
      //   this.templateRows = this._.cloneDeep(this.templateRows)
      //   this.closeActionsWorkflow();
      //   return
      // }
      this.templateRows = cloneDeep(this.templateRows);
      let promise = null;
      const data = {
        body: {
          templateId: this.selectedTemplate.ID,
          update: rows
        }
      };
      this.removeTable = true;
      this.localLoader = true;
      this.$store.dispatch('setTemplateLoading', true);
      promise = this.$store.dispatch('updateInternalToolsData', data);
      promise.then((data) => {
        this.fetchData();
        this.setTableConfig();
      });
    },
    openActionsWorkspace(event) {
      switch (event) {
        case 'clone-row':
          this.cloneRows(this.selections);
          break;
        case 'remove-row':
          this.removeRows(this.selections);
          break;
      }
      this.selections = [];
    },
    selectFilter(context, event) {
      if (!this.config.widgets.widget2.body.APIConfig.where) {
        this.config.widgets.widget2.body.APIConfig.where = {
          dimensionNameValueList: []
        };
      }
      while (1) {
        const index =
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.findIndex(
            (item) => {
              return item.dimensionName === context[0];
            }
          );
        if (index === -1) {
          break;
        } else {
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.splice(
            index,
            1
          );
        }
      }
      for (let i = 0; i < event.length; i++) {
        this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.push(
          {
            dimensionName: context[0],
            dimensionValue: event[i].value
          }
        );
      }
      this.selectedFilter = event;
      this.fetchData();
    },
    onSearchSubmit() {
      var selected = null;
      if (this.searchTerm && this.searchTerm.length > 0) {
        selected = {
          dimensionName: 'search',
          dimensionValue: this.searchTerm,
          operator: 'ILIKE'
        };
      }
      if (!this.config.widgets.widget2.body.APIConfig.where) {
        this.config.widgets.widget2.body.APIConfig.where = {
          dimensionNameValueList: []
        };
      }
      const searchIndex =
        this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.findIndex(
          (item) => {
            return item.dimensionName === 'search';
          }
        );
      if (searchIndex !== -1) {
        if (selected) {
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList[
            searchIndex
          ] = selected;
        } else {
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.splice(
            searchIndex,
            1
          );
        }
      } else {
        if (selected) {
          this.config.widgets.widget2.body.APIConfig.where.dimensionNameValueList.push(
            selected
          );
        }
      }
      this.fetchData();
    },
    onApplyFilter(event) {
      if (!this.config.widgets.widget2.body.APIConfig.where) {
        this.config.widgets.widget2.body.APIConfig.where = {
          dimensionNameValueList: []
        };
      }
      if (event.date_range) {
        this.config.widgets.widget2.body.APIConfig.where.date =
          event.date_range;
      }
      this.fetchData();
    },
    fetchData() {
      this.showActionsBar = false;
      this.config.widgets.widget2.columnDefs = (
        (((this.config || {}).widgets || {}).widget2 || {}).body || {}
      ).columnDefs;
      this.config.widgets.widget2.templateConfig =
        this.selectedTemplate.TEMPLATECONIFG;
      this.$store.dispatch(
        'fetchInternalToolsData',
        this.config.widgets.widget2
      );
    }
  }
};
</script>
