<template>
  <div>
    <div
      class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-mt-m u-spacing-p-l u-width-100 card"
    >
      <div class="u-display-flex u-flex-direction-column">
        <span class="u-font-size-3 u-font-weight-bold u-color-blue-base"
          >Strategy Scheduler</span
        >
        <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
          >Schedule the rules as per the requirements.</span
        >
      </div>
      <div class="date--selection--area u-spacing-mt-m">
        <div
          class="u-display-flex u-flex-justify-content-flex-start u-spacing-mt-s u-spacing-p-m"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <local-date-filter
              :tooltip="true"
              modal-position="Right"
              :custom-date="startDateRange"
              :disabled="startDateDisabled"
              :mode="'Single'"
              @selected-value="setStartDate"
            />
            <span class="u-font-size-6 u-spacing-mh-m">to</span>
            <local-date-filter
              :tooltip="true"
              modal-position="Right"
              :custom-date="endDateRange"
              :mode="'Single'"
              @selected-value="setEndDate"
            />
          </div>
          <div class="u-display-flex u-flex-align-items-center u-spacing-ml-l">
            <rb-select
              class="u-spacing-mr-s valueDropdown"
              :send-details="true"
              :on-close="timeZoneSelected"
              :options="timezoneOptions"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                >
                  <div class="u-display-flex u-flex-direction-column">
                    <span class="u-font-size-7">{{
                      (selectedTimeZoneOption || {}).title
                    }}</span>
                  </div>
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-font-size-7"
                >
                  {{ option.title }}
                </div>
              </template>
            </rb-select>
          </div>
        </div>
        <div class="u-spacing-pl-m u-spacing-pb-s">
          <div
            v-if="errorMessage"
            class="u-spacing-mb-m u-display-flex u-flex-align-items-center"
          >
            <error-message :error-message="errorMessage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import boomerangSingleSelect from '@/components/widgets/select/singleSelect.vue/';
import shortListTable from '@/components/widgets/shortListTable.vue/';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import moment from 'moment-timezone';
import errorMessage from './errorMessage.vue';
export default {
  components: {
    boomerangSingleSelect,
    shortListTable,
    localDateFilter,
    errorMessage
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    allStepsData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      timezone: null,
      dateRange: {},
      startDateRange: {},
      endDateRange: {},
      timezoneOptions: [],
      selectedTimeZoneOption: {},
      stepValid: false,
      editMode: false,
      startDateDisabled: false,
      minDate: null,
      maxDate: null
    };
  },
  computed: {
    customStartDate() {
      return this.startDateRange;
    },
    errorMessage() {
      return this.stepValid
        ? ''
        : 'Start date needs to be before the end date. Please choose an appropriate start date.';
    }
  },
  watch: {},
  created() {
    if (this.step?.data?.mode && this.step.data.mode === 'edit') {
      this.editMode = true;
      this.setEditValues();
    } else {
      this.setStartAndEndDate();
    }
    this.nextStepStatus(true);
    this.setStartDefaultDateRange();
    this.setEndDefaultDateRange();
    this.timeZoneList();
    this.stepValidate();
  },
  methods: {
    setStartAndEndDate() {
      const actions =
        this.allStepsData[this.step.indexMapping.action].data.actions;
      let minDate = moment(actions[0].startDate).format('YYYY-MM-DD');
      let maxDate = moment(actions[0].endDate).format('YYYY-MM-DD');
      for (let i = 0; i < actions.length; i++) {
        const actionStartDate = moment(actions[i].startDate).format(
          'YYYY-MM-DD'
        );
        const actionEndDate = moment(actions[i].endDate).format('YYYY-MM-DD');
        const startDiff = moment(minDate).diff(moment(actionStartDate));
        const endDiff = moment(actionEndDate).diff(moment(maxDate));
        if (startDiff >= 0) {
          minDate = actionStartDate;
        }
        if (endDiff > 0) {
          maxDate = actionEndDate;
        }
        this.minDate = minDate;
        this.maxDate = maxDate;
        this.startDate = moment(minDate).toDate();
        this.endDate = moment(maxDate).toDate();
      }
    },
    setEditValues() {
      const data = this.step.data;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    },
    setStartDefaultDateRange() {
      const startDate = new Date(this.startDate);
      const defaultDate = new Date(this.startDate);
      const toDate = new Date();
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
      const diff = moment(formatedStartDate).diff(currentDate);
      this.startDateDisabled = this.editMode ? diff < 0 : false;
      toDate.setFullYear(this.startDate.getFullYear() + 1);
      startDate.setDate(this.startDate.getDate());
      const range = {
        fromDate: startDate,
        toDate: toDate,
        defaultDate: defaultDate
      };
      this.startDateRange = range;
    },
    setEndDefaultDateRange() {
      const startDate = new Date();
      const toDate = new Date();
      toDate.setFullYear(startDate.getFullYear() + 10);
      startDate.setDate(startDate.getDate() - 1);
      const range = {
        fromDate: startDate,
        toDate: toDate,
        defaultDate: this.endDate
      };
      this.endDateRange = range;
    },
    selectedRange(range) {},
    setStartDate(range) {
      this.startDate = range.from;
      this.stepValidate();
    },
    setEndDate(range) {
      this.endDate = range.to;
      this.stepValidate();
    },
    nextStepStatus(value) {
      this.$emit('nextStep', value, this.index);
    },
    formatStepData() {
      const obj = {
        startDate: this.startDate,
        endDate: this.endDate,
        timezone: this.selectedTimeZoneOption,
        minDate: this.minDate,
        maxDate: this.maxDate
      };
      return obj;
    },
    stepDataOut() {
      const obj = this.formatStepData();
      obj.index = this.index;
      this.$emit('stepDataOut', obj);
    },
    timeZoneList() {
      var timezones = moment.tz.names();
      const formatList = [];
      for (let i = 0; timezones.length > i; i++) {
        const formatZone = moment.tz(timezones[i]).format('Z z');
        const zone = {
          title: `GMT ${formatZone} ${timezones[i]}`,
          value: timezones[i]
        };
        formatList.push(zone);
      }
      const uniqueZones = [...new Set(formatList)];
      let zoneName = moment.tz.guess();
      if (this.editMode) {
        zoneName = this.step.data.timezone.value;
      }
      const defaultIndex = uniqueZones.findIndex((element) => {
        return element.value === zoneName;
      });
      this.selectedTimeZoneOption = uniqueZones[defaultIndex];
      this.timezoneOptions = uniqueZones;
    },
    timeZoneSelected(context, selectedOption) {
      if (!selectedOption?.length) return;
      this.selectedTimeZoneOption = selectedOption[0];
      this.stepValidate();
    },
    stepValidate() {
      this.stepValid = false;
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);
      if (startDate.diff(endDate) <= 0) {
        this.stepValid = true;
      }

      /* We need to validate all the previous steps, the below code only validates the actions section */
      const actions =
        this.allStepsData[this.step.indexMapping.action].data.actions;
      let areActionsValid = true;
      for (var i = 0; i < actions.length; i++) {
        if (!actions[i].isActionValid) {
          areActionsValid = false;
          break;
        }
      }
      if (areActionsValid) {
        this.$emit('nextStep', false, this.step.indexMapping.action);
      }
      /* previous steps validation ends here */

      if (this.stepValid) {
        this.nextStepStatus(false);
        this.stepDataOut();
      } else {
        this.nextStepStatus(true);
      }
    }
  }
};
</script>

<style scoped lang="css">
.date--selection--area {
  background-color: #f5f7fa;
  border-radius: 4px;
}
.valueDropdown {
  width: 280px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: #fff;
}
</style>
