var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-height-100"},[_c('div',{staticClass:"u-font-size-5 u-spacing-ph-m u-spacing-pt-m u-display-flex u-flex-align-items-center"},[_c('span',[_vm._v("Data last updated at : "),_c('span',{staticClass:"u-font-weight-600"},[_vm._v(_vm._s(_vm.last_updated))])]),_c('span',{staticClass:"u-spacing-pl-m u-cursor-pointer",on:{"click":_vm.refreshData}},[_c('rb-icon',{staticClass:"rb-icon--medium",attrs:{"icon":'sync'}})],1)]),_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"card u-font-size-5 u-spacing-m-m",staticStyle:{"min-height":"75px"}},[_c('div',{staticClass:"u-font-weight-600 u-spacing-p-m"},[_vm._v(" Summary of Recommendations run ")]),(_vm.recsSummary.total === undefined)?_c('div',[_c('loader',{staticClass:"fill--parent u-bg-color-grey-white",attrs:{"color":'#007cf6'}})],1):(_vm.recsSummary.succeeded === _vm.recsSummary.total)?_c('div',{staticClass:"u-spacing-p-m u-bg-color-green-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"},[_c('rb-icon',{staticClass:"rb-icon--x-large",attrs:{"icon":'check'}}),_c('span',[_vm._v(_vm._s(_vm.recsSummary.succeeded)+" / "+_vm._s(_vm.recsSummary.total)+" completed")])],1):(_vm.recsSummary.failed === _vm.recsSummary.total)?_c('div',{staticClass:"u-spacing-p-m u-bg-color-red-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"},[_c('rb-icon',{staticClass:"rb-icon--x-large",attrs:{"icon":'warning'}}),_c('span',[_vm._v(_vm._s(_vm.recsSummary.failed)+" / "+_vm._s(_vm.recsSummary.total)+" failed")])],1):_c('div',{staticClass:"u-spacing-ph-m u-spacing-pb-m"},[_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-green-base u-font-weight-600':
                _vm.recsSummary.succeeded > 0
            }},[_vm._v(_vm._s(_vm.recsSummary.succeeded))]),_vm._v(" / "+_vm._s(_vm.recsSummary.total)+" have succeeded ")]),_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-orange-base u-font-weight-600': _vm.recsSummary.running > 0
            }},[_vm._v(_vm._s(_vm.recsSummary.running))]),_vm._v(" / "+_vm._s(_vm.recsSummary.total)+" are Running ")]),_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-red-base u-font-weight-600': _vm.recsSummary.failed > 0
            }},[_vm._v(_vm._s(_vm.recsSummary.failed))]),_vm._v(" / "+_vm._s(_vm.recsSummary.total)+" have failed ")])])]),_c('div',{staticClass:"card u-font-size-5 u-spacing-m-m",staticStyle:{"min-height":"75px"}},[_c('div',{staticClass:"u-font-weight-600 u-spacing-p-m"},[_vm._v(" Summary of Insights run ")]),(_vm.insightsSummary.total === undefined)?_c('div',[_c('loader',{staticClass:"fill--parent u-bg-color-grey-white",attrs:{"color":'#007cf6'}})],1):(_vm.insightsSummary.succeeded === _vm.insightsSummary.total)?_c('div',{staticClass:"u-spacing-p-m u-bg-color-green-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"},[_c('rb-icon',{staticClass:"rb-icon--x-large",attrs:{"icon":'check'}}),_c('span',[_vm._v(_vm._s(_vm.insightsSummary.succeeded)+" / "+_vm._s(_vm.insightsSummary.total)+" completed")])],1):(_vm.insightsSummary.failed === _vm.insightsSummary.total)?_c('div',{staticClass:"u-spacing-p-m u-bg-color-red-base u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-white"},[_c('rb-icon',{staticClass:"rb-icon--x-large",attrs:{"icon":'warning'}}),_c('span',[_vm._v(_vm._s(_vm.insightsSummary.failed)+" / "+_vm._s(_vm.insightsSummary.total)+" failed")])],1):_c('div',{staticClass:"u-spacing-ph-m u-spacing-pb-m"},[_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-green-base u-font-weight-600':
                _vm.insightsSummary.succeeded > 0
            }},[_vm._v(_vm._s(_vm.insightsSummary.succeeded))]),_vm._v(" / "+_vm._s(_vm.insightsSummary.total)+" have succeeded ")]),_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-orange-base u-font-weight-600':
                _vm.insightsSummary.running > 0
            }},[_vm._v(_vm._s(_vm.insightsSummary.running))]),_vm._v(" / "+_vm._s(_vm.insightsSummary.total)+" are Running ")]),_c('div',{staticClass:"u-spacing-pt-s"},[_c('span',{class:{
              'u-color-red-base u-font-weight-600': _vm.insightsSummary.failed > 0
            }},[_vm._v(_vm._s(_vm.insightsSummary.failed))]),_vm._v(" / "+_vm._s(_vm.insightsSummary.total)+" have failed ")])])])]),(_vm.selections.length > 0)?_c('div',{staticClass:"u-font-size-5 u-spacing-p-m"},[_c('span',[_vm._v(_vm._s(_vm.retryConfirmationText))]),_c('span',[_c('span',{staticClass:"u-spacing-p-s u-bg-color-blue-base u-color-grey-white u-border-radius-s u-spacing-mh-s u-cursor-pointer",on:{"click":_vm.retryFailures}},[_vm._v("Go Ahead")]),_c('span',{staticClass:"u-spacing-p-s u-bg-color-grey-white u-border-radius-s u-cursor-pointer",on:{"click":_vm.cancelRetry}},[_vm._v("Cancel")])])]):_vm._e(),_c('rb-insights-table',{attrs:{"table-row":_vm.rows,"table-column":_vm.columns,"row-selection":_vm.rowSelection,"row-height":45,"primary-key":'pipeline',"enable-client-side-sorting":false,"enable-server-side-sorting":true,"pagination":true,"show-pagination-options":true,"row-class-rules":_vm.rowClassRules,"grid-options":_vm.gridOptions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }