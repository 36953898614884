<template>
  <div
    class="u-display-flex u-spacing-mr-m"
    :class="{ 'u-flex-direction-column': isColumn }"
  >
    <span class="u-font-size-5 u-font-weight-600 u-color-grey-light">{{
      groupLabel
    }}</span>
    <div
      v-for="({ type, label, desc }, index) in radioOptions"
      :key="index"
      :class="containerClass"
    >
      <div>
        <rb-radio
          v-model="selectedRadio"
          :native-value="type"
          :data-cy="`radioBtnlabel-${label}`"
        >
          <div>{{ label }}</div>
        </rb-radio>
      </div>
      <div
        class="u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-mt-s u-spacing-ml-l"
        :class="descClass"
        data-cy="radioBtnDesc"
      >
        {{ desc }}
      </div>
      <slot :name="`below_radio_${index}`" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    radioOptions: {
      type: Array,
      required: true
    },
    groupLabel: {
      type: String,
      default: ''
    },
    descClass: {
      type: String,
      default: ''
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: 'global-radio-wrapper u-spacing-mr-l u-spacing-mt-xl'
    }
  },
  data() {
    return {
      selectedRadio: this.value
    };
  },
  watch: {
    selectedRadio(newVal) {
      this.$emit('onRadioChanged', newVal);
    }
  },
  created() {}
};
</script>
<style lang="css">
.u-line-height-1_6 {
  line-height: 16px !important;
}
</style>
