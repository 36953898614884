import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import CONSTANTS from '@/utils/constants';

export default {
  routes: [
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.ESM
    }
  }))
};
