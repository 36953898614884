var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && _vm.data && _vm.data.tag1 !== 'loading' && _vm.data.tag1 !== 'error')?_c('div',{staticClass:"u-flex-align-items-center"},[_c('div',{class:[
      _vm.metricClass,
      _vm.splitClass,
      'rb-metric',
      { 'u-flex-align-items-flex-start': _vm.splitClearPadding }
    ]},[_c('span',{staticClass:"primary_metric u-color-grey-light",class:{
        'u-font-weight-600':
          !(_vm.config || {}).tableCell &&
          ((_vm.data || {}).tag1 || (_vm.data || {}).tag2),
        'u-display-flex': _vm.postMetricSlotPassed,
        'u-flex-direction-column': _vm.useColumnFlexDirection
      },style:({ color: _vm.labelColor }),attrs:{"title":_vm.getTippyString(
          (_vm.data || {}).tag1,
          _vm.getLocale,
          ((_vm.config || {}).tag1Unit || {}).pre
        ),"value":(_vm.data || {}).tag1,"data-cy":"metricValue"}},[(
          !_vm.data ||
          (!(_vm.data || {}).tag1 &&
            (_vm.data || {}).tag1 !== 0 &&
            !(_vm.data || {}).tag2 &&
            (_vm.data || {}).tag2 !== 0)
        )?_c('span',{},[_vm._v(" "+_vm._s(_vm.fallbackMsgExpanded ? 'Not Available' : 'NA')+" ")]):_c('span',[(
            _vm.data.tag1 <= 9 &&
            _vm.data.tag1 > 1 &&
            !(_vm.config.tag1Unit || {}).pre &&
            _vm.hideZero
          )?_c('span',[_vm._v("0")]):_vm._e(),_vm._v(_vm._s(_vm._f("num_format")(_vm.data.tag1, (_vm.config.tag1Unit || {}).pre, (_vm.config.tag1Unit || {}).suff, (_vm.config.tag1Unit || {}).min, (_vm.config.tag1Unit || {}).roundoff, undefined, _vm.selectedCurrency ))+" ")]),_vm._t("postTag1Metric")],2),(_vm.split)?_c('div',{staticClass:"u-spacing-mt-s"}):_vm._e(),(
        (_vm.config || {}).invertTag2 !== true && (_vm.data || {}).tag2 !== undefined
      )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"secondary_metric",class:[
        _vm.selected
          ? 'u-color-white-base'
          : {
              'u-color-red-base': _vm.data.tag2 < 0,
              'u-color-green-base': _vm.data.tag2 > 0,
              'u-color-grey-lighter': _vm.data.tag2 === 0
            }
      ],style:([_vm.splitClearPadding ? { 'padding-left': 0 } : '']),attrs:{"value":_vm.data.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.data.tag2, (_vm.config.tag2Unit || {}).pre, (_vm.config.tag2Unit || {}).suff, (_vm.config.tag2Unit || {}).min, (_vm.config.tag2Unit || {}).roundoff, undefined, _vm.selectedCurrency ))+" ")]):_vm._e(),(
        (_vm.config || {}).invertTag2 === true && (_vm.data || {}).tag2 !== undefined
      )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"secondary_metric",class:[
        _vm.selected
          ? 'u-color-white-base'
          : {
              'u-color-red-base': _vm.data.tag2 > 0,
              'u-color-green-base': _vm.data.tag2 < 0
            }
      ],style:([_vm.splitClearPadding ? { 'padding-left': 0 } : '']),attrs:{"value":_vm.data.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.data.tag2, (_vm.config.tag2Unit || {}).pre, (_vm.config.tag2Unit || {}).suff, undefined, undefined, undefined, _vm.selectedCurrency ))+" ")]):_vm._e(),(
        _vm.splitClearPadding &&
        ((_vm.data || {}).tag2 === undefined || (_vm.data || {}).tag2 === null)
      )?_c('span',{staticClass:"u-spacing-p-s"}):_vm._e()])]):_c('div',{staticClass:"u-flex-align-items-center u-display-flex u-width-100 u-flex-justify-content-center"},[(_vm.data && _vm.data.tag1 === 'loading')?_c('loader',{staticClass:"u-bg-color-grey-white",attrs:{"loading":_vm.data && _vm.data.tag1 === 'loading',"color":'#007cf6'}}):_vm._e(),(_vm.data && _vm.data.tag1 === 'error')?_c('div',{staticClass:"u-font-size-6"},[_vm._v(" Unable to fetch data! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }