<template>
  <CardContainer
    :intermediate-height="intermediateHeight"
    :title="metadata.label"
    :action-icons="actionIcons"
    class="u-width-100"
    :load="
      imageDataService.data.load ||
      snapshotSharesService.data.load ||
      dropdownService.data.load
    "
    :error="
      imageDataService.data.error ||
      snapshotSharesService.data.error ||
      dropdownService.data.error
    "
    :last-updated-date="lastUpdatedDate"
    :no-data="
      imageDataService.data.noData ||
      snapshotSharesService.data.noData ||
      dropdownService.data.noData
    "
    @close="handleClose"
  >
    <section
      slot="post-title"
      v-tippy="tippyOptions"
    >
      <rb-icon
        class="u-spacing-mr-xs u-color-grey-lighter"
        :icon="'info-circle-fill'"
      />
      <section
        :id="infoTippyId"
        ref="dropdownMenu"
        class="custom-styles u-spacing-p-l tippy-drop-down u-border-radius-s"
      >
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-m">
          Snapshot of Page 1
        </div>
        <div class="u-font-size-6 u-font-weight-normal">
          Get a quick overview of the top brands and their SKUs ranking on Page
          1 of the search result. The calculated SoV (in %) corresponds to
          <span class="u-font-weight-600">Total SoV on Page 1</span>
          for that keyword-date, weighted by rank on Page 1. We capture
          information on Page 1 during core daylight hours.
        </div>
      </section>
    </section>
    <section
      slot="pre-filters"
      class="u-display-flex u-spacing-mr-m"
    >
      <rb-select
        class="u-spacing-mr-m"
        :options="dropdownService.data.rows"
        :on-close="handleKeywordsSelect"
        :send-details="true"
      >
        <MultiSelectChip
          slot="trigger"
          ref="keywords-select-tigger"
          :load="dropdownService.data.load"
          :pre-text="filterPretext"
          :selections="selectedKeyword"
        />
      </rb-select>
      <LocalDateFilter
        v-if="customDate"
        class="sov-snapshot-datepicker"
        mode="Single"
        :custom-date="customDate"
        @selected-value="handleDateSelect"
      />
    </section>
    <template
      slot="body"
      slot-scope="{}"
    >
      <DataBars
        :data-bars-limit="null"
        :custom-color-obj="colorsMap"
        :retailer-brand-name="retailerBrandName"
        :image-url-key="keys.imageUrlKey"
        :asin-key="keys.asinKey"
        :asin-hyper-link-key="keys.asinHyperLinkKey"
        :copy-clipboard-text="copyToClipboardText"
        :rank-key="keys.rankKey"
        :client-name="clientName"
        :bar-tooltip-key="keys.barTootlipKey"
        :bars-value="imageDataService.data.rows"
        :key-to-check="keyToCheck"
        :legends-value="snapshotSharesService.data.legendValues"
        :sorted-legends="snapshotSharesService.data.sortedLegends"
        v-bind="dataBarProps"
      />
    </template>
    <div
      slot="footer-right"
      class="u-flex-grow-1"
    >
      <slot name="footer-right" />
    </div>
  </CardContainer>
</template>

<script>
import DataBars from '@/components/basic/dataBars.vue';
import CardContainer from '../organisms/card-container';
import { cloneDeep, get } from 'lodash';
import {
  requestDateReplacement,
  hasPlaceholder
} from './dashboard-service-utils';
import MultiSelectChip from '../atoms/multi-select-chip';
import moment from 'moment';
import LocalDateFilter from '@/components/basic/localDateFilter';

export default {
  components: {
    DataBars,
    CardContainer,
    MultiSelectChip,
    LocalDateFilter
  },
  props: {
    externalClientBrand: {
      type: String,
      default: null
    },
    colorsMap: {
      type: Object,
      default: null
    },
    imageDataService: {
      type: Object,
      default: null
    },
    snapshotSharesService: {
      type: Object,
      default: null
    },
    dropdownService: {
      type: Object,
      default: null
    },
    filterPretext: {
      type: String,
      default: 'Keywords'
    },
    metricsList: {
      type: Array,
      default: () => ['total_listing_count']
    },
    intermediateHeight: {
      type: Number,
      default: 200
    },
    fetchDataOnCreate: {
      type: Boolean,
      default: false
    },
    globalWhereClause: {
      type: Object,
      default: () => ({})
    },
    externalKeywordSelected: {
      type: String,
      default: null
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    retailerBrandName: {
      type: String,
      default: null
    },
    isMarketingClient: {
      type: Boolean,
      default: true
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    namespace: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tippyInstance: null,
      infoTippyId: null,
      selectedDate: null,
      customDate: null,
      dataBarProps: {},
      metadata: {},
      lastUpdatedDate: null,
      actionIcons: [],
      keys: {},
      accessKeyMetadata: {},
      accessKeys: [],
      selectedKeyword: []
    };
  },
  computed: {
    copyToClipboardText() {
      if (this.retailer !== 'amazon') {
        return 'SKU copied to clipboard';
      }
      return 'ASIN copied to clipboard';
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    clientName() {
      return this.externalClientBrand || this.keys.clientName;
    },
    keyToCheck() {
      return this.externalClientBrand
        ? this.keys.externalKeyToCheck
        : this.keys.staticKeyToCheck;
    },
    tippyOptions() {
      return {
        html: '#' + this.infoTippyId,
        reactive: true,
        distance: 4,
        placement: 'bottom',
        interactive: true,
        theme: 'dropdown',
        trigger: 'mouseenter',
        duration: [0, 0]
      };
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    }
  },
  watch: {
    colorsMap: {
      immediate: true,
      handler(newSnapshotColor) {
        if (!newSnapshotColor) {
          this.$emit(
            'newLegendData',
            this.snapshotSharesService.data.legendValues
          );
        }
      }
    },
    externalKeywordSelected: {
      immediate: true,
      handler(newExternalKeywordSelected, oldExternalKeywordSelected) {
        this.externalKeywordSelected = newExternalKeywordSelected;
      }
    }
  },
  created() {
    this.infoTippyId = 'dropdown-' + this._uid;
    this.init();
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    generateAccessKeys(metricsList, accessKeysConfig, metadata) {
      const { client, comp, others, anchored } = accessKeysConfig;
      return [
        ...this.getAccessKey(metricsList, anchored, metadata),
        ...this.getAccessKey(metricsList, client, metadata),
        ...this.getAccessKey(metricsList, comp, metadata),
        ...this.getAccessKey(metricsList, others, metadata)
      ];
    },
    getAccessKey(metricsList, config, metadata) {
      const { prefix, count: metaCount } = config;
      let count = null;
      if (metaCount?.search) {
        count = get(metadata, metaCount.search);
      }
      return this.getAccessKeyFromMetric(
        metricsList,
        metadata.metrics,
        prefix,
        count
      );
    },
    getAccessKeyFromMetric(metrics, metricsMetadata, prefix, count) {
      const accessKeys = [];
      metrics.forEach((metric) => {
        const { keyName } = metricsMetadata[metric];
        if (count) {
          for (let i = 0; i < count; i++) {
            accessKeys.push({
              brandKey: prefix + '_' + (i + 1),
              brandShareKey: prefix + '_' + (i + 1) + '_' + keyName
            });
          }
        } else {
          accessKeys.push({
            brandKey: prefix,
            brandShareKey: prefix + '_' + keyName
          });
        }
      });
      return accessKeys;
    },
    handleDateSelect(newDate) {
      this.selectedDate = this.getDateFormatted(newDate.to);
      this.fetchWidgetVisualization();
    },
    handleKeywordsSelect(context, selections) {
      if (selections && selections[0]) {
        this.selectedKeyword = [selections[0]];
        this.fetchWidgetVisualization();
      }
    },
    fetchWidgetVisualization() {
      this.getShareOfVoiceImages();
      this.getShareOfVoiceData();
    },
    async init() {
      try {
        this.metadata = await this.dropdownService?.getMetadata();
        const { accessKeyMetadata, keys, actionIcons, dataBarProps } =
          this.metadata.customMetadata;
        this.accessKeyMetadata = accessKeyMetadata;
        this.keys = keys;
        this.actionIcons = actionIcons;
        this.accessKeys = this.generateAccessKeys(
          this.metricsList,
          this.accessKeyMetadata,
          this.metadata
        );
        this.dataBarProps = dataBarProps;
        this.lastUpdatedDate = moment(this.metadata?.calendar?.max_date).format(
          'LL'
        );
        this.customDate = {
          defaultDate: new Date(this.metadata?.calendar?.max_date),
          toDate: new Date(this.metadata?.calendar?.max_date)
        };
        this.selectedDate = this.getDateFormatted(
          this.metadata?.calendar?.max_date
        );
        if (this.fetchDataOnCreate) {
          this.getData();
        }
      } catch (e) {
        console.error('e', e);
      }
    },
    getDateFormatted(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    async getData() {
      try {
        await this.getKeywordsData();
        this.fetchWidgetVisualization();
      } catch (e) {
        console.error('e', e);
      }
    },
    setLocalFilters(requestCopy) {
      requestCopy.where.dimensionNameValueList = [
        {
          dimensionName: this.selectedKeyword[0].entityType,
          dimensionValue: this.selectedKeyword[0].title
        }
      ];
      if (hasPlaceholder(requestCopy.where.date)) {
        requestCopy.where.date.from = this.selectedDate;
        requestCopy.where.date.to = this.selectedDate;
        requestCopy.where.date.name = this.getGlobalWhereClause.date_range.name;
        requestCopy.where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      }
      if (hasPlaceholder(requestCopy.where.pvpDate)) {
        requestCopy.where.pvpDate.from = this.selectedDate;
        requestCopy.where.pvpDate.to = this.selectedDate;
        requestCopy.where.pvpDate.compare_name =
          this.getGlobalWhereClause.date_range.compare_name;
      }
    },
    getShareOfVoiceImages() {
      const { request: requestCopy } = cloneDeep(
        this.metadata.metrics[this.keys.sovImageMetricKey].api
      );
      this.setLocalFilters(requestCopy);
      requestDateReplacement(requestCopy.where, this.globalWhereClause);
      const accessKey = this.keys.marketingSearchRankKey;
      this.imageDataService
        .getData(requestCopy, accessKey, this.keys.listingTypeKey)
        .catch((err) => {
          console.error('e', err);
        });
    },
    getShareOfVoiceData() {
      const { request: requestCopy } = cloneDeep(
        this.metadata.metrics[this.keys.shareOfVoiceKey].api
      );
      this.setLocalFilters(requestCopy);
      requestDateReplacement(requestCopy.where, this.globalWhereClause);
      this.$emit('sovDataRequest', requestCopy);
      requestCopy.metricsList = this.metricsList;
      this.snapshotSharesService
        .getData(requestCopy, this.accessKeys, this.keyToCheck, this.clientName)
        .then(() => {
          this.$emit(
            'newLegendData',
            this.snapshotSharesService.data.legendValues
          );
        })
        .catch((err) => {
          console.error('e', err);
        });
    },
    setSelectedKeyword(dropdownData) {
      // find external keyword in the data set, if it exists then, set the current selected keyword to the found item, otherwise select the first element from the dropdown data as the selected keyword
      if (this.externalKeywordSelected) {
        const selectedKeywordIndex = dropdownData.findIndex(
          (item) => item.title === this.externalKeywordSelected
        );
        if (selectedKeywordIndex >= 0) {
          this.selectedKeyword = [dropdownData[selectedKeywordIndex]];
        } else {
          this.selectedKeyword = [dropdownData[0]];
        }
      } else {
        this.selectedKeyword = [dropdownData[0]];
      }
    },
    getKeywordsData() {
      return new Promise(async (resolve, reject) => {
        try {
          const { request: keywordsApi } = cloneDeep(
            this.metadata.metadata.keywordAPI
          );
          requestDateReplacement(keywordsApi.where, this.globalWhereClause);
          keywordsApi.where.dimensionNameValueList = [
            ...this.globalWhereClause.dimensionNameValueList
          ];
          this.$emit('keywordsDataRequest', keywordsApi);
          await this.dropdownService.getData(keywordsApi);
          this.setSelectedKeyword(this.dropdownService.data.rows);
          resolve();
        } catch (err) {
          console.error('err', err);
          reject(err);
        }
      });
    }
  }
};
</script>

<style lang="css">
.sov-snapshot-datepicker .vdp-datepicker__calendar {
  top: -320px;
}
</style>
<style scoped>
.custom-styles {
  width: 420px;
  line-height: 1.23;
}
</style>
