<template>
  <section
    :class="{
      'u-height-400': trendDataLoad,
      'u-spacing-ph-l': !widgetFetchedError
    }"
    class="u-display-flex u-flex-direction-column"
  >
    <div
      v-if="selectedComparision === metricComparison"
      class="u-display-flex u-flex-direction-column"
      :class="{ 'u-spacing-mb-l': !widgetFetchedError }"
    >
      <span
        v-if="getBrandManufactureList.length"
        class="u-text-transform-capitalize u-font-size-5 u-font-weight-600 u-color-grey-lighter"
      >
        {{ searchHeading }}
      </span>
      <div
        v-if="getBrandManufactureList.length"
        class="u-display-flex u-spacing-pt-s"
      >
        <dropdown-with-search
          class="u-border-radius-xxxl u-spacing-pv-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center u-bg-color-grey-white u-border-color-grey-xxx-light u-border-width-s u-border-all"
          :list-header="searchHeading"
          :options-list="getBrandManufactureList"
          @valueSelected="selectedEntity"
        />
      </div>
    </div>
    <div
      v-else
      class="u-spacing-mb-l"
    >
      <span
        class="u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-mb-s"
      >
        Metrics
      </span>
      <tab-panel
        :button-options="buttonOptions"
        :selected-metric="selectedMetric"
        @tabSelected="setSelected"
      />
    </div>
    <section
      v-if="trendDataLoad"
      class="u-display-flex u-flex-direction-column u-height-100"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        color="#007cf6"
      />
    </section>
    <section v-else-if="widgetFetchedError">
      <noData
        title="Uhh ohh! Something went wrong"
        :action="false"
        :description="[]"
        custom-description="Please try after sometime"
      />
    </section>
    <section
      v-else-if="metricDataLoaded"
      class="u-position-relative"
    >
      <div
        v-if="metricType"
        class="u-text-transform-capitalize u-font-size-5 u-font-weight-600 u-color-grey-lighter u-position-absolute"
      >
        {{ metricType }}
      </div>
      <chart-with-legends
        class="charting_wb_wrapper"
        :show-custom-tooltip="true"
        :chart-data="formattedChartData"
        :chart-config="computedChartConfig"
        :metrics-list="metricList"
        :metrics-shown="selectedValues.metricsShown"
        :default-select-metric="selectedValues.selectedMetric"
        :metric-config="selectedValues.metricDisplayConfig"
        :metric-data="selectedValues.metricData"
        :selected-metric-limit="selectedMetricLimit"
        :disable-add-new-metric="disableAddNewMetric"
        :is-chart-loading="isChartLoading"
        :emit-on-metric-default-change="true"
        :show-powered-by-c-i-q-text="true"
        :minimum-metric="4"
        :metric-font-size="'u-font-size-4'"
        :color-object="colorObject.brandToColor"
        :chart-header-text="metricType"
        :total-entity-count="totalEntityCount"
        :show-metric-count="true"
        :hide-zero="false"
        :plot-on-y-axis-only="true"
        @selectedMetricList="metricChanged"
      >
        <div
          slot="space-between-legend-and-chart"
          class="u-display-flex u-spacing-mt-l"
        >
          <div
            class="categorySize u-cursor-pointer u-spacing-pv-xs u-spacing-ph-s u-flex-align-items-center u-font-size-7 u-display-flex u-flex-direction-row u-border-all u-border-radius-xxxl u-border-color-grey-xxx-light"
            @click.stop="toggleCategorySize(showCategorySize)"
          >
            <rb-switch
              :value="showCategorySize"
              class="u-width-auto u-pointer-events-none"
              size="small"
            />
            <rb-icon
              icon="equalizer"
              class="rb-icon rb-icon--small u-spacing-mr-xs"
            />
            <div>Category Size</div>
          </div>
        </div>
      </chart-with-legends>
    </section>
  </section>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import {
  getDisplayByLabel,
  msProCategoryConstants,
  msProConstants,
  colorPalleteIndexed,
  getMetricSelectedForTabView
} from '@/components/pages/insights/amazon/market-share/utils';
import { tooltipRollupWidget } from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
import loader from '@/components/basic/loader';
import noData from '@/components/basic/noData.vue';
import HttpService from '@/utils/services/http-service';
import tabPanel from '@/components/pages/insights/amazon/market_share_pro/atoms/tabPanel.vue';
import DropdownWithSearch from '@/components/pages/insights/amazon/market_share_pro/atoms/dropdownWithSearch.vue';
import moment from 'moment';
import { enumerateDaysBetweenDates } from '@/utils/helpers/date.js';
import { getTimeseriesData } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';

export default {
  components: {
    chartWithLegends,
    loader,
    noData,
    tabPanel,
    DropdownWithSearch
  },
  props: {
    dataService: {
      type: Object,
      default: () => ({})
    },
    globalSettings: {
      type: [Array, Object],
      default: undefined
    },
    selectedComparision: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      updatedSelectedMetric: [],
      buttonOptions: msProCategoryConstants.trendsMetricListForTabView,
      selectedMetric: getMetricSelectedForTabView(),
      metricComparison: 'metric',
      showCategorySize: false,
      widgetFetchedError: false,
      chartData: {
        data: [],
        types: {},
        bar: {
          width: {
            ratio: 0.3 // this makes bar width 50% of length between ticks
          }
        }
      },
      metricList: [],
      selectedValues: {
        metricData: {},
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: []
      },
      selectedMetricLimit: 3,
      isChartLoading: false,
      readApiResponse: {},
      tooltipFormat: {},
      internalColorMaps: {},
      categorySizeData: {},
      viewBy: this.selectedComparision,
      dropDownList: [],
      dropDownSelection: '',
      categorySizeText: 'Category Size'
    };
  },
  computed: {
    disableAddNewMetric() {
      return this.metricList.length === this.selectedValues.metricsShown.length;
    },
    getBrandManufactureList() {
      return this.dropDownList;
    },
    searchHeading() {
      return this.globalSettings?.settingValue?.viewBy?.entityType;
    },
    totalEntityCount() {
      return this.showCategorySize
        ? this.metricList.length - 1
        : this.metricList.length;
    },
    metricType() {
      if (this.viewBy !== 'metric') {
        return this.globalSettings?.settingValue?.viewBy?.label;
      } else {
        return 'Metrics';
      }
    },
    colorObject() {
      if (this.internalColorMaps.brandToColor) {
        return this.internalColorMaps;
      } else {
        return {};
      }
    },
    metricDataLoaded() {
      return this.selectedValues.metricsShown.length;
    },
    trendDataLoad() {
      return this.dataService.isLoading;
    },
    formattedChartData() {
      const data = { ...this.chartData };
      return data;
    },
    rollUpBy() {
      return this.readApiResponse?.metadata?.dataAPI?.request?.operations
        ?.timeseriesRollupBy;
    },
    computedChartConfig() {
      let axis_format = {
        y: {
          show: true,
          suff: '%'
        },
        ...((this.showCategorySize ||
          this.selectedComparision === 'metric') && {
          y2: {
            show: true,
            pre: 'currency'
          }
        })
      };
      if (
        this.selectedComparision !== 'metric' &&
        this.selectedMetric === 'sales'
      ) {
        axis_format.y.suff = 'currency';
        delete axis_format.y2;
      }
      return {
        stack: [],
        xAxisType: 'category',
        chartOptions: {
          axis_format: axis_format,
          legend: false,
          xFormat: '%m/%d/%Y',
          timeseries: 'week_start_date',
          tooltip_format: this.tooltipFormat,
          grid: 'xy',
          hasEvents: false,
          type: 'line',
          show_axis_colors: true,
          events: [],
          size: {
            height: 400
          },
          line: { connectNull: true },
          xDataKey: 'x',
          tooltipTitle: tooltipRollupWidget(
            this.rollUpBy?.toLowerCase() + 's' || 'weeks',
            this.readApiResponse?.calendar?.max_date
          )
        }
      };
    }
  },
  watch: {
    selectedComparision: {
      handler() {
        this.resetSelection();
        this.viewBy = this.selectedComparision;
        if (this.readApiResponse?.metrics) {
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    globalSettings: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.showCategorySize = false;
          this.selectedMetricLimit = 3;
          this.getData();
        }
      },
      deep: true,
      immediate: true
    },
    internalColorMaps(newExternalColorMap) {
      if (!this.isChartLoading) {
        const newChartData = this.appendColorToChart(
          this.chartData,
          newExternalColorMap.brandToColor
        );
        this.$nextTick(() => {
          this.chartData = newChartData;
        });
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    resetSelection() {
      this.dropDownSelection = '';
      this.dropDownList = [];
      this.showCategorySize = false;
      this.selectedMetricLimit = 3;
    },
    selectedEntity(selectedValue) {
      this.dropDownSelection = selectedValue[0];
      this.getData();
    },
    findRemoveAndPush(array, key) {
      // Find the index of the object with the specified key
      const index = array.findIndex((item) => key in item);
      if (index !== -1) {
        // Remove the object from the array
        const [item] = array.splice(index, 1);
        // Push the object back into the array
        array.push(item);
      }
      return array;
    },
    metricChanged(event, rollupChanged, pvpTimeseriesChanged) {
      this.updatedSelectedMetric = event.selectedMetric;
      this.updatedSelectedMetric = this.findRemoveAndPush(
        this.updatedSelectedMetric,
        'category'
      );
    },
    setSelected(tabSelected) {
      this.selectedMetricLimit = 3;
      if (tabSelected === this.selectedMetric) {
        return;
      }
      this.selectedMetric = tabSelected;
      this.showCategorySize = false;
      if (this.readApiResponse?.metrics) {
        this.getData();
      }
      localStorage.setItem(
        'selectedMetricForTabView',
        JSON.stringify(tabSelected)
      );
    },
    appendColorToChart(data, colors) {
      return {
        ...data,
        colors: colors
      };
    },
    showErrorPageLevel(err) {
      this.isChartLoading = false;
      this.widgetFetchedError = true;
      console.log('err', err);
      this.$snackbar.open(msProConstants.errorMessage);
    },
    getMetricName(selectedMetric) {
      const displayShareIn =
        this.globalSettings.settingValue.displayShareIn.label;
      const include3p = this.globalSettings.settingValue.include3p.value;
      const metricMetaData =
        this.readApiResponse.metadata[
          msProCategoryConstants.performanceTrendMetrics[selectedMetric]
        ][displayShareIn][include3p];
      return metricMetaData;
    },
    constructLegendList(responseMetricData, selectedMetric) {
      const metricMetaData = this.getMetricName(selectedMetric);
      const timeseriesFormat = ['week_start_date'];
      let seriesAdded = false;
      const metricData = (responseMetricData || []).map((entity) => {
        return entity.data.reduce((previousVal, currentVal) => {
          const metricName = currentVal.name;
          if (metricName === metricMetaData) {
            previousVal.absoluteValue = currentVal.RESULT?.[currentVal.name];
            const metricMetaData =
              this.readApiResponse.metrics[metricName]?.metadata;
            if (metricMetaData) {
              previousVal.absoluteType =
                metricMetaData.unit !== '' ? metricMetaData.unit : 'NUMERIC';
              previousVal.invertTag = metricMetaData.isInverted;
            }
            if (currentVal.PVP) {
              previousVal.pvpDiff =
                currentVal.PVP['PVP_DIFF_' + currentVal.name];
              previousVal.pvp = currentVal.PVP['PVP_' + currentVal.name];
            }
            previousVal.timeseriesData = [];
            if (currentVal.TIMESERIES) {
              previousVal.timeseriesData.push(entity.entityValue);
              for (const timeseries of currentVal.TIMESERIES) {
                if (!seriesAdded) {
                  timeseriesFormat.push(timeseries.week_start_date);
                }
                previousVal.timeseriesData.push(timeseries[metricName]);
              }
            }
            seriesAdded = true;
          } else {
            previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
          }
          if (this.viewBy !== 'metric') {
            previousVal.preText = `${entity.entityType}:`;
            previousVal.tooltip = entity.entityValue;
          } else {
            previousVal.tooltip =
              msProCategoryConstants.metricVsMetric?.[selectedMetric]
                ?.tooltip || '';
          }
          previousVal.timeseriesFormat = timeseriesFormat;
          previousVal.key = entity.entityValue;
          previousVal.name = entity.entityValue;
          previousVal.title = entity.entityValue;
          previousVal.data = entity.data;
          previousVal.entityType = entity.entityType;
          previousVal.entityValue = entity.entityValue;

          return previousVal;
        }, {});
      });
      return metricData;
    },
    getXAxis(fromDate, toDate, interval) {
      let startDate = moment(fromDate);
      const endDate = moment(toDate);
      const intervalLowerCase = interval.toLowerCase();
      if (intervalLowerCase === 'weeks') {
        startDate = moment(startDate).startOf('isoWeek');
        startDate = moment(startDate).subtract(1, 'd');
      }
      const range = enumerateDaysBetweenDates(
        startDate,
        endDate,
        intervalLowerCase
      );
      return ['week_start_date', ...range.slice(1, range.length)];
    },
    constructChartData() {
      const newColorMap = {
        brandToColor: {}
      };
      let suffix = '';
      if (this.viewBy !== 'metric') {
        suffix =
          this.selectedMetric === 'sales'
            ? getDisplayByLabel() === 'Dollars'
              ? 'currency'
              : ''
            : '%';
      }
      const tooltipSuffix = this.selectedMetric === 'sales' ? '' : '%';
      for (const [index, entityUnit] of this.metricList.entries()) {
        if (this.viewBy === 'metric') {
          suffix =
            entityUnit.absoluteType === 'PERCENTAGE'
              ? '%'
              : getDisplayByLabel() !== 'Dollars'
              ? ''
              : entityUnit?.absoluteType?.toLowerCase();
        }
        if (entityUnit.entityValue === this.categorySizeText) {
          suffix = '';
        }
        this.selectedValues.metricDisplayConfig[entityUnit.key] = {
          invertTag2: entityUnit.invertTag,
          tag1Unit: { suff: suffix, roundoff: 2 },
          tag2Unit: { suff: '%', roundoff: 2 }
        };
        this.selectedValues.metricData[entityUnit.key] = {
          tag1: entityUnit.absoluteValue,
          tag2:
            entityUnit.absoluteType === 'PERCENTAGE'
              ? entityUnit.pvpDiff
              : entityUnit.pvp
        };
        this.tooltipFormat[entityUnit.key] = {
          suff: this.viewBy === 'metric' ? suffix : tooltipSuffix
        };
        newColorMap.brandToColor[entityUnit.key] = colorPalleteIndexed[index];
        if (index === this.metricList.length - 1) {
          newColorMap.brandToColor[this.categorySizeText] =
            colorPalleteIndexed[index + 1];

          this.tooltipFormat[this.categorySizeText] = {
            suff: suffix
          };
        }
      }

      this.internalColorMaps = newColorMap;
      let xAxis = this.getXAxis(
        this.globalSettings.where?.date?.from,
        this.globalSettings.where?.date?.to,
        'Weeks'
      );
      let formattedChartData = [];
      let axes = {};
      for (const entityUnit of this.metricList) {
        formattedChartData.push(
          getTimeseriesData(
            xAxis,
            entityUnit.name,
            entityUnit,
            entityUnit?.data?.length - 1
          )
        );
        axes[entityUnit.key] = 'y';
        if (
          this.selectedComparision !== 'metric' &&
          this.selectedMetric === 'sales'
        ) {
          axes['Category Size'] = 'y';
        } else {
          axes['Category Size'] = 'y2';
          axes.Sales = 'y2';
        }
      }
      xAxis = xAxis.map((date, index) => {
        return index === 0 ? date : moment(date).format('MM/DD/YYYY');
      });
      const newChartData = [xAxis, ...formattedChartData];
      this.chartData = {
        ...this.chartData,
        data: newChartData,
        axes
      };
      this.isChartLoading = false;
    },
    updateSelectedMetricList() {
      for (let i = 0; i < this.metricList.length; i++) {
        const item = this.metricList[i];
        const isAlreadyInCriteria = this.selectedValues.metricsShown.some(
          (criteria) => criteria.key === item.key
        );
        if (!isAlreadyInCriteria) {
          this.selectedValues.metricsShown.push(item);
        }
        // Stop the loop when the length reaches 5
        if (this.selectedValues.metricsShown.length >= 5) {
          break;
        }
      }
    },
    async getCategorySize(val) {
      try {
        this.selectedMetricLimit = 4;
        this.selectedValues.metricsShown = [...this.updatedSelectedMetric];
        this.updateSelectedMetricList();
        this.selectedValues.selectedMetric = [...this.updatedSelectedMetric];
        this.isChartLoading = true;
        this.dataService.isLoading = true;
        const selectedMetric = 'categorySize';
        const metricMetaData = this.getMetricName(selectedMetric);
        const apiStructure = this.readApiResponse.metrics[metricMetaData].api;
        const response = await this.dataService.getData(
          apiStructure,
          this.globalSettings,
          selectedMetric
        );
        response[0].entityValue = this.categorySizeText;
        this.categorySizeData = await this.constructLegendList(
          response,
          selectedMetric
        );
        this.metricList.push(this.categorySizeData[0]);
        this.constructChartData();
        this.selectedValues.selectedMetric = [
          ...this.updatedSelectedMetric,
          this.categorySizeData[0]
        ];
        this.chartData.types[this.categorySizeText] = 'bar';
        this.dataService.isLoading = false;
        this.isChartLoading = false;
        this.showCategorySize = true;
      } catch (err) {
        this.isChartLoading = false;
        this.showCategorySize = false;
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    removeCategorySize() {
      this.selectedMetricLimit = 3;
      this.showCategorySize = false;
      this.selectedValues.selectedMetric = this.updatedSelectedMetric.filter(
        (metric) => metric.key !== this.categorySizeText
      );
      this.metricList = this.metricList.filter(
        (metric) => metric.key !== this.categorySizeText
      );
      this.chartData.data = this.chartData.data.filter(
        (metricData) => metricData[0] !== this.categorySizeText
      );
    },
    toggleCategorySize() {
      const enableCategorySize = !this.showCategorySize;
      if (enableCategorySize) {
        this.getCategorySize(enableCategorySize);
      } else {
        this.removeCategorySize(enableCategorySize);
      }
    },
    async updateDisplayName() {
      // update metric display name
      let metricList = [];
      for (const metricKey in msProCategoryConstants.metricVsMetric) {
        let flag = false;
        const selectedMetric = this.getMetricName(metricKey);
        const displayName =
          msProCategoryConstants.metricVsMetric[metricKey].displayName;
        for (const metricData of this.metricList) {
          for (const metricMetaData of metricData.data) {
            if (metricMetaData.name === selectedMetric) {
              metricData.entityValue = displayName;
              const legend = await this.constructLegendList(
                [metricData],
                metricKey
              );
              metricList = [...metricList, ...legend];
              break;
            }
          }
          if (flag) {
            break;
          }
        }
      }
      this.metricList = metricList;
      this.selectedValues.metricsShown = this.metricList.slice(0, 5);
      this.selectedValues.selectedMetric = this.metricList.slice(0, 2);
      this.dataService.isLoading = false;
      this.constructChartData();
    },
    constructDropDownList(dataList) {
      for (const metric of dataList) {
        if (metric.status === 'fulfilled') {
          const itemsList = metric?.value?.data?.entityData;
          for (const items of itemsList) {
            this.dropDownList.push({ name: items?.entityValue });
          }
          break;
        }
      }
    },
    async getData() {
      try {
        this.isChartLoading = true;
        if (this.viewBy !== 'metric') {
          const data = await this.dataService.getData(
            this.readApiResponse?.metadata?.dataAPI,
            this.globalSettings,
            this.selectedMetric
          );
          this.metricList = await this.constructLegendList(
            data,
            this.selectedMetric
          );
          this.selectedValues.metricsShown = this.metricList.slice(0, 5);
          this.selectedValues.selectedMetric = this.metricList.slice(0, 2);
          this.constructChartData();
        } else {
          this.dataService.isLoading = true;
          this.metricList = [];
          let metricRequestList = [];
          Object.keys(msProCategoryConstants.metricVsMetric).forEach((key) => {
            const selectedMetric = key;
            const metricMetaData = this.getMetricName(key);
            const apiStructure =
              this.readApiResponse.metrics[metricMetaData].api;
            const dataRequest = this.dataService.constructPayload(
              apiStructure.request,
              this.globalSettings,
              selectedMetric
            );
            metricRequestList.push(
              HttpService.post(apiStructure?.service, dataRequest, {
                append: apiStructure.endPoint
              })
            );
          });

          Promise.allSettled(metricRequestList).then(async (response) => {
            response = response.filter(
              (metric) => metric.status !== 'rejected'
            );
            if (response.length) {
              await this.constructDropDownList(response);
              const selecteditem =
                this.dropDownSelection.name ?? this.dropDownList[0].name;
              for (const metric of response) {
                if (metric.status === 'fulfilled') {
                  const itemsList = metric?.value?.data?.entityData;
                  for (const items of itemsList) {
                    if (items?.entityValue === selecteditem) {
                      this.metricList.push(items);
                    }
                  }
                }
              }
              this.constructDropDownList(response);
              this.updateDisplayName();
            } else {
              this.dataService.isLoading = false;
              this.showErrorPageLevel();
            }
          });
        }
      } catch (err) {
        this.isChartLoading = false;
        this.showErrorPageLevel(err);
      }
    },
    async init() {
      try {
        this.isChartLoading = true;
        this.readApiResponse = await this.dataService.getMetadata();
        await this.getData();
      } catch (err) {
        this.isChartLoading = false;
        this.showErrorPageLevel(err);
      }
    }
  }
};
</script>

<style lang="css">
.chart_space_class {
  margin-top: 0 !important;
}
.charting_wb_wrapper {
  position: relative;
}
.charting_wb_wrapper .chart-header-text {
  position: absolute;
  top: 0.2rem;
  left: 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: #8b8f93;
}
</style>

<style lang="css" scoped>
.categorySize {
  height: 24px;
}
</style>
