import { render, staticRenderFns } from "./sotOptionSelection.vue?vue&type=template&id=45f2ff6a&scoped=true&"
import script from "./sotOptionSelection.vue?vue&type=script&lang=js&"
export * from "./sotOptionSelection.vue?vue&type=script&lang=js&"
import style0 from "./sotOptionSelection.vue?vue&type=style&index=0&lang=css&"
import style1 from "./sotOptionSelection.vue?vue&type=style&index=1&id=45f2ff6a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f2ff6a",
  null
  
)

export default component.exports