import Campaigns from '@/components/pages/instacart/campaigns/campaigns/index.vue';
import Keywords from '@/components/pages/instacart/campaigns/keywords/index.vue';
import SKUs from '@/components/pages/instacart/campaigns/skus/index.vue';
import AdGroups from '@/components/pages/instacart/campaigns/adGroups/index.vue';
import CreateCampaignOrAdGroup from '@/components/pages/instacart/campaign-creation/index.vue';
import LaunchCampaign from '@/components/pages/instacart/campaign-launched/index.vue';
import CONSTANTS from '@/utils/constants';
const EntityDetails = () => import('@/pages/entity_details/index.vue');

const getcampaignManagementRoutes = () => {
  const routes = [
    {
      path: 'campaigns/:entityType/:entityId',
      name: 'InstacartEntityDetails',
      title: 'Campaign Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
      name: 'SubEntityDetails',
      title: 'Ad Group Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns/:entityType/:entityId/create_ad_group',
      name: 'create_ad_group',
      title: 'Create Ad Group',
      component: CreateCampaignOrAdGroup,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns/create_campaign',
      name: 'Create Campaign',
      title: 'Create Campaign',
      component: CreateCampaignOrAdGroup,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns/launch_campaign',
      name: 'Launch Campaign',
      title: 'Launch Campaign',
      component: LaunchCampaign,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: Campaigns,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'keywords',
      name: 'Keywords',
      title: 'Keywords',
      component: Keywords,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'keywords'
    },
    {
      path: 'ad_skus',
      name: 'SKUs',
      title: 'SKUs',
      component: SKUs,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'ams_skus'
    },
    {
      path: 'ad_groups',
      name: 'AdGroups',
      title: 'Ad Groups',
      component: AdGroups,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'ad_groups'
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM
    }
  }));

  routes.push({
    path: '',
    redirect: () => {
      return 'campaigns';
    }
  });

  return routes;
};

export default {
  configCheckRequired: false,
  routes: getcampaignManagementRoutes()
};
