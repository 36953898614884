var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showEdit)?_c('modal',{attrs:{"modal-config":_vm.modalConfig}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"content-edit-container u-spacing-pv-l"},[_c('div',{staticClass:"current-content-score",style:({ background: _vm.getColors(_vm.imageContentDetails.contentScore) })},[(
            [_vm.SKU_STATE.IMPROVED, _vm.SKU_STATE.NEGATIVE].includes(
              _vm.imageContentDetails.state
            )
          )?_c('rb-icon',{class:{
            'u-color-yellow-base':
              _vm.SKU_STATE.IMPROVED === _vm.imageContentDetails.state,
            'u-color-red-base':
              _vm.SKU_STATE.NEGATIVE === _vm.imageContentDetails.state
          },attrs:{"icon":"warning"}}):_vm._e(),_c('div',[_c('p',{staticClass:"u-font-size-5 u-font-weight-600"},[_c('span',{staticClass:"u-text-transform-capitalize"},[_vm._v(_vm._s(_vm.imageContentDetails.contentType))]),_vm._v(" content Score "),_c('span',{staticClass:"u-font-size-2 u-spacing-p-s u-font-weight-800"},[_vm._v(_vm._s(_vm.imageContentDetails.contentScore + '%'))])]),_c('p',{staticClass:"u-font-size-5"},[_vm._v(" "+_vm._s(_vm.imageContentDetails.contentScoreDescription)+" ")])])],1)]),_c('img',{staticClass:"current-img",attrs:{"src":_vm.imageContentDetails.currentImageUrl,"alt":"current image"}}),_c('div',{staticClass:"container-base suggestion u-spacing-mv-xl"},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between"},[_c('p',{staticClass:"u-font-size-3 u-color-grey-mid-light u-font-weight-600"},[_vm._v(" SOT Image ")])]),_c('img',{staticClass:"suggested-img",attrs:{"src":_vm.imageContentDetails.suggestedImageUrl,"alt":"SOT image"}}),_c('p',{staticClass:"u-font-size-3 u-color-grey-mid-light"},[_vm._v("Source Data: PIM")]),_c('rb-button',{staticClass:"u-color-grey-white u-spacing-mt-m",attrs:{"text":"Optimize content","type":"filled","icon-left":"segment","click-fn":_vm.matchSOTConentent.bind(this)}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }