<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('organic_growth') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('average_units_growth_is_')
      }}{{ formatter(alert.attributes.avg_units_growth, 'percent') }} ({{
        $t('last_3_weeks')
      }}).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <p class="u-spacing-pt-xl">
      {{ $t('average_units_daily_sold') }}:
      {{ formatter(alert.attributes.avg_units_sold_daily_lw, 'number') }} ({{
        $t('last_1_week')
      }}),
      {{ formatter(alert.attributes.avg_units_sold_daily_lw2, 'number') }} ({{
        $t('last_2_weeks')
      }}) {{ $t('and') }}
      {{ formatter(alert.attributes.avg_units_sold_daily_lw3, 'number') }} ({{
        $t('last_3_weeks')
      }})
    </p>
    <div class="u-display-flex u-spacing-pt-m u-spacing-pb-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('list_price')"
          :item-value="
            formatter(alert.attributes.current_list_price, 'currency')
          "
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('offer_price')"
          :item-value="
            formatter(alert.attributes.current_offer_price, 'currency')
          "
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('promo')"
          :item-value="alert.attributes.promo_flag"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('sri')"
          :item-value="formatter(alert.attributes.sri, 'percentFraction')"
        />
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('revenue')"
          :item-value="
            formatter(alert.attributes.last_4weeks_revenue_client, 'currency')
          "
        />
        <span class="u-font-size-7 u-font-weight-300">
          ({{ $t('last_4_weeks') }})</span
        >
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p
      v-if="alert.attributes.recommended_asin_to_variate"
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max) per week:
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('organic_growth_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p
      v-if="alert.attributes.recommended_asin_to_variate"
      class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s"
    >
      {{ $t('organic_growth_rec_text') }}
    </p>
    <div class="detail-view--card">
      <el-table
        v-if="alert.attributes.recommended_asin_to_variate"
        :data="[{}]"
      >
        <el-table-column
          :label="$t('SKU_details')"
          :width="300"
        >
          <div slot-scope="scope">
            <div>
              <p
                :title="alert.attributes.title_rec_asin"
                class="u-text-overflow-ellipsis"
              >
                {{ alert.attributes.title_rec_asin }}
              </p>
              <p>{{ alert.attributes.recommended_asin_to_variate }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('list_price')">
          <div slot-scope="scope">
            <div>
              <p>
                {{ formatter(alert.attributes.list_price_rec, 'currency') }}
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('offer_price')">
          <div slot-scope="scope">
            <div>
              <p>
                {{ formatter(alert.attributes.offer_price_rec, 'currency') }}
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('cogs')">
          <div slot-scope="scope">
            <div>
              <p>{{ formatter(alert.attributes.cogs_rec, 'currency') }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('revenue')">
          <div slot-scope="scope">
            <div>
              <p>
                {{
                  formatter(
                    alert.attributes.last_4weeks_revenue_rec_asin,
                    'currency'
                  )
                }}
              </p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import keyValuePair from '@/components/basic/keyValuePair';
export default {
  components: {
    chart,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      promoDesc: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            'Promo Timeline': 'y2'
          },
          tooltip_format: {
            SRI: {
              roundoff: 0
            },
            'Unit Sales': {
              roundoff: 0
            },
            'Promo Timeline': function (value, ratio, id, index) {
              return this.promoDesc[index];
            }.bind(this)
          },
          point_format: {
            'Promo Timeline': function (data) {
              if (this.promoDesc[data.index] !== '') {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    var xs = {
      SRI: 'SRI_x',
      'Unit Sales': 'Unit Sales_x',
      'Promo Timeline': 'Promo Timeline_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var sriTrend = [];
    var unitSalesTrend = [];
    var promoTrend = [];

    if (this.alert.attributes.sri_trend) {
      sriTrend = JSON.parse(this.alert.attributes.sri_trend);
      if (sriTrend.length) {
        sriTrend = sriTrend.sort((a, b) => {
          if (a.Date > b.Date) return -1;
          else if (a.Date < b.Date) return 1;
          return 0;
        });
        dataArray = ['SRI'];
        dateArray = ['SRI_x'];
        for (entry of sriTrend) {
          if (entry.sri === undefined) continue;
          dataArray.push(entry.sri);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    if (this.alert.attributes.units_sales_trend) {
      unitSalesTrend = JSON.parse(this.alert.attributes.units_sales_trend);
      if (unitSalesTrend.length) {
        dataArray = ['Unit Sales'];
        dateArray = ['Unit Sales_x'];
        for (entry of unitSalesTrend) {
          if (entry.unit_sales === undefined) continue;
          dataArray.push(entry.unit_sales);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    if (this.alert.attributes.promo_timeline_trend) {
      promoTrend = JSON.parse(this.alert.attributes.promo_timeline_trend);
      if (promoTrend.length) {
        promoTrend = promoTrend.sort((a, b) => {
          if (a.Date < b.Date) return -1;
          else if (a.Date > b.Date) return 1;
          return 0;
        });
        dataArray = ['Promo Timeline'];
        dateArray = ['Promo Timeline_x'];
        for (entry of promoTrend) {
          if (entry.promo_desc === undefined || entry.promo_desc === 'NA')
            continue;
          var desc = JSON.parse(JSON.stringify(entry.promo_desc));
          if (desc.length === 0) continue;
          dataArray.push(1);
          dateArray.push(entry.Date);
          this.promoDesc.push(desc.join(', '));
        }
        chartData.push(dataArray, dateArray);
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
