<template>
  <div class="u-position-relative content">
    <div
      v-if="!loading && !error"
      class="content u-spacing-pv-l u-display-flex u-flex-direction-column u-font-size-5 u-border-color-grey-xxx-light u-border-width-s u-border-radius-s u-border-all u-flex-justify-content-space-around u-flex-align-items-center"
    >
      <div
        v-tippy
        :title="value1tooltip"
        class="title u-font-size-3"
      >
        <span class="u-color-grey-mid-ligh u-spacing-mr-xs">{{
          title.split(' ')[0]
        }}</span>
        <span class="u-color-grey-x-light">{{ title.split(' ')[1] }}</span>
      </div>
      <div class="u-display-inline-flex">
        <span
          :title="getTippyString(value1, getLocale, value1Format)"
          class="u-font-size-1 u-font-weight-600"
          data-cy="metricValue"
          >{{ formatterFn(value1, value1Format, '0') }}</span
        >
        <span
          v-if="value2meta.invert && value2 != undefined"
          v-trend-indicator
          :class="{
            'u-color-red-base': value2 > 0,
            'u-color-green-base': value2 <= 0
          }"
          class="u-spacing-pl-s u-font-size-4"
        >
          {{ formatterFn(value2, value2meta.formatType) }}
          <!-- <animated-number :formatterType="value2Meta.formatType" :number="value2"></animated-number> -->
        </span>
        <span
          v-else-if="value2 != undefined"
          v-trend-indicator
          :class="{
            'u-color-red-base': value2 < 0,
            'u-color-green-base': value2 >= 0
          }"
          class="u-spacing-pl-s u-font-size-4"
        >
          {{ formatterFn(value2, value2meta.formatType) }}
          <!-- <animated-number :formatterType="value2Meta.formatType" :number="value2"></animated-number> -->
        </span>
      </div>
      <div
        v-if="value3 != undefined"
        :title="getTippyString(value3, getLocale, value3Format)"
        class="u-font-weight-600 u-color-grey-x-light"
      >
        <!-- <animated-number :formatterType="value3Format" :number="value3"></animated-number> -->
        {{ formatterFn && formatterFn(value3, value3Format, '0') }}
      </div>
    </div>
    <div v-if="loading">
      <loader
        class="fill--parent"
        :loading="true"
        :color="'#3684bb'"
      />
    </div>
  </div>
</template>

<script>
import {
  formatter,
  getTippyString,
  getLocale
} from '../../utils/helpers/formatter.js';
// import animatedNumber from './animated-number.vue';
import loader from '@/components/basic/loader';
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    // animatedNumber,
    loader
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Default Title'
    },
    value1Format: {
      type: String,
      default: 'NUMERIC'
    },
    value1Meta: {
      type: Object,
      default: () => {
        return {
          formatType: 'percent',
          trend: true,
          invert: false
        };
      }
    },
    value2meta: {
      type: Object,
      default: () => {
        return {
          formatType: 'percent',
          trend: true,
          invert: false
        };
      }
    },
    value3Format: {
      type: String,
      default: 'currency'
    },
    value1: {
      type: [Number, String]
    },
    value2: {
      type: [Number, String]
    },
    value3: {
      type: [Number, String]
    },
    value3Tooltip: {
      type: String,
      default: 'Value'
    },
    value1tooltip: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formatterFn: formatter,
      value1Data: null
    };
  },
  watch: {},
  methods: {
    getTippyString,
    getLocale
  }
};
</script>

<style lang="css" scoped>
.content {
  min-height: 150px;
  background-color: #ffffff;
}
</style>
