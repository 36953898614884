export const getAdvertiserRequestPayload = (retailer, advertiser_id) => ({
  cubeName: `${retailer}_advertiser_details`,
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [],
  groupByDimensionsList: ['advertiser_id', 'advertiser_name'],
  orderByList: [],
  where: {
    dimensionNameValueList:
      retailer === 'walmart'
        ? [
            {
              dimensionName: 'advertiser_type',
              dimensionValue: 'api',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'advertiser_id',
              dimensionValue: advertiser_id,
              operator: 'ILIKE'
            }
          ]
        : []
  }
});
