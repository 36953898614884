import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import {
  groupApis,
  createDictionary,
  getDynamicColumnDefs,
  replacePlaceHolderWithData
} from './dashboard-service-utils';
import { isEmpty } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default class DashboardDataService {
  widgetName;
  page;
  pageId;
  globalViewId;
  constructor(metadata, widgetName, page, pageId) {
    this.widgetName = widgetName;
    this.pageId = pageId;
    this.page = page;
    this.load = true;
    this.metadata = metadata;
    this.tableGrouping = null;
  }

  async download(operations, widgetRequestParams) {
    try {
      this.load = true;
      const api = this.metadata.downloadApiTemplate;
      this.tableGrouping = groupApis(this.metadata.metrics);
      const keys = Object.keys(this.tableGrouping);
      if (keys.length === 1) {
        api.request.metricsList =
          this.tableGrouping[keys[0]]?.api?.request?.metricsList;
      }
      const request = replacePlaceHolderWithData(
        api.request,
        widgetRequestParams
      );
      request.where.dimensionNameValueList =
        widgetRequestParams[':dimensionNameValueList'];
      const response = await HttpService.post(api.service, request, {
        append: api.endPoint
      });
      downloadLinkAsFile(response?.data?.url);
    } catch (error) {
      throw Error(error);
    }
    this.load = false;
  }

  async getDataList(operations, widgetRequestParams) {
    let extraConfigs = {};
    if (
      window.location.href.includes('/campaigns/') ||
      window.location.href.includes('/campaigns/')
    ) {
      extraConfigs = {
        headers: {
          cache: 'false'
        }
      };
    }
    try {
      this.load = true;
      this.tableGrouping = groupApis(this.metadata.metrics);
      const metricsResponseData = {};
      const bundleDataGroupsRequest = {};
      const bundleDataGroupPageAndLimi = {
        limit: 0,
        page: 1
      };
      for (const groupName of Object.keys(this.tableGrouping)) {
        const api = this.tableGrouping[groupName].api;
        const request = replacePlaceHolderWithData(
          api.request,
          widgetRequestParams
        );
        request.operations = { ...operations, ...request.operations };
        if (!widgetRequestParams?.keepExistingDimensionNameValueList) {
          request.where.dimensionNameValueList =
            widgetRequestParams[':dimensionNameValueList'];
        } else if (
          !isEmpty(widgetRequestParams.replaceEntityInDimensionNameValueList)
        ) {
          for (const item of request.where.dimensionNameValueList) {
            item.dimensionValue =
              widgetRequestParams?.replaceEntityInDimensionNameValueList[
                item.dimensionValue
              ] || item.dimensionValue;
          }
          if (widgetRequestParams[':dimensionNameValueList']?.length) {
            // add dimension list for search for bundle data group request
            const metricAndDimensionList = [
              ...(request?.metricsList || []),
              ...(request?.dimensionsList || [])
            ];
            for (const bundleDataDimension of widgetRequestParams[
              ':dimensionNameValueList'
            ]) {
              if (
                metricAndDimensionList.includes(
                  bundleDataDimension.dimensionName
                )
              ) {
                request.where.dimensionNameValueList.push(bundleDataDimension);
              }
            }
          }
        }
        request.enablePaginationCount = true;
        if (widgetRequestParams[':metricsList']?.length > 0) {
          request.metricsList = widgetRequestParams[':metricsList'];
        }
        if (!widgetRequestParams?.metadata?.bundleDataEnabled) {
          const response = await HttpService.post(api.service, request, {
            append: api.endPoint,
            ...extraConfigs
          });
          metricsResponseData[groupName] = response?.data;
        } else {
          // bundleDataGroupsRequest payload for real time KPI
          if (request?.operations?.limit) {
            bundleDataGroupPageAndLimi.limit = request.operations.limit;
            delete request.operations.limit;
          }
          if (request?.operations?.page) {
            bundleDataGroupPageAndLimi.page = request.operations.page;
            delete request.operations.page;
          }
          bundleDataGroupsRequest[groupName] = request;
        }
      }

      // bundleDataGroupsRequest payload for real time KPI
      if (widgetRequestParams?.metadata?.bundleDataEnabled) {
        const api = widgetRequestParams.metadata.bundleDataAPI;
        const request = replacePlaceHolderWithData(
          api.request,
          widgetRequestParams
        );
        if (widgetRequestParams?.replaceEntityInOperationsObject) {
          for (const item in widgetRequestParams.replaceEntityInOperationsObject) {
            deepReplaceObjectValues(
              request.operations,
              item,
              widgetRequestParams.replaceEntityInOperationsObject[item]
            );
          }
        }
        request.operations.limit = bundleDataGroupPageAndLimi.limit;
        request.operations.page = bundleDataGroupPageAndLimi.page;
        if (operations?.orderByList?.length) {
          request.operations.orderByList = [...operations?.orderByList];
        } else if (widgetRequestParams?.[':orderByList']?.length) {
          request.operations.orderByList = [
            ...widgetRequestParams[':orderByList']
          ];
        } else if (
          widgetRequestParams?.metadata?.bundleDataAPI?.request?.operations
            ?.length
        ) {
          request.operations.orderByList =
            widgetRequestParams?.metadata?.bundleDataAPI?.request?.operations;
        } else {
          request.operations.orderByList = [];
        }
        request.bundleDataGroupsRequest = bundleDataGroupsRequest;
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint,
          ...extraConfigs
        });
        for (const item in bundleDataGroupsRequest) {
          metricsResponseData[item] = response?.data;
        }
      }
      this.load = false;
      return metricsResponseData;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  async getTableRows(operations, widgetRequestParams) {
    try {
      this.load = true;
      let data = await this.getDataList(operations, widgetRequestParams);
      data = dashUtils.mergeResponseToSingleRowObjArray(data);
      if (widgetRequestParams?.leftJoin?.value) {
        data = await widgetRequestParams.leftJoin.fn(data);
      }
      this.load = false;
      return data;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  getTableColumns(operations, widgetRequestParams, columnCellMapping) {
    try {
      this.load = true;
      const metricKeys = Object.keys(this.metadata.metrics);
      const columns = createDictionary(metricKeys, this.metadata.metrics);
      let colDefs = getDynamicColumnDefs(
        columns,
        this.metadata?.metadata?.defaultOperations || operations?.orderByList
          ? operations
          : null || {
              orderByList: widgetRequestParams?.[':orderByList'] || []
            },
        columnCellMapping
      );
      // for enabling custom col spans in tables with review status (Walmart SBA camapigns=>adgroups=>ads)
      if (this.metadata?.metadata?.stripColumn) {
        colDefs = colDefs.map((colDef) => ({
          ...colDef,
          colSpan: (params) => {
            const status =
              colDef.field ===
              this.metadata.metadata.stripColumn.stripStartColumn
                ? params.data?.[
                    this.metadata.metadata.stripColumn.stripStartColumnData
                  ]
                : null;
            if (['pending', 'rejected'].includes(status)) {
              return colDefs.filter((col) => !col.pinned).length;
            } else {
              return 1;
            }
          }
        }));
      }
      this.load = false;
      return colDefs;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  updateMetadata(metadata) {
    this.metadata = metadata;
  }
}
