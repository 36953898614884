import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import Vue from 'vue';
import moment from 'moment-timezone';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';

// import { eventBus } from '@/utils/services/eventBus';
const state = {
  startegyStaticMetric: {},
  isLoading: false,
  isError: false,
  primaryFilter: [],
  filterData: [],
  strategyListFilterData: [],
  strategyDetails: {
    mode: 'create',
    strategyName: 'Untitled Strategy',
    description: '',
    objective: {},
    scope: {
      subFilter: null,
      scopeRules: null
    },
    actionsPanelSubsteps: null,
    bidMultiplierData: null,
    strategyEntityType: null,
    rules: null,
    lookBackWindow: null,
    actions: null,
    strategyStartDate: new Date(),
    strategyEndDate: null,
    strategyUIText: {},
    strategyInfoText: {},
    conditionFilterValues: {},
    scopeFilterValues: {},
    executionTemplate: {},
    lockoutValues: {},
    actionPanelValues: {},
    enableSaveBtn: false
  }
};

const getters = {
  getStrategyStaticMetaData: (state) => {
    return state.startegyStaticMetric;
  },
  getLoadingStatus: (state) => {
    return state.isLoading;
  },
  getErrorStatus: (state) => {
    return state.isError;
  },
  getPrimaryFilterData: (state) => {
    return state.primaryFilter;
  },
  getFilterData: (state) => {
    return [...state.filterData, ...state.strategyListFilterData];
  },
  getStrategyDetails: (state) => {
    return state.strategyDetails;
  }
};

const mutations = {
  SHOW_LOADING: (state) => {
    state.isLoading = true;
  },
  HIDE_LOADING: (state) => {
    state.isLoading = false;
  },
  STRATEGY_META_DATA: (state, data) => {
    state.startegyStaticMetric = data;
  },
  SHOW_ERROR: (state) => {
    state.isError = true;
  },
  HIDE_ERROR: (state) => {
    state.isError = false;
  },
  SET_PRIMARY_FILTER: (state, data) => {
    const { retailer = null, strategyEntityType = null } =
      state?.strategyDetails;
    const currentEntityType = strategyEntityType?.entityType;
    const customFilter =
      multiRetailerConfig?.customConditions?.[retailer]?.[currentEntityType] ||
      [];
    state.primaryFilter = [...data, ...customFilter];
  },
  SET_FILTER_DATA: (state, data) => {
    if (Array.isArray(data)) {
      state.filterData = [...data];
    }
  },
  SET_STRATEGY_FILTERS_DATA: (state, data) => {
    state.strategyListFilterData = data.map((strategy) => {
      const jsonStringObj = {
        strategyName: strategy.strategyName,
        strategyUniqueId: strategy.strategyUniqueId,
        description: strategy.description,
        rules: strategy.rules
      };
      return {
        tags: JSON.stringify(jsonStringObj)
      };
    });
  },
  SET_STRATEGY_DETAILS: (state, response) => {
    const { data } = response;
    Vue.set(
      state.strategyDetails,
      'actionsPanelSubsteps',
      data.actionsPanelSubsteps
    );
    Vue.set(state.strategyDetails, 'strategyStatus', data.strategyStatus);
    Vue.set(state.strategyDetails, 'strategyName', data.strategyName);
    Vue.set(state.strategyDetails, 'description', data.description);
    Vue.set(state.strategyDetails, 'strategyStartDate', data.strategyStartDate);
    const strategyEndDate = data.strategyEndDate
      ? moment.utc(data.strategyEndDate).format('YYYY-MM-DD')
      : null;
    Vue.set(state.strategyDetails, 'strategyEndDate', strategyEndDate);
    Vue.set(state.strategyDetails, 'lookBackWindow', data.lookBackWindow);
    Vue.set(state.strategyDetails, 'mode', response.request.mode);
    state.startegyStaticMetric.standardObjectives.forEach((item) => {
      if (item.objective === data.objective) {
        Vue.set(state.strategyDetails, 'objective', item);
      }
    });
    state.startegyStaticMetric.scope.entityList.forEach((item) => {
      if (item.entityType === data.strategyEntityType) {
        Vue.set(state.strategyDetails, 'strategyEntityType', item);
      }
    });
    Vue.set(state.strategyDetails, 'rules', data.rules);
    Vue.set(state.strategyDetails, 'conditionFilterValues', data.rules);
    Vue.set(
      state.strategyDetails,
      'strategyUIText',
      data.strategyInfoText || {}
    );
    Vue.set(
      state.strategyDetails,
      'strategyInfoText',
      data.strategyInfoText || {}
    );
    if (data.strategyEntityType) {
      state.strategyDetails.strategyEntityType.filters.forEach((item) => {
        if (item.name === data.scope.subFilter) {
          Vue.set(state.strategyDetails.scope, 'subFilter', item);
          Vue.set(
            state.strategyDetails.scope,
            'scopeRules',
            data.scope.scopeRules
          );
          Vue.set(
            state.strategyDetails,
            'scopeFilterValues',
            data.scope.scopeRules
          );
        }
      });
    }
    if (
      state.strategyDetails.strategyEntityType &&
      state.strategyDetails.strategyEntityType.entityType
    ) {
      const actions =
        state.startegyStaticMetric.actions[
          state.strategyDetails.strategyEntityType.entityType
        ];
      const selectedActionType =
        data.actions[0].executionTemplate &&
        data.actions[0].executionTemplate.actionType;
      Vue.set(
        state.strategyDetails,
        'executionTemplate',
        data.actions[0].executionTemplate
      );
      actions.forEach((action) => {
        if (action.actionType === selectedActionType) {
          state.strategyDetails.actions = action;
          Vue.set(state.strategyDetails, 'actions', action);
        }
      });
    }
    if (response.request.mode === 'copy') {
      Vue.set(state.strategyDetails, 'mode', 'create');
      Vue.set(
        state.strategyDetails,
        'strategyName',
        `${state.strategyDetails.strategyName} Copy`
      );
    }
  },
  RESET_STRATEGY_DETAILS: (state) => {
    Vue.set(state, 'startegyStaticMetric', {});
    const temp = {
      mode: 'create',
      strategyName: 'Untitled Strategy',
      description: '',
      objective: {},
      scope: {
        subFilter: null,
        scopeRules: null
      },
      strategyEntityType: null,
      bidMultiplierData: null,
      rules: null,
      lookBackWindow: null,
      actions: null,
      strategyStartDate: new Date(),
      strategyEndDate: null,
      strategyUIText: {},
      strategyInfoText: {},
      conditionFilterValues: {},
      scopeFilterValues: {},
      executionTemplate: {},
      lockoutValues: {},
      actionPanelValues: {},
      enableSaveBtn: false
    };
    Vue.set(state, 'strategyDetails', temp);
  }
};

const actions = {
  fetchStartegyStaticMetaData: (context) => {
    return HttpService.get('STRATEGYBUILDERMETADATA');
  },
  fetchStrategyListFilterData: async (context, data) => {
    const response = await HttpService.get('GET_STRATEGIES_WITH_DATA_WOC', {
      append: data.entityType ? '&strategyEntityType=' + data.entityType : ''
    });
    if (response.status === 200 && response.data) {
      context.commit('SET_STRATEGY_FILTERS_DATA', response.data);
    }
  },
  fetchSimulationOutput: (context, data) => {
    return HttpService.post('STRATEGY_SIMULATION_OUTPUT', data);
  },
  fetchStrategyDetails: (context, id) => {
    return HttpService.get('GET_STRATEGY_DATA', {
      append: '?strategyUniqueId=' + id
    });
  },
  fetchStrategyMetaData: ({ commit, dispatch }, data) => {
    const request = [dispatch('fetchStartegyStaticMetaData')];
    if (data && data.id) {
      request.push(dispatch('fetchStrategyDetails', data.id));
    }
    commit('HIDE_ERROR');
    commit('SHOW_LOADING');
    HttpLayer.all(request).then(
      (response) => {
        response[0].data.scope.entityList.forEach((item) => {
          item.filters.forEach((listItem) => {
            if (listItem.defaultRules && listItem.defaultRules.length > 0) {
              listItem.custom = false;
            } else {
              listItem.custom = true;
            }
          });
        });
        commit('STRATEGY_META_DATA', response[0].data);
        if (request.length === 2) {
          commit('SET_STRATEGY_DETAILS', {
            data: response[1].data,
            request: data
          });
        }
        commit('HIDE_LOADING');
      },
      (error) => {
        commit('SHOW_ERROR', error);
        commit('HIDE_LOADING');
      }
    );
  },
  setFilterPrimaryData: (context, data) => {
    context.commit('SET_PRIMARY_FILTER', data.primaryData);
    context.commit('SET_FILTER_DATA', data.filterData);
  },
  saveStrategy: (context, data) => {
    return HttpService[data.method]('CREATE_STRATEGY', data.request);
  },
  setLoadingState: (context, data) => {
    if (data) {
      context.commit('SHOW_LOADING');
    } else {
      context.commit('HIDE_LOADING');
    }
  },
  simulateStrategy: (context, data) => {
    return HttpService.post('STRATEGY_SIMULATE', data);
  },
  getSimulationStatus: (context, data) => {
    return HttpService.post('STRATEGY_SIMULATION_STATUS', data);
  },
  getSimulationOutput: (context, data) => {
    return HttpService.post('STRATEGY_SIMULATION_OUTPUT', data);
  },
  clearFilter: (context) => {
    context.commit('SET_PRIMARY_FILTER', []);
    context.commit('SET_FILTER_DATA', []);
    context.commit('RESET_STRATEGY_DETAILS');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
