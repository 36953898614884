<template>
  <div
    class="u-display-flex u-flex-direction-column u-height-100 u-width-100 cross-retailer-overview-wrapper"
  >
    <div
      class="u-display-flex u-spacing-ph-l u-flex-justify-content-space-between u-flex-align-items-center sticky u-spacing-pb-l u-header-box-shadow u-width-100 u-spacing-mb-0"
      style="z-index: 100"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
      >
        <PageTitle
          :title="selectedDashboard && selectedDashboard.name"
          :path="bookmarkPath"
        />
      </div>
      <div
        slot="download"
        expoter="display-none"
      >
        <rb-button
          v-tippy="{ placement: 'top', arrow: false }"
          :title="'Click here to export the dashboard as a PDF. The current selections and data present on the dashboard will be exported.'"
          text="Export as PDF"
          icon-left="share"
          :type="'hollow'"
          :click-fn="exportAsPdf"
          :disabled="data.load"
          class="u-spacing-ml-m hollow-outlined-btn u-bg-color-transparent-base export-pdf"
          expoter="display-none"
        />
      </div>
    </div>
    <div
      class="u-spacing-m-m u-overflow-auto u-tableau-wrapper u-envelop-width u-width-100"
    >
      <card-widget
        class="u-width-100"
        :data="data"
        :config="config"
      >
        <div slot="body">
          <div class="iframe-container u-width-100">
            <div
              id="tableauContainer"
              class="u-width-100"
            />
          </div>
        </div>
      </card-widget>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import cardWidget from '@/components/widgets/cardWidget';
import customReportsUtils from '@/components/pages/insights/customReports/utils';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import tabIdleTimeout from '@/utils/tabIdleTimeout';
import Vue from 'vue';

export default {
  name: 'TableauWrapper',
  components: {
    loader,
    cardWidget,
    rbFilterPanel
  },
  mixins: [tabIdleTimeout],
  props: {
    dashboardName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sessionIdentifier: null,
      loaderStatus: false,
      timeKeeper: {
        loadStart: null,
        interactiveStart: null
      },
      tableauInstance: null,
      enableDashVersionSwitch: false,
      newDashboardsAccessList: [],
      selectedDashboard: {},
      filters: {
        id: 'tableau-dashboard-filters',
        hasSearch: true,
        search: {
          enable: false
        }
      },
      dateObject: {},
      data: {
        load: true
      },
      config: {
        meta: {
          noAction: true
        },
        footer: {
          show: false
        },
        header: {
          show: false
        }
      }
    };
  },
  computed: {
    bookmarkPath() {
      return this.$route.fullPath
        .replace('?refresh=true&', '')
        ?.replace('?refresh=true', '');
    },
    clientDashboards() {
      return this.$store.getters.getCustomReportsClientDashboards;
    },
    dashUrl() {
      const urlDashName = this.dashboardName;
      const dashboardsList = this.clientDashboards;
      this.selectedDashboard = dashboardsList.filter((dash) => {
        return urlDashName === dash.name;
      })[0];
      return this.selectedDashboard.url;
    }
  },
  mounted() {
    this.startSession();
    this.tabIdleTimeoutHandler = this.initTableauDashboard;
    this.tabIdleTimeout = Vue.options.filters.config_check(
      'feature.tab_idle.tableau.timeout'
    );
    customReportsUtils.initTableauJS(this.initTableauDashboard);
  },
  methods: {
    async initTableauDashboard() {
      try {
        let containerDiv = document.getElementById('tableauContainer');
        containerDiv.innerHTML = '';
        const tableauDiv = document.createElement('div');
        tableauDiv.classList.add('tableauContainer');
        containerDiv.appendChild(tableauDiv);
        this.data.load = true;
        let url;
        let options = {
          hideTabs: true,
          embed: 'yes',
          showAppBanner: 'false',
          ':showShareOptions': 'false',
          display_count: 'no',
          showVizHome: 'no',
          toolbarPosition: 'TOP',
          hideToolbar: false,
          toolbar: 'yes',
          onFirstInteractive: () => {
            this.data.load = false;
            this.timeKeeper.interactiveStart = window.performance.now();
            const timeToInteract =
              this.timeKeeper.interactiveStart - this.timeKeeper.loadStart;
            this.$logger.logEvent({
              eventType: 'cross_retailer_overview_init',
              dashboard_url: `${this.dashUrl}`,
              sessionIdentifier: this.sessionIdentifier,
              step: 'onFirstInteractive',
              loadTime: timeToInteract,
              version: 2
            });
          }
        };

        url = await customReportsUtils.getTrustedForClient(this.dashUrl);
        this.tableauInstance = new window.tableau.Viz(tableauDiv, url, options);
      } catch (error) {
        this.data.load = false;
        this.$logger.logEvent({
          eventType: 'cross_retailer_overview_error',
          dashboard_url: `${this.dashUrl}`,
          sessionIdentifier: this.sessionIdentifier,
          step: 'onFirstInteractive',
          version: 2
        });
        this.$snackbar.open({
          message: error?.message || 'Something Went Wrong !!',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    },
    getClientNameClientDbMapping(clientName) {
      // remove all hypen from the client name
      const clientDbName = clientName.replaceAll('-', '');
      return clientDbName;
    },
    startSession() {
      this.sessionIdentifier = new Date().getTime();
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'cross_retailer_overview_init',
        dashboard_url: `${this.dashUrl}`,
        sessionIdentifier: this.sessionIdentifier,
        step: 'mount',
        version: 2
      });
    },
    exportAsPdf() {
      this.tableauInstance.showExportPDFDialog();
    }
  }
};
</script>
