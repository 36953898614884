<template>
  <div class="u-font-size-5 charting_wb_wrapper">
    <widgetContainer
      :header-options="uiHeaderOptions"
      :footer-options="footerOptionsComp"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isChartLoading"
      :header-container-grid-styles="headerContainerGridStyles"
      :last-updated-date="lastUpdatedDate"
      :enable-footer="enableFooter"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <chart-with-legends
          :key="myKey"
          :chart-config="chartConfig"
          :chart-data="chartData"
          :metrics-list="metricList"
          :metrics-shown="shownMetricList"
          :default-select-metric="defaultSelectMetric"
          :metric-config="metricDisplayConfig"
          :metric-data="metricData"
          :disable-add-new-metric="disableAddNewMetricButtonComputed"
          :is-chart-loading="isChartLoading"
          :show-powered-by-c-i-q-text="true"
          :remove-dropdown="removeDropdownComputed"
          :selected-metric-limit="selectedMetricLimitChart"
          :metric-font-size="'u-font-size-4'"
          :show-chart="showChart"
          :enable-watefall="enableWaterfall"
          :show-legends-as-chips="showLegendsAsChips"
          :minimum-metric="minimumMetric"
          :plot-on-y-axis-only="plotOnYAxisOnly"
          :enable-legend-search="enableLegendSearch"
          :color-object="colorObject"
          :modify-tooltip-message="modifyTooltipMessage"
          :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
          @selectedMetricList="handleSelectedMetricList"
        />
      </template>
    </widgetContainer>
  </div>
</template>

<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import {
  cloneDeep,
  concat,
  flatten,
  groupBy,
  assign,
  isEmpty,
  isEqual
} from 'lodash';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import Vue from 'vue';
import moment from 'moment-timezone';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import ToggleChartVisibility from '@/components/widgets/custom_widgets/custom_widget_components/charts/ToggleChartVisibility.vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

const toggleChart = {
  components: { iconTextActions },
  props: {
    showChart: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('toggle', !this.showChart);
    }
  },
  template: `
  <span>
    <icon-text-actions
      v-if="!showChart"
      mode="default"
      :action="{ title: 'SHOW CHART', icon: 'show-chart' }"
      :apply-padding="false"
      @clicked="handleClick"
    />
    <icon-text-actions
      v-if="showChart"
      mode="default"
      :action="{ title: 'HIDE CHART', icon: 'visibility-off' }"
      :apply-padding="false"
      @clicked="handleClick"
    />
  </span>
  `
};
export default {
  name: 'ChartingWb',
  components: {
    chartWithLegends,
    widgetContainer,
    ToggleChartVisibility
  },
  mixins: [cwMixin],
  props: {
    enableRollup: {
      type: Boolean,
      default: false
    },
    selectedMetricLimit: {
      type: Number,
      default: 1
    },
    removeDropdown: {
      type: Boolean,
      default: false
    },
    disableAddNewMetricButton: {
      type: Boolean,
      default: true
    },
    enableToggle: {
      type: Boolean,
      default: false
    },
    enableWaterfall: {
      type: Boolean,
      default: false
    },
    showLegendsAsChips: {
      type: Boolean,
      default: false
    },
    showChart: {
      type: Boolean,
      default: true
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    enableFooter: {
      type: Boolean,
      default: true
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    chartConfig: {
      type: Object,
      default: function () {
        return {
          chartOptions: {
            legend: false,
            xFormat: '%m/%d/%Y',
            timeseries: 'feed_date',
            tooltip_format: {},
            axes: {},
            grid: 'xy',
            hasEvents: false,
            type: 'line',
            types: {},
            xAxisType: 'category',
            stack: [],
            chartGetter: '',
            show_axis_colors: true,
            events: [],
            dynamicRange: true,
            size: { height: 380 },
            axis_format: {}
          },
          stack: [],
          chartGetter: '',
          xAxisType: 'category',
          lastUpdatedDate: false
        };
      }
    },
    iconShow: {
      type: String,
      default: 'show-chart'
    },
    iconHide: {
      type: String,
      default: 'collapse'
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: false
    },
    enableLegendSearch: {
      type: Boolean,
      default: true
    },
    colorObject: {
      type: Object,
      default: null
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myKey: 1,
      dataGroups: {},
      metricData: {},
      metricsArray: [],
      metricDisplayConfig: {},
      metricEventConfig: this.metadata,
      selectedMetrics: [],
      metricList: [],
      shownMetricList: [],
      defaultSelectMetric: [],
      metricsDataResponse: [],
      selectedMetricLimitChart: this.selectedMetricLimit,
      isChartLoading: false,
      chartData: {
        data: []
      },
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      filtersConfig: [],
      timeseriesX: 'feed_date'
    };
  },
  computed: {
    computedFilterConfig() {
      const filterConfig = JSON.parse(JSON.stringify(this.filtersConfig));
      return filterConfig;
    },
    removeDropdownComputed() {
      return (
        this.removeDropdown ||
        this.selectedMetricLimitChart === this.metricsArray?.length
      );
    },
    disableAddNewMetricButtonComputed() {
      return (
        this.disableAddNewMetricButton ||
        this.selectedMetricLimitChart === this.metricsArray?.length
      );
    },
    uiHeaderOptions() {
      const tempHeaderOptions = cloneDeep(this.headerOptionsComp) || [];
      if (this.enableRollup) {
        const rollUpValue = this.metadata.metadata.defaultConfig.selectedRollUp;
        tempHeaderOptions.splice(1, 0, {
          ui_component: 'RollUpBy',
          props: {
            label: 'Roll Up By:',
            value: rollUpValue,
            dateObj: this.getGlobalRequestParams(),
            useComponentOverSlots: true
          },
          events: {
            onRollUpChange: this.rollUpChanged
          }
        });
      }
      // Need to remove static toggleChart component
      if (this.enableToggle) {
        // show hide chart toggle
        tempHeaderOptions.push({
          ui_component: 'toggleChart',
          props: {
            showChart: this.showChart
          },
          events: {
            toggle: this.toggleChart
          }
        });
      }

      return tempHeaderOptions;
    }
  },
  watch: {
    metadata(newVal) {},
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    },
    metricsDataResponse(newVal) {
      this.isChartLoading = true;
      try {
        // Construct metric data
        const metricDataRes = newVal;
        metricDataRes.forEach((metricGroup) => {
          metricGroup.forEach((metric) => {
            const obj = {};
            if (metric.RESULT) {
              // metric RESULT is the tag1 or the primary value, while the PVP one is for tag2
              obj.tag1 = metric.RESULT[metric.name];
              obj.tag2 = metric?.PVP && metric?.PVP?.['PVP_' + metric.name]; // adding null check for pvp as it may not be required in every case PROD-118353

              // If metric meta unit is percentage then we need the PVP_DIFF
              // TODO: Clarify
              if (
                this.metadata.metrics[metric.name].metadata.unit ===
                'PERCENTAGE'
              ) {
                obj.tag2 =
                  metric?.PVP && metric?.PVP?.['PVP_DIFF_' + metric.name];
              }
              // Assign the created object to the metric data
              this.metricData[this.metadata.metrics[metric.name]?.label] = obj;
            }
          });
        });

        // hacky way to force vue re render
        this.myKey = Math.random();

        // metricsDataResponse gets called for each dataGroup and response will be array with elements for each dataGroup
        // we flatten this array and extract out all timeseries data from it and group it at a feeddate level
        let allMetricsTimeSeriesData = concat(
          flatten(
            (concat(flatten(newVal)) || []).map((item) => item?.TIMESERIES)
          )
        );

        // no data returned - all values will be null
        if (allMetricsTimeSeriesData && !allMetricsTimeSeriesData[0]) {
          Vue.set(this.chartData, 'data', []);
          this.isChartLoading = false;
          return;
        }

        allMetricsTimeSeriesData = groupBy(
          allMetricsTimeSeriesData,
          (obj) => obj[this.timeseriesX]
        );
        // sort by anchor date so that tooltip and chart data is aligned. Need to make it comparible for non date type anchors.

        const allAnchorValues = Object.keys(allMetricsTimeSeriesData).sort(
          (anchorDate1, anchromeDate2) => {
            if (new Date(anchorDate1) < new Date(anchromeDate2)) {
              return -1;
            } else {
              return 1;
            }
          }
        );

        const metricSeriesMap = {};
        for (const anchorValue of allAnchorValues) {
          const allMetricMerged = assign(
            ...allMetricsTimeSeriesData[anchorValue]
          );
          Object.keys(allMetricMerged).forEach((metricKey) => {
            if (!metricSeriesMap[metricKey]) {
              metricSeriesMap[metricKey] = [];
            }
            metricSeriesMap[metricKey].push(allMetricMerged[metricKey]);
          });
        }
        this.chartData.data = [];
        const metricsSeriesList = [];
        // transform the feeddate grouped metrics list into structure accepted by charting WB.
        const meta = this.metadata.metrics;
        Object.keys(metricSeriesMap).forEach((metricKey) => {
          let chartKey = meta[metricKey]?.label;
          if (!meta[metricKey]?.label) {
            chartKey = metricKey;
          }
          const metricValues = metricSeriesMap[metricKey];
          metricValues.unshift(chartKey);
          metricsSeriesList.push(metricValues);
        });
        Vue.set(this.chartData, 'data', metricsSeriesList);
        this.isChartLoading = false;
      } catch (error) {
        console.error({ error });
        // if chart data not available
        this.isChartLoading = false;
        Vue.set(this.chartData, 'data', []);
      }
    }
  },
  created() {
    Vue.component('toggleChart', toggleChart);
    Vue.component('ToggleChartVisibility', ToggleChartVisibility);
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    this.modifyChartingConfig();
    this.fetchData();
  },
  methods: {
    modifyChartingConfig() {
      if (this.widgetRequestParams?.timeseries) {
        this.timeseriesX = this.widgetRequestParams.timeseries;
        this.chartConfig.chartOptions.timeseries =
          this.widgetRequestParams.timeseries;
      }
    },
    addMetric(data) {
      const elementFound = this.shownMetricList.find(
        (elm) => elm.key === data.key
      );
      if (elementFound === undefined && !this.disableAddNewMetricButton) {
        this.selectedMetricLimitChart++;
      }
    },
    fetchData() {
      this.isChartLoading = true;
      const metadata = this.metadata.metadata;
      const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);

      // metrics from the response
      this.metricsArray = Object.values(this.metadata.metrics).map((item) => {
        item.key = item.label;
        item.title = item.label;
        return item;
      });

      this.metricsArray = this.metricsArray.filter(
        (item) => !metadata?.defaultHiddenMetrics?.includes(item.name)
      );

      // to prevent overwriting tiles selected by user on rollup change
      if (!this.selectedMetrics.length) {
        // Metrics to pass to chart-with-legends
        this.selectedMetrics = metadata?.defaultConfig?.selectedMetric;
        // Ensure only visible metrics are selectable
        this.metricList = this.metricsArray.filter(
          (elm) => !elm?.metadata?.hide
        );
        // Metrics to pick from metadata
        const shownMetricListFromMetadata =
          metadata?.defaultConfig?.shownMetricList;
        if (shownMetricListFromMetadata?.length) {
          const metricsArray = [...this.metricsArray];
          // for sorting based on shownMetricListFromMetadata
          this.shownMetricList = shownMetricListFromMetadata.map((el) => ({
            ...metricsArray.find((metric) => metric.name === el)
          }));
        } else {
          this.shownMetricList = cloneDeep(
            this.metricsArray.slice(0, this.selectedMetricLimit)
          );
        }
      }
      // All the metrics should be rendered by default
      this.defaultSelectMetric = this.metricsArray.filter((el) =>
        this.selectedMetrics?.includes(el.name)
      );
      this.metricDisplayConfig = this.generateMetricDisplayConfig(
        this.metricsArray
      );

      let request, api;
      const dataGroupsList = Object.keys(dataGroups);
      for (let i = 0; i < dataGroupsList?.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );
        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;

        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        if (!this.widgetRequestParams?.keepExistingDimensionNameValueList) {
          request.where.dimensionNameValueList =
            this.widgetRequestParams[':dimensionNameValueList'];
        } else if (
          !isEmpty(
            this.widgetRequestParams.replaceEntityInDimensionNameValueList
          )
        ) {
          for (const item of request.where.dimensionNameValueList) {
            item.dimensionValue =
              this.widgetRequestParams?.replaceEntityInDimensionNameValueList[
                item.dimensionValue
              ] || item.dimensionValue;
          }
        }

        request = this.tempTransformer(request, metricsListName);
        dashUtils.fetchDataAPI(request, api).then((args) => {
          Vue.set(this.metricsDataResponse, i, args.entityData?.[0]?.data);
        });
      }
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    generateMetricDisplayConfig(metricData) {
      const metricConfig = {};
      // Construct the required configs
      metricData.forEach((metric) => {
        const metricMeta = metric.metadata;

        // Percentage, currency and metric suffix/prefix in chart tooltip
        if (metricMeta.unit === 'PERCENTAGE') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            suff: '%'
          };
        } else if (metricMeta.unit === 'CURRENCY') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            pre: 'currency'
          };
        } else if (metricMeta.unit === 'NUMBER') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            roundoff: 0
          };
        }

        metricConfig[metric.label] = {
          invertTag2: metricMeta.isInverted,
          tag1Unit: {
            pre: metricMeta.unit === 'CURRENCY' ? 'currency' : '',
            suff:
              metricMeta.unit in this.suffMap
                ? this.suffMap[metricMeta.unit]
                : '',
            roundoff:
              metricMeta.type === 'NUMBER' && metricMeta.unit === ''
                ? 0
                : undefined
          },
          tag2Unit: {
            suff: '%'
          }
        };
      });
      return metricConfig;
    },
    rollUpChanged(data) {
      const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    },
    toggleChart(val) {
      this.showChart = val;
    },
    // event listener which updated tiles selected by user
    handleSelectedMetricList(val) {
      this.selectedMetrics = [
        ...(val?.selectedMetric?.map(({ name }) => name) || [])
      ];
      this.shownMetricList = [...(val?.metricDisplayList || [])];
    }
  }
};
</script>

<style lang="css">
.charting_wb_wrapper .metricListClass {
  margin-bottom: 0;
}

.charting_wb_wrapper .chart_space_class {
  margin-top: 0;
}

.charting_wb_wrapper .header-container-cwc {
  padding-right: 24px;
}
</style>
