<template>
  <div
    class="u-display-flex u-border-all u-border-width-s u-border-color-grey-xxx-light u-border-radius-m u-spacing-mv-s u-spacing-p-l u-flex-justify-content-center u-flex-align-items-center"
  >
    <img
      :height="imageHeight"
      :src="imageSrc"
      alt=""
    />
    <div
      class="u-display-flex u-flex-direction-column u-flex-justify-content-center u-flex-align-items-center"
    >
      <span
        class="u-font-size-4 u-font-weight-600 u-color-grey-light u-spacing-mt-l u-spacing-mb-m"
      >
        {{ title }}
      </span>
      <span class="u-font-size-4 u-color-grey-light">
        {{ description }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    imageHeight: {
      type: String,
      default: '110px'
    },
    imageSrc: {
      type: String,
      default: '/images/ChargebacksComingSoon.svg'
    }
  }
};
</script>
