<template>
  <div
    v-if="showWidget"
    class="u-box-shadow-xx-light u-bg-color-grey-white listings-health-chart"
  >
    <div class="u-spacing-ph-m u-spacing-pb-l u-spacing-pt-m">
      <div class="u-display-flex u-flex-justify-content-space-between">
        <div
          class="u-font-size-4 u-color-grey-lighter u-font-weight-600 u-display-flex u-flex-align-items-center"
        >
          More about
          <span class="u-color-grey-base u-spacing-pl-xs">{{
            expandedCardTitle
          }}</span>
          <icon-text
            icon="bot"
            icon-classes="rb-icon--small u-color-grey-light u-spacing-ml-s"
            text="Automated by CIQ"
            text-classes="badge-text u-font-size-7 u-color-grey-light u-font-weight-600 u-spacing-mt-xxs u-spacing-mr-s"
            container-class="u-border-radius-l automation-badge badge-automated u-spacing-ml-m"
          />
        </div>
        <span @click="closeCard">
          <rb-icon
            icon="cross"
            class="rb-icon u-color-grey-x-light rb-icon--x-medium u-cursor-pointer"
          />
        </span>
      </div>
    </div>
    <div
      class="u-font-size-4 u-color-grey-lighter u-font-weight-600 u-spacing-ph-m u-spacing-mb-m"
    >
      {{ title }}
    </div>
    <chart-with-legends
      class="u-spacing-ph-m"
      :metrics-list="metricsList"
      :color-object="metricsColorCode"
      :chart-data="dataForChart"
      :chart-config="chartConfig"
      :disable-add-new-metric="true"
      :remove-dropdown="false"
      :plot-on-y-axis-only="true"
      :ensure-metric-order-by-priority="true"
      :use-custom-chart-metric-names="true"
      metrics-max-width="auto"
      :metric-config="selectedValues.metricDisplayConfig"
      :metrics-shown="selectedValues.metricsShown"
      :metric-data="selectedValues.metricData"
      :selected-metric-limit="selectedValues.metricsShown.length"
      :minimum-metric="selectedValues.metricsShown.length"
      :default-select-metric="selectedValues.metricsShown"
      :show-metric-val-in-multiple-line="true"
      :show-padding-between="false"
      metric-size="s"
      :margin-top-large="false"
      :margin-top-small="true"
      metric-top-padding-type="s"
      metric-bottom-padding-type="s"
      :is-chart-loading="isLoading"
      :show-custom-tooltip="true"
      metric-tooltip-theme="light"
      metric-font-size="u-font-size-5"
      :metric-tooltip-one-liner="false"
      :hide-zero="false"
      :show-powered-by-c-i-q-text="true"
    >
      <div
        slot="space-between-legend-and-chart"
        class="u-display-flex u-spacing-mt-m"
      >
        <RollUpBy
          class="roll-up-by"
          label="Roll Up By:"
          :value="selectedRollUp"
          :date-obj="getGlobalRequestParams()"
          @onRollUpChange="rollUpChanged"
        />
      </div>
    </chart-with-legends>
  </div>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends-v2.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin';
import iconText from '@/components/basic/iconText.vue';
import { eventBus } from '@/utils/services/eventBus';
import { cloneDeep, isEqual } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'ListingsHealthChart',
  components: {
    chartWithLegends,
    iconText
  },
  mixins: [cwMixin],
  props: {
    showWidget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: 'Benchmarking Metrics',
      isLoading: true,
      selectedRetailer: 'retail_and_3p',
      selectedAutomationType: 'unavailable',
      metricsList: [],
      metricsColorCode: {},
      selectedValues: {
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: [],
        metricData: {}
      },
      overviewData: {},
      metricConfig: {},
      chartData: {
        data: []
      },
      chartConfig: {
        chartOptions: {
          legend: false,
          xFormat: '%m/%d/%Y',
          timeseries: 'feed_date',
          tooltip_format: {},
          axes: {},
          grid: 'xy',
          hasEvents: false,
          type: 'line',
          types: {},
          xAxisType: 'category',
          stack: [],
          chartGetter: '',
          show_axis_colors: true,
          events: [],
          dynamicRange: true,
          size: { height: 300 },
          axis_format: {}
        },
        stack: [],
        chartGetter: '',
        xAxisType: 'category',
        lastUpdatedDate: false
      },
      apiResponse: {}
    };
  },
  computed: {
    dataForChart() {
      return this.chartData;
    },
    expandedCardTitle() {
      switch (this.selectedAutomationType) {
        case 'unavailable': {
          return 'Unavailable SKUs';
        }
        case '3pVariant': {
          return '3P Variants';
        }
        case 'contentAuthority': {
          return 'Content Authority';
        }
        case 'variationAuthority': {
          return 'Variation Authority';
        }
      }
    }
  },
  watch: {
    widgetRequestParams: {
      handler(nv, ov) {
        if (!isEqual(nv, ov)) {
          this.fetchData();
        }
      },
      deep: true
    }
  },
  created() {
    this.selectedRollUp =
      this.metadata.metadata.selectedRollUp || this.selectedRollUp;
    const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
    chartingReqParam[':timeseriesRollupBy'] = this.selectedRollUp;
    eventBus.$on('selectedAutomationCardUpdated', (data) => {
      this.selectedAutomationType = data;
      this.fetchData();
    });
    eventBus.$on('selectedRetailerUpdated', (data) => {
      this.selectedRetailer = data.value;
      this.fetchData();
    });
    this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    this.generateMetricConfig();
  },
  beforeDestroy() {
    eventBus.$off('selectedAutomationCardUpdated');
    eventBus.$off('selectedRetailerUpdated');
  },
  mounted() {
    this.fetchData();
  },
  inject: {
    setExpandedWidgetVisibility: {
      type: Function,
      default: function () {}
    }
  },
  methods: {
    closeCard() {
      eventBus.$emit('closeAutomationTypeCard');
    },
    rollUpChanged(data) {
      this.selectedRollUp = data.key;
      const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    },
    generateMetricConfig() {
      const metricConfig = {};
      const chartMetrics = this.metadata.metadata.selectedMetric || [];
      const metricData = Object.values(this.metadata.metrics);
      const metricNameVsLabel = metricData.reduce((acc, metric) => {
        acc[metric.keyName] = metric.label;
        return acc;
      }, {});
      this.chartConfig.chartOptions.names = metricNameVsLabel;
      metricData.forEach((metric) => {
        this.metricsList.push(metric);
        const tagConfig = metric.metadata.tagConfig;
        metricConfig[metric.keyName] = { label: metric.label, ...tagConfig };
        this.selectedValues.metricDisplayConfig[metric.label] = tagConfig;
        this.selectedValues.selectedMetric.push(metric);
        metric.title = metric.label;
        metric.key = metric.label;
        if (chartMetrics.includes(metric.name)) {
          this.selectedValues.metricsShown.push(metric);
        }
      });
      this.metricConfig = metricConfig;
    },
    async fetchData() {
      this.isLoading = true;
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response = [];
      try {
        for (const groupName of Object.keys(metricsGrouping)) {
          const api = metricsGrouping[groupName].api;
          const request = dashUtils.replacePlaceHolderWithData(
            api.request,
            this.widgetRequestParams
          );
          request.operations.timeseriesRollupBy = this.selectedRollUp;

          request.where.dimensionNameValueList = this.widgetRequestParams[
            ':dimensionNameValueList'
          ].filter(
            (filterConfig) =>
              filterConfig.dimensionName !== 'filter_issue_type' &&
              filterConfig.dimensionName !== 'business_model'
          );
          const retailerFilterConfig = {
            dimensionName: 'filter_issue_type',
            dimensionValue:
              this.metadata.metadata.filterAutomationMapping[
                this.selectedAutomationType
              ],
            operator: 'ILIKE'
          };
          request.where.dimensionNameValueList.push(retailerFilterConfig);
          if (this.selectedRetailer !== 'retail_and_3p') {
            request.where.dimensionNameValueList.push({
              dimensionName: 'business_model',
              dimensionValue: this.selectedRetailer,
              operator: 'ILIKE'
            });
          }
          request.enablePaginationCount = true;
          try {
            const dataAPIResponse = await dashUtils.fetchDataAPI(request, api);
            if (dataAPIResponse?.entityData[0]?.data) {
              response.push(...dataAPIResponse.entityData[0].data);
            }
          } catch (error) {
            console.error('An Error Occurred', error.message);
          }
        }
        response.forEach((e) => {
          this.overviewData[e.name] = {
            tag1: e.RESULT[e.name]
          };
        });
        this.apiResponse = response;
        this.generateFormattedData(response);
      } catch (error) {
        console.error('Error occurred while fetching benchmarking data', error);
      } finally {
        this.isLoading = false;
      }
    },
    generateFormattedData(data) {
      const formattedChartData = [];
      let isTimeSeriesInitialised = false;
      const metricDict = {};
      this.selectedValues.selectedMetric.forEach((metric) => {
        metricDict[metric.alias] = metric;
      });
      data = data.filter((val) => !!metricDict[val.alias]);
      data.forEach((currentMetric) => {
        const metricAlias = currentMetric.alias;
        const metricLabel = metricDict[metricAlias].label;
        if (!isTimeSeriesInitialised) {
          this.initTimeSeriesAxis(currentMetric, formattedChartData);
          isTimeSeriesInitialised = true;
        }
        this.formatAndAddMetricData(
          metricAlias,
          metricLabel,
          currentMetric,
          formattedChartData
        );
        this.selectedValues.metricData[metricLabel] = {
          tag1: currentMetric.RESULT[metricAlias]
        };
      });
      this.chartData.data = formattedChartData;
    },
    formatAndAddMetricData(
      metricAlias,
      metricLabel,
      currentMetric,
      formattedChartData
    ) {
      let doesTimeSeriesDataExists = currentMetric.TIMESERIES?.length > 0;
      const metricData = doesTimeSeriesDataExists
        ? [
            metricLabel,
            ...currentMetric.TIMESERIES.map(
              (currentMetricData) => currentMetricData[metricAlias]
            )
          ]
        : [];
      formattedChartData.push(metricData);
    },
    initTimeSeriesAxis(currentMetric, formattedChartData) {
      const timeseriesKey =
        this.widgetRequestParams[':operations'].timeseriesDimension;
      let timeSeriesDataExists = currentMetric.TIMESERIES?.length > 0;
      if (timeSeriesDataExists) {
        this.chartConfig.xAxisCategories = [
          ...currentMetric.TIMESERIES.map(
            (currentMetricData) => currentMetricData[timeseriesKey]
          )
        ];
        formattedChartData.push([
          timeseriesKey,
          ...this.chartConfig.xAxisCategories
        ]);
      }
    }
  }
};
</script>

<style lang="css">
.listings-health-chart .custom-chart-legend .override-padding {
  padding: 0.8rem;
  margin: 0;
}
.listings-health-chart .automation-badge div {
  display: flex;
  align-items: center;
}
.listings-health-chart .automation-badge .badge-text {
  padding: 0 0 2px 4px;
}
.listings-health-chart .badge-automated {
  background: #007cf61a;
}
.listings-health-chart .badge-not-automated {
  background: #e9eaeb;
}
</style>
