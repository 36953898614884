import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import moment from 'moment';

const state = {
  templateList: {},
  templateData: {},
  templateUpdate: {},
  editeModeIds: {},
  selectedTemplate: {},
  ticketMessages: []
};

const getters = {
  getInternalToolTemplateList: (state) => {
    return state.templateList;
  },
  getInternalToolTemplateData: (state) => {
    return state.templateData;
  },
  getInternalToolUpdateTemplateData: (state) => {
    return state.templateUpdate;
  },
  getEditModeIds: (state) => {
    return state.editeModeIds;
  },
  getSelectedTemplate: (state) => {
    return state.selectedTemplate;
  },
  getTicketMessages: (state) => {
    return state.ticketMessages;
  }
};

const mutations = {
  INTERNAL_TOOLS_TEMPLATE_LIST_SUCCESS: (state, data) => {
    Vue.set(state.templateList, 'rows', data.response.rows);
    Vue.set(state.templateList, 'load', false);
    Vue.set(state.templateList, 'error', false);
    if (data.response.rows.length === 0) {
      Vue.set(state.templateList, 'noData', true);
    } else {
      Vue.set(state.templateList, 'noData', false);
    }
  },
  INTERNAL_TOOLS_TEMPLATE_LIST_RESET: (state) => {
    Vue.set(state.templateList, 'rows', []);
    Vue.set(state.templateList, 'load', true);
    Vue.set(state.templateList, 'error', false);
    Vue.set(state.templateList, 'noData', false);
  },
  INTERNAL_TOOLS_TEMPLATE_LIST_ERROR: (state) => {
    Vue.set(state.templateList, 'rows', []);
    Vue.set(state.templateList, 'load', false);
    Vue.set(state.templateList, 'error', true);
    Vue.set(state.templateList, 'noData', false);
  },
  INTERNAL_TOOLS_TEMPLATE_DATA_SUCCESS: (state, data) => {
    Vue.set(state.templateData, 'rows', data.response.rows);
    Vue.set(state.templateData, 'totalRows', data.response.dataSize);
    Vue.set(state.templateData, 'load', false);
    Vue.set(state.templateData, 'error', false);
    if (data.response.rows.length === 0) {
      Vue.set(state.templateData, 'noData', true);
    } else {
      Vue.set(state.templateData, 'noData', false);
    }
  },
  INTERNAL_TOOLS_TEMPLATE_TICKET_MESSAGES: (state, data) => {
    Vue.set(state, 'ticketMessages', data);
  },
  INTERNAL_TOOLS_TEMPLATE_DATA_RESET: (state) => {
    Vue.set(state.templateData, 'rows', []);
    Vue.set(state.templateData, 'load', true);
    Vue.set(state.templateData, 'error', false);
    Vue.set(state.templateData, 'noData', false);
  },
  INTERNAL_TOOLS_TEMPLATE_DATA_ERROR: (state) => {
    Vue.set(state.templateData, 'rows', []);
    Vue.set(state.templateData, 'load', false);
    Vue.set(state.templateData, 'error', true);
    Vue.set(state.templateData, 'noData', false);
  },
  INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_SUCCESS: (state, data) => {
    Vue.set(state.templateUpdate, 'rows', ((data || {}).response || {}).rows);
    Vue.set(state.templateUpdate, 'load', false);
    Vue.set(state.templateUpdate, 'error', false);
    Vue.set(state.templateUpdate, 'noData', false);
  },
  INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_RESET: (state) => {
    Vue.set(state.templateUpdate, 'rows', []);
    Vue.set(state.templateUpdate, 'load', true);
    Vue.set(state.templateUpdate, 'error', false);
    Vue.set(state.templateUpdate, 'noData', false);
  },
  INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_ERROR: (state) => {
    Vue.set(state.templateUpdate, 'rows', []);
    Vue.set(state.templateUpdate, 'load', false);
    Vue.set(state.templateUpdate, 'error', true);
    Vue.set(state.templateUpdate, 'noData', false);
  },
  INTERNAL_TOOLS_EDIT_MODE_ROW_IDS: (state, ids) => {
    Vue.set(state.editeModeIds, 'ids', ids);
  },
  INTERNAL_TOOLS_TEMPLATE_LOADING: (state, load) => {
    Vue.set(state.templateData, 'load', load);
  },
  INTERNAL_TOOLS_SET_SELECTED_TEMPLATE: (state, data) => {
    Vue.set(state.selectedTemplate, 'data', data);
  }
};

const actions = {
  fetchInternalToolsTemplates: async (context) => {
    context.commit('INTERNAL_TOOLS_TEMPLATE_LIST_RESET');
    try {
      // http serveice CIQ_INTERNAL_TOOL_FETCH_TEMPLATE
      const response = await HttpService.post(
        'CIQ_INTERNAL_TOOL_FETCH_TEMPLATE',
        {}
      );
      context.commit('INTERNAL_TOOLS_TEMPLATE_LIST_SUCCESS', response.data);
    } catch (err) {
      context.commit('INTERNAL_TOOLS_TEMPLATE_LIST_ERROR', err);
    }
  },
  fetchInternalToolsData: async (context, data) => {
    context.dispatch('setEditModeIds', []);
    context.commit('INTERNAL_TOOLS_TEMPLATE_DATA_RESET');
    try {
      if (!data.body.APIConfig.where.dimensionNameValueList) {
        data.body.APIConfig.where.dimensionNameValueList = [];
      }
      const index = data.body.APIConfig.where.dimensionNameValueList.findIndex(
        (item) => {
          return item.dimensionName === 'ISDELETED';
        }
      );
      if (data.body.APIConfig.where && data.body.APIConfig.where.date) {
        data.body.APIConfig.where.date.from = moment(
          data.body.APIConfig.where.date.from,
          'YYYY-MM-DD'
        )
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        data.body.APIConfig.where.date.to = moment(
          data.body.APIConfig.where.date.to,
          'YYYY-MM-DD'
        )
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (index === -1 && !data.templateConfig.DISABLE_IS_DELETED) {
        data.body.APIConfig.where.dimensionNameValueList.push({
          dimensionName: 'ISDELETED',
          dimensionValue: true,
          operator: 'NOT_EQUAL_TO'
        });
      }
      const response = await HttpService.post(
        'CIQ_INTERNAL_TOOL_FETCH_TEMPLATE_DATA',
        data.body.APIConfig
      );
      const rows = response.data.response.rows;

      const columnDefs = data.columnDefs;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        for (const key in row) {
          const index = columnDefs.findIndex((item) => {
            return item.field === key;
          });
          if (index > -1) {
            if (columnDefs[index].dataType === 'DATETIME_PST') {
              if (row[key]) {
                row[key] = moment
                  .tz(row[key], 'UTC')
                  .tz(context.getters.getClientBrowserTimezone?.value)
                  .format('YYYY-MM-DD hh:mm:ss A');
              }
            }
          }
        }
      }
      context.commit('INTERNAL_TOOLS_TEMPLATE_DATA_SUCCESS', response.data);

      const ticketsArray = rows?.reduce((acc, el) => {
        return el.TICKET_NUMBER ? [...acc, el.TICKET_NUMBER] : acc;
      }, []);
      if (ticketsArray.length) {
        context.dispatch('fetchInternalToolsTicketMessages', ticketsArray);
      }
    } catch (err) {
      context.commit('INTERNAL_TOOLS_TEMPLATE_DATA_ERROR', err);
    }
  },
  fetchInternalToolsTicketMessages: async (context, data) => {
    try {
      const ticketMessagesResponse = await HttpService.post(
        'ACTION_SCHDELUER_TICKET_MESSAGE',
        data
      );
      context.commit(
        'INTERNAL_TOOLS_TEMPLATE_TICKET_MESSAGES',
        ticketMessagesResponse.data
      );
    } catch (err) {
      context.commit('INTERNAL_TOOLS_TEMPLATE_TICKET_MESSAGES', err);
    }
  },
  updateInternalToolsData: async (context, data) => {
    context.commit('INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_RESET');
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await HttpService.post('CIQ_INTERNAL_TOOL_UPDATE_TEMPLATE_DATA', data.body, 'updateInternalToolsData');
        const response = await HttpService.post(
          'CIQ_INTERNAL_TOOL_UPDATE_TEMPLATE_DATA',
          data.body
        );
        context.commit(
          'INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_SUCCESS',
          (response || {}).data
        );
        resolve(response);
      } catch (err) {
        context.commit('INTERNAL_TOOLS_UPDATE_TEMPLATE_DATA_ERROR', err);
        reject(err);
      }
    });
  },
  insertInternalToolsData: (context, data) => {
    return HttpService.post(
      'CIQ_INTERNAL_TOOL_INSERT_TEMPLATE_DATA',
      data.body
    );
  },
  downloadDvtTemplateData: (context, data) => {
    console.log(data);
    const body = { ...data };
    body.limit = 100000;
    return new Promise((resolve, reject) => {
      HttpService.post('CIQ_INTERNAL_TOOL_FETCH_TEMPLATE_DATA', body)
        .then((response) => {
          const data = response.data;
          const rows = ((data || {}).response || {}).rows;
          for (let i = 0; i < rows.length; i++) {
            const obj = rows[i];
            const selectedTemplate = context.getters.getSelectedTemplate;
            const columns = selectedTemplate.data.TEMPLATECONIFG.columns;
            for (const key in obj) {
              const index = columns.findIndex((item) => {
                return item.key === key;
              });
              if (index !== -1) {
                const col = columns[index];
                if (col.type === 'DATETIME_PST') {
                  if (obj[key]) {
                    obj[key] = moment
                      .tz(obj[key], 'UTC')
                      .tz(context.getters.getClientBrowserTimezone?.value)
                      .format('YYYY-MM-DD hh:mm:ss A');
                  }
                }
              }
            }
          }
          resolve(rows);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setTemplateLoading: (context, load) => {
    context.commit('INTERNAL_TOOLS_TEMPLATE_LOADING', load);
  },
  setEditModeIds: (context, ids) => {
    context.commit('INTERNAL_TOOLS_EDIT_MODE_ROW_IDS', ids);
  },
  setSelectedTemplate: (context, data) => {
    context.commit('INTERNAL_TOOLS_SET_SELECTED_TEMPLATE', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
