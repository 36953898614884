// -- importing componenets here --
import {
  addEfficientSearchTermsAsKeywordsRecommendationComponentConfig,
  addImportantKeywordsForBrandRecommendationComponentConfig,
  alignCampaignToBestPracticesRecommendationComponentConfig
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';
import {
  addTargetsToCampaignActionPanel,
  addCategoriesToCampaignActionPanel,
  sbAddNegativeKeywordsEnterListComponentConfig,
  addNegativeTargetsEnterListComponentConfig
} from '@/pages/entity_details/configs/amazon/actionConfigs.js';
import {
  adGroupSettingsConfig,
  adgroupDefaultBidConfig,
  adgroupADsConfig
} from '@/pages/entity_details/configs/amazon/sp.js';
import {
  getMetaDataConfig,
  getHeaderConfigs,
  getChartConfigs,
  getAddSearchTermsAsKeywordPayloadTemplate,
  getAddSearchTermsAsNegativeKeywordPayloadTemplate
} from '@/pages/entity_details/configs/amazon/configUtils.js';
const budget = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');
const settings = () =>
  import('@/pages/entity_details/widget_templates/settings/index.vue');
const keywords = () =>
  import('@/pages/entity_details/widget_templates/SB/keywords.vue');
const searchterms = () =>
  import('@/pages/entity_details/widget_templates/SB/searchterms.vue');
const sbNegativeTargeting = () =>
  import('@/pages/entity_details/widget_templates/SB/sbNegativeTargeting.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );

const adGroupTargetingBaseConfig = {
  component: keywords,
  apiConfigs: {
    fetchTargetPages: 'entityDetails/fetchTargetingPageData',
    getTargetingPageData: 'entityDetails/getTargetingPageDetails',
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAdGroupDetails: 'entityDetails/fetchAdgroupDetails',
    getAdGroupDetails: 'entityDetails/getAdgroupDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  customActionPanelConfigs: {
    products: addTargetsToCampaignActionPanel,
    categories: addCategoriesToCampaignActionPanel
  },
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':entityId',
        campaignType: 'SB',
        new_bid: ':newBid',
        keyword_id: ':keywordId',
        target_id: ':keywordId',
        adgroup_id: ':adgroupId'
      },
      viewPayload: {
        campaignId: ':entityId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        asin_id: ':asin_id'
      },
      actionType: ':actionType',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':entityId'
    }
  },
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':entityId',
        keyword_id: ':keywordId',
        target_id: ':keywordId',
        campaignType: 'SB',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':entityId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        asin_id: ':asin_id'
      },
      actionType: ':actionType',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':entityId'
    }
  },
  addTargetsToCampain: {
    requestPayload: {
      actionType: 'addTargetToCampaigns',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        adgroup_id: ':adgroup_id',
        campaignType: 'SB',
        new_expression_type: 'manual',
        new_match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid',
        new_expression: [
          {
            type: ':expressionType', // asinSameAs OR asinCategorySameAs
            value: ':asin_id' // asing or category
          }
        ],
        new_keyword_text: ':asin_id' // value in the new_expression
      },
      viewPayload: {
        new_keyword_text: ':asin_id',
        entityId: ':asin_id',
        match_type: 'TARGETING_EXPRESSION',
        entityName: ':asin_title',
        new_bid: ':bid',
        type: ':entityType' // product or category
      }
    }
  }
};

const adGroupNegativeTargetingBaseConfig = {
  component: sbNegativeTargeting,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTargetingPageData: 'entityDetails/getTargetingPageDetails',
    fetchTargetPages: 'entityDetails/fetchTargetingPageData',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  negativeKeyword: {
    customActionPanelComponent: sbAddNegativeKeywordsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeKeywordArchive'
      }
    ],
    addNegativeKeywordRequestPayload: {
      actionType: 'addNegativeKeyword',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SB',
        adgroup_id: ':adgroupId',
        new_keyword_text: ':newKeywordText',
        new_match_type: ':matchType'
      },
      viewPayload: {
        campaignId: ':campaignId',
        campaignType: 'SB',
        adgroupId: ':adgroupId',
        adgroupName: ':adgroupName',
        keywordText: ':viewPayloadKeywordText',
        matchType: ':matchType'
      }
    },
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaignId: ':campaignId',
          keyword_id: ':keywordId',
          campaignType: 'SB',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          adgroup_name: ':adgroupName'
        },
        actionType: 'updateNegativeKeywordState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    }
  },
  negativeTarget: {
    customActionPanelComponent: addNegativeTargetsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeTargetArchive'
      }
    ],
    addNegativeTargetRequestPayload: {
      actionType: 'addNegativeTarget',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SB',
        adgroup_id: ':adgroupId',
        new_match_type: ':matchType',
        new_keyword_text: ':newKeywordText',
        new_expression: [
          {
            type: ':expressionType', // asinCategorySameAs
            value: ':newKeywordText'
          }
        ]
      },
      viewPayload: {
        campaignId: ':campaignId',
        adgroupId: ':adgroupId',
        // adgroupName: "Terminate",
        keywordText: ':viewPayloadKeywordText', // value in new expression
        matchType: ':matchType'
      }
    },
    stateChange: {
      requestPayload: {
        actionType: 'updateNegativeTargetState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId',
        actionPayload: {
          campaignId: ':campaignId',
          campaignType: 'SB',
          target_id: ':keywordId',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          adgroupId: ':adgroupId',
          previousStatus: ':oldState',
          newStatus: ':state',
          adgroupName: ':adgroupName',
          keyword_text: ':keywordText',
          match_type: ':matchType'
        }
      }
    }
  }
};

const adGroupSearchTermKeywordConfig = {
  component: searchterms,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    getTargetingPageData: 'entityDetails/getTargetingPageDetails',
    fetchTargetPages: 'entityDetails/fetchTargetingPageData',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter:
      'entityDetails/fetchIncrementTableDataKey',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails'
  },
  addAsKeywordPayload: {
    requestPayload: getAddSearchTermsAsKeywordPayloadTemplate('SB')
  },
  addAsNegativeKeywordPayload: {
    requestPayload: getAddSearchTermsAsNegativeKeywordPayloadTemplate('SB')
  },
  requestPayload: {
    // Payload to differenciate product,keyword,category targeting
    cubeName: 'ams_cm_searchterm_workbench',
    measuresList: [
      'ams_cm_searchterm_keyword_text',
      'AMS_CM_SEARCHTERM_KEYWORD_TARGETING',
      'AMS_CM_SEARCHTERM_CATEGORY_TARGETING',
      'ams_cm_SEARCHTERM_product_targeting'
    ],
    groupByDimensionsList: ['keyword_id'],
    timeseriesDimension: 'report_date',
    daterangeDimension: 'report_date',
    orderByList: []
  },
  productTargeting: {
    page: 'cm_campaign_sb_tab_search_terms_products',
    widget: 'campaign_sb_tab_product_category_targeting'
  },
  keywordTargeting: {
    page: 'cm_campaign_sb_tab_search_terms',
    widget: 'campaign_sb_tab_keyword_targeting'
  }
};

function getAdGroupADsConfig() {
  const objectToReturn = {
    ...adgroupADsConfig,
    component: adgroupProductADs,
    apiConfigs: adGroupSearchTermKeywordConfig.apiConfigs,
    allowedActions: []
  };
  objectToReturn.stateChange.requestPayload.actionPayload.campaignType = 'SB';
  delete objectToReturn.stateChange;
  delete objectToReturn.addCustomProducts;
  return objectToReturn;
}

function getAdGroupSettingsConfig() {
  const objectToReturn = {
    ...adGroupSettingsConfig
  };
  objectToReturn.requestPayload.actionPayload.campaignType = 'SB';
  return objectToReturn;
}

function getAdGroupDefaultBidConfig() {
  const objectToReturn = {
    ...adgroupDefaultBidConfig
  };
  objectToReturn.requestPayload.actionPayload.campaignType = 'SB';
  return objectToReturn;
}

export function modifySBAdGroupsTabs(metadata, routeObject) {
  const metadataItemForTab = metadata[0] || null;
  const tabsToExclude = ['Ads', 'Default Bid', 'Settings'];
  if (!routeObject.query.isV4Campaign && metadataItemForTab) {
    metadataItemForTab.metadata.tabs.page_names =
      metadataItemForTab.metadata.tabs.page_names.filter(
        (item) => !tabsToExclude.includes(item.name)
      );
  }
}
export default {
  campaign: {
    page: 'cm_campaign_sb',
    metadata: [
      {
        action: 'entityDetails/fetchCampaingDetails',
        getters: 'entityDetails/getCampaignDetails'
      }
    ],
    header: {
      maxFeedDate: 'ams_campaign',
      filters: {
        config: {
          emit: 'sbCampaignDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'sbCampaignDetailsAddToFilters'
        },
        filterLocalStorageKey: 'campaigns-filters',
        page: 'sbCampaingDetails'
      },
      activityLog: {
        show: true
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        config: {
          name: 'Campaigns',
          params: {}
        }
      },
      // line 1 and 2 will wrap around date picker if its present
      metadata_api_state: {
        getter: 'entityDetails/getCampaignDetails'
      },
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Campaign',
              values: ['name'],
              hoverText: ['name'],
              titleClass:
                'u-font-weight-700 u-color-grey-lighter u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['NA']
            },
            class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
            itemType: 'attribute'
          }
        ],
        class: ''
      },
      line2: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Duration',
              values: ['startDate', 'endDate'],
              titleClass: null,
              delimiter: '-',
              valueClasses: null,
              altValues: ['No start date', 'No end date']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Budget',
              values: ['dailyBudgetView', ''],
              titleClass: null,
              delimiter: ' ',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Type',
              values: ['campaignType', 'targetingType'],
              titleClass: null,
              delimiter: '/',
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA', 'NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-m u-font-size-5'
      },
      line3: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Status',
              values: ['state'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-color-[color]-base u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            // since sb fetch is showing wrong data for campaign id hiding this field
            hide: true,
            fromMetadata: true,
            attribute: {
              title: 'Campaign ID',
              values: ['campaignId'],
              titleClass: null,
              delimiter: '',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Hourly Bidder Status',
              values: ['hourly_bidder_status'],
              titleClass: null,
              delimiter: null,
              valueClasses: null,
              altValues: ['NA']
            },
            class: '',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-l u-font-size-5'
      }
    },
    chart: {
      page: 'cm_campaign_sb',
      // campaignType: 'SB',
      maxFeedDate: 'ams_campaign',
      globalViewId: 0,
      pageId: 0,
      filter: 'sbCampaignDetailsFiltersApplied',
      apiConfigs: {
        getter: 'entityDetails/getCampaignDetails',
        fetchExecuteApiData: 'entityDetails/fetchTargetingPageData',
        getTargetingPageData: 'entityDetails/getTargetingPageDetails',
        getTableDataComponentKeyGetter:
          'entityDetails/getTableDataComponentKey',
        fetchTableDataComponentKeyGetter:
          'entityDetails/fetchIncrementTableDataKey'
      },
      adFormatVideo: 'video',
      sbVideoTabsWidget: 'campaign_sb_tabs_video',
      requestPayload: {
        // Payload to differenciate product,keyword,category targeting
        cubeName: 'ams_cm_targeting_workbench',
        measuresList: [
          'ams_cm_targeting_keyword_text',
          'AMS_CM_TARGETING_KEYWORD_TARGETING',
          'AMS_CM_TARGETING_CATEGORY_TARGETING',
          'ams_cm_targeting_product_targeting',
          'ams_cm_targeting_adgroup_name'
        ],
        groupByDimensionsList: ['keyword_id'],
        timeseriesDimension: 'report_date',
        daterangeDimension: 'report_date',
        orderByList: []
      },
      productTargeting: {
        // page name and widget name for targeting tab called from chart widget
        page: 'cm_campaign_sb_tab_product_category_targeting',
        widget: 'campaign_sb_tab_product_category_targeting'
      },
      keywordTargeting: {
        page: 'cm_campaign_sb_tab_keyword_targeting',
        widget: 'campaign_sb_tab_keyword_targeting'
      }
    },
    // show tabs data if present
    tabs: {
      adGroups: {
        component: adGroups,
        stateChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'SB',
              adgroup_id: ':adgroupId',
              state: ':state'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_state: ':oldState',
              state: ':state'
            },
            actionType: 'adgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          getter: 'entityDetails/getCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey'
        },
        allowedActions(vueRef) {
          const campaignInfo = vueRef.campaignInfo.data;
          if (campaignInfo?.isMultiAdGroupsEnabled === false) {
            // this means that it is a v3 campaign
            return [];
          }
          return [
            {
              icon: 'task-status',
              title: 'EDIT STATUS',
              id: 'editStatus',
              dropDown: true,
              dropDownOptions: [
                {
                  title: 'Enable',
                  value: 'enabled',
                  color: 'u-color-green-base'
                },
                {
                  title: 'Pause',
                  value: 'paused',
                  color: 'u-color-orange-base'
                },
                {
                  title: 'Archive',
                  value: 'archived',
                  color: 'u-color-red-base'
                }
              ]
            }
          ];
        },
        noActionMessage: 'V3 Campaigns are not actionable'
      },
      budget: {
        component: budget,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        reqMetadataMap: {
          ':oldBudget': 'dailyBudget',
          ':campaignId': 'entityId'
        },
        requestPayload: {
          actionPayload: {
            campaignId: ':campaignId',
            campaignType: 'SB',
            daily_budget: ':oldBudget',
            new_daily_budget: ':newBudget'
          },
          viewPayload: {
            name: ':name',
            campaignId: ':campaignId',
            previousBudget: ':oldBudget',
            newBudget: ':newBudget'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        }
      },
      settings: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SB'
          },
          viewPayload: {
            campaignId: ':entityId'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: settings,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        }
      }
    }
  },
  keyword_adgroup: {
    page: 'cm_adgroups_keyword_sb',
    metadata: getMetaDataConfig(),
    header: getHeaderConfigs('sb'),
    chart: getChartConfigs('sb', 'keyword'),
    tabs: {
      adgroupsTargetingKeyword: {
        ...adGroupTargetingBaseConfig,
        recCustomComponent: (context) => {
          return {
            RECOMMENDATIONS_KEYWORDS_EFFICIENT_SEARCHTERMS:
              addEfficientSearchTermsAsKeywordsRecommendationComponentConfig(
                context
              ),
            RECOMMENDATIONS_KEYWORDS_FOR_BRANDS:
              addImportantKeywordsForBrandRecommendationComponentConfig(
                context
              ),
            RECOMMENDATIONS_KEYWORDS_CAMPAIGN_BESTPRACTICE:
              alignCampaignToBestPracticesRecommendationComponentConfig(context)
          };
        }
      },
      adgroupKeywordNegativeTargeting: {
        ...adGroupNegativeTargetingBaseConfig
      },
      adgroupSearchTermKeyword: adGroupSearchTermKeywordConfig,
      adgroupSettings: getAdGroupSettingsConfig(),
      adgroupDefaultBid: getAdGroupDefaultBidConfig(),
      adgroupProductADs: getAdGroupADsConfig()
    }
  },
  product_adgroup: {
    page: 'cm_adgroups_product_sb',
    metadata: getMetaDataConfig(),
    header: getHeaderConfigs('sb'),
    chart: getChartConfigs('sb', 'product'),
    tabs: {
      adgroupsTargetingProduct: {
        ...adGroupTargetingBaseConfig
      },
      adgroupProductNegativeTargeting: {
        ...adGroupNegativeTargetingBaseConfig
      },
      adgroupSettings: getAdGroupSettingsConfig(),
      adgroupDefaultBid: getAdGroupDefaultBidConfig(),
      adgroupProductADs: getAdGroupADsConfig()
    }
  }
};
