import { render, staticRenderFns } from "./market-share-overview-widget.vue?vue&type=template&id=6ca170a6&scoped=true&"
import script from "./market-share-overview-widget.vue?vue&type=script&lang=ts&"
export * from "./market-share-overview-widget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca170a6",
  null
  
)

export default component.exports