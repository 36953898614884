import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import { eventBus } from '@/utils/services/eventBus';
function fetch(config) {}

function readFromLocalStorage(lsKey, storeSetter) {
  var _selectedFilters = localStorage.getItem(lsKey) || '{}';
  _selectedFilters = JSON.parse(_selectedFilters);
  for (var i in _selectedFilters) {
    this.$store.dispatch(storeSetter, {
      filterValueKey: i,
      values: _selectedFilters[i]
    });
  }
}

var oObject = {
  beforeCreate() {
    this.readFromLocalStorage = readFromLocalStorage.bind(this);
  },
  created() {
    if (this.config) {
      for (const key in this.config.widgets) {
        if (
          this.config.widgets[key].meta &&
          this.config.widgets[key].meta.localFilters
        ) {
          this.config.widgets[key].meta.localFilters = this.config.widgets[
            key
          ].meta.localFilters.filter((obj) => {
            return !(obj.dimensionName === 'search');
          });
        }
      }
      fetch.call(this, this.config);
    }

    if (this.config && this.config.filters && this.config.filters.listen) {
      for (var i in this.config.filters.listen) {
        eventBus.$on(
          i,
          function (data, mapping) {
            if (mapping) {
              this.config.mapping = mapping;
              if (this.config.filters.listen[i].transform) {
                this.config = this.config.filters.listen[i].transform(
                  this.config,
                  data,
                  this
                );
              }
              this.$store.dispatch(
                this.config.filters.listen[i].action,
                this.config
              );
            }
          }.bind(this)
        );
      }
    }
  },
  computed: {
    getMarketPlace() {
      return this.$store.getters.getMarketPlace;
    },
    outsideIn() {
      return this.$store.getters.getOutsideIn;
    },
    getColorPattern() {
      return [
        '#ffa800',
        '#bd10e0',
        '#ff6072',
        '#97cc04',
        '#23b5d3',
        '#f5d908',
        '#ff909d',
        '#ffc24c',
        '#d158ea',
        '#f8e552',
        '#b6dc4f',
        '#65cce1'
      ];
    }
  },
  data() {
    return {
      filterData: [],
      primaryFilterData: [],
      secondaryFilterData: {},
      loaderStatus: false
    };
  },
  methods: {
    loadFilter(value) {
      this.loaderStatus = value;
    },
    fetchFilters(cube, endPoint, page, where, system, header, addDigitalShelf) {
      this.loadFilter(true);
      var that = this;
      if (this.filterV2) {
        return HttpService.post('FILTER_METADATA_V2', {
          cubeName: cube,
          pageName: page,
          where: where,
          system: system
        })
          .then((response) => {
            var data = transformer.getFilterPanelData(response?.data?.response);
            that.primaryFilterData = data.panelData;
          })
          .finally(() => {
            that.loadFilter(false);
          });
      } else {
        return HttpLayer.post({
          cube: endPoint || 'FETCH_FILTERS',
          APIData: {
            cubeName: cube,
            pageName: page,
            where: where,
            system: system
          },
          header
        })
          .then((response) => {
            var data = transformer.mergeResultDimension(response.data);
            that.filterData = data;
            that.primaryFilterData =
              response?.fullResponse?.metadata?.dimensionMappingData || [];
            const filterBarData = that?.filter?.filterBar;
            if (filterBarData) {
              that.primaryFilterData = that.primaryFilterData.filter(
                (filter) => {
                  const filterExists = filterBarData.find(
                    (e) => e.key === filter.dimensionColumn
                  );
                  return filterExists ? filterExists?.addInFilter : true;
                }
              );
            }
            if (addDigitalShelf) {
              that.primaryFilterData.push({
                dimensionColumn: 'standardShelf',
                dimensionLabel: 'Standard Shelf',
                dimensionName: 'standardShelf',
                dimensionType: 'DIGITAL_SHELF',
                smart: false
              });
              that.primaryFilterData.push({
                dimensionColumn: 'customShelf',
                dimensionLabel: 'Custom Shelf',
                dimensionName: 'customShelf',
                dimensionType: 'DIGITAL_SHELF',
                smart: false
              });
            }
            return response;
          })
          .catch((err) => {
            return err;
          })
          .finally(() => {
            that.loadFilter(false);
          });
      }
    },
    getComputedFn(widget) {
      return this._computedWatchers[widget.dataSource].getter();
    },
    applyFilter() {
      if (this.config) {
        fetch.call(this, this.config);
      }
    },
    getAllDates() {
      var maxDates = this.$store.getters.getMaxDate;
      var returnDates = {};
      var dateRangeValues = {};
      var selectedDateRange =
        this.$store.getters[this.filterState.getter].date_range.name;
      var selectedDateRangeValues = this.$store.getters.getDateRangeValues;

      if (selectedDateRange) {
        for (var i in selectedDateRangeValues) {
          dateRangeValues[i] = selectedDateRangeValues[i][selectedDateRange];
        }

        for (i in maxDates) {
          returnDates[i] = maxDates[i].max_feed_date;
        }
      }
      returnDates.dateRange = selectedDateRange;
      returnDates.dateRangeValues = dateRangeValues;

      return returnDates;
    }
  }
};

export default oObject;
