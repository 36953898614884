<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('unavailable_sku') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('unavailable_for_last_')
      }}{{ alert.attributes.unavailable_days_count }}{{ $t('_days') }}.
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl u-spacing-pb-m">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('current_units_at_amazon')"
          :item-value="
            formatter(alert.attributes.current_units_at_amazon, 'number')
          "
        />
      </div>
      <!-- <div class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light">
        <key-value-pair :item-key="$t('offer_price')" :item-value="formatter(alert.attributes.offer_price, 'currency')"></key-value-pair>
      </div> -->
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('revenue')"
          :item-value="
            formatter(alert.attributes.last_4weeks_revenue, 'currency')
          "
        />
        <span class="u-font-size-7 u-font-weight-300 u-color-grey-lighter">
          ({{ $t('last_4_weeks') }})</span
        >
      </div>
    </div>
    <div class="u-display-flex u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.rating)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.rating || 'NA' }} {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.reviews || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('few_reviews') }}:
    </p>
    <p
      v-for="index in [1, 2, 3, 4, 5]"
      v-if="alert.attributes['one_star_review_to_display_' + index]"
      :key="index"
      class="u-font-size-4 u-spacing-pv-s"
    >
      {{ alert.attributes['one_star_review_to_display_' + index] }}
    </p>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max) per week:
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('unavailable_sku_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{ $t('unavailable_sku_rec_text') }}
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          hideY2: true,
          axes: {
            BSR: 'y2'
          },
          axis_format: {
            y2: {
              inverted: true
            }
          },
          tooltip_format: {
            All: {
              roundoff: 0
            }
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    var xs = {
      '1 star reviews': '1 star reviews_x',
      BSR: 'BSR_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    // var oneStarReviewsTrend = []
    // if (this.alert.attributes.one_star_reviews_trend) {
    //   oneStarReviewsTrend = JSON.parse(this.alert.attributes.one_star_reviews_trend)
    //   if (oneStarReviewsTrend.length) {
    //     dataArray = ['1 star reviews']
    //     dateArray = ['1 star reviews_x']
    //     for (entry of oneStarReviewsTrend) {
    //       if (entry.one_star_reviews_count === undefined) continue
    //       dataArray.push(entry.one_star_reviews_count)
    //       dateArray.push(entry.Date)
    //     }
    //     chartData.push(dataArray, dateArray)
    //   }
    // }

    // var bsrTrend = []
    // if (this.alert.attributes.bsr_trend) {
    //   bsrTrend = JSON.parse(this.alert.attributes.bsr_trend)
    //   if (bsrTrend.length) {
    //     dataArray = ['BSR']
    //     dateArray = ['BSR_x']
    //     for (entry of bsrTrend) {
    //       if (entry.bsr === undefined) continue
    //       dataArray.push(entry.bsr)
    //       dateArray.push(entry.Date)
    //     }
    //     chartData.push(dataArray, dateArray)
    //   }
    // }

    var weeklyForecastTrend = [];
    if (this.alert.attributes.weekly_forecast_trend) {
      weeklyForecastTrend = JSON.parse(
        this.alert.attributes.weekly_forecast_trend
      );
      if (weeklyForecastTrend.length) {
        weeklyForecastTrend = weeklyForecastTrend.sort((a, b) => {
          if (a.Week_start_date < b.Week_start_date) return -1;
          else if (a.Week_start_date > b.Week_start_date) return 1;
          return 0;
        });
        dataArray = ['Weekly Forecast'];
        dateArray = ['Weekly Forecast_x'];
        for (entry of weeklyForecastTrend) {
          if (entry.Week_value === undefined) continue;
          dataArray.push(entry.Week_value);
          dateArray.push(entry.Week_start_date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
