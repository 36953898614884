<template>
  <div class="u-spacing-mv-s">
    <div class="u-spacing-pv-s">Dynamic CC List</div>
    <div>Upload File here</div>
  </div>
</template>

<script>
export default {};
</script>
