<template>
  <div>
    <draggable
      v-model="localProductList"
      @start="drag = true"
      @end="
        drag = false;
        onMoveCallback();
      "
    >
      <div
        v-for="(product, i) in localProductList"
        :key="i + (product && product.asin)"
        class="u-displau-flex u-flex-direction-column u-spacing-mt-l"
      >
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
          >Product {{ i + 1 }}:</span
        >
        <div class="u-display-flex u-spacing-mt-s u-flex-align-items-center">
          <div
            class="u-width-100 u-flex-justify-content-flex-end u-display-flex u-flex-direction-column"
          >
            <div class="u-display-flex u-text-overflow-ellipsis">
              <rb-sku
                class="u-text-overflow-ellipsis"
                :show-image="true"
                :title="product.product_title"
                :image-url="product.image_url"
                :asin="product.asin"
                :product-page-url="product.product_url"
                :enable-s-d-p="false"
              />
            </div>
          </div>
          <div class="u-flex-1">
            <div class="u-display-flex u-flex-justify-content-flex-start">
              <rb-select
                :send-details="true"
                :on-close="(val, data) => updateSkusItem(data, i)"
                :options="allSkus || []"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-cursor-pointer u-cursor-pointer"
                >
                  <rb-icon
                    class="rb-icon--small u-cursor-pointer u-spacing-ml-xl"
                    :icon="'arrow-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-font-size-7"
                  >
                    <rb-sku
                      class="u-text-overflow-ellipsis"
                      :show-image="true"
                      :title="option.product_title"
                      :image-url="option.image_url"
                      :asin="option.asin"
                      :product-page-url="option.product_url"
                      :enable-s-d-p="false"
                    />
                  </div>
                </template>
              </rb-select>
              <rb-icon
                class="rb-icon--small u-spacing-ml-xl u-cursor-move"
                :icon="'draggable-reorder'"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  props: {
    productList: {
      type: Array,
      default: () => []
    },
    allSkus: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    localProductList: []
  }),
  watch: {
    productList: {
      handler(newVal) {
        this.localProductList = newVal;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.localProductList = this.productList;
  },
  methods: {
    updateSkusItem(sku, i) {
      if (!sku?.length) return;
      this.$emit('updateSkusItem', sku[0], i);
    },
    onMoveCallback() {
      this.$emit('skuPositionChanged', this.localProductList);
    }
  }
};
</script>

<style lang="css" scoped>
.select-creative-sku:hover {
  background: #007cf6;
  cursor: pointer;
}
.product-img {
  height: 46px;
  width: 46px;
}
</style>
