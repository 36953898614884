import Vue from 'vue';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
import {
  nativeRetailers,
  UtilServiceForConfig
} from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';
import {
  getRetailerConfig,
  retailersWithKeywordActions,
  selectSkuLeftTable,
  selectSkuRightTable
} from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils.js';
import { getDayPartingActionObjectArray } from '@/pages/campaign-management/retailers/walmart/campaigns/index.js';
import {
  keywordRightTable,
  removeKeywordsLeftTable,
  removeKeywordsRightTable
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils';
import { addKeywordsEnterListPanelUpdateTextInput } from '@/pages/dashboard_service_campaign_management/citrusAds/campaigns';
import addNegativeKeywordsPanel from '@/components/ams/actionPanels/addNegativeKeywordsPanel.vue';
import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import plotRowsChip from '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue';
import tableSwitchComponent from '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue';
import iconCell from '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import actionsBar from '@/components/widgets/custom_widgets/cw_actionsBar';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import EditBudgetAndPacing from '@/pages/campaign-management/retailers/criteo/line_items/EditBudgetAndPacing/index.vue';
import iconHeader from '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import nameChangePanel from '@/components/ams/actionPanels/nameChangePanel.vue';
import bidManagementPanel from '@/components/ams/actionPanels/bidManagementPanel.vue';
import adjustDatesPanel from '@/components/ams/actionPanels/adjustDatesPanel.vue';
import failedCampaignsBanner from '@/pages/entity_details/common_components/banner.vue';
import BackToAllCampaigns from '@/pages/dashboard_service_campaign_management/citrusAds/BackToAllCampaigns.vue';
import addSkusAdGroupsButton from '@/pages/dashboard_service_campaign_management/promoteiq/addSkusAdGroupsButton.vue';
import {
  getAdSkusActionObject as krogerGetAdSkusActionObject,
  getEditBudgetAndPacingActionObject as krogerGetEditBudgetAndPacingActionObject
} from '@/pages/dashboard_service_campaign_management/kroger/campaigns.js';
import { isEmpty } from 'lodash';

const WIDGET = 'campaign';
const PRIMARY_KEY = 'campaign_id';

const transformBudget = (vueRef, oldBid) => {
  let delta = parseFloat(vueRef.updatedLineItemBudget.inputValue);
  if (vueRef.updatedLineItemBudget.radioValue === 'set_new_budget') {
    return delta;
  }
  if (vueRef.updatedLineItemBudget?.dropdownValue === 'percentage') {
    delta = oldBid * (delta / 100);
  }
  if (vueRef.updatedLineItemBudget?.radioValue === 'increase_budget') {
    return parseFloat((oldBid + delta).toFixed(2));
  } else {
    return parseFloat((oldBid - delta).toFixed(2));
  }
};

export const getCampaignBudgetValue = (vueRef, prevBudget) => {
  return vueRef?.isCheckboxForBudgetSelected
    ? transformBudget(vueRef, prevBudget)
    : null;
};

export const getEndDateValue = (rowItem, vueRef, aggregator) => {
  return vueRef?.adjustLineItemBudgetTypeSelection === 'lifetime' &&
    vueRef?.endDate &&
    rowItem?.data?.[`${aggregator}_cm_campaign_end_date`] === null
    ? moment(vueRef?.endDate).format('YYYY-MM-DD')
    : null;
};

const getAdjustEndDatesActionObject = (retailer, aggregator) => ({
  icon: 'calendar',
  title: 'Adjust End Dates',
  id: 'adjust-end-dates',
  getValidSelections: (selections) =>
    getEditStatusOrEndDateValidSelections(selections, retailer, aggregator),
  invalidSelectionsMessage:
    'Invalid selections have been automatically deselected.',
  customState: {
    component: 'adjustDatesPanel',
    props: {
      timezone: 'EDT/EST',
      widgetsConfig: (adjustDatesPanelVueRef) => {
        return {
          headerText: () => {
            const selectionLength = adjustDatesPanelVueRef.selections.length;
            return `Adjust End Date (${selectionLength} Campaign${
              selectionLength > 1 ? 's' : ''
            } Selected)`;
          },
          leftCalendarDisabled: true,
          defaultStartEndDate: (adjustDatesPanelVueRef) =>
            defaultStartEndDate(adjustDatesPanelVueRef, aggregator),
          onStartDateUpdated: onStartDateUpdated(
            adjustDatesPanelVueRef,
            aggregator
          ),
          onLeftBtnClick: adjustEndDatesOnLeftBtnClick(
            adjustDatesPanelVueRef,
            retailer,
            aggregator
          )
        };
      }
    },
    events: {
      closePanelMethod() {
        this.removeCustomCustomComponents();
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        this.switchBlueBar('line item');
        this.removeCustomCustomComponents();
        this.closeActionsWorkflow();
      }
    }
  }
});

// Adjust End Dates Action Item
const defaultStartEndDate = (adjustDatesPanelVueRef, aggregator) => {
  const rowSelections = adjustDatesPanelVueRef.selections;
  if (rowSelections.length === 1) {
    const startDate =
      rowSelections[0].data?.[`${aggregator}_cm_campaign_start_date`];
    const endDate =
      rowSelections[0].data?.[`${aggregator}_cm_campaign_end_date`];
    return {
      start: startDate
        ? new Date(moment(startDate).format('MM/DD/YYYY'))
        : null,
      end: endDate ? new Date(moment(endDate).format('MM/DD/YYYY')) : null
    };
  }
  return {
    start: new Date(),
    end: new Date(moment().add(1, 'days').format('MM/DD/YYYY'))
  };
};

// Adjust End Dates Action Item
export const onStartDateUpdated = (adjustDatesPanelVueRef, aggregator) => {
  return function onStartDateUpdated() {
    const rowSelections = adjustDatesPanelVueRef.selections;
    if (rowSelections.length === 1) {
      const startDate =
        rowSelections[0].data?.[`${aggregator}_cm_campaign_start_date`];
      let endDateToBeDisabledTill = new Date();
      // check if start date is greater than today
      // if yes, endDateToBeDisabledTill will be till that day, else today's date
      if (moment(startDate).isAfter(moment().format('MM/DD/YYYY'))) {
        endDateToBeDisabledTill = new Date(
          moment(startDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY')
        );
      }
      return {
        endDateToBeDisabledTill
      };
    }
    return {
      endDateToBeDisabledTill: new Date()
    };
  };
};

// Adjust End Dates Action Item Apply Button Clicked.
const adjustEndDatesOnLeftBtnClick = (
  adjustDatesPanelVueRef,
  retailer,
  aggregator
) => {
  return async function onLeftBtnClick(newCampaignsDataObject) {
    const selectedCampaigns = adjustDatesPanelVueRef.selections;
    const payload = selectedCampaigns.map((selectedRow) => {
      const campaignId =
        selectedRow?.data?.[`${aggregator}_cm_campaign_campaign_id`];
      // Setting newEndDate to '-1'. Since sending null is causing issue in BE
      const newEndDate = newCampaignsDataObject.end
        ? moment(newCampaignsDataObject.end).format('YYYY-MM-DD')
        : '-1';
      return {
        widget: 'campaign',
        actionType: `${retailer}CampaignEndDateChange`,
        actionSource: {
          pageUrl: window.location.href
        },
        primaryKey: campaignId,
        actionPayload: {
          campaignId,
          new_end_date: newEndDate
        },
        viewPayload: {
          campaignId,
          current_end_date:
            selectedRow?.data?.[`${aggregator}_cm_campaign_end_date`] ||
            'No End Date',
          new_end_date:
            newEndDate && newEndDate !== '-1'
              ? moment(newEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
              : 'No End Date'
        }
      };
    });
    try {
      await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
      adjustDatesPanelVueRef.$emit('afterAPIActionToCall');
    } catch (err) {
      console.log(err);
    }
  };
};

// promoteiq and citrus don't support keyword level actions
// hence, we perform the actions at a campaign level by bulking the keywords
// reusing citrus configs here because it's already implemented before

function getActionsConfig(retailer, action) {
  const config = {
    remove: {
      title: 'Remove Keywords',
      id: 'remove-keywords',
      leftTab: {
        title: 'Active/Blocked Keywords',
        localFilters: [
          {
            dimensionName: '' + 'keyword_status',
            dimensionValue: 'active',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: '' + 'keyword_status',
            dimensionValue: 'blocklisted',
            operator: 'EQUAL_TO'
          }
        ]
      },
      payload: {
        actionType: `${retailer}CampaignBulkRemoveKeywords`,
        actionPayload(campaignId, rows) {
          return {
            campaignId: campaignId,
            campaignType: 'productAds',
            remove_keyword: rows.map((rowObject) => rowObject.keyword)
          };
        },
        viewPayload(campaignId, rows) {
          return {
            spreadViewPayload: true,
            campaignId: campaignId,
            campaignType: 'productAds',
            viewPayloadArray: rows.map((rowObject) => ({
              new_text: rowObject.keyword
            }))
          };
        }
      }
    },
    block: {
      title: 'Block Keywords',
      id: 'block-keywords',
      leftTab: {
        title: 'Active Keywords',
        localFilters: [
          {
            dimensionName: '' + 'keyword_status',
            dimensionValue: 'active',
            operator: 'EQUAL_TO'
          }
        ]
      },
      payload: {
        actionType: `${retailer}CampaignBulkBlocklistKeywords`,
        actionPayload(campaignId, rows) {
          return {
            campaignId: campaignId,
            campaignType: 'productAds',
            update_keyword: rows.map((keywordItem) => ({
              new_text: keywordItem.keyword,
              new_keyword_isNegative: 'true',
              new_keyword_bidMultiplier: '0'
            }))
          };
        },
        viewPayload(campaignId, rows) {
          return {
            campaignId: campaignId,
            campaignType: 'productAds',
            spreadViewPayload: true,
            viewPayloadArray: rows.map((keywordItem) => ({
              new_text: keywordItem.keyword,
              new_keyword_isNegative: 'true',
              new_keyword_bidMultiplier: '0'
            }))
          };
        }
      }
    },
    unblock: {
      title: 'Unblock Keywords',
      id: 'unblock-keywords',
      leftTab: {
        title: 'Blocked Keywords',
        localFilters: [
          {
            dimensionName: '' + 'keyword_status',
            dimensionValue: 'blocklisted',
            operator: 'EQUAL_TO'
          }
        ]
      },
      payload: {
        actionType: `${retailer}CampaignBulkUnblocklistKeywords`,
        actionPayload(campaignId, rows) {
          return {
            campaignId: campaignId,
            campaignType: 'productAds',
            update_keyword: rows.map((keywordItem) => ({
              new_text: keywordItem.keyword,
              new_keyword_isNegative: 'false',
              new_keyword_bidMultiplier: String(
                getRetailerConfig(retailer).bidValues.min
              )
            }))
          };
        },
        viewPayload(campaignId, rows) {
          return {
            campaignId: campaignId,
            campaignType: 'productAds',
            spreadViewPayload: true,
            viewPayloadArray: rows.map((keywordItem) => ({
              new_text: keywordItem.keyword,
              new_keyword_isNegative: 'false',
              new_keyword_bidMultiplier: String(
                getRetailerConfig(retailer).bidValues.min
              )
            }))
          };
        }
      }
    }
  };
  return config[action];
}

async function addKeywordsActionApply(customActionPanelVueRef, retailer, rows) {
  const payload = customActionPanelVueRef.selections.map((selection) => {
    const campaignId = selection?.data?.promoteiq_cm_campaign_campaign_id;
    return {
      widget: 'campaign',
      actionType: `${retailer}CampaignBulkAddKeywords`,
      actionSource: {
        pageUrl: window.location.href
      },
      actionPayload: {
        campaignId,
        campaignType: 'productAds',
        keywords: rows.map((row) => ({
          new_text: row.keyword,
          new_keyword_isNegative: 'false',
          new_keyword_bidMultiplier: row.newBid
        }))
      },
      viewPayload: {
        spreadViewPayload: true,
        campaignId,
        viewPayloadArray: rows.map((row) => ({
          new_text: row.keyword,
          new_keyword_isNegative: 'false',
          new_keyword_bidMultiplier: row.newBid,
          match_type: 'exact'
        }))
      },
      primaryKey: campaignId
    };
  });
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    customActionPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    customActionPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function getAddKeywordsActionObject(context, retailer) {
  return {
    icon: 'add-circle-outline',
    title: 'Add Keywords',
    id: 'add-keywords',
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: 'Add Keywords',
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            return {
              customActionPanelContainerClass: 'u-spacing-mt-l',
              primaryKey: 'key',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: 'Add Keywords to this list',
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                tabs: {
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'keyword',
                        buttonLabel: 'Add keywords',
                        heading: 'Match Type Exact:',
                        emitTextInput: true,
                        entityCount:
                          getRetailerConfig(retailer).addKeywordsLimit
                      },
                      events: {
                        updateTextInput(data) {
                          addKeywordsEnterListPanelUpdateTextInput(
                            data,
                            customActionPanelVueRef,
                            true
                          );
                        }
                      }
                    },
                    showCustomBidInput: true,
                    suggestedCustomBidErrorMessage: `Bid modifier must be between ${
                      getRetailerConfig(retailer).bidValues.min
                    } and ${getRetailerConfig(retailer).bidValues.max}`,
                    bidValues: {
                      default: getRetailerConfig(retailer).bidValues.min,
                      min: getRetailerConfig(retailer).bidValues.min,
                      max: getRetailerConfig(retailer).bidValues.max
                    },
                    subHeading: 'Bid Modifier',
                    removeLeftTabCustomComponentTopPadding: true
                  }
                },
                table: 'left',
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    page: 1,
                    limit: 200
                  }
                },
                headerText: 'Add Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: keywordRightTable(
                    customActionPanelVueRef
                  ).toSpliced(1, 1, {
                    name: 'Bid Modifier',
                    type: 'custom',
                    uiField: {
                      uiLabel: 'Bid Modifier',
                      uiOrder: '2',
                      uiType: 'custom',
                      metadata: {
                        toolTipText: '',
                        widget: 'input',
                        type: 'number',
                        tableColumnName: 'newBid',
                        defaultValueColumnName: 'newBid',
                        showOnUi: true,
                        isFixed: false,
                        formatType: 'currency',
                        width: 160,
                        keyupEvent: '',
                        onchangeEvent: (context, event) => {
                          // Validatino should happen here
                          context.params.data.newBid = context.bidValue;
                          customActionPanelVueRef.checkValidityForApply();
                        }
                      }
                    }
                  })
                },
                headerText: () =>
                  `Added Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                await addKeywordsActionApply.call(
                  this,
                  customActionPanelVueRef,
                  retailer,
                  rows
                );
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return (
                  customActionPanelVueRef.errorMessage ||
                  !arrayToTraverse.length ||
                  arrayToTraverse.length >
                    getRetailerConfig(retailer).addKeywordsLimit
                );
              }
            };
          }
        }
      },

      events: {
        rightTableRows(newRightTableRows, customActionPanelVueRef) {
          for (const row of newRightTableRows) {
            if (
              !row.newBid ||
              row.newBid < customActionPanelVueRef.cpcBidMin ||
              row.newBid > customActionPanelVueRef.cpcBidMax
            ) {
              customActionPanelVueRef.errorMessage = `Bid modifiers must be between ${customActionPanelVueRef.cpcBidMin} and ${customActionPanelVueRef.cpcBidMax}`;
              return;
            }
          }
          customActionPanelVueRef.errorMessage = '';
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    }
  };
}

async function keywordsActionObjectApply(
  customActionPanelVueRef,
  rows,
  config
) {
  const payload = [];
  customActionPanelVueRef.selections.forEach((selection) => {
    const campaignId = selection?.data?.promoteiq_cm_campaign_campaign_id;
    const rowsForCampaign = rows.filter(
      (row) => row.campaign_id === campaignId
    );
    if (isEmpty(rowsForCampaign)) {
      return;
    }
    payload.push({
      widget: 'campaign',
      actionType: config.payload.actionType,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      actionPayload: config.payload.actionPayload(campaignId, rowsForCampaign),
      viewPayload: config.payload.viewPayload(campaignId, rowsForCampaign)
    });
  });
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    customActionPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    console.log(err);
    customActionPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function getKeywordsActionObject(context, retailer, action) {
  if (action === 'add') {
    return getAddKeywordsActionObject(context, retailer);
  }
  const config = getActionsConfig(retailer, action);
  return {
    icon: 'remove-outline',
    title: config.title,
    id: config.id,
    customState: {
      component: 'customActionPanel',
      props: {
        staticHeaderText: config.title,
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            const campaignIds = customActionPanelVueRef.selections?.map(
              (item) => item?.data?.promoteiq_cm_campaign_campaign_id
            );
            return {
              primaryKey: 'key',
              isMultipleAllowed: true,
              customActionPanelContainerClass: 'u-spacing-mt-l',
              default: {
                noRowsRightTable: `Add Keywords to ${action} to this list`,
                selections: '',
                noRowsLeftTable: 'No Keywords sources found',
                leftBtnLabel: config.title,
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
                // actionInfoText: 'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
              },
              left: {
                tabs: {
                  [config.leftTab.title]: {
                    columnDefs: removeKeywordsLeftTable(
                      customActionPanelVueRef
                    ),
                    search: {
                      enableSearch: true,
                      searchKey: 'search'
                    },
                    showFilterCheckBox: false
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [
                    ...campaignIds.map((campaignId) => ({
                      dimensionName: 'campaign_id',
                      dimensionValue: campaignId,
                      operator: 'EQUAL_TO'
                    })),
                    ...config.leftTab.localFilters
                  ],
                  updateRows: (vueRef) => {
                    const newRows = [];
                    vueRef.leftTableData.rows.forEach((row) => {
                      newRows.push({
                        ...row,
                        campaign_name: row?.promoteiq_cm_keyword_campaign_name,
                        key:
                          row?.promoteiq_cm_keyword_campaign_name + row?.keyword
                      });
                    });
                    vueRef.leftTableRows = newRows;
                  },
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  useExistingWhereClause: true,
                  APIConfig: {
                    cubeName: 'promoteiq_campaigns_keyword_workbench',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'promoteiq_cm_keyword_campaign_name',
                      'promoteiq_cm_keyword_match_type',
                      'promoteiq_cm_keyword_keyword_id',
                      'promoteiq_cm_keyword_keyword'
                    ],
                    groupByDimensionsList: ['campaign_id', 'keyword'],
                    limit: 200,
                    page: 1,
                    customAPIDecisionVars: {
                      system: 'promoteiq_campaign_management'
                    },
                    where: {
                      dimensionNameValueList: []
                    }
                  }
                },
                headerText: config.title
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 50,
                    page: 1
                  },
                  columnDefs: removeKeywordsRightTable(customActionPanelVueRef)
                },
                headerText: () =>
                  `Selected Keywords (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                await keywordsActionObjectApply.call(
                  this,
                  customActionPanelVueRef,
                  rows,
                  config
                );
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                return !arrayToTraverse.length;
              }
            };
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getEditStatusOrEndDateValidSelections(
  selections,
  retailer,
  aggregator
) {
  if (retailer === 'kroger') {
    return selections.filter((selection) =>
      ['ACTIVE', 'PAUSED'].includes(
        selection.data[`${aggregator}_cm_campaign_campaign_status`]
      )
    );
  }
  return selections;
}

function getEditStatusDropdownToStateMapping(retailer) {
  if (retailer === 'kroger') {
    return {
      Enable: 'active',
      Pause: 'paused'
    };
  }
  return {
    Enable: 'active',
    Pause: 'inactive'
  };
}

function getEditStatusActionObject(retailer, aggregator) {
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    getValidSelections: (selections) =>
      getEditStatusOrEndDateValidSelections(selections, retailer, aggregator),
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    dropDownOptions: [
      { title: 'Enable', value: 'Enable' },
      { title: 'Pause', value: 'Pause' }
    ],
    async handler(selectionLength, dropDownSelection, selections = []) {
      const payload = [];
      selections.forEach((selectedCampaignRow) => {
        const campaignId = selectedCampaignRow?.data?.campaign_id;
        const dropdownToStateMapping =
          getEditStatusDropdownToStateMapping(retailer);
        const newState = dropdownToStateMapping[dropDownSelection[0].value];
        payload.push({
          widget: 'campaign',
          actionType: `${retailer}CampaignStatusChange`,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: campaignId,
          actionPayload: {
            campaignId: campaignId,
            state: newState
          },
          viewPayload: {
            campaignId: campaignId,
            current_status:
              selectedCampaignRow?.data?.[
                `${aggregator}_cm_campaign_campaign_status`
              ]?.toUpperCase(),
            status: newState?.toUpperCase()
          }
        });
      });
      try {
        await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
        this.$emit('openSnackbar', 'Action has been submitted');
      } catch (err) {
        console.log(err);
        this.$emit('openSnackbar', 'Something went wrong.');
      }
    }
  };
}

export function isEditBudgetAndPacingApplyDisabled(
  editBudgetAndPacingComponentRef
) {
  const {
    isAdjustLineItemBudgetSelected,
    isCheckboxForBudgetSelected,
    isAdjustPacingSelected,
    adjustLineItemBudgetTypeSelection,
    noOfCampaignsWithoutEndDate,
    endDate,
    updatedLineItemBudget,
    selections
  } = editBudgetAndPacingComponentRef;

  if (
    !(
      isAdjustLineItemBudgetSelected ||
      isCheckboxForBudgetSelected ||
      isAdjustPacingSelected
    )
  ) {
    return true;
  }

  if (
    isAdjustLineItemBudgetSelected &&
    adjustLineItemBudgetTypeSelection === 'lifetime' &&
    noOfCampaignsWithoutEndDate > 0 &&
    endDate === null
  ) {
    return true;
  }

  if (isCheckboxForBudgetSelected && !updatedLineItemBudget?.inputValue) {
    return true;
  }

  return (
    isAdjustLineItemBudgetSelected &&
    selections.length === 1 &&
    adjustLineItemBudgetTypeSelection ===
      selections[0]?.data?.promoteiq_cm_campaign_budget_interval.toLowerCase()
  );
}

function getEditBudgetAndPacingActionObject(context, retailer, aggregator) {
  if (retailer === 'kroger') {
    return krogerGetEditBudgetAndPacingActionObject(
      context,
      retailer,
      aggregator
    );
  }
  return {
    icon: 'dollar',
    title: 'Adjust Budget & Pacing',
    id: 'edit-budget-and-pacing',
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    customState: {
      component: 'EditBudgetAndPacing',
      props: {
        heading: 'Adjust campaign budget and pacing',
        title: 'Budget Interval',
        widgetsConfig: (editBudgetPacingVueRef) => {
          return {
            showCheckboxForBudget: true,
            disabledBudgetTypeTooltipMessage:
              'Please select Scheduled campaigns',
            endDateParam: `${aggregator}_cm_campaign_end_date`,
            secondHeading: 'Adjust Campaign Budget',
            showMonthlyAndDailyCap: false,
            showAdjustPacing: true,
            adjustPacingOptions: [
              {
                title: 'Even',
                value: 'even'
              },
              {
                title: 'Asap',
                value: 'asap'
              },
              ...(retailer === 'chewy_promoteiq'
                ? [
                    {
                      title: 'Front-Loaded',
                      value: 'front_loaded'
                    }
                  ]
                : [])
            ],
            adjustLineItemBudgetTypeSelection: [
              {
                title: 'Daily',
                value: 'daily'
              },
              {
                title: 'Weekly',
                value: 'weekly'
              },
              {
                title: 'Monthly',
                value: 'monthly'
              },
              {
                title: 'Lifetime',
                value: 'lifetime'
              }
            ],
            adjustLineItemBudgetCappedOptions: [
              {
                title: 'Set new budget',
                type: 'input',
                value: 'set_new_budget'
              },
              {
                title: 'Increase budget',
                type: 'dropdown',
                value: 'increase_budget',
                dropdown: [
                  {
                    label: 'Change by percentage',
                    value: 'percentage'
                  },
                  {
                    label: 'Change absolute value',
                    value: 'absolute_value'
                  }
                ]
              },
              {
                title: 'Decrease budget',
                value: 'decrease_budget',
                type: 'dropdown',
                dropdown: [
                  {
                    label: 'Change by percentage',
                    value: 'percentage'
                  },
                  {
                    label: 'Change absolute value',
                    value: 'absolute_value'
                  }
                ]
              }
            ],
            title: 'Adjust Campaign Budget Type',
            heading: 'Adjust campaign budget and pacing',
            actionType: `${retailer}MonthlyAndDailyPacingChange`,
            apiVersion: 'actionlog',
            retailer: context.$store.getters.getRetailer,
            widget: WIDGET,
            primaryKey: PRIMARY_KEY,
            leftBtnDisabled(editBudgetAndPacingComponentRef) {
              return isEditBudgetAndPacingApplyDisabled.call(
                this,
                editBudgetAndPacingComponentRef,
                retailer
              );
            },
            async onLeftBtnClick() {
              const selectedRows = editBudgetPacingVueRef.selections;
              const payload = selectedRows
                .map((campaign) => {
                  const campaignId = campaign?.data?.campaign_id;
                  const prevBudget =
                    campaign?.data?.[`${aggregator}_cm_campaign_total_budget`];
                  const newBudget = getCampaignBudgetValue(
                    editBudgetPacingVueRef,
                    prevBudget
                  );
                  const newBudgetType =
                    editBudgetPacingVueRef?.adjustLineItemBudgetTypeSelection?.toUpperCase();
                  const adjustPacingValue =
                    editBudgetPacingVueRef?.adjustPacingSelection?.toUpperCase();
                  const newEndDate = getEndDateValue(
                    campaign,
                    editBudgetPacingVueRef,
                    aggregator
                  );
                  return {
                    widget: 'campaign',
                    actionType: `${retailer}CampaignBudgetChange`,
                    actionSource: {
                      pageUrl: window.location.href
                    },
                    primaryKey: campaignId,
                    actionPayload: {
                      campaignId,
                      new_budget: newBudget,
                      new_budget_type: newBudgetType,
                      new_pacing: adjustPacingValue,
                      new_end_date: newEndDate
                    },
                    viewPayload: {
                      new_budget: newBudget,
                      new_budget_type: newBudgetType,
                      old_budget: prevBudget,
                      old_budget_type:
                        campaign?.data?.[
                          `${aggregator}_cm_campaign_budget_interval`
                        ],
                      old_pacing:
                        campaign?.data?.[`${aggregator}_cm_campaign_pacing`],
                      new_pacing: adjustPacingValue,
                      new_end_date: newEndDate
                    }
                  };
                })
                .filter(Boolean);
              try {
                await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
                editBudgetPacingVueRef.$emit('afterAPIActionToCall');
              } catch (err) {
                editBudgetPacingVueRef.openSnackbar('Something went wrong.');
              }
            }
          };
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

function onAddSkusActionRightTableRows(
  newRightTableRows,
  customActionPanelVueRef
) {
  const arrayToTraverse = newRightTableRows;
  for (const i in arrayToTraverse) {
    const newBid = parseFloat(arrayToTraverse[i]?.newBid);
    const minimumBid = parseFloat(arrayToTraverse[i]?.minimum_bid);
    if (newBid && minimumBid !== null && newBid < minimumBid) {
      customActionPanelVueRef.errorMessage =
        'The current bid should be greater than equal to the minimum bid';
      return;
    }
    if (!newBid) {
      customActionPanelVueRef.errorMessage =
        'Enter a valid value for current bid';
      return;
    }
  }
  customActionPanelVueRef.errorMessage = '';
}

async function addSkuActionApply(customActionPanelVueRef, rows, retailer) {
  const payload = [];
  customActionPanelVueRef.selections.forEach((selectedCampaign) => {
    rows.forEach((skuItem) => {
      const campaignId = selectedCampaign?.data?.campaign_id;
      const tempPayload = {
        widget: 'campaign',
        actionType: `${retailer}CampaignAddProduct`,
        actionSource: {
          pageUrl: window.location.href
        },
        primaryKey: campaignId,
        actionPayload: null,
        viewPayload: null
      };
      tempPayload.actionPayload = {
        campaignId: campaignId,
        publisher_reference: skuItem.sku,
        sku_bid: skuItem?.newBid
      };
      tempPayload.viewPayload = {
        campaignId: campaignId,
        sku: skuItem.sku,
        sku_title: skuItem?.sku_title,
        image_url: skuItem?.sku_latest_image_url,
        sku_bid: skuItem?.newBid
      };
      payload.push(tempPayload);
    });
  });
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    customActionPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    customActionPanelVueRef.openSnackbar('Something went wrong.');
  }
}

function getAddSkusActionObject(retailer, aggregator) {
  if (retailer === 'kroger') {
    return krogerGetAdSkusActionObject(retailer, aggregator);
  }
  return {
    icon: 'sku-box',
    title: 'Add SKUs',
    id: 'add-skus',
    customState: {
      component: 'customActionPanel',
      staticHeaderText: "Add SKU's",
      props: {
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            return {
              primaryKey: 'sku',
              customActionPanelContainerClass: 'u-spacing-mt-l',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: "Add SKU's to this list",
                selections: '',
                noRowsLeftTable: "No SKU's sources found",
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                rowHeight: 68,
                tabs: {
                  "All SKU's": {
                    columnDefs: selectSkuLeftTable(customActionPanelVueRef),
                    search: {
                      enableSearch: true,
                      searchKey: 'search_text',
                      placeholder:
                        'Search by Sku Name, ID, Category and Availability Status.'
                    },
                    showFilterCheckBox: false,
                    leftCustomBidValues: [
                      {
                        title: 'Minimum Bid',
                        value: 'minimum_bid'
                      },
                      {
                        title: 'Custom Bid',
                        value: 'custom_bid',
                        input: 'currency'
                      },
                      {
                        title: 'Max Suggested Bid',
                        value: 'max_suggested_bid'
                      },
                      {
                        title: 'Min Suggested Bid',
                        value: 'min_suggested_bid'
                      }
                    ],
                    showLeftCustomBid: true
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [],
                  updateRows: (vueRef) => {},
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  APIConfig: {
                    cubeName: 'promoteiq_sku_catalog',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'sku',
                      'sku_latest_image_url',
                      'sku_title',
                      'availability',
                      'price_current',
                      'suggested_bid_range',
                      'max_suggested_bid',
                      'min_suggested_bid',
                      'minimum_bid'
                    ],
                    groupByDimensionsList: ['sku'],
                    orderByList: [
                      {
                        dimension: 'sku_title',
                        direction: 'ASC'
                      }
                    ],
                    limit: 10,
                    page: 1,
                    where: {
                      dimensionNameValueList: []
                    }
                  },
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  columnDefs: selectSkuLeftTable(customActionPanelVueRef)
                },
                headerText: "Add SKU's"
              },
              right: {
                rowHeight: 68,
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 10,
                    page: 1
                  },
                  columnDefs: selectSkuRightTable(customActionPanelVueRef)
                },
                headerText: () =>
                  `Added SKU's (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(rows = []) {
                await addSkuActionApply.call(
                  this,
                  customActionPanelVueRef,
                  rows,
                  retailer
                );
              },
              applyButtonDisabled() {
                const arrayToTraverse =
                  customActionPanelVueRef.computedRightTableRows;
                if (customActionPanelVueRef.errorMessage) {
                  return true;
                }
                return !arrayToTraverse.length;
              }
            };
          }
        }
      },
      events: {
        rightTableRows(newRightTableRows, customActionPanelVueRef) {
          onAddSkusActionRightTableRows.call(
            this,
            newRightTableRows,
            customActionPanelVueRef
          );
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      }
    }
  };
}

function getActionBarActions(
  context,
  retailer,
  aggregator,
  utilServiceForConfig,
  isDayPartingEnabled
) {
  return [
    utilServiceForConfig.getPlotRowsActionObject(),
    getEditStatusActionObject(retailer, aggregator),
    getEditBudgetAndPacingActionObject(context, retailer, aggregator),
    getAddSkusActionObject(retailer, aggregator),
    getAdjustEndDatesActionObject(retailer, aggregator),
    ...getDayPartingActionObjectArray(isDayPartingEnabled),
    ...(retailersWithKeywordActions.includes(retailer)
      ? [
          getKeywordsActionObject(context, retailer, 'add'),
          getKeywordsActionObject(context, retailer, 'remove'),
          getKeywordsActionObject(context, retailer, 'block'),
          getKeywordsActionObject(context, retailer, 'unblock')
        ]
      : [])
  ];
}

function tableDeselectSelections(context, args, aggregator) {
  // this is on x click in actions bar;
  if (Array.isArray(args)) {
    context.handleSelectionChange(args, true);
  } else {
    const selections = args.selections;
    context.handleSelectionChange(selections);
    const selectedNodes = context.gridOptions?.api?.getSelectedNodes();
    selectedNodes?.forEach((node) => {
      if (
        !selections.find(
          (sel) =>
            sel.data?.[`${aggregator}_cm_campaign_campaign_id`] ===
            node.data?.[`${aggregator}_cm_campaign_campaign_id`]
        )
      ) {
        node.setSelected(false);
      }
    });
  }
}

function getTableConfig(utilServiceForConfig, context, retailer, aggregator) {
  const isDaypartingEnabled = Vue.options.filters.config_check(
    `feature.pages.${retailer}_strategies.enable`
  );
  return {
    props: {
      ...utilServiceForConfig.getTableWidgetProps(context),
      headerOptions: [
        {
          ui_component: 'actionsBar',
          props: {
            actionBarActions: getActionBarActions(
              context,
              retailer,
              aggregator,
              utilServiceForConfig,
              isDaypartingEnabled
            ),
            isCustomActionStateVisible: context.isCustomActionStateVisible,
            selections: context.rowSelections,
            showPlotRowsChip: context.showPlotRowsChip
          },
          events: {
            handlePlotRows: context.handlePlotRows,
            deselectSelections: (args) => {
              tableDeselectSelections(context, args, aggregator);
            },
            openSnackbar: context.openSnackbar,
            toggleCustomActionState: context.toggleCustomActionState,
            switchBlueBar: context.switchBlueBar
          }
        },
        ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
          context
        )
      ],
      enableManageColumns: !context.rowSelections.length
    }
  };
}

export function campaignsConfig(retailer) {
  const listApiPageName = `${retailer}_cm_campaigns`;
  const chartWidgetName = `${retailer}_cm_campaigns_charting_wb`;
  const tableWidgetName = `${retailer}_cm_campaigns_table`;
  const localStorageFilterKey = `${retailer}-campaigns-filter`;
  const localStorageFilterOrderKey = `${retailer}-campaigns-filter-order`;
  const tabName = 'Campaigns';
  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  let aggregator = nativeRetailers.includes(retailer) ? retailer : 'promoteiq';
  return {
    PRIMARY_KEY: 'campaign_id',
    entity: 'campaigns',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: utilServiceForConfig.getFilterSettings({
      backendCube: `${aggregator}_campaigns_workbench`,
      searchDimension: 'search',
      searchInputPlaceHolder: 'Search for a Campaign',
      bulkSearchStringConstants: {
        dimensionNameMapping: 'campaign_name',
        findEntityInputPlaceholder: 'Find a campaign name',
        subTitle: 'campaign names'
      },
      enableTaxonomyFilters: true
    }),
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      customActionPanel: customActionPanel,
      nameChangePanel: nameChangePanel,
      bidManagementPanel: bidManagementPanel,
      adjustDatesPanel: adjustDatesPanel,
      BackToAllCampaigns: BackToAllCampaigns,
      EditBudgetAndPacing: EditBudgetAndPacing,
      failedCampaignsBanner: failedCampaignsBanner,
      addNegativeKeywordsPanel: addNegativeKeywordsPanel,
      addSkusAdGroupsButton: addSkusAdGroupsButton
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),
    headerOptions: function (context) {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: getTableConfig(
          utilServiceForConfig,
          context,
          retailer,
          aggregator
        )
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('campaign'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail()
  };
}
