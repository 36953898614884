<template>
  <section
    style="width: 100%"
    class="card businessInsights-topMovers u-position-relative"
    :class="useV2TopMovers ? 'businessInsights-topMovers-3p' : ''"
  >
    <div
      v-show="!isWidgetLoading"
      class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-l u-spacing-pv-l u-flex-direction-row u-flex-align-items-center u-spacing-pb-0"
    >
      <div
        class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-text-case-upper u-display-flex"
      >
        <rb-icon
          data-cy="top_movers_icon"
          class="u-flex-0 rb-icon--medium u-color-grey-lighter"
          :icon="'top_movers'"
        />
        <span
          id="topMoversMeasureChange"
          v-tippy="{ placement: 'top', arrow: false }"
          title="This section helps track the top gainers and top losers based on the metrics selected"
        >
          {{ config && config.name }}
        </span>
        <RbSelectV2
          :on-close="onSelectChange"
          :options="mainDropDownOptions"
          :group-by-key="'metricGroup'"
          :send-details="true"
          class="u-spacing-ml-xs table-select-holder"
          :class-name="'tableSelect'"
          :is-waterfall-dropdown="true"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-cursor-pointer"
          >
            <div
              class="u-flex-1 u-font-size-3 u-font-weight-600 u-text-overflow-ellipsis u-color-grey-base"
              data-cy="top_movers_selected"
            >
              {{ mainDropDownSelectedOption.label }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
            >
              <div
                class="u-spacing-pr-m"
                :class="{ 'u-color-grey-lighter': option.disable }"
                data-cy="top_movers_select_options"
              >
                {{ option.label || option.name }}
              </div>

              <span
                v-if="option.children"
                class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                style="transform: rotate(90deg)"
              />
            </div>
          </template>
        </RbSelectV2>
        <template v-if="useV2TopMovers">
          <rb-tooltip-header
            id="tooltipTemplate"
            :title="topMoversHelpTooltipTitle"
          >
            <div
              slot="tooltip-message"
              class="tooltip-message u-spacing-pt-m u-font-size-6 u-color-grey-light"
            >
              <p>
                Top mover is determined by calculating the difference in the
                chosen metric from the selected date range on the calendar and
                same period last years’ data.
              </p>
              <p class="u-spacing-pt-s">
                <span class="u-font-weight-600">i.e</span> Change in ({{
                  mainDropDownSelectedOption.label
                }}) compared to ({{ getPVPTimeseriesLabel().dateText }})
              </p>
            </div>
          </rb-tooltip-header>
          <div
            v-tippy="{
              html: '#' + 'tooltipTemplate',
              theme: 'rb-tooltip-header',
              placement: 'bottom-start',
              interactive: true,
              reactive: true,
              arrow: false
            }"
            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger4"
          >
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-blue-base"
              icon="help-fill"
            />
          </div>
        </template>
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <span
          v-if="enableBenchmarking"
          v-tippy="{ placement: 'top', arrow: false }"
          :title="benchMarkBtnTitle"
          expoter="visibility-hidden"
        >
          <rb-button
            icon-left="benchmark"
            :click-fn="emitBenchmarkEvent"
            :disabled="topMoversList.length === 0"
            :text="
              topMoversList.length === 0
                ? 'Benchmark'
                : `Benchmark ${topMoversList.length} ${selectedEntity.label}${
                    topMoversList.length > 1 ? '(s)' : ''
                  }`
            "
            :size="'s'"
            :type="'filled'"
            class="u-spacing-mr-xs"
          />
        </span>
        <div
          v-if="!useV2TopMovers"
          class="u-display-flex u-flex-align-items-center"
        >
          <RbSelectV2
            v-tippy="{ placement: 'top', arrow: false }"
            title="Select the parameter you want to see the top movers for."
            class="u-spacing-mr-s u-spacing-ml-m rollby-dropdown u-max-width-100 campaigns-select"
            :on-close="onEntityChange"
            :send-details="true"
            :options="entityList"
            :group-by-key="'dimensionType'"
            :class-name="'campaigns-select'"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
            >
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                icon="calendar"
              />
              <span
                class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
                >Show by:&nbsp;&nbsp;</span
              >
              <div
                class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
              >
                {{ selectedEntity.label }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                icon="caret-down"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
              >
                <p
                  class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                  :class="{ 'u-color-grey-lighter': option.disable }"
                >
                  {{ option.label }}
                </p>
              </div>
            </template>
          </RbSelectV2>
        </div>
        <div
          v-if="enableDownload()"
          class="u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-ml-m"
        >
          <span
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-cursor-pointer"
            title="Downloading the excel will download the list of top gainers and losers based on the metrics selected"
            @click="downloadReport"
          >
            <rb-icon
              v-if="!isDownloadLoading && !isDownloadError"
              class="rb-icon u-color-grey-lighter icon-download"
              :icon="'download'"
            />
          </span>
          <loader
            :loading="isDownloadLoading"
            class=""
            :color="'#007cf6'"
          />
          <rb-icon
            v-if="isDownloadError"
            class="rb-icon--medium u-color-red-base"
            :icon="'download'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="useV2TopMovers"
      v-show="!isWidgetLoading"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-ph-l u-spacing-pv-l u-flex-direction-row u-flex-align-items-center u-spacing-pb-0"
    >
      <div class="u-display-flex u-flex-align-items-center">
        <tabs
          class="u-spacing-mr-s"
          :chip-size="'xm'"
          :tabs-data="tabData"
          :active-tab-index="activeTab"
          @tabClick="handleTabClick"
        />
        <RbSelectV2
          v-if="useV2TopMovers"
          v-tippy="{ placement: 'top', arrow: false }"
          title="Select the parameter you want to see the top movers for."
          class="u-spacing-mr-s u-spacing-ml-s rollby-dropdown u-max-width-100 campaigns-select u-font-weight-600"
          :on-close="onEntityChange"
          :send-details="true"
          :options="entityList"
          :group-by-key="'dimensionType'"
          :class-name="'campaigns-select'"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
          >
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              icon="settings"
            />
            <span
              class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
              >Group by:&nbsp;&nbsp;</span
            >
            <div
              class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
            >
              {{ selectedEntity.label }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              icon="caret-down"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
            >
              <p
                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                :class="{ 'u-color-grey-lighter': option.disable }"
              >
                {{ option.label }}
              </p>
            </div>
          </template>
        </RbSelectV2>
        <div
          v-if="enableSplitBy"
          slot="trigger"
          class="u-spacing-mr-s u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <div
            class="metric-manage-column u-flex-1 u-font-size-3 u-font-weight-600 u-text-overflow-ellipsis u-color-grey-base u-border-color-grey-xxx-light u-border-all u-border-width-s u-font-weight-600"
          >
            <rb-switch
              :true-value="true"
              :false-value="false"
              :value="isSplitByToggled"
              :native-value="isSplitByToggled"
              class="u-width-auto"
              @input="splitByToggled"
            />
            <span
              class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
            >
              Split by : Business Model
            </span>
          </div>
        </div>
        <div
          ref="trigger"
          v-tippy="metricsManageTooltipOptions"
        >
          <div
            class="metric-manage-column u-display-flex u-flex-align-items-center u-cursor-pointer u-border-color-grey-xxx-light u-border-all u-border-width-s u-font-weight-600"
          >
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              icon="settings"
            />
            <span
              class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
              >Metrics:&nbsp;&nbsp;</span
            >
            <div
              class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
            >
              {{ generateMetricsChipContent() }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              icon="caret-down"
            />
          </div>
          <div :id="metricsManageModalId">
            <popup-component
              class="u-width-100"
              :show-popup="openPopup"
              :popup-class="'u-display-inline-block'"
              :header-title="`Metrics (${Object.keys(detailedMetrics).length})`"
              :first-button-config="{ showButton: false }"
              :second-button-config="{ showButton: false }"
              @headerIconClickFn="closeIconClick"
            >
              <div
                slot="body"
                class=""
              >
                <manageColumns
                  :key="manageColumnsKey"
                  :selected-columns="metricsList"
                  listener="BOManageColumnsPopup"
                  :unselected-columns="getUnselectedColumns"
                  :detailed-metrics="detailedMetrics"
                  :non-negotiable-columns="nonNegotiableMetricsList"
                  :is-popup="true"
                  :max-selectable-metrics="maxSelectableMetrics"
                  @manageColumnsApply="manageColumnsApply"
                />
              </div>
            </popup-component>
          </div>
        </div>
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <span class="u-color-grey-base u-font-size-7"
          ><span class="u-font-weight-600">vs PP:</span> Versus Previous
          Period</span
        >
        <rb-icon
          icon="dot"
          class="rb-icon--xx-small u-color-grey-xx-light u-spacing-mh-s"
        />
        <span class="u-color-grey-base u-font-size-7"
          ><span class="u-font-weight-600">vs LY:</span> Versus Same Period Last
          Year</span
        >
      </div>
    </div>
    <div
      class="u-spacing-pt-l u-display-flex u-flex-direction-row u-width-100 u-spacing-pb-0"
      :class="{ 'u-spacing-p-l': !useV2TopMovers }"
    >
      <section
        class="u-display-flex u-flex-direction-column u-position-relative"
        :class="useV2TopMovers ? 'u-width-100' : 'u-width-50 u-spacing-mr-m'"
      >
        <div
          v-if="!useV2TopMovers"
          class="u-font-size-3 u-color-grey-base u-font-weight-600 u-text-camel-case u-spacing-mb-m"
        >
          Top Gainers
        </div>
        <div
          v-show="!dynamicShowLoaderConditionForTableOne"
          class="u-display-flex u-width-100 u-height-100 u-spacing-mb-xl table_wrapper"
        >
          <rb-insights-table
            v-show="getTableData.rows.length > 0"
            :has-expand="false"
            :config="dynamicTableConfig"
            :grid-options="gainersGridOptions"
            :table-column="getColumnDefsByRetailer"
            :row-selection="gainersSelection"
            style="width: 100%"
            :row-height="rowHeight"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="false"
            :sorting-change-event="'sortColumns'"
            :pagination="true"
            :show-pagination-options="true"
            :table-row="getTableData.rows || []"
            :pagination-total-key="getTableData.totalRows"
            :pagination-per-page-limit="getTopMoversRowPerPage"
            :pagination-change-event="'gainersPaginationEvent'"
            table-id="topMovers"
            :row-per-page-list="rowPerPageList"
            :is-full-width-cell="isFullWidthCell"
          />
          <div
            v-show="getTableData.rows.length === 0"
            class="u-font-size-5 u-width-100"
            style="display: flex; align-items: center; justify-content: center"
          >
            No Data
          </div>
        </div>
        <div
          v-if="dynamicShowLoaderConditionForTableOne"
          style="height: 500px"
        >
          <loader
            class="fill--parent u-bg-color-grey-white"
            :loading="dynamicShowLoaderConditionForTableOne"
            :color="'#007cf6'"
          />
        </div>
      </section>
      <section
        v-if="!useV2TopMovers"
        class="u-width-50 u-display-flex u-flex-direction-column u-position-relative"
      >
        <div
          class="u-font-size-3 u-color-grey-base u-font-weight-600 u-text-camel-case u-spacing-mb-m"
        >
          Top Losers
        </div>
        <div
          v-show="!isTopLosersLoading"
          class="u-display-flex u-width-100 u-height-100 u-spacing-mb-xl table_wrapper"
        >
          <rb-insights-table
            v-show="lowersData.rows.length > 0"
            :has-expand="false"
            :config="table_config.widgets['widget2']"
            :grid-options="losersGridOptions"
            :table-column="columnDefs"
            :row-selection="losersSelection"
            style="width: 100%"
            :row-height="rowHeight"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="false"
            :pagination="true"
            :show-pagination-options="true"
            :sorting-change-event="'sortColumns'"
            :table-row="lowersData.rows || []"
            :pagination-total-key="lowersData.totalRows"
            :pagination-per-page-limit="getTopMoversRowPerPage"
            :pagination-change-event="'losersPaginationEvent'"
            table-id="topLosers"
            :row-per-page-list="rowPerPageList"
          />
          <div
            v-show="lowersData.rows.length === 0"
            class="u-font-size-5 u-width-100"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 32px;
            "
          >
            No Data
          </div>
        </div>
        <div
          v-if="isTopLosersLoading"
          style="height: 500px"
        >
          <loader
            class="fill--parent u-bg-color-grey-white"
            :loading="isTopLosersLoading"
            :color="'#007cf6'"
          />
        </div>
      </section>
    </div>
    <div
      v-if="isWidgetLoading"
      style="height: 300px"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="isWidgetLoading"
        :color="'#007cf6'"
      />
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import { formatter } from '@/utils/helpers/formatter.js';
import helper from '@/utils/helpers/index.js';
import logger from '@/components/pages/skuDetails/logger';
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
import {
  columnConfig,
  skuCellDisplay,
  metricCellDisplay
} from '@/components/pages/businessInsights/columnConfig.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import moment from 'moment-timezone';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import TextWithButton from '@/components/basic/TextWithButton.vue';
import { widgetsConfig } from './widgets';
import tabs from '@/components/pages/insights/amazon/share-of-voice/molecules/tabs.vue';
import BOvalueVsPvpVsLYCell from '@/components/pages/businessInsights/BOvalueVsPvpVsLYCell.vue';
import { generateBundledDataPayloadSection } from '@/utils/common/dashboard-service-utils.js';
import popupComponent from '@/components/basic/dialog/popupComponent.vue';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import { generateTruncatedString, isBOPage } from '@/utils/helpers/utils.js';
import CONSTANTS from '@/utils/constants.js';

export default {
  name: 'BusinessInsightsTopMovers',
  author: 'Rajan',
  components: {
    loader,
    RbSelectV2,
    tabs,
    BOvalueVsPvpVsLYCell,
    popupComponent,
    manageColumns
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    page: {
      type: String,
      default: ''
    },
    isDefaultView: {
      type: Boolean,
      default: false
    },
    isSplitByToggled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedEntityValue: {},
      selectedOptionValue: {},
      isWidgetLoading: false,
      isTopMoversLoading: false,
      isTopLosersLoading: false,
      gainersSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onGainersSelectionChanged
      },
      losersSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onLosersSelectionChanged
      },
      isDownloadLoading: false,
      isDownloadError: false,
      gainersGridOptions: {
        context: {
          componentParent: this
        }
      },
      losersGridOptions: {
        context: {
          componentParent: this
        }
      },
      table_config: {
        widgets: {
          widget1: {
            meta: {
              widgetId: 'topGainers',
              type: 'table',
              hideOnNoData: false,
              width: 1,
              action: 'paginationEvent',
              key: 'tableColumnGroup',
              listen: {},
              localFilters: []
            },
            header: {
              search: false
            },
            body: {
              APIConfig: {
                limit: 5,
                page: 1
              }
            }
          },
          widget2: {
            meta: {
              widgetId: 'topLosers',
              type: 'table',
              hideOnNoData: false,
              width: 1,
              action: 'paginationEvent',
              key: 'tableColumnGroup',
              listen: {},
              localFilters: []
            },
            header: {
              search: false
            },
            body: {
              APIConfig: {
                limit: 5,
                page: 1
              }
            }
          }
        }
      },
      isDataLoading: false,
      topMoversList: [],
      topGainersList: [],
      topLosersList: [],
      rowHeight: 90,
      rowPerPageList: [{ title: 5 }, { title: 10 }, { title: 15 }],
      tabData: [{ label: 'Top Losers' }, { label: 'Top Gainers' }],
      activeTab: 0,
      metricsManageModalId: 'metrics-manage-modal',
      openPopup: false,
      manageColumnsKey: 0,
      maxSelectableMetrics: 5,
      topMoversHelpTooltipTitle: 'How is it calculated?'
    };
  },
  computed: {
    dynamicTableConfig() {
      if (this.useV2TopMovers) {
        return this.activeTab === 0
          ? this.table_config.widgets.widget2
          : this.table_config.widgets.widget1;
      }
      return this.table_config.widgets.widget1;
    },
    dynamicShowLoaderConditionForTableOne() {
      return this.useV2TopMovers
        ? this.isTopMoversLoading || this.isTopLosersLoading
        : this.isTopMoversLoading;
    },
    hidePPColumn() {
      const pvpDateObj = this.$store.getters.getWhereClause.pvpDate;

      return pvpDateObj?.compare_name === 'lastYear';
    },
    getColumnDefsByRetailer() {
      return this.useV2TopMovers ? this.columnDefsFor3P : this.columnDefs;
    },
    entityList() {
      return this.$store.getters.getTopMoversEntityList || [];
    },
    selectedEntity() {
      return this.$store.getters.getTopMoversSelectedEntity || {};
    },
    enableBenchmarking() {
      return this.selectedEntity?.additionalData?.allowBenchmarking;
    },
    enableSplitBy() {
      return (
        this.$store.getters.getRetailer === 'amazon_hybrid' &&
        this.$store.getters.getTopMoversSelectedEntity.label !==
          'Business Model'
      );
    },
    columnDefsFor3P() {
      let _columnDefs = [];
      const metricsOrderList =
        this.$store.getters.getTopMoversMetaData?.metadata?.metricOrderList ||
        [];
      const metrics = this.$store.getters?.getTopMoversMetaData?.metrics;
      const config = cloneDeep(
        (this.enableSplitBy && this.isSplitByToggled
          ? columnConfig.amazon_hybrid_split
          : columnConfig[this.$store.getters.getRetailer] ??
            columnConfig.amazon_eu
        )?.column
      );
      config.entity.name = this.selectedEntity;
      config.entity.pinned = true;
      config.entity.field = this.selectedEntity.dimensionName;

      if (this.isSplitByToggled) {
        const childrenTemplate = cloneDeep(config.children);
        delete config.children;
        const selectedMeasureIndex = metricsOrderList?.findIndex(
          (metric) => metric === this.mainDropDownSelectedOption?.alias
        );
        if (selectedMeasureIndex !== -1) {
          metricsOrderList?.splice(
            0,
            0,
            metricsOrderList.splice(selectedMeasureIndex, 1)[0]
          );
        } else {
          if (metricsOrderList?.length > this.maxSelectableMetrics) {
            metricsOrderList?.splice(0, 1);
          }
          metricsOrderList?.unshift(this.mainDropDownSelectedOption?.alias);
        }
        metricsOrderList?.forEach((metric, index) => {
          if (
            metrics?.[metric] &&
            metrics?.[metric]?.metadata?.visible !== false
          ) {
            const children = cloneDeep(childrenTemplate)?.map((children) => {
              if (children.title === 'Retail+3P') {
                children.field = `${metric}`;
              } else if (children.title === 'Retail') {
                children.field = `${metric}__retail`;
              } else if (children.title === '3P') {
                children.field = `${metric}__3p`;
              }
              return children;
            });
            config[metric] = {
              children: [...(children || [])],
              field: metric,
              title: metrics?.[metric]?.title,
              toolTipText: metrics?.[metric]?.tooltip,
              pinned: index === 0
            };
          }
        });
        Object.keys(config)?.forEach((metric) => {
          if (
            metric.includes('sku_3p_all__') &&
            this.$store.getters.getRetailer === 'amazon_hybrid'
          ) {
            delete [config.metric];
          }
        });
        _columnDefs = this.getColumnDefinition(config);
        return _columnDefs.slice(0) || [];
      } else {
        const valueVsPvpVsLYCellTemplate = cloneDeep(config.valueVsPvpVsLYCell);
        delete config.valueVsPvpVsLYCell;
        // Pusing the selected Measure as the first metric in the metrics list
        const selectedMeasureIndex = metricsOrderList?.findIndex(
          (metric) => metric === this.mainDropDownSelectedOption?.alias
        );
        if (selectedMeasureIndex !== -1) {
          metricsOrderList?.splice(
            0,
            0,
            metricsOrderList.splice(selectedMeasureIndex, 1)[0]
          );
        } else {
          if (metricsOrderList?.length > this.maxSelectableMetrics) {
            metricsOrderList?.splice(0, 1);
          }
          metricsOrderList?.unshift(this.mainDropDownSelectedOption?.alias);
        }
        metricsOrderList?.forEach((metric, index) => {
          if (
            metrics?.[metric] &&
            metrics?.[metric]?.metadata?.visible !== false
          ) {
            config[metric] = {
              ...valueVsPvpVsLYCellTemplate,
              field: metric,
              title: metrics?.[metric]?.title,
              toolTipText: metrics?.[metric]?.tooltip,
              pinned: index === 0
            };
          }
        });
        Object.keys(config)?.forEach((metric) => {
          if (
            metric.includes('sku_3p_all__') &&
            this.$store.getters.getRetailer === 'amazon_hybrid'
          ) {
            delete [config.metric];
          }
        });

        _columnDefs = this.getColumnDefinition(config);
        _columnDefs[0].field = 'entityValue';
        _columnDefs.forEach((item, index) => {
          if (index !== 0) {
            item.pinned = false;
            item.width = 400;
          }
        });
        return _columnDefs.slice(0) || [];
      }
    },
    columnDefs() {
      let _columnDefs = [];
      if (
        this.$store.getters.getTopMoversSelectedEntity?.dimensionName &&
        this.$store.getters.getTopMoversSelectedMeasure?.label &&
        this.$store.getters.getWhereClause?.date?.from &&
        this.$store.getters.getWhereClause?.pvpDate?.from
      ) {
        const columnConfigForRetailer =
          columnConfig[this.$store.getters.getRetailer] ?? columnConfig.global;
        const config = cloneDeep(columnConfigForRetailer.column);
        config.entity.title =
          this.$store.getters.getTopMoversSelectedEntity?.dimensionLabel;
        config.entity.toolTipText =
          this.$store.getters.getTopMoversSelectedEntity?.tooltip ||
          this.$store.getters.getTopMoversSelectedEntity?.dimensionLabel;

        config.entity.checkboxSelection =
          this.$store.getters.getTopMoversSelectedEntity?.additionalData
            ?.allowBenchmarking || false;
        if (
          this.$store.getters.getTopMoversSelectedEntity?.dimensionName ===
          'asin'
        ) {
          config.entity.type = 'asin';
        } else {
          this.rowHeight = 60;
          config.entity.type = 'String';
          config.entity.field =
            this.$store.getters.getTopMoversSelectedEntity?.dimensionName;
        }
        if (
          this.$store.getters.getTopMoversSelectedEntity?.additionalData
            ?.filterEntity
        ) {
          config.entity.keys = {
            hoverText: 'Add to Filter',
            hoverRequired: true,
            hoverClickFunction: this.addToFilter
          };
        }
        if (
          this.$store.getters.getTopMoversSelectedEntity?.additionalData
            ?.disableButton &&
          config.entity.type !== 'asin'
        ) {
          config.entity.keys = {
            hoverText: 'Add to Filter',
            hoverRequired: true,
            isDisable: true
          };
        }

        if (this.$store.getters.getTopMoversSelectedMeasure?.label) {
          config.measure.title =
            this.$store.getters.getTopMoversSelectedMeasure?.label;
          config.measure.toolTipText =
            this.$store.getters.getTopMoversSelectedMeasure?.tooltip;
          config.measure.type =
            this.$store.getters.getTopMoversSelectedMeasure?.metadata?.type ||
            'String';
          config.measure.unit =
            this.$store.getters.getTopMoversSelectedMeasure?.metadata?.unit;
        }
        if (this.$store.getters.getWhereClause.pvpDate) {
          config.currentPeriod.toolTipText =
            `Change in ${this.$store.getters.getTopMoversSelectedMeasure?.label} compared to previous period (` +
            this.generateTitleBasedOnDate(
              this.$store.getters.getWhereClause.pvpDate
            ) +
            ')';
          config.samePeriodLastYear.toolTipText =
            `Change in ${this.$store.getters.getTopMoversSelectedMeasure?.label} compared to same period last year (` +
            this.generateTitleBasedOnDate(
              this.$store.getters.getWhereClause.date,
              true
            ) +
            ')';
        }
        _columnDefs = this.getColumnDefinition(config);
      }
      return _columnDefs.slice(0) || [];
    },
    gainersData() {
      return { ...(this.$store.getters.getTopGainersData || {}) };
    },
    lowersData() {
      return { ...(this.$store.getters.getTopLosersData || {}) };
    },
    mainDropDownOptions() {
      const entityGroup =
        this.$store.getters.getTopMoversSelectedEntity?.entityGroup;
      const disableList = [];
      if (entityGroup && !entityGroup[0]?.showAllMetrics) {
        let filteredMeasureList = this.$store.getters.getTopMoversMeasureList;
        if (
          this.$store.getters.getTopMoversSelectedEntity?.entityGroup?.[0]
            ?.metrics.length > 0
        ) {
          filteredMeasureList =
            this.$store.getters.getTopMoversMeasureList.filter((item) => {
              if (
                this.$store.getters.getTopMoversSelectedEntity?.entityGroup?.[0].metrics.indexOf(
                  item.alias
                ) !== -1
              ) {
                item.disable = false;
                return item;
              }
              item.metricKey = item.alias;
              disableList.push({ key: item.alias, name: item.label });
              item.disable = true;
              return item;
            });

          // Remove the hidden metric
          filteredMeasureList = filteredMeasureList.filter((item) => {
            if (entityGroup[0].hiddenMetrics.indexOf(item.alias) !== -1) {
              return false;
            }
            return item;
          });
          filteredMeasureList.sort((a, b) => {
            return a.priority - b.priority;
          });
        }
        if (
          this.$store.getters.getTopMoversSelectedEntity?.entityGroup?.[0]?.metrics.indexOf(
            this.$store.getters.getTopMoversSelectedMeasure.alias
          ) === -1
        ) {
          const selectedMeasure =
            cloneDeep(
              this.$store.getters.getTopMoversMetaData.metrics[
                this.$store.getters.getTopMoversSelectedEntity?.entityGroup?.[0]
                  ?.metrics[0]
              ]
            ) || {};
          if (
            Object.keys(selectedMeasure) &&
            Object.keys(selectedMeasure).length > 0
          ) {
            this.$store.dispatch('MEASURE_CHANGE_ACTION', selectedMeasure);
          }
        }

        // Waterfall changes
        const metricsWithWaterfall =
          this.$store.getters.getTopMoversMetaData.metadata.groupInfo
            .metricsWithWaterfall;
        const waterfall = cloneDeep(
          this.$store.getters.getTopMoversMetaData.metadata.groupInfo.waterfall
            ?.share_of_voice
        );
        if (waterfall) {
          helper.disableTreeNodes(waterfall, disableList);
          filteredMeasureList.push(waterfall);
        }
        filteredMeasureList = filteredMeasureList.filter((item) => {
          if (metricsWithWaterfall[item?.alias]) {
            return false;
          }
          return item;
        });

        return [...filteredMeasureList];
      } else {
        return this.$store.getters.getTopMoversMeasureList || [];
      }
    },
    mainDropDownSelectedOption() {
      return this.$store.getters.getTopMoversSelectedMeasure || {};
    },
    benchMarkBtnTitle() {
      let title = '';
      if (this.topMoversList.length === 0) {
        title = `Select ${this.$store.getters.getTopMoversSelectedEntity?.dimensionLabel} from top gainers and top losers to add the selection to the benchmarking widget.`;
      } else {
        title = `Add selected ${this.$store.getters.getTopMoversSelectedEntity?.dimensionLabel} to the benchmark widget`;
      }
      return title;
    },
    getTopMoversRowPerPage() {
      return this.$store.getters.getTopMoversRowPerPage;
    },
    useV2TopMovers() {
      const retailer = this.$store.getters.getRetailer;
      const currLocation = this.$store.getters.getLocation;
      return (
        CONSTANTS.v2TopMoversSupportedRetailers.includes(retailer) ||
        CONSTANTS.v2TopMoversSupportedRegions.includes(currLocation)
      );
    },

    getTableData() {
      if (!this.useV2TopMovers) {
        return this.gainersData;
      }
      // Move this code to store.
      const data =
        this.activeTab === 0
          ? cloneDeep(this.lowersData)
          : cloneDeep(this.gainersData);
      if (this.isSplitByToggled) {
        data.rows =
          data?.rows?.map((row) => {
            const values = Object.keys(row)?.reduce((acc, cv) => {
              if (row?.[cv]) {
                acc[cv] = row?.[cv];
              }
              if (cv === 'asin' || cv === 'entityValue') {
                acc[cv] = row[cv];
              }
              return acc;
            }, {});
            return values;
          }) || [];
      }
      return data;
    },
    rowSelection() {
      return this.activeTab === 0
        ? this.losersSelection
        : this.gainersSelection;
    },
    metricsManageTooltipOptions() {
      return {
        html: '#' + this.metricsManageModalId,
        reactive: true,
        interactive: true,
        placement: 'top',
        theme: 'dropdown',
        trigger: 'click',
        onShow: this.onShow,
        onHide: this.onHide,
        popperOptions: {
          Defaults: {
            onCreate: this.onTippyCreate
          }
        }
      };
    },
    detailedMetrics() {
      return this.$store.getters.getTopMoversMetaData.metrics || {};
    },
    nonNegotiableMetricsList() {
      return [this.mainDropDownSelectedOption.alias];
    },
    getUnselectedColumns() {
      return Object.keys(this.detailedMetrics)?.reduce((result, metric) => {
        const metricData = this.detailedMetrics[metric];
        if (
          !(
            this.metricsList?.includes(metric) ||
            metricData?.metadata?.visible === false ||
            this.$store.getters.getTopMoversMetaData.metadata?.hiddenMetrics?.includes(
              metricData.alias
            )
          ) &&
          this.$store.getters.getRetailer === 'amazon_hybrid' &&
          !metric.includes('sku_3p_all__')
        ) {
          result.push(metric);
        }
        return result;
      }, []);
    },
    metricsList() {
      return (
        this.$store.getters.getTopMoversMetaData?.metadata?.metricOrderList ||
        []
      );
    },
    hiddenMetricsList() {
      return this.$store.getters.getTopMoversMetaData?.metadata?.hiddenMetrics;
    }
  },
  watch: {
    mainDropDownSelectedOption(newVal) {
      if (!newVal || !this.isDefaultView) {
        return;
      }
      const userPageMetadata = this.$store.getters.getUserPageMetadata;

      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: 'businessInsights',
        topMovers: {
          ...userPageMetadata?.page?.businessInsights?.topMovers,
          selectedMeasure: newVal.alias
        }
      });
    },
    selectedEntity(newVal) {
      if (!newVal || !this.isDefaultView) {
        return;
      }
      const userPageMetadata = this.$store.getters.getUserPageMetadata;

      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: 'businessInsights',
        topMovers: {
          ...userPageMetadata?.page?.businessInsights?.topMovers,
          selectedEntity: newVal.dimensionName
        }
      });
    },
    metricsList(newVal) {
      if (!newVal?.length || !this.isDefaultView) {
        return;
      }
      const userPageMetadata = this.$store.getters.getUserPageMetadata;
      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: 'businessInsights',
        topMovers: {
          ...userPageMetadata?.page?.businessInsights?.topMovers,
          metricsList: newVal
        }
      });
    },
    isSplitByToggled(newVal) {
      const userPageMetadata = this.$store.getters.getUserPageMetadata;
      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: 'businessInsights',
        topMovers: {
          ...userPageMetadata?.page?.businessInsights?.topMovers,
          isSplitByBusinessModelEnabled: newVal
        }
      });
    }
  },
  created() {
    // var _that = this;
    // eventBus.$on('gainersPaginationEvent', (data) => {
    //   // _that.fetchData('gainers', data);
    // });
    // eventBus.$on('losersPaginationEvent', (data) => {
    //   // _that.fetchData('losers', data);
    // });
    // eventBus.$on('sortColumns', function (column, config) {
    // });
    Vue.component('BOvalueVsPvpVsLYCell', BOvalueVsPvpVsLYCell);
    this.onInit();
  },
  mounted() {
    if (isBOPage(this.page)) {
      this.unsubscribeTopMovers = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SETWHERECLAUSEVALUE') {
          this.isWidgetLoading = true;
          const whereClause =
            cloneDeep(this.$store.getters.getWhereClause) || [];
          if (!this.selectedEntity?.filterGroup?.allFiltersApplicable) {
            for (
              let i = 0;
              i < whereClause.dimensionNameValueList.length;
              i++
            ) {
              const index =
                this.selectedEntity?.filterGroup?.filters?.findIndex(
                  (filterKey) => {
                    return (
                      filterKey ===
                      whereClause.dimensionNameValueList[i].dimensionName
                    );
                  }
                );
              if (index === -1) {
                const defaultEntity =
                  this.$store.getters.getTopMoversMetaData?.customMetadata?.dropdownOptions.filter(
                    (dropdownOption) => {
                      return dropdownOption.dimensionColumn === 'asin';
                    }
                  )[0];
                this.$store
                  .dispatch('ENTITY_CHANGE_ACTION', defaultEntity)
                  .finally(() => {
                    this.fetchData();
                  });
                return;
              }
            }
            this.$store
              .dispatch('ENTITY_CHANGE_ACTION', this.selectedEntity)
              .finally(() => {
                this.fetchData();
              });
          } else {
            this.$store
              .dispatch('ENTITY_CHANGE_ACTION', this.selectedEntity)
              .finally(() => {
                this.fetchData();
              });
          }
        }
        if (
          mutation.type ===
          'SET_TOPMOVERS_SELECTEDMEASUREFROMPERFORMANCEOVERVIEW'
        ) {
          if (!this.selectedEntity?.entityGroup?.[0]?.showAllMetrics) {
            if (
              this.selectedEntity?.entityGroup?.[0]?.metrics.indexOf(
                mutation.payload.data.alias
              ) === -1
            ) {
              // Find the entity list
              const selectedEntity =
                this.$store.getters.getTopMoversMetaData?.customMetadata?.dropdownOptions.filter(
                  (key) => {
                    return (
                      key.dimensionColumn ===
                      mutation.payload?.data?.entityList?.[0]
                    );
                  }
                )[0] || null;
              if (selectedEntity) {
                this.$store
                  .dispatch('ENTITY_CHANGE_ACTION', selectedEntity)
                  .finally(() => {
                    this.$store.dispatch(
                      'MEASURE_CHANGE_ACTION',
                      mutation.payload.data
                    );
                    this.$nextTick(() => {
                      this.fetchData();
                    });
                  });
              }
            } else {
              this.$store.dispatch(
                'MEASURE_CHANGE_ACTION',
                mutation.payload.data
              );
              this.fetchData();
            }
          } else {
            this.$store.dispatch(
              'MEASURE_CHANGE_ACTION',
              mutation.payload.data
            );
            this.fetchData();
          }
        }
        if (mutation.type === 'PaginationEvent') {
          if (mutation.payload && mutation.payload.storeKey === 'topGainers') {
            this.fetchData('gainers', mutation.payload.data);
          }
          if (mutation.payload && mutation.payload.storeKey === 'topLosers') {
            this.fetchData('losers', mutation.payload.data);
          }
        }
        if (mutation.type === 'RowPageEvent') {
          this.table_config.widgets.widget1.body.APIConfig.limit =
            mutation.payload.data;
          this.table_config.widgets.widget2.body.APIConfig.limit =
            mutation.payload.data;
          this.fetchData();
        }
      });
    }
  },
  beforeDestroy() {
    this.unsubscribeTopMovers();
    this.$store.dispatch('destroyTopMovers');
  },
  methods: {
    enableDownload() {
      return !Vue.options.filters.config_check(
        'feature.pages.amazon_hybrid.topMoversDisableDownload'
      );
    },
    fetchData(type, pageList) {
      if (
        this.$store.getters.getWhereClause?.date?.from &&
        this.$store.getters.getWhereClause?.date?.to &&
        this.selectedEntity?.label &&
        this.$store.getters.getTopMoversSelectedMeasure?.title
      ) {
        this.isWidgetLoading = false;
        this.topMoversList = [];
        const apiRequest = cloneDeep(
          this.$store.getters.getTopMoversSelectedMeasure.api
        );
        const body = apiRequest.request;
        body.page = this.config.page;
        body.widget = this.config.widgetName;
        body.entityType =
          this.selectedEntity?.dimensionName ||
          this.selectedEntity?.dimensionColumn;
        body.metricsList = [
          this.$store.getters.getTopMoversSelectedMeasure.name
        ];
        body.enablePaginationCount = true;
        body.operations.page = pageList || 1;
        body.operations.limit = this.$store.getters.getTopMoversRowPerPage;
        body.operations.showByEntities = [body.entityType];
        body.operations.isSplitByBusinessModelEnabled = this.isSplitByToggled;
        body.where =
          cloneDeep(
            this.$store.getters.getTopMoversSelectedMeasure.api.request.where
          ) || {};
        const standardDimensionNameValueList =
          cloneDeep(
            this.$store.getters.getTopMoversSelectedMeasure.api.request.where
              .dimensionNameValueList
          ) || [];
        body.where.date = this.$store.getters.getWhereClause.date;
        body.where.pvpDate = this.$store.getters.getWhereClause.pvpDate;
        if (!this.useV2TopMovers || this.isEURegion) {
          body.where.dimensionNameValueList = [
            ...this.$store.getters.getWhereClause.dimensionNameValueList,
            ...standardDimensionNameValueList
          ];
        }

        body.operations.orderByList = [];
        body.operations.outerWhereClause = {
          dimensionNameValueList: []
        };
        // body.where.dimensionNameValueList = body.where.dimensionNameValueList.filter(item => { return item.dimensionName !== 'asin'; });

        body.metricsList.push(
          ...(this.selectedEntity?.additionalDimension || '')
        );

        if (this.useV2TopMovers) {
          const metricsListToBePushed =
            this.$store.getters.getTopMoversMetaData.metadata?.metricOrderList?.filter(
              (metric) =>
                metric !== this.$store.getters.getTopMoversSelectedMeasure.alias
            );
          body.metricsList.push(...metricsListToBePushed);
          body.metricsList.push(
            ...(this.$store.getters.getTopMoversMetaData?.metadata
              ?.nonNegotiableMetricsList || [])
          );
          body.bundleDataGroupsRequest = generateBundledDataPayloadSection(
            this.$store.getters.getTopMoversMetaData.metrics
          );

          Object.keys(body.bundleDataGroupsRequest).forEach((bundle) => {
            body.bundleDataGroupsRequest[bundle].page = this.config.page;
            if (this.isEURegion) {
              body.bundleDataGroupsRequest[
                bundle
              ].where.dimensionNameValueList =
                this.$store.getters.getWhereClause.dimensionNameValueList;
            }
            body.bundleDataGroupsRequest[bundle].widget =
              this.config.widgetName;
            body.bundleDataGroupsRequest[bundle].where.date =
              this.$store.getters.getWhereClause.date;
            body.bundleDataGroupsRequest[bundle].where.pvpDate =
              this.$store.getters.getWhereClause.pvpDate;
            body.bundleDataGroupsRequest[bundle].entityType = body.entityType;
            body.bundleDataGroupsRequest[bundle].operations.showByEntities = [
              body.entityType
            ];
          });
        }

        const gainersReq = cloneDeep(body);
        gainersReq.operations.orderByList.push({
          dimension: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
          direction: 'DESC'
        });
        gainersReq.operations.outerWhereClause.dimensionNameValueList.push({
          dimensionName: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
          dimensionValue: 0,
          operator: 'GREATER_THAN'
        });
        if (
          this.$store.getters.getTopMoversSelectedMeasure?.metadata?.isInverted
        ) {
          gainersReq.operations.orderByList = [
            {
              dimension: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
              direction: 'ASC'
            }
          ];
          gainersReq.operations.outerWhereClause.dimensionNameValueList = [
            {
              dimensionName: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
              dimensionValue: 0,
              operator: 'LESS_THAN'
            }
          ];
        }
        if (type === 'download') {
          return gainersReq;
        }
        if (type === 'gainers' || (!type && !pageList)) {
          this.topGainersList = [];
          this.table_config.widgets.widget1.body.APIConfig.page = pageList || 1;
          this.fetchTopGainers(gainersReq, apiRequest?.endPoint);
          this.validateBMButton();
        }
        const losersReq = cloneDeep(body);
        losersReq.operations.orderByList.push({
          dimension: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
          direction: 'ASC'
        });
        losersReq.operations.outerWhereClause.dimensionNameValueList.push({
          dimensionName: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
          dimensionValue: 0,
          operator: 'LESS_THAN'
        });
        if (
          this.$store.getters.getTopMoversSelectedMeasure?.metadata?.isInverted
        ) {
          losersReq.operations.orderByList = [
            {
              dimension: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
              direction: 'DESC'
            }
          ];
          losersReq.operations.outerWhereClause.dimensionNameValueList = [
            {
              dimensionName: `pvp_diff_${this.$store.getters.getTopMoversSelectedMeasure.keyName}`,
              dimensionValue: 0,
              operator: 'GREATER_THAN'
            }
          ];
        }
        if (type === 'losers' || (!type && !pageList)) {
          this.topLosersList = [];
          this.table_config.widgets.widget2.body.APIConfig.page = pageList || 1;
          this.fetchTopLoser(losersReq, apiRequest?.endPoint);
          this.validateBMButton();
        }
      }
    },
    fetchTopGainers(body, endPoint) {
      this.isTopMoversLoading = true;
      this.$store
        .dispatch('fetchTopMoversData', {
          storeKey: 'topGainersData',
          body,
          endPoint
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isTopMoversLoading = false;
          setTimeout(() => {
            // this.gainersGridOptions.api.refreshHeader();
            this.gainersGridOptions?.api?.sizeColumnsToFit();
            // this.gainersGridOptions.api.redrawRows();
          }, 1);
        });
    },
    fetchTopLoser(body, endPoint) {
      this.isTopLosersLoading = true;
      this.$store
        .dispatch('fetchTopMoversData', {
          storeKey: 'topLosersData',
          body,
          endPoint
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          // this.losersGridOptions.api.sizeColumnsToFit();
          setTimeout(() => {
            // this.losersGridOptions.api.refreshHeader();
            this.losersGridOptions?.api?.sizeColumnsToFit();
            // this.losersGridOptions.api.redrawRows();
          }, 1);
          this.isTopLosersLoading = false;
        });
    },
    onInit() {
      this.isWidgetLoading = true;
      this.isEURegion =
        this.$store.getters.getLocation === CONSTANTS.regions.EU;
      const userPageMeta = this.$store.getters.getUserPageMetadata;
      const updatedConfig = this.config;

      if (
        !updatedConfig.metadata &&
        userPageMeta?.page?.businessInsights?.topMovers
      ) {
        if (!updatedConfig.metadata) {
          updatedConfig.metadata = {};
        }
        updatedConfig.metadata.selectedEntity =
          userPageMeta?.page?.businessInsights?.topMovers?.selectedEntity ??
          updatedConfig.metadata.selectedEntity;
        updatedConfig.metadata.selectedMeasure =
          userPageMeta?.page?.businessInsights?.topMovers?.selectedMeasure ??
          updatedConfig.metadata.selectedMeasure;
      }

      this.$store
        .dispatch('TOPMOVERS_STORE_INIT_ACTION', {
          storeKey: 'topMovers',
          config: updatedConfig,
          retailer: this.$store.getters.getRetailer,
          isDefaultView: this.isDefaultView
        })
        .then((response) => {
          this.fetchData();
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong!!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isWidgetLoading = false;
        });
    },
    onSelectChange(context, selection) {
      let optionSelection = selection;
      if (selection.treePath.length > 0) {
        const metricKey = selection.selectedOptions[0].metricKey;
        optionSelection = [];
        optionSelection.push(
          this.$store.getters.getTopMoversMetaData.metrics[metricKey]
        );
      } else {
        optionSelection = selection.selectedOptions;
      }
      if (optionSelection?.length > 0 && !optionSelection[0]?.disable) {
        this.$logger.logEvent({
          eventType: 'metric_change',
          type: 'Click',
          page: `${this.page}`,
          widget: this.config.widgetName,
          from: this.mainDropDownSelectedOption.label,
          to: optionSelection[0].label
        });

        const widgetMeta = this.$store.getters.getTopMoversMetaData;
        const currentEntityIndex =
          widgetMeta?.metadata?.metricOrderList?.findIndex((metric) => {
            return metric === this.mainDropDownSelectedOption?.alias;
          });
        this.$store.dispatch('MEASURE_CHANGE_ACTION', optionSelection[0]);
        if (currentEntityIndex) {
          widgetMeta.metadata.metricOrderList[currentEntityIndex] =
            this.mainDropDownSelectedOption?.alias;
          this.$store.dispatch('setTopMoversMetadataConfig', {
            storeKey: 'topMovers',
            data: widgetMeta
          });
        }

        this.fetchData();
      }
    },
    // onSelectChange (selectedOption) {
    //   if (selectedOption) {
    //     this.$store.dispatch('MEASURE_CHANGE_ACTION', selectedOption);
    //     this.fetchData();
    //   }
    // },
    onEntityChange(context, selectedOption) {
      if (selectedOption?.length > 0 && !selectedOption[0]?.disable) {
        if (
          this.selectedEntity.label === selectedOption[0]?.label &&
          this.selectedEntity.dimensionColumn ===
            selectedOption[0]?.dimensionColumn
        ) {
          return;
        }
        this.$logger.logEvent({
          eventType: 'show_by_change',
          type: 'Click',
          page: `${this.page}`,
          widget: this.config.widgetName,
          from: this.selectedEntity.dimensionLabel,
          to: selectedOption[0].label
        });
        this.isWidgetLoading = true;
        this.$store
          .dispatch('ENTITY_CHANGE_ACTION', selectedOption[0])
          .finally(() => {
            this.fetchData();
            this.isWidgetLoading = false;
          });
      }
      if (
        this.$store.getters.getTopMoversSelectedEntity.label ===
        'Business Model'
      ) {
        this.isSplitByToggled = false;
      }
    },
    // onEntityChange (selectedOption) {
    //   if (selectedOption) {
    //     this.isWidgetLoading = true;
    //     this.$store.dispatch('ENTITY_CHANGE_ACTION', selectedOption).finally(() => {
    //       this.fetchData();
    //       this.isWidgetLoading = false;
    //     });
    //   }
    // },
    onGainersSelectionChanged(data) {
      this.topGainersList = data;
      this.validateBMButton();
    },
    onLosersSelectionChanged(data) {
      this.topLosersList = data;
      this.validateBMButton();
    },
    validateBMButton() {
      this.topMoversList = [...this.topGainersList, ...this.topLosersList];
    },
    generateColumnDef(column) {
      const obj = {};
      obj.field = column.field;
      obj.headerComponentFramework = 'genericTableHeader';
      // obj.enableSorting = true;
      let keyType = 'string';
      if (column.type?.toUpperCase() === 'NUMERIC') {
        keyType = column.unit ?? 'NUMERIC';
      }
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter,
        clamp: 3
      };
      obj.cellRenderer = cellRenderer;
      obj.headerName = column.title;
      obj.title = column.title;
      obj.headerComponentParams = {
        enableSorting: false
      };
      obj.keyOrder = column.keyOrder;
      obj.sort = column.sort;
      obj.headerCheckboxSelection = column.headerCheckboxSelection;
      obj.checkboxSelection = column.checkboxSelection;
      obj.pinned = column.pinned;
      if (column.columnType === 'entity') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = TextWithButton;
        obj.cellRendererParams.renderParams = column.keys;
        obj.width = 350;
        obj.minWidth = 200;
      }
      if (obj.headerName === 'SKU' || column?.name?.dimensionName === 'asin') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = skuCellDisplay;
        obj.cellRendererParams.renderParams = column.keys;
        obj.headerComponentParams.enableSorting = false;
        obj.minWidth = this.useV2TopMovers ? 350 : 220;
      }
      if (
        ['currentPeriod', 'samePeriodLastYear']?.includes(column.columnType)
      ) {
        delete obj.cellRenderer;
        obj.cellRendererFramework = metricCellDisplay;
        obj.cellRendererParams.renderParams = column.keys;
      }

      if (column.columnType === 'valueVsPvpVsLYCell') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'BOvalueVsPvpVsLYCell';
        obj.cellRendererParams.renderParams = column.keys;
      }

      if (column.columnType === 'children') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'BOvalueVsPvpVsLYCell';
        obj.cellRendererParams.renderParams = column.keys;
      }

      if (column.toolTipText) {
        obj.headerComponentParams.toolTipText = column.toolTipText;
      }

      const amazon3PCondition = this.useV2TopMovers && this.hidePPColumn;
      if (column.type === 'custom') {
        obj.minWidth = amazon3PCondition ? 350 : 270;
      }

      obj.suppressSizeToFit = false;
      obj.headerComponentParams.keyType = keyType;
      return obj;
    },
    getColumnDefinition(columns) {
      let _columnDefs = [];
      let index = 0;
      for (const i in columns) {
        let obj = {};
        if (columns[i].children && columns[i].children.length > 0) {
          obj.headerName = columns[i].title;
          obj.field = columns[i].field;
          obj.width = 350;
          obj.keyOrder = columns[i].keyOrder || index++;
          obj.marryChildren = true;
          obj.pinned = columns[i].pinned;
          obj.children = [];
          for (const j in columns[i].children) {
            obj.children.push(this.generateColumnDef(columns[i].children[j]));
          }
        } else {
          obj = this.generateColumnDef(columns[i]);
        }
        _columnDefs.push(obj);
      }
      // _columnDefs =  _columnDefs.map((item) => {
      //   if (item.field !== 'asin') {
      //     const tempItem = {
      //       title: item.title,
      //       headerName: item.headerName,
      //       key: 'common_columns',
      //       keyOrder: 2,
      //       label: 'SKU Info',
      //       toolTipText: 'SKU Info',
      //       marryChildren: true,
      //       children: [item, item]
      //     };
      //     return tempItem;
      //   }
      //   return item;
      // });
      let columnDefs = [];
      columnDefs = _columnDefs.sort((a, b) => {
        return a.keyOrder - b.keyOrder;
      });
      return columnDefs;
    },
    generateTitleBasedOnDate(date, lastYear) {
      if (lastYear) {
        return (
          moment(date.from, 'YYYY-MM-DD').subtract(1, 'year').format('ll') +
          ' - ' +
          moment(date.to, 'YYYY-MM-DD').subtract(1, 'year').format('ll')
        );
      }
      return (
        moment(date.from, 'YYYY-MM-DD').format('ll') +
        ' - ' +
        moment(date.to, 'YYYY-MM-DD').format('ll')
      );
    },
    downloadReport() {
      logger.log({
        eventType: 'download_button_click',
        type: 'Click',
        page: `${this.config.page}`,
        widget: this.config.widgetName,
        pageIdentifier: `${this._skuID}`,
        eventContext: `${this.config.page} Download Button`
      });

      const APITemplate = cloneDeep(
        this.$store.getters.getTopMoversMetaData?.downloadApiTemplate || {}
      );

      if (APITemplate.request) {
        APITemplate.request.page = this.config.page;
        APITemplate.request.entityValue = this._skuID;
        APITemplate.request.widget = this.config.widgetName;
        APITemplate.request.metricsList = this.useV2TopMovers
          ? this.$store.getters.getTopMoversMetaData.metadata.metricOrderList
          : [this.$store.getters.getTopMoversSelectedMeasure.name];
        APITemplate.request.eventsList = [];
        APITemplate.request.entityType = this.selectedEntity?.dimensionName;
        APITemplate.request.operations = {};
        APITemplate.request.where = {};
        // this.downloadComponent.tempMetricObj
        APITemplate.request.overridenDataGroupsRequest = {};
        APITemplate.request.overridenDataGroupsRequest[
          this.$store.getters.getTopMoversSelectedMeasure?.dataGroup || 'name'
        ] = this.fetchData('download');
        if (this.useV2TopMovers) {
          delete APITemplate.request.overridenDataGroupsRequest[
            this.$store.getters.getTopMoversSelectedMeasure?.dataGroup || 'name'
          ].bundleDataGroupsRequest;
          APITemplate.request.overridenDataGroupsRequest[
            this.$store.getters.getTopMoversSelectedMeasure?.dataGroup || 'name'
          ].metricsList =
            this.$store.getters.getTopMoversMetaData.metadata.metricOrderList;
        }
        this.isDownloadLoading = true;

        HttpService.post(
          APITemplate.service,
          { ...APITemplate.request },
          { append: APITemplate.endPoint }
        )
          .then((response) => {
            if (response.status) {
              downloadFile(response.data.url);
            } else {
              this.handleDownloadError();
            }
            this.isDownloadLoading = false;
          })
          .catch((error) => {
            this.isDownloadLoading = false;
            this.handleDownloadError(error);
          })
          .finally(() => {
            this.isDownloadLoading = false;
          });
      }
    },
    handleDownloadError() {
      this.isDownloadError = true;
      this.$snackbar.open({
        message: 'Oops! The download was not successful. Please try again.',
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
      setTimeout(() => {
        this.isDownloadError = false;
      }, 4000);
    },
    emitBenchmarkEvent() {
      const body = {};
      body.benchmarkingSelectedColumn = this.topMoversList.reduce(
        (acc, item, index) => {
          if (!acc[item.data.entityValue]) {
            acc[item.data.entityValue] = {};
            acc[item.data.entityValue].data = {};
            acc[item.data.entityValue].data.entityId = item.data.entityValue;
            acc[item.data.entityValue].data.entityName = item.data.entityValue;
            acc[item.data.entityValue].data.product_url = item.data.product_url;
            acc[item.data.entityValue].data.image_url = item.data.image_url;
            acc[item.data.entityValue].data.title = item.data.title;
            acc[item.data.entityValue].data[item.data.metricName] =
              item.data.metricValue;
            acc[item.data.entityValue].data.asin = item.data.entityValue;
            acc[item.data.entityValue].data.label = item.data.entityValue;
            acc[item.data.entityValue].data.order = index + 1;
          }
          return acc;
        },
        {}
      );
      body.benchmarkingMainDropdownSelectionKey =
        this.selectedEntity.dimensionColumn;
      body.benchmarkingPlottedMetric = {
        key: this.mainDropDownSelectedOption.alias,
        name: this.mainDropDownSelectedOption.label
      };
      this.gainersGridOptions?.api?.deselectAll();
      this.losersGridOptions?.api?.deselectAll();
      const numberOfEntites = Object.keys(
        body.benchmarkingSelectedColumn
      ).length;
      this.$logger.logEvent({
        eventType: 'topmover_benchmark_by',
        type: 'Click',
        page: `${this.page}`,
        widget: this.config.widgetName,
        showby: body.benchmarkingMainDropdownSelectionKey,
        metric_name: body.benchmarkingPlottedMetric.name,
        number_of_entites: numberOfEntites
      });
      this.$store.dispatch('benchmarkingSetNewState', {
        data: body,
        storeKey: this.page
      });
      document.getElementById('benchMarking').scrollIntoView();
    },
    addToFilter(params) {
      const oFilter = {};
      oFilter.key = params.data.entityType;
      oFilter.value = params.data.entityValue;
      oFilter[params.data.entityType] = params.data.entityValue;
      const defaultEntity =
        this.$store.getters.getTopMoversMetaData?.metadata?.defaultConfig
          ?.selectedEntity;
      const selectedEntity = this.entityList.find((item) => {
        return item.dimensionColumn === defaultEntity;
      });
      this.$store
        .dispatch('ENTITY_CHANGE_ACTION', selectedEntity)
        .finally(() => {
          eventBus.$emit(widgetsConfig.config.filters.addToFilter, oFilter);
        });
    },
    handleTabClick(index) {
      this.activeTab = index;
    },
    closeIconClick() {
      this.openPopup = false;
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.openPopup = true;
    },
    onHide(instance) {
      this.openPopup = false;
    },
    manageColumnsApply(data) {
      const widgetMeta = this.$store.getters.getTopMoversMetaData;
      let filteredColumns = data.selectedColumns;
      if (this.$store.getters.getRetailer === 'amazon_hybrid') {
        filteredColumns = data.selectedColumns.filter(
          (column) => !column.includes('sku_3p_all__')
        );
      }
      widgetMeta.metadata.metricOrderList = filteredColumns;
      this.$store.dispatch('setTopMoversMetadataConfig', {
        storeKey: 'topMovers',
        data: widgetMeta
      });
      const instance = document.querySelector('.tippy-popper')._tippy;
      instance?.hide();
    },
    generateMetricsChipContent() {
      let metricsNames = this.metricsList?.reduce((result, metric) => {
        const metricLabel = this.detailedMetrics[metric]?.label;
        if (metricLabel) {
          result.push(metricLabel);
        }
        return result;
      }, []);
      metricsNames = metricsNames.filter((metricName) => {
        return metricName !== this.mainDropDownSelectedOption.label;
      });
      metricsNames.unshift(this.mainDropDownSelectedOption.label);

      return generateTruncatedString(metricsNames, 24);
    },
    isFullWidthCell(rowNode) {
      return rowNode?.data?.isFullWidthCell;
    },
    getPVPTimeseriesLabel() {
      const fromDate = moment(this.getDateObj().pvpFrom).format('MMM DD, YYYY');
      const toDate = moment(this.getDateObj().pvpTo).format('MMM DD, YYYY');
      this.pvpDateText = fromDate + ' - ' + toDate;
      return {
        text: 'Plot trendline of',
        dateText: this.pvpDateText
      };
    },
    getDateObj() {
      const dateObj = this.$store.getters.getWhereClause.date;
      const pvpDateObj = this.$store.getters.getWhereClause.pvpDate;
      return {
        pvpFrom: pvpDateObj?.from,
        pvpTo: pvpDateObj?.to,
        from: dateObj?.from,
        to: dateObj?.to
      };
    },
    splitByToggled(value) {
      this.isSplitByToggled = !this.isSplitByToggled;
      const meta = this.$store.getters.getTopMoversMetaData?.metadata;
      this.$store.dispatch('TOGGLE_CHANGE_ACTION', value).finally(() => {
        this.fetchData();
        this.isWidgetLoading = false;
      });
    }
  }
};
</script>
<style lang="css" scoped>
.optionsDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  padding: 10px 16px;
}
.rollby-dropdown {
  border-radius: 13.5px;
  height: 26px;
  border: solid 1px #e9eaeb;
}
.rollby-dropdown:hover,
.rollby-dropdown:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}
.icon-download:hover {
  color: #4b5158;
}
.metric-manage-column {
  border-radius: 100px;
  padding: 3px 4px;
}
.rb-input,
.rb-textarea {
  height: 24px !important;
}
.tooltip-message {
  display: flex;
  flex-direction: column;
}
</style>
