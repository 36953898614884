<template>
  <table
    class="u-display-flex u-flex-direction-column u-flex-justify-content-space-around u-height-100"
  >
    <tr
      v-for="(item, index) in listColumn"
      :key="index"
    >
      <component
        :is="subComponent.name"
        v-if="subComponent.name"
        v-bind="subComponent.props[index]"
      />
      <div v-else>
        {{ formatter(item) }}
      </div>
    </tr>
  </table>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  name: 'ColumnListCell',
  data() {
    return {
      componentProps: {
        href: 'www.google.com',
        innerHTML: 'InnerHTML'
      },
      template: 'RbLink',
      formatter: formatter
    };
  },
  computed: {
    listColumn() {
      return this.params.value;
    },
    subComponent() {
      const subComponent = {
        name: '',
        props: []
      };
      if (this.params.subComponent) {
        subComponent.name = this.params.subComponent.name;
        subComponent.props = [];
        for (let index = 0; index < this.params.value.length; index++) {
          const componentProps = {};
          Object.keys(this.params.subComponent.props).forEach((propName) => {
            const propValueKey = this.params.subComponent.props[propName];
            let propValue;
            // if function we need not fetch the data. we can send it directly as prop
            if (typeof propValueKey !== 'function') {
              propValue = this.params.data[propValueKey][index];
            } else {
              propValue = propValueKey(this.params, index);
            }
            componentProps[propName] = propValue;
          });
          subComponent.props.push(componentProps);
        }
      }
      return subComponent;
    }
  }
};
</script>
