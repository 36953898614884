<template>
  <div class="u-display-flex u-flex-direction-column">
    <div
      class="u-display-flex u-flex-direction-column u-spacing-mb-m u-spacing-ph-l u-bg-color-grey-white sticky u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'po-fillrate-filters'"
        :state="filterState"
        :loader-status="loaderStatus"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="false"
        :new-date="true"
        :allow-date-compare="true"
        :max-date="getPoFillPageWiseMinMaxKey"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :default-date-post-range="defaultDatePostRange"
        :additional-ranges="additionalRanges"
        :date-mode="dateMode"
        :on-create="onCreate"
        :disable-between-operator="disableBetweenOperator"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'PO Fill Rate'" />
        </div>
      </rb-filter-panel>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
        :apply-negative-margin="true"
      />
    </div>

    <section class="u-envelop-width u-width-100">
      <div class="u-display-flex u-flex-wrap-yes u-spacing-mh-m">
        <card-widget
          class="u-spacing-mr-m u-min-width-25"
          :config="config.widgets['widget1']"
          :data="
            getInsightsPOFillRateData[config.widgets['widget1'].meta.key] || {}
          "
          data-cy="PO-Fill-Rate-Card"
        >
          <div slot="body">
            <metric
              :size="'xxl'"
              class="u-display-inline-flex u-spacing-mh-l u-spacing-mb-m"
              :config="config.widgets['widget1'].body.tagConfig"
              :data="
                (
                  getInsightsPOFillRateData[
                    config.widgets['widget1'].meta.key
                  ] || {}
                ).metrics || {}
              "
            />
          </div>
        </card-widget>
        <card-widget
          class="u-min-width-30 u-spacing-mr-m"
          :config="config.widgets['widget2']"
          :data="
            getInsightsPOFillRateData[config.widgets['widget2'].meta.key] || {}
          "
          data-cy="Unfulfilled-Revenue"
        >
          <div slot="body">
            <metric
              :size="'xxl'"
              class="u-display-inline-flex u-spacing-mh-l u-spacing-mb-m"
              :config="config.widgets['widget2'].body.tagConfig"
              :data="
                (
                  getInsightsPOFillRateData[
                    config.widgets['widget2'].meta.key
                  ] || {}
                ).metrics || {}
              "
            />
          </div>
        </card-widget>
        <card-widget
          class="u-flex-1"
          :config="config.widgets['widget3']"
          :data="
            getInsightsPOFillRateData[config.widgets['widget3'].meta.key] || {}
          "
        >
          <div slot="body">
            <div
              class="u-flex-1 u-display-flex u-flex-direction-column u-spacing-ph-l u-flex-justify-content-flex-end"
            >
              <div
                v-for="(i, index) in (
                  getInsightsPOFillRateData[
                    config.widgets['widget3'].meta.key
                  ] || {}
                ).data"
                class="u-border-radius-l u-spacing-mb-s"
                :style="{
                  background: getColorPattern[index + 1],
                  height: '8px',
                  width: i['perc'] * 100 + '%'
                }"
              />
              <div class="u-spacing-mv-s">
                <span
                  v-for="(i, index) in (
                    getInsightsPOFillRateData[
                      config.widgets['widget3'].meta.key
                    ] || {}
                  ).data"
                  :class="{ 'u-spacing-pl-s': index !== 0 }"
                  class="u-display-inline-flex u-flex-align-items-center u-font-size-5"
                >
                  <rb-icon
                    :style="{ color: getColorPattern[index + 1] }"
                    :icon="'dot'"
                  />
                  <span
                    >{{ i.text
                    }}<span> ( {{ i.units | num_format }} )</span></span
                  >
                </span>
              </div>
            </div>
          </div>
        </card-widget>
      </div>
      <div class="u-display-flex u-flex-wrap-yes u-spacing-mh-m">
        <card-widget
          class="u-flex-1 u-overflow-auto u-spacing-mv-m"
          :config="config.widgets['widget4']"
          :data="
            getInsightsPOFillRateData[config.widgets['widget4'].meta.key] || {}
          "
          data-cy="SKU-Level-Summary"
        >
          <div slot="body">
            <div>
              <rb-insights-table
                ref="SKU_Level_Summary_Table"
                data-c-y-i-d="SKU-Level-Summary"
                :config="config.widgets['widget4']"
                :grid-options="widget4GridOptions"
                :table-row="
                  (
                    getInsightsPOFillRateData[
                      config.widgets['widget4'].meta.key
                    ] || {}
                  ).rows
                "
                :table-column="config.widgets['widget4'].body.columnDefs"
                style="width: 100%"
                :row-height="80"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="true"
                :pagination="true"
                :pagination-total-key="
                  (
                    getInsightsPOFillRateData[
                      config.widgets['widget4'].meta.key
                    ] || {}
                  ).totalRows
                "
                :pagination-per-page-limit="
                  config.widgets['widget4'].body.APIConfig.limit
                "
                :download-this-table="true"
              />
            </div>
          </div>
        </card-widget>
      </div>
      <div class="u-display-flex u-flex-wrap-yes u-spacing-mh-m">
        <card-widget
          class="u-flex-1 u-spacing-mb-m u-width-100"
          :config="config.widgets['widget5']"
          :data="
            getInsightsPOFillRateData[config.widgets['widget5'].meta.key] || {}
          "
        >
          <div
            slot="body"
            class="u-min-height-100"
          >
            <rb-select
              v-if="
                (
                  getInsightsPOFillRateData[
                    config.widgets['widget5'].meta.key
                  ] || {}
                ).load === false && selectedAsin
              "
              :search-enabled="true"
              :client-search="false"
              :search-listener="asinListener"
              :width="'400px'"
              class="u-spacing-mh-m u-spacing-mb-m u-width-80 asin_select"
              :send-details="true"
              :on-close="asinSelected"
              :options="getInsightsPOFillFilterValues['asin']"
              :context="config.widgets['widget5']"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-cursor-pointer"
              >
                <SkuComponentWithBadge
                  :image-src="selectedAsin.image_url"
                  :asin-id="selectedAsin.asin"
                  :asin-title="selectedAsin.title"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <div
                    style="height: 48px; width: 48px; margin: 0px"
                    class="media__img u-flex-none"
                  >
                    <img
                      style="height: 48px; width: 48px; margin: 0px"
                      :src="option.image_url"
                    />
                  </div>
                  <div class="u-spacing-ml-m u-overflow-hidden">
                    <p
                      class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                    >
                      {{ option.title }}
                    </p>
                    <p
                      class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
                    >
                      {{ option.asin }}
                    </p>
                  </div>
                </div>
              </template>
            </rb-select>
            <div>
              <rb-insights-table
                ref="PO_Details_Table"
                :config="widget5Config"
                :grid-options="widget5GridOptions"
                :table-row="
                  (getInsightsPOFillRateData[widget5Config.meta.key] || {}).rows
                "
                :table-column="widget5Config.body.columnDefs"
                :get-current-instance="widget5RbInsightsTableInstanceFunc"
                style="width: 100%"
                :row-height="45"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="true"
                :pagination="true"
                :pagination-total-key="
                  (getInsightsPOFillRateData[widget5Config.meta.key] || {})
                    .totalRows
                "
                :pagination-per-page-limit="widget5Config.body.APIConfig.limit"
                :download-this-table="true"
              />
            </div>
          </div>
        </card-widget>
      </div>
    </section>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import cardWidget from '@/components/widgets/cardWidget';
import metric from '@/components/basic/metric';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
import rbPagination from '@/components/basic/pagination';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import SkuComponentWithBadge from '@/components/basic/SkuComponentWithBadge.vue';
import Vue from 'vue';
const oObject = {
  mixins: [widgetsUtil],
  created() {
    // this.$store.dispatch('getPOFillRateFilters');
    this.config = widgetsConfig.config(this);
    this.fetchFilters('po_fill_rate_v2');
    this.widget5Config = widgetsConfig.config(this).widgets.widget5;
    if (this.widget5Config.meta.alternateAction) {
      this.widget5Config.meta.action = this.widget5Config.meta.alternateAction;
    }
  },
  computed: {
    getInsightsPOFillFilterValues() {
      return this.$store.getters.getInsightsPOFillFilterValues;
    },
    getInsightsPOFillRateData() {
      this.poDetailsTableData =
        (
          this.$store.getters.getInsightsPOFillRateData[
            this.config.widgets.widget4.meta.key
          ] || {}
        ).rows || [];

      if (this.poDetailsTableData.length === 0) {
        this.asinSelected(null, null);
      }
      return this.$store.getters.getInsightsPOFillRateData;
    },
    getPoFillPageWiseMinMaxKey() {
      return this.$store.getters.getPoFillPageWiseMinMaxKey;
    }
  },
  data() {
    return {
      widget5Config: {},
      poDetailsTableData: [],
      selectedAsin: {},
      filterState: {
        getter: 'getInsightsPOFillRateSelectedFilter',
        setter: 'setInsightsPOFillRateSelectedFilter'
      },
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      widget5RbInsightsTableInstance: {},
      widget4GridOptions: {
        context: {
          componentParent: this
        }
      },
      additionalRanges: {
        add: ['last7Days', 'last4Week', 'last13Week', 'last52Week'],
        order: {
          last7Days: 0,
          last4Week: 2,
          last13Week: 3,
          last52Week: 4
        }
      },
      widget5GridOptions: {
        context: {
          componentParent: this
        }
      },
      defaultDatePostRange: 'last7Days',
      dateMode: {
        mode: 'week',
        count: 0
      },
      disableBetweenOperator: false,
      filterInstance: null,
      showActiveFilterBanner: false
    };
  },
  watch: {
    poDetailsTableData: function (val) {
      if (val.length > 0) {
        this.asinSelected(null, val);
      }
    }
  },
  methods: {
    onCreate(instance) {
      this.filterInstance = instance;
    },
    widget5RbInsightsTableInstanceFunc(that) {
      // Object Instance of the rbInsightsTable for widget5 to access its functions. eg: clearSort()
      this.widget5RbInsightsTableInstance = that;
    },
    asinListener(searchTerm, context) {
      context.meta.localFilters = [
        {
          dimensionName: 'search',
          dimensionValue: searchTerm,
          operator: 'ILIKE'
        }
      ];
      context.meta.from = 'search';
      this.$store.dispatch(context.meta.action, context);
    },
    showPODetails(that) {
      this.asinSelected(null, [that.params.data]);
      const footers = document.getElementsByClassName('card-footer');
      if (footers) {
        footers[footers.length - 1]?.scrollIntoViewIfNeeded();
      }
    },
    asinSelected(context, selection) {
      if (selection && selection.length > 0) {
        // while asin selected clear the previous sorted state of the columns.
        this.widget5RbInsightsTableInstance.clearSort();
        const dimensionNameValueList = [
          {
            dimensionName: 'asin',
            dimensionValue: selection[0].asin
          }
        ];
        this.widget5Config.meta.localFilters = dimensionNameValueList;
        this.config.widgets.widget5.header.actions.download.fileName = `PO Fill Rate - PO Details for ASIN ${selection[0]?.asin}`;
        // To Solve PROD-71575 Downloaded PO Details File has all the rows.
        this.config.widgets.widget5.meta.localFilters = dimensionNameValueList;

        this.widget5Config.body.APIConfig.page = 1;
        this.selectedAsin = selection[0];
        this.$store.dispatch('getPODetailsTableData', this.widget5Config);
      } else if (
        this.getInsightsPOFillFilterValues.asin &&
        this.getInsightsPOFillFilterValues.asin.length === 0
      ) {
        this.selectedAsin = null;
      }
    },
    sortTable(column) {
      if (column && column.column) {
        const widget = column.column.columnKey;
        let sortOrder = this.config.widgets[widget].body.APIConfig.orderByList;

        let currentOrder = null;
        if (column.order !== null) {
          currentOrder = column.order === 'ascending' ? 'ASC' : 'DESC';
          sortOrder = [
            {
              dimension: column.prop,
              direction: currentOrder
            }
          ];
        }
        const defaultSortOrder =
          this.config.widgets[widget].body.defaultOrderBy || [];
        this.config.widgets[widget].body.APIConfig.orderByList =
          sortOrder.concat(defaultSortOrder);
        this.config.widgets[widget].body.APIConfig.page = 1;
        let action = this.config.widgets[widget].meta.action;
        if (this.config.widgets[widget].meta.alternateAction) {
          action = this.config.widgets[widget].meta.alternateAction;
        }
        this.$store.dispatch(action, this.config.widgets[widget]);
      }
    },
    appendToolTip(h, { column, $index }) {
      const widget = column.columnKey;
      const tooltipTexts = this.config.widgets[widget].body.tooltipTexts || [];
      return renderColumnHeaders.renderHeader(
        h,
        column,
        true,
        tooltipTexts[$index]
      );
    }
  },
  components: {
    chart,
    loader,
    rbPagination,
    cardWidget,
    metric,
    rbFilterPanel,
    SkuComponentWithBadge
  }
};

export default oObject;
</script>
<style lang="css">
.el-table-column_1-vertical-align .el-table__row td[rowspan] {
  vertical-align: baseline !important;
  border-right: solid 1px #eee;
}
</style>
