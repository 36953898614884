import HttpService from '@/utils/services/http-service';
import {
  keywordAdditionSuccessObject,
  fewKeywordAdditionFailureObject,
  keywordAdditionFailureObject,
  getSnackbarMessageObjectForKeywordAddition
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';

const generateMatchTypeRows = (row, matchType, showKeyword) => {
  const tempRow = JSON.parse(JSON.stringify(row));
  const tempKeyword = tempRow.keyword ? tempRow.keyword.toLowerCase() : null;
  tempRow.key = tempKeyword + '&' + matchType;
  tempRow.keywordToShow = showKeyword ? tempRow.keyword : ' ';
  tempRow.match_type = matchType;
  tempRow.newBid =
    !Number.isNaN(parseFloat(row.suggestedBid)) &&
    parseFloat(row.suggestedBid) < 1
      ? 1
      : row.suggestedBid;
  return tempRow;
};

export const sbaAddKeywords = (
  context,
  adGroupId,
  campaignType = 'searchBrandsAmplifier',
  LeftTableDefaultTab = null
) => ({
  ui_component: 'customActionPanel',
  props: {
    staticHeaderText: 'Add Keywords',
    widgetsConfig: {
      config: (vueRef) => {
        const widgetConfigs = {
          primaryKey: 'key',
          isMultipleAllowed: false,
          default: {
            noRowsRightTable: 'Add Keywords to this list',
            selections: '',
            noRowsLeftTable: 'No Keywords sources found',
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Error',
            actionInfoText:
              'Minimum of 2 and maximum of 200 keywords can be added to targeting. Adding keywords will renitiate the review process of the campaign.'
          },
          left: {
            altStore: 'walmartCampaignCreation/getKeywords',
            tabs: {
              Suggested: {
                search: {
                  enableSearch: true,
                  placeholder: 'Search',
                  searchKey: 'keyword',
                  isClientSide: true
                },
                localFilter: [
                  {
                    dimensionName: 'adgroup_id',
                    dimensionValue: context?.campaignInfo?.data?.adGroupId
                  }
                ],
                columnDefs: keywordLeftTable(vueRef),
                // search: {
                //   enableSearch: true,
                //   searchKey: 'keyword'
                // },
                showFilterCheckBox: true,
                filterCheckBoxSource: {
                  header: 'Filter by:',
                  defaultSelected: ['Broad', 'Phrase', 'Exact'],
                  items: ['Broad', 'Phrase', 'Exact']
                }
              },
              'Enter List': {
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'keyword',
                    buttonLabel: 'Add keywords',
                    emitTextInput: true,
                    entityCount: 200,
                    emitAtEnd: true
                  },
                  events: {
                    updateTextInput(data) {
                      const keywords = data?.keywordsArray?.filter(
                        (item) => item.keyword.length <= 80
                      );
                      const customBid = data?.ref?.suggestedCustomBidInput;
                      let minBid = 1;
                      let maxBid = 100;
                      if (customBid < minBid || customBid > maxBid) {
                        data?.ref?.$snackbar.open({
                          message: `Please add a bid between ${minBid} and ${maxBid}.`,
                          duration: 6000,
                          buttonColor: '#f5d908',
                          actionText: ''
                        });
                        return;
                      }
                      const addToRightKeywordsTable = [];
                      for (const item of keywords) {
                        addToRightKeywordsTable.push({
                          suggestedBid: '-',
                          newBid: customBid,
                          keyword: item.keyword,
                          keywordToShow: item.keyword,
                          key: item.key,
                          match_type: item.match_type
                        });
                      }
                      const invalidKeywords = new Set();
                      const filteredInvalidKeywords = data?.keywordsArray
                        .filter(({ keyword }) => keyword.length > 80)
                        ?.map?.((item) => item.keyword);
                      filteredInvalidKeywords.forEach((keyword) => {
                        invalidKeywords.add(keyword);
                      });
                      if (invalidKeywords.size > 0) {
                        data?.ref?.$snackbar.open({
                          message:
                            'The length of the keyword(s) should be within 80 characters.',
                          duration: 6000,
                          buttonColor: '#f5d908',
                          actionText: ''
                        });
                      }
                      data.ref.addEntityFromRawObjectArray(
                        addToRightKeywordsTable
                      );
                      data.enterListRef.keywordTextInput = invalidKeywords.size
                        ? Array.from(invalidKeywords)?.join?.('\n')
                        : '';
                    }
                  }
                },
                leftCustomBidValues: [
                  {
                    title: 'Custom Bid',
                    value: 'custom_bid',
                    input: 'currency'
                  }
                ],
                showLeftCustomBid: true,
                showFilterCheckBox: true,
                filterCheckBoxSource: {
                  header: 'Filter by:',
                  items: ['Broad', 'Phrase', 'Exact']
                }
              }
            },
            isColumnDefAutoConfigure: true,
            isClientSidePagination: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'walmartCampaignCreation/fetchKeywords',
              adGroupId: adGroupId,
              updateRows: (vueRef, searchKey, searchText) => {
                const newRows = [];
                let fliterBroad = false;
                let filterPhrase = false;
                let filterExact = false;
                const checkBoxValues = vueRef.leftFilterCheckBoxArray
                  .toString()
                  .toLowerCase();
                fliterBroad = checkBoxValues.includes('broad');
                filterPhrase = checkBoxValues.includes('phrase');
                filterExact = checkBoxValues.includes('exact');
                if (
                  (!filterPhrase && !filterExact && !fliterBroad) ||
                  (filterPhrase && filterExact && fliterBroad)
                ) {
                  filterPhrase = true;
                  filterExact = true;
                  fliterBroad = true;
                }
                vueRef.leftTableData.rows.forEach((row) => {
                  if (fliterBroad) {
                    const broad = generateMatchTypeRows(
                      row,
                      'broad',
                      fliterBroad
                    );
                    newRows.push(broad);
                  }
                  if (filterPhrase) {
                    const phrase = generateMatchTypeRows(
                      row,
                      'phrase',
                      !fliterBroad
                    );
                    newRows.push(phrase);
                  }
                  if (filterExact) {
                    const exact = generateMatchTypeRows(
                      row,
                      'exact',
                      !fliterBroad && !filterPhrase && !fliterBroad
                    );
                    newRows.push(exact);
                  }
                });
                vueRef.leftTableRows = newRows;
                if (searchKey && searchText) {
                  vueRef.leftTableRows = vueRef.leftTableRows.filter((row) =>
                    row[searchKey]
                      .toLowerCase()
                      .includes(searchText?.toLowerCase())
                  );
                }
              },
              localFilters: [
                {
                  dimensionName: 'adgroup_id',
                  dimensionValue: context?.campaignInfo?.data?.adGroupId
                }
              ]
            },
            footer: {
              show: false
            },
            body: {
              useExistingWhereClause: true,
              APIConfig: {
                page: 1,
                limit: 200
              },
              columnDefs: keywordLeftTable(vueRef),
              rowClassRules: {
                ' hideBorderAddKeywordsToCampaigns': function (params) {
                  if (params.data.keywordToShow === ' ') {
                    return true;
                  }
                  return false;
                }
              }
            },
            headerText: 'Add Keywords'
          },
          right: {
            routeData: context.$route,
            type: 'KEYWORDS',
            fetchAll: true,
            altStore: 'entityDetails/getReviewStatusData',
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            keepFetchedRightTableRows: true,
            meta: {
              type: 'table',
              action: 'entityDetails/fetchReviewStatusData',
              localFilters: [
                {
                  dimensionName: 'ad_group_id',
                  dimensionValue: context.entityId,
                  operator: 'EQUAL_TO'
                }
              ],
              updateRows: (vueRef, rows = []) => {
                const newRows = [];
                for (let item of rows) {
                  if (item.status === 'rejected') {
                    continue;
                  }
                  item.keywordToShow = item.keywordText;
                  item.keyword = item.keywordText;
                  item.match_type = item.matchType;
                  item.key = item.keywordToShow + '&' + item.match_type;
                  item.newBid = item.bid;
                  item.suggestedBid = '-';
                  item.disabled = true;
                  item.toolTipText =
                    'Update the bids for existing keywords from the Adjust bids action';
                  newRows.push(item);
                }
                vueRef.fetchedRightTableRows = {
                  rows: newRows,
                  totalRows: vueRef.rightTableData.totalRows
                };
                vueRef.rightTableRows = newRows;
                vueRef.updateEntityInformation();
              }
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                cubeName: 'walmart_campaigns_keyword_workbench_v2',
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: ['walmart_cm_keyword_bid'],
                groupByDimensionsList: [
                  'keyword_id',
                  'match_type',
                  'keyword_text'
                ],
                orderByList: [],
                limit: 50,
                page: 1
              },
              columnDefs: keywordRightTable(vueRef)
            },
            headerText: () => `Added Keywords (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {
            rows = rows.filter(
              (row) =>
                vueRef.fetchedRightTableRows.rows.findIndex(
                  (fetchedRow) =>
                    fetchedRow.key === row.key &&
                    row.newBid === fetchedRow.newBid
                ) === -1
            );
            const adgroupId = context?.entityId;
            const campaignId = context.parentEntityId;
            const campaignType = context.entityConfig.entityType;
            return !rows.length
              ? vueRef.openSnackbar('No keywords were added/modified')
              : new Promise((resolve, reject) => {
                  const payload = rows.map((row) => ({
                    actionType: 'walmartAddKeywordToCampaigns',
                    actionSource: {
                      pageUrl: window.location.href
                    },
                    widget: 'campaign',
                    primaryKey: campaignId,
                    actionPayload: {
                      campaign_id: campaignId,
                      campaignType,
                      ad_group_id: adgroupId,
                      new_keyword_text: row.keyword,
                      new_match_type: row.match_type,
                      new_bid: row.newBid,
                      state: 'enabled',
                      status: 'pending',
                      advertiser_id: context?.$route?.query?.advertiser_id
                    },
                    viewPayload: {
                      campaignId,
                      campaignType,
                      adgroupId,
                      adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
                      new_keyword_text: row.keyword,
                      new_match_type: row.match_type,
                      new_bid: row.newBid
                    }
                  }));
                  HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                    .then((response) => {
                      if (response?.data?.success) {
                        const arrayToTraverse = response?.data?.response;
                        let messageObject =
                          getSnackbarMessageObjectForKeywordAddition(
                            arrayToTraverse,
                            {
                              allSuccessObject: keywordAdditionSuccessObject,
                              someFailedObject: fewKeywordAdditionFailureObject,
                              allFailedObject: keywordAdditionFailureObject
                            }
                          );
                        context.$snackbar.open(messageObject);
                      } else {
                        context.$snackbar.open(keywordAdditionFailureObject);
                      }
                      vueRef.$emit('afterAPIActionToCall');
                      resolve(true);
                    })
                    .catch((err) => {
                      context.$snackbar.open(keywordAdditionFailureObject);
                      reject(err);
                    });
                });
          },
          applyButtonDisabled() {
            const arrayToTraverse = vueRef.computedRightTableRows;
            for (const i in arrayToTraverse) {
              const newBid = arrayToTraverse[i]?.newBid;
              if (
                !newBid ||
                parseFloat(newBid) < 1 ||
                parseFloat(newBid) > 100
              ) {
                context.showErrorMessage =
                  'Bid should be between $1 and $100, both inclusive.';
                return true;
              }
            }
            const addedKeywords = vueRef.computedRightTableRows.map(
              (item) => item?.keyword
            );
            const distinctKeyords = new Set();
            addedKeywords.forEach((keyword) => distinctKeyords.add(keyword));
            if (distinctKeyords.size > 200) {
              context.showErrorMessage =
                'An Ad Group can have a maximum of 200 distinct keywords with 1-3 match types.';
              return true;
            }
            context.showErrorMessage = null;
            const selections = arrayToTraverse.filter(
              (row) =>
                vueRef.fetchedRightTableRows.rows.findIndex(
                  (fetchedRow) => fetchedRow.key === row.key
                ) === -1
            );
            return !vueRef.rightTableRows.length || !selections.length;
          }
        };
        if (LeftTableDefaultTab) {
          widgetConfigs.left.defaultTab = LeftTableDefaultTab;
        }
        return widgetConfigs;
      }
    }
  },
  events: {
    afterAPIActionToCall(payload) {
      context.showAddCustomKeywordPanel = false;
      context.reloadComponent(true);
    },
    closePanelMethod() {
      context.showAddCustomKeywordPanel = false;
    }
  }
});

const keywordLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keywordToShow',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keywordToShow',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'suggestedBid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggestedBid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'score',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword score',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'score',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

const keywordRightTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Suggested bid',
      type: 'custom',
      uiField: {
        widget: 'icon',
        uiLabel: 'Suggested bid',
        uiType: 'custom',
        uiOrder: '3',
        metadata: {
          toolTipText: 'Set suggested bid',
          widget: 'icon',
          type: 'iconText',
          tableColumnName: 'suggestedBid',
          formatType: 'currency',
          displayIcon: 'trending-neutral',
          showOnUi: true,
          isFixed: false,
          width: 90,
          iconClickEvent: (context, event) => {
            const rowNodes = {
              rowNodes: [context.params.node]
            };
            if (!context.params.data.newBid) {
              context.params.data.newBid =
                !Number.isNaN(parseFloat(context.params.data.suggestedBid)) &&
                parseFloat(context.params.data.suggestedBid) < 1
                  ? 1
                  : context.params.data.suggestedBid;
            }
            vueRef.rightTableInstance.redrawTableRows(rowNodes);
          }
        }
      }
    },
    {
      name: 'newBid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '4',
        uiType: 'custom',
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'newBid',
          defaultValueColumnName: 'newBid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 100,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validation should happen here
            context.params.data.newBid = context.bidValue;
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '5',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};
