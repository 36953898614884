<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-p-m"
  >
    <div class="u-display-flex">
      <rb-icon
        icon="spotlight"
        class="rb-icon--large u-color-grey-lighter"
      />
      <p
        class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s u-spacing-pl-s"
      >
        Spotlights
      </p>
    </div>
    <section class="u-display-flex spotlight-card-container">
      <div
        v-for="spot in spotlights"
        class="spotlight-card"
        :class="[`spotlight-${spot.type}`]"
      >
        <div class="u-display-flex">
          <rb-icon
            :icon="spot.icon"
            class="u-color-white-base"
            :class="spot.iconClasses"
          />
          <p
            class="u-font-size-2 u-font-weight-600 u-color-grey-lighter spotlight-title"
          >
            {{ spot.title }}
          </p>
        </div>
        <div
          v-for="spotAction in spot.actionItems"
          class="spotlight-action-container"
        >
          <div>
            <rb-icon
              :icon="spotAction.icon"
              :class="spotAction.iconClasses"
            />
          </div>
          <div>
            <label class="u-font-size-3 u-font-weight-600 u-color-grey-light">
              {{ spotAction.action }}
            </label>
            <p class="u-font-size-5 u-color-grey-light u-spacing-pt-xs">
              {{ spotAction.actionDescription }}
            </p>
            <div
              class="link u-display-flex u-flex-align-items-center u-spacing-pt-m"
              @click="onSpotlightAction(spotAction, spot.type)"
            >
              <span class="u-font-size-4 u-spacing-pr-s">
                {{
                  spot.type === SPOTLIGHT_TYPE.NEGATIVE ? 'Fix it' : 'View it'
                }}
              </span>
              <div class="arrow_forward">
                <rb-icon
                  icon="arrow_back"
                  class="rb-icon--small"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="dummy-carousel u-display-flex u-flex-justify-content-center u-spacing-mt-xxl"
        >
          <div
            v-for="i in [1, 2, 3, 4]"
            class="dot u-bg-color-grey-x-light u-spacing-m-xs"
          />
        </div>
      </div>
    </section>
    <email
      :show-email-modal="showEmail"
      :email-details="emailDetails"
      @onSendEamil="raiseTicket"
      @onCancelEmail="cancelRaiseTicket"
    />
  </div>
</template>

<script>
import email from '@/pages/all_retailers/dso/molecules/email.vue';
import { SKU_STATE } from '@/pages/all_retailers/dso/constant';

export default {
  name: 'Spotlight',
  components: {
    email
  },
  props: {
    spotlights: {
      type: Array,
      default() {
        return [];
      }
    },
    isSendEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEmail: false,
      SPOTLIGHT_TYPE: SKU_STATE,
      selectedSpotLightDetails: {},
      emailDetails: {}
    };
  },
  methods: {
    showEmailModal() {
      this.showEmail = true;
    },
    onSpotlightAction(spotLightDetails, spotlightType) {
      if ([this.SPOTLIGHT_TYPE.NEGATIVE].includes(spotlightType)) {
        this.selectedSpotLightDetails = spotLightDetails;
        if (this.isSendEmail) {
          this.emailDetails = {
            to: 'vendor.manager@amazon.co',
            from: 'client@commerceiq.ai',
            body: `We identified the following ${this.selectedSpotLightDetails.actionDescription}. Please address the respective issue. Kindly provide the updated status of the open items/ in-progress items.`
          };
          this.showEmail = true;
        } else {
          this.$emit('onSpotlightClick', this.selectedSpotLightDetails);
        }
      }
    },
    raiseTicket() {
      this.showEmail = false;
      this.notify();
    },
    cancelRaiseTicket() {
      this.showEmail = false;
    },
    notify() {
      this.$snackbar.open({
        message: 'Ticket Raised',
        duration: 5000,
        buttonColor: '#007CF6',
        actionText: '',
        customStyles: 'background: #007cf6'
      });
    }
  }
};
</script>

<style scoped>
.spotlight-card-container {
  gap: 2rem;
  margin-top: 1rem;
  justify-content: center;
  line-height: 2rem;
}

.shadows {
  box-shadow: 2px 1px 8px 0 #e9eaeb;
}
.spotlight-card {
  padding: 3rem;
  flex-grow: 1;
  border-radius: 4px;
}
.spotlight-positive {
  background-color: rgba(62, 169, 94, 0.05);
  border: 1px solid #d3f9d8;
}

.spotlight-improved {
  background: rgba(255, 168, 0, 0.12);
  border: 1px solid #fef0c5;
}
.spotlight-negative {
  background: rgba(215, 38, 61, 0.05);
  border: 1px solid #ffe3e3;
}

.spotlight-title {
  padding-left: 1rem;
  padding-top: 0.2rem;
}
.spotlight-action-container {
  display: flex;
  gap: 1rem;
  padding-top: 4rem;
}
.arrow_forward {
  transform: rotate(180deg);
  margin-bottom: 5px;
}
.link:hover {
  text-decoration: none;
}
</style>
