import {
  getDataFromRowNode,
  payloadGenerator,
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common.js';
import HttpService from '@/utils/services/http-service';
import metricsDictionary, {
  retailerSpecificMetrics
} from '@/pages/campaign-management/retailers/dictionary.js';
import {
  commonTableActionIcons,
  baseChartConfig,
  commonChartActionIcons,
  additionalDateRanges,
  campaignsEditStatusActionPayloadKeys,
  gridOptions,
  failedItemsToggleIconConfig,
  FAILED_ITEMS_TOGGLE_ID
} from '@/pages/campaign-management/constants.js';
import { cloneDeep } from 'lodash';
import actionPanelConfigs from '@/pages/campaign-management/retailers/walmart/campaigns/addKeywordsPanelWidget.js';
import chartMetrics from '@/pages/campaign-management/retailers/walmart/campaigns/waterfall-structure.js';
import {
  componentBelowFilterConfigItems,
  systemTransparencyBannerConfig
} from '@/pages/campaign-management/retailers/walmart/utils.js';
import { getWidgetConfigForAddSkus } from '@/pages/campaign-management/retailers/walmart/campaigns/addSkusConfig.js';
import {
  getRealtimeUpdateAttributes,
  isRealTimeUpdateEnabled,
  fetchRealtimeTableData
} from '@/pages/campaign-management/store';

import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer.js';
import transformer from '@/utils/services/data-transformer';
const WIDGET = 'campaign';
const PRIMARY_KEY = 'campaign_id';
const SB_CAMPAIGN_TYPES = ['sba', 'sb'];

export const incrementalMetricsForTables = {
  iroas: {
    title: 'IROAS',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    keyOrder: 10,
    table: {
      show: true
    },
    toolTipText:
      'Incremental Sales generated for each dollar of ad spend. This is an ML-driven metric that is currently in beta.'
  },
  incremental_fraction: {
    title: 'Incremental Fraction',
    type: 'NUMERIC',
    unit: 'PERCENTAGE',
    keyOrder: 11,
    table: {
      show: true
    },
    toolTipText:
      'Estimated share of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.'
  },
  incremental_sales: {
    title: 'Incremental Sales',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    keyOrder: 12,
    table: {
      show: true
    },
    toolTipText:
      'Amount of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.'
  },
  incremental_fraction_band: {
    title: 'Incrementality Band',
    type: 'String',
    keyOrder: 13,
    table: {
      show: true
    },
    toolTipText:
      "Classification of performance on the basis of incrementality. 'High' band implies majority of paid sales are incremental and ‘Low’ band implies that majority of sales are not incremental."
  }
};
function getRetailerSpecificAdditionalMetrics(retailer, commonMetrics) {
  const retailerMetricMap = {
    walmart: [
      ...commonMetrics,
      ...retailerSpecificMetrics[retailer].map(
        (metric) => `aggregated_${metric}`
      )
    ],
    samsclub_api: [
      ...commonMetrics,
      ...retailerSpecificMetrics[retailer].map(
        (metric) => `aggregated_${metric}`
      ),
      'aggregated_total_attributed_direct_click_sales',
      'aggregated_total_attributed_related_click_sales',
      'aggregated_total_attributed_brand_click_sales'
    ]
  };
  return retailerMetricMap?.[retailer] || [];
}

function isRetailerWalmartOrSamsclub(retailer) {
  return ['samsclub_api', 'walmart'].includes(retailer);
}

const request = (retailer) => {
  const incrementalityFractionMeasureList = [
    'iroas',
    'incremental_fraction',
    'incremental_sales',
    'incrementality_ad_spend',
    'incrementality_total_attributed_sales',
    'ad_spend',
    'total_attributed_sales'
  ];

  return {
    cubeName: `${retailer}_campaigns_campaigns_workbench`,
    measuresList: [
      'aggregated_clicks',
      'platform_desktop_impressions',
      'platform_mobile_impressions',
      'platform_app_impressions',
      'platform_others_impressions',
      'placement_buy_box_impressions',
      'placement_search_in_grid_impressions',
      'placement_home_page_impressions',
      'placement_stock_up_impressions',
      'placement_browse_in_grid_impressions',
      'placement_carousel_impressions',
      'placement_others_impressions',
      'platform_desktop_units_sold',
      'platform_mobile_units_sold',
      'platform_app_units_sold',
      'platform_others_units_sold',
      'platform_desktop_roas_14d',
      'platform_mobile_roas_14d',
      'platform_app_roas_14d',
      'platform_others_roas_14d',
      'platform_desktop_acos_14d',
      'platform_mobile_acos_14d',
      'platform_app_acos_14d',
      'platform_others_acos_14d',
      'platform_desktop_cpc',
      'platform_mobile_cpc',
      'platform_app_cpc',
      'platform_others_cpc',
      'platform_desktop_ad_spend',
      'platform_mobile_ad_spend',
      'platform_app_ad_spend',
      'platform_others_ad_spend',
      'platform_desktop_clicks',
      'platform_mobile_clicks',
      'platform_app_clicks',
      'platform_others_clicks',
      'platform_desktop_ctr',
      'platform_mobile_ctr',
      'platform_app_ctr',
      'platform_others_ctr',
      'platform_desktop_conversion_rate',
      'platform_mobile_conversion_rate',
      'platform_app_conversion_rate',
      'platform_others_conversion_rate',
      'platform_mobile_total_attributed_sales',
      'platform_desktop_total_attributed_sales',
      'platform_app_total_attributed_sales',
      'platform_others_total_attributed_sales',
      'platform_desktop_total_attributed_direct_click_sales',
      'platform_mobile_total_attributed_direct_click_sales',
      'platform_app_total_attributed_direct_click_sales',
      'platform_others_total_attributed_direct_click_sales',
      'platform_desktop_total_attributed_related_click_sales',
      'platform_mobile_total_attributed_related_click_sales',
      'platform_app_total_attributed_related_click_sales',
      'platform_others_total_attributed_related_click_sales',
      'platform_desktop_total_attributed_brand_click_sales',
      'platform_mobile_total_attributed_brand_click_sales',
      'platform_app_total_attributed_brand_click_sales',
      'platform_others_total_attributed_brand_click_sales',
      'campaign_name',
      'advertiser_name',
      'start_date',
      'end_date',
      'aggregated_roas_14d',
      'aggregated_acos_14d',
      'aggregated_conversion_rate',
      'aggregated_units_sold',
      'aggregated_ctr',
      'aggregated_cpa',
      'aggregated_cpc',
      'aggregated_ad_spend',
      'aggregated_impressions',
      'aggregated_total_attributed_sales',
      'campaign_type',
      'targeting_type',
      'status',
      'placement_buy_box_clicks',
      'placement_search_in_grid_clicks',
      'placement_home_page_clicks',
      'placement_stock_up_clicks',
      'placement_browse_in_grid_clicks',
      'placement_carousel_clicks',
      'placement_others_clicks',
      'placement_buy_box_total_attributed_sales',
      'placement_search_in_grid_total_attributed_sales',
      'placement_home_page_total_attributed_sales',
      'placement_stock_up_total_attributed_sales',
      'placement_browse_in_grid_total_attributed_sales',
      'placement_carousel_total_attributed_sales',
      'placement_others_total_attributed_sales',
      'placement_search_in_grid_total_attributed_direct_click_sales',
      'placement_buy_box_total_attributed_direct_click_sales',
      'placement_home_page_total_attributed_direct_click_sales',
      'placement_stock_up_total_attributed_direct_click_sales',
      'placement_carousel_total_attributed_direct_click_sales',
      'placement_browse_in_grid_total_attributed_direct_click_sales',
      'placement_others_total_attributed_direct_click_sales',
      'placement_search_in_grid_total_attributed_related_click_sales',
      'placement_buy_box_total_attributed_related_click_sales',
      'placement_home_page_total_attributed_related_click_sales',
      'placement_stock_up_total_attributed_related_click_sales',
      'placement_carousel_total_attributed_related_click_sales',
      'placement_browse_in_grid_total_attributed_related_click_sales',
      'placement_others_total_attributed_related_click_sales',
      'placement_search_in_grid_total_attributed_brand_click_sales',
      'placement_buy_box_total_attributed_brand_click_sales',
      'placement_home_page_total_attributed_brand_click_sales',
      'placement_stock_up_total_attributed_brand_click_sales',
      'placement_carousel_total_attributed_brand_click_sales',
      'placement_browse_in_grid_total_attributed_brand_click_sales',
      'placement_others_total_attributed_brand_click_sales',
      'placement_search_in_grid_conversion_rate',
      'placement_buy_box_conversion_rate',
      'placement_home_page_conversion_rate',
      'placement_stock_up_conversion_rate',
      'placement_carousel_conversion_rate',
      'placement_browse_in_grid_conversion_rate',
      'placement_others_conversion_rate',
      'placement_buy_box_ctr',
      'placement_search_in_grid_ctr',
      'placement_home_page_ctr',
      'placement_stock_up_ctr',
      'placement_browse_in_grid_ctr',
      'placement_carousel_ctr',
      'placement_others_ctr',
      'placement_buy_box_cpc',
      'placement_search_in_grid_cpc',
      'placement_home_page_cpc',
      'placement_stock_up_cpc',
      'placement_browse_in_grid_cpc',
      'placement_carousel_cpc',
      'placement_others_cpc',
      'placement_buy_box_ad_spend',
      'placement_search_in_grid_ad_spend',
      'placement_home_page_ad_spend',
      'placement_stock_up_ad_spend',
      'placement_browse_in_grid_ad_spend',
      'placement_carousel_ad_spend',
      'placement_others_ad_spend',
      'placement_buy_box_acos_14d',
      'placement_search_in_grid_acos_14d',
      'placement_home_page_acos_14d',
      'placement_stock_up_acos_14d',
      'placement_browse_in_grid_acos_14d',
      'placement_carousel_acos_14d',
      'placement_others_acos_14d',
      'placement_buy_box_roas_14d',
      'placement_search_in_grid_roas_14d',
      'placement_home_page_roas_14d',
      'placement_stock_up_roas_14d',
      'placement_browse_in_grid_roas_14d',
      'placement_carousel_roas_14d',
      'placement_others_roas_14d',
      'placement_buy_box_units_sold',
      'placement_search_in_grid_units_sold',
      'placement_home_page_units_sold',
      'placement_stock_up_units_sold',
      'placement_browse_in_grid_units_sold',
      'placement_carousel_units_sold',
      'placement_others_units_sold',
      ...getRetailerSpecificAdditionalMetrics(
        retailer,
        incrementalityFractionMeasureList
      )
    ],
    groupByDimensionsList: [
      'campaign_id',
      'advertiser_id',
      'campaign_sub_category',
      'sub_type'
    ],
    timeseriesDimension: 'report_date',
    orderByList: [
      {
        dimension: 'aggregated_ad_spend',
        direction: 'DESC'
      }
    ],
    getLatestAvailableInsteadOfRollup: false,
    getSharePercentage: false,
    enableNewPVPFormulaForSOV: false,
    disableShard: false,
    filterWhereClause: {
      dimensionNameValueList: []
    },
    commonFilterEnabled: false,
    outerWhereClause: {
      dimensionNameValueList: []
    },
    tagEnabled: false,
    whereClauseCombination: [],
    timeseriesEnabled: true,
    pvpenabled: true,
    yoyenabled: false,
    splyenabled: false,
    digitalShelfEnabled: false,
    sharePercentageV2: false,
    where: {
      dimensionNameValueList: [],
      date: {
        from: ':from',
        to: ':to'
      },
      pvpDate: {
        from: ':from',
        to: ':to'
      }
    }
  };
};

const getTableRequest = (retailer) => {
  const retailerMetrics = {
    walmart: ['campaign_review_status', ...retailerSpecificMetrics[retailer]],
    samsclub_api: [
      'campaign_review_status',
      'total_attributed_direct_click_sales',
      'total_attributed_related_click_sales',
      'total_attributed_brand_click_sales',
      ...retailerSpecificMetrics[retailer]
    ],
    // Add other retailers here if necessary
    default: [
      'total_attributed_direct_click_sales',
      'total_attributed_related_click_sales',
      'total_attributed_brand_click_sales',
      ...retailerSpecificMetrics[retailer]
    ]
  };

  const baseMeasuresList = [
    'ad_spend',
    'start_date',
    'end_date',
    'roas_14d',
    'acos_14d',
    'clicks',
    'conversion_rate',
    'units_sold',
    'ctr',
    'cpa',
    'cpc',
    'total_budget',
    'budget_type',
    'daily_budget',
    'impressions',
    'total_attributed_sales',
    'campaign_type',
    'campaign_name',
    'advertiser_name',
    'targeting_type',
    'status',
    'multiplier',
    'multiplier_status'
  ];
  const incrementalityMeasures = [
    'incremental_fraction',
    'iroas',
    'incremental_sales',
    'incremental_fraction_band'
  ];
  const objectToReturn = {
    cubeName: `${retailer}_campaigns_campaigns_workbench`,
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [...baseMeasuresList, ...(retailerMetrics[retailer] || [])],
    groupByDimensionsList: [
      'campaign_id',
      'advertiser_id',
      'sub_type',
      'campaign_sub_category'
    ],
    orderByList: [
      {
        dimension: 'ad_spend',
        direction: 'DESC'
      },
      {
        // required for SBA as ad spend is null in proposal state so order by doesn't work
        dimension: 'campaign_name',
        direction: 'ASC'
      }
    ],
    limit: 30,
    page: 1,
    where: {
      date: {
        from: ':date',
        to: ':date'
      },
      pvpDate: {
        from: ':date',
        to: ':date'
      },
      dimensionNameValueList: [
        {
          dimensionName: 'sub_type',
          dimensionValue: '-'
        }
      ]
    }
  };
  if (retailer === 'walmart') {
    objectToReturn.measuresList = [];
    objectToReturn.groupByDimensionsList = [];
    objectToReturn.primaryDataGroup = 'walmart_cm_campaigns_adv_data';
    objectToReturn.bundleCubeExecutionRequest = {
      walmart_cm_campaigns_adv_data: {
        cubeName: 'walmart_campaigns_campaigns_workbench',
        measuresList: [
          'ad_spend',
          'start_date',
          'end_date',
          'roas_14d',
          'acos_14d',
          'clicks',
          'conversion_rate',
          'units_sold',
          'ctr',
          'cpa',
          'cpc',
          'budget_type',
          'impressions',
          'total_attributed_sales',
          'campaign_type',
          'campaign_name',
          'advertiser_name',
          'targeting_type',
          'multiplier',
          'multiplier_status',
          'campaign_review_status',
          'attributed_orders',
          'advertised_sku_units',
          'other_sku_units',
          'advertised_sku_sales',
          'other_sku_sales',
          'conversion_rate_14d_orders_based',
          'ntb_orders',
          'percentageordersnewtobrand14d',
          'ntb_revenue',
          'percentagesalesnewtobrand14d',
          'ntb_units',
          'percentageunitsnewtobrand14d',
          'conversion_rate_ntb',
          'incremental_fraction',
          'iroas',
          'incremental_sales',
          'incremental_fraction_band'
        ],
        groupByDimensionsList: [
          'campaign_id',
          'advertiser_id',
          'sub_type',
          'campaign_sub_category'
        ],
        orderByList: [],
        timeseriesRollupBy: ':timeseriesRollupBy',
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        disableShard: false,
        getPointInTimeMetrics: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ],
          excludeDedupAxes: []
        },
        commonFilterEnabled: false,
        customAPIDecisionVars: {
          system: 'walmart_campaign_management'
        },
        enableNewPVPFormulaForSOV: false,
        flowResidueEntity: false,
        entityType: 'campaign_id',
        sharePercentageV2: false,
        pvptimeSeriesEnabled: false,
        timeseriesEnabled: false,
        timeseriesDimension: 'report_date',
        yoyenabled: false,
        pvpenabled: false,
        pointInTimeMetrics: false,
        pvptimeseriesEnabled: false,
        splyenabled: false,
        where: {
          date: {
            from: ':date',
            to: ':date'
          },
          pvpDate: {
            from: ':date',
            to: ':date'
          },
          dimensionNameValueList: [
            {
              dimensionName: 'sub_type',
              dimensionValue: '-'
            }
          ]
        },
        tagWhereClause: []
      },
      adv_metadata: {
        cubeName: 'walmart_campaigns_campaign_workbench_metadata',
        measuresList: ['status', 'total_budget', 'daily_budget'],
        groupByDimensionsList: ['campaign_id'],
        orderByList: [],
        timeseriesRollupBy: ':timeseriesRollupBy',
        getLatestAvailableInsteadOfRollup: false,
        getSharePercentage: false,
        disableShard: false,
        getPointInTimeMetrics: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          excludeDedupAxes: []
        },
        commonFilterEnabled: false,
        customAPIDecisionVars: {
          system: 'walmart_campaign_management'
        },
        enableNewPVPFormulaForSOV: false,
        flowResidueEntity: false,
        entityType: 'campaign_id',
        sharePercentageV2: false,
        pvptimeSeriesEnabled: false,
        timeseriesEnabled: false,
        yoyenabled: false,
        pvpenabled: false,
        pointInTimeMetrics: false,
        pvptimeseriesEnabled: false,
        splyenabled: false,
        where: {
          dimensionNameValueList: [],
          tagWhereClause: [],
          date: {},
          pvpDate: {}
        },
        tagWhereClause: []
      }
    };
    objectToReturn.where.dimensionNameValueList = [];
    objectToReturn.customAPIDecisionVars = {};
    objectToReturn.entityType = 'campaign_id';
    objectToReturn.tagWhereClause = [];
    objectToReturn.orderByList = [
      {
        // required for SBA as ad spend is null in proposal state so order by doesn't work
        dimension: 'campaign_name',
        direction: 'ASC'
      }
    ];
  }
  if (retailer === 'samsclub_api') {
    objectToReturn.measuresList = [
      ...objectToReturn.measuresList,
      ...incrementalityMeasures
    ];
  }
  return objectToReturn;
};

const doesFilterAlreadyHaveFailedReviewStatusSelected = (
  currentFilterLocalStorage
) => {
  return currentFilterLocalStorage?.campaign_review_status?.includes('Failed');
};

const getTextAndIconForFailedItemsToggle = (LOCAL_STORAGE_FILTER_KEY) => {
  let subText = 'VIEW ALL CAMPAIGNS';
  let icon = 'campaign_management';
  const currentFilterLocalStorage = {
    ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_KEY))
  };
  const campaignReviewStatusFilter =
    currentFilterLocalStorage?.campaign_review_status || [];
  if (
    campaignReviewStatusFilter.length === 1 &&
    campaignReviewStatusFilter[0] === 'Failed'
  ) {
    subText = 'VIEW ALL CAMPAIGNS';
    icon = 'campaign_management';
  } else {
    subText = 'VIEW FAILED CAMPAIGNS';
    icon = 'campaign';
  }
  return [subText, icon];
};

const removeKeysFromWidgets = (
  metricsObj,
  currentPage,
  currentWidget,
  keysToRemove
) => {
  const clonedMetricsObj = cloneDeep(metricsObj);
  keysToRemove.forEach((key) => {
    const [page = 'campaigns', widget = 'table', metric = ''] = key.split('-');
    if (
      currentPage.includes(page) &&
      currentWidget.includes(widget) &&
      metric
    ) {
      delete clonedMetricsObj[metric];
    }
  });

  return clonedMetricsObj;
};

async function triggerOnCreatedFlow(vueRef, retailer) {
  if (isRetailerWalmartOrSamsclub(retailer)) {
    const response = await HttpLayer.post({
      APIData: {
        cubeName: `${retailer}_cm_banner_campaign_review_status`,
        measuresList: ['latest_campaign_review_status', 'has_failed_recently'],
        groupByDimensionsList: [
          'campaign_id',
          'campaign_name',
          'advertiser_id'
        ],
        where: {
          date: {},
          dimensionNameValueList: [
            {
              dimensionName: 'has_failed_recently',
              operator: 'EQUAL_TO',
              dimensionValue: 'true'
            }
          ]
        },
        orderByList: [],
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false
      }
    });
    let mergedResult = transformer.mergeResultDimension(response.data) || [];
    mergedResult = mergedResult.map((item) => {
      return {
        ...item,
        campaign_type: 'sb_manual'
      };
    });
    if (mergedResult.length > 0) {
      vueRef.failedCampaignsArray = mergedResult;
      vueRef.showFailedCampaignsBanner = true;
      vueRef.showFailedCampaignsBannerText =
        'Campaign Failed in the last 48 Hours: ';
    }
  }
}

const triggerFilterUpdated = (vueRef, LOCAL_STORAGE_FILTER_KEY) => {
  // This logic here is to update the icon in the table widget header options
  const oldHeaderOptions = vueRef.config?.widget2?.headerOptions;
  oldHeaderOptions.forEach((item) => {
    if (item.id === FAILED_ITEMS_TOGGLE_ID) {
      const [subText, icon] = getTextAndIconForFailedItemsToggle(
        LOCAL_STORAGE_FILTER_KEY
      );
      item.subText = subText;
      item.icon = icon;
    }
  });
};

const sbCampaignActionUpdater = (currentAction, selections, retailer) => {
  const actionTitle = currentAction.title || 'Action';
  const isApplicable =
    retailer === 'samsclub_api' &&
    selections.some((item) =>
      SB_CAMPAIGN_TYPES.includes(item.data?.campaign_type?.toLowerCase())
    );

  currentAction.tippy = isApplicable
    ? `The ${actionTitle} is only applicable for SP campaigns`
    : null;

  currentAction.disabled = isApplicable;
  return currentAction;
};

const getPlotRowsActionObject = () => ({
  icon: 'show-chart',
  title: 'PLOT ROWS',
  id: 'show-chart',
  removeSelectionState: false,
  handler(selectionLength, dropDownSelection, selections) {
    const payload = plotRowsPayloadExtraction(selections, PRIMARY_KEY);
    // this points to table-widget because have used call in the method call
    this.$emit('plotRowData', payload);
  }
});

const getEditStatusActionObject = (context, retailer) => ({
  icon: 'pencil',
  title: 'Edit Status',
  id: 'edit-status',
  dropDown: true,
  dropDownOptions: [
    { title: 'Enable', value: 'Live' },
    { title: 'Pause', value: 'Paused' },
    { title: 'End', value: 'Ended' }
  ],
  handler(selectionLength, dropDownSelection, selections) {
    return new Promise((resolve, reject) => {
      const dataArray = selections.map((item) => getDataFromRowNode(item));
      const dynamicKeys = {
        primaryKey: PRIMARY_KEY,
        previousEntityValue: 'status',
        actionPayloadKeys: campaignsEditStatusActionPayloadKeys(),
        viewPayloadKeys: campaignsEditStatusActionPayloadKeys()
      };
      const staticValues = {
        widget: WIDGET,
        actionType: `${retailer}CampaignStatusChange`,
        actionSource: {
          pageUrl: window.location.href
        }
      };
      const newValuesObject = {
        newEntityValue: dropDownSelection[0].value,
        actionPayload: {
          status: dropDownSelection[0].value
        },
        viewPayload: {
          status: dropDownSelection[0].value
        }
      };
      const payloadArray = dataArray.map((item) =>
        payloadGenerator(dynamicKeys, staticValues, newValuesObject, item)
      );
      context.openSnackbar(
        'Status change request has been submitted for the selected campaign(s)'
      );
      HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
        .then((response) => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
          resolve(response);
          executeRealTimeTableData(this);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
});

const executeRealTimeTableData = (context) => {
  const payload = campaignsEditStatusActionPayloadKeys();
  const tableData =
    context.$store.getters['campaignManagement/getCampaignsLevel1Data'];
  const widget = context.config.widget;
  const realtime_update_attributes = getRealtimeUpdateAttributes(
    'walmart',
    widget
  )[`measure_list_${widget}`];

  for (const key of realtime_update_attributes) {
    if (payload.hasOwnProperty(key)) {
      fetchRealtimeTableData(
        'blueBarAction',
        'walmart',
        tableData,
        'campaignManagement/',
        context.config.widget,
        context
      );
      break;
    }
  }
};

const getAddKeywordActionObject = (context, retailer) => ({
  icon: 'add-circle-outline',
  title: 'Add Keyword',
  id: 'add-keyword',
  actionUpdater(currentAction, selections) {
    if (
      selections.some((item) =>
        SB_CAMPAIGN_TYPES.includes(item.data?.campaign_type?.toLowerCase())
      )
    ) {
      currentAction.tippy =
        retailer === 'samsclub_api'
          ? 'Add Keywords is only applicable for auto SP campaigns'
          : 'Add keyword not available for SB campaigns';
      currentAction.disabled = true;
    } else {
      currentAction.disabled = selections.length > 1;

      const dataArray = selections.map((item) => getDataFromRowNode(item));
      const isDisabled = dataArray.reduce((acc, item) => {
        return acc || item.targeting_type === 'auto';
      }, false);
      currentAction.disabled = isDisabled;
      currentAction.tippy =
        isDisabled && 'Add Keywords is not available for auto campaigns';
    }
    return currentAction;
  },
  customState: {
    component: 'addNegativeKeywordsPanel',
    props: {
      apiVersion: 'actionlog',
      widgetsConfigRef: actionPanelConfigs(),
      actionType: `${retailer}AddKeywordToCampaigns`,
      parentAdgroup: true,
      actionName: 'keyword',
      resultTableWidth: '51%',
      primaryKey: PRIMARY_KEY,
      widget: WIDGET,
      defaultBid: 0.3,
      hasCustomCheckBoxOptions: true,
      matchTypes: {
        Exact: 'exact',
        Broad: 'broad',
        Phrase: 'phrase'
      },
      bidConfig: {
        minBid: 0.3,
        maxBid: 20,
        addKeywordsErrorMessage:
          'Default Bid value should be between 0.3 and 20',
        addedKeywordsErrorMessage:
          'One of more bids have invalid data. Bid value should be between 0.3 and 20.'
      },
      customCheckBoxOptions: [
        { label: 'Exact', value: 'exact' },
        { label: 'Phrase', value: 'phrase' },
        { label: 'Broad', value: 'broad' }
      ],
      defaultSelectedCustomCheckBoxOptions: [
        { label: 'Exact', value: 'exact' }
      ],
      showTippyTableHeaderForAdGroupsList: true,
      forceAllowForMultiple: true
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
      },
      closePanelMethod: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  },
  reRunHandlerOnSelectionChange: true,
  async handler(selectionLength, dropDownSelection, selections) {
    const adgroupPayload =
      actionPanelConfigs().config(this).widgets.fetchAddGroups.APIConfig;
    this.fetchAdgroupsData(adgroupPayload, selections);
  },
  customRenderHandler() {}
});

const getAddSkusActionObject = (context, retailer) => ({
  icon: 'sku-box',
  title: 'Add SKUs',
  id: 'add-skus',
  actionUpdater: (currentAction, selections) =>
    sbCampaignActionUpdater(currentAction, selections, retailer),
  customState: {
    component: 'AddAsinsPanel',
    props: {
      widgetsConfigRef: getWidgetConfigForAddSkus(retailer),
      bulkActionsAllowed: true,
      noAsinsPresentInfoMessage: {
        leftTable: 'No sku(s) found for selected campaign',
        rightTable: 'No sku(s) added'
      },
      noteAboveFooter: 'Note : Bids are applicable only for auto campaigns',
      asinKey: 'sku',
      adGroupIdKey: 'ad_group_id',
      adGroupNameKey: 'ad_group_name'
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
      },
      closePanelMethod: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  }
});

const getAddNegativeKeywordActionObject = (context, retailer) => ({
  icon: 'add-circle-outline',
  title: 'Add Negative Keyword',
  id: 'add-negative',
  actionUpdater: (currentAction, selections) =>
    sbCampaignActionUpdater(currentAction, selections, retailer),
  customState: {
    component: 'addNegativeKeywordsPanel',
    props: {
      title: '',
      isMultiple: true,
      apiVersion: 'actionlog',
      widgetsConfigRef: actionPanelConfigs(true),
      actionType: `${retailer}AddNegativeKeywordToCampaigns`,
      parentAdgroup: true,
      actionName: 'negative keyword',
      resultTableWidth: '51%',
      primaryKey: PRIMARY_KEY,
      widget: WIDGET,
      hasCustomCheckBoxOptions: true,
      tableConfig: {
        sourceTableLabel: 'Add Negative Keywords',
        resultTableLabel: 'Added Negative Keywords',
        sourceTableWidth: '50%',
        resultTableWidth: '50%',
        resultTableHeight: '372px !important'
      },
      placeholder: 'Enter list and seperate each of them by new linesss',
      matchTypes: {
        Exact: 'negativeExact'
      },
      customCheckBoxOptions: [{ label: 'Exact', value: 'negativeExact' }],
      defaultSelectedCustomCheckBoxOptions: [
        { label: 'Exact', value: 'negativeExact' }
      ],
      showTippyTableHeaderForAdGroupsList: true,
      forceAllowForMultiple: true
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
      },
      closePanelMethod: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  },
  reRunHandlerOnSelectionChange: true,
  async handler(selectionLength, dropDownSelection, selections) {
    const adgroupPayload =
      actionPanelConfigs(true).config(this).widgets.fetchAddGroups.APIConfig;
    this.fetchAdgroupsData(adgroupPayload, selections);
  },
  customRenderHandler() {}
});

const getAdjustDailyBudgetActionObject = (context) => ({
  icon: 'dollar',
  title: 'Adjust Daily Budget',
  id: 'adjust-bids',
  customState: {
    component: 'BidChangePanel',
    props: {
      title: 'Adjust Daily Budget',
      actionPanelType: 'campaigns',
      apiVersion: 'actionlog',
      retailer: context.$store.getters.getRetailer,
      widget: WIDGET,
      primaryKey: PRIMARY_KEY
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        if (
          isRealTimeUpdateEnabled.includes(context.$store.getters.getRetailer)
        ) {
          fetchRealtimeTableData(
            'blueBarAction',
            'walmart',
            context.$store.getters['campaignManagement/getCampaignsLevel1Data'],
            'campaignManagement/',
            WIDGET,
            context
          );
        }
      },
      closePanelMethod: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  }
});

const getModifyTotalBudgetObject = (context) => ({
  icon: 'dollar',
  title: 'Modify Total Budget',
  id: 'modify-total-budget',
  customState: {
    component: 'BidChangePanel',
    props: {
      title: 'Modify Total Budget',
      actionPanelType: 'modifytotalbudget',
      apiVersion: 'actionlog',
      retailer: context.$store.getters.getRetailer,
      widget: WIDGET,
      primaryKey: PRIMARY_KEY
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        if (
          isRealTimeUpdateEnabled.includes(context.$store.getters.getRetailer)
        ) {
          fetchRealtimeTableData(
            'blueBarAction',
            'walmart',
            context.$store.getters['campaignManagement/getCampaignsLevel1Data'],
            'campaignManagement/',
            WIDGET,
            context
          );
        }
      },
      closePanelMethod: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  }
});

const getAdjustStartEndDatesActionObject = (context) => ({
  icon: 'calendar',
  title: 'Adjust Start/End dates',
  id: 'adjust-start-end-dates',
  customState: {
    component: 'AdjustDates',
    props: {
      apiVersion: 'actionlog',
      retailer: context.$store.getters.getRetailer
    },
    events: {
      afterAPIActionToCall(payload) {
        context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
      },
      close: () => {
        context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
      }
    }
  }
});

export const getDayPartingActionObjectArray = (
  isDaypartingEnabled,
  retailer
) => {
  // Note - This function returns an array
  let arrayToReturn = [];
  if (isDaypartingEnabled) {
    arrayToReturn = [
      {
        icon: 'day-parting',
        title: 'Day Part',
        id: 'day-part',
        actionUpdater: (currentAction, selections) =>
          sbCampaignActionUpdater(currentAction, selections, retailer),
        handler(selectionLength, dropDownSelection, selections) {
          const rows = [];
          for (const item of selections) {
            // needed as target list table in hourly bidder reads only campaign_name as key
            const campaign_name = Object.keys(item.data).find((metric) =>
              metric.includes('campaign_name')
            );
            if (campaign_name) {
              item.data.campaign_name = item.data[campaign_name];
            }
            rows.push(item.data);
          }
          const filters = this.filterInstance
            ? this.filterInstance.getSelectedFilters()
            : [];
          const obj = {
            targetList: rows,
            filters
          };
          this.$store.dispatch('createDayPartingWithData', obj);
          this.$router.push({
            name: 'create-strategy'
          });
        }
      }
    ];
  }
  return arrayToReturn;
};

function getCreateCampaignCustomComponentObjectArray(context, retailer) {
  let arrayToReturn = [];
  if (isRetailerWalmartOrSamsclub(retailer)) {
    arrayToReturn = [
      {
        component: 'rb-button',
        props: {
          text: 'Create Campaign',
          type: 'filled',
          clickFn: () => {
            context.$store.dispatch(
              `${retailer}CampaignCreation/updateAllStepsData`,
              {}
            );
            context.$router.push({ name: 'create_campaign' });
          }
        },
        class: 'u-position-absolute create-campaign-button'
      }
    ];
  }
  return arrayToReturn;
}

function getLegendsCustomComponentObjectArray(retailer) {
  // If it's walmartV2, we hide it
  // else we show it
  let arrayToReturn = [];
  if (retailer === 'samsclub_api') {
    arrayToReturn = [
      {
        component: 'Legends',
        props: {
          externalClasses: [],
          legends: [
            {
              icon: {
                class: ['u-color-purple-base']
              },
              text: {
                value: 'Platform Type'
              }
            },
            {
              icon: {
                class: ['u-color-orange-base']
              },
              text: {
                value: 'Placement Type'
              }
            }
          ]
        }
      }
    ];
  }
  return arrayToReturn;
}

function getFailedItemsToggleIconConfigsForHeaderOptions(retailer) {
  let arrayToReturn = [];
  if (retailer === 'walmart') {
    arrayToReturn = [
      failedItemsToggleIconConfig(...getTextAndIconForFailedItemsToggle())
    ];
  }
  return arrayToReturn;
}

async function takeActionOnFailedItemsToggleClicked(
  context,
  LOCAL_STORAGE_FILTER_KEY
) {
  const currentFilterLocalStorage = {
    ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_KEY))
  };
  let currentFilterOrderLocalStorage = [];
  try {
    const rawCurrentFilterOrderLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_FILTER_KEY + '-order'
    );
    if (rawCurrentFilterOrderLocalStorage) {
      currentFilterOrderLocalStorage = [
        ...JSON.parse(rawCurrentFilterOrderLocalStorage)
      ];
    }
  } catch (err) {
    console.log(err);
  }
  if (
    doesFilterAlreadyHaveFailedReviewStatusSelected(currentFilterLocalStorage)
  ) {
    // this means failed filter is already there
    currentFilterLocalStorage.campaign_review_status = [];
    const indexOfCampaignReviewStatusOrder =
      currentFilterOrderLocalStorage.indexOf('campaign_review_status');
    if (indexOfCampaignReviewStatusOrder > -1) {
      currentFilterOrderLocalStorage.splice(
        indexOfCampaignReviewStatusOrder,
        1
      );
    }
  } else {
    // This means the filter is not present
    currentFilterLocalStorage.campaign_review_status = ['Failed'];
    currentFilterOrderLocalStorage = [
      'campaign_review_status',
      ...currentFilterOrderLocalStorage
    ];
  }
  await localStorage.setItem(
    LOCAL_STORAGE_FILTER_KEY,
    JSON.stringify(currentFilterLocalStorage)
  );
  await localStorage.setItem(
    LOCAL_STORAGE_FILTER_KEY + '-order',
    JSON.stringify(currentFilterOrderLocalStorage)
  );
  if (context?.$refs?.campaignManagement?.filterKey !== undefined) {
    context.$refs.campaignManagement.filterKey++;
  }
}

function getMetrics(retailer, tab, keysToRemove) {
  if (retailer === 'walmart') {
    return {
      ...removeKeysFromWidgets(
        metricsDictionary(tab, 'table', 'walmart').allMetrics,
        tab,
        'table',
        keysToRemove
      ),
      ...incrementalMetricsForTables
    };
  }
  if (retailer === 'samsclub_api') {
    return {
      ...metricsDictionary(tab, 'table', true).allMetrics,
      ...incrementalMetricsForTables
    };
  }
  return metricsDictionary(tab, 'table', true).allMetrics;
}

function takeActionAfterTableFetch(retailer, namespace, that) {
  if (that.dataService.data.error) {
    that.$store.dispatch(namespace + 'setErrorStateCampaignsLevel1');
  }
  if (that.dataService.data.noData) {
    that.$store.dispatch(namespace + 'setNoDataStateCampaignsLevel1');
  }
  if (
    !that.dataService.data.error &&
    !that.dataService.data.noData &&
    isRetailerWalmartOrSamsclub(retailer)
  ) {
    that.$store.dispatch(
      namespace + 'setCampaignsData',
      that.dataService.data.rows?.tableRow
    );
  }
}

function tableExpandChangeTriggeredEvent(context) {
  setTimeout(() => {
    const { api } =
      context?.$refs?.campaignManagement?.$refs?.tableWidget?.config?.table
        ?.props?.gridOptions || {};
    api.forEachNode((row) => {
      if (
        context?.$refs?.campaignManagement?.$refs?.tableWidget
          ?.rowSelectionMap?.[row.data.campaign_id] &&
        row.data['ag-grid-level'] === 0
      ) {
        row.gridApi.selectNode(row, true);
      }
    });
  });
}

export default (context, tab, retailer = 'walmart') => {
  let LOCAL_STORAGE_FILTER_KEY = 'campaign-filters';
  const namespace = 'campaignManagement/';
  const isWalmart = retailer === 'walmart';
  const keysToRemove =
    context.$store.getters.getConfigs?.client?.feature?.walmart_campaign_management?.keysInWidgetsToRemove?.split?.(
      ','
    ) || [];
  const isDaypartingEnabled = Vue.options.filters.config_check(
    `feature.pages.${retailer}_strategies.enable`
  );
  if (retailer === 'samsclub_api') {
    LOCAL_STORAGE_FILTER_KEY = `${retailer}-campaign-filters`;
  }

  const actions = [
    getPlotRowsActionObject(),
    getEditStatusActionObject(context, retailer),
    getAddSkusActionObject(context, retailer),
    getAdjustDailyBudgetActionObject(context),
    getModifyTotalBudgetObject(context),
    getAdjustStartEndDatesActionObject(context),
    getAddKeywordActionObject(context, retailer),
    ...getDayPartingActionObjectArray(isDaypartingEnabled, retailer)
  ];
  if (retailer === 'samsclub_api') {
    actions.push(getAddNegativeKeywordActionObject(context, retailer));
  }
  return {
    namespace,
    bookmark: {
      title: 'Campaigns'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'campaigns_campaign.max_feed_date',
        min: 'campaigns_campaign.min_feed_date'
      },
      api: {
        cube: `${retailer}_campaigns_campaigns_workbench`,
        page: `${retailer}_campaigns`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'campaigns-filter',
      page: `${retailer}_campaigns`,
      listenerEvent: LOCAL_STORAGE_FILTER_KEY,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-campaign',
          placeholder: 'Search for a Campaign',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-campaigns-filter'
      }
    },
    failedCampaignsBanner: function (vueRef) {
      return {
        customClass: 'background-red-warning',
        showCloseIcon: true,
        events: {
          closeIconClicked: function () {
            vueRef.showFailedCampaignsBanner = false;
            vueRef.failedCampaignsArray = [];
            vueRef.showFailedCampaignsBannerText = '';
          }
        }
      };
    },
    async onCreated(vueRef) {
      await triggerOnCreatedFlow(vueRef, retailer);
    },
    filterUpdated: (vueRef) => {
      triggerFilterUpdated(vueRef, LOCAL_STORAGE_FILTER_KEY);
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: request(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          enableWatefall: true,
          chartConfig: baseChartConfig(),
          ...chartMetrics(retailer),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      activityBar: {
        pageType: 'campaign'
      },
      messageBar: {
        pageType: 'campaigns'
      },
      actionsBar: {
        actions
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      header: {
        customComponents: [
          ...getCreateCampaignCustomComponentObjectArray(context, retailer),
          ...getLegendsCustomComponentObjectArray(retailer)
        ]
      },
      headerOptions: [
        ...getFailedItemsToggleIconConfigsForHeaderOptions(retailer),
        ...commonTableActionIcons()
      ],
      headerOptionsEvents: {
        async failedItemsToggle() {
          takeActionOnFailedItemsToggleClicked(
            context,
            LOCAL_STORAGE_FILTER_KEY
          );
        }
      },
      api: {
        request: getTableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'campaigns'
      },
      metrics: getMetrics(retailer, tab, keysToRemove),
      table: {
        beforeFetch() {
          // removed for testing sort fix
          // this.recreateTable();
          this.$store.dispatch(namespace + 'setLoadStateCampaignsLevel1');
        },
        afterFetch() {
          const that = this;
          takeActionAfterTableFetch(retailer, namespace, that);
        },
        dataSource: {
          store: true,
          getter: namespace + 'getCampaignsLevel1Data'
        },
        props: {
          config: {
            body: {
              APIConfig: {
                page: 1,
                limit: 10
              }
            }
          },
          gridOptions: gridOptions(),
          hasExpand: retailer === 'samsclub_api',
          expandCellWidth: 50,
          toolTipTextForExpandIcon:
            'Placement and Platform related metrics are not available',
          customHasExpandColumnOrder: 2,
          levels: isWalmart
            ? []
            : [
                {
                  level: 1,
                  getter: namespace + 'getFilteredSubgroupData',
                  action: namespace + 'filterSubgroupData',
                  api: {
                    storeAction: namespace + 'getCampaignsLevel1Data',
                    request: getTableRequest(retailer)
                  },
                  beforeFetch(request) {
                    const currentRowsDimensionsList = (
                      this.dataService.data.rows.tableRow || []
                    ).map((item) => ({
                      dimensionName: PRIMARY_KEY,
                      dimensionValue: item[PRIMARY_KEY]
                    }));
                    cloneDeep(this.appendWhereClause(request));
                    delete request.page;
                    delete request.limit;
                    request.where.dimensionNameValueList =
                      request.where.dimensionNameValueList.filter(
                        (item) => item.dimensionName !== 'sub_type'
                      );
                    request.where.dimensionNameValueList.push(
                      ...currentRowsDimensionsList
                    );
                  },
                  afterFetch() {
                    // handle custom usecase to show row 0 in expanded state on initial load
                    const rowIndex = 0;
                    const tableRef =
                      this.$refs?.['table-container']?.$refs?.[
                        'rb-insights-table'
                      ];
                    this.$nextTick(() => {
                      const targetRow =
                        this.config.table.props.gridOptions.api.getDisplayedRowAtIndex(
                          rowIndex
                        );
                      tableRef.expandHandler({
                        row: targetRow.data,
                        index: rowIndex
                      });
                    });
                  }
                }
              ],
          enableClientSideSorting: false,
          enableServerSideSorting: false,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
          // hasDefaultRowExpand: true
        },
        events: {
          updateRowSelectionMap(payload) {
            const { selections } = payload;
            this.rowSelectionMap = selections.reduce((acc, selection) => {
              acc[selection.data.campaign_id] = 1;
              return acc;
            }, {});
          },
          tableExpandChangeTriggered() {
            tableExpandChangeTriggeredEvent(context);
          }
        }
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    },
    ...componentBelowFilterConfigItems,
    ...systemTransparencyBannerConfig
  };
};
