<template>
  <section :class="customClass">
    <div
      class="budget-container u-border-radius-s u-border-width-s u-display-flex u-border-all u-border-color-grey-xxx-light u-color-grey-base u-font-size-5"
    >
      <div
        class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-flex-1 u-spacing-ml-m"
      >
        {{ options.prefix }}
      </div>
      <div
        class="u-display-flex u-flex-align-items-center u-flex-auto u-width-100 u-spacing-ml-s"
        data-cy="numberInput"
      >
        <input
          v-model="options.value"
          type="number"
          class="u-border-none"
          :class="customClass"
          data-cy="numberValue"
          @input="handleInput($event)"
        />
      </div>
      <div
        class="u-display-flex u-flex-1 u-flex-direction-column u-flex-justify-content-center u-flex-align-items-center u-width-100"
      >
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-flex-1 u-border-left u-border-color-grey-xxx-light u-border-bottom u-width-100"
          data-cy="increasingBudget"
          @click="handleValue('inc')"
        >
          <rb-icon
            class="rb-icon--small"
            :icon="'plus'"
            data-cy="plusIcon"
          />
        </div>
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-flex-1 u-border-left u-border-color-grey-xxx-light u-width-100"
          data-cy="decreasingBudget"
          @click="handleValue('dec')"
        >
          <rb-icon
            class="rb-icon--small"
            :icon="'minus'"
            data-cy="minusIcon"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    allowEmptyValue: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: null
    }
  },
  methods: {
    handleValue(type) {
      type === 'inc' ? (this.options.value += 1) : (this.options.value -= 1);
      this.$emit('handleValue', this.options.value);
    },
    handleInput(event) {
      if (!event?.target?.value && this.allowEmptyValue === true) {
        this.options.value = event?.target?.value;
        this.$emit('handleInput', this.options?.value);
        return;
      }
      this.options.value = +event?.target?.value;
      this.$emit('handleInput', this.options?.value);
    }
  }
};
</script>

<style lang="css" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-dropdown {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
  color: #e9eaeb;
}
.fit-content {
  width: fit-content;
  min-width: 20%;
}
.container {
  border-radius: 4px;
}
.u-border-none {
  border: none !important;
}
input:focus {
  outline: none;
}
</style>
