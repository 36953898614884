import HttpService from '@/utils/services/http-service';

export const actions = {
  updateStepsData({ commit }, data) {
    commit('updateStepsData', data);
  },
  updateLaunchedCampaign({ commit }, data) {
    commit('updateLaunchedCampaign', data);
  },
  checkIfCampaignNameExists({ commit, state }, data) {
    const payload = {
      retailer: 'instacart',
      campaignName: data.campaignName,
      type: 'CAMPAIGN_DUPLICATE_CHECK'
    };
    commit('updateStepsData', {
      ...state.stepsData,
      campaignNameCheck: {
        alreadyExists: false,
        loading: true
      }
    });
    HttpService.post('FETCH_AMS_ACTIONS_MANAGER', payload)
      .then((response) => {
        const exists = response?.data?.response?.id;
        commit('updateStepsData', {
          ...state.stepsData,
          campaignNameCheck: {
            alreadyExists: exists,
            loading: false
          }
        });
      })
      .catch(() => {
        commit('updateStepsData', {
          ...state.stepsData,
          campaignNameCheck: {
            alreadyExists: false,
            loading: false
          }
        });
      });
  },
  createAdgroup({ commit, state }, vueRef) {
    const {
      steps: { adGroups }
    } = state.stepsData;
    const adGroupRequest = adGroups.map(
      ({ adGroupName, products = [], keywords = [], defaultBid }) => ({
        name: adGroupName,
        defaultBid,
        enabled: true,
        productRequest: products.map(
          ({ upc, image_url, product_url, product_title }) => ({
            upcs: upc,
            imageUrl: image_url,
            productUrl: product_url,
            productTitle: product_title
          })
        ),
        keywordRequest: keywords.map(({ keyword, match_type, newBid }) => ({
          keyword,
          matchingType: match_type.toLowerCase(),
          cpcBid: newBid
        }))
      })
    );
    const reqPayload = {
      campaignType: 'featured_product',
      retailer: 'instacart',
      campaignId: vueRef.campaignInfo?.data?.campaignId,
      adgroupType: 'adGroup',
      request: {
        campaignRequest: { name: vueRef.campaignInfo?.data?.name },
        adGroupRequest
      }
    };
    const message = {};
    HttpService.post('CREATE_AD_GROUP_AMS_ACTIONS_MANAGER', reqPayload)
      .then((res) => {
        if (res?.data?.success >= 0 && res?.data?.failure === 0) {
          message.text = 'Ad Group created successfully';
          message.value = 'success';
        } else {
          message.text = 'Error while creating Ad Group';
          message.value = 'error';
        }
      })
      .catch((err) => {
        message.text = 'Error while creating Ad Group';
        message.value = 'error';
        console.log(err);
      })
      .finally(() => {
        const stepsData = { ...state.stepsData };
        stepsData.tempAdGroup = {};
        commit('updateStepsData', stepsData);

        vueRef.$snackbar.open({
          message: message.text,
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      });
  }
};
