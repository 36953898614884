import HomeV2 from '@/components/homeV2.vue';
import crossRetailerOverviewBO from '@/pages/all_retailers/crossRetailerOverview.vue';
import crossRetailerOverview from '@/pages/all_retailers/crossRetailerTableau/index.vue';
import contentAutomation from '@/pages/all_retailers/dso/content-automation/contentAutomation.vue';
import imap from '@/pages/all_retailers/dso/imap/imap.vue';
import generateTitle from '@/pages/all_retailers/dso/generate-title/generateTitle.vue';

const allRoutes = {
  path: '/us/all/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect: 'cross-retailer-dashboard'
    },
    {
      path: 'cross-retailer-dashboard/:id?',
      name: 'cross-retailer-dashboard',
      component: crossRetailerOverviewBO
    },
    {
      path: 'cross-retailer-overview',
      name: 'cross-retailer-overview',
      component: crossRetailerOverview,
      props: {
        pageName: 'Omnichannel_cross_retailer_overview'
      }
    },
    {
      path: 'content-automation',
      name: 'content-automation',
      component: contentAutomation
    },
    {
      path: 'imap',
      name: 'imap',
      component: imap
    },
    {
      path: 'generate-title',
      name: 'generate-title',
      component: generateTitle
    }
  ]
};

export { allRoutes };
