<template>
  <div
    v-tippy="{
      placement: tooltipPlacement,
      distance: tooltipDistance,
      maxWidth: tooltipMaxWidth
    }"
    :title="tooltipText"
    class="u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <rb-button
      :disabled="isDisabled"
      :click-fn="iconClickEvent"
      :text="buttonText"
      :size="buttonSize"
      :type="buttonType"
      :class="additionalClasses"
    />
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'buttonCell',
  computed: {
    additionalClasses() {
      if (this.params.additionalClasses) {
        return this.params.additionalClasses;
      }
      return 'u-spacing-mr-s';
    },
    buttonType() {
      if (this.params.buttonType) {
        return this.params.buttonType;
      }
      return 'filled';
    },
    buttonSize() {
      if (this.params.buttonSize) {
        return this.params.buttonSize;
      }
      return 's';
    },
    buttonText() {
      if (this.params.buttonText) {
        return this.params.buttonText;
      }
      return 'Download';
    },
    isDisabled() {
      if (
        !this.params.value ||
        this.params.value === 'null' ||
        this.params.value === 'undefined'
      ) {
        return true;
      }
      return false;
    },
    cellValue() {
      return this.params.value;
    },
    tooltipPlacement() {
      if (this.params.tooltipPlacement) {
        return this.params.tooltipPlacement;
      }
      return 'bottom';
    },
    tooltipDistance() {
      if (this.params.tooltipDistance) {
        return this.params.tooltipDistance;
      }
      return -15;
    },
    tooltipMaxWidth() {
      if (this.params.tooltipMaxWidth) {
        return this.params.tooltipMaxWidth;
      }
      return '500px';
    },
    tooltipText() {
      if (this.params.tooltipText) {
        return this.params.tooltipText;
      }
      // return 'Actions with this icon are supported by the CommerceIQ platform.'
      return '';
    }
  },
  methods: {
    iconClickEvent(event) {
      if (this.params.iconClickEvent) {
        this.params.iconClickEvent(this, event);
      }
    }
  }
};
</script>
