<template>
  <span
    v-show="loading"
    class="spinner"
  >
    <span
      class="clip"
      :style="spinnerStyle"
    />
  </span>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String
    },
    size: {
      type: String
    },
    radius: {
      type: String,
      default: '100%'
    },
    thickness: {
      type: String
    }
  },
  data() {
    return {
      spinnerStyle: {
        height: this.size || undefined,
        width: this.size || undefined,
        borderColor: this.color
          ? this.color + ' ' + this.color + ' transparent'
          : undefined,
        borderRadius: this.radius || undefined,
        borderWidth: this.thickness || '2px',
        borderStyle: 'solid',
        background: 'transparent'
      }
    };
  }
};
</script>

<style lang="css" scoped>
.spinner {
  text-align: center;
}

.spinner .clip {
  -webkit-animation: v-clipDelay 0.75s 0s infinite linear;
  animation: v-clipDelay 1s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  margin: auto;
}

@-webkit-keyframes v-clipDelay {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@keyframes v-clipDelay {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
</style>
