var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"scrollable u-display-flex u-flex-direction-row",attrs:{"id":"scrollable"}},[_c('div',{staticClass:"section__left sections-menu u-width-20"},[_c('section',{staticClass:"u-height-100 u-spacing-pl-l u-spacing-pr-l",staticStyle:{"margin-top":"50%"}},_vm._l((_vm._config),function(step,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSection !== _vm.offsets.length - 1),expression:"activeSection !== offsets.length - 1"}],key:index,staticClass:"menu-point u-spacing-mb-m",class:{ active: _vm.activeSection == index }},[_c('p',{staticClass:"u-line-height-1 u-display-flex u-flex-align-items-center u-spacing-mb-xs u-cursor-pointer",class:[
            {
              'u-color-blue-base u-font-size-5 u-cursor-pointer':
                _vm.activeSection === index
            },
            { 'u-font-size-7': _vm.activeSection < index },
            { 'u-font-size-5 u-cursor-pointer': _vm.activeSection > index }
          ],on:{"click":function($event){return _vm.scrollToSection(index)}}},[_c('rb-icon',{staticClass:"u-cursor-pointer",class:[
              {
                'u-color-blue-base rb-icon--medium': _vm.activeSection === index
              },
              {
                'rb-icon--small u-color-grey-lighter': _vm.activeSection < index
              },
              { 'rb-icon--medium': _vm.activeSection > index }
            ],attrs:{"icon":'dot'}}),_c('span',{staticClass:"u-line-height-1"},[_vm._v(_vm._s(step.name))])],1),_c('div',{staticClass:"u-spacing-pl-m u-font-size-7 u-line-height-1-3"},[_c('span',{staticClass:"u-color-grey-lighter",domProps:{"innerHTML":_vm._s(
              _vm.allStepsData.strategyUIText &&
              _vm.allStepsData.strategyUIText[step.textMapping]
            )}})])])}),0)]),_c('div',{staticClass:"section__right u-flex-1",attrs:{"id":"steps"}},[_c('section',{staticClass:"fullpage u-position-relative",staticStyle:{"margin-top":"12%"}},_vm._l((_vm._config),function(step,$index){return _c('div',{key:$index,staticClass:"step-class--pseudo strategyStep step_page u-overflow-auto"},[_c(step.component,{tag:"component",attrs:{"step":step,"selected-values":_vm.allStepsData,"all-config":_vm._config},on:{"nextStep":_vm.moveDown,"prevStep":_vm.moveUp}})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }