var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'u-flex-1 u-flex-direction-column u-display-flex':
      _vm.strategyList.length === 0
  }},[_c('div',{staticClass:"u-display-flex u-spacing-ph-l u-bg-color-grey-white u-spacing-pb-m u-header-box-shadow sticky u-z-index-1 u-spacing-mb-s u-flex-justify-content-space-between"},[_c('PageTitle',{attrs:{"title":'Hourly Bidder'}}),(_vm.isMarketingEnabled && _vm.isDaypartingEnabled)?_c('rb-button',{attrs:{"click-fn":_vm.createStrategy,"text":'Create New Strategy',"type":'filled',"data-cy":"CreateStrategy"}}):_vm._e()],1),_c('section',{staticClass:"u-envelop-width u-width-100",class:{
      'u-flex-1 u-flex-direction-column u-display-flex u-flex-justify-content-center':
        _vm.strategyList.length === 0
    }},[(_vm.isMarketingEnabled && _vm.isDaypartingEnabled)?_c('div',{staticClass:"u-spacing-ph-m"},[(!_vm.isLoaded)?_c('div',{staticClass:"u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative createStratgey"},[(!_vm.isLoaded)?_c('loader',{staticClass:"fill--parent",attrs:{"loading":!_vm.isLoaded,"color":'#007cf6'}}):_vm._e()],1):_c('div',{staticClass:"u-spacing-pb-m u-display-flex u-flex-align-items-center u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-wrap-yes u-width-100 u-flex-align-items-center u-flex-justify-content-center u-height-100"},[_vm._l((_vm.strategyList),function(strategy,index){return _c('div',{key:index,staticClass:"card u-spacing-m-s",class:{
              'status-achived': strategy.strategyStatus === 'ARCHIVED'
            },staticStyle:{"width":"calc(50% - 16px)","min-height":"185px"}},[_c('div',{staticClass:"u-spacing-p-l"},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-5 u-font-weight-bold u-color-blue-base"},[_vm._v(_vm._s('STRATEGY ' + (index + 1)))]),(strategy.submitLoading)?_c('div',[_vm._m(0,true)]):_c('div',[(strategy.isEditable)?_c('rb-select',{attrs:{"send-details":true,"on-close":_vm.statusSelected,"options":strategy.options,"context":[index]},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_vm._v(" "+_vm._s(option.title)+" ")])]}}],null,true)},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('div',{staticClass:"statusChip u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer",class:_vm.statusColorClassesMap[strategy.strategyStatus]},[_c('span',{staticClass:"u-font-size-6 u-color-grey-white u-font-weight-600"},[_vm._v(_vm._s((strategy.selectedStatus || {}).title))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-white",attrs:{"icon":'arrow1-down'}})],1)])])])]):_c('div',{staticClass:"statusChip u-display-flex u-flex-align-items-center u-flex-justify-content-center",class:_vm.statusColorClassesMap[
                        (strategy.selectedStatus || {}).value
                      ]},[_c('span',{staticClass:"u-font-size-6 u-color-grey-white u-font-weight-600"},[_vm._v(_vm._s((strategy.selectedStatus || {}).title))])])],1)]),_c('div',{staticClass:"u-display-flex u-flex-direction-column u-spacing-mt-m"},[_c('span',{staticClass:"u-font-size-4 u-font-weight-bold"},[_vm._v(_vm._s(strategy['startegyName']))]),_c('span',{staticClass:"u-font-size-6 u-spacing-mt-s u-line-height-1-3"},[_vm._v(_vm._s(strategy['strategyDesc']))])]),(strategy.isEditable)?_c('div',{staticClass:"u-display-flex u-spacing-mt-m u-cursor-pointer"},[_c('router-link',{attrs:{"to":{
                    name: 'edit-strategy',
                    query: { strategyUniqueId: strategy.strategyUniqueId }
                  }}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-center"},[_c('rb-icon',{staticClass:"rb-icon--small",attrs:{"icon":'pencil'}}),_c('span',{staticClass:"u-font-size-7 u-spacing-ml-xs"},[_vm._v("Edit strategy")])],1)])],1):_vm._e()])])}),(_vm.strategyList.length === 0)?_c('div',{staticClass:"u-spacing-pv-l u-font-weight-600 u-font-size-3"},[_vm._v(" No Hourly Bidder Strategies created! ")]):_vm._e()],2)])]):_c('div',{staticClass:"u-spacing-p-xl u-spacing-pt-0"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"u-flex-1"},[(!_vm.isLoaded)?_c('div',{staticClass:"u-spacing-p-xl"},[_c('Loader',{staticClass:"fill--parent",attrs:{"loading":!_vm.isLoaded,"color":'#3fadf7',"size":'4rem',"thickness":'.2rem'}})],1):_vm._e(),(_vm.isLoaded && _vm.strategyList.length)?_c('div',[_c('rb-data-table',{attrs:{"grid-options":_vm.gridOptions,"table-columns":_vm.columnList,"table-data":_vm.strategyList,"enable-client-side-sorting":false,"grid-auto-height":true,"row-height":68,"no-rows-message":'No strategies found.'}})],1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mini-spinner"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})])}]

export { render, staticRenderFns }