<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      v-for="(textObject, index) in textArray"
      :key="index"
      class="u-display-flex"
    >
      <span
        v-if="(textObject || {}).text"
        class="u-spacing-mr-xs"
        :class="[
          'u-color-' +
            (((textObject || {}).class || {}).color || 'grey-lighter')
        ]"
      >
        {{ (textObject || {}).text }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    textArray: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
