<template>
  <!-- Bid Change Panel -->
  <section class="u-width-100">
    <section
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </section>

    <section
      v-show="!loader"
      class="bidChangePanel u-bg-color-grey-white"
    >
      <header
        class="u-font-size-5 u-font-weight-600 u-spacing-pv-l u-spacing-ph-l u-text-case-upper title"
      >
        {{ title }} ({{ panelSelectionCount }})
      </header>
      <section class="content u-display-flex u-spacing-pb-l u-spacing-ph-l">
        <div
          class="radio-holder"
          :class="{
            'u-spacing-pr-xxxl': ![
              'lineItemBaseSupplyBid',
              'lineItemMaxSupplyBid'
            ].includes(actionPanelType)
          }"
        >
          <div
            class="u-font-size-5 u-color-grey-light u-width-120px"
            :class="{
              'u-width-140px':
                actionPanelType === 'campaigns' ||
                actionPanelType === 'modifytotalbudget',
              'u-width-244px': [
                'keywords',
                'lineItemBaseSupplyBid',
                'lineItemMaxSupplyBid'
              ].includes(actionPanelType)
            }"
            @change="changeRadio"
          >
            <div class="u-spacing-mb-m">
              <rb-radio
                v-model="bidsType"
                native-value="0"
              >
                Set new {{ bidDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
            <div class="u-spacing-mb-m">
              <rb-radio
                v-model="bidsType"
                native-value="1"
              >
                Increase {{ bidDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
            <div class="u-spacing-mb-xs">
              <rb-radio
                v-model="bidsType"
                native-value="2"
              >
                Decrease {{ bidDisplayText[actionPanelType] }}
              </rb-radio>
            </div>
          </div>
        </div>
        <div class="u-spacing-ph-xxxl">
          <div class="u-display-flex u-spacing-pt-m">
            <rb-select
              v-if="bidsType !== '0'"
              class="u-spacing-mr-s valueDropdown"
              :send-details="true"
              :on-close="optionSelected"
              :options="dropdownOptions"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-flex-direction-column u-spacing-p-s"
                >
                  <span class="u-font-size-5">{{ selectedOption.title }}</span>
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.title }}
                </div>
              </template>
            </rb-select>
            <div
              class="u-display-flex u-flex-0 u-flex-align-items-center"
              :class="{
                percentBox:
                  selectedOption.value === 'percent' && bidsType !== '0'
              }"
            >
              <span
                v-if="bidsType === '0' || selectedOption.value !== 'percent'"
                class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
              >
                {{ currency }}
              </span>
              <input
                ref="new_bid"
                v-model="bidValue"
                type="number"
                data-cy="inputValue"
                class="rb-input currency-input u-display-flex u-flex-align-items-center"
                :min="validationObj[actionPanelType].absolute.min"
                step="0.01"
                :max="validationObj[actionPanelType].absolute.max"
                autofocus
                @input="checkForMaxBid($event)"
              />
              <span
                v-if="bidsType !== '0' && selectedOption.value === 'percent'"
                class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
              >
                %
              </span>
            </div>
            <div
              v-if="shouldDisplayTip && bidsType === '0'"
              class="u-color-grey-lighter u-spacing-pt-xs u-spacing-ml-s u-font-size-6 u-display-flex u-flex-align-items-baseline"
            >
              <div class="u-spacing-pr-xs">
                <rb-icon
                  class="rb-icon--small u-cursor-pointer"
                  icon="info-circle-fill"
                />
              </div>
              <span class="u-line-height-1-3">
                SP Campaigns : The bid should be between $0.3 and $20.<br />
                SB Campaigns : The bid should be between $1.25 and $20.
              </span>
            </div>
          </div>

          <!-- Show min bid value -->
          <div
            v-if="showMinBidInfo"
            class="u-font-size-5 u-color-grey-lighter u-display-flex u-spacing-mt-xs u-flex-align-items-center"
          >
            <rb-icon
              class="rb-icon--medium u-spacing-mr-xxs"
              :icon="'info-circle-fill'"
            />
            <div class="u-spacing-mt-xxs">
              {{ minBidInfo }}
            </div>
          </div>

          <!-- Error Message -->
          <div
            v-if="showError"
            class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
          >
            <rb-icon
              class="u-flex-0 rb-icon--small"
              :icon="'error-fill'"
            />
            <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
              {{ errorMessage }}
            </div>
          </div>
        </div>

        <!-- Info box  -->
        <div
          v-if="showInfoBox"
          class="infoBox u-spacing-pl-xxxl u-spacing-pr-xxxl u-display-flex u-flex-align-items-center u-flex-justify-content-center u-max-width-40"
        >
          <div class="u-font-size-5 u-color-grey-lighter u-display-flex">
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-spacing-mt-xxs"
              :icon="'info-circle-fill'"
            />
            <div class="u-spacing-mt-xxs">
              {{ infoBoxText }}
            </div>
          </div>
        </div>
      </section>
      <footer class="bidChangePanelFooter">
        <div
          class="u-display-flex u-flex-direction-column u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-bottom u-border-top u-border-color-grey-xxx-light"
        >
          <div
            v-if="valueConfirmationRequired && confirmCheckRequired"
            class="u-display-flex u-spacing-mb-m"
          >
            <rb-checkbox
              key="confirmHighValue"
              v-model="validationConfirmed"
              native-value="false"
              class="u-width-50"
            >
              {{ confirmMessage }}
            </rb-checkbox>
          </div>
          <div class="u-display-flex">
            <div
              v-tippy="{ placement: 'top', arrow: true }"
              :title="applyButtonVality ? '' : applyButtonTooltip"
            >
              <rb-button
                :click-fn="takeAction"
                :text="'Apply'"
                :size="'s'"
                :type="'filled'"
                class="u-spacing-mr-s"
                :disabled="applyButtonVality"
                :class="{
                  disabled: bidValue.length === 0 || formValid === false
                }"
              />
            </div>
            <rb-button
              :click-fn="takeAction"
              :text="'Cancel'"
              :size="'s'"
              :type="'hollow'"
              class="u-spacing-mr-s"
            />
          </div>
        </div>
      </footer>
    </section>
  </section>
</template>

<style lang="css" scoped>
.loaderContainer {
  position: relative;
  height: 230px;
}
.valueDropdown {
  min-width: 208px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
.bidChangePanel .radio-holder {
  border-right: 1px solid #e9eaeb;
}
.bidChangePanel .infoBox {
  border-left: 1px solid #e9eaeb;
}
.bidChangePanel .infoBox .rb-icon {
  align-self: start;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}

.u-width-140px {
  width: 140px !important;
}

.u-width-244px {
  width: 244px !important;
}

.errorMessage {
  margin-top: 4px;
}
.errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .rb-icon {
  align-self: flex-start;
}
</style>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import { isCriteoRetailer } from '@/router/utils';
import { getBaseActionType as getCriteoBaseActionType } from '@/pages/campaign-management/retailers/criteo/utils';

const retailerActionMap = {
  instacart: {
    keywords: {
      actionName: 'instacartKeywordCpcBidChange',
      bidKey: 'latest_cpc_bid',
      previousBid: 'previousBid',
      newBid: 'newBid'
    },
    campaigns: {
      actionName: 'instacartCampaignTotalBudgetChange',
      bidKey: 'budget',
      previousBid: 'previousLifetimeBudget',
      newBid: 'newLifetimeBudget'
    },
    adgroups: {
      actionName: 'instacartAdGroupDefaultBidChange',
      bidKey: 'default_bid',
      previousBid: 'previousBid',
      newBid: 'newBid'
    }
  },
  walmart: {
    campaigns: {
      actionName: 'walmartCampaignBudgetChange',
      bidKey: 'daily_budget',
      previousBid: 'daily_budget',
      newBid: 'new_daily_budget'
    },
    keywords: {
      actionName: 'walmartKeywordBidChange',
      bidKey: 'bid',
      previousBid: 'current_bid',
      newBid: 'new_bid'
    },
    adskus: {
      actionName: 'walmartProductAdBidChange',
      bidKey: 'bid',
      previousBid: 'current_bid',
      newBid: 'new_bid'
    },
    modifytotalbudget: {
      actionName: 'walmartCampaignTotalBudgetChange',
      bidKey: 'total_budget',
      previousBid: 'total_budget',
      newBid: 'new_total_budget'
    }
  },
  samsclub_api: {
    campaigns: {
      actionName: 'samsclub_apiCampaignBudgetChange',
      bidKey: 'daily_budget',
      previousBid: 'daily_budget',
      newBid: 'new_daily_budget'
    },
    keywords: {
      actionName: 'samsclub_apiKeywordBidChange',
      bidKey: 'bid',
      previousBid: 'current_bid',
      newBid: 'new_bid'
    },
    adskus: {
      actionName: 'samsclub_apiProductAdBidChange',
      bidKey: 'bid',
      previousBid: 'current_bid',
      newBid: 'new_bid'
    },
    modifytotalbudget: {
      actionName: 'samsclub_apiCampaignTotalBudgetChange',
      bidKey: 'total_budget',
      previousBid: 'total_budget',
      newBid: 'new_total_budget'
    }
  },
  amazon: {
    keywords: {
      actionName: 'keywordMetadataChange',
      bidKey: 'base_bid',
      previousBid: 'view_old_bid',
      newBid: 'new_bid'
    },
    campaigns: {
      actionName: 'campaignMetadataChange',
      bidKey: 'base_budget',
      previousBid: 'daily_budget',
      newBid: 'new_daily_budget'
    },
    lineItemBaseSupplyBid: {
      actionName: 'dspLineItemBaseSupplyBidChange',
      bidKey: 'dsp_line_items_base_bid',
      previousBid: 'previousBid',
      newBid: 'baseSupplyBid'
    },
    lineItemMaxSupplyBid: {
      actionName: 'dspLineItemMaxSupplyBidChange',
      bidKey: 'dsp_line_items_max_bid',
      previousBid: 'previousBid',
      newBid: 'maxSupplyBid'
    }
  },
  target_rms: {
    line_items: {
      actionName: 'criteoCommonLineItemTargetBidChange',
      bidKey: 'target_bid',
      previousBid: 'previousBid',
      newBid: 'new_target_bid',
      minBidKey: 'min_bid'
    },
    adskus: {
      actionName: 'criteoCommonSkUBidChange',
      bidKey: 'bid_override',
      previousBid: 'bid_override',
      newBid: 'set_bid_override',
      minBidKey: 'minimum_bid'
    }
  },
  walmart_criteo: {
    line_items: {
      actionName: 'criteoCommonLineItemTargetBidChange',
      bidKey: 'target_bid',
      previousBid: 'previousBid',
      newBid: 'new_target_bid',
      minBidKey: 'min_bid'
    },
    adskus: {
      actionName: 'criteoCommonSkUBidChange',
      bidKey: 'bid_override',
      previousBid: 'bid_override',
      newBid: 'set_bid_override',
      minBidKey: 'minimum_bid'
    }
  },
  chewy_promoteiq: {
    keywords: {
      bidKey: 'promoteiq_cm_keyword_bid_modifier'
    }
  }
};

function getCriteoRetailerActionMap(retailer) {
  const baseActionType = getCriteoBaseActionType(retailer);
  return {
    line_items: {
      actionName: `${baseActionType}LineItemTargetBidChange`,
      bidKey: 'target_bid',
      previousBid: 'previousBid',
      newBid: 'new_target_bid',
      minBidKey: 'min_bid'
    },
    adskus: {
      actionName: `${baseActionType}SkUBidChange`,
      bidKey: 'bid_override',
      previousBid: 'bid_override',
      newBid: 'set_bid_override',
      minBidKey: 'minimum_bid'
    },
    keywords: {
      actionName: `${baseActionType}LineItemKeywordsUpdateBids`,
      bidKey: 'bid',
      minBidKey: 'min_bid',
      maxBidKey: 'max_bid'
    }
  };
}

const validationObj = {
  amazon: {
    keywords: {
      absolute: {
        max: 1000,
        min: 0.02
      }
    },
    campaigns: {
      absolute: {
        max: 500000,
        min: 1
      }
    },
    lineItemBaseSupplyBid: {
      absolute: {
        max: 500000,
        min: 1
      }
    },
    lineItemMaxSupplyBid: {
      absolute: {
        max: 500000,
        min: 1
      }
    }
  },
  instacart: {
    keywords: {
      absolute: {
        max: 85,
        min: 0.3
      }
    },
    campaigns: {
      absolute: {
        max: 500000,
        min: 1
      }
    },
    adgroups: {
      absolute: {
        max: 85,
        min: 0.3
      }
    }
  },
  walmart: {
    keywords: {
      absolute: {
        min: 0.3,
        max: 20
      }
    },
    campaigns: {
      absolute: {
        min: 50
      }
    },
    adskus: {
      absolute: {
        min: 0.2,
        max: 20
      }
    },
    modifytotalbudget: {
      absolute: {
        min: 100
      }
    }
  },
  samsclub_api: {
    campaigns: {
      absolute: {
        min: 50,
        max: undefined
      }
    },
    keywords: {
      absolute: {
        min: 0.3,
        max: 20
      }
    },
    adskus: {
      absolute: {
        min: 0.2,
        max: 20
      }
    },
    modifytotalbudget: {
      absolute: {
        min: 100
      }
    }
  },
  target: {
    line_items: {
      absolute: {}
    },
    adskus: {
      absolute: {}
    }
  },
  chewy_promoteiq: {
    keywords: {
      absolute: {
        max: 5,
        min: 1.1
      }
    }
  }
};

export default {
  name: 'BidChangePanel',
  components: {
    loader
  },
  props: {
    config: {
      type: Object,
      default: function () {}
    },
    primaryKey: {
      type: String,
      default: null
    },
    widget: {
      type: String,
      default: null
    },
    apiVersion: {
      type: String,
      default: 'worklog'
    },
    retailer: {
      default: 'amazon',
      type: String
    },
    selectedValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    customSelectionLength: {
      type: Object,
      default: function () {
        return {
          visibility: false,
          value: 0
        };
      }
    },
    title: {
      type: String,
      default: ''
    },
    confirmCheckRequired: {
      type: Boolean,
      default: false
    },
    closePanelMethod: {
      type: Function,
      default: function () {}
    },
    afterAPIActionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    infoBoxText: {
      type: String,
      default: ''
    },
    applyButtonTooltip: {
      type: String,
      default: ''
    },
    transformPayload: {
      type: Function,
      default: null
    },
    bidDisplayText: {
      type: Object,
      default: () => {
        return {
          keywords: 'bid',
          campaigns: 'budget',
          adgroups: 'bid',
          adskus: 'bid',
          modifytotalbudget: 'budget',
          line_items: 'bid',
          lineItemBaseSupplyBid: 'base supply bid',
          lineItemMaxSupplyBid: 'max supply bid'
        };
      }
    },
    customPayload: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      bidValue: '',
      bidsType: '0',
      dropdownOptions: [
        {
          title: 'Change by percentage',
          value: 'percent'
        },
        {
          title: 'Change by absolute value',
          value: 'absolute'
        }
      ],
      selectedOption: null,
      currency: this.$currency,
      formValid: false,
      errorMessage: '',
      showError: false,
      actionMap: null,
      validationObj: null,
      minBidValue: 1000,
      loader: false,
      bidsActions: {
        0: 'newBids',
        1: 'increaseBids',
        2: 'decreaseBids'
      },
      validationConfirmed: false,
      valueConfirmationRequired: false
    };
  },
  computed: {
    hasSB() {
      return this.selectedValues.some(
        (item) => item.data.campaign_type === 'SB'
      );
    },
    hasSP() {
      return this.selectedValues.some(
        (item) => item.data.campaign_type === 'SP'
      );
    },
    shouldDisplayTip() {
      return (
        this.retailer === 'samsclub_api' &&
        this.actionPanelType === 'keywords' &&
        this.hasSB &&
        this.hasSP
      );
    },
    panelSelectionCount() {
      const selectedValuesLength = this.customSelectionLength.visibility
        ? this.customSelectionLength.value
        : this.selectedValues.length;

      var panelName = 'Campaign';
      if (this.actionPanelType === 'keywords') {
        panelName = selectedValuesLength > 1 ? 'keywords' : 'keyword';
      } else if (this.actionPanelType === 'campaigns') {
        panelName = selectedValuesLength > 1 ? 'campaigns' : 'campaign';
      } else if (this.actionPanelType === 'modifytotalbudget') {
        panelName = selectedValuesLength > 1 ? 'campaigns' : 'campaign';
      } else if (this.actionPanelType === 'adgroups') {
        panelName = selectedValuesLength > 1 ? 'Ad groups' : 'Ad group';
      } else if (this.actionPanelType === 'adskus') {
        panelName = selectedValuesLength > 1 ? 'Ad SKUs' : 'Ad SKU';
      } else if (
        [
          'line_items',
          'lineItemBaseSupplyBid',
          'lineItemMaxSupplyBid'
        ].includes(this.actionPanelType)
      ) {
        panelName = selectedValuesLength > 1 ? 'Line Items' : 'Line Item';
      }
      return selectedValuesLength + ' ' + panelName + ' selected';
    },
    applyButtonVality() {
      const flag =
        this.bidValue.length === 0 ||
        this.formValid === false ||
        this.selectedValues.length === 0;
      if (this.confirmCheckRequired === false) {
        return flag;
      } else if (this.valueConfirmationRequired === true) {
        return (
          this.validationConfirmed === false ||
          this.bidValue.length === 0 ||
          this.formValid === false ||
          this.selectedValues.length === 0
        );
      } else {
        return flag;
      }
    },
    confirmMessage() {
      const message = {
        keywords: 'keyword bids',
        campagins: "campaigns' budgets",
        adgroups: 'adgroup bids',
        adskus: 'Ad SKU bids',
        modifytotalbudget: "campaigns' budgets",
        lineItemBaseSupplyBid: 'base supply bids',
        lineItemMaxSupplyBid: 'max supply bids'
      };
      return `I understand and accept the big changes made to some of the ${
        message[this.actionPanelType]
      }.`;
    },
    showInfoBox() {
      if (isCriteoRetailer(this.retailer)) {
        if (
          this.actionPanelType === 'line_items' ||
          this.actionPanelType === 'adskus'
        ) {
          if (this.selectedValues.length > 1) {
            return true;
          }
        }
        if (this.actionPanelType === 'keywords') {
          return true;
        }
      }
      return false;
    },
    showMinBidInfo() {
      if (
        (this.actionPanelType === 'line_items' ||
          this.actionPanelType === 'adskus' ||
          this.actionPanelType === 'keywords') &&
        isCriteoRetailer(this.retailer)
      ) {
        if (this.selectedValues.length === 1 && this.showError === false) {
          return true;
        }
      }
      return false;
    },
    minBidInfo() {
      if (
        isCriteoRetailer(this.retailer) &&
        this.actionPanelType === 'keywords' &&
        this.selectedValues[0]?.data.max_bid
      ) {
        return `Minimum Bid : $${
          this.validationObj[this.actionPanelType].absolute.min
        }, Maximum Bid : $${
          this.validationObj[this.actionPanelType].absolute.max
        }`;
      }
      return `Minimum Bid : $${
        this.validationObj[this.actionPanelType].absolute.min
      }`;
    }
  },
  watch: {
    bidsType: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.valueConfirmationRequired = false;
        this.validationConfirmed = false;
      }
    },
    selectedValues: function () {
      this.updateValidationObject();
      this.checkForMaxBid({
        target: {
          value: this.bidValue
        }
      });
      if (this.selectedValues.length === 0) {
        this.showError = false;
      }
    }
  },
  created() {
    this.selectedOption = this.dropdownOptions[0];
    if (isCriteoRetailer(this.retailer)) {
      this.actionMap = getCriteoRetailerActionMap(this.retailer);
    } else {
      this.actionMap = { ...retailerActionMap[this.retailer] };
    }
    this.validationObj = { ...validationObj[this.retailer] };
    this.updateValidationObject();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.new_bid.focus();
    });
  },
  methods: {
    checkForBulkCampaignType() {
      if (this.hasSB && !this.hasSP) {
        return 1.25; // All selected items are of 'SB' type
      } else if (this.hasSP && !this.hasSB) {
        return 0.3; // All selected items are of 'SP' type
      } else {
        return 0.3; // Both 'SB' and 'SP' types are present in selected items
      }
    },
    updateValidationObjectForCriteoLineItemsAndAdSkus(minBid) {
      if (!minBid && this.selectedValues[0].data.status === 'draft') {
        minBid = 0.5;
      }
      this.validationObj = {
        [this.actionPanelType]: {
          absolute: {
            min: minBid
          }
        }
      };
    },
    updateValidationObjectForCriteoKeywords(minBid, maxBid) {
      this.validationObj = {
        [this.actionPanelType]: {
          absolute: {
            min: minBid ?? 0,
            max: maxBid
          }
        }
      };
    },
    updateValidationObject() {
      if (isCriteoRetailer(this.retailer)) {
        const minBidKey = this.actionMap[this.actionPanelType].minBidKey;
        const maxBidKey = this.actionMap[this.actionPanelType].maxBidKey;
        let minBid = this.selectedValues[0].data[minBidKey];
        let maxBid = this.selectedValues[0].data[maxBidKey];
        if (this.selectedValues.length === 1) {
          if (
            this.actionPanelType === 'line_items' ||
            this.actionPanelType === 'adskus'
          ) {
            this.updateValidationObjectForCriteoLineItemsAndAdSkus(minBid);
          } else if (this.actionPanelType === 'keywords') {
            this.updateValidationObjectForCriteoKeywords(minBid, maxBid);
          }
        } else {
          this.validationObj = {
            [this.actionPanelType]: {
              absolute: {
                min: 0
              }
            }
          };
        }
      }
    },
    placeholderVal() {
      if (this.bidsType !== '0' && this.selectedOption.value === 'percent') {
        return 'Enter a value';
      }
      return 'Enter a value';
    },
    takeAction(type) {
      var valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      var objToPass = {
        actionType: type.textContent.trim().toLowerCase(),
        value: valObj
      };
      // this.adjustBidAction(objToPass);
      // this.$emit('actionToCall', objToPass);
      if (objToPass.actionType === 'apply') {
        this.callBidActionApi(objToPass);
        // this.$emit('afterApply', payload);
      } else {
        this.$emit('closePanelMethod');
      }
    },
    changeRadio() {
      this.bidValue = '';
      this.showError = false;
      this.$nextTick(() => {
        this.$refs.new_bid.focus();
      });
    },
    optionSelected(context, selectedOption) {
      if (
        selectedOption[0] &&
        this.selectedOption?.selectedIndex !== selectedOption[0]?.selectedIndex
      ) {
        this.selectedOption = selectedOption[0];
        this.bidValue = '';
      }
    },
    isNullOrUndefined(val) {
      if (val === null || val === undefined) {
        return true;
      }

      return false;
    },
    checkForMaxBid(event, actionObject, key) {
      var inputVal = event.target.value;
      if (!inputVal) {
        return;
      }
      const value = Number(inputVal).toFixed(2);
      this.showError = false;

      var valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      this.getPayload({ value: valObj });

      if (this.bidsType !== '0' && this.selectedOption.value === 'percent') {
        if (value > 0) {
          this.formValid = true;
        }
        return;
      }
      this.formValid = false;
      const maxBid = this.validationObj[this.actionPanelType].absolute.max;
      const minBid =
        this.retailer === 'samsclub_api' && this.actionPanelType === 'keywords'
          ? this.checkForBulkCampaignType()
          : this.validationObj[this.actionPanelType].absolute.min;
      if (!this.isNullOrUndefined(maxBid) && !this.isNullOrUndefined(minBid)) {
        if ((value > maxBid || value < minBid) && this.bidsType === '0') {
          this.errorMessage =
            'Absolute value should be between ' + minBid + ' to ' + maxBid;
          this.showError = true;
          return;
        }
      } else if (!this.isNullOrUndefined(maxBid)) {
        if (value > maxBid && this.bidsType === '0') {
          this.errorMessage = 'Absolute value should be less than ' + maxBid;
          this.showError = true;
          return;
        }
      } else if (!this.isNullOrUndefined(minBid)) {
        if (value < minBid && this.bidsType === '0') {
          this.errorMessage = 'Absolute value should be greater than ' + minBid;
          this.showError = true;
          return;
        }
      }
      this.errorMessage = '';
      this.formValid = true;
      valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      this.getPayload({ value: valObj });
    },

    // initial payload object as per the panel type
    getRetailerBasedInitialObject(data) {
      var obj = {
        actionPayload: {
          campaignId: data.campaign_id,
          profileId: data.profile_id || 0,
          campaignType: data.campaign_type
        },
        viewPayload: {
          name: data.campaign_name,
          newStatus: data.status,
          campaignId: data.campaign_id,
          profileId: data.profile_id || 0,
          campaignType: data.campaign_type
        },
        actionType: this.actionMap[this.actionPanelType].actionName,
        actionSource: {
          pageUrl: window.location.href
        }
      };
      this.retailerSpecificChanges(obj, data);
      return obj;
    },
    retailerSpecificChanges(obj, data) {
      if (this.retailer === 'walmart' || this.retailer === 'samsclub_api') {
        obj.actionPayload.advertiser_id = data.advertiser_id;
        obj.viewPayload.advertiser_id = data.advertiser_id;
        delete obj.actionPayload.profileId;
        delete obj.viewPayload.newStatus;
        delete obj.viewPayload.profileId;
        delete obj.actionPayload.adgroupId;
        delete obj.viewPayload.adgroupId;
      }
      if (isCriteoRetailer(this.retailer)) {
        delete obj.actionPayload;
        delete obj.viewPayload;
        obj.actionPayload = {};
        obj.viewPayload = {};
        obj.actionPayload.line_item_id = data.line_item_id;
        obj.viewPayload.line_item_id = data.line_item_id;
      }
    },
    getInitialPayloadObject(data, actionType) {
      var obj = this.getRetailerBasedInitialObject(data);
      if (this.actionPanelType === 'campaigns' && this.retailer === 'amazon') {
        obj.primaryKey = data[this.primaryKey];
        obj.widget = this.widget;
      }
      if (this.actionPanelType === 'keywords') {
        obj.actionPayload.adgroupId = data.adgroup_id || data.ad_group_id;
        obj.actionPayload.keywordId = data.keyword_id;
        obj.viewPayload.adgroupId = data.adgroup_id || data.ad_group_id;
        obj.viewPayload.keywordId = data.keyword_id;
        obj.viewPayload.match_type =
          data?.match_type || data?.match_type_keyword;
      }
      if (this.actionPanelType === 'adgroups') {
        obj.actionPayload.adgroupId = data.adgroup_id || data.ad_group_id;
        obj.viewPayload.name = data.ad_group_name;
        obj.viewPayload.adgroupId = data.adgroup_id || data.ad_group_id;
      }
      if (this.actionPanelType === 'adskus') {
        if (this.retailer === 'walmart' || this.retailer === 'samsclub_api') {
          obj.actionPayload.item_id = data.item_id || data.sku;
          obj.actionPayload.ad_group_id = data.adgroup_id || data.ad_group_id;
          obj.actionPayload.ad_group_content_id = data.ad_group_content_id;
          obj.viewPayload.item_id = data.item_id || data.sku;
          obj.viewPayload.ad_group_id = data.adgroup_id || data.ad_group_id;
          obj.viewPayload.ad_group_content_id = data.ad_group_content_id;
        }
        if (isCriteoRetailer(this.retailer)) {
          obj.actionPayload.promoted_product_id = data.product_id;
          (obj.actionPayload.actionType =
            this.actionMap[this.actionPanelType].actionName),
            (obj.actionPayload.minimum_bid = data.minimum_bid);
          obj.viewPayload.promoted_product_id = data.product_id;
          obj.viewPayload.minimum_bid = data.minimum_bid;
          obj.viewPayload.name = data.product_name;
        }
      }
      if (this.actionPanelType === 'line_items') {
        if (isCriteoRetailer(this.retailer)) {
          const minBidKey = this.actionMap[this.actionPanelType].minBidKey;
          obj.actionPayload.actionType =
            this.actionMap[this.actionPanelType].actionName;
          obj.actionPayload.line_item_id = data.line_item_id;
          obj.actionPayload.min_bid = data[minBidKey];
          (obj.viewPayload.actionType =
            this.actionMap[this.actionPanelType].actionName),
            (obj.viewPayload.line_item_id = data.line_item_id);
          obj.viewPayload.name = data.line_item_name;
        }
      }
      if (
        ['lineItemBaseSupplyBid', 'lineItemMaxSupplyBid'].includes(
          this.actionPanelType
        )
      ) {
        if (this.retailer === 'amazon') {
          obj.actionPayload.lineItemId = data.dsp_line_items_line_item_id;
          obj.viewPayload.lineItemId = data.dsp_line_items_line_item_id;
          obj.actionPayload.clientName = window.clientName;
          obj.viewPayload.clientName = window.clientName;
          obj.actionPayload.profileId = data.dsp_line_items_profile_id;
          obj.viewPayload.profileId = data.dsp_line_items_profile_id;
          obj.actionPayload.actionType =
            this.actionMap[this.actionPanelType].actionName;
        }
      }
      const targetMatchTypeKeyword = [
        'TARGETING_EXPRESSION',
        'TARGETING_EXPRESSION_PREDEFINED'
      ];
      if (
        targetMatchTypeKeyword.includes(
          (data?.match_type_keyword || data?.match_type)?.toUpperCase()
        ) &&
        data?.keyword_id
      ) {
        obj.actionType =
          this.apiVersion === 'metadataChange'
            ? 'targetMetadataChange'
            : 'targetBidChange';
        delete obj.actionPayload.keywordId;
        obj.actionPayload.targetId = data.keyword_id;
      }
      var newBidKey = this.actionMap[this.actionPanelType].newBid;
      var prevBidKey = this.actionMap[this.actionPanelType].previousBid;
      var rowDataKey = this.actionMap[this.actionPanelType].bidKey;
      obj.actionPayload[prevBidKey] = data[rowDataKey];
      obj.actionPayload[newBidKey] = '';
      obj.viewPayload[prevBidKey] = data[rowDataKey];
      obj.viewPayload[newBidKey] = '';
      obj = this.setAdditionalActionlogApiMetric(obj, data);
      return obj;
    },
    setAdditionalActionlogApiMetric(obj, data) {
      if (this.apiVersion === 'actionlog') {
        const dataKey = this.actionMap[this.actionPanelType].bidKey;
        obj.primaryKey = data[this.primaryKey];
        obj.widget = this.widget;
        obj.previousEntityValue = data[dataKey];
      }
      return obj;
    },

    checkForConfirmation(refObj, newBidKey, prevBidKey) {
      var obj = refObj.actionPayload;
      var prevVal = parseInt(obj[prevBidKey]);
      var newVal = parseInt(obj[newBidKey]);
      if (newVal >= 2 * prevVal) {
        this.valueConfirmationRequired = true;
      } else {
        this.valueConfirmationRequired = false;
        this.validationConfirmed = false;
      }
    },

    getNewBidValue(params, rowData) {
      const rowDataKey = this.actionMap[this.actionPanelType].bidKey;
      if (
        params.value.type === 'increaseBids' ||
        params.value.type === 'decreaseBids'
      ) {
        const mathsFunc = params.value.type === 'increaseBids' ? 'min' : 'max';
        let limit =
          params.value.type === 'increaseBids'
            ? this.validationObj[this.actionPanelType].absolute.max
            : this.validationObj[this.actionPanelType].absolute.min;
        let newBidVal = null;
        if (!this.isNullOrUndefined(limit)) {
          newBidVal = Math[mathsFunc](
            commons.getBidValue(params, rowData[rowDataKey]),
            limit
          );
        } else {
          newBidVal = commons.getBidValue(params, rowData[rowDataKey]);
        }
        return newBidVal.toString();
      } else {
        return parseFloat(params.value.value).toFixed(2);
      }
    },

    // updates the payload with updated values as per required calculations for bid/budget change
    processPayloadForBidChange(params, payloadObjRef, rowData) {
      var newBidKey = this.actionMap[this.actionPanelType].newBid;
      var prevBidKey = this.actionMap[this.actionPanelType].previousBid;
      var rowDataKey = this.actionMap[this.actionPanelType].bidKey;
      this.transformPayloadProcess(
        payloadObjRef,
        rowDataKey,
        rowData,
        'pre-process'
      );
      const newBidVal = this.getNewBidValue(params, rowData);
      if (
        params.value.type === 'increaseBids' ||
        params.value.type === 'decreaseBids'
      ) {
        payloadObjRef.actionPayload[newBidKey] = newBidVal;
        payloadObjRef.viewPayload[newBidKey] = newBidVal;
        if (this.apiVersion === 'actionlog') {
          payloadObjRef.newEntityValue = newBidVal;
        }
      } else {
        payloadObjRef.actionPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.actionPayload[newBidKey] = newBidVal;
        payloadObjRef.viewPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.viewPayload[newBidKey] = newBidVal;
        if (this.apiVersion === 'actionlog') {
          payloadObjRef.newEntityValue = newBidVal;
        }
      }

      this.transformPayloadProcess(
        payloadObjRef,
        rowDataKey,
        rowData,
        'post-process'
      );

      this.checkForConfirmation(payloadObjRef, newBidKey, prevBidKey);
    },

    transformPayloadProcess(payloadObjRef, rowDataKey, rowData, type) {
      if (this.transformPayload) {
        this.transformPayload(
          payloadObjRef,
          rowDataKey,
          this.primaryKey,
          rowData,
          type
        );
      }
    },

    // function that creates the payload which is used in API
    getPayload(params) {
      if (this.customPayload) {
        const keywordValues = {};
        this.selectedValues.forEach((item) => {
          keywordValues[item.data.keyword_id] = this.getNewBidValue(
            params,
            item.data
          );
        });
        return this.customPayload({
          bidChangePanelRef: this,
          values: keywordValues
        });
      }
      var paramsToReturn = [];
      for (let i = 0; i < this.selectedValues.length; i++) {
        var data = this.selectedValues[i].data;
        var obj = this.getInitialPayloadObject(data);
        this.processPayloadForBidChange(params, obj, data);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    // API call
    callBidActionApi(params) {
      let name = '';
      if (this.actionPanelType === 'keywords') {
        name = 'keyword(s)';
      } else if (this.actionPanelType === 'adskus') {
        name = 'Ad SKU(s)';
      } else if (
        [
          'lineItemBaseSupplyBid',
          'lineItemMaxSupplyBid',
          'line_items'
        ].includes(this.actionPanelType)
      ) {
        name = 'Line Items(s)';
      } else {
        name = 'campaign(s)';
      }
      var that = this;
      var payload = this.getPayload(params);
      var othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted for the selected ' + name,
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that?.parentRef?.switchBlueBar?.();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        params,
        payload,
        othersObj,
        this.apiVersion
      );
    }
  }
};
</script>
