<template>
  <div class="u-display-flex u-flex-direction-column u-width-100 u-height-100">
    <div
      v-if="showRefreshBar"
      class="refresh-bar u-spacing-pv-m u-spacing-ph-l u-bg-color-blue-base u-color-grey-white u-display-flex"
    >
      <rb-icon
        class="rb-icon--small u-spacing-mr-xs u-color-white-base"
        :icon="'info-circle-fill'"
      />
      <span class="u-font-size-5"
        >Updates on your actions are here. Please refresh to see the latest
        information.</span
      >
      <span
        class="u-font-size-5 u-cursor-pointer"
        style="margin-left: auto"
        @click="refreshActions"
        >REFRESH</span
      >
      <span @click="showRefreshBar = false"
        ><rb-icon
          class="rb-icon--small u-spacing-ml-s u-cursor-pointer u-color-white-base"
          :icon="'cross'"
      /></span>
    </div>
    <Loader
      v-if="!isLoaded"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />

    <div
      slot="detail"
      class="u-width-100 u-height-100 u-position-relative"
    >
      <!-- <Loader v-if="!isLoaded" class="fill--parent" :color="'#3fadf7'" :size="'4rem'" :thickness="'.2rem'"></Loader> -->
      <div
        v-if="selectedAlert"
        class="u-width-100 u-height-100 fill--parent"
      >
        <recommendation-detail-view />
      </div>
      <div
        class="u-width-100 u-height-100 u-display-flex u-flex-direction-column"
      >
        <div class="u-flex-0 u-position-relative u-bg-color-grey-white">
          <div
            v-show="!selectedAlert && isLoaded"
            class="u-flex-0 u-bg-color-grey-white u-spacing-ph-l u-header-box-shadow"
          >
            <!-- apply-filter removed, as emit does the same job. keeping apply -ilter was triggering the api call twice -->
            <!-- :apply-filter="reloadRecommendations" -->
            <rb-filter-panel
              id="recommendation-table-filters"
              :key="filterKey"
              :page="'recommendations'"
              :loader-status="loaderStatus"
              :enable-save="true"
              :data="filterData"
              :primary="primaryFilterData"
              :secondary-data="secondaryFilterData"
              :listener-event="'recommendations-filters'"
              :state="filterState"
              :has-search="false"
              :emit="'emitReloadRecommendations'"
              :on-create="onCreate"
              :filter-v2="filterV2"
              :show-ingestion-progress="filterV2"
              :enable-remove-all="false"
              @savedFilterApplied="filterKey++"
            >
              <div
                slot="leftSide-searchPanel"
                class="u-display-flex u-flex-align-items-center"
              >
                <span
                  v-if="
                    fetchedRecommendationDetails.recommendationName !== 'asin'
                  "
                  class="u-spacing-mr-s back-arrow"
                  @click.stop="backButton()"
                >
                  <rb-icon
                    :icon="'arrow_back'"
                    class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
                  />
                </span>
                <Bookmark
                  v-if="
                    fetchedRecommendationDetails.recommendationLabel !==
                    'All (SKUs)'
                  "
                  :title="fetchedRecommendationDetails.recommendationLabel"
                  :path="bookmarkPath"
                  :wrapper-class="'u-spacing-mr-xs'"
                />
                <h2
                  v-if="
                    fetchedRecommendationDetails.recommendationLabel !==
                    'All (SKUs)'
                  "
                  class="u-font-size-2 u-color-grey-base u-font-weight-bold"
                  data-cy="pageTitle"
                >
                  {{ fetchedRecommendationDetails.recommendationLabel }}
                </h2>
                <h2
                  v-else
                  class="u-font-size-2 u-color-grey-base u-font-weight-bold"
                >
                  Recommendations
                </h2>
              </div>
              <div
                v-if="
                  fetchedRecommendationDetails.recommendationName === 'asin'
                "
                slot="right-side"
                class="u-display-flex u-flex-align-items-center u-spacing-mt-xs"
              >
                <RecommendationsTypeTabs :selected-tab="'SKUs'" />
              </div>
            </rb-filter-panel>
          </div>

          <div
            v-show="!selectedAlert && isLoaded && !showActionsWorkspace"
            class="u-position-relative u-spacing-p-l u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
          >
            <div
              class="top-bar u-display-flex u-width-100 u-flex-justify-content-space-between"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-align-items-center"
              >
                <div class="u-display-flex u-flex-direction-row">
                  <!-- <span class="u-color-grey-base u-font-weight-600 u-font-size-4">
                    {{ fetchedRecommendationDetails.recommendationLabel }}
                  </span> -->
                  <span
                    v-if="fetchedRecommendationDescription"
                    class="u-color-grey-base u-font-size-7 u-display-block u-spacing-pt-s u-spacing-mr-s"
                  >
                    <rb-icon
                      class="u-color-grey-lighter rb-icon--small u-display-block"
                      icon="info-circle-fill"
                    />
                  </span>
                  <div class="u-display-block u-spacing-pt-s">
                    <span class="u-color-grey-mid-light u-font-size-7">
                      {{ fetchedRecommendationDescription }}
                    </span>
                    <div
                      v-show="showPreview"
                      v-tippy="previewTippyOptions"
                      class="preview-container u-display-inline-flex u-flex-align-items-baseline u-cursor-pointer u-spacing-ml-xxs"
                    >
                      <rb-icon
                        class="rb-icon--small u-spacing-mr-xxs u-color-grey-lighter"
                        :icon="'info-circle-fill'"
                      />
                      <span
                        class="preview-text u-font-size-7 u-line-height-1 u-color-grey-lighter"
                        >Preview</span
                      >
                    </div>
                    <div
                      :id="previewMessageId"
                      class="u-display-flex u-spacing-p-xs"
                    >
                      <img
                        :src="previewImageObj.src"
                        :width="previewImageObj.width"
                        alt="previewImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="u-display-flex u-flex-align-items-center u-spacing-ml-m"
              >
                <search-box
                  class="u-spacing-mr-m"
                  :search-string="searchText"
                  @localSearch="localSearch"
                />
                <quick-subscription
                  v-if="currentSelectedGroup !== 'All'"
                  :is-loading="isSubscriptionLoading"
                  :entity="selectedSubscriptionEntity"
                  :is-error="isSubscriptionError"
                  @subscribeCallback="subscribeCallback"
                  @manageSubscibeCallback="manageSubscibeCallback"
                />
                <icon-text-actions
                  v-for="action in actions"
                  :key="action.name"
                  mode="default"
                  :action="action"
                  @clicked="callAction"
                />
              </div>
            </div>
            <div
              v-if="showActionsBar"
              class="actions-bar--wrapper"
            >
              <actions-bar
                :close-fn="closeActionsWorkflow"
                :selections="selections"
                :recommendation-type="fetchedRecommendationType"
                :actions-config="actionsConfig"
                @open-workspace="openActionsWorkspace"
                @submit-non-boom-action="submitNonBoomAction"
              />
            </div>
          </div>

          <div
            v-if="showActionsWorkspace && !selectedAlert && isLoaded"
            class="u-spacing-mt-s"
          >
            <actions-workspace
              :current-primary-key="fetchedRecommendationPrimaryKey"
              :selections="selections"
              :total-selections="totalSelections"
              :valid-selections="validSelections"
              :submit-action="submitAction"
              :close-fn="closeActionsWorkflow"
              :requested-action="requestedWorkspaceAction"
            />
          </div>
        </div>
        <div
          v-if="!selectedAlert && isLoaded"
          class="u-flex-1"
          :class="{ 'is-unselectable': showActionsWorkspace }"
        >
          <rb-data-table
            :grid-options="gridOptions"
            :row-selection="rowSelection"
            :table-columns="tableColumns"
            :table-data="tableData"
            :get-current-instance="getCurrentInstance"
            :row-class-rules="rowClassRules"
            :primary-key="fetchedRecommendationPrimaryKey"
            :scroll-to-node-id="scrollToNodeId"
            :row-height="fetchedBcRecommendationType !== 'asin' ? 60 : 80"
            :no-rows-message="'No recommendations found.'"
          />
        </div>
      </div>
    </div>

    <remove-alert-dialog
      v-if="showRemoveAlertForm"
      :alert-label="currentAlertLabel"
      :close-fn="closeRemoveAlertForm"
      :apply-fn="removeRecommendation"
    />
    <manage-coloumns-widget
      v-if="showManageColumns"
      :close-fn="closeManageColumns"
      :current-name="fetchedRecommendationName"
      :column-customizations="columnCustomizations"
      :apply="applyColumnChanges"
    />
    <restore-removed-recommendations-widget
      v-if="showRemovedRecommendations"
      :close-fn="closeRemovedRecommendations"
      :rec-name="fetchedRecommendationName"
      :rec-type="fetchedRecommendationType"
      :apply-fn="restoreRecommedation"
    />
    <div
      v-if="showDelegatedActionConfirmation"
      class="actionContainer"
    >
      <component
        :is="showDelegatedActionConfirmation"
        :data="delegatedActionData"
        :callback="putDelegatedAction"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import widgetsMixin from '@/components/widgetsMixin';
import recommendationsMixin from '@/utils/mixins/recommendationsMixin';
import splitContainer from '@/components/layouts/splitContainer';
import searchBox from '@/components/widgets/searchBox.vue';
import actionsBar from '@/components/widgets/actionsBar.vue';
import Loader from '@/components/basic/loader.vue';
import rbFilterPanel from '@/components/widgets/filter-panel';
import recommendationDetailView from '@/components/pages/recommendations/recommendationDetailView';
import manageColoumnsWidget from '@/components/widgets/manageColoumnsWidget.vue';
import removeAlertDialog from '@/components/widgets/removeAlertDialog.vue';
import restoreRemovedRecommendationsWidget from '@/components/widgets/restoreRemovedRecommendationsWidget.vue';
import actionsConfig from '@/components/pages/recommendations/actions.js';
import recommendationsRoute from '@/components/pages/recommendations/route.js';
import boomerangActionsCell from '@/components/widgets/actions/boomerangActionsCell.vue';
import addCampaignCell from '@/components/widgets/actions/addCampaign.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import actionsWorkspace from '@/components/widgets/actions/actionsWorkspace.vue';
import helper from '@/utils/helpers/index.js';
import htmldiff from '@/utils/helpers/htmldiff.js';
import { eventBus } from '@/utils/services/eventBus';
import quickSubscription from '@/components/widgets/quickSubscription.vue';
import RecommendationsTypeTabs from '@/components/widgets/recommendationsTypeTabs.vue';
import constants from '@/utils/constants';
import additionalFilters from '@/components/pages/recommendations/additionalFilters.js';

export default {
  components: {
    helper,
    splitContainer,
    searchBox,
    Loader,
    rbFilterPanel,
    recommendationDetailView,
    manageColoumnsWidget,
    removeAlertDialog,
    restoreRemovedRecommendationsWidget,
    actionsBar,
    iconTextActions,
    actionsWorkspace,
    quickSubscription,
    RecommendationsTypeTabs
  },
  mixins: [recommendationsMixin, widgetsMixin],
  data() {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      selectionTimeout: 4000,
      selectedAsin: null,
      showRefreshBar: false,
      showActionsBar: false,
      showActionsWorkspace: false,
      requestedWorkspaceAction: null,
      actionsConfig: null,
      validSelections: 0,
      totalSelections: 0,
      previousSelections: 0,
      selections: [],
      gridOptions: {
        context: {
          componentParent: this
        },
        onRowClicked: this.recommendationSelectSku
      },
      tippyOptions: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      showManageColumns: false,
      secondaryFilterData: constants.secondaryFilterData,
      filterState: {
        getter: 'getRecommendationSelectedFilters',
        setter: 'setRecommendationSelectedFilters'
      },
      showRemovedRecommendations: false,
      showRemoveAlertForm: false,
      showDelegatedActionConfirmation: null,
      delegatedActionData: null,
      actions: [
        {
          icon: 'download',
          title: 'Download',
          method: 'download'
        },
        {
          icon: 'manage-coloumns',
          title: 'Columns',
          method: 'openManageColModal'
        },
        {
          icon: 'remove-fill',
          title: 'Removed',
          method: 'openRemovedRecModal'
        }
      ],
      recommendationTypeSearchKey: '',
      hideFilter: false,
      dataTableInstance: null,
      filterKey: 0,
      searchText: '',
      hasSubscribed: false,
      subscriptionEntity: {},
      previewImageObj: {},
      previewMessageId: 'preview-popup-message-' + this._uid,
      previewTippyOptions: {
        html: '#' + 'preview-popup-message-' + this._uid,
        reactive: true,
        distance: 4,
        placement: 'bottom-start',
        interactive: true,
        theme: 'dropdown',
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    bookmarkPath() {
      return `${
        this.$store.getters?.getRootPath
      }recommendations/${this.fetchedRecommendationDetails?.recommendationGroup?.toLowerCase()}?name=${
        this.fetchedRecommendationDetails?.recommendationName
      }&type=${
        this.fetchedRecommendationDetails?.recommendationType
      }&bc_recommendation_type=${
        this.fetchedRecommendationDetails?.bc_recommendation_type
      }`;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (
            (this.$store.getters.gethighlightRow.keyToSearch &&
              params.data[this.$store.getters.gethighlightRow.keyToSearch]) ||
            this.selectedAsin
          ) {
            var toHighlightAsin =
              params.data[
                this.$store.getters.gethighlightRow.keyToSearch || 'asin'
              ];
            var highlightedAsin =
              this.$store.getters.gethighlightRow.valueToMap ||
              this.selectedAsin;
            return highlightedAsin.indexOf(toHighlightAsin) > -1;
          } else {
            return false;
          }
        }.bind(this)
      };
    },
    rowSelection() {
      return {
        mode: this.areActionsEnabled ? 'multiple' : undefined,
        onSelectionChanged: this.onSelectionChanged
      };
    },
    currentSelectedGroup() {
      return this.$store.getters.getCurrentSubGroup;
    },
    currentActionRole() {
      return this.$store.state.alerts.actionRoleRequired;
    },
    fetchedFilteredRecommendationIndex() {
      const recommendationTypes = this.recommendationTypes;
      var index = recommendationTypes.findIndex(
        (typeObject) =>
          typeObject.recommendationType === this.fetchedRecommendationType
      );
      if (index > -1) {
        this.$store.dispatch(
          'currentSelectedRecommendation',
          this.recommendationTypes[index]
        );
      }
      return index === -1 ? 0 : index;
    },
    recommendationsRoute() {
      return recommendationsRoute;
    },
    recommendationTypes() {
      const recommendationTypes = this.$store.getters.getRecommendationTypes;
      const searchKey = this.recommendationTypeSearchKey.toLowerCase();
      const filteredRecommendations = recommendationTypes.filter((item) => {
        return item.recommendationLabel.toLowerCase().indexOf(searchKey) > -1;
      });
      return filteredRecommendations;
    },
    recommendationTypeInMemory() {
      return this.$store.getters.getRecommendationTypeInMemory;
    },
    recommendations() {
      return this.$store.getters.getRecommendations;
    },
    tableData() {
      return this.recommendations.map((item) => {
        if (
          item.alertType === 'Content change all' &&
          item.attributes.content_change_type !==
            'Change in number of images' &&
          item.attributes.content_change_type !== 'Change in Video Count' &&
          item.attributes.content_change_type !== 'Video Drop'
        ) {
          item.attributes.new_text_withOutChange = item.attributes.new_text;
          item.attributes.new_text = htmldiff(
            item.attributes.old_text || '',
            item.attributes.new_text || ''
          );
        } else if (item.alertType === 'Change in Variants') {
          var newVariantList = (item.attributes.new_variant_list || '').split(
            ','
          );
          var oldVariantList = (item.attributes.old_variant_list || '').split(
            ','
          );
          var addedVariants = newVariantList.filter(
            (n) => !oldVariantList.includes(n)
          );
          var removedVariants = oldVariantList.filter(
            (n) => !newVariantList.includes(n)
          );
          item.attributes.type_of_change =
            (addedVariants.length > 0
              ? addedVariants.length +
                ' Variant' +
                (addedVariants.length > 1 ? 's' : '') +
                ' added. '
              : '') +
            (removedVariants.length > 0
              ? removedVariants.length +
                ' Variant' +
                (removedVariants.length > 1 ? 's' : '') +
                ' dropped. '
              : '');
          item.attributes.new_variant_list = (
            item.attributes.new_variant_list || ''
          )
            .split(',')
            .sort()
            .join(', ');
          item.attributes.old_variant_list = (
            item.attributes.old_variant_list || ''
          )
            .split(',')
            .sort()
            .join(', ');
          item.attributes.new_variant_list_withOutChange =
            item.attributes.new_variant_list;
          item.attributes.new_variant_list = htmldiff(
            item.attributes.old_variant_list || '',
            item.attributes.new_variant_list || ''
          );
        }
        item.attributes.isLoading = false;
        return item.attributes;
      });
    },
    currentAlertLabel() {
      return this.recommendationTypes.filter(
        (item) => item.recommendationType === this.fetchedRecommendationType
      )[0].recommendationLabel;
    },
    columns() {
      return this.$store.getters.getColumns || [];
    },
    showDelegateActionColumn() {
      if (
        this.recommendations[0] &&
        this.recommendations[0].attributes &&
        this.recommendations[0].attributes.all_actions
      ) {
        return true;
      }
      return false;
    },
    isRemoveActionApplicable() {
      return this.fetchedBcRecommendationType === 'asin';
    },
    areActionsEnabled() {
      // let actionEnabledForRecommendationType = false
      // let enabledRecTypes = Object.keys(actionsConfig.enabledActions)
      // for (var i in enabledRecTypes) {
      //   if (enabledRecTypes[i] === this.fetchedRecommendationType) {
      //     actionEnabledForRecommendationType = true
      //     break
      //   }
      // }
      // return this.currentActionRole === 'MarketingIQ' && actionEnabledForRecommendationType
      return this.currentSelectedGroup !== 'All';
    },
    tableColumns() {
      const formatterFn = this.format;
      var colArr = this.columns.map((item) => {
        let cellRendererFramework = 'genericTableCell';
        if (item.keyType === 'bsr_link') {
          cellRendererFramework = 'alertsTableLinkCell';
        } else if (item.keyType === 'link') {
          cellRendererFramework = 'alertsTableBasicLinkCell';
        }

        // Content Change Recommendation - Column Component. It's of type string. so checking for UIKEY
        if (
          item.uiKey === 'deviation' &&
          item.tableField === 'deviation' &&
          this.fetchedRecommendationName === 'Content change all'
        ) {
          cellRendererFramework = 'alertLastUpdatedAt';
        }

        const increaseColumnWidthKeys = [
          'current_value_in_po',
          'event_type',
          'open_po_quantity',
          'open_purchase_order_quantity'
        ];
        var headerParam = {
          field: item.uiKey,
          suppressSizeToFit: false,
          minWidth:
            increaseColumnWidthKeys.indexOf(item.uiKey) !== -1
              ? 220
              : helper.setMinWidth(item),
          // (item.keyType === 'bsr_link' || (item.keyType === 'string' && item.uiKey !== 'asin')) ? 200 : 120,
          headerName: item.uiLabel,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            toolTipText: item.uiTooltip,
            keyType: item.keyType
          },
          cellRendererFramework: cellRendererFramework,
          cellRendererParams: {
            keyType: item.keyType,
            formatterFn: formatterFn
          }
        };
        var additionalFilter =
          additionalFilters.values[this.fetchedRecommendationType] &&
          additionalFilters.values[this.fetchedRecommendationType][item.uiKey];
        // Hiding Case Size filter option in Discrepancy type for spinmaster client
        if (
          additionalFilter?.key === 'discrepancy_type' &&
          Vue.options.filters.config_check(
            'feature.recommendations.po_discrepancy.discrepancyType.disableCaseSize'
          )
        ) {
          const indexOfValue = additionalFilter?.values?.findIndex((value) => {
            return value.title === 'Case Size';
          });
          if (indexOfValue !== -1) {
            additionalFilter?.values?.splice(indexOfValue, 1);
          }
        }
        // Add MOQ specific discrepacncy types in filters.
        if (
          additionalFilter?.key === 'discrepancy_type' &&
          Vue.options.filters.config_check(
            'feature.recommendations.po_discrepancy.moqDiscrepancyTypes.enable'
          )
        ) {
          const moqDiscrepencies = [
            'Units requested < IPIP',
            'Units requested not a multiple of IPIP'
          ];
          // increasing default width, as above labels as larger.
          additionalFilter.width = '320px';
          moqDiscrepencies.forEach((discrepancy) => {
            const indexOfValue = additionalFilter?.values?.findIndex(
              (value) => {
                return value.title === discrepancy;
              }
            );
            if (indexOfValue === -1) {
              additionalFilter.values.push({ title: `${discrepancy}` });
            }
          });
        }
        if (additionalFilter) {
          additionalFilter.values = additionalFilter.values.map((item) => {
            delete item.selected;
            return item;
          });
          headerParam.headerComponentParams.filter = {
            enable: true,
            width: additionalFilter.width,
            className: additionalFilter.className,
            values: additionalFilter.values,
            context: [additionalFilter.key],
            stateGetter: 'getRecommendationLocalFilters',
            onSelect: (context, value) => {
              const filterValue =
                this.$store.getters.getRecommendationLocalFilters?.filter || {};
              filterValue[context[0]] = value;
              if (filterValue[context[0]].length === 0) {
                delete filterValue[context[0]];
              }
              this.$store.dispatch('setRecommendationLocalFilters', {
                filterValueKey: 'filter',
                values: filterValue
              });
              this.reloadRecommendations();
            }
          };
        }
        return headerParam;
      });
      var skuDetailsColumn = {
        field: 'sku_details',
        minWidth: this.fetchedBcRecommendationType === 'asin' ? 276 : 180,
        suppressSizeToFit: false,
        suppressSorting: true,
        headerName: this.getColumnHeaderFromAlertType(),
        headerComponentFramework: 'genericTableHeader',
        cellRendererFramework: 'alertsTableSku',
        cellRendererParams: {
          product: this.currentActionRole
        },
        pinned: 'left',
        getQuickFilterText: (params) => {
          if (params.data.bc_recommendation_type === 'search_term') {
            return params.data.search_term;
          } else if (params.data.bc_recommendation_type === 'asin') {
            return params.data.title + ' ' + params.data.asin;
          } else if (params.data.bc_recommendation_type === 'brand') {
            return params.data.bc_brand_alert_type;
          } else if (params.data.bc_recommendation_type === 'keyword_text') {
            return params.data.keyword_text;
          } else if (params.data.bc_recommendation_type === 'campaign') {
            return params.data.campaign_name;
          } else {
            return params.data.title + ' ' + params.data.asin;
          }
        }
      };
      var actionsColumn = {
        hide: !this.areActionsEnabled && !this.isRemoveActionApplicable,
        field: 'state',
        suppressSizeToFit: true,
        width:
          this.isRemoveActionApplicable && this.areActionsEnabled ? 80 : 48,
        headerName: '',
        suppressSorting: true,
        cellRendererFramework: 'alertsTableActions',
        pinned: 'left',
        headerCheckboxSelectionFilteredOnly: true
      };
      var statusColumn = {
        hide: !this.areActionsEnabled,
        field: 'rec_status',
        width: 105,
        suppressSizeToFit: true,
        suppressSorting: true,
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Recommendation Status',
          keyType: 'string'
        },
        headerName: 'Status',
        cellRendererFramework: 'alertsTableStatus',
        cellRendererParams: {
          actions: actionsConfig.enabledActions[this.fetchedRecommendationType]
        },
        pinned: 'left'
      };
      var recommendationTypeColumn = {
        field: 'rec_types',
        minWidth: 600,
        suppressSorting: true,
        headerName: 'Recommendation types',
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Recommendation Types: Recommendation types for which the SKU appears. Clicking on the recommendation name in this column will redirect you to the specific recommendation details screen.'
        },
        cellRendererFramework: 'alertsTableChips',
        getQuickFilterText: (params) => {
          return params.data.recommendationNameType.reduce((prev, item) => {
            return prev + ' ' + item.recommendationLabel;
          }, '');
        }
      };

      colArr = [actionsColumn, statusColumn, skuDetailsColumn, ...colArr];
      if (this.showAllSpecificColumn) {
        colArr.push(recommendationTypeColumn);
      }

      if (this.actionsConfig?.enabledActions[this.fetchedRecommendationType]) {
        for (var arrIndex in colArr) {
          if (colArr[arrIndex].field === 'recommended_actions') {
            const actionObj = {
              cellRendererFramework: boomerangActionsCell,
              cellRendererParams: {
                actions:
                  this.actionsConfig.enabledActions[
                    this.fetchedRecommendationType
                  ]
              }
            };
            colArr[arrIndex] = Object.assign(colArr[arrIndex], actionObj);
          }
          if (colArr[arrIndex].field === 'campaign_list') {
            const actionObj = {
              cellRendererFramework: addCampaignCell,
              cellRendererParams: {
                actions:
                  this.actionsConfig.enabledActions[
                    this.fetchedRecommendationType
                  ]
              }
            };
            colArr[arrIndex] = Object.assign(colArr[arrIndex], actionObj);
          }
        }
      }
      return colArr;
    },
    columnCustomizations() {
      return JSON.parse(
        JSON.stringify(this.$store.getters.getColumnCustomizations)
      );
    },
    isLoaded() {
      if (this.$store.getters.areAlertsLoaded === true) {
        this.unHighlightRow();
      }
      return this.$store.getters.areAlertsLoaded;
    },
    hardFetchedSKU() {
      return this.$store.getters.getHardFetchedSKU;
    },
    isSubscriptionLoading() {
      return this.$store.getters.isLoading;
    },
    selectedSubscriptionEntity() {
      this.$store.dispatch('clearSubscribe');
      const entityDetails = this.$store.getters.getSubscriptionDetails;
      const entity =
        entityDetails.subScriptionDetails &&
        entityDetails.subScriptionDetails.subscriptionDetails &&
        entityDetails.subScriptionDetails.subscriptionDetails[0] &&
        entityDetails.subScriptionDetails.subscriptionDetails[0].entityInfo.filter(
          (item) => {
            return item.entityKey === this.fetchedRecommendationName;
          }
        )[0];
      this.subscriptionEntity = entity;
      return entity;
    },
    isSubscriptionError() {
      return this.$store.getters.isError;
    },
    showPreview() {
      if (this.fetchedRecommendationName === 'Suppressed ASIN') {
        this.previewImageObj.src = '/images/preview-suppressed.png';
        this.previewImageObj.width = 224;
        return true;
      } else if (this.fetchedRecommendationName === 'Unavailable SKUs all') {
        this.previewImageObj.src = '/images/preview-unavailable.png';
        this.previewImageObj.width = 224;
        return true;
      }
      return false;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'handleNavigation'
    },
    recommendations: function (newList, oldList) {
      this.showActionsWorkspace = false;
      this.showActionsBar = false;
      this.deselectAllAlerts();
      // this.subscriptionEntityDetails();
    }
  },
  created() {
    this.selectedAsin = this.$route.query.selectedAsin;
    // registering an event which is emitted to reload the recommendations by filter panel when saved filters are selected
    eventBus.$on('emitReloadRecommendations', (params) => {
      this.reloadRecommendations();
    });
  },
  mounted() {
    this.actionsConfig = actionsConfig;
    // get filters (gets it from the local storage first internally)
    this.fetchFilters(
      'recommendation_filter',
      'recommendations',
      this.customFilterData,
      Vue.options.filters.config_check('feature.product.ams_lite')
    );
    this.$store.dispatch('fetchDataColumns');
    this.$store.dispatch('setAppActiveTab', 3);
    this.$store.dispatch('fetchSubscriptionDetails');
    var roles = Vue.options.filters.config_check('users.validRoles');
    if (roles && roles.split) {
      roles = roles.split(',');
      if (roles.indexOf('salesIQ') === -1) {
        this.hideFilter = true;
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('clearRecommendationState');
    // removing the localStorage item ( recommendationActiveTab ) while navigating to all other routes other than recommendations.
    this.$store.dispatch(
      'clearRecommendationActiveTabLocalStorageKey',
      this.$route.path
    );
  },
  methods: {
    backButton() {
      this.$router.push(`${this.$store.getters.getRootPath}recommendations`);
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    subscribeCallback() {
      const fullFilterData = this.filterInstance.getFiltersFullMetadata();
      if (this.subscriptionEntity) {
        const objToSend = Object.assign({}, this.subscriptionEntity);
        objToSend.entityFilter = fullFilterData;
        objToSend.entityFilter =
          Object.keys(fullFilterData[0]).length > 0 ? fullFilterData : null;
        this.$store.dispatch('subscribe', objToSend).then(
          (response) => {
            if (
              response &&
              (response.message === 'Failure' ||
                (response.response &&
                  response.response.data &&
                  response.response.data.success === false))
            ) {
              this.$snackbar.open({
                message: 'Something Went Wrong !!!',
                duration: 5000,
                actionText: ''
              });
              return;
            }
            this.$snackbar.open({
              message: `You have subscribed to ${objToSend.entityLabel} recommendation.`,
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: 'MANAGE SUBSCRIPTIONS',
              onAction: () => {
                this.$router.push('/manageSubscription');
              }
            });
            this.$store.dispatch('fetchSubscriptionDetails');
          },
          (error) => {
            this.$snackbar.open({
              message: 'Something Went Wrong !!!' || error,
              duration: 5000,
              actionText: ''
            });
          }
        );
      } else {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!',
          duration: 5000,
          actionText: ''
        });
      }
    },
    manageSubscibeCallback(entity) {
      this.$router.push({
        path: '/manageSubscription',
        query: {
          mode: 'edit',
          name: entity.entityLabel
        }
      });
    },
    customFilterData(filterData) {
      return (filterData) => {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].profile_name === null) {
            filterData[i].profile_name = 'Others';
          }
        }
        return filterData;
      };
    },
    scrollToNodeId() {
      if (this.selectedAsin) {
        setTimeout(() => {
          Vue.nextTick(() => {
            this.selectedAsin = null;
            this.selectionTimeout = 4000;
            if (
              this.dataTableInstance &&
              this.dataTableInstance.gridOptions &&
              this.dataTableInstance.gridOptions.api
            ) {
              this.dataTableInstance.gridOptions.api.redrawRows();
            }
          });
        }, this.selectionTimeout);
      }
      return (
        this.$store.getters.gethighlightRow.valueToMap || this.selectedAsin
      );
    },
    getCurrentInstance(that) {
      this.dataTableInstance = that;
    },
    unHighlightRow() {
      const highlightData = {
        valueToMap: null,
        keyToSearch: null
      };
      if (this.currentSelectedGroup === 'All') {
        return;
      }
      setTimeout(() => {
        highlightData.valueToMap = null;
        this.$store.dispatch('setHighlightRow', highlightData);
        if (
          this.dataTableInstance &&
          this.dataTableInstance.gridOptions &&
          this.dataTableInstance.gridOptions.api
        ) {
          this.dataTableInstance.gridOptions.api.redrawRows();
        }
      }, 4000);
    },
    localSearch(input) {
      this.searchText = input;
      if (this.gridOptions.api) {
        this.gridOptions.api.setQuickFilter(input);
      }
    },
    openActionsWorkspace(action, deselectInvalidRows, originalSelections) {
      this.totalSelections = originalSelections;
      this.updateValidSelections(action, deselectInvalidRows);
      this.showActionsWorkspace = true;
      this.requestedWorkspaceAction = action;
    },
    closeActionsWorkflow() {
      this.showActionsWorkspace = false;
      this.showActionsBar = false;
      this.validSelections = 0;
      this.totalSelections = 0;
      this.deselectAllAlerts();
    },
    deselectAllAlerts() {
      if (this.gridOptions.api) {
        const selectedNodes = this.gridOptions.api.getSelectedNodes();
        selectedNodes.forEach((item) => {
          item.setSelected(false);
        });
      }
    },
    onSelectionChanged(selections, event) {
      this.selections = selections;
      if (this.selections.length > 0) {
        this.showActionsBar = true;
      } else {
        this.closeActionsWorkflow();
        this.validSelections = 0;
        this.totalSelections = 0;
      }
    },
    updateValidSelections(action, deselectInvalidRows) {
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      this.validSelections = selectedNodes.length;
      if (action && action.id.startsWith('nonBoom')) {
        selectedNodes.forEach((item) => {
          if (deselectInvalidRows) {
            item.setSelected(false);
            this.validSelections--;
          }
        });
      } else {
        if (action && action.id === 'edit') {
          this.validSelections = 1;
        } else {
          const that = this;
          selectedNodes.forEach((item) => {
            const currentActionType = JSON.parse(item.data.allowed_actions)[0];
            if (
              (item.data.action_state !== 'OPEN' &&
                item.data.action_state !== 'FAILED') ||
              !item.data.action_payload ||
              (action.id === 'approve' &&
                that.actionsConfig.allActions[currentActionType].approve ===
                  false)
            ) {
              if (deselectInvalidRows) {
                item.setSelected(false);
              }
              this.validSelections--;
            }
          });
        }
      }
      if (this.validSelections === 0) {
        this.$snackbar.open({
          message:
            'Sorry, none of the selected recommendations could be approved.',
          duration: 5000,
          actionText: ''
        });
      }
    },
    submitNonBoomAction(actionObj) {
      this.requestedWorkspaceAction = actionObj;
      this.validSelections = this.selections.length;
      this.totalSelections = this.selections.length;
      this.submitAction(false, actionObj);
    },
    submitAction(isEditAction, currentActionType, newActionPayload) {
      this.$nextTick(() => {
        this.selections.forEach((item) => {
          item.data.isLoading = true;
        });
      });
      const params = {
        selections: this.selections,
        action: this.requestedWorkspaceAction
      };
      if (isEditAction) {
        params.isEditAction = true;
        params.currentActionType = currentActionType;
        params.newActionPayload = newActionPayload;
      }
      this.showActionsBar = false;
      this.showActionsWorkspace = false;
      this.$store.dispatch('submitRecommendationAction', params).then(
        (resp) => {
          if (!this.requestedWorkspaceAction.id.startsWith('nonBoom')) {
            window.setTimeout(() => {
              this.showRefreshBar = true;
            }, 120000);
          }
          const recoText =
            this.validSelections > 1 ? ' recommendations ' : ' recommendation ';
          this.$snackbar.open({
            message:
              this.validSelections +
              recoText +
              this.requestedWorkspaceAction.successMsg,
            duration: 5000,
            actionText: ''
          });
          this.previousSelections = this.validSelections;
          this.validSelections = 0;
          this.totalSelections = 0;
          this.deselectAllAlerts();
        },
        (err) => {
          this.deselectAllAlerts();
          let msg =
            'The action you took failed due to a system issue. Please try again.';
          if (err.response && err.response.status === 405) {
            msg =
              'The system is currently under maintenance. Please try again after some time for the latest status.';
          }
          this.$snackbar.open({
            message: msg,
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    refreshActions() {
      this.showRefreshBar = false;
      this.reloadRecommendations();
      const recoText =
        this.previousSelections > 1
          ? ' recommendations were '
          : ' recommendation was ';
      this.$snackbar.open({
        message:
          this.previousSelections +
          recoText +
          this.requestedWorkspaceAction.successMsg,
        duration: 5000,
        actionText: ''
      });
      this.previousSelections = 0;
    },
    handleNavigation(newRoute, oldRoute) {
      if (oldRoute && oldRoute.query && oldRoute.query.internal_key) {
        this.selectedAsin = oldRoute.query.internal_key;
        this.selectionTimeout = 1500;
      }
      const newQueryParams = newRoute.query;
      const newRouteName = newRoute.name;
      const oldRouteName = oldRoute && oldRoute.name;
      let refreshRequired = true;
      this.$store.dispatch('setCurrentSubGroup', newRoute.name);
      // '/recommendations' route redirection
      if (newRouteName && newRouteName !== oldRouteName) {
        // did the secondary nav change?
        if (newQueryParams && Object.keys(newQueryParams).length) {
          this.$store.dispatch('setStateFromParams', newQueryParams);
        } else {
          this.$store.dispatch('clearRouteParamsFromState');
          this.$store.dispatch('clearRecommendationState');
        }
        this.$store.dispatch('fetchRecommendations', {
          refreshRequired,
          router: this.$router,
          queryParams: newQueryParams
        });
      } else if (newRouteName && newRouteName === oldRouteName) {
        // secondary nav remains same but recommendation type changes
        // did the call come from routechange within page
        // did the call come from routechange from other page
        // did the routechange happen because data was loaded
        if (newQueryParams && Object.keys(newQueryParams).length) {
          // route changed and all params are there
          this.$store.dispatch('setStateFromParams', newQueryParams);
          if (!oldRoute || oldRoute.query.type !== newQueryParams.type) {
            // has params changed really
            if (newQueryParams.type === this.recommendationTypeInMemory) {
              // was it fired because route was populated after backend call
              return;
            } else {
              this.$store.dispatch('fetchRecommendations', { refreshRequired });
            }
          } else {
            refreshRequired = false;
            this.$store.dispatch('fetchRecommendations', { refreshRequired });
          }
        }
      }
      this.closeActionsWorkflow();
    },
    selectRecommendationType(typeObject) {
      this.selections = [];
      this.showActionsBar = false;
      if (!this.isLoaded) {
        return;
      }
      this.searchText = '';
      this.$router.push({
        query: {
          name: typeObject.recommendationName,
          type: typeObject.recommendationType,
          bc_recommendation_type: typeObject.bc_recommendation_type
        }
      });
    },
    recommendationSelectSku(node) {
      if (this.showAllSpecificColumn) {
        return;
      }
      this.selectAlert(node.data);
    },
    onActionFunc(params) {
      this.openRemoveAlertDialog(params);
    },
    openRemoveAlertDialog(params) {
      this.showRemoveAlertForm = true;
      const recommendationObj = this.getObjectFromAsin(params.attributes.asin);
      const alertObj = {
        recommendationObj,
        event: params.event
      };
      this.$store.dispatch('loadRecommendationsForRemoval', alertObj);
    },
    closeRemoveAlertForm() {
      this.$store.dispatch('clearRecommendationsForRemoval');
      this.showRemoveAlertForm = false;
    },
    removeRecommendation(reason, snoozeRecommendationType) {
      this.showRemoveAlertForm = false;
      var params = {
        reason,
        snoozeRecommendationType,
        reload: true,
        key: this.$store.getters.getSingleAlertForRemoval.recommendationObj
          .attributes[this.fetchedBcRecommendationType],
        alertType:
          snoozeRecommendationType === 'ALL'
            ? this.fetchedBcRecommendationType
            : this.fetchedRecommendationType,
        alertName:
          snoozeRecommendationType === 'ALL'
            ? this.fetchedBcRecommendationType
            : this.fetchedRecommendationName,
        bc_recommendation_type: this.fetchedBcRecommendationType
      };
      this.$store.dispatch('removeRecommendation', params).then(() => {
        this.$snackbar.open({
          message: 'SKU removed from recommendation type',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: 'Undo',
          onAction: () => {
            this.restoreRecommedation(params);
          }
        });
      });
    },
    restoreRecommedation(params) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('restoreRecommendation', params).then(() => {
          this.$store.dispatch('clearRecommendationsForRemoval');
          if (params.reload) {
            this.reloadRecommendations();
          }
          resolve();
        });
      });
    },
    reloadRecommendations() {
      this.localSearch('');
      this.$store.dispatch('fetchRecommendations');
    },
    openManageColModal() {
      this.showManageColumns = true;
    },
    closeManageColumns() {
      this.showManageColumns = false;
    },
    async applyColumnChanges(columnCustomizations, selectedRecommendation) {
      try {
        await this.$store.dispatch(
          'updateUiLabelMapping',
          columnCustomizations
        );

        if (
          this.fetchedRecommendationName !==
          selectedRecommendation.recommendationName
        ) {
          this.$router.push({
            path:
              '/recommendations/' +
              selectedRecommendation.recommendationGroup.toLowerCase(),
            query: {
              name: selectedRecommendation.recommendationName,
              type: selectedRecommendation.recommendationType,
              bc_recommendation_type:
                selectedRecommendation.bc_recommendation_type
            }
          });
        } else {
          await this.$store.dispatch('fetchRecommendations');
        }
      } catch (error) {
        console.error('Unable to update and fetch recommendations' + error);
      }
    },
    openRemovedRecModal() {
      this.showRemovedRecommendations = true;
    },
    closeRemovedRecommendations() {
      this.$store.dispatch('clearRemovedRecommedationTypes');
      this.showRemovedRecommendations = false;
      this.reloadRecommendations();
    },
    callAction(action) {
      var method = action.method;
      this[method]();
    },
    subscriptionEntityDetails: function (newList, oldList) {
      this.$store.dispatch('clearSubscribe');
      const entityDetails = this.$store.getters.getSubscriptionDetails;
      const entity =
        entityDetails.subScriptionDetails &&
        entityDetails.subScriptionDetails.subscriptionDetails &&
        entityDetails.subScriptionDetails.subscriptionDetails[0] &&
        entityDetails.subScriptionDetails.subscriptionDetails[0].entityInfo.filter(
          (item) => {
            return item.entityKey === this.fetchedRecommendationName;
          }
        )[0];
      this.subscriptionEntity = entity;
    }
  }
};
</script>

<style scoped lang="css">
.currency,
.percent,
.number {
  text-align: right;
}
.ag-header-cell {
  text-transform: capitalize !important;
}
.string {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.actionContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.actions-bar--wrapper {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
}

.preview-text {
  text-decoration: underline;
}

.preview-container:hover .icon-info-circle-fill {
  color: #2b333b;
}
.preview-container:hover .preview-text {
  color: #2b333b;
}

.back-arrow .icon-arrow_back:hover {
  color: #2b333b;
  fill: #2b333b;
}
</style>
