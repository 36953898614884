<template>
  <div :key="pageKey">
    <div
      class="u-font-size-5 u-position-relative u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
    >
      Pages:
    </div>
    <loader
      v-if="loadAll"
      class="fill--parent"
      :color="'#3fadf7'"
    />
    <draggable
      v-model="selectedPages"
      @start="drag = true"
      @end="
        drag = false;
        onMoveCallback();
      "
    >
      <div
        v-for="(page, i) in selectedPages"
        :key="i"
        class="u-position-relative"
      >
        <div>
          <loader
            v-if="!loadAll && page && page.load"
            class="fill--parent"
            :color="'#3fadf7'"
          />
        </div>
        <div>
          <div
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
            data-cy="pageNumber"
          >
            Page {{ i + 1 }}
          </div>
          <div class="u-spacing-mt-s u-display-flex">
            <div
              class="u-display-flex u-height-100 u-flex-align-items-center u-flex-justify-content-flex-start"
            >
              <div class="u-display-flex u-flex-direction-column">
                <img
                  class="image-creative"
                  :src="page && page.selectedSku && page.selectedSku.image_url"
                />
                <div>
                  <rb-select
                    :send-details="true"
                    :on-close="(val, data) => selectNewSkuForPage(i, data)"
                    :options="(page && page.skus) || []"
                  >
                    <div
                      slot="trigger"
                      class="u-display-flex u-font-size-8 u-spacing-pt-s u-color-blue-base u-cursor-pointer u-cursor-pointer"
                      data-cy="selectSku"
                    >
                      Select SKU
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div
                        class="u-display-flex u-flex-align-items-center u-font-size-7"
                      >
                        <rb-sku
                          class="u-text-overflow-ellipsis"
                          :show-image="true"
                          :title="option.product_title"
                          :image-url="option.image_url"
                          :asin="option.asin"
                          :product-page-url="option.product_url"
                          :enable-s-d-p="false"
                        />
                      </div>
                    </template>
                  </rb-select>
                </div>
              </div>
              <div
                class="u-spacing-pl-m u-font-size-6 u-font-weight-600"
                data-cy="storePageName"
              >
                {{ page.storePageName }}
              </div>
              <rb-select
                :send-details="true"
                :on-close="(val, data) => selectNewStorePage(i, data)"
                :options="allPages"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-spacing-ph-xs u-cursor-pointer"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-base"
                    icon="caret-down"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-font-size-7"
                    data-cy="optionStorePageName"
                  >
                    {{ option.storePageName }}
                  </div>
                </template>
              </rb-select>

              <rb-icon
                class="rb-icon--small u-spacing-ml-xl u-cursor-move"
                :icon="'draggable-reorder'"
                data-cy="draggableIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import {
  fetchPageAsins,
  getAsinDetails
} from '@/components/ams/campaign_creation/steps/edit/campaignCreative/helper.js';
import transformer from '@/utils/services/data-transformer';
import loader from '@/components/basic/loader';

export default {
  components: {
    loader,
    draggable
  },
  props: {
    suggestedCampaignIndex: {
      type: Number,
      default: 0
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    allStepsData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      loadAll: false,
      selectedPages: [],
      allPages: [],
      pageKey: 0
    };
  },
  created() {
    this.setSelectedPage(true);
  },
  methods: {
    selectNewStorePage(index, data) {
      if (!data.length) {
        return;
      }
      this.selectedPages[index] = data[0];
      this.pageKey++;
      this.setSelectedPage();
      const { selectedPages, profile } =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      selectedPages[index].selectedSku = null;
      this.updateSelectedStore({ selectedPages });
      this.fetchAsinForAPage(this.selectedPages[index], profile);
    },
    selectNewSkuForPage(index, data) {
      if (!data.length) {
        return;
      }
      this.selectedPages[index].selectedSku = data[0];
      this.pageKey++;
      this.updateSelectedStore({ selectedPages: this.selectedPages });
    },
    async setSelectedPage(created) {
      const { storePageInfo, selectedPages, profile } =
        this.allStepsData.suggestedCampaigns[this.suggestedCampaignIndex];
      if (created) {
        this.selectedPages = selectedPages || storePageInfo?.slice(0, 3);
        this.loadAll = true;
        for (let page of this.selectedPages) {
          await this.fetchAsinForAPage(page, profile);
        }
        this.loadAll = false;
      }
      this.allPages = storePageInfo;
      this.updateSelectedStore({ selectedPages: this.selectedPages });
    },
    onMoveCallback() {
      this.setSelectedPage();
    },
    async fetchAsinForAPage(page, profile) {
      page.load = true;
      try {
        const response = await fetchPageAsins(
          page.storePageUrl,
          profile,
          this.$store
        );
        const asinList = response?.data?.response?.asinList || [];
        const skusResponse = await getAsinDetails(asinList);
        const skus = transformer.mergeResultDimension(skusResponse?.data, true);
        page.skus = skus;
        page.selectedSku = page.selectedSku || skus[0];
        page.load = false;
        this.pageKey++;
      } catch {
        this.pageKey++;
        page.load = false;
      }
      this.setSelectedPage();
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    }
  }
};
</script>

<style lang="css" scoped>
.image-creative {
  width: 46px;
  height: 46px;
}
</style>
