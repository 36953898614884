import HttpService from '@/utils/services/http-service';
import { replacePlaceHolderWithData } from '@/utils/common/dashboard-service-utils.js';
import { lzw_decode } from '@/utils/helpers/stringUtils.js';

export function getPinAndUnpinMessageForCalendarPin() {
  return {
    pinMessage:
      "'Pin' to persist the date for all dashboards related to Campaign Management.",
    unpinMessage:
      "'Un-Pin' to not persist the date for all dashboards related to Campaign Management."
  };
}

export function getBulkSearchConfigs(
  retailer,
  tabName,
  bulkSearchStringConstants
) {
  return {
    bulkSearchProps: {
      title: 'Bulk Search',
      findEntityInputPlaceholder:
        bulkSearchStringConstants?.findEntityInputPlaceholder,
      subTitle: bulkSearchStringConstants?.subTitle,
      maxPasteLimit: 1000
    },
    standardSearchConfigInsideBulkSearchProps: {
      emit: `${retailer}standardSearch`
    },
    dimensionNameForBulkSearch: bulkSearchStringConstants?.dimensionNameMapping,
    operator: 'IN',
    bulkSearchComponentRefName: `${retailer}${tabName}`,
    helpModalLocalStorageKey: `${retailer}${tabName}-bulk-search-help-dont-show`
  };
}

export function getAdSkusTabPrimaryKey(retailer) {
  const retailerList = [
    'albertsons_citrus',
    'hyvee_citrus',
    'shoprite_citrus',
    'target_citrus',
    'sainsburys_citrus',
    'waitrose_citrus',
    'tesco_citrus',
    'gopuff_citrus'
  ];
  if (retailerList.includes(retailer)) {
    return 'sku_id';
  }
  return 'sku_catalog_id';
}

const retailersWithMultipleCatalogs = ['ahold_citrus'];

export function getSearchKeyForAddAndRemoveSkusAction(
  retailer,
  isRemoveSkus = false
) {
  if (retailersWithMultipleCatalogs.includes(retailer)) {
    return 'search_with_catalog_text'; // Both for add skus and remove skus
  }
  if (isRemoveSkus) {
    return 'search';
  }
  return 'search_text';
}

export function placeholderTextForAddRemoveSkusSearchBox(retailer) {
  if (retailersWithMultipleCatalogs.includes(retailer)) {
    return 'Search by Sku Name, ID and Catalog Name.';
  }
  return 'Search by Sku Name and ID';
}

export function getDataMismatchComponentObjectArray(retailer) {
  const retailersToShowDataMismatchFor = ['hyvee_citrus'];
  if (retailersToShowDataMismatchFor.includes(retailer)) {
    return [
      {
        ui_component: 'DataMismatchBanner',
        props: {
          customClassArray: ['u-margin-right-auto']
        }
      }
    ];
  }
  return [];
}

export function emailRequestConfig(dashboardWrapperVueRef, tableMetaData) {
  // If metadata for the table is not present, then close the modal and skip everythin
  if (!tableMetaData) {
    dashboardWrapperVueRef.showModal = false;
    this.modalConfig = emailRequestConfig;
    return;
  }
  // In here, the this refers to the config object where you assign this function to
  // e.g if you assign this config under the campaigns config object, it will refer to that complete object.
  return {
    bodyTitle: {
      text: 'Email Report',
      highlightText: '',
      icon: 'email-subscription'
    },
    body: {
      text: 'Request for an email with a link to download this report.'
    },
    footer: {
      leftButton: {
        text: 'Cancel',
        onClick: () => {
          dashboardWrapperVueRef.showModal = false;
          this.modalConfig = emailRequestConfig;
        },
        icon: '',
        show: true
      },
      rightButton: {
        text: 'Request',
        onClick: async () => {
          this.modalConfig = emailConfirmConfig;
          const api = tableMetaData.downloadApiTemplate;
          const measuresListArrayForEmail = getMeasuresListArrayForEmail(
            dashboardWrapperVueRef,
            tableMetaData,
            api?.service
          );
          const keyToRead =
            api?.service?.toUpperCase() === 'BRANDS_SERVICE' ? 'alias' : 'name';
          const columnKeyToLabelMapping = Object.values(
            tableMetaData.metrics
          ).reduce((initial, item) => {
            if (item?.metadata?.visible === false) {
              return initial;
            }
            initial[item[keyToRead]] = item.label;
            return initial;
          }, {});
          const columnLabels = {};
          measuresListArrayForEmail.forEach((item) => {
            columnLabels[item] = columnKeyToLabelMapping[item];
          });
          const widgetRequestParams =
            dashboardWrapperVueRef.getWidgetRequestParams(
              dashboardWrapperVueRef.config.widgetNames?.chart
            );
          const globalRequestParams =
            dashboardWrapperVueRef.getGlobalRequestParams();
          const finalRequestParams = {
            ...widgetRequestParams,
            ...globalRequestParams
          };
          const finalRequest = replacePlaceHolderWithData(
            api.request,
            finalRequestParams
          );
          finalRequest.where.dimensionNameValueList =
            finalRequestParams[':dimensionNameValueList'];
          finalRequest.measuresList = getMeasureListForDownload(
            tableMetaData,
            api.service
          );
          finalRequest.customAPIDecisionVars.downloadRequest.columnLabels =
            columnLabels;
          try {
            await HttpService.post(api.service, finalRequest, {
              append: api.endPoint
            });
          } catch (err) {
            console.log(err);
          }
        },
        icon: '',
        show: true
      }
    }
  };
}

export function emailConfirmConfig(dashboardWrapperVueRef) {
  return {
    bodyTitle: {
      text: 'Request Recieved',
      highlightText: '',
      icon: 'Send'
    },
    body: {
      text: 'Look out for an email with a link to download the report.',
      subtext: 'Requests may take upto 5 minutes to be processed'
    },
    footer: {
      leftButton: {
        show: false
      },
      rightButton: {
        text: 'Done',
        onClick: () => {
          dashboardWrapperVueRef.showModal = false;
          this.modalConfig = emailRequestConfig;
        },
        icon: '',
        show: true
      }
    }
  };
}

export function calculateInputValue(inputValueObject, previousValue) {
  let val = null;
  if (inputValueObject?.inputType === 'dropdown') {
    if (
      previousValue === null &&
      !inputValueObject?.radioValue?.includes('set_new')
    ) {
      return null;
    }
    const delta = previousValue * (inputValueObject?.inputValue / 100);
    if (inputValueObject?.radioValue?.includes('increase_')) {
      val =
        inputValueObject?.dropdownValue === 'percentage'
          ? parseFloat((previousValue + delta).toFixed(2))
          : parseFloat(inputValueObject?.inputValue) + previousValue;
    } else if (inputValueObject?.radioValue?.includes('decrease_')) {
      val =
        inputValueObject?.dropdownValue === 'percentage'
          ? parseFloat((previousValue - delta).toFixed(2))
          : previousValue - parseFloat(inputValueObject?.inputValue);
    }
  } else if (inputValueObject?.inputType === 'input') {
    val = inputValueObject?.inputValue;
  }
  if (val < 0) {
    val = 0;
  }
  return val;
}

export const keywordRightTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '3',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const catalogNameColumnDefinition = {
  // currDefinition
  name: 'catalog',
  type: 'STRING',
  uiField: {
    uiLabel: 'Catalog',
    uiType: 'string',
    uiOrder: '2',
    metadata: {
      toolTipText: '',
      tableColumnName: 'catalog_name',
      showOnUi: true,
      width: 170
    }
  }
};

const hideCellFunctionLogic = (params) => {
  return params?.data?.hideInTable;
};

function getRetailer(vueRef) {
  return vueRef.$store.getters.getRetailer;
}

function showCategoryNameColumn(vueRef) {
  const retailer = getRetailer(vueRef);
  return retailer === 'ahold_citrus';
}

export const selectSkuLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_name',
          columnAsinKey: 'product_code',
          columnImageUrlKey: 'sku_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return `${params?.data?.product_title}_${params?.data?.asin}`;
          },
          hideCell: hideCellFunctionLogic
        }
      }
    },
    ...(showCategoryNameColumn(vueRef) ? [catalogNameColumnDefinition] : []),
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '3',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

export const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_name',
          columnAsinKey: 'product_code',
          columnImageUrlKey: 'sku_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: false,
          hideCell: hideCellFunctionLogic
        }
      }
    },
    ...(showCategoryNameColumn(vueRef) ? [catalogNameColumnDefinition] : []),
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '3',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export async function postKeywordActionSubmitReview(campaignId, retailer) {
  const payload = {
    widget: 'campaign',
    actionType: `${retailer}CampaignSubmitReview`,
    actionSource: {
      pageUrl: window.location.href
    },
    primaryKey: campaignId,
    actionPayload: {
      campaignId: campaignId,
      campaignType: 'productAds'
    },
    viewPayload: {},
    previousEntityValue: null,
    newEntityValue: null
  };
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', [payload]);
  } catch (err) {}
}

function isDuplicatePresentInArray(arrayToCheck) {
  // This method only works for array or strings/numbers
  const arrayItemToPresentMap = {};
  for (const item of arrayToCheck) {
    if (arrayItemToPresentMap[item]) {
      // This means the object already has this key, and hence is already present in the array
      return true;
    } else {
      arrayItemToPresentMap[item] = true;
    }
  }
  return false;
}
export function areSelectedCampaignsDistinct(rowSelections, keyForCampaignId) {
  const campaignIds = rowSelections.map(
    (item) => item.data?.[keyForCampaignId]
  );
  return !isDuplicatePresentInArray(campaignIds);
}

export const removeKeywordsLeftTable = (vueRef) => {
  return [
    {
      name: 'campaign_name',
      type: 'STRING',
      uiField: {
        uiLabel: 'Campaign Name',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'campaign_name',
          showOnUi: true,
          width: 200
        }
      }
    },
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          width: 140
        }
      }
    },
    {
      name: 'Select all',
      type: 'custom',
      uiField: {
        uiLabel: 'Select all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all keywords on this page'
        },
        uiOrder: '3',
        metadata: {
          toolTipText: 'Add this keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

export const removeKeywordsRightTable = (vueRef) => {
  return [
    {
      name: 'campaign_name',
      type: 'STRING',
      uiField: {
        uiLabel: 'Campaign Name',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'campaign_name',
          showOnUi: true,
          width: 200
        }
      }
    },
    {
      // currDefinition
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '2',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          width: 130
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '3',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const catalogNameColumnDefinitionForRemoveSkus = {
  ...catalogNameColumnDefinition,
  uiField: {
    ...catalogNameColumnDefinition.uiField,
    uiOrder: '3',
    metadata: {
      ...catalogNameColumnDefinition.uiField.metadata,
      tableColumnName: 'citrus_cm_sku_catalog_name'
    }
  }
};

export const removeSkusLeftTable = (vueRef) => {
  return [
    {
      name: 'campaign_name',
      type: 'STRING',
      uiField: {
        uiLabel: 'Campaign Name',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'campaign_name_to_show',
          showOnUi: true,
          width: 200
        }
      }
    },
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '2',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'citrus_cm_sku_sku_title',
          columnAsinKey: 'citrus_cm_sku_product_code',
          columnImageUrlKey: 'citrus_cm_sku_latest_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    ...(showCategoryNameColumn(vueRef)
      ? [catalogNameColumnDefinitionForRemoveSkus]
      : []),
    {
      name: 'Select all',
      type: 'custom',
      uiField: {
        uiLabel: 'Select all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all keywords on this page'
        },
        uiOrder: '4',
        metadata: {
          toolTipText: 'Add this keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: 'right',
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

export const removeSkusRightTable = (vueRef) => {
  return [
    {
      name: 'campaign_name',
      type: 'STRING',
      uiField: {
        uiLabel: 'Campaign Name',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'campaign_name_to_show',
          showOnUi: true,
          width: 200
        }
      }
    },
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '2',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'citrus_cm_sku_sku_title',
          columnAsinKey: 'citrus_cm_sku_product_code',
          columnImageUrlKey: 'citrus_cm_sku_latest_image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: '',
          columnDisableProductLink: true,
          copyClipboardText: 'SKU ID copied to clipboard',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    ...(showCategoryNameColumn(vueRef)
      ? [catalogNameColumnDefinitionForRemoveSkus]
      : []),
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '4',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Keyword',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: 'right',
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

const getMeasureList = (tableMetaData) =>
  Object.values(tableMetaData.metrics).reduce((list, item) => {
    if (
      !item.name.includes('activity_log') &&
      item?.metadata?.visible !== false
    ) {
      list.push(item.name);
    }

    return list;
  }, []);

const getMeasureListForDownload = (tableMetaData, service) => {
  const list = Object.values(tableMetaData.metrics).reduce((list, item) => {
    if (
      !item.name.includes('activity_log') &&
      item?.metadata?.visible !== false
    ) {
      if (service?.toUpperCase() === 'BRANDS_SERVICE') {
        list.push(item.alias);
      } else {
        list.push(item.name);
      }
    }
    return list;
  }, []);
  return list;
};

export const getChartWidgetHeaderOptionProps = (context) => ({
  props: {
    headerOptions: [
      {
        ...(!context.showPlotRowsChip
          ? {}
          : {
              ui_component: 'plotRowsChip',
              props: {
                numberOfPlottedSelections: context.rowSelections.length || 0
              },
              events: {
                removeChip: context.removeChip
              }
            })
      }
    ],
    enableRollup: false,
    enableToggle: true,
    enableDownload: false,
    enableWaterfall: true,
    selectedMetricLimit: 3,
    removeDropdown: false,
    disableAddNewMetricButton: false,
    headerContainerGridStyles: {
      display: 'flex',
      'justify-content': 'flex-end',
      gap: '1.6rem',
      'align-items': 'center'
    }
  }
});

export const getInitRequestParams = (
  pageName,
  listApiPageName,
  chartWidgetName,
  tableWidgetName
) => ({
  global: {
    // name here is going to be the pagename
    name: listApiPageName,
    title: pageName,
    ':from': '2021-10-21',
    ':to': '2021-10-27',
    ':pvpFrom': '2021-10-14',
    ':pvpTo': '2021-10-20',
    ':page': listApiPageName,
    ':entityType': '#ALLOVER_AGGREGATE'
  },
  [chartWidgetName]: {
    ':widget': chartWidgetName,
    ':timeseriesRollupBy': 'DAY',
    ':dimensionNameValueList': [],
    timeseries: 'report_date'
  },
  [tableWidgetName]: {
    ':widget': tableWidgetName,
    ':orderByList': [],
    ':dimensionNameValueList': []
  }
});

function getMeasuresListArrayForEmail(
  dashboardWrapperVueRef,
  tableMetaData,
  apiService
) {
  const measureList = getMeasureListForDownload(tableMetaData, apiService);
  if (dashboardWrapperVueRef?.widgetMetaData[1]) {
    const manageColumnsLSKey = 'globalManageColumnsPanelV2';
    const widgetMetaData = dashboardWrapperVueRef?.widgetMetaData[1];
    const manageColumnsPanelListener = `${widgetMetaData.widgetId}_${widgetMetaData.name}ManageColumnsPanel`;
    const globalManageColumnObject =
      JSON.parse(lzw_decode(localStorage.getItem(manageColumnsLSKey))) || {};
    const manageColumnsList =
      globalManageColumnObject[manageColumnsPanelListener] || [];
    if (manageColumnsList?.selectedColumns?.length > 0) {
      return manageColumnsList.selectedColumns.filter((value) =>
        measureList.includes(value)
      );
    }
    return measureList;
  }
  return measureList;
}

export function createAddKeywordsPayload(
  selectedCampaignIds,
  rows,
  retailer,
  isRadioEnabled
) {
  const payload = [];
  for (const selectedCampaign of selectedCampaignIds) {
    const campaignId = selectedCampaign?.data?.citrus_cm_campaign_campaign_id;
    const tempPayload = {
      widget: 'campaign',
      actionType: `${retailer}CampaignAddBulkKeywords`,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      previousEntityValue: '',
      newEntityValue: null
    };
    tempPayload.actionPayload = {
      campaignId,
      campaignType: 'productAds',
      keywords: rows.map((keywordItem) => ({
        new_keyword_matched_type:
          isRadioEnabled && keywordItem.match_type === 'phrase'
            ? 'MATCH_TYPE_PHRASE_MATCH'
            : 'MATCH_TYPE_EXACT_MATCH',
        new_keyword: keywordItem.keyword,
        new_keyword_is_suggested: false
      }))
    };
    tempPayload.viewPayload = {
      spreadViewPayload: true,
      viewPayloadArray: rows.map((keywordItem) => ({
        campaignId,
        campaign_name: selectedCampaign?.data?.citrus_cm_campaign_campaign_name,
        new_keyword: keywordItem.keyword,
        match_type: isRadioEnabled ? keywordItem.match_type : 'Exact'
      }))
    };
    payload.push(tempPayload);
  }
  return payload;
}

export function createRemoveSKUActionPayload(
  retailer,
  campaignIdToRowsMapping
) {
  const payload = [];
  Object.keys(campaignIdToRowsMapping).forEach((campaignId) => {
    const rowsMappedToTheCampaignId = campaignIdToRowsMapping[campaignId];
    const tempPayload = {
      widget: 'campaign',
      actionType: `${retailer}CampaignRemoveBulkProducts`,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      actionPayload: {
        campaignId: campaignId,
        campaignType: 'productAds',
        products: rowsMappedToTheCampaignId.map((rowObject) => ({
          remove_product: rowObject?.citrus_cm_sku_product_code,
          remove_product_catalog_id: rowObject?.citrus_cm_sku_catalog_id
        }))
      },
      viewPayload: {
        spreadViewPayload: true,
        viewPayloadArray: rowsMappedToTheCampaignId.map((rowObject) => ({
          product_code: rowObject?.citrus_cm_sku_product_code,
          product_name: rowObject?.citrus_cm_sku_sku_title,
          sku_image_url: rowObject?.citrus_cm_sku_latest_image_url
        }))
      },
      previousEntityValue: '',
      newEntityValue: ''
    };
    payload.push(tempPayload);
  });
  return payload;
}

export const createSKUActionPayload = (retailer, rows, selectedCampaign) => {
  const campaignId = selectedCampaign?.data?.citrus_cm_campaign_campaign_id;
  const payload = rows.map((skuItem) => ({
    widget: 'campaign',
    actionType: `${retailer}CampaignAddBulkProducts`,
    actionSource: {
      pageUrl: window.location.href
    },
    primaryKey: campaignId,
    actionPayload: {
      campaignId: campaignId,
      campaignType: 'productAds',
      products: [
        {
          new_product: skuItem?.product_code,
          new_product_catalog_id: skuItem?.catalog_id
        }
      ]
    },
    viewPayload: {
      spreadViewPayload: true,
      viewPayloadArray: [
        {
          campaignId: campaignId,
          campaignType: 'productAds',
          product_code: skuItem?.product_code,
          product_name: skuItem?.product_name,
          sku_image_url: skuItem?.sku_image_url
        }
      ]
    },
    previousEntityValue: '',
    newEntityValue: null
  }));
  return payload;
};

export function createRemoveKeywordsPayload(
  retailer,
  rows,
  campaignIdToRowsMapping
) {
  const payload = [];
  for (const campaignId in campaignIdToRowsMapping) {
    const rowsMappedToTheCampaignId = campaignIdToRowsMapping[campaignId];
    const tempPayload = {
      widget: 'campaign',
      actionType: `${retailer}CampaignRemoveBulkKeywords`,
      actionSource: {
        pageUrl: window.location.href
      },
      primaryKey: campaignId,
      actionPayload: {
        campaignId: campaignId,
        campaignType: 'productAds',
        remove_keyword: rowsMappedToTheCampaignId.map(
          (rowObject) => rowObject.keyword
        )
      },
      viewPayload: {
        spreadViewPayload: true,
        viewPayloadArray: rowsMappedToTheCampaignId.map((rowObject) => ({
          remove_keyword: rowObject.keyword
        }))
      },
      previousEntityValue: '',
      newEntityValue: ''
    };
    payload.push(tempPayload);
  }
  return payload;
}
export const citrusRetailersWithMatchType = ['gopuff_citrus'];

export const isRadioEnabled = (retailer) => {
  return citrusRetailersWithMatchType.includes(retailer);
};
