<template>
  <section
    v-if="step.name === 'Review & Save'"
    class="review_wrp u-width-100 u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <Strategy-Header :step="step" />
    <div
      class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-l"
    >
      <section class="u-display-flex u-spacing-mb-xl">
        <section class="u-display-flex u-width-100 u-align-items-flex-start">
          <!-- eslint-disable -->
          <div
            v-for="(step, $index) in allConfig"
            v-if="$index !== allConfig.length - 1"
            :key="$index"
            class="u-display-flex u-width-25 u-flex-direction-column u-spacing-pr-m u-border-width-s u-border-color-grey-xxx-light u-flex-justify-content-flex-start"
            :class="{
              'u-border-right u-spacing-mr-m': $index !== allConfig.length - 2
            }"
          >
            <div class="header u-font-weight-600 u-font-size-5 u-spacing-mb-s">
              <span>{{ $index + 1 }}.{{ step.name }}</span>
            </div>
            <div class="desc u-font-size-5">
              <span
                v-html="
                  selectedValues.strategyUIText &&
                  selectedValues.strategyUIText[step.textMapping]
                "
              />
              <!-- eslint-enable -->
            </div>
          </div>
        </section>
      </section>
      <!-- Section for Simulation -->
      <section class="u-width-100">
        <div
          class="pageBackground u-spacing-p-l u-display-flex u-flex-justify-content-space-between u-position-relative"
        >
          <div
            v-show="!executionObj.isSimulationLoading"
            class="u-width-100"
          >
            <section
              v-if="executionObj.isSimulationStatus === 'saved'"
              v-show="
                selectedValues.mode && selectedValues.mode !== 'create'
                  ? selectedValues.strategyStatus !== 'LIVE'
                  : true
              "
              class="u-display-flex u-flex-justify-content-space-between"
            >
              <span class="u-font-size-5"
                >See an output sample if the strategy runs now.</span
              >
              <span
                class="u-font-size-5 u-font-weight-600 u-cursor-pointer u-text-case-upper"
                :class="
                  selectedValues.enableSaveBtn
                    ? 'u-color-grey-base'
                    : 'u-color-blue-base'
                "
                @click="
                  !selectedValues.enableSaveBtn && callSaveStrategy('simulate')
                "
                >Simulate Strategy</span
              >
            </section>
            <section
              v-if="executionObj.isSimulationStatus === 'saved'"
              v-show="
                selectedValues.mode && selectedValues.mode !== 'create'
                  ? !(selectedValues.strategyStatus !== 'LIVE')
                  : false
              "
              class="u-display-flex u-flex-justify-content-space-between u-flex-direction-column"
            >
              <p
                class="u-spacing-mb-s u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  :icon="'warning'"
                  class="rb-icon--medium u-cursor-pointer u-color-blue-base"
                />
                <span class="u-font-size-3 u-spacing-ml-s u-color-blue-base"
                  >Simulating a live strategy will pause and save it as a
                  draft.</span
                >
              </p>
              <p
                class="u-font-size-5 u-spacing-mb-s u-spacing-ml-l u-line-height-1-3"
              >
                Click on “Prioritise and Save” to run the strategy again.
              </p>
              <span
                class="u-font-size-5 u-font-weight-600 u-cursor-pointer u-spacing-mt-l u-display-flex u-flex-align-items-center u-color-blue-base u-spacing-ml-l u-text-case-upper"
                @click="callSaveStrategy('simulate')"
              >
                CONFIRM SIMULATION
              </span>
            </section>
            <section v-if="executionObj.isSimulationStatus === 'running'">
              <p
                class="u-spacing-mb-s u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  :icon="'info-circle-fill'"
                  class="rb-icon--medium u-cursor-pointer u-color-blue-base"
                />
                <span class="u-font-size-3 u-spacing-ml-s u-color-blue-base"
                  >Simulating…</span
                >
              </p>
              <p
                class="u-font-size-5 u-spacing-mb-s u-spacing-ml-l u-line-height-1-3"
              >
                The output file will be ready in an hour. We will email you the
                file, once it is ready. It will also be available to download
                here.
              </p>
              <span
                class="u-font-size-5 u-font-weight-600 u-cursor-pointer u-spacing-mt-l u-display-flex u-flex-align-items-center u-color-blue-base u-spacing-ml-l"
                @click="getExecutionStatus(executionObj)"
              >
                <rb-icon
                  :icon="'revert'"
                  class="u-color-blue-base rb-icon--medium u-cursor-pointer u-spacing-mr-xs"
                />
                Check Simulation Status
              </span>
            </section>
            <section v-if="executionObj.isSimulationStatus === 'success'">
              <div
                class="u-font-size-5 u-display-flex u-spacing-mb-l u-display-flex u-flex-direction-column"
              >
                <span class="u-color-green-base u-font-size-3">
                  <rb-icon
                    :icon="'check-fill-circle'"
                    class="u-color-green-base rb-icon--medium u-cursor-pointer u-spacing-mr-s"
                  />Simulation Successful.</span
                >
                <p class="u-spacing-mt-s u-spacing-ml-l u-color-grey-base">
                  <span :class="{ disabled: downloadURL.length === 0 }">
                    <a
                      class="u-color-blue-base"
                      :class="{ 'no-pointer-events': downloadURL.length === 0 }"
                      download="simulationOutput"
                      :href="downloadURL"
                      >Click here</a
                    >
                  </span>
                  to download the latest file or please check your e-mail.
                </p>
              </div>
              <div class="u-display-flex u-flex-justify-content-space-between">
                <span class="u-font-size-5 u-color-grey-base"
                  >See the output if the strategy runs now.</span
                >
                <span
                  class="u-font-size-5 u-font-weight-600 u-cursor-pointer u-color-blue-base u-text-case-upper"
                  @click="callSaveStrategy('simulate')"
                  >Simulate Strategy</span
                >
              </div>
            </section>
            <section v-if="executionObj.isSimulationStatus === 'error'">
              <p
                class="u-spacing-mb-s u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  :icon="'cross-fill-circle'"
                  class="rb-icon--medium u-cursor-pointer u-color-red-base"
                />
                <span class="u-font-size-3 u-spacing-ml-s u-color-red-base"
                  >Simulation Unsuccessful</span
                >
              </p>
              <p
                class="u-font-size-5 u-spacing-mb-s u-spacing-ml-l u-line-height-1-3"
              >
                Due to a technical error the simulation couldn’t complete.
              </p>
              <span
                class="u-font-size-5 u-font-weight-600 u-cursor-pointer u-spacing-mt-l u-display-flex u-flex-align-items-center u-color-blue-base u-spacing-ml-l u-text-case-upper"
                @click="simulateStrategy()"
              >
                Retry Simulation
              </span>
            </section>
          </div>
          <Loader
            v-show="executionObj.isSimulationLoading"
            class="fill--parent"
            :color="'#3fadf7'"
            :size="'2rem'"
            :thickness="'.2rem'"
          />
        </div>
      </section>
      <section
        class="u-spacing-mt-l u-display-flex u-flex-direction-column u-margin-bottom-72"
      >
        <div class="u-width-100">
          <h3 class="u-font-size-2 u-text-align-left u-padding-0">
            Prioritise and Save this strategy.
          </h3>
          <!-- <p class="step-desc u-font-size-5 u-spacing-mt-s u-color-grey-lighter u-line-height-1-3">
      Lorem Ipsum DOlor Sit Lorem Ipsum DOlor Sit Lorem Ipsum DOlor
    </p> -->
        </div>
        <div class="u-spacing-mt-l">
          <rb-button
            id="confirmButton"
            :text="'Back'"
            :type="'hollow'"
            :click-fn="movePrev.bind(this)"
            class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s"
          />
          <rb-button
            v-if="
              selectedValues.mode && selectedValues.mode !== 'create'
                ? selectedValues.strategyStatus !== 'LIVE'
                : true
            "
            id="confirmButton"
            :text="'Save draft'"
            :type="'hollow'"
            :click-fn="callSaveStrategy.bind(this, 'draft')"
            class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s u-color-blue-base u-border-all u-border-color-blue-base u-border-width-s"
          />
          <rb-button
            id="confirmButton"
            :text="'Prioritise and Save'"
            :type="'filled'"
            :click-fn="callSaveStrategy.bind(this)"
            :disabled="selectedValues.enableSaveBtn"
          />
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import StrategyHeader from './header';
import Loader from '@/components/basic/loader';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'ReviewStep',
  components: {
    StrategyHeader,
    Loader,
    iconTextActions
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    allConfig: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      isLoading: false,
      downloadURL: '',
      executionObj: {
        isSimulationStatus: 'saved',
        isSimulationLoading: false,
        data: {}
      }
    };
  },
  created() {
    eventBus.$on('simulateStrategy', this.simulateStrategy);
  },
  mounted() {
    // let that = this;
    // eventBus.$on('simulateStrategy', params => {
    //   that.simulateStrategy();
    // });
  },
  beforeDestroy() {
    eventBus.$off('simulateStrategy', this.simulateStrategy);
  },
  methods: {
    movePrev() {
      this.$emit('prevStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    callSaveStrategy(params) {
      this.saveStrategy(params);
    },
    simulateStrategy() {
      this.executionObj.isSimulationLoading = true;
      const id = this.$route.query.id;
      if (id) {
        const dataToSend = {
          clientId: id.split(':')[0],
          draftStrategy: true,
          strategyList: [id]
        };
        this.$store.dispatch('simulateStrategy', dataToSend).then(
          (response) => {
            this.executionObj.isSimulationLoading = false;
            this.executionObj.isSimulationStatus = 'running';
            this.executionObj.data = response.data;
          },
          (error) => {
            this.executionObj.isSimulationLoading = false;
            this.executionObj.isSimulationStatus = 'error';
            this.$snackbar.open({
              message:
                (error.data && error.data.message) ||
                'Strategy Simulation Failed !!!',
              duration: 5000,
              actionText: ''
            });
          }
        );
      }
    },
    getExecutionStatus(params) {
      this.executionObj.isSimulationLoading = true;
      this.$store.dispatch('getSimulationStatus', params.data).then(
        (response) => {
          this.executionObj.isSimulationLoading = false;
          this.executionObj.isSimulationStatus = 'running';
          if (response.data.executionStatus === 'RUNNING') {
            this.executionObj.isSimulationStatus = 'running';
          } else if (response.data.executionStatus === 'FAILURE') {
            this.executionObj.isSimulationStatus = 'error';
          } else if (response.data.executionStatus === 'SUCCESS') {
            this.getSimulationOutput(response.data);
            this.executionObj.isSimulationStatus = 'success';
          } else {
            this.executionObj.isSimulationStatus = 'error';
          }
        },
        (error) => {
          this.executionObj.isSimulationLoading = false;
          this.executionObj.isSimulationStatus = 'error';
          this.$snackbar.open({
            message:
              (error.data && error.data.message) || 'Something Went Wrong !!!',
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    getSimulationOutput(params) {
      this.downloadURL = '';
      this.$store.dispatch('getSimulationOutput', params).then(
        (data) => {
          if (
            data.data &&
            data.data.success === true &&
            data.data.downloadUrl
          ) {
            this.executionObj.isSimulationStatus = 'success';
            this.downloadURL = data.data.downloadUrl;
          } else {
            this.executionObj.isSimulationStatus = 'error';
          }
        },
        (error) => {
          this.$snackbar.open({
            message:
              (error.data && error.data.message) || 'Something Went Wrong !!!',
            duration: 5000,
            actionText: ''
          });
        }
      );
    }
  }
};
</script>
<style lang="css" scoped>
.u-margin-bottom-72 {
  margin-bottom: 72px;
}
.no-pointer-events {
  pointer-events: none;
}
</style>
