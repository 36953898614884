import HttpService from '@/utils/services/http-service';
import dictionary from '@/pages/entity_details/configs/amazon/dictionary.js';
import { getColumnDefinition } from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';
import { cloneDeep } from 'lodash';
import { addSkuOnInput } from '@/components/ams/campaign_creation/edit_configs/amazon/common/skuEnterListHelpers.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { formatter } from '@/utils/helpers/formatter.js';
import {
  addAll,
  addEntity,
  leftCustomBidValues
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/bulkCustomBid.js';
import HttpLayer from '@/utils/services/http-layer';
import {
  keywordAdditionSuccessObject,
  fewKeywordAdditionFailureObject,
  keywordAdditionFailureObject,
  getSnackbarMessageObjectForKeywordAddition
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';
export const SD_TACTIC_PRODUCT_ADGROUP = 'T00020';
export const SD_TACTIC_AUDIENCE_ADGROUP = 'T00030';

const categoryHierarchyExpandLevels = [
  {
    level: 1,
    getter: 'campaignCreation/getAllCategoriesLevel1',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 2,
    getter: 'campaignCreation/getAllCategoriesLevel2',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 3,
    getter: 'campaignCreation/getAllCategoriesLevel3',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 4,
    getter: 'campaignCreation/getAllCategoriesLevel4',
    action: 'campaignCreation/fetchCategoriesLevel'
  },
  {
    level: 5,
    getter: 'campaignCreation/getAllCategoriesLevel5',
    action: 'campaignCreation/fetchCategoriesLevel'
  }
];

const audienceHierarchyExpandLevels = [
  {
    level: 1,
    getter: 'campaignCreation/getAllAudiencesLevel1',
    action: 'campaignCreation/fetchAudienceLevel'
  },
  {
    level: 2,
    getter: 'campaignCreation/getAllAudiencesLevel2',
    action: 'campaignCreation/fetchAudienceLevel'
  },
  {
    level: 3,
    getter: 'campaignCreation/getAllAudiencesLevel3',
    action: 'campaignCreation/fetchAudienceLevel'
  },
  {
    level: 4,
    getter: 'campaignCreation/getAllAudiencesLevel4',
    action: 'campaignCreation/fetchAudienceLevel'
  },
  {
    level: 5,
    getter: 'campaignCreation/getAllAudiencesLevel5',
    action: 'campaignCreation/fetchAudienceLevel'
  }
];
const allCatergoriesLevelCube = {
  cubeName: 'categories_master',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: ['id', 'name', 'level1id', 'level1name'],
  groupByDimensionsList: ['id', 'name', 'level1id', 'level1name'],
  dimensionList: [],
  orderByList: [],
  dedupBeforeRollup: {
    enableDedupBeforeRollup: true,
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'id',
      'level1name'
    ]
  }
};
let productAndCategoryRightTableRows = [];
export const generateMatchTypeRows = (row, matchType, showKeyword) => {
  const tempRow = JSON.parse(JSON.stringify(row));
  const tempRecommendedKeyword = tempRow.recommended_keyword
    ? tempRow.recommended_keyword.toLowerCase()
    : null;
  tempRow.keyword = showKeyword ? tempRow.recommended_keyword : ' ';
  tempRow.key = tempRecommendedKeyword + '&' + matchType;
  tempRow.match_type = matchType;
  if (matchType === 'exact') {
    tempRow.bid = tempRow.exact_bid || '-';
    tempRow.newBid = tempRow.exact_bid || null;
  }
  if (matchType === 'broad') {
    tempRow.bid = tempRow.broad_bid || '-';
    tempRow.newBid = tempRow.broad_bid || null;
  }
  if (matchType === 'phrase') {
    tempRow.bid = tempRow.phrase_bid || '-';
    tempRow.newBid = tempRow.phrase_bid || null;
  }
  if (matchType === 'negativeExact') {
    tempRow.matchTypeToShow = 'Negative Exact';
  }
  if (matchType === 'negativePhrase') {
    tempRow.matchTypeToShow = 'Negative Phrase';
  }
  return tempRow;
};

export const addNegativeKeywordsEnterListComponentConfig = (context) => {
  const isCampaignLevel = !(context.entityId && context.parentEntityId);
  const adgroupId = isCampaignLevel
    ? context?.tabMetadata?.adgroup_id
    : context.entityId;
  const campaignId = isCampaignLevel
    ? context.entityId
    : context.parentEntityId;
  const adgroupName = context.getAdGroupInfo?.data?.name || ''; // update this to actual ad group name
  return {
    ui_component: 'customActionPanel',
    props: {
      staticHeaderText: 'Add Negative Keywords',
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              noRowsRightTable: 'Add negative keywords to this list',
              selections: '',
              noRowsLeftTable: 'No negative keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: isCampaignLevel
                        ? 'campaign_id'
                        : 'adgroup_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  columnDefs:
                    dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  search: {
                    enableSearch: false
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    defaultSelected: ['Negative phrase', 'Negative exact'],
                    items: ['Negative Phrase', 'Negative Exact']
                  }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'negative keyword',
                      buttonLabel: 'Add negative keywords'
                    }
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Negative Phrase', 'Negative Exact']
                  }
                }
              },
              defaultTab: 'Enter List',
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  const newRows = [];
                  let filterPhrase = false;
                  let filterExact = false;
                  const checkBoxValues = vueRef.leftFilterCheckBoxArray
                    .toString()
                    .toLowerCase();
                  filterPhrase = checkBoxValues.includes('phrase');
                  filterExact = checkBoxValues.includes('exact');
                  if (
                    (!filterPhrase && !filterExact) ||
                    (filterPhrase && filterExact)
                  ) {
                    filterPhrase = true;
                    filterExact = true;
                  }
                  vueRef.leftTableData.rows.forEach((row) => {
                    if (filterPhrase) {
                      const phrase = generateMatchTypeRows(
                        row,
                        'negativePhrase',
                        true
                      );
                      newRows.push(phrase);
                    }
                    if (filterExact) {
                      const exact = generateMatchTypeRows(
                        row,
                        'negativeExact',
                        !filterPhrase && filterExact
                      );
                      newRows.push(exact);
                    }
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: [
                  {
                    dimensionName: isCampaignLevel
                      ? 'campaign_id'
                      : 'adgroup_id',
                    dimensionValue: context.entityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName:
                    'ams_cm_negativekeyword_recommendations_inefficient_searchterms',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: ['recommended_keyword', 'acos14d'],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'acos14d',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                    vueRef
                  ),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keyword === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Negative Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs:
                  dictionary.add_negative_keywords_sp_and_sb.right(vueRef)
              },
              headerText: () =>
                `Added Negative Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              const campaignType = context.entityConfig.entityType;
              return new Promise((resolve, reject) => {
                const payload = rows.map((row) => {
                  let matchType = null;
                  if (row?.match_type === 'negativeExact') {
                    matchType = 'Negative Exact';
                  }
                  if (row?.match_type === 'negativePhrase') {
                    matchType = 'Negative Phrase';
                  }
                  return {
                    actionType: 'addNegativeKeyword',
                    actionSource: {
                      pageUrl: window.location.href
                    },
                    widget: 'campaign',
                    primaryKey: campaignId,
                    actionPayload: {
                      campaignId,
                      campaignType,
                      adgroup_id: adgroupId,
                      new_keyword_text: row.recommended_keyword,
                      new_match_type: row.match_type
                    },
                    viewPayload: {
                      campaignId,
                      campaignType,
                      adgroupId,
                      adgroupName,
                      keywordText: row.recommended_keyword,
                      matchType
                    }
                  };
                });
                HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                  .then((response) => {
                    if (response?.data?.success) {
                      const arrayToTraverse = response?.data?.response;
                      let messageObject =
                        getSnackbarMessageObjectForKeywordAddition(
                          arrayToTraverse,
                          {
                            allSuccessObject: keywordAdditionSuccessObject,
                            someFailedObject: fewKeywordAdditionFailureObject,
                            allFailedObject: keywordAdditionFailureObject
                          }
                        );
                      context.$snackbar.open(messageObject);
                    } else {
                      context.$snackbar.open(keywordAdditionFailureObject);
                    }
                    vueRef.$emit('afterAPIActionToCall');
                    resolve(true);
                  })
                  .catch((err) => {
                    context.$snackbar.open(keywordAdditionFailureObject);
                    reject(err);
                  });
              });
            }
          };
        }
      }
    },
    events: {
      afterAPIActionToCall(payload) {
        context.showAddNegativeTargetingPanel = false;
        context.reloadComponent(true);
      },
      closePanelMethod() {
        context.showAddNegativeTargetingPanel = false;
        context.closeActionBar();
      }
    }
  };
};

export const actionPanelAddCustomProductsConfig = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
          primaryKey: 'asin',
          isMultipleAllowed: true,
          default: {
            hideHeader: false,
            // hideFooter: true,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple line items are not allowed'
          },
          left: {
            rowHeight: 68,
            tabs: {
              Search: {
                localFilter: {
                  // dimensionName: 'campaign_id',
                  // dimensionValue: context.entityId
                },
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  placeholder: 'Find a product',
                  searchKey: 'asin_search_text'
                },
                showFilterCheckBox: false
                // filterCheckBoxSource: {
                //   header: 'Filter by:',
                //   items: ['Broad', 'Phrase', 'Exact']
                // }
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              filters: {
                emit: 'fetchFiltersCampaignCreation',
                hasSearch: false,
                page: 'amazon'
              },
              filterState: {
                getter: 'getExecuteApiTableDataFilters',
                setter: 'fetchTableDataExecuteApiFilters'
              },
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              localFilters: [],
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              useExistingWhereClause: true,
              APIConfig: {
                cubeName: 'asin_campaign_relationship_catalogue_asin',
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [
                  'product_title',
                  'product_url',
                  'image_url',
                  'brand',
                  'sku_price',
                  'weeks_of_coverage',
                  'availability_status'
                ],
                groupByDimensionsList: ['asin'],
                orderByList: [
                  {
                    dimension: 'product_title',
                    direction: 'ASC'
                  }
                ],
                where: {
                  dimensionNameValueList: [
                    {
                      dimensionName: 'adgroup_id',
                      dimensionValue: context.$route?.params?.subEntityId,
                      operator: 'NOT_LIKE'
                    }
                  ]
                },
                limit: 10,
                page: 1
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {
            context.addProducts(rows);
          }
        };
      }
    },
    staticHeaderText: 'ADD PRODUCTS'
  },
  events: {
    afterAPIActionToCall(payload) {
      // this.$emit('openSnackbar', 'Action has been submitted');
      // this.switchBlueBar('line item');
      // this.removeCustomCustomComponents();
      context.closeActionBar();
    },
    closePanelMethod() {
      context.showAddCustomProductPanel = false;
      context.closeActionBar();
    }
  }
});

const selectSkuLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return params?.data?.product_title + '_' + params?.data?.asin;
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'sku_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'sku_price',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'weeks_of_coverage',
      type: 'STRING',
      uiField: {
        uiLabel: 'Weeks of coverage(Amazon)',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'weeks_of_coverage',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availablity',
        uiType: 'string',
        uiOrder: '',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];
};

const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'asin',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

// -- Begin -- Product and category custom action panel
const getDataPayload = (context) => {
  let whereClause = {
    operator: 'NOT_LIKE'
  };
  if (context.$route?.params?.subEntityId) {
    whereClause.dimensionName = 'adgroup_id';
    whereClause.dimensionValue = context.$route?.params?.subEntityId;
  } else if (
    context.$route?.params?.entityType === 'sb' &&
    context?.tabMetadata?.targetingType === 'product & category'
  ) {
    whereClause.dimensionName = 'adgroup_id';
    whereClause.dimensionValue = context.tabMetadata.adgroup_id;
  } else {
    whereClause.dimensionName = 'campaign_id';
    whereClause.dimensionValue = context.$route?.params?.entityId;
  }
  const apiRequest = {
    cubeName: 'cc_imp_comp_skus_target_recomm',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    limit: 50,
    page: 1,
    measuresList: [
      'competitor_asin_title',
      'count_of_client_comp_occurence_together_past_7_days',
      'competitor_asin_image_url',
      'competitor_asin_product_url',
      'suggested_bid',
      'suggested_bid_start_range',
      'suggested_bid_end_range'
    ],
    groupByDimensionsList: ['competitor_asin'],
    where: {
      dimensionNameValueList: [whereClause]
    },
    orderByList: [
      {
        dimension: 'count_of_client_comp_occurence_together_past_7_days',
        direction: 'DESC'
      }
    ]
  };
  return apiRequest;
};

const categoryLeftTable = (vueRef, campaignType) => {
  const categoryTable = [
    {
      // currDefinition
      name: 'category',
      type: 'STRING',
      uiField: {
        uiLabel: 'Category',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'category',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () =>
          campaignType === 'sp' ? addAll(vueRef) : vueRef.addAll(),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all categories on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this category',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: (row) =>
            campaignType === 'sp'
              ? addEntity(vueRef, row)
              : vueRef.addEntity(row),
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    },
    {
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    }
  ];

  return categoryTable;
};

const productTargetsLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'Products',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'Products',
          columnTitleKey: 'competitor_asin_title',
          columnAsinKey: 'competitor_asin',
          columnImageUrlKey: 'competitor_asin_image_url',
          coloumnProductUrlKey: 'competitor_asin_product_url',
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return (
              params?.data?.competitor_product_title +
              '_' +
              params?.data?.competitor_asin
            );
          }
        }
      }
    },
    {
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: 'Suggested bid',
          tableColumnName: 'suggested_bid',
          suppressSizeToFit: true,
          showOnUi: true,
          isFixed: false,
          width: 110
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: () => {
          addAll(vueRef);
        },
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Products on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this Product',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: (row) => {
            addEntity(vueRef, row);
          },
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

const allSkusForCampaignIdAndAdGroupId = (context) => {
  const dimensionNameValueList = [
    {
      dimensionName: 'campaign_id',
      dimensionValue: context?.$route?.params?.entityId,
      operator: 'ILIKE'
    }
  ];
  if (context?.$route?.params?.subEntityId) {
    dimensionNameValueList.push({
      dimensionName: 'adgroup_id',
      dimensionValue: context?.$route?.params?.subEntityId,
      operator: 'ILIKE'
    });
  }
  return {
    cubeName: 'ams_cm_asin_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    timeseriesDimension: 'report_date',
    pvpenabled: false,
    yoyenabled: false,
    measuresList: ['ams_cm_asin_asin'],
    groupByDimensionsList: ['ad_id', 'campaign_id', 'adgroup_id'],
    dimensionList: [],
    orderByList: [],
    where: {
      dimensionNameValueList
    }
  };
};

const addAllCategories = (vueRef, isAudience, useDefaultSuggestedBid) => {
  if (
    !vueRef.suggestedCustomBidInput ||
    vueRef.suggestedCustomBidInput === '0' ||
    vueRef.suggestedCustomBidInput > 1000 ||
    (vueRef.suggestedCustomBidInput <= 0 && !useDefaultSuggestedBid)
  ) {
    vueRef.$snackbar.open({
      message: 'Please add a valid custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  let rowsToAddToRightTable = [];
  let leftTable = vueRef.leftTableRows;
  if (vueRef?.expandLeftTableConfig?.hasExpand) {
    leftTable = vueRef.leftTableInstance.localTableRow;
  }

  for (const row of leftTable) {
    let isRowPresent = false;
    row.isRowSelected = true;
    for (const rightTableRow of vueRef.rightTableRows) {
      if (
        row[vueRef.config.primaryKey] ===
        rightTableRow[vueRef.config.primaryKey]
      ) {
        isRowPresent = true;
      }
    }
    if (!isRowPresent) {
      row.suggested_bid = row.suggested_bid || '-';
      row.new_bid = row.suggested_bid || vueRef.suggestedCustomBidInput;
      rowsToAddToRightTable.push(row);
    }
  }
  if (isAudience) {
    rowsToAddToRightTable = rowsToAddToRightTable.filter(
      (item) => item?.isAudience
    );
  }
  vueRef.rightTableRows = [
    ...vueRef.computedRightTableRows,
    ...rowsToAddToRightTable
  ];
  if (vueRef?.expandLeftTableConfig?.hasExpand) {
    vueRef.leftTableInstance.redrawTableRows();
  } else {
    vueRef.leftTableRows = [...vueRef.leftTableRows];
  }

  if (vueRef.fetchedRightTableRows.rows.length) {
    const primaryKey = vueRef.config.primaryKey;
    vueRef.rightTableRows = vueRef.rightTableRows.filter(
      (item) =>
        vueRef.fetchedRightTableRows?.rows?.findIndex(
          (apiData) => apiData[primaryKey] === item[primaryKey]
        ) === -1
    );
  }
};

const addEntityCategory = (row, vueRef, useDefaultSuggestedBid) => {
  row.params.data.suggested_bid = useDefaultSuggestedBid
    ? row.params.data.suggested_bid
    : vueRef?.suggestedCustomBidInput || '-';
  row.params.data.new_bid = useDefaultSuggestedBid
    ? row.params.data.suggested_bid
    : vueRef.suggestedCustomBidInput;
  if (
    !vueRef.suggestedCustomBidInput ||
    vueRef.suggestedCustomBidInput === '0' ||
    vueRef.suggestedCustomBidInput > 1000 ||
    (vueRef.suggestedCustomBidInput <= 0 && !useDefaultSuggestedBid)
  ) {
    vueRef.$snackbar.open({
      message: 'Please add a valid custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  if (!row.params.data?.isRowSelected) {
    row.params.data.isRowSelected = true;
    vueRef.leftTableInstance.redrawTableRows([row.params.node]);
  }
  let rowExistsInRightTable = false;
  const tempRightTableRows = [...vueRef.computedRightTableRows];
  const that = vueRef;
  vueRef.computedRightTableRows.forEach((rightTableRow) => {
    if (
      rightTableRow[that.config.primaryKey] ===
      row.params.data[that.config.primaryKey]
    ) {
      rowExistsInRightTable = true;
    }
  });
  if (!rowExistsInRightTable) {
    tempRightTableRows.push(row.params.data);
    vueRef.rightTableRows = tempRightTableRows;
    vueRef.rightTableInstance.redrawTableRows();
  }
};

const saveAddedProductAndCategoryState = (rows) => {
  productAndCategoryRightTableRows = cloneDeep(rows);
};

const categoryColumnDefWithoutAutoConfigure = (
  vueRef,
  useDefaultSuggestedBid,
  hideSuggestedColumn
) => {
  const columnDefs = [
    {
      showOnUi: true,
      cellClass: null,
      isDownloadable: null,
      headerComponentFramework: 'genericTableHeader',
      title: 'Category',
      headerName: 'Category',
      checkboxSelection: null,
      field: 'categoryUI',
      cellRendererFramework: 'genericTableCell',
      cellRendererParams: { keyType: 'string' },
      minWidth: 170,
      width: 170,
      keyOrder: '1',
      pinned: false,
      suppressSizeToFit: false,
      getQuickFilterText: null,
      headerComponentParams: {
        enableHeaderIconInterAction: false,
        alignHeader: null,
        enableSorting: false,
        headerIcon: null,
        keyType: 'string',
        toolTipText: null,
        clickHeader: null,
        customStyles: null
      }
    },
    {
      showOnUi: true,
      cellClass: null,
      isDownloadable: null,
      headerComponentFramework: 'genericTableHeader',
      title: 'Add all',
      headerName: 'Add all',
      checkboxSelection: null,
      field: 'na',
      cellRendererFramework: 'iconTableCell',
      cellRendererParams: {
        iconSize: 'medium',
        iconClickEvent: (row) =>
          addEntityCategory(row, vueRef, useDefaultSuggestedBid),
        alternateIcon: 'check-fill-circle',
        alternateIconClass: 'u-color-green-base',
        displayIcon: 'plus',
        toolTipText: 'Add this category',
        toolTipPosition: 'left',
        contextReturnEvent: undefined,
        type: 'icon'
      },
      minWidth: 110,
      width: 110,
      keyOrder: '2',
      pinned: false,
      suppressSizeToFit: true,
      getQuickFilterText: null,
      headerComponentParams: {
        enableHeaderIconInterAction: false,
        alignHeader: null,
        enableSorting: false,
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all categories on this page'
        },
        keyType: null,
        toolTipText: null,
        clickHeader: () =>
          addAllCategories(vueRef, null, useDefaultSuggestedBid),
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px'
      }
    }
  ];

  if (!hideSuggestedColumn) {
    columnDefs.push({
      showOnUi: true,
      cellClass: null,
      isDownloadable: null,
      headerComponentFramework: 'genericTableHeader',
      title: 'Suggested Bid',
      headerName: 'Suggested Bid',
      checkboxSelection: null,
      field: 'suggested_bid',
      cellRendererFramework: 'genericTableCell',
      cellRendererParams: {
        keyType: 'currency',
        formatterFn: formatter
      },
      minWidth: 170,
      width: 170,
      keyOrder: '1',
      pinned: false,
      suppressSizeToFit: false,
      getQuickFilterText: null,
      headerComponentParams: {
        enableHeaderIconInterAction: false,
        alignHeader: null,
        enableSorting: false,
        headerIcon: null,
        keyType: 'currency',
        toolTipText: null,
        clickHeader: null,
        customStyles: null
      }
    });
  }
  return columnDefs;
};

const audienceColumnDefWithoutAutoConfigure = (vueRef) => [
  {
    showOnUi: true,
    cellClass: null,
    isDownloadable: null,
    headerComponentFramework: 'genericTableHeader',
    title: 'Audience',
    headerName: 'Audience',
    checkboxSelection: null,
    field: 'categoryUI',
    cellRendererFramework: 'genericTableCell',
    cellRendererParams: { keyType: 'string' },
    minWidth: 170,
    width: 170,
    keyOrder: '1',
    pinned: false,
    suppressSizeToFit: false,
    getQuickFilterText: null,
    headerComponentParams: {
      enableHeaderIconInterAction: false,
      alignHeader: null,
      enableSorting: false,
      headerIcon: null,
      keyType: 'string',
      toolTipText: null,
      clickHeader: null,
      customStyles: null
    }
  },
  {
    showOnUi: true,
    cellClass: null,
    isDownloadable: null,
    headerComponentFramework: 'genericTableHeader',
    title: 'Add all',
    headerName: 'Add all',
    checkboxSelection: null,
    field: 'na',
    cellRendererFramework: 'iconTableCell',
    cellRendererParams: {
      iconSize: 'medium',
      iconClickEvent: (row) => addEntityCategory(row, vueRef),
      alternateIcon: 'check-fill-circle',
      alternateIconClass: 'u-color-green-base',
      displayIcon: 'plus',
      toolTipText: 'Add this audience',
      toolTipPosition: 'left',
      contextReturnEvent: undefined,
      type: 'icon'
    },
    minWidth: 110,
    width: 110,
    keyOrder: '2',
    pinned: false,
    suppressSizeToFit: true,
    getQuickFilterText: null,
    headerComponentParams: {
      enableHeaderIconInterAction: false,
      alignHeader: null,
      enableSorting: false,
      headerIcon: {
        icon: 'info-circle-fill',
        toolTip: 'Add all audiences loaded on this page'
      },
      keyType: null,
      toolTipText: null,
      clickHeader: () => addAllCategories(vueRef, true),
      customStyles:
        'cursor:pointer; color:#037ef6 !important; padding-left:15px'
    }
  }
];

export function addCategoriesToCampaignActionPanel(
  context,
  campaignType = 'sp'
) {
  return {
    component: 'customActionPanel',
    saveAddedProductAndCategoryState: saveAddedProductAndCategoryState,
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Categories to this list',
              selections: '',
              noRowsLeftTable: 'No Categories sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Categories are not allowed'
            },
            left: {
              vueRef: vueRef,
              actionAndGetterForEachTab: true,
              hidePagination: true,
              altStore: {
                Suggested:
                  campaignType === 'sd'
                    ? 'campaignCreation/getSdSuggestedTargets'
                    : 'campaignCreation/getCategories',
                Search: 'campaignCreation/getAllCategories'
              },
              tabs: {
                Suggested: {
                  leftCustomBidValues:
                    campaignType === 'sp'
                      ? leftCustomBidValues
                      : [
                          {
                            title: 'Custom Bid',
                            value: 'custom_bid',
                            input: 'currency'
                          }
                        ],
                  showLeftCustomBid: campaignType === 'sp',
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: categoryLeftTable(vueRef, campaignType),
                  search: {
                    enableSearch: false,
                    searchKey: 'keyword'
                  },
                  showFilterCheckBox: false
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                },
                Search: {
                  hasDefaultRowExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  expandColumnWidth: 140,
                  hasExpand: true,
                  expandLevels: categoryHierarchyExpandLevels,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: campaignType !== 'sd',
                  columnDefs: categoryColumnDefWithoutAutoConfigure(
                    vueRef,
                    campaignType === 'sd'
                  ),
                  search: {
                    enableSearch: true,
                    searchKey: 'name'
                  },
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                action: {
                  Suggested:
                    campaignType === 'sd'
                      ? 'campaignCreation/fetchSdSuggestedTargets'
                      : 'campaignCreation/fetchSuggestedCategoriesForCampaignId',
                  Search: 'campaignCreation/fetchAllCategoriesLevel'
                },
                campaignType: campaignType,
                type: 'table',
                allSkus: null,
                getAdGroupId: () => {
                  return context?.$route?.params?.subEntityId;
                },
                allSkusCubeAPIRequest: () =>
                  allSkusForCampaignIdAndAdGroupId(context),
                skuPromise: true,
                updateRows: async (vueRef) => {
                  if (!vueRef.leftTableData?.rows?.length) return;
                  let categoryBidsResponseValues = [];
                  let leftTableRows =
                    vueRef?.leftTableInstance?.localTableRow?.length >
                    vueRef?.leftTableRows?.length
                      ? vueRef?.leftTableInstance?.localTableRow
                      : vueRef?.leftTableRows;
                  if (campaignType === 'sd') {
                    vueRef.leftTableLoad = true;
                    try {
                      let allAsins = await getAllAsinsData(context, vueRef);
                      const requestPayload = {
                        products: allAsins,
                        bidOptimization: 'reach',
                        costType: 'vcpm',
                        targetingClauses: null
                      };
                      const targetingCaluseReqFormation = {
                        targetingClause: {
                          expressionType: 'manual',
                          expression: [
                            {
                              type: 'asinCategorySameAs',
                              value: ':categoryId'
                            }
                          ]
                        }
                      };
                      const targetingClauses = [];
                      for (let row of leftTableRows) {
                        const requestObject = cloneDeep(
                          targetingCaluseReqFormation
                        );
                        deepReplaceObjectValues(
                          requestObject,
                          ':categoryId',
                          row.categoryId
                        );
                        targetingClauses.push(requestObject);
                      }
                      requestPayload.targetingClauses = targetingClauses;
                      const TargetBidsResponse = await HttpService.post(
                        'GET_SD_CONTEXTUAL_TARGET_BIDS_RECOMMANDATIONS',
                        requestPayload
                      );
                      categoryBidsResponseValues =
                        TargetBidsResponse?.data?.bidRecommendations
                          ?.bidRecommendations;
                    } catch {
                      vueRef.leftTableLoad = false;
                    }
                  }

                  for (let i = 0; i < leftTableRows.length; i++) {
                    leftTableRows[
                      i
                    ].entity_ui_label = `CATEGORY : ${leftTableRows[i].category}`;
                    if (categoryBidsResponseValues?.length) {
                      leftTableRows[i].suggested_bid =
                        categoryBidsResponseValues[i]?.recommended;
                      leftTableRows[i].new_bid =
                        categoryBidsResponseValues[i]?.recommended;
                    }
                  }

                  if (vueRef?.expandLeftTableConfig?.hasExpand) {
                    vueRef.leftTableInstance.redrawTableRows();
                  } else {
                    vueRef.leftTableRows = [...vueRef.leftTableRows];
                  }
                  vueRef.leftTableLoad = false;

                  if (productAndCategoryRightTableRows?.length) {
                    vueRef.rightTableRows = productAndCategoryRightTableRows;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                allCatergoriesLevelCube: allCatergoriesLevelCube,
                sdFilter: {
                  tactic: 'T00020',
                  url: 'GET_SD_TARGET_RECOMMENDATIONS',
                  typeFilter: ['CATEGORY']
                },
                getAllAsinsData: async () =>
                  await getAllAsinsData(context, vueRef),
                useExistingWhereClause: false,
                // APIConfig: getDataPayload(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: categoryLeftTable(vueRef, campaignType)
              },
              headerText: 'Add Categories'
            },
            right: {
              table: 'right',
              primaryKey: 'entity_ui_label',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_targets_to_campaign_sp_and_sb.right(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            }
          };
        }
      },
      staticHeaderText: 'ADD PRODUCTS'
    },
    events: {
      rightTableRows(rows) {
        saveAddedProductAndCategoryState(rows);
      },
      afterAPIActionToCall(payload) {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
        context.reloadComponent(true);
      },
      closePanelMethod() {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
      }
    }
  };
}

export function addTargetsToCampaignActionPanel(context) {
  return {
    component: 'customActionPanel',
    saveAddedProductAndCategoryState: saveAddedProductAndCategoryState,
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              rowHeight: 68,
              tabs: {
                Suggested: {
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: productTargetsLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'competitor_asin'
                  },
                  showFilterCheckBox: false,
                  leftCustomBidValues,
                  showLeftCustomBid: true
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'product',
                      buttonLabel: 'Add Product Targets',
                      emitTextInput: true,
                      isTarget: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN : ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              new_bid: customBid,
                              suggested_bid: '-',
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                        data.enterListRef.keywordTextInput = '';
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  vueRef.leftTableData.rows.forEach((item) => {
                    item.new_bid = item?.suggested_bid;
                    if (item.competitor_asin) {
                      item.entity_ui_label = `ASIN : ${item.competitor_asin}`;
                    }
                  });
                  if (productAndCategoryRightTableRows?.length) {
                    vueRef.rightTableRows = productAndCategoryRightTableRows;
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: getDataPayload(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: productTargetsLeftTable(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_targets_to_campaign_sp_and_sb.right(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const new_bid = arrayToTraverse[i]?.new_bid;
                if (!new_bid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
        }
      },
      staticHeaderText: 'ADD PRODUCTS'
    },
    events: {
      rightTableRows(rows) {
        saveAddedProductAndCategoryState(rows);
      },
      afterAPIActionToCall() {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
      },
      closePanelMethod() {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
      }
    }
  };
}

// -- Beging -- Add searchterms targets to campaign
export function addSearchTermTargetsToCampaignActionPanel(context) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'asin_title',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'Select the targets from below table',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              rowHeight: 68,
              boolUpdateLeftBid: true,
              columnDefs: getColumnDefinition(
                vueRef,
                dictionary.add_search_term_targets_to_campaign_sp_and_sb.left(
                  vueRef
                )
              ),
              showLeftCustomBid: true,
              leftCustomBidValues: [
                {
                  title: 'Custom Bid',
                  value: 'custom_bid',
                  input: 'currency'
                }
              ],
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                updateRows: (vueRef) => {
                  const newRows = [];
                  context.selections.forEach((row) => {
                    row.data.suggested_bid = '-';
                    row.data.new_bid = vueRef.suggestedCustomBidInput;
                    row.data.isRowSelected = false;
                    if (row?.data?.ams_cm_searchterm_search_term) {
                      row.data.asin_title = `<b>ASIN :</b> ${row?.data?.ams_cm_searchterm_search_term.toUpperCase()}`;
                    } else {
                      return;
                    }
                    newRows.push(row.data);
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: []
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: getDataPayload(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_search_term_targets_to_campaign_sp_and_sb.left(
                    vueRef
                  )
              },
              headerText: 'Skus'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              boolUpdateRightTableBid: false,
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_search_term_targets_to_campaign_sp_and_sb.right(
                    vueRef
                  )
                )
              },
              headerText: () => `Added Skus(${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddSearchTermTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const new_bid = arrayToTraverse[i]?.new_bid;
                if (!new_bid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
        }
      },
      staticHeaderText: `ADD AS PRODUCT TARGET (${context.selections.length} SELECTED)`
    },
    events: {
      afterAPIActionToCall() {
        context.closeActionBar();
      },
      closePanelMethod() {
        context.closeActionBar();
      }
    }
  };
}
// -- End -- Add searchterms targets to campaign

// -- Begin -- Add searchterms negative targets to campaign
export function addSearchTermNegativeTargetsToCampaignActionPanel(context) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'asin_title',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Negative Targets to this list',
              selections: '',
              noRowsLeftTable: 'Select the Negative targets from below table',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Negative Target are not allowed'
            },
            left: {
              rowHeight: 68,
              columnDefs: getColumnDefinition(
                vueRef,
                dictionary.add_search_term_negative_targets_to_campaign_sp_and_sb.left(
                  vueRef
                )
              ),
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                updateRows: (vueRef) => {
                  const newRows = [];
                  context.selections.forEach((row) => {
                    row.data.suggested_bid = '-';
                    row.data.new_bid = vueRef.suggestedCustomBidInput;
                    row.data.isRowSelected = false;
                    if (row?.data?.ams_cm_searchterm_search_term) {
                      row.data.asin_title = `<b>ASIN :</b> ${row?.data?.ams_cm_searchterm_search_term.toUpperCase()}`;
                    } else {
                      return;
                    }
                    newRows.push(row.data);
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: []
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: getDataPayload(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_search_term_negative_targets_to_campaign_sp_and_sb.left(
                    vueRef
                  )
              },
              headerText: 'Skus'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              boolUpdateRightTableBid: false,
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_search_term_negative_targets_to_campaign_sp_and_sb.right(
                    vueRef
                  )
                )
              },
              headerText: () => `Added Skus (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddNegativeTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const new_bid = arrayToTraverse[i]?.new_bid;
                if (!new_bid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
        }
      },
      staticHeaderText: `ADD AS NEGATIVE PRODUCT TARGET (${context.selections.length} SELECTED)`
    },
    events: {
      afterAPIActionToCall() {
        context.closeActionBar();
      },
      closePanelMethod() {
        context.closeActionBar();
      }
    }
  };
}
// -- End -- Add searchterms negative targets to campaign

// -- Beging -- SB Add Custom negative keywords
export const sbAddNegativeKeywordsEnterListComponentConfig = (context) => {
  return {
    component: 'customActionPanel',
    props: {
      staticHeaderText: 'Add Negative Keywords',
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}`,
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              hideHeader: false,
              noRowsRightTable: 'Add negative keywords to this list',
              selections: '',
              noRowsLeftTable: 'No negative keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: 'campaign_id',
                      dimensionValue: context.parentEntityId
                    }
                  ],
                  columnDefs:
                    dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  search: {
                    enableSearch: false
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    defaultSelected: ['Negative phrase', 'Negative exact'],
                    items: ['Negative Phrase', 'Negative Exact']
                  }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'negative keyword',
                      buttonLabel: 'Add negative keywords'
                    }
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Filter by:',
                    items: ['Negative Phrase', 'Negative Exact']
                  }
                }
              },
              defaultTab: 'Enter List',
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                // action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  const newRows = [];
                  let filterPhrase = false;
                  let filterExact = false;
                  const checkBoxValues = vueRef.leftFilterCheckBoxArray
                    .toString()
                    .toLowerCase();
                  filterPhrase = checkBoxValues.includes('phrase');
                  filterExact = checkBoxValues.includes('exact');
                  if (
                    (!filterPhrase && !filterExact) ||
                    (filterPhrase && filterExact)
                  ) {
                    filterPhrase = true;
                    filterExact = true;
                  }
                  vueRef.leftTableData.rows.forEach((row) => {
                    if (filterPhrase) {
                      const phrase = generateMatchTypeRows(
                        row,
                        'negativePhrase',
                        true
                      );
                      newRows.push(phrase);
                    }
                    if (filterExact) {
                      const exact = generateMatchTypeRows(
                        row,
                        'negativeExact',
                        !filterPhrase && filterExact
                      );
                      newRows.push(exact);
                    }
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: [
                  {
                    dimensionName: 'campaign_id',
                    dimensionValue: context.parentEntityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName:
                    'ams_cm_negativekeyword_recommendations_inefficient_searchterms',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: ['recommended_keyword', 'acos14d'],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'acos14d',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                    vueRef
                  ),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keyword === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Negative Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs:
                  dictionary.add_negative_keywords_sp_and_sb.right(vueRef)
              },
              headerText: () =>
                `Added Negative Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddNegativeKeyword(rows);
              vueRef.$emit('afterAPIActionToCall');
            }
          };
        }
      }
    },
    events: {
      afterAPIActionToCall() {
        context.showAddNegativeKeywordsPanel = false;
      },
      closePanelMethod() {
        context.showAddNegativeKeywordsPanel = false;
        context.closeActionBar();
      }
    }
  };
};
// -- End -- SB Add Custom negative keywords

// -- Begin -- SB,SP Add Custom negative Targets
export const addNegativeTargetsEnterListComponentConfig = (context) => {
  return {
    component: 'customActionPanel', // usage in multiple places
    ui_component: 'customActionPanel', // usage in multiple places
    props: {
      staticHeaderText: 'Add Negative Target',
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: false,
            default: {
              hideHeader: false,
              noRowsRightTable: 'Add negative targets to this list',
              selections: '',
              noRowsLeftTable: 'No negative targets sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              altStore: 'campaignCreation/getAllCategories',
              hidePagination: true,
              tabs: {
                // 'Category Search': {
                //   search: {
                //     enableSearch: true,
                //     searchKey: 'name'
                //   },
                //   hasDefaultRowExpand: false,
                //   overrideColumnDefAutoConfigure: false,
                //   expandColumnWidth: 140,
                //   hasExpand: true,
                //   expandLevels: categoryHierarchyExpandLevels,
                //   showLeftCustomBid: false,
                //   columnDefs: categoryColumnDefWithoutAutoConfigure(vueRef),
                //   showFilterCheckBox: false
                // },
                'Enter Products': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'negative target',
                      buttonLabel: 'Add negative target',
                      emitTextInput: true
                    },
                    events: {
                      updateTextInput(data) {
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN: ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                      }
                    }
                  }
                  // showFilterCheckBox: true,
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Targeting Expression']
                  // }
                }
              },
              defaultTab: 'Enter Products',
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'campaignCreation/fetchAllCategoriesLevel',
                updateRows: (vueRef) => {},
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                // allCatergoriesLevelCube: allCatergoriesLevelCube,
                APIConfig: {
                  cubeName:
                    'ams_cm_negativekeyword_recommendations_inefficient_searchterms',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: ['recommended_keyword', 'acos14d'],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'acos14d',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_negative_targets_sp_and_sb.left.SUGGESTED(
                    vueRef
                  ),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keyword === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Negative Targets'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_negative_targets_sp_and_sb.right(vueRef)
                )
              },
              headerText: () =>
                `Added Negative Targets (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddNegativeTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            }
          };
        }
      }
    },
    events: {
      afterAPIActionToCall() {
        context.showAddNegativeKeywordsPanel = false;
      },
      closePanelMethod() {
        context.showAddNegativeKeywordsPanel = false;
        context.closeActionBar();
      }
    }
  };
};

export function addAudiencesToCampaignActionPanel(
  context,
  campaignType = 'sp'
) {
  return {
    component: 'customActionPanel',
    saveAddedProductAndCategoryState: saveAddedProductAndCategoryState,
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Categories to this list',
              selections: '',
              noRowsLeftTable: 'No Categories sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Categories are not allowed'
            },
            left: {
              vueRef: vueRef,
              actionAndGetterForEachTab: true,
              hidePagination: true,
              altStore: {
                'Amazon Audience': 'campaignCreation/getAllAudiences'
              },
              tabs: {
                'Amazon Audience': {
                  hasDefaultRowExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  expandColumnWidth: 140,
                  hasExpand: true,
                  expandLevels: audienceHierarchyExpandLevels,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  columnDefs: audienceColumnDefWithoutAutoConfigure(vueRef),
                  search: {
                    enableSearch: true,
                    customAPISearch: true,
                    searchKey: 'category'
                  },
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                action: {
                  Suggested:
                    'campaignCreation/fetchSuggestedCategoriesForCampaignId',
                  'Amazon Audience': 'campaignCreation/fetchL0AudienceTaxonomy'
                },
                campaignType: campaignType,
                type: 'table',
                allSkus: null,
                getAdGroupId: () => {
                  return context?.$route?.params?.subEntityId;
                },
                allSkusCubeAPIRequest: () =>
                  allSkusForCampaignIdAndAdGroupId(context),
                skuPromise: true,
                updateRows: async (vueRef) => {
                  if (productAndCategoryRightTableRows?.length) {
                    vueRef.rightTableRows = productAndCategoryRightTableRows;
                  }
                  // if (!vueRef.leftTableData?.rows?.length) return;
                  // const leftTableRows = vueRef?.leftTableInstance?.localTableRow?.length > vueRef?.leftTableRows?.length ? vueRef?.leftTableInstance?.localTableRow : vueRef?.leftTableRows;
                  // const requestRows = leftTableRows?.filter(item => item?.isAudience);
                  // if (!requestRows?.length) return;
                  // vueRef.leftTableLoad = true;
                  // let allAsins = await getAllAsinsData(context, vueRef);

                  // const requestPayload = {
                  //   'products': allAsins,
                  //   'bidOptimization': 'reach',
                  //   'costType': 'vcpm',
                  //   'targetingClauses': null
                  // };

                  // const targetingCaluseReqFormation = {
                  //   'targetingClause': {
                  //     'expressionType': 'manual',
                  //     'expression': [
                  //       {
                  //         'type': 'audience',
                  //         'value': [
                  //           {
                  //             'type': 'audienceSameAs',
                  //             'value': ':audienceId'
                  //           }
                  //         ]
                  //       }
                  //     ]
                  //   }
                  // };

                  // const targetingReq = [];
                  // requestRows.forEach(item => {
                  //   const requestObject = cloneDeep(targetingCaluseReqFormation);
                  //   deepReplaceObjectValues(requestObject, ':audienceId', item.audienceId);
                  //   targetingReq.push(requestObject);
                  // });
                  // requestPayload.targetingClauses = targetingReq;
                  // let audienceBidsResponseValues = [];
                  // try {
                  //   const audienceBidResponses = await HttpService.post('GET_SD_BID_RECOMMENDATIONS', requestPayload);
                  //   audienceBidsResponseValues = audienceBidResponses?.data?.bidRecommendations?.bidRecommendations;
                  //   // recommended
                  // } catch {
                  //   vueRef.leftTableLoad = false;
                  // }
                  // if (productAndCategoryRightTableRows?.length) {
                  //   vueRef.rightTableRows = productAndCategoryRightTableRows;
                  // }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                allCatergoriesLevelCube: allCatergoriesLevelCube,
                useExistingWhereClause: false,
                // APIConfig: getDataPayload(context),
                rowClassRules: {
                  ' hide-custom-action-panel-table-icon': function (params) {
                    return !(params?.data?.isAudience || false);
                  }
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: audienceColumnDefWithoutAutoConfigure(vueRef)
              },
              headerText: 'Add Audiences'
            },
            right: {
              table: 'right',
              primaryKey: 'entity_ui_label',
              searchKey: 'search',
              footer: {
                show: false
              },
              rowHeight: 80,
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_sd_audience_targets_to_campaign.right(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            }
          };
        }
      },
      staticHeaderText: 'ADD PRODUCTS'
    },
    events: {
      rightTableRows(rows) {
        saveAddedProductAndCategoryState(rows);
      },
      afterAPIActionToCall(payload) {
        saveAddedProductAndCategoryState([]);
        context.showAddAudiences = false;
        context.reloadComponent(true);
      },
      closePanelMethod() {
        saveAddedProductAndCategoryState([]);
        context.showAddAudiences = false;
      }
    }
  };
}

const getAllAsinsData = async (context, vueRef, boolSearchTab = false) => {
  let getAllSKUsRequest = allSkusForCampaignIdAndAdGroupId(context);

  if (vueRef?.config?.left?.callGetDataPayload) {
    getAllSKUsRequest = getDataPayload(context);
  }
  // Search for all asins
  if (boolSearchTab) {
    getAllSKUsRequest.where.dimensionNameValueList = [];
    getAllSKUsRequest.limit = 100;
  }
  let boolSDTargetSearch =
    vueRef?.config?.left?.body?.sdFilter?.fromSDProductTargeting &&
    vueRef?.activeTab === 'Search';
  if (
    vueRef?.config?.left?.tabs?.['Search']?.search?.value &&
    boolSDTargetSearch
  ) {
    getAllSKUsRequest.where.dimensionNameValueList = [
      {
        dimensionName: 'search',
        dimensionValue: vueRef?.config.left?.tabs?.['Search']?.search?.value,
        operator: 'ILIKE'
      }
    ];
  }
  let allAsins = [];
  try {
    const response = await HttpLayer.post({
      APIData: getAllSKUsRequest
    });
    if (vueRef?.config?.left?.callGetDataPayload) {
      allAsins = response?.data?.map((item) => {
        return {
          asin: item?.DIMENSION?.competitor_asin
        };
      });
    } else {
      allAsins = response?.data?.map((item) => {
        return {
          asin: item?.RESULT?.ams_cm_asin_asin
        };
      });
    }
  } catch {
    vueRef.leftTableLoad = false;
    context.$snackbar.open({
      message: 'Could not fetch suggessted bids for categories',
      duration: 6000,
      actionText: ''
    });
  }
  return allAsins;
};

export function addCategoriesToAudienceCampaigns(
  context,
  campaignType = 'sp',
  marketing,
  lookback
) {
  const isPurchaseRemarketing = marketing === 'Purchase Remarketing';
  return {
    component: 'customActionPanel',
    saveAddedProductAndCategoryState: saveAddedProductAndCategoryState,
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Categories to this list',
              selections: '',
              noRowsLeftTable: 'No Categories sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Categories are not allowed'
            },
            left: {
              vueRef: vueRef,
              actionAndGetterForEachTab: true,
              hidePagination: true,
              altStore: {
                Suggested: 'campaignCreation/getSdSuggestedTargets',
                Search: 'campaignCreation/getAllCategories'
              },
              tabs: {
                Suggested: {
                  hasExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: isPurchaseRemarketing,
                  localFilter: {
                    // dimensionName: 'campaign_id',
                    // dimensionValue: context.entityId
                  },
                  columnDefs: categoryColumnDefWithoutAutoConfigure(
                    vueRef,
                    !isPurchaseRemarketing,
                    isPurchaseRemarketing
                  ),
                  search: {
                    enableSearch: false,
                    searchKey: 'keyword'
                  },
                  showFilterCheckBox: false
                  // filterCheckBoxSource: {
                  //   header: 'Filter by:',
                  //   items: ['Broad', 'Phrase', 'Exact']
                  // }
                },
                Search: {
                  hasDefaultRowExpand: false,
                  overrideColumnDefAutoConfigure: false,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: isPurchaseRemarketing,
                  expandColumnWidth: 140,
                  hasExpand: true,
                  expandLevels: categoryHierarchyExpandLevels,
                  columnDefs: categoryColumnDefWithoutAutoConfigure(
                    vueRef,
                    !isPurchaseRemarketing,
                    isPurchaseRemarketing
                  ),
                  search: {
                    enableSearch: true,
                    searchKey: 'name'
                  },
                  showFilterCheckBox: false
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                action: {
                  Suggested: 'campaignCreation/fetchSdSuggestedTargets',
                  Search: 'campaignCreation/fetchAllCategoriesLevel'
                },
                campaignType: campaignType,
                type: 'table',
                allSkus: null,
                getAdGroupId: () => {
                  return context?.$route?.params?.subEntityId;
                },
                allSkusCubeAPIRequest: () =>
                  allSkusForCampaignIdAndAdGroupId(context),
                skuPromise: true,
                updateRows: async (vueRef) => {
                  if (productAndCategoryRightTableRows?.length) {
                    vueRef.rightTableRows = productAndCategoryRightTableRows;
                  }
                  if (!vueRef.leftTableData?.rows?.length) return;
                  let leftTableRows =
                    vueRef?.leftTableInstance?.localTableRow?.length >
                    vueRef?.leftTableRows?.length
                      ? vueRef?.leftTableInstance?.localTableRow
                      : vueRef?.leftTableRows;
                  vueRef.leftTableLoad = true;
                  let allAsins = await getAllAsinsData(context, vueRef);

                  const requestPayload = {
                    products: allAsins,
                    bidOptimization: 'reach',
                    costType: 'vcpm',
                    targetingClauses: null
                  };

                  const targetingCaluseReqFormation = {
                    targetingClause: {
                      expressionType: 'manual',
                      expression: [
                        {
                          type: ':type',
                          value: [
                            {
                              type: 'asinCategorySameAs',
                              value: ':categoryId'
                            },
                            {
                              type: 'lookback',
                              value: ':lookback'
                            }
                          ]
                        }
                      ]
                    }
                  };

                  const targetingReq = [
                    {
                      targetingClause: {
                        expressionType: 'manual',
                        expression: [
                          {
                            type:
                              marketing === 'View Remarketing'
                                ? 'views'
                                : 'purchases',
                            value: [
                              {
                                type: 'exactProduct'
                              },
                              {
                                type: 'lookback',
                                value: lookback
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      targetingClause: {
                        expressionType: 'manual',
                        expression: [
                          {
                            type: !isPurchaseRemarketing
                              ? 'views'
                              : 'purchases',
                            value: [
                              {
                                type: !isPurchaseRemarketing
                                  ? 'similarProduct'
                                  : 'relatedProduct'
                              },
                              {
                                type: 'lookback',
                                value: lookback
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ];

                  leftTableRows.forEach((item) => {
                    item.isRowSelected = false;
                    const requestObject = cloneDeep(
                      targetingCaluseReqFormation
                    );
                    const keyValuePairs = {
                      ':type': !isPurchaseRemarketing ? 'views' : 'purchases',
                      ':categoryId': item.categoryId,
                      ':lookback': lookback
                    };
                    for (const key in keyValuePairs) {
                      deepReplaceObjectValues(
                        requestObject,
                        key,
                        keyValuePairs[key]
                      );
                    }
                    targetingReq.push(requestObject);
                    if (!isPurchaseRemarketing && item.category) {
                      item.marketingType = 'views';
                    } else if (isPurchaseRemarketing && item.category) {
                      item.marketingType = 'purchase';
                    }
                    if (lookback) {
                      item.lookback = lookback;
                    }
                    if (item?.category) {
                      item.entity_ui_label = `CATEGORY : ${item.category} ${item.marketingType} ${lookback}`;
                    }
                  });

                  requestPayload.targetingClauses = targetingReq;
                  let categoryBidsResponseValues = [];
                  if (!isPurchaseRemarketing) {
                    try {
                      const categoryBidsResponse = await HttpService.post(
                        'GET_SD_BID_RECOMMENDATIONS',
                        requestPayload
                      );
                      categoryBidsResponseValues =
                        categoryBidsResponse?.data?.bidRecommendations
                          ?.bidRecommendations;
                      // recommended
                    } catch {
                      vueRef.leftTableLoad = false;
                    }
                  }

                  if (
                    vueRef.activeTab === 'Suggested' &&
                    !leftTableRows?.[0]?.isDynamicSegment
                  ) {
                    const dynamicSegment = !isPurchaseRemarketing
                      ? 'Dynamic Segment Similar to advertised products'
                      : 'Dynamic Segment Related to advertised products';
                    const newRows = [
                      {
                        category: 'Dynamic Segment advertised products',
                        entity_ui_label: `CATEGORY : Dynamic Segment advertised products ${
                          !isPurchaseRemarketing ? 'views' : 'purchase'
                        } ${lookback}`,
                        categoryId: '00',
                        dynamicSegment: 'exactProduct',
                        isDynamicSegment: true,
                        categoryUI: 'Dynamic Segment advertised products',
                        lookback: lookback,
                        marketingType: !isPurchaseRemarketing
                          ? 'views'
                          : 'purchase',
                        isRowSelected: false
                      },
                      {
                        category: dynamicSegment,
                        entity_ui_label: `CATEGORY : ${dynamicSegment} ${
                          !isPurchaseRemarketing ? 'views' : 'purchase'
                        } ${lookback}`,
                        categoryId: '01',
                        dynamicSegment: !isPurchaseRemarketing
                          ? 'similarProduct'
                          : 'relatedProduct',
                        isDynamicSegment: true,
                        categoryUI: dynamicSegment,
                        lookback: lookback,
                        marketingType: !isPurchaseRemarketing
                          ? 'views'
                          : 'purchase',
                        isRowSelected: false
                      }
                    ];
                    leftTableRows = [...newRows, ...leftTableRows];
                  }
                  for (let i = 0; i < leftTableRows.length; i++) {
                    leftTableRows[i].suggested_bid =
                      categoryBidsResponseValues?.[i]?.recommended;
                  }
                  vueRef.leftTableInstance.redrawTableRows();

                  if (vueRef?.expandLeftTableConfig?.hasExpand) {
                    vueRef.leftTableInstance.redrawTableRows();
                  } else {
                    vueRef.leftTableRows = [...leftTableRows];
                  }

                  vueRef.leftTableLoad = false;
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                allCatergoriesLevelCube: allCatergoriesLevelCube,
                sdFilter: {
                  lookback: lookback,
                  tactic: 'T00030',
                  url: 'GET_SD_TARGET_RECOMMENDATIONS',
                  typeFilter: ['CATEGORY']
                },
                getAllAsinsData: async () =>
                  await getAllAsinsData(context, vueRef),
                useExistingWhereClause: false,
                // APIConfig: getDataPayload(context),
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: categoryColumnDefWithoutAutoConfigure(
                  vueRef,
                  !isPurchaseRemarketing,
                  isPurchaseRemarketing
                )
              },
              headerText: 'Add Categories'
            },
            right: {
              table: 'right',
              primaryKey: 'entity_ui_label',
              rowHeight: 80,
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_sd_audience_targets_to_campaign.right(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            }
          };
        }
      },
      staticHeaderText: 'ADD PRODUCTS'
    },
    events: {
      rightTableRows(rows) {
        saveAddedProductAndCategoryState(rows);
      },
      afterAPIActionToCall(payload) {
        saveAddedProductAndCategoryState([]);
        context.showAddAudiences = false;
        context.reloadComponent(true);
      },
      closePanelMethod() {
        saveAddedProductAndCategoryState([]);
        context.showAddAudiences = false;
      }
    }
  };
}

// -- End -- SB,SP Add custom negative targets
// -- Begin -- Add SD targets to campaign
export function addTargetsToCampaignActionPanelSD(
  context,
  campaignType = 'sd',
  lookback = 30
) {
  return {
    component: 'customActionPanel',
    saveAddedProductAndCategoryState: saveAddedProductAndCategoryState,
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'entity_ui_label',
            isMultipleAllowed: true,
            default: {
              hideHeader: false,
              hideFooter: false,
              noRowsRightTable: 'Add Targets to this list',
              selections: '',
              noRowsLeftTable: 'No Target sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple Target are not allowed'
            },
            left: {
              // rowHeight: 6,
              vueRef: vueRef,
              actionAndGetterForEachTab: true,
              hidePagination: true,
              callGetDataPayload: true,
              altStore: {
                Suggested: null,
                Search: 'campaignCreation/getSdSuggestedTargets'
              },
              tabs: {
                Search: {
                  localFilter: null,
                  columnDefs:
                    dictionary.add_targets_to_campaign_sd.left(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'competitor_asin',
                    customAPISearch: true
                  },
                  showFilterCheckBox: false,
                  leftCustomBidValues,
                  showLeftCustomBid: true
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'product',
                      buttonLabel: 'Add Product Targets',
                      emitTextInput: true,
                      isTarget: true
                    },
                    events: {
                      updateTextInput(data) {
                        const customBid = data?.ref?.suggestedCustomBidInput;
                        if (customBid <= 0 || customBid > 1000) {
                          data?.ref?.$snackbar.open({
                            message: 'Please add a vaild custom bid',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const keywords = data?.keywords;
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          const target = `ASIN : ${item}`;
                          let newKeyword = true;
                          for (const rightTableData of data.ref
                            .rightTableRows) {
                            if (rightTableData?.entity_ui_label === target) {
                              newKeyword = false;
                            }
                          }
                          if (newKeyword) {
                            addToRightKeywordsTable.push({
                              new_bid: customBid,
                              suggested_bid: '-',
                              entity_ui_label: target,
                              competitor_asin: item
                            });
                          } else {
                            data?.ref?.$snackbar.open({
                              message:
                                'One or more Products already exists in Added Targets table',
                              duration: 6000,
                              buttonColor: '#f5d908',
                              actionText: ''
                            });
                          }
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                        data.enterListRef.keywordTextInput = '';
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: false
                },
                Suggested: {
                  localFilter: null,
                  columnDefs:
                    dictionary.add_targets_to_campaign_sd.left(vueRef),
                  showFilterCheckBox: false,
                  leftCustomBidValues,
                  showLeftCustomBid: true
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                campaignType: campaignType,
                allSkus: null,
                skuPromise: true,
                action: {
                  Suggested: null,
                  Search: 'campaignCreation/fetchSdSuggestedTargets'
                },
                getAdGroupId: () => {
                  return context?.$route?.params?.subEntityId;
                },
                allSkusCubeAPIRequest: () =>
                  allSkusForCampaignIdAndAdGroupId(context),
                updateTargetRecommendations: (asinRecommendations) => {
                  vueRef.config.left.meta.updateRows(
                    vueRef,
                    null,
                    null,
                    asinRecommendations
                  );
                },
                updateRows: async (
                  vueRef,
                  searchKey,
                  searchText,
                  asinRecommendations
                ) => {
                  let allAsins = [];
                  if (asinRecommendations) {
                    allAsins = asinRecommendations;
                  } else {
                    allAsins =
                      vueRef.activeTab === 'Search'
                        ? await getAllAsinsData(context, vueRef, true)
                        : await getAllAsinsData(context, vueRef, false);
                  }
                  vueRef.leftTableLoad = true;
                  let formRequestPayload = {
                    tactic: SD_TACTIC_PRODUCT_ADGROUP,
                    products: allAsins,
                    typeFilter: ['PRODUCT', 'CATEGORY']
                  };

                  const requestPayload = {
                    products: allAsins,
                    bidOptimization: 'reach',
                    costType: 'vcpm',
                    targetingClauses: null
                  };

                  const targetingCaluseReqFormation = {
                    targetingClause: {
                      expressionType: 'manual',
                      expression: [
                        {
                          type: 'asinSameAs',
                          value: ':asinId'
                        }
                      ]
                    }
                  };

                  let targetRecommendations = [];
                  let targetingReq = [];
                  try {
                    const targetBidResponse = await HttpService.post(
                      'GET_SD_TARGET_RECOMMENDATIONS',
                      formRequestPayload
                    );
                    targetRecommendations =
                      targetBidResponse?.data?.targetRecommendations?.products;
                    if (targetRecommendations?.length) {
                      targetRecommendations.forEach((item) => {
                        const requestObject = cloneDeep(
                          targetingCaluseReqFormation
                        );
                        const keyValuePairs = {
                          ':asinId': item.asin
                        };
                        for (const key in keyValuePairs) {
                          deepReplaceObjectValues(
                            requestObject,
                            key,
                            keyValuePairs[key]
                          );
                        }
                        targetingReq.push(requestObject);

                        if (item?.asin) {
                          item.entity_ui_label = `<b>Product: </b>${item.asin}`;
                          item.competitor_asin_title = item.asin;
                          item.competitor_asin = item.asin;
                        }
                      });

                      requestPayload.targetingClauses = targetingReq;

                      const TargetBidsResponse = await HttpService.post(
                        'GET_SD_CONTEXTUAL_TARGET_BIDS_RECOMMANDATIONS',
                        requestPayload
                      );
                      let TargetBidsResponseValues =
                        TargetBidsResponse?.data?.bidRecommendations
                          ?.bidRecommendations;

                      if (TargetBidsResponseValues?.length) {
                        for (let i = 0; i < targetRecommendations.length; i++) {
                          targetRecommendations[i].suggested_bid =
                            TargetBidsResponseValues[i].recommended;
                          targetRecommendations[i].new_bid =
                            targetRecommendations[i].new_bid ||
                            targetRecommendations[i].suggested_bid;
                        }
                      }
                    }
                  } catch {
                    vueRef.leftTableLoad = false;
                  }
                  vueRef.leftTableRows = targetRecommendations;
                  vueRef.leftTableInstance.redrawTableRows();

                  if (productAndCategoryRightTableRows?.length) {
                    vueRef.rightTableRows = productAndCategoryRightTableRows;
                  }
                  vueRef.leftTableLoad = false;
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: true
              },
              body: {
                useExistingWhereClause: false,
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                sdFilter: {
                  tactic: SD_TACTIC_PRODUCT_ADGROUP,
                  typeFilter: ['PRODUCT, CATEGORY'],
                  fromSDProductTargeting: true
                },
                getAllAsinsData: async () =>
                  await getAllAsinsData(context, vueRef, true),
                columnDefs: dictionary.add_targets_to_campaign_sd.left(vueRef)
              },
              headerText: 'Add Products'
            },
            right: {
              table: 'right',
              primaryKey: 'entity_ui_label',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: false,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: getColumnDefinition(
                  vueRef,
                  dictionary.add_targets_to_campaign_sp_and_sb.right(vueRef)
                )
              },
              headerText: () => `Added (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              context.callAddTargets(rows);
              vueRef.$emit('afterAPIActionToCall');
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const new_bid = arrayToTraverse[i]?.new_bid;
                if (!new_bid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
        }
      },
      staticHeaderText: 'ADD PRODUCTS'
    },
    events: {
      rightTableRows(rows) {
        saveAddedProductAndCategoryState(rows);
      },
      afterAPIActionToCall() {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
      },
      closePanelMethod() {
        saveAddedProductAndCategoryState([]);
        context.showAddCustomProductPanel = false;
      }
    }
  };
}
// -- End -- Add SD targets to campaign
