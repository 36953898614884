import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';

const state = {
  strategyList: [],
  isLoading: false,
  isError: false,
  entityList: [],
  filterMetaData: {
    data: [],
    isFilterMetaDataError: false,
    isFilterMetaDataLoading: false
  }
};

const getters = {
  getStrategyList: (state) => {
    return state;
  },
  getLoadingState: (state) => {
    return state.isLoading;
  },
  getErrorState: (state) => {
    return state.isError;
  },
  getFilterMetaDataInfo: (state) => {
    return state.filterMetaData.data;
  },
  getFilterMetaDataLoading: (state) => {
    return state.filterMetaData.isFilterMetaDataLoading;
  },
  getFilterMetaDataError: (state) => {
    return state.filterMetaData.isFilterMetaDataError;
  }
};

const mutations = {
  FILTER_CUBES_TO_CALL: (state, data) => {
    state.entityList = data;
  },
  SHOW_LOADING: (state) => {
    state.isLoading = true;
  },
  HIDE_LOADING: (state) => {
    state.isLoading = false;
  },
  STRATEGY_DATA: (state, data) => {
    state.strategyList = data;
  },
  SHOW_ERROR: (state) => {
    state.isError = true;
  },
  HIDE_ERROR: (state) => {
    state.isError = false;
  },
  SHOW_CUBE_ERROR: (state) => {
    state.filterMetaData.isFilterMetaDataError = true;
  },
  HIDE_CUBE_ERROR: (state) => {
    state.filterMetaData.isFilterMetaDataError = false;
  },
  SHOW_CUBE_LOADING: (state) => {
    state.filterMetaData.isFilterMetaDataLoading = true;
  },
  HIDE_CUBE_LOADING: (state) => {
    state.filterMetaData.isFilterMetaDataLoading = false;
  },
  STRATEGY_META_DATA: (state, data) => {
    state.filterMetaData.data = data;
  }
};

const actions = {
  fetchStartegyList: (context) => {
    context.commit('SHOW_LOADING');
    const requests = [];
    requests.push(HttpService.get('GET_STRATEGIES_WITH_DATA'));
    requests.push(HttpService.get('STRATEGYBUILDERMETADATA'));
    return HttpLayer.all(requests).then(
      (response) => {
        const dataCopy = {};
        const { actions = {} } = response[1].data;
        const { standardObjectives = [] } = response[1].data;
        const { entityList = [] } = response[1].data.scope;
        context.commit(
          'FILTER_CUBES_TO_CALL',
          entityList.map((item) => {
            return item.cubeSDKDetails;
          })
        );
        dataCopy.data = response[0].data.map((item) => {
          item.showDetails = false;
          const tempActions = actions[item.strategyEntityType];
          const selectedObjective = standardObjectives.filter((objective) => {
            return item.objective === objective.objective;
          })[0];
          const selectedAction = tempActions.filter((name) => {
            if (
              item.actions[0] &&
              item.actions[0].executionTemplate &&
              item.actions[0].executionTemplate.actionType === name.actionType
            ) {
              return name.actionName;
            }
          })[0];
          item.selected = {};
          item.selected.scope = item.scope;
          item.selected.condition = {};
          item.selected.condition.lookBackWindow = item.lookBackWindow;
          item.selected.condition.rules = item.rules;
          item.selected.timeperiod = {
            strategyEndDate: item.strategyEndDate,
            strategyStartDate: item.strategyStartDate
          };
          entityList.forEach((entity) => {
            if (entity.entityType === item.strategyEntityType) {
              item.selected.scope.strategyEntityType = entity.entityName;
              item.selected.scope.entityType = entity.entityType;
            }
          });
          item.selected.objective = selectedObjective;
          item.selected.actions = selectedAction || {};
          item.prevPriorityRank = item.priorityRank;
          return item;
        });
        context.commit('STRATEGY_DATA', dataCopy);
        context.commit('HIDE_ERROR');
        // context.commit('HIDE_LOADING');
        return dataCopy;
      },
      (err) => {
        context.commit('HIDE_LOADING');
        context.commit('SHOW_ERROR');
        this.$snackbar.open({
          message: 'Something Went Wrong !!!' || err,
          duration: 3000,
          actionText: ''
        });
      }
    );
    // setTimeout(() => {
    //   let dataCopy = {};
    //   dataCopy.data = data.data.map(item => {
    //     item.showDetails = false;
    //     return item;
    //   });
    //   context.commit('STRATEGY_DATA', dataCopy);
    //   // context.commit('HIDE_LOADING');
    // }, 250);
  },
  fetchCubeInformation: (context) => {
    context.commit('SHOW_CUBE_LOADING');
    context.commit('HIDE_CUBE_ERROR');
    const requests = [];
    for (const item of context.state.entityList) {
      requests.push(HttpService.post('CUBE_METADATA', item));
    }
    HttpLayer.all(requests).then(
      (response) => {
        let retailer = context.getters.getRetailer;
        const temp = {};
        let list =
          multiRetailerConfig.list[retailer] ||
          multiRetailerConfig.actionStep.default;
        // will be deprecated soon.
        for (let i = 0; i < response.length; i++) {
          const dimensionList =
            response[i]?.data?.response?.dimensionList || [];
          const measureList = response[i]?.data?.response?.measureList || [];
          temp[list[i]] = {};
          measureList?.forEach((item) => {
            if (item.name) {
              temp[list[i]][item.name] = item;
            }
          });
          dimensionList?.forEach((item) => {
            if (item.name) {
              temp[list[i]][item.name] = item;
            }
          });
        }
        context.commit('STRATEGY_META_DATA', temp);
        context.commit('HIDE_CUBE_LOADING');
      },
      (error) => {
        context.commit('HIDE_CUBE_LOADING');
        context.commit('SHOW_CUBE_ERROR', error);
      }
    );
  },
  fetchStartegyDraftList: (context) => {
    return HttpService.get('GET_STRATEGIES_WITH_DATA', {
      append: '&strategyStatus=DRAFT'
    });
  },
  strategyStatusUpdate: (context, data) => {
    return HttpService.patch('CREATE_STRATEGY', null, {
      append: `?strategyUniqueId=${data.strategyUniqueId}&strategyStatus=${data.status}`
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
