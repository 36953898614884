<template>
  <div class="u-display-flex u-flex-direction-column">
    <div class="u-flex-align-items-center u-display-flex u-spacing-mt-m">
      <CollapsibleTitle
        class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
        title="Bid Multiplier"
        :is-expanded="bidMultiplierExpand"
        title-font-class="u-font-size-5 u-font-weight-600"
        @click="$parent.$emit('bidMultiplierExpandClicked')"
      />
      <div class="u-spacing-pt-s">
        <div
          class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
        >
          Optional
        </div>
      </div>
    </div>
    <section
      v-show="bidMultiplierExpand"
      class="u-display-flex u-flex-direction-column"
    >
      <div class="u-display-flex u-flex-direction-column">
        <span class="u-font-size-2 u-font-weight-600 u-color-grey-base">
          What is bid multiplier?
        </span>
        <span
          class="u-spacing-mt-s u-font-size-5 u-font-weight-400 u-color-grey-lighter"
        >
          Choose how you want to pay for clicks on your ads.
        </span>
        <span
          class="u-spacing-mt-xl u-font-size-5 u-font-weight-600 u-color-grey-base"
        >
          Placement:
        </span>
        <div class="u-spacing-mt-m u-display-flex">
          <!-- Buy Box Input Box  -->
          <div
            v-if="showBuyBoxInputBox"
            class="u-display-flex u-flex-direction-column u-spacing-mr-m"
          >
            <span
              data-cy="buyBoxText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Buy Box</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlacement.buyBox"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>

          <!-- Search Ingrid Input Box -->
          <div
            v-if="showSearchIngridInputBox"
            class="u-display-flex u-flex-direction-column u-spacing-mr-m"
          >
            <span
              data-cy="searchIngridText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Search Ingrid</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlacement.searchIngrid"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>

          <!-- Home Page Input Box -->
          <div
            v-if="showHomePageInputBox"
            class="u-display-flex u-flex-direction-column u-spacing-mr-m"
          >
            <span
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Home Page</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlacement.homePage"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>

          <!-- Stock up Input Box  -->
          <div
            v-if="showStockUpInputBox"
            class="u-display-flex u-flex-direction-column u-spacing-mr-m"
          >
            <span
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Stock Up</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlacement.stockUp"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>
        </div>
        <FormErrorMessage
          :show-error="Boolean(formErrorObject.placementBidMultiplier)"
          :error-text="formErrorObject.placementBidMultiplier"
        />

        <span
          data-cy="platformText"
          class="u-spacing-mt-xl u-font-size-5 u-font-weight-600 u-color-grey-base"
        >
          Platform:
        </span>
        <div class="u-spacing-mt-m u-display-flex">
          <div class="u-display-flex u-flex-direction-column">
            <span
              data-cy="desktopText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Desktop</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlatform.desktop"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>
          <div class="u-display-flex u-flex-direction-column u-spacing-ml-m">
            <span
              data-cy="mobileText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >Mobile</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlatform.mobile"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>
          <div class="u-display-flex u-flex-direction-column u-spacing-ml-m">
            <span
              data-cy="appText"
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter u-spacing-mb-xs"
              >App</span
            >
            <div class="u-position-relative u-display-flex">
              <rb-input
                v-model="bidPlatform.app"
                type="number"
                class="u-width-100px height-custom-bid u-bg-color-grey-white"
                :step="0.01"
                @input="updatedBidPlacement"
              />
              <div
                class="u-position-absolute u-font-size-6"
                style="top: 12px; right: 4px"
              >
                %
              </div>
            </div>
          </div>
        </div>
        <FormErrorMessage
          :show-error="Boolean(formErrorObject.platformBidMultiplier)"
          :error-text="formErrorObject.platformBidMultiplier"
        />
      </div>
    </section>
  </div>
</template>
<script>
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
export default {
  components: {
    CollapsibleTitle,
    FormErrorMessage
  },
  props: {
    bidMultiplierExpand: {
      type: Boolean,
      default: false
    },
    bidMultiplierObject: {
      type: Object,
      default: {
        placement: { buyBox: '', searchIngrid: '', homePage: '', stockUp: '' },
        platform: { desktop: '', mobile: '', app: '' }
      }
    },
    showHomePageInputBox: {
      type: Boolean,
      default: true
    },
    showStockUpInputBox: {
      type: Boolean,
      default: true
    },
    showSearchIngridInputBox: {
      type: Boolean,
      default: true
    },
    showBuyBoxInputBox: {
      type: Boolean,
      default: true
    },
    formErrorObject: {
      type: Object,
      default: {
        placementBidMultiplier: '',
        platformBidMultiplier: ''
      }
    }
  },
  data: () => ({
    bidPlacement: {
      buyBox: '',
      searchIngrid: '',
      homePage: '',
      stockUp: ''
    },
    bidPlatform: {
      desktop: '',
      mobile: '',
      app: ''
    }
  }),
  watch: {
    bidMultiplierObject: {
      handler(newVal) {
        if (newVal?.placement) {
          this.bidPlacement = {
            ...this.bidPlacement,
            ...newVal.placement
          };
        }
        if (newVal?.platform) {
          this.bidPlatform = {
            ...this.bidPlatform,
            ...newVal.platform
          };
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    roundToTwoDecimalPlaces(originalObject) {
      const newObject = {};
      for (let key of Object.keys(originalObject)) {
        const valueToUpdate = originalObject[key];
        if (valueToUpdate === '') {
          newObject[key] = valueToUpdate;
          // Do nothing and continue
        } else {
          newObject[key] = (
            Math.round(parseFloat(valueToUpdate) * 100) / 100
          ).toString();
        }
      }
      return newObject;
    },
    updatedBidPlacement() {
      this.$nextTick(() => {
        this.bidPlacement = this.roundToTwoDecimalPlaces(this.bidPlacement);
        this.bidPlatform = this.roundToTwoDecimalPlaces(this.bidPlatform);
        this.emitBidMultiplierValues();
      });
    },
    emitBidMultiplierValues() {
      this.$parent.$emit('bidMultiplierValuesUpdated', {
        placement: this.bidPlacement,
        platform: this.bidPlatform
      });
    }
  }
};
</script>
