<template>
  <section
    class="u-display-flex u-flex-align-items-center u-spacing-p-s u-border-top u-border-bottom u-border-left u-border-right u-border-width-s u-border-color-grey-xxx-light u-border-radius-xs"
  >
    <div class="u-spacing-mr-s">
      <img
        :src="imageUrl"
        :style="{ width: imageWidth, height: imageHeight }"
        class="u-border-top u-border-bottom u-border-left u-border-right u-border-width-s u-border-color-grey-xxx-light"
      />
    </div>
    <div class="u-display-block max-width">
      <div class="u-color-grey-base u-font-size-6 overflow-styles">
        {{ asinName }}
      </div>
      <div
        class="u-spacing-mt-s u-color-grey-lighter u-font-size-7 overflow-styles"
      >
        {{ asin }}
      </div>
    </div>
    <div class="u-spacing-p-s u-cursor-pointer">
      <rb-icon
        class="u-flex-0 rb-icon--medium u-color-grey-base"
        :icon="'caret-down'"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    asin: {
      type: String,
      default: null
    },
    asinName: {
      type: String,
      default: null
    },
    imageHeight: {
      type: String,
      default: '46px'
    },
    imageWidth: {
      type: String,
      default: '46px'
    }
  }
};
</script>

<style lang="css" scoped>
.max-width {
  max-width: 200px;
}
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
