var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-height-100 u-width-100 u-spacing-ph-s u-cursor-default"},[(_vm.isDisabledRow)?_c('div',{staticClass:"u-position-absolute u-spacing-p-m u-height-100 u-bg-color-grey-base disabled-background",class:_vm.disabledRowClass}):_vm._e(),_c('div',{staticClass:"u-display-flex u-height-100 u-width-100 u-flex-justify-content-center u-flex-direction-column",class:[_vm.customClasses]},[(_vm.isDisabledRow)?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-justify-content-center"},[_c('span',{staticClass:"u-border-width-s u-border-top u-border-color-grey-dark",staticStyle:{"width":"2rem"}})]):_c('div',{staticClass:"u-display-flex",class:{
        'u-flex-direction-row u-flex-align-items-center u-flex-justify-content-space-around u-width-100':
          !_vm.isDetailedView && !_vm.isTippy,
        'u-flex-direction-column u-flex-justify-content-center':
          _vm.isDetailedView && !_vm.isTippy,
        'u-flex-direction-row u-flex-justify-content-space-around': _vm.isTippy,
        'confetti-bg': _vm.leader
      }},_vm._l((_vm.data),function(dataElement,index){return _c('div',{key:index,staticClass:"u-display-flex u-flex-direction-row",class:{
          'u-flex-justify-content-space-between':
            _vm.enablePrice && _vm.isClient(dataElement.client_flag),
          'u-flex-justify-content-center': _vm.leader
        }},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-width-max-content",class:{
            'u-flex-direction-column u-text-align-center':
              _vm.leader && _vm.data.length === 1,
            'u-flex-direction-row': !_vm.leader,
            'u-flex-justify-content-center': !_vm.isDetailedView,
            'u-spacing-mb-m':
              _vm.isDetailedView && index < _vm.data.length - 1 && !_vm.isTippy,
            'u-spacing-pr-xs u-flex-1': _vm.enablePrice
          }},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],staticClass:"u-display-block",class:{
              'u-border-color-blue-base u-spacing-p-xs u-border-radius-xxxl u-border-all client-flag':
                _vm.isClient(dataElement.client_flag)
            },attrs:{"title":dataElement.brand}},[(!_vm.initials[dataElement.brand])?_c('img',{staticClass:"brand-image u-border-color-grey-xxx-light u-border-width-s",class:{ 'logo-large-view': !_vm.isDetailedView },attrs:{"src":dataElement.imageUrl,"alt":"brand"},on:{"error":function($event){return _vm.handleNoImage(dataElement.brand)}}}):_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-3 u-border-width-s u-border-all u-text-case-upper u-color-grey-base u-border-color-grey-xxx-light brand-image"},[_vm._v(" "+_vm._s(_vm.initials[dataElement.brand])+" ")])]),(_vm.isDetailedView)?_c('div',{staticClass:"u-display-flex u-flex-justify-content-center u-flex-direction-column",class:{ 'u-spacing-ml-m': !_vm.leader || _vm.data.length > 1 }},[_c('div',{staticClass:"u-font-size-7 u-spacing-mv-xs u-font-weight-600 u-white-space-normal u-text-case-title",class:{
                'u-text-align-center':
                  dataElement.desc && _vm.leader && _vm.data.length === 1,
                'u-color-blue-base': _vm.isClient(dataElement.client_flag),
                'u-color-grey-base': dataElement.client_flag === 'comp'
              }},[(_vm.leader)?_c('img',{attrs:{"src":"https://cdn.rboomerang.com/cva/Leader_Crown.svg","alt":"leader"}}):_vm._e(),_vm._v(" "+_vm._s(dataElement.brand)+" ")]),(dataElement.desc)?_c('span',{staticClass:"u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-400",staticStyle:{"white-space":"break-spaces"}},[_vm._v(" "+_vm._s(_vm.hideDesc ? '' : dataElement.desc))]):_vm._e(),(dataElement.info)?_c('div',_vm._l((dataElement.info),function(infoObj){return _c('div',{key:infoObj.preText},[_c('span',{staticClass:"u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-400"},[_vm._v(" "+_vm._s(infoObj.preText)+" ")]),_c('span',{staticClass:"u-font-size-7 u-spacing-mb-s u-color-grey-base u-font-weight-600"},[_vm._v(" "+_vm._s(infoObj.text)+" ")]),(infoObj.textIcon)?_c('rb-icon',{staticClass:"icon-custom-class",class:infoObj.textIconClasses,attrs:{"icon":infoObj.textIcon}}):_vm._e()],1)}),0):_vm._e(),(dataElement.metricData)?_c('span',{staticClass:"u-spacing-mb-s"},[(!_vm.hideMetricData)?_c('span',{staticClass:"primary-metric u-font-size-4 u-font-weight-bold u-color-grey-base"},[_vm._v(_vm._s(dataElement.metricData.result))]):_vm._e()]):_vm._e()]):_vm._e()]),(
            _vm.enableSOP(
              dataElement.module,
              dataElement.client_flag,
              dataElement.sop_lower,
              dataElement.sop_higher
            )
          )?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-spacing-pl-s u-border-color-grey-xx-light u-border-left-style-dashed u-spacing-mt-s u-spacing-mb-l"},[_vm._m(0,true),_c('div',{staticClass:"u-display-flex u-flex-direction-row u-flex-align-items-center u-font-size-7 u-flex-wrap-yes"},[_c('span',{staticClass:"u-color-grey-base"},[_vm._v("Sales")]),_c('div',{staticClass:"u-display-flex u-flex-direction-row"},[_c('span',{staticClass:"u-font-weight-600 u-color-black-base u-spacing-ml-xxs"},[_vm._v(_vm._s(_vm.sopCalculation( dataElement.sop_lower, dataElement.sop_higher )))]),_c('rb-icon',{staticClass:"rb-icon--x-small u-color-green-base u-spacing-ml-xxs",attrs:{"icon":'sort-asc'}})],1)])]):_vm._e()])}),0),(_vm.enableShowMore)?_c('MoreBrandTooltip',{attrs:{"id":_vm.tooltipId,"data":_vm.tooltipData,"is-detailed-view":_vm.isDetailedView,"hide-metric-data":_vm.hideMetricData,"hide-desc":_vm.hideDesc}}):_vm._e(),(_vm.enableShowMore)?_c('div',{staticClass:"u-display-flex u-flex-justify-content-flex-end u-cursor-pointer custom-style u-font-weight-700"},[(_vm.tooltipData.length > 0)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOption),expression:"tippyOption"}]},[_vm._v(" + "+_vm._s(_vm.tooltipData.length)+" more ")]):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spacing-mb-s u-display-flex u-flex-direction-row u-flex-align-items-center"},[_c('img',{staticClass:"u-spacing-mr-xs u-width-10",attrs:{"src":"https://cdn.rboomerang.com/cva/Leader_Crown.svg","alt":"leader"}}),_c('span',{staticClass:"u-font-size-7 u-color-blue-base"},[_vm._v("Size of Prize")])])}]

export { render, staticRenderFns }