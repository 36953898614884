<template>
  <div
    class="scope_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <div
      v-if="step.name === 'Scope'"
      class="u-width-100 u-spacing-mb-xxl"
    >
      <div
        class="u-width-100 u-display-flex u-flex-justify-content-space-between"
      >
        <div>
          <Strategy-Header :step="step" />
          <rb-list-select
            v-model="selectedValues.strategyEntityType"
            :options="
              (metaDataConfig[step.keyToRead] &&
                metaDataConfig[step.keyToRead].entityList) ||
              []
            "
            :display-key-to-read="'entityName'"
          />

          <div class="u-spacing-mt-m">
            <rb-list-select
              v-model="selectedValues.scope.subFilter"
              :size="'small'"
              :options="
                (selectedValues.strategyEntityType &&
                  selectedValues.strategyEntityType.filters) ||
                []
              "
              :display-key-to-read="'name'"
            />
          </div>
        </div>
        <infoBox
          v-if="infoBox.show"
          class="u-max-width-20 u-flex-align-self-flex-start u-spacing-mb-m"
          :title="infoBox.title"
          :description="infoBox.description"
        />
      </div>
      <div
        v-if="
          selectedValues.scope.subFilter &&
          selectedValues.scope.subFilter.name &&
          selectedValues.scope.subFilter.custom
        "
        class="u-font-size-5 u-color-grey-x-light u-spacing-mt-s pageBackground u-border-radius-s u-spacing-pl-m u-width-100 u-display-flex u-flex-align-items-center u-scope-filter_wrp"
        style="min-height: 63px"
      >
        <rb-filter-panel
          :enable-save="false"
          :data="filterData"
          :primary="primaryFilterDataScope"
          :secondary-data="{}"
          :has-search="false"
          :enable-remove-all="false"
          :loader-status="loaderStatus"
          :on-create="onCreate"
          :use-local-storage="false"
          :emit="'scopeFilterChangeEvent'"
          :show-notification="false"
          :default-filters="defaultFilters"
          :listener-event="'strategy-scope'"
          :operators-to-filter-out="['IS_NULL', 'IS_NOT_NULL']"
        />
      </div>
      <div
        v-if="showEditValidationError"
        class="u-font-size-5 u-spacing-mt-l u-color-red-base"
      >
        <p>Validation Error.</p>
      </div>
    </div>
    <section class="u-margin-top-80 u-display-flex u-margin-bottom-80">
      <rb-button
        id="confirmButton"
        :text="'Back'"
        :type="'hollow'"
        :click-fn="movePrev.bind(this)"
        class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s"
      />
      <rb-button
        id="confirmButton"
        :text="'Next'"
        :disabled="disabledButton"
        :type="'filled'"
        :click-fn="moveNext.bind(this)"
      />
    </section>
    <div class="u-spacing-mb-xxl" />
    <div
      v-if="filterIsActive"
      class="scope-fill-space"
    />
  </div>
</template>
<script>
import StrategyHeader from './header';
import rbListSelect from '@/components/widgets/rbListSelect';
import rbFilterPanel from '@/components/widgets/filter-panel';
import { eventBus } from '@/utils/services/eventBus';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import widgetsMixin from '@/components/widgetsMixin';
import Vue from 'vue';
import infoBox from '@/components/widgets/infoBox';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';
import loader from '@/components/basic/loader';

export default {
  name: 'ScopeStep',
  components: {
    loader,
    StrategyHeader,
    rbListSelect,
    rbFilterPanel,
    infoBox
  },
  mixins: [stepsMixin, widgetsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      showEditValidationError: false,
      filterIsActive: false
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    currentEntity() {
      const entityType = this.selectedValues?.strategyEntityType?.entityType;
      const entityList = this.metaDataConfig?.scope?.entityList || [];
      const matchedEntity = entityList.filter(
        (entity) => entity.entityType === entityType
      )[0];
      return matchedEntity;
    },
    infoBox() {
      return {
        show:
          this.currentEntity?.hasMetadata &&
          this.currentEntity?.metadata?.infoBox,
        description: this.currentEntity?.metadata?.infoBox?.description,
        title: this.currentEntity?.metadata?.infoBox?.title
      };
    },
    defaultFilters() {
      const filtersExtesnsion = multiRetailerConfig.filtersExtension.default;
      if (this.selectedValues && this.selectedValues.scope.subFilter) {
        if (
          this.selectedValues.scope.scopeRules &&
          this.selectedValues.scope.scopeRules.length > 0
        ) {
          // TODO: We are using in this way because, when filters are set using computed property, it's not emiting the filter values once it's set.
          const obj = this.transformFilterFromAPI(
            this.selectedValues.scope.scopeRules
          );
          for (const key in obj) {
            if (filtersExtesnsion[key]) {
              obj[key] = obj[key].map((value) =>
                value.replaceAll(
                  filtersExtesnsion[key].type +
                    filtersExtesnsion[key].delimiter,
                  ''
                )
              );
            }
          }
          return { order: Object.keys(obj) || [], values: obj };
        }
      } else {
        return {
          order: [],
          values: {}
        };
      }
    },
    primaryFilterDataScope() {
      if (!this.filterData.length) return [];
      const tempFilters = this.aggregateScopeFilters(
        this.metaDataConfig.scope,
        this.selectedValues?.strategyEntityType?.entityType
      );
      const tempFiltersMetric = tempFilters.map((filter) => {
        return filter.metric;
      });

      const obj = this.$store.getters.getPrimaryFilterData.filter((filters) => {
        if (
          filters.dimensionType === 'CAMPAIGN_INTERNAL_CATALOGUE' || // enabling campaign categorization for all entities from UI
          tempFiltersMetric.includes(filters.dimensionName)
        ) {
          return filters;
        }
      });

      if (this.currentEntity?.metadata?.filtersExtension?.length) {
        obj.push(...this.currentEntity.metadata.filtersExtension);
      }

      return obj;
    },
    disabledButton() {
      let disabled = false;
      // TODO: Don't remove the following line. It's retrigger the validation when the filter values changes. also in the edit case, need to trigger the validation when the filter data returned from API.
      if (this.selectedValues.scopeFilterValues) {
      }
      if (this.primaryFilterDataScope) {
      }

      if (
        this.selectedValues.strategyEntityType &&
        this.selectedValues.strategyEntityType.entityType
      ) {
        // Sub filter not selected
        if (!this.selectedValues.scope.subFilter) {
          disabled = false;
        } else if (
          this.selectedValues.scope.subFilter &&
          !this.selectedValues.scope.subFilter.custom
        ) {
          disabled = false;
        } else {
          if (this.selectedValues.scope.subFilter.custom) {
            if (this.filterInstance) {
              const temp = this.filterInstance.getSelectedFilters() || {};
              // Checking for Order - Becoz values are not updating properly on closing the data.
              // TODO Filter Revamp
              if (temp.order.length > 0) {
                disabled = false;
              } else {
                disabled = true;
              }
            } else {
              disabled = true;
            }
          } else {
            disabled = true;
          }
        }
      } else {
        disabled = true;
      }
      this.$parent.$emit('stepsGlobalEvent', {
        type: 'ValidationBtn',
        values: { step: this.step.textMapping, valid: !disabled }
      });
      return disabled;
    }
  },
  watch: {
    loaderStatus(newValue) {
      // ensuring loader status here reflects in conditions step too.
      this.selectedValues.conditionStepLoaderStatus = newValue;
    },
    'selectedValues.strategyEntityType': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.fetchFilters(
            this.selectedValues.strategyEntityType.cubeSDKDetails.cubeName,
            this.selectedValues.strategyEntityType.cubeSDKDetails.pageName,
            false,
            false,
            true,
            'setFilterPrimaryData',
            { commonFilterEnabled: true }
          );
          // Added a functionality for pushing the data to the $store.
          // trigger the strategyList API to get Strategies filters if SKUs is selected
          if (
            newValue.entityType === 'ASIN' ||
            (this.retailer === 'kroger_promoteiq' &&
              newValue.entityType === 'SKU')
          ) {
            this.$store.dispatch('fetchStrategyListFilterData', {
              entityType: newValue.entityType
            });
          }
        }
        if (newValue && oldValue) {
          Vue.set(this.selectedValues.scope, 'subFilter', null);
          Vue.set(this.selectedValues.scope, 'scopeRules', null);
          Vue.set(this.selectedValues, 'actions', null);
          // Don't change this logic, this is to make sure that, codition component is listening to the change of the entity selection.
          if (this.selectedValues.rules === undefined) {
            Vue.set(this.selectedValues, 'rules', null);
          } else {
            Vue.set(this.selectedValues, 'rules', undefined);
          }
        }
      },
      deep: true
    },
    'selectedValues.scope.subFilter': {
      handler: function (newValue, oldValue) {
        if (newValue && oldValue) {
          Vue.set(this.selectedValues.scope, 'scopeRules', null);
          const obj = { order: [], values: {} };
          this.filterInstance && this.filterInstance.setFilters(obj);
        }
      },
      deep: true
    }
  },
  mounted() {
    const that = this;
    eventBus.$on('filterStateChanged', (visible) => {
      this.filterIsActive = visible;
    });
    eventBus.$on('scopeFilterChangeEvent', (params) => {
      Vue.set(that.selectedValues, 'scopeFilterValues', params);
    });
  },
  methods: {
    aggregateScopeFilters(scope, entityType) {
      // used to aggrate scope custom and common filters based on entity type.
      if (!entityType) return scope?.filterNames || [];
      let customFilters = [];
      for (const item of scope?.customFilterNames || []) {
        if (item.entityType === entityType) {
          customFilters = item.value;
          break;
        }
      }
      return scope.filterNames.concat(customFilters);
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    moveNext() {
      this.formatDisplayText(this.step.textMapping);
      this.$emit('nextStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    movePrev() {
      this.$emit('prevStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    }
  }
};
</script>
<style lang="css" scoped>
.u-scope-filter_wrp > .filter-dropdown-menu {
  margin-bottom: 16px;
}
.scope-fill-space {
  height: 300px;
  width: 100px;
}
</style>
