<template>
  <div
    ref="catalog_container_ref"
    class="entity_ingestion_container u-flex-1 u-display-flex u-width-100"
  >
    <ingestion-welcome
      v-if="journeyStage === 'ingestion-success'"
      image-path="/images/ingestion-success.png"
      description-icon="check-fill-circle"
      :description="textConfigs.success.text"
      :sub-text="textConfigs.success.subText"
    >
      <div slot="primaryButton">
        <rb-button
          :click-fn="() => redirectToSpecificStage('preview')"
          class="u-width-100 u-spacing-mt-m"
          :text="'Redirect Now'"
          :type="'filled'"
        />
      </div>
    </ingestion-welcome>
    <ingestion-welcome
      v-if="journeyStage === 'welcome'"
      :button-text="textConfigs.welcome.ButtonText"
      :description="textConfigs.welcome.Description"
      :loading="isEntityLoading"
      @launchTriggered="launchTriggered"
    />
    <collapsible-page
      v-if="
        ['bulk_upload', 'taxonomy-select', 'select-upload-type'].includes(
          journeyStage
        )
      "
      close-icon="right-arrow"
      close-icon-classes="flipIcon rb-icon--large"
      :close-icon-styles="{ padding: '0.5rem' }"
      :page-title="textConfigs.page.Title"
      :page-description="textConfigs.page.Description"
      @closePage="catalogViewClose"
    >
      <bulk-change
        v-if="
          journeyStage === 'bulk_upload' ||
          journeyStage === 'select-upload-type'
        "
        class="u-spacing-ph-l"
        :loading="isEntityLoading"
        :accepted-file-format="acceptedFileFormat"
        :current-stage="uploadStatus"
        :text-configs="textConfigs.bulkUpload"
        :inner-components="upload.innerComponents[journeyStage]"
        :file-details="getEntityFileDetails"
        :error-list="errorList"
        :hide-footer="isReuploadScreen"
        :show-status="!isReuploadScreen"
        :warning-text="bulkChangeWarningText"
        :journey-stage="journeyStage"
        :right-icon="isReuploadScreen ? '' : 'check-fill-circle'"
        @footerClicked="footerClicked"
        @tooltipClicked="tooltipClicked"
        @onUpload="onUpload"
        @onNext="onNext"
        @onBack="onBack"
        @removeFile="onFileRemove"
        @downloadTemplate="(...args) => downloadTemplate(...args, true)"
        @onCancel="onBack"
      >
        <div
          v-if="entity === 'campaign'"
          slot="info_section"
        />
      </bulk-change>
      <select-taxonomy
        :upload-type="upload.currentType"
        :show-component="journeyStage === 'taxonomy-select'"
        class="select_taxonomy_section u-display-flex u-flex-direction-column u-spacing-ml-xxl u-spacing-pl-s u-spacing-mt-xxxl"
        :column-defs="selectTaxonomyComp.columns"
        :all-rows="selectTaxonomyComp.rows"
        :skus-count="catalogSelect.skusCount"
        :catalog-count="catalogSelect.catalogCount"
        :loading="isEntityLoading"
        :entity-config="selectTaxonomyConfig"
        @onSelectionComplete="onSelectionComplete"
        @onNext="onNext"
        @onBack="onBack"
      />
    </collapsible-page>
    <editable-dynamic-table
      v-if="journeyStage === 'preview'"
      :all-rows="manageCurrenyTaxonomyInfo.rowList"
      :column-defs="manageCurrenyTaxonomyInfo.columnDefs"
      :grid-options="manageTaxonomyOptions.gridApi"
      :search-placeholder="manageTaxonomyOptions.searchPlaceholder"
      :table-config="tableConfig"
      :loading="isEntityLoading"
      :show-active-filter-banner="false"
      :row-per-page-list="rowPerPageList"
      :show-search="true"
      :show-filter="true"
      :entity-config="editableTableConfig"
      @filtersApplied="filtersApplied"
      @onSearchTrigger="onSearchTrigger"
    >
      <span
        v-if="!entity.includes('catalog')"
        slot="left_action_header"
        :style="{ position: 'relative', top: '6px' }"
        class="u-spacing-ml-m u-display-flex u-flex-align-items-center u-spacing-mr-s"
      >
        <bulk-search
          bulk-search-ref="entity"
          :bulk-search-props="bulkSearchProps"
          :standard-search-config="{
            placeholder: `Search ${entityName}s`,
            emit: 'onSearchTriggered'
          }"
        />
      </span>
      <span
        slot="action_header"
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m"
      >
        <span class="u-flex-1" />
        <div
          v-if="helpDialogPath"
          slot="preDatePicker"
          class="u-border-right u-border-color-grey-xxx-light u-border-width-s"
        >
          <div
            v-tippy="{ placement: 'top', arrow: false }"
            class="u-border-radius-xs u-spacing-p-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-m u-bg-color-grey-white u-display-flex u-height-36px"
            title="Know more about this dashboard"
            expoter="display-none"
            @click="openHelpDialog"
          >
            <rb-icon
              class="u-color-grey-lighter"
              :icon="'info-circle-fill'"
            />
          </div>
        </div>
        <span
          class="u-border-width-s u-border-color-grey-xxx-light u-spacing-ph-m"
        >
          <rb-select
            v-if="!showCatalogSave"
            class="valueDropdown u-border-none"
            :send-details="true"
            :on-close="editOptionSelected"
            :options="manageTaxonomyOptions.editOptions"
          >
            <rb-button
              slot="trigger"
              :text="`Edit ${
                entity === 'catalog'
                  ? 'Standard Internal Catalog'
                  : 'Campaign Taxonomy'
              }`"
              class="u-spacing-p-m edit-catalog-button"
              size="s"
              icon-left="pencil"
              type="filled"
            />
          </rb-select>
          <div
            v-else
            class="u-display-flex u-flex-align-items-center"
          >
            <span
              class="u-cursor-pointer u-color-blue-base u-spacing-ph-m u-spacing-pv-s u-spacing-mh-m u-font-size-7 u-font-weight-600 u-border-radius-s u-border-all u-border-width-s u-border-color-blue-base u-height-100"
              @click="onSaveCancel"
            >
              Cancel
            </span>
            <span
              class="u-cursor-pointer u-spacing-ph-m u-spacing-pv-s u-border-radius-s u-display-inline-flex u-flex-align-items-center u-bg-color-blue-base u-height-100"
              @click="triggerCatalogDataSave"
            >
              <rb-icon
                class="u-color-white-base u-spacing-mr-s rb-icon--small"
                icon="save"
              />
              <span
                class="u-font-size-7 u-color-white-base u-font-weight-600 u-height-100"
                >Save
                {{
                  entity === 'catalog'
                    ? 'standard catalog'
                    : 'campaign taxonomy'
                }}</span
              >
            </span>
          </div>
        </span>
        <span
          class="u-display-flex u-border-width-s u-border-left u-border-color-grey-xxx-light u-spacing-ph-m"
        >
          <div
            v-for="(iconInfo, key) in manageTaxonomyOptions.actions"
            :key="key"
            class="u-display-flex u-flex-direction-column u-spacing-m-xs taxonomy-icon-button u-color-grey-lighter"
            :style="{ marginRight: '-1.5rem' }"
            @click="actionIconClick(iconInfo)"
          >
            <rb-icon
              class="rb-icon--medium u-cursor-pointer"
              :class="iconInfo.IconClassName"
              :icon="iconInfo.icon"
            />
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              class="u-spacing-pt-xs"
              :title="iconInfo.icon === 'download' ? iconInfo.toolTipText : ''"
            >
              <span
                class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-cursor-pointer"
                :class="iconInfo.textClassName"
                >{{ iconInfo.text }}</span
              >
            </div>
          </div>
        </span>
      </span>
      <Banner
        v-if="showBanner"
        slot="banner"
        :show-cta="false"
        :show-close-icon="true"
        :icon-name="'info-circle-fill'"
        :custom-class="bannerConfig.customClass"
        v-on="bannerConfig.events"
      >
        <div
          slot="customBannerTextSlot"
          class="u-font-size-5"
        >
          <span class="u-font-weight-400 u-spacing-mr-16px">Click </span>
          <span
            style="text-decoration: underline; text-decoration-style: dotted"
            class="u-cursor-pointer u-spacing-mr-16px u-color-blue-base u-font-weight-600 u-display-inline-block"
            @click="tagUnmappedCampaigns()"
          >
            here
          </span>
          <span class="u-font-weight-400 u-spacing-mr-16px">
            {{ bannerText }}
          </span>
        </div>
      </Banner>
      <actions-bar
        v-if="journeyStage === 'preview' && rowSelections.length"
        slot="table_header"
        v-bind="actionsBarConfig.props || {}"
        v-on="actionsBarConfig.events || {}"
      ></actions-bar>
    </editable-dynamic-table>
    <addSkuToCatalog v-if="journeyStage === 'manage-catalog-asin'" />
    <span ref="confirmDialog" />
    <HelpDocumentDialog
      v-if="showHelpDialog"
      :full-path="helpDialogPath"
      @closeCallback="closeCallback"
    />
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="entities-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import editableTagsCell from '@/components/widgets/tableComponents/cells/editableTagsCell.vue';
import tagsViewCell from '@/components/widgets/tableComponents/cells/tagsViewCell.vue';
import ingestionWelcome from '@/components/basic/welcomeImageBannerButton.vue';
import bulkChange from '@/components/widgets/upload/bulkChange.vue';
import selectTaxonomy from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/selectTaxonomy.vue';
import editableDynamicTable from '@/components/widgets/tables/editableDynamicTable.vue';
import widgetConfig from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/widgets';
import confirmDialog from '@/components/basic/confirmDialog.vue';
import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';
import transformer from '@/utils/services/data-transformer';
import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import dynamicTableWidget from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/dynamicTableWidget';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
import reUploadSelect from './reUploadSelect.vue';
import {
  downloadLinkAsFile,
  downloadExcelFromS3Csv
} from '@/utils/helpers/downloader';
import utils from '@/utils/helpers/index.js';
import addSkuToCatalog from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/entity-catalog/bulk-entity-manage.vue';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import entityConfigs from '@/pages/toolsAndSettings/entityConfig.js';
import dataCell from '@/components/widgets/tableComponents/cells/dataCell.vue';
import dataHeader from '@/components/globals/dataTable/tableHeaderComponents/dataHeader.vue';
import actionsBar from '@/components/widgets/custom_widgets/cw_actionsBar.vue';
import ColumnValueEdit from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/columnValueEdit.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import {
  Entities,
  EntitiesForPostProcessingStatus
} from '@/pages/toolsAndSettings/const.js';
import { eventBus } from '@/utils/services/eventBus';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import Banner from '@/pages/entity_details/common_components/banner.vue';
export default {
  name: 'EntityTaxonomy',
  components: {
    ingestionWelcome,
    bulkChange,
    selectTaxonomy,
    collapsiblePage,
    editableDynamicTable,
    confirmDialog,
    addSkuToCatalog,
    HelpDocumentDialog,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    Banner
  },
  data() {
    const entity = this.$route.params.entity;
    const retailer = this.$store.getters.getRetailer;
    const widgetConfigInstance = widgetConfig.config(entity);
    const manageTaxonomyTableColumnDefs = transformer.getColumnDefinition(
      dynamicTableWidget.config(entity).widgets.selectTaxonomy.columnDefs
    );
    const { dataProps, computedProps } = entityConfigs[
      entity
    ].getDataAndComputedProps(this, retailer);
    return {
      budgetPlanFilters: [],
      rowPerPageList: [
        { title: 10 },
        { title: 20 },
        { title: 30 },
        { title: 50 }
      ],
      sessionIdentifier: null,
      timeKeeper: {
        loadStart: null,
        ingestionCompleteTime: null
      },
      startUploaderTime: null,
      redirectCountdownTimer: 10,
      showHelpDialog: false,
      showConfirmPopup: true,
      CONSTANTS: {
        SUCCESS_BANNER_TIME: 10000
      },
      searchText: '',
      journeyStage: 'welcome',
      acceptedFileFormat: ['.xlsx', '.xls'],
      editableCells: {},
      catalogSelect: {
        rows: [],
        columns: widgetConfigInstance.widgets.selectTaxonomy.columnDefs || [],
        skusCount: null,
        catalogCount: null
      },
      catalogAsinPage: {},
      pageJourney: [],
      uploadStatusData: 'upload',
      errorListData: [],
      validationResponse: {
        errors: [],
        data: [],
        bValidationSuccess: false,
        fileDetails: {
          fileBuffer: null,
          name: null,
          lastModified: null,
          lastModifiedDate: null
        },
        success: true
      },
      errorContainerClass:
        'u-width-100 u-display-flex u-flex-align-items-center u-spacing-p-xl u-flex-justify-content-space-between u-border-radius-s errorBorder',
      openActivity: false,
      manageTaxonomyTableColumnDefs:
        manageTaxonomyTableColumnDefs.displayColConfigs,
      dynamicTableWidget: dynamicTableWidget.config(entity),
      manageTaxonomyState: 0,
      enableColumnCreate: false,
      ...dataProps,
      dataProps,
      computedProps,
      entityConfig: entityConfigs[entity].config,
      rowSelections: [],
      isCustomActionStateVisible: false,
      entity,
      retailer,
      hideBanner: false,
      enableLatestTaxonomy: Vue.options.filters.config_check(
        `feature.pages.${retailer}.enableLatestTaxonomy`
      )
    };
  },
  computed: {
    showBanner() {
      return (
        this.enableLatestTaxonomy &&
        this.entity === Entities.campaign &&
        (this.budgetPlanFilters.length > 0 ||
          (this.persistedFilters?.length && !this.budgetPlanFilters?.length)) &&
        !this.hideBanner
      );
    },
    persistedFilters() {
      return this.$store.getters.getPersistedFilters(Entities.campaign) || [];
    },
    bannerConfig() {
      return {
        customClass: 'u-bg-color-blue-light unmappedCampaignsBanner',
        showCloseIcon: true,
        events: {
          closeIconClicked: () => {
            this.hideBanner = true;
            this.tagUnmappedCampaigns(false);
          }
        }
      };
    },
    bannerText() {
      return this.persistedFilters.length
        ? ' to remove unmapped campaigns'
        : ' to tag unmapped campaigns';
    },
    isReuploadScreen() {
      return this.journeyStage === 'select-upload-type';
    },
    showCatalogSave() {
      return (
        this.manageTaxonomyOptions.tableEditMode === 'edit' ||
        Object.keys(this.editableCells).length > 0
      );
    },
    textConfigs() {
      return this.computedProps.textConfigs(
        this.bTaxonomyExists,
        this.redirectCountdownTimer
      );
    },
    tableConfig() {
      return {
        entity: this.entityData.entity,
        meta: { action: 'reactToPagination' },
        totalRows: this.paginationData?.count,
        body: {
          APIConfig: {
            page: this.catalogEntityStore.page,
            limit: this.catalogEntityStore.limit
          },
          searchText: this.searchText,
          searchKey: this.dataProps?.readOnlyColumns?.[this.retailer]?.[0]?.key,
          requestBody:
            this.entity === Entities.campaign
              ? this.computedProps.getEntityReadAPIRequestBody(this.retailer)
              : {}
        },
        ...(this.entity !== 'catalog'
          ? {
              rowSelection: {
                mode: 'multiple',
                onSelectionChanged: this.handleSelectionChange
              }
            }
          : {})
      };
    },
    isEntityLoading() {
      const taxonomyLoading = this.$store.getters.getEntityLoadingState(
        this.entityData.entity
      );
      return this.enableLatestTaxonomy && this.entity === Entities.campaign
        ? taxonomyLoading ||
            this.$store.getters['budgetPlannerDashboardV2/isDashboardLoading']
        : taxonomyLoading;
    },
    catalogEntityStore() {
      return this.$store.getters.getEntityStore(this.entityData.entity);
    },
    bTaxonomyExists() {
      return this.$store.getters.getEntityExists(this.entityData.entity);
    },
    previewData() {
      const preview =
        this.$store.getters.getEntityPreviewData(this.entityData.entity)?.data
          ?.entityData?.response?.[0] || {};
      return preview;
    },
    paginationData() {
      const paginationData = this.$store.getters.getEntityPreviewData(
        this.entityData.entity
      )?.data?.entityData?.pagination;
      return paginationData || {};
    },
    getEntityFileDetails() {
      const fileInfo = this.$store.getters.getEntityFileDetails(
        this.entityData.entity
      );
      if (
        (fileInfo && !fileInfo.name) ||
        this.journeyStage === 'select-upload-type'
      ) {
        fileInfo.name = `Your ${this.entityConfig?.altLabel}`;
        fileInfo.lastModifiedDate = '';
      }
      if (this.uploadStatus === 'loading' && this.entityData?.file) {
        fileInfo.name = this.entityData.file.name;
      }
      return fileInfo || {};
    },
    uploadStatus() {
      if (this.journeyStage === 'select-upload-type') {
        return 'success';
      }
      return this.uploadStatusData;
    },
    errorList() {
      return this.$store.getters.getEntityErrorList(this.entityData.entity);
    },
    selectTaxonomyComp() {
      let rows = (
        (this.catalogSelect.rows || []).map((catalog) => {
          return {
            dimensionLabel: catalog.dimensionLabel,
            dimensionColumn: catalog.dimensionColumn,
            uniqueValueCount: catalog.uniqueValueCount,
            noSkuCount: catalog.noSkuCount,
            isNewFilter: catalog.isNewFilter
          };
        }) || []
      ).filter((rowItem) => {
        return !['asin', 'entity_id'].includes(rowItem.dimensionColumn);
      });
      this.catalogSelect.columns.find((column) => {
        if (column.field === 'noSkuCount') {
          column.cellClassRules = {
            'skuCount-text-color-red': (params) => params.value > 0
          };
        }
      });
      rows = this.decodeApstrophe(rows);
      return {
        rows,
        columns: this.catalogSelect.columns
      };
    },
    manageCurrenyTaxonomyInfo() {
      let columnList = this.decodeApstrophe(
        cloneDeep(this.previewData?.dimensionValueAndCountList || [])
      );
      const rowList =
        this.previewData?.[
          this.entity === Entities.campaign
            ? 'entityDataAndValuesList'
            : 'skuDataAndValues'
        ] || [];
      columnList = this.computedProps.addReadOnlyColumns(
        columnList,
        this.retailer
      );
      if (this.enableColumnCreate) {
        columnList.splice(1, 0, {
          dimensionColumn: 'newColumn',
          dimensionLabel: 'Taxonomy Column’s name',
          uniqueValueCount: null
        });
      }

      const columnDefs =
        this.getDynamicColumnDefs(columnList, this.budgetPlanFilters) || [];
      return {
        columnDefs,
        rowList
      };
    },
    actionsBarConfig() {
      return this.computedProps?.actionsBar?.(this);
    },
    bulkSearchProps() {
      return {
        title: 'Bulk Search',
        subTitle: `${this.entityName}s`,
        inputFieldPlaceholder: `Search ${this.entityName}`,
        findEntityInputPlaceholder: `Search ${this.entityName}s`,
        maxPasteLimit: 1000
      };
    }
  },
  watch: {
    'catalogEntityStore.page': {
      handler(nv, ov) {
        if (nv !== ov) {
          this.resetRowSelections();
        }
      }
    },
    'catalogEntityStore.limit': {
      handler(nv, ov) {
        if (nv !== ov) {
          this.resetRowSelections();
        }
      }
    },
    journeyStage(newVal) {
      this.pageJourney.push(newVal);
      if (newVal === 'ingestion-success') {
        this.redirectCountdownTimer = 10;
        this.redirectCountdownTimerLoop();
      }
    }
  },
  created() {
    try {
      // AG Grid wants the components to be present as global to recognise it.
      Vue.component('editableTagsCell', editableTagsCell);
      Vue.component('tagsViewCell', tagsViewCell);
      Vue.component('reUploadSelect', reUploadSelect);
      Vue.component('loader', loader);
      Vue.component('dataCell', dataCell);
      Vue.component('dataHeader', dataHeader);
      Vue.component('actionsBar', actionsBar);
      Vue.component('ColumnValueEdit', ColumnValueEdit);
      this.sessionIdentifier = new Date().getTime();
      this.fetchStatus();
    } catch (error) {
      console.log({ error });
    }
  },
  mounted() {
    const that = this;
    eventBus.$on('onSearchTriggered', (payload) => {
      that.onSearchTrigger(payload);
    });
    eventBus.$on('entityBulkSearchApplied', (payload) => {
      that.onSearchTrigger(payload);
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.entity?.openBulkSearchPopup();
    });
  },
  beforeDestroy() {
    eventBus.$off('onSearchTriggered');
    eventBus.$off('entityBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  methods: {
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    tagUnmappedCampaigns(toggle = true) {
      if (this.persistedFilters.length || toggle === false) {
        this.$store.dispatch('clearPersistedFilters', Entities.campaign);
      } else {
        this.$store.dispatch('setPersistedFilters', {
          entity: Entities.campaign,
          filters: this.budgetPlanFilters
            .map((level) =>
              level?.dimensionName &&
              level?.dimensionName?.startsWith?.('dimension')
                ? {
                    dimensionName: level.dimensionName,
                    dimensionValue: null,
                    operator: 'IS_NULL',
                    esDataSetName: this.esDataSetName
                  }
                : null
            )
            .filter(Boolean)
        });
      }
      this.readExistingTaxonomy();
    },
    async fetchStatus() {
      try {
        let fetchBudgetPlanFilters = null;
        if (this.enableLatestTaxonomy && this.entity === Entities.campaign)
          fetchBudgetPlanFilters = this.$store.dispatch(
            'budgetPlannerOmni/fetchLatestStrategy'
          );
        await this.$store.dispatch('fetchEntityExistsStatus', this.entityData);

        if (this.bTaxonomyExists) {
          if (this.enableLatestTaxonomy && this.entity === Entities.campaign)
            await fetchBudgetPlanFilters;
          this.journeyStage = 'preview';
          this.readExistingTaxonomy();
        } else {
          this.journeyStage = 'welcome';
        }
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong!',
          duration: 5000,
          actionText: '',
          customStyles: 'background-color: #d7263d !important'
        });
      }
    },
    resetRowSelections() {
      this.rowSelections = [];
      this.isCustomActionStateVisible = false;
    },
    openBulkSearchHelperPopup(showCheckbox = false) {
      let showHelpModalCheckbox = showCheckbox;
      this.$refs.bulkSearchHelpModal?.openBulkSearchHelperPopup(
        showHelpModalCheckbox
      );
    },
    editColumns({ selections, actions }) {
      const actionsObj = actions.reduce((acc, action) => {
        acc = { ...acc, ...action };
        return acc;
      }, {});
      const result = selections.reduce((acc, selection) => {
        acc[selection[this.primaryKey]] = {
          [selection.data[0].recordId]: actionsObj
        };
        return acc;
      }, {});
      this.manageTaxonomyOptions.catalogCrudOperations = result;
      this.triggerCatalogDataSave();
    },
    handleSelectionChange(selections, manuallyDeselectGrid = false) {
      this.rowSelections = selections.map((sel) => ({ ...sel.data })) || [];
      if (this.rowSelections.length <= 0) {
        this.isCustomActionStateVisible = false;
      }
      if (manuallyDeselectGrid === true) {
        // this scenario happens when user clicks on x mark in acitons bar, we send true manually
        this.manageTaxonomyOptions?.gridApi?.api?.deselectAll();
      }
    },
    openSnackbar(content) {
      this.$snackbar.open({
        message: content,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },
    enocdeApstrophe(rows) {
      rows = rows.map((col) => {
        if (col.dimensionLabel) {
          col.dimensionLabel = col?.dimensionLabel.replace("'", '%27');
        }
        return col;
      });
      return rows;
    },
    decodeApstrophe(rows) {
      rows = rows.map((col) => {
        if (col.dimensionLabel) {
          col.dimensionLabel = col?.dimensionLabel.replace('%27', "'");
        }
        return col;
      });
      return rows;
    },
    footerClicked(event) {
      if (event.srcElement.id === 'catalog-info-download-trigger') {
        this.downloadTemplate();
      }
    },
    openHelpDialog() {
      this.showHelpDialog = true;
    },
    closeCallback() {
      this.showHelpDialog = false;
    },
    tooltipClicked(event) {
      if (event.srcElement.id === 'open-catalog-page') {
        window.open(window.location.href, '_blank');
      }
    },
    redirectToSpecificStage(stage) {
      this.journeyStage = stage;
    },
    // Timer to redirect the success page to preview page after 10 seconds
    redirectCountdownTimerLoop() {
      if (this.redirectCountdownTimer > 0) {
        setTimeout(() => {
          this.redirectCountdownTimer--;
          this.redirectCountdownTimerLoop();
        }, 1000);
      } else if (this.redirectCountdownTimer === 0) {
        this.redirectToSpecificStage('preview');
      }
    },
    onSaveCancel() {
      this.editModeChange('view');
      this.clearPendingSaveItems();
    },
    filtersApplied(oFiltersData) {
      this.$store
        .dispatch('updateEntityFilters', {
          entity: this.entityData.entity,
          filters: oFiltersData
        })
        .then(() => {
          this.readExistingTaxonomy();
        })
        .catch((err) => {
          this.$snackbar.open({
            message: `Something Went Wrong! ${err}`,
            duration: 5000,
            actionText: '',
            customStyles: 'background-color: #d7263d !important'
          });
        });
    },
    getTableInteractiveState(tableEditMode, asin, column) {
      return tableEditMode === 'edit' ? 'edit' : 'view';
    },
    downloadExistingFile() {
      this.$store
        .dispatch('downloadEntity', { entity: this.entityData.entity })
        .then(() => {
          const downloadLink = this.$store.getters.getEntityDownloadLink(
            this.entityData.entity
          );
          // downloadLink => 's3://taxonomy-and-tags/development/digital-shelves/product1Standard Digital Shelf - User Test Upload.csv'
          if (!downloadLink) {
            this.$snackbar.open({
              message: 'Error. Download Link Empty. Please try again later',
              duration: 5000,
              actionText: ''
            });
            return;
          }
          this.$store.dispatch('setEntityLoading', {
            entity: this.entityData.entity,
            isLoading: true
          });
          const fileName = downloadLink.match(/.*\/(.*)\..*/)[1];
          downloadExcelFromS3Csv(downloadLink, fileName).then(() => {
            this.$logger.logEvent({
              eventType: 'download_existing_entity',
              entityType: this.entityData.entity
            });
            this.$store.dispatch('setEntityLoading', {
              entity: this.entityData.entity,
              isLoading: false
            });
          });
        });
    },
    actionIconClick(iconInfo) {
      if (iconInfo.internalFnTrigger) {
        iconInfo.internalFnTrigger();
      }
    },
    onReUploadOptionSelect(selectedOption) {
      if (!selectedOption.key) {
        return;
      }
      this.upload.currentType = selectedOption.key;
      this.journeyStage = 'bulk_upload';
      this.uploadStatusData = 'upload';
    },
    readExistingTaxonomy() {
      const action =
        'fetchEntityRead' + (this.entity === Entities.campaign ? 'V2' : '');
      this.$store
        .dispatch(action, {
          entity: this.entityData.entity,
          searchText: this.searchText,
          searchKey: this.dataProps?.readOnlyColumns?.[this.retailer]?.[0]?.key,
          requestBody:
            this.entity === Entities.campaign
              ? this.computedProps.getEntityReadAPIRequestBody(this.retailer)
              : {}
        })
        .catch((err) => {
          console.error(err);
          this.$snackbar.open({
            message: 'Something Went Wrong with upload. Please try again later',
            duration: 5000,
            actionText: ''
          });
        });
      if (
        this.enableLatestTaxonomy &&
        this.entity === Entities.campaign &&
        this.bTaxonomyExists
      ) {
        const selectedFilters =
          this.$store.getters['budgetPlannerOmni/getSelectedLevels'];
        if (Object.values(selectedFilters || {})?.length) {
          // to remove duplicate filters
          this.budgetPlanFilters = Array.from(
            new Set([
              ...this.budgetPlanFilters,
              ...Object.values(selectedFilters || {}).filter((filter) =>
                filter?.dimensionName?.startsWith?.('dimension')
              )
            ])
          );
        }
      }
    },
    getDynamicColumnDefs(columnList, budgetPlanFilters = []) {
      const columnMeta = this.columnMetaMapMT;
      const entity = this.entity;
      let columnDefs = columnList.map((column, index) => {
        let columnMetaObject = {};
        const columnDefinition = {
          name: column.dimensionColumn,
          uiField: {
            uiLabel: column.dimensionLabel,
            uiOrder: index + 1,
            uiTooltip:
              this.entity === 'catalog'
                ? "ASIN list as per AVC's Manufacturing catalog."
                : '',
            uiType: 'custom',
            metadata: {
              tableColumnName: column.dimensionColumn,
              showOnUi: true,
              isFixed: false
            }
          }
        };
        if (columnMeta[column.dimensionColumn]) {
          columnMetaObject = columnMeta[column.dimensionColumn](column);
        } else {
          columnMetaObject = columnMeta.default(column);

          if (
            this.enableLatestTaxonomy &&
            entity === Entities.campaign &&
            budgetPlanFilters?.length
          ) {
            const isFilter = budgetPlanFilters.findIndex(
              (filter) => filter.dimensionLabel === column.dimensionLabel
            );
            if (isFilter > -1) {
              columnMetaObject.headerComponent.props = {
                ...columnMetaObject.headerComponent.props,
                customComponent: true,
                deleteProps: { disabled: true },
                renameProps: { disabled: true },
                leftHeaderIcon: {
                  show: true,
                  icon: 'budget_optimizer',
                  classes: 'u-color-blue-base u-spacing-mt-xxs',
                  tooltipTitle:
                    'An active budget plan is running for this level. Any changes made to this column will affect the ongoing budget plan.'
                }
              };
            }
          }
        }
        columnDefinition.uiField.metadata = {
          ...columnDefinition.uiField.metadata,
          ...columnMetaObject
        };
        return columnDefinition;
      });
      columnDefs = transformer.getColumnDefinition(columnDefs);
      return columnDefs.displayColConfigs;
    },
    launchTriggered() {
      this.journeyStage = 'bulk_upload';
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'ingestion_init',
        entityType: this.entityData.entity,
        type: 'fresh'
      });
    },
    createDimensionValueCountList({ data, dimensions }) {
      return Object.entries(
        // data.jsonSheet is parsed uploaded excel data using which
        // we create the expected upload response, required for preview step
        data.jsonSheet.reduce((acc, val) => {
          dimensions.forEach((dimension) => {
            if (dimension.toLowerCase() !== this.primaryKey) {
              if (!acc[dimension]) {
                acc[dimension] = {
                  uniqueValueCount: new Set(),
                  noSkuCount: 0
                };
              }
              if (val[dimension]) {
                const set = acc[dimension].uniqueValueCount;
                acc[dimension].uniqueValueCount = set.add(val[dimension]);
              } else {
                acc[dimension].noSkuCount += 1;
              }
            }
          });
          return acc;
        }, {})
      ).map(([key, value], index) => ({
        dimensionLabel: key,
        dimensionColumn: `dimension${index + 1}`,
        noSkuCount: value.noSkuCount,
        uniqueValueCount: value.uniqueValueCount.size
      }));
    },
    // transformer to mock taxonomy validate API response for preview mode i.e. skipPostTrigger = true
    ingestionTransformer(data) {
      const readOnlyColumKeys = this.dataProps?.readOnlyColumns?.[
        this.retailer
      ]?.map((val) => val.value);
      const dimensions = Object.keys(data.jsonSheet[0]).filter(
        (val) => !readOnlyColumKeys.includes(val)
      );
      data.postApiResponse = {};
      data.postApiResponse.dimensionValueCountList =
        this.createDimensionValueCountList({ data, dimensions });
      data.postApiResponse.totalDimensionCount = data.jsonSheet.length;
      data.postApiResponse.totalCount =
        data.postApiResponse.dimensionValueCountList.length;
    },
    /**
     * @param {object} droppedFile - uploaded file object
     * @param {boolean} skipPostTrigger - to check if it's create/validate flow
     * @param {object} isPreview - to trigger create/validate flow methods
     * @return {Promise}
     */
    onUpload(droppedFile, isPreview = true) {
      const entityData = cloneDeep(this.entityData);
      entityData.file = droppedFile;
      entityData.skipPostTrigger = isPreview;
      this.entityData = entityData;
      this.uploadStatusData = 'loading';
      entityData.replaceAll = true;
      let timeTakenToReachStep =
        window.performance.now() - this.timeKeeper.loadStart;
      this.$logger.logEvent({
        eventType: 'ingestion_upload_init',
        entityType: this.entityData.entity,
        sessionIdentifier: this.sessionIdentifier,
        timeTakenToReachStep
      });
      if (
        this.enableLatestTaxonomy &&
        this.entity === Entities.campaign &&
        this.budgetPlanFilters?.length
      ) {
        entityData.fixedColumns = this.budgetPlanFilters.reduce((acc, curr) => {
          acc[curr.dimensionName] = curr.dimensionLabel;
          return acc;
        }, {});
      }
      return this.$store
        .dispatch('triggerIngestion', entityData)
        .then((response) => {
          this.validationResponse = response;
          if (this.entityData.file) {
            if (response.success) {
              if (isPreview) {
                // function to mock validate API response on skipPostTrigger false
                this.ingestionTransformer(response.data);
                this.catalogSelect.rows =
                  response.data.postApiResponse.dimensionValueCountList;
                this.catalogSelect.skusCount =
                  response.data.postApiResponse.totalDimensionCount;
                this.catalogSelect.catalogCount =
                  response.data.postApiResponse.totalCount;
                this.catalogSelect.postApiResponse =
                  response.data.postApiResponse;
                this.uploadStatusData = 'success';
                // show success snackbar with droppedFile name
                timeTakenToReachStep =
                  window.performance.now() - this.timeKeeper.loadStart;
                const msg = `<div class="u-display-flex u-flex-align-items-center"><div class="rb-icon icon-check-fill-circle rb-icon--small u-spacing-mr-xs"></div><span class="u-font-weight-bold">${droppedFile.name}</span><div>, uploaded successfully!</div></div>`;
                this.$snackbar.open({
                  message: msg,
                  duration: 5000,
                  actionText: '',
                  customStyles: 'background-color: #3ea95e !important'
                });
              } else {
                return response;
              }
              this.$logger.logEvent({
                eventType: 'ingestion_validation_success',
                entityType: this.entityData.entity,
                sessionIdentifier: this.sessionIdentifier,
                timeTakenToReachStep
              });
            } else {
              this.uploadStatusData = 'error';
              timeTakenToReachStep =
                window.performance.now() - this.timeKeeper.loadStart;
              this.$logger.logEvent({
                eventType: 'ingestion_upload_error',
                entityType: this.entityData.entity,
                sessionIdentifier: this.sessionIdentifier,
                errorList: this.errorList,
                timeTakenToReachStep
              });
              this.errorList.forEach((errorItem) => {
                this.$logger.logEvent({
                  eventType: 'ingestion_upload_error_item',
                  entityType: this.entityData.entity,
                  sessionIdentifier: this.sessionIdentifier,
                  errorDescription: errorItem.errorDescription,
                  errorEntity: errorItem.errorEntity
                });
              });
              return false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.uploadStatusData = 'error';
          this.$snackbar.open({
            message: 'Something Went Wrong. Please try again!',
            duration: 5000,
            actionText: ''
          });
        });
    },
    onFileRemove(event) {
      this.uploadStatusData = 'upload';
      this.entityData = { ...this.dataProps.entityData };
    },
    downloadTemplate(event, downloadNewFile = false) {
      if (this.entity === Entities.campaign) {
        this.$store.dispatch('downloadExcel', {
          entity: this.entity,
          downloadNewFile,
          ...this.computedProps.getEntityReadAPIRequestBody(this.retailer)
        });
      } else {
        const url = this.$store.getters.getEntityTemplateFile(
          this.entityData.entity
        );
        downloadLinkAsFile(url);
      }
    },
    onNext() {
      this.journeyStage = 'taxonomy-select';
    },
    onBack() {
      const prevPageIndex = this.pageJourney.indexOf(this.journeyStage) - 1;
      if (prevPageIndex >= 0) {
        this.journeyStage = this.pageJourney[prevPageIndex];
      } else {
        window.history.back();
      }
    },
    catalogViewClose() {
      this.readExistingTaxonomy();
      if (this.bTaxonomyExists) {
        this.journeyStage = 'preview';
      } else {
        this.journeyStage = 'welcome';
      }
    },
    async onSelectionComplete(selectedRows) {
      try {
        const response = await this.onUpload(this.entityData.file, false);
        if (response?.data) {
          selectedRows = this.enocdeApstrophe(
            // post validate and merge filtering actual response data with our mocked(for preview step) data
            response.data.postApiResponse.dimensionValueCountList.filter(
              (row) =>
                selectedRows.find(
                  (val) => val.dimensionLabel === row.dimensionLabel
                )
            )
          );
          const postApiResponse = response.data.postApiResponse;
          postApiResponse.dimensionValueCountList = selectedRows;
          const { entity } = this.entityData;
          // required by merge API
          postApiResponse.entityType = entity;
          postApiResponse.retailer = this.retailer;

          const mergeData = {
            entity,
            mergePayload: postApiResponse
          };

          // call merge API
          await this.mergeSelectedEntity(mergeData);

          this.postUpload(entity);
        } else {
          throw Error('Error while creating taxonomy');
        }
      } catch (error) {
        this.$snackbar.open({
          message:
            error?.message ||
            'Something Went Wrong with upload. Please try again later',
          duration: 5000,
          actionText: ''
        });
      }
    },
    async postUpload(entity) {
      // trigger status API for updated data
      await this.$store.dispatch('fetchEntityExistsStatus', this.entityData);

      this.journeyStage = 'ingestion-success';

      this.$store
        .dispatch(
          'fetchPostProcessingStatus',
          EntitiesForPostProcessingStatus[entity]
        )
        .then(() => {
          utils.checkAndClearLSFilterForEntity(
            EntitiesForPostProcessingStatus[entity]
          );
        });

      this.readExistingTaxonomy();
      const timeToIngest = window.performance.now() - this.timeKeeper.loadStart;
      this.$logger.logEvent({
        eventType: 'ingestion_success',
        entityType: this.entityData.entity,
        sessionIdentifier: this.sessionIdentifier,
        timeToIngest
      });
      setTimeout(() => {
        this.journeyStage = 'preview';
      }, this.CONSTANTS.SUCCESS_BANNER_TIME);
    },
    mergeSelectedEntity(mergeData) {
      return this.$store
        .dispatch('mergeSelectedEntity', mergeData)
        .catch(() => {
          this.$store
            .dispatch('fetchEntityExistsStatus', this.entityData)
            .then(() => {
              // temp to be removed
              this.journeyStage = 'preview';
            });
          this.$snackbar.open({
            message: 'Something Went Wrong with upload. Please try again later',
            duration: 5000,
            actionText: ''
          });
        });
    },
    editModeChange(mode) {
      this.editableCells = {};
      this.manageTaxonomyOptions.tableEditMode = mode;
    },
    onTriggerColumnCreate() {
      this.enableColumnCreate = true;
      // Scroll back to the first column for this new input column to be visible
      this.manageTaxonomyOptions.gridApi.api.ensureColumnVisible(
        this.manageTaxonomyOptions.gridApi.columnApi.getAllDisplayedColumns()[1]
      );
    },
    enableReUploadFlow() {
      this.journeyStage = 'select-upload-type';
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'ingestion_init',
        entityType: this.entityData.entity,
        type: 'overwrite'
      });
    },
    disableNewColumnCreate() {
      this.enableColumnCreate = false;
    },
    onHeaderAddIconClick({ columnDefn }) {
      this.catalogAsinPage.selectedCatalogDimensionLabel =
        columnDefn.colDef.headerComponentParams.props.displayName;
      this.catalogAsinPage.selectedCatalogDimension =
        columnDefn.colDef.headerComponentParams.props.fieldName;
      this.$router.push({
        path: this.path,
        query: {
          dimension: this.catalogAsinPage.selectedCatalogDimension,
          label: this.catalogAsinPage.selectedCatalogDimensionLabel
        }
      });
    },
    onColumnDeleteTrigger(data) {
      utils.showConfirmationDialog(
        this.$refs.confirmDialog,
        `Are you sure about deleting '${data?.columnDefn?.colDef?.headerName}' column`,
        'This column will no longer be available to use across CommerceIQ.',
        () => {
          const { columnDefn } = data;
          const deletePayload = {
            entity: this.entityData.entity,
            dimensionColumnDelete: columnDefn.colDef.field
          };
          this.$store
            .dispatch('deleteEntityColumn', {
              deletePayload,
              entity: deletePayload.entity
            })
            .then(() => {
              this.readExistingTaxonomy();
              this.$logger.logEvent({
                eventType: 'ui_edit',
                entityType: this.entityData.entity,
                type: 'column',
                action: 'DELETE'
              });
            });
        },
        () => {
          console.log('user action cancelled');
        },
        {
          confirmText: 'Delete',
          alertType: 'error'
        }
      );
    },
    onColumnRename(renameEvent) {
      const { columnDefn, newTitle } = renameEvent;

      const renamePayload = {
        entity: this.entityData.entity,
        // incase of newColumn we should trigger a create column.
        columnId:
          columnDefn.colDef.field === 'newColumn'
            ? ''
            : columnDefn.colDef.field,
        newColumnName: newTitle.replace("'", '%27')
      };

      this.$store
        .dispatch('AddOrRenameEntityColumn', {
          renamePayload,
          entity: renamePayload.entity
        })
        .then(() => {
          this.disableNewColumnCreate();
          this.readExistingTaxonomy();
          this.$logger.logEvent({
            eventType: 'ui_edit',
            entityType: this.entityData.entity,
            type: 'column',
            action: columnDefn.colDef.field === 'newColumn' ? 'ADD' : 'RENAME'
          });
        })
        .catch((err) => {
          console.error(err);
          this.$snackbar.open({
            message: 'Something Went Wrong!',
            duration: 5000,
            actionText: '',
            customStyles: 'background-color: #d7263d !important'
          });
        });
    },
    catalogTagChange(newTagsInfo) {
      const { metadata, currentTags, force, recordId } = newTagsInfo;
      if (!this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin]) {
        // this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin] => {};
        Vue.set(
          this.manageTaxonomyOptions.catalogCrudOperations,
          metadata.asin,
          {}
        );
      }
      if (
        !this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin][
          recordId
        ]
      ) {
        // this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin][recordId] => {}
        Vue.set(
          this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin],
          recordId,
          {}
        );
      }
      const pendingTags = currentTags.map((tagInfo) => {
        return tagInfo.title;
      });
      Vue.set(
        this.manageTaxonomyOptions.catalogCrudOperations[metadata.asin][
          recordId
        ],
        metadata.column,
        pendingTags
      );
      if (force) {
        this.triggerCatalogDataSave();
      }
    },
    async triggerCatalogDataSave() {
      try {
        this.editableCells = {};
        const dataUpdatePayload = {
          entity: this.entityData.entity,
          entityDataUpdates: []
        };
        const crudPayload = this.manageTaxonomyOptions.catalogCrudOperations;
        Object.keys(crudPayload).forEach((asin) => {
          const asinRecordIdValues = crudPayload[asin];
          Object.keys(asinRecordIdValues).forEach((recordId) => {
            const asinRecordIdDimensionsValue = asinRecordIdValues[recordId];
            Object.keys(asinRecordIdDimensionsValue).forEach((dimensionId) => {
              // catalog values should always be in lowercase
              const dimensionValue = (
                asinRecordIdDimensionsValue[dimensionId][0] || ''
              )
                .toLowerCase()
                .replace("'", '%27');
              dataUpdatePayload.entityDataUpdates.push({
                entityId: recordId,
                values: {
                  dimensionLabel: dimensionValue,
                  dimensionColumn: dimensionId
                }
              });
            });
          });
        });
        if (dataUpdatePayload.entityDataUpdates.length === 0) {
          this.editModeChange('view');
          return;
        }
        const status = await this.$store.dispatch('saveEntityDataUpdates', {
          dataUpdatePayload,
          entity: dataUpdatePayload.entity
        });
        if (!status.success) {
          this.$snackbar.open({
            message: 'Something Went Wrong with Update. Please try again later',
            duration: 5000,
            actionText: ''
          });
        }
        if (status.success) {
          this.$logger.logEvent({
            eventType: 'ui_edit',
            entityType: this.entityData.entity,
            type: 'tags',
            action: 'UPDATE'
          });
        }
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong with Update. Please try again later',
          duration: 5000,
          actionText: ''
        });
      } finally {
        this.clearPendingSaveItems();
        this.readExistingTaxonomy();
        this.editModeChange('view');
      }
    },
    onRenameCancel({ columnDefn }) {
      if (columnDefn.colDef.field === 'newColumn') {
        this.disableNewColumnCreate();
      }
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    },
    editOptionSelected(context, selection) {
      if (selection[0].internalFnTrigger) {
        this[selection[0].internalFnTrigger]();
      }
    },
    enableInlineTableEdit() {
      this.editModeChange('edit');
    },
    setCellEditable(asin, column, recordId) {
      if (!this.editableCells[asin]) {
        Vue.set(this.editableCells, asin, {});
      }
      if (!this.editableCells[asin][column]) {
        Vue.set(this.editableCells[asin], column, {});
      }
      if (!this.editableCells[asin][column][recordId]) {
        Vue.set(this.editableCells[asin][column], recordId, {});
      }
      Vue.set(this.editableCells[asin][column], recordId, true);
    },
    onTagsSelect(selectedOption) {
      if (selectedOption.tagPayload.tagItem.type === 'newTagTrigger') {
        this.setCellEditable(
          selectedOption.metadata.asin,
          selectedOption.metadata.column,
          selectedOption.recordId
        );
        return;
      }
      // this.journeyStage => 'manage-catalog-asin';
      this.catalogAsinPage.selectedCatalogValue =
        selectedOption.tagPayload.tagItem.title;
      this.catalogAsinPage.selectedCatalogDimensionLabel =
        selectedOption.metadata.columnLabel;
      this.catalogAsinPage.selectedCatalogDimension =
        selectedOption.metadata.column;
      this.$router.push({
        path: this.path,
        query: {
          tag: encodeURIComponent(this.catalogAsinPage.selectedCatalogValue),
          dimension: encodeURIComponent(
            this.catalogAsinPage.selectedCatalogDimension
          ),
          label: encodeURIComponent(
            this.catalogAsinPage.selectedCatalogDimensionLabel
          ),
          ...(this.esDataSetName
            ? { esDataSetName: encodeURIComponent(this.esDataSetName) }
            : {})
        }
      });
    },
    selectionChanged() {},
    onSearchTrigger(searchText) {
      this.searchText = searchText;
      this.$store.commit('SET_ENTITY_PAGE_AND_LIMIT', {
        entity: this.entity,
        page: 1,
        limit: this.catalogEntityStore.limit
      });
      // clear table row selections on search
      this.manageTaxonomyOptions?.gridApi?.api?.deselectAll();
      this.readExistingTaxonomy();
    },
    clearPendingSaveItems() {
      this.manageTaxonomyOptions.catalogCrudOperations = {};
    }
  }
};
</script>

<style lang="css">
.entity_ingestion_container .upload_file_drop_area {
  max-width: 680px;
}
.entity_ingestion_container .iq_tip_container {
  max-width: 450px;
}
.entity_ingestion_container .ingestion_bulk_edit_section {
  justify-content: space-between;
  padding-top: 2.4rem;
  margin-top: 1.6rem;
  margin-right: 3.2rem;
  margin-left: 3.2rem;
}
.entity_ingestion_container .ingestion_work_panel {
  max-width: 750px;
}
.entity_ingestion_container .tag_item_style_class {
  background-color: rgba(255, 168, 0, 0.1);
}
.entity_ingestion_container .tag_item_style_class:hover {
  background-color: #ffa800;
}
.select_taxonomy_section {
  height: 80%;
  width: 80%;
}
.entity_loader {
  z-index: 1002;
  opacity: 0.7;
}
</style>

<style lang="css" scoped>
.unmappedCampaignsBanner {
  padding-inline: 4.4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  background-color: rgba(0, 124, 246, 0.1);
  color: #4b5158;
  margin-top: 0;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.u-border-h-s.ag-cell-value.u-border-h-s {
  border-left: solid 1px !important;
  border-right: solid 1px !important;
  border-color: #007cf6 !important;
  opacity: 0.4;
  z-index: 200;
}

.edit-catalog-button .rb-icon::before {
  font-size: 12px;
}
.taxonomy-icon-button:hover {
  color: #333;
}
</style>
