<template>
  <div
    class="imap-container"
    :class="[status]"
  >
    <p class="imap">
      {{ IMAP_STATUS[status] }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ImapStatus',
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      IMAP_STATUS: {
        open: 'Open',
        fixed: 'Fixed',
        ticketRaised: 'Ticket Raised'
      }
    };
  }
};
</script>

<style scoped lang="css">
.imap-container {
  width: 60%;
  text-align: center;
  border-radius: 4px;
}
.imap {
  padding: 8px 16px;
  font-weight: 600;
}
.open {
  background: #cbf9d5;
  color: #3ea95e;
}
.fixed {
  background: #cbf9d5;
  color: #3ea95e;
}
.ticketRaised {
  background: #fef0c5;
  color: #ffa800;
}
</style>
