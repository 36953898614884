<template>
  <section class="u-display-inline-flex">
    <tippy
      to="infoTooltip"
      trigger="click"
      :distance="0"
      interactive="true"
      reactive="true"
      hide-on-click="true"
      :duration="0"
      placement="top-start"
    >
      <div
        class="u-display-flex u-flex-direction-column table-tooltip-container"
      >
        <div
          class="u-flex-1 u-display-flex u-flex-direction-column card u-border-width-s u-border-color-grey-xxx-light"
        >
          <!-- Right Table header -->
          <div
            v-if="showHeader"
            class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"
          >
            <div class="u-spacing-p-xs">
              {{ headerText }}
            </div>
          </div>

          <div
            class="u-position-relative u-display-flex u-flex-direction-column u-flex-1 u-width-100"
          >
            <rb-insights-table
              class="u-display-flex table-tooltip u-flex-direction-column"
              :grid-options="gridOptions"
              :config="tableConfig"
              :auto-configure="autoConfigureOption"
              :row-height="48"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :client-side-pagination="true"
              :pagination-total-key="autoConfigureOption.rows.length"
              :get-current-instance="getCurrentSourceInstance"
              :pagination-per-page-limit="paginationPerPageLimit"
              :download-this-table="false"
              :show-pagination-options="false"
              :row-no-hover-state="true"
              :suppress-row-transform="true"
              :row-class-rules="rowClassRules"
              :row-selection="rowSelection"
              :pagination-change-event="'onPaginationChange'"
            />
            <footer
              class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
            >
              <rb-button
                :disabled="isApplyDisabled"
                :click-fn="onApply"
                :text="'Apply'"
                :size="'s'"
                :type="'filled'"
                class="u-spacing-mr-s"
              />
            </footer>
          </div>
        </div>
      </div>
    </tippy>
    <div
      name="infoTooltip"
      class="u-display-inline-flex"
    >
      <slot
        name="trigger-content"
        class="u-cursor-pointer"
      />
    </div>
  </section>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
export default {
  name: 'TableTooltip',
  props: {
    columnDefinition: {
      type: Array,
      default: () => []
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paginationPerPageLimit: {
      type: Number,
      default: 10
    },
    headerText: {
      type: String,
      default: 'Header'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    adGroupIdKey: {
      type: String,
      default: 'adgroup_id'
    },
    adGroupNameKey: {
      type: String,
      default: 'adgroup_name'
    }
  },
  data() {
    return {
      rbTableSourceInstance: null,
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      selectionsMap: {},
      currentPage: 1,
      fromChangingTheSelection: false
    };
  },
  computed: {
    autoConfigureOption() {
      return {
        rows: this.dataRows,
        columns: this.columnDefinition
      };
    },
    rowClassRules() {
      return {
        ' hideBorderAddKeywordsToCampaigns': function (params) {
          return params.data.campaignName === ' ';
        }
      };
    },
    rowSelection() {
      return {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChange
      };
    },
    isApplyDisabled() {
      let isApplyDisabled = true;
      Object.values(this.selectionsMap).every((arr) => {
        if (arr.length > 0) {
          isApplyDisabled = false;
          return false;
        }
      });

      return isApplyDisabled;
    }
  },
  mounted() {
    setTimeout(() => {
      this.gridOptions.api.selectAll();
      this.$emit('onSelectionChange', this.dataRows);
    }, 0);
  },
  created() {
    const numberOfPages = Math.ceil(this.dataRows.length / 10);
    new Array(numberOfPages).fill(0).forEach((__, index) => {
      this.selectionsMap[index + 1] = this.dataRows.slice(
        (index + 1) * 10 - 10,
        (index + 1) * 10
      );
    });
    eventBus.$on('onPaginationChange', (page) => {
      setTimeout(() => {
        this.currentPage = page;
        const adGroupsIds = {};
        this.selectionsMap[page]?.forEach((item) => {
          const ad_group_id = item?.[this.adGroupIdKey];
          const ad_group_name = item?.[this.adGroupNameKey];
          adGroupsIds[ad_group_id] = ad_group_name;
        });
        this.fromChangingTheSelection = true;
        this.rbTableSourceInstance.gridOptions.api?.forEachNode((node) => {
          const adGroupId = node?.data?.[this.adGroupIdKey];
          if (adGroupsIds[adGroupId]) {
            node.gridApi.selectNode(node, true);
          }
        });
      }, 0);
      setTimeout(() => {
        this.fromChangingTheSelection = false;
      }, 100);
    });
  },
  methods: {
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    onSelectionChange(newValue) {
      if (!this.fromChangingTheSelection) {
        const adGroups = newValue.map(({ data }) => data);
        this.selectionsMap[this.currentPage] = adGroups;
      }
    },
    onApply() {
      const selections = Object.values(this.selectionsMap).reduce(
        (acc, array) => {
          acc.push(...array);
          return acc;
        },
        []
      );
      this.$emit('onSelectionChange', selections);
      // workaround to close the tippy component, will find a better solution
      document.elementFromPoint(document?.body?.offsetWidth - 1, 0).click();
    }
  }
};
</script>

<style lang="css">
.table-tooltip-container {
  width: 924px;
  height: 625px;
}
.table-tooltip-container .table-tooltip .checkbox-only .ag-selection-checkbox {
  margin-left: 8px;
}
.table-tooltip-container
  .table-tooltip
  .checkbox-only
  .ag-selection-checkbox
  span {
  margin-right: 0 !important;
}
.table-tooltip-container .table-tooltip .hideBorderAddKeywordsToCampaigns {
  border-top: none !important;
}
.table-tooltip-container .table-tooltip .ag-header-select-all {
  display: none;
}
.table-tooltip-container .table-tooltip .row-class-selected {
  background: 'blue';
}
</style>
