var browserDetailsJson = {
  BrowserList: {
    Windows: {
      Chrome: {
        Name: 'chrome',
        DownloadPageUrl: 'https://www.google.com/chrome/browser/desktop',
        DownloadUrl: false,
        ImageUrl: '../images/chrome.png'
      },
      Firefox: {
        Name: 'firefox',
        DownloadPageUrl: 'https://www.mozilla.org/en-US/firefox/new',
        DownloadUrl: 'https://download.mozilla.org/?product=firefox-40.0-SSL&os=win',
        ImageUrl: '../images/firefox.png'
      },
      Edge: {
        Name: 'edge',
        DownloadPageUrl: false,
        DownloadUrl: false,
        ImageUrl: '../images/edge.png'
      },
      Safari: {
        Name: 'safari',
        DownloadPageUrl: false,
        DownloadUrl: false,
        ImageUrl: '../images/safari.png'
      },
      InternetExplorer: {
        Name: 'internet explorer',
        DownloadPageUrl: 'http://windows.microsoft.com/en-us/internet-explorer/download-ie',
        DownloadUrl: false,
        ImageUrl: '../images/internetexplorer.png'
      },
      Opera: {
        Name: 'opera',
        DownloadPageUrl: 'http://www.opera.com',
        DownloadUrl: 'http://www.opera.com/en-US/computer/windows',
        ImageUrl: '../images/opera.png'
      }
    },
    Osx: {
      Chrome: {
        Name: 'chrome',
        DownloadPageUrl: 'https://www.google.com/chrome/browser/desktop',
        DownloadUrl: false,
        ImageUrl: '../images/chrome.png'
      },
      Firefox: {
        Name: 'firefox',
        DownloadPageUrl: 'https://www.mozilla.org/en-US/firefox/new',
        DownloadUrl: 'https://download.mozilla.org/?product=firefox-40.0-SSL&os=osx',
        ImageUrl: '../images/firefox.png'
      },
      Edge: null,
      Safari: {
        Name: 'safari',
        DownloadPageUrl: 'https://support.apple.com/en-us/HT204416',
        DownloadUrl: 'https://support.apple.com/en_US/downloads/safari',
        ImageUrl: '../images/safari.png'
      },
      InternetExplorer: null,
      Opera: {
        Name: 'opera',
        DownloadPageUrl: 'http://www.opera.com',
        DownloadUrl: false,
        ImageUrl: '../images/opera.png'
      }
    },
    Linux: {
      Chrome: {
        Name: 'chrome',
        DownloadPageUrl: 'https://www.google.com/chrome/browser/desktop',
        DownloadUrl: false,
        ImageUrl: '../images/chrome.png'
      },
      Firefox: {
        Name: 'firefox',
        DownloadPageUrl: 'https://www.mozilla.org/en-US/firefox/new',
        DownloadUrl: 'https://download.mozilla.org/?product=firefox-40.0-SSL&os=linux',
        ImageUrl: '../images/firefox.png'
      },
      Edge: null,
      Safari: null,
      InternetExplorer: null,
      Opera: {
        Name: 'opera',
        DownloadPageUrl: 'http://www.opera.com',
        DownloadUrl: 'http://www.opera.com/en-US/computer/linux',
        ImageUrl: '../images/opera.png'
      }
    }
  }
};

var browserThresholdConfig = {
  chrome: {
    version: 49
  },
  firefox: {
    version: 51
  },
  safari: {
    version: 12
  },
  edge: {
    version: 16
  }
};

var browserList = {
  Windows: {
    Chrome: {
      Name: 'chrome',
      LatestVersion: 75,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Firefox: {
      Name: 'firefox',
      LatestVersion: 68,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Edge: {
      Name: 'edge',
      LatestVersion: 18,
      ReleaseMonth: 4,
      ReleaseYear: 2018
    },
    Safari: {
      Name: 'safari',
      LatestVersion: 5,
      ReleaseMonth: 5,
      ReleaseYear: 2012
    },
    InternetExplorer: {
      Name: 'internet explorer',
      LatestVersion: 11,
      ReleaseMonth: 10,
      ReleaseYear: 2016
    },
    Opera: {
      Name: 'opera',
      LatestVersion: 60,
      ReleaseMonth: 9,
      ReleaseYear: 2018
    }
  },
  Osx: {
    Chrome: {
      Name: 'chrome',
      LatestVersion: 75,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Firefox: {
      Name: 'firefox',
      LatestVersion: 68,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Edge: null,
    Safari: {
      Name: 'safari',
      LatestVersion: 12,
      ReleaseMonth: 9,
      ReleaseYear: 2018
    },
    InternetExplorer: null,
    Opera: {
      Name: 'opera',
      LatestVersion: 60,
      ReleaseMonth: 9,
      ReleaseYear: 2018
    }
  },
  Linux: {
    Chrome: {
      Name: 'chrome',
      LatestVersion: 75,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Firefox: {
      Name: 'firefox',
      LatestVersion: 68,
      ReleaseMonth: 10,
      ReleaseYear: 2018
    },
    Edge: null,
    Safari: null,
    InternetExplorer: null,
    Opera: {
      Name: 'opera',
      LatestVersion: 60,
      ReleaseMonth: 9,
      ReleaseYear: 2018
    }
  }
};

export default {
  browserList: browserList,
  browserDetailsJson: browserDetailsJson,
  browserThresholdConfig: browserThresholdConfig
};
