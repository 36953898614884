<template>
  <div
    class="u-display-flex campaign-management-styling u-flex-direction-column u-font-size-1 ams-skus"
  >
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="showSKUsActivityLog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'SKU'"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div
      class="filter-panel-with-banner u-display-flex u-bg-color-grey-white u-spacing-mb-m u-bg-color-grey-white sticky u-header-box-shadow"
      :class="{ 'filter-panel-with-banner-padding': isInfoBannerVisible }"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="wk-m-skus-filters"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'cm-skus-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="true"
        :show-data-missing-msg="showDataMissingMsg"
        :data-missing-date-range="'August 11th, 2023, to October 9th, 2023,'"
        :ingestion-tooltip-text="ingestionToolTipText"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :new-date="true"
        :allow-date-compare="true"
        :default-date-post-range="'last30Days'"
        :max-date="getAdSkusPageWiseMinMaxKey"
        :enable-save="true"
        :on-create="onCreate"
        :page="'ams_asin'"
        :loader-status="loaderStatus"
        :common-date-key="'common-date-cm'"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :additional-ranges="additionalRanges"
        @savedFilterApplied="filterKey++"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Ad SKUs'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            :bulk-search-ref="selectedBulkSearchType"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          >
            <rbRadioGroup
              slot="custom-bulk-type"
              class="skus-radio-group"
              :radio-options="bulkSearchOptions"
              :value="selectedBulkSearchType"
              group-label="Search for:"
              @onRadioChanged="handleRadioSelection"
            />
          </BulkSearch>
        </span>
      </rb-filter-panel>
    </div>
    <div
      v-if="isInfoBannerVisible"
      class="u-display-flex u-margin-bottom-10 u-padding-10 u-flex-justify-content-space-between u-font-size-6 info-banner"
    >
      <div
        class="u-display-flex u-flex-direction-column u-flex-justify-content-center"
      >
        <div class="u-spacing-ml-s u-display-flex u-spacing-mr-s">
          <rb-icon
            class="rb-icon--medium u-color-orange-base u-spacing-mr-s"
            :icon="'info-circle-fill'"
          />
          SKU-level actions are not supported for Sponsored Brand campaigns.
          Please select SKUs from other campaign types.
        </div>
        <div
          class="u-spacing-ml-s u-display-flex u-spacing-mt-s u-spacing-mr-s"
        >
          <rb-icon
            class="rb-icon--medium u-color-orange-base u-spacing-mr-s"
            :icon="'info-circle-fill'"
          />
          Since 19th August 2024, we are facing an issue in fetching SKU-level
          data for Sponsored Brand Campaigns and therefore metrics in this
          section will not match with campaigns tab. We are actively working on
          a fix and will resolve this issue as soon as possible. Thank you for
          your patience.
        </div>
      </div>
      <div
        class="u-spacing-mr-s"
        @click="onInfoBannerCrossClick"
      >
        <rb-icon
          class="u-display-flex u-flex-justify-content-center u-cursor-pointer rb-icon--small u-color-grey-mid-light"
          :icon="'cross'"
        />
      </div>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      class="u-spacing-mt-xxs"
      :dates="filterInstance.selectedDates"
    />
    <card-widget
      class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
      :config="config.widgets['widget1']"
      :data="getWorkbenchMSKUsData[config.widgets['widget1'].meta.key] || {}"
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    class="metric-card-hover"
                    data-cy="metricCardHover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        data-cy="metricList"
                        :class-name="'campaigns-select'"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span class="u-font-size-5">{{ val }}</span>
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getWorkbenchMSKUsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="This metric is enabled only when 1 row is plotted"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <div
                        class="u-display-flex u-flex-align-items-center u-spacing-mt-s"
                      >
                        <metric
                          v-if="
                            val &&
                            ((
                              getWorkbenchMSKUsData[
                                config.widgets['widget1'].meta.key
                              ] || {}
                            ).metrics || {})[val] &&
                            val !== 'Promotions'
                          "
                          :size="'l'"
                          class="u-display-inline-flex"
                          :config="
                            ((
                              getWorkbenchMSKUsData[
                                config.widgets['widget1'].meta.key
                              ] || {}
                            ).metricsUnits || {})[val]
                          "
                          :data="
                            ((
                              getWorkbenchMSKUsData[
                                config.widgets['widget1'].meta.key
                              ] || {}
                            ).metrics || {})[val]
                          "
                        />
                        <custom-tool-tip
                          v-if="showMetricDiscrepancyBanner(val)"
                          :tippy="{
                            html: '#discrepancyTooltip',
                            interactive: false,
                            offset: '0,0'
                          }"
                        >
                          <div slot="toolTipData">
                            <div
                              class="u-display-flex u-flex-direction-column u-width-400px u-spacing-p-s"
                            >
                              <div class="u-display-flex">
                                <rb-icon
                                  :icon="'compare-arrows'"
                                  class="rb-icon--medium u-color-blue-base"
                                />
                                <div
                                  class="u-font-size-5 u-font-weight-600 u-spacing-ml-s"
                                >
                                  Campaign vs Ad SKUs discrepancy
                                </div>
                              </div>

                              <div
                                class="u-font-size-6 u-spacing-mt-m u-font-weight-400"
                              >
                                {{ val }} from Sponsored Brand Campaigns is/are
                                distributed proportionally among purchased SKUs
                                based on SKU’s Ad sales.
                              </div>
                            </div>
                          </div>
                          <div
                            slot="body"
                            slot-scope="{ tippyConfig }"
                          >
                            <div
                              v-tippy="tippyConfig"
                              class="u-font-size-6 u-spacing-ml-s u-spacing-mr-xs"
                            >
                              <rb-icon
                                :icon="'compare-arrows'"
                                class="rb-icon--medium u-color-blue-base"
                              />
                            </div>
                          </div>
                        </custom-tool-tip>
                      </div>
                      <span
                        v-if="
                          !val &&
                          !((
                            getWorkbenchMSKUsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val] &&
                          val !== 'Promotions'
                        "
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                      <!-- eslint-disable -->
                      <span
                        v-if="val === 'Promotions'"
                        class="u-font-size-3 u-color-grey-light u-font-weight-bold u-spacing-mt-s"
                        v-html="getPromotionDays()"
                      />
                      <!-- eslint-enable -->
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <div class="u-display-flex">
              <section
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <span
                  v-if="numberOfPlottedSelections !== 0"
                  class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                  :class="{ isHighlighted: isHighlighted }"
                  style="min-height: 20px"
                >
                  <span class="u-spacing-pr-s u-line-height-1_2"
                    >Graphs plotted for
                    {{ numberOfPlottedSelections }} rows</span
                  >
                  <span @click="plotRows('delete')">
                    <rb-icon
                      :icon="'cross'"
                      class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                      :class="{
                        'isHighlighted u-color-grey-white': isHighlighted
                      }"
                    />
                  </span>
                </span>
              </section>
              <span>
                <icon-text-actions
                  v-if="isExpand"
                  class="u-cursor-pointer padding--right u-width-100"
                  mode="default"
                  :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                  @clicked="isExpand = !isExpand"
                />
                <icon-text-actions
                  v-if="!isExpand"
                  class="u-cursor-pointer u-width-100 padding--right"
                  mode="default"
                  :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                  @clicked="isExpand = !isExpand"
                />
              </span>
            </div>
          </div>
          <div class="u-display-flex set-roll-up-width">
            <div class="u-display-flex u-flex-align-items-center">
              <roll-up-by
                label="Roll up by:"
                :value="selectedRollUpValue"
                :options="computedRollUps"
                @onRollUpChange="
                  (selection) => getSkusChartDataWithRollUp(selection)
                "
              />
            </div>
          </div>
          <chart
            id="workbenchMSKUsChart"
            :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
            class="c3-large u-flex-1 u-spacing-mt-l"
            :config="config.widgets['widget1'].body.chart"
            :data="widget1Data"
          />
        </div>
      </div>
    </card-widget>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div
      v-if="!showActionsBar"
      class="u-display-flex u-spacing-p-m u-bg-color-grey-white u-flex-direction-row-reverse actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <div class="u-display-flex u-flex-align-items-baseline">
        <icon-text-actions
          mode="default"
          :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
          @clicked="openManageColumns"
        />
        <rb-download-button
          :download-email="true"
          :config="config.widgets['widget2']"
          :column-map="config.widgets['widget2'].body.download.columnMap"
          :on-email-click="openEmailPopup"
          :class-name="'enlargeEmailIcon'"
          :disabled="resultLength < 1"
        />
      </div>
    </div>

    <card-widget
      :apply-card-class="false"
      class="u-bg-color-grey-white u-flex-1 u-spacing-mb-m"
      :config="config.widgets['widget2']"
      :data="getWorkbenchMSKUsData[config.widgets['widget2'].meta.key] || {}"
    >
      <div slot="body">
        <div class="u-display-flex u-flex-wrap-yes">
          <rb-insights-table
            :dynamic-column-width="dynamicColumnWidth"
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (getWorkbenchMSKUsData[config.widgets['widget2'].meta.key] || {})
                .rows
            "
            :table-column="tableColumns"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :scroll-to-node-id="scrollToNodeId"
            :primary-key="'ad_id'"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :pagination="true"
            :pagination-total-key="
              (getWorkbenchMSKUsData[config.widgets['widget2'].meta.key] || {})
                .totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :table-header-sticky-obj="sticky"
            :show-pagination-options="true"
          />
        </div>
      </div>
      <realtimeFooter
        v-if="getRealtimeMetadata.realtime"
        slot="footer"
        class="no-wrap u-color-grey-x-light u-font-size-7"
        :last-updated="getRealtimeMetadata.lastUpdated"
        @onRefresh="triggerRefresh(false)"
      />
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="amsSkus-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import sbBanner from '@/components/basic/sb-banner';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import rbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import utilConfig, {
  showSbv4DiscrepancyBanner
} from '@/components/ams/campaigns/utils.js';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import {
  metricsShownForAmazonChart,
  defaultSkuCols
} from '@/components/ams/campaigns/constants.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';
import CustomToolTip from '@/components/widgets/customToolTip.vue';
import { isEmpty } from 'lodash';
import { refreshRealtimeData } from '../storeHelper';
import realtimeFooter from '@/components/ams/campaigns/components/realtimeFooter.vue';

export default {
  components: {
    CustomToolTip,
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    bidChangePanel,
    messageBar,
    activityLog,
    modal,
    sbBanner,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    rbRadioGroup,
    amsAddMetricTile,
    realtimeFooter,
    SystemTransparencyBanner,
    RollUpBy
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      dynamicColumnWidth: {
        enable: false
      },
      showInfoBanner: true,
      additionalRanges: commons.additionalDateRanges(),
      ingestionDependentEntities: [
        EntitiesForPostProcessingStatus.catalog,
        EntitiesForPostProcessingStatus.campaign
      ],
      ingestionToolTipText:
        'Internal catalog / custom list updation or creation is in progress. It will take upto 3 hours for the changes to reflect.',
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      resultLength: 0,
      isSDCampaign: false,
      showEmailPopUp: false,
      modalConfig: {},
      filterKey: 0,
      chartWkbenchAxes: {},
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      promoTimelineMessages: [],
      promotionDays: 0,
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getWorkbenchMSKUsSelectedFilters',
        setter: 'setWorkbenchMSKUsSelectedFilters'
      },
      downloadFileSuffix: 'campaign-management_ams-skus',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForAmazonChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      disableMetric: [
        'Promotions',
        'Change in Traffic %',
        'Change in Conversion'
      ],
      isExpand: false,
      selections: [],
      showActionsBar: false,
      showBlueBar: false,
      timer: 30000,
      showActionsLog: false,
      adId: null,
      openActivity: true,
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'edit-status': this.updateStatus
      },
      allowedActions: [],
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      isHighlighted: false,
      showPaginationOptions: utils.internalUserCheck(window.user),
      editStatusConfigIndex: -1,
      selectedBulkSearchType: 'amsSkus',
      bulkSearchOptions: commons.getBulkSearchOptions('skus'),
      enableManageColumns: true,
      manageColumnsPanelListener: 'skusManageColumnsPanel',
      workBenchDataKey: 'getWorkbenchMSKUsData',
      filterInstance: null,
      ...commons.commonDataProperties
    };
  },
  computed: {
    getRealtimeMetadata() {
      return this.$store.getters.getSkusRealTimeMetadata;
    },
    showDataMissingMsg() {
      return Vue.options.filters.config_check(
        'feature.pages.data_missing_banner_needed'
      ).enable;
    },
    isInfoBannerVisible() {
      return this.showInfoBanner;
    },
    selectedRollUpValue() {
      return this.selectedRollUp?.key || 'DAY';
    },
    computedRollUps() {
      return this.getComputedRollUpOptions(
        this.$store.getters.getAMSSKUsWhereClause,
        this.rollUps
      );
    },
    bulkSearchProps() {
      if (this.selectedBulkSearchType === 'amsSkusCampaigns') {
        const { bulkSearchProps } = utilConfig('amazon', 'campaign');
        return bulkSearchProps();
      }
      return this.config.bulkSearchProps();
    },
    emailStatus() {
      return this.$store.getters.getEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          this.getWorkbenchMSKUsData[this.config.widgets.widget1.meta.key]
            ?.load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    showSKUsActivityLog() {
      return this.$store.getters.getShowAmsSkusActivityLog;
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.AmsSkusAcitivityLog;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.ad_id) {
            return false;
          }
          return params.data.ad_id === this.$store.getters.getAmsSkusPrimaryKey;
        }.bind(this)
      };
    },
    getWorkbenchMSKUsData() {
      this.showActionsBar = false;
      this.resultLength = (
        this.$store.getters.getWorkbenchMSKUsData[
          this.config.widgets.widget2.meta.key
        ]?.rows || []
      ).length;
      return this.$store.getters.getWorkbenchMSKUsData;
    },
    widget1Data() {
      let data =
        this.getWorkbenchMSKUsData[this.config.widgets.widget1.meta.key]?.data;
      let clonedData = data?.data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let promoMessageIndex = this.getPromoMessageIndex({ clonedData });
      let ignoreList = [
        'report_date',
        'count',
        'ordered_units',
        'Promo Messages',
        'inventory_latest'
      ];
      this.metricsList = [];
      for (const element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }
      data = this.createSelectedMetricsList({
        data,
        clonedData,
        promoMessageIndex
      });
      return data;
    },
    getAdSkusPageWiseMinMaxKey() {
      return this.$store.getters.getAdSkusPageWiseMinMaxKey;
    }
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeDestroy() {
    eventBus.$off('workbenchMSKUsFilterApplied');
    eventBus.$off('workbenchMSKUsSearchTriggered');
    eventBus.$off('workbenchMSKUsPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('amsSkusBulkSearchApplied');
    eventBus.$off('amsSkusCampaignsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  beforeCreate() {
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchMSKUsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    const that = this;
    eventBus.$on('workbenchMSKUsFilterApplied', () => {
      that.plottedSelections = 0;
      that.isExpand = true;
      this.closeActionsWorkflow(false);
    });
    eventBus.$on('workbenchMSKUsSearchTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow(false);
    });
    eventBus.$on('workbenchMSKUsPaginationTriggered', () => {
      that.plottedSelections = 0;
      this.closeActionsWorkflow(false);
    });

    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('amsSkusBulkSearchApplied', () => {
      that.plottedSelections = 0;
    });
    eventBus.$on('amsSkusCampaignsBulkSearchApplied', () => {
      that.plottedSelections = 0;
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.amsSkus?.openBulkSearchPopup();
    });
  },
  created() {
    if (this.allowedActions.length === 0) {
      this.allowedActions = this.allActionConfig.getConfigs.call(this);
    }
    this.config = widgetsConfig.config(this);
    this.config.widgets.widget1.meta.plotSelections = [];
    this.fetchFilters(
      'ams_campaigns_asin_workbench',
      'FETCH_FILTERS_V2',
      'ams_asin'
    );
    if (this.config.widgets.widget1.body.chart) {
      this.config.widgets.widget1.body.chart.chartOptions.tooltip_format.Promotions =
        function (value, ratio, id, index) {
          return '-  ' + this.promoTimelineMessages[index] || '';
        }.bind(this);
    }
  },
  methods: {
    ...commonMethods,
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    onInfoBannerCrossClick() {
      this.showInfoBanner = false;
    },
    getSkusChartDataWithRollUp(selection) {
      this.getChartDataWithRollUp(
        selection,
        'MSKUs',
        this.$store,
        this.selectedRollUp,
        this.config
      );
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    getPromoMessageIndex({ clonedData }) {
      let promoMessageIndex = null;
      for (let m = 0; m < clonedData.length; m++) {
        if (clonedData[m][0]) {
          if (clonedData[m][0].toLowerCase() === 'promo messages') {
            promoMessageIndex = m;
            break;
          }
        }
      }
      return promoMessageIndex;
    },
    scrollToNodeId() {
      return null;
    },
    getInitialPayloadObject(data, actionType) {
      let payload = {
        adId: data.ad_id,
        profileId: data.profile_id,
        campaignId: data.campaign_id,
        adgroupId: data.adgroup_id,
        sku: data.asin
      };
      let obj = {
        actionPayload: { ...payload },
        viewPayload: { ...payload },
        actionType: actionType,
        actionSource: {
          pageUrl: window.location.href
        }
      };
      if (actionType === 'productAdStatusChange') {
        obj.actionPayload.newStatus = '';
        obj.actionPayload.previousStatus = data.status;
        obj.actionPayload.campaignType = data.campaign_type;
        obj.viewPayload.newStatus = '';
        obj.viewPayload.previousStatus = data.status;
        obj.viewPayload.campaignType = data.campaign_type;
      }
      return obj;
    },

    processPayloadForBidChange(params, payloadObjRef, rowData) {
      if (
        params.value.type === 'increaseBids' ||
        params.value.type === 'decreaseBids'
      ) {
        if (params.value.selectionType === 'percent') {
          payloadObjRef.actionPayload.newBid = commons.getBidPercentValue(
            params.value.type,
            rowData.bid,
            params.value.value
          );
        } else {
          payloadObjRef.actionPayload.newBid = params.value.value;
        }
      } else {
        payloadObjRef.currentBid = params.value.value;
        payloadObjRef.actionPayload.newBid = params.value.value;
      }
    },

    processPayloadForStatusChange(params, payloadObjRef, rowData) {
      payloadObjRef.actionPayload.newStatus = params;
      payloadObjRef.viewPayload.newStatus = params;
    },

    getPayload(params, actionType) {
      let paramsToReturn = [];
      for (const element of this.selections) {
        let data = element.data;
        let obj = this.getInitialPayloadObject(data, actionType);
        if (actionType === 'productAdStatusChange') {
          this.processPayloadForStatusChange(params, obj, data);
        } else if (actionType === 'addAsinCampaign') {
          this.processPayloadForBidChange(params, obj, data);
        }
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    hideBlueBar() {
      this.showBlueBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    updateStatus(selectedOption) {
      if (!selectedOption?.[0]) {
        return;
      }
      this.deselectSBCampaignsForStatusChange();
      if (isEmpty(this.selections)) {
        return;
      }
      let that = this;
      let selectedStatus = selectedOption[0].value.toLowerCase();
      let payload = this.getPayload(selectedStatus, 'productAdStatusChange');
      let othersObj = {
        successMessage:
          'Status change request has been submitted for the selected ASIN(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closeActionsWorkflow();
          that.onSelectionChanged([]);
          that.plotRows('delete');
          const mutations = {
            reset: 'IWKMSKUs_RESET',
            error: 'IWKMSKUs_ERROR',
            success: 'IWKMSKUs_SUCCESS',
            realtimeMetadata: 'SKUS_REALTIME_META_DATA'
          };

          refreshRealtimeData(
            this.$store,
            this.config.widgets.widget2,
            this.getWorkbenchMSKUsData[this.config.widgets.widget2.meta.key]
              .rows,
            mutations,
            this.$store.getters.getSkusRealTimeMetadata.intervalId
          );
        }
      };
      commons.bidChangeApiAction.call(this, selectedOption, payload, othersObj);
    },

    deselectSBCampaignsForStatusChange() {
      const validSelections = [];
      this.selections.forEach((rowNode) => {
        if (rowNode.data?.campaign_type === 'SB') {
          rowNode.setSelected(false);
        } else {
          validSelections.push(rowNode);
        }
      });
      if (validSelections.length !== this.selections.length) {
        this.$snackbar.open({
          message:
            'Sponsored Brands ASINs are not actionable, and have been deselected.',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        this.selections = validSelections;
      }
    },

    adjustBidAction(params) {
      if (params.actionType.toLowerCase() === 'apply') {
        this.callBidActionApi(params);
      } else {
        this.closeActionsWorkflow();
        this.adjustBidsPanel();
      }
    },

    closeActionsWorkflow(fetchChart = true) {
      commons.closeActionsWorkflow.call(this);
      if (fetchChart) {
        this.onSelectionChanged([]);
        this.plotRows('delete');
      }
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection
    ) {
      if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        this.actionIdFunctionMap[actionId]();
      }
    },

    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openAmsSkusActivityLog');
      this.$store.dispatch('getAmsSkusActivityLog', {
        primaryKey: that.params.node.data.ad_id
      });
      if (this.adId === that.params.node.data.ad_id) {
        return;
      }
      this.adId = that.params.node.data.ad_id;
      this.rbTableInstance.redrawTableRows();
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.adId = null;
      this.$store.dispatch('closeAmsSkusActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    plotRows(type) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForAmazonChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      eventBus.$emit('workbenchMSKUsPlotSelectedRows', this.plotRowsSelections);
      document.getElementById('wk-m-skus-filters').scrollIntoView();
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    getPanelConfigIndex() {
      for (let i = 0; i < this.allowedActions.length; i++) {
        if (this.allowedActions[i].id === 'edit-status') {
          this.editStatusConfigIndex = i;
        }
      }
    },
    onSelectionChanged(data) {
      this.plotRowsSelections = data.map(function (selection) {
        return selection.data;
      });
      if (this.editStatusConfigIndex === -1) {
        this.getPanelConfigIndex();
      }
      this.selections = data;
      this.showActionsBar = data.length > 0;
      commons.validateForArchivedActions(this.allowedActions, this.selections);
      this.isSDCampaign = commons.invalidateSDActions(
        data,
        this.allowedActions
      );
      this.onSDCampaignsSelection();
    },
    onSDCampaignsSelection() {
      const actionTippys = {
        // 'edit-status': unavailableMessage
      };
      this.allowedActions = commons.modifyTippyBasedOnActionState(
        this.allowedActions,
        actionTippys,
        this.isSDCampaign
      );
      this.allowedActions[this.editStatusConfigIndex].disabled = false;
    },
    getPromotionDays() {
      if (this.promotionDays === 0) {
        return 'No Promos';
      } else {
        return this.promotionDays + (this.promotionDays > 1 ? ' Days' : ' Day');
      }
    },
    getAllTableColumns() {
      return commons.addDefaultColCheck(
        this.config.widgets.widget2.body.columnDefs || [],
        defaultSkuCols
      );
    },
    showMetricDiscrepancyBanner(value) {
      if (showSbv4DiscrepancyBanner()) {
        return !value.includes('sales') && !value.includes('Sales');
      }
      return false;
    }
  }
};
</script>

<style lang="css">
.ams-skus .filter__wrapper > div {
  align-items: flex-start;
}
.ams-skus .filter__wrapper > div > div > div {
  align-items: flex-start;
}
.ams-skus .set-roll-up-width {
  min-width: 210px;
  margin-top: 16px;
}
.ams-skus .padding--right {
  padding-right: 0% !important;
}
.ams-skus .info-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
.ams-skus .filter-panel-with-banner-padding {
  margin-bottom: 0px !important;
}
.skus-radio-group > span {
  font-size: 13px !important;
  color: #6a7075;
}
.skus-radio-group .rb-control-label {
  padding: 4px !important;
  font-size: 13px !important;
}

.ams-skus .migration-banner {
  top: 0rem !important;
}
</style>
