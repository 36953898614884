import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import {
  transformRollUpDataPayload,
  timeSeriesDimensionValue,
  transformToFinalWhereClause
} from '@/components/ams/campaigns/storeHelper.js';

const state = {
  workbenchSearchTermsData: {},
  workbenchSearchTermsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  searchTermPrimaryKey: null,
  timeSeriesRollUpBy: 'DAY',
  showSearchTermActivityLog: false,
  page_wise_min_max_key: 'ams_search_term'
};

const getters = {
  getWorkbenchSearchTermsSelectedFilters: (state) => {
    return state.workbenchSearchTermsSelectedFilters;
  },
  getWorkbenchSearchTermsData: (state) => {
    return state.workbenchSearchTermsData;
  },
  getSearchTermPrimaryKey: (state) => {
    return state.searchTermPrimaryKey;
  },
  getShowSearchTermsActivityLog: (state) => {
    return state.showSearchTermActivityLog;
  },
  getSearchTermsPageWiseMinMaxKey() {
    return state.page_wise_min_max_key;
  },
  getSearchTermsPageWhereClause(state) {
    return state.whereClause;
  },
  getSearchTermsRollUpByValue(state) {
    return state.timeSeriesRollUpBy;
  }
};

const mutations = {
  IWKSEARCHTERMS_SUCCESS: (state, data) => {
    Vue.set(state.workbenchSearchTermsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.workbenchSearchTermsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.workbenchSearchTermsData[data.key], 'data', data.columns);
    }
    Vue.set(state.workbenchSearchTermsData[data.key], 'rows', data.rows);
    Vue.set(
      state.workbenchSearchTermsData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(state.workbenchSearchTermsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.workbenchSearchTermsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.workbenchSearchTermsData[data.key], 'page', data.page);
    Vue.set(state.workbenchSearchTermsData[data.key], 'load', false);
    Vue.set(state.workbenchSearchTermsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.workbenchSearchTermsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.workbenchSearchTermsData[data.key], 'noData', true);
    }
  },
  IWKSEARCHTERMS_RESET: (state, data) => {
    Vue.set(state.workbenchSearchTermsData, data.key, {});
    Vue.set(state.workbenchSearchTermsData[data.key], 'columns', []);
    Vue.set(state.workbenchSearchTermsData[data.key], 'rows', []);
    Vue.set(state.workbenchSearchTermsData[data.key], 'load', true);
    Vue.set(state.workbenchSearchTermsData[data.key], 'error', false);
    Vue.set(state.workbenchSearchTermsData[data.key], 'noData', false);
    Vue.set(state.workbenchSearchTermsData[data.key], 'metrics', {});
  },
  IWKSEARCHTERMS_ERROR: (state, data) => {
    Vue.set(state.workbenchSearchTermsData, data.key, {});
    Vue.set(state.workbenchSearchTermsData[data.key], 'columns', []);
    Vue.set(state.workbenchSearchTermsData[data.key], 'rows', []);
    Vue.set(state.workbenchSearchTermsData[data.key], 'load', false);
    Vue.set(state.workbenchSearchTermsData[data.key], 'error', true);
    Vue.set(state.workbenchSearchTermsData[data.key], 'noData', false);
    Vue.set(state.workbenchSearchTermsData[data.key], 'metrics', {});
  },
  IWKSEARCHTERMS_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.workbenchSearchTermsSelectedFilters.date_range;
    Vue.set(state, 'workbenchSearchTermsSelectedFilters', data.values);
    if (!state.workbenchSearchTermsSelectedFilters.date_range) {
      Vue.set(
        state.workbenchSearchTermsSelectedFilters,
        'date_range',
        dateRange
      );
    }
    getFiltersInFormatWKSearchTerms(data, state);
  },
  OPEN_SEARCH_TERMS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showSearchTermActivityLog', true);
  },
  CLOSE_SEARCH_TERMS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showSearchTermActivityLog', false);
  },
  CHANGE_SEARCH_TERMS_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'searchTermPrimaryKey', key);
  },
  SEARCH_TERMS_ROLL_UP_BY_CHANGE: (state, key) => {
    Vue.set(state, 'timeSeriesRollUpBy', key);
  }
};

function getFiltersInFormatWKSearchTerms(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  // where we call the API
  setSearchTermsRollUpByChanges: (context, data) => {
    context.commit('SEARCH_TERMS_ROLL_UP_BY_CHANGE', data);
  },
  downloadSearchTermsTableData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.whereClause);
    // data.body.APIConfig.where.date = context.state.whereClause.date;
    // data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;

    // delete data.body.APIConfig.customOrderAndSort;
    return HttpLayer.post({
      APIData: data
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },
  setWorkbenchSearchTermsSelectedFilters: (context, data) => {
    context.commit('IWKSEARCHTERMS_SET_SELECTED_FILTER', data);
  },
  getWorkbenchSearchTermsTableData: (context, data) => {
    context.commit('IWKSEARCHTERMS_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    transformToFinalWhereClause({
      data,
      configCheck: Vue.options.filters.config_check('feature.campaignListFix'),
      context,
      finalWhereClause
    });
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKSEARCHTERMS_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('IWKSEARCHTERMS_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].final_auto_cubesdk_count,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IWKSEARCHTERMS_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getWorkbenchSearchTermsChartData: (context, data) => {
    context.commit('IWKSEARCHTERMS_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
      context.state.whereClause
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date;
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    data.body.APIConfig.pvpenabled =
      data.body.APIConfig.where.pvpDate !== undefined;

    transformRollUpDataPayload(data, context);

    HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IWKSEARCHTERMS_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          var xs = {};
          response.data[0].TIMESERIES = transformer.getChartRollUpMapping(
            response.data[0].TIMESERIES,
            timeSeriesDimensionValue[context.state.timeSeriesRollUpBy]
          );
          for (var i = 0; i < response.data.length; i++) {
            var aTemp = [];
            aTemp = transformer.getChartDataInFormat(
              data,
              response.data[0].TIMESERIES
            );
          }

          for (i = 0; i < aTemp.length; i++) {
            if (aTemp[i][0] !== 'report_date') {
              xs[aTemp[i][0]] = 'report_date';
            }
          }

          context.commit('IWKSEARCHTERMS_SUCCESS', {
            key: data.meta.key,
            columns: aTemp,
            xs,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            metricsUnits: data.body.getTagUnitData
              ? data.body.getTagUnitData(response.data)
              : {}
          });
        } else {
          context.commit('IWKSEARCHTERMS_SUCCESS', {
            key: data.meta.key,
            columns: [],
            metrics: {}
          });
        }
      }
    });
  },
  getSearchTermsActivityLog: (context, data) => {
    context.commit('OPEN_CAMPAIGNS_ACTIVITY_LOG');
    context.commit('ACTIVITY_LOG_RESET', {
      key: 'SearchTermsActivityLog'
    });
    context.commit('CHANGE_SEARCH_TERMS_PRIMARYKEY', data.primaryKey);
    HttpService.get('AMS_ACTIONS', {
      append: '?widget=searchTerm&primaryKey=' + data.primaryKey
    }).then((response) => {
      if (response.data && !response.data.success) {
        context.commit('ACTIVITY_LOG_ERROR', {
          key: 'SearchTermsActivityLog',
          rows: []
        });
      } else {
        context.commit('ACTIVITY_LOG_SUCCESS', {
          key: 'SearchTermsActivityLog',
          rows: activitytransformer.getActivityData(response.data.response)
        });
      }
    });
  },

  openSearchTermsActivityLog: (context) => {
    context.commit('OPEN_SEARCH_TERMS_ACTIVITY_LOG');
  },

  closeCampaignsActivityLog: (context) => {
    context.commit('CLOSE_SEARCH_TERMS_ACTIVITY_LOG');
    context.commit('CHANGE_SEARCH_TERMS_PRIMARYKEY', '');
  },
  getCampaignList: (context, APIData) => {
    return new Promise((resolve, reject) => {
      HttpLayer.post({
        APIData
      }).then(
        (response) => {
          resolve(transformer.mergeResultDimension(response.data, true));
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
