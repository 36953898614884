<template>
  <div class="u-display-flex u-flex-direction-column u-overflow-x-hidden">
    <div
      class="u-display-flex u-flex-direction-column u-spacing-ph-l sticky u-spacing-mb-m u-header-box-shadow"
      style="z-index: 100"
    >
      <rb-filter-panel
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'content-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :loader-status="loaderStatus"
        :has-search="false"
        :new-date="false"
        :max-date="getContentPageWiseMinMaxKey"
        :default-date-post-range="''"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Content Audit'" />
        </div>
      </rb-filter-panel>
    </div>
    <DepreciationBanner
      class="u-spacing-mh-m u-spacing-mb-m"
      header-text="Content Audit dashboard will be discontinued on"
      header-date="Sep 7th, 2022"
    >
      <section
        slot="sub-text"
        class="u-spacing-mt-s u-font-size-7 u-color-grey-mid-light"
      >
        We're excited to present our new
        <span class="u-color-blue-base u-cursor-pointer u-font-weight-bold">
          <span
            class="u-text-decoration-underline"
            @click="navigateToContentScorecard"
          >
            Content Scorecard dashboard
          </span>
          !</span
        >
        With this launch, we will discontinue the existing content audit
        dashboard.
      </section>
    </DepreciationBanner>
    <section class="u-envelop-width u-width-100">
      <div class="u-display-flex u-flex-wrap-yes u-spacing-mh-m">
        <card-widget
          v-for="(issue, index) in selectedIssues"
          :key="index"
          class="u-flex-1 u-min-width-20"
          :class="{ 'u-spacing-mr-m': index !== selectedIssues.length - 1 }"
          :dispatch-action="index === 0"
          :config="config.widgets['widget1']"
          :data="
            getInsightsContentData[config.widgets['widget1'].meta.key] || {}
          "
        >
          <div slot="header">
            <rb-select
              :width="'240px'"
              :send-details="true"
              :context="[index]"
              :on-close="issueSelected"
              :options="issues | array_differentiator(selectedIssues)"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-cursor-pointer"
              >
                <span
                  class="u-font-size-4 card__title u-color-grey-lighter u-font-weight-600 u-text-case-upper"
                  >{{ (issue || {}).title }}</span
                >
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'caret-down'"
                />
              </div>
            </rb-select>
          </div>
          <div slot="body">
            <span class="u-display-flex u-flex-align-items-baseline">
              <metric
                :size="'xxl'"
                class="u-display-inline-flex u-spacing-ml-l u-spacing-mr-xs u-spacing-mb-m"
                :config="config.widgets['widget1'].body.tagConfig"
                :data="
                  ((
                    getInsightsContentData[
                      config.widgets['widget1'].meta.key
                    ] || {}
                  ).metrics || {})[issue.key]
                "
              />
              <span class="u-color-grey-lighter u-font-size-3">SKUs</span>
            </span>
          </div>
        </card-widget>
      </div>
      <div
        v-if="showWidget2"
        class="u-display-flex u-flex-wrap-yes u-spacing-mh-m"
      >
        <card-widget
          class="u-flex-1 u-spacing-mt-m u-width-100"
          :config="config.widgets['widget2']"
          :data="widget2Data"
        >
          <div slot="body">
            <div>
              <rb-insights-table
                ref="SKU_Count_Potential_Content_Issues_Table"
                :config="config.widgets['widget2']"
                :grid-options="widget2GridOptions"
                :table-row="widget2Data._rows"
                :table-column="config.widgets['widget2'].body.columnDefs"
                style="width: 100%"
                :row-height="40"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="false"
                :pagination="false"
                :download-this-table="true"
              />
            </div>
          </div>
        </card-widget>
      </div>
      <div class="u-display-flex u-flex-wrap-yes u-spacing-mh-m u-spacing-mt-m">
        <card-widget
          class="u-flex-1 u-spacing-mb-m u-width-100 u-overflow-auto"
          :config="config.widgets['widget3']"
          :data="
            getInsightsContentData[config.widgets['widget3'].meta.key] || {}
          "
        >
          <div slot="body">
            <div>
              <rb-insights-table
                ref="SKU_Level_Analysis_Table"
                :config="config.widgets['widget3']"
                :grid-options="widget3GridOptions"
                :table-row="
                  (
                    getInsightsContentData[
                      config.widgets['widget3'].meta.key
                    ] || {}
                  ).rows
                "
                :table-column="config.widgets['widget3'].body.columnDefs"
                style="width: 100%"
                :row-height="80"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="true"
                :pagination="true"
                :pagination-total-key="
                  (
                    getInsightsContentData[
                      config.widgets['widget3'].meta.key
                    ] || {}
                  ).totalRows
                "
                :pagination-per-page-limit="
                  config.widgets['widget3'].body.APIConfig.limit
                "
                :download-this-table="true"
              />
            </div>
          </div>
        </card-widget>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import cardWidget from '@/components/widgets/cardWidget';
import metric from '@/components/basic/metric';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
import rbPagination from '@/components/basic/pagination';
import DepreciationBanner from '@/components/widgets/banner.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';

var oObject = {
  components: {
    chart,
    loader,
    cardWidget,
    metric,
    rbPagination,
    DepreciationBanner,
    rbFilterPanel
  },
  mixins: [widgetsUtil],
  created() {
    this.fetchFilters('content_index_v2');
    // this.$store.dispatch('getContentFilters');
    this.config = widgetsConfig.config(this);
  },
  computed: {
    getIssues() {
      if (!this.outsideIn) {
        this.issues.splice(2, 2);
      }
      return this.issues;
    },
    getInsightsContentData() {
      return this.$store.getters.getInsightsContentData;
    },
    notSelectedIssues() {
      return this.issues.filter((el) => {
        return !this.selectedIssues.includes(el);
      });
    },
    widget2Data: {
      get: function () {
        var data = (this.$store.getters.getInsightsContentData || {})[
          this.config.widgets.widget2.meta.key
        ];
        if (data) {
          var rows = data.rows || [];
          this.brandData = {};
          this.clientBrands = [];
          this.compBrands = [];

          for (var i = 0; i < rows.length; i++) {
            this.brandData[rows[i].is_catalog_sku + rows[i].brand] = rows[i];
            if (rows[i].is_catalog_sku === 1) {
              if (rows[i].brand && rows[i].brand !== '') {
                this.clientBrands.push({
                  title: rows[i].brand,
                  is_catalog_sku: rows[i].is_catalog_sku
                });
              }
            } else {
              if (rows[i].brand && rows[i].brand !== '') {
                this.compBrands.push({
                  title: rows[i].brand,
                  is_catalog_sku: rows[i].is_catalog_sku
                });
              }
            }
          }

          if (this.clientBrands.length > 0 && !this.selectedClientBrand) {
            this.selectedClientBrand = this.clientBrands[0].title;
          } else {
            if (this.clientBrands.length === 0) {
              this.selectedClientBrand = null;
            }
          }

          if (this.compBrands.length > 0 && !this.selectedCompBrand1) {
            this.selectedCompBrand1 = this.compBrands[0].title;
          } else {
            if (this.compBrands.length === 0) {
              this.selectedCompBrand1 = null;
              this.selectedCompBrand2 = null;
            }
          }

          if (this.compBrands.length > 1 && !this.selectedCompBrand2) {
            this.selectedCompBrand2 = this.compBrands[1].title;
          } else {
            if (this.compBrands.length === 1) {
              this.selectedCompBrand2 = null;
            }
          }

          data._rows = [];

          if (
            this.selectedClientBrand &&
            this.brandData['1' + this.selectedClientBrand]
          ) {
            var x = this.brandData['1' + this.selectedClientBrand];
            x.brands = this.clientBrands;
            x.context = 'selectedClientBrand';
            data._rows.push(x);
          }

          if (
            this.selectedCompBrand1 &&
            this.brandData['0' + this.selectedCompBrand1]
          ) {
            x = this.brandData['0' + this.selectedCompBrand1];
            x.brands = this.compBrands;
            x.context = 'selectedCompBrand1';
            data._rows.push(x);
          }

          if (
            this.selectedCompBrand2 &&
            this.brandData['0' + this.selectedCompBrand2]
          ) {
            x = this.brandData['0' + this.selectedCompBrand2];
            x.brands = this.compBrands;
            x.context = 'selectedCompBrand2';
            data._rows.push(x);
          }

          if (data.rows.length === 0 && data.load === false) {
            data.noData = true;
          }
        }
        return data || {};
      }
    },
    getContentPageWiseMinMaxKey() {
      return this.$store.getters.getContentPageWiseMinMaxKey;
    }
  },
  data() {
    var issues = [
      {
        title: 'Title < 40 characters',
        key: 'is_title_less_than_40_chars'
      },
      {
        title: 'No. of images < 4',
        key: 'is_image_count_less_than_4'
      },
      {
        title: 'No. of A+ content',
        key: 'is_aplus_content_present'
      },
      {
        title: 'Bullet points < 3',
        key: 'is_bullet_count_less_than_3'
      },
      {
        title: 'Video content',
        key: 'is_video_present'
      },
      {
        title: 'Reviews < 21',
        key: 'is_review_count_less_than_21'
      },
      {
        title: 'Rating < 3.5',
        key: 'is_avg_product_rating_less_than_3_pt_5'
      }
    ];
    if (Vue.options.filters.config_check('feature.hardline.enable')) {
      issues = issues.concat({
        title: 'No. of Premium A+ content',
        key: 'is_premium_aplus_content_present'
      });
    }
    var _issues = issues.slice(0, 4);
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      currency: Vue.prototype.$currency,
      clientBrands: [],
      compBrands: [],
      selectedClientBrand: null,
      selectedCompBrand1: null,
      selectedCompBrand2: null,
      issues,
      selectedIssues: _issues,
      filterState: {
        getter: 'getInsightsContentSelectedFilter',
        setter: 'setInsightsContentSelectedFilter'
      },
      widget2GridOptions: {
        context: {
          componentParent: this
        }
      },
      widget3GridOptions: {
        context: {
          componentParent: this
        }
      },
      showWidget2: Vue.options.filters.config_check(
        'feature.insights.content.skucontentissue.enable'
      )
    };
  },
  methods: {
    issueSelected(context, selection) {
      this.selectedIssues[context[0]] = selection[0];
      this.selectedIssues = Vue.util.extend([], this.selectedIssues);
    },
    brandSelected(context, selection) {
      if (context && selection && selection.length && selection.length > 0) {
        this[context.context] = selection[0].title;
      }
    },
    sortTable(column, event) {
      if (column && column.column) {
        var widget = column.column.columnKey;
        var sortOrder = this.config.widgets[widget].body.APIConfig.orderByList;

        var currentOrder = null;
        if (column.order !== null) {
          currentOrder = column.order === 'ascending' ? 'ASC' : 'DESC';
          sortOrder = [
            {
              dimension: column.prop,
              direction: currentOrder
            }
          ];
        }
        var defaultSortOrder =
          this.config.widgets[widget].body.defaultOrderBy || [];
        this.config.widgets[widget].body.APIConfig.orderByList =
          sortOrder.concat(defaultSortOrder);
        this.config.widgets[widget].body.APIConfig.page = 1;
        var action = this.config.widgets[widget].meta.action;
        if (this.config.widgets[widget].meta.alternateAction) {
          action = this.config.widgets[widget].meta.alternateAction;
        }
        this.$store.dispatch(action, this.config.widgets[widget]);
      }
    },
    appendToolTip(h, { column, $index }) {
      var widget = column.columnKey;
      var tooltipTexts = this.config.widgets[widget].body.tooltipTexts || [];
      return renderColumnHeaders.renderHeader(
        h,
        column,
        null,
        tooltipTexts[$index]
      );
    },
    navigateToContentScorecard() {
      this.$router.push({ name: 'Content Scorecard' });
    }
  }
};

export default oObject;
</script>
<style lang="css">
.card .contentPageCustomSelectHeader {
  height: 46px;
  border-top: solid 1px #e9eaeb;
  border-bottom: solid 1px #aaadb1;
  border-right: solid 1px #e9eaeb;
}

.card .contentPageCustomSelect {
  line-height: 23px;
  padding: 12px 16px 9px 0px;
  width: 150px;
  border-right: solid 1px #e9eaeb;
}

.table-header-font-size-m .el-table__header thead tr th:nth-child(1) {
  padding-left: 16px;
  text-align: left;
}

.table-header-font-size-m .el-table__header thead tr th:nth-child(1) .cell {
  justify-content: flex-end !important;
  padding-left: 8px;
}

.table-header-font-size-m
  .el-table__header
  thead
  tr
  th:not(:first-child)
  .cell {
  width: auto !important;
  justify-content: end !important;
}

.table-header-font-size-m .el-table__header thead tr th:not(:first-child) {
  padding-left: 16px;
  text-align: end;
}

.table-header-font-size-m
  .el-table__header
  thead
  tr
  th
  .cell
  .el-table--columnHeaderLabel {
  width: auto !important;
}

.table-header-font-size-m
  .el-table__header
  thead
  tr
  th
  .cell
  .el-table--columnHeaderLabel
  span {
  font-size: 11px;
}
</style>
