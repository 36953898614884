import Data from '@/components/pages/data/data.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: false,
  routes: [
    {
      path: 'skus/:tableGroup?',
      name: 'SKUs',
      component: Data,
      productRoles: ['SalesIQ', 'MarketingIQ'],
      page: 'workbench',
      config: 'skus',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    }
  ]
};
