<template>
  <div
    class="u-display-flex u-flex-justify-content-center u-height-auto"
    style="align-self: stretch"
  >
    <div
      v-show="!isChartLoading"
      class="u-width-100 u-height-auto u-spacing-mt-0 u-position-relative"
    >
      <div
        v-if="showPoweredByCIQText"
        class="u-color-grey-x-light u-font-size-7 u-position-absolute u-ciq-style u-font-weight-600"
        :style="{ right: '12px' }"
      >
        Powered by <span class="u-color-grey-base">CommerceIQ</span>
      </div>
      <chart
        :id="chartId"
        :class="customChartClass"
        :config="chartConfig"
        :chart-change="chartChange"
        :data="formattedChartData"
        :data-cy="`chartId-${chartId}`"
        @pointClicked="pointClicked"
      />
    </div>
    <loader
      class="u-bg-color-grey-white u-spacing-mt-0"
      :loading="isChartLoading"
      :color="'#007cf6'"
      style="height: 450px"
    />
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import loader from '@/components/basic/loader';

export default {
  components: {
    chart,
    loader
  },
  props: {
    customChartClass: {
      type: String,
      default: 'u-width-100'
    },
    isChartLoading: {
      type: Boolean,
      default: false
    },
    chartConfig: {
      type: Object,
      default: () => {},
      stack: [],
      chartGetter: ''
    },
    showPoweredByCIQText: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartId: 'temp-124',
      formattedChartData: {},
      masterMetricList: [],
      masterColorObj: {},
      chartChange: 0
    };
  },
  watch: {
    chartData() {
      this.updateChartData();
    }
  },
  created() {
    this.updateChartData();
  },
  methods: {
    pointClicked(data) {
      this.$emit('pointClicked', data);
    },
    updateChartData(metricColors, selectedMetric = []) {
      const axes = {};
      const data = [];
      let obj = {};
      this.masterMetricList = [...selectedMetric];
      this.masterColorObj = {
        ...this.masterColorObj,
        ...metricColors
      };
      const _metricColor = {};
      if (selectedMetric.length) {
        for (let i = 0; i < selectedMetric.length; i++) {
          const metric = selectedMetric[i];
          if (metric.keyName !== 'comp_name') {
            _metricColor[metric.keyName] = this.masterColorObj[metric.key];
            axes[metric.keyName];
            if (this.plotOnYAxisOnly) {
              axes[metric.key] = i % 2 === 0 ? 'y' : 'y';
            } else {
              axes[metric.key] = i % 2 === 0 ? 'y' : 'y2';
            }
            const indexOfMetric = this.chartData?.data.findIndex((item) => {
              return item && item[0] === metric.key;
            });
            if (indexOfMetric !== -1) {
              data.push(this.chartData.data[indexOfMetric]);
            }
          }
        }
        const timeseriesIndex = this.chartData.data.findIndex((item) => {
          return item[0] === this.chartConfig.chartOptions.timeseries;
        });
        if (timeseriesIndex !== -1) {
          data.push(this.chartData.data[timeseriesIndex]);
        }
        if (this.chartData.xs) {
          const xs = this.chartData.xs;
          for (const key in this.chartData.xs) {
            const xsSeries = this.chartData.data.findIndex((item) => {
              return item[0] === xs[key];
            });
            if (xsSeries !== -1) {
              data.push(this.chartData.data[xsSeries]);
            }
          }
        }
        obj = {
          data: data,
          axes: this.chartData?.axes ? this.chartData.axes : axes,
          colors: _metricColor,
          names: this.chartData?.names,
          legends: this.chartData?.legends
        };
      }
      this.formattedChartData = {
        ...this.chartData,
        ...obj
        // data: this.chartData.data,
        // colors: this.chartData?.colors,
        // names: this.chartData?.names,
        // customLegends: this.chartData?.customLegends,
        // xs: {
        // //   x: 'feed_date',
        //   // 'comp_offer_price_amazon.com': 'feed_date',
        //   // 'comp_offer_price_walmart.com': 'feed_date'
        // },
      };
    }
  }
};
</script>

<style lang="css" scoped>
.u-ciq-style {
  z-index: 1;
  top: 12px;
}
</style>
