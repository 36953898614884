<template>
  <div class="page page-fixed-sider">
    <SideNav />
    <section class="page page-body page-right-side">
      <internalUserLabel />
      <PageHeader />
      <PageBody id="pageBody">
        <transition>
          <router-view />
        </transition>
      </PageBody>
      <right-panel />
    </section>
  </div>
</template>

<script>
import PageBody from '@/components/basic/body.vue';
import PageFooter from '@/components/basic/footer.vue';
import rightPanel from '@/components/basic/rightPanel.vue';
import { eventBus } from '@/utils/services/eventBus';
import internalUserLabel from '@/components/globals/internalUserLabel';
import Vue from 'vue';
import SideNav from '@/components/layouts/menu.vue';
import PageHeader from '@/components/basic/PageHeader.vue';

export default {
  components: {
    PageHeader,
    PageBody,
    PageFooter,
    rightPanel,
    internalUserLabel,
    SideNav
  },
  data() {
    return {
      collapsed: false
    };
  },
  beforeCreate() {
    const filterClear = Vue.options.filters.config_check(
      'feature.filter.clear'
    );
    const filterClearHideBanner = Vue.options.filters.config_check(
      'feature.filter.hideBanner'
    );
    const releaseFromLS = localStorage.getItem('release');
    const filterClearFromLS = localStorage.getItem('filterClear');
    if (
      filterClear &&
      (!filterClearFromLS ||
        (filterClearFromLS && filterClearFromLS !== filterClear))
    ) {
      if (!filterClearHideBanner) {
        this.$snackbar.open({
          message:
            'Your previous filters are removed because your internal catalogue is updated.',
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: '              '
        });
      }

      localStorage.clear();
      if (releaseFromLS) {
        localStorage.setItem('release', releaseFromLS);
      }
    }
  },
  mounted() {
    const isDemoClient = Vue.options.filters.config_check(
      'feature.demo.product_demo.enable'
    );
    if (
      (
        Vue.options.filters.config_check('feature.productHeader') || ''
      ).includes('MarketingIQ') &&
      !isDemoClient
    ) {
      localStorage.removeItem('openHelpByDefault');
      var openHelpByDefault = localStorage.getItem('openHelpByDefault1');
      if (!openHelpByDefault) {
        eventBus.$emit('rightPanelOpen');
        localStorage.setItem('openHelpByDefault1', true);
      }
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'GLOBAL_SNACK_BAR_MESSAGE') {
        this.$snackbar.open({
          message: state.commonStore.globalSnakBack.text,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    });
    setTimeout(
      function () {
        this.$store.dispatch('showWelcome', false);
      }.bind(this),
      2000
    );
  }
};
</script>

<style lang="css" scoped>
.page-fixed-sider {
  flex-direction: row;
  display: flex;
  flex: auto;
  min-height: 0;
  background: #f0f2f5;
}

.page-right-side {
  box-shadow: none;
}
</style>
