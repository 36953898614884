export default {
  map: {
    test_status: {
      title: 'Test Status',
      toolTipText: 'Test Status',
      type: 'status',
      page: 'common',
      campaign_keyOrder: 1,
      pinned: 'left',
      showOnUi: true
    },
    source_name: {
      title: 'Source name',
      toolTipText: 'Source name',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 2,
      pinned: 'left',
      showOnUi: true
    },
    test_description: {
      title: 'Test Description',
      toolTipText: 'Test Description',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 3,
      pinned: 'left',
      showOnUi: true,
      downloadable: false
    },
    test_id: {
      title: 'Test Id',
      toolTipText: 'Test Status',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 4,
      showOnUi: true
    },
    success_count: {
      title: 'Success Count',
      toolTipText: 'Success Count',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 5,
      showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    },
    failed_count: {
      title: 'Failed Count',
      toolTipText: 'Failed Count',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 6,
      // showOnRightPanel: true,
      showOnUi: true,
      downloadable: true
    },
    test_category: {
      title: 'Test category',
      toolTipText: 'Test category',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 7,
      showOnUi: true
    },
    run_date: {
      title: 'Date',
      toolTipText: 'Date of execution',
      type: 'Date',
      page: 'common',
      showOnRightPanel: true,
      campaign_keyOrder: 8,
      downloadable: true
    },
    time_taken: {
      title: 'Time Taken',
      toolTipText: 'Duration Of Test ',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 9,
      showOnUi: true,
      downloadable: true
    },
    cube_name: {
      title: 'Cube Name',
      toolTipText: 'Cube Name',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 10,
      showOnUi: true,
      downloadable: true
    },
    test_type: {
      title: 'Test type',
      toolTipText: 'Test type',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 11,
      showOnUi: true
    },
    s3_path: {
      title: 'Download Report',
      toolTipText: 'Download Report',
      type: 'Button',
      page: 'common',
      campaign_keyOrder: 12,
      downloadable: true,
      pinned: 'right'
    }
  }
};
