<template>
  <div class="chargeback-analytics-dashboard">
    <dashboard-inflator
      :key="pageInfo.id"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :filter="filterSettings"
      :custom-mandatory-date-ranges="[]"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @onPageListApiSuccess="fetchExtraData"
    >
      <div
        slot="slotAboveHeader"
        class="title-bar u-display-flex u-flex-justify-content-flex-end"
      >
        <rb-select
          :open-direction="'bottom-end'"
          class="u-spacing-ml-xxs valueDropdown u-border-none"
          :send-details="true"
          :on-close="optionSelectedFn"
          :options="dateRangeOptions"
        >
          <div
            ref="selectTrigger"
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-cursor-pointer u-user-select-none u-font-size-7 u-color-grey-lighter u-spacing-p-xs chargebacksDateRangeSelector"
          >
            Date Range:
            <span class="u-color-blue-base u-spacing-ml-xs">
              {{ selectedDateRangeTitle }}</span
            >
            <rb-icon
              class="u-flex-1 rb-icon--small"
              :icon="'caret-down'"
            />
          </div>
        </rb-select>
      </div>
      <div slot="right-panel">
        <rb-button
          :click-fn="navigateToChargebackClaims"
          text="View chargeback claims"
          type="filled"
          class="u-spacing-mr-s"
        ></rb-button>
      </div>
      <div slot="custom-filter">
        <div class="u-display-flex u-flex-direction-row">
          <rb-filter-panel
            :key="filterSettings.uiKey"
            style="z-index: 10"
            add-filter-text="Vendor Code"
            :get-filter-instance="getFilterInstance"
            :disable-between-operator="!filterSettings.enableBetweenOperator"
            :data="vendorIDFilterData"
            :is-always-on-filter="true"
            :primary="vendorIDPrimaryData"
            listener-event="chargebackAnalyticsVendorID"
            emit="chargebackAnalyticsVendorIDEvent"
            :enable-save="false"
            :custom-config="customFilterConfig"
            :enable-remove-all="false"
            @onCreate="getFilterInstance"
            @filterMounted="handleFilterMount"
          />
        </div>
      </div>
    </dashboard-inflator>
  </div>
</template>
<script>
import ChargebackStats from './components/chargeback-stats.vue';
import DefectRateByChargebackGroups from './components/chargeback-defect-rate.vue';
import ChargebackSpotlights from './components/chargeback-spotlights.vue';
import ChargebackTypes from './components/chargeback-types.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import transformer from '@/utils/services/data-transformer';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import iconText from '@/components/basic/iconText.vue';
import { cloneDeep, union } from 'lodash';
import widgetTitle from './atoms/widgetTitle.vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

const weeklyChargebckAnalyticsPageId = 9998;
const monthlyChargebackAnalyticsPageId = 9999;

export default {
  components: {
    rbFilterPanel
  },
  mixins: [dashboardMixin],
  provide() {
    return {
      scrollTo: this.scrollToDefectRateWidget,
      setSpotlightWidgetVisibility: this.setSpotlightWidgetVisibility
    };
  },
  data() {
    const pageInfo = {
      globalViewId: 0,
      id: weeklyChargebckAnalyticsPageId,
      name: 'ChargebackAnalytics',
      title: 'Chargeback Analytics'
    };
    const context = this;
    return {
      context,
      filterInstance: null,
      isDownloadLoading: false,
      isDownloadPaymentsReportLoading: false,
      isDownloadSummaryReportLoading: false,
      dataService: {},
      customFilterConfig: {
        title: 'Vendor Code',
        anchor: 'vendor_code_list',
        type: 'CLIENT_INTERNAL_CATALOGUE',
        config: {
          dimensionColumn: 'vendor_code_list',
          dimensionLabel: 'Vendor Code',
          dimensionName: 'vendor_code_list',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        },
        smart: true,
        multi: true
      },
      dateRangeOptions: [
        {
          title: 'Weekly',
          value: 'week'
        },
        {
          title: 'Monthly',
          value: 'month'
        }
      ],
      dateRangeSelected: null,
      vendorIDPrimaryData: [
        {
          dimensionColumn: 'vendor_code_list',
          dimensionLabel: 'Vendor Code',
          dimensionName: 'vendor_code_list',
          dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
        }
      ],
      pageInfo,
      showSpotlightWidget: true,
      isSpotlightComingSoon: false,
      spotlightAndTypeWidgetTitle: '',
      filterSettings: {
        emitEvent: 'ChargebackAnalyticsFilterApply',
        uiKey: 0,
        show: false,
        backendCube: 'chargeback_analytics_dashboard',
        defaultDatePostRange: 'lastWeek',
        enableSave: false,
        enableBetweenOperator: true,
        filterV2: true,
        additionalRanges: {
          overrideRange: [
            'lastWeek',
            'W - 1',
            'W - 2',
            'W - 3',
            'W - 4',
            'W - 5'
          ]
        },
        selectionMode: 'week',
        dateMode: {
          mode: 'week',
          count: 5
        }
      },
      widgetMetadata: [],
      vendorIDFilterData: []
    };
  },
  computed: {
    initRequestParams() {
      return {
        global: {
          ...this.pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': this.pageInfo.name
        },
        chargeback_analytics_total_chargebacks: {
          ':widget': 'chargeback_analytics_total_chargebacks',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        chargeback_analytics_defect_rate_by_chargeback: {
          ':widget': 'chargeback_analytics_defect_rate_by_chargeback',
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        chargeback_analytics_defect_rate_by_chargeback_type: {
          ':widget': 'chargeback_analytics_defect_rate_by_chargeback_type',
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      };
    },
    headerOptions() {
      return {
        chargeback_analytics_total_chargebacks: {
          props: {}
        },
        chargeback_analytics_defect_rate_by_chargeback: {
          props: {
            dateModeSelected: this.dateRangeSelected.value
          }
        },
        chargeback_analytics_spotlight: {
          props: {
            showWidget: this.showSpotlightWidget,
            isComingSoon: this.isSpotlightComingSoon,
            widgetTitle: this.spotlightAndTypeWidgetTitle
          }
        },
        chargeback_analytics_defect_rate_by_chargeback_type: {
          props: {
            dateModeSelected: this.dateRangeSelected.value,
            showWidget: this.showSpotlightWidget,
            widgetTitle: this.spotlightAndTypeWidgetTitle
          }
        }
      };
    },
    selectedDateRangeTitle() {
      return this.dateRangeSelected?.title;
    }
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('chargeback_analytics_total_chargebacks', ChargebackStats);
    Vue.component(
      'chargeback_analytics_defect_rate_by_chargeback',
      DefectRateByChargebackGroups
    );
    Vue.component('chargeback_analytics_spotlight', ChargebackSpotlights);
    Vue.component(
      'chargeback_analytics_defect_rate_by_chargeback_type',
      ChargebackTypes
    );
    Vue.component('icon-text', iconText);
    Vue.component('widgetTitle', widgetTitle);
    this.initDateRangeSelection();
  },
  methods: {
    navigateToChargebackClaims() {
      this.$router.push({
        name: 'chargeback_claims'
      });
    },
    setSpotlightWidgetVisibility(showWidget, isComingSoon, widgetTitle) {
      this.showSpotlightWidget = showWidget;
      this.isSpotlightComingSoon = isComingSoon;
      this.spotlightAndTypeWidgetTitle = widgetTitle ?? '';
    },
    initDateRangeSelection() {
      this.dateRangeSelected = this.dateRangeOptions[0];
    },
    optionSelectedFn(_, val) {
      this.dateRangeSelected = this.dateRangeOptions[val[0].selectedIndex];
      if (this.dateRangeSelected.value === 'month') {
        this.switchToDateModeMonth();
      } else {
        this.switchToDateModeWeek();
      }
    },
    switchToDateModeMonth() {
      localStorage.removeItem('ChargebackAnalytics-date-picker');
      this.pageInfo.id = monthlyChargebackAnalyticsPageId;
      this.pageInfo.name = 'MonthlyChargebackAnalytics';
      this.filterSettings.dateMode.mode = 'month';
      this.filterSettings.dateMode.count = 6;
      this.filterSettings.defaultDatePostRange = 'lastMonth';
      this.filterSettings.selectionMode = 'month';
      this.filterSettings.additionalRanges = {
        overrideRange: [
          'lastMonth',
          'M - 2',
          'M - 3',
          'M - 4',
          'M - 5',
          'M - 6'
        ]
      };
    },
    switchToDateModeWeek() {
      localStorage.removeItem('ChargebackAnalytics-date-picker');
      this.pageInfo.id = weeklyChargebckAnalyticsPageId;
      this.pageInfo.name = 'ChargebackAnalytics';
      this.filterSettings.dateMode.mode = 'week';
      this.filterSettings.defaultDatePostRange = 'lastWeek';
      this.filterSettings.selectionMode = 'week';
      this.filterSettings.additionalRanges = {
        overrideRange: ['lastWeek', 'W - 1', 'W - 2', 'W - 3', 'W - 4', 'W - 5']
      };
    },
    scrollToDefectRateWidget(sortCards) {
      const defectRateWidget = document.querySelector(
        '.DefectRateByChargebackGroups'
      );
      defectRateWidget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    getFilterInstance(filter) {
      this.filterInstance = filter;
      this.handleFilterMount(this.filterInstance);
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        if (filters?.values) {
          const global = cloneDeep(this.getGlobalRequestParams());
          const where = transformer.generateWhereClause({}, filters, true);
          global[':dimensionNameValueList'] =
            where.dimensionNameValueList || [];
          this.updateGlobalRequestParams(global);
        }
        eventBus.$on('chargebackAnalyticsVendorIDEvent', (data) => {
          this.vendorIDFilterEventHandler(instance, data);
        });
      }
    },
    metadataUpdated(data) {
      this.widgetMetadata = data;
    },
    fetchVendorCodeFilterData(vendorCodeFilterAPI) {
      let { service, request, endPoint } = vendorCodeFilterAPI;
      dashUtils
        .fetchDataAPI(request, { service, endPoint })
        .then((response) => {
          this.setVendorCodeFilter(response);
        });
    },
    setVendorCodeFilter(data) {
      this.vendorIDFilterData = data?.entityData?.map((entry) => {
        return {
          vendor_code_list: entry.entityValue
        };
      });
    },
    fetchExtraData(pageApiData) {
      if (pageApiData?.API?.vendorCodeFilterAPI) {
        this.fetchVendorCodeFilterData(pageApiData.API.vendorCodeFilterAPI);
      }
    },
    vendorIDFilterEventHandler(instance, data) {
      let filters = instance.getSelectedFilters();
      const filterData = {
        values: { ...data, ...filters.values }
      };
      delete filterData.values.date_range;
      let dimensionNameValueList =
        data?.vendor_code_list?.map((filterValue) => {
          return {
            dimensionName: 'vendor_code_list',
            dimensionValue: filterValue
          };
        }) || [];
      const global = this.getGlobalRequestParams();
      const filteredGlobalDimensionNameValueList =
        global[':dimensionNameValueList']?.filter(
          (el) => el.dimensionName !== 'vendor_code_list'
        ) || [];
      global[':dimensionNameValueList'] = union(
        filteredGlobalDimensionNameValueList,
        dimensionNameValueList
      );
      this.updateGlobalRequestParams(global);
    }
  }
};
</script>
<style lang="css">
.chargeback-analytics-dashboard {
  position: relative;
}
.chargeback-analytics-dashboard .chargebacksDateRangeSelector {
  border: 1px solid #e9eaeb;
  border-bottom: none;
  border-radius: 2px 2px 0px 0px;
}
.chargeback-analytics-dashboard .filter-dropdown-menu {
  top: 0px !important;
  left: 0px !important;
  width: 246px !important;
}
.chargeback-analytics-dashboard .share-icon-background {
  background-color: #fff;
  border: solid 1px #e9eaeb;
  border-radius: 4px;
  width: 52px;
  align-items: center;
}
.chargeback-analytics-dashboard .c3 .c3-line {
  stroke-width: 1px !important;
}
</style>
