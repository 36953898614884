// import Vue from 'vue';
export default {
  config: () => {
    return {
      filters: {
        emit: 'searchFilterApplied',
        listen: {
          searchFilterApplied: {
            action: 'updateSearchPerfFilters'
          }
        },
        left: [
          {
            title: 'Product Group',
            filterValueKey: 'search_product_group',
            smart: true
          },
          {
            title: 'Category',
            subFilterValueKey: 'search_subcategory',
            filterValueKey: 'search_category',
            twoLevel: true,
            smart: true
          },
          // {
          //   title: 'Brand',
          //   filterValueKey: 'search_brand',
          //   smart: true
          // },
          {
            title: 'Keyword List',
            filterValueKey: 'search_tags'
          }
        ]
      },
      widgets: {
        keywordsTable: {
          meta: {
            hideOnNoData: false,
            width: 1,
            noAction: true,
            action: 'getSearchPerformanceData',
            key: 'SearchPerformanceKeywordsTableData',
            listen: {
              searchFilterApplied: {
                action: 'getSearchPerformanceData',
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            }
          },
          header: {
            search: true,
            searchPlaceholder: 'Search for a keyword',
            searchParams: ['search'],
            show: true,
            title: 'SEARCH PERFORMANCE',
            tooltip:
              'Latest search performance data for keywords and brands being tracked.',
            tooltipSales:
              'Latest search performance data for keywords being tracked.',
            actions: {
              date: {
                action: {
                  setSearchPerformanceKeywordDate: {},
                  getSearchPerformanceData: {
                    transform: function (config, data, that) {
                      var _config = config;
                      _config.body.APIConfig.page = 1;
                      return _config;
                    }
                  }
                },
                maxDate: 'sri',
                defaultRangeSelect: 'last30Days'
              },
              download: {
                triggerThroughComponent: true,
                fileName: 'ShareOfVoiceKeywordsTableData',
                cube: 'EXECUTE_CUBE_API_V2'
              }
            }
          },
          footer: {
            date: 'sri'
          },
          body: {
            defaultOrderBy: [
              {
                dimension: 'search',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              widget: 'search_performance_keywords',
              orderByList: [
                {
                  dimension: 'search_frequency_rank',
                  direction: 'ASC'
                },
                {
                  dimension: 'search',
                  direction: 'ASC'
                }
              ],
              where: {},
              limit: 10,
              page: 1
            },
            gridOptions: {
              context: {
                componentParent: this
              }
            }
          }
        },
        widget3: {
          meta: {
            width: 1,
            noAction: true,
            key: 'TimeMachineData',
            localFilters: []
          },
          header: {
            show: true,
            title: 'SNAPSHOT OF PAGE 1',
            tooltip:
              'Distribution of SKUs on page 1 on a selected date. The chart provides breakdown by brands along with exact SKUs (which can be seen on mouse hover of each cell).',
            actions: {
              filter: {
                optionsGetter: 'getSRILocalFilters',
                selectionGetter: 'getSelectedSRILocalFilters',
                items: [
                  {
                    staticKeyToDisplay: 'Keyword :',
                    isMulti: false,
                    dimensionName: 'search',
                    key: 'searchDistribution',
                    action: 'fetchDistributionData',
                    value: [],
                    selectTagEdit: false
                  }
                ]
              },
              date: {
                mode: 'Single',
                action: {
                  setDistributionDate: {},
                  fetchDistributionData: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                maxDate: 'sri',
                defaultRangeSelect: 'last30Days'
              }
            }
          },
          footer: {
            date: 'sri'
          },
          body: {
            APIConfig: {
              cubeName: 'v3_sku_level_search_rank_with_transformed_metadata',
              measuresList: ['image_url', 'asin_hyperlink'],
              groupByDimensionsList: [
                'brand_by_client_flag',
                'search_rank',
                'all_over_search_rank',
                'search_type',
                'sku_title',
                'asin'
              ],
              timeseriesDimension: 'feed_date',
              where: {},
              orderByList: [
                { dimension: 'all_over_search_rank', direction: 'ASC' },
                { dimension: 'search_type', direction: 'ASC' },
                { dimension: 'search_rank', direction: 'ASC' }
              ],
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              getSharePercentage: false,
              limit: 100,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  []
              }
            }
          }
        },
        widget4: {
          meta: {
            type: 'chart',
            hideOnNoData: false,
            noAction: true,
            width: 1,
            key: 'ShareOfSearch',
            listen: {
              searchFilterApplied: {
                action: 'fetchShareOfSearchData',
                transform: function (config, data, that) {
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'SHARE OF SEARCH',
            tooltip:
              'Day to day trend of share of page 1, top 10 or top 3 search for selected keyword along with selected competitors. Top 2 competitors are selected by default. The %age share no. along with brand names is average for the selected date range and also shows change from corresponding previous period.',
            actions: {
              filter: {
                optionsGetter: 'getShareOfSearchLocalFilters',
                selectionGetter: 'getSelectedShareOfSearchLocalFilters',
                items: [
                  {
                    staticKeyToDisplay: 'Keyword :',
                    isMulti: false,
                    dimensionName: 'search',
                    key: 'searchDistribution',
                    action: 'fetchShareOfSearchData',
                    value: [],
                    selectTagEdit: false
                  },
                  {
                    isMulti: false,
                    dimensionName: 'search_rank',
                    operator: 'LESS_THAN_OR_EQUAL_TO',
                    key: 'granularityFilter',
                    action: 'fetchShareOfSearchData',
                    value: [],
                    selectTagEdit: false
                  }
                ]
              },
              date: {
                action: {
                  setShareOfSearchDate: {},
                  fetchShareOfSearchData: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                maxDate: 'sri',
                showComparedToText: true,
                defaultRangeSelect: 'last30Days'
              }
            }
          },
          footer: {
            date: 'sri'
          },
          body: {
            APIConfig: {
              limit: 100,
              page: 1,
              cubeName: 'sov_search_level_data_widget_1',
              measuresList: ['brands_count_page_1'],
              groupByDimensionsList: ['brand', 'client_flag'],
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['search', 'search_type'],
                excludeDedupAxes: ['tags']
              },
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              pvpenabled: true,
              yoyenabled: false,
              getSharePercentage: true
            },
            getTagData(data, mapping) {
              mapping = 'count_share_percentage';
              var tag1Key = 'count_share_percentage';
              var oReturn = {};
              for (var i = 0; i < data.length; i++) {
                mapping = 'count_share_percentage';
                var brand = data[i].DIMENSION.brand;
                if (brand === undefined) {
                  brand = 'ams_spend';
                  tag1Key = 'ams_spend';
                  mapping = 'ams_spend';
                  oReturn[brand] = {
                    tag1: data[i].RESULT[tag1Key] || 0,
                    tag2: (data[i].PVP || {})['PVP_' + mapping]
                  };
                } else {
                  oReturn[brand] = {
                    tag1: data[i].RESULT[tag1Key] || 0,
                    tag2: (data[i].PVP || {})['PVP_DIFF_' + mapping]
                  };
                }
              }
              return oReturn;
            },
            getTagUnitData(data) {
              var oReturn = {};
              oReturn['ams spend'] = {
                tag2Unit: {
                  suff: '%'
                },
                tag1Unit: {
                  pre: 'currency'
                }
              };
              for (var i = 0; i < data.length; i++) {
                oReturn[data[i].DIMENSION.brand] = {
                  tag2Unit: {
                    suff: '%'
                  },
                  tag1Unit: {
                    suff: '%'
                  }
                };
              }
              return oReturn;
            },
            chart: {
              axesConf: {
                axes: {
                  'ams spend': 'y2'
                }
              },
              chartOptions: {
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  All: {
                    suff: '%'
                  },
                  'ams spend': {
                    suff: '',
                    pre: 'currency'
                  }
                },
                axis_format: {
                  y: {
                    suff: '%'
                  },
                  y2: {
                    suff: '',
                    pre: 'currency'
                  }
                },
                size: {
                  height: 380
                },
                grid: 'xy'
              }
            }
          }
        },
        brandsTable: {
          meta: {
            hideOnNoData: false,
            width: 1,
            action: 'getBrandsTableData',
            key: 'SearchPerformanceBrandsTableData',
            listen: {
              searchFilterApplied: {
                action: 'getBrandsTableData',
                transform: function (config, data, that) {
                  return config;
                }
              }
            }
          },
          header: {
            // search: true,
            // searchPlaceholder: 'Search for a brand',
            searchParams: ['brand'],
            show: true,
            title: 'SEARCH PERFORMANCE',
            tooltip:
              'Latest search performance data for keywords and brands being tracked.',
            actions: {
              download: {
                triggerThroughComponent: true,
                fileName: 'ShareOfVoiceBrandsTableData',
                cube: 'EXECUTE_CUBE_API_V2'
              },
              date: {
                action: {
                  setSearchPerformanceBrandsDate: {},
                  getBrandsTableData: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                showComparedToText: true,
                maxDate: 'sri',
                defaultRangeSelect: 'last30Days'
              }
            }
          },
          footer: {
            date: 'sri'
          },
          body: {
            tooltipTexts: [],
            gridOptions: {
              context: {
                componentParent: this
              }
            },
            APIConfig: {
              widget: 'search_performance_brands'
            }
          }
        }
      }
    };
  }
};
