<template>
  <section class="tippy-content rb-select-menu u-min-width-200">
    <div class="u-display-flex u-width-100">
      <rb-input
        ref="searchInput"
        v-model="searchText"
        :placeholder="placeHolder"
        :rounded="false"
        class="search__input u-flex-1"
      />
    </div>
    <div class="tippy-drop-down u-spacing-ph-m u-font-size-6">
      <div
        v-if="!computedSegmentedData.length"
        class="u-font-weight-normal u-font-size-7 u-text-align-center u-color-grey-light u-min-height-100"
      >
        No results
      </div>
      <div
        v-for="(segment, segmentedIndex) in computedSegmentedData"
        v-else
        :key="segmentedIndex"
      >
        <div
          class="u-font-weight-600"
          :class="{
            'u-spacing-mv-s': segment.groupTitle
          }"
        >
          {{ segment.groupTitle }}
        </div>
        <label
          v-for="(data, index) in segment.data"
          :key="index"
          class="u-font-weight-normal u-color-grey-light u-spacing-mb-m u-display-flex u-flex-align-items-center"
        >
          <input
            class="no-margins"
            type="radio"
            :value="data"
            :checked="data.brand === internalSelection.brand"
            @input.prevent="handleInput"
          />
          <div class="u-spacing-pl-xs">{{ data.brand }}</div>
        </label>
      </div>
    </div>
    <div
      class="u-spacing-p-m u-display-flex u-flex-justify-content-flex-end u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="cancelOrBack.clickFn"
        :text="cancelOrBack.text"
        :size="'s'"
        :type="'hollow'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
      <rb-button
        :click-fn="nextOrApply.clickFn"
        :disabled="!isNextApplyActive"
        :text="nextOrApply.text"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
    </div>
  </section>
</template>

<script>
import { isEqual } from 'lodash';
export default {
  props: {
    placeHolder: {
      type: String,
      default: 'Search'
    },
    nextStep: {
      type: Object,
      default: () => ({})
    },
    currentPath: {
      type: Array,
      default: () => []
    },
    currentStepIndex: {
      type: Number,
      default: null
    },
    lastStepIndex: {
      type: Number,
      default: null
    },
    segmentedData: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: Object,
      default: () => ({})
    },
    ruleBuilderChipConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchText: null,
      radioSelection: {},
      internalSelection: {}
    };
  },
  computed: {
    computedSegmentedData() {
      if (this.searchText) {
        const lowerCaseSearchText = this.searchText?.toLowerCase();
        const computedSegmentedData = [];
        this.segmentedData.forEach((segment) => {
          const filteredSegmentData = segment.data.filter((item) => {
            const lowercaseTitle = item.brand?.toLowerCase();
            if (lowercaseTitle) {
              return lowercaseTitle?.includes(lowerCaseSearchText);
            }
          });
          if (filteredSegmentData.length) {
            const segmentCopy = { ...segment };
            segmentCopy.data = filteredSegmentData;
            computedSegmentedData.push(segmentCopy);
          }
        });
        return computedSegmentedData;
      }
      return this.segmentedData;
    },
    isNextApplyActive() {
      if (this.internalSelection?.brand) {
        return true;
      }
      return false;
    },
    cancelOrBack() {
      if (this.currentPath[this.currentStepIndex - 1]) {
        return {
          clickFn: this.handleBack,
          text: 'Back'
        };
      }
      return {
        clickFn: this.handleCancel,
        text: 'Cancel'
      };
    },
    nextOrApply() {
      if (this.currentStepIndex !== this.lastStepIndex) {
        return {
          clickFn: this.handleNext,
          text: 'Next'
        };
      }
      return {
        clickFn: this.handleApply,
        text: 'Apply'
      };
    }
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(newSelectedValue, oldSelctedValue) {
        if (!isEqual(newSelectedValue, oldSelctedValue)) {
          this.internalSelection = JSON.parse(
            JSON.stringify(newSelectedValue || {})
          );
        }
      }
    }
  },
  methods: {
    handleInput(evt) {
      this.internalSelection = evt.target._value;
    },
    selectCurrentSelection() {
      const infix = { text: this.internalSelection.brand };
      const payload = {
        ...this.internalSelection,
        ...this.ruleBuilderChipConfig.radio,
        infix
      };
      this.radioSelection = payload;
      this.$emit('radioSelection', payload);
    },
    handleNext() {
      this.selectCurrentSelection();
      const nextStep = { ...this.nextStep };
      nextStep.stepTitle = this.internalSelection.brand;
      this.$emit('incStep', nextStep);
    },
    handleBack() {
      this.$emit('decStep');
    },
    handleCancel() {
      this.$emit('cancel');
      this.resetInternalSelection();
    },
    handleApply() {
      this.selectCurrentSelection();
      this.$emit('apply');
      this.resetInternalSelection();
    },
    resetInternalSelection() {
      this.internalSelection = JSON.parse(
        JSON.stringify(this.selectedValue || {})
      );
    }
  }
};
</script>

<style lang="css" scoped>
.tippy-drop-down {
  max-height: 300px;
  overflow-y: scroll;
}
.no-margins {
  margin: 0;
}
</style>
