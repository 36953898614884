<template>
  <div class="u-display-flex">
    <div class="u-spacing-mt-l u-spacing-mb-xs">
      <rb-select
        class="fs-budget-plan-dashboard-roll-up u-spacing-mr-m valueDropdown u-max-width-100"
        :send-details="true"
        :options="rollUps"
        :on-close="onRollupChange"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
        >
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
            icon="calendar"
          />
          <span
            class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
            >Roll up by:&nbsp;</span
          >
          <div
            class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
          >
            {{ selectedRollUp.name }}
          </div>
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
            icon="caret-down"
          />
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div class="u-display-flex u-flex-align-items-center">
            {{ option.name }}
          </div>
        </template>
      </rb-select>
    </div>
    <div class="u-spacing-mt-l u-spacing-mb-xs u-max-height-100">
      <splitTabs
        tab1="Cumulative"
        tab2="Absolute"
        class="split-tabs-container"
        @tabClicked="onTabClick"
      />
    </div>
  </div>
</template>

<script>
import splitTabs from '@/components/widgets/splitTabs';

export default {
  components: {
    splitTabs
  },
  props: {
    rollUps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedRollUp: { name: 'Days', key: 'rollup_days' }
    };
  },
  methods: {
    onTabClick(tab) {
      this.$emit('onTabClick', tab);
    },
    onRollupChange(context, rollup) {
      if (rollup.length) {
        this.selectedRollUp = rollup[0];
      }
      this.$emit('onRollupChange', context, rollup);
    }
  }
};
</script>

<style lang="css" scoped>
.valueDropdown {
  border-radius: 13.5px;
  height: 26px;
  border: solid 1px #e9eaeb;
}
.valueDropdown:hover,
.valueDropdown:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}
</style>
