<template>
  <div class="u-display-flex u-flex-align-items-center u-flex-direction-column">
    <migration-banner :paddingcss="paddingcss" />
    <system-transparency-banner-content
      :dates="dates"
      :paddingcss="paddingcss"
    />
  </div>
</template>

<script>
import migrationBanner from '@/components/widgets/migrationBanner.vue';
import systemTransparencyBannerContent from '@/components/widgets/systemTransparencyBannerContent.vue';

export default {
  components: {
    migrationBanner,
    systemTransparencyBannerContent
  },
  props: {
    dates: {
      default: () => {
        return {};
      },
      type: Object
    },
    paddingcss: {
      type: [String],
      default: 'u-spacing-pv-xs'
    }
  }
};
</script>
