<template>
  <div>
    <div
      v-if="!pageError"
      class="u-display-flex u-flex-direction-column"
    >
      <modal
        v-if="isIQTipPopupVisible"
        :modal-config="modalConfig"
      />
      <div
        class="u-display-flex u-flex-direction-column u-spacing-ph-l sticky u-spacing-mb-m u-header-box-shadow"
        style="z-index: 100"
      >
        <rb-filter-panel
          :key="filterKey"
          :data="filterData"
          :primary="primaryFilterData"
          :loader-status="loaderStatus"
          :secondary-data="secondaryFilterData"
          :listener-event="'search-rank-index-filters'"
          :state="filterState"
          :config="config.filters"
          :emit="config.filters.emit"
          :has-search="false"
          :new-date="false"
          :max-date="getSovPageWiseMinMaxKey"
          :default-date-post-range="''"
          :get-filter-instance="getFilterInstance"
        >
          <div
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
          >
            <PageTitle :title="'Share of Voice'" />
          </div>
        </rb-filter-panel>
      </div>
      <warningBanner
        v-if="isMarketingInsightsAvailable"
        class="u-spacing-mh-m u-spacing-mb-m"
        header-text="Share of Voice dashboard will be discontinued on"
        header-date="28th Feb, 2022"
      >
        <section
          slot="sub-text"
          class="u-spacing-mt-s u-font-size-7 u-color-grey-mid-light"
        >
          We're excited to present our new Share of Voice dashboard -
          <span
            class="u-color-blue-base u-cursor-pointer"
            @click="navigateToMarketInsights"
            >Market Insights!</span
          >
          With this, we will discontinue the existing SoV dahsboard, to ensure
          there is one source of truth for SoV data
        </section>
      </warningBanner>
      <card-widget
        v-if="marketingClientConfig === true"
        class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
        :filter-applied="widget4DateFilterApplied"
        :show-loader="true"
        :config="config.widgets['widget4']"
        :data="shareOfSearchData || {}"
        :ishighlighted="ishighlighted"
        :highlighted-key="
          config.widgets['widget4'].header.actions.filter.items[0].key
        "
        :page-wise-min-max-key="getSovPageWiseMinMaxKey"
        data-cy="sov_card_widget"
      >
        <div slot="body">
          <div
            v-if="marketingClientConfig === true"
            class="u-spacing-mh-l u-spacing-mb-l u-display-flex"
          >
            <div class="checkBoxContainer">
              <rb-checkbox
                v-model="checkboxOrganicProducts"
                :true-value="true"
                :false-value="false"
              >
                Organic Products
              </rb-checkbox>
            </div>
            <div class="checkBoxContainer">
              <div class="checkboxBlock">
                <rb-checkbox
                  v-model="checkboxSponsoredProducts"
                  :true-value="true"
                  :false-value="false"
                >
                  Sponsored Products
                </rb-checkbox>
              </div>
              <div class="adTags">AD</div>
            </div>
            <div class="checkBoxContainer">
              <div class="checkboxBlock">
                <rb-checkbox
                  v-model="checkboxSponsoredBrands"
                  :true-value="true"
                  :false-value="false"
                >
                  Sponsored Brands
                </rb-checkbox>
              </div>
              <div class="adTags">AD</div>
            </div>
            <div />
          </div>
          <div class="u-spacing-ph-l">
            <div class="u-display-flex u-flex-align-items-center">
              <div
                :style="{
                  'border-color':
                    shareOfSearchColors['0' + selectedShareClientBrand] ||
                    'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="shareClientBrands <= 0"
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span class="u-font-size-5">My Brands</span>
                </span>
                <rb-select
                  v-if="shareClientBrands.length > 0"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :send-details="true"
                  :on-close="shareClientBrandSelected"
                  :options="shareClientBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <span class="u-font-size-5">{{
                        selectedShareClientBrand
                      }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    selectedShareClientBrand &&
                    ((shareOfSearchData || {}).metrics || {})[
                      selectedShareClientBrand
                    ]
                  "
                  :config="
                    ((shareOfSearchData || {}).metricsUnits || {})[
                      selectedShareClientBrand
                    ]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="
                    ((shareOfSearchData || {}).metrics || {})[
                      selectedShareClientBrand
                    ]
                  "
                />
                <span
                  v-if="!selectedShareClientBrand"
                  class="rb-metric--l u-spacing-mt-s"
                >
                  <span
                    class="primary_metric u-color-grey-light u-font-weight-bold"
                    >0.00%</span
                  >
                </span>
              </div>

              <div
                v-if="marketingClientConfig === true"
                :style="{
                  'border-color':
                    shareOfSearchColors['1ams spend'] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span class="u-font-size-5">AMS Spend</span>
                </span>
                <metric
                  :config="
                    ((shareOfSearchData || {}).metricsUnits || {})['ams spend']
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="((shareOfSearchData || {}).metrics || {})['ams_spend']"
                />
              </div>

              <div
                v-for="(val, index) in selectedShareCompBrand"
                v-if="index <= shareCompBrands.length"
                :key="index"
                :val="val"
                :style="{
                  'border-color':
                    shareOfSearchColors['1' + val] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="
                    marketingClientConfig === false &&
                    index + 1 === selectedShareCompBrand.length &&
                    index > 0
                  "
                  class="removeLegend"
                  @click="removeLegend(index)"
                >
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--small u-cursor-pointer"
                    :icon="'cross'"
                  />
                </span>
                <rb-select
                  v-if="val"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :context="[index]"
                  :send-details="true"
                  :on-close="shareCompBrandSelected"
                  :options="
                    shareCompBrands
                      | array_differentiator(selectedShareCompBrand, 'title')
                  "
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                    >
                      <span class="u-font-size-5">{{ val }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>

                <metric
                  v-if="val && ((shareOfSearchData || {}).metrics || {})[val]"
                  :config="((shareOfSearchData || {}).metricsUnits || {})[val]"
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="((shareOfSearchData || {}).metrics || {})[val]"
                />
                <span
                  v-if="
                    val &&
                    val !== selectBrandPlaceholder &&
                    !((shareOfSearchData || {}).metrics || {})[val]
                  "
                  class="rb-metric--l u-spacing-mt-s"
                >
                  <span
                    class="primary_metric u-color-grey-light u-font-weight-bold"
                    >0.00%</span
                  >
                </span>
                <span
                  v-if="!val && !((shareOfSearchData || {}).metrics || {})[val]"
                  class="u-color-grey-light u-font-size-5"
                  >No Data</span
                >
              </div>

              <div
                v-if="
                  marketingClientConfig === false &&
                  selectedShareCompBrand.length < 4 &&
                  selectedShareCompBrand.length < shareCompBrands.length
                "
                class="add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"
              >
                <span @click="addMore">
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--large u-cursor-pointer"
                    :icon="'add-circle-outline'"
                  />
                </span>
              </div>
            </div>
            <chart
              id="shareOfSearchChart"
              class="u-flex-1 u-spacing-mt-l"
              :config="config.widgets['widget4'].body.chart"
              :data="widget4Data"
            />
          </div>
        </div>
      </card-widget>

      <div
        v-if="marketingClientConfig === true"
        class="search-performance-widget"
        data-cy="search-performance-widget"
      >
        <card-widget
          v-show="activeTab === 0"
          class="searchPerformanceTable u-flex-1 u-spacing-mh-m u-spacing-mb-m"
          :config="config.widgets['keywordsTable']"
          :data="{ load: false }"
          :page-wise-min-max-key="getSovPageWiseMinMaxKey"
          data-cy="sov_card_widget"
          @downloadTableThroughComponent="downloadKeywordTable"
        >
          <div slot="body">
            <rb-tabs
              v-model="activeTab"
              class="u-spacing-mh-m"
              :type="'underlined'"
              :tab-list="shareOfVoiceTabs"
            />
            <div v-if="keywordsTableData">
              <div
                v-if="
                  (
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).load
                "
                class="u-width-100 u-max-height-400 u-position-relative u-border-width-s u-border-top u-border-color-grey-xxx-light"
                style="height: 300px"
              >
                <loader
                  class="fill--parent"
                  :loading="true"
                  :color="'#007cf6'"
                />
              </div>
              <div
                v-show="
                  (
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).noData
                "
                class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
              >
                No Data
              </div>
              <div
                v-show="
                  !(
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).noData &&
                  !(
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).load
                "
              >
                <rb-insights-table
                  ref="keywordsTable"
                  :config="config.widgets.keywordsTable"
                  :grid-options="
                    config.widgets['keywordsTable'].body.gridOptions
                  "
                  :auto-configure="
                    (
                      keywordsTableData[
                        config.widgets['keywordsTable'].meta.key
                      ] || {}
                    ).completeTableData
                  "
                  style="width: 100%"
                  :row-height="45"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="true"
                  :pagination="true"
                  :pagination-total-key="
                    (
                      keywordsTableData[
                        config.widgets['keywordsTable'].meta.key
                      ] || {}
                    ).totalRows
                  "
                  :pagination-per-page-limit="searchPerformancePagination.limit"
                  :download-this-table="true"
                  :custom-table-object="customKeywordTableObject"
                />
              </div>
            </div>
          </div>
        </card-widget>
        <card-widget
          v-show="activeTab === 1"
          class="searchPerformanceTable u-flex-1 u-spacing-mh-m u-spacing-mb-m"
          :config="config.widgets['brandsTable']"
          :data="{ load: false }"
          :page-wise-min-max-key="getSovPageWiseMinMaxKey"
          data-cy="sov_card_widget"
          @downloadTableThroughComponent="downloadBrandsTable"
        >
          <div slot="body">
            <rb-tabs
              v-model="activeTab"
              class="u-spacing-mh-m"
              :type="'underlined'"
              :tab-list="shareOfVoiceTabs"
            />
            <div v-if="brandsTableData">
              <div
                v-if="
                  (
                    brandsTableData[config.widgets['brandsTable'].meta.key] ||
                    {}
                  ).load
                "
                class="u-width-100 u-max-height-400 u-position-relative u-border-width-s u-border-top u-border-color-grey-xxx-light"
                style="height: 300px"
              >
                <loader
                  class="fill--parent"
                  :loading="true"
                  :color="'#007cf6'"
                />
              </div>
              <div
                v-show="
                  (
                    brandsTableData[config.widgets['brandsTable'].meta.key] ||
                    {}
                  ).noData
                "
                class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
              >
                No Data
              </div>
              <div
                v-show="
                  !(
                    brandsTableData[config.widgets['brandsTable'].meta.key] ||
                    {}
                  ).noData &&
                  !(
                    brandsTableData[config.widgets['brandsTable'].meta.key] ||
                    {}
                  ).load
                "
              >
                <rb-insights-table
                  ref="brandsTable"
                  :get-current-instance="setBrandsTableInstance"
                  :config="config.widgets['brandsTable']"
                  :grid-options="config.widgets['brandsTable'].body.gridOptions"
                  :table-row="transformedBrandsTableData"
                  :table-column-object="brandsTableColumns"
                  style="width: 100%"
                  :row-height="45"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="false"
                  :pagination="false"
                  :download-this-table="true"
                />
              </div>
            </div>
          </div>
        </card-widget>
      </div>

      <div
        v-if="marketingClientConfig === false"
        class="search-performance-widget"
      >
        <card-widget
          class="searchPerformanceTable u-flex-1 u-spacing-mh-m u-spacing-mb-m"
          :config="keywordsTableConfigForSales"
          :data="{ load: false }"
          :page-wise-min-max-key="getSovPageWiseMinMaxKey"
          data-cy="sov_card_widget"
          @downloadTableThroughComponent="downloadKeywordTable"
        >
          <div slot="body">
            <div v-if="keywordsTableData">
              <div
                v-if="
                  (
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).load
                "
                class="u-width-100 u-max-height-400 u-position-relative u-border-width-s u-border-top u-border-color-grey-xxx-light"
                style="height: 300px"
              >
                <loader
                  class="fill--parent"
                  :loading="true"
                  :color="'#007cf6'"
                />
              </div>
              <div
                v-if="
                  (
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).noData
                "
                class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
              >
                No Data
              </div>
              <div
                v-show="
                  !(
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).noData &&
                  !(
                    keywordsTableData[
                      config.widgets['keywordsTable'].meta.key
                    ] || {}
                  ).load
                "
              >
                <rb-insights-table
                  ref="keywordsTable"
                  :get-current-instance="setKeywordsTableInstance"
                  :config="config.widgets.keywordsTable"
                  :grid-options="
                    config.widgets['keywordsTable'].body.gridOptions
                  "
                  :auto-configure="
                    (
                      keywordsTableData[
                        config.widgets['keywordsTable'].meta.key
                      ] || {}
                    ).completeTableData
                  "
                  style="width: 100%"
                  :row-height="45"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="false"
                  :pagination="true"
                  :pagination-total-key="
                    (
                      keywordsTableData[
                        config.widgets['keywordsTable'].meta.key
                      ] || {}
                    ).totalRows
                  "
                  :pagination-per-page-limit="searchPerformancePagination.limit"
                  :download-this-table="true"
                  :custom-table-object="customKeywordTableObject"
                />
              </div>
            </div>
          </div>
        </card-widget>
      </div>

      <card-widget
        class="timeMachineWidget over-flow-x-auto u-spacing-mh-m u-spacing-mb-m u-flex-wrap-yes u-flex-1"
        :config="config.widgets['widget3']"
        :data="distributionData || { name: 'value' }"
        :ishighlighted="ishighlighted"
        :highlighted-key="
          config.widgets['widget3'].header.actions.filter.items[0].key
        "
        :page-wise-min-max-key="getSovPageWiseMinMaxKey"
        data-cy="sov_card_widget"
      >
        <div
          slot="body"
          :class="{
            'u-min-height-100': !(
              (distributionData && distributionData.rows) ||
              []
            ).length
          }"
        >
          <div
            class="u-position-relative u-min-width-40 u-flex-1 u-spacing-mb-l"
          >
            <div class="u-spacing-ph-l flex-row u-flex-align-items-center">
              <div class="card__value">
                <div
                  v-if="
                    ((distributionData && distributionData.rows) || []).length
                  "
                  class="flex-row u-flex-align-items-center"
                >
                  <data-bars
                    :retailer-brand-name="getRetailerBrandName"
                    :custom-legends="marketingClientConfig === true"
                    :client-name="'client_brands'"
                    :rank-key="'all_over_search_rank'"
                    :bar-tooltip-key="'sku_title'"
                    :bars-value="distributionData.rows"
                    :legends-value="distributionData.tmLegends"
                    :sorted-legends="distributionData.sortedLegends"
                    :key-to-check="'brand_by_client_flag'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </card-widget>

      <card-widget
        v-if="marketingClientConfig === false"
        class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
        :dispatch-action="false"
        :filter-applied="widget4DateFilterApplied"
        :config="config.widgets['widget4']"
        :data="shareOfSearchData || {}"
        :ishighlighted="ishighlighted"
        :highlighted-key="
          config.widgets['widget4'].header.actions.filter.items[0].key
        "
        :page-wise-min-max-key="getSovPageWiseMinMaxKey"
        data-cy="sov_card_widget"
      >
        <div
          slot="body"
          data-cy="share_of_search"
        >
          <div
            v-if="marketingClientConfig === true"
            class="u-spacing-mh-l u-spacing-mb-l u-display-flex"
          >
            <div class="checkBoxContainer">
              <rb-checkbox
                v-model="checkboxOrganicProducts"
                :true-value="true"
                :false-value="false"
              >
                Organic Products
              </rb-checkbox>
            </div>
            <div class="checkBoxContainer">
              <div class="checkboxBlock">
                <rb-checkbox
                  v-model="checkboxSponsoredProducts"
                  :true-value="true"
                  :false-value="false"
                >
                  Sponsored Products
                </rb-checkbox>
              </div>
              <div class="adTags">AD</div>
            </div>
            <div class="checkBoxContainer">
              <div class="checkboxBlock">
                <rb-checkbox
                  v-model="checkboxSponsoredBrands"
                  :true-value="true"
                  :false-value="false"
                >
                  Sponsored Brands
                </rb-checkbox>
              </div>
              <div class="adTags">AD</div>
            </div>
            <div />
          </div>
          <div class="u-spacing-ph-l">
            <div class="u-display-flex u-flex-align-items-center">
              <div
                :style="{
                  'border-color':
                    shareOfSearchColors['0' + selectedShareClientBrand] ||
                    'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="shareClientBrands <= 0"
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span
                    class="u-font-size-5"
                    data-cy="metricTitle"
                    >My Brands</span
                  >
                </span>
                <rb-select
                  v-if="shareClientBrands.length > 0"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :send-details="true"
                  :on-close="shareClientBrandSelected"
                  :options="shareClientBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <span
                        class="u-font-size-5"
                        data-cy="metricTitle"
                        >{{ selectedShareClientBrand }}</span
                      >
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    selectedShareClientBrand &&
                    ((shareOfSearchData || {}).metrics || {})[
                      selectedShareClientBrand
                    ]
                  "
                  :config="
                    ((shareOfSearchData || {}).metricsUnits || {})[
                      selectedShareClientBrand
                    ]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="
                    ((shareOfSearchData || {}).metrics || {})[
                      selectedShareClientBrand
                    ]
                  "
                />
                <span
                  v-if="!selectedShareClientBrand"
                  class="rb-metric--l u-spacing-mt-s"
                >
                  <span
                    class="primary_metric u-color-grey-light u-font-weight-bold"
                    >0.00%</span
                  >
                </span>
              </div>

              <div
                v-if="marketingClientConfig === true"
                :style="{
                  'border-color':
                    shareOfSearchColors['1ams spend'] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span
                    class="u-font-size-5"
                    data-cy="metricTitle"
                    >AMS Spend</span
                  >
                </span>
                <metric
                  :config="
                    ((shareOfSearchData || {}).metricsUnits || {})['ams spend']
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="((shareOfSearchData || {}).metrics || {})['ams_spend']"
                />
              </div>

              <div
                v-for="(val, index) in selectedShareCompBrand"
                v-if="index <= shareCompBrands.length"
                :key="index"
                :val="val"
                :style="{
                  'border-color':
                    shareOfSearchColors['1' + val] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="
                    marketingClientConfig === false &&
                    index + 1 === selectedShareCompBrand.length &&
                    index > 0
                  "
                  class="removeLegend"
                  @click="removeLegend(index)"
                >
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--small u-cursor-pointer"
                    :icon="'cross'"
                  />
                </span>
                <rb-select
                  v-if="val"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :context="[index]"
                  :send-details="true"
                  :on-close="shareCompBrandSelected"
                  :options="
                    shareCompBrands
                      | array_differentiator(selectedShareCompBrand, 'title')
                  "
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                    >
                      <span
                        class="u-font-size-5"
                        data-cy="metricTitle"
                        loca
                        >{{ val }}</span
                      >
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>

                <metric
                  v-if="val && ((shareOfSearchData || {}).metrics || {})[val]"
                  :config="((shareOfSearchData || {}).metricsUnits || {})[val]"
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :data="((shareOfSearchData || {}).metrics || {})[val]"
                />
                <span
                  v-if="
                    val &&
                    val !== selectBrandPlaceholder &&
                    !((shareOfSearchData || {}).metrics || {})[val]
                  "
                  class="rb-metric--l u-spacing-mt-s"
                >
                  <span
                    class="primary_metric u-color-grey-light u-font-weight-bold"
                    >0.00%</span
                  >
                </span>
                <span
                  v-if="!val && !((shareOfSearchData || {}).metrics || {})[val]"
                  class="u-color-grey-light u-font-size-5"
                  >No Data</span
                >
              </div>

              <div
                v-if="
                  marketingClientConfig === false &&
                  selectedShareCompBrand.length < 4 &&
                  selectedShareCompBrand.length < shareCompBrands.length
                "
                class="add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"
              >
                <span @click="addMore">
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--large u-cursor-pointer"
                    :icon="'add-circle-outline'"
                  />
                </span>
              </div>
            </div>
            <chart
              id="shareOfSearchChart"
              class="u-flex-1 u-spacing-mt-l"
              :config="config.widgets['widget4'].body.chart"
              :data="widget4Data"
            />
          </div>
        </div>
      </card-widget>
    </div>
    <div
      v-if="pageError"
      class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import metric from '@/components/basic/metric';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import dataBars from '@/components/basic/dataBars';
import Vue from 'vue';
import cardWidget from '@/components/widgets/cardWidget';
import progressFiller from '@/components/basic/progressFiller';
import rbPagination from '@/components/basic/pagination';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import transformer from '@/utils/services/data-transformer';
import modal from '@/components/widgets/modal.vue';
import warningBanner from '@/components/widgets/banner.vue';
import { isMarketInsightsEnabled } from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
var oObject = {
  components: {
    chart,
    loader,
    cardWidget,
    metric,
    dataBars,
    progressFiller,
    rbPagination,
    rbFilterPanel,
    modal,
    warningBanner
  },
  beforeCreate() {
    const lsObj =
      JSON.parse(localStorage.getItem('search-rank-index-filters')) || {};
    // remove all the previous unused filters
    const whiteListKeys = ['profile_name', 'tags', 'keyword_type'];
    Object.keys(lsObj).forEach((key) => {
      if (whiteListKeys.indexOf(key) === -1) {
        delete lsObj[key];
      }
    });
    const profileName = Vue.options.filters.config_check(
      'feature.amsDefaultProfileName'
    );
    const filterOrderArr =
      JSON.parse(localStorage.getItem('search-rank-index-filters-order')) || [];
    this.$store.commit('SET_DEFAULT_PROFILE_NAME', profileName);
    const isAdvertisingEnabled =
      this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    if (isAdvertisingEnabled) {
      // if profile name doesn't exists in localStorage, then get from config and update localStorage
      if (
        profileName &&
        ((lsObj.profile_name && !lsObj.profile_name[0]) ||
          !Object.keys(lsObj).length)
      ) {
        lsObj.profile_name = [profileName];
      }
      if (
        lsObj.profile_name &&
        lsObj.profile_name[0] &&
        filterOrderArr.indexOf('profile_name') === -1
      ) {
        filterOrderArr.push('profile_name');
      }
    }
    localStorage.setItem('search-rank-index-filters', JSON.stringify(lsObj));
    localStorage.setItem(
      'search-rank-index-filters-order',
      JSON.stringify(filterOrderArr)
    );
    this.config = widgetsConfig.config();
  },
  mixins: [widgetsUtil],
  created() {
    if (!this.marketingClientConfig) {
      // remove y2 axis for sales only clients in share of search. (y2: currency for ams spend)
      delete this.config.widgets.widget4.body.chart.chartOptions.axis_format.y2;
    }
    this.$store.dispatch('getSearchPerfFilters');
    const where = {
      dimensionNameValueList: []
    };
    this.fetchFilters(
      'v3_sku_level_search_rank_with_transformed_metadata',
      undefined,
      undefined,
      where
    );
    this.$store.dispatch('setWidgetsConfigState', this.config);
  },
  mounted() {
    this.setChartHeight();
  },
  computed: {
    isMarketingInsightsAvailable() {
      return isMarketInsightsEnabled(this?.$store?.getters?.getProductConfig);
    },
    pageError() {
      const page = this.$store.getters.getSubPageError['share-of-voice'];
      return page === undefined ? false : page;
    },
    isIQTipPopupVisible() {
      return this.$store.getters.getPopupVisibility;
    },
    marketingClientConfig() {
      if (this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1) {
        this.shareOfVoiceTabs = ['Keywords', 'Brands'];
        return true;
      }
      return false;
    },
    keywordsTableConfigForSales() {
      if (!this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1) {
        this.config.widgets.keywordsTable.header.tooltip =
          this.config.widgets.keywordsTable.header.tooltipSales;
      }
      return this.config.widgets.keywordsTable;
    },
    getRetailerBrandName() {
      return this.$store.getters.getRetailerBrandName;
    },
    keywordsTableData() {
      this.calculateTableColumnWidth();
      return this.$store.getters.getKeywordsTableData;
    },
    brandsTableData() {
      return this.$store.getters.getBrandsTableData;
    },
    shareOfSearchData() {
      return this.$store.getters.getShareOfSearchData;
    },
    distributionData() {
      return this.$store.getters.getDistributionData;
    },
    searchPerformancePagination() {
      var obj = {
        total: this.$store.getters.getSearchPerformancePaginationTotal,
        limit: this.config.widgets.keywordsTable.body.APIConfig.limit
      };
      return obj;
    },
    clientBrandsData() {
      var clientBrands = [].concat(
        (this.brandsTableData[this.config.widgets.brandsTable.meta.key] || {})
          .rows || []
      );
      for (var i = 0; i < clientBrands.length; i++) {
        if (clientBrands[i]) {
          clientBrands[i] = clientBrands[i].client_data || {};
        }
      }
      return clientBrands;
    },
    brandsTableColumns() {
      var storeData =
        this.brandsTableData &&
        this.brandsTableData[this.config.widgets.brandsTable.meta.key];
      if (
        storeData &&
        storeData.completeTableData &&
        storeData.completeTableData.columns
      ) {
        return transformer.getColumnDefinition(
          storeData.completeTableData.columns,
          {
            brand: {
              component: 'tableSelect',
              params: {
                clientOptions: this.clientBrandsData,
                clientOnClose: this.clientBrandSelection,
                compOptions: this.compBrandsData,
                compOnClose: this.compBrandSelection
              }
            },
            metric: {
              component: 'metricDisplay'
            }
          }
        );
      } else {
        return {};
      }
    },
    transformedBrandsTableData() {
      var storeData =
        this.brandsTableData &&
        this.brandsTableData[this.config.widgets.brandsTable.meta.key];
      if (
        storeData !== undefined &&
        storeData.completeTableData === undefined
      ) {
        this.selectedClientBrandIndex = 0;
        this.selectedCompBrands = [];
      }
      if (storeData && storeData.rows && storeData.rows.length > 0) {
        var selectedBrandData = storeData.rows[this.selectedClientBrandIndex];
        this.compBrandsData = (selectedBrandData || {}).comp_data;
        if (this.selectedCompBrands.length === 0) {
          for (var i = 0; i < 5; i++) {
            if (this.compBrandsData[i]) {
              this.selectedCompBrands.push(this.compBrandsData[i]);
            }
          }
        }
        return [selectedBrandData.client_data].concat(this.selectedCompBrands);
      } else {
        return [];
      }
    },
    checkboxOrganicProducts: {
      get() {
        this.checkboxOrganicProductsProxy =
          this.$store.getters.getCheckboxesState.checkboxOrganicProducts;
        return this.$store.getters.getCheckboxesState.checkboxOrganicProducts;
      },
      set(val) {
        this.checkboxOrganicProductsProxy = val;
        this.updateShareOfSearchData();
      }
    },
    checkboxSponsoredProducts: {
      get() {
        this.checkboxSponsoredProductsProxy =
          this.$store.getters.getCheckboxesState.checkboxSponsoredProducts;
        return this.$store.getters.getCheckboxesState.checkboxSponsoredProducts;
      },
      set(val) {
        this.checkboxSponsoredProductsProxy = val;
        this.updateShareOfSearchData();
      }
    },
    checkboxSponsoredBrands: {
      get() {
        this.checkboxSponsoredBrandsProxy =
          this.$store.getters.getCheckboxesState.checkboxSponsoredBrands;
        return this.$store.getters.getCheckboxesState.checkboxSponsoredBrands;
      },
      set(val) {
        this.checkboxSponsoredBrandsProxy = val;
        this.updateShareOfSearchData();
      }
    },
    widget4Data: {
      get: function () {
        var legendsReset = false;
        var storeData = this.$store.getters.getShareOfSearchData || {};
        var widgetData = this.$store.getters.getShareOfSearchGraphData || {};
        if (widgetData === null) {
          return {};
        }
        var widgetDataLength = Object.keys(widgetData).length;
        if (
          this.selectedShareCompBrand !== null &&
          this.selectedShareCompBrand.length < 2
        ) {
          if (widgetDataLength > 0) {
            for (let wl = 0; wl < widgetDataLength; wl++) {
              this.selectedShareCompBrand.push(null);
              legendsReset = true;
              if (wl === 1) {
                break;
              }
            }
          }
        }
        var aReturn = [];
        if (widgetData && Object.keys(widgetData).length > 0) {
          this.shareClientBrands = [];
          this.shareCompBrands = [];
          for (var i in widgetData) {
            if (
              widgetData[i] &&
              widgetData[i].data &&
              widgetData[i].data.client_flag &&
              widgetData[i].data.client_flag === 'client'
            ) {
              if (i && i.length > 0) {
                this.shareClientBrands.push({
                  title: i
                });
              }
            } else {
              if (
                i &&
                i.length > 0 &&
                i.toLowerCase() !== 'unknown' &&
                i.toLowerCase() !== 'ams spend'
              ) {
                this.shareCompBrands.push({
                  title: i
                });
              }
            }
          }
        }

        if (
          widgetData &&
          Object.keys(widgetData).length > 0 &&
          !this.selectedFromDropdownShare
        ) {
          if (this.shareClientBrands.length > 0) {
            if (
              this.legendAdded === false &&
              (this.selectedShareClientBrand === null ||
                this.$store.getters.getShareOfSearchLegendReset === true)
            ) {
              this.selectedShareClientBrand = this.shareClientBrands[0].title;
            }
          } else {
            this.selectedShareClientBrand = null;
          }

          for (var ij = 0; ij < this.selectedShareCompBrand.length; ij++) {
            if (this.shareCompBrands.length > ij) {
              if (
                (legendsReset === true ||
                  this.$store.getters.getShareOfSearchLegendReset === true) &&
                this.legendAdded === false
              ) {
                this.selectedShareCompBrand[ij] =
                  this.shareCompBrands[ij].title;
              }

              if (this.legendAdded === true) {
                if (this.selectedShareCompBrand[ij] === null) {
                  this.selectedShareCompBrand[ij] =
                    this.shareCompBrands[ij].title;
                }
              }
            } else {
              this.selectedShareCompBrand[ij] = null;
            }
          }

          if (this.legendAdded === true) {
            this.legendAdded = false;
          }

          if (
            this.selectedShareCompBrand.length > this.shareCompBrands.length
          ) {
            var bPoint = -1;
            for (i = 0; i < this.selectedShareCompBrand.length; i++) {
              if (this.shareCompBrands[i] === undefined) {
                bPoint = i;
                break;
              }
            }
            this.selectedShareCompBrand = this.selectedShareCompBrand.slice(
              0,
              bPoint
            );
          } else if (
            this.selectedShareCompBrand.length === this.shareCompBrands.length
          ) {
            for (i = 0; i < this.shareCompBrands.length; i++) {
              this.selectedShareCompBrand[i] = this.shareCompBrands[i].title;
            }
          }
        }
        if (
          storeData &&
          storeData.load !== undefined &&
          storeData.load === false
        ) {
          this.selectedFromDropdownShare = false;
        }

        var colors = {};
        var colorIndex = 0;
        if (this.selectedShareClientBrand) {
          for (var jKey in widgetData[this.selectedShareClientBrand]) {
            if (jKey !== 'data') {
              aReturn.push(widgetData[this.selectedShareClientBrand][jKey]);
              if (!colors['0' + this.selectedShareClientBrand]) {
                colors['0' + this.selectedShareClientBrand] =
                  this.getColorPattern[colorIndex];
                colorIndex++;
              }
            }
          }
        }

        for (ij = 0; ij < this.selectedShareCompBrand.length; ij++) {
          if (
            this.selectedShareClientBrand ||
            this.selectedShareCompBrand[ij]
          ) {
            for (var j in widgetData[this.selectedShareCompBrand[ij]]) {
              if (j !== 'data') {
                aReturn.push(widgetData[this.selectedShareCompBrand[ij]][j]);
                if (!colors['1' + this.selectedShareCompBrand[ij]]) {
                  colors['1' + this.selectedShareCompBrand[ij]] =
                    this.getColorPattern[colorIndex];
                  colorIndex++;
                }
              }
            }
          }
        }

        if (this.marketingClientConfig === true && widgetData['ams spend']) {
          aReturn.push(widgetData['ams spend'].feed_date);
          aReturn.push(widgetData['ams spend'].ams_spend);
          colors['1ams spend'] = this.getColorPattern[colorIndex];
          colorIndex++;
        }

        this.shareOfSearchColors = colors;
        if (storeData && storeData.data) {
          storeData.data.data = aReturn;
          storeData.data.colors = colors;
          storeData.data.axes =
            this.config.widgets.widget4.body.chart.axesConf.axes;
        }
        return Vue.util.extend({}, storeData.data);
      }
    },
    getSovPageWiseMinMaxKey() {
      return this.$store.getters.getSovPageWiseMinMaxKey;
    }
  },
  data() {
    return {
      modalConfig: {
        bodyTitle: {
          text: 'Tip',
          highlightText: 'IQ',
          icon: 'light-bulb'
        },
        body: {
          text: 'The aggregated share metrics in this dashboard are best understood at a specific keyword group level.'
        },
        footer: {
          leftButton: {
            text: 'Try later',
            onClick: this.closeModal,
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Add Filter',
            onClick: this.openFilters,
            icon: 'filter',
            show: true
          }
        }
      },
      compBrandsData: [],
      selectedCompBrands: [],
      activeTab: 0,
      shareOfVoiceTabs: [],
      checkboxOrganicProductsProxy: null,
      checkboxSponsoredBrandsProxy: null,
      checkboxSponsoredProductsProxy: null,
      selectBrandPlaceholder: 'Select Comp Brand',
      selectedFromDropdown: false,
      selectedClientBrandIndex: 0,
      shareClientBrands: [],
      shareCompBrands: [],
      selectedShareClientBrand: null,
      filterState: {
        getter: 'getInsightsSearchPerfWarSelectedFilter',
        setter: 'setInsightsSearchPerfWarSelectedFilter'
      },
      selectedKeyWord: [],
      searchTableData: [],
      selectedShareCompBrand: [null, null],
      widget4LegendsFirstLoad: true,
      legendAdded: false,
      shareOfSearchColors: {},
      customKeywordTableObject: {
        search: {
          hoverRequired: true,
          hoverText: 'More details',
          hoverIcon: 'show-chart',
          hoverClickFunction: this.scrollToTimeMachine,
          tooltip: {
            tooltipMessage: 'View Amazon search results',
            tooltip_direction: 'bottom'
          }
        }
      },
      ishighlighted: false,
      filterKey: 0,
      filterInstance: null,
      brandsTableInstance: null,
      keywordsTableInstance: null
    };
  },
  methods: {
    navigateToMarketInsights() {
      this.$router.push({ name: 'market_insights' });
    },
    setBrandsTableInstance(instance) {
      this.brandsTableInstance = instance;
    },
    setKeywordsTableInstance(instance) {
      this.keywordsTableInstance = instance;
    },
    openFilters() {
      this.closeModal();
      this.filterInstance.openAddFilter();
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    downloadKeywordTable() {
      this.$refs.keywordsTable.downloadTable();
    },
    downloadBrandsTable() {
      this.$refs.brandsTable.downloadTable();
    },
    clientBrandSelection(context, selection) {
      if (selection?.length > 0) {
        var index = this.clientBrandsData.indexOf(selection[0]);
        this.selectedClientBrandIndex = index === -1 ? 0 : index;
        this.selectedCompBrands = [];
      }
    },
    compBrandSelection(context, selection, index) {
      if (selection?.length > 0) {
        this.selectedCompBrands[index - 1] = selection[0];
        this.selectedCompBrands = Vue.util.extend([], this.selectedCompBrands);
      }
    },
    widget4DateFilterApplied(type) {
      if (type === 'date') {
        if (this.widget4LegendsFirstLoad === false) {
          this.$store.dispatch('setShareOfSearchLegendDateApplied', true);
        }
        this.widget4LegendsFirstLoad = false;
      }
    },
    shareCompBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareCompBrand[context[0]] = selection[0].title;
        this.selectedShareCompBrand.splice(0, 0);
        this.selectedFromDropdownShare = true;
      }
    },
    shareClientBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareClientBrand = selection[0].title;
        this.selectedFromDropdownShare = true;
      }
    },
    setChartHeight() {
      var chart = document.getElementById('shareOfSearchChart');
      if (chart) {
        chart.style.height = (chart.clientWidth || 320) / 3 + 'px';
      }
    },
    scrollToTimeMachine(row) {
      this.$store.dispatch('fetchDependentWidgetsData', [
        { search: row.value }
      ]);
      var footers = document.getElementsByClassName('timeMachineWidget');
      footers[0].scrollIntoView({ behavior: 'smooth' });
      this.ishighlighted = true;
      setTimeout(() => {
        this.ishighlighted = false;
      }, 1000);
      this.$snackbar.open({
        message: '<div class="u-spacing-pv-xs">Keyword filter updated</div>',
        duration: 10000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    updateShareOfSearchData() {
      var whereClause = {
        checkboxOrganicProducts: this.checkboxOrganicProductsProxy,
        checkboxSponsoredBrands: this.checkboxSponsoredBrandsProxy,
        checkboxSponsoredProducts: this.checkboxSponsoredProductsProxy,
        apiConfig: this.config.widgets.widget4
      };
      this.$store.dispatch('setShareOfSearchCheckbox', whereClause);
    },
    calculateTableColumnWidth() {
      if (this.keywordsTableInstance) {
        this.keywordsTableInstance.adjustColumns();
      }
    },
    addMore() {
      this.legendAdded = true;
      this.selectedShareCompBrand.push(null);
    },
    removeLegend(index) {
      this.$store.dispatch('setShareOfSearchLegendReset', false);
      this.$delete(this.selectedShareCompBrand, index);
    },
    closeModal() {
      this.$store.commit('HIDE_POPUP_TO_SELECT_FILTERS');
    }
  },
  watch: {
    activeTab: {
      handler: function (value) {
        // make the columns to fit to the screen size if the table is brands.
        if (value === 1) {
          this.brandsTableInstance.adjustColumns();
        }
      }
    }
  }
};

export default oObject;
</script>
<style scoped lang="css">
.flex-row {
  margin-bottom: 0;
}
.el-table-column_1-vertical-align .el-table__row td[rowspan] {
  vertical-align: baseline !important;
  border-right: solid 1px #eee;
}
.performanceTable tr td:first-child {
  padding-left: 15px;
  padding-right: 0;
}
.performanceTable tr td:first-child .cell {
  padding-left: 0;
}
.add-legend-button.custom-chart-legend {
  border-top: 0 !important;
}
.removeLegend {
  position: absolute;
  right: 5px;
  top: 8px;
}
.custom-chart-legend:hover .removeLegend {
  display: flex;
}
.checkBoxContainer {
  width: 25%;
  margin-right: 16px;
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 #caccce;
  display: flex;
}
.checkBoxContainer .checkboxBlock {
  width: 90%;
}
.checkBoxContainer .adTags {
  background: palette(blue);
  color: #fff;
  padding: 4px;
  border-radius: 2px;
  font-weight: bold;
  padding-top: 4px;
  font-size: 0.9rem;
}
.checkBoxContainer:hover {
  box-shadow: 0 0 8px 0 #caccce;
}
.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.is-disabled:hover {
  background: inherit;
  color: inherit;
}
</style>
