<template>
  <div
    v-if="metadataLoad || load"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metdataError || error"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <div
    v-else-if="noData"
    class="intermediate-dimensions u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
  >
    <p>No data</p>
  </div>
  <cardContainer
    v-else
    :title="metadata.label"
    :action-icons="actionIcons"
    :last-updated-date="lastUpdatedDate"
    @download="handleDownload"
  >
    <template slot="footer-right">
      <FooterIcon
        v-if="isAnchored"
        icon="star"
        text="Client brand"
      />
    </template>
    <template
      slot="body"
      slot-scope="{}"
    >
      <div
        v-for="(chartData, index) in computedChartData"
        :key="index"
        class="u-display-flex u-flex-align-items-center"
      >
        <donutWithLegends
          class=""
          :chart-data="chartData"
          :external-color-maps="externalColorMaps.brandToColor"
          :size="'l'"
          :popup-title="'All Digital Shelves'"
          :popup-metadata="dataService.donutPopupData[index]"
          :is-title-clickable="false"
        >
          <section
            slot="between-title-donut"
            class="u-width-100 u-border-bottom u-border-width-s u-border-color-grey-xxx-light u-spacing-pb-xs u-display-flex u-flex-justify-content-flex-start"
          >
            <div
              v-if="dataService.additionalDigitalShelfData[index].totalKeywords"
              class="u-width-100 u-display-flex u-flex-align-items-center"
            >
              <rb-icon
                class="u-display-flex u-flex-0 rb-icon--medium u-spacing-mr-xs u-color-grey-lighter"
                :icon="'powered_by'"
              />
              <absoluteChange
                v-if="intradayCrawlingAvailable"
                metric="Multi Crawl Keywords:"
                :result="`${dataService.additionalDigitalShelfData[index].totalMultiCrawlKeywords.result} / ${dataService.additionalDigitalShelfData[index].totalKeywords.result}`"
              />
              <absoluteChange
                v-else
                metric="Keywords:"
                :result="
                  dataService.additionalDigitalShelfData[index].totalKeywords
                    .result
                "
              />
            </div>
          </section>
        </donutWithLegends>
      </div>
    </template>
  </cardContainer>
</template>

<script>
import cardContainer from '../organisms/card-container';
import loader from '@/components/basic/loader';
import donutWithLegends from '../molecules/donut-with-legends';
import HttpService from '@/utils/services/http-service';
import {
  downloadFile,
  convertDecimalLessThanZero
} from '@/components/pages/skuDetails/helper.js';
import { cloneDeep } from 'lodash';
import absoluteChange from '../atoms/absolute-change.vue';
import moment from 'moment';
import FooterIcon from '../atoms/footer-icons.vue';
import {
  requestDateReplacement,
  requestTimeReplacement,
  appendPartialArrays
} from './dashboard-service-utils';
import {
  accessKeys,
  appendAnchorBrandCondition,
  setDonutLegendMetadata,
  appendAnchorFilter
} from './common';
export default {
  components: {
    FooterIcon,
    cardContainer,
    loader,
    donutWithLegends,
    absoluteChange
  },
  props: {
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      default: null
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    sovDataGroups: {
      type: Object,
      default: () => ({})
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    multiCrawl: {
      type: Boolean,
      default: false
    },
    intradayCrawlingAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastUpdatedDate: '',
      actionIcons: [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with total Share of Voice by brand for selected shelves'
        }
      ],
      hoverIndex: null,
      metadata: {},
      data: {},
      downloadApi: {
        error: false,
        loading: false
      },
      devMetadata: {
        metadata: {
          sharesKey: {
            suffix: 'share_percentage'
          }
        },
        accessKeys: accessKeys()
      }
    };
  },
  computed: {
    isAnchored() {
      const { anchoredBrand } = this.getUnsavedSettings();
      return appendAnchorBrandCondition(anchoredBrand);
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    load() {
      return this?.dataService?.load;
    },
    error() {
      return this?.dataService?.error;
    },
    noData() {
      return this?.dataService?.noData;
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metdataError() {
      return this?.dataService?.metdataError;
    },
    computedChartData() {
      const { anchoredBrand } = this.getUnsavedSettings();
      return this.dataService.donutChartData.map((unitDigitalShelfArray) =>
        unitDigitalShelfArray.map((item) => {
          const returnItem = setDonutLegendMetadata(
            item,
            this.externalColorMaps?.brandToColor,
            anchoredBrand
          );
          if (appendAnchorBrandCondition(anchoredBrand)) {
            returnItem.iconCustomClass =
              item.clientFlag !== 'client' ? 'rb-icon-14' : null;
          } else {
            returnItem.iconCustomClass = 'rb-icon-14';
          }
          returnItem.pvp = convertDecimalLessThanZero(returnItem.pvp);
          return returnItem;
        })
      );
    },
    totalKeywordsMetric() {
      return this.multiCrawl ? 'total_keywords_intraday' : 'total_keywords';
    }
  },
  created() {
    this.init();
  },
  methods: {
    appendAnchorBrand(requestCopy) {
      const { anchoredBrand } = this.getUnsavedSettings();
      if (appendAnchorBrandCondition(anchoredBrand)) {
        requestCopy.operations.totalClientBrandsForDigitalShelf = 0;
        requestCopy.operations.anchoredBrand = anchoredBrand.title;
      }
    },
    getCurrentUnsaved() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'][
        this.widgetName
      ];
    },
    createBundleRequest(metricsList) {
      const bundle = {};

      for (const metric of metricsList) {
        if (this.metadata.metrics[metric]) {
          const { api, dataGroup } = this.metadata.metrics[metric];
          const requestCopy = this.createDataRequestPayload(
            api.request,
            metric,
            dataGroup
          );
          requestCopy.page = this.page;
          requestCopy.widget = this.widgetName;
          this.appendAnchorBrand(requestCopy);
          bundle[dataGroup] = requestCopy;
        }
      }
      return bundle;
    },
    getUnsavedSettings() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
        .settings;
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIcons = actionIconsCopy;
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const { downloadApiTemplate } = this.metadata;
          const { endPoint, service } = downloadApiTemplate;
          const unsavedSettings = this.getUnsavedSettings();
          const bundleRequestTemplate = this.metadata.metadata.dataAPI.request;
          const metric = unsavedSettings.selectedPlacement;
          bundleRequestTemplate.bundleDataGroupsRequest =
            this.createBundleRequest([
              metric,
              this.totalKeywordsMetric,
              ...(this.intradayCrawlingAvailable && !this.multiCrawl
                ? [this.totalMultiCrawlKeywordsMetric]
                : [])
            ]);
          bundleRequestTemplate.widget = this.widgetName;
          bundleRequestTemplate.page = this.page;
          const { data } = await HttpService.post(
            service,
            bundleRequestTemplate,
            {
              append: endPoint
            }
          );
          downloadFile(data.url);
          resolve();
        } catch (e) {
          reject(e);
          console.error('error');
        }
      });
    },
    handleMouseEnter(data) {
      this.hoverIndex = data;
    },
    handleMouseLeave() {
      this.hoverIndex = null;
    },
    getLocalWhereClause(dataGroup, unsavedSettings) {
      const globalWhereClause = cloneDeep(this.getGlobalWhereClause);
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause.time_range.startTime;
        where.time.to = globalWhereClause.time_range.endTime;
        where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
        where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      }
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      if (this.sovDataGroups[dataGroup]) {
        appendPartialArrays(
          where.dimensionNameValueList,
          unsavedSettings.selectedType,
          this.types
        );
      }
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      const { anchoredBrand } = this.getUnsavedSettings();
      appendAnchorFilter(where, anchoredBrand);
      return where;
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.lastUpdatedDate = moment(this.metadata?.calendar?.max_date).format(
          'LL'
        );
        this.totalMultiCrawlKeywordsMetric = 'total_keywords_multi_crawled';
        this.devMetadata.metadata = {
          ...this.devMetadata.metadata,
          totalKeywordsMetric: this.totalKeywordsMetric,
          totalMultiCrawlKeywordsMetric: this.totalMultiCrawlKeywordsMetric
        };
        this.getData();
      } catch (e) {
        console.error('e', e);
      }
    },
    createDataRequestPayload(request, metric, dataGroup) {
      const requestCopy = JSON.parse(JSON.stringify(request));
      const unsavedSettings = this.getUnsavedSettings();
      requestCopy.metricsList = [metric];
      const whereClause = this.getLocalWhereClause(dataGroup, unsavedSettings);
      requestDateReplacement(requestCopy.where, whereClause);
      if (this.multiCrawl) {
        requestTimeReplacement(requestCopy.where, whereClause);
      }
      requestCopy.where.dimensionNameValueList = [
        ...whereClause.dimensionNameValueList,
        ...(metric === this.totalMultiCrawlKeywordsMetric
          ? request.where.dimensionNameValueList
          : [])
      ];
      return requestCopy;
    },
    async getData() {
      try {
        this.dataService.donutChartData = [];
        this.dataService.donutPopupData = [];
        const unsavedSettings = this.getUnsavedSettings();
        const bundleRequestTemplate = this.metadata.metadata.dataAPI.request;
        const metric = unsavedSettings.selectedPlacement;
        bundleRequestTemplate.bundleDataGroupsRequest =
          this.createBundleRequest([
            metric,
            this.totalKeywordsMetric,
            ...(this.intradayCrawlingAvailable && !this.multiCrawl
              ? [this.totalMultiCrawlKeywordsMetric]
              : [])
          ]);
        const apiData = await this.dataService.getData(
          bundleRequestTemplate,
          this
        );
        const { donutChartData, donutPopupData, additionalDigitalShelfData } =
          this.dataService.transformApiData(
            apiData.entityData,
            this.devMetadata,
            metric,
            'name',
            undefined,
            this.intradayCrawlingAvailable
          );
        this.$emit('newDigitalShelfData', donutChartData);
        this.dataService.donutChartData = donutChartData;
        this.dataService.donutPopupData = donutPopupData;
        this.dataService.additionalDigitalShelfData =
          additionalDigitalShelfData;
      } catch (e) {
        console.error('e', e);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.intermediate-dimensions {
  height: 307px;
}
.dimensions {
  min-width: 8px;
  min-height: 8px;
}
.box-shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.dot {
  min-height: 4px;
  min-width: 4px;
  max-height: 4px;
  max-width: 4px;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
