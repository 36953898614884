<template>
  <div>
    <div
      v-if="loader"
      class="u-position-fixed widget--full-screen u-display-flex u-flex-align-items-center u-flex-justify-content-center u-bg-color-grey-white"
    >
      <div
        class="u-display-flex u-flex-direction-column u-width-100 u-flex-1 u-flex-justify-content-center u-flex-align-items-center"
      >
        <img
          style="max-width: 24%"
          :src="imagePath"
        />
        <div class="u-spacing-mt-xl u-font-size-4 u-font-style-italic">
          {{ loaderText }}
        </div>
        <div
          class="u-spacing-mt-s u-spacing-mb-s u-width-30"
          :class="loader ? 'export-loader' : ''"
        />
      </div>
    </div>
    <div
      v-if="loader"
      class="nav-h-primary u-width-100 export-header"
    >
      <div
        class="u-flex-1 u-spacing-p-m u-display-flex u-flex-align-items-bottom"
      >
        <div
          class="wrap__icon--logo u-display-inline-flex u-height-100 u-flex-align-items-center"
        >
          <div>
            <img
              style="width: 125px"
              src="/images/logo-blue-bg.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import domtoimage from './dom-to-image.js';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import { saveAs } from 'file-saver';

export default {
  components: {
    loader
  },
  data() {
    return {
      loader: false,
      imagePath: null,
      loaderText: null
    };
  },
  created() {
    eventBus.$on('triggerExport', async (config) => {
      this.loader = true;
      this.imagePath = config.imagePath;
      this.loaderText = config.loaderText;
      setTimeout(async () => {
        await this.exporter(config);
      });
    });
    eventBus.$on('triggerExportImage', async (config) => {
      this.loader = true;
      this.imagePath = config.imagePath;
      this.loaderText = config.loaderText;
      setTimeout(async () => {
        await this.expoterPng(config);
      });
    });
  },
  methods: {
    async templateBuilder({ backgroundColor, template }, doc) {
      const templateArray = [];
      if (template.header) {
        const header = document.querySelector('.export-header');
        if (!header.style.backgroundColor) {
          header.style.backgroundColor = backgroundColor;
        }
        const image = await domtoimage.toPng(header, { quality: 1 });
        const imgProps = doc.getImageProperties(image);
        templateArray.push({
          element: header,
          imageUrl: image,
          header: true,
          imgProps
        });
      }
      for (let i = 0; i < template.elementSelectors.length; i++) {
        const element = document.querySelector(
          template.elementSelectors[i].selector
        );
        if (!element.style.backgroundColor) {
          element.style.backgroundColor = backgroundColor;
        }
        const imageUrl = await domtoimage
          .toPng(element, { quality: 1 })
          .catch((error) =>
            console.error(
              'Error creating template, download could not be completed.',
              error
            )
          );
        const imgProps = doc.getImageProperties(imageUrl);
        const elementMap = {
          element: element,
          imageUrl: imageUrl,
          custom: true,
          imgProps
        };
        templateArray.push(elementMap);
      }
      return templateArray;
    },
    async getAllImageUrls({ backgroundColor, elementSelector }) {
      const divs = document.querySelectorAll(elementSelector);
      const urlsPromise = [];
      for (let i = 0; i < divs.length; i++) {
        urlsPromise.push(
          new Promise(async (resolve, reject) => {
            const imageUrl = await domtoimage
              .toPng(divs[i], { quality: 1 })
              .catch((error) =>
                console.error(
                  'Error fetching images, download could not be completed.',
                  error
                )
              );
            resolve({
              imageUrl,
              element: divs[i]
            });
          })
        );
      }
      const results = await Promise.all(urlsPromise);
      return results;
    },
    async exporter(templateConfig) {
      const orientation = templateConfig?.template?.orientation || 'landscape';
      const doc = new jsPDF({
        orientation: orientation
      });
      this.hidingElements();
      const templateArray = await this.templateBuilder(templateConfig, doc);
      const imageElementsArray = await this.getAllImageUrls(templateConfig);
      for (let i = 0; i < imageElementsArray.length; i++) {
        this.convertIntoPdf(doc, {
          templateArray,
          imageElement: imageElementsArray[i]
        });
      }
      doc.deletePage(1);
      this.showElements();
      doc.save(templateConfig.fileName);
      this.loader = false;
    },
    async expoterPng(templateConfig) {
      this.hidingElements();
      const header = document.querySelector(templateConfig.elementSelector);
      if (!header.style.backgroundColor) {
        header.style.backgroundColor = templateConfig.backgroundColor;
      }
      var scale = templateConfig.scale || 1;
      domtoimage
        .toBlob(header, {
          width: header.clientWidth * scale,
          height: header.clientHeight * scale,
          style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left'
          }
        })
        .then((blob) => {
          saveAs(blob, templateConfig.fileName);
          this.showElements();
          eventBus.$emit(templateConfig.successEventName);
          this.loader = false;
        });
    },
    hidingElements() {
      const displayNoneElements = document.querySelectorAll(
        '[expoter="display-none"]'
      );
      for (let i = 0; i < displayNoneElements.length; i++) {
        displayNoneElements[i].style.display = 'none';
      }
      const visibilityHiddenElements = document.querySelectorAll(
        '[expoter="visibility-hidden"]'
      );
      for (let i = 0; i < visibilityHiddenElements.length; i++) {
        visibilityHiddenElements[i].style.visibility = 'hidden';
      }
    },
    showElements() {
      const displayNoneElements = document.querySelectorAll(
        '[expoter="display-none"]'
      );
      for (let i = 0; i < displayNoneElements.length; i++) {
        displayNoneElements[i].style.display = 'block';
      }
      const visibilityHiddenElements = document.querySelectorAll(
        '[expoter="visibility-hidden"]'
      );
      for (let i = 0; i < visibilityHiddenElements.length; i++) {
        visibilityHiddenElements[i].style.visibility = 'visible';
      }
    },
    async convertIntoPdf(doc, { templateArray, imageElement }) {
      let totalImgHeight = 0;
      const imgProps = doc.getImageProperties(imageElement.imageUrl);
      totalImgHeight += imgProps.height;
      let isHeaderThere = false;
      for (let i = 0; i < templateArray.length; i++) {
        if (templateArray[i].header) {
          isHeaderThere = true;
          continue;
        }
        totalImgHeight += templateArray[i].imgProps.height;
      }
      const imageWidth = 310;
      const pdfWidth = imageWidth;

      let totalImageHeight = (totalImgHeight * imageWidth) / window.innerWidth;
      if (isHeaderThere) {
        totalImageHeight += 10;
      }
      console.log(totalImageHeight);
      // totalImageHeight = 400;
      doc.addPage([totalImageHeight, pdfWidth]);
      const actualPdfWidth = doc.internal.pageSize.getWidth();
      const actuaPdfHeight = doc.internal.pageSize.getHeight();
      let totalHeightConsumed = 0;
      for (let i = 0; i < templateArray.length; i++) {
        let imageHeight =
          (templateArray[i].imgProps.height * actualPdfWidth) /
          window.innerWidth;
        if (templateArray[i].header) {
          imageHeight = 10;
        }
        doc.addImage(
          templateArray[i].imageUrl,
          'PNG',
          0,
          totalHeightConsumed,
          actualPdfWidth,
          imageHeight,
          undefined,
          'FAST'
        );
        totalHeightConsumed += imageHeight;
      }
      // const imageHeight = (imgProps.height * actualPdfWidth) / imgProps.width;
      const imageHeight = actuaPdfHeight - totalHeightConsumed;
      doc.addImage(
        imageElement.imageUrl,
        'PNG',
        0,
        totalHeightConsumed,
        imageWidth,
        imageHeight,
        undefined,
        'FAST'
      );
    }
  }
};
// 317 288
// 1189 1399
</script>
<style scoped lang="css">
.export-header {
  height: 56px;
  display: flex;
}
.export-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.export-loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 95%;
  height: 4px;
  background-color: #007cf6;
  animation: loading 20s linear;
}
@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 95%;
  }
}
</style>
