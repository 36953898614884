export const getMetricData = (selectedCardLabel, metricData, seprator = '') => {
  let tempObj = {};
  metricData?.forEach((item) => {
    if (
      item.name === selectedCardLabel &&
      (!seprator.length || item.name.includes(seprator))
    ) {
      tempObj = getTagDataSet1(item, selectedCardLabel);
    }
  });
  return tempObj;
};

export const getTagDataSet1 = (data, mapping) => {
  var oReturn = {};
  let tag2Data = 0;
  if ((data.PVP || {})[`PVP_${mapping}`]) {
    tag2Data = (data.PVP || {})[`PVP_${mapping}`];
  } else if ((data.PVP || {})['PVP_' + mapping] === 0) {
    tag2Data = 0;
  } else {
    tag2Data = undefined;
  }
  oReturn = {
    tag1: data.RESULT[mapping],
    tag2: tag2Data
  };
  // console.log(tag2Data);
  return oReturn;
};

export const getSecondaryType = (selectedType) => {
  if (selectedType.includes('_case')) {
    let selectedDollar = selectedType.split('_');
    selectedDollar.pop();
    selectedDollar.push('dollar');
    selectedDollar = selectedDollar.join('_');
    return selectedDollar;
  } else {
    let selectedCase = selectedType.split('_');
    selectedCase.pop();
    selectedCase.push('case');
    selectedCase = selectedCase.join('_');
    return selectedCase;
  }
};
export const sortMetric = (metricData, cards) => {
  metricData.sort((a, b) => b.RESULT[b.name] - a.RESULT[a.name]);
  const sortedArray = [];
  metricData.map((data) => {
    const obj = cards.find((item) => item.alias === data.alias);
    if (obj && Object.keys(obj).length) sortedArray.push(obj);
  });
  return sortedArray;
};
