<template>
  <div class="group-by-chart-legends">
    <div
      class="u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-xl u-spacing-pb-s"
    >
      <el-tooltip
        placement="bottom-start"
        :content="
          isGroupBySelected
            ? 'Uncheck to view time series of aggregate metrics'
            : 'Check to view timeseries of available Groups'
        "
        :visible-arrow="false"
      >
        <rb-switch
          :true-value="true"
          :false-value="false"
          :value="isGroupBySelected"
          :native-value="isGroupBySelected"
          @input="groupByToggled"
        />
      </el-tooltip>
      <!-- <span class="u-color-grey-lighter u-font-size-5 u-font-weight-600">
        Group by :
      </span> -->
      <rb-dropdown
        position="is-bottom-right"
        class="u-spacing-ml-xs"
        :class="{ 'disabled u-pointer-events-none': !isGroupBySelected }"
        :disabled="!isGroupBySelected"
      >
        <div
          slot="trigger"
          ref="selectGroupByTrigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <div
            class="u-flex-1 u-font-size-5 u-font-weight-600 u-text-overflow-ellipsis"
          >
            {{ selectedGroupBy.label }}
          </div>
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
        <rb-dropdown-item
          v-for="(item, index) in availableGroupBy"
          :key="index"
          @click="onGroupByChange(item)"
        >
          {{ item.label }}
        </rb-dropdown-item>
        <slot
          :type="'group by'"
          name="add-metric-footer"
        />
      </rb-dropdown>
    </div>

    <template v-if="hasErrorOccurred">
      <div class="u-text-align-center u-spacing-pv-l u-spacing-ph-l">
        <span>Unable to Fetch Data</span>
      </div>
    </template>

    <div
      v-else-if="isGroupBySelected"
      class="u-display-flex u-flex-wrap-yes u-flex-align-items-flex-start u-width-100"
    >
      <template v-if="isGroupByMetricLoading">
        <div class="u-display-flex u-flex-wrap-yes">
          <shimmer
            v-for="(item, index) in shimmerConfigArray"
            :key="index"
            :height="item[0]"
            :width="item[1]"
            class="u-spacing-ph-s u-spacing-pv-xs u-spacing-mr-m u-spacing-mb-m u-flex-align-self-center"
          />
        </div>
      </template>
      <template v-else>
        <div
          v-if="totalEntityCount === 0"
          class="u-text-align-center u-spacing-p-l u-width-100"
        >
          <span>
            No entities available for this group by!
            <span
              class="u-cursor-pointer u-color-blue-base u-font-weight-600"
              @click="openGroupDropdown"
            >
              Click to change the group by
            </span>
          </span>
        </div>
        <div
          v-for="(key, index) in localMetricDisplayList"
          v-else
          :key="key"
          :val="key"
          class="groupby-metric u-spacing-ph-s u-spacing-pv-xs u-spacing-mr-m u-spacing-mb-m u-display-flex"
          :style="getMetricStyle(key)"
          @click.stop="selectNewMetric(key, index)"
        >
          <img
            v-if="skuInfo(key)"
            :src="skuInfo(key).image_url || skuInfo(key).latest_image_url"
            alt=""
            height="40px"
            width="40px"
          />
          <div>
            <el-tooltip
              :disabled="!skuDescription(key)"
              placement="bottom-start"
              :content="skuDescription(key)"
            >
              <rb-select-v2
                :key="rbSelectKey"
                :options="fetchedMetricList"
                :on-close="metricSelected"
                :search-enabled="true"
                :search-listener="fetchOptions"
                :client-search="false"
                :send-details="true"
                :context="[index]"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer u-color-grey-mid-light u-font-size-4 overflow-styles u-display-block"
                >
                  {{ key }}
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                    :icon="'caret-down'"
                  />
                </div>

                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                  >
                    <span class="u-spacing-mr-m">{{ option.label }} abc</span>
                    <metric
                      :hide-zero="true"
                      size="l"
                      class="u-display-inline-flex"
                      :config="metricConfig"
                      :data="mergedMetricData[option.label]"
                      :class="'rb-metric--l rb-metric'"
                    />
                  </div>
                </template>
              </rb-select-v2>
            </el-tooltip>
            <metric
              :hide-zero="true"
              size="l"
              class="u-display-inline-flex u-spacing-mt-s"
              :config="metricConfig"
              :data="mergedMetricData[key]"
            />
          </div>
        </div>
        <template v-if="totalEntityCount - localMetricDisplayList.length > 0">
          <div
            class="u-spacing-ph-s u-spacing-pv-xs u-spacing-mr-m u-spacing-mb-m u-flex-align-self-center u-color-grey-mid-light"
          >
            & {{ totalEntityCount - localMetricDisplayList.length }}
            {{ selectedGroupBy.label }} (s)
          </div>
          <div
            v-if="localMetricDisplayList.length <= maximumMetricsCount"
            class="groupby-metric--add u-border-color-grey-xxx-light u-display-flex u-flex-align-items-center u-spacing-ph-m u-spacing-pv-xs u-spacing-mb-m"
          >
            <rb-select-v2
              :key="rbSelectKey"
              :options="fetchedMetricList"
              :search-enabled="true"
              :search-listener="fetchOptions"
              :client-search="false"
              :on-close="addMetric"
              :send-details="true"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-cursor-pointer u-color-grey-lighter"
              >
                <rb-icon
                  icon="add-circle-fill"
                  class="rb-icon--small u-spacing-mr-xs u-color-grey-lighter"
                />
                <span
                  >Add
                  <span class="u-text-case-lower">
                    {{ selectedGroupBy.label }}
                  </span></span
                >
              </div>

              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                >
                  <span class="u-spacing-mr-m">{{ option.label }} abcd</span>
                  <metric
                    :hide-zero="true"
                    size="'14-12'"
                    class="u-display-inline-flex"
                    :config="metricConfig"
                    :data="mergedMetricData[option.label]"
                  />
                </div>
              </template>
            </rb-select-v2>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';
import loader from '@/components/basic/loader';
import popupComponent from '@/components/basic/dialog/popupComponent.vue';
import { eventBus } from '@/utils/services/eventBus';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import shimmer from '@/components/basic/shimmer';
import { inject } from '@vue/composition-api';

export default {
  components: {
    metric,
    loader,
    shimmer,
    RbSelectV2,
    popupComponent
  },
  props: {
    skuKeysList: {
      type: Array,
      default: () => [
        'asin',
        'sku',
        'dsp_products_sku',
        'upc',
        'product_id',
        'product_code',
        'sku_id'
      ]
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Object,
      default: () => ({})
    },
    isGroupBySelected: {
      type: Boolean,
      default: false
    },
    availableGroupBy: {
      type: Array,
      default: () => []
    },
    selectedGroupBy: {
      type: Object,
      default: () => {
        // return {
        //   label: 'Business Model',
        //   key: 'BusinessModel'
        // };
      }
    },
    selectedSorting: {
      type: Object,
      default: () => {}
    },
    totalEntityCount: {
      type: Number,
      default: 0
    },
    masterMetricColor: {
      type: String,
      default: ''
    },
    masterMetric: {
      type: Object,
      default: () => {}
    },
    isGroupByMetricLoading: {
      type: Boolean,
      default: true
    },
    hasErrorOccurred: {
      type: Boolean,
      default: false
    },
    isPVPTimeseriesEnabled: {
      type: Boolean,
      default: false
    },
    colorPattern: {
      type: Array,
      default: () => [
        '#ffa800',
        '#bd10e0',
        '#ff6072',
        '#97cc04',
        '#23b5d3',
        '#f5d908',
        '#ff909d',
        '#ffc24c',
        '#d158ea',
        '#f8e552',
        '#b6dc4f',
        '#65cce1'
      ]
    },
    maximumMetricsCount: {
      type: Number,
      default: 12
    },
    addMoreMetricsFunc: {
      type: Function,
      default: null
    }
  },
  setup() {
    const shownGroupByList = inject('shownGroupByList');
    return {
      shownGroupByList
    };
  },
  data() {
    return {
      sortingSelected: {},
      localMetricDisplayList: [],
      selectedMetrics: [],
      skipSortingFetch: false,
      showSortingPopup: false,
      rbSelectKey: 0,
      fetchedMetricList: [],
      mergedMetricData: {},
      shimmerConfigArray: [
        ['60px', '150px'],
        ['60px', '150px'],
        ['60px', '150px'],
        ['60px', '150px'],
        ['60px', '150px'],
        ['22px', '120px'],
        ['60px', '150px']
      ]
      //   sortingOptions: [
      //     {
      //       label: 'Absolute descending',
      //       icons: ['sort-desc'],
      //       direction: 'DESC'
      //     },
      //     {
      //       label: 'Absolute ascending',
      //       icons: ['sort-asc'],
      //       direction: 'ASC'
      //     },
      //     {
      //       label: 'Change descending',
      //       icons: ['change', 'sort-desc'],
      //       sortByChange: true,
      //       direction: 'DESC'
      //     },
      //     {
      //       label: 'Change ascending',
      //       icons: ['change', 'sort-asc'],
      //       sortByChange: true,
      //       direction: 'ASC'
      //     }
      //   ]
    };
  },
  computed: {
    sortTooltipOptions() {
      return {
        html: '#sorting-popup',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        onShow: this.onShow,
        onHide: this.onHide,
        popperOptions: {
          Defaults: {
            onCreate: this.onTippyCreate
          }
        }
      };
    },
    skuInfo() {
      return (asin) => {
        if (this.skuKeysList.includes(this.selectedGroupBy.key)) {
          const requiredSKU = window.ciqNamespace.catalogAsins;
          return requiredSKU[asin];
        }
        return null;
      };
    },
    computedColorPattern() {
      return this.colorPattern.filter((el) => el !== this.masterMetricColor);
    },
    metricColors() {
      const _colors = {};
      var colors = this.computedColorPattern;
      for (let i = 0; i < this.localMetricDisplayList.length; i++) {
        _colors[this.localMetricDisplayList[i]] = colors[i];
      }
      this.$emit('groupByMetricColors', _colors);
      return _colors;
    },
    // sortingApplied() {
    //   this.showSortingPopup = false;

    //   if (!this.masterMetric) {
    //     this.skipSortingFetch = true;
    //     return [];
    //   }
    //   const direction = this.sortingSelected.direction;
    //   const prefix = this.sortingSelected.sortByChange
    //     ? this.masterMetric.metadata.unit === 'PERCENTAGE'
    //       ? 'pvp_diff_'
    //       : 'pvp_'
    //     : '';
    //   const sortingApp = {
    //     dimension: prefix + this.masterMetric.keyName,
    //     direction
    //   };
    //   if (direction) return [sortingApp];
    //   return [];
    // },
    // getSortingDisplayText() {
    //   return `${this.sortingSelected.sortByChange ? 'Change in ' : ''}${
    //     this.sortingSelected.direction === 'ASC' ? 'ascending' : 'descending'
    //   } order`;
    // },
    getMetricStyle() {
      return (key) => {
        if (this.isPVPTimeseriesEnabled) {
          return {
            border: this.selectedMetrics.includes(key)
              ? '1px solid ' + this.metricColors[key]
              : '',
            backgroundColor: this.selectedMetrics.includes(key)
              ? this.metricColors[key] + '10'
              : 'transparent'
          };
        } else {
          return {
            borderTop: this.selectedMetrics.includes(key)
              ? '3px solid ' + this.metricColors[key]
              : '3px solid transparent',
            backgroundColor: 'transparent'
          };
        }
      };
    },
    selectedMetricsCount() {
      return this.localMetricDisplayList.length > 5
        ? this.localMetricDisplayList.length
        : 5;
    },
    skuDescription() {
      return (asin) =>
        this.skuInfo(asin)?.asin_name || this.skuInfo(asin)?.sku_name || '';
    }
  },
  watch: {
    localMetricDisplayList(newVal) {
      this.shownGroupByList = newVal;
    },
    metricData: {
      handler(newVal) {
        if (newVal) {
          const sortedMetricsList = Object.entries(newVal)
            .sort((a, b) => a[1].order - b[1].order)
            .map((e) => e[0]);
          this.localMetricDisplayList = sortedMetricsList;
          this.selectedMetrics = [];
          this.localMetricDisplayList.forEach((key, index) => {
            this.selectNewMetric(key, index);
          });
          this.mergedMetricData = { ...newVal };
          this.fetchedMetricList = this.computeMetricsList(newVal);
        }
      },
      immediate: true
    },
    selectedMetrics(newVal) {
      const dimensionNameValueList = this.constructDimensionsList(newVal);
      if (this.isGroupBySelected) {
        this.$emit('metricAdded', {
          dimensionNameValueList,
          groupBy: this.selectedGroupBy,
          sorting: this.sortingApplied,
          metric: this.masterMetric
        });
      }
    },
    // masterMetric(newVal, oldVal) {
    //   if (
    //     !newVal ||
    //     !oldVal ||
    //     newVal?.label === oldVal?.label ||
    //     !this.isGroupBySelected
    //   )
    //     return;
    //   this.$emit('groupByChanged', {
    //     isGroupBy: this.isGroupBySelected,
    //     groupBy: this.selectedGroupBy,
    //     // sorting: this.sortingApplied,
    //     metric: this.masterMetric
    //   });
    // },
    // sortingSelected() {
    //   if (!this.isGroupBySelected) return;
    //   if (this.skipSortingFetch) {
    //     this.skipSortingFetch = false;
    //     return;
    //   }
    //   this.groupByToggled(true);
    // },
    isPVPTimeseriesEnabled() {
      this.selectedMetrics = this.selectedMetrics.slice(0, 1);
    }
  },
  // created() {
  //   this.sortingSelected =
  //     this.sortingOptions.find(
  //       (el) =>
  //         el.direction === this.selectedSorting.direction &&
  //         el.sortByChange === this.selectedSorting.sortByChange
  //     ) || {};
  // },
  inject: {
    fetchSearchedEntities: {
      type: Function,
      default: function () {}
    }
  },
  mounted() {
    eventBus.$on('emitGroupByChanged', () => {
      this.refetchGroupByData();
    });
  },
  destroyed() {
    eventBus.$off('emitGroupByChanged');
  },
  methods: {
    addMoreGroups() {
      this.addMoreMetricsFunc('group by');
    },
    openGroupDropdown() {
      setTimeout(() => {
        this.$refs?.selectGroupByTrigger.click();
      }, 200);
    },
    computeMetricsList(metricData) {
      const metrics = Object.keys(metricData)
        .map((el) => ({
          label: el,
          value: el
        }))
        .filter((el) => !this.localMetricDisplayList.includes(el.label));
      return metrics;
    },
    async fetchOptions(searchTerm) {
      const dimensionName = [
        {
          dimensionName: this.skuKeysList.includes(this.selectedGroupBy.key)
            ? 'search'
            : this.selectedGroupBy.key,
          dimensionValue: searchTerm,
          operator: 'ILIKE'
        }
      ];
      if (!searchTerm) {
        this.fetchedMetricList = this.computeMetricsList(this.metricData);
      } else {
        const searchedMetricData = await this.fetchSearchedEntities(
          dimensionName,
          this.masterMetric,
          this.selectedGroupBy.key,
          this.sortingApplied
        );

        this.mergedMetricData = {
          ...this.mergedMetricData,
          ...searchedMetricData
        };
        this.fetchedMetricList = this.computeMetricsList(searchedMetricData);
      }
    },
    closeIconClick() {
      this.showSortingPopup = false;
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.showSortingPopup = true;
    },
    onTippyCreate(event) {
      this.tippyInstance = event;
    },
    onHide(instance) {
      this.showSortingPopup = false;
    },
    resetRbSelect() {
      this.fetchedMetricList = this.computeMetricsList(this.metricData);
      this.rbSelectKey = Math.random();
    },
    metricSelected(context, metric) {
      this.resetRbSelect();
      if (!metric?.length) return;
      // To reset sorting and not apply empty sorting
      this.skipSortingFetch = true;
      this.resetSorting();

      const index = context[0];
      const newMetricDisplayList = this.localMetricDisplayList;
      newMetricDisplayList[index] = metric[0].label;
      this.$set(this.localMetricDisplayList, newMetricDisplayList);
      if (this.isPVPTimeseriesEnabled) {
        this.selectedMetrics = [metric[0].label];
      } else {
        const filteredList = this.selectedMetrics.filter((el) =>
          newMetricDisplayList.includes(el)
        );
        this.selectedMetrics = [...filteredList, metric[0].label];
      }

      const dimensionNameValueList = this.constructDimensionsList(
        this.selectedMetrics
      );
      this.$emit('metricChanged', {
        dimensionNameValueList,
        groupBy: this.selectedGroupBy,
        metric: this.masterMetric
      });
    },
    addMetric(context, metric) {
      this.resetRbSelect();
      if (
        !metric?.length ||
        this.localMetricDisplayList.includes(metric?.[0]?.label)
      ) {
        return;
      }
      // To reset sorting and not apply empty sorting
      this.skipSortingFetch = true;
      this.resetSorting();
      const newMetricDisplayList = this.localMetricDisplayList;
      newMetricDisplayList.push(metric[0].label);
      this.$set(this.localMetricDisplayList, newMetricDisplayList);

      if (this.isPVPTimeseriesEnabled) {
        this.selectedMetrics = [metric[0].label];
      } else {
        this.selectedMetrics.push(metric[0].label);
      }

      const dimensionNameValueList = this.constructDimensionsList(
        this.selectedMetrics
      );
    },
    refetchGroupByData() {
      if (this.isGroupBySelected) {
        this.groupByToggled(true);
      }
    },
    selectNewMetric(key, index) {
      const metricIndex = this.selectedMetrics.findIndex((el) => el === key);

      if (metricIndex !== -1) {
        if (this.selectedMetrics.length === 1) return;
        this.selectedMetrics.splice(metricIndex, 1);
      } else {
        if (this.isPVPTimeseriesEnabled) this.selectedMetrics = [key];
        else this.selectedMetrics.push(key);
      }
    },
    groupByToggled(value) {
      console.log('PO split value: ', value);
      this.$emit('groupByChanged', {
        isGroupBy: !!value,
        groupBy: this.selectedGroupBy,
        selectedMetrics: this.selectedMetric,
        sorting: this.sortingApplied,
        metric: this.masterMetric
      });
    },
    constructDimensionsList(metricList) {
      const dimensionList = metricList.map((el) => {
        const dimensionObj = {
          dimensionName: this.selectedGroupBy.key,
          dimensionValue: this.mergedMetricData[el].isUndefined ? null : el
        };
        if (this.mergedMetricData[el].isUndefined) {
          dimensionObj.operator = 'IS_NULL';
        }

        return dimensionObj;
      });

      return dimensionList;
    },
    resetSorting() {
      this.sortingSelected = {};
    }
  }
};
</script>

<style lang="css">
.group-by-chart-legends-popup .rb-control-label {
  padding-left: 4px !important;
}

.group-by-chart-legends .groupby-metric {
  box-shadow: 0 0 4px 0 #caccce;
  min-width: 150px;
  gap: 1rem;
  cursor: pointer;
  padding: 1.6rem 0.8rem;
  border-width: 3px;
  border-radius: 2px;
  margin-right: 1.6rem;
}
.group-by-chart-legends .groupby-metric--add {
  align-self: stretch;
  border-radius: 2px;
  border-style: dashed;
  border-width: 1px;
  cursor: pointer;
}
.group-by-chart-legends .groupby-metric--add:hover {
  border-color: #4b5158;
}
.group-by-chart-legends .groupby-metric--add:hover * {
  color: #4b5158;
}
.group-by-chart-legends .groupby-metric:hover {
  box-shadow: 0 0 8px 0 #caccce;
}
.group-by-chart-legends .sorting-wrapper {
  border-radius: 13.5px;
  border: solid 1px #e9eaeb;
}
.group-by-chart-legends .u-border-style-dashed {
  border-style: dashed;
}
.group-by-chart-legends .add-metric-footer {
  width: 240px;
}
</style>
