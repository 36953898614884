export const getSotOptionsConfigs = (sotType) => {
  return {
    title: `Get your initial ${sotType} "Source of Truth" data`,
    subTitle: `Pick any one data source to generate an initial ${sotType} "Source of Truth"`,
    availableOptions: [
      {
        key: 'pim',
        icon: 'powered_by',
        name: 'Product Information Management "PIM" system',
        about: `CommerceIQ can connect with your PIM system such as salsify or syndigo to automatically pull the latest ${sotType} data for all your items.`,
        isRecommended: false,
        disabled: true,
        showUpcomingBadge: true,
        showLearnMore: false
      },
      {
        key: 'autogenerate',
        icon: 'powered_by',
        name: 'Populated from Vendor Central',
        about: `Automatically pull ${sotType} data from your Vendor Central.`,
        isRecommended: true,
        disabled: false,
        showUpcomingBadge: false,
        showLearnMore: true,
        learnMoreTooltipText:
          'Automatically pull Content data from your Vendor Central Content SKU.'
      },
      {
        key: 'upload',
        icon: 'upload',
        name: `Upload Your Product ${sotType}`,
        about: `Use CommerceIQ provided template to upload your product ${sotType} data.`,
        isRecommended: false,
        disabled: false,
        showUpcomingBadge: false,
        showLearnMore: true,
        learnMoreTooltipText:
          "Use CommerceIQ's provided template to upload your product Content data."
      }
    ]
  };
};
