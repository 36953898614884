import Vue from 'vue';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { skuData } from './constants';

const initialSkuState = skuData();

export const skuCellDisplay = (initialState = initialSkuState) => {
  return Vue.extend({
    data() {
      return initialState.data;
    },
    created() {
      if (this.renderParams !== undefined) {
        this.renderParams = this.renderParams;
      }
    },
    methods: {
      navigateToSDP(asin) {
        navigate_to_sdp(asin);
      }
    },
    template: `
    <div class="u-width-100">
      <rb-sku v-if="params.data[renderParams.asin] !== undefined && params.data[renderParams.imageUrl] !== undefined"
        :title="params.data[renderParams.name] ? params.data[renderParams.name]: \'-----\'"
        :id="params.data[renderParams.asin]"
        :asin="params.data[renderParams.asin]"
        :imageUrl="params.data[renderParams.imageUrl]"
        :showImage="renderParams.showImage"
        :productPageUrl="params.data[renderParams.productPageUrl]"
        @navigateToSDP="navigateToSDP"
        :enableSDP="renderParams.enableSDP"
        :rb-asin-tippy-text="renderParams.asinTippyText">
      </rb-sku>
      <div v-if="params.data[renderParams.asin] === undefined && params.data[renderParams.imageUrl] === undefined">
        <span>{{params.data[renderParams.name]}}</span>
      </div>
    </div>`
  });
};
