const allowedMetrics = [
  'bo_actual_sales_marketshare_v2',
  'bo_actual_unit_marketshare_v2',
  'bo_exclude_3P_sales_marketshare_v2',
  'bo_exclude_3P_unit_marketshare_v2',
  'bo_exclude_my_3P_sales_marketshare_v2',
  'bo_exclude_my_3P_unit_marketshare_v2',
  'bo_only_3P_sales_marketshare_v2',
  'bo_only_3P_unit_marketshare_v2',
  'monthly_net_ppm__monthly_net_ppm',
  'daily_net_ppm__daily_net_ppm',
  'sns__net_sales',
  'sns__net_qty',
  'sns__rebate_percentage',
  'sns__percentage_sns_sales',
  'sns__percentage_sns_units',
  'cm__contribution_margin',
  'cm__contribution_profit',
  'cm__net_pure_profit',
  'inventory-pit__on_hand_inventory',
  'inventory-pit__on_hand_inventory_cogs',
  'woc-pit__weeks_of_cover',
  'walmart_bo__total_end_of_week_on_hand_unit',
  'walmart_bo__total_on_order_unit',
  'walmart_bo__total_values_end_of_week_on_hand_unit',
  'walmart_bo__total_values_on_order_unit'
];

export function categoryHierarchyFilterApplication(whereClause, filterValue) {
  for (var j = 0; j < filterValue.length; j++) {
    let value = filterValue[j].replace(/----/g, '~');
    value = value.toLowerCase() + '~';
    whereClause.dimensionNameValueList.push({
      dimensionName: 'category_hierarchy',
      dimensionValue: value,
      operator: 'STARTSWITH'
    });
  }
}

export function filterValidation(state, where, oFilter, i) {
  if (Object.keys(state.customFilter)?.includes(i)) {
    state.customFilter[i](where, oFilter.values[i]);
    return true;
  }
  return false;
}

export function filterSelected(store) {
  let dimensionNameValueList =
    store.getters?.getWhereClause?.['dimensionNameValueList'];
  return (
    dimensionNameValueList &&
    dimensionNameValueList[0]?.dimensionName === 'category_hierarchy'
  );
}

export function categorisationSelected(store) {
  const allMetricAlias =
    store.getters?.getchartingWbSaveViewData?.['shownMetricList'];
  if (allMetricAlias.some((alias) => allowedMetrics.includes(alias))) {
    let dimensionNameValueList =
      store.getters?.getWhereClause?.['dimensionNameValueList'];
    return (
      dimensionNameValueList &&
      dimensionNameValueList[0]?.dimensionName === 'category_hierarchy'
    );
  } else {
    return true;
  }
}

export function isAllowedMetric(metric) {
  if (allowedMetrics.includes(metric)) {
    return true;
  } else {
    return false;
  }
}

export const filterOpenerBO = {
  dimensionColumn: 'category_hierarchy',
  dimensionLabel: 'Market Share Category',
  dimensionName: 'category_hierarchy',
  dimensionType: 'MARKET_SHARE_CATEGORISATION',
  smart: false
};

export function waterfallName(waterfallGroupingName) {
  return waterfallGroupingName === 'market_share';
}

export function featureEnabled(store) {
  return (
    store.getters.getFeatureEnableConfig?.['insights']?.['market_share']?.[
      'enable'
    ] && store.getters.getRetailProgramConfig === 'amazonretail'
  );
}
