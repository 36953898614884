<template>
  <div class="daterangepicker-calendar">
    <div
      class="u-display-flex u-flex-align-items-center u-spacing-pt-s u-spacing-pb-m"
    >
      <div
        class=""
        :class="calendarIndex == 1 ? '' : 'u-visibility-hidden'"
      >
        <span
          @mousedown.prevent
          @click.stop="goToPrevMonth"
        >
          <rb-icon
            :icon="'angled-arrow-left'"
            class="u-color-grey-lighter u-font-weight-600 u-cursor-pointer rb-icon--medium"
          />
        </span>
      </div>
      <div
        class="u-flex-1 u-max-width-100 u-text-align-center u-font-size-6 u-font-weight-600"
      >
        {{ displayMonth.format('MMM YYYY') }}
      </div>
      <div
        class=""
        :class="calendarIndex == calendarCount ? '' : 'u-visibility-hidden'"
      >
        <span
          @mousedown.prevent
          @click.stop="goToNextMonth"
        >
          <rb-icon
            :icon="'angled-arrow-right'"
            class="u-color=grey-lighter u-font-weight-600 u-cursor-pointer rb-icon--medium"
          />
        </span>
      </div>
    </div>
    <div class="u-display-flex">
      <div
        v-if="showWeekNumbers"
        v-tippy="{ placement: 'right-start' }"
        title="Week Number"
        class="u-display-flex u-flex-direction-column u-spacing-mr-s u-color-grey-x-light"
      >
        <div
          v-for="(weekObj, index) in weeks"
          :key="weekObj.week"
          class="week-number"
          :class="{
            'u-font-weight-600': index === 0
          }"
        >
          {{ weekObj.week }}
        </div>
      </div>
      <div>
        <div
          class="u-display-flex u-flex-justify-content-between u-text-align-center daterangepicker-calendar-row"
        >
          <div
            v-for="day in daysOfFirstWeek"
            :key="day.format('D')"
            class="col-day u-font-weight-600 u-font-size-6"
          >
            {{ day.format('dd').charAt(0) }}
          </div>
        </div>
        <div class="u-display-flex">
          <div
            class="u-display-flex u-flex-wrap-yes u-flex-justify-content-between u-text-align-center daterangepicker-calendar-row"
          >
            <div
              v-for="day in days"
              :key="day.format('M-D')"
              class="col-day u-font-size-6"
              :class="dayClass(day)"
              @click.stop="dayMouseOver(day)"
              @mouseover.stop="hover(day)"
              @mouseout.stop="hoverOut"
              @mousedown.prevent
            >
              {{ day.format('D') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  filters: {},
  props: {
    calendarIndex: {
      type: Number,
      required: true
    },
    calendarCount: {
      type: Number,
      required: true
    },
    month: {
      type: moment.Moment,
      required: true
    },
    startDate: {
      type: moment.Moment,
      required: true
    },
    endDate: {
      type: moment.Moment,
      required: true
    },
    compare: {
      type: Boolean,
      required: true
    },
    startDateCompare: {
      type: moment.Moment,
      required: true
    },
    endDateCompare: {
      type: moment.Moment,
      required: true
    },
    step: {
      type: String,
      default: undefined
    },
    maxDate: {
      type: moment.Moment,
      required: true
    },
    minDate: {
      type: moment.Moment,
      required: true
    },
    allowCompare: {
      type: Boolean,
      required: true
    },
    hoveredStartDate: {
      type: moment.Moment,
      default: undefined
    },
    hoveredEndDate: {
      type: moment.Moment,
      default: undefined
    },
    selectionMode: {
      type: String,
      default: undefined
    },
    hoveredStartDateCompare: {
      type: moment.Moment,
      default: undefined
    },
    hoveredEndDateCompare: {
      type: moment.Moment,
      default: undefined
    },
    showWeekNumbers: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {};
  },
  computed: {
    displayMonth: function () {
      return moment.utc(this.month).add(this.calendarIndex - 1, 'month');
    },
    weeks: function () {
      const startDay = moment.utc(this.displayMonth).startOf('week');
      const endDay = moment
        .utc(this.displayMonth)
        .endOf('month')
        .endOf('week')
        .startOf('day')
        .add(1, 'day');
      const nDays = moment.duration(endDay.diff(startDay)).asDays();
      const returnWeeks = [
        {
          week: 'W#'
        }
      ];

      const weeksSet = new Set();
      /* 
      Earlier we were using object here instead of set to catch week numbewrs but the isuue with objects was that it does not add the week number in the sequence in which it is coming but instead based on its numerical value.
      For example there are certain cases in our logic where we need to have week number 1 marked as last week of the year but if we use object,
      then we would get week number 1 as first week of december as a result contrary to the desired one where last of week of december should be marked as week 1.
      */

      /* 
      The logic that we follow here at CIQ to calculate the first week of the year is that the new year should have atleast 4 or more days in the week for it to be considered as the first week, else it rolls back to last year's last week. 
      For Example: 1 Jan 2022 should be considered as part of 2021's last week and not as part of 2022's first week because 2021 has more than 4 days in the same week.
      But moment.js considers whichever week having 1 Jan as the first week of the year. 
      The below logic is written to tackle that.
      */

      // This is how .day function works in moment.js :- [0:Sunday, 1:Monday, 2:Tuesday, 3:Wednesday, 4:Thursday, 5:Friday, 6:Saturday]
      const currentYearStartsOnWeek_1 =
        moment.utc(this.displayMonth).startOf('year').day() < 4;
      const currentYearEndsOnWeek_1 =
        moment.utc(this.displayMonth).endOf('year').day() < 3;
      const prevYearStartsOnWeek_1 =
        moment
          .utc(this.displayMonth)
          .subtract(1, 'year')
          .startOf('year')
          .day() < 4;

      const startingWeekOfYear = moment
        .utc(this.displayMonth)
        .startOf('year')
        .week(); // This is to determine if the week in question contains the first day of the year
      const endingWeekOfYear = moment
        .utc(this.displayMonth)
        .endOf('year')
        .week(); // This is to determine if the week in question contains the last day of the year

      let day = 0;
      while (day < nDays) {
        let week = moment.utc(startDay).add(day, 'day').week();

        /* 
        If Moment.js returns the week number as 1, it can either be last year's last week (according to our logic explained above) or it can be the current year's first week.
        But moment.js does not tell us that. So we also also need to specify whether the week is December's last week or is it January's last week
        */

        /* 
        If in case the  year starts with week 1 and also the last week contains 4 or more days then it means the week should be marked as week 53
        which means the last of weeek of current year and first week next year should be marked as week 53
        */
        const isFirstWeek_53 =
          week === startingWeekOfYear &&
          !currentYearStartsOnWeek_1 &&
          prevYearStartsOnWeek_1 &&
          moment.utc(this.displayMonth).format('MMMM') === 'January';

        const isLastWeek_53 =
          week === endingWeekOfYear &&
          !currentYearEndsOnWeek_1 &&
          currentYearStartsOnWeek_1 &&
          moment.utc(this.displayMonth).format('MMMM') === 'December';

        const isLastWeek_1 =
          week === endingWeekOfYear &&
          currentYearEndsOnWeek_1 &&
          moment.utc(this.displayMonth).format('MMMM') === 'December';

        if (!currentYearStartsOnWeek_1) {
          week = moment
            .utc(startDay)
            .add(day, 'day')
            .subtract(1, 'week')
            .week();
        }

        if (isLastWeek_1) {
          week = moment
            .utc(startDay)
            .add(day, 'day')
            .subtract(1, 'week')
            .add(1, 'week')
            .week();
        }

        if (isLastWeek_53 || isFirstWeek_53) {
          week =
            moment.utc(startDay).add(day, 'day').subtract(1, 'week').week() + 1;
        }

        weeksSet.add(week);
        day++;
      }

      const weeksArray = Array.from(weeksSet);

      for (let i in weeksArray) {
        returnWeeks.push({
          week: weeksArray[i]
        });
      }

      return returnWeeks;
    },
    days: function () {
      // this is when, we've changed the first day of the week via the locale. defaults to 0 (Sunday) if not set.
      const weekStartOffset = moment.localeData().firstDayOfWeek();
      const startDay = moment
        .utc(this.displayMonth)
        .startOf('month')
        .startOf('week')
        .subtract(weekStartOffset, 'days');
      const endDay = moment
        .utc(this.displayMonth)
        .endOf('month')
        .endOf('week')
        .subtract(weekStartOffset, 'days');
      const nDays = moment.duration(endDay.diff(startDay)).asDays();

      const days = [];
      let day = 0;
      while (day < nDays) {
        days.push(moment.utc(startDay).add(day, 'day'));
        day++;
      }
      return days;
    },
    daysOfFirstWeek: function () {
      return this.days.slice(0, 7);
    }
  },
  watch: {},
  methods: {
    dayClass: function (day) {
      const classes = [];
      if (day.isSame(moment.utc().startOf('day'))) {
        classes.push('today');
      }
      if (
        !day.isBetween(
          this.displayMonth,
          moment.utc(this.displayMonth).endOf('month'),
          'days',
          '[]'
        )
      ) {
        classes.push('u-visibility-hidden');
      }

      if (
        day.isAfter(moment.utc(this.maxDate), 'days') ||
        (this.minDate && day.isBefore(moment.utc(this.minDate), 'days'))
      ) {
        classes.push('daterangepicker-range-disable');
      }

      if (
        day.isBetween(this.startDate, this.endDate, 'days', '[]') ||
        day.isSame(this.startDate)
      ) {
        classes.push('daterangepicker-range');
        if (moment.utc(day).isSame(moment.utc(this.startDate), 'day')) {
          classes.push('active');
          classes.push('active-start');
        }
        if (moment.utc(day).isSame(moment.utc(this.endDate), 'day')) {
          classes.push('active');
          classes.push('active-end');
        }
      }

      if (
        this.allowCompare &&
        this.compare &&
        (day.isBetween(
          this.startDateCompare,
          this.endDateCompare,
          'days',
          '[]'
        ) ||
          day.isSame(this.startDateCompare))
      ) {
        classes.push('daterangepicker-range-compare');
        if (moment.utc(day).isSame(moment.utc(this.startDateCompare), 'day')) {
          classes.push('active');
          classes.push('active-start');
        }
        if (moment.utc(day).isSame(moment.utc(this.endDateCompare), 'day')) {
          classes.push('active');
          classes.push('active-end');
        }
      }

      if (this.step != null) {
        if (
          day.isBetween(
            day.clone().startOf('week'),
            day.clone().endOf('week'),
            'days',
            '[]'
          )
        ) {
          classes.push('daterangepicker-cursor-pointer');
          classes.push('col-day-number');
        }
        classes.push('daterangepicker-cursor-pointer');
        classes.push('col-day-number');
      }

      const hoveredStartDate = this.hoveredStartDate;
      const hoveredEndDate = this.hoveredEndDate;

      if (
        this.selectionMode === 'month' &&
        hoveredStartDate &&
        day.isBetween(
          moment.utc(hoveredEndDate).clone().startOf('month'),
          moment.utc(hoveredEndDate).clone().endOf('month'),
          'days',
          '[]'
        )
      ) {
        classes.push('hover-selection');
      }

      if (
        this.selectionMode === 'month' &&
        this.hoveredStartDateCompare &&
        day.isBetween(
          moment.utc(this.hoveredStartDateCompare).clone().startOf('month'),
          moment.utc(this.hoveredEndDateCompare).clone().endOf('month'),
          'days',
          '[]'
        )
      ) {
        classes.push('hover-selection');
      }

      if (
        this.selectionMode?.includes('week') &&
        hoveredStartDate &&
        day.isBetween(
          moment.utc(hoveredEndDate).clone().startOf('week'),
          moment.utc(hoveredEndDate).clone().endOf('week'),
          'days',
          '[]'
        )
      ) {
        classes.push('hover-selection');
      }

      if (
        this.selectionMode?.includes('week') &&
        this.hoveredStartDateCompare &&
        day.isBetween(
          moment.utc(this.hoveredStartDateCompare).clone().startOf('week'),
          moment.utc(this.hoveredEndDateCompare).clone().endOf('week'),
          'days',
          '[]'
        )
      ) {
        classes.push('hover-selection');
      }

      return classes.join(' ');
    },
    hoverOut() {
      this.$emit('onHoverLeave');
    },
    dayMouseOver: function (day) {
      if (this.step != null) {
        this.selectDate(day);
      }
    },
    hover: function (day) {
      if (this.step !== null) {
        this.$emit('onHover', day);
      }
    },
    dayClick: function (day) {
      if (this.step != null) {
        this.nextStep();
      }
    },
    goToPrevMonth: function () {
      this.$emit('goToPrevMonth');
    },
    goToNextMonth: function () {
      this.$emit('goToNextMonth');
    },
    selectDate: function (date) {
      let emitDate = date;
      if (this.selectionMode === 'month') {
        if (this.step === 'selectStartDate') {
          emitDate = {
            startDate: this.hoveredStartDate
              .clone()
              .startOf('month')
              .startOf('day'),
            endDate: this.hoveredStartDate.clone().endOf('month').startOf('day')
          };
        } else if (this.step === 'selectStartDateCompare') {
          emitDate = {
            startDate: this.hoveredStartDateCompare
              .clone()
              .startOf('month')
              .startOf('day'),
            endDate: this.hoveredStartDateCompare
              .clone()
              .endOf('month')
              .startOf('day')
          };
        }
      } else if (this.selectionMode === 'week') {
        if (this.step === 'selectStartDate') {
          emitDate = {
            startDate: this.hoveredStartDate
              .clone()
              .startOf('week')
              .startOf('day'),
            endDate: this.hoveredStartDate.clone().endOf('week').startOf('day')
          };
        } else if (this.step === 'selectStartDateCompare') {
          emitDate = {
            startDate: this.hoveredStartDateCompare
              .clone()
              .startOf('week')
              .startOf('day'),
            endDate: this.hoveredStartDateCompare
              .clone()
              .endOf('week')
              .startOf('day')
          };
        }
      } else if (this.selectionMode === 'multi-week') {
        if (this.step === 'selectStartDate') {
          emitDate = {
            startDate: this.hoveredStartDate
              .clone()
              .startOf('week')
              .startOf('day'),
            endDate: this.hoveredStartDate.clone().endOf('week').startOf('day')
          };
        } else if (this.step === 'selectEndDate') {
          let startDate = {};
          let hoveredEndDate = this.hoveredEndDate
            .clone()
            .endOf('week')
            .startOf('day');
          if (this.hoveredStartDate) {
            startDate = this.hoveredStartDate
              .clone()
              .startOf('week')
              .startOf('day');
          } else {
            startDate = this.startDate.clone().startOf('week').startOf('day');
            if (!this.hoveredEndDate.isBefore(this.startDate)) {
              startDate = this.startDate.clone().startOf('week').startOf('day');
            } else {
              hoveredEndDate = this.startDate
                .clone()
                .endOf('week')
                .startOf('day');
            }
          }
          emitDate = {
            startDate: startDate,
            endDate: hoveredEndDate
          };
        } else if (this.step === 'selectStartDateCompare') {
          emitDate = {
            startDate: this.hoveredStartDateCompare
              .clone()
              .startOf('week')
              .startOf('day'),
            endDate: this.hoveredStartDateCompare
              .clone()
              .endOf('week')
              .startOf('day')
          };
        } else if (this.step === 'selectEndDateCompare') {
          let startDateCompare = {};
          let hoveredEndDateCompare = this.hoveredEndDateCompare
            .clone()
            .endOf('week')
            .startOf('day');
          if (this.hoveredStartDateCompare) {
            startDateCompare = this.hoveredStartDateCompare
              .clone()
              .startOf('week')
              .startOf('day');
          } else {
            startDateCompare = this.startDateCompare
              .clone()
              .startOf('week')
              .startOf('day');
            if (!this.hoveredEndDateCompare.isBefore(this.startDateCompare)) {
              startDateCompare = this.startDateCompare
                .clone()
                .startOf('week')
                .startOf('day');
            } else {
              hoveredEndDateCompare = this.startDateCompare
                .clone()
                .endOf('week')
                .startOf('day');
            }
          }
          emitDate = {
            startDate: startDateCompare,
            endDate: hoveredEndDateCompare
          };
        }
      }
      this.$emit('selectDate', emitDate);
    },
    nextStep: function () {
      this.$emit('nextStep');
    }
  }
};
</script>

<style lang="css">
.daterangepicker-calendar {
  min-width: 248px;
}
.daterangepicker-calendar-row {
  font-size: 14px;
}

.week-number {
  display: flex;
  padding: 12px 8px;
  white-space: nowrap;
  cursor: default;
  border-right: dashed 1px #aaadb1;
  font-weight: 400;
  box-sizing: border-box !important;
  align-items: center;
  justify-content: center;
}

.col-day {
  width: 14.28%;
  padding: 8px 4px;
  white-space: nowrap;
  cursor: default;
  margin: 4px 0px;
  font-weight: normal;
  box-sizing: border-box !important;
}
.col-day .today {
  border-bottom: solid 2px !important;
  border-color: inherit !important;
  height: 31px;
}

.col-day.col-day-number.hover-selection {
  background-color: #caccce !important;
  color: inherit !important;
  border-radius: 8px !important;
}

.col-day-number:hover {
  background: #caccce !important;
  color: inherit !important;
  border-radius: 8px;
}

.daterangepicker-range.active {
  background-color: #007cf6 !important;
}
.daterangepicker-range.active .active-start.active-end {
  border-radius: 8px;
}

.daterangepicker-range.active .active-start {
  border-radius: 8px 0% 0% 8px;
}

.daterangepicker-range.active .active-end {
  border-radius: 0% 8px 8px 0%;
}

.daterangepicker-range-compare.active {
  background-color: #ffa800 !important;
}
.daterangepicker-range-compare.active .active-start.active-end {
  border-radius: 8px;
}

.daterangepicker-range-compare.active .active-start {
  border-radius: 8px 0% 0% 8px;
}

.daterangepicker-range-compare.active .active-end {
  border-radius: 0% 8px 8px 0%;
}

.daterangepicker-range-disable {
  opacity: 0.3;
  pointer-events: none;
}

.daterangepicker-range.daterangepicker-range-compare.active {
  background: #23b5d3 !important;
}
daterangepicker-range.daterangepicker-range-compare.active
  .active-start.active-end {
  border-radius: 8px;
}

daterangepicker-range.daterangepicker-range-compare.active .active-start {
  border-radius: 8px 0% 0% 8px;
}

daterangepicker-range.daterangepicker-range-compare.active .active-end {
  border-radius: 0% 8px 8px 0%;
}

.daterangepicker-range {
  background: rgba(0, 124, 246, 0.75);
  color: #ffffff;
}

.daterangepicker-range-compare {
  background: rgba(255, 168, 0, 0.75);
  color: #ffffff;
}

.daterangepicker-range.daterangepicker-range-compare {
  background: rgba(35, 181, 211, 0.75) !important;
}

.daterangepicker-cursor-pointer {
  cursor: pointer;
}
</style>
