import dataTransformer from '../../../../../utils/services/data-transformer';
let poTableColumns = require('./poTableFields');
poTableColumns = poTableColumns.getPayload();
let skuPoTableColumns = require('./skuPoTableFields.json');

poTableColumns = dataTransformer.getColumnDefinition(poTableColumns);
skuPoTableColumns = dataTransformer.getColumnDefinition(skuPoTableColumns);
const ROW_HEIGHT = 60;

const config = () => {
  return {
    filters: {
      emit: 'shipmentTrackerFiltersApplied',
      listen: {
        shipmentTrackerFiltersApplied: {
          action: 'updateShipmentTrackerSelectedFilter'
        },
        setInputDateType: {
          action: 'fetchSkuPOInfoList',
          transform: (config, data, that) => {
            config.body.APIConfig.timeseriesDimension =
              that.$store.getters.getInputDateSelection.key;
            return config;
          }
        }
      },
      hasSearch: true,
      hideFilter: true,
      search: {
        width: '240px',
        enable: true,
        emit: 'shipmentTrackerSearchTriggered',
        placeholder: 'Search for PO, ASIN, Location, Vendor'
      }
    },
    widgets: {
      inputDateSelections: [
        {
          key: 'ordered_date',
          title: 'Order date',
          calenderKey: 'ship_track_ordered_on',
          orderby_key: 'ordered_on',
          todayKey: 'ship_track_ordered_on'
        },
        {
          key: 'shipment_window_start',
          title: 'Ship window start',
          calenderKey: 'ship_track_start',
          orderby_key: 'ship_window_start',
          todayKey: 'today_date'
        },
        {
          key: 'shipment_window_end',
          title: 'Ship window end',
          calenderKey: 'ship_track_end',
          orderby_key: 'ship_window_end',
          todayKey: 'today_date'
        }
      ],
      widget1: {
        custom: {
          overViewCardsMeasures: [
            {
              // requested card
              units: {
                data: {
                  m1: 'requested_units',
                  m2: 'PVP_PVP_requested_units',
                  m3: 'requested_values'
                },
                meta: {
                  cardTitle: 'Ordered units',
                  cardTooltip:
                    'Quantity ordered reflects the number and dollar value of the units ordered by Amazon for the POs from the selected date range.'
                }
              },
              cases: {
                data: {
                  m1: 'requested_cases',
                  m2: 'PVP_PVP_requested_cases',
                  m3: 'requested_values'
                },
                meta: {
                  cardTitle: 'Ordered cases',
                  cardTooltip:
                    'Quantity ordered reflects the number and dollar value of the cases ordered by Amazon for the POs from the selected date range.'
                }
              }
            },
            // confirmed card
            {
              units: {
                data: {
                  m1: 'confirmed_units',
                  m2: 'PVP_PVP_confirmed_units',
                  m3: 'confirmed_values'
                },
                meta: {
                  cardTitle: 'Accepted units',
                  cardTooltip:
                    'Quantity accepted reflects the number and dollar value of the units accepted for the POs from the selected date range.'
                }
              },
              cases: {
                data: {
                  m1: 'confirmed_cases',
                  m2: 'PVP_PVP_confirmed_cases',
                  m3: 'confirmed_values'
                },
                meta: {
                  cardTitle: 'Accepted cases',
                  cardTooltip:
                    'Quantity accepted reflects the number and dollar value of the cases accepted for the POs from the selected date range.'
                }
              }
            },
            // received card
            {
              units: {
                data: {
                  m1: 'received_units',
                  m2: 'PVP_PVP_received_units',
                  m3: 'received_values'
                },
                meta: {
                  cardTitle: 'Received units',
                  cardTooltip:
                    'Quantity received reflects the number and dollar value of the units that Amazon has received for the POs from the selected date range.'
                }
              },
              cases: {
                data: {
                  m1: 'received_cases',
                  m2: 'PVP_PVP_received_cases',
                  m3: 'received_values'
                },
                meta: {
                  cardTitle: 'Received cases',
                  cardTooltip:
                    'Quantity received reflects the number and dollar value of the cases that Amazon has received for the POs from the selected date range.'
                }
              }
            },
            // outstanding card
            {
              units: {
                data: {
                  m1: 'outstanding_units',
                  m2: 'PVP_PVP_outstanding_units',
                  m3: 'outstanding_values'
                },
                meta: {
                  cardTitle: 'Outstanding units',
                  cardTooltip:
                    'Quantity outstanding reflects the difference between the number and dollar value of the units accepted and received by Amazon for the POs from the selected date range.',
                  value2Meta: {
                    formatType: 'percent',
                    trend: true,
                    invert: true
                  }
                }
              },
              cases: {
                data: {
                  m1: 'outstanding_cases',
                  m2: 'PVP_PVP_outstanding_cases',
                  m3: 'outstanding_values'
                },
                meta: {
                  cardTitle: 'Outstanding cases',
                  cardTooltip:
                    'Quantity outstanding reflects the difference between the number and dollar value of the cases accepted and received by Amazon for the POs from the selected date range.',
                  value2Meta: {
                    formatType: 'percent',
                    trend: true,
                    invert: true
                  }
                }
              }
            }
          ]
        },
        meta: {
          hideOnNoData: false,
          key: 'shipmentOverviewData',
          action: 'fetchShipmentOverviewData',
          localFilters: [],
          listen: {
            shipmentTrackerFiltersApplied: {
              action: 'fetchShipmentOverviewData',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                return config;
              }
            },
            setInputDateType: {
              action: 'fetchShipmentOverviewData',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                return config;
              }
            },
            shipmentTrackerSearchTriggered: {
              action: 'fetchShipmentOverviewData',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                config.meta.localFilters = [];
                config.meta.localFilters.push({
                  dimensionName: 'search',
                  operator: 'ILIKE',
                  dimensionValue: data
                });
                return config;
              }
            }
          }
        },
        body: {
          APIConfig: {
            cubeName: 'shipment_tracker_dashboard',
            measuresList: [
              'requested_units',
              'confirmed_units',
              'received_units',
              'outstanding_units',
              'requested_cases',
              'confirmed_cases',
              'received_cases',
              'outstanding_cases',
              'requested_values',
              'confirmed_values',
              'received_values',
              'outstanding_values'
            ],
            groupByDimensionsList: [],
            timeseriesEnabled: false,
            timeseriesDimension: 'ordered_on',
            pvpenabled: true,
            yoyenabled: false,
            orderByList: [],
            where: {
              timeseriesDimensionList: [
                {
                  dimensionName: 'ordered_on',
                  dimensionValue: '2020-07-24'
                }
              ],
              pvpDate: {
                from: '2020-07-11',
                to: '2020-07-17'
              },
              date: {
                from: '2020-07-18',
                to: '2020-07-24'
              }
            }
          }
        },
        header: {
          show: true,
          title: 'SHIPMENT OVERVIEW'
        },
        footer: {
          show: true,
          date: 'shipment_tracker'
        }
      },
      widget2: {
        meta: {
          key: 'POTableData',
          hideOnNoData: false,
          action: 'fetchPOInfoList',
          localFilters: [],
          listen: {
            shipmentTrackerFiltersApplied: {
              action: 'fetchPOInfoList',
              transform: (config, data, that) => {
                // on search the widget2 should always be switched to automatically
                that.$store.dispatch('setShowPOTable', true);
                that.$store.dispatch('setSelectedPOs', []);
                config.body.APIConfig.page = 1;
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection;
                return config;
              }
            },
            setInputDateType: {
              action: 'fetchPOInfoList',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection;
                return config;
              }
            },
            shipmentTrackerSearchTriggered: {
              action: 'fetchPOInfoList',
              transform: (config, data, that) => {
                // on search the widget2 should always be switched to automatically
                that.$store.dispatch('setShowPOTable', true);
                that.$store.dispatch('setSelectedPOs', []);
                config.body.APIConfig.page = 1;
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                config.meta.localFilters = [];
                config.meta.localFilters.push({
                  dimensionName: 'search',
                  operator: 'ILIKE',
                  dimensionValue: data
                });
                return config;
              }
            }
          }
        },
        header: {
          show: true,
          title: 'SHIPMENT SUMMARY OF PURCHASE ORDERS',
          search: true,
          searchPlaceholder: 'Search',
          searchParams: ['search'],
          searchTooltip: 'Search for PO, ASIN, location, vendor',
          actions: {
            download: {
              triggerThroughComponent: true,
              fileName: 'Shipment Details PO Level Summary',
              columnMap: poTableColumns.displayColConfigs.map((uiColumn) => {
                return {
                  title: uiColumn.headerName,
                  field: uiColumn.field
                };
              }),
              action: 'downloadShipmentTableData'
            }
          }
        },
        body: {
          defaultOrderBy: [
            {
              dimension: 'ordered_on',
              direction: 'DESC'
            }
          ],
          columns: poTableColumns.displayColConfigs,
          APIConfig: {
            cubeName: 'shipment_tracker_dashboard',
            measuresList: [
              'days_past_shipment_window_end',
              'ordered_quantity_sum',
              'accepted_quantity_sum',
              'received_quantity_sum',
              'received_percentage_po',
              'outstanding_cases',
              'vendor',
              'ship_to_location',
              'window_type',
              'count_total',
              'po_status',
              'ship_window_start',
              'ship_window_end',
              'ordered_on',
              'search',
              'asn',
              'shipment_id',
              'shipment_status',
              'carrier_scac',
              'ship_mode',
              'estimated_delivery_date',
              'carrier_tracking_number',
              'reference_id',
              'asn_url',
              'shipment_id_url',
              'po_url'
            ],
            groupByDimensionsList: ['po_number'],
            orderByList: [
              {
                dimension: 'ship_window_end',
                direction: 'DESC'
              },
              {
                dimension: 'received_percentage_po',
                direction: 'ASC'
              }
            ],
            timeseriesDimension: 'ordered_date',
            timeseriesEnabled: false,
            getLatestAvailableInsteadOfRollup: false,
            pvpenabled: false,
            yoyenabled: false,
            limit: 10,
            page: 1
          }
        },
        gridOptions: {
          context: {
            componentParent: this
          },
          getRowHeight: function (params) {
            return (params.data.asn.length || 1) * ROW_HEIGHT;
          }
        },
        footer: {
          show: true,
          date: 'shipment_tracker'
        }
      },
      widget3: {
        meta: {
          key: 'SkuPOTableData',
          hideOnNoData: false,
          action: 'fetchSkuPOInfoList',
          localFilters: [],
          listen: {
            shipmentTrackerFiltersApplied: {
              action: 'fetchSkuPOInfoList'
            },
            setInputDateType: {
              action: 'fetchSkuPOInfoList',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                return config;
              }
            },
            shipmentTrackerSearchTriggered: {
              action: 'fetchSkuPOInfoList',
              transform: (config, data, that) => {
                config.body.APIConfig.timeseriesDimension =
                  that.$store.getters.getInputDateSelection.key;
                config.meta.localFilters = [];
                config.meta.localFilters.push({
                  dimensionName: 'search',
                  operator: 'ILIKE',
                  dimensionValue: data
                });
                return config;
              }
            }
          }
        },
        header: {
          show: true,
          title: 'SKU LEVEL SHIPMENT SUMMARY OF PURCHASE ORDERS',
          search: true,
          searchPlaceholder: 'Search',
          searchTooltip: 'Search for PO, ASIN, location, vendor',
          searchParams: ['search'],
          actions: {
            download: {
              triggerThroughComponent: true,
              fileName: 'Shipment PO SKU Level Summary',
              columnMap: skuPoTableColumns.downloadColConfigs.map(
                (uiColumn) => {
                  return {
                    title: uiColumn.headerName,
                    field: uiColumn.field
                  };
                }
              ),
              action: 'downloadShipmentTableData'
            }
          }
        },
        body: {
          defaultOrderBy: [
            {
              dimension: 'received_percentage_sum',
              direction: 'ASC'
            }
          ],
          columns: skuPoTableColumns.displayColConfigs,
          APIConfig: {
            cubeName: 'shipment_tracker_dashboard',
            measuresList: [
              'ordered_quantity_sum',
              'accepted_quantity_sum',
              'received_quantity_sum',
              'outstanding_cases',
              'received_percentage_sum',
              'case_size',
              'case_cost',
              'total_cost',
              'vendor',
              'ship_to_location',
              'window_type',
              'count_total'
            ],
            groupByDimensionsList: [
              'po_number',
              'po_status',
              'vendor',
              'ordered_on',
              'ship_window_start',
              'ship_window_end',
              'ship_to_location',
              'asin',
              'product_title',
              'product_url',
              'image_url',
              'search'
            ],
            orderByList: [
              {
                dimension: 'po_number',
                direction: 'ASC'
              },
              {
                dimension: 'received_percentage_sum',
                direction: 'ASC'
              }
            ],
            timeseriesDimension: 'ordered_date',
            timeseriesEnabled: false,
            getLatestAvailableInsteadOfRollup: false,
            pvpenabled: false,
            yoyenabled: false,
            limit: 10,
            page: 1
          }
        },
        gridOptions: {
          context: {
            componentParent: this
          }
        },
        footer: {
          show: true,
          date: 'shipment_tracker'
        }
      }
    }
  };
};

export default {
  config: config
};
