import Vue from 'vue';
import tableDownloadMap from './tableDownloadMap';

function getTableApiConfig(measure, measureType, orderByListReq) {
  var measureOrder = '';
  var check = Array.isArray(measure);
  var measureList = [];
  if (check === true) {
    measureList = measureList.concat(measure);
    measureList = measureList.concat(['asin_hyperlink', 'title', 'image_url']);
    measureOrder = measure[0];
  } else {
    measureList = [measure, 'asin_hyperlink', 'title', 'image_url'];
    measureOrder = measure;
  }
  var objToReturn = {
    cubeName: 'sales_dashboard',
    measuresList: measureList,
    groupByDimensionsList: ['asin'],
    dedupBeforeRollup: {
      enableDedupBeforeRollup: true,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: ['asin']
    },
    timeseriesDimension: 'feed_date',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: true,
    yoyenabled: false,
    where: {
      dimensionNameValueList: [],
      date: {
        from: '2018-07-01',
        to: '2018-07-31'
      }
    }
  };

  // if it not an pvp metric then use orderByList
  if (orderByListReq) {
    objToReturn.orderByList = [
      {
        dimension: measureOrder,
        direction: 'DESC'
      },
      {
        dimension: 'asin',
        direction: 'DESC'
      }
    ];
    objToReturn.limit = 10;
  } else {
    objToReturn.customOrderAndSort = {
      type: 'PVP',
      measureType: measureType,
      measureName: measureOrder,
      direction: 'ASC',
      limit: 10
    };
  }

  return objToReturn;
}

function getTableApiConfigType2(params) {
  var check = Array.isArray(params);
  if (check === false) {
    params = [params];
  }
  var measures = params.splice(1, params.length - 1);
  var measuresList = measures.concat([
    'revenue_lost_ops',
    'revenue_lost_pcogs',
    'asin_hyperlink',
    'title',
    'image_url',
    'revenue_lost_ops_daily',
    'revenue_lost_pcogs_daily'
  ]);
  // Add Direct Fulfilment Inventory Column to table based on a config
  if (
    Vue.options.filters.config_check('feature.direct_fulfilment.enable') &&
    (params.indexOf('lbb_skus') > -1 || params.indexOf('uavl_skus') > -1)
  ) {
    if (measuresList.indexOf('stock') === -1) {
      measuresList.push('stock');
    }
  }
  return {
    cubeName: params[0] + '_v2',
    measuresList: measuresList,
    groupByDimensionsList: ['asin'],
    dedupBeforeRollup: {
      enableDedupBeforeRollup: true,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: ['asin']
    },
    timeseriesDimension: 'feed_date',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: true,
    yoyenabled: false,
    where: {
      dimensionNameValueList: [],
      date: {
        from: '2018-07-01',
        to: '2018-07-31'
      }
    },
    limit: 10,
    orderByList: [
      {
        dimension: 'revenue_lost_ops',
        direction: 'DESC'
      }
    ]
  };
}

function getTableApiConfigType3(params) {
  var check = Array.isArray(params);
  if (check === false) {
    params = [params];
  }
  var measures = params.splice(1, params.length - 1);
  var measuresList = measures.concat(['asin_hyperlink', 'title', 'image_url']);
  // Add Direct Fulfilment Inventory Column to table based on a config
  if (
    Vue.options.filters.config_check('feature.direct_fulfilment.enable') &&
    params.indexOf('gv_imputations') > -1
  ) {
    if (measuresList.indexOf('stock') === -1) {
      measuresList.push('stock');
    }
  }

  return {
    cubeName: params[0],
    measuresList: measuresList,
    groupByDimensionsList: ['asin'],
    dedupBeforeRollup: {
      enableDedupBeforeRollup: true,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: ['asin']
    },
    timeseriesDimension: 'feed_date',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: true,
    yoyenabled: false,
    where: {
      dimensionNameValueList: [],
      date: {
        from: '2018-07-01',
        to: '2018-07-31'
      }
    },
    limit: 10,
    orderByList: [
      {
        dimension: measuresList[0],
        direction: 'DESC'
      }
    ]
  };
}

function tableTransform(config, data, that) {
  var _config = Vue.util.extend({}, config);
  _config = JSON.parse(JSON.stringify(_config));
  _config.body.APIConfig.page = undefined;
  _config.body.APIConfig.limit = undefined;

  // Download columns corresponding only to the 'OPS' or 'PCOGS'
  var keysToCheck = [
    'orderedUnitsTableData',
    'oosTableData',
    'lbbTableData',
    'unvlTableData',
    'marginTableData',
    'priceWar',
    'glanceViewsTableData',
    'conversionTableData'
  ];
  if (keysToCheck.indexOf(_config.meta.key) > -1) {
    if (_config.header.actions && _config.header.actions.download) {
      var indexesToDelete = [];
      if (
        _config.header.actions.download.colMap &&
        _config.header.actions.download.colMap.length > 0
      ) {
        for (var idx in _config.header.actions.download.colMap) {
          var colMapObj = _config.header.actions.download.colMap[idx];
          if (colMapObj.hasOwnProperty('type') && this.currentGraph) {
            if (colMapObj.type !== this.currentGraph) {
              indexesToDelete.push(idx);
            }
          }
        }
        if (indexesToDelete && indexesToDelete.length > 0) {
          indexesToDelete.sort(function (a, b) {
            return a - b;
          });
          for (var index = indexesToDelete.length - 1; index >= 0; index--) {
            _config.header.actions.download.colMap.splice(
              indexesToDelete[index],
              1
            );
          }
        }
      }

      var currentGraphMap = {
        ordered_product_sales: ' - ops',
        pcogs: ' - pcogs'
      };
      if (_config.header.actions.download.fileName && this.currentGraph) {
        _config.header.actions.download.fileName +=
          currentGraphMap[this.currentGraph];
      }
    }
  }

  return _config;
}

function searchTriggeredTransform(config, data, that) {
  config.meta.localFilters = [];
  if (data && data.length > 0) {
    config.meta.localFilters.push({
      dimensionName: 'search',
      operator: 'ILIKE',
      dimensionValue: data[0].dimensionValue
    });
  }
  return config;
}

function getApiConfig(cubename, measureList) {
  return {
    cubeName: cubename,
    measuresList:
      measureList === undefined
        ? ['revenue_lost_ops', 'revenue_lost_pcogs']
        : measureList,
    groupByDimensionsList: [],
    timeseriesDimension: 'feed_date',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: true,
    yoyenabled: false,
    dedupBeforeRollup: {
      enableDedupBeforeRollup: true,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: ['asin']
    },
    where: {
      dimensionNameValueList: [],
      date: {
        from: '2018-07-01',
        to: '2018-07-31'
      }
    }
  };
}

function getTagDataSet1(data, mapping) {
  var oReturn = {};
  for (var i = 0; i < data.length; i++) {
    let tag2Data = 0;
    if ((data[i].PVP || {})['PVP_' + mapping]) {
      tag2Data = (data[i].PVP || {})['PVP_' + mapping];
    } else if ((data[i].PVP || {})['PVP_' + mapping] === 0) {
      tag2Data = 0;
    } else {
      tag2Data = undefined;
    }
    oReturn = {
      tag1: data[i].RESULT[mapping] || 0,
      tag2: tag2Data
    };
  }
  return oReturn;
}

var tilesTagConfig = {
  tag1Unit: {
    pre: 'currency'
  },
  tag2Unit: {
    suff: '%'
  }
};

var invertTilesTagConfig = {
  tag1Unit: {
    pre: 'currency'
  },
  tag2Unit: {
    suff: '%'
  },
  invertTag2: true
};

export default {
  config: (that) => {
    return {
      filters: {
        emit: 'salesDBFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'salesDashboardSearchTriggered',
          placeholder: 'Search for a ASIN'
        }
      },
      widgets: {
        widget21: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            // noAction: true,
            key: 'ds_glance_views',
            localFilters: [],
            action: 'getOrderedProductSales',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            show: true,
            title: 'Glance Views',
            tooltip:
              'Glance Views: Glance views received during the selected date range and percentage change with respect to previous period.',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', ['ds_glance_views']),
            getTagData: getTagDataSet1,
            tagConfig: {
              tag1Unit: {
                pre: ''
              },
              tag2Unit: {
                suff: '%'
              }
            }
          }
        },
        widget1: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getOrderedProductSales',
            key: 'ordered_product_sales',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            show: true,
            title: 'Ordered Product Sales (OPS)',
            tooltip:
              'Ordered Product Sales (OPS): Amazon revenue during the selected date range and %age change compared to previous period as per AVC sales data. ',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', [
              'ordered_product_sales',
              'pcogs'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: tilesTagConfig,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                chartWidth: 's',
                grid: 'xy',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  All: {
                    suff: ''
                  }
                }
              }
            }
          }
        },

        widget18: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getOrderedProductSales',
            key: 'pcogs',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            show: true,
            title: 'Cost of Goods Sold (PCOGS)',
            tooltip:
              'Product Cost of Goods Sold (PCOGS): Calculated by ordered units * unit COGS for the selected date range and percentage change with respect to previous period.',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', [
              'pcogs',
              'ordered_product_sales'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: tilesTagConfig,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                chartWidth: 's',
                grid: 'xy',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  All: {
                    suff: ''
                  }
                }
              }
            }
          }
        },

        widget2: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getRevenueLost',
            key: 'revenue_lost_buy_box',
            listen: {
              salesDBFilterApplied: {
                action: 'getRevenueLost'
              },
              salesDashboardSearchTriggered: {
                action: 'getRevenueLost',
                transform: searchTriggeredTransform
              }
            },
            localFilters: []
          },
          header: {
            show: true,
            title: 'Revenue Lost Due To Buy Box',
            tooltip:
              'Ordered Product Sales ($) lost in the selected time period due to ASINs having lost buy box. Revenue loss is determined by triangulating LBB%, Glance view, Conversion rate and Average Selling Price from AVC.',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('gv_imputations', [
              'revenue_lost_lbb',
              'revenue_lost_lbb_pcogs'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: invertTilesTagConfig
          }
        },

        widget3: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getRevenueLost',
            key: 'revenue_lost_oos_skus',
            listen: {
              salesDBFilterApplied: {
                action: 'getRevenueLost'
              },
              salesDashboardSearchTriggered: {
                action: 'getRevenueLost',
                transform: searchTriggeredTransform
              }
            },
            localFilters: []
          },
          header: {
            show: true,
            title: 'Revenue Lost Due To OOS',
            tooltip:
              'Revenue lost due to OOS: Lost OPS due to OOS calculated by last 30 days avg sales velocity (on in stock days)*ASP*no. of OOS days',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('oos_skus_v2', [
              'revenue_lost_ops',
              'revenue_lost_pcogs'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: invertTilesTagConfig
          }
        },

        widget4: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getRevenueLost',
            key: 'revenue_lost_unavl_skus',
            listen: {
              salesDBFilterApplied: {
                action: 'getRevenueLost'
              },
              salesDashboardSearchTriggered: {
                action: 'getRevenueLost',
                transform: searchTriggeredTransform
              }
            },
            apiKey: 'revenue_lost_unavailable',
            localFilters: []
          },
          header: {
            show: true,
            title: 'Revenue Lost Due to Unavailable Skus',
            tooltip:
              'Revenue lost due to Unavailable: Lost OPS due to unavailability calculated by last 30 days avg sales velocity (on available days)*ASP*no. of unavailable days',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('gv_imputations', [
              'revenue_lost_unavailable',
              'revenue_lost_unavailable_pcogs'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: invertTilesTagConfig
          }
        },

        // 'widget5': {
        //   meta: {
        //     type: 'metric',
        //     hideOnNoData: false,
        //     width: 1,
        //     action: 'getContentIssuesData',
        //     key: 'contentIssues',
        //     localFilters: [{
        //       'dimensionName': 'is_catalog_sku',
        //       'dimensionValue': 1
        //     }]
        //   },
        //   header: {
        //     show: true,
        //     title: 'Revenue Lift Due to Promo',
        //     tooltip: '',
        //     actions: {
        //     }
        //   },
        //   footer: {
        //     date: 'sales_by_account_type'
        //   },
        //   body: {
        //     APIConfig: {
        //       'cubeName': 'content_index',
        //       'measuresList': ['is_title_less_than_40_chars', 'is_image_count_less_than_4', 'is_bullet_count_less_than_3', 'is_video_present', 'is_aplus_content_present', 'is_avg_product_rating_less_than_3_pt_5', 'is_review_count_less_than_21'],
        //       'groupByDimensionsList': [],
        //       'timeseriesDimension': 'feed_date',
        //       'getLatestAvailableInsteadOfRollup': false,
        //       'timeseriesEnabled': false,
        //       'dedupBeforeRollup': {
        //         'enableDedupBeforeRollup': true,
        //         'additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions': []
        //       },
        //       'pvpenabled': false,
        //       'yoyenabled': false
        //     },
        //     getTagData (data, mapping) {
        //       var metrics = {};
        //       for (var i in data.RESULT) {
        //         metrics[i] = {
        //           tag1: data.RESULT[i] || 0
        //         }
        //       }
        //       return metrics
        //     },
        //     tagConfig: tilesTagConfig
        //   }
        // },

        widget6: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            // noAction: true,
            key: 'gross_retailer_margin',
            localFilters: [],
            action: 'getOrderedProductSales',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            show: true,
            title: 'Gross Retailer Margin',
            tooltip:
              "Gross Retailer Margin: Amazon's gross margin calculated by subtracting ordered units*unit COGS from OPS. Percentage change is with respect to previous period. ",
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', [
              'gross_retailer_margin'
            ]),
            getTagData: getTagDataSet1,
            tagConfig: tilesTagConfig
          }
        },

        widget7: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            // noAction: true,
            // action: 'getContentIssuesData',
            key: 'ordered_units',
            action: 'getOrderedProductSales',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            },
            localFilters: []
          },
          header: {
            show: true,
            title: 'Ordered Units',
            tooltip:
              'Ordered Units: Units ordered during the selected date range and percentage change with respect to previous period.  ',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', ['ordered_units']),
            getTagData: getTagDataSet1,
            tagConfig: {
              tag1Unit: {
                pre: ''
              },
              tag2Unit: {
                suff: '%'
              }
            }
          }
        },

        widget8: {
          meta: {
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            // noAction: true,
            // action: 'getContentIssuesData',
            action: 'getOrderedProductSales',
            listen: {
              salesDBFilterApplied: {
                action: 'getOrderedProductSales'
              },
              salesDashboardSearchTriggered: {
                action: 'getOrderedProductSales',
                transform: searchTriggeredTransform
              }
            },
            key: 'avg_selling_price',
            localFilters: []
          },
          header: {
            show: true,
            title: 'Average Selling Price',
            tooltip:
              'Average Selling Price: Average selling price during selected date range and percentage change with respect to previous period. ',
            actions: {}
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            APIConfig: getApiConfig('sales_dashboard', ['avg_selling_price']),
            getTagData: getTagDataSet1,
            tagConfig: tilesTagConfig
          }
        },
        widget9: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'pcogsTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Cost of Goods Sold (PCOGS)',
            tooltip: 'Top 10 SKUs by most change in PCOGS',
            actions: {
              download: {
                fileName: 'PCOGS Table',
                colMap: tableDownloadMap.widget9.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'PCOGS Change: Change in PCOGS in the selected period compared to previous time period',
              'PCOGS Change%: Percenatge change in COGS with respect to previous period',
              'Current period PCOGS: Units ordered in this period multiplied by unit COGS',
              'Previous period PCOGS: Units ordered in the previous period multiplied by unit COGS',
              'Unit COGS of the ASIN in the period selected',
              'Shipped units Current: Number of Units shipped in the time period selected',
              'Shipped units Previous: Number of units shipped in he previous period.'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig(
              ['pcogs', 'unit_cogs', 'units_shipped'],
              'PVP_DIFF'
            )
          }
        },

        widget10: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'opsTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Ordered Product Sales (OPS)',
            tooltip:
              'Top 10 SKUs by the biggest change in OPS compared to previous period. ',
            actions: {
              download: {
                fileName: 'OPS Table',
                colMap: tableDownloadMap.widget10.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              "Revenue Change: Change in retailer's ordered revenue in the selected period compared to previous period",
              "Revenue Change%: Percentage change in retailer's ordered revenue with respect to previous period",
              'Current Period Revenue: Ordered revenue for the SKU in the selected date range',
              'Previous Period Revenue: Ordered revenue for the SKU in the previous period',
              'Average Selling Price Current: Average Offer Price of the ASIN on Amazon in the selected date range',
              'Average Selling Price Previous: Average Offer Price of the ASIN on Amazon in the previous period'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig(
              ['ordered_product_sales', 'avg_selling_price'],
              'PVP_DIFF'
            )
          }
        },

        widget11: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'marginTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Gross Retailer Margin',
            tooltip: 'Top 10 SKUs by biggest change in gross retailer margin. ',
            actions: {
              download: {
                fileName: 'Gross Retailer Margin Table',
                colMap: tableDownloadMap.widget11.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              "Margin Change: Change in retailer's gross margin in the time period selected compared to previous period",
              "Margin Change%: Percentage change in retailer's gross margin compared to retailer's margin in previous period",
              'Current period Margin: OPS - PCOGS for the selected date range. ',
              'Previous period Margin: OPS - PCOGS in the previous period. ',
              'Average Selling Price Current: Average Offer Price of the ASIN on Amazon in the selected date range. ',
              'Average Selling Price Previous: Average Offer Price of the ASIN on Amazon in the previous period.'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig(
              ['gross_retailer_margin', 'avg_selling_price'],
              'PVP_DIFF'
            )
          }
        },

        widget12: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'oosTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Revenue Lost Due To OOS',
            tooltip:
              'Top 10 SKUs by biggest revenue loss due to OOS during the selected date range. ',
            actions: {
              download: {
                fileName: 'OOS Table',
                colMap: tableDownloadMap.widget12.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'The revenue lost due to the ASIN being unavailable for sale for the selected period.',
              'The revenue lost due to the ASIN being unavailable for sale for the previous period.',
              'Current OOS days: Number of Out Of Stock days in the selected date range',
              'Previous OOS days: Number of Out of Stock days in the previous period',
              'Change in revenue lost due to OOS: Change in revenue in the selected time period compared to previous period.',
              'Last 4 weeks revenue: Amazon revenue in last 4 weeks. Calculated by multiplying ordered units with ASP',
              'Open POs: Open Purchase ordered for the ASIN'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfigType2([
              'oos_skus',
              'ordered_revenue_last_30days',
              'rep_oos',
              'oos_days_in_range',
              'open_pos'
            ])
          }
        },

        widget13: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'lbbTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Revenue Lost Due To Lost Buy Box',
            tooltip:
              'Top 10 SKUs by biggest revenue loss due to Buybox loss during the selected date range. ',
            actions: {
              download: {
                fileName: 'LBB Table',
                colMap: tableDownloadMap.widget13.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Total revenue lost in current period: Ordered Product Sales ($) lost in the selected time period due to ASINs having lost buy box. Revenue loss is determined by triangulating LBB%, Glance view, Conversion rate and Average Selling Price from AVC.',
              'Total revenue lost in previous period: Ordered Product Sales ($) lost in the selected time period due to ASINs having lost buy box. Revenue loss is determined by triangulating LBB%, Glance view, Conversion rate and Average Selling Price from AVC.',
              'Change in revenue lost due to LBB: Change in revenue lost due to LBB in the time period selected compared to previous period.',
              'LBB%: Lost buy box for the selected period is the percentage glance views where the ASIN had lost the buy box. LBB across the catalog is calculated as the percentage of total LBB glance views across all ASINs out of the total glance views.',
              'Current units at Amazon: Currently number of units held at Amazon inventory'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfigType3([
              'gv_imputations',
              'revenue_lost_lbb',
              'actual_lbb_rate',
              'units_at_amazon'
            ])
          }
        },

        widget14: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'unvlTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Revenue Lost Due To Unavailable Skus',
            tooltip:
              'Top 10 SKUs by biggest revenue loss due to unavailability during the selected date range. ',
            actions: {
              download: {
                fileName: 'Unavailable Skus Table',
                colMap: tableDownloadMap.widget14.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Total revenue lost in current period: Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC.',
              'Total revenue lost in previous period: Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC.',
              'Change in revenue lost due to Unavailable: Change in revenue lost due to Unavailable in the time period selected compared to previous period.',
              'Percentage of glance views when ASIN(s) was not available for sale. Unavailability % uses Rep OOS values from AVC and data from CommerceIQ’s  Market Intelligence to correctly determine the percentage of glance views when an ASIN(s) was not available for sale.',
              'Rep OOS % values as per AVC. Aggregation for the selected time period is the percentage of glance views where the ASINs were not available for sale. Rep OOS across the catalog is calculated as the percentage of total unavailable glance views across all ASINs out of the total glance views.',
              'Current units at Amazon: Currently number of units held at Amazon inventory',
              'Last 4 weeks revenue: Amazon revenue in last 4 weeks. Calculated by multiplying ordered units with ASP',
              'Open POs: Open Purchase ordered for the ASIN'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfigType3([
              'gv_imputations',
              'revenue_lost_unavailable',
              'ordered_revenue_last_30days',
              'open_pos',
              'actual_unavailability',
              'actual_rep_oos',
              'units_at_amazon'
            ])
          }
        },

        widget15: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'orderedUnitsTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Ordered Units',
            tooltip: 'Top 10 SKUs by biggest change in ordered units. ',
            actions: {
              download: {
                fileName: 'Ordered Units Table',
                colMap: tableDownloadMap.widget15.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Units sold change: Change in units ordered in the selected time period compared to previous period',
              'Units sold change%: Change in ordered units divided by units ordered in previous period',
              'Current period units sold: Units ordered in the selected date range',
              'Previous period units sold: Units ordered in the previous period',
              'Revenue Change: Revenue change in the selected time period compared to previous period',
              'Revenue Change%: Change in revenue is divided by revenue in the previous period.',
              'Current period Revenue: Revenue generated in the selected time period',
              'Previous period Revenue: Revenue generated in the previous time period'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig(
              ['ordered_units', 'pcogs', 'ordered_product_sales'],
              'PVP_DIFF'
            )
          }
        },

        widget16: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'avgSellingPriceTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Average Selling Price',
            tooltip: 'Top 10 SKUs by biggest change in Avg Selling Price. ',
            actions: {
              download: {
                fileName: 'Average Selling Price Table',
                colMap: tableDownloadMap.widget16.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Average Selling Price change: Change in Average Selling Price in the selected time period compared to previous period',
              'Average Selling Price change%: Average Selling Price change divided by previous period ASP',
              'Current period ASP: Average Selling Price in the selected time period',
              'Previous period ASP: Average Selling Price in the previous period',
              'Last 4 weeks revenue: Amazon revenue in last 4 weeks. Calculated by multiplying ordered units with ASP'
            ],
            defaultOrderBy: [
              {
                dimension: 'is_catalog_sku',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig([
              'avg_selling_price',
              'ordered_revenue_last_30days'
            ])
          }
        },

        widget17: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            action: 'getOPSTableData',
            key: 'priceWar',
            localFilters: [
              {
                dimensionName: 'price_war_index',
                dimensionValue: '0.5',
                operator: 'GREATER_THAN_OR_EQUAL_TO'
              }
            ],
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: function (config, data, that) {
                  if (!config.meta.localFilters) {
                    config.meta.localFilters = [];
                  }
                  for (var i = 0; i < config.meta.localFilters.length; i++) {
                    if (
                      config.meta.localFilters[i].dimensionName === 'search'
                    ) {
                      config.meta.localFilters.splice(i, 1);
                    }
                  }

                  if (data && data.length > 0) {
                    config.meta.localFilters.push({
                      dimensionName: 'search',
                      operator: 'ILIKE',
                      dimensionValue: data[0].dimensionValue
                    });
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'Price War',
            tooltip:
              'Price War: SKUs currently under price war, sorted based on price war index in descending order and shown top SKUs.',
            actions: {
              download: {
                fileName: 'Price War Table',
                colMap: tableDownloadMap.widget17.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Revenue: Amazon revenue in last 4 weeks. Calculated by multiplying ordered units with ASP',
              'Price War Index: Price War Index for this ASIN',
              'Amazon offer Price: Updated offer Price on Amazon',
              "Retailer: Retailer's name with which the ASIN is in price war",
              "Retailer's offer Price"
            ],
            APIConfig: {
              cubeName: 'price_war_v2',
              measuresList: [
                'count_total',
                'asin_name',
                'asin_hyperlink',
                'client_image_url',
                'ordered_revenue_last_30days',
                'client_offer_price',
                'comp_name_latest',
                'comp_offer_price',
                'price_war_index_max',
                'priority'
              ],
              groupByDimensionsList: ['asin', 'comp_name'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: true,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  ['asin']
              },
              limit: 10,
              page: 1,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              orderByList: [
                {
                  dimension: 'price_war_index_max',
                  direction: 'DESC'
                },
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ]
            }
          }
        },
        // Glance Views Table Widget Config
        widget19: {
          meta: {
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getOPSTableData',
            key: 'glanceViewsTableData',
            listen: {
              salesDBFilterApplied: {
                action: 'getOPSTableData'
              },
              salesDashboardSearchTriggered: {
                action: 'getOPSTableData',
                transform: searchTriggeredTransform
              }
            }
          },
          header: {
            title: 'Glance Views',
            tooltip:
              'Top 10 SKUs by current period glance view sorted in descending order.',
            actions: {
              download: {
                fileName: 'Glance Views Table',
                colMap: tableDownloadMap.widget19.tableKeys,
                action: 'downloadInSalesDashboardPage',
                transform: tableTransform.bind(that)
              }
            }
          },
          footer: {
            date: 'sales_by_account_type'
          },
          body: {
            tooltipTexts: [
              'Amazon ASIN',
              'Glance Views Change %: Percentage change in glance views compared to glance views in previous period.',
              'Current period glance views: Glance views for the SKU in the selected date range.',
              'Previous period glance views: Glance views for the SKU in the previous period.',
              'Current period units sold: Units ordered in the selected date range.',
              'Previous period units sold: Units ordered in the previous period.',
              'Current period revenue: Ordered revenue for the SKU in the selected date range.',
              'Previous period revenue: Ordered revenue for the SKU in the previous period.'
            ],
            defaultOrderBy: [
              {
                dimension: 'ds_glance_views',
                direction: 'DESC'
              }
            ],
            APIConfig: getTableApiConfig(
              [
                'ds_glance_views',
                'ordered_units',
                'pcogs',
                'ordered_product_sales'
              ],
              'PVP_DIFF',
              true
            )
          }
        }
      }
    };
  }
};
