<template>
  <div class="u-width-100">
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <div
        class="u-display-flex u-flex-align-items-flex-start"
        :class="[
          !isActionApplicable ? 'u-color-grey-x-light' : 'u-color-grey-base'
        ]"
      >
        <div
          class="u-flex-0 logo-wrapper"
          :class="!isActionApplicable ? 'opacity-0_5' : ''"
        >
          <rb-icon
            v-show="isBoomerangActionSupported"
            v-tippy="{ placement: 'bottom', distance: -15, maxWidth: '500px' }"
            :title="'Actions with this icon are supported by the CommerceIQ platform.'"
            class="rb-icon--small u-color-blue-base"
            :icon="'logo'"
          />
        </div>
        <div
          v-tippy="tippyOptions"
          class="cell--content u-text-overflow-ellipsis u-spacing-pl-xs"
        >
          {{ displayText }}
        </div>
        <div
          :id="'boomerangActionsPopUp' + _uid"
          class="boomerangActionsCell"
        >
          <ul>
            <li
              v-for="(action, index) in actionsBullets"
              :key="index"
              class="u-spacing-pb-xs"
            >
              {{ action }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import actionsMixin from '@/utils/mixins/actionsMixin';

export default Vue.extend({
  name: 'BoomerangActions',
  mixins: [actionsMixin],
  data() {
    return {
      tippyOptions: {
        html: '#boomerangActionsPopUp' + this._uid,
        reactive: true,
        placement: 'top',
        offset: '-10, -50',
        delay: [500, 0],
        animation: 'scale',
        interactive: true,
        maxWidth: '200px',
        theme: 'click-to-expand',
        duration: [150, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    displayText() {
      let displayText = 'NA';
      if (this.params.data.recommended_actions) {
        const allActionsTextMap = JSON.parse(
          this.params.data.recommended_actions
        );
        for (var key in allActionsTextMap) {
          displayText = allActionsTextMap[key];
        }
      }
      return displayText;
    },
    actionsBullets() {
      return this.displayText.split(',');
    }
  },
  mounted() {
    var clamp = Math.ceil((this.params.eGridCell.clientHeight - 32) / 16);
    clamp = clamp > 0 ? clamp : 1;
    this.params.eGridCell.classList.add('u-clamp-' + clamp);
    // remove the click to expand feature if no ellipsis is present
    this.$nextTick(() => {
      let toExpand = false;
      const el = this.$el.querySelector('.cell--content');
      if (
        el.clientHeight < el.scrollHeight - 1 ||
        el.clientWidth < el.scrollWidth
      ) {
        toExpand = true;
      } else if (el.children && el.children.length > 0) {
        const children = el.querySelectorAll('*');
        for (const child of children) {
          if (
            child.clientWidth < el.scrollWidth ||
            child.clientWidth < child.scrollWidth
          ) {
            toExpand = true;
            break;
          }
        }
      }
      if (!toExpand) {
        el._tippy.destroy();
        el.removeAttribute('title');
        el.removeAttribute('data-original-title');
        el.classList.add('u-cursor-text');
      } else {
        el.classList.add('u-cursor-pointer');
      }
    });
  }
});
</script>

<style scoped lang="css">
.logo-wrapper {
  padding-top: 1px;
}
@supports not (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content,
  .u-clamp-2 .cell--content,
  .u-clamp-3 .cell--content,
  .u-clamp-4 .cell--content,
  .u-clamp-5 .cell--content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    word-break: break-all;
  }
}
@supports (-webkit-line-clamp: 1) {
  .u-clamp-1 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-2 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-3 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-4 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
  .u-clamp-5 .cell--content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    word-break: break-all;
  }
}
</style>
