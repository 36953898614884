<template>
  <div
    class="u-display-flex u-flex-direction-column u-height-100 custom_report_wrapper u-width-100"
  >
    <div
      class="u-display-flex u-spacing-ph-l u-flex-justify-content-space-around u-flex-align-items-space-around u-flex-direction-column sticky u-spacing-pb-l u-header-box-shadow u-width-100 u-spacing-mb-0"
      style="z-index: 100"
    >
      <rb-filter-panel
        :key="filterKey"
        :listener-event="filters.id"
        :has-search="filters.hasSearch"
        :new-date="selectedDashboard.calendarKey ? true : false"
        :allow-date-compare="true"
        :max-date="selectedDashboard.calendarKey"
        :default-date-post-range="'last30Days'"
        :hide-filter="true"
        :hide-add-filter="true"
        :loader-status="loaderStatus"
        :apply-filter="applyFilter"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <span
            class="u-spacing-mr-s back-arrow"
            @click.stop="backButton()"
          >
            <rb-icon
              :icon="'arrow_back'"
              class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
            />
          </span>
          <PageTitle
            :title="selectedDashboard && selectedDashboard.name"
            :is-bookmark-require-title="true"
          />
        </div>
        <div
          slot="download"
          expoter="display-none"
        >
          <rb-button
            v-tippy="{ placement: 'top', arrow: false }"
            :title="'Click here to export the dashboard as a PDF. The current selections and data present on the dashboard will be exported.'"
            text="Export as PDF"
            icon-left="share"
            :type="'hollow'"
            :click-fn="exportAsPdf"
            :disabled="data.load"
            class="u-spacing-ml-m hollow-outlined-btn u-bg-color-transparent-base export-pdf"
            expoter="display-none"
          />
        </div>
      </rb-filter-panel>
    </div>
    <div
      class="u-spacing-m-m u-overflow-auto u-tableau-wrapper u-envelop-width u-width-100"
    >
      <card-widget
        class="u-width-100"
        :data="data"
        :config="config"
      >
        <div slot="body">
          <div class="iframe-container u-width-100">
            <div
              id="tableauContainer"
              class="u-width-100"
            />
          </div>
        </div>
      </card-widget>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import cardWidget from '@/components/widgets/cardWidget';
import customReportsUtils from '@/components/pages/insights/customReports/utils';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import urlHelper from '@/utils/helpers/url';
import tabIdleTimeout from '@/utils/tabIdleTimeout';
import Vue from 'vue';
import { filters } from '@ciq-dev/ciq-ui';
import { isEmpty } from 'lodash';
export default {
  name: 'CustomReportWrapper',
  components: {
    loader,
    cardWidget,
    rbFilterPanel
  },
  mixins: [tabIdleTimeout],
  data() {
    return {
      sessionIdentifier: null,
      loaderStatus: false,
      timeKeeper: {
        loadStart: null,
        interactiveStart: null
      },
      tableauInstance: null,
      filterKey: 0,
      newDashboardsAccessList: [],
      selectedDashboard: {},
      filters: {
        id: 'tableau-dashboard-filters',
        hasSearch: true,
        search: {
          enable: false
        }
      },
      dateObject: {},
      data: {
        load: true
      },
      config: {
        meta: {
          noAction: true
        },
        footer: {
          show: false
        },
        header: {
          show: false
        }
      }
    };
  },
  computed: {
    bookmarkPath() {
      return this.$route.fullPath
        .replace('?refresh=true&', '')
        ?.replace('?refresh=true', '');
    },
    clientDashboards() {
      return this.$store.getters.getCustomReportsClientDashboards;
    },
    dashUrl() {
      const urlDashName = this.$route.params.id;
      const dashboardsList = this.clientDashboards;
      this.selectedDashboard = dashboardsList.filter((dash) => {
        return urlDashName === urlHelper.convertNameToUrlSection(dash.name);
      })[0];
      this.filterKey = this.filterKey + 1;
      return this.selectedDashboard.url;
    },
    tryNewDashSwitch() {
      return this.$store.getters.getVersionToUse === 2;
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'custom-reports') {
        this.startSession();
        this.filterKey = this.filterKey + 1;
        this.initTableauDashboard();
        window.scrollTo(0, 0);
      }
    }
  },
  mounted() {
    // component will be mounted only once for all dashboards. So we are adding sessionIdentifer in Route change also,
    // Route listener is not called before mounting.
    this.startSession();
    this.tabIdleTimeoutHandler = this.initTableauDashboard;
    this.tabIdleTimeout = Vue.options.filters.config_check(
      'feature.tab_idle.tableau.timeout'
    );
    this.$store.dispatch('check_and_fetch_clients_dashboard').then(() => {
      customReportsUtils.initTableauJS(this.initTableauDashboard);
    });
  },
  methods: {
    async initTableauDashboard() {
      try {
        var containerDiv = document.getElementById('tableauContainer');
        containerDiv.innerHTML = '';
        const tableauDiv = document.createElement('div');
        tableauDiv.classList.add('tableauContainer');
        containerDiv.appendChild(tableauDiv);
        this.data.load = true;
        var url;
        var options = {
          hideTabs: true,
          embed: 'yes',
          showAppBanner: 'false',
          ':showShareOptions': 'false',
          display_count: 'no',
          showVizHome: 'no',
          toolbarPosition: 'TOP',
          hideToolbar: false,
          toolbar: 'yes',
          onFirstInteractive: () => {
            this.data.load = false;
            this.timeKeeper.interactiveStart = window.performance.now();
            const timeToInteract =
              this.timeKeeper.interactiveStart - this.timeKeeper.loadStart;
            this.$logger.logEvent({
              eventType: 'custom_reports_init',
              dashboard_url: `${this.$route.params.id}`,
              sessionIdentifier: this.sessionIdentifier,
              step: 'onFirstInteractive',
              loadTime: timeToInteract,
              version: this.$store.getters.getVersionToUse
            });
            if (
              JSON.stringify(initialDates) !== JSON.stringify(this.dateObject)
            ) {
              customReportsUtils.changeDateParamsAndRefresh(
                this.tableauInstance,
                this.dateObject,
                this.sessionIdentifier
              );
            }
          }
        };

        if (this.tryNewDashSwitch) {
          url = await customReportsUtils.getTrustedForClient(this.dashUrl);
        } else {
          url = await customReportsUtils.getTrustedUrl(this.dashUrl);
          // const clientdetailsid = 995;
          const { client } = urlHelper.getClientInfoFromUrl();
          const clientdetailsid =
            filters.config_check('feature.tableau.client_id_map') ||
            this.getClientNameClientDbMapping(client);
          // const client = 'abbott';
          options.Clientdetailsid = clientdetailsid;
          options.Clientdb = this.getClientNameClientDbMapping(client);
        }
        const initialDates = Object.assign({}, this.dateObject);
        if (!isEmpty(initialDates)) {
          options['Start Date 1'] = this.dateObject.from;
          options['End Date 1'] = this.dateObject.to;
          options['Start Date Pre'] = this.dateObject.compare_from;
          options['End Date Pre'] = this.dateObject.compare_to;
        }
        // options['Profile Name'] = ['(None)'];
        this.tableauInstance = new window.tableau.Viz(tableauDiv, url, options);
      } catch (error) {
        this.data.load = false;
        this.$logger.logEvent({
          eventType: 'custom_reports_error',
          dashboard_url: `${this.$route.params.id}`,
          sessionIdentifier: this.sessionIdentifier,
          step: 'onFirstInteractive',
          version: this.$store.getters.getVersionToUse
        });
        console.error(error);
        this.$snackbar.open({
          message: 'Something Went Wrong !!' || error,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    },
    applyFilter(date) {
      this.dateObject = date.date_range;
      customReportsUtils.changeDateParamsAndRefresh(
        this.tableauInstance,
        this.dateObject
      );
    },
    async getDashBoardFromConfig() {
      const urlDashName = this.$route.params.id;
      const dashboardsList = await customReportsUtils.getCustomDashboardsList();
      this.selectedDashboard = dashboardsList.filter((dash) => {
        return urlDashName === urlHelper.convertNameToUrlSection(dash.name);
      })[0];
      this.filterKey = this.filterKey + 1;
      return this.selectedDashboard.url;
    },
    getClientNameClientDbMapping(clientName) {
      // remove all hypen from the client name
      const clientDbName = clientName.replaceAll('-', '');
      return clientDbName;
    },
    startSession() {
      this.sessionIdentifier = new Date().getTime();
      this.timeKeeper.loadStart = window.performance.now();
      this.$logger.logEvent({
        eventType: 'custom_reports_init',
        dashboard_url: `${this.$route.params.id}`,
        sessionIdentifier: this.sessionIdentifier,
        step: 'mount',
        version: this.$store.getters.getVersionToUse
      });
    },
    backButton() {
      this.$router.push({ path: `${this.$store.getters.getRootPath}reports` });
    },
    exportAsPdf() {
      this.tableauInstance.showExportPDFDialog();
    }
  }
};
</script>
