<template>
  <addEntity
    class="catalog-entity-catalog-manage"
    :page-title="textConfigs.pageTitle"
    :right-table-title="textConfigs.rightTableTitle"
    :widgets-config="config"
    :get-tag-desc="getTagDesc"
    :all-entities="allEntities"
    :subset-entities="subsetEntities"
    :initial-header-value="dimensionValue ? dimensionValue : 'Untitled'"
    :show-delete="dimensionValue ? true : false"
    :show-loader="showLoader"
    :warning-text="textConfigs.warningText"
    :prefer-emit-handlers="true"
    :static-header-text="dimensionLabel"
    :entity-processing-status="entityProcessiongStatus"
    :do-special-character-validation="false"
    @onUpdate="handleAsinUpdates"
    @doDeleteEntity="doDeleteEntity"
  />
</template>
<script>
import addEntity from '@/pages/toolsAndSettings/entityTagging/common/createCustomEntity/index.vue';
import widgetsConfig from './widgets';
import * as entityUtils from '@/pages/toolsAndSettings/entityTagging/entityUtils.js';
import { Entities } from '@/pages/toolsAndSettings/const.js';
import entityConfigs from '@/pages/toolsAndSettings/entityConfig.js';

export default {
  components: {
    addEntity
  },
  props: {
    entityType: {
      type: String,
      default: Entities.catalog
    }
  },
  data() {
    return {
      config: null,
      dimensionName: null,
      showLoader: false,
      dimensionValue: null,
      dimensionLabel: null
    };
  },
  computed: {
    getTagDesc() {
      return this.$store.getters.getTagDescription;
    },
    allEntities() {
      const isEntityCampaign = this.entityType === Entities.campaign;
      return this.$store.getters[
        isEntityCampaign ? 'getLeftTableData' : 'getAllEntities'
      ];
    },
    subsetEntities() {
      const isEntityCampaign = this.entityType === Entities.campaign;
      return this.$store.getters[
        isEntityCampaign ? 'getRightTableData' : 'getSkusForCatalogValues'
      ];
    },
    entityProcessiongStatus() {
      return this.$store.getters.getEntityPostProcessingStatus([
        this.entityType
      ]);
    },
    textConfigs() {
      const label = this.entityType === Entities.campaign ? 'Campaign' : 'SKU';
      return {
        pageTitle: `Choose ${label}s that should be associated with ${
          this.dimensionLabel
        } ${this.dimensionValue ? this.dimensionValue : ''}`,
        rightTableTitle: `${label}s with ${this.dimensionLabel}`,
        warningText: `Adding or removing ${label}s from a filter will update the filter for all Vendor Codes/Platforms the ${label}s is associated with.`
      };
    },
    currentEntityConfig() {
      return entityConfigs[this.entityType].config;
    }
  },

  watch: {
    $route(newValue) {
      this.fetchDimensionDetailsFromUrl();
    }
  },
  created() {
    this.config = widgetsConfig[this.entityType];
    this.fetchDimensionDetailsFromUrl();
  },
  methods: {
    returnToParentPage() {
      this.showLoader = false;
      const { path, query } = this.config.config(this).redirectToParentRoute;
      this.$router.push({ path: path || '/', query: query || null });
    },
    doDeleteEntity() {
      this.updateAsinForDimensionValue(
        [],
        this.dimensionValue,
        this.dimensionName
      ).then(() => {
        this.returnToParentPage();
      });
    },
    handleEntityValueRename(columnId, newColmValue, oldColmValue) {
      return entityUtils.doEntityValueRename(
        columnId,
        newColmValue,
        oldColmValue,
        this.entityType
      );
    },
    async handleAsinUpdates(updatedPayload) {
      try {
        this.showLoader = true;
        const currSkus = updatedPayload.updatedRows.map((asin) => {
          return asin[this.currentEntityConfig.secondaryKey];
        });
        if (
          updatedPayload.title !== this.dimensionValue &&
          updatedPayload.renameFilter
        ) {
          // delete the previous dimension value since rename operation was done. and update the asins with new name
          await this.handleEntityValueRename(
            this.dimensionName,
            updatedPayload.title,
            this.dimensionValue
          );
          this.$logger.logEvent({
            eventType: 'ui_edit',
            entityType: this.entityType,
            type: this.currentEntityConfig.secondaryKey,
            action: 'RENAME'
          });
        }
        if (currSkus.length) {
          this.$logger.logEvent({
            eventType: 'ui_edit',
            entityType: this.entityType,
            type: this.currentEntityConfig.secondaryKey,
            action: 'UPDATE'
          });
        }
        await this.updateAsinForDimensionValue(
          currSkus,
          updatedPayload.title,
          this.dimensionName
        );
        this.returnToParentPage();
      } catch (error) {
        this.showLoader = false;
        this.$snackbar.open({
          message: 'Something Went Wrong with Update. Please try again later',
          duration: 5000,
          actionText: ''
        });
      }
    },
    fetchDimensionDetailsFromUrl() {
      this.dimensionName = decodeURIComponent(
        this.$route.query.dimension || ''
      );
      this.dimensionValue = decodeURIComponent(this.$route.query.tag);
      this.dimensionLabel = decodeURIComponent(this.$route.query.label);
    },
    updateAsinForDimensionValue(asins, dimensionValue, dimensionId) {
      const dataUpdatePayload = {
        entity: this.entityType,
        entityDataUpdates: []
      };
      dataUpdatePayload.entityDataUpdates.push({
        entityId: asins,
        values: {
          dimensionLabel: dimensionValue,
          dimensionColumn: dimensionId
        }
      });
      this.showLoader = true;
      return this.$store
        .dispatch('updateEntitiesForMetadata', {
          dataUpdatePayload,
          entity: dataUpdatePayload.entity
        })
        .then((status) => {
          this.showLoader = false;
          if (!status.success) {
            this.$snackbar.open({
              message:
                'Something Went Wrong with Update. Please try again later',
              duration: 5000,
              actionText: ''
            });
          }
        });
    }
  }
};
</script>
<style lang="css">
.catalog-entity-catalog-manage {
  overflow: hidden;
}
.catalog-entity-catalog-manage .static-title-entity-sub-entity-page {
  width: fit-content;
  color: #8b8f93;
  margin: 0.8rem;
}
.catalog-entity-catalog-manage .editable-title-entity-sub-entity-page {
  color: #ffa800;
  text-decoration: underline;
}
</style>
