import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import { cloneDeep, extend } from 'lodash';
import {
  filterResponse,
  convertArrayToMap,
  getNewSovSettings
} from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';
import {
  STANDARD_PREFIX,
  CUSTOM_PREFIX,
  BRANDS_EXCLUSION_LIST,
  HIERARCHY_FILTER_DELIMITER,
  HIERARCHY_RAW_DELIMITER
} from '@/components/pages/insights/amazon/share-of-voice/templates/constants.js';
import transformer from '@/utils/services/data-transformer';
import CONSTANTS from '@/utils/constants.js';

const initialState = () => {
  return {
    globalViews: { rows: [], load: false, error: false, noData: false },
    pageList: { rows: [], load: false, error: false, noData: false },
    brandsData: { rows: [], load: true, error: false, noData: false },
    clientBrands: { rows: [], load: true, error: false, noData: false },
    customHierarchy: { rows: {}, load: true, error: false, noData: false },
    standardHierarchy: { rows: {}, load: true, error: false, noData: false },
    selectedView: {},
    selectedSovPlacement: {},
    selectedSovType: [],
    selectedAnchorBrand: {},
    anchorBrandsList: [],
    widget5WhereClause: {},
    whereClause: {},
    initalWidgetStates: {},
    unsavedWidgetStates: {},
    selectedCustomShelves: {},
    selectedStandardShelves: {}
  };
};

const state = initialState();

const getters = {
  getBrandsData: (state) => state.brandsData,
  getClientBrandsData: (state) => state.clientBrands,
  getGlobalViews: (state) => state.globalViews,
  getPageList: (state) => state.pageList,
  getSelectedView: (state) => state.selectedView,
  getSelectedSovPlacement: (state) => state.selectedSovPlacement,
  getSelectedSovType: (state) => state.selectedSovType,
  getGlobalFilters: (state) => state.whereClause,
  getWidget5WhereClause: (state) => state.widget5WhereClause,
  getInitialWidgetStates: (state) => state.initalWidgetStates,
  getUnsavedWidgetStates: (state) => state.unsavedWidgetStates,
  getCustomHierarchy: (state) => state.customHierarchy,
  getStandardHierarchy: (state) => state.standardHierarchy,
  getSelectedCustomShelves: (state) => state.selectedCustomShelves,
  getSelectedStandardShelves: (state) => state.selectedStandardShelves
};

const mutations = {
  MARKET_INSIGHTS_RESET_STORE: (state, data) => {
    Object.assign(state, data);
  },
  SET_SELECTED_VIEW: (state, data) => {
    Vue.set(state, 'selectedView', data);
  },
  GLOBAL_VIEWS_RESET: (state, data) => {
    Vue.set(state.globalViews, 'rows', []);
    Vue.set(state.globalViews, 'load', true);
    Vue.set(state.globalViews, 'error', false);
    Vue.set(state.globalViews, 'noData', false);
  },
  GLOBAL_VIEWS_SUCCESS: (state, data) => {
    Vue.set(state.globalViews, 'rows', data);
    Vue.set(state.globalViews, 'load', false);
    Vue.set(state.globalViews, 'error', false);
    Vue.set(state.globalViews, 'noData', false);
    if (data.length === 0) {
      Vue.set(state.globalViews, 'noData', true);
    }
  },
  GLOBAL_VIEWS_FAILURE: (state, data) => {
    Vue.set(state.globalViews, 'rows', []);
    Vue.set(state.globalViews, 'load', false);
    Vue.set(state.globalViews, 'error', true);
    Vue.set(state.globalViews, 'noData', false);
  },
  PAGE_LIST_RESET: (state, payload) => {
    Vue.set(state.pageList, 'rows', []);
    Vue.set(state.pageList, 'load', true);
    Vue.set(state.pageList, 'error', false);
    Vue.set(state.pageList, 'noData', false);
  },
  PAGE_LIST_SUCCESS: (state, payload) => {
    Vue.set(state.pageList, 'rows', payload);
    Vue.set(state.pageList, 'load', false);
    Vue.set(state.pageList, 'error', false);
    Vue.set(state.pageList, 'noData', false);
    if (payload.length === 0) {
      Vue.set(state.pageList, 'noData', true);
    }
  },
  PAGE_LIST_FAILURE: (state, data) => {
    Vue.set(state.pageList, 'rows', []);
    Vue.set(state.pageList, 'load', false);
    Vue.set(state.pageList, 'error', true);
    Vue.set(state.pageList, 'noData', false);
  },
  SET_GLOBAL_WHERE_CLAUSE: (state, data) => {
    const dateRange = state.whereClause.date_range;
    const timeRange = state.whereClause.time_range;
    if (!state.whereClause.date_range) {
      Vue.set(state.whereClause, 'date_range', dateRange);
    }
    if (!state.whereClause.time_range) {
      Vue.set(state.whereClause, 'time_range', timeRange);
    }
    const { customShelves, standardShelves } = getSelectedHierarchies(data);
    Vue.set(state, 'selectedCustomShelves', customShelves);
    Vue.set(state, 'selectedStandardShelves', standardShelves);
    const newWhereClause = getWhereClause(
      data,
      state.whereClause,
      state.customHierarchy.rows.length,
      Object.keys(state.standardHierarchy.rows).length,
      data.page
    );
    Vue.set(state, 'whereClause', newWhereClause);
  },
  SET_WIDGET5_WHERE_CLAUSE: (state, data) => {
    const newWhereClause = getWhereClause(
      data,
      state.widget5WhereClause,
      state.customHierarchy.rows.length,
      Object.keys(state.standardHierarchy.rows).length,
      data.page
    );
    Vue.set(state, 'widget5WhereClause', newWhereClause);
  },
  SET_INITIAL_WIDGETS_STATE: (state, data) => {
    Vue.set(state, 'initalWidgetStates', data);
  },
  SET_UNSAVED_WIDGETS_STATE: (state, data) => {
    Vue.set(state, 'unsavedWidgetStates', data);
  },
  BRANDS_RESET: (state, payload) => {
    Vue.set(state.brandsData, 'rows', []);
    Vue.set(state.brandsData, 'load', true);
    Vue.set(state.brandsData, 'error', false);
  },
  BRANDS_SUCCESS: (state, payload) => {
    Vue.set(state.brandsData, 'rows', payload);
    Vue.set(state.brandsData, 'load', false);
    Vue.set(state.brandsData, 'error', false);
  },
  BRANDS_FAILURE: (state, data) => {
    Vue.set(state.brandsData, 'rows', []);
    Vue.set(state.brandsData, 'load', false);
    Vue.set(state.brandsData, 'error', true);
  },
  CLIENT_BRANDS_RESET: (state, payload) => {
    Vue.set(state.clientBrands, 'rows', []);
    Vue.set(state.clientBrands, 'load', true);
    Vue.set(state.clientBrands, 'error', false);
  },
  CLIENT_BRANDS_SUCCESS: (state, payload) => {
    Vue.set(state.clientBrands, 'rows', payload);
    Vue.set(state.clientBrands, 'load', false);
    Vue.set(state.clientBrands, 'error', false);
  },
  CLIENT_BRANDS_FAILURE: (state, data) => {
    Vue.set(state.clientBrands, 'rows', []);
    Vue.set(state.clientBrands, 'load', false);
    Vue.set(state.clientBrands, 'error', true);
  },
  CUSTOM_HIERARCHY_RESET: (state, payload) => {
    Vue.set(state.customHierarchy, 'rows', []);
    Vue.set(state.customHierarchy, 'load', true);
    Vue.set(state.customHierarchy, 'error', false);
  },
  CUSTOM_HIERARCHY_SUCCESS: (state, payload) => {
    Vue.set(state.customHierarchy, 'rows', payload);
    Vue.set(state.customHierarchy, 'load', false);
    Vue.set(state.customHierarchy, 'error', false);
    Vue.set(state.customHierarchy, 'noData', false);
  },
  CUSTOM_HIERARCHY_FAILURE: (state, data) => {
    Vue.set(state.customHierarchy, 'rows', []);
    Vue.set(state.customHierarchy, 'load', false);
    Vue.set(state.customHierarchy, 'error', true);
  },
  STANDARD_HIERARCHY_RESET: (state, payload) => {
    Vue.set(state.standardHierarchy, 'rows', {});
    Vue.set(state.standardHierarchy, 'load', true);
    Vue.set(state.standardHierarchy, 'error', false);
  },
  STANDARD_HIERARCHY_SUCCESS: (state, payload) => {
    Vue.set(state.standardHierarchy, 'rows', payload);
    Vue.set(state.standardHierarchy, 'load', false);
    Vue.set(state.standardHierarchy, 'error', false);
    Vue.set(state.standardHierarchy, 'noData', false);
  },
  STANDARD_HIERARCHY_FAILURE: (state, data) => {
    Vue.set(state.standardHierarchy, 'rows', {});
    Vue.set(state.standardHierarchy, 'load', false);
    Vue.set(state.standardHierarchy, 'error', true);
  }
};

function getSelectedHierarchies(oFilter) {
  const formmatedCustomShelf = (oFilter?.values?.customShelf || []).map(
    (item) =>
      item.replaceAll(HIERARCHY_RAW_DELIMITER, HIERARCHY_FILTER_DELIMITER)
  );
  const formattedStandardShelf = (oFilter?.values?.standardShelf || []).map(
    (item) =>
      item.replaceAll(HIERARCHY_RAW_DELIMITER, HIERARCHY_FILTER_DELIMITER)
  );
  return {
    customShelves: formmatedCustomShelf,
    standardShelves: formattedStandardShelf
  };
}

function allHierarchyFiltersSelectedPayload(
  dimensionNameValueList,
  prefix,
  page
) {
  dimensionNameValueList.push({
    dimensionName:
      page === 'SOV_INTRADAY' ? 'shelf_hierarchy_intraday' : 'shelf_hierarchy',
    dimensionValue: prefix,
    operator: 'STARTSWITH'
  });
}

function hierarchyFiltersPayload(
  dimensionNameValueList,
  prefix,
  hierarchyFilters
) {
  for (var j in hierarchyFilters) {
    const formattedValue =
      hierarchyFilters[j]
        .replaceAll(HIERARCHY_RAW_DELIMITER, HIERARCHY_FILTER_DELIMITER)
        .toLowerCase() + HIERARCHY_FILTER_DELIMITER;
    dimensionNameValueList.push({
      dimensionName: 'shelf_hierarchy',
      dimensionValue: prefix + formattedValue,
      operator: 'STARTSWITH'
    });
  }
}

function findMaxHierarchyLevel(hierarchyFilters) {
  const lengthArray = [];
  hierarchyFilters.forEach((item) => {
    lengthArray.push((item || '').split(HIERARCHY_RAW_DELIMITER).length);
  });
  return Math.max(...lengthArray);
}

function getWhereClause(
  oFilter,
  where,
  numberOfCustomShelves,
  numberOfStandardShelves,
  page
) {
  const dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i === 'standardShelf') {
      const maxLength = findMaxHierarchyLevel(oFilter.values[i]);
      if (
        numberOfStandardShelves === oFilter.values[i].length &&
        maxLength === 1
      ) {
        allHierarchyFiltersSelectedPayload(
          dimensionNameValueList,
          STANDARD_PREFIX,
          page
        );
      } else {
        hierarchyFiltersPayload(
          dimensionNameValueList,
          STANDARD_PREFIX,
          oFilter.values[i]
        );
      }
    } else if (i === 'customShelf') {
      const maxLength = findMaxHierarchyLevel(oFilter.values[i]);
      if (
        numberOfCustomShelves === oFilter.values[i].length &&
        maxLength === 1
      ) {
        allHierarchyFiltersSelectedPayload(
          dimensionNameValueList,
          CUSTOM_PREFIX,
          page
        );
      } else {
        hierarchyFiltersPayload(
          dimensionNameValueList,
          CUSTOM_PREFIX,
          oFilter.values[i]
        );
      }
    } else if (i === 'time_range') {
      where.time_range = oFilter.values[i];
    } else if (i !== 'date_range' && i !== 'SOV') {
      if (
        oFilter.values[i].length > 0 &&
        oFilter?.values?.[i]?.[0]?.operator?.operator === 'BETWEEN'
      ) {
        const { obj1, obj2 } =
          transformer.convertBetweenOperatorToGreaterThanLessThan(
            oFilter.values[i][0].value,
            i
          );
        dimensionNameValueList.push(obj1, obj2);
      } else if (
        oFilter.values[i].length > 0 &&
        oFilter.values[i][0].operator
      ) {
        dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      where.date_range = oFilter.values[i];
    }
  }
  where.dimensionNameValueList = dimensionNameValueList;
  return where;
  // state.whereClause = where;
}

const getFilterAndWidgetMetdataSplit = (data) => {
  const unsavedState = cloneDeep(data);
  const filters = unsavedState.filters;
  if (
    filters?.selectedFilter?.filter[0] &&
    Object.keys(filters?.selectedFilter?.filter[0])?.length > 0
  ) {
    Object.keys(filters?.selectedFilter?.filter[0]).forEach((key) => {
      delete filters.selectedFilter.filter[0][key].addtionalMetadata;
      filters.selectedFilter.filter[0][key].values =
        filters.selectedFilter.filter[0][key].values.map((item) => {
          if (typeof item === 'object' && item !== null) {
            return JSON.stringify(item);
          } else {
            return item;
          }
        });
    });
  }

  delete unsavedState.filters;
  return { filters, widgetMetadata: unsavedState };
};

const transformGlobalListResponse = (item) => {
  item.currentUser = false;
  if (item.createdBy === window.user.email) {
    item.currentUser = true;
  }
  item.formattedAt = moment(item.createdAt).format('LLL');
  if (item.groupName !== 'Default Views') {
    item.edit = false;
  }
  return item;
};

const getBrandsFormattedData = (data) => {
  const excludedBrandsRemoved = filterResponse(data, BRANDS_EXCLUSION_LIST);
  return excludedBrandsRemoved.entityData.map((entityUnit) => {
    const flatObject = convertArrayToMap(entityUnit.data);
    flatObject.title = entityUnit.entityValue;
    flatObject.entityValue = entityUnit.entityValue;
    flatObject.brand = entityUnit.entityValue;
    return flatObject;
  });
};

const actions = {
  resetStore: (context, data) => {
    const initialStateCopy = initialState();
    context.commit('MARKET_INSIGHTS_RESET_STORE', initialStateCopy);
  },
  viewChangeContentRestore: (context, data) => {
    const viewsList = context.state.globalViews.rows;
    const currentView = viewsList.find(
      (item) => item.globalViewId === data.globalViewId
    );
    const initialPayload = {
      filters: currentView.pageUserFilters,
      ...currentView.widgetUserSelectionMetadata
    };
    let unsavedPayload = initialPayload;
    if (currentView.unsavedWidgetMetadata) {
      unsavedPayload = {
        filters: currentView.unsavedPageUserFilters,
        ...currentView.unsavedWidgetMetadata
      };
    }
    context.commit('SET_UNSAVED_WIDGETS_STATE', cloneDeep(unsavedPayload));
    context.commit('SET_INITIAL_WIDGETS_STATE', cloneDeep(initialPayload));
  },
  storeSavedStateInViews: (context, data) => {
    const viewsList = context.state.globalViews.rows;
    const currentView = viewsList.find(
      (item) => item.globalViewId === context.state.selectedView.globalViewId
    );
    const { filters, widgetMetadata } = getFilterAndWidgetMetdataSplit(
      context.state.initalWidgetStates
    );
    const { filters: unsavedFilters, widgetMetadata: unsavedWidgetMetadata } =
      getFilterAndWidgetMetdataSplit(context.state.unsavedWidgetStates);

    if (currentView) {
      currentView.pageUserFilters = filters;
      currentView.widgetUserSelectionMetadata = widgetMetadata;

      currentView.unsavedPageUserFilters = unsavedFilters;
      currentView.unsavedWidgetMetadata = unsavedWidgetMetadata;
    }
  },
  resetWidgetsToInitialState: (context, data) => {
    const initialStateCopy = cloneDeep(context.state.initalWidgetStates);
    context.commit('SET_UNSAVED_WIDGETS_STATE', initialStateCopy);
  },
  setUnsavedWidgetState: (context, data) => {
    const oldWidgetStates = cloneDeep(context.state.unsavedWidgetStates) || {};
    const unsavedState = extend(oldWidgetStates, data || {});
    context.commit('SET_UNSAVED_WIDGETS_STATE', unsavedState);
  },
  setInitialWidgetState: (context, data) => {
    const oldWidgetStates = cloneDeep(context.state.initalWidgetStates) || {};
    const unsavedState = extend(oldWidgetStates, data || {});
    context.commit('SET_INITIAL_WIDGETS_STATE', unsavedState);
  },
  saveUnsavedState: (context, data) => {
    const oldWidgetStates = cloneDeep(context.state.unsavedWidgetStates) || {};
    context.state.selectedView.pageUserFilters = oldWidgetStates;
    context.commit('SET_INITIAL_WIDGETS_STATE', oldWidgetStates);
  },
  saveViewAction: (context, data) => {
    let appendAPI = '/global/view/create';
    if (data.globalViewId) {
      appendAPI = '/global/view/update';
    }
    const payload = getFilterAndWidgetMetdataSplit(
      context.state.unsavedWidgetStates
    );
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data, ...payload },
      { append: appendAPI }
    ).then(
      (response) => {
        const { data } = response;
        transformGlobalListResponse(data.globalView);
        return response;
      },
      (error) => {
        return error;
      }
    );
  },
  updateViewTitle: (context, payload) => {
    const newViewsList = context.state.globalViews.rows.map((item) => {
      if (item.globalViewId === payload.globalViewId) {
        item.label = payload.label;
      }
      return item;
    });
    context.commit('GLOBAL_VIEWS_SUCCESS', newViewsList);
  },
  appendToViewList: (context, data) => {
    const currentViewList = cloneDeep(context.state.globalViews);
    currentViewList.rows.push(data);
    context.commit('GLOBAL_VIEWS_SUCCESS', currentViewList.rows);
  },
  removeFromViewList: (context, data) => {
    const currentViewList = cloneDeep(context.state.globalViews);
    const newViewList = currentViewList.rows.filter(
      (item) => item.globalViewId !== data.globalViewId
    );
    context.commit('GLOBAL_VIEWS_SUCCESS', newViewList);
  },
  deleteView: (context, data) => {
    const endPoint = '/global/view/delete';
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data },
      { append: endPoint }
    );
  },
  draftView: (context, data) => {
    const payload = getFilterAndWidgetMetdataSplit(
      context.state.unsavedWidgetStates
    );
    const endPoint = '/global/view/draft';
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { ...data, ...payload },
      { append: endPoint }
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
  },
  setGlobalFilters: (context, data) => {
    context.commit('SET_GLOBAL_WHERE_CLAUSE', data);
  },
  setWidget5Filters: (context, data) => {
    context.commit('SET_WIDGET5_WHERE_CLAUSE', data);
  },
  fetchGlobalView(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('GLOBAL_VIEWS_RESET');
        const { page, persistSettings } = payload;
        const requestBody = { page };
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          requestBody,
          { append: '/global/view/list' }
        );
        if (response.status === 200) {
          const viewList = response.data.globalViews.map((item) => {
            return transformGlobalListResponse(item);
          });
          // const unsavedState = { filters: response.data?.userUnsavedGlobalView?.filters };
          const unsavedState = {
            filters: response.data?.userUnsavedGlobalView?.filters,
            ...response.data?.userUnsavedGlobalView?.widgetMetadata
          };
          if (persistSettings) {
            unsavedState.settings = getNewSovSettings(
              state.unsavedWidgetStates?.settings,
              Object.values(CONSTANTS.crawlTypes).find((v) => v.page === page)
                .value
            );
          }
          context.commit('GLOBAL_VIEWS_SUCCESS', viewList);
          // context.commit('SET_UNSAVED_WIDGETS_STATE', null);
          context.commit('SET_UNSAVED_WIDGETS_STATE', unsavedState);
          // context.commit('SET_INITIAL_WIDGETS_STATE', initialState);
          resolve({ viewList: viewList, response: response });
        } else {
          context.commit('GLOBAL_VIEWS_FAILURE', []);
          reject();
        }
      } catch (err) {
        console.error('err', err);
        context.commit('GLOBAL_VIEWS_FAILURE', []);
        reject(err);
      }
    });
  },

  fetchBrands(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('BRANDS_RESET');
        const { service, request, endPoint } = payload;
        const { data } = await HttpService.post(service, request, {
          append: endPoint
        });
        const newEntityData = getBrandsFormattedData(data);
        context.commit('BRANDS_SUCCESS', newEntityData);
        resolve();
      } catch (e) {
        context.commit('BRANDS_FAILURE', {});
        reject();
      }
    });
  },
  fetchClientSubBrands(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('CLIENT_BRANDS_RESET');
        const { service, request, endPoint } = payload;
        const { data } = await HttpService.post(service, request, {
          append: endPoint
        });
        const newEntityData = getBrandsFormattedData(data);
        context.commit('CLIENT_BRANDS_SUCCESS', newEntityData);
        resolve();
      } catch (e) {
        context.commit('CLIENT_BRANDS_FAILURE', {});
        reject();
      }
    });
  },
  setSelectedView(context, payload) {
    context.commit('SET_SELECTED_VIEW', payload);
  },
  toggleEdit(context, payload) {
    const newViewsList = context.state.globalViews.rows.map((item) => {
      if (item.globalViewId === payload.globalViewId) {
        item.edit = !item.edit;
      } else {
        item.edit = false;
      }
      return item;
    });
    context.commit('GLOBAL_VIEWS_SUCCESS', newViewsList);
  },
  fetchCustomShelves(context, data) {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('CUSTOM_HIERARCHY_RESET');
        const config = {
          entityType: 'keyword',
          tagType: 'custom',
          customType: 'Custom'
        };
        const { data } = await HttpService.get('FETCH_SUGGESTED_ENTITY_TAG', {
          append: `?entityType=${config.entityType}&tagType=${config.tagType}&customType=${config.customType}`
        });
        context.commit('CUSTOM_HIERARCHY_SUCCESS', data);
        resolve();
      } catch (e) {
        console.error('e', e);
        context.commit('CUSTOM_HIERARCHY_FAILURE', []);
        reject(e);
      }
    });
  },
  fetchStandardShelves: (context, data, root) => {
    return new Promise(async (resolve, reject) => {
      context.commit('STANDARD_HIERARCHY_RESET');
      const retailer = context.rootGetters.getRootPath.split('/')[2];
      try {
        const { data } = await HttpService.get('GET_DIGITAL_SHELF_TAGGING', {
          headers: {
            retailerId: retailer
          }
        });
        const { response } = data;
        context.commit('STANDARD_HIERARCHY_SUCCESS', response);
        resolve();
      } catch (e) {
        console.error('e', e);
        context.commit('STANDARD_HIERARCHY_FAILURE', {});
        reject(e);
      }
    });
  },
  fetchPageList(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('PAGE_LIST_RESET');
        const { page } = payload;
        const { globalViewId, pageId } = context.state.selectedView;
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page, globalViewId, pageId },
          { append: '/page/widgets/list' }
        );
        context.commit('PAGE_LIST_SUCCESS', response.data);
        const objectToCloneForUnsavedWidgetStates =
          context.state?.unsavedWidgetStates?.settings ||
          context.state?.initalWidgetStates?.settings;
        const {
          selectedKeywordType: initialKeywordType,
          selectedPlacement: initialSelectedPlacement,
          selectedType: initialSelectedType,
          anchoredBrand: initialAnchoredBrand
        } = context.state?.initalWidgetStates?.settings;
        const {
          selectedKeywordType: unsavedKeywordType,
          selectedPlacement: unsavedSelectedPlacement,
          selectedType: unsavedSelectedType,
          anchoredBrand: unsavedAnchoredBrand
        } = cloneDeep(objectToCloneForUnsavedWidgetStates);
        const defaultSovPlacement =
          unsavedSelectedPlacement || initialSelectedPlacement || {};

        const defaultSovType = unsavedSelectedType || initialSelectedType || [];

        const defaultKeywordType =
          unsavedKeywordType || initialKeywordType || [];

        const initialSettings = {
          settings: {
            selectedPlacement: initialSelectedPlacement,
            selectedType: initialSelectedType,
            selectedKeywordType: initialKeywordType,
            anchoredBrand: initialAnchoredBrand
          }
        };
        const unsavedSettings = {
          settings: {
            selectedPlacement: defaultSovPlacement,
            selectedType: defaultSovType,
            selectedKeywordType: defaultKeywordType,
            anchoredBrand: unsavedAnchoredBrand
          }
        };
        context.dispatch('setInitialWidgetState', initialSettings);
        context.dispatch('setUnsavedWidgetState', unsavedSettings);
        resolve(response);
      } catch (err) {
        context.commit('PAGE_LIST_FAILURE', []);
        reject(err);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
