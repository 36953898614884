import dataTransformer from '@/utils/services/data-transformer.js';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
const instacartConfig = {
  reqBody: {
    cubeName: 'instacart_adgroup_campaign_latest',
    measuresList: [
      'ad_group_name',
      'ad_group_status',
      'default_bid',
      'suggested_bid',
      'min_bid'
    ],
    groupByDimensionsList: ['campaign_id', 'ad_group_id'],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'campaign_id',
          operator: 'LIKE',
          dimensionValue: null
        }
      ]
    }
  },
  adGroupColumnMetadata: function (vueRef) {
    return dictionaryRightCreateAdGroup(vueRef);
  }
};
export default instacartConfig;

export const bidSetting = [
  {
    type: 'acquire_ntb',
    label: 'Optimize, Boost New to Brand',
    desc: 'Boost sales from new-to-brand customers while continuing to maximize sales at a low cost per click.'
  },
  {
    type: 'max_sales',
    label: 'Optimize, Maximize Sales',
    desc: 'Maximize sales at lowest cost per click possible with continual bid optimization.'
  },
  {
    type: 'manual',
    label: 'Manual',
    desc: 'Set your own CPC bids and optional override bids for specific keywords.'
  }
];

export const bidSettingsFilter = (optimizedBiddingGoal = 'default') => {
  const bidTypesConfig = {
    acquire_ntb: bidSetting.filter((val) => val.type === 'acquire_ntb'),
    max_sales: bidSetting.filter(
      (val) => val.type === 'max_sales' || val.type === 'manual'
    ),
    default: bidSetting.filter(
      (val) => val.type === 'max_sales' || val.type === 'manual'
    )
  };

  return bidTypesConfig[optimizedBiddingGoal] || bidTypesConfig.default;
};

const dictionaryRightCreateAdGroup = (vueRef) => [
  {
    name: 'ad_group_name',
    type: 'STRING',
    uiField: {
      uiLabel: 'Ad group name',
      customStyles: 'font-size:14px !important',
      uiType: 'string',
      uiOrder: '1',
      metadata: {
        toolTipText: '',
        tableColumnName: 'ad_group_name',
        showOnUi: true,
        isFixed: false,
        width: 104
      }
    }
  },
  {
    name: 'suggeted bid',
    type: 'custom',
    uiField: {
      widget: 'icon',
      uiLabel: 'Suggested Bid',
      clickHeader: vueRef.addAllSuggestedBids,
      customStyles: 'cursor:pointer; font-size:14px !important',
      headerIcon: {
        icon: 'trending-neutral',
        toolTip: 'Set Suggested Bid',
        class: 'rb-icon--medium u-spacing-pl-m'
      },
      uiType: 'custom',
      uiOrder: '2',
      metadata: {
        toolTipText: 'Set Suggested Bid',
        widget: 'icon',
        type: 'iconText',
        tableColumnName: 'suggested_bid',
        defaultValueColumnName: 'suggestedBid',
        formatType: 'currency',
        displayIcon: 'trending-neutral',
        showOnUi: true,
        isFixed: false,
        width: 62,
        iconClickEvent: vueRef.addSuggestedBid
      }
    }
  },
  {
    name: 'Bid',
    type: 'custom',
    uiField: {
      uiLabel: 'Default CPC Bid',
      customStyles: 'font-size:14px !important',
      uiOrder: '3',
      uiType: 'custom',
      metadata: {
        toolTipText: '',
        widget: 'input',
        type: 'number',
        tableColumnName: 'default_bid',
        defaultValueColumnName: 'default_bid',
        showOnUi: true,
        isFixed: false,
        formatType: 'currency',
        width: 62,
        keyupEvent: '',
        onchangeEvent: (context, event) => {
          // Validatino should happen here
          context.params.data.default_bid = context.bidValue;
          let id = context.params.data.ad_group_id;
          vueRef.adGroupsData.forEach((item) => {
            if (item.ad_group_id === id) {
              item.default_bid = context.bidValue;
            }
            return item;
          });
        }
      }
    }
  }
];

const taggedCell = Vue.extend({
  props: {},
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class="u-display-flex u-width-100">
        <div v-if="paramsData.competitor_asin_title || paramsData.competitor_asin" class="u-width-100 u-flex-direction-column">
          <div class="u-text-overflow-ellipsis u-color-grey-base u-flex-1" v-tippy :title="paramsData.competitor_asin_title || paramsData.competitor_asin">
            <span class=" u-font-weight-600">Product:</span> {{paramsData.competitor_asin_title || paramsData.competitor_asin}}</span>
          </div>
          <div>
            <rb-asin
              v-if="paramsData.competitor_asin && paramsData.competitor_asin_title"
              :asin="paramsData.competitor_asin"
              :product-page-url="paramsData.competitor_asin_product_url"
              tippy-text="Amazon Product Page"
            />
          </div>
        </div>
        <div v-if="paramsData.category">
          <div class="u-text-overflow-ellipsis u-color-grey-base u-flex-1" v-tippy :title="paramsData.category">
            <span class="u-font-weight-600">Category:</span> {{paramsData.category}}</span>
          </div>
        </div>
        </div>    
      `
});

const currency = Vue.extend({
  props: {},
  data() {
    return {
      currency: Vue.prototype.$currency
    };
  },
  computed: {
    paramsData() {
      return this.params?.data || {};
    }
  },
  template: `<div class=" u-color-black-base">
          {{currency}} {{paramsData.suggested_bid}}
        </div>    
      `
});
const dictionaryRight = {};
export function getColumnDefinition(vueRef, dictionary = dictionaryRight) {
  var _columnDefs = [];
  const selectedDictionary = dictionary;
  for (const i in selectedDictionary) {
    let obj = {};
    if (Array.isArray(selectedDictionary[i])) {
      const tempColDefs = dataTransformer.getColumnDefinition(
        selectedDictionary[i]
      );
      obj = tempColDefs.displayColConfigs[0];
      _columnDefs.push(obj);
      continue;
    }
    obj.headerComponentParams = {};
    obj.field = i;
    obj.title = selectedDictionary[i].title;
    obj.suppressSizeToFit = false;
    obj.resizable = false;
    obj.headerComponentFramework = 'genericTableHeader';
    const keyType = 'string';
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: formatter
    };
    obj.enableSorting = true;
    obj.cellRenderer = cellRenderer;
    obj.headerComponentParams.enableSorting = true;
    obj.width = selectedDictionary[i].width ?? 450;
    if (selectedDictionary[i].type === 'icon') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererFramework = 'iconTableCell';
      obj.cellRendererParams.displayIcon = selectedDictionary[i].icon;
      obj.cellRendererParams.alternateIcon =
        selectedDictionary[i]?.alternateIcon;
      obj.cellRendererParams.alternateIconClass =
        selectedDictionary[i]?.alternateIconClass;
      obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
      obj.cellRendererParams.iconSize = 'medium';
      obj.cellRendererParams.iconClickEvent =
        vueRef[selectedDictionary[i].iconClickEvent];
      obj.headerComponentParams.clickHeader =
        vueRef[selectedDictionary[i].clickHeader];
      obj.headerComponentParams.customStyles = {
        color: '#007cf6',
        display: 'flex',
        width: '100%',
        'justify-content': 'center'
      };
      if (selectedDictionary[i]?.toolTipText) {
        obj.headerComponentParams.headerIcon = {
          icon: 'info-circle-fill',
          toolTip: selectedDictionary[i]?.toolTipText
        };
      }
      obj.width = 120;
    }
    if (selectedDictionary[i].type === 'append') {
      delete obj.cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      obj.cellRendererFramework = taggedCell;
      obj.cellRendererParams.textKey = i;
      obj.cellRendererParams.keys = selectedDictionary[i].keys;
    }
    if (selectedDictionary[i].type === 'currency') {
      delete obj.cellRenderer;
      obj.cellRendererFramework = currency;
    }
    obj.headerName = selectedDictionary[i].title;
    obj.keyOrder = selectedDictionary[i].keyOrder;
    selectedDictionary[i].pinned && (obj.pinned = selectedDictionary[i].pinned);
    // selectedDictionary[i].pinned && (delete obj.cellRendererFramework);
    if (selectedDictionary[i].toolTipText) {
      obj.headerComponentParams.toolTipText = selectedDictionary[i].toolTipText;
    }
    obj.headerComponentParams.keyType = keyType;
    if (selectedDictionary[i].showOnUi) {
      _columnDefs.push(obj);
    }
  }
  return _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}
