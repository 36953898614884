<template>
  <div>
    <div id="radio-group-dropdown">
      <rule-builder-radio-select
        :segmented-data="segmentedData"
        :selected-value="selectedValue"
        :place-holder="searchPlaceHolder"
        @cancel="handleCancel"
        @radioSelection="handleRadioSelection"
      />
    </div>
    <div
      ref="tippy"
      v-tippy="tippyOptions"
      class="u-display-flex u-flex-align-items-center u-width-100"
    >
      <span class="u-color-grey-light">{{ selectedValue.brand }}</span>
      <rb-icon
        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
        icon="caret-down"
      />
    </div>
  </div>
</template>

<script>
import ruleBuilderRadioSelect from '@/components/pages/insights/amazon/share-of-voice/atoms/rule-builder-radio-select.vue';
export default {
  components: {
    ruleBuilderRadioSelect
  },
  props: {
    segmentedData: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: Object,
      default: () => ({})
    },
    searchPlaceHolder: {
      type: String,
      default: 'Search'
    }
  },
  data() {
    return {
      tippyOptions: {
        html: '#radio-group-dropdown',
        reactive: true,
        trigger: 'click',
        theme: 'dropdown',
        height: 400,
        placement: 'bottom-start',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  methods: {
    handleCancel() {
      this.$refs.tippy._tippy.hide();
    },
    handleRadioSelection(data) {
      this.$emit('applySelection', data, this.selectedValue);
    }
  }
};
</script>
