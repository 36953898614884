<script>
import splitTabs from '@/components/widgets/splitTabs';

export default {
  name: 'RecommendationsTypeTabs',
  components: {
    splitTabs
  },
  props: {
    selectedTab: {
      type: String,
      default: 'Recommendation type'
    }
  },
  data() {
    return {
      recomType: ['Recommendation type', 'SKUs'],
      selectedRecomType: this.selectedTab
    };
  },
  watch: {
    selectedTab: function (newVal) {
      this.selectedRecomType = newVal;
    }
  },
  methods: {
    onRecomTypeClick(selection) {
      this.selectedRecomType = selection;
      if (selection === 'SKUs') {
        this.$logger.logEvent({
          eventType: 'recommendation-type-click',
          type: 'Click',
          page: 'All SKUs',
          eventContext: 'Recommendation Type navigation'
        });
        this.$router.push({ name: 'All' });
      } else {
        this.$logger.logEvent({
          eventType: 'recommendation-type-click',
          type: 'Click',
          page: 'Recommendations',
          eventContext: 'Recommendation Type navigation'
        });
        this.$router.push(`${this.$store.getters.getRootPath}recommendations`);
        // this.$router.push({ name: 'recommendationsLanding' });
      }
    }
  }
};
</script>

<template>
  <div class="u-display-flex u-flex-align-items-center">
    <splitTabs
      :tabs-list="recomType"
      :is-tabs-list="true"
      :active-tab="selectedRecomType"
      @tabClicked="onRecomTypeClick"
    />
  </div>
</template>
