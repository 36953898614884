import requestConfig from '@/pages/campaign-management/retailers/criteo/ad_skus/requestConfig.json';
import {
  getBaseActionType,
  getFinalMeasuresList
} from '@/pages/campaign-management/retailers/criteo/utils';
import { klona } from 'klona/json';
import {
  additionalDateRanges,
  baseChartConfig,
  commonChartActionIcons,
  commonTableActionIcons,
  criteoskusEditStatusActionPayloadKeys,
  gridOptions
} from '@/pages/campaign-management/constants';
import {
  generatePlotRowChip,
  getDataFromRowNode,
  payloadGenerator,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common';
import metricsDictionary from '@/pages/campaign-management/retailers/dictionary';
import HttpService from '@/utils/services/http-service';

function chartRequest(retailer) {
  return {
    ...klona(requestConfig.chart),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.chart.measuresList),
      retailer
    )
  };
}

function tableRequest(retailer) {
  return {
    ...klona(requestConfig.table),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.table.measuresList),
      retailer
    )
  };
}

const WIDGET = 'skus';
const PRIMARY_KEY = 'campaign_product_id';

export function getEditStatusActionObject({
  context,
  primaryKey,
  widgetName,
  unPauseProductStatusActionType,
  pauseProductStatusActionType
}) {
  return {
    icon: 'pencil',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Enable', value: 'enabled' },
      { title: 'Disable', value: 'paused' }
    ],
    handler(selectionLength, dropDownSelection, selections) {
      return new Promise((resolve, reject) => {
        const dataArray = selections.map((item) => getDataFromRowNode(item));
        const dynamicKeys = {
          primaryKey: primaryKey,
          previousEntityValue: 'status',
          actionPayloadKeys: criteoskusEditStatusActionPayloadKeys(),
          viewPayloadKeys: criteoskusEditStatusActionPayloadKeys()
        };
        const staticValues = {
          widget: widgetName,
          actionType:
            dropDownSelection[0].value === 'enabled'
              ? unPauseProductStatusActionType
              : pauseProductStatusActionType,
          actionSource: {
            pageUrl: window.location.href
          }
        };
        const newValuesObject = (item) => {
          return {
            actionPayload: {
              actionType:
                dropDownSelection[0].value === 'enabled'
                  ? unPauseProductStatusActionType
                  : pauseProductStatusActionType
            },
            viewPayload: {
              previousStatus: item?.status === 'active' ? 'Enabled' : 'Paused',
              newStatus:
                dropDownSelection[0]?.value[0].toUpperCase() +
                dropDownSelection[0]?.value.slice(1)
            }
          };
        };
        const payloadArray = dataArray.map((item) =>
          payloadGenerator(
            dynamicKeys,
            staticValues,
            newValuesObject(item),
            item
          )
        );
        context.openSnackbar(
          'Status change request has been submitted for the selected Ad SKU(s)'
        );
        HttpService.post('AMS_ACTIONS_ACTION_LOG', payloadArray)
          .then((response) => {
            context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  };
}

export default (context, tab, retailer) => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Ad SKUs'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'criteo_common_products.max_feed_date',
        min: 'criteo_common_products.min_feed_date'
      },
      api: {
        cube: 'criteo_common_campaign_ad_skus_workbench',
        page: `${retailer}_products`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'skus-filter',
      page: `${retailer}_products`,
      listenerEvent: `${retailer}-skus-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-skus',
          placeholder: 'Search for a SKU',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-skus-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: chartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          chartConfig: baseChartConfig(),
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          getEditStatusActionObject({
            context,
            primaryKey: PRIMARY_KEY,
            widgetName: WIDGET,
            unPauseProductStatusActionType: `${getBaseActionType(
              retailer
            )}UnpauseProductStatus`,
            pauseProductStatusActionType: `${getBaseActionType(
              retailer
            )}PauseProductStatus`
          }),
          {
            icon: 'dollar',
            title: 'Override Bid',
            id: 'adjust-bids',
            customState: {
              component: 'BidChangePanel',
              props: {
                title: 'Override Bid',
                actionPanelType: 'adskus',
                apiVersion: 'actionlog',
                retailer: context.$store.getters.getRetailer,
                widget: WIDGET,
                primaryKey: PRIMARY_KEY,
                infoBoxText:
                  'Product bids should be greater than the minimum bid set for the product'
              },
              events: {
                afterAPIActionToCall(payload) {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
                },
                closePanelMethod: () => {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                }
              }
            }
          }
        ]
      },
      activityBar: {
        pageType: 'campaign'
      },
      messageBar: {
        pageType: 'campaigns'
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: tableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'campaigns'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          rowHeight: 80,
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        },
        events: {}
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    }
  };
};
