import entityConfigs from '@/pages/toolsAndSettings/entityConfig.js';
import { capitalize } from 'lodash';

const config = (entity) => {
  const currentEntity = entityConfigs[entity].config;
  return {
    widgets: {
      selectTaxonomy: {
        columnDefs: [
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: `${capitalize(currentEntity.altlabel)} Columns `,
            checkboxSelection: true,
            field: 'dimensionLabel',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell'
          },
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: `${capitalize(currentEntity.altlabel)} Values`,
            field: 'uniqueValueCount',
            cellRendererFramework: 'genericTableCell',
            maxWidth: 120,
            cellRendererParams: {
              keyType: 'number'
            }
          },
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            maxWidth: 180,
            headerName: `${capitalize(
              currentEntity.entityName
            )}s without ${capitalize(currentEntity.label)} Values`,
            field: 'noSkuCount',
            cellRendererFramework: 'genericTableCell',
            cellRendererParams: {
              keyType: 'number'
            }
          }
        ]
      }
    }
  };
};

export default {
  config: config
};
