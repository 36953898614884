<template>
  <div
    v-tippy="{
      placement: 'top',
      arrow: true,
      interactive: true,
      distance: 30,
      size: 'regular',
      maxWidth: '300px'
    }"
    :title="getCardToolipText()"
  >
    <div
      v-if="config"
      class="card u-border-radius-s u-display-flex u-flex-direction-column u-position-relative box-shadow u-overflow-hidden"
    >
      <div
        class="card-wrapper"
        :class="{ 'selected-card-bg': isbgcolor }"
      >
        <div
          class="card-header u-spacing-ph-l u-spacing-pt-l u-display-flex u-flex-align-items-center u-flex-justify-content-center"
        >
          <slot name="left-header" />
          <slot name="header">
            <div
              v-tippy="{ placement: 'top' }"
              class="u-text-align-center card-header-label u-spacing-pb-m"
            >
              <rb-tooltip-header
                v-if="isTooltipEnabled"
                id="tooltipTemplatecard"
                :title="config.label ? config.label : 'NA'"
                :message="config.tooltip ? modifyMessage(config.tooltip) : 'NA'"
              />
              <span
                v-tippy="setTooltip.tippyOptions"
                class="u-font-size-4 card__title u-color-grey-lighter u-font-weight-600"
                :class="
                  !isbgcolor ? 'u-color-grey-lighter' : 'u-color-white-base'
                "
                data-cy="card-header"
              >
                {{ config.label }}
                <span
                  v-if="isCardCollapsed"
                  class="u-spacing-mh-xs"
                  >:</span
                >
              </span>
            </div>
          </slot>

          <slot name="right-header" />
        </div>

        <div class="u-flex-justify-content-center">
          <slot name="body" />
        </div>
      </div>

      <div
        class="card-footer u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative selected-card-bg-footer"
        :class="defaultClass ? defaultClass : footerBgLogicHandler"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-pv-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
        >
          <slot name="footer-left" />
          <slot name="footer">
            <span class="u-color-grey-x-light u-font-size-7">Impact </span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWidgetWithImpact',
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    isbgcolor: {
      type: Boolean,
      default: ''
    },
    footerValue: {
      type: Object,
      default: function () {
        return {};
      }
    },
    defaultClass: {
      type: String,
      default: () => ''
    },
    isTooltipEnabled: {
      type: Boolean,
      default: true
    },
    constantFooterBg: {
      type: String,
      default: () => ''
    },
    isCardCollapsed: {
      type: Boolean,
      default: true
    },
    cardTooltipText: {
      type: String,
      default: ''
    }
  },
  computed: {
    setTooltip() {
      return {
        id: 'tooltipTemplatecard',
        tippyOptions: {
          html: '#' + 'tooltipTemplatecard',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: false,
          reactive: true,
          arrow: false
        }
      };
    },
    footerBgLogicHandler() {
      if (this.constantFooterBg) {
        if (this.footerValue?.tag1) {
          return this.constantFooterBg;
        } else {
          return 'bg-grey';
        }
      } else {
        if (this.footerValue?.tag2 > 0) {
          return 'bg-green';
        } else if (this.footerValue?.tag2 < 0) {
          return 'bg-red';
        } else {
          return 'bg-grey';
        }
      }
    }
  },
  methods: {
    modifyMessage(message) {
      let messageResult = '';
      const updatedMessage = message.split('|');
      updatedMessage.forEach((item) => {
        messageResult = messageResult + item + '\n';
      });
      return messageResult;
    },
    getCardToolipText() {
      return !this.config?.metadata?.isAutomationTypeEnabled
        ? this.cardTooltipText
        : '';
    }
  }
};
</script>

<style lang="css" scoped>
.rb-input {
  padding-left: 30px;
}
.bg-red {
  background-color: #fbe9ec !important;
}
.bg-green {
  background-color: rgba(62, 169, 94, 0.1) !important;
}
.bg-grey {
  background-color: rgba(233, 234, 235, 0.5);
}
.selected-card-bg {
  background: #007cf6 !important;
}
.card-header {
  padding-bottom: 0px !important;
}
.box-shadow:hover {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
