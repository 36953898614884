<template>
  <div
    v-if="!hideCell"
    class="u-display-flex u-text-overflow-ellipsis"
  >
    <rb-sku
      :show-image="rbSkuData.showImage"
      :title="rbSkuData.title"
      :image-url="rbSkuData.imageUrl"
      :asin="rbSkuData.asin"
      :product-page-url="rbSkuData.productUrl"
      v-bind="dynamicRbSkuProps"
      @navigateToSDP="navigateToSDP"
    />
    <div
      v-if="displayButton"
      class="cellHover u-font-weight-600 u-spacing-ml-m u-font-size-7 u-color-blue-base"
      @click="hoverTextClickEvent($event)"
    >
      <span
        class="u-spacing-ml-xs hover-span u-text-align-center"
        data-cy="skuDataHoverTxt"
        >{{ rbSkuData.hoverText }}</span
      >
    </div>
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'skuTableComponentWithButton',
  data() {},
  computed: {
    dynamicRbSkuProps() {
      let obj = {};
      if (
        this.params?.asinHoverText ||
        this.params?.asinHoverText?.length === 0
      ) {
        obj.rbAsinTippyText = this.params?.asinHoverText;
      }
      if (this.params?.disableProductLink === true) {
        obj['enable-s-d-p'] = false;
      } else {
        obj['enable-s-d-p'] = true;
      }
      if (this.params?.copyClipboardText) {
        obj.copyClipboardText = this.params.copyClipboardText;
      }
      return obj;
    },
    rbSkuData() {
      return {
        title: this.params.data[this.params.keys.title],
        imageUrl: this.params.data[this.params.keys.imageUrl],
        asin: this.params.data[this.params.keys.asin],
        productUrl: this.params.data[this.params.keys.productUrl],
        showImage: true,
        hoverText: this.params.hoverText
      };
    },
    displayButton() {
      return this.params.showButton !== undefined
        ? this.params.showButton
        : true;
    },
    hideCell() {
      if (this.params.hideCell) {
        return this.params.hideCell(this.params);
      }
      return false;
    }
  },
  methods: {
    hoverTextClickEvent(event) {
      if (this.params.hoverTextClickEvent) {
        this.params.hoverTextClickEvent(this, event);
      }
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style lang="css" scoped>
.cellHover .hover-span {
  padding: 3px 5px;
  height: 19px;
  border: 1px solid #007cf6;
  position: absolute;
  bottom: 21px;
  right: 21px;
  border-radius: 2px;
  border-width: 1px;
  border-radius: 2px;
  background-color: white;
}
.cellHover:hover .hover-span {
  color: white;
  background-color: #007cf6;
}
</style>
