<template>
  <tabbed-component-page
    :text-config="textConfig"
    :tabs-config="currentTabConfig"
    :default-tab-index="defaultTabIndex"
    @closePage="navigateBack"
    @currentTab="updateCurrentTab"
  />
</template>

<script>
import loader from '@/components/basic/loader';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import standardDigitalShelf from '@/pages/toolsAndSettings/entityTagging/manageDigitalShelves/standard/index.vue';
import customCatalog from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/custom/view/index.vue';
import customCampaignLists from '@/pages/toolsAndSettings/entityTagging/manageCampaignLists/custom/view/index.vue';
import standardCatalog from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/catalog.vue';
import variantChange from '@/pages/toolsAndSettings/entityTagging/manageVariantChange/index.vue';
import contentChange from '@/pages/toolsAndSettings/entityTagging/manageContentChange/index.vue';
import customDigitalShelves from '@/pages/toolsAndSettings/entityTagging/manageDigitalShelves/custom/view/index.vue';
import tabbedComponentPage from '@/pages/toolsAndSettings/entityTagging/common/tabbedComponentPage.vue';
import { Entities } from '@/pages/toolsAndSettings/const.js';
export default {
  components: {
    loader,
    activityLog,
    standardCatalog,
    tabbedComponentPage,
    customCatalog,
    customDigitalShelves
  },
  data() {
    return {
      currentEntityKey: 'digital-shelves',
      entityConfigs: {
        'digital-shelves': {
          pageTitle: 'Manage Digital Shelves',
          pageDescription: 'Preview, edit and create Digital Shelves',
          tabsConfig: [
            {
              title: 'Standard Digital Shelves',
              component: standardDigitalShelf
            },
            { title: 'Custom Digital Shelves', component: customDigitalShelves }
          ]
        },
        catalog: {
          pageTitle: 'Manage Standard Internal Catalog',
          pageDescription: 'Preview, edit and create Standard Internal Catalog',
          tabsConfig: [
            {
              title: 'Standard Catalog',
              component: standardCatalog,
              textConfig: {
                retailer: this.$store.getters.getRetailer
              }
            },
            {
              title: 'Custom Catalog',
              component: customCatalog,
              textConfig: {
                retailer: this.$store.getters.getRetailer
              }
            }
          ]
        },
        'content-change': {
          pageTitle: 'Manage Source of Truth for Content',
          pageDescription: 'Preview and create Source of Truth for Content',
          tabsConfig: [
            {
              title: 'Manage Source of Truth for Content',
              component: contentChange
            }
          ]
        },
        'variant-change': {
          pageTitle: 'Manage Source of Truth for Variant',
          pageDescription: 'Preview and create Source of Truth for Variant',
          tabsConfig: [
            {
              title: 'Manage Source of Truth for Variant',
              component: variantChange
            }
          ]
        },
        'campaign-lists': {
          pageTitle: 'Manage Campaign Lists',
          pageDescription: 'Preview, edit and create Campaign Lists',
          tabsConfig: [
            { title: 'Custom Lists', component: customCampaignLists }
          ]
        },
        [Entities.campaign]: {
          pageTitle: 'Campaign Taxonomy',
          pageDescription: 'Preview, edit and create Campaign Taxonomy',
          tabsConfig: [
            {
              title: 'Campaign Taxonomy',
              component: standardCatalog,
              textConfig: {
                entity: Entities.campaign,
                retailer: this.$store.getters.getRetailer
              }
            }
          ]
        }
      },
      currentTab: null,
      defaultTabIndex: 0,
      openActivity: false
    };
  },
  computed: {
    textConfig() {
      return {
        ...this.entityConfigs[this.currentEntityKey]
      };
    },
    currentTabConfig() {
      return this.entityConfigs[this.currentEntityKey].tabsConfig;
    }
  },
  watch: {
    $route(to, from) {
      this.setEntityConfigFromUrl();
    }
  },
  created() {
    this.setDefaultTab();
    this.setEntityConfigFromUrl();
  },
  methods: {
    setDefaultTab() {
      if (this.$route?.query?.type === 'custom') this.defaultTabIndex = 1;
    },
    setEntityConfigFromUrl() {
      if (this.$route.params.entity) {
        this.currentEntityKey = this.$route.params.entity;
      }
    },
    navigateBack(event) {
      this.$router.push({ path: '/tools_and_settings/' });
    },
    handleTimeLineButtonClick() {
      this.openActivity = true;
    },
    closeActivityLog() {
      this.openActivity = false;
    },
    updateCurrentTab(selectedTab) {
      this.currentTab = selectedTab;
    }
  }
};
</script>

<style lang="css" scoped>
.iq_tip_container {
  width: 365px;
  height: 255px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin: 80px 40px 105px 235px;
  padding: 24px;
  border-radius: 4px;
  border: solid 1px #e9eaeb;
  background-color: #ffffff;
}
.ingestion_work_panel {
  width: 50%;
  margin-left: 50px;
}
.pagination-overlay {
  width: 100%;
  height: calc(100vh - 182px);
}
.text-italics {
  font-style: italic;
}
.page--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  width: 100%;
}
.container {
  min-height: 500px;
}
.defaultHollowButton.rb-button--hollow {
  color: #fff !important;
  background: #007cf6 !important;
  border: 1px solid #fff !important;
}
.defaultHollowButton.rb-button--hollow:hover {
  background: #2a95ff !important;
  color: #fff !important;
  border-color: #fff !important;
}
.hollow-button-download {
  padding: 0px 24px;
  color: #007cf6 !important;
  border-color: #007cf6 !important;
}
.hollow-button-download:hover {
  color: #0062c3 !important;
  border-color: #0062c3 !important;
}
.taxonomy_topbar {
  background-color: #f5f7fa;
}
</style>
