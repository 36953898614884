<template>
  <div
    class="data-header u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center u-flex-justify-content-space-between"
    :class="{
      'reverse-content': isKeyRightAligned,
      'flex-content-center': isKeyCenterAligned,
      'u-cursor-pointer': enableSorting
    }"
    data-cy="tableHeaderCells"
    @click="customSort()"
  >
    <div
      class="header-text u-flex-shrink-past-min-size u-font-weight-600"
      :style="customStyles"
      :class="{
        'u-color-grey-light': sortState,
        'u-color-grey-lighter': !sortState,
        'reverse-content u-text-align-right': isKeyRightAligned,
        'u-text-align-center': isKeyCenterAligned
      }"
      data-cy="ColLabel"
      @click="clickHeader($event)"
    >
      <div
        v-if="headerIconAlignLeft"
        class="u-display-flex header-icon-container u-spacing-pr-xs"
        data-cy="headerIconTooltip"
        @click="onIconClick"
      >
        <rb-icon
          v-tippy="iconTippy"
          :title="headerIcon.toolTip ? headerIcon.toolTip : ''"
          :class="headerIcon.class"
          class="header-icon u-cursor-pointer"
          data-cy="headerSortIcon"
          :icon="headerIcon.icon"
        />
      </div>
      <rb-tooltip-header
        v-if="toolTipText.length ? toolTipText : params.toolTipText"
        :id="setTooltip.id"
        :title="displayName || params.displayName"
        :message="tooltipMessage"
        :data-cy="`ColName-${displayName}`"
      >
        <!-- eslint-disable -->
        <div
          slot="tooltip-message"
          class="tooltip-message"
          v-html="tooltipMessage"
          data-cy="toolTipMsg"
        />
        <!-- eslint-enable -->
      </rb-tooltip-header>
      <span
        v-tippy="setTooltip.tippyOptions"
        class="text-label"
        :class="textColor"
        :data-cy="`ColName-${displayName}`"
      >
        {{ displayName || params.displayName }}
      </span>
      <div
        v-if="headerIconAlignRight"
        class="u-display-flex header-icon-container u-spacing-pr-xs"
        @click="onIconClick"
      >
        <rb-icon
          v-tippy="iconTippy"
          :title="headerIcon.toolTip ? headerIcon.toolTip : ''"
          :class="headerIcon.class"
          class="header-icon u-cursor-pointer"
          :icon="headerIcon.icon"
        />
      </div>
    </div>
    <div
      v-if="showSortIconOnColumn"
      class="icons-wrap u-flex-none u-display-flex u-flex-align-items-center"
      :class="[
        sortState
          ? isKeyRightAligned
            ? 'u-spacing-pr-s'
            : 'u-spacing-pl-s'
          : ''
      ]"
    >
      <rb-icon
        v-if="sortState === 'asc'"
        class="sort-icon rb-icon--x-small"
        data-cy="ascendingIcon"
        :icon="'sort-asc'"
      />
      <rb-icon
        v-if="sortState === 'desc'"
        class="sort-icon rb-icon--x-small"
        data-cy="descendingIcon"
        :icon="'sort-desc'"
      />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'dataHeader',
  props: {
    customStyles: {
      type: String,
      default: ''
    },
    alignHeader: {
      type: String,
      default: 'left'
    },
    keyType: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    toolTipText: {
      type: String,
      default: ''
    },
    removeSplit: {
      type: String,
      default: ''
    },
    enableSorting: {
      type: Boolean,
      default: false
    },
    headerIcon: {
      type: Object,
      default: null
    },
    params: {
      type: Object,
      default: null
    },
    showSortIcon: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconTippy: {
        placement: 'top',
        showSortIconOnColumn: true,
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      keysToLeftAlign: [
        'string',
        'bsr_link',
        'recommended_action_type',
        'link'
      ],
      sortState: null
    };
  },
  computed: {
    setTooltip() {
      return {
        id: this.params.column.colId,
        tippyOptions: {
          html: '#' + this.params.column.colId,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    headerIconAlignRight() {
      return (
        this.headerIcon &&
        ((!this.isKeyRightAligned && this.headerIcon?.align === 'right') ||
          (this.headerIcon?.align === 'left' && this.isKeyRightAligned))
      );
    },
    headerIconAlignLeft() {
      return (
        this.headerIcon &&
        ((!this.isKeyRightAligned && this.headerIcon?.align === 'left') ||
          (this.headerIcon?.align === 'right' && this.isKeyRightAligned))
      );
    },
    tooltipMessage() {
      const tooltipText = this.toolTipText.length
        ? this.toolTipText
        : this.params.toolTipText;
      const displayName = this.displayName.length
        ? this.displayName
        : this.params.displayName;
      if (tooltipText.length) {
        if (
          displayName &&
          tooltipText.toLowerCase() === displayName.toLowerCase()
        ) {
          return '';
        }
        // By default split using ':'
        if (!this.removeSplit) {
          return tooltipText.includes(':')
            ? tooltipText.split(':')[1].trim()
            : tooltipText;
        } else if (this.removeSplit === true) {
          return tooltipText;
        }
      }
      return '';
    },
    isKeyRightAligned() {
      return this.alignHeader?.toLowerCase() === 'right';
    },
    isKeyCenterAligned() {
      return this.alignHeader?.toLowerCase() === 'center';
    }
  },
  watch: {
    showSortIcon(newVal) {
      this.showSortIconOnColumn = newVal;
    }
  },
  created() {
    // If sort icon needs to be shown in the table
    this.showSortIconOnColumn = this.showSortIcon;

    this.sortState = this.params.column.getSort() ?? null;
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.$nextTick(() => {
      let toExpand = false;
      const el =
        this.$el.querySelector && this.$el.querySelector('.text-label');
      if (!el) {
        return;
      }
      if (el.scrollHeight > 35) {
        toExpand = true;
      }
      if (!toExpand) {
        el.classList.add('u-flex-align-items-center');
      } else {
        el.classList.add('u-flex-align-items-flex-start');
      }
    });
  },
  beforeDestroy() {
    this.params.column.removeEventListener('sortChanged');
  },
  methods: {
    onIconClick(event) {},
    clickHeader(event) {
      if (this.clickHeader) {
        // this.clickHeader(this, event);
      }
    },
    onSortChanged() {
      if (this.enableSorting) {
        this.sortState = this.params.column.getSort();
      }
    },
    customSort() {
      this.showSortIconOnColumn = true;
      this.$nextTick(() => {
        this.enableSorting &&
          this.params.onColumnSort &&
          this.params.onColumnSort(this.params.column);
      });
      if (!this.enableSorting) {
      }
      this.params.progressSort();
    }
  }
};
</script>

<style lang="css" scoped>
.reverse-content {
  flex-direction: row-reverse;
}

.flex-content-center {
  justify-content: center;
}

.header-text {
  height: 100%;
  padding: 8px 0;
  word-break: break-word;
  overflow: hidden;
  vertical-align: middle;
  white-space: normal;
  display: flex;
  align-items: center;
  line-height: 1.1;
}
.header-text .text-label {
  max-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tooltip-message {
  line-height: 1.38;
  color: #2a333c;

  word-break: break-all;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: 1.3rem;
}
</style>
