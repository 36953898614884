import AddKeywordToTag from '@/pages/toolsAndSettings/entityTagging/manageDigitalShelves/custom/create/index.vue';
import EntityTagging from '@/pages/toolsAndSettings/entityTagging/index.vue';
import StandardAddSkuToTag from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/standard/entity-catalog/bulk-entity-manage.vue';
import AddSkuToTag from '@/pages/toolsAndSettings/entityTagging/manageInternalCatalog/custom/create/index.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/index.vue';
import { Entities } from '@/pages/toolsAndSettings/const.js';
import CONSTANTS from '@/utils/constants';

export default {
  routes: [
    {
      path: '',
      name: 'toolsAndSettingsHome',
      component: ToolsAndSettings,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: ':entity',
      component: EntityTagging,
      name: 'EntityTagging',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'digital-shelves/tag',
      component: AddKeywordToTag,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'catalog/tag',
      component: AddSkuToTag,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: `${Entities.campaign}/tag`,
      component: StandardAddSkuToTag,
      props: { entityType: Entities.campaign },
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'catalog/standard/tag',
      component: StandardAddSkuToTag,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    }
  ]
};
