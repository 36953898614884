import { Entities } from '@/pages/toolsAndSettings/const.js';
const config = (entity) => {
  const entityMap = {
    catalog: {
      label: 'Catalog',
      entityName: 'SKU'
    },
    [Entities.campaign]: {
      label: 'Campaign',
      entityName: 'campaign'
    }
  };

  const currentEntity = entityMap[entity];
  return {
    widgets: {
      selectTaxonomy: {
        columnDefs: [
          {
            name: 'name',
            uiField: {
              uiLabel: `${currentEntity.label} Columns`,
              uiTooltip:
                'Number of outstanding cases as per the latest PO data.',
              uiOrder: 1,
              uiType: 'string',
              metadata: {
                tableColumnName: 'name',
                showOnUi: true,
                isDownloadable: true,
                isFixed: false,
                sortOnColumn: true,
                isDefaultSortColumn: false,
                checkboxSelection: true
              }
            }
          },
          {
            name: `${entity}_yes_count`,
            uiField: {
              uiLabel: `${currentEntity.label} Values`,
              uiTooltip:
                'Number of outstanding cases as per the latest PO data.',
              uiType: 'custom',
              uiOrder: 2,
              header: {
                component: ''
              },
              metadata: {
                widget: 'dynamicCellComponent',
                dynamicCellComponentParams: {
                  componentName: 'iconText',
                  paramsToProps: (params) => {
                    return {
                      icon: 'cross',
                      text: 'Dummy Text'
                    };
                  },
                  eventMap: {
                    onClick: 'selectionChanged'
                  }
                },
                tableColumnName: `${entity}_yes_count`,
                showOnUi: true,
                isDownloadable: true,
                isFixed: false,
                sortOnColumn: true,
                isDefaultSortColumn: false
              }
            }
          },
          {
            name: `${entity}_no_count`,
            uiField: {
              uiLabel: `${currentEntity.entityName}s without ${currentEntity.label} Values`,
              uiTooltip:
                'Number of outstanding cases as per the latest PO data.',
              uiType: 'number',
              uiOrder: 3,
              metadata: {
                tableColumnName: `${entity}_no_count`,
                showOnUi: true,
                isDownloadable: true,
                isFixed: false,
                sortOnColumn: true,
                isDefaultSortColumn: false
              }
            }
          }
        ]
      }
    }
  };
};

export default {
  config: config
};
