import { SelectAllSelector } from './select-all-selector';
import { Selector } from './selector';
import { SelectorGroup } from './selector-group';

export class Checkbox extends Selector {
  private _selected: boolean | null = false;
  constructor(public id: string, public group: SelectorGroup<Selector>) {
    super(group);
  }

  handleClick() {
    // Checkbox is deselected if already selected, and vice-versa
    if (this.isSelected) {
      this.deselect();
    } else {
      this.select(true);
    }
  }

  get isSelected() {
    return this._selected;
  }

  _select(): void {
    this._selected = true;
    this.group.parent?.select(false);
  }

  _deselect(): void {
    this._selected = false;
  }

  get icon(): string {
    return (
      'checkbox-' +
      (this.isSelected === null
        ? 'intermediate'
        : this.isSelected === true
        ? 'selected'
        : 'empty')
    );
  }
}

export class NestedCheckbox extends Checkbox {
  get isSelected() {
    if (
      this.childrenGroup?.selectorsArray.every((x) => x.isSelected === true)
    ) {
      return true;
    } else if (
      this.childrenGroup?.selectorsArray.some(
        (x) => x.isSelected === true || x.isSelected === null
      )
    ) {
      return null;
    } else {
      return false;
    }
  }

  _select(childSelect = true): void {
    if (childSelect) {
      this.childrenGroup?.selectorsArray.forEach((child) => child.select(true));
    }
    this.group.parent?.select(false);
  }

  _deselect(): void {
    this.childrenGroup?.selectorsArray.forEach((child) => child.deselect());
  }
}

export class CheckboxGroup extends SelectorGroup<Checkbox | NestedCheckbox> {
  multi = true;

  get selectAllSelector(): SelectAllSelector | null {
    return new SelectAllSelector(this);
  }
}
