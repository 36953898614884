<template>
  <div
    v-if="showBanner && dataLag && dataLag.hasDataLag"
    class="banner data-lag-banner u-font-size-6 u-spacing-pv-s u-spacing-ph-l u-display-flex u-flex-justify-content-space-between"
  >
    <div class="u-display-flex u-flex-align-items-center">
      <span>
        <rb-icon
          class="u-flex-0 rb-icon--medium u-color-orange-base u-spacing-mr-xs icon-align-top"
          icon="warning-triangle"
        />
      </span>
      <span>
        At {{ dataLag.lastSyncTime }} we detected data availability in AVC for
        newer dates. Our system is working in the background and it will be in
        sync within the next few hours.
      </span>
    </div>

    <div @click="hideBanner">
      <rb-icon
        icon="'cross'"
        class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
      />
    </div>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'DataIssueBanner',

  props: {
    applyNegativeMargin: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      showBanner: true,
      dataLag: {}
    };
  },

  created() {
    if (this.$store.getters.getRetailer === 'amazon_3p') {
      this.showBanner = false;
      this.emitDataIssueBannerStatus();
    } else {
      this.getDataHealthCheckStatus();
    }
  },

  methods: {
    emitDataIssueBannerStatus(status) {
      eventBus.$emit('dataIssueBannerStatus', status ?? this.showBanner);
    },
    hideBanner() {
      this.showBanner = false;
    },
    async getDataHealthCheckStatus() {
      try {
        const response = await HttpService.get('GET_DATA_HEALTH_STATUS');
        this.dataLag = response.data.dataLag;
        this.emitDataIssueBannerStatus(
          this.showBanner && this.dataLag && this.dataLag.hasDataLag
        );
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.data-lag-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
.banner-with-negative-margin {
  margin: 0 -2.4rem;
}
</style>
