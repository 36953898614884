<template>
  <div
    class="u-height-100 u-width-100 u-display-flex u-align-items-flex-center u-flex-justify-content-center"
  >
    <rb-icon
      class="rb-icon--bp-custom u-color-green-base"
      icon="check-fill-circle"
    />
    <div class="u-font-size-1 u-spacing-ph-s">Report Sent</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    this.$emit('mounted');
  }
};
</script>

<style lang="css" scoped>
.rb-icon--bp-custom {
  height: 32px;
  width: 32px;
}
.rb-icon--bp-custom:before {
  font-size: 32px;
}
</style>
