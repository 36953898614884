import HttpService from '@/utils/services/http-service';
export default class MetadataService {
  data = null;
  loading = false;
  error = false;
  page = null;
  widget = null;
  pageId = null;
  globalViewId = null;
  apiConfig = null;
  constructor(widgetConfig) {
    this.globalViewId = widgetConfig.globalViewId;
    this.pageId = widgetConfig.pageId;
    this.apiConfig = widgetConfig.api;
    this.page = this.apiConfig.request.page;
    this.widget = this.apiConfig.request.widget;
    this.getMetadata();
  }

  async getMetadata() {
    try {
      if (this.data) {
        return this.data;
      }
      this.loading = true;
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post(this.apiConfig.service, body, {
        append: this.apiConfig.endPoint
      });
      this.error = false;
      this.loading = false;
      this.data = data;
      return data;
    } catch (e) {
      this.loading = false;
      this.error = true;
    }
  }
}
