export const metricsList = {
  advertised_sku_units: {
    title: 'Advertised SKU Units',
    pages: {
      campaigns: {
        keyOrder: 33,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold from the Campaign in the selected time period using 14 days attribution method calculated as units sold from direct click of the advertised item.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 29,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the SKU in the selected time period using 14 days attribution method calculated as units sold from direct click of the advertised item.',
        chart: {}
      },
      search_terms: {
        keyOrder: 29,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the Search term in the selected time period using 14 days attribution method calculated as units sold from direct click of the advertised item.',
        chart: {}
      },
      keywords: {
        keyOrder: 29,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the Keyword in the selected time period using 14 days attribution method calculated as units sold from direct click of the advertised item.',
        chart: {}
      }
    },
    type: 'NUMERIC'
  },
  other_sku_units: {
    title: 'Other SKU Units ',
    pages: {
      campaigns: {
        keyOrder: 34,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold from the Campaign in the selected time period using 14 days attribution method calculated as units sold from halo (brand, related and unadvertised variant) sales.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 30,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the SKU in the selected time period using 14 days attribution method calculated as units sold from halo (brand, related and unadvertised variant) sales.',
        chart: {}
      },
      search_terms: {
        keyOrder: 30,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the Search term in the selected time period using 14 days attribution method calculated as units sold from halo (brand, related and unadvertised variant) sales.',
        chart: {}
      },
      keywords: {
        keyOrder: 30,
        table: {
          show: true
        },
        toolTipText:
          'Total number of units sold by the Keyword in the selected time period using 14 days attribution method calculated as units sold from halo (brand, related and unadvertised variant) sales.',
        chart: {}
      }
    },
    type: 'NUMERIC'
  },
  advertised_sku_sales: {
    title: 'Advertised SKU Sales',
    pages: {
      campaigns: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated from the Campaign in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the direct click of the advertised item',
        chart: {}
      },
      ad_skus: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the SKU in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the direct click of the advertised item',
        chart: {}
      },
      search_terms: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the Search term in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the direct click of the advertised item',
        chart: {}
      },
      keywords: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the Keyword in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the direct click of the advertised item',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  other_sku_sales: {
    title: 'Other SKU sales',
    pages: {
      campaigns: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated from the Campaign in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the halo sales- Brand, related and any variant of the promoted item',
        chart: {}
      },
      ad_skus: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the SKU in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the halo sales- Brand, related and any variant of the promoted item',
        chart: {}
      },
      search_terms: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the Search term in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the halo sales- Brand, related and any variant of the promoted item',
        chart: {}
      },
      keywords: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated by the Keyword in the selected time period using 14 days attribution method calculated as attributed sales revenue based on the halo sales- Brand, related and any variant of the promoted item',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  conversion_rate_14d_orders_based: {
    title: 'Conversion Rate 14 D (Orders Based)',
    pages: {
      campaigns: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Measure of how many clicks converted into orders calculated as orders divided by clicks',
        chart: {}
      },
      ad_skus: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Measure of how many clicks converted into orders calculated as orders divided by clicks',
        chart: {}
      },
      search_terms: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Measure of how many clicks converted into orders calculated as orders divided by clicks.',
        chart: {}
      },
      keywords: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Measure of how many clicks converted into orders calculated as orders divided by clicks.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  ntb_orders: {
    title: 'New to Brand Orders',
    pages: {
      campaigns: {
        keyOrder: 36,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 32,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        chart: {}
      },
      search_terms: {
        keyOrder: 32,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        chart: {}
      },
      keywords: {
        keyOrder: 32,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand orders are the number of first-time orders for products within the brand over a one-year lookback window.',
        chart: {}
      }
    },
    type: 'NUMERIC'
  },
  percentageordersnewtobrand14d: {
    title: 'Percentage New to Brand Orders',
    pages: {
      campaigns: {
        keyOrder: 37,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        chart: {}
      },
      ad_skus: {
        keyOrder: 33,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        chart: {}
      },
      search_terms: {
        keyOrder: 33,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        chart: {}
      },
      keywords: {
        keyOrder: 33,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of orders new-to-brand is the percentage of total orders that are new-to-brand',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  ntb_revenue: {
    title: 'New to Brand Sales',
    pages: {
      campaigns: {
        keyOrder: 38,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 34,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        chart: {}
      },
      search_terms: {
        keyOrder: 34,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        chart: {}
      },
      keywords: {
        keyOrder: 34,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand sales revenue is the total sales of new-to-brand orders.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  percentagesalesnewtobrand14d: {
    title: 'Percentage New to Brand Sales',
    pages: {
      campaigns: {
        keyOrder: 39,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        chart: {}
      },
      search_terms: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        chart: {}
      },
      keywords: {
        keyOrder: 35,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of sales new-to-brand is the percentage of total sales that are new-to-brand.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  ntb_units: {
    title: 'New to Brand Units',
    pages: {
      campaigns: {
        keyOrder: 40,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand units is the number of units purchased from new-to-brand orders.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 36,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand units is the number of units purchased from new-to-brand orders.',
        chart: {}
      },
      search_terms: {
        keyOrder: 36,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand units is the number of units purchased from new-to-brand orders.',
        chart: {}
      },
      keywords: {
        keyOrder: 36,
        table: {
          show: true
        },
        toolTipText:
          'New-to-brand units is the number of units purchased from new-to-brand orders.',
        chart: {}
      }
    },
    type: 'NUMERIC'
  },
  percentageunitsnewtobrand14d: {
    title: 'Percentage New to Brand Units',
    pages: {
      campaigns: {
        keyOrder: 41,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        chart: {}
      },
      ad_skus: {
        keyOrder: 37,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        chart: {}
      },
      search_terms: {
        keyOrder: 37,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        chart: {}
      },
      keywords: {
        keyOrder: 37,
        table: {
          show: true
        },
        toolTipText:
          'Percentage of units new-to-brand is the percentage of total units which are new-to-brand',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  },
  conversion_rate_ntb: {
    title: 'Conversion rate (NTB)',
    pages: {
      campaigns: {
        keyOrder: 42,
        table: {
          show: true
        },
        toolTipText:
          'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 38,
        table: {
          show: true
        },
        toolTipText:
          'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        chart: {}
      },
      search_terms: {
        keyOrder: 38,
        table: {
          show: true
        },
        toolTipText:
          'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        chart: {}
      },
      keywords: {
        keyOrder: 38,
        table: {
          show: true
        },
        toolTipText:
          'Conversion Rate (NTB) is the number of new-to-brand orders relative to the number of clicks, so new-to-brand orders are divided by the number of clicks.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'PERCENTAGE'
  }
};

export default function addAdditionalMetrics(dictionary) {
  return {
    campaign_review_status: {
      title: 'Campaign Review Status',
      pages: {
        campaigns: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText:
            "Review status for SB campaigns indicates whether the campaign is approved and ready to go live, requires changes to go live, or undergoing Walmart's review process."
        },
        samsclub_api_campaigns: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText:
            "Review status for SB campaigns indicates whether the campaign is approved and ready to go live, requires changes to go live, or undergoing Samsclub's review process."
        }
      }
    },
    ...metricsList
  };
}
