const metricToDimensionMapping = {
  ordered_product_sales: 'gross_sales',
  actual_sales_marketsharelite: 'marketshare',
  generic_total_count_page_1: 'paid_sov',
  roi: 'oos'
};

const dimensionToMetricLabelMapping = {
  paid_sov: 'Total Category SOV (Page 1)',
  organic_sov: 'Total Category SOV (Page 1)',
  oos: 'ROAS',
  lbb: 'LBB'
};

const staticLocalSpotlightData = {
  content_score: [
    {
      content:
        'SLA breached for ticket resolution: <span class="u-font-weight-600">B09WD4TWYP</span> at retailer Homedepot and Lowes. The issue has been open for 14+ days.',
      skuDetails: {
        title: 'LG S75Q 3.1.2ch Sound bar with Dolby Atmos DTS:X',
        asin: 'B09WD4TWYP',
        pdpUrl: 'https://www.amazon.com/dp/B09WD4TWYP',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/71AWPASl7rL._AC_SX679_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          handler: () => {}
        }
      ]
    },
    {
      content:
        '<span class="u-font-weight-600">B09WJXGWZV</span>, one of your top SKUs content score has dropped below 40 for retailer Amazon (content out of sync with PIM)',
      skuDetails: {
        title:
          'LG Signature 88-inch Class OLED Z2 Series 8K Smart TV with Alexa Built-in OLED88Z2PUA S80QY 3.1.3ch Sound Bar w/Center Up-Firing',
        asin: 'B09WJXGWZV',
        pdpUrl: 'https://www.amazon.com/dp/B09WJXGWZV',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/91H0RXRKQYL._AC_SX679_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          handler: () => {}
        }
      ]
    },
    {
      content:
        '<span class="u-font-weight-600">Kid’s Breakfast Cereal</span> subcategory description scores are in the bottom 20% of your assortment.'
    }
  ],
  avg_selling_price: [
    {
      content:
        'MAP Price breached for priority SKUs: <span class="u-font-weight-600">B09RMRNSBF, B08WG4HKKY</span> map price have been breached on 01 Jan 2023.'
    },
    {
      content:
        'Amazon dropped selling price on <span class="u-font-weight-600">B09RMRNSBF</span> by 20%, which could potentially lead to CrAP.',
      skuDetails: {
        title:
          'LG C2 Series 65-Inch Class OLED evo Gallery Edition Smart TV OLED65C2PUA, 2022 - AI-Powered 4K TV, Alexa Built-in',
        asin: 'B09RMRNSBF',
        pdpUrl: 'https://www.amazon.com/dp/B09RMRNSBF',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/91vAUToHkcL._AC_SX679_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          handler: () => {}
        }
      ]
    }
  ],
  customer_sentiment: [
    {
      content:
        'One of your top SKUs, <span class="u-font-weight-600">B09RMRNSBF</span>, reported 5 negative reviews on product packaging in the last 30 days',
      skuDetails: {
        title:
          'LG C2 Series 65-Inch Class OLED evo Gallery Edition Smart TV OLED65C2PUA, 2022 - AI-Powered 4K TV, Alexa Built-in',
        asin: 'B09RMRNSBF',
        pdpUrl: 'https://www.amazon.com/dp/B09RMRNSBF',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/91vAUToHkcL._AC_SX679_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          handler: () => {}
        }
      ]
    },
    {
      content:
        'Your brand ranks 10th in the <span class="u-font-weight-600">Breakfast cereal</span> category on the dimension: <span class="u-font-weight-600">Value for Money.</span>',
      actions: [
        {
          text: 'See Why?',
          handler: () => {}
        }
      ]
    },
    {
      content:
        '<span class="u-font-weight-600">High Protein cereal</span> is trending on Amazon, with 50% YoY growth in searches. <span class="u-font-weight-600">B00XMZI1M4</span> rates highly amongst protein cereal and should be further promoted',
      skuDetails: {
        title:
          'Three Sisters Barbaras Morning Oat Crunch Original Cereal, Heart Healthy, Non-GMO, 14 Oz Box',
        asin: 'B00XMZI1M4',
        pdpUrl: 'https://www.amazon.com/dp/B00XMZI1M4',
        imageUrl:
          'https://m.media-amazon.com/images/I/51vidUhX6iL._SX300_SY300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          handler: () => {}
        }
      ]
    }
  ],
  shipped_cogs: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced an increase in Shipped COGS by <span class="u-font-weight-700 u-color-green-base">$1.05M</span> possibly due to increase in <span class="u-font-weight-700">PO Fill rate</span> by <span class="u-color-green-base u-font-weight-700">15%</span> in the specified period.',
      skuDetails: {
        title:
          'PROBAR - Base Protein Bar, Peanut Butter Chocolate, Non-GMO, Gluten-Free, Healthy, Plant-Based Whole Food Ingredients (12 Count)',
        asin: 'B00B97A49U',
        pdpUrl: 'https://www.amazon.com/dp/B00B97A49U',
        imageUrl:
          'https://m.media-amazon.com/images/I/41sa4FMO2pL._SY300_SX300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Shipped COGS by <span class="u-font-weight-700 u-color-red-base">$540K</span> possibly due to increase in <span class="u-font-weight-700">OOS</span> by <span class="u-color-red-base u-font-weight-700">8%</span> and due to drop in <span class="u-font-weight-700">Ad Spend</span> by <span class="u-color-red-base u-font-weight-700">80%</span> in the specified period.',
      skuDetails: {
        title:
          'Three Sisters Barbaras Morning Oat Crunch Original Cereal, Heart Healthy, Non-GMO, 14 Oz Box',
        asin: 'B00XMZI1M4',
        pdpUrl: 'https://www.amazon.com/dp/B00XMZI1M4',
        imageUrl:
          'https://m.media-amazon.com/images/I/51vidUhX6iL._SX300_SY300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Shipped COGS by <span class="u-font-weight-700 u-color-red-base">$470K</span> possibly due to increase in <span class="u-font-weight-700">Price</span> by <span class="u-color-red-base u-font-weight-700">30%</span> and due to drop in <span class="u-font-weight-700">Ad Spend</span> by <span class="u-color-red-base u-font-weight-700">92%</span> in the specified period.',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  gross_retailer_margin_perc: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in Gross Margin by <span class="u-font-weight-700 u-color-green-base">110%</span>',
      skuDetails: {
        title:
          'PROBAR - Base Protein Bar, Peanut Butter Chocolate, Non-GMO, Gluten-Free, Healthy, Plant-Based Whole Food Ingredients (12 Count)',
        asin: 'B00B97A49U',
        pdpUrl: 'https://www.amazon.com/dp/B00B97A49U',
        imageUrl:
          'https://m.media-amazon.com/images/I/41sa4FMO2pL._SY300_SX300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Gross Margin by <span class="u-font-weight-700 u-color-red-base">40%</span> possibly due to increase in <span class="u-font-weight-700">price</span> by <span class="u-color-green-base u-font-weight-700">29.6%</span> in the specified period.',
      skuDetails: {
        title:
          'Three Sisters Barbaras Morning Oat Crunch Original Cereal, Heart Healthy, Non-GMO, 14 Oz Box',
        asin: 'B00XMZI1M4',
        pdpUrl: 'https://www.amazon.com/dp/B00XMZI1M4',
        imageUrl:
          'https://m.media-amazon.com/images/I/51vidUhX6iL._SX300_SY300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Gross Margin by <span class="u-font-weight-700 u-color-red-base">62%</span> in the specified period.',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  conversion_percentile: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Unit Conversion by <span class="u-font-weight-700 u-color-red-base">40%</span> possibly due to increase in <span class="u-font-weight-700">OOS</span> by <span class="u-color-red-base u-font-weight-700">15%</span> and increase in <span class="u-font-weight-700">LBB %</span> by <span class="u-color-red-base u-font-weight-700">5%</span> in the specified period.',
      skuDetails: {
        title:
          'Dixie 16 And 24 Oz. PETE Plastic Cold Cup Flat Lid By GP PRO (Georgia-Pacific), Clear, CL1624, 1,000 Count (100 Lids Per Sleeve, 10 Sleeves Per Case)',
        asin: 'B007G0UPRA',
        pdpUrl: 'https://www.amazon.com/dp/B007G0UPRA',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T1/images/I/51RPKbJ2SFL._SY445_SX342_QL70_FMwebp_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Unit Conversion by <span class="u-font-weight-700 u-color-red-base">23%</span> possibly due to increase in <span class="u-font-weight-700">OOS</span> by <span class="u-color-red-base u-font-weight-700">8%</span> in the specified period.',
      skuDetails: {
        title:
          'Three Sisters Barbaras Morning Oat Crunch Original Cereal, Heart Healthy, Non-GMO, 14 Oz Box',
        asin: 'B00XMZI1M4',
        pdpUrl: 'https://www.amazon.com/dp/B00XMZI1M4',
        imageUrl:
          'https://m.media-amazon.com/images/I/51vidUhX6iL._SX300_SY300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Unit Conversion by <span class="u-font-weight-700 u-color-red-base">38%</span> possibly due to increase in <span class="u-font-weight-700">LBB %</span> by <span class="u-color-red-base u-font-weight-700">7%</span> in the specified period.',
      skuDetails: {
        title:
          'Post Great Grains Raisins, Dates & Pecans Whole Grain Cereal, 16 Ounce',
        asin: 'B0051OLMZC',
        pdpUrl: 'https://www.amazon.com/dp/B0051OLMZC',
        imageUrl:
          'https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/51KI6iVsI9L._SX300_SY300_QL70_FMwebp_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  roi: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in ROAS by <span class="u-font-weight-700 u-color-green-base">7%</span> possibly due to increase in <span class="u-font-weight-700">LBB</span> by <span class="u-color-red-base u-font-weight-700">15%</span> in the specified period.',
      skuDetails: {
        title:
          'ActiveAire Passive Whole-Room Air Freshener Dispenser By GP PRO (Georgia-Pacific), Black, 56803, 3.232" W X 4.057" D X 6.830" H',
        asin: 'B01MRGQEX1',
        pdpUrl: 'https://www.amazon.com/dp/B01MRGQEX1',
        imageUrl:
          'https://m.media-amazon.com/images/I/31-cFyGEnwL._SY445_SX342_QL70_FMwebp_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in ROAS by <span class="u-font-weight-700 u-color-green-base">14%</span> possibly due to increase in <span class="u-font-weight-700">Total Category SOV (Page 1)</span> by <span class="u-color-green-base u-font-weight-700">25%</span> in the specified period.',
      skuDetails: {
        title:
          'Dixie Disposable Paper Cup Dispenser, For 3 Ounce Or 5 Ounce Bath Cups',
        asin: 'B00BMCX016',
        pdpUrl: 'https://www.amazon.com/dp/B00BMCX016',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T2/images/I/81Dz1HIrReL._AC_SX679_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in ROAS by <span class="u-font-weight-700 u-color-red-base">13%</span> possibly due to increase in <span class="u-font-weight-700">Total Category SOV (Page 1)</span> by <span class="u-color-green-base u-font-weight-700">23%</span> in the specified period.',
      skuDetails: {
        title:
          'Dixie 16 And 24 Oz. PETE Plastic Cold Cup Flat Lid By GP PRO (Georgia-Pacific), Clear, CL1624, 1,000 Count (100 Lids Per Sleeve, 10 Sleeves Per Case)',
        asin: 'B007G0UPRA',
        pdpUrl: 'https://www.amazon.com/dp/B007G0UPRA',
        imageUrl:
          'https://m.media-amazon.com/images/W/WEBP_402378-T2/images/I/81i9K8doW8L._SX522_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  actual_lbb_rate: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in LBB % by <span class="u-font-weight-700 u-color-red-base">5%</span>',
      skuDetails: {
        title: 'Arrowhead Mills Puffed Millet Cereal (Pack of 6)6',
        asin: 'B0054S5Q9S',
        pdpUrl: 'https://www.amazon.com/dp/B0054S5Q9S',
        imageUrl:
          'https://www.amazon.com/Arrowhead-Mills-Puffed-Millet-Cereal/dp/B0054S5Q9S'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in LBB % by <span class="u-font-weight-700 u-color-red-base">8%</span>',
      skuDetails: {
        title:
          'Post Great Grains Raisins, Dates & Pecans Whole Grain Cereal, 16 Ounce',
        asin: 'B0051OLMZC',
        pdpUrl: 'https://www.amazon.com/dp/B0051OLMZC',
        imageUrl:
          'https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/51KI6iVsI9L._SX300_SY300_QL70_FMwebp_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in LBB % by <span class="u-font-weight-700 u-color-red-base">7%</span>',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  actual_rep_oos: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in REP OOS % by <span class="u-font-weight-700 u-color-red-base">15%</span> in the specified period.',
      skuDetails: {
        title: 'Arrowhead Mills Puffed Millet Cereal (Pack of 6)6',
        asin: 'B0054S5Q9S',
        pdpUrl: 'https://www.amazon.com/dp/B0054S5Q9S',
        imageUrl:
          'https://www.amazon.com/Arrowhead-Mills-Puffed-Millet-Cereal/dp/B0054S5Q9S'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in REP OOS % by <span class="u-font-weight-700 u-color-red-base">8%</span>',
      skuDetails: {
        title:
          'Post Great Grains Raisins, Dates & Pecans Whole Grain Cereal, 16 Ounce',
        asin: 'B0051OLMZC',
        pdpUrl: 'https://www.amazon.com/dp/B0051OLMZC',
        imageUrl:
          'https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/51KI6iVsI9L._SX300_SY300_QL70_FMwebp_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in REP OOS % by <span class="u-font-weight-700 u-color-red-base">7%</span>',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  po_fill_rate: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in PO Fill Rate by <span class="u-font-weight-700 u-color-green-base">15%</span> in the specified period.',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in PO Fill Rate by <span class="u-font-weight-700 u-color-red-base">45%</span> in the specified period.',
      skuDetails: {
        title:
          'PROBAR - Base Protein Bar, Peanut Butter Chocolate, Non-GMO, Gluten-Free, Healthy, Plant-Based Whole Food Ingredients (12 Count)',
        asin: 'B00B97A49U',
        pdpUrl: 'https://www.amazon.com/dp/B00B97A49U',
        imageUrl:
          'https://m.media-amazon.com/images/I/41sa4FMO2pL._SY300_SX300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in PO Fill Rate by <span class="u-font-weight-700 u-color-red-base">12%</span> in the specified period.',
      skuDetails: {
        title: 'Arrowhead Mills Puffed Millet Cereal (Pack of 6)6',
        asin: 'B0054S5Q9S',
        pdpUrl: 'https://www.amazon.com/dp/B0054S5Q9S',
        imageUrl:
          'https://www.amazon.com/Arrowhead-Mills-Puffed-Millet-Cereal/dp/B0054S5Q9S'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ],
  spend: [
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a increase in Ad Spend by <span class="u-font-weight-700 u-color-green-base">200%</span> in the specified period.',
      skuDetails: {
        title:
          'PROBAR - Base Protein Bar, Peanut Butter Chocolate, Non-GMO, Gluten-Free, Healthy, Plant-Based Whole Food Ingredients (12 Count)',
        asin: 'B00B97A49U',
        pdpUrl: 'https://www.amazon.com/dp/B00B97A49U',
        imageUrl:
          'https://m.media-amazon.com/images/I/41sa4FMO2pL._SY300_SX300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Ad Spend by <span class="u-font-weight-700 u-color-red-base">$20.9K</span> in the specified period.',
      skuDetails: {
        title:
          'Three Sisters Barbaras Morning Oat Crunch Original Cereal, Heart Healthy, Non-GMO, 14 Oz Box',
        asin: 'B00XMZI1M4',
        pdpUrl: 'https://www.amazon.com/dp/B00XMZI1M4',
        imageUrl:
          'https://m.media-amazon.com/images/I/51vidUhX6iL._SX300_SY300_QL70_ML2_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    },
    {
      content:
        'One of your <span class="u-font-weight-700">top SKUs</span> experienced a drop in Ad Spend by <span class="u-font-weight-700 u-color-red-base">$40.6K</span> in the specified period.',
      skuDetails: {
        title: 'Peanut Butter Chex Cereal, Gluten Free, 20 oz',
        asin: 'B07R1ZP4FR',
        pdpUrl: 'https://www.amazon.com/dp/B07R1ZP4FR',
        imageUrl:
          'https://images-na.ssl-images-amazon.com/images/I/81q-NXSkMbL._SX466_PIbundle-8,TopRight,0,0_AA466SH20_.jpg'
      },
      actions: [
        {
          text: 'Review SKU',
          whatToDo: 'Open SDP page for selected asin'
        }
      ]
    }
  ]
};

const whatIfMetricsData = {
  supportingMetrics: {
    columnOrder: [
      'shipped_cogs',
      'sov',
      'contribution_margin',
      'category_growth'
    ],
    contribution_margin: {
      label: 'Contribution Margin',
      unit: 'percent',
      value: 30
    },
    sov: {
      label: 'Total Category SOV (Page 1)',
      unit: 'percent',
      value: 12
    },
    shipped_cogs: {
      label: 'Shipped COGS',
      unit: 'currency',
      value: 44716424
    },
    category_growth: {
      label: 'Category Growth',
      unit: 'percent',
      value: 10
    }
  },
  inputMetrics: {
    columnOrder: ['fill_rate', 'ad_spend', 'content_score'],
    fill_rate: {
      label: 'Fill Rate',
      unit: 'percent',
      value: 73
    },
    ad_spend: {
      label: 'Ad Spend',
      unit: 'currency',
      value: 1500000
    },
    content_score: {
      label: 'Content Score',
      unit: 'percent',
      value: 82
    }
  },
  outputMetrics: {
    columnOrder: ['marketshare', 'shipped_cogs', 'contribution_profit', 'roas'],
    marketshare: {
      label: 'Marketshare',
      unit: 'percent',
      value: 12
    },
    shipped_cogs: {
      label: 'Shipped COGS',
      unit: 'currency',
      value: 44716424
    },
    contribution_profit: {
      label: 'Contribution Profit',
      unit: 'currency',
      value: 13414927
    },
    roas: {
      label: 'ROI of your Ad Spend',
      unit: 'currency',
      value: 7
    }
  }
};

export {
  metricToDimensionMapping,
  dimensionToMetricLabelMapping,
  staticLocalSpotlightData,
  whatIfMetricsData
};
