<template>
  <div
    v-if="metadataLoad"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metadataError || externalWidgetError"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <cardContainer
    v-else
    v-viewport-visible="viewportVisibleConfigs"
    :title="metadata.label"
    :action-icons="actionIcons"
    :last-updated-date="lastUpdatedDate"
    @download="handleDownload"
  >
    <titleSelect
      slot="post-title"
      :options="digitalShelfLevels"
      :selected-option="titleOptionSelected"
      @optionSelect="handleOptionSelect"
    />
    <section
      slot="pre-filters"
      class="u-display-flex"
    >
      <tabs
        class="u-spacing-mr-m"
        :tabs-data="tabData"
        :active-tab-index="activeTab"
        @tabClick="handleTabClick"
      />
    </section>
    <section slot="body">
      <section
        class="u-spacing-mt-s u-wdith-100 u-spacing-pb-m u-display-flex u-flex-justify-content-space-between u-border-color-grey-xx-light u-border-bottom u-border-width-s"
      >
        <rb-select
          class="u-spacing-mr-m"
          :on-close="handleViewsSelect"
          :options="views"
          :send-details="true"
        >
          <viewsSelect
            slot="trigger"
            :title="selectedView"
            :pre-text="'Views:'"
            class="view-title max-width-views u-flex-1 u-spacing-mr-m"
          />
        </rb-select>
        <section
          v-show="activeTab === 0"
          class="u-display-flex u-flex-align-items-center"
        >
          <InputWithIcon
            ref="search-input"
            class="u-spacing-pr-m"
            :place-holder="'Search for digital shelves'"
            @input="handleInput"
          />
          <div
            class="seperator-height u-border-right u-border-left u-border-width-s u-border-color-grey-xxx-light u-spacing-ph-m u-display-flex u-flex-align-items-center"
          >
            <ToggleSlider
              class="u-spacing-mr-s"
              @check="handleCheck"
            />
            <div class="u-color-grey-mid-light u-font-size-5">
              View Bubble Labels
            </div>
          </div>
          <ChartOptions
            :options="chartOptionsData"
            :selected-option-index="selectedOptionIndex"
            @lassoSelect="handleLassoSelect"
            @zoomIn="handleZoomIn"
            @zoomOut="handleZoomOut"
            @reset="handleReset"
          />
        </section>
      </section>
      <section
        v-show="showFiltersSection"
        ref="widget-container"
        class="u-width-100 u-spacing-pv-l u-flex-2 u-display-flex u-flex-justify-content-flex-start u-border-width-s u-border-color-grey-xxx-light"
      >
        <section
          class="u-border-right u-border-width-s u-border-color-grey-xxx-light"
        >
          <div
            class="u-color-grey-base u-font-weight-600 u-font-size-4 u-spacing-mb-m"
          >
            Dimensions
          </div>
          <section class="u-display-flex u-flex-justify-content-flex-start">
            <div
              class="u-spacing-mr-m u-height-100 height-inherit dashed-border u-border-width-s u-border-right u-border-color-grey-xxx-light u-spacing-pr-m"
            >
              <multi-dropdown
                class="u-spacing-mb-m fit-content"
                :multi-dropdown-config="multiDropdownConfig.x"
                :axis-config="xAxisRuleBuilderAxisStepConfig"
                :mid-point-config="xAxisRuleBuidlerMidPointConfig"
                dimension-type="x"
                @axisRuleApply="handleAxisRuleApply"
                @midPointRuleApply="handleMidPointRuleApply"
              />
              <multi-dropdown
                class="u-spacing-mb-m fit-content"
                :multi-dropdown-config="multiDropdownConfig.y"
                :axis-config="yAxisRuleBuilderAxisStepConfig"
                :mid-point-config="yAxisRuleBuidlerMidPointConfig"
                dimension-type="y"
                @axisRuleApply="handleAxisRuleApply"
                @midPointRuleApply="handleMidPointRuleApply"
              />
            </div>
            <div>
              <RuleBuilderTippyWrapperV2
                class="u-spacing-mr-m u-spacing-mb-m fit-content"
                :root="zAxisRuleBuilderAxisStepConfig.root"
                :chip-selections="bubbleRadiusSelections"
                chip-pre-text="Bubble radius:"
                :has-cross="true"
                chip-size="m"
                empty-state-trigger-text="Add A Metric For Bubble Radius"
                @ruleApply="handleZAxisRuleApply"
              />
              <BubbleChartLegend
                v-if="showBubbleRadiusLegend"
                :legend="computedLegendValues"
              />
            </div>
          </section>
        </section>
        <section class="u-flex-2 u-spacing-ml-m">
          <div
            class="u-color-grey-base u-font-weight-600 u-font-size-4 u-spacing-mb-m"
          >
            Filters
          </div>
          <rb-filter-panel
            id="filterID_wd5"
            ref="filter-panel"
            :key="filterKey"
            :data="filterData"
            :primary="primaryFilterData"
            :secondary-data="secondaryFilterData"
            :listener-event="filterLocalStorageKey"
            :emit="filterEmit"
            :loader-status="loaderStatus"
            :has-search="true"
            :allow-date-compare="true"
            :hide-filter="true"
            :enable-remove-all="false"
            :show-notification="false"
            :state="filterState"
            :get-filter-instance="getFilterInstance"
            chip-size="s"
            chip-padding-top="0px"
            chip-padding-bottom="0px"
            :external-filter-selection-listener="
              externalFilterSelectionListener
            "
            :rule-builder-root="filterConfig.root"
            :enable-open-filter="enableOpenFilter"
            @savedFilterApplied="filterKey++"
            @ruleApply="handleFilterRuleApply"
          />
        </section>
      </section>
      <section
        ref="bubble-chart-container"
        class="u-width-100 u-border-bottom u-border-width-s u-border-color-grey-xx-light u-position-relative"
      >
        <div
          class="u-filter-trigger u-position-absolute u-bg-color-blue-base u-spacing-p-xs u-cursor-pointer"
          @click.stop="toggle()"
        >
          <rb-icon
            v-tippy="{ placement: 'top', arrow: false }"
            :title="!showFiltersSection ? 'Expand Menu' : 'Collapse Menu'"
            class="u-color-grey-white rb-icon--medium"
            :icon="showFiltersSection ? 'menu_open' : 'menu_close'"
          />
        </div>
      </section>
      <section>
        <div
          v-if="chartLoad || midPointLoad || externalWidgetLoad"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <loader
            class="u-bg-color-grey-white"
            :loading="true"
            :color="'#007cf6'"
          />
        </div>
        <div
          v-else-if="!hasHierarchy"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter u-flex-direction-column"
        >
          <p class="u-line-height-1-1">
            No Shelves configured! Please switch from
            <span class="u-font-weight-600 u-color-grey-base">{{
              (titleOptionSelected || {}).title
            }}</span>
            to
            <span class="u-font-weight-600 u-color-grey-base">KEYWORDS</span>
            above.
          </p>
          <p class="u-line-height-1-1">
            Please reach out to your Engagement Manager to set up Shelves on
            Market Insights
          </p>
        </div>
        <div
          v-else-if="chartError || midPointError"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <p>Error Occurred. Please try again later</p>
        </div>
        <!--  -->
        <div
          v-else-if="chartNoData || midpointNoData || graphData.length === 0"
          class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
        >
          <p>No Data</p>
        </div>

        <section v-else>
          <section v-show="activeTab === 0">
            <section
              v-if="totalEntityCount > maxBubblesThresold"
              class="bubble-no-load u-spacing-ph-l u-spacing-mv-l u-display-flex u-flex-justify-content-center u-width-100"
            >
              <InteractableError
                class="interactable-error"
                image-source="/images/unable-to-show-chart.png"
                image-height="200px"
                image-width="345px"
                :buttons="buttons"
                @tableSwitch="handleTableSwitch"
                @addFilter="handleAddFilter"
              >
                <div
                  slot="text"
                  class="u-font-size-3 u-spacing-mb-xl u-spacing-mt-s"
                >
                  Currently plotting {{ totalEntityCount }} {{ currentLevel }} -
                  the Bubble Chart visualization only supports plotting upto
                  {{ maxBubblesThresold }} shelves
                </div>
              </InteractableError>
            </section>
            <section v-else>
              <div
                class="u-spacing-mt-m u-spacing-mb-s u-width-100 u-display-flex u-flex-justify-content-space-between"
              >
                <DoubleHeadedTitle
                  v-for="item in 2"
                  :key="item"
                  :label1="
                    (tableTitleConfig[indexToQuadrantMap[item - 1]] || {})
                      .label1
                  "
                  :label2="
                    (tableTitleConfig[indexToQuadrantMap[item - 1]] || {})
                      .label2
                  "
                  :dot-color="
                    (tableTitleConfig[indexToQuadrantMap[item - 1]] || {}).color
                  "
                />
              </div>
              <bubble-chart
                ref="chart"
                :width="computedChartDimensions.width"
                :height="computedChartDimensions.height"
                class="clip u-bg-color-grey-white u-width-100 u-border-width-s u-border-color-grey-xxx-light u-border-top u-border-left u-border-right u-border-bottom u-border-radius-xs"
                :data="graphData"
                :x-axis-offset="computedXAxisOffset"
                :y-axis-offset="computedYAxisOffset"
                :x-translation-offset="logNegativeXOffset"
                :y-translation-offset="logNegativeYOffset"
                :x-axis-label="computedXLabel"
                :y-axis-label="computedYLabel"
                :z-axis-label="computedZLabel"
                :x-axis-unit="computedXUnit"
                :y-axis-unit="computedYUnit"
                :z-axis-unit="computedZUnit"
                :max-bubble-radius="normalizedRadiusMaxValue"
                :min-bubble-radius="normalizedRadiusMinValue"
                :is-zoom-enabled="isZoomEnabled"
                :is-lasso-enabled="isLassoEnabled"
                :show-forced-labels-only="showForcedLabelsOnly"
                :highlight-selected-bubbles="highlightSelectedBubbles"
                :clicked-bubble="clickedBubble"
                :x-scale-domain="xScaleDomain"
                :y-scale-domain="yScaleDomain"
                @lassoSelect="handlePointsSelect"
                @zoom="handleZoom"
                @bubble-click="handleBubbleClick"
                @svgClick="handleSvgClick"
              >
                <section
                  ref="bubble-chart-tooltip"
                  slot="tooltip"
                  slot-scope="{ popupData }"
                >
                  <BubbleChartPopup
                    v-if="!clickedBubble"
                    class="u-pointer-events-none"
                    :popup-data="popupData"
                  >
                    <div
                      slot="footer"
                      class="u-color-blue-base u-font-weight-600 u-font-size-6 u-spacing-ph-m u-spacing-pv-s u-border-width-s u-border-top u-border-color-grey-xxx-light"
                    >
                      Click on the bubble to view more actions
                    </div>
                  </BubbleChartPopup>
                  <BubbleClickOptions
                    v-else
                    :options="computedBubbleOptions"
                    @select="handleSelect"
                  />
                </section>
              </bubble-chart>
              <div
                class="u-width-100 u-spacing-mt-s u-display-flex u-flex-justify-content-space-between"
              >
                <DoubleHeadedTitle
                  v-for="item in 2"
                  :key="item"
                  :label1="
                    (tableTitleConfig[indexToQuadrantMap[item + 1]] || {})
                      .label1
                  "
                  :label2="
                    (tableTitleConfig[indexToQuadrantMap[item + 1]] || {})
                      .label2
                  "
                  :dot-color="
                    (tableTitleConfig[indexToQuadrantMap[item + 1]] || {}).color
                  "
                />
              </div>
            </section>
          </section>
          <section
            v-show="activeTab === 1"
            class="u-display-flex u-flex-wrap-yes"
          >
            <section
              v-for="(chartData, index) in tabularData"
              :key="index"
              class="table-width"
            >
              <section
                class="u-spacing-p-l u-border-top u-border-left u-border-right u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
              >
                <DoubleHeadedTitle
                  class="u-spacing-pb-l"
                  :quadrant="indexToQuadrantMap[index]"
                  :label1="
                    (tableTitleConfig[indexToQuadrantMap[index]] || {}).label1
                  "
                  :label2="
                    (tableTitleConfig[indexToQuadrantMap[index]] || {}).label2
                  "
                  :dot-color="
                    (tableTitleConfig[indexToQuadrantMap[index]] || {}).color
                  "
                />
                <tableContainer
                  class="u-border-top u-border-left u-border-right u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
                  :load="tabularData[indexToQuadrantMap[index]].load"
                  :error="tabularData[indexToQuadrantMap[index]].error"
                  :no-data="
                    ((tabularData[indexToQuadrantMap[index]] || {}).rows || [])
                      .length === 0
                  "
                  :config="tabularData[indexToQuadrantMap[index]].config"
                  :grid-options="
                    (tabularData[indexToQuadrantMap[index]] || {}).gridOptions
                  "
                  :table-row="
                    (tabularData[indexToQuadrantMap[index]] || {}).rows
                  "
                  :table-column="columnDefs"
                  :pagination-total-key="
                    (tabularData[indexToQuadrantMap[index]] || {})
                      .totalEntityCount
                  "
                  :pagination-per-page-limit="
                    ((tabularData[indexToQuadrantMap[index]] || {}).rows || {})
                      .length
                  "
                  :pagination-change-event="
                    paginationActionBase + (indexToQuadrantMap[index] + 1)
                  "
                  :enable-server-side-sorting="false"
                  @rowSelect="handleRowSelect"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <div
      slot="footer-right"
      class="u-display-flex u-flex-justify-content-flex-end u-flex-grow-1"
    >
      <div class="u-display-flex">
        <rb-icon
          class="u-display-flex u-flex-0 rb-icon--medium u-spacing-mr-xs u-color-grey-lighter"
          :icon="'powered_by'"
        />
        <div class="u-color-grey-lighter u-spacing-mr-xs">
          {{ currentLevel }}:
        </div>
        <div>{{ totalEntityCount }}</div>
      </div>
    </div>
  </cardContainer>
</template>

<script>
import cardContainer from '../organisms/card-container';
import loader from '@/components/basic/loader';
import tabs from '@/components/pages/insights/amazon/share-of-voice/molecules/tabs';
import chart from '@/components/basic/chart';
import bubbleChart from '@/components/basic/bubble-chart';
import titleSelect from '@/components/pages/insights/amazon/share-of-voice/molecules/title-select';
import viewsSelect from '@/components/pages/insights/amazon/share-of-voice/atoms/views-select';
import BubbleChartLegend from '@/components/pages/insights/amazon/share-of-voice/atoms/bubble-radius-legend';
import ChartOptions from '@/components/basic/chart-options';
import widgetMixin from '@/components/widgetMixin';
import multiDropdown from '@/components/pages/insights/amazon/share-of-voice/molecules/multi-dropdown';
import RuleBuilderV2 from '@/components/pages/insights/amazon/share-of-voice/molecules/rule-builder-v2.vue';
import RuleBuilderTippyWrapperV2 from '@/components/pages/insights/amazon/share-of-voice/molecules/rule-builder-tippy-wrapper-v2.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import { cloneDeep, debounce } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import BubbleChartPopup from '@/components/pages/insights/amazon/share-of-voice/atoms/bubble-chart-popup';
import tableContainer from '@/components/pages/insights/amazon/share-of-voice/organisms/table-container.vue';
import ruleBuilderChip from '@/components/pages/insights/amazon/share-of-voice/atoms/rule-builder-chip';
import InputWithIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/input-with-icon.vue';
import InteractableError from '@/components/pages/insights/amazon/share-of-voice/atoms/interactable-error.vue';
import BubbleClickOptions from '@/components/pages/insights/amazon/share-of-voice/atoms/bubble-click-options';
import {
  getColumnDefinition,
  appendPartialArrays,
  requestDateReplacement,
  requestTimeReplacement
} from './dashboard-service-utils';
import {
  getSegmentedBrandsData,
  digitalShelfLevels,
  setShelfLevel,
  titleOptionSelect,
  selectedTitleOptions,
  appendIsNotNull,
  getFilteredShelfHierarchy,
  disableInHierarchy,
  setLeafLevel,
  hasHierarchy,
  splitClientCompOnAnchor,
  setRuleBuilderBrand,
  appendAnchorBrandCondition,
  appendAnchorFilter,
  replaceOrderAndValue
} from './common';
import DoubleHeadedTitle from '@/components/pages/insights/amazon/share-of-voice/atoms/double-headed-title.vue';
import ToggleSlider from '@/components/pages/insights/amazon/share-of-voice/atoms/toggle-slider';
import moment from 'moment';
import HttpService from '@/utils/services/http-service';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import { formatter } from '@/utils/helpers/formatter.js';
import {
  setInitialUnsavedState,
  getUnsavedState,
  getWidgetUnsavedState,
  setUnsavedState,
  getUnsavedSettings
} from './views-tracking';
import {
  KEYWORDS_PATH,
  RULE_BUILDER_SOV_ANCHOR,
  SOV_DRIVERS_FILTER_APPLY,
  BRAND_BY_CLIENT_FLAG,
  BRAND
} from '@/components/pages/insights/amazon/share-of-voice/templates/constants.js';
import transformer from '@/utils/services/data-transformer.js';
import CONSTANTS from '@/utils/constants';

export default {
  components: {
    chart,
    BubbleClickOptions,
    ChartOptions,
    bubbleChart,
    loader,
    cardContainer,
    tabs,
    titleSelect,
    viewsSelect,
    BubbleChartLegend,
    multiDropdown,
    rbFilterPanel,
    BubbleChartPopup,
    tableContainer,
    RuleBuilderV2,
    ruleBuilderChip,
    InputWithIcon,
    InteractableError,
    RuleBuilderTippyWrapperV2,
    DoubleHeadedTitle,
    ToggleSlider
  },
  mixins: [widgetMixin],
  props: {
    sovDataGroups: {
      type: Object,
      default: () => ({})
    },
    brandsList: {
      type: Object,
      default: {}
    },
    standardHierarchy: {
      type: Object,
      default: {}
    },
    clientBrands: {
      type: Object,
      default: {}
    },
    previousAnchorBrand: {
      type: Object,
      default: null
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    unsavedState: {
      type: Object,
      default: () => ({})
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    radioBaseConfig: {
      type: Object,
      default: () => ({})
    },
    expressionBaseConfig: {
      type: Object,
      default: () => ({})
    },
    metricsBaseConfig: {
      type: Object,
      default: () => ({})
    },
    textInputBaseConfig: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      default: null
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    multiCrawl: {
      type: Boolean,
      default: false
    },
    intradayCrawlingAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewportVisibleConfigs: {
        onWidgetVisible: this.onWidgetVisible,
        options: {
          root: document.querySelector('#pageBody'),
          rootMargin: '0px',
          threshold: 0.4
        }
      },
      externalFilterSelectionListener: 'drivers-external-selection-set',
      initialised: false,
      bubbleOptions: [
        {
          title: 'Analyze shelf',
          id: 'ADD_TO_FILTER',
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5'
              ]
            }
          }
        },
        {
          title: 'View Keyword Drivers',
          id: 'KEYWORD_DRIVERS',
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5'
              ]
            }
          }
        },
        {
          title: 'Snapshots of Page 1',
          id: 'SNAPSHOT',
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5',
                'search_term'
              ]
            }
          }
        }
      ],
      clickedBubble: null,
      enableOpenFilter: false,
      customViewTitle: 'Custom',
      sovMetricPlaceholder: ':sovMetric',
      actionIcons: [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with chosen metrics and quadrant placement for shelves in scope'
        }
      ],
      downloadApi: {
        error: false,
        loading: false
      },
      lastUpdatedDate: null,
      selectedView: '',
      views: [],
      filterConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      defaultRadius: 15,
      bubbleRadiusSelections: [],
      totalEntityCount: 0,
      maxBubblesThresold: 600,
      buttons: [
        {
          type: 'hollow',
          emit: 'tableSwitch',
          text: 'Switch to table view',
          size: 'm'
        },
        {
          type: 'filled',
          emit: 'addFilter',
          text: 'Plot top shelves',
          size: 'm'
        }
      ],
      showForcedLabelsOnly: false,
      highlightSelectedBubbles: false,
      showFiltersSection: true,
      normalizedRadiusMaxValue: -Infinity,
      normalizedRadiusMinValue: Infinity,
      logNegativeXOffset: 0,
      logNegativeYOffset: 0,
      filterInstance: null,
      selectedOptionIndex: 0,
      selectedPointsStore: {},
      indexToQuadrantMap: {
        0: 1,
        1: 0,
        2: 2,
        3: 3
      },
      midPointLoad: false,
      midPointError: false,
      limit: 150,
      paginationActionBase: 'table-paginate-',
      gridOptions: {
        suppressColumnVirtualisation: false,
        defaultColDef: {
          resizable: false
        },
        domLayout: 'normal'
      },
      columnDefs: [],
      tabularData: [{}, {}, {}, {}],
      chartLoad: true,
      chartError: false,
      chartNoData: false,
      midpointNoData: false,
      apiData: {},
      selectedXMetric: {},
      selectedYMetric: {},
      selectedZMetric: {},
      selectedxData: {},
      selectedyData: {},
      selectedzData: {},
      ternaryXData: {},
      ternaryYData: {},
      ternaryZData: {},
      brandOrder: [],
      xAxisRuleBuilderAxisStepConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      yAxisRuleBuilderAxisStepConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      zAxisRuleBuilderAxisStepConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      xAxisRuleBuidlerMidPointConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      yAxisRuleBuidlerMidPointConfig: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      baseMultiDropdownConfig: [
        {
          iconConfig: {
            name: 'caret-down',
            size: 'small'
          },
          emit: 'metricSelect',
          selected: [],
          seperator: {
            name: 'mid_point_inverted',
            color: 'orange-base',
            size: 'medium'
          }
        },
        {
          iconConfig: {
            name: 'pencil',
            size: 'xx-small'
          },
          preText: 'Mid point',
          emit: 'midPointSelect',
          selected: [[{ load: true }]]
        }
      ],
      minX: null,
      maxX: null,
      minY: null,
      maxY: null,
      minRadius: Infinity,
      maxRadius: -Infinity,
      normalizedMinRadius: 10,
      normalizedMaxRadius: 40,
      multiDropdownConfig: { x: {}, y: {} },
      trueXAxisCenter: 0,
      trueYAxisCenter: 0,
      filterLocalStorageKey: 'sov-widget-5',
      filterKey: 0,
      filterEmit: SOV_DRIVERS_FILTER_APPLY,
      digitalShelfLevels: [],
      tabData: [{ label: 'Bubble Chart' }, { label: 'Table' }],
      metadata: {},
      graphData: [],
      chartOptionsData: [
        { icon: 'zoom_in', text: 'ZOOM IN', enabled: true, emit: 'zoomIn' },
        { icon: 'zoom_out', text: 'ZOOM OUT', enabled: true, emit: 'zoomOut' },
        {
          icon: 'selection_lasso',
          text: 'LASSO SELECT',
          enabled: true,
          emit: 'lassoSelect'
        },
        {
          icon: 'reset_to_default',
          text: 'RESET TO DEFAULT',
          enabled: false,
          emit: 'reset'
        }
      ],
      isZoomEnabled: true,
      isLassoEnabled: false,
      xScaleDomain: {
        min: 0,
        max: 100
      },
      yScaleDomain: {
        min: 0,
        max: 100
      }
    };
  },
  computed: {
    externalWidgetLoad() {
      return (
        this.brandsList.load ||
        this.standardHierarchy.load ||
        this.clientBrands.load
      );
    },
    initialisedAndExternalLoaded() {
      return !this.externalWidgetLoad && this.initialised;
    },
    externalWidgetError() {
      return (
        this.brandsList.error ||
        this.standardHierarchy.error ||
        this.clientBrands.error ||
        this.metadataError
      );
    },
    computedBubbleOptions() {
      const { value } = this.titleOptionSelected;
      return this.bubbleOptions.filter((option) => {
        if (option?.custom?.show?.entityType?.includes(value)) {
          return option;
        }
      });
    },
    hasHierarchy() {
      return hasHierarchy(this);
    },
    getSelectedCustomShelves() {
      return this.$store.getters[this.namespace + 'getSelectedCustomShelves'];
    },
    getSelectedStandardShelves() {
      return this.$store.getters[this.namespace + 'getSelectedStandardShelves'];
    },
    getCustomHierarchy() {
      return this.$store.getters[this.namespace + 'getCustomHierarchy'];
    },
    getStandardHierarchy() {
      return this.$store.getters[this.namespace + 'getStandardHierarchy'];
    },
    filterRuleBuilderChipSelections() {
      const unsavedState = getWidgetUnsavedState(this);
      return unsavedState.filterRuleBuilderChipSelections;
    },
    currentLevel() {
      if (this.titleOptionSelected.value === 'search_term') {
        return 'Keywords';
      } else {
        return 'Shelves';
      }
    },
    titleOptionSelected() {
      return selectedTitleOptions(this);
    },
    activeTab() {
      return this.getUnsavedWidgetState.activeTabIndex;
    },
    showBubbleRadiusLegend() {
      return (
        this.bubbleRadiusSelections.length &&
        !this.midPointLoad &&
        !this.midpointNoData &&
        !this.metadataLoad &&
        !this.metadataError &&
        this.computedLegendValues.length
      );
    },
    computedLegendValues() {
      if (
        this.minRadius === null ||
        this.maxRadius === null ||
        !isFinite(this.minRadius) ||
        !isFinite(this.maxRadius)
      ) {
        return [];
      }
      if (this.minRadius === this.maxRadius) {
        return this.generateLegendsFormat(
          (this.normalizedMinRadius + this.normalizedMaxRadius) / 2,
          this.computedZUnit
        );
      }
      const values = [
        {
          radius: this.normalizedMinRadius,
          labelValue: this.minRadius
        },
        {
          radius: (this.normalizedMinRadius + this.normalizedMaxRadius) / 2,
          labelValue: (this.minRadius + this.maxRadius) / 2
        },
        {
          radius: this.normalizedMaxRadius,
          labelValue: this.maxRadius
        }
      ];
      return values.map((item) =>
        this.generateLegendsFormat(
          item.radius,
          item.labelValue,
          this.computedZUnit
        )
      );
    },
    computedChartDimensions() {
      const dimensions =
        this.$refs['widget-container']?.getBoundingClientRect() || {};
      const ratio = 2;
      const width = dimensions.width || 2560;
      const height = width / ratio || 1000;
      return { width, height };
    },
    computedXUnit() {
      return this.getChangeMetricUnit(
        this.ternaryXData.value,
        this.selectedXMetric
      );
    },
    computedYUnit() {
      return this.getChangeMetricUnit(
        this.ternaryYData.value,
        this.selectedYMetric
      );
    },
    computedZUnit() {
      return this.getChangeMetricUnit(
        this.ternaryZData.value,
        this.selectedZMetric
      );
    },
    computedXLabel() {
      const { title } = this.getTitleAndTooltip(
        this.selectedXMetric,
        this.selectedxData
      );
      const suffix = this.ternaryXData?.value === 'CHANGE' ? ' (Change)' : '';
      return title + suffix;
    },
    computedYLabel() {
      const { title } = this.getTitleAndTooltip(
        this.selectedYMetric,
        this.selectedyData
      );
      const suffix = this.ternaryYData?.value === 'CHANGE' ? ' (Change)' : '';
      return title + suffix;
    },
    computedZLabel() {
      const { title } = this.getTitleAndTooltip(
        this.selectedZMetric,
        this.selectedzData
      );
      const suffix = this.ternaryZData?.value === 'CHANGE' ? ' (Change)' : '';
      return title + suffix;
    },
    tableTitleConfig() {
      const xLabel = this.computedXLabel;
      const yLabel = this.computedYLabel;
      // const isXInverted = this.selectedXMetric?.metadata?.isInverted;
      // const isYInverted = this.selectedYMetric?.metadata?.isInverted;
      const HIGH = 'High';
      const LOW = 'Low';
      return {
        0: {
          label1: {
            direction: HIGH,
            text: xLabel
            // color: this.getLabelColor(HIGH, isXInverted)
          },
          label2: {
            direction: HIGH,
            text: yLabel
            // color: this.getLabelColor(HIGH, isYInverted)
          },
          color: '#23b5d3'
        },
        1: {
          label1: {
            direction: LOW,
            text: xLabel
            // color: this.getLabelColor(LOW, isXInverted)
          },
          label2: {
            direction: HIGH,
            text: yLabel
            // color: this.getLabelColor(HIGH, isYInverted)
          },
          color: '#ffd500'
        },
        2: {
          label1: {
            direction: LOW,
            text: xLabel
            // color: this.getLabelColor(LOW, isXInverted)
          },
          label2: {
            direction: LOW,
            text: yLabel
            // color: this.getLabelColor(LOW, isYInverted)
          },
          color: '#ff6072'
        },
        3: {
          label1: {
            direction: HIGH,
            text: xLabel
            // color: this.getLabelColor(HIGH, isXInverted)
          },
          label2: {
            direction: LOW,
            text: yLabel
            // color: this.getLabelColor(LOW, isYInverted)
          },
          color: '#bd10e0'
        }
      };
    },
    computedXAxisOffset() {
      return this.trueXAxisCenter + this.logNegativeXOffset;
    },
    computedYAxisOffset() {
      return this.trueYAxisCenter + this.logNegativeYOffset;
    },
    getUnsavedState() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'];
    },
    getUnsavedWidgetState() {
      return this.getUnsavedState[this.widgetName];
    },
    computedSettings() {
      return this.getUnsavedState.settings;
    },
    getSelectedSovType() {
      return this.computedSettings?.selectedType || [];
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    filterState() {
      return {
        getter: this.namespace + 'getWidget5Filters',
        setter: this.namespace + 'setWidget5Filters'
      };
    },
    getWidget5WhereClause() {
      return this.$store.getters[this.namespace + 'getWidget5WhereClause'];
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metadataError() {
      return this?.dataService?.metadataError;
    },
    keywordRankKey() {
      return this.multiCrawl ? 'keyword_rank_intraday' : 'keyword_rank';
    }
  },
  watch: {
    initialisedAndExternalLoaded: {
      immediate: true,
      handler(initialisedAndExternalLoaded) {
        if (this.multiDropdownConfig?.x?.[1]) {
          this.multiDropdownConfig.x[1].selected = [[{ load: true }]];
        }
        if (this.multiDropdownConfig?.y?.[1]) {
          this.multiDropdownConfig.y[1].selected = [[{ load: true }]];
        }
        this.multiDropdownConfig = { ...this.multiDropdownConfig };
        if (initialisedAndExternalLoaded) {
          this.transformAndFetch();
        }
      }
    }
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    eventBus.$off('table-paginate-1');
    eventBus.$off('table-paginate-2');
    eventBus.$off('table-paginate-3');
    eventBus.$off('table-paginate-4');
  },
  created() {
    this.initialised = false;
    this.setTitleDropdownContents();
    disableInHierarchy(this);
    eventBus.$on(this.filterEmit, (data) => {
      this.setGlobalFiltersInUnsavedState();
      this.getMidPointAndChartData();
    });
    eventBus.$on('table-paginate-1', (data) => {
      this.getTableData(1, data);
    });
    eventBus.$on('table-paginate-2', (data) => {
      this.getTableData(2, data);
    });
    eventBus.$on('table-paginate-3', (data) => {
      this.getTableData(3, data);
    });
    eventBus.$on('table-paginate-4', (data) => {
      this.getTableData(4, data);
    });
    this.resetPagination();
    setInitialUnsavedState(this);
    this.removeLocalStorage(this.filterLocalStorageKey);
    this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
    this.setGridOptions();

    this.resetAxialWhereClauseCombination();

    this.xAxisRuleBuidlerMidPointConfig =
      this.setMidPointRuleBuilderInitialState(
        this.xAxisRuleBuidlerMidPointConfig
      );
    this.yAxisRuleBuidlerMidPointConfig =
      this.setMidPointRuleBuilderInitialState(
        this.yAxisRuleBuidlerMidPointConfig
      );
    this.multiDropdownConfig.x = this.setInitialMultiDropdownConfig();
    this.multiDropdownConfig.y = this.setInitialMultiDropdownConfig();

    this.multiDropdownConfig.x[0].preText = 'Horizontal axis';
    this.multiDropdownConfig.y[0].preText = 'Vertical axis';
    const unsavedState = getWidgetUnsavedState(this);
    const filterPayload = this.transformFilterFromAPI(
      unsavedState.filters.selectedFilter.filter
    );
    if (filterPayload.values[RULE_BUILDER_SOV_ANCHOR]) {
      // the share of voice rule builder in the filters is creating a local copy for local stprage, this copy becomes stale to the external filter brands subtituion, setting this the latest filter data to avoid bugs
      filterPayload.values[RULE_BUILDER_SOV_ANCHOR] =
        unsavedState.filterRuleBuilderChipSelections;
    }
    this.isIntradayKeywordKey = 'is_intraday';
    this.setFiltersLocalStorage(filterPayload);
    this.init();
    this.$emit('created', this);
  },
  methods: {
    onWidgetVisible() {
      this.initialised = true;
    },
    setTitleDropdownContents() {
      this.digitalShelfLevels = digitalShelfLevels(this.multiCrawl);
    },
    getLabelColor(labelDirection, isInverted) {
      if (isInverted) {
        if (labelDirection === 'High') {
          return 'red-base';
        } else {
          return 'green-base';
        }
      } else {
        if (labelDirection === 'Low') {
          return 'red-base';
        } else {
          return 'green-base';
        }
      }
    },
    appendAnchorBrand(requestCopy, dataGroup) {
      const { anchoredBrand } = getUnsavedSettings(this);
      if (
        this.sovDataGroups[dataGroup] &&
        appendAnchorBrandCondition(anchoredBrand)
      ) {
        requestCopy.operations.anchoredBrand = anchoredBrand.title;
      }
    },
    getFormattedBrandsList() {
      const { client, comp } = this.getClientCompData();
      return getSegmentedBrandsData(client, comp);
    },
    getClientCompData() {
      const { rows: compApiData } =
        this.$store.getters[this.namespace + 'getBrandsData'];
      const { anchoredBrand } = getUnsavedSettings(this);
      const { rows: clientApi } =
        this.$store.getters[this.namespace + 'getClientBrandsData'];
      return splitClientCompOnAnchor(compApiData, clientApi, anchoredBrand);
    },
    handleSelect(payload) {
      this.$refs.chart.showTooltip = false;
      this.$refs.chart.removeD3Popup(this.clickedBubble);
      const { type, level } = selectedTitleOptions(this);
      const obj = { title: this.clickedBubble.text, type, level };
      if (payload.id === 'ADD_TO_FILTER') {
        this.$emit('addToFilter', obj);
      } else if (payload.id === 'KEYWORD_DRIVERS') {
        this.$emit('keywordsDriver', obj);
      } else if (payload.id === 'SNAPSHOT') {
        this.handleKeywordSelection({ value: this.clickedBubble.text });
      }
      this.clickedBubble = null;
    },
    handleSvgClick() {
      if (this.clickedBubble) {
        this.$refs.chart.removeD3Popup(this.clickedBubble);
      }
      this.clickedBubble = null;
    },
    handleBubbleClick(d) {
      this.handleSvgClick();
      this.clickedBubble = d;
      this.$refs.chart.attachD3Popup(this.clickedBubble);
      this.$nextTick(() => {
        const tooltipDimenions =
          this.$refs['bubble-chart-tooltip'].getBoundingClientRect();
        this.$refs.chart.setBubblePopupPosition(d, tooltipDimenions);
      });
    },
    handleKeywordSelection(payload) {
      const { value: selectedText } = payload;
      const selectedTitle = selectedTitleOptions(this);
      const { api, value } = selectedTitle;
      if (value === 'search_term') {
        this.$emit('setAccessedFrom', {
          hierarchy: 'search_term',
          text: selectedText
        });
        this.$emit('setKeywordSelection', selectedText);
      } else {
        const { prefix } = api;
        const prefixSplit = prefix.split('~');
        const externalDimensionNameValueList = [];
        externalDimensionNameValueList.push(
          {
            dimensionName: value,
            dimensionValue: selectedText
          },
          { dimensionName: 'tag_type', dimensionValue: prefixSplit[0] }
        );
        this.$emit('setAccessedFrom', {
          hierarchy: 'shelf',
          text: selectedText
        });
        this.$emit('setWhereClause', externalDimensionNameValueList);
      }
    },
    generateLegendsFormat(radius, labelValue, unit) {
      return {
        radius: radius,
        label: formatter(labelValue, unit, undefined, 1)
      };
    },
    getChangeMetricUnit(metricValue, metricMetadata) {
      if (metricValue === 'CHANGE') {
        return 'PERCENTAGE';
      }
      return metricMetadata?.metadata?.unit || 'NUMERIC';
    },
    setShelfLevel() {
      setShelfLevel(this);
    },
    setShelfToKeywords() {
      setLeafLevel(this, KEYWORDS_PATH);
    },
    setDefaultStateToKeywords() {
      setLeafLevel(this, KEYWORDS_PATH);
      setLeafLevel(this, KEYWORDS_PATH, 'initial');
    },
    resetPagination() {
      this.tabularData.forEach((table) => {
        table.config = {};
        table.config.body = {};
        table.config.body.APIConfig = {};
        table.config.body.APIConfig.page = 1;
      });
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    setGlobalFiltersInUnsavedState() {
      const filters = this.getSelectedFiltersValues();
      setUnsavedState(this, 'filters', filters);
    },
    getSelectedFiltersValues() {
      const getFilterValues = this.filterInstance?.getSelectedFilters() || {};
      if (getFilterValues.values) {
        return {
          selectedFilter: {
            filter: [...this.filterInstance?.getFiltersFullMetadata()],
            order: [...getFilterValues.order]
          }
        };
      }
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    setFilterLocalStorage() {
      const unsavedState = getWidgetUnsavedState(this);
      this.setFiltersLocalStorage(unsavedState.filters);
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    setFiltersLocalStorage(storageValues) {
      this.setLocalStorage(this.filterLocalStorageKey, storageValues.values);
      this.setLocalStorage(
        `${this.filterLocalStorageKey}-order`,
        storageValues.order
      );
    },
    getDefaultState() {
      return {
        customStateAxisConfig: null,
        filterRuleBuilderChipSelections: [],
        filters: {
          selectedFilter: {
            filter: [{}],
            order: []
          }
        },
        selectedViewsId: 2,
        activeTabIndex: 0,
        titleOptionSelected: 'search_term',
        titlePath: ['KEYWORDS']
      };
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const { endPoint, service } = this.metadata.downloadApiTemplate;
          const requestArray = [];
          for (let i = 1; i <= 4; i++) {
            const quadRequest = this.getTableRequest(i);
            delete quadRequest.operations.limit;
            quadRequest.widget = this.widgetName;
            quadRequest.page = this.page;
            requestArray.push(quadRequest);
          }
          const { data } = await HttpService.post(service, requestArray, {
            append: endPoint
          });
          downloadFile(data.url);
          resolve();
        } catch (e) {
          console.error('error', e);
          reject(e);
        }
      });
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIcons = actionIconsCopy;
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    getMetricsFromStep1(step1) {
      if (!step1) return {};
      let metricStepCopy = cloneDeep(step1);
      if (metricStepCopy.metric === this.sovMetricPlaceholder) {
        metricStepCopy = this.getSovMetricConfig();
      } else {
        metricStepCopy = cloneDeep(
          this.metadata.metrics[metricStepCopy.metric]
        );
      }
      metricStepCopy.infix = {
        text: metricStepCopy.label
      };
      return metricStepCopy;
    },
    createDropdownConfigFromSteps(step1, step2, step3) {
      const metricStepCopy = this.getMetricsFromStep1(step1);
      return [[metricStepCopy], [step2], [step3]];
    },
    formuleRuleBuilderState(payload) {
      const { title, stepsConfig } = payload;
      const formattedStepsConfig = this.transformViewStepsConfig(stepsConfig);
      this.selectedView = title;
      const { x, y, z } = formattedStepsConfig;
      this.setAxisStepData('x', x.step1, x.step2, x.step3);
      this.setAxisStepData('y', y.step1, y.step2, y.step3);
      if (z && Object.keys(z).length) {
        this.setAxisStepData('z', z.step1, z.step2, z.step3);
      } else {
        this.bubbleRadiusSelections = [];
      }
    },
    handleViewsSelect(context, payload) {
      if (payload && payload[0]) {
        this.formuleRuleBuilderState(payload[0], true);
        setUnsavedState(this, 'selectedViewsId', payload[0].id || 0);
        this.getMidPointAndChartData();
      }
    },
    handleCheck(isChecked) {
      this.graphData = this.graphData.map((item) => {
        item.forceLabel = false;
        return item;
      });
      this.showForcedLabelsOnly = !isChecked;
    },
    handleTableSwitch() {
      const index = this.tabData.findIndex((item) => item.label === 'Table');
      setUnsavedState(this, 'activeTabIndex', index);
    },
    volumeRankPayload() {
      return {
        values: {
          [this.keywordRankKey]: [
            {
              operator: { title: '<', operator: 'LESS_THAN', selectedIndex: 3 },
              value: this.maxBubblesThresold,
              unit: null
            }
          ]
        },
        order: [this.keywordRankKey]
      };
    },
    appendFilterPayload(filtersPayload) {
      const { order, values } = this.volumeRankPayload();
      const keyToAppend = order[0];
      return replaceOrderAndValue(
        filtersPayload,
        keyToAppend,
        values[keyToAppend]
      );
    },
    handleAddFilter() {
      const currentFilters = this.filterInstance.getSelectedFilters();
      const newFilters = this.appendFilterPayload(currentFilters);
      this.filterInstance.appendToFilters(newFilters);
    },
    handleInput(data) {
      const debouncedInput = debounce(() => {
        let newGraphData = [];
        const lowerCaseInput = data.toLowerCase();
        if (data.length) {
          this.showForcedLabelsOnly = true;
          this.highlightSelectedBubbles = true;
          newGraphData = this.graphData.map((item) => {
            const lowerCaseLabel = item?.text?.toLowerCase();
            if (lowerCaseLabel && lowerCaseLabel.includes(lowerCaseInput)) {
              item.forceLabel = true;
              item.searchSelected = true;
            } else {
              item.forceLabel = false;
              item.searchSelected = false;
            }
            return item;
          });
          this.translateAndScaleSvgAfterInput();
        } else {
          this.showForcedLabelsOnly = false;
          this.highlightSelectedBubbles = false;

          newGraphData = this.graphData.map((item) => {
            item.forceLabel = false;
            item.searchSelected = false;
            return item;
          });
          this.resetZoom();
        }
        this.selectInTable();
        this.graphData = newGraphData;
      }, 1000);
      debouncedInput();
    },
    getDistance(xMin, xMax, yMin, yMax) {
      return Math.sqrt((xMin - xMax) ** 2 + (yMin - yMax) ** 2);
    },
    translateAndScaleSvgAfterInput() {
      const chartInstance = this.getChartInstance();
      // chartInstance?.programmaticZoom(chartInstance?.initialZoomLevel);
      // setTimeout(() => {
      const {
        minActiveLabel,
        maxActiveLabel,
        originalXAxisScale,
        // originalYAxisScale,
        newXAxisScale,
        newYAxisScale
      } = chartInstance;
      if (
        !isFinite(minActiveLabel.x) ||
        !isFinite(minActiveLabel.y) ||
        !isFinite(maxActiveLabel.x) ||
        !isFinite(maxActiveLabel.y)
      ) {
        chartInstance?.programmaticZoom(chartInstance?.initialZoomLevel);
        return;
      }
      const [unScaledOldXMin, unscaledOldXMax] = originalXAxisScale.domain();
      const scaledOldXMin = originalXAxisScale(unScaledOldXMin);
      const scaledOldXMax = originalXAxisScale(unscaledOldXMax);
      const oldViewportWidth = Math.abs(scaledOldXMin - scaledOldXMax);
      let newViewportWidth = Math.abs(
        originalXAxisScale(minActiveLabel.x) -
          originalXAxisScale(maxActiveLabel.x)
      );
      if (!newViewportWidth) {
        newViewportWidth = oldViewportWidth / 2;
      }
      const zoomLevel = oldViewportWidth / newViewportWidth;
      const scaledMinActiveX = newXAxisScale(minActiveLabel.x);
      const scaledMaxActiveX = newXAxisScale(maxActiveLabel.x);
      const scaledMinActiveY = newYAxisScale(minActiveLabel.y);
      const scaledMaxActiveY = newYAxisScale(maxActiveLabel.y);
      if (!zoomLevel || !isFinite(zoomLevel)) {
        chartInstance?.programmaticZoom(chartInstance?.initialZoomLevel);
        return;
      }
      if (
        !isFinite(scaledMinActiveX) ||
        !isFinite(scaledMaxActiveX) ||
        !isFinite(scaledMinActiveY) ||
        !isFinite(scaledMaxActiveY)
      ) {
        chartInstance?.programmaticZoom(chartInstance?.initialZoomLevel);
        return;
      }

      chartInstance?.programmaticZoom(zoomLevel - 0.2);
      setTimeout(() => {
        const centerPointX =
          scaledMinActiveX + (scaledMaxActiveX - scaledMinActiveX) / 2;
        const centerPointY =
          scaledMinActiveY + (scaledMaxActiveY - scaledMinActiveY) / 2;
        chartInstance?.programmaticTranslate(centerPointX, centerPointY);
      }, 750);
    },
    toggle() {
      this.showFiltersSection = !this.showFiltersSection;
    },
    handleFilterRuleApply(payload) {
      setUnsavedState(
        this,
        'filterRuleBuilderChipSelections',
        cloneDeep(payload)
      );
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    computeSelctedBrand(brandConfig) {
      const clientCompData = this.getClientCompData();
      if (clientCompData && brandConfig) {
        let selectedBrand = null;
        if (brandConfig?.brand === 'client' || brandConfig?.brand === 'comp') {
          const targetBrands = clientCompData?.[brandConfig?.brand] || [];
          selectedBrand = targetBrands?.[brandConfig?.index]?.brand;
        }
        if (selectedBrand) {
          brandConfig.brand = selectedBrand;
        }
      }
    },
    transformViewStepsConfig(stepsConfig) {
      const stepsConfigCopy = cloneDeep(stepsConfig);
      const axisKeys = Object.keys(stepsConfigCopy);
      axisKeys.forEach((axis, index) => {
        const target = stepsConfigCopy[axis];
        if (target?.step2) {
          this.computeSelctedBrand(target.step2);
        }
      });
      return stepsConfigCopy;
    },
    setViews() {
      const { metadata } = this.metadata;
      const { views: originalViews } = metadata;
      const unsavedState = getWidgetUnsavedState(this);
      const views = cloneDeep(originalViews);
      if (unsavedState.customStateAxisConfig) {
        views.push({
          title: this.customViewTitle,
          id: 'CUSTOM',
          stepsConfig: unsavedState.customStateAxisConfig
        });
      }
      return views;
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.lastUpdatedDate = moment(this.metadata.calendar.max_date).format(
          'LL'
        );
        this.getFilters();
      } catch (e) {
        console.error('e', e);
      }
    },
    transformAndFetch() {
      try {
        this.transformMetadata();
        this.views = this.setViews();
        this.setUpdatedBrands();
        this.filterConfig = this.setRadioAndExpressionConfig(this.filterConfig);
        this.getMidPointAndChartData();
      } catch (e) {
        console.error('e', e);
      }
    },
    setUpdatedBrands() {
      const unsavedState = getWidgetUnsavedState(this);
      const selectedView = this.getSelectedView(unsavedState.selectedViewsId);
      this.selectedView = selectedView.title;
      this.formuleRuleBuilderState(selectedView, true);
      setUnsavedState(this, 'selectedViewsId', selectedView.id || 0);
    },
    getSelectedView(id) {
      const element = this.views.find((item) => item.id === id);
      if (element) {
        return element;
      } else {
        return this.views[0];
      }
    },
    async getMidPointAndChartData() {
      try {
        this.chartLoad = true;
        this.chartError = false;
        this.chartNoData = false;
        await this.getBubbleChartMidPoints();
        this.getChartData();
      } catch (e) {
        this.chartError = true;
        this.chartLoad = false;
        this.chartNoData = false;
        console.error('e', e);
      }
    },
    setGridOptions() {
      for (let i = 0; i < this.tabularData.length; i++) {
        this.tabularData[i].gridOptions = cloneDeep(this.gridOptions);
      }
    },
    addToPointsStore(x, y, addedBy) {
      const _x = parseFloat(x).toFixed(2);
      const _y = parseFloat(y).toFixed(2);
      if (!this.selectedPointsStore.hasOwnProperty(x)) {
        this.selectedPointsStore[_x] = {};
      }
      if (!this.selectedPointsStore[_x][_y]) {
        this.selectedPointsStore[_x][_y] = [addedBy];
      } else {
        this.selectedPointsStore[_x][_y].push(addedBy);
      }
    },
    handlePointsSelect(selectedPoints) {
      for (const pointData of selectedPoints) {
        const untranslatedX = pointData.x - this.logNegativeXOffset;
        const untranslatedY = pointData.y - this.logNegativeYOffset;
        this.addToPointsStore(untranslatedX, untranslatedY, 'lasso');
      }
      this.selectInTable();
      this.highlightSelectedBubbles = true;
      this.$nextTick(() => {
        this.removeLassoSelect();
      });
    },
    deselectAllInTables() {
      this.selectedPointsStore = {};
      for (const tabularData of this.tabularData) {
        tabularData.gridOptions?.api?.deselectAll();
      }
      this.setSelectedPointsInGraphData();
    },
    selectInTable() {
      for (const tabularData of this.tabularData) {
        tabularData.gridOptions?.api?.forEachNode((node) => {
          const xAccessKey = this.getTableKey(
            this.selectedXMetric,
            this.selectedxData.brand,
            this.ternaryXData.value
          );
          const yAccessKey = this.getTableKey(
            this.selectedYMetric,
            this.selectedyData.brand,
            this.ternaryYData.value
          );
          const xValue = node.data[xAccessKey];
          const yValue = node.data[yAccessKey];
          const existsInStore = this.pointExistsInStore(xValue, yValue);
          if (existsInStore) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        });
      }
    },
    pointExistsInStore(x, y, z) {
      const _x = parseFloat(x).toFixed(2);
      const _y = parseFloat(y).toFixed(2);
      return (
        this.selectedPointsStore.hasOwnProperty(_x) &&
        this.selectedPointsStore[_x].hasOwnProperty(_y) &&
        this.selectedPointsStore[_x][_y].length
      );
    },
    getTableKey(metric, brand, absOrChange) {
      const accessKey = this.getAccessKey(metric, brand);
      return this.createMetricAbsoluteChangePayload(
        accessKey,
        absOrChange,
        metric?.metadata?.unit
      );
    },
    addRowNodeToPointsStore(node) {
      const xAccessKey = this.getTableKey(
        this.selectedXMetric,
        this.selectedxData.brand,
        this.ternaryXData.value
      );
      const yAccessKey = this.getTableKey(
        this.selectedYMetric,
        this.selectedyData.brand,
        this.ternaryYData.value
      );
      const xValue = node.data[xAccessKey];
      const yValue = node.data[yAccessKey];
      this.addToPointsStore(xValue, yValue, 'lasso');
    },
    handleRowSelect(payload) {
      const { selections } = payload;
      this.selectedPointsStore = {};
      for (const tabularData of this.tabularData) {
        const selectedTabularData =
          tabularData?.gridOptions?.api?.getSelectedNodes() || [];
        selectedTabularData.forEach((node) => {
          this.addRowNodeToPointsStore(node);
        });
      }
      for (const node of selections) {
        this.addRowNodeToPointsStore(node);
      }
      this.setSelectedPointsInGraphData();
      this.enableDisableChartIcons();
    },
    setSelectedPointsInGraphData() {
      if (Object.keys(this.selectedPointsStore).length) {
        this.showForcedLabelsOnly = true;
        this.highlightSelectedBubbles = true;
      } else {
        this.highlightSelectedBubbles = false;
        this.showForcedLabelsOnly = false;
      }
      const newGraphData = this.graphData.map((item) => {
        const existsInStore = this.pointExistsInStore(
          item.x - this.logNegativeXOffset,
          item.y - this.logNegativeYOffset
        );
        item.selected = existsInStore;
        if (existsInStore) {
          item.forceLabel = true;
        } else {
          item.forceLabel = false;
        }
        return item;
      });
      this.graphData = newGraphData;
    },
    handleTabClick(item) {
      setUnsavedState(this, 'activeTabIndex', item);
    },
    transformMetadata() {
      const { metrics } = this.metadata;
      const options = [];
      for (const currentMetricGroup of this.metadata.customMetadata
        .metricGroups) {
        for (const currentMetric of currentMetricGroup.metrics) {
          metrics[currentMetric].hidden = true;
        }
      }
      const metricKeys = Object.keys(metrics);
      metricKeys.forEach((metricKey) => {
        options.unshift(metrics[metricKey]);
      });
      const obj = {
        label: 'Share of Voice',
        children: true,
        hidden: false
      };
      options.push(obj);
      options.sort((a, b) => a.priority - b.priority);
      this.xAxisRuleBuilderAxisStepConfig = this.setInitialRuleBuidlerState(
        this.xAxisRuleBuilderAxisStepConfig,
        options,
        false
      );
      this.yAxisRuleBuilderAxisStepConfig = this.setInitialRuleBuidlerState(
        this.yAxisRuleBuilderAxisStepConfig,
        options,
        false
      );
      this.zAxisRuleBuilderAxisStepConfig = this.setInitialRuleBuidlerState(
        this.zAxisRuleBuilderAxisStepConfig,
        options,
        false
      );
    },
    handleMidPointRuleApply(payload) {
      const { id, data } = cloneDeep(payload);
      if (id === 'x') {
        this.trueXAxisCenter = parseFloat(payload?.data?.[0]?.[0]?.text);
      } else {
        this.trueYAxisCenter = parseFloat(payload?.data?.[0]?.[0]?.text);
      }
      for (let i = 1; i <= 4; i++) {
        this.getTableData(i, 1);
      }
      this.$set(this.multiDropdownConfig[id][1], 'selected', data);
    },
    getSovMetricConfig() {
      const metric = this.getSelectedSovMetric();
      return cloneDeep(this.metadata.metrics[metric]);
      // the sov metrics are beings grouped, and for these the step config goes up to 3 in length, hence derive values from the rule builder conditionally
    },
    handleZAxisRuleApply(payload) {
      const payloadCopy = cloneDeep(payload);
      const { metricConfig, selectedBrandData, ternaryData } =
        this.parseRuleBuilderData(payload);
      this.setZAxis(metricConfig, selectedBrandData, ternaryData, payloadCopy);
      this.getMidPointAndChartData();
    },
    getSelectedSovMetric() {
      return getUnsavedState(this).settings.selectedPlacement;
    },
    parseRuleBuilderData(payload) {
      const data = cloneDeep(payload);
      let metricConfig = data?.[0]?.[0] || {};
      let selectedBrandData = {};
      let ternaryData = {};
      if (this.isSovGroup(metricConfig)) {
        metricConfig = this.getSovMetricConfig();
        selectedBrandData = data?.[1]?.[0] || {};
        ternaryData = data?.[2]?.[0] || {};
      } else {
        // ternaryData determines which key to pull data from 'RESULT' or 'PVP' setting confitionally since everything apart from sov has only two steps
        ternaryData = data?.[1]?.[0] || {};
      }
      return { metricConfig, selectedBrandData, ternaryData };
    },
    changeAxisSelectedBrand(axis) {
      // called from the parent to handle the condition in which my brands anchor is swapped out with a client brand
      // when changing the anchor brand of the page, substitute All occurences of MY_BRAND to yje anchor brand in all the axes
      const {
        step1: metricKey,
        step2: brandsKey,
        step3: ternaryDataKey
      } = this.getAxisStepKeys(axis);
      const metricConfig = this[metricKey];
      const selectedBrandData = this[brandsKey];
      const ternaryData = this[ternaryDataKey];
      const { anchoredBrand: latestAnchoredBrand } = getUnsavedSettings(this);
      // since computedAnchorBrand is computed once during render time, it will always contain the value avaiable computed pre render, when the anchored brands is the changed, in the same tick latestAnchoredBrand will be fetched from the store fresh, however this.computedAnchorBrand being computed pre render will have access to the previos value
      if (selectedBrandData?.brand === this.previousAnchorBrand.title) {
        setRuleBuilderBrand(selectedBrandData, latestAnchoredBrand);
      }
      if (Object.keys(metricConfig).length) {
        this.setAxisData(axis, metricConfig, selectedBrandData, ternaryData);
      }
    },
    changeShareOfVoiceFilterOnAnchor() {
      // when changing the anchor brand of the page, substitute All occurences of MY_BRAND to yje anchor brand in the filter
      const { filterRuleBuilderChipSelections } = getWidgetUnsavedState(this);
      if (filterRuleBuilderChipSelections.length) {
        const { anchoredBrand } = getUnsavedSettings(this);
        const selectedBrandData = filterRuleBuilderChipSelections[0][0];
        if (selectedBrandData?.brand === this.previousAnchorBrand.title) {
          this.filterEmit = null;
          setRuleBuilderBrand(selectedBrandData, anchoredBrand);
          this.handleFilterRuleApply(filterRuleBuilderChipSelections);
          this.$nextTick(() => {
            eventBus.$emit(this.externalFilterSelectionListener, {
              anchor: RULE_BUILDER_SOV_ANCHOR,
              values: filterRuleBuilderChipSelections
            });
            this.filterEmit = SOV_DRIVERS_FILTER_APPLY;
          });
        }
      }
    },
    setZAxis(metricConfig, selectedBrandData, ternaryData, chipConfig) {
      this.selectedZMetric = metricConfig;
      this.selectedzData = selectedBrandData;
      this.ternaryZData = ternaryData;
      this.bubbleRadiusSelections = chipConfig;
      this.selectedView = this.customViewTitle;
      this.setCustomView();
    },
    handleAxisRuleApply(payload) {
      const { id, data } = cloneDeep(payload);
      const { metricConfig, selectedBrandData, ternaryData } =
        this.parseRuleBuilderData(data);
      this.setAxisData(id, metricConfig, selectedBrandData, ternaryData);
      this.getMidPointAndChartData();
    },
    setAxisData(id, metricConfig, selectedBrandData, ternaryData) {
      this.setAxisStepDataPoints(
        id,
        metricConfig,
        selectedBrandData,
        ternaryData
      );
      this.selectedView = this.customViewTitle;
      this.setCustomView();
    },
    setCustomView() {
      setUnsavedState(this, 'selectedViewsId', 'CUSTOM');
      const currentAxisState = this.getAxisStates();
      this.appendCustomStateToViewsList(currentAxisState);
      setUnsavedState(this, 'customStateAxisConfig', currentAxisState);
    },
    appendCustomStateToViewsList(currentAxisState) {
      const newViews = [...this.views];
      const payload = {
        title: this.customViewTitle,
        id: 'CUSTOM',
        stepsConfig: currentAxisState
      };
      const customViewIndex = this.getCustomViewsIndex();
      if (customViewIndex !== null) {
        newViews[customViewIndex] = cloneDeep(payload);
      } else {
        newViews.push(payload);
      }
      this.views = newViews;
    },
    getCustomViewsIndex() {
      const index = this.views.findIndex((item) => item.id === 'CUSTOM');
      if (index >= 0) {
        return index;
      } else {
        return null;
      }
    },
    getAxisStates() {
      const xAxisSteps = this.getAxisInSteps('x');
      const yAxisSteps = this.getAxisInSteps('y');
      let zAxisSteps = null;
      if (this.bubbleRadiusSelections.length) {
        zAxisSteps = this.getAxisInSteps('z');
      }
      return {
        x: xAxisSteps,
        y: yAxisSteps,
        z: zAxisSteps
      };
    },
    getAxisInSteps(axis) {
      const axisKeys = this.getAxisStepKeys(axis);
      const step1 = cloneDeep(this[axisKeys.step1]);
      const step2 = cloneDeep(this[axisKeys.step2]);
      const step3 = cloneDeep(this[axisKeys.step3]);
      const sanitizedStep1 = this.sanitiseSteps('step1', step1);
      const sanitizedStep2 = this.sanitiseSteps('step2', step2);
      const sanitizedStep3 = this.sanitiseSteps('step3', step3);
      return {
        step1: sanitizedStep1,
        step2: sanitizedStep2,
        step3: sanitizedStep3
      };
    },
    sanitiseSteps(stepName, step) {
      const payload = {};
      const unsavedSettings = getUnsavedSettings(this);
      if (stepName === 'step1') {
        if (unsavedSettings.selectedPlacement === step.alias) {
          payload.metric = this.sovMetricPlaceholder;
        } else {
          payload.metric = step.alias;
        }
      } else if (stepName === 'step2') {
        if (step.brand) {
          payload.brand = step.brand;
        }
      } else {
        payload.value = step.value;
      }
      return payload;
    },
    getAxisStepKeys(axis) {
      return {
        step1: 'selected' + axis.toUpperCase() + 'Metric',
        step2: 'selected' + axis + 'Data',
        step3: 'ternary' + axis.toUpperCase() + 'Data'
      };
    },
    setAxisStepData(axis, step1 = {}, step2 = {}, step3 = {}) {
      let metricStepCopy = cloneDeep(step1);
      const {
        step1: metricKey,
        step2: brandsKey,
        step3: ternaryData
      } = this.getAxisStepKeys(axis);
      const midPointRuleBuidlerKey = axis + 'AxisRuleBuidlerMidPointConfig';
      if (metricStepCopy.metric === this.sovMetricPlaceholder) {
        metricStepCopy = this.getSovMetricConfig();
      } else {
        metricStepCopy = cloneDeep(
          this.metadata.metrics[metricStepCopy.metric]
        );
      }
      if (step2.brand) {
        step2.infix = {
          text: step2.brand
        };
      }
      if (step3?.value === 'CHANGE') {
        step3.infix = {
          text: '(Change)'
        };
      }
      if (this[midPointRuleBuidlerKey]?.root) {
        this[midPointRuleBuidlerKey].root.stepTitle = metricStepCopy.label;
      }
      const multiDropdownConfig = this.createDropdownConfigFromSteps(
        step1,
        step2,
        step3
      );
      if (axis === 'z') {
        this.bubbleRadiusSelections = multiDropdownConfig;
      } else {
        if (this.multiDropdownConfig?.[axis]) {
          this.$set(
            this.multiDropdownConfig[axis][0],
            'selected',
            multiDropdownConfig
          );
        }
      }
      this[metricKey] = metricStepCopy;
      this[brandsKey] = step2;
      this[ternaryData] = step3;
    },
    setAxisStepDataPoints(axis, metricConfig, selectedBrandData, ternaryData) {
      const step1 = { metric: metricConfig.alias };
      this.setAxisStepData(axis, step1, selectedBrandData, ternaryData);
    },
    isSovGroup(metric) {
      if (metric.label === 'Share of Voice') {
        return true;
      }
    },
    hasBrandsData(metric) {
      return this.isSovGroup(metric) || this.sovDataGroups[metric.dataGroup];
    },
    setInitialRuleBuidlerState(
      config,
      metrics,
      hasExpressionInput,
      appendChangeText = false
    ) {
      const configCopy = cloneDeep(config);
      const baseExpressionConfig = cloneDeep(this.expressionBaseConfig);
      const baseRadioSelectConfig = cloneDeep(this.radioBaseConfig);
      const baseExpressionConfigNumberic = cloneDeep(this.expressionBaseConfig);
      delete baseExpressionConfigNumberic.props.metrics.config.addtionalMetadata
        .unit;
      const { selectedValue, segmentedData } = this.getFormattedBrandsList();
      if (!baseRadioSelectConfig.props) {
        baseRadioSelectConfig.props = {};
      }
      baseRadioSelectConfig.props.selectedValue = selectedValue;
      baseRadioSelectConfig.props.segmentedData = segmentedData;

      baseExpressionConfig.nextStep = null;
      baseExpressionConfig.props.hasQueryBuilder = hasExpressionInput;
      baseExpressionConfigNumberic.props.hasQueryBuilder = hasExpressionInput;
      baseExpressionConfigNumberic.props.appendChangeText = appendChangeText;
      baseRadioSelectConfig.nextStep = baseExpressionConfig;
      configCopy.root = cloneDeep(this.metricsBaseConfig);
      metrics.forEach((item) => {
        if (this.hasBrandsData(item)) {
          item.nextStep = baseRadioSelectConfig;
        } else {
          item.nextStep = baseExpressionConfigNumberic;
        }
      });
      configCopy.root.props = {
        options: metrics
      };
      configCopy.load = false;
      return configCopy;
    },
    setRadioAndExpressionConfig(config) {
      const baseExpressionConfig = cloneDeep(this.expressionBaseConfig);
      const baseRadioSelectConfig = cloneDeep(this.radioBaseConfig);
      baseRadioSelectConfig.nextStep = baseExpressionConfig;
      config.root = baseRadioSelectConfig;
      const { selectedValue, segmentedData } = this.getFormattedBrandsList();
      if (!config.root.props) {
        config.root.props = {};
      }
      config.root.props.selectedValue = selectedValue;
      config.root.props.segmentedData = segmentedData;
      config.load = false;
      return { ...config };
    },
    setMidPointRuleBuilderInitialState(config) {
      config.root = cloneDeep(this.textInputBaseConfig);
      return { ...config };
    },
    getRuleBuilderInitialSelect(
      ruleBuilderConfig,
      selectedOptionIndex,
      step = 0,
      optionsKey = 'options'
    ) {
      const infix = {
        text: ruleBuilderConfig.root.props[optionsKey][selectedOptionIndex]
          .label
      };
      return [
        [
          {
            ...ruleBuilderConfig.root.props[optionsKey][selectedOptionIndex],
            infix
          }
        ]
      ];
    },
    getBubbleChartMidPoints() {
      return new Promise(async (resolve, reject) => {
        try {
          this.totalEntityCount = 0;
          this.resetAxialWhereClauseCombination();
          this.multiDropdownConfig.x[1].selected = [[{ load: true }]];
          this.multiDropdownConfig.y[1].selected = [[{ load: true }]];
          this.midPointLoad = true;
          this.midPointError = false;
          this.midpointNoData = false;
          this.graphData = [];
          const xMetric = this.selectedXMetric;
          const yMetric = this.selectedYMetric;
          const zMetric = this.selectedZMetric;
          const { request } = this.metadata.metadata.graphDataAPI;
          const titleOptionSelected = selectedTitleOptions(this);
          const entityType = titleOptionSelected.value;
          const requestCopy = this.createMidpointApiRequest(
            request,
            entityType,
            xMetric,
            yMetric,
            zMetric
          );

          this.minRadius = Infinity;
          this.maxRadius = -Infinity;
          const data = await this.dataService.getData(requestCopy);
          this.midPointLoad = false;
          this.midPointError = false;
          this.midpointNoData = false;
          this.getXYMidpoint(data, xMetric, yMetric, zMetric);
          resolve();
        } catch (e) {
          this.midPointLoad = false;
          this.midPointError = true;
          this.midpointNoData = false;
          this.multiDropdownConfig.x[1].selected = [
            [{ load: false, text: 'NA', infix: { text: 'NA' } }]
          ];
          this.multiDropdownConfig.y[1].selected = [
            [{ load: false, text: 'NA', infix: { text: 'NA' } }]
          ];
          reject(e);
        }
      });
    },
    getMetricType(ternaryData) {
      if (ternaryData?.value === 'CHANGE') {
        return 'PVP';
      }
      return 'RESULT';
    },
    getAccessKey(metric, brand) {
      let accessKey = metric.alias;
      if (this.sovDataGroups[metric.dataGroup]) {
        const order = this.getSovBrandOrder(brand) + 1;
        accessKey =
          this.getBrandDataPrefix(order, brand) + '_' + metric.keyName;
      }
      return accessKey;
    },
    getXYMidpoint(apiData, xMetric, yMetric, zMetric) {
      const xAccessKey = this.getAccessKey(xMetric, this.selectedxData.brand);
      const yAccessKey = this.getAccessKey(yMetric, this.selectedyData.brand);
      const zAccessKey = this.getAccessKey(zMetric, this.selectedzData.brand);
      const xMetricType = this.getMetricType(this.ternaryXData);
      const yMetricType = this.getMetricType(this.ternaryYData);
      const zMetricType = this.getMetricType(this.ternaryZData);
      const { data } = apiData.entityData[0];
      if (!data.length) {
        this.midpointNoData = true;
      }
      for (const item of data) {
        if (item.name === 'MEDIAN_' + xAccessKey) {
          let midpoint = this.getResultOrPvpValue(item, xMetric, xMetricType);
          if (xMetricType !== 'RESULT') {
            midpoint = 0;
          }
          const text = midpoint === null ? 'NA' : midpoint.toFixed(1);
          const infix = {
            text
          };
          this.multiDropdownConfig.x[1].selected = [
            [{ load: false, text: midpoint, infix }]
          ];
          this.trueXAxisCenter = midpoint;
        }
        if (item.name === 'MAX_' + xAccessKey) {
          const max = this.getResultOrPvpValue(item, xMetric, xMetricType);
          this.maxX = max;
        }
        if (item.name === 'MIN_' + xAccessKey) {
          const min = this.getResultOrPvpValue(item, xMetric, xMetricType);
          this.minX = min;
        }
        if (item.name === 'MEDIAN_' + yAccessKey) {
          let midpoint = this.getResultOrPvpValue(item, yMetric, yMetricType);
          if (yMetricType !== 'RESULT') {
            midpoint = 0;
          }
          const text = midpoint === null ? 'NA' : midpoint.toFixed(1);
          const infix = {
            text
          };
          this.multiDropdownConfig.y[1].selected = [
            [{ load: false, text: midpoint, infix }]
          ];
          this.trueYAxisCenter = midpoint;
        }
        if (item.name === 'MAX_' + yAccessKey) {
          const max = this.getResultOrPvpValue(item, yMetric, yMetricType);
          this.maxY = max;
        }
        if (item.name === 'MIN_' + yAccessKey) {
          const min = this.getResultOrPvpValue(item, yMetric, yMetricType);
          this.minY = min;
        }
        if (item.name === 'MEDIAN_' + zAccessKey) {
          this.medianRadius = this.getResultOrPvpValue(
            item,
            zMetric,
            zMetricType
          );
        }
        if (item.name === 'MAX_' + zAccessKey) {
          const value = this.getResultOrPvpValue(item, zMetric, zMetricType);
          if (zMetric.metadata.isInverted) {
            this.minRadius = value;
          } else {
            this.maxRadius = value;
          }
        }
        if (item.name === 'MIN_' + zAccessKey) {
          const value = this.getResultOrPvpValue(item, zMetric, zMetricType);
          if (zMetric.metadata.isInverted) {
            this.maxRadius = value;
          } else {
            this.minRadius = value;
          }
        }
      }
      if (this.minX > parseFloat(this.trueXAxisCenter)) {
        this.minX = parseFloat(this.trueXAxisCenter);
      }
      if (this.minY > parseFloat(this.trueYAxisCenter)) {
        this.minY = parseFloat(this.trueYAxisCenter);
      }
      // this.setMidpointToViewportMid();
      this.setLogOffset();
      // setting the axis to be in center of viewport at the start
    },
    setLogOffset() {
      this.logNegativeXOffset = this.getTransalationOffset(this.minX);
      this.logNegativeYOffset = this.getTransalationOffset(this.minY);

      this.xScaleDomain = {
        min: this.minX + this.logNegativeXOffset,
        max: this.maxX + this.logNegativeXOffset
      };
      this.yScaleDomain = {
        min: this.minY + this.logNegativeYOffset,
        max: this.maxY + this.logNegativeYOffset
      };
      // if the same axis domains are the same then zoom is buggy
      if (this.xScaleDomain.min === this.xScaleDomain.max) {
        this.xScaleDomain.min /= 10;
        this.xScaleDomain.max *= 10;
      }
      if (this.yScaleDomain.min === this.yScaleDomain.max) {
        this.yScaleDomain.min /= 10;
        this.yScaleDomain.max *= 10;
      }
      this.xAxisRuleBuidlerMidPointConfig.root.props.min = Math.max(
        this.minX,
        0
      );
      this.yAxisRuleBuidlerMidPointConfig.root.props.min = Math.max(
        this.minY,
        0
      );
    },
    setMidpointToViewportMid() {
      const linearXOffset = this.getMaxMinMaxMidDistance(
        this.minX,
        this.maxX,
        this.trueXAxisCenter
      );
      const linearYOffset = this.getMaxMinMaxMidDistance(
        this.minY,
        this.maxY,
        this.trueYAxisCenter
      );
      this.minX = this.trueXAxisCenter - linearXOffset;
      this.maxX = this.trueXAxisCenter + linearXOffset;
      this.minY = this.trueYAxisCenter - linearYOffset;
      this.maxY = this.trueYAxisCenter + linearYOffset;
    },
    getMaxMinMaxMidDistance(min, max, mid) {
      const absMin = Math.abs(min);
      const absMax = Math.abs(max);
      const absMid = Math.abs(mid);
      return Math.max(absMid - absMin, absMax - absMid);
    },
    getResultOrPvpValue(entityUnit, metric, metricType) {
      if (metricType === 'RESULT') {
        return entityUnit.RESULT[entityUnit.name];
      } else {
        if (metric.metadata.unit === 'PERCENTAGE') {
          return entityUnit.PVP['PVP_DIFF_' + entityUnit.name];
        } else {
          return entityUnit.PVP['PVP_' + entityUnit.name];
        }
      }
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    async getFilters() {
      try {
        this.enableOpenFilter = false;
        this.resetFilters();
        const { cubeName, pageName, where } =
          this.metadata.metadata.filterAPI.request;
        await this.fetchFilters(cubeName, 'FETCH_FILTERS_V2', pageName, where);
        this.primaryFilterData.push({
          dimensionColumn: RULE_BUILDER_SOV_ANCHOR,
          dimensionLabel: 'Share of Voice',
          dimensionName: RULE_BUILDER_SOV_ANCHOR,
          dimensionType: 'EXPRESSION'
        });
        this.$nextTick(() => {
          this.enableOpenFilter = true;
        });
      } catch (e) {
        console.error('e', e);
      }
    },
    getExtraMetricsFromWhereClause(selectedDataGroup) {
      const extraMetrics = {};
      const widget5WhereClause = cloneDeep(this.getWidget5WhereClause);
      const whereMetrics = (
        widget5WhereClause.dimensionNameValueList || []
      ).map((item) => item.dimensionName);
      const unsavedState = getWidgetUnsavedState(this);
      const unsavedSettings = getUnsavedSettings(this);
      if ((unsavedState?.filterRuleBuilderChipSelections || []).length) {
        whereMetrics.push(unsavedSettings.selectedPlacement);
      }
      whereMetrics.forEach((item) => {
        extraMetrics[item] = 1;
      });
      selectedDataGroup.forEach((item) => {
        if (extraMetrics[item]) {
          delete extraMetrics.item;
        }
      });
      return Object.keys(extraMetrics);
    },
    createMidpointApiRequest(request, entityType, xMetric, yMetric, zMetric) {
      const requestCopy = cloneDeep(request);
      requestCopy.metricsList = [];
      requestCopy.entityType = entityType;
      const widget5WhereClause = cloneDeep(this.getWidget5WhereClause);
      const globalWhereClause = this.globalWhereClause();
      requestDateReplacement(requestCopy.where, globalWhereClause);
      requestTimeReplacement(requestCopy.where, globalWhereClause);
      requestCopy.bundleDataGroupsRequest = {};
      if (this.sovDataGroups[xMetric.dataGroup]) {
        this.setSovBrandOrder(this.selectedxData.brand);
      }
      if (this.sovDataGroups[yMetric.dataGroup]) {
        this.setSovBrandOrder(this.selectedyData.brand);
      }
      if (this.sovDataGroups[zMetric.dataGroup]) {
        this.setSovBrandOrder(this.selectedzData.brand);
      }
      const sovFilterCombinations =
        this.getSelectedSovFiltersInWhereClauseCombination();
      const brandInFilter = sovFilterCombinations?.[0]?.dimensionValue;
      if (brandInFilter) {
        this.setSovBrandOrder(brandInFilter);
      }
      const unsavedSettings = getUnsavedSettings(this);
      this.setDataGroup(xMetric, requestCopy, entityType);
      this.setDataGroup(yMetric, requestCopy, entityType);
      if (this.bubbleRadiusSelections.length) {
        this.setDataGroup(zMetric, requestCopy, entityType);
      }
      const extraMetrics = this.getExtraMetricsFromWhereClause([
        xMetric.dataGroup,
        yMetric.dataGroup,
        zMetric.dataGroup
      ]);
      extraMetrics.forEach((metric) => {
        const currentMetric = this.metadata.metrics[metric];
        this.setDataGroup(currentMetric, requestCopy, entityType);
      });
      if (
        this.intradayCrawlingAvailable &&
        this.metadata.metrics[this.isIntradayKeywordKey]
      ) {
        this.setDataGroup(
          this.metadata.metrics[this.isIntradayKeywordKey],
          requestCopy,
          entityType
        );
      }
      this.addSovBrandsInOrder(
        requestCopy,
        this.metadata.metrics[unsavedSettings.selectedPlacement].dataGroup
      );
      // custom code for todays release, removing in fast follow
      this.updateSovDimenionList(requestCopy);
      this.appendFilters(requestCopy, widget5WhereClause);
      return requestCopy;
    },
    updateSovDimenionList(requestCopy) {
      const { anchoredBrand } = getUnsavedSettings(this);
      if (appendAnchorBrandCondition(anchoredBrand)) {
        const dataGroups = Object.keys(requestCopy.bundleDataGroupsRequest);
        const currentSovDataGroup = dataGroups.find(
          (item) => this.sovDataGroups[item]
        );
        const sovRequest =
          requestCopy?.bundleDataGroupsRequest?.[currentSovDataGroup];
        if (sovRequest) {
          const brandByClientFlagIndex = sovRequest.dimensionsList.findIndex(
            (item) => item === BRAND_BY_CLIENT_FLAG
          );
          if (brandByClientFlagIndex >= 0) {
            sovRequest.dimensionsList[brandByClientFlagIndex] = BRAND;
          }
        }
      }
    },
    createQuadrantRequest(request, entityType, quadrant) {
      const xMetric = this.selectedXMetric;
      const yMetric = this.selectedYMetric;
      const zMetric = this.selectedZMetric;
      const requestCopy = this.createMidpointApiRequest(
        request,
        entityType,
        xMetric,
        yMetric,
        zMetric
      );
      requestCopy.operations.orderByList = [
        ...(this.bubbleRadiusSelections.length
          ? this.getOrderByList(
              zMetric,
              quadrant,
              'z',
              this.selectedzData.brand
            )
          : []),
        ...this.getOrderByList(
          yMetric,
          quadrant,
          'y',
          this.selectedyData.brand
        ),
        ...this.getOrderByList(xMetric, quadrant, 'x', this.selectedxData.brand)
      ];
      this.setMidpoints(
        requestCopy,
        quadrant,
        'x',
        xMetric,
        this.trueXAxisCenter,
        this.ternaryXData,
        this.selectedxData
      );
      this.setMidpoints(
        requestCopy,
        quadrant,
        'y',
        yMetric,
        this.trueYAxisCenter,
        this.ternaryYData,
        this.selectedyData
      );
      return requestCopy;
    },
    getCurrentSovMetric(metric) {
      if (this.sovDataGroups[metric.dataGroup]) {
        const unsavedSettings = getUnsavedSettings(this);
        return this.metadata.metrics[unsavedSettings.selectedPlacement];
      }
      return metric;
    },
    setUpdatedSovMetrics() {
      // called from parent when the global sov placement is changed
      this.selectedXMetric = cloneDeep(
        this.getCurrentSovMetric(this.selectedXMetric)
      );
      this.selectedYMetric = cloneDeep(
        this.getCurrentSovMetric(this.selectedYMetric)
      );
      this.selectedZMetric = cloneDeep(
        this.getCurrentSovMetric(this.selectedZMetric)
      );
    },
    async getChartData() {
      try {
        const titleOptionSelected = selectedTitleOptions(this);
        const entityType = titleOptionSelected.value;
        this.chartLoad = true;
        const xMetric = this.selectedXMetric;
        const yMetric = this.selectedYMetric;
        const zMetric = this.selectedZMetric;
        this.totalEntityCount = 0;
        this.graphData = [];
        const dictionary = this.createTableColumnDefs(
          xMetric,
          yMetric,
          zMetric
        );
        this.columnDefs = getColumnDefinition(dictionary, 1);
        for (let i = 1; i <= 4; i++) {
          this.$set(this.tabularData, i - 1, {
            ...this.tabularData[i - 1],
            load: true,
            error: false
          });
          const { request } = cloneDeep(this.metadata.metadata.dataAPI);
          const requestCopy = this.createQuadrantRequest(
            request,
            entityType,
            i
          );
          this.dataService
            .getData(requestCopy)
            .then((response) => {
              this.totalEntityCount +=
                response?.entityData?.[0]?.totalEntityCount || 0;
              this.$set(this.tabularData[i - 1], 'load', false);
              this.$set(this.tabularData[i - 1], 'error', false);
              this.$set(this.tabularData[i - 1], 'totalEntityCount', 0);
              this.chartLoad = false;
              const quadrantGraphData = this.extractQuadrantData(
                response,
                xMetric,
                yMetric,
                zMetric,
                i
              );
              const tableData = cloneDeep(
                this.extractTabularData(response, xMetric, yMetric, zMetric)
              );
              this.tabularData[i - 1].totalEntityCount =
                response.entityData?.[0]?.totalEntityCount || 0;
              this.$set(this.tabularData, i - 1, {
                ...this.tabularData[i - 1],
                rows: tableData
              });
              if (this.bubbleRadiusSelections.length) {
                if (zMetric.alias === this.keywordRankKey) {
                  this.getExponentialDistribution(quadrantGraphData);
                } else {
                  this.normalizeRadii(quadrantGraphData);
                }
              } else {
                this.noZmetricRadius(quadrantGraphData);
              }
              const translatedGraphData =
                this.translatePointsToPositiveAxis(quadrantGraphData);
              this.graphData.push(...translatedGraphData);
            })
            .catch((e) => {
              this.$set(this.tabularData[i - 1], 'load', false);
              this.$set(this.tabularData[i - 1], 'error', true);
              console.error('e', e);
            });
        }
      } catch (e) {
        this.chartLoad = false;
        console.error('e', e);
      }
    },
    translatePointsToPositiveAxis(graphData) {
      return graphData.map((item) => {
        item.x = item.x + this.logNegativeXOffset;
        item.y = item.y + this.logNegativeYOffset;
        return item;
      });
    },
    getTransalationOffset(minValue) {
      if (minValue >= 1) {
        return 0;
      } else {
        const absMin = Math.abs(minValue);
        return Math.floor(absMin) + 1;
      }
    },
    getTableRequest(quadrant, page) {
      const titleOptionSelected = selectedTitleOptions(this);
      const entityType = titleOptionSelected.value;
      const { request } = this.metadata.metadata.dataAPI;
      const requestCopy = this.createQuadrantRequest(
        request,
        entityType,
        quadrant
      );
      requestCopy.operations.page = page;
      requestCopy.widget = this.widgetName;
      return requestCopy;
    },
    setTableState(newTableState, index) {
      this.tabularData = this.tabularData.map((item, tableIndex) => {
        if (tableIndex === index) {
          return newTableState;
        }
        return item;
      });
    },
    getTableData(quadrant, page) {
      try {
        const tabularIndex = quadrant - 1;
        this.setTableState({ load: true, error: false }, tabularIndex);
        const requestCopy = this.getTableRequest(quadrant, page);
        this.dataService
          .getData(requestCopy)
          .then((response) => {
            const formattedTableData = cloneDeep(
              this.extractTabularData(
                response,
                this.selectedXMetric,
                this.selectedYMetric,
                this.selectedZMetric
              )
            );
            const newTabularState = {
              ...this.tabularData[tabularIndex],
              load: false,
              error: false,
              totalEntityCount:
                response?.entityData?.[0]?.totalEntityCount || 0,
              config: {
                body: {
                  APIConfig: {
                    page
                  }
                }
              },
              rows: formattedTableData
            };
            this.setTableState(newTabularState, tabularIndex);
          })
          .catch((e) => {
            this.setTableState({ load: false, error: true }, tabularIndex);
          });
      } catch (e) {
        this.chartLoad = false;
        console.error('e', e);
      }
    },
    getResultPvpFormatted(entityUnit, metricsMetadata) {
      const result = this.extractResultKeyValue(entityUnit);
      const pvp = this.extractPvpKeyValue(entityUnit, metricsMetadata);
      return { result, pvp };
    },
    getXYZmetricsData(
      entityUnit,
      metricsMetadata,
      xAccessKey,
      yAccessKey,
      zAccessKey,
      xMetricType,
      yMetricType,
      zMetricType
    ) {
      const { result, pvp } = this.getResultPvpFormatted(
        entityUnit,
        metricsMetadata
      );
      const x = xMetricType === 'RESULT' ? result[xAccessKey] : pvp[xAccessKey];
      const y = yMetricType === 'RESULT' ? result[yAccessKey] : pvp[yAccessKey];
      const z = zMetricType === 'RESULT' ? result[zAccessKey] : pvp[zAccessKey];
      const intradayKeyword = result[this.isIntradayKeywordKey];
      return {
        x,
        y,
        z,
        intradayKeyword
      };
    },
    extractQuadrantData(quadrantData, xMetric, yMetric, zMetric) {
      const xAccessKey = this.getAccessKey(xMetric, this.selectedxData.brand);
      const yAccessKey = this.getAccessKey(yMetric, this.selectedyData.brand);
      const zAccessKey = this.getAccessKey(zMetric, this.selectedzData.brand);
      const xMetricType = this.getMetricType(this.ternaryXData);
      const yMetricType = this.getMetricType(this.ternaryYData);
      const zMetricType = this.getMetricType(this.ternaryZData);
      const { title: xLabel } = this.getTitleAndTooltip(
        xMetric,
        this.selectedxData
      );
      const { title: yLabel } = this.getTitleAndTooltip(
        yMetric,
        this.selectedyData
      );
      const { title: zLabel } = this.getTitleAndTooltip(
        zMetric,
        this.selectedzData
      );
      const formattedData = [];
      if (quadrantData && quadrantData.entityData) {
        const { entityData } = quadrantData;
        for (const item of entityData) {
          const { x, y, z, intradayKeyword } = this.getXYZmetricsData(
            item,
            this.metadata.metrics,
            xAccessKey,
            yAccessKey,
            zAccessKey,
            xMetricType,
            yMetricType,
            zMetricType
          );
          const originalValues = { x, y, z };
          const payloadFormatted = {
            metrics: { x: xMetric, y: yMetric, z: zMetric },
            originalValues
          };
          payloadFormatted.x = x;
          payloadFormatted.y = y;
          payloadFormatted.z = z;
          payloadFormatted.xLabel = xLabel;
          payloadFormatted.yLabel = yLabel;
          payloadFormatted.zLabel = zLabel;
          payloadFormatted.text = item.entityValue;
          if (
            this.intradayCrawlingAvailable &&
            ((this.multiCrawl &&
              this.titleOptionSelected.value === 'search_term') ||
              intradayKeyword)
          ) {
            payloadFormatted.icon = {
              path: '/chartIcons/thunder.svg',
              width: 12,
              height: 12
            };
          }
          formattedData.push(payloadFormatted);
        }
      }
      return formattedData;
    },
    extractTabularData(response, xMetric, yMetric, zMetric) {
      const xAccessKey = this.getAccessKey(xMetric, this.selectedxData.brand);
      const yAccessKey = this.getAccessKey(yMetric, this.selectedyData.brand);
      const zAccessKey = this.getAccessKey(zMetric, this.selectedzData.brand);

      const xMetricType = this.getMetricType(this.ternaryXData);
      const yMetricType = this.getMetricType(this.ternaryYData);
      const zMetricType = this.getMetricType(this.ternaryZData);
      const xKey = this.createMetricAbsoluteChangePayload(
        xAccessKey,
        this.ternaryXData.value,
        xMetric?.metadata?.unit
      );
      const yKey = this.createMetricAbsoluteChangePayload(
        yAccessKey,
        this.ternaryYData.value,
        yMetric?.metadata?.unit
      );
      const zKey = this.createMetricAbsoluteChangePayload(
        zAccessKey,
        this.ternaryZData.value,
        zMetric?.metadata?.unit
      );
      const { entityData } = response;
      const formattedTableData = [];
      for (const entityUnit of entityData) {
        const { x, y, z } = this.getXYZmetricsData(
          entityUnit,
          this.metadata.metrics,
          xAccessKey,
          yAccessKey,
          zAccessKey,
          xMetricType,
          yMetricType,
          zMetricType
        );
        const { result, pvp } = this.getResultPvpFormatted(
          entityUnit,
          this.metadata.metrics
        );
        const payload = { ...result, ...pvp };
        payload[xKey] = x;
        payload[yKey] = y;
        payload[zKey] = z;
        payload.metrics = { x: xMetric, y: yMetric, z: zMetric };
        const intradayKeyword = result[this.isIntradayKeywordKey];
        if (
          this.intradayCrawlingAvailable &&
          ((this.multiCrawl &&
            this.titleOptionSelected.value === 'search_term') ||
            intradayKeyword)
        ) {
          payload[this.isIntradayKeywordKey] = 1;
        }
        formattedTableData.push(payload);
      }
      return formattedTableData;
    },
    createTableColumnDefs(xMetric, yMetric, zMetric) {
      const xAccessKey = this.getAccessKey(xMetric, this.selectedxData.brand);
      const yAccessKey = this.getAccessKey(yMetric, this.selectedyData.brand);
      const zAccessKey = this.getAccessKey(zMetric, this.selectedzData.brand);

      const xKey = this.createMetricAbsoluteChangePayload(
        xAccessKey,
        this.ternaryXData.value,
        xMetric?.metadata?.unit
      );
      const yKey = this.createMetricAbsoluteChangePayload(
        yAccessKey,
        this.ternaryYData.value,
        yMetric?.metadata?.unit
      );
      const zKey = this.createMetricAbsoluteChangePayload(
        zAccessKey,
        this.ternaryZData.value,
        zMetric?.metadata?.unit
      );
      const { name, value } = selectedTitleOptions(this);
      const dictionary = {};
      dictionary[value] = {
        title: name,
        toolTipText: name,
        keyOrder: 0,
        pinned: 'left',
        icon: CONSTANTS.crawlTypes.multiCrawl.icon,
        showIcon: (params) =>
          this.intradayCrawlingAvailable &&
          params.data[this.isIntradayKeywordKey],
        tableConfig: {
          cellRendererFramework: 'linkDisplay',
          cellRendererParams: {
            hoverRequired: true,
            hoverText: 'Snapshots of Page 1',
            hoverClickFunction: this.handleKeywordSelection
          }
        }
      };
      dictionary[xKey] = this.setMetricWiseTableColumns(
        xMetric,
        1,
        this.selectedxData,
        this.ternaryXData.value
      );
      dictionary[yKey] = this.setMetricWiseTableColumns(
        yMetric,
        2,
        this.selectedyData,
        this.ternaryYData.value
      );
      if (this.bubbleRadiusSelections.length) {
        dictionary[zKey] = this.setMetricWiseTableColumns(
          zMetric,
          3,
          this.selectedzData,
          this.ternaryZData.value
        );
      }
      return dictionary;
    },
    getTitleAndTooltip(metric, selectedData = {}) {
      let title = metric.label;
      let tooltip = metric.tooltip;
      if (selectedData.brand) {
        title += ` - ${selectedData.brand}`;
        tooltip += ` - ${selectedData.brand}`;
      }
      return { title, tooltip };
    },
    setMetricWiseTableColumns(
      metric,
      keyOrder,
      selectedData = {},
      absoluteOrChange
    ) {
      const { title, tooltip } = this.getTitleAndTooltip(metric, selectedData);
      const titleSuffix = absoluteOrChange === 'CHANGE' ? ' (Change)' : '';
      const titleAbsOrChange = title + titleSuffix;
      const unit =
        absoluteOrChange === 'CHANGE' ? 'PERCENTAGE' : metric.metadata.unit;
      return {
        title: titleAbsOrChange,
        toolTipText: tooltip,
        keyOrder,
        ...metric.metadata,
        unit
      };
    },
    noZmetricRadius(formattedData) {
      formattedData.forEach((d) => {
        d.r = this.defaultRadius;
      });
      this.normalizedRadiusMaxValue = this.normalizedMaxRadius;
      this.normalizedRadiusMinValue = this.normalizedMinRadius;
      this.minRadius = this.normalizedMinRadius;
      this.maxRadius = this.normalizedMaxRadius;
      return formattedData;
    },
    getExponentialDistribution(formattedData) {
      formattedData.forEach((d) => {
        d.r =
          this.normalizedMinRadius +
          (this.normalizedMaxRadius - this.normalizedMinRadius) *
            this.exponentialDistribution(d.z);
        if (d.r > this.normalizedRadiusMaxValue) {
          this.normalizedRadiusMaxValue = d.r;
        }
        if (d.r < this.normalizedRadiusMinValue) {
          this.normalizedRadiusMinValue = d.r;
        }
      });
      return formattedData;
    },
    normalizeRadii(formattedData) {
      formattedData.forEach((d) => {
        d.r = this.normalizeValue(
          d.z,
          this.maxRadius,
          this.minRadius,
          this.normalizedMaxRadius,
          this.normalizedMinRadius
        );
        if (d.r > this.normalizedRadiusMaxValue) {
          this.normalizedRadiusMaxValue = d.r;
        }
        if (d.r < this.normalizedRadiusMinValue) {
          this.normalizedRadiusMinValue = d.r;
        }
      });
      return formattedData;
    },
    exponentialDistribution(value, slope = 0.015) {
      return Math.exp(-slope * value);
    },
    normalDistribution(value, mean, sigma) {
      let _sigma = sigma;
      if (_sigma === 0) {
        _sigma = 1;
      }
      return Math.exp(((value - mean) / _sigma) ** 2 / -2);
    },
    normalizeValue(value, oldMax, oldMin, newMax = 100, newMin = 0) {
      const oldRange = oldMax - oldMin;
      if (oldRange === 0) return newMin;
      else {
        const newRange = newMax - newMin;
        return ((value - oldMin) * newRange) / oldRange + newMin;
      }
    },
    extractResultKeyValue(entityUnit) {
      const payload = {};
      for (let j = 0; j < entityUnit.data.length; j++) {
        const accessKey = entityUnit.data[j]?.name;
        payload[accessKey] = entityUnit.data[j]?.RESULT?.[accessKey];
      }
      return payload;
    },
    extractPvpKeyValue(entityUnit, metricsMetadata) {
      const payload = {};
      for (const item of entityUnit.data) {
        let accessKey = null;
        const metricName = item.name;
        if (item.PVP) {
          if (metricsMetadata[metricName]) {
            accessKey =
              metricsMetadata[metricName].metadata.unit === 'PERCENTAGE'
                ? 'PVP_DIFF_' + metricName
                : 'PVP_' + metricName;
          } else {
            accessKey = 'PVP_DIFF_' + metricName;
          }
          payload[metricName] = item.PVP[accessKey];
        }
      }
      return payload;
    },
    createMetricAbsoluteChangePayload(
      metric,
      metricType,
      metricUnit = 'PERCENTAGE'
    ) {
      if (metricType === 'ABSOLUTE') {
        return metric;
      }
      if (metricUnit === 'PERCENTAGE') {
        return 'PVP_DIFF_' + metric;
      }
      return 'PVP_' + metric;
    },
    getBaseWhereClauseCombinationStructure(brand) {
      return {
        dimensionNameValueList: [
          {
            dimensionName: this.getAnchoredPrefix(brand),
            dimensionValue: brand,
            operator: 'EQUAL_TO'
          }
        ]
      };
    },
    addSovBrandsInOrder(requestCopy, dataGroup) {
      if (this.sovDataGroups[dataGroup]) {
        const dataGroupRequest = requestCopy.bundleDataGroupsRequest[dataGroup];
        for (const brand of this.brandOrder) {
          if (!dataGroupRequest.operations.whereClauseCombination) {
            dataGroupRequest.operations.whereClauseCombination = [
              this.getBaseWhereClauseCombinationStructure(brand)
            ];
          } else {
            dataGroupRequest.operations.whereClauseCombination.push(
              this.getBaseWhereClauseCombinationStructure(brand)
            );
          }
        }
      }
    },
    addSovFilters(
      bundleDataGroupsRequest,
      quadrant,
      axis,
      selectedMetric,
      midpoint,
      ternaryData,
      selectedData
    ) {
      const order = this.getSovBrandOrder(selectedData.brand);
      const { whereClauseCombination } =
        bundleDataGroupsRequest[selectedMetric.dataGroup].operations;
      const quadrantWiseMetric = this.getQuadrantWiseMetrics(
        selectedMetric.metadata.isInverted,
        quadrant,
        axis
      );
      const metricToBeAppended =
        this.createMetricAbsoluteChangePayload(
          selectedMetric.alias,
          ternaryData.value,
          this.metadata.metrics[selectedMetric.alias].metadata.unit
        ) + '_share_percentage';
      whereClauseCombination[order].dimensionNameValueList.push({
        dimensionName: metricToBeAppended,
        dimensionValue: midpoint,
        operator: quadrantWiseMetric.operator
      });
    },
    setMidpoints(
      requestCopy,
      quadrant,
      axis,
      selectedMetric,
      midpoint,
      ternaryData,
      selectedData
    ) {
      const { bundleDataGroupsRequest } = requestCopy;
      if (this.sovDataGroups[selectedMetric.dataGroup]) {
        this.addSovFilters(
          bundleDataGroupsRequest,
          quadrant,
          axis,
          selectedMetric,
          midpoint,
          ternaryData,
          selectedData
        );
      } else {
        const currentRequest =
          bundleDataGroupsRequest[selectedMetric.dataGroup];
        if (!currentRequest.operations.outerWhereClause) {
          currentRequest.operations.outerWhereClause = {
            dimensionNameValueList: []
          };
        }
        const metricToBeAppended = this.createMetricAbsoluteChangePayload(
          selectedMetric.alias,
          ternaryData.value,
          this.metadata.metrics[selectedMetric.alias].metadata.unit
        );
        const quadrantWiseMetric = this.getQuadrantWiseMetrics(
          selectedMetric.metadata.isInverted,
          quadrant,
          axis
        );
        const payload = {
          dimensionName: metricToBeAppended,
          operator: quadrantWiseMetric.operator,
          dimensionValue: midpoint
        };
        currentRequest.operations.outerWhereClause.dimensionNameValueList.push(
          payload
        );
      }
    },
    getSelectedSovFiltersInWhereClauseCombination() {
      try {
        const unsavedState = getUnsavedState(this);
        const metric = unsavedState.settings.selectedPlacement;
        const unsavedWidgetState = unsavedState[this.widgetName];
        const radio =
          unsavedWidgetState.filterRuleBuilderChipSelections?.[0]?.[0];
        const metricType =
          unsavedWidgetState.filterRuleBuilderChipSelections?.[1]?.[0];
        const expression =
          unsavedWidgetState.filterRuleBuilderChipSelections?.[1]?.[1];
        const dimensionToBeAppended =
          metricType.value === 'ABSOLUTE' ? metric : 'PVP_DIFF_' + metric;
        return [
          {
            dimensionName: radio.brandKey,
            dimensionValue: radio.brand,
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: dimensionToBeAppended + '_share_percentage',
            dimensionValue: expression.value,
            operator: expression.operator.operator
          }
        ];
      } catch (e) {
        return [];
      }
    },
    appendFilters(requestCopy, where) {
      const { customMetadata } = this.metadata;
      const { bundleDataGroupsRequest } = requestCopy;
      const { anchoredBrand } = getUnsavedSettings(this);
      const globalWhereClause = this.globalWhereClause();
      const bundleDataGroupsRequestKeys = Object.keys(bundleDataGroupsRequest);
      const lookupMap = this.getArrayLookupMaps(
        customMetadata.filtersDetails,
        'dimensionName'
      );
      bundleDataGroupsRequestKeys.forEach((bundleKey) => {
        if (this.sovDataGroups[bundleKey]) {
          const sovFilterCombinations =
            this.getSelectedSovFiltersInWhereClauseCombination();
          const brandInFilter = sovFilterCombinations?.[0]?.dimensionValue;
          if (brandInFilter) {
            const order = this.getSovBrandOrder(brandInFilter);
            const { whereClauseCombination } =
              bundleDataGroupsRequest[bundleKey].operations;
            whereClauseCombination[order].dimensionNameValueList.push(
              sovFilterCombinations[1]
            );
          }
        } else {
          for (const item of where.dimensionNameValueList) {
            if (lookupMap[item.dimensionName]?.allDataGroups) {
              this.appendToIndividualDataGroup(
                bundleDataGroupsRequest,
                bundleDataGroupsRequestKeys,
                item
              );
            } else {
              const { dataGroup } = lookupMap[item.dimensionName];
              this.appendToIndividualDataGroup(
                bundleDataGroupsRequest,
                dataGroup,
                item
              );
            }
          }
        }

        const currentDataGroupRequest =
          requestCopy.bundleDataGroupsRequest[bundleKey];
        requestDateReplacement(
          currentDataGroupRequest.where,
          globalWhereClause
        );
        requestTimeReplacement(
          currentDataGroupRequest.where,
          globalWhereClause
        );
        currentDataGroupRequest.where.dimensionNameValueList = [
          ...(currentDataGroupRequest?.where?.dimensionNameValueList || []),
          ...globalWhereClause.dimensionNameValueList
        ];
        this.appendSettings(currentDataGroupRequest, bundleKey);
        appendAnchorFilter(currentDataGroupRequest.where, anchoredBrand);
        const titleOptionSelected = selectedTitleOptions(this);
        const entityType = titleOptionSelected.value;
        appendIsNotNull(
          bundleKey,
          currentDataGroupRequest.where.dimensionNameValueList,
          entityType
        );
        const titleOptionsSlected = selectedTitleOptions(this);
        currentDataGroupRequest.where.dimensionNameValueList =
          getFilteredShelfHierarchy(
            currentDataGroupRequest.where.dimensionNameValueList,
            titleOptionsSlected
          );
      });
    },
    appendSettings(request, dataGroup) {
      const unsavedSettings = getUnsavedSettings(this);
      if (dataGroup && this.sovDataGroups[dataGroup]) {
        appendPartialArrays(
          request.where.dimensionNameValueList,
          unsavedSettings.selectedType,
          this.types
        );
      }
      appendPartialArrays(
        request.where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      this.appendAnchorBrand(request, dataGroup);
    },
    appendToIndividualDataGroup(
      bundleRequestObject,
      keysToAppendOn,
      toBeAppended
    ) {
      keysToAppendOn.forEach((key) => {
        if (bundleRequestObject[key]) {
          bundleRequestObject[key].where.dimensionNameValueList.push(
            toBeAppended
          );
        }
      });
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(this.getGlobalWhereClause);
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause.time_range.startTime;
        where.time.to = globalWhereClause.time_range.endTime;
        where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
        where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      }
      where.dimensionNameValueList = globalWhereClause.dimensionNameValueList;
      return where;
    },
    getArrayLookupMaps(arrayToBeConverted, keyToMapWith) {
      const map = {};
      arrayToBeConverted.forEach((item) => {
        map[item[keyToMapWith]] = item;
      });
      return map;
    },
    setDataGroup(metric, requestCopy, entityType) {
      const { bundleDataGroupsRequest } = requestCopy;
      if (!bundleDataGroupsRequest[metric.dataGroup]) {
        bundleDataGroupsRequest[metric.dataGroup] = cloneDeep(
          metric.api.request
        );
        bundleDataGroupsRequest[metric.dataGroup].entityType = entityType;
        bundleDataGroupsRequest[metric.dataGroup].metricsList = [];
        if (
          bundleDataGroupsRequest?.[metric.dataGroup]?.operations?.dynamicSource
            ?.tables?.[0]?.columns?.[0]?.columnName
        ) {
          bundleDataGroupsRequest[
            metric.dataGroup
          ].operations.dynamicSource.tables[0].columns[0].columnName =
            entityType;
        }
      }
      bundleDataGroupsRequest[metric.dataGroup].page = this.page;
      bundleDataGroupsRequest[metric.dataGroup].widget = this.widgetName;
      bundleDataGroupsRequest[metric.dataGroup].metricsList.push(metric.alias);
    },
    resetAxialWhereClauseCombination() {
      this.brandOrder = [];
    },
    setSovBrandOrder(brand) {
      let found = false;
      for (let i = 0; i < this.brandOrder.length; i++) {
        if (this.brandOrder[i] === brand) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.brandOrder.push(brand);
      }
    },
    getSovBrandOrder(brand) {
      const index = this.brandOrder.findIndex((item) => item === brand);
      if (index >= 0) {
        return index;
      }
    },
    getAnchoredPrefix(selectedBrand) {
      const { anchoredBrand } = getUnsavedSettings(this);
      if (appendAnchorBrandCondition(anchoredBrand)) {
        return BRAND;
      }
      return BRAND_BY_CLIENT_FLAG;
      // if (selectedBrand === MY_BRANDS) {
      //   return BRAND_BY_CLIENT_FLAG;
      // }
      // return BRAND;
    },
    getBrandDataPrefix(order, selectedBrand) {
      return this.getAnchoredPrefix(selectedBrand) + '_' + order;
    },
    getOrderByList(metric, quadrant, axis, selectedBrand) {
      const quadrantWiseMetric = this.getQuadrantWiseMetrics(
        metric.metadata.isInverted,
        quadrant,
        axis
      );
      const payload = {};
      payload.direction = quadrantWiseMetric.direction;
      if (this.sovDataGroups[metric.dataGroup]) {
        const order = this.getSovBrandOrder(selectedBrand) + 1;
        payload.dimension =
          this.getBrandDataPrefix(order, selectedBrand) + '_' + metric.keyName;
      } else {
        payload.dimension = metric.keyName;
      }
      return [payload];
    },
    getPlottedData(metric) {
      const selectedMetric = metric.keyName;
      const value = null;
      return { selectedMetric, value };
    },
    handleOptionSelect(data) {
      const { selectedOptions } = data || {};
      if (selectedOptions[0]) {
        titleOptionSelect(this, data, setUnsavedState);
        this.getMidPointAndChartData();
      }
    },
    setInitialMultiDropdownConfig() {
      return cloneDeep(this.baseMultiDropdownConfig);
    },
    removeLassoSelect() {
      this.isZoomEnabled = true;
      this.isLassoEnabled = false;
      const chartInstance = this.getChartInstance();
      chartInstance?.removeLassoSelect();
    },
    enableDisableChartIcons() {
      const chartInstance = this.getChartInstance();
      this.chartOptionsData = this.chartOptionsData.map((item) => {
        if (item.icon === 'reset_to_default') {
          const { length } = Object.keys(this.selectedPointsStore);
          if (
            length > 0 ||
            chartInstance?.zoomLevel > chartInstance?.minZoomLevel
          ) {
            item.enabled = true;
          } else {
            item.enabled = false;
          }
        }
        return item;
      });
    },
    handleZoom() {
      this.enableDisableChartIcons();
    },
    handleZoomIn() {
      this.isZoomEnabled = true;
      const chartInstance = this.getChartInstance();
      chartInstance?.removeLassoSelect();
      const newZoom = chartInstance?.zoomLevel + 0.5;
      chartInstance?.programmaticZoom(newZoom);
    },
    resetZoom() {
      const chartInstance = this.getChartInstance();
      chartInstance?.programmaticZoom(chartInstance?.initialZoomLevel);
      this.enableDisableChartIcons();
    },
    handleReset() {
      this.removeLassoSelect();
      this.showForcedLabelsOnly = false;
      this.highlightSelectedBubbles = false;
      this.deselectAllInTables();
      this.graphData = this.graphData.map((item) => {
        item.searchSelected = false;
        item.selected = false;
        return item;
      });
      this.$nextTick(() => {
        this.resetZoom();
        this.$refs['search-input'].resetText();
        this.enableDisableChartIcons();
      });
    },
    getQuadrant(xValue, yValue, xOrigin, yOrigin) {
      if (xValue >= xOrigin && yValue >= yOrigin) {
        return 1;
      }
      if (xValue < xOrigin && yValue >= yOrigin) {
        return 2;
      }
      if (xValue < xOrigin && yValue < yOrigin) {
        return 3;
      }
      if (xValue >= xOrigin && yValue < yOrigin) {
        return 4;
      }
    },
    handleZoomOut() {
      this.removeLassoSelect();
      const chartInstance = this.getChartInstance();
      const newZoom = chartInstance?.zoomLevel - 0.5;
      chartInstance?.programmaticZoom(newZoom);
    },
    handleLassoSelect(selectIndex) {
      this.selectedOptionIndex = selectIndex;
      this.isZoomEnabled = false;
      this.isLassoEnabled = true;
      const chartInstance = this.getChartInstance();
      chartInstance?.setLassoSelect();
      this.enableDisableChartIcons();
    },
    getChartInstance() {
      return this.$refs.chart;
    },
    getQuadrantWiseMetrics(isInverted, quadrant, axis) {
      const map = {
        normal: {
          1: {
            x: {
              direction: 'DESC',
              operator: 'GREATER_THAN_OR_EQUAL_TO'
            },
            y: {
              direction: 'DESC',
              operator: 'GREATER_THAN_OR_EQUAL_TO'
            }
          },
          2: {
            x: {
              direction: 'ASC',
              operator: 'LESS_THAN'
            },
            y: {
              direction: 'DESC',
              operator: 'GREATER_THAN_OR_EQUAL_TO'
            }
          },
          3: {
            x: {
              direction: 'ASC',
              operator: 'LESS_THAN'
            },
            y: {
              direction: 'ASC',
              operator: 'LESS_THAN'
            }
          },
          4: {
            x: {
              direction: 'DESC',
              operator: 'GREATER_THAN_OR_EQUAL_TO'
            },
            y: {
              direction: 'ASC',
              operator: 'LESS_THAN'
            }
          }
        },
        inverted: {
          1: {
            x: {
              direction: 'ASC',
              operator: 'LESS_THAN_OR_EQUAL_TO'
            },
            y: {
              direction: 'ASC',
              operator: 'LESS_THAN_OR_EQUAL_TO'
            }
          },
          2: {
            x: {
              direction: 'DESC',
              operator: 'GREATER_THAN'
            },
            y: {
              direction: 'ASC',
              operator: 'LESS_THAN_OR_EQUAL_TO'
            }
          },
          3: {
            x: {
              direction: 'DESC',
              operator: 'GREATER_THAN'
            },
            y: {
              direction: 'DESC',
              operator: 'GREATER_THAN'
            }
          },
          4: {
            x: {
              direction: 'ASC',
              operator: 'LESS_THAN_OR_EQUAL_TO'
            },
            y: {
              direction: 'DESC',
              operator: 'GREATER_THAN'
            }
          }
        }
      };
      if (axis === 'z') {
        if (isInverted) {
          return {
            direction: 'ASC'
          };
        }
        return {
          direction: 'DESC'
        };
      } else {
        // if (isInverted) {
        //   return map.inverted[quadrant][axis];
        // }
        return map.normal[quadrant][axis];
      }
    },
    copyBrandDataToRuleBuidlerAxisConfig(ruleBuilderAxisConfig, brandData) {
      const { selectedValue, segmentedData } = cloneDeep(brandData);
      ruleBuilderAxisConfig.root.props.options.forEach((item) => {
        if (this.hasBrandsData(item)) {
          item.nextStep.props.selectedValue = selectedValue;
          item.nextStep.props.segmentedData = segmentedData;
        }
      });
      return { ...ruleBuilderAxisConfig };
    }
  }
};
</script>

<style lang="css" scoped>
.dashed-border {
  border-right-style: dashed;
}
.height-inherit {
  height: inherit;
}
.seperator-height {
  padding-top: 11px;
  padding-bottom: 11px;
}
.max-width-views {
  max-width: 300px;
}
.max-width-mid-section {
  max-width: 450px;
}
.clip {
  overflow: hidden;
}
.intermediate-dimensions {
  height: 332px;
}
.fit-content {
  width: fit-content;
}
.table-width {
  width: calc(50%);
}
.positioning {
  top: 10px;
  right: 10px;
}
.u-filter-trigger {
  left: calc(50% - 12px);
  border-radius: 50%;
  transform: rotate(-90deg);
  top: -12px;
}
.interactable-error {
  width: 560px;
}
.bubble-no-load {
  margin-top: 200px;
  margin-bottom: 200px;
}
.hide-section {
  visibility: hidden;
}

.view-title.title {
  color: #2b333b;
}
</style>
