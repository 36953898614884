<template>
  <SelectRow
    v-if="showV1V2Switch"
    class="u-font-size-7 v1v2Switch"
    :options="switchValues"
    :is-expanded="true"
    select-type="radio"
    :original-selections="v1V2SwitchValue"
    @selection="handleFeatureSwitch"
  />
</template>

<script>
import SelectRow from '@/components/pages/insights/amazon/share-of-voice/atoms/select-row.vue';
export default {
  name: 'FeatureSwitch',
  components: {
    SelectRow
  },
  props: {
    featureSwitchLocalStorageKey: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      switchValues: [{ label: 'v1' }, { label: 'v2' }],
      v1V2SwitchValue: {
        label: this.getLocalstorageRem1SwitchValue()
      }
    };
  },
  computed: {
    showV1V2Switch() {
      return window.environment !== 'prod';
    }
  },
  methods: {
    handleFeatureSwitch(data) {
      if (this.v1V2SwitchValue.label === data.label) {
        return;
      }
      localStorage.setItem(this.featureSwitchLocalStorageKey, data.label);
      window.location.reload();
    },
    getLocalstorageRem1SwitchValue() {
      const featureVersion =
        localStorage.getItem(this.featureSwitchLocalStorageKey) || 'v1';
      return featureVersion;
    }
  }
};
</script>

<style scoped>
.v1v2Switch {
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: flex-end;
}
</style>
