<template>
  <div
    v-if="shouldShowBanner"
    class="u-width-100 u-display-flex u-spacing-ph-l u-flex-align-items-center migration-banner"
    :class="['migration-banner-bg', paddingcss]"
    :style="{
      top: e2eBannerShowing || dataIssueBannerStatus ? '0' : '-1.6rem'
    }"
  >
    <div
      class="u-display-flex u-cursor-pointer u-position-relative u-spacing-mr-m"
    >
      <rb-icon
        class="rb-icon--medium"
        :class="`u-color-grey-lighter`"
        :icon="'info-circle-outline'"
      />
    </div>
    <div
      class="u-font-size-5 u-color-grey-base u-spacing-mr-s u-display-flex u-overflow-hidden"
    >
      <span
        v-if="compareStatus('prod')"
        class="u-white-space-no-wrap u-overflow-hidden"
      >
        We are upgrading our infrastructure between {{ formattedStartDate }} to
        {{ formattedEndDate }}.</span
      ><span
        v-else-if="compareStatus('live')"
        class="u-white-space-no-wrap u-overflow-hidden"
        >We have upgraded our infrastructure to improve the overall experience
        recently.</span
      ><span class="u-white-space-no-wrap u-overflow-hidden u-spacing-pl-xxs"
        >We expect no to minimal impact to your CIQ portal. In case you
        experience issues, please file a
        <span
          class="u-text-decoration-underline u-cursor-pointer"
          @click="startFeedback"
          >support ticket</span
        >.
      </span>
    </div>
    <span
      class="u-spacing-ml-auto"
      @click="closeBanner"
    >
      <rb-icon
        :icon="'cross'"
        class="u-color-grey-lighter rb-icon--medium u-cursor-pointer"
      />
    </span>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'MigrationBanner',
  props: {
    dates: {
      default: () => {
        return {};
      },
      type: Object
    },
    paddingcss: {
      type: [String],
      default: 'u-spacing-pv-xs'
    }
  },
  data() {
    return {
      disableBanner: this.isBannerDisable(),
      clientData: {},
      formattedStartDate: '',
      formattedEndDate: '',
      isBannerForInternalUsers: Vue.options.filters.config_check(
        'feature.migration_banner.internalUserCheck'
      )
    };
  },
  computed: {
    shouldShowBanner() {
      return (
        !this.disableBanner &&
        this.clientData.showBanner &&
        (this.isBannerForInternalUsers
          ? utils.internalUserCheck(window.user)
          : true)
      );
    },
    migratingClientData() {
      return this.$store.getters.getMigratingClientsData;
    }
  },
  created() {
    this.initializeBanner();
    eventBus.$on('e2eBannerStatus', (status) => {
      this.e2eBannerShowing = status;
    });
    eventBus.$on('dataIssueBannerStatus', (status) => {
      this.dataIssueBannerStatus = status;
    });
  },
  destroyed() {
    eventBus.$off('e2eBannerStatus');
    eventBus.$off('dataIssueBannerStatus');
  },
  methods: {
    compareStatus(status) {
      return this.clientData?.clientEnv?.toLowerCase() === status;
    },
    startFeedback() {
      this.$store.dispatch('feedbackScreenshot');
    },
    initializeBanner() {
      this.$store
        .dispatch('fetchMigratingClientsData')
        .then((res) => {
          this.setBannerInfo();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setBannerInfo() {
      this.clientData = this.migratingClientData.data;
      this.formattedStartDate = moment(this.clientData?.todayDate).format(
        'MMM Do'
      );
      this.formattedEndDate = moment(this.clientData.nextDate).format('MMM Do');
    },
    closeBanner() {
      localStorage.setItem('migration_banner_hidden', true);
      this.disableBanner = true;
    },
    isBannerDisable() {
      const custom_banner_hide_config = localStorage.getItem(
        'migration_banner_hidden'
      );
      return custom_banner_hide_config;
    }
  }
};
</script>
<style lang="css" scoped>
.migration-banner-bg {
  background: #ffefcf;
}
.migration-banner {
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
