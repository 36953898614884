<template>
  <div
    class="alertLastUpdatedAtWrp u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-flex-start"
    @click.stop
  >
    <div
      class="cell--content u-text-overflow-ellipsis u-flex-shrink-past-min-size u-color-grey-base"
      data-cy="datacellvalue"
    >
      <div
        v-if="parsedValue !== 'NA'"
        class="u-display-flex u-flex-direction-column"
      >
        <p
          class="u-line-height-1"
          data-cy="parsedValue"
        >
          {{ parsedValue }}
        </p>
        <p
          v-if="showDate"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-spacing-mt-s"
        >
          <rb-icon
            icon="sync"
            class="u-color-blue-base rb-icon--medium u-spacing-mr-xs"
          />
          <!-- eslint-disable -->
          <span class="u-line-height-1-5">{{
            this.params.formatterFn
              ? this.params.formatterFn(
                  this.params.data.client_data_update_date,
                  'date'
                )
              : '--'
          }}</span>
          <!-- eslint-enable -->
        </p>
      </div>
      <div v-else>NA</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertLastUpdatedAt',
  author: 'Rajan V',
  computed: {
    parsedValue() {
      var currObj = 'NA';
      currObj = this.params.data.deviation ? this.params.data.deviation : 'NA';
      return currObj;
    },
    showDate() {
      return (
        Vue.options.filters.config_check(
          'feature.contentChangeRecommendation.lastUpdatedDate.enable'
        ) || false
      );
    }
  }
};
</script>
