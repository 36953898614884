import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

const getFiltersPayload = (selectedFilters, localFilters) => {
  // default values
  var toRet = {
    recency: 30,
    filters: [],
    topRecommendation: 100
  };
  const currFilterObj = JSON.parse(JSON.stringify(selectedFilters));
  const currLocalFilterObj = JSON.parse(JSON.stringify(localFilters));
  for (var key in currFilterObj.filter) {
    if (key === 'profile_name') {
      const profileArray = currFilterObj.filter[key];
      for (let i = 0; i < profileArray.length; i++) {
        if (profileArray[i] === 'Others') {
          profileArray[i] = 'NA';
        }
      }
      currFilterObj[key] = profileArray;
    }
  }
  if (currFilterObj.filter) {
    if (currFilterObj.filter.rec_status) {
      toRet.recency = currFilterObj.filter.rec_status.filter((item) => {
        return item === 'New';
      })[0]
        ? 0
        : 30;
      delete currFilterObj.filter.rec_status;
    }
    if (currFilterObj.filter.estimation_value) {
      toRet.topRecommendation = currFilterObj.filter.estimation_value.map(
        (item) => {
          return Number(item.slice(-3, -1));
        }
      )[0];
      delete currFilterObj.filter.estimation_value;
    }
    if (currFilterObj.filter.action_state) {
      currFilterObj.filter.action_state = currFilterObj.filter.action_state.map(
        (item) => {
          return item.split(' ').join('_').toUpperCase();
        }
      );
    }
  }
  for (var key in currLocalFilterObj.filter) {
    currFilterObj.filter[key] = currLocalFilterObj.filter[key];
  }
  toRet.filters = [currFilterObj.filter];
  return toRet;
};

const state = {
  currentSelectedGroup: 'All',
  alertsLoader: false,
  recommendationName: undefined,
  recommendationType: undefined,
  recommendationDescription: undefined,
  recommendationTypeInMemory: undefined,
  bc_recommendation_type: undefined,
  recommendationInternalKey: undefined,
  route: {
    asin: undefined,
    search: undefined,
    bc_brand_alert_type: undefined
  },
  selectedAlert: undefined,
  isRedirectedFromAllSection: false,
  recommendationTypes: [],
  recommendations: [],
  columns: [],
  selectedRecommendationFilters: {},
  localRecommendationFilters: {
    filter: {}
  },
  recommendationFiltersPayload: [],
  columnCustomization: [],
  userCustomization: [],
  selectedAlertsForRemoval: [],
  removedRecommendationTypes: [],
  restoreAlertLoader: false,
  hardFetchedSKU: null,
  primaryKeyHashMap: {
    asin: 'asin',
    search: 'search',
    brand: 'bc_brand_alert_type',
    keyword_text: 'keyword_text',
    campaign: 'campaign',
    search_term: 'search_term'
  },
  actionWorkspaceLoaded: true,
  actionRoleRequired: null,
  highlightRow: {
    keyToSearch: '',
    valueToMap: ''
  },
  // P0 temporary change done for backend to pick right column for ES filtering wrt Internal filters. PROD-109382
  filter_hook_mapping: {
    'Comp oos amazon recommendations all': 'rec_pda_asin',
    'Comp VPC all': 'recommended_asin',
    'Tagged 3pt list all': 'client_asin',
    'Duplicate Listing': 'asin_1p',
    'Comp asins with promo': 'rec_pda_asin',
    'Trending comp asins': 'rec_pda_asin'
  },
  recommendationActiveTabLocalStorageKey: 'recommendationActiveTab'
};

const getters = {
  getRecommendationTypes: (state) => {
    return state.recommendationTypes;
  },
  getRecommendations: (state) => {
    return state.recommendations;
  },
  getSingleAlertForRemoval: (state) => {
    return state.selectedAlertsForRemoval[0];
  },
  isRedirectedFromAllSection: (state) => {
    return state.isRedirectedFromAllSection;
  },
  getPrimaryKeyHashMap: (state) => {
    return state.primaryKeyHashMap;
  },
  getPrimaryKeyType: (state) => {
    return state.primaryKeyHashMap[state.bc_recommendation_type];
  },
  getFetchedRecommendationName: (state) => {
    return state.recommendationName;
  },
  getFetchedRecommendationDescription: (state) => {
    return state.recommendationDescription;
  },
  getFetchedRecommendationType: (state) => {
    return state.recommendationType;
  },
  getRecommendationTypeInMemory: (state) => {
    return state.recommendationTypeInMemory;
  },
  getFetchedBcRecommendationType: (state) => {
    return state.bc_recommendation_type;
  },
  getInternalKey: (state) => {
    return state.recommendationInternalKey;
  },
  getSelectedAlert: (state) => {
    return state.selectedAlert;
  },
  getRemovedRecommendationTypes: (state) => {
    return state.removedRecommendationTypes;
  },
  getRestoreAlertLoader: (state) => {
    return state.restoreAlertLoader;
  },
  getColumns: (state) => {
    const distinctBs = new Set();
    const uniqueBs = [];
    for (var a of state.columnCustomization) {
      if (a.recommendationName === state.recommendationName) {
        a.recommendationUiLabelMappings.forEach((b) => {
          if (b.visible && !distinctBs.has(b.id)) {
            distinctBs.add(b.id);
            uniqueBs.push(b);
          }
        });
      }
    }
    return uniqueBs;
  },
  getAllColumns: (state) => {
    for (var a of state.columnCustomization) {
      if (a.recommendationName === state.recommendationName) {
        return a.recommendationUiLabelMappings;
      }
    }
  },
  getRecommendationSelectedFilters: (state) => {
    return state.selectedRecommendationFilters;
  },
  getRecommendationLocalFilters: (state) => {
    return state.localRecommendationFilters;
  },
  getRecommendationFiltersPayload: (state) => {
    return state.recommendationFiltersPayload;
  },
  getColumnCustomizations: (state) => {
    return state.columnCustomization;
  },
  areAlertsLoaded: (state) => {
    return !state.alertsLoader;
  },
  getHardFetchedSKU: (state) => {
    return state.hardFetchedSKU;
  },
  getCurrentSubGroup: (state) => {
    return state.currentSelectedGroup;
  },
  getCurrentSelectedRecommendation: (state) => {
    return state.selectedRecommendation;
  },
  isActionWorkspaceLoaded: (state) => {
    return state.actionWorkspaceLoaded;
  },
  gethighlightRow: (state) => {
    return state.highlightRow;
  },
  getRecommendationActiveTabLocalStorageKey: (state) => {
    return state.recommendationActiveTabLocalStorageKey;
  }
};

const mutations = {
  LOAD_RECOMMENDATIONS_WITH_CURRENT_STATE: (state, router) => {
    var queryParams = {};
    queryParams.name = state.recommendationName;
    queryParams.type = state.recommendationType;
    queryParams.bc_recommendation_type = state.bc_recommendation_type;
    router.push({
      query: queryParams
    });
  },
  SET_ROUTING_STATE_PARAMS: (state, routeParams) => {
    state.recommendationName = routeParams.name
      ? routeParams.name
      : state.recommendationName;
    state.recommendationType = routeParams.type
      ? routeParams.type
      : state.recommendationType;
    state.bc_recommendation_type = routeParams.bc_recommendation_type
      ? routeParams.bc_recommendation_type
      : state.bc_recommendation_type;
    state.recommendationInternalKey = routeParams.internal_key
      ? routeParams.internal_key
      : undefined;
    state.selectedAlert = routeParams.internal_key
      ? state.selectedAlert
      : undefined;
    state.route.asin = routeParams.asin ? routeParams.asin : undefined;
    state.route.search = routeParams.search ? routeParams.search : undefined;
    state.route.bc_brand_alert_type = routeParams.bc_brand_alert_type
      ? routeParams.bc_brand_alert_type
      : undefined;
  },
  CLEAR_ROUTING_STATE_PARAMS: (state) => {
    state.recommendationName = undefined;
    state.recommendationType = undefined;
    state.bc_recommendation_type = undefined;
  },
  RECOMMENDATIONS_FETCHED: (state, data) => {
    if (!data.recommendationMetaData) {
      return;
    }
    if (
      data.recommendationMetaData.recommendationDetails &&
      data.recommendationMetaData.recommendationDetails.length > 0 &&
      !data.recommendationMetaData.recommendationDetails[0].attributes
    ) {
      state.recommendations =
        data.recommendationMetaData.recommendationDetails.map((a) => {
          var keys = Object.keys(a);
          a.attributes = {};
          for (var key of keys) {
            a.attributes[key] = a[key];
          }
          return a;
        });
    } else {
      state.recommendations =
        data.recommendationMetaData.recommendationDetails.sort((a, b) => {
          if (a.attributes.priority_order) {
            return a.attributes.priority_order - b.attributes.priority_order;
          } else if (a.attributes) {
            var a1 = 100000000;
            var b1 = 100000000;
            if (a.attributes.search_importance_flag === 'Yes') {
              a1 = a.attributes.search_priority_rank;
            }
            if (b.attributes.search_importance_flag === 'Yes') {
              b1 = b.attributes.search_priority_rank;
            }
            if (a1 === b1) {
              if (a.attributes.search > b.attributes.search) {
                return 1;
              } else if (a.attributes.search === b.attributes.search) {
                return 0;
              } else {
                return -1;
              }
            } else {
              return a1 - b1;
            }
          }
        });
    }
    state.recommendationName = data.recommendationMetaData.recommendationName;
    state.recommendationType = data.recommendationMetaData.recommendationType;
    state.recommendationDescription =
      data.recommendationMetaData.recommendationDescription;
    state.recommendationTypeInMemory =
      data.recommendationMetaData.recommendationType;
    state.bc_recommendation_type =
      data.recommendationMetaData.bc_recommendation_type;
    state.actionRoleRequired = data.recommendationMetaData.product;
    state.recommendationTypes = data.recommendationCount.sort((a, b) => {
      if (a.displayOrder === null) return -1;
      else if (b.displayOrder === null) return 1;
      else return a.displayOrder - b.displayOrder;
    });
  },
  SET_SELECTED_RECOMMENDATION: (state) => {
    // set the detailed view based on query params
    if (!state.recommendations.length) {
      return;
    }
    let keyToCheck;
    let stateParamToCheck;
    let selectAlert = false;

    if (state.recommendationInternalKey) {
      keyToCheck = 'internal_key';
      stateParamToCheck = state.recommendationInternalKey;
      selectAlert = true;
    } else if (state.route.asin) {
      keyToCheck = 'asin';
      stateParamToCheck = state.route.asin;
      selectAlert = true;
    } else if (state.route.search) {
      keyToCheck = 'search';
      stateParamToCheck = state.route.search;
      selectAlert = true;
    } else if (state.route.bc_brand_alert_type) {
      keyToCheck = 'bc_brand_alert_type';
      stateParamToCheck = state.route.bc_brand_alert_type;
      selectAlert = true;
    }
    if (selectAlert) {
      state.selectedAlert = state.recommendations.filter((alert) => {
        return alert.attributes[keyToCheck] === stateParamToCheck;
      })[0];
    }
  },
  CLEAR_SELECTED_RECOMMENDATION: (state) => {
    state.selectedAlert = undefined;
  },
  CLEAR_DETAIL_VIEW: (state) => {
    state.recommendationInternalKey = undefined;
    state.route.asin = undefined;
    state.route.search = undefined;
    state.route.bc_brand_alert_type = undefined;
    state.selectedAlert = undefined;
  },
  SET_REDIRECTION_FROM_ALL: (state) => {
    state.isRedirectedFromAllSection = true;
  },
  CLEAR_REDIRECTION_FROM_ALL: (state) => {
    state.isRedirectedFromAllSection = false;
  },
  CLEAR_RECOMMENDATIONS: (state) => {
    state.recommendations = [];
    state.recommendationTypes = [];
    state.recommendationTypeInMemory = undefined;
    state.recommendationDescription = undefined;
    state.localRecommendationFilters = {
      filter: {}
    };
    state.recommendationFiltersPayload = getFiltersPayload(
      state.selectedRecommendationFilters,
      state.localRecommendationFilters
    );
  },
  RECOMMENDATIONS_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.selectedRecommendationFilters,
      data.filterValueKey,
      data.values
    );
    state.recommendationFiltersPayload = getFiltersPayload(
      state.selectedRecommendationFilters,
      state.localRecommendationFilters
    );
  },
  RECOMMENDATIONS_SET_LOCAL_FILTER: (state, data) => {
    Vue.set(state.localRecommendationFilters, data.filterValueKey, data.values);
    state.recommendationFiltersPayload = getFiltersPayload(
      state.selectedRecommendationFilters,
      state.localRecommendationFilters
    );
  },
  SORT_RECOMMENDATIONS: (state, options) => {
    if (!options.field) {
      state.recommendations = state.recommendations.sort((a, b) => {
        return a.attributes.priority_order - b.attributes.priority_order;
      });
      return;
    }
    var asc = -1;
    if (options.order === 'asc') {
      asc = 1;
    }
    var col = getters.getColumns(state).filter((item) => {
      return item.uiKey === options.field;
    })[0];
    var prop = options.field;
    if (col.keyType === 'string') {
      state.recommendations = state.recommendations.sort((a, b) => {
        if (
          (a.attributes[prop] + '').toLocaleLowerCase() >
          (b.attributes[prop] + '').toLocaleLowerCase()
        )
          return 1 * asc;
        else if (
          (a.attributes[prop] + '').toLocaleLowerCase() <
          (b.attributes[prop] + '').toLocaleLowerCase()
        )
          return -1 * asc;
        else return 0;
      });
    } else {
      state.recommendations = state.recommendations.sort((a, b) => {
        if (a.attributes[prop] > b.attributes[prop]) return 1 * asc;
        else if (a.attributes[prop] < b.attributes[prop]) return -1 * asc;
        else return 0;
      });
    }
  },
  RECOMMENDATION_COLUMNS_FETCHED: (state, data) => {
    for (var type of data) {
      if (type.recommendationUiLabelMappings === null) {
        type.recommendationUiLabelMappings = [];
      }
      const keysToExclude = [
        'mean_estimate_value',
        'revenue_lost',
        'total_revenue_lost_sum'
      ];
      if (
        !Vue.options.filters.config_check(
          'feature.recommendations.performanceOverview.enable'
        )
      ) {
        type.recommendationUiLabelMappings =
          type.recommendationUiLabelMappings.filter((a) => {
            return keysToExclude.indexOf(a.uiKey) === -1;
          });
      }
      type.recommendationUiLabelMappings.sort((a, b) => {
        return a.keyOrder - b.keyOrder;
      });
    }
    state.columnCustomization = data.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
  },
  UPDATE_RECOMMENDATION_COLUMNS: (state, customizedColumns) => {
    state.columnCustomization = customizedColumns;
  },
  SHOW_ALERTS_LOADER: (state) => {
    state.alertsLoader = true;
  },
  HIDE_ALERTS_LOADER: (state) => {
    state.alertsLoader = false;
  },
  LOAD_RECOMMENDATIONS_FOR_REMOVAL: (state, alertObj) => {
    state.selectedAlertsForRemoval.push(alertObj);
  },
  CLEAR_RECOMMENDATIONS_FOR_REMOVAL: (state) => {
    state.selectedAlertsForRemoval = [];
  },
  CLEAR_REMOVED_RECOMMENDATION_TYPES: (state) => {
    state.removedRecommendationTypes = [];
  },
  SET_REMOVED_RECOMMENDATION_TYPES: (state, data) => {
    state.removedRecommendationTypes = data;
  },
  HARD_FETCHED_SKU_DETAILS: (state, data) => {
    state.selectedAlert = data.recommendationDetails[0] || undefined;
  },
  CLEAR_HARD_FETCHED_SKU_DETAILS: (state) => {
    state.selectedAlert = undefined;
  },
  SHOW_REMOVED_ALERTS_LOADER: (state) => {
    state.restoreAlertLoader = true;
  },
  HIDE_REMOVED_ALERTS_LOADER: (state) => {
    state.restoreAlertLoader = false;
  },
  MUTATE_ACTION_STATUS_FOR_LIST_VIEW: (state, data) => {
    const mutatedAlerts = data.map((item) => item.alertVO);
    // state.recommendations = state.recommendations.map(function (alert) {
    //   for (var i = 0; i < mutatedAlerts.length; i++) {
    //     if (alert.id === mutatedAlerts[i].id) {
    //       return mutatedAlerts[i];
    //     }
    //   }
    //   return alert;
    // })
    state.recommendations.forEach(function (alert) {
      for (var i = 0; i < mutatedAlerts.length; i++) {
        if (alert.id === mutatedAlerts[i].id) {
          alert.attributes.action_state =
            mutatedAlerts[i].attributes.action_state;
          alert.attributes.isLoading = false;
        }
      }
    });
    // console.log(state.recommendations)
  },
  REMOVE_ALERT_FROM_LOCAL_DATA: (state) => {
    var key = state.bc_recommendation_type;
    var value =
      state.selectedAlertsForRemoval[0].recommendationObj.attributes[key];
    state.recommendations = state.recommendations.filter((a) => {
      return a.attributes[key] !== value;
    });
    var type = state.recommendationTypes.filter((a) => {
      return a.recommendationType === state.recommendationType;
    })[0];
    type.recommendationCount = state.recommendations.length;
  },
  SHOW_ACTION_WORKSPACE_LOADER: (state) => {
    state.actionWorkspaceLoaded = false;
  },
  HIDE_ACTION_WORKSPACE_LOADER: (state) => {
    state.actionWorkspaceLoaded = true;
  },
  SET_HIGHLIGHT_ROW: (state, data) => {
    state.highlightRow.keyToSearch = data.keyToSearch;
    state.highlightRow.valueToMap = data.valueToMap;
  }
};

const actions = {
  setStateFromParams: (context, routeParams) => {
    context.commit('SET_ROUTING_STATE_PARAMS', routeParams || {});
  },
  clearRouteParamsFromState: (context) => {
    context.commit('CLEAR_ROUTING_STATE_PARAMS');
  },
  loadDefaultState: (context, router) => {
    context.commit('SET_ROUTING_STATE_PARAMS', {});
    context.commit('LOAD_RECOMMENDATIONS_WITH_CURRENT_STATE', router);
  },
  fetchRecommendations: async (context, options) => {
    context.commit('SHOW_ALERTS_LOADER');
    context.commit('CLEAR_SELECTED_RECOMMENDATION');

    if (options && options.refreshRequired === false) {
      context.commit('SET_SELECTED_RECOMMENDATION');
      context.commit('HIDE_ALERTS_LOADER');
    } else {
      try {
        // Simulate a delay with setTimeout
        const delay = state.recommendationTypeInMemory ? 0 : 200;
        await new Promise((resolve) => setTimeout(resolve, delay));

        // Remove 'date_range' from the payload object
        const updatedFilters =
          state.recommendationFiltersPayload.filters.filter((elm) => {
            delete elm.date_range;
            return elm;
          });

        const updatedWhereClause = transformer.transformWhereClauseWithTag(
          updatedFilters || [],
          'recommendation'
        );

        const config = {
          alertName: state.recommendationName,
          alertType: state.recommendationType,
          bc_recommendation_type: state.bc_recommendation_type,
          filters: updatedWhereClause.updatedFilters || [],
          recency: state.recommendationFiltersPayload.recency,
          topRecommendation:
            state.recommendationFiltersPayload.topRecommendation,
          recommendationGroup: state.currentSelectedGroup,
          filter_hook:
            state.filter_hook_mapping[state.recommendationName] || 'asin',
          tagWhereClause: [{ ...updatedWhereClause.tagWhereClause }]
        };

        // create a new action
        context.dispatch('updateStateAndRouter', config, options);
      } catch (error) {
        if (error.response) {
          context.commit('API_ERROR', error.response);
          context.commit('HIDE_ALERTS_LOADER');
        }
      }
    }
  },
  handleRouteParams: (context) => {
    if (
      state.route.asin ||
      state.route.search ||
      state.route.bc_brand_alert_type
    ) {
      if (!state.selectedAlert) {
        context.commit('CLEAR_HARD_FETCHED_SKU_DETAILS');
        context.commit('SHOW_ALERTS_LOADER');

        const params = {
          alertName: state.recommendationName,
          alertType: state.recommendationType,
          bc_recommendation_type: state.bc_recommendation_type,
          recommendationGroup: context.state.currentSelectedGroup,
          recommendationKey: context.getters.getPrimaryKeyType,
          recommendationValue: state.route[context.getters.getPrimaryKeyType]
        };

        context.dispatch('hardFetchSkuDetails', params);
      } else {
        context.commit('HIDE_ALERTS_LOADER');
      }
    } else {
      context.commit('HIDE_ALERTS_LOADER');
    }
  },
  updateStateAndRouter: async (context, config, options) => {
    // Make an HTTP POST request using async/await
    const response = await HttpService.post('RECOMMENDATIONS', config, {
      headers: { account: 'deprecated' }
    });
    context.commit('RECOMMENDATIONS_FETCHED', response.data);
    context.commit('SET_SELECTED_RECOMMENDATION');
    context.commit('RECOMMENDATION_COLUMNS_FETCHED', [
      response.data?.recommendationMetaData || {}
    ]);
    if (options?.router) {
      const metaData = response?.data?.recommendationMetaData;

      if (!metaData) {
        context.commit('HIDE_ALERTS_LOADER');
        return;
      }

      context.commit('SET_ROUTING_STATE_PARAMS', options.queryParams);

      if (Object.keys(options.queryParams).length > 3) {
        options.router.push({
          query: options.queryParams
        });
      } else {
        const defaultParams = {
          name: metaData.recommendationName,
          type: metaData.recommendationType,
          bc_recommendation_type: metaData.bc_recommendation_type
        };

        options.router.push({
          query: {
            name: defaultParams.name,
            type: defaultParams.type,
            bc_recommendation_type: defaultParams.bc_recommendation_type
          }
        });
      }
    }
    context.dispatch('handleRouteParams');
  },
  hardFetchSkuDetails: async (context, params) => {
    const hardFetchResponse = await HttpService.post(
      'HARDFETCHSKUDETAILS',
      params,
      {
        headers: { account: 'deprecated' }
      }
    );

    context.commit('HARD_FETCHED_SKU_DETAILS', hardFetchResponse.data);
    context.commit('HIDE_ALERTS_LOADER');
  },
  clearRecommendationState: (context) => {
    context.commit('CLEAR_RECOMMENDATIONS');
    context.commit('CLEAR_DETAIL_VIEW');
  },
  updateUiLabelMapping: async (context, data) => {
    try {
      // set keyorder based on the reordering
      var selRecommendationIdx = null;
      for (var [idx, type] of data.entries()) {
        if (type.userSorted === true) {
          selRecommendationIdx = idx;
          var incr = type.reorderingStartIndex;
          for (var column of type.recommendationUiLabelMappings) {
            column.keyOrder = incr;
            incr++;
          }
          type.reorderingStartIndex = undefined;
          type.userSorted = undefined;
          break;
        }
      }

      var params = data[selRecommendationIdx];

      var requestHeaders = {
        headers: {
          account: 'deprecated'
        }
      };
      context.commit('UPDATE_RECOMMENDATION_COLUMNS', data);
      await HttpService.put(
        'UPDATE_RECOMMENDATION_COLUMNS',
        params,
        requestHeaders
      );
    } catch (error) {
      if (error.response) {
        context.commit('API_ERROR', error.response);
        context.commit('HIDE_ALERTS_LOADER');
      }
    }
  },
  setRecommendationSelectedFilters: (context, data) => {
    context.commit('RECOMMENDATIONS_SET_SELECTED_FILTER', data);
  },
  setRecommendationLocalFilters: (context, data) => {
    context.commit('RECOMMENDATIONS_SET_LOCAL_FILTER', data);
  },
  fetchDataColumns: (context) => {
    // Not used any more, getting column configs in the recommendaion api
    // HttpService.get('RECOMMENDATION_COLUMNS', { headers: { account: 'deprecated' } }).then(response => {
    //   // context.commit('RECOMMENDATION_COLUMNS_FETCHED', response.data);
    // });
  },
  loadRecommendationsForRemoval: (context, alertObject) => {
    context.commit('CLEAR_RECOMMENDATIONS_FOR_REMOVAL');
    context.commit('LOAD_RECOMMENDATIONS_FOR_REMOVAL', alertObject);
  },
  clearRecommendationsForRemoval: (context) => {
    context.commit('CLEAR_RECOMMENDATIONS_FOR_REMOVAL');
  },
  clearRemovedRecommedationTypes: (context) => {
    context.commit('CLEAR_REMOVED_RECOMMENDATION_TYPES');
  },
  removeRecommendation: (context, params) => {
    context.commit('SHOW_ALERTS_LOADER');

    var currAlertObj = JSON.parse(
      JSON.stringify(context.getters.getSingleAlertForRemoval.recommendationObj)
    );
    var namesToExclude = ['asin', 'asin_na', 'search', 'search_na'];
    if (
      namesToExclude.indexOf(context.getters.getFetchedRecommendationName) > -1
    ) {
      delete currAlertObj.attributes;
    }
    var reqObj = {
      recommendationCount: context.getters.getRecommendationTypes,
      recommendationMetaData: {
        recommendationDetails: [currAlertObj],
        recommendationLabel: 'string',
        recommendationName: 'string',
        recommendationType: 'string',
        recommendationUiLabelMappings: state.columnCustomization.filter(
          (item) => {
            return item.recommendationName === state.recommendationName;
          }
        )[0].recommendationUiLabelMappings
      }
    };

    for (var type of context.getters.getRecommendationTypes) {
      if (
        type.recommendationName === context.getters.getFetchedRecommendationName
      ) {
        reqObj.recommendationMetaData.recommendationLabel =
          type.recommendationLabel;
        reqObj.recommendationMetaData.recommendationName =
          type.recommendationName;
        reqObj.recommendationMetaData.recommendationType =
          type.recommendationType;
      }
    }

    return new Promise((resolve, reject) => {
      HttpService.post('REMOVE_RECOMMENDATIONS', {
        snoozeRecommendationType:
          params.snoozeRecommendationType === 'ALL'
            ? 'ALL'
            : reqObj.recommendationMetaData.recommendationType,
        snoozeRecommendationName:
          params.snoozeRecommendationType === 'ALL'
            ? 'ALL'
            : reqObj.recommendationMetaData.recommendationName,
        reason: params.reason,
        recommendation: reqObj
      })
        .then(() => {
          context.commit('HIDE_ALERTS_LOADER');
          context.commit('REMOVE_ALERT_FROM_LOCAL_DATA');
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            reject();
            context.commit('API_ERROR', error.response);
            context.commit('HIDE_ALERTS_LOADER');
          }
        });
    });
  },
  restoreRecommendation: (context, options) => {
    if (options.reload) {
      context.commit('SHOW_ALERTS_LOADER');
    } else {
      context.commit('SHOW_REMOVED_ALERTS_LOADER');
    }
    var params = {
      snoozeRecommendationType: options.alertType,
      snoozeRecommendationName: options.alertName,
      bc_recommendation_type: options.bc_recommendation_type,
      key: options.key
    };
    return new Promise((resolve, reject) => {
      HttpService.post('RESTORE_RECOMMENDATIONS', params)
        .then(() => {
          if (options.reload) {
            context.commit('HIDE_ALERTS_LOADER');
          } else {
            context.commit('HIDE_REMOVED_ALERTS_LOADER');
          }
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            context.commit('API_ERROR', error.response);
            if (options.reload) {
              context.commit('HIDE_ALERTS_LOADER');
            } else {
              context.commit('HIDE_REMOVED_ALERTS_LOADER');
            }
            reject();
          }
        });
    });
  },
  fetchRemovedRecommendations: (context, options) => {
    context.commit('SHOW_REMOVED_ALERTS_LOADER');
    context.commit('CLEAR_REMOVED_RECOMMENDATION_TYPES');
    return new Promise((resolve, reject) => {
      HttpService.post('GET_REMOVED_RECOMMENDATIONS', {
        alertName: options.recommendationName,
        alertType: options.recommendationType
      })
        .then((resp) => {
          if (resp.data && resp.data.recommendationCount) {
            var recommedationCount = resp.data.recommendationCount.sort(
              (a, b) => {
                if (a.displayOrder === null) return -1;
                else if (b.displayOrder === null) return 1;
                else return a.displayOrder - b.displayOrder;
              }
            );
            context.commit(
              'SET_REMOVED_RECOMMENDATION_TYPES',
              recommedationCount
            );
          }
          context.commit('HIDE_REMOVED_ALERTS_LOADER');
          if (
            resp.data &&
            resp.data.recommendationMetaData &&
            resp.data.recommendationMetaData.recommendationDetails
          ) {
            resolve(resp.data.recommendationMetaData.recommendationDetails);
          }
        })
        .catch((error) => {
          if (error.response) {
            context.commit('API_ERROR', error.response);
            context.commit('HIDE_REMOVED_ALERTS_LOADER');
            reject();
          }
        });
    });
  },
  currentSelectedRecommendation: (context, value) => {
    context.state.selectedRecommendation = value;
  },
  setCurrentSubGroup: (context, value) => {
    context.state.currentSelectedGroup = value;
  },
  submitRecommendationAction: (context, options) => {
    const allRecommendations = context.getters.getRecommendations;
    const filteredSelections = options.selections.map((item) => item.data);
    const requestedAction = options.action;
    const isBoomerangAction = !requestedAction.id.startsWith('nonBoom');
    let selections;
    if (isBoomerangAction) {
      selections = filteredSelections.filter((item) => {
        return (
          item.action_payload &&
          (!item.action || item.action_state === 'FAILED')
        );
      });
    } else {
      selections = filteredSelections;
    }
    const actionables = allRecommendations.filter(function (alert) {
      for (var i = 0; i < selections.length; i++) {
        if (alert.attributes.internal_key === selections[i].internal_key) {
          return true;
        }
      }
    });
    const params = actionables.map((item) => {
      if (isBoomerangAction) {
        let actionPayload;
        let currentAction;
        let actionsArray = [];
        if (options.isEditAction) {
          actionPayload = options.newActionPayload;
          currentAction = options.currentActionType;
          actionsArray = actionPayload.actions;
        } else {
          if (item.attributes.action_payload) {
            actionPayload = JSON.parse(item.attributes.action_payload);
            currentAction = Object.keys(actionPayload)[0];
            actionsArray = actionPayload[currentAction].actions
              ? JSON.parse(actionPayload[currentAction].actions)
              : [];
          }
        }
        return {
          alertVO: item,
          appliedActionPayload: {
            actions: actionsArray
          },
          type: currentAction,
          actionState: 'APPROVED'
        };
      } else {
        return {
          actionState: requestedAction.value,
          alertVO: item,
          appliedActionPayload: {
            actions: [
              {
                action_type: 'boomerangExternalAction',
                action_state: requestedAction.value
              }
            ]
          },
          type: 'boomerangExternalAction'
        };
      }
    });

    return new Promise((resolve, reject) => {
      // context.commit('SHOW_ACTIONS_LOADER')
      HttpService.post('SUBMIT_ACTIONS', params).then(
        (resp) => {
          // context.commit('HIDE_ACTIONS_LOADER')
          context.commit('MUTATE_ACTION_STATUS_FOR_LIST_VIEW', resp.data);
          resolve(resp);
        },
        (err) => {
          // context.commit('HIDE_ACTIONS_LOADER')
          reject(err);
          console.log(err);
        }
      );
    });
  },
  fetchCampaignList: (context, options) => {
    const data = {
      cubeName: 'campaigns_drop_down_list_per_profile',
      measuresList: ['profile_name'],
      groupByDimensionsList: [
        'campaignType',
        'campaign_id',
        'adgroup_id',
        'campaign_name',
        'adgroup_name',
        'profile_id',
        'client_id'
      ],
      where: {
        date: {}
      },
      orderByList: [
        {
          dimension: 'campaign_id',
          direction: 'ASC'
        }
      ],
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false
    };
    if (options.profiles && options.profiles[0]) {
      const profileList = options.profiles.map((item) => {
        const filter = transformer.addEsDataSetName(
          options.dimensionKeyType,
          item
        );
        filter.operator = 'EQUAL_TO';
        return filter;
      });
      data.where.dimensionNameValueList = profileList;
    }
    context.commit('SHOW_ACTION_WORKSPACE_LOADER');
    return new Promise((resolve, reject) => {
      HttpLayer.post({
        APIData: data
      }).then(
        (response) => {
          context.commit('HIDE_ACTION_WORKSPACE_LOADER');
          resolve(transformer.mergeResultDimension(response.data, true));
        },
        (err) => {
          context.commit('HIDE_ACTION_WORKSPACE_LOADER');
          reject(err);
        }
      );
    });
  },
  setHighlightRow: (context, data) => {
    context.commit('SET_HIGHLIGHT_ROW', data);
  },
  clearRecommendationActiveTabLocalStorageKey: (context, url) => {
    const isRecommendationPage = url.split('/').includes('recommendations');
    if (!isRecommendationPage)
      localStorage.removeItem(
        context.state.recommendationActiveTabLocalStorageKey
      );
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
