<template>
  <div class="u-width-100 u-display-flex u-height-100">
    <progressTableComponent
      :wrapped-in-parent="true"
      :keys="keys"
    >
      <div slot="customComponent">
        <customToolTip v-if="tooltipContent.show">
          <div slot="toolTipData">
            <div class="u-max-width-480px">
              <div
                class="u-spacing-pl-l u-spacing-pt-m u-spacing-pb-s u-font-weight-600 u-font-size-2"
              >
                <span class="u-color-blue-base">IQ</span> Tip
              </div>
              <div
                class="u-font-weight-400 u-line-height-1-3 u-color-grey-light u-spacing-ml-l u-spacing-mr-m u-spacing-mb-m u-font-size-5"
              >
                {{ tooltipContent.title.pre }}
                <span
                  v-if="tooltipContent.title.mid"
                  class="u-font-weight-600"
                >
                  "{{ tooltipContent.title.mid }}"
                </span>
                <span v-if="tooltipContent.title.suff">{{
                  tooltipContent.title.suff
                }}</span>
              </div>
              <div class="u-font-size-6 u-font-weight-normal u-color-grey-base">
                <div
                  v-for="(item, key) in tooltipContent.description"
                  :key="key"
                  class="u-display-flex u-spacing-ml-xs"
                  :class="{ 'u-spacing-mb-m': item.length > 0 }"
                >
                  <div
                    v-if="item.length > 0"
                    class="u-spacing-mr-m u-display-flex u-flex-align-items-flex-start u-width-100"
                  >
                    <div
                      class="u-bullet-points u-flex-none u-border-radius-l u-bg-color-blue-base u-spacing-ml-s u-spacing-mt-xs"
                    ></div>
                    <span class="u-spacing-pl-s u-line-height-1-3">{{
                      item
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            slot="body"
            slot-scope="{ tippyConfig }"
          >
            <div
              v-if="tooltipContent.show"
              class="u-spacing-mr-s budgetPlanIQTipRowHover"
            >
              <div>
                <rb-icon
                  v-tippy="tippyConfig"
                  class="u-cursor-pointer rb-icon--xx-medium u-color-blue-base"
                  icon="light-bulb"
                />
              </div>
            </div>
          </div>
        </customToolTip>
      </div>
    </progressTableComponent>
  </div>
</template>
<script>
import progressTableComponent from '@/components/globals/dataTable/tableComponentsWrapper/progressTableComponent.vue';
import customToolTip from '@/components/widgets/customToolTip';
import Vue from 'vue';
import { groupBy } from 'lodash';
import utils from '@/utils/helpers/';

export default {
  components: {
    progressTableComponent,
    customToolTip
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    keys: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mtdProfilesTooltipContent() {
      // mtd --> month to date.
      return this.$store.getters.getBudgetPlanMTDProfilesTooltipContent;
    },
    tooltipContent() {
      const content = {
        title: {
          pre: "Here's what we have observed with this profile",
          mid: null,
          suff: null
        },
        show: false, // show is decided based on internal user and if description provided by ds team is not empty.
        description: []
      };

      const enableExplainability = this.isExplainabilityEnabled();

      if (!enableExplainability) {
        return content;
      }

      if (this.params.data.entity_type === 'PROFILE') {
        const entity_id = this.params.data.entity_id.toString();
        const profile = this.findMatchingProfile(entity_id);

        if (profile && this.isBudgetRecommendationNeeded(profile)) {
          content.title = this.getBudgetRecommendationTitle(profile);
        }

        this.computeDescription(content, profile);
      }

      return content;
    }
  },
  methods: {
    isExplainabilityEnabled() {
      return (
        Vue.options.filters.config_check(
          'feature.budget_planner.explainability.enable_external'
        ) || utils.internalUserCheck(window.user)
      );
    },
    findMatchingProfile(entity_id) {
      const profilesData = groupBy(
        this.mtdProfilesTooltipContent,
        'profile_id'
      );
      return profilesData?.[entity_id]?.[0] || null;
    },
    isBudgetRecommendationNeeded(profile) {
      return (
        profile.today_plan &&
        profile.yesterday_plan &&
        parseInt(profile.today_plan) - parseInt(profile.yesterday_plan) !== 0
      );
    },
    getBudgetRecommendationTitle(profile) {
      return {
        pre: 'We recommend',
        mid: `${
          parseInt(profile.today_plan) > parseInt(profile.yesterday_plan)
            ? 'increasing'
            : 'decreasing'
        } the daily budget of the profile to ${Vue.options.filters.num_format(
          profile.today_plan,
          'currency'
        )}`,
        suff: 'because of the following factors:'
      };
    },
    computeDescription(content, profile) {
      if (profile?.tooltip_description) {
        let desc = JSON.parse(profile.tooltip_description);
        desc = desc.filter((item) => item.length > 0);
        content.description = desc;
        content.show = desc.length > 0;
      }
    }
  }
};
</script>
