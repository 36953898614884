import HttpService from '@/utils/services/http-service';
import walmartActionsManagerRequestTemplate from '@/components/ams/campaign_creation/walmart_steps/walmartRequestTemplate.js';
import {
  formProductRequest,
  formUpdateProductRequest,
  formKeywordRequest,
  formUpdateKeywordRequest,
  formNegativeKeywordRequest,
  formUpdateNegativeKeywordRequest,
  formCreativeRequest,
  formUpdateCreativeRequest,
  formPlacementInclusionRequest,
  formPlacementBidMultiplierRequest,
  formPlatformBidMultiplierRequest
} from '@/components/ams/campaign_creation/walmart_steps/formRequestPayloadUtility.js';
import { cloneDeep } from 'lodash';
import {
  deepReplaceObjectValues,
  deepReplaceUnusedValuesWithNull
} from '@/pages/entity_details/common_components/helper.js';
import moment from 'moment-timezone';

function getCampaignBudgetType(totalBudget, dailyBudget) {
  if (totalBudget && dailyBudget) {
    return 'both';
  }
  if (!totalBudget && dailyBudget) {
    return 'daily';
  }
  if (totalBudget && !dailyBudget) {
    return 'total';
  }
  return null;
}
export class WalmartCampaignCreateDataService {
  campaignId;
  adGroupId;
  advertiserId;
  campaignType;
  retailer;

  constructor(campaignId, adGroupId, advertiserId, campaignType, retailer) {
    this.campaignId = parseInt(campaignId) || undefined;
    this.adGroupId = parseInt(adGroupId) || undefined;
    this.advertiserId = parseInt(advertiserId) || undefined;
    this.campaignType = campaignType || undefined;
    this.retailer = retailer || 'walmart';
  }

  // Campaign related API's start here
  fetchCampaignDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'CAMPAIGNS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  createCampaign(allStepsData, campaignState = 'paused') {
    let createCampaignRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'campaign_create',
      ':campaignType':
        allStepsData?.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : allStepsData?.campaignType,
      ':campaignName': allStepsData?.campaignName,
      ':campaignStartDate': moment(allStepsData?.campaignDates.start).format(
        'YYYY-MM-DD'
      ),
      ':campaignEndDate': allStepsData?.campaignDates?.end
        ? moment(allStepsData?.campaignDates.end).format('YYYY-MM-DD')
        : '9999-12-30',
      ':totalBudget': allStepsData?.totalBudget || null,
      ':dailyBudget': allStepsData?.dailyBudget || null,
      ':advertiserId': allStepsData?.advertiser?.advertiser_id,
      ':campaignState': campaignState,
      ':campaignId': null,
      ':campaignTargetingType': allStepsData?.targetingType || 'manual',
      ':campaignBudgetType': getCampaignBudgetType(
        allStepsData?.totalBudget,
        allStepsData?.dailyBudget
      )
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        createCampaignRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(createCampaignRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      createCampaignRequestObject
    );
  }

  updateCampaignDetails(campaignUpdateObject) {
    const updateCampaignDetailsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'campaign_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignName': campaignUpdateObject?.campaignName || null,
      ':campaignStartDate': campaignUpdateObject?.startDate || null,
      ':campaignEndDate': campaignUpdateObject?.endDate || null,
      ':totalBudget': campaignUpdateObject?.totalBudget || null,
      ':dailyBudget': campaignUpdateObject?.dailyBudget || null,
      ':advertiserId': this.advertiserId,
      ':campaignState': campaignUpdateObject?.campaignState || null,
      ':campaignId': this.campaignId,
      ':campaignTargetingType': campaignUpdateObject?.targetingType || null,
      ':campaignBudgetType': campaignUpdateObject?.budgetType || null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateCampaignDetailsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(updateCampaignDetailsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updateCampaignDetailsRequestObject
    );
  }

  launchCampaign() {
    const launchCampaignRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'launch_campaign',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        launchCampaignRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(launchCampaignRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      launchCampaignRequestObject
    );
  }
  // Campaign Related API's end here

  // Ad group related API's start here
  createAdGroup(allStepsData, campaignId) {
    const createAdGroupObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'adGroup_create',
      ':campaignType':
        allStepsData?.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : allStepsData?.campaignType,
      ':campaignId': campaignId,
      ':adGroupId': null,
      ':adGroupName': 'Ad Group 1',
      ':advertiserId': allStepsData?.advertiser?.advertiser_id
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(createAdGroupObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(createAdGroupObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      createAdGroupObject
    );
  }

  fetchAdGroupDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'ADGROUPS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  updateAdGroupDetails(adGroupUpdateObject) {
    const updateCampaignDetailsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'adGroup_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupUpdateObject?.adGroupName || null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateCampaignDetailsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(updateCampaignDetailsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updateCampaignDetailsRequestObject
    );
  }
  // Ad Group related API's end here

  // SKU related API's start here
  addSkus(skus = [], adGroupName = 'Test Ad Group') {
    const addSkusRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'sku_add',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(addSkusRequestObject, key, keyValuePairs[key]);
    }
    addSkusRequestObject.request.productRequest = formProductRequest(
      walmartActionsManagerRequestTemplate.productRequest,
      skus,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addSkusRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      addSkusRequestObject
    );
  }

  updateOrDeleteSkus(
    skus = [],
    adGroupName = 'Test Ad Group',
    isDelete = false
  ) {
    const addSkusRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'sku_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(addSkusRequestObject, key, keyValuePairs[key]);
    }
    addSkusRequestObject.request.productRequest = formUpdateProductRequest(
      walmartActionsManagerRequestTemplate.productRequest,
      skus,
      this.campaignId,
      this.adGroupId,
      isDelete
    );
    deepReplaceUnusedValuesWithNull(addSkusRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      addSkusRequestObject
    );
  }

  fetchSkusDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'SKUS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }
  // SKU related API's end here

  // Keywords related API's start here
  addKeywords(keywords = [], adGroupName) {
    const addKeywordsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'keyword_add',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        addKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    addKeywordsRequestObject.request.keywordRequest = formKeywordRequest(
      walmartActionsManagerRequestTemplate.keywordRequest,
      keywords,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addKeywordsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      addKeywordsRequestObject
    );
  }

  updateOrDeleteKeywords(keywords = [], adGroupName, isDelete = false) {
    const updateKeywordsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'keyword_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updateKeywordsRequestObject.request.keywordRequest =
      formUpdateKeywordRequest(
        walmartActionsManagerRequestTemplate.keywordRequest,
        keywords,
        this.campaignId,
        this.adGroupId,
        isDelete
      );
    deepReplaceUnusedValuesWithNull(updateKeywordsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updateKeywordsRequestObject
    );
  }

  fetchKeywordsDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'KEYWORDS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  // Keywords related API's end here

  // NegativeKeywords related API's stare here

  addNegativeKeywords(negativeKeywords, adGroupName) {
    negativeKeywords = negativeKeywords || [];
    const addNegativeKeywordsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'negative_keyword_add',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        addNegativeKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    addNegativeKeywordsRequestObject.request.negativeKeywordRequest =
      formNegativeKeywordRequest(
        walmartActionsManagerRequestTemplate.negativeKeywordRequest,
        negativeKeywords,
        this.campaignId,
        this.adGroupId
      );
    deepReplaceUnusedValuesWithNull(addNegativeKeywordsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      addNegativeKeywordsRequestObject
    );
  }

  updateOrDeleteNegativeKeywords(negativeKeywords, adGroupName, isDelete) {
    negativeKeywords = negativeKeywords || [];
    isDelete = isDelete || false;
    const updateNegativeKeywordsRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'negative_keyword_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateNegativeKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updateNegativeKeywordsRequestObject.request.negativeKeywordRequest =
      formUpdateNegativeKeywordRequest(
        walmartActionsManagerRequestTemplate.negativeKeywordRequest,
        negativeKeywords,
        this.campaignId,
        this.adGroupId,
        isDelete
      );
    deepReplaceUnusedValuesWithNull(updateNegativeKeywordsRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updateNegativeKeywordsRequestObject
    );
  }

  fetchNegativeKeywordsDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'NEGATIVE_KEYWORDS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }
  // Negative Keywords related API's end here

  // Profile related API's start here
  fetchProfileDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: this.adGroupId,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'SBA_PROFILES'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  addProfiles(profiles = []) {
    const addProfilesRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'profile_add',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        addProfilesRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    addProfilesRequestObject.request.creativeRequest = formCreativeRequest(
      walmartActionsManagerRequestTemplate.creativeRequest,
      profiles,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addProfilesRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      addProfilesRequestObject
    );
    // profile_add``
  }

  updateProfiles(profiles = []) {
    const updateProfilesRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'profile_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateProfilesRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updateProfilesRequestObject.request.creativeRequest =
      formUpdateCreativeRequest(
        walmartActionsManagerRequestTemplate.creativeRequest,
        profiles,
        this.campaignId,
        this.adGroupId
      );
    deepReplaceUnusedValuesWithNull(updateProfilesRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updateProfilesRequestObject
    );
  }

  uploadProfileImage(file, profileId) {
    const formData = new FormData();
    formData.append('multipartFile', file);
    return HttpService.post('UPLOAD_PROFILE_AMS_ACTION_MANAGER', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        campaignId: this.campaignId,
        adGroupId: this.adGroupId,
        sbaProfileId: profileId
      }
    });
  }
  // Profile related API' end here

  // #region Placement Inclusion Related API's start here
  fetchPlacementInclusionDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'PLACEMENTS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  updatePlacementInclusion(placementInclusionObject) {
    const updatePlacementInclusionRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'placement_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updatePlacementInclusionRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updatePlacementInclusionRequestObject.request.campaignPlacementRequest =
      formPlacementInclusionRequest(
        walmartActionsManagerRequestTemplate.placementInclusionInnerRequest,
        placementInclusionObject,
        this.campaignId
      );
    deepReplaceUnusedValuesWithNull(updatePlacementInclusionRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updatePlacementInclusionRequestObject
    );
  }
  // #endregion Placement Inclusion Related API's start here

  // #region Placement Bid Multiplier Related API's start here
  fetchPlacementBidMultiplierDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'PLACEMENT_BIDMULTIPLIER'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  updatePlacementBidMultiplier(placementBidMultiplierObject) {
    const updatePlacementBidMultiplierRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'placement_bid_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updatePlacementBidMultiplierRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updatePlacementBidMultiplierRequestObject.request.placementBidMultiplierRequest =
      formPlacementBidMultiplierRequest(
        walmartActionsManagerRequestTemplate.placementBidMultiplierRequest,
        placementBidMultiplierObject,
        this.campaignId
      );
    deepReplaceUnusedValuesWithNull(updatePlacementBidMultiplierRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updatePlacementBidMultiplierRequestObject
    );
  }
  // #endregion Placement Bid Multiplier related API's end here

  // #region Platform Bid Multiplier related API's start here
  fetchPlatformBidMultiplierDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'PLATFORM_BIDMULTIPLIER'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  updatePlatformBidMultiplier(platformBidMultiplierObject) {
    const updatePlatformBidMultiplierRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'platform_bid_update',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updatePlatformBidMultiplierRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    updatePlatformBidMultiplierRequestObject.request.platformBidMultiplierRequest =
      formPlatformBidMultiplierRequest(
        walmartActionsManagerRequestTemplate.platformBidMultiplierRequest,
        platformBidMultiplierObject,
        this.campaignId
      );
    deepReplaceUnusedValuesWithNull(updatePlatformBidMultiplierRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      updatePlatformBidMultiplierRequestObject
    );
  }
  // #endregion Platform Bid Multiplier related API's end here

  // Review related API's start here
  submitForReview() {
    // submit_review
    const submitForReviewRequestObject = cloneDeep(
      walmartActionsManagerRequestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'submit_review',
      ':campaignType':
        this.campaignType === 'sponsoredBrands'
          ? 'searchBrandAmplifier'
          : this.campaignType,
      ':campaignId': this.campaignId,
      ':adGroupId': this.adGroupId,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        submitForReviewRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(submitForReviewRequestObject);
    return HttpService.post(
      'CREATE_CAMPAIGN_AMS_ACTION_MANAGER',
      submitForReviewRequestObject
    );
  }
  // Review related API's end here
}
