export default {
  // 'filters': {
  //   search: {
  //     enable: true,
  //     emit: 'overviewSearchTriggered',
  //     placeholder: 'Search for a ASIN'
  //   },
  //   emit: 'overviewFilterApplied',
  //   listen: {
  //     'overviewFilterApplied': {
  //       action: 'updateOverviewFilters'
  //     }
  //   },
  //   'left': [{
  //     title: 'Product Group',
  //     filterValueKey: 'overview_product_group',
  //     smart: true
  //   }, {
  //     title: 'Category',
  //     subFilterValueKey: 'overview_subcategory',
  //     filterValueKey: 'overview_category',
  //     twoLevel: true,
  //     smart: true
  //   }, {
  //     title: 'Brand',
  //     filterValueKey: 'overview_brand',
  //     smart: true
  //   }],
  //   'right': [{
  //     title: 'Date Range',
  //     filterValueKey: 'date_range'
  //   }]
  // },
  filters: {
    emit: 'overviewFilterApplied',
    hasSearch: true,
    search: {
      enable: true,
      emit: 'overviewSearchTriggered',
      placeholder: 'Search for a ASIN'
    }
  },
  widgets: [
    {
      type: 'line',
      map: {
        ordered_revenue: 'Retailer Revenue',
        ordered_cogs: 'Revenue'
      },
      getHeader1Data(data) {
        return {
          tag1: data.RESULT.ordered_cogs,
          tag2: data.PVP.PVP_ordered_cogs
        };
      },
      date: 'sales_by_account_type',
      header1: {
        title: 'Revenue',
        tooltip: 'Units ordered in this period multiplied by unit COGS',
        tag1Unit: {
          pre: 'currency'
        },
        tag2Unit: {
          suff: '%'
        }
      },
      getHeader2Data(data) {
        return {
          tag1: data.RESULT.ordered_revenue,
          tag2: data.PVP.PVP_ordered_revenue
        };
      },
      header2: {
        title: 'Retailer Revenue',
        tooltip: 'Units ordered in this period multiplied by ASP',
        tag1Unit: {
          pre: 'currency'
        },
        tag2Unit: {
          suff: '%'
        }
      },
      chartDataKey: 'revenueData',
      action: 'getRevenueData',
      chartOptions: {
        xFormat: '%m/%d/%Y',
        timeseries: 'feed_date',
        tooltip_format: {
          Revenue: {
            pre: 'currency'
          },
          'Retailer Revenue': {
            pre: 'currency'
          }
        },
        axis_format: {
          y: {
            pre: 'currency'
          }
        },
        grid: 'xy'
      },
      APIConfig: {
        cubeName: 'client_catalog_data',
        measuresList: ['ordered_cogs', 'ordered_revenue'],
        groupByDimensionsList: [],
        timeseriesDimension: 'FEED_DATE',
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'asin'
          ]
        },
        orderByList: []
      }
    },
    {
      type: 'line',
      date: 'sales_by_account_type',
      chartDataKey: 'marginData',
      map: {
        gross_ordered_margin: 'Retailer Margin',
        gross_ordered_margin_perc: 'Margin %'
      },
      getHeader1Data(data) {
        return {
          tag1: data.RESULT.gross_ordered_margin,
          tag2: data.PVP.PVP_gross_ordered_margin
        };
      },
      getHeader2Data(data) {
        var tag2 = null;
        if (
          data.PVP.gross_ordered_margin_perc !== null &&
          data.RESULT.gross_ordered_margin_perc !== null
        ) {
          tag2 =
            data.RESULT.gross_ordered_margin_perc -
            data.PVP.gross_ordered_margin_perc;
        }
        return {
          tag1: data.RESULT.gross_ordered_margin_perc,
          tag2: tag2
        };
      },
      header1: {
        title: 'Retailer Margin',
        tooltip:
          'Gross margin calculated as Units ordered during this period multiplied by difference between offer price and COGS. Change is from previous period',
        tag1Unit: {
          pre: 'currency'
        },
        tag2Unit: {
          suff: '%'
        }
      },
      header2: {
        title: 'Margin Percentage',
        tooltip:
          'Margin percentage is calculated as a percent of retailer revenue i.e. gross margin divided by units ordered into offer price. Change from previous period is absolute',
        tag1Unit: {
          suff: '%'
        },
        tag2Unit: {}
      },
      action: 'getMarginData',
      chartOptions: {
        xFormat: '%m/%d/%Y',
        timeseries: 'feed_date',
        axes: {
          'Retailer Margin': 'y',
          'Margin %': 'y2'
        },
        tooltip_format: {
          'Retailer Margin': {
            pre: 'currency'
          },
          'Margin %': {
            suff: '%'
          }
        },
        axis_format: {
          y: {
            pre: 'currency'
          },
          y2: {
            suff: '%'
          }
        },
        grid: 'xy'
      },
      APIConfig: {
        cubeName: 'client_catalog_data',
        measuresList: ['gross_ordered_margin', 'gross_ordered_margin_perc'],
        groupByDimensionsList: [],
        timeseriesDimension: 'FEED_DATE',
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'asin'
          ]
        }
      }
    },
    {
      widgetId: 'revenue-lost-oos',
      type: 'line',
      chartDataKey: 'oosData',
      date: 'sales_by_account_type',
      getHeader1Data(data) {
        return {
          tag1: data.RESULT.revenue_lost_unavailable,
          tag2: data.PVP.PVP_revenue_lost_unavailable
        };
      },
      // header1: {
      //   title: 'Out Of Stock',
      //   tooltip: 'Sum of count of OOS SKUs on each day as shown in the graph',
      //   tag1Unit: {
      //     format: false
      //   },
      //   tag2Unit: {
      //     suff: '%'
      //   }
      // },
      header1: {
        title: 'Revenue Lost due to OOS',
        tooltip:
          'Revenue lost due to OOS is calculated by SKU velocity prior to going OOS multiplied by COGS for the duration of OOS',
        tag1Unit: {
          pre: 'currency'
        },
        tag2Unit: {
          suff: '%'
        },
        tag2: {
          invert: true
        }
      },
      map: {
        revenue_lost_unavailable: 'Revenue Lost',
        no_of_skus: 'SKU Count'
      },
      action: 'getOOSData',
      chartOptions: {
        xFormat: '%m/%d/%Y',
        timeseries: 'feed_date',
        axes: {
          'SKU Count': 'y2',
          'Revenue Lost': 'y'
        },
        tooltip_format: {
          'SKU Count': {
            format: false
          },
          'Revenue Lost': {
            pre: 'currency'
          }
        },
        axis_format: {
          y: {
            pre: 'currency'
          }
        },
        grid: 'xy'
      },
      APIConfig: {
        cubeName: 'oos_skus_v2',
        measuresList: ['no_of_skus', 'revenue_lost_unavailable'],
        groupByDimensionsList: [],
        timeseriesDimension: 'feed_date',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: true,
        pvpenabled: true,
        yoyenabled: false,
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'asin'
          ]
        }
      }
    }
  ]
};
