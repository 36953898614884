<template>
  <div class="u-display-flex u-width-100 u-height-100 u-flex-direction-column">
    <div
      class="u-display-flex u-spacing-ph-l u-bg-color-grey-white sticky u-header-box-shadow u-spacing-mb-0"
      style="z-index: 100"
    >
      <modal
        v-if="showPopUp"
        :modal-config="modalConfig"
        @clickedOutside="clickedOutsideModal"
      />
      <div class="u-flex-direction-column u-flex-grow-1 whiteBgTags">
        <rb-filter-panel
          :data="filterData"
          :primary="primaryFilterData"
          :secondary-data="secondaryFilterData"
          :listener-event="'dw-filters'"
          :state="filterState"
          :loader-status="loaderStatus"
          :config="config.filters"
          :emit="config.filters.emit"
          :has-search="config.filters.hasSearch"
          :new-date="true"
          :max-date="getSkusPageWiseMinMaxKey"
          :apply-filter="applyFilterdw"
          :get-filter-instance="getFilterInstance"
          :tags-title-key="'asin'"
          :show-ingestion-progress="filterV2"
          :filter-v2="filterV2"
          :default-date-post-range="defaultDatePostRange"
          :additional-ranges="additionalRanges"
          :date-mode="dateMode"
          :on-create="onCreate"
        >
          <div
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
          >
            <PageTitle
              :is-bookmark-require-title="true"
              :title="
                tableGroupFilters &&
                tableGroupFilters.tabList &&
                tableGroupFilters.tabList[getActiveTab]
              "
            />
          </div>
          <span
            slot="search"
            class="u-display-flex u-flex-align-items-center"
          >
            <rb-select
              :width="'240px'"
              :search-enable-threshold="1000"
              :class="'is-bottom-left'"
              :on-close="searchFromOption"
              :send-details="true"
              :options="searchListOption"
            >
              <template
                slot="item"
                slot-scope="option"
              >
                <div>
                  <div class="u-display-flex u-flex-align-items-center">
                    <div
                      style="height: 48px; width: 48px; margin: 0px"
                      class="media__img u-flex-none"
                    >
                      <img
                        style="height: 48px; width: 48px; margin: 0px"
                        :src="option.latest_image_url"
                        alt="product_url"
                      />
                    </div>
                    <div class="u-spacing-ml-m u-overflow-hidden">
                      <p
                        class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                      >
                        {{ option.asin_name }}
                      </p>
                      <p
                        class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
                      >
                        {{ option.asin }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <form
                slot="trigger"
                class="u-position-relative searchForm"
                @submit.prevent="filteredList"
              >
                <rb-input
                  v-model="searchTerm"
                  :placeholder="'Search ASIN or ASIN Name'"
                  class="search__input u-border-left-none"
                  :rounded="false"
                  @input="getSearchList"
                />
                <span
                  class="u-position-absolute u-display-flex"
                  style="top: 0px; left: 8px; bottom: 0; margin: auto"
                  @click.stop.prevent="filteredList"
                >
                  <rb-icon
                    icon="search"
                    class="search__input-icon rb-icon--medium"
                  />
                </span>
              </form>
            </rb-select>
          </span>
          <div slot="download">
            <div
              v-if="
                getFeatureEnableConfig &&
                getFeatureEnableConfig.download &&
                getFeatureEnableConfig.download.enable
              "
              class="u-display-flex u-position-relative u-spacing-pl-s"
            >
              <rb-tooltip
                :label="$t('table_download_tooltip')"
                position="is-left"
              >
                <rb-button
                  :disabled="tableData.length <= 0"
                  :type="'icon'"
                  :click-fn="downloadTableData"
                  :loading="istableDownloadDataLoader"
                  :icon-right="istableDownloadDataLoader ? '' : 'download'"
                />
              </rb-tooltip>
            </div>
          </div>
        </rb-filter-panel>
        <ActiveFilterBanner
          v-if="showActiveFilterBanner"
          icon-name="info-circle-fill"
          :filter-instance="filterInstance"
          :force-apply-filter="true"
          :apply-negative-margin="true"
        />
      </div>
    </div>
    <split-container :master-width="150">
      <div
        slot="master"
        class="u-height-100"
      >
        <p
          class="u-display-flex u-spacing-pt-l u-spacing-pl-m u-spacing-pb-m u-font-size-8 u-text-transform-uppercase u-font-weight-600 u-color-grey-lighter"
        >
          View Columns
        </p>
        <rb-tabs
          v-model="getActiveTab"
          :type="'vertical-nav'"
          :tab-list="tableGroupFilters.tabList"
          class="u-height-100"
          @input="setColumnFilterFromLeftPanel"
        />
      </div>
      <div slot="detail">
        <div
          v-if="WBTableDataLoader"
          class="loader-block u-position-relative u-height-100 u-bg-color-grey-white"
        >
          <Loader
            :loading="WBTableDataLoader"
            class="loader--expand"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </div>
        <div
          v-show="!WBTableDataLoader"
          class="u-display-flex u-height-100 u-flex-direction-column dataWBTableWrapper"
        >
          <rb-insights-table
            ref="sku-dw-table"
            class="u-display-flex u-flex-direction-column"
            :custom-height="false"
            :config="tableConfig"
            :grid-options="gridOptions"
            :table-row="tableData"
            :table-column="localTableColumnsProps"
            :get-current-instance="getCurrentSourceInstance"
            :row-height="80"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :sorting-change-event="'SKUsSortChange'"
            :pagination="true"
            :pagination-total-key="WBTableDataPagination.total"
            :pagination-per-page-limit="WBTableDataPagination.limit"
            :pagination-change-event="'SKUsPageChange'"
            :download-this-table="true"
            :no-rows-message="'No Results.'"
          />
          <span
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between card-footer u-spacing-pv-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s u-color-grey-lighter u-font-size-7 u-bg-color-grey-white"
            >{{ getMaxDateMessage() }}
            <MetricRefreshDatesView
              class="u-spacing-ml-s"
              refresh-page-id="SKUs"
            />
          </span>
        </div>
      </div>
    </split-container>
  </div>
</template>

<script>
import constants from '@/utils/constants';
import Loader from '@/components/basic/loader.vue';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import localSelectFilter from '@/components/basic/localSelectFilter.vue';
import FnConfigs from './functionConfigs';
import UIConfigs from './uiConfigs';
import Vue from 'vue';
import splitContainer from '@/components/layouts/splitContainer';
import widgetsUtil from '@/components/widgetsMixin';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import ColMap from './columnsMap';
import widgets1 from './widgets';
import { eventBus } from '@/utils/services/eventBus';
import modal from '@/components/widgets/modal.vue';
import MetricRefreshDatesView from '@/components/basic/metric-refresh-dates';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import { createDownloadFileFromBlob } from '@/utils/helpers/downloader';

const debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

let that = null;

const page = {
  components: {
    Loader,
    splitContainer,
    localDateFilter,
    localSelectFilter,
    rbFilterPanel,
    modal,
    MetricRefreshDatesView
  },
  mixins: [widgetsUtil],
  data() {
    return {
      localTableColumnsProps: [],
      showPopUp: false,
      searchHistoryKey: 'workbenchSearchHistory',
      dropdownKeys: {
        HISTORY_DROPDOWN: 'HISTORY_DROPDOWN',
        TAGS_DROPDOWN: 'TAGS_DROPDOWN',
        BUFFERED_TAGS_DROPDOWN: 'BUFFERED_TAGS_DROPDOWN',
        SUGGESTIONS_DROPDOWN: 'SUGGESTIONS_DROPDOWN'
      },
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      config1: {},
      columnPropsArr: [],
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      rbTableSourceInstance: null,
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        }
      },
      tableGroupFilters: {
        activeTab: 0,
        tabList: [
          this.$t('all'),
          this.$t('catalog'),
          this.$t('price_&_promotions'),
          this.$t('sales_&_margin'),
          this.$t('operations'),
          this.$t('search_&_bsr'),
          this.$t('content'),
          this.$t('competition')
        ]
      },
      filterState: {
        getter: 'getDWSelectedFilter',
        setter: 'setDWSelectedFilter'
      },
      tableFilter: {
        activeTab: 0,
        tabList: ['All', ...constants.columnFilter]
      },
      visibleColumnGroup: 'All',
      tableColumns: [],
      currentPage: 1,
      measureListTable: FnConfigs.measureListTableData,
      workbenchDownload: FnConfigs.workbenchDownloadData,
      workbenchTableSearchData: FnConfigs.workbenchTableSearchData,
      dateRangeObj: {},
      searchTerm: '',
      tableHeight: '',
      filteredTableData: [],
      previousSearchedAsin: {},
      dateRange: this.$store.getters.getDateRangeValues,
      compColGroups: [
        'comp_sku1',
        'comp_sku2',
        'comp_sku3',
        'comp_sku4',
        'comp_sku5',
        'comp_sku1_product_url',
        'comp_sku2_product_url',
        'comp_sku3_product_url',
        'comp_sku4_product_url',
        'comp_sku5_product_url',
        'comp_sku1_title',
        'comp_sku2_title',
        'comp_sku3_title',
        'comp_sku4_title',
        'comp_sku5_title'
      ],
      colGroupTypes: [
        'CATALOG',
        'PRICE&PROMOTIONS',
        'SALES&MARGIN',
        'OPERATIONS',
        'SEARCH&BSR',
        'CONTENT',
        'COMPETITION'
      ],
      configs: this.$store.getters.getFeatureEnableConfig,
      dwColumnsToShow: [],
      colMap: ColMap,
      vvue: Vue,
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      istableDownloadDataLoader: false,
      defaultDatePostRange: 'last7Days',
      dateMode: {
        mode: 'week',
        count: 0
      },
      additionalRanges: {
        add: ['last7Days', 'last4Week', 'last13Week', 'last52Week'],
        order: {
          last7Days: 0,
          last4Week: 2,
          last13Week: 3,
          last52Week: 4
        }
      },
      filterInstance: null,
      showActiveFilterBanner: false
    };
  },
  beforeCreate() {
    this.config = UIConfigs;
  },
  beforeRouteUpdate(to, from, next) {
    this.selectTab(to);
    next();
  },
  created() {
    this.fetchFilters('client_workbench_full_catalogue_latest_comp_data');
    this.verifyURL();
    that = this;
    this.$store.dispatch('setAppActiveTab', 0);
    this.config1 = widgets1.config(this);
    // Add Direct Fulfilment Inventory Column to table based on a config
    if (Vue.options.filters.config_check('feature.direct_fulfilment.enable')) {
      if (this.measureListTable.params.measuresList.indexOf('stock') === -1) {
        this.measureListTable.params.measuresList.push('stock');
      }
      if (this.workbenchDownload.params.measuresList.indexOf('stock') === -1) {
        this.workbenchDownload.params.measuresList.push('stock');
      }
      if (this.colMap.columnArray.indexOf('stock') === -1) {
        this.colMap.columnArray.push('stock');
      }
    }

    /*
     * Disable No.of days suppressed, No.of days unavailable, No.of days oos
     * Add No.of days unavailable
     */
    if (
      Vue.options.filters.config_check(
        'feature.merge_unavailable_recommendation.enable'
      )
    ) {
      // remove columns from sales & Margin Tab
      const keysToRemove = ['suppressed_days', 'oos_days', 'unavl_days'];
      keysToRemove.forEach((item) => {
        if (this.measureListTable.params.measuresList.indexOf(item) > -1) {
          this.measureListTable.params.measuresList.splice(
            this.measureListTable.params.measuresList.indexOf(item),
            1
          );
        }
        if (this.workbenchDownload.params.measuresList.indexOf(item) > -1) {
          this.workbenchDownload.params.measuresList.splice(
            this.workbenchDownload.params.measuresList.indexOf(item),
            1
          );
        }
        if (this.colMap.columnArray.indexOf(item) > -1) {
          this.colMap.columnArray.splice(
            this.colMap.columnArray.indexOf(item),
            1
          );
        }
      });
      // Add the new key for No.of days unavailable
      if (
        this.measureListTable.params.measuresList.indexOf(
          'unavailable_merged_days'
        ) === -1
      ) {
        this.measureListTable.params.measuresList.push(
          'unavailable_merged_days'
        );
      }
      if (
        this.workbenchDownload.params.measuresList.indexOf(
          'unavailable_merged_days'
        ) === -1
      ) {
        this.workbenchDownload.params.measuresList.push(
          'unavailable_merged_days'
        );
      }
      if (this.colMap.columnArray.indexOf('unavailable_merged_days') === -1) {
        this.colMap.columnArray.push('unavailable_merged_days');
      }
    }
  },
  mounted() {
    const that = this;
    eventBus.$on('SKUsPageChange', function (page) {
      that.pageChanged(page);
    });
    eventBus.$on('SKUsSortChange', function (column) {
      that.sortDWTable(column);
    });
  },
  destroyed() {
    eventBus.$off('SKUsPageChange');
    eventBus.$off('SKUsSortChange');
  },
  computed: {
    getFeatureEnableConfig() {
      return (this.$store.getters.getFeatureEnableConfig || {}).dw;
    },
    tableData() {
      const tableDataLeft = this.$store.getters.measureListTableData || [];
      const tableData = [];
      if (tableDataLeft.length > 0) {
        for (let i = 0; i < tableDataLeft.length; i++) {
          if (
            tableDataLeft[i].package_dimension_value &&
            tableDataLeft[i].dimension_unit
          ) {
            tableDataLeft[
              i
            ].package_dimension_value = `${tableDataLeft[i].package_dimension_value} ${tableDataLeft[i].dimension_unit}`;
          } else {
            tableDataLeft[i].package_dimension_value = '';
          }
          if (tableDataLeft[i]?.unit_weight && tableDataLeft[i].weight_unit) {
            tableDataLeft[i].unit_weight = `${
              parseFloat(tableDataLeft[i]?.unit_weight).toFixed(2) || ''
            } ${tableDataLeft[i].weight_unit || ''}`;
          } else {
            tableDataLeft[i].unit_weight = '';
          }
          tableData.push(tableDataLeft[i]);
        }
      }
      this.filteredTableData = tableData;
      if (tableData.length === 0) {
        return [];
      }
      return tableData;
    },
    tableDownloadDataLoader() {
      return this.$store.getters.workbenchDownloadDataLoader;
    },
    WBTableDataLoader() {
      return this.$store.getters.measureListTableDataLoader;
    },
    WBTableDataPagination() {
      const obj = {
        total: this.$store.getters.measureListTableDataPaginationTotal,
        limit: this.measureListTable.params.limit
      };
      return obj;
    },
    getActiveTab: {
      get() {
        return this.$store.getters.getDataWorkbenchActiveTabIndex;
      },
      set(index) {
        this.$store.dispatch('setDataWorkbenchActiveTab', index);
      }
    },
    tableColumnsProps() {
      const indexToColumnsMapping = {
        0: 'All',
        1: 'Catalog',
        2: 'PriceAndPromotions',
        3: 'SalesAndMargin',
        4: 'Operations',
        5: 'SearchAndBSR',
        6: 'Content',
        7: 'Competition'
      };

      if (Object.keys(this.config1).length === 0) {
        return [];
      }
      const currentTabIndex =
        this.$store.getters.getDataWorkbenchActiveTabIndex;
      this.columnPropsArr = [
        ...this.config1.allTabsTableColDef.Common,
        ...this.config1.allTabsTableColDef[
          indexToColumnsMapping[currentTabIndex]
        ]
      ];
      // concat all 8 tabs for All tab
      if (currentTabIndex === 0) {
        this.columnPropsArr = [
          ...this.config1.allTabsTableColDef.Common,
          ...this.config1.allTabsTableColDef.Catalog,
          ...this.config1.allTabsTableColDef.PriceAndPromotions,
          ...this.config1.allTabsTableColDef.SalesAndMargin,
          ...this.config1.allTabsTableColDef.Operations,
          ...this.config1.allTabsTableColDef.SearchAndBSR,
          ...this.config1.allTabsTableColDef.Content,
          ...this.config1.allTabsTableColDef.Competition,
          ...this.config1.allTabsTableColDef.All
        ];
      }

      if (this.gridOptions.api) {
        // clear the previously maintained column definitions by the ag-grid
        this.gridOptions.api.setColumnDefs([]);
      }
      return this.columnPropsArr;
    },
    searchListData() {
      return {
        load: this.searchListOptionLoader,
        rows: this.searchListOption,
        noData: this.searchListOption.length === 0,
        error: false
      };
    },
    searchListOption() {
      return this.$store.getters.workbenchTableSearchData || [];
    },
    searchListOptionLoader() {
      return this.$store.getters.workbenchTableSearchDataLoader || true;
    },
    getSKUsWhereClause() {
      return this.$store.getters.getSKUsWhereClause;
    },
    getSkusPageWiseMinMaxKey() {
      return this.$store.getters.getSkusPageWiseMinMaxKey;
    }
  },
  methods: {
    onCreate(instance) {
      this.filterInstance = instance;
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    filteredList(searchString) {
      this.applyFilterdw(this.searchTerm);
    },

    setColumnFilter(col) {
      this.visibleColumnGroup = col;
    },

    setColumnFilterFromLeftPanel(index) {
      this.visibleColumnGroup = this.tableGroupFilters.tabList[index];
      this.$store.dispatch('setDataWorkbenchActiveTab', index);
      this.$router.push(
        '/workbench/skus/' +
          encodeURIComponent(this.tableGroupFilters.tabList[index])
      );
    },

    pageChanged(pageNumber) {
      this.measureListTable.params.page = pageNumber;
      this.getTableData();
    },

    getTableData() {
      this.$store.dispatch(
        this.measureListTable.storeActionMethod,
        this.measureListTable
      );
    },
    getColumnToDownload() {
      const indexToColumnsMapping = {
        0: 'All',
        1: 'Catalog',
        2: 'PriceAndPromotions',
        3: 'SalesAndMargin',
        4: 'Operations',
        5: 'SearchAndBSR',
        6: 'Content',
        7: 'Competition'
      };

      let columnsToDownload = [];
      const currentTabIndex =
        this.$store.getters.getDataWorkbenchActiveTabIndex;
      const rawColumns = this.colMap.getTableColumnsFn();

      // Add Direct Fulfilment Inventory Column to the download for Operations and all tabs
      if (
        Vue.options.filters.config_check('feature.direct_fulfilment.enable')
      ) {
        if (
          this.config1.checkArray(rawColumns.Operations, 'dbKey', 'stock') ===
          false
        ) {
          rawColumns.Operations.splice(1, 0, {
            displayName: 'Direct Fulfilment Inventory',
            dbKey: 'stock',
            keyType: 'numeric',
            group: 'Operations',
            cIndex: 3,
            formatValue: true,
            prefix: '',
            suffix: '',
            toolTipText: 'Units available for direct fulfillment'
          });
        }
      }

      columnsToDownload = [
        ...rawColumns.Common,
        ...rawColumns[indexToColumnsMapping[currentTabIndex]]
      ];

      // concat all 8 tabs for All tab
      if (currentTabIndex === 0) {
        columnsToDownload = [
          ...rawColumns.Common,
          ...rawColumns.Catalog,
          ...rawColumns.PriceAndPromotions,
          ...rawColumns.SalesAndMargin,
          ...rawColumns.Operations,
          ...rawColumns.SearchAndBSR,
          ...rawColumns.Content,
          ...rawColumns.Competition,
          ...rawColumns.All
        ];
      }

      return columnsToDownload;
    },
    downloadTableData() {
      if (this.tableDownloadDataLoader || this.tableData.length <= 0) {
        return;
      }
      delete this.workbenchDownload.params.limit;
      delete this.workbenchDownload.params.page;
      const payloadConfig = cloneDeep(this.workbenchDownload);
      payloadConfig.params.measuresList = payloadConfig.params.measuresList.map(
        (item) => item.toUpperCase()
      );
      const rawColumn = this.getColumnToDownload();
      const finalMeasure = ['scrape_date', 'ordered_revenue'];
      rawColumn.forEach((item) => {
        if (
          payloadConfig.params.measuresList.includes(
            item.dbKey?.toUpperCase()
          ) &&
          !finalMeasure.includes(item.dbKey)
        ) {
          finalMeasure.push(item.dbKey);
        }
      });
      payloadConfig.params.measuresList = finalMeasure;
      const activeTab = this.tableGroupFilters.tabList[this.getActiveTab];
      const actionType =
        payloadConfig.storeActionType.toUpperCase() === 'POST' ? 'post' : 'get';
      this.istableDownloadDataLoader = true;
      if (typeof Worker === 'undefined') {
        payloadConfig.params.limit = 30000;
      }
      HttpService[actionType](
        payloadConfig.apiConstant,
        payloadConfig.params,
        payloadConfig.headers
      )
        .then((response) => {
          if (response.data.success === true) {
            const newData = response.data.response.data;
            newData.forEach((ele) => {
              if (ele?.RESULT?.unit_weight) {
                ele.RESULT.unit_weight = parseFloat(
                  ele.RESULT.unit_weight.toFixed(2)
                );
                // (ele.RESULT.unit_weight == 0 ) ? ele.RESULT.unit_weight =  'NA' :
                // ele.RESULT.unit_weight = parseFloat(ele.RESULT.unit_weight.toFixed(2));
              }
            });
            if (typeof Worker === 'undefined') {
              console.log('web worker is not supported');
              const blob = transformer.generateBlob(newData, rawColumn);
              const fileName = 'SKUs-' + activeTab.replace(/ /g, '') + '.csv';
              createDownloadFileFromBlob(blob, fileName);
            } else {
              console.log('Downloading via - web worker');
              this.$worker
                .run(transformer.generateBlob, [newData, rawColumn])
                .then((blob) => {
                  console.log('blob generated');
                  const fileName =
                    'SKUs-' + activeTab.replace(/ /g, '') + '.csv';
                  createDownloadFileFromBlob(blob, fileName);
                })
                .catch(() => {
                  console.log('download error');
                  this.$snackbar.open({
                    message: 'Something went wrong.',
                    duration: 10000,
                    buttonColor: '#f5d908',
                    actionText: ''
                  });
                })
                .finally(() => {
                  this.istableDownloadDataLoader = false;
                });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.istableDownloadDataLoader = false;
          this.$snackbar.open({
            message: 'Something went wrong.',
            duration: 10000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        });
    },
    getInitalLocalStorage() {
      let searchHistory = localStorage.getItem(this.searchHistoryKey);
      if (searchHistory === undefined || searchHistory === null) {
        searchHistory = '[]';
      }
      return searchHistory;
    },
    overwriteLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    createTextForHistoryDropdown(activeTags) {
      const textArray = [];
      if (activeTags.length <= 2) {
        return (activeTags.map((item) => item.title) || []).join(', ');
      } else {
        textArray.push(activeTags[0].title);
        textArray.push(activeTags[1].title);
      }
      return textArray.join(', ') + ` & ${activeTags.length - 2} more`;
    },
    applyFilterdw(searchBySku, fromSearch) {
      const where = transformer.transformWhereClauseWithTag(
        this.$store.getters.getDWDataWhereClause
      );
      let dimensionNameValueList = [].concat(where.dimensionNameValueList);
      this.measureListTable.params.where = where;
      this.workbenchDownload.params.where = where;
      this.measureListTable.params.tagWhereClause = where.tagWhereClause || [];
      this.workbenchDownload.params.tagWhereClause = where.tagWhereClause || [];
      delete this.measureListTable.params.where.tagWhereClause;
      delete this.workbenchDownload.params.where.tagWhereClause;
      // for (var i = 0; i < dimensionNameValueList.length; i++) {
      //   if (dimensionNameValueList[i].hasOwnProperty('operator') || dimensionNameValueList[i].dimensionName === 'asin') {
      //     dimensionNameValueList.splice(i, 1);
      //   }
      // }
      dimensionNameValueList = dimensionNameValueList.filter((item) => {
        return !(
          item.hasOwnProperty('operator') || item.dimensionName === 'asin'
        );
      });
      for (let i = 0; i < dimensionNameValueList.length; i++) {
        if (
          dimensionNameValueList[i].hasOwnProperty('operator') ||
          dimensionNameValueList[i].dimensionName === 'asin'
        ) {
          dimensionNameValueList.splice(i, 1);
        }
      }

      if (
        searchBySku !== undefined &&
        searchBySku.constructor === Object &&
        fromSearch
      ) {
        dimensionNameValueList.push(searchBySku);
      } else if (
        searchBySku !== undefined &&
        searchBySku.constructor === String
      ) {
        const searchAsin = {
          dimensionName: 'search',
          dimensionValue: '',
          operator: 'ILIKE'
        };
        if (this.searchTerm !== '') {
          searchAsin.dimensionValue = this.searchTerm;
          dimensionNameValueList.push(searchAsin);
        }
      }
      // If Applying date filters after a search.
      if (!searchBySku && !fromSearch && this.searchTerm !== '') {
        dimensionNameValueList.push({
          dimensionName: 'search',
          dimensionValue: this.searchTerm,
          operator: 'ILIKE'
        });
      }
      const activeTags =
        this.filterInstance?.tagsAndSuggestionsInstance?.localActiveTags || [];
      if (activeTags.length) {
        for (let i = 0; i < activeTags.length; i++) {
          dimensionNameValueList.push({
            dimensionName: 'search',
            dimensionValue: activeTags[i].title,
            operator: 'ILIKE'
          });
        }
        const searchHistory = this.getInitalLocalStorage();
        const localSearchHistory = JSON.parse(searchHistory);
        if (localSearchHistory.length > 10) {
          localSearchHistory.pop();
        }
        const dropdownText = this.createTextForHistoryDropdown(activeTags);
        const payload = [
          { type: 'tag', dropdownText, activeTags },
          ...localSearchHistory
        ];
        this.overwriteLocalStorage(this.searchHistoryKey, payload);
      }

      this.measureListTable.params.where.dimensionNameValueList =
        dimensionNameValueList;
      this.workbenchDownload.params.where.dimensionNameValueList =
        dimensionNameValueList;
      this.currentPage = 1;
      if (this.rbTableSourceInstance) {
        this.rbTableSourceInstance.config.body.APIConfig.page = 1;
      }
      this.pageChanged(1);
    },

    sortDWTable(column, event) {
      let order = '';
      if (column.sort === 'asc') {
        order = 'ASC';
      } else if (column.sort === 'desc') {
        order = 'DESC';
      }
      const dimensionName = column.colId;
      let orderObj = [];
      if (order !== '') {
        orderObj = [
          {
            dimension: dimensionName,
            direction: order
          },
          {
            dimension: 'asin',
            direction: 'DESC'
          }
        ];
      } else {
        orderObj = [
          {
            dimension: 'ordered_revenue',
            direction: 'DESC'
          },
          {
            dimension: 'asin',
            direction: 'DESC'
          }
        ];
      }
      this.measureListTable.params.orderByList = orderObj;
      this.workbenchDownload.params.orderByList = orderObj;
      // this.getTableData();
      this.currentPage = 1;
      this.pageChanged(1);
    },

    getMaxDateMessage() {
      const dates = this.getAllDates();
      return (
        this.$t('last_updated') +
        ': ' +
        Vue.options.filters.printable_date(new Date(dates.catalog)) +
        ' (AVC), ' +
        Vue.options.filters.printable_date(new Date(dates.pi)) +
        ' (CIQ Market Intelligence)'
      );
    },

    selectTab(toUrl) {
      const allowedValues = this.tableGroupFilters.tabList;
      const index = allowedValues.indexOf(toUrl.params.tableGroup);
      this.visibleColumnGroup = this.tableGroupFilters.tabList[index];
      this.$store.dispatch('setDataWorkbenchActiveTab', index);
    },

    verifyURL() {
      const allowedValues = this.tableGroupFilters.tabList;
      const index = allowedValues.indexOf(this.$route.params.tableGroup);
      if (index !== undefined && index < 0) {
        this.$router.push('/workbench/skus/All');
      } else {
        this.setColumnFilterFromLeftPanel(index);
      }
    },

    geti18() {
      return this.$t('all');
    },
    selectedRange(range) {
      this.dateRangeObj = range;
    },

    tagClicked(item, index, selectedItems) {},
    initLocalStorage() {},
    searchFromOption(unused, data) {
      if (data[0] === undefined) {
        return;
      }
      if (this.previousSearchedAsin.dimensionValue === data[0].asin) {
        return;
      }
      const dimensionList = {
        dimensionName: 'asin',
        dimensionValue: data[0].asin
      };
      this.previousSearchedAsin = dimensionList;
      this.applyFilterdw(dimensionList, true);
      this.searchTerm = data[0].asin;
    },
    getSearchList: debounce(function (params, abort) {
      if (abort) {
        return;
      }
      const where = this.$store.getters.getDWDataWhereClause;
      const dimensionNameValueList = where.dimensionNameValueList;
      // dimensionNameValueList = dimensionNameValueList.filter(item => item.dimensionName !== 'search')
      for (let i = 0; i < dimensionNameValueList.length; i++) {
        if (dimensionNameValueList[i].dimensionName === 'search') {
          dimensionNameValueList.splice(i, 1);
        }
      }
      if (params.length < 3) {
        return;
      }
      // api usuage here only for workbenchTableSearchData
      delete that.workbenchTableSearchData.params.page;
      const list = {
        dimensionName: 'search',
        dimensionValue: params,
        operator: 'ILIKE'
      };
      dimensionNameValueList.push(list);
      that.$store.state.workbenchTableSearchData = [];
      that.workbenchTableSearchData.params.where = where;
      that.workbenchTableSearchData.params.where.dimensionNameValueList =
        dimensionNameValueList;
      that.$store.dispatch(
        that.workbenchTableSearchData.storeActionMethod,
        that.workbenchTableSearchData
      );
    }, 500),

    dwColumnsConfig() {}
  },
  watch: {
    tableColumnsProps: {
      handler(newVal) {
        if (this.gridOptions.api) {
          // clear the previously maintained column definitions by the ag-grid
          // setting this in the computed property directly caused infinte rendering loops while action bar was enabled
          this.gridOptions.api.setColumnDefs([]);
        }
        this.localTableColumnsProps = newVal;
      },
      deep: true,
      immediate: true
    },
    '$route.params.tableGroup': {
      handler: function (id) {
        this.verifyURL();
      },
      deep: true,
      immediate: true
    }
  }
};
export default page;
</script>

<style lang="css">
.whiteBgTags .searchWrapper {
  background: #f4f7fa;
}
.background-transparent {
  background-color: transparent !important;
}
.active-tags-dropdown-dimensions {
  width: 470px;
}
.main-title {
  font-size: 2.1rem;
  color: #262626;
}

.chart-wrap {
  border: 1px solid rgba(151, 151, 151, 0.17);
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.column-selector {
  font-family: $font;
  margin-right: 8px;
  font-size: 1.6rem;
  cursor: pointer;
  outline: 0;
}

.column-selector:focus:not(:active) {
  box-shadow: none;
  outline: 0;
}

.main-table-wrap th td,
.main-table-wrap tr td {
  max-width: 500px;
  text-overflow: ellipsis;
}

.download-icon {
  background: #0083e1;
  fill: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.tabs.is-toggle a {
  border-color: #0083e1;
  font-size: 1.5rem;
  color: #007cf6;
}

.tabs.is-toggle li.is-active a {
  background-color: #0083e1;
  border-color: #0083e1;
}

.tabs.is-toggle a:hover {
  background-color: #0083e1;
  border-color: #0083e1;
  color: #fff;
}

.loader-block__min-height {
  min-height: 470px;
}

.loader-block .loader--expand {
  border-radius: 2px;
}

.el-table .asin_id {
  margin-top: -5px;
}

.noData {
  background: #fff;
}

.noData div {
  text-align: center;
  margin: auto;
  font-size: 1.6rem;
  color: #4b5158;
}

.rb-select form.searchForm {
  width: 240px;
  padding-right: 1rem;
}

.rb-select form.searchForm .search__input {
  padding: 0;
  background: none;
  box-shadow: none;
}
.overlay-css {
  pointer-events: none;
}

.dataWBTableWrapper .paginationFooter {
  background-color: palette(grey, white);
}
</style>
