<template>
  <!-- Desc: Main file of the enitity details component
  Renders the header, chart and custom tab components -->
  <div class="u-position-relative u-height-100">
    <loader
      v-if="
        (entityConfig || {}).showLoader && entityDetails.status === 'loading'
      "
      class="fill--parent"
      :loading="entityDetails.status === 'loading'"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div v-else>
      <div
        class="sticky"
        style="z-index: 102"
      >
        <Header
          :entity-id="entityId"
          :entity-config="entityConfig && entityConfig.header"
          :show-data-missing-msg="showDataMissingMsg"
          @filterMounted="handleFilterMount"
        />
        <div v-if="toggleBanner">
          <banner
            :text="entityConfig.banner(this).text"
            :cta-text="entityConfig.banner(this).ctaText"
            :action="bannerAction"
            :show-cta="entityConfig.banner(this).showCta"
            :show-close-icon="entityConfig.banner(this).showCloseIcon"
            :icon-custom-class="entityConfig.banner(this).iconCustomClass"
            @closeIconClicked="onBannerClosed"
          />
        </div>
      </div>
      <div v-if="dateFilter">
        <div v-if="entityConfig.chart">
          <chart
            :entity-id="entityId"
            :entity-config="entityConfig && entityConfig.chart"
            :date-filter="dateFilter"
            @tabsMetadata="tabsMetadata"
          />
        </div>
        <TabsComponent
          v-if="tabMetadata.show"
          :tabs-metadata="tabMetadata"
          :entity-config="entityConfig"
          :entity-id="entityId"
          :date-filter="dateFilter"
          :parent-entity-id="parentEntityId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import config from '@/pages/entity_details/configs/index.js';
import Header from '@/pages/entity_details/common_components/header/header.vue';
import TabsComponent from '@/pages/entity_details/common_components/tabsComponent.vue';
import chart from '@/pages/entity_details/common_components/chartWrapper.vue';
import banner from '@/pages/entity_details/common_components/banner.vue';
import loader from '@/components/basic/loader';
import { eventBus } from '@/utils/services/eventBus';

export default {
  components: {
    Header,
    chart,
    TabsComponent,
    banner,
    loader
  },
  data() {
    return {
      entityConfig: null,
      dateFilter: null,
      parentEntityId: null,
      tabMetadata: {
        show: false,
        data: null
      },
      entityId: null
    };
  },
  computed: {
    showDataMissingMsg() {
      return Vue.options.filters.config_check(
        'feature.pages.data_missing_banner_needed'
      ).enable;
    },
    entityDetails() {
      const val =
        this.$store.getters?.[this.entityConfig?.metadata?.[0]?.getters] || {};
      return val;
    },
    toggleBanner() {
      if (this.entityConfig?.banner) {
        const val =
          this.$store.getters?.[
            this.entityConfig?.metadata?.[0]?.bannerGetter
          ] || false;
        return val;
      }
      return false;
    }
  },
  watch: {
    entityDetails: {
      handler(newVal) {
        if (newVal?.status === 'error') {
          this.$snackbar.open({
            message: 'Sorry! The requested data could not be loaded',
            duration: 6000,
            actionText: ''
          });
        }
        if (newVal?.data?.name && !this.parentEntityId) {
          this.$router.push({
            query: {
              ...this.$route.query,
              parent_entity_name: newVal?.data?.name
            }
          });
        }
        this.entityConfig?.validation?.(this, newVal);
      },
      deep: true
    }
  },
  created() {
    const { entityId, entityType, subEntityType, subEntityId } =
      this.$route.params;
    const retailer = this.$store.getters.getRetailer;

    if (this.isEditEnabledForTheRetailer(retailer)) {
      if (subEntityType && subEntityId) {
        this.initSubEntity(
          retailer,
          entityId,
          entityType,
          subEntityType,
          subEntityId
        );
      } else {
        this.init(retailer, entityType, entityId);
      }
      this.setDateRange();
      eventBus.$on(this.entityConfig?.header?.filters?.config?.emit, (data) => {
        this.setDateRange(data);
      });
    } else {
      this.$router.push({
        name: '404'
      });
    }
  },
  methods: {
    isEditEnabledForTheRetailer(retailer) {
      return ['walmart', 'samsclub_api', 'instacart', 'amazon'].includes(
        retailer
      );
    },
    initSubEntity(retailer, entityId, entityType, subEntityType, subEntityId) {
      this.init(retailer, entityType, subEntityId, subEntityType);
      this.parentEntityId = entityId;
    },
    init(retailer, entity_type, entity_id, entityVal = null) {
      const path = this.$route.path;
      const entity =
        entityVal ||
        Object.keys(config[retailer][entity_type]).find((e) =>
          path.includes(e)
        );
      this.entityConfig = config[retailer][entity_type][entity];
      this.entityConfig.entityType = entity_type?.toUpperCase();
      this.entityId = entity_id;
      this.callMetadata(entity_id, entity_type);
    },
    callMetadata(entity_id, entity_type) {
      for (const item of this.entityConfig.metadata) {
        this.$store.dispatch(item.action, {
          entityId: entity_id,
          entityType: entity_type.toUpperCase(),
          routeData: this.$route
        });
      }
    },
    removeObjectiveForInstacart(originalTabsData) {
      return {
        ...originalTabsData,
        tabs: {
          ...originalTabsData.tabs,
          page_names: originalTabsData.tabs?.page_names?.toSpliced(1, 1)
        }
      };
    },
    tabsMetadata(tabsData) {
      // add custom logic for tabs metadata (pages) here - > dsp
      if (tabsData.name === 'instacart_cm_campaign_details_tabs') {
        this.tabMetadata = {
          show: true,
          data: this.removeObjectiveForInstacart(tabsData.metadata)
        };
      } else {
        this.tabMetadata = {
          show: true,
          data: tabsData.metadata
        };
      }
    },
    handleFilterMount() {
      // used to set date filters only during first load
      if (!this.dateFilter) {
        this.setDateRange();
      }
    },
    setDateRange(filterData) {
      let dateFilter = filterData;
      if (!filterData) {
        const key = this.entityConfig?.header?.filters?.filterLocalStorageKey;
        dateFilter = JSON.parse(localStorage.getItem(key))?.date_range || null;
      }
      this.dateFilter = dateFilter && {
        ':to': dateFilter.to,
        ':from': dateFilter.from,
        ':pvpTo': dateFilter.compare_to,
        ':pvpFrom': dateFilter.compare_from
      };
    },
    bannerAction() {
      this.entityConfig?.banner(this)?.action?.();
    },
    onBannerClosed() {
      this.entityConfig?.banner(this)?.bannerCloseAction?.();
    }
  }
};
</script>
