import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import { formatter } from '@/utils/helpers/formatter.js';
import utils from '@/utils/helpers/index.js';
import merge from 'lodash/merge';

import moment from 'moment-timezone';
import Vue from 'vue';

function isWalmartOrSamsClubAPI(retailer) {
  return retailer === 'walmart' || retailer === 'samsclub_api';
}

const deserialization = (resData) => {
  const data = resData.replace(/\\/g, '');
  return JSON.parse(data);
};
const formatSPCampaigns = (resData) => {
  let data = resData;
  if (typeof resData === 'string') {
    data = deserialization(resData);
  }
  data.campaignType =
    data?.campaignType === 'sponsoredProducts' ? 'Sponsored Products' : 'NA';
  data.startDate =
    (data?.startDate && moment(data.startDate).format('MMMM DD, YYYY')) || null;
  data.endDate =
    (data?.endDate && moment(data.endDate).format('MMMM DD, YYYY')) || null;
  data.dailyBudgetView =
    data?.dailyBudget && `${Vue.prototype.$currency}${data?.dailyBudget}`;
  data.targetingTypeView = data?.targetingType
    ? `${data.targetingType} Targeting`
    : null;
  data.color = 'red'; // campaign is archived
  if (data.state === 'enabled') {
    data.color = 'green';
  } else if (data.state === 'paused') {
    data.color = 'orange';
  }
  return data;
};

const formatSPAdgroupDetailsInstacart = (resData, id) => {
  let data = resData;
  if (typeof resData === 'string') {
    data = resData.replace(/\\/g, '');
    data = JSON.parse(data);
  }

  data.defaultBidView = `$${data.default_bid}`;

  data.stateView = data.enabled === false ? 'Paused' : 'Enabled';
  data.state = data.enabled === false ? 'inactive' : 'active';

  if (data.ad_group_status === 'campaign_paused') {
    data.color = 'orange';
  } else {
    data.color = 'blue';
  }
  data.agId = id;
  return data;
};

const formatSPInstacartCampaigns = (resData, id) => {
  let data = resData;
  if (typeof resData === 'string') {
    data = resData.replace(/\\/g, '');
    data = JSON.parse(data);
  }
  data.campaignType = data?.campaign_type ? 'Sponsored Products' : 'NA';
  data.startDate =
    (data?.scheduled_start_at_date &&
      moment(data.scheduled_start_at_date).format('MMM DD, YYYY')) ||
    null;
  data.endDate =
    (data?.scheduled_end_at_date &&
      moment(data.scheduled_end_at_date).format('MMM DD, YYYY')) ||
    null;
  data.dailyBudgetView =
    data?.target_daily_budget &&
    `${Vue.prototype.$currency}${data?.target_daily_budget}`;
  data.targetingTypeView = data?.optimized_bidding_enabled
    ? 'Optimized'
    : 'Manual';
  data.state = data.enabled ? 'enabled' : 'paused';
  data.lifetimeBudgetView =
    data?.budget && `${Vue.prototype.$currency}${data?.budget}`;
  data.campaignId = id;
  data.budgetType = 'Daily Budget';
  data.color = 'red'; // campaign is archived
  if (
    data.campaign_status === 'active' ||
    data.campaign_status === 'scheduled'
  ) {
    data.color = 'blue';
  } else if (
    data.campaign_status === 'paused' ||
    data.campaign_status === 'draft' ||
    data.campaign_status === 'inactive'
  ) {
    data.color = 'orange';
  } else if (data.campaign_status === 'ended') {
    data.color = 'green';
  }
  if (data.campaign_status === 'incomplete_ad_group') {
    data.campaign_status_view = 'Incomplete Ad Group';
  } else if (data.campaign_status === 'incomplete_ad_group_products') {
    data.campaign_status_view = 'Incomplete Ad Group Products';
  } else if (data.campaign_status === 'out_of_budget') {
    data.campaign_status_view = 'Out of Budget';
  } else {
    data.campaign_status_view =
      data.campaign_status.charAt(0).toUpperCase() +
      data.campaign_status.slice(1);
  }
  return data;
};

const formatDSPOrders = (data) => {
  return {
    name: data.dsp_orders_order_name,
    totalBudget: formatter(data.dsp_orders_total_budget, 'currency'),
    goal: utils.capitalizeString(data.dsp_orders_campaign_goal),
    goal_kpi: utils.capitalizeString(data.dsp_orders_campaign_goal_kpi),
    status: utils.capitalizeString(data.dsp_orders_delivery_status),
    delivery_rate:
      formatter(data.dsp_orders_delivery_rate, 'percentage') ?? 'NA',
    startDate: formatter(data.start_date, 'date'),
    endDate: formatter(data.end_date, 'date')
  };
};

const formatterMap = {
  Orders: formatDSPOrders
};
const formatSBCampaigns = (resData, retailer = null) => {
  let data = resData;
  if (typeof resData === 'string') {
    data = deserialization(resData);
  }
  data.dailyBudget = data?.budget || null;
  data.campaignType = 'Sponsored Brands';
  data.startDate =
    (data?.startDate && moment(data.startDate).format('MMMM DD, YYYY')) || null;
  data.endDate =
    (data?.endDate && moment(data.endDate).format('MMMM DD, YYYY')) || null;
  data.dailyBudgetView =
    data?.dailyBudget &&
    `${Vue.prototype.$currency}${
      data?.dailyBudget
    } ${data.budgetType.capitalize()}`;
  data.targetingType = 'Manual Targeting'; // for sponsered brand Only manaul targettig type is there.
  data.adFormat =
    data?.adFormat === 'productCollection'
      ? 'Product Collection'
      : data?.adFormat;
  data.state = retailer === 'amazon' ? data.state.toLowerCase() : data.state;
  if (data.state === 'enabled') {
    data.color = 'green';
  } else if (data.state === 'paused') {
    data.color = 'orange';
  }
  return data;
};

const formatSDCampaigns = (resData) => {
  let data = resData;
  if (typeof resData === 'string') {
    data = deserialization(resData);
  }
  data.dailyBudget = data?.budget || null;
  data.campaignType = 'Sponsored Display';
  data.startDate =
    (data?.startDate && moment(data.startDate).format('MMMM DD, YYYY')) || null;
  data.endDate =
    (data?.endDate && moment(data.endDate).format('MMMM DD, YYYY')) || null;
  data.dailyBudgetView =
    data?.dailyBudget &&
    `${Vue.prototype.$currency}${
      data?.dailyBudget
    } ${data.budgetType.capitalize()}`;
  data.targetingType =
    data.tactic === 'T00030' ? 'Audience Targeting' : 'Product Targeting';
  data.typeTargeting =
    data.tactic === 'T00030' ? 'audience_targeting' : 'product_targeting';
  data.adFormat =
    data?.adFormat === 'productCollection'
      ? 'Product Collection'
      : data?.adFormat;
  data.state = data.state;
  if (data.state === 'enabled') {
    data.color = 'green';
  } else if (data.state === 'paused') {
    data.color = 'orange';
  }
  return data;
};

const formatSBACampaigns = (data) => {
  const result = {};
  result.name = data[0].name;
  result.dailyBudgetView = formatter(data[0]?.dailyBudget, 'currency');
  result.dailyBudget = data[0].dailyBudget;
  result.campaignType =
    data[0].campaignType === 'sponsoredProducts'
      ? 'Sponsored Product'
      : 'Sponsored Brand';
  (result.startDate = formatter(data[0].startDate, 'date')),
    (result.endDate = formatter(data[0].endDate, 'date')),
    (result.totalBudgetView = formatter(data[0]?.totalBudget, 'currency'));
  result.dailyRemainingBudget = formatter(
    data[0]?.dailyRemainingBudget,
    'currency'
  );
  result.todayAdSpend = formatter(data[0]?.todayAdSpend, 'currency');
  result.totalBudget = data[0]?.totalBudget;
  result.todayImpressions = data[0]?.todayImpressions;
  result.todayClicks = data[0]?.todayClicks;
  result.targetingType = `${
    data[0].targetingType === 'manual' ? 'Manual' : 'Automatic'
  } Targeting`;
  result.state = data[0].status;
  result.campaignId = data[0].campaignId;
  result.adGroupId = data[0]?.adGroupId;
  return result;
};

const formatSBAAdgroupDetails = (data) => {
  const result = {};
  result.name = data[0].name;
  result.adGroupId = data[0].adGroupId;
  result.campaignId = data[0].campaignId;
  result.state = data[0].status?.toLowerCase();
  if (result.state === 'enabled') {
    result.color = 'green';
  } else if (result.state === 'paused') {
    result.color = 'orange';
  }
  return result;
};

const formatReviewStatusData = (data) => {
  data = data?.sort?.((a, b) => b.reviewId - a.reviewId)?.[0];
  const result = {};
  result.reviewStatus = data?.reviewStatus;
  result.reviewComments = data?.reviewComments
    ?.map((i) => i.comments)
    ?.join?.(', ');
  result.reviewId = data?.reviewId;
  return result;
};

const fetchExtraMetadata = (
  { context, type, adGroupId, entityType, routeData },
  retailer = 'walmart'
) => {
  let reqPayload;
  reqPayload = {
    retailer,
    campaignId: routeData?.params?.entityId,
    advertiserId: routeData?.query?.advertiser_id,
    adGroupId: routeData?.params?.subEntityId ?? adGroupId,
    filterName: null,
    filterDate: null,
    type
  };

  return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', reqPayload);
};

const getBannerStatus = ({ adGroupId, routeData, context }) => {
  const retailer = context?.rootGetters?.getRetailer;
  context.commit('TOGGLE_BANNER', false);
  setTimeout(() => {
    Promise.all(
      ['SKUS', 'SBA_PROFILES', 'KEYWORDS'].map((type) =>
        fetchExtraMetadata({ type, adGroupId, routeData }, retailer)
      )
    )
      .then((aggResp) => {
        const [
          {
            data: { response: skusStatuses = [] }
          },
          {
            data: { response: profilesStatuses = [] }
          },
          {
            data: { response: keywordsStatuses = [] }
          }
        ] = aggResp;
        const isPending =
          skusStatuses.some((sku) => sku.reviewStatus === 'pending') ||
          keywordsStatuses.some((keyword) => keyword.status === 'pending') ||
          profilesStatuses.some(
            (profile) => profile.reviewStatus === 'pending'
          );
        context.commit('TOGGLE_BANNER', isPending);
      })
      .catch((error) => {
        console.error(error);
        context.commit('TOGGLE_BANNER', false);
      });
  }, 2000);
};

// helper functions

const createCommitObject = (status, data) => {
  // respononse status helper as it is same for most states
  return {
    load: status === 'loading',
    rows: data?.rows || [],
    error: status === 'error' ? data?.error || false : false,
    totalRows: data?.totalRows ? data.totalRows : 0
  };
};

const commonMutationSetter = (state, stateName, data) => {
  Vue.set(state[stateName], 'rows', data?.rows);
  Vue.set(state[stateName], 'load', data.load);
  Vue.set(state[stateName], 'error', data.error);
  Vue.set(state[stateName], 'totalRows', data.totalRows);
  Vue.set(state[stateName], 'data', data?.data);
};

const fetchEntityDetails = (context, type, entityId, entityType, routeData) => {
  let reqPayload;
  const retailer = context?.rootGetters?.getRetailer;
  if (isWalmartOrSamsClubAPI(retailer)) {
    reqPayload = {
      retailer: retailer,
      campaignId: routeData?.params?.entityId,
      advertiserId: routeData?.query?.advertiser_id,
      adGroupId: routeData?.params?.subEntityId ?? null,
      filterName: null,
      filterDate: null,
      type
    };
  } else if (context?.rootGetters?.getRetailer === 'instacart') {
    reqPayload = {
      retailer: 'instacart',
      campaignId: routeData?.params?.entityId,
      adGroupId: routeData?.params?.subEntityId ?? null,
      type
    };
  } else {
    reqPayload = {
      retailer: context?.rootGetters?.getRetailer || 'amazon',
      displayRequest: {
        profileId: null,
        type,
        id: entityId,
        filters: {}
      },
      campaignType: entityType
    };
  }
  return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', reqPayload);
};

const refreshEntityKey = (state, status) => {
  if (status === 'loaded') {
    // real time kpi requires a 2000 ms delay to refresh the table
    setTimeout(() => {
      state.campaing_details.key++;
      state.adgroup_details.key++;
      state.review_status_data.key++;
    }, 2000);
  }
  if (status === 'update_table_key') {
    // real time kpi requires a 2000 ms delay to refresh the table
    setTimeout(() => {
      state.tableDataComponentKey = Math.floor(Math.random() * 50);
    }, 2000);
  }
};

const state = {
  campaing_details: {
    status: 'loaded', // [loaded, error, loading]
    data: {},
    key: 0
  },
  adgroup_details: {
    status: 'loaded', // [loaded, error, loading]
    data: {},
    key: 0
  },
  targeting_page_details: {
    status: 'loaded',
    data: {},
    key: 0
  },
  advertiser_data: {
    status: 'loaded',
    data: {},
    key: 0
  },
  review_status_data: {
    status: 'loaded',
    data: {},
    key: 0
  },
  dsp_order_details: {
    status: 'loaded',
    data: {}
  },
  tableDataComponentKey: 1,
  bannerStatus: false
};
const getters = {
  getCampaignDetails: (state) => state.campaing_details,
  getAdgroupDetails: (state) => state.adgroup_details,
  getTargetingPageDetails: (state) => state.targeting_page_details,
  getAdvertiser: (state) => state.advertiser_data,
  getReviewStatusData: (state) => state.review_status_data,
  getDspOrderDetails: (state) => state.dsp_order_details,
  getTableDataComponentKey: (state) => state.tableDataComponentKey,
  getBannerStatus: (state) => state.bannerStatus
};

const mutations = {
  UPDATE_CAMPAIGN_DETAILS: (state, data) => {
    state.campaing_details = data;
    refreshEntityKey(state, data?.status);
  },
  UPDATE_ADGROUP_DETAILS: (state, data) => {
    state.adgroup_details = data;
    refreshEntityKey(state, data?.status);
  },
  GET_TARGETING_PAGE_DATA: (state, data) => {
    state.targeting_page_details = data;
  },
  GET_ADVERTISER_DATA: (state, data) => {
    state.advertiser_data = data;
  },
  GET_REVIEW_STATUS_DATA: (state, data) => {
    commonMutationSetter(state, 'review_status_data', data);
    refreshEntityKey(state, data?.status);
  },
  UPDATE_DSP_ORDER_DETAILS: (state, data) => {
    state.dsp_order_details = data;
  },
  INCREMENT_TABLE_DATA_COMPONENT_KEY: (state) => {
    refreshEntityKey(state, 'update_table_key');
  },
  TOGGLE_BANNER: (state, data) => {
    state.bannerStatus = data;
  }
};
const actions = {
  updateCampaignDetails(
    context,
    {
      updateRequest,
      entityId,
      entityType,
      snackbar,
      callAdgroupFetch = false,
      entityName,
      routeData
    }
  ) {
    const contextToUpdate = callAdgroupFetch
      ? 'UPDATE_ADGROUP_DETAILS'
      : 'UPDATE_CAMPAIGN_DETAILS';
    const key = callAdgroupFetch
      ? context.getters.getAdgroupDetails.key
      : context.getters.getCampaignDetails.key;
    let message = '';
    const name = entityName || {
      plural: 'entities',
      singular: 'entity'
    };
    name.action = entityName?.action || 'updated';
    name.stopFetchApi = entityName?.stopFetchApi;
    if (!name.stopFetchApi) {
      context.commit(contextToUpdate, {
        status: 'loading',
        data: {},
        key
      });
    }

    HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', updateRequest)
      .then(({ data }) => {
        let successfulCount = 0;
        let notSuccessfulCount = 0;
        for (const response of data?.response) {
          if (response.actionStatus === 'successful') {
            successfulCount++;
          } else {
            notSuccessfulCount++;
          }
        }
        message = 'One or more entities are not updated.';
        if (successfulCount && notSuccessfulCount) {
          message = `${entityName?.noCount ? '' : successfulCount} ${
            successfulCount > 1 ? `${name.plural} are` : `${name.singular} is`
          } ${name.action} and 
        ${entityName?.noCount ? '' : notSuccessfulCount} ${
            notSuccessfulCount > 1
              ? `${name.plural} are`
              : `${name.singular} is`
          } not ${name.action}`;
        } else if (successfulCount && !notSuccessfulCount) {
          message = `${entityName?.noCount ? '' : successfulCount} ${
            successfulCount > 1 ? `${name.plural} are` : `${name.singular} is`
          } ${name.action}`;
        } else if (!successfulCount && notSuccessfulCount) {
          message = `${entityName?.noCount ? '' : notSuccessfulCount} ${
            notSuccessfulCount > 1
              ? `${name.plural} are`
              : `${name.singular} is`
          } not ${name.action}`;
        }
        // for (let item of data.response)
      })
      .catch(() => {
        message = `Error while updating ${
          entityName?.noCount ? '' : 'one or more'
        } ${name.plural}`;
      })
      .finally(() => {
        snackbar.open({
          message,
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
        if (!name.stopFetchApi) {
          if (callAdgroupFetch) {
            context.dispatch('fetchAdgroupDetails', {
              entityId,
              entityType,
              routeData
            });
          } else {
            context.dispatch('fetchCampaingDetails', {
              entityId,
              entityType,
              routeData
            });
          }
        }
        context.dispatch('fetchIncrementTableDataKey');
      });
  },
  fetchCampaingDetails(context, { entityId, entityType, routeData }) {
    const retailer = context?.rootGetters?.getRetailer;
    const key = context.getters.getCampaignDetails.key;
    context.commit('UPDATE_CAMPAIGN_DETAILS', {
      status: 'loading',
      data: {},
      key
    });
    if (
      retailer === 'samsclub_api' &&
      ['SP_AUTO', 'SP_MANUAL'].includes(entityType)
    ) {
      context.commit('TOGGLE_BANNER', true);
    }
    // for fetching SBA review status
    if (isWalmartOrSamsClubAPI(retailer) && entityType === 'SB_MANUAL') {
      const campaignDetailsPromise = fetchEntityDetails(
        context,
        'CAMPAIGNS',
        entityId,
        entityType,
        routeData
      );
      const adgroupDetailsPromise = fetchEntityDetails(
        context,
        'ADGROUPS',
        entityId,
        entityType,
        routeData
      );
      return Promise.all([campaignDetailsPromise, adgroupDetailsPromise])
        .then((res) => {
          let [campaignData, adgroupData] = res;
          fetchExtraMetadata(
            {
              type: 'CAMPAIGN_STATS',
              adGroupId: adgroupData?.data?.response?.[0]?.adGroupId,
              routeData
            },
            retailer
          )
            .then((statsData) => {
              fetchExtraMetadata(
                {
                  type: 'REVIEW_STATUS',
                  adGroupId: adgroupData?.data?.response?.[0]?.adGroupId,
                  routeData
                },
                retailer
              )
                .then((reviewStatusData) => {
                  let data = formatSBACampaigns(
                    merge(
                      campaignData?.data?.response,
                      [
                        {
                          adGroupId: adgroupData?.data?.response?.[0]?.adGroupId
                        }
                      ],
                      statsData?.data?.response || []
                    )
                  );
                  data = {
                    ...data,
                    ...formatReviewStatusData(reviewStatusData?.data?.response)
                  };
                  context.commit('UPDATE_CAMPAIGN_DETAILS', {
                    status: 'loaded',
                    data,
                    key
                  });
                })
                .catch(() => {
                  context.commit('UPDATE_CAMPAIGN_DETAILS', {
                    status: 'error',
                    data: {},
                    key
                  });
                });
              getBannerStatus({
                context,
                routeData,
                adGroupId: adgroupData?.data?.response?.[0]?.adGroupId
              });
            })
            .catch(() => {
              context.commit('UPDATE_CAMPAIGN_DETAILS', {
                status: 'error',
                data: {},
                key
              });
            });
        })
        .catch(() => {
          context.commit('UPDATE_CAMPAIGN_DETAILS', {
            status: 'error',
            data: {},
            key
          });
        });
    }
    if (isWalmartOrSamsClubAPI(retailer) && entityType.includes('SP')) {
      const campaignDetailsPromise = fetchEntityDetails(
        context,
        'CAMPAIGNS',
        entityId,
        entityType,
        routeData
      );
      const campaignStatsPromise = fetchExtraMetadata(
        { type: 'CAMPAIGN_STATS', routeData },
        retailer
      );
      return Promise.all([campaignDetailsPromise, campaignStatsPromise])
        .then((res) => {
          let [campaignData, statsData] = res;
          let data = formatSBACampaigns(
            merge(campaignData?.data?.response, statsData?.data?.response || [])
          );
          context.commit('UPDATE_CAMPAIGN_DETAILS', {
            status: 'loaded',
            data,
            key
          });
        })
        .catch(() => {
          context.commit('UPDATE_CAMPAIGN_DETAILS', {
            status: 'error',
            data: {},
            key
          });
        });
    }
    fetchEntityDetails(context, 'CAMPAIGNS', entityId, entityType, routeData)
      .then((res) => {
        let data = null;
        if (
          entityType === 'SP' &&
          context?.rootGetters?.getRetailer === 'instacart'
        ) {
          data = formatSPInstacartCampaigns(
            res?.data?.response?.data?.attributes,
            res?.data?.response?.data?.id
          );
        } else if (
          entityType === 'SP' &&
          context?.rootGetters?.getRetailer === 'amazon'
        ) {
          data = formatSPCampaigns(res?.data?.response);
        } else if (entityType === 'SB') {
          data = formatSBCampaigns(
            res?.data?.response,
            context?.rootGetters?.getRetailer
          );
        } else if (entityType === 'SD') {
          data = formatSDCampaigns(res?.data?.response);
        } else if (entityType === 'SP' && isWalmartOrSamsClubAPI(retailer)) {
          data = formatSBACampaigns(res?.data?.response);
        }
        context.commit('UPDATE_CAMPAIGN_DETAILS', {
          status: data?.name ? 'loaded' : 'error',
          data,
          key
        });
      })
      .catch(() => {
        context.commit('UPDATE_CAMPAIGN_DETAILS', {
          status: 'error',
          data: {},
          key
        });
      });
  },
  fetchAdgroupDetails(context, { entityId, entityType, routeData }) {
    const retailer = context?.rootGetters?.getRetailer;
    const key = context.getters.getAdgroupDetails.key;
    context.commit('UPDATE_ADGROUP_DETAILS', {
      status: 'loading',
      data: {},
      key
    });

    // for fetching SBA review status
    if (isWalmartOrSamsClubAPI(retailer) && entityType === 'SB_MANUAL') {
      const entityDetailsPromise = fetchEntityDetails(
        context,
        'ADGROUPS',
        entityId,
        entityType,
        routeData
      );
      const reviewStatusPromise = fetchExtraMetadata(
        { type: 'REVIEW_STATUS', routeData },
        retailer
      );
      return Promise.all([entityDetailsPromise, reviewStatusPromise])
        .then((res) => {
          let [campaignData, reviewStatusData] = res;
          let data = formatSBAAdgroupDetails(campaignData?.data?.response);
          data = {
            ...data,
            ...formatReviewStatusData(reviewStatusData?.data?.response)
          };
          getBannerStatus({
            context,
            routeData,
            adGroupId: campaignData?.data?.response?.[0]?.adGroupId
          });
          context.commit('UPDATE_ADGROUP_DETAILS', {
            status: 'loaded',
            data,
            key
          });
        })
        .catch(() => {
          context.commit('UPDATE_ADGROUP_DETAILS', {
            status: 'error',
            data: {},
            key
          });
        });
    }

    fetchEntityDetails(context, 'ADGROUPS', entityId, entityType, routeData)
      .then((res) => {
        let data = res?.data?.response;
        const retailer = context?.rootGetters?.getRetailer;
        if (isWalmartOrSamsClubAPI(retailer)) {
          if (['SP_MANUAL', 'SP_AUTO'].includes(entityType)) {
            const filteredArray = res?.data?.response.filter(
              (item) => item.adGroupId === parseInt(entityId)
            );
            data = formatSBAAdgroupDetails(filteredArray);
          }
        } else if (context?.rootGetters?.getRetailer === 'instacart') {
          data = formatSPAdgroupDetailsInstacart(
            res?.data?.response?.data?.attributes,
            res?.data?.response?.data?.id
          );
        } else {
          if (typeof data === 'string') {
            data = deserialization(data);
          }
          data.defaultBidView =
            data?.defaultBid && `${Vue.prototype.$currency}${data?.defaultBid}`;
          data.color = 'red'; // adgroup is archived
          if (data.state) {
            data.state = data.state.toLowerCase();
          }
          if (data.state === 'enabled') {
            data.color = 'green';
          } else if (data.state === 'paused') {
            data.color = 'orange';
          }
        }
        context.commit('UPDATE_ADGROUP_DETAILS', {
          status: 'loaded',
          data,
          key
        });
      })
      .catch(() => {
        context.commit('UPDATE_ADGROUP_DETAILS', {
          status: 'error',
          data: {},
          key
        });
      });
  },
  fetchWithExecute(context, { APIData, storeActionName, name }) {
    context.commit(storeActionName, {
      status: 'loading',
      data: {}
    });
    HttpLayer.post({
      cube: 'EXECUTE_CUBE_API',
      APIData
    })
      .then((res) => {
        const rows = transformer.mergeResultDimension(res.data);
        context.commit(storeActionName, {
          status: 'loaded',
          data: formatterMap[name](rows[0])
        });
      })
      .catch((error) => {
        console.log({ error });
        context.commit(storeActionName, {
          status: 'error',
          data: {}
        });
      });
  },
  async fetchTargetingPageData(context, { requestParams }) {
    return new Promise(async (resolve, reject) => {
      return await HttpService.post('EXECUTE_CUBE_API', requestParams)
        .then((res) => {
          let data = null;
          data = res?.data?.response?.data;
          context.commit('GET_TARGETING_PAGE_DATA', {
            status: 'loaded',
            data
          });
          resolve(true);
        })
        .catch((error) => {
          context.commit('GET_TARGETING_PAGE_DATA', {
            status: 'error',
            data: {}
          });
          reject(error);
        });
    });
  },
  fetchAdvertiserData(context, { requestParams }) {
    return new Promise(async (resolve, reject) => {
      return await HttpLayer.post({
        cube: 'EXECUTE_CUBE_API',
        APIData: requestParams
      })
        .then((res) => {
          let data = null;
          data = transformer.mergeResultDimension(res.data);
          context.commit('GET_ADVERTISER_DATA', {
            status: 'loaded',
            data
          });
          resolve(true);
        })
        .catch((error) => {
          context.commit('GET_ADVERTISER_DATA', {
            status: 'error',
            data: {}
          });
          reject(error);
        });
    });
  },
  fetchReviewStatusData(context, { type, routeData, fetchAll = false }) {
    const key = context.getters.getReviewStatusData.key;
    const retailer = context?.rootGetters?.getRetailer;
    context.commit('GET_REVIEW_STATUS_DATA', {
      ...createCommitObject('loading'),
      data: {},
      key
    });
    fetchExtraMetadata(
      {
        type,
        routeData
      },
      retailer
    )
      .then((res) => {
        let data = null;
        data = fetchAll
          ? res?.data?.response
          : res?.data?.response?.sort?.((a, b) => b.reviewId - a.reviewId)?.[0];
        context.commit('GET_REVIEW_STATUS_DATA', {
          ...createCommitObject('success', {
            rows: data,
            totalRows: data.length
          }),
          data,
          key
        });
      })
      .catch(() => {
        context.commit('GET_REVIEW_STATUS_DATA', {
          ...createCommitObject('error'),
          data: {},
          key
        });
      });
  },
  fetchIncrementTableDataKey(context) {
    context.commit('INCREMENT_TABLE_DATA_COMPONENT_KEY');
  },
  updateDspOrdersMetadata(context, data) {
    context.commit('UPDATE_DSP_ORDER_DETAILS', {
      status: 'loaded',
      data: formatDSPOrders(data)
    });
  },
  toggleBannerStatus(context) {
    context.commit('TOGGLE_BANNER', !context.state.bannerStatus);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
