<template>
  <div
    class="u-width-auto input-box u-display-flex u-flex-wrap-yes u-cursor-text u-border-width-s u-spacing-pv-xs u-spacing-ph-s u-flex-align-items-center"
    @click.stop="componentClicked"
  >
    <section
      class="u-display-flex u-flex-align-items-center u-flex-wrap-yes u-width-100"
    >
      <div
        v-for="(iconData, index) in preInputIcons"
        :key="index"
        :class="[
          index > 0 && 'u-spacing-ml-xs',
          'rb-icon--' + iconData.size,
          'icon-' + iconData.icon,
          'u-color-' + preInputIconColor || iconData.color,
          iconData.emit ? 'u-cursor-pointer' : 'u-cursor-default'
        ]"
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
        @click.stop="onIconClick(iconData)"
      />
      <section v-if="tagsAggregatedView">
        <div class="u-display-flex u-flex-align-items-center u-width-100">
          <tag
            v-if="localActiveTags.length"
            :id="1"
            :enable-style-api="enableStyleApi"
            :title="aggregatedTagTitleText"
            :tag-color="tagColor"
            :tag-font-size="tagFontSize"
            :tag-font-color="tagFontColor"
            :tags-icon-size="tagsIconSize"
            :icons="tagsIcons"
            :is-tag-clickable="isTagClickable"
            @clearBufferTags="handleClearBufferTags"
            @removeActiveTag="handleRemoveActiveTag"
            @openActiveTagsDropdown="handleOpenActiveTagsDropdown"
            @tagClicked="handleTagClick"
          />
          <tag
            v-if="bufferedTags.length"
            :id="2"
            :enable-style-api="enableStyleApi"
            :title="aggregatedBufferTagTitleText"
            :tag-color="bufferedTagColor"
            :tag-font-size="tagFontSize"
            :tag-font-color="tagFontColor"
            :is-tag-clickable="isTagClickable"
            :tags-icon-size="tagsIconSize"
            :icons="bufferedTagIcons"
            @clearBufferTags="handleClearBufferTags"
            @removeActiveTag="handleRemoveActiveTag"
            @openActiveTagsDropdown="handleOpenBufferedTagsDropdown"
            @tagClicked="handleBufferedTagClick"
          />
          <div
            data-cy="campaignListInput"
            class="u-spacing-mv-xs u-spacing-mh-xxs u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-space-between"
          >
            <!-- @keydown="handleKeydown" -->
            <!-- @click.prevent="handleTextBoxClick" -->
            <input
              ref="input-field"
              :size="tagsAggregatedInputSize"
              :class="['u-font-size-' + inputFontSize]"
              class="u-color-grey-mid-light tag-input-text u-spacing-ph-xs"
              :placeholder="inputPlaceholder"
              :disabled="isInputDisabled"
              :value="textInput"
              @input.prevent="handleInput"
              @paste.prevent="handlePaste"
              @focus.prevent="handleFocus"
              @blur.prevent="handleBlur"
              @keyup.enter="handleEnter"
            />
          </div>
        </div>
      </section>
      <section
        v-else
        class="u-display-flex u-flex-align-items-center u-flex-wrap-yes"
      >
        <!-- buffer tags not included in this view since no use case -->
        <div
          v-for="(item, index) in localActiveTags"
          :key="index"
          class="u-font-size-4 u-font-weight-600"
        >
          <tag
            v-if="item.type !== 'newTagTrigger'"
            :id="item.id"
            :enable-style-api="enableStyleApi"
            class="editable-tag"
            :title="item.title"
            :tag-color="tagColor"
            :tag-font-size="tagFontSize"
            :is-tag-clickable="isTagClickable"
            :tag-font-color="tagFontColor"
            :tags-icon-size="tagsIconSize"
            :icons="tagsIcons"
            @clearBufferTags="handleClearBufferTags"
            @removeActiveTag="handleRemoveActiveTag"
            @openActiveTagsDropdown="handleOpenActiveTagsDropdown"
            @tagClicked="handleTagClick"
          />
        </div>
        <div
          v-if="allowMultipleTags || !localActiveTags.length || checkNewTag()"
          data-cy="campaignListInput"
          class="u-spacing-mv-xs u-spacing-mh-xxs"
        >
          <!-- @keydown="handleKeydown" -->
          <!-- @click.prevent="handleTextBoxClick" -->
          <input
            ref="input-field"
            :value="textInput"
            :size="computedWidth"
            :class="['u-font-size-' + inputFontSize]"
            class="u-color-grey-mid-light tag-input-text u-spacing-ph-xs"
            :placeholder="inputPlaceholder"
            :disabled="isInputDisabled"
            @input.prevent="handleInput"
            @keyup.enter="handleEnter"
            @paste.prevent="handlePaste"
            @focus.prevent="handleFocus"
            @blur.prevent="handleBlur"
            @clearBufferTags="handleClearBufferTags"
          />
        </div>
      </section>
      <div
        v-show="
          textInput !== '' ||
          bufferedTags.length !== 0 ||
          localActiveTags.length !== 0
        "
      >
        <div
          v-for="(iconData, index) in postInputIcons"
          :key="preInputIcons.length + index"
          :class="[
            index > 0 && 'u-spacing-ml-xs',
            'rb-icon--' + iconData.size,
            'icon-' + iconData.icon,
            'u-color-' + postInputIconColor || iconData.color,
            iconData.emit ? 'u-cursor-pointer' : 'u-cursor-default'
          ]"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer"
          @click.stop="onIconClick(iconData)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import tag from './tag';
export default {
  components: {
    tag
  },
  props: {
    enableStyleApi: {
      type: Boolean,
      default: true
    },
    isTagClickable: {
      type: Boolean,
      default: false
    },
    preInputIconColor: {
      type: String,
      default: 'grey-x-light'
    },
    postInputIconColor: {
      type: String,
      default: 'grey-x-light'
    },
    textInput: {
      type: String,
      default: ''
    },
    localActiveTags: {
      type: Array,
      default: () => []
    },
    bufferedTags: {
      type: Array,
      default: () => []
    },
    bufferedTagColor: {
      type: String,
      default: 'rgb(255, 255, 255)'
    },
    isInputDisabled: {
      type: Boolean,
      default: false
    },
    tagColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    tagsTitleKey: {
      type: String,
      default: null
    },
    delimiters: {
      type: Array,
      default: () => []
    },
    tagsAggregatedInputSize: {
      type: Number,
      default: 50
    },
    postInputIcons: {
      type: Array,
      default: () => []
    },
    preInputIcons: {
      type: Array,
      default: () => []
    },
    tagsIcons: {
      type: Array,
      default: () => []
    },
    bufferedTagIcons: {
      type: Array,
      default: () => []
    },
    aggregatedTagTitleText: {
      type: String,
      default: ''
    },
    aggregatedBufferTagTitleText: {
      type: String,
      default: ''
    },
    tagsAggregatedView: {
      type: Boolean,
      default: true
    },
    getTagsInputInstance: {
      type: Function,
      default: () => ({})
    },
    tagFontSize: {
      type: String,
      default: '7'
    },
    tagFontColor: {
      type: String,
      default: 'grey-base'
    },
    tagsIconSize: {
      type: String,
      default: 'xx-small'
    },
    inputFontSize: {
      type: String,
      default: '5'
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    pasteSplit: {
      type: Boolean,
      default: true
    },
    allowMultipleTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: null,
      isInputFocussed: false
    };
  },
  computed: {
    computedWidth() {
      if (!this.textInput.length) {
        return this.inputPlaceholder.length;
      }
      if (this.textInput.length < this.inputPlaceholder.length) {
        return this.inputPlaceholder.length;
      }
      return this.textInput.length;
    }
  },
  created() {
    if (this.getTagsInputInstance) {
      this.getTagsInputInstance(this);
    }
  },
  methods: {
    handleEnter(data) {
      this.$emit('enter', data);
    },
    handleClearBufferTags(data) {
      this.$emit('clearBufferTags', data);
    },
    handleRemoveActiveTag(data) {
      this.$emit('removeActiveTag', data);
    },
    handleInput(e) {
      const payload = { textInput: e.target.value };
      this.$emit('input', payload);
    },
    handleKeydown(data) {
      this.$emit('keydown', data);
    },
    handleKeyup(data) {
      this.$emit('keyup', data);
    },
    handleOpenBufferedTagsDropdown(data) {
      this.$emit('openBufferedTagsDropdown', data);
    },
    handleOpenActiveTagsDropdown(data) {
      this.$emit('openActiveTagsDropdown', data);
    },
    handleFocus() {
      this.isInputFocussed = true;
    },
    handleBlur() {
      this.isInputFocussed = false;
    },
    onIconClick(icon) {
      if (icon.emit && !this.isInputDisabled) {
        this.$emit(icon.emit, this);
      }
    },
    handlePaste(e) {
      if (this.pasteSplit) {
        this.$emit('paste', e);
      }
    },
    handleTextBoxClick() {
      this.focusAtInputField();
    },
    componentClicked(e) {
      // const start = performance.now()
      if (!this.isInputDisabled) {
        this.focusAtInputField();
        this.$emit('componentClick', this);
      }
      // const end = performance.now()
      // console.log('componentClicked', end - start)
    },
    handleBufferedTagClick(data) {
      this.$emit('bufferedTagClicked', data);
    },
    handleTagClick(data) {
      this.$emit('tagClicked', data);
    },
    focusAtInputField() {
      this.$refs['input-field'].focus();
    },
    checkNewTag() {
      return this.localActiveTags[0]?.type === 'newTagTrigger';
    }
  }
};
</script>

<style lang="css" scoped>
.input-box {
  border-radius: 2px;
  border: 1px solid #e9eaeb;
}
.tag-input-text {
  border: none;
  outline: none;
  background: none;
}
.tag-input-text::placeholder {
  color: #caccce;
  line-height: 30px;
}

.editable-tag {
  position: relative;
  top: -5px;
}
</style>
