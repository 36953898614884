<template>
  <section
    class="campaign-creation-edit u-position-fixed widget--full-screen"
    @click.stop="removeFocus"
  >
    <div class="u-position-fixed model u-width-100 campaign-creation-edit">
      <!-- Header Start -->
      <section
        class="campaign-creation-edit--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span @click="closeWithoutSave()">
              <rb-icon
                icon="right-arrow"
                data-cy="rightArrow"
                class="u-color-grey-lighter rb-icon--large u-cursor-pointer is-flipped u-spacing-mr-s"
              />
            </span>
            <!-- <div
              class="u-display-flex"
              :style="{
                width:
                  suggestedCamapignName.length * 11.5 < 800
                    ? `${suggestedCamapignName.length * 11}px`
                    : '60%'
              }"
            >
              <input
                ref="campaign-creation-input"
                v-model="suggestedCamapignName"
                type="text"
                :class="showEdit ? 'u-pointer-events-none text-underline' : ''"
                class="heading--input u-width-100 u-text-overflow-ellipsis u-font-size-2 u-font-weight-bold"
                @click.self.stop=""
                @input="updateSuggestedCampaignName"
              >
            </div> -->

            <div
              class="u-display-flex"
              :style="{ width: headerStyle() }"
            >
              <input
                ref="campaign-creation-input"
                v-model="suggestedCamapignName"
                type="text"
                data-cy="suggestedCampaignNameAndEdit"
                class="heading--input u-width-100 u-font-size-2 u-font-weight-bold text-underline"
                :class="
                  showEdit || suggestedCamapignName.length === 0
                    ? 'u-pointer-events-none u-text-overflow-ellipsis text-underline-color-white'
                    : ''
                "
                style="padding-left: 2px"
                @click.self.stop="() => {}"
                @input="updateSuggestedCampaignName"
              />
            </div>

            <div
              v-if="showEdit"
              class=""
              @click.stop="onEditCampaignName"
            >
              <rb-icon
                class="rb-icon--x-medium u-cursor-pointer u-spacing-ml-s u-color-grey-lighter"
                data-cy="pencilIcon"
                :icon="'pencil'"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- Header ends -->
      <!-- Scroll section -->
      <StepComponent
        :key="stepKey"
        :config="stepsConfig"
        :panel-width="10"
        :all-steps-data="pageData"
        :show-step-bullet-description="true"
        bullet-point-class="u-spacing-pt-m"
        all-bullet-points-class="marign-top-campaign-creation-bullets"
        class="u-width-100"
        :edit-suggestted="editSuggestted"
        :enabled-bread-crumbs="enabledBreadCrumbs"
        @vueRef="setStepComponentRef"
        @createAdGroup="createAdGroup"
        @onStepNextBtnDisabled="handleStepNextBtnDisabled"
      />
      <!-- Scroll section -->
      <loader
        v-show="loading"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/campaign_creation/edit_configs/index.js';
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import loader from '@/components/basic/loader';
import modal from '@/components/widgets/modal.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import Vue from 'vue';
import moment from 'moment-timezone';
import HttpService from '@/utils/services/http-service';
import { cloneDeep, setWith } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    StepComponent,
    loader,
    modal
  },
  mixins: [stepsMixin],
  data() {
    return {
      stepKey: 0,
      stepComponentRef: null,
      cloneStepConfig: [],
      showEdit: true,
      biddingStrategyMap: {
        legacyForSales: 'Dynamic bids - down only',
        autoForSales: 'Dynamic bids - up and down',
        manual: 'Fixed bids'
      },
      campaignTypeMap: {
        sponsoredProducts: 'sponsoredProducts',
        createOwnCampaignSP: 'sponsoredProducts',
        sponsoredBrands: 'sponsoredBrands',
        createOwnCampaignSB: 'sponsoredBrands'
      },
      enabledBreadCrumbs: [0],
      stepsConfig: [],
      suggestedCamapignName: 'Suggested Campaign name',
      header: {},
      pageData: null,
      selectedAdformat: null,
      suggestedCampaignIndex: 0,
      campaignType: 'sp',
      loading: false,
      selectedCampaignType: 'createOwnCampaignSP',
      selectedSpTargetingType: 'manual' // manual / auto
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters[
        this.pageData?.getter || 'campaignCreation/getAllStepsData'
      ];
    },
    isCreateAdGroupFlow() {
      if (this.$route.name === 'create_ad_group' || this.$route?.query?.own) {
        return true;
      } else {
        return false;
      }
    },
    editSuggestted() {
      return this?.$route?.query?.edit;
    }
  },
  watch: {
    allStepsData: {
      handler(newVal, oldValue) {
        // TODO: Move custom code to step config files
        const { adFormat, adFormatSubType, campaignType, spTargetingType } =
          newVal?.suggestedCampaigns?.[this.suggestedCampaignIndex] || {};
        if (this.$route?.query?.own && this.$route?.query?.edit) {
          this.formatStepDescription();
        }
        if (this.$route?.query?.own) {
          this.formatStepDescription();
          if (this.selectedCampaignType !== campaignType) {
            this.selectedSpTargetingType = 'manual';
            this.setRetailerPage();
            this.selectedCampaignType = campaignType;
          } else if (spTargetingType !== this.selectedSpTargetingType) {
            this.selectedSpTargetingType = spTargetingType;
            this.setRetailerPage();
          }
        }

        /** start Watch for SB */
        const supportedCampaignTypes = [
          'sponsoredBrands',
          'createOwnCampaignSB'
        ];
        if (
          !supportedCampaignTypes.includes(campaignType) ||
          (this.stepComponentRef?.activeSection !==
            this.pageData?.disableForActiveSelection &&
            this.pageData?.disableForActiveSelection)
        ) {
          return;
        }
        if (
          (adFormat === 'productCollection' &&
            adFormatSubType !== 'new_landing_page') ||
          adFormat === 'storeSpotlight'
        ) {
          this.stepsConfig = cloneDeep(
            this.stepsConfig.filter((item) => item.key !== 'adgroup')
          );
        } else if (this.selectedAdformat !== `${adFormatSubType}_${adFormat}`) {
          this.setStepConfig(this.stepsConfig);
        }
        this.selectedAdformat = `${adFormatSubType}_${adFormat}`;
        /** end watch for SB */
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.setRetailerPage();
  },
  methods: {
    handleStepNextBtnDisabled(value) {
      const allStepsData = { ...this.allStepsData };
      if (this.pageData.enableStepsAfterValidation) {
        const { disabled, index } = value;
        const campaignId = this?.$route?.query?.key?.split('-')?.[0];
        let enabledBreadCrumbs = allStepsData?.enabledBreadCrumbsMap?.[
          campaignId
        ] || [0];
        const stepIndexToEnable = index + 1;
        const indexOfStepIndex = enabledBreadCrumbs.indexOf(stepIndexToEnable);
        if (!disabled && indexOfStepIndex === -1) {
          enabledBreadCrumbs.push(stepIndexToEnable);
        }
        if (disabled && indexOfStepIndex > -1) {
          enabledBreadCrumbs = enabledBreadCrumbs.filter(
            (i) => i < indexOfStepIndex
          );
        }
        this.enabledBreadCrumbs = enabledBreadCrumbs;
        setWith(
          allStepsData,
          `enabledBreadCrumbsMap['${campaignId}']`,
          enabledBreadCrumbs,
          Object
        );
        this.$store.dispatch(this.pageData.action, { ...allStepsData });
      }
    },
    setRetailerPage() {
      this.campaignType = this.getCampaignType();
      this.header = config?.[this.retailer]?.[this.campaignType]?.header;
      this.pageData = config?.[this.retailer]?.[this.campaignType];
      this.setStepConfig();
      this.formatStepDescription();
      this.setCampaignName();
      if (this.isCreateAdGroupFlow) {
        this.getCreateAdGroupPageData();
      }
    },
    setStepComponentRef(ref) {
      this.stepComponentRef = ref;
    },
    createAdGroup() {
      this.$emit('createAdGroup');
    },
    async getCreateAdGroupPageData() {
      try {
        const { campaignType } =
          this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
        const createOwnCampaign = this.$route?.query?.own;
        this.loading = true;
        const pageData = await dashUtils.getPageWidgets(
          0,
          'cm_own_campaign_creation'
        );
        this.pageData = { ...this.pageData, ...pageData?.data };
        // Filter out only the sp create your own campaign creation widget
        let defaultWidgetName = 'cc_sp_create_own_campaign';
        if (createOwnCampaign) {
          const createMap = {
            sponsoredBrands: 'cc_sb_create_own_campaign',
            sponsoredProducts: 'cc_sp_create_own_campaign',
            createAdGroupSp: 'cc_sp_create_own_campaign',
            createAdGroupSd: 'cc_sd_create_own_campaign',
            createOwnCampaignSP: 'cc_sp_create_own_campaign',
            createOwnCampaignSB: 'cc_sb_create_own_campaign'
          };
          defaultWidgetName = createMap[campaignType];
        }
        const filteredWidgetForSpAdGroupCreate = this.pageData.widgets.filter(
          (item) => item.widgetName === defaultWidgetName
        );
        const allGoals = this.pageData.widgets;
        const widget = await dashUtils.getWidgetMetadata(
          filteredWidgetForSpAdGroupCreate[0],
          this.pageData?.id
        );
        const goalConfig = {
          goalConfig: {
            customMetadata: widget?.[0]?.data?.customMetadata,
            metadata: widget?.[0]?.data?.metadata,
            label: widget?.[0]?.data?.label
          }
        };
        let campaignDetails = null;
        if (!createOwnCampaign) {
          const campaignDetailsRequestPayload = {
            retailer: this.retailer || 'amazon',
            displayRequest: {
              profileId: null,
              type: 'CAMPAIGNS',
              id: this.$route.params.entityId,
              filters: {}
            },
            campaignType: this.$route?.params?.entityType?.toUpperCase()
          };

          const response = await HttpService.post(
            'FETCH_AMS_ACTIONS_MANAGER',
            campaignDetailsRequestPayload
          );

          if (!response?.data?.status === 'OK') {
            throw new Error('No response from fetch api');
          }
          campaignDetails = response?.data?.response;
        }

        const allStepsData = { ...this.allStepsData };
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].biddingStrategy =
          campaignDetails?.bidding?.strategy ||
          allStepsData.suggestedCampaigns[this.suggestedCampaignIndex]
            .biddingStrategy ||
          'legacyForSales';
        this.$store.dispatch(this.pageData.action, {
          ...this.allStepsData,
          allGoals,
          ...goalConfig,
          campaignDetails: campaignDetails
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
        const campaignId = this?.$route?.query?.key?.split('-')?.[0];

        this.$snackbar.open({
          message: 'An error occurred.',
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ''
        });

        this.$router.push({
          name: this.$route?.query?.own
            ? 'setup_suggested_campaign'
            : 'EntityDetails',
          params: this.$route?.query?.own
            ? {
                edit: true
              }
            : {
                entityId: campaignId,
                entityType: 'sp'
              }
        });
        // reroute back to the old screen
      }
    },
    getCampaignType() {
      const campaignType =
        this.allStepsData.suggestedCampaigns?.[this.suggestedCampaignIndex]
          ?.campaignType;
      const campaignTypeMap = {
        sponsoredBrands: 'sb',
        sponsoredProducts: 'sp',
        createAdGroupSp: 'createAdGroupSp',
        createAdGroupSd: 'createAdGroupSd',
        createOwnCampaignSP: 'createOwnCampaignSP',
        createOwnCampaignSB: 'createOwnCampaignSB'
      };
      return campaignTypeMap[campaignType];
    },
    removeFocus() {
      this.$refs?.['campaign-creation-input']?.blur();
      this.showEdit = true;
    },
    onEditCampaignName() {
      this.$refs?.['campaign-creation-input']?.focus();
      this.showEdit = false;
    },
    setStepConfig(oldStepConfig) {
      const { targetingType } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex] ||
        'keyword';
      let stepsConfig = cloneDeep(
        config?.[this.retailer]?.[this.campaignType]?.stepsConfig
      );
      this.restoreDescripitons(oldStepConfig, stepsConfig);
      if (this.selectedSpTargetingType === 'auto') {
        stepsConfig = cloneDeep(
          config?.[this.retailer]?.[this.campaignType]?.autoCampaignStepConfigs
        );
        this.stepsConfig = stepsConfig?.filter((step) => {
          return step?.autoTargetingVisibility === true;
        });
        return;
      }
      this.stepsConfig = stepsConfig?.filter((step) =>
        step?.targetingType?.includes(targetingType)
      );
      Object.keys(this.stepsConfig).forEach((key, index) => {
        this.stepsConfig[key].index = index;
      });
    },
    restoreDescripitons(oldStepConfig, newStepConfig) {
      if (!oldStepConfig) return;
      for (let oldStep of oldStepConfig) {
        for (let newStep of newStepConfig) {
          if (oldStep?.description && oldStep?.key === newStep?.key) {
            newStep.description = oldStep.description;
          }
        }
      }
    },
    formatDateUI(value) {
      if (!value) return 'No end date';
      return moment(value, 'YYYYMMDD').format('ll');
    },
    formatStepDescription() {
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (!suggestedCampaign) {
        this.$router.push({
          name: 'create_campaigns'
        });
      }
      for (const step of this.stepsConfig) {
        let description = '';
        step.incomplete = false;
        if (step.key === 'adgroup') {
          const skuLength = suggestedCampaign?.skus?.length || 0;
          description = `${skuLength} SKU${skuLength !== 1 ? "'s" : ''} added`;
          skuLength || skuLength < step.requiredRows
            ? (step.incomplete = true)
            : (step.incomplete = false);
          step.addedRows = skuLength;
        } else if (step.key === 'ad_format') {
          const adFormatMap = {
            productCollection: 'Product Collection',
            storeSpotlight: 'Store Spotlight',
            video: 'Video'
          };
          if (
            suggestedCampaign?.adFormat &&
            adFormatMap[suggestedCampaign?.adFormat]
          ) {
            description = `Ad Format: ${
              adFormatMap[suggestedCampaign?.adFormat]
            }`;
          } else {
            description = 'Select Ad Format';
          }
        } else if (step.key === 'keywordTarget') {
          const targetsLength = suggestedCampaign?.targets?.length || 0;
          description = `${targetsLength} keyword${
            targetsLength !== 1 ? "'s" : ''
          } added`;
          targetsLength || targetsLength < step.requiredRows
            ? (step.incomplete = true)
            : (step.incomplete = false);
          step.addedRows = targetsLength;
        } else if (step.key === 'productTarget') {
          const targetsLength = suggestedCampaign?.targets?.length || 0;
          description = `${targetsLength} target${
            targetsLength !== 1 ? "'s" : ''
          } added`;
        } else if (step.key === 'biddingStrategy') {
          const { campaignType, bidOptimization, biddingStrategy } =
            suggestedCampaign;
          if (this.campaignTypeMap[campaignType] === 'sponsoredProducts') {
            description = this.biddingStrategyMap[biddingStrategy];
          } else if (this.campaignTypeMap[campaignType] === 'sponsoredBrands') {
            description = bidOptimization
              ? 'Automated bidding'
              : 'Custom bid adjustment';
          }
        } else if (step.key === 'classification') {
          description = `Selected profile: ${
            suggestedCampaign?.profile?.profile_name || 'NA'
          } and portfolio: ${suggestedCampaign?.portfolio?.portfolio_name}`;
        } else if (step.key === 'budget') {
          const budgetType = suggestedCampaign?.budgetType?.title || 'Daily';
          description = `${budgetType} budget: ${Vue.prototype.$currency}${suggestedCampaign?.budget}`;
        } else if (step.key === 'timePeriod') {
          description = `${this.formatDateUI(
            suggestedCampaign?.startDate
          )} to ${this.formatDateUI(suggestedCampaign?.endDate)}`;
        } else if (step.key === 'automaticTargeting') {
          description = step.getDescription(suggestedCampaign);
        }
        step.description = description;
      }
    },
    updateSuggestedCampaignName() {
      const allStepsData = { ...this.allStepsData };
      allStepsData.suggestedCampaigns[
        this.suggestedCampaignIndex
      ].suggestedCampaignName = this.suggestedCamapignName;
      this.$store.dispatch(this.pageData.action, { ...allStepsData });
    },
    setCampaignName() {
      this.suggestedCamapignName =
        this.allStepsData?.suggestedCampaigns[
          this.suggestedCampaignIndex
        ]?.suggestedCampaignName;
    },
    closeWithoutSave() {
      const isManualCampaign = this.$route?.query?.manual;
      if (this.isCreateAdGroupFlow && !this.$route?.query?.own) {
        const campaignId = this?.$route?.query?.key?.split('-')?.[0];
        this.$router.push({
          name: 'EntityDetails',
          params: {
            entityId: campaignId,
            entityType: this.campaignType === 'createAdGroupSd' ? 'sd' : 'sp'
          }
        });
      } else {
        this.$router.push({
          name: 'setup_suggested_campaign',
          query: {
            edit: true,
            manual: isManualCampaign
          }
        });
      }
    },
    headerStyle() {
      return this.showEdit
        ? this.suggestedCamapignName.length === 0
          ? '23ch'
          : this.suggestedCamapignName.length * 11.5 < 800
          ? `${this.suggestedCamapignName.length * 10.05}px`
          : '42%'
        : '100%';
    }
  }
};
</script>

<style lang="css">
.campaign-creation-edit {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.campaign-creation-edit .text-underline {
  text-decoration: underline;
  text-decoration-color: #8b8f93;
  text-decoration-thickness: 4px;
}
.campaign-creation-edit .heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.campaign-creation-edit .heading--input:focus {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.campaign-creation-edit .heading--input:focus:focus {
  border: 0;
  outline: none;
}
.campaign-creation-edit .heading--input .u-width-max-content {
  width: max-content;
}
.campaign-creation-edit .step-component-v2 .scrollable {
  padding-top: 0px;
  height: calc(100vh - 84px);
}
.campaign-creation-edit
  .step-component-v2
  .marign-top-campaign-creation-bullets {
  margin-top: 80px;
  padding-left: 8px;
}
.campaign-creation-edit .campaign-creation-edit--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
</style>
