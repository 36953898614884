import Vue from 'vue';
import moment from 'moment-timezone';

const columns = (vueRef) => {
  const currentYear = new Date().getFullYear();
  const year = vueRef?.$route?.query?.nextYearPlan
    ? currentYear + 1
    : currentYear;
  return {
    masterTable: [
      {
        key: 'businessScopeName',
        name: 'Scope',
        order: 1,
        showOnUI: true,
        pinned: 'left',
        type: 'LABEL'
      },
      {
        key: 'estimatedPcogs',
        name: 'Estimated PCOGS',
        order: 2,
        showOnUI: true,
        type: 'PCOGS'
      },
      {
        key: 'finalBudgetPlan',
        name: 'Budget Plan ' + year,
        order: 3,
        showOnUI: true,
        type: 'CUSTOM'
      }
    ],
    slaveTable: [
      {
        key: 'itemLabel',
        name: 'Scope',
        order: 1,
        showOnUI: true,
        pinned: 'left',
        type: 'LABEL'
      },
      {
        key: 'displayValue',
        name: 'Budget Plan ' + year,
        order: 2,
        showOnUI: true,
        type: 'CUSTOM'
      }
    ]
  };
};

const rowClassRules = {
  'is-eb': "data.businessScope === 'ENTIRE_BUSINESS'",
  'is-profile': "data.businessScope === 'PROFILE'",
  'is-portfolio': "data.businessScope === 'PORTFOLIO'"
};
const rowClassRulesSlave = {
  'is-quarter': (params) => {
    return params && params.data && !params.data.type;
  }
};

const customLabelComponent = Vue.extend({
  computed: {
    cellValue() {
      return this.params.data[this.params.key] || '-';
    }
  },
  template:
    '<div :title="cellValue" class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left u-text-overflow-ellipsis">{{cellValue}} <rb-icon v-if="params.key === \'businessScopeName\'" class="distribute-icon u-position-absolute rb-icon--x-medium u-color-blue-base" style="right:8px; top:12px;" icon="split"></rb-icon></div>'
});

const customNumValueComponent = Vue.extend({
  computed: {
    cellValue() {
      const value = this.params.data[this.params.key];
      return value || value === 0 ? value : '-';
    }
  },
  template:
    '<div class="u-width-100 u-color-grey-base u-display-flex u-text-align-left u-flex-align-items-center"> <span class="u-display-flex">{{cellValue | num_format( "currency" )}}</span> <span class="u-display-flex u-font-size-7 u-spacing-ml-s percentageText" v-if="params.key !== \'estimatedPcogs\' && params.data.percentageText && cellValue !== \'-\'" v-html="params.data.percentageText"></span></div>'
});

function getColumnDefinition(vueRef, tableType) {
  const _columnDefs = [];
  let columnDefs = [];
  const columnObject = columns(vueRef);
  const columnArr = columnObject[tableType];
  // Pushing edit column always
  for (let i = 0; i < columnArr.length; i++) {
    const col = columnArr[i];
    if (!col.showOnUI) {
      continue;
    }
    const obj = {};
    obj.headerComponentFramework = 'genericTableHeader';
    obj.field = col.key;
    obj.headerName = col.name;
    obj.title = col.name;
    obj.order = col.order;
    obj.pinned = col.pinned;
    obj.dataType = col.type;
    obj.cellRendererFramework =
      col.type !== 'LABEL' ? customNumValueComponent : customLabelComponent;
    obj.cellRendererParams = col;
    obj.minWidth = 120;
    if (col.type === 'PCOGS') {
      obj.width = 100;
    }
    if (col.type === 'CUSTOM') {
      obj.width = 140;
    }
    const PCOGS_DISABLED_RETAILERS = ['target', 'instacart'];
    if (
      col.type === 'PCOGS' &&
      PCOGS_DISABLED_RETAILERS.includes(vueRef.$store.getters.getRetailer)
    ) {
      col.showOnUI = false;
    }
    _columnDefs.push(obj);
  }
  // /**
  //  * Sort based on key order
  //  */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.order - b.order;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      getColumnDefinition: getColumnDefinition,
      widgets: {
        masterTableWidget: {
          header: {
            title: 'Profile and portfolio distribution',
            desc: [
              "Here's your budget plan distributed across profiles and portfolios."
            ]
          },
          levels: [
            {
              level: 1,
              getter: 'getPreviewTableProfiles',
              action: 'setPreviewTableProfiles'
            },
            {
              level: 2,
              getter: 'getPreviewTablePortfolios',
              action: 'setPreviewTablePortfolios'
            }
          ],
          gridOptions: {
            onRowClicked: that.setPreviewActiveEntity,
            context: {
              componentParent: this
            },
            rowClassRules: rowClassRules
          },
          body: {
            APIConfig: {}
          },
          columnDefs: getColumnDefinition(that, 'masterTable')
        },
        walmartMasterTableWidget: {
          header: {
            title: 'Advertiser distribution',
            desc: ["Here's your budget plan distributed across advertisers."]
          },
          levels: [
            {
              level: 1,
              getter: 'getPreviewTableProfiles',
              action: 'setPreviewTableProfiles'
            },
            {
              level: 2,
              getter: 'getPreviewTablePortfolios',
              action: 'setPreviewTablePortfolios'
            }
          ],
          gridOptions: {
            onRowClicked: that.setPreviewActiveEntity,
            context: {
              componentParent: this
            },
            rowClassRules: rowClassRules
          },
          body: {
            APIConfig: {}
          },
          columnDefs: getColumnDefinition(that, 'masterTable')
        },
        targetMasterTableWidget: {
          header: {
            title: 'Brand distribution',
            desc: ["Here's your budget plan distributed across brands."]
          },
          levels: [
            {
              level: 1,
              getter: 'getPreviewTableProfiles',
              action: 'setPreviewTableProfiles'
            },
            {
              level: 2,
              getter: 'getPreviewTablePortfolios',
              action: 'setPreviewTablePortfolios'
            }
          ],
          gridOptions: {
            onRowClicked: that.setPreviewActiveEntity,
            context: {
              componentParent: this
            },
            rowClassRules: rowClassRules
          },
          body: {
            APIConfig: {}
          },
          columnDefs: getColumnDefinition(that, 'masterTable')
        },
        instacartMasterTableWidget: {
          header: {
            title: 'Brand and portfolio distribution',
            desc: [
              "Here's your budget plan distributed across brands and portfolios."
            ]
          },
          levels: [
            {
              level: 1,
              getter: 'getPreviewTableProfiles',
              action: 'setPreviewTableProfiles'
            },
            {
              level: 2,
              getter: 'getPreviewTablePortfolios',
              action: 'setPreviewTablePortfolios'
            }
          ],
          gridOptions: {
            onRowClicked: that.setPreviewActiveEntity,
            context: {
              componentParent: this
            },
            rowClassRules: rowClassRules
          },
          body: {
            APIConfig: {}
          },
          columnDefs: getColumnDefinition(that, 'masterTable')
        },
        slaveTableWidget: {
          header: {
            title: 'Monthly and quarterly distribution',
            desc: [
              'Here is the monthly and quarterly distribution of the selection made.'
            ]
          },
          levels: [
            {
              level: 1,
              getter: 'getCurrentQuarterData',
              action: 'setCurrentQuarterData'
            }
          ],
          gridOptions: {
            context: {
              componentParent: this
            },
            rowClassRules: rowClassRulesSlave
          },
          body: {
            APIConfig: {}
          },
          columnDefs: getColumnDefinition(that, 'slaveTable')
        }
      }
    };
  }
};
