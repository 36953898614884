<template>
  <div
    v-if="showWidget"
    class="listings-health-table u-box-shadow-xx-light u-bg-color-grey-white"
  >
    <div class="is-expanded" />
    <dashboard-service-table
      v-bind="$props"
      :enable-client-side-sorting="false"
      :context="componentContext"
      :widget-name="widgetName"
      :metadata="modifiedMetadata"
      :widget-data-service="dataService"
      :watch-for-metadata-changes="true"
      :grid-options="gridOptions"
      :widget-request-params="widgetRequestParamsModified"
      :row-height="60"
      :header-options="headerOptionsComputed"
      @tableDataUpdated="tableDataUpdated"
    />
  </div>
</template>

<script>
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import ListingsHealthDashboardDataService from './listingHealthDashboardService';
import { cloneDeep, isEqual } from 'lodash';
import Vue from 'vue';
import AutomationTableHeader from '@/components/pages/listingsHealth/AutomationTableHeader.vue';
import tabs from '@/components/pages/insights/amazon/share-of-voice/molecules/tabs.vue';
import chipSelect from '@/components/basic/chipSelect.vue';
import iconText from '@/components/basic/iconText.vue';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'ListingsHealthTable',
  components: {
    dashboardServiceTable,
    tabs,
    iconText,
    chipSelect
  },
  mixins: [cwMixin],
  props: {
    showWidget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const componentContext = this;
    return {
      tableID: 0,
      automationType: 'unavailable',
      componentContext,
      gridOptions: {
        context: {
          componentParent: this
        },
        ensureDomOrder: true,
        enableCellTextSelection: true,
        postSort: (rowNodes) => {
          rowNodes.sort((a, b) => {
            return a.data.ciqIndex - b.data.ciqIndex;
          });
          return rowNodes;
        }
      },
      activeTab: 0,
      tabData: [
        { label: 'Current Ticket Status', alias: 'ticketStatus' },
        { label: "Today's Recommendation", alias: 'recommendation' }
      ],
      dataService: ListingsHealthDashboardDataService,
      widgetRequestParamsModified: {},
      metadataForTable: {},
      selectedRetailer: 'retail_and_3p',
      activeFilters: []
    };
  },
  computed: {
    headerOptionsComputed() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'AutomationTableHeader',
        props: {
          activeTabIndex: this.activeTab,
          activeTabName: this.activeTabName,
          handleTabClick: this.handleTabClick,
          tabData: this.tabData
        }
      });
      return headerOptions;
    },
    modifiedMetadata() {
      return this.metadataForTable;
    },
    activeTabName() {
      return this.tabData[this.activeTab].alias;
    }
  },
  watch: {
    activeTab(nv, ov) {
      if (isEqual(nv, ov)) {
        return;
      }
      this.updateTableWidgetRequestParams();
      this.$nextTick(() => {
        // !Updating in the next tick to avoid scenarios where the widgetRequestParams change was processed by Vue before the metadata change.
        this.reloadTable();
      });
    },
    widgetRequestParams: {
      handler(nV, oV) {
        if (isEqual(nV, oV)) {
          return;
        }
        this.updateTableWidgetRequestParams();
        this.$nextTick(() => {
          // !Updating in the next tick to avoid scenarios where the widgetRequestParams change was processed by Vue before the metadata change.
          this.reloadTable();
        });
      },
      deep: true
    }
  },
  created() {
    Vue.component('AutomationTableHeader', AutomationTableHeader);
    eventBus.$on('switchToTicketStatusTab', () => {
      this.handleTabClick(0);
    });
    eventBus.$on('selectedAutomationCardUpdated', (data) => {
      this.automationType = data;
      this.updateTableWidgetRequestParams();
      this.$nextTick(() => {
        // !Updating in the next tick to avoid scenarios where the widgetRequestParams change was processed by Vue before the metadata change.
        this.reloadTable(true);
      });
    });
    eventBus.$on('selectedRetailerUpdated', (data) => {
      this.selectedRetailer = data.value;
      this.updateTableWidgetRequestParams();
      this.$nextTick(() => {
        // !Updating in the next tick to avoid scenarios where the widgetRequestParams change was processed by Vue before the metadata change.
        this.reloadTable();
      });
    });
  },
  beforeDestroy() {
    eventBus.$off('switchToTicketStatusTab');
    eventBus.$off('selectedAutomationCardUpdated');
    eventBus.$off('selectedRetailerUpdated');
  },
  mounted() {
    this.updateTableWidgetRequestParams();
    this.$nextTick(() => {
      // !Updating in the next tick to avoid scenarios where the widgetRequestParams change was processed by Vue before the metadata change.
      this.reloadTable();
    });
  },
  methods: {
    updateTabSpecificMetadata() {
      const modifiedMetadata = cloneDeep(this.metadata);
      const metricsToBeIncluded =
        modifiedMetadata.metadata[this.automationType][
          this.tabData[this.activeTab].alias
        ] || [];
      const filteredMetrics = {};
      Object.values(modifiedMetadata.metrics).forEach((metric) => {
        if (metricsToBeIncluded.includes(metric.name)) {
          filteredMetrics[metric.keyName] = metric;
        }
      });
      modifiedMetadata.metrics = filteredMetrics;
      this.metadataForTable = modifiedMetadata;
    },
    updateActiveFilters() {
      const widgetRequestParams = cloneDeep(this.widgetRequestParams);
      const filters = widgetRequestParams[':dimensionNameValueList'].filter(
        (filterConfig) => filterConfig.dimensionName !== 'business_model'
      );
      if (
        this.selectedRetailer !== 'retail_and_3p' &&
        (this.activeTabName === 'recommendation' ||
          this.automationType === 'unavailable')
      ) {
        filters.push({
          dimensionName: 'business_model',
          dimensionValue: this.selectedRetailer,
          operator: 'ILIKE'
        });
      }
      this.activeFilters = filters;
    },
    updateTableWidgetRequestParams() {
      this.updateTabSpecificMetadata();
      this.updateActiveFilters();
    },
    reloadTable(resetColumnOrder = false) {
      const updatedWidgetRequestParams = cloneDeep(this.widgetRequestParams);
      updatedWidgetRequestParams[':dimensionNameValueList'] =
        this.activeFilters;
      updatedWidgetRequestParams.metadata.tableID = this.tableID++;
      if (this.activeTab === 1) {
        updatedWidgetRequestParams[':from'] = '';
        updatedWidgetRequestParams[':to'] = '';
        updatedWidgetRequestParams[':pvpFrom'] = '';
        updatedWidgetRequestParams[':pvpTo'] = '';
      }
      if (resetColumnOrder) {
        updatedWidgetRequestParams[':orderByList'] = [];
        updatedWidgetRequestParams.resetColumnOrder = true;
      }
      this.widgetRequestParamsModified = updatedWidgetRequestParams;
    },
    tableDataUpdated() {
      this.gridOptions.api.sizeColumnsToFit();
    },
    handleTabClick(index) {
      this.activeTab = index;
    }
  }
};
</script>

<style lang="css">
.listings-health-table .is-expanded {
  position: relative;
}
.listings-health-table .is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 16px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  left: 0em;
  clip-path: inset(0px -2px 0px -2px);
  z-index: 1;
}
.listings-health-table .header-container-cwc {
  padding-top: 0.4rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.listings-health-table
  .inputClassForEntityTaggingSearch:nth-child(2)
  > span:nth-child(2) {
  top: 8px !important;
  display: flex;
  flex-direction: column;
}
.listings-health-table
  .header-container-cwc
  > div:nth-child(1)
  > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  position: relative;
  top: 4px;
}
.listings-health-table .ag-body-viewport.ag-layout-auto-height {
  min-height: 300px;
  max-height: 500px !important;
  overflow: auto !important;
}
.listings-health-table .ag-pinned-left-cols-viewport {
  min-height: 300px;
  max-height: 500px !important;
  overflow: auto !important;
}
.listings-health-table .rb-data-table {
  height: 100% !important;
}
.listings-health-table .recommendation-description > div {
  display: flex;
  align-items: center;
}
.listings-health-table .successful-resolution {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(62, 169, 94, 0.15);
  color: #2b333b;
}
.listings-health-table .unsuccessful-resolution {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(43, 51, 59, 0.1);
  color: #2b333b;
}
.listings-health-table .pending-amazon-action {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(35, 181, 211, 0.15);
  color: #2b333b;
}
.listings-health-table .needs-your-attention {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: #2b333b;
  background: rgba(255, 96, 114, 0.15);
}
</style>
