import HttpService from '@/utils/services/http-service';
import {
  getBasicRequestFormat,
  performanceBreakDownSortMetrics
} from '@/components/pages/insights/amazon/market-share/utils';
import { metricCellDisplay } from '@/components/pages/insights/amazon/market-share/molecules/customCell.js';

export default class BreakdownWidgetService {
  metadata;
  isLoading;

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  brandIconParamsToProps(params) {
    let data = {
      name: params.value,
      imageUrl: '',
      preText: 'manufacturer:',
      manufacturer: params.data.manufacturer,
      showManufacturerTooltip: params.data.entityType === 'manufacturer',
      brandsList: params.data?.brands_list?.split(', ') || []
    };
    const formattedBrandName = data.name.replaceAll(' ', '_').toLowerCase();
    data.imageUrl = `https://cdn.rboomerang.com/images/brands/${formattedBrandName}.jpeg`;
    return { data };
  }

  getColumnDefs(viewBy, enableTooltip = true) {
    let columns = [
      {
        field: viewBy,
        headerName: `<span class="u-font-size-5 u-text-case-title">${viewBy}</span>`,
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'string'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'CategoryIconCell',
          eventMap: {},
          paramsToProps: this.brandIconParamsToProps.bind(this)
        },
        suppressSizeToFit: false,
        minWidth: 300
      },
      {
        headerName: 'Market Share',
        field: 'marketShareMetric',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: `Market Share of the brand in the selected category in the selected time period`,
          tooltipCustomTitle: 'Market Share'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'marketShareMetric',
            data2: 'PVP_DIFF_marketShareMetric',
            data1_type: 'marketShareMetric_absoluteType',
            invertTag: 'marketShareMetric_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        field: 'sales',
        headerComponentFramework: 'genericTableHeader',
        headerName: 'Sales',
        title: 'Sales',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'The sum of estimated sales of all SKUs of the brand in the selected category in the selected time period',
          tooltipCustomTitle: 'Sales'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'sales',
            data2: 'PVP_sales',
            data1_type: 'sales_absoluteType',
            invertTag: 'sales_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      }
    ];
    return [...columns];
  }

  updateColDefs(viewBy, sortConfiguration) {
    let columnDefs = this.getColumnDefs(viewBy);

    for (let columnId = columnDefs.length - 1; columnId >= 1; columnId--) {
      const metricName = columnDefs[columnId].field;
      if (
        performanceBreakDownSortMetrics[sortConfiguration.metric] === metricName
      ) {
        const columnName = columnDefs[columnId].headerName;
        columnDefs[columnId].headerName = this.generateCustomHeader(
          sortConfiguration.sortingOptions.icons,
          columnName
        );
      }
    }
    return columnDefs;
  }

  generateCustomHeader(icons, columnName) {
    return `<div class="u-flex-direction-row u-display-flex u-flex-justify-content-space-between">
    <span class="u-display-flex u-flex-direction-row" style="margin-right: 24rem;"> 
            ${icons
              .map(
                (icon) =>
                  `<span class="icon-${icon} u-color-blue-base rb-icon--small"></span>`
              )
              .join('')}
          </span>
          <span class="u-font-size-5">${columnName}</span></div>`;
  }

  async getMetadata() {
    try {
      this.isLoading = true;
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = data;
      this.isLoading = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.isLoading = false;
    }
  }

  updateParentPayload(parentRequest, config) {
    parentRequest.operations.orderByList = [];
    parentRequest.operations.bundleRequestJoinType = 'FULL_OUTER_JOIN';
    parentRequest.operations.isChainingJoinEnabled = true;
    parentRequest.operations.limit = config?.body.APIConfig.limit;
    parentRequest.operations.page = config?.body.APIConfig.page;
    return parentRequest;
  }

  constructPayload(
    globalSettings,
    entityType,
    config,
    metricDataGroupMapping,
    isSortEnabled,
    sortConfiguration
  ) {
    let parentRequest = getBasicRequestFormat(
      this.metadata.metadata.dataAPI.request,
      globalSettings,
      entityType
    );
    parentRequest.where.dimensionNameValueList = [
      ...globalSettings.where.dimensionNameValueList
    ];
    parentRequest = this.updateParentPayload(parentRequest, config);
    for (const metricKey in metricDataGroupMapping) {
      const metricData = metricDataGroupMapping[metricKey];
      if (isSortEnabled) {
        const sortByMetric = sortConfiguration.metric;
        if (performanceBreakDownSortMetrics[sortByMetric] === metricKey) {
          const metricUnit = metricDataGroupMapping[metricKey].metricUnit;
          const PVP_DIFF_TYPE =
            metricUnit === 'PERCENTAGE' ? 'pvp_diff_' : 'pvp_';
          let metricName = metricDataGroupMapping[metricKey].name;
          if (sortConfiguration.sortingOptions.sortByChange) {
            metricName = `${PVP_DIFF_TYPE}${metricDataGroupMapping[metricKey].name}`;
          }
          parentRequest.operations.orderByList.push({
            dimension: metricName,
            direction: sortConfiguration.sortingOptions.direction
          });
        }
      }
      parentRequest.metricsList.push(metricData.metricList);
    }
    if (globalSettings?.settingValue?.viewBy?.entityType === 'manufacturer') {
      parentRequest.metricsList.push('brands_list_v2');
      const indexOfDimension = parentRequest.dimensionsList.indexOf(
        globalSettings?.settingValue?.viewBy?.entityType
      );
      parentRequest.dimensionsList.splice(indexOfDimension, 1);
    }
    return parentRequest;
  }

  async getData(
    metricDataGroupMapping,
    entityType,
    subCategoryNode,
    globalSettings,
    selectedCategory,
    config,
    sortConfiguration
  ) {
    this.isLoading = true;
    const dataRequest = this.constructPayload(
      globalSettings,
      entityType,
      config,
      metricDataGroupMapping,
      true,
      sortConfiguration
    );
    try {
      const response = await HttpService.post('MARKETSHARE', dataRequest, {
        append: '/marketshare/metrics/data/exec'
      });
      this.isLoading = false;
      return response?.data?.entityData || [];
    } catch (error) {
      console.log('error', error);
      this.isLoading = false;
    }
  }
}
