<template>
  <div
    ref="shimmer"
    class="shimmer"
    style="{
      height: height,
      'max-width': width
    }"
  />
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    }
  }
};
</script>

<style lang="css" scoped>
.shimmer {
  background: #e9eaeb;
  background-size: 250%;
  background-image: linear-gradient(
    to right,
    #e9eaeb 8%,
    #ffffffcc 18%,
    #e9eaeb 33%
  );
  border-radius: 2px;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  /* // animation-duration: 1.5s;
  // animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
  // animation-name: shimmerMove;
  // animation-timing-function: linear; */
}

@keyframes shimmerMove {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
</style>
