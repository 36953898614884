import { SelectorGroup } from './selector-group';

export type DisabledHook =
  | ((id: string) => {
      disabled: boolean;
      tooltip: string;
    })
  | undefined;
export interface Selections {
  [key: string]: boolean | null;
}

/**
 * State of the UI, how the selectors should be selected, what are their names etc?
 * @date 8/22/2022 - 10:19:03 AM
 *
 * @export
 * @interface UIState
 * @typedef {UIState}
 */
export interface UIState {
  /**
   * icon which has to be used for rendering this element
   * @date 8/22/2022 - 10:20:24 AM
   *
   * @type {string}
   */
  icon: string;

  /**
   * true iff selector is selected
   * @date 9/21/2022 - 12:22:46 PM
   *
   * @type {(boolean | null)}
   */
  selected: boolean | null;

  /**
   * Selector's text that should show up on the UI
   * @date 8/22/2022 - 10:20:58 AM
   *
   * @type {string}
   */
  title: string;
  // TODO: Can we get rid of this?
  text: string;

  /**
   * Icon's color
   * @date 8/22/2022 - 10:22:37 AM
   *
   * @type {string}
   */
  iconColorClass: string;

  /**
   * Children's UI state, undefined if no children.
   * @date 8/22/2022 - 10:23:09 AM
   *
   * @type {(UIState[] | undefined)}
   */
  children: UIState[] | undefined;

  /**
   * True iff the selector is disabled
   * @date 8/22/2022 - 1:08:19 PM
   *
   * @type {boolean}
   */
  disabled:
    | {
        tooltip: string;
      }
    | undefined;
}

export abstract class Selector {
  abstract isSelected: boolean | null;

  private _disabledHook: DisabledHook = undefined;

  protected abstract _select(childSelect: boolean): void;
  protected abstract _deselect(): void;
  public childrenGroup: SelectorGroup<Selector> | null = null;
  abstract handleClick(): void;
  abstract id: string;
  abstract icon: string;
  // eslint-disable-next-line no-useless-constructor
  constructor(public group: SelectorGroup<Selector>) {}

  select(childSelect: boolean) {
    if (this.disabled !== undefined) {
      return;
    }
    this._select(childSelect);
  }

  deselect() {
    if (this.disabled !== undefined) {
      return;
    }
    this._deselect();
  }

  registerDisabledHook(fn: DisabledHook) {
    this._disabledHook = fn;
  }

  get disabled():
    | {
        tooltip: string;
      }
    | undefined {
    if (this._disabledHook) {
      return this._disabledHook?.(constructIdentifier(this.group.parent, this));
    } else {
      return undefined;
    }
  }

  get iconColor(): string {
    if (this.isSelected === true || this.isSelected === null) {
      return 'u-color-blue-base';
    } else {
      return 'u-color-grey-lighter';
    }
  }

  public addChildGroup(childGroup: SelectorGroup<Selector>) {
    this.childrenGroup = childGroup;
  }

  get selections(): { [key: string]: boolean | null } {
    if (this.childrenGroup !== null) {
      const selections = this.childrenGroup?.selectorsArray
        .map((child) => child.selections)
        .reduce(function (acc, x) {
          for (var key in x) acc[key] = x[key];
          return acc;
        }, {});
      return {
        [constructIdentifier(this.group.parent, this)]: this.isSelected,
        ...selections
      };
    } else {
      return {
        [constructIdentifier(this.group.parent, this)]: this.isSelected
      };
    }
  }
}

export function constructIdentifier(
  parent: Selector | null,
  current: Selector
): string {
  if (parent === null) {
    return current.id;
  } else {
    return (
      constructIdentifier(parent.group.parent, parent) + '----' + current.id
    );
  }
}
