<template>
  <div
    class="u-display-flex u-flex-direction-column u-font-size-1 ams-campaign-management"
  >
    <messageBar
      :show="showBlueBar"
      :page-type="'campaigns'"
      @hideAction="hideBlueBar"
    />
    <modal
      v-if="showEmailPopUp"
      :modal-config="modalConfig"
      @clickedOutside="clickedOutsideModal"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="getShowInstaCampaignsActivityLog || showActionsLog"
        :data="getActivityLogData"
        :page-name="'campaigns'"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <div
      class="u-display-flex u-bg-color-grey-white u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="insta-campaigns-filters"
        :key="filterKey"
        :page="'campaigns_campaign'"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :additional-ranges="additionalRanges"
        :listener-event="'instacart_campaigns-filters'"
        :state="filterState"
        :config="config.filters"
        :emit="config.filters.emit"
        :allow-date-compare="true"
        :enable-save="true"
        :has-search="true"
        :loader-status="loaderStatus"
        :new-date="true"
        :default-date-post-range="'last30Days'"
        :max-date="getInstaCartCampaignsPageWiseMinMaxKey"
        :on-create="onCreate"
        :get-filter-instance="getFilterInstance"
        :common-date-key="'common-date-cm'"
        :ingestion-dependent-entities="ingestionDependentEntities"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        @savedFilterApplied="filterKey++"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Campaigns'" />
        </div>
        <span
          slot="search"
          class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
        >
          <BulkSearch
            bulk-search-ref="instacartCampaigns"
            :bulk-search-props="bulkSearchProps"
            :standard-search-config="config.filters.search"
          />
        </span>
      </rb-filter-panel>
    </div>
    <SystemTransparencyBanner
      v-if="filterInstance && filterInstance.selectedDates"
      :dates="filterInstance.selectedDates"
    />
    <card-widget
      v-show="
        !showAddKeywordsPanel &&
        !showAdjustBudgetPanel &&
        !showAddAsinsPanel &&
        !showAddNegativeKeywordsPanel &&
        !showEditDate &&
        !showAddSkus &&
        !showBidSettings
      "
      class="u-flex-1 u-spacing-mh-m u-spacing-mb-m"
      :config="config.widgets['widget1']"
      :data="
        getInstacartCampaignsData[config.widgets['widget1'].meta.key] || {}
      "
    >
      <div slot="body">
        <div class="u-spacing-m-l">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start"
          >
            <div class="u-display-flex u-flex-wrap-yes summary-card-container">
              <div
                v-for="(val, index) in metricsShown"
                :key="index"
                :val="val"
                class="metric-card-hover-trigger"
                @click="selectNewMetric([index], [val])"
              >
                <div
                  data-cy="metricCards"
                  class="custom-chart-legend u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
                >
                  <div
                    v-if="metricColors[val]"
                    class="active-metric-card"
                    data-cy="activeMetricCard"
                    :style="{ 'background-color': metricColors[val] }"
                  />
                  <div
                    v-else
                    class="metric-card-hover"
                    :style="
                      metricColors[val]
                        ? { 'background-color': 'transparent' }
                        : { 'background-color': getColorPattern[index] }
                    "
                  />
                  <div>
                    <div
                      v-if="index > minDisplayedMetricCount - 1"
                      class="cross-button-holder"
                    >
                      <div
                        class="cross-icon"
                        data-cy="removemetric"
                        @click.stop="deleteMetric(index)"
                      >
                        <rb-icon
                          class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                          :icon="'cross'"
                        />
                      </div>
                    </div>
                    <div
                      class="u-spacing-ph-m u-spacing-pb-m"
                      :class="[
                        index > minDisplayedMetricCount - 1
                          ? ''
                          : 'u-spacing-pt-s u-spacing-mt-s override-padding'
                      ]"
                    >
                      <rb-select
                        v-if="val"
                        :width="'240px'"
                        class="u-spacing-mr-m"
                        :context="[index]"
                        :send-details="true"
                        :on-close="metricSelected"
                        :options="metricsList"
                        :class-name="'campaigns-select'"
                        :search-enabled="true"
                      >
                        <div
                          slot="trigger"
                          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                        >
                          <div
                            class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          >
                            <span class="u-font-size-5">{{ val }}</span>
                            <rb-icon
                              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                              :icon="'caret-down'"
                            />
                          </div>
                        </div>
                        <template
                          slot="item"
                          slot-scope="option"
                        >
                          <div class="u-display-flex u-flex-align-items-center">
                            <div
                              v-if="
                                disableMetric.indexOf(option.title) !== -1 &&
                                plottedSelections !== 1 &&
                                (
                                  getInstacartCampaignsData[
                                    config.widgets['widget2'].meta.key
                                  ] || {}
                                ).rows.length !== 1
                              "
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                              @click.stop
                            >
                              <p
                                class="u-color-grey-lighter u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                              <rb-icon
                                v-tippy="{ placement: 'top', arrow: true }"
                                class="u-spacing-pl-xs u-cursor-pointer u-color-grey-lighter"
                                :icon="'info-circle-outline'"
                                title="Enabled when a single campaign is selected"
                              />
                            </div>
                            <div
                              v-else
                              class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                            >
                              <p
                                class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                              >
                                {{ option.title }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </rb-select>
                      <metric
                        v-if="
                          val &&
                          ((
                            getInstacartCampaignsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                        :size="'l'"
                        class="u-display-inline-flex u-spacing-mt-s"
                        :config="
                          ((
                            getInstacartCampaignsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metricsUnits || {})[val]
                        "
                        :data="
                          ((
                            getInstacartCampaignsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                      />
                      <span
                        v-if="
                          !val &&
                          !((
                            getInstacartCampaignsData[
                              config.widgets['widget1'].meta.key
                            ] || {}
                          ).metrics || {})[val]
                        "
                        class="u-color-grey-light u-font-size-5"
                        >No Data</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <amsAddMetricTile
                v-if="metricsShown.length < maxDisplayedMetricCount"
                :metrics-list="metricsList"
                :disabled-metrics-fn="disabledMetricsFn"
                @addNewMetric="addNewMetric"
              />
            </div>
            <div class="u-display-flex">
              <section
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                <span
                  v-if="numberOfPlottedSelections !== 0"
                  class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center plotted-rows-info"
                  :class="{ isHighlighted: isHighlighted }"
                  style="min-height: 20px"
                >
                  <span
                    data-cy="graphPlottedInfo"
                    class="u-spacing-pr-s u-line-height-1_2"
                    >Graphs plotted for
                    {{ numberOfPlottedSelections }} rows</span
                  >
                  <span @click="plotRows('delete')">
                    <rb-icon
                      :icon="'cross'"
                      class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
                      :class="{
                        'isHighlighted u-color-grey-white': isHighlighted
                      }"
                    />
                  </span>
                </span>
              </section>
              <span>
                <icon-text-actions
                  v-if="isExpand"
                  class="u-cursor-pointer padding--right u-width-100"
                  mode="default"
                  :action="{ title: 'MAXIMIZE', icon: 'enlarge' }"
                  @clicked="isExpand = !isExpand"
                />
                <icon-text-actions
                  v-if="!isExpand"
                  class="u-cursor-pointer u-width-100 padding--right"
                  mode="default"
                  :action="{ title: 'MINIMIZE', icon: 'collapse' }"
                  @clicked="isExpand = !isExpand"
                />
              </span>
            </div>
          </div>
          <chart
            id="workbenchCampaignsChart"
            :style="[isExpand ? { display: 'none' } : { display: 'block' }]"
            class="c3-large u-flex-1 u-spacing-mt-l"
            :config="config.widgets['widget1'].body.chart"
            :data="widget1Data"
          />
        </div>
      </div>
    </card-widget>

    <div class="u-position-relative detail-panel">
      <div
        v-if="
          isSDCampaign &&
          (showAddNegativeKeywordsPanel ||
            showBidChangePanel ||
            showAddAsinsPanel ||
            showAddKeywordsPanel ||
            showEditDate ||
            showAddSkus ||
            showBidSettings)
        "
        class="fillMessage bring-to-front u-opacity-1 u-flex-justify-content-center u-flex-align-items-center u-text-align-center u-position-absolute u-display-flex fill--parent u-font-size-3"
      >
        {{ SDMessage }}
      </div>
      <addNegativeKeywordsPanel
        v-if="showAddNegativeKeywordsPanel"
        action-name="keyword"
        :selections="selections"
        result-table-width="51%"
        :parent-ref="parentRef"
        :fetched-adgroups="adgroups"
        :parent-adgroup="true"
        default-match-type="exact"
        :use-default-match-type="true"
        :load="adgroupLoader"
        action-type="instacartCampaignsAdGroupKeywordsAdd"
        :widgets-config-ref="widgetsConfig"
        :bid-config="bidConfigsForAddKeywordsAction"
        @closePanelMethod="() => negativeKeywordsAfterAction(true)"
        @afterAPIActionToCall="negativeKeywordsAfterAction"
      />
      <adjustBudget
        v-if="showAdjustBudgetPanel"
        :selections="selections"
        :parent-ref="parentRef"
        action-type="instacartCampaignTotalBudgetChange"
        @afterAPIActionToCall="closeActionsWorkflow"
        @close="() => closeActionsWorkflow(false, true)"
      />
      <bidChangePanel
        v-if="showBidChangePanel"
        :title="bidChangePanelTitle"
        :selected-values="selections"
        :parent-ref="parentRef"
        retailer="instacart"
        :action-panel-type="'campaigns'"
        @closePanelMethod="() => bidChangeAfterAction(false, true)"
        @afterAPIActionToCall="bidChangeAfterAction"
      />
      <addKeywordsPanel
        v-if="showAddKeywordsPanel"
        :selections="selections"
        :parent-ref="parentRef"
        :widgets-config-ref="widgetsConfig"
      />
      <addAsinsPanel
        v-if="showAddAsinsPanel"
        :selections="selections"
        :parent-ref="parentRef"
        :widgets-config-ref="widgetsConfig"
      />
      <div v-if="showEditDate">
        <editDate
          :selections="selections"
          @afterAPIActionToCall="closeActionsWorkflow"
          @close="() => closeActionsWorkflow(false, true)"
        />
      </div>
      <addSkus
        v-if="showAddSkus"
        :selections="selections"
        action-type="instacartCampaignsAdGroupProductsAdd"
        :load="adgroupLoader"
        :show-adgroup="true"
        :adgroups="adgroups"
        @afterAPIActionToCall="closeActionsWorkflow"
        @close="() => closeActionsWorkflow(false, true)"
        @refreshAdgroup="addSkus"
      />
      <bid-settings
        v-if="showBidSettings"
        :selections="selections"
        :parent-ref="parentRef"
        action-type="instacartCampaignEditBidStatus"
        @afterAPIActionToCall="closeActionsWorkflow"
        @close="() => closeActionsWorkflow(false, true)"
      />
    </div>

    <div
      v-if="showActionsBar"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <actions-bar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>

    <div
      v-if="!showActionsBar"
      class="u-display-flex u-flex-justify-content-space-between u-spacing-p-m u-bg-color-grey-white u-flex-direction-row actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <span
        v-tippy="{ placement: 'top', arrow: true }"
        title="Create campaigns for Instacart"
      >
        <rb-button
          :click-fn="createCampaign"
          text="Create campaign"
          type="filled"
        />
      </span>
      <div class="u-display-flex u-flex-align-items-baseline">
        <icon-text-actions
          mode="default"
          :action="{ title: 'MANAGE COLUMNS', icon: 'manage-coloumns' }"
          @clicked="openManageColumns"
        />
        <rb-download-button
          :download-email="true"
          :config="config.widgets['widget2']"
          :column-map="config.widgets['widget2'].body.download.columnMap"
          :on-email-click="openEmailPopup"
          :class-name="'enlargeEmailIcon'"
        />
      </div>
    </div>
    <card-widget
      :apply-card-class="false"
      class="u-flex-1 u-spacing-mb-m u-bg-color-grey-white"
      :config="config.widgets['widget2']"
      :data="
        getInstacartCampaignsData[config.widgets['widget2'].meta.key] || {}
      "
    >
      <div slot="body">
        <div
          class="u-display-flex u-flex-wrap-yes insta-campaigns-table-wrapper"
        >
          <rb-insights-table
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getInstacartCampaignsData[config.widgets['widget2'].meta.key] ||
                {}
              ).rows
            "
            :table-column="tableColumns"
            style="width: 100%; height: calc(100vh - 182px)"
            :row-selection="rowSelection"
            :row-height="config.widgets['widget2'].body.rowHeight"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :scroll-to-node-id="scrollToNodeId"
            :primary-key="'campaign_id'"
            :pagination="true"
            :pagination-total-key="
              (
                getInstacartCampaignsData[config.widgets['widget2'].meta.key] ||
                {}
              ).totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :table-header-sticky-obj="sticky"
            :show-pagination-options="true"
          />
        </div>
      </div>
    </card-widget>
    <how-to-bulk-search-modal
      ref="bulkSearchHelpModal"
      help-modal-local-storage-key="instacartCampaigns-bulk-search-help-dont-show"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import addSkus from './addSkus.vue';
import bidSettings from './bidSettings/bidSettings.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import editDate from './editDate.vue';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import actionsBar from '@/components/widgets/actionsBar.vue';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import bidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import addKeywordsPanel from '@/components/ams/actionPanels/addKeywordsPanel.vue';
import addAsinsPanel from '@/components/ams/actionPanels/addAsinsPanel.vue';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons, commonMethods } from '@/components/ams/campaigns/commons.js';
import allActionConfig from '@/components/widgets/widgets_config/actionBarConfig';
import utils from '@/utils/helpers/';
import modal from '@/components/widgets/modal.vue';
import addNegativeKeywordsPanel from '@/components/ams/actionPanels/addNegativeKeywordsPanel.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import adjustBudget from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/index.vue';
import { initialStepsDataState } from '@/components/pages/instacart/campaign-creation/store/mutations.js';
import { metricsShownForInstacartChart } from '@/components/pages/instacart/campaigns/constants.js';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import amsAddMetricTile from '@/components/ams/campaigns/components/amsAddMetricTile.vue';
import SystemTransparencyBanner from '@/components/widgets/system-transparency-banner.vue';

const { filters } = require('@ciq-dev/ciq-ui');

export default {
  components: {
    editDate,
    addSkus,
    bidSettings,
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    actionsBar,
    messageBar,
    activityLog,
    addKeywordsPanel,
    bidChangePanel,
    adjustBudget,
    addAsinsPanel,
    modal,
    addNegativeKeywordsPanel,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal,
    manageColumns,
    amsAddMetricTile,
    SystemTransparencyBanner
  },
  mixins: [widgetsUtil, manageColumnMixin],
  data() {
    return {
      additionalRanges: commons.additionalDateRanges(),
      unavailableMessageSD:
        'This action is currently unavailable for SD campaigns',
      notSupportedMessageSD: 'Action type not applicable for SD campaigns',
      SDMessage: '',
      pageContainerHeight: '400px',
      adgroupLoader: false,
      adgroups: [],
      showAddSkus: false,
      showBidSettings: false,
      isSDCampaign: false,
      showEmailPopUp: false,
      modalConfig: {},
      widgetsConfig: {},
      parentRef: {},
      resultLength: 0,
      bidChangePanelTitle: 'Budget Change',
      allowedActions: [
        {
          icon: 'show-chart',
          title: 'Plot Rows',
          id: 'show-chart'
        }
      ],
      showBidChangePanel: false,
      showAdjustBudgetPanel: false,
      showActionsBar: false,
      selections: [],
      chartWkbenchAxes: {},
      addNegativeKeywordsIndex: -1,
      chartAxisFormat: {},
      chartAxisFormatMap: {},
      filterKey: 0,
      plottedSelections: 0,
      plotRowsSelections: [],
      filterState: {
        getter: 'getInstacartCampaignsSelectedFilters',
        setter: 'setInstacartCampaignsSelectedFilters'
      },
      downloadFileSuffix: 'instacart_campaign-management_campaigns',
      metricsSelectedIndex: [0, 1],
      metricsSelected: [],
      metricsShown: [...metricsShownForInstacartChart],
      metricsList: [],
      metricColors: {},
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSelectionChanged
      },
      showBlueBar: false,
      timer: 30000,
      disableMetric: [
        'Total sales',
        'PCOGS',
        'Inventory',
        'Organic sales',
        'Promo Value'
      ],
      isExpand: false,
      reDrawRows: true,
      rbTableInstance: null,
      showActionsLog: false,
      campaignId: null,
      openActivity: true,
      showEditDate: false,
      actionIdFunctionMap: {
        'show-chart': this.plotRows,
        'edit-status': this.editCampaignsStatus,
        'add-keywords': this.addKeywords,
        'adjust-bids': this.adjustBudgetPanel,
        'day-part': this.dayparting,
        'add-asins': this.addAsins,
        'add-negative': this.addNegativeKeywords,
        'edit-dates': this.editDates,
        'add-skus': this.addSkus,
        'bid-settings': this.bidSettings
      },
      addKeywordConfigIndex: -1,
      addAsinConfigIndex: -1,
      bidSettingsConfigIndex: -1,
      showAddKeywordsPanel: false,
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      isHighlighted: false,
      filterInstance: null,
      showPaginationOptions: utils.internalUserCheck(window.user),
      showAddAsinsPanel: false,
      showAddNegativeKeywordsPanel: false,
      bidConfigsForAddKeywordsAction: {
        minBid: 0.3,
        maxBid: 85,
        addKeywordsErrorMessage:
          'Default Bid value should be between 0.3 and 85',
        addedKeywordsErrorMessage:
          'One of more bids have invalid data. Bid value should be between 0.3 and 85.'
      },
      enableManageColumns: true,
      manageColumnsPanelListener: 'instacartCampaignManageColumnsPanel',
      workBenchDataKey: 'getInstacartCampaignsData',
      ...commons.commonDataProperties,
      ...utils.filterConfigs('instacart')
    };
  },
  computed: {
    bulkSearchProps() {
      return this.config.bulkSearchProps();
    },
    disableForDraftCampaigns() {
      let result = false;
      for (let i of this.selections) {
        if (i?.data?.campaign_status === 'draft') {
          result = true;
        }
      }
      return result;
    },
    emailStatus() {
      return this.$store.getters.getInstacartEmailSentStatus;
    },
    numberOfPlottedSelections() {
      if (this.plottedSelections) {
        this.isHighlighted = true;
        if (
          (
            this.getInstacartCampaignsData[
              this.config.widgets.widget1.meta.key
            ] || {}
          ).load === false
        ) {
          setTimeout(() => {
            this.isHighlighted = false;
          }, 5000);
        }
      }
      return this.plottedSelections;
    },
    getShowInstaCampaignsActivityLog() {
      return this.$store.getters.getShowInstaCampaignsActivityLog;
    },
    getInstacartCampaignsData() {
      this.showActionsBar = false;
      this.resultLength = (
        (
          this.$store.getters.getInstacartCampaignsData[
            this.config.widgets.widget2.meta.key
          ] || {}
        ).rows || []
      ).length;
      return this.$store.getters.getInstacartCampaignsData;
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.CampaignsActivityLog;
    },
    widget1Data() {
      let data = (
        this.getInstacartCampaignsData[this.config.widgets.widget1.meta.key] ||
        {}
      ).data;
      let clonedData = (data || {}).data;
      this.chartAxisFormatMap =
        this.config.widgets.widget1.meta.axisFormatMap || {};
      if (!clonedData) {
        return null;
      }
      let ignoreList = [
        'report_date',
        'count',
        'customer_orders',
        'inventory_latest'
      ];
      this.metricsList = [];
      for (let element of clonedData) {
        if (
          ignoreList.indexOf(element[0]) === -1 &&
          !this.metricsShown.includes(element[0])
        ) {
          if (element[0]) {
            this.metricsList.push({
              title: element[0]
            });
          }
        }
      }

      data = this.createSelectedMetricsList({ data, clonedData });
      return data;
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          if (!params.data.campaign_id) {
            return false;
          }
          return (
            params.data.campaign_id ===
            this.$store.getters.getInstacartCampaignPrimaryKey
          );
        }.bind(this)
      };
    },
    primaryKey() {
      return this.$store.getters.getInstacartCampaignPrimaryKey[
        this.config.widgets.widget2.meta.primaryKey
      ];
    },
    getInstaCartCampaignsPageWiseMinMaxKey() {
      return this.$store.getters.getInstaCartCampaignsPageWiseMinMaxKey;
    }
  },
  watch: {
    emailStatus(newVal) {
      commons.emailStatusSnackBar(newVal, this);
    },
    resultLength() {
      if (this.resultLength > 1) {
        for (let index in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[index]) > -1) {
            this.metricsShown = [...metricsShownForInstacartChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
    }
  },
  beforeCreate() {
    this.allActionConfig = allActionConfig;
  },
  mounted() {
    let chart = document.getElementById('workbenchCampaignsChart');
    if (chart) {
      chart.style.height = (chart.clientWidth || 320) / 3.5 + 'px';
    }
    let that = this;
    eventBus.$on('instacartCampaignsFilterApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('InstacartCampaignsSearchTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('workbenchCampaignsPaginationTriggered', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('filterStateChanged', (val) => {
      if (val) {
        that.isExpand = true;
      }
    });
    eventBus.$on('instacartCampaignsBulkSearchApplied', () => {
      that.plottedSelections = 0;
      that.removeAllActionPanels(false);
    });
    eventBus.$on('openBulkSearchHelperPopup', () => {
      that.openBulkSearchHelperPopup();
    });
    eventBus.$on('openBulkSearchExitPopup', () => {
      that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
    });
    eventBus.$on('openBulkSearchOverview', () => {
      that.$refs.instacartCampaigns?.openBulkSearchPopup();
    });
  },
  created() {
    this.allowedActionConfigCheck();
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    this.config.widgets.widget1.meta.plotSelections = [];
    this.fetchFilters(
      'instacart_campaigns_workbench',
      'FETCH_FILTERS_V2',
      'instacart_campaigns'
    );
  },
  beforeDestroy() {
    eventBus.$off('instacartCampaignsFilterApplied');
    eventBus.$off('InstacartCampaignsSearchTriggered');
    eventBus.$off('workbenchCampaignsPaginationTriggered');
    eventBus.$off('filterStateChanged');
    eventBus.$off('instacartCampaignsBulkSearchApplied');
    eventBus.$off('openBulkSearchHelperPopup');
    eventBus.$off('openBulkSearchExitPopup');
    eventBus.$off('openBulkSearchOverview');
  },
  methods: {
    ...commonMethods,
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    openBulkSearchHelperPopup(...args) {
      return this.config.openBulkSearchHelperPopup.bind(this)(...args);
    },
    createSelectedMetricsList(...args) {
      return this.config.createSelectedMetricsList.bind(this)(...args);
    },
    getMetricsListForChartingWb(...args) {
      return this.config.getMetricsListForChartingWb.bind(this)(...args);
    },
    allowedActionConfigCheck() {
      const instacartApiActions = [
        {
          icon: 'pencil',
          title: 'Edit Status',
          id: 'edit-status',
          dropDown: true,
          dropDownOptions: [
            { title: 'Active', value: 'active', color: 'u-color-green-base' },
            {
              title: 'Inactive',
              value: 'inactive',
              color: 'u-color-orange-base'
            }
          ]
        },
        {
          icon: 'dollar',
          title: 'Adjust Budget',
          id: 'adjust-bids'
        },
        {
          icon: 'add-circle-outline',
          title: 'Add Keyword',
          id: 'add-negative'
        },
        {
          icon: 'sku-box',
          title: 'Add skus',
          id: 'add-skus'
        },
        {
          icon: 'dollar',
          title: 'Bid Settings',
          id: 'bid-settings'
        },
        {
          icon: 'calendar',
          title: 'Adjust Start/End dates',
          id: 'edit-dates'
        },
        {
          icon: 'day-parting',
          title: 'Day Part',
          id: 'day-part'
        }
      ];
      if (filters.config_check('feature.instacart_campaign_api_actions')) {
        this.allowedActions.push(...instacartApiActions);
      }
    },
    fetchAdgroupsData(showPanel) {
      const adgroupPayload = {
        ...this.config.widgets.adgroupsInstacart.APIConfig
      };
      adgroupPayload.where.dimensionNameValueList[0].dimensionValue =
        this.selections[0].data.campaign_id;
      this[showPanel] = true;
      this.adgroupLoader = true;
      HttpLayer.post({
        cube: 'EXECUTE_CUBE_API',
        APIData: adgroupPayload
      })
        .then((response) => {
          this.adgroups = [];
          if (response.success) {
            this.adgroups = transformer.mergeResultDimension(
              response.data,
              true
            );
          }
        })
        .finally(() => {
          this.adgroupLoader = false;
        });
    },
    addSkus() {
      this.hideActionBar();
      this.fetchAdgroupsData('showAddSkus');
    },
    bidSettings() {
      this.showBidSettings = true;
      this.hideActionBar();
    },
    editDates() {
      this.showEditDate = true;
      this.showActionsBar = false;
    },
    addNegativeKeywords() {
      this.hideActionBar();
      this.fetchAdgroupsData('showAddNegativeKeywordsPanel');
      this.SDMessage = this.notSupportedMessageSD;
      let that = this;
      this.$nextTick(() => {
        if (that.showAddNegativeKeywordsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      });
    },
    overRightAllowedActions() {
      if (
        this.allowedActions &&
        this.allowedActions.length &&
        this.allowedActions.length > 0
      ) {
        this.allowedActions.forEach((allowedAction) => {
          if (allowedAction.id === 'adjust-bids') {
            allowedAction.title = 'BUDGET';
          }
        });
      }
    },
    scrollToNodeId() {
      return 'insta-campaigns-filters';
    },
    getInitialPayloadObject(data) {
      let obj = {
        actionPayload: {
          campaignId: data.campaign_id,
          profileId: data.profile_id || 0,
          campaignType: data.campaign_type
        },
        viewPayload: {
          name: data.campaign_name,
          newStatus: data.status,
          campaignId: data.campaign_id,
          profileId: data.profile_id || 0,
          campaignType: data.campaign_type
        },
        actionType: 'instacartCampaignStatusChange',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      obj.actionPayload.newStatus = '';
      obj.actionPayload.previousStatus = data.status;
      obj.viewPayload.newStatus = '';
      obj.viewPayload.previousStatus = data.status;
      return obj;
    },

    processPayloadForStatusChange(params, payloadObjRef, rowData) {
      payloadObjRef.actionPayload.newStatus = params;
      payloadObjRef.viewPayload.newStatus = params;
      payloadObjRef.actionPayload.previousStatus = rowData.campaign_status;
      payloadObjRef.viewPayload.previousStatus = rowData.campaign_status;
    },

    getPayload(params, actionType) {
      let paramsToReturn = [];
      for (let selection of this.selections) {
        let data = selection.data;
        let obj = this.getInitialPayloadObject(data, actionType);
        this.processPayloadForStatusChange(params, obj, data);
        paramsToReturn.push(obj);
      }
      return paramsToReturn;
    },

    hideBlueBar() {
      this.showBlueBar = false;
    },

    hideActionBar() {
      this.showActionsBar = false;
    },

    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    },

    editCampaignsStatus(selectedOption) {
      let that = this;
      let selectedStatus = selectedOption[0].value.toLowerCase();
      let payload = this.getPayload(selectedStatus);
      let othersObj = {
        successMessage:
          'Status change request has been submitted for the selected campaign(s)',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closeActionsWorkflow(false);
          that.onSelectionChanged([]);
          that.plotRows('delete');
        }
      };
      commons.bidChangeApiAction.call(this, selectedOption, payload, othersObj);
    },

    adjustBidsPanel() {
      this.hideActionBar();
      this.showBidChangePanel = !this.showBidChangePanel;
      if (this.showBidChangePanel === true) {
        commons.scrollPage(0);
      } else {
        commons.scrollPage(500);
      }
    },

    adjustBudgetPanel() {
      this.hideActionBar();
      this.showAdjustBudgetPanel = !this.showAdjustBudgetPanel;
      if (this.showAdjustBudgetPanel === true) {
        commons.scrollPage(0);
      } else {
        commons.scrollPage(500);
      }
    },

    negativeKeywordsAfterAction(isCloseMethod = false) {
      this.closeActionsWorkflow(false, true);
      this.hideActionBar();
      this.showAddNegativeKeywordsPanel = false;
      this.onSelectionChanged([]);
      this.plotRows('delete', isCloseMethod);
    },
    bidChangeAfterAction(isCloseMethod = false) {
      this.bidLoader = false;
      this.closeActionsWorkflow(false, true);
      this.adjustBidsPanel();
      this.onSelectionChanged([]);
      this.plotRows('delete', isCloseMethod);
    },

    addKeywords() {
      this.hideActionBar();
      this.SDMessage = this.notSupportedMessageSD;
      this.showAddKeywordsPanel = !this.showAddKeywordsPanel;
      let that = this;
      setTimeout(function () {
        if (that.showAddKeywordsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      }, 100);
    },

    addAsins() {
      this.hideActionBar();
      this.SDMessage = this.unavailableMessageSD;
      this.showAddAsinsPanel = !this.showAddAsinsPanel;
      let that = this;
      this.$nextTick(() => {
        if (that.showAddAsinsPanel === true) {
          commons.scrollPage(0);
        } else {
          commons.scrollPage(500);
        }
      });
    },

    closeActionsWorkflow(fetchChart = true, isCloseMethod = false) {
      this.hideActionBar();
      this.selections = [];
      this.showEditDate = false;
      this.showAddSkus = false;
      this.showBidSettings = false;
      this.showAdjustBudgetPanel = false;
      if (isCloseMethod) {
        this.deselectAllRows();
        this.onSelectionChanged([]);
      }
      if (fetchChart && !isCloseMethod) {
        this.deselectAllRows();
        this.onSelectionChanged([]);
        this.plotRows('delete');
      }
    },

    removeAllActionPanels(fetchChart = true) {
      this.closeActionsWorkflow(fetchChart);
      this.showBidChangePanel = false;
    },
    openSidePanel(that) {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openCampaignsActivityLog');
      this.$store.dispatch('getCampaignsActivityLog', {
        primaryKey: that.params.node.data.campaign_id
      });
      if (this.campaignId === that.params.node.data.campaign_id) {
        return;
      }
      this.campaignId = that.params.node.data.campaign_id;
      this.rbTableInstance.redrawTableRows();
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      const selectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      const allowedActions = ['add-keywords', 'adjust-bids', 'bid-settings'];
      commons.checkIfActionIsValid(
        actionId,
        allowedActions,
        selectedNodes,
        this.$snackbar
      );
      const newSelectedNodes =
        this.config.widgets.widget2.body.gridOptions.api.getSelectedNodes();
      if (newSelectedNodes.length <= 0) {
        return;
      }
      if (actionId === 'day-part') {
        this.actionIdFunctionMap[actionId](selections);
      } else if (dropDownSelection !== undefined) {
        this.actionIdFunctionMap[actionId](dropDownSelection);
      } else {
        this.actionIdFunctionMap[actionId]();
      }
    },
    plotRows(type, isCloseMethod = false) {
      if (type === 'delete') {
        this.deselectAllRows();
      }
      this.isExpand = false;
      this.plottedSelections = this.plotRowsSelections.length;
      if (this.plottedSelections > 1 || this.plottedSelections === 0) {
        for (let metrics in this.disableMetric) {
          if (this.metricsShown.indexOf(this.disableMetric[metrics]) > -1) {
            this.metricsShown = [...metricsShownForInstacartChart];
            this.metricsSelectedIndex = [0, 1];
          }
        }
      }
      if (!isCloseMethod) {
        eventBus.$emit(
          'workbenchInstacartCampaignsPlotSelectedRows',
          this.plotRowsSelections
        );
      }
      document.getElementById('insta-campaigns-filters').scrollIntoView();
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    getPanelConfigIndex() {
      for (let i = 0; i < this.allowedActions.length; i++) {
        if (this.allowedActions[i].id === 'add-keywords') {
          this.addKeywordConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'add-asins') {
          this.addAsinConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'bid-settings') {
          this.bidSettingsConfigIndex = i;
        }
        if (this.allowedActions[i].id === 'add-negative') {
          this.addNegativeKeywordsIndex = i;
        }
      }
    },

    checkForActionBar(data) {
      if (
        !this.showAddKeywordsPanel &&
        !this.showAdjustBudgetPanel &&
        !this.showActionsBar &&
        !this.showAddAsinsPanel &&
        !this.showAddNegativeKeywordsPanel &&
        !this.showEditDate &&
        !this.showAddSkus &&
        !this.showBidSettings
      ) {
        this.showActionsBar = data.length > 0;
      }
      if (data.length === 0) {
        this.showActionsBar = false;
      }
    },
    onMultiSelect({
      avaliableActions,
      multipleSelected,
      multipleSelectedMessage
    }) {
      const noMultiSelect = ['add-skus', 'add-negative', 'bid-settings'];
      for (const element of this.allowedActions) {
        if (!avaliableActions.includes(element.id)) {
          element.disabled = true;
        } else if (noMultiSelect.includes(element.id) && multipleSelected) {
          element.disabled = true;
          element.tippy = multipleSelectedMessage;
        }
        if (element?.id === 'show-chart' && !this.disableForDraftCampaigns) {
          element.disabled = false;
        }
      }
    },
    validateActions({
      multipleSelected,
      multipleSelectedMessage,
      draftDisabledMessage
    }) {
      if (
        this.allowedActions[this.addKeywordConfigIndex] &&
        this.allowedActions[this.addAsinConfigIndex] &&
        this.allowedActions[this.bidSettingsConfigIndex] &&
        this.allowedActions[this.addNegativeKeywordsIndex]
      ) {
        if (!this.isSDCampaign) {
          this.allowedActions[this.addAsinConfigIndex].disabled =
            multipleSelected;
          this.allowedActions[this.bidSettingsConfigIndex].disabled =
            multipleSelected;
          this.allowedActions[this.addKeywordConfigIndex].disabled =
            multipleSelected;
          this.allowedActions[this.addNegativeKeywordsIndex].disabled =
            multipleSelected;
          this.allowedActions[this.addAsinConfigIndex].tippy =
            multipleSelectedMessage;
          this.allowedActions[this.bidSettingsConfigIndex].tippy =
            multipleSelectedMessage;
          this.allowedActions[this.addKeywordConfigIndex].tippy =
            multipleSelectedMessage;
          this.allowedActions[this.addNegativeKeywordsIndex].tippy =
            multipleSelectedMessage;
        }
      }
      const avaliableActions = [
        'edit-status',
        'edit-dates',
        'adjust-bids',
        'add-skus',
        'add-negative',
        'show-chart',
        'bid-settings',
        'day-part'
      ];

      const noDraftSelect = [
        'edit-status',
        'edit-dates',
        'adjust-bids',
        'add-skus',
        'add-negative',
        'show-chart',
        'bid-settings'
      ];
      if (this.disableForDraftCampaigns) {
        for (const element of this.allowedActions) {
          if (!avaliableActions.includes(element.id)) {
            element.disabled = true;
          } else if (
            noDraftSelect.includes(element.id) &&
            this.disableForDraftCampaigns
          ) {
            element.disabled = true;
            element.tippy = draftDisabledMessage;
          }
        }
      }
      this.onMultiSelect({
        avaliableActions,
        multipleSelected,
        multipleSelectedMessage
      });
    },
    onSelectionChanged(data) {
      this.plotRowsSelections = data.map((selection) => {
        return selection.data;
      });
      this.selections = data;
      this.checkForActionBar(data);

      if (
        [
          this.addKeywordConfigIndex,
          this.addAsinConfigIndex,
          this.bidSettingsConfigIndex,
          this.addNegativeKeywordsIndex
        ].some((val) => val === -1)
      ) {
        this.getPanelConfigIndex();
      }
      const draftDisabledMessage = this.disableForDraftCampaigns
        ? 'Action set not available for draft campaigns'
        : '';
      const multipleSelected = this.selections.length > 1;
      let multipleSelectedMessage = '';
      if (multipleSelected && !this.disableForDraftCampaigns) {
        multipleSelectedMessage =
          'Action set limited for multiple campaign selections';
      } else if (multipleSelected && this.disableForDraftCampaigns) {
        multipleSelectedMessage =
          'Action set not available for draft campaigns';
      }
      this.isSDCampaign = commons.invalidateSDActions(
        data,
        this.allowedActions
      );
      this.onSDCampaignsSelection();
      this.validateActions({
        multipleSelected,
        multipleSelectedMessage,
        draftDisabledMessage
      });
    },

    onSDCampaignsSelection() {
      const actionTippys = {
        'adjust-bids': this.unavailableMessageSD,
        'edit-status': this.unavailableMessageSD,
        'add-keywords': this.notSupportedMessageSD,
        'day-part': this.unavailableMessageSD,
        'add-asins': this.unavailableMessageSD,
        'bid-settings': this.unavailableMessageSD,
        'add-negative': this.notSupportedMessageSD
      };
      this.allowedActions = commons.modifyTippyBasedOnActionState(
        this.allowedActions,
        actionTippys,
        this.isSDCampaign
      );
      if (this.isSDCampaign) {
        this.$snackbar.open({
          message: 'Action set limited for SD campaigns',
          duration: 3000,
          actionText: ''
        });
      }
    },

    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.showActionsLog = false;
      this.openActivity = false;
      this.campaignId = null;
      this.$store.dispatch('closeCampaignsActivityLog');
      this.rbTableInstance.redrawTableRows();
    },
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    },
    createCampaign() {
      this.$store.dispatch(
        'instacartCampaignCreation/updateStepsData',
        initialStepsDataState
      );
      this.$store.dispatch(
        'instacartCampaignCreation/updateLaunchedCampaign',
        {}
      );
      this.$router.push({ name: 'Create Campaign' });
    },
    getAllTableColumns() {
      return this.config.widgets.widget2.body.columnDefs;
    }
  }
};
</script>

<style lang="css">
.bring-to-front {
  z-index: 1001;
}
.padding--right {
  padding-right: 0% !important;
}
</style>
