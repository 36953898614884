// Entity details config file for SP Manual
// Widget_template's are tabs that are imported below
// Common components used within widget_template files

// -- importing componenets here --
import { adGroupCustomActionPanelForAutoSP } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import {
  getMetadata,
  headerConfigForCampaigns,
  getAdgroupsTargetingKeywordConfig,
  getAdGroupSettingsConfig,
  showNegativeKeywordTargetingForSamsClubApi,
  callChangeStatusForAdgroupsAds,
  platformAndPlacementData,
  getBanner
} from '@/pages/entity_details/configs/walmart/sp_auto.js';
import { getAdvertiserRequestPayload } from '@/pages/entity_details/configs/walmart/utils.js';

import { cloneDeep } from 'lodash';
const budget = () =>
  import('@/pages/entity_details/configs/walmart/widget_templates/budget.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const settings = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/settings.vue'
  );
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const bidMultiplier = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/bidMultiplier.vue'
  );
const placement = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/placement.vue'
  );
const platform = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/platform.vue'
  );
const searchTerms = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupSearchTerms.vue'
  );

const retailerToAdGroupCustomActionPanelForAutoSP = {
  walmart: adGroupCustomActionPanelForAutoSP,
  samsclub_api: adGroupCustomActionPanelForAutoSP
};
const setTimeoutForPlacementAndPlatform = (vueRef, res) => {
  setTimeout(async () => {
    if (placement.length || platform.length) {
      await vueRef?.tabConfig?.init?.(vueRef);
    }
    res(true);
  }, 5000);
};

const titleForPlacement = (item) => {
  return item?.status === 'included' ? 'Enabled' : 'Paused';
};

const setTimeoutForPlacement = (vueRef, placements, res) => {
  setTimeout(async () => {
    if (placements.length) {
      await vueRef?.tabConfig?.init?.(vueRef);
    }
    res(true);
  }, 5000);
};

const showBidMultiplierForWalmart = (retailer) => {
  return retailer === 'walmart'
    ? {
        'bid multiplier': {
          component: bidMultiplier,
          defaultState: {
            placement: {
              buyBox: {
                label: 'Buy Box',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              },
              searchIngrid: {
                label: 'Search Ingrid',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              }
            },
            platform: {
              desktop: {
                label: 'Desktop',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              },
              mobile: {
                label: 'Mobile',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              },
              app: {
                label: 'App',
                value: '',
                validation: {
                  min: {
                    value: 0,
                    exclusive: false
                  }
                }
              }
            }
          },
          typeMap: {
            'Buy-Box': 'buyBox',
            buyBox: 'Buy-Box',
            'Search Ingrid': 'searchIngrid',
            searchIngrid: 'Search Ingrid',
            Desktop: 'desktop',
            desktop: 'Desktop',
            Mobile: 'mobile',
            mobile: 'Mobile',
            App: 'app',
            app: 'App'
          },
          apply: async (vueRef, data) => {
            return Promise(async (res) => {
              const { placement, platform } = platformAndPlacementData(
                data,
                vueRef,
                retailer
              );
              if (placement.length) {
                let entityName = null;
                entityName = {
                  singular: 'Placement Bid',
                  plural: 'Placement Bids',
                  stopFetchApi: true
                };

                vueRef.$store.dispatch(vueRef.updateMetadata, {
                  updateRequest: placement,
                  entityId: vueRef.entityId,
                  entityType: 'SP_MANUAL',
                  callAdgroupFetch: false,
                  snackbar: vueRef.$snackbar,
                  entityName: entityName,
                  routeData: vueRef.$route
                });
              }
              if (platform.length) {
                let entityName = null;
                entityName = {
                  singular: 'Platform Bid',
                  plural: 'Platform Bids',
                  stopFetchApi: true
                };

                vueRef.$store.dispatch(vueRef.updateMetadata, {
                  updateRequest: platform,
                  entityId: vueRef.entityId,
                  entityType: 'SP_MANUAL',
                  callAdgroupFetch: false,
                  snackbar: vueRef.$snackbar,
                  entityName: entityName,
                  routeData: vueRef.$route
                });
              }
              vueRef.showComponent = false;
              vueRef.isLoading = true;
              setTimeoutForPlacementAndPlatform(vueRef, res);
            });
          },
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails'
          },
          init: async (vueRef) => {
            return Promise(async (resolve, reject) => {
              try {
                vueRef.isLoading = true;
                const promiseArr = await Promise.all([
                  vueRef.fetchData('PLATFORM_BIDMULTIPLIER'),
                  vueRef.fetchData('PLACEMENT_BIDMULTIPLIER')
                ]);
                const [platformData, placementData] = promiseArr;
                placementData?.data?.response?.map?.((item) => {
                  vueRef.displayState.placement[
                    vueRef.typeMap[item.placementType]
                  ].value = item.multiplier?.toString();
                });
                platformData?.data?.response?.map?.((item) => {
                  vueRef.displayState.platform[
                    vueRef.typeMap[item.platformType]
                  ].value = item.multiplier?.toString();
                });
                vueRef.isLoading = false;
                resolve(true);
              } catch (error) {
                vueRef.isLoading = false;
                reject(error);
              }
            });
          }
        }
      }
    : {};
};

const callAddSearchTermAsKeyword = (
  vueRef,
  dropDownSelection,
  selections,
  retailer
) => {
  const payloadArr = [];
  const searchTermKey = `${retailer}_cm_search_term_search_term`;
  const matchTypeKey = `${retailer}_cm_search_term_match_type`;
  const bidKey = `${retailer}_cm_search_term_bid`;
  for (const selection of selections) {
    const payload = cloneDeep(vueRef.stateChangeRequestPayload);
    const reqValues = {
      ':campaignId': vueRef.parentEntityId,
      ':pageUrl': window.location.href,
      ':keywordText': selection.data[searchTermKey] || 'NA',
      ':matchType': selection.data[matchTypeKey] || 'NA',
      ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
      ':advertiserId': vueRef?.$route?.query?.advertiser_id,
      ':bid': selection.data[bidKey] || null
    };
    for (const item in reqValues) {
      deepReplaceObjectValues(payload, item, reqValues[item]);
    }
    payloadArr.push(payload);
  }
  if (payloadArr.length) {
    vueRef.updateMetadataAction(payloadArr, {
      singular: 'Search term',
      plural: 'Search terms',
      stopFetchApi: true
    });
  } else {
    vueRef.closeActionBar();
  }
};

const getCampaignsData = (vueRef, retailer) => {
  const bidKey = `${retailer}_cm_search_term_bid`;
  const searchTermKey = `${retailer}_cm_search_term_search_term`;
  const keywordTextKey = `${retailer}_cm_search_term_keyword_text`;
  vueRef.campaignInfo = vueRef.parentEntityData;
  const allCampaingsData = [
    {
      campaign_name: vueRef.campaignInfo?.data?.name || 'NA',
      adgroup_id: vueRef.entityId,
      campaignType: vueRef.entityConfig.entityType.toLowerCase(),
      adgroup_name: vueRef.adgroupInfo?.data?.name || 'NA',
      campaign_id: vueRef.parentEntityId,
      selected: true
    }
  ];
  vueRef.campaignEditConfig.allCampaingsData = allCampaingsData;

  vueRef.selections.map((item, index) => {
    vueRef.selections[index].data.bid = !Number.isNaN(
      parseFloat(item.data[bidKey])
    )
      ? parseFloat(item.data[bidKey])
      : 0.3;
    vueRef.selections[index].data.campaign_type =
      vueRef.entityConfig.entityType;
    vueRef.selections[index].data.search_term = item.data[searchTermKey];
    vueRef.selections[index].data.keyword_text = item.data[keywordTextKey];
  });
};

const getkeywordPayload = (vueRef, objData, retailer = 'walmart') => {
  const currentActionId = vueRef.currentActionId;
  const payloadArr = [];
  for (const selection of objData.selections) {
    const payload = cloneDeep(vueRef.addKeywordPayload);
    const reqValues = {
      ':adgroupId': vueRef.entityId,
      ':campaignId': vueRef.parentEntityId,
      ':pageUrl': window.location.href,
      ':keywordText': selection?.actionPayload?.keywordText,
      ':matchType': selection?.actionPayload?.matchType,
      ':bid': parseFloat(selection?.actionPayload?.newBid) || 0.3,
      ':adgroupName': vueRef.adgroupInfo?.data?.name,
      ':advertiserId': vueRef?.$route?.query?.advertiser_id
    };
    for (const item in reqValues) {
      deepReplaceObjectValues(payload, item, reqValues[item]);
    }
    if (currentActionId === 'addAsNegativeKeyword') {
      payload.actionType = `${retailer}AddNegativeKeywordToCampaigns`;
      payload.actionPayload.new_match_type = 'negativeExact';
    }
    payloadArr.push(payload);
  }
  let entityName = {
    singular: 'Search Term',
    plural: 'Search Terms',
    stopFetchApi: true
  };
  vueRef.updateMetadataAction(payloadArr, entityName);
};

const placementConfigForWalmart = (retailer) => {
  return {
    placement: {
      component: placement,
      apiConfigs: {
        updateAction: 'entityDetails/updateCampaignDetails',
        getter: 'entityDetails/getCampaignDetails',
        getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
      },
      tabsList:
        retailer === 'walmart'
          ? ['Placement Classification', 'Placement Status']
          : [],
      activeTab: 'Placement Classification',
      showSplitTab: retailer === 'walmart' ? 'Placement Status' : null,
      displayState: {
        'Search Ingrid': {
          defaultValue: { value: 'included', title: 'Enabled' },
          disabled: true,
          options: [
            { title: 'Enabled', value: 'included' },
            { title: 'Paused', value: 'excluded' }
          ],
          tooltipText:
            'Ads appear in Search Results and Browse pages. This type is mandatory for Walmart Sponsored Products ads and cannot be disabled.'
        },
        'Search Carousel': {
          defaultValue: { value: 'excluded', title: 'Paused' },
          options: [
            { title: 'Enabled', value: 'included' },
            { title: 'Paused', value: 'excluded' }
          ],
          tooltipText:
            'Ads appear in browse, category, curated shelf, and the bottom of search results.'
        },
        'Item Buybox': {
          defaultValue: { value: 'excluded', title: 'Paused' },
          options: [
            { title: 'Enabled', value: 'included' },
            { title: 'Paused', value: 'excluded' }
          ],
          tooltipText:
            'Ads appear below the Buy Box located on the product detail page of a relevant or complementary product.'
        },
        'Item Carousel': {
          defaultValue: { value: 'excluded', title: 'Paused' },
          options: [
            { title: 'Enabled', value: 'included' },
            { title: 'Paused', value: 'excluded' }
          ],
          tooltipText:
            'Ads appear at the middle and bottom of product details page through personalized modules: "Customers also Viewed," "Customers also Considered," "Customers also Bought."'
        }
      },
      init: async (vueRef) => {
        return Promise(async (resolve, reject) => {
          try {
            vueRef.isLoading = true;
            const response = await vueRef.fetchData('PLACEMENTS');
            response?.data?.response?.map?.((item) => {
              vueRef.displayState[item?.placement].defaultValue = {
                value: item?.status,
                title: titleForPlacement(item)
              };
            });
            vueRef.isLoading = false;
            resolve(true);
          } catch (error) {
            vueRef.isLoading = false;
            reject(error);
          }
        });
      },
      onSave: async (vueRef, payload) => {
        return Promise((res, rej) => {
          try {
            const placements = Object.keys(payload).map((key) => ({
              actionPayload: {
                campaign_id: vueRef?.entityId,
                campaignType: 'SP',
                advertiser_id: vueRef?.$route?.query?.advertiser_id,
                placement_type: key,
                status: payload[key].defaultValue.value
              },
              viewPayload: {
                name: vueRef?.campaignInfo?.data?.name,
                campaign_id: vueRef?.entityId,
                campaignType: 'SP',
                placement_type: key,
                old_status: vueRef.displayState[key].defaultValue.value,
                new_status: payload[key].defaultValue.value
              },
              actionType: `${retailer}CampaignPlacementMetadataChange`,
              actionSource: {
                pageUrl: window.location.href
              },
              widget: 'campaign',
              primaryKey: vueRef?.entityId
            }));
            if (placements.length) {
              let entityName = null;
              entityName = {
                singular: 'Placement status',
                plural: 'Placement statuses',
                stopFetchApi: true
              };

              vueRef.$store.dispatch(vueRef.updateMetadata, {
                updateRequest: placements,
                entityId: vueRef.entityId,
                entityType: 'SP_MANUAL',
                callAdgroupFetch: false,
                snackbar: vueRef.$snackbar,
                entityName: entityName,
                routeData: vueRef.$route
              });
            }
            vueRef.isLoading = true;
            setTimeoutForPlacement(vueRef, placements, res);
          } catch (error) {
            rej(error);
          }
        });
      }
    }
  };
};

const adgroupADsConfig = (retailer) => {
  const searchTextKey = `${retailer}_cm_skus_search_text`;
  return {
    apiConfigs: {
      updateAction: 'entityDetails/updateCampaignDetails',
      fetchAction: 'entityDetails/fetchAdgroupDetails',
      getter: 'entityDetails/getAdgroupDetails',
      getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
    },
    allowedActions: [
      {
        icon: 'task-status',
        title: 'EDIT STATUS',
        id: 'editStatus',
        dropDown: true,
        dropDownOptions: [
          { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
          { title: 'Disable', value: 'disabled', color: 'u-color-orange-base' }
        ]
      }
    ],
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaign_id: ':campaignId',
          item_id: ':itemId',
          ad_group_id: ':adgroupId',
          status: ':state',
          advertiser_id: ':advertiserId'
        },
        viewPayload: {
          campaign_id: ':campaignId',
          previousStatus: ':oldState',
          adgroupName: ':adgroupName',
          newStatus: ':state',
          item_id: ':itemId',
          itemName: ':itemName'
        },
        actionType: `${retailer}ProductadMetadataChange`,
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    },
    actionPanelAddCustomProductsConfig:
      retailerToAdGroupCustomActionPanelForAutoSP[retailer](
        'sp_manual',
        'edit'
      ),
    addCustomProducts: {
      requestPayload: getAddCustomProductsRequestPayloadTemplate(retailer)
    },
    changeStatus: (vueRef, dropDownSelection, selections) => {
      callChangeStatusForAdgroupsAds(
        vueRef,
        dropDownSelection,
        selections,
        retailer
      );
    },
    addProducts: (vueRef, selections, actionPanelRef) => {
      selections = selections.filter(
        (row) =>
          actionPanelRef.fetchedRightTableRows.rows.findIndex(
            (fetchedRow) => fetchedRow.sku === row.sku
          ) === -1
      );
      if (!selections.length)
        return actionPanelRef.openSnackbar('No products were added/modified');
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(vueRef.addCustomProductsRequestPayload);
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':sku_title': selection.product_title,
          ':search_text': `${selection.sku}|${selection.product_title}`,
          ':pageUrl': window.location.href,
          ':image_url': selection.image_url,
          ':product_url': selection.product_url,
          ':item_id': selection.sku || 'NA',
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':advertiserId': vueRef?.$route?.query?.advertiser_id
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        vueRef.updateMetadataAction(payloadArr, {
          singular: 'Campaign Product',
          plural: 'Campaign Products',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        vueRef.closeActionBar();
      }
    },
    searchText: searchTextKey
  };
};

export const getAddCustomProductsRequestPayloadTemplate = (retailer) => {
  return {
    actionPayload: {
      campaign_id: ':campaignId',
      ad_group_id: ':adgroupId',
      item_id: ':item_id',
      status: 'enabled',
      advertiser_id: ':advertiserId',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      search_text: ':search_text'
    },
    viewPayload: {
      campaign_id: ':campaignId',
      item_id: ':item_id',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      adgroupName: ':adgroupName'
    },
    actionType: `${retailer}AddSkuToCampaigns`,
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  };
};

const getAllowedActionsForSearchTermsTab = (retailer) => {
  const arrayToReturn = [
    {
      icon: 'add-circle-fill',
      title: 'Add as Keyword',
      id: 'addAsKeyword'
    }
  ];
  if (retailer === 'samsclub_api') {
    arrayToReturn.push({
      icon: 'add-circle-fill',
      title: 'Add as Negative Keyword',
      id: 'addAsNegativeKeyword'
    });
  }
  return arrayToReturn;
};

export const getSearchTermTabsConfig = (retailer) => {
  return {
    component: searchTerms,
    apiConfigs: {
      updateAction: 'entityDetails/updateCampaignDetails',
      fetchAction: 'entityDetails/fetchAdgroupDetails',
      getter: 'entityDetails/getAdgroupDetails',
      campaignGetter: 'entityDetails/getCampaignDetails',
      fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
      getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
    },
    addAsKeywordPayload: {
      requestPayload: {
        actionType: `${retailer}AddKeywordToCampaigns`,
        actionSource: {
          pageUrl: ':pageUrl'
        },
        primaryKey: ':campaignId',
        widget: 'campaign',
        actionPayload: {
          campaignType: 'SP',
          new_keyword_text: ':keywordText',
          new_match_type: ':matchType',
          new_bid: ':bid',
          campaign_id: ':campaignId',
          ad_group_id: ':adgroupId',
          state: 'enabled',
          advertiser_id: ':advertiserId'
        },
        viewPayload: {
          campaign_id: ':campaignId',
          campaignType: 'SP',
          adgroupId: ':adgroupId',
          adgroupName: ':adgroupName',
          new_keyword_text: ':keywordText',
          new_match_type: ':matchType',
          new_bid: ':bid'
        }
      }
    },
    disableRowSelection: false,
    allowed_actions: getAllowedActionsForSearchTermsTab(retailer),
    addSearchTermAsKeyword: (vueRef, dropDownSelection, selections) => {
      callAddSearchTermAsKeyword(
        vueRef,
        dropDownSelection,
        selections,
        retailer
      );
    },
    prepareCampaignsData: (vueRef) => {
      getCampaignsData(vueRef, retailer);
    },
    preparekeywordPayload: (vueRef, objData) => {
      getkeywordPayload(vueRef, objData, retailer);
    },
    actionPanelType: `${retailer}searchTermsSP`
  };
};

export default function (retailer = 'walmart') {
  return {
    campaign: {
      page: 'campaignDetailsSPManual',
      metadata: getMetadata(),
      header: {
        filters: {
          config: {
            emit: 'spManualCampaignDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'spManualCampaignDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'spManualCampaingDetails'
        },
        ...headerConfigForCampaigns(retailer)
      },
      chart: {
        page: 'campaignDetailsSPManual',
        maxFeedDate: 'campaigns_campaign',
        globalViewId: 0,
        pageId: 0,
        filter: 'spManualCampaignDetailsFiltersApplied',
        widget: 'campaignDetailsChartingWbSPManual',
        enableDownload: false,
        enableRollup: true
      },
      // banner: getBanner,
      // show tabs data if present
      tabs: {
        budget: {
          component: budget,
          config: {
            title1: 'Total Budget',
            title2: 'Daily Budget',
            title3: 'Total & Daily Budget',
            validation: {
              ':oldDailyBudget': {
                min: 50,
                text: 'Daily budget must be $50.00 or above'
              },
              ':oldTotalBudget': {
                min: 100,
                text: 'Total budget must be $100.00 or above'
              },
              genericErrorText: {
                text: 'Please enter either total budget, daily budget, or both'
              }
            }
          },
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          reqMetadataMap: {
            ':oldDailyBudget': 'dailyBudget',
            ':oldTotalBudget': 'totalBudget',
            ':campaignId': 'entityId'
          },
          requestPayload: {
            actionPayload: {
              campaign_id: ':campaignId',
              campaignType: 'SP',
              new_daily_budget: ':newDailyBudget',
              new_total_budget: ':newTotalBudget',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              name: ':name',
              campaignId: ':campaignId',
              campaignType: 'SP',
              previousDailyBudget: ':oldDailyBudget',
              newDailyBudget: ':newDailyBudget',
              previousTotalBudget: ':oldTotalBudget',
              newTotalBudget: ':newTotalBudget'
            },
            actionType: `${retailer}CampaignMetadataChange`,
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId'
          }
        },
        ...showBidMultiplierForWalmart(retailer),
        adGroups: {
          component: adGroups,
          stateChange: {
            requestPayload: {
              actionPayload: {
                campaign_id: ':campaignId',
                campaignType: 'SP',
                ad_group_id: ':adgroupId',
                status: ':state',
                advertiser_id: ':advertiserId'
              },
              viewPayload: {
                campaign_id: ':campaignId',
                view_adgroup_name: ':adgroupName',
                view_old_state: ':oldState',
                status: ':state'
              },
              actionType: `${retailer}AdgroupMetadataChange`,
              actionSource: {
                pageUrl: ':pageUrl'
              },
              widget: 'campaign',
              primaryKey: ':campaignId'
            }
          },
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          disableCreateAdgroup: false,
          disableRowSelection: false,
          allowedActions: [
            {
              icon: 'task-status',
              title: 'EDIT STATUS',
              id: 'editStatus',
              dropDown: true,
              dropDownOptions: [
                {
                  title: 'Enable',
                  value: 'enabled',
                  color: 'u-color-green-base'
                },
                {
                  title: 'Disable',
                  value: 'disabled',
                  color: 'u-color-orange-base'
                }
              ]
            }
          ],
          changeStatus: (vueRef, dropDownSelection, selections) => {
            const payloadArr = [];
            const statusKey = `${retailer}_cm_adgroup_status`;
            const adGroupNameKey = `${retailer}_cm_adgroup_name`;
            for (const selection of selections) {
              if (
                selection?.data?.[statusKey]?.toLowerCase() ===
                dropDownSelection[0].value
              ) {
                vueRef.$snackbar.open({
                  message: 'No new state selected for one or more entities.',
                  duration: 6000,
                  buttonColor: '#f5d908',
                  actionText: ''
                });
              } else {
                const payload = cloneDeep(vueRef.stateChangeRequestPayload);
                const reqValues = {
                  ':campaignId': vueRef.entityId,
                  ':oldState': selection?.data?.[statusKey]?.toLowerCase(),
                  ':state': dropDownSelection[0].value,
                  ':pageUrl': window.location.href,
                  ':adgroupName': selection?.data?.[adGroupNameKey] || 'NA',
                  ':advertiserId': vueRef?.$route?.query?.advertiser_id,
                  ':adgroupId': selection?.data?.ad_group_id
                };
                for (const item in reqValues) {
                  deepReplaceObjectValues(payload, item, reqValues[item]);
                }
                payloadArr.push(payload);
              }
            }
            if (payloadArr.length) {
              vueRef.updateMetadataAction(payloadArr, {
                singular: 'Ad Group Status',
                plural: 'Ad Group Statuses',
                stopFetchApi: true
              });
            } else {
              vueRef.closeActionBar();
            }
          },
          createAdgroup: (vueRef) => {
            vueRef.$store.dispatch(
              `${retailer}CampaignCreation/updateAllStepsData`,
              {}
            );
            vueRef.$router.push({
              name: 'create_ad_group',
              query: {
                advertiserId: vueRef.$route.query.advertiser_id,
                campaignId: vueRef.entityId,
                campaignType: 'sp_manual',
                campaignName: vueRef.$route.query.parent_entity_name
              }
            });
          }
        },
        platform: {
          component: platform,
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          disableRowSelection: true
        },
        settings: {
          requestPayload: {
            actionPayload: {
              campaign_id: ':entityId',
              campaignType: 'SP',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              campaignId: ':entityId'
            },
            actionType: `${retailer}CampaignMetadataChange`,
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          },
          getAdvertiserRequestPayload: (advertiser_id) =>
            getAdvertiserRequestPayload(retailer, advertiser_id),
          component: settings,
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey',
            portfolio_campaignList: false,
            showAdvertiser: true,
            fetchAdvertiser: 'entityDetails/fetchAdvertiserData',
            getAdvertiser: 'entityDetails/getAdvertiser',
            statusValues: [
              {
                title: 'Proposal',
                value: 'Proposal'
              },
              {
                title: 'Scheduled',
                value: 'scheduled'
              },
              {
                title: 'Rescheduled',
                value: 'rescheduled'
              },
              {
                title: 'Live',
                value: 'live'
              },
              {
                title: 'Paused',
                value: 'paused'
              },
              {
                title: 'Ended',
                value: 'ended'
              },
              {
                title: 'Enabled',
                value: 'live'
              },
              {
                title: 'Completed',
                value: 'ended'
              },
              {
                title: 'Extend',
                value: 'extended'
              }
            ]
          },
          hideReviewStatus: true
        },
        ...placementConfigForWalmart(retailer)
      }
    },
    manual_adgroup: {
      page: 'adgroupDetailsSPManual',
      metadata: [
        {
          action: 'entityDetails/fetchAdgroupDetails',
          getters: 'entityDetails/getAdgroupDetails',
          // campaignFetchAction: 'entityDetails/fetchCampaingDetails',
          campaignGetter: 'entityDetails/getCampaignDetails'
        }
      ],
      header: {
        maxFeedDate: 'campaigns_campaign',
        line1: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group',
                values: ['name'],
                hoverText: ['name'],
                titleClass:
                  'u-color-grey-lighter u-font-weight-700 u-font-size-2',
                valueClasses: ['u-font-weight-700 u-font-size-2'],
                altValues: ['']
              },
              class: 'u-spacing-pt-xxs',
              itemType: 'attribute'
            }
          ],
          class: ''
        },
        line2: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Status',
                values: ['state'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-color-[color]-base u-text-case-title'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group ID',
                values: ['adGroupId'],
                titleClass: null,
                delimiter: '',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            }
          ],
          class: 'u-spacing-pb-m u-font-size-5'
        },
        filters: {
          config: {
            emit: 'spManualAdgroupDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'spManualAdgroupDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'spManualAdgroupDetails'
        },
        bookmark: {
          value: true,
          title: 'name',
          fromMetadata: true
        },
        activityLog: {
          show: true
        },
        redirectTo: {
          value: true,
          config: (that) => {
            const entityDetailsRouteName = `${retailer}EntityDetails`;
            return {
              name: entityDetailsRouteName,
              params: {
                entityId: ':entityId',
                entityType: 'sp_manual'
              },
              query: that?.$route?.query
            };
          }
        },
        metadata_api_state: {
          getter: 'entityDetails/getAdgroupDetails'
        }
      },
      chart: {
        page: 'adgroupDetailsSPManual',
        globalViewId: 0,
        pageId: 0,
        filter: 'spManualAdgroupDetailsFiltersApplied',
        maxFeedDate: 'campaigns_campaign',
        widget: 'adGroupDetailsChartingWbSPManual',
        enableDownload: false,
        enableRollup: true,
        config: {
          chartingWb: {
            ':widget': 'adGroupDetailsChartingWbSPManual',
            ':timeseriesRollupBy': 'DAY',
            keepExistingDimensionNameValueList: true,
            replaceEntityInDimensionNameValueList: {
              ':ad_group_id': ':entityId'
            },
            timeseries: 'report_date'
          }
        }
      },
      tabs: {
        keywordTargeting: getAdgroupsTargetingKeywordConfig(retailer),
        ...showNegativeKeywordTargetingForSamsClubApi(retailer),
        adItems: {
          component: adgroupProductADs,
          ...adgroupADsConfig(retailer)
        },
        searchTerms: getSearchTermTabsConfig(retailer),
        settings: getAdGroupSettingsConfig(retailer)
      }
    }
  };
}
