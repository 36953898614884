<template>
  <div class="u-width-100">
    <div
      class="u-color-grey-lighter u-height-50 u-font-size-7 u-font-weight-600 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      {{ mainHeaderLabel }}
    </div>
    <div
      class="u-height-50 u-width-100 u-border-color-grey-xxx-light u-border-top u-border-width-s"
    >
      <SplitCell
        :values="splitHeaderLabels"
        :use-as-header-labels="true"
      />
    </div>
  </div>
</template>

<script>
import SplitCell from '@/components/ams/media-planner/generate-plan/steps/goals/split-cell.vue';
export default {
  name: 'SplitCellHeader',
  components: {
    SplitCell
  },
  props: {
    mainHeaderLabel: {
      type: String,
      default: 'Header'
    },
    splitHeaderLabels: {
      type: Array,
      default: () => {
        return [];
      }
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-height-50 {
  height: 50%;
}
</style>
