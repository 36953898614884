<template>
  <div
    class="u-flex-1 u-display-flex u-position-relative"
    :class="isLoadingWidgetConfig ? 'u-viewport-height' : ''"
  >
    <div
      v-if="!isLoadingWidgetConfig"
      class="u-width-100 u-height-auto"
    >
      <div class="u-spacing-pt-l u-display-flex u-flex-direction-column card">
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-l"
        >
          <div
            class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-text-case-upper"
          >
            Charting Workbench
          </div>
          <div class="u-display-flex">
            <span
              class="u-spacing-ml-s u-display-flex u-flex-align-items-center u-spacing-mr-m"
            >
              <rb-datepicker
                :position="'Left'"
                :range-names="dateRanges"
                :min-date="selectedValues.minDate"
                :max-date="selectedValues.maxDate"
                :value="selectedValues.maxDate"
                :local-storage-key="localStorageKey"
              />
              <date-range
                :new-date="true"
                :min-date="selectedValues.minDate"
                :max-date="selectedValues.maxDate"
                :local-storage-key="localStorageKey"
                :default-range-select="defaultRangeSelect"
                :additional-ranges="additionalRanges"
                :date-mode="dateMode"
                :page="page"
                :enable-date-persistence="true"
                :user-page-metadata="userPageMetadata"
                @create="calendarCreated"
                @submit="calendarSubmitEvent"
              />
            </span>
            <!-- Directive to track Event change -->
            <!-- v-changetracker="`{type: 'View Changed', page: ${page}, pageIdentifier: ${skuID}, action: 'Click', eventType: 'Click', agent: 'browser', View:${selectedValues.template}`" -->
            <rb-select
              class="u-spacing-mr-m valueDropdown u-max-width-100"
              :send-details="true"
              :on-close="templateSelected"
              :options="templateList"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-grey-lighter u-cursor-pointer optionsDropdown"
              >
                <div class="u-display-flex u-flex-direction-column">
                  <span class="u-font-size-5">{{
                    selectedValues.template
                  }}</span>
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div
                  class="u-display-flex u-flex-align-items-center"
                  :title="option.label"
                >
                  {{ option.label }}
                </div>
              </template>
            </rb-select>
            <!-- v-tracker:click="`{type: 'Download', page: ${page}, pageIdentifier: ${skuID}, action: 'Click', eventType: 'Click', agent: 'browser'}`" -->
            <div class="optionsDropdown u-cursor-pointer">
              <span @click="downloadReport">
                <rb-icon
                  v-if="!isDownloadLoading && !isDownloadError"
                  class="rb-icon--medium u-color-grey-lighter"
                  :icon="'download'"
                />
              </span>
              <loader
                :loading="isDownloadLoading"
                class=""
                :color="'#007cf6'"
              />
              <rb-icon
                v-if="isDownloadError"
                class="rb-icon--medium u-color-red-base"
                :icon="'download'"
              />
            </div>
          </div>
        </div>
        <div class="u-position-relative u-display-flex u-spacing-ph-l">
          <div
            v-show="!isLoadingWidgetData"
            class="u-width-100"
          >
            <chart-with-legends
              :chart-data="formattedChartData"
              :chart-config="computedChartConfig"
              :metrics-list="metricList"
              :events-list="eventList"
              :metrics-shown="selectedValues.metricsShown"
              :default-select-metric="selectedValues.selectedMetric"
              :metric-config="selectedValues.metricDisplayConfig"
              :metric-data="selectedValues.metricData"
              :events-shown="computedEventsShown"
              :default-select-events="computedDefaultSelectEvents"
              :has-chart-events="hasChartEvents"
              :enable-add-event="false"
              class="u-spacing-mt-l"
              :selected-metric-limit="4"
              :disable-add-new-metric="disableAddNewMetric"
              :disable-add-new-event="disableAddNewEvent"
              :is-chart-loading="isChartLoading"
              :emit-on-metric-default-change="true"
              :plot-on-y-axis-only="true"
              :show-powered-by-c-i-q-text="true"
              :custom-chart-class="[]"
              @selectedMetricList="metricChanged"
              @selectedList="eventsChanged"
            >
              <div
                slot="space-between-legend-and-chart"
                class="u-display-flex u-flex-justify-content-flex-start u-spacing-pt-l"
              >
                <RollUpBy
                  :options="getRollUpOptions()"
                  label="Roll up by:"
                  :value="selectedRollUp.key"
                  @onRollUpChange="rollUpChanged"
                />
              </div>
            </chart-with-legends>
          </div>
          <div v-if="isLoadingWidgetData">
            <loader
              class="fill--parent u-bg-color-grey-white"
              :loading="isLoadingWidgetData"
              :color="'#007cf6'"
            />
          </div>
        </div>
        <div
          class="u-display-flex u-flex-align-items-center card-footer u-spacing-p-m u-border-top u-border-color-grey-xxx-light u-color-grey-x-light u-border-width-s u-font-size-6"
        >
          <span
            >Metrics data is available until
            <span class="u-color-grey-dark">
              {{
                formatter(
                  metricEventConfig.calendar &&
                    metricEventConfig.calendar.max_date,
                  'date'
                )
              }}</span
            ></span
          >
          <MetricRefreshDatesView
            class=""
            :refresh-page-id="metricRefreshPageId"
          />
          <span
            class="u-spacing-pl-s u-spacing-ml-s u-border-left u-border-width-s u-border-color-grey-xxx-light"
            >Events data is available until
            <span class="u-color-grey-dark">
              {{
                formatter(
                  (metricEventConfig.eventCalendar &&
                    metricEventConfig.eventCalendar.max_date) ||
                    metricEventConfig.calendar.max_date,
                  'date'
                )
              }}</span
            ></span
          >
        </div>
      </div>
    </div>
    <div v-if="isLoadingWidgetConfig">
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="isLoadingWidgetConfig"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>
<script>
import widgetConfig from './widget';
import moment from 'moment';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import MetricRefreshDatesView from '@/components/basic/metric-refresh-dates';
import utils from '@/utils/helpers/';
import logger from './logger';
import { differenceBy, cloneDeep } from 'lodash';
import { formatter } from '@/utils/helpers/formatter.js';
import Vue from 'vue';

/* eslint-disable */
export default {
  name: 'chartingWb',
  author: 'Rajan V',
  components: {
    rbDownloadButton,
    dateRange,
    chartWithLegends,
    loader,
    MetricRefreshDatesView,
    RollUpBy
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    skuID: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    },
    metricRefreshPageId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      _skuID: '',
      isLoadingWidgetConfig: false,
      isLoadingWidgetData: false,
      isChartLoading: false,
      isLoading: true,
      metricEventConfig: {},
      metricList: [],
      eventList: [],
      templateList: [],
      viewMetricMapping: {},
      nosOfMetricShown: 3,
      nosOfEventShown: 3,
      dateRanges: [
        'Last 7 days',
        'Last 30 days',
        'Last 90 days',
        'Last Week',
        'Last Month',
        'Last Quarter'
      ],
      selectedValues: {
        template: 'Sales View',
        report_date: [],
        minDate: moment().format('MM/DD/YYYY'),
        maxDate: moment().format('MM/DD/YYYY'),
        metricData: {},
        eventData: {},
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: [],
        eventsShown: [],
        selectedEvents: [],
        date: {},
        pvpDate: {},
        reportDateMapping: {},
        chartTooltip: {}
      },
      downloadComponent: {
        tempMetricObj: {},
        tempEventObj: {}
      },
      defaultRangeSelect: 'last7Days',
      chartData: {
        data: []
      },
      isDownloadLoading: false,
      isDownloadError: false,
      logging: {
        selectedMetric: [],
        selectedEvents: []
      },
      customViewMetrics: {
        metricsShown: [],
        eventsShown: []
      },
      localStorageKey: null,
      saveSelectionKey: 'SDP-saveSelection',
      dateRangeSelected: null,
      disableAddNewEvent: true,
      maxMetricListItem: 12,
      disableAddNewMetric: false,
      additionalRanges: {
        add: ['last7Days', 'last4Week', 'last13Week', 'last52Week'],
        order: {
          last7Days: 0,
          last4Week: 2,
          last13Week: 3,
          last52Week: 4
        }
      },
      dateMode: {
        mode: 'week',
        count: 0
      },
      selectedRollUp: { name: 'Days', key: 'DAY', diff: 'day' },
      rollUpOptions: [{ name: 'Days', key: 'DAY', diff: 'day' }] // Days would be available by default.
    };
  },
  created() {
    // clearing the localstorage key which we already released.
    // localStorage.removeItem(`SDP-calendar`);
    this.removeLocalStorageKey(`SDP-calendar`);
    this._ = _;
    this._skuID = this.skuID;
    this.localStorageKey = `SDP-calendar-web${this._skuID}`;
    // this.removeLocalStorageKey(this.localStorageKey);
    // localStorage.removeItem(this.localStorageKey);
    this.init();
    window.onbeforeunload = function () {
      // localStorage.removeItem(this.localStorageKey);
      this.removeLocalStorageKey(this.localStorageKey);
    }.bind(this);
  },
  watch: {
    skuID(newValue) {
      this._skuID = newValue;
      this.localStorageKey = `SDP-calendar-web${this._skuID}`;
      this.init();
    }
  },
  computed: {
    userPageMetadata() {
      return this.$store.getters.getUserPageMetadata;
    },
    formattedChartData() {
      let data = { ...this.chartData };
      return data;
    },
    computedChartConfig() {
      return this.chartConfig;
    },
    computedEventsShown() {
      return [...this.selectedValues.eventsShown];
    },
    computedDefaultSelectEvents() {
      return [...this.selectedValues.selectedEvents];
    },
    computedMetricShownConfig() {
      return { ...this.selectedValues.metricDisplayConfig };
    },
    // Metric list label-name mapping. Consumed in exludePvpForMetric
    metricListLabelNameMapping() {
      let map = {};
      this.metricList.forEach((metric) => {
        if (metric?.label && metric?.name) {
          map[metric.label] = metric.name;
        }
      });
      return map;
    },
    isRetailerAmazon3P() {
      return this.$store.getters.getRetailer === 'amazon_3p';
    },
    hasChartEvents() {
      return !this.isRetailerAmazon3P;
    }
  },
  methods: {
    returnMetricAliasFromMetricList(metricDisplayList) {
      return cloneDeep(metricDisplayList)?.map((metric) => {
        return metric?.name;
      });
    },
    generateMetricsFromMetricName(metricsList) {
      if (this.metricList?.length === 0) {
        return [];
      }
      return cloneDeep(metricsList)?.reduce((result, metric) => {
        const foundMetric = this.metricList?.find((metr) => {
          return metr?.name === metric;
        });
        if (foundMetric) {
          result.push(cloneDeep(foundMetric));
        }
        return result;
      }, []);
    },
    generateEventsFromEventName(eventsList) {
      if (this.eventList?.length === 0) {
        return [];
      }
      return cloneDeep(eventsList)?.reduce((result, event) => {
        const foundEvent = this.eventList?.find((eve) => {
          return eve?.name === event;
        });
        if (foundEvent) {
          result.push(cloneDeep(foundEvent));
        }
        return result;
      }, []);
    },
    formatter: formatter,
    init() {
      this.chartConfig = { ...widgetConfig.chartConfig };
      this.eventsIconMap = { ...widgetConfig.eventsIconMap };
      // Icon changes for unavailable and 3p variants.
      if (
        Vue.options.filters.config_check(
          'feature.merge_unavailable_recommendation.enable'
        )
      ) {
        this.eventsIconMap['has_3p_variant'].color = 'u-color-turquoise-base';
        this.eventsIconMap['unavailable'].color = 'u-color-red-base';
        this.eventsIconMap['availability'].color = 'u-color-red-base';
        this.eventsIconMap['unavailable_merged'] = {
          icon: 'availability',
          color: 'u-color-red-base'
        };
      }
      this.chartData.data = [];
      // fetch the metadata information
      let requests = [];
      this.config.API.forEach((api) => {
        requests.push(
          HttpService.post(
            api.service,
            { widget: 'chartingWb', id: this._skuID, page: this.page },
            { append: api.endPoint }
          )
        );
      });
      this.isLoadingWidgetConfig = true;
      Promise.all(requests).then(
        (response) => {
          if (!response.success && !Array.isArray(response)) {
            this.isLoadingWidgetConfig = false;
            return;
          }
          this.resetCharttingWb();
          // Transforming Metric.
          this.viewMetricMapping = {};
          this.metricList = [];
          this.eventList = [];
          this.metricEventConfig = response[0].data;
          // Setting up the mertic List
          let metricList = this.transformConfig(
            this.metricEventConfig.metrics,
            'metric'
          );
          this.metricList = [...metricList];
          // Setting up the event List
          let eventList = this.transformConfig(
            this.metricEventConfig.events,
            'event'
          );
          this.eventList = [...eventList];

          // Sort the item based on priority
          if (this.viewMetricMapping) {
            Object.keys(this.viewMetricMapping).forEach((item) => {
              if (this.viewMetricMapping[item].metricList) {
                this.viewMetricMapping[item].metricList.sort((a, b) => {
                  return a.priority - b.priority;
                });
              }
              if (this.viewMetricMapping[item].eventList) {
                this.viewMetricMapping[item].eventList.sort((a, b) => {
                  return a.priority - b.priority;
                });
              }
            });
          }

          // Setting up the View
          this.templateList = Object.keys(this.viewMetricMapping).map((key) => {
            return { label: key };
          });

          // Setting up the date
          this.selectedValues.minDate = moment(
            this.metricEventConfig.calendar.min_date,
            'YYYY-MM-DD'
          ).format('MM/DD/YYYY');
          this.selectedValues.maxDate = moment(
            this.metricEventConfig.calendar.max_date,
            'YYYY-MM-DD'
          ).format('MM/DD/YYYY');
          // Set up the calendar after the response of meta data. So that end date for chart will be events end date
          // Set up report date info
          // Setting up the default View
          let element = localStorage.getItem(this.saveSelectionKey);
          if (element) {
            element = JSON.parse(element);
            if (typeof element === 'object' && element !== null) {
              this.selectedValues.template = element.template;
              this.defaultRangeSelect =
                element.defaultRangeSelect || 'last30Days';
            }
          } else {
            this.selectedValues.template =
              'Sales View' ||
              this.metricEventConfig.metadata.defaultConfig.view;
          }
          const userPageMeta = this.$store.getters.getUserPageMetadata;

          if (userPageMeta?.page?.[this.page]?.selectedView) {
            this.selectedValues.template =
              userPageMeta.page[this.page].selectedView;
          }
          this.updateViews(this.selectedValues.template, true);

          this.rollUpChanged(
            userPageMeta?.page?.[this.page]?.selected_roll_up ?? {
              name: 'Days',
              key: 'DAY',
              diff: 'day'
            }
          );
          this.isLoadingWidgetConfig = false;
        },
        (error) => {
          this.isLoadingWidgetConfig = false;
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
      );
    },
    calendarSubmitEvent(event) {
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify({ date_range: event })
      );
      // Make calls here
      this.updatedateDateSelection(event, true);
    },
    calendarCreated(dateRange, fromCreated) {
      const userPageMeta = this.$store.getters.getUserPageMetadata;
      let storage = localStorage.getItem(this.localStorageKey);
      if (!storage && userPageMeta?.page?.[this.page]?.calendarSelection) {
        localStorage.setItem(
          this.localStorageKey,
          JSON.stringify(userPageMeta.page[this.page].calendarSelection)
        );
        this.updatedateDateSelection(
          userPageMeta.page[this.page].calendarSelection?.date_range
        );
      }
      if (fromCreated) {
        this.updatedateDateSelection(dateRange);
      }
    },
    updatedateDateSelection(range, resetEvent) {
      if (range) {
        this.$store.dispatch('updateUserMetadata', {
          type: 'page',
          typeId: this.page,
          calendarSelection: { date_range: range }
        });
        if (resetEvent) {
          logger.log({
            eventType: 'date_change_sdp',
            type: 'Click',
            page: `${this.page}`,
            pageIdentifier: `${this._skuID}`,
            eventContext: 'SDP Date Change',
            from: this.dateRangeSelected,
            to: range.name
          });
        }
        this.dateRangeSelected = range.name;
        // Construct PVP and Date
        this.selectedValues.date = {
          from: range.from,
          to: range.to,
          name: range.name,
          page_wise_min_max_key: this.page
        };
        this.selectedValues.pvpDate = {
          from: range.compare_from,
          to: range.compare_to,
          compare_name: range.compare_name
        };
        let endDate = range.to;
        // Set the Calender End date to Event Max Date
        if (range.name !== 'custom') {
          endDate =
            (this.metricEventConfig.eventCalendar &&
              this.metricEventConfig.eventCalendar.max_date) ||
            this.metricEventConfig.calendar.max_date;
        }
        // Auto-update selected rollup from 'week/month' to 'days' when range is lessser than week
        this.handleRollUpOnDateChange(range.from, endDate, resetEvent);
      }
    },
    handleRollUpOnDateChange(from, endDate, resetEvent) {
      // get available roll-up based on the new selected dates
      this.getRollUpOptions();
      const isSelectedRollupPresentInAvailableRollup =
        this.rollUpOptions.findIndex((rollUpItem) => {
          return rollUpItem.key === this.selectedRollUp.key;
        });
      // Change roll-up to day if selected rollup not available in current available options
      if (isSelectedRollupPresentInAvailableRollup !== -1) {
        this.setUpReportingDate();
      } else {
        const rollUpByDay = this.rollUpOptions.filter((rollUpItem) => {
          return rollUpItem.key === 'DAY';
        });
        this.rollUpChanged(rollUpByDay[0]);
        this.updateReportDataMapping(
          from,
          endDate,
          this.selectedRollUp.diff,
          resetEvent
        );
      }
    },
    getRollUpOptions() {
      let tempRollUpOptions;
      // get dynamic rollup based on selected date range
      if (this.selectedValues.date.from && this.selectedValues.date.to) {
        tempRollUpOptions = utils.getRollUps(this.selectedValues.date);
      }
      // RollUpBy component uses the property 'key' instead of 'value' which is defined in utility function.
      // Todo - make the property definition and consumption uniform.

      tempRollUpOptions = tempRollUpOptions?.map((option) => {
        option['key'] = option?.value;
        delete option.value;
        return option;
      });
      this.rollUpOptions = tempRollUpOptions;
      return tempRollUpOptions;
    },
    enumerateDates(start, end, diff) {
      const date = [];
      if (!diff) {
        while (moment(start) <= moment(end)) {
          date.push(moment(start).format('MM/DD/YYYY'));
          start = moment(start).add(diff || 1, 'days');
        }
      } else {
        const diffUnitOfTime = `${diff}s`;
        date.push(moment(start).format('MM/DD/YYYY'));
        while (moment(end).diff(start, diffUnitOfTime) > 0) {
          const currentEnd = moment(
            moment(start).add(1, diffUnitOfTime)
          ).format('MM/DD/YYYY');
          date.push(currentEnd);
          start = currentEnd;
        }
      }
      return date;
    },
    updateReportDataMapping(from, to, diff, resetEvent) {
      const report_date = this.enumerateDates(from, to, diff);
      // Doing this for smart comparision. Empty dates are not handled in backend.
      this.selectedValues.reportDateMapping = report_date.reduce(
        (acc, item) => {
          acc[item] = true;
          return acc;
        },
        {}
      );
      if (resetEvent) {
        this.chartData.data = [];
        this.resetCharttingWb();
      }
      this.chartData.data.push(['report_date', ...report_date]);
    },
    transformConfig(config, type) {
      let _that = this;
      if (Vue.options.filters.config_check('feature.product.ams_lite')) {
        config = Object.keys(config).reduce((acc, key) => {
          let findIndex = config[key].views.findIndex(
            (item) => item.label === 'Advertising View'
          );
          if (findIndex === -1) {
            acc[key] = config[key];
            return acc;
          } else {
            // Check for multiple Views exist. If it's more than one view, then remove the view from array
            if (config[key].views.length !== 1) {
              config[key].views = config[key].views.splice(findIndex, 1);
              acc[key] = config[key];
              return acc;
            }
          }
          return acc;
        }, {});
      }
      let metrticList = Object.keys(config).map((key) => {
        let metric = config[key];
        metric.title = metric.label;
        metric.key = metric.label;
        // Extract views from the views object.
        // Selected Metric to View Mapping
        if (type === 'event') {
          let icon = (_that.eventsIconMap[metric.keyName] || {}).icon || 'dot';
          let color =
            (_that.eventsIconMap[metric.keyName] || {}).color ||
            'u-color-black-base';
          this.$set(metric, 'icon', icon);
          this.$set(metric, 'color', color);
          _that.chartConfig.chartOptions.types[metric.title] = 'scatter';
          _that.chartConfig.chartOptions.events.push({
            key: metric.title,
            icon: metric.icon
          });
          _that.selectedValues.chartTooltip[metric.title] = [];
          _that.chartConfig.chartOptions.tooltip_format[metric.title] =
            function (value, ratio, id, index) {
              return (
                '' + this.selectedValues.chartTooltip[metric.title][index] || ''
              );
            }.bind(this);
        }

        if (type === 'metric') {
          let config = {};
          config['invertTag2'] = metric.metadata.isInverted || false;
          if (metric.metadata.unit === 'CURRENCY') {
            config['tag1Unit'] = { pre: 'currency' };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              pre: 'currency'
            };
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
          } else if (metric.metadata.unit === 'PERCENTAGE') {
            config['tag1Unit'] = { suff: '%' };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              suff: '%'
            };
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
          } else {
            config['tag1Unit'] = null;
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = null;
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
          }
          config['tag2Unit'] = { suff: '%' };
          _that.selectedValues.metricDisplayConfig[metric.title] = config;
          if (
            metric.metadata.type &&
            metric.metadata.type.toLowerCase() === 'number'
          ) {
            config['tag1Unit'] = { roundoff: 0 };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              roundoff: 0
            };
          }
          metric.views.forEach((item) => {
            if (
              _that.viewMetricMapping[item.label] &&
              _that.viewMetricMapping[item.label].metricList
            ) {
              if (!item.selected) {
                _that.viewMetricMapping[item.label].metricList.push(metric);
              }
            } else {
              if (!_that.viewMetricMapping[item.label]) {
                _that.viewMetricMapping[item.label] = {};
              }
              _that.viewMetricMapping[item.label].metricList = [];
              if (!item.selected) {
                _that.viewMetricMapping[item.label].metricList.push(metric);
              }
            }
          });
        } else {
          metric.views.forEach((item) => {
            if (
              _that.viewMetricMapping[item.label] &&
              _that.viewMetricMapping[item.label].eventList
            ) {
              if (!item.selected) {
                _that.viewMetricMapping[item.label].eventList.push(metric);
              }
            } else {
              if (!_that.viewMetricMapping[item.label]) {
                _that.viewMetricMapping[item.label] = {};
              }
              _that.viewMetricMapping[item.label].eventList = [];
              if (!item.selected) {
                _that.viewMetricMapping[item.label].eventList.push(metric);
              }
            }
          });
        }
        return metric;
      });
      // Order the merticList based on priority
      metrticList.sort((a, b) => {
        return a.priority - b.priority;
      });
      return metrticList;
    },
    handleLocalStorage(events, type) {
      let _tempList = events.selectedMetric.map((item) => item.key);
      let saveMetric = events.metricDisplayList.map((item) => {
        let _newItem = {};
        _newItem.key = item.key;
        if (_tempList.indexOf(item.key) !== -1) {
          _newItem.selected = true;
        }
        return _newItem;
      });
      let element = localStorage.getItem(this.saveSelectionKey);
      let saveObj = {};
      if (!element) {
        saveObj[type] = saveMetric;
      } else {
        element = JSON.parse(element);
        if (typeof element === 'object' && element !== null) {
          saveObj = element;
        }
        saveObj[type] = saveMetric;
      }
      saveObj['template'] = this.selectedValues.template;
      if (this.dateRangeSelected !== 'custom') {
        saveObj['defaultRangeSelect'] = this.dateRangeSelected;
      }
      localStorage.setItem(this.saveSelectionKey, JSON.stringify(saveObj));
    },
    metricChangedInitMethod(event) {
      if (event.metricDisplayList || event.selectedMetric) {
        const metricsShownAlias = this.returnMetricAliasFromMetricList(
          event.metricDisplayList
        );
        const selectedMetricAlias = this.returnMetricAliasFromMetricList(
          event.selectedMetric
        );

        const filteredSelectedMetrics = selectedMetricAlias.filter((metric) => {
          return metricsShownAlias?.includes(metric);
        });

        this.$store.dispatch('updateUserMetadata', {
          type: 'page',
          typeId: this.page,
          metricsShown: metricsShownAlias,
          selectedMetric: filteredSelectedMetrics
        });
      } else {
        this.updateMetricsAndEventsFromMeta('event');
      }
    },
    async metricChanged(event, rollupChanged = false) {
      this.metricChangedInitMethod(event);

      if (Object.keys(event)?.length === 0) {
        return;
      }

      // Find out the Metric data which is not available
      let tempMetrics = [...event.metricDisplayList];
      this.downloadComponent.tempMetricObj = JSON.parse(JSON.stringify(event));
      if (this.logging.selectedMetric.length !== 0) {
        this.logActivity(
          this.logging.selectedMetric,
          event.selectedMetric,
          'metric_change'
        );
        this.logging.selectedMetric = JSON.parse(
          JSON.stringify(event.selectedMetric)
        );
      } else {
        this.logging.selectedMetric = JSON.parse(
          JSON.stringify(event.selectedMetric)
        );
      }
      this.detectCustomChange();
      this.handleLocalStorage(event, 'metric');

      if (event.metricDisplayList.length < this.maxMetricListItem) {
        this.disableAddNewMetric = false;
      } else {
        this.disableAddNewMetric = true;
      }
      if (rollupChanged) {
        this.selectedValues.metricData = {};
        tempMetrics = tempMetrics.map((item) => {
          this.$set(this.selectedValues.metricData, item.key, {
            tag1: 'loading'
          });
          return item;
        });
      } else {
        tempMetrics = tempMetrics.filter((item) => {
          if (!this.selectedValues.metricData[item.key]) {
            this.$set(this.selectedValues.metricData, item.key, {
              tag1: 'loading'
            });
            return item;
          }
        });
      }
      if (tempMetrics.length > 0) {
        // Group by dataGroup
        const dataGroup = tempMetrics.reduce((acc, item) => {
          if (!acc[item.dataGroup]) {
            acc[item.dataGroup] = [];
            acc[item.dataGroup].push(item);
          } else {
            acc[item.dataGroup].push(item);
          }
          return acc;
        }, {});
        // Formulate the request based on the datagroup
        const requests = Object.keys(dataGroup).map((item) => {
          let api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
          api.request.metricsList = [];
          api.request.page = 'sku_details';
          api.request.entityType = 'asin';
          api.request.entityValue = this._skuID;
          api.request.eventsList = [];
          api.request.where.dimensionNameValueList = [];
          api.request.where.date = { ...this.selectedValues.date };
          api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
          api.request.operations.timeseriesRollupBy = this.selectedRollUp.key;
          // 04/22/2019", "max_date":"04/22/2020"
          dataGroup[item].forEach((list) => {
            api.request.metricsList.push(list.name);
          });
          return HttpService.post(
            api.service,
            { ...api.request },
            { append: '/entity/metrics/data' }
          );
        });
        await this.fetchMetricData(requests);
      }
    },
    eventsChanged(event) {
      if (event.metricDisplayList || event.selectedMetric) {
        const eventsShownAlias = this.returnMetricAliasFromMetricList(
          event.metricDisplayList
        );
        const selectedEventAlias = this.returnMetricAliasFromMetricList(
          event.selectedMetric
        );

        const filteredSelectedEvents = selectedEventAlias.filter((event) => {
          return eventsShownAlias?.includes(event);
        });

        this.$store.dispatch('updateUserMetadata', {
          type: 'page',
          typeId: this.page,
          eventsShown: eventsShownAlias,
          selectedEvents: filteredSelectedEvents
        });
      } else {
        this.updateMetricsAndEventsFromMeta('metric');
      }

      if (Object.keys(event).length > 0) {
        this.downloadComponent.tempEventObj = JSON.parse(JSON.stringify(event));
        // this.selectedValues.selectedEvents = [...event];
        if (this.logging.selectedEvents.length !== 0) {
          this.logActivity(
            this.logging.selectedEvents,
            event.selectedMetric,
            'event_change'
          );
          this.logging.selectedEvents = JSON.parse(
            JSON.stringify(event.selectedMetric)
          );
        } else {
          this.logging.selectedEvents = JSON.parse(
            JSON.stringify(event.selectedMetric)
          );
        }
        this.detectCustomChange();
        this.handleLocalStorage(event, 'event');

        if (event.metricDisplayList.length < this.nosOfEventShown) {
          this.disableAddNewEvent = false;
        } else {
          this.disableAddNewEvent = true;
        }

        // Group by dataGroup
        // let tempEvents = [...event.metricDisplayList];
        // Usability: Show number of events in events dropdowns in SKU Details Page (https://boomerang.atlassian.net/browse/PROD-101239)
        let tempEvents = [...this.eventList];
        tempEvents = tempEvents.filter((item) => {
          if (this.selectedValues.eventData[item.label]) {
            return false;
          } else {
            return item;
          }
        });
        if (tempEvents.length > 0) {
          const dataGroup = tempEvents.reduce((acc, item) => {
            if (!acc[item.dataGroup]) {
              acc[item.dataGroup] = [];
              acc[item.dataGroup].push(item);
            } else {
              acc[item.dataGroup].push(item);
            }
            return acc;
          }, {});
          // Formulate the request based on the datagroup
          const requests = Object.keys(dataGroup).map((item) => {
            if (item && dataGroup[item] && dataGroup[item][0]) {
              let api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
              api.request.metricsList = [];
              api.request.page = 'sku_details';
              api.request.entityType = 'asin';
              api.request.entityValue = this._skuID;
              api.request.eventsList = [];
              api.request.where.dimensionNameValueList = [];
              api.request.where.date = { ...this.selectedValues.date };
              api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
              api.request.operations.timeseriesRollupBy =
                this.selectedRollUp.key;
              dataGroup[item].forEach((list) => {
                api.request.eventsList.push(list.name);
              });
              // Events date: Soln for the custom Date event
              let storage = JSON.parse(
                localStorage.getItem(this.localStorageKey)
              );
              if (
                storage &&
                storage.date_range &&
                storage.date_range.name !== 'custom'
              ) {
                // Fallback to the calendar Max date - fix to sentry
                api.request.where.date.to =
                  (this.metricEventConfig.eventCalendar &&
                    this.metricEventConfig.eventCalendar.max_date) ||
                  this.metricEventConfig.calendar.max_date;
              }
              // Check date to is greater than date from.
              // Fix for dp/1903365260 events api.
              if (
                !moment(api.request.where.date.to).isAfter(
                  api.request.where.date.from
                )
              ) {
                api.request.where.date.to = this.selectedValues.date.to;
              }

              return {
                service: api.service,
                request: { ...api.request },
                append: api.endPoint
              };
            }
          });
          this.fetchEventData(requests);
        }
      }
    },
    templateSelected(context, val) {
      if (
        val.length > 0 &&
        val[0].label &&
        val[0].label !== this.selectedValues.template
      ) {
        logger.log({
          eventType: 'view_change',
          type: 'Click',
          page: `${this.page}`,
          pageIdentifier: `${this._skuID}`,
          eventContext: 'SDP View Change',
          from: this.selectedValues.template,
          to: val[0].label
        });
        this.selectedValues.template = val[0].label;
        // Change the default Mertics and default events.

        this.updateViews(this.selectedValues.template);
      }
    },
    filterValues(_tempObj, type, enableSelected, srcType) {
      let _arr = this[type].filter((item) => {
        let itemIndex = null;
        if (_tempObj[srcType]) {
          itemIndex = _tempObj[srcType].find((metric) => {
            if (item.key === metric.key) {
              if (enableSelected) {
                if (metric.selected) {
                  return true;
                }
                return false;
              } else {
                return true;
              }
            }
            return false;
          });
        }
        if (itemIndex) {
          return item;
        }
      });
      return _arr;
    },
    updateViews(viewName, readFromLocalStorage) {
      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: this.page,
        selectedView: viewName
      });
      const userPageMeta = this.$store.getters.getUserPageMetadata;
      const userMetaMetricsShown =
        userPageMeta?.page?.[this.page]?.metricsShown;
      // Localstorage Handling
      this.selectedValues.templateCopy = viewName;
      let list = this.viewMetricMapping[viewName];
      this.logging.selectedMetric = [];
      this.logging.selectedEvents = [];
      this.selectedValues.selectedMetric = [];
      this.selectedValues.metricsShown = [];
      this.selectedValues.selectedEvents = [];
      this.selectedValues.eventsShown = [];
      let element = localStorage.getItem(this.saveSelectionKey);
      if (readFromLocalStorage && element) {
        if (element) {
          let _tempObj = JSON.parse(element);

          this.selectedValues.metricsShown =
            this.filterValues(_tempObj, 'metricList', false, 'metric') || [];
          this.selectedValues.selectedMetric =
            this.filterValues(_tempObj, 'metricList', true, 'metric') || [];
          this.selectedValues.eventsShown =
            this.filterValues(_tempObj, 'eventList', false, 'event') || [];
          this.selectedValues.selectedEvents =
            this.filterValues(_tempObj, 'eventList', true, 'event') || [];
          this.selectedValues.template = element.template;
          if (
            this.selectedValues.eventsShown &&
            this.selectedValues.eventsShown.length === 0
          ) {
            this.selectedValues.eventsShown = this.eventList.slice(
              0,
              this.nosOfEventShown
            );
            this.selectedValues.selectedEvents = this.eventList.slice(
              0,
              this.nosOfEventShown
            );
          }
        }
      } else if (userMetaMetricsShown?.length && readFromLocalStorage) {
        this.updateMetricsAndEventsFromMeta();
      } else {
        this.selectedValues.metricsShown = this.viewMetricMapping[
          viewName
        ].metricList.slice(0, this.nosOfMetricShown);
        // Set the selected Metric Values
        this.selectedValues.selectedMetric = this.viewMetricMapping[
          viewName
        ].metricList.slice(0, 2);
        if (
          this.viewMetricMapping[viewName] &&
          this.viewMetricMapping[viewName].eventList
        ) {
          this.selectedValues.eventsShown = this.viewMetricMapping[
            viewName
          ].eventList.slice(0, this.nosOfEventShown);
          this.selectedValues.selectedEvents = this.viewMetricMapping[
            viewName
          ].eventList.slice(0, this.nosOfEventShown);
        } else {
          this.selectedValues.eventsShown = this.eventList.slice(
            0,
            this.nosOfEventShown
          );
          this.selectedValues.selectedEvents = this.eventList.slice(
            0,
            this.nosOfEventShown
          );
        }
      }

      // Set the Selected metric as base metric to compare the custom metric selected or not

      const defaultMetricsShown = this.viewMetricMapping[
        viewName
      ].metricList.slice(0, this.nosOfMetricShown);

      const defaultEventsShown = this.viewMetricMapping[viewName]?.eventList
        ? this.viewMetricMapping[viewName].eventList.slice(
            0,
            this.nosOfEventShown
          )
        : this.eventList.slice(0, this.nosOfEventShown);

      this.customViewMetrics.metricsShown = JSON.parse(
        JSON.stringify(defaultMetricsShown)
      );
      this.customViewMetrics.eventsShown = JSON.parse(
        JSON.stringify(defaultEventsShown)
      );
    },
    validateDataResponse(response) {
      var validResponse = [];
      // Extract out the valid responses from the backend.
      let mergeResponse = response.reduce((acc, item) => {
        acc = acc.concat(item.data.entityData);
        return acc;
      }, []);
      if (mergeResponse.length === 0) {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!' || error,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        return;
      } else {
        validResponse = mergeResponse.reduce((acc, item) => {
          if (item.success) {
            item.data.forEach((data) => {
              if (
                data.type === 'METRICS' &&
                this.metricEventConfig['metrics'] &&
                this.metricEventConfig['metrics'][data.alias]
              ) {
                acc.push(data);
              } else if (
                data.type === 'EVENT' &&
                this.metricEventConfig['events'] &&
                this.metricEventConfig['events'][data.alias]
              ) {
                acc.push(data);
              }
            });
          }
          return acc;
        }, []);
      }
      return validResponse;
    },
    async fetchMetricData(request) {
      // this.isLoadingWidgetData = true;
      this.isChartLoading = true;
      await Promise.all(request)
        .then((response) => {
          let validResponse = this.validateDataResponse(response);

          if (validResponse.length === 0) {
            this.$snackbar.open({
              message: 'Something Went Wrong with Metric Data!!' || error,
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
            return;
          }
          let mergeData = this.transformResponse(validResponse);
          mergeData = [].concat(...mergeData);
          // Push the mertic component data.
          let metricData = mergeData.reduce((acc, item) => {
            acc[item.key] = item.metricData[item.key];
            return acc;
          }, {});
          // Push chart Data
          let chartData = mergeData.map((item) => {
            return item.timeseries;
          });
          chartData.forEach((chartItem) => {
            let indexOfChartItem = this.chartData?.data?.findIndex((item) => {
              return item[0] === chartItem[0];
            });
            if (indexOfChartItem > -1) {
              this.chartData.data.splice(indexOfChartItem, 1);
            }
            this.chartData.data.push(chartItem);
          });
          this.selectedValues.metricData = {
            ...this.selectedValues.metricData,
            ...metricData
          };
          // remove pvp for metrics present in excludePvpDisplay array
          this.selectedValues.metricData = this.exludePvpForMetric(
            this.selectedValues.metricData
          );
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          if (Object.keys(this.selectedValues.metricData).length > 0) {
            Object.keys(this.selectedValues.metricData).forEach((item) => {
              if (
                this.selectedValues.metricData[item] &&
                this.selectedValues.metricData[item]['tag1'] &&
                this.selectedValues.metricData[item]['tag1'] === 'loading'
              ) {
                this.$set(
                  this.selectedValues.metricData[item],
                  'tag1',
                  'error'
                );
              }
            });
          }
          this.isChartLoading = false;
        });
    },
    exludePvpForMetric(metricData) {
      let excludePvpDisplayMetric =
        this.metricEventConfig?.metadata?.excludePvpDisplay || {};
      for (let metric in metricData) {
        if (
          Array.isArray(excludePvpDisplayMetric) &&
          excludePvpDisplayMetric.includes(
            this.metricListLabelNameMapping[metric]
          ) &&
          metricData[metric]?.tag1
        ) {
          metricData[metric].tag2 = 0;
        }
      }
      return metricData;
    },
    fetchEventData(request) {
      this.isChartLoading = true;
      // this.isLoadingWidgetData = true;
      let requests = [];
      request.forEach((item) => {
        requests.push(
          HttpService.post(
            item.service,
            { ...item.request },
            { append: item.append }
          )
        );
      });
      Promise.all(requests)
        .then((response) => {
          // this.isLoadingWidgetData = false;
          let validResponse = this.validateDataResponse(response);

          if (validResponse.length === 0) {
            this.$snackbar.open({
              message: 'Something Went Wrong with Event Data !!' || error,
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
          }
          let mergeData = this.transformResponse(validResponse);
          mergeData = [].concat(...mergeData);
          let eventData = mergeData.reduce((acc, item) => {
            acc[item.key] = item.eventData[item.key];
            return acc;
          }, {});

          // Usability: Show number of events in events dropdowns in SKU Details Page (https://boomerang.atlassian.net/browse/PROD-101239)
          this.eventList = this.eventList.map((item) => {
            // find out the obj for event
            const eventData = mergeData.filter(
              (eventDate) => eventDate.key === item.key
            );
            if (eventData.length > 0) {
              const eventCount = eventData[0]?.timeseries?.filter(
                (data) => data === 0
              )?.length;
              item.eventCount = eventCount;
            }
            return item;
          });

          // Push chart Data
          let chartData = mergeData.map((item) => {
            return item.timeseries;
          });
          chartData.forEach((chartItem) => {
            let indexOfChartItem = this.chartData?.data?.findIndex((item) => {
              return item[0] === chartItem[0];
            });
            if (indexOfChartItem > -1) {
              this.chartData.data.splice(indexOfChartItem, 1);
            }
            this.chartData.data.push(chartItem);
          });
          this.selectedValues.eventData = {
            ...this.selectedValues.eventData,
            ...eventData
          };
        })
        .catch((error) => {
          let mergeRequest = request.map((item) => {
            return item.request.eventsList;
          });
          mergeRequest = [].concat(...mergeRequest);
          if (this.selectedValues.selectedEvents.length > 0) {
            this.selectedValues.selectedEvents.map((item, index) => {
              if (mergeRequest.indexOf(item.name) !== -1) {
                this.$set(
                  this.selectedValues.selectedEvents[index],
                  'icon',
                  'error-fill'
                );
                this.$set(
                  this.selectedValues.selectedEvents[index],
                  'color',
                  'u-color-grey-x-light'
                );
              }
              return item;
            });
          }
          this.$snackbar.open({
            message: 'Something Went Wrong Event !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isChartLoading = false;
        });
    },
    transformResponse(response) {
      let _that = this;
      return response.map((item) => {
        let obj = {};
        let oObject = {};
        obj['name'] = item.name;
        if (
          item.type === 'METRICS' &&
          _that.metricEventConfig['metrics'] &&
          _that.metricEventConfig['metrics'][item.alias]
        ) {
          oObject = _that.metricEventConfig['metrics'][item.alias];
          obj['key'] = oObject.key;
          obj['metricData'] = {};
          obj['metricData'][oObject.key] = {};
          obj['metricData'][oObject.key]['tag1'] = item.RESULT[item.name];
          obj['metricData'][oObject.key]['tag2'] = item.PVP['PVP_' + item.name];
          if (oObject.metadata.unit === 'PERCENTAGE') {
            // If unit is PERCENTAGE keep tag2 as PVP_DIFF_
            obj['metricData'][oObject.key]['tag2'] =
              item.PVP['PVP_DIFF_' + item.name];
          }

          // TimeSeries - Map it's date and value for smart filtering.
          let tempObject = {};
          if (item.TIMESERIES) {
            let keyToRead =
              (oObject && oObject.api.request.operations.timeseriesDimension) ||
              'feed_date';
            // let keyToRead = 'feed_date';
            tempObject = item.TIMESERIES.reduce((acc, value) => {
              acc[value[keyToRead]] = value[item.name];
              return acc;
            }, {});
          }
          let timeseries = Object.keys(
            _that.selectedValues.reportDateMapping
          ).map((item) => {
            if (tempObject[item]) {
              return tempObject[item];
            } else if (tempObject[item] === 0) {
              return 0;
            } else {
              return null;
            }
          });
          timeseries.unshift(oObject.key);
          // Check to find key is already exist in the chartData
          obj['timeseries'] = [...timeseries];
        } else if (
          item.type === 'EVENT' &&
          _that.metricEventConfig['events'][item.name]
        ) {
          oObject = _that.metricEventConfig['events'][item.name];
          obj['key'] = oObject.key;
          obj['eventData'] = {};
          obj['eventData'][oObject.key] = true;
          let tempObject = {};
          if (item.TIMESERIES) {
            let keyToRead =
              (oObject && oObject.api.request.operations.timeseriesDimension) ||
              'feed_date';
            // let keyToRead = 'feed_date';
            tempObject = item.TIMESERIES.reduce((acc, value) => {
              acc[value[keyToRead]] = JSON.parse(value[item.name]) || {};
              return acc;
            }, {});
          }

          let timeseries = Object.keys(
            _that.selectedValues.reportDateMapping
          ).map((item) => {
            if (tempObject[item] && tempObject[item]['value']) {
              let tooltip = tempObject[item]['tooltip'];
              // Check for type of tooltip if not array, push the response string as tooltip info.
              if (Array.isArray(tooltip)) {
                tooltip = tooltip
                  .map((listItem) => {
                    return listItem[oObject.keyName];
                  })
                  .join(', ');
              }
              // tooltip formatting
              let maxLength = 75;
              if (tooltip.length > maxLength) {
                tooltip = `${tooltip.slice(0, maxLength)} & more...`;
              }
              _that.selectedValues.chartTooltip[oObject['label']].push(tooltip);
              return 0;
            } else {
              _that.selectedValues.chartTooltip[oObject['label']].push(null);
              return null;
            }
          });
          timeseries.unshift(oObject.key);
          // Check to find key is already exist in the chartData
          obj['timeseries'] = [...timeseries];
        }

        return obj;
      });
    },
    resetCharttingWb() {
      // Resetting the Workbench.
      // Reset the metaData
      this.selectedValues.metricData = {};
      this.selectedValues.eventData = {};
      // Clearing the tooltip info.
      Object.keys(this.selectedValues.chartTooltip).forEach((item) => {
        this.selectedValues.chartTooltip[item] = [];
      });
      // Clearing for logging events
      this.logging.selectedMetric = [];
      this.logging.selectedEvents = [];

      this.metricChanged(this.downloadComponent.tempMetricObj);
      this.eventsChanged(this.downloadComponent.tempEventObj);
    },
    downloadReport() {
      logger.log({
        eventType: 'download_button_click',
        type: 'Click',
        page: `${this.page}`,
        pageIdentifier: `${this._skuID}`,
        eventContext: 'SDP Download Button'
      });

      this.isDownloadLoading = true;
      let measureList =
        this.downloadComponent?.tempMetricObj?.metricDisplayList?.map(
          (item) => {
            return item.name;
          }
        ) || [];
      let eventList =
        this.downloadComponent?.tempEventObj?.metricDisplayList?.map((item) => {
          return item.name;
        }) || [];
      // measureList.push(...this.downloadComponent.tempMetricObj.metricDisplayList)
      let APITemplate = { ...this.metricEventConfig.downloadApiTemplate };
      APITemplate.request.metricsList = [...measureList];
      APITemplate.request.where.dimensionNameValueList = [];
      APITemplate.request.where.date = { ...this.selectedValues.date };
      APITemplate.request.where.pvpDate = { ...this.selectedValues.pvpDate };
      APITemplate.request.page = 'sku_details';
      APITemplate.request.entityValue = this._skuID;
      APITemplate.request.entityType = 'asin';
      APITemplate.request.eventsList = [...eventList];
      const dataGroup =
        this.downloadComponent.tempMetricObj.metricDisplayList.reduce(
          (acc, item) => {
            if (!acc[item.dataGroup]) {
              acc[item.dataGroup] = [];
              acc[item.dataGroup].push(item);
            } else {
              acc[item.dataGroup].push(item);
            }
            return acc;
          },
          {}
        );
      const overridenDataGroupsRequest = this.constructRequest(
        dataGroup
      ).reduce((acc, item) => {
        if (!acc[item.dataGroup]) {
          acc[item.request.dataGroup] = item.request;
        } else {
          acc[item.request.dataGroup] = item.request;
        }
        return acc;
      }, {});
      APITemplate.request.overridenDataGroupsRequest =
        overridenDataGroupsRequest;

      HttpService.post(
        APITemplate.service,
        { ...APITemplate.request },
        { append: APITemplate.endPoint }
      )
        .then((response) => {
          if (response.status) {
            let fileName = response.data.url.split('/');
            fileName = fileName[fileName.length - 1];
            let anchor = document.createElement('a');
            anchor.href = response.data.url;
            anchor.setAttribute('download', fileName);
            anchor.click();
          } else {
            this.handleDownloadError();
          }
          this.isDownloadLoading = false;
        })
        .catch((error) => {
          this.isDownloadLoading = false;
          this.handleDownloadError();
        });
    },
    constructRequest(dataGroup) {
      const requests = Object.keys(dataGroup).map((item) => {
        const api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
        api.request.metricsList = [];
        api.request.page = this.page;
        api.request.widget = this.config.widgetName;
        api.request.entityValue = this._skuID;
        api.request.eventsList = [];
        api.request.dataGroup = item;
        (api.request.entityType = 'asin'),
          (api.request.where.dimensionNameValueList = []);
        api.request.where.date = { ...this.selectedValues.date };
        api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
        api.request.operations.timeseriesRollupBy = this.selectedRollUp.key;
        dataGroup[item].forEach((list) => {
          api.request.metricsList.push(list.name);
        });
        return api;
      });
      return requests;
    },
    handleDownloadError() {
      this.isDownloadError = true;
      this.$snackbar.open({
        message:
          'Unable to download data at this moment, Please try after sometime.' ||
          error,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
      setTimeout(() => {
        this.isDownloadError = false;
      }, 4000);
    },
    logActivity(prevArray, newArray, eventType) {
      let oldMetric = differenceBy(prevArray, newArray, (a, b) => {
        return a.key;
      });
      let newMetric = differenceBy(newArray, prevArray, (a, b) => {
        return a.key;
      });
      if (newMetric.length > 0 && oldMetric.length > 0) {
        logger.log({
          eventType: eventType,
          type: 'Click',
          page: `${this.page}`,
          pageIdentifier: `${this._skuID}`,
          eventContext: eventType,
          from: oldMetric[0] && oldMetric[0].key,
          to: newMetric[0] && newMetric[0].key
        });
      }
    },
    detectCustomChange() {
      let _aMetric = this.customViewMetrics.metricsShown;
      let _bMetric =
        (this.downloadComponent.tempMetricObj &&
          this.downloadComponent.tempMetricObj.metricDisplayList) ||
        [];
      if (_aMetric.length < _bMetric.length) {
        [_aMetric, _bMetric] = [_bMetric, _aMetric];
      }
      let metricList = differenceBy(_aMetric, _bMetric, (a, b) => {
        return a.key;
      });
      let _aEvent = this.customViewMetrics.eventsShown;
      let _bEvent =
        (this.downloadComponent.tempEventObj &&
          this.downloadComponent.tempEventObj.metricDisplayList) ||
        [];
      if (_aEvent.length < _bEvent.length) {
        [_aEvent, _bEvent] = [_bEvent, _aEvent];
      }
      let eventList = differenceBy(_aEvent, _bEvent, (a, b) => {
        return a.key;
      });
      if (metricList.length === 0 && eventList.length === 0) {
        this.selectedValues.template = this.selectedValues.templateCopy;
      } else {
        this.selectedValues.template = 'Custom';
      }
    },
    removeLocalStorageKey(key) {
      // Clearing the localstorage. This approach won't work if we open the same sku in two tabs. Clearing localstorage otherwise it will keep on growing
      localStorage.removeItem(key);
    },
    rollUpChanged(selection) {
      this.$store.dispatch('updateUserMetadata', {
        type: 'page',
        typeId: this.page,
        selected_roll_up: selection
      });
      if (
        selection?.key &&
        selection?.key !== (this.selectedRollUp && this.selectedRollUp.key)
      ) {
        this.selectedRollUp = selection;
        this.setUpReportingDate();
      }
    },
    updateMetricsAndEventsFromMeta(ignoreType) {
      const userPageMeta = this.$store.getters.getUserPageMetadata;
      const initUserPageMeta = this.$store.getters.getInitUserPageMetadata;
      if (
        initUserPageMeta?.page?.[this.page]?.selectedView ===
        userPageMeta?.page?.[this.page]?.selectedView
      ) {
        if (
          userPageMeta?.page?.[this.page]?.metricsShown &&
          this.metricList?.length &&
          ignoreType !== 'metric'
        ) {
          this.selectedValues.metricsShown = this.generateMetricsFromMetricName(
            userPageMeta?.page?.[this.page]?.metricsShown
          );

          const filteredSelectedMetric = userPageMeta?.page?.[
            this.page
          ]?.selectedMetric?.filter((metric) => {
            return userPageMeta?.page?.[this.page]?.metricsShown?.includes(
              metric
            );
          });

          this.selectedValues.selectedMetric =
            this.generateMetricsFromMetricName(filteredSelectedMetric);
        }
        if (
          userPageMeta?.page?.[this.page]?.eventsShown &&
          this.eventList?.length &&
          ignoreType !== 'event'
        ) {
          this.selectedValues.eventsShown = this.generateEventsFromEventName(
            userPageMeta?.page?.[this.page]?.eventsShown
          );
          this.selectedValues.selectedEvents = this.generateEventsFromEventName(
            userPageMeta?.page?.[this.page]?.selectedEvents
          );
        }
      }
    },
    setUpReportingDate() {
      if (
        !this.selectedRollUp?.diff ||
        !this.selectedValues.date.to ||
        !this.selectedValues.date.from
      ) {
        return;
      }
      // Populate the report date for chart based on the store date and roll up value.
      const date = { ...this.selectedValues.date };
      if (this.selectedRollUp.diff === 'week') {
        date.from = moment(date.from).startOf('week').format('YYYY-MM-DD');
      } else if (this.selectedRollUp.diff === 'month') {
        date.from = moment(date.from).startOf('month').format('YYYY-MM-DD');
      }
      this.updateReportDataMapping(
        date.from,
        date.to,
        this.selectedRollUp.diff,
        true
      );
    }
  },
  beforeDestroy() {
    this.removeLocalStorageKey(this.localStorageKey);
  }
};
</script>
<style lang="css" scoped>
.optionsDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  padding: 10px 16px;
}

.valueDropdown {
  border: none;
}

.divider-footer {
  width: 2px;
  height: 12px;
  background: #e9eaeb;
  margin: auto 16px;
}

.metric-card-hover-trigger {
  margin-top: 16px;
}

.u-viewport-height {
  height: 100vh;
}
</style>
