<template>
  <div
    class="u-font-size-6 u-display-flex u-flex-direction-row u-border-radius-xl u-cursor-pointer"
    :style="{ background: background }"
  >
    <div
      class="u-border-radius-xl u-spacing-ph-l u-spacing-pv-xs u-text-align-center"
      :class="tab1Clicked ? 'isHighlighted tabBoxShadow u-font-weight-600' : ''"
      @click="tab1Clicked = true"
    >
      {{ tab1 }}
    </div>
    <div
      class="u-border-radius-xl u-spacing-ph-l u-spacing-pv-xs u-text-align-center"
      :class="
        !tab1Clicked ? 'isHighlighted tabBoxShadow u-font-weight-600' : ''
      "
      @click="tab1Clicked = false"
    >
      {{ tab2 }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab1: {
      type: String,
      default: 'tab1'
    },
    tab2: {
      type: String,
      default: 'tab2'
    },
    background: {
      type: String,
      default: '#f5f7fa'
    },
    initialState: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tab1Clicked: this.initialState
    };
  },
  watch: {
    tab1Clicked(newVal) {
      const tabClicked = newVal ? 'tab1' : 'tab2';
      this.$emit('tabClicked', tabClicked);
    }
  },
  created() {
    this.tab1Clicked = this.initialState;
  }
};
</script>

<style lang="css" scoped>
.tabBoxShadow {
  box-shadow: 0 2px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
