<template>
  <div class="u-display-flex u-flex-direction-row u-flex-align-items-center">
    <div
      class="u-display-flex"
      @click="toggleBulkSearchHandler()"
    >
      <div
        class="u-spacing-pl-s u-spacing-pr-xs u-border-left u-border-width-s u-border-color-grey-xx-light u-font-size-7"
      >
        <rb-icon
          icon="layer"
          class="rb-icon--small u-cursor-pointer"
          :class="[
            isBulkSearchActive ? 'u-color-blue-base' : 'u-color-grey-lighter'
          ]"
        />
      </div>
      <div class="u-display-flex u-flex-align-items-center u-cursor-pointer">
        <div
          v-if="isBulkSearchActive"
          data-cy="exitBulkSearchButton"
          class="u-font-size-7 u-color-red-base u-spacing-pr-xs"
        >
          Exit
        </div>
        <div
          data-cy="bulkSearchButton"
          class="u-font-size-7 u-color-grey-lighter u-white-space-no-wrap"
        >
          Bulk Search
        </div>
      </div>
    </div>
    <div
      class="u-color-grey-lighter u-font-weight-600 u-cursor-pointer bulk-search-info u-spacing-pl-xs"
      @click="openBulkSearchHelperPopup"
    >
      <div
        v-tippy="tippyConfig"
        title="Know more about bulk search"
        class="u-display-flex u-flex-align-items-center"
      >
        <rb-icon
          icon="help-fill"
          class="rb-icon--small u-color-blue-base"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    isBulkSearchActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippyConfig: {
        placement: 'bottom',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      }
    };
  },
  methods: {
    toggleBulkSearchHandler() {
      this.$emit('toggleBulkSearch', !this.isBulkSearchActive);
    },
    openBulkSearchHelperPopup() {
      eventBus.$emit('openBulkSearchHelperPopup');
    }
  }
};
</script>
