<template>
  <div class="page-header">
    <div class="u-width-100">
      <section class="u-display-block page-breadcrumb">
        <div
          class="u-spacing-pt-m u-spacing-pb-m u-spacing-mh-l u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
        >
          <section
            class="u-font-size-5 u-display-flex u-flex-justify-content-center u-flex-align-items-center"
          >
            <div
              v-for="(val, index) in urlEntities"
              :key="index"
              class="u-display-flex u-text-case-title u-flex-align-items-center"
              @click.stop="handleNavigation(val)"
            >
              <rb-icon
                v-if="index === 0"
                class="rb-icon--medium u-color-grey-lighter breadcrumb--text u-cursor-pointer"
                :icon="'home'"
              />
              <span
                v-if="index !== 0"
                :class="{
                  'u-text-decoration-underline u-cursor-pointer u-font-weight-600 breadcrumb--text':
                    val.url || val.name
                }"
                class="lastIndex u-font-size-6 u-color-grey-lighter"
                >{{ val.display }}</span
              >
              <rb-icon
                class="rb-icon--small u-spacing-mh-xs u-color-grey-xx-light"
                :icon="'arrow-right'"
              />
            </div>
          </section>
          <div
            class="u-display-flex u-flex-align-items-center u-color-grey-base"
          >
            <SystemTransparency />
            <CurrencyDropdown v-if="isEURegion" />
            <div class="u-spacing-pl-l">
              <downloadCenterIcon
                :enable-animation="enableAnimation"
                :show-new-downloads="showNewDownloads"
              />
            </div>

            <div
              v-tippy
              title="Release notes"
              class="z-index-1-icons u-spacing-ph-l"
              @click="openHelp"
            >
              <rb-icon
                class="rb-icon--x-medium u-cursor-pointer u-color-grey-lighter"
                :icon="'rocket'"
              />
            </div>

            <div
              class="header-button u-color-grey-white u-spacing-pr-l z-index-1-icons"
            >
              <span
                v-if="!waitingForScreenShot"
                @click.stop="startFeedback()"
              >
                <rb-icon
                  v-tippy
                  title="Feedback"
                  :icon="'feedback'"
                  class="u-color-grey-lighter rb-icon--x-medium"
                />
              </span>
              <span v-if="waitingForScreenShot">
                <rb-icon
                  :icon="'spinner'"
                  class="u-color-grey-lighter rb-icon--x-medium rotate"
                />
              </span>
              <!-- <rb-button
                :size="'s'"
                :loading="waitingForScreenShot"
                :click-fn="startFeedback"
                class="u-color-grey-lighter"
                text=""
                :type="'fill'"
                :icon-left="'feedback'"
              /> -->
            </div>

            <rb-dropdown
              position="is-bottom-left"
              class=""
            >
              <div
                slot="trigger"
                class="logout__btn u-display-flex u-flex-align-items-center u-flex-justify-content-space-around"
              >
                <span
                  class="u-flex-1 logo-client-name u-display-flex u-flex-justify-content-space-around u-flex-align-items-center u-font-weight-bold"
                  >{{ clientNameLogo }}</span
                >
              </div>
              <rb-dropdown-item
                v-if="showInternalTools"
                @click="openToolBox"
              >
                <div class="u-spacing-ph-m u-spacing-pv-s">
                  <span>Internal Tools</span>
                </div>
              </rb-dropdown-item>
              <rb-dropdown-item @click="logout">
                <div class="u-spacing-ph-m u-spacing-pv-s">
                  <span>Logout</span>
                </div>
              </rb-dropdown-item>
            </rb-dropdown>
          </div>
        </div>
      </section>
      <feedback-widget />
      <download-center
        :listener="'downloadCenterOpen'"
        @downloadCenterOpened="downloadCenterOpened"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import utils from '@/utils/helpers/';
import feedbackWidget from '@/components/widgets/feedback.vue';
import downloadCenter from '@/components/widgets/downloadCenter/downloadCenter.vue';
import downloadCenterIcon from '@/components/widgets/downloadCenter/downloadCenterIcon.vue';
import SystemTransparency from '@/components/widgets/system-transparency/index.vue';
import { constants } from '@/components/pages/insights/amazon/market-share/utils';
import CurrencyDropdown from '@/components/widgets/currencyDropdown.vue';
import CONSTANTS from '@/utils/constants';

export default {
  author: 'Rajan V',
  name: 'PageHeader',
  components: {
    feedbackWidget,
    downloadCenter,
    downloadCenterIcon,
    CurrencyDropdown,
    SystemTransparency
  },
  data() {
    return {
      urlEntities: [],
      previousEntities: [],
      isInternalUser: false,
      enableAnimation: false,
      showNewDownloads: false
    };
  },
  computed: {
    clientName() {
      const configs = this.$store.getters.getConfigs;
      const name =
        configs &&
        configs.client &&
        configs.client.vendor &&
        configs.client.vendor.name;
      return name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
    },
    clientNameLogo() {
      const nameArr = this.clientName.split(' ');
      const newArr = [];
      nameArr.forEach((item) => {
        newArr.push(item.charAt(0));
      });
      return newArr.join('');
    },
    recommendationLabel() {
      const recommendationTypes = this.$store.getters.getRecommendationTypes;
      const recommendationType = this.$route.query.type;
      const detailObject =
        recommendationTypes.filter(
          (typeObject) => typeObject.recommendationType === recommendationType
        )[0] || {};
      return detailObject.recommendationLabel || '';
    },
    waitingForScreenShot() {
      return this.$store.getters.getWaitingForScreenShot;
    },
    activeRetailer() {
      return this.$store.getters.getRetailer;
    }
  },
  watch: {
    $route(to, from) {
      this.getURLComponets(to, from);
    },
    recommendationLabel(to, from) {
      this.getURLComponets();
    }
  },
  created() {
    this.isEURegion = this.$store.getters.getLocation === CONSTANTS.regions.EU;
    this.getURLComponets();
    this.isInternalUser = utils.internalUserCheck(window.user);
    this.showInternalTools =
      Vue.options.filters.config_check('feature.pages.internal_tools.enable') &&
      this.isInternalUser;
    eventBus.$on('triggerDownloadingAnimation', () => {
      this.triggerDownloadAnimation();
      this.showDownloadCenterSnackbar();
    });
    eventBus.$on('openFeedbackForm', () => {
      this.startFeedback();
    });
  },
  destroyed() {
    eventBus.$off('triggerDownloadingAnimation');
    eventBus.$off('openFeedbackForm');
  },
  methods: {
    openHelp() {
      eventBus.$emit('rightPanelOpen');
    },
    openToolBox() {
      this.$router.push('/internalToolBox');
    },
    logout() {
      this.$store.dispatch('logout');
    },
    startFeedback() {
      this.$store.dispatch('feedbackScreenshot');
    },
    getURLComponets(to, from) {
      this.previousEntities = this.urlEntities;
      this.urlEntities = [];
      // var recomUrl = '/recommendations?name=asin&type=asin&bc_recommendation_type=asin';
      let url = this.$route.fullPath;
      if (url?.charAt(url?.length - 1) === '/') {
        url = url.slice(0, -1);
      }
      url = url.split('/');
      url.splice(0, 1);
      url.splice(0, 3);
      if (Object.keys(this.$route.params).length > 0) {
        url.pop();
      }

      if (
        this.$route.fullPath?.indexOf('dp') !== -1 &&
        Object.keys(this.$route.params).length > 0
      ) {
        url.pop();
      }

      if (this.$route.path.indexOf('recommendations') > -1) {
        const path = this.$route.path.replace(
          this.$store.getters.getRootPath,
          ''
        );
        console.log(this.$route.path, this.$route.fullPath, path);
        if (path === 'recommendations') {
          url = [];
        }
        if (path === 'recommendations/all') {
          url = [];
        }
      }

      if (this.$route.path.indexOf('account_manager') > -1) {
        this.urlEntities.push({
          display: 'Account Manager',
          url: ''
        });
      }

      for (let i = 0; i < url.length; i++) {
        let display = decodeURIComponent(url[i]).replace(/[-]/gi, ' ');
        const queryIndex = url[i].indexOf('?');

        if (queryIndex > -1) {
          url[i] = url[i].split('?')[0];
          display = display.split('?')[0];
        }
        display = display.replaceAll('_', ' ');
        if (display === 'market share lite') {
          display = 'market share';
        }
        display = display.replace('dsp', 'DSP');
        this.urlEntities.push({
          display: display,
          url: ''
        });

        if (constants.marketSharePages?.includes(display)) {
          break;
        }
      }

      this.urlEntities.unshift({
        display: 'Home',
        name: 'Home'
      });
      if (this.$route.path.includes('market_share')) {
        const marketShareUrlEntities = this.getMarketShareUrlComponents(
          to,
          from
        );
        this.urlEntities.push(...marketShareUrlEntities);
        return;
      }
      if (
        Object.keys(this.$route.params).length === 0 &&
        this.$route.path.indexOf('recommendations') === -1
      ) {
        this.urlEntities.pop();
      }

      this.urlEntities.map((item) => {
        if (item.display === 'recommendations') {
          item.url = `${this.$store.getters.getRootPath}recommendations`;
        }
        if (item.display === 'reports') {
          item.url = `${this.$store.getters.getRootPath}reports`;
        }
        if (item.display?.toLowerCase() === 'skus') {
          item.display = 'SKUs';
        }
      });
      // todo -> pull from page configs
      const entity_details = [
        'sp',
        'dsp',
        'sb',
        'sd',
        'audience adgroup',
        'keyword adgroup',
        'product adgroup',
        'auto adgroup',
        'sba',
        'manual adgroup',
        'sp auto',
        'sp manual',
        'sb manual',
        'sba manual'
      ];
      const urlEntitiesLength = this.urlEntities.length;
      if (
        urlEntitiesLength - 1 >= 0 &&
        entity_details.includes(this.urlEntities[urlEntitiesLength - 1].display)
      ) {
        if (
          this.urlEntities[urlEntitiesLength - 1]?.display?.includes('adgroup')
        ) {
          this.urlEntities[urlEntitiesLength - 1].display = 'ad group';
          this.urlEntities[urlEntitiesLength - 2].display =
            this.$route?.query?.parent_entity_name ||
            this.urlEntities[urlEntitiesLength - 2].display;
          this.urlEntities.splice(urlEntitiesLength - 3, 1);
        } else {
          this.urlEntities.splice(3, 4);
        }
      }
      if (this.$route.name === 'category-page') {
        this.urlEntities[this.urlEntities.length - 1] = {
          display: 'Overview',
          name: 'marketShareOverview'
        };
      }
      this.$store.dispatch('setUrlComponents', this.urlEntities);
    },
    getMarketShareUrlComponents(to, from) {
      if ((!to && !from) || this.$route.path.includes('overview')) {
        return [];
      }
      let toPath = to?.path;
      if (to?.params?.id) {
        toPath = toPath.split('/' + Number.parseInt(to.params.id))[0];
      }
      let fromPath = from.path;
      if (from?.params?.id) {
        fromPath = fromPath.split('/' + Number.parseInt(from.params.id))[0];
      }
      let marketShareEntities = [];
      if (toPath === fromPath) {
        marketShareEntities = this.previousEntities.splice(3);
        return marketShareEntities;
      }
      if (to.name === 'marketshareSkus') {
        marketShareEntities.push({
          display: 'Overview',
          name: 'marketShareOverview'
        });
        if (from.name === 'category-page') {
          marketShareEntities.push({
            display: 'Category',
            name: 'category-page'
          });
        }
        return marketShareEntities;
      }
      if (from.name === 'marketshareSkus') {
        marketShareEntities = this.previousEntities.splice(3);
        marketShareEntities.pop();
        return marketShareEntities;
      }
      const fromPathArray = fromPath.split('/');
      marketShareEntities.push({
        display: fromPathArray[fromPathArray.length - 1],
        name: from.name
      });
      return marketShareEntities;
    },
    handleNavigation(route) {
      if (route.name) {
        this.$router.push({ name: route.name });
      } else if (route.url) {
        this.$router.push({ path: route.url });
      }
    },
    isRetailerAmazon() {
      return this.activeRetailer === 'amazon';
    },
    openDownloadCenter() {
      eventBus.$emit('downloadCenterOpen', 'open');
    },
    triggerDownloadAnimation() {
      this.enableAnimation = true;
      setTimeout(() => {
        this.enableAnimation = false;
        this.showNewDownloads = true;
      }, 5000);
    },
    downloadCenterOpened() {
      this.showNewDownloads = false;
    },
    showDownloadCenterSnackbar() {
      this.$snackbar.open({
        message: `<span class="u-display-flex u-align-items-center u-spacing-pr-xl u-bg-color-green-base"><span class="rb-icon icon-info-circle-fill rb-icon--medium u-color-grey-white"></span> <span class="u-spacing-pt-xxs u-spacing-pl-s u-color-grey-white">Generating report! Sit back and Relax while we prepare the report. It may take 5 - 10 minutes depending on the file size.</span></span>`,
        duration: 5000,
        customStyles: 'background-color: #3ea95e !important;',
        actionText: ''
      });
    }
  }
};
</script>

<style lang="css" scoped>
.page-header {
  z-index: 101;
}

.page-breadcrumb {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}
.logo-client-name {
  color: #fff;
  cursor: pointer;
  background-color: #007cf6;
  font-size: 11px;
  font-family: 'ProximaNova';
  border-radius: 1000px;
  width: 20px;
  height: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.breadcrumb--text:hover {
  color: #4b5158;
}

.z-index-1-icons {
  background-color: #fff;
  z-index: 2;
}
</style>
