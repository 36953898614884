<template>
  <span class="u-width-100 u-height-100 u-display-flex">
    <!-- <div
      v-for="(item,key) in splitMeta"
      :key="key"
      :class="(key % 2 !== 1 ? containerClass : '')"
      class="u-width-50 u-overflow-auto"
    >
      <component
        :is="item.mappedComponent"
        v-bind="keyToPropMap(getMatchingData(item.value,matchKey)[0],item.propColumnKeyMap)"
      />
    </div> -->

    <span
      class="u-display-flex u-flex-align-items-flex-start split-container u-width-100"
    >
      <component
        :is="leftSectionMeta.ui_component"
        class="u-width-50 header-left-component u-spacing-p-0"
        v-bind="keyToPropMap(leftSectionMeta, leftData)"
      />
      <component
        :is="rightSectionMeta.ui_component"
        :class="containerClass"
        class="header-right-component u-spacing-p-0 u-width-50 u-border-color-grey-xxx-light u-border-left u-border-width-s"
        v-bind="keyToPropMap(rightSectionMeta, rightData)"
      />
    </span>
  </span>
</template>

<script>
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    leftSectionMeta: {
      type: Object,
      default() {
        return {
          ui_component: 'statusTextWithIconAndBg',
          props: {
            keyTransformMap: {
              DELIVERY: { icon: 'shipment_tracker', text: 'Delivery' },
              PICKUP: { icon: 'bag', text: 'Pickup' }
            },
            iconClasses: 'u-color-grey-lighter',
            textClasses: 'u-font-size-5'
          },
          propDataMap: {
            text: 'availability'
          }
        };
      }
    },
    leftData: {
      type: [Object, Array, String],
      default() {
        return {};
      }
    },
    rightData: {
      type: [Object, Array, String],
      default() {
        return {};
      }
    },
    rightSectionMeta: {
      type: Object,
      default() {
        return {
          ui_component: 'statusTextWithIconAndBg',
          props: {
            keyTransformMap: {
              DELIVERY: { icon: 'shipment_tracker', text: 'Delivery' },
              PICKUP: { icon: 'bag', text: 'Pickup' }
            },
            iconClasses: 'u-color-grey-lighter',
            textClasses: 'u-font-size-5'
          },
          propDataMap: {
            text: 'availability'
          }
        };
      }
    }
  },
  methods: {
    getMatchingData(value, matchKey) {
      return this.dataMap[value] || {};
    },
    keyToPropMap(componentMeta, data) {
      const props = dashUtils.mapPropToData(
        componentMeta.propDataMap,
        data,
        componentMeta.props
      );
      return props;
    }
  }
};
</script>

<style></style>
