var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.isDisabled ? { placement: 'bottom', arrow: true } : null),expression:"isDisabled ? { placement: 'bottom', arrow: true } : null"}],key:_vm.chipSelectKey,staticClass:"chipSelectWrapper u-font-size-7",attrs:{"title":_vm.isDisabled ? _vm.disableTextForTooltip : null}},[_c('rb-select',{staticClass:"u-display-flex u-flex-align-items-center",class:{ 'u-pointer-events-none disabled': _vm.isDisabled },attrs:{"send-details":true,"options":_vm.options,"on-close":_vm.onSelectEvent,"disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_vm._v(" "+_vm._s(option.label)+" ")])]}}])},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[(_vm.showSettingsIcon)?_c('rb-icon',{staticClass:"rb-icon--small u-color-grey-lighter u-spacing-mr-xs",attrs:{"icon":"settings"}}):_vm._e(),_c('span',{staticClass:"u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-base"},[_vm._v(" "+_vm._s(_vm.displayValue)+" ")]),_c('rb-icon',{staticClass:"u-flex-0 rb-icon--medium u-color-grey-lighter",attrs:{"icon":"caret-down"}}),(_vm.helpTooltip)?[_c('rb-tooltip-header',{attrs:{"id":"tooltipTemplate","title":_vm.displayValue,"message":_vm.helpTooltip}}),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            html: '#' + 'tooltipTemplate',
            theme: 'rb-tooltip-header',
            placement: 'bottom',
            interactive: true,
            reactive: true,
            arrow: false
          }),expression:"{\n            html: '#' + 'tooltipTemplate',\n            theme: 'rb-tooltip-header',\n            placement: 'bottom',\n            interactive: true,\n            reactive: true,\n            arrow: false\n          }"}],staticClass:"u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger4",attrs:{"title":_vm.helpTooltip}},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--medium u-color-grey-lighter",attrs:{"icon":"help-fill"}})],1)]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }