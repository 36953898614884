<template>
  <div
    class="u-position-relative u-display-flex u-flex-1 u-flex-direction-column u-width-100 u-flex-justify-content-center u-flex-align-items-center"
  >
    <loader
      v-if="loading"
      class="entity_loader u-color-blue-base fill--parent"
      :color="'#3684bb'"
      :loading="loading"
    />
    <img
      class="banner-img-class"
      :alt="description"
      :src="imagePath"
    />
    <span class="u-display-inline-flex">
      <rb-icon
        v-if="descriptionIcon"
        :icon="descriptionIcon"
        class="u-color-green-base u-spacing-ph-s"
      />
      <span
        class="u-spacing-pv-l u-font-weight-600 u-font-size-3"
        v-html="description"
      />
    </span>

    <span
      v-if="subText"
      class="u-display-inline-flex"
    >
      <span
        class="u-font-weight-600 u-font-size-5"
        v-html="subText"
      />
    </span>
    <slot name="primaryButton" />
    <rb-button
      v-if="buttonText"
      type="filled"
      :click-fn="launchTriggered"
      :text="buttonText"
    />
  </div>
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      default: '/images/no_budget.png'
    },
    description: {
      type: String,
      default: 'Looks like you haven’t created your Standard Catalog yet!'
    },
    descriptionIcon: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    launchTriggered(event) {
      this.$emit('launchTriggered', event);
    }
  }
};
</script>

<style>
.banner-img-class {
  max-width: 20%;
}
</style>
