<template>
  <div class="u-display-flex u-position-relative">
    <icon-text-actions
      ref="trigger"
      class="u-spacing-ph-m"
      :apply-padding="false"
      mode="default"
      :action="{ title: 'Save', icon: 'save' }"
      @clicked="saveFilters"
    />
    <div
      v-show="isActive"
      ref="dropdownMenu"
      :style="getTopValue"
      class="filter-dropdown-menu"
    >
      <div class="filter--header">
        <span class="u-display-flex u-flex-align-items-center">
          <span class="u-color-grey-white u-font-size-5">Save filter</span>
        </span>
        <span @click="toggle">
          <rb-icon
            :icon="'cross'"
            class="rb-icon--small u-color-grey-white u-cursor-pointer"
          />
        </span>
      </div>
      <div
        class="u-display-flex u-overflow-hidden u-bg-color-grey-white u-flex-direction-column u-position-relative"
      >
        <loader
          class="fill--parent"
          :loading="loading"
          :color="'#007cf6'"
        />
        <div class="u-spacing-m-m u-display-flex u-flex-direction-column">
          <span class="u-font-weight-600 u-font-size-6">Filter name</span>
          <rb-input
            ref="input"
            v-model="filterName"
            :placeholder="''"
            class="u-spacing-pt-s"
            :rounded="false"
            @input="onFilterNameEntry"
          />
          <span
            v-if="disableSave"
            class="u-color-red-base u-font-size-6 u-spacing-pt-s"
            >Filter already exists.</span
          >
          <div
            class="u-display-flex u-flex-justify-content-flex-end u-spacing-pt-xl"
            @click="handleSave"
          >
            <rb-button
              :text="'Save'"
              :type="'filled'"
              :size="'s'"
              :disabled="filterName.length === 0 || disableSave"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import filterUtils from '@/components/widgets/filter/filterUtils';
import loader from '@/components/basic/loader';
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    iconTextActions,
    loader
  },
  props: {
    widget: {
      type: String
    },
    page: {
      type: String
    },
    config: {
      type: Object,
      default: function () {
        return {};
      }
    },
    primaryData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    secondaryData: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      filterName: '',
      isActive: false,
      loading: false,
      disableSave: false,
      filtersToCheck: {}
    };
  },
  computed: {
    getTopValue() {
      return {
        top: '0px',
        right: '0px',
        left: 'unset'
      };
    }
  },
  created() {
    this.filtersToCheck = filterUtils.getHash(this.widget);

    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside);
    }

    eventBus.$on('savedFilterAdded', () => {
      this.filtersToCheck = filterUtils.getHash(this.widget);
    });
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  methods: {
    onFilterNameEntry() {
      var checkNames = this.filtersToCheck.names || [];
      // Name can't match it with Primary and secondary filters.
      this.primaryData.forEach((data) => {
        data.dimensionName && checkNames.push(data.dimensionName);
      });
      Object.keys(this.secondaryData).forEach((data) => {
        this.secondaryData[data] &&
          checkNames.push(this.secondaryData[data].label);
      });
      this.disableSave = false;
      for (var i = 0; i < checkNames.length; i++) {
        if (
          (checkNames[i] || '').toLowerCase() === this.filterName.toLowerCase()
        ) {
          this.disableSave = true;
          break;
        }
      }
    },
    handleSave() {
      var storedFilters = JSON.parse(localStorage.getItem(this.widget)) || {};
      delete storedFilters.date_range;
      var order =
        JSON.parse(localStorage.getItem(this.widget + '-order')) || [];
      var postObject = {
        filterInfo: {
          name: this.filterName,
          widget: this.widget,
          page: this.page,
          fields: {
            filter: storedFilters,
            order: order
          }
        }
      };
      this.loading = true;
      return HttpLayer.post({
        cube: 'SAVE_FILTER',
        APIData: postObject
      }).then((response) => {
        this.loading = false;
        if (response.success) {
          var responseObject = {
            filterInfo: response.fullResponse.filter
          };

          filterUtils.addLocalFilter(this.widget, {
            local: true,
            addtionalMetadata: responseObject,
            dimensionColumn: responseObject.filterInfo.name,
            dimensionLabel: responseObject.filterInfo.name,
            dimensionName: responseObject.filterInfo.name,
            dimensionType: 'MY_SAVED_FILTERS'
          });
          eventBus.$emit('savedFilterAdded', responseObject);
          this.toggle();
          this.$snackbar.open({
            message:
              'Filter saved. "' +
              responseObject.filterInfo.name +
              '" can be applied from Add Filter.',
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: '              '
          });
        }
      });
    },
    open() {
      Vue.nextTick(
        function () {
          this.filterName = '';
          this.toggle();
        }.bind(this)
      );
    },
    toggle() {
      this.isActive = !this.isActive;
      if (!this.isActive) {
        this.hoveredItem = null;
        this.filterName = '';
      } else {
        this.$refs.input.focus();
      }
    },
    saveFilters() {
      this.toggle();
    },
    isInWhiteList(el) {
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      if (this.$refs.dropdownMenu !== undefined) {
        const children = this.$refs.dropdownMenu.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      if (this.$refs.trigger !== undefined) {
        const children = this.$refs.trigger.$el.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      return false;
    },
    clickedOutside(event) {
      if (this.inline) return;
      if (!this.isInWhiteList(event.target)) {
        this.isActive = false;
        this.hoveredItem = null;
      }
    }
  }
};
</script>
<style lang="css" scoped>
.filter-dropdown-menu {
  width: 240px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  position: absolute;
  overflow: auto;
}

.filter-dropdown-content {
  max-height: 332px;
  min-height: 136px;
  padding: 0px 0px 16px;
}

.filter-container {
  overflow: auto;
  width: 100%;
}

.rb-filter {
  position: relative;
  display: inline-block;
  min-height: 25px;
}

.filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
}

.filter-list__item.active {
  background: #007cf6;
}
.filter-list__item.active p,
.filter-list__item.active span {
  color: white;
}
.filter-list__item.disabled {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
