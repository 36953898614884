<template>
  <div
    :key="rollUpKey"
    v-tippy="rollupDisabled ? { placement: 'bottom', arrow: true } : null"
    class="rollUpWrapper"
    :style="{ height: varHeight + 'px' }"
    :title="
      rollupDisabled
        ? 'Roll up by is limited to days due to the current selection in date range'
        : null
    "
  >
    <rb-select
      :class="{ 'u-pointer-events-none disabled': rollupDisabled || disabled }"
      class="u-display-flex u-flex-align-items-center u-spacing-mr-s valueDropdown rollUpWrapperClass"
      :send-details="true"
      :options="computedRollUps"
      :on-close="onRollupChangeSelected"
      :disabled="rollupDisabled || disabled"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small u-spacing-mh-xs u-color-grey-lighter"
          icon="calendar"
        />
        <span
          class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
          >{{ label }}</span
        >
        <div
          class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-base"
        >
          {{ selectedRollUp.name }}
        </div>
        <rb-icon
          class="u-flex-0 rb-icon--medium u-color-grey-lighter"
          icon="caret-down"
        />
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div class="u-display-flex u-flex-align-items-center">
          {{ option.name }}
        </div>
      </template>
    </rb-select>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';

export default {
  name: 'RollUpBy',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: 'DAY'
    },
    label: {
      type: String,
      default: ''
    },
    dateObj: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            name: 'Days',
            key: 'DAY'
          },
          {
            name: 'Weeks',
            key: 'WEEK'
          },
          {
            name: 'Months',
            key: 'MONTH'
          }
        ];
      }
    },
    varHeight: {
      type: Number,
      default: 26
    }
  },
  data() {
    return {
      selectedRollUp: this.options[0],
      rollUpKey: 0
    };
  },
  computed: {
    rollupDisabled() {
      return this.computedRollUps.length <= 1;
    },
    computedRollUps() {
      const fromDate = moment(new Date(this.dateObj[':from']), 'DD.MM.YYYY');
      const toDate = moment(new Date(this.dateObj[':to']), 'DD.MM.YYYY');

      const staticRollUps = this.options;

      let rollUps = [...staticRollUps];

      const diff = toDate.diff(fromDate, 'days');

      if (fromDate && toDate) {
        if (diff < 7) {
          // Remove the rollups by week
          rollUps = rollUps.filter(
            (item) => item.key !== 'MONTH' && item.key !== 'WEEK'
          );
        } else if (diff < 30) {
          // Remove the rollups by month
          rollUps = rollUps.filter((item) => item.key !== 'MONTH');
        }
      }
      return rollUps;
    }
  },
  watch: {
    computedRollUps(newVal, oldValue) {
      // Check if the old existingRollUp is present in the new options or not
      const existingRollUp = newVal.find(
        (item) => item.key === this.selectedRollUp.key
      );
      if (existingRollUp) {
        this.selectedRollUp = existingRollUp;
      } else {
        this.selectedRollUp = this.options[0];
      }
      if (newVal?.length !== oldValue?.length) {
        this.onRollupChangeSelected(this, [this.selectedRollUp]);
      }
    },
    rollupDisabled() {
      this.rollUpKey++;
    }
  },
  created() {
    this.selectedRollUp = this.options.find((el) => el.key === this.value);
    if (!this.selectedRollUp) {
      this.onRollupChangeSelected(this, this.options);
    }
  },
  methods: {
    onRollupChangeSelected(context, rollUp) {
      if (rollUp && rollUp[0]) {
        this.selectedRollUp = rollUp[0];
        this.$emit('onRollUpChange', rollUp[0]);
      }
    }
  }
};
</script>

<style lang="css">
.rollUpWrapper.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.rollUpWrapper .rollUpWrapperClass {
  width: 100% !important;
  padding: 6px 8px;
  border-radius: 13.5px;
}
.rollUpWrapper .rollUpWrapperClass:hover {
  cursor: pointer;
}
.rollUpWrapper .valueDropdown {
  border-radius: 13.5px;
  width: 145px;
  border: solid 1px #e9eaeb;
}
.rollUpWrapper .valueDropdown:hover,
.rollUpWrapper .valueDropdown:focus {
  border: 1px solid #caccce;
}
</style>
