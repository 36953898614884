<template>
  <dashboard-wrapper
    :name="tabName"
    :dashboard-config="dashboardConfig"
    :advanced-reporting-available="advancedReportingAvailable"
  />
</template>

<script>
import DashboardWrapper from '@/pages/dashboard_service_campaign_management/dashboardWrapper.vue';
import commonConfigForAllRetailers from '@/pages/dashboard_service_campaign_management/commonConfig.js';
import { isAdvancedReportingAvailable } from '@/pages/dashboard_service_campaign_management/utils';
export default {
  name: 'DashboardServiceCMRouteWrapper',
  components: {
    DashboardWrapper
  },
  props: {
    configKey: {
      type: String,
      default: 'albertsons_citrus'
    },
    tabName: {
      type: String,
      default: 'Orders'
    }
  },
  computed: {
    dashboardConfig() {
      return commonConfigForAllRetailers[this.configKey] || {};
    },
    advancedReportingAvailable() {
      return isAdvancedReportingAvailable(this?.$store?.getters?.getRetailer);
    }
  }
};
</script>
