<template>
  <!-- Desc: config driven tab component for entity details -->
  <section class="card-min-height campaign-tab-container">
    <ul
      class="u-display-flex u-bg-color-grey-white u-bg-color-grey-white u-spacing-ph-l u-position-relative u-spacing-pt-m u-z-index-1 u-header-box-shadow"
      :style="tabGap"
    >
      <li
        v-for="(tab, index) in tabData"
        :key="index"
        class="tab"
        :class="{
          underline: currentTab && tab.name === currentTab.name && underLine
        }"
      >
        <span
          :data-cy="`tabName-${tab.name}`"
          class="u-font-size-5 u-cursor-pointer"
          :class="{
            'u-color-blue-base u-font-weight-bold':
              currentTab && tab.name === currentTab.name
          }"
          @click="handleTabsClick(tab)"
        >
          {{ tab.name }}
        </span>
      </li>
    </ul>
    <div
      v-if="toggleTabConfigs && toggleTabConfigs.switch"
      class="u-spacing-pl-l u-spacing-pt-l u-bg-color-white-base u-display-flex u-flex-justify-content-flex-start"
    >
      <splitTabs
        :active-tab="activeToggleTab"
        :is-tabs-list="true"
        :tabs-list="toggleTabConfigs.tabsList"
        @tabClicked="onToggleTabClick"
      />
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <component
        :is="fetchComponent(currentTab)"
        :key="key"
        :parent-entity-id="parentEntityId"
        :entity-config="entityConfig"
        :tab-metadata="currentTab"
        :date-filter="dateFilter"
        :entity-id="entityId"
        :parent-entity-data="campaignData"
      />
    </transition>
  </section>
</template>

<script>
import Vue from 'vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
const productAndCategoryComponent = () =>
  import('@/components/basic/productAndCategoryComponent.vue');
const splitTabs = () => import('@/components/widgets/splitTabs.vue');

export default {
  name: 'TabsComponent',
  components: {
    splitTabs
  },
  props: {
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    },
    dateFilter: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    tabsMetadata: {
      default: () => {},
      type: Object
    },
    defaultTabIndex: {
      default: 0,
      type: Number
    },
    tabsList: {
      type: Array,
      default() {
        return [];
      }
    },
    underLine: {
      type: Boolean,
      default: true
    },
    tabStyles: {
      type: Object,
      default() {
        return {
          gap: '2.6rem'
        };
      }
    }
  },
  data() {
    return {
      currentTab: null,
      toggleTabConfigs: null, //  toggle used in sp auto adgroup negative targeting
      activeToggleTab: null,
      key: 0
    };
  },
  computed: {
    tabData() {
      return this.tabsMetadata?.data?.tabs?.page_names || [];
    },
    tabGap() {
      return {
        '--tab-gap': this.tabStyles.gap
      };
    },
    campaignData() {
      return this.$store.getters[
        this?.entityConfig?.metadata[0]?.campaignGetter
      ];
    }
  },
  watch: {
    tabData: {
      handler(newVal) {
        this.currentTab = newVal[0];
      },
      immediate: true
    },
    currentTab: {
      handler(newVal) {
        this.checkForToggleTab();
      },
      immediate: true
    }
  },
  created() {
    this.$emit('currentTab', this.currentTab);
    Vue.component('productAndCategoryComponent', productAndCategoryComponent);
    Vue.component('customActionPanel', customActionPanel);
    this.loadParentEntityData();
  },
  methods: {
    fetchComponent(currentTab) {
      if (this.toggleTabConfigs) {
        if (
          !currentTab?.component ||
          !this?.toggleTabConfigs?.switch[this.activeToggleTab]?.component
        ) {
          return null;
        }
        return this?.toggleTabConfigs?.switch[this.activeToggleTab]?.component;
      } else {
        if (
          !currentTab?.component ||
          !this?.entityConfig?.tabs[currentTab?.component]
        ) {
          return null;
        }
        return this?.entityConfig?.tabs[currentTab?.component]?.component;
      }
    },
    handleTabsClick(selectedTab) {
      this.loadParentEntityData();
      this.key++;
      this.currentTab = selectedTab;
      this.$emit('currentTab', this.currentTab);
      this.checkForToggleTab();
    },
    loadParentEntityData() {
      // to fetch campaign details as props at adgroup level component
      if (
        this.parentEntityId &&
        this?.entityConfig?.metadata[0]?.campaignFetchAction
      ) {
        this.$store.dispatch(
          this?.entityConfig?.metadata[0]?.campaignFetchAction,
          {
            entityId: this.parentEntityId,
            entityType: this.entityConfig?.entityType
          }
        );
      }
    },
    checkForToggleTab() {
      this.toggleTabConfigs = null;
      const tabConfig = this.entityConfig?.tabs[this.currentTab?.component];
      if (tabConfig?.showToggle) {
        this.toggleTabConfigs = tabConfig.toggleTabConfigs;
        this.activeToggleTab = this.toggleTabConfigs.tabsList[0];
      }
    },
    onToggleTabClick(strTab) {
      this.activeToggleTab = strTab;
      this.currentTab.page = this.toggleTabConfigs.switch[strTab].page;
      this.currentTab.component =
        this.toggleTabConfigs.switch[strTab].componentName;
      this.currentTab.widgets[0] = this.toggleTabConfigs.switch[strTab].widget;
      this.fetchComponent(this.currentTab);
      this.key++;
    }
  }
};
</script>

<style lang="css" scoped>
.card-min-height {
  min-height: 70vh;
}
ul {
  gap: var(--tab-gap);
  flex-wrap: wrap;
  row-gap: 0;
}
.tab {
  padding-inline: 1.5rem;
  padding-block: 1.2rem;
}

.underline {
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid #007cf6;
}
.u-header-box-shadow {
  box-shadow: 0 1px 4px 0 rgb(43 51 59 / 15%);
}
</style>
