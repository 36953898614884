var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100 u-display-flex u-bg-color-grey-white u-font-size-3 u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l u-header-box-shadow"},[(_vm.entityConfig && _vm.entityId)?_c('div',{staticClass:"u-display-flex u-width-100"},[_c('loader',{staticClass:"fill--parent",attrs:{"loading":_vm.metadata.status === 'loading',"color":'#3684bb'}}),(_vm.openActivity)?_c('div',[(_vm.getShowCampaignsActivityLog || _vm.showActionsLog)?_c('activity-log',{attrs:{"data":_vm.getActivityLogData,"page-name":"campaigns","close-activity-log":_vm.closeActivityLog,"top-spacing":"0px"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"u-display-flex u-flex-direction-column u-width-100"},[_c('div',{staticClass:"u-display-flex u-spacing-pb-s u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-flex-align-items-center u-display-flex u-max-width-70"},[_c('div',{on:{"click":_vm.redirectTo}},[_c('rb-icon',{staticClass:"u-color-grey-lighter arrow--entity_details u-icon-size-2 u-cursor-pointer u-spacing-mr-s",attrs:{"icon":"arrow_back"}})],1),_c('div',[(_vm.entityConfig.bookmark.value)?_c('Bookmark',{attrs:{"title":_vm.entityConfig.bookmark.fromMetadata
                  ? _vm.metadata.data[_vm.entityConfig.bookmark.title]
                  : _vm.entityConfig.bookmark.title,"path":_vm.$route.fullPath}}):_vm._e()],1),_c('div',{staticClass:"u-text-overflow-ellipsis"},[(
                _vm.entityConfig[_vm.linesHeader] &&
                _vm.entityConfig[_vm.linesHeader].items.length
              )?_c('div',{staticClass:"u-width-100",class:_vm.entityConfig[_vm.linesHeader].class},_vm._l((_vm.entityConfig[_vm.linesHeader]
                  .items),function(lineItem,lineIndex){return _c('div',{key:(lineIndex + "_" + _vm.linesHeader)},[(lineItem.itemType === 'attribute')?_c('attribute',{attrs:{"line-item":lineItem,"line-index":lineIndex,"metadata":_vm.metadata.data}}):_vm._e()],1)}),0):_vm._e()])]),_c('div',{staticClass:"u-display-flex u-min-width-30"},[_c('rb-filter-panel',{key:_vm.filterKey,attrs:{"id":"filterID","data":_vm.filterData,"primary":_vm.primaryFilterData,"secondary-data":_vm.secondaryFilterData,"listener-event":_vm.filterConfig.filterLocalStorageKey,"config":_vm.filterConfig.config,"loader-status":_vm.loaderStatus,"emit":_vm.filterConfig.config.emit,"has-search":true,"new-date":true,"allow-date-compare":true,"show-data-missing-msg":_vm.showDataMissingMsg,"data-missing-date-range":'August 11th, 2023, to October 9th, 2023,',"enable-save":false,"page":_vm.filterConfig.page,"hide-filter":true,"hide-add-filter":true,"default-date-post-range":"last30Days","max-date":_vm.entityConfig.maxFeedDate || _vm.minMaxDate,"enable-remove-all":false,"show-notification":false,"enable-open-filter":false,"additional-ranges":_vm.additionalRanges},on:{"savedFilterApplied":function($event){_vm.filterKey++},"filterMounted":_vm.handleFilterMount}}),(
              _vm.entityConfig &&
              _vm.entityConfig.activityLog &&
              _vm.entityConfig.activityLog.show
            )?_c('div',{staticClass:"u-spacing-pl-s"},[_c('div',{staticClass:"activity-log-entity-details u-cursor-pointer u-spacing-ph-m u-spacing-pv-s u-border-all u-border-color-grey-xxx-light u-border-radius-s u-border-width-s",on:{"click":_vm.openSidePanel}},[_c('rb-icon',{staticClass:"rb-icon--medium u-color-grey-lighter",attrs:{"title":"Activity log","icon":"timeline"}})],1)]):_vm._e()],1)]),_c('div',_vm._l((_vm.subLinesWithLineConfigArray()),function(ref,index){
            var lineName = ref.lineName;
            var lineConfig = ref.lineConfig;
return _c('div',{key:index,staticClass:"u-display-flex u-width-100 u-flex-justify-content-space-between u-spacing-pl-xxxl"},[(lineConfig && lineConfig.items.length)?_c('div',{staticClass:"u-display-flex u-flex-wrap-yes",class:lineConfig.class},_vm._l((lineConfig.items),function(lineItem,lineIndex){return _c('div',{key:(lineIndex + "_" + lineName)},[(lineItem.itemType === 'attribute')?_c('attribute',{attrs:{"line-item":lineItem,"line-index":lineIndex,"metadata":_vm.metadata.data}}):_vm._e()],1)}),0):_vm._e()])}),0)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }