<template>
  <div>
    <div
      v-tippy=""
      :title="titleTooltip"
      :class="titleClass"
    >
      {{ title }}
    </div>
    <div>
      <span
        v-tippy=""
        :title="secondaryTextTippyText"
        :class="{
          secondaryTextClass,
          'link--hover': !isEmpty(secondaryTextNavigateUrl)
        }"
        @click="secondaryTextClicked"
      >
        {{ secondaryText }} {{ secondaryTextSecond }}
      </span>
      <span
        v-if="enableCopyToClipboard"
        v-tippy=""
        title="Copy to clipboard"
        class="u-spacing-ml-xxs"
        @click="copyToClipboard"
      >
        <rb-icon
          class="rb-icon--x-small u-color-grey-lighter u-cursor-pointer"
          :icon="'copy-to-clipboard'"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import helper from '@/utils/helpers/index.js';
export default {
  name: 'TextWithLink',
  props: {
    title: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: 'u-color-grey-base u-spacing-mb-xs'
    },
    titleTooltip: {
      type: String,
      default: ''
    },
    secondaryText: {
      type: String,
      default: ''
    },
    secondaryTextSecond: {
      type: String,
      default: ''
    },
    secondaryTextClass: {
      type: String,
      default: 'u-color-grey-lighter u-font-size-7'
    },
    secondaryTextNavigateUrl: {
      type: String,
      default: ''
    },
    secondaryTextTippyText: {
      type: String,
      default: ''
    },
    enableCopyToClipboard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEmpty: isEmpty
    };
  },
  methods: {
    copyToClipboard(event) {
      helper.copyToClipboardText(this.title, event);
      this.$snackbar.open({
        message: 'Copied to clipboard',
        position: 'is-bottom-left',
        actionText: '',
        duration: 1000
      });
    },
    secondaryTextClicked() {
      if (!isEmpty(this.secondaryTextNavigateUrl)) {
        window.open(this.secondaryTextNavigateUrl, '_blank');
      }
    }
  }
};
</script>

<style lang="css" scoped>
.link--hover:hover {
  text-decoration: underline;
}
</style>
