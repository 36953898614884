<template>
  <div class="u-width-100">
    <div>
      <div
        v-for="(val, index) in computedList"
        :key="index"
      >
        <div
          class="u-display-flex u-flex-justify-content-space-between u-font-size-4 u-spacing-mt-m"
        >
          <span class="u-color-grey-mid-light">{{ val.labelText }}:</span>
          <span class="u-color-greu-base">{{ val.formattedValue }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    computedList() {
      const returnList = [];
      for (let i = 0; i < this.list.length; i++) {
        const obj = {
          ...this.list[i]
        };
        if (obj.numerator) {
          obj.value = `${obj.numerator} / ${obj.denominator}`;
        }
        obj.formattedValue = formatter(obj.value, obj.format);
        returnList.push(obj);
      }
      return returnList;
    }
  },
  watch: {},
  created() {}
};
</script>
