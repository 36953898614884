import { cloneDeep } from 'lodash';
import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import { getColumnDefinition } from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';

const productAdditionSuccessObject = {
  message: 'Product addition was successful',
  duration: 6000,
  actionText: ''
};
const productAdditionFailureObject = {
  message: 'Product addition was unsuccessful',
  duration: 6000,
  actionText: ''
};

const selectSkuLeftTable = (vueRef) => [
  {
    name: 'sku_details',
    type: 'sku',
    uiField: {
      uiLabel: 'SKU Details',
      uiType: 'custom',
      widget: 'sku',
      uiOrder: '1',
      metadata: {
        widget: 'sku',
        tippyText: '',
        toolTipText: '',
        tableColumnName: 'SKU Details',
        columnTitleKey: 'product_title',
        columnAsinKey: 'upc',
        columnImageUrlKey: 'image_url',
        columnAsinHoverText: '',
        columnEnableProductLink: true,
        coloumnProductUrlKey: 'product_url',
        showOnUi: true,
        isFixed: false,
        getQuickFilterText: function (params) {
          return params.data.product_title + '_' + params.data.upc;
        }
      }
    }
  },
  {
    name: 'brand',
    type: 'STRING',
    uiField: {
      uiLabel: 'Brand',
      uiType: 'string',
      uiOrder: '3',
      metadata: {
        toolTipText: '',
        tableColumnName: 'brand_name',
        showOnUi: true,
        isFixed: false,
        width: 90
      }
    }
  },
  {
    name: 'size',
    type: 'STRING',
    uiField: {
      uiLabel: 'Size',
      uiType: 'string',
      uiOrder: '4',
      metadata: {
        toolTipText: '',
        tableColumnName: 'product_size',
        showOnUi: true,
        isFixed: false,
        width: 90
      }
    }
  },
  {
    name: 'Add all',
    type: 'custom',
    uiField: {
      uiLabel: 'Add all',
      uiType: 'custom',
      clickHeader: vueRef.addAll,
      customStyles:
        'cursor:pointer; color:#037ef6 !important; padding-left:15px',
      headerIcon: {
        icon: 'info-circle-fill',
        toolTip: 'Add all SKUs on this page'
      },
      uiOrder: '2',
      metadata: {
        toolTipText: 'Add this SKU',
        toolTipPosition: 'left',
        widget: 'icon',
        tableColumnName: 'na',
        showOnUi: true,
        suppressSizeToFit: true,
        isFixed: false,
        iconClickEvent: vueRef.addEntity,
        displayIcon: 'plus',
        alternateIcon: 'check-fill-circle',
        alternateIconClass: 'u-color-green-base',
        width: 110
      }
    }
  }
];

const selectSkuRightTable = (vueRef) => ({
  sku_details: [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'upc',
          columnImageUrlKey: 'image_url',
          columnAsinHoverText: '',
          columnEnableProductLink: true,
          coloumnProductUrlKey: 'product_url',
          showOnUi: true,
          isFixed: false
        }
      }
    }
  ],
  remove_all: [
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove Sku',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ]
});

const APIConfig = {
  cubeName: 'instacart_sku_catalog',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [
    'product_title',
    'product_url',
    'image_url',
    'brand_name',
    'product_size'
  ],
  groupByDimensionsList: ['upc'],
  orderByList: [{ dimension: 'product_title', direction: 'ASC' }],
  limit: 500,
  page: 1
};

const formSKUList = (skus, rightTableRows) => {
  const rightTableSKUs = rightTableRows.map(
    (rightTableSKU) => rightTableSKU.upc
  );
  const validSKUs = skus.filter((sku) => !rightTableSKUs?.includes(sku.trim()));
  let skuList = '';
  for (const sku of validSKUs) {
    skuList += `'${sku.trim()}',`;
  }
  skuList = skuList.slice(0, -1);
  return skuList;
};

const addSkuOnInput = (data) => {
  const skuList = formSKUList(data.keywords, data?.ref?.rightTableRows);
  const APIConfigDeepClone = cloneDeep(APIConfig);
  let result = [];
  if (skuList === '') {
    data.enterListRef.keywordTextInput = '';
    data.ref.$snackbar.open({
      message: 'SKU(s) in the list are already added',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  delete APIConfigDeepClone.limit;
  delete APIConfigDeepClone.page;
  APIConfigDeepClone.where = {
    dimensionNameValueList: [
      {
        dimensionName: 'upc',
        dimensionValue: skuList,
        operator: 'IN'
      }
    ]
  };
  data.ref.rightTableLoad = true;
  HttpLayer.post({
    APIData: APIConfigDeepClone
  })
    .then((response) => {
      result = transformer.mergeResultDimension(response.data, true);
      let keywordTextLeft = skuList.replace(/'/g, '');
      result.forEach(({ upc }) => {
        keywordTextLeft = keywordTextLeft.replace(upc, '');
      });
      // removing comma from end and start and then replacing all adjacent commas in between with link break.
      data.enterListRef.keywordTextInput = keywordTextLeft
        .replace(/^,+|,+$/g, '')
        .replace(/(,)\1*/g, '\n');
    })
    .finally(() => {
      data.ref.$snackbar.open({
        message: result?.length
          ? 'Added SKU(s) from the list'
          : 'Ineligible/Invalid SKU(s) were not added',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      data.ref.addEntityFromRawObjectArray(result);
      data.ref.rightTableLoad = false;
    });
};

export const addCustomProductsCustomActionPanelState = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'upc',
          isMultipleAllowed: true,
          default: {
            hideHeader: false,
            hideFooter: false,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple line items are not allowed'
          },
          left: {
            rowHeight: 68,
            tabs: {
              "All SKU's": {
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'search_text'
                },
                showFilterCheckBox: false
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              localFilters: [],
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef) => {},
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: cloneDeep(APIConfig),
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            meta: {
              localFilters: [
                {
                  dimensionName: 'ad_group_id',
                  dimensionValue: context?.entityId,
                  operator: 'EQUAL_TO'
                }
              ],
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef, rows = []) => {
                const newRows = [];
                for (let item of rows) {
                  item.product_title = item.sku_title;
                  item.image_url = item.latest_image_url;
                  item.product_url = item.latest_product_page_url;
                  newRows.push(item);
                }
                vueRef.fetchedRightTableRows = {
                  rows: newRows,
                  totalRows: vueRef.rightTableData.totalRows
                };
                vueRef.refreshLeftTableIsRowSelected();
              },
              paginationAction: 'fetchTableDataExecuteApi'
            },
            keepFetchedRightTableRows: true,
            isColumnDefAutoConfigure: false,
            body: {
              APIConfig: {
                ...cloneDeep(APIConfig),
                cubeName: 'instacart_campaigns_sku_workbench',
                customAPIDecisionVars: {
                  system: 'instacart_campaign_management'
                },
                measuresList: [
                  'sku_title',
                  'latest_product_page_url',
                  'latest_image_url',
                  'brand',
                  'product_size'
                ],
                orderByList: [{ dimension: 'sku_title', direction: 'ASC' }]
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: getColumnDefinition(
                vueRef,
                selectSkuRightTable(vueRef)
              )
            },
            headerText: () =>
              `Added SKU's (${vueRef.rightTableRowsProp.length})`
          },
          apply(rows = []) {
            const adgroupName =
              context.$store.getters['entityDetails/getAdgroupDetails']?.data
                ?.name;
            const payloadRequest = rows
              .filter(
                (row) =>
                  vueRef.fetchedRightTableRows.rows.findIndex(
                    (fetchedRow) => fetchedRow.upc === row.upc
                  ) === -1
              )
              .map(({ product_title, product_size, upc, image_url }) => ({
                actionPayload: {
                  campaignId: context?.parentEntityId,
                  adgroup_id: context?.entityId,
                  campaignType: 'featured_product',
                  asin: upc,
                  sku_title: product_title,
                  sku_image_url: image_url,
                  product_size
                },
                viewPayload: {
                  adgroupName,
                  product_size,
                  upc,
                  product_title
                },
                actionType: 'instacartAdGroupProductsAdd',
                actionSource: {
                  pageUrl: 'null'
                },
                widget: 'campaign',
                primaryKey: context?.parentEntityId
              }));
            HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payloadRequest)
              .then((response) => {
                if (response?.data?.success) {
                  context.$snackbar.open(productAdditionSuccessObject);
                } else {
                  context.$snackbar.open(productAdditionFailureObject);
                }
              })
              .catch((err) => {
                if (err) {
                  context.$snackbar.open(productAdditionFailureObject);
                }
              })
              .finally(() => {
                vueRef.$emit('afterAPIActionToCall');
                context.reloadComponent(true);
              });
          }
        };
      }
    },
    staticHeaderText: 'ADD PRODUCTS'
  },
  events: {
    afterAPIActionToCall(payload) {
      // this.$emit('openSnackbar', 'Action has been submitted');
      // this.switchBlueBar('line item');
      // this.removeCustomCustomComponents();
      context.showAddCustomProductPanel = false;
      context.closeActionBar();
    },
    closePanelMethod() {
      context.showAddCustomProductPanel = false;
      context.closeActionBar();
    }
  }
});
