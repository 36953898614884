import { getFinalMeasuresList } from '@/pages/campaign-management/retailers/criteo/utils';
import requestConfig from '@/pages/campaign-management/retailers/criteo/search_terms/requestConfig.json';
import { klona } from 'klona/json';
import {
  additionalDateRanges,
  baseChartConfig,
  commonChartActionIcons,
  commonTableActionIcons,
  gridOptions
} from '@/pages/campaign-management/constants';
import {
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common';
import metricsDictionary from '@/pages/campaign-management/retailers/dictionary';

function chartRequest(retailer) {
  return {
    ...klona(requestConfig.chart),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.chart.measuresList),
      retailer
    )
  };
}

function tableRequest(retailer) {
  return {
    ...klona(requestConfig.table),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.table.measuresList),
      retailer
    )
  };
}

const WIDGET = 'search_terms';
const PRIMARY_KEY = 'search_term_id';

export default (context, tab, retailer) => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Search Terms'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'criteo_common_search_terms.max_feed_date',
        min: 'criteo_common_search_terms.min_feed_date'
      },
      api: {
        cube: 'criteo_common_search_term_workbench',
        page: `${retailer}_search_terms`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'search-terms-filter',
      page: `${retailer}_search_terms`,
      listenerEvent: `${retailer}-search-terms-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-search-terms',
          placeholder: 'Search for Search Terms',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-search-terms-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: chartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          chartConfig: baseChartConfig(),
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          }
        ]
      },
      activityBar: {
        pageType: 'campaign'
      },
      messageBar: {
        pageType: 'campaigns'
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: tableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'campaigns'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        },
        events: {}
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    }
  };
};
