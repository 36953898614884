<template>
  <div
    v-tippy="{ placement: 'right', arrow: false }"
    class="u-position-relative u-border-radius-s u-spacing-mv-s u-spacing-mh-s u-border-width-s u-border-top u-border-left u-border-right u-border-bottom metrics-card u-flex-1 u-cursor-pointer u-bg-color-grey-white"
    :class="{
      'u-border-color-blue-base': isActive,
      'u-border-color-grey-xxx-light': !isActive
    }"
    title="View details"
    @click="handleClick"
  >
    <div class="u-spacing-p-s">
      <div>
        <div
          class="u-font-size-4 u-font-weight-bold u-spacing-pv-s u-text-align-center"
        >
          {{ sourceName }}
        </div>
      </div>
      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column"
      >
        <div class="u-position-relative">
          <chart
            class="chartDimensions"
            :config="chartConfig"
            :data="data"
          />
          <span
            class="u-position-absolute percentage-holder u-font-size-2 u-font-weight-600"
            >{{ getPercentage }}</span
          >
        </div>
        <div class="u-width-100">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="u-spacing-pv-xs"
          >
            <div
              class="u-spacing-pv-xs u-font-size-6 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
            >
              <div
                class="u-spacing-mh-xs u-display-flex u-flex-align-items-center"
              >
                <span
                  class="dot u-spacing-mh-xs"
                  :style="{ 'background-color': getColor(index) }"
                />
                <span class="u-font-weight-600 u-color-grey-light">{{
                  item[0]
                }}</span>
              </div>
              <div class="u-color-grey-lighter u-font-weight-600">
                {{ item[1] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import loader from '@/components/basic/loader';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {
    chart,
    loader
  },
  props: {
    chartConfig: {
      type: Object,
      default: () => {
        return {
          chartOptions: {
            legend: false,
            color: {
              pattern: ['#3ea95e', '#d7263d']
            },
            type: 'donut',
            donutToolTipTitle: 'Title Placeholder',
            tooltip_format: {
              roundOff: 0
            }
          },
          donut: {
            expand: false,
            label: {
              show: false,
              position: 'right'
            },
            width: 12
          }
        };
      }
    },
    sourceName: {
      type: String,
      default: null
    },
    totalSuccess: {
      type: Number,
      default: null
    },
    totalFailed: {
      type: Number,
      default: null
    },
    totalTested: {
      type: Number,
      default: null
    },
    isActive: {
      type: Boolean,
      default: null
    },
    error: {
      type: Boolean,
      default: null
    },
    loading: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      data: [
        ['Successful test runs', this.totalSuccess],
        ['Failed test runs', this.totalFailed]
      ]
    };
  },
  computed: {
    getPercentage() {
      return this.totalTested
        ? formatter((this.totalSuccess / this.totalTested) * 100, 'percent')
        : '?';
    }
  },
  methods: {
    handleClick() {
      this.$emit('cardClicked', this);
    },
    getColor(index) {
      return `${this.chartConfig.chartOptions.color.pattern[index]}`;
    }
    // getChartConfig (data) {
    //   // make individual donut changes here (ex: color based on data, adding suff and pre here, etc)
    //   const chartConfig = cloneDeep(this.config.widgets.chart);
    //   const length = data.formattedChartData.length;
    //   chartConfig.chartOptions.color.pattern = length === 2 ? this.chartColors.customList : this.chartColors.clientBranded;
    //   chartConfig.chartOptions.donutToolTipTitle = data.tags;
    //   return chartConfig;
    // }
  }
};
</script>

<style>
.metrics-card {
  min-width: calc(16.6% - 16px);
  max-width: calc(16.6% - 16px);
}
.chartDimensions {
  max-height: 140px !important;
  max-width: 140px !important;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.percentage-holder {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
