import {
  state,
  getters,
  mutations,
  actions
} from '@/components/ams/campaign_creation/walmart_steps/store.js';
import { cloneDeep } from 'lodash';

const copyOfStateFromWalmart = cloneDeep(state);
const copyOfGettersFromWalmart = cloneDeep(getters);
const copyOfMutationsFromWalmart = cloneDeep(mutations);
const copyOfActionsFromWalmart = cloneDeep(actions);

export default {
  namespaced: true,
  state: copyOfStateFromWalmart,
  getters: copyOfGettersFromWalmart,
  mutations: copyOfMutationsFromWalmart,
  actions: copyOfActionsFromWalmart
};
