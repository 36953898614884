<template>
  <div class="low-inventory">
    <div class="u-envelop-width u-width-100 z-index-2">
      <div class="u-spacing-pb-m">
        <dynamic-component-composer
          :child-components="headerOptionsComputed"
          :container-styles="headerContainerGridStyles"
        />
      </div>
      <div class="low-inventory-card u-border-radius-s u-bg-color-grey-white">
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-m u-spacing-pt-m u-spacing-pb-s"
        >
          <title-with-icon
            icon="inventory"
            title="Low Inventory"
            title-class="u-font-size-5 u-spacing-pt-xxs"
          />
          <icon-text
            icon="help-outline"
            tooltip-title="How does the Low Inventory work?"
            text="How it works"
            text-classes="u-font-size-6 u-color-grey-lighter u-font-weight-600 u-text-decoration-underline u-cursor-default u-spacing-pr-xs"
            icon-classes="u-color-grey-lighter rb-icon--medium"
            :tooltip-text="howItWorksTooltipText"
            container-class="help-tooltip-container"
          />
        </div>
        <div
          class="u-display-flex u-spacing-ph-m u-flex-justify-content-space-between"
        >
          <div
            id="main-summary-data"
            class="u-display-flex"
          >
            <div
              class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-flex-justify-content-center"
            >
              <metric
                size="l"
                :class="[
                  'u-font-weight-600',
                  'u-font-size-3',
                  'u-spacing-pl-xs',
                  'u-display-inline'
                ]"
                :config="metricConfig['count_all_skus_pit']"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : overviewData[
                        summaryMetricVsRetailsMap[selectedFilterOption]
                          .overview_sku_count
                      ]
                "
                :hide-zero="false"
              />
              <span
                class="u-font-size-6 u-color-grey-light u-font-weight-400 u-spacing-pt-s"
                >Total SKUs Impacted</span
              >
              <div
                v-if="selectedFilterOption === 'retail_and_3p'"
                class="u-display-flex u-spacing-pt-s u-spacing-pb-xxs"
              >
                <div class="u-display-flex">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-400"
                    >Retail:
                  </span>
                  <metric
                    size="13-12"
                    :class="[
                      'u-font-weight-600',
                      'u-font-size-3',
                      'u-display-inline',
                      'u-spacing-pl-xs'
                    ]"
                    :config="metricConfig['count_retail_skus']"
                    :data="
                      isMetricLoading
                        ? loadingMetricData
                        : overviewData['count_retail_skus']
                    "
                    :hide-zero="false"
                  />
                </div>
                <rb-icon
                  icon="dot"
                  class="rb-icon u-color-grey-xx-light u-spacing-ml-m u-spacing-mr-m u-spacing-pb-xxs rb-icon--x-small icon-dot"
                />
                <div class="u-display-flex">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-400"
                    >3P:
                  </span>
                  <metric
                    size="13-12"
                    class="u-font-weight-600 u-font-size-3 u-display-inline u-spacing-pl-xs"
                    :config="metricConfig['count_3p_skus']"
                    :data="
                      isMetricLoading
                        ? loadingMetricData
                        : overviewData['count_3p_skus']
                    "
                    :hide-zero="false"
                  />
                </div>
              </div>
            </div>
            <span class="vertical-divider u-spacing-mh-xl u-spacing-mv-l" />
            <div
              class="u-display-flex u-flex-direction-column u-flex-align-items-flex-end u-flex-justify-content-center"
            >
              <metric
                size="l"
                class="u-font-weight-600 u-font-size-3 u-spacing-pl-xs u-display-flex u-flex-justify-content-flex-end"
                :config="metricConfig['revenue_missed_pit']"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : overviewData[
                        summaryMetricVsRetailsMap[selectedFilterOption]
                          .overview_revenue_impact
                      ]
                "
                label-color="#FFA800"
                :hide-zero="false"
              />
              <icon-text
                icon="help-fill"
                tooltip-title="Potential Revenue Risk"
                text="Potential Revenue Risk"
                text-classes="u-font-size-6 u-color-grey-light u-font-weight-400 u-cursor-default u-spacing-pr-xs"
                icon-classes="u-color-grey-lighter rb-icon--small"
                :tooltip-text="potentialRevenueRiskTooltipText"
                container-class="help-tooltip-container u-spacing-pt-xxs"
              />
              <div
                v-if="selectedFilterOption === 'retail_and_3p'"
                class="u-display-flex u-spacing-pt-s"
              >
                <div class="u-display-flex">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-400"
                    >Retail:
                  </span>
                  <metric
                    size="13-12"
                    class="u-font-weight-600 u-font-size-3 u-display-inline u-spacing-pl-xs"
                    :config="metricConfig['revenue_missed_retail']"
                    :data="
                      isMetricLoading
                        ? loadingMetricData
                        : overviewData['revenue_missed_retail']
                    "
                    :hide-zero="false"
                  />
                </div>
                <rb-icon
                  icon="dot"
                  class="rb-icon u-color-grey-xx-light u-spacing-ml-m u-spacing-mr-m u-spacing-pb-xxs rb-icon--x-small icon-dot"
                />
                <div class="u-display-flex">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-400"
                    >3P:
                  </span>
                  <metric
                    size="13-12"
                    class="u-font-weight-600 u-font-size-3 u-display-inline u-spacing-pl-xs"
                    :config="metricConfig['revenue_missed_3P']"
                    :data="
                      isMetricLoading
                        ? loadingMetricData
                        : overviewData['revenue_missed_3P']
                    "
                    :hide-zero="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <span class="vertical-divider u-spacing-mh-xl u-spacing-mv-l" />
          <div
            class="low_inventory_chart u-spacing-pv-m u-display-flex u-flex-justify-content-center u-flex-grow-1"
          >
            <Loader
              v-if="isChartLoading"
              :loading="isChartLoading"
              class="u-bg-color-grey-white"
              :color="'#3fadf7'"
              size="6rem"
              :thickness="'.2rem'"
            />
            <chartWithGradient
              v-else
              :metric-data="chartData"
              :metadata="{ name: 'low_inventory' }"
              :card-primary-metric-color-code="chartColor"
              :metrics-list="chartMetricsList"
              :chart-config="chartConfig"
            />
          </div>
        </div>
        <div class="u-display-inline-block">
          <div
            class="u-font-size-7 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-pb-m u-spacing-ph-m"
            @click="toggleCard"
          >
            <rb-icon
              :icon="isCardExpanded ? 'collapse' : 'full-screen'"
              class="rb-icon--small u-spacing-mr-s"
            />
            <span class="u-font-size-6">
              {{ isCardExpanded ? 'Hide details' : 'View more details' }}
            </span>
          </div>
        </div>
        <div
          v-if="isCardExpanded"
          class="u-spacing-mh-m"
        >
          <div class="u-display-flex u-flex-justify-content-space-between">
            <div
              class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-spacing-pv-m"
            >
              More about <span class="u-color-grey-base">Low Inventory</span>
            </div>
            <span @click="toggleCard">
              <rb-icon
                icon="cross"
                class="rb-icon u-color-grey-x-light rb-icon--x-medium u-cursor-pointer"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isCardExpanded"
      class="u-envelop-width u-width-100 u-spacing-pb-m"
      style="clip-path: inset(0px -15px -15px -15px); top: -10px"
    >
      <dashboard-service-table
        v-bind="$props"
        :table-class="'u-display-flex u-flex-direction-column u-height-100'"
        :enable-client-side-sorting="false"
        :context="componentContext"
        :widget-name="widgetName"
        :metadata="metadata"
        :widget-data-service="dataService"
        :grid-options="gridOptions"
        :widget-request-params="widgetRequestParamsModified"
        :row-height="48"
        :header-options="headerOptions"
        @tableDataUpdated="tableDataUpdated"
      />
    </div>
  </div>
</template>

<script>
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin';
import Vue from 'vue';
import { cloneDeep, isEqual } from 'lodash';
import * as CONSTANTS from './listingsHealthConstants.js';
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';
import metric from '@/components/basic/metric.vue';
import cardGroupHeader from '@/components/pages/listingsHealth/cardGroupHeader.vue';
import titleWithIcon from '@/components/basic/titleWithIcon.vue';
import iconText from '@/components/basic/iconText.vue';
import LowInventoryDataService from './LowInventoryDataService.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'LowInventory',
  components: {
    DynamicComponentComposer,
    titleWithIcon,
    iconText,
    Loader,
    metric,
    chartWithGradient,
    dashboardServiceTable
  },
  mixins: [cwMixin],
  data() {
    const componentContext = this;
    return {
      componentContext,
      isWidgetLoading: true,
      isMetricLoading: true,
      isChartLoading: true,
      isCardExpanded: false,
      overviewData: {},
      metricConfig: {},
      loadingMetricData: {
        tag1: 'loading'
      },
      apiResponse: [],
      chartData: [],
      chartColor: {
        revenue_missed: '#FFA800',
        revenue_missed_retail: '#FFA800',
        revenue_missed_3P: '#FFA800',
        count_all_skus: '#8B8F93',
        count_3p_skus: '#8B8F93',
        count_retail_skus: '#8B8F93'
      },
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false }, y2: { show: false } },
        legend: false,
        size: { height: 65 },
        tooltip: false,
        axes: {
          'Total SKUs Impacted': 'y2',
          gradient0: 'y2'
        },
        tooltip_format: {
          'Potential Revenue Risk': { pre: '', roundoff: 2, suff: 'currency' },
          'Total SKUs Impacted': { pre: '', roundoff: 2, suff: ' SKUs' }
        },
        point: false,
        line: { connectNull: true },
        padding: { left: -8, right: -7, bottom: 5 },
        regions: {},
        timeseries: 'feed_date',
        tooltip_position: function (a, b, c, d) {
          return { top: -45, left: -20 };
        }
      },
      chartMetricsList: [
        {
          label: 'Potential Revenue Risk',
          alias: 'revenue_missed'
        },
        {
          label: 'Total SKUs Impacted',
          alias: 'count_all_skus'
        },
        {
          label: 'Potential Revenue Risk',
          alias: 'revenue_missed_3P'
        },
        {
          label: 'Total SKUs Impacted',
          alias: 'count_3p_skus'
        },
        {
          label: 'Potential Revenue Risk',
          alias: 'revenue_missed_retail'
        },
        {
          label: 'Total SKUs Impacted',
          alias: 'count_retail_skus'
        }
      ],
      widgetRequestParamsModified: {},
      gridOptions: {
        context: {
          componentParent: this
        },
        ensureDomOrder: true,
        enableCellTextSelection: true,
        postSort: (rowNodes) => {
          rowNodes.sort((a, b) => {
            return a.data.ciqIndex - b.data.ciqIndex;
          });
          return rowNodes;
        }
      },
      selectedFilterOption: 'retail_and_3p',
      availableFilterOptions: [
        {
          value: 'retail_and_3p',
          label: 'Retail + 3P'
        },
        {
          value: '3P',
          label: '3P Only'
        },
        {
          value: 'retail',
          label: 'Retail Only'
        }
      ],
      requestParams: {
        globalViewId: 0,
        id: 112,
        name: 'ListingsHealth',
        title: 'Listings Health',
        ':from': '2023-06-13',
        ':to': '2023-07-10',
        ':pvpFrom': '2023-05-16',
        ':pvpTo': '2023-06-12',
        ':page': 'ListingsHealth',
        ':dimensionNameValueList': [],
        ':widget': 'low_inventory',
        ':entityType': 'asin',
        ':orderByList': [],
        metadata: {
          metricsForCardSummary: [
            'count_3p_skus',
            'count_all_skus',
            'count_retail_skus',
            'revenue_missed',
            'revenue_missed_3P',
            'revenue_missed_retail'
          ],
          metricsForExpandedTable: [
            'title',
            'image_url',
            'inventory',
            'last_30_days_OPS',
            'shipped_cogs',
            'sku',
            'weeks_of_coverage'
          ]
        }
      },
      summaryMetricVsRetailsMap:
        CONSTANTS.LOW_INVENTORY_SUMMARY_METRICS_VS_RETAILER_MAP,
      chartMetricsVsRetailersMap:
        CONSTANTS.LOW_INVENTORY_RETAILER_VS_CHART_METRICS_MAP,
      dataService: LowInventoryDataService,
      potentialRevenueRiskTooltipText: '',
      howItWorksTooltipText: ''
    };
  },
  computed: {
    headerOptionsComputed() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'cardGroupHeader',
        props: {
          title: 'Items that need your attention',
          description:
            "The widget below remains unaffected by the chosen date range and consistently relies on the latest available dates' data",
          icon: 'info-circle-fill',
          filterOptions: this.availableFilterOptions,
          selectedOption: this.selectedFilterOption,
          onFilterChange: this.onFilterUpdate
        }
      });
      return headerOptions;
    }
  },
  watch: {
    async widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        await this.fetchData();
        this.transformData(this.apiResponse);
        this.updatedTableWidgetReqParams(this.selectedFilterOption);
      }
    }
  },
  async created() {
    Vue.component('cardGroupHeader', cardGroupHeader);
    this.widgetRequestParamsModified = cloneDeep(this.widgetRequestParams);
    this.generateConfig();
    await this.fetchData();
    this.transformData(this.apiResponse);
  },
  methods: {
    toggleCard() {
      this.isCardExpanded = !this.isCardExpanded;
    },
    generateConfig() {
      const metricConfig = {};
      const metricsForCardSummary =
        this.metadata.metadata.metricsForCardSummary || [];
      this.potentialRevenueRiskTooltipText =
        this.metadata.metadata.potentialRevenueRiskTooltipText || '';
      this.howItWorksTooltipText =
        this.metadata.metadata.howItWorksTooltipText || '';
      const metricData = Object.values(this.metadata.metrics);
      metricData.forEach((metric) => {
        const tagConfig = metric.metadata.tagConfig;
        if (metricsForCardSummary.includes(metric.name)) {
          metricConfig[metric.name] = tagConfig;
        }
      });
      this.metricConfig = metricConfig;
    },
    onFilterUpdate(data) {
      this.selectedFilterOption = data.value;
      this.isChartLoading = true;
      this.updatedTableWidgetReqParams(data.value);
      this.transformData(this.apiResponse);
    },
    updatedTableWidgetReqParams(selectedFilterOption) {
      const widgetRequestParamsModified = cloneDeep(this.widgetRequestParams);
      const activeFilters = widgetRequestParamsModified[
        ':dimensionNameValueList'
      ].filter(
        (filterConfig) => filterConfig.dimensionName !== 'business_model'
      );
      if (selectedFilterOption !== 'retail_and_3p') {
        activeFilters.push({
          dimensionName: 'business_model',
          dimensionValue: selectedFilterOption,
          operator: 'ILIKE'
        });
      }
      widgetRequestParamsModified[':dimensionNameValueList'] = activeFilters;
      this.widgetRequestParamsModified = widgetRequestParamsModified;
    },
    async fetchData() {
      this.isWidgetLoading = false;
      this.isMetricLoading = true;
      this.isChartLoading = true;
      this.apiResponse = [];
      const metricsForCardSummary =
        this.metadata.metadata.metricsForCardSummary || [];
      let api = Object.values(this.metadata.metrics)[0].api;

      const filteredMetrics = {};
      metricsForCardSummary.forEach((metric) => {
        filteredMetrics[metric] = this.metadata.metrics[metric];
      });
      const metricsGrouping = dashUtils.groupApis(filteredMetrics);

      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        let request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );

        request.entityType = '#ALLOVER_AGGREGATE';
        request.operations.timeseriesEnabled = true;

        await dashUtils
          .fetchDataAPI(request, api)
          .then((args) => {
            this.apiResponse.push(args.entityData?.[0]);
          })
          .catch((exception) => {
            console.error(
              'Exception occurred when fetching low inventory data',
              exception
            );
          });
      }
    },
    tableDataUpdated() {
      this.gridOptions.api.sizeColumnsToFit();
    },
    transformData(value) {
      const overviewResponseData = value;
      const chartData = [];
      const metricsToBeShownOnChart =
        this.chartMetricsVsRetailersMap[this.selectedFilterOption];
      overviewResponseData.forEach((dataObj) => {
        dataObj?.data.forEach((el) => {
          this.overviewData[el.name] = {
            tag1: el.RESULT[el.name]
          };

          if (metricsToBeShownOnChart.includes(el.name)) {
            chartData.push({
              name: el.name,
              alias: el.alias,
              type: el.type,
              TIMESERIES: el.TIMESERIES
            });
          }
        });
      });
      this.chartData = chartData;
      this.isMetricLoading = false;
      this.isChartLoading = false;
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      modifiedRequest.operations.timeseriesEnabled = true;
      modifiedRequest.entityType = '#ALLOVER_AGGREGATE';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    }
  }
};
</script>

<style lang="css">
.low-inventory .dropdown-trigger > div:nth-child(1) > div:nth-child(3) {
  font-weight: 600;
}
.low-inventory .low-inventory-card {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.low-inventory .vertical-divider {
  background: #e9eaeb;
  width: 1px;
  height: auto;
}
.low-inventory .icon-text {
  flex-direction: row-reverse !important;
  align-items: center !important;
  color: red;
}
.low-inventory .help-tooltip-container div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 !important;
}
.low-inventory .low_inventory_chart {
  max-width: 620px !important;
}
.low-inventory .dashboard-service-table-wrapper .header-container-cwc {
  padding: 0rem 1.6rem 1.6rem;
}
.low-inventory .dashboard-service-table-wrapper .rb-insights-table-container {
  height: 65vh;
}
.low-inventory .rb-data-table {
  height: 100% !important;
  padding: 0;
}
.low-inventory .rb-data-table > div:nth-child(1) {
  border-left: 1px #e9eaeb solid;
}
.low-inventory .sku--image {
  width: 32px !important;
  height: 32px !important;
}
.low-inventory .sku--image .image {
  width: 30px !important;
  height: 30px !important;
}
.low-inventory .ag-body-viewport.ag-layout-auto-height {
  min-height: 300px;
  max-height: 450px !important;
  overflow: auto !important;
}
</style>
