<template>
  <div>
    <body
      class="u-bg-color-blue-base u-display-flex u-flex-justify-content-center u-flex-align-items-center"
      style="height: 100vh"
    >
      <div
        class="surface u-border-radius-s u-spacing-p-xl"
        style="width: 340px"
      >
        <div class="u-text-align-center u-spacing-mb-l u-spacing-p-l">
          <img
            style="width: 200px"
            src="/images/logo.svg"
          />
        </div>

        <div
          v-if="errorMessage"
          class="row"
        >
          <a class="u-color-orange-base errorMessage">{{ errorMessage }}</a>
        </div>

        <div class="u-spacing-mb-l u-font-size-5">
          <p>
            You can request access here with your Boomerang Commerce mail
            address
          </p>
        </div>

        <form @submit.prevent="register()">
          <div class="u-spacing-mb-l">
            <label class="u-display-block u-font-size-4">Username</label>
            <input
              v-model="username"
              style="padding-left: 0; padding-right: 0"
              class="text-input text-input--line u-width-100"
              type="text"
              name="username"
            />
          </div>

          <div>
            <rb-button
              class="u-width-100"
              :text="'Register'"
              :click-fn="register"
              :loading="registerLoading"
              :disabled="registerLoading"
              :type="'filled'"
            />
            <input
              class="u-display-none"
              type="submit"
              value="ok"
            />
          </div>
        </form>
      </div>
    </body>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: ''
    };
  },
  computed: {
    registerLoading() {
      return this.$store.state.login.registerLoading;
    },
    errorMessage() {
      return this.$store.getters.getErrorMessage;
    }
  },
  mounted() {
    this.$store.commit('CLEAR_ERROR_MESSAGE');
  },
  methods: {
    register() {
      const registerParams = {
        username: this.username
      };
      this.$store.dispatch('register', registerParams);
    }
  }
};
</script>

<style lang="css">
header {
  display: flex;
  padding: 20px;
  background-color: #fff;
}

.logo-wrap .logo {
  width: 200px;
  height: auto;
}

h3 {
  margin: 20px 0;
  font-size: 22px;
  color: #4a4a4a;
  text-align: center;
}

.login-box {
  width: 100%;
  max-width: 480px;
  margin: 80px auto 0;
  border: 1px solid #ebeef6;
  background: #fff;
}

.row {
  margin: 10px;
  display: flex;
  justify-content: space-around;
  color: #4a4a4a;
  font-size: 14px;
}
.row .info-text {
  font-size: 12px;
}
.row label {
  height: 20px;
  line-height: 20px;
}
.row > input {
  width: 50%;
}
.row.btn-row {
  padding: 20px 0 10px;
}
</style>
