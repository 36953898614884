<template>
  <section>
    <div
      v-if="show"
      class="targeting-warning-message u-spacing-mt-l fit-content u-spacing-p-s u-display-flex u-flex-align-items-flex-start u-border-radius-s"
    >
      <rb-icon
        class="u-color-orange-base rb-icon--medium u-flex-align-self-flex-start"
        icon="info-circle-fill"
      />
      <div
        class="u-font-size-5 u-color-grey-base u-spacing-ml-s u-display-flex u-flex-direction-column"
      >
        <span class="u-font-weight-600"> {{ title }} </span>
        <span class="u-spacing-mt-s">
          {{ subText }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    subText: {
      default: '',
      type: String
    }
  }
};
</script>

<style lang="css" scoped>
.targeting-warning-message {
  background-color: #ffa80012;
}
</style>
