import AdGroup from '@/components/ams/campaign_creation/walmart_steps/adGroup/index.vue';
import KeywordTargetingWalmart from '@/components/ams/campaign_creation/walmart_steps/campaignTargeting/keywordTargeting.vue';
import CampaignType from '@/components/ams/campaign_creation/walmart_steps/campaignTypes/index.vue';
import Settings from '@/components/ams/campaign_creation/walmart_steps/settings/index.vue';
import Profile from '@/components/ams/campaign_creation/walmart_steps/profile/index.vue';
import PlacementBid from '@/components/ams/campaign_creation/walmart_steps/placementBidMultiplier/index.vue';
import { keywordTargetingState } from '@/components/ams/campaign_creation/setup_configs/walmart/common/keywordTargeting.js';
import { adGroupCustomActionPanel } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';

export const adgroupConfig = {
  name: 'Ad Group',
  description: '',
  targetingType: ['product', 'keyword'],
  customActionPanelCustomState: adGroupCustomActionPanel,
  header: {
    title: 'Ad Group',
    desc: [
      'Ad groups are a way to organize, manage, and track performance of the products within your campaign. Give your ad group a name that is descriptive and meaningful to you, based on the products you want to advertise. For example, by category, like “Barbeque grills.”'
    ]
  },
  key: 'adgroup',
  keyToRead: 'skus',
  breadCrumbTitle: 'Ad Group',
  textMapping: 'skus',
  skusCountValidationObject: {
    min: 2,
    max: 10
  },
  // skuBidValidationObject: {
  //   min: 0.2,
  //   message:
  //     'The minimum bid for products in Auto campaign products should be $0.2.'
  // },
  component: AdGroup
};

export const keywordTargeting = {
  name: 'Targeting',
  description: '',
  targetingType: ['keyword'],
  header: {
    title: 'Keyword Targeting',
    desc: [
      'Targeting uses keywords and products to help your ads appear in search and detail pages.'
    ]
  },
  key: 'keywordTarget',
  customActionPanelCustomState: keywordTargetingState,
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the keywords you would like to target?',
  textMapping: 'targeting',
  noKeywordsErrorMessage: 'One keyword is required for SB campaigns',
  keywordBidValidationObject: {
    min: 1,
    max: 100
  },
  component: KeywordTargetingWalmart
};

export const campaignType = {
  name: 'Select Campaign Type',
  description: '',
  header: {
    title: 'Select Campaign Type',
    desc: [
      'There are two types of campaigns or ads you can create in Walmart: Sponsored Product ads, Sponsored Brands ads. Sponsored Product ads are the most common.'
    ]
  },
  key: 'campaignType',
  keyToRead: 'campaignType',
  breadCrumbTitle: 'Select Campaign Type',
  textMapping: 'campaignType',
  campaignTypesArray: [
    {
      url: '',
      title: 'Sponsored Products',
      key: 'sponsoredProducts',
      desc: 'Promote products to shoppers actively searching with related keywords or viewing similar products on Walmart.',
      image: '/images/walmart-sp.png'
    },
    {
      url: '',
      title: 'Sponsored Brands',
      key: 'sponsoredBrands',
      desc: 'Showcase a collection of products to shoppers actively searching with related keywords on Walmart.',
      image: '/images/walmart-sba.png',
      info: 'SB campaigns may take 1-3 days to go live'
    }
  ],
  component: CampaignType
};

export const settings = {
  name: 'Settings',
  description: '',
  totalBudget: null,
  dailyBudget: null,
  getAdvertisers: {
    cubeName: 'walmart_advertiser_details',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: [],
    groupByDimensionsList: ['advertiser_id', 'advertiser_name'],
    orderByList: [],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'advertiser_type',
          dimensionValue: 'api',
          operator: 'EQUAL_TO'
        }
      ]
    }
  },
  header: {
    title: 'Campaign Setting Details',
    desc: [
      "Start Date and End Date determine the period when your campaign will be active. Total and daily budget is the amount you're willing to spend in the campaign. You can enter either total budget or daily budget or both. The dates mentioned are in PST."
    ]
  },
  breadCrumbTitle: 'Campaign Setting Details',
  keyToRead: 'settings',
  textMapping: 'settings',
  component: Settings
};

export const profile = {
  name: 'Profile',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'SB Profile Details',
    desc: [
      'Profiles are created in Sponsored Brand campaigns to set the images and text displayed for the ads as well as the brand store URLs. You can create the second profile if required. Profile 1 will be enabled but can be later switched in the Profile tab.'
    ]
  },
  key: 'profile',
  keyToRead: 'profile',
  breadCrumbTitle: '',
  textMapping: 'profile',
  component: Profile
};

export const placementAndBidMultiplier = {
  name: 'Placement & Bid Multiplier (Optional)',
  header: {
    title: '',
    desc: ['']
  },
  key: 'placementAndBidMultiplier',
  keyToRead: 'placementAndBidMultiplier',
  breadCrumbTitle: '',
  textMapping: 'placementAndBidMultiplier',
  component: PlacementBid
};
