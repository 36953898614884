<template>
  <div class="u-font-size-5 charting_wb_wrapper">
    <widgetContainer
      :header-options="uiHeaderOptions"
      :footer-options="footerOptionsComp"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isChartLoading"
      :header-container-grid-styles="headerContainerGridStyles"
      :last-updated-date="lastUpdatedDate"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <chart-with-legends
          :key="myKey"
          :chart-config="chartConfig"
          :chart-data="chartData"
          :metrics-list="metricList"
          :metrics-shown="shownMetricList"
          :default-select-metric="defaultSelectMetric"
          :metric-config="metricDisplayConfig"
          :metric-data="metricData"
          :disable-add-new-metric="disableAddNewMetricButtonComputed"
          :is-chart-loading="isChartLoading"
          :show-powered-by-c-i-q-text="true"
          :remove-dropdown="removeDropdownComputed"
          :selected-metric-limit="selectedMetricLimitChart"
          :minimum-metric="minimumMetric"
          :metric-font-size="'u-font-size-4'"
          :hide-zero="hideZero"
          :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
          :modify-tooltip-message="modifyTooltipMessage"
          @selectedMetricList="updateExistingMetadata"
        />
      </template>
    </widgetContainer>
  </div>
</template>

<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import dualChartingMixin from '@/components/pages/insights/amazon/shortage-invoices/dual_charting_mixin.js';
import { cloneDeep } from 'lodash';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import Vue from 'vue';
import moment from 'moment-timezone';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  name: 'ChartingWb',
  components: {
    chartWithLegends,
    widgetContainer
  },
  mixins: [cwMixin, dualChartingMixin],
  props: {
    enableRollup: {
      type: Boolean,
      default: false
    },
    selectedMetricLimit: {
      type: Number,
      default: 1
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    removeDropdown: {
      type: Boolean,
      default: true
    },
    disableAddNewMetricButton: {
      type: Boolean,
      default: true
    },
    chartConfig: {
      type: Object,
      default: {}
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    enableDisplayIn: {
      type: Boolean,
      default: true
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    bypassFilters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myKey: 1,
      dataGroups: {},
      metricData: {},
      metricsArray: [],
      metricDisplayConfig: {},
      metricEventConfig: this.metadata,
      selectedMetrics: [],
      metricList: [],
      shownMetricList: [],
      defaultSelectMetric: [],
      metricsDataResponse: [],
      selectedMetricLimitChart: this.selectedMetricLimit,
      isChartLoading: false,
      chartData: {
        data: []
      },
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      filtersConfig: [],
      selectedDisplay: ''
    };
  },
  computed: {
    computedFilterConfig() {
      const filterConfig = JSON.parse(JSON.stringify(this.filtersConfig));
      return filterConfig;
    },
    removeDropdownComputed() {
      return (
        this.removeDropdown ||
        this.selectedMetricLimitChart === this.metricsArray.length
      );
    },
    disableAddNewMetricButtonComputed() {
      return (
        this.disableAddNewMetricButton ||
        this.selectedMetricLimitChart === this.metricsArray.length
      );
    },
    uiHeaderOptions() {
      const tempHeaderOptions = cloneDeep(this.headerOptionsComp) || [];
      const lastElement = tempHeaderOptions.slice(-1);
      tempHeaderOptions.pop();

      // enabling Dual Displays
      if (this.enableDisplayIn) {
        const radioValue = this.selectedDisplay;
        const availableDisplays =
          this.metadata.metadata.defaultConfig.availableDisplays;
        const radioOptions = Object.keys(availableDisplays).map((key) => ({
          type: key,
          label: availableDisplays[key].displayLabel?.replaceAll(
            'currency',
            Vue?.options?.filters?.config_check(
              'feature.i18n.currencyLabel.plural'
            )
          )
        }));
        tempHeaderOptions.push({
          ui_component: 'rbRadioGroup',
          props: {
            value: radioValue,
            groupLabel: 'Display in:',
            radioOptions
          },
          events: {
            onRadioChanged: this.displayChanged
          }
        });
      }
      // enable Roll Up
      if (this.enableRollup) {
        const rollUpValue = this.metadata.metadata.defaultConfig.selectedRollUp;

        tempHeaderOptions.push({
          ui_component: 'RollUpBy',
          props: {
            label: 'Roll Up By:',
            value: rollUpValue,
            dateObj: this.getGlobalRequestParams(),
            useComponentOverSlots: true
          },
          events: {
            onRollUpChange: this.rollUpChanged
          }
        });
      }

      tempHeaderOptions.push(lastElement[0]);

      return tempHeaderOptions;
    },
    bypassMetricGroups() {
      return this?.metadata?.metadata?.selectedMetricsListUnaffected;
    }
  },
  watch: {
    metadata(newVal) {},
    widgetRequestParams() {
      this.fetchData();
    },
    selectedDisplay() {
      this.initFunction();
      this.fetchData();
    }
  },
  created() {
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    this.selectedDisplay = this.metadata.metadata.defaultConfig.selectedDisplay;
    this.initFunction();
    this.fetchData();
  },
  methods: {
    initFunction() {
      const metadata = this.metadata.metadata;

      // metrics from the response
      this.metricsArray = Object.values(this.metadata.metrics).map((item) => {
        item.key = item.label;
        item.title = item.label;
        return item;
      });

      // TO-DO : Hiding Metrics from UI, will be picked up in V2
      this.metricsArray = this.metricsArray.filter(
        (item) => !metadata?.defaultHiddenMetrics?.includes(item.name)
      );

      // Metrics to pass to chart-with-legends
      this.selectedMetrics =
        metadata.defaultConfig.availableDisplays[
          this.selectedDisplay
        ].selectedMetric;
      this.metricList = cloneDeep(this.metricsArray);
      // Metrics to pick from metadata
      const shownMetricListFromMetadata =
        metadata.defaultConfig.availableDisplays[this.selectedDisplay]
          .shownMetricList;
      if (shownMetricListFromMetadata.length) {
        this.shownMetricList = this.metricsArray.filter((el) =>
          shownMetricListFromMetadata.includes(el.name)
        );
      } else {
        this.shownMetricList = cloneDeep(
          this.metricsArray.slice(0, this.selectedMetricLimit)
        );
      }

      // All the metrics should be rendered by default
      this.defaultSelectMetric = this.metricsArray.filter((el) =>
        this.selectedMetrics.includes(el.name)
      );
      this.metricDisplayConfig = this.generateMetricDisplayConfig(
        this.metricsArray
      );
    },
    fetchData() {
      this.isChartLoading = true;

      const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);

      let request, api;
      const dataGroupsList = Object.keys(dataGroups);

      for (let i = 0; i < dataGroupsList.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );
        let updatedDimensionValueList = [];

        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        if (this.bypassMetricGroups?.includes(dgKey)) {
          updatedDimensionValueList = this.widgetRequestParams[
            ':dimensionNameValueList'
          ]?.filter((item) => !this.bypassFilters.includes(item.dimensionName));
        } else {
          updatedDimensionValueList =
            this.widgetRequestParams[':dimensionNameValueList'];
        }
        request.where.dimensionNameValueList = updatedDimensionValueList;
        request = this.tempTransformer(request, metricsListName);
        dashUtils.fetchDataAPI(request, api).then((args) => {
          Vue.set(this.metricsDataResponse, i, args.entityData[0].data);
          this.dataTransformer(this.metricsDataResponse);
        });
      }
    },
    rollUpChanged(data) {
      const chartingReqParam = cloneDeep(this.requestParams[this.widgetName]);
      chartingReqParam[':timeseriesRollupBy'] = data.key;
      this.updateWidgetRequestParams(chartingReqParam, this.widgetName);
    },
    displayChanged(data) {
      this.selectedDisplay = data;
    },
    updateExistingMetadata(data) {
      this.defaultSelectMetric = data.selectedMetric;
      this.shownMetricList = data.metricDisplayList;
    }
  }
};
</script>

<style lang="css">
.charting_wb_wrapper .metricListClass {
  margin-bottom: 0;
}

.charting_wb_wrapper .chart_space_class {
  margin-top: 0;
}

.charting_wb_wrapper .header-container-cwc {
  padding-right: 24px;
}
</style>
