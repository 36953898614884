<template>
  <div
    class="u-border-left u-border-color-grey-xxx-light u-border-width-s u-display-flex u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column"
  >
    <div class="u-width-70">
      <div class="u-display-flex u-flex-align-items-center">
        <rb-icon
          class="u-color-grey-lighter"
          icon="alerts"
        />
        <span class="u-font-size-5 u-spacing-ml-xxs">{{ title }}</span>
      </div>
      <div class="u-font-size-6 u-color-grey-lighter u-spacing-mt-xs">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightAlertInfo',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
