import transformer from '@/utils/services/data-transformer';
import Vue from 'vue';

const customCellComponent = Vue.extend({
  props: {
    cellValue: {
      type: String,
      default: ''
    }
  },
  template:
    '<div class="u-color-grey-base u-text-overflow-ellipsis" :title="cellValue">{{cellValue}}</div>'
});

const config = {
  localStoreageFilterKey: 'campaign-lists-view-custom-entity',
  search: {
    placeholder: 'Search for a Campaign',
    dimensionName: 'search'
  },
  APIConfig: {
    brandsRequest: {
      cubeName: 'ams_campaigns_campaign_workbench',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: [],
      groupByDimensionsList: ['campaign_name', 'campaign_id'],
      orderByList: [
        {
          dimension: 'campaign_name',
          direction: 'ASC'
        }
      ]
    },
    tagType: 'custom',
    customType: 'Custom',
    entityType: 'campaign',
    paginationRequest: {
      limit: 20,
      offset: 0
    }
  },
  tableConstants(that) {
    return {
      gridApi: {
        context: {
          componentParent: that
        },
        rowClassRules: {},
        defaultColDef: {
          wrapText: true,
          autoHeight: true
        }
      },
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      }
    };
  },

  generateColumns(that) {
    const colDefs = this.columnDefination.map((column, index) => {
      let columnMetaObject = {};
      const columnDefinition = {
        name: column.dimensionColumn,
        uiField: {
          uiLabel: column.dimensionLabel,
          uiOrder: index + 1,
          uiType: 'custom',
          metadata: {
            tableColumnName: column.dimensionColumn,
            showOnUi: true
          }
        }
      };
      columnMetaObject = this[column.dimensionColumn](column, that);
      columnDefinition.uiField.metadata = {
        ...columnDefinition.uiField.metadata,
        ...columnMetaObject
      };
      return columnDefinition;
    });
    const columnDefs = transformer.getColumnDefinition(colDefs);
    return columnDefs.displayColConfigs;
  },

  tag(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      dynamicCellComponentParams: {
        componentName: 'TagsViewCell',
        paramsToProps: (params) => {
          return {
            allSuggestionEntities: [],
            tagsList: params.data.tags,
            class: 'u-flex-wrap-yes',
            enableStyleApi: false,
            dropdownOptions: [
              { title: 'Manage campaign list', key: 'manage_campaign_list' },
              { title: 'Remove campaign list', key: 'remove_campaign_list' }
            ]
          };
        },
        eventMap: {
          onDropdownOptionSelect: 'customCampaignListDropdown'
        }
      },
      width:
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) - 416
    };
  },

  campaign_name(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      width: 400,
      dynamicCellComponentParams: {
        componentName: customCellComponent,
        paramsToProps: (params) => {
          return {
            cellValue: params.data.campaign_name
          };
        }
      }
    };
  },
  columnDefination: [
    {
      dimensionLabel: 'Campaign Name',
      dimensionColumn: 'campaign_name'
    },
    {
      dimensionLabel: 'Campaign List',
      dimensionColumn: 'tag'
    }
  ]
};

export default config;
