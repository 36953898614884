<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <div
      v-if="selections.length > 1"
      class="fillMessage fill--parent u-font-size-3"
    >
      {{
        config.fillTextOnMultipleSelections || 'Multiple Items are not allowed'
      }}
    </div>
    <!-- Loader -->
    <div
      v-if="isLoading"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="isLoading"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-font-weight-600 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        {{ config.headerText ? config.headerText() : 'Change Campaign Name' }}
      </div>
      <div class="u-font-size-5 u-font-weight-600">
        {{ config.subHeaderText ? config.subHeaderText : 'Campaign Name:' }}
      </div>
      <div>
        <rb-input
          ref="currentItemRef"
          v-model="currentItemName"
          type="text"
          class="u-font-size-2 u-spacing-mt-s entity-name-input"
        />
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-pv-m u-spacing-ph-l u-display-flex"
    >
      <div
        v-if="isLeftBtnDisabled()"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="isLeftBtnDisabled() ? tooltipErrorMessage : ''"
      >
        <rb-button
          :text="config.leftBtnLabel || 'Apply'"
          type="filled"
          :click-fn="onLeftBtnClick"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        :text="config.leftBtnLabel || 'Apply'"
        type="filled"
        :click-fn="onLeftBtnClick"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        :text="config.rightBtnLabel || 'Cancel'"
        :click-fn="onRightBtnClick"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
export default {
  name: 'NameChangePanel',
  components: {
    loader
  },
  props: {
    widgetsConfig: {
      type: Function,
      default: () => {
        return () => ({});
      }
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      isLoading: false,
      config: null,
      currentItemName: '',
      alreadyPresentItemNames: [],
      tooltipErrorMessage: ''
    };
  },
  computed: {
    defaultItemName() {
      if (this.config?.defaultItemName) {
        if (typeof this.config?.defaultItemName === 'function') {
          return this.config?.defaultItemName();
        }
        return this.config?.defaultItemName;
      }
      return '';
    }
  },
  watch: {
    selections(newVal) {
      if (newVal?.length === 0) {
        this.$emit('closePanelMethod');
      } else if (newVal?.length === 1) {
        this.currentItemName = this.defaultItemName;
        this.$refs.currentItemRef.focus();
      } else {
        this.currentItemName = '';
        this.$refs.currentItemRef.blur();
      }
    },
    currentItemName() {
      this.checkIfItemAlreadyExists();
    }
  },
  async created() {
    this.initializeConfig();
    if (this.config?.getAlreadyPresentItemNames) {
      try {
        this.isLoading = true;
        this.alreadyPresentItemNames =
          await this.config?.getAlreadyPresentItemNames();
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.alreadyPresentItemNames = [];
    }
    this.currentItemName = this.defaultItemName;
  },
  mounted() {
    this.$refs.currentItemRef.focus();
  },
  methods: {
    initializeConfig() {
      this.config = this.widgetsConfig(this);
    },
    isLeftBtnDisabled() {
      if (this.config?.isLeftBtnDisabled) {
        return this.config?.isLeftBtnDisabled(this);
      }
      return false;
    },
    checkIfItemAlreadyExists() {
      if (this.config?.checkIfItemAlreadyExists) {
        const doesItemAlreadyExists = this.config?.checkIfItemAlreadyExists(
          this.currentItemName,
          this.alreadyPresentItemNames
        );
        if (doesItemAlreadyExists === true) {
          this.tooltipErrorMessage =
            this.config?.itemNameAlreadyExistErrorText ||
            'Campaign name already exists';
        } else {
          this.tooltipErrorMessage = '';
        }
      }
    },
    async onRightBtnClick() {
      try {
        this.isLoading = true;
        this.config?.onRightBtnClick?.();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
      this.$emit('closePanelMethod');
    },
    async onLeftBtnClick() {
      try {
        this.isLoading = true;
        await this.config?.onLeftBtnClick?.();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.entity-name-input {
  width: 30%;
}
</style>
