<template>
  <div
    class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left"
  >
    <div
      v-tippy="tippy"
      class="u-text-overflow-ellipsis u-width-100 u-display-flex u-flex-align-items-center"
      :style="`height:${cellHeight}px`"
      :title="errorText"
    >
      <span class="u-color-red-base white-space-normal">
        {{ errorText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FailedErrorTextTableCell',
  props: {
    errorText: {
      default: 'Error',
      type: String
    },
    cellHeight: {
      type: Number,
      default: 50
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          placement: 'bottom',
          arrow: false,
          distance: 8,
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }
        };
      }
    }
  }
};
</script>

<style lang="css" scoped>
.white-space-normal {
  white-space: normal !important;
}
</style>
