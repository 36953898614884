import { targetRoutes } from '@/router/target.js';
import { filters } from '@ciq-dev/ciq-ui';

const shiptCriteoRoutes = {
  ...targetRoutes,
  path: '/us/shipt_criteo/retail',
  children: [...targetRoutes.children]
};

shiptCriteoRoutes.children.shift();
shiptCriteoRoutes.children = [
  {
    path: '',
    name: 'Home',
    redirect() {
      const shiptCriteoMarketInsightsConfig = filters.config_check(
        'feature.insights.shipt_criteo_super_sov_v2'
      );
      if (shiptCriteoMarketInsightsConfig.enable) {
        return 'digital_shelf/market_insights';
      }
      return 'advertising/campaign_management';
    }
  },
  ...shiptCriteoRoutes.children
];

export { shiptCriteoRoutes };
