<template>
  <div
    class="u-border-color-white-base u-border-all u-border-radius-m u-spacing-ph-m u-bg-color-yellow-xx-light u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-mh-m"
  >
    <div class="u-display-flex u-flex-direction-row">
      <div class="u-spacing-pt-xs">
        <rb-icon
          class="rb-icon--x-medium u-color-orange-base u-display-flex u-spacing-mr-xs"
          icon="bell"
        />
      </div>
      <div class="u-display-flex u-flex-direction-column u-spacing-pv-s">
        <span class="u-font-size-6 u-font-weight-600 u-spacing-pb-s"
          >A new version of Market Share dashboard with enhanced capabilities is
          now live. Please note, the old version will be discontinued by
          <span class="u-color-orange-base">September 30, 2024.</span>
        </span>
        <span class="u-font-size-6 u-font-weight-400"
          >You can toggle between versions until then.</span
        >
      </div>
    </div>
    <splitTabs
      class="u-display-flex u-flex-align-items-center"
      :tabs-list="tabsList"
      :active-tab="selectedTab"
      :is-tabs-list="true"
      @tabClicked="updateToggleVersion"
    />
  </div>
</template>

<script>
import splitTabs from '@/components/widgets/splitTabs';
import {
  toggleVersion,
  msProConstants
} from '@/components/pages/insights/amazon/market-share/utils';

export default {
  components: {
    splitTabs
  },
  props: {
    selectedTab: {
      type: String,
      default: msProConstants.oldVersion
    }
  },
  data() {
    let oldVersion, newVersion;
    oldVersion = msProConstants.oldVersion;
    newVersion = msProConstants.newVersion;
    return {
      tabsList: msProConstants.versionList,
      oldVersion,
      newVersion,
      tabListMapping: {
        [oldVersion]: false,
        [newVersion]: true
      }
    };
  },
  methods: {
    updateToggleVersion(updateVal) {
      toggleVersion(this.tabListMapping[updateVal]);
      this.$nextTick(() => {
        window.location.reload();
      });
    }
  }
};
</script>
