<template>
  <div class="shortage-fallback">
    <fallback-ui
      :title="title"
      icon="delete"
    >
      <div slot="modal-body">
        <div
          class="shortage-fallback-container u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-color-grey-xlight"
        >
          <div
            class="u-font-size-xxl u-font-weight-600 u-spacing-mb-xxxxl u-font-style-normal u-color-x-grey-base"
          >
            Remove Page Permanently
          </div>
          <div
            class="sub-heading u-text-align-center u-font-size-3 u-font-weight-normal u-font-style-normal u-color-x-grey-base"
          >
            Are you sure about not seeing information about
            <span class="u-font-weight-600">Shortage Invoice Dashboard</span>
            and many more.
          </div>
          <div class="u-display-flex">
            <div class="u-spacing-pr-m">
              <rb-button
                text="Cancel"
                type="hollow"
              />
            </div>
            <div>
              <button
                class="remove-page"
                @click="onRemoveHandler"
              >
                Remove page
              </button>
            </div>
          </div>
        </div>
      </div>
      <div slot="fallback-body">
        <div
          class="u-spacing-pv-m u-spacing-ph-l color u-display-flex u-flex-justify-content-space-between notification"
        >
          <div class="start">
            <span
              ><rb-icon
                class="u-spacing-pr-xs u-color-orange-base"
                icon="lock"
            /></span>
            <span>Looks like you haven't subscribed to this solution yet.</span>
          </div>
          <div
            v-if="isAlreadyNotified === 'true'"
            class="u-display-flex u-flex-align-items-center u-text-align-left u-font-size-6 u-font-weight-600 u-font-style-normal"
          >
            <span>
              <rb-icon
                icon="check-fill-circle "
                class="u-spacing-pr-xs"
            /></span>
            <p>Account executive is notified about your interest.</p>
          </div>
          <div v-else>
            <span
              class="not-notified u-display-flex u-flex-align-items-center u-text-align-left u-font-size-6 u-font-weight-600 u-font-style-normal"
              @click="sendNotification"
            >
              <span>
                <rb-icon
                  icon="chat"
                  class="u-spacing-pr-xs"
                />
              </span>
              <p>Notify account executive about your interest</p>
            </span>
          </div>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items envelops-width"
        >
          <div class="shortage-fallback-body-container">
            <div
              class="title u-spacing-mr-m u-spacing-mb-m u-text-align-left u-font-weight-600 u-font-style-normal u-color-x-grey-base u-spacing-mt-xl"
            >
              <p>Key Features</p>
            </div>
            <div>
              <div
                v-for="(image, index) in images"
                :key="index"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start u-flex-direction-column"
              >
                <div class="u-width-100">
                  <p
                    class="image-heading u-width-100 u-text-align-left u-font-weight-600 u-font-style-normal u-spacing-pb-s"
                  >
                    {{ image.imageHeading }}
                  </p>
                  <p
                    class="image-text u-width-100 text-color u-text-align-left u-font-weight-normal u-font-style-normal"
                  >
                    {{ image.imageSubtext }}
                  </p>
                  <div>
                    <img
                      class="u-width-100"
                      :src="image.imagepath"
                      style="
                        margin-bottom: 40px;
                        box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.2);
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fallback-ui>
  </div>
</template>

<script>
import FallbackUi from './fallback-ui.vue';
import HttpService from '@/utils/services/http-service';

export default {
  components: {
    FallbackUi
  },
  data() {
    return {
      statements: [
        'Track Share of Voice as the single, uniform metric to track business health across retailers',
        'Identify key digital shelves and track major competitors',
        'Identify opportunities for share of voice growth and ad investment',
        'Will be available across all major retailers - Amazon, Walmart, Target, Kroger and Home Depot'
      ],
      isAlreadyNotified:
        this.$ciqlocalStorage.getItem('isAlreadyNotified') || null,
      notificationEvent: 'shortages-fallback-notification',
      title: 'Shortage Invoice Report',
      images: [
        {
          imagepath: '/images/Wid0.png',
          imageHeading: 'Performance Overview',
          imageSubtext:
            'Get visibility of progress real-time for a range of metrics including Shortage Invoices Disputed, In-progress, Accepted and Denied by Amazon and their impact as a %age of Shipped COGS'
        },
        {
          imagepath: '/images/Wid1.png',
          imageHeading: 'Top Contributors ',
          imageSubtext:
            'Identify the top drivers driving shortages at ASIN, FCs and PO level'
        },
        {
          imagepath: '/images/Wid2.png',
          imageHeading: 'Shortage Rate Impact by Combinations',
          imageSubtext:
            'Flexibility across the dashboard to view, slice and dice the data to get to the root cause of issues to have a more permanent and long-term fix for these recurring deductions.'
        },
        {
          imagepath: '/images/Wid3.png',
          imageHeading: 'Summary of Purchase Order with Shortages Invoices',
          imageSubtext:
            'Option to download the raw data with complete details like Shortage Invoice No. PO No., Dispute ID, Remittance ID etc. for further analysis '
        }
      ]
    };
  },
  created() {
    if (this.$ciqlocalStorage.getItem('isAlreadyNotified') === null) {
      this.$ciqlocalStorage.setItem('isAlreadyNotified', false);
    }
    if (this.$ciqlocalStorage.getItem(this.notificationEvent) === null) {
      this.$ciqlocalStorage.setItem(this.notificationEvent, true);
    }
  },
  methods: {
    onRemoveHandler() {
      if (this.$ciqlocalStorage.getItem(this.notificationEvent)) {
        this.$ciqlocalStorage.setItem(this.notificationEvent, false);
        const currentHostName = window.location.hostname;
        const [reigon, retailer, retail] = window.location.pathname
          .split('/')
          .slice(1);
        location.replace(
          `http://${currentHostName}/${reigon}/${retailer}/${retail}/business_overview/1`
        );
      }
    },
    async sendNotification() {
      this.$ciqlocalStorage.setItem('isAlreadyNotified', true);
      this.isAlreadyNotified =
        this.$ciqlocalStorage.getItem('isAlreadyNotified');
      const requestData = {
        email: window.user.email,
        subject: 'Interested In Shortages Dashboard',
        to: 'support@commerceiq.ai',
        template: `<span>User has expressed interest in subscribing to Shortages Dashboard.</span><br/><br/><span>Client: ${window.location.hostname} </span><br/><br/><span>Email: ${window.user.email} </span>`
      };
      await HttpService.post('SEND_MAIL_NOTIFICATION', requestData);
    }
  }
};
</script>
<style lang="css" scoped>
.title-bar {
  padding-top: 8px !important;
  position: sticky;
}
.notification {
  position: sticky;
  top: 40px;
  z-index: 5;
}
.u-font-size-xxl {
  font-size: 32px;
}
.u-color-grey-xlight {
  color: #8b8f93;
}
.u-color-x-grey-base {
  color: #2b333b;
}
.sticky {
  margin-bottom: 0px !important;
}
.highlights {
  color: #4b5158;
}
.u-spacing-mt-xl {
  margin-top: 40px;
}

.title {
  font-size: 18px;
}
.sub-title {
  font-size: 14px;
  line-height: 1.3;
}
.text-color {
  color: #6a7075;
}
.shortage-fallback-container {
  min-height: 285px;
  padding: 56px;
}
.shortage-fallback-container .rb-button--hollow {
  border: 1px solid #007cf6 !important;
  width: 100px;
}
.shortage-fallback-container .rb-button--hollow .rb-button__text {
  color: #007cf6;
}

.icon-check-fill-circle {
  color: #3ea95e;
}

.icon-check-fill-circle:before {
  font-size: 16px !important;
}
.image-heading {
  color: #007cf6;
  font-size: 14px;
}
.ellipsis {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-grow: 0;
  background-color: #007cf6;
  margin-bottom: 16px;
  margin-right: 8px;
}

.image-text {
  width: 752px;
  line-height: 1.3;
  margin-bottom: 16px !important;
  font-size: 14px;
}
.shortage-fallback-body-container {
  background-color: #fff;
  min-width: 800px;
  padding: 0px 24px 40px 24px;
  height: 100%;
}
.start {
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #2b333b;
  display: flex;
  align-items: center;
}

.envelops-width {
  max-width: 550px;
  margin: 0 auto;
  background-color: #f5f7fa;
}
.u-spacing-mt-xlx {
  margin-top: 40px;
}
.color {
  background-color: #fff6e5;
}
.not-notified {
  color: #007cf6;
}
.not-notified .icon-chat:before {
  position: relative;
  top: 2px;
  font-size: 16px !important;
}
.not-notified:hover {
  cursor: pointer;
}

.u-spacing-mb-xxxxl {
  margin-bottom: 32px !important;
}
.u-spacing-p-extra-large {
  padding: 56px 56px 56px 56px;
}
.remove-page {
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  font-stretch: normal;
  padding: 10px 32px;
  border-radius: 4px;
  font-style: normal;
  color: #fff;
  border: none;
  background-color: #d7263d;
}
.remove-page:hover {
  cursor: pointer;
}

.sub-heading {
  width: 441px;
  margin-bottom: 24px;
}
.danger {
  background-color: #d7263d;
}
</style>
<style lang="css">
.shortage-fallback .modal {
  background-color: rgba(43, 51, 59, 0.4) !important;
}
.shortage-fallback .modal .u-spacing-ph-xxl {
  padding: 0px !important;
}
.shortage-fallback .modal .u-spacing-pb-xxl {
  padding: 0px !important;
}
</style>
