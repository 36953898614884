<template>
  <div
    class="u-flex-1 u-display-flex u-position-relative"
    :class="isLoadingWidgetConfig ? 'u-viewport-height' : ''"
  >
    <div
      v-if="!isLoadingWidgetConfig"
      class="u-width-100 u-height-auto"
    >
      <div class="u-spacing-pt-l u-display-flex u-flex-direction-column card">
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-l u-flex-align-items-center"
        >
          <div
            class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-text-case-upper"
          >
            Performance Overview
          </div>
          <div class="u-display-flex">
            <div
              v-if="!enableRollupInBottom"
              class="u-display-flex u-flex-align-items-center"
            >
              <roll-up-by
                label="Roll up by:"
                :value="selectedRollUpValue"
                :options="computedRollUps"
                :disabled="isChartLoading"
                @onRollUpChange="getChartDataWithRollUp"
              />
            </div>
            <!-- v-tracker:click="`{type: 'Download', page: ${page}, pageIdentifier: ${skuID}, action: 'Click', eventType: 'Click', agent: 'browser'}`" -->
            <div
              class="u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-ml-m"
            >
              <span
                v-tippy="{ placement: 'top', arrow: false }"
                title="Downloads an Excel with the day level data of all the metrics selected on the performance overview section."
                @click="downloadReport"
              >
                <rb-icon
                  v-if="!isDownloadLoading && !isDownloadError"
                  class="rb-icon u-color-grey-lighter icon-download"
                  :icon="'download'"
                />
              </span>
              <loader
                :loading="isDownloadLoading"
                class=""
                :color="'#007cf6'"
              />
              <rb-icon
                v-if="isDownloadError"
                class="rb-icon u-color-red-base"
                :icon="'download'"
              />
            </div>
          </div>
        </div>
        <div class="u-position-relative u-display-flex u-spacing-ph-l">
          <div
            v-show="!isLoadingWidgetData"
            class="u-width-100"
          >
            <chart-with-legends-v2
              :key="chartKey"
              :show-custom-tooltip="true"
              :chart-data="formattedChartData"
              :chart-config="computedChartConfig"
              :group-by-config="groupByConfig"
              :metrics-list="metricList"
              :metrics-shown="selectedValues.metricsShown"
              :default-select-metric="selectedValues.selectedMetric"
              :metric-config="selectedValues.metricDisplayConfig"
              :metric-data="selectedValues.metricData"
              :pvp-metric-data="selectedValues.pvpMetricData"
              :enable-add-event="false"
              class="u-spacing-mt-l"
              :selected-metric-limit="
                isGroupByToggled ? 1 : selectedMetricLimit
              "
              :disable-add-new-metric="disableAddNewMetric"
              :is-chart-loading="
                isChartLoading || groupByConfig.isGroupByMetricLoading
              "
              :emit-on-metric-default-change="true"
              :plot-on-y-axis-only="true"
              :show-powered-by-c-i-q-text="true"
              :custom-chart-class="[]"
              :minimum-metric="6"
              :enable-watefall="true"
              :metric-font-size="'u-font-size-4'"
              :metric-tooltip-one-liner="false"
              metric-tooltip-theme="light"
              :note-generator-metric-tooltip="noteGeneratorMetricTooltip"
              :enable-p-v-p-timeseries="enablePVPTimeseries"
              :pvp-chart-data="formattedPVPChartData"
              :pvp-date-text="pvpDateText"
              :restrict-single-metric-deselection="
                enableGroupByTimeseries || enablePVPTimeseries
              "
              :metrics-tooltip-format="metricsTooltipFormat"
              :is-group-by-chart="isGroupByChart"
              :is-group-by-selected="enableGroupByTimeseries"
              :group-by-chart-data="groupByChartData"
              :group-by-p-v-p-chart-data="groupByPVPChartData"
              @selectedMetricList="metricChanged"
              @addMetric="addMetric"
              @groupByChanged="groupByChanged"
              @groupByMetricChanged="groupByMetricChanged"
            >
              <div
                v-if="
                  showPlotTrendlineToggle &&
                  chartConfigurationOptions.length &&
                  chartConfigurationOptions.length
                "
                slot="space-between-legend-and-chart"
                class="u-spacing-mt-l"
              >
                <dynamic-component-composer
                  :key="pvpKey"
                  :child-components="chartConfigurationOptions"
                  :container-styles="{ display: 'flex' }"
                />
              </div>
              <div
                slot="postCustomMetric"
                slot-scope="{ value }"
                class="u-width-100"
              >
                <div
                  class="u-color-grey-lighter u-display-flex u-flex-align-items-flex-end u-spacing-mt-s"
                >
                  <metric
                    v-if="value.metricData || {}"
                    :size="'xml'"
                    class="u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-start"
                    :config="value.metricConfig || {}"
                    :data="getMetricData(value)"
                    :split="true"
                    :split-clear-padding="true"
                  >
                    <template #postTag1Metric>
                      <div
                        v-if="
                          isAllowedMetric(value.metric.name) &&
                          !categorisationSelected
                        "
                        class="u-display-flex u-flex-align-items-center u-spacing-ml-xs"
                      >
                        <rb-icon
                          class="rb-icon--medium u-color-orange-base u-spacing-mr-xs"
                          icon="warning"
                        />
                        <div
                          class="u-font-size-6 action--text"
                          style="
                            text-decoration: underline;
                            text-decoration-style: dotted;
                          "
                          @click.stop="dispatchFilterApply(value, $event)"
                        >
                          MS Category filter is required
                        </div>
                      </div>
                      <div>
                        <customTippy
                          v-if="showNAIcon(value)"
                          id="customNaTooltip"
                          title="Data could be unavailable because of any one of the following reasons:"
                          :one-liner="false"
                          style="max-width: '10px'"
                          :text="naTooltipText"
                        />
                        <rb-icon
                          v-if="showNAIcon(value)"
                          v-tippy="{
                            placement: 'bottom',
                            arrow: false,
                            theme: 'light',
                            reactive: true,
                            interactive: true,
                            html: '#customNaTooltip'
                          }"
                          class="rb-icon--x-medium u-color-grey-lighter u-spacing-ml-m action--text"
                          icon="help-fill"
                          :title="value.metric.metadata.naTooltip"
                        />
                      </div>
                    </template>
                  </metric>
                  <IQTooltip
                    v-if="value.metric.enableIqTooltip"
                    :data="value.metric.iqTooltip"
                  />
                  <div
                    v-tippy="{ placement: 'right', arrow: false }"
                    :title="tooltipViewTopMovers(value)"
                    :class="{ 'disable-top-mover': disableTopMovers(value) }"
                    @click.stop="dispatchStoreAction(value)"
                  >
                    <rb-icon
                      class="rb-icon--medium u-cursor-pointer u-color-grey-lighter"
                      :icon="'top_movers'"
                    />
                  </div>
                </div>
              </div>
            </chart-with-legends-v2>
          </div>
          <div v-if="isLoadingWidgetData">
            <shimmer
              v-if="showShimmerLoader && isLoadingWidgetData"
              class="u-spacing-mb-l"
              height="330px"
              width="100%"
            />
            <loader
              class="fill--parent u-bg-color-grey-white"
              :loading="isLoadingWidgetData"
              :color="'#007cf6'"
              :style="{
                height: groupByConfig.isGroupByMetricLoading ? '550px' : '450px'
              }"
            />
          </div>
        </div>
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start card-footer u-spacing-ph-m u-spacing-pv-s u-border-top u-border-color-grey-xxx-light u-color-grey-x-light u-border-width-s u-font-size-6"
        >
          <!-- <span>Metrics data is available until <span class="u-color-grey-dark"> {{ formatter(metricEventConfig.calendar && metricEventConfig.calendar.max_date, 'date') }}</span></span> -->
          <!-- <span class="divider-footer" /> -->
          <span class="u-display-inline-flex u-spacing-ml-s">
            <span
              v-if="page === 'skuDetails'"
              class="u-color-grey-dark"
            >
              {{
                formatter(
                  (metricEventConfig.eventCalendar &&
                    metricEventConfig.eventCalendar.max_date) ||
                    metricEventConfig.calendar.max_date,
                  'date'
                )
              }}
            </span>
            <span
              v-else-if="isBOPage(page)"
              class="u-color-grey-dark u-spacing-pr-s"
            >
              Data is available until
              {{ formatter($store.getters.getGlobalMaxDate, 'date') }}
            </span>
            <MetricRefreshDatesView
              v-if="
                $store.getters.getHotMetricRefreshDates('Business-Overview')
                  .length
              "
              refresh-page-id="Business-Overview"
            />
          </span>
        </div>
      </div>
    </div>
    <div v-if="isLoadingWidgetConfig">
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="isLoadingWidgetConfig"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>
<script>
import widgetConfig from '@/components/pages/skuDetails/widget.js';
import moment from 'moment';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';
import chartWithLegendsV2 from '@/components/basic/chart-with-legends-v2.vue';
import groupbyChartLegends from '@/components/basic/groupby-chart-legends.vue';
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import MetricRefreshDatesView from '@/components/basic/metric-refresh-dates';
import logger from '@/components/pages/skuDetails/logger';
import _ from 'lodash';
import { formatter } from '@/utils/helpers/formatter.js';
import Vue from 'vue';
import metric from '@/components/basic/metric';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import IQTooltip from '@/components/pages/businessInsights/IQTooltip.vue';
import utils from '@/utils/helpers/';
import { eventBus } from '@/utils/services/eventBus';
import {
  filterSelected,
  isAllowedMetric,
  categorisationSelected,
  featureEnabled,
  waterfallName
} from '@/components/pages/businessInsights/ms-on-bo';
import customTippy from '@/components/pages/insights/amazon/share-of-voice/atoms/customTippy.vue';
import RollUpBy from '@/components/globals/rollUpBy.vue';
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import { klona } from 'klona/json';
import { provide, ref } from '@vue/composition-api';
import CONSTANTS from '@/utils/constants.js';
import { isBOPage } from '@/utils/helpers/utils.js';

export default {
  name: 'PerformaceOverview',
  author: 'Rajan V',
  components: {
    rbDownloadButton,
    dateRange,
    chartWithLegendsV2,
    groupbyChartLegends,
    loader,
    metric,
    IQTooltip,
    MetricRefreshDatesView,
    customTippy,
    RollUpBy,
    DynamicComponentComposer
  },
  setup() {
    const shownGroupByList = ref(null);
    provide('shownGroupByList', shownGroupByList);
    return {
      shownGroupByList
    };
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    skuID: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    },
    isPVPTimeseriesChart: {
      type: Boolean,
      default: true
    },
    isDefaultView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pvpKey: 0,
      groupBySelectedMetric: [],
      shownMetricList: [],
      selectedGroupBy: null,
      chartKey: 0,
      groupByAPIData: {},
      groupByChartData: {},
      naTooltipText:
        "<b>1.</b> Date range selected is too short: Market Share is computed for a week (Sun-Sat) and hence data is available only when the date range selection contains at least one complete week. <br/> <b>2.</b> Selected date range doesn't have Market share data. For Example: Market share computation started from January 2022 but the date range selected is upto December 2021. <br/> <b>3.</b> Partial Data availability i.e. Market Share data is unavailable for the entire date range. For example: Market Share data is available only for last 4 weeks but date range selected is 8 weeks.",
      _skuID: '',
      metadata: {},
      isGroupByToggled: false,
      isLoadingWidgetConfig: false,
      isLoadingWidgetData: false,
      isChartLoading: false,
      isLoading: true,
      metricEventConfig: {},
      metricList: [],
      eventList: [],
      templateList: [],
      viewMetricMapping: {},
      nosOfMetricShown: 3,
      nosOfEventShown: 3,
      dateRanges: [
        'Last 7 days',
        'Last 30 days',
        'Last 90 days',
        'Last Week',
        'Last Month',
        'Last Quarter'
      ],
      selectedValues: {
        template: 'Sales View',
        report_date: [],
        minDate: moment().format('MM/DD/YYYY'),
        maxDate: moment().format('MM/DD/YYYY'),
        metricData: {},
        eventData: {},
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: [],
        eventsShown: [],
        selectedEvents: [],
        date: {},
        pvpDate: {},
        reportDateMapping: {},
        pvpReportDateMapping: {},
        chartTooltip: {}
      },
      downloadComponent: {
        tempMetricObj: {},
        tempEventObj: {}
      },
      defaultRangeSelect: 'last30Days',
      chartData: {
        data: []
      },
      isDownloadLoading: false,
      isDownloadError: false,
      logging: {
        selectedMetric: [],
        selectedEvents: []
      },
      customViewMetrics: {
        metricsShown: [],
        eventsShown: []
      },
      localStorageKey: null,
      saveSelectionKey: 'SDP-saveSelection',
      dateRangeSelected: null,
      disableAddNewEvent: true,
      maxMetricListItem: 12,
      disableAddNewMetric: false,
      rollUps: [
        { name: 'Days', value: 'DAY', diff: 'day', key: 'DAY' },
        { name: 'Weeks', value: 'WEEK', diff: 'week', key: 'WEEK' },
        { name: 'Months', value: 'MONTH', diff: 'month', key: 'MONTH' }
      ],
      selectedRollUp: {},
      selectedMetricLimit: 4,
      mertricIconConfig: {
        icon: 'top_movers',
        storeAction: 'topMoversMeasureChange'
      },
      enablePVPTimeseries: false,
      pvpDateText: '',
      pvpChartData: {
        data: []
      },
      pvpChartConfig: {
        chartOptions: {
          legend: false,
          type: 'line',
          tooltip_label_text: '',
          xAxisType: 'category',
          tooltip_format: {},
          show_axis_colors: false,
          axis_format: {
            y: {
              tick: {
                count: 5
              }
            }
          },
          xDataKey: 'x',
          grid: 'xy'
        },
        stack: [],
        chartGetter: '',
        xAxisType: 'category',
        lastUpdatedDate: null
      },
      metricsTooltipFormat: {},
      enableRollupInBottom: false,
      isBOPage: isBOPage,
      dataGroups: {},
      enableGroupByTimeseries: false,
      groupByPVPChartData: { data: [] },
      isGroupByChart: false,
      groupByChartConfig: {
        chartOptions: {
          legend: false,
          type: 'line',
          xFormat: '%m/%d/%Y',
          timeseries: 'feed_date',
          tooltip_label_text: '',
          tooltip_format: {},
          show_axis_colors: false,
          axis_format: {
            y: {
              tick: {
                count: 5
              }
            }
          },
          xDataKey: 'x',
          grid: 'xy'
        }
      },
      groupByConfig: {},
      reqParams: [],
      forceRefetchData: false,
      response: [],
      validResponse: [],
      validGroupByResponse: [],
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      }
    };
  },
  computed: {
    chartConfigurationOptions() {
      const configurationOptions = [];
      // pvpTimeseriesSwitch
      if (this.isPVPTimeseriesChart) {
        const enablePVPTimeseriesValue =
          this.enablePVPTimeseries ||
          this?.metadata?.metadata?.defaultConfig?.enablePVPTimeseries ||
          false;
        configurationOptions.push({
          ui_component: 'pvpTimeseriesSwitch',
          props: {
            disabled: this.isGroupByToggled,
            value: enablePVPTimeseriesValue,
            label: this.getPVPTimeseriesLabel()
          },
          events: {
            onSwitchChange: this.timeseriesDimensionChanged
          }
        });
      }

      // rollup
      if (this.enableRollupInBottom) {
        configurationOptions.push({
          ui_component: 'RollUpBy',
          props: {
            label: 'Roll up by:',
            value: this.selectedRollUpValue,
            options: this.computedRollUps,
            varHeight: 24
          },
          events: {
            onRollUpChange: this.getChartDataWithRollUp
          }
        });
      }

      return configurationOptions;
    },
    filterSelected() {
      return filterSelected(this.$store);
    },
    categorisationSelected() {
      return categorisationSelected(this.$store);
    },

    formattedChartData() {
      const data = { ...this.chartData };
      // data.xs = {
      //   'Ordered Product Sales - Current':'feed_date_Ordered Product Sales - Current':
      // }
      if (this.isGroupByToggled) {
        return this.groupByChartData;
      }
      return data;
    },
    formattedPVPChartData() {
      if (!this.enablePVPTimeseries) {
        return {};
      }
      const data = { ...this.pvpChartData };
      return data;
    },
    computedChartConfig() {
      let chartConfig = this.chartConfig;
      if (this.enablePVPTimeseries) chartConfig = this.pvpChartConfig;
      if (this.enableGroupByTimeseries) chartConfig = this.groupByChartConfig;
      if (this.isGroupByToggled) {
        return this.groupByChartConfig;
      }
      return chartConfig;
    },
    computedEventsShown() {
      return [...this.selectedValues.eventsShown];
    },
    computedDefaultSelectEvents() {
      return [...this.selectedValues.selectedEvents];
    },
    computedMetricShownConfig() {
      return { ...this.selectedValues.metricDisplayConfig };
    },
    computedRollUps() {
      const date = this.$store.getters.getWhereClause.date;
      let rollUps = [...this.rollUps];
      if (
        date.from &&
        date.to &&
        Math.abs(
          moment(date.from, 'YYYY-MM-DD').diff(
            moment(date.to, 'YYYY-MM-DD'),
            'days'
          )
        ) < 13
      ) {
        // remove the rollups by week
        rollUps = rollUps.filter(
          (item) => item.value !== 'MONTH' && item.value !== 'WEEK'
        );
      } else if (
        date.from &&
        date.to &&
        Math.abs(
          moment(date.from, 'YYYY-MM-DD').diff(
            moment(date.to, 'YYYY-MM-DD'),
            'days'
          )
        ) < 59
      ) {
        // remove the rollups by month
        rollUps = rollUps.filter((item) => item.value !== 'MONTH');
      }
      return [...rollUps];
    },
    showPlotTrendlineToggle() {
      return (
        CONSTANTS.plotTrendlineSupportedRetailers.includes(
          this.$store.getters.getRetailer
        ) ||
        CONSTANTS.plotTrendlineSupportedRegions.includes(
          this.$store.getters.getLocation
        )
      );
    },
    isRetailerAmazonHybrid() {
      return this.$store.getters.getRetailer === 'amazon_hybrid';
    },
    performanceWbStoreValues() {
      return this.$store.getters.getPerformanceWbSaveViewData;
    },
    selectedRollUpValue() {
      return this.selectedRollUp?.key || 'DAY';
    }
  },
  watch: {
    'logging.selectedMetric': {
      handler(newValue) {
        if (!newValue.length) {
          return;
        }
        console.log(newValue);
        if (this.isGroupByToggled) {
          this.fetchGroupBy(
            'BusinessModel',
            newValue[0],
            this.generateReqParams(),
            newValue
          );
        }
      }
    },
    skuID(newValue) {
      this._skuID = newValue;
      this.localStorageKey = `SDP-calendar-web${this._skuID}`;
      this.init();
    },
    performanceWbStoreValues: {
      handler(newValue) {
        if (!newValue?.shownMetricList?.length || !this.isDefaultView) {
          return;
        }
        const updatedNewValue = _.cloneDeep(newValue);

        updatedNewValue.selectedMetric = updatedNewValue.selectedMetric?.filter(
          (metric) => {
            return updatedNewValue.shownMetricList?.includes(metric);
          }
        );

        this.$store.dispatch('updateUserMetadata', {
          type: 'page',
          typeId: 'businessInsights',
          performanceWb: {
            ...updatedNewValue
          }
        });
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this._ = _;
    this.init();
    if (isBOPage(this.page)) {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'SETWHERECLAUSEVALUE') {
          if (this.selectedRollUp.value) {
            const rollUps = utils.getRollUps(
              this.$store.getters.getWhereClause.date
            );
            const itemFound = rollUps.findIndex((item) => {
              return item.value === this.selectedRollUp.value;
            });
            if (itemFound !== -1) {
              this.setUpReportingDate();
              // Check for the date range selection
              if (this.downloadComponent?.tempMetricObj) {
                this.metricChanged(
                  {
                    metricDisplayList:
                      this.downloadComponent?.tempMetricObj
                        ?.metricDisplayList || [],
                    selectedMetric: this.isGroupByToggled
                      ? this.groupBySelectedMetric
                      : this.selectedValues?.selectedMetric
                  },
                  true
                );
              }
            } else {
              const temp_selectedRollUp = this.rollUps.filter((item) => {
                return item.value === 'DAY';
              });
              this.getChartDataWithRollUp(temp_selectedRollUp[0]);
            }
          } else {
            this.setUpReportingDate();
            // Check for the date range selection
            if (this.downloadComponent?.tempMetricObj) {
              this.metricChanged(
                {
                  metricDisplayList:
                    this.downloadComponent?.tempMetricObj?.metricDisplayList ||
                    [],
                  selectedMetric: this.selectedValues?.selectedMetric
                },
                true
              );
            }
          }
        }
      });
      if (this.showPlotTrendlineToggle) {
        this.enableRollupInBottom = true;
      }
    }
    eventBus.$on('updateDefaultViewConfig', () => {
      const userPageMetadata = this.$store.getters.getUserPageMetadata;

      if (userPageMetadata?.page?.['businessInsights']?.performanceWb) {
        this.$store.dispatch('setCompletePageMetadata', {
          shownMetricList:
            userPageMetadata.page.businessInsights.performanceWb
              ?.shownMetricList,
          selectedMetric:
            userPageMetadata.page.businessInsights.performanceWb
              ?.selectedMetric,
          plotCompareTrendLine:
            userPageMetadata.page.businessInsights.performanceWb
              ?.plotCompareTrendLine,
          selectedRollUp:
            userPageMetadata?.page?.businessInsights?.performanceWb
              ?.selectedRollUp
        });
      }
    });
    this.groupByHashingKeysToIgnore = [
      'metricsList',
      'operations',
      'where',
      'entityType'
    ];
  },
  beforeDestroy() {
    this.removeLocalStorageKey(this.localStorageKey);
    this.unsubscribe();
  },
  destroyed() {
    eventBus.$off('updateDefaultViewConfig');
  },
  methods: {
    noteGeneratorMetricTooltip() {
      if (!this.filterSelected) {
        const marketShareFilterRequired = `<hr class="u-color-grey-xxx-light u-border-none metric-tooltip-ruler">
          <div class="u-spacing-ph-m u-font-size-6 u-font-weight-400 u-spacing-ph-m u-font-size-6 u-font-weight-400 u-display-flex u-flex-align-items-center">
            <div class="rb-icon--medium u-color-orange-base u-spacing-mr-xs">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M10,84.0909091 L90,84.0909091 L50,15 L10,84.0909091 Z M53.6363636,73.1818182 L46.3636364,73.1818182 L46.3636364,65.9090909 L53.6363636,65.9090909 L53.6363636,73.1818182 Z M53.6363636,58.6363636 L46.3636364,58.6363636 L46.3636364,44.0909091 L53.6363636,44.0909091 L53.6363636,58.6363636 Z"/>
              </svg>
            </div>
          <b class="u-font-weight-600 u-spacing-mr-xs">Market Share Category Filter</b> is required to view this metric</div>`;
        return marketShareFilterRequired;
      } else {
        return '';
      }
    },
    addMetric(data) {
      if (data?.metadata?.customProperties?.requiredDimensionName) {
        let dimensionNameValueList =
          this.$store.getters.getWhereClause?.['dimensionNameValueList'];
        if (
          !(
            dimensionNameValueList &&
            dimensionNameValueList.length === 1 &&
            dimensionNameValueList[0].dimensionName ===
              data?.metadata?.customProperties?.requiredDimensionName
          )
        ) {
          eventBus.$emit(
            'filterSelect',
            data?.metadata.customProperties.filterSelector
          );
        }
      }
    },
    tooltipViewTopMovers(value) {
      if (this.disableTopMovers(value)) {
        return `View Top Movers for ${value.metric.key} is not available.`;
      } else {
        return `View Top movers for ${value.metric.key}.`;
      }
    },
    disableTopMovers(value) {
      return this.isAllowedMetric(value.metric.name);
    },
    isAllowedMetric(metric) {
      return isAllowedMetric(metric);
    },
    showNAIcon(val) {
      if (
        val?.metric?.metadata?.naTooltip &&
        val?.metricData?.tag1 === null &&
        val?.metricData?.tag2 === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    getMetricData(value) {
      const metric = _.cloneDeep(value);
      const name = metric?.metric?.alias;
      if (
        this.metadata?.excludePvpDisplay?.includes(name) &&
        metric?.metricData?.tag2
      ) {
        metric.metricData.tag2 = 0;
      }
      return metric?.metricData;
    },
    formatter: formatter,
    init() {
      if (isBOPage(this.page)) {
        widgetConfig.chartConfig.chartOptions.size.height = 380;
      }
      this.chartConfig = { ...widgetConfig.chartConfig };
      this.eventsIconMap = { ...widgetConfig.eventsIconMap };
      this.chartData.data = [];
      this.chartData.data.xs = {
        'Ordered Product Sales - Current': 'report_date_ordered_product_sales'
      };
      // fetch the metadata information
      const requests = [];
      this.config.API.forEach((api) => {
        requests.push(
          HttpService.post(
            api.service,
            {
              widget: this.config.widgetName,
              id: this._skuID,
              page: this.page,
              globalViewId: this.config?.globalViewId
            },
            { append: api.endPoint }
          )
        );
      });
      this.isLoadingWidgetConfig = true;
      Promise.all(requests).then(
        (response) => {
          if (!response.success && !Array.isArray(response)) {
            this.isLoadingWidgetConfig = false;
            return;
          }
          if (this.page === 'skuDetails') {
            this.resetCharttingWb();
          }
          // Transforming Metric.
          this.viewMetricMapping = {};
          this.metricList = [];
          this.eventList = [];
          this.metricEventConfig = response[0].data;
          // Setting up the mertic List
          let metricList = this.transformConfig(
            this.metricEventConfig.metrics,
            'metric'
          );

          if (isBOPage(this.page)) {
            // Waterfall changes
            const metricsWithWaterfall =
              response[0].data?.metadata?.groupInfo?.metricsWithWaterfall;
            if (metricsWithWaterfall) {
              for (let waterfallGroupingName in response[0].data.metadata
                .groupInfo.waterfall) {
                if (
                  waterfallName(waterfallGroupingName) &&
                  !featureEnabled(this.$store)
                ) {
                  continue;
                }
                let waterfall =
                  response[0].data.metadata.groupInfo.waterfall[
                    waterfallGroupingName
                  ];
                metricList.push(waterfall);
                metricList = metricList.filter((item) => {
                  if (metricsWithWaterfall[item?.alias]) {
                    item.waterfall = true;
                    return item;
                  }
                  return item;
                });
              }
            }
          }
          this.metricList = [...metricList];
          if (!featureEnabled(this.$store)) {
            this.metricList = this.metricList.filter((metric) => {
              return !isAllowedMetric(metric.alias);
            });
          }
          // Setting up the event List
          const eventList = this.transformConfig(
            this.metricEventConfig.events,
            'event'
          );
          this.eventList = [...eventList];

          if (this.page === 'skuDetails') {
            // Setting up the View
            this.templateList = Object.keys(this.viewMetricMapping).map(
              (key) => {
                return { label: key };
              }
            );

            // Setting up the date
            this.selectedValues.minDate = moment(
              this.metricEventConfig.calendar.min_date,
              'YYYY-MM-DD'
            ).format('MM/DD/YYYY');
            this.selectedValues.maxDate = moment(
              this.metricEventConfig.calendar.max_date,
              'YYYY-MM-DD'
            ).format('MM/DD/YYYY');
            // Set up the calendar after the response of meta data. So that end date for chart will be events end date
            // Set up report date info
            // Setting up the default View
            let element = localStorage.getItem(this.saveSelectionKey);
            if (element) {
              element = JSON.parse(element);
              if (typeof element === 'object' && element !== null) {
                this.selectedValues.template = element.template;
                this.defaultRangeSelect =
                  element.defaultRangeSelect || 'last30Days';
              }
            } else {
              this.selectedValues.template =
                'Sales View' ||
                this.metricEventConfig.metadata.defaultConfig.view;
            }

            const storage = localStorage.getItem(this.localStorageKey);
            if (storage) {
              this.updatedateDateSelection(JSON.parse(storage).date_range);
            }
            this.selectedValues.metricsShown = this.metricList.slice(
              0,
              this.nosOfMetricShown
            );
            // Set the selected Metric Values
            this.selectedValues.selectedMetric = this.metricList.slice(0, 2);
            if (this.enablePVPTimeseries) {
              this.selectedValues.selectedMetric = this.metricList.slice(0, 1);
            }
          } else {
            this.disableAddNewMetric =
              response[0].data.metadata?.staticConfig?.disableAddNewMetric ||
              true;
            this.nosOfMetricShown =
              response[0].data.metadata?.staticConfig?.disableAddNewMetric || 6;
            this.selectedMetricLimit =
              response[0].data.metadata?.staticConfig?.disableAddNewMetric || 3;
            this.selectedValues.metricsShown = this.metricList.slice(
              0,
              this.nosOfMetricShown
            );
            this.selectedValues.selectedMetric = this.metricList.slice(0, 3);
            if (this.enablePVPTimeseries) {
              this.selectedValues.selectedMetric = this.metricList.slice(0, 1);
            }
            // Default config setup
            let temp_selectedRollUp = this.rollUps[0];
            if (this.config?.metadata) {
              // Parse the metadata config for saved metadata config
              const { selectedRollUp } = this.config?.metadata;
              temp_selectedRollUp = this.rollUps.filter(function (item) {
                return item.value === selectedRollUp;
              })[0];
              this.selectedValues.metricsShown = this.metricToKeyMapping(
                this.config?.metadata?.shownMetricList
              );
              this.selectedValues.selectedMetric = this.metricToKeyMapping(
                this.config?.metadata?.selectedMetric
              );
              this.enablePVPTimeseries =
                this.config?.metadata?.plotCompareTrendLine ?? false;
              this.$store.dispatch('setGlobalViewAction', {
                type: 'chartingWb',
                chartingWb: this.config?.metadata
              });
            } else {
              // Parse the response metadata config for default config
              const userPageMetadata = this.$store.getters.getUserPageMetadata;
              temp_selectedRollUp = this.rollUps.filter(function (item) {
                return (
                  item.value ===
                  (userPageMetadata?.page?.businessInsights?.performanceWb
                    ?.selectedRollUp ??
                    response[0].data.metadata?.defaultConfig?.selectedRollUp)
                );
              })[0];

              /**
               * The below condition checks if a config already exists for the user. Also, to tackle upgrading/downgrading of client products. This condition also verifies that the number of shown metrics is equal in the config and the read API response. If they are different, it means the client was upgraded/downgraded, s<o we use the default config in that case.
               */
              if (userPageMetadata?.page?.['businessInsights']?.performanceWb) {
                const metricsShownKeyValueMapping = this.metricToKeyMapping(
                  userPageMetadata.page.businessInsights.performanceWb
                    ?.shownMetricList
                );
                const defaultShownMetricList =
                  response[0].data.metadata?.defaultConfig?.shownMetricList;
                if (
                  metricsShownKeyValueMapping?.length !==
                  defaultShownMetricList?.length
                ) {
                  this.selectedValues.metricsShown = this.metricToKeyMapping(
                    defaultShownMetricList
                  );
                } else {
                  this.selectedValues.metricsShown =
                    metricsShownKeyValueMapping;
                }

                const finalMetricShownList =
                  this.selectedValues.metricsShown?.map((metric) => {
                    return metric.keyName;
                  });
                const filteredSelectedMetric =
                  userPageMetadata.page.businessInsights.performanceWb?.selectedMetric?.filter(
                    (metric) => {
                      return finalMetricShownList?.includes(metric);
                    }
                  );

                this.selectedValues.selectedMetric = this.metricToKeyMapping(
                  filteredSelectedMetric
                );
                this.enablePVPTimeseries =
                  userPageMetadata.page.businessInsights.performanceWb
                    ?.plotCompareTrendLine ?? false;

                this.$store.dispatch('setCompletePageMetadata', {
                  shownMetricList: this.selectedValues.metricsShown,
                  selectedMetric: this.selectedValues.selectedMetric,
                  plotCompareTrendLine: this.enablePVPTimeseries,
                  selectedRollUp: temp_selectedRollUp
                });

                this.$store.dispatch('setGlobalViewAction', {
                  type: 'chartingWb',
                  chartingWb:
                    userPageMetadata.page.businessInsights.performanceWb
                });
              } else {
                this.selectedValues.metricsShown = this.metricToKeyMapping(
                  response[0].data.metadata?.defaultConfig?.shownMetricList
                );
                this.selectedValues.selectedMetric = this.metricToKeyMapping(
                  response[0].data.metadata?.defaultConfig?.selectedMetric
                );
                this.$store.dispatch('setGlobalViewAction', {
                  type: 'chartingWb',
                  chartingWb: response[0].data.metadata?.defaultConfig
                });
              }
            }
            if (!temp_selectedRollUp) {
              temp_selectedRollUp = this.rollUps[0];
            }
            if (this.selectedValues?.metricsShown?.length === 0) {
              this.selectedValues.metricsShown = this.metricList.slice(
                0,
                this.nosOfMetricShown
              );
            }
            if (this.selectedValues?.selectedMetric?.length === 0) {
              this.selectedValues.selectedMetric =
                this.selectedValues?.metricsShown?.slice(0, 3);
              if (this.enablePVPTimeseries) {
                this.selectedValues.selectedMetric =
                  this.selectedValues?.metricsShown?.slice(0, 1);
              }
            }

            this.getChartDataWithRollUp(temp_selectedRollUp);
          }
          this.metadata = response[0]?.data?.metadata;
          if (this.isRetailerAmazonHybrid) {
            this.enableGroupByToggle();
          }
          this.isLoadingWidgetConfig = false;
        },
        (error) => {
          this.isLoadingWidgetConfig = false;
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        }
      );
    },
    calendarSubmitEvent(event) {
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify({ date_range: event })
      );
      // Make calls here
      this.updatedateDateSelection(event, true);
    },
    metricToKeyMapping(listArray) {
      const tempArray = [];
      listArray.forEach((item) => {
        for (let index = 0; index < this.metricList.length; index++) {
          if (this.metricList[index].keyName === item) {
            tempArray.push(this.metricList[index]);
          }
        }
      });
      return tempArray;
    },
    calendarCreated(dateRange) {
      const storage = localStorage.getItem(this.localStorageKey);
      if (!storage && this.page === 'skuDetails') {
        localStorage.setItem(
          this.localStorageKey,
          JSON.stringify({ date_range: dateRange })
        );
        this.updatedateDateSelection(dateRange);
      }
    },
    updatedateDateSelection(range, resetEvent) {
      if (range && this.page === 'skuDetails') {
        if (resetEvent) {
          logger.log({
            eventType: 'date_change_sdp',
            type: 'Click',
            page: `${this.page}`,
            pageIdentifier: `${this._skuID}`,
            eventContext: 'SDP Date Change',
            from: this.dateRangeSelected,
            to: range.name
          });
        }
        this.dateRangeSelected = range.name;
        // Construct PVP and Date
        this.selectedValues.date = {
          from: range.from,
          to: range.to
        };
        this.selectedValues.pvpDate = {
          from: range.compare_from,
          to: range.compare_to
        };
        let endDate = range.to;
        // Set the Calender End date to Event Max Date
        if (range.name !== 'custom') {
          endDate =
            (this.metricEventConfig.eventCalendar &&
              this.metricEventConfig.eventCalendar.max_date) ||
            this.metricEventConfig.calendar.max_date;
        }
        // Update Report Date
        const report_date = this.enumerateDates(range.from, endDate);
        // Doing this for smart comparision. Empty dates are not handled in backend.
        this.selectedValues.reportDateMapping = report_date.reduce(
          (acc, item) => {
            acc[item] = true;
            return acc;
          },
          {}
        );
        if (resetEvent) {
          this.chartData.data = [];
          this.resetCharttingWb();
        }
        this.chartData.data.push(['report_date', ...report_date]);
      }
    },
    enumerateDates(start, end, diff) {
      const date = [];
      if (!diff) {
        while (moment(start) <= moment(end)) {
          date.push(moment(start).format('MM/DD/YYYY'));
          start = moment(start).add(diff || 1, 'days');
        }
      } else {
        const diffUnitOfTime = `${diff}s`;
        date.push(moment(start).format('MM/DD/YYYY'));
        while (moment(end).diff(start, diffUnitOfTime) > 0) {
          const currentEnd = moment(
            moment(start).add(1, diffUnitOfTime)
          ).format('MM/DD/YYYY');
          date.push(currentEnd);
          start = currentEnd;
        }
      }
      return date;
    },
    transformConfig(config, type) {
      const _that = this;
      if (Vue.options.filters.config_check('feature.product.ams_lite')) {
        config = Object.keys(config).reduce((acc, key) => {
          const findIndex = config[key].views.findIndex(
            (item) => item.label === 'Advertising View'
          );
          if (findIndex === -1) {
            acc[key] = config[key];
            return acc;
          } else {
            // Check for multiple Views exist. If it's more than one view, then remove the view from array
            if (config[key].views.length !== 1) {
              config[key].views = config[key].views.splice(findIndex, 1);
              acc[key] = config[key];
              return acc;
            }
          }
          return acc;
        }, {});
      }
      const metrticList = Object.keys(config).map((key) => {
        const metric = config[key];
        metric.title = metric.label;
        metric.key = metric.label;
        // Extract views from the views object.
        // Selected Metric to View Mapping
        if (type === 'event') {
          const icon =
            (_that.eventsIconMap[metric.keyName] || {}).icon || 'dot';
          const color =
            (_that.eventsIconMap[metric.keyName] || {}).color ||
            'u-color-black-base';
          this.$set(metric, 'icon', icon);
          this.$set(metric, 'color', color);
          _that.chartConfig.chartOptions.types[metric.title] = 'scatter';
          _that.chartConfig.chartOptions.events.push({
            key: metric.title,
            icon: metric.icon
          });
          _that.selectedValues.chartTooltip[metric.title] = [];
          _that.chartConfig.chartOptions.tooltip_format[metric.title] =
            function (value, ratio, id, index) {
              return (
                '' + this.selectedValues.chartTooltip[metric.title][index] || ''
              );
            }.bind(this);
        }

        if (type === 'metric') {
          const config = {};
          config.invertTag2 = metric.metadata.isInverted || false;
          if (metric.metadata.unit === 'CURRENCY') {
            config.tag1Unit = { pre: 'currency' };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              pre: 'currency'
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__3p`
            ] = {
              pre: 'currency'
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__retail`
            ] = {
              pre: 'currency'
            };
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}`] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__3p`] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__retail`] =
              'y';
          } else if (metric.metadata.unit === 'PERCENTAGE') {
            config.tag1Unit = { suff: '%' };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              suff: '%'
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__3p`
            ] = {
              suff: '%'
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__retail`
            ] = {
              suff: '%'
            };
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__3p`] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__retail`] =
              'y';
          } else {
            config.tag1Unit = null;
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = null;
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__3p`
            ] = null;
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__retail`
            ] = null;
            _that.chartConfig.chartOptions.axes[metric.title] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__3p`] = 'y';
            _that.chartConfig.chartOptions.axes[`${metric.title}__retail`] =
              'y';
          }
          config.tag2Unit = { suff: '%' };
          _that.selectedValues.metricDisplayConfig[metric.title] = config;
          if (
            metric.metadata.type &&
            metric.metadata.type.toLowerCase() === 'number'
          ) {
            config.tag1Unit = { roundoff: 0 };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              roundoff: 0
            };
            _that.chartConfig.chartOptions.tooltip_format[metric.title] = {
              roundoff: 0
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__3p`
            ] = {
              roundoff: 0
            };
            _that.chartConfig.chartOptions.tooltip_format[
              `${metric.title}__retail`
            ] = {
              roundoff: 0
            };
          }
          metric.views.forEach((item) => {
            if (
              _that.viewMetricMapping[item.label] &&
              _that.viewMetricMapping[item.label].metricList
            ) {
              if (!item.selected) {
                _that.viewMetricMapping[item.label].metricList.push(metric);
              }
            } else {
              if (!_that.viewMetricMapping[item.label]) {
                _that.viewMetricMapping[item.label] = {};
              }
              _that.viewMetricMapping[item.label].metricList = [];
              if (!item.selected) {
                _that.viewMetricMapping[item.label].metricList.push(metric);
              }
            }
          });
        } else {
          metric.views.forEach((item) => {
            if (
              _that.viewMetricMapping[item.label] &&
              _that.viewMetricMapping[item.label].eventList
            ) {
              if (!item.selected) {
                _that.viewMetricMapping[item.label].eventList.push(metric);
              }
            } else {
              if (!_that.viewMetricMapping[item.label]) {
                _that.viewMetricMapping[item.label] = {};
              }
              _that.viewMetricMapping[item.label].eventList = [];
              if (!item.selected) {
                _that.viewMetricMapping[item.label].eventList.push(metric);
              }
            }
          });
        }
        return metric;
      });
      // Order the merticList based on priority
      metrticList.sort((a, b) => {
        return a.priority - b.priority;
      });
      this.metricsTooltipFormat = this.chartConfig.chartOptions.tooltip_format;
      return metrticList;
    },
    handleLocalStorage(events, type) {
      const _tempList = events.selectedMetric.map((item) => item.key);
      const saveMetric = events.metricDisplayList.map((item) => {
        const _newItem = {};
        _newItem.key = item.key;
        if (_tempList.indexOf(item.key) !== -1) {
          _newItem.selected = true;
        }
        return _newItem;
      });
      let element = localStorage.getItem(this.saveSelectionKey);
      let saveObj = {};
      if (!element) {
        saveObj[type] = saveMetric;
      } else {
        element = JSON.parse(element);
        if (typeof element === 'object' && element !== null) {
          saveObj = element;
        }
        saveObj[type] = saveMetric;
      }
      saveObj.template = this.selectedValues.template;
      if (this.dateRangeSelected !== 'custom') {
        saveObj.defaultRangeSelect = this.dateRangeSelected;
      }
      localStorage.setItem(this.saveSelectionKey, JSON.stringify(saveObj));
    },
    metricChanged(event, rollupChanged, pvpTimeseriesChanged) {
      if (!(Object.keys(event).length > 0 && event.metricDisplayList)) {
        return;
      }

      if (event.metricDisplayList?.length && this.isGroupByToggled) {
        this.shownMetricList = event.metricDisplayList;
      }

      const stagedMetric = this.filterStagedMetrics(event);
      let tempMetrics = [...event.metricDisplayList];

      this.prepareLogging(event);

      if (!isBOPage(this.page)) {
        this.detectCustomChange();
        this.handleLocalStorage(event, 'metric');
      }
      if (this.page === 'sku_details') {
        this.disableAddNewMetric =
          event.metricDisplayList.length >= this.maxMetricListItem;
      }

      this.updateSelectedValues(event, stagedMetric);

      if (this.shouldProcessMetrics(rollupChanged, pvpTimeseriesChanged)) {
        tempMetrics = this.processMetricsWithRollup(
          event,
          stagedMetric,
          tempMetrics
        );
      } else {
        tempMetrics = this.processMetricsWithoutRollup(
          event,
          stagedMetric,
          tempMetrics
        );
      }

      if (isBOPage(this.page)) {
        this.updateMetricValueAction(event);
      }
      this.fetchRemainingMetricData(tempMetrics);
      if (
        this.enablePVPTimeseries &&
        event.selectedMetric?.[0]?.metricGroup === 'Advertising'
      ) {
        this.enablePVPTimeseries = false;
        this.$nextTick(() => {
          this.pvpKey++;
          this.chartKey++;
          this.$snackbar.open({
            message:
              "Sorry, you can't compare advertising metrics for previous time periods !",
            duration: 7000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        });
      }
      // if (this.isGroupByToggled && rollupChanged) {
      //   this.fetchGroupBy(
      //     'BusinessModel',
      //     this.metricList[0],
      //     this.generateReqParams(),
      //     this.metricList
      //   );
      // } else if (this.isGroupByToggled) {
      //   // if (tempMetrics.length > 0) {
      //   //   console.log(tempMetrics);
      //   //   this.fetchGroupBy(
      //   //     'BusinessModel',
      //   //     tempMetrics[0],
      //   //     this.generateReqParams(),
      //   //     tempMetrics
      //   //   );
      //   //   return;
      //   // }
      //   const metricData = event.selectedMetric[0];
      //   const groupByMetricData = this.groupByAPIData.reduce(
      //     (acc, el, order) => {
      //       const metricMetadata = metricData.metadata;
      //       if (el.alias.split('__')[0] === metricData.alias) {
      //         const dataResponse = el;
      //         let [tag1, tag2] = [
      //           dataResponse.RESULT && dataResponse.RESULT[el.alias],
      //           dataResponse.PVP && dataResponse.PVP['PVP_' + el.alias]
      //         ];
      //         let entityValue;
      //         if (el.alias.includes('retail')) {
      //           entityValue = 'Retail';
      //         } else if (el.alias.includes('3p')) {
      //           entityValue = '3P';
      //         } else {
      //           entityValue = 'Retail+3p';
      //         }
      //         acc[entityValue] = {
      //           tag1,
      //           tag2,
      //           isUndefined: !entityValue,
      //           order
      //         };
      //       }
      //       return acc;
      //     },
      //     {}
      //   );
      //   this.$set(this.groupByConfig, 'metricData', groupByMetricData);
      // }
    },
    filterStagedMetrics(event) {
      return event?.metricDisplayList
        ?.map((metric, metricId) => [metric, metricId])
        .filter((metric) => {
          return isAllowedMetric(metric[0].alias);
        });
    },
    prepareLogging(event) {
      this.downloadComponent.tempMetricObj = JSON.parse(JSON.stringify(event));
      if (this.logging.selectedMetric.length !== 0) {
        this.logActivity(
          this.logging.selectedMetric,
          event.selectedMetric,
          'metric_change'
        );
      }
      this.logging.selectedMetric = JSON.parse(
        JSON.stringify(event.selectedMetric)
      );
    },
    updateSelectedValues(event, stagedMetric) {
      if (!this.filterSelected && stagedMetric?.length > 0) {
        for (let [_, index] of stagedMetric) {
          this.$set(
            this.selectedValues.metricData,
            event?.metricDisplayList?.[index]?.key,
            { tag1: 'staging' }
          );
        }
      } else {
        for (let [_, index] of stagedMetric) {
          if (
            this.selectedValues.metricData[
              event?.metricDisplayList?.[index]?.key
            ]?.tag1 === 'staging'
          ) {
            this.$set(
              this.selectedValues.metricData,
              event?.metricDisplayList?.[index]?.key,
              undefined
            );
          }
        }
      }
    },
    shouldProcessMetrics(rollupChanged, pvpTimeseriesChanged) {
      return rollupChanged || pvpTimeseriesChanged;
    },
    processMetricsWithRollup(event, stagedMetric, tempMetrics) {
      const stagedMetrics = tempMetrics.filter(
        (item) => this.selectedValues.metricData[item.key]?.tag1 === 'staging'
      );
      this.selectedValues.metricData = {};
      tempMetrics = tempMetrics.map((item, index) => {
        if (stagedMetrics.map((metric) => metric.key).includes(item.key)) {
          this.$set(
            this.selectedValues.metricData,
            event?.metricDisplayList?.[index]?.key,
            { tag1: 'staging' }
          );
        } else {
          this.$set(this.selectedValues.metricData, item.key, {
            tag1: 'loading'
          });
        }
        return item;
      });
      tempMetrics = tempMetrics.filter(
        (item) => this.selectedValues.metricData[item.key]?.tag1 !== 'staging'
      );

      return tempMetrics;
    },

    processMetricsWithoutRollup(event, stagedMetric, tempMetrics) {
      tempMetrics = tempMetrics.filter((item) => {
        if (this.selectedValues.metricData[item.key]) {
          return false;
        } else {
          this.$set(this.selectedValues.metricData, item.key, {
            tag1: 'loading'
          });
          return item;
        }
      });

      return tempMetrics;
    },

    updateMetricValueAction(event) {
      const metricDisplayList = event?.metricDisplayList?.map(
        (item) => item.keyName
      );
      const selectedMetric = event?.selectedMetric?.map((item) => item.keyName);
      const filteredSelectedMetric = selectedMetric.filter((metric) => {
        return metricDisplayList?.includes(metric);
      });

      this.$store.dispatch('setMetricValueAction', {
        metricDisplayList: metricDisplayList || [],
        selectedMetric: filteredSelectedMetric
      });
    },

    fetchRemainingMetricData(tempMetrics) {
      if (tempMetrics.length > 0) {
        const dataGroup = this.groupByDataGroup(tempMetrics);
        const requests = this.formulateRequests(dataGroup);
        this.fetchMetricData(requests);
      }
    },

    groupByDataGroup(tempMetrics) {
      return tempMetrics.reduce((acc, item) => {
        if (!acc[item.dataGroup]) {
          acc[item.dataGroup] = [];
        }
        acc[item.dataGroup].push(item);
        return acc;
      }, {});
    },

    formulateRequests(dataGroup) {
      return Object.keys(dataGroup).map((item) => {
        const api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
        api.request.metricsList = [];
        api.request.page = this.page;
        api.request.widget = this.config.widgetName;
        api.request.entityValue = this._skuID;
        api.request.eventsList = [];
        api.request.where.date = { ...this.selectedValues.date };
        api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
        if (isBOPage(this.page)) {
          const whereClause = { ...this.$store.getters.getWhereClause };
          api.request.where.date = { ...whereClause.date };
          api.request.where.pvpDate = { ...whereClause.pvpDate };
          api.request.where.dimensionNameValueList = [
            ...api.request.where.dimensionNameValueList,
            ...(whereClause?.dimensionNameValueList || [])
          ];
        }
        api.request.operations.timeseriesRollupBy = this.selectedRollUp.value;
        api.request.operations.isSplitByBusinessModelEnabled = true;
        dataGroup[item].forEach((list) => {
          api.request.metricsList.push(list.name);
        });
        if (item === 'ams_campaigns_asin_workbench') {
          api.request.operations.pvptimeseriesEnabled = false;
        } else {
          api.request.operations.pvptimeseriesEnabled =
            this.enablePVPTimeseries;
        }

        return HttpService.post(
          api.service,
          { ...api.request },
          { append: api.endPoint }
        );
      });
    },
    eventsChanged(event) {
      if (Object.keys(event).length > 0) {
        this.downloadComponent.tempEventObj = JSON.parse(JSON.stringify(event));
        // this.selectedValues.selectedEvents = [...event];
        if (this.logging.selectedEvents.length !== 0) {
          this.logActivity(
            this.logging.selectedEvents,
            event.selectedMetric,
            'event_change'
          );
          this.logging.selectedEvents = JSON.parse(
            JSON.stringify(event.selectedMetric)
          );
        } else {
          this.logging.selectedEvents = JSON.parse(
            JSON.stringify(event.selectedMetric)
          );
        }

        if (this.page !== 'BusinessInsights') {
          this.detectCustomChange();
          this.handleLocalStorage(event, 'event');
        }

        if (event.metricDisplayList.length < this.nosOfEventShown) {
          this.disableAddNewEvent = false;
        } else {
          this.disableAddNewEvent = true;
        }

        // Group by dataGroup
        let tempEvents = [...event.metricDisplayList];
        tempEvents = tempEvents.filter((item) => {
          if (this.selectedValues.eventData[item.label]) {
            return false;
          } else {
            return item;
          }
        });
        if (tempEvents.length > 0) {
          const dataGroup = tempEvents.reduce((acc, item) => {
            if (!acc[item.dataGroup]) {
              acc[item.dataGroup] = [];
              acc[item.dataGroup].push(item);
            } else {
              acc[item.dataGroup].push(item);
            }
            return acc;
          }, {});
          // Formulate the request based on the datagroup
          const requests = Object.keys(dataGroup).map((item) => {
            if (item && dataGroup[item] && dataGroup[item][0]) {
              const api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
              api.request.metricsList = [];
              api.request.page = this.page;
              api.request.entityType = 'asin';
              api.request.entityValue = this._skuID;
              api.request.eventsList = [];
              api.request.where.dimensionNameValueList = [];
              api.request.where.date = { ...this.selectedValues.date };
              api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
              dataGroup[item].forEach((list) => {
                api.request.eventsList.push(list.name);
              });
              // Events date: Soln for the custom Date event
              const storage = JSON.parse(
                localStorage.getItem(this.localStorageKey)
              );
              if (
                storage &&
                storage.date_range &&
                storage.date_range.name !== 'custom'
              ) {
                // Fallback to the calendar Max date - fix to sentry
                api.request.where.date.to =
                  (this.metricEventConfig.eventCalendar &&
                    this.metricEventConfig.eventCalendar.max_date) ||
                  this.metricEventConfig.calendar.max_date;
              }
              // Check date to is greater than date from.
              // Fix for dp/1903365260 events api.
              if (
                !moment(api.request.where.date.to).isAfter(
                  api.request.where.date.from
                )
              ) {
                api.request.where.date.to = this.selectedValues.date.to;
              }

              return {
                service: api.service,
                request: { ...api.request },
                append: api.endPoint
              };
            }
          });
          this.fetchEventData(requests);
        }
      }
    },
    templateSelected(val) {
      if (
        val.length > 0 &&
        val[0].label &&
        val[0].label !== this.selectedValues.template
      ) {
        logger.log({
          eventType: 'view_change',
          type: 'Click',
          page: `${this.page}`,
          pageIdentifier: `${this._skuID}`,
          eventContext: 'SDP View Change',
          from: this.selectedValues.template,
          to: val[0].label
        });
        this.selectedValues.template = val[0].label;
        // Change the default Mertics and default events.
        this.updateViews(this.selectedValues.template);
      }
    },
    filterValues(_tempObj, type, enableSelected, srcType) {
      const _arr = this[type].filter((item) => {
        if (_tempObj[srcType]) {
          const itemIndex = _tempObj[srcType].find((metric) => {
            if (item.key === metric.key) {
              if (enableSelected) {
                if (metric.selected) {
                  return true;
                }
                return false;
              } else {
                return true;
              }
            }
            return false;
          });
          if (itemIndex) {
            return item;
          }
        }
      });
      return _arr;
    },
    updateViews(viewName, readFromLocalStorage) {
      // Localstorage Handling
      this.selectedValues.templateCopy = viewName;
      const list = this.viewMetricMapping[viewName];
      console.log(list);
      this.logging.selectedMetric = [];
      this.logging.selectedEvents = [];
      this.selectedValues.selectedMetric = [];
      this.selectedValues.metricsShown = [];
      this.selectedValues.selectedEvents = [];
      this.selectedValues.eventsShown = [];
      const element = localStorage.getItem(this.saveSelectionKey);

      if (readFromLocalStorage && element) {
        if (element) {
          const _tempObj = JSON.parse(element);
          this.selectedValues.metricsShown = this.filterValues(
            _tempObj,
            'metricList',
            false,
            'metric'
          );
          this.selectedValues.selectedMetric = this.filterValues(
            _tempObj,
            'metricList',
            true,
            'metric'
          );
          this.selectedValues.eventsShown = this.filterValues(
            _tempObj,
            'eventList',
            false,
            'event'
          );
          this.selectedValues.selectedEvents = this.filterValues(
            _tempObj,
            'eventList',
            true,
            'event'
          );
          this.selectedValues.template = element.template;
        }
      } else {
        this.selectedValues.metricsShown = this.viewMetricMapping[
          viewName
        ].metricList.slice(0, this.nosOfMetricShown);
        // Set the selected Metric Values
        this.selectedValues.selectedMetric = this.viewMetricMapping[
          viewName
        ].metricList.slice(0, 2);
        if (this.enablePVPTimeseries) {
          this.selectedValues.selectedMetric = this.viewMetricMapping[
            viewName
          ].metricList.slice(0, 1);
        }
        if (
          this.viewMetricMapping[viewName] &&
          this.viewMetricMapping[viewName].eventList
        ) {
          this.selectedValues.eventsShown = this.viewMetricMapping[
            viewName
          ].eventList.slice(0, this.nosOfEventShown);
          this.selectedValues.selectedEvents = this.viewMetricMapping[
            viewName
          ].eventList.slice(0, this.nosOfEventShown);
        } else {
          this.selectedValues.eventsShown = this.eventList.slice(
            0,
            this.nosOfEventShown
          );
          this.selectedValues.selectedEvents = this.eventList.slice(
            0,
            this.nosOfEventShown
          );
        }
      }

      // Set the Selected metric as base metric to compare the custom metric selected or not
      this.customViewMetrics.metricsShown = JSON.parse(
        JSON.stringify(this.selectedValues.metricsShown)
      );
      this.customViewMetrics.eventsShown = JSON.parse(
        JSON.stringify(this.selectedValues.eventsShown)
      );
    },
    validateDataResponse(response) {
      let validResponse = [];
      // Extract out the valid responses from the backend.
      const mergeResponse = response.reduce((acc, item) => {
        acc = acc.concat(item.data.entityData);
        return acc;
      }, []);
      if (mergeResponse.length === 0) {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        return;
      } else {
        validResponse = mergeResponse.reduce((acc, item) => {
          if (item.success) {
            item.data.forEach((data) => {
              if (
                data.type === 'METRICS' &&
                this.metricEventConfig.metrics &&
                this.metricEventConfig.metrics[data.alias]
              ) {
                acc.push(data);
              } else if (
                data.type === 'EVENT' &&
                this.metricEventConfig.events &&
                this.metricEventConfig.events[data.alias]
              ) {
                acc.push(data);
              }
            });
          }
          return acc;
        }, []);
      }
      return validResponse;
    },
    validateGroupByDataResponse(response, validResponse) {
      // let validGroupByResponse = [];
      const mergeResponse = response.reduce((acc, item) => {
        acc = acc.concat(item.data.entityData);
        return acc;
      }, []);
      if (mergeResponse.length === 0) {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
        // return;
      } else {
        const validResponseNames = new Set(
          validResponse.map((data) => data.name)
        );
        this.validGroupByResponse = mergeResponse.reduce((acc, item) => {
          if (item.success) {
            item.data.forEach((data) => {
              if (!validResponseNames.has(data.name)) {
                acc.push(data); // Add data to acc if it's not in validResponse
              }
            });
          }
          return acc;
        }, []);
      }
      // return validGroupByResponse;
    },
    fetchMetricData(request) {
      this.isChartLoading = true;
      Promise.all(request)
        .then((response) => {
          const validResponse = this.validateDataResponse(response);
          if (this.isRetailerAmazonHybrid) {
            this.validateGroupByDataResponse(response, validResponse);
            // this.enableGroupByToggle(response, validResponse, validGroupByResponse);
          }
          if (validResponse.length === 0) {
            this.$snackbar.open({
              message: 'Something Went Wrong with Metric Data!!',
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
            return;
          }
          let mergeData = this.transformResponse(validResponse);
          mergeData = [].concat(...mergeData);
          // Push the mertic component data.
          const metricData = mergeData.reduce((acc, item) => {
            acc[item.key] = item.metricData[item.key];
            return acc;
          }, {});

          const pvpMetricData = mergeData.reduce((acc, item) => {
            acc[item.key] = item.pvpMetricData[item.key];
            return acc;
          }, {});

          // Push chart Data
          const chartData = mergeData.map((item) => {
            return item.timeseries;
          });
          // this.chartData.data = chartData
          this.chartData.data.push(...chartData);

          if (this.enablePVPTimeseries) {
            const pvpChartData = mergeData.map((item) => {
              return item.pvpTimeseries;
            });
            // this.pvpChartData.data = pvpChartData
            this.pvpChartData.data.push(...pvpChartData);
          }

          this.selectedValues.metricData = {
            ...this.selectedValues.metricData,
            ...metricData
          };

          this.selectedValues.pvpMetricData = { ...pvpMetricData };
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          if (Object.keys(this.selectedValues.metricData).length > 0) {
            Object.keys(this.selectedValues.metricData).forEach((item) => {
              if (
                this.selectedValues.metricData[item] &&
                this.selectedValues.metricData[item].tag1 &&
                this.selectedValues.metricData[item].tag1 === 'loading'
              ) {
                this.$set(
                  this.selectedValues.metricData[item],
                  'tag1',
                  'error'
                );
              }
            });
          }
          this.isChartLoading = false;
        });
    },
    fetchEventData(request) {
      this.isChartLoading = true;
      // this.isLoadingWidgetData = true;
      const requests = [];
      request.forEach((item) => {
        requests.push(
          HttpService.post(
            item.service,
            { ...item.request },
            { append: item.append }
          )
        );
      });
      Promise.all(requests)
        .then((response) => {
          // this.isLoadingWidgetData = false;
          const validResponse = this.validateDataResponse(response);

          if (validResponse.length === 0) {
            this.$snackbar.open({
              message: 'Something Went Wrong with Event Data !!',
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
          }
          let mergeData = this.transformResponse(validResponse);
          mergeData = [].concat(...mergeData);
          const eventData = mergeData.reduce((acc, item) => {
            acc[item.key] = item.eventData[item.key];
            return acc;
          }, {});
          // Push chart Data
          const chartData = mergeData.map((item) => {
            return item.timeseries;
          });
          this.chartData.data.push(...chartData);
          this.selectedValues.eventData = {
            ...this.selectedValues.eventData,
            ...eventData
          };
        })
        .catch((error) => {
          let mergeRequest = request.map((item) => {
            return item.request.eventsList;
          });
          mergeRequest = [].concat(...mergeRequest);
          if (this.selectedValues.selectedEvents.length > 0) {
            this.selectedValues.selectedEvents.map((item, index) => {
              if (mergeRequest.indexOf(item.name) !== -1) {
                this.$set(
                  this.selectedValues.selectedEvents[index],
                  'icon',
                  'error-fill'
                );
                this.$set(
                  this.selectedValues.selectedEvents[index],
                  'color',
                  'u-color-grey-x-light'
                );
              }
              return item;
            });
          }
          this.$snackbar.open({
            message: 'Something Went Wrong Event !!' || error,
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isChartLoading = false;
        });
    },
    transformResponse(response) {
      const _that = this;
      return response.map((item) => {
        const obj = {};
        let oObject = {};
        obj.name = item.name;
        if (
          item.type === 'METRICS' &&
          _that.metricEventConfig.metrics &&
          _that.metricEventConfig.metrics[item.alias]
        ) {
          oObject = _that.metricEventConfig.metrics[item.alias];
          obj.key = oObject.key;
          obj.metricData = {};
          obj.pvpMetricData = {};
          obj.metricData[oObject.key] = {};
          obj.pvpMetricData[oObject.key] = {};
          obj.metricData[oObject.key].tag1 = item.RESULT[item.name];
          obj.metricData[oObject.key].tag2 = item.PVP['PVP_' + item.name];
          obj.pvpMetricData[oObject.key].tag1 = item.PVP[item.name];
          obj.pvpMetricData[oObject.key].tag2 =
            item.PVP['PVP_DIFF_' + item.name];
          if (oObject.metadata.unit === 'PERCENTAGE') {
            // If unit is PERCENTAGE keep tag2 as PVP_DIFF_
            obj.metricData[oObject.key].tag2 =
              item.PVP['PVP_DIFF_' + item.name];
          }

          // TimeSeries - Map it's date and value for smart filtering.
          let tempObject = {};
          if (item.TIMESERIES) {
            const keyToRead =
              oObject?.api?.request?.operations?.timeseriesDimension ||
              'feed_date';
            tempObject = item.TIMESERIES.reduce((acc, value) => {
              acc[value[keyToRead]] = value[item.name];
              return acc;
            }, {});
          }
          const timeseries = Object.keys(
            _that.selectedValues.reportDateMapping
          ).map((item) => {
            if (tempObject[item]) {
              return tempObject[item];
            } else if (tempObject[item] === 0) {
              return 0;
            } else {
              return null;
            }
          });
          timeseries.unshift(oObject.key);

          let timeseriesObj = {};
          if (item.PVP_TIMESERIES && this.enablePVPTimeseries) {
            const keyToRead =
              oObject?.api.request.operations.timeseriesDimension ||
              'feed_date';
            // let keyToRead = 'feed_date';
            timeseriesObj = item.PVP_TIMESERIES.reduce((acc, value) => {
              acc[value[keyToRead]] = value[item.name];
              return acc;
            }, {});
          }
          const pvpTimeseries = Object.keys(
            _that.selectedValues.pvpReportDateMapping
          ).map((item) => {
            if (timeseriesObj[item]) {
              return timeseriesObj[item];
            } else if (timeseriesObj[item] === 0) {
              return 0;
            } else {
              return null;
            }
          });
          pvpTimeseries.unshift(oObject.key);
          // Check to find key is already exist in the chartData
          obj.timeseries = [...timeseries];
          if (this.enablePVPTimeseries) {
            obj.pvpTimeseries = [...pvpTimeseries];
          }
        } else if (
          item.type === 'EVENT' &&
          _that.metricEventConfig.events[item.name]
        ) {
          oObject = _that.metricEventConfig.events[item.name];
          obj.key = oObject.key;
          obj.eventData = {};
          obj.eventData[oObject.key] = true;
          let tempObject = {};
          if (item.TIMESERIES) {
            const keyToRead =
              (oObject && oObject.api.request.operations.timeseriesDimension) ||
              'feed_date';
            // let keyToRead = 'feed_date';
            tempObject = item.TIMESERIES.reduce((acc, value) => {
              acc[value[keyToRead]] = JSON.parse(value[item.name]) || {};
              return acc;
            }, {});
          }

          const timeseries = Object.keys(
            _that.selectedValues.reportDateMapping
          ).map((item) => {
            if (tempObject[item] && tempObject[item].value) {
              let tooltip = tempObject[item].tooltip;
              // Check for type of tooltip if not array, push the response string as tooltip info.
              if (Array.isArray(tooltip)) {
                tooltip = tooltip
                  .map((listItem) => {
                    return listItem[oObject.keyName];
                  })
                  .join(', ');
              }
              // tooltip formatting
              const maxLength = 75;
              if (tooltip.length > maxLength) {
                tooltip = `${tooltip.slice(0, maxLength)} & more...`;
              }
              _that.selectedValues.chartTooltip[oObject.label].push(tooltip);
              return 0;
            } else {
              _that.selectedValues.chartTooltip[oObject.label].push(null);
              return null;
            }
          });
          timeseries.unshift(oObject.key);
          // Check to find key is already exist in the chartData
          const tempFlatten = [].concat(..._that.chartData.data);
          if (tempFlatten.indexOf(oObject.key) === -1) {
            obj.timeseries = [...timeseries];
          }
        }
        return obj;
      });
    },
    resetCharttingWb() {
      // Resetting the Workbench.
      // Reset the metaData
      this.selectedValues.metricData = {};
      this.selectedValues.eventData = {};
      // Clearing the tooltip info.
      Object.keys(this.selectedValues.chartTooltip).forEach((item) => {
        this.selectedValues.chartTooltip[item] = [];
      });
      // Clearing for logging events
      this.logging.selectedMetric = [];
      this.logging.selectedEvents = [];
      this.metricChanged(this.downloadComponent.tempMetricObj);
      this.eventsChanged(this.downloadComponent.tempEventObj);
    },
    downloadReport() {
      logger.log({
        eventType: 'download_button_click',
        type: 'Click',
        page: `${this.page}`,
        widget: this.config.widgetName,
        pageIdentifier: `${this._skuID}`,
        eventContext: `${this.page} Download Button`
      });
      const APITemplate = { ...this.metricEventConfig.downloadApiTemplate };

      APITemplate.request.page = this.page;
      APITemplate.request.entityValue = this._skuID;
      APITemplate.request.widget = this.config.widgetName;
      const measureList =
        this.downloadComponent.tempMetricObj.metricDisplayList.map((item) => {
          return item.name;
        }) || [];
      const eventList =
        this.downloadComponent?.tempEventObj?.metricDisplayList?.map((item) => {
          return item.name;
        }) || [];
      APITemplate.request.metricsList = [...measureList];
      APITemplate.request.eventsList = [...eventList];

      if (this.page === 'skuDetails') {
        // measureList.push(...this.downloadComponent.tempMetricObj.metricDisplayList)
        APITemplate.request.where.dimensionNameValueList = [];
        APITemplate.request.where.date = { ...this.selectedValues.date };
        APITemplate.request.where.pvpDate = { ...this.selectedValues.pvpDate };
        APITemplate.request.entityType = 'asin';
      } else if (isBOPage(this.page)) {
        APITemplate.request.entityType = '#ALLOVER_AGGREGATE';
        APITemplate.request.operations.timeseriesRollupBy =
          this.selectedRollUp.value;
        if (!this.metadata?.includeOperations) {
          APITemplate.request.operations = {};
        }
        APITemplate.request.where = {};
        const dataGroup =
          this.downloadComponent.tempMetricObj.metricDisplayList.reduce(
            (acc, item) => {
              if (!acc[item.dataGroup]) {
                acc[item.dataGroup] = [];
                acc[item.dataGroup].push(item);
              } else {
                acc[item.dataGroup].push(item);
              }
              return acc;
            },
            {}
          );
        const overridenDataGroupsRequest = this.constructRequest(
          dataGroup
        ).reduce((acc, item) => {
          if (!acc[item.dataGroup]) {
            acc[item.request.dataGroup] = item.request;
          } else {
            acc[item.request.dataGroup] = item.request;
          }
          return acc;
        }, {});
        APITemplate.request.overridenDataGroupsRequest =
          overridenDataGroupsRequest;
      }

      this.isDownloadLoading = true;

      HttpService.post(
        APITemplate.service,
        { ...APITemplate.request },
        { append: APITemplate.endPoint }
      )
        .then((response) => {
          if (response.status) {
            downloadFile(response.data.url);
          } else {
            this.handleDownloadError();
          }
          this.isDownloadLoading = false;
        })
        .catch((error) => {
          this.isDownloadLoading = false;
          this.handleDownloadError(error);
        })
        .finally(() => {
          this.isDownloadLoading = false;
        });
    },
    handleDownloadError() {
      this.isDownloadError = true;
      this.$snackbar.open({
        message: 'Oops! The download was not successful. Please try again',
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
      setTimeout(() => {
        this.isDownloadError = false;
      }, 4000);
    },
    constructRequest(dataGroup) {
      const requests = Object.keys(dataGroup).map((item) => {
        const api = JSON.parse(JSON.stringify(dataGroup[item][0].api));
        api.request.metricsList = [];
        api.request.page = this.page;
        api.request.widget = this.config.widgetName;
        api.request.entityValue = this._skuID;
        api.request.eventsList = [];
        api.request.dataGroup = item;
        // api.request.where.dimensionNameValueList = [];
        api.request.where.date = { ...this.selectedValues.date };
        api.request.where.pvpDate = { ...this.selectedValues.pvpDate };
        if (isBOPage(this.page)) {
          const whereClause = { ...this.$store.getters.getWhereClause };
          api.request.where.date = { ...whereClause.date };
          api.request.where.pvpDate = { ...whereClause.pvpDate };
          api.request.where.dimensionNameValueList = [
            ...api.request.where.dimensionNameValueList,
            ...(whereClause?.dimensionNameValueList || [])
          ];
        }
        api.request.operations.timeseriesRollupBy = this.selectedRollUp.value;
        api.request.operations.isSplitByBusinessModelEnabled =
          this.isGroupByToggled;
        api.request.operations.timeseriesEnabled = true;
        if (this.isGroupByToggled) {
          api.request.entityType = '#ALLOVER_AGGREGATE';
          api.request.showByEntities = null;
        }
        dataGroup[item].forEach((list) => {
          api.request.metricsList.push(list.name);
        });
        return api;
      });
      return requests;
    },
    logActivity(prevArray, newArray, eventType) {
      const oldMetric = this._.differenceBy(prevArray, newArray, (a) => {
        return a.key;
      });
      const newMetric = this._.differenceBy(newArray, prevArray, (a) => {
        return a.key;
      });
      if (newMetric.length > 0 && oldMetric.length > 0) {
        logger.log({
          eventType: eventType,
          type: 'Click',
          page: `${this.page}`,
          pageIdentifier: `${this._skuID}`,
          widget: this.config.widgetName,
          eventContext: eventType,
          from: oldMetric[0] && oldMetric[0].key,
          to: newMetric[0] && newMetric[0].key
        });
      }
    },
    detectCustomChange() {
      let _aMetric = this.customViewMetrics.metricsShown;
      let _bMetric =
        (this.downloadComponent.tempMetricObj &&
          this.downloadComponent.tempMetricObj.metricDisplayList) ||
        [];
      if (_aMetric.length < _bMetric.length) {
        [_aMetric, _bMetric] = [_bMetric, _aMetric];
      }
      const metricList = this._.differenceBy(_aMetric, _bMetric, (a) => {
        return a.key;
      });

      let _aEvent = this.customViewMetrics.eventsShown;
      let _bEvent =
        (this.downloadComponent.tempEventObj &&
          this.downloadComponent.tempEventObj.metricDisplayList) ||
        [];
      if (_aEvent.length < _bEvent.length) {
        [_aEvent, _bEvent] = [_bEvent, _aEvent];
      }
      const eventList = this._.differenceBy(_aEvent, _bEvent, (a) => {
        return a.key;
      });
      if (metricList.length === 0 && eventList.length === 0) {
        this.selectedValues.template = this.selectedValues.templateCopy;
      } else {
        this.selectedValues.template = 'Custom';
      }
    },
    removeLocalStorageKey(key) {
      // Clearing the localstorage. This approach won't work if we open the same sku in two tabs. Clearing localstorage otherwise it will keep on growing
      localStorage.removeItem(key);
    },
    getChartDataWithRollUp(selection) {
      if (selection?.value && selection.value !== this.selectedRollUp?.value) {
        this.$logger.logEvent({
          eventType: 'roll_up_change',
          type: 'Click',
          page: `${this.page}`,
          widget: this.config.widgetName,
          from: this.selectedRollUp.value,
          to: selection.value
        });
        this.selectedRollUp = selection;
        this.setUpReportingDate();
        this.metricChanged(
          {
            metricDisplayList:
              this.downloadComponent.tempMetricObj.metricDisplayList,
            selectedMetric: this.metricToKeyMapping(
              this.performanceWbStoreValues.selectedMetric
            )
          },
          true
        );
        this.$store.dispatch('setRollUpAction', this.selectedRollUp.value);
        this.$store.dispatch(
          'setPlotCompareTrendLine',
          this.enablePVPTimeseries
        );
      }
    },
    setUpReportingDate() {
      if (!this.selectedRollUp?.diff) {
        return;
      }
      // Populate the report date for chart based on the store date and roll up value.
      const date = { ...this.$store.getters.getWhereClause.date };
      const pvpDate = { ...this.$store.getters.getWhereClause.pvpDate };

      if (this.selectedRollUp.diff === 'week') {
        date.from = moment(date.from).startOf('week').format('YYYY-MM-DD');
        pvpDate.from = moment(pvpDate.from)
          .startOf('week')
          .format('YYYY-MM-DD');
        // Week should start from saturday instead of monday for walmart
        if (this.$store.getters.getRetailer.toLowerCase() === 'walmart') {
          date.from = moment(date.from).subtract(1, 'd');
          pvpDate.from = moment(pvpDate.from).subtract(1, 'd');
        }
      } else if (this.selectedRollUp.diff === 'month') {
        date.from = moment(date.from).startOf('month').format('YYYY-MM-DD');
        pvpDate.from = moment(pvpDate.from)
          .startOf('month')
          .format('YYYY-MM-DD');
      }
      const reportDate = this.enumerateDates(
        date.from,
        date.to,
        this.selectedRollUp.diff
      );

      const pvpReportDate = this.enumerateDates(
        pvpDate.from,
        pvpDate.to,
        this.selectedRollUp.diff
      );

      this.selectedValues.reportDateMapping = reportDate.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      this.selectedValues.pvpReportDateMapping = pvpReportDate.reduce(
        (acc, item) => {
          acc[item] = true;
          return acc;
        },
        {}
      );

      this.chartData.data = [];
      this.pvpChartData.data = [];
      this.chartData.data.push(['report_date', ...reportDate]);
      this.pvpChartData.data.push(['report_date', ...pvpReportDate]);
    },
    getViewInfo() {
      return this.selectedRollUp;
    },
    dispatchFilterApply(value, event) {
      eventBus.$emit(
        'filterSelect',
        value.metric?.metadata?.customProperties?.filterSelector,
        false,
        event
      );
    },
    dispatchStoreAction(val) {
      if (this.disableTopMovers(val)) {
        return;
      }
      this.$store.dispatch(this.mertricIconConfig.storeAction, val.metric);
      const dom = document.getElementById(
        decodeURIComponent(this.mertricIconConfig.storeAction)
      );
      if (dom) {
        dom.scrollIntoView({
          // behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    },
    timeseriesDimensionChanged(value) {
      this.enablePVPTimeseries = value;
      this.selectedValues.selectedMetric = this.metricToKeyMapping(
        this.performanceWbStoreValues.selectedMetric
      )?.slice(0, 1);
      this.metricChanged(
        {
          metricDisplayList:
            this.downloadComponent.tempMetricObj.metricDisplayList,
          selectedMetric: this.selectedValues.selectedMetric?.slice(0, 1)
        },
        false,
        true
      );
      this.$store.dispatch('setPlotCompareTrendLine', this.enablePVPTimeseries);
    },
    getPVPTimeseriesLabel() {
      const fromDate = moment(this.getDateObj().pvpFrom).format('MMM DD, YYYY');
      const toDate = moment(this.getDateObj().pvpTo).format('MMM DD, YYYY');
      this.pvpDateText = fromDate + ' - ' + toDate;
      return {
        text: 'Plot trendline of',
        dateText: this.pvpDateText
      };
    },
    getDateObj() {
      const dateObj = this.$store.getters.getWhereClause.date;
      const pvpDateObj = this.$store.getters.getWhereClause.pvpDate;
      return {
        pvpFrom: pvpDateObj?.from,
        pvpTo: pvpDateObj?.to,
        from: dateObj?.from,
        to: dateObj?.to
      };
    },
    enableGroupByToggle() {
      const groupByMetadata = klona(this.metadata?.groupByLevelInfo);
      const invalidFilterKeys = this.invalidFilters?.length
        ? Object.keys(this.invalidFilters?.[0])
        : null;
      if (invalidFilterKeys?.length) {
        groupByMetadata.availableGroupBy =
          groupByMetadata.availableGroupBy.filter(
            (e) => !invalidFilterKeys.includes(e.key)
          );
        groupByMetadata.selectedGroupBy = invalidFilterKeys.includes(
          groupByMetadata.selectedGroupBy?.key
        )
          ? groupByMetadata.availableGroupBy[0]
          : groupByMetadata.selectedGroupBy;
      }
      if (groupByMetadata?.availableGroupBy?.length) {
        this.isGroupByChart = true;
      }
      if (this.isGroupByChart) {
        this.enableGroupByTimeseries = groupByMetadata.isGroupBySelected;
        [
          'availableGroupBy',
          'selectedGroupBy',
          'selectedSorting',
          'selectedEntities'
        ].forEach((el) => {
          this.$set(this.groupByConfig, el, groupByMetadata[el]);
        });
      }
    },
    populateGroupByPVP(response, validResponse, validGroupByResponse) {
      const timeseriesData =
        validGroupByResponse.TIMESERIES?.sort(
          (a, b) =>
            new Date(a.feed_date || a.report_date) -
            new Date(b.feed_date || b.report_date)
        ) ?? [];
      const PVPTimeseriesData =
        validGroupByResponse.PVP_TIMESERIES?.sort(
          (a, b) =>
            new Date(a.feed_date || a.report_date) -
            new Date(b.feed_date || b.report_date)
        ) ?? [];
      this.$set(this.groupByPVPChartData, 'data', {
        TIMESERIES: timeseriesData,
        PVP_TIMESERIES: PVPTimeseriesData
      });
      this.$set(
        this.groupByPVPChartData,
        'entityValue',
        response.entityValue || 'Uncategorised'
      );
    },
    resetGroupByChartConfigsAndData() {
      this.$set(this.groupByChartData, 'data', {});
      this.$set(this.groupByPVPChartData, 'data', {});
      this.$nextTick(() => {
        this.$refs['chart-with-legends']?.formatData();
      });
    },
    generateReqParams() {
      const dataGroup = {};
      for (const key in this.selectedValues.metricsShown) {
        const item = this.selectedValues.metricsShown[key];
        if (!dataGroup[item.dataGroup]) {
          dataGroup[item.dataGroup] = [];
        }
        dataGroup[item.dataGroup].push(item);
      }
      const requests = this.constructRequest(dataGroup).reduce((acc, item) => {
        if (!acc[item.dataGroup]) {
          acc[item.request.dataGroup] = item.request;
        } else {
          acc[item.request.dataGroup] = item.request;
        }
        return acc;
      }, {});
      const metricName = ['sales_dashboard__ordered_product_sales'];
      for (const selectedItem of this.selectedValues.selectedMetric) {
        const keyName = selectedItem.keyName;
        const result = this.findObjectWithKeyName(requests, keyName);
        if (result) {
          metricName[0] = result;
          break;
        }
      }
      let valuesArray = Object.values(requests);
      let newRequest = { groupByRequest: valuesArray[0] };
      newRequest.groupByRequest.metricsList = metricName;
      // newRequest.groupByRequest.metricsList = this.shownMetricList?.map(metric=>{
      //   const metricMeta = this.metricList.find((e)=> e.name === metric || e.alias === metric);
      //   return metricMeta.name
      // });
      // if (this.isGroupByToggled) {
      newRequest.groupByRequest.operations.timeseriesEnabled = true;
      // }
      this.reqParams = newRequest;
      return this.reqParams.groupByRequest;
    },
    findObjectWithKeyName(requests, keyName) {
      for (const objKey in requests) {
        const metricsList = requests[objKey].metricsList;
        for (const metric of metricsList) {
          const metricValue = metric.split('__')[1] || metric; // Get the part after '__' or use the whole metric if no '__'

          if (metricValue === keyName) {
            return metric;
          }
        }
      }

      return null;
    },
    groupByMetricChanged(params) {
      this.selectedGroupBy = params.dimensionNameValueList[0]?.dimensionValue;
    },
    async groupByChanged(params) {
      try {
        const {
          groupBy,
          isGroupBy,
          // sorting,
          timeseriesEnabled,
          dimensionNameValueList,
          metric
        } = params;
        this.enablePVPTimeseries = false;
        this.pvpKey++;
        this.isGroupByToggled = params.isGroupBy;
        if (!this.isGroupByToggled) {
          Vue.set(this.selectedValues, 'metricsShown', this.shownMetricList);
        }
        const selectedMetricFromGroupBy = this.metricList.filter(
          (item) => item.alias === metric.alias
        );
        const selectedMetric = selectedMetricFromGroupBy.length
          ? selectedMetricFromGroupBy
          : [this.selectedValues.metricsShown[0]];
        Vue.set(this.selectedValues, 'selectedMetric', selectedMetric);
        this.resetGroupByChartConfigsAndData();
        const reqParams = this.generateReqParams();
        await this.fetchGroupBy(groupBy.key, metric, reqParams, selectedMetric);
        if (params.timeseriesEnabled) {
          this.$set(
            this.groupByConfig,
            'selectedEntities',
            dimensionNameValueList.map((el) => el.dimensionValue)
          );
          this.fetchGroupByTimeseriesData(
            params.groupBy.key,
            params.dimensionNameValueList,
            params.metric,
            reqParams
          );
        } else {
          this.$set(this.groupByConfig, 'selectedGroupBy', groupBy);
          const groupByMetadata = klona(this.metadata?.groupByLevelInfo);
          this.metadata.groupInfo.selectedGroupBy = params.groupBy;
          this.metadata.groupInfo.isGroupBySelected = params.isGroupBy;
          this.enableGroupByTimeseries = isGroupBy;
          if (isGroupBy) {
            this.defaultSelectMetric = [metric];
            this.fetchGroupBy(groupBy.key, metric, reqParams, selectedMetric);
          } else {
            this.isChartLoading = false;
            this.$set(this.groupByConfig, 'isGroupByMetricLoading', false);
          }
        }
        if (!this.isGroupByToggled) {
          Vue.set(this.selectedValues, 'metricsShown', this.shownMetricList);
          this.chartKey++;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isChartLoading = false;
      }
    },
    async fetchGroupByTimeseriesData(
      entityType,
      dimensionNameValueList,
      metric,
      reqParams
    ) {
      // if (reqParams.dimensionNameValueList.length === 0) {
      //   this.$set(this.groupByChartData, 'data', []);
      //   this.$set(this.groupByPVPChartData, 'data', []);
      //   this.$nextTick(() => {
      //     this.$refs['chart-with-legends']?.formatData();
      //   });
      //   return;
      // }
      // this.isChartLoading = true;
      // const api = this.selectedValues.metricsShown[0].api;
      // let request = api.request;
      // for (const key in reqParams) {
      //   if (reqParams[key] !== null) {
      //     request[key] = reqParams[key];
      //   }
      // }
      // request.operations.timeseriesEnabled = true;
      // return HttpService.post(
      //   api.service,
      //   { ...api.request },
      //   { append: api.endPoint }
      // )
      if (dimensionNameValueList.length === 0) {
        // this.$set(this.groupByChartData, 'data', {});
        // this.$set(this.groupByPVPChartData, 'data', {});
        this.$nextTick(() => {
          this.$refs['chart-with-legends']?.formatData();
        });
        return;
      }
      this.isChartLoading = true;
      const api = this.selectedValues.metricsShown[0].api;
      let request = api.request;
      for (const key in reqParams) {
        if (reqParams[key] !== null) {
          request[key] = reqParams[key];
        }
      }
      request.entityType = '#ALLOVER_AGGREGATE';
      const metricToPass = metric.name;
      request.metricsList = [metricToPass];
      request.operations.orderByList = [];
      // request.operations.showByEntities = [entityType];
      request.operations.timeseriesEnabled = true;
      const widgetDimensionNameValueList =
        reqParams.where.dimensionNameValueList;
      if (request.where.dimensionNameValueList !== ':dimensionNameValueList') {
        request.where.dimensionNameValueList = [
          ...(request.where.dimensionNameValueList || []),
          ...(dimensionNameValueList || []),
          ...(widgetDimensionNameValueList || [])
        ];
      } else {
        request.where.dimensionNameValueList = [
          ...widgetDimensionNameValueList,
          ...dimensionNameValueList
        ];
      }
      try {
        const response = await HttpService.post(
          api.service,
          { ...api.request },
          { append: api.endPoint }
        );
        let groupByList = response.entityData;
        const metricKey = metric.keyName;
        const metricAlias = metric.alias;
        if (![metricKey, metricAlias].includes(this.selectedMetrics[0])) {
          return;
        }
        if (groupByList) {
          this.populateGroupByData(
            groupByList,
            dimensionNameValueList,
            metricKey,
            metric
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        this.isChartLoading = false;
      }
    },
    processGroupByData(groupByList, selectedMetric) {
      try {
        const metricData = selectedMetric;
        // const totalEntityCount = groupByList?.[0]?.totalEntityCount;
        // this.$set(this.groupByConfig, 'totalEntityCount', totalEntityCount);
        this.$set(this.groupByConfig, 'totalEntityCount', 3);
        const metricConfig = this.generateGroupByMetricConfig(metricData);
        const metricKey = metricData.keyName;
        this.$set(this.groupByConfig, 'metricConfig', metricConfig);

        const suffixes = ['', '__retail', '__3p'];

        const getMetricName = (string) => {
          switch (true) {
            case string.includes('__retail'):
              return string.split('__retail')[0];
            case string.includes('__3p'):
              return string.split('__3p')[0];
            default:
              return string;
          }
        };
        this.groupByAPIData = groupByList[0]?.data;
        const groupByMetricsData = groupByList[0]?.data?.reduce(
          (acc, el, order) => {
            const metricMetadata = metricData.metadata;
            if (getMetricName(el.alias) === metricData.alias) {
              const dataResponse = el;
              let [tag1, tag2] = [
                dataResponse.RESULT?.[el.alias],
                dataResponse.PVP?.['PVP_' + el.alias]
              ];
              let entityValue;

              switch (true) {
                case el.alias.includes('__retail'):
                  entityValue = 'Retail';
                  break;
                case el.alias.includes('__3p'):
                  entityValue = '3P';
                  break;
                default:
                  entityValue = 'Retail+3p';
              }

              acc[entityValue] = {
                tag1,
                tag2,
                isUndefined: !entityValue,
                order
              };
            }
            return acc;
          },
          {}
        );
        let feed_date = ['feed_date'];
        const groupByChartData = groupByList[0].data?.reduce(
          (accumulator, currentValue) => {
            if (
              currentValue &&
              currentValue.TIMESERIES.length > feed_date.length - 1
            ) {
              const dates = currentValue.TIMESERIES.map((e) => e.feed_date);
              feed_date = ['feed_date', ...dates];
            }

            const metric = this.metricList?.find(
              (metric) =>
                metric.alias === getMetricName(currentValue.alias) ||
                metric.name === getMetricName(currentValue.alias)
            );
            let label = metric?.title;
            switch (true) {
              case currentValue.alias.includes('__retail'):
                label = `${metric?.title} - Retail`;
                break;
              case currentValue.alias.includes('__3p'):
                label = `${metric?.title} - 3p`;
                break;
            }

            const currentTimeseriesMap = {};
            currentValue.TIMESERIES.forEach((dateItem) => {
              currentTimeseriesMap[dateItem.feed_date] =
                dateItem[currentValue.alias];
            });

            const values = feed_date?.slice(1)?.map((date) => {
              return currentTimeseriesMap?.[date] ?? null;
            });

            values.unshift(label);
            accumulator.push(values);
            return accumulator;
          },
          []
        );
        groupByChartData.push(feed_date);
        this.$set(this.groupByConfig, 'metricData', groupByMetricsData);
        this.groupByChartData = {
          data: groupByChartData
        };
        this.$set(this.groupByConfig, 'isGroupByMetricLoading', false);
      } catch (error) {
        console.error(error);
      }
    },
    generateGroupByMetricConfig(metricData) {
      const metricMeta = metricData.metadata;
      const metricConfig = {
        invertTag2: metricMeta.isInverted,
        tag1Unit: {
          pre: metricMeta.unit === 'CURRENCY' ? 'currency' : '',
          suff:
            metricMeta.unit in this.suffMap
              ? this.suffMap[metricMeta.unit]
              : '',
          roundoff:
            metricMeta.type === 'NUMBER' && metricMeta.unit === ''
              ? 0
              : undefined
        },
        tag2Unit: {
          suff: '%'
        }
      };

      return metricConfig;
    },
    async fetchGroupBy(entityType, metric, reqParams, selectedMetrics) {
      let cancelFinally = false;
      try {
        this.$set(this, 'selectedMetrics', [metric.alias]);
        this.groupBySelectedMetric = klona([metric]);
        // this.$set(this, 'selectedMetrics', reqParams.metricList);
        this.isChartLoading = true;
        this.$set(this.groupByConfig, 'isGroupByMetricLoading', true);
        this.$set(this.groupByConfig, 'hasErrorOccurred', false);
        const api = klona(metric.api);
        let request = api.request;
        // const updatedRequest = Object.assign({}, request, reqParams);
        for (const key in reqParams) {
          if (reqParams[key] !== null) {
            if (key !== 'operations') {
              request[key] = reqParams[key];
            }
          }
        }
        api.request.operations.timeseriesEnabled =
          reqParams.operations.timeseriesEnabled;
        api.request.operations.isSplitByBusinessModelEnabled =
          reqParams.operations.isSplitByBusinessModelEnabled;
        api.request.operations.timeseriesRollupBy =
          reqParams.operations.timeseriesRollupBy;
        api.request.operations.pvpenabled = reqParams.operations.pvpenabled;
        api.request.operations.samePeriodLastYearTimeseriesEnabled =
          reqParams.operations.samePeriodLastYearTimeseriesEnabled;

        // request.metricsList = this.selectedValues.shownMetricList.map((e) => {
        //   const metricMeta = this.metricList.find(
        //     (metric) => metric.name === e.alias || metric.alias === e.alias
        //   );
        //   return metricMeta.name;
        // });
        request.metricsList = this.metricList.map((e) => {
          return e.name;
        });
        if (selectedMetrics) {
          request.metricsList = selectedMetrics.map((e) => {
            const metricMeta = this.metricList.find(
              (metric) => metric.name === e.alias || metric.alias === e.alias
            );
            return metricMeta.name;
          });
        }
        request.operations.timeseriesEnabled = true;
        request.operations.timeseriesRollupBy = this.selectedRollUp.value;
        // request.operations.showByEntities = [entityType];
        request.where.dimensionNameValueList = [];
        const response = await HttpService.post(
          api.service,
          { ...api.request },
          { append: api.endPoint }
        );
        const groupByList = response.data.entityData;
        if (groupByList) {
          this.processGroupByData(groupByList, selectedMetrics[0] || metric);
        }
      } catch (error) {
        if (HttpService.isCancelRequestError(error)) {
          cancelFinally = true;
          return;
        }
        console.error(error);
        this.$set(this.groupByConfig, 'hasErrorOccurred', true);
        this.$set(this.groupByChartData, 'data', {});
        this.$set(this.groupByConfig, 'isGroupByMetricLoading', false);
      } finally {
        if (cancelFinally) {
          // eslint-disable-next-line
          return;
        }
        this.isChartLoading = false;
      }

      // this.fetchMetricData(request);
    }
  }
};
</script>
<style lang="css" scoped>
.optionsDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  padding: 10px 16px;
}

.divider-footer {
  width: 2px;
  height: 12px;
  background: #e9eaeb;
  margin: auto 16px;
}

.metric-card-hover-trigger {
  margin-top: 16px;
}

.u-viewport-height {
  height: 100vh;
}

.rollby-dropdown {
  border-radius: 13.5px;
  height: 26px;
  border: solid 1px #e9eaeb;
}
.rollby-dropdown:hover,
.rollby-dropdown:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}

.disable-top-mover {
  opacity: 0.3;
  cursor: default;
}

.valueDropdown {
  border-radius: 13.5px;
  height: 24px;
  width: 145px;
  border: solid 1px #e9eaeb;
}
.valueDropdown:hover,
.valueDropdown:focus {
  border: 1px solid #caccce;
}
</style>

<style lang="css">
.custom-chart-legend {
  max-width: 280px;
}

.metric-tooltip-ruler {
  border-width: 0;
  height: 1px;
  background-color: #e9eaeb;
}
</style>
