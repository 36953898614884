<template>
  <div
    class="recommendation--detail-view u-display-flex u-flex-direction-column u-height-100 u-overflow-auto"
  >
    <div
      class="section--top u-flex-none u-display-flex u-flex-align-items-center u-spacing-pv-m u-position-relative"
    >
      <span
        class="u-spacing-mr-s back-arrow"
        @click.stop="deselectAlert()"
      >
        <rb-icon
          :icon="'arrow_back'"
          class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
        />
      </span>
      <!-- eslint-disable -->
      <div v-if="this.fetchedBcRecommendationType === 'asin'">
        <rb-sku
          class="u-text-overflow-ellipsis"
          :title="selectedAlert.attributes.title"
          :image-url="selectedAlert.attributes.image_url"
          :asin="selectedAlert.attributes.asin"
          :product-page-url="selectedAlert.attributes.product_url"
          :enable-s-d-p="enableSDP"
          @navigateToSDP="navigateToSDP"
        >
          <div
            slot="sku-context"
            class="u-display-flex u-font-size-7 u-text-overflow-ellipsis"
          >
            <span v-if="selectedAlert.attributes.recency == 0">{{
              $t('new')
            }}</span>
            <span
              v-if="
                selectedAlert.attributes.recency !== 0 &&
                !(
                  selectedAlert.appName &&
                  selectedAlert.appName !== 'MarketingIQ'
                )
              "
              >{{ selectedAlert.attributes.recency }} times</span
            >
            <span
              v-if="
                selectedAlert.attributes.mean_estimate_value &&
                showPerformanceOverview
              "
              v-tippy="{ placement: 'right' }"
              class="u-color-blue-base"
              :title="$t('mean_estimate_value')"
            >
              <span
                v-if="
                  selectedAlert.attributes.recency == 0 ||
                  !(
                    selectedAlert.appName &&
                    selectedAlert.appName !== 'MarketingIQ'
                  )
                "
                >&nbsp;&#183;&nbsp;</span
              >
              {{
                selectedAlert.attributes.mean_estimate_value
                  | num_format('currency')
              }}
            </span>
            <span
              v-if="selectedAlert.attributes.cached_html_link"
              v-tippy="tippyOptions"
              class="u-display-flex"
              :title="$t('page_snapshot_tooltip')"
            >
              <span
                v-if="
                  selectedAlert.attributes.recency == 0 ||
                  !(
                    selectedAlert.appName &&
                    selectedAlert.appName !== 'MarketingIQ'
                  ) ||
                  (selectedAlert.attributes.mean_estimate_value &&
                    showPerformanceOverview)
                "
                >&nbsp;&#183;&nbsp;</span
              >
              <a
                class="link--asin u-color-grey-lighter"
                :href="selectedAlert.attributes.cached_html_link"
                target="_blank"
                @click.stop
              >
                Cached Page</a
              >
            </span>
          </div>
        </rb-sku>
      </div>
      <div
        v-if="this.fetchedBcRecommendationType === 'search'"
        class="u-spacing-pv-m u-font-weight-600 u-font-size-2"
      >
        Keyword: &ldquo;{{ selectedAlert.attributes.search }}&rdquo;
      </div>
      <div v-if="this.fetchedBcRecommendationType === 'brand'">
        <div class="u-spacing-pb-s u-font-weight-600 u-font-size-2">
          {{ selectedAlert.attributes.bc_brand_alert_type }}
        </div>
        <div class="u-color-grey-lighter u-font-size-5">
          {{ selectedAlert.attributes.bc_category_alert_type }}
        </div>
      </div>
      <!-- eslint-enable -->
    </div>
    <div class="u-flex-1 section--details u-envelop-width u-width-100">
      <component
        :is="selectedComponent"
        :alert="selectedAlert"
        :change-task-status="changeTaskStatus"
        :formatter="format"
      />
    </div>
  </div>
</template>

<script>
import recommendationsMixin from '@/utils/mixins/recommendationsMixin';
import PriceCompressionDetail from '@/components/pages/recommendations/detail-views/priceCompressionDetail.vue';
import OrganicGrowthDetail from '@/components/pages/recommendations/detail-views/organicGrowthDetail.vue';
import OrganicGrowth3PDetail from '@/components/pages/recommendations/detail-views/organicGrowth3PDetail.vue';
import OrganicGrowthOthersDetail from '@/components/pages/recommendations/detail-views/organicGrowthOthersDetail.vue';
import ThreePVariantsDetail from '@/components/pages/recommendations/detail-views/3PVariantsDetail.vue';
import compOOSDetail from '@/components/pages/recommendations/detail-views/competitorOOS.vue';
import PredictiveOOSWithPODetail from '@/components/pages/recommendations/detail-views/PredictiveOOSWithPODetail.vue';
import PredictiveOOSWithoutPODetail from '@/components/pages/recommendations/detail-views/PredictiveOOSWithoutPODetail.vue';
import PredictiveOOSCombined from '@/components/pages/recommendations/detail-views/PredictiveOOSCombined.vue';
import UnavailableSKUsDetail from '@/components/pages/recommendations/detail-views/UnavailableSKUsDetail.vue';
import localizedInventoryDetail from '@/components/pages/recommendations/detail-views/localizedInventoryDetail.vue';
import lostBuyBoxDetail from '@/components/pages/recommendations/detail-views/lostBuyBoxDetail.vue';
import priceDropIncreaseDetail from '@/components/pages/recommendations/detail-views/priceDropIncrease.vue';
import lowInventoryDetail from '@/components/pages/recommendations/detail-views/lowInventory.vue';
import shareOfSearchChangeDetail from '@/components/pages/recommendations/detail-views/shareOfSearch.vue';
import salesDropDetail from '@/components/pages/recommendations/detail-views/salesDropDetail.vue';
import salesIncreaseDetail from '@/components/pages/recommendations/detail-views/salesIncreaseDetail.vue';
import marketShareDetail from '@/components/pages/recommendations/detail-views/marketShareDetail.vue';

import { compRecommendationList as blackListRecommendation } from '@/utils/helpers/constant.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  components: {
    PriceCompressionDetail,
    OrganicGrowthDetail,
    OrganicGrowth3PDetail,
    OrganicGrowthOthersDetail,
    ThreePVariantsDetail,
    compOOSDetail,
    PredictiveOOSWithPODetail,
    PredictiveOOSWithoutPODetail,
    UnavailableSKUsDetail,
    localizedInventoryDetail,
    lostBuyBoxDetail,
    priceDropIncreaseDetail,
    lowInventoryDetail,
    shareOfSearchChangeDetail,
    salesDropDetail,
    salesIncreaseDetail,
    marketShareDetail,
    PredictiveOOSCombined
  },
  mixins: [recommendationsMixin],
  props: {
    changeTaskStatus: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      tippyOptions: {
        theme: 'page-snapshot',
        duration: [0, 0],
        placement: 'right',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    selectedComponent() {
      if (
        this.fetchedRecommendationName ===
        'Price compression recommendations all'
      ) {
        return 'PriceCompressionDetail';
      } else if (
        this.fetchedRecommendationName ===
        'Organic growth client recommendations all'
      ) {
        return 'OrganicGrowthDetail';
      } else if (
        this.fetchedRecommendationName ===
        'Organic growth 3pt recommendations all'
      ) {
        return 'OrganicGrowth3PDetail';
      } else if (
        this.fetchedRecommendationName ===
        'Organic growth comp recommendations all'
      ) {
        return 'OrganicGrowthOthersDetail';
      } else if (this.fetchedRecommendationName === 'Tagged 3pt list all') {
        return 'ThreePVariantsDetail';
      } else if (
        this.fetchedRecommendationName === 'Comp oos amazon recommendations all'
      ) {
        return 'compOOSDetail';
      } else if (
        this.fetchedRecommendationName === 'Predicted oos client with po all'
      ) {
        return 'PredictiveOOSWithPODetail';
      } else if (
        this.fetchedRecommendationName === 'Predicted oos client no po all'
      ) {
        return 'PredictiveOOSWithoutPODetail';
      } else if (
        this.fetchedRecommendationName === 'Predicted OOS combined all'
      ) {
        return 'PredictiveOOSCombined';
      } else if (this.fetchedRecommendationName === 'Unavailable SKUs all') {
        return 'UnavailableSKUsDetail';
      } else if (this.fetchedRecommendationName === 'FC inventory alerts all') {
        return 'localizedInventoryDetail';
      } else if (this.fetchedRecommendationName === 'Buybox loss all') {
        return 'lostBuyBoxDetail';
      } else if (this.fetchedRecommendationName === 'Price Decrease Alert') {
        return 'priceDropIncreaseDetail';
      } else if (this.fetchedRecommendationName === 'Price Increase Alert') {
        return 'priceDropIncreaseDetail';
      } else if (this.fetchedRecommendationName === 'Low Inventory') {
        return 'lowInventoryDetail';
      } else if (this.fetchedRecommendationName.startsWith('Search share')) {
        return 'shareOfSearchChangeDetail';
      } else if (this.fetchedRecommendationName === 'Sales decrease') {
        return 'salesDropDetail';
      } else if (this.fetchedRecommendationName === 'Sales increase') {
        return 'salesIncreaseDetail';
      } else if (
        this.fetchedRecommendationName.startsWith('Market Share') ||
        this.fetchedRecommendationName.startsWith('Market share')
      ) {
        return 'marketShareDetail';
      }
      return null;
    },
    enableSDP() {
      if (
        blackListRecommendation.indexOf(this.fetchedRecommendationName) !== -1
      ) {
        return false;
      }
      return true;
    }
    // 3p asin recommendation changes
    // categoryText () {
    //   let linkString = this.selectedAlert.attributes.bc_category_alert_type
    //   let linkArr = linkString.split('›')
    //   if (linkArr.length === 1) {
    //     return
    //   }
    //   linkArr.pop()
    //   return linkArr.join('›')
    // },
    // newLink () {
    //   let linkString = this.selectedAlert.attributes.bc_category_alert_type
    //   let linkArr = linkString.split('›')
    //   let newLink = linkArr.slice(-1)[0]
    //   return newLink
    // }
  },
  created() {},
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    },
    deselectAlert() {
      this.$store.commit('CLEAR_DETAIL_VIEW');
      this.$store.commit('CLEAR_HARD_FETCHED_SKU_DETAILS');
      if (this.jumpedFromAll) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          query: {
            name: this.fetchedRecommendationName,
            type: this.fetchedRecommendationType,
            bc_recommendation_type: this.fetchedBcRecommendationType
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="css">
.recommendation--detail-view {
  background-color: white;
}
.recommendation--detail-view .section--top {
  padding-left: 80px;
  border-bottom: 1px solid #e9eaeb;
  position: sticky;
  top: 0%;
  z-index: 9;
  background-color: #fff;
}
.recommendation--detail-view .section--details {
  padding-left: 80px;
}
.back-btn {
  height: 24px !important;
  width: 32px !important;
  padding: 0 !important;
  left: 25px;
  transform: rotate(0.5turn);
}
.back-arrow {
  position: absolute;
  left: 25px;
}
.back-arrow .icon-arrow_back:hover {
  color: #2b333b;
  fill: #2b333b;
}
</style>
