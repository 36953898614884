var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayDarsValue)?_c('div',[_c('div',{staticClass:"dataBarContainer u-flex-wrap-wrap u-width-100 u-display-flex u-spacing-pb-s"},_vm._l((_vm.displayDarsValue),function(bar,index){return _c('div',{key:index,class:[{ active: _vm.setActiveClass(bar) }]},[_c('div',{staticClass:"skuList",attrs:{"id":"tooltipSKUs"}},[_c('div',{staticClass:"u-spacing-ml-s u-spacing-mt-s u-spacing-mb-m u-color-grey-base u-font-size-5 u-font-weight-600"},[_vm._v(" "+_vm._s(bar.toolTipHeading)+" ")]),_vm._l((bar.values ? bar.values : [bar]),function(sku,skuIndex){return _c('div',{key:skuIndex,staticClass:"u-spacing-m-s"},[_c('rb-sku',{attrs:{"id":'tooltipSKU',"title":sku[_vm.barTooltipKey],"asin":sku[_vm.asinKey],"image-url":sku[_vm.imageUrlKey],"show-image":sku[_vm.imageUrlKey] !== undefined && sku[_vm.imageUrlKey] !== null,"product-page-url":sku[_vm.asinHyperLinkKey],"enable-s-d-p":_vm.enableSDP,"rb-asin-tippy-text":_vm.rbAsinTippyText,"copy-clipboard-text":_vm.copyClipboardText},on:{"navigateToSDP":_vm.navigateToSDP}})],1)})],2),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            html: '#tooltipSKUs',
            theme: 'rb-tooltip-sku',
            placement: 'top',
            interactive: true,
            reactive: true,
            arrow: false
          }),expression:"{\n            html: '#tooltipSKUs',\n            theme: 'rb-tooltip-sku',\n            placement: 'top',\n            interactive: true,\n            reactive: true,\n            arrow: false\n          }"}],staticClass:"dataBar u-spacing-mb-xs u-font-size-7 u-display-flex u-spacing-mr-s u-flex-justify-content-center u-flex-align-items-center",class:_vm.setColorAndWidth(bar),style:(_vm.setStyleColor(bar)),on:{"click":function($event){return _vm.emitThisBarClickEvent(bar, index)}}},[(bar.showIcon)?_c('span',{staticClass:"bar-value"},[(bar.showCurrencyInIcon)?_c('div',{staticClass:"u-position-absolute currency-in-icon z-index-2 u-color-white-base"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" ")]):_vm._e(),_c('rb-icon',{class:[_vm.iconSize(bar)],attrs:{"icon":bar.showIcon}})],1):(bar.showValue)?_c('span',{staticClass:"bar-value",class:bar.type === 'ORGANIC' ? 'organic-value' : ''},[_vm._v(_vm._s(bar.showValue))]):_vm._e()])])}),0)]):_vm._e(),(_vm.legendsValue && _vm.sortedLegends)?_c('div',{staticClass:"legendsContainer u-display-flex u-spacing-pt-xs u-font-size-6"},[_c('div',{staticClass:"legend u-display-flex u-spacing-mr-l",class:{ active: (_vm.activeClassMapObj[_vm.clientName] || {}).active },on:{"click":function($event){return _vm.legendClick(_vm.clientName)},"mouseover":function($event){return _vm.legendMouseOver(_vm.clientName)},"mouseleave":function($event){return _vm.legendMouseLeave(_vm.clientName)}}},[_c('div',{staticClass:"legend__color u-display-flex u-spacing-mr-xs",class:'u-bg-color-' + (_vm.colorMapObj[_vm.clientName] || _vm.colorMapObj['Others']),style:({
          'background-color':
            (_vm.colorMapObj || {})[_vm.clientName] || (_vm.colorMapObj || {})['Others']
        })}),(_vm.legendsValue !== null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s(_vm.legendsValue[_vm.clientName])+" ")]):_vm._e(),(_vm.legendsValue === null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s(_vm.clientName)+" ")]):_vm._e()]),_vm._l((_vm.computedSortedLegends),function(val,sortedLegendsIndex){return _c('div',{key:sortedLegendsIndex,staticClass:"legend u-display-flex u-spacing-mr-l",class:{ active: (_vm.activeClassMapObj[val[_vm.keyToCheck]] || {}).active },on:{"click":function($event){return _vm.legendClick(val[_vm.keyToCheck])},"mouseover":function($event){return _vm.legendMouseOver(val[_vm.keyToCheck])},"mouseleave":function($event){return _vm.legendMouseLeave(val[_vm.keyToCheck])}}},[_c('div',{staticClass:"legend__color u-display-flex u-spacing-mr-xs",class:'u-bg-color-' + _vm.colorMapObj[val[_vm.keyToCheck]],style:({
          'background-color': (_vm.colorMapObj || {})[val[_vm.keyToCheck]]
        })}),(_vm.legendsValue !== null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s(_vm.legendsValue[val[_vm.keyToCheck]])+" ")]):_vm._e(),(_vm.legendsValue === null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s(val[_vm.keyToCheck])+" ")]):_vm._e()])}),(_vm.activeClassMapObj['Others'] !== undefined)?_c('div',{staticClass:"legend u-display-flex u-spacing-mr-l",class:{ active: _vm.activeClassMapObj['Others'].active },on:{"click":function($event){return _vm.legendClick('Others')},"mouseover":function($event){return _vm.legendMouseOver('Others')},"mouseleave":function($event){return _vm.legendMouseLeave('Others')}}},[_c('div',{staticClass:"legend__color u-display-flex u-spacing-mr-xs",class:'u-bg-color-' + _vm.colorMapObj['Others'],style:({
          'background-color': _vm.colorMapObj['Others']
        })}),(_vm.legendsValue !== null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s(_vm.legendsValue['Others'])+" ")]):_vm._e(),(_vm.legendsValue === null)?_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v(" "+_vm._s('Others')+" ")]):_vm._e()]):_vm._e(),(_vm.customLegends === true && _vm.showAdsLegendsValue.showRetailer)?_c('div',{staticClass:"legend u-display-flex u-spacing-mr-l",class:{ active: _vm.activeClassMapObj[this.retailerKey].active },on:{"click":function($event){return _vm.legendClick(_vm.retailerKey)},"mouseover":function($event){return _vm.legendMouseOver(_vm.retailerKey)},"mouseleave":function($event){return _vm.legendMouseLeave(_vm.retailerKey)}}},[_c('div',{staticClass:"legend__color u-display-flex u-spacing-mr-xs",class:'u-bg-color-' + _vm.colorMapObj[this.retailerKey],style:({
          'background-color': (_vm.colorMapObj || {})[this.retailerKey]
        })}),_c('div',{staticClass:"legend__text u-display-flex"},[_vm._v("Amazon Brands")])]):_vm._e(),(_vm.customLegends === true && _vm.showAdsLegendsValue.showAds)?_c('div',{staticClass:"u-display-flex u-spacing-mr-l"},[(_vm.colorMapObj['Ads'])?_c('div',{staticClass:"legend__color u-display-flex u-spacing-mr-xs",class:'u-bg-color-' + _vm.colorMapObj['Ads'],style:({
          'background-color': (_vm.colorMapObj || {})['Ads']
        })}):_vm._e(),_c('div',{staticClass:"legend__text u-display-flex u-spacing-mr-m"},[_c('div',{staticClass:"u-spacing-mr-xs u-color-grey-lighter"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" ")]),_vm._v(" AD ")]),_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-position-relative u-spacing-mr-xxs"},[_c('div',{staticClass:"u-position-absolute z-index-2 u-color-grey-white u-font-size-7 sponsored-ad-static-legend-currenct-in-icon"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" ")]),_c('rb-icon',{staticClass:"rb-icon--x-medium u-color-grey-lighter u-position-absolute sponsored-ad-static-legend-icon",attrs:{"icon":"videocam"}})],1),_c('div',{staticClass:"u-spacing-pl-l"},[_vm._v("Sponsored Brand Video AD")])])]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }