const InputBudgetPlan = () =>
  import(
    '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/index.vue'
  );
const SelectLevel = () =>
  import('@/pages/budget-planner-v2/create-plan/steps/selectLevel/index.vue');
const SeasonalityStep = () =>
  import(
    '@/pages/budget-planner-v2/create-plan/steps/seasonalityStep/index.vue'
  );
const PreviewStep = () =>
  import('@/pages/budget-planner-v2/create-plan/steps/previewStep/index.vue');

const Automations = () =>
  import('@/pages/budget-planner-v2/create-plan/steps/automations/index.vue');

export const steps = (vueRef, automationsConfigEnabled) => {
  const thisYear = new Date().getFullYear();
  const finalSteps = [
    {
      name: 'Select distribution level',
      header: {
        title: 'How do you want to define your budget?',
        desc: [
          'Choose Retailer Categorization using either the Profile and Portfolio or Internal Categorization to allocate your budgets to one or more categories defined within the Campaign taxonomy.'
        ]
      },
      keyToRead: 'SelectLevel',
      breadCrumbTitle: 'How do you want to define your budget?',
      textMapping: 'SelectLevel',
      component: SelectLevel
    },
    {
      name: 'Input budget plan',
      header: {
        title: 'Enter budget for distribution levels',
        desc: [
          'You can enter your budget plan at either Level 1 or Level 2, ensuring precise alignment with your strategy. Choose Level 1 for high-level allocation, Level 2 for detailed allocation',
          'or both for maximum control. To exclude an entity from optimization or tracking, just leave its budget entry box empty.'
        ]
      },
      keyToRead: 'InputBudgetPlan',
      breadCrumbTitle: 'Enter budget for distribution levels',
      textMapping: 'InputBudgetPlan',
      component: InputBudgetPlan
    },
    {
      name: 'Level distribution',
      header: {
        title: `Level distribution`,
        desc: ['Heres your budget plan distributed across the selected levels']
      },
      component: PreviewStep,
      keyToRead: 'LevelDistribution',
      textMapping: 'LevelDistribution',
      breadCrumbTitle: 'Level distribution'
    },
    {
      name: 'Comulative Graph',
      header: {
        title:
          'Cumulative budget plan for the year ' +
          `${vueRef.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear}`
      },
      breadCrumbTitle: 'Cumulative Graph',
      keyToRead: 'uploadStep',
      textMapping: 'uploadStep',
      component: SeasonalityStep
    }
  ];
  if (automationsConfigEnabled) {
    finalSteps[4] = finalSteps[3];
    finalSteps[5] = finalSteps[4];
    finalSteps[3] = {
      name: 'Automations',
      header: {
        title: `Bid and Budget Recommendation Constraints`,
        desc: [
          'CIQ AI now takes decisions automatically, saving your time. Floor & ceiling values can be added for Bid or Budget actions, in case there is any specific business constraints.'
        ]
      },
      component: Automations,
      keyToRead: 'Automations',
      textMapping: 'Automations',
      breadCrumbTitle: 'Bid and Budget Recommendation Constraints'
    };
  }
  return finalSteps;
};
