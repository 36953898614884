<template>
  <div v-if="searchTermsTableService">
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="brandViewTable"
      v-show="!showSearchTermsTable"
    >
      <!-- will be replaced by benchmarkBrands -->
      <rb-insights-table
        :table-column="brandTableColDef"
        :table-row="table1Data"
        :grid-options="brandTableGridOptions"
      />
    </div>
    <div v-show="showSearchTermsTable && !computedLoading && !computedError">
      <TopContributorsTab
        :key="tabKey"
        class="u-spacing-mb-m u-spacing-ml-m"
        :button-options="buttonOptions"
        @buttonChanged="renderTable"
      />
      <rb-insights-table
        :table-column="searchTableColDef"
        :table-row="table2Data"
        sorting-change-event="brandStrengthSortEvent"
        :grid-options="searchTermsTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import BrandView from '@/components/pages/insights/amazon/cva/services/brandStrength/BrandView.js';
import SearchTermsTableService from '@/components/pages/insights/amazon/cva/services/brandStrength/SearchTerms.js';
import benchmarkBrands from '@/components/pages/insights/amazon/share-of-voice/templates/benchmarkBrands';
import skuWithRating from '@/components/basic/skuWithRating.vue';
import loader from '@/components/basic/loader';
import {
  isCloseDC,
  getUpdatedTrendOptions,
  CONSTANTS
} from '@/components/pages/insights/amazon/cva/utils';
import TopContributorsTab from '@/components/pages/insights/amazon/shortage-invoices/TopContributorsTab.vue';

export default {
  name: 'BrandStrength',
  components: {
    benchmarkBrands,
    skuWithRating,
    loader,
    TopContributorsTab
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewTable: null,
      searchTermsTableService: null,
      selectedView: null,
      searchTermsTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      brandTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      buttonOptions: CONSTANTS.buttonOptions,
      tabKey: 0
    };
  },
  computed: {
    showSearchTermsTable() {
      return this.selectedWidget === 'Search Terms';
    },
    computedLoading() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.loading ||
          this.searchTermsTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.error ||
          this.searchTermsTableService.error
        );
      }
    },
    brandTableColDef() {
      const columns = this.brandViewTable?.getColumnDefs() || [];
      return [...columns];
    },
    searchTableColDef() {
      const columns = this.searchTermsTableService?.getColumnDefs() || [];
      return [...columns];
    },
    table1Data() {
      return [];
    },
    table2Data() {
      this.searchTermsTableGridOptions.api?.sizeColumnsToFit();
      return this.searchTermsTableService?.getTableData() || [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.brandViewTable = new BrandView('cva', 'table1', 4459, 1000);
    this.searchTermsTableService = new SearchTermsTableService(
      'cva',
      'table2',
      459,
      100
    );
    this.searchTermsTableService.fetchData(this.pageSettings);
    this.resetSelectedTab();
    this.fetchLiner();
  },
  created() {
    Vue.component('skuWithRating', skuWithRating);
  },
  methods: {
    resetSelectedTab(selectedButton = CONSTANTS.defaultTrendSelection) {
      this.buttonOptions = getUpdatedTrendOptions(
        this.buttonOptions,
        selectedButton
      );
      this.tabKey++;
    },
    renderTable(selectedButton) {
      this.searchTermsTableService.fetchData(this.pageSettings, selectedButton);
      this.resetSelectedTab(selectedButton);
    },
    async fetchLiner() {
      const x = await this.searchTermsTableService.fetchInsights(
        ['Double click Brand Strength'],
        this.pageSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    }
  }
};
</script>
