<template>
  <div class="u-display-flex u-flex-align-items-center u-flex-wrap-yes">
    <div
      v-for="(item, index) in allGoals"
      :key="index"
      style="align-self: stretch"
      class="u-spacing-pr-m u-spacing-pb-xl u-display-flex"
      data-cy="allGoals"
    >
      <div
        class="u-cursor-pointer u-display-flex u-flex-direction-column u-font-size-4 box-shadow-goals u-max-width-400px u-height-100 u-spacing-p-m u-border-radius-s"
        :class="
          item.isSelected &&
          'u-border-all u-border-color-blue-base u-border-width-s'
        "
        @click="bulkCampaignCreator(index)"
      >
        <div
          class="u-font-weight-600 u-spacing-pb-s"
          :data-cy="`goal-${item.label}`"
        >
          {{ item.label }}
        </div>
        <div
          class="u-font-size-7"
          :data-cy="`goal-${item.uiComponent}`"
        >
          {{ item.uiComponent }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    allGoals: {
      type: Array,
      default: () => []
    },
    isSingleSelect: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  methods: {
    bulkCampaignCreator(selectedGoal) {
      if (this.isSingleSelect) {
        this.allGoals.forEach((item) => {
          item.isSelected = false;
        });
      }
      this.allGoals[selectedGoal].isSelected
        ? (this.allGoals[selectedGoal].isSelected = false)
        : (this.allGoals[selectedGoal].isSelected = true);
      this.$emit('goalsSelection', this.allGoals);
    }
  }
};
</script>
<style lang="css" scoped>
.box-shadow-goals {
  min-height: 96px;
  box-shadow: 0px 0px 4px #caccce;
}
</style>
