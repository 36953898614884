import HomeV2 from '@/components/homeV2.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import campaignManagementRoutes from '@/pages/campaign-management/retailers/samsclub_api/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import { filters } from '@ciq-dev/ciq-ui';
import RetailLinkConnect from '@/components/pages/ciq-internal-tools/retail-link-connect.vue';
import CreateCampaignWalmart from '@/components/ams/campaign_creation/createCampaignWalmart.vue';
import CreateAdgroup from '@/components/ams/ad_group_creation/setup_configs/createAdgroup.vue';
import CampaignCreated from '@/components/ams/campaign_creation/walmart_steps/campaignCreated/index.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import Strategies from '@/components/pages/strategies/strategies.vue';
import CreateStrategy from '@/components/pages/strategies/create/createStrategy.vue';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import {
  digitalShelfRoute,
  campaignOptimizerChildrenRoutes,
  proxyLinksRoute,
  reportsRoute
} from '@/router/commonRoutePaths.js';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const samsclub_apiRoutes = {
  path: '/us/samsclub_api/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        const samsClubApiMarketInsightsConfig = filters.config_check(
          'feature.insights.samsclub_api_super_sov_v2'
        );
        if (samsClubApiMarketInsightsConfig.enable) {
          return 'digital_shelf/market_insights';
        }
        return 'advertising/campaign_optimizer';
      }
    },
    reportsRoute,
    digitalShelfRoute,
    {
      path: 'advertising',
      name: 'advertising',
      redirect() {
        return 'advertising/campaign_optimizer';
      },
      component: PassThroughRoute,
      children: [
        {
          path: 'hourly_bidder',
          component: PassThroughRoute,
          props: true,
          children: [
            {
              path: '',
              name: 'strategies',
              title: 'Hourly Bidder',
              component: Strategies,
              props: true
            },
            {
              path: 'create',
              name: 'create-strategy',
              component: CreateStrategy
            },
            {
              path: 'edit',
              name: 'edit-strategy',
              component: CreateStrategy
            }
          ]
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: campaignManagementRoutes.routes
        },
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes
        },
        {
          path: 'create_campaign',
          name: 'create_campaign',
          title: 'Create Campaign',
          component: CreateCampaignWalmart,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        },
        {
          path: 'create_ad_group',
          name: 'create_ad_group',
          title: 'Create Ad Group',
          component: CreateAdgroup,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        },
        {
          path: 'campaign_created',
          name: 'campaign_created',
          title: 'Campaign Creator',
          component: CampaignCreated,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        }
      ]
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'retail_link_connect',
      name: 'retail_link_connect',
      component: RetailLinkConnect
    },
    {
      path: 'retail-link-connect',
      name: 'retail-link-connect',
      redirect() {
        return 'retail_link_connect';
      }
    },
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'file-download',
      component: fileDownload
    },
    {
      path: 'ciq-efundamentals-announcement',
      name: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    proxyLinksRoute
  ]
};

export { samsclub_apiRoutes };
