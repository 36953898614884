<template>
  <div class="u-display-flex u-flex-direction-column">
    <div
      class="u-spacing-mb-m u-spacing-ph-l u-bg-color-grey-white sticky u-header-box-shadow u-z-index-100"
    >
      <div
        class="date-type-selector-container u-display-flex u-flex-justify-content-flex-end u-spacing-mb-xs"
      >
        <span
          v-for="(dateType, index) in config.widgets.inputDateSelections"
          :key="index"
          :class="{
            'selected-input-date': dateType.key == getInputDateSelection.key,
            'u-color-grey-lighter': dateType.key != getInputDateSelection.key
          }"
          class="input-date-item u-cursor-pointer u-font-size-7 u-spacing-ph-s u-border-width-s u-border-right u-border-color-grey-x-light"
          @click="changeInputDateType(dateType)"
          >{{ dateType.title }}</span
        >
      </div>
      <rb-filter-panel
        id="shipment_tracker"
        :key="filterKey"
        :loader-status="loaderStatus"
        :disable-between-operator="false"
        :page="'shipment_tracker'"
        class="filter_shipment_track"
        :get-filter-instance="getFilterInstance"
        :data="filterData"
        :primary="primaryFilterData"
        :listener-event="filterEventAndLocalStorageKey"
        :default-date-post-range="'last60Days'"
        :state="filterState"
        :use-different-date-for-canned-dates="getInputDateSelection.todayKey"
        :config="config.filters"
        :emit="config.filters.emit"
        :has-search="config.filters.hasSearch"
        :hide-filter="config.filters.hideFilter"
        :allow-date-compare="true"
        :new-date="true"
        :enable-save="true"
        :additional-ranges="additionalRanges"
        :max-date="getInputDateSelection.calenderKey"
        :filter-v2="filterV2"
        :show-ingestion-progress="filterV2"
        :enable-remove-all="false"
        :on-create="onCreate"
        @savedFilterApplied="filterReinitialise"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Shipment Tracker'" />
        </div>
      </rb-filter-panel>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
        :apply-negative-margin="true"
      />
    </div>

    <section class="u-envelop-width u-width-100">
      <card-widget
        :show-loader="false"
        :config="config.widgets.widget1"
        :data="getShipmentOverview"
        class="u-spacing-mh-m"
        data-cy="shipment-overview-widget"
      >
        <div
          slot="header"
          class="u-width-100"
        >
          <div
            class="u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-space-between u-flex-1"
          >
            <div class="u-font-size-3 u-font-weight-600 u-color-grey-lighter">
              {{ config.widgets['widget1'].header.title }}
            </div>
            <div class="u-display-flex sku-collection-selection-container">
              <span
                class="display_in u-font-size-4 u-font-weight-600 u-color-grey-x-light"
                >Display in:</span
              >
              <div
                v-for="(type, index) in getAllSkuCollectionTypes"
                :key="index"
                class="global-radio-wrapper"
              >
                <div class="u-spacing-ml-s">
                  <rb-radio
                    v-model="selectedCollectionType"
                    :native-value="type"
                  >
                    <div>{{ type }}</div>
                  </rb-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="body">
          <div
            class="overview-section u-display-flex u-flex-direction-column u-overflow-auto u-spacing-mb-s"
          >
            <div
              class="u-display-flex u-flex-justify-content-space-around u-spacing-ph-m"
            >
              <div
                v-for="(value, key) in getShipmentOverview.data"
                :key="key"
                class="u-flex-1 u-spacing-ph-s u-spacing-pb-m three_value_card"
              >
                <three-value-metric
                  :loading="getShipmentOverview.load"
                  :error="getShipmentOverview.error"
                  value3tooltip="Value"
                  :value1tooltip="value.meta.cardTooltip"
                  :title="value.meta.cardTitle"
                  :value2meta="value.meta.value2Meta"
                  :value1="value.data.m1"
                  :value2="value.data.m2"
                  :value3="value.data.m3"
                />
              </div>
            </div>
          </div>
        </div>
      </card-widget>
      <div
        class="table-section u-spacing-mt-m u-position-relative u-spacing-mh-m"
      >
        <div
          ref="trigger"
          v-tippy="tippyOptions"
          role="button"
          class="u-position-absolute u-font-size-5 custom-download-section"
        >
          <slot name="trigger">
            <rb-tooltip :label="'Download'">
              <div
                id="shipemt_download_trigger"
                class="u-cursor-pointer u-font-size-5"
              >
                <loader
                  class="fill--parent"
                  :loading="downloadLoading"
                  :color="'#007cf6'"
                />
              </div>
            </rb-tooltip>
          </slot>
        </div>
        <div id="download_shipment_section">
          <div
            class="card u-border-radius-s u-border-width-s u-border-all u-border-color-grey-xxx-light"
          >
            <div
              class="u-cursor-pointer custom-download-item u-spacing-p-m"
              @click="downloadTableWidget('widget2')"
            >
              Download PO Report
            </div>
            <div
              class="u-cursor-pointer custom-download-item u-spacing-p-m"
              @click="downloadTableWidget('widget3')"
            >
              Download PO + SKU Report
            </div>
          </div>
        </div>
        <div v-show="bShowPOTable">
          <card-widget
            :config="config.widgets.widget2"
            :data="poTableData"
            @downloadTableThroughComponent="downloadTriggered"
          >
            <div slot="body">
              <div
                v-if="getSelectedPOs.poList.length > 0"
                class="u-position-relative u-spacing-mb-s"
              >
                <div
                  class="navigation-bar u-font-size-8 u-bg-color-blue-base u-font-size-5 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-text-align-center u-spacing-mt-xs u-spacing-pr-s"
                >
                  <loader
                    class="fill--parent"
                    :loading="getSelectedPOs.load"
                    :color="'#007cf6'"
                  />
                  <div class="u-display-flex u-flex-align-items-center">
                    <div
                      class="u-display-flex u-flex-direction-column u-color-grey-white u-spacing-ph-m u-cursor-pointer"
                      @click="clearSelection(true)"
                    >
                      <rb-icon
                        class="rb-icon--medium"
                        :icon="'checkbox-selected'"
                      />
                      <span
                        class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-display-flex"
                      >
                        {{ getSelectedPOs.poList.length }}
                        <span class="u-spacing-pl-xs">Selected</span>
                      </span>
                    </div>
                    <div class="action-group-divider" />
                    <icon-text-actions
                      class="u-width-100 u-font-size-8"
                      :action="{ title: 'View SKUs', icon: 'sku-box' }"
                      @clicked="showPOsSummarySelected(true)"
                    />
                  </div>
                  <div
                    class="u-display-flex u-flex-direction-column u-color-grey-white u-spacing-ph-m u-cursor-pointer closeButton u-spacing-p-s u-spacing-mr-m u-border-radius-xs"
                    @click="clearSelection(true)"
                  >
                    <rb-icon
                      class="rb-icon--small"
                      :icon="'cross'"
                    />
                    <span
                      class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-display-flex"
                      >close</span
                    >
                  </div>
                </div>
                <div
                  class="u-font-size-6 u-display-flex u-spacing-mv-m u-flex-justify-content-center"
                >
                  <span class="u-spacing-ph-s u-color-grey-base"
                    >{{ getSelectedPOs.poList.length }} PO{{
                      getSelectedPOs.poList.length > 1 ? 's' : ''
                    }}
                    selected.</span
                  >
                  <span
                    v-if="!inSelectAllState"
                    class="u-color-blue-base u-cursor-pointer selection_link"
                    @click="selectAllPOsClicked"
                    >Select all {{ totalPOs }} POs</span
                  >
                  <span
                    v-else
                    class="u-color-blue-base u-cursor-pointer selection_link"
                    @click="clearSelection(true)"
                    >Clear selection</span
                  >
                </div>
              </div>
              <div class="u-display-flex u-flex-direction-column">
                <div class="u-display-flex">
                  <rb-insights-table
                    ref="PO_Details_Table"
                    :filter-instance="filterInstance"
                    :primary-filters-data="primaryFilterData"
                    :config="config.widgets.widget2"
                    :table-column="config.widgets.widget2.body.columns"
                    :table-row="poTableData.rows"
                    :grid-options="config.widgets.widget2.gridOptions"
                    :row-selection="rowSelection"
                    style="width: 100%"
                    :enable-client-side-sorting="false"
                    :enable-server-side-sorting="true"
                    :pagination="true"
                    :download-this-table="true"
                    :show-pagination-options="true"
                    :pagination-total-key="totalPOs"
                    :pagination-per-page-limit="
                      config.widgets.widget2.body.APIConfig.limit
                    "
                    :row-height="45"
                    @selectionChanged="poTableselectionChanged"
                  />
                </div>
              </div>
            </div>
          </card-widget>
        </div>
        <!-- making this show instead of v-if since destroying and recreating card widget causes issues with event dispatch -->
        <div v-show="!bShowPOTable">
          <card-widget
            :config="config.widgets.widget3"
            :data="SkuPOTableData"
            @downloadTableThroughComponent="downloadTriggered"
          >
            <span slot="left-header">
              <span
                class="u-cursor-pointer"
                @click="navigateBackClicked"
              >
                <rb-icon
                  class="left-back-icon u-spacing-mr-s u-color-grey-lighter rb-icon--x-medium"
                  icon="right-arrow"
                />
              </span>
            </span>
            <div slot="body">
              <div class="u-display-flex u-flex-direction-column">
                <div class="u-display-flex">
                  <rb-insights-table
                    ref="SKU_PO_Details_Table"
                    :filter-instance="filterInstance"
                    :primary-filters-data="primaryFilterData"
                    :config="config.widgets.widget3"
                    :table-column="config.widgets.widget3.body.columns"
                    :table-row="SkuPOTableData.rows"
                    :grid-options="config.widgets.widget3.gridOptions"
                    style="width: 100%"
                    :row-height="80"
                    :enable-client-side-sorting="false"
                    :enable-server-side-sorting="true"
                    :pagination="true"
                    :show-pagination-options="true"
                    :pagination-total-key="(SkuPOTableData || {}).totalRows"
                    :pagination-per-page-limit="
                      config.widgets.widget3.body.APIConfig.limit
                    "
                    :download-this-table="true"
                  />
                </div>
              </div>
            </div>
          </card-widget>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import loader from '@/components/basic/loader';
import widgetsConfig from './widgets';
import widgetsUtil from '@/components/widgetMixin';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import cardWidget from '@/components/widgets/cardWidget';
import threeValueMetric from '@/components/basic/threeValueMetric.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';

import utils from '@/utils/helpers/';
const SHIPMENT_TRACKER_STORAGE_KEY = 'SHIPMENT_TRACKER_STORAGE';

export default {
  components: {
    rbFilterPanel,
    cardWidget,
    threeValueMetric,
    loader,
    iconTextActions
  },
  mixins: [widgetsUtil],
  data() {
    return {
      filterEventAndLocalStorageKey: 'insights-shipment-tracker-filters',
      filterKey: 0,
      filterInstance: null,
      widgetsConfig,
      filterState: {
        getter: 'getInsightsShipmentTrackerFilters',
        setter: 'setInsightsShipmentTrackerFilters'
      },
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      selectedCollectionType: this.computedSelectedSkuCollectionType,
      selectedRows: [],
      toggleText: 'Show Sku Level Breakdown',
      showDownloadOptions: false,
      downloadLoading: false,
      config: {},
      additionalRanges: {
        add: ['last60Days'],
        order: {
          last60Days: 2
        }
      },
      rowSelection: {
        mode: 'multiple'
      },
      tippyInstance: null,
      showActiveFilterBanner: false
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#download_shipment_section',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        onShow: this.onShow,
        onHide: this.onHide,
        popperOptions: {
          Defaults: {
            onCreate: this.onTippyCreate
          }
        }
      };
    },
    totalPOs() {
      return (this.poTableData || {}).totalRows;
    },
    inSelectAllState() {
      return (
        (this.poTableData || {}).totalRows === this.getSelectedPOs.poList.length
      );
    },
    bShowPOTable() {
      return this.$store.getters.getShowPOTable;
    },
    getShipmentOverview() {
      let overViewSkuCollectionTypeData =
        this.config.widgets.widget1.custom.overViewCardsMeasures.map(
          (cardMeta) => {
            const cardInfo = {
              meta: JSON.parse(
                JSON.stringify(
                  cardMeta[this.computedSelectedSkuCollectionType].meta
                )
              ),
              data: JSON.parse(
                JSON.stringify(
                  cardMeta[this.computedSelectedSkuCollectionType].data
                )
              )
            };
            return cardInfo;
          }
        );

      const overViewResponse =
        (this.$store.getters.getShipmentOverviewData || {}).data || {};
      overViewSkuCollectionTypeData = overViewSkuCollectionTypeData.map(
        (cardInfo) => {
          const cardData = {
            data: this.mapValueKeyToData(overViewResponse, cardInfo.data),
            meta: cardInfo.meta
          };
          return cardData;
        }
      );
      return {
        data: overViewSkuCollectionTypeData,
        load: this.$store.getters.getShipmentOverviewData?.load,
        error: this.$store.getters.getShipmentOverviewData?.error
      };
    },
    poTableData() {
      return this.$store.getters.getShipmentPOData || {};
    },
    SkuPOTableData() {
      const widget3Config = this.config.widgets.widget3;
      // window resize event / Ag-grid resize events are not getting propegated, so manually triggering resizing.
      this.$nextTick(() => {
        widget3Config.gridOptions.api.sizeColumnsToFit();
      });
      return this.$store.getters.getShipmentSkuPOData || {};
    },
    computedSelectedSkuCollectionType() {
      return this.$store.getters.getSkuCollectionType || 'units';
    },
    getAllSkuCollectionTypes() {
      return this.$store.getters.getAllSkuCollectionTypes;
    },
    getInputDateSelection() {
      return this.$store.getters.getInputDateSelection;
    },
    getSelectedPOs() {
      return this.$store.getters.getSelectedPOs;
    },
    getPrimaryCrossWidgetColumn() {
      return this.$store.getters.getPrimaryCrossWidgetColumn;
    }
  },
  watch: {
    selectedCollectionType(currentType) {
      this.$store.dispatch('setSkuCollectionType', currentType);
    },
    primaryFilterData(value) {
      this.primaryFilterData = value;
    }
  },
  created() {
    this.config = widgetsConfig.config();
    let shipmentLocalStorage = this.$ciqlocalStorage.getItem(
      SHIPMENT_TRACKER_STORAGE_KEY
    );
    if (shipmentLocalStorage) {
      shipmentLocalStorage = JSON.parse(shipmentLocalStorage);
    } else {
      shipmentLocalStorage = this.config.widgets.inputDateSelections[2];
      shipmentLocalStorage = { timeSeriesInput: shipmentLocalStorage };
      this.$ciqlocalStorage.setItem(
        SHIPMENT_TRACKER_STORAGE_KEY,
        JSON.stringify(shipmentLocalStorage)
      );
    }
    this.$store.dispatch(
      'setInputDateType',
      shipmentLocalStorage.timeSeriesInput
    );
    this.fetchFilters(
      'shipment_tracker_dashboard',
      'FETCH_FILTERS_V2',
      'shipment_tracker'
    );
  },
  mounted() {
    this.selectedCollectionType = this.computedSelectedSkuCollectionType;
  },
  methods: {
    onCreate(instance) {
      this.filterInstance = instance;
    },
    filterReinitialise() {
      this.filterKey++;
    },
    onTippyCreate(event) {
      this.tippyInstance = event;
    },
    onShow(instance) {
      this.tippyInstance = instance;
    },
    onHide() {},
    mapValueKeyToData(columnFieldAndDataMap, columnFieldsAsValues) {
      const keyDataMap = {};
      Object.keys(columnFieldsAsValues).forEach((outputKey) => {
        const actualColumnKey = columnFieldsAsValues[outputKey];
        keyDataMap[outputKey] = columnFieldAndDataMap[actualColumnKey];
      });
      return keyDataMap;
    },
    async downloadTableWidget(widgetName) {
      try {
        this.tippyInstance.hide();
        this.downloadLoading = true;
        let widgetConfig = {};
        if (widgetName === 'widget2') {
          widgetConfig = this.config.widgets.widget2;
        } else {
          // since widget3 is not initiated during search of widget one it will not have the local filters
          // present in the cardWidget data. So we are manually copying that data to consider those filters also.
          const widget2Meta = JSON.parse(
            JSON.stringify(this.config.widgets.widget2.meta)
          );
          widgetConfig = this.config.widgets.widget3;
          widgetConfig.meta.localFilters = widget2Meta.localFilters;
        }
        const downloadConfigs = widgetConfig.header.actions.download;
        const downloadColumns = downloadConfigs.columnMap;
        // if the localstorage keys are messed up sometime timeseries dimension can come as object
        // hence this fall back to always pick from the state.
        widgetConfig.body.APIConfig.timeseriesDimension =
          this.getInputDateSelection.key;
        const downloadData = await this.$store.dispatch(
          downloadConfigs.action,
          widgetConfig
        );
        utils.performDownload(
          downloadData,
          downloadColumns,
          downloadConfigs.fileName
        );
        this.downloadLoading = false;
        this.showDownloadOptions = false;
      } catch (error) {
        this.downloadLoading = false;
        this.showDownloadOptions = false;
        this.$snackbar.open({
          message: 'Something Went Wrong. Please try again later',
          duration: 5000,
          actionText: ''
        });
      }
    },
    downloadTriggered() {
      this.showDownloadOptions = true;
    },
    selectAllPOsClicked() {
      this.config.widgets.widget2.gridOptions.api.selectAll();
      this.$store.dispatch('selectAllPOs', this.config.widgets.widget2);
    },
    showPOsSummarySelected() {
      this.config.widgets.widget3.body.APIConfig.page = 1;
      this.toggleSkuTable(false);
      if (this.getSelectedPOs.poList.length > 1) {
        this.config.widgets.widget3.header.title = `SHIPMENT SUMMARY OF ${this.getSelectedPOs.poList.length} PURCHASE ORDERS`;
      } else {
        this.config.widgets.widget3.header.title = `SHIPMENT SUMMARY OF ${this.getSelectedPOs.poList.length} PURCHASE ORDER`;
      }
      if (this.$refs.SKU_PO_Details_Table) {
        this.$store.dispatch('fetchSkuPOInfoList', this.config.widgets.widget3);
      }
    },
    toggleSkuTable(bShow) {
      this.$store.dispatch('setShowPOTable', bShow);
    },
    navigateBackClicked() {
      this.toggleSkuTable(true);
      this.clearSelection();
    },
    clearSelection() {
      this.selectedRows = [];
      this.config.widgets.widget2.gridOptions.api.deselectAll();
      this.$store.dispatch('setSelectedPOs', this.selectedRows);
    },
    poTableselectionChanged(event) {
      this.selectedRows = event.api.getSelectedNodes().map((row) => {
        return row.data[this.getPrimaryCrossWidgetColumn];
      });
      this.$store.dispatch('setSelectedPOs', this.selectedRows);
    },
    getFilterInstance(filter) {
      this.filterInstance = filter;
    },
    setCurrentDefaultOrderByForDateType(currentDataTypeKey) {
      const orderByList = [
        {
          dimension: currentDataTypeKey,
          direction: 'DESC'
        },
        {
          dimension: 'received_percentage_po',
          direction: 'ASC'
        }
      ];
      this.config.widgets.widget2.body.APIConfig.orderByList = orderByList;
    },
    changeInputDateType(inputTimeSeriesDate) {
      this.setCurrentDefaultOrderByForDateType(inputTimeSeriesDate.orderby_key);
      // to Achive the behaviour, change of date type (ordered_date -> shipment window start)
      // to not change the current date range selection, but min max limits of particular date type should be
      // updated in calander accoridingly. So storing the filters as custom in local storage and reinialising the filters in UI.
      let currentFilterStorage = this.$ciqlocalStorage.getItem(
        this.filterEventAndLocalStorageKey
      );
      currentFilterStorage = JSON.parse(currentFilterStorage);
      currentFilterStorage.date_range.name = 'custom';
      this.$ciqlocalStorage.setItem(
        this.filterEventAndLocalStorageKey,
        JSON.stringify(currentFilterStorage)
      );
      this.$ciqlocalStorage.setItem(
        SHIPMENT_TRACKER_STORAGE_KEY,
        JSON.stringify({ timeSeriesInput: inputTimeSeriesDate })
      );
      this.$store.dispatch('setInputDateType', inputTimeSeriesDate);
      this.$store.dispatch('fetchPOInfoList', this.config.widgets.widget2);
      this.$store.dispatch('fetchSkuPOInfoList', this.config.widgets.widget3);
      this.$store.dispatch(
        'fetchShipmentOverviewData',
        this.config.widgets.widget1
      );
      // recreating the filter in dom
      this.filterReinitialise();
    },
    fetchPOInfoList() {
      this.$store.dispatch('fetchPOInfoList', this.config.widgets.widget2);
    }
  }
};
</script>

<style lang="css" scoped>
#shipemt_download_trigger {
  width: 30px;
  height: 30px;
}
.three_value_card :last-child {
  padding-right: 0px;
}

.table-component {
  transition: 0.5s;
}
.transform-active {
  transform: translateX(100%);
  opacity: 0.5;
}
.transform-null {
  transform: translateX(0px);
  opacity: 1;
}
.sku-collection-selection-container {
  text-transform: capitalize;
  color: #4b5158;
}
.left-back-icon {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.selected-input-date {
  color: #007cf6;
}
.input-date-item:last-child {
  border-right: 0px;
}
.custom-download-section {
  right: 18px;
  top: 24px;
  z-index: 10;
}
.custom-download-section .custom-download-item:hover {
  background-color: #007cf6;
  color: #fff;
  border-radius: 4px;
  border-width: 1px;
  border: solid;
}
.display_in {
  text-transform: none;
}
.filter_shipment_track {
  z-index: 100 !important;
}
.action-group-divider {
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  height: 24px;
  margin: auto 16px;
}

.navigation-bar {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 124, 246, 0.9),
    rgba(0, 124, 246, 0.9) 50%,
    #007cf6
  );
  padding: 8px 0;
}

.closeButton:hover {
  background: rgba(43, 51, 59, 0.4);
  color: #fff;
}
.selection_link {
  text-decoration: underline;
}

.u-z-index-100 {
  z-index: 100;
}
</style>
