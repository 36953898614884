export function getKeywordsToAdd(tableKeywords = [], fetchedKeywords = []) {
  const fetchedKeywordsWithPrimaryKey = fetchedKeywords.map((item) => {
    item.key = item?.keywordText + '&' + item?.matchType;
    return item;
  });
  const keywordsToAdd = tableKeywords.filter((item) => {
    const isKeywordAlreadyPresent = fetchedKeywordsWithPrimaryKey.find(
      (fetchedItem) => fetchedItem.key === item?.key
    );
    if (isKeywordAlreadyPresent === undefined) {
      return true;
    } else return false;
  });
  return keywordsToAdd;
}

export function getKeywordsToUpdate(tableKeywords = [], fetchedKeywords = []) {
  const fetchedKeywordsWithPrimaryKey = fetchedKeywords.map((item) => {
    item.key = item?.keywordText + '&' + item?.matchType;
    return item;
  });
  let keywordsToUpdate = tableKeywords.filter((tableKeywordItem) => {
    const isKeywordBidUpdated = fetchedKeywordsWithPrimaryKey.find(
      (fetchedKeywordItem) =>
        tableKeywordItem?.key === fetchedKeywordItem.key &&
        tableKeywordItem.newBid !== fetchedKeywordItem?.bid
    );
    if (isKeywordBidUpdated) {
      return true;
    } else return false;
  });
  keywordsToUpdate = keywordsToUpdate.map((keywordItem) => {
    if (keywordItem.keywordId) {
      return keywordItem;
    } else {
      const fetchedFoundItem = fetchedKeywordsWithPrimaryKey.find(
        (fetchedKeywordItem) => fetchedKeywordItem.key === keywordItem.key
      );
      const keywordId = fetchedFoundItem?.keywordId;
      keywordItem.keywordId = keywordId;
      return keywordItem;
    }
  });
  return keywordsToUpdate;
}

export function getKeywordsToDelete(tableKeywords = [], fetchedKeywords = []) {
  const fetchedKeywordsWithPrimaryKey = fetchedKeywords.map((item) => {
    item.key = item?.keywordText + '&' + item?.matchType;
    return item;
  });
  const keywordsToDelete = fetchedKeywordsWithPrimaryKey.filter(
    (fetchedKeywordItem) => {
      const isKeywordPresentInTableKeywords = tableKeywords.find(
        (tableKeywordItem) => tableKeywordItem.key === fetchedKeywordItem?.key
      );
      if (isKeywordPresentInTableKeywords) {
        return false;
      } else return true;
    }
  );
  return keywordsToDelete;
}
