<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl">
    <createCampaignHeader
      :step="step"
      class="u-spacing-pt-m"
    />
    <div class="">
      <div class="u-spacing-pt-xl u-display-flex">
        <div class="timePeriod_wrp u-display-flex u-flex-wrap-yes">
          <div class="u-spacing-pr-xxl">
            <div
              class="heading--input u-font-size-5 u-font-weight-600 u-spacing-mb-s"
              data-cy="startDate"
            >
              Start Date:
            </div>
            <div v-click-outside="hideStartCalendar">
              <span
                class="date-container u-border-radius-s u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                data-cy="clickStartDateCal"
                @click="openCalendar('startDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span
                  class="u-line-height-1_2"
                  data-cy="formattedStartDate"
                >
                  {{ formatDateUI(startDate) }}</span
                >
              </span>
              <datepicker
                ref="startDate"
                v-model="startDate"
                style="z-index: 104"
                :disabled-dates="disableStartDates"
                input-class="hide_calendar_strategy"
                data-cy="datePicker"
              />
            </div>
          </div>
        </div>

        <div class="u-spacing-pr-xxl">
          <div
            class="heading--input u-font-size-5 u-font-weight-600 u-spacing-mb-s"
            data-cy="enddate"
          >
            End Date: <span class="u-color-grey-lighter">Optional</span>
          </div>
          <div
            v-click-outside="hideEndCalendar"
            class="u-max-width-100px"
          >
            <span
              class="date-container u-border-radius-s u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              data-cy="clickEndDateCal"
              @click="openCalendar('endDate')"
            >
              <rb-icon
                :icon="'calendar'"
                class="rb-icon--small u-spacing-mr-xs"
                data-cy="calendarIcon"
              />
              <span
                v-if="!noEndDate"
                class="u-line-height-1_2"
                data-cy="formattedStartDate"
              >
                {{ formatDateUI(endDate) }}</span
              >
              <span
                v-else
                class="u-line-height-1_2"
                data-cy="noEndDateTxt"
              >
                No end date
              </span>
            </span>
            <datepicker
              ref="endDate"
              v-model="endDate"
              class="date-picker-campaign-creation"
              :disabled-dates="disableEndDates"
              input-class="hide_calendar_strategy"
              data-cy="endDatePicker"
            >
              <div
                slot="beforeCalendarHeader"
                :class="
                  noEndDate && 'u-color-white-base u-bg-color-orange-base'
                "
                class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
                data-cy="noEndDate"
                @click="selectNoEndDate"
              >
                No end date
              </div>
            </datepicker>
          </div>
        </div>
        <div class="u-spacing-pl-s">
          <div
            class="u-border-left u-spacing-pv-s u-border-color-grey-xxx-light u-border-width-s"
          >
            <div
              class="u-spacing-pl-xxl u-font-size-5 u-line-height-1-3 u-color-grey-lighter"
              data-cy="datePickerDesc"
            >
              Choosing no end date means your campaign will run <br />
              longer, and a longer timeframe can give you better insights <br />
              on search terms and keyword performance to further <br />
              optimize your campaign.
            </div>
          </div>
        </div>
      </div>
      <createCampaignFooter
        :disable-right-btn="false"
        class="u-margin-top-40"
        :btn-right-text="step && step.useNextButton ? 'Next' : 'Save'"
        data-cy="campaignCreationFooter"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </div>
  </div>
</template>

<script>
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    Datepicker
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    suggestedCampaignIndex: 0,
    noEndDate: true,
    dateFormat: 'YYYYMMDD',
    startDate: null,
    endDate: null,
    disableStartDates: {
      from: new Date(new Date().getFullYear() + 1, 0, 1),
      to: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    },
    disableEndDates: {
      from: new Date(new Date().getFullYear() + 1, 0, 1),
      to: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    }
  }),
  computed: {
    checkIfDateIsInvalid() {
      let dateIsInvalid = false;
      if (this.startDate && this.endDate) {
        dateIsInvalid = moment(this.startDate).diff(moment(this.endDate)) > 0;
      }
      return dateIsInvalid;
    },
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  watch: {
    startDate(newVal) {
      this.setDate(newVal, 'startDate');
    },
    endDate(newVal) {
      this.noEndDate = false;
      this.setDate(newVal, 'endDate');
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const endDateValue =
      this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
        ?.endDate;
    this.startDate = moment(
      this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
        ?.startDate,
      this.dateFormat
    )?.toDate();
    this.endDate = endDateValue
      ? moment(endDateValue, this.dateFormat)?.toDate()
      : null;
    if (!endDateValue) {
      this.noEndDate = true;
    }
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    formatDateUI(value) {
      if (!value) return 'No end date';
      return moment(value).format('ll');
    },
    setDate(value, dateType) {
      if (this.checkIfDateIsInvalid) {
        this.$snackbar.open({
          message:
            'Start date is ahead of End date. Selected dates will be reverted to values selected prior to this if it is not fixed.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
        return;
      }
      const allStepsData = { ...this.allStepsData };
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex][dateType] =
        value ? moment(value).format(this.dateFormat) : null;
      this.$store.dispatch(this.selectedValues.action, allStepsData);
      this.step.description = `${this.formatDateUI(
        this.startDate
      )} to ${this.formatDateUI(this.endDate)}`;
    },
    selectNoEndDate() {
      this.noEndDate = !this.noEndDate;
      this.endDate = null;
      this.setDate(this.endDate, 'endDate');
      this.hideEndCalendar();
    },
    hideStartCalendar() {
      this.$refs.startDate && this.$refs.startDate.close();
    },
    hideEndCalendar() {
      this.$refs.endDate && this.$refs.endDate.close();
    },
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    nextStep() {
      if (this.step?.useNextButton) {
        this.$emit('nextStep', {});
        return;
      }
      this.$router.push({
        name: 'setup_suggested_campaign',
        query: {
          edit: true
        }
      });
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style lang="css" scoped>
.no-end-date:hover,
.no-end-date:focus {
  background: #ffa800;
}

.date-picker-campaign-creation {
  top: -25px;
  right: -200px;
  z-index: 104;
}
.date-container {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 8px 8px;
  background: #fff;
  color: #8b8f93;
  border: 1px solid #e9eaeb;
  width: 100%;
}
</style>
