<template>
  <div class="u-width-100 u-overflow-auto u-display-flex u-spacing-p-xxl">
    <section class="createBudgetPlan--steps u-width-100 u-position-relative">
      <div class="u-display-flex">
        <div>
          <budgetPlannerHeader :step="step" />
          <rb-radio
            v-model="action"
            native-value="manual"
          >
            I would like to take manual actions
          </rb-radio>
          <section
            style="background: #b3b3b3; border-radius: 4px"
            class="u-color-white-base u-display-flex u-width-80 u-spacing-p-m u-cursor-default u-spacing-mv-l u-flex-direction-column"
            @mouseenter="automatedActionsHovered = true"
            @mouseleave="automatedActionsHovered = false"
          >
            <div
              class="u-display-flex u-flex-direction-row"
              style="padding-top: 2px"
            >
              <div class="u-spacing-pr-xs u-flex-align-items-flex-start">
                <rb-icon
                  class="u-font-size-3"
                  icon="bot"
                />
              </div>
              <div class="u-font-size-2 u-display-flex u-flex-direction-column">
                <div class="u-spacing-pb-xs">Automate by CIQ</div>
                <div class="u-font-size-6 u-spacing-pt-s">
                  Coming soon: CommerceIQ adjusts campaign budgets and keyword
                  bids to pace your spend as per the budget plan.
                </div>
              </div>
            </div>
          </section>
        </div>
        <div style="width: 350px">
          <!-- IQ TIP -->
          <div
            :class="{ 'iq-tip-wrapper': automatedActionsHovered }"
            class="u-position-absolute u-cursor-default u-border-radius-xs u-spacing-p-l"
            style="
              right: 0px;
              width: 250px;
              border: solid 1px #e9eaeb;
              z-index: 100;
            "
          >
            <div
              style="font-size: 24px"
              class="u-font-weight-600"
            >
              <rb-icon
                class="iqIconSize u-color-blue-base"
                icon="light-bulb"
              /><span class="u-color-blue-base">IQ </span>Tip
            </div>
            <div
              class="u-font-size-7 u-spacing-pt-m u-color-grey-lighter u-line-height-1-3"
            >
              <span class="u-font-weight-bold"
                >Automate by CIQ
                <span class="u-color-blue-base">(Coming soon)</span>:</span
              >
              <br />
              CommerceIQ adjusts campaign budgets and keyword bids to pace your
              spend as per the budget plan.
            </div>
            <div
              class="u-font-size-7 u-spacing-pt-m u-color-grey-lighter u-line-height-1-3"
            >
              <span class="u-font-weight-bold">Manual Actions:</span> <br />
              CommerceIQ recommendations actions to pace your spend and budget
              plan. You can manually approve those actions or make changes and
              push them yourself.
            </div>
          </div>
        </div>
      </div>
      <budgetPlannerHeader
        style="margin-top: 80px"
        :default-margin-bottom="false"
        :step="subHeader"
      />
      <budgetPlannerFooter
        :disable-right-btn="!isBudgetPlanModified"
        :btn-right-text="budgetPlanExists ? 'Update plan' : 'Create plan'"
        class="u-spacing-pt-xxl u-spacing-pb-xl"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import stepsMixin from '../stepMixin';
import budgetPlannerFooter from '../common/footer';
import budgetPlannerHeader from '../common/header';
import loader from '@/components/basic/loader';

export default {
  components: {
    budgetPlannerHeader,
    budgetPlannerFooter,
    loader
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    action: 'manual',
    automatedActionsHovered: false
  }),
  computed: {
    budgetPlanExists() {
      return !!this.$store.getters.getBudgetPlannerStrategies[0];
    },
    subHeader() {
      return {
        header: {
          title: 'Save this budget plan.',
          desc: [
            `Click on ${
              this.budgetPlanExists ? 'update' : 'create'
            } plan to put your plan in action`
          ]
        }
      };
    }
  },
  created() {
    this.$store.dispatch('setVisitedSteps', 'automationStep');
  },
  methods: {
    nextStep() {
      this.saveBudgetPlannerStrategy();
      // this.$emit('nextStep', {})
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style lang="css" scoped>
.iq-tip-wrapper {
  border-color: #007cf6 !important;
  cursor: default;
}
.iqIconSize {
  height: 24px;
  width: 24px;
}
.iqIconSize:before {
  font-size: 24px;
}
</style>
