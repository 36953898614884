<template>
  <section
    class="addAsinsPanel actionsPanel u-bg-color-grey-white u-position-relative"
  >
    <div
      v-show="selections.length > 1 && !bulkActionsAllowed"
      class="fillMessage fill--parent u-font-size-3"
    >
      Multiple Campaigns are not allowed
    </div>
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="
        tableLoader || asinsLoading || addGroupLoader || adGroupsLoading
      "
      :color="'#007cf6'"
    />
    <section
      :class="{
        visibilityHidden:
          tableLoader || asinsLoading || addGroupLoader || adGroupsLoading
      }"
      class="containerVisibilty"
    >
      <header
        class="title u-font-size-5 u-font-weight-600 u-spacing-pt-l u-spacing-ph-l u-text-case-upper"
      >
        {{ title }}
      </header>
      <div class="actionsPanelBody u-display-flex u-spacing-p-l">
        <div class="u-width-50 tableContainer">
          <div
            class="tableHeading u-spacing-ph-l u-spacing-pv-m u-font-weight-600 u-font-size-5"
          >
            Add SKUs
          </div>
          <div
            class="u-spacing-p-l u-width-100"
            style="box-shadow: none"
          >
            <div
              class="u-position-relative u-display-flex u-bg-color-grey-white"
            >
              <rb-input
                v-model="asinSearchText"
                :placeholder="$t('search_placeholder_skus')"
                class="search__input u-width-100 u-border-left-none u-bg-color-grey-white"
                @input="localSearch"
              />
              <span class="icon-right u-position-absolute u-display-flex">
                <rb-icon
                  icon="search"
                  class="search__input-icon rb-icon--medium"
                />
              </span>
            </div>
          </div>
          <div class="table-left u-width-100 u-position-relative">
            <div
              v-if="campaignAsinsData.rows.length === 0"
              class="fill--parent message"
            >
              {{ noAsinsPresentInfoMessage.leftTable }}
            </div>
            <div class="u-height-100">
              <rb-insights-table
                v-if="campaignAsinsData.rows.length > 0"
                :config="tableConfigsLeft"
                :grid-options="gridOptions"
                :auto-configure="campaignAsinsData"
                style="width: 100%"
                :row-height="66"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="false"
                :pagination="true"
                :client-side-pagination="true"
                :pagination-total-key="campaignAsinsData.rows.length"
                :pagination-per-page-limit="25"
                :download-this-table="false"
                :get-current-instance="getCurrentSourceInstance"
                :row-class-rules="rowClassRules"
                :show-pagination-options="false"
                :row-no-hover-state="true"
              />
            </div>
          </div>
        </div>
        <div class="u-width-50 tableContainer u-spacing-ml-l">
          <div
            class="tableHeading u-spacing-ph-l u-spacing-pv-m u-font-weight-600 u-font-size-5"
          >
            Added SKUs ({{ getAddedAsinsTableData.rows.length }})
          </div>
          <div v-if="campaignAsinsData.rows.length > 0 && !bulkActionsAllowed">
            <div
              class="u-spacing-ph-l u-spacing-pb-s u-spacing-pt-l u-font-weight-600 u-font-size-5"
            >
              Ad group:
            </div>
            <div
              v-if="adGroups.length > 0"
              class="u-spacing-ph-l u-spacing-pb-m u-max-width-50 u-display-flex"
            >
              <rb-select
                class-name="bp-select"
                :is-multi-select="true"
                class="u-spacing-mr-s valueDropdown u-max-width-100"
                :send-details="true"
                :on-close="adGroupSelected"
                :options="adGroups"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-spacing-ph-xxs u-spacing-ph-xs u-cursor-pointer"
                >
                  <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                    {{ adGroupTitle }}
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
                <div
                  v-if="adGroups.length"
                  slot="footer"
                  class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-m u-border-top u-border-width-s u-border-color-grey-xxx-light"
                >
                  <rb-button
                    :click-fn="clickOutside"
                    :type="'filled'"
                    text="Apply"
                    size="s"
                  />
                </div>
              </rb-select>
              <div class="dummy-element">&nbsp;</div>
            </div>
            <div
              v-else
              class="u-font-size-5 u-color-red-base"
            >
              Ad groups for this campaign are not present, hence no action can
              be taken.
            </div>
          </div>

          <div
            v-if="campaignAsinsData.rows.length > 0 && bulkActionsAllowed"
            class="u-spacing-ph-l u-spacing-pv-m"
          >
            <div
              v-if="adGroupsOfSelectedCampaigns.length > 0"
              class="u-display-flex u-flex-align-items-flex-end u-width-50"
            >
              <div
                class="u-border-radius-s u-border-all u-border-width-xs u-border-color-grey-xxx-light u-font-size-6 u-spacing-p-xs u-display-inline-block u-spacing-mr-s u-text-overflow-ellipsis"
              >
                {{ firstCampaignName }}... +
                {{ adGroupsOfSelectedCampaigns.length - 1 }} more
              </div>
              <TippyTableWithHeader
                header-text="Ad Groups"
                :column-definition="bulkActionConfigs.groupsTableColDefinition"
                :data-rows="adGroupsOfSelectedCampaigns"
                :ad-group-id-key="adGroupIdKey"
                :ad-group-name-key="adGroupNameKey"
                @onSelectionChange="onAdGroupsSelectionsChange"
              >
                <rb-button
                  slot="trigger-content"
                  class="edit-groups-btn"
                  :text="'Edit'"
                  :type="'filled'"
                  size="s"
                  icon-left="pencil"
                />
              </TippyTableWithHeader>
            </div>
            <div
              v-else
              class="u-font-size-5 u-spacing-pb-s u-spacing-mb-s u-spacing-ph-l u-color-red-base"
            >
              Ad groups for the selected campaigns are not present, hence no
              action can be taken.
            </div>
          </div>

          <div
            class="table-right u-spacing-pt-xs u-width-100 u-position-relative"
          >
            <div
              v-if="getAddedAsinsTableData.rows.length === 0"
              class="fill--parent message"
            >
              {{ noAsinsPresentInfoMessage.rightTable }}
            </div>
            <rb-insights-table
              v-else
              :config="tableConfigsRight"
              :grid-options="gridOptions2"
              :auto-configure="getAddedAsinsTableData"
              style="width: 100%"
              :row-height="66"
              :row-class="rowClass"
              :client-side-pagination="true"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="false"
              :pagination="true"
              :pagination-total-key="getAddedAsinsTableData.rows.length"
              :pagination-per-page-limit="25"
              :download-this-table="false"
              :get-current-instance="getCurrentResultInstance"
              :row-no-hover-state="true"
            />
          </div>
        </div>
      </div>
      <div
        v-if="noteAboveFooter"
        class="u-font-size-6 u-font-weight-600 u-color-blue-base u-spacing-pb-l u-spacing-ph-l"
      >
        {{ noteAboveFooter }}
      </div>
      <footer
        class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
      >
        <rb-button
          :disabled="!tableValid"
          :click-fn="addAsinsToCampaign"
          :text="'Apply'"
          :size="'s'"
          :type="'filled'"
          class="u-spacing-mr-s"
        />
        <rb-button
          :click-fn="closePanel"
          :text="'Cancel'"
          :size="'s'"
          :type="'hollow'"
          class="u-spacing-mr-s"
        />
        <div
          v-if="showValueError"
          class="u-spacing-ph-xs u-spacing-pt-xs u-flex-0 u-font-size-7 u-color-red-base"
        >
          {{ errorMessage }}
        </div>
      </footer>
    </section>
  </section>
</template>

<style lang="css">
.addAsinsPanel .edit-groups-btn .icon-pencil:before {
  font-size: 13px;
}
.containerVisibilty {
  visibility: unset;
}
.visibilityHidden {
  visibility: hidden;
}
.tableContainer {
  box-shadow: 0 0 4px 0 #caccce;
}
.tableContainer .table-left {
  min-height: 584px !important;
  height: 584px !important;
}
.tableContainer .table-right {
  height: 524px !important;
}
.tableContainer .ag-pivot-on .ag-header-group-cell {
  font-size: 25px;
  color: #3ea95e;
}
.tableContainer .tableHeading {
  border-bottom: 1px solid #e9eaeb;
}
.tableContainer .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.tableContainer .search__input__icon {
  right: 35px !important;
  top: 35px;
}
.tableContainer .ag-theme-material .ag-row {
  border-style: none;
}
.tableContainer .ag-theme-material .ag-row:last-child {
  border-style: solid;
  border-top: none;
}
.tableContainer .message {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #8b8f93;
}

.disabledTableRow .ag-cell:first-child div {
  opacity: 0.8;
}

.removePointerEvents {
  pointer-events: none;
}

.addAsinsPanel .ag-row.ag-row-level-0 div:nth-child(2) .cell--content,
.addAsinsPanel
  .ag-row.ag-row-first.ag-row-last
  div:nth-child(3)
  .cell--content {
  text-transform: capitalize;
}
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
.fillMessage {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import loader from '@/components/basic/loader';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import { commons } from '@/components/ams/campaigns/commons.js';
import { cloneDeep } from 'lodash';
import TippyTableWithHeader from '@/components/ams/actionPanels/table-tooltip.vue';
export default {
  name: 'AddAsinsPanel',
  components: {
    loader,
    messageBar,
    TippyTableWithHeader
  },
  props: {
    actionToCall: {
      type: Function,
      default: function () {}
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    widgetsConfigRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    bulkActionsAllowed: {
      type: Boolean,
      default: false
    },
    noAsinsPresentInfoMessage: {
      type: Object,
      default: function () {
        return {
          leftTable: 'No asin(s) found for selected Campaign',
          rightTable: 'No asin(s) added'
        };
      }
    },
    noteAboveFooter: {
      type: String,
      default: ''
    },
    asinKey: {
      type: String,
      default: 'asin'
    },
    adGroupIdKey: {
      type: String,
      default: 'adgroup_id'
    },
    adGroupNameKey: {
      type: String,
      default: 'adgroup_name'
    }
  },
  data() {
    return {
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      adGroups: [],
      applyClicked: false,
      previouslySelectedAdGroupData: [],
      gridOptions2: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      showValueError: false,
      errorMessage: 'Bid value should be between 0.02 to 1000',
      asinsLoading: false,
      inputFieldContext: {},
      selectedAdGroups: [],
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      tableConfigsRight: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      responseTableData: [],
      responseTableMetadata: [],
      resultTableData: [],
      currentArrowContext: '',
      resultTableMetadata: [],
      keywordsAddedFromSource: {},
      keywordsAddedToResult: [],
      maxBid: 1000,
      minBid: 0.02,
      rbTableSourceInstance: null,
      rbTableResultInstance: null,
      tableValid: false,
      currentSelectedCampaign: '',
      asinSearchText: '',
      rows: [],
      bulkActionConfigs: {}
    };
  },
  computed: {
    firstCampaignName() {
      return this.adGroupsOfSelectedCampaigns[0]?.campaign_name;
    },
    adGroupsLoading() {
      return this.$store.getters.getCampaignsAdGroups?.load;
    },
    adGroupsOfSelectedCampaigns() {
      const { rows, error } = this.$store.getters.getCampaignsAdGroups || {};
      const campaignIdMap = {};
      const targetedGroups = rows.reduce((list, group) => {
        list.push({
          ...group,
          campaignName: campaignIdMap[group.campaign_id]
            ? ' '
            : group.campaign_name,
          select: ' '
        });

        campaignIdMap[group.campaign_id] = !campaignIdMap[group.campaign_id]
          ? 1
          : campaignIdMap[group.campaign_id] + 1;

        return list;
      }, []);

      return !error ? targetedGroups : [];
    },
    adGroupTitle() {
      let text = '';
      if (this.selectedAdGroups.length === 0) {
        text = 'Please select an ad group';
      } else {
        this.selectedAdGroups.forEach((item) => {
          if (item) {
            text = text + item.title + ', ';
          }
        });
        text = text.slice(0, -2);
      }
      return text;
    },
    addGroupLoader() {
      return this.$store.getters.getAddGroupLoader;
    },
    fetchedAdGroups() {
      return this.$store.getters.getAdGroupsFromCampaignId.APIData
        ? this.$store.getters.getAdGroupsFromCampaignId.APIData
        : [];
    },
    title() {
      return 'ADD SKUs (' + this.selections.length + ' CAMPAIGN SELECTED)';
    },
    campaignAsinsData() {
      let rows = [];
      rows = this.rows.filter((data) => {
        if (
          Object.values(data)
            .toString()
            .toLowerCase()
            .includes(this.asinSearchText.toLowerCase())
        ) {
          return data;
        }
      });
      return {
        rows: rows,
        columns: this.responseTableMetadata
      };
    },
    campaignAsinRows() {
      if (this.selections.length === 0) {
        return [];
      }
      const data = this.$store.getters.getCampaignsAsins;
      const selection = this.selections[0].data.campaign_id;
      let rows = [];
      if (data && data[selection]) {
        rows = data[selection];
      }
      return rows;
    },
    rowClass() {
      return 'ag-grid-no-border-row';
    },
    rowClassRules() {
      const that = this;
      const asinKey = this.asinKey;
      return {
        'disabledTableRow  ag-cell:first-child removePointerEvents': function (
          params
        ) {
          let val = false;
          that.resultTableData.forEach((row) => {
            if (row[asinKey] === params.data?.[asinKey]) {
              val = true;
            }
          });
          return val;
        }
      };
    },
    tableLoader() {
      return this.$store.getters.getCampaignWorkspaceTableLoader;
    },
    getAddedAsinsTableData() {
      return {
        columns: this.resultTableMetadata,
        rows: this.resultTableData
      };
    }
  },
  watch: {
    fetchedAdGroups(newVal) {
      this.selectedAdGroups = [];
      this.adGroups = [];
      if (newVal?.length > 0) {
        newVal.map((item) => {
          item.title = item.adgroup_name;
          item.selected = false;
        });
        this.adGroups = newVal;
        this.adGroups[0].selected = true;
        if (!this.bulkActionsAllowed) {
          this.selectedAdGroups = [this.adGroups[0]];
        }
      }
      this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
    },
    rbTableSourceInstance() {
      this.isRowSelected();
    },
    resultTableData(newVal) {
      this.isRowSelected();
      this.rbTableSourceInstance.redrawTableRows();
      if (this.rbTableResultInstance !== null) {
        this.rbTableResultInstance.redrawTableRows();
      }
      this.checkTableValidity();
    },
    campaignAsinRows(newVal) {
      this.rows = newVal;
    },
    selections: {
      handler: function (newValue, oldValue) {
        if (newValue.length === 0) {
          this.closePanel();
          return;
        }
        if (newValue.length === 1) {
          var currentSelectedCampaign =
            this.currentSelectedCampaign.data.campaign_id;
          var changedValue = newValue[0].data.campaign_id;
          this.adGroups = [];
          if (!this.bulkActionsAllowed) {
            this.getAdGroupsForCampaign(this.selections[0].data);
          }
          if (changedValue !== currentSelectedCampaign) {
            this.getAsinsForCampaign();
            this.resultTableData = [];
          }
          this.checkTableValidity();
        } else {
          // this.checkTableValidity();
        }
        if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(newValue);
        if (
          this.widgetsConfig.widgets.addAsinsPanel.runMethodOnSelectionChange
        ) {
          this.widgetsConfig.widgets.addAsinsPanel.runMethodOnSelectionChange();
        }
      }
    }
  },

  created() {
    this.widgetsConfig = this.widgetsConfigRef.config(this);
    this.responseTableMetadata =
      this.widgetsConfig.widgets.addAsinsPanel.tableConfigs.metadata.sourceTable;
    this.resultTableMetadata =
      this.widgetsConfig.widgets.addAsinsPanel.tableConfigs.metadata.resultTable;
    this.currentSelectedCampaign = this.selections[0];
    this.bulkActionConfigs =
      this.widgetsConfig.widgets.addAsinsPanel.bulkActionConfigs;
    this.rows = this.campaignAsinRows;
    this.getAsinsForCampaign();
    if (this.bulkActionsAllowed) this.getAdGroupsForCampaigns(this.selections);
    else {
      this.getAdGroupsForCampaign(this.selections[0].data);
    }
  },
  beforeDestroy() {
    this.closePanel();
  },
  methods: {
    onAdGroupsSelectionsChange(selectedGroups) {
      this.selectedAdGroups = selectedGroups;
      this.checkTableValidity();
    },
    getAdGroupsForCampaigns(selectedCampaigns) {
      const payload = {
        ...this.bulkActionConfigs.APIConfig
      };
      const dimensionNameValueList = selectedCampaigns.map(({ data }) => ({
        dimensionName: 'campaign_id',
        dimensionValue: data.campaign_id
      }));
      payload.where.dimensionNameValueList = dimensionNameValueList;
      this.$store.dispatch('fetchCampaignsAdGroups', payload);
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    adGroupSelected(context, data) {
      if (this.applyClicked) {
        this.selectedAdGroups = [];
        this.selectedAdGroups = data.filter((item) => item.selected);
        this.applyClicked = false;
        this.$nextTick(() => {
          this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.previouslySelectedAdGroupData.length; i++) {
            if (!this.previouslySelectedAdGroupData[i].selected) {
              delete this.adGroups[i].selected;
            } else {
              this.adGroups[i].selected = true;
            }
          }
        });
      }
      this.checkTableValidity();
    },
    localSearch(input) {
      this.asinSearchText = input;
    },
    addAllAsinsToTable() {
      const currentItems = this.rbTableSourceInstance.rowData;
      const newRows = [];
      const asinKey = this.asinKey;
      currentItems.forEach((res) => {
        if (
          !this.checkRowExistsInCollection(res, asinKey, this.resultTableData)
        ) {
          newRows.push(res);
        }
      });
      this.resultTableData = [...this.resultTableData, ...newRows];
    },
    deleteAllAsinsFromTable() {
      this.resultTableData = [];
    },
    getAdGroupsForCampaign(data) {
      const dimensionNameValueList = [
        {
          dimensionName: 'campaign_id',
          operator: 'EQUAL_TO',
          dimensionValue: data.campaign_id
        },
        {
          dimensionName: 'state',
          operator: 'LIKE',
          dimensionValue: 'enabled'
        }
      ];
      this.widgetsConfig.widgets.fetchAddGroups.body.APIConfig.where.dimensionNameValueList =
        dimensionNameValueList;
      this.$store.dispatch(
        'fetchAdGroupsFromCampaignId',
        this.widgetsConfig.widgets.fetchAddGroups
      );
    },

    getCurrentCampaignAsins() {
      var currentCampaignId = this.selections[0].data.campaign_id;
      var dataExist = this.$store.getters.getCampaignsAsins[currentCampaignId];
      return dataExist;
    },

    // fetch keywords data from server for the selected campaign
    getAsinsForCampaign() {
      var currentCampaignId = this.selections[0].data.campaign_id;
      var dataExist = this.getCurrentCampaignAsins();
      if (dataExist !== undefined) {
        return dataExist;
      }

      var dimensionNameValueList = [
        {
          dimensionName: 'campaign_id_list',
          operator: 'NOT_LIKE',
          dimensionValue: currentCampaignId
        }
      ];
      if (
        this.widgetsConfig.widgets.addAsinsPanel?.useCurrentCampaignIdVariable
      ) {
        this.widgetsConfig.widgets.addAsinsPanel.currentCampaignId =
          currentCampaignId;
        dimensionNameValueList = [];
      }
      this.widgetsConfig.widgets.addAsinsPanel.body.APIConfig.where.dimensionNameValueList =
        dimensionNameValueList;
      this.$store.dispatch(
        'fetchAsinsForTheCampaign',
        this.widgetsConfig.widgets.addAsinsPanel
      );
    },

    // To get the context of the two tables
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },

    getCurrentResultInstance(context) {
      this.rbTableResultInstance = context;
    },

    // Checking the validity of the table to enable/disable apply button
    checkTableValidity() {
      if (this.widgetsConfig.widgets.addAsinsPanel.checkTableValidity) {
        this.widgetsConfig.widgets.addAsinsPanel.checkTableValidity();
        return;
      }
      if (this.bulkActionsAllowed) {
        if (this.resultTableData.length === 0) {
          this.tableValid = false;
          return;
        }
        if (this.selectedAdGroups.length === 0) {
          this.tableValid = false;
          return;
        }
      } else {
        if (this.selections.length > 1) {
          this.tableValid = false;
          return;
        }
        if (this.resultTableData.length === 0) {
          this.tableValid = false;
          return;
        }
        for (var k in this.keywordsAddedToResult) {
          var value = this.keywordsAddedToResult[k];
          if (value < this.minBid || value > this.maxBid) {
            this.tableValid = false;
            this.showValueError = true;
            return;
          }
        }
        if (this.adGroups.length < 1) {
          this.tableValid = false;
          return;
        }
        if (this.selectedAdGroups.length === 0) {
          this.tableValid = false;
          return;
        }
      }

      this.showValueError = false;
      this.tableValid = true;
    },

    // to validate the table data with input field
    validateTableData(context, event) {
      var rowIndex = context.params.node.rowIndex;
      context.params.data.newBid = context.bidValue;
      this.keywordsAddedToResult[rowIndex] = context.params.data.newBid;
      this.resultTableData[rowIndex].newBid = context.params.data.newBid;
      this.checkTableValidity();
    },

    // Adding a asin
    addToSelection(context, event) {
      this.resultTableData.push(context.params.data);
    },

    // removing a keywod from the table
    removeFromSelection(context, event) {
      const asinKey = this.asinKey;
      this.resultTableData = this.resultTableData.filter((result) => {
        if (context.params.data[asinKey] !== result[asinKey]) {
          return result;
        }
      });
    },

    // to set the default value of bid as suggested bid
    setDefaultValue(context, event) {
      context.params.data.newBid = context.params.data.bid;
      var rowNodes = {
        rowNodes: [context.params.node]
      };
      this.rbTableResultInstance.redrawTableRows(rowNodes);
      this.checkTableValidity();
    },

    getArrowButtonContext(context) {
      this.currentArrowContext = context;
    },

    getInputFieldContext(context) {
      if (this.currentArrowContext !== '') {
        this.currentArrowContext.$refs.inputField = context;
      }
      this.currentArrowContext = '';
      this.inputFieldContext[context._uid] = 0;
    },

    getPayloadTemplate(adGroup) {
      var commonPayload = {
        profileId: adGroup.profile_id,
        campaignId: adGroup.campaign_id,
        adgroupId: adGroup.adgroup_id
      };
      var obj = {
        actionType: 'addAsinCampaign',
        actionSource: {
          pageUrl: window.location.href
        }
      };
      obj.actionPayload = Object.assign({}, commonPayload);
      obj.viewPayload = Object.assign({}, commonPayload);
      obj.viewPayload.adgroupName = adGroup.adgroup_name;
      return obj;
    },

    createPayload() {
      var payload = [];
      for (var i = 0; i < this.resultTableData.length; i++) {
        for (const adGroup of this.selectedAdGroups) {
          var obj = this.getPayloadTemplate(adGroup);
          obj.actionPayload.asin = this.resultTableData[i].asin;
          obj.viewPayload.asin = this.resultTableData[i].asin;
          obj.viewPayload.image_url = this.resultTableData[i].image_url;
          obj.viewPayload.product_title = this.resultTableData[i].product_title;
          obj.viewPayload.product_url = this.resultTableData[i].product_url;
          payload.push(obj);
        }
      }
      return payload;
    },

    getBulkPayloadTemplate(adGroup) {
      const payload = [];
      this.resultTableData.forEach((sku, i) => {
        payload[i] = {
          actionType: 'addAsinCampaign',
          actionSource: {
            pageUrl: window.location.href
          },
          widget: 'campaign',
          primaryKey: adGroup.campaign_id
        };
        const commonPayload = {
          asin: sku.asin,
          campaignId: adGroup.campaign_id,
          sku: sku.asin
        };
        payload[i].actionPayload = {
          ...commonPayload,
          campaignType: (adGroup.campaign_type || '').toUpperCase(),
          state: 'enabled',
          adgroup_id: adGroup.adgroup_id
        };
        payload[i].viewPayload = {
          ...commonPayload,
          adgroupName: adGroup.adgroup_name,
          product_title: sku.product_title,
          image_url: sku.image_url
        };
      });

      return {
        uuid: `${adGroup.campaign_id}${adGroup.adgroup_id}`,
        payload
      };
    },

    createBulkPayload() {
      const payload = [];
      this.selectedAdGroups.forEach((adGroup, i) => {
        payload[i] = this.getBulkPayloadTemplate(adGroup);
      });
      return payload;
    },

    async addAsinsToCampaign() {
      var that = this;
      if (this.widgetsConfig.widgets.addAsinsPanel?.apply) {
        await this.widgetsConfig.widgets.addAsinsPanel.apply();
        return;
      }
      const payload = this.bulkActionsAllowed
        ? this.createBulkPayload()
        : this.createPayload();
      var othersObj = {
        loader: 'asinsLoading',
        successMessage:
          'Request to add asin(s) for the selected campaign has been submitted ',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.closePanel();
        }
      };
      commons.bidChangeApiAction.call(
        this,
        this.selections,
        payload,
        othersObj,
        this.bulkActionsAllowed ? 'bulkaction' : undefined
      );
    },

    closePanel() {
      this.parentRef.showAddAsinsPanel = false;
      this.parentRef.closeActionsWorkflow();
      this.resultTableData = [];
      this.$store.dispatch('resetCampaignAsins');
    },
    checkRowExistsInCollection(row, key, table) {
      let val = false;
      table.forEach((item) => {
        if (item[key] === row[key]) {
          val = true;
          return val;
        }
      });
      return val;
    },
    isRowSelected() {
      const rows = [];
      const asinKey = this.asinKey;
      this.rows.forEach((row) => {
        if (this.resultTableData.length > 0) {
          if (
            this.checkRowExistsInCollection(row, asinKey, this.resultTableData)
          ) {
            row.isRowSelected = true;
            rows.push(row);
          } else {
            row.isRowSelected = false;
            rows.push(row);
          }
        } else {
          row.isRowSelected = false;
          rows.push(row);
        }
      });
      this.rows = rows;
    }
  }
};
</script>
