export const conditionStepDisallowedMetrics = {
  CAMPAIGN: [
    'profile_name',
    'campaign_type',
    'campaign_name',
    'campaign_status',
    'serving_status',
    'conversions_14d',
    'acos_14d',
    'roi',
    'bidplus',
    'portfolio_name',
    'creative_type',
    'attributedSalesNewToBrand14d',
    'attributedOrdersNewToBrand14d',
    'attributedUnitsOrderedNewToBrand14d',
    'percent_time_in_budget',
    'suggested_budget',
    'estimated_missed_impressions_upper7d',
    'estimated_missed_clicks_upper7d',
    'estimated_missed_sales_upper7d',
    'PercentageSalesNewToBrand14d',
    'tactic'
  ],
  KEYWORD: [
    'profile_name',
    'campaign_type',
    'campaign_name',
    'keyword_text',
    'keyword_serving_status_latest',
    'state_current',
    'bid_change_date',
    'bid_change_date_current',
    'bid_change_date_real_current',
    'bid',
    'bid_suggested',
    'bid_range_start',
    'bid_range_end',
    'roi',
    'keyword_volume',
    'portfolio_name',
    'adgroup_name',
    'creative_type',
    'search_frequency_rank',
    'keyword_volume',
    'attributedSalesNewToBrand14d',
    'attributedOrdersNewToBrand14d',
    'attributedUnitsOrderedNewToBrand14d',
    'PercentageSalesNewToBrand14d'
  ],
  SEARCH_TERM: [
    'profile_name',
    'campaign_type',
    'campaign_name',
    'keyword_text',
    'keyword_serving_status_latest',
    'keyword_serving_status_latest_current',
    'state_latest_current',
    'bid_change_date',
    'bid',
    'bid_suggested',
    'bid_range_start',
    'bid_range_end',
    'roi',
    'bid_change_date_current',
    'bid_change_date_real_current',
    'keyword_volume',
    'portfolio_name',
    'adgroup_name',
    'creative_type',
    'search_frequency_rank',
    'keyword_volume'
  ],
  ASIN: [
    'profile_name',
    'campaign_type',
    'campaign_name',
    'state_latest_current',
    'portfolio_name',
    'adgroup_name',
    'attributedSalesNewToBrand14d',
    'attributedOrdersNewToBrand14d',
    'attributedUnitsOrderedNewToBrand14d',
    'PercentageSalesNewToBrand14d'
  ]
};

export const conditionStepMultiSelectFilters = {
  KEYWORD: ['keyword_type']
};

export const conditionStepBlackListedDimensionTypes = [
  'CLIENT_SAVED_FILTERS',
  'MY_SAVED_FILTERS'
];

export const scopeStepDisallowedMetrics = {
  CAMPAIGN: ['serving_status', 'tactic']
};
