<template>
  <div>
    <div
      class="u-spacing-ph-m u-spacing-pb-m u-font-weight-600 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <span class="u-font-size-3 u-color-grey-mid-light">
        CATEGORY PERFORMANCE BREAKDOWN
      </span>
      <span class="u-display-flex u-flex-align-items-center">
        <span
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
          @click="handleCategoryRouteClick"
        >
          <rb-icon
            icon="visibility-on"
            class="search__input-icon rb-icon--medium u-color-blue-base"
          />
          <span class="u-spacing-pl-xs u-color-blue-base u-font-size-5">
            View category details
          </span>
        </span>
        <div
          class="u-spacing-pl-m u-color-grey-lighter u-cursor-pointer hover-class"
        >
          <div
            v-if="!actionIcon.loading"
            v-tippy="{ placement: 'top', arrow: false }"
            :title="actionIcon.tippyTitle"
            class="u-display-flex u-flex-align-items-center u-flex-direction-column"
            @click.stop="handleDownload()"
          >
            <rb-icon
              class="u-color-grey-lighter"
              :icon="actionIcon.icon"
            />
          </div>
          <div v-else>
            <loader
              size="16px"
              class="u-bg-color-grey-white"
              :loading="true"
              color="#007cf6"
            />
          </div>
        </div>
        <span
          class="u-spacing-pl-m u-cursor-pointer"
          @click="handleCloseBreakDownView"
        >
          <rb-icon
            class="close-icon u-color-grey-mid-light"
            icon="cross"
          />
        </span>
      </span>
    </div>

    <category-breakdown
      :filter-data="filterData"
      :global-settings="globalSettings"
      :selected-category="selectedCategory"
      :global-view-id="globalViewId"
      :sort-configuration="sortConfiguration"
    />
  </div>
</template>
<script>
import categoryBreakdown from '@/components/pages/insights/amazon/market_share_pro/molecules/categoryBreakdown.vue';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
export default {
  components: {
    categoryBreakdown,
    loader
  },
  props: {
    globalViewId: {
      type: Number,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    filterData: {
      type: Object,
      default: () => {}
    },
    globalSettings: {
      type: Object,
      default: () => {}
    },
    sortConfiguration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionIcon: {
        icon: 'download',
        loading: false,
        tippyTitle:
          'Download Excel with Market Share data for the selected period for this category'
      }
    };
  },
  computed: {
    selectedCategory() {
      return `${this.category.toLowerCase()}~`;
    }
  },
  created() {
    eventBus.$on('overview-breakdown-download-initiated', () => {
      this.actionIcon.loading = false;
    });
  },
  destroyed() {
    eventBus.$off('overview-breakdown-download-initiated');
  },
  methods: {
    handleCategoryRouteClick() {
      // Emit event which handles routing to this category's category-page
      this.$emit('category-details-clicked', this.category);
    },
    handleCloseBreakDownView() {
      this.$emit('close-breakdown-view');
    },
    handleDownload() {
      this.actionIcon.loading = true;
      eventBus.$emit('breakdown-download-clicked');
    }
  }
};
</script>
