<template>
  <section
    class="settings-bar u-cursor-pointer u-bg-color-grey-white u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex u-flex-justify-content-space-between u-spacing-ph-l u-spacing-pv-m u-width-100"
    @click="showExpandedState"
  >
    <div
      class="u-width-100 u-font-size-5 u-color-grey-mid-light u-display-flex u-font-size-7"
    >
      <div class="u-width-100 u-display-flex">
        <div
          v-for="(filterItem, index) in filtersList"
          :key="filterItem.key"
          class="u-spacing-mr-m"
        >
          <SelectRow
            v-if="isRadioOrCheckbox(filterItem.selectType)"
            :key="index"
            :is-expanded="isExpanded"
            :options="filterItem.options"
            :title="filterItem.title"
            :original-selections="internalSelections[filterItem.key]"
            :select-type="filterItem.selectType"
            @selection="handleSelectionChange($event, filterItem.key)"
          />
          <div v-if="filterItem.selectType === 'dropdown'">
            <div
              class="u-display-flex u-font-size-7"
              :class="{
                'u-font-weight-600': isExpanded
              }"
            >
              <div>
                {{ filterItem.title }}
              </div>
              <div
                v-if="!isExpanded"
                v-tippy="{ placement: 'bottom' }"
                :title="dropdownTooltipLabel(filterItem.key)"
                class="u-spacing-ml-xs u-color-grey-base u-font-weight-600"
              >
                {{ dropdownLabel(filterItem.key) }}
              </div>
            </div>
            <rb-select
              v-if="isExpanded"
              :search-enabled="true"
              :is-multi-select="filterItem.multiSelect"
              :on-close="(ctx, data) => dropdownClose(filterItem.key, data)"
              :options="
                filterItem.multiSelect
                  ? internalSelections[filterItem.key]
                  : filterItem.options
              "
              :send-details="true"
              class="u-spacing-mt-m"
            >
              <div
                slot="trigger"
                class="u-display-flex"
              >
                <div
                  class="u-color-grey-base"
                  :class="{
                    'u-font-weight-600': !isExpanded
                  }"
                >
                  {{ dropdownLabel(filterItem.key) }}
                </div>
                <rb-icon
                  class="u-display-flex u-flex-align-items-center rb-icon--small u-spacing-ml-xs u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
            </rb-select>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isExpanded"
      class="edit-button u-color-grey-lighter u-cursor-pointer u-display-flex u-font-weight-600 u-font-size-7"
      @click.stop="showExpandedState"
    >
      <rb-icon
        class="rb-icon--small u-spacing-ml-s"
        :icon="'pencil'"
      />
      <div class="u-spacing-ml-xs">Edit</div>
    </div>
    <div
      v-else
      class="u-display-flex u-flex-align-items-center u-color-grey-lighter"
    >
      <div>
        <ConfigIcon
          :key="'apply'"
          :class="{ 'u-color-blue-base': isExpanded && selectionChanged }"
          class="u-spacing-mr-l"
          :config="applyIcon"
          @click="handleApply"
        />
      </div>
      <ConfigIcon
        :key="'cancel'"
        :config="cancelIcon"
        @click="handleCancel"
      />
    </div>
  </section>
</template>
<script>
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import SelectRow from '@/components/pages/insights/amazon/share-of-voice/atoms/select-row.vue';
import ConfigIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/config-icon';
import Vue from 'vue';
export default {
  components: {
    SelectRow,
    ConfigIcon
  },
  props: {
    notSelectedLabel: {
      type: String,
      default: 'None'
    },
    isUpdateKey: {
      type: String,
      default: ''
    },
    selectedType: {
      type: Array,
      default: () => []
    },
    lsKey: {
      type: String,
      default: null
    },
    filterComponents: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    valueOptions: {
      type: Array,
      default: () => [
        { label: 'Atlanta', value: 'Atlanta' },
        { label: 'SoCal', value: 'SoCal' }
      ]
    },
    isDimensionListEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      initialSelections: [],
      filtersList: [],
      applyIcon: this.getBaseApplyIcon(),
      selectionChanged: false,
      cancelIcon: {
        icon: {
          icon: 'cross',
          class: {}
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Cancel'
        }
      },
      isExpanded: false,
      internalSelections: {},
      internalSelectedType: [],
      internalKeywordType: [],
      internalSelectedPlacement: {},
      internalBrandSelect: {}
    };
  },
  computed: {
    isApplyButtonActive() {
      const isSelectedTypeSameAsOriginal = isEqual(
        this.selectedType,
        this.internalSelectedType
      );
      const isSelectedKeywordTypeSameAsOriginal = isEqual(
        this.selectedKeywordType,
        this.internalKeywordType
      );
      const isSelectedBrandSameAsOriginal = isEqual(
        this.selectedBrand,
        this.internalBrandSelect
      );
      return (
        this.internalSelectedType.length &&
        this.internalKeywordType.length &&
        (!isSelectedTypeSameAsOriginal ||
          !isSelectedKeywordTypeSameAsOriginal ||
          !isSelectedBrandSameAsOriginal)
      );
    }
  },
  watch: {
    selectedType: {
      immediate: true,
      handler(newSelectedType) {
        this.internalSelectedType = cloneDeep(newSelectedType);
      }
    },
    selectedBrand: {
      immediate: true,
      handler(newSelectedBrand) {
        this.internalBrandSelect = cloneDeep(newSelectedBrand);
      }
    },
    filterComponents: {
      handler(nv) {
        this.init();
        const currentFilters = this.getCurrentFilters();
        this.$emit('filterBarInitialized', currentFilters.backendFilters);
      }
    }
  },
  methods: {
    init() {
      this.filtersList = this.filterComponents;
      const internalSelections = this.internalSelections;
      const savedFilters = JSON.parse(
        this.$ciqlocalStorage.getItem(this.lsKey)
      );
      this.filtersList?.forEach((filterItem) => {
        filterItem.isExpanded = false;
        if (filterItem.init) {
          internalSelections[filterItem.key] = filterItem.originalSelections;
        } else if (filterItem.selectType === 'radio') {
          internalSelections[filterItem.key] =
            savedFilters?.[filterItem.key] ?? filterItem.options[0];
        } else if (filterItem.selectType === 'checkbox') {
          internalSelections[filterItem.key] =
            savedFilters?.[filterItem.key] ?? filterItem.options;
        }
        if (filterItem.selectType === 'dropdown') {
          if (filterItem.multiSelect) {
            const dropdownValue = filterItem.options.map((e) => {
              e.selected = true;
              return e;
            });
            if (savedFilters?.[filterItem.key].length) {
              dropdownValue.forEach((el) => {
                el.selected = !!savedFilters[filterItem.key].find(
                  (e) => e.value === el.value && e.selected
                );
              });
            }
            Vue.set(this.internalSelections, filterItem.key, dropdownValue);
            this.internalSelections[filterItem.key] = dropdownValue;
          } else {
            Vue.set(
              this.internalSelections,
              filterItem.key,
              savedFilters?.[filterItem.key] ?? [filterItem.options[0]]
            );
          }
        }
      });
      this.initialSelections = cloneDeep(internalSelections);
      this.internalSelections = cloneDeep(internalSelections);
    },
    dropdownLabel(key) {
      const dropdownElement = this.filtersList.find((e) => e.key === key);
      if (
        Array.isArray(this.internalSelections[key]) &&
        dropdownElement.multiSelect
      ) {
        const values = this.internalSelections[key].filter((e) => e.selected);
        if (values?.length > 2) {
          return (
            values
              .slice(0, 2)
              .map((item) => item?.title)
              .join(',') + ` & ${values.length - 2} more` ||
            this.notSelectedLabel
          );
        }
        return (
          values.map((item) => item?.title).join(',') || this.notSelectedLabel
        );
      }
      return this.internalSelections[key]?.[0]?.title;
    },
    dropdownTooltipLabel(key) {
      const dropdownElement = this.filtersList.find((e) => e.key === key);
      if (
        Array.isArray(this.internalSelections[key]) &&
        dropdownElement.multiSelect
      ) {
        const values = this.internalSelections[key].filter((e) => e.selected);
        return (
          values.map((item) => item?.title).join(',') || this.notSelectedLabel
        );
      }
      return this.internalSelections[key]?.[0]?.title;
    },
    isRadioOrCheckbox(type) {
      return /^(radio|checkbox)$/.test(type);
    },
    dropdownClose(key, data) {
      let dropdownData = this.internalSelections[key];
      const element = this.filtersList.find((e) => e.key === key);
      if (element.multiSelect) {
        dropdownData = dropdownData.map((e) => {
          e.selected = false;
          return e;
        });
        dropdownData.forEach((dropdownElement) => {
          dropdownElement.selected = !!data.find(
            (e) => e.value === dropdownElement.value
          );
        });
        Vue.set(this.internalSelections, key, dropdownData);
      } else {
        Vue.set(this.internalSelections, key, data);
      }
    },
    eventMaper(eventMap) {
      if (isEmpty(eventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = this;
      Object.keys(eventMap).forEach((listenerKey) => {
        if (typeof eventMap[listenerKey] === 'function') {
          vueEventMap[listenerKey] = eventMap[listenerKey];
        } else {
          vueEventMap[listenerKey] = parentReference[eventMap[listenerKey]];
        }
      });
      return vueEventMap;
    },
    getBaseApplyIcon() {
      return {
        icon: {
          icon: 'check',
          class: {
            color: 'null'
          }
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Apply',
          class: {
            color: 'null'
          }
        }
      };
    },
    showExpandedState() {
      this.filtersList = this.filtersList.map((e) => {
        e.isExpanded = true;
        return e;
      });
      this.isExpanded = true;
    },
    hideExpandedState() {
      this.filtersList = this.filtersList.map((e) => {
        e.isExpanded = false;
        return e;
      });
      this.isExpanded = false;
    },
    handleSelectionChange(data, filterKey) {
      if (this.isUpdateKey) {
        const savedFilters = JSON.parse(
          this.$ciqlocalStorage.getItem(this.isUpdateKey)
        );
        if (savedFilters != null) {
          if (!isEqual(savedFilters[filterKey], data))
            this.selectionChanged = true;
          else this.selectionChanged = false;
        } else {
          if (!isEqual(this.initialSelections[filterKey], data))
            this.selectionChanged = true;
          else this.selectionChanged = false;
        }
      }
      this.internalSelections[filterKey] = data;
    },
    handleTypesSelection(data) {
      this.internalSelectedType = data;
    },
    handleCancel(payload) {
      this.selectionChanged = false;
      this.internalSelections = cloneDeep(this.initialSelections);
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      this.$emit('cancel');
    },
    getCurrentFilters() {
      this.initialSelections = cloneDeep(this.internalSelections);
      const allDimensionValueList = this.transformData(this.internalSelections);
      const uiOnlyFiltersKeys = this.filterComponents
        .filter((item) => {
          return item.isUiUpdateOnly;
        })
        .map((item) => {
          return item.key;
        });
      const backendFiltersKeys = this.filterComponents
        .filter((item) => {
          return !item.isUiUpdateOnly;
        })
        .map((item) => {
          return item.key;
        });
      const backendFilters = this.filterDimensionValueListBasedOnKey(
        allDimensionValueList,
        backendFiltersKeys
      );
      const uiOnlyFilters = this.filterDimensionValueListBasedOnKey(
        allDimensionValueList,
        uiOnlyFiltersKeys
      );
      return {
        backendFilters,
        uiOnlyFilters
      };
    },
    handleApply(payload) {
      this.selectionChanged = false;
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      const currentFilters = this.getCurrentFilters();
      this.$ciqlocalStorage.setItem(
        this.lsKey,
        JSON.stringify(this.internalSelections)
      );
      this.$emit('barFiltersApplied', currentFilters.backendFilters);
      this.$emit('updateUIOnly', currentFilters.uiOnlyFilters);
    },
    filterDimensionValueListBasedOnKey(allDimensionValueList, keysToInclude) {
      return allDimensionValueList.filter((item) => {
        return keysToInclude.includes(item.dimensionName);
      });
    },
    transformData(internalSelections) {
      const data = cloneDeep(internalSelections);
      this.filtersList?.forEach((filter) => {
        if (filter.multiSelect) {
          data[filter.key] = data[filter.key].filter((e) => e.selected);
        }
      });
      const dimensionNameValueList = [];
      Object.keys(data).forEach((key) => {
        const filterArr = data[key];
        if (filterArr?.length) {
          filterArr?.forEach((filter) => {
            dimensionNameValueList.push({
              dimensionName: key,
              dimensionValue: filter.value
            });
          });
        } else if (typeof filterArr === 'object') {
          dimensionNameValueList.push({
            dimensionName: key,
            dimensionValue: filterArr.value
          });
        }
      });
      return dimensionNameValueList;
    }
  }
};
</script>
<style lang="css" scoped>
input[type='radio'],
input[type='checkbox'] {
  vertical-align: baseline;
  margin: 0;
}

.settings-bar:hover {
  box-shadow: 0px 8px 8px -8px #d2d4d7;
}

.settings-bar:hover .edit-button {
  color: #007cf6 !important;
}
</style>
