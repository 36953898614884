export default {
  requestObject: {
    campaignType: ':campaignType',
    adgroupType: ':adgroupType',
    // clientName: 'spectrumbrands',
    profileId: ':profileId',
    request: {
      campaignRequest: {
        portfolioId: ':portfolioId',
        name: ':campaignName',
        targetingType: ':targetingType',
        state: 'enabled',
        dailyBudget: ':dailyBudget',
        startDate: ':startDate',
        endDate: ':endDate',
        premiumBidAdjustment: null,
        biddingSP: {
          adjustments: [],
          strategy: ':biddingStrategy'
        }
      },
      adGroupRequest: {
        name: ':adgroupName',
        defaultBid: ':adgroupBid',
        state: 'enabled'
      },
      productRequest: [],
      keywordRequest: [],
      negativeKeywordRequest: [],
      targetRequest: [],
      creativeRequest: [],
      negativeTargetRequest: []
    }
  },
  negativeKeywordRequest: {
    state: 'enabled',
    keywordText: ':keyword',
    matchType: ':matchType'
  },
  adjustments: {
    predicate: ':placement',
    percentage: ':value'
  },
  productRequest: {
    sku: ':asin',
    asin: ':asin',
    state: 'enabled',
    imageUrl: ':imageUrl',
    productUrl: ':productUrl',
    productTitle: ':productTitle'
  },
  targetsRequest: {
    state: 'enabled',
    expression: [
      {
        value: ':value',
        type: ':expressionType'
      }
    ],
    expressionType: 'manual',
    bid: ':bid'
  },
  keywordRequest: {
    state: 'enabled',
    keywordText: ':keyword',
    matchType: ':matchType',
    bid: ':bid'
  },
  autoTargetRequest: {
    bid: ':bid',
    entityName: ':entityName',
    expression: [{ type: ':expressionType' }],
    resolvedExpression: [{ type: ':expressionType' }]
  }
};

export const adGroupCreationRequestTemplate = {
  requestObject: {
    campaignId: ':campaignId',
    adgroupType: ':adgroupType',
    profileId: ':profileId',
    request: {
      campaignRequest: {
        name: ':campaignName'
      },
      adGroupRequest: {
        campaignId: ':campaignId',
        name: ':adgroupName',
        defaultBid: ':adgroupBid',
        state: 'enabled'
      },
      keywordRequest: [],
      negativeKeywordRequest: [],
      targetRequest: [],
      productRequest: [],
      creativeRequest: [],
      negativeTargetRequest: []
    }
  },
  negativeKeywordRequest: {
    state: 'enabled',
    keywordText: ':keyword',
    matchType: ':matchType'
  },
  adjustments: {
    predicate: ':placement',
    percentage: ':value'
  },
  productRequest: {
    sku: ':asin',
    asin: ':asin',
    state: 'enabled',
    imageUrl: ':imageUrl',
    productUrl: ':productUrl',
    productTitle: ':productTitle'
  },
  targetsRequest: {
    state: 'enabled',
    expression: [
      {
        value: ':value',
        type: ':expressionType'
      }
    ],
    expressionType: 'manual',
    bid: ':newBid',
    entityName: ':entityName'
  },
  keywordRequest: {
    state: 'enabled',
    keywordText: ':keyword',
    matchType: ':matchType',
    bid: ':bid'
  }
};

export const sdAdGroupCreationRequestTemplate = {
  requestObject: {
    campaignId: ':campaignId',
    adgroupType: ':adgroupType',
    profileId: ':profileId',
    request: {
      campaignRequest: {},
      adGroupRequest: {
        campaignId: ':campaignId',
        name: ':adgroupName',
        defaultBid: ':adgroupBid',
        state: 'enabled'
      },
      keywordRequest: [],
      negativeKeywordRequest: [],
      targetRequest: [],
      productRequest: [],
      creativeRequest: [],
      negativeTargetRequest: []
    }
  },
  productRequest: {
    sku: ':asin',
    asin: ':asin',
    state: 'enabled',
    imageUrl: ':imageUrl',
    productUrl: ':productUrl',
    productTitle: ':productTitle'
  },
  targetsRequest: {
    state: 'enabled',
    expression: [
      {
        value: ':value',
        type: ':expressionType'
      }
    ],
    expressionType: 'manual',
    bid: ':newBid',
    entityName: ':entityName'
  },
  negativeTargetRequest: {
    target: ':target',
    matchType: ':matchType'
  }
};

export const sbConfigRequest = {
  requestObject: {
    campaignType: ':campaignType',
    adgroupType: ':adgroupType',
    profileId: ':profileId',
    dummyFlag: true,
    request: {
      campaignRequest: {
        name: ':campaignName',
        budget: ':budget',
        budgetType: ':budgetType',
        startDate: ':startDate',
        endDate: ':endDate',
        adFormat: ':adFormat',
        brandEntityId: ':brandEntityId',
        bidOptimization: ':bidOptimization',
        bidMultiplier: ':bidMultiplier',
        portfolioId: ':portfolioId',
        creative: {
          videoMediaIds: ':videoMediaIds',
          type: ':type',
          subPages: ':subPages',
          asins: ':creativeAsins',
          brandName: ':brandName',
          brandLogoAssetID: ':assetId',
          headline: ':headline'
        },
        landingPage: {
          asins: ':skus',
          url: ':customURL'
        }
      },
      sbKeywordRequest: [],
      sbNegativeKeywordRequest: [],
      sbTargetRequest: [],
      sbNegativeTargetRequest: []
    }
  },
  sbKeywordRequest: {
    keywordText: ':keyword',
    nativeLanguageKeyword: null,
    nativeLanguageLocale: null,
    matchType: ':matchType',
    bid: ':bid',
    state: 'enabled'
  },
  sbNegativeKeywordRequest: {
    keywordText: ':keyword',
    matchType: ':matchType'
  },
  sbTargetRequest: {
    expressions: [
      {
        value: ':value',
        type: ':type'
      }
    ],
    bid: ':bid'
  }
};
