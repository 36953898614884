<template>
  <div
    class="u-display-flex noskuImage_container u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-min-height-500 u-border-top u-border-width-s u-border-color-grey-xxx-light"
  >
    <img
      id="no_sku_image"
      alt="error"
      src="https://cdn.rboomerang.com/images/no_sku_found_latest.png"
    />
    <span
      class="u-font-size-3 u-spacing-pt-l u-color-grey-base u-font-weight-600 u-spacing-pb-m"
      >{{ title }}</span
    >
    <span
      v-if="description.length > 0"
      class="u-color-grey-mid-light u-font-size-5 u-font-weight-600 u-spacing-pb-s"
      >There could be {{ description.length }} possible reasons:</span
    >
    <span
      v-if="customDescription"
      class="u-color-grey-mid-light u-font-size-5 u-font-weight-600 u-spacing-pb-s"
      >{{ customDescription }}</span
    >
    <ul
      class="markdown u-font-size-4 u-display-flex u-flex-direction-column u-flex-align-items-center u-spacing-pb-l"
    >
      <li
        v-for="(descData, index) in description"
        :key="index"
        class="u-spacing-pb-xs"
      >
        {{ descData }}
      </li>
    </ul>

    <span
      v-if="action"
      class="u-font-size-6 u-color-grey-light"
      >If you would like to report an issue, please
      <a
        class="u-font-weight-600 u-color-blue-base"
        @click.stop="openFeedbackForm"
        >click here.</a
      ></span
    >
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    title: {
      type: String,
      default: 'Uhh ohh! No SKU found'
    },
    description: {
      type: Array,
      default: () => {
        return [
          'The SKU may be present in a different Category',
          'The SKU was unavailable during the selected date range'
        ];
      }
    },
    action: {
      type: Boolean,
      default: true
    },
    customDescription: {
      type: String,
      default: ''
    }
  },
  methods: {
    openFeedbackForm() {
      eventBus.$emit('openFeedbackForm');
    }
  }
};
</script>
