import {
  adgroupConfig,
  adFormat,
  productAndKeywordTargeting,
  classification,
  budget,
  timePeriod,
  creative,
  biddingStrategySB
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/index.js';
import { cloneDeep } from 'lodash';
import CampaignType from '@/components/ams/campaign_creation/steps/edit/campaignTypes/index.vue';
import { createOwnSBCampaignAddProductActionState } from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';

const campaignType = {
  name: 'Select Campaign Type',
  description: '',
  targetingType: ['product', 'keyword'],
  header: {
    title: 'Select Campaign Type',
    desc: [
      'There are two main types of campaigns or ads you can create in Amazon: Sponsored Product ads and Sponsored Brands ads. The first of these, Sponsored Product ads, are the most common.'
    ]
  },
  key: 'campaignType',
  keyToRead: 'campaignType',
  breadCrumbTitle: 'Select Campaign Type',
  textMapping: 'campaignType',
  component: CampaignType
};

const formStepConfig = () => {
  const adgroup = cloneDeep(adgroupConfig);
  const classify = cloneDeep(classification);
  const dates = cloneDeep(timePeriod);
  const productAndKeywordTargetingEdited = cloneDeep(
    productAndKeywordTargeting
  );
  productAndKeywordTargetingEdited.customActionPanelProductTargetingCustomState =
    createOwnSBCampaignAddProductActionState;
  adgroup.name = "Sku's";
  adgroup.header = {
    title: "Sku's",
    desc: ["Select sku's from the table below you want to advertise."]
  };
  adgroup.enableBackButton = true;
  classify.enableBackButton = true;
  dates.useNextButton = true;
  adgroup.hideAdgroup = true;
  adgroup.showLeftButton = true;
  adgroup.requiredRows = 3;
  adgroup.moduleClass = 'campaign-creation-component-height-class';
  productAndKeywordTargetingEdited.requiredRows = 1;

  const stepsConfig = [
    campaignType,
    classify,
    budget,
    dates,
    adFormat,
    adgroup,
    creative,
    productAndKeywordTargetingEdited,
    biddingStrategySB
  ];

  return stepsConfig;
};

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  disableForActiveSelection: 4,
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  stepsConfig: formStepConfig(),
  enableStepsAfterValidation: true
};
