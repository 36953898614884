<template>
  <section
    class="u-bg-color-white-base u-spacing-m-m u-spacing-p-m u-border-radius-m u-box-shadow-xx-light"
  >
    <div class="u-display-flex u-flex-justify-content-space-between">
      <div class="u-display-flex u-flex-align-items-center">
        <img
          src="https://cdn.rboomerang.com/remcc/notification-red.svg"
          alt="caution img"
        />
        <h3
          class="u-font-size-3 u-font-weight-600 u-color-grey-light u-text-align-left u-spacing-p-s"
        >
          Spotlights
        </h3>
      </div>
      <div
        class="u-display-flex u-flex-align-items-center u-cursor-pointer u-color-blue-base"
        @click="expanded = !expanded"
      >
        <span class="u-font-size-5">{{ collapsedText }}</span>
        <rb-icon
          class="rb-icon--small"
          :icon="collapsedIcon"
        />
      </div>
    </div>
    <section>
      <h5
        v-if="expanded"
        class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mt-m"
      >
        What needs to be monitored?
      </h5>
      <spotlight-carousel :spotlights-data="spotlightsData" />
    </section>
    <section v-if="expanded">
      <h5 class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light">
        What is working?
      </h5>
      <spotlight-carousel :spotlights-data="workingData" />
    </section>
  </section>
</template>
<script>
import Vue from 'vue';
import SpotlightCarousel from '../organisms/spotlight-carousel.vue';

export default Vue.extend({
  components: {
    SpotlightCarousel
  },
  data() {
    return { expanded: false };
  },
  computed: {
    collapsedText() {
      return this.expanded ? 'Collapse' : "See what's working";
    },
    spotlightsData() {
      return [
        {
          heading: 'Marketshare Drop',
          content:
            'Your marketshare has <span class="u-font-weight-600">dropped</span> by <span class="u-color-red-base u-font-weight-600">2%</span> in the category <span class="u-font-weight-600">Breakfast cereals</span>',
          actions: [
            {
              text: 'See Why?',
              handler: () => {
                this.actionHandler({
                  type: 'openRCA',
                  metricKey: 'actual_sales_marketsharelite'
                });
              }
            }
          ]
        },
        {
          heading: 'IMAP Violation',
          content:
            'MAP price on <span class="u-font-weight-600">B09RMRNSBF, B08WG4HKKY </span> have been breached by Amazon on 01 Jan 2023',
          actions: [
            {
              text: 'See More.',
              handler: () => {
                this.actionHandler({
                  type: 'openRCA',
                  metricKey: 'avg_selling_price'
                });
              }
            }
          ]
        },
        {
          heading: 'Unavailable SKUs',
          content:
            'One of your top SKUs has been <span class="u-font-weight-600">unavailable</span> since December 4. It has 2 weeks of cover',
          skuDetails: {
            title: 'RXBAR Oatmeal Cup, Chocolate, 2.24 Oz',
            asin: 'B082FZ8RBZ',
            pdpUrl: 'https://www.amazon.com/dp/B082FZ8RBZ',
            imageUrl:
              'https://m.media-amazon.com/images/I/41LyD6k9iTL._SX300_SY300_QL70_ML2_.jpg'
          }
        },

        {
          heading:
            'Share Of Voice dropped by <span class="u-color-red-base">20%</span>',
          content:
            'One of your top SKUs experienced a large drop of 20% week on week drop in Marketshare possibly due to decrease in Ad Spend',
          skuDetails: {
            title: '$100 Xbox Gift Card [Digital Code]',
            asin: 'B00F4CFHFC',
            pdpUrl:
              'https://www.amazon.com/Xbox-Gift-Card-Digital-Code/dp/B00F4CFHFC',
            imageUrl:
              'https://m.media-amazon.com/images/I/31FPVfKjysS._SY430_SX215_QL70_FMwebp_.jpg'
          },
          actions: [
            {
              text: 'Review SKU',
              handler: () => {}
            },
            {
              text: 'See Marketshare RCA',
              handler: () => {}
            }
          ]
        },
        {
          heading: 'Lost Buy Box',
          content:
            'One of your top SKUs has lost the buy box to seller USA-Supply as on October 24th.'
        }
      ];
    },
    workingData() {
      return [
        {
          heading: 'Product Image Fixed for Priority SKUs',
          content:
            'Image for priority SKUs: <span class="u-font-weight-600">B09RMRNSBF, B08WG4HKKY </span> was fixed yesterday. No further action needed.'
        },
        {
          heading: 'Share of Voice growth',
          content:
            'Category SoV in Frozen snacks has increased by <span class="u-font-weight-600">4% </span> in the last <span class="u-font-weight-600">30 days. </span>',
          actions: [
            {
              text: 'See Why?',
              handler: () => {
                this.actionHandler({
                  type: 'openRCA',
                  metricKey: 'generic_total_count_page_1'
                });
              }
            }
          ]
        },
        {
          heading: 'IMAP Violation resolved',
          content:
            '<span class="u-font-weight-600">B09WD4TWYP </span> Price has been reverted as of 31 Dec 2022 based <span class="u-font-weight-600">Ticket Id: 65665432</span>'
        }
      ];
    },
    collapsedIcon() {
      return this.expanded ? 'arrow-up' : 'arrow-down';
    }
  },
  methods: {
    actionHandler(payload) {
      this.$emit('handleSpotlightAction', payload);
    }
  }
});
</script>
