<template>
  <div
    id="filter__wrapper"
    class="u-flex-1 u-position-relative filter__wrapper"
    style="z-index: 9"
  >
    <div
      class="u-display-flex u-flex-align-items-flex-start u-flex-justify-content-space-between u-flex-wrap-yes"
    >
      <span class="u-display-flex u-flex-align-items-center">
        <slot
          name="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center"
        />
      </span>
      <div
        v-if="hasSearch"
        class="u-display-flex u-flex-align-items-flex-start"
        :class="extraSearchWrapClass"
      >
        <div
          :class="{ 'date-picker-wrapper-disabled': disableDateRangeSelection }"
          class="u-display-flex u-flex-align-items-center"
        >
          <div
            v-if="!hideFilter && hasSearch"
            slot="trigger"
            class="icon-frame u-height-100"
            @click.stop="openAddFilter"
          >
            <rb-button
              :title="'Filters'"
              :type="'icon'"
              :size="'medium'"
              :icon-right="'filter'"
              class="u-border-none u-filter-trigger-button"
            />
          </div>
          <slot name="search">
            <template>
              <form
                v-if="(config.search || {}).enable"
                class="u-position-relative u-display-flex u-spacing-mr-s"
                :style="{
                  width: [config.search.width || '480px']
                }"
                @submit.prevent="searchSubmit"
              >
                <rb-input
                  v-model="searchText"
                  :placeholder="config.search.placeholder || 'Search'"
                  data-cy="SearchInput"
                  class="search__input u-width-100 u-border-left-none"
                  :rounded="false"
                  @input="onSearchInput"
                />

                <span
                  class="u-position-absolute u-display-flex"
                  data-cy="submitBtn"
                  style="top: 0px; left: 8px; bottom: 0; margin: auto"
                  @click="searchSubmit"
                >
                  <rb-icon
                    icon="search"
                    class="search__input-icon rb-icon--medium"
                    data-cy="searchIcon"
                  />
                </span>
              </form>
              <tagsAndSuggestions
                v-else-if="(config.multiSearch || {}).enable"
                class="u-border-radius-xs u-spacing-pr-s u-display-flex u-height-36px"
                :add-filter-instance="addFilterInstance"
                :dropdown-data="multiSearchDropdownData"
                :get-tags-and-suggestions-instance="
                  getTagsAndSuggestionsInstance
                "
                :tags-aggregated-view="tagsAggregatedView"
                :tags-aggregated-input-size="50"
                :tags-icons="tagsIcons"
                :min-dropdown-width="'410px'"
                :tags-title-key="tagsTitleKey"
                :delimiters="delimiters"
                :pre-input-icons="preInputIcons"
                :post-input-icons="postInputIcons"
                :tag-color="tagColor"
                :max-dropdown-width="maxSuggestionsDropdownWidth"
                :dropdown-highlight-text-color="dropdownHighlightTextColor"
                :dropdown-hightlight-color="dropdownHightlightColor"
                :buffered-tag-icons="bufferedTagIcons"
                :input-placeholder="inputPlaceholder"
                :has-tags-buffer="true"
                :is-tag-clickable="isTagClickable"
                :aggregated-tag-title-text="aggregatedTagTitleText"
                :aggregated-buffer-tag-title-text="aggregatedBufferTagTitleText"
                :show-filter-icon="showFilterIcon"
                @clearAll="handleClearAll"
                @suggestionClick="handleSuggestionClick"
                @searchSubmit="handleSearchSubmit"
                @textInput="handleTextInput"
                @inputFieldClick="handleInputFieldClick"
                @openActiveTagsDropdown="handleOpenActiveTagsDropdown"
                @tagClicked="handleTagClicked"
                @bufferedTagClicked="handleBufferedTagClicked"
                @openBufferedTagsDropdown="handleOpenBufferedTagsDropdown"
              >
                <template
                  v-for="slot in getCurrentScopedSlots"
                  :slot="slot"
                  slot-scope="scope"
                >
                  <slot
                    :name="slot"
                    v-bind="scope"
                  />
                </template>
              </tagsAndSuggestions>
            </template>
          </slot>
          <slot name="preDatePicker" />
          <rb-datepicker
            v-if="date"
            :value="
              getSelectedFilter['date_range'].value || defaultCalendarValue
            "
            :position="'Left'"
            :range-names="dateRanges"
            :max-date="getMaxDate"
            :min-date="getMinDate"
            @change="updatedateSelection"
          >
            <rb-button
              slot="trigger"
              :type="'icon'"
              :icon-left="'calendar'"
              :icon-right="'caret-down'"
              :text="dateString"
            />
          </rb-datepicker>
          <time-range-picker
            v-if="enableTimeSelect"
            class="u-spacing-mr-s"
            :local-storage-key="listenerEvent"
            @submit="updateTimeRangeSelection"
          />
          <slot
            name="before-date-picker"
            class="u-display-flex u-flex-align-items-center"
          />
          <div
            class="u-display-flex u-flex-direction-row"
            :class="{ 'pin-date-range-wrapper': commonDateKey }"
          >
            <div>
              <date-range
                v-if="newDate"
                :max-date="getMaxDate"
                :min-date="getMinDate"
                :allow-compare="allowDateCompare"
                :show-compared-to-text="showComparedToText"
                :default-range-select="defaultDatePostRange"
                :local-storage-key="listenerEvent"
                :show-reset="showReset"
                :show-data-missing-msg="showDataMissingMsg"
                :data-missing-date-range="dataMissingDateRange"
                :last-week="lastWeek"
                :use-different-date-for-canned-dates="
                  getDifferentDateForCannedDates
                "
                :additional-ranges="additionalRanges"
                :common-date-key="commonDateKey"
                :class="{
                  'disable-date-range-selection': disableDateRangeSelection
                }"
                :date-mode="dateMode"
                :selection-mode="selectionMode"
                :enable-date-persistence="enableFilterPersistence"
                :user-page-metadata="userPageMetadata"
                @create="storeDate"
                @submit="newUpdatedateSelection"
              />
              <slot name="below-date-range" />
            </div>
            <div
              v-if="commonDateKey"
              class="u-display-flex pin-wrapper"
            >
              <span
                v-tippy="{ placement: 'top', arrow: false }"
                :title="
                  !useCommonDate
                    ? '\'Pin\' to persist the date for all dashboards related to Campaign Management.'
                    : '\'Un-Pin\' to not persist the date for all dashboards related to Campaign Management.'
                "
                data-cy="pinCalenderIcon"
                class="u-display-flex u-cursor-pointer"
                :class="{
                  'u-color-blue-base': useCommonDate,
                  'u-color-grey-lighter': !useCommonDate
                }"
                @click.stop="useCommonDate = !useCommonDate"
              >
                <rb-icon
                  icon="pin"
                  class="rb-icon--x-medium"
                />
              </span>
            </div>
            <slot name="download" />
          </div>
        </div>
      </div>
      <slot name="right-side" />
    </div>
    <div
      v-if="!hideAddFilter"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
      :style="{
        'padding-top': chipPaddingTop,
        'padding-bottom': chipPaddingBottom
      }"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-flex-1"
      >
        <span
          class="u-display-flex u-flex-wrap-yes u-flex-align-items-center"
          :class="cssClass"
        >
          <slot name="before-filter" />
          <span
            class="u-display-flex u-flex-align-items-center u-flex-wrap-yes"
          >
            <span
              v-for="(filter, index) in pinnedChipFilterList"
              :key="index"
            >
              <rb-filter
                v-if="filter"
                ref="filter"
                :filter-for-operators="filterForOperators"
                :disable-between-operator="disableBetweenOperator"
                :listener-event="listenerEvent"
                :data="filter.data"
                :config="customConfigGenerator(filter)"
                :anchor="customConfigGenerator(filter).anchor"
                :apply="onFilterApply"
                :add-filter-text="`Add a ${filter.primaryData.dimensionLabel} filter`"
                :secondary-data="secondaryData"
                :primary-data="[filter.primaryData]"
                :on-create="onChipFilterCreate"
                :multi="customConfigGenerator(filter).multi"
                :show-select-all="customConfigGenerator(filter).showSelectAll"
                :getter="state.getter"
                :default-filters="defaultFilters"
                :use-local-storage="useLocalStorage"
                :filter-v2="customConfigGenerator(filter).filterV2"
                :is-always-on-filter="true"
                :show-notification="showNotification"
                :chip-size="chipSize"
                :rule-builder-root="ruleBuilderRoot"
                :external-selection-listener="externalFilterSelectionListener"
                :hide-clear-all-btn="hideClearAllBtn"
                :enable-minimum-selection="enableMinimumSelection"
                :hide-remove-btn="hideRemoveBtn"
                :filter-list-tooltip-fn="filterListTooltipFn"
                :show-dynamic-tooltip="showDynamicTooltip"
                :show-next-line-ellipsis="showNextLineEllipsis"
                :operators-to-filter-out="operatorsToFilterOut"
                :get-title="getTitle"
                @ruleApply="handleRuleApply"
              />
            </span>
            <span
              v-for="(filter, index) in filters"
              :key="pinnedChipFilterList.length + index"
            >
              <rb-filter
                v-if="filter"
                ref="filter"
                :filter-for-operators="filterForOperators"
                :disable-between-operator="disableBetweenOperator"
                :listener-event="listenerEvent"
                :data="data"
                :config="filter"
                :anchor="filter.anchor"
                :apply="onFilterApply"
                :add-filter-text="addFilterText"
                :secondary-data="secondaryData"
                :primary-data="primary"
                :on-create="onFilterCreate"
                :multi="filter.multi"
                :getter="state.getter"
                :default-filters="defaultFilters"
                :use-local-storage="useLocalStorage"
                :is-always-on-filter="isAlwaysOnFilter"
                :show-notification="showNotification"
                :chip-size="chipSize"
                :rule-builder-root="ruleBuilderRoot"
                :filter-v2="filterV2"
                :external-selection-listener="externalFilterSelectionListener"
                :hide-clear-all-btn="hideClearAllBtn"
                :enable-minimum-selection="enableMinimumSelection"
                :hide-remove-btn="hideRemoveBtn"
                :filter-list-tooltip-fn="filterListTooltipFn"
                :show-dynamic-tooltip="showDynamicTooltip"
                :show-next-line-ellipsis="showNextLineEllipsis"
                :operators-to-filter-out="operatorsToFilterOut"
                :get-title="getTitle"
                @ruleApply="handleRuleApply"
                @back="onNavBackToMainFilterPage"
              />
            </span>
            <span
              v-if="isAlwaysOnFilter"
              key="customFilter"
            >
              <rb-filter
                ref="custom-filter"
                :disable-between-operator="disableBetweenOperator"
                :listener-event="listenerEvent"
                :data="data"
                :config="customConfig"
                :anchor="customConfig.anchor"
                :enable-minimum-selection="enableMinimumSelection"
                :apply="onFilterApply"
                :add-filter-text="addFilterText"
                :secondary-data="secondaryData"
                :is-always-on-filter="isAlwaysOnFilter"
                :primary-data="primary"
                :on-create="onFilterCreate"
                :multi="true"
                :getter="state.getter"
                :default-filters="defaultFilters"
                :use-local-storage="useLocalStorage"
                :show-notification="showNotification"
                :chip-size="chipSize"
                :rule-builder-root="ruleBuilderRoot"
                :filter-v2="filterV2"
                :external-selection-listener="externalFilterSelectionListener"
                :selections-joining-string="selectionsJoiningString"
                :operators-to-filter-out="operatorsToFilterOut"
                @ruleApply="handleRuleApply"
                @back="onNavBackToMainFilterPage"
              />
            </span>
          </span>

          <slot name="custom-state" />
          <add-filter
            v-if="!hideAddFilter && !isAlwaysOnFilter && !hideAddFilterButton"
            ref="add-filter"
            expoter="display-none"
            :disabled-fn="addFilterDisabledFn"
            :disabled-tooltip-fn="addFilterDisabledTooltipFn"
            :add-filter-text="addFilterText"
            :on-create="onAddFilterCreate"
            :enable-save="enableSave"
            :data="primary"
            :loader-status="loaderStatus || localLoaderStatus"
            :selection="onPrimarySelect"
            :widget="listenerEvent"
            :secondary-data="secondaryData"
            @filter-state-change="handleFilterStateChange"
          >
            <div
              slot="extra-metrics"
              :selection="onPrimarySelect"
            >
              <slot name="extra-metrics" />
            </div>
          </add-filter>
          <ingestionBadge
            v-if="showIngestionProgress && !isFilterDropdownActive"
            class="u-spacing-mb-xs u-spacing-mh-xs"
            :tooltip-text="ingestionTooltipText"
            :dependent-entities="ingestionDependentEntities"
          />

          <slot name="after-filter" />
        </span>

        <span class="u-display-inline-flex u-flex-align-items-center">
          <save-filter
            v-if="
              filtersOrder.length > 0 && enableSave && checkSavedFilterDuplicacy
            "
            :secondary-data="secondaryData"
            :primary-data="primary"
            :page="page"
            :widget="listenerEvent"
          />
          <icon-text-actions
            v-if="filtersOrder.length > 0 && enableRemoveAll"
            :apply-padding="false"
            mode="default"
            :action="{ title: 'Remove', icon: 'cross' }"
            @clicked="removeAllFilters"
          />
        </span>
      </div>
      <div
        v-if="!hasSearch && (date || newDate)"
        class="u-spacing-ml-s u-display-flex u-flex-align-items-center margin_top_negative"
      >
        <rb-datepicker
          v-if="date"
          :value="getSelectedFilter['date_range'].value || defaultCalendarValue"
          :position="'Left'"
          :range-names="dateRanges"
          :max-date="getMaxDate"
          :min-date="getMinDate"
          @change="updatedateSelection"
        >
          <rb-button
            slot="trigger"
            :type="'icon'"
            :icon-left="'calendar'"
            :icon-right="'caret-down'"
            :text="dateString"
          />
        </rb-datepicker>
        <div class="u-display-flex u-flex-direction-column">
          <date-range
            v-if="newDate"
            :max-date="getMaxDate"
            :min-date="getMinDate"
            :allow-compare="allowDateCompare"
            :show-compared-to-text="showComparedToText"
            :default-range-select="defaultDatePostRange"
            :local-storage-key="listenerEvent"
            :show-reset="showReset"
            :use-different-date-for-canned-dates="
              getDifferentDateForCannedDates
            "
            :additional-ranges="additionalRanges"
            :common-date-key="commonDateKey"
            :selection-mode="selectionMode"
            :date-mode="dateMode"
            @create="storeDate"
            @submit="newUpdatedateSelection"
          />
          <div v-if="commonDateKey">
            <rb-checkbox v-model="useCommonDate"> Apply to all </rb-checkbox>
          </div>
        </div>
      </div>
      <slot name="right-side-without-search" />
    </div>
    <slot
      v-bind="selectedDates"
      name="banner"
    />
  </div>
</template>

<script>
import filter from '@/components/widgets/filter/filter.vue';
import addFilter from '@/components/widgets/filter/addFilter.vue';
import saveFilter from '@/components/widgets/filter/saveFilter.vue';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';
import TimeRangePicker from '@/components/widgets/time/TimeRangePicker.vue';
import filterUtils from '@/components/widgets/filter/filterUtils';
import tagsAndSuggestions from '@/components/widgets/tagsAndSuggestions';
import ingestionBadge from '@/pages/toolsAndSettings/entityTagging/common/ingestionBadge';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
import transformer from '@/utils/services/data-transformer.js';
import { isEqual } from 'lodash';

export default {
  components: {
    rbFilter: filter,
    addFilter,
    dateRange,
    saveFilter,
    iconTextActions,
    tagsAndSuggestions,
    ingestionBadge,
    TimeRangePicker
  },
  props: {
    showDataMissingMsg: {
      type: Boolean,
      default: false
    },
    dataMissingDateRange: {
      type: String,
      default: ''
    },
    getTitle: {
      type: Function,
      default: (item) => {
        return item.title;
      }
    },
    pinnedChipFilterList: {
      type: Array,
      default: () => []
    },
    clearBulkBanner: {
      type: Function,
      default: () => {}
    },
    showDynamicTooltip: {
      type: Boolean,
      default: false
    },
    filterListTooltipFn: {
      type: Function,
      default: () => {}
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false
    },
    addFilterDisabledTooltipFn: {
      type: Function,
      default: undefined
    },
    filterPrimarySelectStaging: {
      type: Function,
      default: undefined
    },
    addFilterDisabledFn: {
      type: Function,
      default: undefined
    },
    cssClass: {
      type: String,
      default: 'u-flex-justify-content-space-between'
    },
    disableDateRangeSelection: {
      type: Boolean,
      default: false
    },
    enableMinimumSelection: {
      type: Boolean,
      default: false
    },
    hideClearAllBtn: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: null
    },
    lastWeek: {
      type: Object,
      default: function () {
        return {
          startDay: 'Sunday',
          endDay: 'Saturday'
        };
      }
    },
    externalFilterSelectionListener: {
      type: String,
      default: null
    },
    ingestionTooltipText: {
      type: String,
      default:
        'Internal Taxonomy updation or creation is in progress. It will take upto 3 hours for the changes to reflect.'
    },
    ingestionDependentEntities: {
      type: Array,
      default() {
        return [EntitiesForPostProcessingStatus.catalog];
      }
    },
    showFilterIcon: {
      type: Boolean,
      default: true
    },
    showIngestionProgress: {
      type: Boolean,
      default: false
    },
    aggregatedTagTitleText: {
      type: String,
      default: ''
    },
    aggregatedBufferTagTitleText: {
      type: String,
      default: ''
    },
    isTagClickable: {
      type: Boolean,
      default: false
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    bufferedTagIcons: {
      type: Array,
      default: () => []
    },
    dropdownHighlightTextColor: {
      type: String,
      default: null
    },
    dropdownHightlightColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    tagColor: {
      type: String,
      default: 'rgba(0, 124, 246, 0.1)'
    },
    customSearchFunction: {
      type: Function,
      default: undefined
    },
    searchParams: {
      type: Array,
      default: () => []
    },
    preInputIcons: {
      type: Array,
      default: () => []
    },
    postInputIcons: {
      type: Array,
      default: () => []
    },
    tagsTitleKey: {
      type: String,
      default: null
    },
    delimiters: {
      type: Array,
      default: () => []
    },
    tagsIcons: {
      type: Array,
      default: () => []
    },
    multiSearchDropdownData: {
      type: Object,
      default: () => ({})
    },
    ruleBuilderRoot: {
      type: Object,
      default: () => ({})
    },
    chipPaddingTop: {
      type: String,
      default: '16px'
    },
    chipPaddingBottom: {
      type: String,
      default: '11px'
    },
    filterV2: {
      type: Boolean,
      default: false
    },
    chipSize: {
      type: String,
      default: 'm'
    },
    disableBetweenOperator: {
      type: Boolean,
      default: false
    },
    addFilterText: {
      type: String,
      default: 'Add Filters'
    },
    page: {
      type: String,
      default: undefined
    },
    defaultDatePreRange: {
      type: String,
      default: null
    },
    showComparedToText: {
      type: Boolean,
      default: false
    },
    allowDateCompare: {
      type: Boolean,
      default: false
    },
    defaultDatePostRange: {
      type: String,
      default: 'last90Days'
    },
    margin: {
      type: Array,
      default: function () {
        return ['u-spacing-m-m'];
      }
    },
    onCreate: {
      type: Function,
      default: undefined
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function () {
        return {
          search: {}
        };
      }
    },
    state: {
      type: Object,
      default: function () {
        return {};
      }
    },
    defaultFilters: {
      type: Object,
      default() {
        return {
          order: [],
          values: {}
        };
      }
    },
    enableSave: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    emit: {
      type: String,
      default: undefined
    },
    useLocalStorage: {
      type: Boolean,
      default: true
    },
    maxDate: {
      type: String,
      default: undefined
    }, // This one is key used in getMaxDate getter is not actually setting that as date
    loaderStatus: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Array,
      default: () => {
        return [];
      }
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    secondaryData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    listenerEvent: {
      type: String,
      default: null
    },
    onSearchInput: {
      type: Function,
      default: function () {}
    },
    onSearchButtonClick: {
      type: Function,
      default: function () {}
    },
    defaultCalendarValue: {
      type: String,
      default: ''
    },
    date: {
      type: Boolean,
      default: false
    },
    newDate: {
      type: Boolean,
      default: false
    },
    enableTimeSelect: {
      type: Boolean,
      default: false
    },
    applyFilter: {
      type: Function,
      default: undefined
    },
    dateRanges: {
      type: Array,
      default: function () {
        return [
          'Last 7 days',
          'Last 30 days',
          'Last 90 days',
          'Last Week',
          'Last Month',
          'Last Quarter'
        ];
      }
    },
    getFilterInstance: {
      type: Function,
      default: undefined
    },
    extraSearchWrapClass: {
      type: String,
      default: ''
    },
    enableRemoveAll: {
      type: Boolean,
      default: true
    },
    filterForOperators: {
      type: Boolean,
      default: false
    },
    showNotification: {
      type: Boolean,
      default: true
    },
    hideAddFilter: {
      type: Boolean,
      default: false
    },
    hideAddFilterButton: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: true
    },
    useDifferentDateForCannedDates: {
      type: String,
      default: null
    },
    // accepts object in the structure
    // additionalRanges: {
    //    add: ['last60Days'],
    //    order: {
    //     last60Days: 2
    //   }
    // }
    // order section is optional and determines where the additional index is inserted.
    additionalRanges: {
      type: Object,
      default: function () {
        return {};
      }
    },
    commonDateKey: {
      type: String,
      default: null
    },
    minMaxDate: {
      type: Object,
      default: function () {
        return {};
      }
    },
    enableOpenFilter: {
      type: Boolean,
      default: true
    },
    isAlwaysOnFilter: {
      type: Boolean,
      default: () => false
    },
    customConfig: {
      type: Object,
      default: () => {}
    },
    selectionsJoiningString: {
      type: String,
      default: ', '
    },
    dateMode: {
      type: Object,
      default: function () {
        return {
          mode: 'day'
        };
      }
    },
    showNextLineEllipsis: {
      type: Boolean,
      default: false
    },
    isCva: {
      type: Boolean,
      default: false
    },
    operatorsToFilterOut: {
      type: Array,
      default: () => []
    },
    enableFilterPersistence: {
      type: Boolean,
      default: false
    },
    userPageMetadata: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedDates: {},
      selectedTimeRange: {},
      maxSuggestionsDropdownWidth: '400px',
      tagsAggregatedView: true,
      localLoaderStatus: false,
      searchText: '',
      instances: [],
      addFilterInstance: null,
      filters: [],
      selectedFilters: {},
      selectedFilterValues: {},
      simulating: false,
      dateString: '',
      filtersOrder: [],
      localSavedFilters: [],
      checkSavedFilterDuplicacy: false,
      useCommonDate: false,
      isFilterDropdownActive: false
    };
  },
  computed: {
    getCurrentScopedSlots() {
      return Object.keys(this.$scopedSlots);
    },
    getSelectedFilter() {
      const selectedFiltersFromStore =
        this.$store.getters?.[this.state?.getter];
      const defaultFilters = {
        date_range: {},
        ...(this.enableTimeSelect ? { time_range: {} } : {})
      };
      return selectedFiltersFromStore || defaultFilters;
    },
    getDifferentDateForCannedDates() {
      if (!this.useDifferentDateForCannedDates) {
        return null;
      }

      return (
        this.$store.getters.getMaxDate[this.useDifferentDateForCannedDates] ||
        {}
      ).max_feed_date;
    },
    getMaxDate() {
      if (this.minMaxDate && this.minMaxDate.maxDate) {
        return this.minMaxDate.maxDate;
      } else {
        return (this.$store.getters.getMaxDate[this.maxDate || 'catalog'] || {})
          .max_feed_date;
      }
    },
    getMinDate() {
      if (this.minMaxDate && this.minMaxDate.minDate) {
        return this.minMaxDate.minDate;
      } else {
        return (this.$store.getters.getMaxDate[this.maxDate || 'catalog'] || {})
          .min_feed_date;
      }
    }
  },
  watch: {
    primary(val) {
      this.handlePersistence();
      if (this.listenerEvent) {
        filterUtils.init(this.listenerEvent, val);
      }
      this.decideOnSaveIcon();
    },
    selectedFilterValues: {
      handler: function (newVale) {
        this.$emit('clearBulkBanner');
        this.$emit('allFilter', this.selectedFilterValues);
      },
      deep: true
    },
    defaultFilters(val) {
      if (val && val.order) {
        this.filtersOrder = this.defaultFilters.order;
      }
      if (val && val.values) {
        this.selectedFilterValues = this.defaultFilters.values;
      }
      if (
        this.filtersOrder.length === 0 ||
        Object.keys(this.selectedFilterValues).length === 0
      ) {
        this.filtersOrder = [];
        this.selectedFilterValues = {};
      }
      if (
        this.filtersOrder.length === 0 &&
        Object.keys(this.selectedFilterValues).length === 0
      ) {
        this.removeAllFilters(false);
      }
    },
    useCommonDate(val) {
      if (val === false) {
        localStorage.removeItem(this.commonDateKey);
      } else if (val === true) {
        if (this.selectedFilterValues && this.selectedFilterValues.date_range) {
          localStorage.setItem(
            this.commonDateKey,
            JSON.stringify(this.selectedFilterValues.date_range)
          );
        }
      }
    }
  },
  mounted() {
    this.$emit('filterMounted', this);
  },
  beforeDestroy() {
    this.$emit('filterBeforeDestroy');
  },
  created() {
    this.initializeCommonDate();
    this.initializeFilterInstances();
    this.initializeLocalStorage();
    this.initializeDefaultView();
    this.handleFilterValues();
    this.handleOnCreate();
    this.handlePersistenceIfNeeded();
    this.setupSavedFilterListener();
  },

  methods: {
    initializeCommonDate() {
      if (this.commonDateKey) {
        this.useCommonDate =
          JSON.parse(localStorage.getItem(this.commonDateKey)) !== null;
      }
    },
    initializeFilterInstances() {
      if (this.getFilterInstance) {
        this.getFilterInstance(this);
      }
      if (this.getSearchBarTagsAndSuggestionsInstance) {
        this.getSearchBarTagsAndSuggestionsInstance(this);
      }
    },
    initializeLocalStorage() {
      if (this.useLocalStorage && this.listenerEvent) {
        this.filtersOrder =
          JSON.parse(localStorage.getItem(this.listenerEvent + '-order')) || [];
        this.selectedFilterValues =
          JSON.parse(localStorage.getItem(this.listenerEvent)) || {};
      } else {
        this.filtersOrder = this.defaultFilters.order;
        this.selectedFilterValues = this.defaultFilters.values;
      }
    },
    initializeDefaultView() {
      if (
        this.enableFilterPersistence &&
        this.userPageMetadata?.page?.[this.page]
      ) {
        const pageFilters = this.userPageMetadata.page[this.page].filters;
        const existingFilters = JSON.parse(
          localStorage.getItem(this.listenerEvent)
        );
        const dateRangeFilter = {
          ...existingFilters,
          date_range: pageFilters?.date_range
        };
        const pageFiltersOrder =
          this.userPageMetadata.page[this.page].filtersOrder;

        if (pageFilters) {
          localStorage.setItem(
            this.listenerEvent,
            JSON.stringify(dateRangeFilter)
          );
        }
        if (pageFiltersOrder) {
          localStorage.setItem(
            this.listenerEvent + '-order',
            JSON.stringify(pageFiltersOrder)
          );
        }
      }
    },
    handleFilterValues() {
      this.handleFilterEmit({
        selectedFilterValues: this.selectedFilterValues
      });
    },
    handleOnCreate() {
      if (this.onCreate) {
        this.onCreate(this);
      }
    },
    handlePersistenceIfNeeded() {
      if (
        this.primary.length !== 0 ||
        (Object.keys(this.secondaryData).length !== 0 && this.isCva)
      ) {
        this.handlePersistence(true);
      }
    },
    setupSavedFilterListener() {
      eventBus.$on('savedFilterAdded', () => {
        this.decideOnSaveIcon();
      });
    },
    handleClearAll(data) {
      this.$emit('clearAll', data);
    },
    handleSuggestionClick(data) {
      this.$emit('suggestionClick', data);
    },
    handleInputFieldClick(data) {
      this.$emit('inputFieldClick', data);
    },
    handleBufferedTagClicked(data) {
      this.$emit('bufferedTagClicked', data);
    },
    handleTagClicked(data) {
      this.$emit('tagClicked', data);
    },
    handleSearchSubmit(data) {
      if (this.customSearchFunction) {
        this.tagsAndSuggestionsInstance.textInput = '';
        this.customSearchFunction();
      }
    },
    addSearchTerms({ dimensionNameValueList, element, found }) {
      for (let j = 0; j < dimensionNameValueList.length; j++) {
        if (dimensionNameValueList[j].dimensionName === element) {
          found = true;
          dimensionNameValueList[j] = {
            dimensionName: element,
            dimensionValue: this.searchValue,
            operator: 'ILIKE'
          };
        }
      }
      if (!found) {
        dimensionNameValueList.push({
          dimensionName: element,
          dimensionValue: this.searchValue,
          operator: 'ILIKE'
        });
      }
    },
    removeSearchTerms({ dimensionNameValueList, searchParams }) {
      for (let j = 0; j < dimensionNameValueList.length; j++) {
        for (const element of searchParams) {
          if (dimensionNameValueList[j].dimensionName === element) {
            dimensionNameValueList.splice(j, 1);
          }
        }
      }
    },
    filterPanelSearch() {
      this.$store.dispatch('setTablePage', 1);
      this.config.body.APIConfig.page = 1;
      const dimensionNameValueList =
        (this?.config?.meta || {})?.localFilters || [];
      if (this.config.header.searchParams) {
        const searchParams = this.config.header.searchParams;
        if (this.searchValue && this.searchValue.length > 0) {
          for (const element of searchParams) {
            this.addSearchTerms({
              dimensionNameValueList,
              element,
              found: false
            });
          }
        } else {
          this.removeSearchTerms({ dimensionNameValueList, searchParams });
        }
      }
      this.config.body.APIConfig.page = 1;
      this.config.meta.localFilters = dimensionNameValueList;
      this.$store.dispatch(this.config.meta.action, this.config);
    },
    handleTextInput(data) {
      this.$emit('textInput', data);
    },
    handleOpenBufferedTagsDropdown(data) {
      this.$emit('openBufferedTagsDropdown', data);
    },
    handleOpenActiveTagsDropdown(data) {
      this.$emit('openActiveTagsDropdown', data);
    },
    getTagsAndSuggestionsInstance(instance) {
      this.tagsAndSuggestionsInstance = instance;
    },
    handleRuleApply(payload) {
      this.$emit('ruleApply', payload);
    },
    handleFilterStateChange(payload) {
      this.isFilterDropdownActive = payload;
      this.$emit('filter-state-change', payload);
    },
    appendToFilters(filters, emit = true, setState = true) {
      const { values, order } = filters;
      localStorage.setItem(this.listenerEvent, JSON.stringify(values));
      localStorage.setItem(
        `${this.listenerEvent}-order`,
        JSON.stringify(order)
      );
      this.setFilters(filters, emit, setState);
    },
    setOrder(filter) {
      if (filter && filter.order) {
        this.filtersOrder = filter.order;
      }
    },
    setValues(filter) {
      if (filter && filter.values) {
        this.selectedFilterValues = filter.values;
      }
    },
    setFilters(filter, emit = true, setState = false) {
      // if calling externally, remember to change the local storage contents to param filter's order and values otherwise this wont cause a rerender
      this.setOrder(filter);
      this.setValues(filter);
      if (this.primary.length !== 0) {
        this.handlePersistence(emit, setState);
      }
      if (
        this.filtersOrder.length === 0 &&
        Object.keys(this.selectedFilterValues).length === 0
      ) {
        this.removeAllFilters(false);
      }
    },
    onNavBackToMainFilterPage() {
      this.addFilterInstance.open();
    },
    decideOnSaveIcon() {
      if (this.listenerEvent) {
        const localStoredOrderData =
          JSON.parse(localStorage.getItem(this.listenerEvent + '-order')) || [];
        const localStoredData =
          JSON.parse(localStorage.getItem(this.listenerEvent)) || [];

        const hash = filterUtils.calculateHash({
          order: localStoredOrderData,
          filter: localStoredData
        });
        const storedSavedFilterValues = filterUtils.getHash(this.listenerEvent);
        Vue.nextTick(() => {
          if (storedSavedFilterValues.hash.indexOf(hash) !== -1) {
            this.checkSavedFilterDuplicacy = false;
          } else {
            this.checkSavedFilterDuplicacy = true;
          }
        });
      }
    },
    getUpdatedFilters(filter) {
      const x = filter.selections.concat([]);
      const y = this.selectedFilterValues[filter.anchor] || [];
      for (let i = 0; i < x.length; i++) {
        if (filter.add === true) {
          if (y.indexOf(x[i]) === -1) {
            y.push(x[i]);
          }
        } else {
          if (y.indexOf(x[i]) !== -1) {
            y.splice(i, 1);
          }
        }
      }
      return y;
    },
    addFilter(filter) {
      this.localLoaderStatus = true;
      this.simulating = true;

      const secondary = [];
      for (let i in this.secondaryData || {}) {
        secondary.push({
          dimensionColumn: this.secondaryData[i].key,
          dimensionLabel: this.secondaryData[i].label,
          dimensionName: this.secondaryData[i].key,
          dimensionType: this.secondaryData[i].type || 'OTHERS',
          smart: false,
          multi: this.secondaryData[i].multi,
          item: this.secondaryData[i],
          v1: this.secondaryData[i]?.v1
        });
      }
      const finalArray = this.primary.concat(secondary);

      filter.selections = this.getUpdatedFilters(filter);

      for (const element of finalArray) {
        if (
          filter.anchor ===
          transformer.addEsDataSetNameAsIdentifier(
            this.$route?.meta?.appendEsDataSetName,
            element.dimensionName,
            element.esDataSetName
          )
        ) {
          element.addFilterFlow = true;
          this.onPrimarySelect(element);
          this.onFilterApply(filter);
          break;
        }
      }
      const message = '"' + filter.anchor + '" filter applied';
      this.$snackbar.open({
        message,
        duration: 2000,
        buttonColor: '#f5d908',
        actionText: '              '
      });

      setTimeout(
        function () {
          // due to this timout, sub filters dont open for the first
          // time within two second of being interacted with
          this.localLoaderStatus = false;
          this.simulating = false;
        }.bind(this),
        2000
      );
    },
    openAddFilter() {
      if (this.addFilterInstance && this.addFilterInstance.open) {
        this.addFilterInstance.open();
      }
    },
    removeAllFilters(showSnackBar) {
      for (const instance of this.instances) {
        if (instance) {
          instance.removeSelections();
          this.filters = [];
          this.instances = [];
        }
      }
      if (showSnackBar !== false) {
        this.$snackbar.open({
          message: 'All filters removed',
          duration: 2000,
          buttonColor: '#f5d908',
          actionText: '              '
        });
      }
    },
    handleFilterEmit({ selectedFilterValues }) {
      if (this.state.setter) {
        this.$store.dispatch(this.state.setter, {
          filterValueKey: 'filter',
          values: selectedFilterValues,
          page: this.page
        });
      }
    },
    emitSelectedFilter({
      localStoredOrderData,
      finalArray,
      setState,
      emit,
      localStoredData
    }) {
      for (const data of localStoredOrderData) {
        for (const element of finalArray) {
          if (
            data ===
            transformer.addEsDataSetNameAsIdentifier(
              this.$route?.meta?.appendEsDataSetName,
              element.dimensionName,
              element.esDataSetName
            )
          ) {
            this.onPrimarySelect(element);
            break;
          }
        }
      }
      if (setState) {
        this.handleFilterEmit({
          selectedFilterValues: this.selectedFilterValues
        });
      }
      if (emit && this.emit) {
        eventBus.$emit(this.emit, localStoredData);
      }
    },
    handlePersistence(emit, setState) {
      this.simulating = true;
      this.localLoaderStatus = true;
      let localStoredOrderData = [];
      let localStoredData = {};
      if (this.useLocalStorage && this.listenerEvent) {
        localStoredOrderData =
          JSON.parse(localStorage.getItem(this.listenerEvent + '-order')) || [];
        localStoredData =
          JSON.parse(localStorage.getItem(this.listenerEvent)) || {};
      } else {
        localStoredOrderData = this.defaultFilters.order;
        localStoredData = this.defaultFilters.values;
      }

      const secondary = [];
      for (let i in this.secondaryData || {}) {
        secondary.push({
          dimensionColumn: this.secondaryData[i].key,
          dimensionLabel: this.secondaryData[i].label,
          dimensionName: this.secondaryData[i].key,
          dimensionType: this.secondaryData[i].type || 'OTHERS',
          smart: false,
          multi: this.secondaryData[i].multi,
          item: this.secondaryData[i],
          v1: this.secondaryData[i]?.v1
        });
      }
      const finalArray = this.primary.concat(secondary);
      this.emitSelectedFilter({
        localStoredOrderData,
        finalArray,
        setState,
        emit,
        localStoredData
      });
      setTimeout(
        function () {
          this.simulating = false;
          this.localLoaderStatus = false;
          if (this.listenerEvent) {
            eventBus.$emit(this.listenerEvent, localStoredData || {});
          }
        }.bind(this),
        2000
      );
    },
    onAddFilterCreate(instance) {
      this.addFilterInstance = instance;
    },
    onFilterCreate(instance) {
      this.instances.push(instance);
      if (!this.simulating && this.listenerEvent) {
        instance.addFilter(this.selectedFilterValues);
        instance.openFilter(true);
      } else if (!this.simulating) {
        instance.openFilter();
      }
    },
    onChipFilterCreate(instance) {
      this.instances.push(instance);
      if (!this.simulating && this.listenerEvent) {
        instance.addFilter(this.selectedFilterValues);
      }
    },
    formulateMap() {
      this.selectedFilters = {};
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i]) {
          this.selectedFilters[this.filters[i].anchor] = i;
        }
      }
    },
    handlerForSavedFilters({ selection }) {
      const dimensionName = transformer.addEsDataSetNameAsIdentifier(
        this.$route?.meta?.appendEsDataSetName,
        selection.dimensionName,
        selection.esDataSetName
      ); // to distinguish between sku and campaign internal filters as they are generic names such as dimension1,dimenion2
      const index = this.selectedFilters[dimensionName];
      if (this.selectedFilters[dimensionName] === undefined) {
        this.filters.push({
          title: selection.dimensionLabel,
          anchor: dimensionName,
          type: selection.dimensionType,
          config: selection,
          smart: selection.smart === undefined ? true : selection.smart,
          multi: selection.multi === undefined ? true : selection.multi,
          item: selection.item,
          v1: selection?.v1
        });
        this.formulateMap();
      } else {
        if (selection.addFilterFlow) {
          this.instances[index].refresh();
        } else {
          const instances =
            this?.instances?.[this?.pinnedChipFilterList?.length + index];
          if (this?.enableOpenFilter && instances) {
            instances.openFilter(true);
          }
        }
      }
    },
    handlerForOtherFilters({ selection }) {
      const selectionDetails =
        selection?.addtionalMetadata?.filterInfo?.fields || {};
      const order = selectionDetails.order || [];
      const selectedFilterValues = selectionDetails.filter || {};
      selectedFilterValues.date_range =
        this.selectedFilterValues.date_range || {};

      if (order.length > 0 && this.listenerEvent && this.useLocalStorage) {
        localStorage.setItem(
          this.listenerEvent + '-order',
          JSON.stringify(order)
        );
        localStorage.setItem(
          this.listenerEvent,
          JSON.stringify(selectedFilterValues)
        );
      }
      this.$emit('savedFilterApplied', selection);
    },
    onPrimarySelect(selection) {
      if (
        this.filterPrimarySelectStaging &&
        selection.dimensionType === 'MARKET_SHARE_CATEGORISATION'
      ) {
        const proceed = this.filterPrimarySelectStaging(
          selection,
          this.filters
        );
        if (proceed) {
          // Continue
        } else {
          return;
        }
      }
      if (
        selection.dimensionType !== 'CLIENT_SAVED_FILTERS' &&
        selection.dimensionType !== 'MY_SAVED_FILTERS'
      ) {
        this.handlerForSavedFilters({ selection });
      } else {
        this.handlerForOtherFilters({ selection });
      }
    },
    getSelectedFilters() {
      return {
        values: { ...this.selectedFilterValues },
        order: this.filtersOrder
      };
    },
    getFiltersFullMetadata() {
      const obj = {};
      const selectedFilterValues = JSON.parse(
        JSON.stringify(this.selectedFilterValues)
      );
      // Removing Empty Values for delete case.
      for (const i in selectedFilterValues) {
        if (selectedFilterValues[i] && selectedFilterValues[i].length === 0) {
          delete selectedFilterValues[i];
        }
      }
      let tempData = this.primary.slice(0);
      const secondarydata = Object.keys(this.secondaryData).map((data) => {
        const obj = {};
        obj.dimensionColumn = this.secondaryData[data].key;
        obj.dimensionLabel = this.secondaryData[data].label;
        obj.dimensionName = this.secondaryData[data].key;
        obj.dimensionType = this.secondaryData[data].type || 'OTHERS';
        return obj;
      });
      tempData = tempData.concat(secondarydata);
      Object.keys(selectedFilterValues).forEach((item) => {
        tempData.forEach((primary) => {
          if (
            transformer.addEsDataSetNameAsIdentifier(
              this.$route?.meta?.appendEsDataSetName,
              primary.dimensionName,
              primary.esDataSetName
            ) === item
          ) {
            if (selectedFilterValues[item]) {
              let innerObj = {};
              innerObj = { ...(primary || {}) };
              // Change specific to email subscription for Object.hash
              delete innerObj.dimensionColumn;
              delete innerObj.dimensionName;
              innerObj.isInvalidated = false;
              innerObj.values = selectedFilterValues[item].slice(0);
              obj[primary.dimensionName] = { ...innerObj };
            }
          }
        });
      });
      return [obj];
    },
    transformSelectedFilter(selection) {
      // appending the esDataSetName to each selected filter as going forward while using V2 filters
      // the filter object will need esDataSetName to be passed too to distinguish between different
      // taxonomy filters. For example SKU taxonomy filters and campaign taxonomy filter
      return selection.selections.map((sel) =>
        selection?.config?.esDataSetName &&
        typeof sel === 'string' &&
        this.$route?.meta?.appendEsDataSetName
          ? `${sel}---${selection.config.esDataSetName}`
          : sel
      );
    },
    onFilterApply(selection) {
      Vue.set(
        this.selectedFilterValues,
        selection.anchor,
        this.transformSelectedFilter(selection)
      );
      const _selection = {};
      _selection[selection.anchor] = selection.selections;
      if (this.listenerEvent) {
        eventBus.$emit(this.listenerEvent, _selection);
      }
      const index = this.selectedFilters[selection.anchor];
      if (selection.selections.length === 0) {
        this.filters[index] = null;
        this.instances[index] = null;
        this.filters = this.filters.filter(function (item) {
          return Object.assign({}, item) !== {};
        });
        this.formulateMap();
      }
      const selectedFilterValues = Object.assign({}, this.selectedFilterValues);
      for (let i in selectedFilterValues) {
        if (selectedFilterValues[i] && selectedFilterValues[i].length === 0) {
          delete selectedFilterValues[i];
        }
      }
      this.filtersOrder = [];
      for (const element of this.filters) {
        if (element) {
          this.filtersOrder.push(element.anchor);
        }
      }
      if (this.useLocalStorage && this.listenerEvent) {
        localStorage.setItem(
          this.listenerEvent + '-order',
          JSON.stringify(this.filtersOrder)
        );
        localStorage.setItem(
          this.listenerEvent,
          JSON.stringify(selectedFilterValues)
        );

        if (this.enableFilterPersistence) {
          this.$store.dispatch('updateUserMetadata', {
            type: 'page',
            typeId: this.page,
            filters: { date_range: selectedFilterValues.date_range }
          });
        }
      }
      this.handleFilterEmit({ selectedFilterValues });
      if (this.emit) {
        eventBus.$emit(this.emit, selectedFilterValues);
      }
      if (this.applyFilter) {
        this.applyFilter(selectedFilterValues);
      }
      this.refreshFilterData();
      this.decideOnSaveIcon();
    },
    refreshFilterData() {
      // fixes bug where data was not getting refreshed when a filter was removed
      // refreshes data for any active filter panel when another filter either applied or removed
      (this.$refs?.filter || []).forEach((filter) => {
        filter.fetchDataIfActive();
      });
    },
    setSearch(searchText) {
      if (searchText) {
        this.searchText = searchText;
      }
    },
    searchSubmit() {
      if (this.config.search && this.config.search.emit) {
        eventBus.$emit(this.config.search.emit, this.searchText);
      }
    },
    getDateInFormat(__date) {
      const _date = new Date(__date);
      const year = _date.getFullYear();
      const month = '0' + (_date.getMonth() + 1);
      const date = '0' + _date.getDate();
      return [
        year,
        month.slice(month.length - 2, month.length - 2 + month.length),
        date.slice(date.length - 2, date.length - 2 + date.length)
      ].join('-');
    },
    storeDate(date, onCreated, typeOfEvent) {
      let shouldTriggerSubmit = false;
      if (
        !isEqual(date, this.selectedDates) &&
        typeOfEvent !== 'submit' &&
        onCreated !== true
      ) {
        shouldTriggerSubmit = true;
      }
      this.updateSelectedDates(date);
      const selectedFilterValues = this.prepareSelectedFilterValues();

      this.emitUpdatedFilterValues(selectedFilterValues);

      if (this.shouldUseLocalStorage()) {
        this.updateLocalStorage(date, selectedFilterValues);
        this.updateUserMetadata(selectedFilterValues);
      }

      this.applyFilterIfNeeded(selectedFilterValues, onCreated);
      this.decideOnSaveIcon();

      if (shouldTriggerSubmit) {
        eventBus.$emit(this.emit, date);
      }
    },
    storeTime(time, onCreated, typeOfEvent) {
      let shouldTriggerSubmit = false;
      if (
        !isEqual(time, this.selectedTimeRange) &&
        typeOfEvent !== 'submit' &&
        onCreated !== true
      ) {
        shouldTriggerSubmit = true;
      }
      this.updateSelectedTimeRange(time);
      const selectedFilterValues = this.prepareSelectedFilterValues();

      this.emitUpdatedFilterValues(selectedFilterValues);

      if (this.shouldUseLocalStorage()) {
        localStorage.setItem(
          this.listenerEvent,
          JSON.stringify(selectedFilterValues)
        );
        this.updateUserMetadata(selectedFilterValues);
      }

      this.applyFilterIfNeeded(selectedFilterValues, onCreated);
      this.decideOnSaveIcon();

      if (shouldTriggerSubmit) {
        eventBus.$emit(this.emit, time);
      }
    },
    updateSelectedDates(date) {
      this.selectedDates = date;
      Vue.set(this.selectedFilterValues, 'date_range', date);
    },
    updateSelectedTimeRange(time) {
      this.selectedTimeRange = time;
      Vue.set(this.selectedFilterValues, 'time_range', time);
    },
    prepareSelectedFilterValues() {
      const selectedFilterValues = { ...this.selectedFilterValues };

      for (const key in selectedFilterValues) {
        if (
          !selectedFilterValues[key] ||
          selectedFilterValues[key].length === 0
        ) {
          delete selectedFilterValues[key];
        }
      }

      return selectedFilterValues;
    },
    emitUpdatedFilterValues(selectedFilterValues) {
      this.handleFilterEmit({ selectedFilterValues });
    },
    shouldUseLocalStorage() {
      return this.useLocalStorage && this.listenerEvent;
    },
    updateLocalStorage(date, selectedFilterValues) {
      if (this.commonDateKey) {
        if (this.useCommonDate) {
          localStorage.setItem(this.commonDateKey, JSON.stringify(date));
        } else {
          localStorage.removeItem(this.commonDateKey);
        }
      }

      localStorage.setItem(
        this.listenerEvent,
        JSON.stringify(selectedFilterValues)
      );
    },
    updateUserMetadata(selectedFilterValues) {
      if (this.enableFilterPersistence) {
        const metadata = {
          type: 'page',
          typeId: this.page,
          filters: {
            date_range: selectedFilterValues.date_range,
            ...(this.enableTimeSelect
              ? { time_range: selectedFilterValues.time_range }
              : {})
          },
          calendarSelection: { date_range: selectedFilterValues.date_range }
        };
        this.$store.dispatch('updateUserMetadata', metadata);
      }
    },
    applyFilterIfNeeded(selectedFilterValues, onCreated) {
      if (this.applyFilter && onCreated) {
        this.applyFilter(selectedFilterValues);
      }
    },
    newUpdatedateSelection(date) {
      if (this.newDate) {
        this.storeDate(date, false, 'submit');
        if (this.emit) {
          eventBus.$emit(this.emit, date);
        }
        if (this.applyFilter) {
          this.applyFilter(this.selectedFilterValues);
        }
      }
    },
    updateTimeRangeSelection(time) {
      this.storeTime(time, false, 'submit');
      if (this.emit) {
        eventBus.$emit(this.emit, time);
      }
      if (this.applyFilter) {
        this.applyFilter(this.selectedFilterValues);
      }
    },
    updatedateSelection(date) {
      if (this.date) {
        if (date.range === 'Custom') {
          date.value = date.range + '|' + date.start + '|' + date.end;
        } else {
          date.value = date.range;
        }

        if (date && date.constructor.name === 'Object') {
          this.dateString =
            Vue.options.filters.printable_date(new Date(date.start)) +
            '  -  ' +
            Vue.options.filters.printable_date(new Date(date.end));
        }
        Vue.set(this.selectedFilterValues, 'date_range', {
          name: date.range,
          value: date.value,
          from: this.getDateInFormat(date.start),
          to: this.getDateInFormat(date.end)
        });
        const selectedFilterValues = Object.assign(
          {},
          this.selectedFilterValues
        );
        this.handleFilterEmit({ selectedFilterValues });
        if (this.useLocalStorage && this.listenerEvent) {
          localStorage.setItem(
            this.listenerEvent,
            JSON.stringify(selectedFilterValues)
          );
        }
        if (this.emit) {
          eventBus.$emit(this.emit);
        }
        if (this.applyFilter) {
          this.applyFilter();
        }
      }
    },
    customConfigGenerator(filter) {
      return {
        anchor: filter?.primaryData?.dimensionName,
        config: filter?.primaryData,
        title: filter?.primaryData?.dimensionLabel,
        type: filter?.primaryData?.dimensionType,
        smart: true,
        multi: true,
        chipSize: 's',
        filterV2: filter.filterV2 ?? this.filterV2,
        showSelectAll: filter.showSelectAll ?? false
      };
    }
  }
};
</script>

<style lang="css">
.search__input input {
  outline: none;
  border: none !important;
  background-color: #f5f7fa !important;
  border-radius: 4px !important;
  padding-left: 30px !important;
}

.search__input-icon {
  cursor: pointer;
  display: flex;
  color: #8b8f93;
}

.search__input-icon:hover {
  color: #007cf6;
}

.rb-datepicker .rb-datepicker-dropdown {
  right: 0;
}

.apply-to-all-checkbox.rb-checkbox.checkbox {
  align-items: center;
  max-width: 240px;
}

.apply-to-all-checkbox.rb-checkbox span.rb-control-label {
  font-size: 1.1rem;
}

.u-border-none {
  border: none !important;
}

.search__input input:focus {
  outline: none;
  box-shadow: none !important;
}

.u-filter-trigger-button {
  background-color: #f5f7fa !important;
  border-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 36px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
}
.u-filter-trigger-button .icon-filter::before {
  font-size: 16px;
}

.pin-date-range-wrapper .date-picker-trigger {
  border-right: none;
  border-right-width: 0px;
}
.pin-date-range-wrapper .pin-wrapper {
  border: 1px solid #e9eaeb;
  border-left: none;
  margin-left: -4px;
  padding-right: 8px;
  border-radius: 4px;
}
.pin-date-range-wrapper .date-picker-trigger:hover,
.pin-date-range-wrapper .date-picker-trigger:focus {
  border-right: none;
}
.pin-date-range-wrapper:hover .date-picker-trigger,
.pin-date-range-wrapper:focus .date-picker-trigger {
  border: 1px solid #caccce;
  border-right: none;
}
.pin-date-range-wrapper:hover .pin-wrapper,
.pin-date-range-wrapper:focus .pin-wrapper {
  border: 1px solid #caccce;
  border-left: none;
}

.disable-date-range-selection {
  opacity: 0.3;
  pointer-events: none;
}

.date-picker-wrapper-disabled {
  align-items: flex-start !important;
}
</style>
