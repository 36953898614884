<template>
  <div>
    <dashboard-inflator
      class="inventory-dashboard"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
      @filtersUpdated="filtersUpdated"
    />
  </div>
</template>

<script>
import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import inventorySkuTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import Vue from 'vue';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import headerWithSplitSection from '@/components/globals/dataTable/tableHeaderComponents/headerWithSplitSection.vue';
import splitCell from '@/pages/digital_shelf/inventory/splitCell.vue';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import statusTextWithIconAndBg from '@/components/globals/dataTable/tableComponentsWrapper/statusTextWithIconAndBg.vue';
import percentageWithCell from '@/components/basic/percentageBackgroundComponent.vue';
import InStockCell from '@/pages/digital_shelf/inventory/InStockCell.vue';
import iconText from '@/components/basic/iconText.vue';
import DashboardPivotedDataService from '@/utils/common/dashboardPivotedTableDataService.js';
import utils from '@/utils/helpers/';
import moment from 'moment-timezone';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      id: 9,
      name: 'inventory_availability_dashboard',
      title: 'Inventory Availability'
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      headerOptions: () => {
        return {
          chartingWb: {
            props: {
              headerOptions: [],
              enableRollup: true,
              enableDownload: true,
              selectedMetricLimit: 2,
              removeDropdown: true,
              disableAddNewMetricButton: true,
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto 30px',
                'grid-column-gap': '1%'
              }
            }
          },
          inventorySkuTable: {
            props: {
              headerOptions: [
                {
                  ui_component: 'selectWrapper',
                  props: {
                    dropdownOptions: this.zipcodesList,
                    selectedOption: this.selectedZipCode,
                    typeOfDropdown: 'text'
                  },
                  events: {
                    onClick: this.zipcodeSelected
                  }
                }
              ],
              headerContainerGridStyles: {
                'grid-template-columns': '180px 1fr auto 24px'
              },
              enableDownload: true,
              applyFormattingDownload: true,
              downloadFileCustomName:
                'SKU Breakdown By ' + this.selectedZipCode.title,
              searchParams: {
                show: true
              }
            }
          },
          inventoryAvailabilityStatus: {
            props: {
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto 24px'
              },
              searchParams: {
                show: true
              },
              enableDownload: true,
              customDownloadEnable: true,
              customDownloadFunction: this.inventoryAvailDownload
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        chartingWb: {
          ':widget': 'ChartingWB',
          ':timeseriesRollupBy': 'DAY'
        },
        inventorySkuTable: {
          ':widget': 'inventorySkuTable',
          ':orderByList': [],
          ':dimensionNameValueList': []
        },
        inventoryAvailabilityStatus: {
          ':widget': 'inventoryAvailabilityStatus',
          ':orderByList': []
        }
      },
      filterSettings: {
        emitEvent: 'inventoryFilterApply',
        uiKey: 0,
        filterBarLSKey: 'inventoryFilterBarLSKey',
        showFilterBar: true,
        filterBar: [
          {
            title: 'Region',
            key: 'region',
            addInFilter: false,
            selectType: 'dropdown',
            multiSelect: true,
            originalSelections: []
          },
          {
            title: 'Fulfillment',
            key: 'fulfillment',
            addInFilter: false,
            selectType: 'checkbox',
            originalSelections: []
          }
        ],
        show: true,
        backendCube: 'inventory_availability_dashboard',
        defaultDatePostRange: 'last30Days',
        enableSave: false,
        listenerEvent: 'inventory-filter',
        allowCompare: true,
        externalFilters: {}
      },
      widgetMetadata: [],
      zipcodesList: [],
      selectedZipCode: {}
    };
  },
  watch: {
    requestParams(newVal) {}
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('chartingWb', chartingWb);
    Vue.component('inventorySkuTable', inventorySkuTable);
    Vue.component('inventoryAvailabilityStatus', inventorySkuTable);
    Vue.component('dynamicComponentComposer', DynamicComponentComposer);
    Vue.component('headerWithSplitSection', headerWithSplitSection);
    Vue.component('statusTextWithIconAndBg', statusTextWithIconAndBg);
    Vue.component('SplitCell', splitCell);
    Vue.component('InStockCell', InStockCell);
    Vue.component('iconText', iconText);
    Vue.component('percentageWithCell', percentageWithCell);
  },
  methods: {
    async metadataUpdated(data) {
      this.widgetMetadata = data;
      const availabilityTable = data.find(
        (e) => e.name === 'inventoryAvailabilityStatus'
      );
      const inventorySkuTable = data.find(
        (e) => e.name === 'inventorySkuTable'
      );
      if (
        availabilityTable &&
        !availabilityTable?.label?.toLowerCase()?.includes('as on')
      ) {
        availabilityTable.label =
          availabilityTable.label +
          (availabilityTable.calendar.max_date
            ? ' AS ON ' +
              moment(availabilityTable.calendar.max_date).format('ll')
            : '');
      }
      if (inventorySkuTable) {
        await this.getZipcodeList(inventorySkuTable);
        this.zipcodeSelected(this.zipcodesList[0]);
      }
    },
    async filtersUpdated() {
      const inventorySkuTable = this.widgetMetadata.find(
        (e) => e.name === 'inventorySkuTable'
      );
      if (inventorySkuTable) {
        await this.getZipcodeList(inventorySkuTable);
        this.zipcodeSelected(this.zipcodesList?.[0]);
      }
    },
    async getZipcodeList(metadata) {
      if (metadata?.metadata?.headers) {
        /* Generate zipcode dropdown list for Inventory sku table
         * data[2] contains metadata for inventorySkuTable
         */
        const api = metadata.metadata.headers[0].api;
        let request = api.request;

        const placeholderData = {
          ...this.getGlobalRequestParams(),
          ':widget': metadata.name
        };

        request = dashUtils.replacePlaceHolderWithData(
          request,
          placeholderData
        );

        // TODO: Update this for first render from metadata
        // delete request.where.dimensionNameValueList;

        const res = await HttpService.post(api.service, request, {
          append: api.endPoint
        });

        // Generate the zipcode list in desired format to send to the wrapper component
        const zipcodeArr = [];
        res.data?.entityData?.forEach((item) => {
          if (item.data[0].RESULT.label) {
            zipcodeArr.push({
              title: `${item.data[0].RESULT.label}`,
              zipcodeValue: `${item.data[1].RESULT.zipcode}`
            });
          } else {
            zipcodeArr.push({
              title: `<Zipcode> ${item.data[1].RESULT.zipcode}`
            });
          }
        });
        Vue.set(this, 'zipcodesList', zipcodeArr);
      }
    },
    chartingWbDownloadTriggered() {
      try {
        this.dataService.downloadChartingData(
          this.requestParams?.value?.chartingWb,
          this.requestParams?.value
        );
      } catch (error) {
        console.error(error);
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    async inventoryAvailDownload(widgetMeta, requestParams) {
      try {
        const operationsObj = {
          limit: 999999,
          page: 1
        };

        const dataService = new DashboardPivotedDataService(
          widgetMeta,
          widgetMeta.name,
          requestParams.name,
          requestParams.id,
          widgetMeta.metadata.pivot
        );

        // Get table rows from list API
        const rows = await dataService.getTableRows(
          operationsObj,
          requestParams
        );

        // Construct columns in the desired format
        const columnsArr = [];

        columnsArr.push(
          {
            priority: 10,
            field: 'sku',
            title: 'SKU ID'
          },
          {
            priority: 20,
            field: 'title',
            title: 'SKU Title'
          },
          {
            priority: 30,
            field: 'customer_reviews_count',
            title: '# of Reviews'
          },
          {
            priority: 40,
            field: 'avg_rating',
            title: 'Average Ratings'
          },
          {
            priority: 50,
            field: 'delivery_in_stock_perc',
            title: 'Delivery In-Stock %'
          },
          {
            priority: 60,
            field: 'in_store_in_stock_perc',
            title: 'Pickup In-Stock %'
          }
        );

        // Construct list of all zipcodes in desired format column headers
        const parsedRegions = JSON.parse(rows.rows[0].agg_region)?.sort();

        let priority = 700;
        parsedRegions.forEach((region) => {
          columnsArr.push(
            {
              priority: priority,
              field: region + ' Delivery',
              title: region + ' Delivery'
            },
            {
              priority: priority + 1,
              field: region + ' Pick Up',
              title: region + ' Pick Up'
            }
          );
          priority += 100;
        });

        // Options mapping with proper casing
        const options = {
          AVAILABLE: 'Available',
          OUT_OF_STOCK: 'Out of Stock',
          TYPE_404_PAGE: 'Page Not Found'
        };

        const rowsArr = rows.rows.map((row) => {
          const obj = {};

          // Mapping basic columns
          obj.sku = row.sku;
          obj.title = row.title;
          obj.avg_rating = row.avg_rating;
          obj.customer_reviews_count = row.customer_reviews_count;

          // Calculating and computing the delivery and pick-up store percentage
          row.delivery_in_stock_perc = JSON.parse(row.delivery_in_stock_perc);
          row.in_store_in_stock_perc = JSON.parse(row.in_store_in_stock_perc);

          if (row.delivery_in_stock_perc?.length > 0) {
            // Calculating average percentage of entire array
            obj.delivery_in_stock_perc = (
              (row.delivery_in_stock_perc.reduce((prev, curr) => {
                return prev + curr;
              }) /
                row.delivery_in_stock_perc.length) *
              100
            ).toFixed(2);
          } else {
            obj.delivery_in_stock_perc = '-';
          }

          if (row.in_store_in_stock_perc?.length > 0) {
            obj.in_store_in_stock_perc = (
              (row.in_store_in_stock_perc.reduce((prev, curr) => {
                return prev + curr;
              }) /
                row.in_store_in_stock_perc.length) *
              100
            ).toFixed(2);
          } else {
            obj.in_store_in_stock_perc = '-';
          }

          // Mapping the zipcode labels (delivery and in stock) with column headers
          if (row?.pivotedData) {
            Object.values(row.pivotedData)?.forEach((elm) => {
              if (elm) {
                if (elm.Delivery) {
                  obj[elm.Delivery[0]?.label + ' Delivery'] =
                    options[elm.Delivery[0]?.availability] ||
                    elm.Delivery[0]?.availability;
                }
                if (elm.Pickup) {
                  obj[elm.Pickup[0]?.label + ' Pick Up'] =
                    options[elm.Pickup[0]?.availability] ||
                    elm.Pickup[0]?.availability;
                }
              }
            });
          }

          return obj;
        });

        utils.performDownload(rowsArr, columnsArr, widgetMeta.label);
      } catch (error) {
        console.error(error);
        throw Error(error);
      }
    },
    zipcodeSelected(data) {
      // When an option from dropdown is selected, update the 'dimensionNameValueList', with value and update widget params to re fetch the data
      const inventorySkuTableReqParams = cloneDeep(
        this.getWidgetRequestParams('inventorySkuTable')
      );
      // if existing zipcodes are selected, remove that.
      inventorySkuTableReqParams[':dimensionNameValueList'] =
        inventorySkuTableReqParams[':dimensionNameValueList'].filter(
          (filterItem) => {
            return filterItem.dimensionName !== 'zipcode';
          }
        );
      inventorySkuTableReqParams[':dimensionNameValueList'].push({
        dimensionName: 'zipcode',
        dimensionValue: data.zipcodeValue
      });
      this.selectedZipCode = data;
      this.updateWidgetRequestParams(
        inventorySkuTableReqParams,
        'inventorySkuTable'
      );
    }
  }
};
</script>

<style lang="css">
.inventory-dashboard .inventoryAvailabilityStatus .ag-header-cell {
  padding: 0;
}
.inventory-dashboard .inventoryAvailabilityStatus .ag-cell {
  margin: 0;
  padding: 0;
}
.inventory-dashboard .inventoryAvailabilityStatus .status-text-with-icon {
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  color: #2b333b;
}
.inventory-dashboard .inventoryAvailabilityStatus .splt-container,
.inventory-dashboard .inventoryAvailabilityStatus .col-header-title {
  margin-top: -4px;
}
.inventory-dashboard .inventoryAvailabilityStatus .textClass {
  color: #8b8f93;
  padding-top: 0;
}
.inventory-dashboard .inventoryAvailabilityStatus .iconText {
  padding-left: 1.6rem;
}
.inventory-dashboard
  .inventoryAvailabilityStatus
  div[col-id^='delivery_in_stock_perc'],
.inventory-dashboard
  .inventoryAvailabilityStatus
  div[col-id^='in_store_in_stock_perc'] {
  padding-right: 2.4rem;
  border: left;
  border-color: #e9eaeb;
  border-width: 1px;
}
.inventory-dashboard .inventoryAvailabilityStatus div[col-id^='sku'],
.inventory-dashboard
  .inventoryAvailabilityStatus
  div[col-id^='agg_fulfillment'] {
  padding-left: 1.6rem;
}
.inventory-dashboard
  .inventoryAvailabilityStatus
  div[col-id^='delivery_in_stock_perc'].ag-cell,
.inventory-dashboard
  .inventoryAvailabilityStatus
  div[col-id^='in_store_in_stock_perc'].ag-cell {
  margin: 0;
  padding: 0;
}
.inventory-dashboard .inventoryAvailabilityStatus .icon-cached:hover {
  color: #007cf6;
}
.inventory-dashboard .inputClassForEntityTaggingSearch {
  width: 240px;
  margin-right: 0.8rem;
  padding: 0;
}
.inventory-dashboard .search__input {
  box-shadow: none;
}
</style>
