<template>
  <component
    :is="params.colDef.cellRendererParams.component"
    v-bind="paramsToPropsComp(params)"
    v-on="eventMaper(params)"
    @navigateToSDP="navigateToSDP"
  />
</template>

<script>
import { isEmpty } from 'lodash';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
export default {
  name: 'DynamicCellComponent',
  created() {},
  methods: {
    paramsToPropsComp(params) {
      if (params.colDef.cellRendererParams.paramsToProps) {
        return params.colDef.cellRendererParams.paramsToProps(params);
      }
      return params;
    },
    eventMaper(params) {
      const userEventMap = params.colDef.cellRendererParams.eventMap;
      if (isEmpty(userEventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = this.params.context?.componentParent;
      if (parentReference) {
        Object.keys(userEventMap).forEach((listenerKey) => {
          vueEventMap[listenerKey] = parentReference[userEventMap[listenerKey]];
        });
      }

      return vueEventMap;
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style></style>
