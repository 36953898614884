<template>
  <div class="u-width-100">
    <div
      v-if="!params.data[params.colDef.cellRendererParams.url]"
      class="u-display-flex u-flex-align-items-center"
    >
      <rb-icon
        v-if="showIcon"
        class="u-spacing-mr-xxs"
        :icon="params.colDef.icon.name"
        :class="params.colDef.icon.class"
      />
      {{ params.data[params.colDef.field] }}
    </div>
    <div
      v-else
      class="linkDisplayCell"
    >
      <a
        v-if="params.tooltip"
        v-tippy="tippy"
        :title="params.tooltip.tooltipMessage"
        class="rb-link link--asin u-flex-align-items-center"
        :href="params.data[params.colDef.cellRendererParams.url]"
        target="_blank"
        @click.stop
      >
        <rb-icon
          v-if="showIcon"
          class="u-spacing-mr-xxs"
          :icon="params.colDef.icon.name"
          :class="params.colDef.icon.class"
        />
        {{ params.data[params.colDef.field] }}
      </a>
      <a
        v-else
        class="rb-link link--asin u-flex-align-items-center"
        :href="params.data[params.colDef.cellRendererParams.url]"
        target="_blank"
        @click.stop
      >
        <rb-icon
          v-if="showIcon"
          :icon="params.colDef.icon"
          :class="
            params.colDef.iconClass
              ? params.colDef.iconClass
              : 'rb-icon--small u-color-grey-light u-spacing-mr-xxs'
          "
        />
        {{ params.data[params.colDef.field] }}
      </a>
    </div>
    <div
      v-if="params.hoverRequired === true"
      class="cellHover u-font-weight-600 u-spacing-ml-m u-font-size-7 u-color-blue-base"
      @click="params.hoverClickFunction(params)"
    >
      <span class="u-spacing-ml-xs hover-span">{{ params.hoverText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'linkDisplay',
  components: {},
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      status: ''
    };
  },
  computed: {
    showIcon() {
      if (typeof this.params.colDef.showIcon === 'function') {
        return this.params.colDef.showIcon(this.params);
      }
      if (typeof this.params.colDef.showIcon === 'boolean') {
        return this.params.colDef.showIcon;
      }
      return false;
    }
  }
};
</script>

<style lang="css" scoped>
.ag-cell:hover .linkDisplayCell .cellHover {
  display: inline-flex;
}

.linkDisplayCell:hover .cellHover {
  display: inline-flex;
}

.cellHover .hover-span {
  padding: 3px 5px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid #007cf6;
}

.cellHover:hover .hover-span {
  color: white;
  background-color: #007cf6;
}

.ag-row-selected {
  .cellHover {
    background-color: #e6f3ff;
  }
}
</style>
