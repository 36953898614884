<template>
  <div>
    <div
      v-if="action.dropDown === true"
      :class="[
        mode,
        { 'u-spacing-p-s': applyPadding },
        { disabled: isActionDisabled(action) }
      ]"
      class="u-display-flex u-cursor-pointer u-icon-text-action u-border-radius-xs dropDownContainer"
    >
      <rb-select
        :width="'160px'"
        class
        :send-details="true"
        :context="action"
        :on-close="emitDropdownEvent"
        :options="dropdownOptions"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <div
            class="u-display-flex"
            :class="
              splitNewLineText
                ? 'u-flex-direction-column'
                : 'u-flex-direction-row'
            "
          >
            <rb-icon
              class="rb-icon--medium"
              :class="className"
              :icon="action.icon"
              data-cy="actionIcon"
            />
            <span
              class="u-font-size-8 u-text-case-upper u-spacing-pt-xs"
              data-cy="actionTitle"
              :class="className"
              >{{ action.title }}</span
            >
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div
            class="u-display-flex u-flex-align-items-center"
            data-cy="optionTitle"
          >
            <span>{{ option.title }}</span>
          </div>
        </template>
      </rb-select>
    </div>

    <div
      v-else
      v-tippy="getTippyOptions"
      :class="[
        mode,
        { 'u-spacing-p-s': applyPadding },
        { disabled: isActionDisabled(action) },
        { 'u-flex-direction-column': splitNewLineText },
        { 'u-flex-direction-row': !splitNewLineText }
      ]"
      class="u-display-flex u-cursor-pointer u-icon-text-action u-border-radius-xs"
      @click="clicked(action)"
    >
      <rb-icon
        class="rb-icon--medium"
        :class="[className, { 'u-spacing-mr-s': !splitNewLineText }]"
        :icon="action.icon"
      />
      <span
        class="u-text-case-upper u-spacing-pt-xs"
        data-cy="actionTitle"
        :class="[className, buttonTextSize]"
        >{{ action.title }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'IconTextActions',
  props: {
    action: {
      default() {
        return {};
      },
      type: Object
    },
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    applyPadding: {
      default: true,
      type: Boolean
    },
    mode: {
      default: 'light',
      type: String
    },
    parentId: {
      default: '-',
      type: String
    },
    className: {
      default: '',
      type: String
    },
    splitNewLineText: {
      default: true,
      type: Boolean
    },
    buttonTextSize: {
      default: 'u-font-size-8',
      type: String
    }
  },
  data() {
    return {
      dropdownOptions: [],
      currentAction: {}
    };
  },
  computed: {
    getTippyOptions() {
      var tippyObj = {
        html: '#' + this.parentId + this.action.id || '-',
        reactive: true,
        distance: 4,
        placement: 'bottom-start',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0]
      };
      return tippyObj;
    }
  },
  watch: {},
  created() {
    this.initializeForDropdown();
  },
  methods: {
    clicked(action) {
      this.$emit('clicked', action);
    },

    emitDropdownEvent(context, selectedOption) {
      // action has been passed throught context variable
      this.$emit('clicked', context, selectedOption);
    },
    isActionDisabled(action) {
      let actionDisabled = false;
      const selectionCount = this.selections.length;

      if (action.id === 'edit' && selectionCount > 1) {
        actionDisabled = true;
      }
      if (
        (action.id === 'approve' || action.id === 'edit') &&
        selectionCount === 1
      ) {
        if (
          this.selections[0].data.action_state !== 'OPEN' &&
          this.selections[0].data.action_state !== 'FAILED'
        ) {
          actionDisabled = true;
        }
      }
      return actionDisabled;
    },
    initializeForDropdown() {
      this.dropdownOptions =
        this.action.dropDownOptions === undefined
          ? []
          : this.action.dropDownOptions;
    }
  }
};
</script>
<style scoped lang="css">
.padded {
  padding: 8px 16px;
}

.u-icon-text-action.light {
  color: white;
}
.u-icon-text-action.light:active {
  color: rgba(43, 51, 59, 0.7);
  color: white;
}
.u-icon-text-action.light:hover {
  background: rgba(43, 51, 59, 0.4);
  color: white;
}

.u-icon-text-action.default {
  padding-top: 0;
  padding-bottom: 0;
  color: #8b8f93;
}

.u-icon-text-action.default:active {
  color: #2b333b;
}

.u-icon-text-action.default:hover {
  background: transparent;
  color: #2b333b;
}

.u-icon-text-action.disabled {
  opacity: 0.4;
  cursor: pointer;
  pointer-events: none;
}

.dropDownContainer .caretIcon {
  visibility: hidden;
}
.dropDownContainer:hover .caretIcon {
  visibility: visible;
}
.enlargeEmailIcon {
  width: 20px !important;
  height: 20px !important;
}
.enlargeEmailIcon:before {
  font-size: 20px !important;
}
</style>
