<template>
  <div
    class="bg__full-screen--semitransparent u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      class="u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center u-bg-color-grey-white u-border-radius-s u-dialog-wrap"
    >
      <div class="u-overflow-hidden u-width-100 u-height-100">
        <span @click="closeDialog">
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-cross-icon-position u-cursor-pointer"
            :icon="'cross'"
          />
        </span>
        <!-- <div class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-4 u-font-weight-600 u-spacing-pt-xxl">
            <rb-icon class="rb-icon--x-large u-color-blue-base" :icon="'light-bulb'"></rb-icon>
            <div class="u-flex-0 u-spacing-pt-xs u-font-size-3">
              Forecast Help Document.
            </div>
          </div> -->
        <div
          class="u-display-flex u-flex-justify-content-space-between u-spacing-pv-s u-width-100 u-height-100 u-iframe-class"
        >
          <iframe
            class="u-width-100 u-height-100"
            src="https://docs.google.com/document/d/e/2PACX-1vQDPLhy9jNzYYs6gOk3aWZ2K1jWoiuq7cpRGWFiYBvUupv-sRlco6bn45V7iSGu4g/pub?embedded=true"
          />
        </div>
        <!-- <div class="u-display-flex u-flex-justify-content-space-between u-spacing-pt-m u-spacing-pb-xxl">
            <rb-button class="hollow u-spacing-mr-m u-width-20" :text="'Exit'" :type="'hollow'"></rb-button>
            <div class="u-display-flex u-flex-justify-content-space-between">
              <rb-button class="hollow-outlined-btn u-spacing-mr-m" :text="'Cancel'" :type="'hollow'"></rb-button>
              <rb-button class="open-filter-btn" :text="'Save and Exit '" :type="'filled'"></rb-button>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    closeDialog(params) {
      this.$emit('closeCallback', params);
    }
  }
};
</script>

<style scoped lang="css">
.u-dialog-wrap {
  height: 80%;
  width: 80%;
  max-width: 740px;
}
.u-cross-icon-position {
  position: absolute;
  top: 16px;
  right: 24px;
}
</style>
