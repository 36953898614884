import { render, staticRenderFns } from "./absolute-change.vue?vue&type=template&id=3688abed&scoped=true&"
import script from "./absolute-change.vue?vue&type=script&lang=js&"
export * from "./absolute-change.vue?vue&type=script&lang=js&"
import style0 from "./absolute-change.vue?vue&type=style&index=0&id=3688abed&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3688abed",
  null
  
)

export default component.exports