<template>
  <section
    v-if="!destoryComponent"
    class="strategyBuilder u-position-fixed"
  >
    <div class="u-position-fixed model strategyBuilder">
      <!-- Header Start -->
      <section
        class="strategyBuilder--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span @click="goBackValidation()">
              <rb-icon
                :icon="'cross'"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <input
              v-model="selectedValues.strategyName"
              type="text"
              maxlength="56"
              :class="{
                'active-state': isTitleInputClicked,
                ' u-color-grey-lighter': !isTitleInputClicked
              }"
              onClick="this.select();"
              class="heading--input u-font-size-2 u-font-weight-bold"
              style="width: 100%"
              onkeypress="this.style.width = ((this.value.length + 1) * 16) + 'px';"
              @click="titleInputClicked()"
            />
          </div>
          <input
            v-model="selectedValues.description"
            type="text"
            maxlength="160"
            :placeholder="placeholder"
            class="heading--input strategy-desc u-font-size-6 u-spacing-pt-s u-spacing-ml-l"
          />
        </div>
        <div class="u-font-size-5 u-text-align-right u-display-flex">
          <rb-button
            v-if="
              selectedValues.mode && selectedValues.mode !== 'create'
                ? selectedValues.strategyStatus !== 'LIVE'
                : true
            "
            :text="'Save draft'"
            :type="'hollow'"
            class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s u-color-blue-base u-border-all u-border-color-blue-base u-border-width-s"
            :click-fn="saveStrategy.bind(this, 'draft')"
          />
          <rb-button
            id="confirmButton"
            :text="'Prioritise and Save'"
            :type="'filled'"
            :disabled="selectedValues.enableSaveBtn"
            :click-fn="saveStrategy.bind(this)"
          />
        </div>
      </section>
      <!-- Header ends -->
      <!-- Scroll section -->
      <StepComponent
        :config="stepsConfig"
        :all-steps-data="selectedValues"
        @stepsSkippedEvent="formatAllDisplayText"
        @stepsGlobalEvent="stepsGlobalEvent"
      />
      <!-- Scroll section -->
      <Loader
        v-show="isLoading"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import rbListSelect from '@/components/widgets/rbListSelect';
import rbFilterPanel from '@/components/widgets/filter-panel';
import Loader from '@/components/basic/loader';
import widgetsMixin from '@/components/widgetsMixin';

import ObjectiveStep from './steps/objectiveStep.vue';
import ScopeStep from './steps/scopeStep.vue';
import ConditionStep from './steps/conditionStep.vue';
import ActionStep from './steps/actionStep.vue';
import TimePeriodStep from './steps/timePeriodStep.vue';
import ReviewStep from './steps/reviewSaveStep.vue';

import StepComponent from './stepComponent';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';

export default {
  name: 'StrategyBuilder',
  owner: 'Rajan V',
  components: {
    rbListSelect,
    rbFilterPanel,
    Loader,
    StepComponent,
    ObjectiveStep,
    ScopeStep,
    ConditionStep,
    ActionStep,
    TimePeriodStep,
    ReviewStep
  },
  mixins: [widgetsMixin, stepsMixin],
  data() {
    const retailer = this.$store.getters.getRetailer;
    const retailerScopeStep =
      multiRetailerConfig.scopeStep[retailer] ||
      multiRetailerConfig.scopeStep.default;
    return {
      // filter keys
      isTitleInputClicked: false,
      tippy: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      placeholder: 'Give this strategy a description.',
      stepsConfig: [
        {
          name: 'Objective',
          header: {
            title: 'What is your objective?',
            desc: [
              'Choose from a list of objectives to manage and track your strategies better.'
            ]
          },
          keyToRead: 'standardObjectives',
          textMapping: 'objective',
          component: ObjectiveStep
        },
        {
          name: 'Scope',
          header: {
            title: 'Where do you want to apply this strategy?',
            desc: retailerScopeStep.desc
          },
          keyToRead: 'scope',
          textMapping: 'scope',
          component: ScopeStep
        },
        {
          name: 'Condition',
          retailer: retailer || null,
          header: {
            title: 'What conditions do you want Entity to meet?',
            placeholder: 'What conditions do you want Entity to meet?',
            desc: [
              'CommerceIQ only takes an action when the conditions you specify are met.'
            ]
          },
          keyToRead: 'conditions',
          textMapping: 'condition',
          component: ConditionStep
        },
        {
          name: 'Actions',
          header: {
            title: 'What actions do you want to take on Entity?',
            placeholder: 'What actions do you want to take on Entity?',
            desc: [
              'Specify the actions you want to be performed on items identified before. If all conditions you specify are met, CommerceIQ takes automated actions.'
            ]
          },
          keyToRead: 'actions',
          textMapping: 'actions',
          component: ActionStep
        },
        {
          name: 'Time Period',
          header: {
            title: 'What is the time period of this strategy?',
            desc: [
              'Start time is from when CommerceIQ will run this strategy. End date is when CommerceIQ will pause this strategy. To ensure strategies run automatically continuously, please select No end date.',
              'The strategy will run every day at 5 AM PST and actions will be taken at 5:10 AM PST.'
            ]
          },
          textMapping: 'timeperiod',
          component: TimePeriodStep
        },
        {
          name: 'Review & Save',
          header: {
            title: 'Review the strategy:',
            desc: [
              'Choosing this saves the strategy to be activated from your specified start date.',
              'To review this strategy later, choose Save Draft.'
            ]
          },
          component: ReviewStep
        }
      ],
      allSteps: {},
      destoryComponent: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoadingStatus;
    },
    selectedValues() {
      return this.$store.getters.getStrategyDetails;
    }
  },
  created() {},
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.$store.dispatch('fetchStrategyMetaData', {
        mode: this.$route.query.mode,
        id: this.$route.query.id
      });
    } else {
      this.$store.dispatch('fetchStrategyMetaData');
    }
  },
  destroyed() {
    this.destoryComponent = true;
    this.$store.dispatch('clearFilter');
  },
  methods: {
    titleInputClicked() {
      this.isTitleInputClicked = true;
    },
    goBackValidation() {
      this.$router.push({ name: 'StrategyList' });
    },
    stepsGlobalEvent(params) {
      if (params.type === 'ValidationBtn') {
        const currentStep = params.values.step;
        this.allSteps[currentStep] = params.values.valid;
        const stepKeys = Object.keys(this.allSteps);
        const validate = stepKeys.every((item) => {
          return this.allSteps[item];
        });
        Vue.set(this.selectedValues, 'enableSaveBtn', !validate);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.strategyBuilder {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.strategyBuilder .strategyBuilder--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}

.strategy-desc {
  font-style: italic;
}

.heading--input {
  max-width: 75%;
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}
.scrollable {
  height: calc(100vh - 95px);
}

#steps {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 8;
}

.fullpage {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fullpage:last-child {
  height: 100vh;
}

h1 {
  font-size: 6em;
  margin: 0;
  text-align: center;
  padding: 0 1rem;
}

p {
  font-size: 1em;
}

.fullpage a {
  text-decoration: none;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.3);
  padding: 5px 10px;
  color: #fff;
  margin-left: 5px;
}

.u-margin-top-80 {
  margin-top: 80px;
}

.u-dot::after {
  content: '\2022';
  width: 10px;
  height: 10px;
}

.strategy_bullet {
  color: #2b333b;
}

.hide_calendar_strategy {
  display: none !important;
}

.strategy_bullet.strategy_bullet_active {
  color: #007cf6;
}

.u-scope-filter_wrp .filter-dropdown-content {
  max-height: 200px !important;
}
.disable-state {
  opacity: 0.55;
}

.margin-top-40 {
  margin-top: 40%;
}

.margin-top-8 {
  margin-top: 8%;
}
</style>
