const config = () => {
  return {
    widgets: {
      contentChangeColumns: {
        columnDefs: [
          {
            headerComponentFramework: 'genericTableHeader',
            headerComponentParams: {
              toolTipText: 'ASIN'
            },
            title: 'ASIN',
            headerName: 'ASIN',
            field: 'asin',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            width: 250,
            pinned: 'left',
            maxWidth: 400
          },
          {
            headerComponentFramework: 'genericTableHeader',
            headerComponentParams: {
              toolTipText: 'Variation Group'
            },
            title: 'Variation Group',
            headerName: 'Variation Group',
            field: 'variation_group',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            minWidth: 400
            // maxWidth: 700
          }
        ]
      },
      APIConfig: {
        cubeName: 'client_variants_info',
        measuresList: ['variation_group'],
        groupByDimensionsList: ['asin']
      }
    }
  };
};

export default {
  config: config
};
