import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

const state = {
  insightsPerformanceOverviewFilterValues: {},
  performanceOverviewFilters: [],
  insightsPerformanceOverviewSelectedFilter: {
    date_range: {
      name: 'This Month'
    }
  },
  insightsPerformanceOverviewWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    performance_overview_category: 'category',
    performance_overview_subcategory: 'subcategory',
    performance_overview_product_group: 'product_group',
    performance_overview_brand: 'brand',
    performance_overview_date_appeared: 'alert_estimate_feed_date',
    performance_overview_alert_name: 'alert_name'
  },
  insightsPerformanceOverviewData: {}
};

const getters = {
  getInsightsPerformanceOverviewData: (state) => {
    return state.insightsPerformanceOverviewData;
  },
  getInsightsPerformanceOverviewSelectedFilter: (state) => {
    return state.insightsPerformanceOverviewSelectedFilter;
  }
};

const mutations = {
  INSIGHTSPERFORMANCEOVERVIEW_SUCCESS: (state, data) => {
    Vue.set(state.insightsPerformanceOverviewData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsPerformanceOverviewData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(
        state.insightsPerformanceOverviewData[data.key],
        'data',
        data.columns
      );
    }
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'rows', data.rows);
    Vue.set(
      state.insightsPerformanceOverviewData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(
      state.insightsPerformanceOverviewData[data.key],
      'metrics',
      data.metrics
    );
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'page', data.page);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'load', false);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsPerformanceOverviewData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsPerformanceOverviewData[data.key], 'noData', true);
    }
  },
  INSIGHTSPERFORMANCEOVERVIEW_RESET: (state, data) => {
    Vue.set(state.insightsPerformanceOverviewData, data.key, {});
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'columns', []);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'rows', []);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'load', true);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'error', false);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'noData', false);
  },
  INSIGHTSPERFORMANCEOVERVIEW_ERROR: (state, data) => {
    Vue.set(state.insightsPerformanceOverviewData, data.key, {});
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'columns', []);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'rows', []);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'load', false);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'error', true);
    Vue.set(state.insightsPerformanceOverviewData[data.key], 'noData', false);
  },
  INSIGHTSPERFORMANCEOVERVIEW_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsPerformanceOverviewSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getPerformanceFiltersInFormat(
      state.insightsPerformanceOverviewSelectedFilter,
      state
    );
    localStorage.setItem(
      'insights_performance_overview_selected_filters',
      JSON.stringify(state.insightsPerformanceOverviewSelectedFilter)
    );
  },
  SET_PERFORMANCEOVERVIEW_FILTER_VALUES: (state, data) => {
    Vue.set(
      state.insightsPerformanceOverviewFilterValues,
      data.filterValueKey,
      data.values
    );
  }
};

function getPerformanceFiltersInFormat(oFilter, state) {
  var where = state.insightsPerformanceOverviewSelectedFilter;
  where.dimensionNameValueList = [];
  for (var i in oFilter) {
    if (i !== 'date_range') {
      for (var j in oFilter[i]) {
        if (typeof oFilter[i][j] === typeof true) {
          where.dimensionNameValueList.push({
            dimensionName: state.filtersMapping[i] || i,
            dimensionValue: j
          });
        } else {
          if (oFilter[i][j].items && oFilter[i][j].name) {
            for (var k in oFilter[i][j].items) {
              where.dimensionNameValueList.push({
                dimensionName:
                  state.filtersMapping[oFilter[i][j].name] ||
                  oFilter[i][j].name,
                dimensionValue: k
              });
            }
          }
        }
      }
    } else {
      // var transformedDateRangeName = '';
      where.dimensionNameValueList.push({
        dimensionName: 'date_range',
        dimensionValue: oFilter.date_range.name.replace(/ /g, '_').toLowerCase()
      });
    }
  }
  state.insightsPerformanceOverviewSelectedFilter = where;
}

// function getPerformanceOverviewWhereClauseDate (context) {
//   var date = context.getters.getInsightsPerformanceOverviewSelectedFilter.date_range;
//   var returnDate = {
//     to: null,
//     from: null
//   };

//   if (date) {
//     returnDate = {
//       to: date.to,
//       from: date.from
//     }
//   } else {
//     returnDate = {
//       to: context.getters.getDateRangeValues['performance_overview']['Last 90 Days'].to,
//       from: context.getters.getDateRangeValues['performance_overview']['Last 90 Days'].from
//     }
//   }

//   return returnDate;
// }

const actions = {
  setInsightsPerformanceOverviewSelectedFilter: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_SET_SELECTED_FILTER', data);
  },
  updatePerformanceOverviewSelectedFilter: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.searchFilters || [],
        'performance_overview_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  getIPOIdentifiedData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPORecommendationsData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPOTotalStrategiesData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.insightsPerformanceOverviewSelectedFilter);
    // data.body.APIConfig.where.dimensionNameValueList = data.body.APIConfig.where.dimensionNameValueList.slice(1);
    data.body.APIConfig.where = { dimensionNameValueList: [] };
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        if (response.data.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPOSKUTableData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPOCategoryTableData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPOBrandTableData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getIPORecommendationTableData: (context, data) => {
    context.commit('INSIGHTSPERFORMANCEOVERVIEW_RESET', {
      key: data.meta.key
    });

    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('INSIGHTSPERFORMANCEOVERVIEW_ERROR', {
          key: data.meta.key
        });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('INSIGHTSPERFORMANCEOVERVIEW_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  downloadPerformanceTableData: (context, data) => {
    return context.dispatch(data.action, data.config);
  },
  downloadIPOSKUData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.insightsPOFillRateSelectedFilter);
    // data.body.APIConfig.where.date = getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      var _data = transformer.mergeResultDimension(response.data);
      // transform using colMap
      return {
        data: _data,
        fileName: data.header.actions.download.fileName,
        colMap: data.header.actions.download.colMap
      };
    });
  },
  downloadIPOCategoryData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.insightsPOFillRateSelectedFilter);
    // data.body.APIConfig.where.date = getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      var _data = transformer.mergeResultDimension(response.data);
      return {
        data: _data,
        fileName: data.header.actions.download.fileName,
        colMap: data.header.actions.download.colMap
      };
    });
  },
  downloadIPOBrandData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.insightsPOFillRateSelectedFilter);
    // data.body.APIConfig.where.date = getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      var _data = transformer.mergeResultDimension(response.data);
      return {
        data: _data,
        fileName: data.header.actions.download.fileName,
        colMap: data.header.actions.download.colMap
      };
    });
  },
  downloadIPORecommendationData: (context, data) => {
    // data.body.APIConfig.where = transformer.getCompleteWhereClause(data.meta.localFilters, context.state.insightsPOFillRateSelectedFilter);
    // data.body.APIConfig.where.date = getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsPerformanceOverviewSelectedFilter
    );
    data.body.APIConfig.where.date = {};

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      var _data = transformer.mergeResultDimension(response.data);
      return {
        data: _data,
        fileName: data.header.actions.download.fileName,
        colMap: data.header.actions.download.colMap
      };
    });
  },
  getPerformanceOverviewFilters: (context, data) => {
    HttpLayer.post({
      APIData: {
        cubeName: 'alert_estimate_sku_level',
        measuresList: [],
        groupByDimensionsList: [
          'category',
          'subcategory',
          'product_group',
          'brand',
          'alert_name'
        ],
        where: {
          date: {},
          dimensionNameValueList: []
        },
        orderByList: [],
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false
      }
    }).then((response) => {
      if (!response.success) {
      } else {
        context.state.searchFilters = response.data;
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data, 'performance_overview_')
        );
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
