import { filters } from '@ciq-dev/ciq-ui';

const request = {
  bundleCubeExecutionRequest: {
    adv_metrics: {
      commonFilterEnabled: false,
      cubeName: 'ams_keyword_campaign_workbench',
      customAPIDecisionVars: {
        system: 'amazon_campaign_management'
      },
      dedupBeforeRollup: {
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        enableDedupBeforeRollup: true,
        excludeDedupAxes: []
      },
      disableShard: false,
      entityType: 'keyword_id',
      enableNewPVPFormulaForSOV: false,
      flowResidueEntity: false,
      getPointInTimeMetrics: false,
      getSharePercentage: false,
      getLatestAvailableInsteadOfRollup: false,
      groupByDimensionsList: [
        'keyword_text',
        'campaign_name',
        'match_type',
        'keyword_id',
        'profile_name',
        'portfolio_name'
      ],
      measuresList: [
        'spend',
        'impressions',
        'clicks',
        'units_1d',
        'units_7d',
        'units_14d',
        'orders_1d',
        'orders_7d',
        'orders_14d',
        'paid_sales_1d',
        'paid_sales_7d',
        'paid_sales_14d',
        'ctr',
        'conversions_1d',
        'conversions_7d',
        'conversions_14d',
        'acos_1d',
        'acos_7d',
        'acos_14d',
        'cpc',
        'halo_sales_1_day',
        'halo_sales_7_day',
        'halo_sales_14_day',
        'roi',
        'average_order_value',
        'total_sales',
        'organic_sales',
        'count',
        'keyword_volume',
        'campaign_id',
        'attributedSalesNewToBrand14d',
        'PercentageSalesNewToBrand14d',
        'attributedOrdersNewToBrand14d',
        'attributedUnitsOrderedNewToBrand14d',
        'search_frequency_rank',
        'iroas',
        'incremental_fraction',
        'incremental_sales',
        'incremental_fraction_band',
        'profile_id'
      ],
      orderByList: [],
      pvpenabled:
        filters?.config_check('feature.pages.enableRMMAmazonPVP') || false,
      tagWhereClause: [],
      timeseriesDimension: 'report_date',
      timeseriesEnabled: false,
      where: {
        date: {},
        dimensionNameValueList: [],
        pvpDate: {}
      },
      yoyenabled: false
    },
    sov_metrics: {
      commonFilterEnabled: false,
      cubeName: 'ams_keyword_campaign_workbench',
      customAPIDecisionVars: {
        system: 'amazon_campaign_management'
      },
      dedupBeforeRollup: {
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        enableDedupBeforeRollup: true,
        excludeDedupAxes: []
      },
      disableShard: false,
      entityType: 'keyword_id',
      enableNewPVPFormulaForSOV: false,
      flowResidueEntity: false,
      getPointInTimeMetrics: false,
      getSharePercentage: false,
      getLatestAvailableInsteadOfRollup: false,
      groupByDimensionsList: [
        'keyword_text',
        'campaign_name',
        'match_type',
        'keyword_id',
        'profile_name',
        'portfolio_name'
      ],
      measuresList: [
        'share_of_organic_search',
        'share_of_sponsered_search',
        'share_of_sponsered_brand_search'
      ],
      orderByList: [],
      pvpenabled: false,
      tagWhereClause: [],
      timeseriesDimension: 'report_date',
      timeseriesEnabled: false,
      where: {
        date: {},
        dimensionNameValueList: []
      },
      yoyenabled: false
    },
    adv_metadata: {
      commonFilterEnabled: false,
      cubeName: 'ams_keyword_campaign_metadata',
      tagWhereClause: [],
      timeseriesDimension: 'report_date',
      timeseriesEnabled: false,
      customAPIDecisionVars: {
        system: 'amazon_campaign_management'
      },
      dedupBeforeRollup: {
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        enableDedupBeforeRollup: false,
        excludeDedupAxes: []
      },
      groupByDimensionsList: [
        'keyword_text',
        'campaign_name',
        'match_type',
        'keyword_id',
        'profile_name',
        'portfolio_name'
      ],
      measuresList: [
        'tactic',
        'base_bid',
        'keyword_type',
        'targeting_type',
        'campaign_type',
        'adgroup_id',
        'keyword_serving_status_latest',
        'adgroup_name',
        'last_bid_change_date',
        'status',
        'creative_type'
      ],
      getSharePercentage: false,
      getLatestAvailableInsteadOfRollup: false,
      orderByList: [],
      enableNewPVPFormulaForSOV: false,
      flowResidueEntity: false,
      getPointInTimeMetrics: false,
      pvpenabled: false,
      yoyenabled: false,
      where: {
        dimensionNameValueList: [],
        date: {},
        pvpDate: {}
      },
      disableShard: false,
      entityType: 'keyword_id'
    }
  },
  cubeName: 'ams_keyword_campaign_realtime_workbench',
  customAPIDecisionVars: {
    system: 'amazon_campaign_management'
  },
  dimensionList: [],
  entityType: 'keyword_id',
  getLatestAvailableInsteadOfRollup: false,
  groupByDimensionsList: [],
  limit: 30,
  measuresList: [],
  orderByList: [
    {
      dimension: 'spend',
      direction: 'DESC'
    }
  ],
  page: 1,
  primaryDataGroup: 'adv_metrics',
  pvpenabled: false,
  tagWhereClause: [],
  timeseriesDimension: 'report_date',
  timeseriesEnabled: false,
  where: {},
  yoyenabled: false
};

export const CubeConfig = {
  request
};
