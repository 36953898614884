import utils from '@/utils/helpers/';
import Vue from 'vue';
import * as moment from 'moment-timezone';
import confirmDialog from '@/components/basic/confirmDialog.vue';
import { store } from '@/store/store';
import {
  isArray,
  mergeWith,
  cloneDeep,
  isString,
  get,
  capitalize
} from 'lodash';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';

const searchTree = (element, key) => {
  if (element.metricKey === key) {
    return element;
  } else if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], key);
    }
    if (result) {
      result.disable = true;
    }
    return result;
  }
  return null;
};
const isNumeric = (num) => {
  num = '' + num; // coerce num to be a string
  return !isNaN(num) && !isNaN(parseFloat(num));
};
const findLocalStoregeItemsFromPattern = (pattern) => {
  const result = [];
  for (const key of Object.keys(localStorage)) {
    if (key.includes(pattern)) {
      result.push({
        key,
        value: localStorage.getItem(key)
      });
    }
  }
  return result;
};

export default {
  textValidation(invalidCharacters, textArray) {
    let invalid = null;
    // example: textArray = [{value: text, maxLength: 10}];
    for (const textItem of textArray) {
      if (textItem?.value?.length > textItem?.maxLength) {
        invalid = textItem;
        invalid.reason = 'length';
        break;
      }
      if (textItem?.value?.match(invalidCharacters)) {
        invalid = textItem;
        invalid.reason = 'character';
        break;
      }
    }
    // returns null if valid, returns object with the reason key if invalid
    return invalid;
  },
  nativeExtend() {
    var that = this;
    for (var i = 1; i < arguments.length; i++) {
      for (var key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          if (
            typeof arguments[0][key] === 'object' &&
            typeof arguments[i][key] === 'object'
          ) {
            that.nativeExtend(arguments[0][key], arguments[i][key]);
          } else {
            arguments[0][key] = arguments[i][key];
          }
        }
      }
    }
    return arguments[0];
  },
  internalUserCheck(userObject) {
    var toRet = false;
    const email = userObject.email;
    var internalDomains =
      window.configs.common.users.internalDomains.split(',');
    if (email && internalDomains.indexOf(email.split('@')[1]) > -1) {
      toRet = true;
    }
    return toRet;
  },
  externalUserCheck(userObject, emailList) {
    const email = userObject.email;
    return emailList.includes(email);
  },
  setMinWidth(item) {
    var keyTypesObj = {
      string: 200,
      boolean: 100,
      recommended_action_type: 250,
      asin: 120,
      bsr_link: 200,
      link: 200
    };
    if (
      item &&
      item.keyType &&
      keyTypesObj.hasOwnProperty(item.keyType.toLowerCase())
    ) {
      return keyTypesObj[item.keyType];
    } else {
      return 120;
    }
  },
  tabsConfigCheckPass(tab, array) {
    /*
      common function which is passed through all the primary and seconday navigation tab routes,
      all the checks for productHeader etc. should be done here
    */
    const productConfig = this.$store.getters.getProductConfig;
    const configs = this.$store.getters.getFeatureEnableConfig; // athena-canvasconfig 'feature.pages' configs -- needs to change
    const isInternalUserCheckConfigEnabled =
      this.$store.getters.getInternalUserConfig;
    // check if valid productHeader config is present
    const isConfigRolePresent =
      productConfig &&
      productConfig.filter(
        (value) => tab.productRoles && tab.productRoles.includes(value)
      ).length;
    if (tab.primaryTab) {
      tab.page = 'pages';
    }
    if (
      isConfigRolePresent &&
      configs &&
      tab.page &&
      configs[tab.page] &&
      configs[tab.page][tab.config] &&
      configs[tab.page][tab.config].enable === true
    ) {
      if (
        tab.internalUserCheckRequired &&
        isInternalUserCheckConfigEnabled &&
        !utils.internalUserCheck(window.user)
      ) {
        return array;
      }
      array.tabList.push(tab.name);
      array.path.push(tab.path);
    }

    return array;
  },
  tabsConfigCheckPassV2(tab) {
    /*
      common function which is passed through all the navigation tab routes,
      all the checks for productHeader etc. should be done here
    */
    const productConfig = this.$store.getters.getProductConfig;
    const configs = this.$store.getters.getFeatureEnableConfig;
    /**
     * Removing the internalUserConfigCheck
     */
    const isInternalUserCheckConfigEnabled =
      this.$store.getters.getInternalUserConfig;
    // check if valid productHeader config is present
    let isConfigRolePresent =
      productConfig &&
      productConfig.filter(
        (value) => tab.productRoles && tab.productRoles.includes(value)
      ).length;
    // to-do: check recursively for internal users in child
    if (tab.configCheckRequired) {
      if (tab.overrideConfig) {
        const overridConfig = get(configs[tab.page], tab.overrideConfig);
        if (overridConfig !== undefined && overridConfig !== null) {
          if (overridConfig.enable === true) {
            if (
              tab.internalUserCheckRequired &&
              isInternalUserCheckConfigEnabled &&
              !utils.internalUserCheck(window.user)
            ) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        }
      }
      if (tab.strictProductRoleCheck) {
        // StrictProductRoleCheck strict equality for the product roles.
        const tabProductRoles = tab.productRoles || [];
        const configProductRoles = productConfig || [];
        isConfigRolePresent =
          tabProductRoles.length === configProductRoles.length &&
          tabProductRoles.every(function (element, index) {
            return element === configProductRoles[index];
          });
      }
      if (tab.localConfig) {
        if (window.localStorage.getItem(tab.localConfig) === 'false')
          return false;
      }

      if (
        isConfigRolePresent &&
        configs &&
        utils.internalUserCheck(window.user) &&
        tab.page &&
        configs?.[tab.page]?.[tab.config]?.internalRelease === true
      ) {
        return true;
      }

      if (
        isConfigRolePresent &&
        configs &&
        tab.page &&
        configs[tab.page] &&
        configs[tab.page][tab.config] &&
        configs[tab.page][tab.config].enable === true
      ) {
        if (
          tab.internalUserCheckRequired &&
          isInternalUserCheckConfigEnabled &&
          !utils.internalUserCheck(window.user)
        ) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },
  getBrowserName() {
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const userAgentData = navigator.userAgentData;
    const isChrome =
      userAgentData?.brands?.some((el) => el.brand === 'Google Chrome') ??
      false;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge =
      winNav.userAgent.indexOf('Edge') > -1 ||
      winNav.userAgent.indexOf('Edg') > -1;

    if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      isChrome &&
      isOpera === false &&
      isIEedge === false
    ) {
      return 'chrome';
    } else if (isIEedge) {
      return 'IEedge';
    } else {
      return 'unknown';
    }
  },
  jsonToCsvString(dataArray, columnsMap) {
    const headerKeys = columnsMap.map((column) => column.field);
    const headerTitle = columnsMap.map((column) => {
      if (column.title.includes('’')) {
        column.title = column.title.replaceAll('’', "'");
      }
      return `"${column.title}"`;
    });
    let csv = dataArray.map((row) =>
      headerKeys
        .map((fieldName) => {
          if (isString(row[fieldName])) {
            // for cases where there is already a string quote (") , the processing logic might break.
            // So we replace all (") with (')
            row[fieldName] = row[fieldName].replaceAll('"', "'");
          }
          if (`${row[fieldName]}`.indexOf(',') > 0) {
            return `"${row[fieldName]}"`;
          } else if (row[fieldName] === null || row[fieldName] === undefined) {
            return 'NA';
          } else {
            return row[fieldName];
          }
        })
        .join(',')
    );
    csv.unshift(headerTitle.join(','));
    csv = csv.join('\r\n');
    return csv;
  },
  performDownload(dataArray, columnsMap, fileName) {
    const csvString = this.jsonToCsvString(dataArray, columnsMap);
    const exportedFilenmae = (fileName && fileName + '.csv') || 'export.csv';
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  getClientTimezone() {
    const clientTimezone =
      Vue.options.filters.config_check('feature.timezone') || 'PST';
    return clientTimezone;
  },
  /*
   *  For the given date range whhat should be the rollup
   *  @params: dateObj: {
   *  from: string,
   *  to: string
   * }
   *
   */
  getRollUps(dateObj) {
    const date = dateObj;
    const staticRollUps = [
      { name: 'Days', value: 'DAY', diff: 'day' },
      { name: 'Weeks', value: 'WEEK', diff: 'week' },
      { name: 'Months', value: 'MONTH', diff: 'month' }
    ];
    let rollUps = [...staticRollUps];
    if (
      date.from &&
      date.to &&
      Math.abs(
        moment(date.from, 'YYYY-MM-DD').diff(
          moment(date.to, 'YYYY-MM-DD'),
          'days'
        )
      ) < 13
    ) {
      // remove the rollups by week
      rollUps = rollUps.filter(
        (item) => item.value !== 'MONTH' && item.value !== 'WEEK'
      );
    } else if (
      date.from &&
      date.to &&
      Math.abs(
        moment(date.from, 'YYYY-MM-DD').diff(
          moment(date.to, 'YYYY-MM-DD'),
          'days'
        )
      ) < 59
    ) {
      // remove the rollups by month
      rollUps = rollUps.filter((item) => item.value !== 'MONTH');
    }
    return [...rollUps];
  },
  histogramModification(element, { value }) {
    const data = value;
    if (!data) {
      return;
    }
    if (!data.config || !data.config?.enableHistogram) {
      return;
    }
    if (!element.style) {
      element.style = {};
    }
    element.style.position = 'relative';
    const width = data.data[data?.config?.historyKey];
    const inverted = data?.config?.inverted;
    const colorFlag = width >= 0;
    const _colorFlag = inverted ? !colorFlag : colorFlag;
    let colorClass = _colorFlag
      ? 'u-bg-color-green-bg-fade'
      : 'u-bg-color-red-bg-fade';
    if (data.config.customColorClass) {
      colorClass = data.config.customColorClass();
    }
    const htmlStr = `
      <div class="u-width-100 u-height-100 u-position-absolute">
        <div style="width: ${Math.abs(
          width
        )}%;" class="u-height-100 ${colorClass}">
        </div>
      </div>
    `;
    element.innerHTML = htmlStr + element.innerHTML;
  },
  castToBool(boolString) {
    if (String(boolString).toLowerCase().trim() === 'true') {
      return true;
    } else {
      return false;
    }
  },

  disableTreeNodes(tree, disableKeys, disableKey = 'metricKey') {
    const searchTreeAndDisable = (element, disableMetricKey) => {
      if (element[disableKey] === disableMetricKey) {
        element.disable = true;
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = searchTreeAndDisable(element.children[i], disableMetricKey);
        }
        if (result) {
          result.disable = true;
        }
        return result;
      }
      return null;
    };
    for (let i = 0; i < disableKeys.length; i++) {
      searchTreeAndDisable(tree, disableKeys[i].key);
    }
    return tree;
  },
  searchTree,
  /**
   * Sort for the sematic versioning
   * @param {*} a
   * @param {*} b
   */
  compareForVersion(a, b) {
    var aSplit = a.split('.');
    var bSplit = b.split('.');

    var length = Math.min(aSplit.length, bSplit.length);
    for (var i = 0; i < length; ++i) {
      if (parseInt(aSplit[i]) < parseInt(bSplit[i])) {
        return 1;
      } else if (parseInt(aSplit[i]) > parseInt(bSplit[i])) {
        return -1;
      }
    }

    if (aSplit.length < bSplit.length) {
      return 1;
    } else if (aSplit.length > bSplit.length) {
      return -1;
    }

    return 0;
  },
  dynamicallyMountComponent(parentRefInstance, component, props, events) {
    const ComponentClass = Vue.extend(component);
    var instance = new ComponentClass({
      propsData: props
    });
    Object.keys(events).forEach((eventKey) => {
      instance.$on(eventKey, events[eventKey]);
    });
    instance.$mount(); // pass nothing
    parentRefInstance.appendChild(instance.$el);
    return instance;
  },
  showConfirmationDialog(
    parentRefInstance,
    title,
    message,
    onConfirmCallback,
    onCancelCallback,
    overRideProps,
    alertType
  ) {
    let props = { title, message };
    const eventMap = {
      confirm: (event) => {
        onConfirmCallback(event);
      },
      cancel: (event) => {
        onCancelCallback(event);
      }
    };
    if (alertType === 'error') {
      overRideProps[alertType] = 'error';
    } else if (alertType === 'warning') {
      overRideProps[alertType] = 'warning';
    }
    props = { ...props, ...overRideProps };
    return this.dynamicallyMountComponent(
      parentRefInstance,
      confirmDialog,
      props,
      eventMap
    );
  },
  getTransitionEndEventName() {
    var transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd'
    };
    const bodyStyle = document.body.style;
    for (const transition in transitions) {
      if (bodyStyle[transition] !== undefined) {
        return transitions[transition];
      }
    }
  },
  clearCampaignRelatedLS() {
    const filterLocalStorageKeysToRemove = [
      'campaigns-filters', // Campaign Management Amazon page filter keys
      'campaigns-filters-order',
      'keywords-filters',
      'keywords-filters-order',
      'searchTerms-filters',
      'searchTerms-filters-order',
      'cm-skus-filters',
      'cm-skus-filters-order',
      'campaign-filters', // Campaign Management Walmart page filter keys - TODO - make all the keys for walmart reatiler specific
      'campaign-filters-order',
      'keyword-filters',
      'keyword-filters-order',
      'walmart-search-terms-filters',
      'walmart-search-terms-filters-order',
      'skus-filters', // Campaign Management - Ad Skus page filter LS keys
      'skus-filters-order',
      'sov-filters', // MI page filter keys
      'sov-filters-order',
      'sov-widget-5',
      'sov-widget-5-order'
    ];
    filterLocalStorageKeysToRemove.forEach((key) =>
      localStorage.removeItem(key)
    );
  },
  checkAndClearLSFilterForEntity(entityName) {
    const systemEntityUpdateTime =
      store.getters.getEntityLastUpdatedTime(entityName);
    const releaseFromLS = localStorage.getItem('release');
    const entityLSkey = `filterEntityClear-${entityName}`;
    const localEntityUpdateTime = localStorage.getItem(entityLSkey);
    const filterEntityEntries =
      findLocalStoregeItemsFromPattern('filterEntityClear-');
    if (
      systemEntityUpdateTime &&
      (!localEntityUpdateTime ||
        (localEntityUpdateTime &&
          localEntityUpdateTime !== systemEntityUpdateTime))
    ) {
      console.log(
        JSON.stringify(localStorage),
        'Logging before localstorage clear' + entityName
      );
      Vue.prototype.$logger.logEvent({
        eventType: 'clear_localstorage_filters',
        entityType: entityName,
        systemEntityUpdateTime,
        localEntityUpdateTime
      });
      store.dispatch('showGlobalSnack', {
        text: 'Your previous filters are removed because your internal taxonomy / custom list is updated.'
      });
      // Clear only campaign related LC on campaign list change and whole localStorage incase of internal catalog change.
      if (entityName === 'campaign') {
        utils.clearCampaignRelatedLS();
      } else {
        localStorage.clear();
      }
      if (releaseFromLS) {
        localStorage.setItem('release', releaseFromLS);
      }
      if (filterEntityEntries.length) {
        for (const entry of filterEntityEntries) {
          localStorage.setItem(entry.key, entry.value);
        }
      }
      localStorage.setItem(entityLSkey, systemEntityUpdateTime);
      console.log(
        JSON.stringify(localStorage),
        'Logging after localstorage clear' + entityName
      );
    }
  },
  isNumeric: isNumeric,
  isJsonString(str) {
    if (!str) {
      return false;
    }
    if (isNumeric(str)) {
      return false;
    }
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  deepMerge(basePrio2Object, basePrio1Object) {
    const prio1Object = cloneDeep(basePrio1Object);
    const prio2Object = cloneDeep(basePrio2Object);
    const customizer = (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    const mergedData = mergeWith(prio2Object, prio1Object, customizer);
    return mergedData;
  },
  expandOnHover(params, element) {
    var clamp = Math.ceil((params.eGridCell?.clientHeight - 32) / 16);
    clamp = clamp > 0 ? clamp : 1;
    params.eGridCell?.classList.add('u-clamp-' + clamp);
    // remove the click to expand feature if no ellipsis is present
    let toExpand = false;
    const el = element?.querySelector('.cell--content');
    if (
      el?.clientHeight < el?.scrollHeight - 1 ||
      el?.clientWidth < el?.scrollWidth
    ) {
      toExpand = true;
    } else if (el?.children && el?.children?.length > 0) {
      const children = el.querySelectorAll('*');
      for (const child of children) {
        if (
          child.clientWidth < el.scrollWidth ||
          child.clientWidth < child.scrollWidth
        ) {
          toExpand = true;
          break;
        }
      }
    }
    if (!toExpand) {
      el?._tippy?.destroy();
      el?.removeAttribute('title');
      el?.removeAttribute('data-original-title');
      el?.classList.add('u-cursor-text');
    } else {
      el?.classList.add('u-cursor-pointer');
    }
    return toExpand;
  },
  copyToClipboardText(text, event) {
    if (event) {
      event.stopImmediatePropagation();
    }
    var dummyElement = document.createElement('textarea');
    dummyElement.value = text;
    dummyElement.setAttribute('readonly', '');
    dummyElement.style.position = 'absolute';
    dummyElement.style.left = '-9999px';
    document.body.appendChild(dummyElement);
    dummyElement.select();
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
  },
  parseAndReturnArray(str) {
    return str ? JSON.parse(str) : [];
  },
  capitalizeString(str) {
    return str
      ?.split?.('_')
      ?.map?.((el) => capitalize(el))
      ?.join?.(' ');
  },
  validateUserBasedOnConfig(config) {
    const isDemoClient = Vue.options.filters.config_check(
      'feature.demo.product_demo.enable'
    );
    if (isDemoClient) {
      return true;
    } else {
      const userEmailConfig = Vue.options.filters.config_check(config) || '';
      const userEmailList = userEmailConfig.split(',');
      return !!userEmailList.includes(window?.user?.email);
    }
  },
  shouldRetainRetailer(retailerKey) {
    const retailerListToHide =
      Vue.options.filters.config_check(
        'feature.retailers_for_internal_users'
      ) || [];
    let retainRetailer = false;
    if (retailerListToHide?.includes(retailerKey)) {
      if (utils?.internalUserCheck(window.user)) {
        retainRetailer = true;
      }
    } else {
      retainRetailer = true;
    }
    return retainRetailer;
  },
  filterConfigs(retailer) {
    return {
      ingestionDependentEntities: [EntitiesForPostProcessingStatus.campaign],
      filterV2:
        Vue.options.filters.config_check(
          `feature.pages.${retailer}_campaign_taxonomy.enable`
        ) || false
    };
  },
  async copyFromClipboard() {
    const result = await navigator.permissions.query({
      name: 'clipboard-read'
    });
    if (result.state === 'granted' || result.state === 'prompt') {
      /* write to the clipboard now */
      const readText = await navigator.clipboard.readText();
      return readText;
    } else {
      throw Error(
        'Please allow clipboard read access necessary for the copy feature'
      );
    }
  }
};
