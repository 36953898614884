<template>
  <div>
    <!--For Non auto targeting-->
    <div
      v-if="!boolAutoTargeting"
      class="u-spacing-ph-xxl u-spacing-pt-xxxl"
    >
      <createCampaignHeader
        class="u-spacing-pt-m"
        :step="step"
      />
      <div class="u-spacing-pt-xl fit-content">
        <div class="u-display-flex u-flex-align-items-center">
          <div class="u-flex-direction-column u-spacing-pr-xxxl">
            <div class="heading--input u-font-size-5 u-font-weight-600">
              <span v-if="campaignType === 'sp'"> Daily Budget: </span>
              <span v-if="campaignType === 'sb'"> Budget </span>
            </div>
            <div class="u-spacing-pt-s u-display-flex">
              <campaignSelect
                v-if="campaignType === 'sb'"
                class="u-spacing-mr-m"
                entitykey="title"
                :default-option="selectValue"
                :options="[
                  { title: 'Daily', key: 'daily' },
                  { title: 'Lifetime', key: 'lifetime' }
                ]"
                @selectedItem="setBudgetType"
              />
              <rbNumberInput
                :options="inputOptions"
                data-cy="enterBudget"
                @handleInput="setBudget"
                @handleValue="setBudget"
              />
            </div>
          </div>
          <div class="u-spacing-ph-s u-spacing-pt-m">
            <div
              v-if="campaignType === 'sp'"
              class="u-border-left u-spacing-pl-xxxl u-line-height-1-3 u-spacing-pv-m u-font-size-5 u-color-grey-lighter u-border-color-grey-xxx-light u-border-width-s"
              data-cy="dailyBudgetSuggestion"
            >
              Most campaigns with a budget over {{ inputOptions.prefix }}50.00
              run throughout <br />
              the day.
            </div>
          </div>
        </div>
      </div>
      <createCampaignFooter
        :disable-right-btn="!(inputOptions.value > 0)"
        class="u-margin-top-40"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </div>
    <!--For Auto targeting-->
    <div v-if="boolAutoTargeting">
      <div class="fit-content u-spacing-pl-l">
        <div class="u-display-flex u-flex-align-items-center">
          <div class="u-flex-direction-column u-spacing-pr-xxxl u-spacing-mt-l">
            <div class="heading--input u-font-size-5 u-font-weight-600">
              <span data-cy="defaultBidText">Default Bid:</span>
            </div>
            <div class="u-spacing-pt-s u-display-flex">
              <rbNumberInput
                :options="inputOptions"
                @handleInput="setBudget"
                @handleValue="setBudget"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import rbNumberInput from '@/components/widgets/rbNumberInput';
import Vue from 'vue';
import campaignSelect from '@/components/ams/campaign_creation/steps/edit/campaignAdFormat/campaignSelect.vue';

import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    rbNumberInput,
    campaignSelect
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    selectValue: { title: 'Daily', key: 'daily' },
    suggestedCampaignIndex: 0,
    inputOptions: {
      prefix: Vue.prototype.$currency,
      value: 1
    }
  }),
  computed: {
    campaignType() {
      const campaignTypeMap = {
        sponsoredBrands: 'sb',
        sponsoredProducts: 'sp',
        createOwnCampaignSP: 'sp',
        createOwnCampaignSB: 'sb'
      };
      const { campaignType } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      return campaignTypeMap[campaignType];
    },
    boolAutoTargeting() {
      let value = false;
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (
        this.step.key === 'automaticTargeting' &&
        this.campaignType === 'sp' &&
        suggestedCampaign.spTargetingType === 'auto'
      ) {
        value = true;
      }
      return value;
    },
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    if (this.boolAutoTargeting) {
      this.setBudget(
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
          ?.auto_default_Bid || 100
      );
    } else {
      this.setBudget(
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex]
          ?.budget || 100
      );
    }
    this.setBudgetType();
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    stepDesc() {
      const campaignTypeValue =
        this.campaignType === 'sp' ? 'Daily' : this.selectValue?.title;
      this.step.description = `${campaignTypeValue} budget: ${this.inputOptions.prefix}${this.inputOptions.value}`;
    },
    setBudget(value) {
      this.inputOptions.value = value;
      const allStepsData = this.allStepsData;
      if (this.boolAutoTargeting) {
        // bid for sp-autotargeting default_bid
        allStepsData.suggestedCampaigns[
          this.suggestedCampaignIndex
        ].auto_default_Bid = this.inputOptions.value;
      } else {
        allStepsData.suggestedCampaigns[this.suggestedCampaignIndex].budget =
          this.inputOptions.value;
      }
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
      this.stepDesc();
    },
    updateSelectedStore(data) {
      const allStepsData = this.allStepsData;
      allStepsData.suggestedCampaigns[this.suggestedCampaignIndex] = {
        ...allStepsData.suggestedCampaigns[this.suggestedCampaignIndex],
        ...data
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    setBudgetType(value) {
      const { budgetType } =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      this.selectValue = value || budgetType || this.selectValue;
      this.updateSelectedStore({ budgetType: this.selectValue });
      this.stepDesc();
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style></style>
