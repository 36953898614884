export default {
  init(data) {
    var d = document;
    var script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = function () {
      window.wootric_survey_immediately = true;
      var url = location.href.split('/');
      if (url && url[2] && url[2].indexOf('boomerangvendor')) {
        var domain = url[2];
      }
      window.wootricSettings = {
        email: data.user.email,
        account_token: 'NPS-' + data.user.nps,
        product_name: 'CommerceIQ',
        properties: {
          domain: domain || 'dev',
          user: data.user.email
        }
      };
      window.wootric('run');
    };
    script.src = 'https://cdn.wootric.com/wootric-sdk.js';
    d.getElementsByTagName('head')[0].appendChild(script);
  }
};
