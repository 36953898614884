<template>
  <div
    v-if="metadataLoad || isKeywordsLoading"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metadataError || isKeywordsError"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <cardContainer
    v-else
    :title="metadata.label"
    :last-updated-date="lastUpdatedDate"
    :horizontal-padding="'l'"
    :body-bottom-padding="'l'"
  >
    <section
      slot="pre-filters"
      class="u-display-flex"
    >
      <rb-select-v2
        class="u-spacing-mr-m"
        :on-close="handleKeywordsSelect"
        :options="keywordsFilterConfig.options"
        :send-details="true"
      >
        <multiSelectChip
          slot="trigger"
          ref="keywords-select-tigger"
          :pre-text="keywordsFilterConfig.preText"
          :selections="computedKeywordsFilterSelection"
          :chip-has-cross="true"
        />
      </rb-select-v2>
    </section>
    <section slot="body">
      <chart-with-legends
        :hide-zero="false"
        :chart-data="formattedChartData"
        :chart-config="chartConfig"
        :metrics-list="metricsList"
        :metrics-shown="selectedValues.metricsShown"
        :default-select-metric="selectedValues.selectedMetric"
        :metric-config="selectedValues.metricDisplayConfig"
        :metric-data="selectedValues.metricData"
        :enable-add-event="false"
        class="u-spacing-mt-l"
        :selected-metric-limit="selectedMetricLimit"
        :disable-add-new-metric="disableAddNewMetric"
        :is-chart-loading="isChartLoading"
        :emit-on-metric-default-change="true"
        :plot-on-y-axis-only="true"
        :show-powered-by-c-i-q-text="true"
        :custom-chart-class="[]"
        :minimum-metric="6"
        :enable-watefall="true"
        :metric-font-size="'u-font-size-4'"
        @selectedMetricList="metricChanged"
      >
      </chart-with-legends>
    </section>
  </cardContainer>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import cardContainer from '../organisms/card-container';
import loader from '@/components/basic/loader';
import moment from 'moment';
import multiSelectChip from '../atoms/multi-select-chip';
import { cloneDeep } from 'lodash';
import { getTimeRange } from '@/utils/helpers/date.js';
import {
  appendAnchorBrandCondition,
  isSovDataGroup,
  appendAnchorFilter,
  getAnchorFilterPayload
} from './common';
import {
  transformConfig,
  getTimeSeriesWithHoles,
  appendPartialArrays,
  requestTimeReplacement,
  requestDateReplacement
} from '../templates/dashboard-service-utils';

import Vue from 'vue';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import CONSTANTS from '@/utils/constants';

export default {
  components: {
    RbSelectV2,
    cardContainer,
    chartWithLegends,
    loader,
    multiSelectChip
  },
  props: {
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      default: null
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    unsavedState: {
      type: Object,
      default: () => ({})
    },
    sovDataGroups: {
      type: Object,
      default: () => ({})
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lastUpdatedDate: null,
      allKeywordsText: 'All Keywords',
      keywordListOptions: [],
      isApplyClicked: true,
      isKeywordsLoading: true,
      isKeywordsError: false,
      allKeyWordsSelected: true,
      metricData: {},
      chartMetricGroups: {},
      metricsData: {},
      metadata: {},
      formattedChartData: { data: [] },
      fetchedDataGroup: {},
      chartConfig: {
        chartOptions: {
          legend: false,
          xDataKey: 'start_hour',
          tooltip_format: {},
          axes: {},
          grid: 'y',
          hasEvents: false,
          type: 'bar',
          types: {},
          xAxisType: 'category',
          stack: [],
          chartGetter: '',
          show_axis_colors: true,
          events: [],
          dynamicRange: false,
          size: { height: 380 },
          culling: {
            max: 9,
            multiline: true
          }
        },
        bar: { width: { ratio: 0.35 } },
        stack: [],
        chartGetter: '',
        xAxisType: 'category'
      },
      selectedMetricLimit: 2,
      disableAddNewMetric: true,
      selectedValues: {
        metricDisplayConfig: {},
        metricsShown: [],
        selectedMetric: [],
        metricData: {}
      },
      metricsList: [],
      isChartLoading: false,
      keywordsFilterConfig: {
        preText: 'Keywords:',
        selected: [],
        options: []
      },
      devMetadata: {
        metricsSegregated: {
          otherMetrics: ['sov_sri']
        },
        sriMetric: 'sov_sri'
      },
      isDemoClient: Vue.options.filters.config_check(
        'feature.demo.product_demo.enable'
      )
    };
  },
  computed: {
    computedKeywordsFilterSelection() {
      return this.getSeletedKeywords();
    },
    getUnsavedWidgetMetdata() {
      return this.getUnsavedState()[this.widgetName];
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metadataError() {
      return this?.dataService?.metadataError;
    }
  },
  created() {
    this.setInitialUnsavedState();
    this.init();
  },
  methods: {
    appendAnchorBrand(requestCopy, dataGroup) {
      const { anchoredBrand } = this.getUnsavedSettings();
      if (
        dataGroup === 'sov_search_term_level_data' &&
        appendAnchorBrandCondition(anchoredBrand)
      ) {
        requestCopy.operations.anchoredBrand = anchoredBrand.title;
      }
    },
    getSeletedKeywords() {
      const { selectedKeywords } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'][
          this.widgetName
        ];
      const selectedKeywordsMap = {};
      selectedKeywords.forEach((item) => {
        selectedKeywordsMap[item] = 1;
      });
      const formattedSelections = this.keywordsFilterConfig.options.filter(
        (item) => {
          if (selectedKeywordsMap[item.title]) {
            return item;
          }
        }
      );
      return formattedSelections.length
        ? formattedSelections
        : [this.keywordsFilterConfig.options[0]];
    },
    getUnsavedSettings() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
        .settings;
    },
    setUnsavedState(key, value) {
      let unsavedStateCopy = JSON.parse(
        JSON.stringify(this.getWidgetUnsavedState())
      );
      unsavedStateCopy = {
        ...unsavedStateCopy,
        [key]: value
      };
      this.pushToStateStore(unsavedStateCopy);
    },
    setInitialUnsavedState() {
      const localUnsavedState = {};
      const localDefaultState = this.setInitialSavedState();
      const unsavedState = this.getUnsavedWidgetMetdata || {};
      const defaultStateKeys = Object.keys(localDefaultState);
      defaultStateKeys.forEach((key) => {
        localUnsavedState[key] = unsavedState[key] || localDefaultState[key];
      });
      this.pushToStateStore(localUnsavedState);
    },
    setInitialSavedState() {
      const localDefaultState = {};
      const initialState = this.initialState?.[this.widgetName] || {};
      const defaultState = this.getDefaultState();
      const defaultStateKeys = Object.keys(defaultState);
      defaultStateKeys.forEach((key) => {
        localDefaultState[key] = initialState[key] || defaultState[key];
      });
      this.pushToStateStore(localDefaultState, 'pushToInitialState');
      return localDefaultState;
    },
    getDefaultState() {
      return {
        selectedChartMetrics: [
          'all_page_1_count',
          'organic_page_1_count',
          'ams_sov_spend'
        ],
        metricsShown: [
          'all_page_1_count',
          'organic_page_1_count',
          'ams_sov_spend'
        ],
        selectedKeywords: [this.allKeywordsText],
        defaultOrderBy: [
          { dimension: 'amazon_search_frequency_rank', direction: 'ASC' }
        ]
      };
    },
    getUnsavedState() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'];
    },
    getWidgetUnsavedState() {
      return this.getUnsavedState()[this.widgetName];
    },
    handleKeywordsSelect(context, selections) {
      if (selections?.[0]) {
        this.isApplyClicked = false;
        this.allKeyWordsSelected =
          selections.length === this.keywordsFilterConfig.options.length;
        this.setUnsavedState('selectedKeywords', [selections[0].title]);
        this.keywordListOptions = cloneDeep(this.keywordsFilterConfig.options);
        this.getChartData();
      }
    },
    metricChanged(event) {
      const { selectedMetric, metricDisplayList } = event;
      if (selectedMetric.length > 0) {
        this.getSelectedMetricsData(selectedMetric, this.chartMetricGroups);
        const metricList = selectedMetric.map((item) => item.alias);
        const metricsShown = metricDisplayList.map((item) => item.alias);
        this.setUnsavedState('selectedChartMetrics', metricList);
        this.setUnsavedState('metricsShown', metricsShown);
      }
    },
    setAllLoadStates() {
      this.metricData = { tag1: 'loading' };
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.lastUpdatedDate = moment(this.metadata.calendar.max_date).format(
          'LL'
        );
        this.getData();
      } catch (e) {
        console.error('e', e);
      }
    },
    async getData() {
      try {
        await this.getKeywordsData();
        this.getChartData();
      } catch (e) {
        console.error('e', e);
      }
    },
    createDataGroups(metricsMetadata) {
      const metricsApiGrouping = {};
      const metricsKeys = Object.keys(metricsMetadata);
      metricsKeys.forEach((metricKey) => {
        const group = metricsMetadata[metricKey].dataGroup;
        if (!metricsApiGrouping[group]) {
          metricsApiGrouping[group] = {};
        }
        metricsApiGrouping[group][metricKey] = metricsMetadata[metricKey];
      });
      return metricsApiGrouping;
    },
    getSelectedKeywordDimensionNameValue(dimensionNameValueList) {
      const selectedKeywords = this.getSeletedKeywords();
      if (selectedKeywords[0].title === this.allKeywordsText) {
        return dimensionNameValueList;
      }
      const dimensionName = 'search_term';
      const newDimensionNameValueList = dimensionNameValueList.filter(
        (item) => item.dimensionName !== dimensionName
      );
      const dimensionValue = selectedKeywords[0].title;
      newDimensionNameValueList.push({
        dimensionName,
        dimensionValue
      });
      return newDimensionNameValueList;
    },
    extractKeywordsListData(entityData) {
      const keywordsDataFormatted = [];
      entityData.forEach((keywordData) => {
        keywordsDataFormatted.push({
          title: keywordData.entityValue,
          entityType: keywordData.entityType,
          icon: CONSTANTS.crawlTypes.multiCrawl.icon,
          iconColor: CONSTANTS.crawlTypes.multiCrawl.iconColor
        });
      });
      return keywordsDataFormatted;
    },
    setLocalFilters(requestWhere, localWhereClause) {
      requestWhere.dimensionNameValueList =
        localWhereClause.dimensionNameValueList;
      requestDateReplacement(requestWhere, localWhereClause);
      if (requestWhere.date) {
        requestWhere.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      }
      requestTimeReplacement(requestWhere, localWhereClause);
    },
    async getKeywordsData() {
      try {
        const { anchoredBrand } = this.getUnsavedSettings();
        this.isKeywordsLoading = true;
        this.isKeywordsError = false;
        const keywordsApi = cloneDeep(
          this.metadata.customMetadata.commonApis.keywordsData
        );
        const localWhere = this.getLocalWhereClause();
        if (appendAnchorBrandCondition(anchoredBrand)) {
          localWhere.dimensionNameValueList.push(
            getAnchorFilterPayload(anchoredBrand.title)
          );
        }
        this.setLocalFilters(keywordsApi.where, localWhere);
        const { entityData } = await this.dataService.getData(keywordsApi);
        this.isKeywordsLoading = false;
        this.isKeywordsError = false;
        const keywordsDataFormatted = [
          {
            title: this.allKeywordsText,
            selected: true
          },
          ...this.extractKeywordsListData(entityData)
        ];
        this.$set(this.keywordsFilterConfig, 'options', keywordsDataFormatted);
        this.keywordListOptions = cloneDeep(this.keywordsFilterConfig.options);
      } catch (e) {
        this.isKeywordsLoading = false;
        this.isKeywordsError = true;
      }
    },
    getChartData() {
      this.fetchedDataGroup = {};
      this.setAllLoadStates();
      const where = this.getLocalWhereClause();
      const xAxis = this.getXAxis(where.time.from, where.time.to);
      Vue.set(
        this.chartConfig,
        'xAxisCategories',
        xAxis.slice(1).map((v) => moment(v, 'HH').format('LT'))
      );
      this.chartMetricGroups = this.createDataGroups(this.metadata.metrics);
      this.getMetricsList(this.metadata.metrics);
      this.getSelectedMetricsData(
        this.selectedValues.metricsShown,
        this.chartMetricGroups
      );
    },
    createWaterfallStructure(metricsList, treeStructure, metricsWithWaterFall) {
      metricsList.push(treeStructure);
      const filteredMetricsList = metricsList.filter((item) => {
        if (metricsWithWaterFall[item?.alias]) {
          item.waterfall = true;
          return item;
        }
      });
      return filteredMetricsList;
    },
    setMetrics(metricsArray, metricsMetadata) {
      const metricsList = [];
      metricsArray.forEach((metric) => {
        if (metricsMetadata[metric]) {
          metricsList.push(metricsMetadata[metric]);
        }
      });
      return metricsList;
    },
    getMetricsList(metricsMetadata) {
      const unsavedState = this.getWidgetUnsavedState();
      this.metricsList = transformConfig(this, metricsMetadata, 'metric');
      const { metricsWithWaterfall = {}, waterfall = {} } =
        this.metadata.metadata.groupInfo || {};
      const { share_of_voice = {} } = waterfall;
      const selectedMetricsList = this.setMetrics(
        unsavedState.selectedChartMetrics,
        metricsMetadata
      );
      let metricsShown = null;
      metricsShown = this.setMetrics(
        unsavedState.metricsShown,
        metricsMetadata
      );
      const disabledList = selectedMetricsList.map((item) => ({
        key: item.alias,
        name: item.label
      }));
      this.createWaterfallStructure(
        this.metricsList,
        share_of_voice,
        metricsWithWaterfall,
        disabledList
      );
      this.selectedValues.metricsShown = [...metricsShown];
      this.selectedValues.selectedMetric = [...selectedMetricsList];
      const metricList = selectedMetricsList.map((item) => item.alias);
      this.setUnsavedState('selectedChartMetrics', metricList);
      this.setUnsavedState('metricsShown', metricsShown);
    },
    getXAxis(fromTime, toTime) {
      const timeRange = getTimeRange(fromTime, toTime).map((v) =>
        v.slice(0, 2)
      );
      return ['start_hour', ...timeRange];
    },
    getSelectedMetricsData(selectedMetrics, chartMetricGroups) {
      selectedMetrics.forEach((item) => {
        if (
          this.devMetadata.metricsSegregated.otherMetrics.includes(item.alias)
        ) {
          return;
        }
        if (!this.fetchedDataGroup[item.dataGroup]) {
          this.isChartLoading = true;
          this.fetchedDataGroup[item.dataGroup] = {};
          let api = {};
          const metricsList = [];
          const metricKeys = Object.keys(chartMetricGroups[item.dataGroup]);
          metricKeys.forEach((metric) => {
            const searchedMetric = chartMetricGroups[item.dataGroup][metric];
            api = searchedMetric.api;
            metricsList.push(searchedMetric.alias);
            this.$set(this.selectedValues.metricData, searchedMetric.key, {
              tag1: 'loading'
            });
          });
          const { request } = api;
          const requestCopy = cloneDeep(request);
          requestCopy.entityType = requestCopy.entityType.replace(
            ':entityType',
            '#ALLOVER_AGGREGATE'
          );
          requestCopy.metricsList = metricsList;
          const where = this.getLocalWhereClause(item.dataGroup);
          this.setLocalFilters(requestCopy.where, where);
          requestCopy.where.dimensionNameValueList =
            this.getSelectedKeywordDimensionNameValue(
              where.dimensionNameValueList
            );
          this.appendAnchorBrand(requestCopy, item.dataGroup);
          this.dataService
            .getData(requestCopy)
            .then((response) => {
              this.isChartLoading = false;
              this.fetchedDataGroup[item.dataGroup] = response;
              const { entityData } = response;
              const searchMap = {};
              for (const entityUnit of entityData) {
                for (const data of entityUnit.data) {
                  searchMap[data.alias] = data;
                }
              }
              this.formattedChartData = { data: [] };
              const graphData = [];
              for (const metric of metricsList) {
                const entityArray = searchMap[metric];
                const metricMetdata = chartMetricGroups[item.dataGroup][metric];
                const xAxis = this.getXAxis(where.time.from, where.time.to);
                const trendlineData = getTimeSeriesWithHoles(
                  xAxis,
                  entityArray?.name || '',
                  entityArray?.TIMESERIES || [],
                  this.widgetName
                );
                graphData.push([metricMetdata.key, ...trendlineData]);
                const metricDataValue = this.getValueAndPvp(
                  entityArray,
                  metricMetdata.keyName,
                  metricMetdata
                );
                this.$set(
                  this.selectedValues.metricData,
                  metricMetdata.key,
                  metricDataValue
                );
              }
              this.formattedChartData = {
                data: [...this.formattedChartData.data, ...graphData]
              };
            })
            .catch((e) => {
              console.error('e', e);
              metricKeys.forEach((metric) => {
                const searchedMetric =
                  chartMetricGroups[item.dataGroup][metric];
                this.$set(this.selectedValues.metricData, searchedMetric.key, {
                  tag1: 'error'
                });
              });
              this.isChartLoading = false;
            });
        }
      });
    },
    getValueAndPvp(entityUnit, metricKey, metricsMetadata) {
      const pvpPrefix =
        metricsMetadata.metadata.unit === 'PERCENTAGE' ? 'PVP_DIFF_' : 'PVP_';
      return {
        tag1: entityUnit?.RESULT?.[metricKey] || 0,
        tag2: entityUnit?.PVP?.[pvpPrefix + metricKey]
      };
    },
    getLocalWhereClause(dataGroup) {
      const unsavedSettings = this.getUnsavedSettings();
      const { anchoredBrand } = this.getUnsavedSettings();
      const globalWhereClause = cloneDeep(this.getGlobalWhereClause);
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      where.time = {};
      where.pvpTime = {};
      where.time.from = globalWhereClause.time_range.startTime;
      where.time.to = globalWhereClause.time_range.endTime;
      where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
      where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      if (isSovDataGroup(dataGroup, this.sovDataGroups)) {
        appendPartialArrays(
          where.dimensionNameValueList,
          unsavedSettings.selectedType,
          this.types
        );
      }
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      appendAnchorFilter(where, anchoredBrand);
      where.dimensionNameValueList.push(
        ...globalWhereClause.dimensionNameValueList
      );
      return where;
    },
    pushToStateStore(data, emit = 'pushToUnsavedState') {
      this.$emit(emit, { [this.widgetName]: data });
    }
  }
};
</script>

<style lang="css" scoped>
.intermediate-dimensions {
  height: 506px;
}
.pagination-overlay {
  width: 100%;
  height: 650px;
}
</style>
