import sp from '@/pages/entity_details/configs/amazon/sp.js';
import dsp from '@/pages/entity_details/configs/amazon/dsp.js';
import sb from '@/pages/entity_details/configs/amazon/sb.js';
import sd from '@/pages/entity_details/configs/amazon/sd.js';
export default {
  sp,
  sb,
  dsp,
  sd
};
