<!--
Values expected in props for rendering

label:             Text to be displayed in the component (defaulted to value%)
value:             Width of the colored portion (in %)
backgroundColor:   Background color to be rendered
labelColor  :      Text Color for the label
height  :          Height of the component while rendering (in px)
containerClasses:  Container classes for spacing, alignment etc
-->
<template>
  <div
    :class="containerClasses"
    :style="{
      background: `linear-gradient(to right, ${bgColor} ${getValue}%, #fff 0%)`,
      color: labelColor
    }"
  >
    <span
      v-tippy
      :title="naTooltipComp"
      >{{ getLabel }}</span
    >
  </div>
</template>
<script>
import { isNull } from 'lodash';
export default {
  name: 'PercentageBackgroundComponent',
  props: {
    label: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [Number, String],
      default: 0
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 50
    },
    containerClasses: {
      type: String,
      default:
        'u-font-size-5 u-text-align-center u-spacing-pv-m u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-border-left u-border-width-s u-border-color-grey-xxx-light'
    },
    naText: {
      type: String,
      default: '-'
    },
    naTooltip: {
      type: String,
      default: ''
    },
    rangeColorMap: {
      type: Array,
      default: null
      // [{
      //   min: '70',
      //   max: '100',
      //   color: 'rgba(62, 169, 94, 0.1)'
      // }, {
      //   min: 40,
      //   max: 70,
      //   color: 'rgba(255, 168, 0, 0.1)'
      // },
      // {
      //   min: 0,
      //   max: 40,
      //   color: 'rgba(215, 38, 61, 0.1)'
      // }
      // ]
    },
    showNaLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelData: '',
      bgColor: this.backgroundColor
    };
  },
  computed: {
    getLabel() {
      if (isNull(this.labelData) || !this.labelData) {
        this.labelData = +parseFloat(this.value).toFixed(2);
        return this.showNaLabel
          ? this.naText
          : this.labelData.toString().concat('%');
      } else {
        return this.labelData;
      }
    },
    naTooltipComp() {
      if (this.value == null || this.value === undefined) {
        return this.naTooltip;
      } else {
        return '';
      }
    },
    getValue() {
      return this.value;
    }
  },
  watch: {
    label(newVal) {
      this.labelData = newVal;
    }
  },
  created() {
    this.labelData = this.label;
    if (this.rangeColorMap?.length) {
      for (const e of this.rangeColorMap) {
        const comparedValue = parseInt(
          !isNull(this.labelData) ? this.labelData : this.value
        );
        if (
          comparedValue >= parseInt(e.min) &&
          comparedValue <= parseInt(e.max)
        ) {
          this.bgColor = e.color;
        }
      }
    }
  }
};
</script>
