<script>
import { debounce, cloneDeep } from 'lodash';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import HttpService from '@/utils/services/http-service';
import Loader from '@/components/basic/loader.vue';
const logger = require('@/utils/helpers/logger').default;

export default {
  name: 'GlobalSearch',
  author: 'Rajan V',
  components: {
    Loader
  },
  data() {
    return {
      tippy: {
        placement: 'top',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      searchTerm: '',
      limit: 20,
      filteredItem: [],
      searchHistory: [],
      payLoadConfig: {
        location: this.$store.getters.getLocation,
        retailer: this.$store.getters.getRetailer,
        program: this.$store.getters.getProgram,
        type: 'sku_search'
      },
      isLoading: false,
      historyLimit: 10
    };
  },
  computed: {
    resources() {
      return this.$store.getters.getGlobalFilterValues.catalogAsins || [];
    },
    computedSearchHistory() {
      const searchList = this.filterEvents(this.searchHistory, 'search') || [];
      return searchList || [];
    },
    computedASINHistory() {
      const asinList = this.filterEvents(this.searchHistory, 'asin') || [];
      return asinList || [];
    }
  },
  watch: {
    searchTerm: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.noDebouncer();
      } else {
        this.debouncer();
      }
    }
  },
  created() {
    this.getHistory();
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
    });
  },
  methods: {
    filterEvents(events, eventType) {
      const tempAsinList =
        events.filter((item) => item.eventType === eventType) || [];
      if (tempAsinList?.length > 1) {
        tempAsinList.sort((a, b) => b?.logDate - a?.logDate);
        tempAsinList.splice(10, tempAsinList.length);
      }
      return tempAsinList;
    },
    updateHistory(event) {
      const element = this.searchHistory.findIndex(
        (item) => item.term === event.term
      );
      if (element !== -1) {
        // Element found in the array
        if (this.searchHistory[element].eventType !== 'asin') {
          this.searchHistory.splice(element, 1);
          this.searchHistory.unshift(event);
        }
      } else {
        this.searchHistory.unshift(event);
      }
    },
    async getHistory() {
      this.isLoading = true;
      try {
        const res = await HttpService.post('GETALL_SEARCH', this.payLoadConfig);
        // this.searchHistory.unshift(...res.data.data);
        let data = res.data?.data.sort((a, b) => a.logDate - b.logDate);
        data =
          data?.filter(
            (value, index, self) =>
              self.findIndex((m) => m.term === value.term) === index
          ) || [];
        const asinList = this.filterEvents(data || [], 'asin') || [];
        const searchList = this.filterEvents(data || [], 'search') || [];
        this.searchHistory.unshift(...asinList, ...searchList);
      } catch (e) {
        this.$snackbar.open({
          message: 'Failed to Load Search history',
          duration: 2000,
          actionText: 'Failed'
        });
        this.searchHistory = [];
      } finally {
        this.isLoading = false;
      }
    },
    clearSearch() {
      this.searchTerm = '';
      this.limit = 20;
    },
    navigateToSDP(details) {
      const payload = cloneDeep(this.payLoadConfig);
      payload.term = details.asin;
      payload.eventType = 'asin';
      payload.additionalDetails = details;
      logger.addLog(payload);
      navigate_to_sdp(details.asin);
      this.updateHistory(payload);
    },
    changeLimit() {
      this.limit = this.limit * 4;
    },
    filterValues() {
      const searchTerm = this.searchTerm?.trim();
      if (searchTerm) {
        this.limit = 20;
        const filteredItem = this.resources.filter((item) => {
          return (
            item.searchText.toLowerCase().indexOf(searchTerm?.toLowerCase()) !==
            -1
          );
        });
        if (searchTerm.length > 3) {
          const payload = cloneDeep(this.payLoadConfig);
          payload.term = searchTerm;
          payload.eventType = 'search';
          logger.addLog(payload);
          this.updateHistory(payload);
        }
        this.filteredItem = filteredItem;
      } else {
        this.filteredItem = [];
      }
    },
    debouncer: debounce(function () {
      this.filterValues();
    }, 500),
    noDebouncer() {
      this.filterValues();
    },
    selectSearchTerm(item) {
      this.searchTerm = item.term;
    }
  }
};
</script>
<template>
  <section
    class="global_search u-display-flex u-flex-direction-column u-bg-color-grey-white u-spacing-mb-0"
    :class="{ 'u-height-100': !searchTerm || filteredItem.length < 5 }"
  >
    <section
      class="u-spacing-pt-l u-spacing-mb-0 u-spacing-pb-m"
      style="z-index: 2"
      :class="{
        sticky: searchTerm,
        box_shadow: filteredItem.length !== 0 && searchTerm
      }"
    >
      <div
        class="u-position-relative u-display-flex u-flex-direction-column u-spacing-mh-l"
      >
        <rb-input
          ref="searchInput"
          v-model="searchTerm"
          class="search__input u-width-100"
          :rounded="false"
          autofocus
        />
        <span
          class="u-position-absolute u-display-flex"
          style="top: 8px; left: 4px; margin: auto"
          @click.stop.prevent="onSearchSubmit"
        >
          <rb-icon
            icon="search"
            class="search__input-icon"
          />
        </span>
        <span
          v-if="searchTerm"
          class="u-position-absolute u-display-flex"
          style="top: -20px; right: 8px; bottom: 0; margin: auto"
          @click.stop.prevent="clearSearch"
        >
          <rb-icon
            icon="cross"
            class="cross__input-icon"
          />
        </span>
        <p class="u-spacing-mt-s u-font-size-7 u-color-grey-mid-light">
          Search for SKUs with an ASIN or SKU Title
        </p>
      </div>
      <section
        class="recent_search_container u-spacing-mt-l u-display-flex u-spacing-ph-l"
      >
        <div
          v-if="!searchTerm"
          class="u-width-100 u-display-flex u-flex-direction-column"
        >
          <h5
            class="u-font-size-4 u-color-grey-lighter u-font-weight-600 u-text-transform-uppercase u-display-flex u-flex-align-items-center"
          >
            <rb-icon
              icon="history"
              class="u-spacing-mr-s rb-icon--x-medium"
            />
            <p class="u-color-grey-lighter">Recently searched</p>
          </h5>
          <p
            v-if="searchHistory.length === 0"
            class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s"
          >
            You are yet to do your first
            <span class="u-font-weight-600">Universal Search</span>. Search
            away!
          </p>
        </div>
        <div
          v-else
          class="u-width-100 u-font-size-4 u-text-transform-uppercase u-font-weight-600 u-color-grey-lighter"
        >
          <span class="u-color-grey-base">{{ filteredItem.length }}</span> SKUs
          Found with
          <span class="u-color-blue-base u-font-weight-600 u-font-st"
            >"{{ searchTerm }}"</span
          >
        </div>
      </section>
    </section>

    <section
      v-if="searchTerm"
      class="search_items u-spacing-mt-m u-bg-color-grey-white u-spacing-ph-l"
      :class="{ 'u-height-100': filteredItem.length === 0 }"
    >
      <section
        v-if="filteredItem.length > 0"
        class="u-display-flex u-flex-wrap-yes u-bg-color-grey-white u-width-100"
      >
        <div
          v-for="(item, index) in filteredItem"
          v-if="index < limit"
          :key="index"
          class="card u-display-flex u-spacing-p-m u-flex-direction-column u-display-inline-flex u-spacing-mb-l u-cursor-pointer"
          :class="{ 'u-spacing-mr-l': (index + 1) % 4 !== 0 }"
          @click="navigateToSDP(item)"
        >
          <section
            class="u-display-flex u-flex-direction-column u-flex-align-items-center u-height-100 u-flex-justify-content-center"
          >
            <div
              v-if="index < limit"
              class="holder u-border-all u-border-width-s u-border-radius-s u-spacing-p-s u-border-color-grey-xxx-light image_wrapper u-height-100px u-spacing-mh-l"
            >
              <img
                class="asin-image"
                :src="
                  item.image_url ||
                  'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_default.jpg'
                "
                alt="image_url"
                loading="lazy"
              />
            </div>
            <div
              v-tippy="tippy"
              class="u-spacing-mt-m asin-title u-font-weight-600"
              :title="item.asin_name"
            >
              {{ item.asin_name }}
            </div>
            <p
              v-tippy="tippy"
              class="u-display-flex u-spacing-mt-s u-flex-justify-content-center u-font-size-6 u-color-grey-lighter"
              :title="item.asin"
              @click="navigateToSDP(item)"
            >
              {{ item.asin }}
            </p>
          </section>
        </div>
      </section>
      <rb-button
        v-if="filteredItem.length > limit"
        :click-fn="changeLimit"
        :type="'hollow'"
        text="View more"
        icon-left="arrow1-down"
        class="u-spacing-mb-l arrow-down--icon u-spacing-ph-m u-spacing-pv-s"
        size="m"
      />
      <div
        v-if="filteredItem.length === 0"
        class="u-width-100 u-spacing-mt-s u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-flex-1 u-height-100"
      >
        <div class="u-display-flex noskuImage_container">
          <img
            src="/images/no_sku_found.png"
            class="u-width-100"
            alt="no sku found"
          />
        </div>
        <p class="u-font-size-4 u-font-weight-600 u-spacing-mt-l">
          No SKUs were found, please try again!
        </p>
      </div>
    </section>

    <section
      v-else
      class="u-spacing-mt-0 u-display-flex u-flex-1 u-bg-color-grey-white u-spacing-ph-l"
    >
      <div
        v-if="searchHistory.length === 0 && !isLoading"
        class="u-width-100 u-spacing-mt-s u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
      >
        <div class="u-display-flex noskuImage_container">
          <img
            src="/images/global_search.png"
            class="u-width-100"
            alt="no sku found"
          />
        </div>
        <p class="u-font-size-4 u-font-weight-600 u-spacing-mt-l">
          Go ahead and search for any SKU from your catalog!
        </p>
      </div>
      <div
        v-else-if="computedSearchHistory.length > 0 && !isLoading"
        class="u-width-100"
      >
        <section
          class="u-width-100 u-display-flex u-flex-direction-column u-spacing-pb-s"
        >
          <div
            v-for="(item, $index) in computedSearchHistory"
            :key="$index"
            class="u-display-flex u-flex-align-items-center u-spacing-mb-m"
          >
            <p
              class="u-font-size-4 u-font-weight-400 u-font-style-italic u-color-grey-base term_wrapper u-cursor-pointer"
              @click.stop="selectSearchTerm(item)"
            >
              {{ item.term }}
            </p>
          </div>
        </section>
        <div
          v-if="computedASINHistory.length > 0"
          class="u-width-100 u-display-flex u-flex-direction-column u-border-style-dashed u-spacing-pt-m"
        >
          <div class="heading">
            <h5
              class="u-font-size-4 u-color-grey-lighter u-font-weight-600 u-text-transform-uppercase u-display-flex u-flex-align-items-center u-spacing-mb-m"
            >
              <rb-icon
                icon="visibility-on"
                class="u-spacing-mr-s rb-icon--x-medium"
              />
              <p>Recently viewed</p>
            </h5>
          </div>
          <section
            v-if="computedASINHistory.length > 0"
            class="u-display-flex u-flex-wrap-yes u-bg-color-grey-white u-width-100"
          >
            <div
              v-for="(item, $index) in computedASINHistory"
              :key="$index"
              class="card u-display-flex u-spacing-p-m u-flex-direction-column u-display-inline-flex u-spacing-mb-l u-cursor-pointer"
              :class="{ 'u-spacing-mr-l': ($index + 1) % 4 !== 0 }"
              @click="navigateToSDP(item.additionalDetails)"
            >
              <section
                class="u-display-flex u-flex-direction-column u-flex-align-items-center u-height-100 u-flex-justify-content-center"
              >
                <div
                  class="holder u-border-all u-border-width-s u-border-color-grey-xxx-light image_wrapper u-height-100px u-spacing-mh-l u-border-radius-s u-spacing-p-s"
                >
                  <img
                    class="asin-image"
                    :src="
                      item.additionalDetails.image_url ||
                      'https://ciq-demo-artifacts.s3-us-west-2.amazonaws.com/cpg_sales_image_default.jpg'
                    "
                    alt="image_url"
                    loading="lazy"
                  />
                </div>
                <div
                  v-tippy="tippy"
                  class="u-spacing-mt-m asin-title u-font-weight-600"
                  :title="item.additionalDetails.asin_name"
                >
                  {{ item.additionalDetails.asin_name }}
                </div>
                <p
                  v-tippy="tippy"
                  class="u-display-flex u-spacing-mt-s u-flex-justify-content-center u-font-size-6 u-color-grey-lighter"
                  :title="item.additionalDetails.asin"
                  @click="navigateToSDP(item.additionalDetails)"
                >
                  {{ item.additionalDetails.asin }}
                </p>
              </section>
            </div>
          </section>
        </div>
      </div>
      <div v-else-if="isLoading">
        <Loader
          v-if="isLoading"
          class="fill--parent"
          :color="'#3fadf7'"
          :size="'4rem'"
          :thickness="'.2rem'"
        />
      </div>
    </section>
  </section>
</template>

<style lang="css">
.global_search .search__input input {
  border-bottom: 1px solid #e9eaeb !important;
  border-radius: 0 !important;
  font-size: 21px;
  background: #fff !important;
}
.global_search .search__input .rb-input:focus {
  border-bottom: 1px solid #007cf6 !important;
}
.global_search .search__input-icon {
  cursor: initial;
}
.global_search .search__input-icon:hover {
  color: #8b8f93;
}
.global_search .cross__input-icon {
  color: #8b8f93;
}
.global_search .cross__input-icon:hover {
  color: #4b5158;
}
.global_search .icon-arrow1-down:before {
  font-size: 12px;
}
</style>

<style lang="css" scoped>
.global_search:before {
  content: '';
  width: 100%;
  height: 8px;
  top: 48px;
  -webkit-box-shadow: 2px 1px 8px 0 #e9eaeb;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  z-index: 3;
  position: absolute;
}
.card {
  width: calc((100% / 4 - 18px));
  height: 230px;
  border-radius: 4px;
  border: 1px solid #e9eaeb;
  box-shadow: none;
}
.card .image_wrapper {
  width: 148px;
  height: 148px;
}
.card:hover {
  border: 1px solid #007cf6;
}
.asin-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.asin-title {
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  text-align: center;
  line-height: 12px;
  font-size: 1.3rem;
  color: #2b333b;
}
.u-height-100px {
  height: 100px;
}
.noskuImage_container {
  width: 50%;
  height: 240px;
}
.noskuImage_container img {
  object-fit: contain;
}
.term_wrapper:hover {
  color: #007cf6;
}
.u-border-style-dashed {
  border-top: 1px dashed #e9eaeb;
}
.box_shadow {
  box-shadow: 2px 1px 8px 0 #e9eaeb;
}
</style>
