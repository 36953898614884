<template>
  <div>
    <sticky-filter-panel
      :last-week-ranges-compared-to-today="false"
      :hide-export="false"
      :hide-clear-all-btn="true"
      :disable-view="true"
      class="sticky-panel sticky-filter-panel category_page"
      :options-list="[]"
      :default-date-post-range="defaultDateRange"
      :enable-minimum-selection="true"
      :view-selection-enabled="false"
    >
      <div
        slot="before-filter"
        class="u-display-flex u-flex-align-items-center u-height-36px"
      >
        <div class="u-font-size-2 u-font-weight-700 u-display-flex capitalize">
          IMAP
        </div>
      </div>
    </sticky-filter-panel>
    <div class="u-display-flex">
      <imap-violation class="u-width-50 u-spacing-mh-s" />
      <source-of-map-violation class="u-width-50 u-spacing-mh-s" />
    </div>
    <spotlight
      :spotlights="spotlights"
      @onSpotlightClick="onSpotlightClick"
    />
    <sku-breakdown
      id="imapSkuBreakdown"
      class="u-spacing-mb-m"
    />
    <price-trend id="imapPriceTrend" />
  </div>
</template>

<script>
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import spotlight from '@/pages/all_retailers/dso/molecules/spotlight.vue';
import skuBreakdown from '@/pages/all_retailers/dso/imap/skuBreakdown.vue';
import priceTrend from '@/pages/all_retailers/dso/imap/priceTrend.vue';
import imapViolation from '@/pages/all_retailers/dso/imap/imapViolation.vue';
import sourceOfMapViolation from '@/pages/all_retailers/dso/imap/sourceOfMapViolation.vue';

export default {
  name: 'IMAP',
  components: {
    stickyFilterPanel,
    spotlight,
    skuBreakdown,
    priceTrend,
    imapViolation,
    sourceOfMapViolation
  },
  data() {
    return {
      defaultDateRange: 'last30Days',
      spotlights: [
        {
          type: 'positive',
          title: "What's working",
          icon: 'show-chart',
          actionItems: [
            {
              icon: 'thumbsup',
              action: 'MAP Violation resolved',
              actionDescription:
                'B09WD4TWYP Price has been reverted as of 31 Dec 2022 based ticket Id: 65665432',
              skuIds: [],
              iconClasses: 'u-color-green-base'
            }
          ],
          iconClasses: 'u-bg-color-green-base u-border-radius-s rb-icon--medium'
        },
        {
          type: 'negative',
          title: "What's working",
          icon: 'show-chart',
          actionItems: [
            {
              icon: 'thumbsdown',
              action: 'MAP Price breached for priority SKUs',
              actionDescription:
                'B09RMRNSBF, B08WG4HKKY map price have been breached on 01 Jan 2023',
              skuIds: [1, 2],
              iconClasses: 'u-color-red-base'
            },
            {
              icon: 'thumbsdown',
              action: 'AMAZON breached MAP price for SKU B08WG4HKKY',
              actionDescription:
                'B08WG4HKKY map price have been breached on 01 Jan 2023',
              skuIds: [1],
              iconClasses: 'u-color-red-base'
            }
          ],
          iconClasses: 'u-bg-color-red-base u-border-radius-s rb-icon--medium'
        }
      ]
    };
  },
  methods: {
    onSpotlightClick(spotLightDetails) {
      if (!spotLightDetails.skuIds) return;
      let scrollElement;
      if (spotLightDetails.skuIds.length > 1) {
        scrollElement = document.getElementById('imapSkuBreakdown');
      } else if (spotLightDetails.skuIds.length === 1) {
        scrollElement = document.getElementById('imapPriceTrend');
      }
      scrollElement.scrollIntoView();
    }
  }
};
</script>
