<template>
  <section>
    <div
      v-for="(item, $index) in updatedList"
      :key="$index"
    >
      <div class="u-display-flex u-flex-align-items-center">
        <p class="u-font-size-5 u-color-grey-light">
          {{ titleSplit(item.info) }}
        </p>

        <rb-select
          class="u-spacing-mr-s"
          :send-details="true"
          :on-close="optionSelected"
          :options="item.metrics"
        >
          <div
            slot="trigger"
            class="u-spacing-ml-s u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer u-border-all u-border-width-s u-border-color-grey-xxx-light u-border-radius-s"
          >
            <div class="u-display-flex u-flex-direction-column u-spacing-p-xs">
              <span class="u-font-size-5 u-color-grey-light">{{
                selectedOption.metric
              }}</span>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div class="u-display-flex u-flex-align-items-center">
              {{ option.metric }}
            </div>
          </template>
        </rb-select>
        <rb-select
          v-if="operators.length > 0 && selectedOperator"
          class="u-spacing-mr-s"
          :send-details="true"
          :on-close="operatorSelected"
          :options="operators"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer u-border-all u-border-width-s u-border-color-grey-xxx-light u-border-radius-s"
          >
            <div class="u-display-flex u-flex-direction-column u-spacing-p-xs">
              <span class="u-font-size-5 u-color-grey-light">{{
                selectedOperator.operator
              }}</span>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
              :icon="'caret-down'"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div class="u-display-flex u-flex-align-items-center">
              {{ option.operator }}
            </div>
          </template>
        </rb-select>

        <div
          class="u-display-flex u-flex-0 u-flex-align-items-center u-width-10 percentBox"
        >
          <input
            ref="value"
            v-model="selectedValue"
            type="number"
            class="rb-input currency-input u-color-grey-light u-flex-align-items-center"
            @input="validateValues($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Lockout',
  props: {
    entityType: {
      type: String,
      default: ''
    },
    config: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      selectedOption: null,
      selectedOperator: null,
      operators: [],
      selectedValue: 0,
      key: 0
    };
  },
  computed: {
    updatedList() {
      if (this.config.length > 0) {
        this.selectedOption = this.config[0] && this.config[0].metrics[0];
      }
      return this.config;
    }
  },
  watch: {
    selectedOption: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (Object.keys(newValue).length > 0) {
            this.selectedValue = newValue.defaultValue;
            this.operators = Object.keys(newValue.operators).map((item) => {
              return {
                item: item,
                operator: newValue && newValue.operators[item]
              };
            });
            this.selectedOperator = this.operators[0];
          }
        }
        this.validateValues();
      },
      deep: true
    }
  },
  methods: {
    titleSplit(value) {
      if (!value) return '--';
      const newVal = value.replace('{entity}', this.entityType);
      return newVal.split('{metric')[0];
    },
    optionSelected(context, selectedOption) {
      if (!selectedOption?.length) return;
      this.selectedOption = selectedOption[0];
      this.validateValues();
    },
    operatorSelected(context, selectedOption) {
      if (!selectedOption?.length) return;
      this.selectedOperator = selectedOption[0];
      this.validateValues();
    },
    clearValue() {
      this.selectedOption = {
        ...(this.config[0] && this.config[0].metrics[0])
      };
      this.validateValues();
    },
    validateValues() {
      const obj = {
        valid: true,
        operator: this.selectedOperator,
        attribute: this.selectedOption,
        metrics: this.config && this.config[0] && this.config[0].metricsPeriod,
        value: this.selectedValue
      };
      if (this.selectedValue.length === 0) {
        obj.valid = false;
      }
      this.$emit('getLockoutChangeValue', obj);
    }
  }
};
</script>
<style lang="css" scoped>
.currency-input {
  width: 100%;
  padding: 4px;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  outline: none;
}
</style>
