<template>
  <cross-retailer-tableau
    v-if="dashUrl"
    :dashboard-name="dashUrl"
  />
</template>

<script>
import crossRetailerTableau from '@/pages/all_retailers/crossRetailerTableau/crossRetailerTableau.vue';
export default {
  components: { crossRetailerTableau },
  props: {
    pageName: {
      type: String,
      default: ''
    }
  },
  computed: {
    clientDashboards() {
      return this.$store.getters.getCustomReportsClientDashboards;
    },
    dashUrl() {
      const dashboardsList = this.clientDashboards;
      return dashboardsList.find((dash) => {
        return dash.ui_page === this.pageName;
      })?.name;
    }
  },
  created() {
    this.$store.dispatch('set_default_version', 2);
    this.$store.dispatch('check_and_fetch_clients_dashboard');
  }
};
</script>
