export default {
  widget9: {
    widgetName: '',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'PCOGS Change',
        dbKey: 'PVP_PVP_DIFF_pcogs'
      },
      {
        displayName: 'PCOGS Change %',
        dbKey: 'PVP_PVP_pcogs'
      },
      {
        displayName: 'Current Period PCOGS',
        dbKey: 'pcogs'
      },
      {
        displayName: 'Previous Period PCOGS',
        dbKey: 'PVP_pcogs'
      },
      {
        displayName: 'Unit COGS',
        dbKey: 'unit_cogs'
      },
      {
        displayName: 'Shipped Units Current',
        dbKey: 'units_shipped'
      },
      {
        displayName: 'Shipped Units Previous',
        dbKey: 'PVP_units_shipped'
      }
    ]
  },
  widget10: {
    widgetName: '',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Revenue Change',
        dbKey: 'PVP_PVP_DIFF_ordered_product_sales'
      },
      {
        displayName: 'Revenue Change %',
        dbKey: 'PVP_PVP_ordered_product_sales'
      },
      {
        displayName: 'Current Period Revenue',
        dbKey: 'ordered_product_sales'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_ordered_product_sales'
      },
      {
        displayName: 'Average Selling Price Current',
        dbKey: 'avg_selling_price'
      },
      {
        displayName: 'Average Selling Price Previous',
        dbKey: 'PVP_avg_selling_price'
      }
    ]
  },
  widget11: {
    widgetName: '',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Margin Change',
        dbKey: 'PVP_PVP_DIFF_gross_retailer_margin'
      },
      {
        displayName: 'Margin Change %',
        dbKey: 'PVP_PVP_gross_retailer_margin'
      },
      {
        displayName: 'Current Period Margin',
        dbKey: 'gross_retailer_margin'
      },
      {
        displayName: 'Previous Period Margin',
        dbKey: 'PVP_gross_retailer_margin'
      },
      {
        displayName: 'Average Selling Price Current',
        dbKey: 'avg_selling_price',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Average Selling Price Previous',
        dbKey: 'PVP_avg_selling_price',
        type: 'ordered_product_sales'
      }
    ]
  },

  widget15: {
    widgetName: 'Ordered Units',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Units Sold Change',
        dbKey: 'PVP_PVP_DIFF_ordered_units'
      },
      {
        displayName: 'Units Sold Change %',
        dbKey: 'PVP_PVP_ordered_units'
      },
      {
        displayName: 'Current Period Units Sold',
        dbKey: 'ordered_units'
      },
      {
        displayName: 'Previous Period Units Sold',
        dbKey: 'PVP_ordered_units'
      },
      {
        displayName: 'Revenue Change',
        dbKey: 'PVP_PVP_DIFF_ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Revenue Change %',
        dbKey: 'PVP_PVP_ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Revenue Change',
        dbKey: 'PVP_PVP_DIFF_pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Revenue Change %',
        dbKey: 'PVP_PVP_pcogs',
        type: 'pcogs'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_pcogs',
        type: 'pcogs'
      }
    ]
  },
  widget16: {
    widgetName: '',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'ASP Change',
        dbKey: 'PVP_PVP_DIFF_avg_selling_price'
      },
      {
        displayName: 'ASP Change %',
        dbKey: 'PVP_PVP_avg_selling_price'
      },
      {
        displayName: 'Current Period ASP',
        dbKey: 'avg_selling_price'
      },
      {
        displayName: 'Previous Period ASP',
        dbKey: 'PVP_avg_selling_price'
      },
      {
        displayName: 'Last 4 Weeks Revenue',
        dbKey: 'ordered_revenue_last_30days'
      }
    ]
  },
  widget12: {
    widgetName: 'Revenue Lost Due To OOS',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_ops',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_ops',
        type: 'ordered_product_sales'
      },
      // {
      //   'displayName': 'Average daily sales (OPS)',
      //   'dbKey': 'revenue_lost_ops_daily'
      // },
      // {
      //   'displayName': 'Average daily sales (PCOGS)',
      //   'dbKey': 'revenue_lost_pcogs_daily'
      // },
      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_pcogs',
        type: 'pcogs'
      },

      {
        displayName:
          'Total revenue lost due to unavailability in the current period',
        dbKey: 'oos_days_in_range'
      },
      {
        displayName:
          'Total revenue lost due to unavailability in the previous period',
        dbKey: 'PVP_oos_days_in_range'
      },
      {
        displayName: 'Change in Revenue lost due to OOS',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_ops',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Change in Revenue lost due to OOS',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Last 4 Weeks Revenue',
        dbKey: 'ordered_revenue_last_30days'
      },
      // {
      //   'displayName': 'Rep OOS',
      //   'dbKey': 'rep_oos'
      // },
      {
        displayName: 'Open POs',
        dbKey: 'open_pos'
      }
    ]
  },
  widget13: {
    widgetName: 'Revenue Lost Due To Lost Buy Box',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_lbb',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_lbb',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_lbb',
        type: 'pcogs'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_lbb',
        type: 'pcogs'
      },
      // {
      //   'displayName': 'Average daily sales (OPS)',
      //   'dbKey': 'revenue_lost_ops_daily'
      // },
      // {
      //   displayName: 'Total Revenue lost in the current period',
      //   dbKey: 'revenue_lost_pcogs',
      //   type: 'pcogs'
      // },
      // {
      //   displayName: 'Total Revenue lost in the previous period',
      //   dbKey: 'PVP_revenue_lost_pcogs',
      //   type: 'pcogs'
      // },
      // {
      //   'displayName': 'Average daily sales (PCOGS)',
      //   'dbKey': 'revenue_lost_pcogs_daily'
      // },
      // {
      //   displayName: 'Current LBB Days',
      //   dbKey: 'lbb_days_in_range'
      // },
      // {
      //   displayName: 'Previous LBB Days',
      //   dbKey: 'PVP_lbb_days_in_range'
      // },
      {
        displayName: 'Change in Revenue lost due to LBB',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_lbb',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Change in Revenue lost due to LBB',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_lbb',
        type: 'pcogs'
      },
      // {
      //   displayName: 'Current Buybox Winner',
      //   dbKey: 'buybox_seller_name'
      // },
      // {
      //   displayName: 'Winner\'s Offer Price',
      //   dbKey: 'buybox_offer_price'
      // },
      // {
      //   displayName: 'Amazon Offer Price',
      //   dbKey: 'sas_offer_price_not_avg'
      // },
      {
        displayName: 'LBB %',
        dbKey: 'actual_lbb_rate'
      },
      {
        displayName: 'Current Units at Amazon',
        dbKey: 'units_at_amazon'
      }
    ]
  },
  widget14: {
    widgetName: 'Revenue Lost Due To Unavailable Skus',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_unavailable',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_unavailable',
        type: 'ordered_product_sales'
      },

      {
        displayName: 'Total Revenue lost in the current period',
        dbKey: 'revenue_lost_unavailable',
        type: 'pcogs'
      },
      {
        displayName: 'Total Revenue lost in the previous period',
        dbKey: 'PVP_revenue_lost_unavailable',
        type: 'pcogs'
      },
      // {
      //   displayName: 'Current Unavailable Skus Days',
      //   dbKey: 'uavl_days_in_range'
      // },
      // {
      //   displayName: 'Previous Unavailable Skus Days',
      //   dbKey: 'PVP_uavl_days_in_range'
      // },
      // {
      //   'displayName': 'Average daily sales (OPS)',
      //   'dbKey': 'revenue_lost_ops_daily'
      // },
      {
        displayName: 'Change in Revenue lost due to Unavailable Skus',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_unavailable',
        type: 'ordered_product_sales'
      },
      // {
      //   'displayName': 'Average daily sales (PCOGS)',
      //   'dbKey': 'revenue_lost_pcogs_daily'
      // },
      {
        displayName: 'Change in Revenue lost due to Unavailable Skus',
        dbKey: 'PVP_PVP_DIFF_revenue_lost_unavailable',
        type: 'pcogs'
      },
      {
        displayName: 'Unavailability',
        dbKey: 'actual_unavailability'
      },
      {
        displayName: 'Rep OOS',
        dbKey: 'actual_rep_oos'
      },
      {
        displayName: 'Current Units at Amazon',
        dbKey: 'units_at_amazon'
      },
      {
        displayName: 'Last 4 Weeks Revenue',
        dbKey: 'ordered_revenue_last_30days'
      },
      {
        displayName: 'Open POs',
        dbKey: 'open_pos'
      }
    ]
  },

  widget17: {
    widgetName: 'price_war',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'asin_name'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Revenue',
        dbKey: 'ordered_revenue_last_30days',
        checkOutsideIn: true
      },
      {
        displayName: 'Price War Index',
        dbKey: 'price_war_index_max'
      },
      {
        displayName: 'Amazon offer price',
        dbKey: 'client_offer_price'
      },
      {
        displayName: 'Retailer',
        dbKey: 'comp_name_latest'
      },
      {
        displayName: 'Retailer offer price',
        dbKey: 'comp_offer_price'
      }
    ]
  },
  widget19: {
    widgetName: 'Glance Views',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Glance Views Change %',
        dbKey: 'PVP_PVP_ds_glance_views'
      },
      {
        displayName: 'Current Period Glance Views',
        dbKey: 'ds_glance_views'
      },
      {
        displayName: 'Previous Period Glance Views',
        dbKey: 'PVP_ds_glance_views'
      },
      // {
      //   displayName: 'Current Period Conversion',
      //   dbKey: 'conversion_percentile'
      // },
      // {
      //   displayName: 'Previous Period Conversion',
      //   dbKey: 'PVP_conversion_percentile'
      // },
      {
        displayName: 'Current Period Units Sold',
        dbKey: 'ordered_units'
      },
      {
        displayName: 'Previous Period Units Sold',
        dbKey: 'PVP_ordered_units'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_pcogs',
        type: 'pcogs'
      }
    ]
  },
  widget20: {
    widgetName: 'Conversion',
    tableKeys: [
      {
        displayName: 'SKU Details',
        dbKey: 'asin'
      },
      {
        displayName: 'Title',
        dbKey: 'title'
      },
      {
        displayName: 'Product Page URL',
        dbKey: 'asin_hyperlink'
      },
      {
        displayName: 'Current Period Conversion',
        dbKey: 'conversion_percentile'
      },
      {
        displayName: 'Previous Period Conversion',
        dbKey: 'PVP_conversion_percentile'
      },
      {
        displayName: 'Change In Percentage (Subtract 2 from 1)',
        dbKey: 'PVP_PVP_DIFF_conversion_percentile'
      },
      {
        displayName: 'Current Period Glance Views',
        dbKey: 'ds_glance_views'
      },
      {
        displayName: 'Previous Period Glance Views',
        dbKey: 'PVP_ds_glance_views'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_ordered_product_sales',
        type: 'ordered_product_sales'
      },
      {
        displayName: ' Current Period Revenue',
        dbKey: 'pcogs',
        type: 'pcogs'
      },
      {
        displayName: 'Previous Period Revenue',
        dbKey: 'PVP_pcogs',
        type: 'pcogs'
      }
    ]
  }
};
