import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled,
  getOrderByList
} from '@/components/pages/insights/amazon/cva/utils';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  async fetchData(pageSettings) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_in_stock_sku',
        entityType: 'SKU',
        metricsList: rem1V2ConfigEnabled()
          ? ['cva_in_stock_client_id_v2', 'cva_in_stock_percentage_v2']
          : ['cva_in_stock_client_id', 'cva_in_stock_percentage'],
        dimensionsList: [
          'BN_BSR_Rank',
          'score',
          'rating',
          'review',
          'client_id'
        ],
        eventsList: [],
        enablePaginationCount: true,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: getOrderByList('cva_in_stock_percentage', 'ASC'),
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'In_stock_sku',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'rank',
              dimensionValue: 20,
              operator: 'LESS_THAN_OR_EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
        body.operations.orderByList = getOrderByList(
          'cva_in_stock_percentage_v2',
          'ASC'
        );
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'SKU',
        field: 'SKU',
        minWidth: 300,
        headerComponentParams: {
          enableSorting: false,
          toolTipText: 'Product ASIN'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          eventMap: {},
          paramsToProps: skuParamsToProps
        }
      },
      {
        headerName: 'In Stock Percentage',
        field: 'in_stock_percentage',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'Average in stock rate over the time period based on scraping information'
        },
        cellRendererParams: {
          keyType: 'percent',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Rating',
        field: 'rating',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Average rating of the product'
        },
        cellRendererParams: {
          cellAlignment: 'u-text-align-right'
        }
      }
    ];
    columns = getActiveColumns(columns, 'availability_score');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = ['SKU'];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
