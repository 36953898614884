import Vue from 'vue';
import moment from 'moment-timezone';

export const CHART_TOOLTIP_TEMPLATES = {
  STREAM_HOURLY_CHART: (chartOptions, xAxisDate, labelText) => {
    const date = Vue.options.filters.printable_date(
      new Date(moment(xAxisDate).utc().startOf('day').valueOf())
    );
    const previousDay = Vue.options.filters.printable_date(
      new Date(
        moment(xAxisDate).utc().subtract(1, 'days').startOf('day').valueOf()
      )
    );
    const time = moment(xAxisDate).utc().format('h a');
    return `<div class="card u-spacing-pb-s" style="background:rgba(255, 255, 255, 0.98); box-shadow: 0 0 4px 0 #caccce !important;">
                      <div class="u-spacing-p-m u-border-bottom u-border-width-s u-border-color-grey-xxx-light">          
                          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-s">
                            <span class="u-font-size-5 u-color-grey-light u-font-weight-600">Today •</span>
                              <span class="u-font-size-6 u-color-grey-light u-spacing-ml-xs">${date} •</span>
                              <span class="u-font-size-6 u-color-grey-light u-spacing-ml-xs">${time}</span>
                            </div>
                            ${
                              chartOptions.YesterdayMode
                                ? `<div class="u-display-flex u-color-grey-x-light u-flex-align-items-center">
                              <span class="u-font-size-7 ">Compare to <span class='u-font-weight-600'>Yesterday</span> •</span>
                                <span class="u-font-size-7  u-spacing-ml-xs">${previousDay} •</span>
                                <span class="u-font-size-7  u-spacing-ml-xs">${time}</span>
                              </div>`
                                : ``
                            }
                            </div>
                            <div class="u-spacing-pl-m u-spacing-pt-s u-spacing-pb-m u-spacing-pr-s u-display-flex">
                              <span class="u-font-size-5 u-color-grey-light u-font-weight-600">${labelText}</span>
                            </div>`;
  }
};
