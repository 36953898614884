import MetadataService from '@/components/pages/insights/amazon/cva/services/MetadataService.js';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import { store } from '@/store/store';
import { rem1V2ConfigEnabled } from '@/components/pages/insights/amazon/cva/utils';

export default class BaseWidgetService {
  page = null;
  widget = null;
  metadata = null;
  pageId = null;
  globalViewId = null;
  data = null;
  loading = false;
  error = false;
  clickedDimension = null;
  constructor(page, widget, globalViewId, pageId, clickedDimension) {
    this.page = page;
    this.widget = widget;
    this.globalViewId = globalViewId;
    this.pageId = pageId;
    this.clickedDimension = clickedDimension;
    this.metadata = new MetadataService(
      this.page,
      this.widget,
      this.globalViewId,
      this.pageId
    );
  }

  async fetchInsights(modules, widgetSettings, limit = 1) {
    const widgetSettingsCopy = cloneDeep(widgetSettings);
    const body = {
      page: 'customer_value_assesment',
      widget: 'cva_insights',
      entityType: 'insight',
      metricsList: rem1V2ConfigEnabled()
        ? ['cva_insights_rank_v2']
        : ['cva_insights_rank'],
      dimensionsList: [
        'attributes',
        'rank',
        'insight_header',
        'insight_with_metadata',
        'cta',
        'cta_link'
      ],
      eventsList: [],
      enablePaginationCount: true,
      operations: {
        system: 'cva',
        enableNewPVPFormulaForSOV: false,
        enableDedupBeforeRollup: false,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        orderByList: [
          {
            dimension: 'attributes',
            direction: 'ASC'
          }
        ],
        limit: limit,
        page: 1,
        outerWhereClause: {
          dimensionNameValueList: []
        }
      },
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'time_period',
            dimensionValue: 'L90D',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'rank',
            dimensionValue: '1',
            operator: 'EQUAL_TO'
          }
        ]
      }
    };
    if (rem1V2ConfigEnabled()) {
      body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
    }
    body.where.dimensionNameValueList.push(
      ...(widgetSettingsCopy?.where?.dimensionNameValueList || [])
    );
    modules.forEach((module) => {
      body.where.dimensionNameValueList.push({
        dimensionName: 'module',
        dimensionValue: module,
        operator: 'EQUAL_TO'
      });
    });
    const data = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    });
    return data;
  }

  getRem1RetailerPayload() {
    const retailerIdPayload = {
      dimensionName: 'retailer_id',
      dimensionValue:
        store.getters['cvaRem/getSelectedRem1Retailer']?.ID || '4',
      operator: 'EQUAL_TO'
    };
    return retailerIdPayload;
  }
}
