<template>
  <div>
    <span
      v-for="(filter, index) in filters"
      :key="index"
    >
      <rb-filter
        ref="filter"
        :anchor="filter.filterKey"
        :config="{ title: filter.filterLabel, filterKey: filter.filterKey }"
        :show-notification="false"
        :multi="true"
        :use-local-storage="false"
        :hide-clear-all-btn="true"
        :is-always-on-filter="true"
        :add-filter-text="filter.addFilterText"
        :secondary-data="filterOptions"
        :apply="applyAttribute"
        :default-filters="selectedFilters"
        :hide-remove-btn="filter.hideRemoveBtn"
        :is-disabled-filter="filter.isDisabled"
      />
    </span>
  </div>
</template>

<script>
import filter from '@/components/widgets/filter/filter.vue';
import addFilter from '@/components/widgets/filter/addFilter.vue';

export default {
  name: 'HeaderFilters',
  components: {
    rbFilter: filter,
    addFilter
  },
  props: {
    filters: {
      type: Array,
      default: []
    },
    selectedFilters: {
      type: Object,
      default: function () {
        return {};
      }
    },
    filterOptions: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  methods: {
    applyAttribute(data) {
      this.$emit('filter-state-change', data);
    }
  }
};
</script>
