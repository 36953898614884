import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';

const skusUnderPriceWarColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    showToolTip: true,
    toolTipText: '',
    type: 'asin',
    keyOrder: 2,
    hoverText: 'Plot Graph',
    keys: {
      imageUrl: 'client_image_url',
      productUrl: 'asin_hyperlink',
      title: 'asin_name',
      asin: 'asin'
    }
  },
  {
    title: 'Revenue',
    key: 'ordered_revenue_last_30days',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    unit: 'currency',
    keyOrder: 3
  },
  {
    title: 'Priority',
    key: 'priority',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    keyOrder: 4
  },
  {
    title: 'PWI',
    key: 'price_war_index_max',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    keyOrder: 5
  },
  {
    title: 'offer price',
    key: 'client_offer_price',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    unit: 'currency',
    keyOrder: 6
  },
  {
    title: 'Retailer',
    key: 'comp_name_latest',
    showToolTip: true,
    toolTipText: '',
    type: 'string',
    keyOrder: 7
  },
  {
    title: 'Retailer offer price',
    key: 'comp_offer_price',
    showToolTip: true,
    toolTipText: '',
    type: 'numeric',
    unit: 'currency',
    keyOrder: 8
  }
];

function generateToolTip(that) {
  var dates = that.getAllDates();
  var pricewarDates = (dates.dateRangeValues || {}).sales_by_account_type;
  var tooltipTexts = {
    show_chart: '',
    sku_details: 'SKU title and ID as per the catalogue.',
    ordered_revenue_last_30days:
      '4 weeks ordered revenue (ordered units * ASP) from ' +
      Vue.options.filters.printable_date(new Date(pricewarDates.from)) +
      ' to ' +
      Vue.options.filters.printable_date(new Date(pricewarDates.to)),
    priority: 'Priority',
    price_war_index_max:
      'Price War Index (PWI) is the relative price index between different retailers and its value varies between 0 to 1. Example if a product is priced at 10$ on one retailer and at 5$ on another retailer, then the Price War Index (PWI) will be 0.5. In order to view all the retailers, please set the PWI accordingly.',
    client_offer_price: 'Latest offer price at ' + that.getMarketPlace,
    comp_name_latest: 'Retailer which is engaged in price war.',
    comp_offer_price: 'Retailer Offer Price: Latest offer price at retailer.'
  };

  return tooltipTexts;
}

var columnDefs = [];
function getColumnDefinition(vueRef, columns) {
  var tooltipTexts = generateToolTip(vueRef);
  var keysToCheck = ['ordered_revenue_last_30days', 'priority'];
  var _columnDefs = [];
  for (const i in columns) {
    if (columns[i].key === 'client_offer_price') {
      columns[i].title = vueRef.getMarketPlace + ' ' + columns[i].title;
    }

    if (keysToCheck.indexOf(columns[i].key) > -1) {
      // if outsideIn holds true then ignore priority column in the table.
      if (vueRef.outsideIn && columns[i].key === 'priority') {
        continue;
      }
      if (
        !vueRef.outsideIn &&
        columns[i].key === 'ordered_revenue_last_30days'
      ) {
        continue;
      }
    }
    const obj = {};
    obj.field = i;
    obj.headerComponentFramework = 'genericTableHeader';
    let keyType = 'string';
    if (columns[i].type === 'numeric') {
      keyType = columns[i].unit ? columns[i].unit : 'numeric';
    }
    if (columns[i].type === 'date') {
      keyType = columns[i].type;
    }
    obj.field = columns[i].key;
    obj.cellRendererParams = {
      keyType: keyType,
      formatterFn: formatter
    };
    obj.cellRendererFramework = 'genericTableCell';
    obj.headerName = columns[i].title;
    obj.title = columns[i].title;
    obj.headerComponentParams = {
      enableSorting: true
    };
    if (columns[i].type === 'asin') {
      obj.cellRendererFramework = 'skuTableComponentWithButton';
      obj.headerComponentParams.enableSorting = false;
      obj.cellRendererParams = columns[i];
      obj.cellRendererParams.hoverTextClickEvent = vueRef.showAsGraph;
      obj.minWidth = 300;
    }
    obj.keyOrder = columns[i].keyOrder;
    columns[i].pinned && (obj.pinned = columns[i].pinned);
    if (columns[i].showToolTip) {
      obj.headerComponentParams.toolTipText = tooltipTexts[columns[i].key];
    }
    obj.headerComponentParams.keyType = keyType;
    _columnDefs.push(obj);
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      filters: {
        hasSearch: false,
        filterState: {
          getter: 'getInsightsPriceWarSelectedFilter',
          setter: 'setInsightsPriceWarSelectedFilter'
        },
        emit: 'pricewarFilterApplied',
        listen: {
          pricewarFilterApplied: {
            action: 'updatePricewarFilters'
          }
        },
        items: {
          left: [
            {
              title: 'Product Group',
              filterValueKey: 'pricewar_product_group',
              smart: true
            },
            {
              title: 'Category',
              subFilterValueKey: 'pricewar_client_subcategory',
              filterValueKey: 'pricewar_client_category',
              twoLevel: true,
              smart: true
            },
            {
              title: 'Brand',
              filterValueKey: 'pricewar_client_brand',
              smart: true
            },
            {
              title: 'Retailer',
              filterValueKey: 'pricewar_comp_name',
              smart: true
            }
          ]
        }
      },
      widgets: {
        widget1: {
          meta: {
            type: 'chart',
            hideOnNoData: true,
            width: 1,
            action: 'getPWIData',
            key: 'PWIData',
            listen: {
              pricewarFilterApplied: {
                action: 'getPWIData'
              },
              pricewarSettingsChanged: {
                action: 'getPWIData'
              }
            }
          },
          header: {
            show: true,
            title: 'NO. OF SKUs UNDER PRICE WAR',
            tooltip:
              'Count of SKUs which are showing symptoms of price war i.e. 2 or more retailers driving down the price in last 7 days. It is determined by a proprietary index called Price War Index (PWI). Revenue displayed is the combined last 4 weeks revenue for all affected SKUs. Last 30 days trend for both metric i.e. SKU count and revenue can also be seen.'
          },
          footer: {
            date: 'price_war'
          },
          body: {
            APIConfig: {
              cubeName: 'price_war_v2',
              measuresList: ['pwi_avg', 'count', 'ordered_revenue'],
              groupByDimensionsList: [],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: true,
              timeseriesEnabled: true,
              pvpenabled: true,
              yoyenabled: false
            },
            getTagData(data, mapping) {
              var oReturn = {};
              for (var i in data.RESULT) {
                if (mapping) {
                  oReturn[mapping[i] || i] = {
                    tag1: data.RESULT[i] || 0,
                    tag2: data.PVP && data.PVP['PVP_' + i]
                  };
                }
              }
              return oReturn;
            },
            tag2Unit: {
              suff: '%'
            },
            chart: {
              chartOptions: {
                type: 'line',
                chartWidth: 's',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  'SKU Count': {
                    roundoff: 0
                  },
                  Revenue: {
                    pre: 'currency'
                  }
                }
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'chart',
            width: 1,
            hideOnNoData: true,
            action: 'getPriceWarCountData',
            key: 'priceWarCountData',
            listen: {
              pricewarFilterApplied: {
                action: 'getPriceWarCountData'
              },
              pricewarSettingsChanged: {
                action: 'getPriceWarCountData'
              }
            }
          },
          header: {
            show: true,
            title: 'RETAILER BREAKDOWN',
            tooltip:
              'SKU count by retailer. This list is dependent on the same SKU being present across retailers and being under price war.'
          },
          footer: {
            date: 'price_war'
          },
          body: {
            APIConfig: {
              cubeName: 'price_war_v2',
              measuresList: ['count', 'ordered_revenue'],
              groupByDimensionsList: ['comp_name'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: true,
              timeseriesEnabled: false,
              pvpenabled: true,
              yoyenabled: false
            },
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date'
              }
            }
          }
        },
        widget3: {
          meta: {
            type: 'table',
            width: 1,
            hideOnNoData: true,
            key: 'priceWarTableData',
            action: 'getPriceWarTableData',
            listen: {
              pricewarFilterApplied: {
                action: 'getPriceWarTableData',
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              },
              pricewarSettingsChanged: {
                action: 'getPriceWarTableData',
                transform: function (config, data, that) {
                  var _config = config;
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'SKUs UNDER PRICE WAR',
            tooltip: '',
            actions: {
              download: {
                fileName: 'Price War',
                action: 'downloadPricewarTableData',
                colMap: [
                  {
                    displayName: 'SKU',
                    dbKey: 'asin'
                  },
                  {
                    displayName: 'SKU displayName',
                    dbKey: 'asin_name'
                  },
                  {
                    displayName: 'SKU product page URL',
                    dbKey: 'asin_hyperlink'
                  },
                  // {
                  //   displayName: 'SKU image URL',
                  //   dbKey: 'client_image_url'
                  // },
                  {
                    displayName: 'Price War Index',
                    dbKey: 'price_war_index_max'
                  },
                  {
                    displayName: 'Revenue',
                    dbKey: 'ordered_revenue_last_30days'
                    // checkOutsideIn: true
                  },
                  {
                    displayName: 'Priority',
                    dbKey: 'priority',
                    checkOutsideIn: false
                  },
                  {
                    displayName: that.getMarketPlace + ' offer price',
                    dbKey: 'client_offer_price'
                  },
                  {
                    displayName: 'Retailer',
                    dbKey: 'comp_name_latest'
                  },
                  {
                    displayName: 'Retailer offer price',
                    dbKey: 'comp_offer_price'
                  }
                ],
                transform: function (config, data, that) {
                  var _config = Vue.util.extend({}, config);
                  _config = JSON.parse(JSON.stringify(_config));
                  _config.body.APIConfig.page = undefined;
                  _config.body.APIConfig.limit = undefined;
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'price_war'
          },
          body: {
            defaultOrderBy: [
              {
                dimension: 'asin',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              cubeName: 'price_war_v2',
              measuresList: [
                'count_total_all',
                'asin_name',
                'asin_hyperlink',
                'client_image_url',
                'ordered_revenue_last_30days',
                'client_offer_price',
                'comp_name_latest',
                'comp_offer_price',
                'price_war_index_max',
                'priority'
              ],
              groupByDimensionsList: ['asin', 'comp_name'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: true,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              limit: 5,
              page: 1,
              orderByList: [
                {
                  dimension: 'price_war_index_max',
                  direction: 'DESC'
                },
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ]
            },
            columnDefs: getColumnDefinition(that, skusUnderPriceWarColumns)
          }
        },
        widget4: {
          meta: {
            type: 'card',
            width: 1,
            key: 'priceWarData',
            action: 'getPriceGraphFiltersData',
            localFilters: [],
            listen: {
              pricewarFilterApplied: {
                action: 'getPriceGraphFiltersData',
                transform: function (config, data) {
                  for (var i = 0; i < config.meta.localFilters.length; i++) {
                    if (
                      config.meta.localFilters[i].dimensionName === 'search'
                    ) {
                      config.meta.localFilters.splice(i, 1);
                    }
                  }
                  delete config.meta.from;
                  return config;
                }
              },
              pricewarAsinSelected: {
                action: 'getPriceGraphData',
                transform: function (config, data, that) {
                  if (data && data.asin) {
                    that.$store.dispatch('setSelectedAsin', data);
                    var found = false;
                    if (!config.meta.localFilters) {
                      config.meta.localFilters = [];
                    }

                    for (var i = 0; i < config.meta.localFilters.length; i++) {
                      if (
                        config.meta.localFilters[i].dimensionName === 'asin'
                      ) {
                        config.meta.localFilters[i].dimensionValue = data.asin;
                        found = true;
                      }
                    }

                    if (!found && data && data.asin) {
                      config.meta.localFilters.push({
                        dimensionName: 'asin',
                        dimensionValue: data.asin
                      });
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'PRICE TREND',
            tooltip:
              'Price trend of selected SKU across multiple retailers for the chosen date range.',
            actions: {
              filter: {
                items: []
              },
              date: {
                action: {
                  setPriceComparisonChartDate: {},
                  getPriceGraphData: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                maxDate: 'pi',
                enableMinDate: Vue.options.filters.config_check(
                  'feature.pricewar.enableMinDate'
                )
              }
            }
          },
          footer: {
            date: 'pi'
          },
          body: {
            APIConfig: {
              cubeName: 'price_war_v2',
              measuresList: [
                'comp_offer_price',
                'comp_url',
                'comp_offer_price_sum'
              ],
              groupByDimensionsList: ['comp_name'],
              timeseriesDimension: 'feed_date',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              pvpenabled: true,
              yoyenabled: false
            },
            chart: {
              chartOptions: {
                disableLegends: ['Lowest 3P Seller', 'Buy box'],
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_mapper: {
                  'Buy box': 'buybox_seller_name_str',
                  'Lowest 3P Seller': 'unkn_seller_name_str'
                },
                regions: {
                  unit_cogs: [
                    {
                      style: 'dashed'
                    }
                  ]
                },
                tooltip_format: {
                  All: {
                    pre: 'currency'
                  }
                },
                grid: 'xy',
                axis_format: {
                  y: {
                    pre: 'currency'
                  }
                }
              },
              xAxisType: 'category',
              stack: [],
              chartGetter: ''
            }
          }
        }
      }
    };
  }
};
