import amazonDspCMConfig from '@/pages/dashboard_service_campaign_management/amazonDsp/config.js';
import citrusAdsCMConfig from '@/pages/dashboard_service_campaign_management/citrusAds/config.js';
import promoteIqCMConfig from '@/pages/dashboard_service_campaign_management/promoteiq/config.js';
import krogerCMConfig from '@/pages/dashboard_service_campaign_management/kroger/config.js';

export default {
  amazonDsp: amazonDspCMConfig,
  albertsons_citrus: citrusAdsCMConfig,
  shipt_citrus: citrusAdsCMConfig,
  hyvee_citrus: citrusAdsCMConfig,
  shoprite_citrus: citrusAdsCMConfig,
  target_citrus: citrusAdsCMConfig,
  ahold_citrus: citrusAdsCMConfig,
  sainsburys_citrus: citrusAdsCMConfig,
  tesco_citrus: citrusAdsCMConfig,
  waitrose_citrus: citrusAdsCMConfig,
  gopuff_citrus: citrusAdsCMConfig,
  kroger_promoteiq: promoteIqCMConfig,
  chewy_promoteiq: promoteIqCMConfig,
  kroger: krogerCMConfig
};
