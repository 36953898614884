<template>
  <div
    v-if="currentLevelData"
    class="u-display-flex u-flex-direction-column u-width-100 u-overflow-auto"
  >
    <slot
      v-if="level > 0"
      name="childLevelSelectorHeader"
      :level="level"
    />
    <div
      class="u-overflow-auto"
      :class="{ 'u-spacing-mt-s': level > 0, 'u-spacing-mb-s': level > 0 }"
    >
      <div
        v-if="getSelectAllText()"
        class="u-cursor-pointer u-spacing-pv-s u-spacing-ph-m u-width-100 u-display-flex u-flex-align-items-center"
        @click="handleBulkSelections"
      >
        <div
          class="u-line-height-1 u-text-overflow-ellipsis u-font-size-5 u-color-blue-base u-font-weight-600"
        >
          {{ getSelectAllText() }}
        </div>
      </div>
      <div>
        <div
          v-for="(item, index) in showData"
          :id="'dropdown-item' + getID(item, index, level)"
          :key="item.text + index"
          v-tippy="{
            placement: 'top-start',
            arrow: false,
            maxWidth: '200px',
            size: 'regular'
          }"
          class="u-cursor-pointer u-spacing-pv-s u-spacing-ph-m"
          :class="{
            'u-bg-color-blue-xx-light':
              getID(item, index, level) === openedTippy,
            'category disabled': item.disabled
          }"
          :title="disabledTooltip(item)"
        >
          <div
            v-if="currentLevelData[item.text]"
            class="u-display-flex u-width-100 u-flex-justify-content-space-between"
            :class="{ 'category disabled': item.disabled }"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-overflow-auto"
              @click="handleClick(item, getID(item, index, level))"
            >
              <rb-icon
                :class="item.iconColorClass"
                class="u-flex-0 rb-icon--medium"
                :icon="item.icon"
              />
              <div
                :title="item.text"
                class="u-font-weight-normal u-spacing-pl-s u-line-height-1 u-text-overflow-ellipsis u-font-size-5 u-color-grey-light"
              >
                {{ item.text }}
              </div>
            </div>
            <div>
              <rb-icon
                v-if="count(currentLevelData[item.text].children).length > 0"
                :id="
                  (item.selected ? 'selected-icon' : 'unselected-icon') +
                  getID(item, index, level)
                "
                v-tippy="tippyOptions(item, index, level)"
                :identifier="getID(item, index, level)"
                icon="arrow-right"
                class="rb-icon--small next-level-trigger u-color-grey-light"
              />
            </div>
          </div>
          <div
            v-if="currentLevelData[item.text]"
            :id="getID(item, index, level)"
            class="u-display-flex u-flex-direction-column child-multi-level-selector"
          >
            <childMultiLevelSelector
              v-if="nextLevel[getID(item, index, level)]"
              :ref="'nextLevel' + getID(item, index, level)"
              :parent-tippy="tippyInstance"
              :root-tippy-id="rootTippyId"
              :parent-tippy-id="getID(item, index, level)"
              :spawn-in-same-direction="spawnInSameDirection"
              :path="path.concat([item.text])"
              :data="currentLevelData[item.text].children"
              :level="level + 1"
              :identifier="item.identifier"
              :multi="currentLevelData[item.text].multi"
              :current-level-data="currentLevelData[item.text].children"
              :root-selection-group="rootSelectionGroup"
              :current-level-selection-group="nextLevelSelectorGroup(item)"
              :selections="item.children"
              @selection="selectionHandler"
              @mountHandle="handleTippyMount"
            >
              <template
                slot="childLevelSelectorHeader"
                slot-scope="{ level }"
              >
                <slot
                  name="childLevelSelectorHeader"
                  :level="level"
                />
              </template>
            </childMultiLevelSelector>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChildMultiLevelSelector',
  props: {
    spawnInSameDirection: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: true
    },
    path: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rootSelectionGroup: {
      type: Object,
      required: true
    },
    currentLevelSelectionGroup: {
      type: Object,
      required: true
    },
    currentLevelData: {
      type: [Object, Array],
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    parentTippy: {
      type: Object,
      default: undefined
    },
    canDisable: {
      type: Boolean,
      default: false
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // rootTippyId is main level 0 tippy id and parentTippyId is parent of current tippy.
    rootTippyId: {
      type: String,
      default: ''
    },
    parentTippyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentClickedElement: null,
      nextLevel: {},
      tempSelections: {},
      tippyInstance: null,
      openedTippy: null,
      openedTippies: {},
      uiState: []
    };
  },
  computed: {
    showData() {
      return this.selections;
    }
  },
  watch: {
    currentLevelSelectionGroup: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.uiState = newVal?.uiState();
        }
      }
    }
  },
  mounted() {
    this.$emit('mountHandle');
    /**
     * showFirstSelection should get called only when mountHandle is finished execution
     * hence using setTimeout so once mountHandle function is completed, this will run in next iteration
     */
    setTimeout(() => {
      if (this.level === 0) {
        this.showFirstSelection();
      }
    });
  },
  methods: {
    showFirstSelection() {
      /**
       * This function will show initial selection on opening of first level it self
       */
      const firstIcon = this.$el.querySelector('[id^="selected-icon"]');
      // this firstIcon points to the first tippy element's click trigger
      if (firstIcon) {
        firstIcon?._tippy?.show();
        setTimeout(() => {
          const refToNextLevel =
            this.$refs['nextLevel' + firstIcon.getAttribute('identifier')][0];
          refToNextLevel.showFirstSelection();
        }, 100);
      }
    },
    selectionHandler(item) {
      this.$emit('selection', item);
    },
    getIcon(item) {
      return this.currentLevelSelectionGroup.selectors[item.text].icon;
    },
    allSelected() {
      return this.currentLevelSelectionGroup.selectAllSelector?.isSelected;
    },
    getSelectAllText() {
      return this.currentLevelSelectionGroup?.selectAllSelector?.id;
    },
    handleBulkSelections() {
      this.currentLevelSelectionGroup.selectAllSelector?.handleClick();
      this.$emit('selection', null);
    },
    disabledTooltip(item) {
      return item.disabled ? item.disabled?.tooltip : null;
    },
    nextLevelSelectorGroup(item) {
      if (this.currentLevelSelectionGroup) {
        return this.currentLevelSelectionGroup.selectors[item.text]
          .childrenGroup;
      } else {
        return null;
      }
    },
    getIconSelectAll() {
      const allSelected =
        this.currentLevelSelectionGroup.selectAllSelector?.isSelected;
      return 'checkbox-' + (allSelected ? 'selected' : 'empty');
    },
    count(val) {
      return Object.keys(val);
    },
    getID(item, index, level) {
      return 'heir' + this._uid + index + level;
    },
    tippyOptions(item, index, level) {
      let id = 'heir' + this._uid + index + level;
      const tippyOptions = {
        html: '#' + id,
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide(item, index, level),
        title: id
      };
      if (this.spawnInSameDirection) {
        tippyOptions.appendTo = () => {
          const parentTippy = document.getElementById(this.rootTippyId);
          if (parentTippy) {
            return parentTippy;
          } else {
            return document.body;
          }
        };
        tippyOptions.livePlacement = false;
      }
      return tippyOptions;
    },
    onShow(tippy) {
      var id = tippy.reference.getAttribute('identifier');
      this.$set(this.nextLevel, id, true);
      this.tippyInstance = tippy;
      setTimeout(() => {
        this.openedTippy = tippy.options.title;
      }, 250);
      Object.keys(this.openedTippies).map((item) => {
        document
          .querySelector('#' + item)
          ?.closest('.tippy-popper')
          ?._tippy?.hide();
      });
      this.openedTippies[id] = true;
    },
    onHide(item, index, level) {
      return (tippy) => {
        this.openedTippy = null;
        var id = tippy.reference.getAttribute('identifier');
        const identifierChildNode =
          'nextLevel' + this.getID(item, index, level);
        const childRef = this.$refs[identifierChildNode][0];
        childRef?.hide();
        delete this.openedTippies[id];
      };
    },
    hide() {
      this.openedTippy = null;
      Object.keys(this.openedTippies).map((item) => {
        document
          .querySelector('#' + item)
          ?.closest('.tippy-popper')
          ?._tippy?.hide();
        delete this.openedTippies[item];
      });
    },
    handleTippyMount() {
      if (this.spawnInSameDirection) {
        /**
         * here we will fetch current transform style and update it to have 0 px away from initial direction
         * currentTransform.m42 gives y transform value
         * then we will set updated transform value to x = 0 and y = current y value
         */
        setTimeout(() => {
          var style = document.createElement('style');
          const currentTransform = new DOMMatrixReadOnly(
            window.getComputedStyle(this.tippyInstance.popper).transform
          );
          style.type = 'text/css';
          const left = 240;
          let transformProperty = null;
          transformProperty =
            'transform: translate3d(' +
            (240 * this.level).toString() +
            'px, ' +
            currentTransform.m42.toString() +
            'px, 0px) !important;';
          style.innerHTML =
            '.cssClass' +
            this._uid +
            ' { left: ' +
            left +
            'px !important; max-width: unset !important;' +
            transformProperty +
            ' }';
          document.getElementsByTagName('head')[0].appendChild(style);
          this.tippyInstance.popper.classList.add('cssClass' + this._uid);
        });
      } else {
        let currentTippyNode = null;
        let nestedTippyNode = null;
        if (!this.parentTippy) {
          currentTippyNode = document.getElementsByClassName(
            'filter-dropdown-content-custom'
          )[0];
        } else {
          currentTippyNode = document.querySelector(
            '#tippy-' + this.parentTippy.id + '> .tippy-tooltip'
          );
        }

        nestedTippyNode = document.querySelector(
          '#tippy-' + this.tippyInstance.id + '> .tippy-tooltip'
        );
        nestedTippyNode.style.visibility = 'hidden';
        setTimeout(() => {
          const currentTippyNodeDimensions =
            currentTippyNode?.getBoundingClientRect();
          const nestedTippyNodeDimensions =
            nestedTippyNode?.getBoundingClientRect();
          this.findDropdownDirection(
            currentTippyNodeDimensions,
            nestedTippyNodeDimensions
          );
          if (currentTippyNodeDimensions && nestedTippyNodeDimensions) {
            let xCords = null;
            if (this.nextDropdownDirection === 'right') {
              xCords =
                currentTippyNodeDimensions.right -
                nestedTippyNodeDimensions.left;
            } else {
              xCords =
                currentTippyNodeDimensions.left -
                nestedTippyNodeDimensions.left -
                nestedTippyNodeDimensions.width;
            }
            nestedTippyNode.style.transform = `translate(${xCords}px)`;
            this.verticalIndent(nestedTippyNode);
            nestedTippyNode.style.visibility = '';
          }
        });
      }
    },
    verticalIndent(nestedTippyNode) {
      let displacement = 0;
      let activeDropdownElement = null;
      const viewPortHeight = document.documentElement.clientHeight;
      if (this.hoverIndexNamespace) {
        activeDropdownElement =
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace] &&
          this.$refs['currentDropdownElements' + this.hoverIndexNamespace][
            this.hoverIndex
          ];
      } else {
        activeDropdownElement =
          this.$refs.currentDropdownElements &&
          this.$refs.currentDropdownElements[this.hoverIndex];
      }
      if (activeDropdownElement) {
        const activeDropdownElementDimensions =
          activeDropdownElement?.getBoundingClientRect();
        const nestedTippyNodeDimensions =
          nestedTippyNode?.getBoundingClientRect();
        if (activeDropdownElementDimensions && nestedTippyNodeDimensions) {
          if (
            activeDropdownElementDimensions.y +
              nestedTippyNodeDimensions.height <=
            viewPortHeight
          ) {
            displacement =
              activeDropdownElementDimensions.y -
              nestedTippyNodeDimensions.y -
              this.VERTICAL_PADDING;
          } else {
            displacement =
              activeDropdownElementDimensions.y +
              activeDropdownElementDimensions.height -
              nestedTippyNodeDimensions.y -
              nestedTippyNodeDimensions.height +
              this.VERTICAL_PADDING;
          }
        }
      }
      nestedTippyNode.style.transform =
        nestedTippyNode.style.transform + `translateY(${displacement}px)`;
    },
    findDropdownDirection(currentTippyDimensions, nestedTippyDimensions) {
      const parentWidth = document
        .getElementsByClassName('filter__wrapper')[0]
        .getBoundingClientRect().width;
      const currentTippyXStart = currentTippyDimensions.x;
      const currentTippyXEnd =
        currentTippyXStart + currentTippyDimensions.width;
      const direction = currentTippyXEnd <= parentWidth ? 'right' : 'left';
      this.nextDropdownDirection = direction;
    },
    handleClick(item, onSelectClick) {
      this.currentLevelSelectionGroup?.selectors[item.text].handleClick();
      document.getElementById('unselected-icon' + onSelectClick)?.click();
      if (!item.children || item.children.length === 0) {
        Object.keys(this.openedTippies).map((item) => {
          document
            .querySelector('#' + item)
            ?.closest('.tippy-popper')
            ?._tippy?.hide();
        });
        this.openedTippy = null;
      }
      this.$emit('selection', item);
    }
  }
};
</script>
<style lang="css" scoped>
.category.disabled {
  opacity: 0.5;
}

.child-multi-level-selector {
  width: 240px;
  max-height: 210px;
}
</style>
