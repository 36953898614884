<template>
  <div class="u-spacing-ml-s sankey-metric">
    <span class="u-display-flex u-flex-align-items-center u-font-size-6">
      <span
        class="u-font-size-5 rb-icon u-spacing-pr-xs"
        :class="`icon-${metricIcon}`"
        :style="`color: ${metricColor}`"
      />
      <span
        v-tippy="metricTooltip ? { placement: 'bottom', arrow: false } : null"
        :title="modifyMessage(metricTooltip)"
      >
        <div
          class="u-spacing-pr-xs u-color-grey-mid-light"
          :class="{
            'u-cursor-pointer rb-link link--asin hover-container':
              downloadableMetric
          }"
          @click="handleMetricClicked()"
        >
          {{ metricName }}
          <rb-icon
            v-if="downloadableMetric"
            icon="download"
          ></rb-icon>
        </div>
      </span>
    </span>
    <div
      class="u-display-flex u-flex-align-items-center u-spacing-mt-s u-spacing-ml-xxs"
    >
      <metric
        size="s"
        :config="metricConfig"
        :data="metricData"
      />
    </div>
    <div
      v-if="nodeValue"
      class="u-font-size-7 u-spacing-mt-s u-spacing-ml-xxs u-cursor-default u-color-grey-lighter"
    >
      <span
        v-tippy="{ placement: 'bottom-start', arrow: false }"
        :title="`${metricPercentage} of ${startNodeName}`"
      >
        {{ metricPercentage }}
      </span>
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric.vue';
import { eventBus } from '@/utils/services/eventBus';

export default {
  components: { metric },
  props: {
    metricIcon: {
      type: String,
      default: 'shortage-invoices'
    },
    metricColor: {
      type: String,
      default: ''
    },
    metricName: {
      type: String,
      default: ''
    },
    metricConfig: {
      type: Object,
      default: () => ({})
    },
    metricData: {
      type: Object,
      default: () => ({})
    },
    nodeValue: {
      type: Number,
      default: 0
    },
    startNodeName: {
      type: String,
      default: ''
    },
    totalValue: {
      type: Number,
      default: 1
    },
    metricTooltip: {
      type: String,
      default: ''
    },
    metricId: {
      type: String,
      default: ''
    },
    downloadableMetric: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    metricPercentage() {
      return `(${((this.nodeValue / this.totalValue) * 100).toFixed(2)}%)`;
    }
  },
  methods: {
    modifyMessage(message) {
      if (!message) {
        return '';
      }
      let messageResult = '';
      const updatedMessage = message.split('\n');
      const updatedMessageLength = updatedMessage.length;
      updatedMessage.forEach((item, index) => {
        if (index !== updatedMessageLength - 1) {
          messageResult = messageResult + item + '<br><br>';
        } else {
          messageResult = messageResult + item;
        }
      });
      return messageResult;
    },
    handleMetricClicked() {
      if (this.downloadableMetric) {
        eventBus.$emit('metric-clicked', this.metricId);
      }
    }
  }
};
</script>

<style lang="css">
.sankey-metric .rb-metric .primary_metric {
  font-size: 2.1rem;
}
.sankey-metric .rb-metric .primary_metric:not(.u-font-weight-600) {
  font-weight: 600;
  color: #8b8f93;
}

.sankey-metric .rb-metric .secondary_metric {
  font-size: 1.1rem;
}

.hover-container:hover .icon {
  color: #007cf6;
}
</style>
