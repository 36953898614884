<template>
  <div>
    <div v-if="displayDarsValue">
      <div
        class="dataBarContainer u-flex-wrap-wrap u-width-100 u-display-flex u-spacing-pb-s"
      >
        <div
          v-for="(bar, index) in displayDarsValue"
          :key="index"
          :class="[{ active: setActiveClass(bar) }]"
        >
          <!-- {{checkRetailerAndAdsLegend(bar,index)}} -->
          <div
            id="tooltipSKUs"
            class="skuList"
          >
            <div
              class="u-spacing-ml-s u-spacing-mt-s u-spacing-mb-m u-color-grey-base u-font-size-5 u-font-weight-600"
            >
              {{ bar.toolTipHeading }}
            </div>
            <div
              v-for="(sku, skuIndex) in bar.values ? bar.values : [bar]"
              :key="skuIndex"
              class="u-spacing-m-s"
            >
              <rb-sku
                :id="'tooltipSKU'"
                :title="sku[barTooltipKey]"
                :asin="sku[asinKey]"
                :image-url="sku[imageUrlKey]"
                :show-image="
                  sku[imageUrlKey] !== undefined && sku[imageUrlKey] !== null
                "
                :product-page-url="sku[asinHyperLinkKey]"
                :enable-s-d-p="enableSDP"
                :rb-asin-tippy-text="rbAsinTippyText"
                :copy-clipboard-text="copyClipboardText"
                @navigateToSDP="navigateToSDP"
              />
            </div>
          </div>
          <div
            v-tippy="{
              html: '#tooltipSKUs',
              theme: 'rb-tooltip-sku',
              placement: 'top',
              interactive: true,
              reactive: true,
              arrow: false
            }"
            class="dataBar u-spacing-mb-xs u-font-size-7 u-display-flex u-spacing-mr-s u-flex-justify-content-center u-flex-align-items-center"
            :class="setColorAndWidth(bar)"
            :style="setStyleColor(bar)"
            @click="emitThisBarClickEvent(bar, index)"
          >
            <!-- <rb-icon v-if="bar.type === retailerKey" class="rb-icon--medium" :icon="'amazon'"></rb-icon> -->
            <span
              v-if="bar.showIcon"
              class="bar-value"
            >
              <div
                v-if="bar.showCurrencyInIcon"
                class="u-position-absolute currency-in-icon z-index-2 u-color-white-base"
              >
                {{ currencySymbol }}
              </div>
              <rb-icon
                :class="[iconSize(bar)]"
                :icon="bar.showIcon"
              />
            </span>
            <span
              v-else-if="bar.showValue"
              class="bar-value"
              :class="bar.type === 'ORGANIC' ? 'organic-value' : ''"
              >{{ bar.showValue }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="legendsValue && sortedLegends"
      class="legendsContainer u-display-flex u-spacing-pt-xs u-font-size-6"
    >
      <div
        class="legend u-display-flex u-spacing-mr-l"
        :class="{ active: (activeClassMapObj[clientName] || {}).active }"
        @click="legendClick(clientName)"
        @mouseover="legendMouseOver(clientName)"
        @mouseleave="legendMouseLeave(clientName)"
      >
        <div
          class="legend__color u-display-flex u-spacing-mr-xs"
          :class="
            'u-bg-color-' + (colorMapObj[clientName] || colorMapObj['Others'])
          "
          :style="{
            'background-color':
              (colorMapObj || {})[clientName] || (colorMapObj || {})['Others']
          }"
        />
        <div
          v-if="legendsValue !== null"
          class="legend__text u-display-flex"
        >
          {{ legendsValue[clientName] }}
        </div>
        <div
          v-if="legendsValue === null"
          class="legend__text u-display-flex"
        >
          {{ clientName }}
        </div>
      </div>
      <div
        v-for="(val, sortedLegendsIndex) in computedSortedLegends"
        :key="sortedLegendsIndex"
        class="legend u-display-flex u-spacing-mr-l"
        :class="{ active: (activeClassMapObj[val[keyToCheck]] || {}).active }"
        @click="legendClick(val[keyToCheck])"
        @mouseover="legendMouseOver(val[keyToCheck])"
        @mouseleave="legendMouseLeave(val[keyToCheck])"
      >
        <div
          class="legend__color u-display-flex u-spacing-mr-xs"
          :class="'u-bg-color-' + colorMapObj[val[keyToCheck]]"
          :style="{
            'background-color': (colorMapObj || {})[val[keyToCheck]]
          }"
        />
        <div
          v-if="legendsValue !== null"
          class="legend__text u-display-flex"
        >
          {{ legendsValue[val[keyToCheck]] }}
        </div>
        <div
          v-if="legendsValue === null"
          class="legend__text u-display-flex"
        >
          {{ val[keyToCheck] }}
        </div>
      </div>

      <div
        v-if="activeClassMapObj['Others'] !== undefined"
        class="legend u-display-flex u-spacing-mr-l"
        :class="{ active: activeClassMapObj['Others'].active }"
        @click="legendClick('Others')"
        @mouseover="legendMouseOver('Others')"
        @mouseleave="legendMouseLeave('Others')"
      >
        <div
          class="legend__color u-display-flex u-spacing-mr-xs"
          :class="'u-bg-color-' + colorMapObj['Others']"
          :style="{
            'background-color': colorMapObj['Others']
          }"
        />
        <div
          v-if="legendsValue !== null"
          class="legend__text u-display-flex"
        >
          {{ legendsValue['Others'] }}
        </div>
        <div
          v-if="legendsValue === null"
          class="legend__text u-display-flex"
        >
          {{ 'Others' }}
        </div>
      </div>

      <!-- eslint-disable -->
      <div
        v-if="customLegends === true && showAdsLegendsValue.showRetailer"
        class="legend u-display-flex u-spacing-mr-l"
        :class="{ active: activeClassMapObj[this.retailerKey].active }"
        @click="legendClick(retailerKey)"
        @mouseover="legendMouseOver(retailerKey)"
        @mouseleave="legendMouseLeave(retailerKey)"
      >
        <div
          class="legend__color u-display-flex u-spacing-mr-xs"
          :class="'u-bg-color-' + colorMapObj[this.retailerKey]"
          :style="{
            'background-color': (colorMapObj || {})[this.retailerKey]
          }"
        />
        <!-- eslint-enable -->
        <div class="legend__text u-display-flex">Amazon Brands</div>
      </div>
      <div
        v-if="customLegends === true && showAdsLegendsValue.showAds"
        class="u-display-flex u-spacing-mr-l"
      >
        <div
          v-if="colorMapObj['Ads']"
          class="legend__color u-display-flex u-spacing-mr-xs"
          :class="'u-bg-color-' + colorMapObj['Ads']"
          :style="{
            'background-color': (colorMapObj || {})['Ads']
          }"
        />
        <div class="legend__text u-display-flex u-spacing-mr-m">
          <div class="u-spacing-mr-xs u-color-grey-lighter">
            {{ currencySymbol }}
          </div>
          AD
        </div>
        <div class="u-display-flex">
          <div class="u-position-relative u-spacing-mr-xxs">
            <div
              class="u-position-absolute z-index-2 u-color-grey-white u-font-size-7 sponsored-ad-static-legend-currenct-in-icon"
            >
              {{ currencySymbol }}
            </div>
            <rb-icon
              class="rb-icon--x-medium u-color-grey-lighter u-position-absolute sponsored-ad-static-legend-icon"
              icon="videocam"
            />
          </div>
          <div class="u-spacing-pl-l">Sponsored Brand Video AD</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  name: 'DataBars',
  props: {
    dataBarsLimit: {
      type: Number,
      default: 42
      // not sure what the reasoning of this number is, migrating from hardcoded to prop based value
    },
    rbAsinTippyText: {
      type: String,
      default: undefined
    },
    enableSDP: {
      type: Boolean,
      default: true
    },
    customColorObj: {
      type: Object,
      default: null
    },
    asinHyperLinkKey: {
      type: String,
      default: 'asin_hyperlink'
    },
    imageUrlKey: {
      type: String,
      default: 'image_url'
    },
    asinKey: {
      type: String,
      default: 'asin'
    },
    externalColorsMap: {
      type: Object,
      default: null
    },
    barsValue: {
      type: Array,
      default: () => []
    },
    barTooltipKey: {
      type: String,
      default: ''
    },
    legendsValue: {
      type: Object,
      default: null
    },
    rankKey: {
      type: String,
      default: ''
    },
    customLegends: {
      type: Boolean,
      default: false
    },
    retailerKey: {
      type: String,
      default: 'RETAILER_BRANDED'
    },
    headLineKey: {
      type: String,
      default: 'HEADER'
    },
    retailerBrandName: {
      type: String,
      default: 'Amazon'
    },
    clientName: String,
    keyToCheck: String,
    sortedLegends: {
      type: Array,
      default: () => []
    },
    copyClipboardText: {
      type: String,
      default: 'ASIN copied to clipboard'
    }
  },
  data() {
    return {
      colorMapObj: {},
      activeClassMapObj: {},
      clientArray: [],
      clientArrayObj: {},
      ads: [this.headLineKey, 'Sponsored'],
      adsFlag: false,
      retailerFlag: false,
      showAdsLegends: false,
      showRetailerLengend: false,
      organicSearchRank: 0
    };
  },
  computed: {
    computedSortedLegends() {
      return this.sortedLegends.filter(
        (val) =>
          val[this.keyToCheck] !== this.clientName &&
          (val[this.keyToCheck] || '').toUpperCase() !== 'OTHERS' &&
          (val[this.keyToCheck] || '').toUpperCase() !== this.retailerKey &&
          (val[this.keyToCheck] || '').toUpperCase() !== 'ADS' &&
          this.colorMapObj.hasOwnProperty(val[this.keyToCheck])
      );
    },
    isClickable() {
      return !this.separator && !this.disabled;
    },
    showAdsLegendsValue() {
      return this.checkRetailerAndAdsLegend();
    },
    displayDarsValue() {
      if (!this.barsValue) {
        return null;
      }
      if (this.dataBarsLimit) {
        return this.barsValue.slice(0, this.dataBarsLimit);
      }
      return this.barsValue;
    }
  },
  watch: {
    customColorObj() {
      this.colorMap();
    },
    barsValue: function (val) {
      this.checkForBarsValue();
      this.checkRetailerAndAdsLegend();
    },
    legendsValue: function (val) {
      this.checkForLegendsValue();
    }
  },
  created() {
    // this.colorMap();
    this.currencySymbol = this.$currency;
    this.checkForBarsValue();
    this.checkForLegendsValue();
  },
  methods: {
    iconSize(bar) {
      // setting medium as default size.
      let iconSize = 'medium';
      if (bar?.iconSize) {
        iconSize = bar.iconSize;
      }
      return 'rb-icon--' + iconSize;
    },
    // converting watch code in function written for new data-bar changes
    checkForBarsValue() {
      if (!this.customLegends) {
        for (var obj of this.barsValue) {
          if (obj.type === this.retailerKey) {
            this.retailerFlag = true;
            continue;
          } else if (this.ads.indexOf(obj.type) > -1) {
            this.adsFlag = true;
          }
          if (obj.values && obj.values.length > 0) {
            if (
              obj.values[0][this.keyToCheck] !== this.clientName &&
              !this.clientArrayObj.hasOwnProperty(
                obj.values[0][this.keyToCheck]
              )
            ) {
              var client = obj.values[0][this.keyToCheck];
              if (this.legendsValue.hasOwnProperty(client)) {
                this.clientArray.push(client);
              }
              this.clientArrayObj[obj.values[0][this.keyToCheck]] = {
                type: obj.type
              };
            }
          }
        }
        if (this.legendsValue.hasOwnProperty('Others') === true) {
          this.clientArray.push('Others');
        }
        this.colorMap();
      }
    },
    checkRetailerAndAdsLegend() {
      for (let i = 0; i < this.barsValue.length; i++) {
        if (
          this.barsValue[i].type === 'HEADER' ||
          this.barsValue[i].type === 'SPONSORED'
        ) {
          this.showAdsLegends = true;
        } else if (this.barsValue[i].type === this.retailerKey) {
          this.showRetailerLengend = true;
        } else if (this.showAdsLegends && this.showRetailerLengend) {
          break;
        }
      }
      return {
        showAds: this.showAdsLegends,
        showRetailer: this.showRetailerLengend
      };
    },
    checkForLegendsValue() {
      if (this.customLegends) {
        for (var key in this.legendsValue) {
          if (key !== this.clientName && this.clientArray.indexOf(key) < 0) {
            this.clientArray.push(key);
          }
        }
        this.colorMap();
      }
    },
    competitorList() {
      var arrayToReturn = [];
      if (this.customLegends === false) {
        for (var obj of this.barsValue) {
          if (
            obj[this.keyToCheck] !== this.clientName &&
            arrayToReturn.indexOf(obj[this.keyToCheck]) < 0
          ) {
            arrayToReturn.push(obj[this.keyToCheck]);
          }
        }
      } else {
        for (var key in this.legendsValue) {
          if (key !== this.clientName && arrayToReturn.indexOf(key) < 0) {
            arrayToReturn.push(key);
          }
        }
      }
      return arrayToReturn;
    },

    colorMap() {
      var colorArray = [
        'green-light',
        'pink-base',
        'orange-base',
        'purple-base',
        'red-base',
        'grey-light'
      ];
      var clientArray = this.clientArray;
      var colorObj = {};
      var activeMapObj = {};
      colorObj[this.clientName] = 'cyan-base';
      activeMapObj[this.clientName] = {
        active: true,
        click: true
      };

      var tempActiveMapObj = {};

      if (this.retailerFlag) {
        colorObj[this.retailerBrandName] = 'orange-base';
        tempActiveMapObj[this.retailerBrandName] = {
          active: false,
          click: false
        };
        colorArray.splice(colorArray.indexOf('orange-base'), 1);
      }

      var colorObjLength = Object.keys(colorObj).length;
      if (this.customLegends === true) {
        colorObj[this.retailerKey] = 'orange-base';
        tempActiveMapObj[this.retailerKey] = {
          active: false,
          click: false
        };
        colorObjLength = Object.keys(colorObj).length - 1;
        colorArray.splice(colorArray.indexOf('orange-base'), 1);
      }
      for (let i = 0; i < clientArray.length; i++) {
        if (colorObj.hasOwnProperty(clientArray[i])) {
          continue;
        }
        if (
          colorObjLength === colorArray.length + (this.retailerFlag ? 1 : 0) &&
          clientArray.length - colorArray.length >= 1
        ) {
          colorObj.Others = colorArray[i];
          activeMapObj.Others = {
            active: false,
            click: false
          };
          break;
        }
        colorObj[clientArray[i]] = colorArray[i];
        activeMapObj[clientArray[i]] = {
          active: false,
          click: false
        };
      }
      // show others only if all colors are exhausted otherwise dont
      if (
        colorObj.hasOwnProperty('Others') &&
        colorObj.Others !== colorArray[colorArray.length - 1]
      ) {
        colorObj.Others = colorArray[colorArray.length - 1];
        activeMapObj.Others = {
          active: false,
          click: false
        };
      }
      if (this.customColorObj) {
        this.colorMapObj = { ...colorObj, ...this.customColorObj };
      } else {
        this.colorMapObj = colorObj;
      }
      this.activeClassMapObj = Object.assign(activeMapObj, tempActiveMapObj);
    },

    getClientClassName(client) {
      client = client.replace(/[.\s]/g, '_');
      return client + '_Selector';
    },

    legendMouseOver(client) {
      this.activeClassMapObj[client].active = true;
    },

    legendMouseLeave(client) {
      if (this.activeClassMapObj[client].click === false) {
        this.activeClassMapObj[client].active = false;
      }
    },

    legendClick(client) {
      this.activeClassMapObj[client].click =
        !this.activeClassMapObj[client].click;
    },

    emitThisBarClickEvent(item, index) {
      var emitObj = {
        barDetails: item,
        index: index,
        barsArray: this.barsValue
      };
      this.$emit('bar-click-event', emitObj);
    },
    databarValue(bar, _index) {
      if (bar.hasOwnProperty('type')) {
        if (this.ads.indexOf(bar.type) > -1) {
          return '$';
        } else if (bar.type === this.retailerKey) {
          return '@';
        } else if (bar.type === 'Organic') {
          if (
            bar.values[0][this.rankKey] &&
            bar.values[0][this.rankKey] !== ''
          ) {
            return bar.values[0][this.rankKey];
          } else {
            return _index + 1;
          }
        }
      } else {
        if (bar[this.rankKey] && bar[this.rankKey] !== '') {
          return bar[this.rankKey];
        } else {
          return _index + 1;
        }
      }
    },
    databarOrganicValue(bar, index) {
      if (index === 0) {
        this.organicSearchRank = 0;
      }
      if (bar.type === 'ORGANIC') {
        this.organicSearchRank++;
        return this.organicSearchRank;
      }
    },
    setActiveClass(bar) {
      if (bar.type) {
        var returnValue = false;
        var returnValue1 = false;
        if (
          bar.type === this.retailerKey &&
          this.activeClassMapObj[this.retailerKey]
        ) {
          returnValue = this.activeClassMapObj[this.retailerKey].active;
        } else if (this.activeClassMapObj[bar.values[0][this.keyToCheck]]) {
          returnValue =
            this.activeClassMapObj[bar.values[0][this.keyToCheck]].active;
        } else {
          returnValue = (this.activeClassMapObj.Others || {}).active;
        }

        if (this.activeClassMapObj.Ads) {
          if (
            this.activeClassMapObj.Ads.active &&
            this.ads.indexOf(bar.type) > -1
          ) {
            returnValue1 = true;
          }
        }
        return returnValue || returnValue1;
      } else {
        if (this.activeClassMapObj[bar[this.keyToCheck]]) {
          return this.activeClassMapObj[bar[this.keyToCheck]].active;
        } else if (this.activeClassMapObj.Others) {
          return this.activeClassMapObj.Others.active;
        } else {
          return false;
        }
      }
    },
    getBgColorAndColor(bar) {
      let bgColor = null;
      let color = null;
      if (bar.type) {
        if (bar.type === this.retailerKey) {
          bgColor = this.colorMapObj[this.retailerKey];
          color = this.colorMapObj[this.retailerKey];
        } else if (this.colorMapObj[bar.values[0][this.keyToCheck]]) {
          bgColor = this.colorMapObj[bar.values[0][this.keyToCheck]];
          color = this.colorMapObj[bar.values[0][this.keyToCheck]];
        }
      } else {
        bgColor =
          this.colorMapObj[bar[this.keyToCheck]] || this.colorMapObj.Others;
        color =
          this.colorMapObj[bar[this.keyToCheck]] || this.colorMapObj.Others;
      }
      return { bgColor, color };
    },
    setStyleColor(bar) {
      const { bgColor, color } = this.getBgColorAndColor(bar);
      if (bar.type) {
        return {
          'background-color': bgColor || this.colorMapObj.Others
        };
      } else {
        return {
          'background-color': bgColor,
          color: color
        };
      }
    },
    setColorAndWidth(bar) {
      // var bgColor;
      // var color;
      let widthClass = ' dataBar-medium-wdith';
      if (bar.extendBarWidth) {
        widthClass = ' dataBar-large-width';
      }
      const { bgColor, color } = this.getBgColorAndColor(bar);
      if (bar.type) {
        if (bar.type === this.retailerKey || bar.type === this.headLineKey) {
          return (
            'u-bg-color-' +
            (bgColor || this.colorMapObj.Others) +
            ' ' +
            'u-spacing-ph-l' +
            widthClass
          );
        } else {
          return (
            'u-bg-color-' + (bgColor || this.colorMapObj.Others) + widthClass
          );
        }
      } else {
        return 'u-bg-color-' + bgColor + ' ' + 'u-color-' + color + widthClass;
      }
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style lang="css">
.dataBarContainer {
  flex-wrap: wrap;
}
.dataBar-large-width {
  width: 48px;
}
.dataBar-medium-wdith {
  width: 16px;
}

.currency-in-icon {
  left: 18px !important;
  top: 10px !important;
}
.sponsored-ad-static-legend-currenct-in-icon {
  left: 6px !important;
}
.sponsored-ad-static-legend-icon {
  top: -6px !important;
}

.dataBar {
  height: 32px;
  border-radius: 2px;
  cursor: default;
  position: relative;
  background-color: #6a7075;
}

.dataBar:hover {
  opacity: 1;
  color: #fff !important;
}

.dataBar:hover .currency-in-icon {
  color: #6a7075 !important;
}

.dataBar:hover .bar-value {
  background-color: transparent;
}

.dataBar .bar-value {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.dataBar .organic-value {
  color: transparent;
}

.dataBar .organic-value:hover {
  color: #fff;
}

.active .dataBar {
  opacity: 1;
  color: #fff !important;
}
.active .dataBar span {
  background-color: transparent;
  color: #fff;
}
.active .dataBar span .currency-in-icon {
  color: #6a7075 !important;
}

.legend {
  flex-direction: row;
  opacity: 0.55;
  cursor: pointer;
}
.legend:hover {
  opacity: 1;
}

.legend.active {
  opacity: 1;
}

.legend__color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.skuList {
  min-height: auto;
  max-height: 200px;
  max-width: 700px;
  overflow: auto;
}
</style>
