<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-pl-s u-spacing-p-m"
  >
    <p
      class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s"
    >
      SKUs THAT VIOLATED MAP
    </p>
    <div class="u-display-flex u-spacing-mt-m">
      <div
        class="u-spacing-mt-m u-border-right u-border-width-s u-border-color-grey-xxx-light u-spacing-pr-m"
      >
        <p
          class="u-font-size-5 u-color-grey-lighter u-font-weight-600 u-spacing-pb-s"
        >
          Total SKUs under violation
        </p>
        <metric
          :hide-zero="false"
          size="l"
          :config="metricConfig"
          :data="metricData"
        />
        <div
          class="u-font-size-7 u-spacing-mt-s u-width-100px u-bg-color-blue-xx-light u-spacing-pv-xs u-spacing-ph-xs u-font-weight-600 u-border-radius-xl u-text-align-center"
        >
          <rb-icon
            icon="lock"
            class="u-color-blue-base u-spacing-pr-xs"
          />
          <span>Unlock Impact</span>
        </div>
      </div>
      <donut-chart-with-legend
        :chart-data="violationChartData"
        :legends="violationLegends"
        :donut-colors="skuViolationColors"
        :total-skus="skuViolationMetric.total"
      />
    </div>
    <section>
      <retailer-breakdown
        id="reasonOfVoilation"
        :bar-data="retailerBreakDownData"
        :group-colors="retailerBreakDownColors"
        :categories="retailerBreakDownCategories"
        :groups="retailerBreakDownGroups"
      />
    </section>
  </div>
</template>

<script>
import metric from '@/components/basic/metric.vue';
import retailerBreakdown from '@/pages/all_retailers/dso/imap/retailerBreakdown.vue';
import donutChartWithLegend from '@/pages/all_retailers/dso/molecules/donutChartWithLegend.vue';

export default {
  name: 'ImapViolation',
  components: {
    metric,
    retailerBreakdown,
    donutChartWithLegend
  },
  data() {
    return {
      metricConfig: {
        invertTag2: true,
        tag1Unit: { suff: 'SKUs', roundoff: 1 },
        tag2Unit: { suff: '%', roundoff: 1 }
      },
      metricData: { tag1: 20, tag2: 5 },
      skuViolationMetric: {
        total: 100,
        latest: 5,
        existing: 15,
        noViolation: 80
      },
      skuViolationColors: {
        noViolation: '#538FF5',
        latest: '#80CEA4',
        existing: '#F7CE48'
      },
      retailerBreakDownData: [
        ['noViolation', 80, 85, 90, 80],
        ['latest', 5, 10, 0, 15],
        ['existing', 15, 5, 10, 0]
      ],
      retailerBreakDownColors: {
        noViolation: '#538FF5',
        latest: '#80CEA4',
        existing: '#F7CE48'
      },
      retailerBreakDownCategories: ['Amazon', 'Walmart', 'Target', 'Best Buy'],
      retailerBreakDownGroups: [['noViolation', 'latest', 'existing']]
    };
  },
  computed: {
    violationChartData() {
      return [
        ['noViolation', this.skuViolationMetric.noViolation],
        ['latest', this.skuViolationMetric.latest],
        ['existing', this.skuViolationMetric.existing]
      ];
    },
    violationLegends() {
      return [
        {
          label: 'No Violation',
          value: this.skuViolationMetric.noViolation,
          color: '#538FF5'
        },
        {
          label: 'Latest',
          value: this.skuViolationMetric.latest,
          color: '#80CEA4'
        },
        {
          label: 'Existing',
          value: this.skuViolationMetric.existing,
          color: '#F7CE48'
        }
      ];
    }
  }
};
</script>
