<template>
  <modal
    v-if="showEmailModal"
    :modal-config="modalConfig"
  >
    <template slot="body">
      <div class="email-container u-spacing-pv-l">
        <p class="u-font-size-3 u-font-weight-600 u-text-align-center">
          Send an email to raise ticket
        </p>
        <p class="u-font-size-4 u-color-grey-light u-spacing-mt-l">
          To: <span class="link">{{ emailDetails.to }}</span>
        </p>
        <p class="u-font-size-4 u-color-grey-light u-spacing-mt-s">
          From: <span class="link">{{ emailDetails.from }}</span>
        </p>
        <p
          v-if="emailDetails.body"
          class="u-font-size-4 u-color-grey-light u-font-weight-600 u-spacing-mt-l"
        >
          {{ emailDetails.body }}
        </p>
        <slot
          v-else
          name="body"
        />
        <div
          class="u-display-flex u-flex-justify-content-flex-end u-spacing-mt-xl"
        >
          <rb-button
            text="Cancel"
            type="hollow"
            class="u-color-grey-white u-spacing-mt-m u-spacing-mr-m u-bg-color-grey-x-light"
            :click-fn="cancelRaiseTicket.bind(this)"
          />
          <rb-button
            text="Send"
            type="filled"
            class="u-color-grey-white u-spacing-mt-m"
            :click-fn="raiseTicket.bind(this)"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import modal from '@/components/widgets/modal.vue';
export default {
  name: 'Email',
  components: {
    modal
  },
  props: {
    showEmailModal: {
      type: Boolean,
      default: false
    },
    emailDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalConfig: {
        footer: {}
      }
    };
  },
  computed: {
    showEmail() {
      return this.showEmailModal;
    }
  },
  methods: {
    raiseTicket() {
      this.$emit('onSendEamil');
    },
    cancelRaiseTicket() {
      this.$emit('onCancelEmail');
    }
  }
};
</script>

<style scoped>
.email-container {
  line-height: 2rem;
}
</style>
