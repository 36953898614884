const catalogColumns = [
  {
    displayName: 'Parent ASIN',
    dbKey: 'parent_asin',
    keyType: 'parent_asin',
    group: 'Catalog',
    cIndex: 2,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      'Different product variants have a common Parent ASIN. The Parent ASIN represents the generic overall product (non-purchasable) only for aggregation and reporting purposes. The parent ASIN may not have titles most of the times. Landing on a parent ASIN url on Amazon redirects to the default child variant.'
  },
  {
    displayName: 'Parent ASIN TITLE',
    dbKey: 'parent_asin_title',
    show: false,
    group: 'Catalog',
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: '',
    disabled: true
  },
  {
    displayName: 'Parent ASIN URL',
    dbKey: 'parent_asin_product_url',
    show: false,
    group: 'Catalog',
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: '',
    disabled: true
  },
  {
    displayName: 'External ID',
    dbKey: 'external_id',
    keyType: 'number',
    group: 'Catalog',
    cIndex: 3,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: "Product's External ID as per Amazon Vendor Central."
  },
  {
    displayName: 'ID Type',
    dbKey: 'id_type',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 4,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: "Product's external ID type as per Amazon Vendor Central."
  },
  {
    displayName: 'Model number',
    dbKey: 'model_number',
    keyType: 'number',
    group: 'Catalog',
    cIndex: 5,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's model number as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Release Date',
    dbKey: 'released',
    keyType: 'date',
    group: 'Catalog',
    cIndex: 6,
    formatValue: 'formattable_date',
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's release date as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Product Group',
    dbKey: 'product_group',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 7,
    formatValue: false,
    prefix: 'currency',
    suffix: '%',
    toolTipText:
      "ASIN's Product Group as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Category',
    dbKey: 'category',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 8,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Category as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Subcategory',
    dbKey: 'subcategory',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 9,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Subcategory as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Brand Code',
    dbKey: 'brand_code',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 10,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Brand Code as per Catalog Listing Report in Amazon Vendor Central"
  },
  {
    displayName: 'Brand',
    dbKey: 'brand',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 11,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: "SKU's Brand as per Amazon Venor Central"
  },
  {
    displayName: 'Catalog Number',
    dbKey: 'catalog_number',
    group: 'Catalog',
    cIndex: 12,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Catalog Number as per Catalog Listing Report in Amazon Vendor Central",
    disabled: true
  },
  {
    displayName: 'Replenishment Code',
    dbKey: 'replenishment_code',
    group: 'Catalog',
    cIndex: 13,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's latest Replenishment Code as per Catalog Listing Report in Amazon Vendor Central",
    disabled: true
  },
  {
    displayName: 'Page View Rank',
    dbKey: 'page_view_rank',
    group: 'Catalog',
    cIndex: 14,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's latest Page View Rank as per Catalog Listing Report in Amazon Vendor Central",
    disabled: true
  },
  {
    displayName: 'Style Number',
    dbKey: 'style_number',
    keyType: 'number',
    group: 'Catalog',
    cIndex: 15,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Style number as per Sales and Inventory Product Details Report in Amazon Vendor Central"
  },
  {
    displayName: 'Color',
    dbKey: 'color',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 16,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "ASIN's Color as per Sales and Inventory Product Details Report Amazon Vendor Central"
  },
  {
    displayName: 'Color Count',
    dbKey: 'color_count',
    keyType: 'number',
    group: 'Catalog',
    cIndex: 17,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    width: 120,
    toolTipText:
      "ASIN's Catalog Number as per Sales and Inventory Product Details Report in Amazon Vendor Central",
    textAlign: 'right'
  },
  {
    displayName: 'Manufacturer Code',
    dbKey: 'manufacturer_code',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 17,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    width: 120,
    toolTipText: 'Amazon manufacturer code for the owning manufacturer'
  },
  {
    displayName: "Parent's Manufacturer Code",
    dbKey: 'parent_manufacturer_code',
    keyType: 'string',
    group: 'Catalog',
    cIndex: 17,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    width: 120,
    toolTipText: 'Amazon parent manufacturer code for the owning manufacturer'
  }
];

const priceAndPromotionsColumns = [
  {
    displayName: 'ASP',
    dbKey: 'avg_offer_price',
    group: 'Price & Promotions',
    cIndex: 2,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    width: 120,
    toolTipText:
      "Average Buybox offer price during the selected date range as per CommerceIQ's Market Intelligence data.  "
  },
  {
    displayName: 'List Price',
    dbKey: 'avc_list_price',
    group: 'Price & Promotions',
    cIndex: 2,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    width: 120,
    toolTipText:
      "Manufacturer's Suggested Retail Price (MSRP) / List price as per Amazon Vendor Central data"
  },
  {
    displayName: 'Current Offer Price',
    dbKey: 'latest_offer_price',
    group: 'Price & Promotions',
    cIndex: 3,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Buybox offer price during the selected date range as per CommerceIQ's Market Intelligence data."
  },
  {
    displayName: 'Offer Price change',
    dbKey: 'offer_price_change',
    group: 'Price & Promotions',
    cIndex: 4,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Change in Buybox offer price from previously available value as per CommerceIQ's Market Intelligence data. "
  },
  {
    displayName: 'SnS Price',
    dbKey: 'subscribe_and_save_price',
    group: 'Price & Promotions',
    cIndex: 5,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Subscribe and Save Price as per CommerceIQ's Market Intelligence data. "
  },
  {
    displayName: 'Promo Type',
    dbKey: 'promotion_types',
    group: 'Price & Promotions',
    cIndex: 6,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "Promotion Type active on the SKU as per latest CommerceIQ's Market Intellience data . VPC = Vendor Powered Coupon and DOTD = Deal of the Day"
  },
  {
    displayName: 'Promo Value',
    dbKey: 'promotion_messages',
    group: 'Price & Promotions',
    cIndex: 7,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      'Promotion value as seen on the product detail page as per the latest Market Intelligence data.'
  },
  {
    displayName: 'Price Elasticity',
    dbKey: 'ds_elasticity_value',
    group: 'Price & Promotions',
    cIndex: 8,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Fraction change in quantity demanded w.r.t fraction change in price. Price elasticity value is based on demand and price in the past. A value of "NA" is for SKUs which haven\'t shown enough price changes to calculate elasticity value.'
  },
  {
    displayName: 'Price Elasticity Confidence Band',
    dbKey: 'ds_elasticity_bucket',
    group: 'Price & Promotions',
    cIndex: 9,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      'Confidence in price elasticity value. A "High" values means the SKU has provided us enough data points to calculate robust elasticity. A value of "NA" is for SKUs which haven\'t shown enough price changes to calculate elasticity value.'
  }
];

const salesAndMarginColumns = [
  {
    displayName: 'Glance Views',
    dbKey: 'ds_glance_views',
    group: 'Sales & Margin',
    cIndex: 2,
    formatValue: 'numeric',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Total number of glance views received in the chosen time period derived from ARA'
  },
  {
    displayName: 'Ordered Units',
    dbKey: 'customer_orders',
    group: 'Sales & Margin',
    cIndex: 2,
    formatValue: 'numeric',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Number of units ordered in the selected date range as per ARA Premium. In case of unavailability, sales and inventory product details report in ARA Basic is used'
  },
  {
    displayName: 'Unit Conversion %',
    dbKey: 'conversion_percentile',
    group: 'Sales & Margin',
    cIndex: 3,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Calculated as (Ordered Units/Glance Views). The days when the ASIN has 0 glance views are not considered while calculating unit conversion %'
  },
  {
    displayName: 'Unit COGS',
    dbKey: 'unit_cogs',
    group: 'Sales & Margin',
    cIndex: 3,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Units COGS for this ASIN based on shipped COGS and shipped units in selected date range as per ARA Basic'
  },
  {
    displayName: 'Gross Unit Margin',
    dbKey: 'gross_unit_margin',
    group: 'Sales & Margin',
    cIndex: 4,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Difference Between Average selling Price  and Unit COGS during selected date range '
  },
  {
    displayName: 'Gross Unit Margin %age',
    dbKey: 'gross_unit_margin_perc',
    group: 'Sales & Margin',
    cIndex: 5,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Calculated as (Average Selling Price - Unit COGS)/Average Selling Price'
  },
  {
    displayName: 'Shipped Units',
    dbKey: 'units_shipped',
    group: 'Sales & Margin',
    formatValue: 'numeric',
    cIndex: 6,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The total number of units that were shipped in the selected time period as per AVC.'
  },
  {
    displayName: 'Shipped COGS',
    dbKey: 'shipped_cogs',
    group: 'Sales & Margin',
    cIndex: 6,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Shows COGs for the items shipped within the selected time period. These values are extracted from AVC, from the shipped COGS report under sales diagnostics from Brand analytics.'
  },
  {
    displayName: 'Shipped Revenue',
    dbKey: 'shipped_revenue',
    group: 'Sales & Margin',
    cIndex: 7,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Shows the revenue against all the items that are shipped in the selected time period. These values are extracted from AVC, from the shipped revenue report under sales diagnostics from Brand analytics.'
  },
  {
    displayName: 'Ordered Revenue',
    dbKey: 'ordered_revenue',
    group: 'Sales & Margin',
    cIndex: 8,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Ordered Revenue from Sales Diagnostics Report of ARA Premium'
  },
  {
    displayName: 'No. of Days Suppressed',
    dbKey: 'suppressed_days',
    group: 'Sales & Margin',
    cIndex: 9,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Number of days the SKU was suppressed in the selected date range.'
  },
  {
    displayName: 'No. of Days OOS',
    dbKey: 'oos_days',
    group: 'Sales & Margin',
    cIndex: 11,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Number of days the SKU was OOS in the selected date range.'
  },
  {
    displayName: 'Gross Shipped Margin %',
    dbKey: 'gross_shipped_margin_perc',
    group: 'Sales & Margin',
    cIndex: 13,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Calculated as (Shipped Revenue - Shipped COGS)/Shipped Revenue'
  },
  {
    displayName: 'Gross ordered Margin %',
    dbKey: 'gross_ordered_margin_perc',
    group: 'Sales & Margin',
    cIndex: 14,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Calculated as (Ordered Revenue - Ordered COGS)/Ordered Revenue'
  },
  {
    displayName: 'Net PPM (Weekly)',
    dbKey: 'weekly_net_ppm',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Net PPM Weekly ( {1} - {2} )',
    group: 'Sales & Margin',
    width: 300,
    cIndex: 15,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'center',
    toolTipText:
      'Net Pure Profit margin (PPM) for the previous week as reported by AVC. '
  },
  {
    displayName: 'Net PPM Weekly (Start Date)',
    dbKey: 'net_ppm_week_start_date',
    group: 'Sales & Margin',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Net PPM Weekly (End Date)',
    dbKey: 'net_ppm_week_end_date',
    group: 'Sales & Margin',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Net PPM (Monthly)',
    dbKey: 'monthly_net_ppm',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_month_label',
    headerTransformText: 'Net PPM ( {1} )',
    group: 'Sales & Margin',
    cIndex: 16,
    formatValue: 'number',
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Net Pure Profit margin (PPM) for the previous month as reported by AVC.  '
  },
  {
    displayName: 'Net PPM (Calculated Month)',
    dbKey: 'net_ppm_month_label',
    group: 'Sales & Margin',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Contribution Margin % (Weekly)',
    dbKey: 'contribution_margin_percentage',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Contribution Margin % ( {1} - {2} )',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Contribution Margin (CM) is Amazon’s net profitability on your products after including Co-op agreements, allowances, Advertising spend, fulfillment and storage costs. Calculation of CM uses the daily Net PPM data from AVC as an input and inventory at weekly level. Hence it is computed at a weekly cadence. CM is defined as the total Contribution Profit expressed as a percentage of total Shipped Revenue.',
    width: 150
  },
  {
    displayName: 'Contribution Profit (Weekly)',
    dbKey: 'contribution_profit',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Contribution Profit ( {1} - {2} )',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Contribution Profit (CP) is Amazon’s net profit on your products after including Co-op agreements, allowances, Advertising spend, fulfillment and storage costs. It is defined at an SKU level as (ASP * Net PPM %) - fulfillment and storage cost + Ad spent) times the total Shipped Units. CP across the catalog is computed by aggregating Contribution Profit across all ASINs. Calculation of CP uses the daily Net PPM data from AVC as an input and weekly inventory data and hence is computed at a weekly cadence.',
    width: 150
  },
  {
    displayName: 'Net PPPU (Weekly)',
    dbKey: 'net_pppu',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Net PPPU ( {1} - {2} )',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Net Pure Profit is the profit before accounting for Advertising spend, fulfillment and storage costs. Calculation of Net PPPU uses the daily Net PPM data from AVC and Average selling Price of SKU at weekly level as an input and hence is computed at a weekly cadence. It is defined at an ASIN level as ASP * Net PPM%.'
  },
  {
    displayName: 'Subscribe and Save Sales',
    dbKey: 'sns_net_sales',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Total sales made via Subscribe and Save purchase. SnS data is available on Vendor Central lags by a month, hence the data would only be available for the prior months.'
  },
  {
    displayName: 'Subscribe and Save Sales %',
    dbKey: 'percentage_sns_sales',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Percentage of total sales (Shipped Revenue) via Subscribe and Save. SnS data is available on Vendor Central lags by a month, hence the data would only be available for the prior months.'
  },
  {
    displayName: 'Subscribe and Save Units Sold',
    dbKey: 'sns_qty_sold',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Total units sold via Subscribe and Save purchase. SnS data is available on Vendor Central lags by a month, hence the data would only be available for the prior months.'
  },
  {
    displayName: 'Subscribe and Save Unit Sales %',
    dbKey: 'percentage_sns_units',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Percentage of total units shipped units sold via Subscribe and Save. SnS data is available on Vendor Central lags by a month, hence the data would only be available for the prior months.'
  },
  {
    displayName: 'Subscribe and Save Rebate %',
    dbKey: 'sns_rebate_percentage',
    group: 'Sales & Margin',
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Total percentage of rebate on the Subscribe and Save purchase. SnS data is available on Vendor Central lags by a month, hence the data would only be available for the prior months.'
  }
];

const operationsColumns = [
  {
    displayName: 'PDP Availability Text',
    dbKey: 'pdp_availability_text',
    group: 'Operations',
    cIndex: 2,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'The latest availability text as it appears on the Amazon PDP',
    disabled: false
  },
  {
    displayName: 'Availability Status',
    dbKey: 'pdp_availability_status',
    group: 'Operations',
    cIndex: 3,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The latest availability status categorized based on the text on PDP',
    disabled: false
  },
  {
    displayName: 'Units at Amazon',
    dbKey: 'units_at_amazon',
    group: 'Operations',
    cIndex: 4,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Latest units at Amazon as per Inventory Report from Retail Analytics data coming from Vendor Central'
  },
  {
    displayName: 'Sellable On Hand Cost',
    dbKey: 'sellable_on_hand_cost',
    group: 'Operations',
    cIndex: 5,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Latest sellable on hand Cost as per Sales and Product Details Report in ARA Basic'
  },
  {
    displayName: 'Direct Fulfillment Inventory',
    dbKey: 'stock',
    group: 'Operations',
    cIndex: 6,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Latest Direct Fulfillment Inventory (DFI) value as per Vendor Central, unless the data has been shared by the vendor to us explicitly.'
  },
  {
    displayName: 'No. of Days Unavailable',
    dbKey: 'unavl_days',
    group: 'Operations',
    cIndex: 7,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Number of days SKU was not available for sale in the selected date range.'
  },
  {
    displayName: 'Unavailability',
    dbKey: 'actual_unavailability',
    group: 'Operations',
    cIndex: 8,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Percentage of glance views when ASIN(s) was not available for sale. Unavailability % uses Rep OOS values from AVC and data from CommerceIQ’s  Market Intelligence to correctly determine the percentage of glance views when an ASIN(s) was not available for sale.'
  },
  {
    displayName: 'Revenue Lost Due To Unavailability',
    dbKey: 'revenue_lost_ops_unavailable_sku',
    group: 'Operations',
    cIndex: 9,
    formatValue: 'number',
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating unavailability %, Glance view, Conversion rate and Average Selling Price from AVC.'
  },
  {
    displayName: 'Number of days Lost Buy Box',
    dbKey: 'lbb_days',
    group: 'Sales & Margin',
    cIndex: 10,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Number of days the SKU lost Buybox in the selected date range.'
  },
  {
    displayName: 'LBB%',
    dbKey: 'actual_lbb_rate',
    group: 'Operations',
    cIndex: 11,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Lost buy box for the selected period is the percentage glance views where the ASIN had lost the buy box. LBB across the catalog is calculated as the percentage of total LBB glance views across all ASINs out of the total glance views.'
  },
  {
    displayName: 'Revenue lost due to Lost Buy Box',
    dbKey: 'revenue_lost_lbb',
    group: 'Operations',
    cIndex: 12,
    formatValue: 'number',
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Ordered Product Sales ($) lost in the selected time period due to ASINs having lost buy box. Revenue loss is determined by triangulating LBB%, Glance view, Conversion rate and Average Selling Price from AVC.'
  },
  {
    displayName: 'Unsellable Units at Amazon',
    dbKey: 'unsellable_units_at_amazon',
    group: 'Operations',
    cIndex: 13,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Latest unsellable Units at Amazon as per Sales and Product Details Report in ARA Basic'
  },
  {
    displayName: 'Customer Replacements',
    dbKey: 'customer_returns',
    group: 'Operations',
    cIndex: 15,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Sum of customer replacemnets during selected date range as per as per Sales and Product Details Report in ARA Basic'
  },
  {
    displayName: 'Vendor Units Received',
    dbKey: 'vendor_units_received',
    group: 'Operations',
    cIndex: 16,
    formatValue: 'number',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Units at Amazon as per Sales and Product Details Report in ARA Basic'
  },
  {
    displayName: 'Open PO Quantity',
    dbKey: 'open_po_qty',
    group: 'Operations',
    cIndex: 17,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Open Purchase Order Units Requested as per Inventory Report from Retail Analytics data coming from Vendor Central'
  },
  {
    displayName: 'Unfilled Customer Orders',
    dbKey: 'unfilled_customer_orders',
    group: 'Operations',
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Units at Amazon as per Sales and Product Details Report in ARA Basic',
    disabled: true
  },
  {
    displayName: 'Inventory Turns',
    dbKey: 'inventory_turns',
    group: 'Operations',
    formatValue: true,
    prefix: '',
    suffix: '',
    toolTipText:
      'Units at Amazon as per Sales and Product Details Report in ARA Basic',
    disabled: true
  },
  {
    displayName: 'Sell Through Rate',
    dbKey: 'sell_through_rate',
    group: 'Operations',
    cIndex: 18,
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText:
      'Number of units shipped divided by number of units present at Amazon as reported by AVC'
  },
  {
    displayName: 'Chargebacks',
    dbKey: 'chargebacks',
    group: 'Operations',
    formatValue: false,
    prefix: 'currency',
    suffix: '%',
    toolTipText: '',
    disabled: true
  },
  {
    displayName: 'Chargeback Amount',
    dbKey: 'chargeback_amount',
    group: 'Operations',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText: '',
    disabled: true
  },

  {
    displayName: 'Chargeback %',
    dbKey: 'chrageback_perc',
    group: 'Operations',
    formatValue: true,
    prefix: '',
    suffix: '%',
    textAlign: 'right',
    toolTipText: '',
    disabled: true
  },

  {
    displayName: 'Unhealthy Inventory',
    dbKey: 'unhealthy_inventory',
    group: 'Operations',
    cIndex: 14,
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Excess inventory amount on the last day of the selected time frame based on forecasted demand.',
    disabled: false
  },

  {
    displayName: 'Unhealthy Units',
    dbKey: 'unhealthy_units',
    group: 'Operations',
    cIndex: 20,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Excess inventory on the last day of the selected time frame based on forecasted demand.',
    disabled: false
  },

  {
    displayName: 'Replenishment Category',
    dbKey: 'replenishment_category',
    group: 'Operations',
    cIndex: 21,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Replenishment status of the SKU',
    disabled: false
  },
  {
    displayName: 'Weeks of Cover (AVC)',
    dbKey: 'weeks_on_hand_avc',
    group: 'Operations',
    cIndex: 22,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Weeks of Cover as reported in the Forecast and Inventory Planning on Vendor Central.',
    disabled: false
  },
  {
    displayName: 'Weeks of Cover (DFI)',
    dbKey: 'weeks_of_cover_dfi',
    group: 'Operations',
    cIndex: 23,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Calculated by dividing Direct Fulfillment Inventory by weekly average shipped units.',
    disabled: false
  },
  {
    displayName: 'Weeks of Cover (CIQ)',
    dbKey: 'weeks_of_coverage',
    group: 'Operations',
    cIndex: 24,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Number of weeks in which the inventory with Amazon will need replenishment. It is calculated by taking into account the Sellable on Hand Inventory, Direct Fulfillment Inventory and Open Purchase Orders which are completing the same day as per AVC and dividing this with average sales velocity of shipped units in the last 4 weeks.',
    disabled: false
  },
  {
    displayName: 'Average daily sales',
    dbKey: 'daily_sales_trailing_30_day_average',
    group: 'Operations',
    cIndex: 25,
    formatValue: true,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Average units sold per day. Calculated by taking average of Shipped_units for last 30 days.',
    disabled: false
  },
  {
    displayName: 'Package Dimension',
    dbKey: 'package_dimension_value',
    group: 'Operations',
    formatValue: false,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'The dimension of the package which is delivered to the users.'
  },
  {
    displayName: 'Package Dimension Unit',
    show: false,
    dbKey: 'dimension_unit',
    group: 'Operations',
    formatValue: false,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: ''
  },
  {
    displayName: 'Unit Weight',
    dbKey: 'unit_weight',
    group: 'Operations',
    formatValue: false,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'The weight of the package which is delivered to the users.'
  },
  {
    displayName: 'Unit Weight Unit',
    show: false,
    dbKey: 'weight_unit',
    group: 'Operations',
    formatValue: false,
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: ''
  },
  {
    displayName: 'Fulfilment Cost Per Unit',
    dbKey: 'fulfilment_cost_per_unit',
    group: 'Operations',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The cost which Amazon incurred for delivering one unit of this ASIN to the users.'
  },
  {
    displayName: 'Total Fulfilment Cost (Weekly)',
    dbKey: 'total_fulfilment_cost',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Total Fulfilment Cost ( {1} - {2} )',
    group: 'Operations',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The total cost of which Amazon incurred for delivering all the shipped units to the users.',
    width: 150
  },
  {
    displayName: 'Storage Cost Per Unit',
    dbKey: 'storage_cost_per_unit',
    group: 'Operations',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The per unit storage cost of the ASIN. The cost is dependent on the dimension of the ASIN.'
  },
  {
    displayName: 'Total Storage Cost (Weekly)',
    dbKey: 'total_storage_cost',
    enableHeaderValueGetter: true,
    dynamicHeaderDbKeys: 'net_ppm_week_start_date,net_ppm_week_end_date',
    headerTransformText: 'Total Storage Cost ( {1} - {2} )',
    group: 'Operations',
    formatValue: true,
    prefix: 'currency',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'The total storage cost of the ASIN for the week. The cost is dependent on the dimension of the ASIN and the average weekly inventory for the week.',
    width: 150
  }
];

const searchAndBSRColumns = [
  {
    displayName: 'Keywords with Top 3 Appearances',
    dbKey: 'keywords_with_top_3_apps',
    group: 'Search & BSR',
    cIndex: 2,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    toolTipText:
      "No. of search keywords where this SKU has appeared in top 3 organic results as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'Keywords with Top 5 Appearances',
    dbKey: 'keywords_with_top_5_apps',
    group: 'Search & BSR',
    cIndex: 3,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    toolTipText:
      "No. of search keywords where this SKU has appeared in top 5 organic results as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'Keywords with Top 10 Appearances',
    dbKey: 'keywords_with_top_10_apps',
    group: 'Search & BSR',
    cIndex: 4,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    toolTipText:
      "No. of search keywords where this SKU has appeared in top 10 organic results as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'Keywords with Page 1 Appearances',
    dbKey: 'keywords_with_page_1_apps',
    group: 'Search & BSR',
    cIndex: 5,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    toolTipText:
      "No. of search keywords where this SKU has appeared in page 1 of organic results as per most recent CommerceIQ's Market Intelligence Data. ",
    disabled: true
  },
  {
    displayName: 'Number of BSR Categories',
    dbKey: 'bsr_category_obj_size',
    group: 'Search & BSR',
    cIndex: 6,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    toolTipText: 'Current number of categories the SKU is listed in'
  },
  {
    displayName: 'BSR Category 1',
    dbKey: 'category_1',
    group: 'Search & BSR',
    cIndex: 7,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "First Best Seller Category node name which appears on Product details page as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 1 Rank',
    dbKey: 'category_1_best_seller_rank',
    group: 'Search & BSR',
    cIndex: 8,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Best Seller Category rank in first node as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 2',
    dbKey: 'category_2',
    group: 'Search & BSR',
    cIndex: 9,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "Second Best Seller Category node name which appears on Product details page as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 2 Rank',
    dbKey: 'category_2_best_seller_rank',
    group: 'Search & BSR',
    cIndex: 10,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Best Seller Category rank in second node as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 3',
    dbKey: 'category_3',
    group: 'Search & BSR',
    cIndex: 11,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "Third Best Seller Category node name which appears on Product details page as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 3 Rank',
    dbKey: 'category_3_best_seller_rank',
    group: 'Search & BSR',
    cIndex: 12,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Best Seller Category rank in third node as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 4',
    dbKey: 'category_4',
    group: 'Search & BSR',
    cIndex: 13,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "Fourth Best Seller Category node name which appears on Product details page as per most recent CommerceIQ's Market Intelligence Data. "
  },
  {
    displayName: 'BSR Category 4 Rank',
    dbKey: 'category_4_best_seller_rank',
    group: 'Search & BSR',
    cIndex: 14,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "Best Seller Category rank in fourth node as per most recent CommerceIQ's Market Intelligence Data. "
  }
];

const contentColumns = [
  {
    displayName: 'Title Length',
    dbKey: 'length_of_title',
    group: 'Content',
    cIndex: 2,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Character length of Title on Product details page'
  },
  {
    displayName: 'No. of Bullets',
    dbKey: 'no_of_bullets',
    group: 'Content',
    cIndex: 3,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of bullet points on Product details page',
    disabled: true
  },
  {
    displayName: 'Length of Description',
    dbKey: 'length_of_description',
    group: 'Content',
    cIndex: 4,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Character length of description on Product details page'
  },
  {
    displayName: 'No. of Images',
    dbKey: 'no_of_images',
    group: 'Content',
    cIndex: 5,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of images present on Product details page',
    disabled: true
  },
  {
    displayName: 'Presence of A+ Content',
    dbKey: 'presence_of_a+_content',
    group: 'Content',
    cIndex: 6,
    formatValue: false,
    prefix: 'currency',
    suffix: '%',
    toolTipText: 'Status indicating whether A+ content is present or not. ',
    disabled: true
  },
  {
    displayName: 'Avg Rating',
    dbKey: 'avg_product_rating',
    group: 'Content',
    cIndex: 7,
    formatValue: 'numeric',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Current average rating on the product details page out of 5'
  },
  {
    displayName: 'No. of Reviews',
    dbKey: 'total_review_count',
    group: 'Content',
    cIndex: 8,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      "No. of reviews on the product detail pages as per latest CommerceIQ's market intelligence data"
  },
  {
    displayName: 'New Reviews',
    dbKey: 'new_review_count',
    group: 'Content',
    cIndex: 9,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of new reviews received during selected date range. '
  },
  {
    displayName: 'No. of Questions',
    dbKey: 'total_question_count',
    group: 'Content',
    cIndex: 10,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of questions on product details page. '
  },
  {
    displayName: 'No. of New Questions',
    dbKey: 'new_question_count',
    group: 'Content',
    cIndex: 11,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of new questions received during selected date range. '
  },
  {
    displayName: 'No. Of Bullets',
    dbKey: 'productdetailsbulletcount',
    group: 'Content',
    cIndex: 12,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of bullets present on PDP'
  },
  {
    displayName: 'No. Of Images',
    dbKey: 'imagescount',
    group: 'Content',
    cIndex: 13,
    formatValue: 'number',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'No. of product images'
  },
  {
    displayName: 'Videos',
    dbKey: 'video_present',
    group: 'Content',
    cIndex: 14,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Videos present or not'
  },
  {
    displayName: 'A+ Content',
    dbKey: 'apluscontent',
    group: 'Content',
    cIndex: 15,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'A+ content is present or not'
  },
  {
    displayName: 'Premium A+ content',
    dbKey: 'premium_apluscontent',
    group: 'Content',
    cIndex: 16,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText: 'Premium A+ content is present or not'
  },
  {
    displayName: 'Important Information',
    dbKey: 'important_information',
    group: 'Content',
    cIndex: 17,
    formatValue: 'localeString',
    prefix: '',
    suffix: '',
    textAlign: 'right',
    toolTipText:
      'Whether important information section on PDP is present or not'
  }
];

const competitionColumns = [
  {
    displayName: 'Comp ASIN 1',
    dbKey: 'comp_sku1',
    keyType: 'comp_sku',
    group: 'Competition',
    showImage: false,
    cIndex: 2,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "No.1 ASIN from competitor brand which competes directly as per CommerceIQ's market intelligence data. ",
    enableSDP: false
  },
  {
    displayName: 'Comp ASIN 1 TITLE',
    dbKey: 'comp_sku1_title',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 1 URL',
    dbKey: 'comp_sku1_product_url',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 2',
    dbKey: 'comp_sku2',
    keyType: 'comp_sku',
    group: 'Competition',
    showImage: false,
    cIndex: 3,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "No.2 ASIN from competitor brand which competes directly as per CommerceIQ's market intelligence data. "
  },
  {
    displayName: 'Comp ASIN 2 TITLE',
    dbKey: 'comp_sku2_title',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 2 URL',
    dbKey: 'comp_sku2_product_url',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 3',
    dbKey: 'comp_sku3',
    keyType: 'comp_sku',
    group: 'Competition',
    showImage: false,
    cIndex: 4,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "No.3 ASIN from competitor brand which competes directly as per CommerceIQ's market intelligence data. "
  },
  {
    displayName: 'Comp ASIN 3 TITLE',
    dbKey: 'comp_sku3_title',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 3 URL',
    dbKey: 'comp_sku3_product_url',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 4',
    dbKey: 'comp_sku4',
    keyType: 'comp_sku',
    group: 'Competition',
    showImage: false,
    cIndex: 5,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "No.4 ASIN from competitor brand which competes directly as per CommerceIQ's market intelligence data. "
  },
  {
    displayName: 'Comp ASIN 4 TITLE',
    dbKey: 'comp_sku4_title',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 4 URL',
    dbKey: 'comp_sku4_product_url',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 5',
    dbKey: 'comp_sku5',
    keyType: 'comp_sku',
    group: 'Competition',
    showImage: false,
    cIndex: 6,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText:
      "No.5 ASIN from competitor brand which competes directly as per CommerceIQ's market intelligence data. "
  },
  {
    displayName: 'Comp ASIN 5 TITLE',
    dbKey: 'comp_sku5_title',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Comp ASIN 5 URL',
    dbKey: 'comp_sku5_product_url',
    group: 'Competition',
    show: false,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  }
];

const all = [
  {
    displayName: "CommerceIQ's Market Intelligence Date",
    dbKey: 'scrape_date',
    group: 'All',
    cIndex: 83,
    formatValue: 'formattable_date',
    prefix: '',
    suffix: '',
    toolTipText: "Last date of CommerceIQ's Intelligence update for ASIN"
  }
];

const commonColumns = [
  {
    displayName: 'SKU Details',
    dbKey: 'asin',
    keyType: 'asin',
    group: 'Common',
    width: 400,
    cIndex: 0,
    formatValue: false,
    prefix: '',
    suffix: '',
    pinned: 'left',
    toolTipText: 'Product ASIN from Amazon Vendor Central'
  },
  {
    displayName: 'Title',
    dbKey: 'asin_name',
    show: false,
    group: 'Common',
    width: 250,
    cIndex: 1,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: 'Product Title from Amazon Vendor Central'
  },
  {
    displayName: 'Product Page URL',
    dbKey: 'asin_hyperlink',
    show: false,
    group: 'Common',
    cIndex: 2,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  },
  {
    displayName: 'Cache Link',
    dbKey: 'cache_link',
    show: false,
    group: 'Common',
    cIndex: 3,
    formatValue: false,
    prefix: '',
    suffix: '',
    toolTipText: ''
  }
];

const columnArray = [
  'asin',
  'asin_name',
  'parent_asin',
  'external_id',
  'id_type',
  'model_number',
  'released',
  'product_group',
  'category',
  'subcategory',
  'brand_code',
  'brand',
  'catalog_number',
  'replenishment_code',
  'page_view_rank',
  'style_number',
  'color',
  'color_count',
  'manufacturer_code',
  'parent_manufacturer_code',
  'avg_offer_price',
  'latest_offer_price',
  'offer_price_change',
  'subscribe_and_save_price',
  'promotion_types',
  'promotion_messages',
  'ds_elasticity_value',
  'ds_elasticity_bucket',
  'customer_orders',
  'units_shipped',
  'unit_cogs',
  'gross_unit_margin',
  'gross_unit_margin_perc',
  'shipped_cogs',
  'shipped_revenue',
  'ordered_revenue',
  'gross_shipped_margin_perc',
  'gross_ordered_margin_perc',
  'units_at_amazon',
  'sellable_on_hand_cost',
  'actual_unavailability',
  'revenue_lost_ops_unavailable_sku',
  'unsellable_units_at_amazon',
  'customer_returns',
  'vendor_units_received',
  'open_po_qty',
  'unfilled_customer_orders',
  'inventory_turns',
  'sell_through_rate',
  'chargebacks',
  'chargeback_amount',
  'chrageback_perc',
  'unhealthy_inventory',
  'unhealthy_units',
  'replenishment_category',
  'weeks_of_coverage',
  'weeks_on_hand_avc',
  'daily_sales_trailing_30_day_average',
  'keywords_with_top_3_apps',
  'keywords_with_top_5_apps',
  'keywords_with_top_10_apps',
  'keywords_with_page_1_apps',
  'category_1',
  'category_1_best_seller_rank',
  'category_2',
  'category_2_best_seller_rank',
  'category_3',
  'category_3_best_seller_rank',
  'category_4',
  'category_4_best_seller_rank',
  'length_of_title',
  'no_of_bullets',
  'length_of_description',
  'no_of_images',
  'presence_of_a+_content',
  'avg_product_rating',
  'total_review_count',
  'new_review_count',
  'total_question_count',
  'new_question_count',
  'productdetailsbulletcount',
  'imagescount',
  'apluscontent',
  'premium_apluscontent',
  'important_information',
  'rank_1_3p_sku',
  'rank_1_3p_seller_name',
  'rank_2_3p_sku',
  'rank_2_3p_seller_name',
  'comp_sku1',
  'comp_sku2',
  'comp_sku3',
  'comp_sku4',
  'comp_sku5',
  'scrape_date'
];

export default {
  columnArray: columnArray,
  getTableColumnsFn: () => {
    return {
      All: all,
      Catalog: catalogColumns,
      PriceAndPromotions: priceAndPromotionsColumns,
      SalesAndMargin: salesAndMarginColumns,
      Operations: operationsColumns,
      SearchAndBSR: searchAndBSRColumns,
      Content: contentColumns,
      Competition: competitionColumns,
      Common: commonColumns
    };
  }
};
