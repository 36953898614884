var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-mb-m sticky u-header-box-shadow",staticStyle:{"z-index":"100"}},[_c('rb-filter-panel',{attrs:{"data":_vm.filterData,"loader-status":_vm.loaderStatus,"primary":_vm.primaryFilterData,"secondary-data":_vm.secondaryFilterData,"listener-event":'insight-financial-filters',"state":_vm.filterState,"config":_vm.config.filters,"emit":_vm.config.filters.emit,"has-search":_vm.config.filters.hasSearch,"new-date":true,"allow-date-compare":true,"max-date":_vm.getOverviewPageWiseMinMaxKey,"apply-filter":_vm.applyFilter,"filter-v2":_vm.filterV2,"show-ingestion-progress":_vm.filterV2,"enable-remove-all":false,"on-create":_vm.onCreate}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs",attrs:{"slot":"leftSide-searchPanel"},slot:"leftSide-searchPanel"},[_c('span',{staticClass:"u-spacing-mr-s back-arrow",on:{"click":function($event){$event.stopPropagation();return _vm.backButton()}}},[_c('rb-icon',{staticClass:"rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer",attrs:{"data-cy":"arrow_back","icon":'arrow_back'}})],1),_c('PageTitle',{attrs:{"title":'Finance'}})],1),_c('div',{staticClass:"u-spacing-mr-m",attrs:{"slot":"search"},slot:"search"},[_c('asin-search',{attrs:{"config":_vm.config.filters.search}})],1)]),(_vm.showActiveFilterBanner)?_c('ActiveFilterBanner',{attrs:{"icon-name":"info-circle-fill","filter-instance":_vm.filterInstance}}):_vm._e()],1),_c('div',{staticClass:"u-display-flex u-spacing-ph-m u-spacing-pb-l u-flex-wrap-yes",attrs:{"data-cy":"financial-report"}},_vm._l((_vm.config.widgets),function(widget,index){return _c('div',{key:index,staticClass:"card u-position-relative u-spacing-mb-l u-min-width-40 u-flex-1 u-spacing-pb-s",class:{
        'u-spacing-mr-l':
          index % 2 === 0 && index !== _vm.config.widgets.length - 1
      }},[_c('div',{staticClass:"card__header"},[(widget.header1)?_c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('rb-tooltip',{attrs:{"label":widget.header1.tooltip,"position":"is-right","multilined":""}},[_c('span',{staticClass:"card__title u-color-grey-mid-light"},[_vm._v(" "+_vm._s(widget.header1.title)+" ")])]),(
              _vm.getInsightsOverviewData[widget.chartDataKey] &&
              _vm.getInsightsOverviewData[widget.chartDataKey].header1
            )?_c('div',{staticClass:"flex-row u-flex-align-items-center u-spacing-mt-s"},[_c('div',{staticClass:"card__value"},[(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header1.tag1
                )?_c('span',{staticClass:"u-font-size-2 u-color-grey-light u-font-weight-600",attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag1}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag1, (widget.header1.tag1Unit || {}).pre, (widget.header1.tag1Unit || {}).suff, undefined, undefined, (widget.header1.tag1Unit || {}).format ))+" ")]):_vm._e(),(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2 &&
                  !(widget.header1.tag2 || {}).invert
                )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"u-font-size-6 u-spacing-pl-xs",class:{
                  'u-color-red-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header1
                      .tag2 < 0,
                  'u-color-green-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header1
                      .tag2 > 0
                },attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2, widget.header1.tag2Unit.pre, widget.header1.tag2Unit.suff ))+" ")]):_vm._e(),(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2 &&
                  (widget.header1.tag2 || {}).invert
                )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"u-font-size-6 u-spacing-pl-xs",class:{
                  'u-color-green-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header1
                      .tag2 < 0,
                  'u-color-red-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header1
                      .tag2 > 0
                },attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header1.tag2, widget.header1.tag2Unit.pre, widget.header1.tag2Unit.suff ))+" ")]):_vm._e()])]):_vm._e()],1):_vm._e(),(widget.header2)?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-align-items-flex-end"},[_c('rb-tooltip',{attrs:{"label":widget.header2.tooltip,"position":"is-left","multilined":""}},[_c('span',{staticClass:"card__title u-color-grey-mid-light"},[_vm._v(" "+_vm._s(widget.header2.title)+" ")])]),(
              _vm.getInsightsOverviewData[widget.chartDataKey] &&
              _vm.getInsightsOverviewData[widget.chartDataKey].header2
            )?_c('div',{staticClass:"flex-row u-flex-align-items-center u-spacing-mt-s"},[_c('div',{staticClass:"card__value"},[(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2 &&
                  !(widget.header2.tag2 || {}).invert
                )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"u-font-size-6 u-spacing-pr-xs",class:{
                  'u-color-red-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header2
                      .tag2 < 0,
                  'u-color-green-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header2
                      .tag2 > 0
                },attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2, widget.header2.tag2Unit.pre, widget.header2.tag2Unit.suff ))+" ")]):_vm._e(),(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2 &&
                  (widget.header2.tag2 || {}).invert
                )?_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"u-font-size-6 u-spacing-pr-xs",class:{
                  'u-color-green-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header2
                      .tag2 < 0,
                  'u-color-red-base':
                    _vm.getInsightsOverviewData[widget.chartDataKey].header2
                      .tag2 > 0
                },attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag2, widget.header2.tag2Unit.pre, widget.header2.tag2Unit.suff ))+" ")]):_vm._e(),(
                  _vm.getInsightsOverviewData[widget.chartDataKey].header2.tag1
                )?_c('span',{staticClass:"u-font-size-2 u-color-grey-light u-font-weight-600",attrs:{"value":_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag1}},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getInsightsOverviewData[widget.chartDataKey].header2.tag1, (widget.header2.tag1Unit || {}).pre, (widget.header2.tag1Unit || {}).suff ))+" ")]):_vm._e()])]):_vm._e()],1):_vm._e()]),((_vm.getInsightsOverviewData[widget.chartDataKey] || {}).noData)?_c('div',{staticClass:"fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-4"},[_c('p',[_vm._v("No data available for selected filters.")])]):_vm._e(),((_vm.getInsightsOverviewData[widget.chartDataKey] || {}).error)?_c('div',{staticClass:"fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-4"},[_c('p',[_vm._v("Error Occurred. Please try again later")])]):_vm._e(),_c('loader',{staticClass:"fill--parent",attrs:{"loading":(_vm.getInsightsOverviewData[widget.chartDataKey] || {}).load,"color":'#3684bb'}}),_c('chart',{staticClass:"u-spacing-mh-m",attrs:{"config":_vm.extendChartConfig(
            widget,
            (_vm.getInsightsOverviewData[widget.chartDataKey] || {}).config
          ),"data":(_vm.getInsightsOverviewData[widget.chartDataKey] || {}).data}}),_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-space-between card-footer u-spacing-pt-s u-spacing-ph-m u-border-top u-border-color-grey-xxx-light u-border-width-s"},[_c('span',{staticClass:"u-color-grey-x-light u-font-size-7"},[_vm._v(" Last Updated : "+_vm._s(_vm._f("formattable_date")(_vm.getAllDates()[widget.date]))+" ")])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }