import BaseDataService from '@/pages/campaign-management/services/base-data-service.js';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';

export default class ActivityLogDataService extends BaseDataService {
  transformData(data, config) {
    return activitytransformer.getActivityData(data.response);
  }

  sendDataRequest(requestCopy, config) {
    const { primaryKey, widget } = config;
    return HttpService.get('AMS_ACTIONS', {
      append: `?widget=${widget}&primaryKey=${primaryKey}`
    });
  }

  noDataCondtion(response) {
    return response.data.response.length === 0;
  }
}
