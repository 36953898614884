var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.destoryComponent)?_c('section',{staticClass:"strategyBuilder u-position-fixed"},[_c('div',{staticClass:"u-position-fixed model strategyBuilder"},[_c('section',{staticClass:"strategyBuilder--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{on:{"click":function($event){return _vm.goBackValidation()}}},[_c('rb-icon',{staticClass:"u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s",attrs:{"icon":'cross'}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValues.strategyName),expression:"selectedValues.strategyName"}],staticClass:"heading--input u-font-size-2 u-font-weight-bold",class:{
              'active-state': _vm.isTitleInputClicked,
              ' u-color-grey-lighter': !_vm.isTitleInputClicked
            },staticStyle:{"width":"100%"},attrs:{"type":"text","maxlength":"56","onClick":"this.select();","onkeypress":"this.style.width = ((this.value.length + 1) * 16) + 'px';"},domProps:{"value":(_vm.selectedValues.strategyName)},on:{"click":function($event){return _vm.titleInputClicked()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedValues, "strategyName", $event.target.value)}}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValues.description),expression:"selectedValues.description"}],staticClass:"heading--input strategy-desc u-font-size-6 u-spacing-pt-s u-spacing-ml-l",attrs:{"type":"text","maxlength":"160","placeholder":_vm.placeholder},domProps:{"value":(_vm.selectedValues.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedValues, "description", $event.target.value)}}})]),_c('div',{staticClass:"u-font-size-5 u-text-align-right u-display-flex"},[(
            _vm.selectedValues.mode && _vm.selectedValues.mode !== 'create'
              ? _vm.selectedValues.strategyStatus !== 'LIVE'
              : true
          )?_c('rb-button',{staticClass:"u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s u-color-blue-base u-border-all u-border-color-blue-base u-border-width-s",attrs:{"text":'Save draft',"type":'hollow',"click-fn":_vm.saveStrategy.bind(this, 'draft')}}):_vm._e(),_c('rb-button',{attrs:{"id":"confirmButton","text":'Prioritise and Save',"type":'filled',"disabled":_vm.selectedValues.enableSaveBtn,"click-fn":_vm.saveStrategy.bind(this)}})],1)]),_c('StepComponent',{attrs:{"config":_vm.stepsConfig,"all-steps-data":_vm.selectedValues},on:{"stepsSkippedEvent":_vm.formatAllDisplayText,"stepsGlobalEvent":_vm.stepsGlobalEvent}}),_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"fill--parent",attrs:{"color":'#3fadf7',"size":'4rem',"thickness":'.2rem'}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }