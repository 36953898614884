<template>
  <div class="u-spacing-mt-m">
    <div
      class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-p-l u-width-100 card"
    >
      <div
        v-if="stage.noData || stage.error"
        class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative"
      >
        <div
          v-if="stage.noData || !stage"
          class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter line-height-1_5"
        />
        <div
          v-if="stage.error"
          class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
        >
          <p>Error Occurred. Please try again later</p>
        </div>
      </div>
      <div v-show="!stage.error">
        <div class="u-display-flex">
          <div class="u-display-flex u-flex-direction-column u-flex-1">
            <div class="u-display-flex u-flex-align-items-center">
              <span class="u-font-size-3 u-font-weight-bold u-color-blue-base"
                >RULE</span
              >
              <div
                v-if="step.tooltipText"
                v-tippy="tippyConfig"
                class="u-font-size-7 u-spacing-ml-s u-cursor-pointer"
                :title="step.tooltipText"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                  :icon="'info-circle-fill'"
                />
              </div>
            </div>
            <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
              >Set a scope for the strategy.</span
            >
          </div>
        </div>
        <div class="u-display-flex u-flex-direction-column u-spacing-mt-l">
          <div
            class="createStratgey--filter u-spacing-ph-m u-spacing-ph-s u-width-100"
          >
            <rb-filter-panel
              :data="filterData"
              :primary="computedFilterData"
              :secondary-data="secondaryFilterData"
              :has-search="false"
              :use-local-storage="false"
              :apply-filter="rulesApplied"
              :loader-status="loaderStatus"
              :get-filter-instance="getFilterInstance"
              :default-filters="defaultFilters"
              :ingestion-dependent-entities="ingestionDependentEntities"
              :filter-v2="filterV2"
              :show-ingestion-progress="filterV2"
              :listener-event="listenerEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import boomerangSingleSelect from '@/components/widgets/select/singleSelect.vue/';
import widgetsMixin from '@/components/widgetMixin';
import rbFilterPanel from '@/components/widgets/filter-panel';
import transformer from '@/utils/services/data-transformer';
import loader from '@/components/basic/loader';
import moment from 'moment-timezone';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import { isLineItemRetailer } from '@/components/pages/strategies/create/utils.js';

export default {
  components: {
    boomerangSingleSelect,
    rbFilterPanel,
    loader
  },
  mixins: [widgetsMixin],
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    allStepsData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // eslint-disable-next-line vue/require-default-prop
    mode: {
      type: String
    }
  },
  data() {
    const retailer = this.$store.getters.getRetailer;
    return {
      entitySelects: [],
      entitySelected: {},
      filtersResultArray: [],
      filters: {},
      APIConfig: {},
      stage: {
        load: false
      },
      filterInstance: null,
      emptyFilterState: {
        order: [],
        values: {}
      },
      tippyConfig: {
        type: Object,
        default: () => {
          return {
            duration: [0, 0],
            placement: 'top-start',
            popperOptions: {
              modifiers: {
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
              }
            }
          };
        }
      },
      listenerEvent: 'scope-filter', // needed for filter greyout issue
      ...utils.filterConfigs(retailer)
    };
  },
  computed: {
    computedFilterData() {
      return this.primaryFilterData.filter(
        (item) => item.dimensionType !== 'CLIENT_SAVED_FILTERS'
      );
    },
    defaultFilters() {
      const filters = this.step?.data?.filters;
      if (
        typeof filters === 'object' &&
        !Array.isArray(filters) &&
        filters !== null
      ) {
        return this.step.data.filters;
      } else {
        return {
          order: [],
          values: {}
        };
      }
    },
    isEditMode() {
      return (
        this.step.data && this.step.data.mode && this.step.data.mode === 'edit'
      );
    }
  },
  watch: {},
  created() {
    this.fetchFilters(...this.step.filterParams);
    this.APIConfig = JSON.parse(
      JSON.stringify(this.step.ruleBuilder.APIConfig)
    );
    this.nextStepStatus(true);
  },
  mounted() {
    this.sendInitialRequest();
  },
  methods: {
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    sendInitialRequest() {
      const filters = this.filterInstance
        ? this.filterInstance.getSelectedFilters()
        : this.emptyFilterState;
      this.APIConfig.where = { dimensionNameValueList: [] };
      this.APIConfig.where = transformer.generateWhereClause(
        this.APIConfig.where,
        filters
      );
      this.sendRequestFilterResult();
    },
    entityTypeSelectChange(val) {
      this.entitySelected = val;
    },
    nextStepStatus(value) {
      this.$emit('nextStep', value, this.index);
    },
    sendRequestFilterResult() {
      this.stage.load = true;
      this.$emit('loadNextButton', this.stage.load);
      this.filtersResultArray = [];
      this.stepDataOut();
      this.nextStepStatus(true);
      const that = this;
      const data = {
        APIData: this.APIConfig,
        meta: {
          key: this.entitySelected.key
        }
      };
      this.$store.dispatch('fetchRuleBuilder', data).then((result) => {
        const statefilterResult = that.$store.getters.getFilterResult;
        that.filtersResultArray =
          statefilterResult[that.entitySelected.key].rows;
        if (that.filtersResultArray.length === 0) {
          that.filtersResultArray = that.formatFilterResult(
            statefilterResult[that.entitySelected.key].rows,
            result.data
          );
          Vue.set(that.stage, 'load', false);
          that.$emit('loadNextButton', that.stage.load);
          that.stepValidate();
        } else {
          const formatResult = that.getFormatDataForValidation(
            that.filtersResultArray
          );
          this.$store
            .dispatch('fetchOverLapEntityValidation', formatResult)
            .then((result) => {
              that.filtersResultArray = that.formatFilterResult(
                statefilterResult[that.entitySelected.key].rows,
                result.data
              );
              Vue.set(that.stage, 'load', false);
              that.$emit('loadNextButton', that.stage.load);
              that.stepValidate();
            })
            .catch(() => {
              Vue.set(that.stage, 'load', false);
              Vue.set(this.stage, 'error', true);
              this.nextStepStatus(true);
            });
        }
      });
    },
    formatFilterResult(filtersResultArray, overLappingList) {
      const isLineItem = isLineItemRetailer(this.$store.getters.getRetailer);
      const entityType = isLineItem ? 'line items' : 'campaigns';
      const key = isLineItem ? 'line_item_id' : 'campaign_id';

      for (const element of filtersResultArray) {
        if (element?.status?.toLowerCase() === 'archived') {
          element.allow = false;
          element.infoTooltip = `You cannot add this ${entityType} as it is archived.`;
        } else {
          const overlappingItem = overLappingList[element[key]];
          if (overlappingItem) {
            element.allow = overlappingItem.allow;
            element.infoTooltip = decodeURIComponent(
              this.getInfoMessage(overlappingItem.strategies, entityType)
            );
          } else {
            element.allow = true;
          }
        }
      }
      return filtersResultArray;
    },

    getInfoMessage(strategies, entityType) {
      let message = `You cannot add this ${entityType} as it is already a part of `;
      const strategyFormating =
        strategies.length > 1 ? 'strategies' : 'strategy';
      for (let i = 0; i < strategies.length; i++) {
        message += `"${strategies[i].strategyName}"`;
        if (i !== strategies.length - 1) {
          message += ', ';
        }
      }
      message += ` day parting ${strategyFormating}.`;
      return message;
    },
    getFormatDataForValidation(values) {
      const entities = [];
      const isLineItem = isLineItemRetailer(this.$store.getters.getRetailer);
      const entityType = isLineItem ? 'LINE_ITEM' : 'CAMPAIGN';

      for (const element of values) {
        const entityId = isLineItem
          ? element?.line_item_id?.toString()
          : element?.campaign_id?.toString();
        if (entityId !== undefined) {
          entities.push(entityId);
        }
      }
      if (this.mode === 'edit') {
        return {
          entities: entities,
          type: entityType,
          strategyUniqueId: this.step.strategyUniqueId
        };
      } else {
        return {
          entities: entities,
          type: entityType
        };
      }
    },
    formatStep1Data() {
      const filters = this.filterInstance
        ? this.filterInstance.getSelectedFilters()
        : this.emptyFilterState;
      const obj = {
        entityType: this.entitySelected.key,
        filtersResult: this.filtersResultArray,
        filters: filters,
        targetList: (this.step.data || {}).targetList || [],
        load: this.stage.load
      };
      return obj;
    },
    stepDataOut() {
      const obj = this.formatStep1Data();
      obj.index = this.index;
      this.$emit('stepDataOut', obj);
    },
    rulesApplied(filters) {
      this.APIConfig.where = { dimensionNameValueList: [] };
      const filterObj = {
        values: filters
      };
      this.APIConfig.where = transformer.generateWhereClause(
        this.APIConfig.where,
        filterObj
      );
      this.sendRequestFilterResult();
    },
    stepValidate() {
      this.nextStepStatus(false);
      this.stepDataOut();
    }
  }
};
</script>

<style scoped lang="css">
.createStratgey {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 999999;
  background: #f5f7fa;
}
.createStratgey .createStratgey--header {
  background-color: #ffffff;
  height: 104px;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
}
.createStratgey .createStratgey--filter {
  background: #f5f7fa;
}
.heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}
.custom--select {
  width: 33%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border: solid 1px #e9eaeb;
  background-color: #ffffff;
}
.custom--select.active {
  border-left: 4px solid #007cf6;
}
.step--card {
  min-height: 200px;
}
</style>
