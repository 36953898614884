<template>
  <div class="bg__full-screen--semitransparent u-display-flex">
    <div
      class="dialog--center remove-alert-modal u-flex-justify-content-space-around"
    >
      <div
        class="header u-display-flex u-spacing-pv-m u-spacing-ph-l u-flex-justify-content-space-between u-flex-align-items-center"
      >
        <div>
          <rb-icon
            class="u-cursor-pointer"
            icon="warning-triangle"
          />
        </div>
        <div class="text--line-height u-flex u-font-size-7 u-spacing-pl-s">
          {{ $t('recommendation_warning_text') }}
        </div>
        <div @click="closeDialog()">
          <rb-icon
            class="u-flex rb-icon--small u-cursor-pointer"
            icon="cross"
          />
        </div>
      </div>
      <div class="body u-spacing-pv-m u-spacing-ph-l">
        <p class="u-font-size-5 u-font-weight-600">Recommendation Type</p>
        <div
          v-if="localAlertObject.alertType"
          class="u-spacing-mv-s u-font-size-5"
        >
          <rb-radio
            v-model="formData.snoozeRecommendationType"
            :native-value="localAlertObject.alertType"
          >
            {{ alertLabel }}
          </rb-radio>
        </div>
        <div class="u-spacing-mv-s u-font-size-5">
          <rb-radio
            v-model="formData.snoozeRecommendationType"
            :native-value="'ALL'"
          >
            All
          </rb-radio>
        </div>
        <p class="u-spacing-pt-s u-font-size-5 u-font-weight-600">
          SKU Details
        </p>
        <div class="u-spacing-mt-s u-font-size-5">
          <div
            class="media__title u-spacing-pb-xs u-font-size-6"
            :title="localAlertObject.attributes.title"
          >
            {{ localAlertObject.attributes.title }}
          </div>
          <div class="media__subtitle">
            <a
              target="_blank"
              class="link--asin u-line-height-1 u-font-size-7"
              :href="localAlertObject.attributes.product_url"
              title="Open product page"
              >{{ localAlertObject.attributes.asin }}</a
            >
          </div>
        </div>
        <p class="u-spacing-pt-m u-font-size-5 u-font-weight-600">Reasons</p>
        <div class="u-spacing-mv-s u-font-size-5">
          <textarea
            v-model="reason"
            placeholder="Your reasons here, if any..."
          />
        </div>
        <div
          class="u-display-flex u-spacing-mt-m u-flex-justify-content-flex-end"
        >
          <rb-button
            :click-fn="submit"
            text="Remove"
            :size="'s'"
            :type="'filled'"
          />
        </div>
      </div>
      <div
        class="modal-footer u-display-flex u-spacing-pv-m u-spacing-ph-l u-border-top u-border-width-s u-border-color-grey-xxx-light u-flex-align-items-center"
      >
        <div><rb-icon icon="info-circle-fill u-color-grey-lighter" /></div>
        <p
          class="text--line-height u-font-size-7 u-color-grey-lighter u-spacing-ml-s"
        >
          {{ $t('remove_recommendation_info_text') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  props: {
    alertLabel: {
      type: String
    },
    closeFn: {
      type: Function,
      default: () => {}
    },
    applyFn: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      reason: '',
      checkboxGroup: [],
      formData: {
        reason: [],
        snoozeRecommendationType: null
      },
      localAlertObject: {},
      currEventObj: {}
    };
  },
  computed: {
    currentAlertData() {
      return this.$store.getters.getSingleAlertForRemoval;
    }
  },
  created() {
    this.localAlertObject = this.currentAlertData.recommendationObj;
    this.currEventObj = this.currentAlertData.event;
    if (!this.localAlertObject.alertType) {
      this.formData.snoozeRecommendationType = 'ALL';
    } else {
      this.formData.snoozeRecommendationType = this.localAlertObject.alertType;
    }
  },
  methods: {
    closeDialog() {
      this.closeFn();
    },
    submit() {
      this.formData.reason.push(this.reason);
      this.applyFn(
        this.formData.reason,
        this.formData.snoozeRecommendationType
      );
    }
  }
};
</script>

<style scoped lang="css">
textarea {
  border-radius: 2px;
  border-width: 1px;
  border-color: #e9eaeb;

  height: 88px;
  width: 100%;
  resize: none;
  padding: 6px;
}

.remove-alert-modal {
  width: 296px;
}
.remove-alert-modal .text--line-height {
  line-height: 14px;
}
.remove-alert-modal .header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #4b5158;
  color: #fff;
}
.remove-alert-modal .body {
  background-color: #fff;
}
.remove-alert-modal .body .rb-button {
  background-color: #d7263d;
}
.remove-alert-modal .modal-footer {
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
