<template>
  <div class="u-spacing-mr-s">
    <div
      class="u-display-flex u-flex-align-items-center u-color-grey-lighter u-border-color-grey-lighter u-border-width-s u-border-right u-border-color-grey-xxx-light"
    >
      <div
        v-for="(legend, index) in legends"
        :key="index"
        class="u-font-size-6 u-display-flex u-flex-align-items-center"
      >
        <div class="">
          <rb-icon
            :class="legend.icon.class"
            class="rb-icon--medium"
            :icon="(legend.icon || {}).icon || 'dot'"
          />
        </div>
        <div
          class="u-spacing-mr-m u-color-grey-base u-display-flex u-flex-align-items-center"
        >
          {{ (legend.text || {}).value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    legends: {
      type: Array,
      default: () => []
    },
    externalClasses: {
      type: [Array, Object],
      default: () => []
    }
  }
};
</script>

<style></style>
