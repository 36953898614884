<template>
  <div class="u-display-flex u-display-flex u-flex-align-items-center">
    <div
      v-for="(item, i) in buttonOptions"
      :key="i"
      :class="!item.selected && 'inactive'"
    >
      <button
        :class="
          item.selected
            ? 'u-spacing-mt-m u-font-family-ProximaNova u-font-size-6 u-font-color--active u-font-weight-400 u-spacing-pl-s u-spacing-pr-s u-spacing-pt-xs u-spacing-pb-xs u-spacing-mr-m u-bg-color-blue-xx-light u-cursor-pointer  u-border-color-blue-xxlight u-border-radius-xl '
            : 'u-cursor-pointer u-font-family-ProximaNova u-bg-color-white-base u-font-color--inactive u-border-radius-xl u-font-weight-400 u-font-size-6 u-spacing-pl-s u-spacing-pr-s u-spacing-pt-xs u-spacing-pb-xs u-border-all u-bg-color-grey-xx-light u-spacing-mt-m u-spacing-mr-m'
        "
        data-cy="top-contributor-tab"
        @click="setSelected(item.value)"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonOptions: {
      type: Array,
      default: () => {
        return [
          {
            type: 'SKU',
            label: 'Button'
          }
        ];
      }
    }
  },
  methods: {
    setSelected(selectedButton) {
      this.$emit('buttonChanged', selectedButton);
    }
  }
};
</script>
<style lang="css" scoped>
.u-border-radius-xl {
  border-radius: 26px;
}
.u-border-color-blue-xxlight {
  border: 1px solid #007cf6;
}
.u-bg-color-grey-xx-light {
  border: solid 1px #e9eaeb;
}

.inactive :hover {
  border: 1px solid grey;
}
.u-bg-color-blue-xx-light {
  background-color: rgba(0, 124, 246, 0.1);
}
.u-font-color--active {
  color: #2b333b;
}
.u-font-color--inactive {
  color: #6a7075;
}
.u-font-family-ProximaNova {
  font-family: ProximaNova;
}
</style>
