<template>
  <div class="u-cursor-default u-spacing-mv-s">
    <div
      v-tippy="{ placement: 'top', arrow: false }"
      class="loaderCard card u-spacing-ph-l u-flex-1 u-spacing-pv-m u-width-100"
      :title="filename"
      :style="{
        background: fileUploadStatus === 'complete' ? '#3ea95e' : '#fff'
      }"
      :class="{ errorBorder: fileUploadStatus === 'error' }"
    >
      <div
        v-if="fileUploadStatus === 'started'"
        class=""
      >
        <div
          class="u-display-flex u-width-100 u-flex-justify-content-space-between u-font-weight-600"
        >
          <div class="u-font-size-4 u-font-weight-600 u-text-overflow-ellipsis">
            {{ filename }}
          </div>
        </div>
        <div
          class="u-spacing-mv-s"
          :class="fileUploadStatus === 'started' ? 'loader' : ''"
        />
      </div>
      <div
        v-else
        class="u-width-100 container"
      >
        <div
          class="card-stack"
          :class="{ 'u-color-grey-white': fileUploadStatus === 'complete' }"
        >
          <div
            class="u-font-size-6 u-width-100 u-display-flex u-flex-align-items-center"
          >
            <rb-icon
              class="rb-icon--bp-custom"
              :class="fileUploadStatus === 'error' ? 'u-color-red-base' : ''"
              :icon="
                fileUploadStatus === 'complete'
                  ? 'check-fill-circle'
                  : 'info-circle-fill'
              "
              :style="
                fileUploadStatus === 'complete'
                  ? ''
                  : 'transform: rotate(180deg)'
              "
            />
            <div
              class="u-spacing-ph-s u-text-overflow-ellipsis u-width-100 u-font-weight-600 u-font-size-4 u-font-weight-600 u-text-overflow-ellipsis"
            >
              {{ filename }}
            </div>
          </div>
          <div
            class="u-font-size-6 u-width-100 u-display-flex u-flex-align-items-flex-start"
          >
            <rb-icon
              class="u-visibility-hidden rb-icon--bp-custom"
              :class="fileUploadStatus === 'error' ? 'u-color-red-base' : ''"
              :icon="
                fileUploadStatus === 'complete'
                  ? 'check-fill-circle'
                  : 'info-circle-fill'
              "
              :style="
                fileUploadStatus === 'complete'
                  ? ''
                  : 'transform: rotate(180deg)'
              "
            />
            <div
              class="u-spacing-ph-s u-spacing-pv-xs u-text-overflow-ellipsis u-width-100 u-font-size-7 u-font-weight-600 u-text-overflow-ellipsis"
            >
              <div
                class=""
                :class="fileUploadStatus === 'error' ? 'u-color-red-base' : ''"
              >
                {{ message }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="buttons u-display-flex u-flex-justify-content-flex-end on-hover-show u-position-absolute"
        >
          <div
            v-if="
              (fileUploadStatus === 'error' ||
                fileUploadStatus === 'complete') &&
              filePath
            "
            class="rb-icon rb-icon--x-m u-spacing-mh-s u-cursor-pointer icon-download"
            :class="fileUploadStatus === 'error' ? '' : 'u-color-grey-white'"
            @click="onClickDownload()"
          />
          <span @click="onClickRemove(filename)">
            <rb-icon
              v-if="
                fileUploadStatus === 'error' || fileUploadStatus === 'complete'
              "
              class="rb-icon--x-medium u-cursor-pointer"
              :class="fileUploadStatus === 'error' ? '' : 'u-color-grey-white'"
              :icon="'trash'"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="fileUploadStatus === 'error'"
      class="u-display-flex u-spacing-m-xs u-flex-align-items-center"
      @click="onClickDownload()"
    >
      <rb-icon
        class="rb-icon--small"
        :icon="'info-circle-fill'"
        :style="
          fileUploadStatus === 'complete' ? '' : 'transform: rotate(180deg)'
        "
      />
      <div class="u-font-size-8 u-cursor-pointer u-spacing-ph-xxs">
        Download To Fix
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filename: {
      type: String,
      default: null
    },
    fileUploadStatus: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    filePath: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onClickRemove(fileId) {
      this.$emit('deleteFile', fileId);
      // this.$store.dispatch('removeFile', fileId)
    },
    onClickDownload() {
      this.$store.dispatch('downloadFile', this.filePath);
      this.$snackbar.open({
        message: 'Download will begin shortly',
        duration: 5000,
        actionText: ' '
      });
    }
  }
};
</script>
<style lang="css" scoped>
.errorButton {
  background: #fff;
  color: #007cf6;
}
.step-workspace {
  padding-top: 40px;
}
.errorLoader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #d9263e33;
  border-radius: 100px;
}
.errorLoader:before {
  display: block;
  position: absolute;
  content: '';
  width: 80%;
  height: 8px;
  background-color: #d7263d;
  animation: loading 1s linear;
}
.loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #3ea95e;
  animation: loading 30s linear;
}
.loaderCard {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(43, 51, 59, 0.15);
}
.container {
  position: relative;
}
.card-stack {
  opacity: 1;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.buttons {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  right: -2%;
}
.container:hover .card-stack {
  opacity: 0.2;
}
.container:hover .buttons {
  opacity: 1;
}
.rb-button--hollow {
  color: #007cf6;
  border: 1px solid #007cf6;
  padding-left: 20px;
  padding-right: 20px;
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.rb-icon--bp-custom {
  height: 19px;
  width: 19px;
}
.file-container {
  height: 100px;
  align-items: flex-start;
}
.rb-icon--bp-custom {
  height: 16px;
  width: 16px;
}
.rb-icon--bp-custom:before {
  font-size: 16px;
}
.card {
  min-height: 64px;
  max-height: 64px;
  max-width: 320px;
  min-width: 320px;
}
</style>
