var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start u-flex-wrap-yes"},[_c('div',{staticClass:"u-display-flex u-flex-wrap-yes u-flex-align-items-center"},[_vm._l((_vm.localMetricDisplayList),function(val,index){return _c('div',{key:index,staticClass:"metric-card-hover-trigger u-spacing-mt-l",class:{ disabled: !_vm.computedSelectedMetric[val.key] },attrs:{"val":val},on:{"click":function($event){$event.stopPropagation();return _vm.selectNewMetric(index, val)}}},[_c('div',{staticClass:"custom-chart-events-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"},[_c('div',{staticClass:"u-spacing-p-xs"},[_c('div',{staticClass:"u-flex-complete-center u-display-flex u-flex-align-items-center"},[_c('rb-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  placement: 'bottom',
                  arrow: true,
                  theme: 'page-snapshot'
                }),expression:"{\n                  placement: 'bottom',\n                  arrow: true,\n                  theme: 'page-snapshot'\n                }"}],staticClass:"rb-icon--x-medium u-spacing-mr-s",class:val.color,attrs:{"icon":val.icon,"title":!_vm.computedSelectedMetric[val.key]
                    ? 'Show Event'
                    : 'Hide Event'}}),(val)?_c('rb-select',{staticClass:"u-spacing-mr-s",attrs:{"width":'240px',"context":[index],"send-details":true,"on-close":_vm.metricSelected,"options":_vm.computedMetricsList,"class-name":'campaigns-select',"search-enable-threshold":_vm.searchEnableThreshold},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('rb-icon',{staticClass:"u-spacing-mr-s",class:option.color,attrs:{"icon":option.icon}}),_c('p',{staticClass:"u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"},[_vm._v(" "+_vm._s(option.title)+" ")])],1),_c('span',{class:{
                          'u-color-grey-xx-light': option.eventCount === 0,
                          'u-color-grey-mid-light': option.eventCount !== 0
                        }},[_vm._v(_vm._s(option.eventCount))])])])]}}],null,true)},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"},[_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(val.key))]),_c('span',{staticClass:"u-font-size-5 u-spacing-ml-s",class:{
                        'u-color-grey-xx-light': val.eventCount === 0,
                        'u-color-grey-mid-light': val.eventCount !== 0
                      }},[_vm._v(_vm._s(val.eventCount))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])]):_vm._e()],1)])])])}),(!_vm.disableAddNewEvent)?_c('div',[_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"send-details":true,"on-close":_vm.addNewMetric,"options":_vm.computedMetricsList,"class-name":'campaigns-select u-height-100 select-trigger-height-100',"search-enable-threshold":_vm.searchEnableThreshold},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-start"},[_c('rb-icon',{staticClass:"u-spacing-mr-s",class:option.color,attrs:{"icon":option.icon}}),_c('p',{staticClass:"u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"},[_vm._v(" "+_vm._s(option.title)+" ")])],1)])]}}],null,false,235573651)},[_c('div',{staticClass:"metric-card-hover-trigger u-spacing-mt-l",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"custom-chart-events-legend u-display-flex u-flex-align-items-center u-font-size-5 u-color-grey-lighter u-position-relative u-cursor-pointer workbench-hover-card add-event-card u-flex-justify-content-center"},[_c('rb-icon',{staticClass:"rb-icon--small u-cursor-pointer u-spacing-mr-xs icon-add-circle-fill",attrs:{"icon":'add-circle-fill'}}),_c('span',{},[_vm._v(" Add event ")])],1)])])],1):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }