import HomeV2 from '@/components/homeV2.vue';
import DashboardServiceCMRouteWrapper from '@/pages/dashboard_service_campaign_management/DashboardServiceCMRouteWrapper.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import { commonInternalRoutesArray } from '@/router/albertsons.js';
import {
  digitalShelfRoute,
  reportsRoute,
  homeRoute,
  advertisingRoute,
  campaignOptimizerChildrenRoutes,
  hourlyBidderRoutes
} from '@/router/commonRoutePaths.js';
import CONSTANTS from '@/utils/constants';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

export const getCampaignManagementChildrenRoutes = (
  retailer = 'kroger_promoteiq'
) => {
  const routes = [
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_campaigns`,
      props: () => ({
        tabName: 'Campaigns',
        configKey: retailer
      })
    },
    {
      path: 'ad_skus',
      name: 'AMS SKUs',
      title: 'Ad SKUs',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_ad_skus`,
      props: () => ({
        tabName: 'Ad SKUs',
        configKey: `${retailer}`
      })
    },
    {
      path: 'ad_groups',
      name: 'AD Groups',
      title: 'Ad Groups',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_ad_skus`,
      props: () => ({
        tabName: 'Ad Groups',
        configKey: `${retailer}`
      })
    },
    {
      path: 'regions',
      name: 'Regions',
      title: 'Regions',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_regions`,
      props: () => ({
        tabName: 'Regions',
        configKey: `${retailer}`
      })
    },
    {
      path: 'categories',
      name: 'Categories',
      title: 'Categories',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_categories`,
      props: () => ({
        tabName: 'Categories',
        configKey: `${retailer}`
      })
    },
    {
      path: 'keywords',
      name: 'Keywords',
      title: 'Keywords',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: `${retailer}_keywords`,
      props: () => ({
        tabName: 'Keywords',
        configKey: `${retailer}`
      })
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM,
      appendEsDataSetName: true
    }
  }));
  routes.push({
    path: '',
    redirect: () => {
      return 'campaigns';
    }
  });
  return routes;
};

const krogerPromoteIqRoutes = {
  path: '/us/kroger_promoteiq/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      ...homeRoute,
      redirect: 'digital_shelf/market_insights'
    },
    reportsRoute,
    digitalShelfRoute,
    {
      ...advertisingRoute,
      children: [
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes.map((route) => ({
            ...route,
            meta: {
              productLine: CONSTANTS.PRODUCT_LINE.RMM,
              appendEsDataSetName: true
            }
          }))
        },
        ...hourlyBidderRoutes,
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: getCampaignManagementChildrenRoutes()
        },
        {
          path: '',
          redirect: () => {
            return 'campaign_management';
          }
        }
      ]
    },
    ...commonInternalRoutesArray
  ]
};

export { krogerPromoteIqRoutes };
