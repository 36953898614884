<template>
  <div
    class="u-spacing-p-l u-border-all u-border-color-grey-xxx-light u-border-width-s u-border-radius-s"
  >
    <div class="u-font-weight-600 info-box-tips">
      <rb-icon
        class="iq-icon u-color-blue-base"
        icon="light-bulb"
      /><span class="u-color-blue-base">IQ </span>Tip
    </div>
    <div
      v-if="title && title.length"
      class="u-font-size-7 u-spacing-pt-m u-color-grey-light"
    >
      {{ title }}
    </div>
    <div
      v-if="description && description.length"
      class="u-color-grey-mid-light u-spacing-pt-s u-font-size-7"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: null,
      type: String
    },
    description: {
      default: null,
      type: String
    }
  }
};
</script>

<style lang="css" scoped>
.info-box-tips {
  font-size: 24px;
}
.info-box-tips .iq-icon {
  height: 24px;
  width: 24px;
}
.info-box-tips .iq-icon:before {
  font-size: 24px;
}
</style>
