/* ====================================================================================
================================== config snapshot ====================================
{
  icon: 'task-status',  ____________________________ icon-name as per library, to be displayed on button
  title: 'ADJUST BID',  ____________________________ title of the button, appears below icon on the button
  id: 'task-status',    ____________________________ identifier passed in click-handler, identifies which button was clicked
  dropDown: true,       ____________________________ Boolean; If true, button behaves as dropdown, else like normal click button
  dropDownOptions: [    ____________________________ default options for dropdownList
    {title: 'Enable'},
    {title: 'Pause'},
    {title: 'Archive'}
  ],
  primaryPage: ['workbench'], ______________________ if the action is allowed in the primary section. eg. here it is allowed in workbench section
  secondaryPage: ['campaigns', 'ams-skus'], ________ if the action is allowed in the seconadary section. eg. here it is allowed in only 'campaigns', 'ams-skus'
  user: {                     ______________________ user level access, if admin is true, marketingIQ and salesIQ values are ignored, else either of the value is used.
    admin: true,
    marketingIQ: true,
    salesIQ: false
  },
  product: {            ____________________________ product level access, if salesAndMarketing is true, it will be available, else marketingIQ and salesIQ determines accesibilty
    salesAndMarketing: false
    marketingIQ: true
  }
}
=======================================================================================
==================================================================================== */
import redirectConfig from '@/router/redirectConfig.js';

var config = [
  {
    icon: 'show-chart',
    title: 'PLOT ROWS',
    id: 'show-chart',
    primaryPage: ['campaign-management'],
    secondaryPage: ['keywords', 'campaigns', 'search-terms', 'ams-skus'],
    user: {
      readOnly: true,
      admin: true,
      marketingIQ: true,
      salesIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'dollar',
    title: 'ADJUST BID',
    id: 'adjust-bids',
    primaryPage: [
      'campaign-management',
      'budget-optimizer',
      'budget-optimizer-v2'
    ],
    secondaryPage: ['campaigns', 'keywords', 'actions'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'dollar',
    title: 'ADJUST BUDGET',
    id: 'adjust-budget',
    primaryPage: ['budget-optimizer', 'budget-optimizer-v2'],
    secondaryPage: ['actions'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'task-status',
    title: 'EDIT STATUS',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
      { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
      { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
    ],
    primaryPage: ['campaign-management'],
    secondaryPage: ['keywords', 'campaigns', 'ams-skus'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'add-circle-outline',
    title: 'ADD KEYWORDS',
    id: 'add-keywords',
    primaryPage: ['campaign-management'],
    secondaryPage: ['campaigns', 'search-terms'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'day-parting',
    title: 'Day Part',
    id: 'day-part',
    primaryPage: ['campaign-management'],
    secondaryPage: ['campaigns'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'sku-box',
    title: 'Add SKUs',
    id: 'add-asins',
    primaryPage: ['campaign-management'],
    secondaryPage: ['campaigns'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'remove-fill',
    title: 'ADD NEGATIVE KEYWORDS',
    id: 'add-negative',
    primaryPage: ['campaign-management'],
    secondaryPage: ['campaigns', 'search-terms'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'check-fill-circle',
    title: 'Approve Actions',
    id: 'approve-actions',
    primaryPage: ['budget-optimizer', 'budget-optimizer-v2'],
    secondaryPage: ['actions'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'pencil',
    title: 'Edit',
    id: 'edit-actions',
    retailer: [],
    primaryPage: [],
    secondaryPage: ['actions'],
    user: {
      admin: true,
      marketingIQ: true
    },
    product: {
      marketingIQ: true
    }
  },
  {
    icon: 'show-chart',
    title: 'ADD TAG',
    id: 'add-tag',
    // primaryPage: ['insights', 'campaign-management', 'workbench'],
    // secondaryPage: ['share-of-voice', 'search-terms', 'keywords', 'ams-skus', 'skus', 'campaigns'],
    primaryPage: ['campaign-management'],
    secondaryPage: ['campaigns'],
    user: {
      readOnly: true,
      admin: true,
      marketingIQ: true,
      salesIQ: true
    },
    product: {
      marketingIQ: true
    }
  }
];
//
// To get user role as single Value
function getCurrentUserType(userConfigs) {
  if (userConfigs.length === 0) {
    return 'readOnly';
  }
  if (userConfigs.indexOf('ADMIN') > -1) {
    return 'admin';
  } else if (userConfigs.indexOf('MarketingIQ') > -1) {
    return 'marketingIQ';
  } else {
    return 'salesIQ';
  }
}

//
// To decide if it's product level config as single value
function getCurrentProductType(productConfigs) {
  var sales = false;
  var marketing = false;
  if (productConfigs.indexOf('SalesIQ') > -1) {
    sales = true;
  }
  if (productConfigs.indexOf('MarketingIQ') > -1) {
    marketing = true;
  }
  if (sales === true && marketing === true) {
    return 'salesAndMarketing';
  } else if (sales === true && marketing === false) {
    return 'salesIQ';
  } else if (sales === false && marketing === true) {
    return 'marketingIQ';
  }
}

//
// Filtering the options that needs to be returned as per user and product configs
function filterConfigs(config, user, product, isDaypartingEnabled) {
  var userMatch = false;
  var productMatch = false;
  var pageMatch = false;
  /**
   * Old implementation
   * var pathElements = window.location.pathname.split('/');
   *  pathElements.splice(0, 1);
   */
  var pathElements = redirectConfig.filter(
    (item) =>
      window.location.pathname.includes(item.redirect) &&
      item.path !== 'budget-optimizer'
  );
  pathElements = pathElements[0]?.path?.split('/') || [];
  if (user === 'admin' || config.user[user] === true) {
    userMatch = true;
  }
  if (product === 'salesAndMarketing' || config.product[product] === true) {
    productMatch = true;
  }
  if (
    config.primaryPage.indexOf(pathElements[0]) > -1 &&
    config.secondaryPage.indexOf(pathElements[1]) > -1
  ) {
    pageMatch = true;
  }

  if (config.id === 'day-part' && !isDaypartingEnabled) {
    return;
  }
  return userMatch && pageMatch && productMatch;
}

//
// the function returns the allowed actions as per the configs of user and product
function getCurrentConfigs() {
  var userConfigs = this.$store.getters.getSessionUser.roles;
  var productConfigs = this.$store.getters.getProductConfig;
  var currentUser = getCurrentUserType(userConfigs);
  var currentProduct = getCurrentProductType(productConfigs);
  var configsToRead = JSON.parse(JSON.stringify(config));
  var result = configsToRead.filter((elm) => {
    const isDaypartingEnabled =
      this.$options.filters.config_check('feature.dayparting');
    return filterConfigs(elm, currentUser, currentProduct, isDaypartingEnabled);
  });
  return result;
}

export default {
  getConfigs: getCurrentConfigs
};
