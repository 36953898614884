import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { eventBus } from '@/utils/services/eventBus';
import { cloneDeep } from 'lodash';

const state = {
  overrideToDate: null,
  priceWarFilters: [],
  ignoreRetry: false,
  insightsPricewarRetry: [],
  insightsPriceWarData: {},
  pageSettings: [
    {
      dimensionName: 'price_war_index',
      dimensionValue: '0.5',
      operator: 'GREATER_THAN_OR_EQUAL_TO'
    }
  ],
  insightsPriceWarSelectedFilter: {
    date_range: {
      name: 'Last 30 days'
    }
  },
  insightsPriceWarFilterValues: {},
  insightsPriceWarWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  priceComparisonChartDate: null,
  selectedAsin: {},
  mapping: {
    pwi_avg: 'PWI',
    ordered_revenue: 'Revenue',
    count: 'SKU Count',
    feed_date: 'feed_date'
  },
  filtersMapping: {
    pricewar_asin: 'asin',
    pricewar_comp_name: 'comp_name',
    pricewar_client_category: 'client_category',
    pricewar_client_subcategory: 'client_subcategory',
    pricewar_client_brand: 'client_brand',
    pricewar_product_group: 'product_group'
  },
  showFirstAsin: false,
  tableDataFetched: false,
  page_wise_min_max_key: 'price_war'
};

const getters = {
  getPriceComparisonChartDate: (state) => {
    return state.priceComparisonChartDate;
  },
  getInsightsPriceWarSelectedFilter: (state) => {
    return state.insightsPriceWarSelectedFilter;
  },
  getInsightsPriceWarData: (state) => {
    return state.insightsPriceWarData;
  },
  getPriceWarFilterValues: (state) => {
    return state.insightsPriceWarFilterValues;
  },
  getSelectedAsin: (state) => {
    return state.selectedAsin;
  },
  getPriceWarPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  SET_PW_SETTINGS: (state, data) => {
    state.pageSettings = data;
  },
  SUCCESS: (state, data) => {
    Vue.set(state.insightsPriceWarData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsPriceWarData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends,
        classes: data.classes
      });
    } else {
      Vue.set(state.insightsPriceWarData[data.key], 'data', data.columns);
    }
    Vue.set(state.insightsPriceWarData[data.key], 'rows', data.rows);
    Vue.set(state.insightsPriceWarData[data.key], 'custom', data.custom);
    Vue.set(state.insightsPriceWarData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.insightsPriceWarData[data.key], 'metrics', data.metrics);
    Vue.set(state.insightsPriceWarData[data.key], 'load', false);
    Vue.set(state.insightsPriceWarData[data.key], 'page', data.page);
    Vue.set(state.insightsPriceWarData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsPriceWarData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsPriceWarData[data.key], 'noData', true);
    }
  },
  RESET: (state, data) => {
    Vue.set(state.insightsPriceWarData, data.key, {});
    Vue.set(state.insightsPriceWarData[data.key], 'data', []);
    Vue.set(state.insightsPriceWarData[data.key], 'load', true);
    Vue.set(state.insightsPriceWarData[data.key], 'error', false);
    Vue.set(state.insightsPriceWarData[data.key], 'noData', false);
  },
  ERROR: (state, data) => {
    Vue.set(state.insightsPriceWarData, data.key, {});
    Vue.set(state.insightsPriceWarData[data.key], 'data', []);
    Vue.set(state.insightsPriceWarData[data.key], 'load', false);
    Vue.set(state.insightsPriceWarData[data.key], 'error', true);
    Vue.set(state.insightsPriceWarData[data.key], 'noData', false);
  },
  SET_IP_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsPriceWarSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat5(data, state);
  },
  SET_FILTER_VALUES: (state, data) => {
    Vue.set(
      state.insightsPriceWarFilterValues,
      data.filterValueKey,
      data.values
    );
  },
  IP_RETRY: (state, data) => {
    state.insightsPricewarRetry.push(data);
  },
  ASIN_SELECTED: (state, data) => {
    state.selectedAsin = data;
  },
  SHOW_FIRST_ASIN: (state, data) => {
    state.showFirstAsin = data;
  },
  SET_PRICE_COMPARISON_CHART_DATE: (state, data) => {
    state.priceComparisonChartDate = data;
  },
  TABLE_DATA_FETCHED: (state, data) => {
    state.tableDataFetched = data;
  },
  STORE_TO_DATE_FROM_URL: (state, data) => {
    state.overrideToDate = data;
  }
};

function getWhereClauseDate(context, maxdate) {
  if (!maxdate) {
    maxdate = 'price_war';
  }

  var returnDate = {
    to: context.getters.getDateRangeValues[maxdate][
      context.state.insightsPriceWarSelectedFilter.date_range.name
    ].to,
    from: context.getters.getDateRangeValues[maxdate][
      context.state.insightsPriceWarSelectedFilter.date_range.name
    ].from,
    name: context.getters.getDateRangeValues[maxdate][
      context.state.insightsPriceWarSelectedFilter.date_range.name
    ].name,
    page_wise_min_max_key: state.page_wise_min_max_key
  };

  if (context.state.overrideToDate) {
    var _date = new Date(context.state.overrideToDate);
    _date.setDate(_date.getDate() - 30);
    var year = _date.getFullYear();
    var month = '0' + (_date.getMonth() + 1);
    var date = '0' + _date.getDate();

    returnDate = {
      from: [
        year,
        month.substr(month.length - 2, month.length),
        date.substr(date.length - 2, date.length)
      ].join('-'),
      to: context.state.overrideToDate,
      page_wise_min_max_key: state.page_wise_min_max_key
    };
  }

  return returnDate;
}

function getFiltersInFormat5(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

function getChartDataInFormat(_aArray, data, response, replace) {
  var _oObj = {};
  if (response === null) {
    return [];
  }
  for (var i = 0; i < response.length; i++) {
    for (var j in response[i]) {
      if (replace[j] !== undefined) {
        if (!_oObj[j]) {
          _oObj[j] = [j];
        }
        _oObj[j].push(response[i][j]);
      }
    }
  }
  for (i in replace) {
    if (_oObj[i] !== undefined) {
      _oObj[i][0] = replace[i];
    }
  }

  for (var k in _oObj) {
    _aArray.push(_oObj[k]);
  }

  return _aArray;
}

function getPriceWarFilterData(groupByList, measuresList, context) {
  var data = {
    APIData: {
      cubeName: 'price_war_v2',
      measuresList: measuresList,
      groupByDimensionsList: groupByList,
      timeseriesDimension: 'FEED_DATE',
      where: {
        date: {
          // 'from': '2017-01-01',
          // 'to': context.getters.getTodaysDate
        },
        dimensionNameValueList: []
      },
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      orderByList: []
    }
  };

  data.APIData.where.date = getWhereClauseDate(context);
  return HttpLayer.post(data);
}

const actions = {
  storeOverrideToDate: (context, data) => {
    context.commit('STORE_TO_DATE_FROM_URL', data);
  },
  getPriceGraphFiltersData: (context, data) => {
    context.commit('SHOW_FIRST_ASIN', false);
    if (data.meta.from !== 'search') {
      context.commit('SET_FILTER_VALUES', {
        filterValueKey: 'pricewar_asin',
        values: []
      });
    }

    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.where.date = getWhereClauseDate(context);
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    for (
      var i = 0;
      i < data.body.APIConfig.where.dimensionNameValueList.length;
      i++
    ) {
      if (
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
          'asin' ||
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
          'price_war_index'
      ) {
        data.body.APIConfig.where.dimensionNameValueList.splice(i, 1);
      }
    }

    HttpLayer.post({
      APIData: {
        cubeName: 'price_war_v2',
        measuresList: ['asin_name', 'client_image_url', 'ordered_revenue'],
        groupByDimensionsList: ['asin'],
        timeseriesDimension: 'FEED_DATE',
        where: data.body.APIConfig.where,
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false,
        limit: 50,
        page: 1,
        orderByList: [
          {
            dimension: 'ordered_revenue',
            direction: 'DESC'
          }
        ],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: []
        }
      }
    })
      .then((response) => {
        var _aArray = [];
        if (response.success && response.data.length > 0) {
          _aArray = transformer.mergeResultDimension(response.data);
        }

        context.commit('SET_FILTER_VALUES', {
          filterValueKey: 'pricewar_asin',
          values: _aArray
        });

        if (_aArray.length > 0 && context.state.showFirstAsin) {
          eventBus.$emit('pricewarAsinSelected', _aArray[0]);
        }

        if (_aArray.length === 0 && data.meta.from !== 'search') {
          context.commit('SUCCESS', {
            key: data.meta.key,
            rows: []
          });
          eventBus.$emit('pricewarAsinSelected', null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPriceGraphData: (context, data) => {
    if (!context.state.tableDataFetched) {
      return;
    }

    context.commit('RESET', {
      key: data.meta.key
    });

    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    if (!context.getters.getPriceComparisonChartDate) {
      data.body.APIConfig.where.date = getWhereClauseDate(context, 'pi');
    } else {
      data.body.APIConfig.where.date =
        context.getters.getPriceComparisonChartDate.date;
      if (context.getters.getPriceComparisonChartDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.getters.getPriceComparisonChartDate.pvpDate;
      }
    }

    for (
      var i = 0;
      i < data.body.APIConfig.where.dimensionNameValueList.length;
      i++
    ) {
      if (
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
        'search'
      ) {
        data.body.APIConfig.where.dimensionNameValueList.splice(i, 1);
      }
    }

    HttpLayer.all([
      HttpLayer.post({
        APIData: {
          cubeName: 'price_war_v2',
          measuresList: [
            'client_offer_price',
            'client_product_url',
            'buybox_seller_name_str',
            'buybox_offer_price',
            'unkn_seller_name_str',
            'unkn_offer_price',
            'unit_cogs',
            'is_match_available',
            'client_offer_price_sum',
            'unit_cogs_sum',
            'buybox_offer_price_sum',
            'unkn_offer_price_sum'
          ],
          groupByDimensionsList: [],
          timeseriesDimension: 'feed_date',
          getLatestAvailableInsteadOfRollup: false,
          timeseriesEnabled: true,
          pvpenabled: true,
          yoyenabled: false,
          where: data.body.APIConfig.where
        }
      }),
      HttpLayer.post({
        APIData: data.body.APIConfig
      })
    ])
      .then(function (responses) {
        var xs = {};
        var _aArray = [];
        var legends = {};
        var classes = {};
        var response = responses[0];
        if (!response.success) {
          context.commit('ERROR', { key: data.meta.key });
        } else {
          _aArray = getChartDataInFormat(
            _aArray,
            data,
            response.data[0].TIMESERIES,
            {
              client_offer_price:
                response.data[0].RESULT.client_offer_price_sum !== null
                  ? context.getters.getMarketPlace
                  : undefined,
              feed_date:
                response.data[0].RESULT.client_offer_price_sum !== null ||
                response.data[0].RESULT.unit_cogs_sum !== null ||
                response.data[0].RESULT.buybox_offer_price_sum !== null ||
                response.data[0].RESULT.unkn_offer_price_sum !== null
                  ? 'feed_date_' + context.getters.getMarketPlace
                  : undefined,
              unit_cogs:
                response.data[0].RESULT.unit_cogs_sum !== null
                  ? context.getters.getOutsideIn
                    ? 'Unit COGS'
                    : undefined
                  : undefined,
              buybox_offer_price:
                response.data[0].RESULT.buybox_offer_price_sum !== null
                  ? context.getters.getOutsideIn
                    ? 'Buy box'
                    : undefined
                  : undefined,
              unkn_offer_price:
                response.data[0].RESULT.unkn_offer_price_sum !== null
                  ? context.getters.getOutsideIn
                    ? 'Lowest 3P Seller'
                    : undefined
                  : undefined
            }
          );

          xs[context.getters.getMarketPlace] =
            'feed_date_' + context.getters.getMarketPlace;
          if (context.getters.getOutsideIn) {
            xs['Unit COGS'] = 'feed_date_' + context.getters.getMarketPlace;
            xs['Buy box'] = 'feed_date_' + context.getters.getMarketPlace;
            xs['Lowest 3P Seller'] =
              'feed_date_' + context.getters.getMarketPlace;
          }

          if (_aArray.length > 0) {
            legends[context.getters.getMarketPlace] =
              response.data[0].RESULT.client_product_url || null;
          }
          classes['Buy box'] = response.data[0].TIMESERIES;
          classes['Lowest 3P Seller'] = response.data[0].TIMESERIES;
        }

        response = responses[1];
        var compLength = 0;
        if (!response.success) {
          context.commit('ERROR', { key: data.meta.key });
        } else {
          if (response.data.length > 0) {
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].DIMENSION.comp_name) {
                if (response.data[i].RESULT.comp_offer_price_sum !== null) {
                  compLength = compLength + 1;
                  _aArray = getChartDataInFormat(
                    _aArray,
                    data,
                    response.data[i].TIMESERIES,
                    {
                      comp_offer_price:
                        response.data[i].RESULT.comp_offer_price_sum !== null
                          ? (
                              response.data[i].DIMENSION.comp_name || ''
                            ).capitalize()
                          : undefined,
                      feed_date:
                        response.data[i].RESULT.comp_offer_price_sum !== null
                          ? 'feed_date_' +
                            (
                              response.data[i].DIMENSION.comp_name || ''
                            ).capitalize()
                          : undefined
                    }
                  );

                  xs[
                    (response.data[i].DIMENSION.comp_name || '').capitalize()
                  ] =
                    'feed_date_' +
                    (response.data[i].DIMENSION.comp_name || '').capitalize();
                  legends[
                    (response.data[i].DIMENSION.comp_name || '').capitalize()
                  ] = response.data[i].RESULT.comp_url || null;
                }
              }
            }
            if (classes && classes['Buy box']) {
              classes['Buy box'].sort(function (a, b) {
                if (a.feed_date && b.feed_date) {
                  return (
                    new Date(a.feed_date).getTime() -
                    new Date(b.feed_date).getTime()
                  );
                } else {
                  return 0;
                }
              });
            }

            if (classes && classes['Lowest 3P Seller']) {
              classes['Lowest 3P Seller'].sort(function (a, b) {
                if (a.feed_date && b.feed_date) {
                  return (
                    new Date(a.feed_date).getTime() -
                    new Date(b.feed_date).getTime()
                  );
                } else {
                  return 0;
                }
              });
            }

            context.commit('SUCCESS', {
              key: data.meta.key,
              columns: _aArray,
              xs,
              legends,
              classes,
              page: data.body.APIConfig.page,
              custom: compLength === 0
            });
          } else {
            context.commit('SUCCESS', {
              key: data.meta.key,
              columns: [],
              page: data.body.APIConfig.page,
              metrics: undefined
            });
          }
        }
      })
      .catch((error) => {
        context.commit('ERROR', { key: data.meta.key });
        console.log(error);
      });
  },
  setPriceWarSettings: (context, data) => {
    context.commit('SET_PW_SETTINGS', data);
  },
  getPriceWarCountData: (context, data) => {
    context.commit('RESET', {
      key: data.meta.key
    });
    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        (data.meta.localFilters || []).concat(context.state.pageSettings || []),
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.where.date = getWhereClauseDate(context);
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        var totalCount = 0;
        var totalRevenue = 0;
        _aArray = transformer.mergeResultDimension(response.data, true);

        for (var i = 0; i < _aArray.length; i++) {
          totalCount = totalCount + _aArray[i].count || 0;
          totalRevenue = totalRevenue + _aArray[i].ordered_revenue || 0;
          _aArray[i].comp_name = (_aArray[i].comp_name || '').capitalize();
        }

        for (i = 0; i < _aArray.length; i++) {
          _aArray[i].count_perc = (_aArray[i].count || 0) / totalCount;
          _aArray[i].revenue_perc =
            (_aArray[i].ordered_revenue || 0) / totalRevenue;
        }

        context.commit('SUCCESS', {
          key: data.meta.key,
          columns: _aArray,
          metrics: data.body.getTagData
            ? data.body.getTagData(_aArray)
            : undefined,
          page: data.body.APIConfig.page
        });
      }
    });
  },
  getPWIData: (context, data) => {
    context.commit('RESET', {
      key: data.meta.key
    });
    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        (data.meta.localFilters || []).concat(context.state.pageSettings || []),
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.where.date = getWhereClauseDate(context);
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        var oColumns = {};
        var feedDateIndex = 0;

        _aArray = getChartDataInFormat(
          _aArray,
          data,
          response.data[0].TIMESERIES,
          context.state.mapping
        );

        for (var i = 0; i < _aArray.length; i++) {
          if (_aArray[i][0] === 'feed_date') {
            feedDateIndex = i;
            break;
          }
        }

        for (i = 0; i < _aArray.length; i++) {
          if (_aArray[i][0] !== 'feed_date') {
            oColumns[_aArray[i][0]] = [];
            oColumns[_aArray[i][0]].push(_aArray[feedDateIndex]);
            oColumns[_aArray[i][0]].push(_aArray[i]);
          }
        }

        if (Object.keys(oColumns).length === 0) {
          oColumns = [];
        }

        context.commit('SUCCESS', {
          key: data.meta.key,
          columns: oColumns,
          metrics: data.body.getTagData
            ? data.body.getTagData(response.data[0], context.state.mapping)
            : undefined,
          page: data.body.APIConfig.page
        });
      }
    });
  },
  downloadPricewarTableData: (context, data) => {
    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        (data.meta.localFilters || []).concat(context.state.pageSettings || []),
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.where.date = getWhereClauseDate(context);
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data);
    });
  },
  getPriceWarTableData: (context, data) => {
    context.commit('RESET', {
      key: data.meta.key
    });
    const transformedWhereClause = transformer.transformWhereClauseWithTag(
      transformer.getCompleteWhereClause(
        (data.meta.localFilters || []).concat(context.state.pageSettings || []),
        cloneDeep(context.state.whereClause)
      )
    );
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.where.date = getWhereClauseDate(context);
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ERROR', { key: data.meta.key });
      } else {
        context.commit('TABLE_DATA_FETCHED', true);
        var _aArray = transformer.mergeResultDimension(response.data);
        var totalRows = 5;
        if (_aArray.length > 0) {
          totalRows = _aArray[0].count_total_all;
          context.commit('ASIN_SELECTED', _aArray[0]);
          eventBus.$emit('pricewarAsinSelected', _aArray[0]);
        } else {
          if (
            context.state &&
            context.state.insightsPriceWarFilterValues &&
            context.state.insightsPriceWarFilterValues.pricewar_asin &&
            context.state.insightsPriceWarFilterValues.pricewar_asin.length > 0
          ) {
            eventBus.$emit(
              'pricewarAsinSelected',
              context.state.insightsPriceWarFilterValues.pricewar_asin[0]
            );
          } else {
            context.commit('SHOW_FIRST_ASIN', true);
          }
        }
        context.commit('SUCCESS', {
          key: data.meta.key,
          rows: _aArray,
          page: data.body.APIConfig.page,
          totalRows
        });
      }
    });
  },
  setSelectedAsin: (context, data) => {
    context.commit('ASIN_SELECTED', data);
  },
  setInsightsPriceWarSelectedFilter: (context, data) => {
    context.commit('SET_IP_SELECTED_FILTER', data);
  },
  setPriceComparisonChartDate: (context, data) => {
    context.commit('SET_PRICE_COMPARISON_CHART_DATE', data);
  },
  updatePricewarFilters: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.priceWarFilters || [],
        'pricewar_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  getPricewarFilters: (context, data) => {
    getPriceWarFilterData(
      [
        'comp_name',
        'product_group',
        'client_brand',
        'client_category',
        'client_subcategory'
      ],
      [],
      context
    )
      .then((response) => {
        context.state.priceWarFilters = response.data;
        var filters = transformer.getUniqueFilters(response.data, 'pricewar_');
        context.commit('SET_FILTER_DATA', filters);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
