<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-p-m"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-spacing-mt-m u-spacing-mb-m"
    >
      <section class="u-display-flex">
        <rb-icon
          icon="spotlight"
          class="rb-icon--large"
        />
        <p
          class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s u-spacing-pl-s"
        >
          MAP Violation prior by SKUs
        </p>
      </section>
      <section class="u-display-flex">
        <local-date-filter
          :tooltip="true"
          modal-position="Bottom"
          :value="dateRangeObj"
          @selected-value="selectedRange"
        />
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-spacing-pl-s"
          @click="sendEmail"
        >
          <rb-icon
            icon="email"
            class="u-color-blue-base"
          />
          <p class="link u-font-size-7 u-spacing-pl-xs u-spacing-pt-xxs">
            Email
          </p>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-spacing-ml-m"
        >
          <rb-icon
            icon="download"
            class="u-color-blue-base u-cursor-pointer"
          />
          <p class="link u-font-size-7 u-spacing-pl-xs">Download</p>
        </div>
      </section>
    </div>
    <section>
      <rb-select
        :search-enabled="false"
        :client-search="true"
        :width="'400px'"
        :send-details="true"
        :on-close="asinSelected"
        :options="imapSkuList"
        class="u-spacing-mb-m asin_select"
      >
        <div
          slot="trigger"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
        >
          <div
            class="u-border-all u-border-width-s u-border-color-grey-xxx-light"
          >
            <SkuComponentWithBadge
              :image-src="selectedSku.skuDetails.imageUrl"
              :asin-id="selectedSku.skuDetails.asin"
              :asin-title="selectedSku.skuDetails.title"
            />
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <div
              style="height: 48px; width: 48px; margin: 0px"
              class="media__img u-flex-none"
            >
              <img
                style="height: 48px; width: 48px; margin: 0px"
                :src="option.skuDetails.imageUrl"
              />
            </div>
            <div class="u-spacing-ml-m u-overflow-hidden">
              <p
                class="u-spacing-pb-s u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
              >
                {{ option.skuDetails.title }}
              </p>
              <p
                class="u-text-overflow-ellipsis u-color-grey-lighter u-font-size-5 u-text-case-title"
              >
                {{ option.skuDetails.asin }}
              </p>
            </div>
          </div>
        </template>
      </rb-select>
    </section>
    <section>
      <div
        v-for="(filter, index) in priceTrendRetailerFilter"
        :style="{
          'border-color': filter.color
        }"
        class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
      >
        <span
          class="removeLegend"
          @click="removeLegend(index)"
        >
          <rb-icon
            class="u-color-grey-x-light rb-icon--small u-cursor-pointer"
            :icon="'cross'"
          />
        </span>
        <rb-select
          v-if="filter.options.length"
          :width="'240px'"
          class="u-spacing-mr-m"
          :context="[index]"
          :send-details="true"
          :on-close="onRetailerSelection"
          :options="filter.options"
          :is-multi-select="true"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-cursor-pointer"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span class="u-font-size-5">{{
                filter.selectedOption.label
              }}</span>
              <rb-icon
                class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                :icon="'caret-down'"
              />
            </div>
          </div>
          <div
            slot="item"
            slot-scope="option"
          >
            <span>{{ option.label }}</span>
          </div>
        </rb-select>
        <span
          v-if="filter.value"
          class="u-font-size-4 u-color-grey-light u-font-weight-600 u-spacing-pt-s"
          >{{ filter.value }}</span
        >
      </div>
    </section>
    <chart
      :config="chartConfig"
      :data="priceTrendChartData"
    />
    <email
      :show-email-modal="showEmail"
      :email-details="emailDetails"
      @onSendEamil="raiseTicket"
      @onCancelEmail="cancelRaiseTicket"
    >
      <template slot="body">
        <p class="u-font-size-4 u-spacing-mt-s">
          Subject:
          <span class="u-text-transform-capitalize"
            >{{ selectedSku.retailer }} </span
          >SKU -
          <span class="u-font-weight-600">
            {{ selectedSku.skuDetails.asin }}
          </span>
          IMAP Violation, Urgent Action Required
        </p>
        <div class="u-font-size-4 u-spacing-mt-l">
          <p>
            We identified the
            <span class="u-font-weight-600">
              {{ selectedSku.skuDetails.asin }}
            </span>
            SKU under IMAP Violation. Please see the details below and address
            the IMAP violations.
          </p>
          <p class="u-spacing-mt-s">
            Map Price:
            <span class="u-font-weight-600"> {{ selectedSku.mapPrice }} </span>
          </p>
          <chart
            class="u-spacing-mt-l"
            :config="chartConfig"
            :data="priceTrendChartData"
          />
        </div>
        <p class="u-font-size-4 u-spacing-mt-l">
          Kindly provide the updated status of the open items/ in-progress
          items.
        </p>
      </template>
    </email>
  </div>
</template>

<script>
import chart from '@/components/basic/chart.vue';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import {
  RETAILER_LIST,
  IMAP_SKU_DATA
} from '@/pages/all_retailers/dso/constant';
import SkuComponentWithBadge from '@/components/basic/SkuComponentWithBadge.vue';
import email from '@/pages/all_retailers/dso/molecules/email.vue';

export default {
  name: 'PriceTrend',
  components: {
    chart,
    localDateFilter,
    SkuComponentWithBadge,
    email
  },
  data() {
    return {
      chartConfig: {
        chartOptions: {
          type: 'line',
          xFormat: '%m/%d/%Y',
          timeseries: 'price_trend_date',
          tooltip_format: {
            All: {
              roundoff: 0
            },
            Revenue: {
              pre: 'currency'
            }
          },
          xDataKey: 'x',
          grid: 'xy',
          color: {
            pattern: ['#97CC04', '#FF6072', '#FFA800']
          }
        }
      },
      dateRangeObj: {},
      selectedSku: {},
      retailers: RETAILER_LIST,
      selectedRetailer: {},
      imapSkuList: IMAP_SKU_DATA,
      showEmail: false,
      emailDetails: {}
    };
  },
  computed: {
    priceTrendRetailerFilter() {
      return [
        {
          selectedOption: { label: 'MAP Price', value: 'mapPrice' },
          options: [{ label: 'MAP Price', value: 'mapPrice' }],
          value: this.selectedSku.mapPrice,
          color: '#97CC04'
        },
        {
          selectedOption: this.retailers[0],
          options: this.retailers,
          value: '',
          color: '#FF6072'
        },
        {
          selectedOption: this.retailers[1],
          options: this.retailers,
          value: '',
          color: '#FFA800'
        }
      ];
    },
    priceTrendChartData() {
      return this.selectedSku.skuDetails.priceTrend;
    }
  },
  mounted() {
    this.selectedSku = this.imapSkuList[1];
  },
  methods: {
    selectedRange(range) {
      this.dateRangeObj = range;
    },
    asinSelected(context, selectedSkus) {
      this.selectedSku = selectedSkus[0];
      console.log(this.selectedSku);
    },
    onRetailerSelection(context, retailer) {
      this.selectedRetailer = retailer;
    },
    removeLegend(index) {},
    raiseTicket() {
      this.showEmail = false;
      this.notify();
    },
    cancelRaiseTicket() {
      this.showEmail = false;
    },
    sendEmail() {
      this.emailDetails = {
        to: 'vendor.manager@amazon.co',
        from: 'client@commerceiq.ai',
        body: ''
      };
      this.showEmail = true;
    },
    notify() {
      this.$snackbar.open({
        message: 'Ticket Raised',
        duration: 5000,
        buttonColor: '#007CF6',
        actionText: '',
        customStyles: 'background: #007cf6'
      });
    }
  }
};
</script>

<style scoped lang="css">
.chart-header {
  display: flex;
}
</style>
