<template>
  <div
    id="drop-area"
    ref="drop-area"
    class="u-cursor-pointer u-display-flex"
    :class="cssClasses"
    @click="openFileViewer"
  >
    <form
      class="my-form u-display-flex u-flex-justify-content-center u-width-100 u-flex-direction-column"
    >
      <rb-icon
        class="u-color-grey-xxx-light rb-icon--x-large is-flipped u-spacing-m-l"
        icon="download"
      />
      <input
        id="fileElm"
        ref="fileElm"
        type="file"
        :accept="formattedAcceptedFormat"
        :multiple="false"
        @change="processFile"
      />
      <span
        class="u-color-grey-lighter u-font-size-5 u-spacing-pb-l u-text-align-center"
        >Drag and drop the file to add or choose a file <br />
        from your computer. <br />
        <br />
        Accepted formats: {{ formattedAcceptedFormat }}
      </span>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    clickFileViewer: {
      type: Boolean,
      default: false
    },
    acceptFormat: {
      type: Array,
      default: () => {
        return ['.xlsx'];
      }
    },
    cssClasses: {
      type: String,
      default: 'u-width-100'
    }
  },
  data: () => ({
    dropArea: {},
    file: null
  }),
  computed: {
    formattedAcceptedFormat() {
      return this.acceptFormat.join(', ');
    }
  },
  mounted() {
    this.dropArea = this.$refs['drop-area'];
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.preventDefaults, false);
      document.body.addEventListener(eventName, this.preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.unhighlight, false);
    });
    this.dropArea.addEventListener('drop', this.handleDrop, false);
    if (this.clickFileViewer) {
      this.openFileViewer();
    }
  },
  methods: {
    openFileViewer() {
      this.$refs.fileElm.click();
    },
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight(e) {
      this.dropArea.classList.add('highlight');
    },
    unhighlight(e) {
      this.dropArea.classList.remove('highlight');
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const files = dt.files && dt.files[0];
      if (files) {
        this.handleFiles(files);
      }
    },
    processFile(e) {
      const file = e.target.files && e.target.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },
    handleFiles(file) {
      this.$emit('onFileUpload', file);
    }
  }
};
</script>
<style lang="css" scoped>
.rb-icon--x-large {
  height: 100px;
  width: 100px;
}
.rb-icon--x-large:before {
  font-size: 100px;
}
.notices .rb-snackbar {
  background: #d7263d !important;
}
#drop-area {
  border: 1px dashed #979797;
  background: #fafafa;
}
#drop-area.highlight {
  border-color: #007cf6;
}
.my-form {
  margin-bottom: 10px;
}
#fileElm {
  display: none;
}
</style>
