<template>
  <div
    class="u-spacing-pt-m top-contributor-card u-border-radius-m u-spacing-m-s u-border-width-s u-border-all u-border-color-grey-xxx-light u-width-100 u-height-100"
    :data-cy="'top-contributor-' + title"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <div
        class="u-width-100 u-color-x-grey-base u-font-size-5 u-font-weight-600 u-spacing-pl-m"
      >
        <strong> {{ title }}</strong>
      </div>
      <span
        class="u-flex-justify-content-flex-end u-width-100 u-display-flex u-flex-align-items-center u-spacing-pr-m"
      >
        <component
          :is="h_item.ui_component"
          v-for="(h_item, key) in headerOptions"
          :key="key"
          :is-loading="h_item.isLoading"
          :style="{
            'grid-column': h_item.grid_column,
            'grid-row': h_item.grid_row
          }"
          :class="h_item.icon_classes"
          :icon="h_item.icon"
          :tooltip-text="h_item.tooltipText"
          :is-metric-tool-tip-enabled="h_item.isMetricToolTipEnabled"
          @onClick="handler(h_item.icon)"
        />
      </span>
    </div>
    <div
      v-if="isEmpty"
      class="empty u-display-flex u-flex-justify-content-center u-flex-align-items-center"
    >
      <div
        class="u-font-size-3"
        data-cy="top-contributor-no-data"
      >
        No Data
      </div>
    </div>
    <div
      v-else
      class="invisible-scrollbar u-spacing-mt-m u-height-100"
      :style="
        isShippedCogsComparisonEnabled
          ? 'height100%;min-height:340px; max-height:400px '
          : 'height100%;min-height:300px; max-height:400px '
      "
    >
      <div
        v-if="isShippedCogsComparisonEnabled"
        class="u-display-flex u-flex-justify-content-space-between u-spacing-pt-m"
      >
        <div
          class="u-font-size-7 u-font-family-proximanova u-color-grey-xlight u-font-weight-600 u-font-style-normal u-spacing-pl-m"
        >
          {{ cardLevelTitle }}
        </div>
        <div
          class="u-font-size-7 u-font-family-proximanova u-font-weight-600 u-color-grey-xlight u-font-style-normal u-spacing-pr-m"
        >
          Absolute / Shipped COGS
        </div>
      </div>
      <div
        v-for="(row, idx) in rows"
        :key="idx"
        class="u-width-100 u-spacing-mt-m u-spacing-pr-l u-spacing-pl-s"
        :style="
          row.type === 'asin'
            ? isShippedCogsComparisonEnabled
              ? 'height:44px'
              : 'height:40px'
            : 'height:40px; padding-top:4px'
        "
      >
        <div
          class="row rowflex u-display-flex u-flex-justify-content-space-between u-width-100"
        >
          <div v-if="row.type === 'asin'">
            <rb-sku
              :id="idx"
              :title="row.payload.data['title']"
              :asin="row.payload.data['asin']"
              :image-url="row.payload.data['imageUrl']"
              :product-page-url="row.payload.data['productPageUrl']"
              :enable-s-d-p="true"
              @navigateToSDP="navigateToSDP(row.payload.data['asin'])"
            />
          </div>
          <div
            v-else
            class="u-display-flex-column u-flex-align-items-center u-font-size-6 u-font-weight-normal u-color-x-grey-base"
          >
            <div
              v-if="row.payload.url"
              class="polink"
            >
              <a
                :href="row.payload.url"
                target="_blank"
              >
                {{ row.payload.data }}
              </a>
            </div>
            <div v-else>
              {{ row.payload.data }}
            </div>
          </div>
          <div
            v-if="isShippedCogsComparisonEnabled"
            class="u-display-flex u-flex-align-items-center u-font-size-7 u-font-weight-bold u-text-align-left u-bg-color-blue"
          >
            <div
              v-tippy="tippy"
              class="u-display-flex u-flex-align-items-center"
              :title="row.payload.tooltipText ? row.payload.tooltipText : 'NA'"
            >
              <span :class="['overflow-styles u-display-block']">
                <span
                  class="u-font-size-7 u-font-weight-bold u-text-align-left u-color-x-grey-base"
                  >{{ row.payload.value }} /</span
                >
                <span class="shipCogstext u-font-size-7 u-color-x-grey-base">
                  {{ row.payload.shippedCogsValue }}</span
                >
              </span>
            </div>
          </div>
          <div
            v-else
            class="u-display-flex u-flex-align-items-center u-font-size-7 u-font-weight-bold u-text-align-left u-bg-color-blue"
          >
            {{ row.payload.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    rows: {
      type: Array,
      default: () => []
    },
    isEmpty: {
      type: Boolean,
      default: () => false
    },
    cardLevelTitle: {
      type: String,
      default: () => ''
    },
    cards: {
      type: Array,
      default: () => [
        {
          header: {
            title: 'Top SKU',
            description: 'Modified Description',
            download: 'Modified Download'
          }
        }
      ]
    },
    headerOptions: {
      type: Array,
      default: () => {
        return {
          grid_column: '1fr 1fr 1fr 1fr 1fr',
          ui_component: 'icon-text'
        };
      }
    },
    isShippedCogsComparisonEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippy: {
        placement: 'top',
        arrow: false,
        distance: 8,
        boundary: 'viewport'
      }
    };
  },

  methods: {
    handler(icon) {
      if (icon === 'share') this.$emit('download', this.title);
    },
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style scoped lang="css">
.invisible-scrollbar {
  overflow: scroll;
  background-color: rgba(0, 124, 246, 0.02);
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.rowflex .rb-sku {
  max-width: 200px;
}

.rowflex {
  justify-content: space-between !important;
}
.top-contributor-card {
  min-width: 150px;
  min-height: 320px;
  max-height: 400px;
}
.u-font-family-proximanova {
  font-family: ProximaNova;
}
.polink:hover {
  cursor: pointer;
  text-decoration: underline;
}
.u-color-x-grey-base {
  color: #2b333b;
}
.u-color-grey-xlight {
  color: #8b8f93;
}

.shipCogstext {
  font-weight: normal;
}
.empty {
  height: 260px;
}
</style>
