<template>
  <section
    class="u-display-flex u-flex-direction-column"
    :class="{ 'u-height-100': isLoading }"
  >
    <section
      class="filterSection sticky u-spacing-mb-0 z-index-2 u-header-box-shadow"
    >
      <div class="u-flex-0 u-bg-color-grey-white u-spacing-ph-l">
        <rb-filter-panel
          id="recommendation-table-filters"
          :key="filterKey"
          :page="'recommendations'"
          :loader-status="loaderStatus"
          :enable-save="true"
          :data="filterData"
          :primary="primaryFilterData"
          :secondary-data="secondaryFilterData"
          :listener-event="'recommendations-filters'"
          :state="filterState"
          :has-search="true"
          :hide-filter="true"
          :emit="'emitRecommendations'"
          :on-create="onCreate"
          :filter-v2="filterV2"
          :show-ingestion-progress="filterV2"
          :enable-remove-all="false"
          @savedFilterApplied="filterKey++"
        >
          <div
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
          >
            <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
              Recommendations
            </h2>
          </div>
          <div
            slot="right-side"
            class="u-display-flex u-flex-align-items-center"
          >
            <search-box
              class="u-spacing-mr-m"
              :search-string="searchText"
              :placeholder="'Search for a recommendation'"
              @localSearch="localSearch"
            />
            <RecommendationsTypeTabs :selected-tab="'Recommendation type'" />
          </div>
        </rb-filter-panel>
      </div>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
      />
      <DataIssueBanner />
      <DataLagBanner
        v-if="showRecommendationDataLatencyBanner"
        :title="recommendationDataLatencyBanner.title"
      />
    </section>
    <section
      class="alretSection u-spacing-ph-m u-flex-1 card u-envelop-width u-width-100 u-box-shadow-none"
      :class="{ 'u-position-relative': isLoading }"
    >
      <div class="u-display-flex u-flex-direction-column">
        <section class="u-spacing-mt-l">
          <h3
            class="u-font-size-3 u-font-weight-600 u-text-transform-uppercase u-color-grey-lighter u-spacing-m-0 u-display-flex"
            :class="{
              'u-opacity-60': computedBookmarkedAlerts.length === 0,
              'u-opacity-1': computedBookmarkedAlerts.length !== 0
            }"
          >
            Added to &nbsp;<span class="u-color-orange-base u-spacing-ml-xs"
              >&nbsp; My Workspace</span
            >
          </h3>
          <div
            v-if="computedBookmarkedAlerts.length === 0"
            class="no_bookmark u-spacing-mt-m"
          >
            <div class="no_bookmark_img_wrapper">
              <img
                src="/images/empty_bookmark.svg"
                alt="empty bookmark"
                class="u-width-100 u-height-100"
              />
            </div>
            <p
              class="u-font-size-7 u-spacing-grey-lighter u-font-weight-600 u-display-flex u-flex-justify-content-center u-spacing-mt-m u-color-grey-lighter"
            >
              Star your frequently used recommendation types to view them here.
            </p>
          </div>
          <div
            v-if="computedBookmarkedAlerts.length !== 0"
            class="u-width-100 u-display-flex u-flex-align-items-strech u-flex-wrap-yes u-flex-justify-content-start u-spacing-mt-m"
          >
            <div
              v-for="(elem, index) in computedBookmarkedAlerts"
              :key="elem && elem.recommendationLabel"
              :class="{
                'u-spacing-mr-m': index === 0 ? true : index % 3 !== 0
              }"
              class="cardSection u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-mb-m"
              style="width: calc(25% - 1.2rem)"
              @click="handleNavigation(elem)"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-p-m u-width-100"
              >
                <div
                  class="u-font-size-5 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center u-width-100"
                >
                  <Bookmark
                    :path="elem.bookmarkPath"
                    :title="elem.recommendationLabel"
                    :icon-class="'rb-icon--xx-medium u-height-100'"
                    :wrapper-class="'u-spacing-mr-xs'"
                  />
                  <div
                    class="u-height-100 u-flex-align-items-center u-spacing-pt-xs u-spacing-mr-xs u-flex-1 u-ellipsis u-text-transform-capitalize"
                  >
                    {{ elem.recommendationLabel }}
                  </div>
                  <div
                    class="right_side u-display-flex u-flex-align-items-center"
                  >
                    <rb-tooltip-header
                      id="tooltipTemplate"
                      :title="elem.recommendationLabel"
                      :message="elem.recommendationDescription"
                    />
                    <rb-icon
                      v-tippy="setTooltip.tippyOptions"
                      class="u-color-grey-lighter rb-icon--medium"
                      icon="info-circle-fill"
                    />
                  </div>
                </div>
              </div>

              <div
                class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-m u-spacing-pb-m u-width-100"
              >
                <p class="u-display-flex u-flex-align-items-center">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-600 cardHoverHidden"
                    data-cy="recommendation_count"
                    >{{ elem.recommendationCount }} {{ elem.type }}</span
                  >&nbsp;
                  <span
                    class="u-font-size-5 u-font-weight-600 u-color-blue-base cardHoverShown"
                    >View {{ elem.recommendationCount }} {{ elem.type }}</span
                  >
                  <!-- <span
                  class="u-font-size-2 u-display-inline-flex u-line-height-1 u-flex-align-items-center u-color-grey-lighter u-spacing-mh-xs"
                >&bull;</span>&nbsp; -->
                  <!-- <span class="u-color-grey-x-light u-font-size-6">25 New </span> -->
                </p>
                <Tag
                  :tag-color="
                    recommendationGroupColorMapping[elem.recommendationGroup]
                  "
                  :title="elem.recommendationGroup"
                  :tag-font-color="'grey-base'"
                  :extra-wrp-class="'u-spacing-mr-0 u-spacing-extra-xs u-border-radius-xl'"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="u-spacing-pt-m">
          <div class="u-display-flex recommendation-group">
            <splitTabs
              :tabs-list="recoGroup"
              :is-tabs-list="true"
              :active-tab="selectedRecoGroup"
              data-cy="recommendation-tabs"
              @tabClicked="onRecoGroupClick"
            />
          </div>

          <div
            class="u-spacing-mt-m recommendation_card_wrapper u-spacing-mb-l"
          >
            <div
              v-for="(elem, index) in computedAlertList"
              v-if="computedAlertList.length > 0"
              :key="elem && elem.recommendationLabel"
              class="recommendation_card u-flex-direction-column u-spacing-mb-m u-flex-justify-content-space-between u-text-transform-capitalize"
              @click="handleNavigation(elem)"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-p-m"
              >
                <div
                  class="u-font-size-5 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center"
                >
                  <Bookmark
                    :path="elem.bookmarkPath"
                    :title="elem.recommendationLabel"
                    :icon-class="'rb-icon--xx-medium u-height-100'"
                    :wrapper-class="'u-spacing-mr-xs'"
                  />
                  <div
                    class="u-display-flex u-height-100 u-flex-align-items-center u-spacing-mt-xxs"
                    data-cy="recommendation_name"
                  >
                    {{ elem.recommendationLabel }}
                  </div>
                </div>
                <div
                  class="right_side u-display-flex u-flex-align-items-center"
                >
                  <!-- <rb-icon
                  v-tippy="{ placement: 'top', arrow: false, theme: 'light' }"
                  :title="elem.recommendationDescription"
                  class="u-color-grey-lighter u-spacing-mh-s rb-icon--medium"
                  icon="info-circle-fill"
                /> -->
                  <rb-tooltip-header
                    id="tooltipTemplate"
                    :title="elem.recommendationLabel"
                    :message="elem.recommendationDescription"
                  />
                  <rb-icon
                    v-tippy="setTooltip.tippyOptions"
                    class="u-color-grey-lighter u-spacing-mh-s rb-icon--medium"
                    icon="info-circle-fill"
                  />
                  <!-- <span @click.stop="elem.collapse = !elem.collapse">
                  <rb-icon
                    v-if="!elem.collapse"
                    class="u-color-grey-lighter u-spacing-mh-s"
                    icon="enlarge"
                  />
                </span>
                <span @click.stop="elem.collapse = !elem.collapse">
                  <rb-icon
                    v-if="elem.collapse"
                    class="u-color-grey-lighter u-spacing-mh-s"
                    icon="collapse"
                  />
                </span> -->
                  <!-- <rb-icon
                  class="u-color-grey-lighter u-spacing-ml-s"
                  icon="download"
                /> -->
                </div>
              </div>
              <div
                v-if="elem.collapse"
                class="top_alerts_wrap1 u-spacing-ph-m u-spacing-pv-l"
                style="background-color: rgba(0, 124, 246, 0.02)"
              >
                <div
                  class="u-font-size-6 u-font-weight-600 u-color-grey-light u-spacing-mb-m"
                >
                  {{ recomdetails.colum }}
                </div>
                <div
                  v-for="(item, itemIndex) in [1, 2, 3]"
                  :key="item"
                  class="u-overflow-hidden"
                  :class="{
                    'u-spacing-mb-m': itemIndex !== [1, 2, 3].length - 1
                  }"
                >
                  <rb-sku
                    class="u-text-overflow-ellipsis"
                    :title="recomdetails.title"
                    :image-url="recomdetails['image-url']"
                    :asin="recomdetails.asin"
                    :product-page-url="recomdetails['product-page-url']"
                  >
                    <div
                      slot="sku-context"
                      class="u-display-flex"
                    >
                      <!-- <rb-icon
                      class="rb-icon--medium u-color-blue-base rb-icon-14"
                      icon="bell"
                    /><span>recommendations</span> -->
                    </div>
                  </rb-sku>
                </div>
              </div>
              <div
                class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-m u-spacing-pb-m"
              >
                <p class="u-display-flex u-flex-align-items-center">
                  <span
                    class="u-font-size-6 u-color-grey-lighter u-font-weight-600 cardHoverHidden"
                    data-cy="recommendation_count"
                    >{{ elem.recommendationCount }} {{ elem.type }}</span
                  >&nbsp;
                  <span
                    class="u-font-size-5 u-font-weight-600 u-color-blue-base cardHoverShown"
                    >View {{ elem.recommendationCount }} {{ elem.type }}</span
                  >
                  <!-- <span
                  class="u-font-size-2 u-display-inline-flex u-line-height-1 u-flex-align-items-center u-color-grey-lighter u-spacing-mh-xs"
                >&bull;</span>&nbsp; -->
                  <!-- <span class="u-color-grey-x-light u-font-size-6">25 New </span> -->
                </p>
                <Tag
                  :tag-color="
                    recommendationGroupColorMapping[elem.recommendationGroup]
                  "
                  :title="elem.recommendationGroup"
                  :tag-font-color="'grey-base'"
                  :extra-wrp-class="'u-spacing-mr-0 u-spacing-extra-xs u-border-radius-xl'"
                />
                <!-- <p class="chipRound u-font-size-7 u-color-grey-base">
                    Subscribe
                  </p> -->
              </div>
            </div>
          </div>
          <div
            v-if="computedAlertList.length === 0"
            class="u-font-size-5 u-font-weight-600 u-width-100 u-spacing-mb-xxl u-height-200 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
          >
            <p
              class="u-font-size-3 u-text-align-center"
              data-cy="no_recommendation_text"
            >
              No Recommendations found
            </p>
          </div>
        </section>
        <div>
          <Loader
            v-if="isLoading"
            class="fill--parent"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </div>
        <div
          v-if="!isLoading && showError"
          class="u-display-flex u-spacing-p-m u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
        >
          <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
            <p class="u-color-grey-base u-spacing-mr-s">
              Something went wrong!!!. Try again later
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import widgetsMixin from '@/components/widgetsMixin';
import rbFilterPanel from '@/components/widgets/filter-panel';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import { alertDescription } from '@/pages/recommendations/recommendationsLanding/alertDescription.js';
import Loader from '@/components/basic/loader.vue';
import splitTabs from '@/components/widgets/splitTabs';
import RecommendationsTypeTabs from '@/components/widgets/recommendationsTypeTabs.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import Tag from '@/components/widgets/tag';
import searchBox from '@/components/widgets/searchBox.vue';
import constants from '@/utils/constants';
import DataLagBanner from '@/components/basic/DataLagBanner';
import HttpService from '@/utils/services/http-service';
import moment from 'moment';

export default {
  name: 'Alerts',
  author: 'Rajan V',
  components: {
    rbFilterPanel,
    Loader,
    splitTabs,
    RecommendationsTypeTabs,
    iconTextActions,
    Tag,
    searchBox,
    DataLagBanner
  },
  mixins: [widgetsMixin],
  data() {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      filterKey: 0,
      filterState: {
        getter: 'getRecommendationSelectedFilters',
        setter: 'setRecommendationSelectedFilters'
      },
      secondaryFilterData: constants.secondaryFilterData,
      isLoading: false,
      alertList: [],
      showError: false,
      bc_recommendation_type: {
        asin: 'SKU',
        search_term: 'Keyword',
        keyword_text: 'Keyword',
        campaign: 'Campaign'
      },
      recoGroup: ['All', 'Sales', 'Marketing', 'Operations'],
      selectedRecoGroup: 'All',
      recomdetails: {
        colum: 'Top SKUs by OPS (Last 1 Week)',
        title: 'SofPull Regular Centerpull Premium Paper',
        'image-url':
          "https://images-na.ssl-images-amazon.com/images/I/71N4WWrthLL._SX342_.jpg'",
        asin: "'B0017DETBA",
        'product-page-url': 'https://www.amazon.com/dp/B0017DETBA?th=1&psc=1'
      },
      recommendationGroupColorMapping: {
        Operations: 'rgba(151, 204, 4, 0.1)',
        Sales: 'rgba(189, 16, 224, 0.1)',
        Marketing: 'rgba(35, 181, 211, 0.1)'
      },
      searchText: '',
      showRecommendationDataLatencyBanner: false,
      filterInstance: null,
      showActiveFilterBanner: false,
      recommendationDataLatencyBanner: {
        title:
          'Attention : We are currently refreshing the Intraday recommendations - Unavailable SKUs, 3P Variants and Lost Buy Box. You may experience a momentary latency and disparity during this process.'
      }
    };
  },
  computed: {
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    computedBookmarkedAlerts() {
      const list = this.$store.getters['bookmarkStore/getAllBookmarks'];
      const bookmarkedAlert = this.alertList.filter((listItem) => {
        const index = list.findIndex(
          (alertItem) => alertItem.path === listItem.bookmarkPath
        );
        let recomFound = true;
        if (this.searchText) {
          recomFound =
            listItem?.recommendationLabel
              ?.toLowerCase()
              ?.indexOf(this.searchText.toLowerCase()) !== -1;
        }
        if (index !== -1 && recomFound) {
          return listItem;
        }
        return false;
      });
      return bookmarkedAlert;
    },
    computedAlertList() {
      return this.alertList?.filter((item) => {
        let recomFound = true;
        if (this.searchText) {
          recomFound =
            item?.recommendationLabel
              ?.toLowerCase()
              ?.indexOf(this.searchText.toLowerCase()) !== -1;
        }
        if (
          (this.selectedRecoGroup === 'All' ||
            this.selectedRecoGroup === item.recommendationGroup) &&
          recomFound
        ) {
          return item;
        }
        return false;
      });
    },
    recommendationActiveTabLocalStorageKey() {
      return this.$store.getters.getRecommendationActiveTabLocalStorageKey;
    }
  },
  beforeDestroy() {
    // removing the localStorage item ( recommendationActiveTab ) while navigating to all other routes other than recommendations.
    this.$store.dispatch(
      'clearRecommendationActiveTabLocalStorageKey',
      this.$route.path
    );
  },
  created() {
    this.getRecommendationRunProgress();
    eventBus.$on('emitRecommendations', (params) => {
      this.fetchAllRecommendations();
    });
  },
  mounted() {
    // get filters (gets it from the local storage first internally)
    this.fetchFilters(
      'recommendation_filter',
      'recommendations',
      this.customFilterData,
      Vue.options.filters.config_check('feature.product.ams_lite')
    );
    this.fetchAllRecommendations();
    // Set the active tab if present from localStorage.
    const recommendationActiveTabLocalStorageKey = localStorage.getItem(
      this.recommendationActiveTabLocalStorageKey
    );
    if (recommendationActiveTabLocalStorageKey)
      this.selectedRecoGroup = recommendationActiveTabLocalStorageKey;
  },
  methods: {
    getRecommendationRunProgress() {
      const client = location.hostname.split('.')[0];
      const filters = {
        clients: client ? [client] : undefined
      };
      const data = {
        flow: 'alert_email_workflow',
        run_date: moment().format('YYYY-MM-DD'),
        filters: filters,
        refresh: true
      };
      HttpService.post('PIPELINE', data, {
        append: '/getSummary'
      })
        .then((response) => {
          this.showRecommendationDataLatencyBanner =
            response?.data?.summary?.running > 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    mergeRequest(allRequest) {
      return allRequest.reduce((item, arrayItem) => {
        if (
          arrayItem.status === 200 &&
          Array.isArray(arrayItem?.data?.recommendationCount)
        ) {
          item.push(...arrayItem.data.recommendationCount);
        }
        return item;
      }, []);
    },
    addDescription(list) {
      return list.map((item) => {
        if (alertDescription[item.recommendationLabel]) {
          item.recommendationDescription =
            alertDescription[item.recommendationLabel];
        } else {
          item.recommendationDescription = 'No Desc';
        }
        if (alert.recommendationLabel === 'All (SKUs)') {
          item.bookmarkPath = `${this.$store.getters.getRootPath}recommendations/all`;
        } else {
          item.bookmarkPath = `${
            this.$store.getters.getRootPath
          }recommendations/${item.recommendationGroup.toLowerCase()}?name=${
            item.recommendationName
          }&type=${item.recommendationType}&bc_recommendation_type=${
            item.bc_recommendation_type
          }`;
        }
        item.type =
          this.bc_recommendation_type[item.bc_recommendation_type] || 'SKU';
        if (item.recommendationCount > 0) {
          item.type += 's';
        }
        item.collapse = false;
        return item;
      });
    },
    groupRecommendation(list) {
      // const tempList = list.reduce((item, arrayItem) => {
      //   if (item.all) {
      //     item.all.push(arrayItem);
      //   } else {
      //     item.all = [];
      //     item.all.push(arrayItem);
      //   }
      //   return item;
      // }, {});
      // Object.keys(tempList).forEach(item => {
      //   tempList[item] = tempList[item].sort((a, b) => {
      //     if (a.displayOrder === null) return -1;
      //     else if (b.displayOrder === null) return 1;
      //     else return a.displayOrder - b.displayOrder;
      //   });
      // });
      list = list.sort((a, b) => {
        if (a.displayOrder === null) return -1;
        else if (b.displayOrder === null) return 1;
        else return a.displayOrder - b.displayOrder;
      });
      return list;
    },
    customFilterData(filterData) {
      return (filterData) => {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].profile_name === null) {
            filterData[i].profile_name = 'Others';
          }
        }
        return filterData;
      };
    },
    onRecoGroupClick(selection) {
      // Set/Change the localStorage for persisting tab active.
      localStorage.setItem(
        this.recommendationActiveTabLocalStorageKey,
        selection
      );
      this.$logger.logEvent({
        eventType: 'recommendation-group-click',
        type: 'Click',
        page: `${selection}`,
        eventContext: 'Recommendation Group Click'
      });
      this.selectedRecoGroup = selection;
    },
    fetchAllRecommendations() {
      this.isLoading = true;
      this.showError = false;
      this.$store
        .dispatch('alertStore/fetchAllAlerts')
        .then((response) => {
          let mergedResponse = this.mergeRequest(response);
          mergedResponse = this.addDescription(mergedResponse);
          this.alertList = this.groupRecommendation(mergedResponse);
        })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleNavigation(alert) {
      // this.routes.findIndex(r => r.name === this.$route.name);
      this.$logger.logEvent({
        eventType: 'recommendation-card-click',
        type: 'Click',
        page: `${alert.recommendationLabel}`,
        eventContext: 'Recommendation card navigation'
      });
      if (alert.recommendationLabel === 'All (SKUs)') {
        this.$router.push('recommendations/all');
        // this.$router.push('recommendation/all');
      } else {
        this.$router.push({
          path: `recommendations/${alert.recommendationGroup.toLowerCase()}`,
          query: {
            name: alert.recommendationName,
            type: alert.recommendationType,
            bc_recommendation_type: alert.bc_recommendation_type
          }
        });
      }
    },
    localSearch(input) {
      this.searchText = input;
    }
  }
};
</script>

<style lang="css" scoped>
.alert-card {
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(43, 51, 59, 0.15);
  background-color: #ffffff;
  cursor: pointer;
}

.u-spacing-mt-40 {
  margin-top: 40px;
}

.u-width-23 {
  width: 23.8%;
}

.chipRound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 26px;
  background-color: rgba(0, 124, 246, 0.1);
  width: fit-content;
}

.top_alerts_wrap {
  max-width: 400px;
}

.container {
  width: 100%;
}

.recommendation_card_wrapper {
  columns: calc(35vw);
  column-gap: 20px;
}

.recommendation_card {
  break-inside: avoid-column;
  flex: 1 auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border-bottom: 1px solid rgba(43, 51, 59, 0.05);
  border-top: 1px solid rgba(43, 51, 59, 0.05);
  background-color: #ffffff;
  cursor: pointer;
}

.cardHoverShown {
  display: none;
}

.recommendation_card:hover,
.cardSection:hover {
  box-shadow: 0 1px 8px 0 rgba(43, 51, 59, 0.15) !important;
}
.recommendation_card:hover .cardHoverShown,
.cardSection:hover .cardHoverShown {
  display: inline-block;
}
.recommendation_card:hover .cardHoverHidden,
.cardSection:hover .cardHoverHidden {
  display: none;
}

.cardSection {
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
}

.no_bookmark_img_wrapper {
  margin: 0 auto;
  width: 240px;
  height: 160px;
}

.u-opacity-60 {
  opacity: 0.6;
}

.u-spacing-extra-xs {
  padding-top: 4px;
  padding-bottom: 4px;
}

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-height-200 {
  height: 200px;
}

.alretSection {
  background-color: #f5f7fa;
}

.u-box-shadow-none {
  box-shadow: none;
}

.u-text-transform-capitalize {
  text-transform: capitalize;
}
</style>

<style lang="css">
.recommendation-group .split-tabs {
  background-color: #fff;
}
</style>
