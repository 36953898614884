<template>
  <div class="sot-generation-status-wrapper">
    <img
      src="/images/ingestion-success.png"
      alt="sot-generation-placeholder"
    />
    <div
      class="u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
    >
      <div class="u-display-flex u-flex-align-items-center u-spacing-mt-xl">
        <loader
          v-if="showLoader"
          :loading="true"
          :color="'#3684bb'"
        />
        <div class="u-display-flex u-max-width-400">
          <div>
            <rb-icon
              v-if="showIcon"
              :icon="getIconName"
              class="rb-icon--xx-medium"
              :class="getIconClass"
            />
          </div>
          <div class="u-spacing-ml-s">
            <div class="u-font-size-4 u-font-weight-600">
              {{ getTitle }}
            </div>
            <div
              v-if="getSubtitle"
              class="u-font-size-6 u-color-grey-light"
            >
              {{ getSubtitle }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showLoader"
        class="btn-loader u-spacing-mt-m"
      >
        <loader
          :loading="true"
          :color="'#ffffff'"
        />
      </div>
      <div
        v-if="isGenerationStage('SUCCEEDED')"
        class="u-display-flex u-flex-align-items-center u-flex-direction-column u-spacing-mt-s"
      >
        <div class="u-display-flex">
          <div
            v-if="doesSotExists"
            class="u-display-flex u-flex-align-items-center u-spacing-ph-l u-spacing-pv-xs u-spacing-mr-s u-color-blue-base u-border-all u-border-width-xs u-border-color-blue-base u-cursor-pointer u-border-radius-s u-font-weight-600"
            @click="handleBackToExistingTable"
          >
            <span class="button_size u-spacing-mr-s u-font-size-6"> Back </span>
          </div>
          <div
            class="u-display-flex u-flex-align-items-center u-bg-color-blue-base u-spacing-ph-l u-spacing-pv-s u-color-white-base u-cursor-pointer u-border-radius-s u-font-weight-600"
            @click="handleGetStartedClick"
          >
            <span class="button_size u-spacing-mr-s u-font-size-6"
              >Get Started</span
            >
            <rb-icon
              icon="arrow-right"
              class="rb-icon--medium"
            />
          </div>
        </div>
        <div
          class="u-color-blue-base u-text-decoration-underline u-font-size-5 u-cursor-pointer u-font-weight-600 u-spacing-mt-m"
          @click="handleBackToSourceSelections"
        >
          Back to source selections
        </div>
      </div>
      <div
        v-else-if="!isGenerationStage('PENDING')"
        class="u-spacing-mt-m"
      >
        <div class="u-display-flex u-flex-direction-column u-spacing-mt-s">
          <div
            v-if="doesSotExists"
            class="u-display-flex u-flex-align-items-center u-bg-color-blue-base u-spacing-ph-l u-spacing-pv-s u-color-white-base u-cursor-pointer u-border-radius-s u-font-weight-600"
            @click="handleBackToExistingTable"
          >
            <rb-icon
              icon="arrow-right"
              class="rb-icon--medium rotate-icon"
            />
            <span class="button_size u-spacing-ml-s u-font-size-6"
              >Back to last step</span
            >
          </div>
          <div
            class="u-color-blue-base u-text-decoration-underline u-font-size-5 u-cursor-pointer u-font-weight-600 u-spacing-mt-m"
            @click="handleBackToSourceSelections"
          >
            Back to source selections
          </div>
        </div>
      </div>
    </div>
    <modal v-if="showConfirmationPopup">
      <div slot="body">
        <div
          class="u-display-flex u-flex-justify-content-center u-font-size-2 u-font-weight-600 u-spacing-mb-m u-color-grey-base u-spacing-mt-xxl"
        >
          Are you sure you want to overwrite?
        </div>
        <div
          class="u-display-flex u-flex-justify-content-center u-font-size-4 u-spacing-mb-m u-color-grey-base"
        >
          <div class="u-max-width-70 u-text-align-center">
            The previous content sot data and edits will be discarded.
          </div>
        </div>
      </div>
      <div slot="footer">
        <div
          class="u-display-flex u-flex-justify-content-center u-spacing-mb-xxl u-spacing-mt-m"
        >
          <rb-button
            :click-fn="cancelButtonClickHandler"
            :text="'Cancel'"
            :type="'filled'"
            :size="'m'"
            class="cancel-btn"
          />
          <rb-button
            :click-fn="deleteButtonClickHandler"
            :text="'Proceed'"
            :type="'filled'"
            :size="'m'"
            class="proceed-btn"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import modal from '@/components/widgets/modal.vue';

export default {
  components: {
    modal,
    loader
  },
  props: {
    sotType: {
      type: String,
      default: ''
    },
    sotGenerationInitialStatus: {
      type: String,
      default: 'PENDING'
    },
    doesSotExists: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentGenerationStatus: 'PENDING',
      polledValue: false,
      sotAutogenerateTextConfigs: {
        status: {
          PENDING: {
            title: `Hang tight, product ${this.sotType} creation is in the works.`,
            showIcon: false,
            showLoader: true
          },
          FAILED: {
            title: `A failure occurred when creating Product ${this.sotType}.`,
            subTitle:
              'You may try again. Reach out to your support team if you experience multiple failures..',
            icon: {
              showIcon: true,
              iconName: 'cross-fill-circle',
              iconClass: 'u-color-red-base'
            },
            showLoader: false
          },
          SUCCEEDED: {
            title: `Product ${this.sotType} is created successfully!`,
            icon: {
              showIcon: true,
              iconName: 'check-fill-circle',
              iconClass: 'u-color-green-base'
            },
            showLoader: false
          }
        }
      },
      timeout: null,
      showConfirmationPopup: false
    };
  },
  computed: {
    sotStatusConfig() {
      return this.sotAutogenerateTextConfigs.status?.[
        this.currentGenerationStatus
      ];
    },
    showLoader() {
      return this.sotStatusConfig?.showLoader;
    },
    showIcon() {
      return this.sotStatusConfig?.icon?.showIcon;
    },
    getIconClass() {
      return this.sotStatusConfig?.icon?.iconClass;
    },
    getIconName() {
      return this.sotStatusConfig?.icon?.iconName;
    },
    getTitle() {
      return this.sotStatusConfig?.title || 'Something went wrong!!';
    },
    getSubtitle() {
      return this.sotStatusConfig?.subTitle;
    }
  },
  watch: {
    sotGenerationInitialStatus: {
      handler(newValue) {
        if (newValue) {
          this.currentGenerationStatus = this.sotGenerationInitialStatus;
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.sotGenerationInitialStatus) {
      if (this.isGenerationStatusPending(this.sotGenerationInitialStatus)) {
        this.currentGenerationStatus = 'PENDING';
      } else if (
        this.isGenerationStatusSuccess(this.sotGenerationInitialStatus)
      ) {
        this.currentGenerationStatus = 'SUCCEEDED';
      } else {
        this.currentGenerationStatus = this.sotGenerationInitialStatus;
      }
    }
    if (this.currentGenerationStatus === 'PENDING') {
      this.startSotStatusPolling();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    isGenerationStage(generationStage) {
      return this.currentGenerationStatus === generationStage;
    },
    isGenerationStatusPending(status) {
      return (
        ['INITIATED', 'QUEUED', 'RUNNING', 'PREPARING'].indexOf(status) !== -1
      );
    },
    isGenerationStatusSuccess(status) {
      return ['FINISHED', 'COMPLETED'].indexOf(status) !== -1;
    },
    handleBackToSourceSelections() {
      this.$emit('handleBackToSourceSelections');
    },
    handleBackToExistingTable() {
      this.$emit('handleBackToExistingSotTable', 'existing-sot');
    },
    handleGetStartedClick() {
      if (this.doesSotExists) {
        this.showConfirmationPopup = true;
      } else {
        this.$emit('proceedToCreateOrReplaceSot');
      }
    },
    cancelButtonClickHandler() {
      this.showConfirmationPopup = false;
    },
    deleteButtonClickHandler() {
      this.$emit('proceedToCreateOrReplaceSot');
      this.showConfirmationPopup = false;
    },
    getStatus(entityExistCheck = false) {
      return new Promise((resolve, reject) => {
        if (this.sotType) {
          const statusApiPayload = {
            sotType: `${this.sotType.toUpperCase()}_AUTHORITY_SOT_GEN`,
            entityExistCheck: entityExistCheck
          };
          HttpService.post('ENTITY_METADATA_SERVICE', statusApiPayload, {
            append: '/sot/status'
          })
            .then((response) => {
              return resolve(response.data.status);
            })
            .catch(() => {
              this.currentGenerationStatus = 'FAILED';
              this.$snackbar.open({
                message: 'Something Went Wrong!',
                duration: 5000,
                actionText: ''
              });
            });
        }
      });
    },
    poll: async function (fn, fnCondition, ms) {
      let result = await fn();
      while (fnCondition(result)) {
        await this.wait(ms);
        result = await fn();
      }
      return result;
    },
    wait: function (ms = 1000) {
      let that = this;
      return new Promise((resolve) => {
        that.timeout = setTimeout(resolve, ms);
      });
    },
    startSotStatusPolling: async function () {
      let response = await this.poll(this.getStatus, this.validate, 5000);
      if (this.isGenerationStatusPending(response)) {
        this.currentGenerationStatus = 'PENDING';
      } else if (this.isGenerationStatusSuccess(response)) {
        this.currentGenerationStatus = 'SUCCEEDED';
      } else {
        this.currentGenerationStatus = response;
      }
    },
    validate(result) {
      return this.isGenerationStatusPending(result);
    }
  }
};
</script>

<style lang="css" scoped>
.sot-generation-status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.sot-generation-status-wrapper .btn-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007cf6;
  padding: 10px 50px;
  border-radius: 4px;
}
.sot-generation-status-wrapper img {
  width: 320px;
}
.sot-generation-status-wrapper .u-max-width-400 {
  max-width: 400px;
}
.sot-generation-status-wrapper .rotate-icon {
  transform: rotate(180deg);
}
.cancel-btn {
  color: #007cf6;
  border: 1px solid #007cf6;
  background-color: #fff;
  margin-right: 8px;
}
.cancel-btn[disabled] {
  opacity: 0.3;
  background-color: #fff;
}
.cancel-btn:hover {
  color: palette(blue, lighter);
  border: 1px solid #007cf6;
  background-color: #fff;
}
.proceed-btn {
  color: #fff;
  border: 1px solid #d7263d;
  background-color: #d7263d;
}
.proceed-btn:hover {
  border: 1px solid #d7263d;
  background-color: #d7263d;
}
</style>
