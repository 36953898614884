var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-creation-edit u-position-fixed widget--full-screen",on:{"click":function($event){$event.stopPropagation();return _vm.removeFocus($event)}}},[_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"fill--parent",staticStyle:{"z-index":"300"},attrs:{"slot":"loader","color":'#3fadf7',"size":'4rem',"thickness":'.2rem'},slot:"loader"}),_c('div',{staticClass:"u-position-fixed model u-width-100 campaign-creation-edit"},[_c('section',{staticClass:"campaign-creation-edit--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center",staticStyle:{"height":"2.4rem"}},[_c('span',{on:{"click":function($event){return _vm.closeWithoutSave()}}},[_c('rb-icon',{staticClass:"u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s",attrs:{"icon":'cross',"data-cy":"crossIcon"}})],1),_c('div',{staticClass:"u-display-flex",style:({
              width: _vm.showEdit
                ? _vm.adGroupName.length === 0
                  ? '23ch'
                  : _vm.adGroupName.length * 11.5 < 800
                  ? ((_vm.adGroupName.length * 11) + "px")
                  : '60%'
                : '100%'
            })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.adGroupName),expression:"adGroupName"}],ref:"adGroup-creation-input",staticClass:"heading--input u-width-100 u-font-size-2 u-font-weight-bold text-underline",class:_vm.showEdit || _vm.adGroupName.length === 0
                  ? 'u-pointer-events-none u-text-overflow-ellipsis text-underline-color-white'
                  : '',staticStyle:{"padding-left":"2px"},attrs:{"type":"text","data-cy":"suggestedCampaignNameAndEdit","placeholder":"Untitled"},domProps:{"value":(_vm.adGroupName)},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.stopPropagation();return (function () {})($event)},"input":function($event){if($event.target.composing){ return; }_vm.adGroupName=$event.target.value}}})]),(_vm.showEdit)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.onEditAdgroupName($event)}}},[_c('rb-icon',{staticClass:"rb-icon--x-medium u-cursor-pointer u-spacing-ml-s u-color-grey-lighter",attrs:{"data-cy":"pencilIcon","icon":'pencil'}})],1):_vm._e()]),_c('FormErrorMessage',{staticClass:"u-spacing-ml-l",attrs:{"show-error":Boolean(_vm.adgroupNameErrorMessage),"error-text":_vm.adgroupNameErrorMessage}})],1)]),_c('StepComponent',{staticClass:"u-width-100",attrs:{"config":_vm.stepsConfig,"panel-width":10,"all-steps-data":_vm.pageData,"enabled-bread-crumbs":[],"show-step-bullet-description":true,"bullet-point-class":"u-spacing-pt-m","all-bullet-points-class":"marign-top-campaign-creation-bullets","set-active-selection":_vm.setActiveSelection},on:{"activeSelectionUpdated":_vm.activeSelectionUpdated}}),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"fill--parent",attrs:{"color":'#3fadf7',"size":'4rem',"thickness":'.2rem'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }