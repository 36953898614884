import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled
} from '@/components/pages/insights/amazon/cva/utils';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  priceBandMap = {
    'High Price': 'High',
    'Medium Price': 'Medium',
    'Low Price': 'Low'
  };

  async fetchData(pageSettings, priceRange) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_pricing_sku',
        entityType: 'SKU',
        metricsList: rem1V2ConfigEnabled()
          ? ['cva_pricing_price_v2', 'cva_pricing_client_id_v2']
          : ['cva_pricing_price', 'cva_pricing_client_id'],
        dimensionsList: [
          'browse_node_rank',
          'brand',
          'no_of_ratings',
          'rating',
          'client_id',
          'price'
        ],
        eventsList: [],
        enablePaginationCount: true,
        commonFilterEnabled: false,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: [
            {
              dimension: 'no_of_ratings',
              direction: 'DESC'
            }
          ],
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Pricing Leadership SKU',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'no_of_ratings',
              dimensionValue: '10',
              operator: 'GREATER_THAN_OR_EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      body.where.dimensionNameValueList.push({
        dimensionName: 'price_band',
        dimensionValue: this.priceBandMap[priceRange],
        operator: 'EQUAL_TO'
      });
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'SKU Details',
        field: 'SKU',
        minWidth: 300,
        headerComponentParams: {
          enableSorting: false
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          eventMap: {},
          paramsToProps: skuParamsToProps
        },
        pinned: true
      },
      {
        headerName: 'Brand',
        field: 'brand',
        toolTipText: 'Brand of the product',
        cellClass: 'u-text-transform-capitalize'
      },
      {
        headerName: 'Price',
        field: 'price',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Price of the product'
        },
        cellRendererParams: {
          keyType: 'currency',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'No of Ratings',
        field: 'no_of_ratings',
        headerComponentParams: {
          keyType: 'number'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Average Rating',
        field: 'rating',
        headerComponentParams: {
          keyType: 'number',
          toolTipText: 'Average rating of the product'
        },
        cellRendererParams: {
          keyType: 'number',
          cellAlignment: 'u-text-align-right'
        }
      }
    ];
    columns = getActiveColumns(columns, 'price_leadership');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = ['SKU'];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
