import { formatter } from '@/utils/helpers/formatter.js';
import HttpService from '@/utils/services/http-service';
import { convertArraysToMaps } from '@/utils/helpers/utils.js';
import { getNumberSign } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';
import CONSTANTS from '@/utils/constants.js';

export default class DataService {
  arrayLookupMaps;
  donutChartData;
  donutPopupData;
  additionalDigitalShelfData;
  widget;
  page;
  pageId;
  globalViewId;
  load;
  error;
  noData;
  constructor(page, widget, pageId, globalViewId) {
    this.arrayLookupMaps = [];
    this.donutChartData = [];
    this.donutPopupData = [];
    this.additionalDigitalShelfData = [];
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
    this.load = true;
    this.error = false;
    this.noData = false;
    this.metadataLoad = true;
    this.metadataError = false;
  }

  async getMetadata() {
    try {
      this.metadataLoad = true;
      this.metadataError = false;
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadataLoad = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadataLoad = false;
      this.metadataError = true;
    }
  }

  async getData(body) {
    try {
      this.load = true;
      this.error = false;
      this.noData = false;
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/entity/metrics/data'
      });
      if (data.entityData.length === 0) {
        this.noData = true;
      }
      this.load = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.load = false;
      this.error = true;
      this.noData = false;
    }
  }

  transformApiData(
    entityData,
    devMetadata,
    metric,
    keyToMapWith,
    titleOptionSelected,
    intradayCrawlingAvailable,
    metricsMetadata = {}
  ) {
    const apiArrayLookupMap = convertArraysToMaps(entityData, keyToMapWith);
    const { accessKeys, metadata } = devMetadata;
    const additionalDigitalShelfData = [];
    const donutChartData = [];
    const donutPopupData = [];
    const multiCrawl = this.page === 'SOV_INTRADAY';
    for (let j = 0; j < entityData.length; j++) {
      const unitdonutChartData = [];
      const unitdonutPopupData = {};
      const additionalData = {};
      if (metadata.digitalShelfTitleKey) {
        const title = entityData[j]?.[metadata.digitalShelfTitleKey] || 'NA';
        additionalData.title = title;
      }
      for (const data of entityData[j].data) {
        if (data.name === metadata?.totalKeywordsMetric) {
          const formattedResult = formatter(data.RESULT[data.name], 'number');
          additionalData.totalKeywords = {};
          additionalData.totalKeywords.result = formattedResult;
        }
        if (intradayCrawlingAvailable) {
          if (data.name === metadata?.totalMultiCrawlKeywordsMetric) {
            let val = data.RESULT[data.name];
            additionalData.totalMultiCrawlKeywords = {};
            additionalData.totalMultiCrawlKeywords.result = val
              ? formatter(val, 'number')
              : 0;
          }
          if (
            titleOptionSelected?.value === 'search_term' &&
            data.name === metadata?.isIntradayKeywordKey &&
            data.RESULT[data.name]
          ) {
            additionalData.icon = CONSTANTS.crawlTypes.multiCrawl.icon;
          }
        }
        if (data.name === metadata?.shelHierarchyKey) {
          const shelfHierarchy = (data.RESULT?.[data.name] || '')
            .split('~')
            .filter((item) => item !== '');
          const cutOffHierarchy = [];
          let i = 0;
          while (
            shelfHierarchy[i] !== additionalData?.title &&
            i < (shelfHierarchy || []).length
          ) {
            cutOffHierarchy.push(shelfHierarchy[i]);
            i++;
          }
          cutOffHierarchy.push(additionalData.title);
          additionalData.shelfHierarchy = {};
          additionalData.shelfHierarchy.result = cutOffHierarchy;
        }
        if (data.name === metadata?.sovfooterMetricData) {
          const formattedResult = data.RESULT?.[data.name];
          if (formattedResult !== null) {
            additionalData.sovBrand = {};
            additionalData.sovBrand.result = formattedResult;
          }
        }
        if (
          data.name === metadata?.footerMetricKey ||
          data.name === metadata?.footerMetricData
        ) {
          const metricMetaData =
            metricsMetadata[metadata?.originalSortMericKey] ||
            metricsMetadata[metadata?.footerMetricData];
          const pvpKeyPrefix =
            metricMetaData?.metadata?.unit === 'PERCENTAGE'
              ? 'PVP_DIFF_'
              : 'PVP_';
          let pvpObject = {};
          let fomattedPvp = {};
          let formatedPvpValue = null;
          let formattedResult = null;
          if (data?.PVP?.[pvpKeyPrefix + data.name] !== null) {
            pvpObject = { pvp: data?.PVP?.[pvpKeyPrefix + data?.name] };
            fomattedPvp = this.getFormattedPvpObject(
              pvpObject,
              metricMetaData.metadata.isInverted
            );
            formatedPvpValue = formatter(
              fomattedPvp.pvp,
              'PERCENTAGE',
              undefined,
              1
            );
          }
          const resultFormat =
            metricMetaData?.metadata?.unit === ''
              ? metricMetaData?.metadata?.type
              : metricMetaData?.metadata?.unit;
          if (data?.RESULT?.[data?.name] !== null) {
            formattedResult = formatter(
              data.RESULT?.[data?.name],
              resultFormat,
              undefined,
              1
            );
          } else {
            formattedResult = formatter(0, resultFormat, undefined, 1);
          }
          additionalData.sortMetricData = {};
          additionalData.sortMetricData.result = formattedResult;
          additionalData.sortMetricData.pvp = {
            pvp: formatedPvpValue,
            pvpDirection: fomattedPvp.pvpDirection
          };
          additionalData.sortMetricData.isInverted =
            metricMetaData.metadata.isInverted;
        }
      }
      if (multiCrawl) {
        additionalData.totalMultiCrawlKeywords = {};
        additionalData.totalMultiCrawlKeywords.result =
          additionalData.totalKeywords?.result;
        if (titleOptionSelected?.value === 'search_term') {
          additionalData.icon = CONSTANTS.crawlTypes.multiCrawl.icon;
        }
      }
      additionalDigitalShelfData.push(additionalData);
      for (const accessKey of accessKeys) {
        const obj = this.extractApiWrapper(
          apiArrayLookupMap[j],
          accessKey,
          metric,
          metadata
        );
        if (obj) {
          unitdonutChartData.push(obj);
          const { popupKeys } = accessKey;
          const popUpDataColored = [obj];
          const popUpDataUncolored = [];
          for (let k = 0; k < popupKeys.length; k++) {
            const popupObject = this.extractApiWrapper(
              apiArrayLookupMap[j],
              popupKeys[k],
              metric,
              metadata
            );
            if (popupObject?.brand) {
              popUpDataUncolored.push(popupObject);
            }
          }
          unitdonutPopupData[obj.brand] = {
            colored: popUpDataColored,
            uncolored: popUpDataUncolored
          };
        }
      }
      donutChartData.push(unitdonutChartData);
      donutPopupData.push(unitdonutPopupData);
    }
    return { donutChartData, donutPopupData, additionalDigitalShelfData };
  }

  extractApiWrapper(apiArrayLookupMap, accessKey, metric, metadata) {
    const sharesKey = this.getSharesKey(
      accessKey.brandKey,
      metric,
      metadata.sharesKey.suffix
    );
    const clientFlagKey = this.getClientFlagKey(accessKey.brandKey);
    const apiBrandDetailsUnit = this.getApiUnit(
      apiArrayLookupMap,
      accessKey.brandKey
    );
    const apiSharesDetailsUnit = this.getApiUnit(apiArrayLookupMap, sharesKey);
    const apiClientFlagDetails = this.getApiUnit(
      apiArrayLookupMap,
      clientFlagKey
    );
    return this.extractFromApiUnit(
      apiBrandDetailsUnit,
      apiSharesDetailsUnit,
      apiClientFlagDetails,
      sharesKey
    );
  }

  extractFromApiUnit(
    apiBrandDetailsUnit,
    apiSharesDetailsUnit,
    apiClientFlagDetails,
    sharesKey
  ) {
    const pvpKey = this.getPvpKey(sharesKey);
    const obj = {};
    if (apiBrandDetailsUnit) {
      obj.brand = apiBrandDetailsUnit?.RESULT?.[apiBrandDetailsUnit?.name];
    }
    if (!obj.brand) {
      return null;
    }
    if (apiSharesDetailsUnit) {
      const { pvp, pvpDirection } = this.getPvpObject(
        apiSharesDetailsUnit,
        pvpKey
      );
      obj.pvp = pvp;
      obj.pvpDirection = pvpDirection;
      obj.percentage =
        this.getSharePercentage(apiSharesDetailsUnit, sharesKey) || 0;
    }
    if (apiClientFlagDetails) {
      obj.clientFlag =
        apiClientFlagDetails?.RESULT?.[apiClientFlagDetails.name];
    } else {
      // custom use case where coloring logic was buggy
      obj.clientFlag =
        apiBrandDetailsUnit.name === 'client_brand' ? 'client' : 'comp';
    }
    return obj;
  }

  getPvpObject(apiShareUnit, pvpKey) {
    const obj = {};
    obj.pvp = this.getPvp(apiShareUnit, pvpKey);
    return this.getFormattedPvpObject(obj);
  }

  getFormattedPvpObject(pvpObject, isInverted) {
    pvpObject.pvpDirection = getNumberSign(pvpObject.pvp, isInverted);
    pvpObject.pvp = Math.abs(pvpObject.pvp);
    return pvpObject;
  }

  getPvp(apiShareUnit, pvpKey) {
    return this.roundNumber(apiShareUnit.PVP[pvpKey]);
  }

  getSharePercentage(apiShareUnit, sharesKey) {
    return this.roundNumber(apiShareUnit.RESULT[sharesKey]);
  }

  getPvpKey(sharesKey) {
    return 'PVP_DIFF_' + sharesKey;
  }

  getClientFlagKey(sharesKey) {
    return sharesKey + '_client_flag';
  }

  getSharesKey(brandKey, metric, suffix) {
    return `${brandKey}_${metric}_${suffix}`;
  }

  getApiUnit(arrayLookupMap, sharesKey) {
    return arrayLookupMap[sharesKey];
  }

  roundNumber(num) {
    if (num === null) {
      return null;
    }
    return Math.round(num * 10) / 10;
  }
}
