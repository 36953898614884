<template>
  <div
    class="u-width-100 u-font-size-6 u-display-flex master-class u-flex-align-items-center"
  >
    <div class="metric-section u-spacing-pr-xs">
      {{ metric }}
    </div>
    <div class="u-spacing-pr-xs">
      {{ result }}
    </div>
    <div
      v-if="pvpValue !== undefined && pvpValue !== null"
      class="pvp-section u-display-flex u-spacing-pr-xs"
      :class="{
        'u-color-green-base': pvpDirection === '+',
        'u-color-red-base': pvpDirection === '-',
        'u-color-grey-mid-light': pvpDirection === ''
      }"
    >
      <span
        class="u-width-100 rb-icon u-spacing-ph-xs u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--xx-small"
        :class="['icon-arrow-' + computedIconDirection]"
      />
      <span class="">
        {{ pvpValue }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pvpDirection: {
      type: String,
      default: ''
    },
    metric: {
      type: String,
      default: ''
    },
    result: {
      type: String,
      default: null
    },
    pvpValue: {
      type: String,
      default: null
    },
    isInverted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedIconDirection() {
      if (this.isInverted) {
        if (this.pvpDirection === '-' || this.pvpDirection === '') {
          return 'up';
        }
        return 'down';
      } else {
        if (this.pvpDirection === '+' || this.pvpDirection === '') {
          return 'up';
        }
        return 'down';
      }
    }
  }
};
</script>

<style lang="css" scoped>
.master-class {
  font-size: 1.4rem;
}
.master-class .metric-section {
  color: #6a7075;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.master-class .pvp-section {
  font-size: 1.3rem;
}
</style>
