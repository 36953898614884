<template>
  <div class="u-width-100">
    <div class="u-display-flex u-flex-align-items-flex-start u-flex-wrap-yes">
      <div
        v-for="(val, index) in computedStar"
        :key="index"
      >
        <rb-icon
          v-if="val == 'full'"
          :class="[activeClass, iconSize]"
          :icon="'star'"
        />
        <rb-icon
          v-if="val == 'half'"
          :class="[activeClass, iconSize]"
          :icon="'star-half'"
        />
        <rb-icon
          v-if="val == 'empty'"
          :class="[inactiveClass, iconSize]"
          :icon="'star'"
        />
      </div>
      <div
        v-if="count"
        class="u-font-size-4 u-color-grey-lighter u-spacing-ml-s"
        :class="textClass"
      >
        ({{ count }} ratings)
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    star: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 0
    },
    textClass: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: 'rb-icon--medium'
    },
    activeClass: {
      type: String,
      default: 'u-color-grey-base'
    },
    inactiveClass: {
      type: String,
      default: 'u-color-grey-xxx-light'
    }
  },
  data() {
    return {};
  },
  computed: {
    computedStar() {
      const flooredStar = Math.floor(this.star);
      const starArray = [];
      for (let i = 0; i < 5; i++) {
        if (i < flooredStar) {
          starArray.push('full');
        } else {
          starArray.push('empty');
        }
      }
      if (this.star - flooredStar > 0) {
        starArray[flooredStar] = 'half';
      }
      return starArray;
    }
  },
  watch: {},
  created() {}
};
</script>
