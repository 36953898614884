var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100 u-spacing-mt-0",class:_vm.chartId},[(_vm.showMetrics)?_c('chart-legends',{key:_vm.chartKey,attrs:{"fill-metric":_vm.enablePVPTimeseries,"metric-display-list":_vm.localMetricsShown,"metrics-list":_vm.computedMetricsList,"default-select-metric":_vm.defaultSelectMetric,"metric-config":_vm.metricConfig,"metric-data":_vm.metricData,"disable-add-new-metric":_vm.disableAddNewMetric,"remove-dropdown":_vm.removeDropdown,"minimum-metric":_vm.minimumMetric,"enable-legend-search":_vm.enableLegendSearch,"color-object":_vm.colorObject,"selected-metric-limit":_vm.enablePVPTimeseries ? 1 : _vm.computedSelectedMetricLimit,"note-generator-metric-tooltip":_vm.noteGeneratorMetricTooltip,"emit-on-metric-default-change":_vm.emitOnMetricDefaultChange,"plot-on-y-axis-only":_vm.plotOnYAxisOnly,"default-legends":_vm.defaultLegends,"enable-watefall":_vm.enableWatefall,"additional-data":_vm.additionalData,"has-post-custom-metric":_vm.hasPostCustomMetric,"metric-font-size":_vm.metricFontSize,"hide-zero":_vm.hideZero,"show-big-tooltip":_vm.showBigTooltip,"is-metric-tool-tip-enabled":_vm.isMetricToolTipEnabled,"modify-tooltip-message":_vm.modifyTooltipMessage,"show-legends-as-chips":_vm.showLegendsAsChips,"show-chart":_vm.showChart,"margin-top-large":_vm.marginTopLarge,"margin-top-small":_vm.marginTopSmall,"metric-tooltip-one-liner":_vm.metricTooltipOneLiner,"metric-tooltip-theme":_vm.metricTooltipTheme,"show-custom-tooltip":_vm.showCustomTooltip,"restrict-single-metric-deselection":_vm.restrictSingleMetricDeselection},on:{"selectedList":_vm.metricChanged,"removedMetric":_vm.removedMetric,"addMetric":_vm.addMetric},scopedSlots:_vm._u([{key:"customLegend",fn:function(ref){
var val = ref.val;
return _c('div',{},[_vm._t("customLegendForChart",null,{"val":val})],2)}},{key:"customMetric",fn:function(ref){
var value = ref.value;
return _c('div',{},[_vm._t("postCustomMetric",null,{"value":value})],2)}}],null,true)},[_c('div',{staticClass:"u-flex-1",attrs:{"slot":"post-legends"},slot:"post-legends"},[_vm._t("post-legends")],2)]):_vm._e(),(_vm.isGroupByChart)?_c('groupby-chart-legends',{attrs:{"is-group-by-selected":_vm.isGroupBySelected,"is-p-v-p-timeseries-enabled":_vm.enablePVPTimeseries,"metric-config":_vm.groupByConfig.metricConfig,"metric-data":_vm.groupByConfig.metricData,"available-group-by":_vm.groupByConfig.availableGroupBy,"selected-group-by":_vm.groupByConfig.selectedGroupBy,"selected-sorting":_vm.groupByConfig.selectedSorting,"total-entity-count":_vm.groupByConfig.totalEntityCount,"is-group-by-metric-loading":_vm.groupByConfig.isGroupByMetricLoading,"has-error-occurred":_vm.groupByConfig.hasErrorOccurred,"color-pattern":_vm.getColorPattern,"master-metric-color":_vm.masterMetricColor,"master-metric":_vm.masterMetricList[0],"add-more-metrics-func":_vm.addMoreMetricsFunc},on:{"groupByChanged":_vm.groupByChanged,"metricAdded":_vm.groupByMetricChanged,"metricChanged":_vm.groupByMetricChanged,"groupByMetricColors":_vm.setGroupByMetricsChartColor},scopedSlots:_vm._u([{key:"add-metric-footer",fn:function(ref){
var type = ref.type;
return _vm._t("add-metric-footer",null,{"type":type})}}],null,true)}):_vm._e(),(_vm.hasChartEvents)?_c('chart-event-legends',{attrs:{"disable-add-new-event":_vm.disableAddNewEvent,"metric-display-list":_vm.eventsShown,"metrics-list":_vm.eventsList,"default-select-metric":_vm.defaultSelectEvents,"selected-metric-limit":3},on:{"selectedList":_vm.eventsChanged}}):_vm._e(),_vm._t("space-between-legend-and-chart"),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChart),expression:"showChart"}],staticStyle:{"justify-content":"center","display":"flex","align-self":"stretch","height":"auto"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChartLoading),expression:"!isChartLoading"}],class:[
        { 'u-width-100': true },
        { 'u-height-auto': true },
        { 'u-spacing-mt-l': _vm.showPaddingBetween },
        { 'u-position-relative': true }
      ],attrs:{"data-cy":"workbenchCampaignsChart"}},[(_vm.showPoweredByCIQText)?_c('div',{staticClass:"u-color-grey-x-light u-font-size-7 u-position-absolute u-ciq-style u-font-weight-600",style:({ right: _vm.selectedMetric.length === 2 ? '70px' : '12px' })},[_vm._v(" Powered by "),_c('span',{staticClass:"u-color-grey-base"},[_vm._v("CommerceIQ")])]):_vm._e(),_c('chart',{staticClass:"c3-large u-flex-1 chart_space_class",class:_vm.customChartClass,style:({ height: _vm.chartHeight }),attrs:{"id":_vm.chartId,"config":_vm.chartConfig,"data":_vm.formattedChartData,"chart-change":_vm.chartChange,"is-p-v-p-timeseries-chart":_vm.enablePVPTimeseries},on:{"pointClicked":_vm.pointClicked,"updateStroke":function($event){return _vm.$emit('updateStroke')}}})],1),_c('loader',{staticClass:"u-bg-color-grey-white u-spacing-mt-0",style:({
        height: _vm.groupByConfig.isGroupByMetricLoading ? '550px' : '450px'
      }),attrs:{"loading":_vm.isChartLoading,"color":'#007cf6'}})],1),(_vm.showLegendWithPVP)?_c('legend-with-pvp',{attrs:{"config":_vm.pvpLegendConfig}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }