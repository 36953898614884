<template>
  <div v-if="searchTermsTableService">
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="false"
      v-show="!showSearchTermsTable"
    >
      <!-- will be released in v2 -->
      <benchmarkBrands
        :data-service="brandViewService"
        :global-settings="globalSettingsBrandView"
        :max-number-trendlines="5"
        :hide-header="true"
        :chart-height="300"
        :namespace="'marketshare-category/'"
        :page="'MARKETSHARE-CATEGORY'"
      />
    </div>
    <div v-show="showSearchTermsTable && !computedLoading && !computedError">
      <rb-insights-table
        :table-column="searchTableColDef"
        :table-row="searchTermsTableData"
        sorting-change-event="adInEffSortEvent"
        :grid-options="searchTermsTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchTermsTableService from '@/components/pages/insights/amazon/cva/services/adIncrementality/SearchTerms.js';
import benchmarkBrands from '@/components/pages/insights/amazon/share-of-voice/templates/benchmarkBrands';
import loader from '@/components/basic/loader';
import { isCloseDC } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'BrandStrength',
  components: {
    benchmarkBrands,
    loader
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewTable: null,
      searchTermsTableService: null,
      searchTermsTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      selectedView: null,
      brandViewService: null,
      globalSettingsBrandView: {
        where: {
          date: {
            from: '2022-08-07',
            to: '2022-09-03',
            name: 'last4Week',
            page_wise_min_max_key: 'marketshare-category'
          },
          pvpDate: { from: '2022-07-10', to: '2022-08-06', compare_name: null },
          dimensionNameValueList: [
            {
              dimensionName: 'category_hierarchy',
              dimensionValue: 'coffee~',
              operator: 'STARTSWITH'
            }
          ]
        },
        settingValue: {
          viewBy: { entityType: 'brand', label: 'Brands' },
          displayShareIn: { metricsList: 'SALES_ESTIMATES', label: 'Dollars' },
          include3p: {
            label: 'Include 3P sales',
            value: 'include3p',
            tooltip:
              'Helps in understanding the total share of your brands (1P + 3P sales) in the Entire Market (1P + 3P sales)'
          }
        }
      } // dummy global state
    };
  },
  computed: {
    showSearchTermsTable() {
      return this.selectedWidget === 'Search Terms';
    },
    computedLoading() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.loading ||
          this.searchTermsTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.error ||
          this.searchTermsTableService.error
        );
      }
    },
    brandTableColDef() {
      const columns = this.brandViewTable?.getColumnDefs() || [];
      return [...columns];
    },
    brandViewTableData() {
      return [];
    },
    searchTableColDef() {
      const columns = this.searchTermsTableService?.getColumnDefs() || [];
      return [...columns];
    },
    searchTermsTableData() {
      this.searchTermsTableGridOptions?.api?.sizeColumnsToFit();
      return this.searchTermsTableService?.getTableData() || [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.searchTermsTableService = new SearchTermsTableService(
      'cva',
      'table2',
      459,
      100
    );
    this.searchTermsTableService.fetchData(this.pageSettings);
    // this.fetchLiner();
  },
  methods: {
    async fetchLiner() {
      const x = await this.searchTermsTableService.fetchInsights(
        ['Ad inefficiency'],
        this.pageSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    }
  }
};
</script>
