<template>
  <div>
    <EditSuggestedCampaign @createAdGroup="createAdGroup" />
    <loader
      v-show="loading"
      class="fill--parent setupAdGroupLoader"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
  </div>
</template>

<script>
import EditSuggestedCampaign from '@/components/ams/campaign_creation/editSuggestedCampaign.vue';
import loader from '@/components/basic/loader';
import {
  adGroupCreationRequestTemplate,
  sdAdGroupCreationRequestTemplate
} from '@/components/ams/campaign_creation/steps/setup/campaign/requestTemplate.js';
import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import {
  formProductRequest,
  formKeywordRequest,
  formProductTargetsRequest,
  formNegativeKeywordsRequest,
  formNegativeTargetRequest
} from '@/components/ams/campaign_creation/steps/setup/campaign/formRequestPayloadUtility.js';
import HttpService from '@/utils/services/http-service';

export default {
  name: 'SetupAdGroupCreation',
  components: {
    EditSuggestedCampaign,
    loader
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters[
        this.pageData?.getter || 'campaignCreation/getAllStepsData'
      ];
    },
    boolSdAdgroup() {
      return this.$route.params.entityType === 'sd';
    }
  },
  created() {
    const suggestedCampaigns = [
      {
        skus: [],
        targets: [],
        negativeTargets: [],
        biddingStrategy: this.boolSdAdgroup ? 'clicks' : 'legacyForSales',
        targetingType: this.boolSdAdgroup ? 'product' : 'keyword', // defaultRadioButtonSelection
        suggestedCampaignId: this.$route.params.entityId,
        suggestedCampaignName: 'Ad Group 1',
        campaignType: this.boolSdAdgroup ? 'createAdGroupSd' : 'createAdGroupSp'
      }
    ];
    this.$store.dispatch(
      this.pageData?.action || 'campaignCreation/updateAllSetpsData',
      {
        suggestedCampaigns
      }
    );
  },
  methods: {
    async createAdGroup() {
      this.loading = true;
      const that = this;
      const suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns[suggestedCampaignIndex];
      let requestTemplate = adGroupCreationRequestTemplate;
      if (this.boolSdAdgroup) {
        requestTemplate = sdAdGroupCreationRequestTemplate;
        // as of now audience adgroup type is not introduced , so keeping it as product SDProductAdgroup only
        adgroupType =
          suggestedCampaign?.targetingType === 'product'
            ? 'SDProductAdgroup'
            : 'SDProductAdgroup';
      }
      const requestObject = cloneDeep(requestTemplate.requestObject);
      let adgroupType = suggestedCampaign?.targetingType;
      const keyValuePairs = {
        ':campaignName': that.$route.query?.campaignName,
        ':campaignId': that.$route.params.entityId,
        ':adgroupType': adgroupType,
        ':profileId':
          suggestedCampaign?.profile?.profile_id?.toString() || null,
        ':adgroupName':
          suggestedCampaign?.suggestedCampaignName?.trim() || 'Ad Group 1',
        ':adgroupBid': 1
      };
      for (const key in keyValuePairs) {
        deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
      }
      requestObject.request.productRequest = formProductRequest(
        requestTemplate.productRequest,
        suggestedCampaign?.skus || []
      );
      if (suggestedCampaign?.targetingType === 'keyword') {
        requestObject.request.keywordRequest = formKeywordRequest(
          requestTemplate.keywordRequest,
          suggestedCampaign?.targets || []
        );
      } else if (suggestedCampaign?.targetingType === 'product') {
        requestObject.request.targetRequest = formProductTargetsRequest(
          requestTemplate.targetsRequest,
          suggestedCampaign?.targets || []
        );

        requestObject.request.negativeTargetRequest = formNegativeTargetRequest(
          requestTemplate.negativeTargetRequest,
          suggestedCampaign?.negativeTargets || []
        );
      }
      requestObject.request.negativeKeywordRequest =
        formNegativeKeywordsRequest(
          requestTemplate.negativeKeywordRequest,
          suggestedCampaign?.negativeTargets || []
        );

      const message = {};
      try {
        const res = await HttpService.post(
          'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
          requestObject
        );
        if (res?.data?.success > 0 && res?.data?.failure === 0) {
          message.text = 'Ad Group created successfully';
          message.value = 'success';
        } else {
          message.text = 'Error while creating Ad Group';
          message.value = 'error';
        }
      } catch (err) {
        message.text = 'Error while creating Ad Group';
        message.value = 'error';
      } finally {
        this.$snackbar.open({
          message: message.text,
          duration: 10000,
          buttonColor: '#f5d908',
          actionText: ''
        });
        if (message.value === 'success') {
          const campaignId = this?.$route?.query?.key?.split('-')?.[0];
          this.$router.push({
            name: 'EntityDetails',
            params: {
              entityId: campaignId,
              entityType: this.boolSdAdgroup ? 'sd' : 'sp'
            }
          });
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="css" scoped>
.setupAdGroupLoader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
}
</style>
