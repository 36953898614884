<template>
  <div
    v-if="skusTableService"
    id="in_stock_perf"
  >
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="brandViewService"
      v-show="!showSkusTable"
    >
      <!-- will be replaced by benchmarkBrands -->
      <rb-insights-table
        :table-column="brandTableColDef"
        :table-row="table1Data"
        :grid-options="brandTableGridOptions"
      />
    </div>
    <div v-show="showSkusTable && !computedLoading && !computedError">
      <rb-insights-table
        :table-column="skusTableColDefs"
        :table-row="skusTableData"
        sorting-change-event="inStockSortEvent"
        :grid-options="skusTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SkusTableService from '@/components/pages/insights/amazon/cva/services/inStockPerf/skusTableService';
import skuWithRating from '@/components/basic/skuWithRating.vue';
import loader from '@/components/basic/loader';
import { isCloseDC } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'BrandStrength',
  components: {
    skuWithRating,
    loader
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewService: null,
      skusTableService: null,
      selectedView: null,
      skusTableGridOptions: {
        suppressColumnVirtualisation: true
      },
      brandTableGridOptions: {
        suppressColumnVirtualisation: true
      }
    };
  },
  computed: {
    showSkusTable() {
      return this.selectedWidget === 'SKUs';
    },
    computedLoading() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.loading ||
          this.skusTableService.loading
        );
      }
    },
    computedError() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.error || this.skusTableService.error
        );
      }
    },
    brandTableColDef() {
      const columns = this.brandViewService?.getColumnDefs() || [];
      return [...columns];
    },
    skusTableColDefs() {
      const columns = this.skusTableService?.getColumnDefs() || [];
      return [...columns];
    },
    table1Data() {
      return [];
    },
    skusTableData() {
      this.skusTableGridOptions?.api?.sizeColumnsToFit();
      return this.skusTableService?.getTableData() || [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.skusTableService = new SkusTableService('cva', 'table2', 459, 100);
    this.skusTableService.fetchData(this.pageSettings);
    this.fetchLiner();
  },
  created() {
    Vue.component('skuWithRating', skuWithRating);
  },
  methods: {
    async fetchLiner() {
      const x = await this.skusTableService.fetchInsights(
        ['Double click In Stock'],
        this.pageSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    }
  }
};
</script>
