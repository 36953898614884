<template>
  <div
    class="u-color-grey-mid-light u-display-flex u-flex-align-items-center u-flex-justify-content-space-between views-trigger u-font-size-7 u-cursor-pointer"
  >
    <div
      v-if="preText"
      class="preText u-spacing-mr-xs"
    >
      {{ preText }}
    </div>
    <div class="title">
      {{ title }}
    </div>
    <rb-icon
      :icon="'arrow-down'"
      class="u-spacing-ml-m rb-icon--x-small"
    />
  </div>
</template>

<script>
export default {
  props: {
    preText: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    displayConfig: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="css" scoped>
.views-trigger {
  display: inline-flex;
  text-align: center;
  line-height: 1;
  vertical-align: top;
  font-family: inherit;
  border-radius: 4px;
  box-sizing: border-box;
  color: #8b8f93;
  border: 1px solid #e9eaeb;
  padding: 0px 12px;
  background: #fff;
  height: 36px;
}
.views-trigger:hover,
.views-trigger:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
