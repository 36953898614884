<template>
  <div class="u-spacing-pv-s">
    <div
      class="u-font-size-7 u-font-weight-600 u-spacing-pb-xs u-color-grey-x-light"
    >
      {{ title }}
    </div>
    <div
      class="u-border-radius-xs u-color-grey-white u-spacing-p-xs u-font-size-7 fit-content"
      :class="colorMixin"
    >
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    colorMixin: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="css">
.fit-content {
  width: fit-content;
}
</style>
