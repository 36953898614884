<template>
  <section
    class="u-cursor-pointer u-display-flex u-flex-align-items-center"
    @click="handleClick"
  >
    <rb-icon
      class="rb-icon--medium u-color-grey-base"
      :class="{ 'rotate-icon': isExpanded, [iconClass]: !!iconClass }"
      :icon="'arrow1-down'"
    />
    <div
      class="u-spacing-ml-s"
      :class="titleFontClass"
      data-cy="headingTitle"
    >
      {{ title }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    titleFontClass: {
      type: String,
      default: 'u-font-size-4 u-font-weight-600'
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="css" scoped>
.rotate-icon {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
</style>
