<template>
  <div class="u-spacing-ph-xxl u-spacing-pt-xxxl u-position-relative">
    <loader
      v-show="rightButtonLoading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <section class="u-spacing-pt-m">
      <!-- <createCampaignHeader :step="step" /> -->
      <placementBidMultiplier
        :all-steps-data="allStepsData"
        :placement-inclusion-expand="placementInclusionExpand"
        :placement-inclusion-object="placementInclusion"
        :bid-multiplier-object="bidMultiplier"
        :bid-multiplier-expand="bidMultiplierExpand"
        :form-error-object="formErrorObject"
        @bidMultiplierValuesUpdated="bidMultiplierValuesUpdated"
        @placementInclusionValuesUpdated="placementInclusionValuesUpdated"
        @placementInclusionExpandClicked="placementInclusionExpandClicked"
        @bidMultiplierExpandClicked="bidMultiplierExpandClicked"
      />
      <createCampaignFooter
        :right-button-loading="rightButtonLoading"
        :disable-right-btn="disableRightButton"
        btn-right-text="Launch Campaign"
        class="u-spacing-pt-l"
        :error-message="errorMessage"
        @onClickBtnRight="onClickBtnRight"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import placementBidMultiplier from './PlacementBidMultiplier.vue';
import loader from '@/components/basic/loader.vue';
import {
  ErrorCodes,
  WalmartCreateCustomException
} from '@/components/ams/campaign_creation/walmart_steps/errorUtility';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import {
  getBidMultiplierObject,
  getPlacementInclusionObject
} from '@/components/ams/campaign_creation/walmart_steps/placementBidMultiplier/util.js';

export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    placementBidMultiplier,
    loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      placementInclusion: {
        searchCarousel: {
          title: 'Enabled',
          value: 'included'
        },
        itemCarousel: {
          title: 'Disable',
          value: 'excluded'
        },
        itemBuyBox: {
          title: 'Disable',
          value: 'excluded'
        }
      },
      bidMultiplier: {
        placement: { buyBox: '', searchIngrid: '', homePage: '', stockUp: '' },
        platform: { desktop: '', mobile: '', app: '' }
      },
      placementInclusionExpand: false,
      bidMultiplierExpand: false,
      campaignId: null,
      adGroupId: null,
      advertiserId: null,
      rightButtonLoading: false,
      walmartCampaignCreateDataService: {},
      errorMessage: '',
      formErrorObject: {
        placementBidMultiplier: '',
        platformBidMultiplier: ''
      }
    };
  },
  computed: {
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    },
    disableRightButton() {
      let flag = false;
      if (this.bidMultiplierExpand && this.areValidationsErrorPresent) {
        flag = true;
        return flag;
      }
      if (
        this.allStepsData?.campaignNameAlreadyExists === true ||
        this.allStepsData?.campaignName === '' ||
        this.rightButtonLoading
      ) {
        flag = true;
      }
      return flag;
    },
    areValidationsErrorPresent() {
      const formErrorObject = this.formErrorObject;
      let areValidationsErrorPresent = false;
      for (const key of Object.keys(formErrorObject)) {
        if (formErrorObject[key] !== '') {
          areValidationsErrorPresent = true;
          return areValidationsErrorPresent;
        }
      }
      return areValidationsErrorPresent;
    }
  },
  watch: {
    placementInclusion: function (newVal) {
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ placementInclusion: newVal }
      });
    },
    bidMultiplier: function (newVal) {
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ bidMultiplier: newVal }
      });
      this.checkFormValidity();
    }
  },
  async created() {
    this.initializeDataService();
    await this.fetchSaveAndPreFillValues();
  },
  methods: {
    validateMultiplierObject(multiplierObject) {
      let errorMessage = '';
      for (let multiplierObjectKey of Object.keys(multiplierObject)) {
        const valueToCheck = multiplierObject[multiplierObjectKey];
        if (valueToCheck === '') {
          // This means that the input box is empty, hence no need to check;
          continue;
        } else if (parseFloat(valueToCheck) < 0) {
          errorMessage = 'The multiplier should be greater than 0%';
          break;
        }
      }
      return errorMessage;
    },
    checkFormValidity() {
      const formErrorObject = { ...this.formErrorObject };

      const placementBidMultiplierError = this.validateMultiplierObject(
        this.bidMultiplier.placement
      );
      const platformBidMultiplierError = this.validateMultiplierObject(
        this.bidMultiplier.platform
      );

      formErrorObject.placementBidMultiplier = placementBidMultiplierError;
      formErrorObject.platformBidMultiplier = platformBidMultiplierError;

      this.formErrorObject = formErrorObject;
    },
    async fetchSaveAndPreFillValues() {
      try {
        // A successful response will always be an empty array
        // or
        // [
        //   {
        //     placement: 'Item Buybox',
        //     status: 'excluded'
        //   },
        //   {
        //     placement: 'Item Carousel',
        //     status: 'excluded'
        //   },
        //   {
        //     placement: 'Search Carousel',
        //     status: 'excluded'
        //   }
        // ]
        const fetchedPlacementDetailsResponse =
          await this.fetchPlacementDetails();

        // A successful response will always be an empty Array
        // or
        // [
        //   {
        //     "campaignId": 590548,
        //     "placementType": "Search Ingrid",
        //     "multiplier": 200
        //   },
        //   {
        //     "campaignId": 590548,
        //     "placementType": "Buy-Box",
        //     "multiplier": 100
        //   }
        // ]
        const fetchedPlacementBidMultiplierDetailsResponse =
          await this.fetchPlacementBidMultiplierDetails();

        const fetchedPlatformBidMultiplierDetailsResponse =
          await this.fetchPlatformBidMultiplierDetails();
        const newAllStepsData = {
          ...this.allStepsData,
          ...{
            fetchedPlacementDetailsResponse:
              fetchedPlacementDetailsResponse?.data?.response || [],
            fetchedPlacementBidMultiplierDetailsResponse:
              fetchedPlacementBidMultiplierDetailsResponse?.data?.response ||
              [],
            fetchedPlatformBidMultiplierDetailsResponse:
              fetchedPlatformBidMultiplierDetailsResponse?.data?.response || []
          }
        };
        this.$store.dispatch(this.selectedValues.action, newAllStepsData);

        const updatedAllStepsDataFromStore =
          this.$store?.getters?.[this.selectedValues?.getter];
        this.preFillFieldsFromFetchedData(updatedAllStepsDataFromStore);
      } catch (err) {
        console.log(err);
        this.handleCaughtError(err);
      }
    },
    preFillFieldsFromFetchedData(allStepsData = this.allStepsData) {
      this.preFillPlacementInclusionValues(allStepsData);
      this.preFillBidMultiplierValues(allStepsData);
    },
    preFillPlacementInclusionValues(allStepsData) {
      const keyValuePairToPreFillPlacementInclusion = {
        excluded: {
          title: 'Disable',
          value: 'excluded'
        },
        included: {
          title: 'Enabled',
          value: 'included'
        }
      };
      const fetchedPlacementDetailsResponse =
        allStepsData?.fetchedPlacementDetailsResponse;
      let newPlacementInclusionValueObject = {
        ...this.placementInclusion
        // The object formed here will be of this format
        // searchCarousel: {
        //   title: 'Disable',
        //   value: 'excluded'
        // },
        // itemCarousel: {
        //   title: 'Disable',
        //   value: 'excluded'
        // },
        // itemBuyBox: {
        //   title: 'Disable',
        //   value: 'excluded'
        // }
      };
      fetchedPlacementDetailsResponse.forEach((placementItem) => {
        if (placementItem?.placement === 'Search Carousel') {
          newPlacementInclusionValueObject = {
            ...newPlacementInclusionValueObject,
            searchCarousel:
              keyValuePairToPreFillPlacementInclusion[placementItem?.status]
          };
        }
        if (placementItem?.placement === 'Item Carousel') {
          newPlacementInclusionValueObject = {
            ...newPlacementInclusionValueObject,
            itemCarousel:
              keyValuePairToPreFillPlacementInclusion[placementItem?.status]
          };
        }
        if (placementItem?.placement === 'Item Buybox') {
          newPlacementInclusionValueObject = {
            ...newPlacementInclusionValueObject,
            itemBuyBox:
              keyValuePairToPreFillPlacementInclusion[placementItem?.status]
          };
        }
      });
      this.placementInclusion = newPlacementInclusionValueObject;
    },
    preFillBidMultiplierValues(allStepsData) {
      // Pre filling placement bid multiplier
      const fetchedPlacementBidMultiplierDetailsResponse =
        allStepsData?.fetchedPlacementBidMultiplierDetailsResponse;

      let newPlacementBidMultiplierObject = {
        // this object will be of this format
        // {
        //   buyBox: 500,
        //   searchIngrid: 300
        // }
        ...this.bidMultiplier.placement
      };
      fetchedPlacementBidMultiplierDetailsResponse.forEach(
        (placementBidMultiplierItem) => {
          if (placementBidMultiplierItem?.placementType === 'Search Ingrid') {
            newPlacementBidMultiplierObject = {
              ...newPlacementBidMultiplierObject,
              searchIngrid: placementBidMultiplierItem?.multiplier
            };
          }
          if (placementBidMultiplierItem?.placementType === 'Buy-Box') {
            newPlacementBidMultiplierObject = {
              ...newPlacementBidMultiplierObject,
              buyBox: placementBidMultiplierItem?.multiplier
            };
          }
          if (placementBidMultiplierItem?.placementType === 'Home Page') {
            newPlacementBidMultiplierObject = {
              ...newPlacementBidMultiplierObject,
              homePage: placementBidMultiplierItem?.multiplier
            };
          }
          if (placementBidMultiplierItem?.placementType === 'Stock Up') {
            newPlacementBidMultiplierObject = {
              ...newPlacementBidMultiplierObject,
              stockUp: placementBidMultiplierItem?.multiplier
            };
          }
        }
      );

      // Pre filling platform bid multiplier
      const fetchedPlatformBidMultiplierDetailsResponse =
        allStepsData?.fetchedPlatformBidMultiplierDetailsResponse;

      let newPlatformBidMultiplierObject = {
        // this object will be of this format
        // { desktop: '', mobile: '', app: '' }
        ...this.bidMultiplier.platform
      };
      fetchedPlatformBidMultiplierDetailsResponse.forEach(
        (platformBidMultiplierItem) => {
          if (platformBidMultiplierItem?.platformType === 'Desktop') {
            newPlatformBidMultiplierObject = {
              ...newPlatformBidMultiplierObject,
              desktop: platformBidMultiplierItem?.multiplier
            };
          }
          if (platformBidMultiplierItem?.platformType === 'App') {
            newPlatformBidMultiplierObject = {
              ...newPlatformBidMultiplierObject,
              app: platformBidMultiplierItem?.multiplier
            };
          }
          if (platformBidMultiplierItem?.platformType === 'Mobile') {
            newPlatformBidMultiplierObject = {
              ...newPlatformBidMultiplierObject,
              mobile: platformBidMultiplierItem?.multiplier
            };
          }
        }
      );

      this.bidMultiplier = {
        ...this.bidMultiplier,
        placement: newPlacementBidMultiplierObject,
        platform: newPlatformBidMultiplierObject
      };
    },
    async fetchPlatformBidMultiplierDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchPlatformBidMultiplierDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_PLATFORM_BID_MULTIPLIER_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_PLATFORM_BID_MULTIPLIER_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async fetchPlacementBidMultiplierDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchPlacementBidMultiplierDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_PLACEMENT_BID_MULTIPLIER_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_PLACEMENT_BID_MULTIPLIER_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async fetchPlacementDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchPlacementInclusionDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_PLACEMENT_INCLUSION_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_PLACEMENT_INCLUSION_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      this.campaignId = parseInt(campaignId) || undefined;
      const retailer = this.$store.getters.getRetailer;
      this.adGroupId = parseInt(adGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      const dataService =
        this.step?.dataService || WalmartCampaignCreateDataService;
      this.walmartCampaignCreateDataService = new dataService(
        campaignId,
        adGroupId,
        advertiserId,
        campaignType,
        retailer
      );
    },
    async onClickBtnRight() {
      try {
        const [wasPlacementInclusionUpdated, placementInclusionObject] =
          this.checkIfPlacementInclusionWasUpdated();
        const [wasPlacementBidMultiplierUpdated, placementBidMultiplierObject] =
          this.checkIfBidMultiplierWasUpdated('placement');
        const [wasPlatformBidMultiplierUpdated, platformBitMultiplierObject] =
          this.checkIfBidMultiplierWasUpdated('platform');
        if (wasPlacementInclusionUpdated && this.placementInclusionExpand) {
          await this.updatePlacementInclusion(placementInclusionObject);
        }
        if (wasPlacementBidMultiplierUpdated && this.bidMultiplierExpand) {
          await this.updatePlacementBidMultiplier(placementBidMultiplierObject);
        }
        if (wasPlatformBidMultiplierUpdated && this.bidMultiplierExpand) {
          await this.updatePlatformBidMultiplier(platformBitMultiplierObject);
        }
        await this.launchCampaign();
        this.$router.push({
          name: 'campaign_created'
        });
      } catch (err) {
        console.log(err);
        this.handleCaughtError(err);
      }
    },
    handleCaughtError(err) {
      if (err.message === ErrorCodes.SOMETHING_WENT_WRONG) {
        this.errorMessage =
          'Something went wrong. Please try again after some time.';
      }
      if (
        err.message === ErrorCodes.FETCHING_PLACEMENT_INCLUSION_DETAILS_FAILED
      ) {
        this.errorMessage =
          'Something went wrong while fetching the placement inclusion details.';
      }
      if (
        err.message ===
        ErrorCodes.FETCHING_PLACEMENT_BID_MULTIPLIER_DETAILS_FAILED
      ) {
        this.errorMessage =
          'Something went wrong while fetching placement bid multiplier details. Please try again after some time.';
      }
      if (
        err.message ===
        ErrorCodes.FETCHING_PLATFORM_BID_MULTIPLIER_DETAILS_FAILED
      ) {
        this.errorMessage =
          'Something went wrong while fetching platform bid multiplier details. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_PLACEMENT_INCLUSION_FAILED) {
        this.errorMessage =
          'Something went wrong while updating the placement inclusion details. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_PLACEMENT_BID_MULTIPLIER_FAILED) {
        this.errorMessage =
          'Something went wrong while updating placement bid multiplier details. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_PLATFORM_BID_MULTIPLIER_FAILED) {
        this.errorMessage =
          'Something went wrong while updating platform bid multiplier details. Please try again after some time.';
      }
      if (err.message === ErrorCodes.LAUNCH_CAMPAIGN_FAILED) {
        this.errorMessage =
          'Something went wrong while launching the campaign. Please try again after some time.';
      }
    },
    async updatePlacementInclusion(placementInclusionObject) {
      this.rightButtonLoading = true;
      try {
        const updatePlacementInclusionResponse =
          await this.walmartCampaignCreateDataService.updatePlacementInclusion(
            placementInclusionObject
          );
        if (updatePlacementInclusionResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_PLACEMENT_INCLUSION_FAILED,
            updatePlacementInclusionResponse
          );
        }
      } catch (err) {
        if (err.message === ErrorCodes.UPDATE_PLACEMENT_INCLUSION_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async updatePlacementBidMultiplier(placementBidMultiplierObject) {
      this.rightButtonLoading = true;
      try {
        const updatePlacementBidMultiplierResponse =
          await this.walmartCampaignCreateDataService.updatePlacementBidMultiplier(
            placementBidMultiplierObject
          );
        if (updatePlacementBidMultiplierResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_PLACEMENT_BID_MULTIPLIER_FAILED,
            updatePlacementBidMultiplierResponse
          );
        }
      } catch (err) {
        if (err.message === ErrorCodes.UPDATE_PLACEMENT_BID_MULTIPLIER_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async updatePlatformBidMultiplier(platformBidMultiplierObject) {
      this.rightButtonLoading = true;
      try {
        const updatePlatformBidMultiplierResponse =
          await this.walmartCampaignCreateDataService.updatePlatformBidMultiplier(
            platformBidMultiplierObject
          );
        if (updatePlatformBidMultiplierResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_PLATFORM_BID_MULTIPLIER_FAILED,
            updatePlatformBidMultiplierResponse
          );
        }
      } catch (err) {
        if (err.message === ErrorCodes.UPDATE_PLATFORM_BID_MULTIPLIER_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async launchCampaign() {
      this.rightButtonLoading = true;
      try {
        const launchCampaignResponse =
          await this.walmartCampaignCreateDataService.launchCampaign();
        if (launchCampaignResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.LAUNCH_CAMPAIGN_FAILED,
            launchCampaignResponse
          );
        }
      } catch (err) {
        if (err.message === ErrorCodes.LAUNCH_CAMPAIGN_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    placementInclusionExpandClicked() {
      this.placementInclusionExpand = !this.placementInclusionExpand;
    },
    bidMultiplierExpandClicked() {
      this.bidMultiplierExpand = !this.bidMultiplierExpand;
    },
    bidMultiplierValuesUpdated(data) {
      this.bidMultiplier = data;
    },
    placementInclusionValuesUpdated(data) {
      this.placementInclusion = data;
    },
    checkIfPlacementInclusionWasUpdated() {
      const allStepsData = this.allStepsData;
      const fetchedPlacementDetailsResponse =
        allStepsData?.fetchedPlacementDetailsResponse || [];
      const placementInclusionObjectForApi = getPlacementInclusionObject(
        this.placementInclusion,
        fetchedPlacementDetailsResponse
      );
      const wasPlacementInclusionUpdated =
        Object.keys(placementInclusionObjectForApi).length > 0;
      return [wasPlacementInclusionUpdated, placementInclusionObjectForApi];
    },
    checkIfBidMultiplierWasUpdated(bidMultiplierType = 'placement') {
      const allStepsData = this.allStepsData;
      let currentMultiplierObject = {};
      let fetchedMultiplierResponse = [];
      if (bidMultiplierType === 'placement') {
        currentMultiplierObject = this.bidMultiplier.placement || {};
        fetchedMultiplierResponse =
          allStepsData?.fetchedPlacementBidMultiplierDetailsResponse || [];
      }
      if (bidMultiplierType === 'platform') {
        currentMultiplierObject = this.bidMultiplier.platform || {};
        fetchedMultiplierResponse =
          allStepsData?.fetchedPlatformBidMultiplierDetailsResponse || [];
      }
      const bidMultiplierObjectForApi = getBidMultiplierObject(
        currentMultiplierObject,
        fetchedMultiplierResponse,
        bidMultiplierType
      );
      const bidMultiplierUpdated =
        Object.keys(bidMultiplierObjectForApi).length > 0;
      return [bidMultiplierUpdated, bidMultiplierObjectForApi];
    }
  }
};
</script>

<style scoped></style>
