<template>
  <section>
    <div>
      <expandableTooltip
        v-if="data.showManufacturerTooltip"
        :target-id="'tooltip-' + id"
        :primary-text="data.manufacturer"
        :primary-pre-text="data.preText"
        secondary-pre-text="associated brands: "
        :items-list="data.brandsList"
      />
      <customTippy
        v-else
        :id="'tooltip-' + id"
        :pre-text="data.preText"
        :title="data.manufacturer"
      />
    </div>
    <section
      v-tippy="tippyOptions()"
      class="u-display-flex u-flex-direction-row u-flex-justify-content-center u-flex-align-items-center"
    >
      <div
        class="u-display-block u-spacing-p-xs u-border-radius-xxxl u-border-all u-border-color-grey-xxx-light"
      >
        <img
          v-if="!initials[data.name]"
          :src="data.imageUrl"
          alt="brand"
          class="brand-image u-border-color-grey-xxx-light u-border-width-s logo-large-view"
          @error="handleNoImage(data.name)"
        />
        <div
          v-else
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-3 u-border-width-s u-text-case-upper u-color-grey-base brand-image"
        >
          {{ initials[data.name] }}
        </div>
      </div>
      <div
        class="u-display-flex u-flex-justify-content-center u-spacing-ml-xs u-font-size-4 u-font-weight-400 u-color-grey-base"
      >
        {{ data.name }}
      </div>
    </section>
  </section>
</template>

<script>
import Vue from 'vue';
import customTippy from '@/components/pages/insights/amazon/share-of-voice/atoms/customTippy.vue';
import expandableTooltip from '@/components/pages/insights/amazon/market-share/molecules/expandable-tooltip.vue';

export default {
  name: 'CategoryIconCell',
  owner: 'Saumya',
  components: {
    customTippy,
    expandableTooltip
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initials: {},
      id: null
    };
  },
  created() {
    this.id = this._uid;
  },
  methods: {
    tippyOptions() {
      return {
        html: '#tooltip-' + this.id,
        placement: 'bottom-start',
        maxWidth: '440px',
        interactive: true,
        reactive: true,
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      };
    },
    handleNoImage(name) {
      const initials = name
        .split(' ')
        .map((item) => item[0])
        .slice(0, 2)
        .join('');
      Vue.set(this.initials, name, initials);
    }
  }
};
</script>

<style lang="css" scoped>
.brand-image {
  border-radius: 50%;
  background-color: white;
  object-fit: contain;
  min-height: 32px;
  min-width: 32px;
  height: 32px;
  width: 32px;
}
.brand-image.logo-large-view {
  height: 32px;
  width: 32px;
}
</style>
