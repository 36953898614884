import { filters } from '@ciq-dev/ciq-ui';
// for stream + pvp data
const streamConfig = {
  streamCell: true,
  streamPage: ['campaign', 'keyword'],
  streamGetter: {
    amazon: {
      campaign: 'getCampaignsStreamMap',
      keyword: 'getKeywordsStreamMap'
    }
  },
  modeGetter: {
    amazon: {
      campaign: 'getAmazonCampaignMode',
      keyword: 'getAmazonKeywordMode'
    }
  }
};

// for pvp only data
let pvpOnlyConfig = {};

if (filters.config_check('feature.pages.enableRMMAmazonPVP')) {
  pvpOnlyConfig = {
    streamCell: true,
    streamPage: ['campaign', 'keyword', 'sku', 'search'],
    enablePVPOnly: ['campaign', 'keyword', 'sku', 'search']
  };

  streamConfig.enablePVPOnly = ['sku', 'search'];
  streamConfig.streamPage = ['campaign', 'keyword', 'sku', 'search'];
  streamConfig.modeGetter = {
    amazon: {
      campaign: 'getAmazonCampaignMode',
      keyword: 'getAmazonKeywordMode'
    }
  };
}

export default {
  map: {
    status: {
      title: 'Active',
      showToolTip: true,
      tooltipHeader: 'Active Status',
      realTimeHeader: ['keyword', 'sku'],
      getter: {
        amazon: {
          keyword: 'getKeywordsRealTimeMetadata',
          sku: 'getSkusRealTimeMetadata'
        }
      },
      key: 'status',
      toolTipText: 'Current status of the campaign',
      keyword_toolTipText: 'Current status of the keyword',
      search_toolTipText: 'Current status of the keyword',
      sku_toolTipText: 'Current status of the SKU',
      type: 'Icon',
      page: ['keyword', 'sku'],
      keyword_keyOrder: 1,
      search_keyOrder: 1,
      sku_keyOrder: 1,
      pinned: 'left'
    },
    campaign_status: {
      title: 'Active',
      showToolTip: true,
      tooltipHeader: 'Active Status',
      realTimeHeader: ['campaign'],
      getter: {
        amazon: {
          campaign: 'getCampaignsRealTimeMetadata'
        }
      },
      key: 'campaign_status',
      toolTipText: 'Current status of the campaign',
      type: 'Icon',
      page: ['campaign'],
      campaign_keyOrder: 1,
      pinned: 'left'
    },
    timeline: {
      title: 'Timeline',
      toolTipText: 'Activity log',
      type: 'icon',
      page: 'common',
      campaign_keyOrder: 1,
      keyword_keyOrder: 1,
      search_keyOrder: 1,
      sku_keyOrder: 1,
      pinned: 'left'
    },
    campaign_name: {
      title: 'Campaign Name',
      toolTipText: 'Campaign name of which the campaign is a part of',
      keyword_toolTipText: 'Campaign name of which the keyword is a part of',
      search_toolTipText: 'Campaign name of which the keyword is a part of',
      sku_toolTipText: 'Campaign name of which the SKU is a part of',
      type: 'redirect',
      pinned: 'left',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 2,
      keyword_keyOrder: 4,
      search_keyOrder: 4,
      sku_keyOrder: 3
    },
    asin: {
      title: 'SKU',
      toolTipText: 'ASIN of the growth SKU',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 2,
      pinned: 'left'
    },
    serving_status: {
      title: 'Status',
      toolTipText: 'Latest serving state of the campaign',
      type: 'String',
      isServingStatus: true,
      page: 'campaign',
      campaign_keyOrder: 3
    },
    base_bid: {
      getter: {
        amazon: {
          keyword: 'getKeywordsRealTimeMetadata'
        }
      },
      realTimeMetadataCell: true,
      title: 'Bid',
      tooltipHeader: 'Base Bid',
      toolTipText: 'Base bid amount on the keyword',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword'],
      realTimeHeader: ['keyword'],
      keyword_keyOrder: 4
    },
    spend: {
      title: 'Ad Spend',
      tooltipHeader: 'Advertising Spend',
      toolTipText: 'Total spend on the campaign in the selected time period',
      keyword_toolTipText:
        'Total spend on the keyword in the selected time period',
      search_toolTipText:
        'Total spend on the search term in the selected time period',
      sku_toolTipText: 'Total spend on the SKU in the selected time period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 6,
      keyword_keyOrder: 4,
      search_keyOrder: 5,
      sku_keyOrder: 5,
      ...streamConfig
    },
    paid_sales_14d: {
      title: 'Ad Sales',
      tooltipHeader: 'Advertising Sales',
      toolTipText:
        'Paid sales generated from the campaign in the selected time period using 14 days attribution method',
      keyword_toolTipText:
        'Paid sales generated from the keyword in the selected time period using 14 days attribution method',
      search_toolTipText:
        'Paid sales generated from the search term in the selected time period using 14 days attribution method',
      sku_toolTipText:
        'Paid sales generated on the SKU in the selected time period using 14 days attribution method',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'common',
      campaign_keyOrder: 11,
      keyword_keyOrder: 9,
      search_keyOrder: 10,
      sku_keyOrder: 9,
      ...streamConfig
    },
    roi: {
      title: 'ROAS',
      tooltipHeader: 'Return on Advertising Spend',
      ...streamConfig,
      toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      keyword_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      search_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      sku_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'common',
      campaign_keyOrder: 12,
      keyword_keyOrder: 10,
      search_keyOrder: 11,
      sku_keyOrder: 10
    },
    iroas: {
      title: 'iROAS',
      tooltipHeader: 'Incremental Return on Advertising Spend',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      toolTipText:
        'Incremental Sales generated for each dollar of ad spend. This is an ML-driven metric that is currently in beta.',
      campaign_keyOrder: 13,
      keyword_keyOrder: 11,
      ...pvpOnlyConfig
    },
    base_budget: {
      title: 'Daily Budget',
      getter: {
        amazon: {
          campaign: 'getCampaignsRealTimeMetadata'
        }
      },
      toolTipText: 'Base budget allocated to the campaign',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'campaign',
      campaign_keyOrder: 4,
      realTimeMetadataCell: true,
      realTimeHeader: ['campaign']
    },
    percent_time_in_budget: {
      title: '% Time in Budget',
      tooltipHeader: 'Percent Time In Budget',
      toolTipText:
        'The share of time the campaign was in budget during the past 7 days',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'campaign',
      campaign_keyOrder: 5,
      ...pvpOnlyConfig
    },
    clicks: {
      title: 'Clicks',
      toolTipText:
        'Total number of clicks generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of clicks generated from the keyword in the selected time period',
      search_toolTipText:
        'Total number of clicks generated from the search term in the selected time period',
      sku_toolTipText:
        'Total number of clicks generated on the SKU in the selected time period',
      type: 'NUMERIC',
      page: 'common',
      campaign_keyOrder: 7,
      keyword_keyOrder: 5,
      search_keyOrder: 6,
      sku_keyOrder: 20,
      ...streamConfig
    },
    cpc: {
      title: 'CPC',
      tooltipHeader: 'Cost Per Click',
      toolTipText:
        'Average cost per click in the selected time period calculated as spend divided by clicks',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'common',
      campaign_keyOrder: 8,
      keyword_keyOrder: 6,
      search_keyOrder: 7,
      sku_keyOrder: 6,
      ...streamConfig
    },
    ctr: {
      title: 'CTR',
      tooltipHeader: 'Click Through Rate',
      toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions',
      sku_toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period on the ASIN calculated as clicks divided by impressions',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'common',
      campaign_keyOrder: 9,
      keyword_keyOrder: 7,
      search_keyOrder: 8,
      sku_keyOrder: 7,
      ...streamConfig
    },
    conversions_14d: {
      title: 'CVR',
      tooltipHeader: 'Conversion Rate',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders divided by clicks',
      keyword_toolTipText:
        'Measure of how many clicks converted into orders calculated as orders using 14 day attribution method divided by clicks',
      sku_toolTipText:
        'Measure of how many clicks converted into orders calculated as orders using 14 day attribution method divided by clicks',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 10,
      keyword_keyOrder: 8,
      search_keyOrder: 9,
      sku_keyOrder: 8,
      ...streamConfig
    },
    incremental_sales: {
      title: 'Incremental Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['campaign', 'keyword'],
      toolTipText:
        'Amount of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.',
      campaign_keyOrder: 15,
      keyword_keyOrder: 14,
      ...pvpOnlyConfig
    },
    incremental_fraction: {
      title: 'Incremental Fraction',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword'],
      toolTipText:
        'Estimated share of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.',
      campaign_keyOrder: 14,
      keyword_keyOrder: 12,
      ...pvpOnlyConfig
    },
    PercentageSalesNewToBrand14d: {
      title: '% NTB Sales',
      tooltipHeader: 'Percentage of Sales New to Brand',
      toolTipText:
        'Percentage of New to Brand Sales against Paid Sales from the campaign in the selected time period using 14 days attribution',
      keyword_toolTipText:
        'Percentage of New to Brand Sales against Paid Sales from the keyword in the selected time period using 14 days attribution',
      sku_toolTipText:
        'Percentage of New to Brand Sales against Paid Sales from the SKU in the selected time period using 14 days attribution',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword', 'sku'],
      campaign_keyOrder: 16,
      keyword_keyOrder: 14,
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    incremental_fraction_band: {
      title: 'Incrementality Band',
      toolTipText:
        "Classification of performance on the basis of incrementality. 'High' band implies majority of paid sales are incremental and ‘Low’ band implies that majority of sales are not incremental.",
      type: 'String',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 17,
      keyword_keyOrder: 14,
      ...pvpOnlyConfig
    },
    impressions: {
      title: 'Impressions',
      tooltipHeader: 'Impressions',
      toolTipText:
        'Total number of impressions generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of impressions generated from the keyword in the selected time period',
      search_toolTipText:
        'Total number of impressions generated from the search term in the selected time period',
      sku_toolTipText:
        'Total number of impressions generated on the SKU in the selected time period',
      type: 'NUMERIC',
      page: 'common',
      campaign_keyOrder: 18,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    suggested_budget: {
      title: 'Recommended Daily Budget',
      toolTipText:
        'Estimated budget needed to keep the campaign in budget for the full 24-hour period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'campaign',
      campaign_keyOrder: 19
    },
    campaign_type: {
      title: 'Campaign type',
      tooltipHeader: 'Campaign Type',
      toolTipText: 'Type of campaign (Sponsored products, Sponsored brands)',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 20,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 4
    },
    targeting_type: {
      title: 'Targeting type',
      tooltipHeader: 'Targeting Type',
      toolTipText: 'Targeting type (Manual or Auto)',
      type: 'String',
      page: ['campaign', 'keyword'],
      campaign_keyOrder: 21
    },
    profile_name: {
      title: 'Profile',
      tooltipHeader: 'Profile Name',
      toolTipText: 'Profile associated with the entity',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 22,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    portfolio_name: {
      title: 'Portfolio',
      tooltipHeader: 'Portfolio Name',
      toolTipText: 'Portfolio associated with the entity',
      type: 'String',
      page: 'common',
      campaign_keyOrder: 23,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    tactic: {
      title: 'Tactic',
      toolTipText:
        'Display targeting strategy for the campaign (only available for SD campaigns)',
      type: 'String',
      page: ['campaign', 'keyword', 'sku'],
      campaign_keyOrder: 24,
      keyword_keyOrder: 14,
      sku_keyOrder: 20
    },
    acos_14d: {
      title: 'ACoS',
      tooltipHeader: 'Advertising Cost of Sales',
      ...streamConfig,
      toolTipText:
        'Advertised cost of sales generated from the campaign in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales',
      keyword_toolTipText:
        'Advertised cost of sales generated from the keyword in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales',
      search_toolTipText:
        'Advertised cost of sales generated from the search term in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales',
      sku_toolTipText:
        'Advertised cost of sales generated on the ASIN in the selected time period using 14 days attribution method calculated as Total spend/ 14 days attributed sales',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'keyword', 'sku', 'search'],
      campaign_keyOrder: 25,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    orders_14d: {
      title: 'Orders',
      tooltipHeader: 'Orders',
      toolTipText:
        'Total number of orders generated from the campaign in the selected time period using 14 days attribution method',
      keyword_toolTipText:
        'Total number of orders generated from the keyword in the selected time period using 14 days attribution method',
      search_toolTipText:
        'Total number of orders generated from the search term in the selected time period using 14 days attribution method',
      sku_toolTipText:
        'Total number of orders generated on the SKU in the selected time period using 14 days attribution method',
      type: 'NUMERIC',
      page: 'common',
      campaign_keyOrder: 26,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 12,
      ...streamConfig
    },
    attributedSalesNewToBrand14d: {
      title: 'NTB Sales',
      tooltipHeader: 'Attributed Sales New To Brand',
      toolTipText:
        'New to Brand sales generated from the campaign in the selected time period using 14 days attribution',
      keyword_toolTipText:
        'New to Brand sales generated from the keyword in the selected time period using 14 days attribution',
      sku_toolTipText:
        'New to Brand sales generated from the SKU in the selected time period using 14 days attribution',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword', 'sku'],
      campaign_keyOrder: 27,
      keyword_keyOrder: 14,
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    halo_sales_14_day: {
      title: 'Brand Halo Sales',
      tooltipHeader: 'Brand Halo Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      toolTipText: 'Brand Halo Sales occuring within 14 day of click on ads',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 28,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    halo_sales_7_day: {
      title: 'Brand Halo Sales (7D Attr.)',
      tooltipHeader: 'Brand Halo Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      toolTipText: 'Brand Halo Sales occuring within 7 day of click on ads',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 29,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    halo_sales_1_day: {
      title: 'Brand Halo Sales (1D Attr.)',
      tooltipHeader: 'Brand Halo Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['campaign', 'keyword', 'search', 'sku'],
      toolTipText: 'Brand Halo Sales occuring within 1 day of click on ads',
      campaign_keyOrder: 30,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    average_order_value: {
      title: 'AOV',
      tooltipHeader: 'Average Order Value',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['campaign', 'keyword', 'search', 'sku'],
      toolTipText:
        'Average dollar sales for each unit sold calculated as 14 day paid sales divided by 14 day orders',
      campaign_keyOrder: 31,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    units_14d: {
      title: 'Units',
      tooltipHeader: 'Units',
      ...streamConfig,
      toolTipText:
        'Total number of units sold from the campaign in the selected time period using 14 days attribution method',
      keyword_toolTipText:
        'Total number of units sold from the keyword in the selected time period using 14 days attribution method',
      search_toolTipText:
        'Total number of units sold from the search term in the selected time period using 14 days attribution method',
      sku_toolTipText:
        'Total number of units sold on the SKU in the selected time period using 14 days attribution method',
      type: 'NUMERIC',
      page: ['campaign', 'keyword', 'search', 'sku'],
      campaign_keyOrder: 32,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 11
    },
    attributedOrdersNewToBrand14d: {
      title: 'NTB Orders',
      tooltipHeader: 'Attributed New To Brand Orders',
      toolTipText:
        'Total New to Brand orders generated from the campaign in the selected time period using 14 days attribution',
      keyword_toolTipText:
        'Total New to Brand orders generated from the keyword in the selected time period using 14 days attribution',
      sku_toolTipText:
        'Total New to Brand orders generated from the SKU in the selected time period using 14 days attribution',
      type: 'NUMERIC',
      page: ['campaign', 'keyword', 'sku'],
      campaign_keyOrder: 33,
      keyword_keyOrder: 14,
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    attributedUnitsOrderedNewToBrand14d: {
      title: 'NTB Units',
      tooltipHeader: 'Attributed New To Brand Units',
      toolTipText:
        'Total New to Brand units sold from the campaign in the selected time period using 14 days attribution',
      keyword_toolTipText:
        'Total New to Brand units sold from the keyword in the selected time period using 14 days attribution',
      sku_toolTipText:
        'Total New to Brand units sold from the SKU in the selected time period using 14 days attribution',
      type: 'NUMERIC',
      page: ['campaign', 'keyword', 'sku'],
      campaign_keyOrder: 34,
      keyword_keyOrder: 14,
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    estimated_missed_impressions_upper7d: {
      title: 'Est. Missed Impressions',
      tooltipHeader: 'Estimated Missed Impressions',
      toolTipText:
        'Upper Estimated additional impressions the campaign might have generated had it adopt the recommended budget, based on previous website traffic and campaigns historical performance',
      type: 'NUMERIC',
      page: 'campaign',
      campaign_keyOrder: 35,
      ...pvpOnlyConfig
    },
    estimated_missed_clicks_upper7d: {
      title: 'Est. Missed Clicks',
      tooltipHeader: 'Estimated Missed Clicks',
      toolTipText:
        'Upper Estimated additional clicks the campaign might have generated had it adopt the recommended budget,  based on previous website traffic and campaigns historical performance',
      type: 'NUMERIC',
      page: 'campaign',
      campaign_keyOrder: 36,
      ...pvpOnlyConfig
    },
    estimated_missed_sales_upper7d: {
      title: 'Est. Missed Sales',
      tooltipHeader: 'Estimated Missed Sales',
      toolTipText:
        'Upper Estimated additional sales the campaign might have generated had it adopt the recommended budget,  based on previous website traffic and campaigns historical performance',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'campaign',
      campaign_keyOrder: 37,
      ...pvpOnlyConfig
    },
    bidplus: {
      title: 'Bid +',
      toolTipText: 'Whether Bid+ option is currently active',
      type: 'String',
      page: 'campaign',
      campaign_keyOrder: 38
    },
    acogs: {
      title: 'ACOGS',
      tooltipHeader: 'Advertising Cost of Goods Sold',
      toolTipText:
        'Calculated as attributed units ordered 14 days multiplied by cost of SKU to Amazon',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'sku'],
      campaign_keyOrder: 39,
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    start_date: {
      title: 'Start Date',
      toolTipText: 'Start date of the campaign',
      type: 'date',
      page: 'campaign',
      campaign_keyOrder: 40
    },
    end_date: {
      title: 'End Date',
      toolTipText: 'End date of the campaign',
      type: 'date',
      page: 'campaign',
      campaign_keyOrder: 41
    },
    keyword_text: {
      title: 'Keyword',
      toolTipText: 'Keyword',
      search_toolTipText: 'Keyword associated with the search term',
      type: 'String',
      pinned: 'left',
      page: ['keyword', 'search'],
      keyword_keyOrder: 2,
      search_keyOrder: 2
    },
    match_type: {
      title: 'Match type',
      toolTipText: 'Targeting type (exact, phrase, broad)',
      type: 'String',
      page: ['keyword'],
      pinned: 'left',
      keyword_keyOrder: 3
    },
    match_type_keyword: {
      title: 'Match type',
      toolTipText: 'Targeting type (exact, phrase, broad)',
      search_toolTipText:
        'Targeting type of the keyword (exact, phrase, broad)',
      type: 'String',
      page: ['search'],
      search_keyOrder: 3
    },
    asin_name: {
      title: 'SKU Title',
      toolTipText: 'ASIN name of the growth SKU',
      type: 'String',
      page: 'sku',
      isHiddenInTable: true,
      sku_keyOrder: 20
    },
    // 'asin_name': {
    //   title: 'ASIN Name',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'sku',
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 10,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 1,
    //   pinned: 'left'
    // },
    // 'latest_image_url': {
    //   title: 'ASIN Image Url',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'sku',
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 10,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 1,
    //   pinned: 'left'
    // },
    alertCount: {
      title: 'Recommended Action',
      toolTipText: 'Recommended Action',
      type: 'alerts',
      page: 'campaign',
      campaign_keyOrder: 50,
      pinned: 'left'
    },
    search_term: {
      title: 'Search Term',
      toolTipText: 'Search term used by customer',
      type: 'String',
      page: 'search',
      search_keyOrder: 1,
      pinned: 'left'
    },
    adgroup_name: {
      title: 'Adgroup Name',
      tooltipHeader: 'Advertising Group Name',
      toolTipText: 'Adgroup name of which the campaign is a part of',
      type: 'String',
      page: ['keyword', 'search', 'sku'],
      // keyword_keyOrder: 1,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    // 'automation': {
    //   title: 'Automation',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'common',
    //   campaign_keyOrder: 4,
    //   keyword_keyOrder: 5,
    //   search_keyOrder: 6,
    //   sku_keyOrder: 7
    // },
    // 'objective': {
    //   title: 'Objective',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'common',
    //   campaign_keyOrder: 5,
    //   keyword_keyOrder: 6,
    //   search_keyOrder: 7,
    //   sku_keyOrder: 8
    // },
    // 'recommendation': {
    //   title: 'Objective',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'common',
    //   campaign_keyOrder: 6,
    //   keyword_keyOrder: 7,
    //   search_keyOrder: 8,
    //   sku_keyOrder: 9
    // },
    ad_serving_status: {
      title: 'Status',
      toolTipText: 'Latest serving state of Ad',
      isServingStatus: true,
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20
    },
    keyword_serving_status_latest: {
      title: 'Status',
      toolTipText: 'Latest serving state of keyword',
      isServingStatus: true,
      type: 'String',
      page: 'keyword',
      keyword_keyOrder: 14
    },
    share_of_sponsered_brand_search: {
      title: 'Share of SB Ad space',
      tooltipHeader: 'Share of SB Advertising Space',
      toolTipText:
        'Number of client sponsored brands on page 1 divided by total number of sponsored brands on page1 in selected time period.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'keyword',
      keyword_keyOrder: 14
    },
    // 'paid_sales': {
    //   title: 'Paid sales',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   unit: 'CURRENCY',
    //   page: 'graph',
    //   campaign_keyOrder: 7,
    //   keyword_keyOrder: 8,
    //   search_keyOrder: 9,
    //   sku_keyOrder: 10
    // },
    paid_sales_1d: {
      title: 'Paid Sales (1D Attr.)',
      tooltipHeader: 'Paid Sales',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    paid_sales_7d: {
      title: 'Paid Sales (7D Attr.)',
      tooltipHeader: 'Paid Sales',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20,
      ...streamConfig
    },
    // 'paid_sales_30d': {
    //   title: 'Paid sales 30 day',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   unit: 'CURRENCY',
    //   page: ['keyword', 'search'],
    //   campaign_keyOrder: 7,
    //   keyword_keyOrder: 8,
    //   search_keyOrder: 9,
    //   sku_keyOrder: 10
    // },
    acos_1d: {
      title: 'ACoS (1D Attr.)',
      tooltipHeader: 'Advertising Cost Of Goods',
      toolTipText:
        'Advertised cost of sales generated from the keyword in the selected time period using 1 day attribution method calculated as Total spend divided by 1 days attributed sales',
      search_toolTipText:
        'Advertised cost of sales generated from the search term in the selected time period using 1 day attribution method calculated as Total spend divided by 1 days attributed sales',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    acos_7d: {
      title: 'ACoS (7D Attr.)',
      tooltipHeader: 'Advertising Cost Of Goods',
      toolTipText:
        'Advertised cost of sales generated from the keyword in the selected time period using 7 days attribution method calculated as Total spend divided by 7 days attributed sales',
      search_toolTipText:
        'Advertised cost of sales generated from the search term in the selected time period using 7 days attribution method calculated as Total spend divided by 7 days attributed sales',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    // 'acos_30d': {
    //   title: 'ACoS 30 days',
    // tooltipHeader: 'Advertising Cost Of Goods',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   unit: 'PERCENTAGE',
    //   page: ['keyword'],
    //   campaign_keyOrder: 8,
    //   keyword_keyOrder: 17,
    //   search_keyOrder: 17,
    //   sku_keyOrder: 11
    // },
    orders_1d: {
      title: 'Orders (1D Attr.)',
      tooltipHeader: 'Orders',
      toolTipText:
        'Total number of orders generated from the keyword in the selected time period using 1 day attribution method',
      search_toolTipText:
        'Total number of orders generated from the search term in the selected time period using 1 day attribution method',
      type: 'NUMERIC',
      page: ['search', 'keyword'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    orders_7d: {
      title: 'Orders (7D Attr.)',
      tooltipHeader: 'Orders',
      toolTipText:
        'Total number of orders generated from the keyword in the selected time period using 7 days attribution method',
      search_toolTipText:
        'Total number of orders generated from the search term in the selected time period using 7 days attribution method',
      type: 'NUMERIC',
      page: ['search', 'keyword'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    // 'orders_30d': {
    //   title: 'Orders 30 days',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   page: ['search', 'keyword'],
    //   campaign_keyOrder: 9,
    //   keyword_keyOrder: 10,
    //   search_keyOrder: 24,
    //   sku_keyOrder: 12
    // },
    units_1d: {
      ...streamConfig,
      title: 'Units (1D Attr.)',
      tooltipHeader: 'Units',
      toolTipText:
        'Total number of units sold from the keyword in the selected time period using 1 days attribution method',
      search_toolTipText:
        'Total number of units sold from the search term in the selected time period using 1 days attribution method',
      type: 'NUMERIC',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13
    },
    units_7d: {
      title: 'Units (7D Attr.)',
      tooltipHeader: 'Units',
      toolTipText:
        'Total number of units sold from the keyword in the selected time period using 7 days attribution method',
      search_toolTipText:
        'Total number of units sold from the search term in the selected time period using 7 days attribution method',
      type: 'NUMERIC',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    // 'units_30d': {
    //   title: 'Units 30 days',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   page: ['keyword', 'search'],
    //   campaign_keyOrder: 9,
    //   keyword_keyOrder: 24,
    //   search_keyOrder: 28,
    //   sku_keyOrder: 12
    // },
    conversions_1d: {
      title: 'CVR (1D Attr.)',
      tooltipHeader: 'Conversion Rate',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders using 1 day attribution method divided by clicks',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    conversions_7d: {
      title: 'CVR (7D Attr.)',
      tooltipHeader: 'Conversion Rate',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders using 7 day attribution method divided by clicks',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13
    },
    search_frequency_rank: {
      title: 'Amazon Frequency Rank',
      toolTipText:
        'Average of daily Amazon Search Frequency Rank over the time period for the Keyword',
      keyword_toolTipText:
        'Average of daily Amazon Search Frequency Rank over the time period for the Keyword',
      search_toolTipText:
        'Average of daily Amazon Search Frequency Rank over the time period for the Search term',
      type: 'NUMERIC',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      ...streamConfig
    },
    last_bid_change_date: {
      title: 'Date of Last Bid Change',
      toolTipText:
        'Date when the bid was last changed from Campaign Management, Campaign Optimizer or Budget Optimizer',
      keyword_toolTipText:
        'Date when the bid was last changed from Campaign Management, Campaign Optimizer or Budget Optimizer',
      type: 'date',
      page: ['keyword'],
      keyword_keyOrder: 14
    },
    // 'conversions_30d': {
    //   title: 'Conversion 30 days',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   unit: 'PERCENTAGE',
    //   page: ['keyword', 'search'],
    //   campaign_keyOrder: 9,
    //   keyword_keyOrder: 28,
    //   search_keyOrder: 32,
    //   sku_keyOrder: 12
    // },
    keyword_id: {
      title: 'Keyword ID',
      toolTipText: '',
      type: 'String',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    keyword_type: {
      title: 'Search Term type',
      toolTipText: 'Type of keyword (generic, branded, competitor)',
      search_toolTipText: 'Type of search term (generic, branded, competitor)',
      type: 'String',
      page: ['search', 'keyword'],
      keyword_keyOrder: 14,
      search_keyOrder: 13
    },
    // 'suggested_bid': {
    //   title: 'Suggested Bid',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   unit: 'CURRENCY',
    //   page: ['search'],
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 3,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    // 'days_since_last_bid_change': {
    //   title: 'Days since last bid change',
    //   toolTipText: '',
    //   type: 'NUMERIC',
    //   page: ['search'],
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 3,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    share_of_organic_search: {
      title: 'Share of Organic Search',
      toolTipText:
        'Number of client SKUs on page 1 ranked organically divided by total number of SKUs on page 1 ranked organically in selected time period',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['keyword', 'search'],
      keyword_keyOrder: 14,
      search_keyOrder: 13
    },
    // 'search_type': {
    //   title: 'Search type',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'search',
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 3,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    // 'search_term_type': {
    //   title: 'Keyword Type',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'search',
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 3,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    share_of_sponsered_search: {
      title: 'Share of SP Ad Space',
      tooltipHeader: 'Share of SP Advertising Space',
      toolTipText:
        'Number of client sponsored products on page 1 divided by total number of sponsored products on page 1 in in selected time period',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['search', 'keyword'],
      keyword_keyOrder: 14,
      search_keyOrder: 13
    },
    // 'profile_id': {
    //   title: 'Profile ID',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'sku',
    //   campaign_keyOrder: 2,
    //   keyword_keyOrder: 10,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    // 'campaign_id': {
    //   title: 'Campaign ID',
    //   toolTipText: '',
    //   type: 'String',
    //   page: 'graph',
    //   campaign_keyOrder: 12,
    //   keyword_keyOrder: 10,
    //   search_keyOrder: 4,
    //   sku_keyOrder: 5
    // },
    category: {
      title: 'Category',
      toolTipText:
        "ASIN's Category as per Catalog Listing Report in Amazon Vendor Central",
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20
    },
    subcategory: {
      title: 'Sub Category',
      toolTipText:
        "ASIN's Subcategory as per Catalog Listing Report in Amazon Vendor Central",
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20
    },
    brand: {
      title: 'Brand',
      toolTipText: "SKU's Brand as per Amazon Venor Central",
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20
    },
    inventory: {
      title: 'Inventory',
      toolTipText: 'Total inventory on hand for the SKU at Amazon warehouse',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    inventory_latest: {
      title: 'Inventory',
      toolTipText: 'Total inventory on hand for the SKU at Amazon warehouse',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20
    },
    amazon_revenue: {
      title: 'Amazon Revenue',
      toolTipText:
        'Revenue generated by Amazon on the SKU calculated as Units sold multiplied by Average selling price',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      sku_keyOrder: 20
    },
    pcogs: {
      title: 'PCOGS',
      tooltipHeader: 'Product Cost of Goods Sold',
      toolTipText:
        'Calculated as units sold multiplied by cost of SKU to Amazon',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    total_sales: {
      title: 'Total sales',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    iacos: {
      title: 'TACoS',
      tooltipHeader: 'Total Advertising Cost of Sales',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      unit: 'PERCENTAGE',
      ...pvpOnlyConfig,
      toolTipText:
        'Incremental ACoS defined as AMS spend divided by Total sales (both paid and organic)'
    },
    latest_offer_price: {
      title: 'Offer Price',
      toolTipText: 'Current offer price of the SKU',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      sku_keyOrder: 20
    },
    subscribe_and_save_price: {
      title: 'Subscribe and Save Price',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      toolTipText:
        'Subscribe and Save Price as per CIQ Market Intelligence data.',
      sku_keyOrder: 20
    },
    organic_sales: {
      title: 'Organic Sales',
      toolTipText:
        'Measure of organic sales of the ASIN calculated as (Total sales - Paid sales)',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      sku_keyOrder: 20
    },
    avg_selling_price: {
      title: 'ASP',
      tooltipHeader: 'Average Selling Price',
      toolTipText: 'Average selling price of the SKU in the given time period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      sku_keyOrder: 13
    },
    promotion_types: {
      title: 'Promo Types',
      tooltipHeader: 'Promotion Types',
      type: 'String',
      page: 'sku',
      toolTipText:
        ' Promotion Type active on the SKU as per latest CIQ Market Intellience data . VPC = Vendor Powered Coupon and DOTD = Deal of the Day',
      sku_keyOrder: 20
    },
    promotion_messages_daily: {
      title: 'Promo Messages',
      tooltipHeader: 'Promotion Messages',
      type: 'String',
      page: '',
      toolTipText: 'Value of the promotion as seen on the product detail page.'
    },
    promotion_messages: {
      title: 'Promo Messages',
      type: 'String',
      page: 'sku',
      toolTipText: 'Value of the promotion as seen on the product detail page.',
      sku_keyOrder: 20
    },
    active_promo: {
      title: 'Promotions',
      type: 'String',
      page: 'graph',
      toolTipText: 'Value of the promotion as seen on the product detail page.',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    category_1: {
      title: 'Category 1',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 16,
      toolTipText:
        'First Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. '
    },
    category_1_best_seller_rank: {
      title: 'Category 1 BSR',
      tooltipHeader: 'Category 1 Best Seller Rank',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 17,
      toolTipText:
        'Best Seller Category rank in first node as per most recent CIQ Market Intelligence Data. '
    },
    category_2: {
      title: 'Category 2',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Second Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. '
    },
    category_2_best_seller_rank: {
      title: 'Category 2 BSR',
      tooltipHeader: 'Category 2 Best Seller Rank',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Best Seller Category rank in second node as per most recent CIQ Market Intelligence Data. '
    },
    category_3: {
      title: 'Category 3',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Third Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. '
    },
    category_3_best_seller_rank: {
      title: 'Category 3 BSR',
      tooltipHeader: 'Category 3 Best Seller Rank',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Best Seller Category rank in third node as per most recent CIQ Market Intelligence Data. '
    },
    category_4: {
      title: 'Category 4',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Fourth Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. '
    },
    category_4_best_seller_rank: {
      title: 'Category 4 BSR',
      tooltipHeader: 'Category 4 Best Seller Rank',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'Best Seller Category rank in fourth node as per most recent CIQ Market Intelligence Data. '
    },
    avg_product_rating: {
      title: 'Avg Product Rating',
      tooltipHeader: 'Average Product Rating',
      type: 'NUMERIC',
      page: 'sku',
      toolTipText:
        'Current average rating on the product details page out of 5',
      sku_keyOrder: 20
    },
    total_review_count: {
      title: 'Total Review Count',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText:
        'No. of reviews on the product detail pages as per latest CIQ market intelligence data'
    },
    new_review_count: {
      title: 'New Review Count',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText: 'No. of new reviews received during selected date range. '
    },
    length_of_title: {
      title: 'Title Length',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText: 'Character length of Title on Product details page'
    },
    length_of_description: {
      title: 'Length of Description',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20,
      toolTipText: 'Character length of description on Product details page'
    },
    ordered_units: {
      title: 'Ordered Units',
      toolTipText:
        'Number of units (paid and organic) ordered by customers in the given time period',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20
    },
    sub_category_rank: {
      title: 'Subcategory (Sales Rank)',
      toolTipText: 'Sales rank in a subcategory',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 20
    },
    lbb_rate: {
      title: 'LBB Rate',
      tooltipHeader: 'Lost Buy Box Rate',
      toolTipText:
        'Lost buy box for the selected period is the percentage glance views where the ASIN had lost the buy box. LBB across the catalog is calculated as the percentage of total LBB glance views across all ASINs out of the total glance views.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'sku',
      sku_keyOrder: 15
    },
    rep_oos: {
      title: 'Rep OOS %',
      tooltipHeader: 'Replenishable Out of Stock Percentage',
      toolTipText:
        'Rep OOS% for the selected time period is the percentage of glance views where the ASINs were not available for sale. Rep OOS across the catalog is calculated as the percentage of total unavailable glance views across all ASINs out of the total glance views.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'sku',
      sku_keyOrder: 14
    },
    change_in_traffic_percentage: {
      title: 'Change in Traffic %',
      tooltipHeader: 'Change in Traffic Percentage',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    change_in_conversion_percentage: {
      title: 'Change in Conversion %',
      tooltipHeader: 'Change in Conversion Percentage',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 50,
      keyword_keyOrder: 14,
      search_keyOrder: 13,
      sku_keyOrder: 20
    },
    attributedSales14dSameSKU: {
      title: 'Ad SKU Paid Sales',
      tooltipHeader: 'Advertising SKU Paid Sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['sku'],
      toolTipText:
        'Aggregate value of attributed sales occurring within 14 days of click on ad where the purchased SKU was the same as the one advertised.',
      sku_keyOrder: 20,
      ...pvpOnlyConfig
    },
    creative_type: {
      title: 'Creative Type',
      toolTipText: 'Creative Type of the campaign',
      type: 'string',
      page: ['campaign', 'keyword', 'search'],
      campaign_keyOrder: 42
    }
  }
};
