import HttpService from '@/utils/services/http-service';
import { store } from '@/store/store';
import { filters } from '@ciq-dev/ciq-ui';
import { eventBus } from '@/utils/services/eventBus';
import { generateLocationFromUrlComponents } from '@/utils/helpers/utils.js';

export const fetchRecentDownloads = (isInitCall = false) => {
  store.dispatch('fetchRecentDownloads').then((testing) => {
    if (isInitCall === true) {
      startPollingForAllInitiatedDownloads();
    }
  });
};

export const startPollingForAllInitiatedDownloads = () => {
  const downloads = store.getters.getRecentDownloads;
  downloads?.data?.forEach((download) => {
    if (download.status === 'INITIATED' || download.status === 'IN-PROGRESS') {
      fetchDownloadStatusAtInterval(download.request_id);
    }
  });
};

export const fetchDownloadStatusAtInterval = (requestId) => {
  let intervalCall;
  const downloadInterval =
    filters.config_check('feature.report_download_polling_interval_sec') ?? 15;
  const downloadStatusFunction = (requestId) => {
    getIndividualDownloadStatus(requestId).then((data) => {
      updateExistingDownloadsWithNewItem(data?.item);
      const { status } = data?.item || {};
      const stopPollingStatus = ['SUCCESS', 'FAILED', 'TIMED-OUT'];
      if (stopPollingStatus?.includes(status)) {
        eventBus.$emit('downloadCompleted', status);
        clearInterval(intervalCall);
        intervalCall = null;
      }
    });
  };
  downloadStatusFunction(requestId);
  intervalCall = setInterval(() => {
    downloadStatusFunction(requestId);
  }, downloadInterval * 1000);
};

export const updateExistingDownloadsWithNewItem = (updatedDownload) => {
  let existingDownloads = store.getters.getRecentDownloads;
  existingDownloads.data = existingDownloads?.data?.map((download) => {
    if (download.request_id === updatedDownload.request_id) {
      return { ...download, ...updatedDownload };
    }
    return download;
  });
  store.dispatch('setRecentDownloads', existingDownloads);
};

export const getIndividualDownloadStatus = (requestId) => {
  return HttpService.post('ORCHESTRATOR_GET_DOWNLOAD_STATUS', {
    request_id: requestId
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log(error);
      return {};
    });
};

export const generateOrchestratorAPICallPayload = ({
  metadata,
  widgetRequestParams,
  downloadPayload,
  requestId,
  urlComponents,
  currentSelectedView,
  currentSelectedWidget,
  fileName
}) => {
  const generatedPayload = {
    request_id: requestId,
    folder_name: downloadPayload?.operations?.downloadRequest?.folderName,
    location: generateLocationFromUrlComponents(
      urlComponents,
      currentSelectedView,
      currentSelectedWidget
    ),
    request_payload: {
      service: metadata.downloadApiTemplate.service,
      payload: downloadPayload,
      endpoint: metadata.downloadApiTemplate.endPoint
    },
    page_name: widgetRequestParams[':page'],
    widget_name: widgetRequestParams[':widget'],
    file_name: fileName || 'Custom Report being generated'
  };
  return generatedPayload;
};
