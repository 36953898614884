// @ts-ignore
import utils from '@/utils/helpers/';
import {
  PageInfo,
  InitRequestParams,
  FilterSettings,
  WidgetNames,
  ModalBaseConfig,
  ActivityDataReturnType,
  ChartWidgetProps,
  ComponentRenderObject,
  TableWidgetProps
} from '@/pages/dashboard_service_campaign_management/types/index';
// @ts-ignore
import { commons } from '@/components/ams/campaigns/commons.js';
import {
  emailRequestConfig,
  getPinAndUnpinMessageForCalendarPin
  // @ts-ignore
} from '@/pages/dashboard_service_campaign_management/citrusAds/commonUtils.js';
import { areRowsSelected } from '@/pages/dashboard_service_campaign_management/utils/index';

export type UtilServiceForConfigConstructorObject = {
  retailer: string;
  listApiPageName: string;
  chartWidgetName?: string;
  tableWidgetName?: string;
  localStorageFilterKey?: string;
  localStorageFilterOrderKey?: string;
  tabName?: string;
};

type GetFilterSettingsParams = {
  // More properties can be added if something has to be changed from the config end
  backendCube: string;
  searchDimension: string;
  searchInputPlaceHolder?: string;
  bulkSearchStringConstants?: {
    dimensionNameMapping: string;
    findEntityInputPlaceholder: string;
    subTitle: string;
  };
  enableTaxonomyFilters?: boolean;
};

export class UtilServiceForConfig {
  retailer: string;
  listApiPageName: string;
  chartWidgetName?: string;
  tableWidgetName?: string;
  localStorageFilterKey?: string;
  localStorageFilterOrderKey?: string;
  tabName?: string;

  constructor({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  }: UtilServiceForConfigConstructorObject) {
    this.retailer = retailer;
    this.listApiPageName = listApiPageName;
    this.chartWidgetName = chartWidgetName;
    this.tableWidgetName = tableWidgetName;
    this.localStorageFilterKey = localStorageFilterKey;
    this.localStorageFilterOrderKey = localStorageFilterOrderKey;
    this.tabName = tabName;
  }

  getPageInfo(title?: string): PageInfo {
    return {
      name: this.listApiPageName,
      title: title || (this.tabName as string)
    };
  }

  getInitRequestParams(title?: string): InitRequestParams {
    return {
      global: {
        // name here is going to be the pagename
        name: this.listApiPageName,
        title: 'Campaigns',
        ':from': '2021-10-21',
        ':to': '2021-10-27',
        ':pvpFrom': '2021-10-14',
        ':pvpTo': '2021-10-20',
        ':page': this.listApiPageName,
        ':entityType': '#ALLOVER_AGGREGATE'
      },
      [this.chartWidgetName as string]: {
        ':widget': this.chartWidgetName,
        ':timeseriesRollupBy': 'DAY',
        ':dimensionNameValueList': [],
        timeseries: 'report_date'
      },
      [this.tableWidgetName as string]: {
        ':widget': this.tableWidgetName,
        ':orderByList': [],
        ':dimensionNameValueList': []
      }
    };
  }

  getFilterSettings({
    backendCube,
    searchDimension,
    searchInputPlaceHolder,
    bulkSearchStringConstants,
    enableTaxonomyFilters = false
  }: GetFilterSettingsParams): FilterSettings {
    return {
      emitEvent: `${this.retailer}FilterApply`,
      uiKey: 0,
      show: true,
      // to do update backend cube
      backendCube: backendCube,
      defaultDatePostRange: 'last30Days',
      enableSave: true,
      enableRemoveAll: true,
      allowCompare: true,
      externalFilters: {},
      listenerEvent: this.localStorageFilterKey as string,
      search: {
        enable: true,
        width: '320px',
        isFilterIcon: true,
        placeholder: searchInputPlaceHolder || `Search for a ${this.tabName}`,
        searchDimension: searchDimension
      },
      commonDateKey: `${this.retailer}-cm-common-date`,
      pinMessage: getPinAndUnpinMessageForCalendarPin().pinMessage,
      unpinMessage: getPinAndUnpinMessageForCalendarPin().unpinMessage,
      enableBetweenOperator: true,
      additionalRanges: commons.additionalDateRanges(),
      bulkSearchConfigs: {
        bulkSearchProps: {
          title: 'Bulk Search',
          findEntityInputPlaceholder:
            bulkSearchStringConstants?.findEntityInputPlaceholder,
          subTitle: bulkSearchStringConstants?.subTitle,
          maxPasteLimit: 1000
        },
        standardSearchConfigInsideBulkSearchProps: {
          emit: `${this.retailer}standardSearch`
        },
        dimensionNameForBulkSearch:
          bulkSearchStringConstants?.dimensionNameMapping,
        operator: 'IN',
        bulkSearchComponentRefName: `${this.retailer}${this.tabName}`,
        helpModalLocalStorageKey: `${this.retailer}${this.tabName}-bulk-search-help-dont-show`
      },
      ...(enableTaxonomyFilters ? utils.filterConfigs(this.retailer) : {})
    };
  }

  getWidgetNames(): WidgetNames {
    return {
      chart: this.chartWidgetName as string,
      table: this.tableWidgetName as string
    };
  }

  getModalBaseConfigForEmail(): ModalBaseConfig {
    return {
      modalConfig: emailRequestConfig,
      clickedOutsideModal(dashboardWrapperVueRef) {
        dashboardWrapperVueRef.showModal = false;
        // Here this refers to the modalConfig of the current object
        this.modalConfig = emailRequestConfig;
      }
    };
  }

  getGetActivityDataMethod(widget = 'campaign') {
    return (data: any, key: string): ActivityDataReturnType => ({
      params: { widget: widget, primaryKey: data[key] }
    });
  }

  getChartWidgetProps(context: any): ChartWidgetProps {
    return {
      headerOptions: [
        ...(!context.showPlotRowsChip
          ? []
          : [
              {
                ui_component: 'plotRowsChip',
                props: {
                  numberOfPlottedSelections: context.rowSelections.length || 0
                },
                events: {
                  removeChip: context.removeChip
                }
              }
            ])
      ],
      enableRollup: false,
      enableToggle: true,
      enableDownload: false,
      enableWaterfall: true,
      selectedMetricLimit: 3,
      removeDropdown: false,
      disableAddNewMetricButton: false,
      headerContainerGridStyles: {
        display: 'flex',
        'justify-content': 'flex-end',
        gap: '1.6rem',
        'align-items': 'center'
      }
    };
  }

  getTableWidgetProps(context: any): TableWidgetProps {
    const objectToReturn: TableWidgetProps = {
      headerOptions: [],
      headerContainerGridStyles: {},
      searchParams: {
        show: false
      },
      gridOptions: context.gridOptions,
      rowSelection: {
        mode: 'multiple',
        // this is called on selection change row + -
        onSelectionChanged: context.handleSelectionChange
      },
      rowHeight: 45
    };
    if (areRowsSelected(context.rowSelections)) {
      objectToReturn.headerContainerGridStyles = { padding: 0 };
    } else {
      objectToReturn.headerContainerGridStyles = {
        display: 'flex',
        'justify-content': 'flex-end',
        'align-items': 'center'
      };
    }
    return objectToReturn;
  }

  getEmailActionComponentRenderObjectArray(
    context: any
  ): ComponentRenderObject[] {
    if (!areRowsSelected(context.rowSelections)) {
      return [
        {
          ui_component: 'iconTextActions',
          props: {
            action: { title: 'Email', icon: 'email-subscription' },
            mode: 'default',
            applyPadding: false
          },
          events: {
            clicked: () => {
              context.showModal = true;
            }
          },
          order: 400
        }
      ];
    }
    return [];
  }

  // Actions related methods start here
  getPlotRowsActionObject() {
    return {
      icon: 'show-chart',
      title: 'PLOT ROWS',
      id: 'show-chart',
      removeSelectionState: false,
      handler(
        selectionLength: number,
        dropDownSelection: any,
        selections: any[]
      ) {
        // here {this} is bound to the cw_actionsBar component
        // @ts-ignore
        this.$emit('handlePlotRows', selections);
      }
    };
  }
  // Actions related methods end here
}

export const nativeRetailers = ['kroger'];
