<template>
  <section>
    <sticky-filter-panel
      v-if="showFilter"
      ref="sticky-filter-panel"
      :key="stickyFilterKey"
      :hide-export="true"
      :hide-clear-all-btn="true"
      :disable-view="true"
      :show-filter="showFilter"
      class="sticky-panel sticky-filter-panel category_page"
      :secondary-filter-data="secondaryFilterComputedData"
      :filter-local-storage-key="filterLocalStorageKey"
      :filter-emit="filterEmit"
      :options-list="viewList || []"
      :selected-option="selectedView"
      :filter-state="filterState"
      :default-date-post-range="defaultDateRange"
      :min-max-date="minMaxDate"
      :hide-view-btn="hideViewBtn"
      :hide-remove-btn="true"
      :hide-update-view="hideUpdateView"
      :enable-open-filter="enableOpenFilter"
      :filter-key="filterKey"
      :additional-ranges="additionalRanges"
      :date-mode="dateMode"
      :selection-mode="selectionMode"
      :enable-minimum-selection="true"
      :view-selection-enabled="false"
      @savedFilterApplied="handleSaveFilterApplied"
      @resetView="handleResetView"
      @onCreate="onCreate"
      @filterMounted="handleFilterMount"
    >
      <div
        slot="before-filter"
        class="u-display-flex u-flex-align-items-center u-height-36px"
      >
        <PageTitle :title="'Market Share Overview'" />
      </div>
      <div
        slot="preDatePicker"
        class="u-display-flex u-flex-align-items-center"
      >
        <rb-button
          text="View SKUs"
          :type="'filled'"
          class="u-spacing-mr-s"
          :click-fn="navigateTo.bind(this)"
        />
      </div>
      <div slot="footer">
        <settings
          :key="key"
          :types="settingsValue.viewBy"
          :placements="settingsValue.include3p"
          :keyword-type="settingsValue.displayShareIn"
          :selected-type="transformedComputedSelectedSettingsValue.viewBy"
          :selected-placement="
            transformedComputedSelectedSettingsValue.include3p
          "
          :selected-keyword-type="
            transformedComputedSelectedSettingsValue.displayShareIn
          "
          :customer-level-filters="customerLevelFilters"
          widget-name="settings"
          :hide-view-by="true"
          @apply="handleApply"
          @cancel="handleCancel"
        />
      </div>
    </sticky-filter-panel>
    <section
      v-if="pageFetched"
      class="widgetWrapper u-envelop-width"
    >
      <versionToggle
        v-if="isMsproEnabled"
        class="u-spacing-mv-m"
        :selected-tab="selectedTab"
      />
      <card-table-wrapper
        v-if="getGlobalSettings"
        :page="page"
        :global-settings="getGlobalSettings"
        :global-view-id="selectedView.globalViewId"
        :filter-data="filterData"
      />
      <table-widget
        v-if="tableDataService !== undefined"
        ref="table-widget"
        class="widget-3"
        :page="page"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :global-settings="getGlobalSettings"
        :data-service="tableDataService"
        :min-max-date="minMaxDate"
        :action-icons="shareSummaryWidgetActionIcons"
        :share-summary-widget="shareSummaryWidget"
      />
    </section>

    <section v-else>
      <pageLoader
        :page-fetched="pageFetched"
        :page-fetched-error="pageFetchedError"
      />
    </section>
  </section>
</template>

<script>
import pageLoader from '@/components/pages/insights/amazon/market_share_pro/atoms/pageLoader.vue';
import versionToggle from '@/components/pages/insights/amazon/market_share_pro/molecules/versionToggle.vue';
import cardTableWrapper from '@/components/pages/insights/amazon/market_share_pro/molecules/cardTableWrapper.vue';
import TableWidgetService from '@/components/pages/insights/amazon/market-share/service/table-widget-service.js';
import TableWidget from '@/components/pages/insights/amazon/market-share/templates/tableWidget.vue';
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import widgetMixin from '@/components/widgetsMixin.js';
import {
  msProConstants,
  overviewConstants,
  msProOverviewConstants,
  getFormattedCustomerLevelFilters,
  msProDisplayByOptions,
  get3pOptions,
  getAdditionalDateRanges,
  getHighestSelectableRange,
  computeFinalWhereClause,
  asinLevelV2ConfigEnabled,
  transform3pInNestedSettings,
  getDisplayByShareType,
  DefaultDisplayByOptions,
  setGlobalSettingsForMS,
  checkForDisplayShareInOptions,
  getGlobalToLocalInclude3p,
  getMetric,
  isMsproEnabled,
  getGlobalSettingsForMS,
  configurationSetting,
  localStorageFallback,
  constants,
  getSavedCustomerFilters,
  toggleVersion,
  getActionIcons,
  getMsProShareSummaryWidget,
  transformNestedSettingsInDropDown,
  transformNestedSettingsInUI,
  transformNestedSettingsValueForGlobalSettings
} from '@/components/pages/insights/amazon/market-share/utils';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import settings from '@/components/pages/insights/amazon/market-share/molecules/settings-popup.vue';
import transformer from '@/utils/services/data-transformer.js';
import Vue from 'vue';

export default {
  components: {
    cardTableWrapper,
    TableWidget,
    stickyFilterPanel,
    settings,
    versionToggle,
    pageLoader
  },
  mixins: [widgetMixin],
  data() {
    let { filterLocalStorageKey, namespace, pageName, diffDate } =
      overviewConstants;
    let page = msProOverviewConstants.page;
    return {
      pageFetchedError: false,
      pageFetched: false,
      isMsproEnabled: isMsproEnabled(),
      selectedTab: msProConstants.newVersion,
      namespace,
      page,
      diffDate,
      dateMode: constants.dateMode,
      showFilter: false,
      filterMounted: false,
      stickyFilterKey: 0,
      filterLocalStorageKey,
      filterEmit: overviewConstants.filterEmit,
      viewList: [],
      selectedView: {},
      defaultDateRange: 'last4Week',
      minMaxDate: {},
      getGlobalSettings: null,
      globalWhereClauseValue: {},
      hideViewBtn: true,
      hideUpdateView: true,
      enableOpenFilter: true,
      filterInstanceCreated: false,
      filterInstance: null,
      filterKey: 0,
      pageName,
      additionalRanges: {
        add: ['lastWeek', 'last4Week', 'last13Week'],
        overrideRange: ['lastWeek', 'last4Week', 'last13Week'],
        order: {
          lastWeek: 3,
          last4Week: 4,
          last13Week: 5
        }
      },
      pageWiseMinMaxKey: 'marketshare-overview',
      selectionMode: 'multi-week',
      key: 0,
      computedSelectedSettingsValue: {
        viewBy: { entityType: 'category', label: 'Category' },
        displayShareIn: DefaultDisplayByOptions(),
        include3p: get3pOptions()[0]
      },
      selectedCustomerFilters: {},
      shareSummaryWidgetActionIcons: getActionIcons(
        constants.shareSummaryDownloadTippyTitle
      ),
      shareSummaryWidget: getMsProShareSummaryWidget('overviewPage'),
      tableDataService: undefined
    };
  },
  computed: {
    transformedComputedSelectedSettingsValue() {
      const nestedSettings = cloneDeep(this.computedSelectedSettingsValue);
      // local storage has static currency value i.e Dollars, that is transformed so that settings component has dynamic currency value
      const updatedNestedSettings = transformNestedSettingsInUI(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    customerLevelFilters() {
      return getFormattedCustomerLevelFilters(
        this.filterData,
        this.selectedCustomerFilters
      );
    },
    settingsValue() {
      const nestedSettings = {
        viewBy: [{ entityType: 'category', label: 'Category' }],
        displayShareIn: cloneDeep(msProDisplayByOptions),
        include3p: get3pOptions(getDisplayByShareType())
      };
      const updatedNestedSettings = transformNestedSettingsInDropDown(
        nestedSettings,
        Vue
      );
      return updatedNestedSettings;
    },
    secondaryFilterComputedData() {
      const category_hierarchy = {
        multi: true,
        v1: true,
        key: 'category_hierarchy',
        label: 'Category',
        values: []
      };
      Object.keys(this.filterData.category || {}).forEach((categoryName) => {
        category_hierarchy.values.push({ title: categoryName, enable: true });
      });
      return { category_hierarchy };
    },
    filterState() {
      return {
        getter: `${this.namespace}getGlobalFilters`,
        setter: `${this.namespace}setGlobalFilters`
      };
    },
    v2() {
      return asinLevelV2ConfigEnabled();
    },
    globalToLocal() {
      return {
        viewBy: {
          label: {
            Manufacturers: 'Category',
            Brands: 'Category'
          },
          entityType: {
            manufacturer: 'category',
            brand: 'category',
            asin: 'category'
          }
        },
        displayShareIn: {
          metricsList: getMetric('overview')
        },
        include3p: getGlobalToLocalInclude3p()
      };
    }
  },
  watch: {
    '$route.params.id': {
      handler: 'handleViewBasedonParams',
      deep: true,
      immediate: true
    }
  },
  created() {
    transform3pInNestedSettings();
    this.initializePage();
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: 'marketshareSkus' });
    },
    updateToggleVersion(updateVal) {
      toggleVersion(updateVal);
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    handleCancel() {
      this.key++;
    },
    handleFilterMount(instance) {
      this.filterMounted = true;
      eventBus.$on(this.filterEmit, async (data) => {
        // date range is changed we catch the event here
        const filterValue =
          localStorage.getItem(this.filterLocalStorageKey) || '';
        let storage = JSON.parse(filterValue);
        for (const key in storage) {
          if (key !== constants.CATEGORY_HIERARCHY) {
            setGlobalSettingsForMS(key, storage[key]);
          }
        }
        if (data?.category_hierarchy) {
          setGlobalSettingsForMS(
            constants.OVERVIEW_CATEGORY_HIERARCHY,
            data?.category_hierarchy
          );
          // Only if category has changed then make a request for fetching differential dates for category
          await this.handleDateChangeForCategoryUpdate(data.category_hierarchy);
        }

        if (!data.name) {
          this.$store.dispatch('setInitialSelections', {});
        }
        this.refreshWidgets();
      });
    },
    handleViewBasedonParams(params) {
      const viewList = this.viewList || [];
      let selectedView =
        viewList.find((view) => parseInt(params) === view.globalViewId) || null;

      if (!selectedView && viewList.length > 0) {
        selectedView = viewList[0];
        this.$router.push({
          name: this.pageName,
          params: { id: selectedView.globalViewId }
        });
      }

      if (selectedView) {
        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };

        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList(selectedView);
      }
    },
    globalWhereClause() {
      const globalFilters =
        this.$store.getters[this.namespace + 'getGlobalFilters'];
      const { date_range, dimensionNameValueList } = cloneDeep(globalFilters);
      const where = {
        date: {
          from: date_range?.from,
          to: date_range?.to,
          name: date_range?.name,
          page_wise_min_max_key: this.pageWiseMinMaxKey
        },
        pvpDate: {
          from: date_range?.compare_from,
          to: date_range?.compare_to,
          compare_name: date_range?.compare_name
        },
        dimensionNameValueList: dimensionNameValueList || []
      };

      where.dimensionNameValueList.forEach((element) => {
        if (element.dimensionName === 'category_hierarchy') {
          element.dimensionValue = element.dimensionValue.toLowerCase() + '~';
          element.operator = 'STARTSWITH';
        }
      });

      return where;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    resetFilters() {
      this.filterData = [];
      this.secondaryFilterData = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      this.$nextTick(() => {
        this.showFilter = true;
      });
    },
    handleResetView() {
      this.reinitView();
    },
    transformFilterFromAPI(...args) {
      return transformer.transformFilterFromAPI(...args);
    },
    async getGlobalFilters(cube, urlFactoryId, page, where) {
      this.resetFilters();
      await this.fetchFilters(
        cube,
        urlFactoryId,
        page,
        where,
        undefined,
        undefined,
        true
      );
    },
    handleApply(payload) {
      // when settings is changed we catch the event here
      // Payload has dynamic currency value, that is transformed so that local storage has static currency value i.e Dollars
      const nestedSettings =
        transformNestedSettingsValueForGlobalSettings(payload);
      this.selectedCustomerFilters = nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(
        'customerLevelFilters',
        nestedSettings.customerLevelFilters
      );
      delete nestedSettings.customerLevelFilters;
      setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
      this.$nextTick(() => {
        this.computedSelectedSettingsValue = payload;
        this.refreshWidgets();
      });
    },
    async refreshWidgets() {
      this.globalWhereClauseValue = cloneDeep(this.globalWhereClause());
      this.tableDataService.dateApiMaxDate = this.minMaxDate?.maxDate;
      const whereClause = computeFinalWhereClause(
        this.globalWhereClauseValue,
        [],
        this.selectedCustomerFilters
      );
      this.getGlobalSettings = {
        where: whereClause,
        settingValue: configurationSetting(
          getGlobalSettingsForMS('nestedSettings', undefined, this.v2),
          this.globalToLocal
        )
      };
    },
    sortViewList() {
      const defaultViewGroupName = 'Default Views';
      const defaultViewTemp = this.viewList.filter(
        (item) => item.groupName === defaultViewGroupName
      );
      this.viewList = this.viewList.filter(
        (item) => item.groupName !== defaultViewGroupName
      );
      this.viewList = this.viewList.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      this.viewList.unshift(...defaultViewTemp);
    },
    async initializePage() {
      this.showFilter = false;
      const requestBody = { page: this.page };
      try {
        const globalViewData = await HttpService.post(
          'DASHBOARD_SERVICE',
          requestBody,
          {
            append: '/global/view/list'
          }
        );
        this.viewList = globalViewData.data.globalViews.map((item) => {
          item.currentUser = false;
          if (item.createdBy === window.user.email) {
            item.currentUser = true;
          }
          item.formattedAt = moment(item.createdAt).format('LLL');
          if (item.groupName !== 'Default Views') {
            item.edit = false;
          }
          return item;
        });

        this.sortViewList();

        let globalViewId = '';
        if (this.$route?.params?.id) {
          globalViewId = this.$route?.params?.id;
          this.handleViewBasedonParams(globalViewId);
        } else {
          globalViewId =
            globalViewData.data?.userUnsavedGlobalView?.globalViewId;
          if (globalViewId) {
            this.$router.push({
              name: this.pageName,
              params: { id: globalViewId }
            });
          } else {
            this.$router.push({
              name: this.pageName,
              params: { id: this.viewList[0]?.globalViewId }
            });
          }
        }
      } catch (error) {
        this.$snackbar.open(msProConstants.errorMessage);
      }
    },
    async fetchPageList(viewDetails) {
      this.selectedView = viewDetails;
      try {
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await HttpService.post(
          'DASHBOARD_SERVICE',
          { page: this.page, globalViewId: viewDetails.globalViewId },
          { append: '/page/widgets/list' }
        );
        this.setFilterData(response);
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    async handleFilterData(filterData) {
      localStorageFallback(this.filterData?.category, 'overviewPage');
      const storageValues = this.transformFilterFromAPI(filterData || []);
      const globalFilter = getGlobalSettingsForMS();
      const defaultCategoryList =
        this.secondaryFilterComputedData.category_hierarchy?.values.map(
          (categoryList) => categoryList.title
        );
      const category =
        globalFilter?.overview_category_hierarchy?.filter((item) =>
          defaultCategoryList.includes(item)
        ) || defaultCategoryList;

      const dateRange = await this.updatedDateRange(
        category.map((cat) => cat.toLowerCase() + '~')
      );
      const { updateFilter, highestSelectableRange } =
        this.checkForCategoryUpdateDateRange(dateRange);

      this.previousPage = globalFilter?.[constants.NAVIGATE_FROM];
      setGlobalSettingsForMS(constants.NAVIGATE_FROM, {}, true);
      this.selectedCustomerFilters =
        getSavedCustomerFilters(this.filterData) || {};
      const dateRangeHighestSelectable = {
        name: highestSelectableRange,
        compare: true,
        compare_name: null
      };
      checkForDisplayShareInOptions();

      if (Object.keys(globalFilter).length) {
        this.updateSettingsFilterData();
        storageValues.values.date_range = updateFilter
          ? dateRangeHighestSelectable
          : globalFilter?.date_range;
        this.computedSelectedSettingsValue = configurationSetting(
          getGlobalSettingsForMS('nestedSettings', undefined, false),
          this.globalToLocal
        );
        setGlobalSettingsForMS(null, {
          ...storageValues.values
        });
      } else {
        storageValues.values.date_range = dateRangeHighestSelectable;
        const nestedSettings = { ...this.computedSelectedSettingsValue };
        setGlobalSettingsForMS(null, {
          ...storageValues.values,
          nestedSettings
        });
      }

      storageValues.values.category_hierarchy = category;
      storageValues.order = ['category_hierarchy'];
      localStorage.setItem(
        this.filterLocalStorageKey,
        JSON.stringify(storageValues.values)
      );
      localStorage.setItem(
        `${this.filterLocalStorageKey}-order`,
        JSON.stringify(storageValues.order)
      );
      this.showFilter = true;
      this.$nextTick(() => {});
    },
    updateSettingsFilterData() {
      if (isMsproEnabled()) {
        const globalSettingsForMS = getGlobalSettingsForMS();
        const shareType =
          globalSettingsForMS?.nestedSettings?.displayShareIn?.shareType;
        if (shareType !== 'Marketshare') {
          setGlobalSettingsForMS(
            constants.NESTED_SETTINGS,
            msProConstants.nestedSettings
          );
        }
      }
    },
    setFilterData(response) {
      const reqbody = {
        service: 'DASHBOARD_SERVICE',
        endPoint: '/marketshare/filters',
        request: {
          page: 'MARKETSHARE-EXECUTIVE'
        }
      };
      if (response.status === 200) {
        this.fetchFilterV3(
          {
            ...reqbody,
            endPoint: '/marketshare/filters' + '?v2=' + this.v2
          },
          (data) => data
        ).finally(async () => {
          await this.handleFilterData(
            response.data.filters?.selectedFilter?.filter
          );
          this.initializeFilter();
          this.initializeTableWidgetService();
          this.pageFetched = true;
        });
      }
    },
    initializeFilter() {
      setTimeout(() => {
        document.querySelector('.filter-list__item').click();
      }, 0);
      setTimeout(() => {
        document.querySelector('body').click();
        document.querySelector('.rb-filter .icon-cross').style.display = 'none';
        this.refreshWidgets();
      }, 0);
    },
    initializeTableWidgetService() {
      const widgetName = msProOverviewConstants.shareSummaryWidget;
      this.tableDataService = new TableWidgetService(
        this.page,
        widgetName,
        this.selectedView.pageId,
        this.selectedView.globalViewId
      );
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    /**
     * @description utility function to fetch updated dates when categroies change
     * @param {array} categoryList list of categories to fetch dates for
     */
    async handleDateChangeForCategoryUpdate(categoryList) {
      this.pageFetched = false;
      this.showFilter = false;
      let dateRange = await this.updatedDateRange(
        categoryList.map((cat) => cat.toLowerCase() + '~')
      );
      const { updateFilter, highestSelectableRange } =
        this.checkForCategoryUpdateDateRange(dateRange);
      let date_range = {
        name: highestSelectableRange,
        compare: true,
        compare_name: null
      };
      /**
       * if updateFilter is true that means there's mismatch between selected dates and available dates
       */
      if (updateFilter) {
        const storage = JSON.parse(
          localStorage.getItem(this.filterLocalStorageKey)
        );
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify({
            ...storage,
            date_range
          })
        );
      }
      await this.updateFilters();
      this.$nextTick(() => {
        this.pageFetched = true;
        this.showFilter = true;
      });
    },
    /**
     * @description utility function to check currently selected dates are correct and data is available in backend
     * @param {object} dateRange dateRange received from backend
     */
    checkForCategoryUpdateDateRange(dateRange) {
      const { minDate, maxDate } = dateRange.data.response;
      const backendStartDate = moment(minDate);
      const backendEndDate = moment(maxDate);

      this.minMaxDate.minDate = backendStartDate.format('MM/DD/YYYY');
      this.minMaxDate.maxDate = backendEndDate.format('MM/DD/YYYY');
      this.minMaxDate.rawMinDate = backendStartDate;
      this.minMaxDate.rawMaxDate = backendEndDate;

      this.additionalRanges = getAdditionalDateRanges(
        backendStartDate,
        backendEndDate
      );
      const { add: additionalRanges, disabledRange } = this.additionalRanges;
      const availableRanges = additionalRanges.filter(
        (range) => !disabledRange.includes(range)
      );
      const highestSelectableRange = getHighestSelectableRange(
        this.additionalRanges.order,
        availableRanges
      );

      const globalFilter = getGlobalSettingsForMS();

      /**
       * if localstorage is cleared or for some reason we don't have dateRanges stored in localStorage
       * we will update the dateRanges to highestSelectableRange
       * ------- ( OR ) -----
       * if currently selected start date or end date in filter is not between available date ranges
       * we will update the dateRanges to highestSelectableRange
       */
      if (
        !globalFilter?.date_range ||
        !this.isValidDateRange(
          globalFilter.date_range,
          backendStartDate,
          backendEndDate
        )
      ) {
        return { updateFilter: true, highestSelectableRange };
      }

      return { updateFilter: false, highestSelectableRange };
    },
    isValidDateRange(dateRange, startDate, endDate) {
      const currentSelectedStartDate = moment(dateRange.from);
      const currentSelectedEndDate = moment(dateRange.to);

      return (
        (!currentSelectedStartDate ||
          currentSelectedStartDate.isBetween(
            startDate,
            endDate,
            undefined,
            '[]'
          )) &&
        (!currentSelectedEndDate ||
          currentSelectedEndDate.isBetween(startDate, endDate, undefined, '[]'))
      );
    },
    /**
     * @description utility function to fetch updated dates for selected categories
     * @param {array} categorySelection list of selected categories on overview page
     */
    async updatedDateRange(categorySelection = []) {
      if (categorySelection.length === 0) {
        return;
      }
      try {
        /**
         * here overview=true is needed to let backend know this request is for overview page
         */
        return await HttpService.post(this.diffDate, categorySelection, {
          append: '?overview=true',
          params: {
            v2: this.v2
          }
        });
      } catch (err) {
        this.showErrorPageLevel(err);
      }
    },
    showErrorPageLevel(err) {
      this.pageFetched = false;
      this.pageFetchedError = false;
      console.error('err', err);
      this.$snackbar.open(msProConstants.errorMessage);
    },
    async updateFilters() {
      return new Promise((resolve) => {
        this.reinitView();

        // Hack to show the filters
        setTimeout(() => {
          document.querySelector('.filter-list__item').click();
        }, 0);
        setTimeout(() => {
          document.querySelector('body').click();
          document.querySelector('.rb-filter .icon-cross').style.display =
            'none';
        }, 0);

        // Once the filter component is reinitialized, resolve the method
        const interval = setInterval(() => {
          if (this.filterInstanceCreated) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }
  }
};
</script>
<style lang="css" scoped>
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
.view-port {
  height: calc(100vh - 72px);
}
</style>
