import { eventBus } from '@/utils/services/eventBus';
import utils from '@/utils/helpers/';
import moment from 'moment';
import { calculateTimeString } from '@/utils/helpers/formatter.js';
import Vue from 'vue';
import {
  createBacthPayload,
  transformToBatchValues,
  tranformNewBatchPayloadForSelectAll
} from '@/components/ams/campaigns/batchPayloadCreator.js';
import { getterMapping } from '@/components/ams/campaigns/constants.js';

let commons = {};
let parent = commons;
commons.debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const editBulkActionsPayload = (
  apiHandler,
  context,
  payload,
  isBidChange,
  selections,
  isBulkActionEnabled
) => {
  if (
    isBulkActionEnabled &&
    context.$store.getters[
      getterMapping[payload.actionType || payload[0].actionType]
    ]
  ) {
    payload = tranformNewBatchPayloadForSelectAll(
      context,
      payload,
      isBidChange,
      selections
    );
    apiHandler = 'bulkAmsActionsManagerBatchAction';
    return { apiHandler, payload };
  }
};

const bulkActionCheck = (context, payload) => {
  const isBulkActionEnabled = Vue.options.filters.config_check(
    'feature.ams_bulk_select_all'
  )?.enable;
  const isbatchAction = Vue.options.filters.config_check(
    'feature.ams_bulk_action'
  )?.enable;
  const isBulkEnabled =
    isBulkActionEnabled &&
    context.$store.getters[
      getterMapping[
        payload?.actionType ||
          payload[0]?.actionType ||
          payload?.newPayload[0]?.actionType
      ]
    ];

  const bulkEnableChecker = isbatchAction && !isBulkEnabled;

  return { isBulkActionEnabled, bulkEnableChecker, isBulkEnabled };
};

const isMetadataChangeAction = (
  context,
  selections,
  payload,
  otherParams,
  apiVersion,
  isBidChange,
  apiHandler
) => {
  const { isBulkActionEnabled, bulkEnableChecker, isBulkEnabled } =
    bulkActionCheck(context, payload);
  if (bulkEnableChecker) {
    let intermediatePayload = null;
    if (Array.isArray(payload)) {
      intermediatePayload = transformToBatchValues(payload, isBidChange);
    } else {
      intermediatePayload = payload;
    }
    if (intermediatePayload?.isRecursive) {
      for (const pl of intermediatePayload.newPayload) {
        const newPayload = {
          isRecursive: false,
          newPayload: pl
        };
        commons.bidChangeApiAction.call(
          context,
          selections,
          newPayload,
          otherParams,
          apiVersion,
          isBidChange
        );
      }
      return {
        isRecursive: true
      };
    }
    payload = createBacthPayload(context, intermediatePayload.newPayload);

    apiHandler = 'updateBulkAmsActionsManagerBatchAction';
  } else if (payload?.length < 20) {
    apiHandler = 'amsWorkbenchMetadataUpdateApi';
  } else {
    apiHandler = 'updateBulkAmsActionsManager';
  }
  payload = isBulkEnabled
    ? editBulkActionsPayload(
        apiHandler,
        context,
        payload,
        isBidChange,
        selections,
        isBulkActionEnabled
      ).payload
    : payload;
  apiHandler = isBulkEnabled
    ? editBulkActionsPayload(
        apiHandler,
        context,
        payload,
        isBidChange,
        selections,
        isBulkActionEnabled
      ).apiHandler
    : apiHandler;
  return {
    apiHandler,
    payload,
    isRecursive: false
  };
};

commons.editAllowedActionsKeys = (context) => {
  context.allowedActions.forEach((item) => {
    if (item.id === 'add-tag') {
      item.title = 'ADD CAMPAIGN LIST';
      item.icon = 'campaign_management';
    }
    if (item.id === 'add-keywords') {
      item.title = 'ADD TARGETS';
    }
    if (item.id === 'add-negative') {
      item.title = 'ADD NEGATIVE TARGETS';
    }
  });
};

commons.scrollPage = function (element, yPosition = 0) {
  let elm = document.getElementsByClassName('detail-panel')[0];
  elm?.scrollTo(0, yPosition) ?? window.scrollTo(0, yPosition);
};

commons.getBidValue = function (params, oldVal) {
  let delta = parseFloat(params.value.value);
  if (params.value.selectionType === 'percent') {
    delta = oldVal * (delta / 100);
  }
  if (params.value.type === 'increaseBids') {
    return parseFloat((oldVal + delta).toFixed(2));
  } else {
    return parseFloat((oldVal - delta).toFixed(2));
  }
};

function returnSnackbarMsg(key, otherParams, parent, context, time = 10000) {
  if (key) {
    parent.displaySnackbar.call(context, otherParams.errorMessage);
  } else {
    otherParams.successFn.call(context);
    parent.displaySnackbar.call(context, otherParams.successMessage, time);
  }
}

function getBulkBannerStateAndText(context, actionType, otherParams) {
  const isKeywords = actionType === 'keywordMetadataChange';
  const totalRows = isKeywords
    ? context.$store.getters.getWorkbenchKeywordsData?.keywordTable?.totalRows
    : context.$store.getters.getWorkbenchCampaignsData?.campaignsTable
        ?.totalRows;
  if (totalRows && context.$store.getters[getterMapping[actionType]]) {
    const timeString = calculateTimeString(totalRows, 30, 1000);
    otherParams.successMessage = `Actions submitted. Please check your mail in ${timeString} for the status.`;
  }
}

commons.bidChangeApiAction = function (
  selections,
  payload,
  otherParams,
  apiVersion,
  isBidChange = true
) {
  if (otherParams.loader !== undefined) {
    this[otherParams.loader] = true;
  }
  let apiHandler = 'amsWorkbenchActionApi';
  if (apiVersion === 'actionlog') {
    apiHandler = 'amsWorkbenchActionLogApi';
  }
  if (apiVersion === 'bulkaction') {
    apiHandler = 'updateBulkAmsActionsManager';
  }

  if (apiVersion === 'metadataChange') {
    const newPayload = isMetadataChangeAction(
      this,
      selections,
      payload,
      otherParams,
      apiVersion,
      isBidChange,
      apiHandler
    );
    if (newPayload.isRecursive) return;
    payload = newPayload.payload;
    apiHandler = newPayload.apiHandler;
  }
  let promise = this.$store.dispatch(apiHandler, payload);
  promise
    .then((dataRecieved) => {
      let response = dataRecieved.data;
      getBulkBannerStateAndText(this, payload?.actionType, otherParams);
      const responseHasSuccess = response.hasOwnProperty('success');
      responseHasSuccess
        ? returnSnackbarMsg(!response.success, otherParams, parent, this, 12000)
        : returnSnackbarMsg(
            dataRecieved.status !== 200 && apiVersion === 'metadataChange',
            otherParams,
            parent,
            this
          );
    })
    .catch((error) => {
      console.error(error);
      parent.displaySnackbar.call(this, otherParams.errorMessage);
    })
    .finally(() => {
      if (otherParams.loader !== undefined) {
        otherParams.loader = false;
      }

      if (
        apiVersion === 'metadataChange' &&
        utils.internalUserCheck(window.user)
      ) {
        eventBus.$emit('metadataUpdate');
      }

      otherParams.finallyFn();
    });
};

commons.addDefaultColCheck = (cols, defaultColsList) => {
  const colsWithDefaultCheck = cols.map((col) => {
    if (defaultColsList.indexOf(col.field) === -1) {
      col.headerComponentParams.unselected = true;
    }
    return col;
  });
  return colsWithDefaultCheck;
};
commons.overRightAllowedActions = (context) => {
  if (
    context.allowedActions &&
    context?.allowedActions?.length &&
    context?.allowedActions?.length > 0
  ) {
    context.allowedActions.forEach((allowedAction) => {
      if (allowedAction.id === 'adjust-bids') {
        allowedAction.title = 'BUDGET';
      }
    });
  }
};

commons.appendTableDefinitionToConfig = function () {
  let vueRef = this;
  let config = this.config.getColumnDefinition(vueRef);
  let widget2 = this.config.widgets.widget2;
  widget2.body.columnDefs = config;
  widget2.body.download.columnMap = config;
};

commons.displaySnackbar = function (response, time) {
  let timer = time ? 10000 : time;
  let content = '<div class="u-spacing-pv-xs">' + response + '</div>';
  this.$snackbar.open({
    message: content,
    duration: timer,
    buttonColor: '#f5d908',
    actionText: ' '
  });
};

commons.closeActionsWorkflow = function () {
  this.showActionsBar = false;
  this.selections = [];
  this.deselectAllRows();
};

commons.checkIfActionIsValid = function (
  actionId,
  allowedActions,
  selectedNodes,
  snackbar,
  bulkActionsAllowed
) {
  let { removeArchived, removedSDCampaigns } = removeSDCampaignsFromSelection({
    allowedActions,
    actionId,
    selectedNodes,
    bulkActionsAllowed
  });
  if ((removeArchived || removedSDCampaigns) && !bulkActionsAllowed) {
    let snackbarMsgStarter = '';
    if (removeArchived) {
      snackbarMsgStarter = 'Archived';
    }
    if (removedSDCampaigns) {
      snackbarMsgStarter = 'SD';
    }
    if (removedSDCampaigns && removeArchived) {
      snackbarMsgStarter = 'Archived & SD';
    }
    snackbar.open({
      message: `${snackbarMsgStarter} campaigns are removed from selection ${
        removedSDCampaigns ? '(Action Unavailable)' : ''
      }`,
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
  }
};
function removeSDCampaignsFromSelection({
  allowedActions,
  actionId,
  selectedNodes,
  bulkActionsAllowed
}) {
  let removeArchived = false;
  let removedSDCampaigns = false;
  if (allowedActions.includes(actionId) && !bulkActionsAllowed) {
    selectedNodes.forEach((node) => {
      if (
        node?.data &&
        (node?.data?.status || node?.data?.campaign_status) &&
        (node?.data?.status === 'archived' ||
          node?.data?.campaign_status === 'archived')
      ) {
        removeArchived = true;
        node.setSelected(false);
      }
    });
  }
  return { removeArchived, removedSDCampaigns, selectedNodes };
}
commons.emailStatusSnackBar = function (emailStatus, that) {
  const message =
    emailStatus.data && emailStatus.data.success && emailStatus.status === 202
      ? 'Email request successfully sent, please check your email after some time.'
      : 'Request failed, please try again!';
  that.$snackbar.open({
    message: message,
    duration: 6000,
    buttonColor: '#f5d908',
    actionText: ''
  });
};

commons.getDownloadApiRequest = function (columnMap, API, fileSuffix) {
  const APIConfig = Object.assign({}, API);
  const object = {};
  const arr = new Set([
    ...(APIConfig.bundleCubeExecutionRequest
      ? Object.values(APIConfig.bundleCubeExecutionRequest).reduce(
          (acc, value) => {
            acc = [
              ...acc,
              ...value.measuresList,
              ...value.groupByDimensionsList
            ];
            return acc;
          },
          []
        )
      : []),
    ...APIConfig.measuresList,
    ...APIConfig.groupByDimensionsList
  ]);
  columnMap.forEach((column) => {
    arr.forEach((elm) => {
      if (elm === column.field) {
        object[column.field] = column.headerName;
      }
    });
  });
  APIConfig.fileSuffix = fileSuffix || null;
  APIConfig.customAPIDecisionVars = {
    downloadRequest: { columnLabels: object }
  };
  delete APIConfig.page;
  delete APIConfig.limit;
  return APIConfig;
};

commons.sendEmailRequest = function (columnMap, API, that, fileSuffix) {
  const APIConfig = commons.getDownloadApiRequest(columnMap, API, fileSuffix);
  that.$store.dispatch('sendDownloadDataAsEmail', APIConfig);
};
commons.invalidateSDActions = (rows, allowedActions, bulkActionsAllowed) => {
  let isSDCampaign = false;
  for (const row of rows) {
    if (row?.data?.campaign_type === 'SD') {
      isSDCampaign = true;
      break;
    }
  }
  for (const action of allowedActions) {
    const allowedActions = bulkActionsAllowed
      ? [
          'add-keywords',
          'add-asins',
          'add-negative',
          'show-chart',
          'add-tag',
          'day-part'
        ]
      : ['show-chart', 'add-tag', 'adjust-bids', 'edit-status'];
    if (!allowedActions.includes(action.id)) {
      action.disabled = isSDCampaign;
    }
  }
  return isSDCampaign;
};

commons.modifyTippyBasedOnActionState = (actions, actionTippys, show) => {
  actions = actions.map((item) => {
    if (item && show && actionTippys[item?.id]) {
      item.tippy = actionTippys[item.id];
    } else if (item.tippy && !show) {
      delete item.tippy;
    }
    return item;
  });
  return actions;
};

commons.validateForArchivedActions = function (actionsArray, tableSelections) {
  const tableSelectionsLengthOriginal = tableSelections.length;
  tableSelections = tableSelections.filter((obj) => {
    return (
      obj?.data &&
      (obj?.data?.status !== 'archived' ||
        obj?.data?.campaign_status !== 'archived')
    );
  });
  if (tableSelectionsLengthOriginal > tableSelections.length) {
    const actionTippys = {
      'adjust-bids': 'action not supported for archived keywords',
      'edit-status': 'action not supported for archived keywords'
    };
    commons.modifyTippyBasedOnActionState(actionsArray, actionTippys, true);
  }
};

// can be used for other tabs once we have keywords and sku tags too.
commons.tagsValidation = /[`!@#$%^&*+\=\[\]{};':"\\|,.<>\/?~]/;

commons.invertedPatternList = [
  'acos_',
  'cpc',
  'rep_oos',
  'iacos',
  'estimated_missed_',
  'cpa'
];

commons.isInverted = function (value) {
  const invertedPatternList = commons.invertedPatternList;
  for (const element of invertedPatternList) {
    if (value.indexOf(element) > -1) {
      return true;
    }
  }
  return false;
};

commons.calculateTableHeight = (sticky) => {
  const valuesObj = sticky;
  let topHeight = 0;
  for (let selector of valuesObj.addSpaceElementsSelector) {
    const element = document.querySelector(selector);
    topHeight += element.offsetHeight;
  }
  return `100vh - ${topHeight}px`;
};

commons.additionalDateRanges = () => {
  return {
    add: ['lastMonth', 'lastWeek'],
    order: {
      lastWeek: 3,
      lastMonth: 3
    }
  };
};

const bulkSearchAvailableEntities = {
  keywords: [
    {
      type: 'amsKeywords',
      label: 'Keywords'
    },
    {
      type: 'amsKeywordsCampaigns',
      label: 'Campaign names'
    }
  ],
  searchTerms: [
    {
      type: 'amsSearchTerms',
      label: 'Search Terms'
    },
    {
      type: 'amsSearchTermsCampaigns',
      label: 'Campaign names'
    }
  ],
  skus: [
    {
      type: 'amsSkus',
      label: 'Skus'
    },
    {
      type: 'amsSkusCampaigns',
      label: 'Campaign names'
    }
  ]
};
commons.getBulkSearchOptions = (pageName) => {
  return bulkSearchAvailableEntities[pageName];
};

commons.commonDataProperties = {
  maxSelectedMetrics: 2,
  minDisplayedMetricCount: 6,
  maxDisplayedMetricCount: 12,
  selectedRollUp: {},
  rollUps: [
    { name: 'Days', value: 'DAY', diff: 'day', key: 'DAY' },
    { name: 'Weeks', value: 'WEEK', diff: 'week', key: 'WEEK' },
    { name: 'Months', value: 'MONTH', diff: 'month', key: 'MONTH' }
  ]
};

// common reusable methods used across campaign management templates
const commonMethods = {
  triggerRefresh(realtime = true) {
    const widget2 = this.config.widgets.widget2;
    widget2.meta.realTimeRefresh = realtime;
    this.$store.dispatch(widget2.meta.action, widget2);
  },
  getChartDataWithRollUp: (
    selection,
    pageName,
    store,
    selectedRollUp,
    config
  ) => {
    const prevRollUpValue = store.getters[`get${pageName}RollUpByValue`];
    if (
      prevRollUpValue &&
      selection?.value &&
      selection.value !== prevRollUpValue
    ) {
      selectedRollUp = selection;
      store.dispatch(`set${pageName}RollUpByChanges`, selectedRollUp.value);
      store.dispatch(
        `getWorkbench${pageName}ChartData`,
        config.widgets.widget1
      );
    }
  },
  getComputedRollUpOptions: (getter, rollUpValues) => {
    const date = getter.date;
    let rollUps = [...rollUpValues];
    const getDaysDiff = Math.abs(
      moment(date.from, 'YYYY-MM-DD').diff(
        moment(date.to, 'YYYY-MM-DD'),
        'days'
      )
    );
    const isWeekRollUpValid = date.from && date.to && getDaysDiff < 13;
    const isMonthRollUpValid = date.from && date.to && getDaysDiff < 59;
    if (isWeekRollUpValid) {
      // remove the rollups by week
      rollUps = rollUps.filter(
        (item) => item.value !== 'MONTH' && item.value !== 'WEEK'
      );
    } else if (isMonthRollUpValid) {
      // remove the rollups by month
      rollUps = rollUps.filter((item) => item.value !== 'MONTH');
    }
    return [...rollUps];
  },
  destoryRealtimeRefreshInterval() {
    const { realtime, existingInterval } = this.getRealtimeMetadata;
    if (realtime && typeof existingInterval === 'string') {
      clearInterval(existingInterval.split('-')[0]);
    }
  },
  transformForRealtimePayload(obj, rowDataKey, primaryKey, row, type) {
    const realtimeMetadata = this.getRealtimeMetadata;
    const { data, realtime } = realtimeMetadata;
    if (type === 'pre-process') {
      if (primaryKey === 'keyword_id') {
        obj.viewPayload.keyword_text = row.keyword_text;
        obj.viewPayload.adgroup_name = row.adgroup_name;
        obj.actionPayload.keyword_id = row.keyword_id;
        obj.actionPayload.target_id = row.keyword_id;
        obj.actionPayload.adgroup_id = row.adgroup_id;
      }
      obj.widget = primaryKey === 'keyword_id' ? 'keyword' : 'campaign';
      obj.actionPayload.profileId = row.profile_id;
      obj.primaryKey = row[primaryKey];
      if (realtime && data?.[row?.[primaryKey]]?.[rowDataKey]) {
        row[rowDataKey] = data?.[row?.[primaryKey]]?.[rowDataKey];
      }
    } else if (type === 'post-process') {
      if (primaryKey === 'campaign_id') {
        obj.viewPayload.previousBudget = obj.viewPayload.daily_budget;
        obj.viewPayload.newBudget = obj.viewPayload.new_daily_budget;
      }
      if (primaryKey === 'keyword_id') {
        delete obj.actionPayload?.keywordId;
        delete obj.actionPayload?.targetId;
      }
    }
  },
  metricSelected(context, val) {
    if (val.length < 1) return;
    if (this.metricsShown.indexOf(val[0].title) === -1) {
      this.metricsShown.splice(context[0], 1, val[0].title);
      this.replaceMetric(context, [val[0].title]);
      this.isExpand = false;
    }
  },
  replaceMetric(context, val) {
    if (
      context &&
      context.length > 0 &&
      this.metricsSelectedIndex.indexOf(context[0]) === -1 &&
      val &&
      val.length > 0
    ) {
      this.metricsSelectedIndex.shift();
      this.metricsSelectedIndex.push(context[0]);
      this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y2';
      this.chartAxisFormat[context[0] % 2 === 0 ? 'y' : 'y2'] =
        this.chartAxisFormatMap[val[0]];
      this.isExpand = false;
    }
  },
  selectNewMetric(context, val) {
    if (context && context.length > 0) {
      const foundIndex = this.metricsSelectedIndex.indexOf(context[0]);
      if (foundIndex === -1 && val && val.length > 0) {
        if (this.metricsSelectedIndex.length > 1) {
          this.metricsSelectedIndex.shift();
        }
        this.metricsSelectedIndex.push(context[0]);
        this.chartWkbenchAxes[val[0]] = context[0] % 2 === 0 ? 'y' : 'y2';
        this.chartAxisFormat[context[0] % 2 === 0 ? 'y' : 'y2'] =
          this.chartAxisFormatMap[val[0]];
        this.isExpand = false;
      }
    }
  },
  addNewMetric(context, _val) {
    if (_val && _val.length > 0) {
      this.metricsShown.push(_val[0].title);
      if (this.metricsSelectedIndex.length > this.maxSelectedMetrics - 1) {
        this.metricsSelectedIndex.shift();
      }
      this.metricsSelectedIndex.push(this.metricsShown.length - 1);
    }
  },
  deleteMetric(deleteIndex) {
    /**
     * we need to reduce the the index numbers of all the metrics
     * which are selected after the current one
     */
    this.metricsSelectedIndex = this.metricsSelectedIndex.reduce(
      (accumulator, currentValue) => {
        if (currentValue >= deleteIndex) {
          if (accumulator.includes(currentValue - 1))
            accumulator.push(currentValue - 2);
          else {
            accumulator.push(currentValue - 1);
          }
        } else {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );
    this.metricsShown.splice(deleteIndex, 1);
  },
  clickedOutsideModal(val) {
    if (val) {
      this.showEmailPopUp = false;
    }
  },
  disabledMetricsFn(title) {
    const workBenchData = this[this.workBenchDataKey] || {};
    const widgetMetaKey = this.config.widgets.widget2.meta.key;
    return (
      this.disableMetric.includes(title) &&
      this.plottedSelections !== 1 &&
      workBenchData[widgetMetaKey]?.rows?.length !== 1
    );
  },
  openEmailPopup() {
    this.modalConfig = {
      bodyTitle: {
        text: 'Email Report',
        highlightText: '',
        icon: 'email-subscription'
      },
      body: {
        text: 'Request for an email with a link to download this report.'
      },
      footer: {
        leftButton: {
          text: 'Cancel',
          onClick: () => {
            this.showEmailPopUp = false;
          },
          icon: '',
          show: true
        },
        rightButton: {
          text: 'Request',
          onClick: this.changeModalConfig,
          icon: '',
          show: true
        }
      }
    };
    this.showEmailPopUp = true;
  },
  changeModalConfig() {
    this.sendEmailRequest();
    this.modalConfig = {
      bodyTitle: {
        text: 'Request Recieved',
        highlightText: '',
        icon: 'Send'
      },
      body: {
        text: 'Look out for an email with a link to download the report.',
        subtext: 'Requests may take upto 5 minutes to be processed'
      },
      footer: {
        leftButton: {
          show: false
        },
        rightButton: {
          text: 'Done',
          onClick: () => {
            this.showEmailPopUp = false;
          },
          icon: '',
          show: true
        }
      }
    };
  },
  sendEmailRequest() {
    const columnMap =
      this.tableColumns || this.config.widgets.widget2.body.download.columnMap;
    const APIConfig = JSON.parse(
      JSON.stringify(this.config.widgets.widget2.body.APIConfig)
    );
    commons.sendEmailRequest(
      columnMap,
      APIConfig,
      this,
      this.downloadFileSuffix
    );
  },
  getFilterInstance(that) {
    this.filterInstance = that;
  },
  dayparting(selectedOption) {
    const rows = [];
    selectedOption.forEach((option) => {
      rows.push(option.data);
    });
    const filters = this.filterInstance
      ? this.filterInstance.getSelectedFilters()
      : [];
    const obj = {
      targetList: rows,
      filters
    };
    this.$store.dispatch('createDayPartingWithData', obj);
    this.$router.push({ name: 'create-strategy' });
  },
  deselectAllRows() {
    if (this.config.widgets.widget2.body.gridOptions.api !== null) {
      this.config.widgets.widget2.body.gridOptions.api.deselectAll();
    }
    this.onSelectionChanged([]);
  },
  handleRadioSelection(value) {
    this.selectedBulkSearchType = value;
  }
};

export { commons, commonMethods };
