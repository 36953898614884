import {
  adgroupConfig,
  adFormat,
  productTargeting,
  keywordTargeting,
  classification,
  budget,
  timePeriod,
  creative,
  biddingStrategySB
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/index.js';
import { productActionStateForSB } from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';
import { cloneDeep } from 'lodash';

const formStepConfig = () => {
  const adgroup = cloneDeep(adgroupConfig);
  const keywordConfig = cloneDeep(keywordTargeting);
  keywordConfig.pageLimit = 30;
  keywordConfig.hidePaginationOptions = true;
  const classify = cloneDeep(classification);
  const dates = cloneDeep(timePeriod);
  const productTargetingEdited = cloneDeep(productTargeting);
  productTargetingEdited.customActionPanelCustomState = productActionStateForSB;
  adgroup.name = "Sku's";
  adgroup.header = {
    title: "Sku's",
    desc: ["Select sku's from the table below you want to advertise."]
  };
  adgroup.enableBackButton = true;
  classify.enableBackButton = false;
  dates.useNextButton = true;
  adgroup.hideAdgroup = true;
  adgroup.showLeftButton = true;
  const stepsConfig = [
    classify,
    budget,
    dates,
    adFormat,
    adgroup,
    creative,
    productTargetingEdited,
    keywordConfig,
    biddingStrategySB
  ];

  return stepsConfig;
};

export default {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  disableForActiveSelection: 3,
  getter: 'campaignCreation/getAllStepsData',
  action: 'campaignCreation/updateAllSetpsData',
  stepsConfig: formStepConfig(),
  enableStepsAfterValidation: true
};
