<template>
  <div class="u-spacing-mt-0 price_war">
    <widgetContainer
      :footer-options="footerOptionsComp"
      :header-options="headerOptionsComp"
      :last-updated-date="lastUpdatedDate"
      :header-container-grid-styles="headerContainerGridStyles"
    >
      <div
        slot="body"
        class="u-display-flex u-flex-direction-column u-spacing-mt-0"
      >
        <chart-container
          :is-chart-loading="isChartLoading"
          :chart-data="chartData"
          :chart-config="chartConfig"
          :show-powered-by-c-i-q-text="true"
        />
      </div>
    </widgetContainer>
  </div>
</template>

<script>
import cardWidget from '@/components/widgets/cardWidget';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import { cloneDeep, truncate } from 'lodash';
import Vue from 'vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import chartContainer from '@/components/widgets/custom_widgets/custom_widget_components/charts/chart-container.vue';
import moment from 'moment-timezone';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    cardWidget,
    widgetContainer,
    chartContainer
  },
  mixins: [cwMixin],
  data() {
    return {
      isChartLoading: true,
      metricsArray: [],
      dataGroups: {},
      lastUpdatedDate: null,
      metricTooltip: {},
      showMetrics: ['comp_name', 'comp_offer_price'],
      metricData: {},
      // selectedMetrics: [],
      metricsDataResponse: {},
      metricList: [],
      chartData: {},
      chartConfig: {
        chartOptions: {
          legend: true,
          xFormat: '%m/%d/%Y',
          timeseries: 'feed_date',
          tooltip_format: {
            All: {
              pre: 'currency',
              customNameFn: this.customTooltipName
            }
          },
          axes: {},
          grid: 'xy',
          hasEvents: false,
          type: 'line',
          types: {},
          xAxisType: 'category',
          stack: [],
          chartGetter: '',
          show_axis_colors: true,
          events: []
        },
        stack: [],
        chartGetter: '',
        xAxisType: 'category',
        lastUpdatedDate: null
      }
    };
  },
  watch: {
    widgetRequestParams() {
      this.fetchData();
    }
  },
  created() {
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    this.fetchData();
  },
  methods: {
    asinSelected(context, selection) {},
    asinListener(searchTerm, context) {},
    fetchData() {
      this.isChartLoading = true;
      // const metadata = this.metadata.metadata;
      const dataGroups = dashUtils.createDataGroups(this.metadata.metrics);
      const dataGroupsList = Object.keys(dataGroups);
      this.metricsArray = Object.values(this.metadata.metrics).map((item) => {
        item.key = item.label;
        item.title = item.label;
        return item;
      });

      // this.selectedMetrics = metadata?.defaultConfig?.selectedMetric ;
      this.metricList = cloneDeep(this.metricsArray);

      let request, api;
      for (let i = 0; i < dataGroupsList.length; i++) {
        const dgKey = dataGroupsList[i];
        const metricsPerDataGroup = Object.keys(dataGroups[dgKey]);
        const metricsListName = Object.values(dataGroups[dgKey]).map(
          ({ name }) => name
        );
        api = this.metadata.metrics[metricsPerDataGroup[0]].api;
        request = api.request;
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];
        request = dashUtils.replacePlaceHolderWithData(
          request,
          this.widgetRequestParams
        );
        api.request.metricsList = metricsListName;
        request = this.tempTransformer(request, metricsListName);
        if (
          request.where.dimensionNameValueList.find(
            (e) => e.dimensionName === 'sku'
          )
        ) {
          dashUtils.fetchDataAPI(request, api).then((args) => {
            Vue.set(this.metricsDataResponse, dgKey, args.entityData);
            this.dataTransformer(this.metricsDataResponse);
            this.isChartLoading = false;
          });
        }
      }
    },
    customTooltipName(data) {
      const tooltip = this.metricTooltip?.[data.id]?.find((e) =>
        moment(data.x).isSame(e.date)
      );
      return tooltip?.name ?? data.name;
    },
    dataTransformer(metricResponse) {
      const finalData = [];
      const names = {};
      const xs = {};
      const legends = {};
      for (const key in metricResponse) {
        const retailers = metricResponse[key];
        const metricTooltip = {};
        for (const i of retailers) {
          const metrics = i.data;
          const compName = metrics.find((e) => e.name === 'comp_name')?.RESULT
            ?.comp_name;
          const productUrl = metrics.find((e) => e.name === 'comp_url')?.RESULT
            ?.comp_url;
          const is3P = metrics.find((e) => e.name === 'is_3p')?.RESULT;
          const data = [];
          const timeseriesArray = [];
          for (const metric of metrics) {
            const timeseries = metric.TIMESERIES;
            const selectedMetric = this.metricsArray.filter(
              (e) => e.name === 'pricing_dashboard_w1__'.concat(metric.name)
            );
            const metricKeyName = selectedMetric[0]?.keyName?.concat(
              '_' + compName
            );
            if (timeseries.length && this.showMetrics.includes(metric.name)) {
              data.push(metricKeyName);
              names[metricKeyName] = compName;
              legends[metricKeyName] = { title: compName, url: productUrl };
              timeseriesArray.push('feed_date_'.concat(compName));
              metricTooltip[metricKeyName] = [];
              for (const obj of timeseries) {
                if (is3P) {
                  const timeseries3P = metrics?.find(
                    (e) => e.name === 'is_3p'
                  )?.TIMESERIES;
                  const suffix = timeseries3P?.find(
                    (e) => e.feed_date === obj.feed_date
                  )?.is_3p;
                  metricTooltip[metricKeyName].push({
                    name: suffix?.length
                      ? `${compName} ${truncate(suffix)}`
                      : names[metricKeyName],
                    date: obj.feed_date
                  });
                }
                data.push(obj[metric.name]);
                timeseriesArray.push(obj.feed_date);
              }
              xs[data[0]] = timeseriesArray[0];
              finalData.push(data, timeseriesArray);
            }
          }
          this.metricTooltip = metricTooltip;
        }
        this.chartData = {
          data: finalData,
          names,
          colors: {},
          xs,
          legends
        };
      }
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    }
  }
};
</script>

<style lang="css">
.price_war .chart {
  height: 400px;
}
.price_war .legends-container {
  margin-top: 0.2rem;
}
.price_war .graphWithGrids {
  margin-bottom: 3.2rem;
}
.price_war .icon-open-new-window:hover {
  opacity: 0.7;
}
</style>
