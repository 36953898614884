import { addSkuOnInput } from '@/pages/entity_details/configs/walmart/skuEnterListHelpers.js';

export const actionPanelAddCustomProductsConfig = (context) => ({
  component: 'customActionPanel',
  props: {
    staticHeaderText: 'Add Products',
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'sku',
          isMultipleAllowed: true,
          default: {
            hideHeader: false,
            hideFooter: false,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: '',
            actionInfoText:
              'Minimum of 2 and maximum of 10 enabled SKUs can be part of the ad group. Adding SKUs will re-initiate the review process of the campaign.'
          },
          left: {
            rowHeight: 68,
            tabs: {
              Search: {
                localFilter: {
                  // dimensionName: 'campaign_id',
                  // dimensionValue: context.entityId
                },
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'search_text',
                  placeholder: 'Enter Item ID, Item Name, or Brand Name'
                }
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      data.cubeName = 'walmart_sku_catalog';
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              filters: {
                emit: 'fetchTableDataExecuteApi',
                hasSearch: false
              },
              filterState: {
                getter: 'getExecuteApiSelectedFilters',
                setter: 'fetchEntitiesExecuteApiFilters'
              },
              localFilters: [],
              localStoreageFilterKey: 'walmart-add-sku',
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: {
                cubeName: 'walmart_sku_catalog',
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [
                  'product_title',
                  'buy_box_pct',
                  'availability_pct',
                  'availability_status',
                  'brand_name',
                  'offer_price',
                  'image_url',
                  'product_url'
                ],
                groupByDimensionsList: ['sku'],
                orderByList: [],
                where: {
                  dimensionNameValueList: []
                },
                limit: 10,
                page: 1
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            routeData: context.$route,
            type: 'SKUS',
            fetchAll: true,
            altStore: 'entityDetails/getReviewStatusData',
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            keepFetchedRightTableRows: true,
            meta: {
              type: 'table',
              action: 'entityDetails/fetchReviewStatusData',
              localFilters: [
                {
                  dimensionName: 'ad_group_id',
                  dimensionValue: context.entityId,
                  operator: 'EQUAL_TO'
                }
              ],
              updateRows: (vueRef, rows = []) => {
                const newRows = [];
                for (let item of rows) {
                  if (item.reviewStatus === 'rejected') {
                    continue;
                  }
                  item.sku = item.itemId;
                  item.product_title = item.name;
                  item.image_url = item.itemImageUrl;
                  item.product_url = item.itemPageUrl;
                  newRows.push(item);
                }
                vueRef.fetchedRightTableRows = {
                  rows: newRows,
                  totalRows: vueRef.rightTableData.totalRows
                };
                vueRef.rightTableRows = newRows;
                vueRef.removeAll();
              }
            },
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              APIConfig: {
                limit: 30,
                page: 1
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          },
          applyButtonDisabled(vueRef) {
            // Below, we are filtering the items which are enabled
            // We are also counting the skus which were added from the left table, which does not have an item.status itself
            // hence the condition !item.status && item.staut === 'enabled'
            const enabledSkusLength = vueRef.rightTableRows.filter(
              (item) => !item.status || item.status === 'enabled'
            ).length;
            return !(enabledSkusLength > 1 && enabledSkusLength < 11);
          },
          apply(rows = []) {
            context?.tabConfig?.addProducts?.(context, rows, vueRef);
          }
        };
      }
    }
  },
  events: {
    afterAPIActionToCall(payload) {
      // this.$emit('openSnackbar', 'Action has been submitted');
      // this.switchBlueBar('line item');
      // this.removeCustomCustomComponents();
      context.closeActionBar();
    },
    closePanelMethod() {
      context.showAddCustomProductPanel = false;
      context.closeActionBar();
    }
  }
});

const selectSkuLeftTable = (vueRef) => {
  return [
    {
      // currDefinition
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: 'View Walmart detail page',
          copyClipboardText: 'Item ID copied to clipboard',
          columnDisableProductLink: true,
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return params?.data?.product_title + '_' + params?.data?.asin;
          }
        }
      }
    },
    {
      name: 'brand_name',
      type: 'STRING',
      uiField: {
        uiLabel: 'Brand',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'brand_name',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'offer_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'offer_price',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availability',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'buy_box_pct',
      type: 'STRING',
      uiField: {
        uiLabel: 'Buybox win %',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'buy_box_pct',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        uiTooltip: 'Add the item in the Ad Group',
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all SKUs on this page'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};
const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: 'View Walmart detail page',
          showOnUi: true,
          isFixed: false
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        uiTooltip: 'Remove all the added items from the Ad Group.',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove the item from the Ad Group.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};
