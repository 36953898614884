import Vue from 'vue';
import { getLocale } from '@/utils/helpers/formatter';

function getDisplayValue(params) {
  if (
    params.value === undefined ||
    params.value === null ||
    params.value === ''
  ) {
    return 'NA';
  }
  return params.formatterFn
    ? params.formatterFn(
        params.value,
        params.keyType,
        params.customNAString,
        params.precision
      )
    : params.value;
}

function cellRenderer(params) {
  let defaultTextColor =
    typeof params?.colDef?.defaultTextColor === 'string'
      ? params?.colDef?.defaultTextColor
      : 'u-color-grey-base';
  const element = document.createElement('div');
  const defClamp = params.clamp ? params.clamp : 1;
  element.className +=
    'u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-clamp-' +
    defClamp;
  let alignmentClass = ' u-flex-justify-content-flex-end';
  if (params.keyType === 'string') {
    alignmentClass = ' u-flex-justify-content-flex-start';
  }

  element.className += alignmentClass;

  const cellContent = document.createElement('div');
  cellContent.className += `cell--content u-white-space-normal u-flex-shrink-past-min-size ${defaultTextColor} u-overflow-hidden`;
  const cellValue = getDisplayValue(params);
  cellContent.innerHTML = Vue.prototype.getDisplayValue(cellValue);
  cellContent.title = cellValue;
  const localeString = Math.round(params.value).toLocaleString(getLocale(), {
    minimumFractionDigits: 0
  });
  if (typeof params.value === 'number' && params.keyType === 'CURRENCY') {
    cellContent.title = `${
      getDisplayValue(params).includes(`${Vue.prototype.$currency}`)
        ? Vue.prototype.$currency
        : ''
    }${localeString}`;
  }
  element.appendChild(cellContent);
  Vue.nextTick(() => {
    if (params.eGridCell) {
      let clamp = Math.ceil((params.eGridCell.clientHeight - 32) / 16);
      clamp = clamp > 0 ? clamp : 1;
      params.eGridCell.classList.add('u-clamp-' + clamp);
      let toExpand = false;
      let isNumericalCell = false;
      const el = params.eGridCell.querySelector('.cell--content');
      if (
        el.clientHeight < el.scrollHeight - 1 ||
        el.clientWidth < el.scrollWidth
      ) {
        toExpand = true;
      } else if (el.children && el.children.length > 0) {
        const children = el.querySelectorAll('*');
        for (const child of children) {
          if (
            child.clientWidth < el.scrollWidth ||
            child.clientWidth < child.scrollWidth
          ) {
            toExpand = true;
            break;
          }
        }
      } else if (
        typeof params.value === 'number' &&
        params.keyType === 'CURRENCY' &&
        localeString.length > 4
      ) {
        toExpand = true;
        isNumericalCell = true;
      }

      if (!toExpand) {
        el.removeAttribute('title');
        el.removeAttribute('data-original-title');
        el.classList.add('u-cursor-text');
      } else if (isNumericalCell) {
        el.removeAttribute('tippy');
      } else {
        if (window.Tippy) {
          window.Tippy(cellContent, {
            placement: 'top-start',
            delay: [500, 0],
            animation: 'scale',
            interactive: true,
            maxWidth: '200px',
            theme: 'click-to-expand',
            duration: [150, 0]
          });
          el.classList.add('u-cursor-pointer');
        }
      }
    }
  });
  return element;
}

export default cellRenderer;
