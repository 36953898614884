<template>
  <div
    class="u-width-100 header-component"
    :class="{ 'u-margin-bottom-40': defaultMarginBottom }"
  >
    <h3 class="u-font-size-3 u-font-weight-600 u-text-align-left u-padding-0">
      {{ title }}
    </h3>
    <div
      v-if="description"
      class="step-desc u-font-size-5 u-spacing-mt-xs u-color-grey-lighter u-line-height-1-3"
    >
      <p>
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UploadHeader',
  props: {
    defaultMarginBottom: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Default Title'
    },
    description: {
      type: String,
      default: 'Default Description'
    }
  },
  methods: {}
};
</script>
<style lang="css">
.header-component h3 {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
