<template>
  <div v-if="boolLoadChart">
    <dashboard-inflator
      class="campaign-details"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :show-header="false"
      :disable-envelop-width-class="true"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>

<script>
// wrapper service for charting component using dashboard inflator

import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import { modifySBAdGroupsTabs } from '@/pages/entity_details/configs/amazon/sb.js';
export default {
  components: {},
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    entityConfig: {
      default: () => {},
      type: Object
    }
  },
  data() {
    const pageInfo = {
      name: this.entityConfig.page
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      executeApiResponse: null,
      boolLoadChart: false,
      getState: null,
      targetingData: null,
      headerOptions: () => {
        let footerOptions = [];
        let showLastUpdatedDate = true;
        if (this.entityConfig?.maxFeedDate) {
          const maxDate =
            this.$store?.getters?.getMaxDate?.ams_campaign?.max_feed_date;
          showLastUpdatedDate = false;
          footerOptions = [
            {
              ui_component: 'iconText',
              props: {
                text: `Last Updated: ${
                  Vue.options.filters.formattable_date(maxDate) || 'NA'
                }`,
                textClasses: 'u-color-grey-x-light u-font-size-7'
              }
            }
          ];
        }
        return {
          [this.entityConfig?.widget ?? 'chartingWb']: {
            props: {
              headerOptions: [],
              chartConfig: {
                chartOptions: {
                  legend: false,
                  xFormat: '%m/%d/%Y',
                  timeseries: 'report_date',
                  tooltip_format: {},
                  axes: {},
                  grid: 'xy',
                  hasEvents: false,
                  type: 'line',
                  types: {},
                  xAxisType: 'category',
                  stack: [],
                  chartGetter: '',
                  show_axis_colors: true,
                  events: [],
                  dynamicRange: true,
                  dynamicRangeValues: [0, 100],
                  size: { height: 380 },
                  axis_format: {}
                },
                stack: [],
                chartGetter: '',
                xAxisType: 'category',
                lastUpdatedDate: false
              },
              footerOptions,
              showLastUpdatedDate,
              enableRollup: this.entityConfig?.enableRollup ?? true,
              enableDownload: this.entityConfig?.enableDownload ?? false,
              enableToggle: true,
              show: false,
              selectedMetricLimit: 2,
              minimumMetric: 5,
              removeDropdown: false,
              disableAddNewMetricButton: false,
              enableLegendSearch: true,
              headerContainerGridStyles: {
                display: 'flex',
                'justify-content': 'flex-end',
                gap: '1.6rem',
                'align-items': 'center'
              }
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#cm_campaign_details'
        },
        // will be moved to a config file
        [this.entityConfig?.widget ?? 'chartingWb']: {
          ':widget': this.entityConfig?.widget ?? 'ChartingWB',
          ':timeseriesRollupBy': 'DAY',
          keepExistingDimensionNameValueList: true,
          replaceEntityInDimensionNameValueList: {
            ':campaign_id': this.entityId
          },
          timeseries: 'report_date'
        }
      }
    };
  },
  computed: {
    campaignInfo() {
      return this.$store.getters[this.getState]?.data;
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        this.updateEntireRequestParams(this.initRequestParams);
      },
      immediate: true,
      deep: true
    },
    targetingData() {
      if (this.executeApiResponse?.data?.length) {
        this.boolLoadChart = true;
      }
    }
  },

  async created() {
    if (this.entityConfig?.config?.chartingWb) {
      this.updateChartingWB(this.entityConfig?.config?.chartingWb);
    }
    Vue.component(this.entityConfig?.widget ?? 'chartingWb', chartingWb);
    this.getState = this.entityConfig?.apiConfigs?.getter;
    this.boolLoadChart = true;
  },
  methods: {
    updateChartingWB(chartingWB) {
      const newChartingWB = cloneDeep(chartingWB);
      deepReplaceObjectValues(newChartingWB, ':entityId', this.entityId);
      this.initRequestParams[this.entityConfig?.widget ?? 'chartingWb'] =
        newChartingWB;
    },
    async metadataUpdated(data) {
      let metadata = [];
      metadata = data.filter((item) => item?.metadata?.type === 'tabs');
      if (
        this.$route?.params?.entityType === 'sb' &&
        this.$route.params?.subEntityType
      ) {
        modifySBAdGroupsTabs(metadata, this.$route);
      }
      metadata.length && this.$emit('tabsMetadata', metadata[0]);
    }
  }
};
</script>

<style lang="css">
.campaign-details .custom_widget_body {
  padding-bottom: 2.4rem;
}
.campaign-details .charting_wb_wrapper .header-container-cwc {
  padding-bottom: 0;
}
</style>
