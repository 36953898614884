export const ErrorCodes = {
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  FETCHING_CAMPAIGN_DETAILS_FAILED: 'FETCHING_CAMPAIGN_DETAILS_FAILED',
  FETCHING_AD_GROUP_DETAILS_FAILED: 'FETCHING_AD_GROUP_DETAILS_FAILED',
  FETCHING_SKUS_DETAILS_FAILED: 'FETCHING_SKUS_DETAILS_FAILED',
  CAMPAIGN_CREATE_UPDATE_FAILED: 'CAMPAIGN_CREATE_UPDATE_FAILED',
  CREATE_AD_GROUP_FAILED: 'CREATE_AD_GROUP_FAILED',
  UPDATE_AD_GROUP_FAILED: 'UPDATE_AD_GROUP_FAILED',
  ADD_SKUS_FAILED: 'ADD_SKUS_FAILED',
  UPDATE_SKUS_FAILED: 'UPDATE_SKUS_FAILED',
  DELETE_SKUS_FAILED: 'DELETE_SKUS_FAILED',
  FETCHING_KEYWORDS_DETAILS_FAILED: 'FETCHING_KEYWORDS_DETAILS_FAILED',
  FETCHING_NEGATIVE_KEYWORDS_DETAILS_FAILED:
    'FETCHING_NEGATIVE_KEYWORDS_DETAILS_FAILED',
  ADD_KEYWORDS_FAILED: 'ADD_KEYWORDS_FAILED',
  ADD_NEGATIVE_KEYWORDS_FAILED: 'ADD_NEGATIVE_KEYWORDS_FAILED',
  UPDATE_KEYWORDS_FAILED: 'UPDATE_KEYWORDS_FAILED',
  DELETE_KEYWORDS_FAILED: 'DELETE_KEYWORDS_FAILED',
  DELETE_NEGATIVE_KEYWORDS_FAILED: 'DELETE_NEGATIVE_KEYWORDS_FAILED',
  FETCHING_PROFILE_DETAILS_FAILED: 'FETCHING_PROFILE_DETAILS_FAILED',
  ADD_PROFILES_FAILED: 'ADD_PROFILES_FAILED',
  UPDATE_PROFILES_FAILED: 'UPDATE_PROFILES_FAILED',
  UPDATE_PROFILE_IMAGES_FAILED: 'UPDATE_PROFILE_IMAGES_FAILED',
  SUBMIT_FOR_REVIEW_FAILED: 'SUBMIT_FOR_REVIEW_FAILED',
  FETCHING_PLACEMENT_INCLUSION_DETAILS_FAILED:
    'FETCHING_PLACEMENT_INCLUSION_DETAILS_FAILED',
  FETCHING_PLACEMENT_BID_MULTIPLIER_DETAILS_FAILED:
    'FETCHING_PLACEMENT_BID_MULTIPLIER_DETAILS_FAILED',
  FETCHING_PLATFORM_BID_MULTIPLIER_DETAILS_FAILED:
    'FETCHING_PLATFORM_BID_MULTIPLIER_DETAILS_FAILED',
  UPDATE_PLACEMENT_INCLUSION_FAILED: 'UPDATE_PLACEMENT_INCLUSION_FAILED',
  UPDATE_PLACEMENT_BID_MULTIPLIER_FAILED:
    'UPDATE_PLACEMENT_BID_MULTIPLIER_FAILED',
  UPDATE_PLATFORM_BID_MULTIPLIER_FAILED:
    'UPDATE_PLATFORM_BID_MULTIPLIER_FAILED',
  LAUNCH_CAMPAIGN_FAILED: 'LAUNCH_CAMPAIGN_FAILED'
} as const;

type Keys = keyof typeof ErrorCodes;
type ErrorCodesValueTypes = typeof ErrorCodes[Keys];

export class WalmartCreateCustomException extends Error {
  metadata: any;
  constructor(message: ErrorCodesValueTypes, metadata?: any) {
    super(message);
    this.metadata = metadata;
  }
}
