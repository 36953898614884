<template>
  <div
    class="u-width-100 u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-pt-s"
  >
    <!-- cumulativ difference legend -->
    <div
      class="u-display-flex"
      style="min-width: 180px"
    >
      <div
        class="custom-chart-legend u-cursor-default workbench-hover-card"
        :class="[
          dashboardLegendsData.cumulativeDifference.spendStatus ===
          'Underspending'
            ? 'u-bg-color-green-base'
            : 'u-bg-color-red-base'
        ]"
      >
        <div
          class="u-font-size-6 u-color-grey-white u-spacing-pt-m u-spacing-ph-m"
          style="margin-top: 6px"
        >
          Cumulative difference
        </div>
        <div
          class="u-font-size-4 u-color-grey-white u-spacing-ph-m u-spacing-pt-xs"
        >
          {{ dashboardLegendsData.cumulativeDifference.spendStatus }}
        </div>
        <div
          class="u-font-size-3 u-color-grey-white u-font-weight-600 u-spacing-mb-xs u-spacing-ph-m u-spacing-pt-m u-spacing-pb-m"
        >
          {{ dashboardLegendsData.cumulativeDifference.difference }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dashboardLegendsData: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
