<template>
  <div class="emailSubscription u-position-fixed">
    <div class="u-position-fixed modal emailSubscription">
      <div
        class="emailSubscription--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-width-90"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span @click="goBackValidation()">
              <rb-icon
                :icon="'cross'"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <div class="heading--input u-font-size-2 u-font-weight-bold">
              Manage Subscriptions
            </div>
          </div>
          <div
            class="strategy-desc u-font-size-5 u-spacing-pt-s u-spacing-ml-l u-width-100"
          >
            Manage email subscriptions by enabling recommendations, choosing
            columns within a recommendation and setting filters for each
            recommenation type.
          </div>
        </div>
        <div class="u-font-size-5 u-text-align-right">
          <rb-button
            id="confirmButton"
            :text="'Save'"
            :type="'filled'"
            :disabled="
              (!subscriptionToggle && editSubscriptionFlow) ||
              (entity.subscriptionId && !subscriptionToggle) ||
              disableSaveSubscriptionBtn ||
              subscribedEntity.length === 0
            "
            :click-fn="updateSubscription"
          />
        </div>
      </div>
      <div class="scrollable">
        <div
          class="emailSubscription--body u-display-flex u-flex-justify-content-center u-width-100 u-position-relative"
        >
          <div
            class="u-width-90"
            style="padding-bottom: 150px"
          >
            <div class="step-class--pseudo">
              <div class="u-spacing-mt-xl">
                <div
                  v-show="
                    entity.subscriptionId && subscriptionToggle
                      ? true
                      : !entity.subscriptionId
                      ? true
                      : false
                  "
                  class="u-display-flex u-flex-direction-row u-position-relative u-flex-justify-content-flex-start u-spacing-p-xl u-width-100 card u-flex-align-items-center front"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-column">
                      <span
                        class="u-font-size-3 u-font-weight-600 u-color-blue-base u-text-case-upper"
                        >Email Subscription</span
                      >
                      <span
                        class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s"
                        >Easily configure email subscription.</span
                      >
                    </div>
                  </div>
                  <div class="">
                    <rb-switch v-model="subscriptionToggle" />
                  </div>
                </div>

                <div
                  v-show="!subscriptionToggle && entity.subscriptionId"
                  class="u-display-flex u-flex-direction-row u-position-relative u-flex-justify-content-flex-start u-spacing-p-xl u-width-100 card u-flex-align-items-center back"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-column">
                      <span
                        class="u-font-size-3 u-font-weight-600 u-color-blue-base u-text-case-upper"
                      >
                        Unsubscribe?
                      </span>
                      <span
                        class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s"
                      >
                        You will be unsubscribed from all the recommendation
                        types.
                      </span>
                    </div>
                  </div>
                  <div>
                    <rb-button
                      :text="'Cancel'"
                      :click-fn="cancelSubscription"
                      class="u-spacing-mr-s"
                    />
                    <rb-button
                      id="confirmButton"
                      :text="'Confirm & Save'"
                      :type="'filled'"
                      class="confirm__save__btn"
                      :click-fn="unSubscribe"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="step-class--pseudo"
              :style="[
                subscriptionToggle
                  ? { opacity: 1 }
                  : { opacity: 0.25, 'pointer-events': 'none' }
              ]"
            >
              <div class="u-spacing-mt-l">
                <div
                  class="u-display-flex u-flex-direction-row u-position-relative u-flex-justify-content-flex-start u-spacing-p-xl u-width-100 card u-flex-align-items-center"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-column">
                      <span
                        class="u-font-size-3 u-font-weight-600 u-color-blue-base u-text-case-upper"
                        >Frequency</span
                      >
                      <span
                        class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s"
                        >Frequency at which the subscription email will be
                        generated.</span
                      >
                    </div>
                  </div>
                  <div
                    class=""
                    style="pointer-events: none"
                  >
                    <rb-select
                      :send-details="true"
                      :options="[{ title: 'Daily' }, { title: 'Weekly' }]"
                    >
                      <div
                        slot="trigger"
                        class="u-display-flex u-spacing-p-s u-flex-align-items-center u-cursor-pointer"
                      >
                        <span class="u-font-size-5">Daily</span>
                        <!-- <rb-icon
                          class="rb-icon--small u-spacing-ml-s u-color-grey-lighter"
                          :icon="'caret-down'"
                        ></rb-icon> -->
                      </div>
                    </rb-select>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="step-class--pseudo card"
              :style="[
                subscriptionToggle
                  ? { opacity: 1 }
                  : { opacity: 0.25, 'pointer-events': 'none' }
              ]"
            >
              <div class="u-spacing-mt-l">
                <div
                  class="u-display-flex u-flex-direction-row u-position-relative u-flex-justify-content-flex-start u-spacing-p-xl u-spacing-pb-m u-width-100 u-flex-align-items-center"
                >
                  <div class="u-flex-1">
                    <div class="u-display-flex u-flex-direction-column">
                      <span
                        class="u-font-size-3 u-font-weight-600 u-color-blue-base u-text-case-upper"
                        >Recommendation Subscriptions</span
                      >
                      <span
                        class="u-font-size-5 u-color-grey-lighter u-spacing-mt-s"
                        >Add recommendations for subscription and choose columns
                        and filters for subscribed recommendations.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="scroll_into__view"
                class="u-display-flex u-flex-direction-column u-spacing-ph-xl u-spacing-pb-l"
              >
                <div class="u-display-flex">
                  <div class="u-display-flex u-width-100">
                    <div class="u-width-50 u-spacing-mr-m">
                      <section class="custom--table">
                        <div class="">
                          <div
                            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center custom--table--header u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s"
                          >
                            <div
                              class="u-display-flex u-flex-align-items-center"
                            >
                              <span class="u-font-size-4 u-font-weight-600">
                                Recommendation types
                              </span>
                            </div>
                          </div>

                          <div class="">
                            <div
                              class="u-overflow-auto custom--table--body u-spacing-pb-0"
                            >
                              <!-- eslint-disable -->
                              <section
                                v-for="(item, index) of this.entityTransform"
                                :key="index"
                                class="u-spacing-mt-m"
                              >
                                <!-- eslint-enable -->
                                <div
                                  class="u-display-flex u-flex-justify-content-space-between u-spacing-pb-s u-spacing-pl-l"
                                >
                                  <span
                                    class="u-font-size-4 u-font-weight-600 u-color-grey-light"
                                  >
                                    {{ index }}
                                  </span>
                                </div>

                                <!-- eslint-disable -->
                                <div
                                  v-for="(entity, index) in item"
                                  :key="index"
                                  class="u-spacing-pv-s u-spacing-pl-l recommendation"
                                  :class="[
                                    entity.hasSubscribed
                                      ? 'highlightEntity'
                                      : ''
                                  ]"
                                >
                                  <!-- eslint-enable -->
                                  <div
                                    class="u-display-flex u-flex-justify-content-space-between u-spacing-ml-s"
                                  >
                                    <span
                                      class="u-font-size-5 u-color-grey-light"
                                      >{{ entity.entityLabel }}</span
                                    >
                                    <span
                                      class="u-font-size-5 u-color-grey-light u-spacing-pr-l unsubscribe u-cursor-pointer"
                                      @click="toggleSubscription(entity)"
                                    >
                                      <span v-if="!entity.hasSubscribed"
                                        >Subscribe</span
                                      >
                                      <!-- <span v-else>Unsubscribe</span> -->
                                    </span>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div class="u-width-50">
                      <section class="custom--table">
                        <div class="">
                          <div
                            class="u-display-flex u-flex-justify-content-space-between u-flex-direction-column custom--table--header u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s"
                          >
                            <div
                              class="u-display-flex u-flex-align-items-center"
                            >
                              <span class="u-font-size-4 u-font-weight-600">
                                Your subscriptions
                              </span>
                            </div>
                          </div>

                          <!-- section body -->
                          <div>
                            <div
                              v-show="!showEditSubscription"
                              class="u-overflow-auto custom--table--body u-spacing-pb-0"
                            >
                              <section
                                v-if="entity.isFilterInvalidated"
                                class="u-display-flex u-flex-direction-column u-spacing-mh-xs u-spacing-mv-m u-spacing-ph-l"
                              >
                                <div
                                  class="u-display-flex u-flex-align-items-center u-font-size-7"
                                >
                                  <rb-icon
                                    :icon="'error-fill'"
                                    class="rb-icon--small u-color-red-base u-spacing-mr-xs"
                                  />
                                  <p class="u-color-red-base">
                                    Due to a recent update of internal
                                    catalogue, the filters you have applied are
                                    invalidated.
                                  </p>
                                </div>
                                <p
                                  class="u-font-size-7 u-spacing-ml-m u-spacing-mv-xs"
                                >
                                  Please update your filters and save the
                                  subscription wherever applicable.
                                </p>
                              </section>
                              <draggable
                                v-model="subscribedEntity"
                                @start="drag = true"
                                @end="
                                  drag = false;
                                  updateEntityOrder();
                                "
                              >
                                <transition-group
                                  name="list"
                                  tag="div"
                                >
                                  <!-- eslint-disable -->
                                  <div
                                    v-for="entity of subscribedEntity"
                                    :key="entity.entityLabel"
                                    class="card u-display-flex u-flex-direction-column u-spacing-mh-l u-spacing-mv-m u-spacing-p-l entity__label u-border-width-s u-border-color-grey-xxx-light u-border-all"
                                    style="box-shadow: none"
                                  >
                                    <!-- eslint-enable -->
                                    <div
                                      class="u-display-flex u-flex-align-items u-flex-justify-content-space-between u-flex-align-items-center list-item"
                                    >
                                      <div
                                        class="u-font-size-4 u-font-weight-600 u-color-grey-light u-display-flex u-flex-align-items-center"
                                      >
                                        <span
                                          v-tippy
                                          class="u-spacing-mr-s"
                                          title="Reorder"
                                        >
                                          <rb-icon
                                            :icon="'draggable-reorder'"
                                            class="rb-icon--medium u-color-grey-lighter u-cursor-move"
                                          />
                                        </span>
                                        <span
                                          class="u-flex-1 card__entityTitle"
                                          >{{ entity.entityLabel }}</span
                                        >
                                        <div
                                          class="u-font-size-5 u-display-flex u-flex-align-items-center edit__button"
                                        >
                                          <span
                                            class="u-cursor-pointer u-color-grey-lighter u-spacing-mr-m unsubscribe__text"
                                            @click="
                                              toggleSubscription(
                                                entity,
                                                'unSubscribe'
                                              )
                                            "
                                            >Unsubscribe</span
                                          >
                                          <span
                                            class="u-spacing-mr-s u-cursor-pointer u-color-blue-base"
                                            @click="editSubscription(entity)"
                                          >
                                            Edit
                                            <!-- <rb-icon
                                              class="rb-icon--small"
                                              :icon="'pencil'"
                                            ></rb-icon> -->
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="filter u-spacing-mt-m u-spacing-pl-l"
                                    >
                                      <div
                                        class="u-font-size-5 u-color-grey-light u-font-weight-600 u-display-flex u-flex-align-items-center"
                                      >
                                        <p
                                          v-if="
                                            entity.isEntityFilterInvalidated
                                          "
                                          v-tippy
                                          title="Due to a recent update of internal catalogue, the filters you have applied are invalidated."
                                          class="u-cursor-pointer"
                                        >
                                          <rb-icon
                                            :icon="'error-fill'"
                                            class="rb-icon--small u-color-red-base"
                                          />
                                          <span
                                            class="u-color-red-base u-line-height-1-5"
                                            >Filters:</span
                                          >
                                        </p>
                                        <span
                                          v-else
                                          class="u-line-height-1-5"
                                          >Filters:</span
                                        >
                                      </div>
                                      <p
                                        v-if="
                                          !entity.entityFilter ||
                                          entity.isEntityFilterInvalidated
                                        "
                                        class="u-font-size-6 u-color-grey-mid-light u-spacing-mt-s"
                                      >
                                        No filters added
                                      </p>
                                      <p
                                        v-for="(
                                          dimensions, index
                                        ) in entity.entityFilter &&
                                        entity.entityFilter[0]"
                                        v-if="
                                          entity.entityFilter &&
                                          !entity.isEntityFilterInvalidated
                                        "
                                        :key="index"
                                        class="u-font-size-6 u-color-grey-mid-light u-spacing-mt-xs"
                                      >
                                        {{ dimensions.dimensionLabel }} :
                                        <span
                                          class="u-color-grey-base"
                                          :title="dimensions.values"
                                        >
                                          {{
                                            showFiltersValues(dimensions.values)
                                          }}</span
                                        >
                                        <!-- <span v-for="(value,key) in dimensions.values" :key="key">{{value}}
                                          <span
                                            v-if="key !== dimensions.values.length - 1"
                                            class="u-spacing-mr-xs"
                                            style="width: 2px;line-height: 11px;"
                                          >,</span>
                                        </span> -->
                                      </p>
                                    </div>
                                    <div
                                      class="columns u-spacing-mt-m u-spacing-pl-l"
                                    >
                                      <p
                                        class="u-font-size-5 u-color-grey-light u-font-weight-600"
                                      >
                                        Columns:
                                      </p>
                                      <p
                                        class="u-font-size-6 u-color-grey-mid-light u-spacing-mt-xs columnList"
                                      >
                                        <span
                                          v-for="(
                                            column, key
                                          ) in entity.subscribedColumns"
                                          v-if="column.hasSubscribed"
                                          :key="key"
                                          class="u-line-height-1-3 colum__tags"
                                          style="line-height: 1.5"
                                        >
                                          <span>{{ column.columnLabel }}</span>
                                          <span
                                            v-if="
                                              column.hasSubscribed &&
                                              key !==
                                                entity.subscribedColumns
                                                  .length -
                                                  1
                                            "
                                            class="u-spacing-mr-xs column__divider"
                                            style="
                                              width: 2px;
                                              line-height: 11px;
                                            "
                                            >•</span
                                          >
                                        </span>
                                      </p>
                                      <p
                                        v-if="
                                          entity.subscribedColumns.length === 0
                                        "
                                        class="u-font-size-5 u-color-grey-x-light u-spacing-mt-xs columnList"
                                      >
                                        No Columns
                                      </p>
                                    </div>
                                  </div>
                                </transition-group>
                              </draggable>
                            </div>

                            <!-- Edit Subscription  -->
                            <div
                              v-show="showEditSubscription"
                              class="u-overflow-auto custom--table--body u-spacing-pb-0"
                            >
                              <div
                                class="card u-display-flex u-flex-direction-column u-spacing-mh-l u-spacing-mv-m u-spacing-p-l u-spacing-mb-0 u-spacing-pb-0"
                                style="
                                  box-shadow: none;
                                  border: 1px solid #e9eaeb;
                                "
                              >
                                <div
                                  class="u-display-flex u-flex-align-items u-flex-justify-content-space-between u-flex-align-items-center entity__label"
                                >
                                  <div
                                    class="u-font-size-4 u-font-weight-600 u-color-grey-light u-display-flex u-flex-align-items-center"
                                  >
                                    <!-- <span
                                      class="u-spacing-mr-s"
                                      @click="saveSubscription(editEntity)"
                                    >
                                      <rb-icon
                                        :icon="'angled-arrow-left'"
                                        class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
                                      ></rb-icon>
                                    </span> -->
                                    <span class="u-flex-1">{{
                                      editEntity.entityLabel
                                    }}</span>
                                  </div>
                                  <div
                                    class="u-font-size-5 u-display-flex u-flex-align-items-center"
                                  >
                                    <!-- <span class="u-spacing-mr-s u-cursor-pointer" @click="editSubscription(entity)">
                                  <rb-icon class="rb-icon--small" :icon="'pencil'"></rb-icon>
                                </span> -->
                                    <!-- <span class="u-cursor-pointer u-spacing-mr-m u-color-red-base" @click="toggleSubscription(editEntity)">Unsubscribe</span> -->
                                    <!-- <span
                                      class="u-cursor-pointer u-color-blue-base"
                                      @click="saveSubscription(editEntity)"
                                    >Done</span> -->
                                    <!-- <rb-button
                                      :text="'Cancel'"
                                      :type="'hallow'"
                                      :size="'s'"
                                      :clickFn="cancelEditSubscription.bind(this,editEntity)"
                                      id="confirmButton"
                                    ></rb-button> -->
                                    <span
                                      class="u-cursor-pointer u-color-grey-lighter u-spacing-mr-m cancel__text"
                                      @click="
                                        cancelEditSubscription(editEntity)
                                      "
                                      >Cancel</span
                                    >
                                    <rb-button
                                      id="confirmButton"
                                      :text="'Done'"
                                      :type="'filled'"
                                      :size="'s'"
                                      :click-fn="
                                        saveEditSubscription.bind(
                                          this,
                                          editEntity
                                        )
                                      "
                                      :disabled="disableEditEntityBtn"
                                    />
                                  </div>
                                </div>
                                <div class="filter u-spacing-mt-m">
                                  <p class="u-font-size-5 u-color-grey-light">
                                    Filters:
                                  </p>
                                  <div
                                    v-if="showEditSubscription"
                                    class="u-font-size-5 u-color-grey-x-light u-spacing-mt-s pageBackground u-spacing-pl-m u-border-radius-s"
                                  >
                                    <rb-filter-panel
                                      :key="filterKey"
                                      :enable-save="false"
                                      :loader-status="loaderStatus"
                                      :data="filterData"
                                      :primary="primaryFilterData"
                                      :secondary-data="secondaryFilterData"
                                      :has-search="false"
                                      :enable-remove-all="false"
                                      :on-create="onCreate"
                                      :use-local-storage="false"
                                      :default-filters="defaultFilters"
                                      :listener-event="'email-sub'"
                                      :show-notification="false"
                                      :filter-v2="filterV2"
                                      :show-ingestion-progress="filterV2"
                                      @savedFilterApplied="savedFilterApplied"
                                      @allFilter="allFilter"
                                    />
                                  </div>
                                </div>
                                <div class="columns u-spacing-mt-m">
                                  <p class="u-font-size-5 u-color-grey-light">
                                    Columns:
                                  </p>
                                  <p
                                    class="u-font-size-5 u-color-grey-x-light u-spacing-mt-s"
                                  >
                                    Choose a maximum of 5 columns.
                                  </p>
                                  <div
                                    class="u-font-size-5 u-color-grey-x-light u-spacing-mv-m card pageBackground u-spacing-ph-m u-spacing-mt-s"
                                    style="height: 440px; overflow: auto"
                                  >
                                    <div
                                      v-for="(
                                        column, index
                                      ) in editEntity.subscribedColumns"
                                      :key="index"
                                      class="u-font-size-5 u-color-grey-light u-spacing-pv-s"
                                      :class="[
                                        index === 0 ? 'u-spacing-mt-s' : '',
                                        index ===
                                        editEntity.subscribedColumns.length - 1
                                          ? 'u-spacing-mb-s'
                                          : ''
                                      ]"
                                    >
                                      <rb-checkbox
                                        v-model="column.hasSubscribed"
                                        :native-value="column.hasSubscribed"
                                        :disabled="!column.isEditable"
                                        class="u-width-100"
                                      >
                                        <span
                                          class="u-font-size-5 u-color-grey-light"
                                          >{{ column.columnLabel }}</span
                                        >
                                      </rb-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <confirmDialog
          v-show="showConfirmExit"
          @cancelCallback="cancelCallback"
          @submitCallback="submitCallback"
        />
        <Loader
          v-if="isLoading"
          class="fill--parent"
          :color="'#3fadf7'"
          :size="'4rem'"
          :thickness="'.2rem'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/basic/loader.vue';
import tableSwitch from '@/components/globals/dataTable/tableComponentsWrapper/tableSwitch';
import HttpService from '@/utils/services/http-service';
import rbFilterPanel from '@/components/widgets/filter-panel';
import draggable from 'vuedraggable';
import widgetsMixin from '@/components/widgetsMixin';
import confirmDialog from '@/components/pages/emailSubscription/confirmDialog.vue';
import Vue from 'vue';
import { store } from '@/store/store';
import moment from 'moment-timezone';
import constants from '@/utils/constants';
var hash = require('object-hash');

export default {
  components: {
    Loader,
    tableSwitch,
    rbFilterPanel,
    draggable,
    confirmDialog
  },
  mixins: [widgetsMixin],
  data() {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      entity: {},
      entityTransform: [],
      subscribedEntity: [],
      editEntity: {},
      showEditSubscription: false,
      editSubscriptionFlow: false,
      isLoading: false,
      subscriptionToggle: false,
      filterKey: 0,
      tippy: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      secondaryFilterData: constants.secondaryFilterData,
      disableEditEntityBtn: true,
      disableSaveSubscriptionBtn: true,
      showConfirmExit: false
    };
  },
  computed: {
    defaultFilters() {
      if (
        this.editEntity &&
        this.editEntity.entityFilter &&
        this.editEntity.entityFilter.length > 0 &&
        !this.editEntity.isEntityFilterInvalidated
      ) {
        var obj = {};
        Object.keys(this.editEntity.entityFilter[0]).forEach((item) => {
          obj[item] = this.editEntity.entityFilter[0][item].values;
        });
        return {
          order: Object.keys(this.editEntity.entityFilter[0]) || [],
          values: obj
        };
      } else {
        return {
          order: [],
          values: {}
        };
      }
    }
  },
  watch: {
    'editEntity.subscribedColumns': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          const tempArray = newValue.filter((column) => {
            return column.hasSubscribed;
          });
          if (tempArray.length >= 5) {
            newValue = newValue.map((column) => {
              if (!column.isFrozen) {
                column.isEditable = column.hasSubscribed;
              }
              return column;
            });
          } else {
            newValue = newValue.map((column) => {
              // Don't change the default ones.
              if (!column.isFrozen) {
                column.isEditable = true;
              }
              return column;
            });
          }
        }
      },
      deep: true
    },
    editEntity: {
      handler: function (newValue, oldValue) {
        if (Object.keys(newValue).length > 0) {
          const newHash = hash(newValue);
          if (this.hashedEditEntity.hash === newHash) {
            this.disableEditEntityBtn = true;
          } else {
            this.disableEditEntityBtn = false;
          }
        }
      },
      deep: true
    },
    entity: {
      handler: function (newValue, oldValue) {
        if (Object.keys(newValue).length > 0 && this.hashedEntity) {
          const newHash = hash(newValue);
          if (this.hashedEntity.hash === newHash) {
            this.disableSaveSubscriptionBtn = true;
          } else {
            this.disableSaveSubscriptionBtn = false;
          }
        }
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    showFiltersValues(filterArray) {
      if (filterArray.length > 0) {
        var maxStrLen = 36;
        var concatStr = '';
        for (var idx = 0; idx <= filterArray.length; idx++) {
          var curSelected = filterArray[idx];
          if (concatStr.length >= maxStrLen - 3) {
            concatStr = concatStr.slice(0, maxStrLen - 3);
            // add '...' if all the selections is not fit into concatStr
            for (var k = 0; k < idx; k++) {
              if (!concatStr.includes(filterArray[k])) {
                concatStr = concatStr.concat('...');
              }
            }
            // Handling edge case ',' in the end for unclipped selections.
            if (concatStr.charAt(concatStr.length - 1).includes(',')) {
              concatStr = concatStr.slice(0, concatStr.length - 1);
            }
            var moreCount = filterArray.length - idx;
            concatStr = concatStr.concat(
              idx < filterArray.length ? ' & ' + moreCount + ' more' : ''
            );
            break;
          } else if (curSelected && curSelected.length > 0) {
            concatStr = concatStr.concat(
              curSelected,
              idx < filterArray.length - 1 ? ', ' : ''
            );
          }
        }
        return concatStr;
      }
    },
    savedFilterApplied(filter) {
      this.filterKey++;
      var obj = {};
      Object.keys(filter.addtionalMetadata.filterInfo.fields.filter).forEach(
        (item) => {
          if (filter.addtionalMetadata.filterInfo.fields.filter[item]) {
            obj[item] = {};
            obj[item].values =
              filter.addtionalMetadata.filterInfo.fields.filter[item];
          }
        }
      );
      const temp = [{}];
      Object.keys(obj).forEach(function (keys) {
        if (!temp[0][keys]) {
          temp[0][keys] = obj[keys];
        }
      });
      this.editEntity.entityFilter = JSON.parse(JSON.stringify(temp));
    },
    updateEntityOrder() {
      this.subscribedEntity = this.subscribedEntity.map((entity, index) => {
        entity.userSubscribedEntitySequence = index + 1;
        return entity;
      });
    },
    submitCallback() {
      this.showConfirmExit = false;
      if (this.subscribedEntity.length === 0) {
        this.unSubscribe();
      } else {
        this.updateSubscription();
      }
    },
    cancelCallback(params) {
      this.showConfirmExit = false;
      if (params === 'exit') {
        this.navigateBack();
      }
    },
    allFilter() {
      // Validation for filter got added or not.
      const fullFilterData = this.filterInstance.getFiltersFullMetadata();
      const temp = JSON.parse(JSON.stringify(this.editEntity));
      delete temp.hash;
      temp.entityFilter =
        Object.keys(fullFilterData[0]).length > 0 ? fullFilterData : null;
      const newHash = hash(temp);
      if (this.hashedEditEntity.hash === newHash) {
        this.disableEditEntityBtn = true;
      } else {
        this.disableEditEntityBtn = false;
      }
    },
    cancelEditSubscription() {
      this.showEditSubscription = false;
      this.editEntity = {};
    },
    saveEditSubscription(entity) {
      const fullFilterData = this.filterInstance.getFiltersFullMetadata();
      this.entity.entityInfo.forEach((item) => {
        if (item.entityLabel === entity.entityLabel) {
          if (item.isEntityFilterInvalidated) {
            item.isEntityFilterInvalidated = false;
          }
          item.entityFilter =
            Object.keys(fullFilterData[0]).length > 0 ? fullFilterData : null;
          item.subscribedColumns =
            entity.subscribedColumns && entity.subscribedColumns.slice();
        }
      });
      // this.editEntity.entityFilter = Object.keys(fullFilterData[0]).length > 0 ? fullFilterData : null;
      this.showEditSubscription = false;
      this.disableEditEntityBtn = true;
      this.editEntity = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    init() {
      this.isLoading = true;
      this.subscribedEntity = [];
      this.fetchFilters(
        'recommendation_filter',
        'recommendations',
        this.customFilterData
      );
      HttpService.post('READSUBSCRIPTION', {
        entityType: 'RECOMMENDATION'
      }).then(
        (response) => {
          this.entity = response.data.subscriptionDetails[0];
          if (this.entity.subscriptionId) {
            this.editSubscriptionFlow = true;
            this.subscriptionToggle = true;
          }
          this.entityTransform = this.entity.entityInfo
            .sort((a, b) => {
              return a.entitySequence > b.entitySequence
                ? 1
                : b.entitySequence > a.entitySequence
                ? -1
                : 0;
            })
            .sort((a, b) => {
              // return a.entityHierarchy1Order > b.entityHierarchy1Order;
              return a.entityHierarchy1Order > b.entityHierarchy1Order
                ? 1
                : b.entityHierarchy1Order > a.entityHierarchy1Order
                ? -1
                : 0;
            })
            .reduce((initial, item) => {
              item.subscribedColumns = item.subscribedColumns.map((column) => {
                if (!column.isEditable) {
                  column.isFrozen = true;
                } else {
                  column.isFrozen = false;
                }
                return column;
              });
              if (item.hasSubscribed) {
                item.hasSubscribed = false;
                this.toggleSubscription(item, 'edit');
                if (this.$route.query && this.$route.query.mode === 'edit') {
                  if (this.$route.query.name === item.entityLabel) {
                    setTimeout(() => {
                      // document.getElementById('scroll_into__view').scrollIntoView();
                      const cardEntityTitle = [
                        ...document.querySelectorAll('.card__entityTitle')
                      ];
                      cardEntityTitle.forEach((dom) => {
                        if (dom.innerText === this.$route.query.name) {
                          setTimeout(() => {
                            dom.closest('.card') &&
                              dom.closest('.card').scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                                inline: 'center'
                              });
                            dom
                              .closest('.card')
                              .classList.add('transision__border');
                            setTimeout(() => {
                              dom
                                .closest('.card')
                                .classList.remove('transision__border');
                            }, 3000);
                          }, 0);
                        }
                      });
                      // this.editSubscription(item);
                    }, 0);
                  }
                }
              }
              if (initial[item.entityHierarchy1]) {
                initial[item.entityHierarchy1].push(item);
              } else {
                initial[item.entityHierarchy1] = [];
                initial[item.entityHierarchy1].push(item);
              }
              return initial;
            }, {});
          this.isLoading = false;
          if (
            this.entity.subscriptionId &&
            this.$route.path === '/manageSubscription/unsubscribe'
          ) {
            this.subscriptionToggle = false;
          } else if (
            !this.entity.subscriptionId &&
            this.$route.path === '/manageSubscription/unsubscribe'
          ) {
            this.$router.push('/recommendations/all');
          }
          setTimeout(() => {
            this.hashedEntity = JSON.parse(JSON.stringify(this.entity));
            this.hashedEntity.hash = hash(this.hashedEntity);
          }, 1);
        },
        (err) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || err,
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    customFilterData(filterData) {
      return (filterData) => {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].profile_name === null) {
            filterData[i].profile_name = 'Others';
          }
        }
        return filterData;
      };
    },
    goBackValidation() {
      if (!this.disableSaveSubscriptionBtn) {
        this.showConfirmExit = true;
      } else {
        this.navigateBack();
      }
    },
    navigateBack() {
      // if (window.history.length > 2) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.push('/recommendations/all');
      // }
      const path = store.getters.getPreviousBackState || '/recommendations/all';
      this.$router.push(path);
    },
    toggleSubscription(entity, type) {
      entity.hasSubscribed = !entity.hasSubscribed;
      if (entity.hasSubscribed) {
        entity.subscribedColumns = entity.subscribedColumns.map((column) => {
          // Edit case Don't check for isDefault case
          if (!type) {
            entity.userSubscribedEntitySequence =
              this.subscribedEntity.length + 1;
            column.hasSubscribed =
              column.isDefault === true ? true : column.hasSubscribed;
          }
          return column;
        });
        this.subscribedEntity.push(entity);
        this.subscribedEntity = this.subscribedEntity.sort((a, b) => {
          return (
            a.userSubscribedEntitySequence - b.userSubscribedEntitySequence
          );
        });
      } else {
        // Incase of unsubscribe
        if (type === 'unSubscribe') {
          this.entity.entityInfo = this.entity.entityInfo.map((item) => {
            if (item.entityKey === entity.entityKey) {
              item.entityFilter = null;
              item.isEntityFilterInvalidated = false;
              entity.userSubscribedEntitySequence = null;
              item.subscribedColumns = entity.subscribedColumns.map(
                (column) => {
                  column.hasSubscribed = false;
                  return column;
                }
              );
            }
            return item;
          });
        }
        this.subscribedEntity = this.subscribedEntity.filter((item) => {
          return item.entityKey !== entity.entityKey;
        });
        this.updateEntityOrder();
      }
      if (this.showEditSubscription) {
        this.showEditSubscription = false;
        this.editEntity = {};
      }
    },
    editSubscription(entity) {
      this.showEditSubscription = true;
      this.disableEditEntityBtn = true;
      // Filter Invalidate Check need to be added.
      if (entity && entity.isEntityFilterInvalidated) {
        entity.entityFilter = null;
      }
      this.hashedEditEntity = JSON.parse(JSON.stringify(entity));
      this.hashedEditEntity.hash = hash(this.hashedEditEntity);
      this.editEntity = JSON.parse(JSON.stringify(entity));
    },
    updateSubscription() {
      if (!this.disableEditEntityBtn) {
        this.$snackbar.open({
          message: 'You have unsaved changes, kindly save your changes',
          duration: 5000,
          actionText: ''
        });
        return;
      }
      this.isLoading = true;
      this.entity.notificationMedium = ['EMAIL'];
      this.entity.schedule = {
        scheduleFrequencyLabel: 'DAILY',
        scheduleType: 'ALWAYS_FRESH'
      };
      this.entity.internationalisation = {
        locale: Vue.options.filters.config_check('feature.locale') || 'en_US',
        currency: this.$currency,
        // IANA time zone
        timeZone: moment.tz.guess()
      };
      this.entity.hasCompleteSubscriptionDetail = true;
      let apiCall = 'CREATESUBSCRIPTION';
      if (this.entity.subscriptionId) {
        apiCall = 'UPDATESUBSCRIPTION';
      }
      HttpService.post(apiCall, { subscriptionDetails: [this.entity] }).then(
        (response) => {
          this.isLoading = false;
          this.$snackbar.open({
            message:
              apiCall === 'CREATESUBSCRIPTION'
                ? `You have subscribed to ${this.subscribedEntity.length} recommendation types.`
                : 'All changes to your subscription are updated successfully',
            duration: 5000,
            actionText: ''
          });
          this.navigateBack();
        },
        (error) => {
          this.isLoading = false;
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || error,
            duration: 5000,
            actionText: ''
          });
        }
      );
    },
    cancelSubscription() {
      this.subscriptionToggle = !this.subscriptionToggle;
    },
    unSubscribe() {
      const data = {
        unsubscribeDetails: [
          {
            subscriptionId: this.entity.subscriptionId,
            notificationMedium: ['EMAIL']
          }
        ]
      };
      this.isLoading = true;
      HttpService.post('UNSUBSCRIPTION', data).then(
        (response) => {
          this.isLoading = false;
          this.$snackbar.open({
            message: 'You have unsubscribed from all recommendations.',
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: 'MANAGE SUBSCRIPTIONS',
            onAction: () => {
              this.$router.push('/manageSubscription');
            }
          });
          if (this.$route.path === '/manageSubscription/unsubscribe') {
            this.navigateBack();
          } else {
            this.navigateBack();
          }
        },
        (err) => {
          this.isLoading = false;
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || err,
            duration: 5000,
            actionText: ''
          });
          this.navigateBack();
        }
      );
    }
  }
};
</script>

<style scoped lang="css">
.emailSubscription {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background: #f5f7fa;
}
.emailSubscription .emailSubscription--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
.emailSubscription .emailSubscription--filter {
  background: #f5f7fa;
  height: 109px;
}
.heading--input {
  max-width: 75%;
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}
.scrollable {
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200;
}

.card {
  transition: border-color 0.5s linear;
}

.strategy-desc::placeholder {
  color: #aaadb1;
}
.error-highlight {
  border: 1px;
  border-color: #d7263d;
}

.custom--table {
  background-color: #f5f7fa;
  height: auto;
}

.custom--table--body {
  height: 690px;
  padding-bottom: 2.4rem;
}

.custom--table--header {
  box-shadow: 0 4px 4px -4px #caccce;
}

.recommendation:hover {
  background-color: #e9eaeb;
}

.unsubscribe {
  display: none;
}

.recommendation:hover .unsubscribe {
  display: inline;
  color: #007cf6;
}

.highlightEntity {
  opacity: 0.25;
}

.columnList > span:last-child > span.column__divider:last-child {
  display: none;
}

.edit__button {
  display: none;
}

.entity__label:hover .edit__button {
  display: initial;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.unsubscribe__text:hover {
  color: #d7263d;
}
.confirm__save__btn,
.confirm__save__btn:hover,
.confirm__save__btn:active {
  background: #d7263d !important;
}
.colum__tags > span:last-child > span:last-child {
  display: none !important;
}
.cancel__text:hover {
  color: #2b333b;
}

.transision__border {
  border-color: #007cf6;
}
</style>
