var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chip-wrapper"},[(_vm.showChart)?_c('div',{staticClass:"chip-active-indicator",style:({
      'background-color': _vm.computedSelectedMetric[_vm.val.key]
        ? _vm.bgColor
        : _vm.mutedColor
    })}):_vm._e(),_c('rb-tooltip-header',{attrs:{"id":"tooltipTemplate","title":_vm.val.key ? _vm.val.key : 'NA',"message":_vm.val.tooltip ? _vm.modifyMessage(_vm.val.tooltip) : 'NA'}}),(_vm.isMetricToolTipEnabled)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.setTooltip.tippyOptions),expression:"setTooltip.tippyOptions"}],staticClass:"demo u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger",attrs:{"title":_vm.val.tooltip ? _vm.val.tooltip : 'NA'}},[_c('div',{staticClass:"chip-label u-font-size-4",attrs:{"data-cy":"chip-label"}},[_vm._v(" "+_vm._s(_vm.val.label)+" : ")])]):_vm._e(),_c('div',{staticClass:"chip-value u-font-size-4"},[(_vm.hasPVP && (_vm.metricData || {})[_vm.val.key] && !_vm.hasPostCustomMetric)?_c('metric',{staticClass:"u-display-inline-flex",attrs:{"hide-zero":_vm.hideZero,"size":'m',"config":(_vm.metricConfig || {})[_vm.val.key],"data":(_vm.metricData || {})[_vm.val.key]}}):_vm._e()],1),(_vm.val && !_vm.removeDropdown && _vm.enableWatefall)?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'240px',"search-enable-threshold":_vm.searchEnableThreshold,"context":[_vm.index],"send-details":true,"options":_vm.chartChipOptions,"class-name":'campaigns-select'}},[_c('div',{class:[
        !_vm.ellipsisOnOverflow &&
          'u-display-flex u-flex-align-items-center u-cursor-pointer'
      ],attrs:{"slot":"trigger"},slot:"trigger"},[(!_vm.isMetricToolTipEnabled)?_c('div',{staticClass:"u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger",attrs:{"title":_vm.val.tooltip ? _vm.val.tooltip : 'NA'}},[_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1):_c('div',{staticClass:"u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger",attrs:{"title":_vm.val.tooltip ? _vm.val.tooltip : 'NA'}},[_c('span',{class:[
              _vm.metricFontSize,
              _vm.ellipsisOnOverflow && 'overflow-styles u-display-block'
            ]},[_vm._v(_vm._s(_vm.val.key))]),_c('rb-icon',{staticClass:"rb-icon--small u-spacing-ml-xs u-color-grey-lighter",attrs:{"icon":'caret-down'}})],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }