import {
  adgroupConfig,
  keywordTargeting,
  campaignType,
  settings,
  profile
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/index.js';
import { cloneDeep } from 'lodash';

const formStepConfig = () => {
  const clonedSettings = cloneDeep(settings);
  const adGroupConfigCloned = cloneDeep(adgroupConfig);
  adGroupConfigCloned.moduleClass =
    'campaign-creation-component-height-class-with-adgroup';
  clonedSettings.rightInfoAlert = {
    title: 'Campaign will be created!',
    desc: 'Going to next step will create the campaign, you can continue adding campaign details or do the same in campaign management dashboard by clicking into the same campaign. We recommend you to please proceed forward to complete the campaign details and to send the campaign for review.'
  };
  const stepsConfig = [
    campaignType,
    clonedSettings,
    adGroupConfigCloned,
    keywordTargeting,
    profile
  ];

  return stepsConfig;
};

export const sponsoredBrands = {
  header: {
    title: 'Campaign Creator',
    desc: 'Create multiple types of campaigns for an Ad group'
  },
  getter: 'walmartCampaignCreation/getAllStepsData',
  action: 'walmartCampaignCreation/updateAllStepsData',
  stepsConfig: formStepConfig()
};
