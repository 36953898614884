<template>
  <!-- Header Start -->
  <section class="campaign-creation-setup--header u-display-flex u-spacing-p-l">
    <div class="u-flex-1">
      <div class="u-display-flex u-flex-align-items-flex-end">
        <span @click="handleGoBack()">
          <rb-icon
            :icon="backButtonIcon"
            class="u-color-grey-lighter u-cursor-pointer is-flipped u-spacing-mr-s"
            data-cy="crossIcon"
            :class="'rb-icon--x-medium'"
          />
        </span>
        <div class="u-display-flex u-max-width-70">
          <input
            ref="campaign-creation-input"
            :value="title"
            type="text"
            :class="{
              'u-pointer-events-none': isEditVisible,
              'u-width-100': title,
              'text-underline': !isEditVisible && title
            }"
            class="heading--input u-text-overflow-ellipsis u-font-size-2 u-font-weight-bold"
            data-cy="suggestedCampaignNameAndEdit"
            :placeholder="placeholder"
            :size="(title.length || placeholder.length) + 1"
            @click.self.stop=""
            @input="handleEditTitle"
          />
          <div
            v-if="isEditVisible && !checkingForDuplicateCampaignName"
            @click.stop="handleEditBtnClick"
          >
            <rb-icon
              class="rb-icon--x-medium u-cursor-pointer u-color-grey-lighter"
              data-cy="pencilIcon"
              icon="pencil"
            />
          </div>
          <Loader
            :loading="checkingForDuplicateCampaignName"
            :color="'#8b8f93'"
          />
        </div>
        <div
          v-if="!!launchBtnToolTip"
          v-tippy="{ placement: 'top', arrow: true }"
          :title="launchBtnToolTip ? launchBtnToolTip : ''"
          class="u-spacing-ml-auto"
        >
          <rb-button
            v-if="showLaunchBtn"
            class="u-spacing-mr-l"
            :disabled="true"
            :click-fn="launchCampaign"
            text="Launch campaign"
            type="filled"
          />
        </div>
        <div
          v-else
          class="u-spacing-ml-auto"
        >
          <rb-button
            v-if="showLaunchBtn"
            class="u-spacing-mr-l"
            :disabled="!!errorMessage"
            :click-fn="launchCampaign"
            text="Launch campaign"
            type="filled"
            :loading="launchedCampaign.loading"
          />
        </div>
      </div>
      <div
        class="u-font-size-6 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-red-base u-spacing-mt-xs"
      >
        <div
          v-if="errorMessage || isDuplicateErrorMessage"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small"
            icon="error-fill"
          />
          <div class="u-flex-0 u-spacing-ml-xxs">
            {{ errorMessage || isDuplicateErrorMessage }}
          </div>
        </div>
        <div
          v-if="campaignLaunchFailedErrorMessage"
          class="u-spacing-mr-l u-spacing-ml-auto"
        >
          {{ campaignLaunchFailedErrorMessage }}
        </div>
      </div>
    </div>
  </section>
  <!-- Header ends -->
</template>

<script>
import {
  STEPS_DATA_GETTER,
  BID_TYPES,
  BUDGET_TYPES,
  LAUNCHED_CAMPAIGN_SETTER,
  LAUNCHED_CAMPAIGN_GETTER
} from '@/components/pages/instacart/campaign-creation/config';
import moment from 'moment';
import HttpService from '@/utils/services/http-service';
import Loader from '@/components/basic/loader';

export default {
  components: {
    Loader
  },
  model: {
    prop: 'title',
    event: 'handleEditTitle'
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isEditVisible: {
      type: Boolean,
      default: true
    },
    showLaunchBtn: {
      type: Boolean,
      default: false
    },
    backButtonIcon: {
      type: String,
      default: 'cross'
    },
    errorMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    launchedCampaign() {
      return this.$store.getters[LAUNCHED_CAMPAIGN_GETTER];
    },
    isDuplicateErrorMessage() {
      const { status, message } = this.launchedCampaign;
      const { campaignNameCheck } = this.stepsData;
      return (status === 'FAILURE' && message === 'is a duplicate') ||
        (campaignNameCheck?.alreadyExists &&
          this.placeholder === 'Untitled Campaign')
        ? 'Enter a unique campaign name'
        : '';
    },
    campaignLaunchFailedErrorMessage() {
      const { status, message } = this.launchedCampaign;
      return status === 'FAILURE' && this.showLaunchBtn
        ? `Error occurred while creating campaign.${
            message === 'is a duplicate'
              ? ''
              : ' Please try again after some time.'
          }`
        : '';
    },
    launchBtnToolTip() {
      const {
        steps: { adGroups, settings }
      } = this.stepsData;
      const pendingGroups = adGroups.filter(
        ({ defaultBid }) =>
          settings.bidType === BID_TYPES.manual.type &&
          (!defaultBid ||
            (settings.budgetType === BUDGET_TYPES.dailyBudget.type
              ? defaultBid >= settings.dailyBudget
              : defaultBid >= settings.lifetimeBudget))
      );

      return adGroups.length <= 0 || pendingGroups.length
        ? 'Add at least one ad group to launch the campaign'
        : '';
    },
    checkingForDuplicateCampaignName() {
      return this.stepsData?.campaignNameCheck?.loading;
    }
  },
  created() {
    this.$emit('setHeaderRef', this);
  },
  methods: {
    launchCampaign() {
      const {
        campaignName,
        steps: { objective, settings, adGroups }
      } = this.stepsData;
      const isOptimizedBiddingEnabled = [
        BID_TYPES.max_sales.type,
        BID_TYPES.acquire_ntb.type
      ].includes(settings.bidType);
      // Temporary mapping for payload's 'objective' value until instacart accepts new values.
      const mappedObjective = {
        acquire: 'market_share',
        maximize_sales: 'sales',
        other: 'other'
      };
      const adGroupRequest = adGroups.map(
        ({ adGroupName, products = [], keywords = [], defaultBid }) => ({
          name: adGroupName,
          defaultBid:
            settings.bidType === BID_TYPES.manual.type ? defaultBid : undefined,
          enabled: true,
          productRequest: products.map(
            ({ upc, image_url, product_url, product_title }) => ({
              upcs: upc,
              imageUrl: image_url,
              productUrl: product_url,
              productTitle: product_title
            })
          ),
          keywordRequest:
            settings.bidType === BID_TYPES.manual.type
              ? keywords.map(({ keyword, match_type, newBid }) => ({
                  keyword,
                  matchingType: match_type.toLowerCase(),
                  cpcBid: newBid
                }))
              : []
        })
      );

      const { startDate, endDate } = settings.campaignDates;
      const todaysDateInPST = moment.tz(moment(), 'America/Los_Angeles');
      const dayInPST = todaysDateInPST.day();
      const dayInLocalTimezone = new Date(startDate).getDay();
      const differenceInDays =
        dayInPST > dayInLocalTimezone ? dayInPST - dayInLocalTimezone : 0;
      const formattedStartDate = moment(startDate)
        .add(differenceInDays, 'days')
        .format('YYYY-MM-DD');
      const formattedEndDate = endDate
        ? moment(endDate).add(differenceInDays, 'days').format('YYYY-MM-DD')
        : endDate;
      const payload = [
        {
          campaignType: 'featured_product',
          retailer: 'instacart',
          request: {
            campaignRequest: {
              name: campaignName,
              enabled: true,
              budget: settings.lifetimeBudget,
              campaignType: 'featured_product',
              objective: mappedObjective[objective.type],
              objectiveCustom: objective.value || objective.type,
              budgetType: 'daily_budget',
              targetDailyBudget:
                settings.budgetType === BUDGET_TYPES.dailyBudget.type
                  ? settings.dailyBudget
                  : 0,
              optimizedBiddingEnabled: isOptimizedBiddingEnabled,
              startsAt: formattedStartDate,
              endsAt: formattedEndDate,
              optimizedBiddingParameters: {
                roasConstraint: isOptimizedBiddingEnabled
                  ? settings.minRoas || null
                  : 0.0
              },
              optimizedBiddingGoal: isOptimizedBiddingEnabled
                ? settings.bidType
                : null
            },
            adGroupRequest
          }
        }
      ];
      this.$store.dispatch(LAUNCHED_CAMPAIGN_SETTER, { loading: true });
      HttpService.post('CREATE_AMS_ACTIONS_MANAGER', payload)
        .then((res) => {
          const { adGroupResponses, campaignName, description, code } =
            res?.data?.response?.[0] || {};
          this.$store.dispatch(LAUNCHED_CAMPAIGN_SETTER, {
            status: code,
            name: campaignName,
            message: description,
            loading: false,
            adGroups: adGroupResponses
          });
          if (code === 'SUCCESS') {
            this.$router.push({ name: 'Launch Campaign' });
          }
        })
        .catch(() => {
          this.$store.dispatch(LAUNCHED_CAMPAIGN_SETTER, {
            status: 'FAILURE',
            loading: false
          });
        });
    },
    handleGoBack() {
      this.$emit('handleGoBack');
    },
    handleEditBtnClick() {
      this.$refs?.['campaign-creation-input']?.focus();
      this.$emit('handleChangeIsEditVisible', false);
    },
    handleEditTitle(event) {
      this.$emit('handleEditTitle', event.target.value);
    }
  }
};
</script>

<style lang="css">
.u-spacing-ml-auto {
  margin-left: auto;
}

.text-underline {
  text-decoration: underline;
  text-decoration-color: #8b8f93;
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
}

.heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus:focus {
  border: 0;
  outline: none;
}

.campaign-creation-setup--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
</style>
